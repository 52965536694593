[type='text'],input:where(:not([type])),[type='email'],[type='url'],[type='password'],[type='number'],[type='date'],[type='datetime-local'],[type='month'],[type='search'],[type='tel'],[type='time'],[type='week'],[multiple],textarea,select {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

[type='text']:focus, input:where(:not([type])):focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='date']:focus, [type='datetime-local']:focus, [type='month']:focus, [type='search']:focus, [type='tel']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

input::placeholder,textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

::-webkit-datetime-edit {
  display: inline-flex;
}

::-webkit-datetime-edit,::-webkit-datetime-edit-year-field,::-webkit-datetime-edit-month-field,::-webkit-datetime-edit-day-field,::-webkit-datetime-edit-hour-field,::-webkit-datetime-edit-minute-field,::-webkit-datetime-edit-second-field,::-webkit-datetime-edit-millisecond-field,::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}

[multiple],[size]:where(select:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
          print-color-adjust: unset;
}

[type='checkbox'],[type='radio'] {
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

[type='checkbox'] {
  border-radius: 0px;
}

[type='radio'] {
  border-radius: 100%;
}

[type='checkbox']:focus,[type='radio']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

[type='checkbox']:checked,[type='radio']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active)  {
  [type='checkbox']:checked {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

[type='radio']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active)  {
  [type='radio']:checked {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

[type='checkbox']:checked:hover,[type='checkbox']:checked:focus,[type='radio']:checked:hover,[type='radio']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (forced-colors: active)  {
  [type='checkbox']:indeterminate {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

[type='checkbox']:indeterminate:hover,[type='checkbox']:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type='file']:focus {
  outline: 1px solid ButtonText;
  outline: 1px auto -webkit-focus-ring-color;
}

*, ::before, ::after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x:  ;
  --tw-pan-y:  ;
  --tw-pinch-zoom:  ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position:  ;
  --tw-gradient-via-position:  ;
  --tw-gradient-to-position:  ;
  --tw-ordinal:  ;
  --tw-slashed-zero:  ;
  --tw-numeric-figure:  ;
  --tw-numeric-spacing:  ;
  --tw-numeric-fraction:  ;
  --tw-ring-inset:  ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgb(59 130 246 / 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur:  ;
  --tw-brightness:  ;
  --tw-contrast:  ;
  --tw-grayscale:  ;
  --tw-hue-rotate:  ;
  --tw-invert:  ;
  --tw-saturate:  ;
  --tw-sepia:  ;
  --tw-drop-shadow:  ;
  --tw-backdrop-blur:  ;
  --tw-backdrop-brightness:  ;
  --tw-backdrop-contrast:  ;
  --tw-backdrop-grayscale:  ;
  --tw-backdrop-hue-rotate:  ;
  --tw-backdrop-invert:  ;
  --tw-backdrop-opacity:  ;
  --tw-backdrop-saturate:  ;
  --tw-backdrop-sepia:  ;
}

.tw-container {
  width: 100%;
}

@media (min-width: 640px) {
  .tw-container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .tw-container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .tw-container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .tw-container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .tw-container {
    max-width: 1536px;
  }
}

.tw-form-input,.tw-form-textarea,.tw-form-select,.tw-form-multiselect {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  --tw-shadow: 0 0 #0000;
}

.tw-form-input:focus, .tw-form-textarea:focus, .tw-form-select:focus, .tw-form-multiselect:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
  border-color: #2563eb;
}

.tw-form-input::placeholder,.tw-form-textarea::placeholder {
  color: #6b7280;
  opacity: 1;
}

.tw-form-input::-webkit-datetime-edit-fields-wrapper {
  padding: 0;
}

.tw-form-input::-webkit-date-and-time-value {
  min-height: 1.5em;
  text-align: inherit;
}

.tw-form-input::-webkit-datetime-edit {
  display: inline-flex;
}

.tw-form-input::-webkit-datetime-edit,.tw-form-input::-webkit-datetime-edit-year-field,.tw-form-input::-webkit-datetime-edit-month-field,.tw-form-input::-webkit-datetime-edit-day-field,.tw-form-input::-webkit-datetime-edit-hour-field,.tw-form-input::-webkit-datetime-edit-minute-field,.tw-form-input::-webkit-datetime-edit-second-field,.tw-form-input::-webkit-datetime-edit-millisecond-field,.tw-form-input::-webkit-datetime-edit-meridiem-field {
  padding-top: 0;
  padding-bottom: 0;
}

.tw-form-select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
}

.tw-form-select:where([size]:not([size="1"])) {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
          print-color-adjust: unset;
}

.tw-form-checkbox,.tw-form-radio {
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
          print-color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
          user-select: none;
  flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  --tw-shadow: 0 0 #0000;
}

.tw-form-checkbox {
  border-radius: 0px;
}

.tw-form-radio {
  border-radius: 100%;
}

.tw-form-checkbox:focus,.tw-form-radio:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow);
}

.tw-form-checkbox:checked,.tw-form-radio:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

.tw-form-checkbox:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}

@media (forced-colors: active)  {
  .tw-form-checkbox:checked {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

.tw-form-radio:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle cx='8' cy='8' r='3'/%3e%3c/svg%3e");
}

@media (forced-colors: active)  {
  .tw-form-radio:checked {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

.tw-form-checkbox:checked:hover,.tw-form-checkbox:checked:focus,.tw-form-radio:checked:hover,.tw-form-radio:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

.tw-form-checkbox:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

@media (forced-colors: active)  {
  .tw-form-checkbox:indeterminate {
    -webkit-appearance: auto;
            appearance: auto;
  }
}

.tw-form-checkbox:indeterminate:hover,.tw-form-checkbox:indeterminate:focus {
  border-color: transparent;
  background-color: currentColor;
}

.tw-sr-only {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border-width: 0 !important;
}

.tw-not-sr-only {
  position: static !important;
  width: auto !important;
  height: auto !important;
  padding: 0 !important;
  margin: 0 !important;
  overflow: visible !important;
  clip: auto !important;
  white-space: normal !important;
}

.tw-pointer-events-none {
  pointer-events: none !important;
}

.tw-pointer-events-auto {
  pointer-events: auto !important;
}

.tw-visible {
  visibility: visible !important;
}

.tw-invisible {
  visibility: hidden !important;
}

.tw-collapse {
  visibility: collapse !important;
}

.tw-static {
  position: static !important;
}

.tw-fixed {
  position: fixed !important;
}

.tw-absolute {
  position: absolute !important;
}

.tw-relative {
  position: relative !important;
}

.tw-sticky {
  position: -webkit-sticky !important;
  position: sticky !important;
}

.-tw-inset-0 {
  inset: -0px !important;
}

.-tw-inset-0\.5 {
  inset: -0.125rem !important;
}

.-tw-inset-1 {
  inset: -0.25rem !important;
}

.-tw-inset-1\.5 {
  inset: -0.375rem !important;
}

.-tw-inset-1\/2 {
  inset: -50% !important;
}

.-tw-inset-1\/3 {
  inset: -33.333333% !important;
}

.-tw-inset-1\/4 {
  inset: -25% !important;
}

.-tw-inset-10 {
  inset: -2.5rem !important;
}

.-tw-inset-11 {
  inset: -2.75rem !important;
}

.-tw-inset-12 {
  inset: -3rem !important;
}

.-tw-inset-14 {
  inset: -3.5rem !important;
}

.-tw-inset-16 {
  inset: -4rem !important;
}

.-tw-inset-2 {
  inset: -0.5rem !important;
}

.-tw-inset-2\.5 {
  inset: -0.625rem !important;
}

.-tw-inset-2\/3 {
  inset: -66.666667% !important;
}

.-tw-inset-2\/4 {
  inset: -50% !important;
}

.-tw-inset-20 {
  inset: -5rem !important;
}

.-tw-inset-24 {
  inset: -6rem !important;
}

.-tw-inset-28 {
  inset: -7rem !important;
}

.-tw-inset-3 {
  inset: -0.75rem !important;
}

.-tw-inset-3\.5 {
  inset: -0.875rem !important;
}

.-tw-inset-3\/4 {
  inset: -75% !important;
}

.-tw-inset-32 {
  inset: -8rem !important;
}

.-tw-inset-36 {
  inset: -9rem !important;
}

.-tw-inset-4 {
  inset: -1rem !important;
}

.-tw-inset-40 {
  inset: -10rem !important;
}

.-tw-inset-44 {
  inset: -11rem !important;
}

.-tw-inset-48 {
  inset: -12rem !important;
}

.-tw-inset-5 {
  inset: -1.25rem !important;
}

.-tw-inset-52 {
  inset: -13rem !important;
}

.-tw-inset-56 {
  inset: -14rem !important;
}

.-tw-inset-6 {
  inset: -1.5rem !important;
}

.-tw-inset-60 {
  inset: -15rem !important;
}

.-tw-inset-64 {
  inset: -16rem !important;
}

.-tw-inset-7 {
  inset: -1.75rem !important;
}

.-tw-inset-72 {
  inset: -18rem !important;
}

.-tw-inset-8 {
  inset: -2rem !important;
}

.-tw-inset-80 {
  inset: -20rem !important;
}

.-tw-inset-9 {
  inset: -2.25rem !important;
}

.-tw-inset-96 {
  inset: -24rem !important;
}

.-tw-inset-full {
  inset: -100% !important;
}

.-tw-inset-px {
  inset: -1px !important;
}

.tw--inset-0 {
  inset: -0px !important;
}

.tw--inset-0\.5 {
  inset: -0.125rem !important;
}

.tw--inset-1 {
  inset: -0.25rem !important;
}

.tw--inset-1\.5 {
  inset: -0.375rem !important;
}

.tw--inset-1\/2 {
  inset: -50% !important;
}

.tw--inset-1\/3 {
  inset: -33.333333% !important;
}

.tw--inset-1\/4 {
  inset: -25% !important;
}

.tw--inset-10 {
  inset: -2.5rem !important;
}

.tw--inset-11 {
  inset: -2.75rem !important;
}

.tw--inset-12 {
  inset: -3rem !important;
}

.tw--inset-14 {
  inset: -3.5rem !important;
}

.tw--inset-16 {
  inset: -4rem !important;
}

.tw--inset-2 {
  inset: -0.5rem !important;
}

.tw--inset-2\.5 {
  inset: -0.625rem !important;
}

.tw--inset-2\/3 {
  inset: -66.666667% !important;
}

.tw--inset-2\/4 {
  inset: -50% !important;
}

.tw--inset-20 {
  inset: -5rem !important;
}

.tw--inset-24 {
  inset: -6rem !important;
}

.tw--inset-28 {
  inset: -7rem !important;
}

.tw--inset-3 {
  inset: -0.75rem !important;
}

.tw--inset-3\.5 {
  inset: -0.875rem !important;
}

.tw--inset-3\/4 {
  inset: -75% !important;
}

.tw--inset-32 {
  inset: -8rem !important;
}

.tw--inset-36 {
  inset: -9rem !important;
}

.tw--inset-4 {
  inset: -1rem !important;
}

.tw--inset-40 {
  inset: -10rem !important;
}

.tw--inset-44 {
  inset: -11rem !important;
}

.tw--inset-48 {
  inset: -12rem !important;
}

.tw--inset-5 {
  inset: -1.25rem !important;
}

.tw--inset-52 {
  inset: -13rem !important;
}

.tw--inset-56 {
  inset: -14rem !important;
}

.tw--inset-6 {
  inset: -1.5rem !important;
}

.tw--inset-60 {
  inset: -15rem !important;
}

.tw--inset-64 {
  inset: -16rem !important;
}

.tw--inset-7 {
  inset: -1.75rem !important;
}

.tw--inset-72 {
  inset: -18rem !important;
}

.tw--inset-8 {
  inset: -2rem !important;
}

.tw--inset-80 {
  inset: -20rem !important;
}

.tw--inset-9 {
  inset: -2.25rem !important;
}

.tw--inset-96 {
  inset: -24rem !important;
}

.tw--inset-full {
  inset: -100% !important;
}

.tw--inset-px {
  inset: -1px !important;
}

.tw-inset-0 {
  inset: 0px !important;
}

.tw-inset-0\.5 {
  inset: 0.125rem !important;
}

.tw-inset-1 {
  inset: 0.25rem !important;
}

.tw-inset-1\.5 {
  inset: 0.375rem !important;
}

.tw-inset-1\/2 {
  inset: 50% !important;
}

.tw-inset-1\/3 {
  inset: 33.333333% !important;
}

.tw-inset-1\/4 {
  inset: 25% !important;
}

.tw-inset-10 {
  inset: 2.5rem !important;
}

.tw-inset-11 {
  inset: 2.75rem !important;
}

.tw-inset-12 {
  inset: 3rem !important;
}

.tw-inset-14 {
  inset: 3.5rem !important;
}

.tw-inset-16 {
  inset: 4rem !important;
}

.tw-inset-2 {
  inset: 0.5rem !important;
}

.tw-inset-2\.5 {
  inset: 0.625rem !important;
}

.tw-inset-2\/3 {
  inset: 66.666667% !important;
}

.tw-inset-2\/4 {
  inset: 50% !important;
}

.tw-inset-20 {
  inset: 5rem !important;
}

.tw-inset-24 {
  inset: 6rem !important;
}

.tw-inset-28 {
  inset: 7rem !important;
}

.tw-inset-3 {
  inset: 0.75rem !important;
}

.tw-inset-3\.5 {
  inset: 0.875rem !important;
}

.tw-inset-3\/4 {
  inset: 75% !important;
}

.tw-inset-32 {
  inset: 8rem !important;
}

.tw-inset-36 {
  inset: 9rem !important;
}

.tw-inset-4 {
  inset: 1rem !important;
}

.tw-inset-40 {
  inset: 10rem !important;
}

.tw-inset-44 {
  inset: 11rem !important;
}

.tw-inset-48 {
  inset: 12rem !important;
}

.tw-inset-5 {
  inset: 1.25rem !important;
}

.tw-inset-52 {
  inset: 13rem !important;
}

.tw-inset-56 {
  inset: 14rem !important;
}

.tw-inset-6 {
  inset: 1.5rem !important;
}

.tw-inset-60 {
  inset: 15rem !important;
}

.tw-inset-64 {
  inset: 16rem !important;
}

.tw-inset-7 {
  inset: 1.75rem !important;
}

.tw-inset-72 {
  inset: 18rem !important;
}

.tw-inset-8 {
  inset: 2rem !important;
}

.tw-inset-80 {
  inset: 20rem !important;
}

.tw-inset-9 {
  inset: 2.25rem !important;
}

.tw-inset-96 {
  inset: 24rem !important;
}

.tw-inset-auto {
  inset: auto !important;
}

.tw-inset-full {
  inset: 100% !important;
}

.tw-inset-px {
  inset: 1px !important;
}

.-tw-inset-x-0 {
  left: -0px !important;
  right: -0px !important;
}

.-tw-inset-x-0\.5 {
  left: -0.125rem !important;
  right: -0.125rem !important;
}

.-tw-inset-x-1 {
  left: -0.25rem !important;
  right: -0.25rem !important;
}

.-tw-inset-x-1\.5 {
  left: -0.375rem !important;
  right: -0.375rem !important;
}

.-tw-inset-x-1\/2 {
  left: -50% !important;
  right: -50% !important;
}

.-tw-inset-x-1\/3 {
  left: -33.333333% !important;
  right: -33.333333% !important;
}

.-tw-inset-x-1\/4 {
  left: -25% !important;
  right: -25% !important;
}

.-tw-inset-x-10 {
  left: -2.5rem !important;
  right: -2.5rem !important;
}

.-tw-inset-x-11 {
  left: -2.75rem !important;
  right: -2.75rem !important;
}

.-tw-inset-x-12 {
  left: -3rem !important;
  right: -3rem !important;
}

.-tw-inset-x-14 {
  left: -3.5rem !important;
  right: -3.5rem !important;
}

.-tw-inset-x-16 {
  left: -4rem !important;
  right: -4rem !important;
}

.-tw-inset-x-2 {
  left: -0.5rem !important;
  right: -0.5rem !important;
}

.-tw-inset-x-2\.5 {
  left: -0.625rem !important;
  right: -0.625rem !important;
}

.-tw-inset-x-2\/3 {
  left: -66.666667% !important;
  right: -66.666667% !important;
}

.-tw-inset-x-2\/4 {
  left: -50% !important;
  right: -50% !important;
}

.-tw-inset-x-20 {
  left: -5rem !important;
  right: -5rem !important;
}

.-tw-inset-x-24 {
  left: -6rem !important;
  right: -6rem !important;
}

.-tw-inset-x-28 {
  left: -7rem !important;
  right: -7rem !important;
}

.-tw-inset-x-3 {
  left: -0.75rem !important;
  right: -0.75rem !important;
}

.-tw-inset-x-3\.5 {
  left: -0.875rem !important;
  right: -0.875rem !important;
}

.-tw-inset-x-3\/4 {
  left: -75% !important;
  right: -75% !important;
}

.-tw-inset-x-32 {
  left: -8rem !important;
  right: -8rem !important;
}

.-tw-inset-x-36 {
  left: -9rem !important;
  right: -9rem !important;
}

.-tw-inset-x-4 {
  left: -1rem !important;
  right: -1rem !important;
}

.-tw-inset-x-40 {
  left: -10rem !important;
  right: -10rem !important;
}

.-tw-inset-x-44 {
  left: -11rem !important;
  right: -11rem !important;
}

.-tw-inset-x-48 {
  left: -12rem !important;
  right: -12rem !important;
}

.-tw-inset-x-5 {
  left: -1.25rem !important;
  right: -1.25rem !important;
}

.-tw-inset-x-52 {
  left: -13rem !important;
  right: -13rem !important;
}

.-tw-inset-x-56 {
  left: -14rem !important;
  right: -14rem !important;
}

.-tw-inset-x-6 {
  left: -1.5rem !important;
  right: -1.5rem !important;
}

.-tw-inset-x-60 {
  left: -15rem !important;
  right: -15rem !important;
}

.-tw-inset-x-64 {
  left: -16rem !important;
  right: -16rem !important;
}

.-tw-inset-x-7 {
  left: -1.75rem !important;
  right: -1.75rem !important;
}

.-tw-inset-x-72 {
  left: -18rem !important;
  right: -18rem !important;
}

.-tw-inset-x-8 {
  left: -2rem !important;
  right: -2rem !important;
}

.-tw-inset-x-80 {
  left: -20rem !important;
  right: -20rem !important;
}

.-tw-inset-x-9 {
  left: -2.25rem !important;
  right: -2.25rem !important;
}

.-tw-inset-x-96 {
  left: -24rem !important;
  right: -24rem !important;
}

.-tw-inset-x-full {
  left: -100% !important;
  right: -100% !important;
}

.-tw-inset-x-px {
  left: -1px !important;
  right: -1px !important;
}

.-tw-inset-y-0 {
  top: -0px !important;
  bottom: -0px !important;
}

.-tw-inset-y-0\.5 {
  top: -0.125rem !important;
  bottom: -0.125rem !important;
}

.-tw-inset-y-1 {
  top: -0.25rem !important;
  bottom: -0.25rem !important;
}

.-tw-inset-y-1\.5 {
  top: -0.375rem !important;
  bottom: -0.375rem !important;
}

.-tw-inset-y-1\/2 {
  top: -50% !important;
  bottom: -50% !important;
}

.-tw-inset-y-1\/3 {
  top: -33.333333% !important;
  bottom: -33.333333% !important;
}

.-tw-inset-y-1\/4 {
  top: -25% !important;
  bottom: -25% !important;
}

.-tw-inset-y-10 {
  top: -2.5rem !important;
  bottom: -2.5rem !important;
}

.-tw-inset-y-11 {
  top: -2.75rem !important;
  bottom: -2.75rem !important;
}

.-tw-inset-y-12 {
  top: -3rem !important;
  bottom: -3rem !important;
}

.-tw-inset-y-14 {
  top: -3.5rem !important;
  bottom: -3.5rem !important;
}

.-tw-inset-y-16 {
  top: -4rem !important;
  bottom: -4rem !important;
}

.-tw-inset-y-2 {
  top: -0.5rem !important;
  bottom: -0.5rem !important;
}

.-tw-inset-y-2\.5 {
  top: -0.625rem !important;
  bottom: -0.625rem !important;
}

.-tw-inset-y-2\/3 {
  top: -66.666667% !important;
  bottom: -66.666667% !important;
}

.-tw-inset-y-2\/4 {
  top: -50% !important;
  bottom: -50% !important;
}

.-tw-inset-y-20 {
  top: -5rem !important;
  bottom: -5rem !important;
}

.-tw-inset-y-24 {
  top: -6rem !important;
  bottom: -6rem !important;
}

.-tw-inset-y-28 {
  top: -7rem !important;
  bottom: -7rem !important;
}

.-tw-inset-y-3 {
  top: -0.75rem !important;
  bottom: -0.75rem !important;
}

.-tw-inset-y-3\.5 {
  top: -0.875rem !important;
  bottom: -0.875rem !important;
}

.-tw-inset-y-3\/4 {
  top: -75% !important;
  bottom: -75% !important;
}

.-tw-inset-y-32 {
  top: -8rem !important;
  bottom: -8rem !important;
}

.-tw-inset-y-36 {
  top: -9rem !important;
  bottom: -9rem !important;
}

.-tw-inset-y-4 {
  top: -1rem !important;
  bottom: -1rem !important;
}

.-tw-inset-y-40 {
  top: -10rem !important;
  bottom: -10rem !important;
}

.-tw-inset-y-44 {
  top: -11rem !important;
  bottom: -11rem !important;
}

.-tw-inset-y-48 {
  top: -12rem !important;
  bottom: -12rem !important;
}

.-tw-inset-y-5 {
  top: -1.25rem !important;
  bottom: -1.25rem !important;
}

.-tw-inset-y-52 {
  top: -13rem !important;
  bottom: -13rem !important;
}

.-tw-inset-y-56 {
  top: -14rem !important;
  bottom: -14rem !important;
}

.-tw-inset-y-6 {
  top: -1.5rem !important;
  bottom: -1.5rem !important;
}

.-tw-inset-y-60 {
  top: -15rem !important;
  bottom: -15rem !important;
}

.-tw-inset-y-64 {
  top: -16rem !important;
  bottom: -16rem !important;
}

.-tw-inset-y-7 {
  top: -1.75rem !important;
  bottom: -1.75rem !important;
}

.-tw-inset-y-72 {
  top: -18rem !important;
  bottom: -18rem !important;
}

.-tw-inset-y-8 {
  top: -2rem !important;
  bottom: -2rem !important;
}

.-tw-inset-y-80 {
  top: -20rem !important;
  bottom: -20rem !important;
}

.-tw-inset-y-9 {
  top: -2.25rem !important;
  bottom: -2.25rem !important;
}

.-tw-inset-y-96 {
  top: -24rem !important;
  bottom: -24rem !important;
}

.-tw-inset-y-full {
  top: -100% !important;
  bottom: -100% !important;
}

.-tw-inset-y-px {
  top: -1px !important;
  bottom: -1px !important;
}

.tw--inset-x-0 {
  left: -0px !important;
  right: -0px !important;
}

.tw--inset-x-0\.5 {
  left: -0.125rem !important;
  right: -0.125rem !important;
}

.tw--inset-x-1 {
  left: -0.25rem !important;
  right: -0.25rem !important;
}

.tw--inset-x-1\.5 {
  left: -0.375rem !important;
  right: -0.375rem !important;
}

.tw--inset-x-1\/2 {
  left: -50% !important;
  right: -50% !important;
}

.tw--inset-x-1\/3 {
  left: -33.333333% !important;
  right: -33.333333% !important;
}

.tw--inset-x-1\/4 {
  left: -25% !important;
  right: -25% !important;
}

.tw--inset-x-10 {
  left: -2.5rem !important;
  right: -2.5rem !important;
}

.tw--inset-x-11 {
  left: -2.75rem !important;
  right: -2.75rem !important;
}

.tw--inset-x-12 {
  left: -3rem !important;
  right: -3rem !important;
}

.tw--inset-x-14 {
  left: -3.5rem !important;
  right: -3.5rem !important;
}

.tw--inset-x-16 {
  left: -4rem !important;
  right: -4rem !important;
}

.tw--inset-x-2 {
  left: -0.5rem !important;
  right: -0.5rem !important;
}

.tw--inset-x-2\.5 {
  left: -0.625rem !important;
  right: -0.625rem !important;
}

.tw--inset-x-2\/3 {
  left: -66.666667% !important;
  right: -66.666667% !important;
}

.tw--inset-x-2\/4 {
  left: -50% !important;
  right: -50% !important;
}

.tw--inset-x-20 {
  left: -5rem !important;
  right: -5rem !important;
}

.tw--inset-x-24 {
  left: -6rem !important;
  right: -6rem !important;
}

.tw--inset-x-28 {
  left: -7rem !important;
  right: -7rem !important;
}

.tw--inset-x-3 {
  left: -0.75rem !important;
  right: -0.75rem !important;
}

.tw--inset-x-3\.5 {
  left: -0.875rem !important;
  right: -0.875rem !important;
}

.tw--inset-x-3\/4 {
  left: -75% !important;
  right: -75% !important;
}

.tw--inset-x-32 {
  left: -8rem !important;
  right: -8rem !important;
}

.tw--inset-x-36 {
  left: -9rem !important;
  right: -9rem !important;
}

.tw--inset-x-4 {
  left: -1rem !important;
  right: -1rem !important;
}

.tw--inset-x-40 {
  left: -10rem !important;
  right: -10rem !important;
}

.tw--inset-x-44 {
  left: -11rem !important;
  right: -11rem !important;
}

.tw--inset-x-48 {
  left: -12rem !important;
  right: -12rem !important;
}

.tw--inset-x-5 {
  left: -1.25rem !important;
  right: -1.25rem !important;
}

.tw--inset-x-52 {
  left: -13rem !important;
  right: -13rem !important;
}

.tw--inset-x-56 {
  left: -14rem !important;
  right: -14rem !important;
}

.tw--inset-x-6 {
  left: -1.5rem !important;
  right: -1.5rem !important;
}

.tw--inset-x-60 {
  left: -15rem !important;
  right: -15rem !important;
}

.tw--inset-x-64 {
  left: -16rem !important;
  right: -16rem !important;
}

.tw--inset-x-7 {
  left: -1.75rem !important;
  right: -1.75rem !important;
}

.tw--inset-x-72 {
  left: -18rem !important;
  right: -18rem !important;
}

.tw--inset-x-8 {
  left: -2rem !important;
  right: -2rem !important;
}

.tw--inset-x-80 {
  left: -20rem !important;
  right: -20rem !important;
}

.tw--inset-x-9 {
  left: -2.25rem !important;
  right: -2.25rem !important;
}

.tw--inset-x-96 {
  left: -24rem !important;
  right: -24rem !important;
}

.tw--inset-x-full {
  left: -100% !important;
  right: -100% !important;
}

.tw--inset-x-px {
  left: -1px !important;
  right: -1px !important;
}

.tw--inset-y-0 {
  top: -0px !important;
  bottom: -0px !important;
}

.tw--inset-y-0\.5 {
  top: -0.125rem !important;
  bottom: -0.125rem !important;
}

.tw--inset-y-1 {
  top: -0.25rem !important;
  bottom: -0.25rem !important;
}

.tw--inset-y-1\.5 {
  top: -0.375rem !important;
  bottom: -0.375rem !important;
}

.tw--inset-y-1\/2 {
  top: -50% !important;
  bottom: -50% !important;
}

.tw--inset-y-1\/3 {
  top: -33.333333% !important;
  bottom: -33.333333% !important;
}

.tw--inset-y-1\/4 {
  top: -25% !important;
  bottom: -25% !important;
}

.tw--inset-y-10 {
  top: -2.5rem !important;
  bottom: -2.5rem !important;
}

.tw--inset-y-11 {
  top: -2.75rem !important;
  bottom: -2.75rem !important;
}

.tw--inset-y-12 {
  top: -3rem !important;
  bottom: -3rem !important;
}

.tw--inset-y-14 {
  top: -3.5rem !important;
  bottom: -3.5rem !important;
}

.tw--inset-y-16 {
  top: -4rem !important;
  bottom: -4rem !important;
}

.tw--inset-y-2 {
  top: -0.5rem !important;
  bottom: -0.5rem !important;
}

.tw--inset-y-2\.5 {
  top: -0.625rem !important;
  bottom: -0.625rem !important;
}

.tw--inset-y-2\/3 {
  top: -66.666667% !important;
  bottom: -66.666667% !important;
}

.tw--inset-y-2\/4 {
  top: -50% !important;
  bottom: -50% !important;
}

.tw--inset-y-20 {
  top: -5rem !important;
  bottom: -5rem !important;
}

.tw--inset-y-24 {
  top: -6rem !important;
  bottom: -6rem !important;
}

.tw--inset-y-28 {
  top: -7rem !important;
  bottom: -7rem !important;
}

.tw--inset-y-3 {
  top: -0.75rem !important;
  bottom: -0.75rem !important;
}

.tw--inset-y-3\.5 {
  top: -0.875rem !important;
  bottom: -0.875rem !important;
}

.tw--inset-y-3\/4 {
  top: -75% !important;
  bottom: -75% !important;
}

.tw--inset-y-32 {
  top: -8rem !important;
  bottom: -8rem !important;
}

.tw--inset-y-36 {
  top: -9rem !important;
  bottom: -9rem !important;
}

.tw--inset-y-4 {
  top: -1rem !important;
  bottom: -1rem !important;
}

.tw--inset-y-40 {
  top: -10rem !important;
  bottom: -10rem !important;
}

.tw--inset-y-44 {
  top: -11rem !important;
  bottom: -11rem !important;
}

.tw--inset-y-48 {
  top: -12rem !important;
  bottom: -12rem !important;
}

.tw--inset-y-5 {
  top: -1.25rem !important;
  bottom: -1.25rem !important;
}

.tw--inset-y-52 {
  top: -13rem !important;
  bottom: -13rem !important;
}

.tw--inset-y-56 {
  top: -14rem !important;
  bottom: -14rem !important;
}

.tw--inset-y-6 {
  top: -1.5rem !important;
  bottom: -1.5rem !important;
}

.tw--inset-y-60 {
  top: -15rem !important;
  bottom: -15rem !important;
}

.tw--inset-y-64 {
  top: -16rem !important;
  bottom: -16rem !important;
}

.tw--inset-y-7 {
  top: -1.75rem !important;
  bottom: -1.75rem !important;
}

.tw--inset-y-72 {
  top: -18rem !important;
  bottom: -18rem !important;
}

.tw--inset-y-8 {
  top: -2rem !important;
  bottom: -2rem !important;
}

.tw--inset-y-80 {
  top: -20rem !important;
  bottom: -20rem !important;
}

.tw--inset-y-9 {
  top: -2.25rem !important;
  bottom: -2.25rem !important;
}

.tw--inset-y-96 {
  top: -24rem !important;
  bottom: -24rem !important;
}

.tw--inset-y-full {
  top: -100% !important;
  bottom: -100% !important;
}

.tw--inset-y-px {
  top: -1px !important;
  bottom: -1px !important;
}

.tw-inset-x-0 {
  left: 0px !important;
  right: 0px !important;
}

.tw-inset-x-0\.5 {
  left: 0.125rem !important;
  right: 0.125rem !important;
}

.tw-inset-x-1 {
  left: 0.25rem !important;
  right: 0.25rem !important;
}

.tw-inset-x-1\.5 {
  left: 0.375rem !important;
  right: 0.375rem !important;
}

.tw-inset-x-1\/2 {
  left: 50% !important;
  right: 50% !important;
}

.tw-inset-x-1\/3 {
  left: 33.333333% !important;
  right: 33.333333% !important;
}

.tw-inset-x-1\/4 {
  left: 25% !important;
  right: 25% !important;
}

.tw-inset-x-10 {
  left: 2.5rem !important;
  right: 2.5rem !important;
}

.tw-inset-x-11 {
  left: 2.75rem !important;
  right: 2.75rem !important;
}

.tw-inset-x-12 {
  left: 3rem !important;
  right: 3rem !important;
}

.tw-inset-x-14 {
  left: 3.5rem !important;
  right: 3.5rem !important;
}

.tw-inset-x-16 {
  left: 4rem !important;
  right: 4rem !important;
}

.tw-inset-x-2 {
  left: 0.5rem !important;
  right: 0.5rem !important;
}

.tw-inset-x-2\.5 {
  left: 0.625rem !important;
  right: 0.625rem !important;
}

.tw-inset-x-2\/3 {
  left: 66.666667% !important;
  right: 66.666667% !important;
}

.tw-inset-x-2\/4 {
  left: 50% !important;
  right: 50% !important;
}

.tw-inset-x-20 {
  left: 5rem !important;
  right: 5rem !important;
}

.tw-inset-x-24 {
  left: 6rem !important;
  right: 6rem !important;
}

.tw-inset-x-28 {
  left: 7rem !important;
  right: 7rem !important;
}

.tw-inset-x-3 {
  left: 0.75rem !important;
  right: 0.75rem !important;
}

.tw-inset-x-3\.5 {
  left: 0.875rem !important;
  right: 0.875rem !important;
}

.tw-inset-x-3\/4 {
  left: 75% !important;
  right: 75% !important;
}

.tw-inset-x-32 {
  left: 8rem !important;
  right: 8rem !important;
}

.tw-inset-x-36 {
  left: 9rem !important;
  right: 9rem !important;
}

.tw-inset-x-4 {
  left: 1rem !important;
  right: 1rem !important;
}

.tw-inset-x-40 {
  left: 10rem !important;
  right: 10rem !important;
}

.tw-inset-x-44 {
  left: 11rem !important;
  right: 11rem !important;
}

.tw-inset-x-48 {
  left: 12rem !important;
  right: 12rem !important;
}

.tw-inset-x-5 {
  left: 1.25rem !important;
  right: 1.25rem !important;
}

.tw-inset-x-52 {
  left: 13rem !important;
  right: 13rem !important;
}

.tw-inset-x-56 {
  left: 14rem !important;
  right: 14rem !important;
}

.tw-inset-x-6 {
  left: 1.5rem !important;
  right: 1.5rem !important;
}

.tw-inset-x-60 {
  left: 15rem !important;
  right: 15rem !important;
}

.tw-inset-x-64 {
  left: 16rem !important;
  right: 16rem !important;
}

.tw-inset-x-7 {
  left: 1.75rem !important;
  right: 1.75rem !important;
}

.tw-inset-x-72 {
  left: 18rem !important;
  right: 18rem !important;
}

.tw-inset-x-8 {
  left: 2rem !important;
  right: 2rem !important;
}

.tw-inset-x-80 {
  left: 20rem !important;
  right: 20rem !important;
}

.tw-inset-x-9 {
  left: 2.25rem !important;
  right: 2.25rem !important;
}

.tw-inset-x-96 {
  left: 24rem !important;
  right: 24rem !important;
}

.tw-inset-x-auto {
  left: auto !important;
  right: auto !important;
}

.tw-inset-x-full {
  left: 100% !important;
  right: 100% !important;
}

.tw-inset-x-px {
  left: 1px !important;
  right: 1px !important;
}

.tw-inset-y-0 {
  top: 0px !important;
  bottom: 0px !important;
}

.tw-inset-y-0\.5 {
  top: 0.125rem !important;
  bottom: 0.125rem !important;
}

.tw-inset-y-1 {
  top: 0.25rem !important;
  bottom: 0.25rem !important;
}

.tw-inset-y-1\.5 {
  top: 0.375rem !important;
  bottom: 0.375rem !important;
}

.tw-inset-y-1\/2 {
  top: 50% !important;
  bottom: 50% !important;
}

.tw-inset-y-1\/3 {
  top: 33.333333% !important;
  bottom: 33.333333% !important;
}

.tw-inset-y-1\/4 {
  top: 25% !important;
  bottom: 25% !important;
}

.tw-inset-y-10 {
  top: 2.5rem !important;
  bottom: 2.5rem !important;
}

.tw-inset-y-11 {
  top: 2.75rem !important;
  bottom: 2.75rem !important;
}

.tw-inset-y-12 {
  top: 3rem !important;
  bottom: 3rem !important;
}

.tw-inset-y-14 {
  top: 3.5rem !important;
  bottom: 3.5rem !important;
}

.tw-inset-y-16 {
  top: 4rem !important;
  bottom: 4rem !important;
}

.tw-inset-y-2 {
  top: 0.5rem !important;
  bottom: 0.5rem !important;
}

.tw-inset-y-2\.5 {
  top: 0.625rem !important;
  bottom: 0.625rem !important;
}

.tw-inset-y-2\/3 {
  top: 66.666667% !important;
  bottom: 66.666667% !important;
}

.tw-inset-y-2\/4 {
  top: 50% !important;
  bottom: 50% !important;
}

.tw-inset-y-20 {
  top: 5rem !important;
  bottom: 5rem !important;
}

.tw-inset-y-24 {
  top: 6rem !important;
  bottom: 6rem !important;
}

.tw-inset-y-28 {
  top: 7rem !important;
  bottom: 7rem !important;
}

.tw-inset-y-3 {
  top: 0.75rem !important;
  bottom: 0.75rem !important;
}

.tw-inset-y-3\.5 {
  top: 0.875rem !important;
  bottom: 0.875rem !important;
}

.tw-inset-y-3\/4 {
  top: 75% !important;
  bottom: 75% !important;
}

.tw-inset-y-32 {
  top: 8rem !important;
  bottom: 8rem !important;
}

.tw-inset-y-36 {
  top: 9rem !important;
  bottom: 9rem !important;
}

.tw-inset-y-4 {
  top: 1rem !important;
  bottom: 1rem !important;
}

.tw-inset-y-40 {
  top: 10rem !important;
  bottom: 10rem !important;
}

.tw-inset-y-44 {
  top: 11rem !important;
  bottom: 11rem !important;
}

.tw-inset-y-48 {
  top: 12rem !important;
  bottom: 12rem !important;
}

.tw-inset-y-5 {
  top: 1.25rem !important;
  bottom: 1.25rem !important;
}

.tw-inset-y-52 {
  top: 13rem !important;
  bottom: 13rem !important;
}

.tw-inset-y-56 {
  top: 14rem !important;
  bottom: 14rem !important;
}

.tw-inset-y-6 {
  top: 1.5rem !important;
  bottom: 1.5rem !important;
}

.tw-inset-y-60 {
  top: 15rem !important;
  bottom: 15rem !important;
}

.tw-inset-y-64 {
  top: 16rem !important;
  bottom: 16rem !important;
}

.tw-inset-y-7 {
  top: 1.75rem !important;
  bottom: 1.75rem !important;
}

.tw-inset-y-72 {
  top: 18rem !important;
  bottom: 18rem !important;
}

.tw-inset-y-8 {
  top: 2rem !important;
  bottom: 2rem !important;
}

.tw-inset-y-80 {
  top: 20rem !important;
  bottom: 20rem !important;
}

.tw-inset-y-9 {
  top: 2.25rem !important;
  bottom: 2.25rem !important;
}

.tw-inset-y-96 {
  top: 24rem !important;
  bottom: 24rem !important;
}

.tw-inset-y-auto {
  top: auto !important;
  bottom: auto !important;
}

.tw-inset-y-full {
  top: 100% !important;
  bottom: 100% !important;
}

.tw-inset-y-px {
  top: 1px !important;
  bottom: 1px !important;
}

.-tw-bottom-0 {
  bottom: -0px !important;
}

.-tw-bottom-0\.5 {
  bottom: -0.125rem !important;
}

.-tw-bottom-1 {
  bottom: -0.25rem !important;
}

.-tw-bottom-1\.5 {
  bottom: -0.375rem !important;
}

.-tw-bottom-1\/2 {
  bottom: -50% !important;
}

.-tw-bottom-1\/3 {
  bottom: -33.333333% !important;
}

.-tw-bottom-1\/4 {
  bottom: -25% !important;
}

.-tw-bottom-10 {
  bottom: -2.5rem !important;
}

.-tw-bottom-11 {
  bottom: -2.75rem !important;
}

.-tw-bottom-12 {
  bottom: -3rem !important;
}

.-tw-bottom-14 {
  bottom: -3.5rem !important;
}

.-tw-bottom-16 {
  bottom: -4rem !important;
}

.-tw-bottom-2 {
  bottom: -0.5rem !important;
}

.-tw-bottom-2\.5 {
  bottom: -0.625rem !important;
}

.-tw-bottom-2\/3 {
  bottom: -66.666667% !important;
}

.-tw-bottom-2\/4 {
  bottom: -50% !important;
}

.-tw-bottom-20 {
  bottom: -5rem !important;
}

.-tw-bottom-24 {
  bottom: -6rem !important;
}

.-tw-bottom-28 {
  bottom: -7rem !important;
}

.-tw-bottom-3 {
  bottom: -0.75rem !important;
}

.-tw-bottom-3\.5 {
  bottom: -0.875rem !important;
}

.-tw-bottom-3\/4 {
  bottom: -75% !important;
}

.-tw-bottom-32 {
  bottom: -8rem !important;
}

.-tw-bottom-36 {
  bottom: -9rem !important;
}

.-tw-bottom-4 {
  bottom: -1rem !important;
}

.-tw-bottom-40 {
  bottom: -10rem !important;
}

.-tw-bottom-44 {
  bottom: -11rem !important;
}

.-tw-bottom-48 {
  bottom: -12rem !important;
}

.-tw-bottom-5 {
  bottom: -1.25rem !important;
}

.-tw-bottom-52 {
  bottom: -13rem !important;
}

.-tw-bottom-56 {
  bottom: -14rem !important;
}

.-tw-bottom-6 {
  bottom: -1.5rem !important;
}

.-tw-bottom-60 {
  bottom: -15rem !important;
}

.-tw-bottom-64 {
  bottom: -16rem !important;
}

.-tw-bottom-7 {
  bottom: -1.75rem !important;
}

.-tw-bottom-72 {
  bottom: -18rem !important;
}

.-tw-bottom-8 {
  bottom: -2rem !important;
}

.-tw-bottom-80 {
  bottom: -20rem !important;
}

.-tw-bottom-9 {
  bottom: -2.25rem !important;
}

.-tw-bottom-96 {
  bottom: -24rem !important;
}

.-tw-bottom-full {
  bottom: -100% !important;
}

.-tw-bottom-px {
  bottom: -1px !important;
}

.-tw-end-0 {
  inset-inline-end: -0px !important;
}

.-tw-end-0\.5 {
  inset-inline-end: -0.125rem !important;
}

.-tw-end-1 {
  inset-inline-end: -0.25rem !important;
}

.-tw-end-1\.5 {
  inset-inline-end: -0.375rem !important;
}

.-tw-end-1\/2 {
  inset-inline-end: -50% !important;
}

.-tw-end-1\/3 {
  inset-inline-end: -33.333333% !important;
}

.-tw-end-1\/4 {
  inset-inline-end: -25% !important;
}

.-tw-end-10 {
  inset-inline-end: -2.5rem !important;
}

.-tw-end-11 {
  inset-inline-end: -2.75rem !important;
}

.-tw-end-12 {
  inset-inline-end: -3rem !important;
}

.-tw-end-14 {
  inset-inline-end: -3.5rem !important;
}

.-tw-end-16 {
  inset-inline-end: -4rem !important;
}

.-tw-end-2 {
  inset-inline-end: -0.5rem !important;
}

.-tw-end-2\.5 {
  inset-inline-end: -0.625rem !important;
}

.-tw-end-2\/3 {
  inset-inline-end: -66.666667% !important;
}

.-tw-end-2\/4 {
  inset-inline-end: -50% !important;
}

.-tw-end-20 {
  inset-inline-end: -5rem !important;
}

.-tw-end-24 {
  inset-inline-end: -6rem !important;
}

.-tw-end-28 {
  inset-inline-end: -7rem !important;
}

.-tw-end-3 {
  inset-inline-end: -0.75rem !important;
}

.-tw-end-3\.5 {
  inset-inline-end: -0.875rem !important;
}

.-tw-end-3\/4 {
  inset-inline-end: -75% !important;
}

.-tw-end-32 {
  inset-inline-end: -8rem !important;
}

.-tw-end-36 {
  inset-inline-end: -9rem !important;
}

.-tw-end-4 {
  inset-inline-end: -1rem !important;
}

.-tw-end-40 {
  inset-inline-end: -10rem !important;
}

.-tw-end-44 {
  inset-inline-end: -11rem !important;
}

.-tw-end-48 {
  inset-inline-end: -12rem !important;
}

.-tw-end-5 {
  inset-inline-end: -1.25rem !important;
}

.-tw-end-52 {
  inset-inline-end: -13rem !important;
}

.-tw-end-56 {
  inset-inline-end: -14rem !important;
}

.-tw-end-6 {
  inset-inline-end: -1.5rem !important;
}

.-tw-end-60 {
  inset-inline-end: -15rem !important;
}

.-tw-end-64 {
  inset-inline-end: -16rem !important;
}

.-tw-end-7 {
  inset-inline-end: -1.75rem !important;
}

.-tw-end-72 {
  inset-inline-end: -18rem !important;
}

.-tw-end-8 {
  inset-inline-end: -2rem !important;
}

.-tw-end-80 {
  inset-inline-end: -20rem !important;
}

.-tw-end-9 {
  inset-inline-end: -2.25rem !important;
}

.-tw-end-96 {
  inset-inline-end: -24rem !important;
}

.-tw-end-full {
  inset-inline-end: -100% !important;
}

.-tw-end-px {
  inset-inline-end: -1px !important;
}

.-tw-left-0 {
  left: -0px !important;
}

.-tw-left-0\.5 {
  left: -0.125rem !important;
}

.-tw-left-1 {
  left: -0.25rem !important;
}

.-tw-left-1\.5 {
  left: -0.375rem !important;
}

.-tw-left-1\/2 {
  left: -50% !important;
}

.-tw-left-1\/3 {
  left: -33.333333% !important;
}

.-tw-left-1\/4 {
  left: -25% !important;
}

.-tw-left-10 {
  left: -2.5rem !important;
}

.-tw-left-11 {
  left: -2.75rem !important;
}

.-tw-left-12 {
  left: -3rem !important;
}

.-tw-left-14 {
  left: -3.5rem !important;
}

.-tw-left-16 {
  left: -4rem !important;
}

.-tw-left-2 {
  left: -0.5rem !important;
}

.-tw-left-2\.5 {
  left: -0.625rem !important;
}

.-tw-left-2\/3 {
  left: -66.666667% !important;
}

.-tw-left-2\/4 {
  left: -50% !important;
}

.-tw-left-20 {
  left: -5rem !important;
}

.-tw-left-24 {
  left: -6rem !important;
}

.-tw-left-28 {
  left: -7rem !important;
}

.-tw-left-3 {
  left: -0.75rem !important;
}

.-tw-left-3\.5 {
  left: -0.875rem !important;
}

.-tw-left-3\/4 {
  left: -75% !important;
}

.-tw-left-32 {
  left: -8rem !important;
}

.-tw-left-36 {
  left: -9rem !important;
}

.-tw-left-4 {
  left: -1rem !important;
}

.-tw-left-40 {
  left: -10rem !important;
}

.-tw-left-44 {
  left: -11rem !important;
}

.-tw-left-48 {
  left: -12rem !important;
}

.-tw-left-5 {
  left: -1.25rem !important;
}

.-tw-left-52 {
  left: -13rem !important;
}

.-tw-left-56 {
  left: -14rem !important;
}

.-tw-left-6 {
  left: -1.5rem !important;
}

.-tw-left-60 {
  left: -15rem !important;
}

.-tw-left-64 {
  left: -16rem !important;
}

.-tw-left-7 {
  left: -1.75rem !important;
}

.-tw-left-72 {
  left: -18rem !important;
}

.-tw-left-8 {
  left: -2rem !important;
}

.-tw-left-80 {
  left: -20rem !important;
}

.-tw-left-9 {
  left: -2.25rem !important;
}

.-tw-left-96 {
  left: -24rem !important;
}

.-tw-left-full {
  left: -100% !important;
}

.-tw-left-px {
  left: -1px !important;
}

.-tw-right-0 {
  right: -0px !important;
}

.-tw-right-0\.5 {
  right: -0.125rem !important;
}

.-tw-right-1 {
  right: -0.25rem !important;
}

.-tw-right-1\.5 {
  right: -0.375rem !important;
}

.-tw-right-1\/2 {
  right: -50% !important;
}

.-tw-right-1\/3 {
  right: -33.333333% !important;
}

.-tw-right-1\/4 {
  right: -25% !important;
}

.-tw-right-10 {
  right: -2.5rem !important;
}

.-tw-right-11 {
  right: -2.75rem !important;
}

.-tw-right-12 {
  right: -3rem !important;
}

.-tw-right-14 {
  right: -3.5rem !important;
}

.-tw-right-16 {
  right: -4rem !important;
}

.-tw-right-2 {
  right: -0.5rem !important;
}

.-tw-right-2\.5 {
  right: -0.625rem !important;
}

.-tw-right-2\/3 {
  right: -66.666667% !important;
}

.-tw-right-2\/4 {
  right: -50% !important;
}

.-tw-right-20 {
  right: -5rem !important;
}

.-tw-right-24 {
  right: -6rem !important;
}

.-tw-right-28 {
  right: -7rem !important;
}

.-tw-right-3 {
  right: -0.75rem !important;
}

.-tw-right-3\.5 {
  right: -0.875rem !important;
}

.-tw-right-3\/4 {
  right: -75% !important;
}

.-tw-right-32 {
  right: -8rem !important;
}

.-tw-right-36 {
  right: -9rem !important;
}

.-tw-right-4 {
  right: -1rem !important;
}

.-tw-right-40 {
  right: -10rem !important;
}

.-tw-right-44 {
  right: -11rem !important;
}

.-tw-right-48 {
  right: -12rem !important;
}

.-tw-right-5 {
  right: -1.25rem !important;
}

.-tw-right-52 {
  right: -13rem !important;
}

.-tw-right-56 {
  right: -14rem !important;
}

.-tw-right-6 {
  right: -1.5rem !important;
}

.-tw-right-60 {
  right: -15rem !important;
}

.-tw-right-64 {
  right: -16rem !important;
}

.-tw-right-7 {
  right: -1.75rem !important;
}

.-tw-right-72 {
  right: -18rem !important;
}

.-tw-right-8 {
  right: -2rem !important;
}

.-tw-right-80 {
  right: -20rem !important;
}

.-tw-right-9 {
  right: -2.25rem !important;
}

.-tw-right-96 {
  right: -24rem !important;
}

.-tw-right-full {
  right: -100% !important;
}

.-tw-right-px {
  right: -1px !important;
}

.-tw-start-0 {
  inset-inline-start: -0px !important;
}

.-tw-start-0\.5 {
  inset-inline-start: -0.125rem !important;
}

.-tw-start-1 {
  inset-inline-start: -0.25rem !important;
}

.-tw-start-1\.5 {
  inset-inline-start: -0.375rem !important;
}

.-tw-start-1\/2 {
  inset-inline-start: -50% !important;
}

.-tw-start-1\/3 {
  inset-inline-start: -33.333333% !important;
}

.-tw-start-1\/4 {
  inset-inline-start: -25% !important;
}

.-tw-start-10 {
  inset-inline-start: -2.5rem !important;
}

.-tw-start-11 {
  inset-inline-start: -2.75rem !important;
}

.-tw-start-12 {
  inset-inline-start: -3rem !important;
}

.-tw-start-14 {
  inset-inline-start: -3.5rem !important;
}

.-tw-start-16 {
  inset-inline-start: -4rem !important;
}

.-tw-start-2 {
  inset-inline-start: -0.5rem !important;
}

.-tw-start-2\.5 {
  inset-inline-start: -0.625rem !important;
}

.-tw-start-2\/3 {
  inset-inline-start: -66.666667% !important;
}

.-tw-start-2\/4 {
  inset-inline-start: -50% !important;
}

.-tw-start-20 {
  inset-inline-start: -5rem !important;
}

.-tw-start-24 {
  inset-inline-start: -6rem !important;
}

.-tw-start-28 {
  inset-inline-start: -7rem !important;
}

.-tw-start-3 {
  inset-inline-start: -0.75rem !important;
}

.-tw-start-3\.5 {
  inset-inline-start: -0.875rem !important;
}

.-tw-start-3\/4 {
  inset-inline-start: -75% !important;
}

.-tw-start-32 {
  inset-inline-start: -8rem !important;
}

.-tw-start-36 {
  inset-inline-start: -9rem !important;
}

.-tw-start-4 {
  inset-inline-start: -1rem !important;
}

.-tw-start-40 {
  inset-inline-start: -10rem !important;
}

.-tw-start-44 {
  inset-inline-start: -11rem !important;
}

.-tw-start-48 {
  inset-inline-start: -12rem !important;
}

.-tw-start-5 {
  inset-inline-start: -1.25rem !important;
}

.-tw-start-52 {
  inset-inline-start: -13rem !important;
}

.-tw-start-56 {
  inset-inline-start: -14rem !important;
}

.-tw-start-6 {
  inset-inline-start: -1.5rem !important;
}

.-tw-start-60 {
  inset-inline-start: -15rem !important;
}

.-tw-start-64 {
  inset-inline-start: -16rem !important;
}

.-tw-start-7 {
  inset-inline-start: -1.75rem !important;
}

.-tw-start-72 {
  inset-inline-start: -18rem !important;
}

.-tw-start-8 {
  inset-inline-start: -2rem !important;
}

.-tw-start-80 {
  inset-inline-start: -20rem !important;
}

.-tw-start-9 {
  inset-inline-start: -2.25rem !important;
}

.-tw-start-96 {
  inset-inline-start: -24rem !important;
}

.-tw-start-full {
  inset-inline-start: -100% !important;
}

.-tw-start-px {
  inset-inline-start: -1px !important;
}

.-tw-top-0 {
  top: -0px !important;
}

.-tw-top-0\.5 {
  top: -0.125rem !important;
}

.-tw-top-1 {
  top: -0.25rem !important;
}

.-tw-top-1\.5 {
  top: -0.375rem !important;
}

.-tw-top-1\/2 {
  top: -50% !important;
}

.-tw-top-1\/3 {
  top: -33.333333% !important;
}

.-tw-top-1\/4 {
  top: -25% !important;
}

.-tw-top-10 {
  top: -2.5rem !important;
}

.-tw-top-11 {
  top: -2.75rem !important;
}

.-tw-top-12 {
  top: -3rem !important;
}

.-tw-top-14 {
  top: -3.5rem !important;
}

.-tw-top-16 {
  top: -4rem !important;
}

.-tw-top-2 {
  top: -0.5rem !important;
}

.-tw-top-2\.5 {
  top: -0.625rem !important;
}

.-tw-top-2\/3 {
  top: -66.666667% !important;
}

.-tw-top-2\/4 {
  top: -50% !important;
}

.-tw-top-20 {
  top: -5rem !important;
}

.-tw-top-24 {
  top: -6rem !important;
}

.-tw-top-28 {
  top: -7rem !important;
}

.-tw-top-3 {
  top: -0.75rem !important;
}

.-tw-top-3\.5 {
  top: -0.875rem !important;
}

.-tw-top-3\/4 {
  top: -75% !important;
}

.-tw-top-32 {
  top: -8rem !important;
}

.-tw-top-36 {
  top: -9rem !important;
}

.-tw-top-4 {
  top: -1rem !important;
}

.-tw-top-40 {
  top: -10rem !important;
}

.-tw-top-44 {
  top: -11rem !important;
}

.-tw-top-48 {
  top: -12rem !important;
}

.-tw-top-5 {
  top: -1.25rem !important;
}

.-tw-top-52 {
  top: -13rem !important;
}

.-tw-top-56 {
  top: -14rem !important;
}

.-tw-top-6 {
  top: -1.5rem !important;
}

.-tw-top-60 {
  top: -15rem !important;
}

.-tw-top-64 {
  top: -16rem !important;
}

.-tw-top-7 {
  top: -1.75rem !important;
}

.-tw-top-72 {
  top: -18rem !important;
}

.-tw-top-8 {
  top: -2rem !important;
}

.-tw-top-80 {
  top: -20rem !important;
}

.-tw-top-9 {
  top: -2.25rem !important;
}

.-tw-top-96 {
  top: -24rem !important;
}

.-tw-top-full {
  top: -100% !important;
}

.-tw-top-px {
  top: -1px !important;
}

.tw--bottom-0 {
  bottom: -0px !important;
}

.tw--bottom-0\.5 {
  bottom: -0.125rem !important;
}

.tw--bottom-1 {
  bottom: -0.25rem !important;
}

.tw--bottom-1\.5 {
  bottom: -0.375rem !important;
}

.tw--bottom-1\/2 {
  bottom: -50% !important;
}

.tw--bottom-1\/3 {
  bottom: -33.333333% !important;
}

.tw--bottom-1\/4 {
  bottom: -25% !important;
}

.tw--bottom-10 {
  bottom: -2.5rem !important;
}

.tw--bottom-11 {
  bottom: -2.75rem !important;
}

.tw--bottom-12 {
  bottom: -3rem !important;
}

.tw--bottom-14 {
  bottom: -3.5rem !important;
}

.tw--bottom-16 {
  bottom: -4rem !important;
}

.tw--bottom-2 {
  bottom: -0.5rem !important;
}

.tw--bottom-2\.5 {
  bottom: -0.625rem !important;
}

.tw--bottom-2\/3 {
  bottom: -66.666667% !important;
}

.tw--bottom-2\/4 {
  bottom: -50% !important;
}

.tw--bottom-20 {
  bottom: -5rem !important;
}

.tw--bottom-24 {
  bottom: -6rem !important;
}

.tw--bottom-28 {
  bottom: -7rem !important;
}

.tw--bottom-3 {
  bottom: -0.75rem !important;
}

.tw--bottom-3\.5 {
  bottom: -0.875rem !important;
}

.tw--bottom-3\/4 {
  bottom: -75% !important;
}

.tw--bottom-32 {
  bottom: -8rem !important;
}

.tw--bottom-36 {
  bottom: -9rem !important;
}

.tw--bottom-4 {
  bottom: -1rem !important;
}

.tw--bottom-40 {
  bottom: -10rem !important;
}

.tw--bottom-44 {
  bottom: -11rem !important;
}

.tw--bottom-48 {
  bottom: -12rem !important;
}

.tw--bottom-5 {
  bottom: -1.25rem !important;
}

.tw--bottom-52 {
  bottom: -13rem !important;
}

.tw--bottom-56 {
  bottom: -14rem !important;
}

.tw--bottom-6 {
  bottom: -1.5rem !important;
}

.tw--bottom-60 {
  bottom: -15rem !important;
}

.tw--bottom-64 {
  bottom: -16rem !important;
}

.tw--bottom-7 {
  bottom: -1.75rem !important;
}

.tw--bottom-72 {
  bottom: -18rem !important;
}

.tw--bottom-8 {
  bottom: -2rem !important;
}

.tw--bottom-80 {
  bottom: -20rem !important;
}

.tw--bottom-9 {
  bottom: -2.25rem !important;
}

.tw--bottom-96 {
  bottom: -24rem !important;
}

.tw--bottom-full {
  bottom: -100% !important;
}

.tw--bottom-px {
  bottom: -1px !important;
}

.tw--end-0 {
  inset-inline-end: -0px !important;
}

.tw--end-0\.5 {
  inset-inline-end: -0.125rem !important;
}

.tw--end-1 {
  inset-inline-end: -0.25rem !important;
}

.tw--end-1\.5 {
  inset-inline-end: -0.375rem !important;
}

.tw--end-1\/2 {
  inset-inline-end: -50% !important;
}

.tw--end-1\/3 {
  inset-inline-end: -33.333333% !important;
}

.tw--end-1\/4 {
  inset-inline-end: -25% !important;
}

.tw--end-10 {
  inset-inline-end: -2.5rem !important;
}

.tw--end-11 {
  inset-inline-end: -2.75rem !important;
}

.tw--end-12 {
  inset-inline-end: -3rem !important;
}

.tw--end-14 {
  inset-inline-end: -3.5rem !important;
}

.tw--end-16 {
  inset-inline-end: -4rem !important;
}

.tw--end-2 {
  inset-inline-end: -0.5rem !important;
}

.tw--end-2\.5 {
  inset-inline-end: -0.625rem !important;
}

.tw--end-2\/3 {
  inset-inline-end: -66.666667% !important;
}

.tw--end-2\/4 {
  inset-inline-end: -50% !important;
}

.tw--end-20 {
  inset-inline-end: -5rem !important;
}

.tw--end-24 {
  inset-inline-end: -6rem !important;
}

.tw--end-28 {
  inset-inline-end: -7rem !important;
}

.tw--end-3 {
  inset-inline-end: -0.75rem !important;
}

.tw--end-3\.5 {
  inset-inline-end: -0.875rem !important;
}

.tw--end-3\/4 {
  inset-inline-end: -75% !important;
}

.tw--end-32 {
  inset-inline-end: -8rem !important;
}

.tw--end-36 {
  inset-inline-end: -9rem !important;
}

.tw--end-4 {
  inset-inline-end: -1rem !important;
}

.tw--end-40 {
  inset-inline-end: -10rem !important;
}

.tw--end-44 {
  inset-inline-end: -11rem !important;
}

.tw--end-48 {
  inset-inline-end: -12rem !important;
}

.tw--end-5 {
  inset-inline-end: -1.25rem !important;
}

.tw--end-52 {
  inset-inline-end: -13rem !important;
}

.tw--end-56 {
  inset-inline-end: -14rem !important;
}

.tw--end-6 {
  inset-inline-end: -1.5rem !important;
}

.tw--end-60 {
  inset-inline-end: -15rem !important;
}

.tw--end-64 {
  inset-inline-end: -16rem !important;
}

.tw--end-7 {
  inset-inline-end: -1.75rem !important;
}

.tw--end-72 {
  inset-inline-end: -18rem !important;
}

.tw--end-8 {
  inset-inline-end: -2rem !important;
}

.tw--end-80 {
  inset-inline-end: -20rem !important;
}

.tw--end-9 {
  inset-inline-end: -2.25rem !important;
}

.tw--end-96 {
  inset-inline-end: -24rem !important;
}

.tw--end-full {
  inset-inline-end: -100% !important;
}

.tw--end-px {
  inset-inline-end: -1px !important;
}

.tw--left-0 {
  left: -0px !important;
}

.tw--left-0\.5 {
  left: -0.125rem !important;
}

.tw--left-1 {
  left: -0.25rem !important;
}

.tw--left-1\.5 {
  left: -0.375rem !important;
}

.tw--left-1\/2 {
  left: -50% !important;
}

.tw--left-1\/3 {
  left: -33.333333% !important;
}

.tw--left-1\/4 {
  left: -25% !important;
}

.tw--left-10 {
  left: -2.5rem !important;
}

.tw--left-11 {
  left: -2.75rem !important;
}

.tw--left-12 {
  left: -3rem !important;
}

.tw--left-14 {
  left: -3.5rem !important;
}

.tw--left-16 {
  left: -4rem !important;
}

.tw--left-2 {
  left: -0.5rem !important;
}

.tw--left-2\.5 {
  left: -0.625rem !important;
}

.tw--left-2\/3 {
  left: -66.666667% !important;
}

.tw--left-2\/4 {
  left: -50% !important;
}

.tw--left-20 {
  left: -5rem !important;
}

.tw--left-24 {
  left: -6rem !important;
}

.tw--left-28 {
  left: -7rem !important;
}

.tw--left-3 {
  left: -0.75rem !important;
}

.tw--left-3\.5 {
  left: -0.875rem !important;
}

.tw--left-3\/4 {
  left: -75% !important;
}

.tw--left-32 {
  left: -8rem !important;
}

.tw--left-36 {
  left: -9rem !important;
}

.tw--left-4 {
  left: -1rem !important;
}

.tw--left-40 {
  left: -10rem !important;
}

.tw--left-44 {
  left: -11rem !important;
}

.tw--left-48 {
  left: -12rem !important;
}

.tw--left-5 {
  left: -1.25rem !important;
}

.tw--left-52 {
  left: -13rem !important;
}

.tw--left-56 {
  left: -14rem !important;
}

.tw--left-6 {
  left: -1.5rem !important;
}

.tw--left-60 {
  left: -15rem !important;
}

.tw--left-64 {
  left: -16rem !important;
}

.tw--left-7 {
  left: -1.75rem !important;
}

.tw--left-72 {
  left: -18rem !important;
}

.tw--left-8 {
  left: -2rem !important;
}

.tw--left-80 {
  left: -20rem !important;
}

.tw--left-9 {
  left: -2.25rem !important;
}

.tw--left-96 {
  left: -24rem !important;
}

.tw--left-full {
  left: -100% !important;
}

.tw--left-px {
  left: -1px !important;
}

.tw--right-0 {
  right: -0px !important;
}

.tw--right-0\.5 {
  right: -0.125rem !important;
}

.tw--right-1 {
  right: -0.25rem !important;
}

.tw--right-1\.5 {
  right: -0.375rem !important;
}

.tw--right-1\/2 {
  right: -50% !important;
}

.tw--right-1\/3 {
  right: -33.333333% !important;
}

.tw--right-1\/4 {
  right: -25% !important;
}

.tw--right-10 {
  right: -2.5rem !important;
}

.tw--right-11 {
  right: -2.75rem !important;
}

.tw--right-12 {
  right: -3rem !important;
}

.tw--right-14 {
  right: -3.5rem !important;
}

.tw--right-16 {
  right: -4rem !important;
}

.tw--right-2 {
  right: -0.5rem !important;
}

.tw--right-2\.5 {
  right: -0.625rem !important;
}

.tw--right-2\/3 {
  right: -66.666667% !important;
}

.tw--right-2\/4 {
  right: -50% !important;
}

.tw--right-20 {
  right: -5rem !important;
}

.tw--right-24 {
  right: -6rem !important;
}

.tw--right-28 {
  right: -7rem !important;
}

.tw--right-3 {
  right: -0.75rem !important;
}

.tw--right-3\.5 {
  right: -0.875rem !important;
}

.tw--right-3\/4 {
  right: -75% !important;
}

.tw--right-32 {
  right: -8rem !important;
}

.tw--right-36 {
  right: -9rem !important;
}

.tw--right-4 {
  right: -1rem !important;
}

.tw--right-40 {
  right: -10rem !important;
}

.tw--right-44 {
  right: -11rem !important;
}

.tw--right-48 {
  right: -12rem !important;
}

.tw--right-5 {
  right: -1.25rem !important;
}

.tw--right-52 {
  right: -13rem !important;
}

.tw--right-56 {
  right: -14rem !important;
}

.tw--right-6 {
  right: -1.5rem !important;
}

.tw--right-60 {
  right: -15rem !important;
}

.tw--right-64 {
  right: -16rem !important;
}

.tw--right-7 {
  right: -1.75rem !important;
}

.tw--right-72 {
  right: -18rem !important;
}

.tw--right-8 {
  right: -2rem !important;
}

.tw--right-80 {
  right: -20rem !important;
}

.tw--right-9 {
  right: -2.25rem !important;
}

.tw--right-96 {
  right: -24rem !important;
}

.tw--right-full {
  right: -100% !important;
}

.tw--right-px {
  right: -1px !important;
}

.tw--start-0 {
  inset-inline-start: -0px !important;
}

.tw--start-0\.5 {
  inset-inline-start: -0.125rem !important;
}

.tw--start-1 {
  inset-inline-start: -0.25rem !important;
}

.tw--start-1\.5 {
  inset-inline-start: -0.375rem !important;
}

.tw--start-1\/2 {
  inset-inline-start: -50% !important;
}

.tw--start-1\/3 {
  inset-inline-start: -33.333333% !important;
}

.tw--start-1\/4 {
  inset-inline-start: -25% !important;
}

.tw--start-10 {
  inset-inline-start: -2.5rem !important;
}

.tw--start-11 {
  inset-inline-start: -2.75rem !important;
}

.tw--start-12 {
  inset-inline-start: -3rem !important;
}

.tw--start-14 {
  inset-inline-start: -3.5rem !important;
}

.tw--start-16 {
  inset-inline-start: -4rem !important;
}

.tw--start-2 {
  inset-inline-start: -0.5rem !important;
}

.tw--start-2\.5 {
  inset-inline-start: -0.625rem !important;
}

.tw--start-2\/3 {
  inset-inline-start: -66.666667% !important;
}

.tw--start-2\/4 {
  inset-inline-start: -50% !important;
}

.tw--start-20 {
  inset-inline-start: -5rem !important;
}

.tw--start-24 {
  inset-inline-start: -6rem !important;
}

.tw--start-28 {
  inset-inline-start: -7rem !important;
}

.tw--start-3 {
  inset-inline-start: -0.75rem !important;
}

.tw--start-3\.5 {
  inset-inline-start: -0.875rem !important;
}

.tw--start-3\/4 {
  inset-inline-start: -75% !important;
}

.tw--start-32 {
  inset-inline-start: -8rem !important;
}

.tw--start-36 {
  inset-inline-start: -9rem !important;
}

.tw--start-4 {
  inset-inline-start: -1rem !important;
}

.tw--start-40 {
  inset-inline-start: -10rem !important;
}

.tw--start-44 {
  inset-inline-start: -11rem !important;
}

.tw--start-48 {
  inset-inline-start: -12rem !important;
}

.tw--start-5 {
  inset-inline-start: -1.25rem !important;
}

.tw--start-52 {
  inset-inline-start: -13rem !important;
}

.tw--start-56 {
  inset-inline-start: -14rem !important;
}

.tw--start-6 {
  inset-inline-start: -1.5rem !important;
}

.tw--start-60 {
  inset-inline-start: -15rem !important;
}

.tw--start-64 {
  inset-inline-start: -16rem !important;
}

.tw--start-7 {
  inset-inline-start: -1.75rem !important;
}

.tw--start-72 {
  inset-inline-start: -18rem !important;
}

.tw--start-8 {
  inset-inline-start: -2rem !important;
}

.tw--start-80 {
  inset-inline-start: -20rem !important;
}

.tw--start-9 {
  inset-inline-start: -2.25rem !important;
}

.tw--start-96 {
  inset-inline-start: -24rem !important;
}

.tw--start-full {
  inset-inline-start: -100% !important;
}

.tw--start-px {
  inset-inline-start: -1px !important;
}

.tw--top-0 {
  top: -0px !important;
}

.tw--top-0\.5 {
  top: -0.125rem !important;
}

.tw--top-1 {
  top: -0.25rem !important;
}

.tw--top-1\.5 {
  top: -0.375rem !important;
}

.tw--top-1\/2 {
  top: -50% !important;
}

.tw--top-1\/3 {
  top: -33.333333% !important;
}

.tw--top-1\/4 {
  top: -25% !important;
}

.tw--top-10 {
  top: -2.5rem !important;
}

.tw--top-11 {
  top: -2.75rem !important;
}

.tw--top-12 {
  top: -3rem !important;
}

.tw--top-14 {
  top: -3.5rem !important;
}

.tw--top-16 {
  top: -4rem !important;
}

.tw--top-2 {
  top: -0.5rem !important;
}

.tw--top-2\.5 {
  top: -0.625rem !important;
}

.tw--top-2\/3 {
  top: -66.666667% !important;
}

.tw--top-2\/4 {
  top: -50% !important;
}

.tw--top-20 {
  top: -5rem !important;
}

.tw--top-24 {
  top: -6rem !important;
}

.tw--top-28 {
  top: -7rem !important;
}

.tw--top-3 {
  top: -0.75rem !important;
}

.tw--top-3\.5 {
  top: -0.875rem !important;
}

.tw--top-3\/4 {
  top: -75% !important;
}

.tw--top-32 {
  top: -8rem !important;
}

.tw--top-36 {
  top: -9rem !important;
}

.tw--top-4 {
  top: -1rem !important;
}

.tw--top-40 {
  top: -10rem !important;
}

.tw--top-44 {
  top: -11rem !important;
}

.tw--top-48 {
  top: -12rem !important;
}

.tw--top-5 {
  top: -1.25rem !important;
}

.tw--top-52 {
  top: -13rem !important;
}

.tw--top-56 {
  top: -14rem !important;
}

.tw--top-6 {
  top: -1.5rem !important;
}

.tw--top-60 {
  top: -15rem !important;
}

.tw--top-64 {
  top: -16rem !important;
}

.tw--top-7 {
  top: -1.75rem !important;
}

.tw--top-72 {
  top: -18rem !important;
}

.tw--top-8 {
  top: -2rem !important;
}

.tw--top-80 {
  top: -20rem !important;
}

.tw--top-9 {
  top: -2.25rem !important;
}

.tw--top-96 {
  top: -24rem !important;
}

.tw--top-full {
  top: -100% !important;
}

.tw--top-px {
  top: -1px !important;
}

.tw-bottom-0 {
  bottom: 0px !important;
}

.tw-bottom-0\.5 {
  bottom: 0.125rem !important;
}

.tw-bottom-1 {
  bottom: 0.25rem !important;
}

.tw-bottom-1\.5 {
  bottom: 0.375rem !important;
}

.tw-bottom-1\/2 {
  bottom: 50% !important;
}

.tw-bottom-1\/3 {
  bottom: 33.333333% !important;
}

.tw-bottom-1\/4 {
  bottom: 25% !important;
}

.tw-bottom-10 {
  bottom: 2.5rem !important;
}

.tw-bottom-11 {
  bottom: 2.75rem !important;
}

.tw-bottom-12 {
  bottom: 3rem !important;
}

.tw-bottom-14 {
  bottom: 3.5rem !important;
}

.tw-bottom-16 {
  bottom: 4rem !important;
}

.tw-bottom-2 {
  bottom: 0.5rem !important;
}

.tw-bottom-2\.5 {
  bottom: 0.625rem !important;
}

.tw-bottom-2\/3 {
  bottom: 66.666667% !important;
}

.tw-bottom-2\/4 {
  bottom: 50% !important;
}

.tw-bottom-20 {
  bottom: 5rem !important;
}

.tw-bottom-24 {
  bottom: 6rem !important;
}

.tw-bottom-28 {
  bottom: 7rem !important;
}

.tw-bottom-3 {
  bottom: 0.75rem !important;
}

.tw-bottom-3\.5 {
  bottom: 0.875rem !important;
}

.tw-bottom-3\/4 {
  bottom: 75% !important;
}

.tw-bottom-32 {
  bottom: 8rem !important;
}

.tw-bottom-36 {
  bottom: 9rem !important;
}

.tw-bottom-4 {
  bottom: 1rem !important;
}

.tw-bottom-40 {
  bottom: 10rem !important;
}

.tw-bottom-44 {
  bottom: 11rem !important;
}

.tw-bottom-48 {
  bottom: 12rem !important;
}

.tw-bottom-5 {
  bottom: 1.25rem !important;
}

.tw-bottom-52 {
  bottom: 13rem !important;
}

.tw-bottom-56 {
  bottom: 14rem !important;
}

.tw-bottom-6 {
  bottom: 1.5rem !important;
}

.tw-bottom-60 {
  bottom: 15rem !important;
}

.tw-bottom-64 {
  bottom: 16rem !important;
}

.tw-bottom-7 {
  bottom: 1.75rem !important;
}

.tw-bottom-72 {
  bottom: 18rem !important;
}

.tw-bottom-8 {
  bottom: 2rem !important;
}

.tw-bottom-80 {
  bottom: 20rem !important;
}

.tw-bottom-9 {
  bottom: 2.25rem !important;
}

.tw-bottom-96 {
  bottom: 24rem !important;
}

.tw-bottom-auto {
  bottom: auto !important;
}

.tw-bottom-full {
  bottom: 100% !important;
}

.tw-bottom-px {
  bottom: 1px !important;
}

.tw-end-0 {
  inset-inline-end: 0px !important;
}

.tw-end-0\.5 {
  inset-inline-end: 0.125rem !important;
}

.tw-end-1 {
  inset-inline-end: 0.25rem !important;
}

.tw-end-1\.5 {
  inset-inline-end: 0.375rem !important;
}

.tw-end-1\/2 {
  inset-inline-end: 50% !important;
}

.tw-end-1\/3 {
  inset-inline-end: 33.333333% !important;
}

.tw-end-1\/4 {
  inset-inline-end: 25% !important;
}

.tw-end-10 {
  inset-inline-end: 2.5rem !important;
}

.tw-end-11 {
  inset-inline-end: 2.75rem !important;
}

.tw-end-12 {
  inset-inline-end: 3rem !important;
}

.tw-end-14 {
  inset-inline-end: 3.5rem !important;
}

.tw-end-16 {
  inset-inline-end: 4rem !important;
}

.tw-end-2 {
  inset-inline-end: 0.5rem !important;
}

.tw-end-2\.5 {
  inset-inline-end: 0.625rem !important;
}

.tw-end-2\/3 {
  inset-inline-end: 66.666667% !important;
}

.tw-end-2\/4 {
  inset-inline-end: 50% !important;
}

.tw-end-20 {
  inset-inline-end: 5rem !important;
}

.tw-end-24 {
  inset-inline-end: 6rem !important;
}

.tw-end-28 {
  inset-inline-end: 7rem !important;
}

.tw-end-3 {
  inset-inline-end: 0.75rem !important;
}

.tw-end-3\.5 {
  inset-inline-end: 0.875rem !important;
}

.tw-end-3\/4 {
  inset-inline-end: 75% !important;
}

.tw-end-32 {
  inset-inline-end: 8rem !important;
}

.tw-end-36 {
  inset-inline-end: 9rem !important;
}

.tw-end-4 {
  inset-inline-end: 1rem !important;
}

.tw-end-40 {
  inset-inline-end: 10rem !important;
}

.tw-end-44 {
  inset-inline-end: 11rem !important;
}

.tw-end-48 {
  inset-inline-end: 12rem !important;
}

.tw-end-5 {
  inset-inline-end: 1.25rem !important;
}

.tw-end-52 {
  inset-inline-end: 13rem !important;
}

.tw-end-56 {
  inset-inline-end: 14rem !important;
}

.tw-end-6 {
  inset-inline-end: 1.5rem !important;
}

.tw-end-60 {
  inset-inline-end: 15rem !important;
}

.tw-end-64 {
  inset-inline-end: 16rem !important;
}

.tw-end-7 {
  inset-inline-end: 1.75rem !important;
}

.tw-end-72 {
  inset-inline-end: 18rem !important;
}

.tw-end-8 {
  inset-inline-end: 2rem !important;
}

.tw-end-80 {
  inset-inline-end: 20rem !important;
}

.tw-end-9 {
  inset-inline-end: 2.25rem !important;
}

.tw-end-96 {
  inset-inline-end: 24rem !important;
}

.tw-end-auto {
  inset-inline-end: auto !important;
}

.tw-end-full {
  inset-inline-end: 100% !important;
}

.tw-end-px {
  inset-inline-end: 1px !important;
}

.tw-left-0 {
  left: 0px !important;
}

.tw-left-0\.5 {
  left: 0.125rem !important;
}

.tw-left-1 {
  left: 0.25rem !important;
}

.tw-left-1\.5 {
  left: 0.375rem !important;
}

.tw-left-1\/2 {
  left: 50% !important;
}

.tw-left-1\/3 {
  left: 33.333333% !important;
}

.tw-left-1\/4 {
  left: 25% !important;
}

.tw-left-10 {
  left: 2.5rem !important;
}

.tw-left-11 {
  left: 2.75rem !important;
}

.tw-left-12 {
  left: 3rem !important;
}

.tw-left-14 {
  left: 3.5rem !important;
}

.tw-left-16 {
  left: 4rem !important;
}

.tw-left-2 {
  left: 0.5rem !important;
}

.tw-left-2\.5 {
  left: 0.625rem !important;
}

.tw-left-2\/3 {
  left: 66.666667% !important;
}

.tw-left-2\/4 {
  left: 50% !important;
}

.tw-left-20 {
  left: 5rem !important;
}

.tw-left-24 {
  left: 6rem !important;
}

.tw-left-28 {
  left: 7rem !important;
}

.tw-left-3 {
  left: 0.75rem !important;
}

.tw-left-3\.5 {
  left: 0.875rem !important;
}

.tw-left-3\/4 {
  left: 75% !important;
}

.tw-left-32 {
  left: 8rem !important;
}

.tw-left-36 {
  left: 9rem !important;
}

.tw-left-4 {
  left: 1rem !important;
}

.tw-left-40 {
  left: 10rem !important;
}

.tw-left-44 {
  left: 11rem !important;
}

.tw-left-48 {
  left: 12rem !important;
}

.tw-left-5 {
  left: 1.25rem !important;
}

.tw-left-52 {
  left: 13rem !important;
}

.tw-left-56 {
  left: 14rem !important;
}

.tw-left-6 {
  left: 1.5rem !important;
}

.tw-left-60 {
  left: 15rem !important;
}

.tw-left-64 {
  left: 16rem !important;
}

.tw-left-7 {
  left: 1.75rem !important;
}

.tw-left-72 {
  left: 18rem !important;
}

.tw-left-8 {
  left: 2rem !important;
}

.tw-left-80 {
  left: 20rem !important;
}

.tw-left-9 {
  left: 2.25rem !important;
}

.tw-left-96 {
  left: 24rem !important;
}

.tw-left-auto {
  left: auto !important;
}

.tw-left-full {
  left: 100% !important;
}

.tw-left-px {
  left: 1px !important;
}

.tw-right-0 {
  right: 0px !important;
}

.tw-right-0\.5 {
  right: 0.125rem !important;
}

.tw-right-1 {
  right: 0.25rem !important;
}

.tw-right-1\.5 {
  right: 0.375rem !important;
}

.tw-right-1\/2 {
  right: 50% !important;
}

.tw-right-1\/3 {
  right: 33.333333% !important;
}

.tw-right-1\/4 {
  right: 25% !important;
}

.tw-right-10 {
  right: 2.5rem !important;
}

.tw-right-11 {
  right: 2.75rem !important;
}

.tw-right-12 {
  right: 3rem !important;
}

.tw-right-14 {
  right: 3.5rem !important;
}

.tw-right-16 {
  right: 4rem !important;
}

.tw-right-2 {
  right: 0.5rem !important;
}

.tw-right-2\.5 {
  right: 0.625rem !important;
}

.tw-right-2\/3 {
  right: 66.666667% !important;
}

.tw-right-2\/4 {
  right: 50% !important;
}

.tw-right-20 {
  right: 5rem !important;
}

.tw-right-24 {
  right: 6rem !important;
}

.tw-right-28 {
  right: 7rem !important;
}

.tw-right-3 {
  right: 0.75rem !important;
}

.tw-right-3\.5 {
  right: 0.875rem !important;
}

.tw-right-3\/4 {
  right: 75% !important;
}

.tw-right-32 {
  right: 8rem !important;
}

.tw-right-36 {
  right: 9rem !important;
}

.tw-right-4 {
  right: 1rem !important;
}

.tw-right-40 {
  right: 10rem !important;
}

.tw-right-44 {
  right: 11rem !important;
}

.tw-right-48 {
  right: 12rem !important;
}

.tw-right-5 {
  right: 1.25rem !important;
}

.tw-right-52 {
  right: 13rem !important;
}

.tw-right-56 {
  right: 14rem !important;
}

.tw-right-6 {
  right: 1.5rem !important;
}

.tw-right-60 {
  right: 15rem !important;
}

.tw-right-64 {
  right: 16rem !important;
}

.tw-right-7 {
  right: 1.75rem !important;
}

.tw-right-72 {
  right: 18rem !important;
}

.tw-right-8 {
  right: 2rem !important;
}

.tw-right-80 {
  right: 20rem !important;
}

.tw-right-9 {
  right: 2.25rem !important;
}

.tw-right-96 {
  right: 24rem !important;
}

.tw-right-auto {
  right: auto !important;
}

.tw-right-full {
  right: 100% !important;
}

.tw-right-px {
  right: 1px !important;
}

.tw-start-0 {
  inset-inline-start: 0px !important;
}

.tw-start-0\.5 {
  inset-inline-start: 0.125rem !important;
}

.tw-start-1 {
  inset-inline-start: 0.25rem !important;
}

.tw-start-1\.5 {
  inset-inline-start: 0.375rem !important;
}

.tw-start-1\/2 {
  inset-inline-start: 50% !important;
}

.tw-start-1\/3 {
  inset-inline-start: 33.333333% !important;
}

.tw-start-1\/4 {
  inset-inline-start: 25% !important;
}

.tw-start-10 {
  inset-inline-start: 2.5rem !important;
}

.tw-start-11 {
  inset-inline-start: 2.75rem !important;
}

.tw-start-12 {
  inset-inline-start: 3rem !important;
}

.tw-start-14 {
  inset-inline-start: 3.5rem !important;
}

.tw-start-16 {
  inset-inline-start: 4rem !important;
}

.tw-start-2 {
  inset-inline-start: 0.5rem !important;
}

.tw-start-2\.5 {
  inset-inline-start: 0.625rem !important;
}

.tw-start-2\/3 {
  inset-inline-start: 66.666667% !important;
}

.tw-start-2\/4 {
  inset-inline-start: 50% !important;
}

.tw-start-20 {
  inset-inline-start: 5rem !important;
}

.tw-start-24 {
  inset-inline-start: 6rem !important;
}

.tw-start-28 {
  inset-inline-start: 7rem !important;
}

.tw-start-3 {
  inset-inline-start: 0.75rem !important;
}

.tw-start-3\.5 {
  inset-inline-start: 0.875rem !important;
}

.tw-start-3\/4 {
  inset-inline-start: 75% !important;
}

.tw-start-32 {
  inset-inline-start: 8rem !important;
}

.tw-start-36 {
  inset-inline-start: 9rem !important;
}

.tw-start-4 {
  inset-inline-start: 1rem !important;
}

.tw-start-40 {
  inset-inline-start: 10rem !important;
}

.tw-start-44 {
  inset-inline-start: 11rem !important;
}

.tw-start-48 {
  inset-inline-start: 12rem !important;
}

.tw-start-5 {
  inset-inline-start: 1.25rem !important;
}

.tw-start-52 {
  inset-inline-start: 13rem !important;
}

.tw-start-56 {
  inset-inline-start: 14rem !important;
}

.tw-start-6 {
  inset-inline-start: 1.5rem !important;
}

.tw-start-60 {
  inset-inline-start: 15rem !important;
}

.tw-start-64 {
  inset-inline-start: 16rem !important;
}

.tw-start-7 {
  inset-inline-start: 1.75rem !important;
}

.tw-start-72 {
  inset-inline-start: 18rem !important;
}

.tw-start-8 {
  inset-inline-start: 2rem !important;
}

.tw-start-80 {
  inset-inline-start: 20rem !important;
}

.tw-start-9 {
  inset-inline-start: 2.25rem !important;
}

.tw-start-96 {
  inset-inline-start: 24rem !important;
}

.tw-start-auto {
  inset-inline-start: auto !important;
}

.tw-start-full {
  inset-inline-start: 100% !important;
}

.tw-start-px {
  inset-inline-start: 1px !important;
}

.tw-top-0 {
  top: 0px !important;
}

.tw-top-0\.5 {
  top: 0.125rem !important;
}

.tw-top-1 {
  top: 0.25rem !important;
}

.tw-top-1\.5 {
  top: 0.375rem !important;
}

.tw-top-1\/2 {
  top: 50% !important;
}

.tw-top-1\/3 {
  top: 33.333333% !important;
}

.tw-top-1\/4 {
  top: 25% !important;
}

.tw-top-10 {
  top: 2.5rem !important;
}

.tw-top-11 {
  top: 2.75rem !important;
}

.tw-top-12 {
  top: 3rem !important;
}

.tw-top-14 {
  top: 3.5rem !important;
}

.tw-top-16 {
  top: 4rem !important;
}

.tw-top-2 {
  top: 0.5rem !important;
}

.tw-top-2\.5 {
  top: 0.625rem !important;
}

.tw-top-2\/3 {
  top: 66.666667% !important;
}

.tw-top-2\/4 {
  top: 50% !important;
}

.tw-top-20 {
  top: 5rem !important;
}

.tw-top-24 {
  top: 6rem !important;
}

.tw-top-28 {
  top: 7rem !important;
}

.tw-top-3 {
  top: 0.75rem !important;
}

.tw-top-3\.5 {
  top: 0.875rem !important;
}

.tw-top-3\/4 {
  top: 75% !important;
}

.tw-top-32 {
  top: 8rem !important;
}

.tw-top-36 {
  top: 9rem !important;
}

.tw-top-4 {
  top: 1rem !important;
}

.tw-top-40 {
  top: 10rem !important;
}

.tw-top-44 {
  top: 11rem !important;
}

.tw-top-48 {
  top: 12rem !important;
}

.tw-top-5 {
  top: 1.25rem !important;
}

.tw-top-52 {
  top: 13rem !important;
}

.tw-top-56 {
  top: 14rem !important;
}

.tw-top-6 {
  top: 1.5rem !important;
}

.tw-top-60 {
  top: 15rem !important;
}

.tw-top-64 {
  top: 16rem !important;
}

.tw-top-7 {
  top: 1.75rem !important;
}

.tw-top-72 {
  top: 18rem !important;
}

.tw-top-8 {
  top: 2rem !important;
}

.tw-top-80 {
  top: 20rem !important;
}

.tw-top-9 {
  top: 2.25rem !important;
}

.tw-top-96 {
  top: 24rem !important;
}

.tw-top-auto {
  top: auto !important;
}

.tw-top-full {
  top: 100% !important;
}

.tw-top-px {
  top: 1px !important;
}

.tw-isolate {
  isolation: isolate !important;
}

.tw-isolation-auto {
  isolation: auto !important;
}

.-tw-z-0 {
  z-index: 0 !important;
}

.-tw-z-10 {
  z-index: -10 !important;
}

.-tw-z-20 {
  z-index: -20 !important;
}

.-tw-z-30 {
  z-index: -30 !important;
}

.-tw-z-40 {
  z-index: -40 !important;
}

.-tw-z-50 {
  z-index: -50 !important;
}

.tw--z-0 {
  z-index: 0 !important;
}

.tw--z-10 {
  z-index: -10 !important;
}

.tw--z-20 {
  z-index: -20 !important;
}

.tw--z-30 {
  z-index: -30 !important;
}

.tw--z-40 {
  z-index: -40 !important;
}

.tw--z-50 {
  z-index: -50 !important;
}

.tw-z-0 {
  z-index: 0 !important;
}

.tw-z-10 {
  z-index: 10 !important;
}

.tw-z-20 {
  z-index: 20 !important;
}

.tw-z-30 {
  z-index: 30 !important;
}

.tw-z-40 {
  z-index: 40 !important;
}

.tw-z-50 {
  z-index: 50 !important;
}

.tw-z-auto {
  z-index: auto !important;
}

.-tw-order-1 {
  order: -1 !important;
}

.-tw-order-10 {
  order: -10 !important;
}

.-tw-order-11 {
  order: -11 !important;
}

.-tw-order-12 {
  order: -12 !important;
}

.-tw-order-2 {
  order: -2 !important;
}

.-tw-order-3 {
  order: -3 !important;
}

.-tw-order-4 {
  order: -4 !important;
}

.-tw-order-5 {
  order: -5 !important;
}

.-tw-order-6 {
  order: -6 !important;
}

.-tw-order-7 {
  order: -7 !important;
}

.-tw-order-8 {
  order: -8 !important;
}

.-tw-order-9 {
  order: -9 !important;
}

.-tw-order-first {
  order: 9999 !important;
}

.-tw-order-last {
  order: -9999 !important;
}

.-tw-order-none {
  order: 0 !important;
}

.tw--order-1 {
  order: -1 !important;
}

.tw--order-10 {
  order: -10 !important;
}

.tw--order-11 {
  order: -11 !important;
}

.tw--order-12 {
  order: -12 !important;
}

.tw--order-2 {
  order: -2 !important;
}

.tw--order-3 {
  order: -3 !important;
}

.tw--order-4 {
  order: -4 !important;
}

.tw--order-5 {
  order: -5 !important;
}

.tw--order-6 {
  order: -6 !important;
}

.tw--order-7 {
  order: -7 !important;
}

.tw--order-8 {
  order: -8 !important;
}

.tw--order-9 {
  order: -9 !important;
}

.tw--order-first {
  order: 9999 !important;
}

.tw--order-last {
  order: -9999 !important;
}

.tw--order-none {
  order: 0 !important;
}

.tw-order-1 {
  order: 1 !important;
}

.tw-order-10 {
  order: 10 !important;
}

.tw-order-11 {
  order: 11 !important;
}

.tw-order-12 {
  order: 12 !important;
}

.tw-order-2 {
  order: 2 !important;
}

.tw-order-3 {
  order: 3 !important;
}

.tw-order-4 {
  order: 4 !important;
}

.tw-order-5 {
  order: 5 !important;
}

.tw-order-6 {
  order: 6 !important;
}

.tw-order-7 {
  order: 7 !important;
}

.tw-order-8 {
  order: 8 !important;
}

.tw-order-9 {
  order: 9 !important;
}

.tw-order-first {
  order: -9999 !important;
}

.tw-order-last {
  order: 9999 !important;
}

.tw-order-none {
  order: 0 !important;
}

.tw-col-auto {
  grid-column: auto !important;
}

.tw-col-span-1 {
  grid-column: span 1 / span 1 !important;
}

.tw-col-span-10 {
  grid-column: span 10 / span 10 !important;
}

.tw-col-span-11 {
  grid-column: span 11 / span 11 !important;
}

.tw-col-span-12 {
  grid-column: span 12 / span 12 !important;
}

.tw-col-span-2 {
  grid-column: span 2 / span 2 !important;
}

.tw-col-span-3 {
  grid-column: span 3 / span 3 !important;
}

.tw-col-span-4 {
  grid-column: span 4 / span 4 !important;
}

.tw-col-span-5 {
  grid-column: span 5 / span 5 !important;
}

.tw-col-span-6 {
  grid-column: span 6 / span 6 !important;
}

.tw-col-span-7 {
  grid-column: span 7 / span 7 !important;
}

.tw-col-span-8 {
  grid-column: span 8 / span 8 !important;
}

.tw-col-span-9 {
  grid-column: span 9 / span 9 !important;
}

.tw-col-span-full {
  grid-column: 1 / -1 !important;
}

.tw-col-start-1 {
  grid-column-start: 1 !important;
}

.tw-col-start-10 {
  grid-column-start: 10 !important;
}

.tw-col-start-11 {
  grid-column-start: 11 !important;
}

.tw-col-start-12 {
  grid-column-start: 12 !important;
}

.tw-col-start-13 {
  grid-column-start: 13 !important;
}

.tw-col-start-2 {
  grid-column-start: 2 !important;
}

.tw-col-start-3 {
  grid-column-start: 3 !important;
}

.tw-col-start-4 {
  grid-column-start: 4 !important;
}

.tw-col-start-5 {
  grid-column-start: 5 !important;
}

.tw-col-start-6 {
  grid-column-start: 6 !important;
}

.tw-col-start-7 {
  grid-column-start: 7 !important;
}

.tw-col-start-8 {
  grid-column-start: 8 !important;
}

.tw-col-start-9 {
  grid-column-start: 9 !important;
}

.tw-col-start-auto {
  grid-column-start: auto !important;
}

.tw-col-end-1 {
  grid-column-end: 1 !important;
}

.tw-col-end-10 {
  grid-column-end: 10 !important;
}

.tw-col-end-11 {
  grid-column-end: 11 !important;
}

.tw-col-end-12 {
  grid-column-end: 12 !important;
}

.tw-col-end-13 {
  grid-column-end: 13 !important;
}

.tw-col-end-2 {
  grid-column-end: 2 !important;
}

.tw-col-end-3 {
  grid-column-end: 3 !important;
}

.tw-col-end-4 {
  grid-column-end: 4 !important;
}

.tw-col-end-5 {
  grid-column-end: 5 !important;
}

.tw-col-end-6 {
  grid-column-end: 6 !important;
}

.tw-col-end-7 {
  grid-column-end: 7 !important;
}

.tw-col-end-8 {
  grid-column-end: 8 !important;
}

.tw-col-end-9 {
  grid-column-end: 9 !important;
}

.tw-col-end-auto {
  grid-column-end: auto !important;
}

.tw-row-auto {
  grid-row: auto !important;
}

.tw-row-span-1 {
  grid-row: span 1 / span 1 !important;
}

.tw-row-span-10 {
  grid-row: span 10 / span 10 !important;
}

.tw-row-span-11 {
  grid-row: span 11 / span 11 !important;
}

.tw-row-span-12 {
  grid-row: span 12 / span 12 !important;
}

.tw-row-span-2 {
  grid-row: span 2 / span 2 !important;
}

.tw-row-span-3 {
  grid-row: span 3 / span 3 !important;
}

.tw-row-span-4 {
  grid-row: span 4 / span 4 !important;
}

.tw-row-span-5 {
  grid-row: span 5 / span 5 !important;
}

.tw-row-span-6 {
  grid-row: span 6 / span 6 !important;
}

.tw-row-span-7 {
  grid-row: span 7 / span 7 !important;
}

.tw-row-span-8 {
  grid-row: span 8 / span 8 !important;
}

.tw-row-span-9 {
  grid-row: span 9 / span 9 !important;
}

.tw-row-span-full {
  grid-row: 1 / -1 !important;
}

.tw-row-start-1 {
  grid-row-start: 1 !important;
}

.tw-row-start-10 {
  grid-row-start: 10 !important;
}

.tw-row-start-11 {
  grid-row-start: 11 !important;
}

.tw-row-start-12 {
  grid-row-start: 12 !important;
}

.tw-row-start-13 {
  grid-row-start: 13 !important;
}

.tw-row-start-2 {
  grid-row-start: 2 !important;
}

.tw-row-start-3 {
  grid-row-start: 3 !important;
}

.tw-row-start-4 {
  grid-row-start: 4 !important;
}

.tw-row-start-5 {
  grid-row-start: 5 !important;
}

.tw-row-start-6 {
  grid-row-start: 6 !important;
}

.tw-row-start-7 {
  grid-row-start: 7 !important;
}

.tw-row-start-8 {
  grid-row-start: 8 !important;
}

.tw-row-start-9 {
  grid-row-start: 9 !important;
}

.tw-row-start-auto {
  grid-row-start: auto !important;
}

.tw-row-end-1 {
  grid-row-end: 1 !important;
}

.tw-row-end-10 {
  grid-row-end: 10 !important;
}

.tw-row-end-11 {
  grid-row-end: 11 !important;
}

.tw-row-end-12 {
  grid-row-end: 12 !important;
}

.tw-row-end-13 {
  grid-row-end: 13 !important;
}

.tw-row-end-2 {
  grid-row-end: 2 !important;
}

.tw-row-end-3 {
  grid-row-end: 3 !important;
}

.tw-row-end-4 {
  grid-row-end: 4 !important;
}

.tw-row-end-5 {
  grid-row-end: 5 !important;
}

.tw-row-end-6 {
  grid-row-end: 6 !important;
}

.tw-row-end-7 {
  grid-row-end: 7 !important;
}

.tw-row-end-8 {
  grid-row-end: 8 !important;
}

.tw-row-end-9 {
  grid-row-end: 9 !important;
}

.tw-row-end-auto {
  grid-row-end: auto !important;
}

.tw-float-start {
  float: inline-start !important;
}

.tw-float-end {
  float: inline-end !important;
}

.tw-float-right {
  float: right !important;
}

.tw-float-left {
  float: left !important;
}

.tw-float-none {
  float: none !important;
}

.tw-clear-start {
  clear: inline-start !important;
}

.tw-clear-end {
  clear: inline-end !important;
}

.tw-clear-left {
  clear: left !important;
}

.tw-clear-right {
  clear: right !important;
}

.tw-clear-both {
  clear: both !important;
}

.tw-clear-none {
  clear: none !important;
}

.-tw-m-0 {
  margin: -0px !important;
}

.-tw-m-0\.5 {
  margin: -0.125rem !important;
}

.-tw-m-1 {
  margin: -0.25rem !important;
}

.-tw-m-1\.5 {
  margin: -0.375rem !important;
}

.-tw-m-10 {
  margin: -2.5rem !important;
}

.-tw-m-11 {
  margin: -2.75rem !important;
}

.-tw-m-12 {
  margin: -3rem !important;
}

.-tw-m-14 {
  margin: -3.5rem !important;
}

.-tw-m-16 {
  margin: -4rem !important;
}

.-tw-m-2 {
  margin: -0.5rem !important;
}

.-tw-m-2\.5 {
  margin: -0.625rem !important;
}

.-tw-m-20 {
  margin: -5rem !important;
}

.-tw-m-24 {
  margin: -6rem !important;
}

.-tw-m-28 {
  margin: -7rem !important;
}

.-tw-m-3 {
  margin: -0.75rem !important;
}

.-tw-m-3\.5 {
  margin: -0.875rem !important;
}

.-tw-m-32 {
  margin: -8rem !important;
}

.-tw-m-36 {
  margin: -9rem !important;
}

.-tw-m-4 {
  margin: -1rem !important;
}

.-tw-m-40 {
  margin: -10rem !important;
}

.-tw-m-44 {
  margin: -11rem !important;
}

.-tw-m-48 {
  margin: -12rem !important;
}

.-tw-m-5 {
  margin: -1.25rem !important;
}

.-tw-m-52 {
  margin: -13rem !important;
}

.-tw-m-56 {
  margin: -14rem !important;
}

.-tw-m-6 {
  margin: -1.5rem !important;
}

.-tw-m-60 {
  margin: -15rem !important;
}

.-tw-m-64 {
  margin: -16rem !important;
}

.-tw-m-7 {
  margin: -1.75rem !important;
}

.-tw-m-72 {
  margin: -18rem !important;
}

.-tw-m-8 {
  margin: -2rem !important;
}

.-tw-m-80 {
  margin: -20rem !important;
}

.-tw-m-9 {
  margin: -2.25rem !important;
}

.-tw-m-96 {
  margin: -24rem !important;
}

.-tw-m-px {
  margin: -1px !important;
}

.tw--m-0 {
  margin: -0px !important;
}

.tw--m-0\.5 {
  margin: -0.125rem !important;
}

.tw--m-1 {
  margin: -0.25rem !important;
}

.tw--m-1\.5 {
  margin: -0.375rem !important;
}

.tw--m-10 {
  margin: -2.5rem !important;
}

.tw--m-11 {
  margin: -2.75rem !important;
}

.tw--m-12 {
  margin: -3rem !important;
}

.tw--m-14 {
  margin: -3.5rem !important;
}

.tw--m-16 {
  margin: -4rem !important;
}

.tw--m-2 {
  margin: -0.5rem !important;
}

.tw--m-2\.5 {
  margin: -0.625rem !important;
}

.tw--m-20 {
  margin: -5rem !important;
}

.tw--m-24 {
  margin: -6rem !important;
}

.tw--m-28 {
  margin: -7rem !important;
}

.tw--m-3 {
  margin: -0.75rem !important;
}

.tw--m-3\.5 {
  margin: -0.875rem !important;
}

.tw--m-32 {
  margin: -8rem !important;
}

.tw--m-36 {
  margin: -9rem !important;
}

.tw--m-4 {
  margin: -1rem !important;
}

.tw--m-40 {
  margin: -10rem !important;
}

.tw--m-44 {
  margin: -11rem !important;
}

.tw--m-48 {
  margin: -12rem !important;
}

.tw--m-5 {
  margin: -1.25rem !important;
}

.tw--m-52 {
  margin: -13rem !important;
}

.tw--m-56 {
  margin: -14rem !important;
}

.tw--m-6 {
  margin: -1.5rem !important;
}

.tw--m-60 {
  margin: -15rem !important;
}

.tw--m-64 {
  margin: -16rem !important;
}

.tw--m-7 {
  margin: -1.75rem !important;
}

.tw--m-72 {
  margin: -18rem !important;
}

.tw--m-8 {
  margin: -2rem !important;
}

.tw--m-80 {
  margin: -20rem !important;
}

.tw--m-9 {
  margin: -2.25rem !important;
}

.tw--m-96 {
  margin: -24rem !important;
}

.tw--m-px {
  margin: -1px !important;
}

.tw-m-0 {
  margin: 0px !important;
}

.tw-m-0\.5 {
  margin: 0.125rem !important;
}

.tw-m-1 {
  margin: 0.25rem !important;
}

.tw-m-1\.5 {
  margin: 0.375rem !important;
}

.tw-m-10 {
  margin: 2.5rem !important;
}

.tw-m-11 {
  margin: 2.75rem !important;
}

.tw-m-12 {
  margin: 3rem !important;
}

.tw-m-14 {
  margin: 3.5rem !important;
}

.tw-m-16 {
  margin: 4rem !important;
}

.tw-m-2 {
  margin: 0.5rem !important;
}

.tw-m-2\.5 {
  margin: 0.625rem !important;
}

.tw-m-20 {
  margin: 5rem !important;
}

.tw-m-24 {
  margin: 6rem !important;
}

.tw-m-28 {
  margin: 7rem !important;
}

.tw-m-3 {
  margin: 0.75rem !important;
}

.tw-m-3\.5 {
  margin: 0.875rem !important;
}

.tw-m-32 {
  margin: 8rem !important;
}

.tw-m-36 {
  margin: 9rem !important;
}

.tw-m-4 {
  margin: 1rem !important;
}

.tw-m-40 {
  margin: 10rem !important;
}

.tw-m-44 {
  margin: 11rem !important;
}

.tw-m-48 {
  margin: 12rem !important;
}

.tw-m-5 {
  margin: 1.25rem !important;
}

.tw-m-52 {
  margin: 13rem !important;
}

.tw-m-56 {
  margin: 14rem !important;
}

.tw-m-6 {
  margin: 1.5rem !important;
}

.tw-m-60 {
  margin: 15rem !important;
}

.tw-m-64 {
  margin: 16rem !important;
}

.tw-m-7 {
  margin: 1.75rem !important;
}

.tw-m-72 {
  margin: 18rem !important;
}

.tw-m-8 {
  margin: 2rem !important;
}

.tw-m-80 {
  margin: 20rem !important;
}

.tw-m-9 {
  margin: 2.25rem !important;
}

.tw-m-96 {
  margin: 24rem !important;
}

.tw-m-auto {
  margin: auto !important;
}

.tw-m-px {
  margin: 1px !important;
}

.-tw-mx-0 {
  margin-left: -0px !important;
  margin-right: -0px !important;
}

.-tw-mx-0\.5 {
  margin-left: -0.125rem !important;
  margin-right: -0.125rem !important;
}

.-tw-mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.-tw-mx-1\.5 {
  margin-left: -0.375rem !important;
  margin-right: -0.375rem !important;
}

.-tw-mx-10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.-tw-mx-11 {
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.-tw-mx-12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.-tw-mx-14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.-tw-mx-16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.-tw-mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.-tw-mx-2\.5 {
  margin-left: -0.625rem !important;
  margin-right: -0.625rem !important;
}

.-tw-mx-20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.-tw-mx-24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.-tw-mx-28 {
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.-tw-mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.-tw-mx-3\.5 {
  margin-left: -0.875rem !important;
  margin-right: -0.875rem !important;
}

.-tw-mx-32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.-tw-mx-36 {
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.-tw-mx-4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.-tw-mx-40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.-tw-mx-44 {
  margin-left: -11rem !important;
  margin-right: -11rem !important;
}

.-tw-mx-48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.-tw-mx-5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.-tw-mx-52 {
  margin-left: -13rem !important;
  margin-right: -13rem !important;
}

.-tw-mx-56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.-tw-mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.-tw-mx-60 {
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.-tw-mx-64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.-tw-mx-7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.-tw-mx-72 {
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.-tw-mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.-tw-mx-80 {
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.-tw-mx-9 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.-tw-mx-96 {
  margin-left: -24rem !important;
  margin-right: -24rem !important;
}

.-tw-mx-px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.-tw-my-0 {
  margin-top: -0px !important;
  margin-bottom: -0px !important;
}

.-tw-my-0\.5 {
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important;
}

.-tw-my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.-tw-my-1\.5 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.-tw-my-10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.-tw-my-11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.-tw-my-12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.-tw-my-14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.-tw-my-16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.-tw-my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.-tw-my-2\.5 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.-tw-my-20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.-tw-my-24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.-tw-my-28 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.-tw-my-3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.-tw-my-3\.5 {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.-tw-my-32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.-tw-my-36 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.-tw-my-4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.-tw-my-40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.-tw-my-44 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.-tw-my-48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.-tw-my-5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.-tw-my-52 {
  margin-top: -13rem !important;
  margin-bottom: -13rem !important;
}

.-tw-my-56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.-tw-my-6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.-tw-my-60 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.-tw-my-64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.-tw-my-7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.-tw-my-72 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.-tw-my-8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.-tw-my-80 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.-tw-my-9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.-tw-my-96 {
  margin-top: -24rem !important;
  margin-bottom: -24rem !important;
}

.-tw-my-px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.tw--mx-0 {
  margin-left: -0px !important;
  margin-right: -0px !important;
}

.tw--mx-0\.5 {
  margin-left: -0.125rem !important;
  margin-right: -0.125rem !important;
}

.tw--mx-1 {
  margin-left: -0.25rem !important;
  margin-right: -0.25rem !important;
}

.tw--mx-1\.5 {
  margin-left: -0.375rem !important;
  margin-right: -0.375rem !important;
}

.tw--mx-10 {
  margin-left: -2.5rem !important;
  margin-right: -2.5rem !important;
}

.tw--mx-11 {
  margin-left: -2.75rem !important;
  margin-right: -2.75rem !important;
}

.tw--mx-12 {
  margin-left: -3rem !important;
  margin-right: -3rem !important;
}

.tw--mx-14 {
  margin-left: -3.5rem !important;
  margin-right: -3.5rem !important;
}

.tw--mx-16 {
  margin-left: -4rem !important;
  margin-right: -4rem !important;
}

.tw--mx-2 {
  margin-left: -0.5rem !important;
  margin-right: -0.5rem !important;
}

.tw--mx-2\.5 {
  margin-left: -0.625rem !important;
  margin-right: -0.625rem !important;
}

.tw--mx-20 {
  margin-left: -5rem !important;
  margin-right: -5rem !important;
}

.tw--mx-24 {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}

.tw--mx-28 {
  margin-left: -7rem !important;
  margin-right: -7rem !important;
}

.tw--mx-3 {
  margin-left: -0.75rem !important;
  margin-right: -0.75rem !important;
}

.tw--mx-3\.5 {
  margin-left: -0.875rem !important;
  margin-right: -0.875rem !important;
}

.tw--mx-32 {
  margin-left: -8rem !important;
  margin-right: -8rem !important;
}

.tw--mx-36 {
  margin-left: -9rem !important;
  margin-right: -9rem !important;
}

.tw--mx-4 {
  margin-left: -1rem !important;
  margin-right: -1rem !important;
}

.tw--mx-40 {
  margin-left: -10rem !important;
  margin-right: -10rem !important;
}

.tw--mx-44 {
  margin-left: -11rem !important;
  margin-right: -11rem !important;
}

.tw--mx-48 {
  margin-left: -12rem !important;
  margin-right: -12rem !important;
}

.tw--mx-5 {
  margin-left: -1.25rem !important;
  margin-right: -1.25rem !important;
}

.tw--mx-52 {
  margin-left: -13rem !important;
  margin-right: -13rem !important;
}

.tw--mx-56 {
  margin-left: -14rem !important;
  margin-right: -14rem !important;
}

.tw--mx-6 {
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}

.tw--mx-60 {
  margin-left: -15rem !important;
  margin-right: -15rem !important;
}

.tw--mx-64 {
  margin-left: -16rem !important;
  margin-right: -16rem !important;
}

.tw--mx-7 {
  margin-left: -1.75rem !important;
  margin-right: -1.75rem !important;
}

.tw--mx-72 {
  margin-left: -18rem !important;
  margin-right: -18rem !important;
}

.tw--mx-8 {
  margin-left: -2rem !important;
  margin-right: -2rem !important;
}

.tw--mx-80 {
  margin-left: -20rem !important;
  margin-right: -20rem !important;
}

.tw--mx-9 {
  margin-left: -2.25rem !important;
  margin-right: -2.25rem !important;
}

.tw--mx-96 {
  margin-left: -24rem !important;
  margin-right: -24rem !important;
}

.tw--mx-px {
  margin-left: -1px !important;
  margin-right: -1px !important;
}

.tw--my-0 {
  margin-top: -0px !important;
  margin-bottom: -0px !important;
}

.tw--my-0\.5 {
  margin-top: -0.125rem !important;
  margin-bottom: -0.125rem !important;
}

.tw--my-1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.tw--my-1\.5 {
  margin-top: -0.375rem !important;
  margin-bottom: -0.375rem !important;
}

.tw--my-10 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.tw--my-11 {
  margin-top: -2.75rem !important;
  margin-bottom: -2.75rem !important;
}

.tw--my-12 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.tw--my-14 {
  margin-top: -3.5rem !important;
  margin-bottom: -3.5rem !important;
}

.tw--my-16 {
  margin-top: -4rem !important;
  margin-bottom: -4rem !important;
}

.tw--my-2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.tw--my-2\.5 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.tw--my-20 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.tw--my-24 {
  margin-top: -6rem !important;
  margin-bottom: -6rem !important;
}

.tw--my-28 {
  margin-top: -7rem !important;
  margin-bottom: -7rem !important;
}

.tw--my-3 {
  margin-top: -0.75rem !important;
  margin-bottom: -0.75rem !important;
}

.tw--my-3\.5 {
  margin-top: -0.875rem !important;
  margin-bottom: -0.875rem !important;
}

.tw--my-32 {
  margin-top: -8rem !important;
  margin-bottom: -8rem !important;
}

.tw--my-36 {
  margin-top: -9rem !important;
  margin-bottom: -9rem !important;
}

.tw--my-4 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.tw--my-40 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.tw--my-44 {
  margin-top: -11rem !important;
  margin-bottom: -11rem !important;
}

.tw--my-48 {
  margin-top: -12rem !important;
  margin-bottom: -12rem !important;
}

.tw--my-5 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.tw--my-52 {
  margin-top: -13rem !important;
  margin-bottom: -13rem !important;
}

.tw--my-56 {
  margin-top: -14rem !important;
  margin-bottom: -14rem !important;
}

.tw--my-6 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.tw--my-60 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.tw--my-64 {
  margin-top: -16rem !important;
  margin-bottom: -16rem !important;
}

.tw--my-7 {
  margin-top: -1.75rem !important;
  margin-bottom: -1.75rem !important;
}

.tw--my-72 {
  margin-top: -18rem !important;
  margin-bottom: -18rem !important;
}

.tw--my-8 {
  margin-top: -2rem !important;
  margin-bottom: -2rem !important;
}

.tw--my-80 {
  margin-top: -20rem !important;
  margin-bottom: -20rem !important;
}

.tw--my-9 {
  margin-top: -2.25rem !important;
  margin-bottom: -2.25rem !important;
}

.tw--my-96 {
  margin-top: -24rem !important;
  margin-bottom: -24rem !important;
}

.tw--my-px {
  margin-top: -1px !important;
  margin-bottom: -1px !important;
}

.tw-mx-0 {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.tw-mx-0\.5 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}

.tw-mx-1 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}

.tw-mx-1\.5 {
  margin-left: 0.375rem !important;
  margin-right: 0.375rem !important;
}

.tw-mx-10 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}

.tw-mx-11 {
  margin-left: 2.75rem !important;
  margin-right: 2.75rem !important;
}

.tw-mx-12 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}

.tw-mx-14 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}

.tw-mx-16 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}

.tw-mx-2 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}

.tw-mx-2\.5 {
  margin-left: 0.625rem !important;
  margin-right: 0.625rem !important;
}

.tw-mx-20 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}

.tw-mx-24 {
  margin-left: 6rem !important;
  margin-right: 6rem !important;
}

.tw-mx-28 {
  margin-left: 7rem !important;
  margin-right: 7rem !important;
}

.tw-mx-3 {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;
}

.tw-mx-3\.5 {
  margin-left: 0.875rem !important;
  margin-right: 0.875rem !important;
}

.tw-mx-32 {
  margin-left: 8rem !important;
  margin-right: 8rem !important;
}

.tw-mx-36 {
  margin-left: 9rem !important;
  margin-right: 9rem !important;
}

.tw-mx-4 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.tw-mx-40 {
  margin-left: 10rem !important;
  margin-right: 10rem !important;
}

.tw-mx-44 {
  margin-left: 11rem !important;
  margin-right: 11rem !important;
}

.tw-mx-48 {
  margin-left: 12rem !important;
  margin-right: 12rem !important;
}

.tw-mx-5 {
  margin-left: 1.25rem !important;
  margin-right: 1.25rem !important;
}

.tw-mx-52 {
  margin-left: 13rem !important;
  margin-right: 13rem !important;
}

.tw-mx-56 {
  margin-left: 14rem !important;
  margin-right: 14rem !important;
}

.tw-mx-6 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}

.tw-mx-60 {
  margin-left: 15rem !important;
  margin-right: 15rem !important;
}

.tw-mx-64 {
  margin-left: 16rem !important;
  margin-right: 16rem !important;
}

.tw-mx-7 {
  margin-left: 1.75rem !important;
  margin-right: 1.75rem !important;
}

.tw-mx-72 {
  margin-left: 18rem !important;
  margin-right: 18rem !important;
}

.tw-mx-8 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.tw-mx-80 {
  margin-left: 20rem !important;
  margin-right: 20rem !important;
}

.tw-mx-9 {
  margin-left: 2.25rem !important;
  margin-right: 2.25rem !important;
}

.tw-mx-96 {
  margin-left: 24rem !important;
  margin-right: 24rem !important;
}

.tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.tw-mx-px {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.tw-my-0 {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.tw-my-0\.5 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}

.tw-my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.tw-my-1\.5 {
  margin-top: 0.375rem !important;
  margin-bottom: 0.375rem !important;
}

.tw-my-10 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.tw-my-11 {
  margin-top: 2.75rem !important;
  margin-bottom: 2.75rem !important;
}

.tw-my-12 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.tw-my-14 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}

.tw-my-16 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.tw-my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.tw-my-2\.5 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.tw-my-20 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.tw-my-24 {
  margin-top: 6rem !important;
  margin-bottom: 6rem !important;
}

.tw-my-28 {
  margin-top: 7rem !important;
  margin-bottom: 7rem !important;
}

.tw-my-3 {
  margin-top: 0.75rem !important;
  margin-bottom: 0.75rem !important;
}

.tw-my-3\.5 {
  margin-top: 0.875rem !important;
  margin-bottom: 0.875rem !important;
}

.tw-my-32 {
  margin-top: 8rem !important;
  margin-bottom: 8rem !important;
}

.tw-my-36 {
  margin-top: 9rem !important;
  margin-bottom: 9rem !important;
}

.tw-my-4 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.tw-my-40 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.tw-my-44 {
  margin-top: 11rem !important;
  margin-bottom: 11rem !important;
}

.tw-my-48 {
  margin-top: 12rem !important;
  margin-bottom: 12rem !important;
}

.tw-my-5 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.tw-my-52 {
  margin-top: 13rem !important;
  margin-bottom: 13rem !important;
}

.tw-my-56 {
  margin-top: 14rem !important;
  margin-bottom: 14rem !important;
}

.tw-my-6 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.tw-my-60 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.tw-my-64 {
  margin-top: 16rem !important;
  margin-bottom: 16rem !important;
}

.tw-my-7 {
  margin-top: 1.75rem !important;
  margin-bottom: 1.75rem !important;
}

.tw-my-72 {
  margin-top: 18rem !important;
  margin-bottom: 18rem !important;
}

.tw-my-8 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.tw-my-80 {
  margin-top: 20rem !important;
  margin-bottom: 20rem !important;
}

.tw-my-9 {
  margin-top: 2.25rem !important;
  margin-bottom: 2.25rem !important;
}

.tw-my-96 {
  margin-top: 24rem !important;
  margin-bottom: 24rem !important;
}

.tw-my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.tw-my-px {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.-tw-mb-0 {
  margin-bottom: -0px !important;
}

.-tw-mb-0\.5 {
  margin-bottom: -0.125rem !important;
}

.-tw-mb-1 {
  margin-bottom: -0.25rem !important;
}

.-tw-mb-1\.5 {
  margin-bottom: -0.375rem !important;
}

.-tw-mb-10 {
  margin-bottom: -2.5rem !important;
}

.-tw-mb-11 {
  margin-bottom: -2.75rem !important;
}

.-tw-mb-12 {
  margin-bottom: -3rem !important;
}

.-tw-mb-14 {
  margin-bottom: -3.5rem !important;
}

.-tw-mb-16 {
  margin-bottom: -4rem !important;
}

.-tw-mb-2 {
  margin-bottom: -0.5rem !important;
}

.-tw-mb-2\.5 {
  margin-bottom: -0.625rem !important;
}

.-tw-mb-20 {
  margin-bottom: -5rem !important;
}

.-tw-mb-24 {
  margin-bottom: -6rem !important;
}

.-tw-mb-28 {
  margin-bottom: -7rem !important;
}

.-tw-mb-3 {
  margin-bottom: -0.75rem !important;
}

.-tw-mb-3\.5 {
  margin-bottom: -0.875rem !important;
}

.-tw-mb-32 {
  margin-bottom: -8rem !important;
}

.-tw-mb-36 {
  margin-bottom: -9rem !important;
}

.-tw-mb-4 {
  margin-bottom: -1rem !important;
}

.-tw-mb-40 {
  margin-bottom: -10rem !important;
}

.-tw-mb-44 {
  margin-bottom: -11rem !important;
}

.-tw-mb-48 {
  margin-bottom: -12rem !important;
}

.-tw-mb-5 {
  margin-bottom: -1.25rem !important;
}

.-tw-mb-52 {
  margin-bottom: -13rem !important;
}

.-tw-mb-56 {
  margin-bottom: -14rem !important;
}

.-tw-mb-6 {
  margin-bottom: -1.5rem !important;
}

.-tw-mb-60 {
  margin-bottom: -15rem !important;
}

.-tw-mb-64 {
  margin-bottom: -16rem !important;
}

.-tw-mb-7 {
  margin-bottom: -1.75rem !important;
}

.-tw-mb-72 {
  margin-bottom: -18rem !important;
}

.-tw-mb-8 {
  margin-bottom: -2rem !important;
}

.-tw-mb-80 {
  margin-bottom: -20rem !important;
}

.-tw-mb-9 {
  margin-bottom: -2.25rem !important;
}

.-tw-mb-96 {
  margin-bottom: -24rem !important;
}

.-tw-mb-px {
  margin-bottom: -1px !important;
}

.-tw-me-0 {
  margin-inline-end: -0px !important;
}

.-tw-me-0\.5 {
  margin-inline-end: -0.125rem !important;
}

.-tw-me-1 {
  margin-inline-end: -0.25rem !important;
}

.-tw-me-1\.5 {
  margin-inline-end: -0.375rem !important;
}

.-tw-me-10 {
  margin-inline-end: -2.5rem !important;
}

.-tw-me-11 {
  margin-inline-end: -2.75rem !important;
}

.-tw-me-12 {
  margin-inline-end: -3rem !important;
}

.-tw-me-14 {
  margin-inline-end: -3.5rem !important;
}

.-tw-me-16 {
  margin-inline-end: -4rem !important;
}

.-tw-me-2 {
  margin-inline-end: -0.5rem !important;
}

.-tw-me-2\.5 {
  margin-inline-end: -0.625rem !important;
}

.-tw-me-20 {
  margin-inline-end: -5rem !important;
}

.-tw-me-24 {
  margin-inline-end: -6rem !important;
}

.-tw-me-28 {
  margin-inline-end: -7rem !important;
}

.-tw-me-3 {
  margin-inline-end: -0.75rem !important;
}

.-tw-me-3\.5 {
  margin-inline-end: -0.875rem !important;
}

.-tw-me-32 {
  margin-inline-end: -8rem !important;
}

.-tw-me-36 {
  margin-inline-end: -9rem !important;
}

.-tw-me-4 {
  margin-inline-end: -1rem !important;
}

.-tw-me-40 {
  margin-inline-end: -10rem !important;
}

.-tw-me-44 {
  margin-inline-end: -11rem !important;
}

.-tw-me-48 {
  margin-inline-end: -12rem !important;
}

.-tw-me-5 {
  margin-inline-end: -1.25rem !important;
}

.-tw-me-52 {
  margin-inline-end: -13rem !important;
}

.-tw-me-56 {
  margin-inline-end: -14rem !important;
}

.-tw-me-6 {
  margin-inline-end: -1.5rem !important;
}

.-tw-me-60 {
  margin-inline-end: -15rem !important;
}

.-tw-me-64 {
  margin-inline-end: -16rem !important;
}

.-tw-me-7 {
  margin-inline-end: -1.75rem !important;
}

.-tw-me-72 {
  margin-inline-end: -18rem !important;
}

.-tw-me-8 {
  margin-inline-end: -2rem !important;
}

.-tw-me-80 {
  margin-inline-end: -20rem !important;
}

.-tw-me-9 {
  margin-inline-end: -2.25rem !important;
}

.-tw-me-96 {
  margin-inline-end: -24rem !important;
}

.-tw-me-px {
  margin-inline-end: -1px !important;
}

.-tw-ml-0 {
  margin-left: -0px !important;
}

.-tw-ml-0\.5 {
  margin-left: -0.125rem !important;
}

.-tw-ml-1 {
  margin-left: -0.25rem !important;
}

.-tw-ml-1\.5 {
  margin-left: -0.375rem !important;
}

.-tw-ml-10 {
  margin-left: -2.5rem !important;
}

.-tw-ml-11 {
  margin-left: -2.75rem !important;
}

.-tw-ml-12 {
  margin-left: -3rem !important;
}

.-tw-ml-14 {
  margin-left: -3.5rem !important;
}

.-tw-ml-16 {
  margin-left: -4rem !important;
}

.-tw-ml-2 {
  margin-left: -0.5rem !important;
}

.-tw-ml-2\.5 {
  margin-left: -0.625rem !important;
}

.-tw-ml-20 {
  margin-left: -5rem !important;
}

.-tw-ml-24 {
  margin-left: -6rem !important;
}

.-tw-ml-28 {
  margin-left: -7rem !important;
}

.-tw-ml-3 {
  margin-left: -0.75rem !important;
}

.-tw-ml-3\.5 {
  margin-left: -0.875rem !important;
}

.-tw-ml-32 {
  margin-left: -8rem !important;
}

.-tw-ml-36 {
  margin-left: -9rem !important;
}

.-tw-ml-4 {
  margin-left: -1rem !important;
}

.-tw-ml-40 {
  margin-left: -10rem !important;
}

.-tw-ml-44 {
  margin-left: -11rem !important;
}

.-tw-ml-48 {
  margin-left: -12rem !important;
}

.-tw-ml-5 {
  margin-left: -1.25rem !important;
}

.-tw-ml-52 {
  margin-left: -13rem !important;
}

.-tw-ml-56 {
  margin-left: -14rem !important;
}

.-tw-ml-6 {
  margin-left: -1.5rem !important;
}

.-tw-ml-60 {
  margin-left: -15rem !important;
}

.-tw-ml-64 {
  margin-left: -16rem !important;
}

.-tw-ml-7 {
  margin-left: -1.75rem !important;
}

.-tw-ml-72 {
  margin-left: -18rem !important;
}

.-tw-ml-8 {
  margin-left: -2rem !important;
}

.-tw-ml-80 {
  margin-left: -20rem !important;
}

.-tw-ml-9 {
  margin-left: -2.25rem !important;
}

.-tw-ml-96 {
  margin-left: -24rem !important;
}

.-tw-ml-px {
  margin-left: -1px !important;
}

.-tw-mr-0 {
  margin-right: -0px !important;
}

.-tw-mr-0\.5 {
  margin-right: -0.125rem !important;
}

.-tw-mr-1 {
  margin-right: -0.25rem !important;
}

.-tw-mr-1\.5 {
  margin-right: -0.375rem !important;
}

.-tw-mr-10 {
  margin-right: -2.5rem !important;
}

.-tw-mr-11 {
  margin-right: -2.75rem !important;
}

.-tw-mr-12 {
  margin-right: -3rem !important;
}

.-tw-mr-14 {
  margin-right: -3.5rem !important;
}

.-tw-mr-16 {
  margin-right: -4rem !important;
}

.-tw-mr-2 {
  margin-right: -0.5rem !important;
}

.-tw-mr-2\.5 {
  margin-right: -0.625rem !important;
}

.-tw-mr-20 {
  margin-right: -5rem !important;
}

.-tw-mr-24 {
  margin-right: -6rem !important;
}

.-tw-mr-28 {
  margin-right: -7rem !important;
}

.-tw-mr-3 {
  margin-right: -0.75rem !important;
}

.-tw-mr-3\.5 {
  margin-right: -0.875rem !important;
}

.-tw-mr-32 {
  margin-right: -8rem !important;
}

.-tw-mr-36 {
  margin-right: -9rem !important;
}

.-tw-mr-4 {
  margin-right: -1rem !important;
}

.-tw-mr-40 {
  margin-right: -10rem !important;
}

.-tw-mr-44 {
  margin-right: -11rem !important;
}

.-tw-mr-48 {
  margin-right: -12rem !important;
}

.-tw-mr-5 {
  margin-right: -1.25rem !important;
}

.-tw-mr-52 {
  margin-right: -13rem !important;
}

.-tw-mr-56 {
  margin-right: -14rem !important;
}

.-tw-mr-6 {
  margin-right: -1.5rem !important;
}

.-tw-mr-60 {
  margin-right: -15rem !important;
}

.-tw-mr-64 {
  margin-right: -16rem !important;
}

.-tw-mr-7 {
  margin-right: -1.75rem !important;
}

.-tw-mr-72 {
  margin-right: -18rem !important;
}

.-tw-mr-8 {
  margin-right: -2rem !important;
}

.-tw-mr-80 {
  margin-right: -20rem !important;
}

.-tw-mr-9 {
  margin-right: -2.25rem !important;
}

.-tw-mr-96 {
  margin-right: -24rem !important;
}

.-tw-mr-px {
  margin-right: -1px !important;
}

.-tw-ms-0 {
  margin-inline-start: -0px !important;
}

.-tw-ms-0\.5 {
  margin-inline-start: -0.125rem !important;
}

.-tw-ms-1 {
  margin-inline-start: -0.25rem !important;
}

.-tw-ms-1\.5 {
  margin-inline-start: -0.375rem !important;
}

.-tw-ms-10 {
  margin-inline-start: -2.5rem !important;
}

.-tw-ms-11 {
  margin-inline-start: -2.75rem !important;
}

.-tw-ms-12 {
  margin-inline-start: -3rem !important;
}

.-tw-ms-14 {
  margin-inline-start: -3.5rem !important;
}

.-tw-ms-16 {
  margin-inline-start: -4rem !important;
}

.-tw-ms-2 {
  margin-inline-start: -0.5rem !important;
}

.-tw-ms-2\.5 {
  margin-inline-start: -0.625rem !important;
}

.-tw-ms-20 {
  margin-inline-start: -5rem !important;
}

.-tw-ms-24 {
  margin-inline-start: -6rem !important;
}

.-tw-ms-28 {
  margin-inline-start: -7rem !important;
}

.-tw-ms-3 {
  margin-inline-start: -0.75rem !important;
}

.-tw-ms-3\.5 {
  margin-inline-start: -0.875rem !important;
}

.-tw-ms-32 {
  margin-inline-start: -8rem !important;
}

.-tw-ms-36 {
  margin-inline-start: -9rem !important;
}

.-tw-ms-4 {
  margin-inline-start: -1rem !important;
}

.-tw-ms-40 {
  margin-inline-start: -10rem !important;
}

.-tw-ms-44 {
  margin-inline-start: -11rem !important;
}

.-tw-ms-48 {
  margin-inline-start: -12rem !important;
}

.-tw-ms-5 {
  margin-inline-start: -1.25rem !important;
}

.-tw-ms-52 {
  margin-inline-start: -13rem !important;
}

.-tw-ms-56 {
  margin-inline-start: -14rem !important;
}

.-tw-ms-6 {
  margin-inline-start: -1.5rem !important;
}

.-tw-ms-60 {
  margin-inline-start: -15rem !important;
}

.-tw-ms-64 {
  margin-inline-start: -16rem !important;
}

.-tw-ms-7 {
  margin-inline-start: -1.75rem !important;
}

.-tw-ms-72 {
  margin-inline-start: -18rem !important;
}

.-tw-ms-8 {
  margin-inline-start: -2rem !important;
}

.-tw-ms-80 {
  margin-inline-start: -20rem !important;
}

.-tw-ms-9 {
  margin-inline-start: -2.25rem !important;
}

.-tw-ms-96 {
  margin-inline-start: -24rem !important;
}

.-tw-ms-px {
  margin-inline-start: -1px !important;
}

.-tw-mt-0 {
  margin-top: -0px !important;
}

.-tw-mt-0\.5 {
  margin-top: -0.125rem !important;
}

.-tw-mt-1 {
  margin-top: -0.25rem !important;
}

.-tw-mt-1\.5 {
  margin-top: -0.375rem !important;
}

.-tw-mt-10 {
  margin-top: -2.5rem !important;
}

.-tw-mt-11 {
  margin-top: -2.75rem !important;
}

.-tw-mt-12 {
  margin-top: -3rem !important;
}

.-tw-mt-14 {
  margin-top: -3.5rem !important;
}

.-tw-mt-16 {
  margin-top: -4rem !important;
}

.-tw-mt-2 {
  margin-top: -0.5rem !important;
}

.-tw-mt-2\.5 {
  margin-top: -0.625rem !important;
}

.-tw-mt-20 {
  margin-top: -5rem !important;
}

.-tw-mt-24 {
  margin-top: -6rem !important;
}

.-tw-mt-28 {
  margin-top: -7rem !important;
}

.-tw-mt-3 {
  margin-top: -0.75rem !important;
}

.-tw-mt-3\.5 {
  margin-top: -0.875rem !important;
}

.-tw-mt-32 {
  margin-top: -8rem !important;
}

.-tw-mt-36 {
  margin-top: -9rem !important;
}

.-tw-mt-4 {
  margin-top: -1rem !important;
}

.-tw-mt-40 {
  margin-top: -10rem !important;
}

.-tw-mt-44 {
  margin-top: -11rem !important;
}

.-tw-mt-48 {
  margin-top: -12rem !important;
}

.-tw-mt-5 {
  margin-top: -1.25rem !important;
}

.-tw-mt-52 {
  margin-top: -13rem !important;
}

.-tw-mt-56 {
  margin-top: -14rem !important;
}

.-tw-mt-6 {
  margin-top: -1.5rem !important;
}

.-tw-mt-60 {
  margin-top: -15rem !important;
}

.-tw-mt-64 {
  margin-top: -16rem !important;
}

.-tw-mt-7 {
  margin-top: -1.75rem !important;
}

.-tw-mt-72 {
  margin-top: -18rem !important;
}

.-tw-mt-8 {
  margin-top: -2rem !important;
}

.-tw-mt-80 {
  margin-top: -20rem !important;
}

.-tw-mt-9 {
  margin-top: -2.25rem !important;
}

.-tw-mt-96 {
  margin-top: -24rem !important;
}

.-tw-mt-px {
  margin-top: -1px !important;
}

.tw--mb-0 {
  margin-bottom: -0px !important;
}

.tw--mb-0\.5 {
  margin-bottom: -0.125rem !important;
}

.tw--mb-1 {
  margin-bottom: -0.25rem !important;
}

.tw--mb-1\.5 {
  margin-bottom: -0.375rem !important;
}

.tw--mb-10 {
  margin-bottom: -2.5rem !important;
}

.tw--mb-11 {
  margin-bottom: -2.75rem !important;
}

.tw--mb-12 {
  margin-bottom: -3rem !important;
}

.tw--mb-14 {
  margin-bottom: -3.5rem !important;
}

.tw--mb-16 {
  margin-bottom: -4rem !important;
}

.tw--mb-2 {
  margin-bottom: -0.5rem !important;
}

.tw--mb-2\.5 {
  margin-bottom: -0.625rem !important;
}

.tw--mb-20 {
  margin-bottom: -5rem !important;
}

.tw--mb-24 {
  margin-bottom: -6rem !important;
}

.tw--mb-28 {
  margin-bottom: -7rem !important;
}

.tw--mb-3 {
  margin-bottom: -0.75rem !important;
}

.tw--mb-3\.5 {
  margin-bottom: -0.875rem !important;
}

.tw--mb-32 {
  margin-bottom: -8rem !important;
}

.tw--mb-36 {
  margin-bottom: -9rem !important;
}

.tw--mb-4 {
  margin-bottom: -1rem !important;
}

.tw--mb-40 {
  margin-bottom: -10rem !important;
}

.tw--mb-44 {
  margin-bottom: -11rem !important;
}

.tw--mb-48 {
  margin-bottom: -12rem !important;
}

.tw--mb-5 {
  margin-bottom: -1.25rem !important;
}

.tw--mb-52 {
  margin-bottom: -13rem !important;
}

.tw--mb-56 {
  margin-bottom: -14rem !important;
}

.tw--mb-6 {
  margin-bottom: -1.5rem !important;
}

.tw--mb-60 {
  margin-bottom: -15rem !important;
}

.tw--mb-64 {
  margin-bottom: -16rem !important;
}

.tw--mb-7 {
  margin-bottom: -1.75rem !important;
}

.tw--mb-72 {
  margin-bottom: -18rem !important;
}

.tw--mb-8 {
  margin-bottom: -2rem !important;
}

.tw--mb-80 {
  margin-bottom: -20rem !important;
}

.tw--mb-9 {
  margin-bottom: -2.25rem !important;
}

.tw--mb-96 {
  margin-bottom: -24rem !important;
}

.tw--mb-px {
  margin-bottom: -1px !important;
}

.tw--me-0 {
  margin-inline-end: -0px !important;
}

.tw--me-0\.5 {
  margin-inline-end: -0.125rem !important;
}

.tw--me-1 {
  margin-inline-end: -0.25rem !important;
}

.tw--me-1\.5 {
  margin-inline-end: -0.375rem !important;
}

.tw--me-10 {
  margin-inline-end: -2.5rem !important;
}

.tw--me-11 {
  margin-inline-end: -2.75rem !important;
}

.tw--me-12 {
  margin-inline-end: -3rem !important;
}

.tw--me-14 {
  margin-inline-end: -3.5rem !important;
}

.tw--me-16 {
  margin-inline-end: -4rem !important;
}

.tw--me-2 {
  margin-inline-end: -0.5rem !important;
}

.tw--me-2\.5 {
  margin-inline-end: -0.625rem !important;
}

.tw--me-20 {
  margin-inline-end: -5rem !important;
}

.tw--me-24 {
  margin-inline-end: -6rem !important;
}

.tw--me-28 {
  margin-inline-end: -7rem !important;
}

.tw--me-3 {
  margin-inline-end: -0.75rem !important;
}

.tw--me-3\.5 {
  margin-inline-end: -0.875rem !important;
}

.tw--me-32 {
  margin-inline-end: -8rem !important;
}

.tw--me-36 {
  margin-inline-end: -9rem !important;
}

.tw--me-4 {
  margin-inline-end: -1rem !important;
}

.tw--me-40 {
  margin-inline-end: -10rem !important;
}

.tw--me-44 {
  margin-inline-end: -11rem !important;
}

.tw--me-48 {
  margin-inline-end: -12rem !important;
}

.tw--me-5 {
  margin-inline-end: -1.25rem !important;
}

.tw--me-52 {
  margin-inline-end: -13rem !important;
}

.tw--me-56 {
  margin-inline-end: -14rem !important;
}

.tw--me-6 {
  margin-inline-end: -1.5rem !important;
}

.tw--me-60 {
  margin-inline-end: -15rem !important;
}

.tw--me-64 {
  margin-inline-end: -16rem !important;
}

.tw--me-7 {
  margin-inline-end: -1.75rem !important;
}

.tw--me-72 {
  margin-inline-end: -18rem !important;
}

.tw--me-8 {
  margin-inline-end: -2rem !important;
}

.tw--me-80 {
  margin-inline-end: -20rem !important;
}

.tw--me-9 {
  margin-inline-end: -2.25rem !important;
}

.tw--me-96 {
  margin-inline-end: -24rem !important;
}

.tw--me-px {
  margin-inline-end: -1px !important;
}

.tw--ml-0 {
  margin-left: -0px !important;
}

.tw--ml-0\.5 {
  margin-left: -0.125rem !important;
}

.tw--ml-1 {
  margin-left: -0.25rem !important;
}

.tw--ml-1\.5 {
  margin-left: -0.375rem !important;
}

.tw--ml-10 {
  margin-left: -2.5rem !important;
}

.tw--ml-11 {
  margin-left: -2.75rem !important;
}

.tw--ml-12 {
  margin-left: -3rem !important;
}

.tw--ml-14 {
  margin-left: -3.5rem !important;
}

.tw--ml-16 {
  margin-left: -4rem !important;
}

.tw--ml-2 {
  margin-left: -0.5rem !important;
}

.tw--ml-2\.5 {
  margin-left: -0.625rem !important;
}

.tw--ml-20 {
  margin-left: -5rem !important;
}

.tw--ml-24 {
  margin-left: -6rem !important;
}

.tw--ml-28 {
  margin-left: -7rem !important;
}

.tw--ml-3 {
  margin-left: -0.75rem !important;
}

.tw--ml-3\.5 {
  margin-left: -0.875rem !important;
}

.tw--ml-32 {
  margin-left: -8rem !important;
}

.tw--ml-36 {
  margin-left: -9rem !important;
}

.tw--ml-4 {
  margin-left: -1rem !important;
}

.tw--ml-40 {
  margin-left: -10rem !important;
}

.tw--ml-44 {
  margin-left: -11rem !important;
}

.tw--ml-48 {
  margin-left: -12rem !important;
}

.tw--ml-5 {
  margin-left: -1.25rem !important;
}

.tw--ml-52 {
  margin-left: -13rem !important;
}

.tw--ml-56 {
  margin-left: -14rem !important;
}

.tw--ml-6 {
  margin-left: -1.5rem !important;
}

.tw--ml-60 {
  margin-left: -15rem !important;
}

.tw--ml-64 {
  margin-left: -16rem !important;
}

.tw--ml-7 {
  margin-left: -1.75rem !important;
}

.tw--ml-72 {
  margin-left: -18rem !important;
}

.tw--ml-8 {
  margin-left: -2rem !important;
}

.tw--ml-80 {
  margin-left: -20rem !important;
}

.tw--ml-9 {
  margin-left: -2.25rem !important;
}

.tw--ml-96 {
  margin-left: -24rem !important;
}

.tw--ml-px {
  margin-left: -1px !important;
}

.tw--mr-0 {
  margin-right: -0px !important;
}

.tw--mr-0\.5 {
  margin-right: -0.125rem !important;
}

.tw--mr-1 {
  margin-right: -0.25rem !important;
}

.tw--mr-1\.5 {
  margin-right: -0.375rem !important;
}

.tw--mr-10 {
  margin-right: -2.5rem !important;
}

.tw--mr-11 {
  margin-right: -2.75rem !important;
}

.tw--mr-12 {
  margin-right: -3rem !important;
}

.tw--mr-14 {
  margin-right: -3.5rem !important;
}

.tw--mr-16 {
  margin-right: -4rem !important;
}

.tw--mr-2 {
  margin-right: -0.5rem !important;
}

.tw--mr-2\.5 {
  margin-right: -0.625rem !important;
}

.tw--mr-20 {
  margin-right: -5rem !important;
}

.tw--mr-24 {
  margin-right: -6rem !important;
}

.tw--mr-28 {
  margin-right: -7rem !important;
}

.tw--mr-3 {
  margin-right: -0.75rem !important;
}

.tw--mr-3\.5 {
  margin-right: -0.875rem !important;
}

.tw--mr-32 {
  margin-right: -8rem !important;
}

.tw--mr-36 {
  margin-right: -9rem !important;
}

.tw--mr-4 {
  margin-right: -1rem !important;
}

.tw--mr-40 {
  margin-right: -10rem !important;
}

.tw--mr-44 {
  margin-right: -11rem !important;
}

.tw--mr-48 {
  margin-right: -12rem !important;
}

.tw--mr-5 {
  margin-right: -1.25rem !important;
}

.tw--mr-52 {
  margin-right: -13rem !important;
}

.tw--mr-56 {
  margin-right: -14rem !important;
}

.tw--mr-6 {
  margin-right: -1.5rem !important;
}

.tw--mr-60 {
  margin-right: -15rem !important;
}

.tw--mr-64 {
  margin-right: -16rem !important;
}

.tw--mr-7 {
  margin-right: -1.75rem !important;
}

.tw--mr-72 {
  margin-right: -18rem !important;
}

.tw--mr-8 {
  margin-right: -2rem !important;
}

.tw--mr-80 {
  margin-right: -20rem !important;
}

.tw--mr-9 {
  margin-right: -2.25rem !important;
}

.tw--mr-96 {
  margin-right: -24rem !important;
}

.tw--mr-px {
  margin-right: -1px !important;
}

.tw--ms-0 {
  margin-inline-start: -0px !important;
}

.tw--ms-0\.5 {
  margin-inline-start: -0.125rem !important;
}

.tw--ms-1 {
  margin-inline-start: -0.25rem !important;
}

.tw--ms-1\.5 {
  margin-inline-start: -0.375rem !important;
}

.tw--ms-10 {
  margin-inline-start: -2.5rem !important;
}

.tw--ms-11 {
  margin-inline-start: -2.75rem !important;
}

.tw--ms-12 {
  margin-inline-start: -3rem !important;
}

.tw--ms-14 {
  margin-inline-start: -3.5rem !important;
}

.tw--ms-16 {
  margin-inline-start: -4rem !important;
}

.tw--ms-2 {
  margin-inline-start: -0.5rem !important;
}

.tw--ms-2\.5 {
  margin-inline-start: -0.625rem !important;
}

.tw--ms-20 {
  margin-inline-start: -5rem !important;
}

.tw--ms-24 {
  margin-inline-start: -6rem !important;
}

.tw--ms-28 {
  margin-inline-start: -7rem !important;
}

.tw--ms-3 {
  margin-inline-start: -0.75rem !important;
}

.tw--ms-3\.5 {
  margin-inline-start: -0.875rem !important;
}

.tw--ms-32 {
  margin-inline-start: -8rem !important;
}

.tw--ms-36 {
  margin-inline-start: -9rem !important;
}

.tw--ms-4 {
  margin-inline-start: -1rem !important;
}

.tw--ms-40 {
  margin-inline-start: -10rem !important;
}

.tw--ms-44 {
  margin-inline-start: -11rem !important;
}

.tw--ms-48 {
  margin-inline-start: -12rem !important;
}

.tw--ms-5 {
  margin-inline-start: -1.25rem !important;
}

.tw--ms-52 {
  margin-inline-start: -13rem !important;
}

.tw--ms-56 {
  margin-inline-start: -14rem !important;
}

.tw--ms-6 {
  margin-inline-start: -1.5rem !important;
}

.tw--ms-60 {
  margin-inline-start: -15rem !important;
}

.tw--ms-64 {
  margin-inline-start: -16rem !important;
}

.tw--ms-7 {
  margin-inline-start: -1.75rem !important;
}

.tw--ms-72 {
  margin-inline-start: -18rem !important;
}

.tw--ms-8 {
  margin-inline-start: -2rem !important;
}

.tw--ms-80 {
  margin-inline-start: -20rem !important;
}

.tw--ms-9 {
  margin-inline-start: -2.25rem !important;
}

.tw--ms-96 {
  margin-inline-start: -24rem !important;
}

.tw--ms-px {
  margin-inline-start: -1px !important;
}

.tw--mt-0 {
  margin-top: -0px !important;
}

.tw--mt-0\.5 {
  margin-top: -0.125rem !important;
}

.tw--mt-1 {
  margin-top: -0.25rem !important;
}

.tw--mt-1\.5 {
  margin-top: -0.375rem !important;
}

.tw--mt-10 {
  margin-top: -2.5rem !important;
}

.tw--mt-11 {
  margin-top: -2.75rem !important;
}

.tw--mt-12 {
  margin-top: -3rem !important;
}

.tw--mt-14 {
  margin-top: -3.5rem !important;
}

.tw--mt-16 {
  margin-top: -4rem !important;
}

.tw--mt-2 {
  margin-top: -0.5rem !important;
}

.tw--mt-2\.5 {
  margin-top: -0.625rem !important;
}

.tw--mt-20 {
  margin-top: -5rem !important;
}

.tw--mt-24 {
  margin-top: -6rem !important;
}

.tw--mt-28 {
  margin-top: -7rem !important;
}

.tw--mt-3 {
  margin-top: -0.75rem !important;
}

.tw--mt-3\.5 {
  margin-top: -0.875rem !important;
}

.tw--mt-32 {
  margin-top: -8rem !important;
}

.tw--mt-36 {
  margin-top: -9rem !important;
}

.tw--mt-4 {
  margin-top: -1rem !important;
}

.tw--mt-40 {
  margin-top: -10rem !important;
}

.tw--mt-44 {
  margin-top: -11rem !important;
}

.tw--mt-48 {
  margin-top: -12rem !important;
}

.tw--mt-5 {
  margin-top: -1.25rem !important;
}

.tw--mt-52 {
  margin-top: -13rem !important;
}

.tw--mt-56 {
  margin-top: -14rem !important;
}

.tw--mt-6 {
  margin-top: -1.5rem !important;
}

.tw--mt-60 {
  margin-top: -15rem !important;
}

.tw--mt-64 {
  margin-top: -16rem !important;
}

.tw--mt-7 {
  margin-top: -1.75rem !important;
}

.tw--mt-72 {
  margin-top: -18rem !important;
}

.tw--mt-8 {
  margin-top: -2rem !important;
}

.tw--mt-80 {
  margin-top: -20rem !important;
}

.tw--mt-9 {
  margin-top: -2.25rem !important;
}

.tw--mt-96 {
  margin-top: -24rem !important;
}

.tw--mt-px {
  margin-top: -1px !important;
}

.tw-mb-0 {
  margin-bottom: 0px !important;
}

.tw-mb-0\.5 {
  margin-bottom: 0.125rem !important;
}

.tw-mb-1 {
  margin-bottom: 0.25rem !important;
}

.tw-mb-1\.5 {
  margin-bottom: 0.375rem !important;
}

.tw-mb-10 {
  margin-bottom: 2.5rem !important;
}

.tw-mb-11 {
  margin-bottom: 2.75rem !important;
}

.tw-mb-12 {
  margin-bottom: 3rem !important;
}

.tw-mb-14 {
  margin-bottom: 3.5rem !important;
}

.tw-mb-16 {
  margin-bottom: 4rem !important;
}

.tw-mb-2 {
  margin-bottom: 0.5rem !important;
}

.tw-mb-2\.5 {
  margin-bottom: 0.625rem !important;
}

.tw-mb-20 {
  margin-bottom: 5rem !important;
}

.tw-mb-24 {
  margin-bottom: 6rem !important;
}

.tw-mb-28 {
  margin-bottom: 7rem !important;
}

.tw-mb-3 {
  margin-bottom: 0.75rem !important;
}

.tw-mb-3\.5 {
  margin-bottom: 0.875rem !important;
}

.tw-mb-32 {
  margin-bottom: 8rem !important;
}

.tw-mb-36 {
  margin-bottom: 9rem !important;
}

.tw-mb-4 {
  margin-bottom: 1rem !important;
}

.tw-mb-40 {
  margin-bottom: 10rem !important;
}

.tw-mb-44 {
  margin-bottom: 11rem !important;
}

.tw-mb-48 {
  margin-bottom: 12rem !important;
}

.tw-mb-5 {
  margin-bottom: 1.25rem !important;
}

.tw-mb-52 {
  margin-bottom: 13rem !important;
}

.tw-mb-56 {
  margin-bottom: 14rem !important;
}

.tw-mb-6 {
  margin-bottom: 1.5rem !important;
}

.tw-mb-60 {
  margin-bottom: 15rem !important;
}

.tw-mb-64 {
  margin-bottom: 16rem !important;
}

.tw-mb-7 {
  margin-bottom: 1.75rem !important;
}

.tw-mb-72 {
  margin-bottom: 18rem !important;
}

.tw-mb-8 {
  margin-bottom: 2rem !important;
}

.tw-mb-80 {
  margin-bottom: 20rem !important;
}

.tw-mb-9 {
  margin-bottom: 2.25rem !important;
}

.tw-mb-96 {
  margin-bottom: 24rem !important;
}

.tw-mb-auto {
  margin-bottom: auto !important;
}

.tw-mb-px {
  margin-bottom: 1px !important;
}

.tw-me-0 {
  margin-inline-end: 0px !important;
}

.tw-me-0\.5 {
  margin-inline-end: 0.125rem !important;
}

.tw-me-1 {
  margin-inline-end: 0.25rem !important;
}

.tw-me-1\.5 {
  margin-inline-end: 0.375rem !important;
}

.tw-me-10 {
  margin-inline-end: 2.5rem !important;
}

.tw-me-11 {
  margin-inline-end: 2.75rem !important;
}

.tw-me-12 {
  margin-inline-end: 3rem !important;
}

.tw-me-14 {
  margin-inline-end: 3.5rem !important;
}

.tw-me-16 {
  margin-inline-end: 4rem !important;
}

.tw-me-2 {
  margin-inline-end: 0.5rem !important;
}

.tw-me-2\.5 {
  margin-inline-end: 0.625rem !important;
}

.tw-me-20 {
  margin-inline-end: 5rem !important;
}

.tw-me-24 {
  margin-inline-end: 6rem !important;
}

.tw-me-28 {
  margin-inline-end: 7rem !important;
}

.tw-me-3 {
  margin-inline-end: 0.75rem !important;
}

.tw-me-3\.5 {
  margin-inline-end: 0.875rem !important;
}

.tw-me-32 {
  margin-inline-end: 8rem !important;
}

.tw-me-36 {
  margin-inline-end: 9rem !important;
}

.tw-me-4 {
  margin-inline-end: 1rem !important;
}

.tw-me-40 {
  margin-inline-end: 10rem !important;
}

.tw-me-44 {
  margin-inline-end: 11rem !important;
}

.tw-me-48 {
  margin-inline-end: 12rem !important;
}

.tw-me-5 {
  margin-inline-end: 1.25rem !important;
}

.tw-me-52 {
  margin-inline-end: 13rem !important;
}

.tw-me-56 {
  margin-inline-end: 14rem !important;
}

.tw-me-6 {
  margin-inline-end: 1.5rem !important;
}

.tw-me-60 {
  margin-inline-end: 15rem !important;
}

.tw-me-64 {
  margin-inline-end: 16rem !important;
}

.tw-me-7 {
  margin-inline-end: 1.75rem !important;
}

.tw-me-72 {
  margin-inline-end: 18rem !important;
}

.tw-me-8 {
  margin-inline-end: 2rem !important;
}

.tw-me-80 {
  margin-inline-end: 20rem !important;
}

.tw-me-9 {
  margin-inline-end: 2.25rem !important;
}

.tw-me-96 {
  margin-inline-end: 24rem !important;
}

.tw-me-auto {
  margin-inline-end: auto !important;
}

.tw-me-px {
  margin-inline-end: 1px !important;
}

.tw-ml-0 {
  margin-left: 0px !important;
}

.tw-ml-0\.5 {
  margin-left: 0.125rem !important;
}

.tw-ml-1 {
  margin-left: 0.25rem !important;
}

.tw-ml-1\.5 {
  margin-left: 0.375rem !important;
}

.tw-ml-10 {
  margin-left: 2.5rem !important;
}

.tw-ml-11 {
  margin-left: 2.75rem !important;
}

.tw-ml-12 {
  margin-left: 3rem !important;
}

.tw-ml-14 {
  margin-left: 3.5rem !important;
}

.tw-ml-16 {
  margin-left: 4rem !important;
}

.tw-ml-2 {
  margin-left: 0.5rem !important;
}

.tw-ml-2\.5 {
  margin-left: 0.625rem !important;
}

.tw-ml-20 {
  margin-left: 5rem !important;
}

.tw-ml-24 {
  margin-left: 6rem !important;
}

.tw-ml-28 {
  margin-left: 7rem !important;
}

.tw-ml-3 {
  margin-left: 0.75rem !important;
}

.tw-ml-3\.5 {
  margin-left: 0.875rem !important;
}

.tw-ml-32 {
  margin-left: 8rem !important;
}

.tw-ml-36 {
  margin-left: 9rem !important;
}

.tw-ml-4 {
  margin-left: 1rem !important;
}

.tw-ml-40 {
  margin-left: 10rem !important;
}

.tw-ml-44 {
  margin-left: 11rem !important;
}

.tw-ml-48 {
  margin-left: 12rem !important;
}

.tw-ml-5 {
  margin-left: 1.25rem !important;
}

.tw-ml-52 {
  margin-left: 13rem !important;
}

.tw-ml-56 {
  margin-left: 14rem !important;
}

.tw-ml-6 {
  margin-left: 1.5rem !important;
}

.tw-ml-60 {
  margin-left: 15rem !important;
}

.tw-ml-64 {
  margin-left: 16rem !important;
}

.tw-ml-7 {
  margin-left: 1.75rem !important;
}

.tw-ml-72 {
  margin-left: 18rem !important;
}

.tw-ml-8 {
  margin-left: 2rem !important;
}

.tw-ml-80 {
  margin-left: 20rem !important;
}

.tw-ml-9 {
  margin-left: 2.25rem !important;
}

.tw-ml-96 {
  margin-left: 24rem !important;
}

.tw-ml-auto {
  margin-left: auto !important;
}

.tw-ml-px {
  margin-left: 1px !important;
}

.tw-mr-0 {
  margin-right: 0px !important;
}

.tw-mr-0\.5 {
  margin-right: 0.125rem !important;
}

.tw-mr-1 {
  margin-right: 0.25rem !important;
}

.tw-mr-1\.5 {
  margin-right: 0.375rem !important;
}

.tw-mr-10 {
  margin-right: 2.5rem !important;
}

.tw-mr-11 {
  margin-right: 2.75rem !important;
}

.tw-mr-12 {
  margin-right: 3rem !important;
}

.tw-mr-14 {
  margin-right: 3.5rem !important;
}

.tw-mr-16 {
  margin-right: 4rem !important;
}

.tw-mr-2 {
  margin-right: 0.5rem !important;
}

.tw-mr-2\.5 {
  margin-right: 0.625rem !important;
}

.tw-mr-20 {
  margin-right: 5rem !important;
}

.tw-mr-24 {
  margin-right: 6rem !important;
}

.tw-mr-28 {
  margin-right: 7rem !important;
}

.tw-mr-3 {
  margin-right: 0.75rem !important;
}

.tw-mr-3\.5 {
  margin-right: 0.875rem !important;
}

.tw-mr-32 {
  margin-right: 8rem !important;
}

.tw-mr-36 {
  margin-right: 9rem !important;
}

.tw-mr-4 {
  margin-right: 1rem !important;
}

.tw-mr-40 {
  margin-right: 10rem !important;
}

.tw-mr-44 {
  margin-right: 11rem !important;
}

.tw-mr-48 {
  margin-right: 12rem !important;
}

.tw-mr-5 {
  margin-right: 1.25rem !important;
}

.tw-mr-52 {
  margin-right: 13rem !important;
}

.tw-mr-56 {
  margin-right: 14rem !important;
}

.tw-mr-6 {
  margin-right: 1.5rem !important;
}

.tw-mr-60 {
  margin-right: 15rem !important;
}

.tw-mr-64 {
  margin-right: 16rem !important;
}

.tw-mr-7 {
  margin-right: 1.75rem !important;
}

.tw-mr-72 {
  margin-right: 18rem !important;
}

.tw-mr-8 {
  margin-right: 2rem !important;
}

.tw-mr-80 {
  margin-right: 20rem !important;
}

.tw-mr-9 {
  margin-right: 2.25rem !important;
}

.tw-mr-96 {
  margin-right: 24rem !important;
}

.tw-mr-auto {
  margin-right: auto !important;
}

.tw-mr-px {
  margin-right: 1px !important;
}

.tw-ms-0 {
  margin-inline-start: 0px !important;
}

.tw-ms-0\.5 {
  margin-inline-start: 0.125rem !important;
}

.tw-ms-1 {
  margin-inline-start: 0.25rem !important;
}

.tw-ms-1\.5 {
  margin-inline-start: 0.375rem !important;
}

.tw-ms-10 {
  margin-inline-start: 2.5rem !important;
}

.tw-ms-11 {
  margin-inline-start: 2.75rem !important;
}

.tw-ms-12 {
  margin-inline-start: 3rem !important;
}

.tw-ms-14 {
  margin-inline-start: 3.5rem !important;
}

.tw-ms-16 {
  margin-inline-start: 4rem !important;
}

.tw-ms-2 {
  margin-inline-start: 0.5rem !important;
}

.tw-ms-2\.5 {
  margin-inline-start: 0.625rem !important;
}

.tw-ms-20 {
  margin-inline-start: 5rem !important;
}

.tw-ms-24 {
  margin-inline-start: 6rem !important;
}

.tw-ms-28 {
  margin-inline-start: 7rem !important;
}

.tw-ms-3 {
  margin-inline-start: 0.75rem !important;
}

.tw-ms-3\.5 {
  margin-inline-start: 0.875rem !important;
}

.tw-ms-32 {
  margin-inline-start: 8rem !important;
}

.tw-ms-36 {
  margin-inline-start: 9rem !important;
}

.tw-ms-4 {
  margin-inline-start: 1rem !important;
}

.tw-ms-40 {
  margin-inline-start: 10rem !important;
}

.tw-ms-44 {
  margin-inline-start: 11rem !important;
}

.tw-ms-48 {
  margin-inline-start: 12rem !important;
}

.tw-ms-5 {
  margin-inline-start: 1.25rem !important;
}

.tw-ms-52 {
  margin-inline-start: 13rem !important;
}

.tw-ms-56 {
  margin-inline-start: 14rem !important;
}

.tw-ms-6 {
  margin-inline-start: 1.5rem !important;
}

.tw-ms-60 {
  margin-inline-start: 15rem !important;
}

.tw-ms-64 {
  margin-inline-start: 16rem !important;
}

.tw-ms-7 {
  margin-inline-start: 1.75rem !important;
}

.tw-ms-72 {
  margin-inline-start: 18rem !important;
}

.tw-ms-8 {
  margin-inline-start: 2rem !important;
}

.tw-ms-80 {
  margin-inline-start: 20rem !important;
}

.tw-ms-9 {
  margin-inline-start: 2.25rem !important;
}

.tw-ms-96 {
  margin-inline-start: 24rem !important;
}

.tw-ms-auto {
  margin-inline-start: auto !important;
}

.tw-ms-px {
  margin-inline-start: 1px !important;
}

.tw-mt-0 {
  margin-top: 0px !important;
}

.tw-mt-0\.5 {
  margin-top: 0.125rem !important;
}

.tw-mt-1 {
  margin-top: 0.25rem !important;
}

.tw-mt-1\.5 {
  margin-top: 0.375rem !important;
}

.tw-mt-10 {
  margin-top: 2.5rem !important;
}

.tw-mt-11 {
  margin-top: 2.75rem !important;
}

.tw-mt-12 {
  margin-top: 3rem !important;
}

.tw-mt-14 {
  margin-top: 3.5rem !important;
}

.tw-mt-16 {
  margin-top: 4rem !important;
}

.tw-mt-2 {
  margin-top: 0.5rem !important;
}

.tw-mt-2\.5 {
  margin-top: 0.625rem !important;
}

.tw-mt-20 {
  margin-top: 5rem !important;
}

.tw-mt-24 {
  margin-top: 6rem !important;
}

.tw-mt-28 {
  margin-top: 7rem !important;
}

.tw-mt-3 {
  margin-top: 0.75rem !important;
}

.tw-mt-3\.5 {
  margin-top: 0.875rem !important;
}

.tw-mt-32 {
  margin-top: 8rem !important;
}

.tw-mt-36 {
  margin-top: 9rem !important;
}

.tw-mt-4 {
  margin-top: 1rem !important;
}

.tw-mt-40 {
  margin-top: 10rem !important;
}

.tw-mt-44 {
  margin-top: 11rem !important;
}

.tw-mt-48 {
  margin-top: 12rem !important;
}

.tw-mt-5 {
  margin-top: 1.25rem !important;
}

.tw-mt-52 {
  margin-top: 13rem !important;
}

.tw-mt-56 {
  margin-top: 14rem !important;
}

.tw-mt-6 {
  margin-top: 1.5rem !important;
}

.tw-mt-60 {
  margin-top: 15rem !important;
}

.tw-mt-64 {
  margin-top: 16rem !important;
}

.tw-mt-7 {
  margin-top: 1.75rem !important;
}

.tw-mt-72 {
  margin-top: 18rem !important;
}

.tw-mt-8 {
  margin-top: 2rem !important;
}

.tw-mt-80 {
  margin-top: 20rem !important;
}

.tw-mt-9 {
  margin-top: 2.25rem !important;
}

.tw-mt-96 {
  margin-top: 24rem !important;
}

.tw-mt-auto {
  margin-top: auto !important;
}

.tw-mt-px {
  margin-top: 1px !important;
}

.tw-box-border {
  box-sizing: border-box !important;
}

.tw-box-content {
  box-sizing: content-box !important;
}

.tw-line-clamp-1 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 1 !important;
}

.tw-line-clamp-2 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 2 !important;
}

.tw-line-clamp-3 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 3 !important;
}

.tw-line-clamp-4 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 4 !important;
}

.tw-line-clamp-5 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 5 !important;
}

.tw-line-clamp-6 {
  overflow: hidden !important;
  display: -webkit-box !important;
  -webkit-box-orient: vertical !important;
  -webkit-line-clamp: 6 !important;
}

.tw-line-clamp-none {
  overflow: visible !important;
  display: block !important;
  -webkit-box-orient: horizontal !important;
  -webkit-line-clamp: none !important;
}

.tw-block {
  display: block !important;
}

.tw-inline-block {
  display: inline-block !important;
}

.tw-inline {
  display: inline !important;
}

.tw-flex {
  display: flex !important;
}

.tw-inline-flex {
  display: inline-flex !important;
}

.tw-table {
  display: table !important;
}

.tw-inline-table {
  display: inline-table !important;
}

.tw-table-caption {
  display: table-caption !important;
}

.tw-table-cell {
  display: table-cell !important;
}

.tw-table-column {
  display: table-column !important;
}

.tw-table-column-group {
  display: table-column-group !important;
}

.tw-table-footer-group {
  display: table-footer-group !important;
}

.tw-table-header-group {
  display: table-header-group !important;
}

.tw-table-row-group {
  display: table-row-group !important;
}

.tw-table-row {
  display: table-row !important;
}

.tw-flow-root {
  display: flow-root !important;
}

.tw-grid {
  display: grid !important;
}

.tw-inline-grid {
  display: inline-grid !important;
}

.tw-contents {
  display: contents !important;
}

.tw-list-item {
  display: list-item !important;
}

.tw-hidden {
  display: none !important;
}

.tw-aspect-auto {
  aspect-ratio: auto !important;
}

.tw-aspect-square {
  aspect-ratio: 1 / 1 !important;
}

.tw-aspect-video {
  aspect-ratio: 16 / 9 !important;
}

.tw-size-0 {
  width: 0px !important;
  height: 0px !important;
}

.tw-size-0\.5 {
  width: 0.125rem !important;
  height: 0.125rem !important;
}

.tw-size-1 {
  width: 0.25rem !important;
  height: 0.25rem !important;
}

.tw-size-1\.5 {
  width: 0.375rem !important;
  height: 0.375rem !important;
}

.tw-size-1\/12 {
  width: 8.333333% !important;
  height: 8.333333% !important;
}

.tw-size-1\/2 {
  width: 50% !important;
  height: 50% !important;
}

.tw-size-1\/3 {
  width: 33.333333% !important;
  height: 33.333333% !important;
}

.tw-size-1\/4 {
  width: 25% !important;
  height: 25% !important;
}

.tw-size-1\/5 {
  width: 20% !important;
  height: 20% !important;
}

.tw-size-1\/6 {
  width: 16.666667% !important;
  height: 16.666667% !important;
}

.tw-size-10 {
  width: 2.5rem !important;
  height: 2.5rem !important;
}

.tw-size-10\/12 {
  width: 83.333333% !important;
  height: 83.333333% !important;
}

.tw-size-11 {
  width: 2.75rem !important;
  height: 2.75rem !important;
}

.tw-size-11\/12 {
  width: 91.666667% !important;
  height: 91.666667% !important;
}

.tw-size-12 {
  width: 3rem !important;
  height: 3rem !important;
}

.tw-size-14 {
  width: 3.5rem !important;
  height: 3.5rem !important;
}

.tw-size-16 {
  width: 4rem !important;
  height: 4rem !important;
}

.tw-size-2 {
  width: 0.5rem !important;
  height: 0.5rem !important;
}

.tw-size-2\.5 {
  width: 0.625rem !important;
  height: 0.625rem !important;
}

.tw-size-2\/12 {
  width: 16.666667% !important;
  height: 16.666667% !important;
}

.tw-size-2\/3 {
  width: 66.666667% !important;
  height: 66.666667% !important;
}

.tw-size-2\/4 {
  width: 50% !important;
  height: 50% !important;
}

.tw-size-2\/5 {
  width: 40% !important;
  height: 40% !important;
}

.tw-size-2\/6 {
  width: 33.333333% !important;
  height: 33.333333% !important;
}

.tw-size-20 {
  width: 5rem !important;
  height: 5rem !important;
}

.tw-size-24 {
  width: 6rem !important;
  height: 6rem !important;
}

.tw-size-28 {
  width: 7rem !important;
  height: 7rem !important;
}

.tw-size-3 {
  width: 0.75rem !important;
  height: 0.75rem !important;
}

.tw-size-3\.5 {
  width: 0.875rem !important;
  height: 0.875rem !important;
}

.tw-size-3\/12 {
  width: 25% !important;
  height: 25% !important;
}

.tw-size-3\/4 {
  width: 75% !important;
  height: 75% !important;
}

.tw-size-3\/5 {
  width: 60% !important;
  height: 60% !important;
}

.tw-size-3\/6 {
  width: 50% !important;
  height: 50% !important;
}

.tw-size-32 {
  width: 8rem !important;
  height: 8rem !important;
}

.tw-size-36 {
  width: 9rem !important;
  height: 9rem !important;
}

.tw-size-4 {
  width: 1rem !important;
  height: 1rem !important;
}

.tw-size-4\/12 {
  width: 33.333333% !important;
  height: 33.333333% !important;
}

.tw-size-4\/5 {
  width: 80% !important;
  height: 80% !important;
}

.tw-size-4\/6 {
  width: 66.666667% !important;
  height: 66.666667% !important;
}

.tw-size-40 {
  width: 10rem !important;
  height: 10rem !important;
}

.tw-size-44 {
  width: 11rem !important;
  height: 11rem !important;
}

.tw-size-48 {
  width: 12rem !important;
  height: 12rem !important;
}

.tw-size-5 {
  width: 1.25rem !important;
  height: 1.25rem !important;
}

.tw-size-5\/12 {
  width: 41.666667% !important;
  height: 41.666667% !important;
}

.tw-size-5\/6 {
  width: 83.333333% !important;
  height: 83.333333% !important;
}

.tw-size-52 {
  width: 13rem !important;
  height: 13rem !important;
}

.tw-size-56 {
  width: 14rem !important;
  height: 14rem !important;
}

.tw-size-6 {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.tw-size-6\/12 {
  width: 50% !important;
  height: 50% !important;
}

.tw-size-60 {
  width: 15rem !important;
  height: 15rem !important;
}

.tw-size-64 {
  width: 16rem !important;
  height: 16rem !important;
}

.tw-size-7 {
  width: 1.75rem !important;
  height: 1.75rem !important;
}

.tw-size-7\/12 {
  width: 58.333333% !important;
  height: 58.333333% !important;
}

.tw-size-72 {
  width: 18rem !important;
  height: 18rem !important;
}

.tw-size-8 {
  width: 2rem !important;
  height: 2rem !important;
}

.tw-size-8\/12 {
  width: 66.666667% !important;
  height: 66.666667% !important;
}

.tw-size-80 {
  width: 20rem !important;
  height: 20rem !important;
}

.tw-size-9 {
  width: 2.25rem !important;
  height: 2.25rem !important;
}

.tw-size-9\/12 {
  width: 75% !important;
  height: 75% !important;
}

.tw-size-96 {
  width: 24rem !important;
  height: 24rem !important;
}

.tw-size-auto {
  width: auto !important;
  height: auto !important;
}

.tw-size-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.tw-size-full {
  width: 100% !important;
  height: 100% !important;
}

.tw-size-max {
  width: -webkit-max-content !important;
  width: max-content !important;
  height: -webkit-max-content !important;
  height: max-content !important;
}

.tw-size-min {
  width: -webkit-min-content !important;
  width: min-content !important;
  height: -webkit-min-content !important;
  height: min-content !important;
}

.tw-size-px {
  width: 1px !important;
  height: 1px !important;
}

.tw-h-0 {
  height: 0px !important;
}

.tw-h-0\.5 {
  height: 0.125rem !important;
}

.tw-h-1 {
  height: 0.25rem !important;
}

.tw-h-1\.5 {
  height: 0.375rem !important;
}

.tw-h-1\/2 {
  height: 50% !important;
}

.tw-h-1\/3 {
  height: 33.333333% !important;
}

.tw-h-1\/4 {
  height: 25% !important;
}

.tw-h-1\/5 {
  height: 20% !important;
}

.tw-h-1\/6 {
  height: 16.666667% !important;
}

.tw-h-10 {
  height: 2.5rem !important;
}

.tw-h-11 {
  height: 2.75rem !important;
}

.tw-h-12 {
  height: 3rem !important;
}

.tw-h-14 {
  height: 3.5rem !important;
}

.tw-h-16 {
  height: 4rem !important;
}

.tw-h-2 {
  height: 0.5rem !important;
}

.tw-h-2\.5 {
  height: 0.625rem !important;
}

.tw-h-2\/3 {
  height: 66.666667% !important;
}

.tw-h-2\/4 {
  height: 50% !important;
}

.tw-h-2\/5 {
  height: 40% !important;
}

.tw-h-2\/6 {
  height: 33.333333% !important;
}

.tw-h-20 {
  height: 5rem !important;
}

.tw-h-24 {
  height: 6rem !important;
}

.tw-h-28 {
  height: 7rem !important;
}

.tw-h-3 {
  height: 0.75rem !important;
}

.tw-h-3\.5 {
  height: 0.875rem !important;
}

.tw-h-3\/4 {
  height: 75% !important;
}

.tw-h-3\/5 {
  height: 60% !important;
}

.tw-h-3\/6 {
  height: 50% !important;
}

.tw-h-32 {
  height: 8rem !important;
}

.tw-h-36 {
  height: 9rem !important;
}

.tw-h-4 {
  height: 1rem !important;
}

.tw-h-4\/5 {
  height: 80% !important;
}

.tw-h-4\/6 {
  height: 66.666667% !important;
}

.tw-h-40 {
  height: 10rem !important;
}

.tw-h-44 {
  height: 11rem !important;
}

.tw-h-48 {
  height: 12rem !important;
}

.tw-h-5 {
  height: 1.25rem !important;
}

.tw-h-5\/6 {
  height: 83.333333% !important;
}

.tw-h-52 {
  height: 13rem !important;
}

.tw-h-56 {
  height: 14rem !important;
}

.tw-h-6 {
  height: 1.5rem !important;
}

.tw-h-60 {
  height: 15rem !important;
}

.tw-h-64 {
  height: 16rem !important;
}

.tw-h-7 {
  height: 1.75rem !important;
}

.tw-h-72 {
  height: 18rem !important;
}

.tw-h-8 {
  height: 2rem !important;
}

.tw-h-80 {
  height: 20rem !important;
}

.tw-h-9 {
  height: 2.25rem !important;
}

.tw-h-96 {
  height: 24rem !important;
}

.tw-h-auto {
  height: auto !important;
}

.tw-h-dvh {
  height: 100dvh !important;
}

.tw-h-fit {
  height: -webkit-fit-content !important;
  height: -moz-fit-content !important;
  height: fit-content !important;
}

.tw-h-full {
  height: 100% !important;
}

.tw-h-lvh {
  height: 100lvh !important;
}

.tw-h-max {
  height: -webkit-max-content !important;
  height: max-content !important;
}

.tw-h-min {
  height: -webkit-min-content !important;
  height: min-content !important;
}

.tw-h-px {
  height: 1px !important;
}

.tw-h-screen {
  height: 100vh !important;
}

.tw-h-svh {
  height: 100svh !important;
}

.tw-max-h-0 {
  max-height: 0px !important;
}

.tw-max-h-0\.5 {
  max-height: 0.125rem !important;
}

.tw-max-h-1 {
  max-height: 0.25rem !important;
}

.tw-max-h-1\.5 {
  max-height: 0.375rem !important;
}

.tw-max-h-10 {
  max-height: 2.5rem !important;
}

.tw-max-h-11 {
  max-height: 2.75rem !important;
}

.tw-max-h-12 {
  max-height: 3rem !important;
}

.tw-max-h-14 {
  max-height: 3.5rem !important;
}

.tw-max-h-16 {
  max-height: 4rem !important;
}

.tw-max-h-2 {
  max-height: 0.5rem !important;
}

.tw-max-h-2\.5 {
  max-height: 0.625rem !important;
}

.tw-max-h-20 {
  max-height: 5rem !important;
}

.tw-max-h-24 {
  max-height: 6rem !important;
}

.tw-max-h-28 {
  max-height: 7rem !important;
}

.tw-max-h-3 {
  max-height: 0.75rem !important;
}

.tw-max-h-3\.5 {
  max-height: 0.875rem !important;
}

.tw-max-h-32 {
  max-height: 8rem !important;
}

.tw-max-h-36 {
  max-height: 9rem !important;
}

.tw-max-h-4 {
  max-height: 1rem !important;
}

.tw-max-h-40 {
  max-height: 10rem !important;
}

.tw-max-h-44 {
  max-height: 11rem !important;
}

.tw-max-h-48 {
  max-height: 12rem !important;
}

.tw-max-h-5 {
  max-height: 1.25rem !important;
}

.tw-max-h-52 {
  max-height: 13rem !important;
}

.tw-max-h-56 {
  max-height: 14rem !important;
}

.tw-max-h-6 {
  max-height: 1.5rem !important;
}

.tw-max-h-60 {
  max-height: 15rem !important;
}

.tw-max-h-64 {
  max-height: 16rem !important;
}

.tw-max-h-7 {
  max-height: 1.75rem !important;
}

.tw-max-h-72 {
  max-height: 18rem !important;
}

.tw-max-h-8 {
  max-height: 2rem !important;
}

.tw-max-h-80 {
  max-height: 20rem !important;
}

.tw-max-h-9 {
  max-height: 2.25rem !important;
}

.tw-max-h-96 {
  max-height: 24rem !important;
}

.tw-max-h-dvh {
  max-height: 100dvh !important;
}

.tw-max-h-fit {
  max-height: -webkit-fit-content !important;
  max-height: -moz-fit-content !important;
  max-height: fit-content !important;
}

.tw-max-h-full {
  max-height: 100% !important;
}

.tw-max-h-lvh {
  max-height: 100lvh !important;
}

.tw-max-h-max {
  max-height: -webkit-max-content !important;
  max-height: max-content !important;
}

.tw-max-h-min {
  max-height: -webkit-min-content !important;
  max-height: min-content !important;
}

.tw-max-h-none {
  max-height: none !important;
}

.tw-max-h-px {
  max-height: 1px !important;
}

.tw-max-h-screen {
  max-height: 100vh !important;
}

.tw-max-h-svh {
  max-height: 100svh !important;
}

.tw-min-h-0 {
  min-height: 0px !important;
}

.tw-min-h-0\.5 {
  min-height: 0.125rem !important;
}

.tw-min-h-1 {
  min-height: 0.25rem !important;
}

.tw-min-h-1\.5 {
  min-height: 0.375rem !important;
}

.tw-min-h-10 {
  min-height: 2.5rem !important;
}

.tw-min-h-11 {
  min-height: 2.75rem !important;
}

.tw-min-h-12 {
  min-height: 3rem !important;
}

.tw-min-h-14 {
  min-height: 3.5rem !important;
}

.tw-min-h-16 {
  min-height: 4rem !important;
}

.tw-min-h-2 {
  min-height: 0.5rem !important;
}

.tw-min-h-2\.5 {
  min-height: 0.625rem !important;
}

.tw-min-h-20 {
  min-height: 5rem !important;
}

.tw-min-h-24 {
  min-height: 6rem !important;
}

.tw-min-h-28 {
  min-height: 7rem !important;
}

.tw-min-h-3 {
  min-height: 0.75rem !important;
}

.tw-min-h-3\.5 {
  min-height: 0.875rem !important;
}

.tw-min-h-32 {
  min-height: 8rem !important;
}

.tw-min-h-36 {
  min-height: 9rem !important;
}

.tw-min-h-4 {
  min-height: 1rem !important;
}

.tw-min-h-40 {
  min-height: 10rem !important;
}

.tw-min-h-44 {
  min-height: 11rem !important;
}

.tw-min-h-48 {
  min-height: 12rem !important;
}

.tw-min-h-5 {
  min-height: 1.25rem !important;
}

.tw-min-h-52 {
  min-height: 13rem !important;
}

.tw-min-h-56 {
  min-height: 14rem !important;
}

.tw-min-h-6 {
  min-height: 1.5rem !important;
}

.tw-min-h-60 {
  min-height: 15rem !important;
}

.tw-min-h-64 {
  min-height: 16rem !important;
}

.tw-min-h-7 {
  min-height: 1.75rem !important;
}

.tw-min-h-72 {
  min-height: 18rem !important;
}

.tw-min-h-8 {
  min-height: 2rem !important;
}

.tw-min-h-80 {
  min-height: 20rem !important;
}

.tw-min-h-9 {
  min-height: 2.25rem !important;
}

.tw-min-h-96 {
  min-height: 24rem !important;
}

.tw-min-h-dvh {
  min-height: 100dvh !important;
}

.tw-min-h-fit {
  min-height: -webkit-fit-content !important;
  min-height: -moz-fit-content !important;
  min-height: fit-content !important;
}

.tw-min-h-full {
  min-height: 100% !important;
}

.tw-min-h-lvh {
  min-height: 100lvh !important;
}

.tw-min-h-max {
  min-height: -webkit-max-content !important;
  min-height: max-content !important;
}

.tw-min-h-min {
  min-height: -webkit-min-content !important;
  min-height: min-content !important;
}

.tw-min-h-px {
  min-height: 1px !important;
}

.tw-min-h-screen {
  min-height: 100vh !important;
}

.tw-min-h-svh {
  min-height: 100svh !important;
}

.tw-w-0 {
  width: 0px !important;
}

.tw-w-0\.5 {
  width: 0.125rem !important;
}

.tw-w-1 {
  width: 0.25rem !important;
}

.tw-w-1\.5 {
  width: 0.375rem !important;
}

.tw-w-1\/12 {
  width: 8.333333% !important;
}

.tw-w-1\/2 {
  width: 50% !important;
}

.tw-w-1\/3 {
  width: 33.333333% !important;
}

.tw-w-1\/4 {
  width: 25% !important;
}

.tw-w-1\/5 {
  width: 20% !important;
}

.tw-w-1\/6 {
  width: 16.666667% !important;
}

.tw-w-10 {
  width: 2.5rem !important;
}

.tw-w-10\/12 {
  width: 83.333333% !important;
}

.tw-w-11 {
  width: 2.75rem !important;
}

.tw-w-11\/12 {
  width: 91.666667% !important;
}

.tw-w-12 {
  width: 3rem !important;
}

.tw-w-14 {
  width: 3.5rem !important;
}

.tw-w-16 {
  width: 4rem !important;
}

.tw-w-2 {
  width: 0.5rem !important;
}

.tw-w-2\.5 {
  width: 0.625rem !important;
}

.tw-w-2\/12 {
  width: 16.666667% !important;
}

.tw-w-2\/3 {
  width: 66.666667% !important;
}

.tw-w-2\/4 {
  width: 50% !important;
}

.tw-w-2\/5 {
  width: 40% !important;
}

.tw-w-2\/6 {
  width: 33.333333% !important;
}

.tw-w-20 {
  width: 5rem !important;
}

.tw-w-24 {
  width: 6rem !important;
}

.tw-w-28 {
  width: 7rem !important;
}

.tw-w-3 {
  width: 0.75rem !important;
}

.tw-w-3\.5 {
  width: 0.875rem !important;
}

.tw-w-3\/12 {
  width: 25% !important;
}

.tw-w-3\/4 {
  width: 75% !important;
}

.tw-w-3\/5 {
  width: 60% !important;
}

.tw-w-3\/6 {
  width: 50% !important;
}

.tw-w-32 {
  width: 8rem !important;
}

.tw-w-36 {
  width: 9rem !important;
}

.tw-w-4 {
  width: 1rem !important;
}

.tw-w-4\/12 {
  width: 33.333333% !important;
}

.tw-w-4\/5 {
  width: 80% !important;
}

.tw-w-4\/6 {
  width: 66.666667% !important;
}

.tw-w-40 {
  width: 10rem !important;
}

.tw-w-44 {
  width: 11rem !important;
}

.tw-w-48 {
  width: 12rem !important;
}

.tw-w-5 {
  width: 1.25rem !important;
}

.tw-w-5\/12 {
  width: 41.666667% !important;
}

.tw-w-5\/6 {
  width: 83.333333% !important;
}

.tw-w-52 {
  width: 13rem !important;
}

.tw-w-56 {
  width: 14rem !important;
}

.tw-w-6 {
  width: 1.5rem !important;
}

.tw-w-6\/12 {
  width: 50% !important;
}

.tw-w-60 {
  width: 15rem !important;
}

.tw-w-64 {
  width: 16rem !important;
}

.tw-w-7 {
  width: 1.75rem !important;
}

.tw-w-7\/12 {
  width: 58.333333% !important;
}

.tw-w-72 {
  width: 18rem !important;
}

.tw-w-8 {
  width: 2rem !important;
}

.tw-w-8\/12 {
  width: 66.666667% !important;
}

.tw-w-80 {
  width: 20rem !important;
}

.tw-w-9 {
  width: 2.25rem !important;
}

.tw-w-9\/12 {
  width: 75% !important;
}

.tw-w-96 {
  width: 24rem !important;
}

.tw-w-auto {
  width: auto !important;
}

.tw-w-dvw {
  width: 100dvw !important;
}

.tw-w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.tw-w-full {
  width: 100% !important;
}

.tw-w-lvw {
  width: 100lvw !important;
}

.tw-w-max {
  width: -webkit-max-content !important;
  width: max-content !important;
}

.tw-w-min {
  width: -webkit-min-content !important;
  width: min-content !important;
}

.tw-w-px {
  width: 1px !important;
}

.tw-w-screen {
  width: 100vw !important;
}

.tw-w-svw {
  width: 100svw !important;
}

.tw-min-w-0 {
  min-width: 0px !important;
}

.tw-min-w-0\.5 {
  min-width: 0.125rem !important;
}

.tw-min-w-1 {
  min-width: 0.25rem !important;
}

.tw-min-w-1\.5 {
  min-width: 0.375rem !important;
}

.tw-min-w-10 {
  min-width: 2.5rem !important;
}

.tw-min-w-11 {
  min-width: 2.75rem !important;
}

.tw-min-w-12 {
  min-width: 3rem !important;
}

.tw-min-w-14 {
  min-width: 3.5rem !important;
}

.tw-min-w-16 {
  min-width: 4rem !important;
}

.tw-min-w-2 {
  min-width: 0.5rem !important;
}

.tw-min-w-2\.5 {
  min-width: 0.625rem !important;
}

.tw-min-w-20 {
  min-width: 5rem !important;
}

.tw-min-w-24 {
  min-width: 6rem !important;
}

.tw-min-w-28 {
  min-width: 7rem !important;
}

.tw-min-w-3 {
  min-width: 0.75rem !important;
}

.tw-min-w-3\.5 {
  min-width: 0.875rem !important;
}

.tw-min-w-32 {
  min-width: 8rem !important;
}

.tw-min-w-36 {
  min-width: 9rem !important;
}

.tw-min-w-4 {
  min-width: 1rem !important;
}

.tw-min-w-40 {
  min-width: 10rem !important;
}

.tw-min-w-44 {
  min-width: 11rem !important;
}

.tw-min-w-48 {
  min-width: 12rem !important;
}

.tw-min-w-5 {
  min-width: 1.25rem !important;
}

.tw-min-w-52 {
  min-width: 13rem !important;
}

.tw-min-w-56 {
  min-width: 14rem !important;
}

.tw-min-w-6 {
  min-width: 1.5rem !important;
}

.tw-min-w-60 {
  min-width: 15rem !important;
}

.tw-min-w-64 {
  min-width: 16rem !important;
}

.tw-min-w-7 {
  min-width: 1.75rem !important;
}

.tw-min-w-72 {
  min-width: 18rem !important;
}

.tw-min-w-8 {
  min-width: 2rem !important;
}

.tw-min-w-80 {
  min-width: 20rem !important;
}

.tw-min-w-9 {
  min-width: 2.25rem !important;
}

.tw-min-w-96 {
  min-width: 24rem !important;
}

.tw-min-w-fit {
  min-width: -webkit-fit-content !important;
  min-width: -moz-fit-content !important;
  min-width: fit-content !important;
}

.tw-min-w-full {
  min-width: 100% !important;
}

.tw-min-w-max {
  min-width: -webkit-max-content !important;
  min-width: max-content !important;
}

.tw-min-w-min {
  min-width: -webkit-min-content !important;
  min-width: min-content !important;
}

.tw-min-w-px {
  min-width: 1px !important;
}

.tw-max-w-0 {
  max-width: 0px !important;
}

.tw-max-w-0\.5 {
  max-width: 0.125rem !important;
}

.tw-max-w-1 {
  max-width: 0.25rem !important;
}

.tw-max-w-1\.5 {
  max-width: 0.375rem !important;
}

.tw-max-w-10 {
  max-width: 2.5rem !important;
}

.tw-max-w-11 {
  max-width: 2.75rem !important;
}

.tw-max-w-12 {
  max-width: 3rem !important;
}

.tw-max-w-14 {
  max-width: 3.5rem !important;
}

.tw-max-w-16 {
  max-width: 4rem !important;
}

.tw-max-w-2 {
  max-width: 0.5rem !important;
}

.tw-max-w-2\.5 {
  max-width: 0.625rem !important;
}

.tw-max-w-20 {
  max-width: 5rem !important;
}

.tw-max-w-24 {
  max-width: 6rem !important;
}

.tw-max-w-28 {
  max-width: 7rem !important;
}

.tw-max-w-2xl {
  max-width: 42rem !important;
}

.tw-max-w-3 {
  max-width: 0.75rem !important;
}

.tw-max-w-3\.5 {
  max-width: 0.875rem !important;
}

.tw-max-w-32 {
  max-width: 8rem !important;
}

.tw-max-w-36 {
  max-width: 9rem !important;
}

.tw-max-w-3xl {
  max-width: 48rem !important;
}

.tw-max-w-4 {
  max-width: 1rem !important;
}

.tw-max-w-40 {
  max-width: 10rem !important;
}

.tw-max-w-44 {
  max-width: 11rem !important;
}

.tw-max-w-48 {
  max-width: 12rem !important;
}

.tw-max-w-4xl {
  max-width: 56rem !important;
}

.tw-max-w-5 {
  max-width: 1.25rem !important;
}

.tw-max-w-52 {
  max-width: 13rem !important;
}

.tw-max-w-56 {
  max-width: 14rem !important;
}

.tw-max-w-5xl {
  max-width: 64rem !important;
}

.tw-max-w-6 {
  max-width: 1.5rem !important;
}

.tw-max-w-60 {
  max-width: 15rem !important;
}

.tw-max-w-64 {
  max-width: 16rem !important;
}

.tw-max-w-6xl {
  max-width: 72rem !important;
}

.tw-max-w-7 {
  max-width: 1.75rem !important;
}

.tw-max-w-72 {
  max-width: 18rem !important;
}

.tw-max-w-7xl {
  max-width: 80rem !important;
}

.tw-max-w-8 {
  max-width: 2rem !important;
}

.tw-max-w-80 {
  max-width: 20rem !important;
}

.tw-max-w-9 {
  max-width: 2.25rem !important;
}

.tw-max-w-96 {
  max-width: 24rem !important;
}

.tw-max-w-fit {
  max-width: -webkit-fit-content !important;
  max-width: -moz-fit-content !important;
  max-width: fit-content !important;
}

.tw-max-w-full {
  max-width: 100% !important;
}

.tw-max-w-lg {
  max-width: 32rem !important;
}

.tw-max-w-max {
  max-width: -webkit-max-content !important;
  max-width: max-content !important;
}

.tw-max-w-md {
  max-width: 28rem !important;
}

.tw-max-w-min {
  max-width: -webkit-min-content !important;
  max-width: min-content !important;
}

.tw-max-w-none {
  max-width: none !important;
}

.tw-max-w-prose {
  max-width: 65ch !important;
}

.tw-max-w-px {
  max-width: 1px !important;
}

.tw-max-w-screen-2xl {
  max-width: 1536px !important;
}

.tw-max-w-screen-lg {
  max-width: 1024px !important;
}

.tw-max-w-screen-md {
  max-width: 768px !important;
}

.tw-max-w-screen-sm {
  max-width: 640px !important;
}

.tw-max-w-screen-xl {
  max-width: 1280px !important;
}

.tw-max-w-sm {
  max-width: 24rem !important;
}

.tw-max-w-xl {
  max-width: 36rem !important;
}

.tw-max-w-xs {
  max-width: 20rem !important;
}

.tw-flex-1 {
  flex: 1 1 0% !important;
}

.tw-flex-auto {
  flex: 1 1 auto !important;
}

.tw-flex-initial {
  flex: 0 1 auto !important;
}

.tw-flex-none {
  flex: none !important;
}

.tw-flex-shrink {
  flex-shrink: 1 !important;
}

.tw-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.tw-shrink {
  flex-shrink: 1 !important;
}

.tw-shrink-0 {
  flex-shrink: 0 !important;
}

.tw-flex-grow {
  flex-grow: 1 !important;
}

.tw-flex-grow-0 {
  flex-grow: 0 !important;
}

.tw-grow {
  flex-grow: 1 !important;
}

.tw-grow-0 {
  flex-grow: 0 !important;
}

.tw-basis-0 {
  flex-basis: 0px !important;
}

.tw-basis-0\.5 {
  flex-basis: 0.125rem !important;
}

.tw-basis-1 {
  flex-basis: 0.25rem !important;
}

.tw-basis-1\.5 {
  flex-basis: 0.375rem !important;
}

.tw-basis-1\/12 {
  flex-basis: 8.333333% !important;
}

.tw-basis-1\/2 {
  flex-basis: 50% !important;
}

.tw-basis-1\/3 {
  flex-basis: 33.333333% !important;
}

.tw-basis-1\/4 {
  flex-basis: 25% !important;
}

.tw-basis-1\/5 {
  flex-basis: 20% !important;
}

.tw-basis-1\/6 {
  flex-basis: 16.666667% !important;
}

.tw-basis-10 {
  flex-basis: 2.5rem !important;
}

.tw-basis-10\/12 {
  flex-basis: 83.333333% !important;
}

.tw-basis-11 {
  flex-basis: 2.75rem !important;
}

.tw-basis-11\/12 {
  flex-basis: 91.666667% !important;
}

.tw-basis-12 {
  flex-basis: 3rem !important;
}

.tw-basis-14 {
  flex-basis: 3.5rem !important;
}

.tw-basis-16 {
  flex-basis: 4rem !important;
}

.tw-basis-2 {
  flex-basis: 0.5rem !important;
}

.tw-basis-2\.5 {
  flex-basis: 0.625rem !important;
}

.tw-basis-2\/12 {
  flex-basis: 16.666667% !important;
}

.tw-basis-2\/3 {
  flex-basis: 66.666667% !important;
}

.tw-basis-2\/4 {
  flex-basis: 50% !important;
}

.tw-basis-2\/5 {
  flex-basis: 40% !important;
}

.tw-basis-2\/6 {
  flex-basis: 33.333333% !important;
}

.tw-basis-20 {
  flex-basis: 5rem !important;
}

.tw-basis-24 {
  flex-basis: 6rem !important;
}

.tw-basis-28 {
  flex-basis: 7rem !important;
}

.tw-basis-3 {
  flex-basis: 0.75rem !important;
}

.tw-basis-3\.5 {
  flex-basis: 0.875rem !important;
}

.tw-basis-3\/12 {
  flex-basis: 25% !important;
}

.tw-basis-3\/4 {
  flex-basis: 75% !important;
}

.tw-basis-3\/5 {
  flex-basis: 60% !important;
}

.tw-basis-3\/6 {
  flex-basis: 50% !important;
}

.tw-basis-32 {
  flex-basis: 8rem !important;
}

.tw-basis-36 {
  flex-basis: 9rem !important;
}

.tw-basis-4 {
  flex-basis: 1rem !important;
}

.tw-basis-4\/12 {
  flex-basis: 33.333333% !important;
}

.tw-basis-4\/5 {
  flex-basis: 80% !important;
}

.tw-basis-4\/6 {
  flex-basis: 66.666667% !important;
}

.tw-basis-40 {
  flex-basis: 10rem !important;
}

.tw-basis-44 {
  flex-basis: 11rem !important;
}

.tw-basis-48 {
  flex-basis: 12rem !important;
}

.tw-basis-5 {
  flex-basis: 1.25rem !important;
}

.tw-basis-5\/12 {
  flex-basis: 41.666667% !important;
}

.tw-basis-5\/6 {
  flex-basis: 83.333333% !important;
}

.tw-basis-52 {
  flex-basis: 13rem !important;
}

.tw-basis-56 {
  flex-basis: 14rem !important;
}

.tw-basis-6 {
  flex-basis: 1.5rem !important;
}

.tw-basis-6\/12 {
  flex-basis: 50% !important;
}

.tw-basis-60 {
  flex-basis: 15rem !important;
}

.tw-basis-64 {
  flex-basis: 16rem !important;
}

.tw-basis-7 {
  flex-basis: 1.75rem !important;
}

.tw-basis-7\/12 {
  flex-basis: 58.333333% !important;
}

.tw-basis-72 {
  flex-basis: 18rem !important;
}

.tw-basis-8 {
  flex-basis: 2rem !important;
}

.tw-basis-8\/12 {
  flex-basis: 66.666667% !important;
}

.tw-basis-80 {
  flex-basis: 20rem !important;
}

.tw-basis-9 {
  flex-basis: 2.25rem !important;
}

.tw-basis-9\/12 {
  flex-basis: 75% !important;
}

.tw-basis-96 {
  flex-basis: 24rem !important;
}

.tw-basis-auto {
  flex-basis: auto !important;
}

.tw-basis-full {
  flex-basis: 100% !important;
}

.tw-basis-px {
  flex-basis: 1px !important;
}

.tw-table-auto {
  table-layout: auto !important;
}

.tw-table-fixed {
  table-layout: fixed !important;
}

.tw-caption-top {
  caption-side: top !important;
}

.tw-caption-bottom {
  caption-side: bottom !important;
}

.tw-border-collapse {
  border-collapse: collapse !important;
}

.tw-border-separate {
  border-collapse: separate !important;
}

.tw-border-spacing-0 {
  --tw-border-spacing-x: 0px !important;
  --tw-border-spacing-y: 0px !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-0\.5 {
  --tw-border-spacing-x: 0.125rem !important;
  --tw-border-spacing-y: 0.125rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-1 {
  --tw-border-spacing-x: 0.25rem !important;
  --tw-border-spacing-y: 0.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-1\.5 {
  --tw-border-spacing-x: 0.375rem !important;
  --tw-border-spacing-y: 0.375rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-10 {
  --tw-border-spacing-x: 2.5rem !important;
  --tw-border-spacing-y: 2.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-11 {
  --tw-border-spacing-x: 2.75rem !important;
  --tw-border-spacing-y: 2.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-12 {
  --tw-border-spacing-x: 3rem !important;
  --tw-border-spacing-y: 3rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-14 {
  --tw-border-spacing-x: 3.5rem !important;
  --tw-border-spacing-y: 3.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-16 {
  --tw-border-spacing-x: 4rem !important;
  --tw-border-spacing-y: 4rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-2 {
  --tw-border-spacing-x: 0.5rem !important;
  --tw-border-spacing-y: 0.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-2\.5 {
  --tw-border-spacing-x: 0.625rem !important;
  --tw-border-spacing-y: 0.625rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-20 {
  --tw-border-spacing-x: 5rem !important;
  --tw-border-spacing-y: 5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-24 {
  --tw-border-spacing-x: 6rem !important;
  --tw-border-spacing-y: 6rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-28 {
  --tw-border-spacing-x: 7rem !important;
  --tw-border-spacing-y: 7rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-3 {
  --tw-border-spacing-x: 0.75rem !important;
  --tw-border-spacing-y: 0.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-3\.5 {
  --tw-border-spacing-x: 0.875rem !important;
  --tw-border-spacing-y: 0.875rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-32 {
  --tw-border-spacing-x: 8rem !important;
  --tw-border-spacing-y: 8rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-36 {
  --tw-border-spacing-x: 9rem !important;
  --tw-border-spacing-y: 9rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-4 {
  --tw-border-spacing-x: 1rem !important;
  --tw-border-spacing-y: 1rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-40 {
  --tw-border-spacing-x: 10rem !important;
  --tw-border-spacing-y: 10rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-44 {
  --tw-border-spacing-x: 11rem !important;
  --tw-border-spacing-y: 11rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-48 {
  --tw-border-spacing-x: 12rem !important;
  --tw-border-spacing-y: 12rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-5 {
  --tw-border-spacing-x: 1.25rem !important;
  --tw-border-spacing-y: 1.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-52 {
  --tw-border-spacing-x: 13rem !important;
  --tw-border-spacing-y: 13rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-56 {
  --tw-border-spacing-x: 14rem !important;
  --tw-border-spacing-y: 14rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-6 {
  --tw-border-spacing-x: 1.5rem !important;
  --tw-border-spacing-y: 1.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-60 {
  --tw-border-spacing-x: 15rem !important;
  --tw-border-spacing-y: 15rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-64 {
  --tw-border-spacing-x: 16rem !important;
  --tw-border-spacing-y: 16rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-7 {
  --tw-border-spacing-x: 1.75rem !important;
  --tw-border-spacing-y: 1.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-72 {
  --tw-border-spacing-x: 18rem !important;
  --tw-border-spacing-y: 18rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-8 {
  --tw-border-spacing-x: 2rem !important;
  --tw-border-spacing-y: 2rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-80 {
  --tw-border-spacing-x: 20rem !important;
  --tw-border-spacing-y: 20rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-9 {
  --tw-border-spacing-x: 2.25rem !important;
  --tw-border-spacing-y: 2.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-96 {
  --tw-border-spacing-x: 24rem !important;
  --tw-border-spacing-y: 24rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-px {
  --tw-border-spacing-x: 1px !important;
  --tw-border-spacing-y: 1px !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-0 {
  --tw-border-spacing-x: 0px !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-0\.5 {
  --tw-border-spacing-x: 0.125rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-1 {
  --tw-border-spacing-x: 0.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-1\.5 {
  --tw-border-spacing-x: 0.375rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-10 {
  --tw-border-spacing-x: 2.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-11 {
  --tw-border-spacing-x: 2.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-12 {
  --tw-border-spacing-x: 3rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-14 {
  --tw-border-spacing-x: 3.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-16 {
  --tw-border-spacing-x: 4rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-2 {
  --tw-border-spacing-x: 0.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-2\.5 {
  --tw-border-spacing-x: 0.625rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-20 {
  --tw-border-spacing-x: 5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-24 {
  --tw-border-spacing-x: 6rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-28 {
  --tw-border-spacing-x: 7rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-3 {
  --tw-border-spacing-x: 0.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-3\.5 {
  --tw-border-spacing-x: 0.875rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-32 {
  --tw-border-spacing-x: 8rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-36 {
  --tw-border-spacing-x: 9rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-4 {
  --tw-border-spacing-x: 1rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-40 {
  --tw-border-spacing-x: 10rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-44 {
  --tw-border-spacing-x: 11rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-48 {
  --tw-border-spacing-x: 12rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-5 {
  --tw-border-spacing-x: 1.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-52 {
  --tw-border-spacing-x: 13rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-56 {
  --tw-border-spacing-x: 14rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-6 {
  --tw-border-spacing-x: 1.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-60 {
  --tw-border-spacing-x: 15rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-64 {
  --tw-border-spacing-x: 16rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-7 {
  --tw-border-spacing-x: 1.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-72 {
  --tw-border-spacing-x: 18rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-8 {
  --tw-border-spacing-x: 2rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-80 {
  --tw-border-spacing-x: 20rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-9 {
  --tw-border-spacing-x: 2.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-96 {
  --tw-border-spacing-x: 24rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-x-px {
  --tw-border-spacing-x: 1px !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-0 {
  --tw-border-spacing-y: 0px !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-0\.5 {
  --tw-border-spacing-y: 0.125rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-1 {
  --tw-border-spacing-y: 0.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-1\.5 {
  --tw-border-spacing-y: 0.375rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-10 {
  --tw-border-spacing-y: 2.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-11 {
  --tw-border-spacing-y: 2.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-12 {
  --tw-border-spacing-y: 3rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-14 {
  --tw-border-spacing-y: 3.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-16 {
  --tw-border-spacing-y: 4rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-2 {
  --tw-border-spacing-y: 0.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-2\.5 {
  --tw-border-spacing-y: 0.625rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-20 {
  --tw-border-spacing-y: 5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-24 {
  --tw-border-spacing-y: 6rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-28 {
  --tw-border-spacing-y: 7rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-3 {
  --tw-border-spacing-y: 0.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-3\.5 {
  --tw-border-spacing-y: 0.875rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-32 {
  --tw-border-spacing-y: 8rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-36 {
  --tw-border-spacing-y: 9rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-4 {
  --tw-border-spacing-y: 1rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-40 {
  --tw-border-spacing-y: 10rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-44 {
  --tw-border-spacing-y: 11rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-48 {
  --tw-border-spacing-y: 12rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-5 {
  --tw-border-spacing-y: 1.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-52 {
  --tw-border-spacing-y: 13rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-56 {
  --tw-border-spacing-y: 14rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-6 {
  --tw-border-spacing-y: 1.5rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-60 {
  --tw-border-spacing-y: 15rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-64 {
  --tw-border-spacing-y: 16rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-7 {
  --tw-border-spacing-y: 1.75rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-72 {
  --tw-border-spacing-y: 18rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-8 {
  --tw-border-spacing-y: 2rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-80 {
  --tw-border-spacing-y: 20rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-9 {
  --tw-border-spacing-y: 2.25rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-96 {
  --tw-border-spacing-y: 24rem !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-border-spacing-y-px {
  --tw-border-spacing-y: 1px !important;
  border-spacing: var(--tw-border-spacing-x) var(--tw-border-spacing-y) !important;
}

.tw-origin-bottom {
  transform-origin: bottom !important;
}

.tw-origin-bottom-left {
  transform-origin: bottom left !important;
}

.tw-origin-bottom-right {
  transform-origin: bottom right !important;
}

.tw-origin-center {
  transform-origin: center !important;
}

.tw-origin-left {
  transform-origin: left !important;
}

.tw-origin-right {
  transform-origin: right !important;
}

.tw-origin-top {
  transform-origin: top !important;
}

.tw-origin-top-left {
  transform-origin: top left !important;
}

.tw-origin-top-right {
  transform-origin: top right !important;
}

.-tw-translate-x-0 {
  --tw-translate-x: -0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-0\.5 {
  --tw-translate-x: -0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-1 {
  --tw-translate-x: -0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-1\.5 {
  --tw-translate-x: -0.375rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-1\/2 {
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-1\/3 {
  --tw-translate-x: -33.333333% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-1\/4 {
  --tw-translate-x: -25% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-10 {
  --tw-translate-x: -2.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-11 {
  --tw-translate-x: -2.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-12 {
  --tw-translate-x: -3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-14 {
  --tw-translate-x: -3.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-16 {
  --tw-translate-x: -4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-2 {
  --tw-translate-x: -0.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-2\.5 {
  --tw-translate-x: -0.625rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-2\/3 {
  --tw-translate-x: -66.666667% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-2\/4 {
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-20 {
  --tw-translate-x: -5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-24 {
  --tw-translate-x: -6rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-28 {
  --tw-translate-x: -7rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-3 {
  --tw-translate-x: -0.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-3\.5 {
  --tw-translate-x: -0.875rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-3\/4 {
  --tw-translate-x: -75% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-32 {
  --tw-translate-x: -8rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-36 {
  --tw-translate-x: -9rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-4 {
  --tw-translate-x: -1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-40 {
  --tw-translate-x: -10rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-44 {
  --tw-translate-x: -11rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-48 {
  --tw-translate-x: -12rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-5 {
  --tw-translate-x: -1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-52 {
  --tw-translate-x: -13rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-56 {
  --tw-translate-x: -14rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-6 {
  --tw-translate-x: -1.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-60 {
  --tw-translate-x: -15rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-64 {
  --tw-translate-x: -16rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-7 {
  --tw-translate-x: -1.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-72 {
  --tw-translate-x: -18rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-8 {
  --tw-translate-x: -2rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-80 {
  --tw-translate-x: -20rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-9 {
  --tw-translate-x: -2.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-96 {
  --tw-translate-x: -24rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-full {
  --tw-translate-x: -100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-x-px {
  --tw-translate-x: -1px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-0 {
  --tw-translate-y: -0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-0\.5 {
  --tw-translate-y: -0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-1 {
  --tw-translate-y: -0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-1\.5 {
  --tw-translate-y: -0.375rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-1\/2 {
  --tw-translate-y: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-1\/3 {
  --tw-translate-y: -33.333333% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-1\/4 {
  --tw-translate-y: -25% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-10 {
  --tw-translate-y: -2.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-11 {
  --tw-translate-y: -2.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-12 {
  --tw-translate-y: -3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-14 {
  --tw-translate-y: -3.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-16 {
  --tw-translate-y: -4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-2 {
  --tw-translate-y: -0.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-2\.5 {
  --tw-translate-y: -0.625rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-2\/3 {
  --tw-translate-y: -66.666667% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-2\/4 {
  --tw-translate-y: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-20 {
  --tw-translate-y: -5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-24 {
  --tw-translate-y: -6rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-28 {
  --tw-translate-y: -7rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-3 {
  --tw-translate-y: -0.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-3\.5 {
  --tw-translate-y: -0.875rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-3\/4 {
  --tw-translate-y: -75% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-32 {
  --tw-translate-y: -8rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-36 {
  --tw-translate-y: -9rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-4 {
  --tw-translate-y: -1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-40 {
  --tw-translate-y: -10rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-44 {
  --tw-translate-y: -11rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-48 {
  --tw-translate-y: -12rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-5 {
  --tw-translate-y: -1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-52 {
  --tw-translate-y: -13rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-56 {
  --tw-translate-y: -14rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-6 {
  --tw-translate-y: -1.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-60 {
  --tw-translate-y: -15rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-64 {
  --tw-translate-y: -16rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-7 {
  --tw-translate-y: -1.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-72 {
  --tw-translate-y: -18rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-8 {
  --tw-translate-y: -2rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-80 {
  --tw-translate-y: -20rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-9 {
  --tw-translate-y: -2.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-96 {
  --tw-translate-y: -24rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-full {
  --tw-translate-y: -100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-translate-y-px {
  --tw-translate-y: -1px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-0 {
  --tw-translate-x: -0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-0\.5 {
  --tw-translate-x: -0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-1 {
  --tw-translate-x: -0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-1\.5 {
  --tw-translate-x: -0.375rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-1\/2 {
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-1\/3 {
  --tw-translate-x: -33.333333% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-1\/4 {
  --tw-translate-x: -25% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-10 {
  --tw-translate-x: -2.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-11 {
  --tw-translate-x: -2.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-12 {
  --tw-translate-x: -3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-14 {
  --tw-translate-x: -3.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-16 {
  --tw-translate-x: -4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-2 {
  --tw-translate-x: -0.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-2\.5 {
  --tw-translate-x: -0.625rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-2\/3 {
  --tw-translate-x: -66.666667% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-2\/4 {
  --tw-translate-x: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-20 {
  --tw-translate-x: -5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-24 {
  --tw-translate-x: -6rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-28 {
  --tw-translate-x: -7rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-3 {
  --tw-translate-x: -0.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-3\.5 {
  --tw-translate-x: -0.875rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-3\/4 {
  --tw-translate-x: -75% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-32 {
  --tw-translate-x: -8rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-36 {
  --tw-translate-x: -9rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-4 {
  --tw-translate-x: -1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-40 {
  --tw-translate-x: -10rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-44 {
  --tw-translate-x: -11rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-48 {
  --tw-translate-x: -12rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-5 {
  --tw-translate-x: -1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-52 {
  --tw-translate-x: -13rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-56 {
  --tw-translate-x: -14rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-6 {
  --tw-translate-x: -1.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-60 {
  --tw-translate-x: -15rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-64 {
  --tw-translate-x: -16rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-7 {
  --tw-translate-x: -1.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-72 {
  --tw-translate-x: -18rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-8 {
  --tw-translate-x: -2rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-80 {
  --tw-translate-x: -20rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-9 {
  --tw-translate-x: -2.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-96 {
  --tw-translate-x: -24rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-full {
  --tw-translate-x: -100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-x-px {
  --tw-translate-x: -1px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-0 {
  --tw-translate-y: -0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-0\.5 {
  --tw-translate-y: -0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-1 {
  --tw-translate-y: -0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-1\.5 {
  --tw-translate-y: -0.375rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-1\/2 {
  --tw-translate-y: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-1\/3 {
  --tw-translate-y: -33.333333% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-1\/4 {
  --tw-translate-y: -25% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-10 {
  --tw-translate-y: -2.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-11 {
  --tw-translate-y: -2.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-12 {
  --tw-translate-y: -3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-14 {
  --tw-translate-y: -3.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-16 {
  --tw-translate-y: -4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-2 {
  --tw-translate-y: -0.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-2\.5 {
  --tw-translate-y: -0.625rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-2\/3 {
  --tw-translate-y: -66.666667% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-2\/4 {
  --tw-translate-y: -50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-20 {
  --tw-translate-y: -5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-24 {
  --tw-translate-y: -6rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-28 {
  --tw-translate-y: -7rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-3 {
  --tw-translate-y: -0.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-3\.5 {
  --tw-translate-y: -0.875rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-3\/4 {
  --tw-translate-y: -75% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-32 {
  --tw-translate-y: -8rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-36 {
  --tw-translate-y: -9rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-4 {
  --tw-translate-y: -1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-40 {
  --tw-translate-y: -10rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-44 {
  --tw-translate-y: -11rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-48 {
  --tw-translate-y: -12rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-5 {
  --tw-translate-y: -1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-52 {
  --tw-translate-y: -13rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-56 {
  --tw-translate-y: -14rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-6 {
  --tw-translate-y: -1.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-60 {
  --tw-translate-y: -15rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-64 {
  --tw-translate-y: -16rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-7 {
  --tw-translate-y: -1.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-72 {
  --tw-translate-y: -18rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-8 {
  --tw-translate-y: -2rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-80 {
  --tw-translate-y: -20rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-9 {
  --tw-translate-y: -2.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-96 {
  --tw-translate-y: -24rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-full {
  --tw-translate-y: -100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--translate-y-px {
  --tw-translate-y: -1px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-0 {
  --tw-translate-x: 0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-0\.5 {
  --tw-translate-x: 0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-1 {
  --tw-translate-x: 0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-1\.5 {
  --tw-translate-x: 0.375rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-1\/2 {
  --tw-translate-x: 50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-1\/3 {
  --tw-translate-x: 33.333333% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-1\/4 {
  --tw-translate-x: 25% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-10 {
  --tw-translate-x: 2.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-11 {
  --tw-translate-x: 2.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-12 {
  --tw-translate-x: 3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-14 {
  --tw-translate-x: 3.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-16 {
  --tw-translate-x: 4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-2 {
  --tw-translate-x: 0.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-2\.5 {
  --tw-translate-x: 0.625rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-2\/3 {
  --tw-translate-x: 66.666667% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-2\/4 {
  --tw-translate-x: 50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-20 {
  --tw-translate-x: 5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-24 {
  --tw-translate-x: 6rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-28 {
  --tw-translate-x: 7rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-3 {
  --tw-translate-x: 0.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-3\.5 {
  --tw-translate-x: 0.875rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-3\/4 {
  --tw-translate-x: 75% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-32 {
  --tw-translate-x: 8rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-36 {
  --tw-translate-x: 9rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-4 {
  --tw-translate-x: 1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-40 {
  --tw-translate-x: 10rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-44 {
  --tw-translate-x: 11rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-48 {
  --tw-translate-x: 12rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-5 {
  --tw-translate-x: 1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-52 {
  --tw-translate-x: 13rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-56 {
  --tw-translate-x: 14rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-6 {
  --tw-translate-x: 1.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-60 {
  --tw-translate-x: 15rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-64 {
  --tw-translate-x: 16rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-7 {
  --tw-translate-x: 1.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-72 {
  --tw-translate-x: 18rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-8 {
  --tw-translate-x: 2rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-80 {
  --tw-translate-x: 20rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-9 {
  --tw-translate-x: 2.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-96 {
  --tw-translate-x: 24rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-full {
  --tw-translate-x: 100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-x-px {
  --tw-translate-x: 1px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-0 {
  --tw-translate-y: 0px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-0\.5 {
  --tw-translate-y: 0.125rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-1 {
  --tw-translate-y: 0.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-1\.5 {
  --tw-translate-y: 0.375rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-1\/2 {
  --tw-translate-y: 50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-1\/3 {
  --tw-translate-y: 33.333333% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-1\/4 {
  --tw-translate-y: 25% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-10 {
  --tw-translate-y: 2.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-11 {
  --tw-translate-y: 2.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-12 {
  --tw-translate-y: 3rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-14 {
  --tw-translate-y: 3.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-16 {
  --tw-translate-y: 4rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-2 {
  --tw-translate-y: 0.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-2\.5 {
  --tw-translate-y: 0.625rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-2\/3 {
  --tw-translate-y: 66.666667% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-2\/4 {
  --tw-translate-y: 50% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-20 {
  --tw-translate-y: 5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-24 {
  --tw-translate-y: 6rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-28 {
  --tw-translate-y: 7rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-3 {
  --tw-translate-y: 0.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-3\.5 {
  --tw-translate-y: 0.875rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-3\/4 {
  --tw-translate-y: 75% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-32 {
  --tw-translate-y: 8rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-36 {
  --tw-translate-y: 9rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-4 {
  --tw-translate-y: 1rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-40 {
  --tw-translate-y: 10rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-44 {
  --tw-translate-y: 11rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-48 {
  --tw-translate-y: 12rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-5 {
  --tw-translate-y: 1.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-52 {
  --tw-translate-y: 13rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-56 {
  --tw-translate-y: 14rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-6 {
  --tw-translate-y: 1.5rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-60 {
  --tw-translate-y: 15rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-64 {
  --tw-translate-y: 16rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-7 {
  --tw-translate-y: 1.75rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-72 {
  --tw-translate-y: 18rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-8 {
  --tw-translate-y: 2rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-80 {
  --tw-translate-y: 20rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-9 {
  --tw-translate-y: 2.25rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-96 {
  --tw-translate-y: 24rem !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-full {
  --tw-translate-y: 100% !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-translate-y-px {
  --tw-translate-y: 1px !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-0 {
  --tw-rotate: -0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-1 {
  --tw-rotate: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-12 {
  --tw-rotate: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-180 {
  --tw-rotate: -180deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-2 {
  --tw-rotate: -2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-3 {
  --tw-rotate: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-45 {
  --tw-rotate: -45deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-6 {
  --tw-rotate: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-rotate-90 {
  --tw-rotate: -90deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-0 {
  --tw-rotate: -0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-1 {
  --tw-rotate: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-12 {
  --tw-rotate: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-180 {
  --tw-rotate: -180deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-2 {
  --tw-rotate: -2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-3 {
  --tw-rotate: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-45 {
  --tw-rotate: -45deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-6 {
  --tw-rotate: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--rotate-90 {
  --tw-rotate: -90deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-0 {
  --tw-rotate: 0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-1 {
  --tw-rotate: 1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-12 {
  --tw-rotate: 12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-180 {
  --tw-rotate: 180deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-2 {
  --tw-rotate: 2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-3 {
  --tw-rotate: 3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-45 {
  --tw-rotate: 45deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-6 {
  --tw-rotate: 6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-rotate-90 {
  --tw-rotate: 90deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-x-0 {
  --tw-skew-x: -0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-x-1 {
  --tw-skew-x: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-x-12 {
  --tw-skew-x: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-x-2 {
  --tw-skew-x: -2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-x-3 {
  --tw-skew-x: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-x-6 {
  --tw-skew-x: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-y-0 {
  --tw-skew-y: -0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-y-1 {
  --tw-skew-y: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-y-12 {
  --tw-skew-y: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-y-2 {
  --tw-skew-y: -2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-y-3 {
  --tw-skew-y: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-skew-y-6 {
  --tw-skew-y: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-x-0 {
  --tw-skew-x: -0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-x-1 {
  --tw-skew-x: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-x-12 {
  --tw-skew-x: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-x-2 {
  --tw-skew-x: -2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-x-3 {
  --tw-skew-x: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-x-6 {
  --tw-skew-x: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-y-0 {
  --tw-skew-y: -0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-y-1 {
  --tw-skew-y: -1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-y-12 {
  --tw-skew-y: -12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-y-2 {
  --tw-skew-y: -2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-y-3 {
  --tw-skew-y: -3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--skew-y-6 {
  --tw-skew-y: -6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-x-0 {
  --tw-skew-x: 0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-x-1 {
  --tw-skew-x: 1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-x-12 {
  --tw-skew-x: 12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-x-2 {
  --tw-skew-x: 2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-x-3 {
  --tw-skew-x: 3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-x-6 {
  --tw-skew-x: 6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-y-0 {
  --tw-skew-y: 0deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-y-1 {
  --tw-skew-y: 1deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-y-12 {
  --tw-skew-y: 12deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-y-2 {
  --tw-skew-y: 2deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-y-3 {
  --tw-skew-y: 3deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-skew-y-6 {
  --tw-skew-y: 6deg !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-0 {
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-100 {
  --tw-scale-x: -1 !important;
  --tw-scale-y: -1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-105 {
  --tw-scale-x: -1.05 !important;
  --tw-scale-y: -1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-110 {
  --tw-scale-x: -1.1 !important;
  --tw-scale-y: -1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-125 {
  --tw-scale-x: -1.25 !important;
  --tw-scale-y: -1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-150 {
  --tw-scale-x: -1.5 !important;
  --tw-scale-y: -1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-50 {
  --tw-scale-x: -.5 !important;
  --tw-scale-y: -.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-75 {
  --tw-scale-x: -.75 !important;
  --tw-scale-y: -.75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-90 {
  --tw-scale-x: -.9 !important;
  --tw-scale-y: -.9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-95 {
  --tw-scale-x: -.95 !important;
  --tw-scale-y: -.95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-0 {
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-100 {
  --tw-scale-x: -1 !important;
  --tw-scale-y: -1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-105 {
  --tw-scale-x: -1.05 !important;
  --tw-scale-y: -1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-110 {
  --tw-scale-x: -1.1 !important;
  --tw-scale-y: -1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-125 {
  --tw-scale-x: -1.25 !important;
  --tw-scale-y: -1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-150 {
  --tw-scale-x: -1.5 !important;
  --tw-scale-y: -1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-50 {
  --tw-scale-x: -.5 !important;
  --tw-scale-y: -.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-75 {
  --tw-scale-x: -.75 !important;
  --tw-scale-y: -.75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-90 {
  --tw-scale-x: -.9 !important;
  --tw-scale-y: -.9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-95 {
  --tw-scale-x: -.95 !important;
  --tw-scale-y: -.95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-0 {
  --tw-scale-x: 0 !important;
  --tw-scale-y: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-100 {
  --tw-scale-x: 1 !important;
  --tw-scale-y: 1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-105 {
  --tw-scale-x: 1.05 !important;
  --tw-scale-y: 1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-110 {
  --tw-scale-x: 1.1 !important;
  --tw-scale-y: 1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-125 {
  --tw-scale-x: 1.25 !important;
  --tw-scale-y: 1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-150 {
  --tw-scale-x: 1.5 !important;
  --tw-scale-y: 1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-50 {
  --tw-scale-x: .5 !important;
  --tw-scale-y: .5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-75 {
  --tw-scale-x: .75 !important;
  --tw-scale-y: .75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-90 {
  --tw-scale-x: .9 !important;
  --tw-scale-y: .9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-95 {
  --tw-scale-x: .95 !important;
  --tw-scale-y: .95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-0 {
  --tw-scale-x: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-100 {
  --tw-scale-x: -1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-105 {
  --tw-scale-x: -1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-110 {
  --tw-scale-x: -1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-125 {
  --tw-scale-x: -1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-150 {
  --tw-scale-x: -1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-50 {
  --tw-scale-x: -.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-75 {
  --tw-scale-x: -.75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-90 {
  --tw-scale-x: -.9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-x-95 {
  --tw-scale-x: -.95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-0 {
  --tw-scale-y: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-100 {
  --tw-scale-y: -1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-105 {
  --tw-scale-y: -1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-110 {
  --tw-scale-y: -1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-125 {
  --tw-scale-y: -1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-150 {
  --tw-scale-y: -1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-50 {
  --tw-scale-y: -.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-75 {
  --tw-scale-y: -.75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-90 {
  --tw-scale-y: -.9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.-tw-scale-y-95 {
  --tw-scale-y: -.95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-0 {
  --tw-scale-x: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-100 {
  --tw-scale-x: -1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-105 {
  --tw-scale-x: -1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-110 {
  --tw-scale-x: -1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-125 {
  --tw-scale-x: -1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-150 {
  --tw-scale-x: -1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-50 {
  --tw-scale-x: -.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-75 {
  --tw-scale-x: -.75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-90 {
  --tw-scale-x: -.9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-x-95 {
  --tw-scale-x: -.95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-0 {
  --tw-scale-y: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-100 {
  --tw-scale-y: -1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-105 {
  --tw-scale-y: -1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-110 {
  --tw-scale-y: -1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-125 {
  --tw-scale-y: -1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-150 {
  --tw-scale-y: -1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-50 {
  --tw-scale-y: -.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-75 {
  --tw-scale-y: -.75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-90 {
  --tw-scale-y: -.9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw--scale-y-95 {
  --tw-scale-y: -.95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-0 {
  --tw-scale-x: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-100 {
  --tw-scale-x: 1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-105 {
  --tw-scale-x: 1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-110 {
  --tw-scale-x: 1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-125 {
  --tw-scale-x: 1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-150 {
  --tw-scale-x: 1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-50 {
  --tw-scale-x: .5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-75 {
  --tw-scale-x: .75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-90 {
  --tw-scale-x: .9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-x-95 {
  --tw-scale-x: .95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-0 {
  --tw-scale-y: 0 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-100 {
  --tw-scale-y: 1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-105 {
  --tw-scale-y: 1.05 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-110 {
  --tw-scale-y: 1.1 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-125 {
  --tw-scale-y: 1.25 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-150 {
  --tw-scale-y: 1.5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-50 {
  --tw-scale-y: .5 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-75 {
  --tw-scale-y: .75 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-90 {
  --tw-scale-y: .9 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-scale-y-95 {
  --tw-scale-y: .95 !important;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-transform-cpu {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-transform-gpu {
  transform: translate3d(var(--tw-translate-x), var(--tw-translate-y), 0) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y)) !important;
}

.tw-transform-none {
  transform: none !important;
}

@keyframes tw-bounce {
  0%, 100% {
    transform: translateY(-25%);
    animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    transform: none;
    animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

.tw-animate-bounce {
  animation: tw-bounce 1s infinite !important;
}

.tw-animate-none {
  animation: none !important;
}

@keyframes tw-ping {
  75%, 100% {
    transform: scale(2);
    opacity: 0;
  }
}

.tw-animate-ping {
  animation: tw-ping 1s cubic-bezier(0, 0, 0.2, 1) infinite !important;
}

@keyframes tw-pulse {
  50% {
    opacity: .5;
  }
}

.tw-animate-pulse {
  animation: tw-pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite !important;
}

@keyframes tw-spin {
  to {
    transform: rotate(360deg);
  }
}

.tw-animate-spin {
  animation: tw-spin 1s linear infinite !important;
}

.tw-cursor-alias {
  cursor: alias !important;
}

.tw-cursor-all-scroll {
  cursor: all-scroll !important;
}

.tw-cursor-auto {
  cursor: auto !important;
}

.tw-cursor-cell {
  cursor: cell !important;
}

.tw-cursor-col-resize {
  cursor: col-resize !important;
}

.tw-cursor-context-menu {
  cursor: context-menu !important;
}

.tw-cursor-copy {
  cursor: copy !important;
}

.tw-cursor-crosshair {
  cursor: crosshair !important;
}

.tw-cursor-default {
  cursor: default !important;
}

.tw-cursor-e-resize {
  cursor: e-resize !important;
}

.tw-cursor-ew-resize {
  cursor: ew-resize !important;
}

.tw-cursor-grab {
  cursor: grab !important;
}

.tw-cursor-grabbing {
  cursor: grabbing !important;
}

.tw-cursor-help {
  cursor: help !important;
}

.tw-cursor-move {
  cursor: move !important;
}

.tw-cursor-n-resize {
  cursor: n-resize !important;
}

.tw-cursor-ne-resize {
  cursor: ne-resize !important;
}

.tw-cursor-nesw-resize {
  cursor: nesw-resize !important;
}

.tw-cursor-no-drop {
  cursor: no-drop !important;
}

.tw-cursor-none {
  cursor: none !important;
}

.tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.tw-cursor-ns-resize {
  cursor: ns-resize !important;
}

.tw-cursor-nw-resize {
  cursor: nw-resize !important;
}

.tw-cursor-nwse-resize {
  cursor: nwse-resize !important;
}

.tw-cursor-pointer {
  cursor: pointer !important;
}

.tw-cursor-progress {
  cursor: progress !important;
}

.tw-cursor-row-resize {
  cursor: row-resize !important;
}

.tw-cursor-s-resize {
  cursor: s-resize !important;
}

.tw-cursor-se-resize {
  cursor: se-resize !important;
}

.tw-cursor-sw-resize {
  cursor: sw-resize !important;
}

.tw-cursor-text {
  cursor: text !important;
}

.tw-cursor-vertical-text {
  cursor: vertical-text !important;
}

.tw-cursor-w-resize {
  cursor: w-resize !important;
}

.tw-cursor-wait {
  cursor: wait !important;
}

.tw-cursor-zoom-in {
  cursor: zoom-in !important;
}

.tw-cursor-zoom-out {
  cursor: zoom-out !important;
}

.tw-touch-auto {
  touch-action: auto !important;
}

.tw-touch-none {
  touch-action: none !important;
}

.tw-touch-pan-x {
  --tw-pan-x: pan-x !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-pan-left {
  --tw-pan-x: pan-left !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-pan-right {
  --tw-pan-x: pan-right !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-pan-y {
  --tw-pan-y: pan-y !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-pan-up {
  --tw-pan-y: pan-up !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-pan-down {
  --tw-pan-y: pan-down !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-pinch-zoom {
  --tw-pinch-zoom: pinch-zoom !important;
  touch-action: var(--tw-pan-x) var(--tw-pan-y) var(--tw-pinch-zoom) !important;
}

.tw-touch-manipulation {
  touch-action: manipulation !important;
}

.tw-select-none {
  -webkit-user-select: none !important;
          user-select: none !important;
}

.tw-select-text {
  -webkit-user-select: text !important;
          user-select: text !important;
}

.tw-select-all {
  -webkit-user-select: all !important;
          user-select: all !important;
}

.tw-select-auto {
  -webkit-user-select: auto !important;
          user-select: auto !important;
}

.tw-resize-none {
  resize: none !important;
}

.tw-resize-y {
  resize: vertical !important;
}

.tw-resize-x {
  resize: horizontal !important;
}

.tw-resize {
  resize: both !important;
}

.tw-snap-none {
  scroll-snap-type: none !important;
}

.tw-snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness) !important;
}

.tw-snap-y {
  scroll-snap-type: y var(--tw-scroll-snap-strictness) !important;
}

.tw-snap-both {
  scroll-snap-type: both var(--tw-scroll-snap-strictness) !important;
}

.tw-snap-mandatory {
  --tw-scroll-snap-strictness: mandatory !important;
}

.tw-snap-proximity {
  --tw-scroll-snap-strictness: proximity !important;
}

.tw-snap-start {
  scroll-snap-align: start !important;
}

.tw-snap-end {
  scroll-snap-align: end !important;
}

.tw-snap-center {
  scroll-snap-align: center !important;
}

.tw-snap-align-none {
  scroll-snap-align: none !important;
}

.tw-snap-normal {
  scroll-snap-stop: normal !important;
}

.tw-snap-always {
  scroll-snap-stop: always !important;
}

.-tw-scroll-m-0 {
  scroll-margin: -0px !important;
}

.-tw-scroll-m-0\.5 {
  scroll-margin: -0.125rem !important;
}

.-tw-scroll-m-1 {
  scroll-margin: -0.25rem !important;
}

.-tw-scroll-m-1\.5 {
  scroll-margin: -0.375rem !important;
}

.-tw-scroll-m-10 {
  scroll-margin: -2.5rem !important;
}

.-tw-scroll-m-11 {
  scroll-margin: -2.75rem !important;
}

.-tw-scroll-m-12 {
  scroll-margin: -3rem !important;
}

.-tw-scroll-m-14 {
  scroll-margin: -3.5rem !important;
}

.-tw-scroll-m-16 {
  scroll-margin: -4rem !important;
}

.-tw-scroll-m-2 {
  scroll-margin: -0.5rem !important;
}

.-tw-scroll-m-2\.5 {
  scroll-margin: -0.625rem !important;
}

.-tw-scroll-m-20 {
  scroll-margin: -5rem !important;
}

.-tw-scroll-m-24 {
  scroll-margin: -6rem !important;
}

.-tw-scroll-m-28 {
  scroll-margin: -7rem !important;
}

.-tw-scroll-m-3 {
  scroll-margin: -0.75rem !important;
}

.-tw-scroll-m-3\.5 {
  scroll-margin: -0.875rem !important;
}

.-tw-scroll-m-32 {
  scroll-margin: -8rem !important;
}

.-tw-scroll-m-36 {
  scroll-margin: -9rem !important;
}

.-tw-scroll-m-4 {
  scroll-margin: -1rem !important;
}

.-tw-scroll-m-40 {
  scroll-margin: -10rem !important;
}

.-tw-scroll-m-44 {
  scroll-margin: -11rem !important;
}

.-tw-scroll-m-48 {
  scroll-margin: -12rem !important;
}

.-tw-scroll-m-5 {
  scroll-margin: -1.25rem !important;
}

.-tw-scroll-m-52 {
  scroll-margin: -13rem !important;
}

.-tw-scroll-m-56 {
  scroll-margin: -14rem !important;
}

.-tw-scroll-m-6 {
  scroll-margin: -1.5rem !important;
}

.-tw-scroll-m-60 {
  scroll-margin: -15rem !important;
}

.-tw-scroll-m-64 {
  scroll-margin: -16rem !important;
}

.-tw-scroll-m-7 {
  scroll-margin: -1.75rem !important;
}

.-tw-scroll-m-72 {
  scroll-margin: -18rem !important;
}

.-tw-scroll-m-8 {
  scroll-margin: -2rem !important;
}

.-tw-scroll-m-80 {
  scroll-margin: -20rem !important;
}

.-tw-scroll-m-9 {
  scroll-margin: -2.25rem !important;
}

.-tw-scroll-m-96 {
  scroll-margin: -24rem !important;
}

.-tw-scroll-m-px {
  scroll-margin: -1px !important;
}

.tw--scroll-m-0 {
  scroll-margin: -0px !important;
}

.tw--scroll-m-0\.5 {
  scroll-margin: -0.125rem !important;
}

.tw--scroll-m-1 {
  scroll-margin: -0.25rem !important;
}

.tw--scroll-m-1\.5 {
  scroll-margin: -0.375rem !important;
}

.tw--scroll-m-10 {
  scroll-margin: -2.5rem !important;
}

.tw--scroll-m-11 {
  scroll-margin: -2.75rem !important;
}

.tw--scroll-m-12 {
  scroll-margin: -3rem !important;
}

.tw--scroll-m-14 {
  scroll-margin: -3.5rem !important;
}

.tw--scroll-m-16 {
  scroll-margin: -4rem !important;
}

.tw--scroll-m-2 {
  scroll-margin: -0.5rem !important;
}

.tw--scroll-m-2\.5 {
  scroll-margin: -0.625rem !important;
}

.tw--scroll-m-20 {
  scroll-margin: -5rem !important;
}

.tw--scroll-m-24 {
  scroll-margin: -6rem !important;
}

.tw--scroll-m-28 {
  scroll-margin: -7rem !important;
}

.tw--scroll-m-3 {
  scroll-margin: -0.75rem !important;
}

.tw--scroll-m-3\.5 {
  scroll-margin: -0.875rem !important;
}

.tw--scroll-m-32 {
  scroll-margin: -8rem !important;
}

.tw--scroll-m-36 {
  scroll-margin: -9rem !important;
}

.tw--scroll-m-4 {
  scroll-margin: -1rem !important;
}

.tw--scroll-m-40 {
  scroll-margin: -10rem !important;
}

.tw--scroll-m-44 {
  scroll-margin: -11rem !important;
}

.tw--scroll-m-48 {
  scroll-margin: -12rem !important;
}

.tw--scroll-m-5 {
  scroll-margin: -1.25rem !important;
}

.tw--scroll-m-52 {
  scroll-margin: -13rem !important;
}

.tw--scroll-m-56 {
  scroll-margin: -14rem !important;
}

.tw--scroll-m-6 {
  scroll-margin: -1.5rem !important;
}

.tw--scroll-m-60 {
  scroll-margin: -15rem !important;
}

.tw--scroll-m-64 {
  scroll-margin: -16rem !important;
}

.tw--scroll-m-7 {
  scroll-margin: -1.75rem !important;
}

.tw--scroll-m-72 {
  scroll-margin: -18rem !important;
}

.tw--scroll-m-8 {
  scroll-margin: -2rem !important;
}

.tw--scroll-m-80 {
  scroll-margin: -20rem !important;
}

.tw--scroll-m-9 {
  scroll-margin: -2.25rem !important;
}

.tw--scroll-m-96 {
  scroll-margin: -24rem !important;
}

.tw--scroll-m-px {
  scroll-margin: -1px !important;
}

.tw-scroll-m-0 {
  scroll-margin: 0px !important;
}

.tw-scroll-m-0\.5 {
  scroll-margin: 0.125rem !important;
}

.tw-scroll-m-1 {
  scroll-margin: 0.25rem !important;
}

.tw-scroll-m-1\.5 {
  scroll-margin: 0.375rem !important;
}

.tw-scroll-m-10 {
  scroll-margin: 2.5rem !important;
}

.tw-scroll-m-11 {
  scroll-margin: 2.75rem !important;
}

.tw-scroll-m-12 {
  scroll-margin: 3rem !important;
}

.tw-scroll-m-14 {
  scroll-margin: 3.5rem !important;
}

.tw-scroll-m-16 {
  scroll-margin: 4rem !important;
}

.tw-scroll-m-2 {
  scroll-margin: 0.5rem !important;
}

.tw-scroll-m-2\.5 {
  scroll-margin: 0.625rem !important;
}

.tw-scroll-m-20 {
  scroll-margin: 5rem !important;
}

.tw-scroll-m-24 {
  scroll-margin: 6rem !important;
}

.tw-scroll-m-28 {
  scroll-margin: 7rem !important;
}

.tw-scroll-m-3 {
  scroll-margin: 0.75rem !important;
}

.tw-scroll-m-3\.5 {
  scroll-margin: 0.875rem !important;
}

.tw-scroll-m-32 {
  scroll-margin: 8rem !important;
}

.tw-scroll-m-36 {
  scroll-margin: 9rem !important;
}

.tw-scroll-m-4 {
  scroll-margin: 1rem !important;
}

.tw-scroll-m-40 {
  scroll-margin: 10rem !important;
}

.tw-scroll-m-44 {
  scroll-margin: 11rem !important;
}

.tw-scroll-m-48 {
  scroll-margin: 12rem !important;
}

.tw-scroll-m-5 {
  scroll-margin: 1.25rem !important;
}

.tw-scroll-m-52 {
  scroll-margin: 13rem !important;
}

.tw-scroll-m-56 {
  scroll-margin: 14rem !important;
}

.tw-scroll-m-6 {
  scroll-margin: 1.5rem !important;
}

.tw-scroll-m-60 {
  scroll-margin: 15rem !important;
}

.tw-scroll-m-64 {
  scroll-margin: 16rem !important;
}

.tw-scroll-m-7 {
  scroll-margin: 1.75rem !important;
}

.tw-scroll-m-72 {
  scroll-margin: 18rem !important;
}

.tw-scroll-m-8 {
  scroll-margin: 2rem !important;
}

.tw-scroll-m-80 {
  scroll-margin: 20rem !important;
}

.tw-scroll-m-9 {
  scroll-margin: 2.25rem !important;
}

.tw-scroll-m-96 {
  scroll-margin: 24rem !important;
}

.tw-scroll-m-px {
  scroll-margin: 1px !important;
}

.-tw-scroll-mx-0 {
  scroll-margin-left: -0px !important;
  scroll-margin-right: -0px !important;
}

.-tw-scroll-mx-0\.5 {
  scroll-margin-left: -0.125rem !important;
  scroll-margin-right: -0.125rem !important;
}

.-tw-scroll-mx-1 {
  scroll-margin-left: -0.25rem !important;
  scroll-margin-right: -0.25rem !important;
}

.-tw-scroll-mx-1\.5 {
  scroll-margin-left: -0.375rem !important;
  scroll-margin-right: -0.375rem !important;
}

.-tw-scroll-mx-10 {
  scroll-margin-left: -2.5rem !important;
  scroll-margin-right: -2.5rem !important;
}

.-tw-scroll-mx-11 {
  scroll-margin-left: -2.75rem !important;
  scroll-margin-right: -2.75rem !important;
}

.-tw-scroll-mx-12 {
  scroll-margin-left: -3rem !important;
  scroll-margin-right: -3rem !important;
}

.-tw-scroll-mx-14 {
  scroll-margin-left: -3.5rem !important;
  scroll-margin-right: -3.5rem !important;
}

.-tw-scroll-mx-16 {
  scroll-margin-left: -4rem !important;
  scroll-margin-right: -4rem !important;
}

.-tw-scroll-mx-2 {
  scroll-margin-left: -0.5rem !important;
  scroll-margin-right: -0.5rem !important;
}

.-tw-scroll-mx-2\.5 {
  scroll-margin-left: -0.625rem !important;
  scroll-margin-right: -0.625rem !important;
}

.-tw-scroll-mx-20 {
  scroll-margin-left: -5rem !important;
  scroll-margin-right: -5rem !important;
}

.-tw-scroll-mx-24 {
  scroll-margin-left: -6rem !important;
  scroll-margin-right: -6rem !important;
}

.-tw-scroll-mx-28 {
  scroll-margin-left: -7rem !important;
  scroll-margin-right: -7rem !important;
}

.-tw-scroll-mx-3 {
  scroll-margin-left: -0.75rem !important;
  scroll-margin-right: -0.75rem !important;
}

.-tw-scroll-mx-3\.5 {
  scroll-margin-left: -0.875rem !important;
  scroll-margin-right: -0.875rem !important;
}

.-tw-scroll-mx-32 {
  scroll-margin-left: -8rem !important;
  scroll-margin-right: -8rem !important;
}

.-tw-scroll-mx-36 {
  scroll-margin-left: -9rem !important;
  scroll-margin-right: -9rem !important;
}

.-tw-scroll-mx-4 {
  scroll-margin-left: -1rem !important;
  scroll-margin-right: -1rem !important;
}

.-tw-scroll-mx-40 {
  scroll-margin-left: -10rem !important;
  scroll-margin-right: -10rem !important;
}

.-tw-scroll-mx-44 {
  scroll-margin-left: -11rem !important;
  scroll-margin-right: -11rem !important;
}

.-tw-scroll-mx-48 {
  scroll-margin-left: -12rem !important;
  scroll-margin-right: -12rem !important;
}

.-tw-scroll-mx-5 {
  scroll-margin-left: -1.25rem !important;
  scroll-margin-right: -1.25rem !important;
}

.-tw-scroll-mx-52 {
  scroll-margin-left: -13rem !important;
  scroll-margin-right: -13rem !important;
}

.-tw-scroll-mx-56 {
  scroll-margin-left: -14rem !important;
  scroll-margin-right: -14rem !important;
}

.-tw-scroll-mx-6 {
  scroll-margin-left: -1.5rem !important;
  scroll-margin-right: -1.5rem !important;
}

.-tw-scroll-mx-60 {
  scroll-margin-left: -15rem !important;
  scroll-margin-right: -15rem !important;
}

.-tw-scroll-mx-64 {
  scroll-margin-left: -16rem !important;
  scroll-margin-right: -16rem !important;
}

.-tw-scroll-mx-7 {
  scroll-margin-left: -1.75rem !important;
  scroll-margin-right: -1.75rem !important;
}

.-tw-scroll-mx-72 {
  scroll-margin-left: -18rem !important;
  scroll-margin-right: -18rem !important;
}

.-tw-scroll-mx-8 {
  scroll-margin-left: -2rem !important;
  scroll-margin-right: -2rem !important;
}

.-tw-scroll-mx-80 {
  scroll-margin-left: -20rem !important;
  scroll-margin-right: -20rem !important;
}

.-tw-scroll-mx-9 {
  scroll-margin-left: -2.25rem !important;
  scroll-margin-right: -2.25rem !important;
}

.-tw-scroll-mx-96 {
  scroll-margin-left: -24rem !important;
  scroll-margin-right: -24rem !important;
}

.-tw-scroll-mx-px {
  scroll-margin-left: -1px !important;
  scroll-margin-right: -1px !important;
}

.-tw-scroll-my-0 {
  scroll-margin-top: -0px !important;
  scroll-margin-bottom: -0px !important;
}

.-tw-scroll-my-0\.5 {
  scroll-margin-top: -0.125rem !important;
  scroll-margin-bottom: -0.125rem !important;
}

.-tw-scroll-my-1 {
  scroll-margin-top: -0.25rem !important;
  scroll-margin-bottom: -0.25rem !important;
}

.-tw-scroll-my-1\.5 {
  scroll-margin-top: -0.375rem !important;
  scroll-margin-bottom: -0.375rem !important;
}

.-tw-scroll-my-10 {
  scroll-margin-top: -2.5rem !important;
  scroll-margin-bottom: -2.5rem !important;
}

.-tw-scroll-my-11 {
  scroll-margin-top: -2.75rem !important;
  scroll-margin-bottom: -2.75rem !important;
}

.-tw-scroll-my-12 {
  scroll-margin-top: -3rem !important;
  scroll-margin-bottom: -3rem !important;
}

.-tw-scroll-my-14 {
  scroll-margin-top: -3.5rem !important;
  scroll-margin-bottom: -3.5rem !important;
}

.-tw-scroll-my-16 {
  scroll-margin-top: -4rem !important;
  scroll-margin-bottom: -4rem !important;
}

.-tw-scroll-my-2 {
  scroll-margin-top: -0.5rem !important;
  scroll-margin-bottom: -0.5rem !important;
}

.-tw-scroll-my-2\.5 {
  scroll-margin-top: -0.625rem !important;
  scroll-margin-bottom: -0.625rem !important;
}

.-tw-scroll-my-20 {
  scroll-margin-top: -5rem !important;
  scroll-margin-bottom: -5rem !important;
}

.-tw-scroll-my-24 {
  scroll-margin-top: -6rem !important;
  scroll-margin-bottom: -6rem !important;
}

.-tw-scroll-my-28 {
  scroll-margin-top: -7rem !important;
  scroll-margin-bottom: -7rem !important;
}

.-tw-scroll-my-3 {
  scroll-margin-top: -0.75rem !important;
  scroll-margin-bottom: -0.75rem !important;
}

.-tw-scroll-my-3\.5 {
  scroll-margin-top: -0.875rem !important;
  scroll-margin-bottom: -0.875rem !important;
}

.-tw-scroll-my-32 {
  scroll-margin-top: -8rem !important;
  scroll-margin-bottom: -8rem !important;
}

.-tw-scroll-my-36 {
  scroll-margin-top: -9rem !important;
  scroll-margin-bottom: -9rem !important;
}

.-tw-scroll-my-4 {
  scroll-margin-top: -1rem !important;
  scroll-margin-bottom: -1rem !important;
}

.-tw-scroll-my-40 {
  scroll-margin-top: -10rem !important;
  scroll-margin-bottom: -10rem !important;
}

.-tw-scroll-my-44 {
  scroll-margin-top: -11rem !important;
  scroll-margin-bottom: -11rem !important;
}

.-tw-scroll-my-48 {
  scroll-margin-top: -12rem !important;
  scroll-margin-bottom: -12rem !important;
}

.-tw-scroll-my-5 {
  scroll-margin-top: -1.25rem !important;
  scroll-margin-bottom: -1.25rem !important;
}

.-tw-scroll-my-52 {
  scroll-margin-top: -13rem !important;
  scroll-margin-bottom: -13rem !important;
}

.-tw-scroll-my-56 {
  scroll-margin-top: -14rem !important;
  scroll-margin-bottom: -14rem !important;
}

.-tw-scroll-my-6 {
  scroll-margin-top: -1.5rem !important;
  scroll-margin-bottom: -1.5rem !important;
}

.-tw-scroll-my-60 {
  scroll-margin-top: -15rem !important;
  scroll-margin-bottom: -15rem !important;
}

.-tw-scroll-my-64 {
  scroll-margin-top: -16rem !important;
  scroll-margin-bottom: -16rem !important;
}

.-tw-scroll-my-7 {
  scroll-margin-top: -1.75rem !important;
  scroll-margin-bottom: -1.75rem !important;
}

.-tw-scroll-my-72 {
  scroll-margin-top: -18rem !important;
  scroll-margin-bottom: -18rem !important;
}

.-tw-scroll-my-8 {
  scroll-margin-top: -2rem !important;
  scroll-margin-bottom: -2rem !important;
}

.-tw-scroll-my-80 {
  scroll-margin-top: -20rem !important;
  scroll-margin-bottom: -20rem !important;
}

.-tw-scroll-my-9 {
  scroll-margin-top: -2.25rem !important;
  scroll-margin-bottom: -2.25rem !important;
}

.-tw-scroll-my-96 {
  scroll-margin-top: -24rem !important;
  scroll-margin-bottom: -24rem !important;
}

.-tw-scroll-my-px {
  scroll-margin-top: -1px !important;
  scroll-margin-bottom: -1px !important;
}

.tw--scroll-mx-0 {
  scroll-margin-left: -0px !important;
  scroll-margin-right: -0px !important;
}

.tw--scroll-mx-0\.5 {
  scroll-margin-left: -0.125rem !important;
  scroll-margin-right: -0.125rem !important;
}

.tw--scroll-mx-1 {
  scroll-margin-left: -0.25rem !important;
  scroll-margin-right: -0.25rem !important;
}

.tw--scroll-mx-1\.5 {
  scroll-margin-left: -0.375rem !important;
  scroll-margin-right: -0.375rem !important;
}

.tw--scroll-mx-10 {
  scroll-margin-left: -2.5rem !important;
  scroll-margin-right: -2.5rem !important;
}

.tw--scroll-mx-11 {
  scroll-margin-left: -2.75rem !important;
  scroll-margin-right: -2.75rem !important;
}

.tw--scroll-mx-12 {
  scroll-margin-left: -3rem !important;
  scroll-margin-right: -3rem !important;
}

.tw--scroll-mx-14 {
  scroll-margin-left: -3.5rem !important;
  scroll-margin-right: -3.5rem !important;
}

.tw--scroll-mx-16 {
  scroll-margin-left: -4rem !important;
  scroll-margin-right: -4rem !important;
}

.tw--scroll-mx-2 {
  scroll-margin-left: -0.5rem !important;
  scroll-margin-right: -0.5rem !important;
}

.tw--scroll-mx-2\.5 {
  scroll-margin-left: -0.625rem !important;
  scroll-margin-right: -0.625rem !important;
}

.tw--scroll-mx-20 {
  scroll-margin-left: -5rem !important;
  scroll-margin-right: -5rem !important;
}

.tw--scroll-mx-24 {
  scroll-margin-left: -6rem !important;
  scroll-margin-right: -6rem !important;
}

.tw--scroll-mx-28 {
  scroll-margin-left: -7rem !important;
  scroll-margin-right: -7rem !important;
}

.tw--scroll-mx-3 {
  scroll-margin-left: -0.75rem !important;
  scroll-margin-right: -0.75rem !important;
}

.tw--scroll-mx-3\.5 {
  scroll-margin-left: -0.875rem !important;
  scroll-margin-right: -0.875rem !important;
}

.tw--scroll-mx-32 {
  scroll-margin-left: -8rem !important;
  scroll-margin-right: -8rem !important;
}

.tw--scroll-mx-36 {
  scroll-margin-left: -9rem !important;
  scroll-margin-right: -9rem !important;
}

.tw--scroll-mx-4 {
  scroll-margin-left: -1rem !important;
  scroll-margin-right: -1rem !important;
}

.tw--scroll-mx-40 {
  scroll-margin-left: -10rem !important;
  scroll-margin-right: -10rem !important;
}

.tw--scroll-mx-44 {
  scroll-margin-left: -11rem !important;
  scroll-margin-right: -11rem !important;
}

.tw--scroll-mx-48 {
  scroll-margin-left: -12rem !important;
  scroll-margin-right: -12rem !important;
}

.tw--scroll-mx-5 {
  scroll-margin-left: -1.25rem !important;
  scroll-margin-right: -1.25rem !important;
}

.tw--scroll-mx-52 {
  scroll-margin-left: -13rem !important;
  scroll-margin-right: -13rem !important;
}

.tw--scroll-mx-56 {
  scroll-margin-left: -14rem !important;
  scroll-margin-right: -14rem !important;
}

.tw--scroll-mx-6 {
  scroll-margin-left: -1.5rem !important;
  scroll-margin-right: -1.5rem !important;
}

.tw--scroll-mx-60 {
  scroll-margin-left: -15rem !important;
  scroll-margin-right: -15rem !important;
}

.tw--scroll-mx-64 {
  scroll-margin-left: -16rem !important;
  scroll-margin-right: -16rem !important;
}

.tw--scroll-mx-7 {
  scroll-margin-left: -1.75rem !important;
  scroll-margin-right: -1.75rem !important;
}

.tw--scroll-mx-72 {
  scroll-margin-left: -18rem !important;
  scroll-margin-right: -18rem !important;
}

.tw--scroll-mx-8 {
  scroll-margin-left: -2rem !important;
  scroll-margin-right: -2rem !important;
}

.tw--scroll-mx-80 {
  scroll-margin-left: -20rem !important;
  scroll-margin-right: -20rem !important;
}

.tw--scroll-mx-9 {
  scroll-margin-left: -2.25rem !important;
  scroll-margin-right: -2.25rem !important;
}

.tw--scroll-mx-96 {
  scroll-margin-left: -24rem !important;
  scroll-margin-right: -24rem !important;
}

.tw--scroll-mx-px {
  scroll-margin-left: -1px !important;
  scroll-margin-right: -1px !important;
}

.tw--scroll-my-0 {
  scroll-margin-top: -0px !important;
  scroll-margin-bottom: -0px !important;
}

.tw--scroll-my-0\.5 {
  scroll-margin-top: -0.125rem !important;
  scroll-margin-bottom: -0.125rem !important;
}

.tw--scroll-my-1 {
  scroll-margin-top: -0.25rem !important;
  scroll-margin-bottom: -0.25rem !important;
}

.tw--scroll-my-1\.5 {
  scroll-margin-top: -0.375rem !important;
  scroll-margin-bottom: -0.375rem !important;
}

.tw--scroll-my-10 {
  scroll-margin-top: -2.5rem !important;
  scroll-margin-bottom: -2.5rem !important;
}

.tw--scroll-my-11 {
  scroll-margin-top: -2.75rem !important;
  scroll-margin-bottom: -2.75rem !important;
}

.tw--scroll-my-12 {
  scroll-margin-top: -3rem !important;
  scroll-margin-bottom: -3rem !important;
}

.tw--scroll-my-14 {
  scroll-margin-top: -3.5rem !important;
  scroll-margin-bottom: -3.5rem !important;
}

.tw--scroll-my-16 {
  scroll-margin-top: -4rem !important;
  scroll-margin-bottom: -4rem !important;
}

.tw--scroll-my-2 {
  scroll-margin-top: -0.5rem !important;
  scroll-margin-bottom: -0.5rem !important;
}

.tw--scroll-my-2\.5 {
  scroll-margin-top: -0.625rem !important;
  scroll-margin-bottom: -0.625rem !important;
}

.tw--scroll-my-20 {
  scroll-margin-top: -5rem !important;
  scroll-margin-bottom: -5rem !important;
}

.tw--scroll-my-24 {
  scroll-margin-top: -6rem !important;
  scroll-margin-bottom: -6rem !important;
}

.tw--scroll-my-28 {
  scroll-margin-top: -7rem !important;
  scroll-margin-bottom: -7rem !important;
}

.tw--scroll-my-3 {
  scroll-margin-top: -0.75rem !important;
  scroll-margin-bottom: -0.75rem !important;
}

.tw--scroll-my-3\.5 {
  scroll-margin-top: -0.875rem !important;
  scroll-margin-bottom: -0.875rem !important;
}

.tw--scroll-my-32 {
  scroll-margin-top: -8rem !important;
  scroll-margin-bottom: -8rem !important;
}

.tw--scroll-my-36 {
  scroll-margin-top: -9rem !important;
  scroll-margin-bottom: -9rem !important;
}

.tw--scroll-my-4 {
  scroll-margin-top: -1rem !important;
  scroll-margin-bottom: -1rem !important;
}

.tw--scroll-my-40 {
  scroll-margin-top: -10rem !important;
  scroll-margin-bottom: -10rem !important;
}

.tw--scroll-my-44 {
  scroll-margin-top: -11rem !important;
  scroll-margin-bottom: -11rem !important;
}

.tw--scroll-my-48 {
  scroll-margin-top: -12rem !important;
  scroll-margin-bottom: -12rem !important;
}

.tw--scroll-my-5 {
  scroll-margin-top: -1.25rem !important;
  scroll-margin-bottom: -1.25rem !important;
}

.tw--scroll-my-52 {
  scroll-margin-top: -13rem !important;
  scroll-margin-bottom: -13rem !important;
}

.tw--scroll-my-56 {
  scroll-margin-top: -14rem !important;
  scroll-margin-bottom: -14rem !important;
}

.tw--scroll-my-6 {
  scroll-margin-top: -1.5rem !important;
  scroll-margin-bottom: -1.5rem !important;
}

.tw--scroll-my-60 {
  scroll-margin-top: -15rem !important;
  scroll-margin-bottom: -15rem !important;
}

.tw--scroll-my-64 {
  scroll-margin-top: -16rem !important;
  scroll-margin-bottom: -16rem !important;
}

.tw--scroll-my-7 {
  scroll-margin-top: -1.75rem !important;
  scroll-margin-bottom: -1.75rem !important;
}

.tw--scroll-my-72 {
  scroll-margin-top: -18rem !important;
  scroll-margin-bottom: -18rem !important;
}

.tw--scroll-my-8 {
  scroll-margin-top: -2rem !important;
  scroll-margin-bottom: -2rem !important;
}

.tw--scroll-my-80 {
  scroll-margin-top: -20rem !important;
  scroll-margin-bottom: -20rem !important;
}

.tw--scroll-my-9 {
  scroll-margin-top: -2.25rem !important;
  scroll-margin-bottom: -2.25rem !important;
}

.tw--scroll-my-96 {
  scroll-margin-top: -24rem !important;
  scroll-margin-bottom: -24rem !important;
}

.tw--scroll-my-px {
  scroll-margin-top: -1px !important;
  scroll-margin-bottom: -1px !important;
}

.tw-scroll-mx-0 {
  scroll-margin-left: 0px !important;
  scroll-margin-right: 0px !important;
}

.tw-scroll-mx-0\.5 {
  scroll-margin-left: 0.125rem !important;
  scroll-margin-right: 0.125rem !important;
}

.tw-scroll-mx-1 {
  scroll-margin-left: 0.25rem !important;
  scroll-margin-right: 0.25rem !important;
}

.tw-scroll-mx-1\.5 {
  scroll-margin-left: 0.375rem !important;
  scroll-margin-right: 0.375rem !important;
}

.tw-scroll-mx-10 {
  scroll-margin-left: 2.5rem !important;
  scroll-margin-right: 2.5rem !important;
}

.tw-scroll-mx-11 {
  scroll-margin-left: 2.75rem !important;
  scroll-margin-right: 2.75rem !important;
}

.tw-scroll-mx-12 {
  scroll-margin-left: 3rem !important;
  scroll-margin-right: 3rem !important;
}

.tw-scroll-mx-14 {
  scroll-margin-left: 3.5rem !important;
  scroll-margin-right: 3.5rem !important;
}

.tw-scroll-mx-16 {
  scroll-margin-left: 4rem !important;
  scroll-margin-right: 4rem !important;
}

.tw-scroll-mx-2 {
  scroll-margin-left: 0.5rem !important;
  scroll-margin-right: 0.5rem !important;
}

.tw-scroll-mx-2\.5 {
  scroll-margin-left: 0.625rem !important;
  scroll-margin-right: 0.625rem !important;
}

.tw-scroll-mx-20 {
  scroll-margin-left: 5rem !important;
  scroll-margin-right: 5rem !important;
}

.tw-scroll-mx-24 {
  scroll-margin-left: 6rem !important;
  scroll-margin-right: 6rem !important;
}

.tw-scroll-mx-28 {
  scroll-margin-left: 7rem !important;
  scroll-margin-right: 7rem !important;
}

.tw-scroll-mx-3 {
  scroll-margin-left: 0.75rem !important;
  scroll-margin-right: 0.75rem !important;
}

.tw-scroll-mx-3\.5 {
  scroll-margin-left: 0.875rem !important;
  scroll-margin-right: 0.875rem !important;
}

.tw-scroll-mx-32 {
  scroll-margin-left: 8rem !important;
  scroll-margin-right: 8rem !important;
}

.tw-scroll-mx-36 {
  scroll-margin-left: 9rem !important;
  scroll-margin-right: 9rem !important;
}

.tw-scroll-mx-4 {
  scroll-margin-left: 1rem !important;
  scroll-margin-right: 1rem !important;
}

.tw-scroll-mx-40 {
  scroll-margin-left: 10rem !important;
  scroll-margin-right: 10rem !important;
}

.tw-scroll-mx-44 {
  scroll-margin-left: 11rem !important;
  scroll-margin-right: 11rem !important;
}

.tw-scroll-mx-48 {
  scroll-margin-left: 12rem !important;
  scroll-margin-right: 12rem !important;
}

.tw-scroll-mx-5 {
  scroll-margin-left: 1.25rem !important;
  scroll-margin-right: 1.25rem !important;
}

.tw-scroll-mx-52 {
  scroll-margin-left: 13rem !important;
  scroll-margin-right: 13rem !important;
}

.tw-scroll-mx-56 {
  scroll-margin-left: 14rem !important;
  scroll-margin-right: 14rem !important;
}

.tw-scroll-mx-6 {
  scroll-margin-left: 1.5rem !important;
  scroll-margin-right: 1.5rem !important;
}

.tw-scroll-mx-60 {
  scroll-margin-left: 15rem !important;
  scroll-margin-right: 15rem !important;
}

.tw-scroll-mx-64 {
  scroll-margin-left: 16rem !important;
  scroll-margin-right: 16rem !important;
}

.tw-scroll-mx-7 {
  scroll-margin-left: 1.75rem !important;
  scroll-margin-right: 1.75rem !important;
}

.tw-scroll-mx-72 {
  scroll-margin-left: 18rem !important;
  scroll-margin-right: 18rem !important;
}

.tw-scroll-mx-8 {
  scroll-margin-left: 2rem !important;
  scroll-margin-right: 2rem !important;
}

.tw-scroll-mx-80 {
  scroll-margin-left: 20rem !important;
  scroll-margin-right: 20rem !important;
}

.tw-scroll-mx-9 {
  scroll-margin-left: 2.25rem !important;
  scroll-margin-right: 2.25rem !important;
}

.tw-scroll-mx-96 {
  scroll-margin-left: 24rem !important;
  scroll-margin-right: 24rem !important;
}

.tw-scroll-mx-px {
  scroll-margin-left: 1px !important;
  scroll-margin-right: 1px !important;
}

.tw-scroll-my-0 {
  scroll-margin-top: 0px !important;
  scroll-margin-bottom: 0px !important;
}

.tw-scroll-my-0\.5 {
  scroll-margin-top: 0.125rem !important;
  scroll-margin-bottom: 0.125rem !important;
}

.tw-scroll-my-1 {
  scroll-margin-top: 0.25rem !important;
  scroll-margin-bottom: 0.25rem !important;
}

.tw-scroll-my-1\.5 {
  scroll-margin-top: 0.375rem !important;
  scroll-margin-bottom: 0.375rem !important;
}

.tw-scroll-my-10 {
  scroll-margin-top: 2.5rem !important;
  scroll-margin-bottom: 2.5rem !important;
}

.tw-scroll-my-11 {
  scroll-margin-top: 2.75rem !important;
  scroll-margin-bottom: 2.75rem !important;
}

.tw-scroll-my-12 {
  scroll-margin-top: 3rem !important;
  scroll-margin-bottom: 3rem !important;
}

.tw-scroll-my-14 {
  scroll-margin-top: 3.5rem !important;
  scroll-margin-bottom: 3.5rem !important;
}

.tw-scroll-my-16 {
  scroll-margin-top: 4rem !important;
  scroll-margin-bottom: 4rem !important;
}

.tw-scroll-my-2 {
  scroll-margin-top: 0.5rem !important;
  scroll-margin-bottom: 0.5rem !important;
}

.tw-scroll-my-2\.5 {
  scroll-margin-top: 0.625rem !important;
  scroll-margin-bottom: 0.625rem !important;
}

.tw-scroll-my-20 {
  scroll-margin-top: 5rem !important;
  scroll-margin-bottom: 5rem !important;
}

.tw-scroll-my-24 {
  scroll-margin-top: 6rem !important;
  scroll-margin-bottom: 6rem !important;
}

.tw-scroll-my-28 {
  scroll-margin-top: 7rem !important;
  scroll-margin-bottom: 7rem !important;
}

.tw-scroll-my-3 {
  scroll-margin-top: 0.75rem !important;
  scroll-margin-bottom: 0.75rem !important;
}

.tw-scroll-my-3\.5 {
  scroll-margin-top: 0.875rem !important;
  scroll-margin-bottom: 0.875rem !important;
}

.tw-scroll-my-32 {
  scroll-margin-top: 8rem !important;
  scroll-margin-bottom: 8rem !important;
}

.tw-scroll-my-36 {
  scroll-margin-top: 9rem !important;
  scroll-margin-bottom: 9rem !important;
}

.tw-scroll-my-4 {
  scroll-margin-top: 1rem !important;
  scroll-margin-bottom: 1rem !important;
}

.tw-scroll-my-40 {
  scroll-margin-top: 10rem !important;
  scroll-margin-bottom: 10rem !important;
}

.tw-scroll-my-44 {
  scroll-margin-top: 11rem !important;
  scroll-margin-bottom: 11rem !important;
}

.tw-scroll-my-48 {
  scroll-margin-top: 12rem !important;
  scroll-margin-bottom: 12rem !important;
}

.tw-scroll-my-5 {
  scroll-margin-top: 1.25rem !important;
  scroll-margin-bottom: 1.25rem !important;
}

.tw-scroll-my-52 {
  scroll-margin-top: 13rem !important;
  scroll-margin-bottom: 13rem !important;
}

.tw-scroll-my-56 {
  scroll-margin-top: 14rem !important;
  scroll-margin-bottom: 14rem !important;
}

.tw-scroll-my-6 {
  scroll-margin-top: 1.5rem !important;
  scroll-margin-bottom: 1.5rem !important;
}

.tw-scroll-my-60 {
  scroll-margin-top: 15rem !important;
  scroll-margin-bottom: 15rem !important;
}

.tw-scroll-my-64 {
  scroll-margin-top: 16rem !important;
  scroll-margin-bottom: 16rem !important;
}

.tw-scroll-my-7 {
  scroll-margin-top: 1.75rem !important;
  scroll-margin-bottom: 1.75rem !important;
}

.tw-scroll-my-72 {
  scroll-margin-top: 18rem !important;
  scroll-margin-bottom: 18rem !important;
}

.tw-scroll-my-8 {
  scroll-margin-top: 2rem !important;
  scroll-margin-bottom: 2rem !important;
}

.tw-scroll-my-80 {
  scroll-margin-top: 20rem !important;
  scroll-margin-bottom: 20rem !important;
}

.tw-scroll-my-9 {
  scroll-margin-top: 2.25rem !important;
  scroll-margin-bottom: 2.25rem !important;
}

.tw-scroll-my-96 {
  scroll-margin-top: 24rem !important;
  scroll-margin-bottom: 24rem !important;
}

.tw-scroll-my-px {
  scroll-margin-top: 1px !important;
  scroll-margin-bottom: 1px !important;
}

.-tw-scroll-mb-0 {
  scroll-margin-bottom: -0px !important;
}

.-tw-scroll-mb-0\.5 {
  scroll-margin-bottom: -0.125rem !important;
}

.-tw-scroll-mb-1 {
  scroll-margin-bottom: -0.25rem !important;
}

.-tw-scroll-mb-1\.5 {
  scroll-margin-bottom: -0.375rem !important;
}

.-tw-scroll-mb-10 {
  scroll-margin-bottom: -2.5rem !important;
}

.-tw-scroll-mb-11 {
  scroll-margin-bottom: -2.75rem !important;
}

.-tw-scroll-mb-12 {
  scroll-margin-bottom: -3rem !important;
}

.-tw-scroll-mb-14 {
  scroll-margin-bottom: -3.5rem !important;
}

.-tw-scroll-mb-16 {
  scroll-margin-bottom: -4rem !important;
}

.-tw-scroll-mb-2 {
  scroll-margin-bottom: -0.5rem !important;
}

.-tw-scroll-mb-2\.5 {
  scroll-margin-bottom: -0.625rem !important;
}

.-tw-scroll-mb-20 {
  scroll-margin-bottom: -5rem !important;
}

.-tw-scroll-mb-24 {
  scroll-margin-bottom: -6rem !important;
}

.-tw-scroll-mb-28 {
  scroll-margin-bottom: -7rem !important;
}

.-tw-scroll-mb-3 {
  scroll-margin-bottom: -0.75rem !important;
}

.-tw-scroll-mb-3\.5 {
  scroll-margin-bottom: -0.875rem !important;
}

.-tw-scroll-mb-32 {
  scroll-margin-bottom: -8rem !important;
}

.-tw-scroll-mb-36 {
  scroll-margin-bottom: -9rem !important;
}

.-tw-scroll-mb-4 {
  scroll-margin-bottom: -1rem !important;
}

.-tw-scroll-mb-40 {
  scroll-margin-bottom: -10rem !important;
}

.-tw-scroll-mb-44 {
  scroll-margin-bottom: -11rem !important;
}

.-tw-scroll-mb-48 {
  scroll-margin-bottom: -12rem !important;
}

.-tw-scroll-mb-5 {
  scroll-margin-bottom: -1.25rem !important;
}

.-tw-scroll-mb-52 {
  scroll-margin-bottom: -13rem !important;
}

.-tw-scroll-mb-56 {
  scroll-margin-bottom: -14rem !important;
}

.-tw-scroll-mb-6 {
  scroll-margin-bottom: -1.5rem !important;
}

.-tw-scroll-mb-60 {
  scroll-margin-bottom: -15rem !important;
}

.-tw-scroll-mb-64 {
  scroll-margin-bottom: -16rem !important;
}

.-tw-scroll-mb-7 {
  scroll-margin-bottom: -1.75rem !important;
}

.-tw-scroll-mb-72 {
  scroll-margin-bottom: -18rem !important;
}

.-tw-scroll-mb-8 {
  scroll-margin-bottom: -2rem !important;
}

.-tw-scroll-mb-80 {
  scroll-margin-bottom: -20rem !important;
}

.-tw-scroll-mb-9 {
  scroll-margin-bottom: -2.25rem !important;
}

.-tw-scroll-mb-96 {
  scroll-margin-bottom: -24rem !important;
}

.-tw-scroll-mb-px {
  scroll-margin-bottom: -1px !important;
}

.-tw-scroll-me-0 {
  scroll-margin-inline-end: -0px !important;
}

.-tw-scroll-me-0\.5 {
  scroll-margin-inline-end: -0.125rem !important;
}

.-tw-scroll-me-1 {
  scroll-margin-inline-end: -0.25rem !important;
}

.-tw-scroll-me-1\.5 {
  scroll-margin-inline-end: -0.375rem !important;
}

.-tw-scroll-me-10 {
  scroll-margin-inline-end: -2.5rem !important;
}

.-tw-scroll-me-11 {
  scroll-margin-inline-end: -2.75rem !important;
}

.-tw-scroll-me-12 {
  scroll-margin-inline-end: -3rem !important;
}

.-tw-scroll-me-14 {
  scroll-margin-inline-end: -3.5rem !important;
}

.-tw-scroll-me-16 {
  scroll-margin-inline-end: -4rem !important;
}

.-tw-scroll-me-2 {
  scroll-margin-inline-end: -0.5rem !important;
}

.-tw-scroll-me-2\.5 {
  scroll-margin-inline-end: -0.625rem !important;
}

.-tw-scroll-me-20 {
  scroll-margin-inline-end: -5rem !important;
}

.-tw-scroll-me-24 {
  scroll-margin-inline-end: -6rem !important;
}

.-tw-scroll-me-28 {
  scroll-margin-inline-end: -7rem !important;
}

.-tw-scroll-me-3 {
  scroll-margin-inline-end: -0.75rem !important;
}

.-tw-scroll-me-3\.5 {
  scroll-margin-inline-end: -0.875rem !important;
}

.-tw-scroll-me-32 {
  scroll-margin-inline-end: -8rem !important;
}

.-tw-scroll-me-36 {
  scroll-margin-inline-end: -9rem !important;
}

.-tw-scroll-me-4 {
  scroll-margin-inline-end: -1rem !important;
}

.-tw-scroll-me-40 {
  scroll-margin-inline-end: -10rem !important;
}

.-tw-scroll-me-44 {
  scroll-margin-inline-end: -11rem !important;
}

.-tw-scroll-me-48 {
  scroll-margin-inline-end: -12rem !important;
}

.-tw-scroll-me-5 {
  scroll-margin-inline-end: -1.25rem !important;
}

.-tw-scroll-me-52 {
  scroll-margin-inline-end: -13rem !important;
}

.-tw-scroll-me-56 {
  scroll-margin-inline-end: -14rem !important;
}

.-tw-scroll-me-6 {
  scroll-margin-inline-end: -1.5rem !important;
}

.-tw-scroll-me-60 {
  scroll-margin-inline-end: -15rem !important;
}

.-tw-scroll-me-64 {
  scroll-margin-inline-end: -16rem !important;
}

.-tw-scroll-me-7 {
  scroll-margin-inline-end: -1.75rem !important;
}

.-tw-scroll-me-72 {
  scroll-margin-inline-end: -18rem !important;
}

.-tw-scroll-me-8 {
  scroll-margin-inline-end: -2rem !important;
}

.-tw-scroll-me-80 {
  scroll-margin-inline-end: -20rem !important;
}

.-tw-scroll-me-9 {
  scroll-margin-inline-end: -2.25rem !important;
}

.-tw-scroll-me-96 {
  scroll-margin-inline-end: -24rem !important;
}

.-tw-scroll-me-px {
  scroll-margin-inline-end: -1px !important;
}

.-tw-scroll-ml-0 {
  scroll-margin-left: -0px !important;
}

.-tw-scroll-ml-0\.5 {
  scroll-margin-left: -0.125rem !important;
}

.-tw-scroll-ml-1 {
  scroll-margin-left: -0.25rem !important;
}

.-tw-scroll-ml-1\.5 {
  scroll-margin-left: -0.375rem !important;
}

.-tw-scroll-ml-10 {
  scroll-margin-left: -2.5rem !important;
}

.-tw-scroll-ml-11 {
  scroll-margin-left: -2.75rem !important;
}

.-tw-scroll-ml-12 {
  scroll-margin-left: -3rem !important;
}

.-tw-scroll-ml-14 {
  scroll-margin-left: -3.5rem !important;
}

.-tw-scroll-ml-16 {
  scroll-margin-left: -4rem !important;
}

.-tw-scroll-ml-2 {
  scroll-margin-left: -0.5rem !important;
}

.-tw-scroll-ml-2\.5 {
  scroll-margin-left: -0.625rem !important;
}

.-tw-scroll-ml-20 {
  scroll-margin-left: -5rem !important;
}

.-tw-scroll-ml-24 {
  scroll-margin-left: -6rem !important;
}

.-tw-scroll-ml-28 {
  scroll-margin-left: -7rem !important;
}

.-tw-scroll-ml-3 {
  scroll-margin-left: -0.75rem !important;
}

.-tw-scroll-ml-3\.5 {
  scroll-margin-left: -0.875rem !important;
}

.-tw-scroll-ml-32 {
  scroll-margin-left: -8rem !important;
}

.-tw-scroll-ml-36 {
  scroll-margin-left: -9rem !important;
}

.-tw-scroll-ml-4 {
  scroll-margin-left: -1rem !important;
}

.-tw-scroll-ml-40 {
  scroll-margin-left: -10rem !important;
}

.-tw-scroll-ml-44 {
  scroll-margin-left: -11rem !important;
}

.-tw-scroll-ml-48 {
  scroll-margin-left: -12rem !important;
}

.-tw-scroll-ml-5 {
  scroll-margin-left: -1.25rem !important;
}

.-tw-scroll-ml-52 {
  scroll-margin-left: -13rem !important;
}

.-tw-scroll-ml-56 {
  scroll-margin-left: -14rem !important;
}

.-tw-scroll-ml-6 {
  scroll-margin-left: -1.5rem !important;
}

.-tw-scroll-ml-60 {
  scroll-margin-left: -15rem !important;
}

.-tw-scroll-ml-64 {
  scroll-margin-left: -16rem !important;
}

.-tw-scroll-ml-7 {
  scroll-margin-left: -1.75rem !important;
}

.-tw-scroll-ml-72 {
  scroll-margin-left: -18rem !important;
}

.-tw-scroll-ml-8 {
  scroll-margin-left: -2rem !important;
}

.-tw-scroll-ml-80 {
  scroll-margin-left: -20rem !important;
}

.-tw-scroll-ml-9 {
  scroll-margin-left: -2.25rem !important;
}

.-tw-scroll-ml-96 {
  scroll-margin-left: -24rem !important;
}

.-tw-scroll-ml-px {
  scroll-margin-left: -1px !important;
}

.-tw-scroll-mr-0 {
  scroll-margin-right: -0px !important;
}

.-tw-scroll-mr-0\.5 {
  scroll-margin-right: -0.125rem !important;
}

.-tw-scroll-mr-1 {
  scroll-margin-right: -0.25rem !important;
}

.-tw-scroll-mr-1\.5 {
  scroll-margin-right: -0.375rem !important;
}

.-tw-scroll-mr-10 {
  scroll-margin-right: -2.5rem !important;
}

.-tw-scroll-mr-11 {
  scroll-margin-right: -2.75rem !important;
}

.-tw-scroll-mr-12 {
  scroll-margin-right: -3rem !important;
}

.-tw-scroll-mr-14 {
  scroll-margin-right: -3.5rem !important;
}

.-tw-scroll-mr-16 {
  scroll-margin-right: -4rem !important;
}

.-tw-scroll-mr-2 {
  scroll-margin-right: -0.5rem !important;
}

.-tw-scroll-mr-2\.5 {
  scroll-margin-right: -0.625rem !important;
}

.-tw-scroll-mr-20 {
  scroll-margin-right: -5rem !important;
}

.-tw-scroll-mr-24 {
  scroll-margin-right: -6rem !important;
}

.-tw-scroll-mr-28 {
  scroll-margin-right: -7rem !important;
}

.-tw-scroll-mr-3 {
  scroll-margin-right: -0.75rem !important;
}

.-tw-scroll-mr-3\.5 {
  scroll-margin-right: -0.875rem !important;
}

.-tw-scroll-mr-32 {
  scroll-margin-right: -8rem !important;
}

.-tw-scroll-mr-36 {
  scroll-margin-right: -9rem !important;
}

.-tw-scroll-mr-4 {
  scroll-margin-right: -1rem !important;
}

.-tw-scroll-mr-40 {
  scroll-margin-right: -10rem !important;
}

.-tw-scroll-mr-44 {
  scroll-margin-right: -11rem !important;
}

.-tw-scroll-mr-48 {
  scroll-margin-right: -12rem !important;
}

.-tw-scroll-mr-5 {
  scroll-margin-right: -1.25rem !important;
}

.-tw-scroll-mr-52 {
  scroll-margin-right: -13rem !important;
}

.-tw-scroll-mr-56 {
  scroll-margin-right: -14rem !important;
}

.-tw-scroll-mr-6 {
  scroll-margin-right: -1.5rem !important;
}

.-tw-scroll-mr-60 {
  scroll-margin-right: -15rem !important;
}

.-tw-scroll-mr-64 {
  scroll-margin-right: -16rem !important;
}

.-tw-scroll-mr-7 {
  scroll-margin-right: -1.75rem !important;
}

.-tw-scroll-mr-72 {
  scroll-margin-right: -18rem !important;
}

.-tw-scroll-mr-8 {
  scroll-margin-right: -2rem !important;
}

.-tw-scroll-mr-80 {
  scroll-margin-right: -20rem !important;
}

.-tw-scroll-mr-9 {
  scroll-margin-right: -2.25rem !important;
}

.-tw-scroll-mr-96 {
  scroll-margin-right: -24rem !important;
}

.-tw-scroll-mr-px {
  scroll-margin-right: -1px !important;
}

.-tw-scroll-ms-0 {
  scroll-margin-inline-start: -0px !important;
}

.-tw-scroll-ms-0\.5 {
  scroll-margin-inline-start: -0.125rem !important;
}

.-tw-scroll-ms-1 {
  scroll-margin-inline-start: -0.25rem !important;
}

.-tw-scroll-ms-1\.5 {
  scroll-margin-inline-start: -0.375rem !important;
}

.-tw-scroll-ms-10 {
  scroll-margin-inline-start: -2.5rem !important;
}

.-tw-scroll-ms-11 {
  scroll-margin-inline-start: -2.75rem !important;
}

.-tw-scroll-ms-12 {
  scroll-margin-inline-start: -3rem !important;
}

.-tw-scroll-ms-14 {
  scroll-margin-inline-start: -3.5rem !important;
}

.-tw-scroll-ms-16 {
  scroll-margin-inline-start: -4rem !important;
}

.-tw-scroll-ms-2 {
  scroll-margin-inline-start: -0.5rem !important;
}

.-tw-scroll-ms-2\.5 {
  scroll-margin-inline-start: -0.625rem !important;
}

.-tw-scroll-ms-20 {
  scroll-margin-inline-start: -5rem !important;
}

.-tw-scroll-ms-24 {
  scroll-margin-inline-start: -6rem !important;
}

.-tw-scroll-ms-28 {
  scroll-margin-inline-start: -7rem !important;
}

.-tw-scroll-ms-3 {
  scroll-margin-inline-start: -0.75rem !important;
}

.-tw-scroll-ms-3\.5 {
  scroll-margin-inline-start: -0.875rem !important;
}

.-tw-scroll-ms-32 {
  scroll-margin-inline-start: -8rem !important;
}

.-tw-scroll-ms-36 {
  scroll-margin-inline-start: -9rem !important;
}

.-tw-scroll-ms-4 {
  scroll-margin-inline-start: -1rem !important;
}

.-tw-scroll-ms-40 {
  scroll-margin-inline-start: -10rem !important;
}

.-tw-scroll-ms-44 {
  scroll-margin-inline-start: -11rem !important;
}

.-tw-scroll-ms-48 {
  scroll-margin-inline-start: -12rem !important;
}

.-tw-scroll-ms-5 {
  scroll-margin-inline-start: -1.25rem !important;
}

.-tw-scroll-ms-52 {
  scroll-margin-inline-start: -13rem !important;
}

.-tw-scroll-ms-56 {
  scroll-margin-inline-start: -14rem !important;
}

.-tw-scroll-ms-6 {
  scroll-margin-inline-start: -1.5rem !important;
}

.-tw-scroll-ms-60 {
  scroll-margin-inline-start: -15rem !important;
}

.-tw-scroll-ms-64 {
  scroll-margin-inline-start: -16rem !important;
}

.-tw-scroll-ms-7 {
  scroll-margin-inline-start: -1.75rem !important;
}

.-tw-scroll-ms-72 {
  scroll-margin-inline-start: -18rem !important;
}

.-tw-scroll-ms-8 {
  scroll-margin-inline-start: -2rem !important;
}

.-tw-scroll-ms-80 {
  scroll-margin-inline-start: -20rem !important;
}

.-tw-scroll-ms-9 {
  scroll-margin-inline-start: -2.25rem !important;
}

.-tw-scroll-ms-96 {
  scroll-margin-inline-start: -24rem !important;
}

.-tw-scroll-ms-px {
  scroll-margin-inline-start: -1px !important;
}

.-tw-scroll-mt-0 {
  scroll-margin-top: -0px !important;
}

.-tw-scroll-mt-0\.5 {
  scroll-margin-top: -0.125rem !important;
}

.-tw-scroll-mt-1 {
  scroll-margin-top: -0.25rem !important;
}

.-tw-scroll-mt-1\.5 {
  scroll-margin-top: -0.375rem !important;
}

.-tw-scroll-mt-10 {
  scroll-margin-top: -2.5rem !important;
}

.-tw-scroll-mt-11 {
  scroll-margin-top: -2.75rem !important;
}

.-tw-scroll-mt-12 {
  scroll-margin-top: -3rem !important;
}

.-tw-scroll-mt-14 {
  scroll-margin-top: -3.5rem !important;
}

.-tw-scroll-mt-16 {
  scroll-margin-top: -4rem !important;
}

.-tw-scroll-mt-2 {
  scroll-margin-top: -0.5rem !important;
}

.-tw-scroll-mt-2\.5 {
  scroll-margin-top: -0.625rem !important;
}

.-tw-scroll-mt-20 {
  scroll-margin-top: -5rem !important;
}

.-tw-scroll-mt-24 {
  scroll-margin-top: -6rem !important;
}

.-tw-scroll-mt-28 {
  scroll-margin-top: -7rem !important;
}

.-tw-scroll-mt-3 {
  scroll-margin-top: -0.75rem !important;
}

.-tw-scroll-mt-3\.5 {
  scroll-margin-top: -0.875rem !important;
}

.-tw-scroll-mt-32 {
  scroll-margin-top: -8rem !important;
}

.-tw-scroll-mt-36 {
  scroll-margin-top: -9rem !important;
}

.-tw-scroll-mt-4 {
  scroll-margin-top: -1rem !important;
}

.-tw-scroll-mt-40 {
  scroll-margin-top: -10rem !important;
}

.-tw-scroll-mt-44 {
  scroll-margin-top: -11rem !important;
}

.-tw-scroll-mt-48 {
  scroll-margin-top: -12rem !important;
}

.-tw-scroll-mt-5 {
  scroll-margin-top: -1.25rem !important;
}

.-tw-scroll-mt-52 {
  scroll-margin-top: -13rem !important;
}

.-tw-scroll-mt-56 {
  scroll-margin-top: -14rem !important;
}

.-tw-scroll-mt-6 {
  scroll-margin-top: -1.5rem !important;
}

.-tw-scroll-mt-60 {
  scroll-margin-top: -15rem !important;
}

.-tw-scroll-mt-64 {
  scroll-margin-top: -16rem !important;
}

.-tw-scroll-mt-7 {
  scroll-margin-top: -1.75rem !important;
}

.-tw-scroll-mt-72 {
  scroll-margin-top: -18rem !important;
}

.-tw-scroll-mt-8 {
  scroll-margin-top: -2rem !important;
}

.-tw-scroll-mt-80 {
  scroll-margin-top: -20rem !important;
}

.-tw-scroll-mt-9 {
  scroll-margin-top: -2.25rem !important;
}

.-tw-scroll-mt-96 {
  scroll-margin-top: -24rem !important;
}

.-tw-scroll-mt-px {
  scroll-margin-top: -1px !important;
}

.tw--scroll-mb-0 {
  scroll-margin-bottom: -0px !important;
}

.tw--scroll-mb-0\.5 {
  scroll-margin-bottom: -0.125rem !important;
}

.tw--scroll-mb-1 {
  scroll-margin-bottom: -0.25rem !important;
}

.tw--scroll-mb-1\.5 {
  scroll-margin-bottom: -0.375rem !important;
}

.tw--scroll-mb-10 {
  scroll-margin-bottom: -2.5rem !important;
}

.tw--scroll-mb-11 {
  scroll-margin-bottom: -2.75rem !important;
}

.tw--scroll-mb-12 {
  scroll-margin-bottom: -3rem !important;
}

.tw--scroll-mb-14 {
  scroll-margin-bottom: -3.5rem !important;
}

.tw--scroll-mb-16 {
  scroll-margin-bottom: -4rem !important;
}

.tw--scroll-mb-2 {
  scroll-margin-bottom: -0.5rem !important;
}

.tw--scroll-mb-2\.5 {
  scroll-margin-bottom: -0.625rem !important;
}

.tw--scroll-mb-20 {
  scroll-margin-bottom: -5rem !important;
}

.tw--scroll-mb-24 {
  scroll-margin-bottom: -6rem !important;
}

.tw--scroll-mb-28 {
  scroll-margin-bottom: -7rem !important;
}

.tw--scroll-mb-3 {
  scroll-margin-bottom: -0.75rem !important;
}

.tw--scroll-mb-3\.5 {
  scroll-margin-bottom: -0.875rem !important;
}

.tw--scroll-mb-32 {
  scroll-margin-bottom: -8rem !important;
}

.tw--scroll-mb-36 {
  scroll-margin-bottom: -9rem !important;
}

.tw--scroll-mb-4 {
  scroll-margin-bottom: -1rem !important;
}

.tw--scroll-mb-40 {
  scroll-margin-bottom: -10rem !important;
}

.tw--scroll-mb-44 {
  scroll-margin-bottom: -11rem !important;
}

.tw--scroll-mb-48 {
  scroll-margin-bottom: -12rem !important;
}

.tw--scroll-mb-5 {
  scroll-margin-bottom: -1.25rem !important;
}

.tw--scroll-mb-52 {
  scroll-margin-bottom: -13rem !important;
}

.tw--scroll-mb-56 {
  scroll-margin-bottom: -14rem !important;
}

.tw--scroll-mb-6 {
  scroll-margin-bottom: -1.5rem !important;
}

.tw--scroll-mb-60 {
  scroll-margin-bottom: -15rem !important;
}

.tw--scroll-mb-64 {
  scroll-margin-bottom: -16rem !important;
}

.tw--scroll-mb-7 {
  scroll-margin-bottom: -1.75rem !important;
}

.tw--scroll-mb-72 {
  scroll-margin-bottom: -18rem !important;
}

.tw--scroll-mb-8 {
  scroll-margin-bottom: -2rem !important;
}

.tw--scroll-mb-80 {
  scroll-margin-bottom: -20rem !important;
}

.tw--scroll-mb-9 {
  scroll-margin-bottom: -2.25rem !important;
}

.tw--scroll-mb-96 {
  scroll-margin-bottom: -24rem !important;
}

.tw--scroll-mb-px {
  scroll-margin-bottom: -1px !important;
}

.tw--scroll-me-0 {
  scroll-margin-inline-end: -0px !important;
}

.tw--scroll-me-0\.5 {
  scroll-margin-inline-end: -0.125rem !important;
}

.tw--scroll-me-1 {
  scroll-margin-inline-end: -0.25rem !important;
}

.tw--scroll-me-1\.5 {
  scroll-margin-inline-end: -0.375rem !important;
}

.tw--scroll-me-10 {
  scroll-margin-inline-end: -2.5rem !important;
}

.tw--scroll-me-11 {
  scroll-margin-inline-end: -2.75rem !important;
}

.tw--scroll-me-12 {
  scroll-margin-inline-end: -3rem !important;
}

.tw--scroll-me-14 {
  scroll-margin-inline-end: -3.5rem !important;
}

.tw--scroll-me-16 {
  scroll-margin-inline-end: -4rem !important;
}

.tw--scroll-me-2 {
  scroll-margin-inline-end: -0.5rem !important;
}

.tw--scroll-me-2\.5 {
  scroll-margin-inline-end: -0.625rem !important;
}

.tw--scroll-me-20 {
  scroll-margin-inline-end: -5rem !important;
}

.tw--scroll-me-24 {
  scroll-margin-inline-end: -6rem !important;
}

.tw--scroll-me-28 {
  scroll-margin-inline-end: -7rem !important;
}

.tw--scroll-me-3 {
  scroll-margin-inline-end: -0.75rem !important;
}

.tw--scroll-me-3\.5 {
  scroll-margin-inline-end: -0.875rem !important;
}

.tw--scroll-me-32 {
  scroll-margin-inline-end: -8rem !important;
}

.tw--scroll-me-36 {
  scroll-margin-inline-end: -9rem !important;
}

.tw--scroll-me-4 {
  scroll-margin-inline-end: -1rem !important;
}

.tw--scroll-me-40 {
  scroll-margin-inline-end: -10rem !important;
}

.tw--scroll-me-44 {
  scroll-margin-inline-end: -11rem !important;
}

.tw--scroll-me-48 {
  scroll-margin-inline-end: -12rem !important;
}

.tw--scroll-me-5 {
  scroll-margin-inline-end: -1.25rem !important;
}

.tw--scroll-me-52 {
  scroll-margin-inline-end: -13rem !important;
}

.tw--scroll-me-56 {
  scroll-margin-inline-end: -14rem !important;
}

.tw--scroll-me-6 {
  scroll-margin-inline-end: -1.5rem !important;
}

.tw--scroll-me-60 {
  scroll-margin-inline-end: -15rem !important;
}

.tw--scroll-me-64 {
  scroll-margin-inline-end: -16rem !important;
}

.tw--scroll-me-7 {
  scroll-margin-inline-end: -1.75rem !important;
}

.tw--scroll-me-72 {
  scroll-margin-inline-end: -18rem !important;
}

.tw--scroll-me-8 {
  scroll-margin-inline-end: -2rem !important;
}

.tw--scroll-me-80 {
  scroll-margin-inline-end: -20rem !important;
}

.tw--scroll-me-9 {
  scroll-margin-inline-end: -2.25rem !important;
}

.tw--scroll-me-96 {
  scroll-margin-inline-end: -24rem !important;
}

.tw--scroll-me-px {
  scroll-margin-inline-end: -1px !important;
}

.tw--scroll-ml-0 {
  scroll-margin-left: -0px !important;
}

.tw--scroll-ml-0\.5 {
  scroll-margin-left: -0.125rem !important;
}

.tw--scroll-ml-1 {
  scroll-margin-left: -0.25rem !important;
}

.tw--scroll-ml-1\.5 {
  scroll-margin-left: -0.375rem !important;
}

.tw--scroll-ml-10 {
  scroll-margin-left: -2.5rem !important;
}

.tw--scroll-ml-11 {
  scroll-margin-left: -2.75rem !important;
}

.tw--scroll-ml-12 {
  scroll-margin-left: -3rem !important;
}

.tw--scroll-ml-14 {
  scroll-margin-left: -3.5rem !important;
}

.tw--scroll-ml-16 {
  scroll-margin-left: -4rem !important;
}

.tw--scroll-ml-2 {
  scroll-margin-left: -0.5rem !important;
}

.tw--scroll-ml-2\.5 {
  scroll-margin-left: -0.625rem !important;
}

.tw--scroll-ml-20 {
  scroll-margin-left: -5rem !important;
}

.tw--scroll-ml-24 {
  scroll-margin-left: -6rem !important;
}

.tw--scroll-ml-28 {
  scroll-margin-left: -7rem !important;
}

.tw--scroll-ml-3 {
  scroll-margin-left: -0.75rem !important;
}

.tw--scroll-ml-3\.5 {
  scroll-margin-left: -0.875rem !important;
}

.tw--scroll-ml-32 {
  scroll-margin-left: -8rem !important;
}

.tw--scroll-ml-36 {
  scroll-margin-left: -9rem !important;
}

.tw--scroll-ml-4 {
  scroll-margin-left: -1rem !important;
}

.tw--scroll-ml-40 {
  scroll-margin-left: -10rem !important;
}

.tw--scroll-ml-44 {
  scroll-margin-left: -11rem !important;
}

.tw--scroll-ml-48 {
  scroll-margin-left: -12rem !important;
}

.tw--scroll-ml-5 {
  scroll-margin-left: -1.25rem !important;
}

.tw--scroll-ml-52 {
  scroll-margin-left: -13rem !important;
}

.tw--scroll-ml-56 {
  scroll-margin-left: -14rem !important;
}

.tw--scroll-ml-6 {
  scroll-margin-left: -1.5rem !important;
}

.tw--scroll-ml-60 {
  scroll-margin-left: -15rem !important;
}

.tw--scroll-ml-64 {
  scroll-margin-left: -16rem !important;
}

.tw--scroll-ml-7 {
  scroll-margin-left: -1.75rem !important;
}

.tw--scroll-ml-72 {
  scroll-margin-left: -18rem !important;
}

.tw--scroll-ml-8 {
  scroll-margin-left: -2rem !important;
}

.tw--scroll-ml-80 {
  scroll-margin-left: -20rem !important;
}

.tw--scroll-ml-9 {
  scroll-margin-left: -2.25rem !important;
}

.tw--scroll-ml-96 {
  scroll-margin-left: -24rem !important;
}

.tw--scroll-ml-px {
  scroll-margin-left: -1px !important;
}

.tw--scroll-mr-0 {
  scroll-margin-right: -0px !important;
}

.tw--scroll-mr-0\.5 {
  scroll-margin-right: -0.125rem !important;
}

.tw--scroll-mr-1 {
  scroll-margin-right: -0.25rem !important;
}

.tw--scroll-mr-1\.5 {
  scroll-margin-right: -0.375rem !important;
}

.tw--scroll-mr-10 {
  scroll-margin-right: -2.5rem !important;
}

.tw--scroll-mr-11 {
  scroll-margin-right: -2.75rem !important;
}

.tw--scroll-mr-12 {
  scroll-margin-right: -3rem !important;
}

.tw--scroll-mr-14 {
  scroll-margin-right: -3.5rem !important;
}

.tw--scroll-mr-16 {
  scroll-margin-right: -4rem !important;
}

.tw--scroll-mr-2 {
  scroll-margin-right: -0.5rem !important;
}

.tw--scroll-mr-2\.5 {
  scroll-margin-right: -0.625rem !important;
}

.tw--scroll-mr-20 {
  scroll-margin-right: -5rem !important;
}

.tw--scroll-mr-24 {
  scroll-margin-right: -6rem !important;
}

.tw--scroll-mr-28 {
  scroll-margin-right: -7rem !important;
}

.tw--scroll-mr-3 {
  scroll-margin-right: -0.75rem !important;
}

.tw--scroll-mr-3\.5 {
  scroll-margin-right: -0.875rem !important;
}

.tw--scroll-mr-32 {
  scroll-margin-right: -8rem !important;
}

.tw--scroll-mr-36 {
  scroll-margin-right: -9rem !important;
}

.tw--scroll-mr-4 {
  scroll-margin-right: -1rem !important;
}

.tw--scroll-mr-40 {
  scroll-margin-right: -10rem !important;
}

.tw--scroll-mr-44 {
  scroll-margin-right: -11rem !important;
}

.tw--scroll-mr-48 {
  scroll-margin-right: -12rem !important;
}

.tw--scroll-mr-5 {
  scroll-margin-right: -1.25rem !important;
}

.tw--scroll-mr-52 {
  scroll-margin-right: -13rem !important;
}

.tw--scroll-mr-56 {
  scroll-margin-right: -14rem !important;
}

.tw--scroll-mr-6 {
  scroll-margin-right: -1.5rem !important;
}

.tw--scroll-mr-60 {
  scroll-margin-right: -15rem !important;
}

.tw--scroll-mr-64 {
  scroll-margin-right: -16rem !important;
}

.tw--scroll-mr-7 {
  scroll-margin-right: -1.75rem !important;
}

.tw--scroll-mr-72 {
  scroll-margin-right: -18rem !important;
}

.tw--scroll-mr-8 {
  scroll-margin-right: -2rem !important;
}

.tw--scroll-mr-80 {
  scroll-margin-right: -20rem !important;
}

.tw--scroll-mr-9 {
  scroll-margin-right: -2.25rem !important;
}

.tw--scroll-mr-96 {
  scroll-margin-right: -24rem !important;
}

.tw--scroll-mr-px {
  scroll-margin-right: -1px !important;
}

.tw--scroll-ms-0 {
  scroll-margin-inline-start: -0px !important;
}

.tw--scroll-ms-0\.5 {
  scroll-margin-inline-start: -0.125rem !important;
}

.tw--scroll-ms-1 {
  scroll-margin-inline-start: -0.25rem !important;
}

.tw--scroll-ms-1\.5 {
  scroll-margin-inline-start: -0.375rem !important;
}

.tw--scroll-ms-10 {
  scroll-margin-inline-start: -2.5rem !important;
}

.tw--scroll-ms-11 {
  scroll-margin-inline-start: -2.75rem !important;
}

.tw--scroll-ms-12 {
  scroll-margin-inline-start: -3rem !important;
}

.tw--scroll-ms-14 {
  scroll-margin-inline-start: -3.5rem !important;
}

.tw--scroll-ms-16 {
  scroll-margin-inline-start: -4rem !important;
}

.tw--scroll-ms-2 {
  scroll-margin-inline-start: -0.5rem !important;
}

.tw--scroll-ms-2\.5 {
  scroll-margin-inline-start: -0.625rem !important;
}

.tw--scroll-ms-20 {
  scroll-margin-inline-start: -5rem !important;
}

.tw--scroll-ms-24 {
  scroll-margin-inline-start: -6rem !important;
}

.tw--scroll-ms-28 {
  scroll-margin-inline-start: -7rem !important;
}

.tw--scroll-ms-3 {
  scroll-margin-inline-start: -0.75rem !important;
}

.tw--scroll-ms-3\.5 {
  scroll-margin-inline-start: -0.875rem !important;
}

.tw--scroll-ms-32 {
  scroll-margin-inline-start: -8rem !important;
}

.tw--scroll-ms-36 {
  scroll-margin-inline-start: -9rem !important;
}

.tw--scroll-ms-4 {
  scroll-margin-inline-start: -1rem !important;
}

.tw--scroll-ms-40 {
  scroll-margin-inline-start: -10rem !important;
}

.tw--scroll-ms-44 {
  scroll-margin-inline-start: -11rem !important;
}

.tw--scroll-ms-48 {
  scroll-margin-inline-start: -12rem !important;
}

.tw--scroll-ms-5 {
  scroll-margin-inline-start: -1.25rem !important;
}

.tw--scroll-ms-52 {
  scroll-margin-inline-start: -13rem !important;
}

.tw--scroll-ms-56 {
  scroll-margin-inline-start: -14rem !important;
}

.tw--scroll-ms-6 {
  scroll-margin-inline-start: -1.5rem !important;
}

.tw--scroll-ms-60 {
  scroll-margin-inline-start: -15rem !important;
}

.tw--scroll-ms-64 {
  scroll-margin-inline-start: -16rem !important;
}

.tw--scroll-ms-7 {
  scroll-margin-inline-start: -1.75rem !important;
}

.tw--scroll-ms-72 {
  scroll-margin-inline-start: -18rem !important;
}

.tw--scroll-ms-8 {
  scroll-margin-inline-start: -2rem !important;
}

.tw--scroll-ms-80 {
  scroll-margin-inline-start: -20rem !important;
}

.tw--scroll-ms-9 {
  scroll-margin-inline-start: -2.25rem !important;
}

.tw--scroll-ms-96 {
  scroll-margin-inline-start: -24rem !important;
}

.tw--scroll-ms-px {
  scroll-margin-inline-start: -1px !important;
}

.tw--scroll-mt-0 {
  scroll-margin-top: -0px !important;
}

.tw--scroll-mt-0\.5 {
  scroll-margin-top: -0.125rem !important;
}

.tw--scroll-mt-1 {
  scroll-margin-top: -0.25rem !important;
}

.tw--scroll-mt-1\.5 {
  scroll-margin-top: -0.375rem !important;
}

.tw--scroll-mt-10 {
  scroll-margin-top: -2.5rem !important;
}

.tw--scroll-mt-11 {
  scroll-margin-top: -2.75rem !important;
}

.tw--scroll-mt-12 {
  scroll-margin-top: -3rem !important;
}

.tw--scroll-mt-14 {
  scroll-margin-top: -3.5rem !important;
}

.tw--scroll-mt-16 {
  scroll-margin-top: -4rem !important;
}

.tw--scroll-mt-2 {
  scroll-margin-top: -0.5rem !important;
}

.tw--scroll-mt-2\.5 {
  scroll-margin-top: -0.625rem !important;
}

.tw--scroll-mt-20 {
  scroll-margin-top: -5rem !important;
}

.tw--scroll-mt-24 {
  scroll-margin-top: -6rem !important;
}

.tw--scroll-mt-28 {
  scroll-margin-top: -7rem !important;
}

.tw--scroll-mt-3 {
  scroll-margin-top: -0.75rem !important;
}

.tw--scroll-mt-3\.5 {
  scroll-margin-top: -0.875rem !important;
}

.tw--scroll-mt-32 {
  scroll-margin-top: -8rem !important;
}

.tw--scroll-mt-36 {
  scroll-margin-top: -9rem !important;
}

.tw--scroll-mt-4 {
  scroll-margin-top: -1rem !important;
}

.tw--scroll-mt-40 {
  scroll-margin-top: -10rem !important;
}

.tw--scroll-mt-44 {
  scroll-margin-top: -11rem !important;
}

.tw--scroll-mt-48 {
  scroll-margin-top: -12rem !important;
}

.tw--scroll-mt-5 {
  scroll-margin-top: -1.25rem !important;
}

.tw--scroll-mt-52 {
  scroll-margin-top: -13rem !important;
}

.tw--scroll-mt-56 {
  scroll-margin-top: -14rem !important;
}

.tw--scroll-mt-6 {
  scroll-margin-top: -1.5rem !important;
}

.tw--scroll-mt-60 {
  scroll-margin-top: -15rem !important;
}

.tw--scroll-mt-64 {
  scroll-margin-top: -16rem !important;
}

.tw--scroll-mt-7 {
  scroll-margin-top: -1.75rem !important;
}

.tw--scroll-mt-72 {
  scroll-margin-top: -18rem !important;
}

.tw--scroll-mt-8 {
  scroll-margin-top: -2rem !important;
}

.tw--scroll-mt-80 {
  scroll-margin-top: -20rem !important;
}

.tw--scroll-mt-9 {
  scroll-margin-top: -2.25rem !important;
}

.tw--scroll-mt-96 {
  scroll-margin-top: -24rem !important;
}

.tw--scroll-mt-px {
  scroll-margin-top: -1px !important;
}

.tw-scroll-mb-0 {
  scroll-margin-bottom: 0px !important;
}

.tw-scroll-mb-0\.5 {
  scroll-margin-bottom: 0.125rem !important;
}

.tw-scroll-mb-1 {
  scroll-margin-bottom: 0.25rem !important;
}

.tw-scroll-mb-1\.5 {
  scroll-margin-bottom: 0.375rem !important;
}

.tw-scroll-mb-10 {
  scroll-margin-bottom: 2.5rem !important;
}

.tw-scroll-mb-11 {
  scroll-margin-bottom: 2.75rem !important;
}

.tw-scroll-mb-12 {
  scroll-margin-bottom: 3rem !important;
}

.tw-scroll-mb-14 {
  scroll-margin-bottom: 3.5rem !important;
}

.tw-scroll-mb-16 {
  scroll-margin-bottom: 4rem !important;
}

.tw-scroll-mb-2 {
  scroll-margin-bottom: 0.5rem !important;
}

.tw-scroll-mb-2\.5 {
  scroll-margin-bottom: 0.625rem !important;
}

.tw-scroll-mb-20 {
  scroll-margin-bottom: 5rem !important;
}

.tw-scroll-mb-24 {
  scroll-margin-bottom: 6rem !important;
}

.tw-scroll-mb-28 {
  scroll-margin-bottom: 7rem !important;
}

.tw-scroll-mb-3 {
  scroll-margin-bottom: 0.75rem !important;
}

.tw-scroll-mb-3\.5 {
  scroll-margin-bottom: 0.875rem !important;
}

.tw-scroll-mb-32 {
  scroll-margin-bottom: 8rem !important;
}

.tw-scroll-mb-36 {
  scroll-margin-bottom: 9rem !important;
}

.tw-scroll-mb-4 {
  scroll-margin-bottom: 1rem !important;
}

.tw-scroll-mb-40 {
  scroll-margin-bottom: 10rem !important;
}

.tw-scroll-mb-44 {
  scroll-margin-bottom: 11rem !important;
}

.tw-scroll-mb-48 {
  scroll-margin-bottom: 12rem !important;
}

.tw-scroll-mb-5 {
  scroll-margin-bottom: 1.25rem !important;
}

.tw-scroll-mb-52 {
  scroll-margin-bottom: 13rem !important;
}

.tw-scroll-mb-56 {
  scroll-margin-bottom: 14rem !important;
}

.tw-scroll-mb-6 {
  scroll-margin-bottom: 1.5rem !important;
}

.tw-scroll-mb-60 {
  scroll-margin-bottom: 15rem !important;
}

.tw-scroll-mb-64 {
  scroll-margin-bottom: 16rem !important;
}

.tw-scroll-mb-7 {
  scroll-margin-bottom: 1.75rem !important;
}

.tw-scroll-mb-72 {
  scroll-margin-bottom: 18rem !important;
}

.tw-scroll-mb-8 {
  scroll-margin-bottom: 2rem !important;
}

.tw-scroll-mb-80 {
  scroll-margin-bottom: 20rem !important;
}

.tw-scroll-mb-9 {
  scroll-margin-bottom: 2.25rem !important;
}

.tw-scroll-mb-96 {
  scroll-margin-bottom: 24rem !important;
}

.tw-scroll-mb-px {
  scroll-margin-bottom: 1px !important;
}

.tw-scroll-me-0 {
  scroll-margin-inline-end: 0px !important;
}

.tw-scroll-me-0\.5 {
  scroll-margin-inline-end: 0.125rem !important;
}

.tw-scroll-me-1 {
  scroll-margin-inline-end: 0.25rem !important;
}

.tw-scroll-me-1\.5 {
  scroll-margin-inline-end: 0.375rem !important;
}

.tw-scroll-me-10 {
  scroll-margin-inline-end: 2.5rem !important;
}

.tw-scroll-me-11 {
  scroll-margin-inline-end: 2.75rem !important;
}

.tw-scroll-me-12 {
  scroll-margin-inline-end: 3rem !important;
}

.tw-scroll-me-14 {
  scroll-margin-inline-end: 3.5rem !important;
}

.tw-scroll-me-16 {
  scroll-margin-inline-end: 4rem !important;
}

.tw-scroll-me-2 {
  scroll-margin-inline-end: 0.5rem !important;
}

.tw-scroll-me-2\.5 {
  scroll-margin-inline-end: 0.625rem !important;
}

.tw-scroll-me-20 {
  scroll-margin-inline-end: 5rem !important;
}

.tw-scroll-me-24 {
  scroll-margin-inline-end: 6rem !important;
}

.tw-scroll-me-28 {
  scroll-margin-inline-end: 7rem !important;
}

.tw-scroll-me-3 {
  scroll-margin-inline-end: 0.75rem !important;
}

.tw-scroll-me-3\.5 {
  scroll-margin-inline-end: 0.875rem !important;
}

.tw-scroll-me-32 {
  scroll-margin-inline-end: 8rem !important;
}

.tw-scroll-me-36 {
  scroll-margin-inline-end: 9rem !important;
}

.tw-scroll-me-4 {
  scroll-margin-inline-end: 1rem !important;
}

.tw-scroll-me-40 {
  scroll-margin-inline-end: 10rem !important;
}

.tw-scroll-me-44 {
  scroll-margin-inline-end: 11rem !important;
}

.tw-scroll-me-48 {
  scroll-margin-inline-end: 12rem !important;
}

.tw-scroll-me-5 {
  scroll-margin-inline-end: 1.25rem !important;
}

.tw-scroll-me-52 {
  scroll-margin-inline-end: 13rem !important;
}

.tw-scroll-me-56 {
  scroll-margin-inline-end: 14rem !important;
}

.tw-scroll-me-6 {
  scroll-margin-inline-end: 1.5rem !important;
}

.tw-scroll-me-60 {
  scroll-margin-inline-end: 15rem !important;
}

.tw-scroll-me-64 {
  scroll-margin-inline-end: 16rem !important;
}

.tw-scroll-me-7 {
  scroll-margin-inline-end: 1.75rem !important;
}

.tw-scroll-me-72 {
  scroll-margin-inline-end: 18rem !important;
}

.tw-scroll-me-8 {
  scroll-margin-inline-end: 2rem !important;
}

.tw-scroll-me-80 {
  scroll-margin-inline-end: 20rem !important;
}

.tw-scroll-me-9 {
  scroll-margin-inline-end: 2.25rem !important;
}

.tw-scroll-me-96 {
  scroll-margin-inline-end: 24rem !important;
}

.tw-scroll-me-px {
  scroll-margin-inline-end: 1px !important;
}

.tw-scroll-ml-0 {
  scroll-margin-left: 0px !important;
}

.tw-scroll-ml-0\.5 {
  scroll-margin-left: 0.125rem !important;
}

.tw-scroll-ml-1 {
  scroll-margin-left: 0.25rem !important;
}

.tw-scroll-ml-1\.5 {
  scroll-margin-left: 0.375rem !important;
}

.tw-scroll-ml-10 {
  scroll-margin-left: 2.5rem !important;
}

.tw-scroll-ml-11 {
  scroll-margin-left: 2.75rem !important;
}

.tw-scroll-ml-12 {
  scroll-margin-left: 3rem !important;
}

.tw-scroll-ml-14 {
  scroll-margin-left: 3.5rem !important;
}

.tw-scroll-ml-16 {
  scroll-margin-left: 4rem !important;
}

.tw-scroll-ml-2 {
  scroll-margin-left: 0.5rem !important;
}

.tw-scroll-ml-2\.5 {
  scroll-margin-left: 0.625rem !important;
}

.tw-scroll-ml-20 {
  scroll-margin-left: 5rem !important;
}

.tw-scroll-ml-24 {
  scroll-margin-left: 6rem !important;
}

.tw-scroll-ml-28 {
  scroll-margin-left: 7rem !important;
}

.tw-scroll-ml-3 {
  scroll-margin-left: 0.75rem !important;
}

.tw-scroll-ml-3\.5 {
  scroll-margin-left: 0.875rem !important;
}

.tw-scroll-ml-32 {
  scroll-margin-left: 8rem !important;
}

.tw-scroll-ml-36 {
  scroll-margin-left: 9rem !important;
}

.tw-scroll-ml-4 {
  scroll-margin-left: 1rem !important;
}

.tw-scroll-ml-40 {
  scroll-margin-left: 10rem !important;
}

.tw-scroll-ml-44 {
  scroll-margin-left: 11rem !important;
}

.tw-scroll-ml-48 {
  scroll-margin-left: 12rem !important;
}

.tw-scroll-ml-5 {
  scroll-margin-left: 1.25rem !important;
}

.tw-scroll-ml-52 {
  scroll-margin-left: 13rem !important;
}

.tw-scroll-ml-56 {
  scroll-margin-left: 14rem !important;
}

.tw-scroll-ml-6 {
  scroll-margin-left: 1.5rem !important;
}

.tw-scroll-ml-60 {
  scroll-margin-left: 15rem !important;
}

.tw-scroll-ml-64 {
  scroll-margin-left: 16rem !important;
}

.tw-scroll-ml-7 {
  scroll-margin-left: 1.75rem !important;
}

.tw-scroll-ml-72 {
  scroll-margin-left: 18rem !important;
}

.tw-scroll-ml-8 {
  scroll-margin-left: 2rem !important;
}

.tw-scroll-ml-80 {
  scroll-margin-left: 20rem !important;
}

.tw-scroll-ml-9 {
  scroll-margin-left: 2.25rem !important;
}

.tw-scroll-ml-96 {
  scroll-margin-left: 24rem !important;
}

.tw-scroll-ml-px {
  scroll-margin-left: 1px !important;
}

.tw-scroll-mr-0 {
  scroll-margin-right: 0px !important;
}

.tw-scroll-mr-0\.5 {
  scroll-margin-right: 0.125rem !important;
}

.tw-scroll-mr-1 {
  scroll-margin-right: 0.25rem !important;
}

.tw-scroll-mr-1\.5 {
  scroll-margin-right: 0.375rem !important;
}

.tw-scroll-mr-10 {
  scroll-margin-right: 2.5rem !important;
}

.tw-scroll-mr-11 {
  scroll-margin-right: 2.75rem !important;
}

.tw-scroll-mr-12 {
  scroll-margin-right: 3rem !important;
}

.tw-scroll-mr-14 {
  scroll-margin-right: 3.5rem !important;
}

.tw-scroll-mr-16 {
  scroll-margin-right: 4rem !important;
}

.tw-scroll-mr-2 {
  scroll-margin-right: 0.5rem !important;
}

.tw-scroll-mr-2\.5 {
  scroll-margin-right: 0.625rem !important;
}

.tw-scroll-mr-20 {
  scroll-margin-right: 5rem !important;
}

.tw-scroll-mr-24 {
  scroll-margin-right: 6rem !important;
}

.tw-scroll-mr-28 {
  scroll-margin-right: 7rem !important;
}

.tw-scroll-mr-3 {
  scroll-margin-right: 0.75rem !important;
}

.tw-scroll-mr-3\.5 {
  scroll-margin-right: 0.875rem !important;
}

.tw-scroll-mr-32 {
  scroll-margin-right: 8rem !important;
}

.tw-scroll-mr-36 {
  scroll-margin-right: 9rem !important;
}

.tw-scroll-mr-4 {
  scroll-margin-right: 1rem !important;
}

.tw-scroll-mr-40 {
  scroll-margin-right: 10rem !important;
}

.tw-scroll-mr-44 {
  scroll-margin-right: 11rem !important;
}

.tw-scroll-mr-48 {
  scroll-margin-right: 12rem !important;
}

.tw-scroll-mr-5 {
  scroll-margin-right: 1.25rem !important;
}

.tw-scroll-mr-52 {
  scroll-margin-right: 13rem !important;
}

.tw-scroll-mr-56 {
  scroll-margin-right: 14rem !important;
}

.tw-scroll-mr-6 {
  scroll-margin-right: 1.5rem !important;
}

.tw-scroll-mr-60 {
  scroll-margin-right: 15rem !important;
}

.tw-scroll-mr-64 {
  scroll-margin-right: 16rem !important;
}

.tw-scroll-mr-7 {
  scroll-margin-right: 1.75rem !important;
}

.tw-scroll-mr-72 {
  scroll-margin-right: 18rem !important;
}

.tw-scroll-mr-8 {
  scroll-margin-right: 2rem !important;
}

.tw-scroll-mr-80 {
  scroll-margin-right: 20rem !important;
}

.tw-scroll-mr-9 {
  scroll-margin-right: 2.25rem !important;
}

.tw-scroll-mr-96 {
  scroll-margin-right: 24rem !important;
}

.tw-scroll-mr-px {
  scroll-margin-right: 1px !important;
}

.tw-scroll-ms-0 {
  scroll-margin-inline-start: 0px !important;
}

.tw-scroll-ms-0\.5 {
  scroll-margin-inline-start: 0.125rem !important;
}

.tw-scroll-ms-1 {
  scroll-margin-inline-start: 0.25rem !important;
}

.tw-scroll-ms-1\.5 {
  scroll-margin-inline-start: 0.375rem !important;
}

.tw-scroll-ms-10 {
  scroll-margin-inline-start: 2.5rem !important;
}

.tw-scroll-ms-11 {
  scroll-margin-inline-start: 2.75rem !important;
}

.tw-scroll-ms-12 {
  scroll-margin-inline-start: 3rem !important;
}

.tw-scroll-ms-14 {
  scroll-margin-inline-start: 3.5rem !important;
}

.tw-scroll-ms-16 {
  scroll-margin-inline-start: 4rem !important;
}

.tw-scroll-ms-2 {
  scroll-margin-inline-start: 0.5rem !important;
}

.tw-scroll-ms-2\.5 {
  scroll-margin-inline-start: 0.625rem !important;
}

.tw-scroll-ms-20 {
  scroll-margin-inline-start: 5rem !important;
}

.tw-scroll-ms-24 {
  scroll-margin-inline-start: 6rem !important;
}

.tw-scroll-ms-28 {
  scroll-margin-inline-start: 7rem !important;
}

.tw-scroll-ms-3 {
  scroll-margin-inline-start: 0.75rem !important;
}

.tw-scroll-ms-3\.5 {
  scroll-margin-inline-start: 0.875rem !important;
}

.tw-scroll-ms-32 {
  scroll-margin-inline-start: 8rem !important;
}

.tw-scroll-ms-36 {
  scroll-margin-inline-start: 9rem !important;
}

.tw-scroll-ms-4 {
  scroll-margin-inline-start: 1rem !important;
}

.tw-scroll-ms-40 {
  scroll-margin-inline-start: 10rem !important;
}

.tw-scroll-ms-44 {
  scroll-margin-inline-start: 11rem !important;
}

.tw-scroll-ms-48 {
  scroll-margin-inline-start: 12rem !important;
}

.tw-scroll-ms-5 {
  scroll-margin-inline-start: 1.25rem !important;
}

.tw-scroll-ms-52 {
  scroll-margin-inline-start: 13rem !important;
}

.tw-scroll-ms-56 {
  scroll-margin-inline-start: 14rem !important;
}

.tw-scroll-ms-6 {
  scroll-margin-inline-start: 1.5rem !important;
}

.tw-scroll-ms-60 {
  scroll-margin-inline-start: 15rem !important;
}

.tw-scroll-ms-64 {
  scroll-margin-inline-start: 16rem !important;
}

.tw-scroll-ms-7 {
  scroll-margin-inline-start: 1.75rem !important;
}

.tw-scroll-ms-72 {
  scroll-margin-inline-start: 18rem !important;
}

.tw-scroll-ms-8 {
  scroll-margin-inline-start: 2rem !important;
}

.tw-scroll-ms-80 {
  scroll-margin-inline-start: 20rem !important;
}

.tw-scroll-ms-9 {
  scroll-margin-inline-start: 2.25rem !important;
}

.tw-scroll-ms-96 {
  scroll-margin-inline-start: 24rem !important;
}

.tw-scroll-ms-px {
  scroll-margin-inline-start: 1px !important;
}

.tw-scroll-mt-0 {
  scroll-margin-top: 0px !important;
}

.tw-scroll-mt-0\.5 {
  scroll-margin-top: 0.125rem !important;
}

.tw-scroll-mt-1 {
  scroll-margin-top: 0.25rem !important;
}

.tw-scroll-mt-1\.5 {
  scroll-margin-top: 0.375rem !important;
}

.tw-scroll-mt-10 {
  scroll-margin-top: 2.5rem !important;
}

.tw-scroll-mt-11 {
  scroll-margin-top: 2.75rem !important;
}

.tw-scroll-mt-12 {
  scroll-margin-top: 3rem !important;
}

.tw-scroll-mt-14 {
  scroll-margin-top: 3.5rem !important;
}

.tw-scroll-mt-16 {
  scroll-margin-top: 4rem !important;
}

.tw-scroll-mt-2 {
  scroll-margin-top: 0.5rem !important;
}

.tw-scroll-mt-2\.5 {
  scroll-margin-top: 0.625rem !important;
}

.tw-scroll-mt-20 {
  scroll-margin-top: 5rem !important;
}

.tw-scroll-mt-24 {
  scroll-margin-top: 6rem !important;
}

.tw-scroll-mt-28 {
  scroll-margin-top: 7rem !important;
}

.tw-scroll-mt-3 {
  scroll-margin-top: 0.75rem !important;
}

.tw-scroll-mt-3\.5 {
  scroll-margin-top: 0.875rem !important;
}

.tw-scroll-mt-32 {
  scroll-margin-top: 8rem !important;
}

.tw-scroll-mt-36 {
  scroll-margin-top: 9rem !important;
}

.tw-scroll-mt-4 {
  scroll-margin-top: 1rem !important;
}

.tw-scroll-mt-40 {
  scroll-margin-top: 10rem !important;
}

.tw-scroll-mt-44 {
  scroll-margin-top: 11rem !important;
}

.tw-scroll-mt-48 {
  scroll-margin-top: 12rem !important;
}

.tw-scroll-mt-5 {
  scroll-margin-top: 1.25rem !important;
}

.tw-scroll-mt-52 {
  scroll-margin-top: 13rem !important;
}

.tw-scroll-mt-56 {
  scroll-margin-top: 14rem !important;
}

.tw-scroll-mt-6 {
  scroll-margin-top: 1.5rem !important;
}

.tw-scroll-mt-60 {
  scroll-margin-top: 15rem !important;
}

.tw-scroll-mt-64 {
  scroll-margin-top: 16rem !important;
}

.tw-scroll-mt-7 {
  scroll-margin-top: 1.75rem !important;
}

.tw-scroll-mt-72 {
  scroll-margin-top: 18rem !important;
}

.tw-scroll-mt-8 {
  scroll-margin-top: 2rem !important;
}

.tw-scroll-mt-80 {
  scroll-margin-top: 20rem !important;
}

.tw-scroll-mt-9 {
  scroll-margin-top: 2.25rem !important;
}

.tw-scroll-mt-96 {
  scroll-margin-top: 24rem !important;
}

.tw-scroll-mt-px {
  scroll-margin-top: 1px !important;
}

.tw-scroll-p-0 {
  scroll-padding: 0px !important;
}

.tw-scroll-p-0\.5 {
  scroll-padding: 0.125rem !important;
}

.tw-scroll-p-1 {
  scroll-padding: 0.25rem !important;
}

.tw-scroll-p-1\.5 {
  scroll-padding: 0.375rem !important;
}

.tw-scroll-p-10 {
  scroll-padding: 2.5rem !important;
}

.tw-scroll-p-11 {
  scroll-padding: 2.75rem !important;
}

.tw-scroll-p-12 {
  scroll-padding: 3rem !important;
}

.tw-scroll-p-14 {
  scroll-padding: 3.5rem !important;
}

.tw-scroll-p-16 {
  scroll-padding: 4rem !important;
}

.tw-scroll-p-2 {
  scroll-padding: 0.5rem !important;
}

.tw-scroll-p-2\.5 {
  scroll-padding: 0.625rem !important;
}

.tw-scroll-p-20 {
  scroll-padding: 5rem !important;
}

.tw-scroll-p-24 {
  scroll-padding: 6rem !important;
}

.tw-scroll-p-28 {
  scroll-padding: 7rem !important;
}

.tw-scroll-p-3 {
  scroll-padding: 0.75rem !important;
}

.tw-scroll-p-3\.5 {
  scroll-padding: 0.875rem !important;
}

.tw-scroll-p-32 {
  scroll-padding: 8rem !important;
}

.tw-scroll-p-36 {
  scroll-padding: 9rem !important;
}

.tw-scroll-p-4 {
  scroll-padding: 1rem !important;
}

.tw-scroll-p-40 {
  scroll-padding: 10rem !important;
}

.tw-scroll-p-44 {
  scroll-padding: 11rem !important;
}

.tw-scroll-p-48 {
  scroll-padding: 12rem !important;
}

.tw-scroll-p-5 {
  scroll-padding: 1.25rem !important;
}

.tw-scroll-p-52 {
  scroll-padding: 13rem !important;
}

.tw-scroll-p-56 {
  scroll-padding: 14rem !important;
}

.tw-scroll-p-6 {
  scroll-padding: 1.5rem !important;
}

.tw-scroll-p-60 {
  scroll-padding: 15rem !important;
}

.tw-scroll-p-64 {
  scroll-padding: 16rem !important;
}

.tw-scroll-p-7 {
  scroll-padding: 1.75rem !important;
}

.tw-scroll-p-72 {
  scroll-padding: 18rem !important;
}

.tw-scroll-p-8 {
  scroll-padding: 2rem !important;
}

.tw-scroll-p-80 {
  scroll-padding: 20rem !important;
}

.tw-scroll-p-9 {
  scroll-padding: 2.25rem !important;
}

.tw-scroll-p-96 {
  scroll-padding: 24rem !important;
}

.tw-scroll-p-px {
  scroll-padding: 1px !important;
}

.tw-scroll-px-0 {
  scroll-padding-left: 0px !important;
  scroll-padding-right: 0px !important;
}

.tw-scroll-px-0\.5 {
  scroll-padding-left: 0.125rem !important;
  scroll-padding-right: 0.125rem !important;
}

.tw-scroll-px-1 {
  scroll-padding-left: 0.25rem !important;
  scroll-padding-right: 0.25rem !important;
}

.tw-scroll-px-1\.5 {
  scroll-padding-left: 0.375rem !important;
  scroll-padding-right: 0.375rem !important;
}

.tw-scroll-px-10 {
  scroll-padding-left: 2.5rem !important;
  scroll-padding-right: 2.5rem !important;
}

.tw-scroll-px-11 {
  scroll-padding-left: 2.75rem !important;
  scroll-padding-right: 2.75rem !important;
}

.tw-scroll-px-12 {
  scroll-padding-left: 3rem !important;
  scroll-padding-right: 3rem !important;
}

.tw-scroll-px-14 {
  scroll-padding-left: 3.5rem !important;
  scroll-padding-right: 3.5rem !important;
}

.tw-scroll-px-16 {
  scroll-padding-left: 4rem !important;
  scroll-padding-right: 4rem !important;
}

.tw-scroll-px-2 {
  scroll-padding-left: 0.5rem !important;
  scroll-padding-right: 0.5rem !important;
}

.tw-scroll-px-2\.5 {
  scroll-padding-left: 0.625rem !important;
  scroll-padding-right: 0.625rem !important;
}

.tw-scroll-px-20 {
  scroll-padding-left: 5rem !important;
  scroll-padding-right: 5rem !important;
}

.tw-scroll-px-24 {
  scroll-padding-left: 6rem !important;
  scroll-padding-right: 6rem !important;
}

.tw-scroll-px-28 {
  scroll-padding-left: 7rem !important;
  scroll-padding-right: 7rem !important;
}

.tw-scroll-px-3 {
  scroll-padding-left: 0.75rem !important;
  scroll-padding-right: 0.75rem !important;
}

.tw-scroll-px-3\.5 {
  scroll-padding-left: 0.875rem !important;
  scroll-padding-right: 0.875rem !important;
}

.tw-scroll-px-32 {
  scroll-padding-left: 8rem !important;
  scroll-padding-right: 8rem !important;
}

.tw-scroll-px-36 {
  scroll-padding-left: 9rem !important;
  scroll-padding-right: 9rem !important;
}

.tw-scroll-px-4 {
  scroll-padding-left: 1rem !important;
  scroll-padding-right: 1rem !important;
}

.tw-scroll-px-40 {
  scroll-padding-left: 10rem !important;
  scroll-padding-right: 10rem !important;
}

.tw-scroll-px-44 {
  scroll-padding-left: 11rem !important;
  scroll-padding-right: 11rem !important;
}

.tw-scroll-px-48 {
  scroll-padding-left: 12rem !important;
  scroll-padding-right: 12rem !important;
}

.tw-scroll-px-5 {
  scroll-padding-left: 1.25rem !important;
  scroll-padding-right: 1.25rem !important;
}

.tw-scroll-px-52 {
  scroll-padding-left: 13rem !important;
  scroll-padding-right: 13rem !important;
}

.tw-scroll-px-56 {
  scroll-padding-left: 14rem !important;
  scroll-padding-right: 14rem !important;
}

.tw-scroll-px-6 {
  scroll-padding-left: 1.5rem !important;
  scroll-padding-right: 1.5rem !important;
}

.tw-scroll-px-60 {
  scroll-padding-left: 15rem !important;
  scroll-padding-right: 15rem !important;
}

.tw-scroll-px-64 {
  scroll-padding-left: 16rem !important;
  scroll-padding-right: 16rem !important;
}

.tw-scroll-px-7 {
  scroll-padding-left: 1.75rem !important;
  scroll-padding-right: 1.75rem !important;
}

.tw-scroll-px-72 {
  scroll-padding-left: 18rem !important;
  scroll-padding-right: 18rem !important;
}

.tw-scroll-px-8 {
  scroll-padding-left: 2rem !important;
  scroll-padding-right: 2rem !important;
}

.tw-scroll-px-80 {
  scroll-padding-left: 20rem !important;
  scroll-padding-right: 20rem !important;
}

.tw-scroll-px-9 {
  scroll-padding-left: 2.25rem !important;
  scroll-padding-right: 2.25rem !important;
}

.tw-scroll-px-96 {
  scroll-padding-left: 24rem !important;
  scroll-padding-right: 24rem !important;
}

.tw-scroll-px-px {
  scroll-padding-left: 1px !important;
  scroll-padding-right: 1px !important;
}

.tw-scroll-py-0 {
  scroll-padding-top: 0px !important;
  scroll-padding-bottom: 0px !important;
}

.tw-scroll-py-0\.5 {
  scroll-padding-top: 0.125rem !important;
  scroll-padding-bottom: 0.125rem !important;
}

.tw-scroll-py-1 {
  scroll-padding-top: 0.25rem !important;
  scroll-padding-bottom: 0.25rem !important;
}

.tw-scroll-py-1\.5 {
  scroll-padding-top: 0.375rem !important;
  scroll-padding-bottom: 0.375rem !important;
}

.tw-scroll-py-10 {
  scroll-padding-top: 2.5rem !important;
  scroll-padding-bottom: 2.5rem !important;
}

.tw-scroll-py-11 {
  scroll-padding-top: 2.75rem !important;
  scroll-padding-bottom: 2.75rem !important;
}

.tw-scroll-py-12 {
  scroll-padding-top: 3rem !important;
  scroll-padding-bottom: 3rem !important;
}

.tw-scroll-py-14 {
  scroll-padding-top: 3.5rem !important;
  scroll-padding-bottom: 3.5rem !important;
}

.tw-scroll-py-16 {
  scroll-padding-top: 4rem !important;
  scroll-padding-bottom: 4rem !important;
}

.tw-scroll-py-2 {
  scroll-padding-top: 0.5rem !important;
  scroll-padding-bottom: 0.5rem !important;
}

.tw-scroll-py-2\.5 {
  scroll-padding-top: 0.625rem !important;
  scroll-padding-bottom: 0.625rem !important;
}

.tw-scroll-py-20 {
  scroll-padding-top: 5rem !important;
  scroll-padding-bottom: 5rem !important;
}

.tw-scroll-py-24 {
  scroll-padding-top: 6rem !important;
  scroll-padding-bottom: 6rem !important;
}

.tw-scroll-py-28 {
  scroll-padding-top: 7rem !important;
  scroll-padding-bottom: 7rem !important;
}

.tw-scroll-py-3 {
  scroll-padding-top: 0.75rem !important;
  scroll-padding-bottom: 0.75rem !important;
}

.tw-scroll-py-3\.5 {
  scroll-padding-top: 0.875rem !important;
  scroll-padding-bottom: 0.875rem !important;
}

.tw-scroll-py-32 {
  scroll-padding-top: 8rem !important;
  scroll-padding-bottom: 8rem !important;
}

.tw-scroll-py-36 {
  scroll-padding-top: 9rem !important;
  scroll-padding-bottom: 9rem !important;
}

.tw-scroll-py-4 {
  scroll-padding-top: 1rem !important;
  scroll-padding-bottom: 1rem !important;
}

.tw-scroll-py-40 {
  scroll-padding-top: 10rem !important;
  scroll-padding-bottom: 10rem !important;
}

.tw-scroll-py-44 {
  scroll-padding-top: 11rem !important;
  scroll-padding-bottom: 11rem !important;
}

.tw-scroll-py-48 {
  scroll-padding-top: 12rem !important;
  scroll-padding-bottom: 12rem !important;
}

.tw-scroll-py-5 {
  scroll-padding-top: 1.25rem !important;
  scroll-padding-bottom: 1.25rem !important;
}

.tw-scroll-py-52 {
  scroll-padding-top: 13rem !important;
  scroll-padding-bottom: 13rem !important;
}

.tw-scroll-py-56 {
  scroll-padding-top: 14rem !important;
  scroll-padding-bottom: 14rem !important;
}

.tw-scroll-py-6 {
  scroll-padding-top: 1.5rem !important;
  scroll-padding-bottom: 1.5rem !important;
}

.tw-scroll-py-60 {
  scroll-padding-top: 15rem !important;
  scroll-padding-bottom: 15rem !important;
}

.tw-scroll-py-64 {
  scroll-padding-top: 16rem !important;
  scroll-padding-bottom: 16rem !important;
}

.tw-scroll-py-7 {
  scroll-padding-top: 1.75rem !important;
  scroll-padding-bottom: 1.75rem !important;
}

.tw-scroll-py-72 {
  scroll-padding-top: 18rem !important;
  scroll-padding-bottom: 18rem !important;
}

.tw-scroll-py-8 {
  scroll-padding-top: 2rem !important;
  scroll-padding-bottom: 2rem !important;
}

.tw-scroll-py-80 {
  scroll-padding-top: 20rem !important;
  scroll-padding-bottom: 20rem !important;
}

.tw-scroll-py-9 {
  scroll-padding-top: 2.25rem !important;
  scroll-padding-bottom: 2.25rem !important;
}

.tw-scroll-py-96 {
  scroll-padding-top: 24rem !important;
  scroll-padding-bottom: 24rem !important;
}

.tw-scroll-py-px {
  scroll-padding-top: 1px !important;
  scroll-padding-bottom: 1px !important;
}

.tw-scroll-pb-0 {
  scroll-padding-bottom: 0px !important;
}

.tw-scroll-pb-0\.5 {
  scroll-padding-bottom: 0.125rem !important;
}

.tw-scroll-pb-1 {
  scroll-padding-bottom: 0.25rem !important;
}

.tw-scroll-pb-1\.5 {
  scroll-padding-bottom: 0.375rem !important;
}

.tw-scroll-pb-10 {
  scroll-padding-bottom: 2.5rem !important;
}

.tw-scroll-pb-11 {
  scroll-padding-bottom: 2.75rem !important;
}

.tw-scroll-pb-12 {
  scroll-padding-bottom: 3rem !important;
}

.tw-scroll-pb-14 {
  scroll-padding-bottom: 3.5rem !important;
}

.tw-scroll-pb-16 {
  scroll-padding-bottom: 4rem !important;
}

.tw-scroll-pb-2 {
  scroll-padding-bottom: 0.5rem !important;
}

.tw-scroll-pb-2\.5 {
  scroll-padding-bottom: 0.625rem !important;
}

.tw-scroll-pb-20 {
  scroll-padding-bottom: 5rem !important;
}

.tw-scroll-pb-24 {
  scroll-padding-bottom: 6rem !important;
}

.tw-scroll-pb-28 {
  scroll-padding-bottom: 7rem !important;
}

.tw-scroll-pb-3 {
  scroll-padding-bottom: 0.75rem !important;
}

.tw-scroll-pb-3\.5 {
  scroll-padding-bottom: 0.875rem !important;
}

.tw-scroll-pb-32 {
  scroll-padding-bottom: 8rem !important;
}

.tw-scroll-pb-36 {
  scroll-padding-bottom: 9rem !important;
}

.tw-scroll-pb-4 {
  scroll-padding-bottom: 1rem !important;
}

.tw-scroll-pb-40 {
  scroll-padding-bottom: 10rem !important;
}

.tw-scroll-pb-44 {
  scroll-padding-bottom: 11rem !important;
}

.tw-scroll-pb-48 {
  scroll-padding-bottom: 12rem !important;
}

.tw-scroll-pb-5 {
  scroll-padding-bottom: 1.25rem !important;
}

.tw-scroll-pb-52 {
  scroll-padding-bottom: 13rem !important;
}

.tw-scroll-pb-56 {
  scroll-padding-bottom: 14rem !important;
}

.tw-scroll-pb-6 {
  scroll-padding-bottom: 1.5rem !important;
}

.tw-scroll-pb-60 {
  scroll-padding-bottom: 15rem !important;
}

.tw-scroll-pb-64 {
  scroll-padding-bottom: 16rem !important;
}

.tw-scroll-pb-7 {
  scroll-padding-bottom: 1.75rem !important;
}

.tw-scroll-pb-72 {
  scroll-padding-bottom: 18rem !important;
}

.tw-scroll-pb-8 {
  scroll-padding-bottom: 2rem !important;
}

.tw-scroll-pb-80 {
  scroll-padding-bottom: 20rem !important;
}

.tw-scroll-pb-9 {
  scroll-padding-bottom: 2.25rem !important;
}

.tw-scroll-pb-96 {
  scroll-padding-bottom: 24rem !important;
}

.tw-scroll-pb-px {
  scroll-padding-bottom: 1px !important;
}

.tw-scroll-pe-0 {
  scroll-padding-inline-end: 0px !important;
}

.tw-scroll-pe-0\.5 {
  scroll-padding-inline-end: 0.125rem !important;
}

.tw-scroll-pe-1 {
  scroll-padding-inline-end: 0.25rem !important;
}

.tw-scroll-pe-1\.5 {
  scroll-padding-inline-end: 0.375rem !important;
}

.tw-scroll-pe-10 {
  scroll-padding-inline-end: 2.5rem !important;
}

.tw-scroll-pe-11 {
  scroll-padding-inline-end: 2.75rem !important;
}

.tw-scroll-pe-12 {
  scroll-padding-inline-end: 3rem !important;
}

.tw-scroll-pe-14 {
  scroll-padding-inline-end: 3.5rem !important;
}

.tw-scroll-pe-16 {
  scroll-padding-inline-end: 4rem !important;
}

.tw-scroll-pe-2 {
  scroll-padding-inline-end: 0.5rem !important;
}

.tw-scroll-pe-2\.5 {
  scroll-padding-inline-end: 0.625rem !important;
}

.tw-scroll-pe-20 {
  scroll-padding-inline-end: 5rem !important;
}

.tw-scroll-pe-24 {
  scroll-padding-inline-end: 6rem !important;
}

.tw-scroll-pe-28 {
  scroll-padding-inline-end: 7rem !important;
}

.tw-scroll-pe-3 {
  scroll-padding-inline-end: 0.75rem !important;
}

.tw-scroll-pe-3\.5 {
  scroll-padding-inline-end: 0.875rem !important;
}

.tw-scroll-pe-32 {
  scroll-padding-inline-end: 8rem !important;
}

.tw-scroll-pe-36 {
  scroll-padding-inline-end: 9rem !important;
}

.tw-scroll-pe-4 {
  scroll-padding-inline-end: 1rem !important;
}

.tw-scroll-pe-40 {
  scroll-padding-inline-end: 10rem !important;
}

.tw-scroll-pe-44 {
  scroll-padding-inline-end: 11rem !important;
}

.tw-scroll-pe-48 {
  scroll-padding-inline-end: 12rem !important;
}

.tw-scroll-pe-5 {
  scroll-padding-inline-end: 1.25rem !important;
}

.tw-scroll-pe-52 {
  scroll-padding-inline-end: 13rem !important;
}

.tw-scroll-pe-56 {
  scroll-padding-inline-end: 14rem !important;
}

.tw-scroll-pe-6 {
  scroll-padding-inline-end: 1.5rem !important;
}

.tw-scroll-pe-60 {
  scroll-padding-inline-end: 15rem !important;
}

.tw-scroll-pe-64 {
  scroll-padding-inline-end: 16rem !important;
}

.tw-scroll-pe-7 {
  scroll-padding-inline-end: 1.75rem !important;
}

.tw-scroll-pe-72 {
  scroll-padding-inline-end: 18rem !important;
}

.tw-scroll-pe-8 {
  scroll-padding-inline-end: 2rem !important;
}

.tw-scroll-pe-80 {
  scroll-padding-inline-end: 20rem !important;
}

.tw-scroll-pe-9 {
  scroll-padding-inline-end: 2.25rem !important;
}

.tw-scroll-pe-96 {
  scroll-padding-inline-end: 24rem !important;
}

.tw-scroll-pe-px {
  scroll-padding-inline-end: 1px !important;
}

.tw-scroll-pl-0 {
  scroll-padding-left: 0px !important;
}

.tw-scroll-pl-0\.5 {
  scroll-padding-left: 0.125rem !important;
}

.tw-scroll-pl-1 {
  scroll-padding-left: 0.25rem !important;
}

.tw-scroll-pl-1\.5 {
  scroll-padding-left: 0.375rem !important;
}

.tw-scroll-pl-10 {
  scroll-padding-left: 2.5rem !important;
}

.tw-scroll-pl-11 {
  scroll-padding-left: 2.75rem !important;
}

.tw-scroll-pl-12 {
  scroll-padding-left: 3rem !important;
}

.tw-scroll-pl-14 {
  scroll-padding-left: 3.5rem !important;
}

.tw-scroll-pl-16 {
  scroll-padding-left: 4rem !important;
}

.tw-scroll-pl-2 {
  scroll-padding-left: 0.5rem !important;
}

.tw-scroll-pl-2\.5 {
  scroll-padding-left: 0.625rem !important;
}

.tw-scroll-pl-20 {
  scroll-padding-left: 5rem !important;
}

.tw-scroll-pl-24 {
  scroll-padding-left: 6rem !important;
}

.tw-scroll-pl-28 {
  scroll-padding-left: 7rem !important;
}

.tw-scroll-pl-3 {
  scroll-padding-left: 0.75rem !important;
}

.tw-scroll-pl-3\.5 {
  scroll-padding-left: 0.875rem !important;
}

.tw-scroll-pl-32 {
  scroll-padding-left: 8rem !important;
}

.tw-scroll-pl-36 {
  scroll-padding-left: 9rem !important;
}

.tw-scroll-pl-4 {
  scroll-padding-left: 1rem !important;
}

.tw-scroll-pl-40 {
  scroll-padding-left: 10rem !important;
}

.tw-scroll-pl-44 {
  scroll-padding-left: 11rem !important;
}

.tw-scroll-pl-48 {
  scroll-padding-left: 12rem !important;
}

.tw-scroll-pl-5 {
  scroll-padding-left: 1.25rem !important;
}

.tw-scroll-pl-52 {
  scroll-padding-left: 13rem !important;
}

.tw-scroll-pl-56 {
  scroll-padding-left: 14rem !important;
}

.tw-scroll-pl-6 {
  scroll-padding-left: 1.5rem !important;
}

.tw-scroll-pl-60 {
  scroll-padding-left: 15rem !important;
}

.tw-scroll-pl-64 {
  scroll-padding-left: 16rem !important;
}

.tw-scroll-pl-7 {
  scroll-padding-left: 1.75rem !important;
}

.tw-scroll-pl-72 {
  scroll-padding-left: 18rem !important;
}

.tw-scroll-pl-8 {
  scroll-padding-left: 2rem !important;
}

.tw-scroll-pl-80 {
  scroll-padding-left: 20rem !important;
}

.tw-scroll-pl-9 {
  scroll-padding-left: 2.25rem !important;
}

.tw-scroll-pl-96 {
  scroll-padding-left: 24rem !important;
}

.tw-scroll-pl-px {
  scroll-padding-left: 1px !important;
}

.tw-scroll-pr-0 {
  scroll-padding-right: 0px !important;
}

.tw-scroll-pr-0\.5 {
  scroll-padding-right: 0.125rem !important;
}

.tw-scroll-pr-1 {
  scroll-padding-right: 0.25rem !important;
}

.tw-scroll-pr-1\.5 {
  scroll-padding-right: 0.375rem !important;
}

.tw-scroll-pr-10 {
  scroll-padding-right: 2.5rem !important;
}

.tw-scroll-pr-11 {
  scroll-padding-right: 2.75rem !important;
}

.tw-scroll-pr-12 {
  scroll-padding-right: 3rem !important;
}

.tw-scroll-pr-14 {
  scroll-padding-right: 3.5rem !important;
}

.tw-scroll-pr-16 {
  scroll-padding-right: 4rem !important;
}

.tw-scroll-pr-2 {
  scroll-padding-right: 0.5rem !important;
}

.tw-scroll-pr-2\.5 {
  scroll-padding-right: 0.625rem !important;
}

.tw-scroll-pr-20 {
  scroll-padding-right: 5rem !important;
}

.tw-scroll-pr-24 {
  scroll-padding-right: 6rem !important;
}

.tw-scroll-pr-28 {
  scroll-padding-right: 7rem !important;
}

.tw-scroll-pr-3 {
  scroll-padding-right: 0.75rem !important;
}

.tw-scroll-pr-3\.5 {
  scroll-padding-right: 0.875rem !important;
}

.tw-scroll-pr-32 {
  scroll-padding-right: 8rem !important;
}

.tw-scroll-pr-36 {
  scroll-padding-right: 9rem !important;
}

.tw-scroll-pr-4 {
  scroll-padding-right: 1rem !important;
}

.tw-scroll-pr-40 {
  scroll-padding-right: 10rem !important;
}

.tw-scroll-pr-44 {
  scroll-padding-right: 11rem !important;
}

.tw-scroll-pr-48 {
  scroll-padding-right: 12rem !important;
}

.tw-scroll-pr-5 {
  scroll-padding-right: 1.25rem !important;
}

.tw-scroll-pr-52 {
  scroll-padding-right: 13rem !important;
}

.tw-scroll-pr-56 {
  scroll-padding-right: 14rem !important;
}

.tw-scroll-pr-6 {
  scroll-padding-right: 1.5rem !important;
}

.tw-scroll-pr-60 {
  scroll-padding-right: 15rem !important;
}

.tw-scroll-pr-64 {
  scroll-padding-right: 16rem !important;
}

.tw-scroll-pr-7 {
  scroll-padding-right: 1.75rem !important;
}

.tw-scroll-pr-72 {
  scroll-padding-right: 18rem !important;
}

.tw-scroll-pr-8 {
  scroll-padding-right: 2rem !important;
}

.tw-scroll-pr-80 {
  scroll-padding-right: 20rem !important;
}

.tw-scroll-pr-9 {
  scroll-padding-right: 2.25rem !important;
}

.tw-scroll-pr-96 {
  scroll-padding-right: 24rem !important;
}

.tw-scroll-pr-px {
  scroll-padding-right: 1px !important;
}

.tw-scroll-ps-0 {
  scroll-padding-inline-start: 0px !important;
}

.tw-scroll-ps-0\.5 {
  scroll-padding-inline-start: 0.125rem !important;
}

.tw-scroll-ps-1 {
  scroll-padding-inline-start: 0.25rem !important;
}

.tw-scroll-ps-1\.5 {
  scroll-padding-inline-start: 0.375rem !important;
}

.tw-scroll-ps-10 {
  scroll-padding-inline-start: 2.5rem !important;
}

.tw-scroll-ps-11 {
  scroll-padding-inline-start: 2.75rem !important;
}

.tw-scroll-ps-12 {
  scroll-padding-inline-start: 3rem !important;
}

.tw-scroll-ps-14 {
  scroll-padding-inline-start: 3.5rem !important;
}

.tw-scroll-ps-16 {
  scroll-padding-inline-start: 4rem !important;
}

.tw-scroll-ps-2 {
  scroll-padding-inline-start: 0.5rem !important;
}

.tw-scroll-ps-2\.5 {
  scroll-padding-inline-start: 0.625rem !important;
}

.tw-scroll-ps-20 {
  scroll-padding-inline-start: 5rem !important;
}

.tw-scroll-ps-24 {
  scroll-padding-inline-start: 6rem !important;
}

.tw-scroll-ps-28 {
  scroll-padding-inline-start: 7rem !important;
}

.tw-scroll-ps-3 {
  scroll-padding-inline-start: 0.75rem !important;
}

.tw-scroll-ps-3\.5 {
  scroll-padding-inline-start: 0.875rem !important;
}

.tw-scroll-ps-32 {
  scroll-padding-inline-start: 8rem !important;
}

.tw-scroll-ps-36 {
  scroll-padding-inline-start: 9rem !important;
}

.tw-scroll-ps-4 {
  scroll-padding-inline-start: 1rem !important;
}

.tw-scroll-ps-40 {
  scroll-padding-inline-start: 10rem !important;
}

.tw-scroll-ps-44 {
  scroll-padding-inline-start: 11rem !important;
}

.tw-scroll-ps-48 {
  scroll-padding-inline-start: 12rem !important;
}

.tw-scroll-ps-5 {
  scroll-padding-inline-start: 1.25rem !important;
}

.tw-scroll-ps-52 {
  scroll-padding-inline-start: 13rem !important;
}

.tw-scroll-ps-56 {
  scroll-padding-inline-start: 14rem !important;
}

.tw-scroll-ps-6 {
  scroll-padding-inline-start: 1.5rem !important;
}

.tw-scroll-ps-60 {
  scroll-padding-inline-start: 15rem !important;
}

.tw-scroll-ps-64 {
  scroll-padding-inline-start: 16rem !important;
}

.tw-scroll-ps-7 {
  scroll-padding-inline-start: 1.75rem !important;
}

.tw-scroll-ps-72 {
  scroll-padding-inline-start: 18rem !important;
}

.tw-scroll-ps-8 {
  scroll-padding-inline-start: 2rem !important;
}

.tw-scroll-ps-80 {
  scroll-padding-inline-start: 20rem !important;
}

.tw-scroll-ps-9 {
  scroll-padding-inline-start: 2.25rem !important;
}

.tw-scroll-ps-96 {
  scroll-padding-inline-start: 24rem !important;
}

.tw-scroll-ps-px {
  scroll-padding-inline-start: 1px !important;
}

.tw-scroll-pt-0 {
  scroll-padding-top: 0px !important;
}

.tw-scroll-pt-0\.5 {
  scroll-padding-top: 0.125rem !important;
}

.tw-scroll-pt-1 {
  scroll-padding-top: 0.25rem !important;
}

.tw-scroll-pt-1\.5 {
  scroll-padding-top: 0.375rem !important;
}

.tw-scroll-pt-10 {
  scroll-padding-top: 2.5rem !important;
}

.tw-scroll-pt-11 {
  scroll-padding-top: 2.75rem !important;
}

.tw-scroll-pt-12 {
  scroll-padding-top: 3rem !important;
}

.tw-scroll-pt-14 {
  scroll-padding-top: 3.5rem !important;
}

.tw-scroll-pt-16 {
  scroll-padding-top: 4rem !important;
}

.tw-scroll-pt-2 {
  scroll-padding-top: 0.5rem !important;
}

.tw-scroll-pt-2\.5 {
  scroll-padding-top: 0.625rem !important;
}

.tw-scroll-pt-20 {
  scroll-padding-top: 5rem !important;
}

.tw-scroll-pt-24 {
  scroll-padding-top: 6rem !important;
}

.tw-scroll-pt-28 {
  scroll-padding-top: 7rem !important;
}

.tw-scroll-pt-3 {
  scroll-padding-top: 0.75rem !important;
}

.tw-scroll-pt-3\.5 {
  scroll-padding-top: 0.875rem !important;
}

.tw-scroll-pt-32 {
  scroll-padding-top: 8rem !important;
}

.tw-scroll-pt-36 {
  scroll-padding-top: 9rem !important;
}

.tw-scroll-pt-4 {
  scroll-padding-top: 1rem !important;
}

.tw-scroll-pt-40 {
  scroll-padding-top: 10rem !important;
}

.tw-scroll-pt-44 {
  scroll-padding-top: 11rem !important;
}

.tw-scroll-pt-48 {
  scroll-padding-top: 12rem !important;
}

.tw-scroll-pt-5 {
  scroll-padding-top: 1.25rem !important;
}

.tw-scroll-pt-52 {
  scroll-padding-top: 13rem !important;
}

.tw-scroll-pt-56 {
  scroll-padding-top: 14rem !important;
}

.tw-scroll-pt-6 {
  scroll-padding-top: 1.5rem !important;
}

.tw-scroll-pt-60 {
  scroll-padding-top: 15rem !important;
}

.tw-scroll-pt-64 {
  scroll-padding-top: 16rem !important;
}

.tw-scroll-pt-7 {
  scroll-padding-top: 1.75rem !important;
}

.tw-scroll-pt-72 {
  scroll-padding-top: 18rem !important;
}

.tw-scroll-pt-8 {
  scroll-padding-top: 2rem !important;
}

.tw-scroll-pt-80 {
  scroll-padding-top: 20rem !important;
}

.tw-scroll-pt-9 {
  scroll-padding-top: 2.25rem !important;
}

.tw-scroll-pt-96 {
  scroll-padding-top: 24rem !important;
}

.tw-scroll-pt-px {
  scroll-padding-top: 1px !important;
}

.tw-list-inside {
  list-style-position: inside !important;
}

.tw-list-outside {
  list-style-position: outside !important;
}

.tw-list-decimal {
  list-style-type: decimal !important;
}

.tw-list-disc {
  list-style-type: disc !important;
}

.tw-list-none {
  list-style-type: none !important;
}

.tw-list-image-none {
  list-style-image: none !important;
}

.tw-appearance-none {
  -webkit-appearance: none !important;
          appearance: none !important;
}

.tw-appearance-auto {
  -webkit-appearance: auto !important;
          appearance: auto !important;
}

.tw-columns-1 {
  columns: 1 !important;
}

.tw-columns-10 {
  columns: 10 !important;
}

.tw-columns-11 {
  columns: 11 !important;
}

.tw-columns-12 {
  columns: 12 !important;
}

.tw-columns-2 {
  columns: 2 !important;
}

.tw-columns-2xl {
  columns: 42rem !important;
}

.tw-columns-2xs {
  columns: 18rem !important;
}

.tw-columns-3 {
  columns: 3 !important;
}

.tw-columns-3xl {
  columns: 48rem !important;
}

.tw-columns-3xs {
  columns: 16rem !important;
}

.tw-columns-4 {
  columns: 4 !important;
}

.tw-columns-4xl {
  columns: 56rem !important;
}

.tw-columns-5 {
  columns: 5 !important;
}

.tw-columns-5xl {
  columns: 64rem !important;
}

.tw-columns-6 {
  columns: 6 !important;
}

.tw-columns-6xl {
  columns: 72rem !important;
}

.tw-columns-7 {
  columns: 7 !important;
}

.tw-columns-7xl {
  columns: 80rem !important;
}

.tw-columns-8 {
  columns: 8 !important;
}

.tw-columns-9 {
  columns: 9 !important;
}

.tw-columns-auto {
  columns: auto !important;
}

.tw-columns-lg {
  columns: 32rem !important;
}

.tw-columns-md {
  columns: 28rem !important;
}

.tw-columns-sm {
  columns: 24rem !important;
}

.tw-columns-xl {
  columns: 36rem !important;
}

.tw-columns-xs {
  columns: 20rem !important;
}

.tw-break-before-auto {
  break-before: auto !important;
}

.tw-break-before-avoid {
  break-before: avoid !important;
}

.tw-break-before-all {
  break-before: all !important;
}

.tw-break-before-avoid-page {
  break-before: avoid-page !important;
}

.tw-break-before-page {
  break-before: page !important;
}

.tw-break-before-left {
  break-before: left !important;
}

.tw-break-before-right {
  break-before: right !important;
}

.tw-break-before-column {
  break-before: column !important;
}

.tw-break-inside-auto {
  break-inside: auto !important;
}

.tw-break-inside-avoid {
  break-inside: avoid !important;
}

.tw-break-inside-avoid-page {
  break-inside: avoid-page !important;
}

.tw-break-inside-avoid-column {
  break-inside: avoid-column !important;
}

.tw-break-after-auto {
  break-after: auto !important;
}

.tw-break-after-avoid {
  break-after: avoid !important;
}

.tw-break-after-all {
  break-after: all !important;
}

.tw-break-after-avoid-page {
  break-after: avoid-page !important;
}

.tw-break-after-page {
  break-after: page !important;
}

.tw-break-after-left {
  break-after: left !important;
}

.tw-break-after-right {
  break-after: right !important;
}

.tw-break-after-column {
  break-after: column !important;
}

.tw-auto-cols-auto {
  grid-auto-columns: auto !important;
}

.tw-auto-cols-fr {
  grid-auto-columns: minmax(0, 1fr) !important;
}

.tw-auto-cols-max {
  grid-auto-columns: -webkit-max-content !important;
  grid-auto-columns: max-content !important;
}

.tw-auto-cols-min {
  grid-auto-columns: -webkit-min-content !important;
  grid-auto-columns: min-content !important;
}

.tw-grid-flow-row {
  grid-auto-flow: row !important;
}

.tw-grid-flow-col {
  grid-auto-flow: column !important;
}

.tw-grid-flow-dense {
  grid-auto-flow: dense !important;
}

.tw-grid-flow-row-dense {
  grid-auto-flow: row dense !important;
}

.tw-grid-flow-col-dense {
  grid-auto-flow: column dense !important;
}

.tw-auto-rows-auto {
  grid-auto-rows: auto !important;
}

.tw-auto-rows-fr {
  grid-auto-rows: minmax(0, 1fr) !important;
}

.tw-auto-rows-max {
  grid-auto-rows: -webkit-max-content !important;
  grid-auto-rows: max-content !important;
}

.tw-auto-rows-min {
  grid-auto-rows: -webkit-min-content !important;
  grid-auto-rows: min-content !important;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
}

.tw-grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr)) !important;
}

.tw-grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr)) !important;
}

.tw-grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr)) !important;
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
}

.tw-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr)) !important;
}

.tw-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr)) !important;
}

.tw-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr)) !important;
}

.tw-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr)) !important;
}

.tw-grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr)) !important;
}

.tw-grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr)) !important;
}

.tw-grid-cols-none {
  grid-template-columns: none !important;
}

.tw-grid-cols-subgrid {
  grid-template-columns: subgrid !important;
}

.tw-grid-rows-1 {
  grid-template-rows: repeat(1, minmax(0, 1fr)) !important;
}

.tw-grid-rows-10 {
  grid-template-rows: repeat(10, minmax(0, 1fr)) !important;
}

.tw-grid-rows-11 {
  grid-template-rows: repeat(11, minmax(0, 1fr)) !important;
}

.tw-grid-rows-12 {
  grid-template-rows: repeat(12, minmax(0, 1fr)) !important;
}

.tw-grid-rows-2 {
  grid-template-rows: repeat(2, minmax(0, 1fr)) !important;
}

.tw-grid-rows-3 {
  grid-template-rows: repeat(3, minmax(0, 1fr)) !important;
}

.tw-grid-rows-4 {
  grid-template-rows: repeat(4, minmax(0, 1fr)) !important;
}

.tw-grid-rows-5 {
  grid-template-rows: repeat(5, minmax(0, 1fr)) !important;
}

.tw-grid-rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr)) !important;
}

.tw-grid-rows-7 {
  grid-template-rows: repeat(7, minmax(0, 1fr)) !important;
}

.tw-grid-rows-8 {
  grid-template-rows: repeat(8, minmax(0, 1fr)) !important;
}

.tw-grid-rows-9 {
  grid-template-rows: repeat(9, minmax(0, 1fr)) !important;
}

.tw-grid-rows-none {
  grid-template-rows: none !important;
}

.tw-grid-rows-subgrid {
  grid-template-rows: subgrid !important;
}

.tw-flex-row {
  flex-direction: row !important;
}

.tw-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.tw-flex-col {
  flex-direction: column !important;
}

.tw-flex-col-reverse {
  flex-direction: column-reverse !important;
}

.tw-flex-wrap {
  flex-wrap: wrap !important;
}

.tw-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.tw-flex-nowrap {
  flex-wrap: nowrap !important;
}

.tw-place-content-center {
  place-content: center !important;
}

.tw-place-content-start {
  place-content: start !important;
}

.tw-place-content-end {
  place-content: end !important;
}

.tw-place-content-between {
  place-content: space-between !important;
}

.tw-place-content-around {
  place-content: space-around !important;
}

.tw-place-content-evenly {
  place-content: space-evenly !important;
}

.tw-place-content-baseline {
  place-content: baseline !important;
}

.tw-place-content-stretch {
  place-content: stretch !important;
}

.tw-place-items-start {
  place-items: start !important;
}

.tw-place-items-end {
  place-items: end !important;
}

.tw-place-items-center {
  place-items: center !important;
}

.tw-place-items-baseline {
  place-items: baseline !important;
}

.tw-place-items-stretch {
  place-items: stretch !important;
}

.tw-content-normal {
  align-content: normal !important;
}

.tw-content-center {
  align-content: center !important;
}

.tw-content-start {
  align-content: flex-start !important;
}

.tw-content-end {
  align-content: flex-end !important;
}

.tw-content-between {
  align-content: space-between !important;
}

.tw-content-around {
  align-content: space-around !important;
}

.tw-content-evenly {
  align-content: space-evenly !important;
}

.tw-content-baseline {
  align-content: baseline !important;
}

.tw-content-stretch {
  align-content: stretch !important;
}

.tw-items-start {
  align-items: flex-start !important;
}

.tw-items-end {
  align-items: flex-end !important;
}

.tw-items-center {
  align-items: center !important;
}

.tw-items-baseline {
  align-items: baseline !important;
}

.tw-items-stretch {
  align-items: stretch !important;
}

.tw-justify-normal {
  justify-content: normal !important;
}

.tw-justify-start {
  justify-content: flex-start !important;
}

.tw-justify-end {
  justify-content: flex-end !important;
}

.tw-justify-center {
  justify-content: center !important;
}

.tw-justify-between {
  justify-content: space-between !important;
}

.tw-justify-around {
  justify-content: space-around !important;
}

.tw-justify-evenly {
  justify-content: space-evenly !important;
}

.tw-justify-stretch {
  justify-content: stretch !important;
}

.tw-justify-items-start {
  justify-items: start !important;
}

.tw-justify-items-end {
  justify-items: end !important;
}

.tw-justify-items-center {
  justify-items: center !important;
}

.tw-justify-items-stretch {
  justify-items: stretch !important;
}

.tw-gap-0 {
  gap: 0px !important;
}

.tw-gap-0\.5 {
  gap: 0.125rem !important;
}

.tw-gap-1 {
  gap: 0.25rem !important;
}

.tw-gap-1\.5 {
  gap: 0.375rem !important;
}

.tw-gap-10 {
  gap: 2.5rem !important;
}

.tw-gap-11 {
  gap: 2.75rem !important;
}

.tw-gap-12 {
  gap: 3rem !important;
}

.tw-gap-14 {
  gap: 3.5rem !important;
}

.tw-gap-16 {
  gap: 4rem !important;
}

.tw-gap-2 {
  gap: 0.5rem !important;
}

.tw-gap-2\.5 {
  gap: 0.625rem !important;
}

.tw-gap-20 {
  gap: 5rem !important;
}

.tw-gap-24 {
  gap: 6rem !important;
}

.tw-gap-28 {
  gap: 7rem !important;
}

.tw-gap-3 {
  gap: 0.75rem !important;
}

.tw-gap-3\.5 {
  gap: 0.875rem !important;
}

.tw-gap-32 {
  gap: 8rem !important;
}

.tw-gap-36 {
  gap: 9rem !important;
}

.tw-gap-4 {
  gap: 1rem !important;
}

.tw-gap-40 {
  gap: 10rem !important;
}

.tw-gap-44 {
  gap: 11rem !important;
}

.tw-gap-48 {
  gap: 12rem !important;
}

.tw-gap-5 {
  gap: 1.25rem !important;
}

.tw-gap-52 {
  gap: 13rem !important;
}

.tw-gap-56 {
  gap: 14rem !important;
}

.tw-gap-6 {
  gap: 1.5rem !important;
}

.tw-gap-60 {
  gap: 15rem !important;
}

.tw-gap-64 {
  gap: 16rem !important;
}

.tw-gap-7 {
  gap: 1.75rem !important;
}

.tw-gap-72 {
  gap: 18rem !important;
}

.tw-gap-8 {
  gap: 2rem !important;
}

.tw-gap-80 {
  gap: 20rem !important;
}

.tw-gap-9 {
  gap: 2.25rem !important;
}

.tw-gap-96 {
  gap: 24rem !important;
}

.tw-gap-px {
  gap: 1px !important;
}

.tw-gap-x-0 {
  column-gap: 0px !important;
}

.tw-gap-x-0\.5 {
  column-gap: 0.125rem !important;
}

.tw-gap-x-1 {
  column-gap: 0.25rem !important;
}

.tw-gap-x-1\.5 {
  column-gap: 0.375rem !important;
}

.tw-gap-x-10 {
  column-gap: 2.5rem !important;
}

.tw-gap-x-11 {
  column-gap: 2.75rem !important;
}

.tw-gap-x-12 {
  column-gap: 3rem !important;
}

.tw-gap-x-14 {
  column-gap: 3.5rem !important;
}

.tw-gap-x-16 {
  column-gap: 4rem !important;
}

.tw-gap-x-2 {
  column-gap: 0.5rem !important;
}

.tw-gap-x-2\.5 {
  column-gap: 0.625rem !important;
}

.tw-gap-x-20 {
  column-gap: 5rem !important;
}

.tw-gap-x-24 {
  column-gap: 6rem !important;
}

.tw-gap-x-28 {
  column-gap: 7rem !important;
}

.tw-gap-x-3 {
  column-gap: 0.75rem !important;
}

.tw-gap-x-3\.5 {
  column-gap: 0.875rem !important;
}

.tw-gap-x-32 {
  column-gap: 8rem !important;
}

.tw-gap-x-36 {
  column-gap: 9rem !important;
}

.tw-gap-x-4 {
  column-gap: 1rem !important;
}

.tw-gap-x-40 {
  column-gap: 10rem !important;
}

.tw-gap-x-44 {
  column-gap: 11rem !important;
}

.tw-gap-x-48 {
  column-gap: 12rem !important;
}

.tw-gap-x-5 {
  column-gap: 1.25rem !important;
}

.tw-gap-x-52 {
  column-gap: 13rem !important;
}

.tw-gap-x-56 {
  column-gap: 14rem !important;
}

.tw-gap-x-6 {
  column-gap: 1.5rem !important;
}

.tw-gap-x-60 {
  column-gap: 15rem !important;
}

.tw-gap-x-64 {
  column-gap: 16rem !important;
}

.tw-gap-x-7 {
  column-gap: 1.75rem !important;
}

.tw-gap-x-72 {
  column-gap: 18rem !important;
}

.tw-gap-x-8 {
  column-gap: 2rem !important;
}

.tw-gap-x-80 {
  column-gap: 20rem !important;
}

.tw-gap-x-9 {
  column-gap: 2.25rem !important;
}

.tw-gap-x-96 {
  column-gap: 24rem !important;
}

.tw-gap-x-px {
  column-gap: 1px !important;
}

.tw-gap-y-0 {
  row-gap: 0px !important;
}

.tw-gap-y-0\.5 {
  row-gap: 0.125rem !important;
}

.tw-gap-y-1 {
  row-gap: 0.25rem !important;
}

.tw-gap-y-1\.5 {
  row-gap: 0.375rem !important;
}

.tw-gap-y-10 {
  row-gap: 2.5rem !important;
}

.tw-gap-y-11 {
  row-gap: 2.75rem !important;
}

.tw-gap-y-12 {
  row-gap: 3rem !important;
}

.tw-gap-y-14 {
  row-gap: 3.5rem !important;
}

.tw-gap-y-16 {
  row-gap: 4rem !important;
}

.tw-gap-y-2 {
  row-gap: 0.5rem !important;
}

.tw-gap-y-2\.5 {
  row-gap: 0.625rem !important;
}

.tw-gap-y-20 {
  row-gap: 5rem !important;
}

.tw-gap-y-24 {
  row-gap: 6rem !important;
}

.tw-gap-y-28 {
  row-gap: 7rem !important;
}

.tw-gap-y-3 {
  row-gap: 0.75rem !important;
}

.tw-gap-y-3\.5 {
  row-gap: 0.875rem !important;
}

.tw-gap-y-32 {
  row-gap: 8rem !important;
}

.tw-gap-y-36 {
  row-gap: 9rem !important;
}

.tw-gap-y-4 {
  row-gap: 1rem !important;
}

.tw-gap-y-40 {
  row-gap: 10rem !important;
}

.tw-gap-y-44 {
  row-gap: 11rem !important;
}

.tw-gap-y-48 {
  row-gap: 12rem !important;
}

.tw-gap-y-5 {
  row-gap: 1.25rem !important;
}

.tw-gap-y-52 {
  row-gap: 13rem !important;
}

.tw-gap-y-56 {
  row-gap: 14rem !important;
}

.tw-gap-y-6 {
  row-gap: 1.5rem !important;
}

.tw-gap-y-60 {
  row-gap: 15rem !important;
}

.tw-gap-y-64 {
  row-gap: 16rem !important;
}

.tw-gap-y-7 {
  row-gap: 1.75rem !important;
}

.tw-gap-y-72 {
  row-gap: 18rem !important;
}

.tw-gap-y-8 {
  row-gap: 2rem !important;
}

.tw-gap-y-80 {
  row-gap: 20rem !important;
}

.tw-gap-y-9 {
  row-gap: 2.25rem !important;
}

.tw-gap-y-96 {
  row-gap: 24rem !important;
}

.tw-gap-y-px {
  row-gap: 1px !important;
}

.-tw-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.-tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0px * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
}

.-tw-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
}

.tw--space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(-1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(-1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw--space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0px * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.125rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.25rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.375rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.5rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.75rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-3.5rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-4rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.5rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.625rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-5rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-6rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-7rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.75rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-0.875rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-8rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-9rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-10rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-11rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-12rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.25rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-13rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-14rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.5rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-15rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-16rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1.75rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-18rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-20rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-2.25rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-24rem * var(--tw-space-y-reverse)) !important;
}

.tw--space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(-1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(-1px * var(--tw-space-y-reverse)) !important;
}

.tw-space-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.125rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.125rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.375rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.375rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(3.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(3.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(4rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(4rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.625rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.625rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(6rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(6rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(7rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(7rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(0.875rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(0.875rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(8rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(8rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(9rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(9rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(10rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(10rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(11rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(11rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(12rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(12rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(13rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(13rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(14rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(14rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.5rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.5rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(15rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(15rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(16rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(16rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1.75rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1.75rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(18rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(18rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(20rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(20rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(2.25rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(2.25rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(24rem * var(--tw-space-x-reverse)) !important;
  margin-left: calc(24rem * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-x-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0 !important;
  margin-right: calc(1px * var(--tw-space-x-reverse)) !important;
  margin-left: calc(1px * calc(1 - var(--tw-space-x-reverse))) !important;
}

.tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.125rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.125rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-1 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.375rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.375rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-11 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.75rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-12 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-14 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(3.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(3.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-16 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(4rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(4rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.625rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.625rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-24 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(6rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(6rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-28 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(7rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(7rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-3 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.75rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(0.875rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(0.875rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-32 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(8rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(8rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-36 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(9rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(9rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(10rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(10rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-44 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(11rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(11rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-48 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(12rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(12rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-52 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(13rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(13rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-56 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(14rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(14rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-6 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.5rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.5rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(15rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(15rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-64 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(16rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(16rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-7 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1.75rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1.75rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-72 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(18rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(18rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(20rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(20rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-9 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(2.25rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(2.25rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-96 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(24rem * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(24rem * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-px > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0 !important;
  margin-top: calc(1px * calc(1 - var(--tw-space-y-reverse))) !important;
  margin-bottom: calc(1px * var(--tw-space-y-reverse)) !important;
}

.tw-space-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 1 !important;
}

.tw-space-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 1 !important;
}

.tw-divide-x > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(1px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(1px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.tw-divide-x-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(0px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(0px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.tw-divide-x-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(2px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(2px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.tw-divide-x-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(4px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(4px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.tw-divide-x-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 0 !important;
  border-right-width: calc(8px * var(--tw-divide-x-reverse)) !important;
  border-left-width: calc(8px * calc(1 - var(--tw-divide-x-reverse))) !important;
}

.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse)) !important;
}

.tw-divide-y-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(0px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(0px * var(--tw-divide-y-reverse)) !important;
}

.tw-divide-y-2 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(2px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(2px * var(--tw-divide-y-reverse)) !important;
}

.tw-divide-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(4px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(4px * var(--tw-divide-y-reverse)) !important;
}

.tw-divide-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0 !important;
  border-top-width: calc(8px * calc(1 - var(--tw-divide-y-reverse))) !important;
  border-bottom-width: calc(8px * var(--tw-divide-y-reverse)) !important;
}

.tw-divide-y-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 1 !important;
}

.tw-divide-x-reverse > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-x-reverse: 1 !important;
}

.tw-divide-solid > :not([hidden]) ~ :not([hidden]) {
  border-style: solid !important;
}

.tw-divide-dashed > :not([hidden]) ~ :not([hidden]) {
  border-style: dashed !important;
}

.tw-divide-dotted > :not([hidden]) ~ :not([hidden]) {
  border-style: dotted !important;
}

.tw-divide-double > :not([hidden]) ~ :not([hidden]) {
  border-style: double !important;
}

.tw-divide-none > :not([hidden]) ~ :not([hidden]) {
  border-style: none !important;
}

.tw-divide-amber-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 243 199 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0) !important;
}

.tw-divide-amber-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.1) !important;
}

.tw-divide-amber-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 1) !important;
}

.tw-divide-amber-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.15) !important;
}

.tw-divide-amber-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.2) !important;
}

.tw-divide-amber-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.25) !important;
}

.tw-divide-amber-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.3) !important;
}

.tw-divide-amber-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.35) !important;
}

.tw-divide-amber-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.4) !important;
}

.tw-divide-amber-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.45) !important;
}

.tw-divide-amber-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.05) !important;
}

.tw-divide-amber-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.5) !important;
}

.tw-divide-amber-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.55) !important;
}

.tw-divide-amber-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.6) !important;
}

.tw-divide-amber-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.65) !important;
}

.tw-divide-amber-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.7) !important;
}

.tw-divide-amber-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.75) !important;
}

.tw-divide-amber-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.8) !important;
}

.tw-divide-amber-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.85) !important;
}

.tw-divide-amber-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.9) !important;
}

.tw-divide-amber-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 243 199 / 0.95) !important;
}

.tw-divide-amber-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(253 230 138 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0) !important;
}

.tw-divide-amber-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.1) !important;
}

.tw-divide-amber-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 1) !important;
}

.tw-divide-amber-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.15) !important;
}

.tw-divide-amber-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.2) !important;
}

.tw-divide-amber-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.25) !important;
}

.tw-divide-amber-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.3) !important;
}

.tw-divide-amber-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.35) !important;
}

.tw-divide-amber-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.4) !important;
}

.tw-divide-amber-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.45) !important;
}

.tw-divide-amber-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.05) !important;
}

.tw-divide-amber-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.5) !important;
}

.tw-divide-amber-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.55) !important;
}

.tw-divide-amber-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.6) !important;
}

.tw-divide-amber-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.65) !important;
}

.tw-divide-amber-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.7) !important;
}

.tw-divide-amber-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.75) !important;
}

.tw-divide-amber-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.8) !important;
}

.tw-divide-amber-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.85) !important;
}

.tw-divide-amber-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.9) !important;
}

.tw-divide-amber-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 230 138 / 0.95) !important;
}

.tw-divide-amber-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(252 211 77 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0) !important;
}

.tw-divide-amber-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.1) !important;
}

.tw-divide-amber-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 1) !important;
}

.tw-divide-amber-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.15) !important;
}

.tw-divide-amber-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.2) !important;
}

.tw-divide-amber-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.25) !important;
}

.tw-divide-amber-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.3) !important;
}

.tw-divide-amber-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.35) !important;
}

.tw-divide-amber-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.4) !important;
}

.tw-divide-amber-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.45) !important;
}

.tw-divide-amber-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.05) !important;
}

.tw-divide-amber-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.5) !important;
}

.tw-divide-amber-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.55) !important;
}

.tw-divide-amber-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.6) !important;
}

.tw-divide-amber-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.65) !important;
}

.tw-divide-amber-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.7) !important;
}

.tw-divide-amber-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.75) !important;
}

.tw-divide-amber-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.8) !important;
}

.tw-divide-amber-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.85) !important;
}

.tw-divide-amber-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.9) !important;
}

.tw-divide-amber-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 211 77 / 0.95) !important;
}

.tw-divide-amber-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(251 191 36 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0) !important;
}

.tw-divide-amber-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.1) !important;
}

.tw-divide-amber-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 1) !important;
}

.tw-divide-amber-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.15) !important;
}

.tw-divide-amber-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.2) !important;
}

.tw-divide-amber-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.25) !important;
}

.tw-divide-amber-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.3) !important;
}

.tw-divide-amber-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.35) !important;
}

.tw-divide-amber-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.4) !important;
}

.tw-divide-amber-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.45) !important;
}

.tw-divide-amber-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.05) !important;
}

.tw-divide-amber-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.5) !important;
}

.tw-divide-amber-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.55) !important;
}

.tw-divide-amber-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.6) !important;
}

.tw-divide-amber-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.65) !important;
}

.tw-divide-amber-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.7) !important;
}

.tw-divide-amber-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.75) !important;
}

.tw-divide-amber-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.8) !important;
}

.tw-divide-amber-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.85) !important;
}

.tw-divide-amber-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.9) !important;
}

.tw-divide-amber-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 191 36 / 0.95) !important;
}

.tw-divide-amber-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 251 235 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0) !important;
}

.tw-divide-amber-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.1) !important;
}

.tw-divide-amber-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 1) !important;
}

.tw-divide-amber-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.15) !important;
}

.tw-divide-amber-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.2) !important;
}

.tw-divide-amber-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.25) !important;
}

.tw-divide-amber-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.3) !important;
}

.tw-divide-amber-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.35) !important;
}

.tw-divide-amber-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.4) !important;
}

.tw-divide-amber-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.45) !important;
}

.tw-divide-amber-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.05) !important;
}

.tw-divide-amber-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.5) !important;
}

.tw-divide-amber-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.55) !important;
}

.tw-divide-amber-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.6) !important;
}

.tw-divide-amber-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.65) !important;
}

.tw-divide-amber-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.7) !important;
}

.tw-divide-amber-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.75) !important;
}

.tw-divide-amber-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.8) !important;
}

.tw-divide-amber-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.85) !important;
}

.tw-divide-amber-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.9) !important;
}

.tw-divide-amber-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 251 235 / 0.95) !important;
}

.tw-divide-amber-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(245 158 11 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0) !important;
}

.tw-divide-amber-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.1) !important;
}

.tw-divide-amber-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 1) !important;
}

.tw-divide-amber-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.15) !important;
}

.tw-divide-amber-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.2) !important;
}

.tw-divide-amber-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.25) !important;
}

.tw-divide-amber-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.3) !important;
}

.tw-divide-amber-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.35) !important;
}

.tw-divide-amber-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.4) !important;
}

.tw-divide-amber-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.45) !important;
}

.tw-divide-amber-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.05) !important;
}

.tw-divide-amber-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.5) !important;
}

.tw-divide-amber-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.55) !important;
}

.tw-divide-amber-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.6) !important;
}

.tw-divide-amber-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.65) !important;
}

.tw-divide-amber-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.7) !important;
}

.tw-divide-amber-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.75) !important;
}

.tw-divide-amber-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.8) !important;
}

.tw-divide-amber-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.85) !important;
}

.tw-divide-amber-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.9) !important;
}

.tw-divide-amber-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 158 11 / 0.95) !important;
}

.tw-divide-amber-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(217 119 6 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0) !important;
}

.tw-divide-amber-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.1) !important;
}

.tw-divide-amber-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 1) !important;
}

.tw-divide-amber-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.15) !important;
}

.tw-divide-amber-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.2) !important;
}

.tw-divide-amber-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.25) !important;
}

.tw-divide-amber-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.3) !important;
}

.tw-divide-amber-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.35) !important;
}

.tw-divide-amber-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.4) !important;
}

.tw-divide-amber-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.45) !important;
}

.tw-divide-amber-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.05) !important;
}

.tw-divide-amber-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.5) !important;
}

.tw-divide-amber-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.55) !important;
}

.tw-divide-amber-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.6) !important;
}

.tw-divide-amber-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.65) !important;
}

.tw-divide-amber-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.7) !important;
}

.tw-divide-amber-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.75) !important;
}

.tw-divide-amber-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.8) !important;
}

.tw-divide-amber-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.85) !important;
}

.tw-divide-amber-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.9) !important;
}

.tw-divide-amber-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 119 6 / 0.95) !important;
}

.tw-divide-amber-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(180 83 9 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0) !important;
}

.tw-divide-amber-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.1) !important;
}

.tw-divide-amber-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 1) !important;
}

.tw-divide-amber-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.15) !important;
}

.tw-divide-amber-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.2) !important;
}

.tw-divide-amber-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.25) !important;
}

.tw-divide-amber-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.3) !important;
}

.tw-divide-amber-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.35) !important;
}

.tw-divide-amber-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.4) !important;
}

.tw-divide-amber-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.45) !important;
}

.tw-divide-amber-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.05) !important;
}

.tw-divide-amber-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.5) !important;
}

.tw-divide-amber-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.55) !important;
}

.tw-divide-amber-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.6) !important;
}

.tw-divide-amber-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.65) !important;
}

.tw-divide-amber-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.7) !important;
}

.tw-divide-amber-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.75) !important;
}

.tw-divide-amber-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.8) !important;
}

.tw-divide-amber-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.85) !important;
}

.tw-divide-amber-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.9) !important;
}

.tw-divide-amber-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(180 83 9 / 0.95) !important;
}

.tw-divide-amber-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(146 64 14 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0) !important;
}

.tw-divide-amber-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.1) !important;
}

.tw-divide-amber-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 1) !important;
}

.tw-divide-amber-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.15) !important;
}

.tw-divide-amber-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.2) !important;
}

.tw-divide-amber-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.25) !important;
}

.tw-divide-amber-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.3) !important;
}

.tw-divide-amber-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.35) !important;
}

.tw-divide-amber-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.4) !important;
}

.tw-divide-amber-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.45) !important;
}

.tw-divide-amber-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.05) !important;
}

.tw-divide-amber-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.5) !important;
}

.tw-divide-amber-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.55) !important;
}

.tw-divide-amber-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.6) !important;
}

.tw-divide-amber-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.65) !important;
}

.tw-divide-amber-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.7) !important;
}

.tw-divide-amber-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.75) !important;
}

.tw-divide-amber-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.8) !important;
}

.tw-divide-amber-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.85) !important;
}

.tw-divide-amber-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.9) !important;
}

.tw-divide-amber-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(146 64 14 / 0.95) !important;
}

.tw-divide-amber-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(120 53 15 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0) !important;
}

.tw-divide-amber-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.1) !important;
}

.tw-divide-amber-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 1) !important;
}

.tw-divide-amber-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.15) !important;
}

.tw-divide-amber-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.2) !important;
}

.tw-divide-amber-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.25) !important;
}

.tw-divide-amber-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.3) !important;
}

.tw-divide-amber-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.35) !important;
}

.tw-divide-amber-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.4) !important;
}

.tw-divide-amber-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.45) !important;
}

.tw-divide-amber-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.05) !important;
}

.tw-divide-amber-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.5) !important;
}

.tw-divide-amber-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.55) !important;
}

.tw-divide-amber-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.6) !important;
}

.tw-divide-amber-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.65) !important;
}

.tw-divide-amber-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.7) !important;
}

.tw-divide-amber-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.75) !important;
}

.tw-divide-amber-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.8) !important;
}

.tw-divide-amber-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.85) !important;
}

.tw-divide-amber-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.9) !important;
}

.tw-divide-amber-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 53 15 / 0.95) !important;
}

.tw-divide-amber-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(69 26 3 / var(--tw-divide-opacity)) !important;
}

.tw-divide-amber-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0) !important;
}

.tw-divide-amber-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.1) !important;
}

.tw-divide-amber-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 1) !important;
}

.tw-divide-amber-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.15) !important;
}

.tw-divide-amber-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.2) !important;
}

.tw-divide-amber-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.25) !important;
}

.tw-divide-amber-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.3) !important;
}

.tw-divide-amber-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.35) !important;
}

.tw-divide-amber-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.4) !important;
}

.tw-divide-amber-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.45) !important;
}

.tw-divide-amber-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.05) !important;
}

.tw-divide-amber-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.5) !important;
}

.tw-divide-amber-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.55) !important;
}

.tw-divide-amber-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.6) !important;
}

.tw-divide-amber-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.65) !important;
}

.tw-divide-amber-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.7) !important;
}

.tw-divide-amber-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.75) !important;
}

.tw-divide-amber-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.8) !important;
}

.tw-divide-amber-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.85) !important;
}

.tw-divide-amber-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.9) !important;
}

.tw-divide-amber-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 26 3 / 0.95) !important;
}

.tw-divide-black > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-divide-opacity)) !important;
}

.tw-divide-black\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0) !important;
}

.tw-divide-black\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.1) !important;
}

.tw-divide-black\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 1) !important;
}

.tw-divide-black\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.15) !important;
}

.tw-divide-black\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.2) !important;
}

.tw-divide-black\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.25) !important;
}

.tw-divide-black\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.3) !important;
}

.tw-divide-black\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.35) !important;
}

.tw-divide-black\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.4) !important;
}

.tw-divide-black\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.45) !important;
}

.tw-divide-black\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.05) !important;
}

.tw-divide-black\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.5) !important;
}

.tw-divide-black\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.55) !important;
}

.tw-divide-black\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.6) !important;
}

.tw-divide-black\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.65) !important;
}

.tw-divide-black\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.7) !important;
}

.tw-divide-black\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.75) !important;
}

.tw-divide-black\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.8) !important;
}

.tw-divide-black\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.85) !important;
}

.tw-divide-black\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.9) !important;
}

.tw-divide-black\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.95) !important;
}

.tw-divide-blue-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(219 234 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0) !important;
}

.tw-divide-blue-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.1) !important;
}

.tw-divide-blue-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 1) !important;
}

.tw-divide-blue-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.15) !important;
}

.tw-divide-blue-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.2) !important;
}

.tw-divide-blue-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.25) !important;
}

.tw-divide-blue-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.3) !important;
}

.tw-divide-blue-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.35) !important;
}

.tw-divide-blue-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.4) !important;
}

.tw-divide-blue-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.45) !important;
}

.tw-divide-blue-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.05) !important;
}

.tw-divide-blue-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.5) !important;
}

.tw-divide-blue-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.55) !important;
}

.tw-divide-blue-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.6) !important;
}

.tw-divide-blue-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.65) !important;
}

.tw-divide-blue-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.7) !important;
}

.tw-divide-blue-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.75) !important;
}

.tw-divide-blue-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.8) !important;
}

.tw-divide-blue-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.85) !important;
}

.tw-divide-blue-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.9) !important;
}

.tw-divide-blue-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 234 254 / 0.95) !important;
}

.tw-divide-blue-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(191 219 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0) !important;
}

.tw-divide-blue-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.1) !important;
}

.tw-divide-blue-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 1) !important;
}

.tw-divide-blue-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.15) !important;
}

.tw-divide-blue-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.2) !important;
}

.tw-divide-blue-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.25) !important;
}

.tw-divide-blue-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.3) !important;
}

.tw-divide-blue-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.35) !important;
}

.tw-divide-blue-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.4) !important;
}

.tw-divide-blue-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.45) !important;
}

.tw-divide-blue-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.05) !important;
}

.tw-divide-blue-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.5) !important;
}

.tw-divide-blue-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.55) !important;
}

.tw-divide-blue-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.6) !important;
}

.tw-divide-blue-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.65) !important;
}

.tw-divide-blue-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.7) !important;
}

.tw-divide-blue-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.75) !important;
}

.tw-divide-blue-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.8) !important;
}

.tw-divide-blue-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.85) !important;
}

.tw-divide-blue-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.9) !important;
}

.tw-divide-blue-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(191 219 254 / 0.95) !important;
}

.tw-divide-blue-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(147 197 253 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0) !important;
}

.tw-divide-blue-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.1) !important;
}

.tw-divide-blue-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 1) !important;
}

.tw-divide-blue-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.15) !important;
}

.tw-divide-blue-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.2) !important;
}

.tw-divide-blue-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.25) !important;
}

.tw-divide-blue-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.3) !important;
}

.tw-divide-blue-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.35) !important;
}

.tw-divide-blue-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.4) !important;
}

.tw-divide-blue-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.45) !important;
}

.tw-divide-blue-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.05) !important;
}

.tw-divide-blue-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.5) !important;
}

.tw-divide-blue-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.55) !important;
}

.tw-divide-blue-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.6) !important;
}

.tw-divide-blue-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.65) !important;
}

.tw-divide-blue-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.7) !important;
}

.tw-divide-blue-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.75) !important;
}

.tw-divide-blue-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.8) !important;
}

.tw-divide-blue-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.85) !important;
}

.tw-divide-blue-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.9) !important;
}

.tw-divide-blue-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 197 253 / 0.95) !important;
}

.tw-divide-blue-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(96 165 250 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0) !important;
}

.tw-divide-blue-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.1) !important;
}

.tw-divide-blue-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 1) !important;
}

.tw-divide-blue-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.15) !important;
}

.tw-divide-blue-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.2) !important;
}

.tw-divide-blue-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.25) !important;
}

.tw-divide-blue-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.3) !important;
}

.tw-divide-blue-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.35) !important;
}

.tw-divide-blue-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.4) !important;
}

.tw-divide-blue-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.45) !important;
}

.tw-divide-blue-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.05) !important;
}

.tw-divide-blue-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.5) !important;
}

.tw-divide-blue-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.55) !important;
}

.tw-divide-blue-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.6) !important;
}

.tw-divide-blue-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.65) !important;
}

.tw-divide-blue-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.7) !important;
}

.tw-divide-blue-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.75) !important;
}

.tw-divide-blue-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.8) !important;
}

.tw-divide-blue-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.85) !important;
}

.tw-divide-blue-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.9) !important;
}

.tw-divide-blue-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(96 165 250 / 0.95) !important;
}

.tw-divide-blue-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(239 246 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0) !important;
}

.tw-divide-blue-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.1) !important;
}

.tw-divide-blue-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 1) !important;
}

.tw-divide-blue-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.15) !important;
}

.tw-divide-blue-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.2) !important;
}

.tw-divide-blue-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.25) !important;
}

.tw-divide-blue-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.3) !important;
}

.tw-divide-blue-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.35) !important;
}

.tw-divide-blue-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.4) !important;
}

.tw-divide-blue-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.45) !important;
}

.tw-divide-blue-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.05) !important;
}

.tw-divide-blue-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.5) !important;
}

.tw-divide-blue-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.55) !important;
}

.tw-divide-blue-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.6) !important;
}

.tw-divide-blue-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.65) !important;
}

.tw-divide-blue-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.7) !important;
}

.tw-divide-blue-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.75) !important;
}

.tw-divide-blue-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.8) !important;
}

.tw-divide-blue-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.85) !important;
}

.tw-divide-blue-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.9) !important;
}

.tw-divide-blue-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 246 255 / 0.95) !important;
}

.tw-divide-blue-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(59 130 246 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0) !important;
}

.tw-divide-blue-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.1) !important;
}

.tw-divide-blue-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 1) !important;
}

.tw-divide-blue-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.15) !important;
}

.tw-divide-blue-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.2) !important;
}

.tw-divide-blue-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.25) !important;
}

.tw-divide-blue-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.3) !important;
}

.tw-divide-blue-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.35) !important;
}

.tw-divide-blue-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.4) !important;
}

.tw-divide-blue-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.45) !important;
}

.tw-divide-blue-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.05) !important;
}

.tw-divide-blue-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.5) !important;
}

.tw-divide-blue-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.55) !important;
}

.tw-divide-blue-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.6) !important;
}

.tw-divide-blue-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.65) !important;
}

.tw-divide-blue-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.7) !important;
}

.tw-divide-blue-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.75) !important;
}

.tw-divide-blue-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.8) !important;
}

.tw-divide-blue-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.85) !important;
}

.tw-divide-blue-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.9) !important;
}

.tw-divide-blue-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 130 246 / 0.95) !important;
}

.tw-divide-blue-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(37 99 235 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0) !important;
}

.tw-divide-blue-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.1) !important;
}

.tw-divide-blue-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 1) !important;
}

.tw-divide-blue-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.15) !important;
}

.tw-divide-blue-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.2) !important;
}

.tw-divide-blue-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.25) !important;
}

.tw-divide-blue-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.3) !important;
}

.tw-divide-blue-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.35) !important;
}

.tw-divide-blue-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.4) !important;
}

.tw-divide-blue-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.45) !important;
}

.tw-divide-blue-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.05) !important;
}

.tw-divide-blue-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.5) !important;
}

.tw-divide-blue-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.55) !important;
}

.tw-divide-blue-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.6) !important;
}

.tw-divide-blue-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.65) !important;
}

.tw-divide-blue-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.7) !important;
}

.tw-divide-blue-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.75) !important;
}

.tw-divide-blue-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.8) !important;
}

.tw-divide-blue-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.85) !important;
}

.tw-divide-blue-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.9) !important;
}

.tw-divide-blue-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(37 99 235 / 0.95) !important;
}

.tw-divide-blue-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(29 78 216 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0) !important;
}

.tw-divide-blue-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.1) !important;
}

.tw-divide-blue-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 1) !important;
}

.tw-divide-blue-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.15) !important;
}

.tw-divide-blue-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.2) !important;
}

.tw-divide-blue-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.25) !important;
}

.tw-divide-blue-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.3) !important;
}

.tw-divide-blue-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.35) !important;
}

.tw-divide-blue-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.4) !important;
}

.tw-divide-blue-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.45) !important;
}

.tw-divide-blue-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.05) !important;
}

.tw-divide-blue-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.5) !important;
}

.tw-divide-blue-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.55) !important;
}

.tw-divide-blue-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.6) !important;
}

.tw-divide-blue-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.65) !important;
}

.tw-divide-blue-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.7) !important;
}

.tw-divide-blue-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.75) !important;
}

.tw-divide-blue-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.8) !important;
}

.tw-divide-blue-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.85) !important;
}

.tw-divide-blue-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.9) !important;
}

.tw-divide-blue-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(29 78 216 / 0.95) !important;
}

.tw-divide-blue-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(30 64 175 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0) !important;
}

.tw-divide-blue-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.1) !important;
}

.tw-divide-blue-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 1) !important;
}

.tw-divide-blue-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.15) !important;
}

.tw-divide-blue-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.2) !important;
}

.tw-divide-blue-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.25) !important;
}

.tw-divide-blue-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.3) !important;
}

.tw-divide-blue-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.35) !important;
}

.tw-divide-blue-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.4) !important;
}

.tw-divide-blue-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.45) !important;
}

.tw-divide-blue-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.05) !important;
}

.tw-divide-blue-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.5) !important;
}

.tw-divide-blue-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.55) !important;
}

.tw-divide-blue-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.6) !important;
}

.tw-divide-blue-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.65) !important;
}

.tw-divide-blue-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.7) !important;
}

.tw-divide-blue-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.75) !important;
}

.tw-divide-blue-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.8) !important;
}

.tw-divide-blue-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.85) !important;
}

.tw-divide-blue-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.9) !important;
}

.tw-divide-blue-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 64 175 / 0.95) !important;
}

.tw-divide-blue-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(30 58 138 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0) !important;
}

.tw-divide-blue-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.1) !important;
}

.tw-divide-blue-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 1) !important;
}

.tw-divide-blue-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.15) !important;
}

.tw-divide-blue-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.2) !important;
}

.tw-divide-blue-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.25) !important;
}

.tw-divide-blue-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.3) !important;
}

.tw-divide-blue-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.35) !important;
}

.tw-divide-blue-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.4) !important;
}

.tw-divide-blue-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.45) !important;
}

.tw-divide-blue-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.05) !important;
}

.tw-divide-blue-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.5) !important;
}

.tw-divide-blue-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.55) !important;
}

.tw-divide-blue-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.6) !important;
}

.tw-divide-blue-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.65) !important;
}

.tw-divide-blue-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.7) !important;
}

.tw-divide-blue-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.75) !important;
}

.tw-divide-blue-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.8) !important;
}

.tw-divide-blue-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.85) !important;
}

.tw-divide-blue-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.9) !important;
}

.tw-divide-blue-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 58 138 / 0.95) !important;
}

.tw-divide-blue-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(23 37 84 / var(--tw-divide-opacity)) !important;
}

.tw-divide-blue-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0) !important;
}

.tw-divide-blue-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.1) !important;
}

.tw-divide-blue-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 1) !important;
}

.tw-divide-blue-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.15) !important;
}

.tw-divide-blue-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.2) !important;
}

.tw-divide-blue-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.25) !important;
}

.tw-divide-blue-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.3) !important;
}

.tw-divide-blue-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.35) !important;
}

.tw-divide-blue-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.4) !important;
}

.tw-divide-blue-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.45) !important;
}

.tw-divide-blue-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.05) !important;
}

.tw-divide-blue-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.5) !important;
}

.tw-divide-blue-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.55) !important;
}

.tw-divide-blue-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.6) !important;
}

.tw-divide-blue-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.65) !important;
}

.tw-divide-blue-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.7) !important;
}

.tw-divide-blue-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.75) !important;
}

.tw-divide-blue-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.8) !important;
}

.tw-divide-blue-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.85) !important;
}

.tw-divide-blue-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.9) !important;
}

.tw-divide-blue-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 37 84 / 0.95) !important;
}

.tw-divide-current > :not([hidden]) ~ :not([hidden]) {
  border-color: currentColor !important;
}

.tw-divide-cyan-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(207 250 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0) !important;
}

.tw-divide-cyan-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.1) !important;
}

.tw-divide-cyan-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 1) !important;
}

.tw-divide-cyan-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.15) !important;
}

.tw-divide-cyan-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.2) !important;
}

.tw-divide-cyan-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.25) !important;
}

.tw-divide-cyan-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.3) !important;
}

.tw-divide-cyan-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.35) !important;
}

.tw-divide-cyan-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.4) !important;
}

.tw-divide-cyan-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.45) !important;
}

.tw-divide-cyan-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.05) !important;
}

.tw-divide-cyan-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.5) !important;
}

.tw-divide-cyan-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.55) !important;
}

.tw-divide-cyan-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.6) !important;
}

.tw-divide-cyan-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.65) !important;
}

.tw-divide-cyan-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.7) !important;
}

.tw-divide-cyan-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.75) !important;
}

.tw-divide-cyan-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.8) !important;
}

.tw-divide-cyan-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.85) !important;
}

.tw-divide-cyan-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.9) !important;
}

.tw-divide-cyan-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(207 250 254 / 0.95) !important;
}

.tw-divide-cyan-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(165 243 252 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0) !important;
}

.tw-divide-cyan-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.1) !important;
}

.tw-divide-cyan-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 1) !important;
}

.tw-divide-cyan-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.15) !important;
}

.tw-divide-cyan-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.2) !important;
}

.tw-divide-cyan-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.25) !important;
}

.tw-divide-cyan-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.3) !important;
}

.tw-divide-cyan-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.35) !important;
}

.tw-divide-cyan-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.4) !important;
}

.tw-divide-cyan-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.45) !important;
}

.tw-divide-cyan-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.05) !important;
}

.tw-divide-cyan-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.5) !important;
}

.tw-divide-cyan-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.55) !important;
}

.tw-divide-cyan-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.6) !important;
}

.tw-divide-cyan-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.65) !important;
}

.tw-divide-cyan-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.7) !important;
}

.tw-divide-cyan-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.75) !important;
}

.tw-divide-cyan-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.8) !important;
}

.tw-divide-cyan-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.85) !important;
}

.tw-divide-cyan-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.9) !important;
}

.tw-divide-cyan-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 243 252 / 0.95) !important;
}

.tw-divide-cyan-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(103 232 249 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0) !important;
}

.tw-divide-cyan-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.1) !important;
}

.tw-divide-cyan-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 1) !important;
}

.tw-divide-cyan-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.15) !important;
}

.tw-divide-cyan-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.2) !important;
}

.tw-divide-cyan-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.25) !important;
}

.tw-divide-cyan-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.3) !important;
}

.tw-divide-cyan-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.35) !important;
}

.tw-divide-cyan-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.4) !important;
}

.tw-divide-cyan-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.45) !important;
}

.tw-divide-cyan-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.05) !important;
}

.tw-divide-cyan-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.5) !important;
}

.tw-divide-cyan-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.55) !important;
}

.tw-divide-cyan-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.6) !important;
}

.tw-divide-cyan-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.65) !important;
}

.tw-divide-cyan-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.7) !important;
}

.tw-divide-cyan-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.75) !important;
}

.tw-divide-cyan-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.8) !important;
}

.tw-divide-cyan-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.85) !important;
}

.tw-divide-cyan-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.9) !important;
}

.tw-divide-cyan-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(103 232 249 / 0.95) !important;
}

.tw-divide-cyan-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(34 211 238 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0) !important;
}

.tw-divide-cyan-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.1) !important;
}

.tw-divide-cyan-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 1) !important;
}

.tw-divide-cyan-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.15) !important;
}

.tw-divide-cyan-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.2) !important;
}

.tw-divide-cyan-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.25) !important;
}

.tw-divide-cyan-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.3) !important;
}

.tw-divide-cyan-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.35) !important;
}

.tw-divide-cyan-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.4) !important;
}

.tw-divide-cyan-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.45) !important;
}

.tw-divide-cyan-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.05) !important;
}

.tw-divide-cyan-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.5) !important;
}

.tw-divide-cyan-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.55) !important;
}

.tw-divide-cyan-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.6) !important;
}

.tw-divide-cyan-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.65) !important;
}

.tw-divide-cyan-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.7) !important;
}

.tw-divide-cyan-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.75) !important;
}

.tw-divide-cyan-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.8) !important;
}

.tw-divide-cyan-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.85) !important;
}

.tw-divide-cyan-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.9) !important;
}

.tw-divide-cyan-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 211 238 / 0.95) !important;
}

.tw-divide-cyan-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(236 254 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0) !important;
}

.tw-divide-cyan-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.1) !important;
}

.tw-divide-cyan-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 1) !important;
}

.tw-divide-cyan-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.15) !important;
}

.tw-divide-cyan-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.2) !important;
}

.tw-divide-cyan-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.25) !important;
}

.tw-divide-cyan-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.3) !important;
}

.tw-divide-cyan-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.35) !important;
}

.tw-divide-cyan-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.4) !important;
}

.tw-divide-cyan-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.45) !important;
}

.tw-divide-cyan-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.05) !important;
}

.tw-divide-cyan-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.5) !important;
}

.tw-divide-cyan-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.55) !important;
}

.tw-divide-cyan-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.6) !important;
}

.tw-divide-cyan-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.65) !important;
}

.tw-divide-cyan-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.7) !important;
}

.tw-divide-cyan-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.75) !important;
}

.tw-divide-cyan-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.8) !important;
}

.tw-divide-cyan-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.85) !important;
}

.tw-divide-cyan-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.9) !important;
}

.tw-divide-cyan-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 254 255 / 0.95) !important;
}

.tw-divide-cyan-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(6 182 212 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0) !important;
}

.tw-divide-cyan-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.1) !important;
}

.tw-divide-cyan-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 1) !important;
}

.tw-divide-cyan-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.15) !important;
}

.tw-divide-cyan-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.2) !important;
}

.tw-divide-cyan-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.25) !important;
}

.tw-divide-cyan-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.3) !important;
}

.tw-divide-cyan-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.35) !important;
}

.tw-divide-cyan-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.4) !important;
}

.tw-divide-cyan-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.45) !important;
}

.tw-divide-cyan-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.05) !important;
}

.tw-divide-cyan-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.5) !important;
}

.tw-divide-cyan-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.55) !important;
}

.tw-divide-cyan-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.6) !important;
}

.tw-divide-cyan-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.65) !important;
}

.tw-divide-cyan-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.7) !important;
}

.tw-divide-cyan-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.75) !important;
}

.tw-divide-cyan-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.8) !important;
}

.tw-divide-cyan-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.85) !important;
}

.tw-divide-cyan-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.9) !important;
}

.tw-divide-cyan-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 182 212 / 0.95) !important;
}

.tw-divide-cyan-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(8 145 178 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0) !important;
}

.tw-divide-cyan-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.1) !important;
}

.tw-divide-cyan-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 1) !important;
}

.tw-divide-cyan-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.15) !important;
}

.tw-divide-cyan-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.2) !important;
}

.tw-divide-cyan-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.25) !important;
}

.tw-divide-cyan-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.3) !important;
}

.tw-divide-cyan-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.35) !important;
}

.tw-divide-cyan-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.4) !important;
}

.tw-divide-cyan-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.45) !important;
}

.tw-divide-cyan-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.05) !important;
}

.tw-divide-cyan-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.5) !important;
}

.tw-divide-cyan-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.55) !important;
}

.tw-divide-cyan-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.6) !important;
}

.tw-divide-cyan-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.65) !important;
}

.tw-divide-cyan-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.7) !important;
}

.tw-divide-cyan-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.75) !important;
}

.tw-divide-cyan-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.8) !important;
}

.tw-divide-cyan-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.85) !important;
}

.tw-divide-cyan-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.9) !important;
}

.tw-divide-cyan-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 145 178 / 0.95) !important;
}

.tw-divide-cyan-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(14 116 144 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0) !important;
}

.tw-divide-cyan-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.1) !important;
}

.tw-divide-cyan-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 1) !important;
}

.tw-divide-cyan-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.15) !important;
}

.tw-divide-cyan-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.2) !important;
}

.tw-divide-cyan-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.25) !important;
}

.tw-divide-cyan-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.3) !important;
}

.tw-divide-cyan-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.35) !important;
}

.tw-divide-cyan-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.4) !important;
}

.tw-divide-cyan-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.45) !important;
}

.tw-divide-cyan-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.05) !important;
}

.tw-divide-cyan-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.5) !important;
}

.tw-divide-cyan-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.55) !important;
}

.tw-divide-cyan-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.6) !important;
}

.tw-divide-cyan-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.65) !important;
}

.tw-divide-cyan-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.7) !important;
}

.tw-divide-cyan-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.75) !important;
}

.tw-divide-cyan-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.8) !important;
}

.tw-divide-cyan-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.85) !important;
}

.tw-divide-cyan-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.9) !important;
}

.tw-divide-cyan-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 116 144 / 0.95) !important;
}

.tw-divide-cyan-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(21 94 117 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0) !important;
}

.tw-divide-cyan-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.1) !important;
}

.tw-divide-cyan-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 1) !important;
}

.tw-divide-cyan-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.15) !important;
}

.tw-divide-cyan-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.2) !important;
}

.tw-divide-cyan-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.25) !important;
}

.tw-divide-cyan-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.3) !important;
}

.tw-divide-cyan-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.35) !important;
}

.tw-divide-cyan-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.4) !important;
}

.tw-divide-cyan-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.45) !important;
}

.tw-divide-cyan-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.05) !important;
}

.tw-divide-cyan-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.5) !important;
}

.tw-divide-cyan-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.55) !important;
}

.tw-divide-cyan-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.6) !important;
}

.tw-divide-cyan-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.65) !important;
}

.tw-divide-cyan-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.7) !important;
}

.tw-divide-cyan-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.75) !important;
}

.tw-divide-cyan-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.8) !important;
}

.tw-divide-cyan-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.85) !important;
}

.tw-divide-cyan-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.9) !important;
}

.tw-divide-cyan-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 94 117 / 0.95) !important;
}

.tw-divide-cyan-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(22 78 99 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0) !important;
}

.tw-divide-cyan-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.1) !important;
}

.tw-divide-cyan-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 1) !important;
}

.tw-divide-cyan-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.15) !important;
}

.tw-divide-cyan-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.2) !important;
}

.tw-divide-cyan-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.25) !important;
}

.tw-divide-cyan-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.3) !important;
}

.tw-divide-cyan-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.35) !important;
}

.tw-divide-cyan-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.4) !important;
}

.tw-divide-cyan-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.45) !important;
}

.tw-divide-cyan-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.05) !important;
}

.tw-divide-cyan-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.5) !important;
}

.tw-divide-cyan-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.55) !important;
}

.tw-divide-cyan-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.6) !important;
}

.tw-divide-cyan-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.65) !important;
}

.tw-divide-cyan-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.7) !important;
}

.tw-divide-cyan-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.75) !important;
}

.tw-divide-cyan-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.8) !important;
}

.tw-divide-cyan-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.85) !important;
}

.tw-divide-cyan-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.9) !important;
}

.tw-divide-cyan-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 78 99 / 0.95) !important;
}

.tw-divide-cyan-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(8 51 68 / var(--tw-divide-opacity)) !important;
}

.tw-divide-cyan-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0) !important;
}

.tw-divide-cyan-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.1) !important;
}

.tw-divide-cyan-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 1) !important;
}

.tw-divide-cyan-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.15) !important;
}

.tw-divide-cyan-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.2) !important;
}

.tw-divide-cyan-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.25) !important;
}

.tw-divide-cyan-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.3) !important;
}

.tw-divide-cyan-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.35) !important;
}

.tw-divide-cyan-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.4) !important;
}

.tw-divide-cyan-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.45) !important;
}

.tw-divide-cyan-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.05) !important;
}

.tw-divide-cyan-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.5) !important;
}

.tw-divide-cyan-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.55) !important;
}

.tw-divide-cyan-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.6) !important;
}

.tw-divide-cyan-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.65) !important;
}

.tw-divide-cyan-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.7) !important;
}

.tw-divide-cyan-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.75) !important;
}

.tw-divide-cyan-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.8) !important;
}

.tw-divide-cyan-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.85) !important;
}

.tw-divide-cyan-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.9) !important;
}

.tw-divide-cyan-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 51 68 / 0.95) !important;
}

.tw-divide-emerald-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(209 250 229 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0) !important;
}

.tw-divide-emerald-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.1) !important;
}

.tw-divide-emerald-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 1) !important;
}

.tw-divide-emerald-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.15) !important;
}

.tw-divide-emerald-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.2) !important;
}

.tw-divide-emerald-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.25) !important;
}

.tw-divide-emerald-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.3) !important;
}

.tw-divide-emerald-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.35) !important;
}

.tw-divide-emerald-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.4) !important;
}

.tw-divide-emerald-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.45) !important;
}

.tw-divide-emerald-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.05) !important;
}

.tw-divide-emerald-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.5) !important;
}

.tw-divide-emerald-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.55) !important;
}

.tw-divide-emerald-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.6) !important;
}

.tw-divide-emerald-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.65) !important;
}

.tw-divide-emerald-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.7) !important;
}

.tw-divide-emerald-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.75) !important;
}

.tw-divide-emerald-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.8) !important;
}

.tw-divide-emerald-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.85) !important;
}

.tw-divide-emerald-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.9) !important;
}

.tw-divide-emerald-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 250 229 / 0.95) !important;
}

.tw-divide-emerald-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(167 243 208 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0) !important;
}

.tw-divide-emerald-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.1) !important;
}

.tw-divide-emerald-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 1) !important;
}

.tw-divide-emerald-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.15) !important;
}

.tw-divide-emerald-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.2) !important;
}

.tw-divide-emerald-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.25) !important;
}

.tw-divide-emerald-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.3) !important;
}

.tw-divide-emerald-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.35) !important;
}

.tw-divide-emerald-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.4) !important;
}

.tw-divide-emerald-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.45) !important;
}

.tw-divide-emerald-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.05) !important;
}

.tw-divide-emerald-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.5) !important;
}

.tw-divide-emerald-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.55) !important;
}

.tw-divide-emerald-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.6) !important;
}

.tw-divide-emerald-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.65) !important;
}

.tw-divide-emerald-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.7) !important;
}

.tw-divide-emerald-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.75) !important;
}

.tw-divide-emerald-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.8) !important;
}

.tw-divide-emerald-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.85) !important;
}

.tw-divide-emerald-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.9) !important;
}

.tw-divide-emerald-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 243 208 / 0.95) !important;
}

.tw-divide-emerald-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(110 231 183 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0) !important;
}

.tw-divide-emerald-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.1) !important;
}

.tw-divide-emerald-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 1) !important;
}

.tw-divide-emerald-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.15) !important;
}

.tw-divide-emerald-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.2) !important;
}

.tw-divide-emerald-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.25) !important;
}

.tw-divide-emerald-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.3) !important;
}

.tw-divide-emerald-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.35) !important;
}

.tw-divide-emerald-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.4) !important;
}

.tw-divide-emerald-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.45) !important;
}

.tw-divide-emerald-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.05) !important;
}

.tw-divide-emerald-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.5) !important;
}

.tw-divide-emerald-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.55) !important;
}

.tw-divide-emerald-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.6) !important;
}

.tw-divide-emerald-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.65) !important;
}

.tw-divide-emerald-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.7) !important;
}

.tw-divide-emerald-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.75) !important;
}

.tw-divide-emerald-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.8) !important;
}

.tw-divide-emerald-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.85) !important;
}

.tw-divide-emerald-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.9) !important;
}

.tw-divide-emerald-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(110 231 183 / 0.95) !important;
}

.tw-divide-emerald-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(52 211 153 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0) !important;
}

.tw-divide-emerald-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.1) !important;
}

.tw-divide-emerald-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 1) !important;
}

.tw-divide-emerald-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.15) !important;
}

.tw-divide-emerald-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.2) !important;
}

.tw-divide-emerald-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.25) !important;
}

.tw-divide-emerald-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.3) !important;
}

.tw-divide-emerald-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.35) !important;
}

.tw-divide-emerald-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.4) !important;
}

.tw-divide-emerald-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.45) !important;
}

.tw-divide-emerald-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.05) !important;
}

.tw-divide-emerald-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.5) !important;
}

.tw-divide-emerald-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.55) !important;
}

.tw-divide-emerald-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.6) !important;
}

.tw-divide-emerald-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.65) !important;
}

.tw-divide-emerald-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.7) !important;
}

.tw-divide-emerald-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.75) !important;
}

.tw-divide-emerald-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.8) !important;
}

.tw-divide-emerald-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.85) !important;
}

.tw-divide-emerald-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.9) !important;
}

.tw-divide-emerald-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(52 211 153 / 0.95) !important;
}

.tw-divide-emerald-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(236 253 245 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0) !important;
}

.tw-divide-emerald-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.1) !important;
}

.tw-divide-emerald-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 1) !important;
}

.tw-divide-emerald-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.15) !important;
}

.tw-divide-emerald-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.2) !important;
}

.tw-divide-emerald-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.25) !important;
}

.tw-divide-emerald-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.3) !important;
}

.tw-divide-emerald-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.35) !important;
}

.tw-divide-emerald-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.4) !important;
}

.tw-divide-emerald-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.45) !important;
}

.tw-divide-emerald-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.05) !important;
}

.tw-divide-emerald-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.5) !important;
}

.tw-divide-emerald-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.55) !important;
}

.tw-divide-emerald-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.6) !important;
}

.tw-divide-emerald-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.65) !important;
}

.tw-divide-emerald-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.7) !important;
}

.tw-divide-emerald-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.75) !important;
}

.tw-divide-emerald-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.8) !important;
}

.tw-divide-emerald-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.85) !important;
}

.tw-divide-emerald-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.9) !important;
}

.tw-divide-emerald-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 253 245 / 0.95) !important;
}

.tw-divide-emerald-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(16 185 129 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0) !important;
}

.tw-divide-emerald-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.1) !important;
}

.tw-divide-emerald-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 1) !important;
}

.tw-divide-emerald-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.15) !important;
}

.tw-divide-emerald-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.2) !important;
}

.tw-divide-emerald-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.25) !important;
}

.tw-divide-emerald-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.3) !important;
}

.tw-divide-emerald-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.35) !important;
}

.tw-divide-emerald-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.4) !important;
}

.tw-divide-emerald-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.45) !important;
}

.tw-divide-emerald-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.05) !important;
}

.tw-divide-emerald-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.5) !important;
}

.tw-divide-emerald-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.55) !important;
}

.tw-divide-emerald-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.6) !important;
}

.tw-divide-emerald-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.65) !important;
}

.tw-divide-emerald-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.7) !important;
}

.tw-divide-emerald-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.75) !important;
}

.tw-divide-emerald-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.8) !important;
}

.tw-divide-emerald-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.85) !important;
}

.tw-divide-emerald-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.9) !important;
}

.tw-divide-emerald-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(16 185 129 / 0.95) !important;
}

.tw-divide-emerald-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(5 150 105 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0) !important;
}

.tw-divide-emerald-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.1) !important;
}

.tw-divide-emerald-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 1) !important;
}

.tw-divide-emerald-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.15) !important;
}

.tw-divide-emerald-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.2) !important;
}

.tw-divide-emerald-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.25) !important;
}

.tw-divide-emerald-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.3) !important;
}

.tw-divide-emerald-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.35) !important;
}

.tw-divide-emerald-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.4) !important;
}

.tw-divide-emerald-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.45) !important;
}

.tw-divide-emerald-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.05) !important;
}

.tw-divide-emerald-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.5) !important;
}

.tw-divide-emerald-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.55) !important;
}

.tw-divide-emerald-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.6) !important;
}

.tw-divide-emerald-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.65) !important;
}

.tw-divide-emerald-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.7) !important;
}

.tw-divide-emerald-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.75) !important;
}

.tw-divide-emerald-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.8) !important;
}

.tw-divide-emerald-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.85) !important;
}

.tw-divide-emerald-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.9) !important;
}

.tw-divide-emerald-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 150 105 / 0.95) !important;
}

.tw-divide-emerald-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(4 120 87 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0) !important;
}

.tw-divide-emerald-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.1) !important;
}

.tw-divide-emerald-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 1) !important;
}

.tw-divide-emerald-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.15) !important;
}

.tw-divide-emerald-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.2) !important;
}

.tw-divide-emerald-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.25) !important;
}

.tw-divide-emerald-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.3) !important;
}

.tw-divide-emerald-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.35) !important;
}

.tw-divide-emerald-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.4) !important;
}

.tw-divide-emerald-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.45) !important;
}

.tw-divide-emerald-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.05) !important;
}

.tw-divide-emerald-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.5) !important;
}

.tw-divide-emerald-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.55) !important;
}

.tw-divide-emerald-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.6) !important;
}

.tw-divide-emerald-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.65) !important;
}

.tw-divide-emerald-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.7) !important;
}

.tw-divide-emerald-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.75) !important;
}

.tw-divide-emerald-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.8) !important;
}

.tw-divide-emerald-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.85) !important;
}

.tw-divide-emerald-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.9) !important;
}

.tw-divide-emerald-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 120 87 / 0.95) !important;
}

.tw-divide-emerald-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(6 95 70 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0) !important;
}

.tw-divide-emerald-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.1) !important;
}

.tw-divide-emerald-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 1) !important;
}

.tw-divide-emerald-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.15) !important;
}

.tw-divide-emerald-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.2) !important;
}

.tw-divide-emerald-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.25) !important;
}

.tw-divide-emerald-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.3) !important;
}

.tw-divide-emerald-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.35) !important;
}

.tw-divide-emerald-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.4) !important;
}

.tw-divide-emerald-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.45) !important;
}

.tw-divide-emerald-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.05) !important;
}

.tw-divide-emerald-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.5) !important;
}

.tw-divide-emerald-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.55) !important;
}

.tw-divide-emerald-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.6) !important;
}

.tw-divide-emerald-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.65) !important;
}

.tw-divide-emerald-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.7) !important;
}

.tw-divide-emerald-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.75) !important;
}

.tw-divide-emerald-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.8) !important;
}

.tw-divide-emerald-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.85) !important;
}

.tw-divide-emerald-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.9) !important;
}

.tw-divide-emerald-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 95 70 / 0.95) !important;
}

.tw-divide-emerald-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(6 78 59 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0) !important;
}

.tw-divide-emerald-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.1) !important;
}

.tw-divide-emerald-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 1) !important;
}

.tw-divide-emerald-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.15) !important;
}

.tw-divide-emerald-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.2) !important;
}

.tw-divide-emerald-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.25) !important;
}

.tw-divide-emerald-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.3) !important;
}

.tw-divide-emerald-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.35) !important;
}

.tw-divide-emerald-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.4) !important;
}

.tw-divide-emerald-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.45) !important;
}

.tw-divide-emerald-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.05) !important;
}

.tw-divide-emerald-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.5) !important;
}

.tw-divide-emerald-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.55) !important;
}

.tw-divide-emerald-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.6) !important;
}

.tw-divide-emerald-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.65) !important;
}

.tw-divide-emerald-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.7) !important;
}

.tw-divide-emerald-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.75) !important;
}

.tw-divide-emerald-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.8) !important;
}

.tw-divide-emerald-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.85) !important;
}

.tw-divide-emerald-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.9) !important;
}

.tw-divide-emerald-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(6 78 59 / 0.95) !important;
}

.tw-divide-emerald-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(2 44 34 / var(--tw-divide-opacity)) !important;
}

.tw-divide-emerald-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0) !important;
}

.tw-divide-emerald-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.1) !important;
}

.tw-divide-emerald-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 1) !important;
}

.tw-divide-emerald-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.15) !important;
}

.tw-divide-emerald-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.2) !important;
}

.tw-divide-emerald-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.25) !important;
}

.tw-divide-emerald-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.3) !important;
}

.tw-divide-emerald-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.35) !important;
}

.tw-divide-emerald-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.4) !important;
}

.tw-divide-emerald-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.45) !important;
}

.tw-divide-emerald-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.05) !important;
}

.tw-divide-emerald-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.5) !important;
}

.tw-divide-emerald-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.55) !important;
}

.tw-divide-emerald-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.6) !important;
}

.tw-divide-emerald-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.65) !important;
}

.tw-divide-emerald-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.7) !important;
}

.tw-divide-emerald-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.75) !important;
}

.tw-divide-emerald-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.8) !important;
}

.tw-divide-emerald-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.85) !important;
}

.tw-divide-emerald-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.9) !important;
}

.tw-divide-emerald-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 44 34 / 0.95) !important;
}

.tw-divide-fuchsia-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(250 232 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0) !important;
}

.tw-divide-fuchsia-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.1) !important;
}

.tw-divide-fuchsia-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 1) !important;
}

.tw-divide-fuchsia-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.15) !important;
}

.tw-divide-fuchsia-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.2) !important;
}

.tw-divide-fuchsia-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.25) !important;
}

.tw-divide-fuchsia-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.3) !important;
}

.tw-divide-fuchsia-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.35) !important;
}

.tw-divide-fuchsia-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.4) !important;
}

.tw-divide-fuchsia-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.45) !important;
}

.tw-divide-fuchsia-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.05) !important;
}

.tw-divide-fuchsia-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.5) !important;
}

.tw-divide-fuchsia-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.55) !important;
}

.tw-divide-fuchsia-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.6) !important;
}

.tw-divide-fuchsia-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.65) !important;
}

.tw-divide-fuchsia-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.7) !important;
}

.tw-divide-fuchsia-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.75) !important;
}

.tw-divide-fuchsia-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.8) !important;
}

.tw-divide-fuchsia-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.85) !important;
}

.tw-divide-fuchsia-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.9) !important;
}

.tw-divide-fuchsia-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 232 255 / 0.95) !important;
}

.tw-divide-fuchsia-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(245 208 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0) !important;
}

.tw-divide-fuchsia-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.1) !important;
}

.tw-divide-fuchsia-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 1) !important;
}

.tw-divide-fuchsia-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.15) !important;
}

.tw-divide-fuchsia-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.2) !important;
}

.tw-divide-fuchsia-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.25) !important;
}

.tw-divide-fuchsia-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.3) !important;
}

.tw-divide-fuchsia-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.35) !important;
}

.tw-divide-fuchsia-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.4) !important;
}

.tw-divide-fuchsia-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.45) !important;
}

.tw-divide-fuchsia-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.05) !important;
}

.tw-divide-fuchsia-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.5) !important;
}

.tw-divide-fuchsia-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.55) !important;
}

.tw-divide-fuchsia-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.6) !important;
}

.tw-divide-fuchsia-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.65) !important;
}

.tw-divide-fuchsia-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.7) !important;
}

.tw-divide-fuchsia-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.75) !important;
}

.tw-divide-fuchsia-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.8) !important;
}

.tw-divide-fuchsia-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.85) !important;
}

.tw-divide-fuchsia-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.9) !important;
}

.tw-divide-fuchsia-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 208 254 / 0.95) !important;
}

.tw-divide-fuchsia-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(240 171 252 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0) !important;
}

.tw-divide-fuchsia-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.1) !important;
}

.tw-divide-fuchsia-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 1) !important;
}

.tw-divide-fuchsia-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.15) !important;
}

.tw-divide-fuchsia-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.2) !important;
}

.tw-divide-fuchsia-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.25) !important;
}

.tw-divide-fuchsia-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.3) !important;
}

.tw-divide-fuchsia-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.35) !important;
}

.tw-divide-fuchsia-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.4) !important;
}

.tw-divide-fuchsia-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.45) !important;
}

.tw-divide-fuchsia-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.05) !important;
}

.tw-divide-fuchsia-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.5) !important;
}

.tw-divide-fuchsia-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.55) !important;
}

.tw-divide-fuchsia-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.6) !important;
}

.tw-divide-fuchsia-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.65) !important;
}

.tw-divide-fuchsia-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.7) !important;
}

.tw-divide-fuchsia-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.75) !important;
}

.tw-divide-fuchsia-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.8) !important;
}

.tw-divide-fuchsia-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.85) !important;
}

.tw-divide-fuchsia-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.9) !important;
}

.tw-divide-fuchsia-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 171 252 / 0.95) !important;
}

.tw-divide-fuchsia-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(232 121 249 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0) !important;
}

.tw-divide-fuchsia-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.1) !important;
}

.tw-divide-fuchsia-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 1) !important;
}

.tw-divide-fuchsia-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.15) !important;
}

.tw-divide-fuchsia-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.2) !important;
}

.tw-divide-fuchsia-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.25) !important;
}

.tw-divide-fuchsia-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.3) !important;
}

.tw-divide-fuchsia-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.35) !important;
}

.tw-divide-fuchsia-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.4) !important;
}

.tw-divide-fuchsia-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.45) !important;
}

.tw-divide-fuchsia-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.05) !important;
}

.tw-divide-fuchsia-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.5) !important;
}

.tw-divide-fuchsia-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.55) !important;
}

.tw-divide-fuchsia-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.6) !important;
}

.tw-divide-fuchsia-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.65) !important;
}

.tw-divide-fuchsia-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.7) !important;
}

.tw-divide-fuchsia-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.75) !important;
}

.tw-divide-fuchsia-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.8) !important;
}

.tw-divide-fuchsia-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.85) !important;
}

.tw-divide-fuchsia-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.9) !important;
}

.tw-divide-fuchsia-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(232 121 249 / 0.95) !important;
}

.tw-divide-fuchsia-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(253 244 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0) !important;
}

.tw-divide-fuchsia-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.1) !important;
}

.tw-divide-fuchsia-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 1) !important;
}

.tw-divide-fuchsia-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.15) !important;
}

.tw-divide-fuchsia-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.2) !important;
}

.tw-divide-fuchsia-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.25) !important;
}

.tw-divide-fuchsia-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.3) !important;
}

.tw-divide-fuchsia-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.35) !important;
}

.tw-divide-fuchsia-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.4) !important;
}

.tw-divide-fuchsia-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.45) !important;
}

.tw-divide-fuchsia-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.05) !important;
}

.tw-divide-fuchsia-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.5) !important;
}

.tw-divide-fuchsia-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.55) !important;
}

.tw-divide-fuchsia-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.6) !important;
}

.tw-divide-fuchsia-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.65) !important;
}

.tw-divide-fuchsia-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.7) !important;
}

.tw-divide-fuchsia-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.75) !important;
}

.tw-divide-fuchsia-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.8) !important;
}

.tw-divide-fuchsia-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.85) !important;
}

.tw-divide-fuchsia-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.9) !important;
}

.tw-divide-fuchsia-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 244 255 / 0.95) !important;
}

.tw-divide-fuchsia-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(217 70 239 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0) !important;
}

.tw-divide-fuchsia-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.1) !important;
}

.tw-divide-fuchsia-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 1) !important;
}

.tw-divide-fuchsia-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.15) !important;
}

.tw-divide-fuchsia-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.2) !important;
}

.tw-divide-fuchsia-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.25) !important;
}

.tw-divide-fuchsia-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.3) !important;
}

.tw-divide-fuchsia-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.35) !important;
}

.tw-divide-fuchsia-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.4) !important;
}

.tw-divide-fuchsia-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.45) !important;
}

.tw-divide-fuchsia-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.05) !important;
}

.tw-divide-fuchsia-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.5) !important;
}

.tw-divide-fuchsia-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.55) !important;
}

.tw-divide-fuchsia-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.6) !important;
}

.tw-divide-fuchsia-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.65) !important;
}

.tw-divide-fuchsia-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.7) !important;
}

.tw-divide-fuchsia-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.75) !important;
}

.tw-divide-fuchsia-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.8) !important;
}

.tw-divide-fuchsia-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.85) !important;
}

.tw-divide-fuchsia-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.9) !important;
}

.tw-divide-fuchsia-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 70 239 / 0.95) !important;
}

.tw-divide-fuchsia-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(192 38 211 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0) !important;
}

.tw-divide-fuchsia-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.1) !important;
}

.tw-divide-fuchsia-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 1) !important;
}

.tw-divide-fuchsia-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.15) !important;
}

.tw-divide-fuchsia-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.2) !important;
}

.tw-divide-fuchsia-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.25) !important;
}

.tw-divide-fuchsia-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.3) !important;
}

.tw-divide-fuchsia-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.35) !important;
}

.tw-divide-fuchsia-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.4) !important;
}

.tw-divide-fuchsia-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.45) !important;
}

.tw-divide-fuchsia-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.05) !important;
}

.tw-divide-fuchsia-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.5) !important;
}

.tw-divide-fuchsia-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.55) !important;
}

.tw-divide-fuchsia-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.6) !important;
}

.tw-divide-fuchsia-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.65) !important;
}

.tw-divide-fuchsia-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.7) !important;
}

.tw-divide-fuchsia-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.75) !important;
}

.tw-divide-fuchsia-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.8) !important;
}

.tw-divide-fuchsia-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.85) !important;
}

.tw-divide-fuchsia-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.9) !important;
}

.tw-divide-fuchsia-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 38 211 / 0.95) !important;
}

.tw-divide-fuchsia-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(162 28 175 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0) !important;
}

.tw-divide-fuchsia-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.1) !important;
}

.tw-divide-fuchsia-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 1) !important;
}

.tw-divide-fuchsia-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.15) !important;
}

.tw-divide-fuchsia-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.2) !important;
}

.tw-divide-fuchsia-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.25) !important;
}

.tw-divide-fuchsia-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.3) !important;
}

.tw-divide-fuchsia-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.35) !important;
}

.tw-divide-fuchsia-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.4) !important;
}

.tw-divide-fuchsia-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.45) !important;
}

.tw-divide-fuchsia-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.05) !important;
}

.tw-divide-fuchsia-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.5) !important;
}

.tw-divide-fuchsia-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.55) !important;
}

.tw-divide-fuchsia-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.6) !important;
}

.tw-divide-fuchsia-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.65) !important;
}

.tw-divide-fuchsia-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.7) !important;
}

.tw-divide-fuchsia-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.75) !important;
}

.tw-divide-fuchsia-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.8) !important;
}

.tw-divide-fuchsia-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.85) !important;
}

.tw-divide-fuchsia-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.9) !important;
}

.tw-divide-fuchsia-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(162 28 175 / 0.95) !important;
}

.tw-divide-fuchsia-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(134 25 143 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0) !important;
}

.tw-divide-fuchsia-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.1) !important;
}

.tw-divide-fuchsia-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 1) !important;
}

.tw-divide-fuchsia-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.15) !important;
}

.tw-divide-fuchsia-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.2) !important;
}

.tw-divide-fuchsia-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.25) !important;
}

.tw-divide-fuchsia-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.3) !important;
}

.tw-divide-fuchsia-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.35) !important;
}

.tw-divide-fuchsia-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.4) !important;
}

.tw-divide-fuchsia-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.45) !important;
}

.tw-divide-fuchsia-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.05) !important;
}

.tw-divide-fuchsia-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.5) !important;
}

.tw-divide-fuchsia-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.55) !important;
}

.tw-divide-fuchsia-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.6) !important;
}

.tw-divide-fuchsia-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.65) !important;
}

.tw-divide-fuchsia-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.7) !important;
}

.tw-divide-fuchsia-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.75) !important;
}

.tw-divide-fuchsia-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.8) !important;
}

.tw-divide-fuchsia-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.85) !important;
}

.tw-divide-fuchsia-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.9) !important;
}

.tw-divide-fuchsia-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 25 143 / 0.95) !important;
}

.tw-divide-fuchsia-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(112 26 117 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0) !important;
}

.tw-divide-fuchsia-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.1) !important;
}

.tw-divide-fuchsia-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 1) !important;
}

.tw-divide-fuchsia-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.15) !important;
}

.tw-divide-fuchsia-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.2) !important;
}

.tw-divide-fuchsia-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.25) !important;
}

.tw-divide-fuchsia-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.3) !important;
}

.tw-divide-fuchsia-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.35) !important;
}

.tw-divide-fuchsia-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.4) !important;
}

.tw-divide-fuchsia-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.45) !important;
}

.tw-divide-fuchsia-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.05) !important;
}

.tw-divide-fuchsia-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.5) !important;
}

.tw-divide-fuchsia-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.55) !important;
}

.tw-divide-fuchsia-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.6) !important;
}

.tw-divide-fuchsia-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.65) !important;
}

.tw-divide-fuchsia-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.7) !important;
}

.tw-divide-fuchsia-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.75) !important;
}

.tw-divide-fuchsia-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.8) !important;
}

.tw-divide-fuchsia-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.85) !important;
}

.tw-divide-fuchsia-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.9) !important;
}

.tw-divide-fuchsia-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(112 26 117 / 0.95) !important;
}

.tw-divide-fuchsia-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(74 4 78 / var(--tw-divide-opacity)) !important;
}

.tw-divide-fuchsia-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0) !important;
}

.tw-divide-fuchsia-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.1) !important;
}

.tw-divide-fuchsia-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 1) !important;
}

.tw-divide-fuchsia-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.15) !important;
}

.tw-divide-fuchsia-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.2) !important;
}

.tw-divide-fuchsia-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.25) !important;
}

.tw-divide-fuchsia-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.3) !important;
}

.tw-divide-fuchsia-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.35) !important;
}

.tw-divide-fuchsia-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.4) !important;
}

.tw-divide-fuchsia-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.45) !important;
}

.tw-divide-fuchsia-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.05) !important;
}

.tw-divide-fuchsia-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.5) !important;
}

.tw-divide-fuchsia-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.55) !important;
}

.tw-divide-fuchsia-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.6) !important;
}

.tw-divide-fuchsia-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.65) !important;
}

.tw-divide-fuchsia-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.7) !important;
}

.tw-divide-fuchsia-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.75) !important;
}

.tw-divide-fuchsia-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.8) !important;
}

.tw-divide-fuchsia-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.85) !important;
}

.tw-divide-fuchsia-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.9) !important;
}

.tw-divide-fuchsia-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 4 78 / 0.95) !important;
}

.tw-divide-gray-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0) !important;
}

.tw-divide-gray-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.1) !important;
}

.tw-divide-gray-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 1) !important;
}

.tw-divide-gray-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.15) !important;
}

.tw-divide-gray-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.2) !important;
}

.tw-divide-gray-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.25) !important;
}

.tw-divide-gray-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.3) !important;
}

.tw-divide-gray-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.35) !important;
}

.tw-divide-gray-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.4) !important;
}

.tw-divide-gray-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.45) !important;
}

.tw-divide-gray-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.05) !important;
}

.tw-divide-gray-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.5) !important;
}

.tw-divide-gray-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.55) !important;
}

.tw-divide-gray-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.6) !important;
}

.tw-divide-gray-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.65) !important;
}

.tw-divide-gray-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.7) !important;
}

.tw-divide-gray-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.75) !important;
}

.tw-divide-gray-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.8) !important;
}

.tw-divide-gray-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.85) !important;
}

.tw-divide-gray-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.9) !important;
}

.tw-divide-gray-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 244 246 / 0.95) !important;
}

.tw-divide-gray-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0) !important;
}

.tw-divide-gray-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.1) !important;
}

.tw-divide-gray-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 1) !important;
}

.tw-divide-gray-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.15) !important;
}

.tw-divide-gray-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.2) !important;
}

.tw-divide-gray-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.25) !important;
}

.tw-divide-gray-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.3) !important;
}

.tw-divide-gray-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.35) !important;
}

.tw-divide-gray-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.4) !important;
}

.tw-divide-gray-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.45) !important;
}

.tw-divide-gray-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.05) !important;
}

.tw-divide-gray-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.5) !important;
}

.tw-divide-gray-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.55) !important;
}

.tw-divide-gray-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.6) !important;
}

.tw-divide-gray-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.65) !important;
}

.tw-divide-gray-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.7) !important;
}

.tw-divide-gray-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.75) !important;
}

.tw-divide-gray-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.8) !important;
}

.tw-divide-gray-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.85) !important;
}

.tw-divide-gray-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.9) !important;
}

.tw-divide-gray-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 231 235 / 0.95) !important;
}

.tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0) !important;
}

.tw-divide-gray-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.1) !important;
}

.tw-divide-gray-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 1) !important;
}

.tw-divide-gray-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.15) !important;
}

.tw-divide-gray-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.2) !important;
}

.tw-divide-gray-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.25) !important;
}

.tw-divide-gray-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.3) !important;
}

.tw-divide-gray-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.35) !important;
}

.tw-divide-gray-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.4) !important;
}

.tw-divide-gray-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.45) !important;
}

.tw-divide-gray-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.05) !important;
}

.tw-divide-gray-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.5) !important;
}

.tw-divide-gray-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.55) !important;
}

.tw-divide-gray-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.6) !important;
}

.tw-divide-gray-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.65) !important;
}

.tw-divide-gray-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.7) !important;
}

.tw-divide-gray-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.75) !important;
}

.tw-divide-gray-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.8) !important;
}

.tw-divide-gray-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.85) !important;
}

.tw-divide-gray-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.9) !important;
}

.tw-divide-gray-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(209 213 219 / 0.95) !important;
}

.tw-divide-gray-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0) !important;
}

.tw-divide-gray-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.1) !important;
}

.tw-divide-gray-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 1) !important;
}

.tw-divide-gray-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.15) !important;
}

.tw-divide-gray-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.2) !important;
}

.tw-divide-gray-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.25) !important;
}

.tw-divide-gray-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.3) !important;
}

.tw-divide-gray-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.35) !important;
}

.tw-divide-gray-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.4) !important;
}

.tw-divide-gray-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.45) !important;
}

.tw-divide-gray-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.05) !important;
}

.tw-divide-gray-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.5) !important;
}

.tw-divide-gray-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.55) !important;
}

.tw-divide-gray-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.6) !important;
}

.tw-divide-gray-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.65) !important;
}

.tw-divide-gray-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.7) !important;
}

.tw-divide-gray-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.75) !important;
}

.tw-divide-gray-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.8) !important;
}

.tw-divide-gray-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.85) !important;
}

.tw-divide-gray-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.9) !important;
}

.tw-divide-gray-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(156 163 175 / 0.95) !important;
}

.tw-divide-gray-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(249 250 251 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0) !important;
}

.tw-divide-gray-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.1) !important;
}

.tw-divide-gray-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 1) !important;
}

.tw-divide-gray-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.15) !important;
}

.tw-divide-gray-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.2) !important;
}

.tw-divide-gray-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.25) !important;
}

.tw-divide-gray-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.3) !important;
}

.tw-divide-gray-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.35) !important;
}

.tw-divide-gray-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.4) !important;
}

.tw-divide-gray-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.45) !important;
}

.tw-divide-gray-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.05) !important;
}

.tw-divide-gray-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.5) !important;
}

.tw-divide-gray-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.55) !important;
}

.tw-divide-gray-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.6) !important;
}

.tw-divide-gray-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.65) !important;
}

.tw-divide-gray-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.7) !important;
}

.tw-divide-gray-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.75) !important;
}

.tw-divide-gray-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.8) !important;
}

.tw-divide-gray-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.85) !important;
}

.tw-divide-gray-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.9) !important;
}

.tw-divide-gray-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 250 251 / 0.95) !important;
}

.tw-divide-gray-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(107 114 128 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0) !important;
}

.tw-divide-gray-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.1) !important;
}

.tw-divide-gray-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 1) !important;
}

.tw-divide-gray-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.15) !important;
}

.tw-divide-gray-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.2) !important;
}

.tw-divide-gray-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.25) !important;
}

.tw-divide-gray-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.3) !important;
}

.tw-divide-gray-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.35) !important;
}

.tw-divide-gray-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.4) !important;
}

.tw-divide-gray-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.45) !important;
}

.tw-divide-gray-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.05) !important;
}

.tw-divide-gray-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.5) !important;
}

.tw-divide-gray-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.55) !important;
}

.tw-divide-gray-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.6) !important;
}

.tw-divide-gray-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.65) !important;
}

.tw-divide-gray-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.7) !important;
}

.tw-divide-gray-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.75) !important;
}

.tw-divide-gray-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.8) !important;
}

.tw-divide-gray-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.85) !important;
}

.tw-divide-gray-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.9) !important;
}

.tw-divide-gray-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 114 128 / 0.95) !important;
}

.tw-divide-gray-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(75 85 99 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0) !important;
}

.tw-divide-gray-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.1) !important;
}

.tw-divide-gray-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 1) !important;
}

.tw-divide-gray-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.15) !important;
}

.tw-divide-gray-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.2) !important;
}

.tw-divide-gray-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.25) !important;
}

.tw-divide-gray-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.3) !important;
}

.tw-divide-gray-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.35) !important;
}

.tw-divide-gray-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.4) !important;
}

.tw-divide-gray-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.45) !important;
}

.tw-divide-gray-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.05) !important;
}

.tw-divide-gray-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.5) !important;
}

.tw-divide-gray-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.55) !important;
}

.tw-divide-gray-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.6) !important;
}

.tw-divide-gray-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.65) !important;
}

.tw-divide-gray-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.7) !important;
}

.tw-divide-gray-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.75) !important;
}

.tw-divide-gray-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.8) !important;
}

.tw-divide-gray-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.85) !important;
}

.tw-divide-gray-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.9) !important;
}

.tw-divide-gray-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(75 85 99 / 0.95) !important;
}

.tw-divide-gray-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0) !important;
}

.tw-divide-gray-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.1) !important;
}

.tw-divide-gray-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 1) !important;
}

.tw-divide-gray-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.15) !important;
}

.tw-divide-gray-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.2) !important;
}

.tw-divide-gray-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.25) !important;
}

.tw-divide-gray-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.3) !important;
}

.tw-divide-gray-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.35) !important;
}

.tw-divide-gray-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.4) !important;
}

.tw-divide-gray-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.45) !important;
}

.tw-divide-gray-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.05) !important;
}

.tw-divide-gray-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.5) !important;
}

.tw-divide-gray-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.55) !important;
}

.tw-divide-gray-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.6) !important;
}

.tw-divide-gray-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.65) !important;
}

.tw-divide-gray-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.7) !important;
}

.tw-divide-gray-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.75) !important;
}

.tw-divide-gray-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.8) !important;
}

.tw-divide-gray-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.85) !important;
}

.tw-divide-gray-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.9) !important;
}

.tw-divide-gray-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 65 81 / 0.95) !important;
}

.tw-divide-gray-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(31 41 55 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0) !important;
}

.tw-divide-gray-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.1) !important;
}

.tw-divide-gray-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 1) !important;
}

.tw-divide-gray-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.15) !important;
}

.tw-divide-gray-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.2) !important;
}

.tw-divide-gray-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.25) !important;
}

.tw-divide-gray-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.3) !important;
}

.tw-divide-gray-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.35) !important;
}

.tw-divide-gray-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.4) !important;
}

.tw-divide-gray-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.45) !important;
}

.tw-divide-gray-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.05) !important;
}

.tw-divide-gray-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.5) !important;
}

.tw-divide-gray-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.55) !important;
}

.tw-divide-gray-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.6) !important;
}

.tw-divide-gray-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.65) !important;
}

.tw-divide-gray-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.7) !important;
}

.tw-divide-gray-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.75) !important;
}

.tw-divide-gray-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.8) !important;
}

.tw-divide-gray-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.85) !important;
}

.tw-divide-gray-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.9) !important;
}

.tw-divide-gray-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(31 41 55 / 0.95) !important;
}

.tw-divide-gray-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(17 24 39 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0) !important;
}

.tw-divide-gray-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.1) !important;
}

.tw-divide-gray-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 1) !important;
}

.tw-divide-gray-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.15) !important;
}

.tw-divide-gray-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.2) !important;
}

.tw-divide-gray-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.25) !important;
}

.tw-divide-gray-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.3) !important;
}

.tw-divide-gray-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.35) !important;
}

.tw-divide-gray-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.4) !important;
}

.tw-divide-gray-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.45) !important;
}

.tw-divide-gray-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.05) !important;
}

.tw-divide-gray-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.5) !important;
}

.tw-divide-gray-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.55) !important;
}

.tw-divide-gray-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.6) !important;
}

.tw-divide-gray-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.65) !important;
}

.tw-divide-gray-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.7) !important;
}

.tw-divide-gray-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.75) !important;
}

.tw-divide-gray-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.8) !important;
}

.tw-divide-gray-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.85) !important;
}

.tw-divide-gray-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.9) !important;
}

.tw-divide-gray-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 24 39 / 0.95) !important;
}

.tw-divide-gray-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(3 7 18 / var(--tw-divide-opacity)) !important;
}

.tw-divide-gray-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0) !important;
}

.tw-divide-gray-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.1) !important;
}

.tw-divide-gray-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 1) !important;
}

.tw-divide-gray-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.15) !important;
}

.tw-divide-gray-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.2) !important;
}

.tw-divide-gray-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.25) !important;
}

.tw-divide-gray-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.3) !important;
}

.tw-divide-gray-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.35) !important;
}

.tw-divide-gray-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.4) !important;
}

.tw-divide-gray-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.45) !important;
}

.tw-divide-gray-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.05) !important;
}

.tw-divide-gray-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.5) !important;
}

.tw-divide-gray-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.55) !important;
}

.tw-divide-gray-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.6) !important;
}

.tw-divide-gray-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.65) !important;
}

.tw-divide-gray-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.7) !important;
}

.tw-divide-gray-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.75) !important;
}

.tw-divide-gray-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.8) !important;
}

.tw-divide-gray-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.85) !important;
}

.tw-divide-gray-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.9) !important;
}

.tw-divide-gray-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 7 18 / 0.95) !important;
}

.tw-divide-green-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(220 252 231 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0) !important;
}

.tw-divide-green-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.1) !important;
}

.tw-divide-green-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 1) !important;
}

.tw-divide-green-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.15) !important;
}

.tw-divide-green-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.2) !important;
}

.tw-divide-green-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.25) !important;
}

.tw-divide-green-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.3) !important;
}

.tw-divide-green-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.35) !important;
}

.tw-divide-green-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.4) !important;
}

.tw-divide-green-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.45) !important;
}

.tw-divide-green-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.05) !important;
}

.tw-divide-green-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.5) !important;
}

.tw-divide-green-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.55) !important;
}

.tw-divide-green-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.6) !important;
}

.tw-divide-green-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.65) !important;
}

.tw-divide-green-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.7) !important;
}

.tw-divide-green-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.75) !important;
}

.tw-divide-green-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.8) !important;
}

.tw-divide-green-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.85) !important;
}

.tw-divide-green-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.9) !important;
}

.tw-divide-green-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 252 231 / 0.95) !important;
}

.tw-divide-green-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(187 247 208 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0) !important;
}

.tw-divide-green-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.1) !important;
}

.tw-divide-green-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 1) !important;
}

.tw-divide-green-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.15) !important;
}

.tw-divide-green-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.2) !important;
}

.tw-divide-green-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.25) !important;
}

.tw-divide-green-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.3) !important;
}

.tw-divide-green-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.35) !important;
}

.tw-divide-green-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.4) !important;
}

.tw-divide-green-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.45) !important;
}

.tw-divide-green-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.05) !important;
}

.tw-divide-green-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.5) !important;
}

.tw-divide-green-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.55) !important;
}

.tw-divide-green-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.6) !important;
}

.tw-divide-green-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.65) !important;
}

.tw-divide-green-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.7) !important;
}

.tw-divide-green-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.75) !important;
}

.tw-divide-green-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.8) !important;
}

.tw-divide-green-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.85) !important;
}

.tw-divide-green-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.9) !important;
}

.tw-divide-green-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(187 247 208 / 0.95) !important;
}

.tw-divide-green-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(134 239 172 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0) !important;
}

.tw-divide-green-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.1) !important;
}

.tw-divide-green-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 1) !important;
}

.tw-divide-green-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.15) !important;
}

.tw-divide-green-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.2) !important;
}

.tw-divide-green-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.25) !important;
}

.tw-divide-green-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.3) !important;
}

.tw-divide-green-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.35) !important;
}

.tw-divide-green-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.4) !important;
}

.tw-divide-green-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.45) !important;
}

.tw-divide-green-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.05) !important;
}

.tw-divide-green-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.5) !important;
}

.tw-divide-green-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.55) !important;
}

.tw-divide-green-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.6) !important;
}

.tw-divide-green-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.65) !important;
}

.tw-divide-green-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.7) !important;
}

.tw-divide-green-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.75) !important;
}

.tw-divide-green-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.8) !important;
}

.tw-divide-green-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.85) !important;
}

.tw-divide-green-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.9) !important;
}

.tw-divide-green-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(134 239 172 / 0.95) !important;
}

.tw-divide-green-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(74 222 128 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0) !important;
}

.tw-divide-green-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.1) !important;
}

.tw-divide-green-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 1) !important;
}

.tw-divide-green-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.15) !important;
}

.tw-divide-green-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.2) !important;
}

.tw-divide-green-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.25) !important;
}

.tw-divide-green-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.3) !important;
}

.tw-divide-green-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.35) !important;
}

.tw-divide-green-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.4) !important;
}

.tw-divide-green-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.45) !important;
}

.tw-divide-green-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.05) !important;
}

.tw-divide-green-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.5) !important;
}

.tw-divide-green-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.55) !important;
}

.tw-divide-green-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.6) !important;
}

.tw-divide-green-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.65) !important;
}

.tw-divide-green-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.7) !important;
}

.tw-divide-green-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.75) !important;
}

.tw-divide-green-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.8) !important;
}

.tw-divide-green-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.85) !important;
}

.tw-divide-green-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.9) !important;
}

.tw-divide-green-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(74 222 128 / 0.95) !important;
}

.tw-divide-green-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(240 253 244 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0) !important;
}

.tw-divide-green-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.1) !important;
}

.tw-divide-green-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 1) !important;
}

.tw-divide-green-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.15) !important;
}

.tw-divide-green-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.2) !important;
}

.tw-divide-green-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.25) !important;
}

.tw-divide-green-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.3) !important;
}

.tw-divide-green-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.35) !important;
}

.tw-divide-green-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.4) !important;
}

.tw-divide-green-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.45) !important;
}

.tw-divide-green-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.05) !important;
}

.tw-divide-green-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.5) !important;
}

.tw-divide-green-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.55) !important;
}

.tw-divide-green-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.6) !important;
}

.tw-divide-green-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.65) !important;
}

.tw-divide-green-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.7) !important;
}

.tw-divide-green-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.75) !important;
}

.tw-divide-green-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.8) !important;
}

.tw-divide-green-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.85) !important;
}

.tw-divide-green-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.9) !important;
}

.tw-divide-green-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 244 / 0.95) !important;
}

.tw-divide-green-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(34 197 94 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0) !important;
}

.tw-divide-green-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.1) !important;
}

.tw-divide-green-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 1) !important;
}

.tw-divide-green-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.15) !important;
}

.tw-divide-green-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.2) !important;
}

.tw-divide-green-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.25) !important;
}

.tw-divide-green-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.3) !important;
}

.tw-divide-green-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.35) !important;
}

.tw-divide-green-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.4) !important;
}

.tw-divide-green-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.45) !important;
}

.tw-divide-green-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.05) !important;
}

.tw-divide-green-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.5) !important;
}

.tw-divide-green-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.55) !important;
}

.tw-divide-green-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.6) !important;
}

.tw-divide-green-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.65) !important;
}

.tw-divide-green-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.7) !important;
}

.tw-divide-green-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.75) !important;
}

.tw-divide-green-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.8) !important;
}

.tw-divide-green-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.85) !important;
}

.tw-divide-green-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.9) !important;
}

.tw-divide-green-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(34 197 94 / 0.95) !important;
}

.tw-divide-green-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(22 163 74 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0) !important;
}

.tw-divide-green-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.1) !important;
}

.tw-divide-green-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 1) !important;
}

.tw-divide-green-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.15) !important;
}

.tw-divide-green-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.2) !important;
}

.tw-divide-green-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.25) !important;
}

.tw-divide-green-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.3) !important;
}

.tw-divide-green-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.35) !important;
}

.tw-divide-green-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.4) !important;
}

.tw-divide-green-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.45) !important;
}

.tw-divide-green-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.05) !important;
}

.tw-divide-green-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.5) !important;
}

.tw-divide-green-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.55) !important;
}

.tw-divide-green-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.6) !important;
}

.tw-divide-green-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.65) !important;
}

.tw-divide-green-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.7) !important;
}

.tw-divide-green-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.75) !important;
}

.tw-divide-green-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.8) !important;
}

.tw-divide-green-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.85) !important;
}

.tw-divide-green-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.9) !important;
}

.tw-divide-green-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 163 74 / 0.95) !important;
}

.tw-divide-green-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(21 128 61 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0) !important;
}

.tw-divide-green-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.1) !important;
}

.tw-divide-green-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 1) !important;
}

.tw-divide-green-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.15) !important;
}

.tw-divide-green-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.2) !important;
}

.tw-divide-green-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.25) !important;
}

.tw-divide-green-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.3) !important;
}

.tw-divide-green-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.35) !important;
}

.tw-divide-green-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.4) !important;
}

.tw-divide-green-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.45) !important;
}

.tw-divide-green-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.05) !important;
}

.tw-divide-green-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.5) !important;
}

.tw-divide-green-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.55) !important;
}

.tw-divide-green-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.6) !important;
}

.tw-divide-green-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.65) !important;
}

.tw-divide-green-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.7) !important;
}

.tw-divide-green-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.75) !important;
}

.tw-divide-green-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.8) !important;
}

.tw-divide-green-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.85) !important;
}

.tw-divide-green-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.9) !important;
}

.tw-divide-green-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(21 128 61 / 0.95) !important;
}

.tw-divide-green-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(22 101 52 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0) !important;
}

.tw-divide-green-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.1) !important;
}

.tw-divide-green-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 1) !important;
}

.tw-divide-green-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.15) !important;
}

.tw-divide-green-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.2) !important;
}

.tw-divide-green-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.25) !important;
}

.tw-divide-green-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.3) !important;
}

.tw-divide-green-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.35) !important;
}

.tw-divide-green-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.4) !important;
}

.tw-divide-green-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.45) !important;
}

.tw-divide-green-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.05) !important;
}

.tw-divide-green-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.5) !important;
}

.tw-divide-green-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.55) !important;
}

.tw-divide-green-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.6) !important;
}

.tw-divide-green-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.65) !important;
}

.tw-divide-green-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.7) !important;
}

.tw-divide-green-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.75) !important;
}

.tw-divide-green-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.8) !important;
}

.tw-divide-green-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.85) !important;
}

.tw-divide-green-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.9) !important;
}

.tw-divide-green-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(22 101 52 / 0.95) !important;
}

.tw-divide-green-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(20 83 45 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0) !important;
}

.tw-divide-green-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.1) !important;
}

.tw-divide-green-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 1) !important;
}

.tw-divide-green-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.15) !important;
}

.tw-divide-green-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.2) !important;
}

.tw-divide-green-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.25) !important;
}

.tw-divide-green-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.3) !important;
}

.tw-divide-green-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.35) !important;
}

.tw-divide-green-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.4) !important;
}

.tw-divide-green-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.45) !important;
}

.tw-divide-green-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.05) !important;
}

.tw-divide-green-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.5) !important;
}

.tw-divide-green-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.55) !important;
}

.tw-divide-green-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.6) !important;
}

.tw-divide-green-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.65) !important;
}

.tw-divide-green-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.7) !important;
}

.tw-divide-green-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.75) !important;
}

.tw-divide-green-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.8) !important;
}

.tw-divide-green-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.85) !important;
}

.tw-divide-green-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.9) !important;
}

.tw-divide-green-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 83 45 / 0.95) !important;
}

.tw-divide-green-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(5 46 22 / var(--tw-divide-opacity)) !important;
}

.tw-divide-green-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0) !important;
}

.tw-divide-green-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.1) !important;
}

.tw-divide-green-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 1) !important;
}

.tw-divide-green-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.15) !important;
}

.tw-divide-green-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.2) !important;
}

.tw-divide-green-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.25) !important;
}

.tw-divide-green-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.3) !important;
}

.tw-divide-green-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.35) !important;
}

.tw-divide-green-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.4) !important;
}

.tw-divide-green-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.45) !important;
}

.tw-divide-green-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.05) !important;
}

.tw-divide-green-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.5) !important;
}

.tw-divide-green-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.55) !important;
}

.tw-divide-green-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.6) !important;
}

.tw-divide-green-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.65) !important;
}

.tw-divide-green-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.7) !important;
}

.tw-divide-green-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.75) !important;
}

.tw-divide-green-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.8) !important;
}

.tw-divide-green-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.85) !important;
}

.tw-divide-green-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.9) !important;
}

.tw-divide-green-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(5 46 22 / 0.95) !important;
}

.tw-divide-indigo-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(224 231 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0) !important;
}

.tw-divide-indigo-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.1) !important;
}

.tw-divide-indigo-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 1) !important;
}

.tw-divide-indigo-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.15) !important;
}

.tw-divide-indigo-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.2) !important;
}

.tw-divide-indigo-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.25) !important;
}

.tw-divide-indigo-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.3) !important;
}

.tw-divide-indigo-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.35) !important;
}

.tw-divide-indigo-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.4) !important;
}

.tw-divide-indigo-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.45) !important;
}

.tw-divide-indigo-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.05) !important;
}

.tw-divide-indigo-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.5) !important;
}

.tw-divide-indigo-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.55) !important;
}

.tw-divide-indigo-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.6) !important;
}

.tw-divide-indigo-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.65) !important;
}

.tw-divide-indigo-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.7) !important;
}

.tw-divide-indigo-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.75) !important;
}

.tw-divide-indigo-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.8) !important;
}

.tw-divide-indigo-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.85) !important;
}

.tw-divide-indigo-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.9) !important;
}

.tw-divide-indigo-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 231 255 / 0.95) !important;
}

.tw-divide-indigo-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(199 210 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0) !important;
}

.tw-divide-indigo-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.1) !important;
}

.tw-divide-indigo-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 1) !important;
}

.tw-divide-indigo-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.15) !important;
}

.tw-divide-indigo-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.2) !important;
}

.tw-divide-indigo-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.25) !important;
}

.tw-divide-indigo-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.3) !important;
}

.tw-divide-indigo-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.35) !important;
}

.tw-divide-indigo-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.4) !important;
}

.tw-divide-indigo-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.45) !important;
}

.tw-divide-indigo-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.05) !important;
}

.tw-divide-indigo-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.5) !important;
}

.tw-divide-indigo-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.55) !important;
}

.tw-divide-indigo-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.6) !important;
}

.tw-divide-indigo-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.65) !important;
}

.tw-divide-indigo-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.7) !important;
}

.tw-divide-indigo-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.75) !important;
}

.tw-divide-indigo-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.8) !important;
}

.tw-divide-indigo-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.85) !important;
}

.tw-divide-indigo-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.9) !important;
}

.tw-divide-indigo-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(199 210 254 / 0.95) !important;
}

.tw-divide-indigo-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(165 180 252 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0) !important;
}

.tw-divide-indigo-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.1) !important;
}

.tw-divide-indigo-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 1) !important;
}

.tw-divide-indigo-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.15) !important;
}

.tw-divide-indigo-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.2) !important;
}

.tw-divide-indigo-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.25) !important;
}

.tw-divide-indigo-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.3) !important;
}

.tw-divide-indigo-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.35) !important;
}

.tw-divide-indigo-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.4) !important;
}

.tw-divide-indigo-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.45) !important;
}

.tw-divide-indigo-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.05) !important;
}

.tw-divide-indigo-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.5) !important;
}

.tw-divide-indigo-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.55) !important;
}

.tw-divide-indigo-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.6) !important;
}

.tw-divide-indigo-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.65) !important;
}

.tw-divide-indigo-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.7) !important;
}

.tw-divide-indigo-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.75) !important;
}

.tw-divide-indigo-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.8) !important;
}

.tw-divide-indigo-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.85) !important;
}

.tw-divide-indigo-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.9) !important;
}

.tw-divide-indigo-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(165 180 252 / 0.95) !important;
}

.tw-divide-indigo-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(129 140 248 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0) !important;
}

.tw-divide-indigo-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.1) !important;
}

.tw-divide-indigo-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 1) !important;
}

.tw-divide-indigo-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.15) !important;
}

.tw-divide-indigo-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.2) !important;
}

.tw-divide-indigo-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.25) !important;
}

.tw-divide-indigo-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.3) !important;
}

.tw-divide-indigo-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.35) !important;
}

.tw-divide-indigo-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.4) !important;
}

.tw-divide-indigo-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.45) !important;
}

.tw-divide-indigo-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.05) !important;
}

.tw-divide-indigo-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.5) !important;
}

.tw-divide-indigo-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.55) !important;
}

.tw-divide-indigo-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.6) !important;
}

.tw-divide-indigo-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.65) !important;
}

.tw-divide-indigo-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.7) !important;
}

.tw-divide-indigo-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.75) !important;
}

.tw-divide-indigo-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.8) !important;
}

.tw-divide-indigo-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.85) !important;
}

.tw-divide-indigo-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.9) !important;
}

.tw-divide-indigo-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(129 140 248 / 0.95) !important;
}

.tw-divide-indigo-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(238 242 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0) !important;
}

.tw-divide-indigo-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.1) !important;
}

.tw-divide-indigo-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 1) !important;
}

.tw-divide-indigo-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.15) !important;
}

.tw-divide-indigo-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.2) !important;
}

.tw-divide-indigo-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.25) !important;
}

.tw-divide-indigo-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.3) !important;
}

.tw-divide-indigo-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.35) !important;
}

.tw-divide-indigo-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.4) !important;
}

.tw-divide-indigo-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.45) !important;
}

.tw-divide-indigo-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.05) !important;
}

.tw-divide-indigo-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.5) !important;
}

.tw-divide-indigo-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.55) !important;
}

.tw-divide-indigo-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.6) !important;
}

.tw-divide-indigo-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.65) !important;
}

.tw-divide-indigo-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.7) !important;
}

.tw-divide-indigo-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.75) !important;
}

.tw-divide-indigo-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.8) !important;
}

.tw-divide-indigo-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.85) !important;
}

.tw-divide-indigo-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.9) !important;
}

.tw-divide-indigo-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(238 242 255 / 0.95) !important;
}

.tw-divide-indigo-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(99 102 241 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0) !important;
}

.tw-divide-indigo-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.1) !important;
}

.tw-divide-indigo-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 1) !important;
}

.tw-divide-indigo-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.15) !important;
}

.tw-divide-indigo-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.2) !important;
}

.tw-divide-indigo-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.25) !important;
}

.tw-divide-indigo-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.3) !important;
}

.tw-divide-indigo-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.35) !important;
}

.tw-divide-indigo-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.4) !important;
}

.tw-divide-indigo-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.45) !important;
}

.tw-divide-indigo-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.05) !important;
}

.tw-divide-indigo-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.5) !important;
}

.tw-divide-indigo-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.55) !important;
}

.tw-divide-indigo-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.6) !important;
}

.tw-divide-indigo-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.65) !important;
}

.tw-divide-indigo-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.7) !important;
}

.tw-divide-indigo-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.75) !important;
}

.tw-divide-indigo-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.8) !important;
}

.tw-divide-indigo-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.85) !important;
}

.tw-divide-indigo-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.9) !important;
}

.tw-divide-indigo-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(99 102 241 / 0.95) !important;
}

.tw-divide-indigo-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0) !important;
}

.tw-divide-indigo-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.1) !important;
}

.tw-divide-indigo-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 1) !important;
}

.tw-divide-indigo-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.15) !important;
}

.tw-divide-indigo-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.2) !important;
}

.tw-divide-indigo-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.25) !important;
}

.tw-divide-indigo-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.3) !important;
}

.tw-divide-indigo-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.35) !important;
}

.tw-divide-indigo-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.4) !important;
}

.tw-divide-indigo-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.45) !important;
}

.tw-divide-indigo-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.05) !important;
}

.tw-divide-indigo-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.5) !important;
}

.tw-divide-indigo-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.55) !important;
}

.tw-divide-indigo-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.6) !important;
}

.tw-divide-indigo-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.65) !important;
}

.tw-divide-indigo-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.7) !important;
}

.tw-divide-indigo-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.75) !important;
}

.tw-divide-indigo-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.8) !important;
}

.tw-divide-indigo-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.85) !important;
}

.tw-divide-indigo-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.9) !important;
}

.tw-divide-indigo-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(79 70 229 / 0.95) !important;
}

.tw-divide-indigo-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(67 56 202 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0) !important;
}

.tw-divide-indigo-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.1) !important;
}

.tw-divide-indigo-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 1) !important;
}

.tw-divide-indigo-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.15) !important;
}

.tw-divide-indigo-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.2) !important;
}

.tw-divide-indigo-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.25) !important;
}

.tw-divide-indigo-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.3) !important;
}

.tw-divide-indigo-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.35) !important;
}

.tw-divide-indigo-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.4) !important;
}

.tw-divide-indigo-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.45) !important;
}

.tw-divide-indigo-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.05) !important;
}

.tw-divide-indigo-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.5) !important;
}

.tw-divide-indigo-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.55) !important;
}

.tw-divide-indigo-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.6) !important;
}

.tw-divide-indigo-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.65) !important;
}

.tw-divide-indigo-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.7) !important;
}

.tw-divide-indigo-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.75) !important;
}

.tw-divide-indigo-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.8) !important;
}

.tw-divide-indigo-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.85) !important;
}

.tw-divide-indigo-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.9) !important;
}

.tw-divide-indigo-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 56 202 / 0.95) !important;
}

.tw-divide-indigo-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(55 48 163 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0) !important;
}

.tw-divide-indigo-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.1) !important;
}

.tw-divide-indigo-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 1) !important;
}

.tw-divide-indigo-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.15) !important;
}

.tw-divide-indigo-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.2) !important;
}

.tw-divide-indigo-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.25) !important;
}

.tw-divide-indigo-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.3) !important;
}

.tw-divide-indigo-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.35) !important;
}

.tw-divide-indigo-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.4) !important;
}

.tw-divide-indigo-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.45) !important;
}

.tw-divide-indigo-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.05) !important;
}

.tw-divide-indigo-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.5) !important;
}

.tw-divide-indigo-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.55) !important;
}

.tw-divide-indigo-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.6) !important;
}

.tw-divide-indigo-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.65) !important;
}

.tw-divide-indigo-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.7) !important;
}

.tw-divide-indigo-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.75) !important;
}

.tw-divide-indigo-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.8) !important;
}

.tw-divide-indigo-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.85) !important;
}

.tw-divide-indigo-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.9) !important;
}

.tw-divide-indigo-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(55 48 163 / 0.95) !important;
}

.tw-divide-indigo-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(49 46 129 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0) !important;
}

.tw-divide-indigo-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.1) !important;
}

.tw-divide-indigo-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 1) !important;
}

.tw-divide-indigo-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.15) !important;
}

.tw-divide-indigo-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.2) !important;
}

.tw-divide-indigo-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.25) !important;
}

.tw-divide-indigo-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.3) !important;
}

.tw-divide-indigo-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.35) !important;
}

.tw-divide-indigo-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.4) !important;
}

.tw-divide-indigo-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.45) !important;
}

.tw-divide-indigo-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.05) !important;
}

.tw-divide-indigo-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.5) !important;
}

.tw-divide-indigo-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.55) !important;
}

.tw-divide-indigo-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.6) !important;
}

.tw-divide-indigo-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.65) !important;
}

.tw-divide-indigo-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.7) !important;
}

.tw-divide-indigo-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.75) !important;
}

.tw-divide-indigo-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.8) !important;
}

.tw-divide-indigo-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.85) !important;
}

.tw-divide-indigo-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.9) !important;
}

.tw-divide-indigo-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(49 46 129 / 0.95) !important;
}

.tw-divide-indigo-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(30 27 75 / var(--tw-divide-opacity)) !important;
}

.tw-divide-indigo-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0) !important;
}

.tw-divide-indigo-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.1) !important;
}

.tw-divide-indigo-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 1) !important;
}

.tw-divide-indigo-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.15) !important;
}

.tw-divide-indigo-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.2) !important;
}

.tw-divide-indigo-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.25) !important;
}

.tw-divide-indigo-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.3) !important;
}

.tw-divide-indigo-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.35) !important;
}

.tw-divide-indigo-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.4) !important;
}

.tw-divide-indigo-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.45) !important;
}

.tw-divide-indigo-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.05) !important;
}

.tw-divide-indigo-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.5) !important;
}

.tw-divide-indigo-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.55) !important;
}

.tw-divide-indigo-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.6) !important;
}

.tw-divide-indigo-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.65) !important;
}

.tw-divide-indigo-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.7) !important;
}

.tw-divide-indigo-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.75) !important;
}

.tw-divide-indigo-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.8) !important;
}

.tw-divide-indigo-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.85) !important;
}

.tw-divide-indigo-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.9) !important;
}

.tw-divide-indigo-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 27 75 / 0.95) !important;
}

.tw-divide-inherit > :not([hidden]) ~ :not([hidden]) {
  border-color: inherit !important;
}

.tw-divide-lime-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(236 252 203 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0) !important;
}

.tw-divide-lime-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.1) !important;
}

.tw-divide-lime-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 1) !important;
}

.tw-divide-lime-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.15) !important;
}

.tw-divide-lime-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.2) !important;
}

.tw-divide-lime-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.25) !important;
}

.tw-divide-lime-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.3) !important;
}

.tw-divide-lime-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.35) !important;
}

.tw-divide-lime-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.4) !important;
}

.tw-divide-lime-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.45) !important;
}

.tw-divide-lime-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.05) !important;
}

.tw-divide-lime-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.5) !important;
}

.tw-divide-lime-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.55) !important;
}

.tw-divide-lime-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.6) !important;
}

.tw-divide-lime-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.65) !important;
}

.tw-divide-lime-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.7) !important;
}

.tw-divide-lime-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.75) !important;
}

.tw-divide-lime-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.8) !important;
}

.tw-divide-lime-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.85) !important;
}

.tw-divide-lime-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.9) !important;
}

.tw-divide-lime-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 252 203 / 0.95) !important;
}

.tw-divide-lime-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(217 249 157 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0) !important;
}

.tw-divide-lime-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.1) !important;
}

.tw-divide-lime-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 1) !important;
}

.tw-divide-lime-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.15) !important;
}

.tw-divide-lime-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.2) !important;
}

.tw-divide-lime-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.25) !important;
}

.tw-divide-lime-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.3) !important;
}

.tw-divide-lime-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.35) !important;
}

.tw-divide-lime-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.4) !important;
}

.tw-divide-lime-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.45) !important;
}

.tw-divide-lime-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.05) !important;
}

.tw-divide-lime-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.5) !important;
}

.tw-divide-lime-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.55) !important;
}

.tw-divide-lime-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.6) !important;
}

.tw-divide-lime-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.65) !important;
}

.tw-divide-lime-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.7) !important;
}

.tw-divide-lime-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.75) !important;
}

.tw-divide-lime-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.8) !important;
}

.tw-divide-lime-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.85) !important;
}

.tw-divide-lime-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.9) !important;
}

.tw-divide-lime-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(217 249 157 / 0.95) !important;
}

.tw-divide-lime-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(190 242 100 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0) !important;
}

.tw-divide-lime-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.1) !important;
}

.tw-divide-lime-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 1) !important;
}

.tw-divide-lime-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.15) !important;
}

.tw-divide-lime-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.2) !important;
}

.tw-divide-lime-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.25) !important;
}

.tw-divide-lime-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.3) !important;
}

.tw-divide-lime-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.35) !important;
}

.tw-divide-lime-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.4) !important;
}

.tw-divide-lime-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.45) !important;
}

.tw-divide-lime-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.05) !important;
}

.tw-divide-lime-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.5) !important;
}

.tw-divide-lime-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.55) !important;
}

.tw-divide-lime-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.6) !important;
}

.tw-divide-lime-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.65) !important;
}

.tw-divide-lime-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.7) !important;
}

.tw-divide-lime-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.75) !important;
}

.tw-divide-lime-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.8) !important;
}

.tw-divide-lime-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.85) !important;
}

.tw-divide-lime-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.9) !important;
}

.tw-divide-lime-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 242 100 / 0.95) !important;
}

.tw-divide-lime-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(163 230 53 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0) !important;
}

.tw-divide-lime-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.1) !important;
}

.tw-divide-lime-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 1) !important;
}

.tw-divide-lime-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.15) !important;
}

.tw-divide-lime-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.2) !important;
}

.tw-divide-lime-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.25) !important;
}

.tw-divide-lime-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.3) !important;
}

.tw-divide-lime-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.35) !important;
}

.tw-divide-lime-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.4) !important;
}

.tw-divide-lime-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.45) !important;
}

.tw-divide-lime-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.05) !important;
}

.tw-divide-lime-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.5) !important;
}

.tw-divide-lime-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.55) !important;
}

.tw-divide-lime-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.6) !important;
}

.tw-divide-lime-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.65) !important;
}

.tw-divide-lime-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.7) !important;
}

.tw-divide-lime-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.75) !important;
}

.tw-divide-lime-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.8) !important;
}

.tw-divide-lime-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.85) !important;
}

.tw-divide-lime-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.9) !important;
}

.tw-divide-lime-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 230 53 / 0.95) !important;
}

.tw-divide-lime-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(247 254 231 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0) !important;
}

.tw-divide-lime-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.1) !important;
}

.tw-divide-lime-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 1) !important;
}

.tw-divide-lime-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.15) !important;
}

.tw-divide-lime-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.2) !important;
}

.tw-divide-lime-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.25) !important;
}

.tw-divide-lime-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.3) !important;
}

.tw-divide-lime-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.35) !important;
}

.tw-divide-lime-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.4) !important;
}

.tw-divide-lime-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.45) !important;
}

.tw-divide-lime-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.05) !important;
}

.tw-divide-lime-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.5) !important;
}

.tw-divide-lime-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.55) !important;
}

.tw-divide-lime-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.6) !important;
}

.tw-divide-lime-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.65) !important;
}

.tw-divide-lime-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.7) !important;
}

.tw-divide-lime-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.75) !important;
}

.tw-divide-lime-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.8) !important;
}

.tw-divide-lime-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.85) !important;
}

.tw-divide-lime-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.9) !important;
}

.tw-divide-lime-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(247 254 231 / 0.95) !important;
}

.tw-divide-lime-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(132 204 22 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0) !important;
}

.tw-divide-lime-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.1) !important;
}

.tw-divide-lime-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 1) !important;
}

.tw-divide-lime-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.15) !important;
}

.tw-divide-lime-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.2) !important;
}

.tw-divide-lime-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.25) !important;
}

.tw-divide-lime-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.3) !important;
}

.tw-divide-lime-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.35) !important;
}

.tw-divide-lime-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.4) !important;
}

.tw-divide-lime-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.45) !important;
}

.tw-divide-lime-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.05) !important;
}

.tw-divide-lime-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.5) !important;
}

.tw-divide-lime-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.55) !important;
}

.tw-divide-lime-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.6) !important;
}

.tw-divide-lime-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.65) !important;
}

.tw-divide-lime-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.7) !important;
}

.tw-divide-lime-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.75) !important;
}

.tw-divide-lime-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.8) !important;
}

.tw-divide-lime-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.85) !important;
}

.tw-divide-lime-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.9) !important;
}

.tw-divide-lime-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(132 204 22 / 0.95) !important;
}

.tw-divide-lime-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(101 163 13 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0) !important;
}

.tw-divide-lime-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.1) !important;
}

.tw-divide-lime-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 1) !important;
}

.tw-divide-lime-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.15) !important;
}

.tw-divide-lime-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.2) !important;
}

.tw-divide-lime-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.25) !important;
}

.tw-divide-lime-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.3) !important;
}

.tw-divide-lime-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.35) !important;
}

.tw-divide-lime-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.4) !important;
}

.tw-divide-lime-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.45) !important;
}

.tw-divide-lime-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.05) !important;
}

.tw-divide-lime-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.5) !important;
}

.tw-divide-lime-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.55) !important;
}

.tw-divide-lime-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.6) !important;
}

.tw-divide-lime-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.65) !important;
}

.tw-divide-lime-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.7) !important;
}

.tw-divide-lime-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.75) !important;
}

.tw-divide-lime-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.8) !important;
}

.tw-divide-lime-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.85) !important;
}

.tw-divide-lime-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.9) !important;
}

.tw-divide-lime-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(101 163 13 / 0.95) !important;
}

.tw-divide-lime-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(77 124 15 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0) !important;
}

.tw-divide-lime-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.1) !important;
}

.tw-divide-lime-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 1) !important;
}

.tw-divide-lime-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.15) !important;
}

.tw-divide-lime-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.2) !important;
}

.tw-divide-lime-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.25) !important;
}

.tw-divide-lime-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.3) !important;
}

.tw-divide-lime-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.35) !important;
}

.tw-divide-lime-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.4) !important;
}

.tw-divide-lime-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.45) !important;
}

.tw-divide-lime-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.05) !important;
}

.tw-divide-lime-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.5) !important;
}

.tw-divide-lime-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.55) !important;
}

.tw-divide-lime-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.6) !important;
}

.tw-divide-lime-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.65) !important;
}

.tw-divide-lime-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.7) !important;
}

.tw-divide-lime-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.75) !important;
}

.tw-divide-lime-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.8) !important;
}

.tw-divide-lime-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.85) !important;
}

.tw-divide-lime-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.9) !important;
}

.tw-divide-lime-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(77 124 15 / 0.95) !important;
}

.tw-divide-lime-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(63 98 18 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0) !important;
}

.tw-divide-lime-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.1) !important;
}

.tw-divide-lime-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 1) !important;
}

.tw-divide-lime-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.15) !important;
}

.tw-divide-lime-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.2) !important;
}

.tw-divide-lime-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.25) !important;
}

.tw-divide-lime-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.3) !important;
}

.tw-divide-lime-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.35) !important;
}

.tw-divide-lime-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.4) !important;
}

.tw-divide-lime-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.45) !important;
}

.tw-divide-lime-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.05) !important;
}

.tw-divide-lime-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.5) !important;
}

.tw-divide-lime-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.55) !important;
}

.tw-divide-lime-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.6) !important;
}

.tw-divide-lime-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.65) !important;
}

.tw-divide-lime-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.7) !important;
}

.tw-divide-lime-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.75) !important;
}

.tw-divide-lime-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.8) !important;
}

.tw-divide-lime-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.85) !important;
}

.tw-divide-lime-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.9) !important;
}

.tw-divide-lime-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 98 18 / 0.95) !important;
}

.tw-divide-lime-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(54 83 20 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0) !important;
}

.tw-divide-lime-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.1) !important;
}

.tw-divide-lime-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 1) !important;
}

.tw-divide-lime-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.15) !important;
}

.tw-divide-lime-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.2) !important;
}

.tw-divide-lime-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.25) !important;
}

.tw-divide-lime-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.3) !important;
}

.tw-divide-lime-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.35) !important;
}

.tw-divide-lime-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.4) !important;
}

.tw-divide-lime-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.45) !important;
}

.tw-divide-lime-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.05) !important;
}

.tw-divide-lime-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.5) !important;
}

.tw-divide-lime-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.55) !important;
}

.tw-divide-lime-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.6) !important;
}

.tw-divide-lime-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.65) !important;
}

.tw-divide-lime-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.7) !important;
}

.tw-divide-lime-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.75) !important;
}

.tw-divide-lime-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.8) !important;
}

.tw-divide-lime-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.85) !important;
}

.tw-divide-lime-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.9) !important;
}

.tw-divide-lime-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(54 83 20 / 0.95) !important;
}

.tw-divide-lime-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(26 46 5 / var(--tw-divide-opacity)) !important;
}

.tw-divide-lime-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0) !important;
}

.tw-divide-lime-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.1) !important;
}

.tw-divide-lime-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 1) !important;
}

.tw-divide-lime-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.15) !important;
}

.tw-divide-lime-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.2) !important;
}

.tw-divide-lime-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.25) !important;
}

.tw-divide-lime-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.3) !important;
}

.tw-divide-lime-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.35) !important;
}

.tw-divide-lime-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.4) !important;
}

.tw-divide-lime-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.45) !important;
}

.tw-divide-lime-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.05) !important;
}

.tw-divide-lime-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.5) !important;
}

.tw-divide-lime-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.55) !important;
}

.tw-divide-lime-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.6) !important;
}

.tw-divide-lime-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.65) !important;
}

.tw-divide-lime-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.7) !important;
}

.tw-divide-lime-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.75) !important;
}

.tw-divide-lime-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.8) !important;
}

.tw-divide-lime-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.85) !important;
}

.tw-divide-lime-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.9) !important;
}

.tw-divide-lime-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(26 46 5 / 0.95) !important;
}

.tw-divide-neutral-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(245 245 245 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0) !important;
}

.tw-divide-neutral-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.1) !important;
}

.tw-divide-neutral-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 1) !important;
}

.tw-divide-neutral-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.15) !important;
}

.tw-divide-neutral-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.2) !important;
}

.tw-divide-neutral-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.25) !important;
}

.tw-divide-neutral-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.3) !important;
}

.tw-divide-neutral-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.35) !important;
}

.tw-divide-neutral-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.4) !important;
}

.tw-divide-neutral-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.45) !important;
}

.tw-divide-neutral-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.05) !important;
}

.tw-divide-neutral-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.5) !important;
}

.tw-divide-neutral-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.55) !important;
}

.tw-divide-neutral-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.6) !important;
}

.tw-divide-neutral-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.65) !important;
}

.tw-divide-neutral-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.7) !important;
}

.tw-divide-neutral-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.75) !important;
}

.tw-divide-neutral-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.8) !important;
}

.tw-divide-neutral-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.85) !important;
}

.tw-divide-neutral-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.9) !important;
}

.tw-divide-neutral-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 245 / 0.95) !important;
}

.tw-divide-neutral-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(229 229 229 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0) !important;
}

.tw-divide-neutral-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.1) !important;
}

.tw-divide-neutral-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 1) !important;
}

.tw-divide-neutral-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.15) !important;
}

.tw-divide-neutral-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.2) !important;
}

.tw-divide-neutral-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.25) !important;
}

.tw-divide-neutral-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.3) !important;
}

.tw-divide-neutral-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.35) !important;
}

.tw-divide-neutral-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.4) !important;
}

.tw-divide-neutral-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.45) !important;
}

.tw-divide-neutral-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.05) !important;
}

.tw-divide-neutral-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.5) !important;
}

.tw-divide-neutral-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.55) !important;
}

.tw-divide-neutral-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.6) !important;
}

.tw-divide-neutral-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.65) !important;
}

.tw-divide-neutral-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.7) !important;
}

.tw-divide-neutral-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.75) !important;
}

.tw-divide-neutral-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.8) !important;
}

.tw-divide-neutral-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.85) !important;
}

.tw-divide-neutral-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.9) !important;
}

.tw-divide-neutral-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(229 229 229 / 0.95) !important;
}

.tw-divide-neutral-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(212 212 212 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0) !important;
}

.tw-divide-neutral-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.1) !important;
}

.tw-divide-neutral-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 1) !important;
}

.tw-divide-neutral-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.15) !important;
}

.tw-divide-neutral-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.2) !important;
}

.tw-divide-neutral-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.25) !important;
}

.tw-divide-neutral-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.3) !important;
}

.tw-divide-neutral-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.35) !important;
}

.tw-divide-neutral-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.4) !important;
}

.tw-divide-neutral-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.45) !important;
}

.tw-divide-neutral-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.05) !important;
}

.tw-divide-neutral-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.5) !important;
}

.tw-divide-neutral-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.55) !important;
}

.tw-divide-neutral-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.6) !important;
}

.tw-divide-neutral-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.65) !important;
}

.tw-divide-neutral-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.7) !important;
}

.tw-divide-neutral-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.75) !important;
}

.tw-divide-neutral-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.8) !important;
}

.tw-divide-neutral-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.85) !important;
}

.tw-divide-neutral-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.9) !important;
}

.tw-divide-neutral-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 212 / 0.95) !important;
}

.tw-divide-neutral-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(163 163 163 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0) !important;
}

.tw-divide-neutral-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.1) !important;
}

.tw-divide-neutral-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 1) !important;
}

.tw-divide-neutral-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.15) !important;
}

.tw-divide-neutral-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.2) !important;
}

.tw-divide-neutral-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.25) !important;
}

.tw-divide-neutral-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.3) !important;
}

.tw-divide-neutral-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.35) !important;
}

.tw-divide-neutral-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.4) !important;
}

.tw-divide-neutral-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.45) !important;
}

.tw-divide-neutral-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.05) !important;
}

.tw-divide-neutral-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.5) !important;
}

.tw-divide-neutral-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.55) !important;
}

.tw-divide-neutral-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.6) !important;
}

.tw-divide-neutral-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.65) !important;
}

.tw-divide-neutral-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.7) !important;
}

.tw-divide-neutral-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.75) !important;
}

.tw-divide-neutral-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.8) !important;
}

.tw-divide-neutral-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.85) !important;
}

.tw-divide-neutral-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.9) !important;
}

.tw-divide-neutral-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(163 163 163 / 0.95) !important;
}

.tw-divide-neutral-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(250 250 250 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0) !important;
}

.tw-divide-neutral-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.1) !important;
}

.tw-divide-neutral-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 1) !important;
}

.tw-divide-neutral-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.15) !important;
}

.tw-divide-neutral-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.2) !important;
}

.tw-divide-neutral-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.25) !important;
}

.tw-divide-neutral-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.3) !important;
}

.tw-divide-neutral-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.35) !important;
}

.tw-divide-neutral-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.4) !important;
}

.tw-divide-neutral-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.45) !important;
}

.tw-divide-neutral-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.05) !important;
}

.tw-divide-neutral-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.5) !important;
}

.tw-divide-neutral-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.55) !important;
}

.tw-divide-neutral-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.6) !important;
}

.tw-divide-neutral-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.65) !important;
}

.tw-divide-neutral-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.7) !important;
}

.tw-divide-neutral-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.75) !important;
}

.tw-divide-neutral-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.8) !important;
}

.tw-divide-neutral-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.85) !important;
}

.tw-divide-neutral-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.9) !important;
}

.tw-divide-neutral-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.95) !important;
}

.tw-divide-neutral-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(115 115 115 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0) !important;
}

.tw-divide-neutral-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.1) !important;
}

.tw-divide-neutral-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 1) !important;
}

.tw-divide-neutral-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.15) !important;
}

.tw-divide-neutral-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.2) !important;
}

.tw-divide-neutral-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.25) !important;
}

.tw-divide-neutral-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.3) !important;
}

.tw-divide-neutral-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.35) !important;
}

.tw-divide-neutral-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.4) !important;
}

.tw-divide-neutral-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.45) !important;
}

.tw-divide-neutral-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.05) !important;
}

.tw-divide-neutral-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.5) !important;
}

.tw-divide-neutral-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.55) !important;
}

.tw-divide-neutral-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.6) !important;
}

.tw-divide-neutral-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.65) !important;
}

.tw-divide-neutral-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.7) !important;
}

.tw-divide-neutral-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.75) !important;
}

.tw-divide-neutral-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.8) !important;
}

.tw-divide-neutral-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.85) !important;
}

.tw-divide-neutral-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.9) !important;
}

.tw-divide-neutral-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(115 115 115 / 0.95) !important;
}

.tw-divide-neutral-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(82 82 82 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0) !important;
}

.tw-divide-neutral-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.1) !important;
}

.tw-divide-neutral-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 1) !important;
}

.tw-divide-neutral-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.15) !important;
}

.tw-divide-neutral-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.2) !important;
}

.tw-divide-neutral-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.25) !important;
}

.tw-divide-neutral-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.3) !important;
}

.tw-divide-neutral-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.35) !important;
}

.tw-divide-neutral-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.4) !important;
}

.tw-divide-neutral-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.45) !important;
}

.tw-divide-neutral-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.05) !important;
}

.tw-divide-neutral-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.5) !important;
}

.tw-divide-neutral-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.55) !important;
}

.tw-divide-neutral-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.6) !important;
}

.tw-divide-neutral-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.65) !important;
}

.tw-divide-neutral-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.7) !important;
}

.tw-divide-neutral-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.75) !important;
}

.tw-divide-neutral-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.8) !important;
}

.tw-divide-neutral-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.85) !important;
}

.tw-divide-neutral-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.9) !important;
}

.tw-divide-neutral-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 82 / 0.95) !important;
}

.tw-divide-neutral-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(64 64 64 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0) !important;
}

.tw-divide-neutral-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.1) !important;
}

.tw-divide-neutral-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 1) !important;
}

.tw-divide-neutral-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.15) !important;
}

.tw-divide-neutral-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.2) !important;
}

.tw-divide-neutral-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.25) !important;
}

.tw-divide-neutral-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.3) !important;
}

.tw-divide-neutral-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.35) !important;
}

.tw-divide-neutral-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.4) !important;
}

.tw-divide-neutral-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.45) !important;
}

.tw-divide-neutral-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.05) !important;
}

.tw-divide-neutral-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.5) !important;
}

.tw-divide-neutral-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.55) !important;
}

.tw-divide-neutral-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.6) !important;
}

.tw-divide-neutral-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.65) !important;
}

.tw-divide-neutral-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.7) !important;
}

.tw-divide-neutral-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.75) !important;
}

.tw-divide-neutral-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.8) !important;
}

.tw-divide-neutral-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.85) !important;
}

.tw-divide-neutral-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.9) !important;
}

.tw-divide-neutral-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(64 64 64 / 0.95) !important;
}

.tw-divide-neutral-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(38 38 38 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0) !important;
}

.tw-divide-neutral-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.1) !important;
}

.tw-divide-neutral-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 1) !important;
}

.tw-divide-neutral-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.15) !important;
}

.tw-divide-neutral-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.2) !important;
}

.tw-divide-neutral-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.25) !important;
}

.tw-divide-neutral-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.3) !important;
}

.tw-divide-neutral-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.35) !important;
}

.tw-divide-neutral-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.4) !important;
}

.tw-divide-neutral-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.45) !important;
}

.tw-divide-neutral-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.05) !important;
}

.tw-divide-neutral-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.5) !important;
}

.tw-divide-neutral-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.55) !important;
}

.tw-divide-neutral-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.6) !important;
}

.tw-divide-neutral-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.65) !important;
}

.tw-divide-neutral-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.7) !important;
}

.tw-divide-neutral-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.75) !important;
}

.tw-divide-neutral-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.8) !important;
}

.tw-divide-neutral-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.85) !important;
}

.tw-divide-neutral-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.9) !important;
}

.tw-divide-neutral-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(38 38 38 / 0.95) !important;
}

.tw-divide-neutral-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(23 23 23 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0) !important;
}

.tw-divide-neutral-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.1) !important;
}

.tw-divide-neutral-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 1) !important;
}

.tw-divide-neutral-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.15) !important;
}

.tw-divide-neutral-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.2) !important;
}

.tw-divide-neutral-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.25) !important;
}

.tw-divide-neutral-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.3) !important;
}

.tw-divide-neutral-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.35) !important;
}

.tw-divide-neutral-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.4) !important;
}

.tw-divide-neutral-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.45) !important;
}

.tw-divide-neutral-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.05) !important;
}

.tw-divide-neutral-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.5) !important;
}

.tw-divide-neutral-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.55) !important;
}

.tw-divide-neutral-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.6) !important;
}

.tw-divide-neutral-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.65) !important;
}

.tw-divide-neutral-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.7) !important;
}

.tw-divide-neutral-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.75) !important;
}

.tw-divide-neutral-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.8) !important;
}

.tw-divide-neutral-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.85) !important;
}

.tw-divide-neutral-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.9) !important;
}

.tw-divide-neutral-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(23 23 23 / 0.95) !important;
}

.tw-divide-neutral-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(10 10 10 / var(--tw-divide-opacity)) !important;
}

.tw-divide-neutral-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0) !important;
}

.tw-divide-neutral-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.1) !important;
}

.tw-divide-neutral-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 1) !important;
}

.tw-divide-neutral-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.15) !important;
}

.tw-divide-neutral-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.2) !important;
}

.tw-divide-neutral-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.25) !important;
}

.tw-divide-neutral-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.3) !important;
}

.tw-divide-neutral-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.35) !important;
}

.tw-divide-neutral-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.4) !important;
}

.tw-divide-neutral-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.45) !important;
}

.tw-divide-neutral-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.05) !important;
}

.tw-divide-neutral-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.5) !important;
}

.tw-divide-neutral-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.55) !important;
}

.tw-divide-neutral-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.6) !important;
}

.tw-divide-neutral-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.65) !important;
}

.tw-divide-neutral-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.7) !important;
}

.tw-divide-neutral-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.75) !important;
}

.tw-divide-neutral-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.8) !important;
}

.tw-divide-neutral-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.85) !important;
}

.tw-divide-neutral-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.9) !important;
}

.tw-divide-neutral-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(10 10 10 / 0.95) !important;
}

.tw-divide-orange-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 237 213 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0) !important;
}

.tw-divide-orange-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.1) !important;
}

.tw-divide-orange-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 1) !important;
}

.tw-divide-orange-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.15) !important;
}

.tw-divide-orange-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.2) !important;
}

.tw-divide-orange-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.25) !important;
}

.tw-divide-orange-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.3) !important;
}

.tw-divide-orange-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.35) !important;
}

.tw-divide-orange-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.4) !important;
}

.tw-divide-orange-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.45) !important;
}

.tw-divide-orange-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.05) !important;
}

.tw-divide-orange-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.5) !important;
}

.tw-divide-orange-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.55) !important;
}

.tw-divide-orange-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.6) !important;
}

.tw-divide-orange-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.65) !important;
}

.tw-divide-orange-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.7) !important;
}

.tw-divide-orange-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.75) !important;
}

.tw-divide-orange-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.8) !important;
}

.tw-divide-orange-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.85) !important;
}

.tw-divide-orange-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.9) !important;
}

.tw-divide-orange-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 237 213 / 0.95) !important;
}

.tw-divide-orange-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 215 170 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0) !important;
}

.tw-divide-orange-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.1) !important;
}

.tw-divide-orange-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 1) !important;
}

.tw-divide-orange-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.15) !important;
}

.tw-divide-orange-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.2) !important;
}

.tw-divide-orange-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.25) !important;
}

.tw-divide-orange-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.3) !important;
}

.tw-divide-orange-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.35) !important;
}

.tw-divide-orange-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.4) !important;
}

.tw-divide-orange-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.45) !important;
}

.tw-divide-orange-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.05) !important;
}

.tw-divide-orange-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.5) !important;
}

.tw-divide-orange-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.55) !important;
}

.tw-divide-orange-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.6) !important;
}

.tw-divide-orange-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.65) !important;
}

.tw-divide-orange-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.7) !important;
}

.tw-divide-orange-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.75) !important;
}

.tw-divide-orange-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.8) !important;
}

.tw-divide-orange-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.85) !important;
}

.tw-divide-orange-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.9) !important;
}

.tw-divide-orange-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 215 170 / 0.95) !important;
}

.tw-divide-orange-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(253 186 116 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0) !important;
}

.tw-divide-orange-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.1) !important;
}

.tw-divide-orange-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 1) !important;
}

.tw-divide-orange-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.15) !important;
}

.tw-divide-orange-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.2) !important;
}

.tw-divide-orange-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.25) !important;
}

.tw-divide-orange-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.3) !important;
}

.tw-divide-orange-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.35) !important;
}

.tw-divide-orange-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.4) !important;
}

.tw-divide-orange-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.45) !important;
}

.tw-divide-orange-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.05) !important;
}

.tw-divide-orange-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.5) !important;
}

.tw-divide-orange-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.55) !important;
}

.tw-divide-orange-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.6) !important;
}

.tw-divide-orange-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.65) !important;
}

.tw-divide-orange-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.7) !important;
}

.tw-divide-orange-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.75) !important;
}

.tw-divide-orange-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.8) !important;
}

.tw-divide-orange-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.85) !important;
}

.tw-divide-orange-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.9) !important;
}

.tw-divide-orange-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 186 116 / 0.95) !important;
}

.tw-divide-orange-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(251 146 60 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0) !important;
}

.tw-divide-orange-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.1) !important;
}

.tw-divide-orange-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 1) !important;
}

.tw-divide-orange-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.15) !important;
}

.tw-divide-orange-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.2) !important;
}

.tw-divide-orange-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.25) !important;
}

.tw-divide-orange-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.3) !important;
}

.tw-divide-orange-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.35) !important;
}

.tw-divide-orange-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.4) !important;
}

.tw-divide-orange-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.45) !important;
}

.tw-divide-orange-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.05) !important;
}

.tw-divide-orange-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.5) !important;
}

.tw-divide-orange-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.55) !important;
}

.tw-divide-orange-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.6) !important;
}

.tw-divide-orange-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.65) !important;
}

.tw-divide-orange-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.7) !important;
}

.tw-divide-orange-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.75) !important;
}

.tw-divide-orange-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.8) !important;
}

.tw-divide-orange-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.85) !important;
}

.tw-divide-orange-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.9) !important;
}

.tw-divide-orange-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 146 60 / 0.95) !important;
}

.tw-divide-orange-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 247 237 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0) !important;
}

.tw-divide-orange-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.1) !important;
}

.tw-divide-orange-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 1) !important;
}

.tw-divide-orange-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.15) !important;
}

.tw-divide-orange-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.2) !important;
}

.tw-divide-orange-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.25) !important;
}

.tw-divide-orange-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.3) !important;
}

.tw-divide-orange-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.35) !important;
}

.tw-divide-orange-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.4) !important;
}

.tw-divide-orange-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.45) !important;
}

.tw-divide-orange-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.05) !important;
}

.tw-divide-orange-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.5) !important;
}

.tw-divide-orange-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.55) !important;
}

.tw-divide-orange-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.6) !important;
}

.tw-divide-orange-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.65) !important;
}

.tw-divide-orange-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.7) !important;
}

.tw-divide-orange-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.75) !important;
}

.tw-divide-orange-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.8) !important;
}

.tw-divide-orange-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.85) !important;
}

.tw-divide-orange-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.9) !important;
}

.tw-divide-orange-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 247 237 / 0.95) !important;
}

.tw-divide-orange-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(249 115 22 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0) !important;
}

.tw-divide-orange-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.1) !important;
}

.tw-divide-orange-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 1) !important;
}

.tw-divide-orange-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.15) !important;
}

.tw-divide-orange-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.2) !important;
}

.tw-divide-orange-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.25) !important;
}

.tw-divide-orange-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.3) !important;
}

.tw-divide-orange-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.35) !important;
}

.tw-divide-orange-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.4) !important;
}

.tw-divide-orange-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.45) !important;
}

.tw-divide-orange-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.05) !important;
}

.tw-divide-orange-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.5) !important;
}

.tw-divide-orange-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.55) !important;
}

.tw-divide-orange-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.6) !important;
}

.tw-divide-orange-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.65) !important;
}

.tw-divide-orange-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.7) !important;
}

.tw-divide-orange-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.75) !important;
}

.tw-divide-orange-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.8) !important;
}

.tw-divide-orange-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.85) !important;
}

.tw-divide-orange-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.9) !important;
}

.tw-divide-orange-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 115 22 / 0.95) !important;
}

.tw-divide-orange-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(234 88 12 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0) !important;
}

.tw-divide-orange-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.1) !important;
}

.tw-divide-orange-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 1) !important;
}

.tw-divide-orange-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.15) !important;
}

.tw-divide-orange-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.2) !important;
}

.tw-divide-orange-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.25) !important;
}

.tw-divide-orange-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.3) !important;
}

.tw-divide-orange-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.35) !important;
}

.tw-divide-orange-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.4) !important;
}

.tw-divide-orange-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.45) !important;
}

.tw-divide-orange-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.05) !important;
}

.tw-divide-orange-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.5) !important;
}

.tw-divide-orange-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.55) !important;
}

.tw-divide-orange-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.6) !important;
}

.tw-divide-orange-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.65) !important;
}

.tw-divide-orange-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.7) !important;
}

.tw-divide-orange-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.75) !important;
}

.tw-divide-orange-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.8) !important;
}

.tw-divide-orange-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.85) !important;
}

.tw-divide-orange-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.9) !important;
}

.tw-divide-orange-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 88 12 / 0.95) !important;
}

.tw-divide-orange-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(194 65 12 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0) !important;
}

.tw-divide-orange-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.1) !important;
}

.tw-divide-orange-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 1) !important;
}

.tw-divide-orange-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.15) !important;
}

.tw-divide-orange-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.2) !important;
}

.tw-divide-orange-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.25) !important;
}

.tw-divide-orange-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.3) !important;
}

.tw-divide-orange-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.35) !important;
}

.tw-divide-orange-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.4) !important;
}

.tw-divide-orange-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.45) !important;
}

.tw-divide-orange-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.05) !important;
}

.tw-divide-orange-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.5) !important;
}

.tw-divide-orange-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.55) !important;
}

.tw-divide-orange-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.6) !important;
}

.tw-divide-orange-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.65) !important;
}

.tw-divide-orange-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.7) !important;
}

.tw-divide-orange-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.75) !important;
}

.tw-divide-orange-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.8) !important;
}

.tw-divide-orange-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.85) !important;
}

.tw-divide-orange-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.9) !important;
}

.tw-divide-orange-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(194 65 12 / 0.95) !important;
}

.tw-divide-orange-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(154 52 18 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0) !important;
}

.tw-divide-orange-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.1) !important;
}

.tw-divide-orange-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 1) !important;
}

.tw-divide-orange-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.15) !important;
}

.tw-divide-orange-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.2) !important;
}

.tw-divide-orange-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.25) !important;
}

.tw-divide-orange-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.3) !important;
}

.tw-divide-orange-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.35) !important;
}

.tw-divide-orange-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.4) !important;
}

.tw-divide-orange-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.45) !important;
}

.tw-divide-orange-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.05) !important;
}

.tw-divide-orange-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.5) !important;
}

.tw-divide-orange-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.55) !important;
}

.tw-divide-orange-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.6) !important;
}

.tw-divide-orange-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.65) !important;
}

.tw-divide-orange-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.7) !important;
}

.tw-divide-orange-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.75) !important;
}

.tw-divide-orange-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.8) !important;
}

.tw-divide-orange-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.85) !important;
}

.tw-divide-orange-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.9) !important;
}

.tw-divide-orange-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(154 52 18 / 0.95) !important;
}

.tw-divide-orange-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(124 45 18 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0) !important;
}

.tw-divide-orange-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.1) !important;
}

.tw-divide-orange-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 1) !important;
}

.tw-divide-orange-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.15) !important;
}

.tw-divide-orange-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.2) !important;
}

.tw-divide-orange-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.25) !important;
}

.tw-divide-orange-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.3) !important;
}

.tw-divide-orange-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.35) !important;
}

.tw-divide-orange-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.4) !important;
}

.tw-divide-orange-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.45) !important;
}

.tw-divide-orange-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.05) !important;
}

.tw-divide-orange-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.5) !important;
}

.tw-divide-orange-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.55) !important;
}

.tw-divide-orange-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.6) !important;
}

.tw-divide-orange-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.65) !important;
}

.tw-divide-orange-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.7) !important;
}

.tw-divide-orange-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.75) !important;
}

.tw-divide-orange-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.8) !important;
}

.tw-divide-orange-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.85) !important;
}

.tw-divide-orange-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.9) !important;
}

.tw-divide-orange-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 45 18 / 0.95) !important;
}

.tw-divide-orange-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(67 20 7 / var(--tw-divide-opacity)) !important;
}

.tw-divide-orange-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0) !important;
}

.tw-divide-orange-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.1) !important;
}

.tw-divide-orange-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 1) !important;
}

.tw-divide-orange-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.15) !important;
}

.tw-divide-orange-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.2) !important;
}

.tw-divide-orange-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.25) !important;
}

.tw-divide-orange-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.3) !important;
}

.tw-divide-orange-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.35) !important;
}

.tw-divide-orange-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.4) !important;
}

.tw-divide-orange-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.45) !important;
}

.tw-divide-orange-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.05) !important;
}

.tw-divide-orange-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.5) !important;
}

.tw-divide-orange-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.55) !important;
}

.tw-divide-orange-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.6) !important;
}

.tw-divide-orange-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.65) !important;
}

.tw-divide-orange-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.7) !important;
}

.tw-divide-orange-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.75) !important;
}

.tw-divide-orange-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.8) !important;
}

.tw-divide-orange-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.85) !important;
}

.tw-divide-orange-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.9) !important;
}

.tw-divide-orange-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(67 20 7 / 0.95) !important;
}

.tw-divide-pink-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(252 231 243 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0) !important;
}

.tw-divide-pink-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.1) !important;
}

.tw-divide-pink-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 1) !important;
}

.tw-divide-pink-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.15) !important;
}

.tw-divide-pink-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.2) !important;
}

.tw-divide-pink-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.25) !important;
}

.tw-divide-pink-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.3) !important;
}

.tw-divide-pink-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.35) !important;
}

.tw-divide-pink-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.4) !important;
}

.tw-divide-pink-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.45) !important;
}

.tw-divide-pink-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.05) !important;
}

.tw-divide-pink-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.5) !important;
}

.tw-divide-pink-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.55) !important;
}

.tw-divide-pink-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.6) !important;
}

.tw-divide-pink-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.65) !important;
}

.tw-divide-pink-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.7) !important;
}

.tw-divide-pink-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.75) !important;
}

.tw-divide-pink-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.8) !important;
}

.tw-divide-pink-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.85) !important;
}

.tw-divide-pink-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.9) !important;
}

.tw-divide-pink-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 231 243 / 0.95) !important;
}

.tw-divide-pink-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(251 207 232 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0) !important;
}

.tw-divide-pink-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.1) !important;
}

.tw-divide-pink-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 1) !important;
}

.tw-divide-pink-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.15) !important;
}

.tw-divide-pink-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.2) !important;
}

.tw-divide-pink-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.25) !important;
}

.tw-divide-pink-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.3) !important;
}

.tw-divide-pink-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.35) !important;
}

.tw-divide-pink-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.4) !important;
}

.tw-divide-pink-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.45) !important;
}

.tw-divide-pink-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.05) !important;
}

.tw-divide-pink-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.5) !important;
}

.tw-divide-pink-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.55) !important;
}

.tw-divide-pink-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.6) !important;
}

.tw-divide-pink-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.65) !important;
}

.tw-divide-pink-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.7) !important;
}

.tw-divide-pink-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.75) !important;
}

.tw-divide-pink-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.8) !important;
}

.tw-divide-pink-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.85) !important;
}

.tw-divide-pink-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.9) !important;
}

.tw-divide-pink-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 207 232 / 0.95) !important;
}

.tw-divide-pink-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(249 168 212 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0) !important;
}

.tw-divide-pink-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.1) !important;
}

.tw-divide-pink-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 1) !important;
}

.tw-divide-pink-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.15) !important;
}

.tw-divide-pink-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.2) !important;
}

.tw-divide-pink-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.25) !important;
}

.tw-divide-pink-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.3) !important;
}

.tw-divide-pink-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.35) !important;
}

.tw-divide-pink-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.4) !important;
}

.tw-divide-pink-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.45) !important;
}

.tw-divide-pink-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.05) !important;
}

.tw-divide-pink-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.5) !important;
}

.tw-divide-pink-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.55) !important;
}

.tw-divide-pink-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.6) !important;
}

.tw-divide-pink-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.65) !important;
}

.tw-divide-pink-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.7) !important;
}

.tw-divide-pink-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.75) !important;
}

.tw-divide-pink-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.8) !important;
}

.tw-divide-pink-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.85) !important;
}

.tw-divide-pink-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.9) !important;
}

.tw-divide-pink-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(249 168 212 / 0.95) !important;
}

.tw-divide-pink-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(244 114 182 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0) !important;
}

.tw-divide-pink-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.1) !important;
}

.tw-divide-pink-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 1) !important;
}

.tw-divide-pink-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.15) !important;
}

.tw-divide-pink-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.2) !important;
}

.tw-divide-pink-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.25) !important;
}

.tw-divide-pink-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.3) !important;
}

.tw-divide-pink-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.35) !important;
}

.tw-divide-pink-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.4) !important;
}

.tw-divide-pink-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.45) !important;
}

.tw-divide-pink-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.05) !important;
}

.tw-divide-pink-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.5) !important;
}

.tw-divide-pink-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.55) !important;
}

.tw-divide-pink-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.6) !important;
}

.tw-divide-pink-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.65) !important;
}

.tw-divide-pink-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.7) !important;
}

.tw-divide-pink-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.75) !important;
}

.tw-divide-pink-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.8) !important;
}

.tw-divide-pink-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.85) !important;
}

.tw-divide-pink-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.9) !important;
}

.tw-divide-pink-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 114 182 / 0.95) !important;
}

.tw-divide-pink-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(253 242 248 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0) !important;
}

.tw-divide-pink-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.1) !important;
}

.tw-divide-pink-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 1) !important;
}

.tw-divide-pink-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.15) !important;
}

.tw-divide-pink-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.2) !important;
}

.tw-divide-pink-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.25) !important;
}

.tw-divide-pink-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.3) !important;
}

.tw-divide-pink-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.35) !important;
}

.tw-divide-pink-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.4) !important;
}

.tw-divide-pink-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.45) !important;
}

.tw-divide-pink-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.05) !important;
}

.tw-divide-pink-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.5) !important;
}

.tw-divide-pink-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.55) !important;
}

.tw-divide-pink-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.6) !important;
}

.tw-divide-pink-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.65) !important;
}

.tw-divide-pink-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.7) !important;
}

.tw-divide-pink-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.75) !important;
}

.tw-divide-pink-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.8) !important;
}

.tw-divide-pink-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.85) !important;
}

.tw-divide-pink-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.9) !important;
}

.tw-divide-pink-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 242 248 / 0.95) !important;
}

.tw-divide-pink-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(236 72 153 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0) !important;
}

.tw-divide-pink-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.1) !important;
}

.tw-divide-pink-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 1) !important;
}

.tw-divide-pink-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.15) !important;
}

.tw-divide-pink-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.2) !important;
}

.tw-divide-pink-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.25) !important;
}

.tw-divide-pink-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.3) !important;
}

.tw-divide-pink-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.35) !important;
}

.tw-divide-pink-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.4) !important;
}

.tw-divide-pink-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.45) !important;
}

.tw-divide-pink-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.05) !important;
}

.tw-divide-pink-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.5) !important;
}

.tw-divide-pink-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.55) !important;
}

.tw-divide-pink-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.6) !important;
}

.tw-divide-pink-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.65) !important;
}

.tw-divide-pink-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.7) !important;
}

.tw-divide-pink-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.75) !important;
}

.tw-divide-pink-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.8) !important;
}

.tw-divide-pink-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.85) !important;
}

.tw-divide-pink-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.9) !important;
}

.tw-divide-pink-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(236 72 153 / 0.95) !important;
}

.tw-divide-pink-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(219 39 119 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0) !important;
}

.tw-divide-pink-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.1) !important;
}

.tw-divide-pink-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 1) !important;
}

.tw-divide-pink-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.15) !important;
}

.tw-divide-pink-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.2) !important;
}

.tw-divide-pink-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.25) !important;
}

.tw-divide-pink-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.3) !important;
}

.tw-divide-pink-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.35) !important;
}

.tw-divide-pink-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.4) !important;
}

.tw-divide-pink-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.45) !important;
}

.tw-divide-pink-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.05) !important;
}

.tw-divide-pink-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.5) !important;
}

.tw-divide-pink-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.55) !important;
}

.tw-divide-pink-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.6) !important;
}

.tw-divide-pink-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.65) !important;
}

.tw-divide-pink-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.7) !important;
}

.tw-divide-pink-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.75) !important;
}

.tw-divide-pink-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.8) !important;
}

.tw-divide-pink-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.85) !important;
}

.tw-divide-pink-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.9) !important;
}

.tw-divide-pink-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(219 39 119 / 0.95) !important;
}

.tw-divide-pink-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(190 24 93 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0) !important;
}

.tw-divide-pink-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.1) !important;
}

.tw-divide-pink-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 1) !important;
}

.tw-divide-pink-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.15) !important;
}

.tw-divide-pink-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.2) !important;
}

.tw-divide-pink-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.25) !important;
}

.tw-divide-pink-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.3) !important;
}

.tw-divide-pink-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.35) !important;
}

.tw-divide-pink-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.4) !important;
}

.tw-divide-pink-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.45) !important;
}

.tw-divide-pink-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.05) !important;
}

.tw-divide-pink-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.5) !important;
}

.tw-divide-pink-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.55) !important;
}

.tw-divide-pink-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.6) !important;
}

.tw-divide-pink-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.65) !important;
}

.tw-divide-pink-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.7) !important;
}

.tw-divide-pink-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.75) !important;
}

.tw-divide-pink-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.8) !important;
}

.tw-divide-pink-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.85) !important;
}

.tw-divide-pink-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.9) !important;
}

.tw-divide-pink-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 24 93 / 0.95) !important;
}

.tw-divide-pink-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(157 23 77 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0) !important;
}

.tw-divide-pink-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.1) !important;
}

.tw-divide-pink-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 1) !important;
}

.tw-divide-pink-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.15) !important;
}

.tw-divide-pink-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.2) !important;
}

.tw-divide-pink-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.25) !important;
}

.tw-divide-pink-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.3) !important;
}

.tw-divide-pink-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.35) !important;
}

.tw-divide-pink-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.4) !important;
}

.tw-divide-pink-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.45) !important;
}

.tw-divide-pink-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.05) !important;
}

.tw-divide-pink-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.5) !important;
}

.tw-divide-pink-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.55) !important;
}

.tw-divide-pink-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.6) !important;
}

.tw-divide-pink-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.65) !important;
}

.tw-divide-pink-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.7) !important;
}

.tw-divide-pink-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.75) !important;
}

.tw-divide-pink-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.8) !important;
}

.tw-divide-pink-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.85) !important;
}

.tw-divide-pink-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.9) !important;
}

.tw-divide-pink-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(157 23 77 / 0.95) !important;
}

.tw-divide-pink-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(131 24 67 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0) !important;
}

.tw-divide-pink-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.1) !important;
}

.tw-divide-pink-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 1) !important;
}

.tw-divide-pink-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.15) !important;
}

.tw-divide-pink-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.2) !important;
}

.tw-divide-pink-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.25) !important;
}

.tw-divide-pink-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.3) !important;
}

.tw-divide-pink-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.35) !important;
}

.tw-divide-pink-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.4) !important;
}

.tw-divide-pink-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.45) !important;
}

.tw-divide-pink-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.05) !important;
}

.tw-divide-pink-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.5) !important;
}

.tw-divide-pink-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.55) !important;
}

.tw-divide-pink-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.6) !important;
}

.tw-divide-pink-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.65) !important;
}

.tw-divide-pink-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.7) !important;
}

.tw-divide-pink-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.75) !important;
}

.tw-divide-pink-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.8) !important;
}

.tw-divide-pink-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.85) !important;
}

.tw-divide-pink-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.9) !important;
}

.tw-divide-pink-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(131 24 67 / 0.95) !important;
}

.tw-divide-pink-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(80 7 36 / var(--tw-divide-opacity)) !important;
}

.tw-divide-pink-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0) !important;
}

.tw-divide-pink-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.1) !important;
}

.tw-divide-pink-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 1) !important;
}

.tw-divide-pink-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.15) !important;
}

.tw-divide-pink-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.2) !important;
}

.tw-divide-pink-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.25) !important;
}

.tw-divide-pink-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.3) !important;
}

.tw-divide-pink-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.35) !important;
}

.tw-divide-pink-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.4) !important;
}

.tw-divide-pink-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.45) !important;
}

.tw-divide-pink-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.05) !important;
}

.tw-divide-pink-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.5) !important;
}

.tw-divide-pink-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.55) !important;
}

.tw-divide-pink-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.6) !important;
}

.tw-divide-pink-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.65) !important;
}

.tw-divide-pink-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.7) !important;
}

.tw-divide-pink-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.75) !important;
}

.tw-divide-pink-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.8) !important;
}

.tw-divide-pink-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.85) !important;
}

.tw-divide-pink-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.9) !important;
}

.tw-divide-pink-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(80 7 36 / 0.95) !important;
}

.tw-divide-purple-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(243 232 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0) !important;
}

.tw-divide-purple-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.1) !important;
}

.tw-divide-purple-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 1) !important;
}

.tw-divide-purple-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.15) !important;
}

.tw-divide-purple-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.2) !important;
}

.tw-divide-purple-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.25) !important;
}

.tw-divide-purple-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.3) !important;
}

.tw-divide-purple-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.35) !important;
}

.tw-divide-purple-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.4) !important;
}

.tw-divide-purple-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.45) !important;
}

.tw-divide-purple-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.05) !important;
}

.tw-divide-purple-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.5) !important;
}

.tw-divide-purple-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.55) !important;
}

.tw-divide-purple-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.6) !important;
}

.tw-divide-purple-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.65) !important;
}

.tw-divide-purple-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.7) !important;
}

.tw-divide-purple-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.75) !important;
}

.tw-divide-purple-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.8) !important;
}

.tw-divide-purple-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.85) !important;
}

.tw-divide-purple-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.9) !important;
}

.tw-divide-purple-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(243 232 255 / 0.95) !important;
}

.tw-divide-purple-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(233 213 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0) !important;
}

.tw-divide-purple-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.1) !important;
}

.tw-divide-purple-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 1) !important;
}

.tw-divide-purple-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.15) !important;
}

.tw-divide-purple-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.2) !important;
}

.tw-divide-purple-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.25) !important;
}

.tw-divide-purple-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.3) !important;
}

.tw-divide-purple-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.35) !important;
}

.tw-divide-purple-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.4) !important;
}

.tw-divide-purple-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.45) !important;
}

.tw-divide-purple-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.05) !important;
}

.tw-divide-purple-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.5) !important;
}

.tw-divide-purple-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.55) !important;
}

.tw-divide-purple-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.6) !important;
}

.tw-divide-purple-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.65) !important;
}

.tw-divide-purple-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.7) !important;
}

.tw-divide-purple-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.75) !important;
}

.tw-divide-purple-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.8) !important;
}

.tw-divide-purple-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.85) !important;
}

.tw-divide-purple-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.9) !important;
}

.tw-divide-purple-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(233 213 255 / 0.95) !important;
}

.tw-divide-purple-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(216 180 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0) !important;
}

.tw-divide-purple-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.1) !important;
}

.tw-divide-purple-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 1) !important;
}

.tw-divide-purple-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.15) !important;
}

.tw-divide-purple-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.2) !important;
}

.tw-divide-purple-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.25) !important;
}

.tw-divide-purple-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.3) !important;
}

.tw-divide-purple-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.35) !important;
}

.tw-divide-purple-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.4) !important;
}

.tw-divide-purple-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.45) !important;
}

.tw-divide-purple-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.05) !important;
}

.tw-divide-purple-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.5) !important;
}

.tw-divide-purple-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.55) !important;
}

.tw-divide-purple-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.6) !important;
}

.tw-divide-purple-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.65) !important;
}

.tw-divide-purple-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.7) !important;
}

.tw-divide-purple-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.75) !important;
}

.tw-divide-purple-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.8) !important;
}

.tw-divide-purple-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.85) !important;
}

.tw-divide-purple-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.9) !important;
}

.tw-divide-purple-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(216 180 254 / 0.95) !important;
}

.tw-divide-purple-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(192 132 252 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0) !important;
}

.tw-divide-purple-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.1) !important;
}

.tw-divide-purple-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 1) !important;
}

.tw-divide-purple-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.15) !important;
}

.tw-divide-purple-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.2) !important;
}

.tw-divide-purple-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.25) !important;
}

.tw-divide-purple-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.3) !important;
}

.tw-divide-purple-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.35) !important;
}

.tw-divide-purple-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.4) !important;
}

.tw-divide-purple-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.45) !important;
}

.tw-divide-purple-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.05) !important;
}

.tw-divide-purple-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.5) !important;
}

.tw-divide-purple-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.55) !important;
}

.tw-divide-purple-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.6) !important;
}

.tw-divide-purple-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.65) !important;
}

.tw-divide-purple-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.7) !important;
}

.tw-divide-purple-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.75) !important;
}

.tw-divide-purple-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.8) !important;
}

.tw-divide-purple-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.85) !important;
}

.tw-divide-purple-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.9) !important;
}

.tw-divide-purple-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(192 132 252 / 0.95) !important;
}

.tw-divide-purple-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(250 245 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0) !important;
}

.tw-divide-purple-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.1) !important;
}

.tw-divide-purple-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 1) !important;
}

.tw-divide-purple-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.15) !important;
}

.tw-divide-purple-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.2) !important;
}

.tw-divide-purple-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.25) !important;
}

.tw-divide-purple-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.3) !important;
}

.tw-divide-purple-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.35) !important;
}

.tw-divide-purple-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.4) !important;
}

.tw-divide-purple-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.45) !important;
}

.tw-divide-purple-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.05) !important;
}

.tw-divide-purple-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.5) !important;
}

.tw-divide-purple-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.55) !important;
}

.tw-divide-purple-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.6) !important;
}

.tw-divide-purple-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.65) !important;
}

.tw-divide-purple-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.7) !important;
}

.tw-divide-purple-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.75) !important;
}

.tw-divide-purple-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.8) !important;
}

.tw-divide-purple-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.85) !important;
}

.tw-divide-purple-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.9) !important;
}

.tw-divide-purple-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 245 255 / 0.95) !important;
}

.tw-divide-purple-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(168 85 247 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0) !important;
}

.tw-divide-purple-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.1) !important;
}

.tw-divide-purple-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 1) !important;
}

.tw-divide-purple-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.15) !important;
}

.tw-divide-purple-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.2) !important;
}

.tw-divide-purple-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.25) !important;
}

.tw-divide-purple-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.3) !important;
}

.tw-divide-purple-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.35) !important;
}

.tw-divide-purple-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.4) !important;
}

.tw-divide-purple-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.45) !important;
}

.tw-divide-purple-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.05) !important;
}

.tw-divide-purple-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.5) !important;
}

.tw-divide-purple-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.55) !important;
}

.tw-divide-purple-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.6) !important;
}

.tw-divide-purple-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.65) !important;
}

.tw-divide-purple-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.7) !important;
}

.tw-divide-purple-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.75) !important;
}

.tw-divide-purple-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.8) !important;
}

.tw-divide-purple-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.85) !important;
}

.tw-divide-purple-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.9) !important;
}

.tw-divide-purple-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 85 247 / 0.95) !important;
}

.tw-divide-purple-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(147 51 234 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0) !important;
}

.tw-divide-purple-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.1) !important;
}

.tw-divide-purple-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 1) !important;
}

.tw-divide-purple-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.15) !important;
}

.tw-divide-purple-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.2) !important;
}

.tw-divide-purple-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.25) !important;
}

.tw-divide-purple-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.3) !important;
}

.tw-divide-purple-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.35) !important;
}

.tw-divide-purple-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.4) !important;
}

.tw-divide-purple-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.45) !important;
}

.tw-divide-purple-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.05) !important;
}

.tw-divide-purple-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.5) !important;
}

.tw-divide-purple-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.55) !important;
}

.tw-divide-purple-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.6) !important;
}

.tw-divide-purple-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.65) !important;
}

.tw-divide-purple-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.7) !important;
}

.tw-divide-purple-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.75) !important;
}

.tw-divide-purple-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.8) !important;
}

.tw-divide-purple-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.85) !important;
}

.tw-divide-purple-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.9) !important;
}

.tw-divide-purple-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(147 51 234 / 0.95) !important;
}

.tw-divide-purple-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(126 34 206 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0) !important;
}

.tw-divide-purple-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.1) !important;
}

.tw-divide-purple-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 1) !important;
}

.tw-divide-purple-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.15) !important;
}

.tw-divide-purple-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.2) !important;
}

.tw-divide-purple-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.25) !important;
}

.tw-divide-purple-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.3) !important;
}

.tw-divide-purple-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.35) !important;
}

.tw-divide-purple-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.4) !important;
}

.tw-divide-purple-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.45) !important;
}

.tw-divide-purple-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.05) !important;
}

.tw-divide-purple-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.5) !important;
}

.tw-divide-purple-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.55) !important;
}

.tw-divide-purple-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.6) !important;
}

.tw-divide-purple-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.65) !important;
}

.tw-divide-purple-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.7) !important;
}

.tw-divide-purple-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.75) !important;
}

.tw-divide-purple-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.8) !important;
}

.tw-divide-purple-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.85) !important;
}

.tw-divide-purple-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.9) !important;
}

.tw-divide-purple-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(126 34 206 / 0.95) !important;
}

.tw-divide-purple-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(107 33 168 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0) !important;
}

.tw-divide-purple-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.1) !important;
}

.tw-divide-purple-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 1) !important;
}

.tw-divide-purple-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.15) !important;
}

.tw-divide-purple-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.2) !important;
}

.tw-divide-purple-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.25) !important;
}

.tw-divide-purple-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.3) !important;
}

.tw-divide-purple-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.35) !important;
}

.tw-divide-purple-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.4) !important;
}

.tw-divide-purple-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.45) !important;
}

.tw-divide-purple-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.05) !important;
}

.tw-divide-purple-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.5) !important;
}

.tw-divide-purple-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.55) !important;
}

.tw-divide-purple-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.6) !important;
}

.tw-divide-purple-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.65) !important;
}

.tw-divide-purple-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.7) !important;
}

.tw-divide-purple-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.75) !important;
}

.tw-divide-purple-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.8) !important;
}

.tw-divide-purple-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.85) !important;
}

.tw-divide-purple-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.9) !important;
}

.tw-divide-purple-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(107 33 168 / 0.95) !important;
}

.tw-divide-purple-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(88 28 135 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0) !important;
}

.tw-divide-purple-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.1) !important;
}

.tw-divide-purple-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 1) !important;
}

.tw-divide-purple-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.15) !important;
}

.tw-divide-purple-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.2) !important;
}

.tw-divide-purple-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.25) !important;
}

.tw-divide-purple-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.3) !important;
}

.tw-divide-purple-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.35) !important;
}

.tw-divide-purple-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.4) !important;
}

.tw-divide-purple-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.45) !important;
}

.tw-divide-purple-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.05) !important;
}

.tw-divide-purple-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.5) !important;
}

.tw-divide-purple-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.55) !important;
}

.tw-divide-purple-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.6) !important;
}

.tw-divide-purple-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.65) !important;
}

.tw-divide-purple-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.7) !important;
}

.tw-divide-purple-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.75) !important;
}

.tw-divide-purple-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.8) !important;
}

.tw-divide-purple-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.85) !important;
}

.tw-divide-purple-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.9) !important;
}

.tw-divide-purple-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(88 28 135 / 0.95) !important;
}

.tw-divide-purple-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(59 7 100 / var(--tw-divide-opacity)) !important;
}

.tw-divide-purple-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0) !important;
}

.tw-divide-purple-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.1) !important;
}

.tw-divide-purple-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 1) !important;
}

.tw-divide-purple-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.15) !important;
}

.tw-divide-purple-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.2) !important;
}

.tw-divide-purple-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.25) !important;
}

.tw-divide-purple-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.3) !important;
}

.tw-divide-purple-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.35) !important;
}

.tw-divide-purple-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.4) !important;
}

.tw-divide-purple-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.45) !important;
}

.tw-divide-purple-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.05) !important;
}

.tw-divide-purple-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.5) !important;
}

.tw-divide-purple-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.55) !important;
}

.tw-divide-purple-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.6) !important;
}

.tw-divide-purple-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.65) !important;
}

.tw-divide-purple-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.7) !important;
}

.tw-divide-purple-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.75) !important;
}

.tw-divide-purple-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.8) !important;
}

.tw-divide-purple-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.85) !important;
}

.tw-divide-purple-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.9) !important;
}

.tw-divide-purple-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(59 7 100 / 0.95) !important;
}

.tw-divide-red-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 226 226 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0) !important;
}

.tw-divide-red-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.1) !important;
}

.tw-divide-red-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 1) !important;
}

.tw-divide-red-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.15) !important;
}

.tw-divide-red-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.2) !important;
}

.tw-divide-red-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.25) !important;
}

.tw-divide-red-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.3) !important;
}

.tw-divide-red-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.35) !important;
}

.tw-divide-red-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.4) !important;
}

.tw-divide-red-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.45) !important;
}

.tw-divide-red-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.05) !important;
}

.tw-divide-red-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.5) !important;
}

.tw-divide-red-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.55) !important;
}

.tw-divide-red-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.6) !important;
}

.tw-divide-red-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.65) !important;
}

.tw-divide-red-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.7) !important;
}

.tw-divide-red-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.75) !important;
}

.tw-divide-red-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.8) !important;
}

.tw-divide-red-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.85) !important;
}

.tw-divide-red-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.9) !important;
}

.tw-divide-red-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 226 226 / 0.95) !important;
}

.tw-divide-red-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 202 202 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0) !important;
}

.tw-divide-red-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.1) !important;
}

.tw-divide-red-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 1) !important;
}

.tw-divide-red-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.15) !important;
}

.tw-divide-red-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.2) !important;
}

.tw-divide-red-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.25) !important;
}

.tw-divide-red-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.3) !important;
}

.tw-divide-red-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.35) !important;
}

.tw-divide-red-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.4) !important;
}

.tw-divide-red-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.45) !important;
}

.tw-divide-red-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.05) !important;
}

.tw-divide-red-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.5) !important;
}

.tw-divide-red-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.55) !important;
}

.tw-divide-red-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.6) !important;
}

.tw-divide-red-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.65) !important;
}

.tw-divide-red-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.7) !important;
}

.tw-divide-red-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.75) !important;
}

.tw-divide-red-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.8) !important;
}

.tw-divide-red-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.85) !important;
}

.tw-divide-red-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.9) !important;
}

.tw-divide-red-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 202 202 / 0.95) !important;
}

.tw-divide-red-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(252 165 165 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0) !important;
}

.tw-divide-red-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.1) !important;
}

.tw-divide-red-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 1) !important;
}

.tw-divide-red-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.15) !important;
}

.tw-divide-red-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.2) !important;
}

.tw-divide-red-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.25) !important;
}

.tw-divide-red-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.3) !important;
}

.tw-divide-red-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.35) !important;
}

.tw-divide-red-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.4) !important;
}

.tw-divide-red-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.45) !important;
}

.tw-divide-red-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.05) !important;
}

.tw-divide-red-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.5) !important;
}

.tw-divide-red-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.55) !important;
}

.tw-divide-red-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.6) !important;
}

.tw-divide-red-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.65) !important;
}

.tw-divide-red-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.7) !important;
}

.tw-divide-red-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.75) !important;
}

.tw-divide-red-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.8) !important;
}

.tw-divide-red-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.85) !important;
}

.tw-divide-red-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.9) !important;
}

.tw-divide-red-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(252 165 165 / 0.95) !important;
}

.tw-divide-red-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(248 113 113 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0) !important;
}

.tw-divide-red-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.1) !important;
}

.tw-divide-red-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 1) !important;
}

.tw-divide-red-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.15) !important;
}

.tw-divide-red-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.2) !important;
}

.tw-divide-red-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.25) !important;
}

.tw-divide-red-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.3) !important;
}

.tw-divide-red-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.35) !important;
}

.tw-divide-red-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.4) !important;
}

.tw-divide-red-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.45) !important;
}

.tw-divide-red-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.05) !important;
}

.tw-divide-red-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.5) !important;
}

.tw-divide-red-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.55) !important;
}

.tw-divide-red-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.6) !important;
}

.tw-divide-red-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.65) !important;
}

.tw-divide-red-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.7) !important;
}

.tw-divide-red-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.75) !important;
}

.tw-divide-red-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.8) !important;
}

.tw-divide-red-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.85) !important;
}

.tw-divide-red-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.9) !important;
}

.tw-divide-red-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 113 113 / 0.95) !important;
}

.tw-divide-red-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 242 242 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0) !important;
}

.tw-divide-red-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.1) !important;
}

.tw-divide-red-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 1) !important;
}

.tw-divide-red-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.15) !important;
}

.tw-divide-red-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.2) !important;
}

.tw-divide-red-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.25) !important;
}

.tw-divide-red-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.3) !important;
}

.tw-divide-red-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.35) !important;
}

.tw-divide-red-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.4) !important;
}

.tw-divide-red-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.45) !important;
}

.tw-divide-red-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.05) !important;
}

.tw-divide-red-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.5) !important;
}

.tw-divide-red-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.55) !important;
}

.tw-divide-red-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.6) !important;
}

.tw-divide-red-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.65) !important;
}

.tw-divide-red-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.7) !important;
}

.tw-divide-red-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.75) !important;
}

.tw-divide-red-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.8) !important;
}

.tw-divide-red-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.85) !important;
}

.tw-divide-red-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.9) !important;
}

.tw-divide-red-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 242 242 / 0.95) !important;
}

.tw-divide-red-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0) !important;
}

.tw-divide-red-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.1) !important;
}

.tw-divide-red-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 1) !important;
}

.tw-divide-red-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.15) !important;
}

.tw-divide-red-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.2) !important;
}

.tw-divide-red-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.25) !important;
}

.tw-divide-red-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.3) !important;
}

.tw-divide-red-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.35) !important;
}

.tw-divide-red-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.4) !important;
}

.tw-divide-red-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.45) !important;
}

.tw-divide-red-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.05) !important;
}

.tw-divide-red-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.5) !important;
}

.tw-divide-red-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.55) !important;
}

.tw-divide-red-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.6) !important;
}

.tw-divide-red-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.65) !important;
}

.tw-divide-red-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.7) !important;
}

.tw-divide-red-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.75) !important;
}

.tw-divide-red-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.8) !important;
}

.tw-divide-red-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.85) !important;
}

.tw-divide-red-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.9) !important;
}

.tw-divide-red-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(239 68 68 / 0.95) !important;
}

.tw-divide-red-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(220 38 38 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0) !important;
}

.tw-divide-red-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.1) !important;
}

.tw-divide-red-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 1) !important;
}

.tw-divide-red-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.15) !important;
}

.tw-divide-red-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.2) !important;
}

.tw-divide-red-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.25) !important;
}

.tw-divide-red-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.3) !important;
}

.tw-divide-red-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.35) !important;
}

.tw-divide-red-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.4) !important;
}

.tw-divide-red-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.45) !important;
}

.tw-divide-red-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.05) !important;
}

.tw-divide-red-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.5) !important;
}

.tw-divide-red-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.55) !important;
}

.tw-divide-red-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.6) !important;
}

.tw-divide-red-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.65) !important;
}

.tw-divide-red-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.7) !important;
}

.tw-divide-red-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.75) !important;
}

.tw-divide-red-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.8) !important;
}

.tw-divide-red-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.85) !important;
}

.tw-divide-red-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.9) !important;
}

.tw-divide-red-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(220 38 38 / 0.95) !important;
}

.tw-divide-red-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(185 28 28 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0) !important;
}

.tw-divide-red-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.1) !important;
}

.tw-divide-red-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 1) !important;
}

.tw-divide-red-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.15) !important;
}

.tw-divide-red-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.2) !important;
}

.tw-divide-red-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.25) !important;
}

.tw-divide-red-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.3) !important;
}

.tw-divide-red-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.35) !important;
}

.tw-divide-red-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.4) !important;
}

.tw-divide-red-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.45) !important;
}

.tw-divide-red-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.05) !important;
}

.tw-divide-red-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.5) !important;
}

.tw-divide-red-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.55) !important;
}

.tw-divide-red-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.6) !important;
}

.tw-divide-red-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.65) !important;
}

.tw-divide-red-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.7) !important;
}

.tw-divide-red-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.75) !important;
}

.tw-divide-red-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.8) !important;
}

.tw-divide-red-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.85) !important;
}

.tw-divide-red-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.9) !important;
}

.tw-divide-red-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(185 28 28 / 0.95) !important;
}

.tw-divide-red-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(153 27 27 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0) !important;
}

.tw-divide-red-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.1) !important;
}

.tw-divide-red-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 1) !important;
}

.tw-divide-red-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.15) !important;
}

.tw-divide-red-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.2) !important;
}

.tw-divide-red-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.25) !important;
}

.tw-divide-red-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.3) !important;
}

.tw-divide-red-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.35) !important;
}

.tw-divide-red-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.4) !important;
}

.tw-divide-red-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.45) !important;
}

.tw-divide-red-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.05) !important;
}

.tw-divide-red-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.5) !important;
}

.tw-divide-red-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.55) !important;
}

.tw-divide-red-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.6) !important;
}

.tw-divide-red-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.65) !important;
}

.tw-divide-red-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.7) !important;
}

.tw-divide-red-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.75) !important;
}

.tw-divide-red-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.8) !important;
}

.tw-divide-red-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.85) !important;
}

.tw-divide-red-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.9) !important;
}

.tw-divide-red-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 27 27 / 0.95) !important;
}

.tw-divide-red-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(127 29 29 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0) !important;
}

.tw-divide-red-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.1) !important;
}

.tw-divide-red-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 1) !important;
}

.tw-divide-red-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.15) !important;
}

.tw-divide-red-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.2) !important;
}

.tw-divide-red-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.25) !important;
}

.tw-divide-red-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.3) !important;
}

.tw-divide-red-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.35) !important;
}

.tw-divide-red-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.4) !important;
}

.tw-divide-red-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.45) !important;
}

.tw-divide-red-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.05) !important;
}

.tw-divide-red-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.5) !important;
}

.tw-divide-red-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.55) !important;
}

.tw-divide-red-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.6) !important;
}

.tw-divide-red-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.65) !important;
}

.tw-divide-red-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.7) !important;
}

.tw-divide-red-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.75) !important;
}

.tw-divide-red-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.8) !important;
}

.tw-divide-red-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.85) !important;
}

.tw-divide-red-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.9) !important;
}

.tw-divide-red-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(127 29 29 / 0.95) !important;
}

.tw-divide-red-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(69 10 10 / var(--tw-divide-opacity)) !important;
}

.tw-divide-red-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0) !important;
}

.tw-divide-red-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.1) !important;
}

.tw-divide-red-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 1) !important;
}

.tw-divide-red-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.15) !important;
}

.tw-divide-red-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.2) !important;
}

.tw-divide-red-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.25) !important;
}

.tw-divide-red-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.3) !important;
}

.tw-divide-red-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.35) !important;
}

.tw-divide-red-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.4) !important;
}

.tw-divide-red-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.45) !important;
}

.tw-divide-red-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.05) !important;
}

.tw-divide-red-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.5) !important;
}

.tw-divide-red-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.55) !important;
}

.tw-divide-red-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.6) !important;
}

.tw-divide-red-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.65) !important;
}

.tw-divide-red-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.7) !important;
}

.tw-divide-red-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.75) !important;
}

.tw-divide-red-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.8) !important;
}

.tw-divide-red-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.85) !important;
}

.tw-divide-red-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.9) !important;
}

.tw-divide-red-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(69 10 10 / 0.95) !important;
}

.tw-divide-rose-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 228 230 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0) !important;
}

.tw-divide-rose-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.1) !important;
}

.tw-divide-rose-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 1) !important;
}

.tw-divide-rose-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.15) !important;
}

.tw-divide-rose-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.2) !important;
}

.tw-divide-rose-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.25) !important;
}

.tw-divide-rose-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.3) !important;
}

.tw-divide-rose-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.35) !important;
}

.tw-divide-rose-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.4) !important;
}

.tw-divide-rose-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.45) !important;
}

.tw-divide-rose-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.05) !important;
}

.tw-divide-rose-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.5) !important;
}

.tw-divide-rose-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.55) !important;
}

.tw-divide-rose-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.6) !important;
}

.tw-divide-rose-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.65) !important;
}

.tw-divide-rose-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.7) !important;
}

.tw-divide-rose-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.75) !important;
}

.tw-divide-rose-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.8) !important;
}

.tw-divide-rose-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.85) !important;
}

.tw-divide-rose-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.9) !important;
}

.tw-divide-rose-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 228 230 / 0.95) !important;
}

.tw-divide-rose-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 205 211 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0) !important;
}

.tw-divide-rose-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.1) !important;
}

.tw-divide-rose-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 1) !important;
}

.tw-divide-rose-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.15) !important;
}

.tw-divide-rose-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.2) !important;
}

.tw-divide-rose-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.25) !important;
}

.tw-divide-rose-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.3) !important;
}

.tw-divide-rose-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.35) !important;
}

.tw-divide-rose-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.4) !important;
}

.tw-divide-rose-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.45) !important;
}

.tw-divide-rose-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.05) !important;
}

.tw-divide-rose-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.5) !important;
}

.tw-divide-rose-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.55) !important;
}

.tw-divide-rose-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.6) !important;
}

.tw-divide-rose-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.65) !important;
}

.tw-divide-rose-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.7) !important;
}

.tw-divide-rose-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.75) !important;
}

.tw-divide-rose-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.8) !important;
}

.tw-divide-rose-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.85) !important;
}

.tw-divide-rose-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.9) !important;
}

.tw-divide-rose-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 205 211 / 0.95) !important;
}

.tw-divide-rose-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(253 164 175 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0) !important;
}

.tw-divide-rose-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.1) !important;
}

.tw-divide-rose-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 1) !important;
}

.tw-divide-rose-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.15) !important;
}

.tw-divide-rose-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.2) !important;
}

.tw-divide-rose-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.25) !important;
}

.tw-divide-rose-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.3) !important;
}

.tw-divide-rose-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.35) !important;
}

.tw-divide-rose-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.4) !important;
}

.tw-divide-rose-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.45) !important;
}

.tw-divide-rose-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.05) !important;
}

.tw-divide-rose-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.5) !important;
}

.tw-divide-rose-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.55) !important;
}

.tw-divide-rose-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.6) !important;
}

.tw-divide-rose-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.65) !important;
}

.tw-divide-rose-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.7) !important;
}

.tw-divide-rose-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.75) !important;
}

.tw-divide-rose-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.8) !important;
}

.tw-divide-rose-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.85) !important;
}

.tw-divide-rose-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.9) !important;
}

.tw-divide-rose-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 164 175 / 0.95) !important;
}

.tw-divide-rose-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(251 113 133 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0) !important;
}

.tw-divide-rose-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.1) !important;
}

.tw-divide-rose-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 1) !important;
}

.tw-divide-rose-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.15) !important;
}

.tw-divide-rose-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.2) !important;
}

.tw-divide-rose-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.25) !important;
}

.tw-divide-rose-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.3) !important;
}

.tw-divide-rose-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.35) !important;
}

.tw-divide-rose-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.4) !important;
}

.tw-divide-rose-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.45) !important;
}

.tw-divide-rose-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.05) !important;
}

.tw-divide-rose-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.5) !important;
}

.tw-divide-rose-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.55) !important;
}

.tw-divide-rose-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.6) !important;
}

.tw-divide-rose-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.65) !important;
}

.tw-divide-rose-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.7) !important;
}

.tw-divide-rose-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.75) !important;
}

.tw-divide-rose-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.8) !important;
}

.tw-divide-rose-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.85) !important;
}

.tw-divide-rose-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.9) !important;
}

.tw-divide-rose-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(251 113 133 / 0.95) !important;
}

.tw-divide-rose-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 241 242 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0) !important;
}

.tw-divide-rose-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.1) !important;
}

.tw-divide-rose-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 1) !important;
}

.tw-divide-rose-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.15) !important;
}

.tw-divide-rose-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.2) !important;
}

.tw-divide-rose-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.25) !important;
}

.tw-divide-rose-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.3) !important;
}

.tw-divide-rose-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.35) !important;
}

.tw-divide-rose-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.4) !important;
}

.tw-divide-rose-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.45) !important;
}

.tw-divide-rose-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.05) !important;
}

.tw-divide-rose-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.5) !important;
}

.tw-divide-rose-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.55) !important;
}

.tw-divide-rose-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.6) !important;
}

.tw-divide-rose-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.65) !important;
}

.tw-divide-rose-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.7) !important;
}

.tw-divide-rose-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.75) !important;
}

.tw-divide-rose-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.8) !important;
}

.tw-divide-rose-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.85) !important;
}

.tw-divide-rose-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.9) !important;
}

.tw-divide-rose-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 241 242 / 0.95) !important;
}

.tw-divide-rose-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(244 63 94 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0) !important;
}

.tw-divide-rose-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.1) !important;
}

.tw-divide-rose-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 1) !important;
}

.tw-divide-rose-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.15) !important;
}

.tw-divide-rose-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.2) !important;
}

.tw-divide-rose-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.25) !important;
}

.tw-divide-rose-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.3) !important;
}

.tw-divide-rose-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.35) !important;
}

.tw-divide-rose-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.4) !important;
}

.tw-divide-rose-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.45) !important;
}

.tw-divide-rose-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.05) !important;
}

.tw-divide-rose-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.5) !important;
}

.tw-divide-rose-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.55) !important;
}

.tw-divide-rose-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.6) !important;
}

.tw-divide-rose-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.65) !important;
}

.tw-divide-rose-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.7) !important;
}

.tw-divide-rose-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.75) !important;
}

.tw-divide-rose-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.8) !important;
}

.tw-divide-rose-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.85) !important;
}

.tw-divide-rose-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.9) !important;
}

.tw-divide-rose-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 63 94 / 0.95) !important;
}

.tw-divide-rose-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(225 29 72 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0) !important;
}

.tw-divide-rose-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.1) !important;
}

.tw-divide-rose-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 1) !important;
}

.tw-divide-rose-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.15) !important;
}

.tw-divide-rose-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.2) !important;
}

.tw-divide-rose-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.25) !important;
}

.tw-divide-rose-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.3) !important;
}

.tw-divide-rose-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.35) !important;
}

.tw-divide-rose-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.4) !important;
}

.tw-divide-rose-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.45) !important;
}

.tw-divide-rose-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.05) !important;
}

.tw-divide-rose-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.5) !important;
}

.tw-divide-rose-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.55) !important;
}

.tw-divide-rose-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.6) !important;
}

.tw-divide-rose-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.65) !important;
}

.tw-divide-rose-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.7) !important;
}

.tw-divide-rose-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.75) !important;
}

.tw-divide-rose-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.8) !important;
}

.tw-divide-rose-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.85) !important;
}

.tw-divide-rose-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.9) !important;
}

.tw-divide-rose-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(225 29 72 / 0.95) !important;
}

.tw-divide-rose-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(190 18 60 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0) !important;
}

.tw-divide-rose-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.1) !important;
}

.tw-divide-rose-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 1) !important;
}

.tw-divide-rose-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.15) !important;
}

.tw-divide-rose-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.2) !important;
}

.tw-divide-rose-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.25) !important;
}

.tw-divide-rose-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.3) !important;
}

.tw-divide-rose-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.35) !important;
}

.tw-divide-rose-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.4) !important;
}

.tw-divide-rose-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.45) !important;
}

.tw-divide-rose-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.05) !important;
}

.tw-divide-rose-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.5) !important;
}

.tw-divide-rose-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.55) !important;
}

.tw-divide-rose-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.6) !important;
}

.tw-divide-rose-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.65) !important;
}

.tw-divide-rose-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.7) !important;
}

.tw-divide-rose-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.75) !important;
}

.tw-divide-rose-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.8) !important;
}

.tw-divide-rose-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.85) !important;
}

.tw-divide-rose-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.9) !important;
}

.tw-divide-rose-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(190 18 60 / 0.95) !important;
}

.tw-divide-rose-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(159 18 57 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0) !important;
}

.tw-divide-rose-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.1) !important;
}

.tw-divide-rose-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 1) !important;
}

.tw-divide-rose-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.15) !important;
}

.tw-divide-rose-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.2) !important;
}

.tw-divide-rose-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.25) !important;
}

.tw-divide-rose-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.3) !important;
}

.tw-divide-rose-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.35) !important;
}

.tw-divide-rose-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.4) !important;
}

.tw-divide-rose-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.45) !important;
}

.tw-divide-rose-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.05) !important;
}

.tw-divide-rose-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.5) !important;
}

.tw-divide-rose-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.55) !important;
}

.tw-divide-rose-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.6) !important;
}

.tw-divide-rose-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.65) !important;
}

.tw-divide-rose-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.7) !important;
}

.tw-divide-rose-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.75) !important;
}

.tw-divide-rose-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.8) !important;
}

.tw-divide-rose-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.85) !important;
}

.tw-divide-rose-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.9) !important;
}

.tw-divide-rose-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(159 18 57 / 0.95) !important;
}

.tw-divide-rose-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(136 19 55 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0) !important;
}

.tw-divide-rose-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.1) !important;
}

.tw-divide-rose-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 1) !important;
}

.tw-divide-rose-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.15) !important;
}

.tw-divide-rose-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.2) !important;
}

.tw-divide-rose-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.25) !important;
}

.tw-divide-rose-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.3) !important;
}

.tw-divide-rose-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.35) !important;
}

.tw-divide-rose-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.4) !important;
}

.tw-divide-rose-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.45) !important;
}

.tw-divide-rose-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.05) !important;
}

.tw-divide-rose-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.5) !important;
}

.tw-divide-rose-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.55) !important;
}

.tw-divide-rose-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.6) !important;
}

.tw-divide-rose-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.65) !important;
}

.tw-divide-rose-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.7) !important;
}

.tw-divide-rose-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.75) !important;
}

.tw-divide-rose-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.8) !important;
}

.tw-divide-rose-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.85) !important;
}

.tw-divide-rose-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.9) !important;
}

.tw-divide-rose-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(136 19 55 / 0.95) !important;
}

.tw-divide-rose-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(76 5 25 / var(--tw-divide-opacity)) !important;
}

.tw-divide-rose-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0) !important;
}

.tw-divide-rose-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.1) !important;
}

.tw-divide-rose-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 1) !important;
}

.tw-divide-rose-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.15) !important;
}

.tw-divide-rose-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.2) !important;
}

.tw-divide-rose-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.25) !important;
}

.tw-divide-rose-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.3) !important;
}

.tw-divide-rose-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.35) !important;
}

.tw-divide-rose-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.4) !important;
}

.tw-divide-rose-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.45) !important;
}

.tw-divide-rose-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.05) !important;
}

.tw-divide-rose-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.5) !important;
}

.tw-divide-rose-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.55) !important;
}

.tw-divide-rose-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.6) !important;
}

.tw-divide-rose-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.65) !important;
}

.tw-divide-rose-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.7) !important;
}

.tw-divide-rose-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.75) !important;
}

.tw-divide-rose-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.8) !important;
}

.tw-divide-rose-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.85) !important;
}

.tw-divide-rose-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.9) !important;
}

.tw-divide-rose-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 5 25 / 0.95) !important;
}

.tw-divide-sky-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(224 242 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0) !important;
}

.tw-divide-sky-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.1) !important;
}

.tw-divide-sky-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 1) !important;
}

.tw-divide-sky-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.15) !important;
}

.tw-divide-sky-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.2) !important;
}

.tw-divide-sky-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.25) !important;
}

.tw-divide-sky-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.3) !important;
}

.tw-divide-sky-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.35) !important;
}

.tw-divide-sky-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.4) !important;
}

.tw-divide-sky-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.45) !important;
}

.tw-divide-sky-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.05) !important;
}

.tw-divide-sky-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.5) !important;
}

.tw-divide-sky-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.55) !important;
}

.tw-divide-sky-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.6) !important;
}

.tw-divide-sky-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.65) !important;
}

.tw-divide-sky-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.7) !important;
}

.tw-divide-sky-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.75) !important;
}

.tw-divide-sky-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.8) !important;
}

.tw-divide-sky-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.85) !important;
}

.tw-divide-sky-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.9) !important;
}

.tw-divide-sky-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(224 242 254 / 0.95) !important;
}

.tw-divide-sky-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(186 230 253 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0) !important;
}

.tw-divide-sky-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.1) !important;
}

.tw-divide-sky-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 1) !important;
}

.tw-divide-sky-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.15) !important;
}

.tw-divide-sky-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.2) !important;
}

.tw-divide-sky-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.25) !important;
}

.tw-divide-sky-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.3) !important;
}

.tw-divide-sky-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.35) !important;
}

.tw-divide-sky-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.4) !important;
}

.tw-divide-sky-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.45) !important;
}

.tw-divide-sky-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.05) !important;
}

.tw-divide-sky-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.5) !important;
}

.tw-divide-sky-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.55) !important;
}

.tw-divide-sky-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.6) !important;
}

.tw-divide-sky-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.65) !important;
}

.tw-divide-sky-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.7) !important;
}

.tw-divide-sky-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.75) !important;
}

.tw-divide-sky-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.8) !important;
}

.tw-divide-sky-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.85) !important;
}

.tw-divide-sky-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.9) !important;
}

.tw-divide-sky-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(186 230 253 / 0.95) !important;
}

.tw-divide-sky-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(125 211 252 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0) !important;
}

.tw-divide-sky-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.1) !important;
}

.tw-divide-sky-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 1) !important;
}

.tw-divide-sky-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.15) !important;
}

.tw-divide-sky-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.2) !important;
}

.tw-divide-sky-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.25) !important;
}

.tw-divide-sky-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.3) !important;
}

.tw-divide-sky-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.35) !important;
}

.tw-divide-sky-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.4) !important;
}

.tw-divide-sky-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.45) !important;
}

.tw-divide-sky-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.05) !important;
}

.tw-divide-sky-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.5) !important;
}

.tw-divide-sky-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.55) !important;
}

.tw-divide-sky-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.6) !important;
}

.tw-divide-sky-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.65) !important;
}

.tw-divide-sky-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.7) !important;
}

.tw-divide-sky-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.75) !important;
}

.tw-divide-sky-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.8) !important;
}

.tw-divide-sky-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.85) !important;
}

.tw-divide-sky-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.9) !important;
}

.tw-divide-sky-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(125 211 252 / 0.95) !important;
}

.tw-divide-sky-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(56 189 248 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0) !important;
}

.tw-divide-sky-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.1) !important;
}

.tw-divide-sky-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 1) !important;
}

.tw-divide-sky-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.15) !important;
}

.tw-divide-sky-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.2) !important;
}

.tw-divide-sky-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.25) !important;
}

.tw-divide-sky-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.3) !important;
}

.tw-divide-sky-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.35) !important;
}

.tw-divide-sky-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.4) !important;
}

.tw-divide-sky-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.45) !important;
}

.tw-divide-sky-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.05) !important;
}

.tw-divide-sky-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.5) !important;
}

.tw-divide-sky-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.55) !important;
}

.tw-divide-sky-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.6) !important;
}

.tw-divide-sky-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.65) !important;
}

.tw-divide-sky-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.7) !important;
}

.tw-divide-sky-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.75) !important;
}

.tw-divide-sky-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.8) !important;
}

.tw-divide-sky-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.85) !important;
}

.tw-divide-sky-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.9) !important;
}

.tw-divide-sky-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(56 189 248 / 0.95) !important;
}

.tw-divide-sky-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(240 249 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0) !important;
}

.tw-divide-sky-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.1) !important;
}

.tw-divide-sky-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 1) !important;
}

.tw-divide-sky-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.15) !important;
}

.tw-divide-sky-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.2) !important;
}

.tw-divide-sky-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.25) !important;
}

.tw-divide-sky-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.3) !important;
}

.tw-divide-sky-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.35) !important;
}

.tw-divide-sky-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.4) !important;
}

.tw-divide-sky-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.45) !important;
}

.tw-divide-sky-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.05) !important;
}

.tw-divide-sky-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.5) !important;
}

.tw-divide-sky-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.55) !important;
}

.tw-divide-sky-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.6) !important;
}

.tw-divide-sky-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.65) !important;
}

.tw-divide-sky-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.7) !important;
}

.tw-divide-sky-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.75) !important;
}

.tw-divide-sky-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.8) !important;
}

.tw-divide-sky-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.85) !important;
}

.tw-divide-sky-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.9) !important;
}

.tw-divide-sky-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 249 255 / 0.95) !important;
}

.tw-divide-sky-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(14 165 233 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0) !important;
}

.tw-divide-sky-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.1) !important;
}

.tw-divide-sky-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 1) !important;
}

.tw-divide-sky-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.15) !important;
}

.tw-divide-sky-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.2) !important;
}

.tw-divide-sky-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.25) !important;
}

.tw-divide-sky-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.3) !important;
}

.tw-divide-sky-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.35) !important;
}

.tw-divide-sky-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.4) !important;
}

.tw-divide-sky-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.45) !important;
}

.tw-divide-sky-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.05) !important;
}

.tw-divide-sky-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.5) !important;
}

.tw-divide-sky-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.55) !important;
}

.tw-divide-sky-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.6) !important;
}

.tw-divide-sky-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.65) !important;
}

.tw-divide-sky-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.7) !important;
}

.tw-divide-sky-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.75) !important;
}

.tw-divide-sky-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.8) !important;
}

.tw-divide-sky-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.85) !important;
}

.tw-divide-sky-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.9) !important;
}

.tw-divide-sky-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(14 165 233 / 0.95) !important;
}

.tw-divide-sky-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(2 132 199 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0) !important;
}

.tw-divide-sky-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.1) !important;
}

.tw-divide-sky-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 1) !important;
}

.tw-divide-sky-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.15) !important;
}

.tw-divide-sky-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.2) !important;
}

.tw-divide-sky-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.25) !important;
}

.tw-divide-sky-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.3) !important;
}

.tw-divide-sky-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.35) !important;
}

.tw-divide-sky-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.4) !important;
}

.tw-divide-sky-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.45) !important;
}

.tw-divide-sky-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.05) !important;
}

.tw-divide-sky-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.5) !important;
}

.tw-divide-sky-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.55) !important;
}

.tw-divide-sky-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.6) !important;
}

.tw-divide-sky-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.65) !important;
}

.tw-divide-sky-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.7) !important;
}

.tw-divide-sky-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.75) !important;
}

.tw-divide-sky-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.8) !important;
}

.tw-divide-sky-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.85) !important;
}

.tw-divide-sky-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.9) !important;
}

.tw-divide-sky-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 132 199 / 0.95) !important;
}

.tw-divide-sky-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(3 105 161 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0) !important;
}

.tw-divide-sky-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.1) !important;
}

.tw-divide-sky-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 1) !important;
}

.tw-divide-sky-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.15) !important;
}

.tw-divide-sky-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.2) !important;
}

.tw-divide-sky-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.25) !important;
}

.tw-divide-sky-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.3) !important;
}

.tw-divide-sky-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.35) !important;
}

.tw-divide-sky-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.4) !important;
}

.tw-divide-sky-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.45) !important;
}

.tw-divide-sky-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.05) !important;
}

.tw-divide-sky-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.5) !important;
}

.tw-divide-sky-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.55) !important;
}

.tw-divide-sky-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.6) !important;
}

.tw-divide-sky-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.65) !important;
}

.tw-divide-sky-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.7) !important;
}

.tw-divide-sky-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.75) !important;
}

.tw-divide-sky-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.8) !important;
}

.tw-divide-sky-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.85) !important;
}

.tw-divide-sky-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.9) !important;
}

.tw-divide-sky-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(3 105 161 / 0.95) !important;
}

.tw-divide-sky-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(7 89 133 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0) !important;
}

.tw-divide-sky-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.1) !important;
}

.tw-divide-sky-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 1) !important;
}

.tw-divide-sky-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.15) !important;
}

.tw-divide-sky-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.2) !important;
}

.tw-divide-sky-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.25) !important;
}

.tw-divide-sky-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.3) !important;
}

.tw-divide-sky-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.35) !important;
}

.tw-divide-sky-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.4) !important;
}

.tw-divide-sky-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.45) !important;
}

.tw-divide-sky-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.05) !important;
}

.tw-divide-sky-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.5) !important;
}

.tw-divide-sky-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.55) !important;
}

.tw-divide-sky-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.6) !important;
}

.tw-divide-sky-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.65) !important;
}

.tw-divide-sky-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.7) !important;
}

.tw-divide-sky-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.75) !important;
}

.tw-divide-sky-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.8) !important;
}

.tw-divide-sky-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.85) !important;
}

.tw-divide-sky-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.9) !important;
}

.tw-divide-sky-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(7 89 133 / 0.95) !important;
}

.tw-divide-sky-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(12 74 110 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0) !important;
}

.tw-divide-sky-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.1) !important;
}

.tw-divide-sky-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 1) !important;
}

.tw-divide-sky-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.15) !important;
}

.tw-divide-sky-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.2) !important;
}

.tw-divide-sky-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.25) !important;
}

.tw-divide-sky-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.3) !important;
}

.tw-divide-sky-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.35) !important;
}

.tw-divide-sky-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.4) !important;
}

.tw-divide-sky-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.45) !important;
}

.tw-divide-sky-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.05) !important;
}

.tw-divide-sky-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.5) !important;
}

.tw-divide-sky-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.55) !important;
}

.tw-divide-sky-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.6) !important;
}

.tw-divide-sky-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.65) !important;
}

.tw-divide-sky-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.7) !important;
}

.tw-divide-sky-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.75) !important;
}

.tw-divide-sky-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.8) !important;
}

.tw-divide-sky-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.85) !important;
}

.tw-divide-sky-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.9) !important;
}

.tw-divide-sky-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 74 110 / 0.95) !important;
}

.tw-divide-sky-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(8 47 73 / var(--tw-divide-opacity)) !important;
}

.tw-divide-sky-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0) !important;
}

.tw-divide-sky-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.1) !important;
}

.tw-divide-sky-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 1) !important;
}

.tw-divide-sky-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.15) !important;
}

.tw-divide-sky-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.2) !important;
}

.tw-divide-sky-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.25) !important;
}

.tw-divide-sky-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.3) !important;
}

.tw-divide-sky-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.35) !important;
}

.tw-divide-sky-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.4) !important;
}

.tw-divide-sky-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.45) !important;
}

.tw-divide-sky-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.05) !important;
}

.tw-divide-sky-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.5) !important;
}

.tw-divide-sky-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.55) !important;
}

.tw-divide-sky-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.6) !important;
}

.tw-divide-sky-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.65) !important;
}

.tw-divide-sky-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.7) !important;
}

.tw-divide-sky-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.75) !important;
}

.tw-divide-sky-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.8) !important;
}

.tw-divide-sky-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.85) !important;
}

.tw-divide-sky-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.9) !important;
}

.tw-divide-sky-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(8 47 73 / 0.95) !important;
}

.tw-divide-slate-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(241 245 249 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0) !important;
}

.tw-divide-slate-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.1) !important;
}

.tw-divide-slate-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 1) !important;
}

.tw-divide-slate-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.15) !important;
}

.tw-divide-slate-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.2) !important;
}

.tw-divide-slate-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.25) !important;
}

.tw-divide-slate-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.3) !important;
}

.tw-divide-slate-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.35) !important;
}

.tw-divide-slate-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.4) !important;
}

.tw-divide-slate-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.45) !important;
}

.tw-divide-slate-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.05) !important;
}

.tw-divide-slate-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.5) !important;
}

.tw-divide-slate-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.55) !important;
}

.tw-divide-slate-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.6) !important;
}

.tw-divide-slate-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.65) !important;
}

.tw-divide-slate-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.7) !important;
}

.tw-divide-slate-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.75) !important;
}

.tw-divide-slate-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.8) !important;
}

.tw-divide-slate-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.85) !important;
}

.tw-divide-slate-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.9) !important;
}

.tw-divide-slate-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(241 245 249 / 0.95) !important;
}

.tw-divide-slate-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(226 232 240 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0) !important;
}

.tw-divide-slate-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.1) !important;
}

.tw-divide-slate-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 1) !important;
}

.tw-divide-slate-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.15) !important;
}

.tw-divide-slate-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.2) !important;
}

.tw-divide-slate-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.25) !important;
}

.tw-divide-slate-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.3) !important;
}

.tw-divide-slate-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.35) !important;
}

.tw-divide-slate-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.4) !important;
}

.tw-divide-slate-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.45) !important;
}

.tw-divide-slate-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.05) !important;
}

.tw-divide-slate-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.5) !important;
}

.tw-divide-slate-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.55) !important;
}

.tw-divide-slate-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.6) !important;
}

.tw-divide-slate-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.65) !important;
}

.tw-divide-slate-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.7) !important;
}

.tw-divide-slate-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.75) !important;
}

.tw-divide-slate-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.8) !important;
}

.tw-divide-slate-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.85) !important;
}

.tw-divide-slate-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.9) !important;
}

.tw-divide-slate-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(226 232 240 / 0.95) !important;
}

.tw-divide-slate-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(203 213 225 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0) !important;
}

.tw-divide-slate-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.1) !important;
}

.tw-divide-slate-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 1) !important;
}

.tw-divide-slate-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.15) !important;
}

.tw-divide-slate-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.2) !important;
}

.tw-divide-slate-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.25) !important;
}

.tw-divide-slate-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.3) !important;
}

.tw-divide-slate-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.35) !important;
}

.tw-divide-slate-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.4) !important;
}

.tw-divide-slate-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.45) !important;
}

.tw-divide-slate-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.05) !important;
}

.tw-divide-slate-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.5) !important;
}

.tw-divide-slate-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.55) !important;
}

.tw-divide-slate-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.6) !important;
}

.tw-divide-slate-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.65) !important;
}

.tw-divide-slate-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.7) !important;
}

.tw-divide-slate-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.75) !important;
}

.tw-divide-slate-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.8) !important;
}

.tw-divide-slate-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.85) !important;
}

.tw-divide-slate-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.9) !important;
}

.tw-divide-slate-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(203 213 225 / 0.95) !important;
}

.tw-divide-slate-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(148 163 184 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0) !important;
}

.tw-divide-slate-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.1) !important;
}

.tw-divide-slate-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 1) !important;
}

.tw-divide-slate-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.15) !important;
}

.tw-divide-slate-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.2) !important;
}

.tw-divide-slate-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.25) !important;
}

.tw-divide-slate-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.3) !important;
}

.tw-divide-slate-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.35) !important;
}

.tw-divide-slate-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.4) !important;
}

.tw-divide-slate-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.45) !important;
}

.tw-divide-slate-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.05) !important;
}

.tw-divide-slate-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.5) !important;
}

.tw-divide-slate-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.55) !important;
}

.tw-divide-slate-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.6) !important;
}

.tw-divide-slate-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.65) !important;
}

.tw-divide-slate-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.7) !important;
}

.tw-divide-slate-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.75) !important;
}

.tw-divide-slate-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.8) !important;
}

.tw-divide-slate-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.85) !important;
}

.tw-divide-slate-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.9) !important;
}

.tw-divide-slate-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(148 163 184 / 0.95) !important;
}

.tw-divide-slate-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(248 250 252 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0) !important;
}

.tw-divide-slate-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.1) !important;
}

.tw-divide-slate-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 1) !important;
}

.tw-divide-slate-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.15) !important;
}

.tw-divide-slate-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.2) !important;
}

.tw-divide-slate-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.25) !important;
}

.tw-divide-slate-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.3) !important;
}

.tw-divide-slate-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.35) !important;
}

.tw-divide-slate-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.4) !important;
}

.tw-divide-slate-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.45) !important;
}

.tw-divide-slate-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.05) !important;
}

.tw-divide-slate-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.5) !important;
}

.tw-divide-slate-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.55) !important;
}

.tw-divide-slate-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.6) !important;
}

.tw-divide-slate-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.65) !important;
}

.tw-divide-slate-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.7) !important;
}

.tw-divide-slate-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.75) !important;
}

.tw-divide-slate-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.8) !important;
}

.tw-divide-slate-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.85) !important;
}

.tw-divide-slate-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.9) !important;
}

.tw-divide-slate-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(248 250 252 / 0.95) !important;
}

.tw-divide-slate-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(100 116 139 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0) !important;
}

.tw-divide-slate-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.1) !important;
}

.tw-divide-slate-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 1) !important;
}

.tw-divide-slate-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.15) !important;
}

.tw-divide-slate-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.2) !important;
}

.tw-divide-slate-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.25) !important;
}

.tw-divide-slate-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.3) !important;
}

.tw-divide-slate-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.35) !important;
}

.tw-divide-slate-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.4) !important;
}

.tw-divide-slate-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.45) !important;
}

.tw-divide-slate-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.05) !important;
}

.tw-divide-slate-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.5) !important;
}

.tw-divide-slate-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.55) !important;
}

.tw-divide-slate-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.6) !important;
}

.tw-divide-slate-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.65) !important;
}

.tw-divide-slate-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.7) !important;
}

.tw-divide-slate-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.75) !important;
}

.tw-divide-slate-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.8) !important;
}

.tw-divide-slate-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.85) !important;
}

.tw-divide-slate-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.9) !important;
}

.tw-divide-slate-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(100 116 139 / 0.95) !important;
}

.tw-divide-slate-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(71 85 105 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0) !important;
}

.tw-divide-slate-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.1) !important;
}

.tw-divide-slate-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 1) !important;
}

.tw-divide-slate-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.15) !important;
}

.tw-divide-slate-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.2) !important;
}

.tw-divide-slate-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.25) !important;
}

.tw-divide-slate-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.3) !important;
}

.tw-divide-slate-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.35) !important;
}

.tw-divide-slate-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.4) !important;
}

.tw-divide-slate-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.45) !important;
}

.tw-divide-slate-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.05) !important;
}

.tw-divide-slate-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.5) !important;
}

.tw-divide-slate-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.55) !important;
}

.tw-divide-slate-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.6) !important;
}

.tw-divide-slate-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.65) !important;
}

.tw-divide-slate-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.7) !important;
}

.tw-divide-slate-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.75) !important;
}

.tw-divide-slate-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.8) !important;
}

.tw-divide-slate-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.85) !important;
}

.tw-divide-slate-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.9) !important;
}

.tw-divide-slate-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(71 85 105 / 0.95) !important;
}

.tw-divide-slate-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(51 65 85 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0) !important;
}

.tw-divide-slate-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.1) !important;
}

.tw-divide-slate-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 1) !important;
}

.tw-divide-slate-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.15) !important;
}

.tw-divide-slate-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.2) !important;
}

.tw-divide-slate-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.25) !important;
}

.tw-divide-slate-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.3) !important;
}

.tw-divide-slate-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.35) !important;
}

.tw-divide-slate-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.4) !important;
}

.tw-divide-slate-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.45) !important;
}

.tw-divide-slate-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.05) !important;
}

.tw-divide-slate-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.5) !important;
}

.tw-divide-slate-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.55) !important;
}

.tw-divide-slate-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.6) !important;
}

.tw-divide-slate-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.65) !important;
}

.tw-divide-slate-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.7) !important;
}

.tw-divide-slate-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.75) !important;
}

.tw-divide-slate-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.8) !important;
}

.tw-divide-slate-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.85) !important;
}

.tw-divide-slate-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.9) !important;
}

.tw-divide-slate-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(51 65 85 / 0.95) !important;
}

.tw-divide-slate-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0) !important;
}

.tw-divide-slate-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.1) !important;
}

.tw-divide-slate-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 1) !important;
}

.tw-divide-slate-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.15) !important;
}

.tw-divide-slate-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.2) !important;
}

.tw-divide-slate-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.25) !important;
}

.tw-divide-slate-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.3) !important;
}

.tw-divide-slate-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.35) !important;
}

.tw-divide-slate-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.4) !important;
}

.tw-divide-slate-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.45) !important;
}

.tw-divide-slate-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.05) !important;
}

.tw-divide-slate-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.5) !important;
}

.tw-divide-slate-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.55) !important;
}

.tw-divide-slate-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.6) !important;
}

.tw-divide-slate-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.65) !important;
}

.tw-divide-slate-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.7) !important;
}

.tw-divide-slate-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.75) !important;
}

.tw-divide-slate-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.8) !important;
}

.tw-divide-slate-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.85) !important;
}

.tw-divide-slate-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.9) !important;
}

.tw-divide-slate-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(30 41 59 / 0.95) !important;
}

.tw-divide-slate-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(15 23 42 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0) !important;
}

.tw-divide-slate-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.1) !important;
}

.tw-divide-slate-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 1) !important;
}

.tw-divide-slate-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.15) !important;
}

.tw-divide-slate-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.2) !important;
}

.tw-divide-slate-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.25) !important;
}

.tw-divide-slate-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.3) !important;
}

.tw-divide-slate-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.35) !important;
}

.tw-divide-slate-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.4) !important;
}

.tw-divide-slate-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.45) !important;
}

.tw-divide-slate-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.05) !important;
}

.tw-divide-slate-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.5) !important;
}

.tw-divide-slate-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.55) !important;
}

.tw-divide-slate-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.6) !important;
}

.tw-divide-slate-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.65) !important;
}

.tw-divide-slate-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.7) !important;
}

.tw-divide-slate-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.75) !important;
}

.tw-divide-slate-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.8) !important;
}

.tw-divide-slate-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.85) !important;
}

.tw-divide-slate-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.9) !important;
}

.tw-divide-slate-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 23 42 / 0.95) !important;
}

.tw-divide-slate-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(2 6 23 / var(--tw-divide-opacity)) !important;
}

.tw-divide-slate-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0) !important;
}

.tw-divide-slate-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.1) !important;
}

.tw-divide-slate-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 1) !important;
}

.tw-divide-slate-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.15) !important;
}

.tw-divide-slate-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.2) !important;
}

.tw-divide-slate-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.25) !important;
}

.tw-divide-slate-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.3) !important;
}

.tw-divide-slate-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.35) !important;
}

.tw-divide-slate-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.4) !important;
}

.tw-divide-slate-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.45) !important;
}

.tw-divide-slate-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.05) !important;
}

.tw-divide-slate-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.5) !important;
}

.tw-divide-slate-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.55) !important;
}

.tw-divide-slate-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.6) !important;
}

.tw-divide-slate-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.65) !important;
}

.tw-divide-slate-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.7) !important;
}

.tw-divide-slate-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.75) !important;
}

.tw-divide-slate-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.8) !important;
}

.tw-divide-slate-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.85) !important;
}

.tw-divide-slate-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.9) !important;
}

.tw-divide-slate-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(2 6 23 / 0.95) !important;
}

.tw-divide-stone-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(245 245 244 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0) !important;
}

.tw-divide-stone-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.1) !important;
}

.tw-divide-stone-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 1) !important;
}

.tw-divide-stone-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.15) !important;
}

.tw-divide-stone-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.2) !important;
}

.tw-divide-stone-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.25) !important;
}

.tw-divide-stone-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.3) !important;
}

.tw-divide-stone-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.35) !important;
}

.tw-divide-stone-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.4) !important;
}

.tw-divide-stone-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.45) !important;
}

.tw-divide-stone-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.05) !important;
}

.tw-divide-stone-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.5) !important;
}

.tw-divide-stone-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.55) !important;
}

.tw-divide-stone-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.6) !important;
}

.tw-divide-stone-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.65) !important;
}

.tw-divide-stone-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.7) !important;
}

.tw-divide-stone-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.75) !important;
}

.tw-divide-stone-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.8) !important;
}

.tw-divide-stone-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.85) !important;
}

.tw-divide-stone-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.9) !important;
}

.tw-divide-stone-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 245 244 / 0.95) !important;
}

.tw-divide-stone-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(231 229 228 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0) !important;
}

.tw-divide-stone-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.1) !important;
}

.tw-divide-stone-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 1) !important;
}

.tw-divide-stone-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.15) !important;
}

.tw-divide-stone-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.2) !important;
}

.tw-divide-stone-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.25) !important;
}

.tw-divide-stone-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.3) !important;
}

.tw-divide-stone-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.35) !important;
}

.tw-divide-stone-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.4) !important;
}

.tw-divide-stone-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.45) !important;
}

.tw-divide-stone-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.05) !important;
}

.tw-divide-stone-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.5) !important;
}

.tw-divide-stone-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.55) !important;
}

.tw-divide-stone-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.6) !important;
}

.tw-divide-stone-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.65) !important;
}

.tw-divide-stone-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.7) !important;
}

.tw-divide-stone-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.75) !important;
}

.tw-divide-stone-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.8) !important;
}

.tw-divide-stone-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.85) !important;
}

.tw-divide-stone-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.9) !important;
}

.tw-divide-stone-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(231 229 228 / 0.95) !important;
}

.tw-divide-stone-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(214 211 209 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0) !important;
}

.tw-divide-stone-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.1) !important;
}

.tw-divide-stone-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 1) !important;
}

.tw-divide-stone-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.15) !important;
}

.tw-divide-stone-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.2) !important;
}

.tw-divide-stone-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.25) !important;
}

.tw-divide-stone-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.3) !important;
}

.tw-divide-stone-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.35) !important;
}

.tw-divide-stone-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.4) !important;
}

.tw-divide-stone-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.45) !important;
}

.tw-divide-stone-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.05) !important;
}

.tw-divide-stone-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.5) !important;
}

.tw-divide-stone-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.55) !important;
}

.tw-divide-stone-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.6) !important;
}

.tw-divide-stone-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.65) !important;
}

.tw-divide-stone-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.7) !important;
}

.tw-divide-stone-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.75) !important;
}

.tw-divide-stone-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.8) !important;
}

.tw-divide-stone-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.85) !important;
}

.tw-divide-stone-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.9) !important;
}

.tw-divide-stone-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(214 211 209 / 0.95) !important;
}

.tw-divide-stone-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(168 162 158 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0) !important;
}

.tw-divide-stone-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.1) !important;
}

.tw-divide-stone-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 1) !important;
}

.tw-divide-stone-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.15) !important;
}

.tw-divide-stone-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.2) !important;
}

.tw-divide-stone-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.25) !important;
}

.tw-divide-stone-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.3) !important;
}

.tw-divide-stone-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.35) !important;
}

.tw-divide-stone-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.4) !important;
}

.tw-divide-stone-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.45) !important;
}

.tw-divide-stone-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.05) !important;
}

.tw-divide-stone-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.5) !important;
}

.tw-divide-stone-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.55) !important;
}

.tw-divide-stone-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.6) !important;
}

.tw-divide-stone-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.65) !important;
}

.tw-divide-stone-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.7) !important;
}

.tw-divide-stone-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.75) !important;
}

.tw-divide-stone-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.8) !important;
}

.tw-divide-stone-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.85) !important;
}

.tw-divide-stone-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.9) !important;
}

.tw-divide-stone-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(168 162 158 / 0.95) !important;
}

.tw-divide-stone-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(250 250 249 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0) !important;
}

.tw-divide-stone-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.1) !important;
}

.tw-divide-stone-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 1) !important;
}

.tw-divide-stone-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.15) !important;
}

.tw-divide-stone-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.2) !important;
}

.tw-divide-stone-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.25) !important;
}

.tw-divide-stone-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.3) !important;
}

.tw-divide-stone-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.35) !important;
}

.tw-divide-stone-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.4) !important;
}

.tw-divide-stone-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.45) !important;
}

.tw-divide-stone-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.05) !important;
}

.tw-divide-stone-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.5) !important;
}

.tw-divide-stone-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.55) !important;
}

.tw-divide-stone-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.6) !important;
}

.tw-divide-stone-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.65) !important;
}

.tw-divide-stone-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.7) !important;
}

.tw-divide-stone-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.75) !important;
}

.tw-divide-stone-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.8) !important;
}

.tw-divide-stone-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.85) !important;
}

.tw-divide-stone-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.9) !important;
}

.tw-divide-stone-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 249 / 0.95) !important;
}

.tw-divide-stone-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(120 113 108 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0) !important;
}

.tw-divide-stone-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.1) !important;
}

.tw-divide-stone-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 1) !important;
}

.tw-divide-stone-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.15) !important;
}

.tw-divide-stone-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.2) !important;
}

.tw-divide-stone-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.25) !important;
}

.tw-divide-stone-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.3) !important;
}

.tw-divide-stone-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.35) !important;
}

.tw-divide-stone-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.4) !important;
}

.tw-divide-stone-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.45) !important;
}

.tw-divide-stone-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.05) !important;
}

.tw-divide-stone-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.5) !important;
}

.tw-divide-stone-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.55) !important;
}

.tw-divide-stone-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.6) !important;
}

.tw-divide-stone-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.65) !important;
}

.tw-divide-stone-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.7) !important;
}

.tw-divide-stone-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.75) !important;
}

.tw-divide-stone-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.8) !important;
}

.tw-divide-stone-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.85) !important;
}

.tw-divide-stone-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.9) !important;
}

.tw-divide-stone-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(120 113 108 / 0.95) !important;
}

.tw-divide-stone-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(87 83 78 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0) !important;
}

.tw-divide-stone-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.1) !important;
}

.tw-divide-stone-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 1) !important;
}

.tw-divide-stone-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.15) !important;
}

.tw-divide-stone-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.2) !important;
}

.tw-divide-stone-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.25) !important;
}

.tw-divide-stone-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.3) !important;
}

.tw-divide-stone-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.35) !important;
}

.tw-divide-stone-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.4) !important;
}

.tw-divide-stone-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.45) !important;
}

.tw-divide-stone-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.05) !important;
}

.tw-divide-stone-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.5) !important;
}

.tw-divide-stone-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.55) !important;
}

.tw-divide-stone-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.6) !important;
}

.tw-divide-stone-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.65) !important;
}

.tw-divide-stone-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.7) !important;
}

.tw-divide-stone-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.75) !important;
}

.tw-divide-stone-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.8) !important;
}

.tw-divide-stone-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.85) !important;
}

.tw-divide-stone-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.9) !important;
}

.tw-divide-stone-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(87 83 78 / 0.95) !important;
}

.tw-divide-stone-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(68 64 60 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0) !important;
}

.tw-divide-stone-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.1) !important;
}

.tw-divide-stone-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 1) !important;
}

.tw-divide-stone-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.15) !important;
}

.tw-divide-stone-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.2) !important;
}

.tw-divide-stone-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.25) !important;
}

.tw-divide-stone-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.3) !important;
}

.tw-divide-stone-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.35) !important;
}

.tw-divide-stone-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.4) !important;
}

.tw-divide-stone-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.45) !important;
}

.tw-divide-stone-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.05) !important;
}

.tw-divide-stone-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.5) !important;
}

.tw-divide-stone-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.55) !important;
}

.tw-divide-stone-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.6) !important;
}

.tw-divide-stone-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.65) !important;
}

.tw-divide-stone-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.7) !important;
}

.tw-divide-stone-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.75) !important;
}

.tw-divide-stone-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.8) !important;
}

.tw-divide-stone-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.85) !important;
}

.tw-divide-stone-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.9) !important;
}

.tw-divide-stone-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(68 64 60 / 0.95) !important;
}

.tw-divide-stone-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(41 37 36 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0) !important;
}

.tw-divide-stone-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.1) !important;
}

.tw-divide-stone-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 1) !important;
}

.tw-divide-stone-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.15) !important;
}

.tw-divide-stone-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.2) !important;
}

.tw-divide-stone-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.25) !important;
}

.tw-divide-stone-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.3) !important;
}

.tw-divide-stone-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.35) !important;
}

.tw-divide-stone-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.4) !important;
}

.tw-divide-stone-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.45) !important;
}

.tw-divide-stone-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.05) !important;
}

.tw-divide-stone-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.5) !important;
}

.tw-divide-stone-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.55) !important;
}

.tw-divide-stone-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.6) !important;
}

.tw-divide-stone-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.65) !important;
}

.tw-divide-stone-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.7) !important;
}

.tw-divide-stone-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.75) !important;
}

.tw-divide-stone-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.8) !important;
}

.tw-divide-stone-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.85) !important;
}

.tw-divide-stone-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.9) !important;
}

.tw-divide-stone-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(41 37 36 / 0.95) !important;
}

.tw-divide-stone-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(28 25 23 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0) !important;
}

.tw-divide-stone-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.1) !important;
}

.tw-divide-stone-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 1) !important;
}

.tw-divide-stone-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.15) !important;
}

.tw-divide-stone-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.2) !important;
}

.tw-divide-stone-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.25) !important;
}

.tw-divide-stone-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.3) !important;
}

.tw-divide-stone-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.35) !important;
}

.tw-divide-stone-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.4) !important;
}

.tw-divide-stone-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.45) !important;
}

.tw-divide-stone-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.05) !important;
}

.tw-divide-stone-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.5) !important;
}

.tw-divide-stone-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.55) !important;
}

.tw-divide-stone-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.6) !important;
}

.tw-divide-stone-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.65) !important;
}

.tw-divide-stone-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.7) !important;
}

.tw-divide-stone-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.75) !important;
}

.tw-divide-stone-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.8) !important;
}

.tw-divide-stone-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.85) !important;
}

.tw-divide-stone-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.9) !important;
}

.tw-divide-stone-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(28 25 23 / 0.95) !important;
}

.tw-divide-stone-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(12 10 9 / var(--tw-divide-opacity)) !important;
}

.tw-divide-stone-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0) !important;
}

.tw-divide-stone-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.1) !important;
}

.tw-divide-stone-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 1) !important;
}

.tw-divide-stone-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.15) !important;
}

.tw-divide-stone-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.2) !important;
}

.tw-divide-stone-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.25) !important;
}

.tw-divide-stone-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.3) !important;
}

.tw-divide-stone-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.35) !important;
}

.tw-divide-stone-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.4) !important;
}

.tw-divide-stone-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.45) !important;
}

.tw-divide-stone-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.05) !important;
}

.tw-divide-stone-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.5) !important;
}

.tw-divide-stone-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.55) !important;
}

.tw-divide-stone-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.6) !important;
}

.tw-divide-stone-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.65) !important;
}

.tw-divide-stone-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.7) !important;
}

.tw-divide-stone-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.75) !important;
}

.tw-divide-stone-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.8) !important;
}

.tw-divide-stone-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.85) !important;
}

.tw-divide-stone-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.9) !important;
}

.tw-divide-stone-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(12 10 9 / 0.95) !important;
}

.tw-divide-teal-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(204 251 241 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0) !important;
}

.tw-divide-teal-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.1) !important;
}

.tw-divide-teal-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 1) !important;
}

.tw-divide-teal-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.15) !important;
}

.tw-divide-teal-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.2) !important;
}

.tw-divide-teal-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.25) !important;
}

.tw-divide-teal-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.3) !important;
}

.tw-divide-teal-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.35) !important;
}

.tw-divide-teal-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.4) !important;
}

.tw-divide-teal-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.45) !important;
}

.tw-divide-teal-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.05) !important;
}

.tw-divide-teal-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.5) !important;
}

.tw-divide-teal-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.55) !important;
}

.tw-divide-teal-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.6) !important;
}

.tw-divide-teal-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.65) !important;
}

.tw-divide-teal-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.7) !important;
}

.tw-divide-teal-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.75) !important;
}

.tw-divide-teal-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.8) !important;
}

.tw-divide-teal-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.85) !important;
}

.tw-divide-teal-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.9) !important;
}

.tw-divide-teal-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(204 251 241 / 0.95) !important;
}

.tw-divide-teal-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(153 246 228 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0) !important;
}

.tw-divide-teal-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.1) !important;
}

.tw-divide-teal-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 1) !important;
}

.tw-divide-teal-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.15) !important;
}

.tw-divide-teal-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.2) !important;
}

.tw-divide-teal-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.25) !important;
}

.tw-divide-teal-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.3) !important;
}

.tw-divide-teal-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.35) !important;
}

.tw-divide-teal-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.4) !important;
}

.tw-divide-teal-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.45) !important;
}

.tw-divide-teal-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.05) !important;
}

.tw-divide-teal-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.5) !important;
}

.tw-divide-teal-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.55) !important;
}

.tw-divide-teal-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.6) !important;
}

.tw-divide-teal-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.65) !important;
}

.tw-divide-teal-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.7) !important;
}

.tw-divide-teal-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.75) !important;
}

.tw-divide-teal-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.8) !important;
}

.tw-divide-teal-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.85) !important;
}

.tw-divide-teal-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.9) !important;
}

.tw-divide-teal-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(153 246 228 / 0.95) !important;
}

.tw-divide-teal-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(94 234 212 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0) !important;
}

.tw-divide-teal-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.1) !important;
}

.tw-divide-teal-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 1) !important;
}

.tw-divide-teal-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.15) !important;
}

.tw-divide-teal-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.2) !important;
}

.tw-divide-teal-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.25) !important;
}

.tw-divide-teal-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.3) !important;
}

.tw-divide-teal-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.35) !important;
}

.tw-divide-teal-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.4) !important;
}

.tw-divide-teal-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.45) !important;
}

.tw-divide-teal-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.05) !important;
}

.tw-divide-teal-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.5) !important;
}

.tw-divide-teal-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.55) !important;
}

.tw-divide-teal-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.6) !important;
}

.tw-divide-teal-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.65) !important;
}

.tw-divide-teal-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.7) !important;
}

.tw-divide-teal-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.75) !important;
}

.tw-divide-teal-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.8) !important;
}

.tw-divide-teal-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.85) !important;
}

.tw-divide-teal-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.9) !important;
}

.tw-divide-teal-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(94 234 212 / 0.95) !important;
}

.tw-divide-teal-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(45 212 191 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0) !important;
}

.tw-divide-teal-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.1) !important;
}

.tw-divide-teal-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 1) !important;
}

.tw-divide-teal-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.15) !important;
}

.tw-divide-teal-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.2) !important;
}

.tw-divide-teal-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.25) !important;
}

.tw-divide-teal-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.3) !important;
}

.tw-divide-teal-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.35) !important;
}

.tw-divide-teal-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.4) !important;
}

.tw-divide-teal-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.45) !important;
}

.tw-divide-teal-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.05) !important;
}

.tw-divide-teal-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.5) !important;
}

.tw-divide-teal-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.55) !important;
}

.tw-divide-teal-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.6) !important;
}

.tw-divide-teal-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.65) !important;
}

.tw-divide-teal-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.7) !important;
}

.tw-divide-teal-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.75) !important;
}

.tw-divide-teal-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.8) !important;
}

.tw-divide-teal-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.85) !important;
}

.tw-divide-teal-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.9) !important;
}

.tw-divide-teal-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(45 212 191 / 0.95) !important;
}

.tw-divide-teal-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(240 253 250 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0) !important;
}

.tw-divide-teal-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.1) !important;
}

.tw-divide-teal-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 1) !important;
}

.tw-divide-teal-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.15) !important;
}

.tw-divide-teal-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.2) !important;
}

.tw-divide-teal-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.25) !important;
}

.tw-divide-teal-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.3) !important;
}

.tw-divide-teal-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.35) !important;
}

.tw-divide-teal-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.4) !important;
}

.tw-divide-teal-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.45) !important;
}

.tw-divide-teal-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.05) !important;
}

.tw-divide-teal-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.5) !important;
}

.tw-divide-teal-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.55) !important;
}

.tw-divide-teal-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.6) !important;
}

.tw-divide-teal-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.65) !important;
}

.tw-divide-teal-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.7) !important;
}

.tw-divide-teal-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.75) !important;
}

.tw-divide-teal-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.8) !important;
}

.tw-divide-teal-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.85) !important;
}

.tw-divide-teal-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.9) !important;
}

.tw-divide-teal-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(240 253 250 / 0.95) !important;
}

.tw-divide-teal-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(20 184 166 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0) !important;
}

.tw-divide-teal-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.1) !important;
}

.tw-divide-teal-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 1) !important;
}

.tw-divide-teal-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.15) !important;
}

.tw-divide-teal-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.2) !important;
}

.tw-divide-teal-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.25) !important;
}

.tw-divide-teal-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.3) !important;
}

.tw-divide-teal-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.35) !important;
}

.tw-divide-teal-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.4) !important;
}

.tw-divide-teal-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.45) !important;
}

.tw-divide-teal-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.05) !important;
}

.tw-divide-teal-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.5) !important;
}

.tw-divide-teal-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.55) !important;
}

.tw-divide-teal-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.6) !important;
}

.tw-divide-teal-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.65) !important;
}

.tw-divide-teal-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.7) !important;
}

.tw-divide-teal-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.75) !important;
}

.tw-divide-teal-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.8) !important;
}

.tw-divide-teal-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.85) !important;
}

.tw-divide-teal-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.9) !important;
}

.tw-divide-teal-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(20 184 166 / 0.95) !important;
}

.tw-divide-teal-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(13 148 136 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0) !important;
}

.tw-divide-teal-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.1) !important;
}

.tw-divide-teal-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 1) !important;
}

.tw-divide-teal-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.15) !important;
}

.tw-divide-teal-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.2) !important;
}

.tw-divide-teal-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.25) !important;
}

.tw-divide-teal-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.3) !important;
}

.tw-divide-teal-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.35) !important;
}

.tw-divide-teal-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.4) !important;
}

.tw-divide-teal-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.45) !important;
}

.tw-divide-teal-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.05) !important;
}

.tw-divide-teal-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.5) !important;
}

.tw-divide-teal-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.55) !important;
}

.tw-divide-teal-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.6) !important;
}

.tw-divide-teal-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.65) !important;
}

.tw-divide-teal-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.7) !important;
}

.tw-divide-teal-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.75) !important;
}

.tw-divide-teal-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.8) !important;
}

.tw-divide-teal-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.85) !important;
}

.tw-divide-teal-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.9) !important;
}

.tw-divide-teal-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(13 148 136 / 0.95) !important;
}

.tw-divide-teal-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(15 118 110 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0) !important;
}

.tw-divide-teal-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.1) !important;
}

.tw-divide-teal-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 1) !important;
}

.tw-divide-teal-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.15) !important;
}

.tw-divide-teal-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.2) !important;
}

.tw-divide-teal-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.25) !important;
}

.tw-divide-teal-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.3) !important;
}

.tw-divide-teal-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.35) !important;
}

.tw-divide-teal-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.4) !important;
}

.tw-divide-teal-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.45) !important;
}

.tw-divide-teal-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.05) !important;
}

.tw-divide-teal-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.5) !important;
}

.tw-divide-teal-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.55) !important;
}

.tw-divide-teal-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.6) !important;
}

.tw-divide-teal-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.65) !important;
}

.tw-divide-teal-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.7) !important;
}

.tw-divide-teal-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.75) !important;
}

.tw-divide-teal-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.8) !important;
}

.tw-divide-teal-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.85) !important;
}

.tw-divide-teal-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.9) !important;
}

.tw-divide-teal-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(15 118 110 / 0.95) !important;
}

.tw-divide-teal-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(17 94 89 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0) !important;
}

.tw-divide-teal-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.1) !important;
}

.tw-divide-teal-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 1) !important;
}

.tw-divide-teal-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.15) !important;
}

.tw-divide-teal-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.2) !important;
}

.tw-divide-teal-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.25) !important;
}

.tw-divide-teal-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.3) !important;
}

.tw-divide-teal-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.35) !important;
}

.tw-divide-teal-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.4) !important;
}

.tw-divide-teal-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.45) !important;
}

.tw-divide-teal-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.05) !important;
}

.tw-divide-teal-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.5) !important;
}

.tw-divide-teal-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.55) !important;
}

.tw-divide-teal-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.6) !important;
}

.tw-divide-teal-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.65) !important;
}

.tw-divide-teal-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.7) !important;
}

.tw-divide-teal-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.75) !important;
}

.tw-divide-teal-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.8) !important;
}

.tw-divide-teal-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.85) !important;
}

.tw-divide-teal-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.9) !important;
}

.tw-divide-teal-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(17 94 89 / 0.95) !important;
}

.tw-divide-teal-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(19 78 74 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0) !important;
}

.tw-divide-teal-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.1) !important;
}

.tw-divide-teal-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 1) !important;
}

.tw-divide-teal-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.15) !important;
}

.tw-divide-teal-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.2) !important;
}

.tw-divide-teal-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.25) !important;
}

.tw-divide-teal-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.3) !important;
}

.tw-divide-teal-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.35) !important;
}

.tw-divide-teal-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.4) !important;
}

.tw-divide-teal-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.45) !important;
}

.tw-divide-teal-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.05) !important;
}

.tw-divide-teal-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.5) !important;
}

.tw-divide-teal-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.55) !important;
}

.tw-divide-teal-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.6) !important;
}

.tw-divide-teal-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.65) !important;
}

.tw-divide-teal-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.7) !important;
}

.tw-divide-teal-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.75) !important;
}

.tw-divide-teal-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.8) !important;
}

.tw-divide-teal-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.85) !important;
}

.tw-divide-teal-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.9) !important;
}

.tw-divide-teal-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(19 78 74 / 0.95) !important;
}

.tw-divide-teal-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(4 47 46 / var(--tw-divide-opacity)) !important;
}

.tw-divide-teal-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0) !important;
}

.tw-divide-teal-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.1) !important;
}

.tw-divide-teal-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 1) !important;
}

.tw-divide-teal-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.15) !important;
}

.tw-divide-teal-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.2) !important;
}

.tw-divide-teal-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.25) !important;
}

.tw-divide-teal-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.3) !important;
}

.tw-divide-teal-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.35) !important;
}

.tw-divide-teal-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.4) !important;
}

.tw-divide-teal-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.45) !important;
}

.tw-divide-teal-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.05) !important;
}

.tw-divide-teal-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.5) !important;
}

.tw-divide-teal-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.55) !important;
}

.tw-divide-teal-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.6) !important;
}

.tw-divide-teal-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.65) !important;
}

.tw-divide-teal-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.7) !important;
}

.tw-divide-teal-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.75) !important;
}

.tw-divide-teal-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.8) !important;
}

.tw-divide-teal-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.85) !important;
}

.tw-divide-teal-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.9) !important;
}

.tw-divide-teal-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(4 47 46 / 0.95) !important;
}

.tw-divide-transparent > :not([hidden]) ~ :not([hidden]) {
  border-color: transparent !important;
}

.tw-divide-transparent\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0) !important;
}

.tw-divide-transparent\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.1) !important;
}

.tw-divide-transparent\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 1) !important;
}

.tw-divide-transparent\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.15) !important;
}

.tw-divide-transparent\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.2) !important;
}

.tw-divide-transparent\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.25) !important;
}

.tw-divide-transparent\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.3) !important;
}

.tw-divide-transparent\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.35) !important;
}

.tw-divide-transparent\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.4) !important;
}

.tw-divide-transparent\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.45) !important;
}

.tw-divide-transparent\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.05) !important;
}

.tw-divide-transparent\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.5) !important;
}

.tw-divide-transparent\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.55) !important;
}

.tw-divide-transparent\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.6) !important;
}

.tw-divide-transparent\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.65) !important;
}

.tw-divide-transparent\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.7) !important;
}

.tw-divide-transparent\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.75) !important;
}

.tw-divide-transparent\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.8) !important;
}

.tw-divide-transparent\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.85) !important;
}

.tw-divide-transparent\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.9) !important;
}

.tw-divide-transparent\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(0 0 0 / 0.95) !important;
}

.tw-divide-violet-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(237 233 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0) !important;
}

.tw-divide-violet-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.1) !important;
}

.tw-divide-violet-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 1) !important;
}

.tw-divide-violet-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.15) !important;
}

.tw-divide-violet-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.2) !important;
}

.tw-divide-violet-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.25) !important;
}

.tw-divide-violet-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.3) !important;
}

.tw-divide-violet-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.35) !important;
}

.tw-divide-violet-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.4) !important;
}

.tw-divide-violet-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.45) !important;
}

.tw-divide-violet-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.05) !important;
}

.tw-divide-violet-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.5) !important;
}

.tw-divide-violet-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.55) !important;
}

.tw-divide-violet-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.6) !important;
}

.tw-divide-violet-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.65) !important;
}

.tw-divide-violet-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.7) !important;
}

.tw-divide-violet-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.75) !important;
}

.tw-divide-violet-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.8) !important;
}

.tw-divide-violet-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.85) !important;
}

.tw-divide-violet-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.9) !important;
}

.tw-divide-violet-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(237 233 254 / 0.95) !important;
}

.tw-divide-violet-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(221 214 254 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0) !important;
}

.tw-divide-violet-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.1) !important;
}

.tw-divide-violet-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 1) !important;
}

.tw-divide-violet-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.15) !important;
}

.tw-divide-violet-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.2) !important;
}

.tw-divide-violet-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.25) !important;
}

.tw-divide-violet-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.3) !important;
}

.tw-divide-violet-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.35) !important;
}

.tw-divide-violet-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.4) !important;
}

.tw-divide-violet-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.45) !important;
}

.tw-divide-violet-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.05) !important;
}

.tw-divide-violet-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.5) !important;
}

.tw-divide-violet-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.55) !important;
}

.tw-divide-violet-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.6) !important;
}

.tw-divide-violet-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.65) !important;
}

.tw-divide-violet-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.7) !important;
}

.tw-divide-violet-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.75) !important;
}

.tw-divide-violet-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.8) !important;
}

.tw-divide-violet-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.85) !important;
}

.tw-divide-violet-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.9) !important;
}

.tw-divide-violet-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(221 214 254 / 0.95) !important;
}

.tw-divide-violet-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(196 181 253 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0) !important;
}

.tw-divide-violet-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.1) !important;
}

.tw-divide-violet-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 1) !important;
}

.tw-divide-violet-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.15) !important;
}

.tw-divide-violet-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.2) !important;
}

.tw-divide-violet-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.25) !important;
}

.tw-divide-violet-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.3) !important;
}

.tw-divide-violet-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.35) !important;
}

.tw-divide-violet-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.4) !important;
}

.tw-divide-violet-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.45) !important;
}

.tw-divide-violet-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.05) !important;
}

.tw-divide-violet-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.5) !important;
}

.tw-divide-violet-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.55) !important;
}

.tw-divide-violet-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.6) !important;
}

.tw-divide-violet-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.65) !important;
}

.tw-divide-violet-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.7) !important;
}

.tw-divide-violet-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.75) !important;
}

.tw-divide-violet-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.8) !important;
}

.tw-divide-violet-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.85) !important;
}

.tw-divide-violet-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.9) !important;
}

.tw-divide-violet-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(196 181 253 / 0.95) !important;
}

.tw-divide-violet-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(167 139 250 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0) !important;
}

.tw-divide-violet-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.1) !important;
}

.tw-divide-violet-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 1) !important;
}

.tw-divide-violet-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.15) !important;
}

.tw-divide-violet-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.2) !important;
}

.tw-divide-violet-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.25) !important;
}

.tw-divide-violet-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.3) !important;
}

.tw-divide-violet-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.35) !important;
}

.tw-divide-violet-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.4) !important;
}

.tw-divide-violet-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.45) !important;
}

.tw-divide-violet-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.05) !important;
}

.tw-divide-violet-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.5) !important;
}

.tw-divide-violet-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.55) !important;
}

.tw-divide-violet-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.6) !important;
}

.tw-divide-violet-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.65) !important;
}

.tw-divide-violet-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.7) !important;
}

.tw-divide-violet-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.75) !important;
}

.tw-divide-violet-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.8) !important;
}

.tw-divide-violet-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.85) !important;
}

.tw-divide-violet-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.9) !important;
}

.tw-divide-violet-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(167 139 250 / 0.95) !important;
}

.tw-divide-violet-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(245 243 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0) !important;
}

.tw-divide-violet-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.1) !important;
}

.tw-divide-violet-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 1) !important;
}

.tw-divide-violet-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.15) !important;
}

.tw-divide-violet-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.2) !important;
}

.tw-divide-violet-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.25) !important;
}

.tw-divide-violet-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.3) !important;
}

.tw-divide-violet-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.35) !important;
}

.tw-divide-violet-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.4) !important;
}

.tw-divide-violet-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.45) !important;
}

.tw-divide-violet-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.05) !important;
}

.tw-divide-violet-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.5) !important;
}

.tw-divide-violet-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.55) !important;
}

.tw-divide-violet-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.6) !important;
}

.tw-divide-violet-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.65) !important;
}

.tw-divide-violet-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.7) !important;
}

.tw-divide-violet-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.75) !important;
}

.tw-divide-violet-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.8) !important;
}

.tw-divide-violet-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.85) !important;
}

.tw-divide-violet-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.9) !important;
}

.tw-divide-violet-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(245 243 255 / 0.95) !important;
}

.tw-divide-violet-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(139 92 246 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0) !important;
}

.tw-divide-violet-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.1) !important;
}

.tw-divide-violet-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 1) !important;
}

.tw-divide-violet-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.15) !important;
}

.tw-divide-violet-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.2) !important;
}

.tw-divide-violet-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.25) !important;
}

.tw-divide-violet-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.3) !important;
}

.tw-divide-violet-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.35) !important;
}

.tw-divide-violet-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.4) !important;
}

.tw-divide-violet-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.45) !important;
}

.tw-divide-violet-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.05) !important;
}

.tw-divide-violet-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.5) !important;
}

.tw-divide-violet-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.55) !important;
}

.tw-divide-violet-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.6) !important;
}

.tw-divide-violet-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.65) !important;
}

.tw-divide-violet-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.7) !important;
}

.tw-divide-violet-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.75) !important;
}

.tw-divide-violet-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.8) !important;
}

.tw-divide-violet-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.85) !important;
}

.tw-divide-violet-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.9) !important;
}

.tw-divide-violet-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(139 92 246 / 0.95) !important;
}

.tw-divide-violet-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(124 58 237 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0) !important;
}

.tw-divide-violet-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.1) !important;
}

.tw-divide-violet-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 1) !important;
}

.tw-divide-violet-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.15) !important;
}

.tw-divide-violet-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.2) !important;
}

.tw-divide-violet-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.25) !important;
}

.tw-divide-violet-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.3) !important;
}

.tw-divide-violet-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.35) !important;
}

.tw-divide-violet-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.4) !important;
}

.tw-divide-violet-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.45) !important;
}

.tw-divide-violet-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.05) !important;
}

.tw-divide-violet-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.5) !important;
}

.tw-divide-violet-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.55) !important;
}

.tw-divide-violet-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.6) !important;
}

.tw-divide-violet-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.65) !important;
}

.tw-divide-violet-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.7) !important;
}

.tw-divide-violet-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.75) !important;
}

.tw-divide-violet-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.8) !important;
}

.tw-divide-violet-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.85) !important;
}

.tw-divide-violet-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.9) !important;
}

.tw-divide-violet-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(124 58 237 / 0.95) !important;
}

.tw-divide-violet-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(109 40 217 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0) !important;
}

.tw-divide-violet-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.1) !important;
}

.tw-divide-violet-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 1) !important;
}

.tw-divide-violet-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.15) !important;
}

.tw-divide-violet-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.2) !important;
}

.tw-divide-violet-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.25) !important;
}

.tw-divide-violet-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.3) !important;
}

.tw-divide-violet-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.35) !important;
}

.tw-divide-violet-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.4) !important;
}

.tw-divide-violet-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.45) !important;
}

.tw-divide-violet-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.05) !important;
}

.tw-divide-violet-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.5) !important;
}

.tw-divide-violet-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.55) !important;
}

.tw-divide-violet-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.6) !important;
}

.tw-divide-violet-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.65) !important;
}

.tw-divide-violet-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.7) !important;
}

.tw-divide-violet-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.75) !important;
}

.tw-divide-violet-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.8) !important;
}

.tw-divide-violet-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.85) !important;
}

.tw-divide-violet-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.9) !important;
}

.tw-divide-violet-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(109 40 217 / 0.95) !important;
}

.tw-divide-violet-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(91 33 182 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0) !important;
}

.tw-divide-violet-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.1) !important;
}

.tw-divide-violet-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 1) !important;
}

.tw-divide-violet-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.15) !important;
}

.tw-divide-violet-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.2) !important;
}

.tw-divide-violet-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.25) !important;
}

.tw-divide-violet-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.3) !important;
}

.tw-divide-violet-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.35) !important;
}

.tw-divide-violet-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.4) !important;
}

.tw-divide-violet-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.45) !important;
}

.tw-divide-violet-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.05) !important;
}

.tw-divide-violet-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.5) !important;
}

.tw-divide-violet-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.55) !important;
}

.tw-divide-violet-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.6) !important;
}

.tw-divide-violet-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.65) !important;
}

.tw-divide-violet-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.7) !important;
}

.tw-divide-violet-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.75) !important;
}

.tw-divide-violet-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.8) !important;
}

.tw-divide-violet-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.85) !important;
}

.tw-divide-violet-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.9) !important;
}

.tw-divide-violet-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(91 33 182 / 0.95) !important;
}

.tw-divide-violet-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(76 29 149 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0) !important;
}

.tw-divide-violet-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.1) !important;
}

.tw-divide-violet-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 1) !important;
}

.tw-divide-violet-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.15) !important;
}

.tw-divide-violet-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.2) !important;
}

.tw-divide-violet-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.25) !important;
}

.tw-divide-violet-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.3) !important;
}

.tw-divide-violet-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.35) !important;
}

.tw-divide-violet-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.4) !important;
}

.tw-divide-violet-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.45) !important;
}

.tw-divide-violet-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.05) !important;
}

.tw-divide-violet-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.5) !important;
}

.tw-divide-violet-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.55) !important;
}

.tw-divide-violet-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.6) !important;
}

.tw-divide-violet-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.65) !important;
}

.tw-divide-violet-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.7) !important;
}

.tw-divide-violet-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.75) !important;
}

.tw-divide-violet-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.8) !important;
}

.tw-divide-violet-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.85) !important;
}

.tw-divide-violet-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.9) !important;
}

.tw-divide-violet-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(76 29 149 / 0.95) !important;
}

.tw-divide-violet-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(46 16 101 / var(--tw-divide-opacity)) !important;
}

.tw-divide-violet-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0) !important;
}

.tw-divide-violet-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.1) !important;
}

.tw-divide-violet-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 1) !important;
}

.tw-divide-violet-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.15) !important;
}

.tw-divide-violet-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.2) !important;
}

.tw-divide-violet-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.25) !important;
}

.tw-divide-violet-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.3) !important;
}

.tw-divide-violet-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.35) !important;
}

.tw-divide-violet-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.4) !important;
}

.tw-divide-violet-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.45) !important;
}

.tw-divide-violet-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.05) !important;
}

.tw-divide-violet-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.5) !important;
}

.tw-divide-violet-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.55) !important;
}

.tw-divide-violet-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.6) !important;
}

.tw-divide-violet-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.65) !important;
}

.tw-divide-violet-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.7) !important;
}

.tw-divide-violet-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.75) !important;
}

.tw-divide-violet-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.8) !important;
}

.tw-divide-violet-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.85) !important;
}

.tw-divide-violet-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.9) !important;
}

.tw-divide-violet-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(46 16 101 / 0.95) !important;
}

.tw-divide-white > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-divide-opacity)) !important;
}

.tw-divide-white\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0) !important;
}

.tw-divide-white\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.1) !important;
}

.tw-divide-white\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 1) !important;
}

.tw-divide-white\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.15) !important;
}

.tw-divide-white\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.2) !important;
}

.tw-divide-white\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.25) !important;
}

.tw-divide-white\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.3) !important;
}

.tw-divide-white\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.35) !important;
}

.tw-divide-white\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.4) !important;
}

.tw-divide-white\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.45) !important;
}

.tw-divide-white\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.05) !important;
}

.tw-divide-white\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.5) !important;
}

.tw-divide-white\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.55) !important;
}

.tw-divide-white\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.6) !important;
}

.tw-divide-white\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.65) !important;
}

.tw-divide-white\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.7) !important;
}

.tw-divide-white\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.75) !important;
}

.tw-divide-white\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.8) !important;
}

.tw-divide-white\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.85) !important;
}

.tw-divide-white\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.9) !important;
}

.tw-divide-white\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(255 255 255 / 0.95) !important;
}

.tw-divide-yellow-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 249 195 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0) !important;
}

.tw-divide-yellow-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.1) !important;
}

.tw-divide-yellow-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 1) !important;
}

.tw-divide-yellow-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.15) !important;
}

.tw-divide-yellow-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.2) !important;
}

.tw-divide-yellow-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.25) !important;
}

.tw-divide-yellow-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.3) !important;
}

.tw-divide-yellow-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.35) !important;
}

.tw-divide-yellow-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.4) !important;
}

.tw-divide-yellow-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.45) !important;
}

.tw-divide-yellow-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.05) !important;
}

.tw-divide-yellow-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.5) !important;
}

.tw-divide-yellow-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.55) !important;
}

.tw-divide-yellow-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.6) !important;
}

.tw-divide-yellow-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.65) !important;
}

.tw-divide-yellow-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.7) !important;
}

.tw-divide-yellow-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.75) !important;
}

.tw-divide-yellow-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.8) !important;
}

.tw-divide-yellow-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.85) !important;
}

.tw-divide-yellow-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.9) !important;
}

.tw-divide-yellow-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 249 195 / 0.95) !important;
}

.tw-divide-yellow-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 240 138 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0) !important;
}

.tw-divide-yellow-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.1) !important;
}

.tw-divide-yellow-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 1) !important;
}

.tw-divide-yellow-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.15) !important;
}

.tw-divide-yellow-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.2) !important;
}

.tw-divide-yellow-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.25) !important;
}

.tw-divide-yellow-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.3) !important;
}

.tw-divide-yellow-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.35) !important;
}

.tw-divide-yellow-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.4) !important;
}

.tw-divide-yellow-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.45) !important;
}

.tw-divide-yellow-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.05) !important;
}

.tw-divide-yellow-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.5) !important;
}

.tw-divide-yellow-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.55) !important;
}

.tw-divide-yellow-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.6) !important;
}

.tw-divide-yellow-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.65) !important;
}

.tw-divide-yellow-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.7) !important;
}

.tw-divide-yellow-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.75) !important;
}

.tw-divide-yellow-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.8) !important;
}

.tw-divide-yellow-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.85) !important;
}

.tw-divide-yellow-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.9) !important;
}

.tw-divide-yellow-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 240 138 / 0.95) !important;
}

.tw-divide-yellow-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(253 224 71 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0) !important;
}

.tw-divide-yellow-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.1) !important;
}

.tw-divide-yellow-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 1) !important;
}

.tw-divide-yellow-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.15) !important;
}

.tw-divide-yellow-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.2) !important;
}

.tw-divide-yellow-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.25) !important;
}

.tw-divide-yellow-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.3) !important;
}

.tw-divide-yellow-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.35) !important;
}

.tw-divide-yellow-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.4) !important;
}

.tw-divide-yellow-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.45) !important;
}

.tw-divide-yellow-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.05) !important;
}

.tw-divide-yellow-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.5) !important;
}

.tw-divide-yellow-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.55) !important;
}

.tw-divide-yellow-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.6) !important;
}

.tw-divide-yellow-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.65) !important;
}

.tw-divide-yellow-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.7) !important;
}

.tw-divide-yellow-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.75) !important;
}

.tw-divide-yellow-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.8) !important;
}

.tw-divide-yellow-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.85) !important;
}

.tw-divide-yellow-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.9) !important;
}

.tw-divide-yellow-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(253 224 71 / 0.95) !important;
}

.tw-divide-yellow-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(250 204 21 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0) !important;
}

.tw-divide-yellow-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.1) !important;
}

.tw-divide-yellow-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 1) !important;
}

.tw-divide-yellow-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.15) !important;
}

.tw-divide-yellow-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.2) !important;
}

.tw-divide-yellow-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.25) !important;
}

.tw-divide-yellow-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.3) !important;
}

.tw-divide-yellow-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.35) !important;
}

.tw-divide-yellow-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.4) !important;
}

.tw-divide-yellow-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.45) !important;
}

.tw-divide-yellow-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.05) !important;
}

.tw-divide-yellow-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.5) !important;
}

.tw-divide-yellow-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.55) !important;
}

.tw-divide-yellow-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.6) !important;
}

.tw-divide-yellow-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.65) !important;
}

.tw-divide-yellow-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.7) !important;
}

.tw-divide-yellow-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.75) !important;
}

.tw-divide-yellow-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.8) !important;
}

.tw-divide-yellow-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.85) !important;
}

.tw-divide-yellow-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.9) !important;
}

.tw-divide-yellow-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 204 21 / 0.95) !important;
}

.tw-divide-yellow-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(254 252 232 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0) !important;
}

.tw-divide-yellow-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.1) !important;
}

.tw-divide-yellow-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 1) !important;
}

.tw-divide-yellow-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.15) !important;
}

.tw-divide-yellow-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.2) !important;
}

.tw-divide-yellow-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.25) !important;
}

.tw-divide-yellow-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.3) !important;
}

.tw-divide-yellow-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.35) !important;
}

.tw-divide-yellow-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.4) !important;
}

.tw-divide-yellow-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.45) !important;
}

.tw-divide-yellow-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.05) !important;
}

.tw-divide-yellow-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.5) !important;
}

.tw-divide-yellow-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.55) !important;
}

.tw-divide-yellow-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.6) !important;
}

.tw-divide-yellow-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.65) !important;
}

.tw-divide-yellow-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.7) !important;
}

.tw-divide-yellow-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.75) !important;
}

.tw-divide-yellow-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.8) !important;
}

.tw-divide-yellow-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.85) !important;
}

.tw-divide-yellow-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.9) !important;
}

.tw-divide-yellow-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(254 252 232 / 0.95) !important;
}

.tw-divide-yellow-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(234 179 8 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0) !important;
}

.tw-divide-yellow-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.1) !important;
}

.tw-divide-yellow-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 1) !important;
}

.tw-divide-yellow-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.15) !important;
}

.tw-divide-yellow-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.2) !important;
}

.tw-divide-yellow-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.25) !important;
}

.tw-divide-yellow-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.3) !important;
}

.tw-divide-yellow-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.35) !important;
}

.tw-divide-yellow-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.4) !important;
}

.tw-divide-yellow-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.45) !important;
}

.tw-divide-yellow-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.05) !important;
}

.tw-divide-yellow-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.5) !important;
}

.tw-divide-yellow-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.55) !important;
}

.tw-divide-yellow-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.6) !important;
}

.tw-divide-yellow-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.65) !important;
}

.tw-divide-yellow-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.7) !important;
}

.tw-divide-yellow-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.75) !important;
}

.tw-divide-yellow-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.8) !important;
}

.tw-divide-yellow-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.85) !important;
}

.tw-divide-yellow-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.9) !important;
}

.tw-divide-yellow-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(234 179 8 / 0.95) !important;
}

.tw-divide-yellow-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(202 138 4 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0) !important;
}

.tw-divide-yellow-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.1) !important;
}

.tw-divide-yellow-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 1) !important;
}

.tw-divide-yellow-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.15) !important;
}

.tw-divide-yellow-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.2) !important;
}

.tw-divide-yellow-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.25) !important;
}

.tw-divide-yellow-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.3) !important;
}

.tw-divide-yellow-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.35) !important;
}

.tw-divide-yellow-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.4) !important;
}

.tw-divide-yellow-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.45) !important;
}

.tw-divide-yellow-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.05) !important;
}

.tw-divide-yellow-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.5) !important;
}

.tw-divide-yellow-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.55) !important;
}

.tw-divide-yellow-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.6) !important;
}

.tw-divide-yellow-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.65) !important;
}

.tw-divide-yellow-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.7) !important;
}

.tw-divide-yellow-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.75) !important;
}

.tw-divide-yellow-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.8) !important;
}

.tw-divide-yellow-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.85) !important;
}

.tw-divide-yellow-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.9) !important;
}

.tw-divide-yellow-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(202 138 4 / 0.95) !important;
}

.tw-divide-yellow-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(161 98 7 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0) !important;
}

.tw-divide-yellow-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.1) !important;
}

.tw-divide-yellow-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 1) !important;
}

.tw-divide-yellow-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.15) !important;
}

.tw-divide-yellow-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.2) !important;
}

.tw-divide-yellow-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.25) !important;
}

.tw-divide-yellow-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.3) !important;
}

.tw-divide-yellow-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.35) !important;
}

.tw-divide-yellow-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.4) !important;
}

.tw-divide-yellow-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.45) !important;
}

.tw-divide-yellow-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.05) !important;
}

.tw-divide-yellow-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.5) !important;
}

.tw-divide-yellow-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.55) !important;
}

.tw-divide-yellow-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.6) !important;
}

.tw-divide-yellow-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.65) !important;
}

.tw-divide-yellow-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.7) !important;
}

.tw-divide-yellow-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.75) !important;
}

.tw-divide-yellow-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.8) !important;
}

.tw-divide-yellow-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.85) !important;
}

.tw-divide-yellow-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.9) !important;
}

.tw-divide-yellow-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 98 7 / 0.95) !important;
}

.tw-divide-yellow-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(133 77 14 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0) !important;
}

.tw-divide-yellow-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.1) !important;
}

.tw-divide-yellow-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 1) !important;
}

.tw-divide-yellow-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.15) !important;
}

.tw-divide-yellow-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.2) !important;
}

.tw-divide-yellow-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.25) !important;
}

.tw-divide-yellow-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.3) !important;
}

.tw-divide-yellow-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.35) !important;
}

.tw-divide-yellow-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.4) !important;
}

.tw-divide-yellow-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.45) !important;
}

.tw-divide-yellow-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.05) !important;
}

.tw-divide-yellow-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.5) !important;
}

.tw-divide-yellow-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.55) !important;
}

.tw-divide-yellow-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.6) !important;
}

.tw-divide-yellow-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.65) !important;
}

.tw-divide-yellow-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.7) !important;
}

.tw-divide-yellow-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.75) !important;
}

.tw-divide-yellow-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.8) !important;
}

.tw-divide-yellow-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.85) !important;
}

.tw-divide-yellow-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.9) !important;
}

.tw-divide-yellow-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(133 77 14 / 0.95) !important;
}

.tw-divide-yellow-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(113 63 18 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0) !important;
}

.tw-divide-yellow-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.1) !important;
}

.tw-divide-yellow-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 1) !important;
}

.tw-divide-yellow-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.15) !important;
}

.tw-divide-yellow-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.2) !important;
}

.tw-divide-yellow-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.25) !important;
}

.tw-divide-yellow-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.3) !important;
}

.tw-divide-yellow-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.35) !important;
}

.tw-divide-yellow-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.4) !important;
}

.tw-divide-yellow-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.45) !important;
}

.tw-divide-yellow-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.05) !important;
}

.tw-divide-yellow-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.5) !important;
}

.tw-divide-yellow-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.55) !important;
}

.tw-divide-yellow-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.6) !important;
}

.tw-divide-yellow-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.65) !important;
}

.tw-divide-yellow-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.7) !important;
}

.tw-divide-yellow-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.75) !important;
}

.tw-divide-yellow-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.8) !important;
}

.tw-divide-yellow-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.85) !important;
}

.tw-divide-yellow-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.9) !important;
}

.tw-divide-yellow-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 63 18 / 0.95) !important;
}

.tw-divide-yellow-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(66 32 6 / var(--tw-divide-opacity)) !important;
}

.tw-divide-yellow-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0) !important;
}

.tw-divide-yellow-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.1) !important;
}

.tw-divide-yellow-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 1) !important;
}

.tw-divide-yellow-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.15) !important;
}

.tw-divide-yellow-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.2) !important;
}

.tw-divide-yellow-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.25) !important;
}

.tw-divide-yellow-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.3) !important;
}

.tw-divide-yellow-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.35) !important;
}

.tw-divide-yellow-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.4) !important;
}

.tw-divide-yellow-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.45) !important;
}

.tw-divide-yellow-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.05) !important;
}

.tw-divide-yellow-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.5) !important;
}

.tw-divide-yellow-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.55) !important;
}

.tw-divide-yellow-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.6) !important;
}

.tw-divide-yellow-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.65) !important;
}

.tw-divide-yellow-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.7) !important;
}

.tw-divide-yellow-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.75) !important;
}

.tw-divide-yellow-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.8) !important;
}

.tw-divide-yellow-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.85) !important;
}

.tw-divide-yellow-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.9) !important;
}

.tw-divide-yellow-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(66 32 6 / 0.95) !important;
}

.tw-divide-zinc-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(244 244 245 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-100\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0) !important;
}

.tw-divide-zinc-100\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.1) !important;
}

.tw-divide-zinc-100\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 1) !important;
}

.tw-divide-zinc-100\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.15) !important;
}

.tw-divide-zinc-100\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.2) !important;
}

.tw-divide-zinc-100\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.25) !important;
}

.tw-divide-zinc-100\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.3) !important;
}

.tw-divide-zinc-100\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.35) !important;
}

.tw-divide-zinc-100\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.4) !important;
}

.tw-divide-zinc-100\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.45) !important;
}

.tw-divide-zinc-100\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.05) !important;
}

.tw-divide-zinc-100\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.5) !important;
}

.tw-divide-zinc-100\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.55) !important;
}

.tw-divide-zinc-100\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.6) !important;
}

.tw-divide-zinc-100\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.65) !important;
}

.tw-divide-zinc-100\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.7) !important;
}

.tw-divide-zinc-100\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.75) !important;
}

.tw-divide-zinc-100\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.8) !important;
}

.tw-divide-zinc-100\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.85) !important;
}

.tw-divide-zinc-100\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.9) !important;
}

.tw-divide-zinc-100\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(244 244 245 / 0.95) !important;
}

.tw-divide-zinc-200 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(228 228 231 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-200\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0) !important;
}

.tw-divide-zinc-200\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.1) !important;
}

.tw-divide-zinc-200\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 1) !important;
}

.tw-divide-zinc-200\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.15) !important;
}

.tw-divide-zinc-200\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.2) !important;
}

.tw-divide-zinc-200\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.25) !important;
}

.tw-divide-zinc-200\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.3) !important;
}

.tw-divide-zinc-200\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.35) !important;
}

.tw-divide-zinc-200\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.4) !important;
}

.tw-divide-zinc-200\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.45) !important;
}

.tw-divide-zinc-200\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.05) !important;
}

.tw-divide-zinc-200\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.5) !important;
}

.tw-divide-zinc-200\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.55) !important;
}

.tw-divide-zinc-200\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.6) !important;
}

.tw-divide-zinc-200\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.65) !important;
}

.tw-divide-zinc-200\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.7) !important;
}

.tw-divide-zinc-200\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.75) !important;
}

.tw-divide-zinc-200\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.8) !important;
}

.tw-divide-zinc-200\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.85) !important;
}

.tw-divide-zinc-200\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.9) !important;
}

.tw-divide-zinc-200\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(228 228 231 / 0.95) !important;
}

.tw-divide-zinc-300 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(212 212 216 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-300\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0) !important;
}

.tw-divide-zinc-300\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.1) !important;
}

.tw-divide-zinc-300\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 1) !important;
}

.tw-divide-zinc-300\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.15) !important;
}

.tw-divide-zinc-300\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.2) !important;
}

.tw-divide-zinc-300\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.25) !important;
}

.tw-divide-zinc-300\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.3) !important;
}

.tw-divide-zinc-300\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.35) !important;
}

.tw-divide-zinc-300\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.4) !important;
}

.tw-divide-zinc-300\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.45) !important;
}

.tw-divide-zinc-300\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.05) !important;
}

.tw-divide-zinc-300\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.5) !important;
}

.tw-divide-zinc-300\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.55) !important;
}

.tw-divide-zinc-300\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.6) !important;
}

.tw-divide-zinc-300\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.65) !important;
}

.tw-divide-zinc-300\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.7) !important;
}

.tw-divide-zinc-300\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.75) !important;
}

.tw-divide-zinc-300\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.8) !important;
}

.tw-divide-zinc-300\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.85) !important;
}

.tw-divide-zinc-300\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.9) !important;
}

.tw-divide-zinc-300\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(212 212 216 / 0.95) !important;
}

.tw-divide-zinc-400 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(161 161 170 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-400\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0) !important;
}

.tw-divide-zinc-400\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.1) !important;
}

.tw-divide-zinc-400\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 1) !important;
}

.tw-divide-zinc-400\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.15) !important;
}

.tw-divide-zinc-400\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.2) !important;
}

.tw-divide-zinc-400\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.25) !important;
}

.tw-divide-zinc-400\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.3) !important;
}

.tw-divide-zinc-400\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.35) !important;
}

.tw-divide-zinc-400\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.4) !important;
}

.tw-divide-zinc-400\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.45) !important;
}

.tw-divide-zinc-400\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.05) !important;
}

.tw-divide-zinc-400\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.5) !important;
}

.tw-divide-zinc-400\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.55) !important;
}

.tw-divide-zinc-400\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.6) !important;
}

.tw-divide-zinc-400\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.65) !important;
}

.tw-divide-zinc-400\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.7) !important;
}

.tw-divide-zinc-400\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.75) !important;
}

.tw-divide-zinc-400\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.8) !important;
}

.tw-divide-zinc-400\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.85) !important;
}

.tw-divide-zinc-400\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.9) !important;
}

.tw-divide-zinc-400\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(161 161 170 / 0.95) !important;
}

.tw-divide-zinc-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(250 250 250 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-50\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0) !important;
}

.tw-divide-zinc-50\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.1) !important;
}

.tw-divide-zinc-50\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 1) !important;
}

.tw-divide-zinc-50\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.15) !important;
}

.tw-divide-zinc-50\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.2) !important;
}

.tw-divide-zinc-50\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.25) !important;
}

.tw-divide-zinc-50\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.3) !important;
}

.tw-divide-zinc-50\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.35) !important;
}

.tw-divide-zinc-50\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.4) !important;
}

.tw-divide-zinc-50\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.45) !important;
}

.tw-divide-zinc-50\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.05) !important;
}

.tw-divide-zinc-50\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.5) !important;
}

.tw-divide-zinc-50\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.55) !important;
}

.tw-divide-zinc-50\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.6) !important;
}

.tw-divide-zinc-50\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.65) !important;
}

.tw-divide-zinc-50\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.7) !important;
}

.tw-divide-zinc-50\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.75) !important;
}

.tw-divide-zinc-50\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.8) !important;
}

.tw-divide-zinc-50\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.85) !important;
}

.tw-divide-zinc-50\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.9) !important;
}

.tw-divide-zinc-50\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(250 250 250 / 0.95) !important;
}

.tw-divide-zinc-500 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(113 113 122 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-500\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0) !important;
}

.tw-divide-zinc-500\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.1) !important;
}

.tw-divide-zinc-500\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 1) !important;
}

.tw-divide-zinc-500\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.15) !important;
}

.tw-divide-zinc-500\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.2) !important;
}

.tw-divide-zinc-500\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.25) !important;
}

.tw-divide-zinc-500\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.3) !important;
}

.tw-divide-zinc-500\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.35) !important;
}

.tw-divide-zinc-500\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.4) !important;
}

.tw-divide-zinc-500\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.45) !important;
}

.tw-divide-zinc-500\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.05) !important;
}

.tw-divide-zinc-500\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.5) !important;
}

.tw-divide-zinc-500\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.55) !important;
}

.tw-divide-zinc-500\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.6) !important;
}

.tw-divide-zinc-500\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.65) !important;
}

.tw-divide-zinc-500\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.7) !important;
}

.tw-divide-zinc-500\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.75) !important;
}

.tw-divide-zinc-500\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.8) !important;
}

.tw-divide-zinc-500\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.85) !important;
}

.tw-divide-zinc-500\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.9) !important;
}

.tw-divide-zinc-500\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(113 113 122 / 0.95) !important;
}

.tw-divide-zinc-600 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(82 82 91 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-600\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0) !important;
}

.tw-divide-zinc-600\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.1) !important;
}

.tw-divide-zinc-600\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 1) !important;
}

.tw-divide-zinc-600\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.15) !important;
}

.tw-divide-zinc-600\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.2) !important;
}

.tw-divide-zinc-600\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.25) !important;
}

.tw-divide-zinc-600\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.3) !important;
}

.tw-divide-zinc-600\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.35) !important;
}

.tw-divide-zinc-600\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.4) !important;
}

.tw-divide-zinc-600\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.45) !important;
}

.tw-divide-zinc-600\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.05) !important;
}

.tw-divide-zinc-600\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.5) !important;
}

.tw-divide-zinc-600\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.55) !important;
}

.tw-divide-zinc-600\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.6) !important;
}

.tw-divide-zinc-600\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.65) !important;
}

.tw-divide-zinc-600\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.7) !important;
}

.tw-divide-zinc-600\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.75) !important;
}

.tw-divide-zinc-600\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.8) !important;
}

.tw-divide-zinc-600\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.85) !important;
}

.tw-divide-zinc-600\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.9) !important;
}

.tw-divide-zinc-600\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(82 82 91 / 0.95) !important;
}

.tw-divide-zinc-700 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(63 63 70 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-700\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0) !important;
}

.tw-divide-zinc-700\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.1) !important;
}

.tw-divide-zinc-700\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 1) !important;
}

.tw-divide-zinc-700\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.15) !important;
}

.tw-divide-zinc-700\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.2) !important;
}

.tw-divide-zinc-700\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.25) !important;
}

.tw-divide-zinc-700\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.3) !important;
}

.tw-divide-zinc-700\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.35) !important;
}

.tw-divide-zinc-700\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.4) !important;
}

.tw-divide-zinc-700\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.45) !important;
}

.tw-divide-zinc-700\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.05) !important;
}

.tw-divide-zinc-700\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.5) !important;
}

.tw-divide-zinc-700\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.55) !important;
}

.tw-divide-zinc-700\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.6) !important;
}

.tw-divide-zinc-700\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.65) !important;
}

.tw-divide-zinc-700\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.7) !important;
}

.tw-divide-zinc-700\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.75) !important;
}

.tw-divide-zinc-700\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.8) !important;
}

.tw-divide-zinc-700\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.85) !important;
}

.tw-divide-zinc-700\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.9) !important;
}

.tw-divide-zinc-700\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(63 63 70 / 0.95) !important;
}

.tw-divide-zinc-800 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(39 39 42 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-800\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0) !important;
}

.tw-divide-zinc-800\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.1) !important;
}

.tw-divide-zinc-800\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 1) !important;
}

.tw-divide-zinc-800\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.15) !important;
}

.tw-divide-zinc-800\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.2) !important;
}

.tw-divide-zinc-800\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.25) !important;
}

.tw-divide-zinc-800\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.3) !important;
}

.tw-divide-zinc-800\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.35) !important;
}

.tw-divide-zinc-800\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.4) !important;
}

.tw-divide-zinc-800\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.45) !important;
}

.tw-divide-zinc-800\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.05) !important;
}

.tw-divide-zinc-800\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.5) !important;
}

.tw-divide-zinc-800\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.55) !important;
}

.tw-divide-zinc-800\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.6) !important;
}

.tw-divide-zinc-800\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.65) !important;
}

.tw-divide-zinc-800\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.7) !important;
}

.tw-divide-zinc-800\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.75) !important;
}

.tw-divide-zinc-800\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.8) !important;
}

.tw-divide-zinc-800\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.85) !important;
}

.tw-divide-zinc-800\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.9) !important;
}

.tw-divide-zinc-800\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(39 39 42 / 0.95) !important;
}

.tw-divide-zinc-900 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(24 24 27 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-900\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0) !important;
}

.tw-divide-zinc-900\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.1) !important;
}

.tw-divide-zinc-900\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 1) !important;
}

.tw-divide-zinc-900\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.15) !important;
}

.tw-divide-zinc-900\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.2) !important;
}

.tw-divide-zinc-900\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.25) !important;
}

.tw-divide-zinc-900\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.3) !important;
}

.tw-divide-zinc-900\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.35) !important;
}

.tw-divide-zinc-900\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.4) !important;
}

.tw-divide-zinc-900\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.45) !important;
}

.tw-divide-zinc-900\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.05) !important;
}

.tw-divide-zinc-900\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.5) !important;
}

.tw-divide-zinc-900\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.55) !important;
}

.tw-divide-zinc-900\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.6) !important;
}

.tw-divide-zinc-900\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.65) !important;
}

.tw-divide-zinc-900\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.7) !important;
}

.tw-divide-zinc-900\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.75) !important;
}

.tw-divide-zinc-900\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.8) !important;
}

.tw-divide-zinc-900\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.85) !important;
}

.tw-divide-zinc-900\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.9) !important;
}

.tw-divide-zinc-900\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(24 24 27 / 0.95) !important;
}

.tw-divide-zinc-950 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
  border-color: rgb(9 9 11 / var(--tw-divide-opacity)) !important;
}

.tw-divide-zinc-950\/0 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0) !important;
}

.tw-divide-zinc-950\/10 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.1) !important;
}

.tw-divide-zinc-950\/100 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 1) !important;
}

.tw-divide-zinc-950\/15 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.15) !important;
}

.tw-divide-zinc-950\/20 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.2) !important;
}

.tw-divide-zinc-950\/25 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.25) !important;
}

.tw-divide-zinc-950\/30 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.3) !important;
}

.tw-divide-zinc-950\/35 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.35) !important;
}

.tw-divide-zinc-950\/40 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.4) !important;
}

.tw-divide-zinc-950\/45 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.45) !important;
}

.tw-divide-zinc-950\/5 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.05) !important;
}

.tw-divide-zinc-950\/50 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.5) !important;
}

.tw-divide-zinc-950\/55 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.55) !important;
}

.tw-divide-zinc-950\/60 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.6) !important;
}

.tw-divide-zinc-950\/65 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.65) !important;
}

.tw-divide-zinc-950\/70 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.7) !important;
}

.tw-divide-zinc-950\/75 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.75) !important;
}

.tw-divide-zinc-950\/80 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.8) !important;
}

.tw-divide-zinc-950\/85 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.85) !important;
}

.tw-divide-zinc-950\/90 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.9) !important;
}

.tw-divide-zinc-950\/95 > :not([hidden]) ~ :not([hidden]) {
  border-color: rgb(9 9 11 / 0.95) !important;
}

.tw-divide-opacity-0 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0 !important;
}

.tw-divide-opacity-10 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.1 !important;
}

.tw-divide-opacity-100 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 1 !important;
}

.tw-divide-opacity-15 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.15 !important;
}

.tw-divide-opacity-20 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.2 !important;
}

.tw-divide-opacity-25 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.25 !important;
}

.tw-divide-opacity-30 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.3 !important;
}

.tw-divide-opacity-35 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.35 !important;
}

.tw-divide-opacity-40 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.4 !important;
}

.tw-divide-opacity-45 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.45 !important;
}

.tw-divide-opacity-5 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.05 !important;
}

.tw-divide-opacity-50 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.5 !important;
}

.tw-divide-opacity-55 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.55 !important;
}

.tw-divide-opacity-60 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.6 !important;
}

.tw-divide-opacity-65 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.65 !important;
}

.tw-divide-opacity-70 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.7 !important;
}

.tw-divide-opacity-75 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.75 !important;
}

.tw-divide-opacity-80 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.8 !important;
}

.tw-divide-opacity-85 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.85 !important;
}

.tw-divide-opacity-90 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.9 !important;
}

.tw-divide-opacity-95 > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-opacity: 0.95 !important;
}

.tw-place-self-auto {
  place-self: auto !important;
}

.tw-place-self-start {
  place-self: start !important;
}

.tw-place-self-end {
  place-self: end !important;
}

.tw-place-self-center {
  place-self: center !important;
}

.tw-place-self-stretch {
  place-self: stretch !important;
}

.tw-self-auto {
  align-self: auto !important;
}

.tw-self-start {
  align-self: flex-start !important;
}

.tw-self-end {
  align-self: flex-end !important;
}

.tw-self-center {
  align-self: center !important;
}

.tw-self-stretch {
  align-self: stretch !important;
}

.tw-self-baseline {
  align-self: baseline !important;
}

.tw-justify-self-auto {
  justify-self: auto !important;
}

.tw-justify-self-start {
  justify-self: start !important;
}

.tw-justify-self-end {
  justify-self: end !important;
}

.tw-justify-self-center {
  justify-self: center !important;
}

.tw-justify-self-stretch {
  justify-self: stretch !important;
}

.tw-overflow-auto {
  overflow: auto !important;
}

.tw-overflow-hidden {
  overflow: hidden !important;
}

.tw-overflow-clip {
  overflow: clip !important;
}

.tw-overflow-visible {
  overflow: visible !important;
}

.tw-overflow-scroll {
  overflow: scroll !important;
}

.tw-overflow-x-auto {
  overflow-x: auto !important;
}

.tw-overflow-y-auto {
  overflow-y: auto !important;
}

.tw-overflow-x-hidden {
  overflow-x: hidden !important;
}

.tw-overflow-y-hidden {
  overflow-y: hidden !important;
}

.tw-overflow-x-clip {
  overflow-x: clip !important;
}

.tw-overflow-y-clip {
  overflow-y: clip !important;
}

.tw-overflow-x-visible {
  overflow-x: visible !important;
}

.tw-overflow-y-visible {
  overflow-y: visible !important;
}

.tw-overflow-x-scroll {
  overflow-x: scroll !important;
}

.tw-overflow-y-scroll {
  overflow-y: scroll !important;
}

.tw-overscroll-auto {
  overscroll-behavior: auto !important;
}

.tw-overscroll-contain {
  overscroll-behavior: contain !important;
}

.tw-overscroll-none {
  overscroll-behavior: none !important;
}

.tw-overscroll-y-auto {
  overscroll-behavior-y: auto !important;
}

.tw-overscroll-y-contain {
  overscroll-behavior-y: contain !important;
}

.tw-overscroll-y-none {
  overscroll-behavior-y: none !important;
}

.tw-overscroll-x-auto {
  overscroll-behavior-x: auto !important;
}

.tw-overscroll-x-contain {
  overscroll-behavior-x: contain !important;
}

.tw-overscroll-x-none {
  overscroll-behavior-x: none !important;
}

.tw-scroll-auto {
  scroll-behavior: auto !important;
}

.tw-scroll-smooth {
  scroll-behavior: smooth !important;
}

.tw-truncate {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

.tw-overflow-ellipsis {
  text-overflow: ellipsis !important;
}

.tw-text-ellipsis {
  text-overflow: ellipsis !important;
}

.tw-text-clip {
  text-overflow: clip !important;
}

.tw-hyphens-none {
  -webkit-hyphens: none !important;
          hyphens: none !important;
}

.tw-hyphens-manual {
  -webkit-hyphens: manual !important;
          hyphens: manual !important;
}

.tw-hyphens-auto {
  -webkit-hyphens: auto !important;
          hyphens: auto !important;
}

.tw-whitespace-normal {
  white-space: normal !important;
}

.tw-whitespace-nowrap {
  white-space: nowrap !important;
}

.tw-whitespace-pre {
  white-space: pre !important;
}

.tw-whitespace-pre-line {
  white-space: pre-line !important;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap !important;
}

.tw-whitespace-break-spaces {
  white-space: break-spaces !important;
}

.tw-text-wrap {
  text-wrap: wrap !important;
}

.tw-text-nowrap {
  text-wrap: nowrap !important;
}

.tw-text-balance {
  text-wrap: balance !important;
}

.tw-text-pretty {
  text-wrap: pretty !important;
}

.tw-break-normal {
  overflow-wrap: normal !important;
  word-break: normal !important;
}

.tw-break-words {
  overflow-wrap: break-word !important;
}

.tw-break-all {
  word-break: break-all !important;
}

.tw-break-keep {
  word-break: keep-all !important;
}

.tw-rounded {
  border-radius: 0.25rem !important;
}

.tw-rounded-2xl {
  border-radius: 1rem !important;
}

.tw-rounded-3xl {
  border-radius: 1.5rem !important;
}

.tw-rounded-full {
  border-radius: 9999px !important;
}

.tw-rounded-lg {
  border-radius: 0.5rem !important;
}

.tw-rounded-md {
  border-radius: 0.375rem !important;
}

.tw-rounded-none {
  border-radius: 0px !important;
}

.tw-rounded-sm {
  border-radius: 0.125rem !important;
}

.tw-rounded-xl {
  border-radius: 0.75rem !important;
}

.tw-rounded-b {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.tw-rounded-b-2xl {
  border-bottom-right-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.tw-rounded-b-3xl {
  border-bottom-right-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.tw-rounded-b-full {
  border-bottom-right-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.tw-rounded-b-lg {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.tw-rounded-b-md {
  border-bottom-right-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.tw-rounded-b-none {
  border-bottom-right-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.tw-rounded-b-sm {
  border-bottom-right-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.tw-rounded-b-xl {
  border-bottom-right-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.tw-rounded-e {
  border-start-end-radius: 0.25rem !important;
  border-end-end-radius: 0.25rem !important;
}

.tw-rounded-e-2xl {
  border-start-end-radius: 1rem !important;
  border-end-end-radius: 1rem !important;
}

.tw-rounded-e-3xl {
  border-start-end-radius: 1.5rem !important;
  border-end-end-radius: 1.5rem !important;
}

.tw-rounded-e-full {
  border-start-end-radius: 9999px !important;
  border-end-end-radius: 9999px !important;
}

.tw-rounded-e-lg {
  border-start-end-radius: 0.5rem !important;
  border-end-end-radius: 0.5rem !important;
}

.tw-rounded-e-md {
  border-start-end-radius: 0.375rem !important;
  border-end-end-radius: 0.375rem !important;
}

.tw-rounded-e-none {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}

.tw-rounded-e-sm {
  border-start-end-radius: 0.125rem !important;
  border-end-end-radius: 0.125rem !important;
}

.tw-rounded-e-xl {
  border-start-end-radius: 0.75rem !important;
  border-end-end-radius: 0.75rem !important;
}

.tw-rounded-l {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.tw-rounded-l-2xl {
  border-top-left-radius: 1rem !important;
  border-bottom-left-radius: 1rem !important;
}

.tw-rounded-l-3xl {
  border-top-left-radius: 1.5rem !important;
  border-bottom-left-radius: 1.5rem !important;
}

.tw-rounded-l-full {
  border-top-left-radius: 9999px !important;
  border-bottom-left-radius: 9999px !important;
}

.tw-rounded-l-lg {
  border-top-left-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.tw-rounded-l-md {
  border-top-left-radius: 0.375rem !important;
  border-bottom-left-radius: 0.375rem !important;
}

.tw-rounded-l-none {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.tw-rounded-l-sm {
  border-top-left-radius: 0.125rem !important;
  border-bottom-left-radius: 0.125rem !important;
}

.tw-rounded-l-xl {
  border-top-left-radius: 0.75rem !important;
  border-bottom-left-radius: 0.75rem !important;
}

.tw-rounded-r {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.tw-rounded-r-2xl {
  border-top-right-radius: 1rem !important;
  border-bottom-right-radius: 1rem !important;
}

.tw-rounded-r-3xl {
  border-top-right-radius: 1.5rem !important;
  border-bottom-right-radius: 1.5rem !important;
}

.tw-rounded-r-full {
  border-top-right-radius: 9999px !important;
  border-bottom-right-radius: 9999px !important;
}

.tw-rounded-r-lg {
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}

.tw-rounded-r-md {
  border-top-right-radius: 0.375rem !important;
  border-bottom-right-radius: 0.375rem !important;
}

.tw-rounded-r-none {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.tw-rounded-r-sm {
  border-top-right-radius: 0.125rem !important;
  border-bottom-right-radius: 0.125rem !important;
}

.tw-rounded-r-xl {
  border-top-right-radius: 0.75rem !important;
  border-bottom-right-radius: 0.75rem !important;
}

.tw-rounded-s {
  border-start-start-radius: 0.25rem !important;
  border-end-start-radius: 0.25rem !important;
}

.tw-rounded-s-2xl {
  border-start-start-radius: 1rem !important;
  border-end-start-radius: 1rem !important;
}

.tw-rounded-s-3xl {
  border-start-start-radius: 1.5rem !important;
  border-end-start-radius: 1.5rem !important;
}

.tw-rounded-s-full {
  border-start-start-radius: 9999px !important;
  border-end-start-radius: 9999px !important;
}

.tw-rounded-s-lg {
  border-start-start-radius: 0.5rem !important;
  border-end-start-radius: 0.5rem !important;
}

.tw-rounded-s-md {
  border-start-start-radius: 0.375rem !important;
  border-end-start-radius: 0.375rem !important;
}

.tw-rounded-s-none {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}

.tw-rounded-s-sm {
  border-start-start-radius: 0.125rem !important;
  border-end-start-radius: 0.125rem !important;
}

.tw-rounded-s-xl {
  border-start-start-radius: 0.75rem !important;
  border-end-start-radius: 0.75rem !important;
}

.tw-rounded-t {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.tw-rounded-t-2xl {
  border-top-left-radius: 1rem !important;
  border-top-right-radius: 1rem !important;
}

.tw-rounded-t-3xl {
  border-top-left-radius: 1.5rem !important;
  border-top-right-radius: 1.5rem !important;
}

.tw-rounded-t-full {
  border-top-left-radius: 9999px !important;
  border-top-right-radius: 9999px !important;
}

.tw-rounded-t-lg {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.tw-rounded-t-md {
  border-top-left-radius: 0.375rem !important;
  border-top-right-radius: 0.375rem !important;
}

.tw-rounded-t-none {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.tw-rounded-t-sm {
  border-top-left-radius: 0.125rem !important;
  border-top-right-radius: 0.125rem !important;
}

.tw-rounded-t-xl {
  border-top-left-radius: 0.75rem !important;
  border-top-right-radius: 0.75rem !important;
}

.tw-rounded-bl {
  border-bottom-left-radius: 0.25rem !important;
}

.tw-rounded-bl-2xl {
  border-bottom-left-radius: 1rem !important;
}

.tw-rounded-bl-3xl {
  border-bottom-left-radius: 1.5rem !important;
}

.tw-rounded-bl-full {
  border-bottom-left-radius: 9999px !important;
}

.tw-rounded-bl-lg {
  border-bottom-left-radius: 0.5rem !important;
}

.tw-rounded-bl-md {
  border-bottom-left-radius: 0.375rem !important;
}

.tw-rounded-bl-none {
  border-bottom-left-radius: 0px !important;
}

.tw-rounded-bl-sm {
  border-bottom-left-radius: 0.125rem !important;
}

.tw-rounded-bl-xl {
  border-bottom-left-radius: 0.75rem !important;
}

.tw-rounded-br {
  border-bottom-right-radius: 0.25rem !important;
}

.tw-rounded-br-2xl {
  border-bottom-right-radius: 1rem !important;
}

.tw-rounded-br-3xl {
  border-bottom-right-radius: 1.5rem !important;
}

.tw-rounded-br-full {
  border-bottom-right-radius: 9999px !important;
}

.tw-rounded-br-lg {
  border-bottom-right-radius: 0.5rem !important;
}

.tw-rounded-br-md {
  border-bottom-right-radius: 0.375rem !important;
}

.tw-rounded-br-none {
  border-bottom-right-radius: 0px !important;
}

.tw-rounded-br-sm {
  border-bottom-right-radius: 0.125rem !important;
}

.tw-rounded-br-xl {
  border-bottom-right-radius: 0.75rem !important;
}

.tw-rounded-ee {
  border-end-end-radius: 0.25rem !important;
}

.tw-rounded-ee-2xl {
  border-end-end-radius: 1rem !important;
}

.tw-rounded-ee-3xl {
  border-end-end-radius: 1.5rem !important;
}

.tw-rounded-ee-full {
  border-end-end-radius: 9999px !important;
}

.tw-rounded-ee-lg {
  border-end-end-radius: 0.5rem !important;
}

.tw-rounded-ee-md {
  border-end-end-radius: 0.375rem !important;
}

.tw-rounded-ee-none {
  border-end-end-radius: 0px !important;
}

.tw-rounded-ee-sm {
  border-end-end-radius: 0.125rem !important;
}

.tw-rounded-ee-xl {
  border-end-end-radius: 0.75rem !important;
}

.tw-rounded-es {
  border-end-start-radius: 0.25rem !important;
}

.tw-rounded-es-2xl {
  border-end-start-radius: 1rem !important;
}

.tw-rounded-es-3xl {
  border-end-start-radius: 1.5rem !important;
}

.tw-rounded-es-full {
  border-end-start-radius: 9999px !important;
}

.tw-rounded-es-lg {
  border-end-start-radius: 0.5rem !important;
}

.tw-rounded-es-md {
  border-end-start-radius: 0.375rem !important;
}

.tw-rounded-es-none {
  border-end-start-radius: 0px !important;
}

.tw-rounded-es-sm {
  border-end-start-radius: 0.125rem !important;
}

.tw-rounded-es-xl {
  border-end-start-radius: 0.75rem !important;
}

.tw-rounded-se {
  border-start-end-radius: 0.25rem !important;
}

.tw-rounded-se-2xl {
  border-start-end-radius: 1rem !important;
}

.tw-rounded-se-3xl {
  border-start-end-radius: 1.5rem !important;
}

.tw-rounded-se-full {
  border-start-end-radius: 9999px !important;
}

.tw-rounded-se-lg {
  border-start-end-radius: 0.5rem !important;
}

.tw-rounded-se-md {
  border-start-end-radius: 0.375rem !important;
}

.tw-rounded-se-none {
  border-start-end-radius: 0px !important;
}

.tw-rounded-se-sm {
  border-start-end-radius: 0.125rem !important;
}

.tw-rounded-se-xl {
  border-start-end-radius: 0.75rem !important;
}

.tw-rounded-ss {
  border-start-start-radius: 0.25rem !important;
}

.tw-rounded-ss-2xl {
  border-start-start-radius: 1rem !important;
}

.tw-rounded-ss-3xl {
  border-start-start-radius: 1.5rem !important;
}

.tw-rounded-ss-full {
  border-start-start-radius: 9999px !important;
}

.tw-rounded-ss-lg {
  border-start-start-radius: 0.5rem !important;
}

.tw-rounded-ss-md {
  border-start-start-radius: 0.375rem !important;
}

.tw-rounded-ss-none {
  border-start-start-radius: 0px !important;
}

.tw-rounded-ss-sm {
  border-start-start-radius: 0.125rem !important;
}

.tw-rounded-ss-xl {
  border-start-start-radius: 0.75rem !important;
}

.tw-rounded-tl {
  border-top-left-radius: 0.25rem !important;
}

.tw-rounded-tl-2xl {
  border-top-left-radius: 1rem !important;
}

.tw-rounded-tl-3xl {
  border-top-left-radius: 1.5rem !important;
}

.tw-rounded-tl-full {
  border-top-left-radius: 9999px !important;
}

.tw-rounded-tl-lg {
  border-top-left-radius: 0.5rem !important;
}

.tw-rounded-tl-md {
  border-top-left-radius: 0.375rem !important;
}

.tw-rounded-tl-none {
  border-top-left-radius: 0px !important;
}

.tw-rounded-tl-sm {
  border-top-left-radius: 0.125rem !important;
}

.tw-rounded-tl-xl {
  border-top-left-radius: 0.75rem !important;
}

.tw-rounded-tr {
  border-top-right-radius: 0.25rem !important;
}

.tw-rounded-tr-2xl {
  border-top-right-radius: 1rem !important;
}

.tw-rounded-tr-3xl {
  border-top-right-radius: 1.5rem !important;
}

.tw-rounded-tr-full {
  border-top-right-radius: 9999px !important;
}

.tw-rounded-tr-lg {
  border-top-right-radius: 0.5rem !important;
}

.tw-rounded-tr-md {
  border-top-right-radius: 0.375rem !important;
}

.tw-rounded-tr-none {
  border-top-right-radius: 0px !important;
}

.tw-rounded-tr-sm {
  border-top-right-radius: 0.125rem !important;
}

.tw-rounded-tr-xl {
  border-top-right-radius: 0.75rem !important;
}

.tw-border {
  border-width: 1px !important;
}

.tw-border-0 {
  border-width: 0px !important;
}

.tw-border-2 {
  border-width: 2px !important;
}

.tw-border-4 {
  border-width: 4px !important;
}

.tw-border-8 {
  border-width: 8px !important;
}

.tw-border-x {
  border-left-width: 1px !important;
  border-right-width: 1px !important;
}

.tw-border-x-0 {
  border-left-width: 0px !important;
  border-right-width: 0px !important;
}

.tw-border-x-2 {
  border-left-width: 2px !important;
  border-right-width: 2px !important;
}

.tw-border-x-4 {
  border-left-width: 4px !important;
  border-right-width: 4px !important;
}

.tw-border-x-8 {
  border-left-width: 8px !important;
  border-right-width: 8px !important;
}

.tw-border-y {
  border-top-width: 1px !important;
  border-bottom-width: 1px !important;
}

.tw-border-y-0 {
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
}

.tw-border-y-2 {
  border-top-width: 2px !important;
  border-bottom-width: 2px !important;
}

.tw-border-y-4 {
  border-top-width: 4px !important;
  border-bottom-width: 4px !important;
}

.tw-border-y-8 {
  border-top-width: 8px !important;
  border-bottom-width: 8px !important;
}

.tw-border-b {
  border-bottom-width: 1px !important;
}

.tw-border-b-0 {
  border-bottom-width: 0px !important;
}

.tw-border-b-2 {
  border-bottom-width: 2px !important;
}

.tw-border-b-4 {
  border-bottom-width: 4px !important;
}

.tw-border-b-8 {
  border-bottom-width: 8px !important;
}

.tw-border-e {
  border-inline-end-width: 1px !important;
}

.tw-border-e-0 {
  border-inline-end-width: 0px !important;
}

.tw-border-e-2 {
  border-inline-end-width: 2px !important;
}

.tw-border-e-4 {
  border-inline-end-width: 4px !important;
}

.tw-border-e-8 {
  border-inline-end-width: 8px !important;
}

.tw-border-l {
  border-left-width: 1px !important;
}

.tw-border-l-0 {
  border-left-width: 0px !important;
}

.tw-border-l-2 {
  border-left-width: 2px !important;
}

.tw-border-l-4 {
  border-left-width: 4px !important;
}

.tw-border-l-8 {
  border-left-width: 8px !important;
}

.tw-border-r {
  border-right-width: 1px !important;
}

.tw-border-r-0 {
  border-right-width: 0px !important;
}

.tw-border-r-2 {
  border-right-width: 2px !important;
}

.tw-border-r-4 {
  border-right-width: 4px !important;
}

.tw-border-r-8 {
  border-right-width: 8px !important;
}

.tw-border-s {
  border-inline-start-width: 1px !important;
}

.tw-border-s-0 {
  border-inline-start-width: 0px !important;
}

.tw-border-s-2 {
  border-inline-start-width: 2px !important;
}

.tw-border-s-4 {
  border-inline-start-width: 4px !important;
}

.tw-border-s-8 {
  border-inline-start-width: 8px !important;
}

.tw-border-t {
  border-top-width: 1px !important;
}

.tw-border-t-0 {
  border-top-width: 0px !important;
}

.tw-border-t-2 {
  border-top-width: 2px !important;
}

.tw-border-t-4 {
  border-top-width: 4px !important;
}

.tw-border-t-8 {
  border-top-width: 8px !important;
}

.tw-border-solid {
  border-style: solid !important;
}

.tw-border-dashed {
  border-style: dashed !important;
}

.tw-border-dotted {
  border-style: dotted !important;
}

.tw-border-double {
  border-style: double !important;
}

.tw-border-hidden {
  border-style: hidden !important;
}

.tw-border-none {
  border-style: none !important;
}

.tw-border-amber-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-100\/0 {
  border-color: rgb(254 243 199 / 0) !important;
}

.tw-border-amber-100\/10 {
  border-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-amber-100\/100 {
  border-color: rgb(254 243 199 / 1) !important;
}

.tw-border-amber-100\/15 {
  border-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-amber-100\/20 {
  border-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-amber-100\/25 {
  border-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-amber-100\/30 {
  border-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-amber-100\/35 {
  border-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-amber-100\/40 {
  border-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-amber-100\/45 {
  border-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-amber-100\/5 {
  border-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-amber-100\/50 {
  border-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-amber-100\/55 {
  border-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-amber-100\/60 {
  border-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-amber-100\/65 {
  border-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-amber-100\/70 {
  border-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-amber-100\/75 {
  border-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-amber-100\/80 {
  border-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-amber-100\/85 {
  border-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-amber-100\/90 {
  border-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-amber-100\/95 {
  border-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-amber-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-200\/0 {
  border-color: rgb(253 230 138 / 0) !important;
}

.tw-border-amber-200\/10 {
  border-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-amber-200\/100 {
  border-color: rgb(253 230 138 / 1) !important;
}

.tw-border-amber-200\/15 {
  border-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-amber-200\/20 {
  border-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-amber-200\/25 {
  border-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-amber-200\/30 {
  border-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-amber-200\/35 {
  border-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-amber-200\/40 {
  border-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-amber-200\/45 {
  border-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-amber-200\/5 {
  border-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-amber-200\/50 {
  border-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-amber-200\/55 {
  border-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-amber-200\/60 {
  border-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-amber-200\/65 {
  border-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-amber-200\/70 {
  border-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-amber-200\/75 {
  border-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-amber-200\/80 {
  border-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-amber-200\/85 {
  border-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-amber-200\/90 {
  border-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-amber-200\/95 {
  border-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-amber-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-300\/0 {
  border-color: rgb(252 211 77 / 0) !important;
}

.tw-border-amber-300\/10 {
  border-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-amber-300\/100 {
  border-color: rgb(252 211 77 / 1) !important;
}

.tw-border-amber-300\/15 {
  border-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-amber-300\/20 {
  border-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-amber-300\/25 {
  border-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-amber-300\/30 {
  border-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-amber-300\/35 {
  border-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-amber-300\/40 {
  border-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-amber-300\/45 {
  border-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-amber-300\/5 {
  border-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-amber-300\/50 {
  border-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-amber-300\/55 {
  border-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-amber-300\/60 {
  border-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-amber-300\/65 {
  border-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-amber-300\/70 {
  border-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-amber-300\/75 {
  border-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-amber-300\/80 {
  border-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-amber-300\/85 {
  border-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-amber-300\/90 {
  border-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-amber-300\/95 {
  border-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-amber-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-400\/0 {
  border-color: rgb(251 191 36 / 0) !important;
}

.tw-border-amber-400\/10 {
  border-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-amber-400\/100 {
  border-color: rgb(251 191 36 / 1) !important;
}

.tw-border-amber-400\/15 {
  border-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-amber-400\/20 {
  border-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-amber-400\/25 {
  border-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-amber-400\/30 {
  border-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-amber-400\/35 {
  border-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-amber-400\/40 {
  border-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-amber-400\/45 {
  border-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-amber-400\/5 {
  border-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-amber-400\/50 {
  border-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-amber-400\/55 {
  border-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-amber-400\/60 {
  border-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-amber-400\/65 {
  border-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-amber-400\/70 {
  border-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-amber-400\/75 {
  border-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-amber-400\/80 {
  border-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-amber-400\/85 {
  border-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-amber-400\/90 {
  border-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-amber-400\/95 {
  border-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-amber-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-50\/0 {
  border-color: rgb(255 251 235 / 0) !important;
}

.tw-border-amber-50\/10 {
  border-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-amber-50\/100 {
  border-color: rgb(255 251 235 / 1) !important;
}

.tw-border-amber-50\/15 {
  border-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-amber-50\/20 {
  border-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-amber-50\/25 {
  border-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-amber-50\/30 {
  border-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-amber-50\/35 {
  border-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-amber-50\/40 {
  border-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-amber-50\/45 {
  border-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-amber-50\/5 {
  border-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-amber-50\/50 {
  border-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-amber-50\/55 {
  border-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-amber-50\/60 {
  border-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-amber-50\/65 {
  border-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-amber-50\/70 {
  border-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-amber-50\/75 {
  border-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-amber-50\/80 {
  border-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-amber-50\/85 {
  border-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-amber-50\/90 {
  border-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-amber-50\/95 {
  border-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-amber-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-500\/0 {
  border-color: rgb(245 158 11 / 0) !important;
}

.tw-border-amber-500\/10 {
  border-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-amber-500\/100 {
  border-color: rgb(245 158 11 / 1) !important;
}

.tw-border-amber-500\/15 {
  border-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-amber-500\/20 {
  border-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-amber-500\/25 {
  border-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-amber-500\/30 {
  border-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-amber-500\/35 {
  border-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-amber-500\/40 {
  border-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-amber-500\/45 {
  border-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-amber-500\/5 {
  border-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-amber-500\/50 {
  border-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-amber-500\/55 {
  border-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-amber-500\/60 {
  border-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-amber-500\/65 {
  border-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-amber-500\/70 {
  border-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-amber-500\/75 {
  border-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-amber-500\/80 {
  border-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-amber-500\/85 {
  border-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-amber-500\/90 {
  border-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-amber-500\/95 {
  border-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-amber-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-600\/0 {
  border-color: rgb(217 119 6 / 0) !important;
}

.tw-border-amber-600\/10 {
  border-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-amber-600\/100 {
  border-color: rgb(217 119 6 / 1) !important;
}

.tw-border-amber-600\/15 {
  border-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-amber-600\/20 {
  border-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-amber-600\/25 {
  border-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-amber-600\/30 {
  border-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-amber-600\/35 {
  border-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-amber-600\/40 {
  border-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-amber-600\/45 {
  border-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-amber-600\/5 {
  border-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-amber-600\/50 {
  border-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-amber-600\/55 {
  border-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-amber-600\/60 {
  border-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-amber-600\/65 {
  border-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-amber-600\/70 {
  border-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-amber-600\/75 {
  border-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-amber-600\/80 {
  border-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-amber-600\/85 {
  border-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-amber-600\/90 {
  border-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-amber-600\/95 {
  border-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-amber-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-700\/0 {
  border-color: rgb(180 83 9 / 0) !important;
}

.tw-border-amber-700\/10 {
  border-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-amber-700\/100 {
  border-color: rgb(180 83 9 / 1) !important;
}

.tw-border-amber-700\/15 {
  border-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-amber-700\/20 {
  border-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-amber-700\/25 {
  border-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-amber-700\/30 {
  border-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-amber-700\/35 {
  border-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-amber-700\/40 {
  border-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-amber-700\/45 {
  border-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-amber-700\/5 {
  border-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-amber-700\/50 {
  border-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-amber-700\/55 {
  border-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-amber-700\/60 {
  border-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-amber-700\/65 {
  border-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-amber-700\/70 {
  border-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-amber-700\/75 {
  border-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-amber-700\/80 {
  border-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-amber-700\/85 {
  border-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-amber-700\/90 {
  border-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-amber-700\/95 {
  border-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-amber-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-800\/0 {
  border-color: rgb(146 64 14 / 0) !important;
}

.tw-border-amber-800\/10 {
  border-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-amber-800\/100 {
  border-color: rgb(146 64 14 / 1) !important;
}

.tw-border-amber-800\/15 {
  border-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-amber-800\/20 {
  border-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-amber-800\/25 {
  border-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-amber-800\/30 {
  border-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-amber-800\/35 {
  border-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-amber-800\/40 {
  border-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-amber-800\/45 {
  border-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-amber-800\/5 {
  border-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-amber-800\/50 {
  border-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-amber-800\/55 {
  border-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-amber-800\/60 {
  border-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-amber-800\/65 {
  border-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-amber-800\/70 {
  border-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-amber-800\/75 {
  border-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-amber-800\/80 {
  border-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-amber-800\/85 {
  border-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-amber-800\/90 {
  border-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-amber-800\/95 {
  border-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-amber-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-900\/0 {
  border-color: rgb(120 53 15 / 0) !important;
}

.tw-border-amber-900\/10 {
  border-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-amber-900\/100 {
  border-color: rgb(120 53 15 / 1) !important;
}

.tw-border-amber-900\/15 {
  border-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-amber-900\/20 {
  border-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-amber-900\/25 {
  border-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-amber-900\/30 {
  border-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-amber-900\/35 {
  border-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-amber-900\/40 {
  border-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-amber-900\/45 {
  border-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-amber-900\/5 {
  border-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-amber-900\/50 {
  border-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-amber-900\/55 {
  border-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-amber-900\/60 {
  border-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-amber-900\/65 {
  border-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-amber-900\/70 {
  border-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-amber-900\/75 {
  border-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-amber-900\/80 {
  border-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-amber-900\/85 {
  border-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-amber-900\/90 {
  border-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-amber-900\/95 {
  border-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-amber-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-amber-950\/0 {
  border-color: rgb(69 26 3 / 0) !important;
}

.tw-border-amber-950\/10 {
  border-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-amber-950\/100 {
  border-color: rgb(69 26 3 / 1) !important;
}

.tw-border-amber-950\/15 {
  border-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-amber-950\/20 {
  border-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-amber-950\/25 {
  border-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-amber-950\/30 {
  border-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-amber-950\/35 {
  border-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-amber-950\/40 {
  border-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-amber-950\/45 {
  border-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-amber-950\/5 {
  border-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-amber-950\/50 {
  border-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-amber-950\/55 {
  border-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-amber-950\/60 {
  border-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-amber-950\/65 {
  border-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-amber-950\/70 {
  border-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-amber-950\/75 {
  border-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-amber-950\/80 {
  border-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-amber-950\/85 {
  border-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-amber-950\/90 {
  border-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-amber-950\/95 {
  border-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-black {
  --tw-border-opacity: 1 !important;
  border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-black\/0 {
  border-color: rgb(0 0 0 / 0) !important;
}

.tw-border-black\/10 {
  border-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-black\/100 {
  border-color: rgb(0 0 0 / 1) !important;
}

.tw-border-black\/15 {
  border-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-black\/20 {
  border-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-black\/25 {
  border-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-black\/30 {
  border-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-black\/35 {
  border-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-black\/40 {
  border-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-black\/45 {
  border-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-black\/5 {
  border-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-black\/50 {
  border-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-black\/55 {
  border-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-black\/60 {
  border-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-black\/65 {
  border-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-black\/70 {
  border-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-black\/75 {
  border-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-black\/80 {
  border-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-black\/85 {
  border-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-black\/90 {
  border-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-black\/95 {
  border-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-blue-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-100\/0 {
  border-color: rgb(219 234 254 / 0) !important;
}

.tw-border-blue-100\/10 {
  border-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-blue-100\/100 {
  border-color: rgb(219 234 254 / 1) !important;
}

.tw-border-blue-100\/15 {
  border-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-blue-100\/20 {
  border-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-blue-100\/25 {
  border-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-blue-100\/30 {
  border-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-blue-100\/35 {
  border-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-blue-100\/40 {
  border-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-blue-100\/45 {
  border-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-blue-100\/5 {
  border-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-blue-100\/50 {
  border-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-blue-100\/55 {
  border-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-blue-100\/60 {
  border-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-blue-100\/65 {
  border-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-blue-100\/70 {
  border-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-blue-100\/75 {
  border-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-blue-100\/80 {
  border-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-blue-100\/85 {
  border-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-blue-100\/90 {
  border-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-blue-100\/95 {
  border-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-blue-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-200\/0 {
  border-color: rgb(191 219 254 / 0) !important;
}

.tw-border-blue-200\/10 {
  border-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-blue-200\/100 {
  border-color: rgb(191 219 254 / 1) !important;
}

.tw-border-blue-200\/15 {
  border-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-blue-200\/20 {
  border-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-blue-200\/25 {
  border-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-blue-200\/30 {
  border-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-blue-200\/35 {
  border-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-blue-200\/40 {
  border-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-blue-200\/45 {
  border-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-blue-200\/5 {
  border-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-blue-200\/50 {
  border-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-blue-200\/55 {
  border-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-blue-200\/60 {
  border-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-blue-200\/65 {
  border-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-blue-200\/70 {
  border-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-blue-200\/75 {
  border-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-blue-200\/80 {
  border-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-blue-200\/85 {
  border-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-blue-200\/90 {
  border-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-blue-200\/95 {
  border-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-blue-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-300\/0 {
  border-color: rgb(147 197 253 / 0) !important;
}

.tw-border-blue-300\/10 {
  border-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-blue-300\/100 {
  border-color: rgb(147 197 253 / 1) !important;
}

.tw-border-blue-300\/15 {
  border-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-blue-300\/20 {
  border-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-blue-300\/25 {
  border-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-blue-300\/30 {
  border-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-blue-300\/35 {
  border-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-blue-300\/40 {
  border-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-blue-300\/45 {
  border-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-blue-300\/5 {
  border-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-blue-300\/50 {
  border-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-blue-300\/55 {
  border-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-blue-300\/60 {
  border-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-blue-300\/65 {
  border-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-blue-300\/70 {
  border-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-blue-300\/75 {
  border-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-blue-300\/80 {
  border-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-blue-300\/85 {
  border-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-blue-300\/90 {
  border-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-blue-300\/95 {
  border-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-blue-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-400\/0 {
  border-color: rgb(96 165 250 / 0) !important;
}

.tw-border-blue-400\/10 {
  border-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-blue-400\/100 {
  border-color: rgb(96 165 250 / 1) !important;
}

.tw-border-blue-400\/15 {
  border-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-blue-400\/20 {
  border-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-blue-400\/25 {
  border-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-blue-400\/30 {
  border-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-blue-400\/35 {
  border-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-blue-400\/40 {
  border-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-blue-400\/45 {
  border-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-blue-400\/5 {
  border-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-blue-400\/50 {
  border-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-blue-400\/55 {
  border-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-blue-400\/60 {
  border-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-blue-400\/65 {
  border-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-blue-400\/70 {
  border-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-blue-400\/75 {
  border-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-blue-400\/80 {
  border-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-blue-400\/85 {
  border-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-blue-400\/90 {
  border-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-blue-400\/95 {
  border-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-blue-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-50\/0 {
  border-color: rgb(239 246 255 / 0) !important;
}

.tw-border-blue-50\/10 {
  border-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-blue-50\/100 {
  border-color: rgb(239 246 255 / 1) !important;
}

.tw-border-blue-50\/15 {
  border-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-blue-50\/20 {
  border-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-blue-50\/25 {
  border-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-blue-50\/30 {
  border-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-blue-50\/35 {
  border-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-blue-50\/40 {
  border-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-blue-50\/45 {
  border-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-blue-50\/5 {
  border-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-blue-50\/50 {
  border-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-blue-50\/55 {
  border-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-blue-50\/60 {
  border-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-blue-50\/65 {
  border-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-blue-50\/70 {
  border-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-blue-50\/75 {
  border-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-blue-50\/80 {
  border-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-blue-50\/85 {
  border-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-blue-50\/90 {
  border-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-blue-50\/95 {
  border-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-blue-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-500\/0 {
  border-color: rgb(59 130 246 / 0) !important;
}

.tw-border-blue-500\/10 {
  border-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-blue-500\/100 {
  border-color: rgb(59 130 246 / 1) !important;
}

.tw-border-blue-500\/15 {
  border-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-blue-500\/20 {
  border-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-blue-500\/25 {
  border-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-blue-500\/30 {
  border-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-blue-500\/35 {
  border-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-blue-500\/40 {
  border-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-blue-500\/45 {
  border-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-blue-500\/5 {
  border-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-blue-500\/50 {
  border-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-blue-500\/55 {
  border-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-blue-500\/60 {
  border-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-blue-500\/65 {
  border-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-blue-500\/70 {
  border-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-blue-500\/75 {
  border-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-blue-500\/80 {
  border-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-blue-500\/85 {
  border-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-blue-500\/90 {
  border-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-blue-500\/95 {
  border-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-blue-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-600\/0 {
  border-color: rgb(37 99 235 / 0) !important;
}

.tw-border-blue-600\/10 {
  border-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-blue-600\/100 {
  border-color: rgb(37 99 235 / 1) !important;
}

.tw-border-blue-600\/15 {
  border-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-blue-600\/20 {
  border-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-blue-600\/25 {
  border-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-blue-600\/30 {
  border-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-blue-600\/35 {
  border-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-blue-600\/40 {
  border-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-blue-600\/45 {
  border-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-blue-600\/5 {
  border-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-blue-600\/50 {
  border-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-blue-600\/55 {
  border-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-blue-600\/60 {
  border-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-blue-600\/65 {
  border-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-blue-600\/70 {
  border-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-blue-600\/75 {
  border-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-blue-600\/80 {
  border-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-blue-600\/85 {
  border-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-blue-600\/90 {
  border-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-blue-600\/95 {
  border-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-blue-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-700\/0 {
  border-color: rgb(29 78 216 / 0) !important;
}

.tw-border-blue-700\/10 {
  border-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-blue-700\/100 {
  border-color: rgb(29 78 216 / 1) !important;
}

.tw-border-blue-700\/15 {
  border-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-blue-700\/20 {
  border-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-blue-700\/25 {
  border-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-blue-700\/30 {
  border-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-blue-700\/35 {
  border-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-blue-700\/40 {
  border-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-blue-700\/45 {
  border-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-blue-700\/5 {
  border-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-blue-700\/50 {
  border-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-blue-700\/55 {
  border-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-blue-700\/60 {
  border-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-blue-700\/65 {
  border-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-blue-700\/70 {
  border-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-blue-700\/75 {
  border-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-blue-700\/80 {
  border-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-blue-700\/85 {
  border-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-blue-700\/90 {
  border-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-blue-700\/95 {
  border-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-blue-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-800\/0 {
  border-color: rgb(30 64 175 / 0) !important;
}

.tw-border-blue-800\/10 {
  border-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-blue-800\/100 {
  border-color: rgb(30 64 175 / 1) !important;
}

.tw-border-blue-800\/15 {
  border-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-blue-800\/20 {
  border-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-blue-800\/25 {
  border-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-blue-800\/30 {
  border-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-blue-800\/35 {
  border-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-blue-800\/40 {
  border-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-blue-800\/45 {
  border-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-blue-800\/5 {
  border-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-blue-800\/50 {
  border-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-blue-800\/55 {
  border-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-blue-800\/60 {
  border-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-blue-800\/65 {
  border-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-blue-800\/70 {
  border-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-blue-800\/75 {
  border-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-blue-800\/80 {
  border-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-blue-800\/85 {
  border-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-blue-800\/90 {
  border-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-blue-800\/95 {
  border-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-blue-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-900\/0 {
  border-color: rgb(30 58 138 / 0) !important;
}

.tw-border-blue-900\/10 {
  border-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-blue-900\/100 {
  border-color: rgb(30 58 138 / 1) !important;
}

.tw-border-blue-900\/15 {
  border-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-blue-900\/20 {
  border-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-blue-900\/25 {
  border-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-blue-900\/30 {
  border-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-blue-900\/35 {
  border-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-blue-900\/40 {
  border-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-blue-900\/45 {
  border-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-blue-900\/5 {
  border-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-blue-900\/50 {
  border-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-blue-900\/55 {
  border-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-blue-900\/60 {
  border-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-blue-900\/65 {
  border-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-blue-900\/70 {
  border-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-blue-900\/75 {
  border-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-blue-900\/80 {
  border-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-blue-900\/85 {
  border-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-blue-900\/90 {
  border-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-blue-900\/95 {
  border-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-blue-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-blue-950\/0 {
  border-color: rgb(23 37 84 / 0) !important;
}

.tw-border-blue-950\/10 {
  border-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-blue-950\/100 {
  border-color: rgb(23 37 84 / 1) !important;
}

.tw-border-blue-950\/15 {
  border-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-blue-950\/20 {
  border-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-blue-950\/25 {
  border-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-blue-950\/30 {
  border-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-blue-950\/35 {
  border-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-blue-950\/40 {
  border-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-blue-950\/45 {
  border-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-blue-950\/5 {
  border-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-blue-950\/50 {
  border-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-blue-950\/55 {
  border-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-blue-950\/60 {
  border-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-blue-950\/65 {
  border-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-blue-950\/70 {
  border-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-blue-950\/75 {
  border-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-blue-950\/80 {
  border-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-blue-950\/85 {
  border-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-blue-950\/90 {
  border-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-blue-950\/95 {
  border-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-current {
  border-color: currentColor !important;
}

.tw-border-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-100\/0 {
  border-color: rgb(207 250 254 / 0) !important;
}

.tw-border-cyan-100\/10 {
  border-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-cyan-100\/100 {
  border-color: rgb(207 250 254 / 1) !important;
}

.tw-border-cyan-100\/15 {
  border-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-cyan-100\/20 {
  border-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-cyan-100\/25 {
  border-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-cyan-100\/30 {
  border-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-cyan-100\/35 {
  border-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-cyan-100\/40 {
  border-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-cyan-100\/45 {
  border-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-cyan-100\/5 {
  border-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-cyan-100\/50 {
  border-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-cyan-100\/55 {
  border-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-cyan-100\/60 {
  border-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-cyan-100\/65 {
  border-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-cyan-100\/70 {
  border-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-cyan-100\/75 {
  border-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-cyan-100\/80 {
  border-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-cyan-100\/85 {
  border-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-cyan-100\/90 {
  border-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-cyan-100\/95 {
  border-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-200\/0 {
  border-color: rgb(165 243 252 / 0) !important;
}

.tw-border-cyan-200\/10 {
  border-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-cyan-200\/100 {
  border-color: rgb(165 243 252 / 1) !important;
}

.tw-border-cyan-200\/15 {
  border-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-cyan-200\/20 {
  border-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-cyan-200\/25 {
  border-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-cyan-200\/30 {
  border-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-cyan-200\/35 {
  border-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-cyan-200\/40 {
  border-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-cyan-200\/45 {
  border-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-cyan-200\/5 {
  border-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-cyan-200\/50 {
  border-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-cyan-200\/55 {
  border-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-cyan-200\/60 {
  border-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-cyan-200\/65 {
  border-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-cyan-200\/70 {
  border-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-cyan-200\/75 {
  border-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-cyan-200\/80 {
  border-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-cyan-200\/85 {
  border-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-cyan-200\/90 {
  border-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-cyan-200\/95 {
  border-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-300\/0 {
  border-color: rgb(103 232 249 / 0) !important;
}

.tw-border-cyan-300\/10 {
  border-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-cyan-300\/100 {
  border-color: rgb(103 232 249 / 1) !important;
}

.tw-border-cyan-300\/15 {
  border-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-cyan-300\/20 {
  border-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-cyan-300\/25 {
  border-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-cyan-300\/30 {
  border-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-cyan-300\/35 {
  border-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-cyan-300\/40 {
  border-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-cyan-300\/45 {
  border-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-cyan-300\/5 {
  border-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-cyan-300\/50 {
  border-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-cyan-300\/55 {
  border-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-cyan-300\/60 {
  border-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-cyan-300\/65 {
  border-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-cyan-300\/70 {
  border-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-cyan-300\/75 {
  border-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-cyan-300\/80 {
  border-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-cyan-300\/85 {
  border-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-cyan-300\/90 {
  border-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-cyan-300\/95 {
  border-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-400\/0 {
  border-color: rgb(34 211 238 / 0) !important;
}

.tw-border-cyan-400\/10 {
  border-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-cyan-400\/100 {
  border-color: rgb(34 211 238 / 1) !important;
}

.tw-border-cyan-400\/15 {
  border-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-cyan-400\/20 {
  border-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-cyan-400\/25 {
  border-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-cyan-400\/30 {
  border-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-cyan-400\/35 {
  border-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-cyan-400\/40 {
  border-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-cyan-400\/45 {
  border-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-cyan-400\/5 {
  border-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-cyan-400\/50 {
  border-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-cyan-400\/55 {
  border-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-cyan-400\/60 {
  border-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-cyan-400\/65 {
  border-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-cyan-400\/70 {
  border-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-cyan-400\/75 {
  border-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-cyan-400\/80 {
  border-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-cyan-400\/85 {
  border-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-cyan-400\/90 {
  border-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-cyan-400\/95 {
  border-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-50\/0 {
  border-color: rgb(236 254 255 / 0) !important;
}

.tw-border-cyan-50\/10 {
  border-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-cyan-50\/100 {
  border-color: rgb(236 254 255 / 1) !important;
}

.tw-border-cyan-50\/15 {
  border-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-cyan-50\/20 {
  border-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-cyan-50\/25 {
  border-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-cyan-50\/30 {
  border-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-cyan-50\/35 {
  border-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-cyan-50\/40 {
  border-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-cyan-50\/45 {
  border-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-cyan-50\/5 {
  border-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-cyan-50\/50 {
  border-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-cyan-50\/55 {
  border-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-cyan-50\/60 {
  border-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-cyan-50\/65 {
  border-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-cyan-50\/70 {
  border-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-cyan-50\/75 {
  border-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-cyan-50\/80 {
  border-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-cyan-50\/85 {
  border-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-cyan-50\/90 {
  border-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-cyan-50\/95 {
  border-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-500\/0 {
  border-color: rgb(6 182 212 / 0) !important;
}

.tw-border-cyan-500\/10 {
  border-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-cyan-500\/100 {
  border-color: rgb(6 182 212 / 1) !important;
}

.tw-border-cyan-500\/15 {
  border-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-cyan-500\/20 {
  border-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-cyan-500\/25 {
  border-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-cyan-500\/30 {
  border-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-cyan-500\/35 {
  border-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-cyan-500\/40 {
  border-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-cyan-500\/45 {
  border-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-cyan-500\/5 {
  border-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-cyan-500\/50 {
  border-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-cyan-500\/55 {
  border-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-cyan-500\/60 {
  border-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-cyan-500\/65 {
  border-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-cyan-500\/70 {
  border-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-cyan-500\/75 {
  border-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-cyan-500\/80 {
  border-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-cyan-500\/85 {
  border-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-cyan-500\/90 {
  border-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-cyan-500\/95 {
  border-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-600\/0 {
  border-color: rgb(8 145 178 / 0) !important;
}

.tw-border-cyan-600\/10 {
  border-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-cyan-600\/100 {
  border-color: rgb(8 145 178 / 1) !important;
}

.tw-border-cyan-600\/15 {
  border-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-cyan-600\/20 {
  border-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-cyan-600\/25 {
  border-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-cyan-600\/30 {
  border-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-cyan-600\/35 {
  border-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-cyan-600\/40 {
  border-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-cyan-600\/45 {
  border-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-cyan-600\/5 {
  border-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-cyan-600\/50 {
  border-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-cyan-600\/55 {
  border-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-cyan-600\/60 {
  border-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-cyan-600\/65 {
  border-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-cyan-600\/70 {
  border-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-cyan-600\/75 {
  border-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-cyan-600\/80 {
  border-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-cyan-600\/85 {
  border-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-cyan-600\/90 {
  border-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-cyan-600\/95 {
  border-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-700\/0 {
  border-color: rgb(14 116 144 / 0) !important;
}

.tw-border-cyan-700\/10 {
  border-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-cyan-700\/100 {
  border-color: rgb(14 116 144 / 1) !important;
}

.tw-border-cyan-700\/15 {
  border-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-cyan-700\/20 {
  border-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-cyan-700\/25 {
  border-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-cyan-700\/30 {
  border-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-cyan-700\/35 {
  border-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-cyan-700\/40 {
  border-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-cyan-700\/45 {
  border-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-cyan-700\/5 {
  border-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-cyan-700\/50 {
  border-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-cyan-700\/55 {
  border-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-cyan-700\/60 {
  border-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-cyan-700\/65 {
  border-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-cyan-700\/70 {
  border-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-cyan-700\/75 {
  border-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-cyan-700\/80 {
  border-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-cyan-700\/85 {
  border-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-cyan-700\/90 {
  border-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-cyan-700\/95 {
  border-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-800\/0 {
  border-color: rgb(21 94 117 / 0) !important;
}

.tw-border-cyan-800\/10 {
  border-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-cyan-800\/100 {
  border-color: rgb(21 94 117 / 1) !important;
}

.tw-border-cyan-800\/15 {
  border-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-cyan-800\/20 {
  border-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-cyan-800\/25 {
  border-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-cyan-800\/30 {
  border-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-cyan-800\/35 {
  border-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-cyan-800\/40 {
  border-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-cyan-800\/45 {
  border-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-cyan-800\/5 {
  border-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-cyan-800\/50 {
  border-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-cyan-800\/55 {
  border-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-cyan-800\/60 {
  border-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-cyan-800\/65 {
  border-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-cyan-800\/70 {
  border-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-cyan-800\/75 {
  border-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-cyan-800\/80 {
  border-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-cyan-800\/85 {
  border-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-cyan-800\/90 {
  border-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-cyan-800\/95 {
  border-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-900\/0 {
  border-color: rgb(22 78 99 / 0) !important;
}

.tw-border-cyan-900\/10 {
  border-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-cyan-900\/100 {
  border-color: rgb(22 78 99 / 1) !important;
}

.tw-border-cyan-900\/15 {
  border-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-cyan-900\/20 {
  border-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-cyan-900\/25 {
  border-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-cyan-900\/30 {
  border-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-cyan-900\/35 {
  border-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-cyan-900\/40 {
  border-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-cyan-900\/45 {
  border-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-cyan-900\/5 {
  border-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-cyan-900\/50 {
  border-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-cyan-900\/55 {
  border-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-cyan-900\/60 {
  border-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-cyan-900\/65 {
  border-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-cyan-900\/70 {
  border-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-cyan-900\/75 {
  border-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-cyan-900\/80 {
  border-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-cyan-900\/85 {
  border-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-cyan-900\/90 {
  border-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-cyan-900\/95 {
  border-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-cyan-950\/0 {
  border-color: rgb(8 51 68 / 0) !important;
}

.tw-border-cyan-950\/10 {
  border-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-cyan-950\/100 {
  border-color: rgb(8 51 68 / 1) !important;
}

.tw-border-cyan-950\/15 {
  border-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-cyan-950\/20 {
  border-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-cyan-950\/25 {
  border-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-cyan-950\/30 {
  border-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-cyan-950\/35 {
  border-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-cyan-950\/40 {
  border-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-cyan-950\/45 {
  border-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-cyan-950\/5 {
  border-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-cyan-950\/50 {
  border-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-cyan-950\/55 {
  border-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-cyan-950\/60 {
  border-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-cyan-950\/65 {
  border-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-cyan-950\/70 {
  border-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-cyan-950\/75 {
  border-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-cyan-950\/80 {
  border-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-cyan-950\/85 {
  border-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-cyan-950\/90 {
  border-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-cyan-950\/95 {
  border-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-100\/0 {
  border-color: rgb(209 250 229 / 0) !important;
}

.tw-border-emerald-100\/10 {
  border-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-emerald-100\/100 {
  border-color: rgb(209 250 229 / 1) !important;
}

.tw-border-emerald-100\/15 {
  border-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-emerald-100\/20 {
  border-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-emerald-100\/25 {
  border-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-emerald-100\/30 {
  border-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-emerald-100\/35 {
  border-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-emerald-100\/40 {
  border-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-emerald-100\/45 {
  border-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-emerald-100\/5 {
  border-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-emerald-100\/50 {
  border-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-emerald-100\/55 {
  border-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-emerald-100\/60 {
  border-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-emerald-100\/65 {
  border-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-emerald-100\/70 {
  border-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-emerald-100\/75 {
  border-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-emerald-100\/80 {
  border-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-emerald-100\/85 {
  border-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-emerald-100\/90 {
  border-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-emerald-100\/95 {
  border-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-200\/0 {
  border-color: rgb(167 243 208 / 0) !important;
}

.tw-border-emerald-200\/10 {
  border-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-emerald-200\/100 {
  border-color: rgb(167 243 208 / 1) !important;
}

.tw-border-emerald-200\/15 {
  border-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-emerald-200\/20 {
  border-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-emerald-200\/25 {
  border-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-emerald-200\/30 {
  border-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-emerald-200\/35 {
  border-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-emerald-200\/40 {
  border-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-emerald-200\/45 {
  border-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-emerald-200\/5 {
  border-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-emerald-200\/50 {
  border-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-emerald-200\/55 {
  border-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-emerald-200\/60 {
  border-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-emerald-200\/65 {
  border-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-emerald-200\/70 {
  border-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-emerald-200\/75 {
  border-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-emerald-200\/80 {
  border-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-emerald-200\/85 {
  border-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-emerald-200\/90 {
  border-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-emerald-200\/95 {
  border-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-300\/0 {
  border-color: rgb(110 231 183 / 0) !important;
}

.tw-border-emerald-300\/10 {
  border-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-emerald-300\/100 {
  border-color: rgb(110 231 183 / 1) !important;
}

.tw-border-emerald-300\/15 {
  border-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-emerald-300\/20 {
  border-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-emerald-300\/25 {
  border-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-emerald-300\/30 {
  border-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-emerald-300\/35 {
  border-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-emerald-300\/40 {
  border-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-emerald-300\/45 {
  border-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-emerald-300\/5 {
  border-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-emerald-300\/50 {
  border-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-emerald-300\/55 {
  border-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-emerald-300\/60 {
  border-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-emerald-300\/65 {
  border-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-emerald-300\/70 {
  border-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-emerald-300\/75 {
  border-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-emerald-300\/80 {
  border-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-emerald-300\/85 {
  border-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-emerald-300\/90 {
  border-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-emerald-300\/95 {
  border-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-400\/0 {
  border-color: rgb(52 211 153 / 0) !important;
}

.tw-border-emerald-400\/10 {
  border-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-emerald-400\/100 {
  border-color: rgb(52 211 153 / 1) !important;
}

.tw-border-emerald-400\/15 {
  border-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-emerald-400\/20 {
  border-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-emerald-400\/25 {
  border-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-emerald-400\/30 {
  border-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-emerald-400\/35 {
  border-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-emerald-400\/40 {
  border-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-emerald-400\/45 {
  border-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-emerald-400\/5 {
  border-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-emerald-400\/50 {
  border-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-emerald-400\/55 {
  border-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-emerald-400\/60 {
  border-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-emerald-400\/65 {
  border-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-emerald-400\/70 {
  border-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-emerald-400\/75 {
  border-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-emerald-400\/80 {
  border-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-emerald-400\/85 {
  border-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-emerald-400\/90 {
  border-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-emerald-400\/95 {
  border-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-50\/0 {
  border-color: rgb(236 253 245 / 0) !important;
}

.tw-border-emerald-50\/10 {
  border-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-emerald-50\/100 {
  border-color: rgb(236 253 245 / 1) !important;
}

.tw-border-emerald-50\/15 {
  border-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-emerald-50\/20 {
  border-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-emerald-50\/25 {
  border-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-emerald-50\/30 {
  border-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-emerald-50\/35 {
  border-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-emerald-50\/40 {
  border-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-emerald-50\/45 {
  border-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-emerald-50\/5 {
  border-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-emerald-50\/50 {
  border-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-emerald-50\/55 {
  border-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-emerald-50\/60 {
  border-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-emerald-50\/65 {
  border-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-emerald-50\/70 {
  border-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-emerald-50\/75 {
  border-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-emerald-50\/80 {
  border-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-emerald-50\/85 {
  border-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-emerald-50\/90 {
  border-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-emerald-50\/95 {
  border-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-500\/0 {
  border-color: rgb(16 185 129 / 0) !important;
}

.tw-border-emerald-500\/10 {
  border-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-emerald-500\/100 {
  border-color: rgb(16 185 129 / 1) !important;
}

.tw-border-emerald-500\/15 {
  border-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-emerald-500\/20 {
  border-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-emerald-500\/25 {
  border-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-emerald-500\/30 {
  border-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-emerald-500\/35 {
  border-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-emerald-500\/40 {
  border-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-emerald-500\/45 {
  border-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-emerald-500\/5 {
  border-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-emerald-500\/50 {
  border-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-emerald-500\/55 {
  border-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-emerald-500\/60 {
  border-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-emerald-500\/65 {
  border-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-emerald-500\/70 {
  border-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-emerald-500\/75 {
  border-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-emerald-500\/80 {
  border-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-emerald-500\/85 {
  border-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-emerald-500\/90 {
  border-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-emerald-500\/95 {
  border-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-600\/0 {
  border-color: rgb(5 150 105 / 0) !important;
}

.tw-border-emerald-600\/10 {
  border-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-emerald-600\/100 {
  border-color: rgb(5 150 105 / 1) !important;
}

.tw-border-emerald-600\/15 {
  border-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-emerald-600\/20 {
  border-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-emerald-600\/25 {
  border-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-emerald-600\/30 {
  border-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-emerald-600\/35 {
  border-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-emerald-600\/40 {
  border-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-emerald-600\/45 {
  border-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-emerald-600\/5 {
  border-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-emerald-600\/50 {
  border-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-emerald-600\/55 {
  border-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-emerald-600\/60 {
  border-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-emerald-600\/65 {
  border-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-emerald-600\/70 {
  border-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-emerald-600\/75 {
  border-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-emerald-600\/80 {
  border-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-emerald-600\/85 {
  border-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-emerald-600\/90 {
  border-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-emerald-600\/95 {
  border-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-700\/0 {
  border-color: rgb(4 120 87 / 0) !important;
}

.tw-border-emerald-700\/10 {
  border-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-emerald-700\/100 {
  border-color: rgb(4 120 87 / 1) !important;
}

.tw-border-emerald-700\/15 {
  border-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-emerald-700\/20 {
  border-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-emerald-700\/25 {
  border-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-emerald-700\/30 {
  border-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-emerald-700\/35 {
  border-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-emerald-700\/40 {
  border-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-emerald-700\/45 {
  border-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-emerald-700\/5 {
  border-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-emerald-700\/50 {
  border-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-emerald-700\/55 {
  border-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-emerald-700\/60 {
  border-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-emerald-700\/65 {
  border-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-emerald-700\/70 {
  border-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-emerald-700\/75 {
  border-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-emerald-700\/80 {
  border-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-emerald-700\/85 {
  border-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-emerald-700\/90 {
  border-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-emerald-700\/95 {
  border-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-800\/0 {
  border-color: rgb(6 95 70 / 0) !important;
}

.tw-border-emerald-800\/10 {
  border-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-emerald-800\/100 {
  border-color: rgb(6 95 70 / 1) !important;
}

.tw-border-emerald-800\/15 {
  border-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-emerald-800\/20 {
  border-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-emerald-800\/25 {
  border-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-emerald-800\/30 {
  border-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-emerald-800\/35 {
  border-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-emerald-800\/40 {
  border-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-emerald-800\/45 {
  border-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-emerald-800\/5 {
  border-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-emerald-800\/50 {
  border-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-emerald-800\/55 {
  border-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-emerald-800\/60 {
  border-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-emerald-800\/65 {
  border-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-emerald-800\/70 {
  border-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-emerald-800\/75 {
  border-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-emerald-800\/80 {
  border-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-emerald-800\/85 {
  border-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-emerald-800\/90 {
  border-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-emerald-800\/95 {
  border-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-900\/0 {
  border-color: rgb(6 78 59 / 0) !important;
}

.tw-border-emerald-900\/10 {
  border-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-emerald-900\/100 {
  border-color: rgb(6 78 59 / 1) !important;
}

.tw-border-emerald-900\/15 {
  border-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-emerald-900\/20 {
  border-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-emerald-900\/25 {
  border-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-emerald-900\/30 {
  border-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-emerald-900\/35 {
  border-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-emerald-900\/40 {
  border-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-emerald-900\/45 {
  border-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-emerald-900\/5 {
  border-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-emerald-900\/50 {
  border-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-emerald-900\/55 {
  border-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-emerald-900\/60 {
  border-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-emerald-900\/65 {
  border-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-emerald-900\/70 {
  border-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-emerald-900\/75 {
  border-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-emerald-900\/80 {
  border-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-emerald-900\/85 {
  border-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-emerald-900\/90 {
  border-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-emerald-900\/95 {
  border-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-emerald-950\/0 {
  border-color: rgb(2 44 34 / 0) !important;
}

.tw-border-emerald-950\/10 {
  border-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-emerald-950\/100 {
  border-color: rgb(2 44 34 / 1) !important;
}

.tw-border-emerald-950\/15 {
  border-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-emerald-950\/20 {
  border-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-emerald-950\/25 {
  border-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-emerald-950\/30 {
  border-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-emerald-950\/35 {
  border-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-emerald-950\/40 {
  border-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-emerald-950\/45 {
  border-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-emerald-950\/5 {
  border-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-emerald-950\/50 {
  border-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-emerald-950\/55 {
  border-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-emerald-950\/60 {
  border-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-emerald-950\/65 {
  border-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-emerald-950\/70 {
  border-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-emerald-950\/75 {
  border-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-emerald-950\/80 {
  border-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-emerald-950\/85 {
  border-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-emerald-950\/90 {
  border-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-emerald-950\/95 {
  border-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-100\/0 {
  border-color: rgb(250 232 255 / 0) !important;
}

.tw-border-fuchsia-100\/10 {
  border-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-fuchsia-100\/100 {
  border-color: rgb(250 232 255 / 1) !important;
}

.tw-border-fuchsia-100\/15 {
  border-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-fuchsia-100\/20 {
  border-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-fuchsia-100\/25 {
  border-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-fuchsia-100\/30 {
  border-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-fuchsia-100\/35 {
  border-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-fuchsia-100\/40 {
  border-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-fuchsia-100\/45 {
  border-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-fuchsia-100\/5 {
  border-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-fuchsia-100\/50 {
  border-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-fuchsia-100\/55 {
  border-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-fuchsia-100\/60 {
  border-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-fuchsia-100\/65 {
  border-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-fuchsia-100\/70 {
  border-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-fuchsia-100\/75 {
  border-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-fuchsia-100\/80 {
  border-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-fuchsia-100\/85 {
  border-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-fuchsia-100\/90 {
  border-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-fuchsia-100\/95 {
  border-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-200\/0 {
  border-color: rgb(245 208 254 / 0) !important;
}

.tw-border-fuchsia-200\/10 {
  border-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-fuchsia-200\/100 {
  border-color: rgb(245 208 254 / 1) !important;
}

.tw-border-fuchsia-200\/15 {
  border-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-fuchsia-200\/20 {
  border-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-fuchsia-200\/25 {
  border-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-fuchsia-200\/30 {
  border-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-fuchsia-200\/35 {
  border-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-fuchsia-200\/40 {
  border-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-fuchsia-200\/45 {
  border-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-fuchsia-200\/5 {
  border-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-fuchsia-200\/50 {
  border-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-fuchsia-200\/55 {
  border-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-fuchsia-200\/60 {
  border-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-fuchsia-200\/65 {
  border-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-fuchsia-200\/70 {
  border-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-fuchsia-200\/75 {
  border-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-fuchsia-200\/80 {
  border-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-fuchsia-200\/85 {
  border-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-fuchsia-200\/90 {
  border-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-fuchsia-200\/95 {
  border-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-300\/0 {
  border-color: rgb(240 171 252 / 0) !important;
}

.tw-border-fuchsia-300\/10 {
  border-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-fuchsia-300\/100 {
  border-color: rgb(240 171 252 / 1) !important;
}

.tw-border-fuchsia-300\/15 {
  border-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-fuchsia-300\/20 {
  border-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-fuchsia-300\/25 {
  border-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-fuchsia-300\/30 {
  border-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-fuchsia-300\/35 {
  border-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-fuchsia-300\/40 {
  border-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-fuchsia-300\/45 {
  border-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-fuchsia-300\/5 {
  border-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-fuchsia-300\/50 {
  border-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-fuchsia-300\/55 {
  border-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-fuchsia-300\/60 {
  border-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-fuchsia-300\/65 {
  border-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-fuchsia-300\/70 {
  border-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-fuchsia-300\/75 {
  border-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-fuchsia-300\/80 {
  border-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-fuchsia-300\/85 {
  border-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-fuchsia-300\/90 {
  border-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-fuchsia-300\/95 {
  border-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-400\/0 {
  border-color: rgb(232 121 249 / 0) !important;
}

.tw-border-fuchsia-400\/10 {
  border-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-fuchsia-400\/100 {
  border-color: rgb(232 121 249 / 1) !important;
}

.tw-border-fuchsia-400\/15 {
  border-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-fuchsia-400\/20 {
  border-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-fuchsia-400\/25 {
  border-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-fuchsia-400\/30 {
  border-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-fuchsia-400\/35 {
  border-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-fuchsia-400\/40 {
  border-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-fuchsia-400\/45 {
  border-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-fuchsia-400\/5 {
  border-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-fuchsia-400\/50 {
  border-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-fuchsia-400\/55 {
  border-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-fuchsia-400\/60 {
  border-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-fuchsia-400\/65 {
  border-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-fuchsia-400\/70 {
  border-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-fuchsia-400\/75 {
  border-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-fuchsia-400\/80 {
  border-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-fuchsia-400\/85 {
  border-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-fuchsia-400\/90 {
  border-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-fuchsia-400\/95 {
  border-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-50\/0 {
  border-color: rgb(253 244 255 / 0) !important;
}

.tw-border-fuchsia-50\/10 {
  border-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-fuchsia-50\/100 {
  border-color: rgb(253 244 255 / 1) !important;
}

.tw-border-fuchsia-50\/15 {
  border-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-fuchsia-50\/20 {
  border-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-fuchsia-50\/25 {
  border-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-fuchsia-50\/30 {
  border-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-fuchsia-50\/35 {
  border-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-fuchsia-50\/40 {
  border-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-fuchsia-50\/45 {
  border-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-fuchsia-50\/5 {
  border-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-fuchsia-50\/50 {
  border-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-fuchsia-50\/55 {
  border-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-fuchsia-50\/60 {
  border-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-fuchsia-50\/65 {
  border-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-fuchsia-50\/70 {
  border-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-fuchsia-50\/75 {
  border-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-fuchsia-50\/80 {
  border-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-fuchsia-50\/85 {
  border-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-fuchsia-50\/90 {
  border-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-fuchsia-50\/95 {
  border-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-500\/0 {
  border-color: rgb(217 70 239 / 0) !important;
}

.tw-border-fuchsia-500\/10 {
  border-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-fuchsia-500\/100 {
  border-color: rgb(217 70 239 / 1) !important;
}

.tw-border-fuchsia-500\/15 {
  border-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-fuchsia-500\/20 {
  border-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-fuchsia-500\/25 {
  border-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-fuchsia-500\/30 {
  border-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-fuchsia-500\/35 {
  border-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-fuchsia-500\/40 {
  border-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-fuchsia-500\/45 {
  border-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-fuchsia-500\/5 {
  border-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-fuchsia-500\/50 {
  border-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-fuchsia-500\/55 {
  border-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-fuchsia-500\/60 {
  border-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-fuchsia-500\/65 {
  border-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-fuchsia-500\/70 {
  border-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-fuchsia-500\/75 {
  border-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-fuchsia-500\/80 {
  border-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-fuchsia-500\/85 {
  border-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-fuchsia-500\/90 {
  border-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-fuchsia-500\/95 {
  border-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-600\/0 {
  border-color: rgb(192 38 211 / 0) !important;
}

.tw-border-fuchsia-600\/10 {
  border-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-fuchsia-600\/100 {
  border-color: rgb(192 38 211 / 1) !important;
}

.tw-border-fuchsia-600\/15 {
  border-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-fuchsia-600\/20 {
  border-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-fuchsia-600\/25 {
  border-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-fuchsia-600\/30 {
  border-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-fuchsia-600\/35 {
  border-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-fuchsia-600\/40 {
  border-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-fuchsia-600\/45 {
  border-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-fuchsia-600\/5 {
  border-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-fuchsia-600\/50 {
  border-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-fuchsia-600\/55 {
  border-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-fuchsia-600\/60 {
  border-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-fuchsia-600\/65 {
  border-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-fuchsia-600\/70 {
  border-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-fuchsia-600\/75 {
  border-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-fuchsia-600\/80 {
  border-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-fuchsia-600\/85 {
  border-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-fuchsia-600\/90 {
  border-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-fuchsia-600\/95 {
  border-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-700\/0 {
  border-color: rgb(162 28 175 / 0) !important;
}

.tw-border-fuchsia-700\/10 {
  border-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-fuchsia-700\/100 {
  border-color: rgb(162 28 175 / 1) !important;
}

.tw-border-fuchsia-700\/15 {
  border-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-fuchsia-700\/20 {
  border-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-fuchsia-700\/25 {
  border-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-fuchsia-700\/30 {
  border-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-fuchsia-700\/35 {
  border-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-fuchsia-700\/40 {
  border-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-fuchsia-700\/45 {
  border-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-fuchsia-700\/5 {
  border-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-fuchsia-700\/50 {
  border-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-fuchsia-700\/55 {
  border-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-fuchsia-700\/60 {
  border-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-fuchsia-700\/65 {
  border-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-fuchsia-700\/70 {
  border-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-fuchsia-700\/75 {
  border-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-fuchsia-700\/80 {
  border-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-fuchsia-700\/85 {
  border-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-fuchsia-700\/90 {
  border-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-fuchsia-700\/95 {
  border-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-800\/0 {
  border-color: rgb(134 25 143 / 0) !important;
}

.tw-border-fuchsia-800\/10 {
  border-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-fuchsia-800\/100 {
  border-color: rgb(134 25 143 / 1) !important;
}

.tw-border-fuchsia-800\/15 {
  border-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-fuchsia-800\/20 {
  border-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-fuchsia-800\/25 {
  border-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-fuchsia-800\/30 {
  border-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-fuchsia-800\/35 {
  border-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-fuchsia-800\/40 {
  border-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-fuchsia-800\/45 {
  border-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-fuchsia-800\/5 {
  border-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-fuchsia-800\/50 {
  border-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-fuchsia-800\/55 {
  border-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-fuchsia-800\/60 {
  border-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-fuchsia-800\/65 {
  border-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-fuchsia-800\/70 {
  border-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-fuchsia-800\/75 {
  border-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-fuchsia-800\/80 {
  border-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-fuchsia-800\/85 {
  border-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-fuchsia-800\/90 {
  border-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-fuchsia-800\/95 {
  border-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-900\/0 {
  border-color: rgb(112 26 117 / 0) !important;
}

.tw-border-fuchsia-900\/10 {
  border-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-fuchsia-900\/100 {
  border-color: rgb(112 26 117 / 1) !important;
}

.tw-border-fuchsia-900\/15 {
  border-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-fuchsia-900\/20 {
  border-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-fuchsia-900\/25 {
  border-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-fuchsia-900\/30 {
  border-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-fuchsia-900\/35 {
  border-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-fuchsia-900\/40 {
  border-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-fuchsia-900\/45 {
  border-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-fuchsia-900\/5 {
  border-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-fuchsia-900\/50 {
  border-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-fuchsia-900\/55 {
  border-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-fuchsia-900\/60 {
  border-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-fuchsia-900\/65 {
  border-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-fuchsia-900\/70 {
  border-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-fuchsia-900\/75 {
  border-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-fuchsia-900\/80 {
  border-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-fuchsia-900\/85 {
  border-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-fuchsia-900\/90 {
  border-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-fuchsia-900\/95 {
  border-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-fuchsia-950\/0 {
  border-color: rgb(74 4 78 / 0) !important;
}

.tw-border-fuchsia-950\/10 {
  border-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-fuchsia-950\/100 {
  border-color: rgb(74 4 78 / 1) !important;
}

.tw-border-fuchsia-950\/15 {
  border-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-fuchsia-950\/20 {
  border-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-fuchsia-950\/25 {
  border-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-fuchsia-950\/30 {
  border-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-fuchsia-950\/35 {
  border-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-fuchsia-950\/40 {
  border-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-fuchsia-950\/45 {
  border-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-fuchsia-950\/5 {
  border-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-fuchsia-950\/50 {
  border-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-fuchsia-950\/55 {
  border-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-fuchsia-950\/60 {
  border-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-fuchsia-950\/65 {
  border-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-fuchsia-950\/70 {
  border-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-fuchsia-950\/75 {
  border-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-fuchsia-950\/80 {
  border-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-fuchsia-950\/85 {
  border-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-fuchsia-950\/90 {
  border-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-fuchsia-950\/95 {
  border-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-gray-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-100\/0 {
  border-color: rgb(243 244 246 / 0) !important;
}

.tw-border-gray-100\/10 {
  border-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-gray-100\/100 {
  border-color: rgb(243 244 246 / 1) !important;
}

.tw-border-gray-100\/15 {
  border-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-gray-100\/20 {
  border-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-gray-100\/25 {
  border-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-gray-100\/30 {
  border-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-gray-100\/35 {
  border-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-gray-100\/40 {
  border-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-gray-100\/45 {
  border-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-gray-100\/5 {
  border-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-gray-100\/50 {
  border-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-gray-100\/55 {
  border-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-gray-100\/60 {
  border-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-gray-100\/65 {
  border-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-gray-100\/70 {
  border-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-gray-100\/75 {
  border-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-gray-100\/80 {
  border-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-gray-100\/85 {
  border-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-gray-100\/90 {
  border-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-gray-100\/95 {
  border-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-gray-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-200\/0 {
  border-color: rgb(229 231 235 / 0) !important;
}

.tw-border-gray-200\/10 {
  border-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-gray-200\/100 {
  border-color: rgb(229 231 235 / 1) !important;
}

.tw-border-gray-200\/15 {
  border-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-gray-200\/20 {
  border-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-gray-200\/25 {
  border-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-gray-200\/30 {
  border-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-gray-200\/35 {
  border-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-gray-200\/40 {
  border-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-gray-200\/45 {
  border-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-gray-200\/5 {
  border-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-gray-200\/50 {
  border-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-gray-200\/55 {
  border-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-gray-200\/60 {
  border-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-gray-200\/65 {
  border-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-gray-200\/70 {
  border-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-gray-200\/75 {
  border-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-gray-200\/80 {
  border-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-gray-200\/85 {
  border-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-gray-200\/90 {
  border-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-gray-200\/95 {
  border-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-gray-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-300\/0 {
  border-color: rgb(209 213 219 / 0) !important;
}

.tw-border-gray-300\/10 {
  border-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-gray-300\/100 {
  border-color: rgb(209 213 219 / 1) !important;
}

.tw-border-gray-300\/15 {
  border-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-gray-300\/20 {
  border-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-gray-300\/25 {
  border-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-gray-300\/30 {
  border-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-gray-300\/35 {
  border-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-gray-300\/40 {
  border-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-gray-300\/45 {
  border-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-gray-300\/5 {
  border-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-gray-300\/50 {
  border-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-gray-300\/55 {
  border-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-gray-300\/60 {
  border-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-gray-300\/65 {
  border-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-gray-300\/70 {
  border-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-gray-300\/75 {
  border-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-gray-300\/80 {
  border-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-gray-300\/85 {
  border-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-gray-300\/90 {
  border-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-gray-300\/95 {
  border-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-gray-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-400\/0 {
  border-color: rgb(156 163 175 / 0) !important;
}

.tw-border-gray-400\/10 {
  border-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-gray-400\/100 {
  border-color: rgb(156 163 175 / 1) !important;
}

.tw-border-gray-400\/15 {
  border-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-gray-400\/20 {
  border-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-gray-400\/25 {
  border-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-gray-400\/30 {
  border-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-gray-400\/35 {
  border-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-gray-400\/40 {
  border-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-gray-400\/45 {
  border-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-gray-400\/5 {
  border-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-gray-400\/50 {
  border-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-gray-400\/55 {
  border-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-gray-400\/60 {
  border-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-gray-400\/65 {
  border-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-gray-400\/70 {
  border-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-gray-400\/75 {
  border-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-gray-400\/80 {
  border-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-gray-400\/85 {
  border-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-gray-400\/90 {
  border-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-gray-400\/95 {
  border-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-gray-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-50\/0 {
  border-color: rgb(249 250 251 / 0) !important;
}

.tw-border-gray-50\/10 {
  border-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-gray-50\/100 {
  border-color: rgb(249 250 251 / 1) !important;
}

.tw-border-gray-50\/15 {
  border-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-gray-50\/20 {
  border-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-gray-50\/25 {
  border-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-gray-50\/30 {
  border-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-gray-50\/35 {
  border-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-gray-50\/40 {
  border-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-gray-50\/45 {
  border-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-gray-50\/5 {
  border-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-gray-50\/50 {
  border-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-gray-50\/55 {
  border-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-gray-50\/60 {
  border-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-gray-50\/65 {
  border-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-gray-50\/70 {
  border-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-gray-50\/75 {
  border-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-gray-50\/80 {
  border-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-gray-50\/85 {
  border-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-gray-50\/90 {
  border-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-gray-50\/95 {
  border-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-gray-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-500\/0 {
  border-color: rgb(107 114 128 / 0) !important;
}

.tw-border-gray-500\/10 {
  border-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-gray-500\/100 {
  border-color: rgb(107 114 128 / 1) !important;
}

.tw-border-gray-500\/15 {
  border-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-gray-500\/20 {
  border-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-gray-500\/25 {
  border-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-gray-500\/30 {
  border-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-gray-500\/35 {
  border-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-gray-500\/40 {
  border-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-gray-500\/45 {
  border-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-gray-500\/5 {
  border-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-gray-500\/50 {
  border-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-gray-500\/55 {
  border-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-gray-500\/60 {
  border-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-gray-500\/65 {
  border-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-gray-500\/70 {
  border-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-gray-500\/75 {
  border-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-gray-500\/80 {
  border-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-gray-500\/85 {
  border-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-gray-500\/90 {
  border-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-gray-500\/95 {
  border-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-gray-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-600\/0 {
  border-color: rgb(75 85 99 / 0) !important;
}

.tw-border-gray-600\/10 {
  border-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-gray-600\/100 {
  border-color: rgb(75 85 99 / 1) !important;
}

.tw-border-gray-600\/15 {
  border-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-gray-600\/20 {
  border-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-gray-600\/25 {
  border-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-gray-600\/30 {
  border-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-gray-600\/35 {
  border-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-gray-600\/40 {
  border-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-gray-600\/45 {
  border-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-gray-600\/5 {
  border-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-gray-600\/50 {
  border-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-gray-600\/55 {
  border-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-gray-600\/60 {
  border-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-gray-600\/65 {
  border-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-gray-600\/70 {
  border-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-gray-600\/75 {
  border-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-gray-600\/80 {
  border-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-gray-600\/85 {
  border-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-gray-600\/90 {
  border-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-gray-600\/95 {
  border-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-gray-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-700\/0 {
  border-color: rgb(55 65 81 / 0) !important;
}

.tw-border-gray-700\/10 {
  border-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-gray-700\/100 {
  border-color: rgb(55 65 81 / 1) !important;
}

.tw-border-gray-700\/15 {
  border-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-gray-700\/20 {
  border-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-gray-700\/25 {
  border-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-gray-700\/30 {
  border-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-gray-700\/35 {
  border-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-gray-700\/40 {
  border-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-gray-700\/45 {
  border-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-gray-700\/5 {
  border-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-gray-700\/50 {
  border-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-gray-700\/55 {
  border-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-gray-700\/60 {
  border-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-gray-700\/65 {
  border-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-gray-700\/70 {
  border-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-gray-700\/75 {
  border-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-gray-700\/80 {
  border-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-gray-700\/85 {
  border-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-gray-700\/90 {
  border-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-gray-700\/95 {
  border-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-gray-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-800\/0 {
  border-color: rgb(31 41 55 / 0) !important;
}

.tw-border-gray-800\/10 {
  border-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-gray-800\/100 {
  border-color: rgb(31 41 55 / 1) !important;
}

.tw-border-gray-800\/15 {
  border-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-gray-800\/20 {
  border-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-gray-800\/25 {
  border-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-gray-800\/30 {
  border-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-gray-800\/35 {
  border-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-gray-800\/40 {
  border-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-gray-800\/45 {
  border-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-gray-800\/5 {
  border-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-gray-800\/50 {
  border-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-gray-800\/55 {
  border-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-gray-800\/60 {
  border-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-gray-800\/65 {
  border-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-gray-800\/70 {
  border-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-gray-800\/75 {
  border-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-gray-800\/80 {
  border-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-gray-800\/85 {
  border-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-gray-800\/90 {
  border-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-gray-800\/95 {
  border-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-gray-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-900\/0 {
  border-color: rgb(17 24 39 / 0) !important;
}

.tw-border-gray-900\/10 {
  border-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-gray-900\/100 {
  border-color: rgb(17 24 39 / 1) !important;
}

.tw-border-gray-900\/15 {
  border-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-gray-900\/20 {
  border-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-gray-900\/25 {
  border-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-gray-900\/30 {
  border-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-gray-900\/35 {
  border-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-gray-900\/40 {
  border-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-gray-900\/45 {
  border-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-gray-900\/5 {
  border-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-gray-900\/50 {
  border-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-gray-900\/55 {
  border-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-gray-900\/60 {
  border-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-gray-900\/65 {
  border-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-gray-900\/70 {
  border-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-gray-900\/75 {
  border-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-gray-900\/80 {
  border-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-gray-900\/85 {
  border-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-gray-900\/90 {
  border-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-gray-900\/95 {
  border-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-gray-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-gray-950\/0 {
  border-color: rgb(3 7 18 / 0) !important;
}

.tw-border-gray-950\/10 {
  border-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-gray-950\/100 {
  border-color: rgb(3 7 18 / 1) !important;
}

.tw-border-gray-950\/15 {
  border-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-gray-950\/20 {
  border-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-gray-950\/25 {
  border-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-gray-950\/30 {
  border-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-gray-950\/35 {
  border-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-gray-950\/40 {
  border-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-gray-950\/45 {
  border-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-gray-950\/5 {
  border-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-gray-950\/50 {
  border-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-gray-950\/55 {
  border-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-gray-950\/60 {
  border-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-gray-950\/65 {
  border-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-gray-950\/70 {
  border-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-gray-950\/75 {
  border-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-gray-950\/80 {
  border-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-gray-950\/85 {
  border-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-gray-950\/90 {
  border-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-gray-950\/95 {
  border-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-green-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-green-100\/0 {
  border-color: rgb(220 252 231 / 0) !important;
}

.tw-border-green-100\/10 {
  border-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-green-100\/100 {
  border-color: rgb(220 252 231 / 1) !important;
}

.tw-border-green-100\/15 {
  border-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-green-100\/20 {
  border-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-green-100\/25 {
  border-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-green-100\/30 {
  border-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-green-100\/35 {
  border-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-green-100\/40 {
  border-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-green-100\/45 {
  border-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-green-100\/5 {
  border-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-green-100\/50 {
  border-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-green-100\/55 {
  border-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-green-100\/60 {
  border-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-green-100\/65 {
  border-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-green-100\/70 {
  border-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-green-100\/75 {
  border-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-green-100\/80 {
  border-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-green-100\/85 {
  border-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-green-100\/90 {
  border-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-green-100\/95 {
  border-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-green-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-green-200\/0 {
  border-color: rgb(187 247 208 / 0) !important;
}

.tw-border-green-200\/10 {
  border-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-green-200\/100 {
  border-color: rgb(187 247 208 / 1) !important;
}

.tw-border-green-200\/15 {
  border-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-green-200\/20 {
  border-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-green-200\/25 {
  border-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-green-200\/30 {
  border-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-green-200\/35 {
  border-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-green-200\/40 {
  border-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-green-200\/45 {
  border-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-green-200\/5 {
  border-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-green-200\/50 {
  border-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-green-200\/55 {
  border-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-green-200\/60 {
  border-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-green-200\/65 {
  border-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-green-200\/70 {
  border-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-green-200\/75 {
  border-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-green-200\/80 {
  border-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-green-200\/85 {
  border-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-green-200\/90 {
  border-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-green-200\/95 {
  border-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-green-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-green-300\/0 {
  border-color: rgb(134 239 172 / 0) !important;
}

.tw-border-green-300\/10 {
  border-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-green-300\/100 {
  border-color: rgb(134 239 172 / 1) !important;
}

.tw-border-green-300\/15 {
  border-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-green-300\/20 {
  border-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-green-300\/25 {
  border-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-green-300\/30 {
  border-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-green-300\/35 {
  border-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-green-300\/40 {
  border-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-green-300\/45 {
  border-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-green-300\/5 {
  border-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-green-300\/50 {
  border-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-green-300\/55 {
  border-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-green-300\/60 {
  border-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-green-300\/65 {
  border-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-green-300\/70 {
  border-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-green-300\/75 {
  border-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-green-300\/80 {
  border-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-green-300\/85 {
  border-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-green-300\/90 {
  border-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-green-300\/95 {
  border-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-green-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-green-400\/0 {
  border-color: rgb(74 222 128 / 0) !important;
}

.tw-border-green-400\/10 {
  border-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-green-400\/100 {
  border-color: rgb(74 222 128 / 1) !important;
}

.tw-border-green-400\/15 {
  border-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-green-400\/20 {
  border-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-green-400\/25 {
  border-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-green-400\/30 {
  border-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-green-400\/35 {
  border-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-green-400\/40 {
  border-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-green-400\/45 {
  border-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-green-400\/5 {
  border-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-green-400\/50 {
  border-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-green-400\/55 {
  border-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-green-400\/60 {
  border-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-green-400\/65 {
  border-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-green-400\/70 {
  border-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-green-400\/75 {
  border-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-green-400\/80 {
  border-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-green-400\/85 {
  border-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-green-400\/90 {
  border-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-green-400\/95 {
  border-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-green-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-green-50\/0 {
  border-color: rgb(240 253 244 / 0) !important;
}

.tw-border-green-50\/10 {
  border-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-green-50\/100 {
  border-color: rgb(240 253 244 / 1) !important;
}

.tw-border-green-50\/15 {
  border-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-green-50\/20 {
  border-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-green-50\/25 {
  border-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-green-50\/30 {
  border-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-green-50\/35 {
  border-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-green-50\/40 {
  border-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-green-50\/45 {
  border-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-green-50\/5 {
  border-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-green-50\/50 {
  border-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-green-50\/55 {
  border-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-green-50\/60 {
  border-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-green-50\/65 {
  border-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-green-50\/70 {
  border-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-green-50\/75 {
  border-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-green-50\/80 {
  border-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-green-50\/85 {
  border-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-green-50\/90 {
  border-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-green-50\/95 {
  border-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-green-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-green-500\/0 {
  border-color: rgb(34 197 94 / 0) !important;
}

.tw-border-green-500\/10 {
  border-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-green-500\/100 {
  border-color: rgb(34 197 94 / 1) !important;
}

.tw-border-green-500\/15 {
  border-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-green-500\/20 {
  border-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-green-500\/25 {
  border-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-green-500\/30 {
  border-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-green-500\/35 {
  border-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-green-500\/40 {
  border-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-green-500\/45 {
  border-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-green-500\/5 {
  border-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-green-500\/50 {
  border-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-green-500\/55 {
  border-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-green-500\/60 {
  border-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-green-500\/65 {
  border-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-green-500\/70 {
  border-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-green-500\/75 {
  border-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-green-500\/80 {
  border-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-green-500\/85 {
  border-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-green-500\/90 {
  border-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-green-500\/95 {
  border-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-green-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-green-600\/0 {
  border-color: rgb(22 163 74 / 0) !important;
}

.tw-border-green-600\/10 {
  border-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-green-600\/100 {
  border-color: rgb(22 163 74 / 1) !important;
}

.tw-border-green-600\/15 {
  border-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-green-600\/20 {
  border-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-green-600\/25 {
  border-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-green-600\/30 {
  border-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-green-600\/35 {
  border-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-green-600\/40 {
  border-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-green-600\/45 {
  border-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-green-600\/5 {
  border-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-green-600\/50 {
  border-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-green-600\/55 {
  border-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-green-600\/60 {
  border-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-green-600\/65 {
  border-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-green-600\/70 {
  border-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-green-600\/75 {
  border-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-green-600\/80 {
  border-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-green-600\/85 {
  border-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-green-600\/90 {
  border-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-green-600\/95 {
  border-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-green-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-green-700\/0 {
  border-color: rgb(21 128 61 / 0) !important;
}

.tw-border-green-700\/10 {
  border-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-green-700\/100 {
  border-color: rgb(21 128 61 / 1) !important;
}

.tw-border-green-700\/15 {
  border-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-green-700\/20 {
  border-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-green-700\/25 {
  border-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-green-700\/30 {
  border-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-green-700\/35 {
  border-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-green-700\/40 {
  border-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-green-700\/45 {
  border-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-green-700\/5 {
  border-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-green-700\/50 {
  border-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-green-700\/55 {
  border-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-green-700\/60 {
  border-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-green-700\/65 {
  border-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-green-700\/70 {
  border-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-green-700\/75 {
  border-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-green-700\/80 {
  border-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-green-700\/85 {
  border-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-green-700\/90 {
  border-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-green-700\/95 {
  border-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-green-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-green-800\/0 {
  border-color: rgb(22 101 52 / 0) !important;
}

.tw-border-green-800\/10 {
  border-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-green-800\/100 {
  border-color: rgb(22 101 52 / 1) !important;
}

.tw-border-green-800\/15 {
  border-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-green-800\/20 {
  border-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-green-800\/25 {
  border-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-green-800\/30 {
  border-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-green-800\/35 {
  border-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-green-800\/40 {
  border-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-green-800\/45 {
  border-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-green-800\/5 {
  border-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-green-800\/50 {
  border-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-green-800\/55 {
  border-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-green-800\/60 {
  border-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-green-800\/65 {
  border-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-green-800\/70 {
  border-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-green-800\/75 {
  border-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-green-800\/80 {
  border-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-green-800\/85 {
  border-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-green-800\/90 {
  border-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-green-800\/95 {
  border-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-green-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-green-900\/0 {
  border-color: rgb(20 83 45 / 0) !important;
}

.tw-border-green-900\/10 {
  border-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-green-900\/100 {
  border-color: rgb(20 83 45 / 1) !important;
}

.tw-border-green-900\/15 {
  border-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-green-900\/20 {
  border-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-green-900\/25 {
  border-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-green-900\/30 {
  border-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-green-900\/35 {
  border-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-green-900\/40 {
  border-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-green-900\/45 {
  border-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-green-900\/5 {
  border-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-green-900\/50 {
  border-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-green-900\/55 {
  border-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-green-900\/60 {
  border-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-green-900\/65 {
  border-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-green-900\/70 {
  border-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-green-900\/75 {
  border-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-green-900\/80 {
  border-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-green-900\/85 {
  border-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-green-900\/90 {
  border-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-green-900\/95 {
  border-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-green-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-green-950\/0 {
  border-color: rgb(5 46 22 / 0) !important;
}

.tw-border-green-950\/10 {
  border-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-green-950\/100 {
  border-color: rgb(5 46 22 / 1) !important;
}

.tw-border-green-950\/15 {
  border-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-green-950\/20 {
  border-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-green-950\/25 {
  border-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-green-950\/30 {
  border-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-green-950\/35 {
  border-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-green-950\/40 {
  border-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-green-950\/45 {
  border-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-green-950\/5 {
  border-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-green-950\/50 {
  border-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-green-950\/55 {
  border-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-green-950\/60 {
  border-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-green-950\/65 {
  border-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-green-950\/70 {
  border-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-green-950\/75 {
  border-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-green-950\/80 {
  border-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-green-950\/85 {
  border-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-green-950\/90 {
  border-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-green-950\/95 {
  border-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-100\/0 {
  border-color: rgb(224 231 255 / 0) !important;
}

.tw-border-indigo-100\/10 {
  border-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-indigo-100\/100 {
  border-color: rgb(224 231 255 / 1) !important;
}

.tw-border-indigo-100\/15 {
  border-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-indigo-100\/20 {
  border-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-indigo-100\/25 {
  border-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-indigo-100\/30 {
  border-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-indigo-100\/35 {
  border-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-indigo-100\/40 {
  border-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-indigo-100\/45 {
  border-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-indigo-100\/5 {
  border-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-indigo-100\/50 {
  border-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-indigo-100\/55 {
  border-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-indigo-100\/60 {
  border-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-indigo-100\/65 {
  border-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-indigo-100\/70 {
  border-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-indigo-100\/75 {
  border-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-indigo-100\/80 {
  border-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-indigo-100\/85 {
  border-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-indigo-100\/90 {
  border-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-indigo-100\/95 {
  border-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-200\/0 {
  border-color: rgb(199 210 254 / 0) !important;
}

.tw-border-indigo-200\/10 {
  border-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-indigo-200\/100 {
  border-color: rgb(199 210 254 / 1) !important;
}

.tw-border-indigo-200\/15 {
  border-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-indigo-200\/20 {
  border-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-indigo-200\/25 {
  border-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-indigo-200\/30 {
  border-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-indigo-200\/35 {
  border-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-indigo-200\/40 {
  border-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-indigo-200\/45 {
  border-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-indigo-200\/5 {
  border-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-indigo-200\/50 {
  border-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-indigo-200\/55 {
  border-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-indigo-200\/60 {
  border-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-indigo-200\/65 {
  border-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-indigo-200\/70 {
  border-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-indigo-200\/75 {
  border-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-indigo-200\/80 {
  border-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-indigo-200\/85 {
  border-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-indigo-200\/90 {
  border-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-indigo-200\/95 {
  border-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-300\/0 {
  border-color: rgb(165 180 252 / 0) !important;
}

.tw-border-indigo-300\/10 {
  border-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-indigo-300\/100 {
  border-color: rgb(165 180 252 / 1) !important;
}

.tw-border-indigo-300\/15 {
  border-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-indigo-300\/20 {
  border-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-indigo-300\/25 {
  border-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-indigo-300\/30 {
  border-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-indigo-300\/35 {
  border-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-indigo-300\/40 {
  border-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-indigo-300\/45 {
  border-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-indigo-300\/5 {
  border-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-indigo-300\/50 {
  border-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-indigo-300\/55 {
  border-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-indigo-300\/60 {
  border-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-indigo-300\/65 {
  border-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-indigo-300\/70 {
  border-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-indigo-300\/75 {
  border-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-indigo-300\/80 {
  border-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-indigo-300\/85 {
  border-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-indigo-300\/90 {
  border-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-indigo-300\/95 {
  border-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-400\/0 {
  border-color: rgb(129 140 248 / 0) !important;
}

.tw-border-indigo-400\/10 {
  border-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-indigo-400\/100 {
  border-color: rgb(129 140 248 / 1) !important;
}

.tw-border-indigo-400\/15 {
  border-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-indigo-400\/20 {
  border-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-indigo-400\/25 {
  border-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-indigo-400\/30 {
  border-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-indigo-400\/35 {
  border-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-indigo-400\/40 {
  border-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-indigo-400\/45 {
  border-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-indigo-400\/5 {
  border-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-indigo-400\/50 {
  border-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-indigo-400\/55 {
  border-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-indigo-400\/60 {
  border-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-indigo-400\/65 {
  border-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-indigo-400\/70 {
  border-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-indigo-400\/75 {
  border-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-indigo-400\/80 {
  border-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-indigo-400\/85 {
  border-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-indigo-400\/90 {
  border-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-indigo-400\/95 {
  border-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-50\/0 {
  border-color: rgb(238 242 255 / 0) !important;
}

.tw-border-indigo-50\/10 {
  border-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-indigo-50\/100 {
  border-color: rgb(238 242 255 / 1) !important;
}

.tw-border-indigo-50\/15 {
  border-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-indigo-50\/20 {
  border-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-indigo-50\/25 {
  border-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-indigo-50\/30 {
  border-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-indigo-50\/35 {
  border-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-indigo-50\/40 {
  border-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-indigo-50\/45 {
  border-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-indigo-50\/5 {
  border-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-indigo-50\/50 {
  border-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-indigo-50\/55 {
  border-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-indigo-50\/60 {
  border-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-indigo-50\/65 {
  border-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-indigo-50\/70 {
  border-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-indigo-50\/75 {
  border-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-indigo-50\/80 {
  border-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-indigo-50\/85 {
  border-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-indigo-50\/90 {
  border-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-indigo-50\/95 {
  border-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-500\/0 {
  border-color: rgb(99 102 241 / 0) !important;
}

.tw-border-indigo-500\/10 {
  border-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-indigo-500\/100 {
  border-color: rgb(99 102 241 / 1) !important;
}

.tw-border-indigo-500\/15 {
  border-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-indigo-500\/20 {
  border-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-indigo-500\/25 {
  border-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-indigo-500\/30 {
  border-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-indigo-500\/35 {
  border-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-indigo-500\/40 {
  border-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-indigo-500\/45 {
  border-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-indigo-500\/5 {
  border-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-indigo-500\/50 {
  border-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-indigo-500\/55 {
  border-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-indigo-500\/60 {
  border-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-indigo-500\/65 {
  border-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-indigo-500\/70 {
  border-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-indigo-500\/75 {
  border-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-indigo-500\/80 {
  border-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-indigo-500\/85 {
  border-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-indigo-500\/90 {
  border-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-indigo-500\/95 {
  border-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-600\/0 {
  border-color: rgb(79 70 229 / 0) !important;
}

.tw-border-indigo-600\/10 {
  border-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-indigo-600\/100 {
  border-color: rgb(79 70 229 / 1) !important;
}

.tw-border-indigo-600\/15 {
  border-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-indigo-600\/20 {
  border-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-indigo-600\/25 {
  border-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-indigo-600\/30 {
  border-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-indigo-600\/35 {
  border-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-indigo-600\/40 {
  border-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-indigo-600\/45 {
  border-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-indigo-600\/5 {
  border-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-indigo-600\/50 {
  border-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-indigo-600\/55 {
  border-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-indigo-600\/60 {
  border-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-indigo-600\/65 {
  border-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-indigo-600\/70 {
  border-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-indigo-600\/75 {
  border-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-indigo-600\/80 {
  border-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-indigo-600\/85 {
  border-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-indigo-600\/90 {
  border-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-indigo-600\/95 {
  border-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-700\/0 {
  border-color: rgb(67 56 202 / 0) !important;
}

.tw-border-indigo-700\/10 {
  border-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-indigo-700\/100 {
  border-color: rgb(67 56 202 / 1) !important;
}

.tw-border-indigo-700\/15 {
  border-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-indigo-700\/20 {
  border-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-indigo-700\/25 {
  border-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-indigo-700\/30 {
  border-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-indigo-700\/35 {
  border-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-indigo-700\/40 {
  border-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-indigo-700\/45 {
  border-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-indigo-700\/5 {
  border-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-indigo-700\/50 {
  border-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-indigo-700\/55 {
  border-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-indigo-700\/60 {
  border-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-indigo-700\/65 {
  border-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-indigo-700\/70 {
  border-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-indigo-700\/75 {
  border-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-indigo-700\/80 {
  border-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-indigo-700\/85 {
  border-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-indigo-700\/90 {
  border-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-indigo-700\/95 {
  border-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-800\/0 {
  border-color: rgb(55 48 163 / 0) !important;
}

.tw-border-indigo-800\/10 {
  border-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-indigo-800\/100 {
  border-color: rgb(55 48 163 / 1) !important;
}

.tw-border-indigo-800\/15 {
  border-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-indigo-800\/20 {
  border-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-indigo-800\/25 {
  border-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-indigo-800\/30 {
  border-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-indigo-800\/35 {
  border-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-indigo-800\/40 {
  border-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-indigo-800\/45 {
  border-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-indigo-800\/5 {
  border-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-indigo-800\/50 {
  border-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-indigo-800\/55 {
  border-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-indigo-800\/60 {
  border-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-indigo-800\/65 {
  border-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-indigo-800\/70 {
  border-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-indigo-800\/75 {
  border-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-indigo-800\/80 {
  border-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-indigo-800\/85 {
  border-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-indigo-800\/90 {
  border-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-indigo-800\/95 {
  border-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-900\/0 {
  border-color: rgb(49 46 129 / 0) !important;
}

.tw-border-indigo-900\/10 {
  border-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-indigo-900\/100 {
  border-color: rgb(49 46 129 / 1) !important;
}

.tw-border-indigo-900\/15 {
  border-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-indigo-900\/20 {
  border-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-indigo-900\/25 {
  border-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-indigo-900\/30 {
  border-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-indigo-900\/35 {
  border-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-indigo-900\/40 {
  border-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-indigo-900\/45 {
  border-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-indigo-900\/5 {
  border-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-indigo-900\/50 {
  border-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-indigo-900\/55 {
  border-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-indigo-900\/60 {
  border-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-indigo-900\/65 {
  border-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-indigo-900\/70 {
  border-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-indigo-900\/75 {
  border-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-indigo-900\/80 {
  border-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-indigo-900\/85 {
  border-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-indigo-900\/90 {
  border-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-indigo-900\/95 {
  border-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-indigo-950\/0 {
  border-color: rgb(30 27 75 / 0) !important;
}

.tw-border-indigo-950\/10 {
  border-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-indigo-950\/100 {
  border-color: rgb(30 27 75 / 1) !important;
}

.tw-border-indigo-950\/15 {
  border-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-indigo-950\/20 {
  border-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-indigo-950\/25 {
  border-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-indigo-950\/30 {
  border-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-indigo-950\/35 {
  border-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-indigo-950\/40 {
  border-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-indigo-950\/45 {
  border-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-indigo-950\/5 {
  border-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-indigo-950\/50 {
  border-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-indigo-950\/55 {
  border-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-indigo-950\/60 {
  border-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-indigo-950\/65 {
  border-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-indigo-950\/70 {
  border-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-indigo-950\/75 {
  border-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-indigo-950\/80 {
  border-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-indigo-950\/85 {
  border-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-indigo-950\/90 {
  border-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-indigo-950\/95 {
  border-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-inherit {
  border-color: inherit !important;
}

.tw-border-lime-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-100\/0 {
  border-color: rgb(236 252 203 / 0) !important;
}

.tw-border-lime-100\/10 {
  border-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-lime-100\/100 {
  border-color: rgb(236 252 203 / 1) !important;
}

.tw-border-lime-100\/15 {
  border-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-lime-100\/20 {
  border-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-lime-100\/25 {
  border-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-lime-100\/30 {
  border-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-lime-100\/35 {
  border-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-lime-100\/40 {
  border-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-lime-100\/45 {
  border-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-lime-100\/5 {
  border-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-lime-100\/50 {
  border-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-lime-100\/55 {
  border-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-lime-100\/60 {
  border-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-lime-100\/65 {
  border-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-lime-100\/70 {
  border-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-lime-100\/75 {
  border-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-lime-100\/80 {
  border-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-lime-100\/85 {
  border-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-lime-100\/90 {
  border-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-lime-100\/95 {
  border-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-lime-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-200\/0 {
  border-color: rgb(217 249 157 / 0) !important;
}

.tw-border-lime-200\/10 {
  border-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-lime-200\/100 {
  border-color: rgb(217 249 157 / 1) !important;
}

.tw-border-lime-200\/15 {
  border-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-lime-200\/20 {
  border-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-lime-200\/25 {
  border-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-lime-200\/30 {
  border-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-lime-200\/35 {
  border-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-lime-200\/40 {
  border-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-lime-200\/45 {
  border-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-lime-200\/5 {
  border-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-lime-200\/50 {
  border-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-lime-200\/55 {
  border-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-lime-200\/60 {
  border-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-lime-200\/65 {
  border-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-lime-200\/70 {
  border-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-lime-200\/75 {
  border-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-lime-200\/80 {
  border-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-lime-200\/85 {
  border-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-lime-200\/90 {
  border-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-lime-200\/95 {
  border-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-lime-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-300\/0 {
  border-color: rgb(190 242 100 / 0) !important;
}

.tw-border-lime-300\/10 {
  border-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-lime-300\/100 {
  border-color: rgb(190 242 100 / 1) !important;
}

.tw-border-lime-300\/15 {
  border-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-lime-300\/20 {
  border-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-lime-300\/25 {
  border-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-lime-300\/30 {
  border-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-lime-300\/35 {
  border-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-lime-300\/40 {
  border-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-lime-300\/45 {
  border-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-lime-300\/5 {
  border-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-lime-300\/50 {
  border-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-lime-300\/55 {
  border-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-lime-300\/60 {
  border-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-lime-300\/65 {
  border-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-lime-300\/70 {
  border-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-lime-300\/75 {
  border-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-lime-300\/80 {
  border-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-lime-300\/85 {
  border-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-lime-300\/90 {
  border-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-lime-300\/95 {
  border-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-lime-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-400\/0 {
  border-color: rgb(163 230 53 / 0) !important;
}

.tw-border-lime-400\/10 {
  border-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-lime-400\/100 {
  border-color: rgb(163 230 53 / 1) !important;
}

.tw-border-lime-400\/15 {
  border-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-lime-400\/20 {
  border-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-lime-400\/25 {
  border-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-lime-400\/30 {
  border-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-lime-400\/35 {
  border-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-lime-400\/40 {
  border-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-lime-400\/45 {
  border-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-lime-400\/5 {
  border-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-lime-400\/50 {
  border-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-lime-400\/55 {
  border-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-lime-400\/60 {
  border-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-lime-400\/65 {
  border-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-lime-400\/70 {
  border-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-lime-400\/75 {
  border-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-lime-400\/80 {
  border-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-lime-400\/85 {
  border-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-lime-400\/90 {
  border-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-lime-400\/95 {
  border-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-lime-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-50\/0 {
  border-color: rgb(247 254 231 / 0) !important;
}

.tw-border-lime-50\/10 {
  border-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-lime-50\/100 {
  border-color: rgb(247 254 231 / 1) !important;
}

.tw-border-lime-50\/15 {
  border-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-lime-50\/20 {
  border-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-lime-50\/25 {
  border-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-lime-50\/30 {
  border-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-lime-50\/35 {
  border-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-lime-50\/40 {
  border-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-lime-50\/45 {
  border-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-lime-50\/5 {
  border-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-lime-50\/50 {
  border-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-lime-50\/55 {
  border-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-lime-50\/60 {
  border-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-lime-50\/65 {
  border-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-lime-50\/70 {
  border-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-lime-50\/75 {
  border-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-lime-50\/80 {
  border-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-lime-50\/85 {
  border-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-lime-50\/90 {
  border-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-lime-50\/95 {
  border-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-lime-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-500\/0 {
  border-color: rgb(132 204 22 / 0) !important;
}

.tw-border-lime-500\/10 {
  border-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-lime-500\/100 {
  border-color: rgb(132 204 22 / 1) !important;
}

.tw-border-lime-500\/15 {
  border-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-lime-500\/20 {
  border-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-lime-500\/25 {
  border-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-lime-500\/30 {
  border-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-lime-500\/35 {
  border-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-lime-500\/40 {
  border-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-lime-500\/45 {
  border-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-lime-500\/5 {
  border-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-lime-500\/50 {
  border-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-lime-500\/55 {
  border-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-lime-500\/60 {
  border-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-lime-500\/65 {
  border-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-lime-500\/70 {
  border-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-lime-500\/75 {
  border-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-lime-500\/80 {
  border-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-lime-500\/85 {
  border-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-lime-500\/90 {
  border-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-lime-500\/95 {
  border-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-lime-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-600\/0 {
  border-color: rgb(101 163 13 / 0) !important;
}

.tw-border-lime-600\/10 {
  border-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-lime-600\/100 {
  border-color: rgb(101 163 13 / 1) !important;
}

.tw-border-lime-600\/15 {
  border-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-lime-600\/20 {
  border-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-lime-600\/25 {
  border-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-lime-600\/30 {
  border-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-lime-600\/35 {
  border-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-lime-600\/40 {
  border-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-lime-600\/45 {
  border-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-lime-600\/5 {
  border-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-lime-600\/50 {
  border-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-lime-600\/55 {
  border-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-lime-600\/60 {
  border-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-lime-600\/65 {
  border-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-lime-600\/70 {
  border-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-lime-600\/75 {
  border-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-lime-600\/80 {
  border-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-lime-600\/85 {
  border-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-lime-600\/90 {
  border-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-lime-600\/95 {
  border-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-lime-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-700\/0 {
  border-color: rgb(77 124 15 / 0) !important;
}

.tw-border-lime-700\/10 {
  border-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-lime-700\/100 {
  border-color: rgb(77 124 15 / 1) !important;
}

.tw-border-lime-700\/15 {
  border-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-lime-700\/20 {
  border-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-lime-700\/25 {
  border-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-lime-700\/30 {
  border-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-lime-700\/35 {
  border-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-lime-700\/40 {
  border-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-lime-700\/45 {
  border-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-lime-700\/5 {
  border-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-lime-700\/50 {
  border-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-lime-700\/55 {
  border-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-lime-700\/60 {
  border-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-lime-700\/65 {
  border-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-lime-700\/70 {
  border-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-lime-700\/75 {
  border-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-lime-700\/80 {
  border-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-lime-700\/85 {
  border-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-lime-700\/90 {
  border-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-lime-700\/95 {
  border-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-lime-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-800\/0 {
  border-color: rgb(63 98 18 / 0) !important;
}

.tw-border-lime-800\/10 {
  border-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-lime-800\/100 {
  border-color: rgb(63 98 18 / 1) !important;
}

.tw-border-lime-800\/15 {
  border-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-lime-800\/20 {
  border-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-lime-800\/25 {
  border-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-lime-800\/30 {
  border-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-lime-800\/35 {
  border-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-lime-800\/40 {
  border-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-lime-800\/45 {
  border-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-lime-800\/5 {
  border-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-lime-800\/50 {
  border-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-lime-800\/55 {
  border-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-lime-800\/60 {
  border-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-lime-800\/65 {
  border-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-lime-800\/70 {
  border-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-lime-800\/75 {
  border-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-lime-800\/80 {
  border-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-lime-800\/85 {
  border-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-lime-800\/90 {
  border-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-lime-800\/95 {
  border-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-lime-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-900\/0 {
  border-color: rgb(54 83 20 / 0) !important;
}

.tw-border-lime-900\/10 {
  border-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-lime-900\/100 {
  border-color: rgb(54 83 20 / 1) !important;
}

.tw-border-lime-900\/15 {
  border-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-lime-900\/20 {
  border-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-lime-900\/25 {
  border-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-lime-900\/30 {
  border-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-lime-900\/35 {
  border-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-lime-900\/40 {
  border-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-lime-900\/45 {
  border-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-lime-900\/5 {
  border-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-lime-900\/50 {
  border-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-lime-900\/55 {
  border-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-lime-900\/60 {
  border-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-lime-900\/65 {
  border-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-lime-900\/70 {
  border-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-lime-900\/75 {
  border-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-lime-900\/80 {
  border-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-lime-900\/85 {
  border-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-lime-900\/90 {
  border-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-lime-900\/95 {
  border-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-lime-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-lime-950\/0 {
  border-color: rgb(26 46 5 / 0) !important;
}

.tw-border-lime-950\/10 {
  border-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-lime-950\/100 {
  border-color: rgb(26 46 5 / 1) !important;
}

.tw-border-lime-950\/15 {
  border-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-lime-950\/20 {
  border-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-lime-950\/25 {
  border-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-lime-950\/30 {
  border-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-lime-950\/35 {
  border-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-lime-950\/40 {
  border-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-lime-950\/45 {
  border-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-lime-950\/5 {
  border-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-lime-950\/50 {
  border-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-lime-950\/55 {
  border-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-lime-950\/60 {
  border-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-lime-950\/65 {
  border-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-lime-950\/70 {
  border-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-lime-950\/75 {
  border-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-lime-950\/80 {
  border-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-lime-950\/85 {
  border-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-lime-950\/90 {
  border-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-lime-950\/95 {
  border-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-100\/0 {
  border-color: rgb(245 245 245 / 0) !important;
}

.tw-border-neutral-100\/10 {
  border-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-neutral-100\/100 {
  border-color: rgb(245 245 245 / 1) !important;
}

.tw-border-neutral-100\/15 {
  border-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-neutral-100\/20 {
  border-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-neutral-100\/25 {
  border-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-neutral-100\/30 {
  border-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-neutral-100\/35 {
  border-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-neutral-100\/40 {
  border-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-neutral-100\/45 {
  border-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-neutral-100\/5 {
  border-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-neutral-100\/50 {
  border-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-neutral-100\/55 {
  border-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-neutral-100\/60 {
  border-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-neutral-100\/65 {
  border-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-neutral-100\/70 {
  border-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-neutral-100\/75 {
  border-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-neutral-100\/80 {
  border-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-neutral-100\/85 {
  border-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-neutral-100\/90 {
  border-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-neutral-100\/95 {
  border-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-200\/0 {
  border-color: rgb(229 229 229 / 0) !important;
}

.tw-border-neutral-200\/10 {
  border-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-neutral-200\/100 {
  border-color: rgb(229 229 229 / 1) !important;
}

.tw-border-neutral-200\/15 {
  border-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-neutral-200\/20 {
  border-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-neutral-200\/25 {
  border-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-neutral-200\/30 {
  border-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-neutral-200\/35 {
  border-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-neutral-200\/40 {
  border-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-neutral-200\/45 {
  border-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-neutral-200\/5 {
  border-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-neutral-200\/50 {
  border-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-neutral-200\/55 {
  border-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-neutral-200\/60 {
  border-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-neutral-200\/65 {
  border-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-neutral-200\/70 {
  border-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-neutral-200\/75 {
  border-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-neutral-200\/80 {
  border-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-neutral-200\/85 {
  border-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-neutral-200\/90 {
  border-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-neutral-200\/95 {
  border-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-300\/0 {
  border-color: rgb(212 212 212 / 0) !important;
}

.tw-border-neutral-300\/10 {
  border-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-neutral-300\/100 {
  border-color: rgb(212 212 212 / 1) !important;
}

.tw-border-neutral-300\/15 {
  border-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-neutral-300\/20 {
  border-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-neutral-300\/25 {
  border-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-neutral-300\/30 {
  border-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-neutral-300\/35 {
  border-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-neutral-300\/40 {
  border-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-neutral-300\/45 {
  border-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-neutral-300\/5 {
  border-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-neutral-300\/50 {
  border-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-neutral-300\/55 {
  border-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-neutral-300\/60 {
  border-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-neutral-300\/65 {
  border-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-neutral-300\/70 {
  border-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-neutral-300\/75 {
  border-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-neutral-300\/80 {
  border-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-neutral-300\/85 {
  border-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-neutral-300\/90 {
  border-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-neutral-300\/95 {
  border-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-400\/0 {
  border-color: rgb(163 163 163 / 0) !important;
}

.tw-border-neutral-400\/10 {
  border-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-neutral-400\/100 {
  border-color: rgb(163 163 163 / 1) !important;
}

.tw-border-neutral-400\/15 {
  border-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-neutral-400\/20 {
  border-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-neutral-400\/25 {
  border-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-neutral-400\/30 {
  border-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-neutral-400\/35 {
  border-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-neutral-400\/40 {
  border-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-neutral-400\/45 {
  border-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-neutral-400\/5 {
  border-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-neutral-400\/50 {
  border-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-neutral-400\/55 {
  border-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-neutral-400\/60 {
  border-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-neutral-400\/65 {
  border-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-neutral-400\/70 {
  border-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-neutral-400\/75 {
  border-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-neutral-400\/80 {
  border-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-neutral-400\/85 {
  border-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-neutral-400\/90 {
  border-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-neutral-400\/95 {
  border-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-50\/0 {
  border-color: rgb(250 250 250 / 0) !important;
}

.tw-border-neutral-50\/10 {
  border-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-neutral-50\/100 {
  border-color: rgb(250 250 250 / 1) !important;
}

.tw-border-neutral-50\/15 {
  border-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-neutral-50\/20 {
  border-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-neutral-50\/25 {
  border-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-neutral-50\/30 {
  border-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-neutral-50\/35 {
  border-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-neutral-50\/40 {
  border-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-neutral-50\/45 {
  border-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-neutral-50\/5 {
  border-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-neutral-50\/50 {
  border-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-neutral-50\/55 {
  border-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-neutral-50\/60 {
  border-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-neutral-50\/65 {
  border-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-neutral-50\/70 {
  border-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-neutral-50\/75 {
  border-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-neutral-50\/80 {
  border-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-neutral-50\/85 {
  border-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-neutral-50\/90 {
  border-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-neutral-50\/95 {
  border-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-500\/0 {
  border-color: rgb(115 115 115 / 0) !important;
}

.tw-border-neutral-500\/10 {
  border-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-neutral-500\/100 {
  border-color: rgb(115 115 115 / 1) !important;
}

.tw-border-neutral-500\/15 {
  border-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-neutral-500\/20 {
  border-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-neutral-500\/25 {
  border-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-neutral-500\/30 {
  border-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-neutral-500\/35 {
  border-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-neutral-500\/40 {
  border-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-neutral-500\/45 {
  border-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-neutral-500\/5 {
  border-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-neutral-500\/50 {
  border-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-neutral-500\/55 {
  border-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-neutral-500\/60 {
  border-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-neutral-500\/65 {
  border-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-neutral-500\/70 {
  border-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-neutral-500\/75 {
  border-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-neutral-500\/80 {
  border-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-neutral-500\/85 {
  border-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-neutral-500\/90 {
  border-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-neutral-500\/95 {
  border-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-600\/0 {
  border-color: rgb(82 82 82 / 0) !important;
}

.tw-border-neutral-600\/10 {
  border-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-neutral-600\/100 {
  border-color: rgb(82 82 82 / 1) !important;
}

.tw-border-neutral-600\/15 {
  border-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-neutral-600\/20 {
  border-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-neutral-600\/25 {
  border-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-neutral-600\/30 {
  border-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-neutral-600\/35 {
  border-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-neutral-600\/40 {
  border-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-neutral-600\/45 {
  border-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-neutral-600\/5 {
  border-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-neutral-600\/50 {
  border-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-neutral-600\/55 {
  border-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-neutral-600\/60 {
  border-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-neutral-600\/65 {
  border-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-neutral-600\/70 {
  border-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-neutral-600\/75 {
  border-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-neutral-600\/80 {
  border-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-neutral-600\/85 {
  border-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-neutral-600\/90 {
  border-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-neutral-600\/95 {
  border-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-700\/0 {
  border-color: rgb(64 64 64 / 0) !important;
}

.tw-border-neutral-700\/10 {
  border-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-neutral-700\/100 {
  border-color: rgb(64 64 64 / 1) !important;
}

.tw-border-neutral-700\/15 {
  border-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-neutral-700\/20 {
  border-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-neutral-700\/25 {
  border-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-neutral-700\/30 {
  border-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-neutral-700\/35 {
  border-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-neutral-700\/40 {
  border-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-neutral-700\/45 {
  border-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-neutral-700\/5 {
  border-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-neutral-700\/50 {
  border-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-neutral-700\/55 {
  border-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-neutral-700\/60 {
  border-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-neutral-700\/65 {
  border-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-neutral-700\/70 {
  border-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-neutral-700\/75 {
  border-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-neutral-700\/80 {
  border-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-neutral-700\/85 {
  border-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-neutral-700\/90 {
  border-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-neutral-700\/95 {
  border-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-800\/0 {
  border-color: rgb(38 38 38 / 0) !important;
}

.tw-border-neutral-800\/10 {
  border-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-neutral-800\/100 {
  border-color: rgb(38 38 38 / 1) !important;
}

.tw-border-neutral-800\/15 {
  border-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-neutral-800\/20 {
  border-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-neutral-800\/25 {
  border-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-neutral-800\/30 {
  border-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-neutral-800\/35 {
  border-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-neutral-800\/40 {
  border-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-neutral-800\/45 {
  border-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-neutral-800\/5 {
  border-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-neutral-800\/50 {
  border-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-neutral-800\/55 {
  border-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-neutral-800\/60 {
  border-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-neutral-800\/65 {
  border-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-neutral-800\/70 {
  border-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-neutral-800\/75 {
  border-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-neutral-800\/80 {
  border-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-neutral-800\/85 {
  border-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-neutral-800\/90 {
  border-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-neutral-800\/95 {
  border-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-900\/0 {
  border-color: rgb(23 23 23 / 0) !important;
}

.tw-border-neutral-900\/10 {
  border-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-neutral-900\/100 {
  border-color: rgb(23 23 23 / 1) !important;
}

.tw-border-neutral-900\/15 {
  border-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-neutral-900\/20 {
  border-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-neutral-900\/25 {
  border-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-neutral-900\/30 {
  border-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-neutral-900\/35 {
  border-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-neutral-900\/40 {
  border-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-neutral-900\/45 {
  border-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-neutral-900\/5 {
  border-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-neutral-900\/50 {
  border-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-neutral-900\/55 {
  border-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-neutral-900\/60 {
  border-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-neutral-900\/65 {
  border-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-neutral-900\/70 {
  border-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-neutral-900\/75 {
  border-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-neutral-900\/80 {
  border-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-neutral-900\/85 {
  border-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-neutral-900\/90 {
  border-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-neutral-900\/95 {
  border-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-neutral-950\/0 {
  border-color: rgb(10 10 10 / 0) !important;
}

.tw-border-neutral-950\/10 {
  border-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-neutral-950\/100 {
  border-color: rgb(10 10 10 / 1) !important;
}

.tw-border-neutral-950\/15 {
  border-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-neutral-950\/20 {
  border-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-neutral-950\/25 {
  border-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-neutral-950\/30 {
  border-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-neutral-950\/35 {
  border-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-neutral-950\/40 {
  border-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-neutral-950\/45 {
  border-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-neutral-950\/5 {
  border-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-neutral-950\/50 {
  border-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-neutral-950\/55 {
  border-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-neutral-950\/60 {
  border-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-neutral-950\/65 {
  border-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-neutral-950\/70 {
  border-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-neutral-950\/75 {
  border-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-neutral-950\/80 {
  border-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-neutral-950\/85 {
  border-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-neutral-950\/90 {
  border-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-neutral-950\/95 {
  border-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-orange-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-100\/0 {
  border-color: rgb(255 237 213 / 0) !important;
}

.tw-border-orange-100\/10 {
  border-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-orange-100\/100 {
  border-color: rgb(255 237 213 / 1) !important;
}

.tw-border-orange-100\/15 {
  border-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-orange-100\/20 {
  border-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-orange-100\/25 {
  border-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-orange-100\/30 {
  border-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-orange-100\/35 {
  border-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-orange-100\/40 {
  border-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-orange-100\/45 {
  border-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-orange-100\/5 {
  border-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-orange-100\/50 {
  border-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-orange-100\/55 {
  border-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-orange-100\/60 {
  border-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-orange-100\/65 {
  border-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-orange-100\/70 {
  border-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-orange-100\/75 {
  border-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-orange-100\/80 {
  border-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-orange-100\/85 {
  border-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-orange-100\/90 {
  border-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-orange-100\/95 {
  border-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-orange-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-200\/0 {
  border-color: rgb(254 215 170 / 0) !important;
}

.tw-border-orange-200\/10 {
  border-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-orange-200\/100 {
  border-color: rgb(254 215 170 / 1) !important;
}

.tw-border-orange-200\/15 {
  border-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-orange-200\/20 {
  border-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-orange-200\/25 {
  border-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-orange-200\/30 {
  border-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-orange-200\/35 {
  border-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-orange-200\/40 {
  border-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-orange-200\/45 {
  border-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-orange-200\/5 {
  border-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-orange-200\/50 {
  border-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-orange-200\/55 {
  border-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-orange-200\/60 {
  border-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-orange-200\/65 {
  border-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-orange-200\/70 {
  border-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-orange-200\/75 {
  border-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-orange-200\/80 {
  border-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-orange-200\/85 {
  border-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-orange-200\/90 {
  border-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-orange-200\/95 {
  border-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-orange-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-300\/0 {
  border-color: rgb(253 186 116 / 0) !important;
}

.tw-border-orange-300\/10 {
  border-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-orange-300\/100 {
  border-color: rgb(253 186 116 / 1) !important;
}

.tw-border-orange-300\/15 {
  border-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-orange-300\/20 {
  border-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-orange-300\/25 {
  border-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-orange-300\/30 {
  border-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-orange-300\/35 {
  border-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-orange-300\/40 {
  border-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-orange-300\/45 {
  border-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-orange-300\/5 {
  border-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-orange-300\/50 {
  border-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-orange-300\/55 {
  border-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-orange-300\/60 {
  border-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-orange-300\/65 {
  border-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-orange-300\/70 {
  border-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-orange-300\/75 {
  border-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-orange-300\/80 {
  border-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-orange-300\/85 {
  border-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-orange-300\/90 {
  border-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-orange-300\/95 {
  border-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-orange-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-400\/0 {
  border-color: rgb(251 146 60 / 0) !important;
}

.tw-border-orange-400\/10 {
  border-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-orange-400\/100 {
  border-color: rgb(251 146 60 / 1) !important;
}

.tw-border-orange-400\/15 {
  border-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-orange-400\/20 {
  border-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-orange-400\/25 {
  border-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-orange-400\/30 {
  border-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-orange-400\/35 {
  border-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-orange-400\/40 {
  border-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-orange-400\/45 {
  border-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-orange-400\/5 {
  border-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-orange-400\/50 {
  border-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-orange-400\/55 {
  border-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-orange-400\/60 {
  border-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-orange-400\/65 {
  border-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-orange-400\/70 {
  border-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-orange-400\/75 {
  border-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-orange-400\/80 {
  border-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-orange-400\/85 {
  border-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-orange-400\/90 {
  border-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-orange-400\/95 {
  border-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-orange-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-50\/0 {
  border-color: rgb(255 247 237 / 0) !important;
}

.tw-border-orange-50\/10 {
  border-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-orange-50\/100 {
  border-color: rgb(255 247 237 / 1) !important;
}

.tw-border-orange-50\/15 {
  border-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-orange-50\/20 {
  border-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-orange-50\/25 {
  border-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-orange-50\/30 {
  border-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-orange-50\/35 {
  border-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-orange-50\/40 {
  border-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-orange-50\/45 {
  border-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-orange-50\/5 {
  border-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-orange-50\/50 {
  border-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-orange-50\/55 {
  border-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-orange-50\/60 {
  border-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-orange-50\/65 {
  border-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-orange-50\/70 {
  border-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-orange-50\/75 {
  border-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-orange-50\/80 {
  border-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-orange-50\/85 {
  border-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-orange-50\/90 {
  border-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-orange-50\/95 {
  border-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-orange-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-500\/0 {
  border-color: rgb(249 115 22 / 0) !important;
}

.tw-border-orange-500\/10 {
  border-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-orange-500\/100 {
  border-color: rgb(249 115 22 / 1) !important;
}

.tw-border-orange-500\/15 {
  border-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-orange-500\/20 {
  border-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-orange-500\/25 {
  border-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-orange-500\/30 {
  border-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-orange-500\/35 {
  border-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-orange-500\/40 {
  border-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-orange-500\/45 {
  border-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-orange-500\/5 {
  border-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-orange-500\/50 {
  border-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-orange-500\/55 {
  border-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-orange-500\/60 {
  border-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-orange-500\/65 {
  border-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-orange-500\/70 {
  border-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-orange-500\/75 {
  border-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-orange-500\/80 {
  border-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-orange-500\/85 {
  border-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-orange-500\/90 {
  border-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-orange-500\/95 {
  border-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-orange-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-600\/0 {
  border-color: rgb(234 88 12 / 0) !important;
}

.tw-border-orange-600\/10 {
  border-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-orange-600\/100 {
  border-color: rgb(234 88 12 / 1) !important;
}

.tw-border-orange-600\/15 {
  border-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-orange-600\/20 {
  border-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-orange-600\/25 {
  border-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-orange-600\/30 {
  border-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-orange-600\/35 {
  border-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-orange-600\/40 {
  border-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-orange-600\/45 {
  border-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-orange-600\/5 {
  border-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-orange-600\/50 {
  border-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-orange-600\/55 {
  border-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-orange-600\/60 {
  border-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-orange-600\/65 {
  border-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-orange-600\/70 {
  border-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-orange-600\/75 {
  border-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-orange-600\/80 {
  border-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-orange-600\/85 {
  border-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-orange-600\/90 {
  border-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-orange-600\/95 {
  border-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-orange-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-700\/0 {
  border-color: rgb(194 65 12 / 0) !important;
}

.tw-border-orange-700\/10 {
  border-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-orange-700\/100 {
  border-color: rgb(194 65 12 / 1) !important;
}

.tw-border-orange-700\/15 {
  border-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-orange-700\/20 {
  border-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-orange-700\/25 {
  border-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-orange-700\/30 {
  border-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-orange-700\/35 {
  border-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-orange-700\/40 {
  border-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-orange-700\/45 {
  border-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-orange-700\/5 {
  border-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-orange-700\/50 {
  border-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-orange-700\/55 {
  border-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-orange-700\/60 {
  border-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-orange-700\/65 {
  border-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-orange-700\/70 {
  border-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-orange-700\/75 {
  border-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-orange-700\/80 {
  border-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-orange-700\/85 {
  border-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-orange-700\/90 {
  border-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-orange-700\/95 {
  border-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-orange-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-800\/0 {
  border-color: rgb(154 52 18 / 0) !important;
}

.tw-border-orange-800\/10 {
  border-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-orange-800\/100 {
  border-color: rgb(154 52 18 / 1) !important;
}

.tw-border-orange-800\/15 {
  border-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-orange-800\/20 {
  border-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-orange-800\/25 {
  border-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-orange-800\/30 {
  border-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-orange-800\/35 {
  border-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-orange-800\/40 {
  border-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-orange-800\/45 {
  border-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-orange-800\/5 {
  border-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-orange-800\/50 {
  border-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-orange-800\/55 {
  border-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-orange-800\/60 {
  border-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-orange-800\/65 {
  border-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-orange-800\/70 {
  border-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-orange-800\/75 {
  border-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-orange-800\/80 {
  border-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-orange-800\/85 {
  border-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-orange-800\/90 {
  border-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-orange-800\/95 {
  border-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-orange-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-900\/0 {
  border-color: rgb(124 45 18 / 0) !important;
}

.tw-border-orange-900\/10 {
  border-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-orange-900\/100 {
  border-color: rgb(124 45 18 / 1) !important;
}

.tw-border-orange-900\/15 {
  border-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-orange-900\/20 {
  border-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-orange-900\/25 {
  border-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-orange-900\/30 {
  border-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-orange-900\/35 {
  border-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-orange-900\/40 {
  border-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-orange-900\/45 {
  border-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-orange-900\/5 {
  border-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-orange-900\/50 {
  border-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-orange-900\/55 {
  border-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-orange-900\/60 {
  border-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-orange-900\/65 {
  border-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-orange-900\/70 {
  border-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-orange-900\/75 {
  border-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-orange-900\/80 {
  border-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-orange-900\/85 {
  border-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-orange-900\/90 {
  border-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-orange-900\/95 {
  border-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-orange-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-orange-950\/0 {
  border-color: rgb(67 20 7 / 0) !important;
}

.tw-border-orange-950\/10 {
  border-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-orange-950\/100 {
  border-color: rgb(67 20 7 / 1) !important;
}

.tw-border-orange-950\/15 {
  border-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-orange-950\/20 {
  border-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-orange-950\/25 {
  border-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-orange-950\/30 {
  border-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-orange-950\/35 {
  border-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-orange-950\/40 {
  border-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-orange-950\/45 {
  border-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-orange-950\/5 {
  border-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-orange-950\/50 {
  border-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-orange-950\/55 {
  border-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-orange-950\/60 {
  border-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-orange-950\/65 {
  border-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-orange-950\/70 {
  border-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-orange-950\/75 {
  border-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-orange-950\/80 {
  border-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-orange-950\/85 {
  border-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-orange-950\/90 {
  border-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-orange-950\/95 {
  border-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-pink-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-100\/0 {
  border-color: rgb(252 231 243 / 0) !important;
}

.tw-border-pink-100\/10 {
  border-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-pink-100\/100 {
  border-color: rgb(252 231 243 / 1) !important;
}

.tw-border-pink-100\/15 {
  border-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-pink-100\/20 {
  border-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-pink-100\/25 {
  border-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-pink-100\/30 {
  border-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-pink-100\/35 {
  border-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-pink-100\/40 {
  border-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-pink-100\/45 {
  border-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-pink-100\/5 {
  border-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-pink-100\/50 {
  border-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-pink-100\/55 {
  border-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-pink-100\/60 {
  border-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-pink-100\/65 {
  border-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-pink-100\/70 {
  border-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-pink-100\/75 {
  border-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-pink-100\/80 {
  border-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-pink-100\/85 {
  border-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-pink-100\/90 {
  border-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-pink-100\/95 {
  border-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-pink-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-200\/0 {
  border-color: rgb(251 207 232 / 0) !important;
}

.tw-border-pink-200\/10 {
  border-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-pink-200\/100 {
  border-color: rgb(251 207 232 / 1) !important;
}

.tw-border-pink-200\/15 {
  border-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-pink-200\/20 {
  border-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-pink-200\/25 {
  border-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-pink-200\/30 {
  border-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-pink-200\/35 {
  border-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-pink-200\/40 {
  border-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-pink-200\/45 {
  border-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-pink-200\/5 {
  border-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-pink-200\/50 {
  border-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-pink-200\/55 {
  border-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-pink-200\/60 {
  border-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-pink-200\/65 {
  border-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-pink-200\/70 {
  border-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-pink-200\/75 {
  border-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-pink-200\/80 {
  border-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-pink-200\/85 {
  border-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-pink-200\/90 {
  border-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-pink-200\/95 {
  border-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-pink-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-300\/0 {
  border-color: rgb(249 168 212 / 0) !important;
}

.tw-border-pink-300\/10 {
  border-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-pink-300\/100 {
  border-color: rgb(249 168 212 / 1) !important;
}

.tw-border-pink-300\/15 {
  border-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-pink-300\/20 {
  border-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-pink-300\/25 {
  border-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-pink-300\/30 {
  border-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-pink-300\/35 {
  border-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-pink-300\/40 {
  border-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-pink-300\/45 {
  border-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-pink-300\/5 {
  border-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-pink-300\/50 {
  border-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-pink-300\/55 {
  border-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-pink-300\/60 {
  border-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-pink-300\/65 {
  border-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-pink-300\/70 {
  border-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-pink-300\/75 {
  border-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-pink-300\/80 {
  border-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-pink-300\/85 {
  border-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-pink-300\/90 {
  border-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-pink-300\/95 {
  border-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-pink-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-400\/0 {
  border-color: rgb(244 114 182 / 0) !important;
}

.tw-border-pink-400\/10 {
  border-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-pink-400\/100 {
  border-color: rgb(244 114 182 / 1) !important;
}

.tw-border-pink-400\/15 {
  border-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-pink-400\/20 {
  border-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-pink-400\/25 {
  border-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-pink-400\/30 {
  border-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-pink-400\/35 {
  border-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-pink-400\/40 {
  border-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-pink-400\/45 {
  border-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-pink-400\/5 {
  border-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-pink-400\/50 {
  border-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-pink-400\/55 {
  border-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-pink-400\/60 {
  border-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-pink-400\/65 {
  border-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-pink-400\/70 {
  border-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-pink-400\/75 {
  border-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-pink-400\/80 {
  border-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-pink-400\/85 {
  border-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-pink-400\/90 {
  border-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-pink-400\/95 {
  border-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-pink-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-50\/0 {
  border-color: rgb(253 242 248 / 0) !important;
}

.tw-border-pink-50\/10 {
  border-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-pink-50\/100 {
  border-color: rgb(253 242 248 / 1) !important;
}

.tw-border-pink-50\/15 {
  border-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-pink-50\/20 {
  border-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-pink-50\/25 {
  border-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-pink-50\/30 {
  border-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-pink-50\/35 {
  border-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-pink-50\/40 {
  border-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-pink-50\/45 {
  border-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-pink-50\/5 {
  border-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-pink-50\/50 {
  border-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-pink-50\/55 {
  border-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-pink-50\/60 {
  border-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-pink-50\/65 {
  border-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-pink-50\/70 {
  border-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-pink-50\/75 {
  border-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-pink-50\/80 {
  border-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-pink-50\/85 {
  border-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-pink-50\/90 {
  border-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-pink-50\/95 {
  border-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-pink-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-500\/0 {
  border-color: rgb(236 72 153 / 0) !important;
}

.tw-border-pink-500\/10 {
  border-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-pink-500\/100 {
  border-color: rgb(236 72 153 / 1) !important;
}

.tw-border-pink-500\/15 {
  border-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-pink-500\/20 {
  border-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-pink-500\/25 {
  border-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-pink-500\/30 {
  border-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-pink-500\/35 {
  border-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-pink-500\/40 {
  border-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-pink-500\/45 {
  border-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-pink-500\/5 {
  border-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-pink-500\/50 {
  border-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-pink-500\/55 {
  border-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-pink-500\/60 {
  border-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-pink-500\/65 {
  border-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-pink-500\/70 {
  border-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-pink-500\/75 {
  border-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-pink-500\/80 {
  border-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-pink-500\/85 {
  border-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-pink-500\/90 {
  border-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-pink-500\/95 {
  border-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-pink-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-600\/0 {
  border-color: rgb(219 39 119 / 0) !important;
}

.tw-border-pink-600\/10 {
  border-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-pink-600\/100 {
  border-color: rgb(219 39 119 / 1) !important;
}

.tw-border-pink-600\/15 {
  border-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-pink-600\/20 {
  border-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-pink-600\/25 {
  border-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-pink-600\/30 {
  border-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-pink-600\/35 {
  border-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-pink-600\/40 {
  border-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-pink-600\/45 {
  border-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-pink-600\/5 {
  border-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-pink-600\/50 {
  border-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-pink-600\/55 {
  border-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-pink-600\/60 {
  border-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-pink-600\/65 {
  border-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-pink-600\/70 {
  border-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-pink-600\/75 {
  border-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-pink-600\/80 {
  border-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-pink-600\/85 {
  border-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-pink-600\/90 {
  border-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-pink-600\/95 {
  border-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-pink-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-700\/0 {
  border-color: rgb(190 24 93 / 0) !important;
}

.tw-border-pink-700\/10 {
  border-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-pink-700\/100 {
  border-color: rgb(190 24 93 / 1) !important;
}

.tw-border-pink-700\/15 {
  border-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-pink-700\/20 {
  border-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-pink-700\/25 {
  border-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-pink-700\/30 {
  border-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-pink-700\/35 {
  border-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-pink-700\/40 {
  border-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-pink-700\/45 {
  border-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-pink-700\/5 {
  border-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-pink-700\/50 {
  border-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-pink-700\/55 {
  border-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-pink-700\/60 {
  border-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-pink-700\/65 {
  border-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-pink-700\/70 {
  border-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-pink-700\/75 {
  border-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-pink-700\/80 {
  border-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-pink-700\/85 {
  border-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-pink-700\/90 {
  border-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-pink-700\/95 {
  border-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-pink-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-800\/0 {
  border-color: rgb(157 23 77 / 0) !important;
}

.tw-border-pink-800\/10 {
  border-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-pink-800\/100 {
  border-color: rgb(157 23 77 / 1) !important;
}

.tw-border-pink-800\/15 {
  border-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-pink-800\/20 {
  border-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-pink-800\/25 {
  border-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-pink-800\/30 {
  border-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-pink-800\/35 {
  border-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-pink-800\/40 {
  border-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-pink-800\/45 {
  border-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-pink-800\/5 {
  border-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-pink-800\/50 {
  border-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-pink-800\/55 {
  border-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-pink-800\/60 {
  border-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-pink-800\/65 {
  border-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-pink-800\/70 {
  border-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-pink-800\/75 {
  border-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-pink-800\/80 {
  border-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-pink-800\/85 {
  border-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-pink-800\/90 {
  border-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-pink-800\/95 {
  border-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-pink-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-900\/0 {
  border-color: rgb(131 24 67 / 0) !important;
}

.tw-border-pink-900\/10 {
  border-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-pink-900\/100 {
  border-color: rgb(131 24 67 / 1) !important;
}

.tw-border-pink-900\/15 {
  border-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-pink-900\/20 {
  border-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-pink-900\/25 {
  border-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-pink-900\/30 {
  border-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-pink-900\/35 {
  border-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-pink-900\/40 {
  border-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-pink-900\/45 {
  border-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-pink-900\/5 {
  border-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-pink-900\/50 {
  border-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-pink-900\/55 {
  border-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-pink-900\/60 {
  border-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-pink-900\/65 {
  border-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-pink-900\/70 {
  border-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-pink-900\/75 {
  border-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-pink-900\/80 {
  border-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-pink-900\/85 {
  border-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-pink-900\/90 {
  border-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-pink-900\/95 {
  border-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-pink-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-pink-950\/0 {
  border-color: rgb(80 7 36 / 0) !important;
}

.tw-border-pink-950\/10 {
  border-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-pink-950\/100 {
  border-color: rgb(80 7 36 / 1) !important;
}

.tw-border-pink-950\/15 {
  border-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-pink-950\/20 {
  border-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-pink-950\/25 {
  border-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-pink-950\/30 {
  border-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-pink-950\/35 {
  border-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-pink-950\/40 {
  border-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-pink-950\/45 {
  border-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-pink-950\/5 {
  border-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-pink-950\/50 {
  border-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-pink-950\/55 {
  border-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-pink-950\/60 {
  border-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-pink-950\/65 {
  border-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-pink-950\/70 {
  border-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-pink-950\/75 {
  border-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-pink-950\/80 {
  border-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-pink-950\/85 {
  border-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-pink-950\/90 {
  border-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-pink-950\/95 {
  border-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-purple-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-100\/0 {
  border-color: rgb(243 232 255 / 0) !important;
}

.tw-border-purple-100\/10 {
  border-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-purple-100\/100 {
  border-color: rgb(243 232 255 / 1) !important;
}

.tw-border-purple-100\/15 {
  border-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-purple-100\/20 {
  border-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-purple-100\/25 {
  border-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-purple-100\/30 {
  border-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-purple-100\/35 {
  border-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-purple-100\/40 {
  border-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-purple-100\/45 {
  border-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-purple-100\/5 {
  border-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-purple-100\/50 {
  border-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-purple-100\/55 {
  border-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-purple-100\/60 {
  border-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-purple-100\/65 {
  border-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-purple-100\/70 {
  border-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-purple-100\/75 {
  border-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-purple-100\/80 {
  border-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-purple-100\/85 {
  border-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-purple-100\/90 {
  border-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-purple-100\/95 {
  border-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-purple-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-200\/0 {
  border-color: rgb(233 213 255 / 0) !important;
}

.tw-border-purple-200\/10 {
  border-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-purple-200\/100 {
  border-color: rgb(233 213 255 / 1) !important;
}

.tw-border-purple-200\/15 {
  border-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-purple-200\/20 {
  border-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-purple-200\/25 {
  border-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-purple-200\/30 {
  border-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-purple-200\/35 {
  border-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-purple-200\/40 {
  border-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-purple-200\/45 {
  border-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-purple-200\/5 {
  border-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-purple-200\/50 {
  border-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-purple-200\/55 {
  border-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-purple-200\/60 {
  border-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-purple-200\/65 {
  border-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-purple-200\/70 {
  border-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-purple-200\/75 {
  border-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-purple-200\/80 {
  border-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-purple-200\/85 {
  border-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-purple-200\/90 {
  border-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-purple-200\/95 {
  border-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-purple-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-300\/0 {
  border-color: rgb(216 180 254 / 0) !important;
}

.tw-border-purple-300\/10 {
  border-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-purple-300\/100 {
  border-color: rgb(216 180 254 / 1) !important;
}

.tw-border-purple-300\/15 {
  border-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-purple-300\/20 {
  border-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-purple-300\/25 {
  border-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-purple-300\/30 {
  border-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-purple-300\/35 {
  border-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-purple-300\/40 {
  border-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-purple-300\/45 {
  border-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-purple-300\/5 {
  border-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-purple-300\/50 {
  border-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-purple-300\/55 {
  border-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-purple-300\/60 {
  border-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-purple-300\/65 {
  border-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-purple-300\/70 {
  border-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-purple-300\/75 {
  border-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-purple-300\/80 {
  border-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-purple-300\/85 {
  border-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-purple-300\/90 {
  border-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-purple-300\/95 {
  border-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-purple-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-400\/0 {
  border-color: rgb(192 132 252 / 0) !important;
}

.tw-border-purple-400\/10 {
  border-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-purple-400\/100 {
  border-color: rgb(192 132 252 / 1) !important;
}

.tw-border-purple-400\/15 {
  border-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-purple-400\/20 {
  border-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-purple-400\/25 {
  border-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-purple-400\/30 {
  border-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-purple-400\/35 {
  border-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-purple-400\/40 {
  border-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-purple-400\/45 {
  border-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-purple-400\/5 {
  border-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-purple-400\/50 {
  border-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-purple-400\/55 {
  border-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-purple-400\/60 {
  border-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-purple-400\/65 {
  border-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-purple-400\/70 {
  border-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-purple-400\/75 {
  border-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-purple-400\/80 {
  border-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-purple-400\/85 {
  border-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-purple-400\/90 {
  border-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-purple-400\/95 {
  border-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-purple-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-50\/0 {
  border-color: rgb(250 245 255 / 0) !important;
}

.tw-border-purple-50\/10 {
  border-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-purple-50\/100 {
  border-color: rgb(250 245 255 / 1) !important;
}

.tw-border-purple-50\/15 {
  border-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-purple-50\/20 {
  border-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-purple-50\/25 {
  border-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-purple-50\/30 {
  border-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-purple-50\/35 {
  border-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-purple-50\/40 {
  border-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-purple-50\/45 {
  border-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-purple-50\/5 {
  border-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-purple-50\/50 {
  border-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-purple-50\/55 {
  border-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-purple-50\/60 {
  border-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-purple-50\/65 {
  border-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-purple-50\/70 {
  border-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-purple-50\/75 {
  border-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-purple-50\/80 {
  border-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-purple-50\/85 {
  border-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-purple-50\/90 {
  border-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-purple-50\/95 {
  border-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-purple-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-500\/0 {
  border-color: rgb(168 85 247 / 0) !important;
}

.tw-border-purple-500\/10 {
  border-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-purple-500\/100 {
  border-color: rgb(168 85 247 / 1) !important;
}

.tw-border-purple-500\/15 {
  border-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-purple-500\/20 {
  border-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-purple-500\/25 {
  border-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-purple-500\/30 {
  border-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-purple-500\/35 {
  border-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-purple-500\/40 {
  border-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-purple-500\/45 {
  border-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-purple-500\/5 {
  border-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-purple-500\/50 {
  border-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-purple-500\/55 {
  border-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-purple-500\/60 {
  border-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-purple-500\/65 {
  border-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-purple-500\/70 {
  border-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-purple-500\/75 {
  border-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-purple-500\/80 {
  border-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-purple-500\/85 {
  border-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-purple-500\/90 {
  border-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-purple-500\/95 {
  border-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-purple-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-600\/0 {
  border-color: rgb(147 51 234 / 0) !important;
}

.tw-border-purple-600\/10 {
  border-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-purple-600\/100 {
  border-color: rgb(147 51 234 / 1) !important;
}

.tw-border-purple-600\/15 {
  border-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-purple-600\/20 {
  border-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-purple-600\/25 {
  border-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-purple-600\/30 {
  border-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-purple-600\/35 {
  border-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-purple-600\/40 {
  border-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-purple-600\/45 {
  border-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-purple-600\/5 {
  border-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-purple-600\/50 {
  border-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-purple-600\/55 {
  border-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-purple-600\/60 {
  border-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-purple-600\/65 {
  border-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-purple-600\/70 {
  border-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-purple-600\/75 {
  border-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-purple-600\/80 {
  border-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-purple-600\/85 {
  border-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-purple-600\/90 {
  border-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-purple-600\/95 {
  border-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-purple-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-700\/0 {
  border-color: rgb(126 34 206 / 0) !important;
}

.tw-border-purple-700\/10 {
  border-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-purple-700\/100 {
  border-color: rgb(126 34 206 / 1) !important;
}

.tw-border-purple-700\/15 {
  border-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-purple-700\/20 {
  border-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-purple-700\/25 {
  border-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-purple-700\/30 {
  border-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-purple-700\/35 {
  border-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-purple-700\/40 {
  border-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-purple-700\/45 {
  border-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-purple-700\/5 {
  border-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-purple-700\/50 {
  border-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-purple-700\/55 {
  border-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-purple-700\/60 {
  border-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-purple-700\/65 {
  border-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-purple-700\/70 {
  border-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-purple-700\/75 {
  border-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-purple-700\/80 {
  border-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-purple-700\/85 {
  border-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-purple-700\/90 {
  border-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-purple-700\/95 {
  border-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-purple-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-800\/0 {
  border-color: rgb(107 33 168 / 0) !important;
}

.tw-border-purple-800\/10 {
  border-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-purple-800\/100 {
  border-color: rgb(107 33 168 / 1) !important;
}

.tw-border-purple-800\/15 {
  border-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-purple-800\/20 {
  border-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-purple-800\/25 {
  border-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-purple-800\/30 {
  border-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-purple-800\/35 {
  border-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-purple-800\/40 {
  border-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-purple-800\/45 {
  border-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-purple-800\/5 {
  border-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-purple-800\/50 {
  border-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-purple-800\/55 {
  border-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-purple-800\/60 {
  border-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-purple-800\/65 {
  border-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-purple-800\/70 {
  border-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-purple-800\/75 {
  border-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-purple-800\/80 {
  border-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-purple-800\/85 {
  border-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-purple-800\/90 {
  border-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-purple-800\/95 {
  border-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-purple-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-900\/0 {
  border-color: rgb(88 28 135 / 0) !important;
}

.tw-border-purple-900\/10 {
  border-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-purple-900\/100 {
  border-color: rgb(88 28 135 / 1) !important;
}

.tw-border-purple-900\/15 {
  border-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-purple-900\/20 {
  border-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-purple-900\/25 {
  border-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-purple-900\/30 {
  border-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-purple-900\/35 {
  border-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-purple-900\/40 {
  border-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-purple-900\/45 {
  border-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-purple-900\/5 {
  border-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-purple-900\/50 {
  border-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-purple-900\/55 {
  border-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-purple-900\/60 {
  border-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-purple-900\/65 {
  border-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-purple-900\/70 {
  border-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-purple-900\/75 {
  border-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-purple-900\/80 {
  border-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-purple-900\/85 {
  border-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-purple-900\/90 {
  border-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-purple-900\/95 {
  border-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-purple-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-purple-950\/0 {
  border-color: rgb(59 7 100 / 0) !important;
}

.tw-border-purple-950\/10 {
  border-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-purple-950\/100 {
  border-color: rgb(59 7 100 / 1) !important;
}

.tw-border-purple-950\/15 {
  border-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-purple-950\/20 {
  border-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-purple-950\/25 {
  border-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-purple-950\/30 {
  border-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-purple-950\/35 {
  border-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-purple-950\/40 {
  border-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-purple-950\/45 {
  border-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-purple-950\/5 {
  border-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-purple-950\/50 {
  border-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-purple-950\/55 {
  border-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-purple-950\/60 {
  border-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-purple-950\/65 {
  border-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-purple-950\/70 {
  border-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-purple-950\/75 {
  border-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-purple-950\/80 {
  border-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-purple-950\/85 {
  border-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-purple-950\/90 {
  border-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-purple-950\/95 {
  border-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-red-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-red-100\/0 {
  border-color: rgb(254 226 226 / 0) !important;
}

.tw-border-red-100\/10 {
  border-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-red-100\/100 {
  border-color: rgb(254 226 226 / 1) !important;
}

.tw-border-red-100\/15 {
  border-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-red-100\/20 {
  border-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-red-100\/25 {
  border-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-red-100\/30 {
  border-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-red-100\/35 {
  border-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-red-100\/40 {
  border-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-red-100\/45 {
  border-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-red-100\/5 {
  border-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-red-100\/50 {
  border-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-red-100\/55 {
  border-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-red-100\/60 {
  border-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-red-100\/65 {
  border-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-red-100\/70 {
  border-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-red-100\/75 {
  border-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-red-100\/80 {
  border-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-red-100\/85 {
  border-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-red-100\/90 {
  border-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-red-100\/95 {
  border-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-red-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-red-200\/0 {
  border-color: rgb(254 202 202 / 0) !important;
}

.tw-border-red-200\/10 {
  border-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-red-200\/100 {
  border-color: rgb(254 202 202 / 1) !important;
}

.tw-border-red-200\/15 {
  border-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-red-200\/20 {
  border-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-red-200\/25 {
  border-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-red-200\/30 {
  border-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-red-200\/35 {
  border-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-red-200\/40 {
  border-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-red-200\/45 {
  border-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-red-200\/5 {
  border-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-red-200\/50 {
  border-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-red-200\/55 {
  border-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-red-200\/60 {
  border-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-red-200\/65 {
  border-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-red-200\/70 {
  border-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-red-200\/75 {
  border-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-red-200\/80 {
  border-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-red-200\/85 {
  border-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-red-200\/90 {
  border-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-red-200\/95 {
  border-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-red-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-red-300\/0 {
  border-color: rgb(252 165 165 / 0) !important;
}

.tw-border-red-300\/10 {
  border-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-red-300\/100 {
  border-color: rgb(252 165 165 / 1) !important;
}

.tw-border-red-300\/15 {
  border-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-red-300\/20 {
  border-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-red-300\/25 {
  border-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-red-300\/30 {
  border-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-red-300\/35 {
  border-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-red-300\/40 {
  border-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-red-300\/45 {
  border-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-red-300\/5 {
  border-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-red-300\/50 {
  border-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-red-300\/55 {
  border-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-red-300\/60 {
  border-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-red-300\/65 {
  border-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-red-300\/70 {
  border-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-red-300\/75 {
  border-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-red-300\/80 {
  border-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-red-300\/85 {
  border-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-red-300\/90 {
  border-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-red-300\/95 {
  border-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-red-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-red-400\/0 {
  border-color: rgb(248 113 113 / 0) !important;
}

.tw-border-red-400\/10 {
  border-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-red-400\/100 {
  border-color: rgb(248 113 113 / 1) !important;
}

.tw-border-red-400\/15 {
  border-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-red-400\/20 {
  border-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-red-400\/25 {
  border-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-red-400\/30 {
  border-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-red-400\/35 {
  border-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-red-400\/40 {
  border-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-red-400\/45 {
  border-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-red-400\/5 {
  border-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-red-400\/50 {
  border-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-red-400\/55 {
  border-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-red-400\/60 {
  border-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-red-400\/65 {
  border-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-red-400\/70 {
  border-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-red-400\/75 {
  border-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-red-400\/80 {
  border-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-red-400\/85 {
  border-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-red-400\/90 {
  border-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-red-400\/95 {
  border-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-red-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-red-50\/0 {
  border-color: rgb(254 242 242 / 0) !important;
}

.tw-border-red-50\/10 {
  border-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-red-50\/100 {
  border-color: rgb(254 242 242 / 1) !important;
}

.tw-border-red-50\/15 {
  border-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-red-50\/20 {
  border-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-red-50\/25 {
  border-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-red-50\/30 {
  border-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-red-50\/35 {
  border-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-red-50\/40 {
  border-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-red-50\/45 {
  border-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-red-50\/5 {
  border-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-red-50\/50 {
  border-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-red-50\/55 {
  border-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-red-50\/60 {
  border-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-red-50\/65 {
  border-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-red-50\/70 {
  border-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-red-50\/75 {
  border-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-red-50\/80 {
  border-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-red-50\/85 {
  border-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-red-50\/90 {
  border-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-red-50\/95 {
  border-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-red-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-red-500\/0 {
  border-color: rgb(239 68 68 / 0) !important;
}

.tw-border-red-500\/10 {
  border-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-red-500\/100 {
  border-color: rgb(239 68 68 / 1) !important;
}

.tw-border-red-500\/15 {
  border-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-red-500\/20 {
  border-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-red-500\/25 {
  border-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-red-500\/30 {
  border-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-red-500\/35 {
  border-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-red-500\/40 {
  border-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-red-500\/45 {
  border-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-red-500\/5 {
  border-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-red-500\/50 {
  border-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-red-500\/55 {
  border-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-red-500\/60 {
  border-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-red-500\/65 {
  border-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-red-500\/70 {
  border-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-red-500\/75 {
  border-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-red-500\/80 {
  border-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-red-500\/85 {
  border-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-red-500\/90 {
  border-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-red-500\/95 {
  border-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-red-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-red-600\/0 {
  border-color: rgb(220 38 38 / 0) !important;
}

.tw-border-red-600\/10 {
  border-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-red-600\/100 {
  border-color: rgb(220 38 38 / 1) !important;
}

.tw-border-red-600\/15 {
  border-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-red-600\/20 {
  border-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-red-600\/25 {
  border-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-red-600\/30 {
  border-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-red-600\/35 {
  border-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-red-600\/40 {
  border-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-red-600\/45 {
  border-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-red-600\/5 {
  border-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-red-600\/50 {
  border-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-red-600\/55 {
  border-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-red-600\/60 {
  border-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-red-600\/65 {
  border-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-red-600\/70 {
  border-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-red-600\/75 {
  border-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-red-600\/80 {
  border-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-red-600\/85 {
  border-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-red-600\/90 {
  border-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-red-600\/95 {
  border-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-red-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-red-700\/0 {
  border-color: rgb(185 28 28 / 0) !important;
}

.tw-border-red-700\/10 {
  border-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-red-700\/100 {
  border-color: rgb(185 28 28 / 1) !important;
}

.tw-border-red-700\/15 {
  border-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-red-700\/20 {
  border-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-red-700\/25 {
  border-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-red-700\/30 {
  border-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-red-700\/35 {
  border-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-red-700\/40 {
  border-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-red-700\/45 {
  border-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-red-700\/5 {
  border-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-red-700\/50 {
  border-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-red-700\/55 {
  border-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-red-700\/60 {
  border-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-red-700\/65 {
  border-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-red-700\/70 {
  border-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-red-700\/75 {
  border-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-red-700\/80 {
  border-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-red-700\/85 {
  border-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-red-700\/90 {
  border-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-red-700\/95 {
  border-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-red-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-red-800\/0 {
  border-color: rgb(153 27 27 / 0) !important;
}

.tw-border-red-800\/10 {
  border-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-red-800\/100 {
  border-color: rgb(153 27 27 / 1) !important;
}

.tw-border-red-800\/15 {
  border-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-red-800\/20 {
  border-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-red-800\/25 {
  border-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-red-800\/30 {
  border-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-red-800\/35 {
  border-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-red-800\/40 {
  border-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-red-800\/45 {
  border-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-red-800\/5 {
  border-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-red-800\/50 {
  border-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-red-800\/55 {
  border-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-red-800\/60 {
  border-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-red-800\/65 {
  border-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-red-800\/70 {
  border-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-red-800\/75 {
  border-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-red-800\/80 {
  border-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-red-800\/85 {
  border-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-red-800\/90 {
  border-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-red-800\/95 {
  border-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-red-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-red-900\/0 {
  border-color: rgb(127 29 29 / 0) !important;
}

.tw-border-red-900\/10 {
  border-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-red-900\/100 {
  border-color: rgb(127 29 29 / 1) !important;
}

.tw-border-red-900\/15 {
  border-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-red-900\/20 {
  border-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-red-900\/25 {
  border-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-red-900\/30 {
  border-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-red-900\/35 {
  border-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-red-900\/40 {
  border-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-red-900\/45 {
  border-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-red-900\/5 {
  border-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-red-900\/50 {
  border-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-red-900\/55 {
  border-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-red-900\/60 {
  border-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-red-900\/65 {
  border-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-red-900\/70 {
  border-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-red-900\/75 {
  border-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-red-900\/80 {
  border-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-red-900\/85 {
  border-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-red-900\/90 {
  border-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-red-900\/95 {
  border-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-red-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-red-950\/0 {
  border-color: rgb(69 10 10 / 0) !important;
}

.tw-border-red-950\/10 {
  border-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-red-950\/100 {
  border-color: rgb(69 10 10 / 1) !important;
}

.tw-border-red-950\/15 {
  border-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-red-950\/20 {
  border-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-red-950\/25 {
  border-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-red-950\/30 {
  border-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-red-950\/35 {
  border-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-red-950\/40 {
  border-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-red-950\/45 {
  border-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-red-950\/5 {
  border-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-red-950\/50 {
  border-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-red-950\/55 {
  border-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-red-950\/60 {
  border-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-red-950\/65 {
  border-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-red-950\/70 {
  border-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-red-950\/75 {
  border-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-red-950\/80 {
  border-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-red-950\/85 {
  border-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-red-950\/90 {
  border-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-red-950\/95 {
  border-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-rose-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-100\/0 {
  border-color: rgb(255 228 230 / 0) !important;
}

.tw-border-rose-100\/10 {
  border-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-rose-100\/100 {
  border-color: rgb(255 228 230 / 1) !important;
}

.tw-border-rose-100\/15 {
  border-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-rose-100\/20 {
  border-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-rose-100\/25 {
  border-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-rose-100\/30 {
  border-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-rose-100\/35 {
  border-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-rose-100\/40 {
  border-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-rose-100\/45 {
  border-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-rose-100\/5 {
  border-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-rose-100\/50 {
  border-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-rose-100\/55 {
  border-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-rose-100\/60 {
  border-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-rose-100\/65 {
  border-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-rose-100\/70 {
  border-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-rose-100\/75 {
  border-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-rose-100\/80 {
  border-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-rose-100\/85 {
  border-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-rose-100\/90 {
  border-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-rose-100\/95 {
  border-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-rose-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-200\/0 {
  border-color: rgb(254 205 211 / 0) !important;
}

.tw-border-rose-200\/10 {
  border-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-rose-200\/100 {
  border-color: rgb(254 205 211 / 1) !important;
}

.tw-border-rose-200\/15 {
  border-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-rose-200\/20 {
  border-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-rose-200\/25 {
  border-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-rose-200\/30 {
  border-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-rose-200\/35 {
  border-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-rose-200\/40 {
  border-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-rose-200\/45 {
  border-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-rose-200\/5 {
  border-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-rose-200\/50 {
  border-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-rose-200\/55 {
  border-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-rose-200\/60 {
  border-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-rose-200\/65 {
  border-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-rose-200\/70 {
  border-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-rose-200\/75 {
  border-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-rose-200\/80 {
  border-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-rose-200\/85 {
  border-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-rose-200\/90 {
  border-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-rose-200\/95 {
  border-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-rose-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-300\/0 {
  border-color: rgb(253 164 175 / 0) !important;
}

.tw-border-rose-300\/10 {
  border-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-rose-300\/100 {
  border-color: rgb(253 164 175 / 1) !important;
}

.tw-border-rose-300\/15 {
  border-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-rose-300\/20 {
  border-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-rose-300\/25 {
  border-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-rose-300\/30 {
  border-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-rose-300\/35 {
  border-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-rose-300\/40 {
  border-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-rose-300\/45 {
  border-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-rose-300\/5 {
  border-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-rose-300\/50 {
  border-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-rose-300\/55 {
  border-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-rose-300\/60 {
  border-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-rose-300\/65 {
  border-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-rose-300\/70 {
  border-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-rose-300\/75 {
  border-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-rose-300\/80 {
  border-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-rose-300\/85 {
  border-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-rose-300\/90 {
  border-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-rose-300\/95 {
  border-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-rose-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-400\/0 {
  border-color: rgb(251 113 133 / 0) !important;
}

.tw-border-rose-400\/10 {
  border-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-rose-400\/100 {
  border-color: rgb(251 113 133 / 1) !important;
}

.tw-border-rose-400\/15 {
  border-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-rose-400\/20 {
  border-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-rose-400\/25 {
  border-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-rose-400\/30 {
  border-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-rose-400\/35 {
  border-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-rose-400\/40 {
  border-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-rose-400\/45 {
  border-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-rose-400\/5 {
  border-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-rose-400\/50 {
  border-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-rose-400\/55 {
  border-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-rose-400\/60 {
  border-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-rose-400\/65 {
  border-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-rose-400\/70 {
  border-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-rose-400\/75 {
  border-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-rose-400\/80 {
  border-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-rose-400\/85 {
  border-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-rose-400\/90 {
  border-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-rose-400\/95 {
  border-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-rose-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-50\/0 {
  border-color: rgb(255 241 242 / 0) !important;
}

.tw-border-rose-50\/10 {
  border-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-rose-50\/100 {
  border-color: rgb(255 241 242 / 1) !important;
}

.tw-border-rose-50\/15 {
  border-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-rose-50\/20 {
  border-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-rose-50\/25 {
  border-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-rose-50\/30 {
  border-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-rose-50\/35 {
  border-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-rose-50\/40 {
  border-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-rose-50\/45 {
  border-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-rose-50\/5 {
  border-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-rose-50\/50 {
  border-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-rose-50\/55 {
  border-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-rose-50\/60 {
  border-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-rose-50\/65 {
  border-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-rose-50\/70 {
  border-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-rose-50\/75 {
  border-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-rose-50\/80 {
  border-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-rose-50\/85 {
  border-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-rose-50\/90 {
  border-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-rose-50\/95 {
  border-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-rose-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-500\/0 {
  border-color: rgb(244 63 94 / 0) !important;
}

.tw-border-rose-500\/10 {
  border-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-rose-500\/100 {
  border-color: rgb(244 63 94 / 1) !important;
}

.tw-border-rose-500\/15 {
  border-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-rose-500\/20 {
  border-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-rose-500\/25 {
  border-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-rose-500\/30 {
  border-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-rose-500\/35 {
  border-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-rose-500\/40 {
  border-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-rose-500\/45 {
  border-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-rose-500\/5 {
  border-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-rose-500\/50 {
  border-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-rose-500\/55 {
  border-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-rose-500\/60 {
  border-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-rose-500\/65 {
  border-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-rose-500\/70 {
  border-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-rose-500\/75 {
  border-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-rose-500\/80 {
  border-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-rose-500\/85 {
  border-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-rose-500\/90 {
  border-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-rose-500\/95 {
  border-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-rose-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-600\/0 {
  border-color: rgb(225 29 72 / 0) !important;
}

.tw-border-rose-600\/10 {
  border-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-rose-600\/100 {
  border-color: rgb(225 29 72 / 1) !important;
}

.tw-border-rose-600\/15 {
  border-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-rose-600\/20 {
  border-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-rose-600\/25 {
  border-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-rose-600\/30 {
  border-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-rose-600\/35 {
  border-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-rose-600\/40 {
  border-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-rose-600\/45 {
  border-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-rose-600\/5 {
  border-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-rose-600\/50 {
  border-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-rose-600\/55 {
  border-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-rose-600\/60 {
  border-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-rose-600\/65 {
  border-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-rose-600\/70 {
  border-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-rose-600\/75 {
  border-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-rose-600\/80 {
  border-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-rose-600\/85 {
  border-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-rose-600\/90 {
  border-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-rose-600\/95 {
  border-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-rose-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-700\/0 {
  border-color: rgb(190 18 60 / 0) !important;
}

.tw-border-rose-700\/10 {
  border-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-rose-700\/100 {
  border-color: rgb(190 18 60 / 1) !important;
}

.tw-border-rose-700\/15 {
  border-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-rose-700\/20 {
  border-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-rose-700\/25 {
  border-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-rose-700\/30 {
  border-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-rose-700\/35 {
  border-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-rose-700\/40 {
  border-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-rose-700\/45 {
  border-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-rose-700\/5 {
  border-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-rose-700\/50 {
  border-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-rose-700\/55 {
  border-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-rose-700\/60 {
  border-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-rose-700\/65 {
  border-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-rose-700\/70 {
  border-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-rose-700\/75 {
  border-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-rose-700\/80 {
  border-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-rose-700\/85 {
  border-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-rose-700\/90 {
  border-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-rose-700\/95 {
  border-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-rose-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-800\/0 {
  border-color: rgb(159 18 57 / 0) !important;
}

.tw-border-rose-800\/10 {
  border-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-rose-800\/100 {
  border-color: rgb(159 18 57 / 1) !important;
}

.tw-border-rose-800\/15 {
  border-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-rose-800\/20 {
  border-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-rose-800\/25 {
  border-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-rose-800\/30 {
  border-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-rose-800\/35 {
  border-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-rose-800\/40 {
  border-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-rose-800\/45 {
  border-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-rose-800\/5 {
  border-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-rose-800\/50 {
  border-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-rose-800\/55 {
  border-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-rose-800\/60 {
  border-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-rose-800\/65 {
  border-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-rose-800\/70 {
  border-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-rose-800\/75 {
  border-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-rose-800\/80 {
  border-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-rose-800\/85 {
  border-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-rose-800\/90 {
  border-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-rose-800\/95 {
  border-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-rose-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-900\/0 {
  border-color: rgb(136 19 55 / 0) !important;
}

.tw-border-rose-900\/10 {
  border-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-rose-900\/100 {
  border-color: rgb(136 19 55 / 1) !important;
}

.tw-border-rose-900\/15 {
  border-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-rose-900\/20 {
  border-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-rose-900\/25 {
  border-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-rose-900\/30 {
  border-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-rose-900\/35 {
  border-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-rose-900\/40 {
  border-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-rose-900\/45 {
  border-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-rose-900\/5 {
  border-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-rose-900\/50 {
  border-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-rose-900\/55 {
  border-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-rose-900\/60 {
  border-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-rose-900\/65 {
  border-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-rose-900\/70 {
  border-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-rose-900\/75 {
  border-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-rose-900\/80 {
  border-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-rose-900\/85 {
  border-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-rose-900\/90 {
  border-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-rose-900\/95 {
  border-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-rose-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-rose-950\/0 {
  border-color: rgb(76 5 25 / 0) !important;
}

.tw-border-rose-950\/10 {
  border-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-rose-950\/100 {
  border-color: rgb(76 5 25 / 1) !important;
}

.tw-border-rose-950\/15 {
  border-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-rose-950\/20 {
  border-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-rose-950\/25 {
  border-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-rose-950\/30 {
  border-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-rose-950\/35 {
  border-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-rose-950\/40 {
  border-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-rose-950\/45 {
  border-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-rose-950\/5 {
  border-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-rose-950\/50 {
  border-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-rose-950\/55 {
  border-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-rose-950\/60 {
  border-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-rose-950\/65 {
  border-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-rose-950\/70 {
  border-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-rose-950\/75 {
  border-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-rose-950\/80 {
  border-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-rose-950\/85 {
  border-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-rose-950\/90 {
  border-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-rose-950\/95 {
  border-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-sky-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-100\/0 {
  border-color: rgb(224 242 254 / 0) !important;
}

.tw-border-sky-100\/10 {
  border-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-sky-100\/100 {
  border-color: rgb(224 242 254 / 1) !important;
}

.tw-border-sky-100\/15 {
  border-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-sky-100\/20 {
  border-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-sky-100\/25 {
  border-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-sky-100\/30 {
  border-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-sky-100\/35 {
  border-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-sky-100\/40 {
  border-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-sky-100\/45 {
  border-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-sky-100\/5 {
  border-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-sky-100\/50 {
  border-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-sky-100\/55 {
  border-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-sky-100\/60 {
  border-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-sky-100\/65 {
  border-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-sky-100\/70 {
  border-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-sky-100\/75 {
  border-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-sky-100\/80 {
  border-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-sky-100\/85 {
  border-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-sky-100\/90 {
  border-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-sky-100\/95 {
  border-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-sky-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-200\/0 {
  border-color: rgb(186 230 253 / 0) !important;
}

.tw-border-sky-200\/10 {
  border-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-sky-200\/100 {
  border-color: rgb(186 230 253 / 1) !important;
}

.tw-border-sky-200\/15 {
  border-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-sky-200\/20 {
  border-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-sky-200\/25 {
  border-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-sky-200\/30 {
  border-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-sky-200\/35 {
  border-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-sky-200\/40 {
  border-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-sky-200\/45 {
  border-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-sky-200\/5 {
  border-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-sky-200\/50 {
  border-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-sky-200\/55 {
  border-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-sky-200\/60 {
  border-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-sky-200\/65 {
  border-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-sky-200\/70 {
  border-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-sky-200\/75 {
  border-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-sky-200\/80 {
  border-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-sky-200\/85 {
  border-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-sky-200\/90 {
  border-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-sky-200\/95 {
  border-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-sky-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-300\/0 {
  border-color: rgb(125 211 252 / 0) !important;
}

.tw-border-sky-300\/10 {
  border-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-sky-300\/100 {
  border-color: rgb(125 211 252 / 1) !important;
}

.tw-border-sky-300\/15 {
  border-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-sky-300\/20 {
  border-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-sky-300\/25 {
  border-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-sky-300\/30 {
  border-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-sky-300\/35 {
  border-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-sky-300\/40 {
  border-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-sky-300\/45 {
  border-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-sky-300\/5 {
  border-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-sky-300\/50 {
  border-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-sky-300\/55 {
  border-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-sky-300\/60 {
  border-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-sky-300\/65 {
  border-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-sky-300\/70 {
  border-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-sky-300\/75 {
  border-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-sky-300\/80 {
  border-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-sky-300\/85 {
  border-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-sky-300\/90 {
  border-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-sky-300\/95 {
  border-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-sky-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-400\/0 {
  border-color: rgb(56 189 248 / 0) !important;
}

.tw-border-sky-400\/10 {
  border-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-sky-400\/100 {
  border-color: rgb(56 189 248 / 1) !important;
}

.tw-border-sky-400\/15 {
  border-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-sky-400\/20 {
  border-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-sky-400\/25 {
  border-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-sky-400\/30 {
  border-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-sky-400\/35 {
  border-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-sky-400\/40 {
  border-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-sky-400\/45 {
  border-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-sky-400\/5 {
  border-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-sky-400\/50 {
  border-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-sky-400\/55 {
  border-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-sky-400\/60 {
  border-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-sky-400\/65 {
  border-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-sky-400\/70 {
  border-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-sky-400\/75 {
  border-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-sky-400\/80 {
  border-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-sky-400\/85 {
  border-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-sky-400\/90 {
  border-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-sky-400\/95 {
  border-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-sky-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-50\/0 {
  border-color: rgb(240 249 255 / 0) !important;
}

.tw-border-sky-50\/10 {
  border-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-sky-50\/100 {
  border-color: rgb(240 249 255 / 1) !important;
}

.tw-border-sky-50\/15 {
  border-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-sky-50\/20 {
  border-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-sky-50\/25 {
  border-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-sky-50\/30 {
  border-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-sky-50\/35 {
  border-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-sky-50\/40 {
  border-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-sky-50\/45 {
  border-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-sky-50\/5 {
  border-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-sky-50\/50 {
  border-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-sky-50\/55 {
  border-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-sky-50\/60 {
  border-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-sky-50\/65 {
  border-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-sky-50\/70 {
  border-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-sky-50\/75 {
  border-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-sky-50\/80 {
  border-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-sky-50\/85 {
  border-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-sky-50\/90 {
  border-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-sky-50\/95 {
  border-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-sky-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-500\/0 {
  border-color: rgb(14 165 233 / 0) !important;
}

.tw-border-sky-500\/10 {
  border-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-sky-500\/100 {
  border-color: rgb(14 165 233 / 1) !important;
}

.tw-border-sky-500\/15 {
  border-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-sky-500\/20 {
  border-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-sky-500\/25 {
  border-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-sky-500\/30 {
  border-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-sky-500\/35 {
  border-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-sky-500\/40 {
  border-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-sky-500\/45 {
  border-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-sky-500\/5 {
  border-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-sky-500\/50 {
  border-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-sky-500\/55 {
  border-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-sky-500\/60 {
  border-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-sky-500\/65 {
  border-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-sky-500\/70 {
  border-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-sky-500\/75 {
  border-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-sky-500\/80 {
  border-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-sky-500\/85 {
  border-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-sky-500\/90 {
  border-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-sky-500\/95 {
  border-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-sky-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-600\/0 {
  border-color: rgb(2 132 199 / 0) !important;
}

.tw-border-sky-600\/10 {
  border-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-sky-600\/100 {
  border-color: rgb(2 132 199 / 1) !important;
}

.tw-border-sky-600\/15 {
  border-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-sky-600\/20 {
  border-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-sky-600\/25 {
  border-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-sky-600\/30 {
  border-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-sky-600\/35 {
  border-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-sky-600\/40 {
  border-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-sky-600\/45 {
  border-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-sky-600\/5 {
  border-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-sky-600\/50 {
  border-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-sky-600\/55 {
  border-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-sky-600\/60 {
  border-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-sky-600\/65 {
  border-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-sky-600\/70 {
  border-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-sky-600\/75 {
  border-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-sky-600\/80 {
  border-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-sky-600\/85 {
  border-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-sky-600\/90 {
  border-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-sky-600\/95 {
  border-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-sky-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-700\/0 {
  border-color: rgb(3 105 161 / 0) !important;
}

.tw-border-sky-700\/10 {
  border-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-sky-700\/100 {
  border-color: rgb(3 105 161 / 1) !important;
}

.tw-border-sky-700\/15 {
  border-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-sky-700\/20 {
  border-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-sky-700\/25 {
  border-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-sky-700\/30 {
  border-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-sky-700\/35 {
  border-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-sky-700\/40 {
  border-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-sky-700\/45 {
  border-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-sky-700\/5 {
  border-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-sky-700\/50 {
  border-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-sky-700\/55 {
  border-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-sky-700\/60 {
  border-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-sky-700\/65 {
  border-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-sky-700\/70 {
  border-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-sky-700\/75 {
  border-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-sky-700\/80 {
  border-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-sky-700\/85 {
  border-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-sky-700\/90 {
  border-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-sky-700\/95 {
  border-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-sky-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-800\/0 {
  border-color: rgb(7 89 133 / 0) !important;
}

.tw-border-sky-800\/10 {
  border-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-sky-800\/100 {
  border-color: rgb(7 89 133 / 1) !important;
}

.tw-border-sky-800\/15 {
  border-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-sky-800\/20 {
  border-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-sky-800\/25 {
  border-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-sky-800\/30 {
  border-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-sky-800\/35 {
  border-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-sky-800\/40 {
  border-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-sky-800\/45 {
  border-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-sky-800\/5 {
  border-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-sky-800\/50 {
  border-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-sky-800\/55 {
  border-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-sky-800\/60 {
  border-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-sky-800\/65 {
  border-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-sky-800\/70 {
  border-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-sky-800\/75 {
  border-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-sky-800\/80 {
  border-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-sky-800\/85 {
  border-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-sky-800\/90 {
  border-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-sky-800\/95 {
  border-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-sky-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-900\/0 {
  border-color: rgb(12 74 110 / 0) !important;
}

.tw-border-sky-900\/10 {
  border-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-sky-900\/100 {
  border-color: rgb(12 74 110 / 1) !important;
}

.tw-border-sky-900\/15 {
  border-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-sky-900\/20 {
  border-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-sky-900\/25 {
  border-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-sky-900\/30 {
  border-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-sky-900\/35 {
  border-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-sky-900\/40 {
  border-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-sky-900\/45 {
  border-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-sky-900\/5 {
  border-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-sky-900\/50 {
  border-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-sky-900\/55 {
  border-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-sky-900\/60 {
  border-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-sky-900\/65 {
  border-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-sky-900\/70 {
  border-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-sky-900\/75 {
  border-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-sky-900\/80 {
  border-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-sky-900\/85 {
  border-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-sky-900\/90 {
  border-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-sky-900\/95 {
  border-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-sky-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-sky-950\/0 {
  border-color: rgb(8 47 73 / 0) !important;
}

.tw-border-sky-950\/10 {
  border-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-sky-950\/100 {
  border-color: rgb(8 47 73 / 1) !important;
}

.tw-border-sky-950\/15 {
  border-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-sky-950\/20 {
  border-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-sky-950\/25 {
  border-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-sky-950\/30 {
  border-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-sky-950\/35 {
  border-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-sky-950\/40 {
  border-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-sky-950\/45 {
  border-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-sky-950\/5 {
  border-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-sky-950\/50 {
  border-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-sky-950\/55 {
  border-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-sky-950\/60 {
  border-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-sky-950\/65 {
  border-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-sky-950\/70 {
  border-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-sky-950\/75 {
  border-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-sky-950\/80 {
  border-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-sky-950\/85 {
  border-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-sky-950\/90 {
  border-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-sky-950\/95 {
  border-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-slate-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-100\/0 {
  border-color: rgb(241 245 249 / 0) !important;
}

.tw-border-slate-100\/10 {
  border-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-slate-100\/100 {
  border-color: rgb(241 245 249 / 1) !important;
}

.tw-border-slate-100\/15 {
  border-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-slate-100\/20 {
  border-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-slate-100\/25 {
  border-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-slate-100\/30 {
  border-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-slate-100\/35 {
  border-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-slate-100\/40 {
  border-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-slate-100\/45 {
  border-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-slate-100\/5 {
  border-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-slate-100\/50 {
  border-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-slate-100\/55 {
  border-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-slate-100\/60 {
  border-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-slate-100\/65 {
  border-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-slate-100\/70 {
  border-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-slate-100\/75 {
  border-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-slate-100\/80 {
  border-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-slate-100\/85 {
  border-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-slate-100\/90 {
  border-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-slate-100\/95 {
  border-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-slate-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-200\/0 {
  border-color: rgb(226 232 240 / 0) !important;
}

.tw-border-slate-200\/10 {
  border-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-slate-200\/100 {
  border-color: rgb(226 232 240 / 1) !important;
}

.tw-border-slate-200\/15 {
  border-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-slate-200\/20 {
  border-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-slate-200\/25 {
  border-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-slate-200\/30 {
  border-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-slate-200\/35 {
  border-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-slate-200\/40 {
  border-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-slate-200\/45 {
  border-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-slate-200\/5 {
  border-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-slate-200\/50 {
  border-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-slate-200\/55 {
  border-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-slate-200\/60 {
  border-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-slate-200\/65 {
  border-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-slate-200\/70 {
  border-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-slate-200\/75 {
  border-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-slate-200\/80 {
  border-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-slate-200\/85 {
  border-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-slate-200\/90 {
  border-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-slate-200\/95 {
  border-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-slate-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-300\/0 {
  border-color: rgb(203 213 225 / 0) !important;
}

.tw-border-slate-300\/10 {
  border-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-slate-300\/100 {
  border-color: rgb(203 213 225 / 1) !important;
}

.tw-border-slate-300\/15 {
  border-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-slate-300\/20 {
  border-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-slate-300\/25 {
  border-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-slate-300\/30 {
  border-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-slate-300\/35 {
  border-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-slate-300\/40 {
  border-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-slate-300\/45 {
  border-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-slate-300\/5 {
  border-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-slate-300\/50 {
  border-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-slate-300\/55 {
  border-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-slate-300\/60 {
  border-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-slate-300\/65 {
  border-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-slate-300\/70 {
  border-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-slate-300\/75 {
  border-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-slate-300\/80 {
  border-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-slate-300\/85 {
  border-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-slate-300\/90 {
  border-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-slate-300\/95 {
  border-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-slate-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-400\/0 {
  border-color: rgb(148 163 184 / 0) !important;
}

.tw-border-slate-400\/10 {
  border-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-slate-400\/100 {
  border-color: rgb(148 163 184 / 1) !important;
}

.tw-border-slate-400\/15 {
  border-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-slate-400\/20 {
  border-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-slate-400\/25 {
  border-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-slate-400\/30 {
  border-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-slate-400\/35 {
  border-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-slate-400\/40 {
  border-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-slate-400\/45 {
  border-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-slate-400\/5 {
  border-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-slate-400\/50 {
  border-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-slate-400\/55 {
  border-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-slate-400\/60 {
  border-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-slate-400\/65 {
  border-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-slate-400\/70 {
  border-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-slate-400\/75 {
  border-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-slate-400\/80 {
  border-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-slate-400\/85 {
  border-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-slate-400\/90 {
  border-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-slate-400\/95 {
  border-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-slate-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-50\/0 {
  border-color: rgb(248 250 252 / 0) !important;
}

.tw-border-slate-50\/10 {
  border-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-slate-50\/100 {
  border-color: rgb(248 250 252 / 1) !important;
}

.tw-border-slate-50\/15 {
  border-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-slate-50\/20 {
  border-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-slate-50\/25 {
  border-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-slate-50\/30 {
  border-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-slate-50\/35 {
  border-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-slate-50\/40 {
  border-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-slate-50\/45 {
  border-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-slate-50\/5 {
  border-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-slate-50\/50 {
  border-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-slate-50\/55 {
  border-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-slate-50\/60 {
  border-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-slate-50\/65 {
  border-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-slate-50\/70 {
  border-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-slate-50\/75 {
  border-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-slate-50\/80 {
  border-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-slate-50\/85 {
  border-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-slate-50\/90 {
  border-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-slate-50\/95 {
  border-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-slate-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-500\/0 {
  border-color: rgb(100 116 139 / 0) !important;
}

.tw-border-slate-500\/10 {
  border-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-slate-500\/100 {
  border-color: rgb(100 116 139 / 1) !important;
}

.tw-border-slate-500\/15 {
  border-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-slate-500\/20 {
  border-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-slate-500\/25 {
  border-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-slate-500\/30 {
  border-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-slate-500\/35 {
  border-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-slate-500\/40 {
  border-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-slate-500\/45 {
  border-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-slate-500\/5 {
  border-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-slate-500\/50 {
  border-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-slate-500\/55 {
  border-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-slate-500\/60 {
  border-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-slate-500\/65 {
  border-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-slate-500\/70 {
  border-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-slate-500\/75 {
  border-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-slate-500\/80 {
  border-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-slate-500\/85 {
  border-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-slate-500\/90 {
  border-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-slate-500\/95 {
  border-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-slate-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-600\/0 {
  border-color: rgb(71 85 105 / 0) !important;
}

.tw-border-slate-600\/10 {
  border-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-slate-600\/100 {
  border-color: rgb(71 85 105 / 1) !important;
}

.tw-border-slate-600\/15 {
  border-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-slate-600\/20 {
  border-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-slate-600\/25 {
  border-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-slate-600\/30 {
  border-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-slate-600\/35 {
  border-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-slate-600\/40 {
  border-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-slate-600\/45 {
  border-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-slate-600\/5 {
  border-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-slate-600\/50 {
  border-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-slate-600\/55 {
  border-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-slate-600\/60 {
  border-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-slate-600\/65 {
  border-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-slate-600\/70 {
  border-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-slate-600\/75 {
  border-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-slate-600\/80 {
  border-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-slate-600\/85 {
  border-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-slate-600\/90 {
  border-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-slate-600\/95 {
  border-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-slate-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-700\/0 {
  border-color: rgb(51 65 85 / 0) !important;
}

.tw-border-slate-700\/10 {
  border-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-slate-700\/100 {
  border-color: rgb(51 65 85 / 1) !important;
}

.tw-border-slate-700\/15 {
  border-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-slate-700\/20 {
  border-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-slate-700\/25 {
  border-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-slate-700\/30 {
  border-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-slate-700\/35 {
  border-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-slate-700\/40 {
  border-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-slate-700\/45 {
  border-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-slate-700\/5 {
  border-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-slate-700\/50 {
  border-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-slate-700\/55 {
  border-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-slate-700\/60 {
  border-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-slate-700\/65 {
  border-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-slate-700\/70 {
  border-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-slate-700\/75 {
  border-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-slate-700\/80 {
  border-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-slate-700\/85 {
  border-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-slate-700\/90 {
  border-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-slate-700\/95 {
  border-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-slate-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-800\/0 {
  border-color: rgb(30 41 59 / 0) !important;
}

.tw-border-slate-800\/10 {
  border-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-slate-800\/100 {
  border-color: rgb(30 41 59 / 1) !important;
}

.tw-border-slate-800\/15 {
  border-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-slate-800\/20 {
  border-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-slate-800\/25 {
  border-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-slate-800\/30 {
  border-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-slate-800\/35 {
  border-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-slate-800\/40 {
  border-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-slate-800\/45 {
  border-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-slate-800\/5 {
  border-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-slate-800\/50 {
  border-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-slate-800\/55 {
  border-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-slate-800\/60 {
  border-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-slate-800\/65 {
  border-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-slate-800\/70 {
  border-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-slate-800\/75 {
  border-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-slate-800\/80 {
  border-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-slate-800\/85 {
  border-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-slate-800\/90 {
  border-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-slate-800\/95 {
  border-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-slate-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-900\/0 {
  border-color: rgb(15 23 42 / 0) !important;
}

.tw-border-slate-900\/10 {
  border-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-slate-900\/100 {
  border-color: rgb(15 23 42 / 1) !important;
}

.tw-border-slate-900\/15 {
  border-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-slate-900\/20 {
  border-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-slate-900\/25 {
  border-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-slate-900\/30 {
  border-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-slate-900\/35 {
  border-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-slate-900\/40 {
  border-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-slate-900\/45 {
  border-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-slate-900\/5 {
  border-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-slate-900\/50 {
  border-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-slate-900\/55 {
  border-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-slate-900\/60 {
  border-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-slate-900\/65 {
  border-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-slate-900\/70 {
  border-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-slate-900\/75 {
  border-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-slate-900\/80 {
  border-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-slate-900\/85 {
  border-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-slate-900\/90 {
  border-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-slate-900\/95 {
  border-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-slate-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-slate-950\/0 {
  border-color: rgb(2 6 23 / 0) !important;
}

.tw-border-slate-950\/10 {
  border-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-slate-950\/100 {
  border-color: rgb(2 6 23 / 1) !important;
}

.tw-border-slate-950\/15 {
  border-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-slate-950\/20 {
  border-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-slate-950\/25 {
  border-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-slate-950\/30 {
  border-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-slate-950\/35 {
  border-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-slate-950\/40 {
  border-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-slate-950\/45 {
  border-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-slate-950\/5 {
  border-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-slate-950\/50 {
  border-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-slate-950\/55 {
  border-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-slate-950\/60 {
  border-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-slate-950\/65 {
  border-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-slate-950\/70 {
  border-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-slate-950\/75 {
  border-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-slate-950\/80 {
  border-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-slate-950\/85 {
  border-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-slate-950\/90 {
  border-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-slate-950\/95 {
  border-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-stone-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-100\/0 {
  border-color: rgb(245 245 244 / 0) !important;
}

.tw-border-stone-100\/10 {
  border-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-stone-100\/100 {
  border-color: rgb(245 245 244 / 1) !important;
}

.tw-border-stone-100\/15 {
  border-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-stone-100\/20 {
  border-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-stone-100\/25 {
  border-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-stone-100\/30 {
  border-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-stone-100\/35 {
  border-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-stone-100\/40 {
  border-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-stone-100\/45 {
  border-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-stone-100\/5 {
  border-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-stone-100\/50 {
  border-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-stone-100\/55 {
  border-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-stone-100\/60 {
  border-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-stone-100\/65 {
  border-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-stone-100\/70 {
  border-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-stone-100\/75 {
  border-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-stone-100\/80 {
  border-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-stone-100\/85 {
  border-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-stone-100\/90 {
  border-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-stone-100\/95 {
  border-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-stone-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-200\/0 {
  border-color: rgb(231 229 228 / 0) !important;
}

.tw-border-stone-200\/10 {
  border-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-stone-200\/100 {
  border-color: rgb(231 229 228 / 1) !important;
}

.tw-border-stone-200\/15 {
  border-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-stone-200\/20 {
  border-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-stone-200\/25 {
  border-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-stone-200\/30 {
  border-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-stone-200\/35 {
  border-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-stone-200\/40 {
  border-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-stone-200\/45 {
  border-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-stone-200\/5 {
  border-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-stone-200\/50 {
  border-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-stone-200\/55 {
  border-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-stone-200\/60 {
  border-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-stone-200\/65 {
  border-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-stone-200\/70 {
  border-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-stone-200\/75 {
  border-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-stone-200\/80 {
  border-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-stone-200\/85 {
  border-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-stone-200\/90 {
  border-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-stone-200\/95 {
  border-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-stone-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-300\/0 {
  border-color: rgb(214 211 209 / 0) !important;
}

.tw-border-stone-300\/10 {
  border-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-stone-300\/100 {
  border-color: rgb(214 211 209 / 1) !important;
}

.tw-border-stone-300\/15 {
  border-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-stone-300\/20 {
  border-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-stone-300\/25 {
  border-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-stone-300\/30 {
  border-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-stone-300\/35 {
  border-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-stone-300\/40 {
  border-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-stone-300\/45 {
  border-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-stone-300\/5 {
  border-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-stone-300\/50 {
  border-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-stone-300\/55 {
  border-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-stone-300\/60 {
  border-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-stone-300\/65 {
  border-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-stone-300\/70 {
  border-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-stone-300\/75 {
  border-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-stone-300\/80 {
  border-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-stone-300\/85 {
  border-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-stone-300\/90 {
  border-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-stone-300\/95 {
  border-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-stone-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-400\/0 {
  border-color: rgb(168 162 158 / 0) !important;
}

.tw-border-stone-400\/10 {
  border-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-stone-400\/100 {
  border-color: rgb(168 162 158 / 1) !important;
}

.tw-border-stone-400\/15 {
  border-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-stone-400\/20 {
  border-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-stone-400\/25 {
  border-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-stone-400\/30 {
  border-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-stone-400\/35 {
  border-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-stone-400\/40 {
  border-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-stone-400\/45 {
  border-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-stone-400\/5 {
  border-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-stone-400\/50 {
  border-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-stone-400\/55 {
  border-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-stone-400\/60 {
  border-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-stone-400\/65 {
  border-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-stone-400\/70 {
  border-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-stone-400\/75 {
  border-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-stone-400\/80 {
  border-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-stone-400\/85 {
  border-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-stone-400\/90 {
  border-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-stone-400\/95 {
  border-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-stone-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-50\/0 {
  border-color: rgb(250 250 249 / 0) !important;
}

.tw-border-stone-50\/10 {
  border-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-stone-50\/100 {
  border-color: rgb(250 250 249 / 1) !important;
}

.tw-border-stone-50\/15 {
  border-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-stone-50\/20 {
  border-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-stone-50\/25 {
  border-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-stone-50\/30 {
  border-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-stone-50\/35 {
  border-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-stone-50\/40 {
  border-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-stone-50\/45 {
  border-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-stone-50\/5 {
  border-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-stone-50\/50 {
  border-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-stone-50\/55 {
  border-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-stone-50\/60 {
  border-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-stone-50\/65 {
  border-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-stone-50\/70 {
  border-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-stone-50\/75 {
  border-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-stone-50\/80 {
  border-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-stone-50\/85 {
  border-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-stone-50\/90 {
  border-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-stone-50\/95 {
  border-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-stone-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-500\/0 {
  border-color: rgb(120 113 108 / 0) !important;
}

.tw-border-stone-500\/10 {
  border-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-stone-500\/100 {
  border-color: rgb(120 113 108 / 1) !important;
}

.tw-border-stone-500\/15 {
  border-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-stone-500\/20 {
  border-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-stone-500\/25 {
  border-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-stone-500\/30 {
  border-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-stone-500\/35 {
  border-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-stone-500\/40 {
  border-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-stone-500\/45 {
  border-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-stone-500\/5 {
  border-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-stone-500\/50 {
  border-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-stone-500\/55 {
  border-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-stone-500\/60 {
  border-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-stone-500\/65 {
  border-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-stone-500\/70 {
  border-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-stone-500\/75 {
  border-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-stone-500\/80 {
  border-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-stone-500\/85 {
  border-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-stone-500\/90 {
  border-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-stone-500\/95 {
  border-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-stone-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-600\/0 {
  border-color: rgb(87 83 78 / 0) !important;
}

.tw-border-stone-600\/10 {
  border-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-stone-600\/100 {
  border-color: rgb(87 83 78 / 1) !important;
}

.tw-border-stone-600\/15 {
  border-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-stone-600\/20 {
  border-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-stone-600\/25 {
  border-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-stone-600\/30 {
  border-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-stone-600\/35 {
  border-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-stone-600\/40 {
  border-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-stone-600\/45 {
  border-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-stone-600\/5 {
  border-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-stone-600\/50 {
  border-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-stone-600\/55 {
  border-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-stone-600\/60 {
  border-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-stone-600\/65 {
  border-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-stone-600\/70 {
  border-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-stone-600\/75 {
  border-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-stone-600\/80 {
  border-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-stone-600\/85 {
  border-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-stone-600\/90 {
  border-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-stone-600\/95 {
  border-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-stone-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-700\/0 {
  border-color: rgb(68 64 60 / 0) !important;
}

.tw-border-stone-700\/10 {
  border-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-stone-700\/100 {
  border-color: rgb(68 64 60 / 1) !important;
}

.tw-border-stone-700\/15 {
  border-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-stone-700\/20 {
  border-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-stone-700\/25 {
  border-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-stone-700\/30 {
  border-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-stone-700\/35 {
  border-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-stone-700\/40 {
  border-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-stone-700\/45 {
  border-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-stone-700\/5 {
  border-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-stone-700\/50 {
  border-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-stone-700\/55 {
  border-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-stone-700\/60 {
  border-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-stone-700\/65 {
  border-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-stone-700\/70 {
  border-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-stone-700\/75 {
  border-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-stone-700\/80 {
  border-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-stone-700\/85 {
  border-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-stone-700\/90 {
  border-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-stone-700\/95 {
  border-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-stone-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-800\/0 {
  border-color: rgb(41 37 36 / 0) !important;
}

.tw-border-stone-800\/10 {
  border-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-stone-800\/100 {
  border-color: rgb(41 37 36 / 1) !important;
}

.tw-border-stone-800\/15 {
  border-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-stone-800\/20 {
  border-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-stone-800\/25 {
  border-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-stone-800\/30 {
  border-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-stone-800\/35 {
  border-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-stone-800\/40 {
  border-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-stone-800\/45 {
  border-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-stone-800\/5 {
  border-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-stone-800\/50 {
  border-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-stone-800\/55 {
  border-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-stone-800\/60 {
  border-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-stone-800\/65 {
  border-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-stone-800\/70 {
  border-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-stone-800\/75 {
  border-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-stone-800\/80 {
  border-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-stone-800\/85 {
  border-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-stone-800\/90 {
  border-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-stone-800\/95 {
  border-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-stone-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-900\/0 {
  border-color: rgb(28 25 23 / 0) !important;
}

.tw-border-stone-900\/10 {
  border-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-stone-900\/100 {
  border-color: rgb(28 25 23 / 1) !important;
}

.tw-border-stone-900\/15 {
  border-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-stone-900\/20 {
  border-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-stone-900\/25 {
  border-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-stone-900\/30 {
  border-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-stone-900\/35 {
  border-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-stone-900\/40 {
  border-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-stone-900\/45 {
  border-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-stone-900\/5 {
  border-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-stone-900\/50 {
  border-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-stone-900\/55 {
  border-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-stone-900\/60 {
  border-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-stone-900\/65 {
  border-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-stone-900\/70 {
  border-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-stone-900\/75 {
  border-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-stone-900\/80 {
  border-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-stone-900\/85 {
  border-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-stone-900\/90 {
  border-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-stone-900\/95 {
  border-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-stone-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-stone-950\/0 {
  border-color: rgb(12 10 9 / 0) !important;
}

.tw-border-stone-950\/10 {
  border-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-stone-950\/100 {
  border-color: rgb(12 10 9 / 1) !important;
}

.tw-border-stone-950\/15 {
  border-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-stone-950\/20 {
  border-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-stone-950\/25 {
  border-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-stone-950\/30 {
  border-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-stone-950\/35 {
  border-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-stone-950\/40 {
  border-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-stone-950\/45 {
  border-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-stone-950\/5 {
  border-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-stone-950\/50 {
  border-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-stone-950\/55 {
  border-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-stone-950\/60 {
  border-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-stone-950\/65 {
  border-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-stone-950\/70 {
  border-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-stone-950\/75 {
  border-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-stone-950\/80 {
  border-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-stone-950\/85 {
  border-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-stone-950\/90 {
  border-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-stone-950\/95 {
  border-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-teal-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-100\/0 {
  border-color: rgb(204 251 241 / 0) !important;
}

.tw-border-teal-100\/10 {
  border-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-teal-100\/100 {
  border-color: rgb(204 251 241 / 1) !important;
}

.tw-border-teal-100\/15 {
  border-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-teal-100\/20 {
  border-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-teal-100\/25 {
  border-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-teal-100\/30 {
  border-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-teal-100\/35 {
  border-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-teal-100\/40 {
  border-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-teal-100\/45 {
  border-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-teal-100\/5 {
  border-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-teal-100\/50 {
  border-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-teal-100\/55 {
  border-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-teal-100\/60 {
  border-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-teal-100\/65 {
  border-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-teal-100\/70 {
  border-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-teal-100\/75 {
  border-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-teal-100\/80 {
  border-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-teal-100\/85 {
  border-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-teal-100\/90 {
  border-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-teal-100\/95 {
  border-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-teal-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-200\/0 {
  border-color: rgb(153 246 228 / 0) !important;
}

.tw-border-teal-200\/10 {
  border-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-teal-200\/100 {
  border-color: rgb(153 246 228 / 1) !important;
}

.tw-border-teal-200\/15 {
  border-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-teal-200\/20 {
  border-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-teal-200\/25 {
  border-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-teal-200\/30 {
  border-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-teal-200\/35 {
  border-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-teal-200\/40 {
  border-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-teal-200\/45 {
  border-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-teal-200\/5 {
  border-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-teal-200\/50 {
  border-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-teal-200\/55 {
  border-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-teal-200\/60 {
  border-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-teal-200\/65 {
  border-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-teal-200\/70 {
  border-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-teal-200\/75 {
  border-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-teal-200\/80 {
  border-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-teal-200\/85 {
  border-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-teal-200\/90 {
  border-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-teal-200\/95 {
  border-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-teal-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-300\/0 {
  border-color: rgb(94 234 212 / 0) !important;
}

.tw-border-teal-300\/10 {
  border-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-teal-300\/100 {
  border-color: rgb(94 234 212 / 1) !important;
}

.tw-border-teal-300\/15 {
  border-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-teal-300\/20 {
  border-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-teal-300\/25 {
  border-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-teal-300\/30 {
  border-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-teal-300\/35 {
  border-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-teal-300\/40 {
  border-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-teal-300\/45 {
  border-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-teal-300\/5 {
  border-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-teal-300\/50 {
  border-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-teal-300\/55 {
  border-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-teal-300\/60 {
  border-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-teal-300\/65 {
  border-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-teal-300\/70 {
  border-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-teal-300\/75 {
  border-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-teal-300\/80 {
  border-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-teal-300\/85 {
  border-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-teal-300\/90 {
  border-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-teal-300\/95 {
  border-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-teal-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-400\/0 {
  border-color: rgb(45 212 191 / 0) !important;
}

.tw-border-teal-400\/10 {
  border-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-teal-400\/100 {
  border-color: rgb(45 212 191 / 1) !important;
}

.tw-border-teal-400\/15 {
  border-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-teal-400\/20 {
  border-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-teal-400\/25 {
  border-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-teal-400\/30 {
  border-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-teal-400\/35 {
  border-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-teal-400\/40 {
  border-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-teal-400\/45 {
  border-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-teal-400\/5 {
  border-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-teal-400\/50 {
  border-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-teal-400\/55 {
  border-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-teal-400\/60 {
  border-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-teal-400\/65 {
  border-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-teal-400\/70 {
  border-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-teal-400\/75 {
  border-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-teal-400\/80 {
  border-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-teal-400\/85 {
  border-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-teal-400\/90 {
  border-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-teal-400\/95 {
  border-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-teal-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-50\/0 {
  border-color: rgb(240 253 250 / 0) !important;
}

.tw-border-teal-50\/10 {
  border-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-teal-50\/100 {
  border-color: rgb(240 253 250 / 1) !important;
}

.tw-border-teal-50\/15 {
  border-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-teal-50\/20 {
  border-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-teal-50\/25 {
  border-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-teal-50\/30 {
  border-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-teal-50\/35 {
  border-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-teal-50\/40 {
  border-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-teal-50\/45 {
  border-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-teal-50\/5 {
  border-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-teal-50\/50 {
  border-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-teal-50\/55 {
  border-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-teal-50\/60 {
  border-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-teal-50\/65 {
  border-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-teal-50\/70 {
  border-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-teal-50\/75 {
  border-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-teal-50\/80 {
  border-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-teal-50\/85 {
  border-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-teal-50\/90 {
  border-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-teal-50\/95 {
  border-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-teal-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-500\/0 {
  border-color: rgb(20 184 166 / 0) !important;
}

.tw-border-teal-500\/10 {
  border-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-teal-500\/100 {
  border-color: rgb(20 184 166 / 1) !important;
}

.tw-border-teal-500\/15 {
  border-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-teal-500\/20 {
  border-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-teal-500\/25 {
  border-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-teal-500\/30 {
  border-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-teal-500\/35 {
  border-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-teal-500\/40 {
  border-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-teal-500\/45 {
  border-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-teal-500\/5 {
  border-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-teal-500\/50 {
  border-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-teal-500\/55 {
  border-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-teal-500\/60 {
  border-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-teal-500\/65 {
  border-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-teal-500\/70 {
  border-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-teal-500\/75 {
  border-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-teal-500\/80 {
  border-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-teal-500\/85 {
  border-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-teal-500\/90 {
  border-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-teal-500\/95 {
  border-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-teal-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-600\/0 {
  border-color: rgb(13 148 136 / 0) !important;
}

.tw-border-teal-600\/10 {
  border-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-teal-600\/100 {
  border-color: rgb(13 148 136 / 1) !important;
}

.tw-border-teal-600\/15 {
  border-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-teal-600\/20 {
  border-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-teal-600\/25 {
  border-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-teal-600\/30 {
  border-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-teal-600\/35 {
  border-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-teal-600\/40 {
  border-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-teal-600\/45 {
  border-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-teal-600\/5 {
  border-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-teal-600\/50 {
  border-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-teal-600\/55 {
  border-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-teal-600\/60 {
  border-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-teal-600\/65 {
  border-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-teal-600\/70 {
  border-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-teal-600\/75 {
  border-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-teal-600\/80 {
  border-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-teal-600\/85 {
  border-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-teal-600\/90 {
  border-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-teal-600\/95 {
  border-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-teal-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-700\/0 {
  border-color: rgb(15 118 110 / 0) !important;
}

.tw-border-teal-700\/10 {
  border-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-teal-700\/100 {
  border-color: rgb(15 118 110 / 1) !important;
}

.tw-border-teal-700\/15 {
  border-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-teal-700\/20 {
  border-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-teal-700\/25 {
  border-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-teal-700\/30 {
  border-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-teal-700\/35 {
  border-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-teal-700\/40 {
  border-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-teal-700\/45 {
  border-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-teal-700\/5 {
  border-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-teal-700\/50 {
  border-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-teal-700\/55 {
  border-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-teal-700\/60 {
  border-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-teal-700\/65 {
  border-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-teal-700\/70 {
  border-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-teal-700\/75 {
  border-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-teal-700\/80 {
  border-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-teal-700\/85 {
  border-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-teal-700\/90 {
  border-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-teal-700\/95 {
  border-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-teal-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-800\/0 {
  border-color: rgb(17 94 89 / 0) !important;
}

.tw-border-teal-800\/10 {
  border-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-teal-800\/100 {
  border-color: rgb(17 94 89 / 1) !important;
}

.tw-border-teal-800\/15 {
  border-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-teal-800\/20 {
  border-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-teal-800\/25 {
  border-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-teal-800\/30 {
  border-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-teal-800\/35 {
  border-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-teal-800\/40 {
  border-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-teal-800\/45 {
  border-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-teal-800\/5 {
  border-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-teal-800\/50 {
  border-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-teal-800\/55 {
  border-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-teal-800\/60 {
  border-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-teal-800\/65 {
  border-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-teal-800\/70 {
  border-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-teal-800\/75 {
  border-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-teal-800\/80 {
  border-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-teal-800\/85 {
  border-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-teal-800\/90 {
  border-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-teal-800\/95 {
  border-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-teal-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-900\/0 {
  border-color: rgb(19 78 74 / 0) !important;
}

.tw-border-teal-900\/10 {
  border-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-teal-900\/100 {
  border-color: rgb(19 78 74 / 1) !important;
}

.tw-border-teal-900\/15 {
  border-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-teal-900\/20 {
  border-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-teal-900\/25 {
  border-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-teal-900\/30 {
  border-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-teal-900\/35 {
  border-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-teal-900\/40 {
  border-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-teal-900\/45 {
  border-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-teal-900\/5 {
  border-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-teal-900\/50 {
  border-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-teal-900\/55 {
  border-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-teal-900\/60 {
  border-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-teal-900\/65 {
  border-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-teal-900\/70 {
  border-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-teal-900\/75 {
  border-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-teal-900\/80 {
  border-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-teal-900\/85 {
  border-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-teal-900\/90 {
  border-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-teal-900\/95 {
  border-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-teal-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-teal-950\/0 {
  border-color: rgb(4 47 46 / 0) !important;
}

.tw-border-teal-950\/10 {
  border-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-teal-950\/100 {
  border-color: rgb(4 47 46 / 1) !important;
}

.tw-border-teal-950\/15 {
  border-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-teal-950\/20 {
  border-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-teal-950\/25 {
  border-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-teal-950\/30 {
  border-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-teal-950\/35 {
  border-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-teal-950\/40 {
  border-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-teal-950\/45 {
  border-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-teal-950\/5 {
  border-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-teal-950\/50 {
  border-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-teal-950\/55 {
  border-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-teal-950\/60 {
  border-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-teal-950\/65 {
  border-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-teal-950\/70 {
  border-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-teal-950\/75 {
  border-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-teal-950\/80 {
  border-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-teal-950\/85 {
  border-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-teal-950\/90 {
  border-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-teal-950\/95 {
  border-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-transparent {
  border-color: transparent !important;
}

.tw-border-transparent\/0 {
  border-color: rgb(0 0 0 / 0) !important;
}

.tw-border-transparent\/10 {
  border-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-transparent\/100 {
  border-color: rgb(0 0 0 / 1) !important;
}

.tw-border-transparent\/15 {
  border-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-transparent\/20 {
  border-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-transparent\/25 {
  border-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-transparent\/30 {
  border-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-transparent\/35 {
  border-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-transparent\/40 {
  border-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-transparent\/45 {
  border-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-transparent\/5 {
  border-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-transparent\/50 {
  border-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-transparent\/55 {
  border-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-transparent\/60 {
  border-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-transparent\/65 {
  border-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-transparent\/70 {
  border-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-transparent\/75 {
  border-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-transparent\/80 {
  border-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-transparent\/85 {
  border-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-transparent\/90 {
  border-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-transparent\/95 {
  border-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-violet-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-100\/0 {
  border-color: rgb(237 233 254 / 0) !important;
}

.tw-border-violet-100\/10 {
  border-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-violet-100\/100 {
  border-color: rgb(237 233 254 / 1) !important;
}

.tw-border-violet-100\/15 {
  border-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-violet-100\/20 {
  border-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-violet-100\/25 {
  border-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-violet-100\/30 {
  border-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-violet-100\/35 {
  border-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-violet-100\/40 {
  border-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-violet-100\/45 {
  border-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-violet-100\/5 {
  border-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-violet-100\/50 {
  border-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-violet-100\/55 {
  border-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-violet-100\/60 {
  border-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-violet-100\/65 {
  border-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-violet-100\/70 {
  border-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-violet-100\/75 {
  border-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-violet-100\/80 {
  border-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-violet-100\/85 {
  border-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-violet-100\/90 {
  border-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-violet-100\/95 {
  border-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-violet-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-200\/0 {
  border-color: rgb(221 214 254 / 0) !important;
}

.tw-border-violet-200\/10 {
  border-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-violet-200\/100 {
  border-color: rgb(221 214 254 / 1) !important;
}

.tw-border-violet-200\/15 {
  border-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-violet-200\/20 {
  border-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-violet-200\/25 {
  border-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-violet-200\/30 {
  border-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-violet-200\/35 {
  border-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-violet-200\/40 {
  border-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-violet-200\/45 {
  border-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-violet-200\/5 {
  border-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-violet-200\/50 {
  border-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-violet-200\/55 {
  border-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-violet-200\/60 {
  border-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-violet-200\/65 {
  border-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-violet-200\/70 {
  border-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-violet-200\/75 {
  border-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-violet-200\/80 {
  border-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-violet-200\/85 {
  border-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-violet-200\/90 {
  border-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-violet-200\/95 {
  border-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-violet-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-300\/0 {
  border-color: rgb(196 181 253 / 0) !important;
}

.tw-border-violet-300\/10 {
  border-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-violet-300\/100 {
  border-color: rgb(196 181 253 / 1) !important;
}

.tw-border-violet-300\/15 {
  border-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-violet-300\/20 {
  border-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-violet-300\/25 {
  border-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-violet-300\/30 {
  border-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-violet-300\/35 {
  border-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-violet-300\/40 {
  border-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-violet-300\/45 {
  border-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-violet-300\/5 {
  border-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-violet-300\/50 {
  border-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-violet-300\/55 {
  border-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-violet-300\/60 {
  border-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-violet-300\/65 {
  border-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-violet-300\/70 {
  border-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-violet-300\/75 {
  border-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-violet-300\/80 {
  border-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-violet-300\/85 {
  border-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-violet-300\/90 {
  border-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-violet-300\/95 {
  border-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-violet-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-400\/0 {
  border-color: rgb(167 139 250 / 0) !important;
}

.tw-border-violet-400\/10 {
  border-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-violet-400\/100 {
  border-color: rgb(167 139 250 / 1) !important;
}

.tw-border-violet-400\/15 {
  border-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-violet-400\/20 {
  border-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-violet-400\/25 {
  border-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-violet-400\/30 {
  border-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-violet-400\/35 {
  border-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-violet-400\/40 {
  border-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-violet-400\/45 {
  border-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-violet-400\/5 {
  border-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-violet-400\/50 {
  border-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-violet-400\/55 {
  border-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-violet-400\/60 {
  border-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-violet-400\/65 {
  border-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-violet-400\/70 {
  border-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-violet-400\/75 {
  border-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-violet-400\/80 {
  border-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-violet-400\/85 {
  border-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-violet-400\/90 {
  border-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-violet-400\/95 {
  border-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-violet-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-50\/0 {
  border-color: rgb(245 243 255 / 0) !important;
}

.tw-border-violet-50\/10 {
  border-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-violet-50\/100 {
  border-color: rgb(245 243 255 / 1) !important;
}

.tw-border-violet-50\/15 {
  border-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-violet-50\/20 {
  border-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-violet-50\/25 {
  border-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-violet-50\/30 {
  border-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-violet-50\/35 {
  border-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-violet-50\/40 {
  border-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-violet-50\/45 {
  border-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-violet-50\/5 {
  border-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-violet-50\/50 {
  border-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-violet-50\/55 {
  border-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-violet-50\/60 {
  border-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-violet-50\/65 {
  border-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-violet-50\/70 {
  border-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-violet-50\/75 {
  border-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-violet-50\/80 {
  border-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-violet-50\/85 {
  border-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-violet-50\/90 {
  border-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-violet-50\/95 {
  border-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-violet-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-500\/0 {
  border-color: rgb(139 92 246 / 0) !important;
}

.tw-border-violet-500\/10 {
  border-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-violet-500\/100 {
  border-color: rgb(139 92 246 / 1) !important;
}

.tw-border-violet-500\/15 {
  border-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-violet-500\/20 {
  border-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-violet-500\/25 {
  border-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-violet-500\/30 {
  border-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-violet-500\/35 {
  border-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-violet-500\/40 {
  border-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-violet-500\/45 {
  border-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-violet-500\/5 {
  border-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-violet-500\/50 {
  border-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-violet-500\/55 {
  border-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-violet-500\/60 {
  border-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-violet-500\/65 {
  border-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-violet-500\/70 {
  border-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-violet-500\/75 {
  border-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-violet-500\/80 {
  border-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-violet-500\/85 {
  border-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-violet-500\/90 {
  border-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-violet-500\/95 {
  border-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-violet-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-600\/0 {
  border-color: rgb(124 58 237 / 0) !important;
}

.tw-border-violet-600\/10 {
  border-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-violet-600\/100 {
  border-color: rgb(124 58 237 / 1) !important;
}

.tw-border-violet-600\/15 {
  border-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-violet-600\/20 {
  border-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-violet-600\/25 {
  border-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-violet-600\/30 {
  border-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-violet-600\/35 {
  border-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-violet-600\/40 {
  border-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-violet-600\/45 {
  border-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-violet-600\/5 {
  border-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-violet-600\/50 {
  border-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-violet-600\/55 {
  border-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-violet-600\/60 {
  border-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-violet-600\/65 {
  border-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-violet-600\/70 {
  border-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-violet-600\/75 {
  border-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-violet-600\/80 {
  border-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-violet-600\/85 {
  border-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-violet-600\/90 {
  border-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-violet-600\/95 {
  border-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-violet-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-700\/0 {
  border-color: rgb(109 40 217 / 0) !important;
}

.tw-border-violet-700\/10 {
  border-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-violet-700\/100 {
  border-color: rgb(109 40 217 / 1) !important;
}

.tw-border-violet-700\/15 {
  border-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-violet-700\/20 {
  border-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-violet-700\/25 {
  border-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-violet-700\/30 {
  border-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-violet-700\/35 {
  border-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-violet-700\/40 {
  border-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-violet-700\/45 {
  border-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-violet-700\/5 {
  border-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-violet-700\/50 {
  border-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-violet-700\/55 {
  border-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-violet-700\/60 {
  border-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-violet-700\/65 {
  border-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-violet-700\/70 {
  border-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-violet-700\/75 {
  border-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-violet-700\/80 {
  border-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-violet-700\/85 {
  border-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-violet-700\/90 {
  border-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-violet-700\/95 {
  border-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-violet-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-800\/0 {
  border-color: rgb(91 33 182 / 0) !important;
}

.tw-border-violet-800\/10 {
  border-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-violet-800\/100 {
  border-color: rgb(91 33 182 / 1) !important;
}

.tw-border-violet-800\/15 {
  border-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-violet-800\/20 {
  border-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-violet-800\/25 {
  border-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-violet-800\/30 {
  border-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-violet-800\/35 {
  border-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-violet-800\/40 {
  border-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-violet-800\/45 {
  border-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-violet-800\/5 {
  border-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-violet-800\/50 {
  border-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-violet-800\/55 {
  border-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-violet-800\/60 {
  border-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-violet-800\/65 {
  border-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-violet-800\/70 {
  border-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-violet-800\/75 {
  border-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-violet-800\/80 {
  border-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-violet-800\/85 {
  border-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-violet-800\/90 {
  border-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-violet-800\/95 {
  border-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-violet-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-900\/0 {
  border-color: rgb(76 29 149 / 0) !important;
}

.tw-border-violet-900\/10 {
  border-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-violet-900\/100 {
  border-color: rgb(76 29 149 / 1) !important;
}

.tw-border-violet-900\/15 {
  border-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-violet-900\/20 {
  border-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-violet-900\/25 {
  border-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-violet-900\/30 {
  border-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-violet-900\/35 {
  border-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-violet-900\/40 {
  border-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-violet-900\/45 {
  border-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-violet-900\/5 {
  border-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-violet-900\/50 {
  border-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-violet-900\/55 {
  border-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-violet-900\/60 {
  border-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-violet-900\/65 {
  border-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-violet-900\/70 {
  border-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-violet-900\/75 {
  border-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-violet-900\/80 {
  border-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-violet-900\/85 {
  border-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-violet-900\/90 {
  border-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-violet-900\/95 {
  border-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-violet-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-violet-950\/0 {
  border-color: rgb(46 16 101 / 0) !important;
}

.tw-border-violet-950\/10 {
  border-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-violet-950\/100 {
  border-color: rgb(46 16 101 / 1) !important;
}

.tw-border-violet-950\/15 {
  border-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-violet-950\/20 {
  border-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-violet-950\/25 {
  border-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-violet-950\/30 {
  border-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-violet-950\/35 {
  border-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-violet-950\/40 {
  border-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-violet-950\/45 {
  border-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-violet-950\/5 {
  border-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-violet-950\/50 {
  border-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-violet-950\/55 {
  border-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-violet-950\/60 {
  border-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-violet-950\/65 {
  border-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-violet-950\/70 {
  border-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-violet-950\/75 {
  border-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-violet-950\/80 {
  border-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-violet-950\/85 {
  border-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-violet-950\/90 {
  border-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-violet-950\/95 {
  border-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-white {
  --tw-border-opacity: 1 !important;
  border-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-white\/0 {
  border-color: rgb(255 255 255 / 0) !important;
}

.tw-border-white\/10 {
  border-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-white\/100 {
  border-color: rgb(255 255 255 / 1) !important;
}

.tw-border-white\/15 {
  border-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-white\/20 {
  border-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-white\/25 {
  border-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-white\/30 {
  border-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-white\/35 {
  border-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-white\/40 {
  border-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-white\/45 {
  border-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-white\/5 {
  border-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-white\/50 {
  border-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-white\/55 {
  border-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-white\/60 {
  border-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-white\/65 {
  border-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-white\/70 {
  border-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-white\/75 {
  border-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-white\/80 {
  border-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-white\/85 {
  border-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-white\/90 {
  border-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-white\/95 {
  border-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-100\/0 {
  border-color: rgb(254 249 195 / 0) !important;
}

.tw-border-yellow-100\/10 {
  border-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-yellow-100\/100 {
  border-color: rgb(254 249 195 / 1) !important;
}

.tw-border-yellow-100\/15 {
  border-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-yellow-100\/20 {
  border-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-yellow-100\/25 {
  border-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-yellow-100\/30 {
  border-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-yellow-100\/35 {
  border-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-yellow-100\/40 {
  border-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-yellow-100\/45 {
  border-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-yellow-100\/5 {
  border-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-yellow-100\/50 {
  border-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-yellow-100\/55 {
  border-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-yellow-100\/60 {
  border-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-yellow-100\/65 {
  border-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-yellow-100\/70 {
  border-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-yellow-100\/75 {
  border-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-yellow-100\/80 {
  border-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-yellow-100\/85 {
  border-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-yellow-100\/90 {
  border-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-yellow-100\/95 {
  border-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-200\/0 {
  border-color: rgb(254 240 138 / 0) !important;
}

.tw-border-yellow-200\/10 {
  border-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-yellow-200\/100 {
  border-color: rgb(254 240 138 / 1) !important;
}

.tw-border-yellow-200\/15 {
  border-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-yellow-200\/20 {
  border-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-yellow-200\/25 {
  border-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-yellow-200\/30 {
  border-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-yellow-200\/35 {
  border-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-yellow-200\/40 {
  border-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-yellow-200\/45 {
  border-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-yellow-200\/5 {
  border-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-yellow-200\/50 {
  border-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-yellow-200\/55 {
  border-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-yellow-200\/60 {
  border-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-yellow-200\/65 {
  border-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-yellow-200\/70 {
  border-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-yellow-200\/75 {
  border-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-yellow-200\/80 {
  border-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-yellow-200\/85 {
  border-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-yellow-200\/90 {
  border-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-yellow-200\/95 {
  border-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-300\/0 {
  border-color: rgb(253 224 71 / 0) !important;
}

.tw-border-yellow-300\/10 {
  border-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-yellow-300\/100 {
  border-color: rgb(253 224 71 / 1) !important;
}

.tw-border-yellow-300\/15 {
  border-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-yellow-300\/20 {
  border-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-yellow-300\/25 {
  border-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-yellow-300\/30 {
  border-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-yellow-300\/35 {
  border-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-yellow-300\/40 {
  border-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-yellow-300\/45 {
  border-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-yellow-300\/5 {
  border-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-yellow-300\/50 {
  border-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-yellow-300\/55 {
  border-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-yellow-300\/60 {
  border-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-yellow-300\/65 {
  border-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-yellow-300\/70 {
  border-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-yellow-300\/75 {
  border-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-yellow-300\/80 {
  border-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-yellow-300\/85 {
  border-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-yellow-300\/90 {
  border-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-yellow-300\/95 {
  border-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-400\/0 {
  border-color: rgb(250 204 21 / 0) !important;
}

.tw-border-yellow-400\/10 {
  border-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-yellow-400\/100 {
  border-color: rgb(250 204 21 / 1) !important;
}

.tw-border-yellow-400\/15 {
  border-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-yellow-400\/20 {
  border-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-yellow-400\/25 {
  border-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-yellow-400\/30 {
  border-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-yellow-400\/35 {
  border-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-yellow-400\/40 {
  border-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-yellow-400\/45 {
  border-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-yellow-400\/5 {
  border-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-yellow-400\/50 {
  border-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-yellow-400\/55 {
  border-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-yellow-400\/60 {
  border-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-yellow-400\/65 {
  border-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-yellow-400\/70 {
  border-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-yellow-400\/75 {
  border-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-yellow-400\/80 {
  border-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-yellow-400\/85 {
  border-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-yellow-400\/90 {
  border-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-yellow-400\/95 {
  border-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-50\/0 {
  border-color: rgb(254 252 232 / 0) !important;
}

.tw-border-yellow-50\/10 {
  border-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-yellow-50\/100 {
  border-color: rgb(254 252 232 / 1) !important;
}

.tw-border-yellow-50\/15 {
  border-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-yellow-50\/20 {
  border-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-yellow-50\/25 {
  border-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-yellow-50\/30 {
  border-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-yellow-50\/35 {
  border-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-yellow-50\/40 {
  border-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-yellow-50\/45 {
  border-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-yellow-50\/5 {
  border-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-yellow-50\/50 {
  border-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-yellow-50\/55 {
  border-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-yellow-50\/60 {
  border-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-yellow-50\/65 {
  border-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-yellow-50\/70 {
  border-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-yellow-50\/75 {
  border-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-yellow-50\/80 {
  border-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-yellow-50\/85 {
  border-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-yellow-50\/90 {
  border-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-yellow-50\/95 {
  border-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-500\/0 {
  border-color: rgb(234 179 8 / 0) !important;
}

.tw-border-yellow-500\/10 {
  border-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-yellow-500\/100 {
  border-color: rgb(234 179 8 / 1) !important;
}

.tw-border-yellow-500\/15 {
  border-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-yellow-500\/20 {
  border-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-yellow-500\/25 {
  border-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-yellow-500\/30 {
  border-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-yellow-500\/35 {
  border-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-yellow-500\/40 {
  border-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-yellow-500\/45 {
  border-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-yellow-500\/5 {
  border-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-yellow-500\/50 {
  border-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-yellow-500\/55 {
  border-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-yellow-500\/60 {
  border-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-yellow-500\/65 {
  border-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-yellow-500\/70 {
  border-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-yellow-500\/75 {
  border-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-yellow-500\/80 {
  border-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-yellow-500\/85 {
  border-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-yellow-500\/90 {
  border-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-yellow-500\/95 {
  border-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-600\/0 {
  border-color: rgb(202 138 4 / 0) !important;
}

.tw-border-yellow-600\/10 {
  border-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-yellow-600\/100 {
  border-color: rgb(202 138 4 / 1) !important;
}

.tw-border-yellow-600\/15 {
  border-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-yellow-600\/20 {
  border-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-yellow-600\/25 {
  border-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-yellow-600\/30 {
  border-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-yellow-600\/35 {
  border-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-yellow-600\/40 {
  border-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-yellow-600\/45 {
  border-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-yellow-600\/5 {
  border-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-yellow-600\/50 {
  border-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-yellow-600\/55 {
  border-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-yellow-600\/60 {
  border-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-yellow-600\/65 {
  border-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-yellow-600\/70 {
  border-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-yellow-600\/75 {
  border-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-yellow-600\/80 {
  border-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-yellow-600\/85 {
  border-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-yellow-600\/90 {
  border-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-yellow-600\/95 {
  border-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-700\/0 {
  border-color: rgb(161 98 7 / 0) !important;
}

.tw-border-yellow-700\/10 {
  border-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-yellow-700\/100 {
  border-color: rgb(161 98 7 / 1) !important;
}

.tw-border-yellow-700\/15 {
  border-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-yellow-700\/20 {
  border-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-yellow-700\/25 {
  border-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-yellow-700\/30 {
  border-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-yellow-700\/35 {
  border-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-yellow-700\/40 {
  border-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-yellow-700\/45 {
  border-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-yellow-700\/5 {
  border-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-yellow-700\/50 {
  border-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-yellow-700\/55 {
  border-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-yellow-700\/60 {
  border-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-yellow-700\/65 {
  border-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-yellow-700\/70 {
  border-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-yellow-700\/75 {
  border-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-yellow-700\/80 {
  border-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-yellow-700\/85 {
  border-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-yellow-700\/90 {
  border-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-yellow-700\/95 {
  border-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-800\/0 {
  border-color: rgb(133 77 14 / 0) !important;
}

.tw-border-yellow-800\/10 {
  border-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-yellow-800\/100 {
  border-color: rgb(133 77 14 / 1) !important;
}

.tw-border-yellow-800\/15 {
  border-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-yellow-800\/20 {
  border-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-yellow-800\/25 {
  border-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-yellow-800\/30 {
  border-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-yellow-800\/35 {
  border-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-yellow-800\/40 {
  border-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-yellow-800\/45 {
  border-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-yellow-800\/5 {
  border-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-yellow-800\/50 {
  border-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-yellow-800\/55 {
  border-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-yellow-800\/60 {
  border-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-yellow-800\/65 {
  border-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-yellow-800\/70 {
  border-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-yellow-800\/75 {
  border-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-yellow-800\/80 {
  border-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-yellow-800\/85 {
  border-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-yellow-800\/90 {
  border-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-yellow-800\/95 {
  border-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-900\/0 {
  border-color: rgb(113 63 18 / 0) !important;
}

.tw-border-yellow-900\/10 {
  border-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-yellow-900\/100 {
  border-color: rgb(113 63 18 / 1) !important;
}

.tw-border-yellow-900\/15 {
  border-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-yellow-900\/20 {
  border-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-yellow-900\/25 {
  border-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-yellow-900\/30 {
  border-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-yellow-900\/35 {
  border-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-yellow-900\/40 {
  border-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-yellow-900\/45 {
  border-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-yellow-900\/5 {
  border-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-yellow-900\/50 {
  border-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-yellow-900\/55 {
  border-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-yellow-900\/60 {
  border-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-yellow-900\/65 {
  border-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-yellow-900\/70 {
  border-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-yellow-900\/75 {
  border-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-yellow-900\/80 {
  border-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-yellow-900\/85 {
  border-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-yellow-900\/90 {
  border-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-yellow-900\/95 {
  border-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-yellow-950\/0 {
  border-color: rgb(66 32 6 / 0) !important;
}

.tw-border-yellow-950\/10 {
  border-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-yellow-950\/100 {
  border-color: rgb(66 32 6 / 1) !important;
}

.tw-border-yellow-950\/15 {
  border-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-yellow-950\/20 {
  border-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-yellow-950\/25 {
  border-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-yellow-950\/30 {
  border-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-yellow-950\/35 {
  border-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-yellow-950\/40 {
  border-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-yellow-950\/45 {
  border-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-yellow-950\/5 {
  border-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-yellow-950\/50 {
  border-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-yellow-950\/55 {
  border-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-yellow-950\/60 {
  border-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-yellow-950\/65 {
  border-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-yellow-950\/70 {
  border-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-yellow-950\/75 {
  border-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-yellow-950\/80 {
  border-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-yellow-950\/85 {
  border-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-yellow-950\/90 {
  border-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-yellow-950\/95 {
  border-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-100\/0 {
  border-color: rgb(244 244 245 / 0) !important;
}

.tw-border-zinc-100\/10 {
  border-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-zinc-100\/100 {
  border-color: rgb(244 244 245 / 1) !important;
}

.tw-border-zinc-100\/15 {
  border-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-zinc-100\/20 {
  border-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-zinc-100\/25 {
  border-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-zinc-100\/30 {
  border-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-zinc-100\/35 {
  border-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-zinc-100\/40 {
  border-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-zinc-100\/45 {
  border-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-zinc-100\/5 {
  border-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-zinc-100\/50 {
  border-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-zinc-100\/55 {
  border-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-zinc-100\/60 {
  border-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-zinc-100\/65 {
  border-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-zinc-100\/70 {
  border-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-zinc-100\/75 {
  border-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-zinc-100\/80 {
  border-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-zinc-100\/85 {
  border-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-zinc-100\/90 {
  border-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-zinc-100\/95 {
  border-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-200\/0 {
  border-color: rgb(228 228 231 / 0) !important;
}

.tw-border-zinc-200\/10 {
  border-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-zinc-200\/100 {
  border-color: rgb(228 228 231 / 1) !important;
}

.tw-border-zinc-200\/15 {
  border-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-zinc-200\/20 {
  border-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-zinc-200\/25 {
  border-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-zinc-200\/30 {
  border-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-zinc-200\/35 {
  border-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-zinc-200\/40 {
  border-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-zinc-200\/45 {
  border-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-zinc-200\/5 {
  border-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-zinc-200\/50 {
  border-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-zinc-200\/55 {
  border-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-zinc-200\/60 {
  border-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-zinc-200\/65 {
  border-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-zinc-200\/70 {
  border-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-zinc-200\/75 {
  border-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-zinc-200\/80 {
  border-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-zinc-200\/85 {
  border-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-zinc-200\/90 {
  border-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-zinc-200\/95 {
  border-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-300\/0 {
  border-color: rgb(212 212 216 / 0) !important;
}

.tw-border-zinc-300\/10 {
  border-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-zinc-300\/100 {
  border-color: rgb(212 212 216 / 1) !important;
}

.tw-border-zinc-300\/15 {
  border-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-zinc-300\/20 {
  border-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-zinc-300\/25 {
  border-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-zinc-300\/30 {
  border-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-zinc-300\/35 {
  border-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-zinc-300\/40 {
  border-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-zinc-300\/45 {
  border-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-zinc-300\/5 {
  border-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-zinc-300\/50 {
  border-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-zinc-300\/55 {
  border-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-zinc-300\/60 {
  border-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-zinc-300\/65 {
  border-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-zinc-300\/70 {
  border-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-zinc-300\/75 {
  border-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-zinc-300\/80 {
  border-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-zinc-300\/85 {
  border-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-zinc-300\/90 {
  border-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-zinc-300\/95 {
  border-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-400\/0 {
  border-color: rgb(161 161 170 / 0) !important;
}

.tw-border-zinc-400\/10 {
  border-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-zinc-400\/100 {
  border-color: rgb(161 161 170 / 1) !important;
}

.tw-border-zinc-400\/15 {
  border-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-zinc-400\/20 {
  border-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-zinc-400\/25 {
  border-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-zinc-400\/30 {
  border-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-zinc-400\/35 {
  border-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-zinc-400\/40 {
  border-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-zinc-400\/45 {
  border-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-zinc-400\/5 {
  border-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-zinc-400\/50 {
  border-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-zinc-400\/55 {
  border-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-zinc-400\/60 {
  border-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-zinc-400\/65 {
  border-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-zinc-400\/70 {
  border-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-zinc-400\/75 {
  border-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-zinc-400\/80 {
  border-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-zinc-400\/85 {
  border-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-zinc-400\/90 {
  border-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-zinc-400\/95 {
  border-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-50\/0 {
  border-color: rgb(250 250 250 / 0) !important;
}

.tw-border-zinc-50\/10 {
  border-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-zinc-50\/100 {
  border-color: rgb(250 250 250 / 1) !important;
}

.tw-border-zinc-50\/15 {
  border-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-zinc-50\/20 {
  border-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-zinc-50\/25 {
  border-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-zinc-50\/30 {
  border-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-zinc-50\/35 {
  border-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-zinc-50\/40 {
  border-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-zinc-50\/45 {
  border-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-zinc-50\/5 {
  border-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-zinc-50\/50 {
  border-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-zinc-50\/55 {
  border-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-zinc-50\/60 {
  border-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-zinc-50\/65 {
  border-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-zinc-50\/70 {
  border-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-zinc-50\/75 {
  border-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-zinc-50\/80 {
  border-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-zinc-50\/85 {
  border-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-zinc-50\/90 {
  border-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-zinc-50\/95 {
  border-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-500\/0 {
  border-color: rgb(113 113 122 / 0) !important;
}

.tw-border-zinc-500\/10 {
  border-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-zinc-500\/100 {
  border-color: rgb(113 113 122 / 1) !important;
}

.tw-border-zinc-500\/15 {
  border-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-zinc-500\/20 {
  border-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-zinc-500\/25 {
  border-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-zinc-500\/30 {
  border-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-zinc-500\/35 {
  border-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-zinc-500\/40 {
  border-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-zinc-500\/45 {
  border-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-zinc-500\/5 {
  border-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-zinc-500\/50 {
  border-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-zinc-500\/55 {
  border-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-zinc-500\/60 {
  border-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-zinc-500\/65 {
  border-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-zinc-500\/70 {
  border-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-zinc-500\/75 {
  border-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-zinc-500\/80 {
  border-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-zinc-500\/85 {
  border-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-zinc-500\/90 {
  border-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-zinc-500\/95 {
  border-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-600\/0 {
  border-color: rgb(82 82 91 / 0) !important;
}

.tw-border-zinc-600\/10 {
  border-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-zinc-600\/100 {
  border-color: rgb(82 82 91 / 1) !important;
}

.tw-border-zinc-600\/15 {
  border-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-zinc-600\/20 {
  border-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-zinc-600\/25 {
  border-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-zinc-600\/30 {
  border-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-zinc-600\/35 {
  border-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-zinc-600\/40 {
  border-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-zinc-600\/45 {
  border-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-zinc-600\/5 {
  border-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-zinc-600\/50 {
  border-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-zinc-600\/55 {
  border-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-zinc-600\/60 {
  border-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-zinc-600\/65 {
  border-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-zinc-600\/70 {
  border-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-zinc-600\/75 {
  border-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-zinc-600\/80 {
  border-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-zinc-600\/85 {
  border-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-zinc-600\/90 {
  border-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-zinc-600\/95 {
  border-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-700\/0 {
  border-color: rgb(63 63 70 / 0) !important;
}

.tw-border-zinc-700\/10 {
  border-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-zinc-700\/100 {
  border-color: rgb(63 63 70 / 1) !important;
}

.tw-border-zinc-700\/15 {
  border-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-zinc-700\/20 {
  border-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-zinc-700\/25 {
  border-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-zinc-700\/30 {
  border-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-zinc-700\/35 {
  border-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-zinc-700\/40 {
  border-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-zinc-700\/45 {
  border-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-zinc-700\/5 {
  border-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-zinc-700\/50 {
  border-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-zinc-700\/55 {
  border-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-zinc-700\/60 {
  border-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-zinc-700\/65 {
  border-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-zinc-700\/70 {
  border-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-zinc-700\/75 {
  border-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-zinc-700\/80 {
  border-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-zinc-700\/85 {
  border-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-zinc-700\/90 {
  border-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-zinc-700\/95 {
  border-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-800\/0 {
  border-color: rgb(39 39 42 / 0) !important;
}

.tw-border-zinc-800\/10 {
  border-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-zinc-800\/100 {
  border-color: rgb(39 39 42 / 1) !important;
}

.tw-border-zinc-800\/15 {
  border-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-zinc-800\/20 {
  border-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-zinc-800\/25 {
  border-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-zinc-800\/30 {
  border-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-zinc-800\/35 {
  border-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-zinc-800\/40 {
  border-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-zinc-800\/45 {
  border-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-zinc-800\/5 {
  border-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-zinc-800\/50 {
  border-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-zinc-800\/55 {
  border-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-zinc-800\/60 {
  border-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-zinc-800\/65 {
  border-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-zinc-800\/70 {
  border-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-zinc-800\/75 {
  border-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-zinc-800\/80 {
  border-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-zinc-800\/85 {
  border-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-zinc-800\/90 {
  border-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-zinc-800\/95 {
  border-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-900\/0 {
  border-color: rgb(24 24 27 / 0) !important;
}

.tw-border-zinc-900\/10 {
  border-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-zinc-900\/100 {
  border-color: rgb(24 24 27 / 1) !important;
}

.tw-border-zinc-900\/15 {
  border-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-zinc-900\/20 {
  border-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-zinc-900\/25 {
  border-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-zinc-900\/30 {
  border-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-zinc-900\/35 {
  border-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-zinc-900\/40 {
  border-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-zinc-900\/45 {
  border-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-zinc-900\/5 {
  border-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-zinc-900\/50 {
  border-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-zinc-900\/55 {
  border-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-zinc-900\/60 {
  border-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-zinc-900\/65 {
  border-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-zinc-900\/70 {
  border-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-zinc-900\/75 {
  border-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-zinc-900\/80 {
  border-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-zinc-900\/85 {
  border-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-zinc-900\/90 {
  border-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-zinc-900\/95 {
  border-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-zinc-950\/0 {
  border-color: rgb(9 9 11 / 0) !important;
}

.tw-border-zinc-950\/10 {
  border-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-zinc-950\/100 {
  border-color: rgb(9 9 11 / 1) !important;
}

.tw-border-zinc-950\/15 {
  border-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-zinc-950\/20 {
  border-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-zinc-950\/25 {
  border-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-zinc-950\/30 {
  border-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-zinc-950\/35 {
  border-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-zinc-950\/40 {
  border-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-zinc-950\/45 {
  border-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-zinc-950\/5 {
  border-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-zinc-950\/50 {
  border-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-zinc-950\/55 {
  border-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-zinc-950\/60 {
  border-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-zinc-950\/65 {
  border-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-zinc-950\/70 {
  border-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-zinc-950\/75 {
  border-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-zinc-950\/80 {
  border-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-zinc-950\/85 {
  border-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-zinc-950\/90 {
  border-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-zinc-950\/95 {
  border-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-x-amber-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-100\/0 {
  border-left-color: rgb(254 243 199 / 0) !important;
  border-right-color: rgb(254 243 199 / 0) !important;
}

.tw-border-x-amber-100\/10 {
  border-left-color: rgb(254 243 199 / 0.1) !important;
  border-right-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-x-amber-100\/100 {
  border-left-color: rgb(254 243 199 / 1) !important;
  border-right-color: rgb(254 243 199 / 1) !important;
}

.tw-border-x-amber-100\/15 {
  border-left-color: rgb(254 243 199 / 0.15) !important;
  border-right-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-x-amber-100\/20 {
  border-left-color: rgb(254 243 199 / 0.2) !important;
  border-right-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-x-amber-100\/25 {
  border-left-color: rgb(254 243 199 / 0.25) !important;
  border-right-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-x-amber-100\/30 {
  border-left-color: rgb(254 243 199 / 0.3) !important;
  border-right-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-x-amber-100\/35 {
  border-left-color: rgb(254 243 199 / 0.35) !important;
  border-right-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-x-amber-100\/40 {
  border-left-color: rgb(254 243 199 / 0.4) !important;
  border-right-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-x-amber-100\/45 {
  border-left-color: rgb(254 243 199 / 0.45) !important;
  border-right-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-x-amber-100\/5 {
  border-left-color: rgb(254 243 199 / 0.05) !important;
  border-right-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-x-amber-100\/50 {
  border-left-color: rgb(254 243 199 / 0.5) !important;
  border-right-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-x-amber-100\/55 {
  border-left-color: rgb(254 243 199 / 0.55) !important;
  border-right-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-x-amber-100\/60 {
  border-left-color: rgb(254 243 199 / 0.6) !important;
  border-right-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-x-amber-100\/65 {
  border-left-color: rgb(254 243 199 / 0.65) !important;
  border-right-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-x-amber-100\/70 {
  border-left-color: rgb(254 243 199 / 0.7) !important;
  border-right-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-x-amber-100\/75 {
  border-left-color: rgb(254 243 199 / 0.75) !important;
  border-right-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-x-amber-100\/80 {
  border-left-color: rgb(254 243 199 / 0.8) !important;
  border-right-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-x-amber-100\/85 {
  border-left-color: rgb(254 243 199 / 0.85) !important;
  border-right-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-x-amber-100\/90 {
  border-left-color: rgb(254 243 199 / 0.9) !important;
  border-right-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-x-amber-100\/95 {
  border-left-color: rgb(254 243 199 / 0.95) !important;
  border-right-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-x-amber-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-200\/0 {
  border-left-color: rgb(253 230 138 / 0) !important;
  border-right-color: rgb(253 230 138 / 0) !important;
}

.tw-border-x-amber-200\/10 {
  border-left-color: rgb(253 230 138 / 0.1) !important;
  border-right-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-x-amber-200\/100 {
  border-left-color: rgb(253 230 138 / 1) !important;
  border-right-color: rgb(253 230 138 / 1) !important;
}

.tw-border-x-amber-200\/15 {
  border-left-color: rgb(253 230 138 / 0.15) !important;
  border-right-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-x-amber-200\/20 {
  border-left-color: rgb(253 230 138 / 0.2) !important;
  border-right-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-x-amber-200\/25 {
  border-left-color: rgb(253 230 138 / 0.25) !important;
  border-right-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-x-amber-200\/30 {
  border-left-color: rgb(253 230 138 / 0.3) !important;
  border-right-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-x-amber-200\/35 {
  border-left-color: rgb(253 230 138 / 0.35) !important;
  border-right-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-x-amber-200\/40 {
  border-left-color: rgb(253 230 138 / 0.4) !important;
  border-right-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-x-amber-200\/45 {
  border-left-color: rgb(253 230 138 / 0.45) !important;
  border-right-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-x-amber-200\/5 {
  border-left-color: rgb(253 230 138 / 0.05) !important;
  border-right-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-x-amber-200\/50 {
  border-left-color: rgb(253 230 138 / 0.5) !important;
  border-right-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-x-amber-200\/55 {
  border-left-color: rgb(253 230 138 / 0.55) !important;
  border-right-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-x-amber-200\/60 {
  border-left-color: rgb(253 230 138 / 0.6) !important;
  border-right-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-x-amber-200\/65 {
  border-left-color: rgb(253 230 138 / 0.65) !important;
  border-right-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-x-amber-200\/70 {
  border-left-color: rgb(253 230 138 / 0.7) !important;
  border-right-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-x-amber-200\/75 {
  border-left-color: rgb(253 230 138 / 0.75) !important;
  border-right-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-x-amber-200\/80 {
  border-left-color: rgb(253 230 138 / 0.8) !important;
  border-right-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-x-amber-200\/85 {
  border-left-color: rgb(253 230 138 / 0.85) !important;
  border-right-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-x-amber-200\/90 {
  border-left-color: rgb(253 230 138 / 0.9) !important;
  border-right-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-x-amber-200\/95 {
  border-left-color: rgb(253 230 138 / 0.95) !important;
  border-right-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-x-amber-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-300\/0 {
  border-left-color: rgb(252 211 77 / 0) !important;
  border-right-color: rgb(252 211 77 / 0) !important;
}

.tw-border-x-amber-300\/10 {
  border-left-color: rgb(252 211 77 / 0.1) !important;
  border-right-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-x-amber-300\/100 {
  border-left-color: rgb(252 211 77 / 1) !important;
  border-right-color: rgb(252 211 77 / 1) !important;
}

.tw-border-x-amber-300\/15 {
  border-left-color: rgb(252 211 77 / 0.15) !important;
  border-right-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-x-amber-300\/20 {
  border-left-color: rgb(252 211 77 / 0.2) !important;
  border-right-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-x-amber-300\/25 {
  border-left-color: rgb(252 211 77 / 0.25) !important;
  border-right-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-x-amber-300\/30 {
  border-left-color: rgb(252 211 77 / 0.3) !important;
  border-right-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-x-amber-300\/35 {
  border-left-color: rgb(252 211 77 / 0.35) !important;
  border-right-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-x-amber-300\/40 {
  border-left-color: rgb(252 211 77 / 0.4) !important;
  border-right-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-x-amber-300\/45 {
  border-left-color: rgb(252 211 77 / 0.45) !important;
  border-right-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-x-amber-300\/5 {
  border-left-color: rgb(252 211 77 / 0.05) !important;
  border-right-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-x-amber-300\/50 {
  border-left-color: rgb(252 211 77 / 0.5) !important;
  border-right-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-x-amber-300\/55 {
  border-left-color: rgb(252 211 77 / 0.55) !important;
  border-right-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-x-amber-300\/60 {
  border-left-color: rgb(252 211 77 / 0.6) !important;
  border-right-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-x-amber-300\/65 {
  border-left-color: rgb(252 211 77 / 0.65) !important;
  border-right-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-x-amber-300\/70 {
  border-left-color: rgb(252 211 77 / 0.7) !important;
  border-right-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-x-amber-300\/75 {
  border-left-color: rgb(252 211 77 / 0.75) !important;
  border-right-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-x-amber-300\/80 {
  border-left-color: rgb(252 211 77 / 0.8) !important;
  border-right-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-x-amber-300\/85 {
  border-left-color: rgb(252 211 77 / 0.85) !important;
  border-right-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-x-amber-300\/90 {
  border-left-color: rgb(252 211 77 / 0.9) !important;
  border-right-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-x-amber-300\/95 {
  border-left-color: rgb(252 211 77 / 0.95) !important;
  border-right-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-x-amber-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-400\/0 {
  border-left-color: rgb(251 191 36 / 0) !important;
  border-right-color: rgb(251 191 36 / 0) !important;
}

.tw-border-x-amber-400\/10 {
  border-left-color: rgb(251 191 36 / 0.1) !important;
  border-right-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-x-amber-400\/100 {
  border-left-color: rgb(251 191 36 / 1) !important;
  border-right-color: rgb(251 191 36 / 1) !important;
}

.tw-border-x-amber-400\/15 {
  border-left-color: rgb(251 191 36 / 0.15) !important;
  border-right-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-x-amber-400\/20 {
  border-left-color: rgb(251 191 36 / 0.2) !important;
  border-right-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-x-amber-400\/25 {
  border-left-color: rgb(251 191 36 / 0.25) !important;
  border-right-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-x-amber-400\/30 {
  border-left-color: rgb(251 191 36 / 0.3) !important;
  border-right-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-x-amber-400\/35 {
  border-left-color: rgb(251 191 36 / 0.35) !important;
  border-right-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-x-amber-400\/40 {
  border-left-color: rgb(251 191 36 / 0.4) !important;
  border-right-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-x-amber-400\/45 {
  border-left-color: rgb(251 191 36 / 0.45) !important;
  border-right-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-x-amber-400\/5 {
  border-left-color: rgb(251 191 36 / 0.05) !important;
  border-right-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-x-amber-400\/50 {
  border-left-color: rgb(251 191 36 / 0.5) !important;
  border-right-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-x-amber-400\/55 {
  border-left-color: rgb(251 191 36 / 0.55) !important;
  border-right-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-x-amber-400\/60 {
  border-left-color: rgb(251 191 36 / 0.6) !important;
  border-right-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-x-amber-400\/65 {
  border-left-color: rgb(251 191 36 / 0.65) !important;
  border-right-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-x-amber-400\/70 {
  border-left-color: rgb(251 191 36 / 0.7) !important;
  border-right-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-x-amber-400\/75 {
  border-left-color: rgb(251 191 36 / 0.75) !important;
  border-right-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-x-amber-400\/80 {
  border-left-color: rgb(251 191 36 / 0.8) !important;
  border-right-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-x-amber-400\/85 {
  border-left-color: rgb(251 191 36 / 0.85) !important;
  border-right-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-x-amber-400\/90 {
  border-left-color: rgb(251 191 36 / 0.9) !important;
  border-right-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-x-amber-400\/95 {
  border-left-color: rgb(251 191 36 / 0.95) !important;
  border-right-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-x-amber-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-50\/0 {
  border-left-color: rgb(255 251 235 / 0) !important;
  border-right-color: rgb(255 251 235 / 0) !important;
}

.tw-border-x-amber-50\/10 {
  border-left-color: rgb(255 251 235 / 0.1) !important;
  border-right-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-x-amber-50\/100 {
  border-left-color: rgb(255 251 235 / 1) !important;
  border-right-color: rgb(255 251 235 / 1) !important;
}

.tw-border-x-amber-50\/15 {
  border-left-color: rgb(255 251 235 / 0.15) !important;
  border-right-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-x-amber-50\/20 {
  border-left-color: rgb(255 251 235 / 0.2) !important;
  border-right-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-x-amber-50\/25 {
  border-left-color: rgb(255 251 235 / 0.25) !important;
  border-right-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-x-amber-50\/30 {
  border-left-color: rgb(255 251 235 / 0.3) !important;
  border-right-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-x-amber-50\/35 {
  border-left-color: rgb(255 251 235 / 0.35) !important;
  border-right-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-x-amber-50\/40 {
  border-left-color: rgb(255 251 235 / 0.4) !important;
  border-right-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-x-amber-50\/45 {
  border-left-color: rgb(255 251 235 / 0.45) !important;
  border-right-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-x-amber-50\/5 {
  border-left-color: rgb(255 251 235 / 0.05) !important;
  border-right-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-x-amber-50\/50 {
  border-left-color: rgb(255 251 235 / 0.5) !important;
  border-right-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-x-amber-50\/55 {
  border-left-color: rgb(255 251 235 / 0.55) !important;
  border-right-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-x-amber-50\/60 {
  border-left-color: rgb(255 251 235 / 0.6) !important;
  border-right-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-x-amber-50\/65 {
  border-left-color: rgb(255 251 235 / 0.65) !important;
  border-right-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-x-amber-50\/70 {
  border-left-color: rgb(255 251 235 / 0.7) !important;
  border-right-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-x-amber-50\/75 {
  border-left-color: rgb(255 251 235 / 0.75) !important;
  border-right-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-x-amber-50\/80 {
  border-left-color: rgb(255 251 235 / 0.8) !important;
  border-right-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-x-amber-50\/85 {
  border-left-color: rgb(255 251 235 / 0.85) !important;
  border-right-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-x-amber-50\/90 {
  border-left-color: rgb(255 251 235 / 0.9) !important;
  border-right-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-x-amber-50\/95 {
  border-left-color: rgb(255 251 235 / 0.95) !important;
  border-right-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-x-amber-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-500\/0 {
  border-left-color: rgb(245 158 11 / 0) !important;
  border-right-color: rgb(245 158 11 / 0) !important;
}

.tw-border-x-amber-500\/10 {
  border-left-color: rgb(245 158 11 / 0.1) !important;
  border-right-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-x-amber-500\/100 {
  border-left-color: rgb(245 158 11 / 1) !important;
  border-right-color: rgb(245 158 11 / 1) !important;
}

.tw-border-x-amber-500\/15 {
  border-left-color: rgb(245 158 11 / 0.15) !important;
  border-right-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-x-amber-500\/20 {
  border-left-color: rgb(245 158 11 / 0.2) !important;
  border-right-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-x-amber-500\/25 {
  border-left-color: rgb(245 158 11 / 0.25) !important;
  border-right-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-x-amber-500\/30 {
  border-left-color: rgb(245 158 11 / 0.3) !important;
  border-right-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-x-amber-500\/35 {
  border-left-color: rgb(245 158 11 / 0.35) !important;
  border-right-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-x-amber-500\/40 {
  border-left-color: rgb(245 158 11 / 0.4) !important;
  border-right-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-x-amber-500\/45 {
  border-left-color: rgb(245 158 11 / 0.45) !important;
  border-right-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-x-amber-500\/5 {
  border-left-color: rgb(245 158 11 / 0.05) !important;
  border-right-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-x-amber-500\/50 {
  border-left-color: rgb(245 158 11 / 0.5) !important;
  border-right-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-x-amber-500\/55 {
  border-left-color: rgb(245 158 11 / 0.55) !important;
  border-right-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-x-amber-500\/60 {
  border-left-color: rgb(245 158 11 / 0.6) !important;
  border-right-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-x-amber-500\/65 {
  border-left-color: rgb(245 158 11 / 0.65) !important;
  border-right-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-x-amber-500\/70 {
  border-left-color: rgb(245 158 11 / 0.7) !important;
  border-right-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-x-amber-500\/75 {
  border-left-color: rgb(245 158 11 / 0.75) !important;
  border-right-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-x-amber-500\/80 {
  border-left-color: rgb(245 158 11 / 0.8) !important;
  border-right-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-x-amber-500\/85 {
  border-left-color: rgb(245 158 11 / 0.85) !important;
  border-right-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-x-amber-500\/90 {
  border-left-color: rgb(245 158 11 / 0.9) !important;
  border-right-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-x-amber-500\/95 {
  border-left-color: rgb(245 158 11 / 0.95) !important;
  border-right-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-x-amber-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-600\/0 {
  border-left-color: rgb(217 119 6 / 0) !important;
  border-right-color: rgb(217 119 6 / 0) !important;
}

.tw-border-x-amber-600\/10 {
  border-left-color: rgb(217 119 6 / 0.1) !important;
  border-right-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-x-amber-600\/100 {
  border-left-color: rgb(217 119 6 / 1) !important;
  border-right-color: rgb(217 119 6 / 1) !important;
}

.tw-border-x-amber-600\/15 {
  border-left-color: rgb(217 119 6 / 0.15) !important;
  border-right-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-x-amber-600\/20 {
  border-left-color: rgb(217 119 6 / 0.2) !important;
  border-right-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-x-amber-600\/25 {
  border-left-color: rgb(217 119 6 / 0.25) !important;
  border-right-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-x-amber-600\/30 {
  border-left-color: rgb(217 119 6 / 0.3) !important;
  border-right-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-x-amber-600\/35 {
  border-left-color: rgb(217 119 6 / 0.35) !important;
  border-right-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-x-amber-600\/40 {
  border-left-color: rgb(217 119 6 / 0.4) !important;
  border-right-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-x-amber-600\/45 {
  border-left-color: rgb(217 119 6 / 0.45) !important;
  border-right-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-x-amber-600\/5 {
  border-left-color: rgb(217 119 6 / 0.05) !important;
  border-right-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-x-amber-600\/50 {
  border-left-color: rgb(217 119 6 / 0.5) !important;
  border-right-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-x-amber-600\/55 {
  border-left-color: rgb(217 119 6 / 0.55) !important;
  border-right-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-x-amber-600\/60 {
  border-left-color: rgb(217 119 6 / 0.6) !important;
  border-right-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-x-amber-600\/65 {
  border-left-color: rgb(217 119 6 / 0.65) !important;
  border-right-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-x-amber-600\/70 {
  border-left-color: rgb(217 119 6 / 0.7) !important;
  border-right-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-x-amber-600\/75 {
  border-left-color: rgb(217 119 6 / 0.75) !important;
  border-right-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-x-amber-600\/80 {
  border-left-color: rgb(217 119 6 / 0.8) !important;
  border-right-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-x-amber-600\/85 {
  border-left-color: rgb(217 119 6 / 0.85) !important;
  border-right-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-x-amber-600\/90 {
  border-left-color: rgb(217 119 6 / 0.9) !important;
  border-right-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-x-amber-600\/95 {
  border-left-color: rgb(217 119 6 / 0.95) !important;
  border-right-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-x-amber-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-700\/0 {
  border-left-color: rgb(180 83 9 / 0) !important;
  border-right-color: rgb(180 83 9 / 0) !important;
}

.tw-border-x-amber-700\/10 {
  border-left-color: rgb(180 83 9 / 0.1) !important;
  border-right-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-x-amber-700\/100 {
  border-left-color: rgb(180 83 9 / 1) !important;
  border-right-color: rgb(180 83 9 / 1) !important;
}

.tw-border-x-amber-700\/15 {
  border-left-color: rgb(180 83 9 / 0.15) !important;
  border-right-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-x-amber-700\/20 {
  border-left-color: rgb(180 83 9 / 0.2) !important;
  border-right-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-x-amber-700\/25 {
  border-left-color: rgb(180 83 9 / 0.25) !important;
  border-right-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-x-amber-700\/30 {
  border-left-color: rgb(180 83 9 / 0.3) !important;
  border-right-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-x-amber-700\/35 {
  border-left-color: rgb(180 83 9 / 0.35) !important;
  border-right-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-x-amber-700\/40 {
  border-left-color: rgb(180 83 9 / 0.4) !important;
  border-right-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-x-amber-700\/45 {
  border-left-color: rgb(180 83 9 / 0.45) !important;
  border-right-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-x-amber-700\/5 {
  border-left-color: rgb(180 83 9 / 0.05) !important;
  border-right-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-x-amber-700\/50 {
  border-left-color: rgb(180 83 9 / 0.5) !important;
  border-right-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-x-amber-700\/55 {
  border-left-color: rgb(180 83 9 / 0.55) !important;
  border-right-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-x-amber-700\/60 {
  border-left-color: rgb(180 83 9 / 0.6) !important;
  border-right-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-x-amber-700\/65 {
  border-left-color: rgb(180 83 9 / 0.65) !important;
  border-right-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-x-amber-700\/70 {
  border-left-color: rgb(180 83 9 / 0.7) !important;
  border-right-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-x-amber-700\/75 {
  border-left-color: rgb(180 83 9 / 0.75) !important;
  border-right-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-x-amber-700\/80 {
  border-left-color: rgb(180 83 9 / 0.8) !important;
  border-right-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-x-amber-700\/85 {
  border-left-color: rgb(180 83 9 / 0.85) !important;
  border-right-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-x-amber-700\/90 {
  border-left-color: rgb(180 83 9 / 0.9) !important;
  border-right-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-x-amber-700\/95 {
  border-left-color: rgb(180 83 9 / 0.95) !important;
  border-right-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-x-amber-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-800\/0 {
  border-left-color: rgb(146 64 14 / 0) !important;
  border-right-color: rgb(146 64 14 / 0) !important;
}

.tw-border-x-amber-800\/10 {
  border-left-color: rgb(146 64 14 / 0.1) !important;
  border-right-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-x-amber-800\/100 {
  border-left-color: rgb(146 64 14 / 1) !important;
  border-right-color: rgb(146 64 14 / 1) !important;
}

.tw-border-x-amber-800\/15 {
  border-left-color: rgb(146 64 14 / 0.15) !important;
  border-right-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-x-amber-800\/20 {
  border-left-color: rgb(146 64 14 / 0.2) !important;
  border-right-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-x-amber-800\/25 {
  border-left-color: rgb(146 64 14 / 0.25) !important;
  border-right-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-x-amber-800\/30 {
  border-left-color: rgb(146 64 14 / 0.3) !important;
  border-right-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-x-amber-800\/35 {
  border-left-color: rgb(146 64 14 / 0.35) !important;
  border-right-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-x-amber-800\/40 {
  border-left-color: rgb(146 64 14 / 0.4) !important;
  border-right-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-x-amber-800\/45 {
  border-left-color: rgb(146 64 14 / 0.45) !important;
  border-right-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-x-amber-800\/5 {
  border-left-color: rgb(146 64 14 / 0.05) !important;
  border-right-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-x-amber-800\/50 {
  border-left-color: rgb(146 64 14 / 0.5) !important;
  border-right-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-x-amber-800\/55 {
  border-left-color: rgb(146 64 14 / 0.55) !important;
  border-right-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-x-amber-800\/60 {
  border-left-color: rgb(146 64 14 / 0.6) !important;
  border-right-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-x-amber-800\/65 {
  border-left-color: rgb(146 64 14 / 0.65) !important;
  border-right-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-x-amber-800\/70 {
  border-left-color: rgb(146 64 14 / 0.7) !important;
  border-right-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-x-amber-800\/75 {
  border-left-color: rgb(146 64 14 / 0.75) !important;
  border-right-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-x-amber-800\/80 {
  border-left-color: rgb(146 64 14 / 0.8) !important;
  border-right-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-x-amber-800\/85 {
  border-left-color: rgb(146 64 14 / 0.85) !important;
  border-right-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-x-amber-800\/90 {
  border-left-color: rgb(146 64 14 / 0.9) !important;
  border-right-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-x-amber-800\/95 {
  border-left-color: rgb(146 64 14 / 0.95) !important;
  border-right-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-x-amber-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-900\/0 {
  border-left-color: rgb(120 53 15 / 0) !important;
  border-right-color: rgb(120 53 15 / 0) !important;
}

.tw-border-x-amber-900\/10 {
  border-left-color: rgb(120 53 15 / 0.1) !important;
  border-right-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-x-amber-900\/100 {
  border-left-color: rgb(120 53 15 / 1) !important;
  border-right-color: rgb(120 53 15 / 1) !important;
}

.tw-border-x-amber-900\/15 {
  border-left-color: rgb(120 53 15 / 0.15) !important;
  border-right-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-x-amber-900\/20 {
  border-left-color: rgb(120 53 15 / 0.2) !important;
  border-right-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-x-amber-900\/25 {
  border-left-color: rgb(120 53 15 / 0.25) !important;
  border-right-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-x-amber-900\/30 {
  border-left-color: rgb(120 53 15 / 0.3) !important;
  border-right-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-x-amber-900\/35 {
  border-left-color: rgb(120 53 15 / 0.35) !important;
  border-right-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-x-amber-900\/40 {
  border-left-color: rgb(120 53 15 / 0.4) !important;
  border-right-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-x-amber-900\/45 {
  border-left-color: rgb(120 53 15 / 0.45) !important;
  border-right-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-x-amber-900\/5 {
  border-left-color: rgb(120 53 15 / 0.05) !important;
  border-right-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-x-amber-900\/50 {
  border-left-color: rgb(120 53 15 / 0.5) !important;
  border-right-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-x-amber-900\/55 {
  border-left-color: rgb(120 53 15 / 0.55) !important;
  border-right-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-x-amber-900\/60 {
  border-left-color: rgb(120 53 15 / 0.6) !important;
  border-right-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-x-amber-900\/65 {
  border-left-color: rgb(120 53 15 / 0.65) !important;
  border-right-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-x-amber-900\/70 {
  border-left-color: rgb(120 53 15 / 0.7) !important;
  border-right-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-x-amber-900\/75 {
  border-left-color: rgb(120 53 15 / 0.75) !important;
  border-right-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-x-amber-900\/80 {
  border-left-color: rgb(120 53 15 / 0.8) !important;
  border-right-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-x-amber-900\/85 {
  border-left-color: rgb(120 53 15 / 0.85) !important;
  border-right-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-x-amber-900\/90 {
  border-left-color: rgb(120 53 15 / 0.9) !important;
  border-right-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-x-amber-900\/95 {
  border-left-color: rgb(120 53 15 / 0.95) !important;
  border-right-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-x-amber-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-x-amber-950\/0 {
  border-left-color: rgb(69 26 3 / 0) !important;
  border-right-color: rgb(69 26 3 / 0) !important;
}

.tw-border-x-amber-950\/10 {
  border-left-color: rgb(69 26 3 / 0.1) !important;
  border-right-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-x-amber-950\/100 {
  border-left-color: rgb(69 26 3 / 1) !important;
  border-right-color: rgb(69 26 3 / 1) !important;
}

.tw-border-x-amber-950\/15 {
  border-left-color: rgb(69 26 3 / 0.15) !important;
  border-right-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-x-amber-950\/20 {
  border-left-color: rgb(69 26 3 / 0.2) !important;
  border-right-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-x-amber-950\/25 {
  border-left-color: rgb(69 26 3 / 0.25) !important;
  border-right-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-x-amber-950\/30 {
  border-left-color: rgb(69 26 3 / 0.3) !important;
  border-right-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-x-amber-950\/35 {
  border-left-color: rgb(69 26 3 / 0.35) !important;
  border-right-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-x-amber-950\/40 {
  border-left-color: rgb(69 26 3 / 0.4) !important;
  border-right-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-x-amber-950\/45 {
  border-left-color: rgb(69 26 3 / 0.45) !important;
  border-right-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-x-amber-950\/5 {
  border-left-color: rgb(69 26 3 / 0.05) !important;
  border-right-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-x-amber-950\/50 {
  border-left-color: rgb(69 26 3 / 0.5) !important;
  border-right-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-x-amber-950\/55 {
  border-left-color: rgb(69 26 3 / 0.55) !important;
  border-right-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-x-amber-950\/60 {
  border-left-color: rgb(69 26 3 / 0.6) !important;
  border-right-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-x-amber-950\/65 {
  border-left-color: rgb(69 26 3 / 0.65) !important;
  border-right-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-x-amber-950\/70 {
  border-left-color: rgb(69 26 3 / 0.7) !important;
  border-right-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-x-amber-950\/75 {
  border-left-color: rgb(69 26 3 / 0.75) !important;
  border-right-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-x-amber-950\/80 {
  border-left-color: rgb(69 26 3 / 0.8) !important;
  border-right-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-x-amber-950\/85 {
  border-left-color: rgb(69 26 3 / 0.85) !important;
  border-right-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-x-amber-950\/90 {
  border-left-color: rgb(69 26 3 / 0.9) !important;
  border-right-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-x-amber-950\/95 {
  border-left-color: rgb(69 26 3 / 0.95) !important;
  border-right-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-x-black {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-x-black\/0 {
  border-left-color: rgb(0 0 0 / 0) !important;
  border-right-color: rgb(0 0 0 / 0) !important;
}

.tw-border-x-black\/10 {
  border-left-color: rgb(0 0 0 / 0.1) !important;
  border-right-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-x-black\/100 {
  border-left-color: rgb(0 0 0 / 1) !important;
  border-right-color: rgb(0 0 0 / 1) !important;
}

.tw-border-x-black\/15 {
  border-left-color: rgb(0 0 0 / 0.15) !important;
  border-right-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-x-black\/20 {
  border-left-color: rgb(0 0 0 / 0.2) !important;
  border-right-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-x-black\/25 {
  border-left-color: rgb(0 0 0 / 0.25) !important;
  border-right-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-x-black\/30 {
  border-left-color: rgb(0 0 0 / 0.3) !important;
  border-right-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-x-black\/35 {
  border-left-color: rgb(0 0 0 / 0.35) !important;
  border-right-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-x-black\/40 {
  border-left-color: rgb(0 0 0 / 0.4) !important;
  border-right-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-x-black\/45 {
  border-left-color: rgb(0 0 0 / 0.45) !important;
  border-right-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-x-black\/5 {
  border-left-color: rgb(0 0 0 / 0.05) !important;
  border-right-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-x-black\/50 {
  border-left-color: rgb(0 0 0 / 0.5) !important;
  border-right-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-x-black\/55 {
  border-left-color: rgb(0 0 0 / 0.55) !important;
  border-right-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-x-black\/60 {
  border-left-color: rgb(0 0 0 / 0.6) !important;
  border-right-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-x-black\/65 {
  border-left-color: rgb(0 0 0 / 0.65) !important;
  border-right-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-x-black\/70 {
  border-left-color: rgb(0 0 0 / 0.7) !important;
  border-right-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-x-black\/75 {
  border-left-color: rgb(0 0 0 / 0.75) !important;
  border-right-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-x-black\/80 {
  border-left-color: rgb(0 0 0 / 0.8) !important;
  border-right-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-x-black\/85 {
  border-left-color: rgb(0 0 0 / 0.85) !important;
  border-right-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-x-black\/90 {
  border-left-color: rgb(0 0 0 / 0.9) !important;
  border-right-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-x-black\/95 {
  border-left-color: rgb(0 0 0 / 0.95) !important;
  border-right-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-x-blue-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-100\/0 {
  border-left-color: rgb(219 234 254 / 0) !important;
  border-right-color: rgb(219 234 254 / 0) !important;
}

.tw-border-x-blue-100\/10 {
  border-left-color: rgb(219 234 254 / 0.1) !important;
  border-right-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-x-blue-100\/100 {
  border-left-color: rgb(219 234 254 / 1) !important;
  border-right-color: rgb(219 234 254 / 1) !important;
}

.tw-border-x-blue-100\/15 {
  border-left-color: rgb(219 234 254 / 0.15) !important;
  border-right-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-x-blue-100\/20 {
  border-left-color: rgb(219 234 254 / 0.2) !important;
  border-right-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-x-blue-100\/25 {
  border-left-color: rgb(219 234 254 / 0.25) !important;
  border-right-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-x-blue-100\/30 {
  border-left-color: rgb(219 234 254 / 0.3) !important;
  border-right-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-x-blue-100\/35 {
  border-left-color: rgb(219 234 254 / 0.35) !important;
  border-right-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-x-blue-100\/40 {
  border-left-color: rgb(219 234 254 / 0.4) !important;
  border-right-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-x-blue-100\/45 {
  border-left-color: rgb(219 234 254 / 0.45) !important;
  border-right-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-x-blue-100\/5 {
  border-left-color: rgb(219 234 254 / 0.05) !important;
  border-right-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-x-blue-100\/50 {
  border-left-color: rgb(219 234 254 / 0.5) !important;
  border-right-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-x-blue-100\/55 {
  border-left-color: rgb(219 234 254 / 0.55) !important;
  border-right-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-x-blue-100\/60 {
  border-left-color: rgb(219 234 254 / 0.6) !important;
  border-right-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-x-blue-100\/65 {
  border-left-color: rgb(219 234 254 / 0.65) !important;
  border-right-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-x-blue-100\/70 {
  border-left-color: rgb(219 234 254 / 0.7) !important;
  border-right-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-x-blue-100\/75 {
  border-left-color: rgb(219 234 254 / 0.75) !important;
  border-right-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-x-blue-100\/80 {
  border-left-color: rgb(219 234 254 / 0.8) !important;
  border-right-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-x-blue-100\/85 {
  border-left-color: rgb(219 234 254 / 0.85) !important;
  border-right-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-x-blue-100\/90 {
  border-left-color: rgb(219 234 254 / 0.9) !important;
  border-right-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-x-blue-100\/95 {
  border-left-color: rgb(219 234 254 / 0.95) !important;
  border-right-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-x-blue-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-200\/0 {
  border-left-color: rgb(191 219 254 / 0) !important;
  border-right-color: rgb(191 219 254 / 0) !important;
}

.tw-border-x-blue-200\/10 {
  border-left-color: rgb(191 219 254 / 0.1) !important;
  border-right-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-x-blue-200\/100 {
  border-left-color: rgb(191 219 254 / 1) !important;
  border-right-color: rgb(191 219 254 / 1) !important;
}

.tw-border-x-blue-200\/15 {
  border-left-color: rgb(191 219 254 / 0.15) !important;
  border-right-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-x-blue-200\/20 {
  border-left-color: rgb(191 219 254 / 0.2) !important;
  border-right-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-x-blue-200\/25 {
  border-left-color: rgb(191 219 254 / 0.25) !important;
  border-right-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-x-blue-200\/30 {
  border-left-color: rgb(191 219 254 / 0.3) !important;
  border-right-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-x-blue-200\/35 {
  border-left-color: rgb(191 219 254 / 0.35) !important;
  border-right-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-x-blue-200\/40 {
  border-left-color: rgb(191 219 254 / 0.4) !important;
  border-right-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-x-blue-200\/45 {
  border-left-color: rgb(191 219 254 / 0.45) !important;
  border-right-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-x-blue-200\/5 {
  border-left-color: rgb(191 219 254 / 0.05) !important;
  border-right-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-x-blue-200\/50 {
  border-left-color: rgb(191 219 254 / 0.5) !important;
  border-right-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-x-blue-200\/55 {
  border-left-color: rgb(191 219 254 / 0.55) !important;
  border-right-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-x-blue-200\/60 {
  border-left-color: rgb(191 219 254 / 0.6) !important;
  border-right-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-x-blue-200\/65 {
  border-left-color: rgb(191 219 254 / 0.65) !important;
  border-right-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-x-blue-200\/70 {
  border-left-color: rgb(191 219 254 / 0.7) !important;
  border-right-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-x-blue-200\/75 {
  border-left-color: rgb(191 219 254 / 0.75) !important;
  border-right-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-x-blue-200\/80 {
  border-left-color: rgb(191 219 254 / 0.8) !important;
  border-right-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-x-blue-200\/85 {
  border-left-color: rgb(191 219 254 / 0.85) !important;
  border-right-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-x-blue-200\/90 {
  border-left-color: rgb(191 219 254 / 0.9) !important;
  border-right-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-x-blue-200\/95 {
  border-left-color: rgb(191 219 254 / 0.95) !important;
  border-right-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-x-blue-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-300\/0 {
  border-left-color: rgb(147 197 253 / 0) !important;
  border-right-color: rgb(147 197 253 / 0) !important;
}

.tw-border-x-blue-300\/10 {
  border-left-color: rgb(147 197 253 / 0.1) !important;
  border-right-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-x-blue-300\/100 {
  border-left-color: rgb(147 197 253 / 1) !important;
  border-right-color: rgb(147 197 253 / 1) !important;
}

.tw-border-x-blue-300\/15 {
  border-left-color: rgb(147 197 253 / 0.15) !important;
  border-right-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-x-blue-300\/20 {
  border-left-color: rgb(147 197 253 / 0.2) !important;
  border-right-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-x-blue-300\/25 {
  border-left-color: rgb(147 197 253 / 0.25) !important;
  border-right-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-x-blue-300\/30 {
  border-left-color: rgb(147 197 253 / 0.3) !important;
  border-right-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-x-blue-300\/35 {
  border-left-color: rgb(147 197 253 / 0.35) !important;
  border-right-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-x-blue-300\/40 {
  border-left-color: rgb(147 197 253 / 0.4) !important;
  border-right-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-x-blue-300\/45 {
  border-left-color: rgb(147 197 253 / 0.45) !important;
  border-right-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-x-blue-300\/5 {
  border-left-color: rgb(147 197 253 / 0.05) !important;
  border-right-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-x-blue-300\/50 {
  border-left-color: rgb(147 197 253 / 0.5) !important;
  border-right-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-x-blue-300\/55 {
  border-left-color: rgb(147 197 253 / 0.55) !important;
  border-right-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-x-blue-300\/60 {
  border-left-color: rgb(147 197 253 / 0.6) !important;
  border-right-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-x-blue-300\/65 {
  border-left-color: rgb(147 197 253 / 0.65) !important;
  border-right-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-x-blue-300\/70 {
  border-left-color: rgb(147 197 253 / 0.7) !important;
  border-right-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-x-blue-300\/75 {
  border-left-color: rgb(147 197 253 / 0.75) !important;
  border-right-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-x-blue-300\/80 {
  border-left-color: rgb(147 197 253 / 0.8) !important;
  border-right-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-x-blue-300\/85 {
  border-left-color: rgb(147 197 253 / 0.85) !important;
  border-right-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-x-blue-300\/90 {
  border-left-color: rgb(147 197 253 / 0.9) !important;
  border-right-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-x-blue-300\/95 {
  border-left-color: rgb(147 197 253 / 0.95) !important;
  border-right-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-x-blue-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-400\/0 {
  border-left-color: rgb(96 165 250 / 0) !important;
  border-right-color: rgb(96 165 250 / 0) !important;
}

.tw-border-x-blue-400\/10 {
  border-left-color: rgb(96 165 250 / 0.1) !important;
  border-right-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-x-blue-400\/100 {
  border-left-color: rgb(96 165 250 / 1) !important;
  border-right-color: rgb(96 165 250 / 1) !important;
}

.tw-border-x-blue-400\/15 {
  border-left-color: rgb(96 165 250 / 0.15) !important;
  border-right-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-x-blue-400\/20 {
  border-left-color: rgb(96 165 250 / 0.2) !important;
  border-right-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-x-blue-400\/25 {
  border-left-color: rgb(96 165 250 / 0.25) !important;
  border-right-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-x-blue-400\/30 {
  border-left-color: rgb(96 165 250 / 0.3) !important;
  border-right-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-x-blue-400\/35 {
  border-left-color: rgb(96 165 250 / 0.35) !important;
  border-right-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-x-blue-400\/40 {
  border-left-color: rgb(96 165 250 / 0.4) !important;
  border-right-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-x-blue-400\/45 {
  border-left-color: rgb(96 165 250 / 0.45) !important;
  border-right-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-x-blue-400\/5 {
  border-left-color: rgb(96 165 250 / 0.05) !important;
  border-right-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-x-blue-400\/50 {
  border-left-color: rgb(96 165 250 / 0.5) !important;
  border-right-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-x-blue-400\/55 {
  border-left-color: rgb(96 165 250 / 0.55) !important;
  border-right-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-x-blue-400\/60 {
  border-left-color: rgb(96 165 250 / 0.6) !important;
  border-right-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-x-blue-400\/65 {
  border-left-color: rgb(96 165 250 / 0.65) !important;
  border-right-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-x-blue-400\/70 {
  border-left-color: rgb(96 165 250 / 0.7) !important;
  border-right-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-x-blue-400\/75 {
  border-left-color: rgb(96 165 250 / 0.75) !important;
  border-right-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-x-blue-400\/80 {
  border-left-color: rgb(96 165 250 / 0.8) !important;
  border-right-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-x-blue-400\/85 {
  border-left-color: rgb(96 165 250 / 0.85) !important;
  border-right-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-x-blue-400\/90 {
  border-left-color: rgb(96 165 250 / 0.9) !important;
  border-right-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-x-blue-400\/95 {
  border-left-color: rgb(96 165 250 / 0.95) !important;
  border-right-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-x-blue-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-50\/0 {
  border-left-color: rgb(239 246 255 / 0) !important;
  border-right-color: rgb(239 246 255 / 0) !important;
}

.tw-border-x-blue-50\/10 {
  border-left-color: rgb(239 246 255 / 0.1) !important;
  border-right-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-x-blue-50\/100 {
  border-left-color: rgb(239 246 255 / 1) !important;
  border-right-color: rgb(239 246 255 / 1) !important;
}

.tw-border-x-blue-50\/15 {
  border-left-color: rgb(239 246 255 / 0.15) !important;
  border-right-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-x-blue-50\/20 {
  border-left-color: rgb(239 246 255 / 0.2) !important;
  border-right-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-x-blue-50\/25 {
  border-left-color: rgb(239 246 255 / 0.25) !important;
  border-right-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-x-blue-50\/30 {
  border-left-color: rgb(239 246 255 / 0.3) !important;
  border-right-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-x-blue-50\/35 {
  border-left-color: rgb(239 246 255 / 0.35) !important;
  border-right-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-x-blue-50\/40 {
  border-left-color: rgb(239 246 255 / 0.4) !important;
  border-right-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-x-blue-50\/45 {
  border-left-color: rgb(239 246 255 / 0.45) !important;
  border-right-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-x-blue-50\/5 {
  border-left-color: rgb(239 246 255 / 0.05) !important;
  border-right-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-x-blue-50\/50 {
  border-left-color: rgb(239 246 255 / 0.5) !important;
  border-right-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-x-blue-50\/55 {
  border-left-color: rgb(239 246 255 / 0.55) !important;
  border-right-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-x-blue-50\/60 {
  border-left-color: rgb(239 246 255 / 0.6) !important;
  border-right-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-x-blue-50\/65 {
  border-left-color: rgb(239 246 255 / 0.65) !important;
  border-right-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-x-blue-50\/70 {
  border-left-color: rgb(239 246 255 / 0.7) !important;
  border-right-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-x-blue-50\/75 {
  border-left-color: rgb(239 246 255 / 0.75) !important;
  border-right-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-x-blue-50\/80 {
  border-left-color: rgb(239 246 255 / 0.8) !important;
  border-right-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-x-blue-50\/85 {
  border-left-color: rgb(239 246 255 / 0.85) !important;
  border-right-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-x-blue-50\/90 {
  border-left-color: rgb(239 246 255 / 0.9) !important;
  border-right-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-x-blue-50\/95 {
  border-left-color: rgb(239 246 255 / 0.95) !important;
  border-right-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-x-blue-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-500\/0 {
  border-left-color: rgb(59 130 246 / 0) !important;
  border-right-color: rgb(59 130 246 / 0) !important;
}

.tw-border-x-blue-500\/10 {
  border-left-color: rgb(59 130 246 / 0.1) !important;
  border-right-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-x-blue-500\/100 {
  border-left-color: rgb(59 130 246 / 1) !important;
  border-right-color: rgb(59 130 246 / 1) !important;
}

.tw-border-x-blue-500\/15 {
  border-left-color: rgb(59 130 246 / 0.15) !important;
  border-right-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-x-blue-500\/20 {
  border-left-color: rgb(59 130 246 / 0.2) !important;
  border-right-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-x-blue-500\/25 {
  border-left-color: rgb(59 130 246 / 0.25) !important;
  border-right-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-x-blue-500\/30 {
  border-left-color: rgb(59 130 246 / 0.3) !important;
  border-right-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-x-blue-500\/35 {
  border-left-color: rgb(59 130 246 / 0.35) !important;
  border-right-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-x-blue-500\/40 {
  border-left-color: rgb(59 130 246 / 0.4) !important;
  border-right-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-x-blue-500\/45 {
  border-left-color: rgb(59 130 246 / 0.45) !important;
  border-right-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-x-blue-500\/5 {
  border-left-color: rgb(59 130 246 / 0.05) !important;
  border-right-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-x-blue-500\/50 {
  border-left-color: rgb(59 130 246 / 0.5) !important;
  border-right-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-x-blue-500\/55 {
  border-left-color: rgb(59 130 246 / 0.55) !important;
  border-right-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-x-blue-500\/60 {
  border-left-color: rgb(59 130 246 / 0.6) !important;
  border-right-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-x-blue-500\/65 {
  border-left-color: rgb(59 130 246 / 0.65) !important;
  border-right-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-x-blue-500\/70 {
  border-left-color: rgb(59 130 246 / 0.7) !important;
  border-right-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-x-blue-500\/75 {
  border-left-color: rgb(59 130 246 / 0.75) !important;
  border-right-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-x-blue-500\/80 {
  border-left-color: rgb(59 130 246 / 0.8) !important;
  border-right-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-x-blue-500\/85 {
  border-left-color: rgb(59 130 246 / 0.85) !important;
  border-right-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-x-blue-500\/90 {
  border-left-color: rgb(59 130 246 / 0.9) !important;
  border-right-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-x-blue-500\/95 {
  border-left-color: rgb(59 130 246 / 0.95) !important;
  border-right-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-x-blue-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-600\/0 {
  border-left-color: rgb(37 99 235 / 0) !important;
  border-right-color: rgb(37 99 235 / 0) !important;
}

.tw-border-x-blue-600\/10 {
  border-left-color: rgb(37 99 235 / 0.1) !important;
  border-right-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-x-blue-600\/100 {
  border-left-color: rgb(37 99 235 / 1) !important;
  border-right-color: rgb(37 99 235 / 1) !important;
}

.tw-border-x-blue-600\/15 {
  border-left-color: rgb(37 99 235 / 0.15) !important;
  border-right-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-x-blue-600\/20 {
  border-left-color: rgb(37 99 235 / 0.2) !important;
  border-right-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-x-blue-600\/25 {
  border-left-color: rgb(37 99 235 / 0.25) !important;
  border-right-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-x-blue-600\/30 {
  border-left-color: rgb(37 99 235 / 0.3) !important;
  border-right-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-x-blue-600\/35 {
  border-left-color: rgb(37 99 235 / 0.35) !important;
  border-right-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-x-blue-600\/40 {
  border-left-color: rgb(37 99 235 / 0.4) !important;
  border-right-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-x-blue-600\/45 {
  border-left-color: rgb(37 99 235 / 0.45) !important;
  border-right-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-x-blue-600\/5 {
  border-left-color: rgb(37 99 235 / 0.05) !important;
  border-right-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-x-blue-600\/50 {
  border-left-color: rgb(37 99 235 / 0.5) !important;
  border-right-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-x-blue-600\/55 {
  border-left-color: rgb(37 99 235 / 0.55) !important;
  border-right-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-x-blue-600\/60 {
  border-left-color: rgb(37 99 235 / 0.6) !important;
  border-right-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-x-blue-600\/65 {
  border-left-color: rgb(37 99 235 / 0.65) !important;
  border-right-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-x-blue-600\/70 {
  border-left-color: rgb(37 99 235 / 0.7) !important;
  border-right-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-x-blue-600\/75 {
  border-left-color: rgb(37 99 235 / 0.75) !important;
  border-right-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-x-blue-600\/80 {
  border-left-color: rgb(37 99 235 / 0.8) !important;
  border-right-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-x-blue-600\/85 {
  border-left-color: rgb(37 99 235 / 0.85) !important;
  border-right-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-x-blue-600\/90 {
  border-left-color: rgb(37 99 235 / 0.9) !important;
  border-right-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-x-blue-600\/95 {
  border-left-color: rgb(37 99 235 / 0.95) !important;
  border-right-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-x-blue-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-700\/0 {
  border-left-color: rgb(29 78 216 / 0) !important;
  border-right-color: rgb(29 78 216 / 0) !important;
}

.tw-border-x-blue-700\/10 {
  border-left-color: rgb(29 78 216 / 0.1) !important;
  border-right-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-x-blue-700\/100 {
  border-left-color: rgb(29 78 216 / 1) !important;
  border-right-color: rgb(29 78 216 / 1) !important;
}

.tw-border-x-blue-700\/15 {
  border-left-color: rgb(29 78 216 / 0.15) !important;
  border-right-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-x-blue-700\/20 {
  border-left-color: rgb(29 78 216 / 0.2) !important;
  border-right-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-x-blue-700\/25 {
  border-left-color: rgb(29 78 216 / 0.25) !important;
  border-right-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-x-blue-700\/30 {
  border-left-color: rgb(29 78 216 / 0.3) !important;
  border-right-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-x-blue-700\/35 {
  border-left-color: rgb(29 78 216 / 0.35) !important;
  border-right-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-x-blue-700\/40 {
  border-left-color: rgb(29 78 216 / 0.4) !important;
  border-right-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-x-blue-700\/45 {
  border-left-color: rgb(29 78 216 / 0.45) !important;
  border-right-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-x-blue-700\/5 {
  border-left-color: rgb(29 78 216 / 0.05) !important;
  border-right-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-x-blue-700\/50 {
  border-left-color: rgb(29 78 216 / 0.5) !important;
  border-right-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-x-blue-700\/55 {
  border-left-color: rgb(29 78 216 / 0.55) !important;
  border-right-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-x-blue-700\/60 {
  border-left-color: rgb(29 78 216 / 0.6) !important;
  border-right-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-x-blue-700\/65 {
  border-left-color: rgb(29 78 216 / 0.65) !important;
  border-right-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-x-blue-700\/70 {
  border-left-color: rgb(29 78 216 / 0.7) !important;
  border-right-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-x-blue-700\/75 {
  border-left-color: rgb(29 78 216 / 0.75) !important;
  border-right-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-x-blue-700\/80 {
  border-left-color: rgb(29 78 216 / 0.8) !important;
  border-right-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-x-blue-700\/85 {
  border-left-color: rgb(29 78 216 / 0.85) !important;
  border-right-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-x-blue-700\/90 {
  border-left-color: rgb(29 78 216 / 0.9) !important;
  border-right-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-x-blue-700\/95 {
  border-left-color: rgb(29 78 216 / 0.95) !important;
  border-right-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-x-blue-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-800\/0 {
  border-left-color: rgb(30 64 175 / 0) !important;
  border-right-color: rgb(30 64 175 / 0) !important;
}

.tw-border-x-blue-800\/10 {
  border-left-color: rgb(30 64 175 / 0.1) !important;
  border-right-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-x-blue-800\/100 {
  border-left-color: rgb(30 64 175 / 1) !important;
  border-right-color: rgb(30 64 175 / 1) !important;
}

.tw-border-x-blue-800\/15 {
  border-left-color: rgb(30 64 175 / 0.15) !important;
  border-right-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-x-blue-800\/20 {
  border-left-color: rgb(30 64 175 / 0.2) !important;
  border-right-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-x-blue-800\/25 {
  border-left-color: rgb(30 64 175 / 0.25) !important;
  border-right-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-x-blue-800\/30 {
  border-left-color: rgb(30 64 175 / 0.3) !important;
  border-right-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-x-blue-800\/35 {
  border-left-color: rgb(30 64 175 / 0.35) !important;
  border-right-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-x-blue-800\/40 {
  border-left-color: rgb(30 64 175 / 0.4) !important;
  border-right-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-x-blue-800\/45 {
  border-left-color: rgb(30 64 175 / 0.45) !important;
  border-right-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-x-blue-800\/5 {
  border-left-color: rgb(30 64 175 / 0.05) !important;
  border-right-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-x-blue-800\/50 {
  border-left-color: rgb(30 64 175 / 0.5) !important;
  border-right-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-x-blue-800\/55 {
  border-left-color: rgb(30 64 175 / 0.55) !important;
  border-right-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-x-blue-800\/60 {
  border-left-color: rgb(30 64 175 / 0.6) !important;
  border-right-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-x-blue-800\/65 {
  border-left-color: rgb(30 64 175 / 0.65) !important;
  border-right-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-x-blue-800\/70 {
  border-left-color: rgb(30 64 175 / 0.7) !important;
  border-right-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-x-blue-800\/75 {
  border-left-color: rgb(30 64 175 / 0.75) !important;
  border-right-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-x-blue-800\/80 {
  border-left-color: rgb(30 64 175 / 0.8) !important;
  border-right-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-x-blue-800\/85 {
  border-left-color: rgb(30 64 175 / 0.85) !important;
  border-right-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-x-blue-800\/90 {
  border-left-color: rgb(30 64 175 / 0.9) !important;
  border-right-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-x-blue-800\/95 {
  border-left-color: rgb(30 64 175 / 0.95) !important;
  border-right-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-x-blue-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-900\/0 {
  border-left-color: rgb(30 58 138 / 0) !important;
  border-right-color: rgb(30 58 138 / 0) !important;
}

.tw-border-x-blue-900\/10 {
  border-left-color: rgb(30 58 138 / 0.1) !important;
  border-right-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-x-blue-900\/100 {
  border-left-color: rgb(30 58 138 / 1) !important;
  border-right-color: rgb(30 58 138 / 1) !important;
}

.tw-border-x-blue-900\/15 {
  border-left-color: rgb(30 58 138 / 0.15) !important;
  border-right-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-x-blue-900\/20 {
  border-left-color: rgb(30 58 138 / 0.2) !important;
  border-right-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-x-blue-900\/25 {
  border-left-color: rgb(30 58 138 / 0.25) !important;
  border-right-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-x-blue-900\/30 {
  border-left-color: rgb(30 58 138 / 0.3) !important;
  border-right-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-x-blue-900\/35 {
  border-left-color: rgb(30 58 138 / 0.35) !important;
  border-right-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-x-blue-900\/40 {
  border-left-color: rgb(30 58 138 / 0.4) !important;
  border-right-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-x-blue-900\/45 {
  border-left-color: rgb(30 58 138 / 0.45) !important;
  border-right-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-x-blue-900\/5 {
  border-left-color: rgb(30 58 138 / 0.05) !important;
  border-right-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-x-blue-900\/50 {
  border-left-color: rgb(30 58 138 / 0.5) !important;
  border-right-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-x-blue-900\/55 {
  border-left-color: rgb(30 58 138 / 0.55) !important;
  border-right-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-x-blue-900\/60 {
  border-left-color: rgb(30 58 138 / 0.6) !important;
  border-right-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-x-blue-900\/65 {
  border-left-color: rgb(30 58 138 / 0.65) !important;
  border-right-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-x-blue-900\/70 {
  border-left-color: rgb(30 58 138 / 0.7) !important;
  border-right-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-x-blue-900\/75 {
  border-left-color: rgb(30 58 138 / 0.75) !important;
  border-right-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-x-blue-900\/80 {
  border-left-color: rgb(30 58 138 / 0.8) !important;
  border-right-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-x-blue-900\/85 {
  border-left-color: rgb(30 58 138 / 0.85) !important;
  border-right-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-x-blue-900\/90 {
  border-left-color: rgb(30 58 138 / 0.9) !important;
  border-right-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-x-blue-900\/95 {
  border-left-color: rgb(30 58 138 / 0.95) !important;
  border-right-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-x-blue-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-x-blue-950\/0 {
  border-left-color: rgb(23 37 84 / 0) !important;
  border-right-color: rgb(23 37 84 / 0) !important;
}

.tw-border-x-blue-950\/10 {
  border-left-color: rgb(23 37 84 / 0.1) !important;
  border-right-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-x-blue-950\/100 {
  border-left-color: rgb(23 37 84 / 1) !important;
  border-right-color: rgb(23 37 84 / 1) !important;
}

.tw-border-x-blue-950\/15 {
  border-left-color: rgb(23 37 84 / 0.15) !important;
  border-right-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-x-blue-950\/20 {
  border-left-color: rgb(23 37 84 / 0.2) !important;
  border-right-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-x-blue-950\/25 {
  border-left-color: rgb(23 37 84 / 0.25) !important;
  border-right-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-x-blue-950\/30 {
  border-left-color: rgb(23 37 84 / 0.3) !important;
  border-right-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-x-blue-950\/35 {
  border-left-color: rgb(23 37 84 / 0.35) !important;
  border-right-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-x-blue-950\/40 {
  border-left-color: rgb(23 37 84 / 0.4) !important;
  border-right-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-x-blue-950\/45 {
  border-left-color: rgb(23 37 84 / 0.45) !important;
  border-right-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-x-blue-950\/5 {
  border-left-color: rgb(23 37 84 / 0.05) !important;
  border-right-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-x-blue-950\/50 {
  border-left-color: rgb(23 37 84 / 0.5) !important;
  border-right-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-x-blue-950\/55 {
  border-left-color: rgb(23 37 84 / 0.55) !important;
  border-right-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-x-blue-950\/60 {
  border-left-color: rgb(23 37 84 / 0.6) !important;
  border-right-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-x-blue-950\/65 {
  border-left-color: rgb(23 37 84 / 0.65) !important;
  border-right-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-x-blue-950\/70 {
  border-left-color: rgb(23 37 84 / 0.7) !important;
  border-right-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-x-blue-950\/75 {
  border-left-color: rgb(23 37 84 / 0.75) !important;
  border-right-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-x-blue-950\/80 {
  border-left-color: rgb(23 37 84 / 0.8) !important;
  border-right-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-x-blue-950\/85 {
  border-left-color: rgb(23 37 84 / 0.85) !important;
  border-right-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-x-blue-950\/90 {
  border-left-color: rgb(23 37 84 / 0.9) !important;
  border-right-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-x-blue-950\/95 {
  border-left-color: rgb(23 37 84 / 0.95) !important;
  border-right-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-x-current {
  border-left-color: currentColor !important;
  border-right-color: currentColor !important;
}

.tw-border-x-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-100\/0 {
  border-left-color: rgb(207 250 254 / 0) !important;
  border-right-color: rgb(207 250 254 / 0) !important;
}

.tw-border-x-cyan-100\/10 {
  border-left-color: rgb(207 250 254 / 0.1) !important;
  border-right-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-x-cyan-100\/100 {
  border-left-color: rgb(207 250 254 / 1) !important;
  border-right-color: rgb(207 250 254 / 1) !important;
}

.tw-border-x-cyan-100\/15 {
  border-left-color: rgb(207 250 254 / 0.15) !important;
  border-right-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-x-cyan-100\/20 {
  border-left-color: rgb(207 250 254 / 0.2) !important;
  border-right-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-x-cyan-100\/25 {
  border-left-color: rgb(207 250 254 / 0.25) !important;
  border-right-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-x-cyan-100\/30 {
  border-left-color: rgb(207 250 254 / 0.3) !important;
  border-right-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-x-cyan-100\/35 {
  border-left-color: rgb(207 250 254 / 0.35) !important;
  border-right-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-x-cyan-100\/40 {
  border-left-color: rgb(207 250 254 / 0.4) !important;
  border-right-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-x-cyan-100\/45 {
  border-left-color: rgb(207 250 254 / 0.45) !important;
  border-right-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-x-cyan-100\/5 {
  border-left-color: rgb(207 250 254 / 0.05) !important;
  border-right-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-x-cyan-100\/50 {
  border-left-color: rgb(207 250 254 / 0.5) !important;
  border-right-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-x-cyan-100\/55 {
  border-left-color: rgb(207 250 254 / 0.55) !important;
  border-right-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-x-cyan-100\/60 {
  border-left-color: rgb(207 250 254 / 0.6) !important;
  border-right-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-x-cyan-100\/65 {
  border-left-color: rgb(207 250 254 / 0.65) !important;
  border-right-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-x-cyan-100\/70 {
  border-left-color: rgb(207 250 254 / 0.7) !important;
  border-right-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-x-cyan-100\/75 {
  border-left-color: rgb(207 250 254 / 0.75) !important;
  border-right-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-x-cyan-100\/80 {
  border-left-color: rgb(207 250 254 / 0.8) !important;
  border-right-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-x-cyan-100\/85 {
  border-left-color: rgb(207 250 254 / 0.85) !important;
  border-right-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-x-cyan-100\/90 {
  border-left-color: rgb(207 250 254 / 0.9) !important;
  border-right-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-x-cyan-100\/95 {
  border-left-color: rgb(207 250 254 / 0.95) !important;
  border-right-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-x-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-200\/0 {
  border-left-color: rgb(165 243 252 / 0) !important;
  border-right-color: rgb(165 243 252 / 0) !important;
}

.tw-border-x-cyan-200\/10 {
  border-left-color: rgb(165 243 252 / 0.1) !important;
  border-right-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-x-cyan-200\/100 {
  border-left-color: rgb(165 243 252 / 1) !important;
  border-right-color: rgb(165 243 252 / 1) !important;
}

.tw-border-x-cyan-200\/15 {
  border-left-color: rgb(165 243 252 / 0.15) !important;
  border-right-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-x-cyan-200\/20 {
  border-left-color: rgb(165 243 252 / 0.2) !important;
  border-right-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-x-cyan-200\/25 {
  border-left-color: rgb(165 243 252 / 0.25) !important;
  border-right-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-x-cyan-200\/30 {
  border-left-color: rgb(165 243 252 / 0.3) !important;
  border-right-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-x-cyan-200\/35 {
  border-left-color: rgb(165 243 252 / 0.35) !important;
  border-right-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-x-cyan-200\/40 {
  border-left-color: rgb(165 243 252 / 0.4) !important;
  border-right-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-x-cyan-200\/45 {
  border-left-color: rgb(165 243 252 / 0.45) !important;
  border-right-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-x-cyan-200\/5 {
  border-left-color: rgb(165 243 252 / 0.05) !important;
  border-right-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-x-cyan-200\/50 {
  border-left-color: rgb(165 243 252 / 0.5) !important;
  border-right-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-x-cyan-200\/55 {
  border-left-color: rgb(165 243 252 / 0.55) !important;
  border-right-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-x-cyan-200\/60 {
  border-left-color: rgb(165 243 252 / 0.6) !important;
  border-right-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-x-cyan-200\/65 {
  border-left-color: rgb(165 243 252 / 0.65) !important;
  border-right-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-x-cyan-200\/70 {
  border-left-color: rgb(165 243 252 / 0.7) !important;
  border-right-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-x-cyan-200\/75 {
  border-left-color: rgb(165 243 252 / 0.75) !important;
  border-right-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-x-cyan-200\/80 {
  border-left-color: rgb(165 243 252 / 0.8) !important;
  border-right-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-x-cyan-200\/85 {
  border-left-color: rgb(165 243 252 / 0.85) !important;
  border-right-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-x-cyan-200\/90 {
  border-left-color: rgb(165 243 252 / 0.9) !important;
  border-right-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-x-cyan-200\/95 {
  border-left-color: rgb(165 243 252 / 0.95) !important;
  border-right-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-x-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-300\/0 {
  border-left-color: rgb(103 232 249 / 0) !important;
  border-right-color: rgb(103 232 249 / 0) !important;
}

.tw-border-x-cyan-300\/10 {
  border-left-color: rgb(103 232 249 / 0.1) !important;
  border-right-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-x-cyan-300\/100 {
  border-left-color: rgb(103 232 249 / 1) !important;
  border-right-color: rgb(103 232 249 / 1) !important;
}

.tw-border-x-cyan-300\/15 {
  border-left-color: rgb(103 232 249 / 0.15) !important;
  border-right-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-x-cyan-300\/20 {
  border-left-color: rgb(103 232 249 / 0.2) !important;
  border-right-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-x-cyan-300\/25 {
  border-left-color: rgb(103 232 249 / 0.25) !important;
  border-right-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-x-cyan-300\/30 {
  border-left-color: rgb(103 232 249 / 0.3) !important;
  border-right-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-x-cyan-300\/35 {
  border-left-color: rgb(103 232 249 / 0.35) !important;
  border-right-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-x-cyan-300\/40 {
  border-left-color: rgb(103 232 249 / 0.4) !important;
  border-right-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-x-cyan-300\/45 {
  border-left-color: rgb(103 232 249 / 0.45) !important;
  border-right-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-x-cyan-300\/5 {
  border-left-color: rgb(103 232 249 / 0.05) !important;
  border-right-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-x-cyan-300\/50 {
  border-left-color: rgb(103 232 249 / 0.5) !important;
  border-right-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-x-cyan-300\/55 {
  border-left-color: rgb(103 232 249 / 0.55) !important;
  border-right-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-x-cyan-300\/60 {
  border-left-color: rgb(103 232 249 / 0.6) !important;
  border-right-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-x-cyan-300\/65 {
  border-left-color: rgb(103 232 249 / 0.65) !important;
  border-right-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-x-cyan-300\/70 {
  border-left-color: rgb(103 232 249 / 0.7) !important;
  border-right-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-x-cyan-300\/75 {
  border-left-color: rgb(103 232 249 / 0.75) !important;
  border-right-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-x-cyan-300\/80 {
  border-left-color: rgb(103 232 249 / 0.8) !important;
  border-right-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-x-cyan-300\/85 {
  border-left-color: rgb(103 232 249 / 0.85) !important;
  border-right-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-x-cyan-300\/90 {
  border-left-color: rgb(103 232 249 / 0.9) !important;
  border-right-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-x-cyan-300\/95 {
  border-left-color: rgb(103 232 249 / 0.95) !important;
  border-right-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-x-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-400\/0 {
  border-left-color: rgb(34 211 238 / 0) !important;
  border-right-color: rgb(34 211 238 / 0) !important;
}

.tw-border-x-cyan-400\/10 {
  border-left-color: rgb(34 211 238 / 0.1) !important;
  border-right-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-x-cyan-400\/100 {
  border-left-color: rgb(34 211 238 / 1) !important;
  border-right-color: rgb(34 211 238 / 1) !important;
}

.tw-border-x-cyan-400\/15 {
  border-left-color: rgb(34 211 238 / 0.15) !important;
  border-right-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-x-cyan-400\/20 {
  border-left-color: rgb(34 211 238 / 0.2) !important;
  border-right-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-x-cyan-400\/25 {
  border-left-color: rgb(34 211 238 / 0.25) !important;
  border-right-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-x-cyan-400\/30 {
  border-left-color: rgb(34 211 238 / 0.3) !important;
  border-right-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-x-cyan-400\/35 {
  border-left-color: rgb(34 211 238 / 0.35) !important;
  border-right-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-x-cyan-400\/40 {
  border-left-color: rgb(34 211 238 / 0.4) !important;
  border-right-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-x-cyan-400\/45 {
  border-left-color: rgb(34 211 238 / 0.45) !important;
  border-right-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-x-cyan-400\/5 {
  border-left-color: rgb(34 211 238 / 0.05) !important;
  border-right-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-x-cyan-400\/50 {
  border-left-color: rgb(34 211 238 / 0.5) !important;
  border-right-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-x-cyan-400\/55 {
  border-left-color: rgb(34 211 238 / 0.55) !important;
  border-right-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-x-cyan-400\/60 {
  border-left-color: rgb(34 211 238 / 0.6) !important;
  border-right-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-x-cyan-400\/65 {
  border-left-color: rgb(34 211 238 / 0.65) !important;
  border-right-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-x-cyan-400\/70 {
  border-left-color: rgb(34 211 238 / 0.7) !important;
  border-right-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-x-cyan-400\/75 {
  border-left-color: rgb(34 211 238 / 0.75) !important;
  border-right-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-x-cyan-400\/80 {
  border-left-color: rgb(34 211 238 / 0.8) !important;
  border-right-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-x-cyan-400\/85 {
  border-left-color: rgb(34 211 238 / 0.85) !important;
  border-right-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-x-cyan-400\/90 {
  border-left-color: rgb(34 211 238 / 0.9) !important;
  border-right-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-x-cyan-400\/95 {
  border-left-color: rgb(34 211 238 / 0.95) !important;
  border-right-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-x-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-50\/0 {
  border-left-color: rgb(236 254 255 / 0) !important;
  border-right-color: rgb(236 254 255 / 0) !important;
}

.tw-border-x-cyan-50\/10 {
  border-left-color: rgb(236 254 255 / 0.1) !important;
  border-right-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-x-cyan-50\/100 {
  border-left-color: rgb(236 254 255 / 1) !important;
  border-right-color: rgb(236 254 255 / 1) !important;
}

.tw-border-x-cyan-50\/15 {
  border-left-color: rgb(236 254 255 / 0.15) !important;
  border-right-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-x-cyan-50\/20 {
  border-left-color: rgb(236 254 255 / 0.2) !important;
  border-right-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-x-cyan-50\/25 {
  border-left-color: rgb(236 254 255 / 0.25) !important;
  border-right-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-x-cyan-50\/30 {
  border-left-color: rgb(236 254 255 / 0.3) !important;
  border-right-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-x-cyan-50\/35 {
  border-left-color: rgb(236 254 255 / 0.35) !important;
  border-right-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-x-cyan-50\/40 {
  border-left-color: rgb(236 254 255 / 0.4) !important;
  border-right-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-x-cyan-50\/45 {
  border-left-color: rgb(236 254 255 / 0.45) !important;
  border-right-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-x-cyan-50\/5 {
  border-left-color: rgb(236 254 255 / 0.05) !important;
  border-right-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-x-cyan-50\/50 {
  border-left-color: rgb(236 254 255 / 0.5) !important;
  border-right-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-x-cyan-50\/55 {
  border-left-color: rgb(236 254 255 / 0.55) !important;
  border-right-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-x-cyan-50\/60 {
  border-left-color: rgb(236 254 255 / 0.6) !important;
  border-right-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-x-cyan-50\/65 {
  border-left-color: rgb(236 254 255 / 0.65) !important;
  border-right-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-x-cyan-50\/70 {
  border-left-color: rgb(236 254 255 / 0.7) !important;
  border-right-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-x-cyan-50\/75 {
  border-left-color: rgb(236 254 255 / 0.75) !important;
  border-right-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-x-cyan-50\/80 {
  border-left-color: rgb(236 254 255 / 0.8) !important;
  border-right-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-x-cyan-50\/85 {
  border-left-color: rgb(236 254 255 / 0.85) !important;
  border-right-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-x-cyan-50\/90 {
  border-left-color: rgb(236 254 255 / 0.9) !important;
  border-right-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-x-cyan-50\/95 {
  border-left-color: rgb(236 254 255 / 0.95) !important;
  border-right-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-x-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-500\/0 {
  border-left-color: rgb(6 182 212 / 0) !important;
  border-right-color: rgb(6 182 212 / 0) !important;
}

.tw-border-x-cyan-500\/10 {
  border-left-color: rgb(6 182 212 / 0.1) !important;
  border-right-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-x-cyan-500\/100 {
  border-left-color: rgb(6 182 212 / 1) !important;
  border-right-color: rgb(6 182 212 / 1) !important;
}

.tw-border-x-cyan-500\/15 {
  border-left-color: rgb(6 182 212 / 0.15) !important;
  border-right-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-x-cyan-500\/20 {
  border-left-color: rgb(6 182 212 / 0.2) !important;
  border-right-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-x-cyan-500\/25 {
  border-left-color: rgb(6 182 212 / 0.25) !important;
  border-right-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-x-cyan-500\/30 {
  border-left-color: rgb(6 182 212 / 0.3) !important;
  border-right-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-x-cyan-500\/35 {
  border-left-color: rgb(6 182 212 / 0.35) !important;
  border-right-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-x-cyan-500\/40 {
  border-left-color: rgb(6 182 212 / 0.4) !important;
  border-right-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-x-cyan-500\/45 {
  border-left-color: rgb(6 182 212 / 0.45) !important;
  border-right-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-x-cyan-500\/5 {
  border-left-color: rgb(6 182 212 / 0.05) !important;
  border-right-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-x-cyan-500\/50 {
  border-left-color: rgb(6 182 212 / 0.5) !important;
  border-right-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-x-cyan-500\/55 {
  border-left-color: rgb(6 182 212 / 0.55) !important;
  border-right-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-x-cyan-500\/60 {
  border-left-color: rgb(6 182 212 / 0.6) !important;
  border-right-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-x-cyan-500\/65 {
  border-left-color: rgb(6 182 212 / 0.65) !important;
  border-right-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-x-cyan-500\/70 {
  border-left-color: rgb(6 182 212 / 0.7) !important;
  border-right-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-x-cyan-500\/75 {
  border-left-color: rgb(6 182 212 / 0.75) !important;
  border-right-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-x-cyan-500\/80 {
  border-left-color: rgb(6 182 212 / 0.8) !important;
  border-right-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-x-cyan-500\/85 {
  border-left-color: rgb(6 182 212 / 0.85) !important;
  border-right-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-x-cyan-500\/90 {
  border-left-color: rgb(6 182 212 / 0.9) !important;
  border-right-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-x-cyan-500\/95 {
  border-left-color: rgb(6 182 212 / 0.95) !important;
  border-right-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-x-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-600\/0 {
  border-left-color: rgb(8 145 178 / 0) !important;
  border-right-color: rgb(8 145 178 / 0) !important;
}

.tw-border-x-cyan-600\/10 {
  border-left-color: rgb(8 145 178 / 0.1) !important;
  border-right-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-x-cyan-600\/100 {
  border-left-color: rgb(8 145 178 / 1) !important;
  border-right-color: rgb(8 145 178 / 1) !important;
}

.tw-border-x-cyan-600\/15 {
  border-left-color: rgb(8 145 178 / 0.15) !important;
  border-right-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-x-cyan-600\/20 {
  border-left-color: rgb(8 145 178 / 0.2) !important;
  border-right-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-x-cyan-600\/25 {
  border-left-color: rgb(8 145 178 / 0.25) !important;
  border-right-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-x-cyan-600\/30 {
  border-left-color: rgb(8 145 178 / 0.3) !important;
  border-right-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-x-cyan-600\/35 {
  border-left-color: rgb(8 145 178 / 0.35) !important;
  border-right-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-x-cyan-600\/40 {
  border-left-color: rgb(8 145 178 / 0.4) !important;
  border-right-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-x-cyan-600\/45 {
  border-left-color: rgb(8 145 178 / 0.45) !important;
  border-right-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-x-cyan-600\/5 {
  border-left-color: rgb(8 145 178 / 0.05) !important;
  border-right-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-x-cyan-600\/50 {
  border-left-color: rgb(8 145 178 / 0.5) !important;
  border-right-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-x-cyan-600\/55 {
  border-left-color: rgb(8 145 178 / 0.55) !important;
  border-right-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-x-cyan-600\/60 {
  border-left-color: rgb(8 145 178 / 0.6) !important;
  border-right-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-x-cyan-600\/65 {
  border-left-color: rgb(8 145 178 / 0.65) !important;
  border-right-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-x-cyan-600\/70 {
  border-left-color: rgb(8 145 178 / 0.7) !important;
  border-right-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-x-cyan-600\/75 {
  border-left-color: rgb(8 145 178 / 0.75) !important;
  border-right-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-x-cyan-600\/80 {
  border-left-color: rgb(8 145 178 / 0.8) !important;
  border-right-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-x-cyan-600\/85 {
  border-left-color: rgb(8 145 178 / 0.85) !important;
  border-right-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-x-cyan-600\/90 {
  border-left-color: rgb(8 145 178 / 0.9) !important;
  border-right-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-x-cyan-600\/95 {
  border-left-color: rgb(8 145 178 / 0.95) !important;
  border-right-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-x-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-700\/0 {
  border-left-color: rgb(14 116 144 / 0) !important;
  border-right-color: rgb(14 116 144 / 0) !important;
}

.tw-border-x-cyan-700\/10 {
  border-left-color: rgb(14 116 144 / 0.1) !important;
  border-right-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-x-cyan-700\/100 {
  border-left-color: rgb(14 116 144 / 1) !important;
  border-right-color: rgb(14 116 144 / 1) !important;
}

.tw-border-x-cyan-700\/15 {
  border-left-color: rgb(14 116 144 / 0.15) !important;
  border-right-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-x-cyan-700\/20 {
  border-left-color: rgb(14 116 144 / 0.2) !important;
  border-right-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-x-cyan-700\/25 {
  border-left-color: rgb(14 116 144 / 0.25) !important;
  border-right-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-x-cyan-700\/30 {
  border-left-color: rgb(14 116 144 / 0.3) !important;
  border-right-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-x-cyan-700\/35 {
  border-left-color: rgb(14 116 144 / 0.35) !important;
  border-right-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-x-cyan-700\/40 {
  border-left-color: rgb(14 116 144 / 0.4) !important;
  border-right-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-x-cyan-700\/45 {
  border-left-color: rgb(14 116 144 / 0.45) !important;
  border-right-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-x-cyan-700\/5 {
  border-left-color: rgb(14 116 144 / 0.05) !important;
  border-right-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-x-cyan-700\/50 {
  border-left-color: rgb(14 116 144 / 0.5) !important;
  border-right-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-x-cyan-700\/55 {
  border-left-color: rgb(14 116 144 / 0.55) !important;
  border-right-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-x-cyan-700\/60 {
  border-left-color: rgb(14 116 144 / 0.6) !important;
  border-right-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-x-cyan-700\/65 {
  border-left-color: rgb(14 116 144 / 0.65) !important;
  border-right-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-x-cyan-700\/70 {
  border-left-color: rgb(14 116 144 / 0.7) !important;
  border-right-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-x-cyan-700\/75 {
  border-left-color: rgb(14 116 144 / 0.75) !important;
  border-right-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-x-cyan-700\/80 {
  border-left-color: rgb(14 116 144 / 0.8) !important;
  border-right-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-x-cyan-700\/85 {
  border-left-color: rgb(14 116 144 / 0.85) !important;
  border-right-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-x-cyan-700\/90 {
  border-left-color: rgb(14 116 144 / 0.9) !important;
  border-right-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-x-cyan-700\/95 {
  border-left-color: rgb(14 116 144 / 0.95) !important;
  border-right-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-x-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-800\/0 {
  border-left-color: rgb(21 94 117 / 0) !important;
  border-right-color: rgb(21 94 117 / 0) !important;
}

.tw-border-x-cyan-800\/10 {
  border-left-color: rgb(21 94 117 / 0.1) !important;
  border-right-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-x-cyan-800\/100 {
  border-left-color: rgb(21 94 117 / 1) !important;
  border-right-color: rgb(21 94 117 / 1) !important;
}

.tw-border-x-cyan-800\/15 {
  border-left-color: rgb(21 94 117 / 0.15) !important;
  border-right-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-x-cyan-800\/20 {
  border-left-color: rgb(21 94 117 / 0.2) !important;
  border-right-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-x-cyan-800\/25 {
  border-left-color: rgb(21 94 117 / 0.25) !important;
  border-right-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-x-cyan-800\/30 {
  border-left-color: rgb(21 94 117 / 0.3) !important;
  border-right-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-x-cyan-800\/35 {
  border-left-color: rgb(21 94 117 / 0.35) !important;
  border-right-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-x-cyan-800\/40 {
  border-left-color: rgb(21 94 117 / 0.4) !important;
  border-right-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-x-cyan-800\/45 {
  border-left-color: rgb(21 94 117 / 0.45) !important;
  border-right-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-x-cyan-800\/5 {
  border-left-color: rgb(21 94 117 / 0.05) !important;
  border-right-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-x-cyan-800\/50 {
  border-left-color: rgb(21 94 117 / 0.5) !important;
  border-right-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-x-cyan-800\/55 {
  border-left-color: rgb(21 94 117 / 0.55) !important;
  border-right-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-x-cyan-800\/60 {
  border-left-color: rgb(21 94 117 / 0.6) !important;
  border-right-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-x-cyan-800\/65 {
  border-left-color: rgb(21 94 117 / 0.65) !important;
  border-right-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-x-cyan-800\/70 {
  border-left-color: rgb(21 94 117 / 0.7) !important;
  border-right-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-x-cyan-800\/75 {
  border-left-color: rgb(21 94 117 / 0.75) !important;
  border-right-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-x-cyan-800\/80 {
  border-left-color: rgb(21 94 117 / 0.8) !important;
  border-right-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-x-cyan-800\/85 {
  border-left-color: rgb(21 94 117 / 0.85) !important;
  border-right-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-x-cyan-800\/90 {
  border-left-color: rgb(21 94 117 / 0.9) !important;
  border-right-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-x-cyan-800\/95 {
  border-left-color: rgb(21 94 117 / 0.95) !important;
  border-right-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-x-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-900\/0 {
  border-left-color: rgb(22 78 99 / 0) !important;
  border-right-color: rgb(22 78 99 / 0) !important;
}

.tw-border-x-cyan-900\/10 {
  border-left-color: rgb(22 78 99 / 0.1) !important;
  border-right-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-x-cyan-900\/100 {
  border-left-color: rgb(22 78 99 / 1) !important;
  border-right-color: rgb(22 78 99 / 1) !important;
}

.tw-border-x-cyan-900\/15 {
  border-left-color: rgb(22 78 99 / 0.15) !important;
  border-right-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-x-cyan-900\/20 {
  border-left-color: rgb(22 78 99 / 0.2) !important;
  border-right-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-x-cyan-900\/25 {
  border-left-color: rgb(22 78 99 / 0.25) !important;
  border-right-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-x-cyan-900\/30 {
  border-left-color: rgb(22 78 99 / 0.3) !important;
  border-right-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-x-cyan-900\/35 {
  border-left-color: rgb(22 78 99 / 0.35) !important;
  border-right-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-x-cyan-900\/40 {
  border-left-color: rgb(22 78 99 / 0.4) !important;
  border-right-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-x-cyan-900\/45 {
  border-left-color: rgb(22 78 99 / 0.45) !important;
  border-right-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-x-cyan-900\/5 {
  border-left-color: rgb(22 78 99 / 0.05) !important;
  border-right-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-x-cyan-900\/50 {
  border-left-color: rgb(22 78 99 / 0.5) !important;
  border-right-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-x-cyan-900\/55 {
  border-left-color: rgb(22 78 99 / 0.55) !important;
  border-right-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-x-cyan-900\/60 {
  border-left-color: rgb(22 78 99 / 0.6) !important;
  border-right-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-x-cyan-900\/65 {
  border-left-color: rgb(22 78 99 / 0.65) !important;
  border-right-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-x-cyan-900\/70 {
  border-left-color: rgb(22 78 99 / 0.7) !important;
  border-right-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-x-cyan-900\/75 {
  border-left-color: rgb(22 78 99 / 0.75) !important;
  border-right-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-x-cyan-900\/80 {
  border-left-color: rgb(22 78 99 / 0.8) !important;
  border-right-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-x-cyan-900\/85 {
  border-left-color: rgb(22 78 99 / 0.85) !important;
  border-right-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-x-cyan-900\/90 {
  border-left-color: rgb(22 78 99 / 0.9) !important;
  border-right-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-x-cyan-900\/95 {
  border-left-color: rgb(22 78 99 / 0.95) !important;
  border-right-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-x-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-x-cyan-950\/0 {
  border-left-color: rgb(8 51 68 / 0) !important;
  border-right-color: rgb(8 51 68 / 0) !important;
}

.tw-border-x-cyan-950\/10 {
  border-left-color: rgb(8 51 68 / 0.1) !important;
  border-right-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-x-cyan-950\/100 {
  border-left-color: rgb(8 51 68 / 1) !important;
  border-right-color: rgb(8 51 68 / 1) !important;
}

.tw-border-x-cyan-950\/15 {
  border-left-color: rgb(8 51 68 / 0.15) !important;
  border-right-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-x-cyan-950\/20 {
  border-left-color: rgb(8 51 68 / 0.2) !important;
  border-right-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-x-cyan-950\/25 {
  border-left-color: rgb(8 51 68 / 0.25) !important;
  border-right-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-x-cyan-950\/30 {
  border-left-color: rgb(8 51 68 / 0.3) !important;
  border-right-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-x-cyan-950\/35 {
  border-left-color: rgb(8 51 68 / 0.35) !important;
  border-right-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-x-cyan-950\/40 {
  border-left-color: rgb(8 51 68 / 0.4) !important;
  border-right-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-x-cyan-950\/45 {
  border-left-color: rgb(8 51 68 / 0.45) !important;
  border-right-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-x-cyan-950\/5 {
  border-left-color: rgb(8 51 68 / 0.05) !important;
  border-right-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-x-cyan-950\/50 {
  border-left-color: rgb(8 51 68 / 0.5) !important;
  border-right-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-x-cyan-950\/55 {
  border-left-color: rgb(8 51 68 / 0.55) !important;
  border-right-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-x-cyan-950\/60 {
  border-left-color: rgb(8 51 68 / 0.6) !important;
  border-right-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-x-cyan-950\/65 {
  border-left-color: rgb(8 51 68 / 0.65) !important;
  border-right-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-x-cyan-950\/70 {
  border-left-color: rgb(8 51 68 / 0.7) !important;
  border-right-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-x-cyan-950\/75 {
  border-left-color: rgb(8 51 68 / 0.75) !important;
  border-right-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-x-cyan-950\/80 {
  border-left-color: rgb(8 51 68 / 0.8) !important;
  border-right-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-x-cyan-950\/85 {
  border-left-color: rgb(8 51 68 / 0.85) !important;
  border-right-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-x-cyan-950\/90 {
  border-left-color: rgb(8 51 68 / 0.9) !important;
  border-right-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-x-cyan-950\/95 {
  border-left-color: rgb(8 51 68 / 0.95) !important;
  border-right-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-x-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-100\/0 {
  border-left-color: rgb(209 250 229 / 0) !important;
  border-right-color: rgb(209 250 229 / 0) !important;
}

.tw-border-x-emerald-100\/10 {
  border-left-color: rgb(209 250 229 / 0.1) !important;
  border-right-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-x-emerald-100\/100 {
  border-left-color: rgb(209 250 229 / 1) !important;
  border-right-color: rgb(209 250 229 / 1) !important;
}

.tw-border-x-emerald-100\/15 {
  border-left-color: rgb(209 250 229 / 0.15) !important;
  border-right-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-x-emerald-100\/20 {
  border-left-color: rgb(209 250 229 / 0.2) !important;
  border-right-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-x-emerald-100\/25 {
  border-left-color: rgb(209 250 229 / 0.25) !important;
  border-right-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-x-emerald-100\/30 {
  border-left-color: rgb(209 250 229 / 0.3) !important;
  border-right-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-x-emerald-100\/35 {
  border-left-color: rgb(209 250 229 / 0.35) !important;
  border-right-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-x-emerald-100\/40 {
  border-left-color: rgb(209 250 229 / 0.4) !important;
  border-right-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-x-emerald-100\/45 {
  border-left-color: rgb(209 250 229 / 0.45) !important;
  border-right-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-x-emerald-100\/5 {
  border-left-color: rgb(209 250 229 / 0.05) !important;
  border-right-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-x-emerald-100\/50 {
  border-left-color: rgb(209 250 229 / 0.5) !important;
  border-right-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-x-emerald-100\/55 {
  border-left-color: rgb(209 250 229 / 0.55) !important;
  border-right-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-x-emerald-100\/60 {
  border-left-color: rgb(209 250 229 / 0.6) !important;
  border-right-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-x-emerald-100\/65 {
  border-left-color: rgb(209 250 229 / 0.65) !important;
  border-right-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-x-emerald-100\/70 {
  border-left-color: rgb(209 250 229 / 0.7) !important;
  border-right-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-x-emerald-100\/75 {
  border-left-color: rgb(209 250 229 / 0.75) !important;
  border-right-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-x-emerald-100\/80 {
  border-left-color: rgb(209 250 229 / 0.8) !important;
  border-right-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-x-emerald-100\/85 {
  border-left-color: rgb(209 250 229 / 0.85) !important;
  border-right-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-x-emerald-100\/90 {
  border-left-color: rgb(209 250 229 / 0.9) !important;
  border-right-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-x-emerald-100\/95 {
  border-left-color: rgb(209 250 229 / 0.95) !important;
  border-right-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-x-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-200\/0 {
  border-left-color: rgb(167 243 208 / 0) !important;
  border-right-color: rgb(167 243 208 / 0) !important;
}

.tw-border-x-emerald-200\/10 {
  border-left-color: rgb(167 243 208 / 0.1) !important;
  border-right-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-x-emerald-200\/100 {
  border-left-color: rgb(167 243 208 / 1) !important;
  border-right-color: rgb(167 243 208 / 1) !important;
}

.tw-border-x-emerald-200\/15 {
  border-left-color: rgb(167 243 208 / 0.15) !important;
  border-right-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-x-emerald-200\/20 {
  border-left-color: rgb(167 243 208 / 0.2) !important;
  border-right-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-x-emerald-200\/25 {
  border-left-color: rgb(167 243 208 / 0.25) !important;
  border-right-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-x-emerald-200\/30 {
  border-left-color: rgb(167 243 208 / 0.3) !important;
  border-right-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-x-emerald-200\/35 {
  border-left-color: rgb(167 243 208 / 0.35) !important;
  border-right-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-x-emerald-200\/40 {
  border-left-color: rgb(167 243 208 / 0.4) !important;
  border-right-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-x-emerald-200\/45 {
  border-left-color: rgb(167 243 208 / 0.45) !important;
  border-right-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-x-emerald-200\/5 {
  border-left-color: rgb(167 243 208 / 0.05) !important;
  border-right-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-x-emerald-200\/50 {
  border-left-color: rgb(167 243 208 / 0.5) !important;
  border-right-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-x-emerald-200\/55 {
  border-left-color: rgb(167 243 208 / 0.55) !important;
  border-right-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-x-emerald-200\/60 {
  border-left-color: rgb(167 243 208 / 0.6) !important;
  border-right-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-x-emerald-200\/65 {
  border-left-color: rgb(167 243 208 / 0.65) !important;
  border-right-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-x-emerald-200\/70 {
  border-left-color: rgb(167 243 208 / 0.7) !important;
  border-right-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-x-emerald-200\/75 {
  border-left-color: rgb(167 243 208 / 0.75) !important;
  border-right-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-x-emerald-200\/80 {
  border-left-color: rgb(167 243 208 / 0.8) !important;
  border-right-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-x-emerald-200\/85 {
  border-left-color: rgb(167 243 208 / 0.85) !important;
  border-right-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-x-emerald-200\/90 {
  border-left-color: rgb(167 243 208 / 0.9) !important;
  border-right-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-x-emerald-200\/95 {
  border-left-color: rgb(167 243 208 / 0.95) !important;
  border-right-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-x-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-300\/0 {
  border-left-color: rgb(110 231 183 / 0) !important;
  border-right-color: rgb(110 231 183 / 0) !important;
}

.tw-border-x-emerald-300\/10 {
  border-left-color: rgb(110 231 183 / 0.1) !important;
  border-right-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-x-emerald-300\/100 {
  border-left-color: rgb(110 231 183 / 1) !important;
  border-right-color: rgb(110 231 183 / 1) !important;
}

.tw-border-x-emerald-300\/15 {
  border-left-color: rgb(110 231 183 / 0.15) !important;
  border-right-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-x-emerald-300\/20 {
  border-left-color: rgb(110 231 183 / 0.2) !important;
  border-right-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-x-emerald-300\/25 {
  border-left-color: rgb(110 231 183 / 0.25) !important;
  border-right-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-x-emerald-300\/30 {
  border-left-color: rgb(110 231 183 / 0.3) !important;
  border-right-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-x-emerald-300\/35 {
  border-left-color: rgb(110 231 183 / 0.35) !important;
  border-right-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-x-emerald-300\/40 {
  border-left-color: rgb(110 231 183 / 0.4) !important;
  border-right-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-x-emerald-300\/45 {
  border-left-color: rgb(110 231 183 / 0.45) !important;
  border-right-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-x-emerald-300\/5 {
  border-left-color: rgb(110 231 183 / 0.05) !important;
  border-right-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-x-emerald-300\/50 {
  border-left-color: rgb(110 231 183 / 0.5) !important;
  border-right-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-x-emerald-300\/55 {
  border-left-color: rgb(110 231 183 / 0.55) !important;
  border-right-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-x-emerald-300\/60 {
  border-left-color: rgb(110 231 183 / 0.6) !important;
  border-right-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-x-emerald-300\/65 {
  border-left-color: rgb(110 231 183 / 0.65) !important;
  border-right-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-x-emerald-300\/70 {
  border-left-color: rgb(110 231 183 / 0.7) !important;
  border-right-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-x-emerald-300\/75 {
  border-left-color: rgb(110 231 183 / 0.75) !important;
  border-right-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-x-emerald-300\/80 {
  border-left-color: rgb(110 231 183 / 0.8) !important;
  border-right-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-x-emerald-300\/85 {
  border-left-color: rgb(110 231 183 / 0.85) !important;
  border-right-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-x-emerald-300\/90 {
  border-left-color: rgb(110 231 183 / 0.9) !important;
  border-right-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-x-emerald-300\/95 {
  border-left-color: rgb(110 231 183 / 0.95) !important;
  border-right-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-x-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-400\/0 {
  border-left-color: rgb(52 211 153 / 0) !important;
  border-right-color: rgb(52 211 153 / 0) !important;
}

.tw-border-x-emerald-400\/10 {
  border-left-color: rgb(52 211 153 / 0.1) !important;
  border-right-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-x-emerald-400\/100 {
  border-left-color: rgb(52 211 153 / 1) !important;
  border-right-color: rgb(52 211 153 / 1) !important;
}

.tw-border-x-emerald-400\/15 {
  border-left-color: rgb(52 211 153 / 0.15) !important;
  border-right-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-x-emerald-400\/20 {
  border-left-color: rgb(52 211 153 / 0.2) !important;
  border-right-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-x-emerald-400\/25 {
  border-left-color: rgb(52 211 153 / 0.25) !important;
  border-right-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-x-emerald-400\/30 {
  border-left-color: rgb(52 211 153 / 0.3) !important;
  border-right-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-x-emerald-400\/35 {
  border-left-color: rgb(52 211 153 / 0.35) !important;
  border-right-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-x-emerald-400\/40 {
  border-left-color: rgb(52 211 153 / 0.4) !important;
  border-right-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-x-emerald-400\/45 {
  border-left-color: rgb(52 211 153 / 0.45) !important;
  border-right-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-x-emerald-400\/5 {
  border-left-color: rgb(52 211 153 / 0.05) !important;
  border-right-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-x-emerald-400\/50 {
  border-left-color: rgb(52 211 153 / 0.5) !important;
  border-right-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-x-emerald-400\/55 {
  border-left-color: rgb(52 211 153 / 0.55) !important;
  border-right-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-x-emerald-400\/60 {
  border-left-color: rgb(52 211 153 / 0.6) !important;
  border-right-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-x-emerald-400\/65 {
  border-left-color: rgb(52 211 153 / 0.65) !important;
  border-right-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-x-emerald-400\/70 {
  border-left-color: rgb(52 211 153 / 0.7) !important;
  border-right-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-x-emerald-400\/75 {
  border-left-color: rgb(52 211 153 / 0.75) !important;
  border-right-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-x-emerald-400\/80 {
  border-left-color: rgb(52 211 153 / 0.8) !important;
  border-right-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-x-emerald-400\/85 {
  border-left-color: rgb(52 211 153 / 0.85) !important;
  border-right-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-x-emerald-400\/90 {
  border-left-color: rgb(52 211 153 / 0.9) !important;
  border-right-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-x-emerald-400\/95 {
  border-left-color: rgb(52 211 153 / 0.95) !important;
  border-right-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-x-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-50\/0 {
  border-left-color: rgb(236 253 245 / 0) !important;
  border-right-color: rgb(236 253 245 / 0) !important;
}

.tw-border-x-emerald-50\/10 {
  border-left-color: rgb(236 253 245 / 0.1) !important;
  border-right-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-x-emerald-50\/100 {
  border-left-color: rgb(236 253 245 / 1) !important;
  border-right-color: rgb(236 253 245 / 1) !important;
}

.tw-border-x-emerald-50\/15 {
  border-left-color: rgb(236 253 245 / 0.15) !important;
  border-right-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-x-emerald-50\/20 {
  border-left-color: rgb(236 253 245 / 0.2) !important;
  border-right-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-x-emerald-50\/25 {
  border-left-color: rgb(236 253 245 / 0.25) !important;
  border-right-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-x-emerald-50\/30 {
  border-left-color: rgb(236 253 245 / 0.3) !important;
  border-right-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-x-emerald-50\/35 {
  border-left-color: rgb(236 253 245 / 0.35) !important;
  border-right-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-x-emerald-50\/40 {
  border-left-color: rgb(236 253 245 / 0.4) !important;
  border-right-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-x-emerald-50\/45 {
  border-left-color: rgb(236 253 245 / 0.45) !important;
  border-right-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-x-emerald-50\/5 {
  border-left-color: rgb(236 253 245 / 0.05) !important;
  border-right-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-x-emerald-50\/50 {
  border-left-color: rgb(236 253 245 / 0.5) !important;
  border-right-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-x-emerald-50\/55 {
  border-left-color: rgb(236 253 245 / 0.55) !important;
  border-right-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-x-emerald-50\/60 {
  border-left-color: rgb(236 253 245 / 0.6) !important;
  border-right-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-x-emerald-50\/65 {
  border-left-color: rgb(236 253 245 / 0.65) !important;
  border-right-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-x-emerald-50\/70 {
  border-left-color: rgb(236 253 245 / 0.7) !important;
  border-right-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-x-emerald-50\/75 {
  border-left-color: rgb(236 253 245 / 0.75) !important;
  border-right-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-x-emerald-50\/80 {
  border-left-color: rgb(236 253 245 / 0.8) !important;
  border-right-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-x-emerald-50\/85 {
  border-left-color: rgb(236 253 245 / 0.85) !important;
  border-right-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-x-emerald-50\/90 {
  border-left-color: rgb(236 253 245 / 0.9) !important;
  border-right-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-x-emerald-50\/95 {
  border-left-color: rgb(236 253 245 / 0.95) !important;
  border-right-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-x-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-500\/0 {
  border-left-color: rgb(16 185 129 / 0) !important;
  border-right-color: rgb(16 185 129 / 0) !important;
}

.tw-border-x-emerald-500\/10 {
  border-left-color: rgb(16 185 129 / 0.1) !important;
  border-right-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-x-emerald-500\/100 {
  border-left-color: rgb(16 185 129 / 1) !important;
  border-right-color: rgb(16 185 129 / 1) !important;
}

.tw-border-x-emerald-500\/15 {
  border-left-color: rgb(16 185 129 / 0.15) !important;
  border-right-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-x-emerald-500\/20 {
  border-left-color: rgb(16 185 129 / 0.2) !important;
  border-right-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-x-emerald-500\/25 {
  border-left-color: rgb(16 185 129 / 0.25) !important;
  border-right-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-x-emerald-500\/30 {
  border-left-color: rgb(16 185 129 / 0.3) !important;
  border-right-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-x-emerald-500\/35 {
  border-left-color: rgb(16 185 129 / 0.35) !important;
  border-right-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-x-emerald-500\/40 {
  border-left-color: rgb(16 185 129 / 0.4) !important;
  border-right-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-x-emerald-500\/45 {
  border-left-color: rgb(16 185 129 / 0.45) !important;
  border-right-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-x-emerald-500\/5 {
  border-left-color: rgb(16 185 129 / 0.05) !important;
  border-right-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-x-emerald-500\/50 {
  border-left-color: rgb(16 185 129 / 0.5) !important;
  border-right-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-x-emerald-500\/55 {
  border-left-color: rgb(16 185 129 / 0.55) !important;
  border-right-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-x-emerald-500\/60 {
  border-left-color: rgb(16 185 129 / 0.6) !important;
  border-right-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-x-emerald-500\/65 {
  border-left-color: rgb(16 185 129 / 0.65) !important;
  border-right-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-x-emerald-500\/70 {
  border-left-color: rgb(16 185 129 / 0.7) !important;
  border-right-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-x-emerald-500\/75 {
  border-left-color: rgb(16 185 129 / 0.75) !important;
  border-right-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-x-emerald-500\/80 {
  border-left-color: rgb(16 185 129 / 0.8) !important;
  border-right-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-x-emerald-500\/85 {
  border-left-color: rgb(16 185 129 / 0.85) !important;
  border-right-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-x-emerald-500\/90 {
  border-left-color: rgb(16 185 129 / 0.9) !important;
  border-right-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-x-emerald-500\/95 {
  border-left-color: rgb(16 185 129 / 0.95) !important;
  border-right-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-x-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-600\/0 {
  border-left-color: rgb(5 150 105 / 0) !important;
  border-right-color: rgb(5 150 105 / 0) !important;
}

.tw-border-x-emerald-600\/10 {
  border-left-color: rgb(5 150 105 / 0.1) !important;
  border-right-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-x-emerald-600\/100 {
  border-left-color: rgb(5 150 105 / 1) !important;
  border-right-color: rgb(5 150 105 / 1) !important;
}

.tw-border-x-emerald-600\/15 {
  border-left-color: rgb(5 150 105 / 0.15) !important;
  border-right-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-x-emerald-600\/20 {
  border-left-color: rgb(5 150 105 / 0.2) !important;
  border-right-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-x-emerald-600\/25 {
  border-left-color: rgb(5 150 105 / 0.25) !important;
  border-right-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-x-emerald-600\/30 {
  border-left-color: rgb(5 150 105 / 0.3) !important;
  border-right-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-x-emerald-600\/35 {
  border-left-color: rgb(5 150 105 / 0.35) !important;
  border-right-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-x-emerald-600\/40 {
  border-left-color: rgb(5 150 105 / 0.4) !important;
  border-right-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-x-emerald-600\/45 {
  border-left-color: rgb(5 150 105 / 0.45) !important;
  border-right-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-x-emerald-600\/5 {
  border-left-color: rgb(5 150 105 / 0.05) !important;
  border-right-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-x-emerald-600\/50 {
  border-left-color: rgb(5 150 105 / 0.5) !important;
  border-right-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-x-emerald-600\/55 {
  border-left-color: rgb(5 150 105 / 0.55) !important;
  border-right-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-x-emerald-600\/60 {
  border-left-color: rgb(5 150 105 / 0.6) !important;
  border-right-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-x-emerald-600\/65 {
  border-left-color: rgb(5 150 105 / 0.65) !important;
  border-right-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-x-emerald-600\/70 {
  border-left-color: rgb(5 150 105 / 0.7) !important;
  border-right-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-x-emerald-600\/75 {
  border-left-color: rgb(5 150 105 / 0.75) !important;
  border-right-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-x-emerald-600\/80 {
  border-left-color: rgb(5 150 105 / 0.8) !important;
  border-right-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-x-emerald-600\/85 {
  border-left-color: rgb(5 150 105 / 0.85) !important;
  border-right-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-x-emerald-600\/90 {
  border-left-color: rgb(5 150 105 / 0.9) !important;
  border-right-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-x-emerald-600\/95 {
  border-left-color: rgb(5 150 105 / 0.95) !important;
  border-right-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-x-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-700\/0 {
  border-left-color: rgb(4 120 87 / 0) !important;
  border-right-color: rgb(4 120 87 / 0) !important;
}

.tw-border-x-emerald-700\/10 {
  border-left-color: rgb(4 120 87 / 0.1) !important;
  border-right-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-x-emerald-700\/100 {
  border-left-color: rgb(4 120 87 / 1) !important;
  border-right-color: rgb(4 120 87 / 1) !important;
}

.tw-border-x-emerald-700\/15 {
  border-left-color: rgb(4 120 87 / 0.15) !important;
  border-right-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-x-emerald-700\/20 {
  border-left-color: rgb(4 120 87 / 0.2) !important;
  border-right-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-x-emerald-700\/25 {
  border-left-color: rgb(4 120 87 / 0.25) !important;
  border-right-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-x-emerald-700\/30 {
  border-left-color: rgb(4 120 87 / 0.3) !important;
  border-right-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-x-emerald-700\/35 {
  border-left-color: rgb(4 120 87 / 0.35) !important;
  border-right-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-x-emerald-700\/40 {
  border-left-color: rgb(4 120 87 / 0.4) !important;
  border-right-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-x-emerald-700\/45 {
  border-left-color: rgb(4 120 87 / 0.45) !important;
  border-right-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-x-emerald-700\/5 {
  border-left-color: rgb(4 120 87 / 0.05) !important;
  border-right-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-x-emerald-700\/50 {
  border-left-color: rgb(4 120 87 / 0.5) !important;
  border-right-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-x-emerald-700\/55 {
  border-left-color: rgb(4 120 87 / 0.55) !important;
  border-right-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-x-emerald-700\/60 {
  border-left-color: rgb(4 120 87 / 0.6) !important;
  border-right-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-x-emerald-700\/65 {
  border-left-color: rgb(4 120 87 / 0.65) !important;
  border-right-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-x-emerald-700\/70 {
  border-left-color: rgb(4 120 87 / 0.7) !important;
  border-right-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-x-emerald-700\/75 {
  border-left-color: rgb(4 120 87 / 0.75) !important;
  border-right-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-x-emerald-700\/80 {
  border-left-color: rgb(4 120 87 / 0.8) !important;
  border-right-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-x-emerald-700\/85 {
  border-left-color: rgb(4 120 87 / 0.85) !important;
  border-right-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-x-emerald-700\/90 {
  border-left-color: rgb(4 120 87 / 0.9) !important;
  border-right-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-x-emerald-700\/95 {
  border-left-color: rgb(4 120 87 / 0.95) !important;
  border-right-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-x-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-800\/0 {
  border-left-color: rgb(6 95 70 / 0) !important;
  border-right-color: rgb(6 95 70 / 0) !important;
}

.tw-border-x-emerald-800\/10 {
  border-left-color: rgb(6 95 70 / 0.1) !important;
  border-right-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-x-emerald-800\/100 {
  border-left-color: rgb(6 95 70 / 1) !important;
  border-right-color: rgb(6 95 70 / 1) !important;
}

.tw-border-x-emerald-800\/15 {
  border-left-color: rgb(6 95 70 / 0.15) !important;
  border-right-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-x-emerald-800\/20 {
  border-left-color: rgb(6 95 70 / 0.2) !important;
  border-right-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-x-emerald-800\/25 {
  border-left-color: rgb(6 95 70 / 0.25) !important;
  border-right-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-x-emerald-800\/30 {
  border-left-color: rgb(6 95 70 / 0.3) !important;
  border-right-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-x-emerald-800\/35 {
  border-left-color: rgb(6 95 70 / 0.35) !important;
  border-right-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-x-emerald-800\/40 {
  border-left-color: rgb(6 95 70 / 0.4) !important;
  border-right-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-x-emerald-800\/45 {
  border-left-color: rgb(6 95 70 / 0.45) !important;
  border-right-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-x-emerald-800\/5 {
  border-left-color: rgb(6 95 70 / 0.05) !important;
  border-right-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-x-emerald-800\/50 {
  border-left-color: rgb(6 95 70 / 0.5) !important;
  border-right-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-x-emerald-800\/55 {
  border-left-color: rgb(6 95 70 / 0.55) !important;
  border-right-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-x-emerald-800\/60 {
  border-left-color: rgb(6 95 70 / 0.6) !important;
  border-right-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-x-emerald-800\/65 {
  border-left-color: rgb(6 95 70 / 0.65) !important;
  border-right-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-x-emerald-800\/70 {
  border-left-color: rgb(6 95 70 / 0.7) !important;
  border-right-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-x-emerald-800\/75 {
  border-left-color: rgb(6 95 70 / 0.75) !important;
  border-right-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-x-emerald-800\/80 {
  border-left-color: rgb(6 95 70 / 0.8) !important;
  border-right-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-x-emerald-800\/85 {
  border-left-color: rgb(6 95 70 / 0.85) !important;
  border-right-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-x-emerald-800\/90 {
  border-left-color: rgb(6 95 70 / 0.9) !important;
  border-right-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-x-emerald-800\/95 {
  border-left-color: rgb(6 95 70 / 0.95) !important;
  border-right-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-x-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-900\/0 {
  border-left-color: rgb(6 78 59 / 0) !important;
  border-right-color: rgb(6 78 59 / 0) !important;
}

.tw-border-x-emerald-900\/10 {
  border-left-color: rgb(6 78 59 / 0.1) !important;
  border-right-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-x-emerald-900\/100 {
  border-left-color: rgb(6 78 59 / 1) !important;
  border-right-color: rgb(6 78 59 / 1) !important;
}

.tw-border-x-emerald-900\/15 {
  border-left-color: rgb(6 78 59 / 0.15) !important;
  border-right-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-x-emerald-900\/20 {
  border-left-color: rgb(6 78 59 / 0.2) !important;
  border-right-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-x-emerald-900\/25 {
  border-left-color: rgb(6 78 59 / 0.25) !important;
  border-right-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-x-emerald-900\/30 {
  border-left-color: rgb(6 78 59 / 0.3) !important;
  border-right-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-x-emerald-900\/35 {
  border-left-color: rgb(6 78 59 / 0.35) !important;
  border-right-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-x-emerald-900\/40 {
  border-left-color: rgb(6 78 59 / 0.4) !important;
  border-right-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-x-emerald-900\/45 {
  border-left-color: rgb(6 78 59 / 0.45) !important;
  border-right-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-x-emerald-900\/5 {
  border-left-color: rgb(6 78 59 / 0.05) !important;
  border-right-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-x-emerald-900\/50 {
  border-left-color: rgb(6 78 59 / 0.5) !important;
  border-right-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-x-emerald-900\/55 {
  border-left-color: rgb(6 78 59 / 0.55) !important;
  border-right-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-x-emerald-900\/60 {
  border-left-color: rgb(6 78 59 / 0.6) !important;
  border-right-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-x-emerald-900\/65 {
  border-left-color: rgb(6 78 59 / 0.65) !important;
  border-right-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-x-emerald-900\/70 {
  border-left-color: rgb(6 78 59 / 0.7) !important;
  border-right-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-x-emerald-900\/75 {
  border-left-color: rgb(6 78 59 / 0.75) !important;
  border-right-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-x-emerald-900\/80 {
  border-left-color: rgb(6 78 59 / 0.8) !important;
  border-right-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-x-emerald-900\/85 {
  border-left-color: rgb(6 78 59 / 0.85) !important;
  border-right-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-x-emerald-900\/90 {
  border-left-color: rgb(6 78 59 / 0.9) !important;
  border-right-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-x-emerald-900\/95 {
  border-left-color: rgb(6 78 59 / 0.95) !important;
  border-right-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-x-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-x-emerald-950\/0 {
  border-left-color: rgb(2 44 34 / 0) !important;
  border-right-color: rgb(2 44 34 / 0) !important;
}

.tw-border-x-emerald-950\/10 {
  border-left-color: rgb(2 44 34 / 0.1) !important;
  border-right-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-x-emerald-950\/100 {
  border-left-color: rgb(2 44 34 / 1) !important;
  border-right-color: rgb(2 44 34 / 1) !important;
}

.tw-border-x-emerald-950\/15 {
  border-left-color: rgb(2 44 34 / 0.15) !important;
  border-right-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-x-emerald-950\/20 {
  border-left-color: rgb(2 44 34 / 0.2) !important;
  border-right-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-x-emerald-950\/25 {
  border-left-color: rgb(2 44 34 / 0.25) !important;
  border-right-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-x-emerald-950\/30 {
  border-left-color: rgb(2 44 34 / 0.3) !important;
  border-right-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-x-emerald-950\/35 {
  border-left-color: rgb(2 44 34 / 0.35) !important;
  border-right-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-x-emerald-950\/40 {
  border-left-color: rgb(2 44 34 / 0.4) !important;
  border-right-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-x-emerald-950\/45 {
  border-left-color: rgb(2 44 34 / 0.45) !important;
  border-right-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-x-emerald-950\/5 {
  border-left-color: rgb(2 44 34 / 0.05) !important;
  border-right-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-x-emerald-950\/50 {
  border-left-color: rgb(2 44 34 / 0.5) !important;
  border-right-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-x-emerald-950\/55 {
  border-left-color: rgb(2 44 34 / 0.55) !important;
  border-right-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-x-emerald-950\/60 {
  border-left-color: rgb(2 44 34 / 0.6) !important;
  border-right-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-x-emerald-950\/65 {
  border-left-color: rgb(2 44 34 / 0.65) !important;
  border-right-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-x-emerald-950\/70 {
  border-left-color: rgb(2 44 34 / 0.7) !important;
  border-right-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-x-emerald-950\/75 {
  border-left-color: rgb(2 44 34 / 0.75) !important;
  border-right-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-x-emerald-950\/80 {
  border-left-color: rgb(2 44 34 / 0.8) !important;
  border-right-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-x-emerald-950\/85 {
  border-left-color: rgb(2 44 34 / 0.85) !important;
  border-right-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-x-emerald-950\/90 {
  border-left-color: rgb(2 44 34 / 0.9) !important;
  border-right-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-x-emerald-950\/95 {
  border-left-color: rgb(2 44 34 / 0.95) !important;
  border-right-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-x-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-100\/0 {
  border-left-color: rgb(250 232 255 / 0) !important;
  border-right-color: rgb(250 232 255 / 0) !important;
}

.tw-border-x-fuchsia-100\/10 {
  border-left-color: rgb(250 232 255 / 0.1) !important;
  border-right-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-x-fuchsia-100\/100 {
  border-left-color: rgb(250 232 255 / 1) !important;
  border-right-color: rgb(250 232 255 / 1) !important;
}

.tw-border-x-fuchsia-100\/15 {
  border-left-color: rgb(250 232 255 / 0.15) !important;
  border-right-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-x-fuchsia-100\/20 {
  border-left-color: rgb(250 232 255 / 0.2) !important;
  border-right-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-x-fuchsia-100\/25 {
  border-left-color: rgb(250 232 255 / 0.25) !important;
  border-right-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-x-fuchsia-100\/30 {
  border-left-color: rgb(250 232 255 / 0.3) !important;
  border-right-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-x-fuchsia-100\/35 {
  border-left-color: rgb(250 232 255 / 0.35) !important;
  border-right-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-x-fuchsia-100\/40 {
  border-left-color: rgb(250 232 255 / 0.4) !important;
  border-right-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-x-fuchsia-100\/45 {
  border-left-color: rgb(250 232 255 / 0.45) !important;
  border-right-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-x-fuchsia-100\/5 {
  border-left-color: rgb(250 232 255 / 0.05) !important;
  border-right-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-x-fuchsia-100\/50 {
  border-left-color: rgb(250 232 255 / 0.5) !important;
  border-right-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-x-fuchsia-100\/55 {
  border-left-color: rgb(250 232 255 / 0.55) !important;
  border-right-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-x-fuchsia-100\/60 {
  border-left-color: rgb(250 232 255 / 0.6) !important;
  border-right-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-x-fuchsia-100\/65 {
  border-left-color: rgb(250 232 255 / 0.65) !important;
  border-right-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-x-fuchsia-100\/70 {
  border-left-color: rgb(250 232 255 / 0.7) !important;
  border-right-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-x-fuchsia-100\/75 {
  border-left-color: rgb(250 232 255 / 0.75) !important;
  border-right-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-x-fuchsia-100\/80 {
  border-left-color: rgb(250 232 255 / 0.8) !important;
  border-right-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-x-fuchsia-100\/85 {
  border-left-color: rgb(250 232 255 / 0.85) !important;
  border-right-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-x-fuchsia-100\/90 {
  border-left-color: rgb(250 232 255 / 0.9) !important;
  border-right-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-x-fuchsia-100\/95 {
  border-left-color: rgb(250 232 255 / 0.95) !important;
  border-right-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-x-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-200\/0 {
  border-left-color: rgb(245 208 254 / 0) !important;
  border-right-color: rgb(245 208 254 / 0) !important;
}

.tw-border-x-fuchsia-200\/10 {
  border-left-color: rgb(245 208 254 / 0.1) !important;
  border-right-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-x-fuchsia-200\/100 {
  border-left-color: rgb(245 208 254 / 1) !important;
  border-right-color: rgb(245 208 254 / 1) !important;
}

.tw-border-x-fuchsia-200\/15 {
  border-left-color: rgb(245 208 254 / 0.15) !important;
  border-right-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-x-fuchsia-200\/20 {
  border-left-color: rgb(245 208 254 / 0.2) !important;
  border-right-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-x-fuchsia-200\/25 {
  border-left-color: rgb(245 208 254 / 0.25) !important;
  border-right-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-x-fuchsia-200\/30 {
  border-left-color: rgb(245 208 254 / 0.3) !important;
  border-right-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-x-fuchsia-200\/35 {
  border-left-color: rgb(245 208 254 / 0.35) !important;
  border-right-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-x-fuchsia-200\/40 {
  border-left-color: rgb(245 208 254 / 0.4) !important;
  border-right-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-x-fuchsia-200\/45 {
  border-left-color: rgb(245 208 254 / 0.45) !important;
  border-right-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-x-fuchsia-200\/5 {
  border-left-color: rgb(245 208 254 / 0.05) !important;
  border-right-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-x-fuchsia-200\/50 {
  border-left-color: rgb(245 208 254 / 0.5) !important;
  border-right-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-x-fuchsia-200\/55 {
  border-left-color: rgb(245 208 254 / 0.55) !important;
  border-right-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-x-fuchsia-200\/60 {
  border-left-color: rgb(245 208 254 / 0.6) !important;
  border-right-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-x-fuchsia-200\/65 {
  border-left-color: rgb(245 208 254 / 0.65) !important;
  border-right-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-x-fuchsia-200\/70 {
  border-left-color: rgb(245 208 254 / 0.7) !important;
  border-right-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-x-fuchsia-200\/75 {
  border-left-color: rgb(245 208 254 / 0.75) !important;
  border-right-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-x-fuchsia-200\/80 {
  border-left-color: rgb(245 208 254 / 0.8) !important;
  border-right-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-x-fuchsia-200\/85 {
  border-left-color: rgb(245 208 254 / 0.85) !important;
  border-right-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-x-fuchsia-200\/90 {
  border-left-color: rgb(245 208 254 / 0.9) !important;
  border-right-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-x-fuchsia-200\/95 {
  border-left-color: rgb(245 208 254 / 0.95) !important;
  border-right-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-x-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-300\/0 {
  border-left-color: rgb(240 171 252 / 0) !important;
  border-right-color: rgb(240 171 252 / 0) !important;
}

.tw-border-x-fuchsia-300\/10 {
  border-left-color: rgb(240 171 252 / 0.1) !important;
  border-right-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-x-fuchsia-300\/100 {
  border-left-color: rgb(240 171 252 / 1) !important;
  border-right-color: rgb(240 171 252 / 1) !important;
}

.tw-border-x-fuchsia-300\/15 {
  border-left-color: rgb(240 171 252 / 0.15) !important;
  border-right-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-x-fuchsia-300\/20 {
  border-left-color: rgb(240 171 252 / 0.2) !important;
  border-right-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-x-fuchsia-300\/25 {
  border-left-color: rgb(240 171 252 / 0.25) !important;
  border-right-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-x-fuchsia-300\/30 {
  border-left-color: rgb(240 171 252 / 0.3) !important;
  border-right-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-x-fuchsia-300\/35 {
  border-left-color: rgb(240 171 252 / 0.35) !important;
  border-right-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-x-fuchsia-300\/40 {
  border-left-color: rgb(240 171 252 / 0.4) !important;
  border-right-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-x-fuchsia-300\/45 {
  border-left-color: rgb(240 171 252 / 0.45) !important;
  border-right-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-x-fuchsia-300\/5 {
  border-left-color: rgb(240 171 252 / 0.05) !important;
  border-right-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-x-fuchsia-300\/50 {
  border-left-color: rgb(240 171 252 / 0.5) !important;
  border-right-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-x-fuchsia-300\/55 {
  border-left-color: rgb(240 171 252 / 0.55) !important;
  border-right-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-x-fuchsia-300\/60 {
  border-left-color: rgb(240 171 252 / 0.6) !important;
  border-right-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-x-fuchsia-300\/65 {
  border-left-color: rgb(240 171 252 / 0.65) !important;
  border-right-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-x-fuchsia-300\/70 {
  border-left-color: rgb(240 171 252 / 0.7) !important;
  border-right-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-x-fuchsia-300\/75 {
  border-left-color: rgb(240 171 252 / 0.75) !important;
  border-right-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-x-fuchsia-300\/80 {
  border-left-color: rgb(240 171 252 / 0.8) !important;
  border-right-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-x-fuchsia-300\/85 {
  border-left-color: rgb(240 171 252 / 0.85) !important;
  border-right-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-x-fuchsia-300\/90 {
  border-left-color: rgb(240 171 252 / 0.9) !important;
  border-right-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-x-fuchsia-300\/95 {
  border-left-color: rgb(240 171 252 / 0.95) !important;
  border-right-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-x-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-400\/0 {
  border-left-color: rgb(232 121 249 / 0) !important;
  border-right-color: rgb(232 121 249 / 0) !important;
}

.tw-border-x-fuchsia-400\/10 {
  border-left-color: rgb(232 121 249 / 0.1) !important;
  border-right-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-x-fuchsia-400\/100 {
  border-left-color: rgb(232 121 249 / 1) !important;
  border-right-color: rgb(232 121 249 / 1) !important;
}

.tw-border-x-fuchsia-400\/15 {
  border-left-color: rgb(232 121 249 / 0.15) !important;
  border-right-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-x-fuchsia-400\/20 {
  border-left-color: rgb(232 121 249 / 0.2) !important;
  border-right-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-x-fuchsia-400\/25 {
  border-left-color: rgb(232 121 249 / 0.25) !important;
  border-right-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-x-fuchsia-400\/30 {
  border-left-color: rgb(232 121 249 / 0.3) !important;
  border-right-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-x-fuchsia-400\/35 {
  border-left-color: rgb(232 121 249 / 0.35) !important;
  border-right-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-x-fuchsia-400\/40 {
  border-left-color: rgb(232 121 249 / 0.4) !important;
  border-right-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-x-fuchsia-400\/45 {
  border-left-color: rgb(232 121 249 / 0.45) !important;
  border-right-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-x-fuchsia-400\/5 {
  border-left-color: rgb(232 121 249 / 0.05) !important;
  border-right-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-x-fuchsia-400\/50 {
  border-left-color: rgb(232 121 249 / 0.5) !important;
  border-right-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-x-fuchsia-400\/55 {
  border-left-color: rgb(232 121 249 / 0.55) !important;
  border-right-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-x-fuchsia-400\/60 {
  border-left-color: rgb(232 121 249 / 0.6) !important;
  border-right-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-x-fuchsia-400\/65 {
  border-left-color: rgb(232 121 249 / 0.65) !important;
  border-right-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-x-fuchsia-400\/70 {
  border-left-color: rgb(232 121 249 / 0.7) !important;
  border-right-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-x-fuchsia-400\/75 {
  border-left-color: rgb(232 121 249 / 0.75) !important;
  border-right-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-x-fuchsia-400\/80 {
  border-left-color: rgb(232 121 249 / 0.8) !important;
  border-right-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-x-fuchsia-400\/85 {
  border-left-color: rgb(232 121 249 / 0.85) !important;
  border-right-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-x-fuchsia-400\/90 {
  border-left-color: rgb(232 121 249 / 0.9) !important;
  border-right-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-x-fuchsia-400\/95 {
  border-left-color: rgb(232 121 249 / 0.95) !important;
  border-right-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-x-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-50\/0 {
  border-left-color: rgb(253 244 255 / 0) !important;
  border-right-color: rgb(253 244 255 / 0) !important;
}

.tw-border-x-fuchsia-50\/10 {
  border-left-color: rgb(253 244 255 / 0.1) !important;
  border-right-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-x-fuchsia-50\/100 {
  border-left-color: rgb(253 244 255 / 1) !important;
  border-right-color: rgb(253 244 255 / 1) !important;
}

.tw-border-x-fuchsia-50\/15 {
  border-left-color: rgb(253 244 255 / 0.15) !important;
  border-right-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-x-fuchsia-50\/20 {
  border-left-color: rgb(253 244 255 / 0.2) !important;
  border-right-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-x-fuchsia-50\/25 {
  border-left-color: rgb(253 244 255 / 0.25) !important;
  border-right-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-x-fuchsia-50\/30 {
  border-left-color: rgb(253 244 255 / 0.3) !important;
  border-right-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-x-fuchsia-50\/35 {
  border-left-color: rgb(253 244 255 / 0.35) !important;
  border-right-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-x-fuchsia-50\/40 {
  border-left-color: rgb(253 244 255 / 0.4) !important;
  border-right-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-x-fuchsia-50\/45 {
  border-left-color: rgb(253 244 255 / 0.45) !important;
  border-right-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-x-fuchsia-50\/5 {
  border-left-color: rgb(253 244 255 / 0.05) !important;
  border-right-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-x-fuchsia-50\/50 {
  border-left-color: rgb(253 244 255 / 0.5) !important;
  border-right-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-x-fuchsia-50\/55 {
  border-left-color: rgb(253 244 255 / 0.55) !important;
  border-right-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-x-fuchsia-50\/60 {
  border-left-color: rgb(253 244 255 / 0.6) !important;
  border-right-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-x-fuchsia-50\/65 {
  border-left-color: rgb(253 244 255 / 0.65) !important;
  border-right-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-x-fuchsia-50\/70 {
  border-left-color: rgb(253 244 255 / 0.7) !important;
  border-right-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-x-fuchsia-50\/75 {
  border-left-color: rgb(253 244 255 / 0.75) !important;
  border-right-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-x-fuchsia-50\/80 {
  border-left-color: rgb(253 244 255 / 0.8) !important;
  border-right-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-x-fuchsia-50\/85 {
  border-left-color: rgb(253 244 255 / 0.85) !important;
  border-right-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-x-fuchsia-50\/90 {
  border-left-color: rgb(253 244 255 / 0.9) !important;
  border-right-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-x-fuchsia-50\/95 {
  border-left-color: rgb(253 244 255 / 0.95) !important;
  border-right-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-x-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-500\/0 {
  border-left-color: rgb(217 70 239 / 0) !important;
  border-right-color: rgb(217 70 239 / 0) !important;
}

.tw-border-x-fuchsia-500\/10 {
  border-left-color: rgb(217 70 239 / 0.1) !important;
  border-right-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-x-fuchsia-500\/100 {
  border-left-color: rgb(217 70 239 / 1) !important;
  border-right-color: rgb(217 70 239 / 1) !important;
}

.tw-border-x-fuchsia-500\/15 {
  border-left-color: rgb(217 70 239 / 0.15) !important;
  border-right-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-x-fuchsia-500\/20 {
  border-left-color: rgb(217 70 239 / 0.2) !important;
  border-right-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-x-fuchsia-500\/25 {
  border-left-color: rgb(217 70 239 / 0.25) !important;
  border-right-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-x-fuchsia-500\/30 {
  border-left-color: rgb(217 70 239 / 0.3) !important;
  border-right-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-x-fuchsia-500\/35 {
  border-left-color: rgb(217 70 239 / 0.35) !important;
  border-right-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-x-fuchsia-500\/40 {
  border-left-color: rgb(217 70 239 / 0.4) !important;
  border-right-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-x-fuchsia-500\/45 {
  border-left-color: rgb(217 70 239 / 0.45) !important;
  border-right-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-x-fuchsia-500\/5 {
  border-left-color: rgb(217 70 239 / 0.05) !important;
  border-right-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-x-fuchsia-500\/50 {
  border-left-color: rgb(217 70 239 / 0.5) !important;
  border-right-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-x-fuchsia-500\/55 {
  border-left-color: rgb(217 70 239 / 0.55) !important;
  border-right-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-x-fuchsia-500\/60 {
  border-left-color: rgb(217 70 239 / 0.6) !important;
  border-right-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-x-fuchsia-500\/65 {
  border-left-color: rgb(217 70 239 / 0.65) !important;
  border-right-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-x-fuchsia-500\/70 {
  border-left-color: rgb(217 70 239 / 0.7) !important;
  border-right-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-x-fuchsia-500\/75 {
  border-left-color: rgb(217 70 239 / 0.75) !important;
  border-right-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-x-fuchsia-500\/80 {
  border-left-color: rgb(217 70 239 / 0.8) !important;
  border-right-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-x-fuchsia-500\/85 {
  border-left-color: rgb(217 70 239 / 0.85) !important;
  border-right-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-x-fuchsia-500\/90 {
  border-left-color: rgb(217 70 239 / 0.9) !important;
  border-right-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-x-fuchsia-500\/95 {
  border-left-color: rgb(217 70 239 / 0.95) !important;
  border-right-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-x-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-600\/0 {
  border-left-color: rgb(192 38 211 / 0) !important;
  border-right-color: rgb(192 38 211 / 0) !important;
}

.tw-border-x-fuchsia-600\/10 {
  border-left-color: rgb(192 38 211 / 0.1) !important;
  border-right-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-x-fuchsia-600\/100 {
  border-left-color: rgb(192 38 211 / 1) !important;
  border-right-color: rgb(192 38 211 / 1) !important;
}

.tw-border-x-fuchsia-600\/15 {
  border-left-color: rgb(192 38 211 / 0.15) !important;
  border-right-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-x-fuchsia-600\/20 {
  border-left-color: rgb(192 38 211 / 0.2) !important;
  border-right-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-x-fuchsia-600\/25 {
  border-left-color: rgb(192 38 211 / 0.25) !important;
  border-right-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-x-fuchsia-600\/30 {
  border-left-color: rgb(192 38 211 / 0.3) !important;
  border-right-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-x-fuchsia-600\/35 {
  border-left-color: rgb(192 38 211 / 0.35) !important;
  border-right-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-x-fuchsia-600\/40 {
  border-left-color: rgb(192 38 211 / 0.4) !important;
  border-right-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-x-fuchsia-600\/45 {
  border-left-color: rgb(192 38 211 / 0.45) !important;
  border-right-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-x-fuchsia-600\/5 {
  border-left-color: rgb(192 38 211 / 0.05) !important;
  border-right-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-x-fuchsia-600\/50 {
  border-left-color: rgb(192 38 211 / 0.5) !important;
  border-right-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-x-fuchsia-600\/55 {
  border-left-color: rgb(192 38 211 / 0.55) !important;
  border-right-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-x-fuchsia-600\/60 {
  border-left-color: rgb(192 38 211 / 0.6) !important;
  border-right-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-x-fuchsia-600\/65 {
  border-left-color: rgb(192 38 211 / 0.65) !important;
  border-right-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-x-fuchsia-600\/70 {
  border-left-color: rgb(192 38 211 / 0.7) !important;
  border-right-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-x-fuchsia-600\/75 {
  border-left-color: rgb(192 38 211 / 0.75) !important;
  border-right-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-x-fuchsia-600\/80 {
  border-left-color: rgb(192 38 211 / 0.8) !important;
  border-right-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-x-fuchsia-600\/85 {
  border-left-color: rgb(192 38 211 / 0.85) !important;
  border-right-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-x-fuchsia-600\/90 {
  border-left-color: rgb(192 38 211 / 0.9) !important;
  border-right-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-x-fuchsia-600\/95 {
  border-left-color: rgb(192 38 211 / 0.95) !important;
  border-right-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-x-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-700\/0 {
  border-left-color: rgb(162 28 175 / 0) !important;
  border-right-color: rgb(162 28 175 / 0) !important;
}

.tw-border-x-fuchsia-700\/10 {
  border-left-color: rgb(162 28 175 / 0.1) !important;
  border-right-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-x-fuchsia-700\/100 {
  border-left-color: rgb(162 28 175 / 1) !important;
  border-right-color: rgb(162 28 175 / 1) !important;
}

.tw-border-x-fuchsia-700\/15 {
  border-left-color: rgb(162 28 175 / 0.15) !important;
  border-right-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-x-fuchsia-700\/20 {
  border-left-color: rgb(162 28 175 / 0.2) !important;
  border-right-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-x-fuchsia-700\/25 {
  border-left-color: rgb(162 28 175 / 0.25) !important;
  border-right-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-x-fuchsia-700\/30 {
  border-left-color: rgb(162 28 175 / 0.3) !important;
  border-right-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-x-fuchsia-700\/35 {
  border-left-color: rgb(162 28 175 / 0.35) !important;
  border-right-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-x-fuchsia-700\/40 {
  border-left-color: rgb(162 28 175 / 0.4) !important;
  border-right-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-x-fuchsia-700\/45 {
  border-left-color: rgb(162 28 175 / 0.45) !important;
  border-right-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-x-fuchsia-700\/5 {
  border-left-color: rgb(162 28 175 / 0.05) !important;
  border-right-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-x-fuchsia-700\/50 {
  border-left-color: rgb(162 28 175 / 0.5) !important;
  border-right-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-x-fuchsia-700\/55 {
  border-left-color: rgb(162 28 175 / 0.55) !important;
  border-right-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-x-fuchsia-700\/60 {
  border-left-color: rgb(162 28 175 / 0.6) !important;
  border-right-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-x-fuchsia-700\/65 {
  border-left-color: rgb(162 28 175 / 0.65) !important;
  border-right-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-x-fuchsia-700\/70 {
  border-left-color: rgb(162 28 175 / 0.7) !important;
  border-right-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-x-fuchsia-700\/75 {
  border-left-color: rgb(162 28 175 / 0.75) !important;
  border-right-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-x-fuchsia-700\/80 {
  border-left-color: rgb(162 28 175 / 0.8) !important;
  border-right-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-x-fuchsia-700\/85 {
  border-left-color: rgb(162 28 175 / 0.85) !important;
  border-right-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-x-fuchsia-700\/90 {
  border-left-color: rgb(162 28 175 / 0.9) !important;
  border-right-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-x-fuchsia-700\/95 {
  border-left-color: rgb(162 28 175 / 0.95) !important;
  border-right-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-x-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-800\/0 {
  border-left-color: rgb(134 25 143 / 0) !important;
  border-right-color: rgb(134 25 143 / 0) !important;
}

.tw-border-x-fuchsia-800\/10 {
  border-left-color: rgb(134 25 143 / 0.1) !important;
  border-right-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-x-fuchsia-800\/100 {
  border-left-color: rgb(134 25 143 / 1) !important;
  border-right-color: rgb(134 25 143 / 1) !important;
}

.tw-border-x-fuchsia-800\/15 {
  border-left-color: rgb(134 25 143 / 0.15) !important;
  border-right-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-x-fuchsia-800\/20 {
  border-left-color: rgb(134 25 143 / 0.2) !important;
  border-right-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-x-fuchsia-800\/25 {
  border-left-color: rgb(134 25 143 / 0.25) !important;
  border-right-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-x-fuchsia-800\/30 {
  border-left-color: rgb(134 25 143 / 0.3) !important;
  border-right-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-x-fuchsia-800\/35 {
  border-left-color: rgb(134 25 143 / 0.35) !important;
  border-right-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-x-fuchsia-800\/40 {
  border-left-color: rgb(134 25 143 / 0.4) !important;
  border-right-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-x-fuchsia-800\/45 {
  border-left-color: rgb(134 25 143 / 0.45) !important;
  border-right-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-x-fuchsia-800\/5 {
  border-left-color: rgb(134 25 143 / 0.05) !important;
  border-right-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-x-fuchsia-800\/50 {
  border-left-color: rgb(134 25 143 / 0.5) !important;
  border-right-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-x-fuchsia-800\/55 {
  border-left-color: rgb(134 25 143 / 0.55) !important;
  border-right-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-x-fuchsia-800\/60 {
  border-left-color: rgb(134 25 143 / 0.6) !important;
  border-right-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-x-fuchsia-800\/65 {
  border-left-color: rgb(134 25 143 / 0.65) !important;
  border-right-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-x-fuchsia-800\/70 {
  border-left-color: rgb(134 25 143 / 0.7) !important;
  border-right-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-x-fuchsia-800\/75 {
  border-left-color: rgb(134 25 143 / 0.75) !important;
  border-right-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-x-fuchsia-800\/80 {
  border-left-color: rgb(134 25 143 / 0.8) !important;
  border-right-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-x-fuchsia-800\/85 {
  border-left-color: rgb(134 25 143 / 0.85) !important;
  border-right-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-x-fuchsia-800\/90 {
  border-left-color: rgb(134 25 143 / 0.9) !important;
  border-right-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-x-fuchsia-800\/95 {
  border-left-color: rgb(134 25 143 / 0.95) !important;
  border-right-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-x-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-900\/0 {
  border-left-color: rgb(112 26 117 / 0) !important;
  border-right-color: rgb(112 26 117 / 0) !important;
}

.tw-border-x-fuchsia-900\/10 {
  border-left-color: rgb(112 26 117 / 0.1) !important;
  border-right-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-x-fuchsia-900\/100 {
  border-left-color: rgb(112 26 117 / 1) !important;
  border-right-color: rgb(112 26 117 / 1) !important;
}

.tw-border-x-fuchsia-900\/15 {
  border-left-color: rgb(112 26 117 / 0.15) !important;
  border-right-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-x-fuchsia-900\/20 {
  border-left-color: rgb(112 26 117 / 0.2) !important;
  border-right-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-x-fuchsia-900\/25 {
  border-left-color: rgb(112 26 117 / 0.25) !important;
  border-right-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-x-fuchsia-900\/30 {
  border-left-color: rgb(112 26 117 / 0.3) !important;
  border-right-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-x-fuchsia-900\/35 {
  border-left-color: rgb(112 26 117 / 0.35) !important;
  border-right-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-x-fuchsia-900\/40 {
  border-left-color: rgb(112 26 117 / 0.4) !important;
  border-right-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-x-fuchsia-900\/45 {
  border-left-color: rgb(112 26 117 / 0.45) !important;
  border-right-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-x-fuchsia-900\/5 {
  border-left-color: rgb(112 26 117 / 0.05) !important;
  border-right-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-x-fuchsia-900\/50 {
  border-left-color: rgb(112 26 117 / 0.5) !important;
  border-right-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-x-fuchsia-900\/55 {
  border-left-color: rgb(112 26 117 / 0.55) !important;
  border-right-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-x-fuchsia-900\/60 {
  border-left-color: rgb(112 26 117 / 0.6) !important;
  border-right-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-x-fuchsia-900\/65 {
  border-left-color: rgb(112 26 117 / 0.65) !important;
  border-right-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-x-fuchsia-900\/70 {
  border-left-color: rgb(112 26 117 / 0.7) !important;
  border-right-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-x-fuchsia-900\/75 {
  border-left-color: rgb(112 26 117 / 0.75) !important;
  border-right-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-x-fuchsia-900\/80 {
  border-left-color: rgb(112 26 117 / 0.8) !important;
  border-right-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-x-fuchsia-900\/85 {
  border-left-color: rgb(112 26 117 / 0.85) !important;
  border-right-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-x-fuchsia-900\/90 {
  border-left-color: rgb(112 26 117 / 0.9) !important;
  border-right-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-x-fuchsia-900\/95 {
  border-left-color: rgb(112 26 117 / 0.95) !important;
  border-right-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-x-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-x-fuchsia-950\/0 {
  border-left-color: rgb(74 4 78 / 0) !important;
  border-right-color: rgb(74 4 78 / 0) !important;
}

.tw-border-x-fuchsia-950\/10 {
  border-left-color: rgb(74 4 78 / 0.1) !important;
  border-right-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-x-fuchsia-950\/100 {
  border-left-color: rgb(74 4 78 / 1) !important;
  border-right-color: rgb(74 4 78 / 1) !important;
}

.tw-border-x-fuchsia-950\/15 {
  border-left-color: rgb(74 4 78 / 0.15) !important;
  border-right-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-x-fuchsia-950\/20 {
  border-left-color: rgb(74 4 78 / 0.2) !important;
  border-right-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-x-fuchsia-950\/25 {
  border-left-color: rgb(74 4 78 / 0.25) !important;
  border-right-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-x-fuchsia-950\/30 {
  border-left-color: rgb(74 4 78 / 0.3) !important;
  border-right-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-x-fuchsia-950\/35 {
  border-left-color: rgb(74 4 78 / 0.35) !important;
  border-right-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-x-fuchsia-950\/40 {
  border-left-color: rgb(74 4 78 / 0.4) !important;
  border-right-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-x-fuchsia-950\/45 {
  border-left-color: rgb(74 4 78 / 0.45) !important;
  border-right-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-x-fuchsia-950\/5 {
  border-left-color: rgb(74 4 78 / 0.05) !important;
  border-right-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-x-fuchsia-950\/50 {
  border-left-color: rgb(74 4 78 / 0.5) !important;
  border-right-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-x-fuchsia-950\/55 {
  border-left-color: rgb(74 4 78 / 0.55) !important;
  border-right-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-x-fuchsia-950\/60 {
  border-left-color: rgb(74 4 78 / 0.6) !important;
  border-right-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-x-fuchsia-950\/65 {
  border-left-color: rgb(74 4 78 / 0.65) !important;
  border-right-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-x-fuchsia-950\/70 {
  border-left-color: rgb(74 4 78 / 0.7) !important;
  border-right-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-x-fuchsia-950\/75 {
  border-left-color: rgb(74 4 78 / 0.75) !important;
  border-right-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-x-fuchsia-950\/80 {
  border-left-color: rgb(74 4 78 / 0.8) !important;
  border-right-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-x-fuchsia-950\/85 {
  border-left-color: rgb(74 4 78 / 0.85) !important;
  border-right-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-x-fuchsia-950\/90 {
  border-left-color: rgb(74 4 78 / 0.9) !important;
  border-right-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-x-fuchsia-950\/95 {
  border-left-color: rgb(74 4 78 / 0.95) !important;
  border-right-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-x-gray-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-100\/0 {
  border-left-color: rgb(243 244 246 / 0) !important;
  border-right-color: rgb(243 244 246 / 0) !important;
}

.tw-border-x-gray-100\/10 {
  border-left-color: rgb(243 244 246 / 0.1) !important;
  border-right-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-x-gray-100\/100 {
  border-left-color: rgb(243 244 246 / 1) !important;
  border-right-color: rgb(243 244 246 / 1) !important;
}

.tw-border-x-gray-100\/15 {
  border-left-color: rgb(243 244 246 / 0.15) !important;
  border-right-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-x-gray-100\/20 {
  border-left-color: rgb(243 244 246 / 0.2) !important;
  border-right-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-x-gray-100\/25 {
  border-left-color: rgb(243 244 246 / 0.25) !important;
  border-right-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-x-gray-100\/30 {
  border-left-color: rgb(243 244 246 / 0.3) !important;
  border-right-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-x-gray-100\/35 {
  border-left-color: rgb(243 244 246 / 0.35) !important;
  border-right-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-x-gray-100\/40 {
  border-left-color: rgb(243 244 246 / 0.4) !important;
  border-right-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-x-gray-100\/45 {
  border-left-color: rgb(243 244 246 / 0.45) !important;
  border-right-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-x-gray-100\/5 {
  border-left-color: rgb(243 244 246 / 0.05) !important;
  border-right-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-x-gray-100\/50 {
  border-left-color: rgb(243 244 246 / 0.5) !important;
  border-right-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-x-gray-100\/55 {
  border-left-color: rgb(243 244 246 / 0.55) !important;
  border-right-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-x-gray-100\/60 {
  border-left-color: rgb(243 244 246 / 0.6) !important;
  border-right-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-x-gray-100\/65 {
  border-left-color: rgb(243 244 246 / 0.65) !important;
  border-right-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-x-gray-100\/70 {
  border-left-color: rgb(243 244 246 / 0.7) !important;
  border-right-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-x-gray-100\/75 {
  border-left-color: rgb(243 244 246 / 0.75) !important;
  border-right-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-x-gray-100\/80 {
  border-left-color: rgb(243 244 246 / 0.8) !important;
  border-right-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-x-gray-100\/85 {
  border-left-color: rgb(243 244 246 / 0.85) !important;
  border-right-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-x-gray-100\/90 {
  border-left-color: rgb(243 244 246 / 0.9) !important;
  border-right-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-x-gray-100\/95 {
  border-left-color: rgb(243 244 246 / 0.95) !important;
  border-right-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-x-gray-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-200\/0 {
  border-left-color: rgb(229 231 235 / 0) !important;
  border-right-color: rgb(229 231 235 / 0) !important;
}

.tw-border-x-gray-200\/10 {
  border-left-color: rgb(229 231 235 / 0.1) !important;
  border-right-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-x-gray-200\/100 {
  border-left-color: rgb(229 231 235 / 1) !important;
  border-right-color: rgb(229 231 235 / 1) !important;
}

.tw-border-x-gray-200\/15 {
  border-left-color: rgb(229 231 235 / 0.15) !important;
  border-right-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-x-gray-200\/20 {
  border-left-color: rgb(229 231 235 / 0.2) !important;
  border-right-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-x-gray-200\/25 {
  border-left-color: rgb(229 231 235 / 0.25) !important;
  border-right-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-x-gray-200\/30 {
  border-left-color: rgb(229 231 235 / 0.3) !important;
  border-right-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-x-gray-200\/35 {
  border-left-color: rgb(229 231 235 / 0.35) !important;
  border-right-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-x-gray-200\/40 {
  border-left-color: rgb(229 231 235 / 0.4) !important;
  border-right-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-x-gray-200\/45 {
  border-left-color: rgb(229 231 235 / 0.45) !important;
  border-right-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-x-gray-200\/5 {
  border-left-color: rgb(229 231 235 / 0.05) !important;
  border-right-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-x-gray-200\/50 {
  border-left-color: rgb(229 231 235 / 0.5) !important;
  border-right-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-x-gray-200\/55 {
  border-left-color: rgb(229 231 235 / 0.55) !important;
  border-right-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-x-gray-200\/60 {
  border-left-color: rgb(229 231 235 / 0.6) !important;
  border-right-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-x-gray-200\/65 {
  border-left-color: rgb(229 231 235 / 0.65) !important;
  border-right-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-x-gray-200\/70 {
  border-left-color: rgb(229 231 235 / 0.7) !important;
  border-right-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-x-gray-200\/75 {
  border-left-color: rgb(229 231 235 / 0.75) !important;
  border-right-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-x-gray-200\/80 {
  border-left-color: rgb(229 231 235 / 0.8) !important;
  border-right-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-x-gray-200\/85 {
  border-left-color: rgb(229 231 235 / 0.85) !important;
  border-right-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-x-gray-200\/90 {
  border-left-color: rgb(229 231 235 / 0.9) !important;
  border-right-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-x-gray-200\/95 {
  border-left-color: rgb(229 231 235 / 0.95) !important;
  border-right-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-x-gray-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-300\/0 {
  border-left-color: rgb(209 213 219 / 0) !important;
  border-right-color: rgb(209 213 219 / 0) !important;
}

.tw-border-x-gray-300\/10 {
  border-left-color: rgb(209 213 219 / 0.1) !important;
  border-right-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-x-gray-300\/100 {
  border-left-color: rgb(209 213 219 / 1) !important;
  border-right-color: rgb(209 213 219 / 1) !important;
}

.tw-border-x-gray-300\/15 {
  border-left-color: rgb(209 213 219 / 0.15) !important;
  border-right-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-x-gray-300\/20 {
  border-left-color: rgb(209 213 219 / 0.2) !important;
  border-right-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-x-gray-300\/25 {
  border-left-color: rgb(209 213 219 / 0.25) !important;
  border-right-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-x-gray-300\/30 {
  border-left-color: rgb(209 213 219 / 0.3) !important;
  border-right-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-x-gray-300\/35 {
  border-left-color: rgb(209 213 219 / 0.35) !important;
  border-right-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-x-gray-300\/40 {
  border-left-color: rgb(209 213 219 / 0.4) !important;
  border-right-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-x-gray-300\/45 {
  border-left-color: rgb(209 213 219 / 0.45) !important;
  border-right-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-x-gray-300\/5 {
  border-left-color: rgb(209 213 219 / 0.05) !important;
  border-right-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-x-gray-300\/50 {
  border-left-color: rgb(209 213 219 / 0.5) !important;
  border-right-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-x-gray-300\/55 {
  border-left-color: rgb(209 213 219 / 0.55) !important;
  border-right-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-x-gray-300\/60 {
  border-left-color: rgb(209 213 219 / 0.6) !important;
  border-right-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-x-gray-300\/65 {
  border-left-color: rgb(209 213 219 / 0.65) !important;
  border-right-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-x-gray-300\/70 {
  border-left-color: rgb(209 213 219 / 0.7) !important;
  border-right-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-x-gray-300\/75 {
  border-left-color: rgb(209 213 219 / 0.75) !important;
  border-right-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-x-gray-300\/80 {
  border-left-color: rgb(209 213 219 / 0.8) !important;
  border-right-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-x-gray-300\/85 {
  border-left-color: rgb(209 213 219 / 0.85) !important;
  border-right-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-x-gray-300\/90 {
  border-left-color: rgb(209 213 219 / 0.9) !important;
  border-right-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-x-gray-300\/95 {
  border-left-color: rgb(209 213 219 / 0.95) !important;
  border-right-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-x-gray-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-400\/0 {
  border-left-color: rgb(156 163 175 / 0) !important;
  border-right-color: rgb(156 163 175 / 0) !important;
}

.tw-border-x-gray-400\/10 {
  border-left-color: rgb(156 163 175 / 0.1) !important;
  border-right-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-x-gray-400\/100 {
  border-left-color: rgb(156 163 175 / 1) !important;
  border-right-color: rgb(156 163 175 / 1) !important;
}

.tw-border-x-gray-400\/15 {
  border-left-color: rgb(156 163 175 / 0.15) !important;
  border-right-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-x-gray-400\/20 {
  border-left-color: rgb(156 163 175 / 0.2) !important;
  border-right-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-x-gray-400\/25 {
  border-left-color: rgb(156 163 175 / 0.25) !important;
  border-right-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-x-gray-400\/30 {
  border-left-color: rgb(156 163 175 / 0.3) !important;
  border-right-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-x-gray-400\/35 {
  border-left-color: rgb(156 163 175 / 0.35) !important;
  border-right-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-x-gray-400\/40 {
  border-left-color: rgb(156 163 175 / 0.4) !important;
  border-right-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-x-gray-400\/45 {
  border-left-color: rgb(156 163 175 / 0.45) !important;
  border-right-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-x-gray-400\/5 {
  border-left-color: rgb(156 163 175 / 0.05) !important;
  border-right-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-x-gray-400\/50 {
  border-left-color: rgb(156 163 175 / 0.5) !important;
  border-right-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-x-gray-400\/55 {
  border-left-color: rgb(156 163 175 / 0.55) !important;
  border-right-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-x-gray-400\/60 {
  border-left-color: rgb(156 163 175 / 0.6) !important;
  border-right-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-x-gray-400\/65 {
  border-left-color: rgb(156 163 175 / 0.65) !important;
  border-right-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-x-gray-400\/70 {
  border-left-color: rgb(156 163 175 / 0.7) !important;
  border-right-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-x-gray-400\/75 {
  border-left-color: rgb(156 163 175 / 0.75) !important;
  border-right-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-x-gray-400\/80 {
  border-left-color: rgb(156 163 175 / 0.8) !important;
  border-right-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-x-gray-400\/85 {
  border-left-color: rgb(156 163 175 / 0.85) !important;
  border-right-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-x-gray-400\/90 {
  border-left-color: rgb(156 163 175 / 0.9) !important;
  border-right-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-x-gray-400\/95 {
  border-left-color: rgb(156 163 175 / 0.95) !important;
  border-right-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-x-gray-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-50\/0 {
  border-left-color: rgb(249 250 251 / 0) !important;
  border-right-color: rgb(249 250 251 / 0) !important;
}

.tw-border-x-gray-50\/10 {
  border-left-color: rgb(249 250 251 / 0.1) !important;
  border-right-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-x-gray-50\/100 {
  border-left-color: rgb(249 250 251 / 1) !important;
  border-right-color: rgb(249 250 251 / 1) !important;
}

.tw-border-x-gray-50\/15 {
  border-left-color: rgb(249 250 251 / 0.15) !important;
  border-right-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-x-gray-50\/20 {
  border-left-color: rgb(249 250 251 / 0.2) !important;
  border-right-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-x-gray-50\/25 {
  border-left-color: rgb(249 250 251 / 0.25) !important;
  border-right-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-x-gray-50\/30 {
  border-left-color: rgb(249 250 251 / 0.3) !important;
  border-right-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-x-gray-50\/35 {
  border-left-color: rgb(249 250 251 / 0.35) !important;
  border-right-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-x-gray-50\/40 {
  border-left-color: rgb(249 250 251 / 0.4) !important;
  border-right-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-x-gray-50\/45 {
  border-left-color: rgb(249 250 251 / 0.45) !important;
  border-right-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-x-gray-50\/5 {
  border-left-color: rgb(249 250 251 / 0.05) !important;
  border-right-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-x-gray-50\/50 {
  border-left-color: rgb(249 250 251 / 0.5) !important;
  border-right-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-x-gray-50\/55 {
  border-left-color: rgb(249 250 251 / 0.55) !important;
  border-right-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-x-gray-50\/60 {
  border-left-color: rgb(249 250 251 / 0.6) !important;
  border-right-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-x-gray-50\/65 {
  border-left-color: rgb(249 250 251 / 0.65) !important;
  border-right-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-x-gray-50\/70 {
  border-left-color: rgb(249 250 251 / 0.7) !important;
  border-right-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-x-gray-50\/75 {
  border-left-color: rgb(249 250 251 / 0.75) !important;
  border-right-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-x-gray-50\/80 {
  border-left-color: rgb(249 250 251 / 0.8) !important;
  border-right-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-x-gray-50\/85 {
  border-left-color: rgb(249 250 251 / 0.85) !important;
  border-right-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-x-gray-50\/90 {
  border-left-color: rgb(249 250 251 / 0.9) !important;
  border-right-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-x-gray-50\/95 {
  border-left-color: rgb(249 250 251 / 0.95) !important;
  border-right-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-x-gray-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-500\/0 {
  border-left-color: rgb(107 114 128 / 0) !important;
  border-right-color: rgb(107 114 128 / 0) !important;
}

.tw-border-x-gray-500\/10 {
  border-left-color: rgb(107 114 128 / 0.1) !important;
  border-right-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-x-gray-500\/100 {
  border-left-color: rgb(107 114 128 / 1) !important;
  border-right-color: rgb(107 114 128 / 1) !important;
}

.tw-border-x-gray-500\/15 {
  border-left-color: rgb(107 114 128 / 0.15) !important;
  border-right-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-x-gray-500\/20 {
  border-left-color: rgb(107 114 128 / 0.2) !important;
  border-right-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-x-gray-500\/25 {
  border-left-color: rgb(107 114 128 / 0.25) !important;
  border-right-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-x-gray-500\/30 {
  border-left-color: rgb(107 114 128 / 0.3) !important;
  border-right-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-x-gray-500\/35 {
  border-left-color: rgb(107 114 128 / 0.35) !important;
  border-right-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-x-gray-500\/40 {
  border-left-color: rgb(107 114 128 / 0.4) !important;
  border-right-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-x-gray-500\/45 {
  border-left-color: rgb(107 114 128 / 0.45) !important;
  border-right-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-x-gray-500\/5 {
  border-left-color: rgb(107 114 128 / 0.05) !important;
  border-right-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-x-gray-500\/50 {
  border-left-color: rgb(107 114 128 / 0.5) !important;
  border-right-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-x-gray-500\/55 {
  border-left-color: rgb(107 114 128 / 0.55) !important;
  border-right-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-x-gray-500\/60 {
  border-left-color: rgb(107 114 128 / 0.6) !important;
  border-right-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-x-gray-500\/65 {
  border-left-color: rgb(107 114 128 / 0.65) !important;
  border-right-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-x-gray-500\/70 {
  border-left-color: rgb(107 114 128 / 0.7) !important;
  border-right-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-x-gray-500\/75 {
  border-left-color: rgb(107 114 128 / 0.75) !important;
  border-right-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-x-gray-500\/80 {
  border-left-color: rgb(107 114 128 / 0.8) !important;
  border-right-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-x-gray-500\/85 {
  border-left-color: rgb(107 114 128 / 0.85) !important;
  border-right-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-x-gray-500\/90 {
  border-left-color: rgb(107 114 128 / 0.9) !important;
  border-right-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-x-gray-500\/95 {
  border-left-color: rgb(107 114 128 / 0.95) !important;
  border-right-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-x-gray-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-600\/0 {
  border-left-color: rgb(75 85 99 / 0) !important;
  border-right-color: rgb(75 85 99 / 0) !important;
}

.tw-border-x-gray-600\/10 {
  border-left-color: rgb(75 85 99 / 0.1) !important;
  border-right-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-x-gray-600\/100 {
  border-left-color: rgb(75 85 99 / 1) !important;
  border-right-color: rgb(75 85 99 / 1) !important;
}

.tw-border-x-gray-600\/15 {
  border-left-color: rgb(75 85 99 / 0.15) !important;
  border-right-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-x-gray-600\/20 {
  border-left-color: rgb(75 85 99 / 0.2) !important;
  border-right-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-x-gray-600\/25 {
  border-left-color: rgb(75 85 99 / 0.25) !important;
  border-right-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-x-gray-600\/30 {
  border-left-color: rgb(75 85 99 / 0.3) !important;
  border-right-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-x-gray-600\/35 {
  border-left-color: rgb(75 85 99 / 0.35) !important;
  border-right-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-x-gray-600\/40 {
  border-left-color: rgb(75 85 99 / 0.4) !important;
  border-right-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-x-gray-600\/45 {
  border-left-color: rgb(75 85 99 / 0.45) !important;
  border-right-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-x-gray-600\/5 {
  border-left-color: rgb(75 85 99 / 0.05) !important;
  border-right-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-x-gray-600\/50 {
  border-left-color: rgb(75 85 99 / 0.5) !important;
  border-right-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-x-gray-600\/55 {
  border-left-color: rgb(75 85 99 / 0.55) !important;
  border-right-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-x-gray-600\/60 {
  border-left-color: rgb(75 85 99 / 0.6) !important;
  border-right-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-x-gray-600\/65 {
  border-left-color: rgb(75 85 99 / 0.65) !important;
  border-right-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-x-gray-600\/70 {
  border-left-color: rgb(75 85 99 / 0.7) !important;
  border-right-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-x-gray-600\/75 {
  border-left-color: rgb(75 85 99 / 0.75) !important;
  border-right-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-x-gray-600\/80 {
  border-left-color: rgb(75 85 99 / 0.8) !important;
  border-right-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-x-gray-600\/85 {
  border-left-color: rgb(75 85 99 / 0.85) !important;
  border-right-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-x-gray-600\/90 {
  border-left-color: rgb(75 85 99 / 0.9) !important;
  border-right-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-x-gray-600\/95 {
  border-left-color: rgb(75 85 99 / 0.95) !important;
  border-right-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-x-gray-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-700\/0 {
  border-left-color: rgb(55 65 81 / 0) !important;
  border-right-color: rgb(55 65 81 / 0) !important;
}

.tw-border-x-gray-700\/10 {
  border-left-color: rgb(55 65 81 / 0.1) !important;
  border-right-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-x-gray-700\/100 {
  border-left-color: rgb(55 65 81 / 1) !important;
  border-right-color: rgb(55 65 81 / 1) !important;
}

.tw-border-x-gray-700\/15 {
  border-left-color: rgb(55 65 81 / 0.15) !important;
  border-right-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-x-gray-700\/20 {
  border-left-color: rgb(55 65 81 / 0.2) !important;
  border-right-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-x-gray-700\/25 {
  border-left-color: rgb(55 65 81 / 0.25) !important;
  border-right-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-x-gray-700\/30 {
  border-left-color: rgb(55 65 81 / 0.3) !important;
  border-right-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-x-gray-700\/35 {
  border-left-color: rgb(55 65 81 / 0.35) !important;
  border-right-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-x-gray-700\/40 {
  border-left-color: rgb(55 65 81 / 0.4) !important;
  border-right-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-x-gray-700\/45 {
  border-left-color: rgb(55 65 81 / 0.45) !important;
  border-right-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-x-gray-700\/5 {
  border-left-color: rgb(55 65 81 / 0.05) !important;
  border-right-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-x-gray-700\/50 {
  border-left-color: rgb(55 65 81 / 0.5) !important;
  border-right-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-x-gray-700\/55 {
  border-left-color: rgb(55 65 81 / 0.55) !important;
  border-right-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-x-gray-700\/60 {
  border-left-color: rgb(55 65 81 / 0.6) !important;
  border-right-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-x-gray-700\/65 {
  border-left-color: rgb(55 65 81 / 0.65) !important;
  border-right-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-x-gray-700\/70 {
  border-left-color: rgb(55 65 81 / 0.7) !important;
  border-right-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-x-gray-700\/75 {
  border-left-color: rgb(55 65 81 / 0.75) !important;
  border-right-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-x-gray-700\/80 {
  border-left-color: rgb(55 65 81 / 0.8) !important;
  border-right-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-x-gray-700\/85 {
  border-left-color: rgb(55 65 81 / 0.85) !important;
  border-right-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-x-gray-700\/90 {
  border-left-color: rgb(55 65 81 / 0.9) !important;
  border-right-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-x-gray-700\/95 {
  border-left-color: rgb(55 65 81 / 0.95) !important;
  border-right-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-x-gray-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-800\/0 {
  border-left-color: rgb(31 41 55 / 0) !important;
  border-right-color: rgb(31 41 55 / 0) !important;
}

.tw-border-x-gray-800\/10 {
  border-left-color: rgb(31 41 55 / 0.1) !important;
  border-right-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-x-gray-800\/100 {
  border-left-color: rgb(31 41 55 / 1) !important;
  border-right-color: rgb(31 41 55 / 1) !important;
}

.tw-border-x-gray-800\/15 {
  border-left-color: rgb(31 41 55 / 0.15) !important;
  border-right-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-x-gray-800\/20 {
  border-left-color: rgb(31 41 55 / 0.2) !important;
  border-right-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-x-gray-800\/25 {
  border-left-color: rgb(31 41 55 / 0.25) !important;
  border-right-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-x-gray-800\/30 {
  border-left-color: rgb(31 41 55 / 0.3) !important;
  border-right-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-x-gray-800\/35 {
  border-left-color: rgb(31 41 55 / 0.35) !important;
  border-right-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-x-gray-800\/40 {
  border-left-color: rgb(31 41 55 / 0.4) !important;
  border-right-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-x-gray-800\/45 {
  border-left-color: rgb(31 41 55 / 0.45) !important;
  border-right-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-x-gray-800\/5 {
  border-left-color: rgb(31 41 55 / 0.05) !important;
  border-right-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-x-gray-800\/50 {
  border-left-color: rgb(31 41 55 / 0.5) !important;
  border-right-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-x-gray-800\/55 {
  border-left-color: rgb(31 41 55 / 0.55) !important;
  border-right-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-x-gray-800\/60 {
  border-left-color: rgb(31 41 55 / 0.6) !important;
  border-right-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-x-gray-800\/65 {
  border-left-color: rgb(31 41 55 / 0.65) !important;
  border-right-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-x-gray-800\/70 {
  border-left-color: rgb(31 41 55 / 0.7) !important;
  border-right-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-x-gray-800\/75 {
  border-left-color: rgb(31 41 55 / 0.75) !important;
  border-right-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-x-gray-800\/80 {
  border-left-color: rgb(31 41 55 / 0.8) !important;
  border-right-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-x-gray-800\/85 {
  border-left-color: rgb(31 41 55 / 0.85) !important;
  border-right-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-x-gray-800\/90 {
  border-left-color: rgb(31 41 55 / 0.9) !important;
  border-right-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-x-gray-800\/95 {
  border-left-color: rgb(31 41 55 / 0.95) !important;
  border-right-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-x-gray-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-900\/0 {
  border-left-color: rgb(17 24 39 / 0) !important;
  border-right-color: rgb(17 24 39 / 0) !important;
}

.tw-border-x-gray-900\/10 {
  border-left-color: rgb(17 24 39 / 0.1) !important;
  border-right-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-x-gray-900\/100 {
  border-left-color: rgb(17 24 39 / 1) !important;
  border-right-color: rgb(17 24 39 / 1) !important;
}

.tw-border-x-gray-900\/15 {
  border-left-color: rgb(17 24 39 / 0.15) !important;
  border-right-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-x-gray-900\/20 {
  border-left-color: rgb(17 24 39 / 0.2) !important;
  border-right-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-x-gray-900\/25 {
  border-left-color: rgb(17 24 39 / 0.25) !important;
  border-right-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-x-gray-900\/30 {
  border-left-color: rgb(17 24 39 / 0.3) !important;
  border-right-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-x-gray-900\/35 {
  border-left-color: rgb(17 24 39 / 0.35) !important;
  border-right-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-x-gray-900\/40 {
  border-left-color: rgb(17 24 39 / 0.4) !important;
  border-right-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-x-gray-900\/45 {
  border-left-color: rgb(17 24 39 / 0.45) !important;
  border-right-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-x-gray-900\/5 {
  border-left-color: rgb(17 24 39 / 0.05) !important;
  border-right-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-x-gray-900\/50 {
  border-left-color: rgb(17 24 39 / 0.5) !important;
  border-right-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-x-gray-900\/55 {
  border-left-color: rgb(17 24 39 / 0.55) !important;
  border-right-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-x-gray-900\/60 {
  border-left-color: rgb(17 24 39 / 0.6) !important;
  border-right-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-x-gray-900\/65 {
  border-left-color: rgb(17 24 39 / 0.65) !important;
  border-right-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-x-gray-900\/70 {
  border-left-color: rgb(17 24 39 / 0.7) !important;
  border-right-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-x-gray-900\/75 {
  border-left-color: rgb(17 24 39 / 0.75) !important;
  border-right-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-x-gray-900\/80 {
  border-left-color: rgb(17 24 39 / 0.8) !important;
  border-right-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-x-gray-900\/85 {
  border-left-color: rgb(17 24 39 / 0.85) !important;
  border-right-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-x-gray-900\/90 {
  border-left-color: rgb(17 24 39 / 0.9) !important;
  border-right-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-x-gray-900\/95 {
  border-left-color: rgb(17 24 39 / 0.95) !important;
  border-right-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-x-gray-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-x-gray-950\/0 {
  border-left-color: rgb(3 7 18 / 0) !important;
  border-right-color: rgb(3 7 18 / 0) !important;
}

.tw-border-x-gray-950\/10 {
  border-left-color: rgb(3 7 18 / 0.1) !important;
  border-right-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-x-gray-950\/100 {
  border-left-color: rgb(3 7 18 / 1) !important;
  border-right-color: rgb(3 7 18 / 1) !important;
}

.tw-border-x-gray-950\/15 {
  border-left-color: rgb(3 7 18 / 0.15) !important;
  border-right-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-x-gray-950\/20 {
  border-left-color: rgb(3 7 18 / 0.2) !important;
  border-right-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-x-gray-950\/25 {
  border-left-color: rgb(3 7 18 / 0.25) !important;
  border-right-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-x-gray-950\/30 {
  border-left-color: rgb(3 7 18 / 0.3) !important;
  border-right-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-x-gray-950\/35 {
  border-left-color: rgb(3 7 18 / 0.35) !important;
  border-right-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-x-gray-950\/40 {
  border-left-color: rgb(3 7 18 / 0.4) !important;
  border-right-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-x-gray-950\/45 {
  border-left-color: rgb(3 7 18 / 0.45) !important;
  border-right-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-x-gray-950\/5 {
  border-left-color: rgb(3 7 18 / 0.05) !important;
  border-right-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-x-gray-950\/50 {
  border-left-color: rgb(3 7 18 / 0.5) !important;
  border-right-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-x-gray-950\/55 {
  border-left-color: rgb(3 7 18 / 0.55) !important;
  border-right-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-x-gray-950\/60 {
  border-left-color: rgb(3 7 18 / 0.6) !important;
  border-right-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-x-gray-950\/65 {
  border-left-color: rgb(3 7 18 / 0.65) !important;
  border-right-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-x-gray-950\/70 {
  border-left-color: rgb(3 7 18 / 0.7) !important;
  border-right-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-x-gray-950\/75 {
  border-left-color: rgb(3 7 18 / 0.75) !important;
  border-right-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-x-gray-950\/80 {
  border-left-color: rgb(3 7 18 / 0.8) !important;
  border-right-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-x-gray-950\/85 {
  border-left-color: rgb(3 7 18 / 0.85) !important;
  border-right-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-x-gray-950\/90 {
  border-left-color: rgb(3 7 18 / 0.9) !important;
  border-right-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-x-gray-950\/95 {
  border-left-color: rgb(3 7 18 / 0.95) !important;
  border-right-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-x-green-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-100\/0 {
  border-left-color: rgb(220 252 231 / 0) !important;
  border-right-color: rgb(220 252 231 / 0) !important;
}

.tw-border-x-green-100\/10 {
  border-left-color: rgb(220 252 231 / 0.1) !important;
  border-right-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-x-green-100\/100 {
  border-left-color: rgb(220 252 231 / 1) !important;
  border-right-color: rgb(220 252 231 / 1) !important;
}

.tw-border-x-green-100\/15 {
  border-left-color: rgb(220 252 231 / 0.15) !important;
  border-right-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-x-green-100\/20 {
  border-left-color: rgb(220 252 231 / 0.2) !important;
  border-right-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-x-green-100\/25 {
  border-left-color: rgb(220 252 231 / 0.25) !important;
  border-right-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-x-green-100\/30 {
  border-left-color: rgb(220 252 231 / 0.3) !important;
  border-right-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-x-green-100\/35 {
  border-left-color: rgb(220 252 231 / 0.35) !important;
  border-right-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-x-green-100\/40 {
  border-left-color: rgb(220 252 231 / 0.4) !important;
  border-right-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-x-green-100\/45 {
  border-left-color: rgb(220 252 231 / 0.45) !important;
  border-right-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-x-green-100\/5 {
  border-left-color: rgb(220 252 231 / 0.05) !important;
  border-right-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-x-green-100\/50 {
  border-left-color: rgb(220 252 231 / 0.5) !important;
  border-right-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-x-green-100\/55 {
  border-left-color: rgb(220 252 231 / 0.55) !important;
  border-right-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-x-green-100\/60 {
  border-left-color: rgb(220 252 231 / 0.6) !important;
  border-right-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-x-green-100\/65 {
  border-left-color: rgb(220 252 231 / 0.65) !important;
  border-right-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-x-green-100\/70 {
  border-left-color: rgb(220 252 231 / 0.7) !important;
  border-right-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-x-green-100\/75 {
  border-left-color: rgb(220 252 231 / 0.75) !important;
  border-right-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-x-green-100\/80 {
  border-left-color: rgb(220 252 231 / 0.8) !important;
  border-right-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-x-green-100\/85 {
  border-left-color: rgb(220 252 231 / 0.85) !important;
  border-right-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-x-green-100\/90 {
  border-left-color: rgb(220 252 231 / 0.9) !important;
  border-right-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-x-green-100\/95 {
  border-left-color: rgb(220 252 231 / 0.95) !important;
  border-right-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-x-green-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-200\/0 {
  border-left-color: rgb(187 247 208 / 0) !important;
  border-right-color: rgb(187 247 208 / 0) !important;
}

.tw-border-x-green-200\/10 {
  border-left-color: rgb(187 247 208 / 0.1) !important;
  border-right-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-x-green-200\/100 {
  border-left-color: rgb(187 247 208 / 1) !important;
  border-right-color: rgb(187 247 208 / 1) !important;
}

.tw-border-x-green-200\/15 {
  border-left-color: rgb(187 247 208 / 0.15) !important;
  border-right-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-x-green-200\/20 {
  border-left-color: rgb(187 247 208 / 0.2) !important;
  border-right-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-x-green-200\/25 {
  border-left-color: rgb(187 247 208 / 0.25) !important;
  border-right-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-x-green-200\/30 {
  border-left-color: rgb(187 247 208 / 0.3) !important;
  border-right-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-x-green-200\/35 {
  border-left-color: rgb(187 247 208 / 0.35) !important;
  border-right-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-x-green-200\/40 {
  border-left-color: rgb(187 247 208 / 0.4) !important;
  border-right-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-x-green-200\/45 {
  border-left-color: rgb(187 247 208 / 0.45) !important;
  border-right-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-x-green-200\/5 {
  border-left-color: rgb(187 247 208 / 0.05) !important;
  border-right-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-x-green-200\/50 {
  border-left-color: rgb(187 247 208 / 0.5) !important;
  border-right-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-x-green-200\/55 {
  border-left-color: rgb(187 247 208 / 0.55) !important;
  border-right-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-x-green-200\/60 {
  border-left-color: rgb(187 247 208 / 0.6) !important;
  border-right-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-x-green-200\/65 {
  border-left-color: rgb(187 247 208 / 0.65) !important;
  border-right-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-x-green-200\/70 {
  border-left-color: rgb(187 247 208 / 0.7) !important;
  border-right-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-x-green-200\/75 {
  border-left-color: rgb(187 247 208 / 0.75) !important;
  border-right-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-x-green-200\/80 {
  border-left-color: rgb(187 247 208 / 0.8) !important;
  border-right-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-x-green-200\/85 {
  border-left-color: rgb(187 247 208 / 0.85) !important;
  border-right-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-x-green-200\/90 {
  border-left-color: rgb(187 247 208 / 0.9) !important;
  border-right-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-x-green-200\/95 {
  border-left-color: rgb(187 247 208 / 0.95) !important;
  border-right-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-x-green-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-300\/0 {
  border-left-color: rgb(134 239 172 / 0) !important;
  border-right-color: rgb(134 239 172 / 0) !important;
}

.tw-border-x-green-300\/10 {
  border-left-color: rgb(134 239 172 / 0.1) !important;
  border-right-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-x-green-300\/100 {
  border-left-color: rgb(134 239 172 / 1) !important;
  border-right-color: rgb(134 239 172 / 1) !important;
}

.tw-border-x-green-300\/15 {
  border-left-color: rgb(134 239 172 / 0.15) !important;
  border-right-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-x-green-300\/20 {
  border-left-color: rgb(134 239 172 / 0.2) !important;
  border-right-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-x-green-300\/25 {
  border-left-color: rgb(134 239 172 / 0.25) !important;
  border-right-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-x-green-300\/30 {
  border-left-color: rgb(134 239 172 / 0.3) !important;
  border-right-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-x-green-300\/35 {
  border-left-color: rgb(134 239 172 / 0.35) !important;
  border-right-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-x-green-300\/40 {
  border-left-color: rgb(134 239 172 / 0.4) !important;
  border-right-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-x-green-300\/45 {
  border-left-color: rgb(134 239 172 / 0.45) !important;
  border-right-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-x-green-300\/5 {
  border-left-color: rgb(134 239 172 / 0.05) !important;
  border-right-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-x-green-300\/50 {
  border-left-color: rgb(134 239 172 / 0.5) !important;
  border-right-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-x-green-300\/55 {
  border-left-color: rgb(134 239 172 / 0.55) !important;
  border-right-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-x-green-300\/60 {
  border-left-color: rgb(134 239 172 / 0.6) !important;
  border-right-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-x-green-300\/65 {
  border-left-color: rgb(134 239 172 / 0.65) !important;
  border-right-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-x-green-300\/70 {
  border-left-color: rgb(134 239 172 / 0.7) !important;
  border-right-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-x-green-300\/75 {
  border-left-color: rgb(134 239 172 / 0.75) !important;
  border-right-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-x-green-300\/80 {
  border-left-color: rgb(134 239 172 / 0.8) !important;
  border-right-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-x-green-300\/85 {
  border-left-color: rgb(134 239 172 / 0.85) !important;
  border-right-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-x-green-300\/90 {
  border-left-color: rgb(134 239 172 / 0.9) !important;
  border-right-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-x-green-300\/95 {
  border-left-color: rgb(134 239 172 / 0.95) !important;
  border-right-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-x-green-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-400\/0 {
  border-left-color: rgb(74 222 128 / 0) !important;
  border-right-color: rgb(74 222 128 / 0) !important;
}

.tw-border-x-green-400\/10 {
  border-left-color: rgb(74 222 128 / 0.1) !important;
  border-right-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-x-green-400\/100 {
  border-left-color: rgb(74 222 128 / 1) !important;
  border-right-color: rgb(74 222 128 / 1) !important;
}

.tw-border-x-green-400\/15 {
  border-left-color: rgb(74 222 128 / 0.15) !important;
  border-right-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-x-green-400\/20 {
  border-left-color: rgb(74 222 128 / 0.2) !important;
  border-right-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-x-green-400\/25 {
  border-left-color: rgb(74 222 128 / 0.25) !important;
  border-right-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-x-green-400\/30 {
  border-left-color: rgb(74 222 128 / 0.3) !important;
  border-right-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-x-green-400\/35 {
  border-left-color: rgb(74 222 128 / 0.35) !important;
  border-right-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-x-green-400\/40 {
  border-left-color: rgb(74 222 128 / 0.4) !important;
  border-right-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-x-green-400\/45 {
  border-left-color: rgb(74 222 128 / 0.45) !important;
  border-right-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-x-green-400\/5 {
  border-left-color: rgb(74 222 128 / 0.05) !important;
  border-right-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-x-green-400\/50 {
  border-left-color: rgb(74 222 128 / 0.5) !important;
  border-right-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-x-green-400\/55 {
  border-left-color: rgb(74 222 128 / 0.55) !important;
  border-right-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-x-green-400\/60 {
  border-left-color: rgb(74 222 128 / 0.6) !important;
  border-right-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-x-green-400\/65 {
  border-left-color: rgb(74 222 128 / 0.65) !important;
  border-right-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-x-green-400\/70 {
  border-left-color: rgb(74 222 128 / 0.7) !important;
  border-right-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-x-green-400\/75 {
  border-left-color: rgb(74 222 128 / 0.75) !important;
  border-right-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-x-green-400\/80 {
  border-left-color: rgb(74 222 128 / 0.8) !important;
  border-right-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-x-green-400\/85 {
  border-left-color: rgb(74 222 128 / 0.85) !important;
  border-right-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-x-green-400\/90 {
  border-left-color: rgb(74 222 128 / 0.9) !important;
  border-right-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-x-green-400\/95 {
  border-left-color: rgb(74 222 128 / 0.95) !important;
  border-right-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-x-green-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-50\/0 {
  border-left-color: rgb(240 253 244 / 0) !important;
  border-right-color: rgb(240 253 244 / 0) !important;
}

.tw-border-x-green-50\/10 {
  border-left-color: rgb(240 253 244 / 0.1) !important;
  border-right-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-x-green-50\/100 {
  border-left-color: rgb(240 253 244 / 1) !important;
  border-right-color: rgb(240 253 244 / 1) !important;
}

.tw-border-x-green-50\/15 {
  border-left-color: rgb(240 253 244 / 0.15) !important;
  border-right-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-x-green-50\/20 {
  border-left-color: rgb(240 253 244 / 0.2) !important;
  border-right-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-x-green-50\/25 {
  border-left-color: rgb(240 253 244 / 0.25) !important;
  border-right-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-x-green-50\/30 {
  border-left-color: rgb(240 253 244 / 0.3) !important;
  border-right-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-x-green-50\/35 {
  border-left-color: rgb(240 253 244 / 0.35) !important;
  border-right-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-x-green-50\/40 {
  border-left-color: rgb(240 253 244 / 0.4) !important;
  border-right-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-x-green-50\/45 {
  border-left-color: rgb(240 253 244 / 0.45) !important;
  border-right-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-x-green-50\/5 {
  border-left-color: rgb(240 253 244 / 0.05) !important;
  border-right-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-x-green-50\/50 {
  border-left-color: rgb(240 253 244 / 0.5) !important;
  border-right-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-x-green-50\/55 {
  border-left-color: rgb(240 253 244 / 0.55) !important;
  border-right-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-x-green-50\/60 {
  border-left-color: rgb(240 253 244 / 0.6) !important;
  border-right-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-x-green-50\/65 {
  border-left-color: rgb(240 253 244 / 0.65) !important;
  border-right-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-x-green-50\/70 {
  border-left-color: rgb(240 253 244 / 0.7) !important;
  border-right-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-x-green-50\/75 {
  border-left-color: rgb(240 253 244 / 0.75) !important;
  border-right-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-x-green-50\/80 {
  border-left-color: rgb(240 253 244 / 0.8) !important;
  border-right-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-x-green-50\/85 {
  border-left-color: rgb(240 253 244 / 0.85) !important;
  border-right-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-x-green-50\/90 {
  border-left-color: rgb(240 253 244 / 0.9) !important;
  border-right-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-x-green-50\/95 {
  border-left-color: rgb(240 253 244 / 0.95) !important;
  border-right-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-x-green-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-500\/0 {
  border-left-color: rgb(34 197 94 / 0) !important;
  border-right-color: rgb(34 197 94 / 0) !important;
}

.tw-border-x-green-500\/10 {
  border-left-color: rgb(34 197 94 / 0.1) !important;
  border-right-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-x-green-500\/100 {
  border-left-color: rgb(34 197 94 / 1) !important;
  border-right-color: rgb(34 197 94 / 1) !important;
}

.tw-border-x-green-500\/15 {
  border-left-color: rgb(34 197 94 / 0.15) !important;
  border-right-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-x-green-500\/20 {
  border-left-color: rgb(34 197 94 / 0.2) !important;
  border-right-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-x-green-500\/25 {
  border-left-color: rgb(34 197 94 / 0.25) !important;
  border-right-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-x-green-500\/30 {
  border-left-color: rgb(34 197 94 / 0.3) !important;
  border-right-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-x-green-500\/35 {
  border-left-color: rgb(34 197 94 / 0.35) !important;
  border-right-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-x-green-500\/40 {
  border-left-color: rgb(34 197 94 / 0.4) !important;
  border-right-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-x-green-500\/45 {
  border-left-color: rgb(34 197 94 / 0.45) !important;
  border-right-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-x-green-500\/5 {
  border-left-color: rgb(34 197 94 / 0.05) !important;
  border-right-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-x-green-500\/50 {
  border-left-color: rgb(34 197 94 / 0.5) !important;
  border-right-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-x-green-500\/55 {
  border-left-color: rgb(34 197 94 / 0.55) !important;
  border-right-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-x-green-500\/60 {
  border-left-color: rgb(34 197 94 / 0.6) !important;
  border-right-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-x-green-500\/65 {
  border-left-color: rgb(34 197 94 / 0.65) !important;
  border-right-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-x-green-500\/70 {
  border-left-color: rgb(34 197 94 / 0.7) !important;
  border-right-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-x-green-500\/75 {
  border-left-color: rgb(34 197 94 / 0.75) !important;
  border-right-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-x-green-500\/80 {
  border-left-color: rgb(34 197 94 / 0.8) !important;
  border-right-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-x-green-500\/85 {
  border-left-color: rgb(34 197 94 / 0.85) !important;
  border-right-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-x-green-500\/90 {
  border-left-color: rgb(34 197 94 / 0.9) !important;
  border-right-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-x-green-500\/95 {
  border-left-color: rgb(34 197 94 / 0.95) !important;
  border-right-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-x-green-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-600\/0 {
  border-left-color: rgb(22 163 74 / 0) !important;
  border-right-color: rgb(22 163 74 / 0) !important;
}

.tw-border-x-green-600\/10 {
  border-left-color: rgb(22 163 74 / 0.1) !important;
  border-right-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-x-green-600\/100 {
  border-left-color: rgb(22 163 74 / 1) !important;
  border-right-color: rgb(22 163 74 / 1) !important;
}

.tw-border-x-green-600\/15 {
  border-left-color: rgb(22 163 74 / 0.15) !important;
  border-right-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-x-green-600\/20 {
  border-left-color: rgb(22 163 74 / 0.2) !important;
  border-right-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-x-green-600\/25 {
  border-left-color: rgb(22 163 74 / 0.25) !important;
  border-right-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-x-green-600\/30 {
  border-left-color: rgb(22 163 74 / 0.3) !important;
  border-right-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-x-green-600\/35 {
  border-left-color: rgb(22 163 74 / 0.35) !important;
  border-right-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-x-green-600\/40 {
  border-left-color: rgb(22 163 74 / 0.4) !important;
  border-right-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-x-green-600\/45 {
  border-left-color: rgb(22 163 74 / 0.45) !important;
  border-right-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-x-green-600\/5 {
  border-left-color: rgb(22 163 74 / 0.05) !important;
  border-right-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-x-green-600\/50 {
  border-left-color: rgb(22 163 74 / 0.5) !important;
  border-right-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-x-green-600\/55 {
  border-left-color: rgb(22 163 74 / 0.55) !important;
  border-right-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-x-green-600\/60 {
  border-left-color: rgb(22 163 74 / 0.6) !important;
  border-right-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-x-green-600\/65 {
  border-left-color: rgb(22 163 74 / 0.65) !important;
  border-right-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-x-green-600\/70 {
  border-left-color: rgb(22 163 74 / 0.7) !important;
  border-right-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-x-green-600\/75 {
  border-left-color: rgb(22 163 74 / 0.75) !important;
  border-right-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-x-green-600\/80 {
  border-left-color: rgb(22 163 74 / 0.8) !important;
  border-right-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-x-green-600\/85 {
  border-left-color: rgb(22 163 74 / 0.85) !important;
  border-right-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-x-green-600\/90 {
  border-left-color: rgb(22 163 74 / 0.9) !important;
  border-right-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-x-green-600\/95 {
  border-left-color: rgb(22 163 74 / 0.95) !important;
  border-right-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-x-green-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-700\/0 {
  border-left-color: rgb(21 128 61 / 0) !important;
  border-right-color: rgb(21 128 61 / 0) !important;
}

.tw-border-x-green-700\/10 {
  border-left-color: rgb(21 128 61 / 0.1) !important;
  border-right-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-x-green-700\/100 {
  border-left-color: rgb(21 128 61 / 1) !important;
  border-right-color: rgb(21 128 61 / 1) !important;
}

.tw-border-x-green-700\/15 {
  border-left-color: rgb(21 128 61 / 0.15) !important;
  border-right-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-x-green-700\/20 {
  border-left-color: rgb(21 128 61 / 0.2) !important;
  border-right-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-x-green-700\/25 {
  border-left-color: rgb(21 128 61 / 0.25) !important;
  border-right-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-x-green-700\/30 {
  border-left-color: rgb(21 128 61 / 0.3) !important;
  border-right-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-x-green-700\/35 {
  border-left-color: rgb(21 128 61 / 0.35) !important;
  border-right-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-x-green-700\/40 {
  border-left-color: rgb(21 128 61 / 0.4) !important;
  border-right-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-x-green-700\/45 {
  border-left-color: rgb(21 128 61 / 0.45) !important;
  border-right-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-x-green-700\/5 {
  border-left-color: rgb(21 128 61 / 0.05) !important;
  border-right-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-x-green-700\/50 {
  border-left-color: rgb(21 128 61 / 0.5) !important;
  border-right-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-x-green-700\/55 {
  border-left-color: rgb(21 128 61 / 0.55) !important;
  border-right-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-x-green-700\/60 {
  border-left-color: rgb(21 128 61 / 0.6) !important;
  border-right-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-x-green-700\/65 {
  border-left-color: rgb(21 128 61 / 0.65) !important;
  border-right-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-x-green-700\/70 {
  border-left-color: rgb(21 128 61 / 0.7) !important;
  border-right-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-x-green-700\/75 {
  border-left-color: rgb(21 128 61 / 0.75) !important;
  border-right-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-x-green-700\/80 {
  border-left-color: rgb(21 128 61 / 0.8) !important;
  border-right-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-x-green-700\/85 {
  border-left-color: rgb(21 128 61 / 0.85) !important;
  border-right-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-x-green-700\/90 {
  border-left-color: rgb(21 128 61 / 0.9) !important;
  border-right-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-x-green-700\/95 {
  border-left-color: rgb(21 128 61 / 0.95) !important;
  border-right-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-x-green-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-800\/0 {
  border-left-color: rgb(22 101 52 / 0) !important;
  border-right-color: rgb(22 101 52 / 0) !important;
}

.tw-border-x-green-800\/10 {
  border-left-color: rgb(22 101 52 / 0.1) !important;
  border-right-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-x-green-800\/100 {
  border-left-color: rgb(22 101 52 / 1) !important;
  border-right-color: rgb(22 101 52 / 1) !important;
}

.tw-border-x-green-800\/15 {
  border-left-color: rgb(22 101 52 / 0.15) !important;
  border-right-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-x-green-800\/20 {
  border-left-color: rgb(22 101 52 / 0.2) !important;
  border-right-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-x-green-800\/25 {
  border-left-color: rgb(22 101 52 / 0.25) !important;
  border-right-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-x-green-800\/30 {
  border-left-color: rgb(22 101 52 / 0.3) !important;
  border-right-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-x-green-800\/35 {
  border-left-color: rgb(22 101 52 / 0.35) !important;
  border-right-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-x-green-800\/40 {
  border-left-color: rgb(22 101 52 / 0.4) !important;
  border-right-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-x-green-800\/45 {
  border-left-color: rgb(22 101 52 / 0.45) !important;
  border-right-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-x-green-800\/5 {
  border-left-color: rgb(22 101 52 / 0.05) !important;
  border-right-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-x-green-800\/50 {
  border-left-color: rgb(22 101 52 / 0.5) !important;
  border-right-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-x-green-800\/55 {
  border-left-color: rgb(22 101 52 / 0.55) !important;
  border-right-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-x-green-800\/60 {
  border-left-color: rgb(22 101 52 / 0.6) !important;
  border-right-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-x-green-800\/65 {
  border-left-color: rgb(22 101 52 / 0.65) !important;
  border-right-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-x-green-800\/70 {
  border-left-color: rgb(22 101 52 / 0.7) !important;
  border-right-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-x-green-800\/75 {
  border-left-color: rgb(22 101 52 / 0.75) !important;
  border-right-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-x-green-800\/80 {
  border-left-color: rgb(22 101 52 / 0.8) !important;
  border-right-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-x-green-800\/85 {
  border-left-color: rgb(22 101 52 / 0.85) !important;
  border-right-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-x-green-800\/90 {
  border-left-color: rgb(22 101 52 / 0.9) !important;
  border-right-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-x-green-800\/95 {
  border-left-color: rgb(22 101 52 / 0.95) !important;
  border-right-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-x-green-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-900\/0 {
  border-left-color: rgb(20 83 45 / 0) !important;
  border-right-color: rgb(20 83 45 / 0) !important;
}

.tw-border-x-green-900\/10 {
  border-left-color: rgb(20 83 45 / 0.1) !important;
  border-right-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-x-green-900\/100 {
  border-left-color: rgb(20 83 45 / 1) !important;
  border-right-color: rgb(20 83 45 / 1) !important;
}

.tw-border-x-green-900\/15 {
  border-left-color: rgb(20 83 45 / 0.15) !important;
  border-right-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-x-green-900\/20 {
  border-left-color: rgb(20 83 45 / 0.2) !important;
  border-right-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-x-green-900\/25 {
  border-left-color: rgb(20 83 45 / 0.25) !important;
  border-right-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-x-green-900\/30 {
  border-left-color: rgb(20 83 45 / 0.3) !important;
  border-right-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-x-green-900\/35 {
  border-left-color: rgb(20 83 45 / 0.35) !important;
  border-right-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-x-green-900\/40 {
  border-left-color: rgb(20 83 45 / 0.4) !important;
  border-right-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-x-green-900\/45 {
  border-left-color: rgb(20 83 45 / 0.45) !important;
  border-right-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-x-green-900\/5 {
  border-left-color: rgb(20 83 45 / 0.05) !important;
  border-right-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-x-green-900\/50 {
  border-left-color: rgb(20 83 45 / 0.5) !important;
  border-right-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-x-green-900\/55 {
  border-left-color: rgb(20 83 45 / 0.55) !important;
  border-right-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-x-green-900\/60 {
  border-left-color: rgb(20 83 45 / 0.6) !important;
  border-right-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-x-green-900\/65 {
  border-left-color: rgb(20 83 45 / 0.65) !important;
  border-right-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-x-green-900\/70 {
  border-left-color: rgb(20 83 45 / 0.7) !important;
  border-right-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-x-green-900\/75 {
  border-left-color: rgb(20 83 45 / 0.75) !important;
  border-right-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-x-green-900\/80 {
  border-left-color: rgb(20 83 45 / 0.8) !important;
  border-right-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-x-green-900\/85 {
  border-left-color: rgb(20 83 45 / 0.85) !important;
  border-right-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-x-green-900\/90 {
  border-left-color: rgb(20 83 45 / 0.9) !important;
  border-right-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-x-green-900\/95 {
  border-left-color: rgb(20 83 45 / 0.95) !important;
  border-right-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-x-green-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-x-green-950\/0 {
  border-left-color: rgb(5 46 22 / 0) !important;
  border-right-color: rgb(5 46 22 / 0) !important;
}

.tw-border-x-green-950\/10 {
  border-left-color: rgb(5 46 22 / 0.1) !important;
  border-right-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-x-green-950\/100 {
  border-left-color: rgb(5 46 22 / 1) !important;
  border-right-color: rgb(5 46 22 / 1) !important;
}

.tw-border-x-green-950\/15 {
  border-left-color: rgb(5 46 22 / 0.15) !important;
  border-right-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-x-green-950\/20 {
  border-left-color: rgb(5 46 22 / 0.2) !important;
  border-right-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-x-green-950\/25 {
  border-left-color: rgb(5 46 22 / 0.25) !important;
  border-right-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-x-green-950\/30 {
  border-left-color: rgb(5 46 22 / 0.3) !important;
  border-right-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-x-green-950\/35 {
  border-left-color: rgb(5 46 22 / 0.35) !important;
  border-right-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-x-green-950\/40 {
  border-left-color: rgb(5 46 22 / 0.4) !important;
  border-right-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-x-green-950\/45 {
  border-left-color: rgb(5 46 22 / 0.45) !important;
  border-right-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-x-green-950\/5 {
  border-left-color: rgb(5 46 22 / 0.05) !important;
  border-right-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-x-green-950\/50 {
  border-left-color: rgb(5 46 22 / 0.5) !important;
  border-right-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-x-green-950\/55 {
  border-left-color: rgb(5 46 22 / 0.55) !important;
  border-right-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-x-green-950\/60 {
  border-left-color: rgb(5 46 22 / 0.6) !important;
  border-right-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-x-green-950\/65 {
  border-left-color: rgb(5 46 22 / 0.65) !important;
  border-right-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-x-green-950\/70 {
  border-left-color: rgb(5 46 22 / 0.7) !important;
  border-right-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-x-green-950\/75 {
  border-left-color: rgb(5 46 22 / 0.75) !important;
  border-right-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-x-green-950\/80 {
  border-left-color: rgb(5 46 22 / 0.8) !important;
  border-right-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-x-green-950\/85 {
  border-left-color: rgb(5 46 22 / 0.85) !important;
  border-right-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-x-green-950\/90 {
  border-left-color: rgb(5 46 22 / 0.9) !important;
  border-right-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-x-green-950\/95 {
  border-left-color: rgb(5 46 22 / 0.95) !important;
  border-right-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-x-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-100\/0 {
  border-left-color: rgb(224 231 255 / 0) !important;
  border-right-color: rgb(224 231 255 / 0) !important;
}

.tw-border-x-indigo-100\/10 {
  border-left-color: rgb(224 231 255 / 0.1) !important;
  border-right-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-x-indigo-100\/100 {
  border-left-color: rgb(224 231 255 / 1) !important;
  border-right-color: rgb(224 231 255 / 1) !important;
}

.tw-border-x-indigo-100\/15 {
  border-left-color: rgb(224 231 255 / 0.15) !important;
  border-right-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-x-indigo-100\/20 {
  border-left-color: rgb(224 231 255 / 0.2) !important;
  border-right-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-x-indigo-100\/25 {
  border-left-color: rgb(224 231 255 / 0.25) !important;
  border-right-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-x-indigo-100\/30 {
  border-left-color: rgb(224 231 255 / 0.3) !important;
  border-right-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-x-indigo-100\/35 {
  border-left-color: rgb(224 231 255 / 0.35) !important;
  border-right-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-x-indigo-100\/40 {
  border-left-color: rgb(224 231 255 / 0.4) !important;
  border-right-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-x-indigo-100\/45 {
  border-left-color: rgb(224 231 255 / 0.45) !important;
  border-right-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-x-indigo-100\/5 {
  border-left-color: rgb(224 231 255 / 0.05) !important;
  border-right-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-x-indigo-100\/50 {
  border-left-color: rgb(224 231 255 / 0.5) !important;
  border-right-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-x-indigo-100\/55 {
  border-left-color: rgb(224 231 255 / 0.55) !important;
  border-right-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-x-indigo-100\/60 {
  border-left-color: rgb(224 231 255 / 0.6) !important;
  border-right-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-x-indigo-100\/65 {
  border-left-color: rgb(224 231 255 / 0.65) !important;
  border-right-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-x-indigo-100\/70 {
  border-left-color: rgb(224 231 255 / 0.7) !important;
  border-right-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-x-indigo-100\/75 {
  border-left-color: rgb(224 231 255 / 0.75) !important;
  border-right-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-x-indigo-100\/80 {
  border-left-color: rgb(224 231 255 / 0.8) !important;
  border-right-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-x-indigo-100\/85 {
  border-left-color: rgb(224 231 255 / 0.85) !important;
  border-right-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-x-indigo-100\/90 {
  border-left-color: rgb(224 231 255 / 0.9) !important;
  border-right-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-x-indigo-100\/95 {
  border-left-color: rgb(224 231 255 / 0.95) !important;
  border-right-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-x-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-200\/0 {
  border-left-color: rgb(199 210 254 / 0) !important;
  border-right-color: rgb(199 210 254 / 0) !important;
}

.tw-border-x-indigo-200\/10 {
  border-left-color: rgb(199 210 254 / 0.1) !important;
  border-right-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-x-indigo-200\/100 {
  border-left-color: rgb(199 210 254 / 1) !important;
  border-right-color: rgb(199 210 254 / 1) !important;
}

.tw-border-x-indigo-200\/15 {
  border-left-color: rgb(199 210 254 / 0.15) !important;
  border-right-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-x-indigo-200\/20 {
  border-left-color: rgb(199 210 254 / 0.2) !important;
  border-right-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-x-indigo-200\/25 {
  border-left-color: rgb(199 210 254 / 0.25) !important;
  border-right-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-x-indigo-200\/30 {
  border-left-color: rgb(199 210 254 / 0.3) !important;
  border-right-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-x-indigo-200\/35 {
  border-left-color: rgb(199 210 254 / 0.35) !important;
  border-right-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-x-indigo-200\/40 {
  border-left-color: rgb(199 210 254 / 0.4) !important;
  border-right-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-x-indigo-200\/45 {
  border-left-color: rgb(199 210 254 / 0.45) !important;
  border-right-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-x-indigo-200\/5 {
  border-left-color: rgb(199 210 254 / 0.05) !important;
  border-right-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-x-indigo-200\/50 {
  border-left-color: rgb(199 210 254 / 0.5) !important;
  border-right-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-x-indigo-200\/55 {
  border-left-color: rgb(199 210 254 / 0.55) !important;
  border-right-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-x-indigo-200\/60 {
  border-left-color: rgb(199 210 254 / 0.6) !important;
  border-right-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-x-indigo-200\/65 {
  border-left-color: rgb(199 210 254 / 0.65) !important;
  border-right-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-x-indigo-200\/70 {
  border-left-color: rgb(199 210 254 / 0.7) !important;
  border-right-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-x-indigo-200\/75 {
  border-left-color: rgb(199 210 254 / 0.75) !important;
  border-right-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-x-indigo-200\/80 {
  border-left-color: rgb(199 210 254 / 0.8) !important;
  border-right-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-x-indigo-200\/85 {
  border-left-color: rgb(199 210 254 / 0.85) !important;
  border-right-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-x-indigo-200\/90 {
  border-left-color: rgb(199 210 254 / 0.9) !important;
  border-right-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-x-indigo-200\/95 {
  border-left-color: rgb(199 210 254 / 0.95) !important;
  border-right-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-x-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-300\/0 {
  border-left-color: rgb(165 180 252 / 0) !important;
  border-right-color: rgb(165 180 252 / 0) !important;
}

.tw-border-x-indigo-300\/10 {
  border-left-color: rgb(165 180 252 / 0.1) !important;
  border-right-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-x-indigo-300\/100 {
  border-left-color: rgb(165 180 252 / 1) !important;
  border-right-color: rgb(165 180 252 / 1) !important;
}

.tw-border-x-indigo-300\/15 {
  border-left-color: rgb(165 180 252 / 0.15) !important;
  border-right-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-x-indigo-300\/20 {
  border-left-color: rgb(165 180 252 / 0.2) !important;
  border-right-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-x-indigo-300\/25 {
  border-left-color: rgb(165 180 252 / 0.25) !important;
  border-right-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-x-indigo-300\/30 {
  border-left-color: rgb(165 180 252 / 0.3) !important;
  border-right-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-x-indigo-300\/35 {
  border-left-color: rgb(165 180 252 / 0.35) !important;
  border-right-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-x-indigo-300\/40 {
  border-left-color: rgb(165 180 252 / 0.4) !important;
  border-right-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-x-indigo-300\/45 {
  border-left-color: rgb(165 180 252 / 0.45) !important;
  border-right-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-x-indigo-300\/5 {
  border-left-color: rgb(165 180 252 / 0.05) !important;
  border-right-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-x-indigo-300\/50 {
  border-left-color: rgb(165 180 252 / 0.5) !important;
  border-right-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-x-indigo-300\/55 {
  border-left-color: rgb(165 180 252 / 0.55) !important;
  border-right-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-x-indigo-300\/60 {
  border-left-color: rgb(165 180 252 / 0.6) !important;
  border-right-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-x-indigo-300\/65 {
  border-left-color: rgb(165 180 252 / 0.65) !important;
  border-right-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-x-indigo-300\/70 {
  border-left-color: rgb(165 180 252 / 0.7) !important;
  border-right-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-x-indigo-300\/75 {
  border-left-color: rgb(165 180 252 / 0.75) !important;
  border-right-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-x-indigo-300\/80 {
  border-left-color: rgb(165 180 252 / 0.8) !important;
  border-right-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-x-indigo-300\/85 {
  border-left-color: rgb(165 180 252 / 0.85) !important;
  border-right-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-x-indigo-300\/90 {
  border-left-color: rgb(165 180 252 / 0.9) !important;
  border-right-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-x-indigo-300\/95 {
  border-left-color: rgb(165 180 252 / 0.95) !important;
  border-right-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-x-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-400\/0 {
  border-left-color: rgb(129 140 248 / 0) !important;
  border-right-color: rgb(129 140 248 / 0) !important;
}

.tw-border-x-indigo-400\/10 {
  border-left-color: rgb(129 140 248 / 0.1) !important;
  border-right-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-x-indigo-400\/100 {
  border-left-color: rgb(129 140 248 / 1) !important;
  border-right-color: rgb(129 140 248 / 1) !important;
}

.tw-border-x-indigo-400\/15 {
  border-left-color: rgb(129 140 248 / 0.15) !important;
  border-right-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-x-indigo-400\/20 {
  border-left-color: rgb(129 140 248 / 0.2) !important;
  border-right-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-x-indigo-400\/25 {
  border-left-color: rgb(129 140 248 / 0.25) !important;
  border-right-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-x-indigo-400\/30 {
  border-left-color: rgb(129 140 248 / 0.3) !important;
  border-right-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-x-indigo-400\/35 {
  border-left-color: rgb(129 140 248 / 0.35) !important;
  border-right-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-x-indigo-400\/40 {
  border-left-color: rgb(129 140 248 / 0.4) !important;
  border-right-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-x-indigo-400\/45 {
  border-left-color: rgb(129 140 248 / 0.45) !important;
  border-right-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-x-indigo-400\/5 {
  border-left-color: rgb(129 140 248 / 0.05) !important;
  border-right-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-x-indigo-400\/50 {
  border-left-color: rgb(129 140 248 / 0.5) !important;
  border-right-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-x-indigo-400\/55 {
  border-left-color: rgb(129 140 248 / 0.55) !important;
  border-right-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-x-indigo-400\/60 {
  border-left-color: rgb(129 140 248 / 0.6) !important;
  border-right-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-x-indigo-400\/65 {
  border-left-color: rgb(129 140 248 / 0.65) !important;
  border-right-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-x-indigo-400\/70 {
  border-left-color: rgb(129 140 248 / 0.7) !important;
  border-right-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-x-indigo-400\/75 {
  border-left-color: rgb(129 140 248 / 0.75) !important;
  border-right-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-x-indigo-400\/80 {
  border-left-color: rgb(129 140 248 / 0.8) !important;
  border-right-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-x-indigo-400\/85 {
  border-left-color: rgb(129 140 248 / 0.85) !important;
  border-right-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-x-indigo-400\/90 {
  border-left-color: rgb(129 140 248 / 0.9) !important;
  border-right-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-x-indigo-400\/95 {
  border-left-color: rgb(129 140 248 / 0.95) !important;
  border-right-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-x-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-50\/0 {
  border-left-color: rgb(238 242 255 / 0) !important;
  border-right-color: rgb(238 242 255 / 0) !important;
}

.tw-border-x-indigo-50\/10 {
  border-left-color: rgb(238 242 255 / 0.1) !important;
  border-right-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-x-indigo-50\/100 {
  border-left-color: rgb(238 242 255 / 1) !important;
  border-right-color: rgb(238 242 255 / 1) !important;
}

.tw-border-x-indigo-50\/15 {
  border-left-color: rgb(238 242 255 / 0.15) !important;
  border-right-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-x-indigo-50\/20 {
  border-left-color: rgb(238 242 255 / 0.2) !important;
  border-right-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-x-indigo-50\/25 {
  border-left-color: rgb(238 242 255 / 0.25) !important;
  border-right-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-x-indigo-50\/30 {
  border-left-color: rgb(238 242 255 / 0.3) !important;
  border-right-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-x-indigo-50\/35 {
  border-left-color: rgb(238 242 255 / 0.35) !important;
  border-right-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-x-indigo-50\/40 {
  border-left-color: rgb(238 242 255 / 0.4) !important;
  border-right-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-x-indigo-50\/45 {
  border-left-color: rgb(238 242 255 / 0.45) !important;
  border-right-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-x-indigo-50\/5 {
  border-left-color: rgb(238 242 255 / 0.05) !important;
  border-right-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-x-indigo-50\/50 {
  border-left-color: rgb(238 242 255 / 0.5) !important;
  border-right-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-x-indigo-50\/55 {
  border-left-color: rgb(238 242 255 / 0.55) !important;
  border-right-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-x-indigo-50\/60 {
  border-left-color: rgb(238 242 255 / 0.6) !important;
  border-right-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-x-indigo-50\/65 {
  border-left-color: rgb(238 242 255 / 0.65) !important;
  border-right-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-x-indigo-50\/70 {
  border-left-color: rgb(238 242 255 / 0.7) !important;
  border-right-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-x-indigo-50\/75 {
  border-left-color: rgb(238 242 255 / 0.75) !important;
  border-right-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-x-indigo-50\/80 {
  border-left-color: rgb(238 242 255 / 0.8) !important;
  border-right-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-x-indigo-50\/85 {
  border-left-color: rgb(238 242 255 / 0.85) !important;
  border-right-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-x-indigo-50\/90 {
  border-left-color: rgb(238 242 255 / 0.9) !important;
  border-right-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-x-indigo-50\/95 {
  border-left-color: rgb(238 242 255 / 0.95) !important;
  border-right-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-x-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-500\/0 {
  border-left-color: rgb(99 102 241 / 0) !important;
  border-right-color: rgb(99 102 241 / 0) !important;
}

.tw-border-x-indigo-500\/10 {
  border-left-color: rgb(99 102 241 / 0.1) !important;
  border-right-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-x-indigo-500\/100 {
  border-left-color: rgb(99 102 241 / 1) !important;
  border-right-color: rgb(99 102 241 / 1) !important;
}

.tw-border-x-indigo-500\/15 {
  border-left-color: rgb(99 102 241 / 0.15) !important;
  border-right-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-x-indigo-500\/20 {
  border-left-color: rgb(99 102 241 / 0.2) !important;
  border-right-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-x-indigo-500\/25 {
  border-left-color: rgb(99 102 241 / 0.25) !important;
  border-right-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-x-indigo-500\/30 {
  border-left-color: rgb(99 102 241 / 0.3) !important;
  border-right-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-x-indigo-500\/35 {
  border-left-color: rgb(99 102 241 / 0.35) !important;
  border-right-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-x-indigo-500\/40 {
  border-left-color: rgb(99 102 241 / 0.4) !important;
  border-right-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-x-indigo-500\/45 {
  border-left-color: rgb(99 102 241 / 0.45) !important;
  border-right-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-x-indigo-500\/5 {
  border-left-color: rgb(99 102 241 / 0.05) !important;
  border-right-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-x-indigo-500\/50 {
  border-left-color: rgb(99 102 241 / 0.5) !important;
  border-right-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-x-indigo-500\/55 {
  border-left-color: rgb(99 102 241 / 0.55) !important;
  border-right-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-x-indigo-500\/60 {
  border-left-color: rgb(99 102 241 / 0.6) !important;
  border-right-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-x-indigo-500\/65 {
  border-left-color: rgb(99 102 241 / 0.65) !important;
  border-right-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-x-indigo-500\/70 {
  border-left-color: rgb(99 102 241 / 0.7) !important;
  border-right-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-x-indigo-500\/75 {
  border-left-color: rgb(99 102 241 / 0.75) !important;
  border-right-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-x-indigo-500\/80 {
  border-left-color: rgb(99 102 241 / 0.8) !important;
  border-right-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-x-indigo-500\/85 {
  border-left-color: rgb(99 102 241 / 0.85) !important;
  border-right-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-x-indigo-500\/90 {
  border-left-color: rgb(99 102 241 / 0.9) !important;
  border-right-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-x-indigo-500\/95 {
  border-left-color: rgb(99 102 241 / 0.95) !important;
  border-right-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-x-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-600\/0 {
  border-left-color: rgb(79 70 229 / 0) !important;
  border-right-color: rgb(79 70 229 / 0) !important;
}

.tw-border-x-indigo-600\/10 {
  border-left-color: rgb(79 70 229 / 0.1) !important;
  border-right-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-x-indigo-600\/100 {
  border-left-color: rgb(79 70 229 / 1) !important;
  border-right-color: rgb(79 70 229 / 1) !important;
}

.tw-border-x-indigo-600\/15 {
  border-left-color: rgb(79 70 229 / 0.15) !important;
  border-right-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-x-indigo-600\/20 {
  border-left-color: rgb(79 70 229 / 0.2) !important;
  border-right-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-x-indigo-600\/25 {
  border-left-color: rgb(79 70 229 / 0.25) !important;
  border-right-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-x-indigo-600\/30 {
  border-left-color: rgb(79 70 229 / 0.3) !important;
  border-right-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-x-indigo-600\/35 {
  border-left-color: rgb(79 70 229 / 0.35) !important;
  border-right-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-x-indigo-600\/40 {
  border-left-color: rgb(79 70 229 / 0.4) !important;
  border-right-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-x-indigo-600\/45 {
  border-left-color: rgb(79 70 229 / 0.45) !important;
  border-right-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-x-indigo-600\/5 {
  border-left-color: rgb(79 70 229 / 0.05) !important;
  border-right-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-x-indigo-600\/50 {
  border-left-color: rgb(79 70 229 / 0.5) !important;
  border-right-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-x-indigo-600\/55 {
  border-left-color: rgb(79 70 229 / 0.55) !important;
  border-right-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-x-indigo-600\/60 {
  border-left-color: rgb(79 70 229 / 0.6) !important;
  border-right-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-x-indigo-600\/65 {
  border-left-color: rgb(79 70 229 / 0.65) !important;
  border-right-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-x-indigo-600\/70 {
  border-left-color: rgb(79 70 229 / 0.7) !important;
  border-right-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-x-indigo-600\/75 {
  border-left-color: rgb(79 70 229 / 0.75) !important;
  border-right-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-x-indigo-600\/80 {
  border-left-color: rgb(79 70 229 / 0.8) !important;
  border-right-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-x-indigo-600\/85 {
  border-left-color: rgb(79 70 229 / 0.85) !important;
  border-right-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-x-indigo-600\/90 {
  border-left-color: rgb(79 70 229 / 0.9) !important;
  border-right-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-x-indigo-600\/95 {
  border-left-color: rgb(79 70 229 / 0.95) !important;
  border-right-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-x-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-700\/0 {
  border-left-color: rgb(67 56 202 / 0) !important;
  border-right-color: rgb(67 56 202 / 0) !important;
}

.tw-border-x-indigo-700\/10 {
  border-left-color: rgb(67 56 202 / 0.1) !important;
  border-right-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-x-indigo-700\/100 {
  border-left-color: rgb(67 56 202 / 1) !important;
  border-right-color: rgb(67 56 202 / 1) !important;
}

.tw-border-x-indigo-700\/15 {
  border-left-color: rgb(67 56 202 / 0.15) !important;
  border-right-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-x-indigo-700\/20 {
  border-left-color: rgb(67 56 202 / 0.2) !important;
  border-right-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-x-indigo-700\/25 {
  border-left-color: rgb(67 56 202 / 0.25) !important;
  border-right-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-x-indigo-700\/30 {
  border-left-color: rgb(67 56 202 / 0.3) !important;
  border-right-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-x-indigo-700\/35 {
  border-left-color: rgb(67 56 202 / 0.35) !important;
  border-right-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-x-indigo-700\/40 {
  border-left-color: rgb(67 56 202 / 0.4) !important;
  border-right-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-x-indigo-700\/45 {
  border-left-color: rgb(67 56 202 / 0.45) !important;
  border-right-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-x-indigo-700\/5 {
  border-left-color: rgb(67 56 202 / 0.05) !important;
  border-right-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-x-indigo-700\/50 {
  border-left-color: rgb(67 56 202 / 0.5) !important;
  border-right-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-x-indigo-700\/55 {
  border-left-color: rgb(67 56 202 / 0.55) !important;
  border-right-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-x-indigo-700\/60 {
  border-left-color: rgb(67 56 202 / 0.6) !important;
  border-right-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-x-indigo-700\/65 {
  border-left-color: rgb(67 56 202 / 0.65) !important;
  border-right-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-x-indigo-700\/70 {
  border-left-color: rgb(67 56 202 / 0.7) !important;
  border-right-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-x-indigo-700\/75 {
  border-left-color: rgb(67 56 202 / 0.75) !important;
  border-right-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-x-indigo-700\/80 {
  border-left-color: rgb(67 56 202 / 0.8) !important;
  border-right-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-x-indigo-700\/85 {
  border-left-color: rgb(67 56 202 / 0.85) !important;
  border-right-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-x-indigo-700\/90 {
  border-left-color: rgb(67 56 202 / 0.9) !important;
  border-right-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-x-indigo-700\/95 {
  border-left-color: rgb(67 56 202 / 0.95) !important;
  border-right-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-x-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-800\/0 {
  border-left-color: rgb(55 48 163 / 0) !important;
  border-right-color: rgb(55 48 163 / 0) !important;
}

.tw-border-x-indigo-800\/10 {
  border-left-color: rgb(55 48 163 / 0.1) !important;
  border-right-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-x-indigo-800\/100 {
  border-left-color: rgb(55 48 163 / 1) !important;
  border-right-color: rgb(55 48 163 / 1) !important;
}

.tw-border-x-indigo-800\/15 {
  border-left-color: rgb(55 48 163 / 0.15) !important;
  border-right-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-x-indigo-800\/20 {
  border-left-color: rgb(55 48 163 / 0.2) !important;
  border-right-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-x-indigo-800\/25 {
  border-left-color: rgb(55 48 163 / 0.25) !important;
  border-right-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-x-indigo-800\/30 {
  border-left-color: rgb(55 48 163 / 0.3) !important;
  border-right-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-x-indigo-800\/35 {
  border-left-color: rgb(55 48 163 / 0.35) !important;
  border-right-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-x-indigo-800\/40 {
  border-left-color: rgb(55 48 163 / 0.4) !important;
  border-right-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-x-indigo-800\/45 {
  border-left-color: rgb(55 48 163 / 0.45) !important;
  border-right-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-x-indigo-800\/5 {
  border-left-color: rgb(55 48 163 / 0.05) !important;
  border-right-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-x-indigo-800\/50 {
  border-left-color: rgb(55 48 163 / 0.5) !important;
  border-right-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-x-indigo-800\/55 {
  border-left-color: rgb(55 48 163 / 0.55) !important;
  border-right-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-x-indigo-800\/60 {
  border-left-color: rgb(55 48 163 / 0.6) !important;
  border-right-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-x-indigo-800\/65 {
  border-left-color: rgb(55 48 163 / 0.65) !important;
  border-right-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-x-indigo-800\/70 {
  border-left-color: rgb(55 48 163 / 0.7) !important;
  border-right-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-x-indigo-800\/75 {
  border-left-color: rgb(55 48 163 / 0.75) !important;
  border-right-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-x-indigo-800\/80 {
  border-left-color: rgb(55 48 163 / 0.8) !important;
  border-right-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-x-indigo-800\/85 {
  border-left-color: rgb(55 48 163 / 0.85) !important;
  border-right-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-x-indigo-800\/90 {
  border-left-color: rgb(55 48 163 / 0.9) !important;
  border-right-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-x-indigo-800\/95 {
  border-left-color: rgb(55 48 163 / 0.95) !important;
  border-right-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-x-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-900\/0 {
  border-left-color: rgb(49 46 129 / 0) !important;
  border-right-color: rgb(49 46 129 / 0) !important;
}

.tw-border-x-indigo-900\/10 {
  border-left-color: rgb(49 46 129 / 0.1) !important;
  border-right-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-x-indigo-900\/100 {
  border-left-color: rgb(49 46 129 / 1) !important;
  border-right-color: rgb(49 46 129 / 1) !important;
}

.tw-border-x-indigo-900\/15 {
  border-left-color: rgb(49 46 129 / 0.15) !important;
  border-right-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-x-indigo-900\/20 {
  border-left-color: rgb(49 46 129 / 0.2) !important;
  border-right-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-x-indigo-900\/25 {
  border-left-color: rgb(49 46 129 / 0.25) !important;
  border-right-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-x-indigo-900\/30 {
  border-left-color: rgb(49 46 129 / 0.3) !important;
  border-right-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-x-indigo-900\/35 {
  border-left-color: rgb(49 46 129 / 0.35) !important;
  border-right-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-x-indigo-900\/40 {
  border-left-color: rgb(49 46 129 / 0.4) !important;
  border-right-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-x-indigo-900\/45 {
  border-left-color: rgb(49 46 129 / 0.45) !important;
  border-right-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-x-indigo-900\/5 {
  border-left-color: rgb(49 46 129 / 0.05) !important;
  border-right-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-x-indigo-900\/50 {
  border-left-color: rgb(49 46 129 / 0.5) !important;
  border-right-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-x-indigo-900\/55 {
  border-left-color: rgb(49 46 129 / 0.55) !important;
  border-right-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-x-indigo-900\/60 {
  border-left-color: rgb(49 46 129 / 0.6) !important;
  border-right-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-x-indigo-900\/65 {
  border-left-color: rgb(49 46 129 / 0.65) !important;
  border-right-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-x-indigo-900\/70 {
  border-left-color: rgb(49 46 129 / 0.7) !important;
  border-right-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-x-indigo-900\/75 {
  border-left-color: rgb(49 46 129 / 0.75) !important;
  border-right-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-x-indigo-900\/80 {
  border-left-color: rgb(49 46 129 / 0.8) !important;
  border-right-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-x-indigo-900\/85 {
  border-left-color: rgb(49 46 129 / 0.85) !important;
  border-right-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-x-indigo-900\/90 {
  border-left-color: rgb(49 46 129 / 0.9) !important;
  border-right-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-x-indigo-900\/95 {
  border-left-color: rgb(49 46 129 / 0.95) !important;
  border-right-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-x-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-x-indigo-950\/0 {
  border-left-color: rgb(30 27 75 / 0) !important;
  border-right-color: rgb(30 27 75 / 0) !important;
}

.tw-border-x-indigo-950\/10 {
  border-left-color: rgb(30 27 75 / 0.1) !important;
  border-right-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-x-indigo-950\/100 {
  border-left-color: rgb(30 27 75 / 1) !important;
  border-right-color: rgb(30 27 75 / 1) !important;
}

.tw-border-x-indigo-950\/15 {
  border-left-color: rgb(30 27 75 / 0.15) !important;
  border-right-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-x-indigo-950\/20 {
  border-left-color: rgb(30 27 75 / 0.2) !important;
  border-right-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-x-indigo-950\/25 {
  border-left-color: rgb(30 27 75 / 0.25) !important;
  border-right-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-x-indigo-950\/30 {
  border-left-color: rgb(30 27 75 / 0.3) !important;
  border-right-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-x-indigo-950\/35 {
  border-left-color: rgb(30 27 75 / 0.35) !important;
  border-right-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-x-indigo-950\/40 {
  border-left-color: rgb(30 27 75 / 0.4) !important;
  border-right-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-x-indigo-950\/45 {
  border-left-color: rgb(30 27 75 / 0.45) !important;
  border-right-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-x-indigo-950\/5 {
  border-left-color: rgb(30 27 75 / 0.05) !important;
  border-right-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-x-indigo-950\/50 {
  border-left-color: rgb(30 27 75 / 0.5) !important;
  border-right-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-x-indigo-950\/55 {
  border-left-color: rgb(30 27 75 / 0.55) !important;
  border-right-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-x-indigo-950\/60 {
  border-left-color: rgb(30 27 75 / 0.6) !important;
  border-right-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-x-indigo-950\/65 {
  border-left-color: rgb(30 27 75 / 0.65) !important;
  border-right-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-x-indigo-950\/70 {
  border-left-color: rgb(30 27 75 / 0.7) !important;
  border-right-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-x-indigo-950\/75 {
  border-left-color: rgb(30 27 75 / 0.75) !important;
  border-right-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-x-indigo-950\/80 {
  border-left-color: rgb(30 27 75 / 0.8) !important;
  border-right-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-x-indigo-950\/85 {
  border-left-color: rgb(30 27 75 / 0.85) !important;
  border-right-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-x-indigo-950\/90 {
  border-left-color: rgb(30 27 75 / 0.9) !important;
  border-right-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-x-indigo-950\/95 {
  border-left-color: rgb(30 27 75 / 0.95) !important;
  border-right-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-x-inherit {
  border-left-color: inherit !important;
  border-right-color: inherit !important;
}

.tw-border-x-lime-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-100\/0 {
  border-left-color: rgb(236 252 203 / 0) !important;
  border-right-color: rgb(236 252 203 / 0) !important;
}

.tw-border-x-lime-100\/10 {
  border-left-color: rgb(236 252 203 / 0.1) !important;
  border-right-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-x-lime-100\/100 {
  border-left-color: rgb(236 252 203 / 1) !important;
  border-right-color: rgb(236 252 203 / 1) !important;
}

.tw-border-x-lime-100\/15 {
  border-left-color: rgb(236 252 203 / 0.15) !important;
  border-right-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-x-lime-100\/20 {
  border-left-color: rgb(236 252 203 / 0.2) !important;
  border-right-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-x-lime-100\/25 {
  border-left-color: rgb(236 252 203 / 0.25) !important;
  border-right-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-x-lime-100\/30 {
  border-left-color: rgb(236 252 203 / 0.3) !important;
  border-right-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-x-lime-100\/35 {
  border-left-color: rgb(236 252 203 / 0.35) !important;
  border-right-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-x-lime-100\/40 {
  border-left-color: rgb(236 252 203 / 0.4) !important;
  border-right-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-x-lime-100\/45 {
  border-left-color: rgb(236 252 203 / 0.45) !important;
  border-right-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-x-lime-100\/5 {
  border-left-color: rgb(236 252 203 / 0.05) !important;
  border-right-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-x-lime-100\/50 {
  border-left-color: rgb(236 252 203 / 0.5) !important;
  border-right-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-x-lime-100\/55 {
  border-left-color: rgb(236 252 203 / 0.55) !important;
  border-right-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-x-lime-100\/60 {
  border-left-color: rgb(236 252 203 / 0.6) !important;
  border-right-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-x-lime-100\/65 {
  border-left-color: rgb(236 252 203 / 0.65) !important;
  border-right-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-x-lime-100\/70 {
  border-left-color: rgb(236 252 203 / 0.7) !important;
  border-right-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-x-lime-100\/75 {
  border-left-color: rgb(236 252 203 / 0.75) !important;
  border-right-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-x-lime-100\/80 {
  border-left-color: rgb(236 252 203 / 0.8) !important;
  border-right-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-x-lime-100\/85 {
  border-left-color: rgb(236 252 203 / 0.85) !important;
  border-right-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-x-lime-100\/90 {
  border-left-color: rgb(236 252 203 / 0.9) !important;
  border-right-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-x-lime-100\/95 {
  border-left-color: rgb(236 252 203 / 0.95) !important;
  border-right-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-x-lime-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-200\/0 {
  border-left-color: rgb(217 249 157 / 0) !important;
  border-right-color: rgb(217 249 157 / 0) !important;
}

.tw-border-x-lime-200\/10 {
  border-left-color: rgb(217 249 157 / 0.1) !important;
  border-right-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-x-lime-200\/100 {
  border-left-color: rgb(217 249 157 / 1) !important;
  border-right-color: rgb(217 249 157 / 1) !important;
}

.tw-border-x-lime-200\/15 {
  border-left-color: rgb(217 249 157 / 0.15) !important;
  border-right-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-x-lime-200\/20 {
  border-left-color: rgb(217 249 157 / 0.2) !important;
  border-right-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-x-lime-200\/25 {
  border-left-color: rgb(217 249 157 / 0.25) !important;
  border-right-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-x-lime-200\/30 {
  border-left-color: rgb(217 249 157 / 0.3) !important;
  border-right-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-x-lime-200\/35 {
  border-left-color: rgb(217 249 157 / 0.35) !important;
  border-right-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-x-lime-200\/40 {
  border-left-color: rgb(217 249 157 / 0.4) !important;
  border-right-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-x-lime-200\/45 {
  border-left-color: rgb(217 249 157 / 0.45) !important;
  border-right-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-x-lime-200\/5 {
  border-left-color: rgb(217 249 157 / 0.05) !important;
  border-right-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-x-lime-200\/50 {
  border-left-color: rgb(217 249 157 / 0.5) !important;
  border-right-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-x-lime-200\/55 {
  border-left-color: rgb(217 249 157 / 0.55) !important;
  border-right-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-x-lime-200\/60 {
  border-left-color: rgb(217 249 157 / 0.6) !important;
  border-right-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-x-lime-200\/65 {
  border-left-color: rgb(217 249 157 / 0.65) !important;
  border-right-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-x-lime-200\/70 {
  border-left-color: rgb(217 249 157 / 0.7) !important;
  border-right-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-x-lime-200\/75 {
  border-left-color: rgb(217 249 157 / 0.75) !important;
  border-right-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-x-lime-200\/80 {
  border-left-color: rgb(217 249 157 / 0.8) !important;
  border-right-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-x-lime-200\/85 {
  border-left-color: rgb(217 249 157 / 0.85) !important;
  border-right-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-x-lime-200\/90 {
  border-left-color: rgb(217 249 157 / 0.9) !important;
  border-right-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-x-lime-200\/95 {
  border-left-color: rgb(217 249 157 / 0.95) !important;
  border-right-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-x-lime-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-300\/0 {
  border-left-color: rgb(190 242 100 / 0) !important;
  border-right-color: rgb(190 242 100 / 0) !important;
}

.tw-border-x-lime-300\/10 {
  border-left-color: rgb(190 242 100 / 0.1) !important;
  border-right-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-x-lime-300\/100 {
  border-left-color: rgb(190 242 100 / 1) !important;
  border-right-color: rgb(190 242 100 / 1) !important;
}

.tw-border-x-lime-300\/15 {
  border-left-color: rgb(190 242 100 / 0.15) !important;
  border-right-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-x-lime-300\/20 {
  border-left-color: rgb(190 242 100 / 0.2) !important;
  border-right-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-x-lime-300\/25 {
  border-left-color: rgb(190 242 100 / 0.25) !important;
  border-right-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-x-lime-300\/30 {
  border-left-color: rgb(190 242 100 / 0.3) !important;
  border-right-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-x-lime-300\/35 {
  border-left-color: rgb(190 242 100 / 0.35) !important;
  border-right-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-x-lime-300\/40 {
  border-left-color: rgb(190 242 100 / 0.4) !important;
  border-right-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-x-lime-300\/45 {
  border-left-color: rgb(190 242 100 / 0.45) !important;
  border-right-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-x-lime-300\/5 {
  border-left-color: rgb(190 242 100 / 0.05) !important;
  border-right-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-x-lime-300\/50 {
  border-left-color: rgb(190 242 100 / 0.5) !important;
  border-right-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-x-lime-300\/55 {
  border-left-color: rgb(190 242 100 / 0.55) !important;
  border-right-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-x-lime-300\/60 {
  border-left-color: rgb(190 242 100 / 0.6) !important;
  border-right-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-x-lime-300\/65 {
  border-left-color: rgb(190 242 100 / 0.65) !important;
  border-right-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-x-lime-300\/70 {
  border-left-color: rgb(190 242 100 / 0.7) !important;
  border-right-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-x-lime-300\/75 {
  border-left-color: rgb(190 242 100 / 0.75) !important;
  border-right-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-x-lime-300\/80 {
  border-left-color: rgb(190 242 100 / 0.8) !important;
  border-right-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-x-lime-300\/85 {
  border-left-color: rgb(190 242 100 / 0.85) !important;
  border-right-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-x-lime-300\/90 {
  border-left-color: rgb(190 242 100 / 0.9) !important;
  border-right-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-x-lime-300\/95 {
  border-left-color: rgb(190 242 100 / 0.95) !important;
  border-right-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-x-lime-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-400\/0 {
  border-left-color: rgb(163 230 53 / 0) !important;
  border-right-color: rgb(163 230 53 / 0) !important;
}

.tw-border-x-lime-400\/10 {
  border-left-color: rgb(163 230 53 / 0.1) !important;
  border-right-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-x-lime-400\/100 {
  border-left-color: rgb(163 230 53 / 1) !important;
  border-right-color: rgb(163 230 53 / 1) !important;
}

.tw-border-x-lime-400\/15 {
  border-left-color: rgb(163 230 53 / 0.15) !important;
  border-right-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-x-lime-400\/20 {
  border-left-color: rgb(163 230 53 / 0.2) !important;
  border-right-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-x-lime-400\/25 {
  border-left-color: rgb(163 230 53 / 0.25) !important;
  border-right-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-x-lime-400\/30 {
  border-left-color: rgb(163 230 53 / 0.3) !important;
  border-right-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-x-lime-400\/35 {
  border-left-color: rgb(163 230 53 / 0.35) !important;
  border-right-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-x-lime-400\/40 {
  border-left-color: rgb(163 230 53 / 0.4) !important;
  border-right-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-x-lime-400\/45 {
  border-left-color: rgb(163 230 53 / 0.45) !important;
  border-right-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-x-lime-400\/5 {
  border-left-color: rgb(163 230 53 / 0.05) !important;
  border-right-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-x-lime-400\/50 {
  border-left-color: rgb(163 230 53 / 0.5) !important;
  border-right-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-x-lime-400\/55 {
  border-left-color: rgb(163 230 53 / 0.55) !important;
  border-right-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-x-lime-400\/60 {
  border-left-color: rgb(163 230 53 / 0.6) !important;
  border-right-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-x-lime-400\/65 {
  border-left-color: rgb(163 230 53 / 0.65) !important;
  border-right-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-x-lime-400\/70 {
  border-left-color: rgb(163 230 53 / 0.7) !important;
  border-right-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-x-lime-400\/75 {
  border-left-color: rgb(163 230 53 / 0.75) !important;
  border-right-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-x-lime-400\/80 {
  border-left-color: rgb(163 230 53 / 0.8) !important;
  border-right-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-x-lime-400\/85 {
  border-left-color: rgb(163 230 53 / 0.85) !important;
  border-right-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-x-lime-400\/90 {
  border-left-color: rgb(163 230 53 / 0.9) !important;
  border-right-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-x-lime-400\/95 {
  border-left-color: rgb(163 230 53 / 0.95) !important;
  border-right-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-x-lime-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-50\/0 {
  border-left-color: rgb(247 254 231 / 0) !important;
  border-right-color: rgb(247 254 231 / 0) !important;
}

.tw-border-x-lime-50\/10 {
  border-left-color: rgb(247 254 231 / 0.1) !important;
  border-right-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-x-lime-50\/100 {
  border-left-color: rgb(247 254 231 / 1) !important;
  border-right-color: rgb(247 254 231 / 1) !important;
}

.tw-border-x-lime-50\/15 {
  border-left-color: rgb(247 254 231 / 0.15) !important;
  border-right-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-x-lime-50\/20 {
  border-left-color: rgb(247 254 231 / 0.2) !important;
  border-right-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-x-lime-50\/25 {
  border-left-color: rgb(247 254 231 / 0.25) !important;
  border-right-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-x-lime-50\/30 {
  border-left-color: rgb(247 254 231 / 0.3) !important;
  border-right-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-x-lime-50\/35 {
  border-left-color: rgb(247 254 231 / 0.35) !important;
  border-right-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-x-lime-50\/40 {
  border-left-color: rgb(247 254 231 / 0.4) !important;
  border-right-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-x-lime-50\/45 {
  border-left-color: rgb(247 254 231 / 0.45) !important;
  border-right-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-x-lime-50\/5 {
  border-left-color: rgb(247 254 231 / 0.05) !important;
  border-right-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-x-lime-50\/50 {
  border-left-color: rgb(247 254 231 / 0.5) !important;
  border-right-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-x-lime-50\/55 {
  border-left-color: rgb(247 254 231 / 0.55) !important;
  border-right-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-x-lime-50\/60 {
  border-left-color: rgb(247 254 231 / 0.6) !important;
  border-right-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-x-lime-50\/65 {
  border-left-color: rgb(247 254 231 / 0.65) !important;
  border-right-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-x-lime-50\/70 {
  border-left-color: rgb(247 254 231 / 0.7) !important;
  border-right-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-x-lime-50\/75 {
  border-left-color: rgb(247 254 231 / 0.75) !important;
  border-right-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-x-lime-50\/80 {
  border-left-color: rgb(247 254 231 / 0.8) !important;
  border-right-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-x-lime-50\/85 {
  border-left-color: rgb(247 254 231 / 0.85) !important;
  border-right-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-x-lime-50\/90 {
  border-left-color: rgb(247 254 231 / 0.9) !important;
  border-right-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-x-lime-50\/95 {
  border-left-color: rgb(247 254 231 / 0.95) !important;
  border-right-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-x-lime-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-500\/0 {
  border-left-color: rgb(132 204 22 / 0) !important;
  border-right-color: rgb(132 204 22 / 0) !important;
}

.tw-border-x-lime-500\/10 {
  border-left-color: rgb(132 204 22 / 0.1) !important;
  border-right-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-x-lime-500\/100 {
  border-left-color: rgb(132 204 22 / 1) !important;
  border-right-color: rgb(132 204 22 / 1) !important;
}

.tw-border-x-lime-500\/15 {
  border-left-color: rgb(132 204 22 / 0.15) !important;
  border-right-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-x-lime-500\/20 {
  border-left-color: rgb(132 204 22 / 0.2) !important;
  border-right-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-x-lime-500\/25 {
  border-left-color: rgb(132 204 22 / 0.25) !important;
  border-right-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-x-lime-500\/30 {
  border-left-color: rgb(132 204 22 / 0.3) !important;
  border-right-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-x-lime-500\/35 {
  border-left-color: rgb(132 204 22 / 0.35) !important;
  border-right-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-x-lime-500\/40 {
  border-left-color: rgb(132 204 22 / 0.4) !important;
  border-right-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-x-lime-500\/45 {
  border-left-color: rgb(132 204 22 / 0.45) !important;
  border-right-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-x-lime-500\/5 {
  border-left-color: rgb(132 204 22 / 0.05) !important;
  border-right-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-x-lime-500\/50 {
  border-left-color: rgb(132 204 22 / 0.5) !important;
  border-right-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-x-lime-500\/55 {
  border-left-color: rgb(132 204 22 / 0.55) !important;
  border-right-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-x-lime-500\/60 {
  border-left-color: rgb(132 204 22 / 0.6) !important;
  border-right-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-x-lime-500\/65 {
  border-left-color: rgb(132 204 22 / 0.65) !important;
  border-right-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-x-lime-500\/70 {
  border-left-color: rgb(132 204 22 / 0.7) !important;
  border-right-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-x-lime-500\/75 {
  border-left-color: rgb(132 204 22 / 0.75) !important;
  border-right-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-x-lime-500\/80 {
  border-left-color: rgb(132 204 22 / 0.8) !important;
  border-right-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-x-lime-500\/85 {
  border-left-color: rgb(132 204 22 / 0.85) !important;
  border-right-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-x-lime-500\/90 {
  border-left-color: rgb(132 204 22 / 0.9) !important;
  border-right-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-x-lime-500\/95 {
  border-left-color: rgb(132 204 22 / 0.95) !important;
  border-right-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-x-lime-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-600\/0 {
  border-left-color: rgb(101 163 13 / 0) !important;
  border-right-color: rgb(101 163 13 / 0) !important;
}

.tw-border-x-lime-600\/10 {
  border-left-color: rgb(101 163 13 / 0.1) !important;
  border-right-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-x-lime-600\/100 {
  border-left-color: rgb(101 163 13 / 1) !important;
  border-right-color: rgb(101 163 13 / 1) !important;
}

.tw-border-x-lime-600\/15 {
  border-left-color: rgb(101 163 13 / 0.15) !important;
  border-right-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-x-lime-600\/20 {
  border-left-color: rgb(101 163 13 / 0.2) !important;
  border-right-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-x-lime-600\/25 {
  border-left-color: rgb(101 163 13 / 0.25) !important;
  border-right-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-x-lime-600\/30 {
  border-left-color: rgb(101 163 13 / 0.3) !important;
  border-right-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-x-lime-600\/35 {
  border-left-color: rgb(101 163 13 / 0.35) !important;
  border-right-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-x-lime-600\/40 {
  border-left-color: rgb(101 163 13 / 0.4) !important;
  border-right-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-x-lime-600\/45 {
  border-left-color: rgb(101 163 13 / 0.45) !important;
  border-right-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-x-lime-600\/5 {
  border-left-color: rgb(101 163 13 / 0.05) !important;
  border-right-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-x-lime-600\/50 {
  border-left-color: rgb(101 163 13 / 0.5) !important;
  border-right-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-x-lime-600\/55 {
  border-left-color: rgb(101 163 13 / 0.55) !important;
  border-right-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-x-lime-600\/60 {
  border-left-color: rgb(101 163 13 / 0.6) !important;
  border-right-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-x-lime-600\/65 {
  border-left-color: rgb(101 163 13 / 0.65) !important;
  border-right-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-x-lime-600\/70 {
  border-left-color: rgb(101 163 13 / 0.7) !important;
  border-right-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-x-lime-600\/75 {
  border-left-color: rgb(101 163 13 / 0.75) !important;
  border-right-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-x-lime-600\/80 {
  border-left-color: rgb(101 163 13 / 0.8) !important;
  border-right-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-x-lime-600\/85 {
  border-left-color: rgb(101 163 13 / 0.85) !important;
  border-right-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-x-lime-600\/90 {
  border-left-color: rgb(101 163 13 / 0.9) !important;
  border-right-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-x-lime-600\/95 {
  border-left-color: rgb(101 163 13 / 0.95) !important;
  border-right-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-x-lime-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-700\/0 {
  border-left-color: rgb(77 124 15 / 0) !important;
  border-right-color: rgb(77 124 15 / 0) !important;
}

.tw-border-x-lime-700\/10 {
  border-left-color: rgb(77 124 15 / 0.1) !important;
  border-right-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-x-lime-700\/100 {
  border-left-color: rgb(77 124 15 / 1) !important;
  border-right-color: rgb(77 124 15 / 1) !important;
}

.tw-border-x-lime-700\/15 {
  border-left-color: rgb(77 124 15 / 0.15) !important;
  border-right-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-x-lime-700\/20 {
  border-left-color: rgb(77 124 15 / 0.2) !important;
  border-right-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-x-lime-700\/25 {
  border-left-color: rgb(77 124 15 / 0.25) !important;
  border-right-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-x-lime-700\/30 {
  border-left-color: rgb(77 124 15 / 0.3) !important;
  border-right-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-x-lime-700\/35 {
  border-left-color: rgb(77 124 15 / 0.35) !important;
  border-right-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-x-lime-700\/40 {
  border-left-color: rgb(77 124 15 / 0.4) !important;
  border-right-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-x-lime-700\/45 {
  border-left-color: rgb(77 124 15 / 0.45) !important;
  border-right-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-x-lime-700\/5 {
  border-left-color: rgb(77 124 15 / 0.05) !important;
  border-right-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-x-lime-700\/50 {
  border-left-color: rgb(77 124 15 / 0.5) !important;
  border-right-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-x-lime-700\/55 {
  border-left-color: rgb(77 124 15 / 0.55) !important;
  border-right-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-x-lime-700\/60 {
  border-left-color: rgb(77 124 15 / 0.6) !important;
  border-right-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-x-lime-700\/65 {
  border-left-color: rgb(77 124 15 / 0.65) !important;
  border-right-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-x-lime-700\/70 {
  border-left-color: rgb(77 124 15 / 0.7) !important;
  border-right-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-x-lime-700\/75 {
  border-left-color: rgb(77 124 15 / 0.75) !important;
  border-right-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-x-lime-700\/80 {
  border-left-color: rgb(77 124 15 / 0.8) !important;
  border-right-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-x-lime-700\/85 {
  border-left-color: rgb(77 124 15 / 0.85) !important;
  border-right-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-x-lime-700\/90 {
  border-left-color: rgb(77 124 15 / 0.9) !important;
  border-right-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-x-lime-700\/95 {
  border-left-color: rgb(77 124 15 / 0.95) !important;
  border-right-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-x-lime-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-800\/0 {
  border-left-color: rgb(63 98 18 / 0) !important;
  border-right-color: rgb(63 98 18 / 0) !important;
}

.tw-border-x-lime-800\/10 {
  border-left-color: rgb(63 98 18 / 0.1) !important;
  border-right-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-x-lime-800\/100 {
  border-left-color: rgb(63 98 18 / 1) !important;
  border-right-color: rgb(63 98 18 / 1) !important;
}

.tw-border-x-lime-800\/15 {
  border-left-color: rgb(63 98 18 / 0.15) !important;
  border-right-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-x-lime-800\/20 {
  border-left-color: rgb(63 98 18 / 0.2) !important;
  border-right-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-x-lime-800\/25 {
  border-left-color: rgb(63 98 18 / 0.25) !important;
  border-right-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-x-lime-800\/30 {
  border-left-color: rgb(63 98 18 / 0.3) !important;
  border-right-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-x-lime-800\/35 {
  border-left-color: rgb(63 98 18 / 0.35) !important;
  border-right-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-x-lime-800\/40 {
  border-left-color: rgb(63 98 18 / 0.4) !important;
  border-right-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-x-lime-800\/45 {
  border-left-color: rgb(63 98 18 / 0.45) !important;
  border-right-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-x-lime-800\/5 {
  border-left-color: rgb(63 98 18 / 0.05) !important;
  border-right-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-x-lime-800\/50 {
  border-left-color: rgb(63 98 18 / 0.5) !important;
  border-right-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-x-lime-800\/55 {
  border-left-color: rgb(63 98 18 / 0.55) !important;
  border-right-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-x-lime-800\/60 {
  border-left-color: rgb(63 98 18 / 0.6) !important;
  border-right-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-x-lime-800\/65 {
  border-left-color: rgb(63 98 18 / 0.65) !important;
  border-right-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-x-lime-800\/70 {
  border-left-color: rgb(63 98 18 / 0.7) !important;
  border-right-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-x-lime-800\/75 {
  border-left-color: rgb(63 98 18 / 0.75) !important;
  border-right-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-x-lime-800\/80 {
  border-left-color: rgb(63 98 18 / 0.8) !important;
  border-right-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-x-lime-800\/85 {
  border-left-color: rgb(63 98 18 / 0.85) !important;
  border-right-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-x-lime-800\/90 {
  border-left-color: rgb(63 98 18 / 0.9) !important;
  border-right-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-x-lime-800\/95 {
  border-left-color: rgb(63 98 18 / 0.95) !important;
  border-right-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-x-lime-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-900\/0 {
  border-left-color: rgb(54 83 20 / 0) !important;
  border-right-color: rgb(54 83 20 / 0) !important;
}

.tw-border-x-lime-900\/10 {
  border-left-color: rgb(54 83 20 / 0.1) !important;
  border-right-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-x-lime-900\/100 {
  border-left-color: rgb(54 83 20 / 1) !important;
  border-right-color: rgb(54 83 20 / 1) !important;
}

.tw-border-x-lime-900\/15 {
  border-left-color: rgb(54 83 20 / 0.15) !important;
  border-right-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-x-lime-900\/20 {
  border-left-color: rgb(54 83 20 / 0.2) !important;
  border-right-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-x-lime-900\/25 {
  border-left-color: rgb(54 83 20 / 0.25) !important;
  border-right-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-x-lime-900\/30 {
  border-left-color: rgb(54 83 20 / 0.3) !important;
  border-right-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-x-lime-900\/35 {
  border-left-color: rgb(54 83 20 / 0.35) !important;
  border-right-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-x-lime-900\/40 {
  border-left-color: rgb(54 83 20 / 0.4) !important;
  border-right-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-x-lime-900\/45 {
  border-left-color: rgb(54 83 20 / 0.45) !important;
  border-right-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-x-lime-900\/5 {
  border-left-color: rgb(54 83 20 / 0.05) !important;
  border-right-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-x-lime-900\/50 {
  border-left-color: rgb(54 83 20 / 0.5) !important;
  border-right-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-x-lime-900\/55 {
  border-left-color: rgb(54 83 20 / 0.55) !important;
  border-right-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-x-lime-900\/60 {
  border-left-color: rgb(54 83 20 / 0.6) !important;
  border-right-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-x-lime-900\/65 {
  border-left-color: rgb(54 83 20 / 0.65) !important;
  border-right-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-x-lime-900\/70 {
  border-left-color: rgb(54 83 20 / 0.7) !important;
  border-right-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-x-lime-900\/75 {
  border-left-color: rgb(54 83 20 / 0.75) !important;
  border-right-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-x-lime-900\/80 {
  border-left-color: rgb(54 83 20 / 0.8) !important;
  border-right-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-x-lime-900\/85 {
  border-left-color: rgb(54 83 20 / 0.85) !important;
  border-right-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-x-lime-900\/90 {
  border-left-color: rgb(54 83 20 / 0.9) !important;
  border-right-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-x-lime-900\/95 {
  border-left-color: rgb(54 83 20 / 0.95) !important;
  border-right-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-x-lime-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-x-lime-950\/0 {
  border-left-color: rgb(26 46 5 / 0) !important;
  border-right-color: rgb(26 46 5 / 0) !important;
}

.tw-border-x-lime-950\/10 {
  border-left-color: rgb(26 46 5 / 0.1) !important;
  border-right-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-x-lime-950\/100 {
  border-left-color: rgb(26 46 5 / 1) !important;
  border-right-color: rgb(26 46 5 / 1) !important;
}

.tw-border-x-lime-950\/15 {
  border-left-color: rgb(26 46 5 / 0.15) !important;
  border-right-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-x-lime-950\/20 {
  border-left-color: rgb(26 46 5 / 0.2) !important;
  border-right-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-x-lime-950\/25 {
  border-left-color: rgb(26 46 5 / 0.25) !important;
  border-right-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-x-lime-950\/30 {
  border-left-color: rgb(26 46 5 / 0.3) !important;
  border-right-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-x-lime-950\/35 {
  border-left-color: rgb(26 46 5 / 0.35) !important;
  border-right-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-x-lime-950\/40 {
  border-left-color: rgb(26 46 5 / 0.4) !important;
  border-right-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-x-lime-950\/45 {
  border-left-color: rgb(26 46 5 / 0.45) !important;
  border-right-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-x-lime-950\/5 {
  border-left-color: rgb(26 46 5 / 0.05) !important;
  border-right-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-x-lime-950\/50 {
  border-left-color: rgb(26 46 5 / 0.5) !important;
  border-right-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-x-lime-950\/55 {
  border-left-color: rgb(26 46 5 / 0.55) !important;
  border-right-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-x-lime-950\/60 {
  border-left-color: rgb(26 46 5 / 0.6) !important;
  border-right-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-x-lime-950\/65 {
  border-left-color: rgb(26 46 5 / 0.65) !important;
  border-right-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-x-lime-950\/70 {
  border-left-color: rgb(26 46 5 / 0.7) !important;
  border-right-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-x-lime-950\/75 {
  border-left-color: rgb(26 46 5 / 0.75) !important;
  border-right-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-x-lime-950\/80 {
  border-left-color: rgb(26 46 5 / 0.8) !important;
  border-right-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-x-lime-950\/85 {
  border-left-color: rgb(26 46 5 / 0.85) !important;
  border-right-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-x-lime-950\/90 {
  border-left-color: rgb(26 46 5 / 0.9) !important;
  border-right-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-x-lime-950\/95 {
  border-left-color: rgb(26 46 5 / 0.95) !important;
  border-right-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-x-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-100\/0 {
  border-left-color: rgb(245 245 245 / 0) !important;
  border-right-color: rgb(245 245 245 / 0) !important;
}

.tw-border-x-neutral-100\/10 {
  border-left-color: rgb(245 245 245 / 0.1) !important;
  border-right-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-x-neutral-100\/100 {
  border-left-color: rgb(245 245 245 / 1) !important;
  border-right-color: rgb(245 245 245 / 1) !important;
}

.tw-border-x-neutral-100\/15 {
  border-left-color: rgb(245 245 245 / 0.15) !important;
  border-right-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-x-neutral-100\/20 {
  border-left-color: rgb(245 245 245 / 0.2) !important;
  border-right-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-x-neutral-100\/25 {
  border-left-color: rgb(245 245 245 / 0.25) !important;
  border-right-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-x-neutral-100\/30 {
  border-left-color: rgb(245 245 245 / 0.3) !important;
  border-right-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-x-neutral-100\/35 {
  border-left-color: rgb(245 245 245 / 0.35) !important;
  border-right-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-x-neutral-100\/40 {
  border-left-color: rgb(245 245 245 / 0.4) !important;
  border-right-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-x-neutral-100\/45 {
  border-left-color: rgb(245 245 245 / 0.45) !important;
  border-right-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-x-neutral-100\/5 {
  border-left-color: rgb(245 245 245 / 0.05) !important;
  border-right-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-x-neutral-100\/50 {
  border-left-color: rgb(245 245 245 / 0.5) !important;
  border-right-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-x-neutral-100\/55 {
  border-left-color: rgb(245 245 245 / 0.55) !important;
  border-right-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-x-neutral-100\/60 {
  border-left-color: rgb(245 245 245 / 0.6) !important;
  border-right-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-x-neutral-100\/65 {
  border-left-color: rgb(245 245 245 / 0.65) !important;
  border-right-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-x-neutral-100\/70 {
  border-left-color: rgb(245 245 245 / 0.7) !important;
  border-right-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-x-neutral-100\/75 {
  border-left-color: rgb(245 245 245 / 0.75) !important;
  border-right-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-x-neutral-100\/80 {
  border-left-color: rgb(245 245 245 / 0.8) !important;
  border-right-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-x-neutral-100\/85 {
  border-left-color: rgb(245 245 245 / 0.85) !important;
  border-right-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-x-neutral-100\/90 {
  border-left-color: rgb(245 245 245 / 0.9) !important;
  border-right-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-x-neutral-100\/95 {
  border-left-color: rgb(245 245 245 / 0.95) !important;
  border-right-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-x-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-200\/0 {
  border-left-color: rgb(229 229 229 / 0) !important;
  border-right-color: rgb(229 229 229 / 0) !important;
}

.tw-border-x-neutral-200\/10 {
  border-left-color: rgb(229 229 229 / 0.1) !important;
  border-right-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-x-neutral-200\/100 {
  border-left-color: rgb(229 229 229 / 1) !important;
  border-right-color: rgb(229 229 229 / 1) !important;
}

.tw-border-x-neutral-200\/15 {
  border-left-color: rgb(229 229 229 / 0.15) !important;
  border-right-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-x-neutral-200\/20 {
  border-left-color: rgb(229 229 229 / 0.2) !important;
  border-right-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-x-neutral-200\/25 {
  border-left-color: rgb(229 229 229 / 0.25) !important;
  border-right-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-x-neutral-200\/30 {
  border-left-color: rgb(229 229 229 / 0.3) !important;
  border-right-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-x-neutral-200\/35 {
  border-left-color: rgb(229 229 229 / 0.35) !important;
  border-right-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-x-neutral-200\/40 {
  border-left-color: rgb(229 229 229 / 0.4) !important;
  border-right-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-x-neutral-200\/45 {
  border-left-color: rgb(229 229 229 / 0.45) !important;
  border-right-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-x-neutral-200\/5 {
  border-left-color: rgb(229 229 229 / 0.05) !important;
  border-right-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-x-neutral-200\/50 {
  border-left-color: rgb(229 229 229 / 0.5) !important;
  border-right-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-x-neutral-200\/55 {
  border-left-color: rgb(229 229 229 / 0.55) !important;
  border-right-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-x-neutral-200\/60 {
  border-left-color: rgb(229 229 229 / 0.6) !important;
  border-right-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-x-neutral-200\/65 {
  border-left-color: rgb(229 229 229 / 0.65) !important;
  border-right-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-x-neutral-200\/70 {
  border-left-color: rgb(229 229 229 / 0.7) !important;
  border-right-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-x-neutral-200\/75 {
  border-left-color: rgb(229 229 229 / 0.75) !important;
  border-right-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-x-neutral-200\/80 {
  border-left-color: rgb(229 229 229 / 0.8) !important;
  border-right-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-x-neutral-200\/85 {
  border-left-color: rgb(229 229 229 / 0.85) !important;
  border-right-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-x-neutral-200\/90 {
  border-left-color: rgb(229 229 229 / 0.9) !important;
  border-right-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-x-neutral-200\/95 {
  border-left-color: rgb(229 229 229 / 0.95) !important;
  border-right-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-x-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-300\/0 {
  border-left-color: rgb(212 212 212 / 0) !important;
  border-right-color: rgb(212 212 212 / 0) !important;
}

.tw-border-x-neutral-300\/10 {
  border-left-color: rgb(212 212 212 / 0.1) !important;
  border-right-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-x-neutral-300\/100 {
  border-left-color: rgb(212 212 212 / 1) !important;
  border-right-color: rgb(212 212 212 / 1) !important;
}

.tw-border-x-neutral-300\/15 {
  border-left-color: rgb(212 212 212 / 0.15) !important;
  border-right-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-x-neutral-300\/20 {
  border-left-color: rgb(212 212 212 / 0.2) !important;
  border-right-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-x-neutral-300\/25 {
  border-left-color: rgb(212 212 212 / 0.25) !important;
  border-right-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-x-neutral-300\/30 {
  border-left-color: rgb(212 212 212 / 0.3) !important;
  border-right-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-x-neutral-300\/35 {
  border-left-color: rgb(212 212 212 / 0.35) !important;
  border-right-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-x-neutral-300\/40 {
  border-left-color: rgb(212 212 212 / 0.4) !important;
  border-right-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-x-neutral-300\/45 {
  border-left-color: rgb(212 212 212 / 0.45) !important;
  border-right-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-x-neutral-300\/5 {
  border-left-color: rgb(212 212 212 / 0.05) !important;
  border-right-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-x-neutral-300\/50 {
  border-left-color: rgb(212 212 212 / 0.5) !important;
  border-right-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-x-neutral-300\/55 {
  border-left-color: rgb(212 212 212 / 0.55) !important;
  border-right-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-x-neutral-300\/60 {
  border-left-color: rgb(212 212 212 / 0.6) !important;
  border-right-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-x-neutral-300\/65 {
  border-left-color: rgb(212 212 212 / 0.65) !important;
  border-right-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-x-neutral-300\/70 {
  border-left-color: rgb(212 212 212 / 0.7) !important;
  border-right-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-x-neutral-300\/75 {
  border-left-color: rgb(212 212 212 / 0.75) !important;
  border-right-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-x-neutral-300\/80 {
  border-left-color: rgb(212 212 212 / 0.8) !important;
  border-right-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-x-neutral-300\/85 {
  border-left-color: rgb(212 212 212 / 0.85) !important;
  border-right-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-x-neutral-300\/90 {
  border-left-color: rgb(212 212 212 / 0.9) !important;
  border-right-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-x-neutral-300\/95 {
  border-left-color: rgb(212 212 212 / 0.95) !important;
  border-right-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-x-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-400\/0 {
  border-left-color: rgb(163 163 163 / 0) !important;
  border-right-color: rgb(163 163 163 / 0) !important;
}

.tw-border-x-neutral-400\/10 {
  border-left-color: rgb(163 163 163 / 0.1) !important;
  border-right-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-x-neutral-400\/100 {
  border-left-color: rgb(163 163 163 / 1) !important;
  border-right-color: rgb(163 163 163 / 1) !important;
}

.tw-border-x-neutral-400\/15 {
  border-left-color: rgb(163 163 163 / 0.15) !important;
  border-right-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-x-neutral-400\/20 {
  border-left-color: rgb(163 163 163 / 0.2) !important;
  border-right-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-x-neutral-400\/25 {
  border-left-color: rgb(163 163 163 / 0.25) !important;
  border-right-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-x-neutral-400\/30 {
  border-left-color: rgb(163 163 163 / 0.3) !important;
  border-right-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-x-neutral-400\/35 {
  border-left-color: rgb(163 163 163 / 0.35) !important;
  border-right-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-x-neutral-400\/40 {
  border-left-color: rgb(163 163 163 / 0.4) !important;
  border-right-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-x-neutral-400\/45 {
  border-left-color: rgb(163 163 163 / 0.45) !important;
  border-right-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-x-neutral-400\/5 {
  border-left-color: rgb(163 163 163 / 0.05) !important;
  border-right-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-x-neutral-400\/50 {
  border-left-color: rgb(163 163 163 / 0.5) !important;
  border-right-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-x-neutral-400\/55 {
  border-left-color: rgb(163 163 163 / 0.55) !important;
  border-right-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-x-neutral-400\/60 {
  border-left-color: rgb(163 163 163 / 0.6) !important;
  border-right-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-x-neutral-400\/65 {
  border-left-color: rgb(163 163 163 / 0.65) !important;
  border-right-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-x-neutral-400\/70 {
  border-left-color: rgb(163 163 163 / 0.7) !important;
  border-right-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-x-neutral-400\/75 {
  border-left-color: rgb(163 163 163 / 0.75) !important;
  border-right-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-x-neutral-400\/80 {
  border-left-color: rgb(163 163 163 / 0.8) !important;
  border-right-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-x-neutral-400\/85 {
  border-left-color: rgb(163 163 163 / 0.85) !important;
  border-right-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-x-neutral-400\/90 {
  border-left-color: rgb(163 163 163 / 0.9) !important;
  border-right-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-x-neutral-400\/95 {
  border-left-color: rgb(163 163 163 / 0.95) !important;
  border-right-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-x-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-50\/0 {
  border-left-color: rgb(250 250 250 / 0) !important;
  border-right-color: rgb(250 250 250 / 0) !important;
}

.tw-border-x-neutral-50\/10 {
  border-left-color: rgb(250 250 250 / 0.1) !important;
  border-right-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-x-neutral-50\/100 {
  border-left-color: rgb(250 250 250 / 1) !important;
  border-right-color: rgb(250 250 250 / 1) !important;
}

.tw-border-x-neutral-50\/15 {
  border-left-color: rgb(250 250 250 / 0.15) !important;
  border-right-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-x-neutral-50\/20 {
  border-left-color: rgb(250 250 250 / 0.2) !important;
  border-right-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-x-neutral-50\/25 {
  border-left-color: rgb(250 250 250 / 0.25) !important;
  border-right-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-x-neutral-50\/30 {
  border-left-color: rgb(250 250 250 / 0.3) !important;
  border-right-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-x-neutral-50\/35 {
  border-left-color: rgb(250 250 250 / 0.35) !important;
  border-right-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-x-neutral-50\/40 {
  border-left-color: rgb(250 250 250 / 0.4) !important;
  border-right-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-x-neutral-50\/45 {
  border-left-color: rgb(250 250 250 / 0.45) !important;
  border-right-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-x-neutral-50\/5 {
  border-left-color: rgb(250 250 250 / 0.05) !important;
  border-right-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-x-neutral-50\/50 {
  border-left-color: rgb(250 250 250 / 0.5) !important;
  border-right-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-x-neutral-50\/55 {
  border-left-color: rgb(250 250 250 / 0.55) !important;
  border-right-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-x-neutral-50\/60 {
  border-left-color: rgb(250 250 250 / 0.6) !important;
  border-right-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-x-neutral-50\/65 {
  border-left-color: rgb(250 250 250 / 0.65) !important;
  border-right-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-x-neutral-50\/70 {
  border-left-color: rgb(250 250 250 / 0.7) !important;
  border-right-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-x-neutral-50\/75 {
  border-left-color: rgb(250 250 250 / 0.75) !important;
  border-right-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-x-neutral-50\/80 {
  border-left-color: rgb(250 250 250 / 0.8) !important;
  border-right-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-x-neutral-50\/85 {
  border-left-color: rgb(250 250 250 / 0.85) !important;
  border-right-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-x-neutral-50\/90 {
  border-left-color: rgb(250 250 250 / 0.9) !important;
  border-right-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-x-neutral-50\/95 {
  border-left-color: rgb(250 250 250 / 0.95) !important;
  border-right-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-x-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-500\/0 {
  border-left-color: rgb(115 115 115 / 0) !important;
  border-right-color: rgb(115 115 115 / 0) !important;
}

.tw-border-x-neutral-500\/10 {
  border-left-color: rgb(115 115 115 / 0.1) !important;
  border-right-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-x-neutral-500\/100 {
  border-left-color: rgb(115 115 115 / 1) !important;
  border-right-color: rgb(115 115 115 / 1) !important;
}

.tw-border-x-neutral-500\/15 {
  border-left-color: rgb(115 115 115 / 0.15) !important;
  border-right-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-x-neutral-500\/20 {
  border-left-color: rgb(115 115 115 / 0.2) !important;
  border-right-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-x-neutral-500\/25 {
  border-left-color: rgb(115 115 115 / 0.25) !important;
  border-right-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-x-neutral-500\/30 {
  border-left-color: rgb(115 115 115 / 0.3) !important;
  border-right-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-x-neutral-500\/35 {
  border-left-color: rgb(115 115 115 / 0.35) !important;
  border-right-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-x-neutral-500\/40 {
  border-left-color: rgb(115 115 115 / 0.4) !important;
  border-right-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-x-neutral-500\/45 {
  border-left-color: rgb(115 115 115 / 0.45) !important;
  border-right-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-x-neutral-500\/5 {
  border-left-color: rgb(115 115 115 / 0.05) !important;
  border-right-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-x-neutral-500\/50 {
  border-left-color: rgb(115 115 115 / 0.5) !important;
  border-right-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-x-neutral-500\/55 {
  border-left-color: rgb(115 115 115 / 0.55) !important;
  border-right-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-x-neutral-500\/60 {
  border-left-color: rgb(115 115 115 / 0.6) !important;
  border-right-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-x-neutral-500\/65 {
  border-left-color: rgb(115 115 115 / 0.65) !important;
  border-right-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-x-neutral-500\/70 {
  border-left-color: rgb(115 115 115 / 0.7) !important;
  border-right-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-x-neutral-500\/75 {
  border-left-color: rgb(115 115 115 / 0.75) !important;
  border-right-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-x-neutral-500\/80 {
  border-left-color: rgb(115 115 115 / 0.8) !important;
  border-right-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-x-neutral-500\/85 {
  border-left-color: rgb(115 115 115 / 0.85) !important;
  border-right-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-x-neutral-500\/90 {
  border-left-color: rgb(115 115 115 / 0.9) !important;
  border-right-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-x-neutral-500\/95 {
  border-left-color: rgb(115 115 115 / 0.95) !important;
  border-right-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-x-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-600\/0 {
  border-left-color: rgb(82 82 82 / 0) !important;
  border-right-color: rgb(82 82 82 / 0) !important;
}

.tw-border-x-neutral-600\/10 {
  border-left-color: rgb(82 82 82 / 0.1) !important;
  border-right-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-x-neutral-600\/100 {
  border-left-color: rgb(82 82 82 / 1) !important;
  border-right-color: rgb(82 82 82 / 1) !important;
}

.tw-border-x-neutral-600\/15 {
  border-left-color: rgb(82 82 82 / 0.15) !important;
  border-right-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-x-neutral-600\/20 {
  border-left-color: rgb(82 82 82 / 0.2) !important;
  border-right-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-x-neutral-600\/25 {
  border-left-color: rgb(82 82 82 / 0.25) !important;
  border-right-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-x-neutral-600\/30 {
  border-left-color: rgb(82 82 82 / 0.3) !important;
  border-right-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-x-neutral-600\/35 {
  border-left-color: rgb(82 82 82 / 0.35) !important;
  border-right-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-x-neutral-600\/40 {
  border-left-color: rgb(82 82 82 / 0.4) !important;
  border-right-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-x-neutral-600\/45 {
  border-left-color: rgb(82 82 82 / 0.45) !important;
  border-right-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-x-neutral-600\/5 {
  border-left-color: rgb(82 82 82 / 0.05) !important;
  border-right-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-x-neutral-600\/50 {
  border-left-color: rgb(82 82 82 / 0.5) !important;
  border-right-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-x-neutral-600\/55 {
  border-left-color: rgb(82 82 82 / 0.55) !important;
  border-right-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-x-neutral-600\/60 {
  border-left-color: rgb(82 82 82 / 0.6) !important;
  border-right-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-x-neutral-600\/65 {
  border-left-color: rgb(82 82 82 / 0.65) !important;
  border-right-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-x-neutral-600\/70 {
  border-left-color: rgb(82 82 82 / 0.7) !important;
  border-right-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-x-neutral-600\/75 {
  border-left-color: rgb(82 82 82 / 0.75) !important;
  border-right-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-x-neutral-600\/80 {
  border-left-color: rgb(82 82 82 / 0.8) !important;
  border-right-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-x-neutral-600\/85 {
  border-left-color: rgb(82 82 82 / 0.85) !important;
  border-right-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-x-neutral-600\/90 {
  border-left-color: rgb(82 82 82 / 0.9) !important;
  border-right-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-x-neutral-600\/95 {
  border-left-color: rgb(82 82 82 / 0.95) !important;
  border-right-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-x-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-700\/0 {
  border-left-color: rgb(64 64 64 / 0) !important;
  border-right-color: rgb(64 64 64 / 0) !important;
}

.tw-border-x-neutral-700\/10 {
  border-left-color: rgb(64 64 64 / 0.1) !important;
  border-right-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-x-neutral-700\/100 {
  border-left-color: rgb(64 64 64 / 1) !important;
  border-right-color: rgb(64 64 64 / 1) !important;
}

.tw-border-x-neutral-700\/15 {
  border-left-color: rgb(64 64 64 / 0.15) !important;
  border-right-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-x-neutral-700\/20 {
  border-left-color: rgb(64 64 64 / 0.2) !important;
  border-right-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-x-neutral-700\/25 {
  border-left-color: rgb(64 64 64 / 0.25) !important;
  border-right-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-x-neutral-700\/30 {
  border-left-color: rgb(64 64 64 / 0.3) !important;
  border-right-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-x-neutral-700\/35 {
  border-left-color: rgb(64 64 64 / 0.35) !important;
  border-right-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-x-neutral-700\/40 {
  border-left-color: rgb(64 64 64 / 0.4) !important;
  border-right-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-x-neutral-700\/45 {
  border-left-color: rgb(64 64 64 / 0.45) !important;
  border-right-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-x-neutral-700\/5 {
  border-left-color: rgb(64 64 64 / 0.05) !important;
  border-right-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-x-neutral-700\/50 {
  border-left-color: rgb(64 64 64 / 0.5) !important;
  border-right-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-x-neutral-700\/55 {
  border-left-color: rgb(64 64 64 / 0.55) !important;
  border-right-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-x-neutral-700\/60 {
  border-left-color: rgb(64 64 64 / 0.6) !important;
  border-right-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-x-neutral-700\/65 {
  border-left-color: rgb(64 64 64 / 0.65) !important;
  border-right-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-x-neutral-700\/70 {
  border-left-color: rgb(64 64 64 / 0.7) !important;
  border-right-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-x-neutral-700\/75 {
  border-left-color: rgb(64 64 64 / 0.75) !important;
  border-right-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-x-neutral-700\/80 {
  border-left-color: rgb(64 64 64 / 0.8) !important;
  border-right-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-x-neutral-700\/85 {
  border-left-color: rgb(64 64 64 / 0.85) !important;
  border-right-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-x-neutral-700\/90 {
  border-left-color: rgb(64 64 64 / 0.9) !important;
  border-right-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-x-neutral-700\/95 {
  border-left-color: rgb(64 64 64 / 0.95) !important;
  border-right-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-x-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-800\/0 {
  border-left-color: rgb(38 38 38 / 0) !important;
  border-right-color: rgb(38 38 38 / 0) !important;
}

.tw-border-x-neutral-800\/10 {
  border-left-color: rgb(38 38 38 / 0.1) !important;
  border-right-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-x-neutral-800\/100 {
  border-left-color: rgb(38 38 38 / 1) !important;
  border-right-color: rgb(38 38 38 / 1) !important;
}

.tw-border-x-neutral-800\/15 {
  border-left-color: rgb(38 38 38 / 0.15) !important;
  border-right-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-x-neutral-800\/20 {
  border-left-color: rgb(38 38 38 / 0.2) !important;
  border-right-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-x-neutral-800\/25 {
  border-left-color: rgb(38 38 38 / 0.25) !important;
  border-right-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-x-neutral-800\/30 {
  border-left-color: rgb(38 38 38 / 0.3) !important;
  border-right-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-x-neutral-800\/35 {
  border-left-color: rgb(38 38 38 / 0.35) !important;
  border-right-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-x-neutral-800\/40 {
  border-left-color: rgb(38 38 38 / 0.4) !important;
  border-right-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-x-neutral-800\/45 {
  border-left-color: rgb(38 38 38 / 0.45) !important;
  border-right-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-x-neutral-800\/5 {
  border-left-color: rgb(38 38 38 / 0.05) !important;
  border-right-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-x-neutral-800\/50 {
  border-left-color: rgb(38 38 38 / 0.5) !important;
  border-right-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-x-neutral-800\/55 {
  border-left-color: rgb(38 38 38 / 0.55) !important;
  border-right-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-x-neutral-800\/60 {
  border-left-color: rgb(38 38 38 / 0.6) !important;
  border-right-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-x-neutral-800\/65 {
  border-left-color: rgb(38 38 38 / 0.65) !important;
  border-right-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-x-neutral-800\/70 {
  border-left-color: rgb(38 38 38 / 0.7) !important;
  border-right-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-x-neutral-800\/75 {
  border-left-color: rgb(38 38 38 / 0.75) !important;
  border-right-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-x-neutral-800\/80 {
  border-left-color: rgb(38 38 38 / 0.8) !important;
  border-right-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-x-neutral-800\/85 {
  border-left-color: rgb(38 38 38 / 0.85) !important;
  border-right-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-x-neutral-800\/90 {
  border-left-color: rgb(38 38 38 / 0.9) !important;
  border-right-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-x-neutral-800\/95 {
  border-left-color: rgb(38 38 38 / 0.95) !important;
  border-right-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-x-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-900\/0 {
  border-left-color: rgb(23 23 23 / 0) !important;
  border-right-color: rgb(23 23 23 / 0) !important;
}

.tw-border-x-neutral-900\/10 {
  border-left-color: rgb(23 23 23 / 0.1) !important;
  border-right-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-x-neutral-900\/100 {
  border-left-color: rgb(23 23 23 / 1) !important;
  border-right-color: rgb(23 23 23 / 1) !important;
}

.tw-border-x-neutral-900\/15 {
  border-left-color: rgb(23 23 23 / 0.15) !important;
  border-right-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-x-neutral-900\/20 {
  border-left-color: rgb(23 23 23 / 0.2) !important;
  border-right-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-x-neutral-900\/25 {
  border-left-color: rgb(23 23 23 / 0.25) !important;
  border-right-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-x-neutral-900\/30 {
  border-left-color: rgb(23 23 23 / 0.3) !important;
  border-right-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-x-neutral-900\/35 {
  border-left-color: rgb(23 23 23 / 0.35) !important;
  border-right-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-x-neutral-900\/40 {
  border-left-color: rgb(23 23 23 / 0.4) !important;
  border-right-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-x-neutral-900\/45 {
  border-left-color: rgb(23 23 23 / 0.45) !important;
  border-right-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-x-neutral-900\/5 {
  border-left-color: rgb(23 23 23 / 0.05) !important;
  border-right-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-x-neutral-900\/50 {
  border-left-color: rgb(23 23 23 / 0.5) !important;
  border-right-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-x-neutral-900\/55 {
  border-left-color: rgb(23 23 23 / 0.55) !important;
  border-right-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-x-neutral-900\/60 {
  border-left-color: rgb(23 23 23 / 0.6) !important;
  border-right-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-x-neutral-900\/65 {
  border-left-color: rgb(23 23 23 / 0.65) !important;
  border-right-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-x-neutral-900\/70 {
  border-left-color: rgb(23 23 23 / 0.7) !important;
  border-right-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-x-neutral-900\/75 {
  border-left-color: rgb(23 23 23 / 0.75) !important;
  border-right-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-x-neutral-900\/80 {
  border-left-color: rgb(23 23 23 / 0.8) !important;
  border-right-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-x-neutral-900\/85 {
  border-left-color: rgb(23 23 23 / 0.85) !important;
  border-right-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-x-neutral-900\/90 {
  border-left-color: rgb(23 23 23 / 0.9) !important;
  border-right-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-x-neutral-900\/95 {
  border-left-color: rgb(23 23 23 / 0.95) !important;
  border-right-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-x-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-x-neutral-950\/0 {
  border-left-color: rgb(10 10 10 / 0) !important;
  border-right-color: rgb(10 10 10 / 0) !important;
}

.tw-border-x-neutral-950\/10 {
  border-left-color: rgb(10 10 10 / 0.1) !important;
  border-right-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-x-neutral-950\/100 {
  border-left-color: rgb(10 10 10 / 1) !important;
  border-right-color: rgb(10 10 10 / 1) !important;
}

.tw-border-x-neutral-950\/15 {
  border-left-color: rgb(10 10 10 / 0.15) !important;
  border-right-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-x-neutral-950\/20 {
  border-left-color: rgb(10 10 10 / 0.2) !important;
  border-right-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-x-neutral-950\/25 {
  border-left-color: rgb(10 10 10 / 0.25) !important;
  border-right-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-x-neutral-950\/30 {
  border-left-color: rgb(10 10 10 / 0.3) !important;
  border-right-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-x-neutral-950\/35 {
  border-left-color: rgb(10 10 10 / 0.35) !important;
  border-right-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-x-neutral-950\/40 {
  border-left-color: rgb(10 10 10 / 0.4) !important;
  border-right-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-x-neutral-950\/45 {
  border-left-color: rgb(10 10 10 / 0.45) !important;
  border-right-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-x-neutral-950\/5 {
  border-left-color: rgb(10 10 10 / 0.05) !important;
  border-right-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-x-neutral-950\/50 {
  border-left-color: rgb(10 10 10 / 0.5) !important;
  border-right-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-x-neutral-950\/55 {
  border-left-color: rgb(10 10 10 / 0.55) !important;
  border-right-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-x-neutral-950\/60 {
  border-left-color: rgb(10 10 10 / 0.6) !important;
  border-right-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-x-neutral-950\/65 {
  border-left-color: rgb(10 10 10 / 0.65) !important;
  border-right-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-x-neutral-950\/70 {
  border-left-color: rgb(10 10 10 / 0.7) !important;
  border-right-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-x-neutral-950\/75 {
  border-left-color: rgb(10 10 10 / 0.75) !important;
  border-right-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-x-neutral-950\/80 {
  border-left-color: rgb(10 10 10 / 0.8) !important;
  border-right-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-x-neutral-950\/85 {
  border-left-color: rgb(10 10 10 / 0.85) !important;
  border-right-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-x-neutral-950\/90 {
  border-left-color: rgb(10 10 10 / 0.9) !important;
  border-right-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-x-neutral-950\/95 {
  border-left-color: rgb(10 10 10 / 0.95) !important;
  border-right-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-x-orange-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-100\/0 {
  border-left-color: rgb(255 237 213 / 0) !important;
  border-right-color: rgb(255 237 213 / 0) !important;
}

.tw-border-x-orange-100\/10 {
  border-left-color: rgb(255 237 213 / 0.1) !important;
  border-right-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-x-orange-100\/100 {
  border-left-color: rgb(255 237 213 / 1) !important;
  border-right-color: rgb(255 237 213 / 1) !important;
}

.tw-border-x-orange-100\/15 {
  border-left-color: rgb(255 237 213 / 0.15) !important;
  border-right-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-x-orange-100\/20 {
  border-left-color: rgb(255 237 213 / 0.2) !important;
  border-right-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-x-orange-100\/25 {
  border-left-color: rgb(255 237 213 / 0.25) !important;
  border-right-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-x-orange-100\/30 {
  border-left-color: rgb(255 237 213 / 0.3) !important;
  border-right-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-x-orange-100\/35 {
  border-left-color: rgb(255 237 213 / 0.35) !important;
  border-right-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-x-orange-100\/40 {
  border-left-color: rgb(255 237 213 / 0.4) !important;
  border-right-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-x-orange-100\/45 {
  border-left-color: rgb(255 237 213 / 0.45) !important;
  border-right-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-x-orange-100\/5 {
  border-left-color: rgb(255 237 213 / 0.05) !important;
  border-right-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-x-orange-100\/50 {
  border-left-color: rgb(255 237 213 / 0.5) !important;
  border-right-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-x-orange-100\/55 {
  border-left-color: rgb(255 237 213 / 0.55) !important;
  border-right-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-x-orange-100\/60 {
  border-left-color: rgb(255 237 213 / 0.6) !important;
  border-right-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-x-orange-100\/65 {
  border-left-color: rgb(255 237 213 / 0.65) !important;
  border-right-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-x-orange-100\/70 {
  border-left-color: rgb(255 237 213 / 0.7) !important;
  border-right-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-x-orange-100\/75 {
  border-left-color: rgb(255 237 213 / 0.75) !important;
  border-right-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-x-orange-100\/80 {
  border-left-color: rgb(255 237 213 / 0.8) !important;
  border-right-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-x-orange-100\/85 {
  border-left-color: rgb(255 237 213 / 0.85) !important;
  border-right-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-x-orange-100\/90 {
  border-left-color: rgb(255 237 213 / 0.9) !important;
  border-right-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-x-orange-100\/95 {
  border-left-color: rgb(255 237 213 / 0.95) !important;
  border-right-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-x-orange-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-200\/0 {
  border-left-color: rgb(254 215 170 / 0) !important;
  border-right-color: rgb(254 215 170 / 0) !important;
}

.tw-border-x-orange-200\/10 {
  border-left-color: rgb(254 215 170 / 0.1) !important;
  border-right-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-x-orange-200\/100 {
  border-left-color: rgb(254 215 170 / 1) !important;
  border-right-color: rgb(254 215 170 / 1) !important;
}

.tw-border-x-orange-200\/15 {
  border-left-color: rgb(254 215 170 / 0.15) !important;
  border-right-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-x-orange-200\/20 {
  border-left-color: rgb(254 215 170 / 0.2) !important;
  border-right-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-x-orange-200\/25 {
  border-left-color: rgb(254 215 170 / 0.25) !important;
  border-right-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-x-orange-200\/30 {
  border-left-color: rgb(254 215 170 / 0.3) !important;
  border-right-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-x-orange-200\/35 {
  border-left-color: rgb(254 215 170 / 0.35) !important;
  border-right-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-x-orange-200\/40 {
  border-left-color: rgb(254 215 170 / 0.4) !important;
  border-right-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-x-orange-200\/45 {
  border-left-color: rgb(254 215 170 / 0.45) !important;
  border-right-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-x-orange-200\/5 {
  border-left-color: rgb(254 215 170 / 0.05) !important;
  border-right-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-x-orange-200\/50 {
  border-left-color: rgb(254 215 170 / 0.5) !important;
  border-right-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-x-orange-200\/55 {
  border-left-color: rgb(254 215 170 / 0.55) !important;
  border-right-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-x-orange-200\/60 {
  border-left-color: rgb(254 215 170 / 0.6) !important;
  border-right-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-x-orange-200\/65 {
  border-left-color: rgb(254 215 170 / 0.65) !important;
  border-right-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-x-orange-200\/70 {
  border-left-color: rgb(254 215 170 / 0.7) !important;
  border-right-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-x-orange-200\/75 {
  border-left-color: rgb(254 215 170 / 0.75) !important;
  border-right-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-x-orange-200\/80 {
  border-left-color: rgb(254 215 170 / 0.8) !important;
  border-right-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-x-orange-200\/85 {
  border-left-color: rgb(254 215 170 / 0.85) !important;
  border-right-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-x-orange-200\/90 {
  border-left-color: rgb(254 215 170 / 0.9) !important;
  border-right-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-x-orange-200\/95 {
  border-left-color: rgb(254 215 170 / 0.95) !important;
  border-right-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-x-orange-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-300\/0 {
  border-left-color: rgb(253 186 116 / 0) !important;
  border-right-color: rgb(253 186 116 / 0) !important;
}

.tw-border-x-orange-300\/10 {
  border-left-color: rgb(253 186 116 / 0.1) !important;
  border-right-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-x-orange-300\/100 {
  border-left-color: rgb(253 186 116 / 1) !important;
  border-right-color: rgb(253 186 116 / 1) !important;
}

.tw-border-x-orange-300\/15 {
  border-left-color: rgb(253 186 116 / 0.15) !important;
  border-right-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-x-orange-300\/20 {
  border-left-color: rgb(253 186 116 / 0.2) !important;
  border-right-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-x-orange-300\/25 {
  border-left-color: rgb(253 186 116 / 0.25) !important;
  border-right-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-x-orange-300\/30 {
  border-left-color: rgb(253 186 116 / 0.3) !important;
  border-right-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-x-orange-300\/35 {
  border-left-color: rgb(253 186 116 / 0.35) !important;
  border-right-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-x-orange-300\/40 {
  border-left-color: rgb(253 186 116 / 0.4) !important;
  border-right-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-x-orange-300\/45 {
  border-left-color: rgb(253 186 116 / 0.45) !important;
  border-right-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-x-orange-300\/5 {
  border-left-color: rgb(253 186 116 / 0.05) !important;
  border-right-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-x-orange-300\/50 {
  border-left-color: rgb(253 186 116 / 0.5) !important;
  border-right-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-x-orange-300\/55 {
  border-left-color: rgb(253 186 116 / 0.55) !important;
  border-right-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-x-orange-300\/60 {
  border-left-color: rgb(253 186 116 / 0.6) !important;
  border-right-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-x-orange-300\/65 {
  border-left-color: rgb(253 186 116 / 0.65) !important;
  border-right-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-x-orange-300\/70 {
  border-left-color: rgb(253 186 116 / 0.7) !important;
  border-right-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-x-orange-300\/75 {
  border-left-color: rgb(253 186 116 / 0.75) !important;
  border-right-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-x-orange-300\/80 {
  border-left-color: rgb(253 186 116 / 0.8) !important;
  border-right-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-x-orange-300\/85 {
  border-left-color: rgb(253 186 116 / 0.85) !important;
  border-right-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-x-orange-300\/90 {
  border-left-color: rgb(253 186 116 / 0.9) !important;
  border-right-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-x-orange-300\/95 {
  border-left-color: rgb(253 186 116 / 0.95) !important;
  border-right-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-x-orange-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-400\/0 {
  border-left-color: rgb(251 146 60 / 0) !important;
  border-right-color: rgb(251 146 60 / 0) !important;
}

.tw-border-x-orange-400\/10 {
  border-left-color: rgb(251 146 60 / 0.1) !important;
  border-right-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-x-orange-400\/100 {
  border-left-color: rgb(251 146 60 / 1) !important;
  border-right-color: rgb(251 146 60 / 1) !important;
}

.tw-border-x-orange-400\/15 {
  border-left-color: rgb(251 146 60 / 0.15) !important;
  border-right-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-x-orange-400\/20 {
  border-left-color: rgb(251 146 60 / 0.2) !important;
  border-right-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-x-orange-400\/25 {
  border-left-color: rgb(251 146 60 / 0.25) !important;
  border-right-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-x-orange-400\/30 {
  border-left-color: rgb(251 146 60 / 0.3) !important;
  border-right-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-x-orange-400\/35 {
  border-left-color: rgb(251 146 60 / 0.35) !important;
  border-right-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-x-orange-400\/40 {
  border-left-color: rgb(251 146 60 / 0.4) !important;
  border-right-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-x-orange-400\/45 {
  border-left-color: rgb(251 146 60 / 0.45) !important;
  border-right-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-x-orange-400\/5 {
  border-left-color: rgb(251 146 60 / 0.05) !important;
  border-right-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-x-orange-400\/50 {
  border-left-color: rgb(251 146 60 / 0.5) !important;
  border-right-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-x-orange-400\/55 {
  border-left-color: rgb(251 146 60 / 0.55) !important;
  border-right-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-x-orange-400\/60 {
  border-left-color: rgb(251 146 60 / 0.6) !important;
  border-right-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-x-orange-400\/65 {
  border-left-color: rgb(251 146 60 / 0.65) !important;
  border-right-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-x-orange-400\/70 {
  border-left-color: rgb(251 146 60 / 0.7) !important;
  border-right-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-x-orange-400\/75 {
  border-left-color: rgb(251 146 60 / 0.75) !important;
  border-right-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-x-orange-400\/80 {
  border-left-color: rgb(251 146 60 / 0.8) !important;
  border-right-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-x-orange-400\/85 {
  border-left-color: rgb(251 146 60 / 0.85) !important;
  border-right-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-x-orange-400\/90 {
  border-left-color: rgb(251 146 60 / 0.9) !important;
  border-right-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-x-orange-400\/95 {
  border-left-color: rgb(251 146 60 / 0.95) !important;
  border-right-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-x-orange-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-50\/0 {
  border-left-color: rgb(255 247 237 / 0) !important;
  border-right-color: rgb(255 247 237 / 0) !important;
}

.tw-border-x-orange-50\/10 {
  border-left-color: rgb(255 247 237 / 0.1) !important;
  border-right-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-x-orange-50\/100 {
  border-left-color: rgb(255 247 237 / 1) !important;
  border-right-color: rgb(255 247 237 / 1) !important;
}

.tw-border-x-orange-50\/15 {
  border-left-color: rgb(255 247 237 / 0.15) !important;
  border-right-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-x-orange-50\/20 {
  border-left-color: rgb(255 247 237 / 0.2) !important;
  border-right-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-x-orange-50\/25 {
  border-left-color: rgb(255 247 237 / 0.25) !important;
  border-right-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-x-orange-50\/30 {
  border-left-color: rgb(255 247 237 / 0.3) !important;
  border-right-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-x-orange-50\/35 {
  border-left-color: rgb(255 247 237 / 0.35) !important;
  border-right-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-x-orange-50\/40 {
  border-left-color: rgb(255 247 237 / 0.4) !important;
  border-right-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-x-orange-50\/45 {
  border-left-color: rgb(255 247 237 / 0.45) !important;
  border-right-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-x-orange-50\/5 {
  border-left-color: rgb(255 247 237 / 0.05) !important;
  border-right-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-x-orange-50\/50 {
  border-left-color: rgb(255 247 237 / 0.5) !important;
  border-right-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-x-orange-50\/55 {
  border-left-color: rgb(255 247 237 / 0.55) !important;
  border-right-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-x-orange-50\/60 {
  border-left-color: rgb(255 247 237 / 0.6) !important;
  border-right-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-x-orange-50\/65 {
  border-left-color: rgb(255 247 237 / 0.65) !important;
  border-right-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-x-orange-50\/70 {
  border-left-color: rgb(255 247 237 / 0.7) !important;
  border-right-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-x-orange-50\/75 {
  border-left-color: rgb(255 247 237 / 0.75) !important;
  border-right-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-x-orange-50\/80 {
  border-left-color: rgb(255 247 237 / 0.8) !important;
  border-right-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-x-orange-50\/85 {
  border-left-color: rgb(255 247 237 / 0.85) !important;
  border-right-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-x-orange-50\/90 {
  border-left-color: rgb(255 247 237 / 0.9) !important;
  border-right-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-x-orange-50\/95 {
  border-left-color: rgb(255 247 237 / 0.95) !important;
  border-right-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-x-orange-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-500\/0 {
  border-left-color: rgb(249 115 22 / 0) !important;
  border-right-color: rgb(249 115 22 / 0) !important;
}

.tw-border-x-orange-500\/10 {
  border-left-color: rgb(249 115 22 / 0.1) !important;
  border-right-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-x-orange-500\/100 {
  border-left-color: rgb(249 115 22 / 1) !important;
  border-right-color: rgb(249 115 22 / 1) !important;
}

.tw-border-x-orange-500\/15 {
  border-left-color: rgb(249 115 22 / 0.15) !important;
  border-right-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-x-orange-500\/20 {
  border-left-color: rgb(249 115 22 / 0.2) !important;
  border-right-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-x-orange-500\/25 {
  border-left-color: rgb(249 115 22 / 0.25) !important;
  border-right-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-x-orange-500\/30 {
  border-left-color: rgb(249 115 22 / 0.3) !important;
  border-right-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-x-orange-500\/35 {
  border-left-color: rgb(249 115 22 / 0.35) !important;
  border-right-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-x-orange-500\/40 {
  border-left-color: rgb(249 115 22 / 0.4) !important;
  border-right-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-x-orange-500\/45 {
  border-left-color: rgb(249 115 22 / 0.45) !important;
  border-right-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-x-orange-500\/5 {
  border-left-color: rgb(249 115 22 / 0.05) !important;
  border-right-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-x-orange-500\/50 {
  border-left-color: rgb(249 115 22 / 0.5) !important;
  border-right-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-x-orange-500\/55 {
  border-left-color: rgb(249 115 22 / 0.55) !important;
  border-right-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-x-orange-500\/60 {
  border-left-color: rgb(249 115 22 / 0.6) !important;
  border-right-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-x-orange-500\/65 {
  border-left-color: rgb(249 115 22 / 0.65) !important;
  border-right-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-x-orange-500\/70 {
  border-left-color: rgb(249 115 22 / 0.7) !important;
  border-right-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-x-orange-500\/75 {
  border-left-color: rgb(249 115 22 / 0.75) !important;
  border-right-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-x-orange-500\/80 {
  border-left-color: rgb(249 115 22 / 0.8) !important;
  border-right-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-x-orange-500\/85 {
  border-left-color: rgb(249 115 22 / 0.85) !important;
  border-right-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-x-orange-500\/90 {
  border-left-color: rgb(249 115 22 / 0.9) !important;
  border-right-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-x-orange-500\/95 {
  border-left-color: rgb(249 115 22 / 0.95) !important;
  border-right-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-x-orange-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-600\/0 {
  border-left-color: rgb(234 88 12 / 0) !important;
  border-right-color: rgb(234 88 12 / 0) !important;
}

.tw-border-x-orange-600\/10 {
  border-left-color: rgb(234 88 12 / 0.1) !important;
  border-right-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-x-orange-600\/100 {
  border-left-color: rgb(234 88 12 / 1) !important;
  border-right-color: rgb(234 88 12 / 1) !important;
}

.tw-border-x-orange-600\/15 {
  border-left-color: rgb(234 88 12 / 0.15) !important;
  border-right-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-x-orange-600\/20 {
  border-left-color: rgb(234 88 12 / 0.2) !important;
  border-right-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-x-orange-600\/25 {
  border-left-color: rgb(234 88 12 / 0.25) !important;
  border-right-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-x-orange-600\/30 {
  border-left-color: rgb(234 88 12 / 0.3) !important;
  border-right-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-x-orange-600\/35 {
  border-left-color: rgb(234 88 12 / 0.35) !important;
  border-right-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-x-orange-600\/40 {
  border-left-color: rgb(234 88 12 / 0.4) !important;
  border-right-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-x-orange-600\/45 {
  border-left-color: rgb(234 88 12 / 0.45) !important;
  border-right-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-x-orange-600\/5 {
  border-left-color: rgb(234 88 12 / 0.05) !important;
  border-right-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-x-orange-600\/50 {
  border-left-color: rgb(234 88 12 / 0.5) !important;
  border-right-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-x-orange-600\/55 {
  border-left-color: rgb(234 88 12 / 0.55) !important;
  border-right-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-x-orange-600\/60 {
  border-left-color: rgb(234 88 12 / 0.6) !important;
  border-right-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-x-orange-600\/65 {
  border-left-color: rgb(234 88 12 / 0.65) !important;
  border-right-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-x-orange-600\/70 {
  border-left-color: rgb(234 88 12 / 0.7) !important;
  border-right-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-x-orange-600\/75 {
  border-left-color: rgb(234 88 12 / 0.75) !important;
  border-right-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-x-orange-600\/80 {
  border-left-color: rgb(234 88 12 / 0.8) !important;
  border-right-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-x-orange-600\/85 {
  border-left-color: rgb(234 88 12 / 0.85) !important;
  border-right-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-x-orange-600\/90 {
  border-left-color: rgb(234 88 12 / 0.9) !important;
  border-right-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-x-orange-600\/95 {
  border-left-color: rgb(234 88 12 / 0.95) !important;
  border-right-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-x-orange-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-700\/0 {
  border-left-color: rgb(194 65 12 / 0) !important;
  border-right-color: rgb(194 65 12 / 0) !important;
}

.tw-border-x-orange-700\/10 {
  border-left-color: rgb(194 65 12 / 0.1) !important;
  border-right-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-x-orange-700\/100 {
  border-left-color: rgb(194 65 12 / 1) !important;
  border-right-color: rgb(194 65 12 / 1) !important;
}

.tw-border-x-orange-700\/15 {
  border-left-color: rgb(194 65 12 / 0.15) !important;
  border-right-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-x-orange-700\/20 {
  border-left-color: rgb(194 65 12 / 0.2) !important;
  border-right-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-x-orange-700\/25 {
  border-left-color: rgb(194 65 12 / 0.25) !important;
  border-right-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-x-orange-700\/30 {
  border-left-color: rgb(194 65 12 / 0.3) !important;
  border-right-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-x-orange-700\/35 {
  border-left-color: rgb(194 65 12 / 0.35) !important;
  border-right-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-x-orange-700\/40 {
  border-left-color: rgb(194 65 12 / 0.4) !important;
  border-right-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-x-orange-700\/45 {
  border-left-color: rgb(194 65 12 / 0.45) !important;
  border-right-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-x-orange-700\/5 {
  border-left-color: rgb(194 65 12 / 0.05) !important;
  border-right-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-x-orange-700\/50 {
  border-left-color: rgb(194 65 12 / 0.5) !important;
  border-right-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-x-orange-700\/55 {
  border-left-color: rgb(194 65 12 / 0.55) !important;
  border-right-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-x-orange-700\/60 {
  border-left-color: rgb(194 65 12 / 0.6) !important;
  border-right-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-x-orange-700\/65 {
  border-left-color: rgb(194 65 12 / 0.65) !important;
  border-right-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-x-orange-700\/70 {
  border-left-color: rgb(194 65 12 / 0.7) !important;
  border-right-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-x-orange-700\/75 {
  border-left-color: rgb(194 65 12 / 0.75) !important;
  border-right-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-x-orange-700\/80 {
  border-left-color: rgb(194 65 12 / 0.8) !important;
  border-right-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-x-orange-700\/85 {
  border-left-color: rgb(194 65 12 / 0.85) !important;
  border-right-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-x-orange-700\/90 {
  border-left-color: rgb(194 65 12 / 0.9) !important;
  border-right-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-x-orange-700\/95 {
  border-left-color: rgb(194 65 12 / 0.95) !important;
  border-right-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-x-orange-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-800\/0 {
  border-left-color: rgb(154 52 18 / 0) !important;
  border-right-color: rgb(154 52 18 / 0) !important;
}

.tw-border-x-orange-800\/10 {
  border-left-color: rgb(154 52 18 / 0.1) !important;
  border-right-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-x-orange-800\/100 {
  border-left-color: rgb(154 52 18 / 1) !important;
  border-right-color: rgb(154 52 18 / 1) !important;
}

.tw-border-x-orange-800\/15 {
  border-left-color: rgb(154 52 18 / 0.15) !important;
  border-right-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-x-orange-800\/20 {
  border-left-color: rgb(154 52 18 / 0.2) !important;
  border-right-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-x-orange-800\/25 {
  border-left-color: rgb(154 52 18 / 0.25) !important;
  border-right-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-x-orange-800\/30 {
  border-left-color: rgb(154 52 18 / 0.3) !important;
  border-right-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-x-orange-800\/35 {
  border-left-color: rgb(154 52 18 / 0.35) !important;
  border-right-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-x-orange-800\/40 {
  border-left-color: rgb(154 52 18 / 0.4) !important;
  border-right-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-x-orange-800\/45 {
  border-left-color: rgb(154 52 18 / 0.45) !important;
  border-right-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-x-orange-800\/5 {
  border-left-color: rgb(154 52 18 / 0.05) !important;
  border-right-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-x-orange-800\/50 {
  border-left-color: rgb(154 52 18 / 0.5) !important;
  border-right-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-x-orange-800\/55 {
  border-left-color: rgb(154 52 18 / 0.55) !important;
  border-right-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-x-orange-800\/60 {
  border-left-color: rgb(154 52 18 / 0.6) !important;
  border-right-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-x-orange-800\/65 {
  border-left-color: rgb(154 52 18 / 0.65) !important;
  border-right-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-x-orange-800\/70 {
  border-left-color: rgb(154 52 18 / 0.7) !important;
  border-right-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-x-orange-800\/75 {
  border-left-color: rgb(154 52 18 / 0.75) !important;
  border-right-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-x-orange-800\/80 {
  border-left-color: rgb(154 52 18 / 0.8) !important;
  border-right-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-x-orange-800\/85 {
  border-left-color: rgb(154 52 18 / 0.85) !important;
  border-right-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-x-orange-800\/90 {
  border-left-color: rgb(154 52 18 / 0.9) !important;
  border-right-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-x-orange-800\/95 {
  border-left-color: rgb(154 52 18 / 0.95) !important;
  border-right-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-x-orange-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-900\/0 {
  border-left-color: rgb(124 45 18 / 0) !important;
  border-right-color: rgb(124 45 18 / 0) !important;
}

.tw-border-x-orange-900\/10 {
  border-left-color: rgb(124 45 18 / 0.1) !important;
  border-right-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-x-orange-900\/100 {
  border-left-color: rgb(124 45 18 / 1) !important;
  border-right-color: rgb(124 45 18 / 1) !important;
}

.tw-border-x-orange-900\/15 {
  border-left-color: rgb(124 45 18 / 0.15) !important;
  border-right-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-x-orange-900\/20 {
  border-left-color: rgb(124 45 18 / 0.2) !important;
  border-right-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-x-orange-900\/25 {
  border-left-color: rgb(124 45 18 / 0.25) !important;
  border-right-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-x-orange-900\/30 {
  border-left-color: rgb(124 45 18 / 0.3) !important;
  border-right-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-x-orange-900\/35 {
  border-left-color: rgb(124 45 18 / 0.35) !important;
  border-right-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-x-orange-900\/40 {
  border-left-color: rgb(124 45 18 / 0.4) !important;
  border-right-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-x-orange-900\/45 {
  border-left-color: rgb(124 45 18 / 0.45) !important;
  border-right-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-x-orange-900\/5 {
  border-left-color: rgb(124 45 18 / 0.05) !important;
  border-right-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-x-orange-900\/50 {
  border-left-color: rgb(124 45 18 / 0.5) !important;
  border-right-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-x-orange-900\/55 {
  border-left-color: rgb(124 45 18 / 0.55) !important;
  border-right-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-x-orange-900\/60 {
  border-left-color: rgb(124 45 18 / 0.6) !important;
  border-right-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-x-orange-900\/65 {
  border-left-color: rgb(124 45 18 / 0.65) !important;
  border-right-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-x-orange-900\/70 {
  border-left-color: rgb(124 45 18 / 0.7) !important;
  border-right-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-x-orange-900\/75 {
  border-left-color: rgb(124 45 18 / 0.75) !important;
  border-right-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-x-orange-900\/80 {
  border-left-color: rgb(124 45 18 / 0.8) !important;
  border-right-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-x-orange-900\/85 {
  border-left-color: rgb(124 45 18 / 0.85) !important;
  border-right-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-x-orange-900\/90 {
  border-left-color: rgb(124 45 18 / 0.9) !important;
  border-right-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-x-orange-900\/95 {
  border-left-color: rgb(124 45 18 / 0.95) !important;
  border-right-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-x-orange-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-x-orange-950\/0 {
  border-left-color: rgb(67 20 7 / 0) !important;
  border-right-color: rgb(67 20 7 / 0) !important;
}

.tw-border-x-orange-950\/10 {
  border-left-color: rgb(67 20 7 / 0.1) !important;
  border-right-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-x-orange-950\/100 {
  border-left-color: rgb(67 20 7 / 1) !important;
  border-right-color: rgb(67 20 7 / 1) !important;
}

.tw-border-x-orange-950\/15 {
  border-left-color: rgb(67 20 7 / 0.15) !important;
  border-right-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-x-orange-950\/20 {
  border-left-color: rgb(67 20 7 / 0.2) !important;
  border-right-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-x-orange-950\/25 {
  border-left-color: rgb(67 20 7 / 0.25) !important;
  border-right-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-x-orange-950\/30 {
  border-left-color: rgb(67 20 7 / 0.3) !important;
  border-right-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-x-orange-950\/35 {
  border-left-color: rgb(67 20 7 / 0.35) !important;
  border-right-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-x-orange-950\/40 {
  border-left-color: rgb(67 20 7 / 0.4) !important;
  border-right-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-x-orange-950\/45 {
  border-left-color: rgb(67 20 7 / 0.45) !important;
  border-right-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-x-orange-950\/5 {
  border-left-color: rgb(67 20 7 / 0.05) !important;
  border-right-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-x-orange-950\/50 {
  border-left-color: rgb(67 20 7 / 0.5) !important;
  border-right-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-x-orange-950\/55 {
  border-left-color: rgb(67 20 7 / 0.55) !important;
  border-right-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-x-orange-950\/60 {
  border-left-color: rgb(67 20 7 / 0.6) !important;
  border-right-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-x-orange-950\/65 {
  border-left-color: rgb(67 20 7 / 0.65) !important;
  border-right-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-x-orange-950\/70 {
  border-left-color: rgb(67 20 7 / 0.7) !important;
  border-right-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-x-orange-950\/75 {
  border-left-color: rgb(67 20 7 / 0.75) !important;
  border-right-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-x-orange-950\/80 {
  border-left-color: rgb(67 20 7 / 0.8) !important;
  border-right-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-x-orange-950\/85 {
  border-left-color: rgb(67 20 7 / 0.85) !important;
  border-right-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-x-orange-950\/90 {
  border-left-color: rgb(67 20 7 / 0.9) !important;
  border-right-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-x-orange-950\/95 {
  border-left-color: rgb(67 20 7 / 0.95) !important;
  border-right-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-x-pink-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-100\/0 {
  border-left-color: rgb(252 231 243 / 0) !important;
  border-right-color: rgb(252 231 243 / 0) !important;
}

.tw-border-x-pink-100\/10 {
  border-left-color: rgb(252 231 243 / 0.1) !important;
  border-right-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-x-pink-100\/100 {
  border-left-color: rgb(252 231 243 / 1) !important;
  border-right-color: rgb(252 231 243 / 1) !important;
}

.tw-border-x-pink-100\/15 {
  border-left-color: rgb(252 231 243 / 0.15) !important;
  border-right-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-x-pink-100\/20 {
  border-left-color: rgb(252 231 243 / 0.2) !important;
  border-right-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-x-pink-100\/25 {
  border-left-color: rgb(252 231 243 / 0.25) !important;
  border-right-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-x-pink-100\/30 {
  border-left-color: rgb(252 231 243 / 0.3) !important;
  border-right-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-x-pink-100\/35 {
  border-left-color: rgb(252 231 243 / 0.35) !important;
  border-right-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-x-pink-100\/40 {
  border-left-color: rgb(252 231 243 / 0.4) !important;
  border-right-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-x-pink-100\/45 {
  border-left-color: rgb(252 231 243 / 0.45) !important;
  border-right-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-x-pink-100\/5 {
  border-left-color: rgb(252 231 243 / 0.05) !important;
  border-right-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-x-pink-100\/50 {
  border-left-color: rgb(252 231 243 / 0.5) !important;
  border-right-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-x-pink-100\/55 {
  border-left-color: rgb(252 231 243 / 0.55) !important;
  border-right-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-x-pink-100\/60 {
  border-left-color: rgb(252 231 243 / 0.6) !important;
  border-right-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-x-pink-100\/65 {
  border-left-color: rgb(252 231 243 / 0.65) !important;
  border-right-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-x-pink-100\/70 {
  border-left-color: rgb(252 231 243 / 0.7) !important;
  border-right-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-x-pink-100\/75 {
  border-left-color: rgb(252 231 243 / 0.75) !important;
  border-right-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-x-pink-100\/80 {
  border-left-color: rgb(252 231 243 / 0.8) !important;
  border-right-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-x-pink-100\/85 {
  border-left-color: rgb(252 231 243 / 0.85) !important;
  border-right-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-x-pink-100\/90 {
  border-left-color: rgb(252 231 243 / 0.9) !important;
  border-right-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-x-pink-100\/95 {
  border-left-color: rgb(252 231 243 / 0.95) !important;
  border-right-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-x-pink-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-200\/0 {
  border-left-color: rgb(251 207 232 / 0) !important;
  border-right-color: rgb(251 207 232 / 0) !important;
}

.tw-border-x-pink-200\/10 {
  border-left-color: rgb(251 207 232 / 0.1) !important;
  border-right-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-x-pink-200\/100 {
  border-left-color: rgb(251 207 232 / 1) !important;
  border-right-color: rgb(251 207 232 / 1) !important;
}

.tw-border-x-pink-200\/15 {
  border-left-color: rgb(251 207 232 / 0.15) !important;
  border-right-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-x-pink-200\/20 {
  border-left-color: rgb(251 207 232 / 0.2) !important;
  border-right-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-x-pink-200\/25 {
  border-left-color: rgb(251 207 232 / 0.25) !important;
  border-right-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-x-pink-200\/30 {
  border-left-color: rgb(251 207 232 / 0.3) !important;
  border-right-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-x-pink-200\/35 {
  border-left-color: rgb(251 207 232 / 0.35) !important;
  border-right-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-x-pink-200\/40 {
  border-left-color: rgb(251 207 232 / 0.4) !important;
  border-right-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-x-pink-200\/45 {
  border-left-color: rgb(251 207 232 / 0.45) !important;
  border-right-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-x-pink-200\/5 {
  border-left-color: rgb(251 207 232 / 0.05) !important;
  border-right-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-x-pink-200\/50 {
  border-left-color: rgb(251 207 232 / 0.5) !important;
  border-right-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-x-pink-200\/55 {
  border-left-color: rgb(251 207 232 / 0.55) !important;
  border-right-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-x-pink-200\/60 {
  border-left-color: rgb(251 207 232 / 0.6) !important;
  border-right-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-x-pink-200\/65 {
  border-left-color: rgb(251 207 232 / 0.65) !important;
  border-right-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-x-pink-200\/70 {
  border-left-color: rgb(251 207 232 / 0.7) !important;
  border-right-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-x-pink-200\/75 {
  border-left-color: rgb(251 207 232 / 0.75) !important;
  border-right-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-x-pink-200\/80 {
  border-left-color: rgb(251 207 232 / 0.8) !important;
  border-right-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-x-pink-200\/85 {
  border-left-color: rgb(251 207 232 / 0.85) !important;
  border-right-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-x-pink-200\/90 {
  border-left-color: rgb(251 207 232 / 0.9) !important;
  border-right-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-x-pink-200\/95 {
  border-left-color: rgb(251 207 232 / 0.95) !important;
  border-right-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-x-pink-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-300\/0 {
  border-left-color: rgb(249 168 212 / 0) !important;
  border-right-color: rgb(249 168 212 / 0) !important;
}

.tw-border-x-pink-300\/10 {
  border-left-color: rgb(249 168 212 / 0.1) !important;
  border-right-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-x-pink-300\/100 {
  border-left-color: rgb(249 168 212 / 1) !important;
  border-right-color: rgb(249 168 212 / 1) !important;
}

.tw-border-x-pink-300\/15 {
  border-left-color: rgb(249 168 212 / 0.15) !important;
  border-right-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-x-pink-300\/20 {
  border-left-color: rgb(249 168 212 / 0.2) !important;
  border-right-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-x-pink-300\/25 {
  border-left-color: rgb(249 168 212 / 0.25) !important;
  border-right-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-x-pink-300\/30 {
  border-left-color: rgb(249 168 212 / 0.3) !important;
  border-right-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-x-pink-300\/35 {
  border-left-color: rgb(249 168 212 / 0.35) !important;
  border-right-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-x-pink-300\/40 {
  border-left-color: rgb(249 168 212 / 0.4) !important;
  border-right-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-x-pink-300\/45 {
  border-left-color: rgb(249 168 212 / 0.45) !important;
  border-right-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-x-pink-300\/5 {
  border-left-color: rgb(249 168 212 / 0.05) !important;
  border-right-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-x-pink-300\/50 {
  border-left-color: rgb(249 168 212 / 0.5) !important;
  border-right-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-x-pink-300\/55 {
  border-left-color: rgb(249 168 212 / 0.55) !important;
  border-right-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-x-pink-300\/60 {
  border-left-color: rgb(249 168 212 / 0.6) !important;
  border-right-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-x-pink-300\/65 {
  border-left-color: rgb(249 168 212 / 0.65) !important;
  border-right-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-x-pink-300\/70 {
  border-left-color: rgb(249 168 212 / 0.7) !important;
  border-right-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-x-pink-300\/75 {
  border-left-color: rgb(249 168 212 / 0.75) !important;
  border-right-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-x-pink-300\/80 {
  border-left-color: rgb(249 168 212 / 0.8) !important;
  border-right-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-x-pink-300\/85 {
  border-left-color: rgb(249 168 212 / 0.85) !important;
  border-right-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-x-pink-300\/90 {
  border-left-color: rgb(249 168 212 / 0.9) !important;
  border-right-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-x-pink-300\/95 {
  border-left-color: rgb(249 168 212 / 0.95) !important;
  border-right-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-x-pink-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-400\/0 {
  border-left-color: rgb(244 114 182 / 0) !important;
  border-right-color: rgb(244 114 182 / 0) !important;
}

.tw-border-x-pink-400\/10 {
  border-left-color: rgb(244 114 182 / 0.1) !important;
  border-right-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-x-pink-400\/100 {
  border-left-color: rgb(244 114 182 / 1) !important;
  border-right-color: rgb(244 114 182 / 1) !important;
}

.tw-border-x-pink-400\/15 {
  border-left-color: rgb(244 114 182 / 0.15) !important;
  border-right-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-x-pink-400\/20 {
  border-left-color: rgb(244 114 182 / 0.2) !important;
  border-right-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-x-pink-400\/25 {
  border-left-color: rgb(244 114 182 / 0.25) !important;
  border-right-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-x-pink-400\/30 {
  border-left-color: rgb(244 114 182 / 0.3) !important;
  border-right-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-x-pink-400\/35 {
  border-left-color: rgb(244 114 182 / 0.35) !important;
  border-right-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-x-pink-400\/40 {
  border-left-color: rgb(244 114 182 / 0.4) !important;
  border-right-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-x-pink-400\/45 {
  border-left-color: rgb(244 114 182 / 0.45) !important;
  border-right-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-x-pink-400\/5 {
  border-left-color: rgb(244 114 182 / 0.05) !important;
  border-right-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-x-pink-400\/50 {
  border-left-color: rgb(244 114 182 / 0.5) !important;
  border-right-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-x-pink-400\/55 {
  border-left-color: rgb(244 114 182 / 0.55) !important;
  border-right-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-x-pink-400\/60 {
  border-left-color: rgb(244 114 182 / 0.6) !important;
  border-right-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-x-pink-400\/65 {
  border-left-color: rgb(244 114 182 / 0.65) !important;
  border-right-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-x-pink-400\/70 {
  border-left-color: rgb(244 114 182 / 0.7) !important;
  border-right-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-x-pink-400\/75 {
  border-left-color: rgb(244 114 182 / 0.75) !important;
  border-right-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-x-pink-400\/80 {
  border-left-color: rgb(244 114 182 / 0.8) !important;
  border-right-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-x-pink-400\/85 {
  border-left-color: rgb(244 114 182 / 0.85) !important;
  border-right-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-x-pink-400\/90 {
  border-left-color: rgb(244 114 182 / 0.9) !important;
  border-right-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-x-pink-400\/95 {
  border-left-color: rgb(244 114 182 / 0.95) !important;
  border-right-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-x-pink-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-50\/0 {
  border-left-color: rgb(253 242 248 / 0) !important;
  border-right-color: rgb(253 242 248 / 0) !important;
}

.tw-border-x-pink-50\/10 {
  border-left-color: rgb(253 242 248 / 0.1) !important;
  border-right-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-x-pink-50\/100 {
  border-left-color: rgb(253 242 248 / 1) !important;
  border-right-color: rgb(253 242 248 / 1) !important;
}

.tw-border-x-pink-50\/15 {
  border-left-color: rgb(253 242 248 / 0.15) !important;
  border-right-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-x-pink-50\/20 {
  border-left-color: rgb(253 242 248 / 0.2) !important;
  border-right-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-x-pink-50\/25 {
  border-left-color: rgb(253 242 248 / 0.25) !important;
  border-right-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-x-pink-50\/30 {
  border-left-color: rgb(253 242 248 / 0.3) !important;
  border-right-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-x-pink-50\/35 {
  border-left-color: rgb(253 242 248 / 0.35) !important;
  border-right-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-x-pink-50\/40 {
  border-left-color: rgb(253 242 248 / 0.4) !important;
  border-right-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-x-pink-50\/45 {
  border-left-color: rgb(253 242 248 / 0.45) !important;
  border-right-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-x-pink-50\/5 {
  border-left-color: rgb(253 242 248 / 0.05) !important;
  border-right-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-x-pink-50\/50 {
  border-left-color: rgb(253 242 248 / 0.5) !important;
  border-right-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-x-pink-50\/55 {
  border-left-color: rgb(253 242 248 / 0.55) !important;
  border-right-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-x-pink-50\/60 {
  border-left-color: rgb(253 242 248 / 0.6) !important;
  border-right-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-x-pink-50\/65 {
  border-left-color: rgb(253 242 248 / 0.65) !important;
  border-right-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-x-pink-50\/70 {
  border-left-color: rgb(253 242 248 / 0.7) !important;
  border-right-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-x-pink-50\/75 {
  border-left-color: rgb(253 242 248 / 0.75) !important;
  border-right-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-x-pink-50\/80 {
  border-left-color: rgb(253 242 248 / 0.8) !important;
  border-right-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-x-pink-50\/85 {
  border-left-color: rgb(253 242 248 / 0.85) !important;
  border-right-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-x-pink-50\/90 {
  border-left-color: rgb(253 242 248 / 0.9) !important;
  border-right-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-x-pink-50\/95 {
  border-left-color: rgb(253 242 248 / 0.95) !important;
  border-right-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-x-pink-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-500\/0 {
  border-left-color: rgb(236 72 153 / 0) !important;
  border-right-color: rgb(236 72 153 / 0) !important;
}

.tw-border-x-pink-500\/10 {
  border-left-color: rgb(236 72 153 / 0.1) !important;
  border-right-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-x-pink-500\/100 {
  border-left-color: rgb(236 72 153 / 1) !important;
  border-right-color: rgb(236 72 153 / 1) !important;
}

.tw-border-x-pink-500\/15 {
  border-left-color: rgb(236 72 153 / 0.15) !important;
  border-right-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-x-pink-500\/20 {
  border-left-color: rgb(236 72 153 / 0.2) !important;
  border-right-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-x-pink-500\/25 {
  border-left-color: rgb(236 72 153 / 0.25) !important;
  border-right-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-x-pink-500\/30 {
  border-left-color: rgb(236 72 153 / 0.3) !important;
  border-right-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-x-pink-500\/35 {
  border-left-color: rgb(236 72 153 / 0.35) !important;
  border-right-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-x-pink-500\/40 {
  border-left-color: rgb(236 72 153 / 0.4) !important;
  border-right-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-x-pink-500\/45 {
  border-left-color: rgb(236 72 153 / 0.45) !important;
  border-right-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-x-pink-500\/5 {
  border-left-color: rgb(236 72 153 / 0.05) !important;
  border-right-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-x-pink-500\/50 {
  border-left-color: rgb(236 72 153 / 0.5) !important;
  border-right-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-x-pink-500\/55 {
  border-left-color: rgb(236 72 153 / 0.55) !important;
  border-right-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-x-pink-500\/60 {
  border-left-color: rgb(236 72 153 / 0.6) !important;
  border-right-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-x-pink-500\/65 {
  border-left-color: rgb(236 72 153 / 0.65) !important;
  border-right-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-x-pink-500\/70 {
  border-left-color: rgb(236 72 153 / 0.7) !important;
  border-right-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-x-pink-500\/75 {
  border-left-color: rgb(236 72 153 / 0.75) !important;
  border-right-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-x-pink-500\/80 {
  border-left-color: rgb(236 72 153 / 0.8) !important;
  border-right-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-x-pink-500\/85 {
  border-left-color: rgb(236 72 153 / 0.85) !important;
  border-right-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-x-pink-500\/90 {
  border-left-color: rgb(236 72 153 / 0.9) !important;
  border-right-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-x-pink-500\/95 {
  border-left-color: rgb(236 72 153 / 0.95) !important;
  border-right-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-x-pink-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-600\/0 {
  border-left-color: rgb(219 39 119 / 0) !important;
  border-right-color: rgb(219 39 119 / 0) !important;
}

.tw-border-x-pink-600\/10 {
  border-left-color: rgb(219 39 119 / 0.1) !important;
  border-right-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-x-pink-600\/100 {
  border-left-color: rgb(219 39 119 / 1) !important;
  border-right-color: rgb(219 39 119 / 1) !important;
}

.tw-border-x-pink-600\/15 {
  border-left-color: rgb(219 39 119 / 0.15) !important;
  border-right-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-x-pink-600\/20 {
  border-left-color: rgb(219 39 119 / 0.2) !important;
  border-right-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-x-pink-600\/25 {
  border-left-color: rgb(219 39 119 / 0.25) !important;
  border-right-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-x-pink-600\/30 {
  border-left-color: rgb(219 39 119 / 0.3) !important;
  border-right-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-x-pink-600\/35 {
  border-left-color: rgb(219 39 119 / 0.35) !important;
  border-right-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-x-pink-600\/40 {
  border-left-color: rgb(219 39 119 / 0.4) !important;
  border-right-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-x-pink-600\/45 {
  border-left-color: rgb(219 39 119 / 0.45) !important;
  border-right-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-x-pink-600\/5 {
  border-left-color: rgb(219 39 119 / 0.05) !important;
  border-right-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-x-pink-600\/50 {
  border-left-color: rgb(219 39 119 / 0.5) !important;
  border-right-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-x-pink-600\/55 {
  border-left-color: rgb(219 39 119 / 0.55) !important;
  border-right-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-x-pink-600\/60 {
  border-left-color: rgb(219 39 119 / 0.6) !important;
  border-right-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-x-pink-600\/65 {
  border-left-color: rgb(219 39 119 / 0.65) !important;
  border-right-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-x-pink-600\/70 {
  border-left-color: rgb(219 39 119 / 0.7) !important;
  border-right-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-x-pink-600\/75 {
  border-left-color: rgb(219 39 119 / 0.75) !important;
  border-right-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-x-pink-600\/80 {
  border-left-color: rgb(219 39 119 / 0.8) !important;
  border-right-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-x-pink-600\/85 {
  border-left-color: rgb(219 39 119 / 0.85) !important;
  border-right-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-x-pink-600\/90 {
  border-left-color: rgb(219 39 119 / 0.9) !important;
  border-right-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-x-pink-600\/95 {
  border-left-color: rgb(219 39 119 / 0.95) !important;
  border-right-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-x-pink-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-700\/0 {
  border-left-color: rgb(190 24 93 / 0) !important;
  border-right-color: rgb(190 24 93 / 0) !important;
}

.tw-border-x-pink-700\/10 {
  border-left-color: rgb(190 24 93 / 0.1) !important;
  border-right-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-x-pink-700\/100 {
  border-left-color: rgb(190 24 93 / 1) !important;
  border-right-color: rgb(190 24 93 / 1) !important;
}

.tw-border-x-pink-700\/15 {
  border-left-color: rgb(190 24 93 / 0.15) !important;
  border-right-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-x-pink-700\/20 {
  border-left-color: rgb(190 24 93 / 0.2) !important;
  border-right-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-x-pink-700\/25 {
  border-left-color: rgb(190 24 93 / 0.25) !important;
  border-right-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-x-pink-700\/30 {
  border-left-color: rgb(190 24 93 / 0.3) !important;
  border-right-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-x-pink-700\/35 {
  border-left-color: rgb(190 24 93 / 0.35) !important;
  border-right-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-x-pink-700\/40 {
  border-left-color: rgb(190 24 93 / 0.4) !important;
  border-right-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-x-pink-700\/45 {
  border-left-color: rgb(190 24 93 / 0.45) !important;
  border-right-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-x-pink-700\/5 {
  border-left-color: rgb(190 24 93 / 0.05) !important;
  border-right-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-x-pink-700\/50 {
  border-left-color: rgb(190 24 93 / 0.5) !important;
  border-right-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-x-pink-700\/55 {
  border-left-color: rgb(190 24 93 / 0.55) !important;
  border-right-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-x-pink-700\/60 {
  border-left-color: rgb(190 24 93 / 0.6) !important;
  border-right-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-x-pink-700\/65 {
  border-left-color: rgb(190 24 93 / 0.65) !important;
  border-right-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-x-pink-700\/70 {
  border-left-color: rgb(190 24 93 / 0.7) !important;
  border-right-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-x-pink-700\/75 {
  border-left-color: rgb(190 24 93 / 0.75) !important;
  border-right-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-x-pink-700\/80 {
  border-left-color: rgb(190 24 93 / 0.8) !important;
  border-right-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-x-pink-700\/85 {
  border-left-color: rgb(190 24 93 / 0.85) !important;
  border-right-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-x-pink-700\/90 {
  border-left-color: rgb(190 24 93 / 0.9) !important;
  border-right-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-x-pink-700\/95 {
  border-left-color: rgb(190 24 93 / 0.95) !important;
  border-right-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-x-pink-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-800\/0 {
  border-left-color: rgb(157 23 77 / 0) !important;
  border-right-color: rgb(157 23 77 / 0) !important;
}

.tw-border-x-pink-800\/10 {
  border-left-color: rgb(157 23 77 / 0.1) !important;
  border-right-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-x-pink-800\/100 {
  border-left-color: rgb(157 23 77 / 1) !important;
  border-right-color: rgb(157 23 77 / 1) !important;
}

.tw-border-x-pink-800\/15 {
  border-left-color: rgb(157 23 77 / 0.15) !important;
  border-right-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-x-pink-800\/20 {
  border-left-color: rgb(157 23 77 / 0.2) !important;
  border-right-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-x-pink-800\/25 {
  border-left-color: rgb(157 23 77 / 0.25) !important;
  border-right-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-x-pink-800\/30 {
  border-left-color: rgb(157 23 77 / 0.3) !important;
  border-right-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-x-pink-800\/35 {
  border-left-color: rgb(157 23 77 / 0.35) !important;
  border-right-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-x-pink-800\/40 {
  border-left-color: rgb(157 23 77 / 0.4) !important;
  border-right-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-x-pink-800\/45 {
  border-left-color: rgb(157 23 77 / 0.45) !important;
  border-right-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-x-pink-800\/5 {
  border-left-color: rgb(157 23 77 / 0.05) !important;
  border-right-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-x-pink-800\/50 {
  border-left-color: rgb(157 23 77 / 0.5) !important;
  border-right-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-x-pink-800\/55 {
  border-left-color: rgb(157 23 77 / 0.55) !important;
  border-right-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-x-pink-800\/60 {
  border-left-color: rgb(157 23 77 / 0.6) !important;
  border-right-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-x-pink-800\/65 {
  border-left-color: rgb(157 23 77 / 0.65) !important;
  border-right-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-x-pink-800\/70 {
  border-left-color: rgb(157 23 77 / 0.7) !important;
  border-right-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-x-pink-800\/75 {
  border-left-color: rgb(157 23 77 / 0.75) !important;
  border-right-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-x-pink-800\/80 {
  border-left-color: rgb(157 23 77 / 0.8) !important;
  border-right-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-x-pink-800\/85 {
  border-left-color: rgb(157 23 77 / 0.85) !important;
  border-right-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-x-pink-800\/90 {
  border-left-color: rgb(157 23 77 / 0.9) !important;
  border-right-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-x-pink-800\/95 {
  border-left-color: rgb(157 23 77 / 0.95) !important;
  border-right-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-x-pink-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-900\/0 {
  border-left-color: rgb(131 24 67 / 0) !important;
  border-right-color: rgb(131 24 67 / 0) !important;
}

.tw-border-x-pink-900\/10 {
  border-left-color: rgb(131 24 67 / 0.1) !important;
  border-right-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-x-pink-900\/100 {
  border-left-color: rgb(131 24 67 / 1) !important;
  border-right-color: rgb(131 24 67 / 1) !important;
}

.tw-border-x-pink-900\/15 {
  border-left-color: rgb(131 24 67 / 0.15) !important;
  border-right-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-x-pink-900\/20 {
  border-left-color: rgb(131 24 67 / 0.2) !important;
  border-right-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-x-pink-900\/25 {
  border-left-color: rgb(131 24 67 / 0.25) !important;
  border-right-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-x-pink-900\/30 {
  border-left-color: rgb(131 24 67 / 0.3) !important;
  border-right-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-x-pink-900\/35 {
  border-left-color: rgb(131 24 67 / 0.35) !important;
  border-right-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-x-pink-900\/40 {
  border-left-color: rgb(131 24 67 / 0.4) !important;
  border-right-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-x-pink-900\/45 {
  border-left-color: rgb(131 24 67 / 0.45) !important;
  border-right-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-x-pink-900\/5 {
  border-left-color: rgb(131 24 67 / 0.05) !important;
  border-right-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-x-pink-900\/50 {
  border-left-color: rgb(131 24 67 / 0.5) !important;
  border-right-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-x-pink-900\/55 {
  border-left-color: rgb(131 24 67 / 0.55) !important;
  border-right-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-x-pink-900\/60 {
  border-left-color: rgb(131 24 67 / 0.6) !important;
  border-right-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-x-pink-900\/65 {
  border-left-color: rgb(131 24 67 / 0.65) !important;
  border-right-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-x-pink-900\/70 {
  border-left-color: rgb(131 24 67 / 0.7) !important;
  border-right-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-x-pink-900\/75 {
  border-left-color: rgb(131 24 67 / 0.75) !important;
  border-right-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-x-pink-900\/80 {
  border-left-color: rgb(131 24 67 / 0.8) !important;
  border-right-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-x-pink-900\/85 {
  border-left-color: rgb(131 24 67 / 0.85) !important;
  border-right-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-x-pink-900\/90 {
  border-left-color: rgb(131 24 67 / 0.9) !important;
  border-right-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-x-pink-900\/95 {
  border-left-color: rgb(131 24 67 / 0.95) !important;
  border-right-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-x-pink-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-x-pink-950\/0 {
  border-left-color: rgb(80 7 36 / 0) !important;
  border-right-color: rgb(80 7 36 / 0) !important;
}

.tw-border-x-pink-950\/10 {
  border-left-color: rgb(80 7 36 / 0.1) !important;
  border-right-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-x-pink-950\/100 {
  border-left-color: rgb(80 7 36 / 1) !important;
  border-right-color: rgb(80 7 36 / 1) !important;
}

.tw-border-x-pink-950\/15 {
  border-left-color: rgb(80 7 36 / 0.15) !important;
  border-right-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-x-pink-950\/20 {
  border-left-color: rgb(80 7 36 / 0.2) !important;
  border-right-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-x-pink-950\/25 {
  border-left-color: rgb(80 7 36 / 0.25) !important;
  border-right-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-x-pink-950\/30 {
  border-left-color: rgb(80 7 36 / 0.3) !important;
  border-right-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-x-pink-950\/35 {
  border-left-color: rgb(80 7 36 / 0.35) !important;
  border-right-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-x-pink-950\/40 {
  border-left-color: rgb(80 7 36 / 0.4) !important;
  border-right-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-x-pink-950\/45 {
  border-left-color: rgb(80 7 36 / 0.45) !important;
  border-right-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-x-pink-950\/5 {
  border-left-color: rgb(80 7 36 / 0.05) !important;
  border-right-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-x-pink-950\/50 {
  border-left-color: rgb(80 7 36 / 0.5) !important;
  border-right-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-x-pink-950\/55 {
  border-left-color: rgb(80 7 36 / 0.55) !important;
  border-right-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-x-pink-950\/60 {
  border-left-color: rgb(80 7 36 / 0.6) !important;
  border-right-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-x-pink-950\/65 {
  border-left-color: rgb(80 7 36 / 0.65) !important;
  border-right-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-x-pink-950\/70 {
  border-left-color: rgb(80 7 36 / 0.7) !important;
  border-right-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-x-pink-950\/75 {
  border-left-color: rgb(80 7 36 / 0.75) !important;
  border-right-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-x-pink-950\/80 {
  border-left-color: rgb(80 7 36 / 0.8) !important;
  border-right-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-x-pink-950\/85 {
  border-left-color: rgb(80 7 36 / 0.85) !important;
  border-right-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-x-pink-950\/90 {
  border-left-color: rgb(80 7 36 / 0.9) !important;
  border-right-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-x-pink-950\/95 {
  border-left-color: rgb(80 7 36 / 0.95) !important;
  border-right-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-x-purple-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-100\/0 {
  border-left-color: rgb(243 232 255 / 0) !important;
  border-right-color: rgb(243 232 255 / 0) !important;
}

.tw-border-x-purple-100\/10 {
  border-left-color: rgb(243 232 255 / 0.1) !important;
  border-right-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-x-purple-100\/100 {
  border-left-color: rgb(243 232 255 / 1) !important;
  border-right-color: rgb(243 232 255 / 1) !important;
}

.tw-border-x-purple-100\/15 {
  border-left-color: rgb(243 232 255 / 0.15) !important;
  border-right-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-x-purple-100\/20 {
  border-left-color: rgb(243 232 255 / 0.2) !important;
  border-right-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-x-purple-100\/25 {
  border-left-color: rgb(243 232 255 / 0.25) !important;
  border-right-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-x-purple-100\/30 {
  border-left-color: rgb(243 232 255 / 0.3) !important;
  border-right-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-x-purple-100\/35 {
  border-left-color: rgb(243 232 255 / 0.35) !important;
  border-right-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-x-purple-100\/40 {
  border-left-color: rgb(243 232 255 / 0.4) !important;
  border-right-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-x-purple-100\/45 {
  border-left-color: rgb(243 232 255 / 0.45) !important;
  border-right-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-x-purple-100\/5 {
  border-left-color: rgb(243 232 255 / 0.05) !important;
  border-right-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-x-purple-100\/50 {
  border-left-color: rgb(243 232 255 / 0.5) !important;
  border-right-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-x-purple-100\/55 {
  border-left-color: rgb(243 232 255 / 0.55) !important;
  border-right-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-x-purple-100\/60 {
  border-left-color: rgb(243 232 255 / 0.6) !important;
  border-right-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-x-purple-100\/65 {
  border-left-color: rgb(243 232 255 / 0.65) !important;
  border-right-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-x-purple-100\/70 {
  border-left-color: rgb(243 232 255 / 0.7) !important;
  border-right-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-x-purple-100\/75 {
  border-left-color: rgb(243 232 255 / 0.75) !important;
  border-right-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-x-purple-100\/80 {
  border-left-color: rgb(243 232 255 / 0.8) !important;
  border-right-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-x-purple-100\/85 {
  border-left-color: rgb(243 232 255 / 0.85) !important;
  border-right-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-x-purple-100\/90 {
  border-left-color: rgb(243 232 255 / 0.9) !important;
  border-right-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-x-purple-100\/95 {
  border-left-color: rgb(243 232 255 / 0.95) !important;
  border-right-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-x-purple-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-200\/0 {
  border-left-color: rgb(233 213 255 / 0) !important;
  border-right-color: rgb(233 213 255 / 0) !important;
}

.tw-border-x-purple-200\/10 {
  border-left-color: rgb(233 213 255 / 0.1) !important;
  border-right-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-x-purple-200\/100 {
  border-left-color: rgb(233 213 255 / 1) !important;
  border-right-color: rgb(233 213 255 / 1) !important;
}

.tw-border-x-purple-200\/15 {
  border-left-color: rgb(233 213 255 / 0.15) !important;
  border-right-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-x-purple-200\/20 {
  border-left-color: rgb(233 213 255 / 0.2) !important;
  border-right-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-x-purple-200\/25 {
  border-left-color: rgb(233 213 255 / 0.25) !important;
  border-right-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-x-purple-200\/30 {
  border-left-color: rgb(233 213 255 / 0.3) !important;
  border-right-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-x-purple-200\/35 {
  border-left-color: rgb(233 213 255 / 0.35) !important;
  border-right-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-x-purple-200\/40 {
  border-left-color: rgb(233 213 255 / 0.4) !important;
  border-right-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-x-purple-200\/45 {
  border-left-color: rgb(233 213 255 / 0.45) !important;
  border-right-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-x-purple-200\/5 {
  border-left-color: rgb(233 213 255 / 0.05) !important;
  border-right-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-x-purple-200\/50 {
  border-left-color: rgb(233 213 255 / 0.5) !important;
  border-right-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-x-purple-200\/55 {
  border-left-color: rgb(233 213 255 / 0.55) !important;
  border-right-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-x-purple-200\/60 {
  border-left-color: rgb(233 213 255 / 0.6) !important;
  border-right-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-x-purple-200\/65 {
  border-left-color: rgb(233 213 255 / 0.65) !important;
  border-right-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-x-purple-200\/70 {
  border-left-color: rgb(233 213 255 / 0.7) !important;
  border-right-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-x-purple-200\/75 {
  border-left-color: rgb(233 213 255 / 0.75) !important;
  border-right-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-x-purple-200\/80 {
  border-left-color: rgb(233 213 255 / 0.8) !important;
  border-right-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-x-purple-200\/85 {
  border-left-color: rgb(233 213 255 / 0.85) !important;
  border-right-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-x-purple-200\/90 {
  border-left-color: rgb(233 213 255 / 0.9) !important;
  border-right-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-x-purple-200\/95 {
  border-left-color: rgb(233 213 255 / 0.95) !important;
  border-right-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-x-purple-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-300\/0 {
  border-left-color: rgb(216 180 254 / 0) !important;
  border-right-color: rgb(216 180 254 / 0) !important;
}

.tw-border-x-purple-300\/10 {
  border-left-color: rgb(216 180 254 / 0.1) !important;
  border-right-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-x-purple-300\/100 {
  border-left-color: rgb(216 180 254 / 1) !important;
  border-right-color: rgb(216 180 254 / 1) !important;
}

.tw-border-x-purple-300\/15 {
  border-left-color: rgb(216 180 254 / 0.15) !important;
  border-right-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-x-purple-300\/20 {
  border-left-color: rgb(216 180 254 / 0.2) !important;
  border-right-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-x-purple-300\/25 {
  border-left-color: rgb(216 180 254 / 0.25) !important;
  border-right-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-x-purple-300\/30 {
  border-left-color: rgb(216 180 254 / 0.3) !important;
  border-right-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-x-purple-300\/35 {
  border-left-color: rgb(216 180 254 / 0.35) !important;
  border-right-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-x-purple-300\/40 {
  border-left-color: rgb(216 180 254 / 0.4) !important;
  border-right-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-x-purple-300\/45 {
  border-left-color: rgb(216 180 254 / 0.45) !important;
  border-right-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-x-purple-300\/5 {
  border-left-color: rgb(216 180 254 / 0.05) !important;
  border-right-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-x-purple-300\/50 {
  border-left-color: rgb(216 180 254 / 0.5) !important;
  border-right-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-x-purple-300\/55 {
  border-left-color: rgb(216 180 254 / 0.55) !important;
  border-right-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-x-purple-300\/60 {
  border-left-color: rgb(216 180 254 / 0.6) !important;
  border-right-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-x-purple-300\/65 {
  border-left-color: rgb(216 180 254 / 0.65) !important;
  border-right-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-x-purple-300\/70 {
  border-left-color: rgb(216 180 254 / 0.7) !important;
  border-right-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-x-purple-300\/75 {
  border-left-color: rgb(216 180 254 / 0.75) !important;
  border-right-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-x-purple-300\/80 {
  border-left-color: rgb(216 180 254 / 0.8) !important;
  border-right-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-x-purple-300\/85 {
  border-left-color: rgb(216 180 254 / 0.85) !important;
  border-right-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-x-purple-300\/90 {
  border-left-color: rgb(216 180 254 / 0.9) !important;
  border-right-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-x-purple-300\/95 {
  border-left-color: rgb(216 180 254 / 0.95) !important;
  border-right-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-x-purple-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-400\/0 {
  border-left-color: rgb(192 132 252 / 0) !important;
  border-right-color: rgb(192 132 252 / 0) !important;
}

.tw-border-x-purple-400\/10 {
  border-left-color: rgb(192 132 252 / 0.1) !important;
  border-right-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-x-purple-400\/100 {
  border-left-color: rgb(192 132 252 / 1) !important;
  border-right-color: rgb(192 132 252 / 1) !important;
}

.tw-border-x-purple-400\/15 {
  border-left-color: rgb(192 132 252 / 0.15) !important;
  border-right-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-x-purple-400\/20 {
  border-left-color: rgb(192 132 252 / 0.2) !important;
  border-right-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-x-purple-400\/25 {
  border-left-color: rgb(192 132 252 / 0.25) !important;
  border-right-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-x-purple-400\/30 {
  border-left-color: rgb(192 132 252 / 0.3) !important;
  border-right-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-x-purple-400\/35 {
  border-left-color: rgb(192 132 252 / 0.35) !important;
  border-right-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-x-purple-400\/40 {
  border-left-color: rgb(192 132 252 / 0.4) !important;
  border-right-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-x-purple-400\/45 {
  border-left-color: rgb(192 132 252 / 0.45) !important;
  border-right-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-x-purple-400\/5 {
  border-left-color: rgb(192 132 252 / 0.05) !important;
  border-right-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-x-purple-400\/50 {
  border-left-color: rgb(192 132 252 / 0.5) !important;
  border-right-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-x-purple-400\/55 {
  border-left-color: rgb(192 132 252 / 0.55) !important;
  border-right-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-x-purple-400\/60 {
  border-left-color: rgb(192 132 252 / 0.6) !important;
  border-right-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-x-purple-400\/65 {
  border-left-color: rgb(192 132 252 / 0.65) !important;
  border-right-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-x-purple-400\/70 {
  border-left-color: rgb(192 132 252 / 0.7) !important;
  border-right-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-x-purple-400\/75 {
  border-left-color: rgb(192 132 252 / 0.75) !important;
  border-right-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-x-purple-400\/80 {
  border-left-color: rgb(192 132 252 / 0.8) !important;
  border-right-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-x-purple-400\/85 {
  border-left-color: rgb(192 132 252 / 0.85) !important;
  border-right-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-x-purple-400\/90 {
  border-left-color: rgb(192 132 252 / 0.9) !important;
  border-right-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-x-purple-400\/95 {
  border-left-color: rgb(192 132 252 / 0.95) !important;
  border-right-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-x-purple-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-50\/0 {
  border-left-color: rgb(250 245 255 / 0) !important;
  border-right-color: rgb(250 245 255 / 0) !important;
}

.tw-border-x-purple-50\/10 {
  border-left-color: rgb(250 245 255 / 0.1) !important;
  border-right-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-x-purple-50\/100 {
  border-left-color: rgb(250 245 255 / 1) !important;
  border-right-color: rgb(250 245 255 / 1) !important;
}

.tw-border-x-purple-50\/15 {
  border-left-color: rgb(250 245 255 / 0.15) !important;
  border-right-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-x-purple-50\/20 {
  border-left-color: rgb(250 245 255 / 0.2) !important;
  border-right-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-x-purple-50\/25 {
  border-left-color: rgb(250 245 255 / 0.25) !important;
  border-right-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-x-purple-50\/30 {
  border-left-color: rgb(250 245 255 / 0.3) !important;
  border-right-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-x-purple-50\/35 {
  border-left-color: rgb(250 245 255 / 0.35) !important;
  border-right-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-x-purple-50\/40 {
  border-left-color: rgb(250 245 255 / 0.4) !important;
  border-right-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-x-purple-50\/45 {
  border-left-color: rgb(250 245 255 / 0.45) !important;
  border-right-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-x-purple-50\/5 {
  border-left-color: rgb(250 245 255 / 0.05) !important;
  border-right-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-x-purple-50\/50 {
  border-left-color: rgb(250 245 255 / 0.5) !important;
  border-right-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-x-purple-50\/55 {
  border-left-color: rgb(250 245 255 / 0.55) !important;
  border-right-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-x-purple-50\/60 {
  border-left-color: rgb(250 245 255 / 0.6) !important;
  border-right-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-x-purple-50\/65 {
  border-left-color: rgb(250 245 255 / 0.65) !important;
  border-right-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-x-purple-50\/70 {
  border-left-color: rgb(250 245 255 / 0.7) !important;
  border-right-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-x-purple-50\/75 {
  border-left-color: rgb(250 245 255 / 0.75) !important;
  border-right-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-x-purple-50\/80 {
  border-left-color: rgb(250 245 255 / 0.8) !important;
  border-right-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-x-purple-50\/85 {
  border-left-color: rgb(250 245 255 / 0.85) !important;
  border-right-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-x-purple-50\/90 {
  border-left-color: rgb(250 245 255 / 0.9) !important;
  border-right-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-x-purple-50\/95 {
  border-left-color: rgb(250 245 255 / 0.95) !important;
  border-right-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-x-purple-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-500\/0 {
  border-left-color: rgb(168 85 247 / 0) !important;
  border-right-color: rgb(168 85 247 / 0) !important;
}

.tw-border-x-purple-500\/10 {
  border-left-color: rgb(168 85 247 / 0.1) !important;
  border-right-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-x-purple-500\/100 {
  border-left-color: rgb(168 85 247 / 1) !important;
  border-right-color: rgb(168 85 247 / 1) !important;
}

.tw-border-x-purple-500\/15 {
  border-left-color: rgb(168 85 247 / 0.15) !important;
  border-right-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-x-purple-500\/20 {
  border-left-color: rgb(168 85 247 / 0.2) !important;
  border-right-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-x-purple-500\/25 {
  border-left-color: rgb(168 85 247 / 0.25) !important;
  border-right-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-x-purple-500\/30 {
  border-left-color: rgb(168 85 247 / 0.3) !important;
  border-right-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-x-purple-500\/35 {
  border-left-color: rgb(168 85 247 / 0.35) !important;
  border-right-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-x-purple-500\/40 {
  border-left-color: rgb(168 85 247 / 0.4) !important;
  border-right-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-x-purple-500\/45 {
  border-left-color: rgb(168 85 247 / 0.45) !important;
  border-right-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-x-purple-500\/5 {
  border-left-color: rgb(168 85 247 / 0.05) !important;
  border-right-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-x-purple-500\/50 {
  border-left-color: rgb(168 85 247 / 0.5) !important;
  border-right-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-x-purple-500\/55 {
  border-left-color: rgb(168 85 247 / 0.55) !important;
  border-right-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-x-purple-500\/60 {
  border-left-color: rgb(168 85 247 / 0.6) !important;
  border-right-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-x-purple-500\/65 {
  border-left-color: rgb(168 85 247 / 0.65) !important;
  border-right-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-x-purple-500\/70 {
  border-left-color: rgb(168 85 247 / 0.7) !important;
  border-right-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-x-purple-500\/75 {
  border-left-color: rgb(168 85 247 / 0.75) !important;
  border-right-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-x-purple-500\/80 {
  border-left-color: rgb(168 85 247 / 0.8) !important;
  border-right-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-x-purple-500\/85 {
  border-left-color: rgb(168 85 247 / 0.85) !important;
  border-right-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-x-purple-500\/90 {
  border-left-color: rgb(168 85 247 / 0.9) !important;
  border-right-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-x-purple-500\/95 {
  border-left-color: rgb(168 85 247 / 0.95) !important;
  border-right-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-x-purple-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-600\/0 {
  border-left-color: rgb(147 51 234 / 0) !important;
  border-right-color: rgb(147 51 234 / 0) !important;
}

.tw-border-x-purple-600\/10 {
  border-left-color: rgb(147 51 234 / 0.1) !important;
  border-right-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-x-purple-600\/100 {
  border-left-color: rgb(147 51 234 / 1) !important;
  border-right-color: rgb(147 51 234 / 1) !important;
}

.tw-border-x-purple-600\/15 {
  border-left-color: rgb(147 51 234 / 0.15) !important;
  border-right-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-x-purple-600\/20 {
  border-left-color: rgb(147 51 234 / 0.2) !important;
  border-right-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-x-purple-600\/25 {
  border-left-color: rgb(147 51 234 / 0.25) !important;
  border-right-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-x-purple-600\/30 {
  border-left-color: rgb(147 51 234 / 0.3) !important;
  border-right-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-x-purple-600\/35 {
  border-left-color: rgb(147 51 234 / 0.35) !important;
  border-right-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-x-purple-600\/40 {
  border-left-color: rgb(147 51 234 / 0.4) !important;
  border-right-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-x-purple-600\/45 {
  border-left-color: rgb(147 51 234 / 0.45) !important;
  border-right-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-x-purple-600\/5 {
  border-left-color: rgb(147 51 234 / 0.05) !important;
  border-right-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-x-purple-600\/50 {
  border-left-color: rgb(147 51 234 / 0.5) !important;
  border-right-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-x-purple-600\/55 {
  border-left-color: rgb(147 51 234 / 0.55) !important;
  border-right-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-x-purple-600\/60 {
  border-left-color: rgb(147 51 234 / 0.6) !important;
  border-right-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-x-purple-600\/65 {
  border-left-color: rgb(147 51 234 / 0.65) !important;
  border-right-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-x-purple-600\/70 {
  border-left-color: rgb(147 51 234 / 0.7) !important;
  border-right-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-x-purple-600\/75 {
  border-left-color: rgb(147 51 234 / 0.75) !important;
  border-right-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-x-purple-600\/80 {
  border-left-color: rgb(147 51 234 / 0.8) !important;
  border-right-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-x-purple-600\/85 {
  border-left-color: rgb(147 51 234 / 0.85) !important;
  border-right-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-x-purple-600\/90 {
  border-left-color: rgb(147 51 234 / 0.9) !important;
  border-right-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-x-purple-600\/95 {
  border-left-color: rgb(147 51 234 / 0.95) !important;
  border-right-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-x-purple-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-700\/0 {
  border-left-color: rgb(126 34 206 / 0) !important;
  border-right-color: rgb(126 34 206 / 0) !important;
}

.tw-border-x-purple-700\/10 {
  border-left-color: rgb(126 34 206 / 0.1) !important;
  border-right-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-x-purple-700\/100 {
  border-left-color: rgb(126 34 206 / 1) !important;
  border-right-color: rgb(126 34 206 / 1) !important;
}

.tw-border-x-purple-700\/15 {
  border-left-color: rgb(126 34 206 / 0.15) !important;
  border-right-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-x-purple-700\/20 {
  border-left-color: rgb(126 34 206 / 0.2) !important;
  border-right-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-x-purple-700\/25 {
  border-left-color: rgb(126 34 206 / 0.25) !important;
  border-right-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-x-purple-700\/30 {
  border-left-color: rgb(126 34 206 / 0.3) !important;
  border-right-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-x-purple-700\/35 {
  border-left-color: rgb(126 34 206 / 0.35) !important;
  border-right-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-x-purple-700\/40 {
  border-left-color: rgb(126 34 206 / 0.4) !important;
  border-right-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-x-purple-700\/45 {
  border-left-color: rgb(126 34 206 / 0.45) !important;
  border-right-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-x-purple-700\/5 {
  border-left-color: rgb(126 34 206 / 0.05) !important;
  border-right-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-x-purple-700\/50 {
  border-left-color: rgb(126 34 206 / 0.5) !important;
  border-right-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-x-purple-700\/55 {
  border-left-color: rgb(126 34 206 / 0.55) !important;
  border-right-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-x-purple-700\/60 {
  border-left-color: rgb(126 34 206 / 0.6) !important;
  border-right-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-x-purple-700\/65 {
  border-left-color: rgb(126 34 206 / 0.65) !important;
  border-right-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-x-purple-700\/70 {
  border-left-color: rgb(126 34 206 / 0.7) !important;
  border-right-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-x-purple-700\/75 {
  border-left-color: rgb(126 34 206 / 0.75) !important;
  border-right-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-x-purple-700\/80 {
  border-left-color: rgb(126 34 206 / 0.8) !important;
  border-right-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-x-purple-700\/85 {
  border-left-color: rgb(126 34 206 / 0.85) !important;
  border-right-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-x-purple-700\/90 {
  border-left-color: rgb(126 34 206 / 0.9) !important;
  border-right-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-x-purple-700\/95 {
  border-left-color: rgb(126 34 206 / 0.95) !important;
  border-right-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-x-purple-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-800\/0 {
  border-left-color: rgb(107 33 168 / 0) !important;
  border-right-color: rgb(107 33 168 / 0) !important;
}

.tw-border-x-purple-800\/10 {
  border-left-color: rgb(107 33 168 / 0.1) !important;
  border-right-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-x-purple-800\/100 {
  border-left-color: rgb(107 33 168 / 1) !important;
  border-right-color: rgb(107 33 168 / 1) !important;
}

.tw-border-x-purple-800\/15 {
  border-left-color: rgb(107 33 168 / 0.15) !important;
  border-right-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-x-purple-800\/20 {
  border-left-color: rgb(107 33 168 / 0.2) !important;
  border-right-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-x-purple-800\/25 {
  border-left-color: rgb(107 33 168 / 0.25) !important;
  border-right-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-x-purple-800\/30 {
  border-left-color: rgb(107 33 168 / 0.3) !important;
  border-right-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-x-purple-800\/35 {
  border-left-color: rgb(107 33 168 / 0.35) !important;
  border-right-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-x-purple-800\/40 {
  border-left-color: rgb(107 33 168 / 0.4) !important;
  border-right-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-x-purple-800\/45 {
  border-left-color: rgb(107 33 168 / 0.45) !important;
  border-right-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-x-purple-800\/5 {
  border-left-color: rgb(107 33 168 / 0.05) !important;
  border-right-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-x-purple-800\/50 {
  border-left-color: rgb(107 33 168 / 0.5) !important;
  border-right-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-x-purple-800\/55 {
  border-left-color: rgb(107 33 168 / 0.55) !important;
  border-right-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-x-purple-800\/60 {
  border-left-color: rgb(107 33 168 / 0.6) !important;
  border-right-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-x-purple-800\/65 {
  border-left-color: rgb(107 33 168 / 0.65) !important;
  border-right-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-x-purple-800\/70 {
  border-left-color: rgb(107 33 168 / 0.7) !important;
  border-right-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-x-purple-800\/75 {
  border-left-color: rgb(107 33 168 / 0.75) !important;
  border-right-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-x-purple-800\/80 {
  border-left-color: rgb(107 33 168 / 0.8) !important;
  border-right-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-x-purple-800\/85 {
  border-left-color: rgb(107 33 168 / 0.85) !important;
  border-right-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-x-purple-800\/90 {
  border-left-color: rgb(107 33 168 / 0.9) !important;
  border-right-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-x-purple-800\/95 {
  border-left-color: rgb(107 33 168 / 0.95) !important;
  border-right-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-x-purple-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-900\/0 {
  border-left-color: rgb(88 28 135 / 0) !important;
  border-right-color: rgb(88 28 135 / 0) !important;
}

.tw-border-x-purple-900\/10 {
  border-left-color: rgb(88 28 135 / 0.1) !important;
  border-right-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-x-purple-900\/100 {
  border-left-color: rgb(88 28 135 / 1) !important;
  border-right-color: rgb(88 28 135 / 1) !important;
}

.tw-border-x-purple-900\/15 {
  border-left-color: rgb(88 28 135 / 0.15) !important;
  border-right-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-x-purple-900\/20 {
  border-left-color: rgb(88 28 135 / 0.2) !important;
  border-right-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-x-purple-900\/25 {
  border-left-color: rgb(88 28 135 / 0.25) !important;
  border-right-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-x-purple-900\/30 {
  border-left-color: rgb(88 28 135 / 0.3) !important;
  border-right-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-x-purple-900\/35 {
  border-left-color: rgb(88 28 135 / 0.35) !important;
  border-right-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-x-purple-900\/40 {
  border-left-color: rgb(88 28 135 / 0.4) !important;
  border-right-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-x-purple-900\/45 {
  border-left-color: rgb(88 28 135 / 0.45) !important;
  border-right-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-x-purple-900\/5 {
  border-left-color: rgb(88 28 135 / 0.05) !important;
  border-right-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-x-purple-900\/50 {
  border-left-color: rgb(88 28 135 / 0.5) !important;
  border-right-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-x-purple-900\/55 {
  border-left-color: rgb(88 28 135 / 0.55) !important;
  border-right-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-x-purple-900\/60 {
  border-left-color: rgb(88 28 135 / 0.6) !important;
  border-right-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-x-purple-900\/65 {
  border-left-color: rgb(88 28 135 / 0.65) !important;
  border-right-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-x-purple-900\/70 {
  border-left-color: rgb(88 28 135 / 0.7) !important;
  border-right-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-x-purple-900\/75 {
  border-left-color: rgb(88 28 135 / 0.75) !important;
  border-right-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-x-purple-900\/80 {
  border-left-color: rgb(88 28 135 / 0.8) !important;
  border-right-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-x-purple-900\/85 {
  border-left-color: rgb(88 28 135 / 0.85) !important;
  border-right-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-x-purple-900\/90 {
  border-left-color: rgb(88 28 135 / 0.9) !important;
  border-right-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-x-purple-900\/95 {
  border-left-color: rgb(88 28 135 / 0.95) !important;
  border-right-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-x-purple-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-x-purple-950\/0 {
  border-left-color: rgb(59 7 100 / 0) !important;
  border-right-color: rgb(59 7 100 / 0) !important;
}

.tw-border-x-purple-950\/10 {
  border-left-color: rgb(59 7 100 / 0.1) !important;
  border-right-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-x-purple-950\/100 {
  border-left-color: rgb(59 7 100 / 1) !important;
  border-right-color: rgb(59 7 100 / 1) !important;
}

.tw-border-x-purple-950\/15 {
  border-left-color: rgb(59 7 100 / 0.15) !important;
  border-right-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-x-purple-950\/20 {
  border-left-color: rgb(59 7 100 / 0.2) !important;
  border-right-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-x-purple-950\/25 {
  border-left-color: rgb(59 7 100 / 0.25) !important;
  border-right-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-x-purple-950\/30 {
  border-left-color: rgb(59 7 100 / 0.3) !important;
  border-right-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-x-purple-950\/35 {
  border-left-color: rgb(59 7 100 / 0.35) !important;
  border-right-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-x-purple-950\/40 {
  border-left-color: rgb(59 7 100 / 0.4) !important;
  border-right-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-x-purple-950\/45 {
  border-left-color: rgb(59 7 100 / 0.45) !important;
  border-right-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-x-purple-950\/5 {
  border-left-color: rgb(59 7 100 / 0.05) !important;
  border-right-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-x-purple-950\/50 {
  border-left-color: rgb(59 7 100 / 0.5) !important;
  border-right-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-x-purple-950\/55 {
  border-left-color: rgb(59 7 100 / 0.55) !important;
  border-right-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-x-purple-950\/60 {
  border-left-color: rgb(59 7 100 / 0.6) !important;
  border-right-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-x-purple-950\/65 {
  border-left-color: rgb(59 7 100 / 0.65) !important;
  border-right-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-x-purple-950\/70 {
  border-left-color: rgb(59 7 100 / 0.7) !important;
  border-right-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-x-purple-950\/75 {
  border-left-color: rgb(59 7 100 / 0.75) !important;
  border-right-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-x-purple-950\/80 {
  border-left-color: rgb(59 7 100 / 0.8) !important;
  border-right-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-x-purple-950\/85 {
  border-left-color: rgb(59 7 100 / 0.85) !important;
  border-right-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-x-purple-950\/90 {
  border-left-color: rgb(59 7 100 / 0.9) !important;
  border-right-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-x-purple-950\/95 {
  border-left-color: rgb(59 7 100 / 0.95) !important;
  border-right-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-x-red-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-100\/0 {
  border-left-color: rgb(254 226 226 / 0) !important;
  border-right-color: rgb(254 226 226 / 0) !important;
}

.tw-border-x-red-100\/10 {
  border-left-color: rgb(254 226 226 / 0.1) !important;
  border-right-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-x-red-100\/100 {
  border-left-color: rgb(254 226 226 / 1) !important;
  border-right-color: rgb(254 226 226 / 1) !important;
}

.tw-border-x-red-100\/15 {
  border-left-color: rgb(254 226 226 / 0.15) !important;
  border-right-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-x-red-100\/20 {
  border-left-color: rgb(254 226 226 / 0.2) !important;
  border-right-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-x-red-100\/25 {
  border-left-color: rgb(254 226 226 / 0.25) !important;
  border-right-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-x-red-100\/30 {
  border-left-color: rgb(254 226 226 / 0.3) !important;
  border-right-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-x-red-100\/35 {
  border-left-color: rgb(254 226 226 / 0.35) !important;
  border-right-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-x-red-100\/40 {
  border-left-color: rgb(254 226 226 / 0.4) !important;
  border-right-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-x-red-100\/45 {
  border-left-color: rgb(254 226 226 / 0.45) !important;
  border-right-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-x-red-100\/5 {
  border-left-color: rgb(254 226 226 / 0.05) !important;
  border-right-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-x-red-100\/50 {
  border-left-color: rgb(254 226 226 / 0.5) !important;
  border-right-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-x-red-100\/55 {
  border-left-color: rgb(254 226 226 / 0.55) !important;
  border-right-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-x-red-100\/60 {
  border-left-color: rgb(254 226 226 / 0.6) !important;
  border-right-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-x-red-100\/65 {
  border-left-color: rgb(254 226 226 / 0.65) !important;
  border-right-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-x-red-100\/70 {
  border-left-color: rgb(254 226 226 / 0.7) !important;
  border-right-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-x-red-100\/75 {
  border-left-color: rgb(254 226 226 / 0.75) !important;
  border-right-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-x-red-100\/80 {
  border-left-color: rgb(254 226 226 / 0.8) !important;
  border-right-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-x-red-100\/85 {
  border-left-color: rgb(254 226 226 / 0.85) !important;
  border-right-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-x-red-100\/90 {
  border-left-color: rgb(254 226 226 / 0.9) !important;
  border-right-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-x-red-100\/95 {
  border-left-color: rgb(254 226 226 / 0.95) !important;
  border-right-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-x-red-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-200\/0 {
  border-left-color: rgb(254 202 202 / 0) !important;
  border-right-color: rgb(254 202 202 / 0) !important;
}

.tw-border-x-red-200\/10 {
  border-left-color: rgb(254 202 202 / 0.1) !important;
  border-right-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-x-red-200\/100 {
  border-left-color: rgb(254 202 202 / 1) !important;
  border-right-color: rgb(254 202 202 / 1) !important;
}

.tw-border-x-red-200\/15 {
  border-left-color: rgb(254 202 202 / 0.15) !important;
  border-right-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-x-red-200\/20 {
  border-left-color: rgb(254 202 202 / 0.2) !important;
  border-right-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-x-red-200\/25 {
  border-left-color: rgb(254 202 202 / 0.25) !important;
  border-right-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-x-red-200\/30 {
  border-left-color: rgb(254 202 202 / 0.3) !important;
  border-right-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-x-red-200\/35 {
  border-left-color: rgb(254 202 202 / 0.35) !important;
  border-right-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-x-red-200\/40 {
  border-left-color: rgb(254 202 202 / 0.4) !important;
  border-right-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-x-red-200\/45 {
  border-left-color: rgb(254 202 202 / 0.45) !important;
  border-right-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-x-red-200\/5 {
  border-left-color: rgb(254 202 202 / 0.05) !important;
  border-right-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-x-red-200\/50 {
  border-left-color: rgb(254 202 202 / 0.5) !important;
  border-right-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-x-red-200\/55 {
  border-left-color: rgb(254 202 202 / 0.55) !important;
  border-right-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-x-red-200\/60 {
  border-left-color: rgb(254 202 202 / 0.6) !important;
  border-right-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-x-red-200\/65 {
  border-left-color: rgb(254 202 202 / 0.65) !important;
  border-right-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-x-red-200\/70 {
  border-left-color: rgb(254 202 202 / 0.7) !important;
  border-right-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-x-red-200\/75 {
  border-left-color: rgb(254 202 202 / 0.75) !important;
  border-right-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-x-red-200\/80 {
  border-left-color: rgb(254 202 202 / 0.8) !important;
  border-right-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-x-red-200\/85 {
  border-left-color: rgb(254 202 202 / 0.85) !important;
  border-right-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-x-red-200\/90 {
  border-left-color: rgb(254 202 202 / 0.9) !important;
  border-right-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-x-red-200\/95 {
  border-left-color: rgb(254 202 202 / 0.95) !important;
  border-right-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-x-red-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-300\/0 {
  border-left-color: rgb(252 165 165 / 0) !important;
  border-right-color: rgb(252 165 165 / 0) !important;
}

.tw-border-x-red-300\/10 {
  border-left-color: rgb(252 165 165 / 0.1) !important;
  border-right-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-x-red-300\/100 {
  border-left-color: rgb(252 165 165 / 1) !important;
  border-right-color: rgb(252 165 165 / 1) !important;
}

.tw-border-x-red-300\/15 {
  border-left-color: rgb(252 165 165 / 0.15) !important;
  border-right-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-x-red-300\/20 {
  border-left-color: rgb(252 165 165 / 0.2) !important;
  border-right-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-x-red-300\/25 {
  border-left-color: rgb(252 165 165 / 0.25) !important;
  border-right-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-x-red-300\/30 {
  border-left-color: rgb(252 165 165 / 0.3) !important;
  border-right-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-x-red-300\/35 {
  border-left-color: rgb(252 165 165 / 0.35) !important;
  border-right-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-x-red-300\/40 {
  border-left-color: rgb(252 165 165 / 0.4) !important;
  border-right-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-x-red-300\/45 {
  border-left-color: rgb(252 165 165 / 0.45) !important;
  border-right-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-x-red-300\/5 {
  border-left-color: rgb(252 165 165 / 0.05) !important;
  border-right-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-x-red-300\/50 {
  border-left-color: rgb(252 165 165 / 0.5) !important;
  border-right-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-x-red-300\/55 {
  border-left-color: rgb(252 165 165 / 0.55) !important;
  border-right-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-x-red-300\/60 {
  border-left-color: rgb(252 165 165 / 0.6) !important;
  border-right-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-x-red-300\/65 {
  border-left-color: rgb(252 165 165 / 0.65) !important;
  border-right-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-x-red-300\/70 {
  border-left-color: rgb(252 165 165 / 0.7) !important;
  border-right-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-x-red-300\/75 {
  border-left-color: rgb(252 165 165 / 0.75) !important;
  border-right-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-x-red-300\/80 {
  border-left-color: rgb(252 165 165 / 0.8) !important;
  border-right-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-x-red-300\/85 {
  border-left-color: rgb(252 165 165 / 0.85) !important;
  border-right-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-x-red-300\/90 {
  border-left-color: rgb(252 165 165 / 0.9) !important;
  border-right-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-x-red-300\/95 {
  border-left-color: rgb(252 165 165 / 0.95) !important;
  border-right-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-x-red-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-400\/0 {
  border-left-color: rgb(248 113 113 / 0) !important;
  border-right-color: rgb(248 113 113 / 0) !important;
}

.tw-border-x-red-400\/10 {
  border-left-color: rgb(248 113 113 / 0.1) !important;
  border-right-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-x-red-400\/100 {
  border-left-color: rgb(248 113 113 / 1) !important;
  border-right-color: rgb(248 113 113 / 1) !important;
}

.tw-border-x-red-400\/15 {
  border-left-color: rgb(248 113 113 / 0.15) !important;
  border-right-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-x-red-400\/20 {
  border-left-color: rgb(248 113 113 / 0.2) !important;
  border-right-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-x-red-400\/25 {
  border-left-color: rgb(248 113 113 / 0.25) !important;
  border-right-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-x-red-400\/30 {
  border-left-color: rgb(248 113 113 / 0.3) !important;
  border-right-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-x-red-400\/35 {
  border-left-color: rgb(248 113 113 / 0.35) !important;
  border-right-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-x-red-400\/40 {
  border-left-color: rgb(248 113 113 / 0.4) !important;
  border-right-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-x-red-400\/45 {
  border-left-color: rgb(248 113 113 / 0.45) !important;
  border-right-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-x-red-400\/5 {
  border-left-color: rgb(248 113 113 / 0.05) !important;
  border-right-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-x-red-400\/50 {
  border-left-color: rgb(248 113 113 / 0.5) !important;
  border-right-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-x-red-400\/55 {
  border-left-color: rgb(248 113 113 / 0.55) !important;
  border-right-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-x-red-400\/60 {
  border-left-color: rgb(248 113 113 / 0.6) !important;
  border-right-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-x-red-400\/65 {
  border-left-color: rgb(248 113 113 / 0.65) !important;
  border-right-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-x-red-400\/70 {
  border-left-color: rgb(248 113 113 / 0.7) !important;
  border-right-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-x-red-400\/75 {
  border-left-color: rgb(248 113 113 / 0.75) !important;
  border-right-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-x-red-400\/80 {
  border-left-color: rgb(248 113 113 / 0.8) !important;
  border-right-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-x-red-400\/85 {
  border-left-color: rgb(248 113 113 / 0.85) !important;
  border-right-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-x-red-400\/90 {
  border-left-color: rgb(248 113 113 / 0.9) !important;
  border-right-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-x-red-400\/95 {
  border-left-color: rgb(248 113 113 / 0.95) !important;
  border-right-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-x-red-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-50\/0 {
  border-left-color: rgb(254 242 242 / 0) !important;
  border-right-color: rgb(254 242 242 / 0) !important;
}

.tw-border-x-red-50\/10 {
  border-left-color: rgb(254 242 242 / 0.1) !important;
  border-right-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-x-red-50\/100 {
  border-left-color: rgb(254 242 242 / 1) !important;
  border-right-color: rgb(254 242 242 / 1) !important;
}

.tw-border-x-red-50\/15 {
  border-left-color: rgb(254 242 242 / 0.15) !important;
  border-right-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-x-red-50\/20 {
  border-left-color: rgb(254 242 242 / 0.2) !important;
  border-right-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-x-red-50\/25 {
  border-left-color: rgb(254 242 242 / 0.25) !important;
  border-right-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-x-red-50\/30 {
  border-left-color: rgb(254 242 242 / 0.3) !important;
  border-right-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-x-red-50\/35 {
  border-left-color: rgb(254 242 242 / 0.35) !important;
  border-right-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-x-red-50\/40 {
  border-left-color: rgb(254 242 242 / 0.4) !important;
  border-right-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-x-red-50\/45 {
  border-left-color: rgb(254 242 242 / 0.45) !important;
  border-right-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-x-red-50\/5 {
  border-left-color: rgb(254 242 242 / 0.05) !important;
  border-right-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-x-red-50\/50 {
  border-left-color: rgb(254 242 242 / 0.5) !important;
  border-right-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-x-red-50\/55 {
  border-left-color: rgb(254 242 242 / 0.55) !important;
  border-right-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-x-red-50\/60 {
  border-left-color: rgb(254 242 242 / 0.6) !important;
  border-right-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-x-red-50\/65 {
  border-left-color: rgb(254 242 242 / 0.65) !important;
  border-right-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-x-red-50\/70 {
  border-left-color: rgb(254 242 242 / 0.7) !important;
  border-right-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-x-red-50\/75 {
  border-left-color: rgb(254 242 242 / 0.75) !important;
  border-right-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-x-red-50\/80 {
  border-left-color: rgb(254 242 242 / 0.8) !important;
  border-right-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-x-red-50\/85 {
  border-left-color: rgb(254 242 242 / 0.85) !important;
  border-right-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-x-red-50\/90 {
  border-left-color: rgb(254 242 242 / 0.9) !important;
  border-right-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-x-red-50\/95 {
  border-left-color: rgb(254 242 242 / 0.95) !important;
  border-right-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-x-red-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-500\/0 {
  border-left-color: rgb(239 68 68 / 0) !important;
  border-right-color: rgb(239 68 68 / 0) !important;
}

.tw-border-x-red-500\/10 {
  border-left-color: rgb(239 68 68 / 0.1) !important;
  border-right-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-x-red-500\/100 {
  border-left-color: rgb(239 68 68 / 1) !important;
  border-right-color: rgb(239 68 68 / 1) !important;
}

.tw-border-x-red-500\/15 {
  border-left-color: rgb(239 68 68 / 0.15) !important;
  border-right-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-x-red-500\/20 {
  border-left-color: rgb(239 68 68 / 0.2) !important;
  border-right-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-x-red-500\/25 {
  border-left-color: rgb(239 68 68 / 0.25) !important;
  border-right-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-x-red-500\/30 {
  border-left-color: rgb(239 68 68 / 0.3) !important;
  border-right-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-x-red-500\/35 {
  border-left-color: rgb(239 68 68 / 0.35) !important;
  border-right-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-x-red-500\/40 {
  border-left-color: rgb(239 68 68 / 0.4) !important;
  border-right-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-x-red-500\/45 {
  border-left-color: rgb(239 68 68 / 0.45) !important;
  border-right-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-x-red-500\/5 {
  border-left-color: rgb(239 68 68 / 0.05) !important;
  border-right-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-x-red-500\/50 {
  border-left-color: rgb(239 68 68 / 0.5) !important;
  border-right-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-x-red-500\/55 {
  border-left-color: rgb(239 68 68 / 0.55) !important;
  border-right-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-x-red-500\/60 {
  border-left-color: rgb(239 68 68 / 0.6) !important;
  border-right-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-x-red-500\/65 {
  border-left-color: rgb(239 68 68 / 0.65) !important;
  border-right-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-x-red-500\/70 {
  border-left-color: rgb(239 68 68 / 0.7) !important;
  border-right-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-x-red-500\/75 {
  border-left-color: rgb(239 68 68 / 0.75) !important;
  border-right-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-x-red-500\/80 {
  border-left-color: rgb(239 68 68 / 0.8) !important;
  border-right-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-x-red-500\/85 {
  border-left-color: rgb(239 68 68 / 0.85) !important;
  border-right-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-x-red-500\/90 {
  border-left-color: rgb(239 68 68 / 0.9) !important;
  border-right-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-x-red-500\/95 {
  border-left-color: rgb(239 68 68 / 0.95) !important;
  border-right-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-x-red-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-600\/0 {
  border-left-color: rgb(220 38 38 / 0) !important;
  border-right-color: rgb(220 38 38 / 0) !important;
}

.tw-border-x-red-600\/10 {
  border-left-color: rgb(220 38 38 / 0.1) !important;
  border-right-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-x-red-600\/100 {
  border-left-color: rgb(220 38 38 / 1) !important;
  border-right-color: rgb(220 38 38 / 1) !important;
}

.tw-border-x-red-600\/15 {
  border-left-color: rgb(220 38 38 / 0.15) !important;
  border-right-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-x-red-600\/20 {
  border-left-color: rgb(220 38 38 / 0.2) !important;
  border-right-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-x-red-600\/25 {
  border-left-color: rgb(220 38 38 / 0.25) !important;
  border-right-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-x-red-600\/30 {
  border-left-color: rgb(220 38 38 / 0.3) !important;
  border-right-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-x-red-600\/35 {
  border-left-color: rgb(220 38 38 / 0.35) !important;
  border-right-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-x-red-600\/40 {
  border-left-color: rgb(220 38 38 / 0.4) !important;
  border-right-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-x-red-600\/45 {
  border-left-color: rgb(220 38 38 / 0.45) !important;
  border-right-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-x-red-600\/5 {
  border-left-color: rgb(220 38 38 / 0.05) !important;
  border-right-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-x-red-600\/50 {
  border-left-color: rgb(220 38 38 / 0.5) !important;
  border-right-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-x-red-600\/55 {
  border-left-color: rgb(220 38 38 / 0.55) !important;
  border-right-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-x-red-600\/60 {
  border-left-color: rgb(220 38 38 / 0.6) !important;
  border-right-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-x-red-600\/65 {
  border-left-color: rgb(220 38 38 / 0.65) !important;
  border-right-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-x-red-600\/70 {
  border-left-color: rgb(220 38 38 / 0.7) !important;
  border-right-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-x-red-600\/75 {
  border-left-color: rgb(220 38 38 / 0.75) !important;
  border-right-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-x-red-600\/80 {
  border-left-color: rgb(220 38 38 / 0.8) !important;
  border-right-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-x-red-600\/85 {
  border-left-color: rgb(220 38 38 / 0.85) !important;
  border-right-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-x-red-600\/90 {
  border-left-color: rgb(220 38 38 / 0.9) !important;
  border-right-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-x-red-600\/95 {
  border-left-color: rgb(220 38 38 / 0.95) !important;
  border-right-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-x-red-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-700\/0 {
  border-left-color: rgb(185 28 28 / 0) !important;
  border-right-color: rgb(185 28 28 / 0) !important;
}

.tw-border-x-red-700\/10 {
  border-left-color: rgb(185 28 28 / 0.1) !important;
  border-right-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-x-red-700\/100 {
  border-left-color: rgb(185 28 28 / 1) !important;
  border-right-color: rgb(185 28 28 / 1) !important;
}

.tw-border-x-red-700\/15 {
  border-left-color: rgb(185 28 28 / 0.15) !important;
  border-right-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-x-red-700\/20 {
  border-left-color: rgb(185 28 28 / 0.2) !important;
  border-right-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-x-red-700\/25 {
  border-left-color: rgb(185 28 28 / 0.25) !important;
  border-right-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-x-red-700\/30 {
  border-left-color: rgb(185 28 28 / 0.3) !important;
  border-right-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-x-red-700\/35 {
  border-left-color: rgb(185 28 28 / 0.35) !important;
  border-right-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-x-red-700\/40 {
  border-left-color: rgb(185 28 28 / 0.4) !important;
  border-right-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-x-red-700\/45 {
  border-left-color: rgb(185 28 28 / 0.45) !important;
  border-right-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-x-red-700\/5 {
  border-left-color: rgb(185 28 28 / 0.05) !important;
  border-right-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-x-red-700\/50 {
  border-left-color: rgb(185 28 28 / 0.5) !important;
  border-right-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-x-red-700\/55 {
  border-left-color: rgb(185 28 28 / 0.55) !important;
  border-right-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-x-red-700\/60 {
  border-left-color: rgb(185 28 28 / 0.6) !important;
  border-right-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-x-red-700\/65 {
  border-left-color: rgb(185 28 28 / 0.65) !important;
  border-right-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-x-red-700\/70 {
  border-left-color: rgb(185 28 28 / 0.7) !important;
  border-right-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-x-red-700\/75 {
  border-left-color: rgb(185 28 28 / 0.75) !important;
  border-right-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-x-red-700\/80 {
  border-left-color: rgb(185 28 28 / 0.8) !important;
  border-right-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-x-red-700\/85 {
  border-left-color: rgb(185 28 28 / 0.85) !important;
  border-right-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-x-red-700\/90 {
  border-left-color: rgb(185 28 28 / 0.9) !important;
  border-right-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-x-red-700\/95 {
  border-left-color: rgb(185 28 28 / 0.95) !important;
  border-right-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-x-red-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-800\/0 {
  border-left-color: rgb(153 27 27 / 0) !important;
  border-right-color: rgb(153 27 27 / 0) !important;
}

.tw-border-x-red-800\/10 {
  border-left-color: rgb(153 27 27 / 0.1) !important;
  border-right-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-x-red-800\/100 {
  border-left-color: rgb(153 27 27 / 1) !important;
  border-right-color: rgb(153 27 27 / 1) !important;
}

.tw-border-x-red-800\/15 {
  border-left-color: rgb(153 27 27 / 0.15) !important;
  border-right-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-x-red-800\/20 {
  border-left-color: rgb(153 27 27 / 0.2) !important;
  border-right-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-x-red-800\/25 {
  border-left-color: rgb(153 27 27 / 0.25) !important;
  border-right-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-x-red-800\/30 {
  border-left-color: rgb(153 27 27 / 0.3) !important;
  border-right-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-x-red-800\/35 {
  border-left-color: rgb(153 27 27 / 0.35) !important;
  border-right-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-x-red-800\/40 {
  border-left-color: rgb(153 27 27 / 0.4) !important;
  border-right-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-x-red-800\/45 {
  border-left-color: rgb(153 27 27 / 0.45) !important;
  border-right-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-x-red-800\/5 {
  border-left-color: rgb(153 27 27 / 0.05) !important;
  border-right-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-x-red-800\/50 {
  border-left-color: rgb(153 27 27 / 0.5) !important;
  border-right-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-x-red-800\/55 {
  border-left-color: rgb(153 27 27 / 0.55) !important;
  border-right-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-x-red-800\/60 {
  border-left-color: rgb(153 27 27 / 0.6) !important;
  border-right-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-x-red-800\/65 {
  border-left-color: rgb(153 27 27 / 0.65) !important;
  border-right-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-x-red-800\/70 {
  border-left-color: rgb(153 27 27 / 0.7) !important;
  border-right-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-x-red-800\/75 {
  border-left-color: rgb(153 27 27 / 0.75) !important;
  border-right-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-x-red-800\/80 {
  border-left-color: rgb(153 27 27 / 0.8) !important;
  border-right-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-x-red-800\/85 {
  border-left-color: rgb(153 27 27 / 0.85) !important;
  border-right-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-x-red-800\/90 {
  border-left-color: rgb(153 27 27 / 0.9) !important;
  border-right-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-x-red-800\/95 {
  border-left-color: rgb(153 27 27 / 0.95) !important;
  border-right-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-x-red-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-900\/0 {
  border-left-color: rgb(127 29 29 / 0) !important;
  border-right-color: rgb(127 29 29 / 0) !important;
}

.tw-border-x-red-900\/10 {
  border-left-color: rgb(127 29 29 / 0.1) !important;
  border-right-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-x-red-900\/100 {
  border-left-color: rgb(127 29 29 / 1) !important;
  border-right-color: rgb(127 29 29 / 1) !important;
}

.tw-border-x-red-900\/15 {
  border-left-color: rgb(127 29 29 / 0.15) !important;
  border-right-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-x-red-900\/20 {
  border-left-color: rgb(127 29 29 / 0.2) !important;
  border-right-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-x-red-900\/25 {
  border-left-color: rgb(127 29 29 / 0.25) !important;
  border-right-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-x-red-900\/30 {
  border-left-color: rgb(127 29 29 / 0.3) !important;
  border-right-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-x-red-900\/35 {
  border-left-color: rgb(127 29 29 / 0.35) !important;
  border-right-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-x-red-900\/40 {
  border-left-color: rgb(127 29 29 / 0.4) !important;
  border-right-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-x-red-900\/45 {
  border-left-color: rgb(127 29 29 / 0.45) !important;
  border-right-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-x-red-900\/5 {
  border-left-color: rgb(127 29 29 / 0.05) !important;
  border-right-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-x-red-900\/50 {
  border-left-color: rgb(127 29 29 / 0.5) !important;
  border-right-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-x-red-900\/55 {
  border-left-color: rgb(127 29 29 / 0.55) !important;
  border-right-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-x-red-900\/60 {
  border-left-color: rgb(127 29 29 / 0.6) !important;
  border-right-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-x-red-900\/65 {
  border-left-color: rgb(127 29 29 / 0.65) !important;
  border-right-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-x-red-900\/70 {
  border-left-color: rgb(127 29 29 / 0.7) !important;
  border-right-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-x-red-900\/75 {
  border-left-color: rgb(127 29 29 / 0.75) !important;
  border-right-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-x-red-900\/80 {
  border-left-color: rgb(127 29 29 / 0.8) !important;
  border-right-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-x-red-900\/85 {
  border-left-color: rgb(127 29 29 / 0.85) !important;
  border-right-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-x-red-900\/90 {
  border-left-color: rgb(127 29 29 / 0.9) !important;
  border-right-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-x-red-900\/95 {
  border-left-color: rgb(127 29 29 / 0.95) !important;
  border-right-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-x-red-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-x-red-950\/0 {
  border-left-color: rgb(69 10 10 / 0) !important;
  border-right-color: rgb(69 10 10 / 0) !important;
}

.tw-border-x-red-950\/10 {
  border-left-color: rgb(69 10 10 / 0.1) !important;
  border-right-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-x-red-950\/100 {
  border-left-color: rgb(69 10 10 / 1) !important;
  border-right-color: rgb(69 10 10 / 1) !important;
}

.tw-border-x-red-950\/15 {
  border-left-color: rgb(69 10 10 / 0.15) !important;
  border-right-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-x-red-950\/20 {
  border-left-color: rgb(69 10 10 / 0.2) !important;
  border-right-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-x-red-950\/25 {
  border-left-color: rgb(69 10 10 / 0.25) !important;
  border-right-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-x-red-950\/30 {
  border-left-color: rgb(69 10 10 / 0.3) !important;
  border-right-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-x-red-950\/35 {
  border-left-color: rgb(69 10 10 / 0.35) !important;
  border-right-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-x-red-950\/40 {
  border-left-color: rgb(69 10 10 / 0.4) !important;
  border-right-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-x-red-950\/45 {
  border-left-color: rgb(69 10 10 / 0.45) !important;
  border-right-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-x-red-950\/5 {
  border-left-color: rgb(69 10 10 / 0.05) !important;
  border-right-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-x-red-950\/50 {
  border-left-color: rgb(69 10 10 / 0.5) !important;
  border-right-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-x-red-950\/55 {
  border-left-color: rgb(69 10 10 / 0.55) !important;
  border-right-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-x-red-950\/60 {
  border-left-color: rgb(69 10 10 / 0.6) !important;
  border-right-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-x-red-950\/65 {
  border-left-color: rgb(69 10 10 / 0.65) !important;
  border-right-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-x-red-950\/70 {
  border-left-color: rgb(69 10 10 / 0.7) !important;
  border-right-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-x-red-950\/75 {
  border-left-color: rgb(69 10 10 / 0.75) !important;
  border-right-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-x-red-950\/80 {
  border-left-color: rgb(69 10 10 / 0.8) !important;
  border-right-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-x-red-950\/85 {
  border-left-color: rgb(69 10 10 / 0.85) !important;
  border-right-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-x-red-950\/90 {
  border-left-color: rgb(69 10 10 / 0.9) !important;
  border-right-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-x-red-950\/95 {
  border-left-color: rgb(69 10 10 / 0.95) !important;
  border-right-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-x-rose-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-100\/0 {
  border-left-color: rgb(255 228 230 / 0) !important;
  border-right-color: rgb(255 228 230 / 0) !important;
}

.tw-border-x-rose-100\/10 {
  border-left-color: rgb(255 228 230 / 0.1) !important;
  border-right-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-x-rose-100\/100 {
  border-left-color: rgb(255 228 230 / 1) !important;
  border-right-color: rgb(255 228 230 / 1) !important;
}

.tw-border-x-rose-100\/15 {
  border-left-color: rgb(255 228 230 / 0.15) !important;
  border-right-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-x-rose-100\/20 {
  border-left-color: rgb(255 228 230 / 0.2) !important;
  border-right-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-x-rose-100\/25 {
  border-left-color: rgb(255 228 230 / 0.25) !important;
  border-right-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-x-rose-100\/30 {
  border-left-color: rgb(255 228 230 / 0.3) !important;
  border-right-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-x-rose-100\/35 {
  border-left-color: rgb(255 228 230 / 0.35) !important;
  border-right-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-x-rose-100\/40 {
  border-left-color: rgb(255 228 230 / 0.4) !important;
  border-right-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-x-rose-100\/45 {
  border-left-color: rgb(255 228 230 / 0.45) !important;
  border-right-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-x-rose-100\/5 {
  border-left-color: rgb(255 228 230 / 0.05) !important;
  border-right-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-x-rose-100\/50 {
  border-left-color: rgb(255 228 230 / 0.5) !important;
  border-right-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-x-rose-100\/55 {
  border-left-color: rgb(255 228 230 / 0.55) !important;
  border-right-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-x-rose-100\/60 {
  border-left-color: rgb(255 228 230 / 0.6) !important;
  border-right-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-x-rose-100\/65 {
  border-left-color: rgb(255 228 230 / 0.65) !important;
  border-right-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-x-rose-100\/70 {
  border-left-color: rgb(255 228 230 / 0.7) !important;
  border-right-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-x-rose-100\/75 {
  border-left-color: rgb(255 228 230 / 0.75) !important;
  border-right-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-x-rose-100\/80 {
  border-left-color: rgb(255 228 230 / 0.8) !important;
  border-right-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-x-rose-100\/85 {
  border-left-color: rgb(255 228 230 / 0.85) !important;
  border-right-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-x-rose-100\/90 {
  border-left-color: rgb(255 228 230 / 0.9) !important;
  border-right-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-x-rose-100\/95 {
  border-left-color: rgb(255 228 230 / 0.95) !important;
  border-right-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-x-rose-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-200\/0 {
  border-left-color: rgb(254 205 211 / 0) !important;
  border-right-color: rgb(254 205 211 / 0) !important;
}

.tw-border-x-rose-200\/10 {
  border-left-color: rgb(254 205 211 / 0.1) !important;
  border-right-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-x-rose-200\/100 {
  border-left-color: rgb(254 205 211 / 1) !important;
  border-right-color: rgb(254 205 211 / 1) !important;
}

.tw-border-x-rose-200\/15 {
  border-left-color: rgb(254 205 211 / 0.15) !important;
  border-right-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-x-rose-200\/20 {
  border-left-color: rgb(254 205 211 / 0.2) !important;
  border-right-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-x-rose-200\/25 {
  border-left-color: rgb(254 205 211 / 0.25) !important;
  border-right-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-x-rose-200\/30 {
  border-left-color: rgb(254 205 211 / 0.3) !important;
  border-right-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-x-rose-200\/35 {
  border-left-color: rgb(254 205 211 / 0.35) !important;
  border-right-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-x-rose-200\/40 {
  border-left-color: rgb(254 205 211 / 0.4) !important;
  border-right-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-x-rose-200\/45 {
  border-left-color: rgb(254 205 211 / 0.45) !important;
  border-right-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-x-rose-200\/5 {
  border-left-color: rgb(254 205 211 / 0.05) !important;
  border-right-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-x-rose-200\/50 {
  border-left-color: rgb(254 205 211 / 0.5) !important;
  border-right-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-x-rose-200\/55 {
  border-left-color: rgb(254 205 211 / 0.55) !important;
  border-right-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-x-rose-200\/60 {
  border-left-color: rgb(254 205 211 / 0.6) !important;
  border-right-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-x-rose-200\/65 {
  border-left-color: rgb(254 205 211 / 0.65) !important;
  border-right-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-x-rose-200\/70 {
  border-left-color: rgb(254 205 211 / 0.7) !important;
  border-right-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-x-rose-200\/75 {
  border-left-color: rgb(254 205 211 / 0.75) !important;
  border-right-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-x-rose-200\/80 {
  border-left-color: rgb(254 205 211 / 0.8) !important;
  border-right-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-x-rose-200\/85 {
  border-left-color: rgb(254 205 211 / 0.85) !important;
  border-right-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-x-rose-200\/90 {
  border-left-color: rgb(254 205 211 / 0.9) !important;
  border-right-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-x-rose-200\/95 {
  border-left-color: rgb(254 205 211 / 0.95) !important;
  border-right-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-x-rose-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-300\/0 {
  border-left-color: rgb(253 164 175 / 0) !important;
  border-right-color: rgb(253 164 175 / 0) !important;
}

.tw-border-x-rose-300\/10 {
  border-left-color: rgb(253 164 175 / 0.1) !important;
  border-right-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-x-rose-300\/100 {
  border-left-color: rgb(253 164 175 / 1) !important;
  border-right-color: rgb(253 164 175 / 1) !important;
}

.tw-border-x-rose-300\/15 {
  border-left-color: rgb(253 164 175 / 0.15) !important;
  border-right-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-x-rose-300\/20 {
  border-left-color: rgb(253 164 175 / 0.2) !important;
  border-right-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-x-rose-300\/25 {
  border-left-color: rgb(253 164 175 / 0.25) !important;
  border-right-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-x-rose-300\/30 {
  border-left-color: rgb(253 164 175 / 0.3) !important;
  border-right-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-x-rose-300\/35 {
  border-left-color: rgb(253 164 175 / 0.35) !important;
  border-right-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-x-rose-300\/40 {
  border-left-color: rgb(253 164 175 / 0.4) !important;
  border-right-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-x-rose-300\/45 {
  border-left-color: rgb(253 164 175 / 0.45) !important;
  border-right-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-x-rose-300\/5 {
  border-left-color: rgb(253 164 175 / 0.05) !important;
  border-right-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-x-rose-300\/50 {
  border-left-color: rgb(253 164 175 / 0.5) !important;
  border-right-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-x-rose-300\/55 {
  border-left-color: rgb(253 164 175 / 0.55) !important;
  border-right-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-x-rose-300\/60 {
  border-left-color: rgb(253 164 175 / 0.6) !important;
  border-right-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-x-rose-300\/65 {
  border-left-color: rgb(253 164 175 / 0.65) !important;
  border-right-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-x-rose-300\/70 {
  border-left-color: rgb(253 164 175 / 0.7) !important;
  border-right-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-x-rose-300\/75 {
  border-left-color: rgb(253 164 175 / 0.75) !important;
  border-right-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-x-rose-300\/80 {
  border-left-color: rgb(253 164 175 / 0.8) !important;
  border-right-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-x-rose-300\/85 {
  border-left-color: rgb(253 164 175 / 0.85) !important;
  border-right-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-x-rose-300\/90 {
  border-left-color: rgb(253 164 175 / 0.9) !important;
  border-right-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-x-rose-300\/95 {
  border-left-color: rgb(253 164 175 / 0.95) !important;
  border-right-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-x-rose-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-400\/0 {
  border-left-color: rgb(251 113 133 / 0) !important;
  border-right-color: rgb(251 113 133 / 0) !important;
}

.tw-border-x-rose-400\/10 {
  border-left-color: rgb(251 113 133 / 0.1) !important;
  border-right-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-x-rose-400\/100 {
  border-left-color: rgb(251 113 133 / 1) !important;
  border-right-color: rgb(251 113 133 / 1) !important;
}

.tw-border-x-rose-400\/15 {
  border-left-color: rgb(251 113 133 / 0.15) !important;
  border-right-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-x-rose-400\/20 {
  border-left-color: rgb(251 113 133 / 0.2) !important;
  border-right-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-x-rose-400\/25 {
  border-left-color: rgb(251 113 133 / 0.25) !important;
  border-right-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-x-rose-400\/30 {
  border-left-color: rgb(251 113 133 / 0.3) !important;
  border-right-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-x-rose-400\/35 {
  border-left-color: rgb(251 113 133 / 0.35) !important;
  border-right-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-x-rose-400\/40 {
  border-left-color: rgb(251 113 133 / 0.4) !important;
  border-right-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-x-rose-400\/45 {
  border-left-color: rgb(251 113 133 / 0.45) !important;
  border-right-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-x-rose-400\/5 {
  border-left-color: rgb(251 113 133 / 0.05) !important;
  border-right-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-x-rose-400\/50 {
  border-left-color: rgb(251 113 133 / 0.5) !important;
  border-right-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-x-rose-400\/55 {
  border-left-color: rgb(251 113 133 / 0.55) !important;
  border-right-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-x-rose-400\/60 {
  border-left-color: rgb(251 113 133 / 0.6) !important;
  border-right-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-x-rose-400\/65 {
  border-left-color: rgb(251 113 133 / 0.65) !important;
  border-right-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-x-rose-400\/70 {
  border-left-color: rgb(251 113 133 / 0.7) !important;
  border-right-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-x-rose-400\/75 {
  border-left-color: rgb(251 113 133 / 0.75) !important;
  border-right-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-x-rose-400\/80 {
  border-left-color: rgb(251 113 133 / 0.8) !important;
  border-right-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-x-rose-400\/85 {
  border-left-color: rgb(251 113 133 / 0.85) !important;
  border-right-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-x-rose-400\/90 {
  border-left-color: rgb(251 113 133 / 0.9) !important;
  border-right-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-x-rose-400\/95 {
  border-left-color: rgb(251 113 133 / 0.95) !important;
  border-right-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-x-rose-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-50\/0 {
  border-left-color: rgb(255 241 242 / 0) !important;
  border-right-color: rgb(255 241 242 / 0) !important;
}

.tw-border-x-rose-50\/10 {
  border-left-color: rgb(255 241 242 / 0.1) !important;
  border-right-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-x-rose-50\/100 {
  border-left-color: rgb(255 241 242 / 1) !important;
  border-right-color: rgb(255 241 242 / 1) !important;
}

.tw-border-x-rose-50\/15 {
  border-left-color: rgb(255 241 242 / 0.15) !important;
  border-right-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-x-rose-50\/20 {
  border-left-color: rgb(255 241 242 / 0.2) !important;
  border-right-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-x-rose-50\/25 {
  border-left-color: rgb(255 241 242 / 0.25) !important;
  border-right-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-x-rose-50\/30 {
  border-left-color: rgb(255 241 242 / 0.3) !important;
  border-right-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-x-rose-50\/35 {
  border-left-color: rgb(255 241 242 / 0.35) !important;
  border-right-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-x-rose-50\/40 {
  border-left-color: rgb(255 241 242 / 0.4) !important;
  border-right-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-x-rose-50\/45 {
  border-left-color: rgb(255 241 242 / 0.45) !important;
  border-right-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-x-rose-50\/5 {
  border-left-color: rgb(255 241 242 / 0.05) !important;
  border-right-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-x-rose-50\/50 {
  border-left-color: rgb(255 241 242 / 0.5) !important;
  border-right-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-x-rose-50\/55 {
  border-left-color: rgb(255 241 242 / 0.55) !important;
  border-right-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-x-rose-50\/60 {
  border-left-color: rgb(255 241 242 / 0.6) !important;
  border-right-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-x-rose-50\/65 {
  border-left-color: rgb(255 241 242 / 0.65) !important;
  border-right-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-x-rose-50\/70 {
  border-left-color: rgb(255 241 242 / 0.7) !important;
  border-right-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-x-rose-50\/75 {
  border-left-color: rgb(255 241 242 / 0.75) !important;
  border-right-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-x-rose-50\/80 {
  border-left-color: rgb(255 241 242 / 0.8) !important;
  border-right-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-x-rose-50\/85 {
  border-left-color: rgb(255 241 242 / 0.85) !important;
  border-right-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-x-rose-50\/90 {
  border-left-color: rgb(255 241 242 / 0.9) !important;
  border-right-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-x-rose-50\/95 {
  border-left-color: rgb(255 241 242 / 0.95) !important;
  border-right-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-x-rose-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-500\/0 {
  border-left-color: rgb(244 63 94 / 0) !important;
  border-right-color: rgb(244 63 94 / 0) !important;
}

.tw-border-x-rose-500\/10 {
  border-left-color: rgb(244 63 94 / 0.1) !important;
  border-right-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-x-rose-500\/100 {
  border-left-color: rgb(244 63 94 / 1) !important;
  border-right-color: rgb(244 63 94 / 1) !important;
}

.tw-border-x-rose-500\/15 {
  border-left-color: rgb(244 63 94 / 0.15) !important;
  border-right-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-x-rose-500\/20 {
  border-left-color: rgb(244 63 94 / 0.2) !important;
  border-right-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-x-rose-500\/25 {
  border-left-color: rgb(244 63 94 / 0.25) !important;
  border-right-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-x-rose-500\/30 {
  border-left-color: rgb(244 63 94 / 0.3) !important;
  border-right-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-x-rose-500\/35 {
  border-left-color: rgb(244 63 94 / 0.35) !important;
  border-right-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-x-rose-500\/40 {
  border-left-color: rgb(244 63 94 / 0.4) !important;
  border-right-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-x-rose-500\/45 {
  border-left-color: rgb(244 63 94 / 0.45) !important;
  border-right-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-x-rose-500\/5 {
  border-left-color: rgb(244 63 94 / 0.05) !important;
  border-right-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-x-rose-500\/50 {
  border-left-color: rgb(244 63 94 / 0.5) !important;
  border-right-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-x-rose-500\/55 {
  border-left-color: rgb(244 63 94 / 0.55) !important;
  border-right-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-x-rose-500\/60 {
  border-left-color: rgb(244 63 94 / 0.6) !important;
  border-right-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-x-rose-500\/65 {
  border-left-color: rgb(244 63 94 / 0.65) !important;
  border-right-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-x-rose-500\/70 {
  border-left-color: rgb(244 63 94 / 0.7) !important;
  border-right-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-x-rose-500\/75 {
  border-left-color: rgb(244 63 94 / 0.75) !important;
  border-right-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-x-rose-500\/80 {
  border-left-color: rgb(244 63 94 / 0.8) !important;
  border-right-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-x-rose-500\/85 {
  border-left-color: rgb(244 63 94 / 0.85) !important;
  border-right-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-x-rose-500\/90 {
  border-left-color: rgb(244 63 94 / 0.9) !important;
  border-right-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-x-rose-500\/95 {
  border-left-color: rgb(244 63 94 / 0.95) !important;
  border-right-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-x-rose-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-600\/0 {
  border-left-color: rgb(225 29 72 / 0) !important;
  border-right-color: rgb(225 29 72 / 0) !important;
}

.tw-border-x-rose-600\/10 {
  border-left-color: rgb(225 29 72 / 0.1) !important;
  border-right-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-x-rose-600\/100 {
  border-left-color: rgb(225 29 72 / 1) !important;
  border-right-color: rgb(225 29 72 / 1) !important;
}

.tw-border-x-rose-600\/15 {
  border-left-color: rgb(225 29 72 / 0.15) !important;
  border-right-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-x-rose-600\/20 {
  border-left-color: rgb(225 29 72 / 0.2) !important;
  border-right-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-x-rose-600\/25 {
  border-left-color: rgb(225 29 72 / 0.25) !important;
  border-right-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-x-rose-600\/30 {
  border-left-color: rgb(225 29 72 / 0.3) !important;
  border-right-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-x-rose-600\/35 {
  border-left-color: rgb(225 29 72 / 0.35) !important;
  border-right-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-x-rose-600\/40 {
  border-left-color: rgb(225 29 72 / 0.4) !important;
  border-right-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-x-rose-600\/45 {
  border-left-color: rgb(225 29 72 / 0.45) !important;
  border-right-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-x-rose-600\/5 {
  border-left-color: rgb(225 29 72 / 0.05) !important;
  border-right-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-x-rose-600\/50 {
  border-left-color: rgb(225 29 72 / 0.5) !important;
  border-right-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-x-rose-600\/55 {
  border-left-color: rgb(225 29 72 / 0.55) !important;
  border-right-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-x-rose-600\/60 {
  border-left-color: rgb(225 29 72 / 0.6) !important;
  border-right-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-x-rose-600\/65 {
  border-left-color: rgb(225 29 72 / 0.65) !important;
  border-right-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-x-rose-600\/70 {
  border-left-color: rgb(225 29 72 / 0.7) !important;
  border-right-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-x-rose-600\/75 {
  border-left-color: rgb(225 29 72 / 0.75) !important;
  border-right-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-x-rose-600\/80 {
  border-left-color: rgb(225 29 72 / 0.8) !important;
  border-right-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-x-rose-600\/85 {
  border-left-color: rgb(225 29 72 / 0.85) !important;
  border-right-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-x-rose-600\/90 {
  border-left-color: rgb(225 29 72 / 0.9) !important;
  border-right-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-x-rose-600\/95 {
  border-left-color: rgb(225 29 72 / 0.95) !important;
  border-right-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-x-rose-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-700\/0 {
  border-left-color: rgb(190 18 60 / 0) !important;
  border-right-color: rgb(190 18 60 / 0) !important;
}

.tw-border-x-rose-700\/10 {
  border-left-color: rgb(190 18 60 / 0.1) !important;
  border-right-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-x-rose-700\/100 {
  border-left-color: rgb(190 18 60 / 1) !important;
  border-right-color: rgb(190 18 60 / 1) !important;
}

.tw-border-x-rose-700\/15 {
  border-left-color: rgb(190 18 60 / 0.15) !important;
  border-right-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-x-rose-700\/20 {
  border-left-color: rgb(190 18 60 / 0.2) !important;
  border-right-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-x-rose-700\/25 {
  border-left-color: rgb(190 18 60 / 0.25) !important;
  border-right-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-x-rose-700\/30 {
  border-left-color: rgb(190 18 60 / 0.3) !important;
  border-right-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-x-rose-700\/35 {
  border-left-color: rgb(190 18 60 / 0.35) !important;
  border-right-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-x-rose-700\/40 {
  border-left-color: rgb(190 18 60 / 0.4) !important;
  border-right-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-x-rose-700\/45 {
  border-left-color: rgb(190 18 60 / 0.45) !important;
  border-right-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-x-rose-700\/5 {
  border-left-color: rgb(190 18 60 / 0.05) !important;
  border-right-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-x-rose-700\/50 {
  border-left-color: rgb(190 18 60 / 0.5) !important;
  border-right-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-x-rose-700\/55 {
  border-left-color: rgb(190 18 60 / 0.55) !important;
  border-right-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-x-rose-700\/60 {
  border-left-color: rgb(190 18 60 / 0.6) !important;
  border-right-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-x-rose-700\/65 {
  border-left-color: rgb(190 18 60 / 0.65) !important;
  border-right-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-x-rose-700\/70 {
  border-left-color: rgb(190 18 60 / 0.7) !important;
  border-right-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-x-rose-700\/75 {
  border-left-color: rgb(190 18 60 / 0.75) !important;
  border-right-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-x-rose-700\/80 {
  border-left-color: rgb(190 18 60 / 0.8) !important;
  border-right-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-x-rose-700\/85 {
  border-left-color: rgb(190 18 60 / 0.85) !important;
  border-right-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-x-rose-700\/90 {
  border-left-color: rgb(190 18 60 / 0.9) !important;
  border-right-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-x-rose-700\/95 {
  border-left-color: rgb(190 18 60 / 0.95) !important;
  border-right-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-x-rose-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-800\/0 {
  border-left-color: rgb(159 18 57 / 0) !important;
  border-right-color: rgb(159 18 57 / 0) !important;
}

.tw-border-x-rose-800\/10 {
  border-left-color: rgb(159 18 57 / 0.1) !important;
  border-right-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-x-rose-800\/100 {
  border-left-color: rgb(159 18 57 / 1) !important;
  border-right-color: rgb(159 18 57 / 1) !important;
}

.tw-border-x-rose-800\/15 {
  border-left-color: rgb(159 18 57 / 0.15) !important;
  border-right-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-x-rose-800\/20 {
  border-left-color: rgb(159 18 57 / 0.2) !important;
  border-right-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-x-rose-800\/25 {
  border-left-color: rgb(159 18 57 / 0.25) !important;
  border-right-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-x-rose-800\/30 {
  border-left-color: rgb(159 18 57 / 0.3) !important;
  border-right-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-x-rose-800\/35 {
  border-left-color: rgb(159 18 57 / 0.35) !important;
  border-right-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-x-rose-800\/40 {
  border-left-color: rgb(159 18 57 / 0.4) !important;
  border-right-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-x-rose-800\/45 {
  border-left-color: rgb(159 18 57 / 0.45) !important;
  border-right-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-x-rose-800\/5 {
  border-left-color: rgb(159 18 57 / 0.05) !important;
  border-right-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-x-rose-800\/50 {
  border-left-color: rgb(159 18 57 / 0.5) !important;
  border-right-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-x-rose-800\/55 {
  border-left-color: rgb(159 18 57 / 0.55) !important;
  border-right-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-x-rose-800\/60 {
  border-left-color: rgb(159 18 57 / 0.6) !important;
  border-right-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-x-rose-800\/65 {
  border-left-color: rgb(159 18 57 / 0.65) !important;
  border-right-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-x-rose-800\/70 {
  border-left-color: rgb(159 18 57 / 0.7) !important;
  border-right-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-x-rose-800\/75 {
  border-left-color: rgb(159 18 57 / 0.75) !important;
  border-right-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-x-rose-800\/80 {
  border-left-color: rgb(159 18 57 / 0.8) !important;
  border-right-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-x-rose-800\/85 {
  border-left-color: rgb(159 18 57 / 0.85) !important;
  border-right-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-x-rose-800\/90 {
  border-left-color: rgb(159 18 57 / 0.9) !important;
  border-right-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-x-rose-800\/95 {
  border-left-color: rgb(159 18 57 / 0.95) !important;
  border-right-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-x-rose-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-900\/0 {
  border-left-color: rgb(136 19 55 / 0) !important;
  border-right-color: rgb(136 19 55 / 0) !important;
}

.tw-border-x-rose-900\/10 {
  border-left-color: rgb(136 19 55 / 0.1) !important;
  border-right-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-x-rose-900\/100 {
  border-left-color: rgb(136 19 55 / 1) !important;
  border-right-color: rgb(136 19 55 / 1) !important;
}

.tw-border-x-rose-900\/15 {
  border-left-color: rgb(136 19 55 / 0.15) !important;
  border-right-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-x-rose-900\/20 {
  border-left-color: rgb(136 19 55 / 0.2) !important;
  border-right-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-x-rose-900\/25 {
  border-left-color: rgb(136 19 55 / 0.25) !important;
  border-right-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-x-rose-900\/30 {
  border-left-color: rgb(136 19 55 / 0.3) !important;
  border-right-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-x-rose-900\/35 {
  border-left-color: rgb(136 19 55 / 0.35) !important;
  border-right-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-x-rose-900\/40 {
  border-left-color: rgb(136 19 55 / 0.4) !important;
  border-right-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-x-rose-900\/45 {
  border-left-color: rgb(136 19 55 / 0.45) !important;
  border-right-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-x-rose-900\/5 {
  border-left-color: rgb(136 19 55 / 0.05) !important;
  border-right-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-x-rose-900\/50 {
  border-left-color: rgb(136 19 55 / 0.5) !important;
  border-right-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-x-rose-900\/55 {
  border-left-color: rgb(136 19 55 / 0.55) !important;
  border-right-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-x-rose-900\/60 {
  border-left-color: rgb(136 19 55 / 0.6) !important;
  border-right-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-x-rose-900\/65 {
  border-left-color: rgb(136 19 55 / 0.65) !important;
  border-right-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-x-rose-900\/70 {
  border-left-color: rgb(136 19 55 / 0.7) !important;
  border-right-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-x-rose-900\/75 {
  border-left-color: rgb(136 19 55 / 0.75) !important;
  border-right-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-x-rose-900\/80 {
  border-left-color: rgb(136 19 55 / 0.8) !important;
  border-right-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-x-rose-900\/85 {
  border-left-color: rgb(136 19 55 / 0.85) !important;
  border-right-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-x-rose-900\/90 {
  border-left-color: rgb(136 19 55 / 0.9) !important;
  border-right-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-x-rose-900\/95 {
  border-left-color: rgb(136 19 55 / 0.95) !important;
  border-right-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-x-rose-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-x-rose-950\/0 {
  border-left-color: rgb(76 5 25 / 0) !important;
  border-right-color: rgb(76 5 25 / 0) !important;
}

.tw-border-x-rose-950\/10 {
  border-left-color: rgb(76 5 25 / 0.1) !important;
  border-right-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-x-rose-950\/100 {
  border-left-color: rgb(76 5 25 / 1) !important;
  border-right-color: rgb(76 5 25 / 1) !important;
}

.tw-border-x-rose-950\/15 {
  border-left-color: rgb(76 5 25 / 0.15) !important;
  border-right-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-x-rose-950\/20 {
  border-left-color: rgb(76 5 25 / 0.2) !important;
  border-right-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-x-rose-950\/25 {
  border-left-color: rgb(76 5 25 / 0.25) !important;
  border-right-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-x-rose-950\/30 {
  border-left-color: rgb(76 5 25 / 0.3) !important;
  border-right-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-x-rose-950\/35 {
  border-left-color: rgb(76 5 25 / 0.35) !important;
  border-right-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-x-rose-950\/40 {
  border-left-color: rgb(76 5 25 / 0.4) !important;
  border-right-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-x-rose-950\/45 {
  border-left-color: rgb(76 5 25 / 0.45) !important;
  border-right-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-x-rose-950\/5 {
  border-left-color: rgb(76 5 25 / 0.05) !important;
  border-right-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-x-rose-950\/50 {
  border-left-color: rgb(76 5 25 / 0.5) !important;
  border-right-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-x-rose-950\/55 {
  border-left-color: rgb(76 5 25 / 0.55) !important;
  border-right-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-x-rose-950\/60 {
  border-left-color: rgb(76 5 25 / 0.6) !important;
  border-right-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-x-rose-950\/65 {
  border-left-color: rgb(76 5 25 / 0.65) !important;
  border-right-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-x-rose-950\/70 {
  border-left-color: rgb(76 5 25 / 0.7) !important;
  border-right-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-x-rose-950\/75 {
  border-left-color: rgb(76 5 25 / 0.75) !important;
  border-right-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-x-rose-950\/80 {
  border-left-color: rgb(76 5 25 / 0.8) !important;
  border-right-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-x-rose-950\/85 {
  border-left-color: rgb(76 5 25 / 0.85) !important;
  border-right-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-x-rose-950\/90 {
  border-left-color: rgb(76 5 25 / 0.9) !important;
  border-right-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-x-rose-950\/95 {
  border-left-color: rgb(76 5 25 / 0.95) !important;
  border-right-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-x-sky-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-100\/0 {
  border-left-color: rgb(224 242 254 / 0) !important;
  border-right-color: rgb(224 242 254 / 0) !important;
}

.tw-border-x-sky-100\/10 {
  border-left-color: rgb(224 242 254 / 0.1) !important;
  border-right-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-x-sky-100\/100 {
  border-left-color: rgb(224 242 254 / 1) !important;
  border-right-color: rgb(224 242 254 / 1) !important;
}

.tw-border-x-sky-100\/15 {
  border-left-color: rgb(224 242 254 / 0.15) !important;
  border-right-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-x-sky-100\/20 {
  border-left-color: rgb(224 242 254 / 0.2) !important;
  border-right-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-x-sky-100\/25 {
  border-left-color: rgb(224 242 254 / 0.25) !important;
  border-right-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-x-sky-100\/30 {
  border-left-color: rgb(224 242 254 / 0.3) !important;
  border-right-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-x-sky-100\/35 {
  border-left-color: rgb(224 242 254 / 0.35) !important;
  border-right-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-x-sky-100\/40 {
  border-left-color: rgb(224 242 254 / 0.4) !important;
  border-right-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-x-sky-100\/45 {
  border-left-color: rgb(224 242 254 / 0.45) !important;
  border-right-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-x-sky-100\/5 {
  border-left-color: rgb(224 242 254 / 0.05) !important;
  border-right-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-x-sky-100\/50 {
  border-left-color: rgb(224 242 254 / 0.5) !important;
  border-right-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-x-sky-100\/55 {
  border-left-color: rgb(224 242 254 / 0.55) !important;
  border-right-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-x-sky-100\/60 {
  border-left-color: rgb(224 242 254 / 0.6) !important;
  border-right-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-x-sky-100\/65 {
  border-left-color: rgb(224 242 254 / 0.65) !important;
  border-right-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-x-sky-100\/70 {
  border-left-color: rgb(224 242 254 / 0.7) !important;
  border-right-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-x-sky-100\/75 {
  border-left-color: rgb(224 242 254 / 0.75) !important;
  border-right-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-x-sky-100\/80 {
  border-left-color: rgb(224 242 254 / 0.8) !important;
  border-right-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-x-sky-100\/85 {
  border-left-color: rgb(224 242 254 / 0.85) !important;
  border-right-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-x-sky-100\/90 {
  border-left-color: rgb(224 242 254 / 0.9) !important;
  border-right-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-x-sky-100\/95 {
  border-left-color: rgb(224 242 254 / 0.95) !important;
  border-right-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-x-sky-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-200\/0 {
  border-left-color: rgb(186 230 253 / 0) !important;
  border-right-color: rgb(186 230 253 / 0) !important;
}

.tw-border-x-sky-200\/10 {
  border-left-color: rgb(186 230 253 / 0.1) !important;
  border-right-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-x-sky-200\/100 {
  border-left-color: rgb(186 230 253 / 1) !important;
  border-right-color: rgb(186 230 253 / 1) !important;
}

.tw-border-x-sky-200\/15 {
  border-left-color: rgb(186 230 253 / 0.15) !important;
  border-right-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-x-sky-200\/20 {
  border-left-color: rgb(186 230 253 / 0.2) !important;
  border-right-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-x-sky-200\/25 {
  border-left-color: rgb(186 230 253 / 0.25) !important;
  border-right-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-x-sky-200\/30 {
  border-left-color: rgb(186 230 253 / 0.3) !important;
  border-right-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-x-sky-200\/35 {
  border-left-color: rgb(186 230 253 / 0.35) !important;
  border-right-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-x-sky-200\/40 {
  border-left-color: rgb(186 230 253 / 0.4) !important;
  border-right-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-x-sky-200\/45 {
  border-left-color: rgb(186 230 253 / 0.45) !important;
  border-right-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-x-sky-200\/5 {
  border-left-color: rgb(186 230 253 / 0.05) !important;
  border-right-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-x-sky-200\/50 {
  border-left-color: rgb(186 230 253 / 0.5) !important;
  border-right-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-x-sky-200\/55 {
  border-left-color: rgb(186 230 253 / 0.55) !important;
  border-right-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-x-sky-200\/60 {
  border-left-color: rgb(186 230 253 / 0.6) !important;
  border-right-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-x-sky-200\/65 {
  border-left-color: rgb(186 230 253 / 0.65) !important;
  border-right-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-x-sky-200\/70 {
  border-left-color: rgb(186 230 253 / 0.7) !important;
  border-right-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-x-sky-200\/75 {
  border-left-color: rgb(186 230 253 / 0.75) !important;
  border-right-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-x-sky-200\/80 {
  border-left-color: rgb(186 230 253 / 0.8) !important;
  border-right-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-x-sky-200\/85 {
  border-left-color: rgb(186 230 253 / 0.85) !important;
  border-right-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-x-sky-200\/90 {
  border-left-color: rgb(186 230 253 / 0.9) !important;
  border-right-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-x-sky-200\/95 {
  border-left-color: rgb(186 230 253 / 0.95) !important;
  border-right-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-x-sky-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-300\/0 {
  border-left-color: rgb(125 211 252 / 0) !important;
  border-right-color: rgb(125 211 252 / 0) !important;
}

.tw-border-x-sky-300\/10 {
  border-left-color: rgb(125 211 252 / 0.1) !important;
  border-right-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-x-sky-300\/100 {
  border-left-color: rgb(125 211 252 / 1) !important;
  border-right-color: rgb(125 211 252 / 1) !important;
}

.tw-border-x-sky-300\/15 {
  border-left-color: rgb(125 211 252 / 0.15) !important;
  border-right-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-x-sky-300\/20 {
  border-left-color: rgb(125 211 252 / 0.2) !important;
  border-right-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-x-sky-300\/25 {
  border-left-color: rgb(125 211 252 / 0.25) !important;
  border-right-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-x-sky-300\/30 {
  border-left-color: rgb(125 211 252 / 0.3) !important;
  border-right-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-x-sky-300\/35 {
  border-left-color: rgb(125 211 252 / 0.35) !important;
  border-right-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-x-sky-300\/40 {
  border-left-color: rgb(125 211 252 / 0.4) !important;
  border-right-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-x-sky-300\/45 {
  border-left-color: rgb(125 211 252 / 0.45) !important;
  border-right-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-x-sky-300\/5 {
  border-left-color: rgb(125 211 252 / 0.05) !important;
  border-right-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-x-sky-300\/50 {
  border-left-color: rgb(125 211 252 / 0.5) !important;
  border-right-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-x-sky-300\/55 {
  border-left-color: rgb(125 211 252 / 0.55) !important;
  border-right-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-x-sky-300\/60 {
  border-left-color: rgb(125 211 252 / 0.6) !important;
  border-right-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-x-sky-300\/65 {
  border-left-color: rgb(125 211 252 / 0.65) !important;
  border-right-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-x-sky-300\/70 {
  border-left-color: rgb(125 211 252 / 0.7) !important;
  border-right-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-x-sky-300\/75 {
  border-left-color: rgb(125 211 252 / 0.75) !important;
  border-right-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-x-sky-300\/80 {
  border-left-color: rgb(125 211 252 / 0.8) !important;
  border-right-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-x-sky-300\/85 {
  border-left-color: rgb(125 211 252 / 0.85) !important;
  border-right-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-x-sky-300\/90 {
  border-left-color: rgb(125 211 252 / 0.9) !important;
  border-right-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-x-sky-300\/95 {
  border-left-color: rgb(125 211 252 / 0.95) !important;
  border-right-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-x-sky-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-400\/0 {
  border-left-color: rgb(56 189 248 / 0) !important;
  border-right-color: rgb(56 189 248 / 0) !important;
}

.tw-border-x-sky-400\/10 {
  border-left-color: rgb(56 189 248 / 0.1) !important;
  border-right-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-x-sky-400\/100 {
  border-left-color: rgb(56 189 248 / 1) !important;
  border-right-color: rgb(56 189 248 / 1) !important;
}

.tw-border-x-sky-400\/15 {
  border-left-color: rgb(56 189 248 / 0.15) !important;
  border-right-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-x-sky-400\/20 {
  border-left-color: rgb(56 189 248 / 0.2) !important;
  border-right-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-x-sky-400\/25 {
  border-left-color: rgb(56 189 248 / 0.25) !important;
  border-right-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-x-sky-400\/30 {
  border-left-color: rgb(56 189 248 / 0.3) !important;
  border-right-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-x-sky-400\/35 {
  border-left-color: rgb(56 189 248 / 0.35) !important;
  border-right-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-x-sky-400\/40 {
  border-left-color: rgb(56 189 248 / 0.4) !important;
  border-right-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-x-sky-400\/45 {
  border-left-color: rgb(56 189 248 / 0.45) !important;
  border-right-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-x-sky-400\/5 {
  border-left-color: rgb(56 189 248 / 0.05) !important;
  border-right-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-x-sky-400\/50 {
  border-left-color: rgb(56 189 248 / 0.5) !important;
  border-right-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-x-sky-400\/55 {
  border-left-color: rgb(56 189 248 / 0.55) !important;
  border-right-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-x-sky-400\/60 {
  border-left-color: rgb(56 189 248 / 0.6) !important;
  border-right-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-x-sky-400\/65 {
  border-left-color: rgb(56 189 248 / 0.65) !important;
  border-right-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-x-sky-400\/70 {
  border-left-color: rgb(56 189 248 / 0.7) !important;
  border-right-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-x-sky-400\/75 {
  border-left-color: rgb(56 189 248 / 0.75) !important;
  border-right-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-x-sky-400\/80 {
  border-left-color: rgb(56 189 248 / 0.8) !important;
  border-right-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-x-sky-400\/85 {
  border-left-color: rgb(56 189 248 / 0.85) !important;
  border-right-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-x-sky-400\/90 {
  border-left-color: rgb(56 189 248 / 0.9) !important;
  border-right-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-x-sky-400\/95 {
  border-left-color: rgb(56 189 248 / 0.95) !important;
  border-right-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-x-sky-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-50\/0 {
  border-left-color: rgb(240 249 255 / 0) !important;
  border-right-color: rgb(240 249 255 / 0) !important;
}

.tw-border-x-sky-50\/10 {
  border-left-color: rgb(240 249 255 / 0.1) !important;
  border-right-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-x-sky-50\/100 {
  border-left-color: rgb(240 249 255 / 1) !important;
  border-right-color: rgb(240 249 255 / 1) !important;
}

.tw-border-x-sky-50\/15 {
  border-left-color: rgb(240 249 255 / 0.15) !important;
  border-right-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-x-sky-50\/20 {
  border-left-color: rgb(240 249 255 / 0.2) !important;
  border-right-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-x-sky-50\/25 {
  border-left-color: rgb(240 249 255 / 0.25) !important;
  border-right-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-x-sky-50\/30 {
  border-left-color: rgb(240 249 255 / 0.3) !important;
  border-right-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-x-sky-50\/35 {
  border-left-color: rgb(240 249 255 / 0.35) !important;
  border-right-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-x-sky-50\/40 {
  border-left-color: rgb(240 249 255 / 0.4) !important;
  border-right-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-x-sky-50\/45 {
  border-left-color: rgb(240 249 255 / 0.45) !important;
  border-right-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-x-sky-50\/5 {
  border-left-color: rgb(240 249 255 / 0.05) !important;
  border-right-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-x-sky-50\/50 {
  border-left-color: rgb(240 249 255 / 0.5) !important;
  border-right-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-x-sky-50\/55 {
  border-left-color: rgb(240 249 255 / 0.55) !important;
  border-right-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-x-sky-50\/60 {
  border-left-color: rgb(240 249 255 / 0.6) !important;
  border-right-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-x-sky-50\/65 {
  border-left-color: rgb(240 249 255 / 0.65) !important;
  border-right-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-x-sky-50\/70 {
  border-left-color: rgb(240 249 255 / 0.7) !important;
  border-right-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-x-sky-50\/75 {
  border-left-color: rgb(240 249 255 / 0.75) !important;
  border-right-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-x-sky-50\/80 {
  border-left-color: rgb(240 249 255 / 0.8) !important;
  border-right-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-x-sky-50\/85 {
  border-left-color: rgb(240 249 255 / 0.85) !important;
  border-right-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-x-sky-50\/90 {
  border-left-color: rgb(240 249 255 / 0.9) !important;
  border-right-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-x-sky-50\/95 {
  border-left-color: rgb(240 249 255 / 0.95) !important;
  border-right-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-x-sky-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-500\/0 {
  border-left-color: rgb(14 165 233 / 0) !important;
  border-right-color: rgb(14 165 233 / 0) !important;
}

.tw-border-x-sky-500\/10 {
  border-left-color: rgb(14 165 233 / 0.1) !important;
  border-right-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-x-sky-500\/100 {
  border-left-color: rgb(14 165 233 / 1) !important;
  border-right-color: rgb(14 165 233 / 1) !important;
}

.tw-border-x-sky-500\/15 {
  border-left-color: rgb(14 165 233 / 0.15) !important;
  border-right-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-x-sky-500\/20 {
  border-left-color: rgb(14 165 233 / 0.2) !important;
  border-right-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-x-sky-500\/25 {
  border-left-color: rgb(14 165 233 / 0.25) !important;
  border-right-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-x-sky-500\/30 {
  border-left-color: rgb(14 165 233 / 0.3) !important;
  border-right-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-x-sky-500\/35 {
  border-left-color: rgb(14 165 233 / 0.35) !important;
  border-right-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-x-sky-500\/40 {
  border-left-color: rgb(14 165 233 / 0.4) !important;
  border-right-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-x-sky-500\/45 {
  border-left-color: rgb(14 165 233 / 0.45) !important;
  border-right-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-x-sky-500\/5 {
  border-left-color: rgb(14 165 233 / 0.05) !important;
  border-right-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-x-sky-500\/50 {
  border-left-color: rgb(14 165 233 / 0.5) !important;
  border-right-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-x-sky-500\/55 {
  border-left-color: rgb(14 165 233 / 0.55) !important;
  border-right-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-x-sky-500\/60 {
  border-left-color: rgb(14 165 233 / 0.6) !important;
  border-right-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-x-sky-500\/65 {
  border-left-color: rgb(14 165 233 / 0.65) !important;
  border-right-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-x-sky-500\/70 {
  border-left-color: rgb(14 165 233 / 0.7) !important;
  border-right-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-x-sky-500\/75 {
  border-left-color: rgb(14 165 233 / 0.75) !important;
  border-right-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-x-sky-500\/80 {
  border-left-color: rgb(14 165 233 / 0.8) !important;
  border-right-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-x-sky-500\/85 {
  border-left-color: rgb(14 165 233 / 0.85) !important;
  border-right-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-x-sky-500\/90 {
  border-left-color: rgb(14 165 233 / 0.9) !important;
  border-right-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-x-sky-500\/95 {
  border-left-color: rgb(14 165 233 / 0.95) !important;
  border-right-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-x-sky-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-600\/0 {
  border-left-color: rgb(2 132 199 / 0) !important;
  border-right-color: rgb(2 132 199 / 0) !important;
}

.tw-border-x-sky-600\/10 {
  border-left-color: rgb(2 132 199 / 0.1) !important;
  border-right-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-x-sky-600\/100 {
  border-left-color: rgb(2 132 199 / 1) !important;
  border-right-color: rgb(2 132 199 / 1) !important;
}

.tw-border-x-sky-600\/15 {
  border-left-color: rgb(2 132 199 / 0.15) !important;
  border-right-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-x-sky-600\/20 {
  border-left-color: rgb(2 132 199 / 0.2) !important;
  border-right-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-x-sky-600\/25 {
  border-left-color: rgb(2 132 199 / 0.25) !important;
  border-right-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-x-sky-600\/30 {
  border-left-color: rgb(2 132 199 / 0.3) !important;
  border-right-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-x-sky-600\/35 {
  border-left-color: rgb(2 132 199 / 0.35) !important;
  border-right-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-x-sky-600\/40 {
  border-left-color: rgb(2 132 199 / 0.4) !important;
  border-right-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-x-sky-600\/45 {
  border-left-color: rgb(2 132 199 / 0.45) !important;
  border-right-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-x-sky-600\/5 {
  border-left-color: rgb(2 132 199 / 0.05) !important;
  border-right-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-x-sky-600\/50 {
  border-left-color: rgb(2 132 199 / 0.5) !important;
  border-right-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-x-sky-600\/55 {
  border-left-color: rgb(2 132 199 / 0.55) !important;
  border-right-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-x-sky-600\/60 {
  border-left-color: rgb(2 132 199 / 0.6) !important;
  border-right-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-x-sky-600\/65 {
  border-left-color: rgb(2 132 199 / 0.65) !important;
  border-right-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-x-sky-600\/70 {
  border-left-color: rgb(2 132 199 / 0.7) !important;
  border-right-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-x-sky-600\/75 {
  border-left-color: rgb(2 132 199 / 0.75) !important;
  border-right-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-x-sky-600\/80 {
  border-left-color: rgb(2 132 199 / 0.8) !important;
  border-right-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-x-sky-600\/85 {
  border-left-color: rgb(2 132 199 / 0.85) !important;
  border-right-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-x-sky-600\/90 {
  border-left-color: rgb(2 132 199 / 0.9) !important;
  border-right-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-x-sky-600\/95 {
  border-left-color: rgb(2 132 199 / 0.95) !important;
  border-right-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-x-sky-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-700\/0 {
  border-left-color: rgb(3 105 161 / 0) !important;
  border-right-color: rgb(3 105 161 / 0) !important;
}

.tw-border-x-sky-700\/10 {
  border-left-color: rgb(3 105 161 / 0.1) !important;
  border-right-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-x-sky-700\/100 {
  border-left-color: rgb(3 105 161 / 1) !important;
  border-right-color: rgb(3 105 161 / 1) !important;
}

.tw-border-x-sky-700\/15 {
  border-left-color: rgb(3 105 161 / 0.15) !important;
  border-right-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-x-sky-700\/20 {
  border-left-color: rgb(3 105 161 / 0.2) !important;
  border-right-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-x-sky-700\/25 {
  border-left-color: rgb(3 105 161 / 0.25) !important;
  border-right-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-x-sky-700\/30 {
  border-left-color: rgb(3 105 161 / 0.3) !important;
  border-right-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-x-sky-700\/35 {
  border-left-color: rgb(3 105 161 / 0.35) !important;
  border-right-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-x-sky-700\/40 {
  border-left-color: rgb(3 105 161 / 0.4) !important;
  border-right-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-x-sky-700\/45 {
  border-left-color: rgb(3 105 161 / 0.45) !important;
  border-right-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-x-sky-700\/5 {
  border-left-color: rgb(3 105 161 / 0.05) !important;
  border-right-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-x-sky-700\/50 {
  border-left-color: rgb(3 105 161 / 0.5) !important;
  border-right-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-x-sky-700\/55 {
  border-left-color: rgb(3 105 161 / 0.55) !important;
  border-right-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-x-sky-700\/60 {
  border-left-color: rgb(3 105 161 / 0.6) !important;
  border-right-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-x-sky-700\/65 {
  border-left-color: rgb(3 105 161 / 0.65) !important;
  border-right-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-x-sky-700\/70 {
  border-left-color: rgb(3 105 161 / 0.7) !important;
  border-right-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-x-sky-700\/75 {
  border-left-color: rgb(3 105 161 / 0.75) !important;
  border-right-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-x-sky-700\/80 {
  border-left-color: rgb(3 105 161 / 0.8) !important;
  border-right-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-x-sky-700\/85 {
  border-left-color: rgb(3 105 161 / 0.85) !important;
  border-right-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-x-sky-700\/90 {
  border-left-color: rgb(3 105 161 / 0.9) !important;
  border-right-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-x-sky-700\/95 {
  border-left-color: rgb(3 105 161 / 0.95) !important;
  border-right-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-x-sky-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-800\/0 {
  border-left-color: rgb(7 89 133 / 0) !important;
  border-right-color: rgb(7 89 133 / 0) !important;
}

.tw-border-x-sky-800\/10 {
  border-left-color: rgb(7 89 133 / 0.1) !important;
  border-right-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-x-sky-800\/100 {
  border-left-color: rgb(7 89 133 / 1) !important;
  border-right-color: rgb(7 89 133 / 1) !important;
}

.tw-border-x-sky-800\/15 {
  border-left-color: rgb(7 89 133 / 0.15) !important;
  border-right-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-x-sky-800\/20 {
  border-left-color: rgb(7 89 133 / 0.2) !important;
  border-right-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-x-sky-800\/25 {
  border-left-color: rgb(7 89 133 / 0.25) !important;
  border-right-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-x-sky-800\/30 {
  border-left-color: rgb(7 89 133 / 0.3) !important;
  border-right-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-x-sky-800\/35 {
  border-left-color: rgb(7 89 133 / 0.35) !important;
  border-right-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-x-sky-800\/40 {
  border-left-color: rgb(7 89 133 / 0.4) !important;
  border-right-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-x-sky-800\/45 {
  border-left-color: rgb(7 89 133 / 0.45) !important;
  border-right-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-x-sky-800\/5 {
  border-left-color: rgb(7 89 133 / 0.05) !important;
  border-right-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-x-sky-800\/50 {
  border-left-color: rgb(7 89 133 / 0.5) !important;
  border-right-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-x-sky-800\/55 {
  border-left-color: rgb(7 89 133 / 0.55) !important;
  border-right-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-x-sky-800\/60 {
  border-left-color: rgb(7 89 133 / 0.6) !important;
  border-right-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-x-sky-800\/65 {
  border-left-color: rgb(7 89 133 / 0.65) !important;
  border-right-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-x-sky-800\/70 {
  border-left-color: rgb(7 89 133 / 0.7) !important;
  border-right-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-x-sky-800\/75 {
  border-left-color: rgb(7 89 133 / 0.75) !important;
  border-right-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-x-sky-800\/80 {
  border-left-color: rgb(7 89 133 / 0.8) !important;
  border-right-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-x-sky-800\/85 {
  border-left-color: rgb(7 89 133 / 0.85) !important;
  border-right-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-x-sky-800\/90 {
  border-left-color: rgb(7 89 133 / 0.9) !important;
  border-right-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-x-sky-800\/95 {
  border-left-color: rgb(7 89 133 / 0.95) !important;
  border-right-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-x-sky-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-900\/0 {
  border-left-color: rgb(12 74 110 / 0) !important;
  border-right-color: rgb(12 74 110 / 0) !important;
}

.tw-border-x-sky-900\/10 {
  border-left-color: rgb(12 74 110 / 0.1) !important;
  border-right-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-x-sky-900\/100 {
  border-left-color: rgb(12 74 110 / 1) !important;
  border-right-color: rgb(12 74 110 / 1) !important;
}

.tw-border-x-sky-900\/15 {
  border-left-color: rgb(12 74 110 / 0.15) !important;
  border-right-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-x-sky-900\/20 {
  border-left-color: rgb(12 74 110 / 0.2) !important;
  border-right-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-x-sky-900\/25 {
  border-left-color: rgb(12 74 110 / 0.25) !important;
  border-right-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-x-sky-900\/30 {
  border-left-color: rgb(12 74 110 / 0.3) !important;
  border-right-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-x-sky-900\/35 {
  border-left-color: rgb(12 74 110 / 0.35) !important;
  border-right-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-x-sky-900\/40 {
  border-left-color: rgb(12 74 110 / 0.4) !important;
  border-right-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-x-sky-900\/45 {
  border-left-color: rgb(12 74 110 / 0.45) !important;
  border-right-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-x-sky-900\/5 {
  border-left-color: rgb(12 74 110 / 0.05) !important;
  border-right-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-x-sky-900\/50 {
  border-left-color: rgb(12 74 110 / 0.5) !important;
  border-right-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-x-sky-900\/55 {
  border-left-color: rgb(12 74 110 / 0.55) !important;
  border-right-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-x-sky-900\/60 {
  border-left-color: rgb(12 74 110 / 0.6) !important;
  border-right-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-x-sky-900\/65 {
  border-left-color: rgb(12 74 110 / 0.65) !important;
  border-right-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-x-sky-900\/70 {
  border-left-color: rgb(12 74 110 / 0.7) !important;
  border-right-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-x-sky-900\/75 {
  border-left-color: rgb(12 74 110 / 0.75) !important;
  border-right-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-x-sky-900\/80 {
  border-left-color: rgb(12 74 110 / 0.8) !important;
  border-right-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-x-sky-900\/85 {
  border-left-color: rgb(12 74 110 / 0.85) !important;
  border-right-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-x-sky-900\/90 {
  border-left-color: rgb(12 74 110 / 0.9) !important;
  border-right-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-x-sky-900\/95 {
  border-left-color: rgb(12 74 110 / 0.95) !important;
  border-right-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-x-sky-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-x-sky-950\/0 {
  border-left-color: rgb(8 47 73 / 0) !important;
  border-right-color: rgb(8 47 73 / 0) !important;
}

.tw-border-x-sky-950\/10 {
  border-left-color: rgb(8 47 73 / 0.1) !important;
  border-right-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-x-sky-950\/100 {
  border-left-color: rgb(8 47 73 / 1) !important;
  border-right-color: rgb(8 47 73 / 1) !important;
}

.tw-border-x-sky-950\/15 {
  border-left-color: rgb(8 47 73 / 0.15) !important;
  border-right-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-x-sky-950\/20 {
  border-left-color: rgb(8 47 73 / 0.2) !important;
  border-right-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-x-sky-950\/25 {
  border-left-color: rgb(8 47 73 / 0.25) !important;
  border-right-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-x-sky-950\/30 {
  border-left-color: rgb(8 47 73 / 0.3) !important;
  border-right-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-x-sky-950\/35 {
  border-left-color: rgb(8 47 73 / 0.35) !important;
  border-right-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-x-sky-950\/40 {
  border-left-color: rgb(8 47 73 / 0.4) !important;
  border-right-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-x-sky-950\/45 {
  border-left-color: rgb(8 47 73 / 0.45) !important;
  border-right-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-x-sky-950\/5 {
  border-left-color: rgb(8 47 73 / 0.05) !important;
  border-right-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-x-sky-950\/50 {
  border-left-color: rgb(8 47 73 / 0.5) !important;
  border-right-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-x-sky-950\/55 {
  border-left-color: rgb(8 47 73 / 0.55) !important;
  border-right-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-x-sky-950\/60 {
  border-left-color: rgb(8 47 73 / 0.6) !important;
  border-right-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-x-sky-950\/65 {
  border-left-color: rgb(8 47 73 / 0.65) !important;
  border-right-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-x-sky-950\/70 {
  border-left-color: rgb(8 47 73 / 0.7) !important;
  border-right-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-x-sky-950\/75 {
  border-left-color: rgb(8 47 73 / 0.75) !important;
  border-right-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-x-sky-950\/80 {
  border-left-color: rgb(8 47 73 / 0.8) !important;
  border-right-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-x-sky-950\/85 {
  border-left-color: rgb(8 47 73 / 0.85) !important;
  border-right-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-x-sky-950\/90 {
  border-left-color: rgb(8 47 73 / 0.9) !important;
  border-right-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-x-sky-950\/95 {
  border-left-color: rgb(8 47 73 / 0.95) !important;
  border-right-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-x-slate-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-100\/0 {
  border-left-color: rgb(241 245 249 / 0) !important;
  border-right-color: rgb(241 245 249 / 0) !important;
}

.tw-border-x-slate-100\/10 {
  border-left-color: rgb(241 245 249 / 0.1) !important;
  border-right-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-x-slate-100\/100 {
  border-left-color: rgb(241 245 249 / 1) !important;
  border-right-color: rgb(241 245 249 / 1) !important;
}

.tw-border-x-slate-100\/15 {
  border-left-color: rgb(241 245 249 / 0.15) !important;
  border-right-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-x-slate-100\/20 {
  border-left-color: rgb(241 245 249 / 0.2) !important;
  border-right-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-x-slate-100\/25 {
  border-left-color: rgb(241 245 249 / 0.25) !important;
  border-right-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-x-slate-100\/30 {
  border-left-color: rgb(241 245 249 / 0.3) !important;
  border-right-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-x-slate-100\/35 {
  border-left-color: rgb(241 245 249 / 0.35) !important;
  border-right-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-x-slate-100\/40 {
  border-left-color: rgb(241 245 249 / 0.4) !important;
  border-right-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-x-slate-100\/45 {
  border-left-color: rgb(241 245 249 / 0.45) !important;
  border-right-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-x-slate-100\/5 {
  border-left-color: rgb(241 245 249 / 0.05) !important;
  border-right-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-x-slate-100\/50 {
  border-left-color: rgb(241 245 249 / 0.5) !important;
  border-right-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-x-slate-100\/55 {
  border-left-color: rgb(241 245 249 / 0.55) !important;
  border-right-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-x-slate-100\/60 {
  border-left-color: rgb(241 245 249 / 0.6) !important;
  border-right-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-x-slate-100\/65 {
  border-left-color: rgb(241 245 249 / 0.65) !important;
  border-right-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-x-slate-100\/70 {
  border-left-color: rgb(241 245 249 / 0.7) !important;
  border-right-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-x-slate-100\/75 {
  border-left-color: rgb(241 245 249 / 0.75) !important;
  border-right-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-x-slate-100\/80 {
  border-left-color: rgb(241 245 249 / 0.8) !important;
  border-right-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-x-slate-100\/85 {
  border-left-color: rgb(241 245 249 / 0.85) !important;
  border-right-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-x-slate-100\/90 {
  border-left-color: rgb(241 245 249 / 0.9) !important;
  border-right-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-x-slate-100\/95 {
  border-left-color: rgb(241 245 249 / 0.95) !important;
  border-right-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-x-slate-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-200\/0 {
  border-left-color: rgb(226 232 240 / 0) !important;
  border-right-color: rgb(226 232 240 / 0) !important;
}

.tw-border-x-slate-200\/10 {
  border-left-color: rgb(226 232 240 / 0.1) !important;
  border-right-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-x-slate-200\/100 {
  border-left-color: rgb(226 232 240 / 1) !important;
  border-right-color: rgb(226 232 240 / 1) !important;
}

.tw-border-x-slate-200\/15 {
  border-left-color: rgb(226 232 240 / 0.15) !important;
  border-right-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-x-slate-200\/20 {
  border-left-color: rgb(226 232 240 / 0.2) !important;
  border-right-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-x-slate-200\/25 {
  border-left-color: rgb(226 232 240 / 0.25) !important;
  border-right-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-x-slate-200\/30 {
  border-left-color: rgb(226 232 240 / 0.3) !important;
  border-right-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-x-slate-200\/35 {
  border-left-color: rgb(226 232 240 / 0.35) !important;
  border-right-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-x-slate-200\/40 {
  border-left-color: rgb(226 232 240 / 0.4) !important;
  border-right-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-x-slate-200\/45 {
  border-left-color: rgb(226 232 240 / 0.45) !important;
  border-right-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-x-slate-200\/5 {
  border-left-color: rgb(226 232 240 / 0.05) !important;
  border-right-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-x-slate-200\/50 {
  border-left-color: rgb(226 232 240 / 0.5) !important;
  border-right-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-x-slate-200\/55 {
  border-left-color: rgb(226 232 240 / 0.55) !important;
  border-right-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-x-slate-200\/60 {
  border-left-color: rgb(226 232 240 / 0.6) !important;
  border-right-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-x-slate-200\/65 {
  border-left-color: rgb(226 232 240 / 0.65) !important;
  border-right-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-x-slate-200\/70 {
  border-left-color: rgb(226 232 240 / 0.7) !important;
  border-right-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-x-slate-200\/75 {
  border-left-color: rgb(226 232 240 / 0.75) !important;
  border-right-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-x-slate-200\/80 {
  border-left-color: rgb(226 232 240 / 0.8) !important;
  border-right-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-x-slate-200\/85 {
  border-left-color: rgb(226 232 240 / 0.85) !important;
  border-right-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-x-slate-200\/90 {
  border-left-color: rgb(226 232 240 / 0.9) !important;
  border-right-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-x-slate-200\/95 {
  border-left-color: rgb(226 232 240 / 0.95) !important;
  border-right-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-x-slate-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-300\/0 {
  border-left-color: rgb(203 213 225 / 0) !important;
  border-right-color: rgb(203 213 225 / 0) !important;
}

.tw-border-x-slate-300\/10 {
  border-left-color: rgb(203 213 225 / 0.1) !important;
  border-right-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-x-slate-300\/100 {
  border-left-color: rgb(203 213 225 / 1) !important;
  border-right-color: rgb(203 213 225 / 1) !important;
}

.tw-border-x-slate-300\/15 {
  border-left-color: rgb(203 213 225 / 0.15) !important;
  border-right-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-x-slate-300\/20 {
  border-left-color: rgb(203 213 225 / 0.2) !important;
  border-right-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-x-slate-300\/25 {
  border-left-color: rgb(203 213 225 / 0.25) !important;
  border-right-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-x-slate-300\/30 {
  border-left-color: rgb(203 213 225 / 0.3) !important;
  border-right-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-x-slate-300\/35 {
  border-left-color: rgb(203 213 225 / 0.35) !important;
  border-right-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-x-slate-300\/40 {
  border-left-color: rgb(203 213 225 / 0.4) !important;
  border-right-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-x-slate-300\/45 {
  border-left-color: rgb(203 213 225 / 0.45) !important;
  border-right-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-x-slate-300\/5 {
  border-left-color: rgb(203 213 225 / 0.05) !important;
  border-right-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-x-slate-300\/50 {
  border-left-color: rgb(203 213 225 / 0.5) !important;
  border-right-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-x-slate-300\/55 {
  border-left-color: rgb(203 213 225 / 0.55) !important;
  border-right-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-x-slate-300\/60 {
  border-left-color: rgb(203 213 225 / 0.6) !important;
  border-right-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-x-slate-300\/65 {
  border-left-color: rgb(203 213 225 / 0.65) !important;
  border-right-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-x-slate-300\/70 {
  border-left-color: rgb(203 213 225 / 0.7) !important;
  border-right-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-x-slate-300\/75 {
  border-left-color: rgb(203 213 225 / 0.75) !important;
  border-right-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-x-slate-300\/80 {
  border-left-color: rgb(203 213 225 / 0.8) !important;
  border-right-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-x-slate-300\/85 {
  border-left-color: rgb(203 213 225 / 0.85) !important;
  border-right-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-x-slate-300\/90 {
  border-left-color: rgb(203 213 225 / 0.9) !important;
  border-right-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-x-slate-300\/95 {
  border-left-color: rgb(203 213 225 / 0.95) !important;
  border-right-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-x-slate-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-400\/0 {
  border-left-color: rgb(148 163 184 / 0) !important;
  border-right-color: rgb(148 163 184 / 0) !important;
}

.tw-border-x-slate-400\/10 {
  border-left-color: rgb(148 163 184 / 0.1) !important;
  border-right-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-x-slate-400\/100 {
  border-left-color: rgb(148 163 184 / 1) !important;
  border-right-color: rgb(148 163 184 / 1) !important;
}

.tw-border-x-slate-400\/15 {
  border-left-color: rgb(148 163 184 / 0.15) !important;
  border-right-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-x-slate-400\/20 {
  border-left-color: rgb(148 163 184 / 0.2) !important;
  border-right-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-x-slate-400\/25 {
  border-left-color: rgb(148 163 184 / 0.25) !important;
  border-right-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-x-slate-400\/30 {
  border-left-color: rgb(148 163 184 / 0.3) !important;
  border-right-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-x-slate-400\/35 {
  border-left-color: rgb(148 163 184 / 0.35) !important;
  border-right-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-x-slate-400\/40 {
  border-left-color: rgb(148 163 184 / 0.4) !important;
  border-right-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-x-slate-400\/45 {
  border-left-color: rgb(148 163 184 / 0.45) !important;
  border-right-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-x-slate-400\/5 {
  border-left-color: rgb(148 163 184 / 0.05) !important;
  border-right-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-x-slate-400\/50 {
  border-left-color: rgb(148 163 184 / 0.5) !important;
  border-right-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-x-slate-400\/55 {
  border-left-color: rgb(148 163 184 / 0.55) !important;
  border-right-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-x-slate-400\/60 {
  border-left-color: rgb(148 163 184 / 0.6) !important;
  border-right-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-x-slate-400\/65 {
  border-left-color: rgb(148 163 184 / 0.65) !important;
  border-right-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-x-slate-400\/70 {
  border-left-color: rgb(148 163 184 / 0.7) !important;
  border-right-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-x-slate-400\/75 {
  border-left-color: rgb(148 163 184 / 0.75) !important;
  border-right-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-x-slate-400\/80 {
  border-left-color: rgb(148 163 184 / 0.8) !important;
  border-right-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-x-slate-400\/85 {
  border-left-color: rgb(148 163 184 / 0.85) !important;
  border-right-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-x-slate-400\/90 {
  border-left-color: rgb(148 163 184 / 0.9) !important;
  border-right-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-x-slate-400\/95 {
  border-left-color: rgb(148 163 184 / 0.95) !important;
  border-right-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-x-slate-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-50\/0 {
  border-left-color: rgb(248 250 252 / 0) !important;
  border-right-color: rgb(248 250 252 / 0) !important;
}

.tw-border-x-slate-50\/10 {
  border-left-color: rgb(248 250 252 / 0.1) !important;
  border-right-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-x-slate-50\/100 {
  border-left-color: rgb(248 250 252 / 1) !important;
  border-right-color: rgb(248 250 252 / 1) !important;
}

.tw-border-x-slate-50\/15 {
  border-left-color: rgb(248 250 252 / 0.15) !important;
  border-right-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-x-slate-50\/20 {
  border-left-color: rgb(248 250 252 / 0.2) !important;
  border-right-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-x-slate-50\/25 {
  border-left-color: rgb(248 250 252 / 0.25) !important;
  border-right-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-x-slate-50\/30 {
  border-left-color: rgb(248 250 252 / 0.3) !important;
  border-right-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-x-slate-50\/35 {
  border-left-color: rgb(248 250 252 / 0.35) !important;
  border-right-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-x-slate-50\/40 {
  border-left-color: rgb(248 250 252 / 0.4) !important;
  border-right-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-x-slate-50\/45 {
  border-left-color: rgb(248 250 252 / 0.45) !important;
  border-right-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-x-slate-50\/5 {
  border-left-color: rgb(248 250 252 / 0.05) !important;
  border-right-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-x-slate-50\/50 {
  border-left-color: rgb(248 250 252 / 0.5) !important;
  border-right-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-x-slate-50\/55 {
  border-left-color: rgb(248 250 252 / 0.55) !important;
  border-right-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-x-slate-50\/60 {
  border-left-color: rgb(248 250 252 / 0.6) !important;
  border-right-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-x-slate-50\/65 {
  border-left-color: rgb(248 250 252 / 0.65) !important;
  border-right-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-x-slate-50\/70 {
  border-left-color: rgb(248 250 252 / 0.7) !important;
  border-right-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-x-slate-50\/75 {
  border-left-color: rgb(248 250 252 / 0.75) !important;
  border-right-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-x-slate-50\/80 {
  border-left-color: rgb(248 250 252 / 0.8) !important;
  border-right-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-x-slate-50\/85 {
  border-left-color: rgb(248 250 252 / 0.85) !important;
  border-right-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-x-slate-50\/90 {
  border-left-color: rgb(248 250 252 / 0.9) !important;
  border-right-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-x-slate-50\/95 {
  border-left-color: rgb(248 250 252 / 0.95) !important;
  border-right-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-x-slate-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-500\/0 {
  border-left-color: rgb(100 116 139 / 0) !important;
  border-right-color: rgb(100 116 139 / 0) !important;
}

.tw-border-x-slate-500\/10 {
  border-left-color: rgb(100 116 139 / 0.1) !important;
  border-right-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-x-slate-500\/100 {
  border-left-color: rgb(100 116 139 / 1) !important;
  border-right-color: rgb(100 116 139 / 1) !important;
}

.tw-border-x-slate-500\/15 {
  border-left-color: rgb(100 116 139 / 0.15) !important;
  border-right-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-x-slate-500\/20 {
  border-left-color: rgb(100 116 139 / 0.2) !important;
  border-right-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-x-slate-500\/25 {
  border-left-color: rgb(100 116 139 / 0.25) !important;
  border-right-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-x-slate-500\/30 {
  border-left-color: rgb(100 116 139 / 0.3) !important;
  border-right-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-x-slate-500\/35 {
  border-left-color: rgb(100 116 139 / 0.35) !important;
  border-right-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-x-slate-500\/40 {
  border-left-color: rgb(100 116 139 / 0.4) !important;
  border-right-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-x-slate-500\/45 {
  border-left-color: rgb(100 116 139 / 0.45) !important;
  border-right-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-x-slate-500\/5 {
  border-left-color: rgb(100 116 139 / 0.05) !important;
  border-right-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-x-slate-500\/50 {
  border-left-color: rgb(100 116 139 / 0.5) !important;
  border-right-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-x-slate-500\/55 {
  border-left-color: rgb(100 116 139 / 0.55) !important;
  border-right-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-x-slate-500\/60 {
  border-left-color: rgb(100 116 139 / 0.6) !important;
  border-right-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-x-slate-500\/65 {
  border-left-color: rgb(100 116 139 / 0.65) !important;
  border-right-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-x-slate-500\/70 {
  border-left-color: rgb(100 116 139 / 0.7) !important;
  border-right-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-x-slate-500\/75 {
  border-left-color: rgb(100 116 139 / 0.75) !important;
  border-right-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-x-slate-500\/80 {
  border-left-color: rgb(100 116 139 / 0.8) !important;
  border-right-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-x-slate-500\/85 {
  border-left-color: rgb(100 116 139 / 0.85) !important;
  border-right-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-x-slate-500\/90 {
  border-left-color: rgb(100 116 139 / 0.9) !important;
  border-right-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-x-slate-500\/95 {
  border-left-color: rgb(100 116 139 / 0.95) !important;
  border-right-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-x-slate-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-600\/0 {
  border-left-color: rgb(71 85 105 / 0) !important;
  border-right-color: rgb(71 85 105 / 0) !important;
}

.tw-border-x-slate-600\/10 {
  border-left-color: rgb(71 85 105 / 0.1) !important;
  border-right-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-x-slate-600\/100 {
  border-left-color: rgb(71 85 105 / 1) !important;
  border-right-color: rgb(71 85 105 / 1) !important;
}

.tw-border-x-slate-600\/15 {
  border-left-color: rgb(71 85 105 / 0.15) !important;
  border-right-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-x-slate-600\/20 {
  border-left-color: rgb(71 85 105 / 0.2) !important;
  border-right-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-x-slate-600\/25 {
  border-left-color: rgb(71 85 105 / 0.25) !important;
  border-right-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-x-slate-600\/30 {
  border-left-color: rgb(71 85 105 / 0.3) !important;
  border-right-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-x-slate-600\/35 {
  border-left-color: rgb(71 85 105 / 0.35) !important;
  border-right-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-x-slate-600\/40 {
  border-left-color: rgb(71 85 105 / 0.4) !important;
  border-right-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-x-slate-600\/45 {
  border-left-color: rgb(71 85 105 / 0.45) !important;
  border-right-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-x-slate-600\/5 {
  border-left-color: rgb(71 85 105 / 0.05) !important;
  border-right-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-x-slate-600\/50 {
  border-left-color: rgb(71 85 105 / 0.5) !important;
  border-right-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-x-slate-600\/55 {
  border-left-color: rgb(71 85 105 / 0.55) !important;
  border-right-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-x-slate-600\/60 {
  border-left-color: rgb(71 85 105 / 0.6) !important;
  border-right-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-x-slate-600\/65 {
  border-left-color: rgb(71 85 105 / 0.65) !important;
  border-right-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-x-slate-600\/70 {
  border-left-color: rgb(71 85 105 / 0.7) !important;
  border-right-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-x-slate-600\/75 {
  border-left-color: rgb(71 85 105 / 0.75) !important;
  border-right-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-x-slate-600\/80 {
  border-left-color: rgb(71 85 105 / 0.8) !important;
  border-right-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-x-slate-600\/85 {
  border-left-color: rgb(71 85 105 / 0.85) !important;
  border-right-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-x-slate-600\/90 {
  border-left-color: rgb(71 85 105 / 0.9) !important;
  border-right-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-x-slate-600\/95 {
  border-left-color: rgb(71 85 105 / 0.95) !important;
  border-right-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-x-slate-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-700\/0 {
  border-left-color: rgb(51 65 85 / 0) !important;
  border-right-color: rgb(51 65 85 / 0) !important;
}

.tw-border-x-slate-700\/10 {
  border-left-color: rgb(51 65 85 / 0.1) !important;
  border-right-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-x-slate-700\/100 {
  border-left-color: rgb(51 65 85 / 1) !important;
  border-right-color: rgb(51 65 85 / 1) !important;
}

.tw-border-x-slate-700\/15 {
  border-left-color: rgb(51 65 85 / 0.15) !important;
  border-right-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-x-slate-700\/20 {
  border-left-color: rgb(51 65 85 / 0.2) !important;
  border-right-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-x-slate-700\/25 {
  border-left-color: rgb(51 65 85 / 0.25) !important;
  border-right-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-x-slate-700\/30 {
  border-left-color: rgb(51 65 85 / 0.3) !important;
  border-right-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-x-slate-700\/35 {
  border-left-color: rgb(51 65 85 / 0.35) !important;
  border-right-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-x-slate-700\/40 {
  border-left-color: rgb(51 65 85 / 0.4) !important;
  border-right-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-x-slate-700\/45 {
  border-left-color: rgb(51 65 85 / 0.45) !important;
  border-right-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-x-slate-700\/5 {
  border-left-color: rgb(51 65 85 / 0.05) !important;
  border-right-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-x-slate-700\/50 {
  border-left-color: rgb(51 65 85 / 0.5) !important;
  border-right-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-x-slate-700\/55 {
  border-left-color: rgb(51 65 85 / 0.55) !important;
  border-right-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-x-slate-700\/60 {
  border-left-color: rgb(51 65 85 / 0.6) !important;
  border-right-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-x-slate-700\/65 {
  border-left-color: rgb(51 65 85 / 0.65) !important;
  border-right-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-x-slate-700\/70 {
  border-left-color: rgb(51 65 85 / 0.7) !important;
  border-right-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-x-slate-700\/75 {
  border-left-color: rgb(51 65 85 / 0.75) !important;
  border-right-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-x-slate-700\/80 {
  border-left-color: rgb(51 65 85 / 0.8) !important;
  border-right-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-x-slate-700\/85 {
  border-left-color: rgb(51 65 85 / 0.85) !important;
  border-right-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-x-slate-700\/90 {
  border-left-color: rgb(51 65 85 / 0.9) !important;
  border-right-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-x-slate-700\/95 {
  border-left-color: rgb(51 65 85 / 0.95) !important;
  border-right-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-x-slate-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-800\/0 {
  border-left-color: rgb(30 41 59 / 0) !important;
  border-right-color: rgb(30 41 59 / 0) !important;
}

.tw-border-x-slate-800\/10 {
  border-left-color: rgb(30 41 59 / 0.1) !important;
  border-right-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-x-slate-800\/100 {
  border-left-color: rgb(30 41 59 / 1) !important;
  border-right-color: rgb(30 41 59 / 1) !important;
}

.tw-border-x-slate-800\/15 {
  border-left-color: rgb(30 41 59 / 0.15) !important;
  border-right-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-x-slate-800\/20 {
  border-left-color: rgb(30 41 59 / 0.2) !important;
  border-right-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-x-slate-800\/25 {
  border-left-color: rgb(30 41 59 / 0.25) !important;
  border-right-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-x-slate-800\/30 {
  border-left-color: rgb(30 41 59 / 0.3) !important;
  border-right-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-x-slate-800\/35 {
  border-left-color: rgb(30 41 59 / 0.35) !important;
  border-right-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-x-slate-800\/40 {
  border-left-color: rgb(30 41 59 / 0.4) !important;
  border-right-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-x-slate-800\/45 {
  border-left-color: rgb(30 41 59 / 0.45) !important;
  border-right-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-x-slate-800\/5 {
  border-left-color: rgb(30 41 59 / 0.05) !important;
  border-right-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-x-slate-800\/50 {
  border-left-color: rgb(30 41 59 / 0.5) !important;
  border-right-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-x-slate-800\/55 {
  border-left-color: rgb(30 41 59 / 0.55) !important;
  border-right-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-x-slate-800\/60 {
  border-left-color: rgb(30 41 59 / 0.6) !important;
  border-right-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-x-slate-800\/65 {
  border-left-color: rgb(30 41 59 / 0.65) !important;
  border-right-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-x-slate-800\/70 {
  border-left-color: rgb(30 41 59 / 0.7) !important;
  border-right-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-x-slate-800\/75 {
  border-left-color: rgb(30 41 59 / 0.75) !important;
  border-right-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-x-slate-800\/80 {
  border-left-color: rgb(30 41 59 / 0.8) !important;
  border-right-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-x-slate-800\/85 {
  border-left-color: rgb(30 41 59 / 0.85) !important;
  border-right-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-x-slate-800\/90 {
  border-left-color: rgb(30 41 59 / 0.9) !important;
  border-right-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-x-slate-800\/95 {
  border-left-color: rgb(30 41 59 / 0.95) !important;
  border-right-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-x-slate-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-900\/0 {
  border-left-color: rgb(15 23 42 / 0) !important;
  border-right-color: rgb(15 23 42 / 0) !important;
}

.tw-border-x-slate-900\/10 {
  border-left-color: rgb(15 23 42 / 0.1) !important;
  border-right-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-x-slate-900\/100 {
  border-left-color: rgb(15 23 42 / 1) !important;
  border-right-color: rgb(15 23 42 / 1) !important;
}

.tw-border-x-slate-900\/15 {
  border-left-color: rgb(15 23 42 / 0.15) !important;
  border-right-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-x-slate-900\/20 {
  border-left-color: rgb(15 23 42 / 0.2) !important;
  border-right-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-x-slate-900\/25 {
  border-left-color: rgb(15 23 42 / 0.25) !important;
  border-right-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-x-slate-900\/30 {
  border-left-color: rgb(15 23 42 / 0.3) !important;
  border-right-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-x-slate-900\/35 {
  border-left-color: rgb(15 23 42 / 0.35) !important;
  border-right-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-x-slate-900\/40 {
  border-left-color: rgb(15 23 42 / 0.4) !important;
  border-right-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-x-slate-900\/45 {
  border-left-color: rgb(15 23 42 / 0.45) !important;
  border-right-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-x-slate-900\/5 {
  border-left-color: rgb(15 23 42 / 0.05) !important;
  border-right-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-x-slate-900\/50 {
  border-left-color: rgb(15 23 42 / 0.5) !important;
  border-right-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-x-slate-900\/55 {
  border-left-color: rgb(15 23 42 / 0.55) !important;
  border-right-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-x-slate-900\/60 {
  border-left-color: rgb(15 23 42 / 0.6) !important;
  border-right-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-x-slate-900\/65 {
  border-left-color: rgb(15 23 42 / 0.65) !important;
  border-right-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-x-slate-900\/70 {
  border-left-color: rgb(15 23 42 / 0.7) !important;
  border-right-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-x-slate-900\/75 {
  border-left-color: rgb(15 23 42 / 0.75) !important;
  border-right-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-x-slate-900\/80 {
  border-left-color: rgb(15 23 42 / 0.8) !important;
  border-right-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-x-slate-900\/85 {
  border-left-color: rgb(15 23 42 / 0.85) !important;
  border-right-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-x-slate-900\/90 {
  border-left-color: rgb(15 23 42 / 0.9) !important;
  border-right-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-x-slate-900\/95 {
  border-left-color: rgb(15 23 42 / 0.95) !important;
  border-right-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-x-slate-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-x-slate-950\/0 {
  border-left-color: rgb(2 6 23 / 0) !important;
  border-right-color: rgb(2 6 23 / 0) !important;
}

.tw-border-x-slate-950\/10 {
  border-left-color: rgb(2 6 23 / 0.1) !important;
  border-right-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-x-slate-950\/100 {
  border-left-color: rgb(2 6 23 / 1) !important;
  border-right-color: rgb(2 6 23 / 1) !important;
}

.tw-border-x-slate-950\/15 {
  border-left-color: rgb(2 6 23 / 0.15) !important;
  border-right-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-x-slate-950\/20 {
  border-left-color: rgb(2 6 23 / 0.2) !important;
  border-right-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-x-slate-950\/25 {
  border-left-color: rgb(2 6 23 / 0.25) !important;
  border-right-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-x-slate-950\/30 {
  border-left-color: rgb(2 6 23 / 0.3) !important;
  border-right-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-x-slate-950\/35 {
  border-left-color: rgb(2 6 23 / 0.35) !important;
  border-right-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-x-slate-950\/40 {
  border-left-color: rgb(2 6 23 / 0.4) !important;
  border-right-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-x-slate-950\/45 {
  border-left-color: rgb(2 6 23 / 0.45) !important;
  border-right-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-x-slate-950\/5 {
  border-left-color: rgb(2 6 23 / 0.05) !important;
  border-right-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-x-slate-950\/50 {
  border-left-color: rgb(2 6 23 / 0.5) !important;
  border-right-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-x-slate-950\/55 {
  border-left-color: rgb(2 6 23 / 0.55) !important;
  border-right-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-x-slate-950\/60 {
  border-left-color: rgb(2 6 23 / 0.6) !important;
  border-right-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-x-slate-950\/65 {
  border-left-color: rgb(2 6 23 / 0.65) !important;
  border-right-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-x-slate-950\/70 {
  border-left-color: rgb(2 6 23 / 0.7) !important;
  border-right-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-x-slate-950\/75 {
  border-left-color: rgb(2 6 23 / 0.75) !important;
  border-right-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-x-slate-950\/80 {
  border-left-color: rgb(2 6 23 / 0.8) !important;
  border-right-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-x-slate-950\/85 {
  border-left-color: rgb(2 6 23 / 0.85) !important;
  border-right-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-x-slate-950\/90 {
  border-left-color: rgb(2 6 23 / 0.9) !important;
  border-right-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-x-slate-950\/95 {
  border-left-color: rgb(2 6 23 / 0.95) !important;
  border-right-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-x-stone-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-100\/0 {
  border-left-color: rgb(245 245 244 / 0) !important;
  border-right-color: rgb(245 245 244 / 0) !important;
}

.tw-border-x-stone-100\/10 {
  border-left-color: rgb(245 245 244 / 0.1) !important;
  border-right-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-x-stone-100\/100 {
  border-left-color: rgb(245 245 244 / 1) !important;
  border-right-color: rgb(245 245 244 / 1) !important;
}

.tw-border-x-stone-100\/15 {
  border-left-color: rgb(245 245 244 / 0.15) !important;
  border-right-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-x-stone-100\/20 {
  border-left-color: rgb(245 245 244 / 0.2) !important;
  border-right-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-x-stone-100\/25 {
  border-left-color: rgb(245 245 244 / 0.25) !important;
  border-right-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-x-stone-100\/30 {
  border-left-color: rgb(245 245 244 / 0.3) !important;
  border-right-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-x-stone-100\/35 {
  border-left-color: rgb(245 245 244 / 0.35) !important;
  border-right-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-x-stone-100\/40 {
  border-left-color: rgb(245 245 244 / 0.4) !important;
  border-right-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-x-stone-100\/45 {
  border-left-color: rgb(245 245 244 / 0.45) !important;
  border-right-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-x-stone-100\/5 {
  border-left-color: rgb(245 245 244 / 0.05) !important;
  border-right-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-x-stone-100\/50 {
  border-left-color: rgb(245 245 244 / 0.5) !important;
  border-right-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-x-stone-100\/55 {
  border-left-color: rgb(245 245 244 / 0.55) !important;
  border-right-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-x-stone-100\/60 {
  border-left-color: rgb(245 245 244 / 0.6) !important;
  border-right-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-x-stone-100\/65 {
  border-left-color: rgb(245 245 244 / 0.65) !important;
  border-right-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-x-stone-100\/70 {
  border-left-color: rgb(245 245 244 / 0.7) !important;
  border-right-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-x-stone-100\/75 {
  border-left-color: rgb(245 245 244 / 0.75) !important;
  border-right-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-x-stone-100\/80 {
  border-left-color: rgb(245 245 244 / 0.8) !important;
  border-right-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-x-stone-100\/85 {
  border-left-color: rgb(245 245 244 / 0.85) !important;
  border-right-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-x-stone-100\/90 {
  border-left-color: rgb(245 245 244 / 0.9) !important;
  border-right-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-x-stone-100\/95 {
  border-left-color: rgb(245 245 244 / 0.95) !important;
  border-right-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-x-stone-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-200\/0 {
  border-left-color: rgb(231 229 228 / 0) !important;
  border-right-color: rgb(231 229 228 / 0) !important;
}

.tw-border-x-stone-200\/10 {
  border-left-color: rgb(231 229 228 / 0.1) !important;
  border-right-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-x-stone-200\/100 {
  border-left-color: rgb(231 229 228 / 1) !important;
  border-right-color: rgb(231 229 228 / 1) !important;
}

.tw-border-x-stone-200\/15 {
  border-left-color: rgb(231 229 228 / 0.15) !important;
  border-right-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-x-stone-200\/20 {
  border-left-color: rgb(231 229 228 / 0.2) !important;
  border-right-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-x-stone-200\/25 {
  border-left-color: rgb(231 229 228 / 0.25) !important;
  border-right-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-x-stone-200\/30 {
  border-left-color: rgb(231 229 228 / 0.3) !important;
  border-right-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-x-stone-200\/35 {
  border-left-color: rgb(231 229 228 / 0.35) !important;
  border-right-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-x-stone-200\/40 {
  border-left-color: rgb(231 229 228 / 0.4) !important;
  border-right-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-x-stone-200\/45 {
  border-left-color: rgb(231 229 228 / 0.45) !important;
  border-right-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-x-stone-200\/5 {
  border-left-color: rgb(231 229 228 / 0.05) !important;
  border-right-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-x-stone-200\/50 {
  border-left-color: rgb(231 229 228 / 0.5) !important;
  border-right-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-x-stone-200\/55 {
  border-left-color: rgb(231 229 228 / 0.55) !important;
  border-right-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-x-stone-200\/60 {
  border-left-color: rgb(231 229 228 / 0.6) !important;
  border-right-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-x-stone-200\/65 {
  border-left-color: rgb(231 229 228 / 0.65) !important;
  border-right-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-x-stone-200\/70 {
  border-left-color: rgb(231 229 228 / 0.7) !important;
  border-right-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-x-stone-200\/75 {
  border-left-color: rgb(231 229 228 / 0.75) !important;
  border-right-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-x-stone-200\/80 {
  border-left-color: rgb(231 229 228 / 0.8) !important;
  border-right-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-x-stone-200\/85 {
  border-left-color: rgb(231 229 228 / 0.85) !important;
  border-right-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-x-stone-200\/90 {
  border-left-color: rgb(231 229 228 / 0.9) !important;
  border-right-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-x-stone-200\/95 {
  border-left-color: rgb(231 229 228 / 0.95) !important;
  border-right-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-x-stone-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-300\/0 {
  border-left-color: rgb(214 211 209 / 0) !important;
  border-right-color: rgb(214 211 209 / 0) !important;
}

.tw-border-x-stone-300\/10 {
  border-left-color: rgb(214 211 209 / 0.1) !important;
  border-right-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-x-stone-300\/100 {
  border-left-color: rgb(214 211 209 / 1) !important;
  border-right-color: rgb(214 211 209 / 1) !important;
}

.tw-border-x-stone-300\/15 {
  border-left-color: rgb(214 211 209 / 0.15) !important;
  border-right-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-x-stone-300\/20 {
  border-left-color: rgb(214 211 209 / 0.2) !important;
  border-right-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-x-stone-300\/25 {
  border-left-color: rgb(214 211 209 / 0.25) !important;
  border-right-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-x-stone-300\/30 {
  border-left-color: rgb(214 211 209 / 0.3) !important;
  border-right-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-x-stone-300\/35 {
  border-left-color: rgb(214 211 209 / 0.35) !important;
  border-right-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-x-stone-300\/40 {
  border-left-color: rgb(214 211 209 / 0.4) !important;
  border-right-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-x-stone-300\/45 {
  border-left-color: rgb(214 211 209 / 0.45) !important;
  border-right-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-x-stone-300\/5 {
  border-left-color: rgb(214 211 209 / 0.05) !important;
  border-right-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-x-stone-300\/50 {
  border-left-color: rgb(214 211 209 / 0.5) !important;
  border-right-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-x-stone-300\/55 {
  border-left-color: rgb(214 211 209 / 0.55) !important;
  border-right-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-x-stone-300\/60 {
  border-left-color: rgb(214 211 209 / 0.6) !important;
  border-right-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-x-stone-300\/65 {
  border-left-color: rgb(214 211 209 / 0.65) !important;
  border-right-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-x-stone-300\/70 {
  border-left-color: rgb(214 211 209 / 0.7) !important;
  border-right-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-x-stone-300\/75 {
  border-left-color: rgb(214 211 209 / 0.75) !important;
  border-right-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-x-stone-300\/80 {
  border-left-color: rgb(214 211 209 / 0.8) !important;
  border-right-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-x-stone-300\/85 {
  border-left-color: rgb(214 211 209 / 0.85) !important;
  border-right-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-x-stone-300\/90 {
  border-left-color: rgb(214 211 209 / 0.9) !important;
  border-right-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-x-stone-300\/95 {
  border-left-color: rgb(214 211 209 / 0.95) !important;
  border-right-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-x-stone-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-400\/0 {
  border-left-color: rgb(168 162 158 / 0) !important;
  border-right-color: rgb(168 162 158 / 0) !important;
}

.tw-border-x-stone-400\/10 {
  border-left-color: rgb(168 162 158 / 0.1) !important;
  border-right-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-x-stone-400\/100 {
  border-left-color: rgb(168 162 158 / 1) !important;
  border-right-color: rgb(168 162 158 / 1) !important;
}

.tw-border-x-stone-400\/15 {
  border-left-color: rgb(168 162 158 / 0.15) !important;
  border-right-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-x-stone-400\/20 {
  border-left-color: rgb(168 162 158 / 0.2) !important;
  border-right-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-x-stone-400\/25 {
  border-left-color: rgb(168 162 158 / 0.25) !important;
  border-right-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-x-stone-400\/30 {
  border-left-color: rgb(168 162 158 / 0.3) !important;
  border-right-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-x-stone-400\/35 {
  border-left-color: rgb(168 162 158 / 0.35) !important;
  border-right-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-x-stone-400\/40 {
  border-left-color: rgb(168 162 158 / 0.4) !important;
  border-right-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-x-stone-400\/45 {
  border-left-color: rgb(168 162 158 / 0.45) !important;
  border-right-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-x-stone-400\/5 {
  border-left-color: rgb(168 162 158 / 0.05) !important;
  border-right-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-x-stone-400\/50 {
  border-left-color: rgb(168 162 158 / 0.5) !important;
  border-right-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-x-stone-400\/55 {
  border-left-color: rgb(168 162 158 / 0.55) !important;
  border-right-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-x-stone-400\/60 {
  border-left-color: rgb(168 162 158 / 0.6) !important;
  border-right-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-x-stone-400\/65 {
  border-left-color: rgb(168 162 158 / 0.65) !important;
  border-right-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-x-stone-400\/70 {
  border-left-color: rgb(168 162 158 / 0.7) !important;
  border-right-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-x-stone-400\/75 {
  border-left-color: rgb(168 162 158 / 0.75) !important;
  border-right-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-x-stone-400\/80 {
  border-left-color: rgb(168 162 158 / 0.8) !important;
  border-right-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-x-stone-400\/85 {
  border-left-color: rgb(168 162 158 / 0.85) !important;
  border-right-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-x-stone-400\/90 {
  border-left-color: rgb(168 162 158 / 0.9) !important;
  border-right-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-x-stone-400\/95 {
  border-left-color: rgb(168 162 158 / 0.95) !important;
  border-right-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-x-stone-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-50\/0 {
  border-left-color: rgb(250 250 249 / 0) !important;
  border-right-color: rgb(250 250 249 / 0) !important;
}

.tw-border-x-stone-50\/10 {
  border-left-color: rgb(250 250 249 / 0.1) !important;
  border-right-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-x-stone-50\/100 {
  border-left-color: rgb(250 250 249 / 1) !important;
  border-right-color: rgb(250 250 249 / 1) !important;
}

.tw-border-x-stone-50\/15 {
  border-left-color: rgb(250 250 249 / 0.15) !important;
  border-right-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-x-stone-50\/20 {
  border-left-color: rgb(250 250 249 / 0.2) !important;
  border-right-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-x-stone-50\/25 {
  border-left-color: rgb(250 250 249 / 0.25) !important;
  border-right-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-x-stone-50\/30 {
  border-left-color: rgb(250 250 249 / 0.3) !important;
  border-right-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-x-stone-50\/35 {
  border-left-color: rgb(250 250 249 / 0.35) !important;
  border-right-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-x-stone-50\/40 {
  border-left-color: rgb(250 250 249 / 0.4) !important;
  border-right-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-x-stone-50\/45 {
  border-left-color: rgb(250 250 249 / 0.45) !important;
  border-right-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-x-stone-50\/5 {
  border-left-color: rgb(250 250 249 / 0.05) !important;
  border-right-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-x-stone-50\/50 {
  border-left-color: rgb(250 250 249 / 0.5) !important;
  border-right-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-x-stone-50\/55 {
  border-left-color: rgb(250 250 249 / 0.55) !important;
  border-right-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-x-stone-50\/60 {
  border-left-color: rgb(250 250 249 / 0.6) !important;
  border-right-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-x-stone-50\/65 {
  border-left-color: rgb(250 250 249 / 0.65) !important;
  border-right-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-x-stone-50\/70 {
  border-left-color: rgb(250 250 249 / 0.7) !important;
  border-right-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-x-stone-50\/75 {
  border-left-color: rgb(250 250 249 / 0.75) !important;
  border-right-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-x-stone-50\/80 {
  border-left-color: rgb(250 250 249 / 0.8) !important;
  border-right-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-x-stone-50\/85 {
  border-left-color: rgb(250 250 249 / 0.85) !important;
  border-right-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-x-stone-50\/90 {
  border-left-color: rgb(250 250 249 / 0.9) !important;
  border-right-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-x-stone-50\/95 {
  border-left-color: rgb(250 250 249 / 0.95) !important;
  border-right-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-x-stone-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-500\/0 {
  border-left-color: rgb(120 113 108 / 0) !important;
  border-right-color: rgb(120 113 108 / 0) !important;
}

.tw-border-x-stone-500\/10 {
  border-left-color: rgb(120 113 108 / 0.1) !important;
  border-right-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-x-stone-500\/100 {
  border-left-color: rgb(120 113 108 / 1) !important;
  border-right-color: rgb(120 113 108 / 1) !important;
}

.tw-border-x-stone-500\/15 {
  border-left-color: rgb(120 113 108 / 0.15) !important;
  border-right-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-x-stone-500\/20 {
  border-left-color: rgb(120 113 108 / 0.2) !important;
  border-right-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-x-stone-500\/25 {
  border-left-color: rgb(120 113 108 / 0.25) !important;
  border-right-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-x-stone-500\/30 {
  border-left-color: rgb(120 113 108 / 0.3) !important;
  border-right-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-x-stone-500\/35 {
  border-left-color: rgb(120 113 108 / 0.35) !important;
  border-right-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-x-stone-500\/40 {
  border-left-color: rgb(120 113 108 / 0.4) !important;
  border-right-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-x-stone-500\/45 {
  border-left-color: rgb(120 113 108 / 0.45) !important;
  border-right-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-x-stone-500\/5 {
  border-left-color: rgb(120 113 108 / 0.05) !important;
  border-right-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-x-stone-500\/50 {
  border-left-color: rgb(120 113 108 / 0.5) !important;
  border-right-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-x-stone-500\/55 {
  border-left-color: rgb(120 113 108 / 0.55) !important;
  border-right-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-x-stone-500\/60 {
  border-left-color: rgb(120 113 108 / 0.6) !important;
  border-right-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-x-stone-500\/65 {
  border-left-color: rgb(120 113 108 / 0.65) !important;
  border-right-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-x-stone-500\/70 {
  border-left-color: rgb(120 113 108 / 0.7) !important;
  border-right-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-x-stone-500\/75 {
  border-left-color: rgb(120 113 108 / 0.75) !important;
  border-right-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-x-stone-500\/80 {
  border-left-color: rgb(120 113 108 / 0.8) !important;
  border-right-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-x-stone-500\/85 {
  border-left-color: rgb(120 113 108 / 0.85) !important;
  border-right-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-x-stone-500\/90 {
  border-left-color: rgb(120 113 108 / 0.9) !important;
  border-right-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-x-stone-500\/95 {
  border-left-color: rgb(120 113 108 / 0.95) !important;
  border-right-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-x-stone-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-600\/0 {
  border-left-color: rgb(87 83 78 / 0) !important;
  border-right-color: rgb(87 83 78 / 0) !important;
}

.tw-border-x-stone-600\/10 {
  border-left-color: rgb(87 83 78 / 0.1) !important;
  border-right-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-x-stone-600\/100 {
  border-left-color: rgb(87 83 78 / 1) !important;
  border-right-color: rgb(87 83 78 / 1) !important;
}

.tw-border-x-stone-600\/15 {
  border-left-color: rgb(87 83 78 / 0.15) !important;
  border-right-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-x-stone-600\/20 {
  border-left-color: rgb(87 83 78 / 0.2) !important;
  border-right-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-x-stone-600\/25 {
  border-left-color: rgb(87 83 78 / 0.25) !important;
  border-right-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-x-stone-600\/30 {
  border-left-color: rgb(87 83 78 / 0.3) !important;
  border-right-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-x-stone-600\/35 {
  border-left-color: rgb(87 83 78 / 0.35) !important;
  border-right-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-x-stone-600\/40 {
  border-left-color: rgb(87 83 78 / 0.4) !important;
  border-right-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-x-stone-600\/45 {
  border-left-color: rgb(87 83 78 / 0.45) !important;
  border-right-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-x-stone-600\/5 {
  border-left-color: rgb(87 83 78 / 0.05) !important;
  border-right-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-x-stone-600\/50 {
  border-left-color: rgb(87 83 78 / 0.5) !important;
  border-right-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-x-stone-600\/55 {
  border-left-color: rgb(87 83 78 / 0.55) !important;
  border-right-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-x-stone-600\/60 {
  border-left-color: rgb(87 83 78 / 0.6) !important;
  border-right-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-x-stone-600\/65 {
  border-left-color: rgb(87 83 78 / 0.65) !important;
  border-right-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-x-stone-600\/70 {
  border-left-color: rgb(87 83 78 / 0.7) !important;
  border-right-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-x-stone-600\/75 {
  border-left-color: rgb(87 83 78 / 0.75) !important;
  border-right-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-x-stone-600\/80 {
  border-left-color: rgb(87 83 78 / 0.8) !important;
  border-right-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-x-stone-600\/85 {
  border-left-color: rgb(87 83 78 / 0.85) !important;
  border-right-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-x-stone-600\/90 {
  border-left-color: rgb(87 83 78 / 0.9) !important;
  border-right-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-x-stone-600\/95 {
  border-left-color: rgb(87 83 78 / 0.95) !important;
  border-right-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-x-stone-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-700\/0 {
  border-left-color: rgb(68 64 60 / 0) !important;
  border-right-color: rgb(68 64 60 / 0) !important;
}

.tw-border-x-stone-700\/10 {
  border-left-color: rgb(68 64 60 / 0.1) !important;
  border-right-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-x-stone-700\/100 {
  border-left-color: rgb(68 64 60 / 1) !important;
  border-right-color: rgb(68 64 60 / 1) !important;
}

.tw-border-x-stone-700\/15 {
  border-left-color: rgb(68 64 60 / 0.15) !important;
  border-right-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-x-stone-700\/20 {
  border-left-color: rgb(68 64 60 / 0.2) !important;
  border-right-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-x-stone-700\/25 {
  border-left-color: rgb(68 64 60 / 0.25) !important;
  border-right-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-x-stone-700\/30 {
  border-left-color: rgb(68 64 60 / 0.3) !important;
  border-right-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-x-stone-700\/35 {
  border-left-color: rgb(68 64 60 / 0.35) !important;
  border-right-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-x-stone-700\/40 {
  border-left-color: rgb(68 64 60 / 0.4) !important;
  border-right-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-x-stone-700\/45 {
  border-left-color: rgb(68 64 60 / 0.45) !important;
  border-right-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-x-stone-700\/5 {
  border-left-color: rgb(68 64 60 / 0.05) !important;
  border-right-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-x-stone-700\/50 {
  border-left-color: rgb(68 64 60 / 0.5) !important;
  border-right-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-x-stone-700\/55 {
  border-left-color: rgb(68 64 60 / 0.55) !important;
  border-right-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-x-stone-700\/60 {
  border-left-color: rgb(68 64 60 / 0.6) !important;
  border-right-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-x-stone-700\/65 {
  border-left-color: rgb(68 64 60 / 0.65) !important;
  border-right-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-x-stone-700\/70 {
  border-left-color: rgb(68 64 60 / 0.7) !important;
  border-right-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-x-stone-700\/75 {
  border-left-color: rgb(68 64 60 / 0.75) !important;
  border-right-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-x-stone-700\/80 {
  border-left-color: rgb(68 64 60 / 0.8) !important;
  border-right-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-x-stone-700\/85 {
  border-left-color: rgb(68 64 60 / 0.85) !important;
  border-right-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-x-stone-700\/90 {
  border-left-color: rgb(68 64 60 / 0.9) !important;
  border-right-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-x-stone-700\/95 {
  border-left-color: rgb(68 64 60 / 0.95) !important;
  border-right-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-x-stone-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-800\/0 {
  border-left-color: rgb(41 37 36 / 0) !important;
  border-right-color: rgb(41 37 36 / 0) !important;
}

.tw-border-x-stone-800\/10 {
  border-left-color: rgb(41 37 36 / 0.1) !important;
  border-right-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-x-stone-800\/100 {
  border-left-color: rgb(41 37 36 / 1) !important;
  border-right-color: rgb(41 37 36 / 1) !important;
}

.tw-border-x-stone-800\/15 {
  border-left-color: rgb(41 37 36 / 0.15) !important;
  border-right-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-x-stone-800\/20 {
  border-left-color: rgb(41 37 36 / 0.2) !important;
  border-right-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-x-stone-800\/25 {
  border-left-color: rgb(41 37 36 / 0.25) !important;
  border-right-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-x-stone-800\/30 {
  border-left-color: rgb(41 37 36 / 0.3) !important;
  border-right-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-x-stone-800\/35 {
  border-left-color: rgb(41 37 36 / 0.35) !important;
  border-right-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-x-stone-800\/40 {
  border-left-color: rgb(41 37 36 / 0.4) !important;
  border-right-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-x-stone-800\/45 {
  border-left-color: rgb(41 37 36 / 0.45) !important;
  border-right-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-x-stone-800\/5 {
  border-left-color: rgb(41 37 36 / 0.05) !important;
  border-right-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-x-stone-800\/50 {
  border-left-color: rgb(41 37 36 / 0.5) !important;
  border-right-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-x-stone-800\/55 {
  border-left-color: rgb(41 37 36 / 0.55) !important;
  border-right-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-x-stone-800\/60 {
  border-left-color: rgb(41 37 36 / 0.6) !important;
  border-right-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-x-stone-800\/65 {
  border-left-color: rgb(41 37 36 / 0.65) !important;
  border-right-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-x-stone-800\/70 {
  border-left-color: rgb(41 37 36 / 0.7) !important;
  border-right-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-x-stone-800\/75 {
  border-left-color: rgb(41 37 36 / 0.75) !important;
  border-right-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-x-stone-800\/80 {
  border-left-color: rgb(41 37 36 / 0.8) !important;
  border-right-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-x-stone-800\/85 {
  border-left-color: rgb(41 37 36 / 0.85) !important;
  border-right-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-x-stone-800\/90 {
  border-left-color: rgb(41 37 36 / 0.9) !important;
  border-right-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-x-stone-800\/95 {
  border-left-color: rgb(41 37 36 / 0.95) !important;
  border-right-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-x-stone-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-900\/0 {
  border-left-color: rgb(28 25 23 / 0) !important;
  border-right-color: rgb(28 25 23 / 0) !important;
}

.tw-border-x-stone-900\/10 {
  border-left-color: rgb(28 25 23 / 0.1) !important;
  border-right-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-x-stone-900\/100 {
  border-left-color: rgb(28 25 23 / 1) !important;
  border-right-color: rgb(28 25 23 / 1) !important;
}

.tw-border-x-stone-900\/15 {
  border-left-color: rgb(28 25 23 / 0.15) !important;
  border-right-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-x-stone-900\/20 {
  border-left-color: rgb(28 25 23 / 0.2) !important;
  border-right-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-x-stone-900\/25 {
  border-left-color: rgb(28 25 23 / 0.25) !important;
  border-right-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-x-stone-900\/30 {
  border-left-color: rgb(28 25 23 / 0.3) !important;
  border-right-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-x-stone-900\/35 {
  border-left-color: rgb(28 25 23 / 0.35) !important;
  border-right-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-x-stone-900\/40 {
  border-left-color: rgb(28 25 23 / 0.4) !important;
  border-right-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-x-stone-900\/45 {
  border-left-color: rgb(28 25 23 / 0.45) !important;
  border-right-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-x-stone-900\/5 {
  border-left-color: rgb(28 25 23 / 0.05) !important;
  border-right-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-x-stone-900\/50 {
  border-left-color: rgb(28 25 23 / 0.5) !important;
  border-right-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-x-stone-900\/55 {
  border-left-color: rgb(28 25 23 / 0.55) !important;
  border-right-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-x-stone-900\/60 {
  border-left-color: rgb(28 25 23 / 0.6) !important;
  border-right-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-x-stone-900\/65 {
  border-left-color: rgb(28 25 23 / 0.65) !important;
  border-right-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-x-stone-900\/70 {
  border-left-color: rgb(28 25 23 / 0.7) !important;
  border-right-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-x-stone-900\/75 {
  border-left-color: rgb(28 25 23 / 0.75) !important;
  border-right-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-x-stone-900\/80 {
  border-left-color: rgb(28 25 23 / 0.8) !important;
  border-right-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-x-stone-900\/85 {
  border-left-color: rgb(28 25 23 / 0.85) !important;
  border-right-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-x-stone-900\/90 {
  border-left-color: rgb(28 25 23 / 0.9) !important;
  border-right-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-x-stone-900\/95 {
  border-left-color: rgb(28 25 23 / 0.95) !important;
  border-right-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-x-stone-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-x-stone-950\/0 {
  border-left-color: rgb(12 10 9 / 0) !important;
  border-right-color: rgb(12 10 9 / 0) !important;
}

.tw-border-x-stone-950\/10 {
  border-left-color: rgb(12 10 9 / 0.1) !important;
  border-right-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-x-stone-950\/100 {
  border-left-color: rgb(12 10 9 / 1) !important;
  border-right-color: rgb(12 10 9 / 1) !important;
}

.tw-border-x-stone-950\/15 {
  border-left-color: rgb(12 10 9 / 0.15) !important;
  border-right-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-x-stone-950\/20 {
  border-left-color: rgb(12 10 9 / 0.2) !important;
  border-right-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-x-stone-950\/25 {
  border-left-color: rgb(12 10 9 / 0.25) !important;
  border-right-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-x-stone-950\/30 {
  border-left-color: rgb(12 10 9 / 0.3) !important;
  border-right-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-x-stone-950\/35 {
  border-left-color: rgb(12 10 9 / 0.35) !important;
  border-right-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-x-stone-950\/40 {
  border-left-color: rgb(12 10 9 / 0.4) !important;
  border-right-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-x-stone-950\/45 {
  border-left-color: rgb(12 10 9 / 0.45) !important;
  border-right-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-x-stone-950\/5 {
  border-left-color: rgb(12 10 9 / 0.05) !important;
  border-right-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-x-stone-950\/50 {
  border-left-color: rgb(12 10 9 / 0.5) !important;
  border-right-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-x-stone-950\/55 {
  border-left-color: rgb(12 10 9 / 0.55) !important;
  border-right-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-x-stone-950\/60 {
  border-left-color: rgb(12 10 9 / 0.6) !important;
  border-right-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-x-stone-950\/65 {
  border-left-color: rgb(12 10 9 / 0.65) !important;
  border-right-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-x-stone-950\/70 {
  border-left-color: rgb(12 10 9 / 0.7) !important;
  border-right-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-x-stone-950\/75 {
  border-left-color: rgb(12 10 9 / 0.75) !important;
  border-right-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-x-stone-950\/80 {
  border-left-color: rgb(12 10 9 / 0.8) !important;
  border-right-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-x-stone-950\/85 {
  border-left-color: rgb(12 10 9 / 0.85) !important;
  border-right-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-x-stone-950\/90 {
  border-left-color: rgb(12 10 9 / 0.9) !important;
  border-right-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-x-stone-950\/95 {
  border-left-color: rgb(12 10 9 / 0.95) !important;
  border-right-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-x-teal-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-100\/0 {
  border-left-color: rgb(204 251 241 / 0) !important;
  border-right-color: rgb(204 251 241 / 0) !important;
}

.tw-border-x-teal-100\/10 {
  border-left-color: rgb(204 251 241 / 0.1) !important;
  border-right-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-x-teal-100\/100 {
  border-left-color: rgb(204 251 241 / 1) !important;
  border-right-color: rgb(204 251 241 / 1) !important;
}

.tw-border-x-teal-100\/15 {
  border-left-color: rgb(204 251 241 / 0.15) !important;
  border-right-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-x-teal-100\/20 {
  border-left-color: rgb(204 251 241 / 0.2) !important;
  border-right-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-x-teal-100\/25 {
  border-left-color: rgb(204 251 241 / 0.25) !important;
  border-right-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-x-teal-100\/30 {
  border-left-color: rgb(204 251 241 / 0.3) !important;
  border-right-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-x-teal-100\/35 {
  border-left-color: rgb(204 251 241 / 0.35) !important;
  border-right-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-x-teal-100\/40 {
  border-left-color: rgb(204 251 241 / 0.4) !important;
  border-right-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-x-teal-100\/45 {
  border-left-color: rgb(204 251 241 / 0.45) !important;
  border-right-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-x-teal-100\/5 {
  border-left-color: rgb(204 251 241 / 0.05) !important;
  border-right-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-x-teal-100\/50 {
  border-left-color: rgb(204 251 241 / 0.5) !important;
  border-right-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-x-teal-100\/55 {
  border-left-color: rgb(204 251 241 / 0.55) !important;
  border-right-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-x-teal-100\/60 {
  border-left-color: rgb(204 251 241 / 0.6) !important;
  border-right-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-x-teal-100\/65 {
  border-left-color: rgb(204 251 241 / 0.65) !important;
  border-right-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-x-teal-100\/70 {
  border-left-color: rgb(204 251 241 / 0.7) !important;
  border-right-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-x-teal-100\/75 {
  border-left-color: rgb(204 251 241 / 0.75) !important;
  border-right-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-x-teal-100\/80 {
  border-left-color: rgb(204 251 241 / 0.8) !important;
  border-right-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-x-teal-100\/85 {
  border-left-color: rgb(204 251 241 / 0.85) !important;
  border-right-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-x-teal-100\/90 {
  border-left-color: rgb(204 251 241 / 0.9) !important;
  border-right-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-x-teal-100\/95 {
  border-left-color: rgb(204 251 241 / 0.95) !important;
  border-right-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-x-teal-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-200\/0 {
  border-left-color: rgb(153 246 228 / 0) !important;
  border-right-color: rgb(153 246 228 / 0) !important;
}

.tw-border-x-teal-200\/10 {
  border-left-color: rgb(153 246 228 / 0.1) !important;
  border-right-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-x-teal-200\/100 {
  border-left-color: rgb(153 246 228 / 1) !important;
  border-right-color: rgb(153 246 228 / 1) !important;
}

.tw-border-x-teal-200\/15 {
  border-left-color: rgb(153 246 228 / 0.15) !important;
  border-right-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-x-teal-200\/20 {
  border-left-color: rgb(153 246 228 / 0.2) !important;
  border-right-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-x-teal-200\/25 {
  border-left-color: rgb(153 246 228 / 0.25) !important;
  border-right-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-x-teal-200\/30 {
  border-left-color: rgb(153 246 228 / 0.3) !important;
  border-right-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-x-teal-200\/35 {
  border-left-color: rgb(153 246 228 / 0.35) !important;
  border-right-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-x-teal-200\/40 {
  border-left-color: rgb(153 246 228 / 0.4) !important;
  border-right-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-x-teal-200\/45 {
  border-left-color: rgb(153 246 228 / 0.45) !important;
  border-right-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-x-teal-200\/5 {
  border-left-color: rgb(153 246 228 / 0.05) !important;
  border-right-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-x-teal-200\/50 {
  border-left-color: rgb(153 246 228 / 0.5) !important;
  border-right-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-x-teal-200\/55 {
  border-left-color: rgb(153 246 228 / 0.55) !important;
  border-right-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-x-teal-200\/60 {
  border-left-color: rgb(153 246 228 / 0.6) !important;
  border-right-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-x-teal-200\/65 {
  border-left-color: rgb(153 246 228 / 0.65) !important;
  border-right-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-x-teal-200\/70 {
  border-left-color: rgb(153 246 228 / 0.7) !important;
  border-right-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-x-teal-200\/75 {
  border-left-color: rgb(153 246 228 / 0.75) !important;
  border-right-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-x-teal-200\/80 {
  border-left-color: rgb(153 246 228 / 0.8) !important;
  border-right-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-x-teal-200\/85 {
  border-left-color: rgb(153 246 228 / 0.85) !important;
  border-right-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-x-teal-200\/90 {
  border-left-color: rgb(153 246 228 / 0.9) !important;
  border-right-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-x-teal-200\/95 {
  border-left-color: rgb(153 246 228 / 0.95) !important;
  border-right-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-x-teal-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-300\/0 {
  border-left-color: rgb(94 234 212 / 0) !important;
  border-right-color: rgb(94 234 212 / 0) !important;
}

.tw-border-x-teal-300\/10 {
  border-left-color: rgb(94 234 212 / 0.1) !important;
  border-right-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-x-teal-300\/100 {
  border-left-color: rgb(94 234 212 / 1) !important;
  border-right-color: rgb(94 234 212 / 1) !important;
}

.tw-border-x-teal-300\/15 {
  border-left-color: rgb(94 234 212 / 0.15) !important;
  border-right-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-x-teal-300\/20 {
  border-left-color: rgb(94 234 212 / 0.2) !important;
  border-right-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-x-teal-300\/25 {
  border-left-color: rgb(94 234 212 / 0.25) !important;
  border-right-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-x-teal-300\/30 {
  border-left-color: rgb(94 234 212 / 0.3) !important;
  border-right-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-x-teal-300\/35 {
  border-left-color: rgb(94 234 212 / 0.35) !important;
  border-right-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-x-teal-300\/40 {
  border-left-color: rgb(94 234 212 / 0.4) !important;
  border-right-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-x-teal-300\/45 {
  border-left-color: rgb(94 234 212 / 0.45) !important;
  border-right-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-x-teal-300\/5 {
  border-left-color: rgb(94 234 212 / 0.05) !important;
  border-right-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-x-teal-300\/50 {
  border-left-color: rgb(94 234 212 / 0.5) !important;
  border-right-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-x-teal-300\/55 {
  border-left-color: rgb(94 234 212 / 0.55) !important;
  border-right-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-x-teal-300\/60 {
  border-left-color: rgb(94 234 212 / 0.6) !important;
  border-right-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-x-teal-300\/65 {
  border-left-color: rgb(94 234 212 / 0.65) !important;
  border-right-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-x-teal-300\/70 {
  border-left-color: rgb(94 234 212 / 0.7) !important;
  border-right-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-x-teal-300\/75 {
  border-left-color: rgb(94 234 212 / 0.75) !important;
  border-right-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-x-teal-300\/80 {
  border-left-color: rgb(94 234 212 / 0.8) !important;
  border-right-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-x-teal-300\/85 {
  border-left-color: rgb(94 234 212 / 0.85) !important;
  border-right-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-x-teal-300\/90 {
  border-left-color: rgb(94 234 212 / 0.9) !important;
  border-right-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-x-teal-300\/95 {
  border-left-color: rgb(94 234 212 / 0.95) !important;
  border-right-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-x-teal-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-400\/0 {
  border-left-color: rgb(45 212 191 / 0) !important;
  border-right-color: rgb(45 212 191 / 0) !important;
}

.tw-border-x-teal-400\/10 {
  border-left-color: rgb(45 212 191 / 0.1) !important;
  border-right-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-x-teal-400\/100 {
  border-left-color: rgb(45 212 191 / 1) !important;
  border-right-color: rgb(45 212 191 / 1) !important;
}

.tw-border-x-teal-400\/15 {
  border-left-color: rgb(45 212 191 / 0.15) !important;
  border-right-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-x-teal-400\/20 {
  border-left-color: rgb(45 212 191 / 0.2) !important;
  border-right-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-x-teal-400\/25 {
  border-left-color: rgb(45 212 191 / 0.25) !important;
  border-right-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-x-teal-400\/30 {
  border-left-color: rgb(45 212 191 / 0.3) !important;
  border-right-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-x-teal-400\/35 {
  border-left-color: rgb(45 212 191 / 0.35) !important;
  border-right-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-x-teal-400\/40 {
  border-left-color: rgb(45 212 191 / 0.4) !important;
  border-right-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-x-teal-400\/45 {
  border-left-color: rgb(45 212 191 / 0.45) !important;
  border-right-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-x-teal-400\/5 {
  border-left-color: rgb(45 212 191 / 0.05) !important;
  border-right-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-x-teal-400\/50 {
  border-left-color: rgb(45 212 191 / 0.5) !important;
  border-right-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-x-teal-400\/55 {
  border-left-color: rgb(45 212 191 / 0.55) !important;
  border-right-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-x-teal-400\/60 {
  border-left-color: rgb(45 212 191 / 0.6) !important;
  border-right-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-x-teal-400\/65 {
  border-left-color: rgb(45 212 191 / 0.65) !important;
  border-right-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-x-teal-400\/70 {
  border-left-color: rgb(45 212 191 / 0.7) !important;
  border-right-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-x-teal-400\/75 {
  border-left-color: rgb(45 212 191 / 0.75) !important;
  border-right-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-x-teal-400\/80 {
  border-left-color: rgb(45 212 191 / 0.8) !important;
  border-right-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-x-teal-400\/85 {
  border-left-color: rgb(45 212 191 / 0.85) !important;
  border-right-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-x-teal-400\/90 {
  border-left-color: rgb(45 212 191 / 0.9) !important;
  border-right-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-x-teal-400\/95 {
  border-left-color: rgb(45 212 191 / 0.95) !important;
  border-right-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-x-teal-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-50\/0 {
  border-left-color: rgb(240 253 250 / 0) !important;
  border-right-color: rgb(240 253 250 / 0) !important;
}

.tw-border-x-teal-50\/10 {
  border-left-color: rgb(240 253 250 / 0.1) !important;
  border-right-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-x-teal-50\/100 {
  border-left-color: rgb(240 253 250 / 1) !important;
  border-right-color: rgb(240 253 250 / 1) !important;
}

.tw-border-x-teal-50\/15 {
  border-left-color: rgb(240 253 250 / 0.15) !important;
  border-right-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-x-teal-50\/20 {
  border-left-color: rgb(240 253 250 / 0.2) !important;
  border-right-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-x-teal-50\/25 {
  border-left-color: rgb(240 253 250 / 0.25) !important;
  border-right-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-x-teal-50\/30 {
  border-left-color: rgb(240 253 250 / 0.3) !important;
  border-right-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-x-teal-50\/35 {
  border-left-color: rgb(240 253 250 / 0.35) !important;
  border-right-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-x-teal-50\/40 {
  border-left-color: rgb(240 253 250 / 0.4) !important;
  border-right-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-x-teal-50\/45 {
  border-left-color: rgb(240 253 250 / 0.45) !important;
  border-right-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-x-teal-50\/5 {
  border-left-color: rgb(240 253 250 / 0.05) !important;
  border-right-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-x-teal-50\/50 {
  border-left-color: rgb(240 253 250 / 0.5) !important;
  border-right-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-x-teal-50\/55 {
  border-left-color: rgb(240 253 250 / 0.55) !important;
  border-right-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-x-teal-50\/60 {
  border-left-color: rgb(240 253 250 / 0.6) !important;
  border-right-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-x-teal-50\/65 {
  border-left-color: rgb(240 253 250 / 0.65) !important;
  border-right-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-x-teal-50\/70 {
  border-left-color: rgb(240 253 250 / 0.7) !important;
  border-right-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-x-teal-50\/75 {
  border-left-color: rgb(240 253 250 / 0.75) !important;
  border-right-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-x-teal-50\/80 {
  border-left-color: rgb(240 253 250 / 0.8) !important;
  border-right-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-x-teal-50\/85 {
  border-left-color: rgb(240 253 250 / 0.85) !important;
  border-right-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-x-teal-50\/90 {
  border-left-color: rgb(240 253 250 / 0.9) !important;
  border-right-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-x-teal-50\/95 {
  border-left-color: rgb(240 253 250 / 0.95) !important;
  border-right-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-x-teal-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-500\/0 {
  border-left-color: rgb(20 184 166 / 0) !important;
  border-right-color: rgb(20 184 166 / 0) !important;
}

.tw-border-x-teal-500\/10 {
  border-left-color: rgb(20 184 166 / 0.1) !important;
  border-right-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-x-teal-500\/100 {
  border-left-color: rgb(20 184 166 / 1) !important;
  border-right-color: rgb(20 184 166 / 1) !important;
}

.tw-border-x-teal-500\/15 {
  border-left-color: rgb(20 184 166 / 0.15) !important;
  border-right-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-x-teal-500\/20 {
  border-left-color: rgb(20 184 166 / 0.2) !important;
  border-right-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-x-teal-500\/25 {
  border-left-color: rgb(20 184 166 / 0.25) !important;
  border-right-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-x-teal-500\/30 {
  border-left-color: rgb(20 184 166 / 0.3) !important;
  border-right-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-x-teal-500\/35 {
  border-left-color: rgb(20 184 166 / 0.35) !important;
  border-right-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-x-teal-500\/40 {
  border-left-color: rgb(20 184 166 / 0.4) !important;
  border-right-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-x-teal-500\/45 {
  border-left-color: rgb(20 184 166 / 0.45) !important;
  border-right-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-x-teal-500\/5 {
  border-left-color: rgb(20 184 166 / 0.05) !important;
  border-right-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-x-teal-500\/50 {
  border-left-color: rgb(20 184 166 / 0.5) !important;
  border-right-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-x-teal-500\/55 {
  border-left-color: rgb(20 184 166 / 0.55) !important;
  border-right-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-x-teal-500\/60 {
  border-left-color: rgb(20 184 166 / 0.6) !important;
  border-right-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-x-teal-500\/65 {
  border-left-color: rgb(20 184 166 / 0.65) !important;
  border-right-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-x-teal-500\/70 {
  border-left-color: rgb(20 184 166 / 0.7) !important;
  border-right-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-x-teal-500\/75 {
  border-left-color: rgb(20 184 166 / 0.75) !important;
  border-right-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-x-teal-500\/80 {
  border-left-color: rgb(20 184 166 / 0.8) !important;
  border-right-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-x-teal-500\/85 {
  border-left-color: rgb(20 184 166 / 0.85) !important;
  border-right-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-x-teal-500\/90 {
  border-left-color: rgb(20 184 166 / 0.9) !important;
  border-right-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-x-teal-500\/95 {
  border-left-color: rgb(20 184 166 / 0.95) !important;
  border-right-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-x-teal-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-600\/0 {
  border-left-color: rgb(13 148 136 / 0) !important;
  border-right-color: rgb(13 148 136 / 0) !important;
}

.tw-border-x-teal-600\/10 {
  border-left-color: rgb(13 148 136 / 0.1) !important;
  border-right-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-x-teal-600\/100 {
  border-left-color: rgb(13 148 136 / 1) !important;
  border-right-color: rgb(13 148 136 / 1) !important;
}

.tw-border-x-teal-600\/15 {
  border-left-color: rgb(13 148 136 / 0.15) !important;
  border-right-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-x-teal-600\/20 {
  border-left-color: rgb(13 148 136 / 0.2) !important;
  border-right-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-x-teal-600\/25 {
  border-left-color: rgb(13 148 136 / 0.25) !important;
  border-right-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-x-teal-600\/30 {
  border-left-color: rgb(13 148 136 / 0.3) !important;
  border-right-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-x-teal-600\/35 {
  border-left-color: rgb(13 148 136 / 0.35) !important;
  border-right-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-x-teal-600\/40 {
  border-left-color: rgb(13 148 136 / 0.4) !important;
  border-right-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-x-teal-600\/45 {
  border-left-color: rgb(13 148 136 / 0.45) !important;
  border-right-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-x-teal-600\/5 {
  border-left-color: rgb(13 148 136 / 0.05) !important;
  border-right-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-x-teal-600\/50 {
  border-left-color: rgb(13 148 136 / 0.5) !important;
  border-right-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-x-teal-600\/55 {
  border-left-color: rgb(13 148 136 / 0.55) !important;
  border-right-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-x-teal-600\/60 {
  border-left-color: rgb(13 148 136 / 0.6) !important;
  border-right-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-x-teal-600\/65 {
  border-left-color: rgb(13 148 136 / 0.65) !important;
  border-right-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-x-teal-600\/70 {
  border-left-color: rgb(13 148 136 / 0.7) !important;
  border-right-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-x-teal-600\/75 {
  border-left-color: rgb(13 148 136 / 0.75) !important;
  border-right-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-x-teal-600\/80 {
  border-left-color: rgb(13 148 136 / 0.8) !important;
  border-right-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-x-teal-600\/85 {
  border-left-color: rgb(13 148 136 / 0.85) !important;
  border-right-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-x-teal-600\/90 {
  border-left-color: rgb(13 148 136 / 0.9) !important;
  border-right-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-x-teal-600\/95 {
  border-left-color: rgb(13 148 136 / 0.95) !important;
  border-right-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-x-teal-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-700\/0 {
  border-left-color: rgb(15 118 110 / 0) !important;
  border-right-color: rgb(15 118 110 / 0) !important;
}

.tw-border-x-teal-700\/10 {
  border-left-color: rgb(15 118 110 / 0.1) !important;
  border-right-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-x-teal-700\/100 {
  border-left-color: rgb(15 118 110 / 1) !important;
  border-right-color: rgb(15 118 110 / 1) !important;
}

.tw-border-x-teal-700\/15 {
  border-left-color: rgb(15 118 110 / 0.15) !important;
  border-right-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-x-teal-700\/20 {
  border-left-color: rgb(15 118 110 / 0.2) !important;
  border-right-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-x-teal-700\/25 {
  border-left-color: rgb(15 118 110 / 0.25) !important;
  border-right-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-x-teal-700\/30 {
  border-left-color: rgb(15 118 110 / 0.3) !important;
  border-right-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-x-teal-700\/35 {
  border-left-color: rgb(15 118 110 / 0.35) !important;
  border-right-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-x-teal-700\/40 {
  border-left-color: rgb(15 118 110 / 0.4) !important;
  border-right-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-x-teal-700\/45 {
  border-left-color: rgb(15 118 110 / 0.45) !important;
  border-right-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-x-teal-700\/5 {
  border-left-color: rgb(15 118 110 / 0.05) !important;
  border-right-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-x-teal-700\/50 {
  border-left-color: rgb(15 118 110 / 0.5) !important;
  border-right-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-x-teal-700\/55 {
  border-left-color: rgb(15 118 110 / 0.55) !important;
  border-right-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-x-teal-700\/60 {
  border-left-color: rgb(15 118 110 / 0.6) !important;
  border-right-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-x-teal-700\/65 {
  border-left-color: rgb(15 118 110 / 0.65) !important;
  border-right-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-x-teal-700\/70 {
  border-left-color: rgb(15 118 110 / 0.7) !important;
  border-right-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-x-teal-700\/75 {
  border-left-color: rgb(15 118 110 / 0.75) !important;
  border-right-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-x-teal-700\/80 {
  border-left-color: rgb(15 118 110 / 0.8) !important;
  border-right-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-x-teal-700\/85 {
  border-left-color: rgb(15 118 110 / 0.85) !important;
  border-right-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-x-teal-700\/90 {
  border-left-color: rgb(15 118 110 / 0.9) !important;
  border-right-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-x-teal-700\/95 {
  border-left-color: rgb(15 118 110 / 0.95) !important;
  border-right-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-x-teal-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-800\/0 {
  border-left-color: rgb(17 94 89 / 0) !important;
  border-right-color: rgb(17 94 89 / 0) !important;
}

.tw-border-x-teal-800\/10 {
  border-left-color: rgb(17 94 89 / 0.1) !important;
  border-right-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-x-teal-800\/100 {
  border-left-color: rgb(17 94 89 / 1) !important;
  border-right-color: rgb(17 94 89 / 1) !important;
}

.tw-border-x-teal-800\/15 {
  border-left-color: rgb(17 94 89 / 0.15) !important;
  border-right-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-x-teal-800\/20 {
  border-left-color: rgb(17 94 89 / 0.2) !important;
  border-right-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-x-teal-800\/25 {
  border-left-color: rgb(17 94 89 / 0.25) !important;
  border-right-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-x-teal-800\/30 {
  border-left-color: rgb(17 94 89 / 0.3) !important;
  border-right-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-x-teal-800\/35 {
  border-left-color: rgb(17 94 89 / 0.35) !important;
  border-right-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-x-teal-800\/40 {
  border-left-color: rgb(17 94 89 / 0.4) !important;
  border-right-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-x-teal-800\/45 {
  border-left-color: rgb(17 94 89 / 0.45) !important;
  border-right-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-x-teal-800\/5 {
  border-left-color: rgb(17 94 89 / 0.05) !important;
  border-right-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-x-teal-800\/50 {
  border-left-color: rgb(17 94 89 / 0.5) !important;
  border-right-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-x-teal-800\/55 {
  border-left-color: rgb(17 94 89 / 0.55) !important;
  border-right-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-x-teal-800\/60 {
  border-left-color: rgb(17 94 89 / 0.6) !important;
  border-right-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-x-teal-800\/65 {
  border-left-color: rgb(17 94 89 / 0.65) !important;
  border-right-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-x-teal-800\/70 {
  border-left-color: rgb(17 94 89 / 0.7) !important;
  border-right-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-x-teal-800\/75 {
  border-left-color: rgb(17 94 89 / 0.75) !important;
  border-right-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-x-teal-800\/80 {
  border-left-color: rgb(17 94 89 / 0.8) !important;
  border-right-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-x-teal-800\/85 {
  border-left-color: rgb(17 94 89 / 0.85) !important;
  border-right-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-x-teal-800\/90 {
  border-left-color: rgb(17 94 89 / 0.9) !important;
  border-right-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-x-teal-800\/95 {
  border-left-color: rgb(17 94 89 / 0.95) !important;
  border-right-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-x-teal-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-900\/0 {
  border-left-color: rgb(19 78 74 / 0) !important;
  border-right-color: rgb(19 78 74 / 0) !important;
}

.tw-border-x-teal-900\/10 {
  border-left-color: rgb(19 78 74 / 0.1) !important;
  border-right-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-x-teal-900\/100 {
  border-left-color: rgb(19 78 74 / 1) !important;
  border-right-color: rgb(19 78 74 / 1) !important;
}

.tw-border-x-teal-900\/15 {
  border-left-color: rgb(19 78 74 / 0.15) !important;
  border-right-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-x-teal-900\/20 {
  border-left-color: rgb(19 78 74 / 0.2) !important;
  border-right-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-x-teal-900\/25 {
  border-left-color: rgb(19 78 74 / 0.25) !important;
  border-right-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-x-teal-900\/30 {
  border-left-color: rgb(19 78 74 / 0.3) !important;
  border-right-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-x-teal-900\/35 {
  border-left-color: rgb(19 78 74 / 0.35) !important;
  border-right-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-x-teal-900\/40 {
  border-left-color: rgb(19 78 74 / 0.4) !important;
  border-right-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-x-teal-900\/45 {
  border-left-color: rgb(19 78 74 / 0.45) !important;
  border-right-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-x-teal-900\/5 {
  border-left-color: rgb(19 78 74 / 0.05) !important;
  border-right-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-x-teal-900\/50 {
  border-left-color: rgb(19 78 74 / 0.5) !important;
  border-right-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-x-teal-900\/55 {
  border-left-color: rgb(19 78 74 / 0.55) !important;
  border-right-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-x-teal-900\/60 {
  border-left-color: rgb(19 78 74 / 0.6) !important;
  border-right-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-x-teal-900\/65 {
  border-left-color: rgb(19 78 74 / 0.65) !important;
  border-right-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-x-teal-900\/70 {
  border-left-color: rgb(19 78 74 / 0.7) !important;
  border-right-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-x-teal-900\/75 {
  border-left-color: rgb(19 78 74 / 0.75) !important;
  border-right-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-x-teal-900\/80 {
  border-left-color: rgb(19 78 74 / 0.8) !important;
  border-right-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-x-teal-900\/85 {
  border-left-color: rgb(19 78 74 / 0.85) !important;
  border-right-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-x-teal-900\/90 {
  border-left-color: rgb(19 78 74 / 0.9) !important;
  border-right-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-x-teal-900\/95 {
  border-left-color: rgb(19 78 74 / 0.95) !important;
  border-right-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-x-teal-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-x-teal-950\/0 {
  border-left-color: rgb(4 47 46 / 0) !important;
  border-right-color: rgb(4 47 46 / 0) !important;
}

.tw-border-x-teal-950\/10 {
  border-left-color: rgb(4 47 46 / 0.1) !important;
  border-right-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-x-teal-950\/100 {
  border-left-color: rgb(4 47 46 / 1) !important;
  border-right-color: rgb(4 47 46 / 1) !important;
}

.tw-border-x-teal-950\/15 {
  border-left-color: rgb(4 47 46 / 0.15) !important;
  border-right-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-x-teal-950\/20 {
  border-left-color: rgb(4 47 46 / 0.2) !important;
  border-right-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-x-teal-950\/25 {
  border-left-color: rgb(4 47 46 / 0.25) !important;
  border-right-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-x-teal-950\/30 {
  border-left-color: rgb(4 47 46 / 0.3) !important;
  border-right-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-x-teal-950\/35 {
  border-left-color: rgb(4 47 46 / 0.35) !important;
  border-right-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-x-teal-950\/40 {
  border-left-color: rgb(4 47 46 / 0.4) !important;
  border-right-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-x-teal-950\/45 {
  border-left-color: rgb(4 47 46 / 0.45) !important;
  border-right-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-x-teal-950\/5 {
  border-left-color: rgb(4 47 46 / 0.05) !important;
  border-right-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-x-teal-950\/50 {
  border-left-color: rgb(4 47 46 / 0.5) !important;
  border-right-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-x-teal-950\/55 {
  border-left-color: rgb(4 47 46 / 0.55) !important;
  border-right-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-x-teal-950\/60 {
  border-left-color: rgb(4 47 46 / 0.6) !important;
  border-right-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-x-teal-950\/65 {
  border-left-color: rgb(4 47 46 / 0.65) !important;
  border-right-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-x-teal-950\/70 {
  border-left-color: rgb(4 47 46 / 0.7) !important;
  border-right-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-x-teal-950\/75 {
  border-left-color: rgb(4 47 46 / 0.75) !important;
  border-right-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-x-teal-950\/80 {
  border-left-color: rgb(4 47 46 / 0.8) !important;
  border-right-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-x-teal-950\/85 {
  border-left-color: rgb(4 47 46 / 0.85) !important;
  border-right-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-x-teal-950\/90 {
  border-left-color: rgb(4 47 46 / 0.9) !important;
  border-right-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-x-teal-950\/95 {
  border-left-color: rgb(4 47 46 / 0.95) !important;
  border-right-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-x-transparent {
  border-left-color: transparent !important;
  border-right-color: transparent !important;
}

.tw-border-x-transparent\/0 {
  border-left-color: rgb(0 0 0 / 0) !important;
  border-right-color: rgb(0 0 0 / 0) !important;
}

.tw-border-x-transparent\/10 {
  border-left-color: rgb(0 0 0 / 0.1) !important;
  border-right-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-x-transparent\/100 {
  border-left-color: rgb(0 0 0 / 1) !important;
  border-right-color: rgb(0 0 0 / 1) !important;
}

.tw-border-x-transparent\/15 {
  border-left-color: rgb(0 0 0 / 0.15) !important;
  border-right-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-x-transparent\/20 {
  border-left-color: rgb(0 0 0 / 0.2) !important;
  border-right-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-x-transparent\/25 {
  border-left-color: rgb(0 0 0 / 0.25) !important;
  border-right-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-x-transparent\/30 {
  border-left-color: rgb(0 0 0 / 0.3) !important;
  border-right-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-x-transparent\/35 {
  border-left-color: rgb(0 0 0 / 0.35) !important;
  border-right-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-x-transparent\/40 {
  border-left-color: rgb(0 0 0 / 0.4) !important;
  border-right-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-x-transparent\/45 {
  border-left-color: rgb(0 0 0 / 0.45) !important;
  border-right-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-x-transparent\/5 {
  border-left-color: rgb(0 0 0 / 0.05) !important;
  border-right-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-x-transparent\/50 {
  border-left-color: rgb(0 0 0 / 0.5) !important;
  border-right-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-x-transparent\/55 {
  border-left-color: rgb(0 0 0 / 0.55) !important;
  border-right-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-x-transparent\/60 {
  border-left-color: rgb(0 0 0 / 0.6) !important;
  border-right-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-x-transparent\/65 {
  border-left-color: rgb(0 0 0 / 0.65) !important;
  border-right-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-x-transparent\/70 {
  border-left-color: rgb(0 0 0 / 0.7) !important;
  border-right-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-x-transparent\/75 {
  border-left-color: rgb(0 0 0 / 0.75) !important;
  border-right-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-x-transparent\/80 {
  border-left-color: rgb(0 0 0 / 0.8) !important;
  border-right-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-x-transparent\/85 {
  border-left-color: rgb(0 0 0 / 0.85) !important;
  border-right-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-x-transparent\/90 {
  border-left-color: rgb(0 0 0 / 0.9) !important;
  border-right-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-x-transparent\/95 {
  border-left-color: rgb(0 0 0 / 0.95) !important;
  border-right-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-x-violet-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-100\/0 {
  border-left-color: rgb(237 233 254 / 0) !important;
  border-right-color: rgb(237 233 254 / 0) !important;
}

.tw-border-x-violet-100\/10 {
  border-left-color: rgb(237 233 254 / 0.1) !important;
  border-right-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-x-violet-100\/100 {
  border-left-color: rgb(237 233 254 / 1) !important;
  border-right-color: rgb(237 233 254 / 1) !important;
}

.tw-border-x-violet-100\/15 {
  border-left-color: rgb(237 233 254 / 0.15) !important;
  border-right-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-x-violet-100\/20 {
  border-left-color: rgb(237 233 254 / 0.2) !important;
  border-right-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-x-violet-100\/25 {
  border-left-color: rgb(237 233 254 / 0.25) !important;
  border-right-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-x-violet-100\/30 {
  border-left-color: rgb(237 233 254 / 0.3) !important;
  border-right-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-x-violet-100\/35 {
  border-left-color: rgb(237 233 254 / 0.35) !important;
  border-right-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-x-violet-100\/40 {
  border-left-color: rgb(237 233 254 / 0.4) !important;
  border-right-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-x-violet-100\/45 {
  border-left-color: rgb(237 233 254 / 0.45) !important;
  border-right-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-x-violet-100\/5 {
  border-left-color: rgb(237 233 254 / 0.05) !important;
  border-right-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-x-violet-100\/50 {
  border-left-color: rgb(237 233 254 / 0.5) !important;
  border-right-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-x-violet-100\/55 {
  border-left-color: rgb(237 233 254 / 0.55) !important;
  border-right-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-x-violet-100\/60 {
  border-left-color: rgb(237 233 254 / 0.6) !important;
  border-right-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-x-violet-100\/65 {
  border-left-color: rgb(237 233 254 / 0.65) !important;
  border-right-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-x-violet-100\/70 {
  border-left-color: rgb(237 233 254 / 0.7) !important;
  border-right-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-x-violet-100\/75 {
  border-left-color: rgb(237 233 254 / 0.75) !important;
  border-right-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-x-violet-100\/80 {
  border-left-color: rgb(237 233 254 / 0.8) !important;
  border-right-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-x-violet-100\/85 {
  border-left-color: rgb(237 233 254 / 0.85) !important;
  border-right-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-x-violet-100\/90 {
  border-left-color: rgb(237 233 254 / 0.9) !important;
  border-right-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-x-violet-100\/95 {
  border-left-color: rgb(237 233 254 / 0.95) !important;
  border-right-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-x-violet-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-200\/0 {
  border-left-color: rgb(221 214 254 / 0) !important;
  border-right-color: rgb(221 214 254 / 0) !important;
}

.tw-border-x-violet-200\/10 {
  border-left-color: rgb(221 214 254 / 0.1) !important;
  border-right-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-x-violet-200\/100 {
  border-left-color: rgb(221 214 254 / 1) !important;
  border-right-color: rgb(221 214 254 / 1) !important;
}

.tw-border-x-violet-200\/15 {
  border-left-color: rgb(221 214 254 / 0.15) !important;
  border-right-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-x-violet-200\/20 {
  border-left-color: rgb(221 214 254 / 0.2) !important;
  border-right-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-x-violet-200\/25 {
  border-left-color: rgb(221 214 254 / 0.25) !important;
  border-right-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-x-violet-200\/30 {
  border-left-color: rgb(221 214 254 / 0.3) !important;
  border-right-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-x-violet-200\/35 {
  border-left-color: rgb(221 214 254 / 0.35) !important;
  border-right-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-x-violet-200\/40 {
  border-left-color: rgb(221 214 254 / 0.4) !important;
  border-right-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-x-violet-200\/45 {
  border-left-color: rgb(221 214 254 / 0.45) !important;
  border-right-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-x-violet-200\/5 {
  border-left-color: rgb(221 214 254 / 0.05) !important;
  border-right-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-x-violet-200\/50 {
  border-left-color: rgb(221 214 254 / 0.5) !important;
  border-right-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-x-violet-200\/55 {
  border-left-color: rgb(221 214 254 / 0.55) !important;
  border-right-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-x-violet-200\/60 {
  border-left-color: rgb(221 214 254 / 0.6) !important;
  border-right-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-x-violet-200\/65 {
  border-left-color: rgb(221 214 254 / 0.65) !important;
  border-right-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-x-violet-200\/70 {
  border-left-color: rgb(221 214 254 / 0.7) !important;
  border-right-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-x-violet-200\/75 {
  border-left-color: rgb(221 214 254 / 0.75) !important;
  border-right-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-x-violet-200\/80 {
  border-left-color: rgb(221 214 254 / 0.8) !important;
  border-right-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-x-violet-200\/85 {
  border-left-color: rgb(221 214 254 / 0.85) !important;
  border-right-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-x-violet-200\/90 {
  border-left-color: rgb(221 214 254 / 0.9) !important;
  border-right-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-x-violet-200\/95 {
  border-left-color: rgb(221 214 254 / 0.95) !important;
  border-right-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-x-violet-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-300\/0 {
  border-left-color: rgb(196 181 253 / 0) !important;
  border-right-color: rgb(196 181 253 / 0) !important;
}

.tw-border-x-violet-300\/10 {
  border-left-color: rgb(196 181 253 / 0.1) !important;
  border-right-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-x-violet-300\/100 {
  border-left-color: rgb(196 181 253 / 1) !important;
  border-right-color: rgb(196 181 253 / 1) !important;
}

.tw-border-x-violet-300\/15 {
  border-left-color: rgb(196 181 253 / 0.15) !important;
  border-right-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-x-violet-300\/20 {
  border-left-color: rgb(196 181 253 / 0.2) !important;
  border-right-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-x-violet-300\/25 {
  border-left-color: rgb(196 181 253 / 0.25) !important;
  border-right-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-x-violet-300\/30 {
  border-left-color: rgb(196 181 253 / 0.3) !important;
  border-right-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-x-violet-300\/35 {
  border-left-color: rgb(196 181 253 / 0.35) !important;
  border-right-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-x-violet-300\/40 {
  border-left-color: rgb(196 181 253 / 0.4) !important;
  border-right-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-x-violet-300\/45 {
  border-left-color: rgb(196 181 253 / 0.45) !important;
  border-right-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-x-violet-300\/5 {
  border-left-color: rgb(196 181 253 / 0.05) !important;
  border-right-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-x-violet-300\/50 {
  border-left-color: rgb(196 181 253 / 0.5) !important;
  border-right-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-x-violet-300\/55 {
  border-left-color: rgb(196 181 253 / 0.55) !important;
  border-right-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-x-violet-300\/60 {
  border-left-color: rgb(196 181 253 / 0.6) !important;
  border-right-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-x-violet-300\/65 {
  border-left-color: rgb(196 181 253 / 0.65) !important;
  border-right-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-x-violet-300\/70 {
  border-left-color: rgb(196 181 253 / 0.7) !important;
  border-right-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-x-violet-300\/75 {
  border-left-color: rgb(196 181 253 / 0.75) !important;
  border-right-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-x-violet-300\/80 {
  border-left-color: rgb(196 181 253 / 0.8) !important;
  border-right-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-x-violet-300\/85 {
  border-left-color: rgb(196 181 253 / 0.85) !important;
  border-right-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-x-violet-300\/90 {
  border-left-color: rgb(196 181 253 / 0.9) !important;
  border-right-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-x-violet-300\/95 {
  border-left-color: rgb(196 181 253 / 0.95) !important;
  border-right-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-x-violet-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-400\/0 {
  border-left-color: rgb(167 139 250 / 0) !important;
  border-right-color: rgb(167 139 250 / 0) !important;
}

.tw-border-x-violet-400\/10 {
  border-left-color: rgb(167 139 250 / 0.1) !important;
  border-right-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-x-violet-400\/100 {
  border-left-color: rgb(167 139 250 / 1) !important;
  border-right-color: rgb(167 139 250 / 1) !important;
}

.tw-border-x-violet-400\/15 {
  border-left-color: rgb(167 139 250 / 0.15) !important;
  border-right-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-x-violet-400\/20 {
  border-left-color: rgb(167 139 250 / 0.2) !important;
  border-right-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-x-violet-400\/25 {
  border-left-color: rgb(167 139 250 / 0.25) !important;
  border-right-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-x-violet-400\/30 {
  border-left-color: rgb(167 139 250 / 0.3) !important;
  border-right-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-x-violet-400\/35 {
  border-left-color: rgb(167 139 250 / 0.35) !important;
  border-right-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-x-violet-400\/40 {
  border-left-color: rgb(167 139 250 / 0.4) !important;
  border-right-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-x-violet-400\/45 {
  border-left-color: rgb(167 139 250 / 0.45) !important;
  border-right-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-x-violet-400\/5 {
  border-left-color: rgb(167 139 250 / 0.05) !important;
  border-right-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-x-violet-400\/50 {
  border-left-color: rgb(167 139 250 / 0.5) !important;
  border-right-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-x-violet-400\/55 {
  border-left-color: rgb(167 139 250 / 0.55) !important;
  border-right-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-x-violet-400\/60 {
  border-left-color: rgb(167 139 250 / 0.6) !important;
  border-right-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-x-violet-400\/65 {
  border-left-color: rgb(167 139 250 / 0.65) !important;
  border-right-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-x-violet-400\/70 {
  border-left-color: rgb(167 139 250 / 0.7) !important;
  border-right-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-x-violet-400\/75 {
  border-left-color: rgb(167 139 250 / 0.75) !important;
  border-right-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-x-violet-400\/80 {
  border-left-color: rgb(167 139 250 / 0.8) !important;
  border-right-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-x-violet-400\/85 {
  border-left-color: rgb(167 139 250 / 0.85) !important;
  border-right-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-x-violet-400\/90 {
  border-left-color: rgb(167 139 250 / 0.9) !important;
  border-right-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-x-violet-400\/95 {
  border-left-color: rgb(167 139 250 / 0.95) !important;
  border-right-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-x-violet-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-50\/0 {
  border-left-color: rgb(245 243 255 / 0) !important;
  border-right-color: rgb(245 243 255 / 0) !important;
}

.tw-border-x-violet-50\/10 {
  border-left-color: rgb(245 243 255 / 0.1) !important;
  border-right-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-x-violet-50\/100 {
  border-left-color: rgb(245 243 255 / 1) !important;
  border-right-color: rgb(245 243 255 / 1) !important;
}

.tw-border-x-violet-50\/15 {
  border-left-color: rgb(245 243 255 / 0.15) !important;
  border-right-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-x-violet-50\/20 {
  border-left-color: rgb(245 243 255 / 0.2) !important;
  border-right-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-x-violet-50\/25 {
  border-left-color: rgb(245 243 255 / 0.25) !important;
  border-right-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-x-violet-50\/30 {
  border-left-color: rgb(245 243 255 / 0.3) !important;
  border-right-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-x-violet-50\/35 {
  border-left-color: rgb(245 243 255 / 0.35) !important;
  border-right-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-x-violet-50\/40 {
  border-left-color: rgb(245 243 255 / 0.4) !important;
  border-right-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-x-violet-50\/45 {
  border-left-color: rgb(245 243 255 / 0.45) !important;
  border-right-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-x-violet-50\/5 {
  border-left-color: rgb(245 243 255 / 0.05) !important;
  border-right-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-x-violet-50\/50 {
  border-left-color: rgb(245 243 255 / 0.5) !important;
  border-right-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-x-violet-50\/55 {
  border-left-color: rgb(245 243 255 / 0.55) !important;
  border-right-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-x-violet-50\/60 {
  border-left-color: rgb(245 243 255 / 0.6) !important;
  border-right-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-x-violet-50\/65 {
  border-left-color: rgb(245 243 255 / 0.65) !important;
  border-right-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-x-violet-50\/70 {
  border-left-color: rgb(245 243 255 / 0.7) !important;
  border-right-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-x-violet-50\/75 {
  border-left-color: rgb(245 243 255 / 0.75) !important;
  border-right-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-x-violet-50\/80 {
  border-left-color: rgb(245 243 255 / 0.8) !important;
  border-right-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-x-violet-50\/85 {
  border-left-color: rgb(245 243 255 / 0.85) !important;
  border-right-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-x-violet-50\/90 {
  border-left-color: rgb(245 243 255 / 0.9) !important;
  border-right-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-x-violet-50\/95 {
  border-left-color: rgb(245 243 255 / 0.95) !important;
  border-right-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-x-violet-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-500\/0 {
  border-left-color: rgb(139 92 246 / 0) !important;
  border-right-color: rgb(139 92 246 / 0) !important;
}

.tw-border-x-violet-500\/10 {
  border-left-color: rgb(139 92 246 / 0.1) !important;
  border-right-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-x-violet-500\/100 {
  border-left-color: rgb(139 92 246 / 1) !important;
  border-right-color: rgb(139 92 246 / 1) !important;
}

.tw-border-x-violet-500\/15 {
  border-left-color: rgb(139 92 246 / 0.15) !important;
  border-right-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-x-violet-500\/20 {
  border-left-color: rgb(139 92 246 / 0.2) !important;
  border-right-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-x-violet-500\/25 {
  border-left-color: rgb(139 92 246 / 0.25) !important;
  border-right-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-x-violet-500\/30 {
  border-left-color: rgb(139 92 246 / 0.3) !important;
  border-right-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-x-violet-500\/35 {
  border-left-color: rgb(139 92 246 / 0.35) !important;
  border-right-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-x-violet-500\/40 {
  border-left-color: rgb(139 92 246 / 0.4) !important;
  border-right-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-x-violet-500\/45 {
  border-left-color: rgb(139 92 246 / 0.45) !important;
  border-right-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-x-violet-500\/5 {
  border-left-color: rgb(139 92 246 / 0.05) !important;
  border-right-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-x-violet-500\/50 {
  border-left-color: rgb(139 92 246 / 0.5) !important;
  border-right-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-x-violet-500\/55 {
  border-left-color: rgb(139 92 246 / 0.55) !important;
  border-right-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-x-violet-500\/60 {
  border-left-color: rgb(139 92 246 / 0.6) !important;
  border-right-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-x-violet-500\/65 {
  border-left-color: rgb(139 92 246 / 0.65) !important;
  border-right-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-x-violet-500\/70 {
  border-left-color: rgb(139 92 246 / 0.7) !important;
  border-right-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-x-violet-500\/75 {
  border-left-color: rgb(139 92 246 / 0.75) !important;
  border-right-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-x-violet-500\/80 {
  border-left-color: rgb(139 92 246 / 0.8) !important;
  border-right-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-x-violet-500\/85 {
  border-left-color: rgb(139 92 246 / 0.85) !important;
  border-right-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-x-violet-500\/90 {
  border-left-color: rgb(139 92 246 / 0.9) !important;
  border-right-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-x-violet-500\/95 {
  border-left-color: rgb(139 92 246 / 0.95) !important;
  border-right-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-x-violet-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-600\/0 {
  border-left-color: rgb(124 58 237 / 0) !important;
  border-right-color: rgb(124 58 237 / 0) !important;
}

.tw-border-x-violet-600\/10 {
  border-left-color: rgb(124 58 237 / 0.1) !important;
  border-right-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-x-violet-600\/100 {
  border-left-color: rgb(124 58 237 / 1) !important;
  border-right-color: rgb(124 58 237 / 1) !important;
}

.tw-border-x-violet-600\/15 {
  border-left-color: rgb(124 58 237 / 0.15) !important;
  border-right-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-x-violet-600\/20 {
  border-left-color: rgb(124 58 237 / 0.2) !important;
  border-right-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-x-violet-600\/25 {
  border-left-color: rgb(124 58 237 / 0.25) !important;
  border-right-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-x-violet-600\/30 {
  border-left-color: rgb(124 58 237 / 0.3) !important;
  border-right-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-x-violet-600\/35 {
  border-left-color: rgb(124 58 237 / 0.35) !important;
  border-right-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-x-violet-600\/40 {
  border-left-color: rgb(124 58 237 / 0.4) !important;
  border-right-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-x-violet-600\/45 {
  border-left-color: rgb(124 58 237 / 0.45) !important;
  border-right-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-x-violet-600\/5 {
  border-left-color: rgb(124 58 237 / 0.05) !important;
  border-right-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-x-violet-600\/50 {
  border-left-color: rgb(124 58 237 / 0.5) !important;
  border-right-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-x-violet-600\/55 {
  border-left-color: rgb(124 58 237 / 0.55) !important;
  border-right-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-x-violet-600\/60 {
  border-left-color: rgb(124 58 237 / 0.6) !important;
  border-right-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-x-violet-600\/65 {
  border-left-color: rgb(124 58 237 / 0.65) !important;
  border-right-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-x-violet-600\/70 {
  border-left-color: rgb(124 58 237 / 0.7) !important;
  border-right-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-x-violet-600\/75 {
  border-left-color: rgb(124 58 237 / 0.75) !important;
  border-right-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-x-violet-600\/80 {
  border-left-color: rgb(124 58 237 / 0.8) !important;
  border-right-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-x-violet-600\/85 {
  border-left-color: rgb(124 58 237 / 0.85) !important;
  border-right-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-x-violet-600\/90 {
  border-left-color: rgb(124 58 237 / 0.9) !important;
  border-right-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-x-violet-600\/95 {
  border-left-color: rgb(124 58 237 / 0.95) !important;
  border-right-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-x-violet-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-700\/0 {
  border-left-color: rgb(109 40 217 / 0) !important;
  border-right-color: rgb(109 40 217 / 0) !important;
}

.tw-border-x-violet-700\/10 {
  border-left-color: rgb(109 40 217 / 0.1) !important;
  border-right-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-x-violet-700\/100 {
  border-left-color: rgb(109 40 217 / 1) !important;
  border-right-color: rgb(109 40 217 / 1) !important;
}

.tw-border-x-violet-700\/15 {
  border-left-color: rgb(109 40 217 / 0.15) !important;
  border-right-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-x-violet-700\/20 {
  border-left-color: rgb(109 40 217 / 0.2) !important;
  border-right-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-x-violet-700\/25 {
  border-left-color: rgb(109 40 217 / 0.25) !important;
  border-right-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-x-violet-700\/30 {
  border-left-color: rgb(109 40 217 / 0.3) !important;
  border-right-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-x-violet-700\/35 {
  border-left-color: rgb(109 40 217 / 0.35) !important;
  border-right-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-x-violet-700\/40 {
  border-left-color: rgb(109 40 217 / 0.4) !important;
  border-right-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-x-violet-700\/45 {
  border-left-color: rgb(109 40 217 / 0.45) !important;
  border-right-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-x-violet-700\/5 {
  border-left-color: rgb(109 40 217 / 0.05) !important;
  border-right-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-x-violet-700\/50 {
  border-left-color: rgb(109 40 217 / 0.5) !important;
  border-right-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-x-violet-700\/55 {
  border-left-color: rgb(109 40 217 / 0.55) !important;
  border-right-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-x-violet-700\/60 {
  border-left-color: rgb(109 40 217 / 0.6) !important;
  border-right-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-x-violet-700\/65 {
  border-left-color: rgb(109 40 217 / 0.65) !important;
  border-right-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-x-violet-700\/70 {
  border-left-color: rgb(109 40 217 / 0.7) !important;
  border-right-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-x-violet-700\/75 {
  border-left-color: rgb(109 40 217 / 0.75) !important;
  border-right-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-x-violet-700\/80 {
  border-left-color: rgb(109 40 217 / 0.8) !important;
  border-right-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-x-violet-700\/85 {
  border-left-color: rgb(109 40 217 / 0.85) !important;
  border-right-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-x-violet-700\/90 {
  border-left-color: rgb(109 40 217 / 0.9) !important;
  border-right-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-x-violet-700\/95 {
  border-left-color: rgb(109 40 217 / 0.95) !important;
  border-right-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-x-violet-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-800\/0 {
  border-left-color: rgb(91 33 182 / 0) !important;
  border-right-color: rgb(91 33 182 / 0) !important;
}

.tw-border-x-violet-800\/10 {
  border-left-color: rgb(91 33 182 / 0.1) !important;
  border-right-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-x-violet-800\/100 {
  border-left-color: rgb(91 33 182 / 1) !important;
  border-right-color: rgb(91 33 182 / 1) !important;
}

.tw-border-x-violet-800\/15 {
  border-left-color: rgb(91 33 182 / 0.15) !important;
  border-right-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-x-violet-800\/20 {
  border-left-color: rgb(91 33 182 / 0.2) !important;
  border-right-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-x-violet-800\/25 {
  border-left-color: rgb(91 33 182 / 0.25) !important;
  border-right-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-x-violet-800\/30 {
  border-left-color: rgb(91 33 182 / 0.3) !important;
  border-right-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-x-violet-800\/35 {
  border-left-color: rgb(91 33 182 / 0.35) !important;
  border-right-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-x-violet-800\/40 {
  border-left-color: rgb(91 33 182 / 0.4) !important;
  border-right-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-x-violet-800\/45 {
  border-left-color: rgb(91 33 182 / 0.45) !important;
  border-right-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-x-violet-800\/5 {
  border-left-color: rgb(91 33 182 / 0.05) !important;
  border-right-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-x-violet-800\/50 {
  border-left-color: rgb(91 33 182 / 0.5) !important;
  border-right-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-x-violet-800\/55 {
  border-left-color: rgb(91 33 182 / 0.55) !important;
  border-right-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-x-violet-800\/60 {
  border-left-color: rgb(91 33 182 / 0.6) !important;
  border-right-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-x-violet-800\/65 {
  border-left-color: rgb(91 33 182 / 0.65) !important;
  border-right-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-x-violet-800\/70 {
  border-left-color: rgb(91 33 182 / 0.7) !important;
  border-right-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-x-violet-800\/75 {
  border-left-color: rgb(91 33 182 / 0.75) !important;
  border-right-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-x-violet-800\/80 {
  border-left-color: rgb(91 33 182 / 0.8) !important;
  border-right-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-x-violet-800\/85 {
  border-left-color: rgb(91 33 182 / 0.85) !important;
  border-right-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-x-violet-800\/90 {
  border-left-color: rgb(91 33 182 / 0.9) !important;
  border-right-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-x-violet-800\/95 {
  border-left-color: rgb(91 33 182 / 0.95) !important;
  border-right-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-x-violet-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-900\/0 {
  border-left-color: rgb(76 29 149 / 0) !important;
  border-right-color: rgb(76 29 149 / 0) !important;
}

.tw-border-x-violet-900\/10 {
  border-left-color: rgb(76 29 149 / 0.1) !important;
  border-right-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-x-violet-900\/100 {
  border-left-color: rgb(76 29 149 / 1) !important;
  border-right-color: rgb(76 29 149 / 1) !important;
}

.tw-border-x-violet-900\/15 {
  border-left-color: rgb(76 29 149 / 0.15) !important;
  border-right-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-x-violet-900\/20 {
  border-left-color: rgb(76 29 149 / 0.2) !important;
  border-right-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-x-violet-900\/25 {
  border-left-color: rgb(76 29 149 / 0.25) !important;
  border-right-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-x-violet-900\/30 {
  border-left-color: rgb(76 29 149 / 0.3) !important;
  border-right-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-x-violet-900\/35 {
  border-left-color: rgb(76 29 149 / 0.35) !important;
  border-right-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-x-violet-900\/40 {
  border-left-color: rgb(76 29 149 / 0.4) !important;
  border-right-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-x-violet-900\/45 {
  border-left-color: rgb(76 29 149 / 0.45) !important;
  border-right-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-x-violet-900\/5 {
  border-left-color: rgb(76 29 149 / 0.05) !important;
  border-right-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-x-violet-900\/50 {
  border-left-color: rgb(76 29 149 / 0.5) !important;
  border-right-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-x-violet-900\/55 {
  border-left-color: rgb(76 29 149 / 0.55) !important;
  border-right-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-x-violet-900\/60 {
  border-left-color: rgb(76 29 149 / 0.6) !important;
  border-right-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-x-violet-900\/65 {
  border-left-color: rgb(76 29 149 / 0.65) !important;
  border-right-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-x-violet-900\/70 {
  border-left-color: rgb(76 29 149 / 0.7) !important;
  border-right-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-x-violet-900\/75 {
  border-left-color: rgb(76 29 149 / 0.75) !important;
  border-right-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-x-violet-900\/80 {
  border-left-color: rgb(76 29 149 / 0.8) !important;
  border-right-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-x-violet-900\/85 {
  border-left-color: rgb(76 29 149 / 0.85) !important;
  border-right-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-x-violet-900\/90 {
  border-left-color: rgb(76 29 149 / 0.9) !important;
  border-right-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-x-violet-900\/95 {
  border-left-color: rgb(76 29 149 / 0.95) !important;
  border-right-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-x-violet-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-x-violet-950\/0 {
  border-left-color: rgb(46 16 101 / 0) !important;
  border-right-color: rgb(46 16 101 / 0) !important;
}

.tw-border-x-violet-950\/10 {
  border-left-color: rgb(46 16 101 / 0.1) !important;
  border-right-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-x-violet-950\/100 {
  border-left-color: rgb(46 16 101 / 1) !important;
  border-right-color: rgb(46 16 101 / 1) !important;
}

.tw-border-x-violet-950\/15 {
  border-left-color: rgb(46 16 101 / 0.15) !important;
  border-right-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-x-violet-950\/20 {
  border-left-color: rgb(46 16 101 / 0.2) !important;
  border-right-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-x-violet-950\/25 {
  border-left-color: rgb(46 16 101 / 0.25) !important;
  border-right-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-x-violet-950\/30 {
  border-left-color: rgb(46 16 101 / 0.3) !important;
  border-right-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-x-violet-950\/35 {
  border-left-color: rgb(46 16 101 / 0.35) !important;
  border-right-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-x-violet-950\/40 {
  border-left-color: rgb(46 16 101 / 0.4) !important;
  border-right-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-x-violet-950\/45 {
  border-left-color: rgb(46 16 101 / 0.45) !important;
  border-right-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-x-violet-950\/5 {
  border-left-color: rgb(46 16 101 / 0.05) !important;
  border-right-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-x-violet-950\/50 {
  border-left-color: rgb(46 16 101 / 0.5) !important;
  border-right-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-x-violet-950\/55 {
  border-left-color: rgb(46 16 101 / 0.55) !important;
  border-right-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-x-violet-950\/60 {
  border-left-color: rgb(46 16 101 / 0.6) !important;
  border-right-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-x-violet-950\/65 {
  border-left-color: rgb(46 16 101 / 0.65) !important;
  border-right-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-x-violet-950\/70 {
  border-left-color: rgb(46 16 101 / 0.7) !important;
  border-right-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-x-violet-950\/75 {
  border-left-color: rgb(46 16 101 / 0.75) !important;
  border-right-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-x-violet-950\/80 {
  border-left-color: rgb(46 16 101 / 0.8) !important;
  border-right-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-x-violet-950\/85 {
  border-left-color: rgb(46 16 101 / 0.85) !important;
  border-right-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-x-violet-950\/90 {
  border-left-color: rgb(46 16 101 / 0.9) !important;
  border-right-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-x-violet-950\/95 {
  border-left-color: rgb(46 16 101 / 0.95) !important;
  border-right-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-x-white {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-x-white\/0 {
  border-left-color: rgb(255 255 255 / 0) !important;
  border-right-color: rgb(255 255 255 / 0) !important;
}

.tw-border-x-white\/10 {
  border-left-color: rgb(255 255 255 / 0.1) !important;
  border-right-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-x-white\/100 {
  border-left-color: rgb(255 255 255 / 1) !important;
  border-right-color: rgb(255 255 255 / 1) !important;
}

.tw-border-x-white\/15 {
  border-left-color: rgb(255 255 255 / 0.15) !important;
  border-right-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-x-white\/20 {
  border-left-color: rgb(255 255 255 / 0.2) !important;
  border-right-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-x-white\/25 {
  border-left-color: rgb(255 255 255 / 0.25) !important;
  border-right-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-x-white\/30 {
  border-left-color: rgb(255 255 255 / 0.3) !important;
  border-right-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-x-white\/35 {
  border-left-color: rgb(255 255 255 / 0.35) !important;
  border-right-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-x-white\/40 {
  border-left-color: rgb(255 255 255 / 0.4) !important;
  border-right-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-x-white\/45 {
  border-left-color: rgb(255 255 255 / 0.45) !important;
  border-right-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-x-white\/5 {
  border-left-color: rgb(255 255 255 / 0.05) !important;
  border-right-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-x-white\/50 {
  border-left-color: rgb(255 255 255 / 0.5) !important;
  border-right-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-x-white\/55 {
  border-left-color: rgb(255 255 255 / 0.55) !important;
  border-right-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-x-white\/60 {
  border-left-color: rgb(255 255 255 / 0.6) !important;
  border-right-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-x-white\/65 {
  border-left-color: rgb(255 255 255 / 0.65) !important;
  border-right-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-x-white\/70 {
  border-left-color: rgb(255 255 255 / 0.7) !important;
  border-right-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-x-white\/75 {
  border-left-color: rgb(255 255 255 / 0.75) !important;
  border-right-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-x-white\/80 {
  border-left-color: rgb(255 255 255 / 0.8) !important;
  border-right-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-x-white\/85 {
  border-left-color: rgb(255 255 255 / 0.85) !important;
  border-right-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-x-white\/90 {
  border-left-color: rgb(255 255 255 / 0.9) !important;
  border-right-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-x-white\/95 {
  border-left-color: rgb(255 255 255 / 0.95) !important;
  border-right-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-x-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-100\/0 {
  border-left-color: rgb(254 249 195 / 0) !important;
  border-right-color: rgb(254 249 195 / 0) !important;
}

.tw-border-x-yellow-100\/10 {
  border-left-color: rgb(254 249 195 / 0.1) !important;
  border-right-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-x-yellow-100\/100 {
  border-left-color: rgb(254 249 195 / 1) !important;
  border-right-color: rgb(254 249 195 / 1) !important;
}

.tw-border-x-yellow-100\/15 {
  border-left-color: rgb(254 249 195 / 0.15) !important;
  border-right-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-x-yellow-100\/20 {
  border-left-color: rgb(254 249 195 / 0.2) !important;
  border-right-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-x-yellow-100\/25 {
  border-left-color: rgb(254 249 195 / 0.25) !important;
  border-right-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-x-yellow-100\/30 {
  border-left-color: rgb(254 249 195 / 0.3) !important;
  border-right-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-x-yellow-100\/35 {
  border-left-color: rgb(254 249 195 / 0.35) !important;
  border-right-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-x-yellow-100\/40 {
  border-left-color: rgb(254 249 195 / 0.4) !important;
  border-right-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-x-yellow-100\/45 {
  border-left-color: rgb(254 249 195 / 0.45) !important;
  border-right-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-x-yellow-100\/5 {
  border-left-color: rgb(254 249 195 / 0.05) !important;
  border-right-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-x-yellow-100\/50 {
  border-left-color: rgb(254 249 195 / 0.5) !important;
  border-right-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-x-yellow-100\/55 {
  border-left-color: rgb(254 249 195 / 0.55) !important;
  border-right-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-x-yellow-100\/60 {
  border-left-color: rgb(254 249 195 / 0.6) !important;
  border-right-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-x-yellow-100\/65 {
  border-left-color: rgb(254 249 195 / 0.65) !important;
  border-right-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-x-yellow-100\/70 {
  border-left-color: rgb(254 249 195 / 0.7) !important;
  border-right-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-x-yellow-100\/75 {
  border-left-color: rgb(254 249 195 / 0.75) !important;
  border-right-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-x-yellow-100\/80 {
  border-left-color: rgb(254 249 195 / 0.8) !important;
  border-right-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-x-yellow-100\/85 {
  border-left-color: rgb(254 249 195 / 0.85) !important;
  border-right-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-x-yellow-100\/90 {
  border-left-color: rgb(254 249 195 / 0.9) !important;
  border-right-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-x-yellow-100\/95 {
  border-left-color: rgb(254 249 195 / 0.95) !important;
  border-right-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-x-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-200\/0 {
  border-left-color: rgb(254 240 138 / 0) !important;
  border-right-color: rgb(254 240 138 / 0) !important;
}

.tw-border-x-yellow-200\/10 {
  border-left-color: rgb(254 240 138 / 0.1) !important;
  border-right-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-x-yellow-200\/100 {
  border-left-color: rgb(254 240 138 / 1) !important;
  border-right-color: rgb(254 240 138 / 1) !important;
}

.tw-border-x-yellow-200\/15 {
  border-left-color: rgb(254 240 138 / 0.15) !important;
  border-right-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-x-yellow-200\/20 {
  border-left-color: rgb(254 240 138 / 0.2) !important;
  border-right-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-x-yellow-200\/25 {
  border-left-color: rgb(254 240 138 / 0.25) !important;
  border-right-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-x-yellow-200\/30 {
  border-left-color: rgb(254 240 138 / 0.3) !important;
  border-right-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-x-yellow-200\/35 {
  border-left-color: rgb(254 240 138 / 0.35) !important;
  border-right-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-x-yellow-200\/40 {
  border-left-color: rgb(254 240 138 / 0.4) !important;
  border-right-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-x-yellow-200\/45 {
  border-left-color: rgb(254 240 138 / 0.45) !important;
  border-right-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-x-yellow-200\/5 {
  border-left-color: rgb(254 240 138 / 0.05) !important;
  border-right-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-x-yellow-200\/50 {
  border-left-color: rgb(254 240 138 / 0.5) !important;
  border-right-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-x-yellow-200\/55 {
  border-left-color: rgb(254 240 138 / 0.55) !important;
  border-right-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-x-yellow-200\/60 {
  border-left-color: rgb(254 240 138 / 0.6) !important;
  border-right-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-x-yellow-200\/65 {
  border-left-color: rgb(254 240 138 / 0.65) !important;
  border-right-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-x-yellow-200\/70 {
  border-left-color: rgb(254 240 138 / 0.7) !important;
  border-right-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-x-yellow-200\/75 {
  border-left-color: rgb(254 240 138 / 0.75) !important;
  border-right-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-x-yellow-200\/80 {
  border-left-color: rgb(254 240 138 / 0.8) !important;
  border-right-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-x-yellow-200\/85 {
  border-left-color: rgb(254 240 138 / 0.85) !important;
  border-right-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-x-yellow-200\/90 {
  border-left-color: rgb(254 240 138 / 0.9) !important;
  border-right-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-x-yellow-200\/95 {
  border-left-color: rgb(254 240 138 / 0.95) !important;
  border-right-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-x-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-300\/0 {
  border-left-color: rgb(253 224 71 / 0) !important;
  border-right-color: rgb(253 224 71 / 0) !important;
}

.tw-border-x-yellow-300\/10 {
  border-left-color: rgb(253 224 71 / 0.1) !important;
  border-right-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-x-yellow-300\/100 {
  border-left-color: rgb(253 224 71 / 1) !important;
  border-right-color: rgb(253 224 71 / 1) !important;
}

.tw-border-x-yellow-300\/15 {
  border-left-color: rgb(253 224 71 / 0.15) !important;
  border-right-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-x-yellow-300\/20 {
  border-left-color: rgb(253 224 71 / 0.2) !important;
  border-right-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-x-yellow-300\/25 {
  border-left-color: rgb(253 224 71 / 0.25) !important;
  border-right-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-x-yellow-300\/30 {
  border-left-color: rgb(253 224 71 / 0.3) !important;
  border-right-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-x-yellow-300\/35 {
  border-left-color: rgb(253 224 71 / 0.35) !important;
  border-right-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-x-yellow-300\/40 {
  border-left-color: rgb(253 224 71 / 0.4) !important;
  border-right-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-x-yellow-300\/45 {
  border-left-color: rgb(253 224 71 / 0.45) !important;
  border-right-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-x-yellow-300\/5 {
  border-left-color: rgb(253 224 71 / 0.05) !important;
  border-right-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-x-yellow-300\/50 {
  border-left-color: rgb(253 224 71 / 0.5) !important;
  border-right-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-x-yellow-300\/55 {
  border-left-color: rgb(253 224 71 / 0.55) !important;
  border-right-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-x-yellow-300\/60 {
  border-left-color: rgb(253 224 71 / 0.6) !important;
  border-right-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-x-yellow-300\/65 {
  border-left-color: rgb(253 224 71 / 0.65) !important;
  border-right-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-x-yellow-300\/70 {
  border-left-color: rgb(253 224 71 / 0.7) !important;
  border-right-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-x-yellow-300\/75 {
  border-left-color: rgb(253 224 71 / 0.75) !important;
  border-right-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-x-yellow-300\/80 {
  border-left-color: rgb(253 224 71 / 0.8) !important;
  border-right-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-x-yellow-300\/85 {
  border-left-color: rgb(253 224 71 / 0.85) !important;
  border-right-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-x-yellow-300\/90 {
  border-left-color: rgb(253 224 71 / 0.9) !important;
  border-right-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-x-yellow-300\/95 {
  border-left-color: rgb(253 224 71 / 0.95) !important;
  border-right-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-x-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-400\/0 {
  border-left-color: rgb(250 204 21 / 0) !important;
  border-right-color: rgb(250 204 21 / 0) !important;
}

.tw-border-x-yellow-400\/10 {
  border-left-color: rgb(250 204 21 / 0.1) !important;
  border-right-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-x-yellow-400\/100 {
  border-left-color: rgb(250 204 21 / 1) !important;
  border-right-color: rgb(250 204 21 / 1) !important;
}

.tw-border-x-yellow-400\/15 {
  border-left-color: rgb(250 204 21 / 0.15) !important;
  border-right-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-x-yellow-400\/20 {
  border-left-color: rgb(250 204 21 / 0.2) !important;
  border-right-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-x-yellow-400\/25 {
  border-left-color: rgb(250 204 21 / 0.25) !important;
  border-right-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-x-yellow-400\/30 {
  border-left-color: rgb(250 204 21 / 0.3) !important;
  border-right-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-x-yellow-400\/35 {
  border-left-color: rgb(250 204 21 / 0.35) !important;
  border-right-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-x-yellow-400\/40 {
  border-left-color: rgb(250 204 21 / 0.4) !important;
  border-right-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-x-yellow-400\/45 {
  border-left-color: rgb(250 204 21 / 0.45) !important;
  border-right-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-x-yellow-400\/5 {
  border-left-color: rgb(250 204 21 / 0.05) !important;
  border-right-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-x-yellow-400\/50 {
  border-left-color: rgb(250 204 21 / 0.5) !important;
  border-right-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-x-yellow-400\/55 {
  border-left-color: rgb(250 204 21 / 0.55) !important;
  border-right-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-x-yellow-400\/60 {
  border-left-color: rgb(250 204 21 / 0.6) !important;
  border-right-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-x-yellow-400\/65 {
  border-left-color: rgb(250 204 21 / 0.65) !important;
  border-right-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-x-yellow-400\/70 {
  border-left-color: rgb(250 204 21 / 0.7) !important;
  border-right-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-x-yellow-400\/75 {
  border-left-color: rgb(250 204 21 / 0.75) !important;
  border-right-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-x-yellow-400\/80 {
  border-left-color: rgb(250 204 21 / 0.8) !important;
  border-right-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-x-yellow-400\/85 {
  border-left-color: rgb(250 204 21 / 0.85) !important;
  border-right-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-x-yellow-400\/90 {
  border-left-color: rgb(250 204 21 / 0.9) !important;
  border-right-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-x-yellow-400\/95 {
  border-left-color: rgb(250 204 21 / 0.95) !important;
  border-right-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-x-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-50\/0 {
  border-left-color: rgb(254 252 232 / 0) !important;
  border-right-color: rgb(254 252 232 / 0) !important;
}

.tw-border-x-yellow-50\/10 {
  border-left-color: rgb(254 252 232 / 0.1) !important;
  border-right-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-x-yellow-50\/100 {
  border-left-color: rgb(254 252 232 / 1) !important;
  border-right-color: rgb(254 252 232 / 1) !important;
}

.tw-border-x-yellow-50\/15 {
  border-left-color: rgb(254 252 232 / 0.15) !important;
  border-right-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-x-yellow-50\/20 {
  border-left-color: rgb(254 252 232 / 0.2) !important;
  border-right-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-x-yellow-50\/25 {
  border-left-color: rgb(254 252 232 / 0.25) !important;
  border-right-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-x-yellow-50\/30 {
  border-left-color: rgb(254 252 232 / 0.3) !important;
  border-right-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-x-yellow-50\/35 {
  border-left-color: rgb(254 252 232 / 0.35) !important;
  border-right-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-x-yellow-50\/40 {
  border-left-color: rgb(254 252 232 / 0.4) !important;
  border-right-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-x-yellow-50\/45 {
  border-left-color: rgb(254 252 232 / 0.45) !important;
  border-right-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-x-yellow-50\/5 {
  border-left-color: rgb(254 252 232 / 0.05) !important;
  border-right-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-x-yellow-50\/50 {
  border-left-color: rgb(254 252 232 / 0.5) !important;
  border-right-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-x-yellow-50\/55 {
  border-left-color: rgb(254 252 232 / 0.55) !important;
  border-right-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-x-yellow-50\/60 {
  border-left-color: rgb(254 252 232 / 0.6) !important;
  border-right-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-x-yellow-50\/65 {
  border-left-color: rgb(254 252 232 / 0.65) !important;
  border-right-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-x-yellow-50\/70 {
  border-left-color: rgb(254 252 232 / 0.7) !important;
  border-right-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-x-yellow-50\/75 {
  border-left-color: rgb(254 252 232 / 0.75) !important;
  border-right-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-x-yellow-50\/80 {
  border-left-color: rgb(254 252 232 / 0.8) !important;
  border-right-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-x-yellow-50\/85 {
  border-left-color: rgb(254 252 232 / 0.85) !important;
  border-right-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-x-yellow-50\/90 {
  border-left-color: rgb(254 252 232 / 0.9) !important;
  border-right-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-x-yellow-50\/95 {
  border-left-color: rgb(254 252 232 / 0.95) !important;
  border-right-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-x-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-500\/0 {
  border-left-color: rgb(234 179 8 / 0) !important;
  border-right-color: rgb(234 179 8 / 0) !important;
}

.tw-border-x-yellow-500\/10 {
  border-left-color: rgb(234 179 8 / 0.1) !important;
  border-right-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-x-yellow-500\/100 {
  border-left-color: rgb(234 179 8 / 1) !important;
  border-right-color: rgb(234 179 8 / 1) !important;
}

.tw-border-x-yellow-500\/15 {
  border-left-color: rgb(234 179 8 / 0.15) !important;
  border-right-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-x-yellow-500\/20 {
  border-left-color: rgb(234 179 8 / 0.2) !important;
  border-right-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-x-yellow-500\/25 {
  border-left-color: rgb(234 179 8 / 0.25) !important;
  border-right-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-x-yellow-500\/30 {
  border-left-color: rgb(234 179 8 / 0.3) !important;
  border-right-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-x-yellow-500\/35 {
  border-left-color: rgb(234 179 8 / 0.35) !important;
  border-right-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-x-yellow-500\/40 {
  border-left-color: rgb(234 179 8 / 0.4) !important;
  border-right-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-x-yellow-500\/45 {
  border-left-color: rgb(234 179 8 / 0.45) !important;
  border-right-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-x-yellow-500\/5 {
  border-left-color: rgb(234 179 8 / 0.05) !important;
  border-right-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-x-yellow-500\/50 {
  border-left-color: rgb(234 179 8 / 0.5) !important;
  border-right-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-x-yellow-500\/55 {
  border-left-color: rgb(234 179 8 / 0.55) !important;
  border-right-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-x-yellow-500\/60 {
  border-left-color: rgb(234 179 8 / 0.6) !important;
  border-right-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-x-yellow-500\/65 {
  border-left-color: rgb(234 179 8 / 0.65) !important;
  border-right-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-x-yellow-500\/70 {
  border-left-color: rgb(234 179 8 / 0.7) !important;
  border-right-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-x-yellow-500\/75 {
  border-left-color: rgb(234 179 8 / 0.75) !important;
  border-right-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-x-yellow-500\/80 {
  border-left-color: rgb(234 179 8 / 0.8) !important;
  border-right-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-x-yellow-500\/85 {
  border-left-color: rgb(234 179 8 / 0.85) !important;
  border-right-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-x-yellow-500\/90 {
  border-left-color: rgb(234 179 8 / 0.9) !important;
  border-right-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-x-yellow-500\/95 {
  border-left-color: rgb(234 179 8 / 0.95) !important;
  border-right-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-x-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-600\/0 {
  border-left-color: rgb(202 138 4 / 0) !important;
  border-right-color: rgb(202 138 4 / 0) !important;
}

.tw-border-x-yellow-600\/10 {
  border-left-color: rgb(202 138 4 / 0.1) !important;
  border-right-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-x-yellow-600\/100 {
  border-left-color: rgb(202 138 4 / 1) !important;
  border-right-color: rgb(202 138 4 / 1) !important;
}

.tw-border-x-yellow-600\/15 {
  border-left-color: rgb(202 138 4 / 0.15) !important;
  border-right-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-x-yellow-600\/20 {
  border-left-color: rgb(202 138 4 / 0.2) !important;
  border-right-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-x-yellow-600\/25 {
  border-left-color: rgb(202 138 4 / 0.25) !important;
  border-right-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-x-yellow-600\/30 {
  border-left-color: rgb(202 138 4 / 0.3) !important;
  border-right-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-x-yellow-600\/35 {
  border-left-color: rgb(202 138 4 / 0.35) !important;
  border-right-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-x-yellow-600\/40 {
  border-left-color: rgb(202 138 4 / 0.4) !important;
  border-right-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-x-yellow-600\/45 {
  border-left-color: rgb(202 138 4 / 0.45) !important;
  border-right-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-x-yellow-600\/5 {
  border-left-color: rgb(202 138 4 / 0.05) !important;
  border-right-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-x-yellow-600\/50 {
  border-left-color: rgb(202 138 4 / 0.5) !important;
  border-right-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-x-yellow-600\/55 {
  border-left-color: rgb(202 138 4 / 0.55) !important;
  border-right-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-x-yellow-600\/60 {
  border-left-color: rgb(202 138 4 / 0.6) !important;
  border-right-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-x-yellow-600\/65 {
  border-left-color: rgb(202 138 4 / 0.65) !important;
  border-right-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-x-yellow-600\/70 {
  border-left-color: rgb(202 138 4 / 0.7) !important;
  border-right-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-x-yellow-600\/75 {
  border-left-color: rgb(202 138 4 / 0.75) !important;
  border-right-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-x-yellow-600\/80 {
  border-left-color: rgb(202 138 4 / 0.8) !important;
  border-right-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-x-yellow-600\/85 {
  border-left-color: rgb(202 138 4 / 0.85) !important;
  border-right-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-x-yellow-600\/90 {
  border-left-color: rgb(202 138 4 / 0.9) !important;
  border-right-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-x-yellow-600\/95 {
  border-left-color: rgb(202 138 4 / 0.95) !important;
  border-right-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-x-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-700\/0 {
  border-left-color: rgb(161 98 7 / 0) !important;
  border-right-color: rgb(161 98 7 / 0) !important;
}

.tw-border-x-yellow-700\/10 {
  border-left-color: rgb(161 98 7 / 0.1) !important;
  border-right-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-x-yellow-700\/100 {
  border-left-color: rgb(161 98 7 / 1) !important;
  border-right-color: rgb(161 98 7 / 1) !important;
}

.tw-border-x-yellow-700\/15 {
  border-left-color: rgb(161 98 7 / 0.15) !important;
  border-right-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-x-yellow-700\/20 {
  border-left-color: rgb(161 98 7 / 0.2) !important;
  border-right-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-x-yellow-700\/25 {
  border-left-color: rgb(161 98 7 / 0.25) !important;
  border-right-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-x-yellow-700\/30 {
  border-left-color: rgb(161 98 7 / 0.3) !important;
  border-right-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-x-yellow-700\/35 {
  border-left-color: rgb(161 98 7 / 0.35) !important;
  border-right-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-x-yellow-700\/40 {
  border-left-color: rgb(161 98 7 / 0.4) !important;
  border-right-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-x-yellow-700\/45 {
  border-left-color: rgb(161 98 7 / 0.45) !important;
  border-right-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-x-yellow-700\/5 {
  border-left-color: rgb(161 98 7 / 0.05) !important;
  border-right-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-x-yellow-700\/50 {
  border-left-color: rgb(161 98 7 / 0.5) !important;
  border-right-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-x-yellow-700\/55 {
  border-left-color: rgb(161 98 7 / 0.55) !important;
  border-right-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-x-yellow-700\/60 {
  border-left-color: rgb(161 98 7 / 0.6) !important;
  border-right-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-x-yellow-700\/65 {
  border-left-color: rgb(161 98 7 / 0.65) !important;
  border-right-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-x-yellow-700\/70 {
  border-left-color: rgb(161 98 7 / 0.7) !important;
  border-right-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-x-yellow-700\/75 {
  border-left-color: rgb(161 98 7 / 0.75) !important;
  border-right-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-x-yellow-700\/80 {
  border-left-color: rgb(161 98 7 / 0.8) !important;
  border-right-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-x-yellow-700\/85 {
  border-left-color: rgb(161 98 7 / 0.85) !important;
  border-right-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-x-yellow-700\/90 {
  border-left-color: rgb(161 98 7 / 0.9) !important;
  border-right-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-x-yellow-700\/95 {
  border-left-color: rgb(161 98 7 / 0.95) !important;
  border-right-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-x-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-800\/0 {
  border-left-color: rgb(133 77 14 / 0) !important;
  border-right-color: rgb(133 77 14 / 0) !important;
}

.tw-border-x-yellow-800\/10 {
  border-left-color: rgb(133 77 14 / 0.1) !important;
  border-right-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-x-yellow-800\/100 {
  border-left-color: rgb(133 77 14 / 1) !important;
  border-right-color: rgb(133 77 14 / 1) !important;
}

.tw-border-x-yellow-800\/15 {
  border-left-color: rgb(133 77 14 / 0.15) !important;
  border-right-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-x-yellow-800\/20 {
  border-left-color: rgb(133 77 14 / 0.2) !important;
  border-right-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-x-yellow-800\/25 {
  border-left-color: rgb(133 77 14 / 0.25) !important;
  border-right-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-x-yellow-800\/30 {
  border-left-color: rgb(133 77 14 / 0.3) !important;
  border-right-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-x-yellow-800\/35 {
  border-left-color: rgb(133 77 14 / 0.35) !important;
  border-right-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-x-yellow-800\/40 {
  border-left-color: rgb(133 77 14 / 0.4) !important;
  border-right-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-x-yellow-800\/45 {
  border-left-color: rgb(133 77 14 / 0.45) !important;
  border-right-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-x-yellow-800\/5 {
  border-left-color: rgb(133 77 14 / 0.05) !important;
  border-right-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-x-yellow-800\/50 {
  border-left-color: rgb(133 77 14 / 0.5) !important;
  border-right-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-x-yellow-800\/55 {
  border-left-color: rgb(133 77 14 / 0.55) !important;
  border-right-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-x-yellow-800\/60 {
  border-left-color: rgb(133 77 14 / 0.6) !important;
  border-right-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-x-yellow-800\/65 {
  border-left-color: rgb(133 77 14 / 0.65) !important;
  border-right-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-x-yellow-800\/70 {
  border-left-color: rgb(133 77 14 / 0.7) !important;
  border-right-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-x-yellow-800\/75 {
  border-left-color: rgb(133 77 14 / 0.75) !important;
  border-right-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-x-yellow-800\/80 {
  border-left-color: rgb(133 77 14 / 0.8) !important;
  border-right-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-x-yellow-800\/85 {
  border-left-color: rgb(133 77 14 / 0.85) !important;
  border-right-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-x-yellow-800\/90 {
  border-left-color: rgb(133 77 14 / 0.9) !important;
  border-right-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-x-yellow-800\/95 {
  border-left-color: rgb(133 77 14 / 0.95) !important;
  border-right-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-x-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-900\/0 {
  border-left-color: rgb(113 63 18 / 0) !important;
  border-right-color: rgb(113 63 18 / 0) !important;
}

.tw-border-x-yellow-900\/10 {
  border-left-color: rgb(113 63 18 / 0.1) !important;
  border-right-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-x-yellow-900\/100 {
  border-left-color: rgb(113 63 18 / 1) !important;
  border-right-color: rgb(113 63 18 / 1) !important;
}

.tw-border-x-yellow-900\/15 {
  border-left-color: rgb(113 63 18 / 0.15) !important;
  border-right-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-x-yellow-900\/20 {
  border-left-color: rgb(113 63 18 / 0.2) !important;
  border-right-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-x-yellow-900\/25 {
  border-left-color: rgb(113 63 18 / 0.25) !important;
  border-right-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-x-yellow-900\/30 {
  border-left-color: rgb(113 63 18 / 0.3) !important;
  border-right-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-x-yellow-900\/35 {
  border-left-color: rgb(113 63 18 / 0.35) !important;
  border-right-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-x-yellow-900\/40 {
  border-left-color: rgb(113 63 18 / 0.4) !important;
  border-right-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-x-yellow-900\/45 {
  border-left-color: rgb(113 63 18 / 0.45) !important;
  border-right-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-x-yellow-900\/5 {
  border-left-color: rgb(113 63 18 / 0.05) !important;
  border-right-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-x-yellow-900\/50 {
  border-left-color: rgb(113 63 18 / 0.5) !important;
  border-right-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-x-yellow-900\/55 {
  border-left-color: rgb(113 63 18 / 0.55) !important;
  border-right-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-x-yellow-900\/60 {
  border-left-color: rgb(113 63 18 / 0.6) !important;
  border-right-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-x-yellow-900\/65 {
  border-left-color: rgb(113 63 18 / 0.65) !important;
  border-right-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-x-yellow-900\/70 {
  border-left-color: rgb(113 63 18 / 0.7) !important;
  border-right-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-x-yellow-900\/75 {
  border-left-color: rgb(113 63 18 / 0.75) !important;
  border-right-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-x-yellow-900\/80 {
  border-left-color: rgb(113 63 18 / 0.8) !important;
  border-right-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-x-yellow-900\/85 {
  border-left-color: rgb(113 63 18 / 0.85) !important;
  border-right-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-x-yellow-900\/90 {
  border-left-color: rgb(113 63 18 / 0.9) !important;
  border-right-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-x-yellow-900\/95 {
  border-left-color: rgb(113 63 18 / 0.95) !important;
  border-right-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-x-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-x-yellow-950\/0 {
  border-left-color: rgb(66 32 6 / 0) !important;
  border-right-color: rgb(66 32 6 / 0) !important;
}

.tw-border-x-yellow-950\/10 {
  border-left-color: rgb(66 32 6 / 0.1) !important;
  border-right-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-x-yellow-950\/100 {
  border-left-color: rgb(66 32 6 / 1) !important;
  border-right-color: rgb(66 32 6 / 1) !important;
}

.tw-border-x-yellow-950\/15 {
  border-left-color: rgb(66 32 6 / 0.15) !important;
  border-right-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-x-yellow-950\/20 {
  border-left-color: rgb(66 32 6 / 0.2) !important;
  border-right-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-x-yellow-950\/25 {
  border-left-color: rgb(66 32 6 / 0.25) !important;
  border-right-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-x-yellow-950\/30 {
  border-left-color: rgb(66 32 6 / 0.3) !important;
  border-right-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-x-yellow-950\/35 {
  border-left-color: rgb(66 32 6 / 0.35) !important;
  border-right-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-x-yellow-950\/40 {
  border-left-color: rgb(66 32 6 / 0.4) !important;
  border-right-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-x-yellow-950\/45 {
  border-left-color: rgb(66 32 6 / 0.45) !important;
  border-right-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-x-yellow-950\/5 {
  border-left-color: rgb(66 32 6 / 0.05) !important;
  border-right-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-x-yellow-950\/50 {
  border-left-color: rgb(66 32 6 / 0.5) !important;
  border-right-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-x-yellow-950\/55 {
  border-left-color: rgb(66 32 6 / 0.55) !important;
  border-right-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-x-yellow-950\/60 {
  border-left-color: rgb(66 32 6 / 0.6) !important;
  border-right-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-x-yellow-950\/65 {
  border-left-color: rgb(66 32 6 / 0.65) !important;
  border-right-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-x-yellow-950\/70 {
  border-left-color: rgb(66 32 6 / 0.7) !important;
  border-right-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-x-yellow-950\/75 {
  border-left-color: rgb(66 32 6 / 0.75) !important;
  border-right-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-x-yellow-950\/80 {
  border-left-color: rgb(66 32 6 / 0.8) !important;
  border-right-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-x-yellow-950\/85 {
  border-left-color: rgb(66 32 6 / 0.85) !important;
  border-right-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-x-yellow-950\/90 {
  border-left-color: rgb(66 32 6 / 0.9) !important;
  border-right-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-x-yellow-950\/95 {
  border-left-color: rgb(66 32 6 / 0.95) !important;
  border-right-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-x-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-100\/0 {
  border-left-color: rgb(244 244 245 / 0) !important;
  border-right-color: rgb(244 244 245 / 0) !important;
}

.tw-border-x-zinc-100\/10 {
  border-left-color: rgb(244 244 245 / 0.1) !important;
  border-right-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-x-zinc-100\/100 {
  border-left-color: rgb(244 244 245 / 1) !important;
  border-right-color: rgb(244 244 245 / 1) !important;
}

.tw-border-x-zinc-100\/15 {
  border-left-color: rgb(244 244 245 / 0.15) !important;
  border-right-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-x-zinc-100\/20 {
  border-left-color: rgb(244 244 245 / 0.2) !important;
  border-right-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-x-zinc-100\/25 {
  border-left-color: rgb(244 244 245 / 0.25) !important;
  border-right-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-x-zinc-100\/30 {
  border-left-color: rgb(244 244 245 / 0.3) !important;
  border-right-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-x-zinc-100\/35 {
  border-left-color: rgb(244 244 245 / 0.35) !important;
  border-right-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-x-zinc-100\/40 {
  border-left-color: rgb(244 244 245 / 0.4) !important;
  border-right-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-x-zinc-100\/45 {
  border-left-color: rgb(244 244 245 / 0.45) !important;
  border-right-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-x-zinc-100\/5 {
  border-left-color: rgb(244 244 245 / 0.05) !important;
  border-right-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-x-zinc-100\/50 {
  border-left-color: rgb(244 244 245 / 0.5) !important;
  border-right-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-x-zinc-100\/55 {
  border-left-color: rgb(244 244 245 / 0.55) !important;
  border-right-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-x-zinc-100\/60 {
  border-left-color: rgb(244 244 245 / 0.6) !important;
  border-right-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-x-zinc-100\/65 {
  border-left-color: rgb(244 244 245 / 0.65) !important;
  border-right-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-x-zinc-100\/70 {
  border-left-color: rgb(244 244 245 / 0.7) !important;
  border-right-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-x-zinc-100\/75 {
  border-left-color: rgb(244 244 245 / 0.75) !important;
  border-right-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-x-zinc-100\/80 {
  border-left-color: rgb(244 244 245 / 0.8) !important;
  border-right-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-x-zinc-100\/85 {
  border-left-color: rgb(244 244 245 / 0.85) !important;
  border-right-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-x-zinc-100\/90 {
  border-left-color: rgb(244 244 245 / 0.9) !important;
  border-right-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-x-zinc-100\/95 {
  border-left-color: rgb(244 244 245 / 0.95) !important;
  border-right-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-x-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-200\/0 {
  border-left-color: rgb(228 228 231 / 0) !important;
  border-right-color: rgb(228 228 231 / 0) !important;
}

.tw-border-x-zinc-200\/10 {
  border-left-color: rgb(228 228 231 / 0.1) !important;
  border-right-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-x-zinc-200\/100 {
  border-left-color: rgb(228 228 231 / 1) !important;
  border-right-color: rgb(228 228 231 / 1) !important;
}

.tw-border-x-zinc-200\/15 {
  border-left-color: rgb(228 228 231 / 0.15) !important;
  border-right-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-x-zinc-200\/20 {
  border-left-color: rgb(228 228 231 / 0.2) !important;
  border-right-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-x-zinc-200\/25 {
  border-left-color: rgb(228 228 231 / 0.25) !important;
  border-right-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-x-zinc-200\/30 {
  border-left-color: rgb(228 228 231 / 0.3) !important;
  border-right-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-x-zinc-200\/35 {
  border-left-color: rgb(228 228 231 / 0.35) !important;
  border-right-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-x-zinc-200\/40 {
  border-left-color: rgb(228 228 231 / 0.4) !important;
  border-right-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-x-zinc-200\/45 {
  border-left-color: rgb(228 228 231 / 0.45) !important;
  border-right-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-x-zinc-200\/5 {
  border-left-color: rgb(228 228 231 / 0.05) !important;
  border-right-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-x-zinc-200\/50 {
  border-left-color: rgb(228 228 231 / 0.5) !important;
  border-right-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-x-zinc-200\/55 {
  border-left-color: rgb(228 228 231 / 0.55) !important;
  border-right-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-x-zinc-200\/60 {
  border-left-color: rgb(228 228 231 / 0.6) !important;
  border-right-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-x-zinc-200\/65 {
  border-left-color: rgb(228 228 231 / 0.65) !important;
  border-right-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-x-zinc-200\/70 {
  border-left-color: rgb(228 228 231 / 0.7) !important;
  border-right-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-x-zinc-200\/75 {
  border-left-color: rgb(228 228 231 / 0.75) !important;
  border-right-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-x-zinc-200\/80 {
  border-left-color: rgb(228 228 231 / 0.8) !important;
  border-right-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-x-zinc-200\/85 {
  border-left-color: rgb(228 228 231 / 0.85) !important;
  border-right-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-x-zinc-200\/90 {
  border-left-color: rgb(228 228 231 / 0.9) !important;
  border-right-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-x-zinc-200\/95 {
  border-left-color: rgb(228 228 231 / 0.95) !important;
  border-right-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-x-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-300\/0 {
  border-left-color: rgb(212 212 216 / 0) !important;
  border-right-color: rgb(212 212 216 / 0) !important;
}

.tw-border-x-zinc-300\/10 {
  border-left-color: rgb(212 212 216 / 0.1) !important;
  border-right-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-x-zinc-300\/100 {
  border-left-color: rgb(212 212 216 / 1) !important;
  border-right-color: rgb(212 212 216 / 1) !important;
}

.tw-border-x-zinc-300\/15 {
  border-left-color: rgb(212 212 216 / 0.15) !important;
  border-right-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-x-zinc-300\/20 {
  border-left-color: rgb(212 212 216 / 0.2) !important;
  border-right-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-x-zinc-300\/25 {
  border-left-color: rgb(212 212 216 / 0.25) !important;
  border-right-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-x-zinc-300\/30 {
  border-left-color: rgb(212 212 216 / 0.3) !important;
  border-right-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-x-zinc-300\/35 {
  border-left-color: rgb(212 212 216 / 0.35) !important;
  border-right-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-x-zinc-300\/40 {
  border-left-color: rgb(212 212 216 / 0.4) !important;
  border-right-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-x-zinc-300\/45 {
  border-left-color: rgb(212 212 216 / 0.45) !important;
  border-right-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-x-zinc-300\/5 {
  border-left-color: rgb(212 212 216 / 0.05) !important;
  border-right-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-x-zinc-300\/50 {
  border-left-color: rgb(212 212 216 / 0.5) !important;
  border-right-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-x-zinc-300\/55 {
  border-left-color: rgb(212 212 216 / 0.55) !important;
  border-right-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-x-zinc-300\/60 {
  border-left-color: rgb(212 212 216 / 0.6) !important;
  border-right-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-x-zinc-300\/65 {
  border-left-color: rgb(212 212 216 / 0.65) !important;
  border-right-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-x-zinc-300\/70 {
  border-left-color: rgb(212 212 216 / 0.7) !important;
  border-right-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-x-zinc-300\/75 {
  border-left-color: rgb(212 212 216 / 0.75) !important;
  border-right-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-x-zinc-300\/80 {
  border-left-color: rgb(212 212 216 / 0.8) !important;
  border-right-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-x-zinc-300\/85 {
  border-left-color: rgb(212 212 216 / 0.85) !important;
  border-right-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-x-zinc-300\/90 {
  border-left-color: rgb(212 212 216 / 0.9) !important;
  border-right-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-x-zinc-300\/95 {
  border-left-color: rgb(212 212 216 / 0.95) !important;
  border-right-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-x-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-400\/0 {
  border-left-color: rgb(161 161 170 / 0) !important;
  border-right-color: rgb(161 161 170 / 0) !important;
}

.tw-border-x-zinc-400\/10 {
  border-left-color: rgb(161 161 170 / 0.1) !important;
  border-right-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-x-zinc-400\/100 {
  border-left-color: rgb(161 161 170 / 1) !important;
  border-right-color: rgb(161 161 170 / 1) !important;
}

.tw-border-x-zinc-400\/15 {
  border-left-color: rgb(161 161 170 / 0.15) !important;
  border-right-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-x-zinc-400\/20 {
  border-left-color: rgb(161 161 170 / 0.2) !important;
  border-right-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-x-zinc-400\/25 {
  border-left-color: rgb(161 161 170 / 0.25) !important;
  border-right-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-x-zinc-400\/30 {
  border-left-color: rgb(161 161 170 / 0.3) !important;
  border-right-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-x-zinc-400\/35 {
  border-left-color: rgb(161 161 170 / 0.35) !important;
  border-right-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-x-zinc-400\/40 {
  border-left-color: rgb(161 161 170 / 0.4) !important;
  border-right-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-x-zinc-400\/45 {
  border-left-color: rgb(161 161 170 / 0.45) !important;
  border-right-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-x-zinc-400\/5 {
  border-left-color: rgb(161 161 170 / 0.05) !important;
  border-right-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-x-zinc-400\/50 {
  border-left-color: rgb(161 161 170 / 0.5) !important;
  border-right-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-x-zinc-400\/55 {
  border-left-color: rgb(161 161 170 / 0.55) !important;
  border-right-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-x-zinc-400\/60 {
  border-left-color: rgb(161 161 170 / 0.6) !important;
  border-right-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-x-zinc-400\/65 {
  border-left-color: rgb(161 161 170 / 0.65) !important;
  border-right-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-x-zinc-400\/70 {
  border-left-color: rgb(161 161 170 / 0.7) !important;
  border-right-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-x-zinc-400\/75 {
  border-left-color: rgb(161 161 170 / 0.75) !important;
  border-right-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-x-zinc-400\/80 {
  border-left-color: rgb(161 161 170 / 0.8) !important;
  border-right-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-x-zinc-400\/85 {
  border-left-color: rgb(161 161 170 / 0.85) !important;
  border-right-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-x-zinc-400\/90 {
  border-left-color: rgb(161 161 170 / 0.9) !important;
  border-right-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-x-zinc-400\/95 {
  border-left-color: rgb(161 161 170 / 0.95) !important;
  border-right-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-x-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-50\/0 {
  border-left-color: rgb(250 250 250 / 0) !important;
  border-right-color: rgb(250 250 250 / 0) !important;
}

.tw-border-x-zinc-50\/10 {
  border-left-color: rgb(250 250 250 / 0.1) !important;
  border-right-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-x-zinc-50\/100 {
  border-left-color: rgb(250 250 250 / 1) !important;
  border-right-color: rgb(250 250 250 / 1) !important;
}

.tw-border-x-zinc-50\/15 {
  border-left-color: rgb(250 250 250 / 0.15) !important;
  border-right-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-x-zinc-50\/20 {
  border-left-color: rgb(250 250 250 / 0.2) !important;
  border-right-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-x-zinc-50\/25 {
  border-left-color: rgb(250 250 250 / 0.25) !important;
  border-right-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-x-zinc-50\/30 {
  border-left-color: rgb(250 250 250 / 0.3) !important;
  border-right-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-x-zinc-50\/35 {
  border-left-color: rgb(250 250 250 / 0.35) !important;
  border-right-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-x-zinc-50\/40 {
  border-left-color: rgb(250 250 250 / 0.4) !important;
  border-right-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-x-zinc-50\/45 {
  border-left-color: rgb(250 250 250 / 0.45) !important;
  border-right-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-x-zinc-50\/5 {
  border-left-color: rgb(250 250 250 / 0.05) !important;
  border-right-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-x-zinc-50\/50 {
  border-left-color: rgb(250 250 250 / 0.5) !important;
  border-right-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-x-zinc-50\/55 {
  border-left-color: rgb(250 250 250 / 0.55) !important;
  border-right-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-x-zinc-50\/60 {
  border-left-color: rgb(250 250 250 / 0.6) !important;
  border-right-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-x-zinc-50\/65 {
  border-left-color: rgb(250 250 250 / 0.65) !important;
  border-right-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-x-zinc-50\/70 {
  border-left-color: rgb(250 250 250 / 0.7) !important;
  border-right-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-x-zinc-50\/75 {
  border-left-color: rgb(250 250 250 / 0.75) !important;
  border-right-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-x-zinc-50\/80 {
  border-left-color: rgb(250 250 250 / 0.8) !important;
  border-right-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-x-zinc-50\/85 {
  border-left-color: rgb(250 250 250 / 0.85) !important;
  border-right-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-x-zinc-50\/90 {
  border-left-color: rgb(250 250 250 / 0.9) !important;
  border-right-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-x-zinc-50\/95 {
  border-left-color: rgb(250 250 250 / 0.95) !important;
  border-right-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-x-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-500\/0 {
  border-left-color: rgb(113 113 122 / 0) !important;
  border-right-color: rgb(113 113 122 / 0) !important;
}

.tw-border-x-zinc-500\/10 {
  border-left-color: rgb(113 113 122 / 0.1) !important;
  border-right-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-x-zinc-500\/100 {
  border-left-color: rgb(113 113 122 / 1) !important;
  border-right-color: rgb(113 113 122 / 1) !important;
}

.tw-border-x-zinc-500\/15 {
  border-left-color: rgb(113 113 122 / 0.15) !important;
  border-right-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-x-zinc-500\/20 {
  border-left-color: rgb(113 113 122 / 0.2) !important;
  border-right-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-x-zinc-500\/25 {
  border-left-color: rgb(113 113 122 / 0.25) !important;
  border-right-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-x-zinc-500\/30 {
  border-left-color: rgb(113 113 122 / 0.3) !important;
  border-right-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-x-zinc-500\/35 {
  border-left-color: rgb(113 113 122 / 0.35) !important;
  border-right-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-x-zinc-500\/40 {
  border-left-color: rgb(113 113 122 / 0.4) !important;
  border-right-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-x-zinc-500\/45 {
  border-left-color: rgb(113 113 122 / 0.45) !important;
  border-right-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-x-zinc-500\/5 {
  border-left-color: rgb(113 113 122 / 0.05) !important;
  border-right-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-x-zinc-500\/50 {
  border-left-color: rgb(113 113 122 / 0.5) !important;
  border-right-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-x-zinc-500\/55 {
  border-left-color: rgb(113 113 122 / 0.55) !important;
  border-right-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-x-zinc-500\/60 {
  border-left-color: rgb(113 113 122 / 0.6) !important;
  border-right-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-x-zinc-500\/65 {
  border-left-color: rgb(113 113 122 / 0.65) !important;
  border-right-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-x-zinc-500\/70 {
  border-left-color: rgb(113 113 122 / 0.7) !important;
  border-right-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-x-zinc-500\/75 {
  border-left-color: rgb(113 113 122 / 0.75) !important;
  border-right-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-x-zinc-500\/80 {
  border-left-color: rgb(113 113 122 / 0.8) !important;
  border-right-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-x-zinc-500\/85 {
  border-left-color: rgb(113 113 122 / 0.85) !important;
  border-right-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-x-zinc-500\/90 {
  border-left-color: rgb(113 113 122 / 0.9) !important;
  border-right-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-x-zinc-500\/95 {
  border-left-color: rgb(113 113 122 / 0.95) !important;
  border-right-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-x-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-600\/0 {
  border-left-color: rgb(82 82 91 / 0) !important;
  border-right-color: rgb(82 82 91 / 0) !important;
}

.tw-border-x-zinc-600\/10 {
  border-left-color: rgb(82 82 91 / 0.1) !important;
  border-right-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-x-zinc-600\/100 {
  border-left-color: rgb(82 82 91 / 1) !important;
  border-right-color: rgb(82 82 91 / 1) !important;
}

.tw-border-x-zinc-600\/15 {
  border-left-color: rgb(82 82 91 / 0.15) !important;
  border-right-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-x-zinc-600\/20 {
  border-left-color: rgb(82 82 91 / 0.2) !important;
  border-right-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-x-zinc-600\/25 {
  border-left-color: rgb(82 82 91 / 0.25) !important;
  border-right-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-x-zinc-600\/30 {
  border-left-color: rgb(82 82 91 / 0.3) !important;
  border-right-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-x-zinc-600\/35 {
  border-left-color: rgb(82 82 91 / 0.35) !important;
  border-right-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-x-zinc-600\/40 {
  border-left-color: rgb(82 82 91 / 0.4) !important;
  border-right-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-x-zinc-600\/45 {
  border-left-color: rgb(82 82 91 / 0.45) !important;
  border-right-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-x-zinc-600\/5 {
  border-left-color: rgb(82 82 91 / 0.05) !important;
  border-right-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-x-zinc-600\/50 {
  border-left-color: rgb(82 82 91 / 0.5) !important;
  border-right-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-x-zinc-600\/55 {
  border-left-color: rgb(82 82 91 / 0.55) !important;
  border-right-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-x-zinc-600\/60 {
  border-left-color: rgb(82 82 91 / 0.6) !important;
  border-right-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-x-zinc-600\/65 {
  border-left-color: rgb(82 82 91 / 0.65) !important;
  border-right-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-x-zinc-600\/70 {
  border-left-color: rgb(82 82 91 / 0.7) !important;
  border-right-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-x-zinc-600\/75 {
  border-left-color: rgb(82 82 91 / 0.75) !important;
  border-right-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-x-zinc-600\/80 {
  border-left-color: rgb(82 82 91 / 0.8) !important;
  border-right-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-x-zinc-600\/85 {
  border-left-color: rgb(82 82 91 / 0.85) !important;
  border-right-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-x-zinc-600\/90 {
  border-left-color: rgb(82 82 91 / 0.9) !important;
  border-right-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-x-zinc-600\/95 {
  border-left-color: rgb(82 82 91 / 0.95) !important;
  border-right-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-x-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-700\/0 {
  border-left-color: rgb(63 63 70 / 0) !important;
  border-right-color: rgb(63 63 70 / 0) !important;
}

.tw-border-x-zinc-700\/10 {
  border-left-color: rgb(63 63 70 / 0.1) !important;
  border-right-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-x-zinc-700\/100 {
  border-left-color: rgb(63 63 70 / 1) !important;
  border-right-color: rgb(63 63 70 / 1) !important;
}

.tw-border-x-zinc-700\/15 {
  border-left-color: rgb(63 63 70 / 0.15) !important;
  border-right-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-x-zinc-700\/20 {
  border-left-color: rgb(63 63 70 / 0.2) !important;
  border-right-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-x-zinc-700\/25 {
  border-left-color: rgb(63 63 70 / 0.25) !important;
  border-right-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-x-zinc-700\/30 {
  border-left-color: rgb(63 63 70 / 0.3) !important;
  border-right-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-x-zinc-700\/35 {
  border-left-color: rgb(63 63 70 / 0.35) !important;
  border-right-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-x-zinc-700\/40 {
  border-left-color: rgb(63 63 70 / 0.4) !important;
  border-right-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-x-zinc-700\/45 {
  border-left-color: rgb(63 63 70 / 0.45) !important;
  border-right-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-x-zinc-700\/5 {
  border-left-color: rgb(63 63 70 / 0.05) !important;
  border-right-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-x-zinc-700\/50 {
  border-left-color: rgb(63 63 70 / 0.5) !important;
  border-right-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-x-zinc-700\/55 {
  border-left-color: rgb(63 63 70 / 0.55) !important;
  border-right-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-x-zinc-700\/60 {
  border-left-color: rgb(63 63 70 / 0.6) !important;
  border-right-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-x-zinc-700\/65 {
  border-left-color: rgb(63 63 70 / 0.65) !important;
  border-right-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-x-zinc-700\/70 {
  border-left-color: rgb(63 63 70 / 0.7) !important;
  border-right-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-x-zinc-700\/75 {
  border-left-color: rgb(63 63 70 / 0.75) !important;
  border-right-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-x-zinc-700\/80 {
  border-left-color: rgb(63 63 70 / 0.8) !important;
  border-right-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-x-zinc-700\/85 {
  border-left-color: rgb(63 63 70 / 0.85) !important;
  border-right-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-x-zinc-700\/90 {
  border-left-color: rgb(63 63 70 / 0.9) !important;
  border-right-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-x-zinc-700\/95 {
  border-left-color: rgb(63 63 70 / 0.95) !important;
  border-right-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-x-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-800\/0 {
  border-left-color: rgb(39 39 42 / 0) !important;
  border-right-color: rgb(39 39 42 / 0) !important;
}

.tw-border-x-zinc-800\/10 {
  border-left-color: rgb(39 39 42 / 0.1) !important;
  border-right-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-x-zinc-800\/100 {
  border-left-color: rgb(39 39 42 / 1) !important;
  border-right-color: rgb(39 39 42 / 1) !important;
}

.tw-border-x-zinc-800\/15 {
  border-left-color: rgb(39 39 42 / 0.15) !important;
  border-right-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-x-zinc-800\/20 {
  border-left-color: rgb(39 39 42 / 0.2) !important;
  border-right-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-x-zinc-800\/25 {
  border-left-color: rgb(39 39 42 / 0.25) !important;
  border-right-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-x-zinc-800\/30 {
  border-left-color: rgb(39 39 42 / 0.3) !important;
  border-right-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-x-zinc-800\/35 {
  border-left-color: rgb(39 39 42 / 0.35) !important;
  border-right-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-x-zinc-800\/40 {
  border-left-color: rgb(39 39 42 / 0.4) !important;
  border-right-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-x-zinc-800\/45 {
  border-left-color: rgb(39 39 42 / 0.45) !important;
  border-right-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-x-zinc-800\/5 {
  border-left-color: rgb(39 39 42 / 0.05) !important;
  border-right-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-x-zinc-800\/50 {
  border-left-color: rgb(39 39 42 / 0.5) !important;
  border-right-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-x-zinc-800\/55 {
  border-left-color: rgb(39 39 42 / 0.55) !important;
  border-right-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-x-zinc-800\/60 {
  border-left-color: rgb(39 39 42 / 0.6) !important;
  border-right-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-x-zinc-800\/65 {
  border-left-color: rgb(39 39 42 / 0.65) !important;
  border-right-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-x-zinc-800\/70 {
  border-left-color: rgb(39 39 42 / 0.7) !important;
  border-right-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-x-zinc-800\/75 {
  border-left-color: rgb(39 39 42 / 0.75) !important;
  border-right-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-x-zinc-800\/80 {
  border-left-color: rgb(39 39 42 / 0.8) !important;
  border-right-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-x-zinc-800\/85 {
  border-left-color: rgb(39 39 42 / 0.85) !important;
  border-right-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-x-zinc-800\/90 {
  border-left-color: rgb(39 39 42 / 0.9) !important;
  border-right-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-x-zinc-800\/95 {
  border-left-color: rgb(39 39 42 / 0.95) !important;
  border-right-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-x-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-900\/0 {
  border-left-color: rgb(24 24 27 / 0) !important;
  border-right-color: rgb(24 24 27 / 0) !important;
}

.tw-border-x-zinc-900\/10 {
  border-left-color: rgb(24 24 27 / 0.1) !important;
  border-right-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-x-zinc-900\/100 {
  border-left-color: rgb(24 24 27 / 1) !important;
  border-right-color: rgb(24 24 27 / 1) !important;
}

.tw-border-x-zinc-900\/15 {
  border-left-color: rgb(24 24 27 / 0.15) !important;
  border-right-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-x-zinc-900\/20 {
  border-left-color: rgb(24 24 27 / 0.2) !important;
  border-right-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-x-zinc-900\/25 {
  border-left-color: rgb(24 24 27 / 0.25) !important;
  border-right-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-x-zinc-900\/30 {
  border-left-color: rgb(24 24 27 / 0.3) !important;
  border-right-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-x-zinc-900\/35 {
  border-left-color: rgb(24 24 27 / 0.35) !important;
  border-right-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-x-zinc-900\/40 {
  border-left-color: rgb(24 24 27 / 0.4) !important;
  border-right-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-x-zinc-900\/45 {
  border-left-color: rgb(24 24 27 / 0.45) !important;
  border-right-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-x-zinc-900\/5 {
  border-left-color: rgb(24 24 27 / 0.05) !important;
  border-right-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-x-zinc-900\/50 {
  border-left-color: rgb(24 24 27 / 0.5) !important;
  border-right-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-x-zinc-900\/55 {
  border-left-color: rgb(24 24 27 / 0.55) !important;
  border-right-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-x-zinc-900\/60 {
  border-left-color: rgb(24 24 27 / 0.6) !important;
  border-right-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-x-zinc-900\/65 {
  border-left-color: rgb(24 24 27 / 0.65) !important;
  border-right-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-x-zinc-900\/70 {
  border-left-color: rgb(24 24 27 / 0.7) !important;
  border-right-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-x-zinc-900\/75 {
  border-left-color: rgb(24 24 27 / 0.75) !important;
  border-right-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-x-zinc-900\/80 {
  border-left-color: rgb(24 24 27 / 0.8) !important;
  border-right-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-x-zinc-900\/85 {
  border-left-color: rgb(24 24 27 / 0.85) !important;
  border-right-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-x-zinc-900\/90 {
  border-left-color: rgb(24 24 27 / 0.9) !important;
  border-right-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-x-zinc-900\/95 {
  border-left-color: rgb(24 24 27 / 0.95) !important;
  border-right-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-x-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
  border-right-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-x-zinc-950\/0 {
  border-left-color: rgb(9 9 11 / 0) !important;
  border-right-color: rgb(9 9 11 / 0) !important;
}

.tw-border-x-zinc-950\/10 {
  border-left-color: rgb(9 9 11 / 0.1) !important;
  border-right-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-x-zinc-950\/100 {
  border-left-color: rgb(9 9 11 / 1) !important;
  border-right-color: rgb(9 9 11 / 1) !important;
}

.tw-border-x-zinc-950\/15 {
  border-left-color: rgb(9 9 11 / 0.15) !important;
  border-right-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-x-zinc-950\/20 {
  border-left-color: rgb(9 9 11 / 0.2) !important;
  border-right-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-x-zinc-950\/25 {
  border-left-color: rgb(9 9 11 / 0.25) !important;
  border-right-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-x-zinc-950\/30 {
  border-left-color: rgb(9 9 11 / 0.3) !important;
  border-right-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-x-zinc-950\/35 {
  border-left-color: rgb(9 9 11 / 0.35) !important;
  border-right-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-x-zinc-950\/40 {
  border-left-color: rgb(9 9 11 / 0.4) !important;
  border-right-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-x-zinc-950\/45 {
  border-left-color: rgb(9 9 11 / 0.45) !important;
  border-right-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-x-zinc-950\/5 {
  border-left-color: rgb(9 9 11 / 0.05) !important;
  border-right-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-x-zinc-950\/50 {
  border-left-color: rgb(9 9 11 / 0.5) !important;
  border-right-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-x-zinc-950\/55 {
  border-left-color: rgb(9 9 11 / 0.55) !important;
  border-right-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-x-zinc-950\/60 {
  border-left-color: rgb(9 9 11 / 0.6) !important;
  border-right-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-x-zinc-950\/65 {
  border-left-color: rgb(9 9 11 / 0.65) !important;
  border-right-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-x-zinc-950\/70 {
  border-left-color: rgb(9 9 11 / 0.7) !important;
  border-right-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-x-zinc-950\/75 {
  border-left-color: rgb(9 9 11 / 0.75) !important;
  border-right-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-x-zinc-950\/80 {
  border-left-color: rgb(9 9 11 / 0.8) !important;
  border-right-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-x-zinc-950\/85 {
  border-left-color: rgb(9 9 11 / 0.85) !important;
  border-right-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-x-zinc-950\/90 {
  border-left-color: rgb(9 9 11 / 0.9) !important;
  border-right-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-x-zinc-950\/95 {
  border-left-color: rgb(9 9 11 / 0.95) !important;
  border-right-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-y-amber-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-100\/0 {
  border-top-color: rgb(254 243 199 / 0) !important;
  border-bottom-color: rgb(254 243 199 / 0) !important;
}

.tw-border-y-amber-100\/10 {
  border-top-color: rgb(254 243 199 / 0.1) !important;
  border-bottom-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-y-amber-100\/100 {
  border-top-color: rgb(254 243 199 / 1) !important;
  border-bottom-color: rgb(254 243 199 / 1) !important;
}

.tw-border-y-amber-100\/15 {
  border-top-color: rgb(254 243 199 / 0.15) !important;
  border-bottom-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-y-amber-100\/20 {
  border-top-color: rgb(254 243 199 / 0.2) !important;
  border-bottom-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-y-amber-100\/25 {
  border-top-color: rgb(254 243 199 / 0.25) !important;
  border-bottom-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-y-amber-100\/30 {
  border-top-color: rgb(254 243 199 / 0.3) !important;
  border-bottom-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-y-amber-100\/35 {
  border-top-color: rgb(254 243 199 / 0.35) !important;
  border-bottom-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-y-amber-100\/40 {
  border-top-color: rgb(254 243 199 / 0.4) !important;
  border-bottom-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-y-amber-100\/45 {
  border-top-color: rgb(254 243 199 / 0.45) !important;
  border-bottom-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-y-amber-100\/5 {
  border-top-color: rgb(254 243 199 / 0.05) !important;
  border-bottom-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-y-amber-100\/50 {
  border-top-color: rgb(254 243 199 / 0.5) !important;
  border-bottom-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-y-amber-100\/55 {
  border-top-color: rgb(254 243 199 / 0.55) !important;
  border-bottom-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-y-amber-100\/60 {
  border-top-color: rgb(254 243 199 / 0.6) !important;
  border-bottom-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-y-amber-100\/65 {
  border-top-color: rgb(254 243 199 / 0.65) !important;
  border-bottom-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-y-amber-100\/70 {
  border-top-color: rgb(254 243 199 / 0.7) !important;
  border-bottom-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-y-amber-100\/75 {
  border-top-color: rgb(254 243 199 / 0.75) !important;
  border-bottom-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-y-amber-100\/80 {
  border-top-color: rgb(254 243 199 / 0.8) !important;
  border-bottom-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-y-amber-100\/85 {
  border-top-color: rgb(254 243 199 / 0.85) !important;
  border-bottom-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-y-amber-100\/90 {
  border-top-color: rgb(254 243 199 / 0.9) !important;
  border-bottom-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-y-amber-100\/95 {
  border-top-color: rgb(254 243 199 / 0.95) !important;
  border-bottom-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-y-amber-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-200\/0 {
  border-top-color: rgb(253 230 138 / 0) !important;
  border-bottom-color: rgb(253 230 138 / 0) !important;
}

.tw-border-y-amber-200\/10 {
  border-top-color: rgb(253 230 138 / 0.1) !important;
  border-bottom-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-y-amber-200\/100 {
  border-top-color: rgb(253 230 138 / 1) !important;
  border-bottom-color: rgb(253 230 138 / 1) !important;
}

.tw-border-y-amber-200\/15 {
  border-top-color: rgb(253 230 138 / 0.15) !important;
  border-bottom-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-y-amber-200\/20 {
  border-top-color: rgb(253 230 138 / 0.2) !important;
  border-bottom-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-y-amber-200\/25 {
  border-top-color: rgb(253 230 138 / 0.25) !important;
  border-bottom-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-y-amber-200\/30 {
  border-top-color: rgb(253 230 138 / 0.3) !important;
  border-bottom-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-y-amber-200\/35 {
  border-top-color: rgb(253 230 138 / 0.35) !important;
  border-bottom-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-y-amber-200\/40 {
  border-top-color: rgb(253 230 138 / 0.4) !important;
  border-bottom-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-y-amber-200\/45 {
  border-top-color: rgb(253 230 138 / 0.45) !important;
  border-bottom-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-y-amber-200\/5 {
  border-top-color: rgb(253 230 138 / 0.05) !important;
  border-bottom-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-y-amber-200\/50 {
  border-top-color: rgb(253 230 138 / 0.5) !important;
  border-bottom-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-y-amber-200\/55 {
  border-top-color: rgb(253 230 138 / 0.55) !important;
  border-bottom-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-y-amber-200\/60 {
  border-top-color: rgb(253 230 138 / 0.6) !important;
  border-bottom-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-y-amber-200\/65 {
  border-top-color: rgb(253 230 138 / 0.65) !important;
  border-bottom-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-y-amber-200\/70 {
  border-top-color: rgb(253 230 138 / 0.7) !important;
  border-bottom-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-y-amber-200\/75 {
  border-top-color: rgb(253 230 138 / 0.75) !important;
  border-bottom-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-y-amber-200\/80 {
  border-top-color: rgb(253 230 138 / 0.8) !important;
  border-bottom-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-y-amber-200\/85 {
  border-top-color: rgb(253 230 138 / 0.85) !important;
  border-bottom-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-y-amber-200\/90 {
  border-top-color: rgb(253 230 138 / 0.9) !important;
  border-bottom-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-y-amber-200\/95 {
  border-top-color: rgb(253 230 138 / 0.95) !important;
  border-bottom-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-y-amber-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-300\/0 {
  border-top-color: rgb(252 211 77 / 0) !important;
  border-bottom-color: rgb(252 211 77 / 0) !important;
}

.tw-border-y-amber-300\/10 {
  border-top-color: rgb(252 211 77 / 0.1) !important;
  border-bottom-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-y-amber-300\/100 {
  border-top-color: rgb(252 211 77 / 1) !important;
  border-bottom-color: rgb(252 211 77 / 1) !important;
}

.tw-border-y-amber-300\/15 {
  border-top-color: rgb(252 211 77 / 0.15) !important;
  border-bottom-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-y-amber-300\/20 {
  border-top-color: rgb(252 211 77 / 0.2) !important;
  border-bottom-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-y-amber-300\/25 {
  border-top-color: rgb(252 211 77 / 0.25) !important;
  border-bottom-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-y-amber-300\/30 {
  border-top-color: rgb(252 211 77 / 0.3) !important;
  border-bottom-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-y-amber-300\/35 {
  border-top-color: rgb(252 211 77 / 0.35) !important;
  border-bottom-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-y-amber-300\/40 {
  border-top-color: rgb(252 211 77 / 0.4) !important;
  border-bottom-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-y-amber-300\/45 {
  border-top-color: rgb(252 211 77 / 0.45) !important;
  border-bottom-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-y-amber-300\/5 {
  border-top-color: rgb(252 211 77 / 0.05) !important;
  border-bottom-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-y-amber-300\/50 {
  border-top-color: rgb(252 211 77 / 0.5) !important;
  border-bottom-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-y-amber-300\/55 {
  border-top-color: rgb(252 211 77 / 0.55) !important;
  border-bottom-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-y-amber-300\/60 {
  border-top-color: rgb(252 211 77 / 0.6) !important;
  border-bottom-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-y-amber-300\/65 {
  border-top-color: rgb(252 211 77 / 0.65) !important;
  border-bottom-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-y-amber-300\/70 {
  border-top-color: rgb(252 211 77 / 0.7) !important;
  border-bottom-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-y-amber-300\/75 {
  border-top-color: rgb(252 211 77 / 0.75) !important;
  border-bottom-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-y-amber-300\/80 {
  border-top-color: rgb(252 211 77 / 0.8) !important;
  border-bottom-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-y-amber-300\/85 {
  border-top-color: rgb(252 211 77 / 0.85) !important;
  border-bottom-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-y-amber-300\/90 {
  border-top-color: rgb(252 211 77 / 0.9) !important;
  border-bottom-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-y-amber-300\/95 {
  border-top-color: rgb(252 211 77 / 0.95) !important;
  border-bottom-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-y-amber-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-400\/0 {
  border-top-color: rgb(251 191 36 / 0) !important;
  border-bottom-color: rgb(251 191 36 / 0) !important;
}

.tw-border-y-amber-400\/10 {
  border-top-color: rgb(251 191 36 / 0.1) !important;
  border-bottom-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-y-amber-400\/100 {
  border-top-color: rgb(251 191 36 / 1) !important;
  border-bottom-color: rgb(251 191 36 / 1) !important;
}

.tw-border-y-amber-400\/15 {
  border-top-color: rgb(251 191 36 / 0.15) !important;
  border-bottom-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-y-amber-400\/20 {
  border-top-color: rgb(251 191 36 / 0.2) !important;
  border-bottom-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-y-amber-400\/25 {
  border-top-color: rgb(251 191 36 / 0.25) !important;
  border-bottom-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-y-amber-400\/30 {
  border-top-color: rgb(251 191 36 / 0.3) !important;
  border-bottom-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-y-amber-400\/35 {
  border-top-color: rgb(251 191 36 / 0.35) !important;
  border-bottom-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-y-amber-400\/40 {
  border-top-color: rgb(251 191 36 / 0.4) !important;
  border-bottom-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-y-amber-400\/45 {
  border-top-color: rgb(251 191 36 / 0.45) !important;
  border-bottom-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-y-amber-400\/5 {
  border-top-color: rgb(251 191 36 / 0.05) !important;
  border-bottom-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-y-amber-400\/50 {
  border-top-color: rgb(251 191 36 / 0.5) !important;
  border-bottom-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-y-amber-400\/55 {
  border-top-color: rgb(251 191 36 / 0.55) !important;
  border-bottom-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-y-amber-400\/60 {
  border-top-color: rgb(251 191 36 / 0.6) !important;
  border-bottom-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-y-amber-400\/65 {
  border-top-color: rgb(251 191 36 / 0.65) !important;
  border-bottom-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-y-amber-400\/70 {
  border-top-color: rgb(251 191 36 / 0.7) !important;
  border-bottom-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-y-amber-400\/75 {
  border-top-color: rgb(251 191 36 / 0.75) !important;
  border-bottom-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-y-amber-400\/80 {
  border-top-color: rgb(251 191 36 / 0.8) !important;
  border-bottom-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-y-amber-400\/85 {
  border-top-color: rgb(251 191 36 / 0.85) !important;
  border-bottom-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-y-amber-400\/90 {
  border-top-color: rgb(251 191 36 / 0.9) !important;
  border-bottom-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-y-amber-400\/95 {
  border-top-color: rgb(251 191 36 / 0.95) !important;
  border-bottom-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-y-amber-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-50\/0 {
  border-top-color: rgb(255 251 235 / 0) !important;
  border-bottom-color: rgb(255 251 235 / 0) !important;
}

.tw-border-y-amber-50\/10 {
  border-top-color: rgb(255 251 235 / 0.1) !important;
  border-bottom-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-y-amber-50\/100 {
  border-top-color: rgb(255 251 235 / 1) !important;
  border-bottom-color: rgb(255 251 235 / 1) !important;
}

.tw-border-y-amber-50\/15 {
  border-top-color: rgb(255 251 235 / 0.15) !important;
  border-bottom-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-y-amber-50\/20 {
  border-top-color: rgb(255 251 235 / 0.2) !important;
  border-bottom-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-y-amber-50\/25 {
  border-top-color: rgb(255 251 235 / 0.25) !important;
  border-bottom-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-y-amber-50\/30 {
  border-top-color: rgb(255 251 235 / 0.3) !important;
  border-bottom-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-y-amber-50\/35 {
  border-top-color: rgb(255 251 235 / 0.35) !important;
  border-bottom-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-y-amber-50\/40 {
  border-top-color: rgb(255 251 235 / 0.4) !important;
  border-bottom-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-y-amber-50\/45 {
  border-top-color: rgb(255 251 235 / 0.45) !important;
  border-bottom-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-y-amber-50\/5 {
  border-top-color: rgb(255 251 235 / 0.05) !important;
  border-bottom-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-y-amber-50\/50 {
  border-top-color: rgb(255 251 235 / 0.5) !important;
  border-bottom-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-y-amber-50\/55 {
  border-top-color: rgb(255 251 235 / 0.55) !important;
  border-bottom-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-y-amber-50\/60 {
  border-top-color: rgb(255 251 235 / 0.6) !important;
  border-bottom-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-y-amber-50\/65 {
  border-top-color: rgb(255 251 235 / 0.65) !important;
  border-bottom-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-y-amber-50\/70 {
  border-top-color: rgb(255 251 235 / 0.7) !important;
  border-bottom-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-y-amber-50\/75 {
  border-top-color: rgb(255 251 235 / 0.75) !important;
  border-bottom-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-y-amber-50\/80 {
  border-top-color: rgb(255 251 235 / 0.8) !important;
  border-bottom-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-y-amber-50\/85 {
  border-top-color: rgb(255 251 235 / 0.85) !important;
  border-bottom-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-y-amber-50\/90 {
  border-top-color: rgb(255 251 235 / 0.9) !important;
  border-bottom-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-y-amber-50\/95 {
  border-top-color: rgb(255 251 235 / 0.95) !important;
  border-bottom-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-y-amber-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-500\/0 {
  border-top-color: rgb(245 158 11 / 0) !important;
  border-bottom-color: rgb(245 158 11 / 0) !important;
}

.tw-border-y-amber-500\/10 {
  border-top-color: rgb(245 158 11 / 0.1) !important;
  border-bottom-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-y-amber-500\/100 {
  border-top-color: rgb(245 158 11 / 1) !important;
  border-bottom-color: rgb(245 158 11 / 1) !important;
}

.tw-border-y-amber-500\/15 {
  border-top-color: rgb(245 158 11 / 0.15) !important;
  border-bottom-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-y-amber-500\/20 {
  border-top-color: rgb(245 158 11 / 0.2) !important;
  border-bottom-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-y-amber-500\/25 {
  border-top-color: rgb(245 158 11 / 0.25) !important;
  border-bottom-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-y-amber-500\/30 {
  border-top-color: rgb(245 158 11 / 0.3) !important;
  border-bottom-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-y-amber-500\/35 {
  border-top-color: rgb(245 158 11 / 0.35) !important;
  border-bottom-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-y-amber-500\/40 {
  border-top-color: rgb(245 158 11 / 0.4) !important;
  border-bottom-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-y-amber-500\/45 {
  border-top-color: rgb(245 158 11 / 0.45) !important;
  border-bottom-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-y-amber-500\/5 {
  border-top-color: rgb(245 158 11 / 0.05) !important;
  border-bottom-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-y-amber-500\/50 {
  border-top-color: rgb(245 158 11 / 0.5) !important;
  border-bottom-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-y-amber-500\/55 {
  border-top-color: rgb(245 158 11 / 0.55) !important;
  border-bottom-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-y-amber-500\/60 {
  border-top-color: rgb(245 158 11 / 0.6) !important;
  border-bottom-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-y-amber-500\/65 {
  border-top-color: rgb(245 158 11 / 0.65) !important;
  border-bottom-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-y-amber-500\/70 {
  border-top-color: rgb(245 158 11 / 0.7) !important;
  border-bottom-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-y-amber-500\/75 {
  border-top-color: rgb(245 158 11 / 0.75) !important;
  border-bottom-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-y-amber-500\/80 {
  border-top-color: rgb(245 158 11 / 0.8) !important;
  border-bottom-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-y-amber-500\/85 {
  border-top-color: rgb(245 158 11 / 0.85) !important;
  border-bottom-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-y-amber-500\/90 {
  border-top-color: rgb(245 158 11 / 0.9) !important;
  border-bottom-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-y-amber-500\/95 {
  border-top-color: rgb(245 158 11 / 0.95) !important;
  border-bottom-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-y-amber-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-600\/0 {
  border-top-color: rgb(217 119 6 / 0) !important;
  border-bottom-color: rgb(217 119 6 / 0) !important;
}

.tw-border-y-amber-600\/10 {
  border-top-color: rgb(217 119 6 / 0.1) !important;
  border-bottom-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-y-amber-600\/100 {
  border-top-color: rgb(217 119 6 / 1) !important;
  border-bottom-color: rgb(217 119 6 / 1) !important;
}

.tw-border-y-amber-600\/15 {
  border-top-color: rgb(217 119 6 / 0.15) !important;
  border-bottom-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-y-amber-600\/20 {
  border-top-color: rgb(217 119 6 / 0.2) !important;
  border-bottom-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-y-amber-600\/25 {
  border-top-color: rgb(217 119 6 / 0.25) !important;
  border-bottom-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-y-amber-600\/30 {
  border-top-color: rgb(217 119 6 / 0.3) !important;
  border-bottom-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-y-amber-600\/35 {
  border-top-color: rgb(217 119 6 / 0.35) !important;
  border-bottom-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-y-amber-600\/40 {
  border-top-color: rgb(217 119 6 / 0.4) !important;
  border-bottom-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-y-amber-600\/45 {
  border-top-color: rgb(217 119 6 / 0.45) !important;
  border-bottom-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-y-amber-600\/5 {
  border-top-color: rgb(217 119 6 / 0.05) !important;
  border-bottom-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-y-amber-600\/50 {
  border-top-color: rgb(217 119 6 / 0.5) !important;
  border-bottom-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-y-amber-600\/55 {
  border-top-color: rgb(217 119 6 / 0.55) !important;
  border-bottom-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-y-amber-600\/60 {
  border-top-color: rgb(217 119 6 / 0.6) !important;
  border-bottom-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-y-amber-600\/65 {
  border-top-color: rgb(217 119 6 / 0.65) !important;
  border-bottom-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-y-amber-600\/70 {
  border-top-color: rgb(217 119 6 / 0.7) !important;
  border-bottom-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-y-amber-600\/75 {
  border-top-color: rgb(217 119 6 / 0.75) !important;
  border-bottom-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-y-amber-600\/80 {
  border-top-color: rgb(217 119 6 / 0.8) !important;
  border-bottom-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-y-amber-600\/85 {
  border-top-color: rgb(217 119 6 / 0.85) !important;
  border-bottom-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-y-amber-600\/90 {
  border-top-color: rgb(217 119 6 / 0.9) !important;
  border-bottom-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-y-amber-600\/95 {
  border-top-color: rgb(217 119 6 / 0.95) !important;
  border-bottom-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-y-amber-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-700\/0 {
  border-top-color: rgb(180 83 9 / 0) !important;
  border-bottom-color: rgb(180 83 9 / 0) !important;
}

.tw-border-y-amber-700\/10 {
  border-top-color: rgb(180 83 9 / 0.1) !important;
  border-bottom-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-y-amber-700\/100 {
  border-top-color: rgb(180 83 9 / 1) !important;
  border-bottom-color: rgb(180 83 9 / 1) !important;
}

.tw-border-y-amber-700\/15 {
  border-top-color: rgb(180 83 9 / 0.15) !important;
  border-bottom-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-y-amber-700\/20 {
  border-top-color: rgb(180 83 9 / 0.2) !important;
  border-bottom-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-y-amber-700\/25 {
  border-top-color: rgb(180 83 9 / 0.25) !important;
  border-bottom-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-y-amber-700\/30 {
  border-top-color: rgb(180 83 9 / 0.3) !important;
  border-bottom-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-y-amber-700\/35 {
  border-top-color: rgb(180 83 9 / 0.35) !important;
  border-bottom-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-y-amber-700\/40 {
  border-top-color: rgb(180 83 9 / 0.4) !important;
  border-bottom-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-y-amber-700\/45 {
  border-top-color: rgb(180 83 9 / 0.45) !important;
  border-bottom-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-y-amber-700\/5 {
  border-top-color: rgb(180 83 9 / 0.05) !important;
  border-bottom-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-y-amber-700\/50 {
  border-top-color: rgb(180 83 9 / 0.5) !important;
  border-bottom-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-y-amber-700\/55 {
  border-top-color: rgb(180 83 9 / 0.55) !important;
  border-bottom-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-y-amber-700\/60 {
  border-top-color: rgb(180 83 9 / 0.6) !important;
  border-bottom-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-y-amber-700\/65 {
  border-top-color: rgb(180 83 9 / 0.65) !important;
  border-bottom-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-y-amber-700\/70 {
  border-top-color: rgb(180 83 9 / 0.7) !important;
  border-bottom-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-y-amber-700\/75 {
  border-top-color: rgb(180 83 9 / 0.75) !important;
  border-bottom-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-y-amber-700\/80 {
  border-top-color: rgb(180 83 9 / 0.8) !important;
  border-bottom-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-y-amber-700\/85 {
  border-top-color: rgb(180 83 9 / 0.85) !important;
  border-bottom-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-y-amber-700\/90 {
  border-top-color: rgb(180 83 9 / 0.9) !important;
  border-bottom-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-y-amber-700\/95 {
  border-top-color: rgb(180 83 9 / 0.95) !important;
  border-bottom-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-y-amber-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-800\/0 {
  border-top-color: rgb(146 64 14 / 0) !important;
  border-bottom-color: rgb(146 64 14 / 0) !important;
}

.tw-border-y-amber-800\/10 {
  border-top-color: rgb(146 64 14 / 0.1) !important;
  border-bottom-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-y-amber-800\/100 {
  border-top-color: rgb(146 64 14 / 1) !important;
  border-bottom-color: rgb(146 64 14 / 1) !important;
}

.tw-border-y-amber-800\/15 {
  border-top-color: rgb(146 64 14 / 0.15) !important;
  border-bottom-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-y-amber-800\/20 {
  border-top-color: rgb(146 64 14 / 0.2) !important;
  border-bottom-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-y-amber-800\/25 {
  border-top-color: rgb(146 64 14 / 0.25) !important;
  border-bottom-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-y-amber-800\/30 {
  border-top-color: rgb(146 64 14 / 0.3) !important;
  border-bottom-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-y-amber-800\/35 {
  border-top-color: rgb(146 64 14 / 0.35) !important;
  border-bottom-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-y-amber-800\/40 {
  border-top-color: rgb(146 64 14 / 0.4) !important;
  border-bottom-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-y-amber-800\/45 {
  border-top-color: rgb(146 64 14 / 0.45) !important;
  border-bottom-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-y-amber-800\/5 {
  border-top-color: rgb(146 64 14 / 0.05) !important;
  border-bottom-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-y-amber-800\/50 {
  border-top-color: rgb(146 64 14 / 0.5) !important;
  border-bottom-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-y-amber-800\/55 {
  border-top-color: rgb(146 64 14 / 0.55) !important;
  border-bottom-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-y-amber-800\/60 {
  border-top-color: rgb(146 64 14 / 0.6) !important;
  border-bottom-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-y-amber-800\/65 {
  border-top-color: rgb(146 64 14 / 0.65) !important;
  border-bottom-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-y-amber-800\/70 {
  border-top-color: rgb(146 64 14 / 0.7) !important;
  border-bottom-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-y-amber-800\/75 {
  border-top-color: rgb(146 64 14 / 0.75) !important;
  border-bottom-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-y-amber-800\/80 {
  border-top-color: rgb(146 64 14 / 0.8) !important;
  border-bottom-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-y-amber-800\/85 {
  border-top-color: rgb(146 64 14 / 0.85) !important;
  border-bottom-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-y-amber-800\/90 {
  border-top-color: rgb(146 64 14 / 0.9) !important;
  border-bottom-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-y-amber-800\/95 {
  border-top-color: rgb(146 64 14 / 0.95) !important;
  border-bottom-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-y-amber-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-900\/0 {
  border-top-color: rgb(120 53 15 / 0) !important;
  border-bottom-color: rgb(120 53 15 / 0) !important;
}

.tw-border-y-amber-900\/10 {
  border-top-color: rgb(120 53 15 / 0.1) !important;
  border-bottom-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-y-amber-900\/100 {
  border-top-color: rgb(120 53 15 / 1) !important;
  border-bottom-color: rgb(120 53 15 / 1) !important;
}

.tw-border-y-amber-900\/15 {
  border-top-color: rgb(120 53 15 / 0.15) !important;
  border-bottom-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-y-amber-900\/20 {
  border-top-color: rgb(120 53 15 / 0.2) !important;
  border-bottom-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-y-amber-900\/25 {
  border-top-color: rgb(120 53 15 / 0.25) !important;
  border-bottom-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-y-amber-900\/30 {
  border-top-color: rgb(120 53 15 / 0.3) !important;
  border-bottom-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-y-amber-900\/35 {
  border-top-color: rgb(120 53 15 / 0.35) !important;
  border-bottom-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-y-amber-900\/40 {
  border-top-color: rgb(120 53 15 / 0.4) !important;
  border-bottom-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-y-amber-900\/45 {
  border-top-color: rgb(120 53 15 / 0.45) !important;
  border-bottom-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-y-amber-900\/5 {
  border-top-color: rgb(120 53 15 / 0.05) !important;
  border-bottom-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-y-amber-900\/50 {
  border-top-color: rgb(120 53 15 / 0.5) !important;
  border-bottom-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-y-amber-900\/55 {
  border-top-color: rgb(120 53 15 / 0.55) !important;
  border-bottom-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-y-amber-900\/60 {
  border-top-color: rgb(120 53 15 / 0.6) !important;
  border-bottom-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-y-amber-900\/65 {
  border-top-color: rgb(120 53 15 / 0.65) !important;
  border-bottom-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-y-amber-900\/70 {
  border-top-color: rgb(120 53 15 / 0.7) !important;
  border-bottom-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-y-amber-900\/75 {
  border-top-color: rgb(120 53 15 / 0.75) !important;
  border-bottom-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-y-amber-900\/80 {
  border-top-color: rgb(120 53 15 / 0.8) !important;
  border-bottom-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-y-amber-900\/85 {
  border-top-color: rgb(120 53 15 / 0.85) !important;
  border-bottom-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-y-amber-900\/90 {
  border-top-color: rgb(120 53 15 / 0.9) !important;
  border-bottom-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-y-amber-900\/95 {
  border-top-color: rgb(120 53 15 / 0.95) !important;
  border-bottom-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-y-amber-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-y-amber-950\/0 {
  border-top-color: rgb(69 26 3 / 0) !important;
  border-bottom-color: rgb(69 26 3 / 0) !important;
}

.tw-border-y-amber-950\/10 {
  border-top-color: rgb(69 26 3 / 0.1) !important;
  border-bottom-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-y-amber-950\/100 {
  border-top-color: rgb(69 26 3 / 1) !important;
  border-bottom-color: rgb(69 26 3 / 1) !important;
}

.tw-border-y-amber-950\/15 {
  border-top-color: rgb(69 26 3 / 0.15) !important;
  border-bottom-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-y-amber-950\/20 {
  border-top-color: rgb(69 26 3 / 0.2) !important;
  border-bottom-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-y-amber-950\/25 {
  border-top-color: rgb(69 26 3 / 0.25) !important;
  border-bottom-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-y-amber-950\/30 {
  border-top-color: rgb(69 26 3 / 0.3) !important;
  border-bottom-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-y-amber-950\/35 {
  border-top-color: rgb(69 26 3 / 0.35) !important;
  border-bottom-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-y-amber-950\/40 {
  border-top-color: rgb(69 26 3 / 0.4) !important;
  border-bottom-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-y-amber-950\/45 {
  border-top-color: rgb(69 26 3 / 0.45) !important;
  border-bottom-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-y-amber-950\/5 {
  border-top-color: rgb(69 26 3 / 0.05) !important;
  border-bottom-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-y-amber-950\/50 {
  border-top-color: rgb(69 26 3 / 0.5) !important;
  border-bottom-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-y-amber-950\/55 {
  border-top-color: rgb(69 26 3 / 0.55) !important;
  border-bottom-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-y-amber-950\/60 {
  border-top-color: rgb(69 26 3 / 0.6) !important;
  border-bottom-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-y-amber-950\/65 {
  border-top-color: rgb(69 26 3 / 0.65) !important;
  border-bottom-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-y-amber-950\/70 {
  border-top-color: rgb(69 26 3 / 0.7) !important;
  border-bottom-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-y-amber-950\/75 {
  border-top-color: rgb(69 26 3 / 0.75) !important;
  border-bottom-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-y-amber-950\/80 {
  border-top-color: rgb(69 26 3 / 0.8) !important;
  border-bottom-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-y-amber-950\/85 {
  border-top-color: rgb(69 26 3 / 0.85) !important;
  border-bottom-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-y-amber-950\/90 {
  border-top-color: rgb(69 26 3 / 0.9) !important;
  border-bottom-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-y-amber-950\/95 {
  border-top-color: rgb(69 26 3 / 0.95) !important;
  border-bottom-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-y-black {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-y-black\/0 {
  border-top-color: rgb(0 0 0 / 0) !important;
  border-bottom-color: rgb(0 0 0 / 0) !important;
}

.tw-border-y-black\/10 {
  border-top-color: rgb(0 0 0 / 0.1) !important;
  border-bottom-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-y-black\/100 {
  border-top-color: rgb(0 0 0 / 1) !important;
  border-bottom-color: rgb(0 0 0 / 1) !important;
}

.tw-border-y-black\/15 {
  border-top-color: rgb(0 0 0 / 0.15) !important;
  border-bottom-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-y-black\/20 {
  border-top-color: rgb(0 0 0 / 0.2) !important;
  border-bottom-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-y-black\/25 {
  border-top-color: rgb(0 0 0 / 0.25) !important;
  border-bottom-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-y-black\/30 {
  border-top-color: rgb(0 0 0 / 0.3) !important;
  border-bottom-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-y-black\/35 {
  border-top-color: rgb(0 0 0 / 0.35) !important;
  border-bottom-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-y-black\/40 {
  border-top-color: rgb(0 0 0 / 0.4) !important;
  border-bottom-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-y-black\/45 {
  border-top-color: rgb(0 0 0 / 0.45) !important;
  border-bottom-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-y-black\/5 {
  border-top-color: rgb(0 0 0 / 0.05) !important;
  border-bottom-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-y-black\/50 {
  border-top-color: rgb(0 0 0 / 0.5) !important;
  border-bottom-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-y-black\/55 {
  border-top-color: rgb(0 0 0 / 0.55) !important;
  border-bottom-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-y-black\/60 {
  border-top-color: rgb(0 0 0 / 0.6) !important;
  border-bottom-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-y-black\/65 {
  border-top-color: rgb(0 0 0 / 0.65) !important;
  border-bottom-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-y-black\/70 {
  border-top-color: rgb(0 0 0 / 0.7) !important;
  border-bottom-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-y-black\/75 {
  border-top-color: rgb(0 0 0 / 0.75) !important;
  border-bottom-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-y-black\/80 {
  border-top-color: rgb(0 0 0 / 0.8) !important;
  border-bottom-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-y-black\/85 {
  border-top-color: rgb(0 0 0 / 0.85) !important;
  border-bottom-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-y-black\/90 {
  border-top-color: rgb(0 0 0 / 0.9) !important;
  border-bottom-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-y-black\/95 {
  border-top-color: rgb(0 0 0 / 0.95) !important;
  border-bottom-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-y-blue-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-100\/0 {
  border-top-color: rgb(219 234 254 / 0) !important;
  border-bottom-color: rgb(219 234 254 / 0) !important;
}

.tw-border-y-blue-100\/10 {
  border-top-color: rgb(219 234 254 / 0.1) !important;
  border-bottom-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-y-blue-100\/100 {
  border-top-color: rgb(219 234 254 / 1) !important;
  border-bottom-color: rgb(219 234 254 / 1) !important;
}

.tw-border-y-blue-100\/15 {
  border-top-color: rgb(219 234 254 / 0.15) !important;
  border-bottom-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-y-blue-100\/20 {
  border-top-color: rgb(219 234 254 / 0.2) !important;
  border-bottom-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-y-blue-100\/25 {
  border-top-color: rgb(219 234 254 / 0.25) !important;
  border-bottom-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-y-blue-100\/30 {
  border-top-color: rgb(219 234 254 / 0.3) !important;
  border-bottom-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-y-blue-100\/35 {
  border-top-color: rgb(219 234 254 / 0.35) !important;
  border-bottom-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-y-blue-100\/40 {
  border-top-color: rgb(219 234 254 / 0.4) !important;
  border-bottom-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-y-blue-100\/45 {
  border-top-color: rgb(219 234 254 / 0.45) !important;
  border-bottom-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-y-blue-100\/5 {
  border-top-color: rgb(219 234 254 / 0.05) !important;
  border-bottom-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-y-blue-100\/50 {
  border-top-color: rgb(219 234 254 / 0.5) !important;
  border-bottom-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-y-blue-100\/55 {
  border-top-color: rgb(219 234 254 / 0.55) !important;
  border-bottom-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-y-blue-100\/60 {
  border-top-color: rgb(219 234 254 / 0.6) !important;
  border-bottom-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-y-blue-100\/65 {
  border-top-color: rgb(219 234 254 / 0.65) !important;
  border-bottom-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-y-blue-100\/70 {
  border-top-color: rgb(219 234 254 / 0.7) !important;
  border-bottom-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-y-blue-100\/75 {
  border-top-color: rgb(219 234 254 / 0.75) !important;
  border-bottom-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-y-blue-100\/80 {
  border-top-color: rgb(219 234 254 / 0.8) !important;
  border-bottom-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-y-blue-100\/85 {
  border-top-color: rgb(219 234 254 / 0.85) !important;
  border-bottom-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-y-blue-100\/90 {
  border-top-color: rgb(219 234 254 / 0.9) !important;
  border-bottom-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-y-blue-100\/95 {
  border-top-color: rgb(219 234 254 / 0.95) !important;
  border-bottom-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-y-blue-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-200\/0 {
  border-top-color: rgb(191 219 254 / 0) !important;
  border-bottom-color: rgb(191 219 254 / 0) !important;
}

.tw-border-y-blue-200\/10 {
  border-top-color: rgb(191 219 254 / 0.1) !important;
  border-bottom-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-y-blue-200\/100 {
  border-top-color: rgb(191 219 254 / 1) !important;
  border-bottom-color: rgb(191 219 254 / 1) !important;
}

.tw-border-y-blue-200\/15 {
  border-top-color: rgb(191 219 254 / 0.15) !important;
  border-bottom-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-y-blue-200\/20 {
  border-top-color: rgb(191 219 254 / 0.2) !important;
  border-bottom-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-y-blue-200\/25 {
  border-top-color: rgb(191 219 254 / 0.25) !important;
  border-bottom-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-y-blue-200\/30 {
  border-top-color: rgb(191 219 254 / 0.3) !important;
  border-bottom-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-y-blue-200\/35 {
  border-top-color: rgb(191 219 254 / 0.35) !important;
  border-bottom-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-y-blue-200\/40 {
  border-top-color: rgb(191 219 254 / 0.4) !important;
  border-bottom-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-y-blue-200\/45 {
  border-top-color: rgb(191 219 254 / 0.45) !important;
  border-bottom-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-y-blue-200\/5 {
  border-top-color: rgb(191 219 254 / 0.05) !important;
  border-bottom-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-y-blue-200\/50 {
  border-top-color: rgb(191 219 254 / 0.5) !important;
  border-bottom-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-y-blue-200\/55 {
  border-top-color: rgb(191 219 254 / 0.55) !important;
  border-bottom-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-y-blue-200\/60 {
  border-top-color: rgb(191 219 254 / 0.6) !important;
  border-bottom-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-y-blue-200\/65 {
  border-top-color: rgb(191 219 254 / 0.65) !important;
  border-bottom-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-y-blue-200\/70 {
  border-top-color: rgb(191 219 254 / 0.7) !important;
  border-bottom-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-y-blue-200\/75 {
  border-top-color: rgb(191 219 254 / 0.75) !important;
  border-bottom-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-y-blue-200\/80 {
  border-top-color: rgb(191 219 254 / 0.8) !important;
  border-bottom-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-y-blue-200\/85 {
  border-top-color: rgb(191 219 254 / 0.85) !important;
  border-bottom-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-y-blue-200\/90 {
  border-top-color: rgb(191 219 254 / 0.9) !important;
  border-bottom-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-y-blue-200\/95 {
  border-top-color: rgb(191 219 254 / 0.95) !important;
  border-bottom-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-y-blue-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-300\/0 {
  border-top-color: rgb(147 197 253 / 0) !important;
  border-bottom-color: rgb(147 197 253 / 0) !important;
}

.tw-border-y-blue-300\/10 {
  border-top-color: rgb(147 197 253 / 0.1) !important;
  border-bottom-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-y-blue-300\/100 {
  border-top-color: rgb(147 197 253 / 1) !important;
  border-bottom-color: rgb(147 197 253 / 1) !important;
}

.tw-border-y-blue-300\/15 {
  border-top-color: rgb(147 197 253 / 0.15) !important;
  border-bottom-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-y-blue-300\/20 {
  border-top-color: rgb(147 197 253 / 0.2) !important;
  border-bottom-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-y-blue-300\/25 {
  border-top-color: rgb(147 197 253 / 0.25) !important;
  border-bottom-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-y-blue-300\/30 {
  border-top-color: rgb(147 197 253 / 0.3) !important;
  border-bottom-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-y-blue-300\/35 {
  border-top-color: rgb(147 197 253 / 0.35) !important;
  border-bottom-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-y-blue-300\/40 {
  border-top-color: rgb(147 197 253 / 0.4) !important;
  border-bottom-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-y-blue-300\/45 {
  border-top-color: rgb(147 197 253 / 0.45) !important;
  border-bottom-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-y-blue-300\/5 {
  border-top-color: rgb(147 197 253 / 0.05) !important;
  border-bottom-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-y-blue-300\/50 {
  border-top-color: rgb(147 197 253 / 0.5) !important;
  border-bottom-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-y-blue-300\/55 {
  border-top-color: rgb(147 197 253 / 0.55) !important;
  border-bottom-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-y-blue-300\/60 {
  border-top-color: rgb(147 197 253 / 0.6) !important;
  border-bottom-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-y-blue-300\/65 {
  border-top-color: rgb(147 197 253 / 0.65) !important;
  border-bottom-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-y-blue-300\/70 {
  border-top-color: rgb(147 197 253 / 0.7) !important;
  border-bottom-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-y-blue-300\/75 {
  border-top-color: rgb(147 197 253 / 0.75) !important;
  border-bottom-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-y-blue-300\/80 {
  border-top-color: rgb(147 197 253 / 0.8) !important;
  border-bottom-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-y-blue-300\/85 {
  border-top-color: rgb(147 197 253 / 0.85) !important;
  border-bottom-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-y-blue-300\/90 {
  border-top-color: rgb(147 197 253 / 0.9) !important;
  border-bottom-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-y-blue-300\/95 {
  border-top-color: rgb(147 197 253 / 0.95) !important;
  border-bottom-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-y-blue-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-400\/0 {
  border-top-color: rgb(96 165 250 / 0) !important;
  border-bottom-color: rgb(96 165 250 / 0) !important;
}

.tw-border-y-blue-400\/10 {
  border-top-color: rgb(96 165 250 / 0.1) !important;
  border-bottom-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-y-blue-400\/100 {
  border-top-color: rgb(96 165 250 / 1) !important;
  border-bottom-color: rgb(96 165 250 / 1) !important;
}

.tw-border-y-blue-400\/15 {
  border-top-color: rgb(96 165 250 / 0.15) !important;
  border-bottom-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-y-blue-400\/20 {
  border-top-color: rgb(96 165 250 / 0.2) !important;
  border-bottom-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-y-blue-400\/25 {
  border-top-color: rgb(96 165 250 / 0.25) !important;
  border-bottom-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-y-blue-400\/30 {
  border-top-color: rgb(96 165 250 / 0.3) !important;
  border-bottom-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-y-blue-400\/35 {
  border-top-color: rgb(96 165 250 / 0.35) !important;
  border-bottom-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-y-blue-400\/40 {
  border-top-color: rgb(96 165 250 / 0.4) !important;
  border-bottom-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-y-blue-400\/45 {
  border-top-color: rgb(96 165 250 / 0.45) !important;
  border-bottom-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-y-blue-400\/5 {
  border-top-color: rgb(96 165 250 / 0.05) !important;
  border-bottom-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-y-blue-400\/50 {
  border-top-color: rgb(96 165 250 / 0.5) !important;
  border-bottom-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-y-blue-400\/55 {
  border-top-color: rgb(96 165 250 / 0.55) !important;
  border-bottom-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-y-blue-400\/60 {
  border-top-color: rgb(96 165 250 / 0.6) !important;
  border-bottom-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-y-blue-400\/65 {
  border-top-color: rgb(96 165 250 / 0.65) !important;
  border-bottom-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-y-blue-400\/70 {
  border-top-color: rgb(96 165 250 / 0.7) !important;
  border-bottom-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-y-blue-400\/75 {
  border-top-color: rgb(96 165 250 / 0.75) !important;
  border-bottom-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-y-blue-400\/80 {
  border-top-color: rgb(96 165 250 / 0.8) !important;
  border-bottom-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-y-blue-400\/85 {
  border-top-color: rgb(96 165 250 / 0.85) !important;
  border-bottom-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-y-blue-400\/90 {
  border-top-color: rgb(96 165 250 / 0.9) !important;
  border-bottom-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-y-blue-400\/95 {
  border-top-color: rgb(96 165 250 / 0.95) !important;
  border-bottom-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-y-blue-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-50\/0 {
  border-top-color: rgb(239 246 255 / 0) !important;
  border-bottom-color: rgb(239 246 255 / 0) !important;
}

.tw-border-y-blue-50\/10 {
  border-top-color: rgb(239 246 255 / 0.1) !important;
  border-bottom-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-y-blue-50\/100 {
  border-top-color: rgb(239 246 255 / 1) !important;
  border-bottom-color: rgb(239 246 255 / 1) !important;
}

.tw-border-y-blue-50\/15 {
  border-top-color: rgb(239 246 255 / 0.15) !important;
  border-bottom-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-y-blue-50\/20 {
  border-top-color: rgb(239 246 255 / 0.2) !important;
  border-bottom-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-y-blue-50\/25 {
  border-top-color: rgb(239 246 255 / 0.25) !important;
  border-bottom-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-y-blue-50\/30 {
  border-top-color: rgb(239 246 255 / 0.3) !important;
  border-bottom-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-y-blue-50\/35 {
  border-top-color: rgb(239 246 255 / 0.35) !important;
  border-bottom-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-y-blue-50\/40 {
  border-top-color: rgb(239 246 255 / 0.4) !important;
  border-bottom-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-y-blue-50\/45 {
  border-top-color: rgb(239 246 255 / 0.45) !important;
  border-bottom-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-y-blue-50\/5 {
  border-top-color: rgb(239 246 255 / 0.05) !important;
  border-bottom-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-y-blue-50\/50 {
  border-top-color: rgb(239 246 255 / 0.5) !important;
  border-bottom-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-y-blue-50\/55 {
  border-top-color: rgb(239 246 255 / 0.55) !important;
  border-bottom-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-y-blue-50\/60 {
  border-top-color: rgb(239 246 255 / 0.6) !important;
  border-bottom-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-y-blue-50\/65 {
  border-top-color: rgb(239 246 255 / 0.65) !important;
  border-bottom-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-y-blue-50\/70 {
  border-top-color: rgb(239 246 255 / 0.7) !important;
  border-bottom-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-y-blue-50\/75 {
  border-top-color: rgb(239 246 255 / 0.75) !important;
  border-bottom-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-y-blue-50\/80 {
  border-top-color: rgb(239 246 255 / 0.8) !important;
  border-bottom-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-y-blue-50\/85 {
  border-top-color: rgb(239 246 255 / 0.85) !important;
  border-bottom-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-y-blue-50\/90 {
  border-top-color: rgb(239 246 255 / 0.9) !important;
  border-bottom-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-y-blue-50\/95 {
  border-top-color: rgb(239 246 255 / 0.95) !important;
  border-bottom-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-y-blue-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-500\/0 {
  border-top-color: rgb(59 130 246 / 0) !important;
  border-bottom-color: rgb(59 130 246 / 0) !important;
}

.tw-border-y-blue-500\/10 {
  border-top-color: rgb(59 130 246 / 0.1) !important;
  border-bottom-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-y-blue-500\/100 {
  border-top-color: rgb(59 130 246 / 1) !important;
  border-bottom-color: rgb(59 130 246 / 1) !important;
}

.tw-border-y-blue-500\/15 {
  border-top-color: rgb(59 130 246 / 0.15) !important;
  border-bottom-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-y-blue-500\/20 {
  border-top-color: rgb(59 130 246 / 0.2) !important;
  border-bottom-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-y-blue-500\/25 {
  border-top-color: rgb(59 130 246 / 0.25) !important;
  border-bottom-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-y-blue-500\/30 {
  border-top-color: rgb(59 130 246 / 0.3) !important;
  border-bottom-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-y-blue-500\/35 {
  border-top-color: rgb(59 130 246 / 0.35) !important;
  border-bottom-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-y-blue-500\/40 {
  border-top-color: rgb(59 130 246 / 0.4) !important;
  border-bottom-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-y-blue-500\/45 {
  border-top-color: rgb(59 130 246 / 0.45) !important;
  border-bottom-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-y-blue-500\/5 {
  border-top-color: rgb(59 130 246 / 0.05) !important;
  border-bottom-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-y-blue-500\/50 {
  border-top-color: rgb(59 130 246 / 0.5) !important;
  border-bottom-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-y-blue-500\/55 {
  border-top-color: rgb(59 130 246 / 0.55) !important;
  border-bottom-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-y-blue-500\/60 {
  border-top-color: rgb(59 130 246 / 0.6) !important;
  border-bottom-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-y-blue-500\/65 {
  border-top-color: rgb(59 130 246 / 0.65) !important;
  border-bottom-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-y-blue-500\/70 {
  border-top-color: rgb(59 130 246 / 0.7) !important;
  border-bottom-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-y-blue-500\/75 {
  border-top-color: rgb(59 130 246 / 0.75) !important;
  border-bottom-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-y-blue-500\/80 {
  border-top-color: rgb(59 130 246 / 0.8) !important;
  border-bottom-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-y-blue-500\/85 {
  border-top-color: rgb(59 130 246 / 0.85) !important;
  border-bottom-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-y-blue-500\/90 {
  border-top-color: rgb(59 130 246 / 0.9) !important;
  border-bottom-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-y-blue-500\/95 {
  border-top-color: rgb(59 130 246 / 0.95) !important;
  border-bottom-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-y-blue-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-600\/0 {
  border-top-color: rgb(37 99 235 / 0) !important;
  border-bottom-color: rgb(37 99 235 / 0) !important;
}

.tw-border-y-blue-600\/10 {
  border-top-color: rgb(37 99 235 / 0.1) !important;
  border-bottom-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-y-blue-600\/100 {
  border-top-color: rgb(37 99 235 / 1) !important;
  border-bottom-color: rgb(37 99 235 / 1) !important;
}

.tw-border-y-blue-600\/15 {
  border-top-color: rgb(37 99 235 / 0.15) !important;
  border-bottom-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-y-blue-600\/20 {
  border-top-color: rgb(37 99 235 / 0.2) !important;
  border-bottom-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-y-blue-600\/25 {
  border-top-color: rgb(37 99 235 / 0.25) !important;
  border-bottom-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-y-blue-600\/30 {
  border-top-color: rgb(37 99 235 / 0.3) !important;
  border-bottom-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-y-blue-600\/35 {
  border-top-color: rgb(37 99 235 / 0.35) !important;
  border-bottom-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-y-blue-600\/40 {
  border-top-color: rgb(37 99 235 / 0.4) !important;
  border-bottom-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-y-blue-600\/45 {
  border-top-color: rgb(37 99 235 / 0.45) !important;
  border-bottom-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-y-blue-600\/5 {
  border-top-color: rgb(37 99 235 / 0.05) !important;
  border-bottom-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-y-blue-600\/50 {
  border-top-color: rgb(37 99 235 / 0.5) !important;
  border-bottom-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-y-blue-600\/55 {
  border-top-color: rgb(37 99 235 / 0.55) !important;
  border-bottom-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-y-blue-600\/60 {
  border-top-color: rgb(37 99 235 / 0.6) !important;
  border-bottom-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-y-blue-600\/65 {
  border-top-color: rgb(37 99 235 / 0.65) !important;
  border-bottom-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-y-blue-600\/70 {
  border-top-color: rgb(37 99 235 / 0.7) !important;
  border-bottom-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-y-blue-600\/75 {
  border-top-color: rgb(37 99 235 / 0.75) !important;
  border-bottom-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-y-blue-600\/80 {
  border-top-color: rgb(37 99 235 / 0.8) !important;
  border-bottom-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-y-blue-600\/85 {
  border-top-color: rgb(37 99 235 / 0.85) !important;
  border-bottom-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-y-blue-600\/90 {
  border-top-color: rgb(37 99 235 / 0.9) !important;
  border-bottom-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-y-blue-600\/95 {
  border-top-color: rgb(37 99 235 / 0.95) !important;
  border-bottom-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-y-blue-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-700\/0 {
  border-top-color: rgb(29 78 216 / 0) !important;
  border-bottom-color: rgb(29 78 216 / 0) !important;
}

.tw-border-y-blue-700\/10 {
  border-top-color: rgb(29 78 216 / 0.1) !important;
  border-bottom-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-y-blue-700\/100 {
  border-top-color: rgb(29 78 216 / 1) !important;
  border-bottom-color: rgb(29 78 216 / 1) !important;
}

.tw-border-y-blue-700\/15 {
  border-top-color: rgb(29 78 216 / 0.15) !important;
  border-bottom-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-y-blue-700\/20 {
  border-top-color: rgb(29 78 216 / 0.2) !important;
  border-bottom-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-y-blue-700\/25 {
  border-top-color: rgb(29 78 216 / 0.25) !important;
  border-bottom-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-y-blue-700\/30 {
  border-top-color: rgb(29 78 216 / 0.3) !important;
  border-bottom-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-y-blue-700\/35 {
  border-top-color: rgb(29 78 216 / 0.35) !important;
  border-bottom-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-y-blue-700\/40 {
  border-top-color: rgb(29 78 216 / 0.4) !important;
  border-bottom-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-y-blue-700\/45 {
  border-top-color: rgb(29 78 216 / 0.45) !important;
  border-bottom-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-y-blue-700\/5 {
  border-top-color: rgb(29 78 216 / 0.05) !important;
  border-bottom-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-y-blue-700\/50 {
  border-top-color: rgb(29 78 216 / 0.5) !important;
  border-bottom-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-y-blue-700\/55 {
  border-top-color: rgb(29 78 216 / 0.55) !important;
  border-bottom-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-y-blue-700\/60 {
  border-top-color: rgb(29 78 216 / 0.6) !important;
  border-bottom-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-y-blue-700\/65 {
  border-top-color: rgb(29 78 216 / 0.65) !important;
  border-bottom-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-y-blue-700\/70 {
  border-top-color: rgb(29 78 216 / 0.7) !important;
  border-bottom-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-y-blue-700\/75 {
  border-top-color: rgb(29 78 216 / 0.75) !important;
  border-bottom-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-y-blue-700\/80 {
  border-top-color: rgb(29 78 216 / 0.8) !important;
  border-bottom-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-y-blue-700\/85 {
  border-top-color: rgb(29 78 216 / 0.85) !important;
  border-bottom-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-y-blue-700\/90 {
  border-top-color: rgb(29 78 216 / 0.9) !important;
  border-bottom-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-y-blue-700\/95 {
  border-top-color: rgb(29 78 216 / 0.95) !important;
  border-bottom-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-y-blue-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-800\/0 {
  border-top-color: rgb(30 64 175 / 0) !important;
  border-bottom-color: rgb(30 64 175 / 0) !important;
}

.tw-border-y-blue-800\/10 {
  border-top-color: rgb(30 64 175 / 0.1) !important;
  border-bottom-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-y-blue-800\/100 {
  border-top-color: rgb(30 64 175 / 1) !important;
  border-bottom-color: rgb(30 64 175 / 1) !important;
}

.tw-border-y-blue-800\/15 {
  border-top-color: rgb(30 64 175 / 0.15) !important;
  border-bottom-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-y-blue-800\/20 {
  border-top-color: rgb(30 64 175 / 0.2) !important;
  border-bottom-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-y-blue-800\/25 {
  border-top-color: rgb(30 64 175 / 0.25) !important;
  border-bottom-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-y-blue-800\/30 {
  border-top-color: rgb(30 64 175 / 0.3) !important;
  border-bottom-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-y-blue-800\/35 {
  border-top-color: rgb(30 64 175 / 0.35) !important;
  border-bottom-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-y-blue-800\/40 {
  border-top-color: rgb(30 64 175 / 0.4) !important;
  border-bottom-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-y-blue-800\/45 {
  border-top-color: rgb(30 64 175 / 0.45) !important;
  border-bottom-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-y-blue-800\/5 {
  border-top-color: rgb(30 64 175 / 0.05) !important;
  border-bottom-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-y-blue-800\/50 {
  border-top-color: rgb(30 64 175 / 0.5) !important;
  border-bottom-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-y-blue-800\/55 {
  border-top-color: rgb(30 64 175 / 0.55) !important;
  border-bottom-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-y-blue-800\/60 {
  border-top-color: rgb(30 64 175 / 0.6) !important;
  border-bottom-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-y-blue-800\/65 {
  border-top-color: rgb(30 64 175 / 0.65) !important;
  border-bottom-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-y-blue-800\/70 {
  border-top-color: rgb(30 64 175 / 0.7) !important;
  border-bottom-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-y-blue-800\/75 {
  border-top-color: rgb(30 64 175 / 0.75) !important;
  border-bottom-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-y-blue-800\/80 {
  border-top-color: rgb(30 64 175 / 0.8) !important;
  border-bottom-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-y-blue-800\/85 {
  border-top-color: rgb(30 64 175 / 0.85) !important;
  border-bottom-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-y-blue-800\/90 {
  border-top-color: rgb(30 64 175 / 0.9) !important;
  border-bottom-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-y-blue-800\/95 {
  border-top-color: rgb(30 64 175 / 0.95) !important;
  border-bottom-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-y-blue-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-900\/0 {
  border-top-color: rgb(30 58 138 / 0) !important;
  border-bottom-color: rgb(30 58 138 / 0) !important;
}

.tw-border-y-blue-900\/10 {
  border-top-color: rgb(30 58 138 / 0.1) !important;
  border-bottom-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-y-blue-900\/100 {
  border-top-color: rgb(30 58 138 / 1) !important;
  border-bottom-color: rgb(30 58 138 / 1) !important;
}

.tw-border-y-blue-900\/15 {
  border-top-color: rgb(30 58 138 / 0.15) !important;
  border-bottom-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-y-blue-900\/20 {
  border-top-color: rgb(30 58 138 / 0.2) !important;
  border-bottom-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-y-blue-900\/25 {
  border-top-color: rgb(30 58 138 / 0.25) !important;
  border-bottom-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-y-blue-900\/30 {
  border-top-color: rgb(30 58 138 / 0.3) !important;
  border-bottom-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-y-blue-900\/35 {
  border-top-color: rgb(30 58 138 / 0.35) !important;
  border-bottom-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-y-blue-900\/40 {
  border-top-color: rgb(30 58 138 / 0.4) !important;
  border-bottom-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-y-blue-900\/45 {
  border-top-color: rgb(30 58 138 / 0.45) !important;
  border-bottom-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-y-blue-900\/5 {
  border-top-color: rgb(30 58 138 / 0.05) !important;
  border-bottom-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-y-blue-900\/50 {
  border-top-color: rgb(30 58 138 / 0.5) !important;
  border-bottom-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-y-blue-900\/55 {
  border-top-color: rgb(30 58 138 / 0.55) !important;
  border-bottom-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-y-blue-900\/60 {
  border-top-color: rgb(30 58 138 / 0.6) !important;
  border-bottom-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-y-blue-900\/65 {
  border-top-color: rgb(30 58 138 / 0.65) !important;
  border-bottom-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-y-blue-900\/70 {
  border-top-color: rgb(30 58 138 / 0.7) !important;
  border-bottom-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-y-blue-900\/75 {
  border-top-color: rgb(30 58 138 / 0.75) !important;
  border-bottom-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-y-blue-900\/80 {
  border-top-color: rgb(30 58 138 / 0.8) !important;
  border-bottom-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-y-blue-900\/85 {
  border-top-color: rgb(30 58 138 / 0.85) !important;
  border-bottom-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-y-blue-900\/90 {
  border-top-color: rgb(30 58 138 / 0.9) !important;
  border-bottom-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-y-blue-900\/95 {
  border-top-color: rgb(30 58 138 / 0.95) !important;
  border-bottom-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-y-blue-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-y-blue-950\/0 {
  border-top-color: rgb(23 37 84 / 0) !important;
  border-bottom-color: rgb(23 37 84 / 0) !important;
}

.tw-border-y-blue-950\/10 {
  border-top-color: rgb(23 37 84 / 0.1) !important;
  border-bottom-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-y-blue-950\/100 {
  border-top-color: rgb(23 37 84 / 1) !important;
  border-bottom-color: rgb(23 37 84 / 1) !important;
}

.tw-border-y-blue-950\/15 {
  border-top-color: rgb(23 37 84 / 0.15) !important;
  border-bottom-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-y-blue-950\/20 {
  border-top-color: rgb(23 37 84 / 0.2) !important;
  border-bottom-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-y-blue-950\/25 {
  border-top-color: rgb(23 37 84 / 0.25) !important;
  border-bottom-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-y-blue-950\/30 {
  border-top-color: rgb(23 37 84 / 0.3) !important;
  border-bottom-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-y-blue-950\/35 {
  border-top-color: rgb(23 37 84 / 0.35) !important;
  border-bottom-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-y-blue-950\/40 {
  border-top-color: rgb(23 37 84 / 0.4) !important;
  border-bottom-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-y-blue-950\/45 {
  border-top-color: rgb(23 37 84 / 0.45) !important;
  border-bottom-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-y-blue-950\/5 {
  border-top-color: rgb(23 37 84 / 0.05) !important;
  border-bottom-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-y-blue-950\/50 {
  border-top-color: rgb(23 37 84 / 0.5) !important;
  border-bottom-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-y-blue-950\/55 {
  border-top-color: rgb(23 37 84 / 0.55) !important;
  border-bottom-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-y-blue-950\/60 {
  border-top-color: rgb(23 37 84 / 0.6) !important;
  border-bottom-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-y-blue-950\/65 {
  border-top-color: rgb(23 37 84 / 0.65) !important;
  border-bottom-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-y-blue-950\/70 {
  border-top-color: rgb(23 37 84 / 0.7) !important;
  border-bottom-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-y-blue-950\/75 {
  border-top-color: rgb(23 37 84 / 0.75) !important;
  border-bottom-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-y-blue-950\/80 {
  border-top-color: rgb(23 37 84 / 0.8) !important;
  border-bottom-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-y-blue-950\/85 {
  border-top-color: rgb(23 37 84 / 0.85) !important;
  border-bottom-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-y-blue-950\/90 {
  border-top-color: rgb(23 37 84 / 0.9) !important;
  border-bottom-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-y-blue-950\/95 {
  border-top-color: rgb(23 37 84 / 0.95) !important;
  border-bottom-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-y-current {
  border-top-color: currentColor !important;
  border-bottom-color: currentColor !important;
}

.tw-border-y-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-100\/0 {
  border-top-color: rgb(207 250 254 / 0) !important;
  border-bottom-color: rgb(207 250 254 / 0) !important;
}

.tw-border-y-cyan-100\/10 {
  border-top-color: rgb(207 250 254 / 0.1) !important;
  border-bottom-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-y-cyan-100\/100 {
  border-top-color: rgb(207 250 254 / 1) !important;
  border-bottom-color: rgb(207 250 254 / 1) !important;
}

.tw-border-y-cyan-100\/15 {
  border-top-color: rgb(207 250 254 / 0.15) !important;
  border-bottom-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-y-cyan-100\/20 {
  border-top-color: rgb(207 250 254 / 0.2) !important;
  border-bottom-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-y-cyan-100\/25 {
  border-top-color: rgb(207 250 254 / 0.25) !important;
  border-bottom-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-y-cyan-100\/30 {
  border-top-color: rgb(207 250 254 / 0.3) !important;
  border-bottom-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-y-cyan-100\/35 {
  border-top-color: rgb(207 250 254 / 0.35) !important;
  border-bottom-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-y-cyan-100\/40 {
  border-top-color: rgb(207 250 254 / 0.4) !important;
  border-bottom-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-y-cyan-100\/45 {
  border-top-color: rgb(207 250 254 / 0.45) !important;
  border-bottom-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-y-cyan-100\/5 {
  border-top-color: rgb(207 250 254 / 0.05) !important;
  border-bottom-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-y-cyan-100\/50 {
  border-top-color: rgb(207 250 254 / 0.5) !important;
  border-bottom-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-y-cyan-100\/55 {
  border-top-color: rgb(207 250 254 / 0.55) !important;
  border-bottom-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-y-cyan-100\/60 {
  border-top-color: rgb(207 250 254 / 0.6) !important;
  border-bottom-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-y-cyan-100\/65 {
  border-top-color: rgb(207 250 254 / 0.65) !important;
  border-bottom-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-y-cyan-100\/70 {
  border-top-color: rgb(207 250 254 / 0.7) !important;
  border-bottom-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-y-cyan-100\/75 {
  border-top-color: rgb(207 250 254 / 0.75) !important;
  border-bottom-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-y-cyan-100\/80 {
  border-top-color: rgb(207 250 254 / 0.8) !important;
  border-bottom-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-y-cyan-100\/85 {
  border-top-color: rgb(207 250 254 / 0.85) !important;
  border-bottom-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-y-cyan-100\/90 {
  border-top-color: rgb(207 250 254 / 0.9) !important;
  border-bottom-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-y-cyan-100\/95 {
  border-top-color: rgb(207 250 254 / 0.95) !important;
  border-bottom-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-y-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-200\/0 {
  border-top-color: rgb(165 243 252 / 0) !important;
  border-bottom-color: rgb(165 243 252 / 0) !important;
}

.tw-border-y-cyan-200\/10 {
  border-top-color: rgb(165 243 252 / 0.1) !important;
  border-bottom-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-y-cyan-200\/100 {
  border-top-color: rgb(165 243 252 / 1) !important;
  border-bottom-color: rgb(165 243 252 / 1) !important;
}

.tw-border-y-cyan-200\/15 {
  border-top-color: rgb(165 243 252 / 0.15) !important;
  border-bottom-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-y-cyan-200\/20 {
  border-top-color: rgb(165 243 252 / 0.2) !important;
  border-bottom-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-y-cyan-200\/25 {
  border-top-color: rgb(165 243 252 / 0.25) !important;
  border-bottom-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-y-cyan-200\/30 {
  border-top-color: rgb(165 243 252 / 0.3) !important;
  border-bottom-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-y-cyan-200\/35 {
  border-top-color: rgb(165 243 252 / 0.35) !important;
  border-bottom-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-y-cyan-200\/40 {
  border-top-color: rgb(165 243 252 / 0.4) !important;
  border-bottom-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-y-cyan-200\/45 {
  border-top-color: rgb(165 243 252 / 0.45) !important;
  border-bottom-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-y-cyan-200\/5 {
  border-top-color: rgb(165 243 252 / 0.05) !important;
  border-bottom-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-y-cyan-200\/50 {
  border-top-color: rgb(165 243 252 / 0.5) !important;
  border-bottom-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-y-cyan-200\/55 {
  border-top-color: rgb(165 243 252 / 0.55) !important;
  border-bottom-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-y-cyan-200\/60 {
  border-top-color: rgb(165 243 252 / 0.6) !important;
  border-bottom-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-y-cyan-200\/65 {
  border-top-color: rgb(165 243 252 / 0.65) !important;
  border-bottom-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-y-cyan-200\/70 {
  border-top-color: rgb(165 243 252 / 0.7) !important;
  border-bottom-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-y-cyan-200\/75 {
  border-top-color: rgb(165 243 252 / 0.75) !important;
  border-bottom-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-y-cyan-200\/80 {
  border-top-color: rgb(165 243 252 / 0.8) !important;
  border-bottom-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-y-cyan-200\/85 {
  border-top-color: rgb(165 243 252 / 0.85) !important;
  border-bottom-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-y-cyan-200\/90 {
  border-top-color: rgb(165 243 252 / 0.9) !important;
  border-bottom-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-y-cyan-200\/95 {
  border-top-color: rgb(165 243 252 / 0.95) !important;
  border-bottom-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-y-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-300\/0 {
  border-top-color: rgb(103 232 249 / 0) !important;
  border-bottom-color: rgb(103 232 249 / 0) !important;
}

.tw-border-y-cyan-300\/10 {
  border-top-color: rgb(103 232 249 / 0.1) !important;
  border-bottom-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-y-cyan-300\/100 {
  border-top-color: rgb(103 232 249 / 1) !important;
  border-bottom-color: rgb(103 232 249 / 1) !important;
}

.tw-border-y-cyan-300\/15 {
  border-top-color: rgb(103 232 249 / 0.15) !important;
  border-bottom-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-y-cyan-300\/20 {
  border-top-color: rgb(103 232 249 / 0.2) !important;
  border-bottom-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-y-cyan-300\/25 {
  border-top-color: rgb(103 232 249 / 0.25) !important;
  border-bottom-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-y-cyan-300\/30 {
  border-top-color: rgb(103 232 249 / 0.3) !important;
  border-bottom-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-y-cyan-300\/35 {
  border-top-color: rgb(103 232 249 / 0.35) !important;
  border-bottom-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-y-cyan-300\/40 {
  border-top-color: rgb(103 232 249 / 0.4) !important;
  border-bottom-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-y-cyan-300\/45 {
  border-top-color: rgb(103 232 249 / 0.45) !important;
  border-bottom-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-y-cyan-300\/5 {
  border-top-color: rgb(103 232 249 / 0.05) !important;
  border-bottom-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-y-cyan-300\/50 {
  border-top-color: rgb(103 232 249 / 0.5) !important;
  border-bottom-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-y-cyan-300\/55 {
  border-top-color: rgb(103 232 249 / 0.55) !important;
  border-bottom-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-y-cyan-300\/60 {
  border-top-color: rgb(103 232 249 / 0.6) !important;
  border-bottom-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-y-cyan-300\/65 {
  border-top-color: rgb(103 232 249 / 0.65) !important;
  border-bottom-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-y-cyan-300\/70 {
  border-top-color: rgb(103 232 249 / 0.7) !important;
  border-bottom-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-y-cyan-300\/75 {
  border-top-color: rgb(103 232 249 / 0.75) !important;
  border-bottom-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-y-cyan-300\/80 {
  border-top-color: rgb(103 232 249 / 0.8) !important;
  border-bottom-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-y-cyan-300\/85 {
  border-top-color: rgb(103 232 249 / 0.85) !important;
  border-bottom-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-y-cyan-300\/90 {
  border-top-color: rgb(103 232 249 / 0.9) !important;
  border-bottom-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-y-cyan-300\/95 {
  border-top-color: rgb(103 232 249 / 0.95) !important;
  border-bottom-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-y-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-400\/0 {
  border-top-color: rgb(34 211 238 / 0) !important;
  border-bottom-color: rgb(34 211 238 / 0) !important;
}

.tw-border-y-cyan-400\/10 {
  border-top-color: rgb(34 211 238 / 0.1) !important;
  border-bottom-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-y-cyan-400\/100 {
  border-top-color: rgb(34 211 238 / 1) !important;
  border-bottom-color: rgb(34 211 238 / 1) !important;
}

.tw-border-y-cyan-400\/15 {
  border-top-color: rgb(34 211 238 / 0.15) !important;
  border-bottom-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-y-cyan-400\/20 {
  border-top-color: rgb(34 211 238 / 0.2) !important;
  border-bottom-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-y-cyan-400\/25 {
  border-top-color: rgb(34 211 238 / 0.25) !important;
  border-bottom-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-y-cyan-400\/30 {
  border-top-color: rgb(34 211 238 / 0.3) !important;
  border-bottom-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-y-cyan-400\/35 {
  border-top-color: rgb(34 211 238 / 0.35) !important;
  border-bottom-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-y-cyan-400\/40 {
  border-top-color: rgb(34 211 238 / 0.4) !important;
  border-bottom-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-y-cyan-400\/45 {
  border-top-color: rgb(34 211 238 / 0.45) !important;
  border-bottom-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-y-cyan-400\/5 {
  border-top-color: rgb(34 211 238 / 0.05) !important;
  border-bottom-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-y-cyan-400\/50 {
  border-top-color: rgb(34 211 238 / 0.5) !important;
  border-bottom-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-y-cyan-400\/55 {
  border-top-color: rgb(34 211 238 / 0.55) !important;
  border-bottom-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-y-cyan-400\/60 {
  border-top-color: rgb(34 211 238 / 0.6) !important;
  border-bottom-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-y-cyan-400\/65 {
  border-top-color: rgb(34 211 238 / 0.65) !important;
  border-bottom-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-y-cyan-400\/70 {
  border-top-color: rgb(34 211 238 / 0.7) !important;
  border-bottom-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-y-cyan-400\/75 {
  border-top-color: rgb(34 211 238 / 0.75) !important;
  border-bottom-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-y-cyan-400\/80 {
  border-top-color: rgb(34 211 238 / 0.8) !important;
  border-bottom-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-y-cyan-400\/85 {
  border-top-color: rgb(34 211 238 / 0.85) !important;
  border-bottom-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-y-cyan-400\/90 {
  border-top-color: rgb(34 211 238 / 0.9) !important;
  border-bottom-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-y-cyan-400\/95 {
  border-top-color: rgb(34 211 238 / 0.95) !important;
  border-bottom-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-y-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-50\/0 {
  border-top-color: rgb(236 254 255 / 0) !important;
  border-bottom-color: rgb(236 254 255 / 0) !important;
}

.tw-border-y-cyan-50\/10 {
  border-top-color: rgb(236 254 255 / 0.1) !important;
  border-bottom-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-y-cyan-50\/100 {
  border-top-color: rgb(236 254 255 / 1) !important;
  border-bottom-color: rgb(236 254 255 / 1) !important;
}

.tw-border-y-cyan-50\/15 {
  border-top-color: rgb(236 254 255 / 0.15) !important;
  border-bottom-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-y-cyan-50\/20 {
  border-top-color: rgb(236 254 255 / 0.2) !important;
  border-bottom-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-y-cyan-50\/25 {
  border-top-color: rgb(236 254 255 / 0.25) !important;
  border-bottom-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-y-cyan-50\/30 {
  border-top-color: rgb(236 254 255 / 0.3) !important;
  border-bottom-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-y-cyan-50\/35 {
  border-top-color: rgb(236 254 255 / 0.35) !important;
  border-bottom-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-y-cyan-50\/40 {
  border-top-color: rgb(236 254 255 / 0.4) !important;
  border-bottom-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-y-cyan-50\/45 {
  border-top-color: rgb(236 254 255 / 0.45) !important;
  border-bottom-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-y-cyan-50\/5 {
  border-top-color: rgb(236 254 255 / 0.05) !important;
  border-bottom-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-y-cyan-50\/50 {
  border-top-color: rgb(236 254 255 / 0.5) !important;
  border-bottom-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-y-cyan-50\/55 {
  border-top-color: rgb(236 254 255 / 0.55) !important;
  border-bottom-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-y-cyan-50\/60 {
  border-top-color: rgb(236 254 255 / 0.6) !important;
  border-bottom-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-y-cyan-50\/65 {
  border-top-color: rgb(236 254 255 / 0.65) !important;
  border-bottom-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-y-cyan-50\/70 {
  border-top-color: rgb(236 254 255 / 0.7) !important;
  border-bottom-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-y-cyan-50\/75 {
  border-top-color: rgb(236 254 255 / 0.75) !important;
  border-bottom-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-y-cyan-50\/80 {
  border-top-color: rgb(236 254 255 / 0.8) !important;
  border-bottom-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-y-cyan-50\/85 {
  border-top-color: rgb(236 254 255 / 0.85) !important;
  border-bottom-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-y-cyan-50\/90 {
  border-top-color: rgb(236 254 255 / 0.9) !important;
  border-bottom-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-y-cyan-50\/95 {
  border-top-color: rgb(236 254 255 / 0.95) !important;
  border-bottom-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-y-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-500\/0 {
  border-top-color: rgb(6 182 212 / 0) !important;
  border-bottom-color: rgb(6 182 212 / 0) !important;
}

.tw-border-y-cyan-500\/10 {
  border-top-color: rgb(6 182 212 / 0.1) !important;
  border-bottom-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-y-cyan-500\/100 {
  border-top-color: rgb(6 182 212 / 1) !important;
  border-bottom-color: rgb(6 182 212 / 1) !important;
}

.tw-border-y-cyan-500\/15 {
  border-top-color: rgb(6 182 212 / 0.15) !important;
  border-bottom-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-y-cyan-500\/20 {
  border-top-color: rgb(6 182 212 / 0.2) !important;
  border-bottom-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-y-cyan-500\/25 {
  border-top-color: rgb(6 182 212 / 0.25) !important;
  border-bottom-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-y-cyan-500\/30 {
  border-top-color: rgb(6 182 212 / 0.3) !important;
  border-bottom-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-y-cyan-500\/35 {
  border-top-color: rgb(6 182 212 / 0.35) !important;
  border-bottom-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-y-cyan-500\/40 {
  border-top-color: rgb(6 182 212 / 0.4) !important;
  border-bottom-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-y-cyan-500\/45 {
  border-top-color: rgb(6 182 212 / 0.45) !important;
  border-bottom-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-y-cyan-500\/5 {
  border-top-color: rgb(6 182 212 / 0.05) !important;
  border-bottom-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-y-cyan-500\/50 {
  border-top-color: rgb(6 182 212 / 0.5) !important;
  border-bottom-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-y-cyan-500\/55 {
  border-top-color: rgb(6 182 212 / 0.55) !important;
  border-bottom-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-y-cyan-500\/60 {
  border-top-color: rgb(6 182 212 / 0.6) !important;
  border-bottom-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-y-cyan-500\/65 {
  border-top-color: rgb(6 182 212 / 0.65) !important;
  border-bottom-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-y-cyan-500\/70 {
  border-top-color: rgb(6 182 212 / 0.7) !important;
  border-bottom-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-y-cyan-500\/75 {
  border-top-color: rgb(6 182 212 / 0.75) !important;
  border-bottom-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-y-cyan-500\/80 {
  border-top-color: rgb(6 182 212 / 0.8) !important;
  border-bottom-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-y-cyan-500\/85 {
  border-top-color: rgb(6 182 212 / 0.85) !important;
  border-bottom-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-y-cyan-500\/90 {
  border-top-color: rgb(6 182 212 / 0.9) !important;
  border-bottom-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-y-cyan-500\/95 {
  border-top-color: rgb(6 182 212 / 0.95) !important;
  border-bottom-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-y-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-600\/0 {
  border-top-color: rgb(8 145 178 / 0) !important;
  border-bottom-color: rgb(8 145 178 / 0) !important;
}

.tw-border-y-cyan-600\/10 {
  border-top-color: rgb(8 145 178 / 0.1) !important;
  border-bottom-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-y-cyan-600\/100 {
  border-top-color: rgb(8 145 178 / 1) !important;
  border-bottom-color: rgb(8 145 178 / 1) !important;
}

.tw-border-y-cyan-600\/15 {
  border-top-color: rgb(8 145 178 / 0.15) !important;
  border-bottom-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-y-cyan-600\/20 {
  border-top-color: rgb(8 145 178 / 0.2) !important;
  border-bottom-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-y-cyan-600\/25 {
  border-top-color: rgb(8 145 178 / 0.25) !important;
  border-bottom-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-y-cyan-600\/30 {
  border-top-color: rgb(8 145 178 / 0.3) !important;
  border-bottom-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-y-cyan-600\/35 {
  border-top-color: rgb(8 145 178 / 0.35) !important;
  border-bottom-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-y-cyan-600\/40 {
  border-top-color: rgb(8 145 178 / 0.4) !important;
  border-bottom-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-y-cyan-600\/45 {
  border-top-color: rgb(8 145 178 / 0.45) !important;
  border-bottom-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-y-cyan-600\/5 {
  border-top-color: rgb(8 145 178 / 0.05) !important;
  border-bottom-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-y-cyan-600\/50 {
  border-top-color: rgb(8 145 178 / 0.5) !important;
  border-bottom-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-y-cyan-600\/55 {
  border-top-color: rgb(8 145 178 / 0.55) !important;
  border-bottom-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-y-cyan-600\/60 {
  border-top-color: rgb(8 145 178 / 0.6) !important;
  border-bottom-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-y-cyan-600\/65 {
  border-top-color: rgb(8 145 178 / 0.65) !important;
  border-bottom-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-y-cyan-600\/70 {
  border-top-color: rgb(8 145 178 / 0.7) !important;
  border-bottom-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-y-cyan-600\/75 {
  border-top-color: rgb(8 145 178 / 0.75) !important;
  border-bottom-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-y-cyan-600\/80 {
  border-top-color: rgb(8 145 178 / 0.8) !important;
  border-bottom-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-y-cyan-600\/85 {
  border-top-color: rgb(8 145 178 / 0.85) !important;
  border-bottom-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-y-cyan-600\/90 {
  border-top-color: rgb(8 145 178 / 0.9) !important;
  border-bottom-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-y-cyan-600\/95 {
  border-top-color: rgb(8 145 178 / 0.95) !important;
  border-bottom-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-y-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-700\/0 {
  border-top-color: rgb(14 116 144 / 0) !important;
  border-bottom-color: rgb(14 116 144 / 0) !important;
}

.tw-border-y-cyan-700\/10 {
  border-top-color: rgb(14 116 144 / 0.1) !important;
  border-bottom-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-y-cyan-700\/100 {
  border-top-color: rgb(14 116 144 / 1) !important;
  border-bottom-color: rgb(14 116 144 / 1) !important;
}

.tw-border-y-cyan-700\/15 {
  border-top-color: rgb(14 116 144 / 0.15) !important;
  border-bottom-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-y-cyan-700\/20 {
  border-top-color: rgb(14 116 144 / 0.2) !important;
  border-bottom-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-y-cyan-700\/25 {
  border-top-color: rgb(14 116 144 / 0.25) !important;
  border-bottom-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-y-cyan-700\/30 {
  border-top-color: rgb(14 116 144 / 0.3) !important;
  border-bottom-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-y-cyan-700\/35 {
  border-top-color: rgb(14 116 144 / 0.35) !important;
  border-bottom-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-y-cyan-700\/40 {
  border-top-color: rgb(14 116 144 / 0.4) !important;
  border-bottom-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-y-cyan-700\/45 {
  border-top-color: rgb(14 116 144 / 0.45) !important;
  border-bottom-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-y-cyan-700\/5 {
  border-top-color: rgb(14 116 144 / 0.05) !important;
  border-bottom-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-y-cyan-700\/50 {
  border-top-color: rgb(14 116 144 / 0.5) !important;
  border-bottom-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-y-cyan-700\/55 {
  border-top-color: rgb(14 116 144 / 0.55) !important;
  border-bottom-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-y-cyan-700\/60 {
  border-top-color: rgb(14 116 144 / 0.6) !important;
  border-bottom-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-y-cyan-700\/65 {
  border-top-color: rgb(14 116 144 / 0.65) !important;
  border-bottom-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-y-cyan-700\/70 {
  border-top-color: rgb(14 116 144 / 0.7) !important;
  border-bottom-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-y-cyan-700\/75 {
  border-top-color: rgb(14 116 144 / 0.75) !important;
  border-bottom-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-y-cyan-700\/80 {
  border-top-color: rgb(14 116 144 / 0.8) !important;
  border-bottom-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-y-cyan-700\/85 {
  border-top-color: rgb(14 116 144 / 0.85) !important;
  border-bottom-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-y-cyan-700\/90 {
  border-top-color: rgb(14 116 144 / 0.9) !important;
  border-bottom-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-y-cyan-700\/95 {
  border-top-color: rgb(14 116 144 / 0.95) !important;
  border-bottom-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-y-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-800\/0 {
  border-top-color: rgb(21 94 117 / 0) !important;
  border-bottom-color: rgb(21 94 117 / 0) !important;
}

.tw-border-y-cyan-800\/10 {
  border-top-color: rgb(21 94 117 / 0.1) !important;
  border-bottom-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-y-cyan-800\/100 {
  border-top-color: rgb(21 94 117 / 1) !important;
  border-bottom-color: rgb(21 94 117 / 1) !important;
}

.tw-border-y-cyan-800\/15 {
  border-top-color: rgb(21 94 117 / 0.15) !important;
  border-bottom-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-y-cyan-800\/20 {
  border-top-color: rgb(21 94 117 / 0.2) !important;
  border-bottom-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-y-cyan-800\/25 {
  border-top-color: rgb(21 94 117 / 0.25) !important;
  border-bottom-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-y-cyan-800\/30 {
  border-top-color: rgb(21 94 117 / 0.3) !important;
  border-bottom-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-y-cyan-800\/35 {
  border-top-color: rgb(21 94 117 / 0.35) !important;
  border-bottom-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-y-cyan-800\/40 {
  border-top-color: rgb(21 94 117 / 0.4) !important;
  border-bottom-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-y-cyan-800\/45 {
  border-top-color: rgb(21 94 117 / 0.45) !important;
  border-bottom-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-y-cyan-800\/5 {
  border-top-color: rgb(21 94 117 / 0.05) !important;
  border-bottom-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-y-cyan-800\/50 {
  border-top-color: rgb(21 94 117 / 0.5) !important;
  border-bottom-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-y-cyan-800\/55 {
  border-top-color: rgb(21 94 117 / 0.55) !important;
  border-bottom-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-y-cyan-800\/60 {
  border-top-color: rgb(21 94 117 / 0.6) !important;
  border-bottom-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-y-cyan-800\/65 {
  border-top-color: rgb(21 94 117 / 0.65) !important;
  border-bottom-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-y-cyan-800\/70 {
  border-top-color: rgb(21 94 117 / 0.7) !important;
  border-bottom-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-y-cyan-800\/75 {
  border-top-color: rgb(21 94 117 / 0.75) !important;
  border-bottom-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-y-cyan-800\/80 {
  border-top-color: rgb(21 94 117 / 0.8) !important;
  border-bottom-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-y-cyan-800\/85 {
  border-top-color: rgb(21 94 117 / 0.85) !important;
  border-bottom-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-y-cyan-800\/90 {
  border-top-color: rgb(21 94 117 / 0.9) !important;
  border-bottom-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-y-cyan-800\/95 {
  border-top-color: rgb(21 94 117 / 0.95) !important;
  border-bottom-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-y-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-900\/0 {
  border-top-color: rgb(22 78 99 / 0) !important;
  border-bottom-color: rgb(22 78 99 / 0) !important;
}

.tw-border-y-cyan-900\/10 {
  border-top-color: rgb(22 78 99 / 0.1) !important;
  border-bottom-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-y-cyan-900\/100 {
  border-top-color: rgb(22 78 99 / 1) !important;
  border-bottom-color: rgb(22 78 99 / 1) !important;
}

.tw-border-y-cyan-900\/15 {
  border-top-color: rgb(22 78 99 / 0.15) !important;
  border-bottom-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-y-cyan-900\/20 {
  border-top-color: rgb(22 78 99 / 0.2) !important;
  border-bottom-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-y-cyan-900\/25 {
  border-top-color: rgb(22 78 99 / 0.25) !important;
  border-bottom-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-y-cyan-900\/30 {
  border-top-color: rgb(22 78 99 / 0.3) !important;
  border-bottom-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-y-cyan-900\/35 {
  border-top-color: rgb(22 78 99 / 0.35) !important;
  border-bottom-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-y-cyan-900\/40 {
  border-top-color: rgb(22 78 99 / 0.4) !important;
  border-bottom-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-y-cyan-900\/45 {
  border-top-color: rgb(22 78 99 / 0.45) !important;
  border-bottom-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-y-cyan-900\/5 {
  border-top-color: rgb(22 78 99 / 0.05) !important;
  border-bottom-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-y-cyan-900\/50 {
  border-top-color: rgb(22 78 99 / 0.5) !important;
  border-bottom-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-y-cyan-900\/55 {
  border-top-color: rgb(22 78 99 / 0.55) !important;
  border-bottom-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-y-cyan-900\/60 {
  border-top-color: rgb(22 78 99 / 0.6) !important;
  border-bottom-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-y-cyan-900\/65 {
  border-top-color: rgb(22 78 99 / 0.65) !important;
  border-bottom-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-y-cyan-900\/70 {
  border-top-color: rgb(22 78 99 / 0.7) !important;
  border-bottom-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-y-cyan-900\/75 {
  border-top-color: rgb(22 78 99 / 0.75) !important;
  border-bottom-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-y-cyan-900\/80 {
  border-top-color: rgb(22 78 99 / 0.8) !important;
  border-bottom-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-y-cyan-900\/85 {
  border-top-color: rgb(22 78 99 / 0.85) !important;
  border-bottom-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-y-cyan-900\/90 {
  border-top-color: rgb(22 78 99 / 0.9) !important;
  border-bottom-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-y-cyan-900\/95 {
  border-top-color: rgb(22 78 99 / 0.95) !important;
  border-bottom-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-y-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-y-cyan-950\/0 {
  border-top-color: rgb(8 51 68 / 0) !important;
  border-bottom-color: rgb(8 51 68 / 0) !important;
}

.tw-border-y-cyan-950\/10 {
  border-top-color: rgb(8 51 68 / 0.1) !important;
  border-bottom-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-y-cyan-950\/100 {
  border-top-color: rgb(8 51 68 / 1) !important;
  border-bottom-color: rgb(8 51 68 / 1) !important;
}

.tw-border-y-cyan-950\/15 {
  border-top-color: rgb(8 51 68 / 0.15) !important;
  border-bottom-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-y-cyan-950\/20 {
  border-top-color: rgb(8 51 68 / 0.2) !important;
  border-bottom-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-y-cyan-950\/25 {
  border-top-color: rgb(8 51 68 / 0.25) !important;
  border-bottom-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-y-cyan-950\/30 {
  border-top-color: rgb(8 51 68 / 0.3) !important;
  border-bottom-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-y-cyan-950\/35 {
  border-top-color: rgb(8 51 68 / 0.35) !important;
  border-bottom-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-y-cyan-950\/40 {
  border-top-color: rgb(8 51 68 / 0.4) !important;
  border-bottom-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-y-cyan-950\/45 {
  border-top-color: rgb(8 51 68 / 0.45) !important;
  border-bottom-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-y-cyan-950\/5 {
  border-top-color: rgb(8 51 68 / 0.05) !important;
  border-bottom-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-y-cyan-950\/50 {
  border-top-color: rgb(8 51 68 / 0.5) !important;
  border-bottom-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-y-cyan-950\/55 {
  border-top-color: rgb(8 51 68 / 0.55) !important;
  border-bottom-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-y-cyan-950\/60 {
  border-top-color: rgb(8 51 68 / 0.6) !important;
  border-bottom-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-y-cyan-950\/65 {
  border-top-color: rgb(8 51 68 / 0.65) !important;
  border-bottom-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-y-cyan-950\/70 {
  border-top-color: rgb(8 51 68 / 0.7) !important;
  border-bottom-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-y-cyan-950\/75 {
  border-top-color: rgb(8 51 68 / 0.75) !important;
  border-bottom-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-y-cyan-950\/80 {
  border-top-color: rgb(8 51 68 / 0.8) !important;
  border-bottom-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-y-cyan-950\/85 {
  border-top-color: rgb(8 51 68 / 0.85) !important;
  border-bottom-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-y-cyan-950\/90 {
  border-top-color: rgb(8 51 68 / 0.9) !important;
  border-bottom-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-y-cyan-950\/95 {
  border-top-color: rgb(8 51 68 / 0.95) !important;
  border-bottom-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-y-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-100\/0 {
  border-top-color: rgb(209 250 229 / 0) !important;
  border-bottom-color: rgb(209 250 229 / 0) !important;
}

.tw-border-y-emerald-100\/10 {
  border-top-color: rgb(209 250 229 / 0.1) !important;
  border-bottom-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-y-emerald-100\/100 {
  border-top-color: rgb(209 250 229 / 1) !important;
  border-bottom-color: rgb(209 250 229 / 1) !important;
}

.tw-border-y-emerald-100\/15 {
  border-top-color: rgb(209 250 229 / 0.15) !important;
  border-bottom-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-y-emerald-100\/20 {
  border-top-color: rgb(209 250 229 / 0.2) !important;
  border-bottom-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-y-emerald-100\/25 {
  border-top-color: rgb(209 250 229 / 0.25) !important;
  border-bottom-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-y-emerald-100\/30 {
  border-top-color: rgb(209 250 229 / 0.3) !important;
  border-bottom-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-y-emerald-100\/35 {
  border-top-color: rgb(209 250 229 / 0.35) !important;
  border-bottom-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-y-emerald-100\/40 {
  border-top-color: rgb(209 250 229 / 0.4) !important;
  border-bottom-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-y-emerald-100\/45 {
  border-top-color: rgb(209 250 229 / 0.45) !important;
  border-bottom-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-y-emerald-100\/5 {
  border-top-color: rgb(209 250 229 / 0.05) !important;
  border-bottom-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-y-emerald-100\/50 {
  border-top-color: rgb(209 250 229 / 0.5) !important;
  border-bottom-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-y-emerald-100\/55 {
  border-top-color: rgb(209 250 229 / 0.55) !important;
  border-bottom-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-y-emerald-100\/60 {
  border-top-color: rgb(209 250 229 / 0.6) !important;
  border-bottom-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-y-emerald-100\/65 {
  border-top-color: rgb(209 250 229 / 0.65) !important;
  border-bottom-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-y-emerald-100\/70 {
  border-top-color: rgb(209 250 229 / 0.7) !important;
  border-bottom-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-y-emerald-100\/75 {
  border-top-color: rgb(209 250 229 / 0.75) !important;
  border-bottom-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-y-emerald-100\/80 {
  border-top-color: rgb(209 250 229 / 0.8) !important;
  border-bottom-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-y-emerald-100\/85 {
  border-top-color: rgb(209 250 229 / 0.85) !important;
  border-bottom-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-y-emerald-100\/90 {
  border-top-color: rgb(209 250 229 / 0.9) !important;
  border-bottom-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-y-emerald-100\/95 {
  border-top-color: rgb(209 250 229 / 0.95) !important;
  border-bottom-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-y-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-200\/0 {
  border-top-color: rgb(167 243 208 / 0) !important;
  border-bottom-color: rgb(167 243 208 / 0) !important;
}

.tw-border-y-emerald-200\/10 {
  border-top-color: rgb(167 243 208 / 0.1) !important;
  border-bottom-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-y-emerald-200\/100 {
  border-top-color: rgb(167 243 208 / 1) !important;
  border-bottom-color: rgb(167 243 208 / 1) !important;
}

.tw-border-y-emerald-200\/15 {
  border-top-color: rgb(167 243 208 / 0.15) !important;
  border-bottom-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-y-emerald-200\/20 {
  border-top-color: rgb(167 243 208 / 0.2) !important;
  border-bottom-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-y-emerald-200\/25 {
  border-top-color: rgb(167 243 208 / 0.25) !important;
  border-bottom-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-y-emerald-200\/30 {
  border-top-color: rgb(167 243 208 / 0.3) !important;
  border-bottom-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-y-emerald-200\/35 {
  border-top-color: rgb(167 243 208 / 0.35) !important;
  border-bottom-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-y-emerald-200\/40 {
  border-top-color: rgb(167 243 208 / 0.4) !important;
  border-bottom-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-y-emerald-200\/45 {
  border-top-color: rgb(167 243 208 / 0.45) !important;
  border-bottom-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-y-emerald-200\/5 {
  border-top-color: rgb(167 243 208 / 0.05) !important;
  border-bottom-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-y-emerald-200\/50 {
  border-top-color: rgb(167 243 208 / 0.5) !important;
  border-bottom-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-y-emerald-200\/55 {
  border-top-color: rgb(167 243 208 / 0.55) !important;
  border-bottom-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-y-emerald-200\/60 {
  border-top-color: rgb(167 243 208 / 0.6) !important;
  border-bottom-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-y-emerald-200\/65 {
  border-top-color: rgb(167 243 208 / 0.65) !important;
  border-bottom-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-y-emerald-200\/70 {
  border-top-color: rgb(167 243 208 / 0.7) !important;
  border-bottom-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-y-emerald-200\/75 {
  border-top-color: rgb(167 243 208 / 0.75) !important;
  border-bottom-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-y-emerald-200\/80 {
  border-top-color: rgb(167 243 208 / 0.8) !important;
  border-bottom-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-y-emerald-200\/85 {
  border-top-color: rgb(167 243 208 / 0.85) !important;
  border-bottom-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-y-emerald-200\/90 {
  border-top-color: rgb(167 243 208 / 0.9) !important;
  border-bottom-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-y-emerald-200\/95 {
  border-top-color: rgb(167 243 208 / 0.95) !important;
  border-bottom-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-y-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-300\/0 {
  border-top-color: rgb(110 231 183 / 0) !important;
  border-bottom-color: rgb(110 231 183 / 0) !important;
}

.tw-border-y-emerald-300\/10 {
  border-top-color: rgb(110 231 183 / 0.1) !important;
  border-bottom-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-y-emerald-300\/100 {
  border-top-color: rgb(110 231 183 / 1) !important;
  border-bottom-color: rgb(110 231 183 / 1) !important;
}

.tw-border-y-emerald-300\/15 {
  border-top-color: rgb(110 231 183 / 0.15) !important;
  border-bottom-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-y-emerald-300\/20 {
  border-top-color: rgb(110 231 183 / 0.2) !important;
  border-bottom-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-y-emerald-300\/25 {
  border-top-color: rgb(110 231 183 / 0.25) !important;
  border-bottom-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-y-emerald-300\/30 {
  border-top-color: rgb(110 231 183 / 0.3) !important;
  border-bottom-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-y-emerald-300\/35 {
  border-top-color: rgb(110 231 183 / 0.35) !important;
  border-bottom-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-y-emerald-300\/40 {
  border-top-color: rgb(110 231 183 / 0.4) !important;
  border-bottom-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-y-emerald-300\/45 {
  border-top-color: rgb(110 231 183 / 0.45) !important;
  border-bottom-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-y-emerald-300\/5 {
  border-top-color: rgb(110 231 183 / 0.05) !important;
  border-bottom-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-y-emerald-300\/50 {
  border-top-color: rgb(110 231 183 / 0.5) !important;
  border-bottom-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-y-emerald-300\/55 {
  border-top-color: rgb(110 231 183 / 0.55) !important;
  border-bottom-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-y-emerald-300\/60 {
  border-top-color: rgb(110 231 183 / 0.6) !important;
  border-bottom-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-y-emerald-300\/65 {
  border-top-color: rgb(110 231 183 / 0.65) !important;
  border-bottom-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-y-emerald-300\/70 {
  border-top-color: rgb(110 231 183 / 0.7) !important;
  border-bottom-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-y-emerald-300\/75 {
  border-top-color: rgb(110 231 183 / 0.75) !important;
  border-bottom-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-y-emerald-300\/80 {
  border-top-color: rgb(110 231 183 / 0.8) !important;
  border-bottom-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-y-emerald-300\/85 {
  border-top-color: rgb(110 231 183 / 0.85) !important;
  border-bottom-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-y-emerald-300\/90 {
  border-top-color: rgb(110 231 183 / 0.9) !important;
  border-bottom-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-y-emerald-300\/95 {
  border-top-color: rgb(110 231 183 / 0.95) !important;
  border-bottom-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-y-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-400\/0 {
  border-top-color: rgb(52 211 153 / 0) !important;
  border-bottom-color: rgb(52 211 153 / 0) !important;
}

.tw-border-y-emerald-400\/10 {
  border-top-color: rgb(52 211 153 / 0.1) !important;
  border-bottom-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-y-emerald-400\/100 {
  border-top-color: rgb(52 211 153 / 1) !important;
  border-bottom-color: rgb(52 211 153 / 1) !important;
}

.tw-border-y-emerald-400\/15 {
  border-top-color: rgb(52 211 153 / 0.15) !important;
  border-bottom-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-y-emerald-400\/20 {
  border-top-color: rgb(52 211 153 / 0.2) !important;
  border-bottom-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-y-emerald-400\/25 {
  border-top-color: rgb(52 211 153 / 0.25) !important;
  border-bottom-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-y-emerald-400\/30 {
  border-top-color: rgb(52 211 153 / 0.3) !important;
  border-bottom-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-y-emerald-400\/35 {
  border-top-color: rgb(52 211 153 / 0.35) !important;
  border-bottom-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-y-emerald-400\/40 {
  border-top-color: rgb(52 211 153 / 0.4) !important;
  border-bottom-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-y-emerald-400\/45 {
  border-top-color: rgb(52 211 153 / 0.45) !important;
  border-bottom-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-y-emerald-400\/5 {
  border-top-color: rgb(52 211 153 / 0.05) !important;
  border-bottom-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-y-emerald-400\/50 {
  border-top-color: rgb(52 211 153 / 0.5) !important;
  border-bottom-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-y-emerald-400\/55 {
  border-top-color: rgb(52 211 153 / 0.55) !important;
  border-bottom-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-y-emerald-400\/60 {
  border-top-color: rgb(52 211 153 / 0.6) !important;
  border-bottom-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-y-emerald-400\/65 {
  border-top-color: rgb(52 211 153 / 0.65) !important;
  border-bottom-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-y-emerald-400\/70 {
  border-top-color: rgb(52 211 153 / 0.7) !important;
  border-bottom-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-y-emerald-400\/75 {
  border-top-color: rgb(52 211 153 / 0.75) !important;
  border-bottom-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-y-emerald-400\/80 {
  border-top-color: rgb(52 211 153 / 0.8) !important;
  border-bottom-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-y-emerald-400\/85 {
  border-top-color: rgb(52 211 153 / 0.85) !important;
  border-bottom-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-y-emerald-400\/90 {
  border-top-color: rgb(52 211 153 / 0.9) !important;
  border-bottom-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-y-emerald-400\/95 {
  border-top-color: rgb(52 211 153 / 0.95) !important;
  border-bottom-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-y-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-50\/0 {
  border-top-color: rgb(236 253 245 / 0) !important;
  border-bottom-color: rgb(236 253 245 / 0) !important;
}

.tw-border-y-emerald-50\/10 {
  border-top-color: rgb(236 253 245 / 0.1) !important;
  border-bottom-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-y-emerald-50\/100 {
  border-top-color: rgb(236 253 245 / 1) !important;
  border-bottom-color: rgb(236 253 245 / 1) !important;
}

.tw-border-y-emerald-50\/15 {
  border-top-color: rgb(236 253 245 / 0.15) !important;
  border-bottom-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-y-emerald-50\/20 {
  border-top-color: rgb(236 253 245 / 0.2) !important;
  border-bottom-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-y-emerald-50\/25 {
  border-top-color: rgb(236 253 245 / 0.25) !important;
  border-bottom-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-y-emerald-50\/30 {
  border-top-color: rgb(236 253 245 / 0.3) !important;
  border-bottom-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-y-emerald-50\/35 {
  border-top-color: rgb(236 253 245 / 0.35) !important;
  border-bottom-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-y-emerald-50\/40 {
  border-top-color: rgb(236 253 245 / 0.4) !important;
  border-bottom-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-y-emerald-50\/45 {
  border-top-color: rgb(236 253 245 / 0.45) !important;
  border-bottom-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-y-emerald-50\/5 {
  border-top-color: rgb(236 253 245 / 0.05) !important;
  border-bottom-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-y-emerald-50\/50 {
  border-top-color: rgb(236 253 245 / 0.5) !important;
  border-bottom-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-y-emerald-50\/55 {
  border-top-color: rgb(236 253 245 / 0.55) !important;
  border-bottom-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-y-emerald-50\/60 {
  border-top-color: rgb(236 253 245 / 0.6) !important;
  border-bottom-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-y-emerald-50\/65 {
  border-top-color: rgb(236 253 245 / 0.65) !important;
  border-bottom-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-y-emerald-50\/70 {
  border-top-color: rgb(236 253 245 / 0.7) !important;
  border-bottom-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-y-emerald-50\/75 {
  border-top-color: rgb(236 253 245 / 0.75) !important;
  border-bottom-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-y-emerald-50\/80 {
  border-top-color: rgb(236 253 245 / 0.8) !important;
  border-bottom-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-y-emerald-50\/85 {
  border-top-color: rgb(236 253 245 / 0.85) !important;
  border-bottom-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-y-emerald-50\/90 {
  border-top-color: rgb(236 253 245 / 0.9) !important;
  border-bottom-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-y-emerald-50\/95 {
  border-top-color: rgb(236 253 245 / 0.95) !important;
  border-bottom-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-y-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-500\/0 {
  border-top-color: rgb(16 185 129 / 0) !important;
  border-bottom-color: rgb(16 185 129 / 0) !important;
}

.tw-border-y-emerald-500\/10 {
  border-top-color: rgb(16 185 129 / 0.1) !important;
  border-bottom-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-y-emerald-500\/100 {
  border-top-color: rgb(16 185 129 / 1) !important;
  border-bottom-color: rgb(16 185 129 / 1) !important;
}

.tw-border-y-emerald-500\/15 {
  border-top-color: rgb(16 185 129 / 0.15) !important;
  border-bottom-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-y-emerald-500\/20 {
  border-top-color: rgb(16 185 129 / 0.2) !important;
  border-bottom-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-y-emerald-500\/25 {
  border-top-color: rgb(16 185 129 / 0.25) !important;
  border-bottom-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-y-emerald-500\/30 {
  border-top-color: rgb(16 185 129 / 0.3) !important;
  border-bottom-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-y-emerald-500\/35 {
  border-top-color: rgb(16 185 129 / 0.35) !important;
  border-bottom-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-y-emerald-500\/40 {
  border-top-color: rgb(16 185 129 / 0.4) !important;
  border-bottom-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-y-emerald-500\/45 {
  border-top-color: rgb(16 185 129 / 0.45) !important;
  border-bottom-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-y-emerald-500\/5 {
  border-top-color: rgb(16 185 129 / 0.05) !important;
  border-bottom-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-y-emerald-500\/50 {
  border-top-color: rgb(16 185 129 / 0.5) !important;
  border-bottom-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-y-emerald-500\/55 {
  border-top-color: rgb(16 185 129 / 0.55) !important;
  border-bottom-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-y-emerald-500\/60 {
  border-top-color: rgb(16 185 129 / 0.6) !important;
  border-bottom-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-y-emerald-500\/65 {
  border-top-color: rgb(16 185 129 / 0.65) !important;
  border-bottom-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-y-emerald-500\/70 {
  border-top-color: rgb(16 185 129 / 0.7) !important;
  border-bottom-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-y-emerald-500\/75 {
  border-top-color: rgb(16 185 129 / 0.75) !important;
  border-bottom-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-y-emerald-500\/80 {
  border-top-color: rgb(16 185 129 / 0.8) !important;
  border-bottom-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-y-emerald-500\/85 {
  border-top-color: rgb(16 185 129 / 0.85) !important;
  border-bottom-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-y-emerald-500\/90 {
  border-top-color: rgb(16 185 129 / 0.9) !important;
  border-bottom-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-y-emerald-500\/95 {
  border-top-color: rgb(16 185 129 / 0.95) !important;
  border-bottom-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-y-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-600\/0 {
  border-top-color: rgb(5 150 105 / 0) !important;
  border-bottom-color: rgb(5 150 105 / 0) !important;
}

.tw-border-y-emerald-600\/10 {
  border-top-color: rgb(5 150 105 / 0.1) !important;
  border-bottom-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-y-emerald-600\/100 {
  border-top-color: rgb(5 150 105 / 1) !important;
  border-bottom-color: rgb(5 150 105 / 1) !important;
}

.tw-border-y-emerald-600\/15 {
  border-top-color: rgb(5 150 105 / 0.15) !important;
  border-bottom-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-y-emerald-600\/20 {
  border-top-color: rgb(5 150 105 / 0.2) !important;
  border-bottom-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-y-emerald-600\/25 {
  border-top-color: rgb(5 150 105 / 0.25) !important;
  border-bottom-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-y-emerald-600\/30 {
  border-top-color: rgb(5 150 105 / 0.3) !important;
  border-bottom-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-y-emerald-600\/35 {
  border-top-color: rgb(5 150 105 / 0.35) !important;
  border-bottom-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-y-emerald-600\/40 {
  border-top-color: rgb(5 150 105 / 0.4) !important;
  border-bottom-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-y-emerald-600\/45 {
  border-top-color: rgb(5 150 105 / 0.45) !important;
  border-bottom-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-y-emerald-600\/5 {
  border-top-color: rgb(5 150 105 / 0.05) !important;
  border-bottom-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-y-emerald-600\/50 {
  border-top-color: rgb(5 150 105 / 0.5) !important;
  border-bottom-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-y-emerald-600\/55 {
  border-top-color: rgb(5 150 105 / 0.55) !important;
  border-bottom-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-y-emerald-600\/60 {
  border-top-color: rgb(5 150 105 / 0.6) !important;
  border-bottom-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-y-emerald-600\/65 {
  border-top-color: rgb(5 150 105 / 0.65) !important;
  border-bottom-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-y-emerald-600\/70 {
  border-top-color: rgb(5 150 105 / 0.7) !important;
  border-bottom-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-y-emerald-600\/75 {
  border-top-color: rgb(5 150 105 / 0.75) !important;
  border-bottom-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-y-emerald-600\/80 {
  border-top-color: rgb(5 150 105 / 0.8) !important;
  border-bottom-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-y-emerald-600\/85 {
  border-top-color: rgb(5 150 105 / 0.85) !important;
  border-bottom-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-y-emerald-600\/90 {
  border-top-color: rgb(5 150 105 / 0.9) !important;
  border-bottom-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-y-emerald-600\/95 {
  border-top-color: rgb(5 150 105 / 0.95) !important;
  border-bottom-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-y-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-700\/0 {
  border-top-color: rgb(4 120 87 / 0) !important;
  border-bottom-color: rgb(4 120 87 / 0) !important;
}

.tw-border-y-emerald-700\/10 {
  border-top-color: rgb(4 120 87 / 0.1) !important;
  border-bottom-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-y-emerald-700\/100 {
  border-top-color: rgb(4 120 87 / 1) !important;
  border-bottom-color: rgb(4 120 87 / 1) !important;
}

.tw-border-y-emerald-700\/15 {
  border-top-color: rgb(4 120 87 / 0.15) !important;
  border-bottom-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-y-emerald-700\/20 {
  border-top-color: rgb(4 120 87 / 0.2) !important;
  border-bottom-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-y-emerald-700\/25 {
  border-top-color: rgb(4 120 87 / 0.25) !important;
  border-bottom-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-y-emerald-700\/30 {
  border-top-color: rgb(4 120 87 / 0.3) !important;
  border-bottom-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-y-emerald-700\/35 {
  border-top-color: rgb(4 120 87 / 0.35) !important;
  border-bottom-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-y-emerald-700\/40 {
  border-top-color: rgb(4 120 87 / 0.4) !important;
  border-bottom-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-y-emerald-700\/45 {
  border-top-color: rgb(4 120 87 / 0.45) !important;
  border-bottom-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-y-emerald-700\/5 {
  border-top-color: rgb(4 120 87 / 0.05) !important;
  border-bottom-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-y-emerald-700\/50 {
  border-top-color: rgb(4 120 87 / 0.5) !important;
  border-bottom-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-y-emerald-700\/55 {
  border-top-color: rgb(4 120 87 / 0.55) !important;
  border-bottom-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-y-emerald-700\/60 {
  border-top-color: rgb(4 120 87 / 0.6) !important;
  border-bottom-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-y-emerald-700\/65 {
  border-top-color: rgb(4 120 87 / 0.65) !important;
  border-bottom-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-y-emerald-700\/70 {
  border-top-color: rgb(4 120 87 / 0.7) !important;
  border-bottom-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-y-emerald-700\/75 {
  border-top-color: rgb(4 120 87 / 0.75) !important;
  border-bottom-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-y-emerald-700\/80 {
  border-top-color: rgb(4 120 87 / 0.8) !important;
  border-bottom-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-y-emerald-700\/85 {
  border-top-color: rgb(4 120 87 / 0.85) !important;
  border-bottom-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-y-emerald-700\/90 {
  border-top-color: rgb(4 120 87 / 0.9) !important;
  border-bottom-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-y-emerald-700\/95 {
  border-top-color: rgb(4 120 87 / 0.95) !important;
  border-bottom-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-y-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-800\/0 {
  border-top-color: rgb(6 95 70 / 0) !important;
  border-bottom-color: rgb(6 95 70 / 0) !important;
}

.tw-border-y-emerald-800\/10 {
  border-top-color: rgb(6 95 70 / 0.1) !important;
  border-bottom-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-y-emerald-800\/100 {
  border-top-color: rgb(6 95 70 / 1) !important;
  border-bottom-color: rgb(6 95 70 / 1) !important;
}

.tw-border-y-emerald-800\/15 {
  border-top-color: rgb(6 95 70 / 0.15) !important;
  border-bottom-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-y-emerald-800\/20 {
  border-top-color: rgb(6 95 70 / 0.2) !important;
  border-bottom-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-y-emerald-800\/25 {
  border-top-color: rgb(6 95 70 / 0.25) !important;
  border-bottom-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-y-emerald-800\/30 {
  border-top-color: rgb(6 95 70 / 0.3) !important;
  border-bottom-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-y-emerald-800\/35 {
  border-top-color: rgb(6 95 70 / 0.35) !important;
  border-bottom-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-y-emerald-800\/40 {
  border-top-color: rgb(6 95 70 / 0.4) !important;
  border-bottom-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-y-emerald-800\/45 {
  border-top-color: rgb(6 95 70 / 0.45) !important;
  border-bottom-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-y-emerald-800\/5 {
  border-top-color: rgb(6 95 70 / 0.05) !important;
  border-bottom-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-y-emerald-800\/50 {
  border-top-color: rgb(6 95 70 / 0.5) !important;
  border-bottom-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-y-emerald-800\/55 {
  border-top-color: rgb(6 95 70 / 0.55) !important;
  border-bottom-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-y-emerald-800\/60 {
  border-top-color: rgb(6 95 70 / 0.6) !important;
  border-bottom-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-y-emerald-800\/65 {
  border-top-color: rgb(6 95 70 / 0.65) !important;
  border-bottom-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-y-emerald-800\/70 {
  border-top-color: rgb(6 95 70 / 0.7) !important;
  border-bottom-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-y-emerald-800\/75 {
  border-top-color: rgb(6 95 70 / 0.75) !important;
  border-bottom-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-y-emerald-800\/80 {
  border-top-color: rgb(6 95 70 / 0.8) !important;
  border-bottom-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-y-emerald-800\/85 {
  border-top-color: rgb(6 95 70 / 0.85) !important;
  border-bottom-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-y-emerald-800\/90 {
  border-top-color: rgb(6 95 70 / 0.9) !important;
  border-bottom-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-y-emerald-800\/95 {
  border-top-color: rgb(6 95 70 / 0.95) !important;
  border-bottom-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-y-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-900\/0 {
  border-top-color: rgb(6 78 59 / 0) !important;
  border-bottom-color: rgb(6 78 59 / 0) !important;
}

.tw-border-y-emerald-900\/10 {
  border-top-color: rgb(6 78 59 / 0.1) !important;
  border-bottom-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-y-emerald-900\/100 {
  border-top-color: rgb(6 78 59 / 1) !important;
  border-bottom-color: rgb(6 78 59 / 1) !important;
}

.tw-border-y-emerald-900\/15 {
  border-top-color: rgb(6 78 59 / 0.15) !important;
  border-bottom-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-y-emerald-900\/20 {
  border-top-color: rgb(6 78 59 / 0.2) !important;
  border-bottom-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-y-emerald-900\/25 {
  border-top-color: rgb(6 78 59 / 0.25) !important;
  border-bottom-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-y-emerald-900\/30 {
  border-top-color: rgb(6 78 59 / 0.3) !important;
  border-bottom-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-y-emerald-900\/35 {
  border-top-color: rgb(6 78 59 / 0.35) !important;
  border-bottom-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-y-emerald-900\/40 {
  border-top-color: rgb(6 78 59 / 0.4) !important;
  border-bottom-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-y-emerald-900\/45 {
  border-top-color: rgb(6 78 59 / 0.45) !important;
  border-bottom-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-y-emerald-900\/5 {
  border-top-color: rgb(6 78 59 / 0.05) !important;
  border-bottom-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-y-emerald-900\/50 {
  border-top-color: rgb(6 78 59 / 0.5) !important;
  border-bottom-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-y-emerald-900\/55 {
  border-top-color: rgb(6 78 59 / 0.55) !important;
  border-bottom-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-y-emerald-900\/60 {
  border-top-color: rgb(6 78 59 / 0.6) !important;
  border-bottom-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-y-emerald-900\/65 {
  border-top-color: rgb(6 78 59 / 0.65) !important;
  border-bottom-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-y-emerald-900\/70 {
  border-top-color: rgb(6 78 59 / 0.7) !important;
  border-bottom-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-y-emerald-900\/75 {
  border-top-color: rgb(6 78 59 / 0.75) !important;
  border-bottom-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-y-emerald-900\/80 {
  border-top-color: rgb(6 78 59 / 0.8) !important;
  border-bottom-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-y-emerald-900\/85 {
  border-top-color: rgb(6 78 59 / 0.85) !important;
  border-bottom-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-y-emerald-900\/90 {
  border-top-color: rgb(6 78 59 / 0.9) !important;
  border-bottom-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-y-emerald-900\/95 {
  border-top-color: rgb(6 78 59 / 0.95) !important;
  border-bottom-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-y-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-y-emerald-950\/0 {
  border-top-color: rgb(2 44 34 / 0) !important;
  border-bottom-color: rgb(2 44 34 / 0) !important;
}

.tw-border-y-emerald-950\/10 {
  border-top-color: rgb(2 44 34 / 0.1) !important;
  border-bottom-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-y-emerald-950\/100 {
  border-top-color: rgb(2 44 34 / 1) !important;
  border-bottom-color: rgb(2 44 34 / 1) !important;
}

.tw-border-y-emerald-950\/15 {
  border-top-color: rgb(2 44 34 / 0.15) !important;
  border-bottom-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-y-emerald-950\/20 {
  border-top-color: rgb(2 44 34 / 0.2) !important;
  border-bottom-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-y-emerald-950\/25 {
  border-top-color: rgb(2 44 34 / 0.25) !important;
  border-bottom-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-y-emerald-950\/30 {
  border-top-color: rgb(2 44 34 / 0.3) !important;
  border-bottom-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-y-emerald-950\/35 {
  border-top-color: rgb(2 44 34 / 0.35) !important;
  border-bottom-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-y-emerald-950\/40 {
  border-top-color: rgb(2 44 34 / 0.4) !important;
  border-bottom-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-y-emerald-950\/45 {
  border-top-color: rgb(2 44 34 / 0.45) !important;
  border-bottom-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-y-emerald-950\/5 {
  border-top-color: rgb(2 44 34 / 0.05) !important;
  border-bottom-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-y-emerald-950\/50 {
  border-top-color: rgb(2 44 34 / 0.5) !important;
  border-bottom-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-y-emerald-950\/55 {
  border-top-color: rgb(2 44 34 / 0.55) !important;
  border-bottom-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-y-emerald-950\/60 {
  border-top-color: rgb(2 44 34 / 0.6) !important;
  border-bottom-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-y-emerald-950\/65 {
  border-top-color: rgb(2 44 34 / 0.65) !important;
  border-bottom-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-y-emerald-950\/70 {
  border-top-color: rgb(2 44 34 / 0.7) !important;
  border-bottom-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-y-emerald-950\/75 {
  border-top-color: rgb(2 44 34 / 0.75) !important;
  border-bottom-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-y-emerald-950\/80 {
  border-top-color: rgb(2 44 34 / 0.8) !important;
  border-bottom-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-y-emerald-950\/85 {
  border-top-color: rgb(2 44 34 / 0.85) !important;
  border-bottom-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-y-emerald-950\/90 {
  border-top-color: rgb(2 44 34 / 0.9) !important;
  border-bottom-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-y-emerald-950\/95 {
  border-top-color: rgb(2 44 34 / 0.95) !important;
  border-bottom-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-y-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-100\/0 {
  border-top-color: rgb(250 232 255 / 0) !important;
  border-bottom-color: rgb(250 232 255 / 0) !important;
}

.tw-border-y-fuchsia-100\/10 {
  border-top-color: rgb(250 232 255 / 0.1) !important;
  border-bottom-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-y-fuchsia-100\/100 {
  border-top-color: rgb(250 232 255 / 1) !important;
  border-bottom-color: rgb(250 232 255 / 1) !important;
}

.tw-border-y-fuchsia-100\/15 {
  border-top-color: rgb(250 232 255 / 0.15) !important;
  border-bottom-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-y-fuchsia-100\/20 {
  border-top-color: rgb(250 232 255 / 0.2) !important;
  border-bottom-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-y-fuchsia-100\/25 {
  border-top-color: rgb(250 232 255 / 0.25) !important;
  border-bottom-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-y-fuchsia-100\/30 {
  border-top-color: rgb(250 232 255 / 0.3) !important;
  border-bottom-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-y-fuchsia-100\/35 {
  border-top-color: rgb(250 232 255 / 0.35) !important;
  border-bottom-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-y-fuchsia-100\/40 {
  border-top-color: rgb(250 232 255 / 0.4) !important;
  border-bottom-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-y-fuchsia-100\/45 {
  border-top-color: rgb(250 232 255 / 0.45) !important;
  border-bottom-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-y-fuchsia-100\/5 {
  border-top-color: rgb(250 232 255 / 0.05) !important;
  border-bottom-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-y-fuchsia-100\/50 {
  border-top-color: rgb(250 232 255 / 0.5) !important;
  border-bottom-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-y-fuchsia-100\/55 {
  border-top-color: rgb(250 232 255 / 0.55) !important;
  border-bottom-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-y-fuchsia-100\/60 {
  border-top-color: rgb(250 232 255 / 0.6) !important;
  border-bottom-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-y-fuchsia-100\/65 {
  border-top-color: rgb(250 232 255 / 0.65) !important;
  border-bottom-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-y-fuchsia-100\/70 {
  border-top-color: rgb(250 232 255 / 0.7) !important;
  border-bottom-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-y-fuchsia-100\/75 {
  border-top-color: rgb(250 232 255 / 0.75) !important;
  border-bottom-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-y-fuchsia-100\/80 {
  border-top-color: rgb(250 232 255 / 0.8) !important;
  border-bottom-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-y-fuchsia-100\/85 {
  border-top-color: rgb(250 232 255 / 0.85) !important;
  border-bottom-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-y-fuchsia-100\/90 {
  border-top-color: rgb(250 232 255 / 0.9) !important;
  border-bottom-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-y-fuchsia-100\/95 {
  border-top-color: rgb(250 232 255 / 0.95) !important;
  border-bottom-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-y-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-200\/0 {
  border-top-color: rgb(245 208 254 / 0) !important;
  border-bottom-color: rgb(245 208 254 / 0) !important;
}

.tw-border-y-fuchsia-200\/10 {
  border-top-color: rgb(245 208 254 / 0.1) !important;
  border-bottom-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-y-fuchsia-200\/100 {
  border-top-color: rgb(245 208 254 / 1) !important;
  border-bottom-color: rgb(245 208 254 / 1) !important;
}

.tw-border-y-fuchsia-200\/15 {
  border-top-color: rgb(245 208 254 / 0.15) !important;
  border-bottom-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-y-fuchsia-200\/20 {
  border-top-color: rgb(245 208 254 / 0.2) !important;
  border-bottom-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-y-fuchsia-200\/25 {
  border-top-color: rgb(245 208 254 / 0.25) !important;
  border-bottom-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-y-fuchsia-200\/30 {
  border-top-color: rgb(245 208 254 / 0.3) !important;
  border-bottom-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-y-fuchsia-200\/35 {
  border-top-color: rgb(245 208 254 / 0.35) !important;
  border-bottom-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-y-fuchsia-200\/40 {
  border-top-color: rgb(245 208 254 / 0.4) !important;
  border-bottom-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-y-fuchsia-200\/45 {
  border-top-color: rgb(245 208 254 / 0.45) !important;
  border-bottom-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-y-fuchsia-200\/5 {
  border-top-color: rgb(245 208 254 / 0.05) !important;
  border-bottom-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-y-fuchsia-200\/50 {
  border-top-color: rgb(245 208 254 / 0.5) !important;
  border-bottom-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-y-fuchsia-200\/55 {
  border-top-color: rgb(245 208 254 / 0.55) !important;
  border-bottom-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-y-fuchsia-200\/60 {
  border-top-color: rgb(245 208 254 / 0.6) !important;
  border-bottom-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-y-fuchsia-200\/65 {
  border-top-color: rgb(245 208 254 / 0.65) !important;
  border-bottom-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-y-fuchsia-200\/70 {
  border-top-color: rgb(245 208 254 / 0.7) !important;
  border-bottom-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-y-fuchsia-200\/75 {
  border-top-color: rgb(245 208 254 / 0.75) !important;
  border-bottom-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-y-fuchsia-200\/80 {
  border-top-color: rgb(245 208 254 / 0.8) !important;
  border-bottom-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-y-fuchsia-200\/85 {
  border-top-color: rgb(245 208 254 / 0.85) !important;
  border-bottom-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-y-fuchsia-200\/90 {
  border-top-color: rgb(245 208 254 / 0.9) !important;
  border-bottom-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-y-fuchsia-200\/95 {
  border-top-color: rgb(245 208 254 / 0.95) !important;
  border-bottom-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-y-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-300\/0 {
  border-top-color: rgb(240 171 252 / 0) !important;
  border-bottom-color: rgb(240 171 252 / 0) !important;
}

.tw-border-y-fuchsia-300\/10 {
  border-top-color: rgb(240 171 252 / 0.1) !important;
  border-bottom-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-y-fuchsia-300\/100 {
  border-top-color: rgb(240 171 252 / 1) !important;
  border-bottom-color: rgb(240 171 252 / 1) !important;
}

.tw-border-y-fuchsia-300\/15 {
  border-top-color: rgb(240 171 252 / 0.15) !important;
  border-bottom-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-y-fuchsia-300\/20 {
  border-top-color: rgb(240 171 252 / 0.2) !important;
  border-bottom-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-y-fuchsia-300\/25 {
  border-top-color: rgb(240 171 252 / 0.25) !important;
  border-bottom-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-y-fuchsia-300\/30 {
  border-top-color: rgb(240 171 252 / 0.3) !important;
  border-bottom-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-y-fuchsia-300\/35 {
  border-top-color: rgb(240 171 252 / 0.35) !important;
  border-bottom-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-y-fuchsia-300\/40 {
  border-top-color: rgb(240 171 252 / 0.4) !important;
  border-bottom-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-y-fuchsia-300\/45 {
  border-top-color: rgb(240 171 252 / 0.45) !important;
  border-bottom-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-y-fuchsia-300\/5 {
  border-top-color: rgb(240 171 252 / 0.05) !important;
  border-bottom-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-y-fuchsia-300\/50 {
  border-top-color: rgb(240 171 252 / 0.5) !important;
  border-bottom-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-y-fuchsia-300\/55 {
  border-top-color: rgb(240 171 252 / 0.55) !important;
  border-bottom-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-y-fuchsia-300\/60 {
  border-top-color: rgb(240 171 252 / 0.6) !important;
  border-bottom-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-y-fuchsia-300\/65 {
  border-top-color: rgb(240 171 252 / 0.65) !important;
  border-bottom-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-y-fuchsia-300\/70 {
  border-top-color: rgb(240 171 252 / 0.7) !important;
  border-bottom-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-y-fuchsia-300\/75 {
  border-top-color: rgb(240 171 252 / 0.75) !important;
  border-bottom-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-y-fuchsia-300\/80 {
  border-top-color: rgb(240 171 252 / 0.8) !important;
  border-bottom-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-y-fuchsia-300\/85 {
  border-top-color: rgb(240 171 252 / 0.85) !important;
  border-bottom-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-y-fuchsia-300\/90 {
  border-top-color: rgb(240 171 252 / 0.9) !important;
  border-bottom-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-y-fuchsia-300\/95 {
  border-top-color: rgb(240 171 252 / 0.95) !important;
  border-bottom-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-y-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-400\/0 {
  border-top-color: rgb(232 121 249 / 0) !important;
  border-bottom-color: rgb(232 121 249 / 0) !important;
}

.tw-border-y-fuchsia-400\/10 {
  border-top-color: rgb(232 121 249 / 0.1) !important;
  border-bottom-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-y-fuchsia-400\/100 {
  border-top-color: rgb(232 121 249 / 1) !important;
  border-bottom-color: rgb(232 121 249 / 1) !important;
}

.tw-border-y-fuchsia-400\/15 {
  border-top-color: rgb(232 121 249 / 0.15) !important;
  border-bottom-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-y-fuchsia-400\/20 {
  border-top-color: rgb(232 121 249 / 0.2) !important;
  border-bottom-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-y-fuchsia-400\/25 {
  border-top-color: rgb(232 121 249 / 0.25) !important;
  border-bottom-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-y-fuchsia-400\/30 {
  border-top-color: rgb(232 121 249 / 0.3) !important;
  border-bottom-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-y-fuchsia-400\/35 {
  border-top-color: rgb(232 121 249 / 0.35) !important;
  border-bottom-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-y-fuchsia-400\/40 {
  border-top-color: rgb(232 121 249 / 0.4) !important;
  border-bottom-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-y-fuchsia-400\/45 {
  border-top-color: rgb(232 121 249 / 0.45) !important;
  border-bottom-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-y-fuchsia-400\/5 {
  border-top-color: rgb(232 121 249 / 0.05) !important;
  border-bottom-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-y-fuchsia-400\/50 {
  border-top-color: rgb(232 121 249 / 0.5) !important;
  border-bottom-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-y-fuchsia-400\/55 {
  border-top-color: rgb(232 121 249 / 0.55) !important;
  border-bottom-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-y-fuchsia-400\/60 {
  border-top-color: rgb(232 121 249 / 0.6) !important;
  border-bottom-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-y-fuchsia-400\/65 {
  border-top-color: rgb(232 121 249 / 0.65) !important;
  border-bottom-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-y-fuchsia-400\/70 {
  border-top-color: rgb(232 121 249 / 0.7) !important;
  border-bottom-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-y-fuchsia-400\/75 {
  border-top-color: rgb(232 121 249 / 0.75) !important;
  border-bottom-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-y-fuchsia-400\/80 {
  border-top-color: rgb(232 121 249 / 0.8) !important;
  border-bottom-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-y-fuchsia-400\/85 {
  border-top-color: rgb(232 121 249 / 0.85) !important;
  border-bottom-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-y-fuchsia-400\/90 {
  border-top-color: rgb(232 121 249 / 0.9) !important;
  border-bottom-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-y-fuchsia-400\/95 {
  border-top-color: rgb(232 121 249 / 0.95) !important;
  border-bottom-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-y-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-50\/0 {
  border-top-color: rgb(253 244 255 / 0) !important;
  border-bottom-color: rgb(253 244 255 / 0) !important;
}

.tw-border-y-fuchsia-50\/10 {
  border-top-color: rgb(253 244 255 / 0.1) !important;
  border-bottom-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-y-fuchsia-50\/100 {
  border-top-color: rgb(253 244 255 / 1) !important;
  border-bottom-color: rgb(253 244 255 / 1) !important;
}

.tw-border-y-fuchsia-50\/15 {
  border-top-color: rgb(253 244 255 / 0.15) !important;
  border-bottom-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-y-fuchsia-50\/20 {
  border-top-color: rgb(253 244 255 / 0.2) !important;
  border-bottom-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-y-fuchsia-50\/25 {
  border-top-color: rgb(253 244 255 / 0.25) !important;
  border-bottom-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-y-fuchsia-50\/30 {
  border-top-color: rgb(253 244 255 / 0.3) !important;
  border-bottom-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-y-fuchsia-50\/35 {
  border-top-color: rgb(253 244 255 / 0.35) !important;
  border-bottom-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-y-fuchsia-50\/40 {
  border-top-color: rgb(253 244 255 / 0.4) !important;
  border-bottom-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-y-fuchsia-50\/45 {
  border-top-color: rgb(253 244 255 / 0.45) !important;
  border-bottom-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-y-fuchsia-50\/5 {
  border-top-color: rgb(253 244 255 / 0.05) !important;
  border-bottom-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-y-fuchsia-50\/50 {
  border-top-color: rgb(253 244 255 / 0.5) !important;
  border-bottom-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-y-fuchsia-50\/55 {
  border-top-color: rgb(253 244 255 / 0.55) !important;
  border-bottom-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-y-fuchsia-50\/60 {
  border-top-color: rgb(253 244 255 / 0.6) !important;
  border-bottom-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-y-fuchsia-50\/65 {
  border-top-color: rgb(253 244 255 / 0.65) !important;
  border-bottom-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-y-fuchsia-50\/70 {
  border-top-color: rgb(253 244 255 / 0.7) !important;
  border-bottom-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-y-fuchsia-50\/75 {
  border-top-color: rgb(253 244 255 / 0.75) !important;
  border-bottom-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-y-fuchsia-50\/80 {
  border-top-color: rgb(253 244 255 / 0.8) !important;
  border-bottom-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-y-fuchsia-50\/85 {
  border-top-color: rgb(253 244 255 / 0.85) !important;
  border-bottom-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-y-fuchsia-50\/90 {
  border-top-color: rgb(253 244 255 / 0.9) !important;
  border-bottom-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-y-fuchsia-50\/95 {
  border-top-color: rgb(253 244 255 / 0.95) !important;
  border-bottom-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-y-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-500\/0 {
  border-top-color: rgb(217 70 239 / 0) !important;
  border-bottom-color: rgb(217 70 239 / 0) !important;
}

.tw-border-y-fuchsia-500\/10 {
  border-top-color: rgb(217 70 239 / 0.1) !important;
  border-bottom-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-y-fuchsia-500\/100 {
  border-top-color: rgb(217 70 239 / 1) !important;
  border-bottom-color: rgb(217 70 239 / 1) !important;
}

.tw-border-y-fuchsia-500\/15 {
  border-top-color: rgb(217 70 239 / 0.15) !important;
  border-bottom-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-y-fuchsia-500\/20 {
  border-top-color: rgb(217 70 239 / 0.2) !important;
  border-bottom-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-y-fuchsia-500\/25 {
  border-top-color: rgb(217 70 239 / 0.25) !important;
  border-bottom-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-y-fuchsia-500\/30 {
  border-top-color: rgb(217 70 239 / 0.3) !important;
  border-bottom-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-y-fuchsia-500\/35 {
  border-top-color: rgb(217 70 239 / 0.35) !important;
  border-bottom-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-y-fuchsia-500\/40 {
  border-top-color: rgb(217 70 239 / 0.4) !important;
  border-bottom-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-y-fuchsia-500\/45 {
  border-top-color: rgb(217 70 239 / 0.45) !important;
  border-bottom-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-y-fuchsia-500\/5 {
  border-top-color: rgb(217 70 239 / 0.05) !important;
  border-bottom-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-y-fuchsia-500\/50 {
  border-top-color: rgb(217 70 239 / 0.5) !important;
  border-bottom-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-y-fuchsia-500\/55 {
  border-top-color: rgb(217 70 239 / 0.55) !important;
  border-bottom-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-y-fuchsia-500\/60 {
  border-top-color: rgb(217 70 239 / 0.6) !important;
  border-bottom-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-y-fuchsia-500\/65 {
  border-top-color: rgb(217 70 239 / 0.65) !important;
  border-bottom-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-y-fuchsia-500\/70 {
  border-top-color: rgb(217 70 239 / 0.7) !important;
  border-bottom-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-y-fuchsia-500\/75 {
  border-top-color: rgb(217 70 239 / 0.75) !important;
  border-bottom-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-y-fuchsia-500\/80 {
  border-top-color: rgb(217 70 239 / 0.8) !important;
  border-bottom-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-y-fuchsia-500\/85 {
  border-top-color: rgb(217 70 239 / 0.85) !important;
  border-bottom-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-y-fuchsia-500\/90 {
  border-top-color: rgb(217 70 239 / 0.9) !important;
  border-bottom-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-y-fuchsia-500\/95 {
  border-top-color: rgb(217 70 239 / 0.95) !important;
  border-bottom-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-y-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-600\/0 {
  border-top-color: rgb(192 38 211 / 0) !important;
  border-bottom-color: rgb(192 38 211 / 0) !important;
}

.tw-border-y-fuchsia-600\/10 {
  border-top-color: rgb(192 38 211 / 0.1) !important;
  border-bottom-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-y-fuchsia-600\/100 {
  border-top-color: rgb(192 38 211 / 1) !important;
  border-bottom-color: rgb(192 38 211 / 1) !important;
}

.tw-border-y-fuchsia-600\/15 {
  border-top-color: rgb(192 38 211 / 0.15) !important;
  border-bottom-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-y-fuchsia-600\/20 {
  border-top-color: rgb(192 38 211 / 0.2) !important;
  border-bottom-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-y-fuchsia-600\/25 {
  border-top-color: rgb(192 38 211 / 0.25) !important;
  border-bottom-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-y-fuchsia-600\/30 {
  border-top-color: rgb(192 38 211 / 0.3) !important;
  border-bottom-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-y-fuchsia-600\/35 {
  border-top-color: rgb(192 38 211 / 0.35) !important;
  border-bottom-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-y-fuchsia-600\/40 {
  border-top-color: rgb(192 38 211 / 0.4) !important;
  border-bottom-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-y-fuchsia-600\/45 {
  border-top-color: rgb(192 38 211 / 0.45) !important;
  border-bottom-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-y-fuchsia-600\/5 {
  border-top-color: rgb(192 38 211 / 0.05) !important;
  border-bottom-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-y-fuchsia-600\/50 {
  border-top-color: rgb(192 38 211 / 0.5) !important;
  border-bottom-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-y-fuchsia-600\/55 {
  border-top-color: rgb(192 38 211 / 0.55) !important;
  border-bottom-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-y-fuchsia-600\/60 {
  border-top-color: rgb(192 38 211 / 0.6) !important;
  border-bottom-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-y-fuchsia-600\/65 {
  border-top-color: rgb(192 38 211 / 0.65) !important;
  border-bottom-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-y-fuchsia-600\/70 {
  border-top-color: rgb(192 38 211 / 0.7) !important;
  border-bottom-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-y-fuchsia-600\/75 {
  border-top-color: rgb(192 38 211 / 0.75) !important;
  border-bottom-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-y-fuchsia-600\/80 {
  border-top-color: rgb(192 38 211 / 0.8) !important;
  border-bottom-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-y-fuchsia-600\/85 {
  border-top-color: rgb(192 38 211 / 0.85) !important;
  border-bottom-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-y-fuchsia-600\/90 {
  border-top-color: rgb(192 38 211 / 0.9) !important;
  border-bottom-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-y-fuchsia-600\/95 {
  border-top-color: rgb(192 38 211 / 0.95) !important;
  border-bottom-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-y-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-700\/0 {
  border-top-color: rgb(162 28 175 / 0) !important;
  border-bottom-color: rgb(162 28 175 / 0) !important;
}

.tw-border-y-fuchsia-700\/10 {
  border-top-color: rgb(162 28 175 / 0.1) !important;
  border-bottom-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-y-fuchsia-700\/100 {
  border-top-color: rgb(162 28 175 / 1) !important;
  border-bottom-color: rgb(162 28 175 / 1) !important;
}

.tw-border-y-fuchsia-700\/15 {
  border-top-color: rgb(162 28 175 / 0.15) !important;
  border-bottom-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-y-fuchsia-700\/20 {
  border-top-color: rgb(162 28 175 / 0.2) !important;
  border-bottom-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-y-fuchsia-700\/25 {
  border-top-color: rgb(162 28 175 / 0.25) !important;
  border-bottom-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-y-fuchsia-700\/30 {
  border-top-color: rgb(162 28 175 / 0.3) !important;
  border-bottom-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-y-fuchsia-700\/35 {
  border-top-color: rgb(162 28 175 / 0.35) !important;
  border-bottom-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-y-fuchsia-700\/40 {
  border-top-color: rgb(162 28 175 / 0.4) !important;
  border-bottom-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-y-fuchsia-700\/45 {
  border-top-color: rgb(162 28 175 / 0.45) !important;
  border-bottom-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-y-fuchsia-700\/5 {
  border-top-color: rgb(162 28 175 / 0.05) !important;
  border-bottom-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-y-fuchsia-700\/50 {
  border-top-color: rgb(162 28 175 / 0.5) !important;
  border-bottom-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-y-fuchsia-700\/55 {
  border-top-color: rgb(162 28 175 / 0.55) !important;
  border-bottom-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-y-fuchsia-700\/60 {
  border-top-color: rgb(162 28 175 / 0.6) !important;
  border-bottom-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-y-fuchsia-700\/65 {
  border-top-color: rgb(162 28 175 / 0.65) !important;
  border-bottom-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-y-fuchsia-700\/70 {
  border-top-color: rgb(162 28 175 / 0.7) !important;
  border-bottom-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-y-fuchsia-700\/75 {
  border-top-color: rgb(162 28 175 / 0.75) !important;
  border-bottom-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-y-fuchsia-700\/80 {
  border-top-color: rgb(162 28 175 / 0.8) !important;
  border-bottom-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-y-fuchsia-700\/85 {
  border-top-color: rgb(162 28 175 / 0.85) !important;
  border-bottom-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-y-fuchsia-700\/90 {
  border-top-color: rgb(162 28 175 / 0.9) !important;
  border-bottom-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-y-fuchsia-700\/95 {
  border-top-color: rgb(162 28 175 / 0.95) !important;
  border-bottom-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-y-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-800\/0 {
  border-top-color: rgb(134 25 143 / 0) !important;
  border-bottom-color: rgb(134 25 143 / 0) !important;
}

.tw-border-y-fuchsia-800\/10 {
  border-top-color: rgb(134 25 143 / 0.1) !important;
  border-bottom-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-y-fuchsia-800\/100 {
  border-top-color: rgb(134 25 143 / 1) !important;
  border-bottom-color: rgb(134 25 143 / 1) !important;
}

.tw-border-y-fuchsia-800\/15 {
  border-top-color: rgb(134 25 143 / 0.15) !important;
  border-bottom-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-y-fuchsia-800\/20 {
  border-top-color: rgb(134 25 143 / 0.2) !important;
  border-bottom-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-y-fuchsia-800\/25 {
  border-top-color: rgb(134 25 143 / 0.25) !important;
  border-bottom-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-y-fuchsia-800\/30 {
  border-top-color: rgb(134 25 143 / 0.3) !important;
  border-bottom-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-y-fuchsia-800\/35 {
  border-top-color: rgb(134 25 143 / 0.35) !important;
  border-bottom-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-y-fuchsia-800\/40 {
  border-top-color: rgb(134 25 143 / 0.4) !important;
  border-bottom-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-y-fuchsia-800\/45 {
  border-top-color: rgb(134 25 143 / 0.45) !important;
  border-bottom-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-y-fuchsia-800\/5 {
  border-top-color: rgb(134 25 143 / 0.05) !important;
  border-bottom-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-y-fuchsia-800\/50 {
  border-top-color: rgb(134 25 143 / 0.5) !important;
  border-bottom-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-y-fuchsia-800\/55 {
  border-top-color: rgb(134 25 143 / 0.55) !important;
  border-bottom-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-y-fuchsia-800\/60 {
  border-top-color: rgb(134 25 143 / 0.6) !important;
  border-bottom-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-y-fuchsia-800\/65 {
  border-top-color: rgb(134 25 143 / 0.65) !important;
  border-bottom-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-y-fuchsia-800\/70 {
  border-top-color: rgb(134 25 143 / 0.7) !important;
  border-bottom-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-y-fuchsia-800\/75 {
  border-top-color: rgb(134 25 143 / 0.75) !important;
  border-bottom-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-y-fuchsia-800\/80 {
  border-top-color: rgb(134 25 143 / 0.8) !important;
  border-bottom-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-y-fuchsia-800\/85 {
  border-top-color: rgb(134 25 143 / 0.85) !important;
  border-bottom-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-y-fuchsia-800\/90 {
  border-top-color: rgb(134 25 143 / 0.9) !important;
  border-bottom-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-y-fuchsia-800\/95 {
  border-top-color: rgb(134 25 143 / 0.95) !important;
  border-bottom-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-y-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-900\/0 {
  border-top-color: rgb(112 26 117 / 0) !important;
  border-bottom-color: rgb(112 26 117 / 0) !important;
}

.tw-border-y-fuchsia-900\/10 {
  border-top-color: rgb(112 26 117 / 0.1) !important;
  border-bottom-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-y-fuchsia-900\/100 {
  border-top-color: rgb(112 26 117 / 1) !important;
  border-bottom-color: rgb(112 26 117 / 1) !important;
}

.tw-border-y-fuchsia-900\/15 {
  border-top-color: rgb(112 26 117 / 0.15) !important;
  border-bottom-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-y-fuchsia-900\/20 {
  border-top-color: rgb(112 26 117 / 0.2) !important;
  border-bottom-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-y-fuchsia-900\/25 {
  border-top-color: rgb(112 26 117 / 0.25) !important;
  border-bottom-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-y-fuchsia-900\/30 {
  border-top-color: rgb(112 26 117 / 0.3) !important;
  border-bottom-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-y-fuchsia-900\/35 {
  border-top-color: rgb(112 26 117 / 0.35) !important;
  border-bottom-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-y-fuchsia-900\/40 {
  border-top-color: rgb(112 26 117 / 0.4) !important;
  border-bottom-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-y-fuchsia-900\/45 {
  border-top-color: rgb(112 26 117 / 0.45) !important;
  border-bottom-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-y-fuchsia-900\/5 {
  border-top-color: rgb(112 26 117 / 0.05) !important;
  border-bottom-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-y-fuchsia-900\/50 {
  border-top-color: rgb(112 26 117 / 0.5) !important;
  border-bottom-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-y-fuchsia-900\/55 {
  border-top-color: rgb(112 26 117 / 0.55) !important;
  border-bottom-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-y-fuchsia-900\/60 {
  border-top-color: rgb(112 26 117 / 0.6) !important;
  border-bottom-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-y-fuchsia-900\/65 {
  border-top-color: rgb(112 26 117 / 0.65) !important;
  border-bottom-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-y-fuchsia-900\/70 {
  border-top-color: rgb(112 26 117 / 0.7) !important;
  border-bottom-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-y-fuchsia-900\/75 {
  border-top-color: rgb(112 26 117 / 0.75) !important;
  border-bottom-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-y-fuchsia-900\/80 {
  border-top-color: rgb(112 26 117 / 0.8) !important;
  border-bottom-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-y-fuchsia-900\/85 {
  border-top-color: rgb(112 26 117 / 0.85) !important;
  border-bottom-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-y-fuchsia-900\/90 {
  border-top-color: rgb(112 26 117 / 0.9) !important;
  border-bottom-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-y-fuchsia-900\/95 {
  border-top-color: rgb(112 26 117 / 0.95) !important;
  border-bottom-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-y-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-y-fuchsia-950\/0 {
  border-top-color: rgb(74 4 78 / 0) !important;
  border-bottom-color: rgb(74 4 78 / 0) !important;
}

.tw-border-y-fuchsia-950\/10 {
  border-top-color: rgb(74 4 78 / 0.1) !important;
  border-bottom-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-y-fuchsia-950\/100 {
  border-top-color: rgb(74 4 78 / 1) !important;
  border-bottom-color: rgb(74 4 78 / 1) !important;
}

.tw-border-y-fuchsia-950\/15 {
  border-top-color: rgb(74 4 78 / 0.15) !important;
  border-bottom-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-y-fuchsia-950\/20 {
  border-top-color: rgb(74 4 78 / 0.2) !important;
  border-bottom-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-y-fuchsia-950\/25 {
  border-top-color: rgb(74 4 78 / 0.25) !important;
  border-bottom-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-y-fuchsia-950\/30 {
  border-top-color: rgb(74 4 78 / 0.3) !important;
  border-bottom-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-y-fuchsia-950\/35 {
  border-top-color: rgb(74 4 78 / 0.35) !important;
  border-bottom-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-y-fuchsia-950\/40 {
  border-top-color: rgb(74 4 78 / 0.4) !important;
  border-bottom-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-y-fuchsia-950\/45 {
  border-top-color: rgb(74 4 78 / 0.45) !important;
  border-bottom-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-y-fuchsia-950\/5 {
  border-top-color: rgb(74 4 78 / 0.05) !important;
  border-bottom-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-y-fuchsia-950\/50 {
  border-top-color: rgb(74 4 78 / 0.5) !important;
  border-bottom-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-y-fuchsia-950\/55 {
  border-top-color: rgb(74 4 78 / 0.55) !important;
  border-bottom-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-y-fuchsia-950\/60 {
  border-top-color: rgb(74 4 78 / 0.6) !important;
  border-bottom-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-y-fuchsia-950\/65 {
  border-top-color: rgb(74 4 78 / 0.65) !important;
  border-bottom-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-y-fuchsia-950\/70 {
  border-top-color: rgb(74 4 78 / 0.7) !important;
  border-bottom-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-y-fuchsia-950\/75 {
  border-top-color: rgb(74 4 78 / 0.75) !important;
  border-bottom-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-y-fuchsia-950\/80 {
  border-top-color: rgb(74 4 78 / 0.8) !important;
  border-bottom-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-y-fuchsia-950\/85 {
  border-top-color: rgb(74 4 78 / 0.85) !important;
  border-bottom-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-y-fuchsia-950\/90 {
  border-top-color: rgb(74 4 78 / 0.9) !important;
  border-bottom-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-y-fuchsia-950\/95 {
  border-top-color: rgb(74 4 78 / 0.95) !important;
  border-bottom-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-y-gray-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-100\/0 {
  border-top-color: rgb(243 244 246 / 0) !important;
  border-bottom-color: rgb(243 244 246 / 0) !important;
}

.tw-border-y-gray-100\/10 {
  border-top-color: rgb(243 244 246 / 0.1) !important;
  border-bottom-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-y-gray-100\/100 {
  border-top-color: rgb(243 244 246 / 1) !important;
  border-bottom-color: rgb(243 244 246 / 1) !important;
}

.tw-border-y-gray-100\/15 {
  border-top-color: rgb(243 244 246 / 0.15) !important;
  border-bottom-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-y-gray-100\/20 {
  border-top-color: rgb(243 244 246 / 0.2) !important;
  border-bottom-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-y-gray-100\/25 {
  border-top-color: rgb(243 244 246 / 0.25) !important;
  border-bottom-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-y-gray-100\/30 {
  border-top-color: rgb(243 244 246 / 0.3) !important;
  border-bottom-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-y-gray-100\/35 {
  border-top-color: rgb(243 244 246 / 0.35) !important;
  border-bottom-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-y-gray-100\/40 {
  border-top-color: rgb(243 244 246 / 0.4) !important;
  border-bottom-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-y-gray-100\/45 {
  border-top-color: rgb(243 244 246 / 0.45) !important;
  border-bottom-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-y-gray-100\/5 {
  border-top-color: rgb(243 244 246 / 0.05) !important;
  border-bottom-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-y-gray-100\/50 {
  border-top-color: rgb(243 244 246 / 0.5) !important;
  border-bottom-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-y-gray-100\/55 {
  border-top-color: rgb(243 244 246 / 0.55) !important;
  border-bottom-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-y-gray-100\/60 {
  border-top-color: rgb(243 244 246 / 0.6) !important;
  border-bottom-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-y-gray-100\/65 {
  border-top-color: rgb(243 244 246 / 0.65) !important;
  border-bottom-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-y-gray-100\/70 {
  border-top-color: rgb(243 244 246 / 0.7) !important;
  border-bottom-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-y-gray-100\/75 {
  border-top-color: rgb(243 244 246 / 0.75) !important;
  border-bottom-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-y-gray-100\/80 {
  border-top-color: rgb(243 244 246 / 0.8) !important;
  border-bottom-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-y-gray-100\/85 {
  border-top-color: rgb(243 244 246 / 0.85) !important;
  border-bottom-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-y-gray-100\/90 {
  border-top-color: rgb(243 244 246 / 0.9) !important;
  border-bottom-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-y-gray-100\/95 {
  border-top-color: rgb(243 244 246 / 0.95) !important;
  border-bottom-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-y-gray-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-200\/0 {
  border-top-color: rgb(229 231 235 / 0) !important;
  border-bottom-color: rgb(229 231 235 / 0) !important;
}

.tw-border-y-gray-200\/10 {
  border-top-color: rgb(229 231 235 / 0.1) !important;
  border-bottom-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-y-gray-200\/100 {
  border-top-color: rgb(229 231 235 / 1) !important;
  border-bottom-color: rgb(229 231 235 / 1) !important;
}

.tw-border-y-gray-200\/15 {
  border-top-color: rgb(229 231 235 / 0.15) !important;
  border-bottom-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-y-gray-200\/20 {
  border-top-color: rgb(229 231 235 / 0.2) !important;
  border-bottom-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-y-gray-200\/25 {
  border-top-color: rgb(229 231 235 / 0.25) !important;
  border-bottom-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-y-gray-200\/30 {
  border-top-color: rgb(229 231 235 / 0.3) !important;
  border-bottom-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-y-gray-200\/35 {
  border-top-color: rgb(229 231 235 / 0.35) !important;
  border-bottom-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-y-gray-200\/40 {
  border-top-color: rgb(229 231 235 / 0.4) !important;
  border-bottom-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-y-gray-200\/45 {
  border-top-color: rgb(229 231 235 / 0.45) !important;
  border-bottom-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-y-gray-200\/5 {
  border-top-color: rgb(229 231 235 / 0.05) !important;
  border-bottom-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-y-gray-200\/50 {
  border-top-color: rgb(229 231 235 / 0.5) !important;
  border-bottom-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-y-gray-200\/55 {
  border-top-color: rgb(229 231 235 / 0.55) !important;
  border-bottom-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-y-gray-200\/60 {
  border-top-color: rgb(229 231 235 / 0.6) !important;
  border-bottom-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-y-gray-200\/65 {
  border-top-color: rgb(229 231 235 / 0.65) !important;
  border-bottom-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-y-gray-200\/70 {
  border-top-color: rgb(229 231 235 / 0.7) !important;
  border-bottom-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-y-gray-200\/75 {
  border-top-color: rgb(229 231 235 / 0.75) !important;
  border-bottom-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-y-gray-200\/80 {
  border-top-color: rgb(229 231 235 / 0.8) !important;
  border-bottom-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-y-gray-200\/85 {
  border-top-color: rgb(229 231 235 / 0.85) !important;
  border-bottom-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-y-gray-200\/90 {
  border-top-color: rgb(229 231 235 / 0.9) !important;
  border-bottom-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-y-gray-200\/95 {
  border-top-color: rgb(229 231 235 / 0.95) !important;
  border-bottom-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-y-gray-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-300\/0 {
  border-top-color: rgb(209 213 219 / 0) !important;
  border-bottom-color: rgb(209 213 219 / 0) !important;
}

.tw-border-y-gray-300\/10 {
  border-top-color: rgb(209 213 219 / 0.1) !important;
  border-bottom-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-y-gray-300\/100 {
  border-top-color: rgb(209 213 219 / 1) !important;
  border-bottom-color: rgb(209 213 219 / 1) !important;
}

.tw-border-y-gray-300\/15 {
  border-top-color: rgb(209 213 219 / 0.15) !important;
  border-bottom-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-y-gray-300\/20 {
  border-top-color: rgb(209 213 219 / 0.2) !important;
  border-bottom-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-y-gray-300\/25 {
  border-top-color: rgb(209 213 219 / 0.25) !important;
  border-bottom-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-y-gray-300\/30 {
  border-top-color: rgb(209 213 219 / 0.3) !important;
  border-bottom-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-y-gray-300\/35 {
  border-top-color: rgb(209 213 219 / 0.35) !important;
  border-bottom-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-y-gray-300\/40 {
  border-top-color: rgb(209 213 219 / 0.4) !important;
  border-bottom-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-y-gray-300\/45 {
  border-top-color: rgb(209 213 219 / 0.45) !important;
  border-bottom-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-y-gray-300\/5 {
  border-top-color: rgb(209 213 219 / 0.05) !important;
  border-bottom-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-y-gray-300\/50 {
  border-top-color: rgb(209 213 219 / 0.5) !important;
  border-bottom-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-y-gray-300\/55 {
  border-top-color: rgb(209 213 219 / 0.55) !important;
  border-bottom-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-y-gray-300\/60 {
  border-top-color: rgb(209 213 219 / 0.6) !important;
  border-bottom-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-y-gray-300\/65 {
  border-top-color: rgb(209 213 219 / 0.65) !important;
  border-bottom-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-y-gray-300\/70 {
  border-top-color: rgb(209 213 219 / 0.7) !important;
  border-bottom-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-y-gray-300\/75 {
  border-top-color: rgb(209 213 219 / 0.75) !important;
  border-bottom-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-y-gray-300\/80 {
  border-top-color: rgb(209 213 219 / 0.8) !important;
  border-bottom-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-y-gray-300\/85 {
  border-top-color: rgb(209 213 219 / 0.85) !important;
  border-bottom-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-y-gray-300\/90 {
  border-top-color: rgb(209 213 219 / 0.9) !important;
  border-bottom-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-y-gray-300\/95 {
  border-top-color: rgb(209 213 219 / 0.95) !important;
  border-bottom-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-y-gray-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-400\/0 {
  border-top-color: rgb(156 163 175 / 0) !important;
  border-bottom-color: rgb(156 163 175 / 0) !important;
}

.tw-border-y-gray-400\/10 {
  border-top-color: rgb(156 163 175 / 0.1) !important;
  border-bottom-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-y-gray-400\/100 {
  border-top-color: rgb(156 163 175 / 1) !important;
  border-bottom-color: rgb(156 163 175 / 1) !important;
}

.tw-border-y-gray-400\/15 {
  border-top-color: rgb(156 163 175 / 0.15) !important;
  border-bottom-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-y-gray-400\/20 {
  border-top-color: rgb(156 163 175 / 0.2) !important;
  border-bottom-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-y-gray-400\/25 {
  border-top-color: rgb(156 163 175 / 0.25) !important;
  border-bottom-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-y-gray-400\/30 {
  border-top-color: rgb(156 163 175 / 0.3) !important;
  border-bottom-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-y-gray-400\/35 {
  border-top-color: rgb(156 163 175 / 0.35) !important;
  border-bottom-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-y-gray-400\/40 {
  border-top-color: rgb(156 163 175 / 0.4) !important;
  border-bottom-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-y-gray-400\/45 {
  border-top-color: rgb(156 163 175 / 0.45) !important;
  border-bottom-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-y-gray-400\/5 {
  border-top-color: rgb(156 163 175 / 0.05) !important;
  border-bottom-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-y-gray-400\/50 {
  border-top-color: rgb(156 163 175 / 0.5) !important;
  border-bottom-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-y-gray-400\/55 {
  border-top-color: rgb(156 163 175 / 0.55) !important;
  border-bottom-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-y-gray-400\/60 {
  border-top-color: rgb(156 163 175 / 0.6) !important;
  border-bottom-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-y-gray-400\/65 {
  border-top-color: rgb(156 163 175 / 0.65) !important;
  border-bottom-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-y-gray-400\/70 {
  border-top-color: rgb(156 163 175 / 0.7) !important;
  border-bottom-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-y-gray-400\/75 {
  border-top-color: rgb(156 163 175 / 0.75) !important;
  border-bottom-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-y-gray-400\/80 {
  border-top-color: rgb(156 163 175 / 0.8) !important;
  border-bottom-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-y-gray-400\/85 {
  border-top-color: rgb(156 163 175 / 0.85) !important;
  border-bottom-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-y-gray-400\/90 {
  border-top-color: rgb(156 163 175 / 0.9) !important;
  border-bottom-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-y-gray-400\/95 {
  border-top-color: rgb(156 163 175 / 0.95) !important;
  border-bottom-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-y-gray-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-50\/0 {
  border-top-color: rgb(249 250 251 / 0) !important;
  border-bottom-color: rgb(249 250 251 / 0) !important;
}

.tw-border-y-gray-50\/10 {
  border-top-color: rgb(249 250 251 / 0.1) !important;
  border-bottom-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-y-gray-50\/100 {
  border-top-color: rgb(249 250 251 / 1) !important;
  border-bottom-color: rgb(249 250 251 / 1) !important;
}

.tw-border-y-gray-50\/15 {
  border-top-color: rgb(249 250 251 / 0.15) !important;
  border-bottom-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-y-gray-50\/20 {
  border-top-color: rgb(249 250 251 / 0.2) !important;
  border-bottom-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-y-gray-50\/25 {
  border-top-color: rgb(249 250 251 / 0.25) !important;
  border-bottom-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-y-gray-50\/30 {
  border-top-color: rgb(249 250 251 / 0.3) !important;
  border-bottom-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-y-gray-50\/35 {
  border-top-color: rgb(249 250 251 / 0.35) !important;
  border-bottom-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-y-gray-50\/40 {
  border-top-color: rgb(249 250 251 / 0.4) !important;
  border-bottom-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-y-gray-50\/45 {
  border-top-color: rgb(249 250 251 / 0.45) !important;
  border-bottom-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-y-gray-50\/5 {
  border-top-color: rgb(249 250 251 / 0.05) !important;
  border-bottom-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-y-gray-50\/50 {
  border-top-color: rgb(249 250 251 / 0.5) !important;
  border-bottom-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-y-gray-50\/55 {
  border-top-color: rgb(249 250 251 / 0.55) !important;
  border-bottom-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-y-gray-50\/60 {
  border-top-color: rgb(249 250 251 / 0.6) !important;
  border-bottom-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-y-gray-50\/65 {
  border-top-color: rgb(249 250 251 / 0.65) !important;
  border-bottom-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-y-gray-50\/70 {
  border-top-color: rgb(249 250 251 / 0.7) !important;
  border-bottom-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-y-gray-50\/75 {
  border-top-color: rgb(249 250 251 / 0.75) !important;
  border-bottom-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-y-gray-50\/80 {
  border-top-color: rgb(249 250 251 / 0.8) !important;
  border-bottom-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-y-gray-50\/85 {
  border-top-color: rgb(249 250 251 / 0.85) !important;
  border-bottom-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-y-gray-50\/90 {
  border-top-color: rgb(249 250 251 / 0.9) !important;
  border-bottom-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-y-gray-50\/95 {
  border-top-color: rgb(249 250 251 / 0.95) !important;
  border-bottom-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-y-gray-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-500\/0 {
  border-top-color: rgb(107 114 128 / 0) !important;
  border-bottom-color: rgb(107 114 128 / 0) !important;
}

.tw-border-y-gray-500\/10 {
  border-top-color: rgb(107 114 128 / 0.1) !important;
  border-bottom-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-y-gray-500\/100 {
  border-top-color: rgb(107 114 128 / 1) !important;
  border-bottom-color: rgb(107 114 128 / 1) !important;
}

.tw-border-y-gray-500\/15 {
  border-top-color: rgb(107 114 128 / 0.15) !important;
  border-bottom-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-y-gray-500\/20 {
  border-top-color: rgb(107 114 128 / 0.2) !important;
  border-bottom-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-y-gray-500\/25 {
  border-top-color: rgb(107 114 128 / 0.25) !important;
  border-bottom-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-y-gray-500\/30 {
  border-top-color: rgb(107 114 128 / 0.3) !important;
  border-bottom-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-y-gray-500\/35 {
  border-top-color: rgb(107 114 128 / 0.35) !important;
  border-bottom-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-y-gray-500\/40 {
  border-top-color: rgb(107 114 128 / 0.4) !important;
  border-bottom-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-y-gray-500\/45 {
  border-top-color: rgb(107 114 128 / 0.45) !important;
  border-bottom-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-y-gray-500\/5 {
  border-top-color: rgb(107 114 128 / 0.05) !important;
  border-bottom-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-y-gray-500\/50 {
  border-top-color: rgb(107 114 128 / 0.5) !important;
  border-bottom-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-y-gray-500\/55 {
  border-top-color: rgb(107 114 128 / 0.55) !important;
  border-bottom-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-y-gray-500\/60 {
  border-top-color: rgb(107 114 128 / 0.6) !important;
  border-bottom-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-y-gray-500\/65 {
  border-top-color: rgb(107 114 128 / 0.65) !important;
  border-bottom-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-y-gray-500\/70 {
  border-top-color: rgb(107 114 128 / 0.7) !important;
  border-bottom-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-y-gray-500\/75 {
  border-top-color: rgb(107 114 128 / 0.75) !important;
  border-bottom-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-y-gray-500\/80 {
  border-top-color: rgb(107 114 128 / 0.8) !important;
  border-bottom-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-y-gray-500\/85 {
  border-top-color: rgb(107 114 128 / 0.85) !important;
  border-bottom-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-y-gray-500\/90 {
  border-top-color: rgb(107 114 128 / 0.9) !important;
  border-bottom-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-y-gray-500\/95 {
  border-top-color: rgb(107 114 128 / 0.95) !important;
  border-bottom-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-y-gray-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-600\/0 {
  border-top-color: rgb(75 85 99 / 0) !important;
  border-bottom-color: rgb(75 85 99 / 0) !important;
}

.tw-border-y-gray-600\/10 {
  border-top-color: rgb(75 85 99 / 0.1) !important;
  border-bottom-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-y-gray-600\/100 {
  border-top-color: rgb(75 85 99 / 1) !important;
  border-bottom-color: rgb(75 85 99 / 1) !important;
}

.tw-border-y-gray-600\/15 {
  border-top-color: rgb(75 85 99 / 0.15) !important;
  border-bottom-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-y-gray-600\/20 {
  border-top-color: rgb(75 85 99 / 0.2) !important;
  border-bottom-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-y-gray-600\/25 {
  border-top-color: rgb(75 85 99 / 0.25) !important;
  border-bottom-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-y-gray-600\/30 {
  border-top-color: rgb(75 85 99 / 0.3) !important;
  border-bottom-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-y-gray-600\/35 {
  border-top-color: rgb(75 85 99 / 0.35) !important;
  border-bottom-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-y-gray-600\/40 {
  border-top-color: rgb(75 85 99 / 0.4) !important;
  border-bottom-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-y-gray-600\/45 {
  border-top-color: rgb(75 85 99 / 0.45) !important;
  border-bottom-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-y-gray-600\/5 {
  border-top-color: rgb(75 85 99 / 0.05) !important;
  border-bottom-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-y-gray-600\/50 {
  border-top-color: rgb(75 85 99 / 0.5) !important;
  border-bottom-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-y-gray-600\/55 {
  border-top-color: rgb(75 85 99 / 0.55) !important;
  border-bottom-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-y-gray-600\/60 {
  border-top-color: rgb(75 85 99 / 0.6) !important;
  border-bottom-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-y-gray-600\/65 {
  border-top-color: rgb(75 85 99 / 0.65) !important;
  border-bottom-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-y-gray-600\/70 {
  border-top-color: rgb(75 85 99 / 0.7) !important;
  border-bottom-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-y-gray-600\/75 {
  border-top-color: rgb(75 85 99 / 0.75) !important;
  border-bottom-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-y-gray-600\/80 {
  border-top-color: rgb(75 85 99 / 0.8) !important;
  border-bottom-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-y-gray-600\/85 {
  border-top-color: rgb(75 85 99 / 0.85) !important;
  border-bottom-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-y-gray-600\/90 {
  border-top-color: rgb(75 85 99 / 0.9) !important;
  border-bottom-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-y-gray-600\/95 {
  border-top-color: rgb(75 85 99 / 0.95) !important;
  border-bottom-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-y-gray-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-700\/0 {
  border-top-color: rgb(55 65 81 / 0) !important;
  border-bottom-color: rgb(55 65 81 / 0) !important;
}

.tw-border-y-gray-700\/10 {
  border-top-color: rgb(55 65 81 / 0.1) !important;
  border-bottom-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-y-gray-700\/100 {
  border-top-color: rgb(55 65 81 / 1) !important;
  border-bottom-color: rgb(55 65 81 / 1) !important;
}

.tw-border-y-gray-700\/15 {
  border-top-color: rgb(55 65 81 / 0.15) !important;
  border-bottom-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-y-gray-700\/20 {
  border-top-color: rgb(55 65 81 / 0.2) !important;
  border-bottom-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-y-gray-700\/25 {
  border-top-color: rgb(55 65 81 / 0.25) !important;
  border-bottom-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-y-gray-700\/30 {
  border-top-color: rgb(55 65 81 / 0.3) !important;
  border-bottom-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-y-gray-700\/35 {
  border-top-color: rgb(55 65 81 / 0.35) !important;
  border-bottom-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-y-gray-700\/40 {
  border-top-color: rgb(55 65 81 / 0.4) !important;
  border-bottom-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-y-gray-700\/45 {
  border-top-color: rgb(55 65 81 / 0.45) !important;
  border-bottom-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-y-gray-700\/5 {
  border-top-color: rgb(55 65 81 / 0.05) !important;
  border-bottom-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-y-gray-700\/50 {
  border-top-color: rgb(55 65 81 / 0.5) !important;
  border-bottom-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-y-gray-700\/55 {
  border-top-color: rgb(55 65 81 / 0.55) !important;
  border-bottom-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-y-gray-700\/60 {
  border-top-color: rgb(55 65 81 / 0.6) !important;
  border-bottom-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-y-gray-700\/65 {
  border-top-color: rgb(55 65 81 / 0.65) !important;
  border-bottom-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-y-gray-700\/70 {
  border-top-color: rgb(55 65 81 / 0.7) !important;
  border-bottom-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-y-gray-700\/75 {
  border-top-color: rgb(55 65 81 / 0.75) !important;
  border-bottom-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-y-gray-700\/80 {
  border-top-color: rgb(55 65 81 / 0.8) !important;
  border-bottom-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-y-gray-700\/85 {
  border-top-color: rgb(55 65 81 / 0.85) !important;
  border-bottom-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-y-gray-700\/90 {
  border-top-color: rgb(55 65 81 / 0.9) !important;
  border-bottom-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-y-gray-700\/95 {
  border-top-color: rgb(55 65 81 / 0.95) !important;
  border-bottom-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-y-gray-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-800\/0 {
  border-top-color: rgb(31 41 55 / 0) !important;
  border-bottom-color: rgb(31 41 55 / 0) !important;
}

.tw-border-y-gray-800\/10 {
  border-top-color: rgb(31 41 55 / 0.1) !important;
  border-bottom-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-y-gray-800\/100 {
  border-top-color: rgb(31 41 55 / 1) !important;
  border-bottom-color: rgb(31 41 55 / 1) !important;
}

.tw-border-y-gray-800\/15 {
  border-top-color: rgb(31 41 55 / 0.15) !important;
  border-bottom-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-y-gray-800\/20 {
  border-top-color: rgb(31 41 55 / 0.2) !important;
  border-bottom-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-y-gray-800\/25 {
  border-top-color: rgb(31 41 55 / 0.25) !important;
  border-bottom-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-y-gray-800\/30 {
  border-top-color: rgb(31 41 55 / 0.3) !important;
  border-bottom-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-y-gray-800\/35 {
  border-top-color: rgb(31 41 55 / 0.35) !important;
  border-bottom-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-y-gray-800\/40 {
  border-top-color: rgb(31 41 55 / 0.4) !important;
  border-bottom-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-y-gray-800\/45 {
  border-top-color: rgb(31 41 55 / 0.45) !important;
  border-bottom-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-y-gray-800\/5 {
  border-top-color: rgb(31 41 55 / 0.05) !important;
  border-bottom-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-y-gray-800\/50 {
  border-top-color: rgb(31 41 55 / 0.5) !important;
  border-bottom-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-y-gray-800\/55 {
  border-top-color: rgb(31 41 55 / 0.55) !important;
  border-bottom-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-y-gray-800\/60 {
  border-top-color: rgb(31 41 55 / 0.6) !important;
  border-bottom-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-y-gray-800\/65 {
  border-top-color: rgb(31 41 55 / 0.65) !important;
  border-bottom-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-y-gray-800\/70 {
  border-top-color: rgb(31 41 55 / 0.7) !important;
  border-bottom-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-y-gray-800\/75 {
  border-top-color: rgb(31 41 55 / 0.75) !important;
  border-bottom-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-y-gray-800\/80 {
  border-top-color: rgb(31 41 55 / 0.8) !important;
  border-bottom-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-y-gray-800\/85 {
  border-top-color: rgb(31 41 55 / 0.85) !important;
  border-bottom-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-y-gray-800\/90 {
  border-top-color: rgb(31 41 55 / 0.9) !important;
  border-bottom-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-y-gray-800\/95 {
  border-top-color: rgb(31 41 55 / 0.95) !important;
  border-bottom-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-y-gray-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-900\/0 {
  border-top-color: rgb(17 24 39 / 0) !important;
  border-bottom-color: rgb(17 24 39 / 0) !important;
}

.tw-border-y-gray-900\/10 {
  border-top-color: rgb(17 24 39 / 0.1) !important;
  border-bottom-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-y-gray-900\/100 {
  border-top-color: rgb(17 24 39 / 1) !important;
  border-bottom-color: rgb(17 24 39 / 1) !important;
}

.tw-border-y-gray-900\/15 {
  border-top-color: rgb(17 24 39 / 0.15) !important;
  border-bottom-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-y-gray-900\/20 {
  border-top-color: rgb(17 24 39 / 0.2) !important;
  border-bottom-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-y-gray-900\/25 {
  border-top-color: rgb(17 24 39 / 0.25) !important;
  border-bottom-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-y-gray-900\/30 {
  border-top-color: rgb(17 24 39 / 0.3) !important;
  border-bottom-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-y-gray-900\/35 {
  border-top-color: rgb(17 24 39 / 0.35) !important;
  border-bottom-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-y-gray-900\/40 {
  border-top-color: rgb(17 24 39 / 0.4) !important;
  border-bottom-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-y-gray-900\/45 {
  border-top-color: rgb(17 24 39 / 0.45) !important;
  border-bottom-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-y-gray-900\/5 {
  border-top-color: rgb(17 24 39 / 0.05) !important;
  border-bottom-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-y-gray-900\/50 {
  border-top-color: rgb(17 24 39 / 0.5) !important;
  border-bottom-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-y-gray-900\/55 {
  border-top-color: rgb(17 24 39 / 0.55) !important;
  border-bottom-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-y-gray-900\/60 {
  border-top-color: rgb(17 24 39 / 0.6) !important;
  border-bottom-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-y-gray-900\/65 {
  border-top-color: rgb(17 24 39 / 0.65) !important;
  border-bottom-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-y-gray-900\/70 {
  border-top-color: rgb(17 24 39 / 0.7) !important;
  border-bottom-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-y-gray-900\/75 {
  border-top-color: rgb(17 24 39 / 0.75) !important;
  border-bottom-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-y-gray-900\/80 {
  border-top-color: rgb(17 24 39 / 0.8) !important;
  border-bottom-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-y-gray-900\/85 {
  border-top-color: rgb(17 24 39 / 0.85) !important;
  border-bottom-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-y-gray-900\/90 {
  border-top-color: rgb(17 24 39 / 0.9) !important;
  border-bottom-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-y-gray-900\/95 {
  border-top-color: rgb(17 24 39 / 0.95) !important;
  border-bottom-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-y-gray-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-y-gray-950\/0 {
  border-top-color: rgb(3 7 18 / 0) !important;
  border-bottom-color: rgb(3 7 18 / 0) !important;
}

.tw-border-y-gray-950\/10 {
  border-top-color: rgb(3 7 18 / 0.1) !important;
  border-bottom-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-y-gray-950\/100 {
  border-top-color: rgb(3 7 18 / 1) !important;
  border-bottom-color: rgb(3 7 18 / 1) !important;
}

.tw-border-y-gray-950\/15 {
  border-top-color: rgb(3 7 18 / 0.15) !important;
  border-bottom-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-y-gray-950\/20 {
  border-top-color: rgb(3 7 18 / 0.2) !important;
  border-bottom-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-y-gray-950\/25 {
  border-top-color: rgb(3 7 18 / 0.25) !important;
  border-bottom-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-y-gray-950\/30 {
  border-top-color: rgb(3 7 18 / 0.3) !important;
  border-bottom-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-y-gray-950\/35 {
  border-top-color: rgb(3 7 18 / 0.35) !important;
  border-bottom-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-y-gray-950\/40 {
  border-top-color: rgb(3 7 18 / 0.4) !important;
  border-bottom-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-y-gray-950\/45 {
  border-top-color: rgb(3 7 18 / 0.45) !important;
  border-bottom-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-y-gray-950\/5 {
  border-top-color: rgb(3 7 18 / 0.05) !important;
  border-bottom-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-y-gray-950\/50 {
  border-top-color: rgb(3 7 18 / 0.5) !important;
  border-bottom-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-y-gray-950\/55 {
  border-top-color: rgb(3 7 18 / 0.55) !important;
  border-bottom-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-y-gray-950\/60 {
  border-top-color: rgb(3 7 18 / 0.6) !important;
  border-bottom-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-y-gray-950\/65 {
  border-top-color: rgb(3 7 18 / 0.65) !important;
  border-bottom-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-y-gray-950\/70 {
  border-top-color: rgb(3 7 18 / 0.7) !important;
  border-bottom-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-y-gray-950\/75 {
  border-top-color: rgb(3 7 18 / 0.75) !important;
  border-bottom-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-y-gray-950\/80 {
  border-top-color: rgb(3 7 18 / 0.8) !important;
  border-bottom-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-y-gray-950\/85 {
  border-top-color: rgb(3 7 18 / 0.85) !important;
  border-bottom-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-y-gray-950\/90 {
  border-top-color: rgb(3 7 18 / 0.9) !important;
  border-bottom-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-y-gray-950\/95 {
  border-top-color: rgb(3 7 18 / 0.95) !important;
  border-bottom-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-y-green-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-100\/0 {
  border-top-color: rgb(220 252 231 / 0) !important;
  border-bottom-color: rgb(220 252 231 / 0) !important;
}

.tw-border-y-green-100\/10 {
  border-top-color: rgb(220 252 231 / 0.1) !important;
  border-bottom-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-y-green-100\/100 {
  border-top-color: rgb(220 252 231 / 1) !important;
  border-bottom-color: rgb(220 252 231 / 1) !important;
}

.tw-border-y-green-100\/15 {
  border-top-color: rgb(220 252 231 / 0.15) !important;
  border-bottom-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-y-green-100\/20 {
  border-top-color: rgb(220 252 231 / 0.2) !important;
  border-bottom-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-y-green-100\/25 {
  border-top-color: rgb(220 252 231 / 0.25) !important;
  border-bottom-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-y-green-100\/30 {
  border-top-color: rgb(220 252 231 / 0.3) !important;
  border-bottom-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-y-green-100\/35 {
  border-top-color: rgb(220 252 231 / 0.35) !important;
  border-bottom-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-y-green-100\/40 {
  border-top-color: rgb(220 252 231 / 0.4) !important;
  border-bottom-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-y-green-100\/45 {
  border-top-color: rgb(220 252 231 / 0.45) !important;
  border-bottom-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-y-green-100\/5 {
  border-top-color: rgb(220 252 231 / 0.05) !important;
  border-bottom-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-y-green-100\/50 {
  border-top-color: rgb(220 252 231 / 0.5) !important;
  border-bottom-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-y-green-100\/55 {
  border-top-color: rgb(220 252 231 / 0.55) !important;
  border-bottom-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-y-green-100\/60 {
  border-top-color: rgb(220 252 231 / 0.6) !important;
  border-bottom-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-y-green-100\/65 {
  border-top-color: rgb(220 252 231 / 0.65) !important;
  border-bottom-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-y-green-100\/70 {
  border-top-color: rgb(220 252 231 / 0.7) !important;
  border-bottom-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-y-green-100\/75 {
  border-top-color: rgb(220 252 231 / 0.75) !important;
  border-bottom-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-y-green-100\/80 {
  border-top-color: rgb(220 252 231 / 0.8) !important;
  border-bottom-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-y-green-100\/85 {
  border-top-color: rgb(220 252 231 / 0.85) !important;
  border-bottom-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-y-green-100\/90 {
  border-top-color: rgb(220 252 231 / 0.9) !important;
  border-bottom-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-y-green-100\/95 {
  border-top-color: rgb(220 252 231 / 0.95) !important;
  border-bottom-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-y-green-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-200\/0 {
  border-top-color: rgb(187 247 208 / 0) !important;
  border-bottom-color: rgb(187 247 208 / 0) !important;
}

.tw-border-y-green-200\/10 {
  border-top-color: rgb(187 247 208 / 0.1) !important;
  border-bottom-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-y-green-200\/100 {
  border-top-color: rgb(187 247 208 / 1) !important;
  border-bottom-color: rgb(187 247 208 / 1) !important;
}

.tw-border-y-green-200\/15 {
  border-top-color: rgb(187 247 208 / 0.15) !important;
  border-bottom-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-y-green-200\/20 {
  border-top-color: rgb(187 247 208 / 0.2) !important;
  border-bottom-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-y-green-200\/25 {
  border-top-color: rgb(187 247 208 / 0.25) !important;
  border-bottom-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-y-green-200\/30 {
  border-top-color: rgb(187 247 208 / 0.3) !important;
  border-bottom-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-y-green-200\/35 {
  border-top-color: rgb(187 247 208 / 0.35) !important;
  border-bottom-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-y-green-200\/40 {
  border-top-color: rgb(187 247 208 / 0.4) !important;
  border-bottom-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-y-green-200\/45 {
  border-top-color: rgb(187 247 208 / 0.45) !important;
  border-bottom-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-y-green-200\/5 {
  border-top-color: rgb(187 247 208 / 0.05) !important;
  border-bottom-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-y-green-200\/50 {
  border-top-color: rgb(187 247 208 / 0.5) !important;
  border-bottom-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-y-green-200\/55 {
  border-top-color: rgb(187 247 208 / 0.55) !important;
  border-bottom-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-y-green-200\/60 {
  border-top-color: rgb(187 247 208 / 0.6) !important;
  border-bottom-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-y-green-200\/65 {
  border-top-color: rgb(187 247 208 / 0.65) !important;
  border-bottom-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-y-green-200\/70 {
  border-top-color: rgb(187 247 208 / 0.7) !important;
  border-bottom-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-y-green-200\/75 {
  border-top-color: rgb(187 247 208 / 0.75) !important;
  border-bottom-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-y-green-200\/80 {
  border-top-color: rgb(187 247 208 / 0.8) !important;
  border-bottom-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-y-green-200\/85 {
  border-top-color: rgb(187 247 208 / 0.85) !important;
  border-bottom-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-y-green-200\/90 {
  border-top-color: rgb(187 247 208 / 0.9) !important;
  border-bottom-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-y-green-200\/95 {
  border-top-color: rgb(187 247 208 / 0.95) !important;
  border-bottom-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-y-green-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-300\/0 {
  border-top-color: rgb(134 239 172 / 0) !important;
  border-bottom-color: rgb(134 239 172 / 0) !important;
}

.tw-border-y-green-300\/10 {
  border-top-color: rgb(134 239 172 / 0.1) !important;
  border-bottom-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-y-green-300\/100 {
  border-top-color: rgb(134 239 172 / 1) !important;
  border-bottom-color: rgb(134 239 172 / 1) !important;
}

.tw-border-y-green-300\/15 {
  border-top-color: rgb(134 239 172 / 0.15) !important;
  border-bottom-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-y-green-300\/20 {
  border-top-color: rgb(134 239 172 / 0.2) !important;
  border-bottom-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-y-green-300\/25 {
  border-top-color: rgb(134 239 172 / 0.25) !important;
  border-bottom-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-y-green-300\/30 {
  border-top-color: rgb(134 239 172 / 0.3) !important;
  border-bottom-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-y-green-300\/35 {
  border-top-color: rgb(134 239 172 / 0.35) !important;
  border-bottom-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-y-green-300\/40 {
  border-top-color: rgb(134 239 172 / 0.4) !important;
  border-bottom-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-y-green-300\/45 {
  border-top-color: rgb(134 239 172 / 0.45) !important;
  border-bottom-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-y-green-300\/5 {
  border-top-color: rgb(134 239 172 / 0.05) !important;
  border-bottom-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-y-green-300\/50 {
  border-top-color: rgb(134 239 172 / 0.5) !important;
  border-bottom-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-y-green-300\/55 {
  border-top-color: rgb(134 239 172 / 0.55) !important;
  border-bottom-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-y-green-300\/60 {
  border-top-color: rgb(134 239 172 / 0.6) !important;
  border-bottom-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-y-green-300\/65 {
  border-top-color: rgb(134 239 172 / 0.65) !important;
  border-bottom-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-y-green-300\/70 {
  border-top-color: rgb(134 239 172 / 0.7) !important;
  border-bottom-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-y-green-300\/75 {
  border-top-color: rgb(134 239 172 / 0.75) !important;
  border-bottom-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-y-green-300\/80 {
  border-top-color: rgb(134 239 172 / 0.8) !important;
  border-bottom-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-y-green-300\/85 {
  border-top-color: rgb(134 239 172 / 0.85) !important;
  border-bottom-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-y-green-300\/90 {
  border-top-color: rgb(134 239 172 / 0.9) !important;
  border-bottom-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-y-green-300\/95 {
  border-top-color: rgb(134 239 172 / 0.95) !important;
  border-bottom-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-y-green-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-400\/0 {
  border-top-color: rgb(74 222 128 / 0) !important;
  border-bottom-color: rgb(74 222 128 / 0) !important;
}

.tw-border-y-green-400\/10 {
  border-top-color: rgb(74 222 128 / 0.1) !important;
  border-bottom-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-y-green-400\/100 {
  border-top-color: rgb(74 222 128 / 1) !important;
  border-bottom-color: rgb(74 222 128 / 1) !important;
}

.tw-border-y-green-400\/15 {
  border-top-color: rgb(74 222 128 / 0.15) !important;
  border-bottom-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-y-green-400\/20 {
  border-top-color: rgb(74 222 128 / 0.2) !important;
  border-bottom-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-y-green-400\/25 {
  border-top-color: rgb(74 222 128 / 0.25) !important;
  border-bottom-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-y-green-400\/30 {
  border-top-color: rgb(74 222 128 / 0.3) !important;
  border-bottom-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-y-green-400\/35 {
  border-top-color: rgb(74 222 128 / 0.35) !important;
  border-bottom-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-y-green-400\/40 {
  border-top-color: rgb(74 222 128 / 0.4) !important;
  border-bottom-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-y-green-400\/45 {
  border-top-color: rgb(74 222 128 / 0.45) !important;
  border-bottom-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-y-green-400\/5 {
  border-top-color: rgb(74 222 128 / 0.05) !important;
  border-bottom-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-y-green-400\/50 {
  border-top-color: rgb(74 222 128 / 0.5) !important;
  border-bottom-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-y-green-400\/55 {
  border-top-color: rgb(74 222 128 / 0.55) !important;
  border-bottom-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-y-green-400\/60 {
  border-top-color: rgb(74 222 128 / 0.6) !important;
  border-bottom-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-y-green-400\/65 {
  border-top-color: rgb(74 222 128 / 0.65) !important;
  border-bottom-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-y-green-400\/70 {
  border-top-color: rgb(74 222 128 / 0.7) !important;
  border-bottom-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-y-green-400\/75 {
  border-top-color: rgb(74 222 128 / 0.75) !important;
  border-bottom-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-y-green-400\/80 {
  border-top-color: rgb(74 222 128 / 0.8) !important;
  border-bottom-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-y-green-400\/85 {
  border-top-color: rgb(74 222 128 / 0.85) !important;
  border-bottom-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-y-green-400\/90 {
  border-top-color: rgb(74 222 128 / 0.9) !important;
  border-bottom-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-y-green-400\/95 {
  border-top-color: rgb(74 222 128 / 0.95) !important;
  border-bottom-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-y-green-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-50\/0 {
  border-top-color: rgb(240 253 244 / 0) !important;
  border-bottom-color: rgb(240 253 244 / 0) !important;
}

.tw-border-y-green-50\/10 {
  border-top-color: rgb(240 253 244 / 0.1) !important;
  border-bottom-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-y-green-50\/100 {
  border-top-color: rgb(240 253 244 / 1) !important;
  border-bottom-color: rgb(240 253 244 / 1) !important;
}

.tw-border-y-green-50\/15 {
  border-top-color: rgb(240 253 244 / 0.15) !important;
  border-bottom-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-y-green-50\/20 {
  border-top-color: rgb(240 253 244 / 0.2) !important;
  border-bottom-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-y-green-50\/25 {
  border-top-color: rgb(240 253 244 / 0.25) !important;
  border-bottom-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-y-green-50\/30 {
  border-top-color: rgb(240 253 244 / 0.3) !important;
  border-bottom-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-y-green-50\/35 {
  border-top-color: rgb(240 253 244 / 0.35) !important;
  border-bottom-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-y-green-50\/40 {
  border-top-color: rgb(240 253 244 / 0.4) !important;
  border-bottom-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-y-green-50\/45 {
  border-top-color: rgb(240 253 244 / 0.45) !important;
  border-bottom-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-y-green-50\/5 {
  border-top-color: rgb(240 253 244 / 0.05) !important;
  border-bottom-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-y-green-50\/50 {
  border-top-color: rgb(240 253 244 / 0.5) !important;
  border-bottom-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-y-green-50\/55 {
  border-top-color: rgb(240 253 244 / 0.55) !important;
  border-bottom-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-y-green-50\/60 {
  border-top-color: rgb(240 253 244 / 0.6) !important;
  border-bottom-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-y-green-50\/65 {
  border-top-color: rgb(240 253 244 / 0.65) !important;
  border-bottom-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-y-green-50\/70 {
  border-top-color: rgb(240 253 244 / 0.7) !important;
  border-bottom-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-y-green-50\/75 {
  border-top-color: rgb(240 253 244 / 0.75) !important;
  border-bottom-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-y-green-50\/80 {
  border-top-color: rgb(240 253 244 / 0.8) !important;
  border-bottom-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-y-green-50\/85 {
  border-top-color: rgb(240 253 244 / 0.85) !important;
  border-bottom-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-y-green-50\/90 {
  border-top-color: rgb(240 253 244 / 0.9) !important;
  border-bottom-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-y-green-50\/95 {
  border-top-color: rgb(240 253 244 / 0.95) !important;
  border-bottom-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-y-green-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-500\/0 {
  border-top-color: rgb(34 197 94 / 0) !important;
  border-bottom-color: rgb(34 197 94 / 0) !important;
}

.tw-border-y-green-500\/10 {
  border-top-color: rgb(34 197 94 / 0.1) !important;
  border-bottom-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-y-green-500\/100 {
  border-top-color: rgb(34 197 94 / 1) !important;
  border-bottom-color: rgb(34 197 94 / 1) !important;
}

.tw-border-y-green-500\/15 {
  border-top-color: rgb(34 197 94 / 0.15) !important;
  border-bottom-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-y-green-500\/20 {
  border-top-color: rgb(34 197 94 / 0.2) !important;
  border-bottom-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-y-green-500\/25 {
  border-top-color: rgb(34 197 94 / 0.25) !important;
  border-bottom-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-y-green-500\/30 {
  border-top-color: rgb(34 197 94 / 0.3) !important;
  border-bottom-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-y-green-500\/35 {
  border-top-color: rgb(34 197 94 / 0.35) !important;
  border-bottom-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-y-green-500\/40 {
  border-top-color: rgb(34 197 94 / 0.4) !important;
  border-bottom-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-y-green-500\/45 {
  border-top-color: rgb(34 197 94 / 0.45) !important;
  border-bottom-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-y-green-500\/5 {
  border-top-color: rgb(34 197 94 / 0.05) !important;
  border-bottom-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-y-green-500\/50 {
  border-top-color: rgb(34 197 94 / 0.5) !important;
  border-bottom-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-y-green-500\/55 {
  border-top-color: rgb(34 197 94 / 0.55) !important;
  border-bottom-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-y-green-500\/60 {
  border-top-color: rgb(34 197 94 / 0.6) !important;
  border-bottom-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-y-green-500\/65 {
  border-top-color: rgb(34 197 94 / 0.65) !important;
  border-bottom-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-y-green-500\/70 {
  border-top-color: rgb(34 197 94 / 0.7) !important;
  border-bottom-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-y-green-500\/75 {
  border-top-color: rgb(34 197 94 / 0.75) !important;
  border-bottom-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-y-green-500\/80 {
  border-top-color: rgb(34 197 94 / 0.8) !important;
  border-bottom-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-y-green-500\/85 {
  border-top-color: rgb(34 197 94 / 0.85) !important;
  border-bottom-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-y-green-500\/90 {
  border-top-color: rgb(34 197 94 / 0.9) !important;
  border-bottom-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-y-green-500\/95 {
  border-top-color: rgb(34 197 94 / 0.95) !important;
  border-bottom-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-y-green-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-600\/0 {
  border-top-color: rgb(22 163 74 / 0) !important;
  border-bottom-color: rgb(22 163 74 / 0) !important;
}

.tw-border-y-green-600\/10 {
  border-top-color: rgb(22 163 74 / 0.1) !important;
  border-bottom-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-y-green-600\/100 {
  border-top-color: rgb(22 163 74 / 1) !important;
  border-bottom-color: rgb(22 163 74 / 1) !important;
}

.tw-border-y-green-600\/15 {
  border-top-color: rgb(22 163 74 / 0.15) !important;
  border-bottom-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-y-green-600\/20 {
  border-top-color: rgb(22 163 74 / 0.2) !important;
  border-bottom-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-y-green-600\/25 {
  border-top-color: rgb(22 163 74 / 0.25) !important;
  border-bottom-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-y-green-600\/30 {
  border-top-color: rgb(22 163 74 / 0.3) !important;
  border-bottom-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-y-green-600\/35 {
  border-top-color: rgb(22 163 74 / 0.35) !important;
  border-bottom-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-y-green-600\/40 {
  border-top-color: rgb(22 163 74 / 0.4) !important;
  border-bottom-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-y-green-600\/45 {
  border-top-color: rgb(22 163 74 / 0.45) !important;
  border-bottom-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-y-green-600\/5 {
  border-top-color: rgb(22 163 74 / 0.05) !important;
  border-bottom-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-y-green-600\/50 {
  border-top-color: rgb(22 163 74 / 0.5) !important;
  border-bottom-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-y-green-600\/55 {
  border-top-color: rgb(22 163 74 / 0.55) !important;
  border-bottom-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-y-green-600\/60 {
  border-top-color: rgb(22 163 74 / 0.6) !important;
  border-bottom-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-y-green-600\/65 {
  border-top-color: rgb(22 163 74 / 0.65) !important;
  border-bottom-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-y-green-600\/70 {
  border-top-color: rgb(22 163 74 / 0.7) !important;
  border-bottom-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-y-green-600\/75 {
  border-top-color: rgb(22 163 74 / 0.75) !important;
  border-bottom-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-y-green-600\/80 {
  border-top-color: rgb(22 163 74 / 0.8) !important;
  border-bottom-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-y-green-600\/85 {
  border-top-color: rgb(22 163 74 / 0.85) !important;
  border-bottom-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-y-green-600\/90 {
  border-top-color: rgb(22 163 74 / 0.9) !important;
  border-bottom-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-y-green-600\/95 {
  border-top-color: rgb(22 163 74 / 0.95) !important;
  border-bottom-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-y-green-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-700\/0 {
  border-top-color: rgb(21 128 61 / 0) !important;
  border-bottom-color: rgb(21 128 61 / 0) !important;
}

.tw-border-y-green-700\/10 {
  border-top-color: rgb(21 128 61 / 0.1) !important;
  border-bottom-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-y-green-700\/100 {
  border-top-color: rgb(21 128 61 / 1) !important;
  border-bottom-color: rgb(21 128 61 / 1) !important;
}

.tw-border-y-green-700\/15 {
  border-top-color: rgb(21 128 61 / 0.15) !important;
  border-bottom-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-y-green-700\/20 {
  border-top-color: rgb(21 128 61 / 0.2) !important;
  border-bottom-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-y-green-700\/25 {
  border-top-color: rgb(21 128 61 / 0.25) !important;
  border-bottom-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-y-green-700\/30 {
  border-top-color: rgb(21 128 61 / 0.3) !important;
  border-bottom-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-y-green-700\/35 {
  border-top-color: rgb(21 128 61 / 0.35) !important;
  border-bottom-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-y-green-700\/40 {
  border-top-color: rgb(21 128 61 / 0.4) !important;
  border-bottom-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-y-green-700\/45 {
  border-top-color: rgb(21 128 61 / 0.45) !important;
  border-bottom-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-y-green-700\/5 {
  border-top-color: rgb(21 128 61 / 0.05) !important;
  border-bottom-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-y-green-700\/50 {
  border-top-color: rgb(21 128 61 / 0.5) !important;
  border-bottom-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-y-green-700\/55 {
  border-top-color: rgb(21 128 61 / 0.55) !important;
  border-bottom-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-y-green-700\/60 {
  border-top-color: rgb(21 128 61 / 0.6) !important;
  border-bottom-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-y-green-700\/65 {
  border-top-color: rgb(21 128 61 / 0.65) !important;
  border-bottom-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-y-green-700\/70 {
  border-top-color: rgb(21 128 61 / 0.7) !important;
  border-bottom-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-y-green-700\/75 {
  border-top-color: rgb(21 128 61 / 0.75) !important;
  border-bottom-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-y-green-700\/80 {
  border-top-color: rgb(21 128 61 / 0.8) !important;
  border-bottom-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-y-green-700\/85 {
  border-top-color: rgb(21 128 61 / 0.85) !important;
  border-bottom-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-y-green-700\/90 {
  border-top-color: rgb(21 128 61 / 0.9) !important;
  border-bottom-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-y-green-700\/95 {
  border-top-color: rgb(21 128 61 / 0.95) !important;
  border-bottom-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-y-green-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-800\/0 {
  border-top-color: rgb(22 101 52 / 0) !important;
  border-bottom-color: rgb(22 101 52 / 0) !important;
}

.tw-border-y-green-800\/10 {
  border-top-color: rgb(22 101 52 / 0.1) !important;
  border-bottom-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-y-green-800\/100 {
  border-top-color: rgb(22 101 52 / 1) !important;
  border-bottom-color: rgb(22 101 52 / 1) !important;
}

.tw-border-y-green-800\/15 {
  border-top-color: rgb(22 101 52 / 0.15) !important;
  border-bottom-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-y-green-800\/20 {
  border-top-color: rgb(22 101 52 / 0.2) !important;
  border-bottom-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-y-green-800\/25 {
  border-top-color: rgb(22 101 52 / 0.25) !important;
  border-bottom-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-y-green-800\/30 {
  border-top-color: rgb(22 101 52 / 0.3) !important;
  border-bottom-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-y-green-800\/35 {
  border-top-color: rgb(22 101 52 / 0.35) !important;
  border-bottom-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-y-green-800\/40 {
  border-top-color: rgb(22 101 52 / 0.4) !important;
  border-bottom-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-y-green-800\/45 {
  border-top-color: rgb(22 101 52 / 0.45) !important;
  border-bottom-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-y-green-800\/5 {
  border-top-color: rgb(22 101 52 / 0.05) !important;
  border-bottom-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-y-green-800\/50 {
  border-top-color: rgb(22 101 52 / 0.5) !important;
  border-bottom-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-y-green-800\/55 {
  border-top-color: rgb(22 101 52 / 0.55) !important;
  border-bottom-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-y-green-800\/60 {
  border-top-color: rgb(22 101 52 / 0.6) !important;
  border-bottom-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-y-green-800\/65 {
  border-top-color: rgb(22 101 52 / 0.65) !important;
  border-bottom-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-y-green-800\/70 {
  border-top-color: rgb(22 101 52 / 0.7) !important;
  border-bottom-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-y-green-800\/75 {
  border-top-color: rgb(22 101 52 / 0.75) !important;
  border-bottom-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-y-green-800\/80 {
  border-top-color: rgb(22 101 52 / 0.8) !important;
  border-bottom-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-y-green-800\/85 {
  border-top-color: rgb(22 101 52 / 0.85) !important;
  border-bottom-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-y-green-800\/90 {
  border-top-color: rgb(22 101 52 / 0.9) !important;
  border-bottom-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-y-green-800\/95 {
  border-top-color: rgb(22 101 52 / 0.95) !important;
  border-bottom-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-y-green-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-900\/0 {
  border-top-color: rgb(20 83 45 / 0) !important;
  border-bottom-color: rgb(20 83 45 / 0) !important;
}

.tw-border-y-green-900\/10 {
  border-top-color: rgb(20 83 45 / 0.1) !important;
  border-bottom-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-y-green-900\/100 {
  border-top-color: rgb(20 83 45 / 1) !important;
  border-bottom-color: rgb(20 83 45 / 1) !important;
}

.tw-border-y-green-900\/15 {
  border-top-color: rgb(20 83 45 / 0.15) !important;
  border-bottom-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-y-green-900\/20 {
  border-top-color: rgb(20 83 45 / 0.2) !important;
  border-bottom-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-y-green-900\/25 {
  border-top-color: rgb(20 83 45 / 0.25) !important;
  border-bottom-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-y-green-900\/30 {
  border-top-color: rgb(20 83 45 / 0.3) !important;
  border-bottom-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-y-green-900\/35 {
  border-top-color: rgb(20 83 45 / 0.35) !important;
  border-bottom-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-y-green-900\/40 {
  border-top-color: rgb(20 83 45 / 0.4) !important;
  border-bottom-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-y-green-900\/45 {
  border-top-color: rgb(20 83 45 / 0.45) !important;
  border-bottom-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-y-green-900\/5 {
  border-top-color: rgb(20 83 45 / 0.05) !important;
  border-bottom-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-y-green-900\/50 {
  border-top-color: rgb(20 83 45 / 0.5) !important;
  border-bottom-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-y-green-900\/55 {
  border-top-color: rgb(20 83 45 / 0.55) !important;
  border-bottom-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-y-green-900\/60 {
  border-top-color: rgb(20 83 45 / 0.6) !important;
  border-bottom-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-y-green-900\/65 {
  border-top-color: rgb(20 83 45 / 0.65) !important;
  border-bottom-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-y-green-900\/70 {
  border-top-color: rgb(20 83 45 / 0.7) !important;
  border-bottom-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-y-green-900\/75 {
  border-top-color: rgb(20 83 45 / 0.75) !important;
  border-bottom-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-y-green-900\/80 {
  border-top-color: rgb(20 83 45 / 0.8) !important;
  border-bottom-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-y-green-900\/85 {
  border-top-color: rgb(20 83 45 / 0.85) !important;
  border-bottom-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-y-green-900\/90 {
  border-top-color: rgb(20 83 45 / 0.9) !important;
  border-bottom-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-y-green-900\/95 {
  border-top-color: rgb(20 83 45 / 0.95) !important;
  border-bottom-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-y-green-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-y-green-950\/0 {
  border-top-color: rgb(5 46 22 / 0) !important;
  border-bottom-color: rgb(5 46 22 / 0) !important;
}

.tw-border-y-green-950\/10 {
  border-top-color: rgb(5 46 22 / 0.1) !important;
  border-bottom-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-y-green-950\/100 {
  border-top-color: rgb(5 46 22 / 1) !important;
  border-bottom-color: rgb(5 46 22 / 1) !important;
}

.tw-border-y-green-950\/15 {
  border-top-color: rgb(5 46 22 / 0.15) !important;
  border-bottom-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-y-green-950\/20 {
  border-top-color: rgb(5 46 22 / 0.2) !important;
  border-bottom-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-y-green-950\/25 {
  border-top-color: rgb(5 46 22 / 0.25) !important;
  border-bottom-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-y-green-950\/30 {
  border-top-color: rgb(5 46 22 / 0.3) !important;
  border-bottom-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-y-green-950\/35 {
  border-top-color: rgb(5 46 22 / 0.35) !important;
  border-bottom-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-y-green-950\/40 {
  border-top-color: rgb(5 46 22 / 0.4) !important;
  border-bottom-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-y-green-950\/45 {
  border-top-color: rgb(5 46 22 / 0.45) !important;
  border-bottom-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-y-green-950\/5 {
  border-top-color: rgb(5 46 22 / 0.05) !important;
  border-bottom-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-y-green-950\/50 {
  border-top-color: rgb(5 46 22 / 0.5) !important;
  border-bottom-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-y-green-950\/55 {
  border-top-color: rgb(5 46 22 / 0.55) !important;
  border-bottom-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-y-green-950\/60 {
  border-top-color: rgb(5 46 22 / 0.6) !important;
  border-bottom-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-y-green-950\/65 {
  border-top-color: rgb(5 46 22 / 0.65) !important;
  border-bottom-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-y-green-950\/70 {
  border-top-color: rgb(5 46 22 / 0.7) !important;
  border-bottom-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-y-green-950\/75 {
  border-top-color: rgb(5 46 22 / 0.75) !important;
  border-bottom-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-y-green-950\/80 {
  border-top-color: rgb(5 46 22 / 0.8) !important;
  border-bottom-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-y-green-950\/85 {
  border-top-color: rgb(5 46 22 / 0.85) !important;
  border-bottom-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-y-green-950\/90 {
  border-top-color: rgb(5 46 22 / 0.9) !important;
  border-bottom-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-y-green-950\/95 {
  border-top-color: rgb(5 46 22 / 0.95) !important;
  border-bottom-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-y-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-100\/0 {
  border-top-color: rgb(224 231 255 / 0) !important;
  border-bottom-color: rgb(224 231 255 / 0) !important;
}

.tw-border-y-indigo-100\/10 {
  border-top-color: rgb(224 231 255 / 0.1) !important;
  border-bottom-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-y-indigo-100\/100 {
  border-top-color: rgb(224 231 255 / 1) !important;
  border-bottom-color: rgb(224 231 255 / 1) !important;
}

.tw-border-y-indigo-100\/15 {
  border-top-color: rgb(224 231 255 / 0.15) !important;
  border-bottom-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-y-indigo-100\/20 {
  border-top-color: rgb(224 231 255 / 0.2) !important;
  border-bottom-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-y-indigo-100\/25 {
  border-top-color: rgb(224 231 255 / 0.25) !important;
  border-bottom-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-y-indigo-100\/30 {
  border-top-color: rgb(224 231 255 / 0.3) !important;
  border-bottom-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-y-indigo-100\/35 {
  border-top-color: rgb(224 231 255 / 0.35) !important;
  border-bottom-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-y-indigo-100\/40 {
  border-top-color: rgb(224 231 255 / 0.4) !important;
  border-bottom-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-y-indigo-100\/45 {
  border-top-color: rgb(224 231 255 / 0.45) !important;
  border-bottom-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-y-indigo-100\/5 {
  border-top-color: rgb(224 231 255 / 0.05) !important;
  border-bottom-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-y-indigo-100\/50 {
  border-top-color: rgb(224 231 255 / 0.5) !important;
  border-bottom-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-y-indigo-100\/55 {
  border-top-color: rgb(224 231 255 / 0.55) !important;
  border-bottom-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-y-indigo-100\/60 {
  border-top-color: rgb(224 231 255 / 0.6) !important;
  border-bottom-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-y-indigo-100\/65 {
  border-top-color: rgb(224 231 255 / 0.65) !important;
  border-bottom-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-y-indigo-100\/70 {
  border-top-color: rgb(224 231 255 / 0.7) !important;
  border-bottom-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-y-indigo-100\/75 {
  border-top-color: rgb(224 231 255 / 0.75) !important;
  border-bottom-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-y-indigo-100\/80 {
  border-top-color: rgb(224 231 255 / 0.8) !important;
  border-bottom-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-y-indigo-100\/85 {
  border-top-color: rgb(224 231 255 / 0.85) !important;
  border-bottom-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-y-indigo-100\/90 {
  border-top-color: rgb(224 231 255 / 0.9) !important;
  border-bottom-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-y-indigo-100\/95 {
  border-top-color: rgb(224 231 255 / 0.95) !important;
  border-bottom-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-y-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-200\/0 {
  border-top-color: rgb(199 210 254 / 0) !important;
  border-bottom-color: rgb(199 210 254 / 0) !important;
}

.tw-border-y-indigo-200\/10 {
  border-top-color: rgb(199 210 254 / 0.1) !important;
  border-bottom-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-y-indigo-200\/100 {
  border-top-color: rgb(199 210 254 / 1) !important;
  border-bottom-color: rgb(199 210 254 / 1) !important;
}

.tw-border-y-indigo-200\/15 {
  border-top-color: rgb(199 210 254 / 0.15) !important;
  border-bottom-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-y-indigo-200\/20 {
  border-top-color: rgb(199 210 254 / 0.2) !important;
  border-bottom-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-y-indigo-200\/25 {
  border-top-color: rgb(199 210 254 / 0.25) !important;
  border-bottom-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-y-indigo-200\/30 {
  border-top-color: rgb(199 210 254 / 0.3) !important;
  border-bottom-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-y-indigo-200\/35 {
  border-top-color: rgb(199 210 254 / 0.35) !important;
  border-bottom-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-y-indigo-200\/40 {
  border-top-color: rgb(199 210 254 / 0.4) !important;
  border-bottom-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-y-indigo-200\/45 {
  border-top-color: rgb(199 210 254 / 0.45) !important;
  border-bottom-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-y-indigo-200\/5 {
  border-top-color: rgb(199 210 254 / 0.05) !important;
  border-bottom-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-y-indigo-200\/50 {
  border-top-color: rgb(199 210 254 / 0.5) !important;
  border-bottom-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-y-indigo-200\/55 {
  border-top-color: rgb(199 210 254 / 0.55) !important;
  border-bottom-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-y-indigo-200\/60 {
  border-top-color: rgb(199 210 254 / 0.6) !important;
  border-bottom-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-y-indigo-200\/65 {
  border-top-color: rgb(199 210 254 / 0.65) !important;
  border-bottom-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-y-indigo-200\/70 {
  border-top-color: rgb(199 210 254 / 0.7) !important;
  border-bottom-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-y-indigo-200\/75 {
  border-top-color: rgb(199 210 254 / 0.75) !important;
  border-bottom-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-y-indigo-200\/80 {
  border-top-color: rgb(199 210 254 / 0.8) !important;
  border-bottom-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-y-indigo-200\/85 {
  border-top-color: rgb(199 210 254 / 0.85) !important;
  border-bottom-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-y-indigo-200\/90 {
  border-top-color: rgb(199 210 254 / 0.9) !important;
  border-bottom-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-y-indigo-200\/95 {
  border-top-color: rgb(199 210 254 / 0.95) !important;
  border-bottom-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-y-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-300\/0 {
  border-top-color: rgb(165 180 252 / 0) !important;
  border-bottom-color: rgb(165 180 252 / 0) !important;
}

.tw-border-y-indigo-300\/10 {
  border-top-color: rgb(165 180 252 / 0.1) !important;
  border-bottom-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-y-indigo-300\/100 {
  border-top-color: rgb(165 180 252 / 1) !important;
  border-bottom-color: rgb(165 180 252 / 1) !important;
}

.tw-border-y-indigo-300\/15 {
  border-top-color: rgb(165 180 252 / 0.15) !important;
  border-bottom-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-y-indigo-300\/20 {
  border-top-color: rgb(165 180 252 / 0.2) !important;
  border-bottom-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-y-indigo-300\/25 {
  border-top-color: rgb(165 180 252 / 0.25) !important;
  border-bottom-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-y-indigo-300\/30 {
  border-top-color: rgb(165 180 252 / 0.3) !important;
  border-bottom-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-y-indigo-300\/35 {
  border-top-color: rgb(165 180 252 / 0.35) !important;
  border-bottom-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-y-indigo-300\/40 {
  border-top-color: rgb(165 180 252 / 0.4) !important;
  border-bottom-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-y-indigo-300\/45 {
  border-top-color: rgb(165 180 252 / 0.45) !important;
  border-bottom-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-y-indigo-300\/5 {
  border-top-color: rgb(165 180 252 / 0.05) !important;
  border-bottom-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-y-indigo-300\/50 {
  border-top-color: rgb(165 180 252 / 0.5) !important;
  border-bottom-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-y-indigo-300\/55 {
  border-top-color: rgb(165 180 252 / 0.55) !important;
  border-bottom-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-y-indigo-300\/60 {
  border-top-color: rgb(165 180 252 / 0.6) !important;
  border-bottom-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-y-indigo-300\/65 {
  border-top-color: rgb(165 180 252 / 0.65) !important;
  border-bottom-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-y-indigo-300\/70 {
  border-top-color: rgb(165 180 252 / 0.7) !important;
  border-bottom-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-y-indigo-300\/75 {
  border-top-color: rgb(165 180 252 / 0.75) !important;
  border-bottom-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-y-indigo-300\/80 {
  border-top-color: rgb(165 180 252 / 0.8) !important;
  border-bottom-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-y-indigo-300\/85 {
  border-top-color: rgb(165 180 252 / 0.85) !important;
  border-bottom-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-y-indigo-300\/90 {
  border-top-color: rgb(165 180 252 / 0.9) !important;
  border-bottom-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-y-indigo-300\/95 {
  border-top-color: rgb(165 180 252 / 0.95) !important;
  border-bottom-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-y-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-400\/0 {
  border-top-color: rgb(129 140 248 / 0) !important;
  border-bottom-color: rgb(129 140 248 / 0) !important;
}

.tw-border-y-indigo-400\/10 {
  border-top-color: rgb(129 140 248 / 0.1) !important;
  border-bottom-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-y-indigo-400\/100 {
  border-top-color: rgb(129 140 248 / 1) !important;
  border-bottom-color: rgb(129 140 248 / 1) !important;
}

.tw-border-y-indigo-400\/15 {
  border-top-color: rgb(129 140 248 / 0.15) !important;
  border-bottom-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-y-indigo-400\/20 {
  border-top-color: rgb(129 140 248 / 0.2) !important;
  border-bottom-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-y-indigo-400\/25 {
  border-top-color: rgb(129 140 248 / 0.25) !important;
  border-bottom-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-y-indigo-400\/30 {
  border-top-color: rgb(129 140 248 / 0.3) !important;
  border-bottom-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-y-indigo-400\/35 {
  border-top-color: rgb(129 140 248 / 0.35) !important;
  border-bottom-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-y-indigo-400\/40 {
  border-top-color: rgb(129 140 248 / 0.4) !important;
  border-bottom-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-y-indigo-400\/45 {
  border-top-color: rgb(129 140 248 / 0.45) !important;
  border-bottom-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-y-indigo-400\/5 {
  border-top-color: rgb(129 140 248 / 0.05) !important;
  border-bottom-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-y-indigo-400\/50 {
  border-top-color: rgb(129 140 248 / 0.5) !important;
  border-bottom-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-y-indigo-400\/55 {
  border-top-color: rgb(129 140 248 / 0.55) !important;
  border-bottom-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-y-indigo-400\/60 {
  border-top-color: rgb(129 140 248 / 0.6) !important;
  border-bottom-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-y-indigo-400\/65 {
  border-top-color: rgb(129 140 248 / 0.65) !important;
  border-bottom-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-y-indigo-400\/70 {
  border-top-color: rgb(129 140 248 / 0.7) !important;
  border-bottom-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-y-indigo-400\/75 {
  border-top-color: rgb(129 140 248 / 0.75) !important;
  border-bottom-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-y-indigo-400\/80 {
  border-top-color: rgb(129 140 248 / 0.8) !important;
  border-bottom-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-y-indigo-400\/85 {
  border-top-color: rgb(129 140 248 / 0.85) !important;
  border-bottom-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-y-indigo-400\/90 {
  border-top-color: rgb(129 140 248 / 0.9) !important;
  border-bottom-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-y-indigo-400\/95 {
  border-top-color: rgb(129 140 248 / 0.95) !important;
  border-bottom-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-y-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-50\/0 {
  border-top-color: rgb(238 242 255 / 0) !important;
  border-bottom-color: rgb(238 242 255 / 0) !important;
}

.tw-border-y-indigo-50\/10 {
  border-top-color: rgb(238 242 255 / 0.1) !important;
  border-bottom-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-y-indigo-50\/100 {
  border-top-color: rgb(238 242 255 / 1) !important;
  border-bottom-color: rgb(238 242 255 / 1) !important;
}

.tw-border-y-indigo-50\/15 {
  border-top-color: rgb(238 242 255 / 0.15) !important;
  border-bottom-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-y-indigo-50\/20 {
  border-top-color: rgb(238 242 255 / 0.2) !important;
  border-bottom-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-y-indigo-50\/25 {
  border-top-color: rgb(238 242 255 / 0.25) !important;
  border-bottom-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-y-indigo-50\/30 {
  border-top-color: rgb(238 242 255 / 0.3) !important;
  border-bottom-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-y-indigo-50\/35 {
  border-top-color: rgb(238 242 255 / 0.35) !important;
  border-bottom-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-y-indigo-50\/40 {
  border-top-color: rgb(238 242 255 / 0.4) !important;
  border-bottom-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-y-indigo-50\/45 {
  border-top-color: rgb(238 242 255 / 0.45) !important;
  border-bottom-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-y-indigo-50\/5 {
  border-top-color: rgb(238 242 255 / 0.05) !important;
  border-bottom-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-y-indigo-50\/50 {
  border-top-color: rgb(238 242 255 / 0.5) !important;
  border-bottom-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-y-indigo-50\/55 {
  border-top-color: rgb(238 242 255 / 0.55) !important;
  border-bottom-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-y-indigo-50\/60 {
  border-top-color: rgb(238 242 255 / 0.6) !important;
  border-bottom-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-y-indigo-50\/65 {
  border-top-color: rgb(238 242 255 / 0.65) !important;
  border-bottom-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-y-indigo-50\/70 {
  border-top-color: rgb(238 242 255 / 0.7) !important;
  border-bottom-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-y-indigo-50\/75 {
  border-top-color: rgb(238 242 255 / 0.75) !important;
  border-bottom-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-y-indigo-50\/80 {
  border-top-color: rgb(238 242 255 / 0.8) !important;
  border-bottom-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-y-indigo-50\/85 {
  border-top-color: rgb(238 242 255 / 0.85) !important;
  border-bottom-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-y-indigo-50\/90 {
  border-top-color: rgb(238 242 255 / 0.9) !important;
  border-bottom-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-y-indigo-50\/95 {
  border-top-color: rgb(238 242 255 / 0.95) !important;
  border-bottom-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-y-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-500\/0 {
  border-top-color: rgb(99 102 241 / 0) !important;
  border-bottom-color: rgb(99 102 241 / 0) !important;
}

.tw-border-y-indigo-500\/10 {
  border-top-color: rgb(99 102 241 / 0.1) !important;
  border-bottom-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-y-indigo-500\/100 {
  border-top-color: rgb(99 102 241 / 1) !important;
  border-bottom-color: rgb(99 102 241 / 1) !important;
}

.tw-border-y-indigo-500\/15 {
  border-top-color: rgb(99 102 241 / 0.15) !important;
  border-bottom-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-y-indigo-500\/20 {
  border-top-color: rgb(99 102 241 / 0.2) !important;
  border-bottom-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-y-indigo-500\/25 {
  border-top-color: rgb(99 102 241 / 0.25) !important;
  border-bottom-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-y-indigo-500\/30 {
  border-top-color: rgb(99 102 241 / 0.3) !important;
  border-bottom-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-y-indigo-500\/35 {
  border-top-color: rgb(99 102 241 / 0.35) !important;
  border-bottom-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-y-indigo-500\/40 {
  border-top-color: rgb(99 102 241 / 0.4) !important;
  border-bottom-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-y-indigo-500\/45 {
  border-top-color: rgb(99 102 241 / 0.45) !important;
  border-bottom-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-y-indigo-500\/5 {
  border-top-color: rgb(99 102 241 / 0.05) !important;
  border-bottom-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-y-indigo-500\/50 {
  border-top-color: rgb(99 102 241 / 0.5) !important;
  border-bottom-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-y-indigo-500\/55 {
  border-top-color: rgb(99 102 241 / 0.55) !important;
  border-bottom-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-y-indigo-500\/60 {
  border-top-color: rgb(99 102 241 / 0.6) !important;
  border-bottom-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-y-indigo-500\/65 {
  border-top-color: rgb(99 102 241 / 0.65) !important;
  border-bottom-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-y-indigo-500\/70 {
  border-top-color: rgb(99 102 241 / 0.7) !important;
  border-bottom-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-y-indigo-500\/75 {
  border-top-color: rgb(99 102 241 / 0.75) !important;
  border-bottom-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-y-indigo-500\/80 {
  border-top-color: rgb(99 102 241 / 0.8) !important;
  border-bottom-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-y-indigo-500\/85 {
  border-top-color: rgb(99 102 241 / 0.85) !important;
  border-bottom-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-y-indigo-500\/90 {
  border-top-color: rgb(99 102 241 / 0.9) !important;
  border-bottom-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-y-indigo-500\/95 {
  border-top-color: rgb(99 102 241 / 0.95) !important;
  border-bottom-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-y-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-600\/0 {
  border-top-color: rgb(79 70 229 / 0) !important;
  border-bottom-color: rgb(79 70 229 / 0) !important;
}

.tw-border-y-indigo-600\/10 {
  border-top-color: rgb(79 70 229 / 0.1) !important;
  border-bottom-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-y-indigo-600\/100 {
  border-top-color: rgb(79 70 229 / 1) !important;
  border-bottom-color: rgb(79 70 229 / 1) !important;
}

.tw-border-y-indigo-600\/15 {
  border-top-color: rgb(79 70 229 / 0.15) !important;
  border-bottom-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-y-indigo-600\/20 {
  border-top-color: rgb(79 70 229 / 0.2) !important;
  border-bottom-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-y-indigo-600\/25 {
  border-top-color: rgb(79 70 229 / 0.25) !important;
  border-bottom-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-y-indigo-600\/30 {
  border-top-color: rgb(79 70 229 / 0.3) !important;
  border-bottom-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-y-indigo-600\/35 {
  border-top-color: rgb(79 70 229 / 0.35) !important;
  border-bottom-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-y-indigo-600\/40 {
  border-top-color: rgb(79 70 229 / 0.4) !important;
  border-bottom-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-y-indigo-600\/45 {
  border-top-color: rgb(79 70 229 / 0.45) !important;
  border-bottom-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-y-indigo-600\/5 {
  border-top-color: rgb(79 70 229 / 0.05) !important;
  border-bottom-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-y-indigo-600\/50 {
  border-top-color: rgb(79 70 229 / 0.5) !important;
  border-bottom-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-y-indigo-600\/55 {
  border-top-color: rgb(79 70 229 / 0.55) !important;
  border-bottom-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-y-indigo-600\/60 {
  border-top-color: rgb(79 70 229 / 0.6) !important;
  border-bottom-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-y-indigo-600\/65 {
  border-top-color: rgb(79 70 229 / 0.65) !important;
  border-bottom-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-y-indigo-600\/70 {
  border-top-color: rgb(79 70 229 / 0.7) !important;
  border-bottom-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-y-indigo-600\/75 {
  border-top-color: rgb(79 70 229 / 0.75) !important;
  border-bottom-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-y-indigo-600\/80 {
  border-top-color: rgb(79 70 229 / 0.8) !important;
  border-bottom-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-y-indigo-600\/85 {
  border-top-color: rgb(79 70 229 / 0.85) !important;
  border-bottom-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-y-indigo-600\/90 {
  border-top-color: rgb(79 70 229 / 0.9) !important;
  border-bottom-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-y-indigo-600\/95 {
  border-top-color: rgb(79 70 229 / 0.95) !important;
  border-bottom-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-y-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-700\/0 {
  border-top-color: rgb(67 56 202 / 0) !important;
  border-bottom-color: rgb(67 56 202 / 0) !important;
}

.tw-border-y-indigo-700\/10 {
  border-top-color: rgb(67 56 202 / 0.1) !important;
  border-bottom-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-y-indigo-700\/100 {
  border-top-color: rgb(67 56 202 / 1) !important;
  border-bottom-color: rgb(67 56 202 / 1) !important;
}

.tw-border-y-indigo-700\/15 {
  border-top-color: rgb(67 56 202 / 0.15) !important;
  border-bottom-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-y-indigo-700\/20 {
  border-top-color: rgb(67 56 202 / 0.2) !important;
  border-bottom-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-y-indigo-700\/25 {
  border-top-color: rgb(67 56 202 / 0.25) !important;
  border-bottom-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-y-indigo-700\/30 {
  border-top-color: rgb(67 56 202 / 0.3) !important;
  border-bottom-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-y-indigo-700\/35 {
  border-top-color: rgb(67 56 202 / 0.35) !important;
  border-bottom-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-y-indigo-700\/40 {
  border-top-color: rgb(67 56 202 / 0.4) !important;
  border-bottom-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-y-indigo-700\/45 {
  border-top-color: rgb(67 56 202 / 0.45) !important;
  border-bottom-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-y-indigo-700\/5 {
  border-top-color: rgb(67 56 202 / 0.05) !important;
  border-bottom-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-y-indigo-700\/50 {
  border-top-color: rgb(67 56 202 / 0.5) !important;
  border-bottom-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-y-indigo-700\/55 {
  border-top-color: rgb(67 56 202 / 0.55) !important;
  border-bottom-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-y-indigo-700\/60 {
  border-top-color: rgb(67 56 202 / 0.6) !important;
  border-bottom-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-y-indigo-700\/65 {
  border-top-color: rgb(67 56 202 / 0.65) !important;
  border-bottom-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-y-indigo-700\/70 {
  border-top-color: rgb(67 56 202 / 0.7) !important;
  border-bottom-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-y-indigo-700\/75 {
  border-top-color: rgb(67 56 202 / 0.75) !important;
  border-bottom-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-y-indigo-700\/80 {
  border-top-color: rgb(67 56 202 / 0.8) !important;
  border-bottom-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-y-indigo-700\/85 {
  border-top-color: rgb(67 56 202 / 0.85) !important;
  border-bottom-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-y-indigo-700\/90 {
  border-top-color: rgb(67 56 202 / 0.9) !important;
  border-bottom-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-y-indigo-700\/95 {
  border-top-color: rgb(67 56 202 / 0.95) !important;
  border-bottom-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-y-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-800\/0 {
  border-top-color: rgb(55 48 163 / 0) !important;
  border-bottom-color: rgb(55 48 163 / 0) !important;
}

.tw-border-y-indigo-800\/10 {
  border-top-color: rgb(55 48 163 / 0.1) !important;
  border-bottom-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-y-indigo-800\/100 {
  border-top-color: rgb(55 48 163 / 1) !important;
  border-bottom-color: rgb(55 48 163 / 1) !important;
}

.tw-border-y-indigo-800\/15 {
  border-top-color: rgb(55 48 163 / 0.15) !important;
  border-bottom-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-y-indigo-800\/20 {
  border-top-color: rgb(55 48 163 / 0.2) !important;
  border-bottom-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-y-indigo-800\/25 {
  border-top-color: rgb(55 48 163 / 0.25) !important;
  border-bottom-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-y-indigo-800\/30 {
  border-top-color: rgb(55 48 163 / 0.3) !important;
  border-bottom-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-y-indigo-800\/35 {
  border-top-color: rgb(55 48 163 / 0.35) !important;
  border-bottom-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-y-indigo-800\/40 {
  border-top-color: rgb(55 48 163 / 0.4) !important;
  border-bottom-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-y-indigo-800\/45 {
  border-top-color: rgb(55 48 163 / 0.45) !important;
  border-bottom-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-y-indigo-800\/5 {
  border-top-color: rgb(55 48 163 / 0.05) !important;
  border-bottom-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-y-indigo-800\/50 {
  border-top-color: rgb(55 48 163 / 0.5) !important;
  border-bottom-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-y-indigo-800\/55 {
  border-top-color: rgb(55 48 163 / 0.55) !important;
  border-bottom-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-y-indigo-800\/60 {
  border-top-color: rgb(55 48 163 / 0.6) !important;
  border-bottom-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-y-indigo-800\/65 {
  border-top-color: rgb(55 48 163 / 0.65) !important;
  border-bottom-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-y-indigo-800\/70 {
  border-top-color: rgb(55 48 163 / 0.7) !important;
  border-bottom-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-y-indigo-800\/75 {
  border-top-color: rgb(55 48 163 / 0.75) !important;
  border-bottom-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-y-indigo-800\/80 {
  border-top-color: rgb(55 48 163 / 0.8) !important;
  border-bottom-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-y-indigo-800\/85 {
  border-top-color: rgb(55 48 163 / 0.85) !important;
  border-bottom-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-y-indigo-800\/90 {
  border-top-color: rgb(55 48 163 / 0.9) !important;
  border-bottom-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-y-indigo-800\/95 {
  border-top-color: rgb(55 48 163 / 0.95) !important;
  border-bottom-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-y-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-900\/0 {
  border-top-color: rgb(49 46 129 / 0) !important;
  border-bottom-color: rgb(49 46 129 / 0) !important;
}

.tw-border-y-indigo-900\/10 {
  border-top-color: rgb(49 46 129 / 0.1) !important;
  border-bottom-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-y-indigo-900\/100 {
  border-top-color: rgb(49 46 129 / 1) !important;
  border-bottom-color: rgb(49 46 129 / 1) !important;
}

.tw-border-y-indigo-900\/15 {
  border-top-color: rgb(49 46 129 / 0.15) !important;
  border-bottom-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-y-indigo-900\/20 {
  border-top-color: rgb(49 46 129 / 0.2) !important;
  border-bottom-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-y-indigo-900\/25 {
  border-top-color: rgb(49 46 129 / 0.25) !important;
  border-bottom-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-y-indigo-900\/30 {
  border-top-color: rgb(49 46 129 / 0.3) !important;
  border-bottom-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-y-indigo-900\/35 {
  border-top-color: rgb(49 46 129 / 0.35) !important;
  border-bottom-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-y-indigo-900\/40 {
  border-top-color: rgb(49 46 129 / 0.4) !important;
  border-bottom-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-y-indigo-900\/45 {
  border-top-color: rgb(49 46 129 / 0.45) !important;
  border-bottom-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-y-indigo-900\/5 {
  border-top-color: rgb(49 46 129 / 0.05) !important;
  border-bottom-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-y-indigo-900\/50 {
  border-top-color: rgb(49 46 129 / 0.5) !important;
  border-bottom-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-y-indigo-900\/55 {
  border-top-color: rgb(49 46 129 / 0.55) !important;
  border-bottom-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-y-indigo-900\/60 {
  border-top-color: rgb(49 46 129 / 0.6) !important;
  border-bottom-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-y-indigo-900\/65 {
  border-top-color: rgb(49 46 129 / 0.65) !important;
  border-bottom-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-y-indigo-900\/70 {
  border-top-color: rgb(49 46 129 / 0.7) !important;
  border-bottom-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-y-indigo-900\/75 {
  border-top-color: rgb(49 46 129 / 0.75) !important;
  border-bottom-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-y-indigo-900\/80 {
  border-top-color: rgb(49 46 129 / 0.8) !important;
  border-bottom-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-y-indigo-900\/85 {
  border-top-color: rgb(49 46 129 / 0.85) !important;
  border-bottom-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-y-indigo-900\/90 {
  border-top-color: rgb(49 46 129 / 0.9) !important;
  border-bottom-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-y-indigo-900\/95 {
  border-top-color: rgb(49 46 129 / 0.95) !important;
  border-bottom-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-y-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-y-indigo-950\/0 {
  border-top-color: rgb(30 27 75 / 0) !important;
  border-bottom-color: rgb(30 27 75 / 0) !important;
}

.tw-border-y-indigo-950\/10 {
  border-top-color: rgb(30 27 75 / 0.1) !important;
  border-bottom-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-y-indigo-950\/100 {
  border-top-color: rgb(30 27 75 / 1) !important;
  border-bottom-color: rgb(30 27 75 / 1) !important;
}

.tw-border-y-indigo-950\/15 {
  border-top-color: rgb(30 27 75 / 0.15) !important;
  border-bottom-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-y-indigo-950\/20 {
  border-top-color: rgb(30 27 75 / 0.2) !important;
  border-bottom-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-y-indigo-950\/25 {
  border-top-color: rgb(30 27 75 / 0.25) !important;
  border-bottom-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-y-indigo-950\/30 {
  border-top-color: rgb(30 27 75 / 0.3) !important;
  border-bottom-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-y-indigo-950\/35 {
  border-top-color: rgb(30 27 75 / 0.35) !important;
  border-bottom-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-y-indigo-950\/40 {
  border-top-color: rgb(30 27 75 / 0.4) !important;
  border-bottom-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-y-indigo-950\/45 {
  border-top-color: rgb(30 27 75 / 0.45) !important;
  border-bottom-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-y-indigo-950\/5 {
  border-top-color: rgb(30 27 75 / 0.05) !important;
  border-bottom-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-y-indigo-950\/50 {
  border-top-color: rgb(30 27 75 / 0.5) !important;
  border-bottom-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-y-indigo-950\/55 {
  border-top-color: rgb(30 27 75 / 0.55) !important;
  border-bottom-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-y-indigo-950\/60 {
  border-top-color: rgb(30 27 75 / 0.6) !important;
  border-bottom-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-y-indigo-950\/65 {
  border-top-color: rgb(30 27 75 / 0.65) !important;
  border-bottom-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-y-indigo-950\/70 {
  border-top-color: rgb(30 27 75 / 0.7) !important;
  border-bottom-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-y-indigo-950\/75 {
  border-top-color: rgb(30 27 75 / 0.75) !important;
  border-bottom-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-y-indigo-950\/80 {
  border-top-color: rgb(30 27 75 / 0.8) !important;
  border-bottom-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-y-indigo-950\/85 {
  border-top-color: rgb(30 27 75 / 0.85) !important;
  border-bottom-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-y-indigo-950\/90 {
  border-top-color: rgb(30 27 75 / 0.9) !important;
  border-bottom-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-y-indigo-950\/95 {
  border-top-color: rgb(30 27 75 / 0.95) !important;
  border-bottom-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-y-inherit {
  border-top-color: inherit !important;
  border-bottom-color: inherit !important;
}

.tw-border-y-lime-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-100\/0 {
  border-top-color: rgb(236 252 203 / 0) !important;
  border-bottom-color: rgb(236 252 203 / 0) !important;
}

.tw-border-y-lime-100\/10 {
  border-top-color: rgb(236 252 203 / 0.1) !important;
  border-bottom-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-y-lime-100\/100 {
  border-top-color: rgb(236 252 203 / 1) !important;
  border-bottom-color: rgb(236 252 203 / 1) !important;
}

.tw-border-y-lime-100\/15 {
  border-top-color: rgb(236 252 203 / 0.15) !important;
  border-bottom-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-y-lime-100\/20 {
  border-top-color: rgb(236 252 203 / 0.2) !important;
  border-bottom-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-y-lime-100\/25 {
  border-top-color: rgb(236 252 203 / 0.25) !important;
  border-bottom-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-y-lime-100\/30 {
  border-top-color: rgb(236 252 203 / 0.3) !important;
  border-bottom-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-y-lime-100\/35 {
  border-top-color: rgb(236 252 203 / 0.35) !important;
  border-bottom-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-y-lime-100\/40 {
  border-top-color: rgb(236 252 203 / 0.4) !important;
  border-bottom-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-y-lime-100\/45 {
  border-top-color: rgb(236 252 203 / 0.45) !important;
  border-bottom-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-y-lime-100\/5 {
  border-top-color: rgb(236 252 203 / 0.05) !important;
  border-bottom-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-y-lime-100\/50 {
  border-top-color: rgb(236 252 203 / 0.5) !important;
  border-bottom-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-y-lime-100\/55 {
  border-top-color: rgb(236 252 203 / 0.55) !important;
  border-bottom-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-y-lime-100\/60 {
  border-top-color: rgb(236 252 203 / 0.6) !important;
  border-bottom-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-y-lime-100\/65 {
  border-top-color: rgb(236 252 203 / 0.65) !important;
  border-bottom-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-y-lime-100\/70 {
  border-top-color: rgb(236 252 203 / 0.7) !important;
  border-bottom-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-y-lime-100\/75 {
  border-top-color: rgb(236 252 203 / 0.75) !important;
  border-bottom-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-y-lime-100\/80 {
  border-top-color: rgb(236 252 203 / 0.8) !important;
  border-bottom-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-y-lime-100\/85 {
  border-top-color: rgb(236 252 203 / 0.85) !important;
  border-bottom-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-y-lime-100\/90 {
  border-top-color: rgb(236 252 203 / 0.9) !important;
  border-bottom-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-y-lime-100\/95 {
  border-top-color: rgb(236 252 203 / 0.95) !important;
  border-bottom-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-y-lime-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-200\/0 {
  border-top-color: rgb(217 249 157 / 0) !important;
  border-bottom-color: rgb(217 249 157 / 0) !important;
}

.tw-border-y-lime-200\/10 {
  border-top-color: rgb(217 249 157 / 0.1) !important;
  border-bottom-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-y-lime-200\/100 {
  border-top-color: rgb(217 249 157 / 1) !important;
  border-bottom-color: rgb(217 249 157 / 1) !important;
}

.tw-border-y-lime-200\/15 {
  border-top-color: rgb(217 249 157 / 0.15) !important;
  border-bottom-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-y-lime-200\/20 {
  border-top-color: rgb(217 249 157 / 0.2) !important;
  border-bottom-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-y-lime-200\/25 {
  border-top-color: rgb(217 249 157 / 0.25) !important;
  border-bottom-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-y-lime-200\/30 {
  border-top-color: rgb(217 249 157 / 0.3) !important;
  border-bottom-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-y-lime-200\/35 {
  border-top-color: rgb(217 249 157 / 0.35) !important;
  border-bottom-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-y-lime-200\/40 {
  border-top-color: rgb(217 249 157 / 0.4) !important;
  border-bottom-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-y-lime-200\/45 {
  border-top-color: rgb(217 249 157 / 0.45) !important;
  border-bottom-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-y-lime-200\/5 {
  border-top-color: rgb(217 249 157 / 0.05) !important;
  border-bottom-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-y-lime-200\/50 {
  border-top-color: rgb(217 249 157 / 0.5) !important;
  border-bottom-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-y-lime-200\/55 {
  border-top-color: rgb(217 249 157 / 0.55) !important;
  border-bottom-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-y-lime-200\/60 {
  border-top-color: rgb(217 249 157 / 0.6) !important;
  border-bottom-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-y-lime-200\/65 {
  border-top-color: rgb(217 249 157 / 0.65) !important;
  border-bottom-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-y-lime-200\/70 {
  border-top-color: rgb(217 249 157 / 0.7) !important;
  border-bottom-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-y-lime-200\/75 {
  border-top-color: rgb(217 249 157 / 0.75) !important;
  border-bottom-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-y-lime-200\/80 {
  border-top-color: rgb(217 249 157 / 0.8) !important;
  border-bottom-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-y-lime-200\/85 {
  border-top-color: rgb(217 249 157 / 0.85) !important;
  border-bottom-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-y-lime-200\/90 {
  border-top-color: rgb(217 249 157 / 0.9) !important;
  border-bottom-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-y-lime-200\/95 {
  border-top-color: rgb(217 249 157 / 0.95) !important;
  border-bottom-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-y-lime-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-300\/0 {
  border-top-color: rgb(190 242 100 / 0) !important;
  border-bottom-color: rgb(190 242 100 / 0) !important;
}

.tw-border-y-lime-300\/10 {
  border-top-color: rgb(190 242 100 / 0.1) !important;
  border-bottom-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-y-lime-300\/100 {
  border-top-color: rgb(190 242 100 / 1) !important;
  border-bottom-color: rgb(190 242 100 / 1) !important;
}

.tw-border-y-lime-300\/15 {
  border-top-color: rgb(190 242 100 / 0.15) !important;
  border-bottom-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-y-lime-300\/20 {
  border-top-color: rgb(190 242 100 / 0.2) !important;
  border-bottom-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-y-lime-300\/25 {
  border-top-color: rgb(190 242 100 / 0.25) !important;
  border-bottom-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-y-lime-300\/30 {
  border-top-color: rgb(190 242 100 / 0.3) !important;
  border-bottom-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-y-lime-300\/35 {
  border-top-color: rgb(190 242 100 / 0.35) !important;
  border-bottom-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-y-lime-300\/40 {
  border-top-color: rgb(190 242 100 / 0.4) !important;
  border-bottom-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-y-lime-300\/45 {
  border-top-color: rgb(190 242 100 / 0.45) !important;
  border-bottom-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-y-lime-300\/5 {
  border-top-color: rgb(190 242 100 / 0.05) !important;
  border-bottom-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-y-lime-300\/50 {
  border-top-color: rgb(190 242 100 / 0.5) !important;
  border-bottom-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-y-lime-300\/55 {
  border-top-color: rgb(190 242 100 / 0.55) !important;
  border-bottom-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-y-lime-300\/60 {
  border-top-color: rgb(190 242 100 / 0.6) !important;
  border-bottom-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-y-lime-300\/65 {
  border-top-color: rgb(190 242 100 / 0.65) !important;
  border-bottom-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-y-lime-300\/70 {
  border-top-color: rgb(190 242 100 / 0.7) !important;
  border-bottom-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-y-lime-300\/75 {
  border-top-color: rgb(190 242 100 / 0.75) !important;
  border-bottom-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-y-lime-300\/80 {
  border-top-color: rgb(190 242 100 / 0.8) !important;
  border-bottom-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-y-lime-300\/85 {
  border-top-color: rgb(190 242 100 / 0.85) !important;
  border-bottom-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-y-lime-300\/90 {
  border-top-color: rgb(190 242 100 / 0.9) !important;
  border-bottom-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-y-lime-300\/95 {
  border-top-color: rgb(190 242 100 / 0.95) !important;
  border-bottom-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-y-lime-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-400\/0 {
  border-top-color: rgb(163 230 53 / 0) !important;
  border-bottom-color: rgb(163 230 53 / 0) !important;
}

.tw-border-y-lime-400\/10 {
  border-top-color: rgb(163 230 53 / 0.1) !important;
  border-bottom-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-y-lime-400\/100 {
  border-top-color: rgb(163 230 53 / 1) !important;
  border-bottom-color: rgb(163 230 53 / 1) !important;
}

.tw-border-y-lime-400\/15 {
  border-top-color: rgb(163 230 53 / 0.15) !important;
  border-bottom-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-y-lime-400\/20 {
  border-top-color: rgb(163 230 53 / 0.2) !important;
  border-bottom-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-y-lime-400\/25 {
  border-top-color: rgb(163 230 53 / 0.25) !important;
  border-bottom-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-y-lime-400\/30 {
  border-top-color: rgb(163 230 53 / 0.3) !important;
  border-bottom-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-y-lime-400\/35 {
  border-top-color: rgb(163 230 53 / 0.35) !important;
  border-bottom-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-y-lime-400\/40 {
  border-top-color: rgb(163 230 53 / 0.4) !important;
  border-bottom-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-y-lime-400\/45 {
  border-top-color: rgb(163 230 53 / 0.45) !important;
  border-bottom-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-y-lime-400\/5 {
  border-top-color: rgb(163 230 53 / 0.05) !important;
  border-bottom-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-y-lime-400\/50 {
  border-top-color: rgb(163 230 53 / 0.5) !important;
  border-bottom-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-y-lime-400\/55 {
  border-top-color: rgb(163 230 53 / 0.55) !important;
  border-bottom-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-y-lime-400\/60 {
  border-top-color: rgb(163 230 53 / 0.6) !important;
  border-bottom-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-y-lime-400\/65 {
  border-top-color: rgb(163 230 53 / 0.65) !important;
  border-bottom-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-y-lime-400\/70 {
  border-top-color: rgb(163 230 53 / 0.7) !important;
  border-bottom-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-y-lime-400\/75 {
  border-top-color: rgb(163 230 53 / 0.75) !important;
  border-bottom-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-y-lime-400\/80 {
  border-top-color: rgb(163 230 53 / 0.8) !important;
  border-bottom-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-y-lime-400\/85 {
  border-top-color: rgb(163 230 53 / 0.85) !important;
  border-bottom-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-y-lime-400\/90 {
  border-top-color: rgb(163 230 53 / 0.9) !important;
  border-bottom-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-y-lime-400\/95 {
  border-top-color: rgb(163 230 53 / 0.95) !important;
  border-bottom-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-y-lime-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-50\/0 {
  border-top-color: rgb(247 254 231 / 0) !important;
  border-bottom-color: rgb(247 254 231 / 0) !important;
}

.tw-border-y-lime-50\/10 {
  border-top-color: rgb(247 254 231 / 0.1) !important;
  border-bottom-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-y-lime-50\/100 {
  border-top-color: rgb(247 254 231 / 1) !important;
  border-bottom-color: rgb(247 254 231 / 1) !important;
}

.tw-border-y-lime-50\/15 {
  border-top-color: rgb(247 254 231 / 0.15) !important;
  border-bottom-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-y-lime-50\/20 {
  border-top-color: rgb(247 254 231 / 0.2) !important;
  border-bottom-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-y-lime-50\/25 {
  border-top-color: rgb(247 254 231 / 0.25) !important;
  border-bottom-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-y-lime-50\/30 {
  border-top-color: rgb(247 254 231 / 0.3) !important;
  border-bottom-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-y-lime-50\/35 {
  border-top-color: rgb(247 254 231 / 0.35) !important;
  border-bottom-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-y-lime-50\/40 {
  border-top-color: rgb(247 254 231 / 0.4) !important;
  border-bottom-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-y-lime-50\/45 {
  border-top-color: rgb(247 254 231 / 0.45) !important;
  border-bottom-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-y-lime-50\/5 {
  border-top-color: rgb(247 254 231 / 0.05) !important;
  border-bottom-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-y-lime-50\/50 {
  border-top-color: rgb(247 254 231 / 0.5) !important;
  border-bottom-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-y-lime-50\/55 {
  border-top-color: rgb(247 254 231 / 0.55) !important;
  border-bottom-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-y-lime-50\/60 {
  border-top-color: rgb(247 254 231 / 0.6) !important;
  border-bottom-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-y-lime-50\/65 {
  border-top-color: rgb(247 254 231 / 0.65) !important;
  border-bottom-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-y-lime-50\/70 {
  border-top-color: rgb(247 254 231 / 0.7) !important;
  border-bottom-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-y-lime-50\/75 {
  border-top-color: rgb(247 254 231 / 0.75) !important;
  border-bottom-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-y-lime-50\/80 {
  border-top-color: rgb(247 254 231 / 0.8) !important;
  border-bottom-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-y-lime-50\/85 {
  border-top-color: rgb(247 254 231 / 0.85) !important;
  border-bottom-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-y-lime-50\/90 {
  border-top-color: rgb(247 254 231 / 0.9) !important;
  border-bottom-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-y-lime-50\/95 {
  border-top-color: rgb(247 254 231 / 0.95) !important;
  border-bottom-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-y-lime-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-500\/0 {
  border-top-color: rgb(132 204 22 / 0) !important;
  border-bottom-color: rgb(132 204 22 / 0) !important;
}

.tw-border-y-lime-500\/10 {
  border-top-color: rgb(132 204 22 / 0.1) !important;
  border-bottom-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-y-lime-500\/100 {
  border-top-color: rgb(132 204 22 / 1) !important;
  border-bottom-color: rgb(132 204 22 / 1) !important;
}

.tw-border-y-lime-500\/15 {
  border-top-color: rgb(132 204 22 / 0.15) !important;
  border-bottom-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-y-lime-500\/20 {
  border-top-color: rgb(132 204 22 / 0.2) !important;
  border-bottom-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-y-lime-500\/25 {
  border-top-color: rgb(132 204 22 / 0.25) !important;
  border-bottom-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-y-lime-500\/30 {
  border-top-color: rgb(132 204 22 / 0.3) !important;
  border-bottom-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-y-lime-500\/35 {
  border-top-color: rgb(132 204 22 / 0.35) !important;
  border-bottom-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-y-lime-500\/40 {
  border-top-color: rgb(132 204 22 / 0.4) !important;
  border-bottom-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-y-lime-500\/45 {
  border-top-color: rgb(132 204 22 / 0.45) !important;
  border-bottom-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-y-lime-500\/5 {
  border-top-color: rgb(132 204 22 / 0.05) !important;
  border-bottom-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-y-lime-500\/50 {
  border-top-color: rgb(132 204 22 / 0.5) !important;
  border-bottom-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-y-lime-500\/55 {
  border-top-color: rgb(132 204 22 / 0.55) !important;
  border-bottom-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-y-lime-500\/60 {
  border-top-color: rgb(132 204 22 / 0.6) !important;
  border-bottom-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-y-lime-500\/65 {
  border-top-color: rgb(132 204 22 / 0.65) !important;
  border-bottom-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-y-lime-500\/70 {
  border-top-color: rgb(132 204 22 / 0.7) !important;
  border-bottom-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-y-lime-500\/75 {
  border-top-color: rgb(132 204 22 / 0.75) !important;
  border-bottom-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-y-lime-500\/80 {
  border-top-color: rgb(132 204 22 / 0.8) !important;
  border-bottom-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-y-lime-500\/85 {
  border-top-color: rgb(132 204 22 / 0.85) !important;
  border-bottom-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-y-lime-500\/90 {
  border-top-color: rgb(132 204 22 / 0.9) !important;
  border-bottom-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-y-lime-500\/95 {
  border-top-color: rgb(132 204 22 / 0.95) !important;
  border-bottom-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-y-lime-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-600\/0 {
  border-top-color: rgb(101 163 13 / 0) !important;
  border-bottom-color: rgb(101 163 13 / 0) !important;
}

.tw-border-y-lime-600\/10 {
  border-top-color: rgb(101 163 13 / 0.1) !important;
  border-bottom-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-y-lime-600\/100 {
  border-top-color: rgb(101 163 13 / 1) !important;
  border-bottom-color: rgb(101 163 13 / 1) !important;
}

.tw-border-y-lime-600\/15 {
  border-top-color: rgb(101 163 13 / 0.15) !important;
  border-bottom-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-y-lime-600\/20 {
  border-top-color: rgb(101 163 13 / 0.2) !important;
  border-bottom-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-y-lime-600\/25 {
  border-top-color: rgb(101 163 13 / 0.25) !important;
  border-bottom-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-y-lime-600\/30 {
  border-top-color: rgb(101 163 13 / 0.3) !important;
  border-bottom-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-y-lime-600\/35 {
  border-top-color: rgb(101 163 13 / 0.35) !important;
  border-bottom-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-y-lime-600\/40 {
  border-top-color: rgb(101 163 13 / 0.4) !important;
  border-bottom-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-y-lime-600\/45 {
  border-top-color: rgb(101 163 13 / 0.45) !important;
  border-bottom-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-y-lime-600\/5 {
  border-top-color: rgb(101 163 13 / 0.05) !important;
  border-bottom-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-y-lime-600\/50 {
  border-top-color: rgb(101 163 13 / 0.5) !important;
  border-bottom-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-y-lime-600\/55 {
  border-top-color: rgb(101 163 13 / 0.55) !important;
  border-bottom-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-y-lime-600\/60 {
  border-top-color: rgb(101 163 13 / 0.6) !important;
  border-bottom-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-y-lime-600\/65 {
  border-top-color: rgb(101 163 13 / 0.65) !important;
  border-bottom-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-y-lime-600\/70 {
  border-top-color: rgb(101 163 13 / 0.7) !important;
  border-bottom-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-y-lime-600\/75 {
  border-top-color: rgb(101 163 13 / 0.75) !important;
  border-bottom-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-y-lime-600\/80 {
  border-top-color: rgb(101 163 13 / 0.8) !important;
  border-bottom-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-y-lime-600\/85 {
  border-top-color: rgb(101 163 13 / 0.85) !important;
  border-bottom-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-y-lime-600\/90 {
  border-top-color: rgb(101 163 13 / 0.9) !important;
  border-bottom-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-y-lime-600\/95 {
  border-top-color: rgb(101 163 13 / 0.95) !important;
  border-bottom-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-y-lime-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-700\/0 {
  border-top-color: rgb(77 124 15 / 0) !important;
  border-bottom-color: rgb(77 124 15 / 0) !important;
}

.tw-border-y-lime-700\/10 {
  border-top-color: rgb(77 124 15 / 0.1) !important;
  border-bottom-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-y-lime-700\/100 {
  border-top-color: rgb(77 124 15 / 1) !important;
  border-bottom-color: rgb(77 124 15 / 1) !important;
}

.tw-border-y-lime-700\/15 {
  border-top-color: rgb(77 124 15 / 0.15) !important;
  border-bottom-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-y-lime-700\/20 {
  border-top-color: rgb(77 124 15 / 0.2) !important;
  border-bottom-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-y-lime-700\/25 {
  border-top-color: rgb(77 124 15 / 0.25) !important;
  border-bottom-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-y-lime-700\/30 {
  border-top-color: rgb(77 124 15 / 0.3) !important;
  border-bottom-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-y-lime-700\/35 {
  border-top-color: rgb(77 124 15 / 0.35) !important;
  border-bottom-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-y-lime-700\/40 {
  border-top-color: rgb(77 124 15 / 0.4) !important;
  border-bottom-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-y-lime-700\/45 {
  border-top-color: rgb(77 124 15 / 0.45) !important;
  border-bottom-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-y-lime-700\/5 {
  border-top-color: rgb(77 124 15 / 0.05) !important;
  border-bottom-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-y-lime-700\/50 {
  border-top-color: rgb(77 124 15 / 0.5) !important;
  border-bottom-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-y-lime-700\/55 {
  border-top-color: rgb(77 124 15 / 0.55) !important;
  border-bottom-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-y-lime-700\/60 {
  border-top-color: rgb(77 124 15 / 0.6) !important;
  border-bottom-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-y-lime-700\/65 {
  border-top-color: rgb(77 124 15 / 0.65) !important;
  border-bottom-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-y-lime-700\/70 {
  border-top-color: rgb(77 124 15 / 0.7) !important;
  border-bottom-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-y-lime-700\/75 {
  border-top-color: rgb(77 124 15 / 0.75) !important;
  border-bottom-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-y-lime-700\/80 {
  border-top-color: rgb(77 124 15 / 0.8) !important;
  border-bottom-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-y-lime-700\/85 {
  border-top-color: rgb(77 124 15 / 0.85) !important;
  border-bottom-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-y-lime-700\/90 {
  border-top-color: rgb(77 124 15 / 0.9) !important;
  border-bottom-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-y-lime-700\/95 {
  border-top-color: rgb(77 124 15 / 0.95) !important;
  border-bottom-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-y-lime-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-800\/0 {
  border-top-color: rgb(63 98 18 / 0) !important;
  border-bottom-color: rgb(63 98 18 / 0) !important;
}

.tw-border-y-lime-800\/10 {
  border-top-color: rgb(63 98 18 / 0.1) !important;
  border-bottom-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-y-lime-800\/100 {
  border-top-color: rgb(63 98 18 / 1) !important;
  border-bottom-color: rgb(63 98 18 / 1) !important;
}

.tw-border-y-lime-800\/15 {
  border-top-color: rgb(63 98 18 / 0.15) !important;
  border-bottom-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-y-lime-800\/20 {
  border-top-color: rgb(63 98 18 / 0.2) !important;
  border-bottom-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-y-lime-800\/25 {
  border-top-color: rgb(63 98 18 / 0.25) !important;
  border-bottom-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-y-lime-800\/30 {
  border-top-color: rgb(63 98 18 / 0.3) !important;
  border-bottom-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-y-lime-800\/35 {
  border-top-color: rgb(63 98 18 / 0.35) !important;
  border-bottom-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-y-lime-800\/40 {
  border-top-color: rgb(63 98 18 / 0.4) !important;
  border-bottom-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-y-lime-800\/45 {
  border-top-color: rgb(63 98 18 / 0.45) !important;
  border-bottom-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-y-lime-800\/5 {
  border-top-color: rgb(63 98 18 / 0.05) !important;
  border-bottom-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-y-lime-800\/50 {
  border-top-color: rgb(63 98 18 / 0.5) !important;
  border-bottom-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-y-lime-800\/55 {
  border-top-color: rgb(63 98 18 / 0.55) !important;
  border-bottom-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-y-lime-800\/60 {
  border-top-color: rgb(63 98 18 / 0.6) !important;
  border-bottom-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-y-lime-800\/65 {
  border-top-color: rgb(63 98 18 / 0.65) !important;
  border-bottom-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-y-lime-800\/70 {
  border-top-color: rgb(63 98 18 / 0.7) !important;
  border-bottom-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-y-lime-800\/75 {
  border-top-color: rgb(63 98 18 / 0.75) !important;
  border-bottom-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-y-lime-800\/80 {
  border-top-color: rgb(63 98 18 / 0.8) !important;
  border-bottom-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-y-lime-800\/85 {
  border-top-color: rgb(63 98 18 / 0.85) !important;
  border-bottom-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-y-lime-800\/90 {
  border-top-color: rgb(63 98 18 / 0.9) !important;
  border-bottom-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-y-lime-800\/95 {
  border-top-color: rgb(63 98 18 / 0.95) !important;
  border-bottom-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-y-lime-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-900\/0 {
  border-top-color: rgb(54 83 20 / 0) !important;
  border-bottom-color: rgb(54 83 20 / 0) !important;
}

.tw-border-y-lime-900\/10 {
  border-top-color: rgb(54 83 20 / 0.1) !important;
  border-bottom-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-y-lime-900\/100 {
  border-top-color: rgb(54 83 20 / 1) !important;
  border-bottom-color: rgb(54 83 20 / 1) !important;
}

.tw-border-y-lime-900\/15 {
  border-top-color: rgb(54 83 20 / 0.15) !important;
  border-bottom-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-y-lime-900\/20 {
  border-top-color: rgb(54 83 20 / 0.2) !important;
  border-bottom-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-y-lime-900\/25 {
  border-top-color: rgb(54 83 20 / 0.25) !important;
  border-bottom-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-y-lime-900\/30 {
  border-top-color: rgb(54 83 20 / 0.3) !important;
  border-bottom-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-y-lime-900\/35 {
  border-top-color: rgb(54 83 20 / 0.35) !important;
  border-bottom-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-y-lime-900\/40 {
  border-top-color: rgb(54 83 20 / 0.4) !important;
  border-bottom-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-y-lime-900\/45 {
  border-top-color: rgb(54 83 20 / 0.45) !important;
  border-bottom-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-y-lime-900\/5 {
  border-top-color: rgb(54 83 20 / 0.05) !important;
  border-bottom-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-y-lime-900\/50 {
  border-top-color: rgb(54 83 20 / 0.5) !important;
  border-bottom-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-y-lime-900\/55 {
  border-top-color: rgb(54 83 20 / 0.55) !important;
  border-bottom-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-y-lime-900\/60 {
  border-top-color: rgb(54 83 20 / 0.6) !important;
  border-bottom-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-y-lime-900\/65 {
  border-top-color: rgb(54 83 20 / 0.65) !important;
  border-bottom-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-y-lime-900\/70 {
  border-top-color: rgb(54 83 20 / 0.7) !important;
  border-bottom-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-y-lime-900\/75 {
  border-top-color: rgb(54 83 20 / 0.75) !important;
  border-bottom-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-y-lime-900\/80 {
  border-top-color: rgb(54 83 20 / 0.8) !important;
  border-bottom-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-y-lime-900\/85 {
  border-top-color: rgb(54 83 20 / 0.85) !important;
  border-bottom-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-y-lime-900\/90 {
  border-top-color: rgb(54 83 20 / 0.9) !important;
  border-bottom-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-y-lime-900\/95 {
  border-top-color: rgb(54 83 20 / 0.95) !important;
  border-bottom-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-y-lime-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-y-lime-950\/0 {
  border-top-color: rgb(26 46 5 / 0) !important;
  border-bottom-color: rgb(26 46 5 / 0) !important;
}

.tw-border-y-lime-950\/10 {
  border-top-color: rgb(26 46 5 / 0.1) !important;
  border-bottom-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-y-lime-950\/100 {
  border-top-color: rgb(26 46 5 / 1) !important;
  border-bottom-color: rgb(26 46 5 / 1) !important;
}

.tw-border-y-lime-950\/15 {
  border-top-color: rgb(26 46 5 / 0.15) !important;
  border-bottom-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-y-lime-950\/20 {
  border-top-color: rgb(26 46 5 / 0.2) !important;
  border-bottom-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-y-lime-950\/25 {
  border-top-color: rgb(26 46 5 / 0.25) !important;
  border-bottom-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-y-lime-950\/30 {
  border-top-color: rgb(26 46 5 / 0.3) !important;
  border-bottom-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-y-lime-950\/35 {
  border-top-color: rgb(26 46 5 / 0.35) !important;
  border-bottom-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-y-lime-950\/40 {
  border-top-color: rgb(26 46 5 / 0.4) !important;
  border-bottom-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-y-lime-950\/45 {
  border-top-color: rgb(26 46 5 / 0.45) !important;
  border-bottom-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-y-lime-950\/5 {
  border-top-color: rgb(26 46 5 / 0.05) !important;
  border-bottom-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-y-lime-950\/50 {
  border-top-color: rgb(26 46 5 / 0.5) !important;
  border-bottom-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-y-lime-950\/55 {
  border-top-color: rgb(26 46 5 / 0.55) !important;
  border-bottom-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-y-lime-950\/60 {
  border-top-color: rgb(26 46 5 / 0.6) !important;
  border-bottom-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-y-lime-950\/65 {
  border-top-color: rgb(26 46 5 / 0.65) !important;
  border-bottom-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-y-lime-950\/70 {
  border-top-color: rgb(26 46 5 / 0.7) !important;
  border-bottom-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-y-lime-950\/75 {
  border-top-color: rgb(26 46 5 / 0.75) !important;
  border-bottom-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-y-lime-950\/80 {
  border-top-color: rgb(26 46 5 / 0.8) !important;
  border-bottom-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-y-lime-950\/85 {
  border-top-color: rgb(26 46 5 / 0.85) !important;
  border-bottom-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-y-lime-950\/90 {
  border-top-color: rgb(26 46 5 / 0.9) !important;
  border-bottom-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-y-lime-950\/95 {
  border-top-color: rgb(26 46 5 / 0.95) !important;
  border-bottom-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-y-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-100\/0 {
  border-top-color: rgb(245 245 245 / 0) !important;
  border-bottom-color: rgb(245 245 245 / 0) !important;
}

.tw-border-y-neutral-100\/10 {
  border-top-color: rgb(245 245 245 / 0.1) !important;
  border-bottom-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-y-neutral-100\/100 {
  border-top-color: rgb(245 245 245 / 1) !important;
  border-bottom-color: rgb(245 245 245 / 1) !important;
}

.tw-border-y-neutral-100\/15 {
  border-top-color: rgb(245 245 245 / 0.15) !important;
  border-bottom-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-y-neutral-100\/20 {
  border-top-color: rgb(245 245 245 / 0.2) !important;
  border-bottom-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-y-neutral-100\/25 {
  border-top-color: rgb(245 245 245 / 0.25) !important;
  border-bottom-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-y-neutral-100\/30 {
  border-top-color: rgb(245 245 245 / 0.3) !important;
  border-bottom-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-y-neutral-100\/35 {
  border-top-color: rgb(245 245 245 / 0.35) !important;
  border-bottom-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-y-neutral-100\/40 {
  border-top-color: rgb(245 245 245 / 0.4) !important;
  border-bottom-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-y-neutral-100\/45 {
  border-top-color: rgb(245 245 245 / 0.45) !important;
  border-bottom-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-y-neutral-100\/5 {
  border-top-color: rgb(245 245 245 / 0.05) !important;
  border-bottom-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-y-neutral-100\/50 {
  border-top-color: rgb(245 245 245 / 0.5) !important;
  border-bottom-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-y-neutral-100\/55 {
  border-top-color: rgb(245 245 245 / 0.55) !important;
  border-bottom-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-y-neutral-100\/60 {
  border-top-color: rgb(245 245 245 / 0.6) !important;
  border-bottom-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-y-neutral-100\/65 {
  border-top-color: rgb(245 245 245 / 0.65) !important;
  border-bottom-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-y-neutral-100\/70 {
  border-top-color: rgb(245 245 245 / 0.7) !important;
  border-bottom-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-y-neutral-100\/75 {
  border-top-color: rgb(245 245 245 / 0.75) !important;
  border-bottom-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-y-neutral-100\/80 {
  border-top-color: rgb(245 245 245 / 0.8) !important;
  border-bottom-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-y-neutral-100\/85 {
  border-top-color: rgb(245 245 245 / 0.85) !important;
  border-bottom-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-y-neutral-100\/90 {
  border-top-color: rgb(245 245 245 / 0.9) !important;
  border-bottom-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-y-neutral-100\/95 {
  border-top-color: rgb(245 245 245 / 0.95) !important;
  border-bottom-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-y-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-200\/0 {
  border-top-color: rgb(229 229 229 / 0) !important;
  border-bottom-color: rgb(229 229 229 / 0) !important;
}

.tw-border-y-neutral-200\/10 {
  border-top-color: rgb(229 229 229 / 0.1) !important;
  border-bottom-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-y-neutral-200\/100 {
  border-top-color: rgb(229 229 229 / 1) !important;
  border-bottom-color: rgb(229 229 229 / 1) !important;
}

.tw-border-y-neutral-200\/15 {
  border-top-color: rgb(229 229 229 / 0.15) !important;
  border-bottom-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-y-neutral-200\/20 {
  border-top-color: rgb(229 229 229 / 0.2) !important;
  border-bottom-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-y-neutral-200\/25 {
  border-top-color: rgb(229 229 229 / 0.25) !important;
  border-bottom-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-y-neutral-200\/30 {
  border-top-color: rgb(229 229 229 / 0.3) !important;
  border-bottom-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-y-neutral-200\/35 {
  border-top-color: rgb(229 229 229 / 0.35) !important;
  border-bottom-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-y-neutral-200\/40 {
  border-top-color: rgb(229 229 229 / 0.4) !important;
  border-bottom-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-y-neutral-200\/45 {
  border-top-color: rgb(229 229 229 / 0.45) !important;
  border-bottom-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-y-neutral-200\/5 {
  border-top-color: rgb(229 229 229 / 0.05) !important;
  border-bottom-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-y-neutral-200\/50 {
  border-top-color: rgb(229 229 229 / 0.5) !important;
  border-bottom-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-y-neutral-200\/55 {
  border-top-color: rgb(229 229 229 / 0.55) !important;
  border-bottom-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-y-neutral-200\/60 {
  border-top-color: rgb(229 229 229 / 0.6) !important;
  border-bottom-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-y-neutral-200\/65 {
  border-top-color: rgb(229 229 229 / 0.65) !important;
  border-bottom-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-y-neutral-200\/70 {
  border-top-color: rgb(229 229 229 / 0.7) !important;
  border-bottom-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-y-neutral-200\/75 {
  border-top-color: rgb(229 229 229 / 0.75) !important;
  border-bottom-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-y-neutral-200\/80 {
  border-top-color: rgb(229 229 229 / 0.8) !important;
  border-bottom-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-y-neutral-200\/85 {
  border-top-color: rgb(229 229 229 / 0.85) !important;
  border-bottom-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-y-neutral-200\/90 {
  border-top-color: rgb(229 229 229 / 0.9) !important;
  border-bottom-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-y-neutral-200\/95 {
  border-top-color: rgb(229 229 229 / 0.95) !important;
  border-bottom-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-y-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-300\/0 {
  border-top-color: rgb(212 212 212 / 0) !important;
  border-bottom-color: rgb(212 212 212 / 0) !important;
}

.tw-border-y-neutral-300\/10 {
  border-top-color: rgb(212 212 212 / 0.1) !important;
  border-bottom-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-y-neutral-300\/100 {
  border-top-color: rgb(212 212 212 / 1) !important;
  border-bottom-color: rgb(212 212 212 / 1) !important;
}

.tw-border-y-neutral-300\/15 {
  border-top-color: rgb(212 212 212 / 0.15) !important;
  border-bottom-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-y-neutral-300\/20 {
  border-top-color: rgb(212 212 212 / 0.2) !important;
  border-bottom-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-y-neutral-300\/25 {
  border-top-color: rgb(212 212 212 / 0.25) !important;
  border-bottom-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-y-neutral-300\/30 {
  border-top-color: rgb(212 212 212 / 0.3) !important;
  border-bottom-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-y-neutral-300\/35 {
  border-top-color: rgb(212 212 212 / 0.35) !important;
  border-bottom-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-y-neutral-300\/40 {
  border-top-color: rgb(212 212 212 / 0.4) !important;
  border-bottom-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-y-neutral-300\/45 {
  border-top-color: rgb(212 212 212 / 0.45) !important;
  border-bottom-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-y-neutral-300\/5 {
  border-top-color: rgb(212 212 212 / 0.05) !important;
  border-bottom-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-y-neutral-300\/50 {
  border-top-color: rgb(212 212 212 / 0.5) !important;
  border-bottom-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-y-neutral-300\/55 {
  border-top-color: rgb(212 212 212 / 0.55) !important;
  border-bottom-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-y-neutral-300\/60 {
  border-top-color: rgb(212 212 212 / 0.6) !important;
  border-bottom-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-y-neutral-300\/65 {
  border-top-color: rgb(212 212 212 / 0.65) !important;
  border-bottom-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-y-neutral-300\/70 {
  border-top-color: rgb(212 212 212 / 0.7) !important;
  border-bottom-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-y-neutral-300\/75 {
  border-top-color: rgb(212 212 212 / 0.75) !important;
  border-bottom-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-y-neutral-300\/80 {
  border-top-color: rgb(212 212 212 / 0.8) !important;
  border-bottom-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-y-neutral-300\/85 {
  border-top-color: rgb(212 212 212 / 0.85) !important;
  border-bottom-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-y-neutral-300\/90 {
  border-top-color: rgb(212 212 212 / 0.9) !important;
  border-bottom-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-y-neutral-300\/95 {
  border-top-color: rgb(212 212 212 / 0.95) !important;
  border-bottom-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-y-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-400\/0 {
  border-top-color: rgb(163 163 163 / 0) !important;
  border-bottom-color: rgb(163 163 163 / 0) !important;
}

.tw-border-y-neutral-400\/10 {
  border-top-color: rgb(163 163 163 / 0.1) !important;
  border-bottom-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-y-neutral-400\/100 {
  border-top-color: rgb(163 163 163 / 1) !important;
  border-bottom-color: rgb(163 163 163 / 1) !important;
}

.tw-border-y-neutral-400\/15 {
  border-top-color: rgb(163 163 163 / 0.15) !important;
  border-bottom-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-y-neutral-400\/20 {
  border-top-color: rgb(163 163 163 / 0.2) !important;
  border-bottom-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-y-neutral-400\/25 {
  border-top-color: rgb(163 163 163 / 0.25) !important;
  border-bottom-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-y-neutral-400\/30 {
  border-top-color: rgb(163 163 163 / 0.3) !important;
  border-bottom-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-y-neutral-400\/35 {
  border-top-color: rgb(163 163 163 / 0.35) !important;
  border-bottom-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-y-neutral-400\/40 {
  border-top-color: rgb(163 163 163 / 0.4) !important;
  border-bottom-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-y-neutral-400\/45 {
  border-top-color: rgb(163 163 163 / 0.45) !important;
  border-bottom-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-y-neutral-400\/5 {
  border-top-color: rgb(163 163 163 / 0.05) !important;
  border-bottom-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-y-neutral-400\/50 {
  border-top-color: rgb(163 163 163 / 0.5) !important;
  border-bottom-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-y-neutral-400\/55 {
  border-top-color: rgb(163 163 163 / 0.55) !important;
  border-bottom-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-y-neutral-400\/60 {
  border-top-color: rgb(163 163 163 / 0.6) !important;
  border-bottom-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-y-neutral-400\/65 {
  border-top-color: rgb(163 163 163 / 0.65) !important;
  border-bottom-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-y-neutral-400\/70 {
  border-top-color: rgb(163 163 163 / 0.7) !important;
  border-bottom-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-y-neutral-400\/75 {
  border-top-color: rgb(163 163 163 / 0.75) !important;
  border-bottom-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-y-neutral-400\/80 {
  border-top-color: rgb(163 163 163 / 0.8) !important;
  border-bottom-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-y-neutral-400\/85 {
  border-top-color: rgb(163 163 163 / 0.85) !important;
  border-bottom-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-y-neutral-400\/90 {
  border-top-color: rgb(163 163 163 / 0.9) !important;
  border-bottom-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-y-neutral-400\/95 {
  border-top-color: rgb(163 163 163 / 0.95) !important;
  border-bottom-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-y-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-50\/0 {
  border-top-color: rgb(250 250 250 / 0) !important;
  border-bottom-color: rgb(250 250 250 / 0) !important;
}

.tw-border-y-neutral-50\/10 {
  border-top-color: rgb(250 250 250 / 0.1) !important;
  border-bottom-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-y-neutral-50\/100 {
  border-top-color: rgb(250 250 250 / 1) !important;
  border-bottom-color: rgb(250 250 250 / 1) !important;
}

.tw-border-y-neutral-50\/15 {
  border-top-color: rgb(250 250 250 / 0.15) !important;
  border-bottom-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-y-neutral-50\/20 {
  border-top-color: rgb(250 250 250 / 0.2) !important;
  border-bottom-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-y-neutral-50\/25 {
  border-top-color: rgb(250 250 250 / 0.25) !important;
  border-bottom-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-y-neutral-50\/30 {
  border-top-color: rgb(250 250 250 / 0.3) !important;
  border-bottom-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-y-neutral-50\/35 {
  border-top-color: rgb(250 250 250 / 0.35) !important;
  border-bottom-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-y-neutral-50\/40 {
  border-top-color: rgb(250 250 250 / 0.4) !important;
  border-bottom-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-y-neutral-50\/45 {
  border-top-color: rgb(250 250 250 / 0.45) !important;
  border-bottom-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-y-neutral-50\/5 {
  border-top-color: rgb(250 250 250 / 0.05) !important;
  border-bottom-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-y-neutral-50\/50 {
  border-top-color: rgb(250 250 250 / 0.5) !important;
  border-bottom-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-y-neutral-50\/55 {
  border-top-color: rgb(250 250 250 / 0.55) !important;
  border-bottom-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-y-neutral-50\/60 {
  border-top-color: rgb(250 250 250 / 0.6) !important;
  border-bottom-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-y-neutral-50\/65 {
  border-top-color: rgb(250 250 250 / 0.65) !important;
  border-bottom-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-y-neutral-50\/70 {
  border-top-color: rgb(250 250 250 / 0.7) !important;
  border-bottom-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-y-neutral-50\/75 {
  border-top-color: rgb(250 250 250 / 0.75) !important;
  border-bottom-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-y-neutral-50\/80 {
  border-top-color: rgb(250 250 250 / 0.8) !important;
  border-bottom-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-y-neutral-50\/85 {
  border-top-color: rgb(250 250 250 / 0.85) !important;
  border-bottom-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-y-neutral-50\/90 {
  border-top-color: rgb(250 250 250 / 0.9) !important;
  border-bottom-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-y-neutral-50\/95 {
  border-top-color: rgb(250 250 250 / 0.95) !important;
  border-bottom-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-y-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-500\/0 {
  border-top-color: rgb(115 115 115 / 0) !important;
  border-bottom-color: rgb(115 115 115 / 0) !important;
}

.tw-border-y-neutral-500\/10 {
  border-top-color: rgb(115 115 115 / 0.1) !important;
  border-bottom-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-y-neutral-500\/100 {
  border-top-color: rgb(115 115 115 / 1) !important;
  border-bottom-color: rgb(115 115 115 / 1) !important;
}

.tw-border-y-neutral-500\/15 {
  border-top-color: rgb(115 115 115 / 0.15) !important;
  border-bottom-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-y-neutral-500\/20 {
  border-top-color: rgb(115 115 115 / 0.2) !important;
  border-bottom-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-y-neutral-500\/25 {
  border-top-color: rgb(115 115 115 / 0.25) !important;
  border-bottom-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-y-neutral-500\/30 {
  border-top-color: rgb(115 115 115 / 0.3) !important;
  border-bottom-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-y-neutral-500\/35 {
  border-top-color: rgb(115 115 115 / 0.35) !important;
  border-bottom-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-y-neutral-500\/40 {
  border-top-color: rgb(115 115 115 / 0.4) !important;
  border-bottom-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-y-neutral-500\/45 {
  border-top-color: rgb(115 115 115 / 0.45) !important;
  border-bottom-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-y-neutral-500\/5 {
  border-top-color: rgb(115 115 115 / 0.05) !important;
  border-bottom-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-y-neutral-500\/50 {
  border-top-color: rgb(115 115 115 / 0.5) !important;
  border-bottom-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-y-neutral-500\/55 {
  border-top-color: rgb(115 115 115 / 0.55) !important;
  border-bottom-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-y-neutral-500\/60 {
  border-top-color: rgb(115 115 115 / 0.6) !important;
  border-bottom-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-y-neutral-500\/65 {
  border-top-color: rgb(115 115 115 / 0.65) !important;
  border-bottom-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-y-neutral-500\/70 {
  border-top-color: rgb(115 115 115 / 0.7) !important;
  border-bottom-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-y-neutral-500\/75 {
  border-top-color: rgb(115 115 115 / 0.75) !important;
  border-bottom-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-y-neutral-500\/80 {
  border-top-color: rgb(115 115 115 / 0.8) !important;
  border-bottom-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-y-neutral-500\/85 {
  border-top-color: rgb(115 115 115 / 0.85) !important;
  border-bottom-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-y-neutral-500\/90 {
  border-top-color: rgb(115 115 115 / 0.9) !important;
  border-bottom-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-y-neutral-500\/95 {
  border-top-color: rgb(115 115 115 / 0.95) !important;
  border-bottom-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-y-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-600\/0 {
  border-top-color: rgb(82 82 82 / 0) !important;
  border-bottom-color: rgb(82 82 82 / 0) !important;
}

.tw-border-y-neutral-600\/10 {
  border-top-color: rgb(82 82 82 / 0.1) !important;
  border-bottom-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-y-neutral-600\/100 {
  border-top-color: rgb(82 82 82 / 1) !important;
  border-bottom-color: rgb(82 82 82 / 1) !important;
}

.tw-border-y-neutral-600\/15 {
  border-top-color: rgb(82 82 82 / 0.15) !important;
  border-bottom-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-y-neutral-600\/20 {
  border-top-color: rgb(82 82 82 / 0.2) !important;
  border-bottom-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-y-neutral-600\/25 {
  border-top-color: rgb(82 82 82 / 0.25) !important;
  border-bottom-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-y-neutral-600\/30 {
  border-top-color: rgb(82 82 82 / 0.3) !important;
  border-bottom-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-y-neutral-600\/35 {
  border-top-color: rgb(82 82 82 / 0.35) !important;
  border-bottom-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-y-neutral-600\/40 {
  border-top-color: rgb(82 82 82 / 0.4) !important;
  border-bottom-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-y-neutral-600\/45 {
  border-top-color: rgb(82 82 82 / 0.45) !important;
  border-bottom-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-y-neutral-600\/5 {
  border-top-color: rgb(82 82 82 / 0.05) !important;
  border-bottom-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-y-neutral-600\/50 {
  border-top-color: rgb(82 82 82 / 0.5) !important;
  border-bottom-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-y-neutral-600\/55 {
  border-top-color: rgb(82 82 82 / 0.55) !important;
  border-bottom-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-y-neutral-600\/60 {
  border-top-color: rgb(82 82 82 / 0.6) !important;
  border-bottom-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-y-neutral-600\/65 {
  border-top-color: rgb(82 82 82 / 0.65) !important;
  border-bottom-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-y-neutral-600\/70 {
  border-top-color: rgb(82 82 82 / 0.7) !important;
  border-bottom-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-y-neutral-600\/75 {
  border-top-color: rgb(82 82 82 / 0.75) !important;
  border-bottom-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-y-neutral-600\/80 {
  border-top-color: rgb(82 82 82 / 0.8) !important;
  border-bottom-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-y-neutral-600\/85 {
  border-top-color: rgb(82 82 82 / 0.85) !important;
  border-bottom-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-y-neutral-600\/90 {
  border-top-color: rgb(82 82 82 / 0.9) !important;
  border-bottom-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-y-neutral-600\/95 {
  border-top-color: rgb(82 82 82 / 0.95) !important;
  border-bottom-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-y-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-700\/0 {
  border-top-color: rgb(64 64 64 / 0) !important;
  border-bottom-color: rgb(64 64 64 / 0) !important;
}

.tw-border-y-neutral-700\/10 {
  border-top-color: rgb(64 64 64 / 0.1) !important;
  border-bottom-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-y-neutral-700\/100 {
  border-top-color: rgb(64 64 64 / 1) !important;
  border-bottom-color: rgb(64 64 64 / 1) !important;
}

.tw-border-y-neutral-700\/15 {
  border-top-color: rgb(64 64 64 / 0.15) !important;
  border-bottom-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-y-neutral-700\/20 {
  border-top-color: rgb(64 64 64 / 0.2) !important;
  border-bottom-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-y-neutral-700\/25 {
  border-top-color: rgb(64 64 64 / 0.25) !important;
  border-bottom-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-y-neutral-700\/30 {
  border-top-color: rgb(64 64 64 / 0.3) !important;
  border-bottom-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-y-neutral-700\/35 {
  border-top-color: rgb(64 64 64 / 0.35) !important;
  border-bottom-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-y-neutral-700\/40 {
  border-top-color: rgb(64 64 64 / 0.4) !important;
  border-bottom-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-y-neutral-700\/45 {
  border-top-color: rgb(64 64 64 / 0.45) !important;
  border-bottom-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-y-neutral-700\/5 {
  border-top-color: rgb(64 64 64 / 0.05) !important;
  border-bottom-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-y-neutral-700\/50 {
  border-top-color: rgb(64 64 64 / 0.5) !important;
  border-bottom-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-y-neutral-700\/55 {
  border-top-color: rgb(64 64 64 / 0.55) !important;
  border-bottom-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-y-neutral-700\/60 {
  border-top-color: rgb(64 64 64 / 0.6) !important;
  border-bottom-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-y-neutral-700\/65 {
  border-top-color: rgb(64 64 64 / 0.65) !important;
  border-bottom-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-y-neutral-700\/70 {
  border-top-color: rgb(64 64 64 / 0.7) !important;
  border-bottom-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-y-neutral-700\/75 {
  border-top-color: rgb(64 64 64 / 0.75) !important;
  border-bottom-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-y-neutral-700\/80 {
  border-top-color: rgb(64 64 64 / 0.8) !important;
  border-bottom-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-y-neutral-700\/85 {
  border-top-color: rgb(64 64 64 / 0.85) !important;
  border-bottom-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-y-neutral-700\/90 {
  border-top-color: rgb(64 64 64 / 0.9) !important;
  border-bottom-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-y-neutral-700\/95 {
  border-top-color: rgb(64 64 64 / 0.95) !important;
  border-bottom-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-y-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-800\/0 {
  border-top-color: rgb(38 38 38 / 0) !important;
  border-bottom-color: rgb(38 38 38 / 0) !important;
}

.tw-border-y-neutral-800\/10 {
  border-top-color: rgb(38 38 38 / 0.1) !important;
  border-bottom-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-y-neutral-800\/100 {
  border-top-color: rgb(38 38 38 / 1) !important;
  border-bottom-color: rgb(38 38 38 / 1) !important;
}

.tw-border-y-neutral-800\/15 {
  border-top-color: rgb(38 38 38 / 0.15) !important;
  border-bottom-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-y-neutral-800\/20 {
  border-top-color: rgb(38 38 38 / 0.2) !important;
  border-bottom-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-y-neutral-800\/25 {
  border-top-color: rgb(38 38 38 / 0.25) !important;
  border-bottom-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-y-neutral-800\/30 {
  border-top-color: rgb(38 38 38 / 0.3) !important;
  border-bottom-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-y-neutral-800\/35 {
  border-top-color: rgb(38 38 38 / 0.35) !important;
  border-bottom-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-y-neutral-800\/40 {
  border-top-color: rgb(38 38 38 / 0.4) !important;
  border-bottom-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-y-neutral-800\/45 {
  border-top-color: rgb(38 38 38 / 0.45) !important;
  border-bottom-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-y-neutral-800\/5 {
  border-top-color: rgb(38 38 38 / 0.05) !important;
  border-bottom-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-y-neutral-800\/50 {
  border-top-color: rgb(38 38 38 / 0.5) !important;
  border-bottom-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-y-neutral-800\/55 {
  border-top-color: rgb(38 38 38 / 0.55) !important;
  border-bottom-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-y-neutral-800\/60 {
  border-top-color: rgb(38 38 38 / 0.6) !important;
  border-bottom-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-y-neutral-800\/65 {
  border-top-color: rgb(38 38 38 / 0.65) !important;
  border-bottom-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-y-neutral-800\/70 {
  border-top-color: rgb(38 38 38 / 0.7) !important;
  border-bottom-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-y-neutral-800\/75 {
  border-top-color: rgb(38 38 38 / 0.75) !important;
  border-bottom-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-y-neutral-800\/80 {
  border-top-color: rgb(38 38 38 / 0.8) !important;
  border-bottom-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-y-neutral-800\/85 {
  border-top-color: rgb(38 38 38 / 0.85) !important;
  border-bottom-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-y-neutral-800\/90 {
  border-top-color: rgb(38 38 38 / 0.9) !important;
  border-bottom-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-y-neutral-800\/95 {
  border-top-color: rgb(38 38 38 / 0.95) !important;
  border-bottom-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-y-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-900\/0 {
  border-top-color: rgb(23 23 23 / 0) !important;
  border-bottom-color: rgb(23 23 23 / 0) !important;
}

.tw-border-y-neutral-900\/10 {
  border-top-color: rgb(23 23 23 / 0.1) !important;
  border-bottom-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-y-neutral-900\/100 {
  border-top-color: rgb(23 23 23 / 1) !important;
  border-bottom-color: rgb(23 23 23 / 1) !important;
}

.tw-border-y-neutral-900\/15 {
  border-top-color: rgb(23 23 23 / 0.15) !important;
  border-bottom-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-y-neutral-900\/20 {
  border-top-color: rgb(23 23 23 / 0.2) !important;
  border-bottom-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-y-neutral-900\/25 {
  border-top-color: rgb(23 23 23 / 0.25) !important;
  border-bottom-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-y-neutral-900\/30 {
  border-top-color: rgb(23 23 23 / 0.3) !important;
  border-bottom-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-y-neutral-900\/35 {
  border-top-color: rgb(23 23 23 / 0.35) !important;
  border-bottom-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-y-neutral-900\/40 {
  border-top-color: rgb(23 23 23 / 0.4) !important;
  border-bottom-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-y-neutral-900\/45 {
  border-top-color: rgb(23 23 23 / 0.45) !important;
  border-bottom-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-y-neutral-900\/5 {
  border-top-color: rgb(23 23 23 / 0.05) !important;
  border-bottom-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-y-neutral-900\/50 {
  border-top-color: rgb(23 23 23 / 0.5) !important;
  border-bottom-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-y-neutral-900\/55 {
  border-top-color: rgb(23 23 23 / 0.55) !important;
  border-bottom-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-y-neutral-900\/60 {
  border-top-color: rgb(23 23 23 / 0.6) !important;
  border-bottom-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-y-neutral-900\/65 {
  border-top-color: rgb(23 23 23 / 0.65) !important;
  border-bottom-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-y-neutral-900\/70 {
  border-top-color: rgb(23 23 23 / 0.7) !important;
  border-bottom-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-y-neutral-900\/75 {
  border-top-color: rgb(23 23 23 / 0.75) !important;
  border-bottom-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-y-neutral-900\/80 {
  border-top-color: rgb(23 23 23 / 0.8) !important;
  border-bottom-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-y-neutral-900\/85 {
  border-top-color: rgb(23 23 23 / 0.85) !important;
  border-bottom-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-y-neutral-900\/90 {
  border-top-color: rgb(23 23 23 / 0.9) !important;
  border-bottom-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-y-neutral-900\/95 {
  border-top-color: rgb(23 23 23 / 0.95) !important;
  border-bottom-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-y-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-y-neutral-950\/0 {
  border-top-color: rgb(10 10 10 / 0) !important;
  border-bottom-color: rgb(10 10 10 / 0) !important;
}

.tw-border-y-neutral-950\/10 {
  border-top-color: rgb(10 10 10 / 0.1) !important;
  border-bottom-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-y-neutral-950\/100 {
  border-top-color: rgb(10 10 10 / 1) !important;
  border-bottom-color: rgb(10 10 10 / 1) !important;
}

.tw-border-y-neutral-950\/15 {
  border-top-color: rgb(10 10 10 / 0.15) !important;
  border-bottom-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-y-neutral-950\/20 {
  border-top-color: rgb(10 10 10 / 0.2) !important;
  border-bottom-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-y-neutral-950\/25 {
  border-top-color: rgb(10 10 10 / 0.25) !important;
  border-bottom-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-y-neutral-950\/30 {
  border-top-color: rgb(10 10 10 / 0.3) !important;
  border-bottom-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-y-neutral-950\/35 {
  border-top-color: rgb(10 10 10 / 0.35) !important;
  border-bottom-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-y-neutral-950\/40 {
  border-top-color: rgb(10 10 10 / 0.4) !important;
  border-bottom-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-y-neutral-950\/45 {
  border-top-color: rgb(10 10 10 / 0.45) !important;
  border-bottom-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-y-neutral-950\/5 {
  border-top-color: rgb(10 10 10 / 0.05) !important;
  border-bottom-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-y-neutral-950\/50 {
  border-top-color: rgb(10 10 10 / 0.5) !important;
  border-bottom-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-y-neutral-950\/55 {
  border-top-color: rgb(10 10 10 / 0.55) !important;
  border-bottom-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-y-neutral-950\/60 {
  border-top-color: rgb(10 10 10 / 0.6) !important;
  border-bottom-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-y-neutral-950\/65 {
  border-top-color: rgb(10 10 10 / 0.65) !important;
  border-bottom-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-y-neutral-950\/70 {
  border-top-color: rgb(10 10 10 / 0.7) !important;
  border-bottom-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-y-neutral-950\/75 {
  border-top-color: rgb(10 10 10 / 0.75) !important;
  border-bottom-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-y-neutral-950\/80 {
  border-top-color: rgb(10 10 10 / 0.8) !important;
  border-bottom-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-y-neutral-950\/85 {
  border-top-color: rgb(10 10 10 / 0.85) !important;
  border-bottom-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-y-neutral-950\/90 {
  border-top-color: rgb(10 10 10 / 0.9) !important;
  border-bottom-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-y-neutral-950\/95 {
  border-top-color: rgb(10 10 10 / 0.95) !important;
  border-bottom-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-y-orange-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-100\/0 {
  border-top-color: rgb(255 237 213 / 0) !important;
  border-bottom-color: rgb(255 237 213 / 0) !important;
}

.tw-border-y-orange-100\/10 {
  border-top-color: rgb(255 237 213 / 0.1) !important;
  border-bottom-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-y-orange-100\/100 {
  border-top-color: rgb(255 237 213 / 1) !important;
  border-bottom-color: rgb(255 237 213 / 1) !important;
}

.tw-border-y-orange-100\/15 {
  border-top-color: rgb(255 237 213 / 0.15) !important;
  border-bottom-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-y-orange-100\/20 {
  border-top-color: rgb(255 237 213 / 0.2) !important;
  border-bottom-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-y-orange-100\/25 {
  border-top-color: rgb(255 237 213 / 0.25) !important;
  border-bottom-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-y-orange-100\/30 {
  border-top-color: rgb(255 237 213 / 0.3) !important;
  border-bottom-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-y-orange-100\/35 {
  border-top-color: rgb(255 237 213 / 0.35) !important;
  border-bottom-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-y-orange-100\/40 {
  border-top-color: rgb(255 237 213 / 0.4) !important;
  border-bottom-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-y-orange-100\/45 {
  border-top-color: rgb(255 237 213 / 0.45) !important;
  border-bottom-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-y-orange-100\/5 {
  border-top-color: rgb(255 237 213 / 0.05) !important;
  border-bottom-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-y-orange-100\/50 {
  border-top-color: rgb(255 237 213 / 0.5) !important;
  border-bottom-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-y-orange-100\/55 {
  border-top-color: rgb(255 237 213 / 0.55) !important;
  border-bottom-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-y-orange-100\/60 {
  border-top-color: rgb(255 237 213 / 0.6) !important;
  border-bottom-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-y-orange-100\/65 {
  border-top-color: rgb(255 237 213 / 0.65) !important;
  border-bottom-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-y-orange-100\/70 {
  border-top-color: rgb(255 237 213 / 0.7) !important;
  border-bottom-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-y-orange-100\/75 {
  border-top-color: rgb(255 237 213 / 0.75) !important;
  border-bottom-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-y-orange-100\/80 {
  border-top-color: rgb(255 237 213 / 0.8) !important;
  border-bottom-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-y-orange-100\/85 {
  border-top-color: rgb(255 237 213 / 0.85) !important;
  border-bottom-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-y-orange-100\/90 {
  border-top-color: rgb(255 237 213 / 0.9) !important;
  border-bottom-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-y-orange-100\/95 {
  border-top-color: rgb(255 237 213 / 0.95) !important;
  border-bottom-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-y-orange-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-200\/0 {
  border-top-color: rgb(254 215 170 / 0) !important;
  border-bottom-color: rgb(254 215 170 / 0) !important;
}

.tw-border-y-orange-200\/10 {
  border-top-color: rgb(254 215 170 / 0.1) !important;
  border-bottom-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-y-orange-200\/100 {
  border-top-color: rgb(254 215 170 / 1) !important;
  border-bottom-color: rgb(254 215 170 / 1) !important;
}

.tw-border-y-orange-200\/15 {
  border-top-color: rgb(254 215 170 / 0.15) !important;
  border-bottom-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-y-orange-200\/20 {
  border-top-color: rgb(254 215 170 / 0.2) !important;
  border-bottom-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-y-orange-200\/25 {
  border-top-color: rgb(254 215 170 / 0.25) !important;
  border-bottom-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-y-orange-200\/30 {
  border-top-color: rgb(254 215 170 / 0.3) !important;
  border-bottom-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-y-orange-200\/35 {
  border-top-color: rgb(254 215 170 / 0.35) !important;
  border-bottom-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-y-orange-200\/40 {
  border-top-color: rgb(254 215 170 / 0.4) !important;
  border-bottom-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-y-orange-200\/45 {
  border-top-color: rgb(254 215 170 / 0.45) !important;
  border-bottom-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-y-orange-200\/5 {
  border-top-color: rgb(254 215 170 / 0.05) !important;
  border-bottom-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-y-orange-200\/50 {
  border-top-color: rgb(254 215 170 / 0.5) !important;
  border-bottom-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-y-orange-200\/55 {
  border-top-color: rgb(254 215 170 / 0.55) !important;
  border-bottom-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-y-orange-200\/60 {
  border-top-color: rgb(254 215 170 / 0.6) !important;
  border-bottom-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-y-orange-200\/65 {
  border-top-color: rgb(254 215 170 / 0.65) !important;
  border-bottom-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-y-orange-200\/70 {
  border-top-color: rgb(254 215 170 / 0.7) !important;
  border-bottom-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-y-orange-200\/75 {
  border-top-color: rgb(254 215 170 / 0.75) !important;
  border-bottom-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-y-orange-200\/80 {
  border-top-color: rgb(254 215 170 / 0.8) !important;
  border-bottom-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-y-orange-200\/85 {
  border-top-color: rgb(254 215 170 / 0.85) !important;
  border-bottom-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-y-orange-200\/90 {
  border-top-color: rgb(254 215 170 / 0.9) !important;
  border-bottom-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-y-orange-200\/95 {
  border-top-color: rgb(254 215 170 / 0.95) !important;
  border-bottom-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-y-orange-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-300\/0 {
  border-top-color: rgb(253 186 116 / 0) !important;
  border-bottom-color: rgb(253 186 116 / 0) !important;
}

.tw-border-y-orange-300\/10 {
  border-top-color: rgb(253 186 116 / 0.1) !important;
  border-bottom-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-y-orange-300\/100 {
  border-top-color: rgb(253 186 116 / 1) !important;
  border-bottom-color: rgb(253 186 116 / 1) !important;
}

.tw-border-y-orange-300\/15 {
  border-top-color: rgb(253 186 116 / 0.15) !important;
  border-bottom-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-y-orange-300\/20 {
  border-top-color: rgb(253 186 116 / 0.2) !important;
  border-bottom-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-y-orange-300\/25 {
  border-top-color: rgb(253 186 116 / 0.25) !important;
  border-bottom-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-y-orange-300\/30 {
  border-top-color: rgb(253 186 116 / 0.3) !important;
  border-bottom-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-y-orange-300\/35 {
  border-top-color: rgb(253 186 116 / 0.35) !important;
  border-bottom-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-y-orange-300\/40 {
  border-top-color: rgb(253 186 116 / 0.4) !important;
  border-bottom-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-y-orange-300\/45 {
  border-top-color: rgb(253 186 116 / 0.45) !important;
  border-bottom-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-y-orange-300\/5 {
  border-top-color: rgb(253 186 116 / 0.05) !important;
  border-bottom-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-y-orange-300\/50 {
  border-top-color: rgb(253 186 116 / 0.5) !important;
  border-bottom-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-y-orange-300\/55 {
  border-top-color: rgb(253 186 116 / 0.55) !important;
  border-bottom-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-y-orange-300\/60 {
  border-top-color: rgb(253 186 116 / 0.6) !important;
  border-bottom-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-y-orange-300\/65 {
  border-top-color: rgb(253 186 116 / 0.65) !important;
  border-bottom-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-y-orange-300\/70 {
  border-top-color: rgb(253 186 116 / 0.7) !important;
  border-bottom-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-y-orange-300\/75 {
  border-top-color: rgb(253 186 116 / 0.75) !important;
  border-bottom-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-y-orange-300\/80 {
  border-top-color: rgb(253 186 116 / 0.8) !important;
  border-bottom-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-y-orange-300\/85 {
  border-top-color: rgb(253 186 116 / 0.85) !important;
  border-bottom-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-y-orange-300\/90 {
  border-top-color: rgb(253 186 116 / 0.9) !important;
  border-bottom-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-y-orange-300\/95 {
  border-top-color: rgb(253 186 116 / 0.95) !important;
  border-bottom-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-y-orange-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-400\/0 {
  border-top-color: rgb(251 146 60 / 0) !important;
  border-bottom-color: rgb(251 146 60 / 0) !important;
}

.tw-border-y-orange-400\/10 {
  border-top-color: rgb(251 146 60 / 0.1) !important;
  border-bottom-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-y-orange-400\/100 {
  border-top-color: rgb(251 146 60 / 1) !important;
  border-bottom-color: rgb(251 146 60 / 1) !important;
}

.tw-border-y-orange-400\/15 {
  border-top-color: rgb(251 146 60 / 0.15) !important;
  border-bottom-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-y-orange-400\/20 {
  border-top-color: rgb(251 146 60 / 0.2) !important;
  border-bottom-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-y-orange-400\/25 {
  border-top-color: rgb(251 146 60 / 0.25) !important;
  border-bottom-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-y-orange-400\/30 {
  border-top-color: rgb(251 146 60 / 0.3) !important;
  border-bottom-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-y-orange-400\/35 {
  border-top-color: rgb(251 146 60 / 0.35) !important;
  border-bottom-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-y-orange-400\/40 {
  border-top-color: rgb(251 146 60 / 0.4) !important;
  border-bottom-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-y-orange-400\/45 {
  border-top-color: rgb(251 146 60 / 0.45) !important;
  border-bottom-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-y-orange-400\/5 {
  border-top-color: rgb(251 146 60 / 0.05) !important;
  border-bottom-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-y-orange-400\/50 {
  border-top-color: rgb(251 146 60 / 0.5) !important;
  border-bottom-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-y-orange-400\/55 {
  border-top-color: rgb(251 146 60 / 0.55) !important;
  border-bottom-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-y-orange-400\/60 {
  border-top-color: rgb(251 146 60 / 0.6) !important;
  border-bottom-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-y-orange-400\/65 {
  border-top-color: rgb(251 146 60 / 0.65) !important;
  border-bottom-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-y-orange-400\/70 {
  border-top-color: rgb(251 146 60 / 0.7) !important;
  border-bottom-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-y-orange-400\/75 {
  border-top-color: rgb(251 146 60 / 0.75) !important;
  border-bottom-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-y-orange-400\/80 {
  border-top-color: rgb(251 146 60 / 0.8) !important;
  border-bottom-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-y-orange-400\/85 {
  border-top-color: rgb(251 146 60 / 0.85) !important;
  border-bottom-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-y-orange-400\/90 {
  border-top-color: rgb(251 146 60 / 0.9) !important;
  border-bottom-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-y-orange-400\/95 {
  border-top-color: rgb(251 146 60 / 0.95) !important;
  border-bottom-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-y-orange-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-50\/0 {
  border-top-color: rgb(255 247 237 / 0) !important;
  border-bottom-color: rgb(255 247 237 / 0) !important;
}

.tw-border-y-orange-50\/10 {
  border-top-color: rgb(255 247 237 / 0.1) !important;
  border-bottom-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-y-orange-50\/100 {
  border-top-color: rgb(255 247 237 / 1) !important;
  border-bottom-color: rgb(255 247 237 / 1) !important;
}

.tw-border-y-orange-50\/15 {
  border-top-color: rgb(255 247 237 / 0.15) !important;
  border-bottom-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-y-orange-50\/20 {
  border-top-color: rgb(255 247 237 / 0.2) !important;
  border-bottom-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-y-orange-50\/25 {
  border-top-color: rgb(255 247 237 / 0.25) !important;
  border-bottom-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-y-orange-50\/30 {
  border-top-color: rgb(255 247 237 / 0.3) !important;
  border-bottom-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-y-orange-50\/35 {
  border-top-color: rgb(255 247 237 / 0.35) !important;
  border-bottom-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-y-orange-50\/40 {
  border-top-color: rgb(255 247 237 / 0.4) !important;
  border-bottom-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-y-orange-50\/45 {
  border-top-color: rgb(255 247 237 / 0.45) !important;
  border-bottom-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-y-orange-50\/5 {
  border-top-color: rgb(255 247 237 / 0.05) !important;
  border-bottom-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-y-orange-50\/50 {
  border-top-color: rgb(255 247 237 / 0.5) !important;
  border-bottom-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-y-orange-50\/55 {
  border-top-color: rgb(255 247 237 / 0.55) !important;
  border-bottom-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-y-orange-50\/60 {
  border-top-color: rgb(255 247 237 / 0.6) !important;
  border-bottom-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-y-orange-50\/65 {
  border-top-color: rgb(255 247 237 / 0.65) !important;
  border-bottom-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-y-orange-50\/70 {
  border-top-color: rgb(255 247 237 / 0.7) !important;
  border-bottom-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-y-orange-50\/75 {
  border-top-color: rgb(255 247 237 / 0.75) !important;
  border-bottom-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-y-orange-50\/80 {
  border-top-color: rgb(255 247 237 / 0.8) !important;
  border-bottom-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-y-orange-50\/85 {
  border-top-color: rgb(255 247 237 / 0.85) !important;
  border-bottom-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-y-orange-50\/90 {
  border-top-color: rgb(255 247 237 / 0.9) !important;
  border-bottom-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-y-orange-50\/95 {
  border-top-color: rgb(255 247 237 / 0.95) !important;
  border-bottom-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-y-orange-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-500\/0 {
  border-top-color: rgb(249 115 22 / 0) !important;
  border-bottom-color: rgb(249 115 22 / 0) !important;
}

.tw-border-y-orange-500\/10 {
  border-top-color: rgb(249 115 22 / 0.1) !important;
  border-bottom-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-y-orange-500\/100 {
  border-top-color: rgb(249 115 22 / 1) !important;
  border-bottom-color: rgb(249 115 22 / 1) !important;
}

.tw-border-y-orange-500\/15 {
  border-top-color: rgb(249 115 22 / 0.15) !important;
  border-bottom-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-y-orange-500\/20 {
  border-top-color: rgb(249 115 22 / 0.2) !important;
  border-bottom-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-y-orange-500\/25 {
  border-top-color: rgb(249 115 22 / 0.25) !important;
  border-bottom-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-y-orange-500\/30 {
  border-top-color: rgb(249 115 22 / 0.3) !important;
  border-bottom-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-y-orange-500\/35 {
  border-top-color: rgb(249 115 22 / 0.35) !important;
  border-bottom-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-y-orange-500\/40 {
  border-top-color: rgb(249 115 22 / 0.4) !important;
  border-bottom-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-y-orange-500\/45 {
  border-top-color: rgb(249 115 22 / 0.45) !important;
  border-bottom-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-y-orange-500\/5 {
  border-top-color: rgb(249 115 22 / 0.05) !important;
  border-bottom-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-y-orange-500\/50 {
  border-top-color: rgb(249 115 22 / 0.5) !important;
  border-bottom-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-y-orange-500\/55 {
  border-top-color: rgb(249 115 22 / 0.55) !important;
  border-bottom-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-y-orange-500\/60 {
  border-top-color: rgb(249 115 22 / 0.6) !important;
  border-bottom-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-y-orange-500\/65 {
  border-top-color: rgb(249 115 22 / 0.65) !important;
  border-bottom-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-y-orange-500\/70 {
  border-top-color: rgb(249 115 22 / 0.7) !important;
  border-bottom-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-y-orange-500\/75 {
  border-top-color: rgb(249 115 22 / 0.75) !important;
  border-bottom-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-y-orange-500\/80 {
  border-top-color: rgb(249 115 22 / 0.8) !important;
  border-bottom-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-y-orange-500\/85 {
  border-top-color: rgb(249 115 22 / 0.85) !important;
  border-bottom-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-y-orange-500\/90 {
  border-top-color: rgb(249 115 22 / 0.9) !important;
  border-bottom-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-y-orange-500\/95 {
  border-top-color: rgb(249 115 22 / 0.95) !important;
  border-bottom-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-y-orange-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-600\/0 {
  border-top-color: rgb(234 88 12 / 0) !important;
  border-bottom-color: rgb(234 88 12 / 0) !important;
}

.tw-border-y-orange-600\/10 {
  border-top-color: rgb(234 88 12 / 0.1) !important;
  border-bottom-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-y-orange-600\/100 {
  border-top-color: rgb(234 88 12 / 1) !important;
  border-bottom-color: rgb(234 88 12 / 1) !important;
}

.tw-border-y-orange-600\/15 {
  border-top-color: rgb(234 88 12 / 0.15) !important;
  border-bottom-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-y-orange-600\/20 {
  border-top-color: rgb(234 88 12 / 0.2) !important;
  border-bottom-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-y-orange-600\/25 {
  border-top-color: rgb(234 88 12 / 0.25) !important;
  border-bottom-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-y-orange-600\/30 {
  border-top-color: rgb(234 88 12 / 0.3) !important;
  border-bottom-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-y-orange-600\/35 {
  border-top-color: rgb(234 88 12 / 0.35) !important;
  border-bottom-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-y-orange-600\/40 {
  border-top-color: rgb(234 88 12 / 0.4) !important;
  border-bottom-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-y-orange-600\/45 {
  border-top-color: rgb(234 88 12 / 0.45) !important;
  border-bottom-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-y-orange-600\/5 {
  border-top-color: rgb(234 88 12 / 0.05) !important;
  border-bottom-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-y-orange-600\/50 {
  border-top-color: rgb(234 88 12 / 0.5) !important;
  border-bottom-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-y-orange-600\/55 {
  border-top-color: rgb(234 88 12 / 0.55) !important;
  border-bottom-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-y-orange-600\/60 {
  border-top-color: rgb(234 88 12 / 0.6) !important;
  border-bottom-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-y-orange-600\/65 {
  border-top-color: rgb(234 88 12 / 0.65) !important;
  border-bottom-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-y-orange-600\/70 {
  border-top-color: rgb(234 88 12 / 0.7) !important;
  border-bottom-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-y-orange-600\/75 {
  border-top-color: rgb(234 88 12 / 0.75) !important;
  border-bottom-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-y-orange-600\/80 {
  border-top-color: rgb(234 88 12 / 0.8) !important;
  border-bottom-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-y-orange-600\/85 {
  border-top-color: rgb(234 88 12 / 0.85) !important;
  border-bottom-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-y-orange-600\/90 {
  border-top-color: rgb(234 88 12 / 0.9) !important;
  border-bottom-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-y-orange-600\/95 {
  border-top-color: rgb(234 88 12 / 0.95) !important;
  border-bottom-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-y-orange-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-700\/0 {
  border-top-color: rgb(194 65 12 / 0) !important;
  border-bottom-color: rgb(194 65 12 / 0) !important;
}

.tw-border-y-orange-700\/10 {
  border-top-color: rgb(194 65 12 / 0.1) !important;
  border-bottom-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-y-orange-700\/100 {
  border-top-color: rgb(194 65 12 / 1) !important;
  border-bottom-color: rgb(194 65 12 / 1) !important;
}

.tw-border-y-orange-700\/15 {
  border-top-color: rgb(194 65 12 / 0.15) !important;
  border-bottom-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-y-orange-700\/20 {
  border-top-color: rgb(194 65 12 / 0.2) !important;
  border-bottom-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-y-orange-700\/25 {
  border-top-color: rgb(194 65 12 / 0.25) !important;
  border-bottom-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-y-orange-700\/30 {
  border-top-color: rgb(194 65 12 / 0.3) !important;
  border-bottom-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-y-orange-700\/35 {
  border-top-color: rgb(194 65 12 / 0.35) !important;
  border-bottom-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-y-orange-700\/40 {
  border-top-color: rgb(194 65 12 / 0.4) !important;
  border-bottom-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-y-orange-700\/45 {
  border-top-color: rgb(194 65 12 / 0.45) !important;
  border-bottom-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-y-orange-700\/5 {
  border-top-color: rgb(194 65 12 / 0.05) !important;
  border-bottom-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-y-orange-700\/50 {
  border-top-color: rgb(194 65 12 / 0.5) !important;
  border-bottom-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-y-orange-700\/55 {
  border-top-color: rgb(194 65 12 / 0.55) !important;
  border-bottom-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-y-orange-700\/60 {
  border-top-color: rgb(194 65 12 / 0.6) !important;
  border-bottom-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-y-orange-700\/65 {
  border-top-color: rgb(194 65 12 / 0.65) !important;
  border-bottom-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-y-orange-700\/70 {
  border-top-color: rgb(194 65 12 / 0.7) !important;
  border-bottom-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-y-orange-700\/75 {
  border-top-color: rgb(194 65 12 / 0.75) !important;
  border-bottom-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-y-orange-700\/80 {
  border-top-color: rgb(194 65 12 / 0.8) !important;
  border-bottom-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-y-orange-700\/85 {
  border-top-color: rgb(194 65 12 / 0.85) !important;
  border-bottom-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-y-orange-700\/90 {
  border-top-color: rgb(194 65 12 / 0.9) !important;
  border-bottom-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-y-orange-700\/95 {
  border-top-color: rgb(194 65 12 / 0.95) !important;
  border-bottom-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-y-orange-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-800\/0 {
  border-top-color: rgb(154 52 18 / 0) !important;
  border-bottom-color: rgb(154 52 18 / 0) !important;
}

.tw-border-y-orange-800\/10 {
  border-top-color: rgb(154 52 18 / 0.1) !important;
  border-bottom-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-y-orange-800\/100 {
  border-top-color: rgb(154 52 18 / 1) !important;
  border-bottom-color: rgb(154 52 18 / 1) !important;
}

.tw-border-y-orange-800\/15 {
  border-top-color: rgb(154 52 18 / 0.15) !important;
  border-bottom-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-y-orange-800\/20 {
  border-top-color: rgb(154 52 18 / 0.2) !important;
  border-bottom-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-y-orange-800\/25 {
  border-top-color: rgb(154 52 18 / 0.25) !important;
  border-bottom-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-y-orange-800\/30 {
  border-top-color: rgb(154 52 18 / 0.3) !important;
  border-bottom-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-y-orange-800\/35 {
  border-top-color: rgb(154 52 18 / 0.35) !important;
  border-bottom-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-y-orange-800\/40 {
  border-top-color: rgb(154 52 18 / 0.4) !important;
  border-bottom-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-y-orange-800\/45 {
  border-top-color: rgb(154 52 18 / 0.45) !important;
  border-bottom-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-y-orange-800\/5 {
  border-top-color: rgb(154 52 18 / 0.05) !important;
  border-bottom-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-y-orange-800\/50 {
  border-top-color: rgb(154 52 18 / 0.5) !important;
  border-bottom-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-y-orange-800\/55 {
  border-top-color: rgb(154 52 18 / 0.55) !important;
  border-bottom-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-y-orange-800\/60 {
  border-top-color: rgb(154 52 18 / 0.6) !important;
  border-bottom-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-y-orange-800\/65 {
  border-top-color: rgb(154 52 18 / 0.65) !important;
  border-bottom-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-y-orange-800\/70 {
  border-top-color: rgb(154 52 18 / 0.7) !important;
  border-bottom-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-y-orange-800\/75 {
  border-top-color: rgb(154 52 18 / 0.75) !important;
  border-bottom-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-y-orange-800\/80 {
  border-top-color: rgb(154 52 18 / 0.8) !important;
  border-bottom-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-y-orange-800\/85 {
  border-top-color: rgb(154 52 18 / 0.85) !important;
  border-bottom-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-y-orange-800\/90 {
  border-top-color: rgb(154 52 18 / 0.9) !important;
  border-bottom-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-y-orange-800\/95 {
  border-top-color: rgb(154 52 18 / 0.95) !important;
  border-bottom-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-y-orange-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-900\/0 {
  border-top-color: rgb(124 45 18 / 0) !important;
  border-bottom-color: rgb(124 45 18 / 0) !important;
}

.tw-border-y-orange-900\/10 {
  border-top-color: rgb(124 45 18 / 0.1) !important;
  border-bottom-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-y-orange-900\/100 {
  border-top-color: rgb(124 45 18 / 1) !important;
  border-bottom-color: rgb(124 45 18 / 1) !important;
}

.tw-border-y-orange-900\/15 {
  border-top-color: rgb(124 45 18 / 0.15) !important;
  border-bottom-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-y-orange-900\/20 {
  border-top-color: rgb(124 45 18 / 0.2) !important;
  border-bottom-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-y-orange-900\/25 {
  border-top-color: rgb(124 45 18 / 0.25) !important;
  border-bottom-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-y-orange-900\/30 {
  border-top-color: rgb(124 45 18 / 0.3) !important;
  border-bottom-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-y-orange-900\/35 {
  border-top-color: rgb(124 45 18 / 0.35) !important;
  border-bottom-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-y-orange-900\/40 {
  border-top-color: rgb(124 45 18 / 0.4) !important;
  border-bottom-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-y-orange-900\/45 {
  border-top-color: rgb(124 45 18 / 0.45) !important;
  border-bottom-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-y-orange-900\/5 {
  border-top-color: rgb(124 45 18 / 0.05) !important;
  border-bottom-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-y-orange-900\/50 {
  border-top-color: rgb(124 45 18 / 0.5) !important;
  border-bottom-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-y-orange-900\/55 {
  border-top-color: rgb(124 45 18 / 0.55) !important;
  border-bottom-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-y-orange-900\/60 {
  border-top-color: rgb(124 45 18 / 0.6) !important;
  border-bottom-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-y-orange-900\/65 {
  border-top-color: rgb(124 45 18 / 0.65) !important;
  border-bottom-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-y-orange-900\/70 {
  border-top-color: rgb(124 45 18 / 0.7) !important;
  border-bottom-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-y-orange-900\/75 {
  border-top-color: rgb(124 45 18 / 0.75) !important;
  border-bottom-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-y-orange-900\/80 {
  border-top-color: rgb(124 45 18 / 0.8) !important;
  border-bottom-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-y-orange-900\/85 {
  border-top-color: rgb(124 45 18 / 0.85) !important;
  border-bottom-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-y-orange-900\/90 {
  border-top-color: rgb(124 45 18 / 0.9) !important;
  border-bottom-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-y-orange-900\/95 {
  border-top-color: rgb(124 45 18 / 0.95) !important;
  border-bottom-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-y-orange-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-y-orange-950\/0 {
  border-top-color: rgb(67 20 7 / 0) !important;
  border-bottom-color: rgb(67 20 7 / 0) !important;
}

.tw-border-y-orange-950\/10 {
  border-top-color: rgb(67 20 7 / 0.1) !important;
  border-bottom-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-y-orange-950\/100 {
  border-top-color: rgb(67 20 7 / 1) !important;
  border-bottom-color: rgb(67 20 7 / 1) !important;
}

.tw-border-y-orange-950\/15 {
  border-top-color: rgb(67 20 7 / 0.15) !important;
  border-bottom-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-y-orange-950\/20 {
  border-top-color: rgb(67 20 7 / 0.2) !important;
  border-bottom-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-y-orange-950\/25 {
  border-top-color: rgb(67 20 7 / 0.25) !important;
  border-bottom-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-y-orange-950\/30 {
  border-top-color: rgb(67 20 7 / 0.3) !important;
  border-bottom-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-y-orange-950\/35 {
  border-top-color: rgb(67 20 7 / 0.35) !important;
  border-bottom-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-y-orange-950\/40 {
  border-top-color: rgb(67 20 7 / 0.4) !important;
  border-bottom-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-y-orange-950\/45 {
  border-top-color: rgb(67 20 7 / 0.45) !important;
  border-bottom-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-y-orange-950\/5 {
  border-top-color: rgb(67 20 7 / 0.05) !important;
  border-bottom-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-y-orange-950\/50 {
  border-top-color: rgb(67 20 7 / 0.5) !important;
  border-bottom-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-y-orange-950\/55 {
  border-top-color: rgb(67 20 7 / 0.55) !important;
  border-bottom-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-y-orange-950\/60 {
  border-top-color: rgb(67 20 7 / 0.6) !important;
  border-bottom-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-y-orange-950\/65 {
  border-top-color: rgb(67 20 7 / 0.65) !important;
  border-bottom-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-y-orange-950\/70 {
  border-top-color: rgb(67 20 7 / 0.7) !important;
  border-bottom-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-y-orange-950\/75 {
  border-top-color: rgb(67 20 7 / 0.75) !important;
  border-bottom-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-y-orange-950\/80 {
  border-top-color: rgb(67 20 7 / 0.8) !important;
  border-bottom-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-y-orange-950\/85 {
  border-top-color: rgb(67 20 7 / 0.85) !important;
  border-bottom-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-y-orange-950\/90 {
  border-top-color: rgb(67 20 7 / 0.9) !important;
  border-bottom-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-y-orange-950\/95 {
  border-top-color: rgb(67 20 7 / 0.95) !important;
  border-bottom-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-y-pink-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-100\/0 {
  border-top-color: rgb(252 231 243 / 0) !important;
  border-bottom-color: rgb(252 231 243 / 0) !important;
}

.tw-border-y-pink-100\/10 {
  border-top-color: rgb(252 231 243 / 0.1) !important;
  border-bottom-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-y-pink-100\/100 {
  border-top-color: rgb(252 231 243 / 1) !important;
  border-bottom-color: rgb(252 231 243 / 1) !important;
}

.tw-border-y-pink-100\/15 {
  border-top-color: rgb(252 231 243 / 0.15) !important;
  border-bottom-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-y-pink-100\/20 {
  border-top-color: rgb(252 231 243 / 0.2) !important;
  border-bottom-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-y-pink-100\/25 {
  border-top-color: rgb(252 231 243 / 0.25) !important;
  border-bottom-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-y-pink-100\/30 {
  border-top-color: rgb(252 231 243 / 0.3) !important;
  border-bottom-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-y-pink-100\/35 {
  border-top-color: rgb(252 231 243 / 0.35) !important;
  border-bottom-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-y-pink-100\/40 {
  border-top-color: rgb(252 231 243 / 0.4) !important;
  border-bottom-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-y-pink-100\/45 {
  border-top-color: rgb(252 231 243 / 0.45) !important;
  border-bottom-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-y-pink-100\/5 {
  border-top-color: rgb(252 231 243 / 0.05) !important;
  border-bottom-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-y-pink-100\/50 {
  border-top-color: rgb(252 231 243 / 0.5) !important;
  border-bottom-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-y-pink-100\/55 {
  border-top-color: rgb(252 231 243 / 0.55) !important;
  border-bottom-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-y-pink-100\/60 {
  border-top-color: rgb(252 231 243 / 0.6) !important;
  border-bottom-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-y-pink-100\/65 {
  border-top-color: rgb(252 231 243 / 0.65) !important;
  border-bottom-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-y-pink-100\/70 {
  border-top-color: rgb(252 231 243 / 0.7) !important;
  border-bottom-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-y-pink-100\/75 {
  border-top-color: rgb(252 231 243 / 0.75) !important;
  border-bottom-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-y-pink-100\/80 {
  border-top-color: rgb(252 231 243 / 0.8) !important;
  border-bottom-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-y-pink-100\/85 {
  border-top-color: rgb(252 231 243 / 0.85) !important;
  border-bottom-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-y-pink-100\/90 {
  border-top-color: rgb(252 231 243 / 0.9) !important;
  border-bottom-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-y-pink-100\/95 {
  border-top-color: rgb(252 231 243 / 0.95) !important;
  border-bottom-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-y-pink-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-200\/0 {
  border-top-color: rgb(251 207 232 / 0) !important;
  border-bottom-color: rgb(251 207 232 / 0) !important;
}

.tw-border-y-pink-200\/10 {
  border-top-color: rgb(251 207 232 / 0.1) !important;
  border-bottom-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-y-pink-200\/100 {
  border-top-color: rgb(251 207 232 / 1) !important;
  border-bottom-color: rgb(251 207 232 / 1) !important;
}

.tw-border-y-pink-200\/15 {
  border-top-color: rgb(251 207 232 / 0.15) !important;
  border-bottom-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-y-pink-200\/20 {
  border-top-color: rgb(251 207 232 / 0.2) !important;
  border-bottom-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-y-pink-200\/25 {
  border-top-color: rgb(251 207 232 / 0.25) !important;
  border-bottom-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-y-pink-200\/30 {
  border-top-color: rgb(251 207 232 / 0.3) !important;
  border-bottom-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-y-pink-200\/35 {
  border-top-color: rgb(251 207 232 / 0.35) !important;
  border-bottom-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-y-pink-200\/40 {
  border-top-color: rgb(251 207 232 / 0.4) !important;
  border-bottom-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-y-pink-200\/45 {
  border-top-color: rgb(251 207 232 / 0.45) !important;
  border-bottom-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-y-pink-200\/5 {
  border-top-color: rgb(251 207 232 / 0.05) !important;
  border-bottom-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-y-pink-200\/50 {
  border-top-color: rgb(251 207 232 / 0.5) !important;
  border-bottom-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-y-pink-200\/55 {
  border-top-color: rgb(251 207 232 / 0.55) !important;
  border-bottom-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-y-pink-200\/60 {
  border-top-color: rgb(251 207 232 / 0.6) !important;
  border-bottom-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-y-pink-200\/65 {
  border-top-color: rgb(251 207 232 / 0.65) !important;
  border-bottom-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-y-pink-200\/70 {
  border-top-color: rgb(251 207 232 / 0.7) !important;
  border-bottom-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-y-pink-200\/75 {
  border-top-color: rgb(251 207 232 / 0.75) !important;
  border-bottom-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-y-pink-200\/80 {
  border-top-color: rgb(251 207 232 / 0.8) !important;
  border-bottom-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-y-pink-200\/85 {
  border-top-color: rgb(251 207 232 / 0.85) !important;
  border-bottom-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-y-pink-200\/90 {
  border-top-color: rgb(251 207 232 / 0.9) !important;
  border-bottom-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-y-pink-200\/95 {
  border-top-color: rgb(251 207 232 / 0.95) !important;
  border-bottom-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-y-pink-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-300\/0 {
  border-top-color: rgb(249 168 212 / 0) !important;
  border-bottom-color: rgb(249 168 212 / 0) !important;
}

.tw-border-y-pink-300\/10 {
  border-top-color: rgb(249 168 212 / 0.1) !important;
  border-bottom-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-y-pink-300\/100 {
  border-top-color: rgb(249 168 212 / 1) !important;
  border-bottom-color: rgb(249 168 212 / 1) !important;
}

.tw-border-y-pink-300\/15 {
  border-top-color: rgb(249 168 212 / 0.15) !important;
  border-bottom-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-y-pink-300\/20 {
  border-top-color: rgb(249 168 212 / 0.2) !important;
  border-bottom-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-y-pink-300\/25 {
  border-top-color: rgb(249 168 212 / 0.25) !important;
  border-bottom-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-y-pink-300\/30 {
  border-top-color: rgb(249 168 212 / 0.3) !important;
  border-bottom-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-y-pink-300\/35 {
  border-top-color: rgb(249 168 212 / 0.35) !important;
  border-bottom-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-y-pink-300\/40 {
  border-top-color: rgb(249 168 212 / 0.4) !important;
  border-bottom-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-y-pink-300\/45 {
  border-top-color: rgb(249 168 212 / 0.45) !important;
  border-bottom-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-y-pink-300\/5 {
  border-top-color: rgb(249 168 212 / 0.05) !important;
  border-bottom-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-y-pink-300\/50 {
  border-top-color: rgb(249 168 212 / 0.5) !important;
  border-bottom-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-y-pink-300\/55 {
  border-top-color: rgb(249 168 212 / 0.55) !important;
  border-bottom-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-y-pink-300\/60 {
  border-top-color: rgb(249 168 212 / 0.6) !important;
  border-bottom-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-y-pink-300\/65 {
  border-top-color: rgb(249 168 212 / 0.65) !important;
  border-bottom-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-y-pink-300\/70 {
  border-top-color: rgb(249 168 212 / 0.7) !important;
  border-bottom-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-y-pink-300\/75 {
  border-top-color: rgb(249 168 212 / 0.75) !important;
  border-bottom-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-y-pink-300\/80 {
  border-top-color: rgb(249 168 212 / 0.8) !important;
  border-bottom-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-y-pink-300\/85 {
  border-top-color: rgb(249 168 212 / 0.85) !important;
  border-bottom-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-y-pink-300\/90 {
  border-top-color: rgb(249 168 212 / 0.9) !important;
  border-bottom-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-y-pink-300\/95 {
  border-top-color: rgb(249 168 212 / 0.95) !important;
  border-bottom-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-y-pink-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-400\/0 {
  border-top-color: rgb(244 114 182 / 0) !important;
  border-bottom-color: rgb(244 114 182 / 0) !important;
}

.tw-border-y-pink-400\/10 {
  border-top-color: rgb(244 114 182 / 0.1) !important;
  border-bottom-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-y-pink-400\/100 {
  border-top-color: rgb(244 114 182 / 1) !important;
  border-bottom-color: rgb(244 114 182 / 1) !important;
}

.tw-border-y-pink-400\/15 {
  border-top-color: rgb(244 114 182 / 0.15) !important;
  border-bottom-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-y-pink-400\/20 {
  border-top-color: rgb(244 114 182 / 0.2) !important;
  border-bottom-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-y-pink-400\/25 {
  border-top-color: rgb(244 114 182 / 0.25) !important;
  border-bottom-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-y-pink-400\/30 {
  border-top-color: rgb(244 114 182 / 0.3) !important;
  border-bottom-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-y-pink-400\/35 {
  border-top-color: rgb(244 114 182 / 0.35) !important;
  border-bottom-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-y-pink-400\/40 {
  border-top-color: rgb(244 114 182 / 0.4) !important;
  border-bottom-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-y-pink-400\/45 {
  border-top-color: rgb(244 114 182 / 0.45) !important;
  border-bottom-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-y-pink-400\/5 {
  border-top-color: rgb(244 114 182 / 0.05) !important;
  border-bottom-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-y-pink-400\/50 {
  border-top-color: rgb(244 114 182 / 0.5) !important;
  border-bottom-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-y-pink-400\/55 {
  border-top-color: rgb(244 114 182 / 0.55) !important;
  border-bottom-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-y-pink-400\/60 {
  border-top-color: rgb(244 114 182 / 0.6) !important;
  border-bottom-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-y-pink-400\/65 {
  border-top-color: rgb(244 114 182 / 0.65) !important;
  border-bottom-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-y-pink-400\/70 {
  border-top-color: rgb(244 114 182 / 0.7) !important;
  border-bottom-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-y-pink-400\/75 {
  border-top-color: rgb(244 114 182 / 0.75) !important;
  border-bottom-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-y-pink-400\/80 {
  border-top-color: rgb(244 114 182 / 0.8) !important;
  border-bottom-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-y-pink-400\/85 {
  border-top-color: rgb(244 114 182 / 0.85) !important;
  border-bottom-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-y-pink-400\/90 {
  border-top-color: rgb(244 114 182 / 0.9) !important;
  border-bottom-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-y-pink-400\/95 {
  border-top-color: rgb(244 114 182 / 0.95) !important;
  border-bottom-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-y-pink-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-50\/0 {
  border-top-color: rgb(253 242 248 / 0) !important;
  border-bottom-color: rgb(253 242 248 / 0) !important;
}

.tw-border-y-pink-50\/10 {
  border-top-color: rgb(253 242 248 / 0.1) !important;
  border-bottom-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-y-pink-50\/100 {
  border-top-color: rgb(253 242 248 / 1) !important;
  border-bottom-color: rgb(253 242 248 / 1) !important;
}

.tw-border-y-pink-50\/15 {
  border-top-color: rgb(253 242 248 / 0.15) !important;
  border-bottom-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-y-pink-50\/20 {
  border-top-color: rgb(253 242 248 / 0.2) !important;
  border-bottom-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-y-pink-50\/25 {
  border-top-color: rgb(253 242 248 / 0.25) !important;
  border-bottom-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-y-pink-50\/30 {
  border-top-color: rgb(253 242 248 / 0.3) !important;
  border-bottom-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-y-pink-50\/35 {
  border-top-color: rgb(253 242 248 / 0.35) !important;
  border-bottom-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-y-pink-50\/40 {
  border-top-color: rgb(253 242 248 / 0.4) !important;
  border-bottom-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-y-pink-50\/45 {
  border-top-color: rgb(253 242 248 / 0.45) !important;
  border-bottom-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-y-pink-50\/5 {
  border-top-color: rgb(253 242 248 / 0.05) !important;
  border-bottom-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-y-pink-50\/50 {
  border-top-color: rgb(253 242 248 / 0.5) !important;
  border-bottom-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-y-pink-50\/55 {
  border-top-color: rgb(253 242 248 / 0.55) !important;
  border-bottom-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-y-pink-50\/60 {
  border-top-color: rgb(253 242 248 / 0.6) !important;
  border-bottom-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-y-pink-50\/65 {
  border-top-color: rgb(253 242 248 / 0.65) !important;
  border-bottom-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-y-pink-50\/70 {
  border-top-color: rgb(253 242 248 / 0.7) !important;
  border-bottom-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-y-pink-50\/75 {
  border-top-color: rgb(253 242 248 / 0.75) !important;
  border-bottom-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-y-pink-50\/80 {
  border-top-color: rgb(253 242 248 / 0.8) !important;
  border-bottom-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-y-pink-50\/85 {
  border-top-color: rgb(253 242 248 / 0.85) !important;
  border-bottom-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-y-pink-50\/90 {
  border-top-color: rgb(253 242 248 / 0.9) !important;
  border-bottom-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-y-pink-50\/95 {
  border-top-color: rgb(253 242 248 / 0.95) !important;
  border-bottom-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-y-pink-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-500\/0 {
  border-top-color: rgb(236 72 153 / 0) !important;
  border-bottom-color: rgb(236 72 153 / 0) !important;
}

.tw-border-y-pink-500\/10 {
  border-top-color: rgb(236 72 153 / 0.1) !important;
  border-bottom-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-y-pink-500\/100 {
  border-top-color: rgb(236 72 153 / 1) !important;
  border-bottom-color: rgb(236 72 153 / 1) !important;
}

.tw-border-y-pink-500\/15 {
  border-top-color: rgb(236 72 153 / 0.15) !important;
  border-bottom-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-y-pink-500\/20 {
  border-top-color: rgb(236 72 153 / 0.2) !important;
  border-bottom-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-y-pink-500\/25 {
  border-top-color: rgb(236 72 153 / 0.25) !important;
  border-bottom-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-y-pink-500\/30 {
  border-top-color: rgb(236 72 153 / 0.3) !important;
  border-bottom-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-y-pink-500\/35 {
  border-top-color: rgb(236 72 153 / 0.35) !important;
  border-bottom-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-y-pink-500\/40 {
  border-top-color: rgb(236 72 153 / 0.4) !important;
  border-bottom-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-y-pink-500\/45 {
  border-top-color: rgb(236 72 153 / 0.45) !important;
  border-bottom-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-y-pink-500\/5 {
  border-top-color: rgb(236 72 153 / 0.05) !important;
  border-bottom-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-y-pink-500\/50 {
  border-top-color: rgb(236 72 153 / 0.5) !important;
  border-bottom-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-y-pink-500\/55 {
  border-top-color: rgb(236 72 153 / 0.55) !important;
  border-bottom-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-y-pink-500\/60 {
  border-top-color: rgb(236 72 153 / 0.6) !important;
  border-bottom-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-y-pink-500\/65 {
  border-top-color: rgb(236 72 153 / 0.65) !important;
  border-bottom-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-y-pink-500\/70 {
  border-top-color: rgb(236 72 153 / 0.7) !important;
  border-bottom-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-y-pink-500\/75 {
  border-top-color: rgb(236 72 153 / 0.75) !important;
  border-bottom-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-y-pink-500\/80 {
  border-top-color: rgb(236 72 153 / 0.8) !important;
  border-bottom-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-y-pink-500\/85 {
  border-top-color: rgb(236 72 153 / 0.85) !important;
  border-bottom-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-y-pink-500\/90 {
  border-top-color: rgb(236 72 153 / 0.9) !important;
  border-bottom-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-y-pink-500\/95 {
  border-top-color: rgb(236 72 153 / 0.95) !important;
  border-bottom-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-y-pink-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-600\/0 {
  border-top-color: rgb(219 39 119 / 0) !important;
  border-bottom-color: rgb(219 39 119 / 0) !important;
}

.tw-border-y-pink-600\/10 {
  border-top-color: rgb(219 39 119 / 0.1) !important;
  border-bottom-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-y-pink-600\/100 {
  border-top-color: rgb(219 39 119 / 1) !important;
  border-bottom-color: rgb(219 39 119 / 1) !important;
}

.tw-border-y-pink-600\/15 {
  border-top-color: rgb(219 39 119 / 0.15) !important;
  border-bottom-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-y-pink-600\/20 {
  border-top-color: rgb(219 39 119 / 0.2) !important;
  border-bottom-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-y-pink-600\/25 {
  border-top-color: rgb(219 39 119 / 0.25) !important;
  border-bottom-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-y-pink-600\/30 {
  border-top-color: rgb(219 39 119 / 0.3) !important;
  border-bottom-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-y-pink-600\/35 {
  border-top-color: rgb(219 39 119 / 0.35) !important;
  border-bottom-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-y-pink-600\/40 {
  border-top-color: rgb(219 39 119 / 0.4) !important;
  border-bottom-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-y-pink-600\/45 {
  border-top-color: rgb(219 39 119 / 0.45) !important;
  border-bottom-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-y-pink-600\/5 {
  border-top-color: rgb(219 39 119 / 0.05) !important;
  border-bottom-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-y-pink-600\/50 {
  border-top-color: rgb(219 39 119 / 0.5) !important;
  border-bottom-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-y-pink-600\/55 {
  border-top-color: rgb(219 39 119 / 0.55) !important;
  border-bottom-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-y-pink-600\/60 {
  border-top-color: rgb(219 39 119 / 0.6) !important;
  border-bottom-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-y-pink-600\/65 {
  border-top-color: rgb(219 39 119 / 0.65) !important;
  border-bottom-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-y-pink-600\/70 {
  border-top-color: rgb(219 39 119 / 0.7) !important;
  border-bottom-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-y-pink-600\/75 {
  border-top-color: rgb(219 39 119 / 0.75) !important;
  border-bottom-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-y-pink-600\/80 {
  border-top-color: rgb(219 39 119 / 0.8) !important;
  border-bottom-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-y-pink-600\/85 {
  border-top-color: rgb(219 39 119 / 0.85) !important;
  border-bottom-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-y-pink-600\/90 {
  border-top-color: rgb(219 39 119 / 0.9) !important;
  border-bottom-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-y-pink-600\/95 {
  border-top-color: rgb(219 39 119 / 0.95) !important;
  border-bottom-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-y-pink-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-700\/0 {
  border-top-color: rgb(190 24 93 / 0) !important;
  border-bottom-color: rgb(190 24 93 / 0) !important;
}

.tw-border-y-pink-700\/10 {
  border-top-color: rgb(190 24 93 / 0.1) !important;
  border-bottom-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-y-pink-700\/100 {
  border-top-color: rgb(190 24 93 / 1) !important;
  border-bottom-color: rgb(190 24 93 / 1) !important;
}

.tw-border-y-pink-700\/15 {
  border-top-color: rgb(190 24 93 / 0.15) !important;
  border-bottom-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-y-pink-700\/20 {
  border-top-color: rgb(190 24 93 / 0.2) !important;
  border-bottom-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-y-pink-700\/25 {
  border-top-color: rgb(190 24 93 / 0.25) !important;
  border-bottom-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-y-pink-700\/30 {
  border-top-color: rgb(190 24 93 / 0.3) !important;
  border-bottom-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-y-pink-700\/35 {
  border-top-color: rgb(190 24 93 / 0.35) !important;
  border-bottom-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-y-pink-700\/40 {
  border-top-color: rgb(190 24 93 / 0.4) !important;
  border-bottom-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-y-pink-700\/45 {
  border-top-color: rgb(190 24 93 / 0.45) !important;
  border-bottom-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-y-pink-700\/5 {
  border-top-color: rgb(190 24 93 / 0.05) !important;
  border-bottom-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-y-pink-700\/50 {
  border-top-color: rgb(190 24 93 / 0.5) !important;
  border-bottom-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-y-pink-700\/55 {
  border-top-color: rgb(190 24 93 / 0.55) !important;
  border-bottom-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-y-pink-700\/60 {
  border-top-color: rgb(190 24 93 / 0.6) !important;
  border-bottom-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-y-pink-700\/65 {
  border-top-color: rgb(190 24 93 / 0.65) !important;
  border-bottom-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-y-pink-700\/70 {
  border-top-color: rgb(190 24 93 / 0.7) !important;
  border-bottom-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-y-pink-700\/75 {
  border-top-color: rgb(190 24 93 / 0.75) !important;
  border-bottom-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-y-pink-700\/80 {
  border-top-color: rgb(190 24 93 / 0.8) !important;
  border-bottom-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-y-pink-700\/85 {
  border-top-color: rgb(190 24 93 / 0.85) !important;
  border-bottom-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-y-pink-700\/90 {
  border-top-color: rgb(190 24 93 / 0.9) !important;
  border-bottom-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-y-pink-700\/95 {
  border-top-color: rgb(190 24 93 / 0.95) !important;
  border-bottom-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-y-pink-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-800\/0 {
  border-top-color: rgb(157 23 77 / 0) !important;
  border-bottom-color: rgb(157 23 77 / 0) !important;
}

.tw-border-y-pink-800\/10 {
  border-top-color: rgb(157 23 77 / 0.1) !important;
  border-bottom-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-y-pink-800\/100 {
  border-top-color: rgb(157 23 77 / 1) !important;
  border-bottom-color: rgb(157 23 77 / 1) !important;
}

.tw-border-y-pink-800\/15 {
  border-top-color: rgb(157 23 77 / 0.15) !important;
  border-bottom-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-y-pink-800\/20 {
  border-top-color: rgb(157 23 77 / 0.2) !important;
  border-bottom-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-y-pink-800\/25 {
  border-top-color: rgb(157 23 77 / 0.25) !important;
  border-bottom-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-y-pink-800\/30 {
  border-top-color: rgb(157 23 77 / 0.3) !important;
  border-bottom-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-y-pink-800\/35 {
  border-top-color: rgb(157 23 77 / 0.35) !important;
  border-bottom-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-y-pink-800\/40 {
  border-top-color: rgb(157 23 77 / 0.4) !important;
  border-bottom-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-y-pink-800\/45 {
  border-top-color: rgb(157 23 77 / 0.45) !important;
  border-bottom-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-y-pink-800\/5 {
  border-top-color: rgb(157 23 77 / 0.05) !important;
  border-bottom-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-y-pink-800\/50 {
  border-top-color: rgb(157 23 77 / 0.5) !important;
  border-bottom-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-y-pink-800\/55 {
  border-top-color: rgb(157 23 77 / 0.55) !important;
  border-bottom-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-y-pink-800\/60 {
  border-top-color: rgb(157 23 77 / 0.6) !important;
  border-bottom-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-y-pink-800\/65 {
  border-top-color: rgb(157 23 77 / 0.65) !important;
  border-bottom-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-y-pink-800\/70 {
  border-top-color: rgb(157 23 77 / 0.7) !important;
  border-bottom-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-y-pink-800\/75 {
  border-top-color: rgb(157 23 77 / 0.75) !important;
  border-bottom-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-y-pink-800\/80 {
  border-top-color: rgb(157 23 77 / 0.8) !important;
  border-bottom-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-y-pink-800\/85 {
  border-top-color: rgb(157 23 77 / 0.85) !important;
  border-bottom-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-y-pink-800\/90 {
  border-top-color: rgb(157 23 77 / 0.9) !important;
  border-bottom-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-y-pink-800\/95 {
  border-top-color: rgb(157 23 77 / 0.95) !important;
  border-bottom-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-y-pink-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-900\/0 {
  border-top-color: rgb(131 24 67 / 0) !important;
  border-bottom-color: rgb(131 24 67 / 0) !important;
}

.tw-border-y-pink-900\/10 {
  border-top-color: rgb(131 24 67 / 0.1) !important;
  border-bottom-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-y-pink-900\/100 {
  border-top-color: rgb(131 24 67 / 1) !important;
  border-bottom-color: rgb(131 24 67 / 1) !important;
}

.tw-border-y-pink-900\/15 {
  border-top-color: rgb(131 24 67 / 0.15) !important;
  border-bottom-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-y-pink-900\/20 {
  border-top-color: rgb(131 24 67 / 0.2) !important;
  border-bottom-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-y-pink-900\/25 {
  border-top-color: rgb(131 24 67 / 0.25) !important;
  border-bottom-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-y-pink-900\/30 {
  border-top-color: rgb(131 24 67 / 0.3) !important;
  border-bottom-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-y-pink-900\/35 {
  border-top-color: rgb(131 24 67 / 0.35) !important;
  border-bottom-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-y-pink-900\/40 {
  border-top-color: rgb(131 24 67 / 0.4) !important;
  border-bottom-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-y-pink-900\/45 {
  border-top-color: rgb(131 24 67 / 0.45) !important;
  border-bottom-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-y-pink-900\/5 {
  border-top-color: rgb(131 24 67 / 0.05) !important;
  border-bottom-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-y-pink-900\/50 {
  border-top-color: rgb(131 24 67 / 0.5) !important;
  border-bottom-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-y-pink-900\/55 {
  border-top-color: rgb(131 24 67 / 0.55) !important;
  border-bottom-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-y-pink-900\/60 {
  border-top-color: rgb(131 24 67 / 0.6) !important;
  border-bottom-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-y-pink-900\/65 {
  border-top-color: rgb(131 24 67 / 0.65) !important;
  border-bottom-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-y-pink-900\/70 {
  border-top-color: rgb(131 24 67 / 0.7) !important;
  border-bottom-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-y-pink-900\/75 {
  border-top-color: rgb(131 24 67 / 0.75) !important;
  border-bottom-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-y-pink-900\/80 {
  border-top-color: rgb(131 24 67 / 0.8) !important;
  border-bottom-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-y-pink-900\/85 {
  border-top-color: rgb(131 24 67 / 0.85) !important;
  border-bottom-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-y-pink-900\/90 {
  border-top-color: rgb(131 24 67 / 0.9) !important;
  border-bottom-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-y-pink-900\/95 {
  border-top-color: rgb(131 24 67 / 0.95) !important;
  border-bottom-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-y-pink-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-y-pink-950\/0 {
  border-top-color: rgb(80 7 36 / 0) !important;
  border-bottom-color: rgb(80 7 36 / 0) !important;
}

.tw-border-y-pink-950\/10 {
  border-top-color: rgb(80 7 36 / 0.1) !important;
  border-bottom-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-y-pink-950\/100 {
  border-top-color: rgb(80 7 36 / 1) !important;
  border-bottom-color: rgb(80 7 36 / 1) !important;
}

.tw-border-y-pink-950\/15 {
  border-top-color: rgb(80 7 36 / 0.15) !important;
  border-bottom-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-y-pink-950\/20 {
  border-top-color: rgb(80 7 36 / 0.2) !important;
  border-bottom-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-y-pink-950\/25 {
  border-top-color: rgb(80 7 36 / 0.25) !important;
  border-bottom-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-y-pink-950\/30 {
  border-top-color: rgb(80 7 36 / 0.3) !important;
  border-bottom-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-y-pink-950\/35 {
  border-top-color: rgb(80 7 36 / 0.35) !important;
  border-bottom-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-y-pink-950\/40 {
  border-top-color: rgb(80 7 36 / 0.4) !important;
  border-bottom-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-y-pink-950\/45 {
  border-top-color: rgb(80 7 36 / 0.45) !important;
  border-bottom-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-y-pink-950\/5 {
  border-top-color: rgb(80 7 36 / 0.05) !important;
  border-bottom-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-y-pink-950\/50 {
  border-top-color: rgb(80 7 36 / 0.5) !important;
  border-bottom-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-y-pink-950\/55 {
  border-top-color: rgb(80 7 36 / 0.55) !important;
  border-bottom-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-y-pink-950\/60 {
  border-top-color: rgb(80 7 36 / 0.6) !important;
  border-bottom-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-y-pink-950\/65 {
  border-top-color: rgb(80 7 36 / 0.65) !important;
  border-bottom-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-y-pink-950\/70 {
  border-top-color: rgb(80 7 36 / 0.7) !important;
  border-bottom-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-y-pink-950\/75 {
  border-top-color: rgb(80 7 36 / 0.75) !important;
  border-bottom-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-y-pink-950\/80 {
  border-top-color: rgb(80 7 36 / 0.8) !important;
  border-bottom-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-y-pink-950\/85 {
  border-top-color: rgb(80 7 36 / 0.85) !important;
  border-bottom-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-y-pink-950\/90 {
  border-top-color: rgb(80 7 36 / 0.9) !important;
  border-bottom-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-y-pink-950\/95 {
  border-top-color: rgb(80 7 36 / 0.95) !important;
  border-bottom-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-y-purple-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-100\/0 {
  border-top-color: rgb(243 232 255 / 0) !important;
  border-bottom-color: rgb(243 232 255 / 0) !important;
}

.tw-border-y-purple-100\/10 {
  border-top-color: rgb(243 232 255 / 0.1) !important;
  border-bottom-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-y-purple-100\/100 {
  border-top-color: rgb(243 232 255 / 1) !important;
  border-bottom-color: rgb(243 232 255 / 1) !important;
}

.tw-border-y-purple-100\/15 {
  border-top-color: rgb(243 232 255 / 0.15) !important;
  border-bottom-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-y-purple-100\/20 {
  border-top-color: rgb(243 232 255 / 0.2) !important;
  border-bottom-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-y-purple-100\/25 {
  border-top-color: rgb(243 232 255 / 0.25) !important;
  border-bottom-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-y-purple-100\/30 {
  border-top-color: rgb(243 232 255 / 0.3) !important;
  border-bottom-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-y-purple-100\/35 {
  border-top-color: rgb(243 232 255 / 0.35) !important;
  border-bottom-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-y-purple-100\/40 {
  border-top-color: rgb(243 232 255 / 0.4) !important;
  border-bottom-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-y-purple-100\/45 {
  border-top-color: rgb(243 232 255 / 0.45) !important;
  border-bottom-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-y-purple-100\/5 {
  border-top-color: rgb(243 232 255 / 0.05) !important;
  border-bottom-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-y-purple-100\/50 {
  border-top-color: rgb(243 232 255 / 0.5) !important;
  border-bottom-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-y-purple-100\/55 {
  border-top-color: rgb(243 232 255 / 0.55) !important;
  border-bottom-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-y-purple-100\/60 {
  border-top-color: rgb(243 232 255 / 0.6) !important;
  border-bottom-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-y-purple-100\/65 {
  border-top-color: rgb(243 232 255 / 0.65) !important;
  border-bottom-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-y-purple-100\/70 {
  border-top-color: rgb(243 232 255 / 0.7) !important;
  border-bottom-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-y-purple-100\/75 {
  border-top-color: rgb(243 232 255 / 0.75) !important;
  border-bottom-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-y-purple-100\/80 {
  border-top-color: rgb(243 232 255 / 0.8) !important;
  border-bottom-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-y-purple-100\/85 {
  border-top-color: rgb(243 232 255 / 0.85) !important;
  border-bottom-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-y-purple-100\/90 {
  border-top-color: rgb(243 232 255 / 0.9) !important;
  border-bottom-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-y-purple-100\/95 {
  border-top-color: rgb(243 232 255 / 0.95) !important;
  border-bottom-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-y-purple-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-200\/0 {
  border-top-color: rgb(233 213 255 / 0) !important;
  border-bottom-color: rgb(233 213 255 / 0) !important;
}

.tw-border-y-purple-200\/10 {
  border-top-color: rgb(233 213 255 / 0.1) !important;
  border-bottom-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-y-purple-200\/100 {
  border-top-color: rgb(233 213 255 / 1) !important;
  border-bottom-color: rgb(233 213 255 / 1) !important;
}

.tw-border-y-purple-200\/15 {
  border-top-color: rgb(233 213 255 / 0.15) !important;
  border-bottom-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-y-purple-200\/20 {
  border-top-color: rgb(233 213 255 / 0.2) !important;
  border-bottom-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-y-purple-200\/25 {
  border-top-color: rgb(233 213 255 / 0.25) !important;
  border-bottom-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-y-purple-200\/30 {
  border-top-color: rgb(233 213 255 / 0.3) !important;
  border-bottom-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-y-purple-200\/35 {
  border-top-color: rgb(233 213 255 / 0.35) !important;
  border-bottom-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-y-purple-200\/40 {
  border-top-color: rgb(233 213 255 / 0.4) !important;
  border-bottom-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-y-purple-200\/45 {
  border-top-color: rgb(233 213 255 / 0.45) !important;
  border-bottom-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-y-purple-200\/5 {
  border-top-color: rgb(233 213 255 / 0.05) !important;
  border-bottom-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-y-purple-200\/50 {
  border-top-color: rgb(233 213 255 / 0.5) !important;
  border-bottom-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-y-purple-200\/55 {
  border-top-color: rgb(233 213 255 / 0.55) !important;
  border-bottom-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-y-purple-200\/60 {
  border-top-color: rgb(233 213 255 / 0.6) !important;
  border-bottom-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-y-purple-200\/65 {
  border-top-color: rgb(233 213 255 / 0.65) !important;
  border-bottom-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-y-purple-200\/70 {
  border-top-color: rgb(233 213 255 / 0.7) !important;
  border-bottom-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-y-purple-200\/75 {
  border-top-color: rgb(233 213 255 / 0.75) !important;
  border-bottom-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-y-purple-200\/80 {
  border-top-color: rgb(233 213 255 / 0.8) !important;
  border-bottom-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-y-purple-200\/85 {
  border-top-color: rgb(233 213 255 / 0.85) !important;
  border-bottom-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-y-purple-200\/90 {
  border-top-color: rgb(233 213 255 / 0.9) !important;
  border-bottom-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-y-purple-200\/95 {
  border-top-color: rgb(233 213 255 / 0.95) !important;
  border-bottom-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-y-purple-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-300\/0 {
  border-top-color: rgb(216 180 254 / 0) !important;
  border-bottom-color: rgb(216 180 254 / 0) !important;
}

.tw-border-y-purple-300\/10 {
  border-top-color: rgb(216 180 254 / 0.1) !important;
  border-bottom-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-y-purple-300\/100 {
  border-top-color: rgb(216 180 254 / 1) !important;
  border-bottom-color: rgb(216 180 254 / 1) !important;
}

.tw-border-y-purple-300\/15 {
  border-top-color: rgb(216 180 254 / 0.15) !important;
  border-bottom-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-y-purple-300\/20 {
  border-top-color: rgb(216 180 254 / 0.2) !important;
  border-bottom-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-y-purple-300\/25 {
  border-top-color: rgb(216 180 254 / 0.25) !important;
  border-bottom-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-y-purple-300\/30 {
  border-top-color: rgb(216 180 254 / 0.3) !important;
  border-bottom-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-y-purple-300\/35 {
  border-top-color: rgb(216 180 254 / 0.35) !important;
  border-bottom-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-y-purple-300\/40 {
  border-top-color: rgb(216 180 254 / 0.4) !important;
  border-bottom-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-y-purple-300\/45 {
  border-top-color: rgb(216 180 254 / 0.45) !important;
  border-bottom-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-y-purple-300\/5 {
  border-top-color: rgb(216 180 254 / 0.05) !important;
  border-bottom-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-y-purple-300\/50 {
  border-top-color: rgb(216 180 254 / 0.5) !important;
  border-bottom-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-y-purple-300\/55 {
  border-top-color: rgb(216 180 254 / 0.55) !important;
  border-bottom-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-y-purple-300\/60 {
  border-top-color: rgb(216 180 254 / 0.6) !important;
  border-bottom-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-y-purple-300\/65 {
  border-top-color: rgb(216 180 254 / 0.65) !important;
  border-bottom-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-y-purple-300\/70 {
  border-top-color: rgb(216 180 254 / 0.7) !important;
  border-bottom-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-y-purple-300\/75 {
  border-top-color: rgb(216 180 254 / 0.75) !important;
  border-bottom-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-y-purple-300\/80 {
  border-top-color: rgb(216 180 254 / 0.8) !important;
  border-bottom-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-y-purple-300\/85 {
  border-top-color: rgb(216 180 254 / 0.85) !important;
  border-bottom-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-y-purple-300\/90 {
  border-top-color: rgb(216 180 254 / 0.9) !important;
  border-bottom-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-y-purple-300\/95 {
  border-top-color: rgb(216 180 254 / 0.95) !important;
  border-bottom-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-y-purple-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-400\/0 {
  border-top-color: rgb(192 132 252 / 0) !important;
  border-bottom-color: rgb(192 132 252 / 0) !important;
}

.tw-border-y-purple-400\/10 {
  border-top-color: rgb(192 132 252 / 0.1) !important;
  border-bottom-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-y-purple-400\/100 {
  border-top-color: rgb(192 132 252 / 1) !important;
  border-bottom-color: rgb(192 132 252 / 1) !important;
}

.tw-border-y-purple-400\/15 {
  border-top-color: rgb(192 132 252 / 0.15) !important;
  border-bottom-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-y-purple-400\/20 {
  border-top-color: rgb(192 132 252 / 0.2) !important;
  border-bottom-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-y-purple-400\/25 {
  border-top-color: rgb(192 132 252 / 0.25) !important;
  border-bottom-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-y-purple-400\/30 {
  border-top-color: rgb(192 132 252 / 0.3) !important;
  border-bottom-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-y-purple-400\/35 {
  border-top-color: rgb(192 132 252 / 0.35) !important;
  border-bottom-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-y-purple-400\/40 {
  border-top-color: rgb(192 132 252 / 0.4) !important;
  border-bottom-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-y-purple-400\/45 {
  border-top-color: rgb(192 132 252 / 0.45) !important;
  border-bottom-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-y-purple-400\/5 {
  border-top-color: rgb(192 132 252 / 0.05) !important;
  border-bottom-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-y-purple-400\/50 {
  border-top-color: rgb(192 132 252 / 0.5) !important;
  border-bottom-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-y-purple-400\/55 {
  border-top-color: rgb(192 132 252 / 0.55) !important;
  border-bottom-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-y-purple-400\/60 {
  border-top-color: rgb(192 132 252 / 0.6) !important;
  border-bottom-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-y-purple-400\/65 {
  border-top-color: rgb(192 132 252 / 0.65) !important;
  border-bottom-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-y-purple-400\/70 {
  border-top-color: rgb(192 132 252 / 0.7) !important;
  border-bottom-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-y-purple-400\/75 {
  border-top-color: rgb(192 132 252 / 0.75) !important;
  border-bottom-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-y-purple-400\/80 {
  border-top-color: rgb(192 132 252 / 0.8) !important;
  border-bottom-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-y-purple-400\/85 {
  border-top-color: rgb(192 132 252 / 0.85) !important;
  border-bottom-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-y-purple-400\/90 {
  border-top-color: rgb(192 132 252 / 0.9) !important;
  border-bottom-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-y-purple-400\/95 {
  border-top-color: rgb(192 132 252 / 0.95) !important;
  border-bottom-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-y-purple-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-50\/0 {
  border-top-color: rgb(250 245 255 / 0) !important;
  border-bottom-color: rgb(250 245 255 / 0) !important;
}

.tw-border-y-purple-50\/10 {
  border-top-color: rgb(250 245 255 / 0.1) !important;
  border-bottom-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-y-purple-50\/100 {
  border-top-color: rgb(250 245 255 / 1) !important;
  border-bottom-color: rgb(250 245 255 / 1) !important;
}

.tw-border-y-purple-50\/15 {
  border-top-color: rgb(250 245 255 / 0.15) !important;
  border-bottom-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-y-purple-50\/20 {
  border-top-color: rgb(250 245 255 / 0.2) !important;
  border-bottom-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-y-purple-50\/25 {
  border-top-color: rgb(250 245 255 / 0.25) !important;
  border-bottom-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-y-purple-50\/30 {
  border-top-color: rgb(250 245 255 / 0.3) !important;
  border-bottom-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-y-purple-50\/35 {
  border-top-color: rgb(250 245 255 / 0.35) !important;
  border-bottom-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-y-purple-50\/40 {
  border-top-color: rgb(250 245 255 / 0.4) !important;
  border-bottom-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-y-purple-50\/45 {
  border-top-color: rgb(250 245 255 / 0.45) !important;
  border-bottom-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-y-purple-50\/5 {
  border-top-color: rgb(250 245 255 / 0.05) !important;
  border-bottom-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-y-purple-50\/50 {
  border-top-color: rgb(250 245 255 / 0.5) !important;
  border-bottom-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-y-purple-50\/55 {
  border-top-color: rgb(250 245 255 / 0.55) !important;
  border-bottom-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-y-purple-50\/60 {
  border-top-color: rgb(250 245 255 / 0.6) !important;
  border-bottom-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-y-purple-50\/65 {
  border-top-color: rgb(250 245 255 / 0.65) !important;
  border-bottom-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-y-purple-50\/70 {
  border-top-color: rgb(250 245 255 / 0.7) !important;
  border-bottom-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-y-purple-50\/75 {
  border-top-color: rgb(250 245 255 / 0.75) !important;
  border-bottom-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-y-purple-50\/80 {
  border-top-color: rgb(250 245 255 / 0.8) !important;
  border-bottom-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-y-purple-50\/85 {
  border-top-color: rgb(250 245 255 / 0.85) !important;
  border-bottom-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-y-purple-50\/90 {
  border-top-color: rgb(250 245 255 / 0.9) !important;
  border-bottom-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-y-purple-50\/95 {
  border-top-color: rgb(250 245 255 / 0.95) !important;
  border-bottom-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-y-purple-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-500\/0 {
  border-top-color: rgb(168 85 247 / 0) !important;
  border-bottom-color: rgb(168 85 247 / 0) !important;
}

.tw-border-y-purple-500\/10 {
  border-top-color: rgb(168 85 247 / 0.1) !important;
  border-bottom-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-y-purple-500\/100 {
  border-top-color: rgb(168 85 247 / 1) !important;
  border-bottom-color: rgb(168 85 247 / 1) !important;
}

.tw-border-y-purple-500\/15 {
  border-top-color: rgb(168 85 247 / 0.15) !important;
  border-bottom-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-y-purple-500\/20 {
  border-top-color: rgb(168 85 247 / 0.2) !important;
  border-bottom-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-y-purple-500\/25 {
  border-top-color: rgb(168 85 247 / 0.25) !important;
  border-bottom-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-y-purple-500\/30 {
  border-top-color: rgb(168 85 247 / 0.3) !important;
  border-bottom-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-y-purple-500\/35 {
  border-top-color: rgb(168 85 247 / 0.35) !important;
  border-bottom-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-y-purple-500\/40 {
  border-top-color: rgb(168 85 247 / 0.4) !important;
  border-bottom-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-y-purple-500\/45 {
  border-top-color: rgb(168 85 247 / 0.45) !important;
  border-bottom-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-y-purple-500\/5 {
  border-top-color: rgb(168 85 247 / 0.05) !important;
  border-bottom-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-y-purple-500\/50 {
  border-top-color: rgb(168 85 247 / 0.5) !important;
  border-bottom-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-y-purple-500\/55 {
  border-top-color: rgb(168 85 247 / 0.55) !important;
  border-bottom-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-y-purple-500\/60 {
  border-top-color: rgb(168 85 247 / 0.6) !important;
  border-bottom-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-y-purple-500\/65 {
  border-top-color: rgb(168 85 247 / 0.65) !important;
  border-bottom-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-y-purple-500\/70 {
  border-top-color: rgb(168 85 247 / 0.7) !important;
  border-bottom-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-y-purple-500\/75 {
  border-top-color: rgb(168 85 247 / 0.75) !important;
  border-bottom-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-y-purple-500\/80 {
  border-top-color: rgb(168 85 247 / 0.8) !important;
  border-bottom-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-y-purple-500\/85 {
  border-top-color: rgb(168 85 247 / 0.85) !important;
  border-bottom-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-y-purple-500\/90 {
  border-top-color: rgb(168 85 247 / 0.9) !important;
  border-bottom-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-y-purple-500\/95 {
  border-top-color: rgb(168 85 247 / 0.95) !important;
  border-bottom-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-y-purple-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-600\/0 {
  border-top-color: rgb(147 51 234 / 0) !important;
  border-bottom-color: rgb(147 51 234 / 0) !important;
}

.tw-border-y-purple-600\/10 {
  border-top-color: rgb(147 51 234 / 0.1) !important;
  border-bottom-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-y-purple-600\/100 {
  border-top-color: rgb(147 51 234 / 1) !important;
  border-bottom-color: rgb(147 51 234 / 1) !important;
}

.tw-border-y-purple-600\/15 {
  border-top-color: rgb(147 51 234 / 0.15) !important;
  border-bottom-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-y-purple-600\/20 {
  border-top-color: rgb(147 51 234 / 0.2) !important;
  border-bottom-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-y-purple-600\/25 {
  border-top-color: rgb(147 51 234 / 0.25) !important;
  border-bottom-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-y-purple-600\/30 {
  border-top-color: rgb(147 51 234 / 0.3) !important;
  border-bottom-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-y-purple-600\/35 {
  border-top-color: rgb(147 51 234 / 0.35) !important;
  border-bottom-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-y-purple-600\/40 {
  border-top-color: rgb(147 51 234 / 0.4) !important;
  border-bottom-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-y-purple-600\/45 {
  border-top-color: rgb(147 51 234 / 0.45) !important;
  border-bottom-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-y-purple-600\/5 {
  border-top-color: rgb(147 51 234 / 0.05) !important;
  border-bottom-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-y-purple-600\/50 {
  border-top-color: rgb(147 51 234 / 0.5) !important;
  border-bottom-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-y-purple-600\/55 {
  border-top-color: rgb(147 51 234 / 0.55) !important;
  border-bottom-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-y-purple-600\/60 {
  border-top-color: rgb(147 51 234 / 0.6) !important;
  border-bottom-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-y-purple-600\/65 {
  border-top-color: rgb(147 51 234 / 0.65) !important;
  border-bottom-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-y-purple-600\/70 {
  border-top-color: rgb(147 51 234 / 0.7) !important;
  border-bottom-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-y-purple-600\/75 {
  border-top-color: rgb(147 51 234 / 0.75) !important;
  border-bottom-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-y-purple-600\/80 {
  border-top-color: rgb(147 51 234 / 0.8) !important;
  border-bottom-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-y-purple-600\/85 {
  border-top-color: rgb(147 51 234 / 0.85) !important;
  border-bottom-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-y-purple-600\/90 {
  border-top-color: rgb(147 51 234 / 0.9) !important;
  border-bottom-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-y-purple-600\/95 {
  border-top-color: rgb(147 51 234 / 0.95) !important;
  border-bottom-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-y-purple-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-700\/0 {
  border-top-color: rgb(126 34 206 / 0) !important;
  border-bottom-color: rgb(126 34 206 / 0) !important;
}

.tw-border-y-purple-700\/10 {
  border-top-color: rgb(126 34 206 / 0.1) !important;
  border-bottom-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-y-purple-700\/100 {
  border-top-color: rgb(126 34 206 / 1) !important;
  border-bottom-color: rgb(126 34 206 / 1) !important;
}

.tw-border-y-purple-700\/15 {
  border-top-color: rgb(126 34 206 / 0.15) !important;
  border-bottom-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-y-purple-700\/20 {
  border-top-color: rgb(126 34 206 / 0.2) !important;
  border-bottom-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-y-purple-700\/25 {
  border-top-color: rgb(126 34 206 / 0.25) !important;
  border-bottom-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-y-purple-700\/30 {
  border-top-color: rgb(126 34 206 / 0.3) !important;
  border-bottom-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-y-purple-700\/35 {
  border-top-color: rgb(126 34 206 / 0.35) !important;
  border-bottom-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-y-purple-700\/40 {
  border-top-color: rgb(126 34 206 / 0.4) !important;
  border-bottom-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-y-purple-700\/45 {
  border-top-color: rgb(126 34 206 / 0.45) !important;
  border-bottom-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-y-purple-700\/5 {
  border-top-color: rgb(126 34 206 / 0.05) !important;
  border-bottom-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-y-purple-700\/50 {
  border-top-color: rgb(126 34 206 / 0.5) !important;
  border-bottom-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-y-purple-700\/55 {
  border-top-color: rgb(126 34 206 / 0.55) !important;
  border-bottom-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-y-purple-700\/60 {
  border-top-color: rgb(126 34 206 / 0.6) !important;
  border-bottom-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-y-purple-700\/65 {
  border-top-color: rgb(126 34 206 / 0.65) !important;
  border-bottom-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-y-purple-700\/70 {
  border-top-color: rgb(126 34 206 / 0.7) !important;
  border-bottom-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-y-purple-700\/75 {
  border-top-color: rgb(126 34 206 / 0.75) !important;
  border-bottom-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-y-purple-700\/80 {
  border-top-color: rgb(126 34 206 / 0.8) !important;
  border-bottom-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-y-purple-700\/85 {
  border-top-color: rgb(126 34 206 / 0.85) !important;
  border-bottom-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-y-purple-700\/90 {
  border-top-color: rgb(126 34 206 / 0.9) !important;
  border-bottom-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-y-purple-700\/95 {
  border-top-color: rgb(126 34 206 / 0.95) !important;
  border-bottom-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-y-purple-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-800\/0 {
  border-top-color: rgb(107 33 168 / 0) !important;
  border-bottom-color: rgb(107 33 168 / 0) !important;
}

.tw-border-y-purple-800\/10 {
  border-top-color: rgb(107 33 168 / 0.1) !important;
  border-bottom-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-y-purple-800\/100 {
  border-top-color: rgb(107 33 168 / 1) !important;
  border-bottom-color: rgb(107 33 168 / 1) !important;
}

.tw-border-y-purple-800\/15 {
  border-top-color: rgb(107 33 168 / 0.15) !important;
  border-bottom-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-y-purple-800\/20 {
  border-top-color: rgb(107 33 168 / 0.2) !important;
  border-bottom-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-y-purple-800\/25 {
  border-top-color: rgb(107 33 168 / 0.25) !important;
  border-bottom-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-y-purple-800\/30 {
  border-top-color: rgb(107 33 168 / 0.3) !important;
  border-bottom-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-y-purple-800\/35 {
  border-top-color: rgb(107 33 168 / 0.35) !important;
  border-bottom-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-y-purple-800\/40 {
  border-top-color: rgb(107 33 168 / 0.4) !important;
  border-bottom-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-y-purple-800\/45 {
  border-top-color: rgb(107 33 168 / 0.45) !important;
  border-bottom-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-y-purple-800\/5 {
  border-top-color: rgb(107 33 168 / 0.05) !important;
  border-bottom-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-y-purple-800\/50 {
  border-top-color: rgb(107 33 168 / 0.5) !important;
  border-bottom-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-y-purple-800\/55 {
  border-top-color: rgb(107 33 168 / 0.55) !important;
  border-bottom-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-y-purple-800\/60 {
  border-top-color: rgb(107 33 168 / 0.6) !important;
  border-bottom-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-y-purple-800\/65 {
  border-top-color: rgb(107 33 168 / 0.65) !important;
  border-bottom-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-y-purple-800\/70 {
  border-top-color: rgb(107 33 168 / 0.7) !important;
  border-bottom-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-y-purple-800\/75 {
  border-top-color: rgb(107 33 168 / 0.75) !important;
  border-bottom-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-y-purple-800\/80 {
  border-top-color: rgb(107 33 168 / 0.8) !important;
  border-bottom-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-y-purple-800\/85 {
  border-top-color: rgb(107 33 168 / 0.85) !important;
  border-bottom-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-y-purple-800\/90 {
  border-top-color: rgb(107 33 168 / 0.9) !important;
  border-bottom-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-y-purple-800\/95 {
  border-top-color: rgb(107 33 168 / 0.95) !important;
  border-bottom-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-y-purple-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-900\/0 {
  border-top-color: rgb(88 28 135 / 0) !important;
  border-bottom-color: rgb(88 28 135 / 0) !important;
}

.tw-border-y-purple-900\/10 {
  border-top-color: rgb(88 28 135 / 0.1) !important;
  border-bottom-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-y-purple-900\/100 {
  border-top-color: rgb(88 28 135 / 1) !important;
  border-bottom-color: rgb(88 28 135 / 1) !important;
}

.tw-border-y-purple-900\/15 {
  border-top-color: rgb(88 28 135 / 0.15) !important;
  border-bottom-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-y-purple-900\/20 {
  border-top-color: rgb(88 28 135 / 0.2) !important;
  border-bottom-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-y-purple-900\/25 {
  border-top-color: rgb(88 28 135 / 0.25) !important;
  border-bottom-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-y-purple-900\/30 {
  border-top-color: rgb(88 28 135 / 0.3) !important;
  border-bottom-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-y-purple-900\/35 {
  border-top-color: rgb(88 28 135 / 0.35) !important;
  border-bottom-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-y-purple-900\/40 {
  border-top-color: rgb(88 28 135 / 0.4) !important;
  border-bottom-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-y-purple-900\/45 {
  border-top-color: rgb(88 28 135 / 0.45) !important;
  border-bottom-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-y-purple-900\/5 {
  border-top-color: rgb(88 28 135 / 0.05) !important;
  border-bottom-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-y-purple-900\/50 {
  border-top-color: rgb(88 28 135 / 0.5) !important;
  border-bottom-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-y-purple-900\/55 {
  border-top-color: rgb(88 28 135 / 0.55) !important;
  border-bottom-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-y-purple-900\/60 {
  border-top-color: rgb(88 28 135 / 0.6) !important;
  border-bottom-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-y-purple-900\/65 {
  border-top-color: rgb(88 28 135 / 0.65) !important;
  border-bottom-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-y-purple-900\/70 {
  border-top-color: rgb(88 28 135 / 0.7) !important;
  border-bottom-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-y-purple-900\/75 {
  border-top-color: rgb(88 28 135 / 0.75) !important;
  border-bottom-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-y-purple-900\/80 {
  border-top-color: rgb(88 28 135 / 0.8) !important;
  border-bottom-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-y-purple-900\/85 {
  border-top-color: rgb(88 28 135 / 0.85) !important;
  border-bottom-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-y-purple-900\/90 {
  border-top-color: rgb(88 28 135 / 0.9) !important;
  border-bottom-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-y-purple-900\/95 {
  border-top-color: rgb(88 28 135 / 0.95) !important;
  border-bottom-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-y-purple-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-y-purple-950\/0 {
  border-top-color: rgb(59 7 100 / 0) !important;
  border-bottom-color: rgb(59 7 100 / 0) !important;
}

.tw-border-y-purple-950\/10 {
  border-top-color: rgb(59 7 100 / 0.1) !important;
  border-bottom-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-y-purple-950\/100 {
  border-top-color: rgb(59 7 100 / 1) !important;
  border-bottom-color: rgb(59 7 100 / 1) !important;
}

.tw-border-y-purple-950\/15 {
  border-top-color: rgb(59 7 100 / 0.15) !important;
  border-bottom-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-y-purple-950\/20 {
  border-top-color: rgb(59 7 100 / 0.2) !important;
  border-bottom-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-y-purple-950\/25 {
  border-top-color: rgb(59 7 100 / 0.25) !important;
  border-bottom-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-y-purple-950\/30 {
  border-top-color: rgb(59 7 100 / 0.3) !important;
  border-bottom-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-y-purple-950\/35 {
  border-top-color: rgb(59 7 100 / 0.35) !important;
  border-bottom-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-y-purple-950\/40 {
  border-top-color: rgb(59 7 100 / 0.4) !important;
  border-bottom-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-y-purple-950\/45 {
  border-top-color: rgb(59 7 100 / 0.45) !important;
  border-bottom-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-y-purple-950\/5 {
  border-top-color: rgb(59 7 100 / 0.05) !important;
  border-bottom-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-y-purple-950\/50 {
  border-top-color: rgb(59 7 100 / 0.5) !important;
  border-bottom-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-y-purple-950\/55 {
  border-top-color: rgb(59 7 100 / 0.55) !important;
  border-bottom-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-y-purple-950\/60 {
  border-top-color: rgb(59 7 100 / 0.6) !important;
  border-bottom-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-y-purple-950\/65 {
  border-top-color: rgb(59 7 100 / 0.65) !important;
  border-bottom-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-y-purple-950\/70 {
  border-top-color: rgb(59 7 100 / 0.7) !important;
  border-bottom-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-y-purple-950\/75 {
  border-top-color: rgb(59 7 100 / 0.75) !important;
  border-bottom-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-y-purple-950\/80 {
  border-top-color: rgb(59 7 100 / 0.8) !important;
  border-bottom-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-y-purple-950\/85 {
  border-top-color: rgb(59 7 100 / 0.85) !important;
  border-bottom-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-y-purple-950\/90 {
  border-top-color: rgb(59 7 100 / 0.9) !important;
  border-bottom-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-y-purple-950\/95 {
  border-top-color: rgb(59 7 100 / 0.95) !important;
  border-bottom-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-y-red-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-100\/0 {
  border-top-color: rgb(254 226 226 / 0) !important;
  border-bottom-color: rgb(254 226 226 / 0) !important;
}

.tw-border-y-red-100\/10 {
  border-top-color: rgb(254 226 226 / 0.1) !important;
  border-bottom-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-y-red-100\/100 {
  border-top-color: rgb(254 226 226 / 1) !important;
  border-bottom-color: rgb(254 226 226 / 1) !important;
}

.tw-border-y-red-100\/15 {
  border-top-color: rgb(254 226 226 / 0.15) !important;
  border-bottom-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-y-red-100\/20 {
  border-top-color: rgb(254 226 226 / 0.2) !important;
  border-bottom-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-y-red-100\/25 {
  border-top-color: rgb(254 226 226 / 0.25) !important;
  border-bottom-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-y-red-100\/30 {
  border-top-color: rgb(254 226 226 / 0.3) !important;
  border-bottom-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-y-red-100\/35 {
  border-top-color: rgb(254 226 226 / 0.35) !important;
  border-bottom-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-y-red-100\/40 {
  border-top-color: rgb(254 226 226 / 0.4) !important;
  border-bottom-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-y-red-100\/45 {
  border-top-color: rgb(254 226 226 / 0.45) !important;
  border-bottom-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-y-red-100\/5 {
  border-top-color: rgb(254 226 226 / 0.05) !important;
  border-bottom-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-y-red-100\/50 {
  border-top-color: rgb(254 226 226 / 0.5) !important;
  border-bottom-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-y-red-100\/55 {
  border-top-color: rgb(254 226 226 / 0.55) !important;
  border-bottom-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-y-red-100\/60 {
  border-top-color: rgb(254 226 226 / 0.6) !important;
  border-bottom-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-y-red-100\/65 {
  border-top-color: rgb(254 226 226 / 0.65) !important;
  border-bottom-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-y-red-100\/70 {
  border-top-color: rgb(254 226 226 / 0.7) !important;
  border-bottom-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-y-red-100\/75 {
  border-top-color: rgb(254 226 226 / 0.75) !important;
  border-bottom-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-y-red-100\/80 {
  border-top-color: rgb(254 226 226 / 0.8) !important;
  border-bottom-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-y-red-100\/85 {
  border-top-color: rgb(254 226 226 / 0.85) !important;
  border-bottom-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-y-red-100\/90 {
  border-top-color: rgb(254 226 226 / 0.9) !important;
  border-bottom-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-y-red-100\/95 {
  border-top-color: rgb(254 226 226 / 0.95) !important;
  border-bottom-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-y-red-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-200\/0 {
  border-top-color: rgb(254 202 202 / 0) !important;
  border-bottom-color: rgb(254 202 202 / 0) !important;
}

.tw-border-y-red-200\/10 {
  border-top-color: rgb(254 202 202 / 0.1) !important;
  border-bottom-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-y-red-200\/100 {
  border-top-color: rgb(254 202 202 / 1) !important;
  border-bottom-color: rgb(254 202 202 / 1) !important;
}

.tw-border-y-red-200\/15 {
  border-top-color: rgb(254 202 202 / 0.15) !important;
  border-bottom-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-y-red-200\/20 {
  border-top-color: rgb(254 202 202 / 0.2) !important;
  border-bottom-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-y-red-200\/25 {
  border-top-color: rgb(254 202 202 / 0.25) !important;
  border-bottom-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-y-red-200\/30 {
  border-top-color: rgb(254 202 202 / 0.3) !important;
  border-bottom-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-y-red-200\/35 {
  border-top-color: rgb(254 202 202 / 0.35) !important;
  border-bottom-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-y-red-200\/40 {
  border-top-color: rgb(254 202 202 / 0.4) !important;
  border-bottom-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-y-red-200\/45 {
  border-top-color: rgb(254 202 202 / 0.45) !important;
  border-bottom-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-y-red-200\/5 {
  border-top-color: rgb(254 202 202 / 0.05) !important;
  border-bottom-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-y-red-200\/50 {
  border-top-color: rgb(254 202 202 / 0.5) !important;
  border-bottom-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-y-red-200\/55 {
  border-top-color: rgb(254 202 202 / 0.55) !important;
  border-bottom-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-y-red-200\/60 {
  border-top-color: rgb(254 202 202 / 0.6) !important;
  border-bottom-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-y-red-200\/65 {
  border-top-color: rgb(254 202 202 / 0.65) !important;
  border-bottom-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-y-red-200\/70 {
  border-top-color: rgb(254 202 202 / 0.7) !important;
  border-bottom-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-y-red-200\/75 {
  border-top-color: rgb(254 202 202 / 0.75) !important;
  border-bottom-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-y-red-200\/80 {
  border-top-color: rgb(254 202 202 / 0.8) !important;
  border-bottom-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-y-red-200\/85 {
  border-top-color: rgb(254 202 202 / 0.85) !important;
  border-bottom-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-y-red-200\/90 {
  border-top-color: rgb(254 202 202 / 0.9) !important;
  border-bottom-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-y-red-200\/95 {
  border-top-color: rgb(254 202 202 / 0.95) !important;
  border-bottom-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-y-red-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-300\/0 {
  border-top-color: rgb(252 165 165 / 0) !important;
  border-bottom-color: rgb(252 165 165 / 0) !important;
}

.tw-border-y-red-300\/10 {
  border-top-color: rgb(252 165 165 / 0.1) !important;
  border-bottom-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-y-red-300\/100 {
  border-top-color: rgb(252 165 165 / 1) !important;
  border-bottom-color: rgb(252 165 165 / 1) !important;
}

.tw-border-y-red-300\/15 {
  border-top-color: rgb(252 165 165 / 0.15) !important;
  border-bottom-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-y-red-300\/20 {
  border-top-color: rgb(252 165 165 / 0.2) !important;
  border-bottom-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-y-red-300\/25 {
  border-top-color: rgb(252 165 165 / 0.25) !important;
  border-bottom-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-y-red-300\/30 {
  border-top-color: rgb(252 165 165 / 0.3) !important;
  border-bottom-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-y-red-300\/35 {
  border-top-color: rgb(252 165 165 / 0.35) !important;
  border-bottom-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-y-red-300\/40 {
  border-top-color: rgb(252 165 165 / 0.4) !important;
  border-bottom-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-y-red-300\/45 {
  border-top-color: rgb(252 165 165 / 0.45) !important;
  border-bottom-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-y-red-300\/5 {
  border-top-color: rgb(252 165 165 / 0.05) !important;
  border-bottom-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-y-red-300\/50 {
  border-top-color: rgb(252 165 165 / 0.5) !important;
  border-bottom-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-y-red-300\/55 {
  border-top-color: rgb(252 165 165 / 0.55) !important;
  border-bottom-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-y-red-300\/60 {
  border-top-color: rgb(252 165 165 / 0.6) !important;
  border-bottom-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-y-red-300\/65 {
  border-top-color: rgb(252 165 165 / 0.65) !important;
  border-bottom-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-y-red-300\/70 {
  border-top-color: rgb(252 165 165 / 0.7) !important;
  border-bottom-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-y-red-300\/75 {
  border-top-color: rgb(252 165 165 / 0.75) !important;
  border-bottom-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-y-red-300\/80 {
  border-top-color: rgb(252 165 165 / 0.8) !important;
  border-bottom-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-y-red-300\/85 {
  border-top-color: rgb(252 165 165 / 0.85) !important;
  border-bottom-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-y-red-300\/90 {
  border-top-color: rgb(252 165 165 / 0.9) !important;
  border-bottom-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-y-red-300\/95 {
  border-top-color: rgb(252 165 165 / 0.95) !important;
  border-bottom-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-y-red-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-400\/0 {
  border-top-color: rgb(248 113 113 / 0) !important;
  border-bottom-color: rgb(248 113 113 / 0) !important;
}

.tw-border-y-red-400\/10 {
  border-top-color: rgb(248 113 113 / 0.1) !important;
  border-bottom-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-y-red-400\/100 {
  border-top-color: rgb(248 113 113 / 1) !important;
  border-bottom-color: rgb(248 113 113 / 1) !important;
}

.tw-border-y-red-400\/15 {
  border-top-color: rgb(248 113 113 / 0.15) !important;
  border-bottom-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-y-red-400\/20 {
  border-top-color: rgb(248 113 113 / 0.2) !important;
  border-bottom-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-y-red-400\/25 {
  border-top-color: rgb(248 113 113 / 0.25) !important;
  border-bottom-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-y-red-400\/30 {
  border-top-color: rgb(248 113 113 / 0.3) !important;
  border-bottom-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-y-red-400\/35 {
  border-top-color: rgb(248 113 113 / 0.35) !important;
  border-bottom-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-y-red-400\/40 {
  border-top-color: rgb(248 113 113 / 0.4) !important;
  border-bottom-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-y-red-400\/45 {
  border-top-color: rgb(248 113 113 / 0.45) !important;
  border-bottom-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-y-red-400\/5 {
  border-top-color: rgb(248 113 113 / 0.05) !important;
  border-bottom-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-y-red-400\/50 {
  border-top-color: rgb(248 113 113 / 0.5) !important;
  border-bottom-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-y-red-400\/55 {
  border-top-color: rgb(248 113 113 / 0.55) !important;
  border-bottom-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-y-red-400\/60 {
  border-top-color: rgb(248 113 113 / 0.6) !important;
  border-bottom-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-y-red-400\/65 {
  border-top-color: rgb(248 113 113 / 0.65) !important;
  border-bottom-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-y-red-400\/70 {
  border-top-color: rgb(248 113 113 / 0.7) !important;
  border-bottom-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-y-red-400\/75 {
  border-top-color: rgb(248 113 113 / 0.75) !important;
  border-bottom-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-y-red-400\/80 {
  border-top-color: rgb(248 113 113 / 0.8) !important;
  border-bottom-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-y-red-400\/85 {
  border-top-color: rgb(248 113 113 / 0.85) !important;
  border-bottom-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-y-red-400\/90 {
  border-top-color: rgb(248 113 113 / 0.9) !important;
  border-bottom-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-y-red-400\/95 {
  border-top-color: rgb(248 113 113 / 0.95) !important;
  border-bottom-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-y-red-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-50\/0 {
  border-top-color: rgb(254 242 242 / 0) !important;
  border-bottom-color: rgb(254 242 242 / 0) !important;
}

.tw-border-y-red-50\/10 {
  border-top-color: rgb(254 242 242 / 0.1) !important;
  border-bottom-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-y-red-50\/100 {
  border-top-color: rgb(254 242 242 / 1) !important;
  border-bottom-color: rgb(254 242 242 / 1) !important;
}

.tw-border-y-red-50\/15 {
  border-top-color: rgb(254 242 242 / 0.15) !important;
  border-bottom-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-y-red-50\/20 {
  border-top-color: rgb(254 242 242 / 0.2) !important;
  border-bottom-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-y-red-50\/25 {
  border-top-color: rgb(254 242 242 / 0.25) !important;
  border-bottom-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-y-red-50\/30 {
  border-top-color: rgb(254 242 242 / 0.3) !important;
  border-bottom-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-y-red-50\/35 {
  border-top-color: rgb(254 242 242 / 0.35) !important;
  border-bottom-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-y-red-50\/40 {
  border-top-color: rgb(254 242 242 / 0.4) !important;
  border-bottom-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-y-red-50\/45 {
  border-top-color: rgb(254 242 242 / 0.45) !important;
  border-bottom-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-y-red-50\/5 {
  border-top-color: rgb(254 242 242 / 0.05) !important;
  border-bottom-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-y-red-50\/50 {
  border-top-color: rgb(254 242 242 / 0.5) !important;
  border-bottom-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-y-red-50\/55 {
  border-top-color: rgb(254 242 242 / 0.55) !important;
  border-bottom-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-y-red-50\/60 {
  border-top-color: rgb(254 242 242 / 0.6) !important;
  border-bottom-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-y-red-50\/65 {
  border-top-color: rgb(254 242 242 / 0.65) !important;
  border-bottom-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-y-red-50\/70 {
  border-top-color: rgb(254 242 242 / 0.7) !important;
  border-bottom-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-y-red-50\/75 {
  border-top-color: rgb(254 242 242 / 0.75) !important;
  border-bottom-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-y-red-50\/80 {
  border-top-color: rgb(254 242 242 / 0.8) !important;
  border-bottom-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-y-red-50\/85 {
  border-top-color: rgb(254 242 242 / 0.85) !important;
  border-bottom-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-y-red-50\/90 {
  border-top-color: rgb(254 242 242 / 0.9) !important;
  border-bottom-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-y-red-50\/95 {
  border-top-color: rgb(254 242 242 / 0.95) !important;
  border-bottom-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-y-red-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-500\/0 {
  border-top-color: rgb(239 68 68 / 0) !important;
  border-bottom-color: rgb(239 68 68 / 0) !important;
}

.tw-border-y-red-500\/10 {
  border-top-color: rgb(239 68 68 / 0.1) !important;
  border-bottom-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-y-red-500\/100 {
  border-top-color: rgb(239 68 68 / 1) !important;
  border-bottom-color: rgb(239 68 68 / 1) !important;
}

.tw-border-y-red-500\/15 {
  border-top-color: rgb(239 68 68 / 0.15) !important;
  border-bottom-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-y-red-500\/20 {
  border-top-color: rgb(239 68 68 / 0.2) !important;
  border-bottom-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-y-red-500\/25 {
  border-top-color: rgb(239 68 68 / 0.25) !important;
  border-bottom-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-y-red-500\/30 {
  border-top-color: rgb(239 68 68 / 0.3) !important;
  border-bottom-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-y-red-500\/35 {
  border-top-color: rgb(239 68 68 / 0.35) !important;
  border-bottom-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-y-red-500\/40 {
  border-top-color: rgb(239 68 68 / 0.4) !important;
  border-bottom-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-y-red-500\/45 {
  border-top-color: rgb(239 68 68 / 0.45) !important;
  border-bottom-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-y-red-500\/5 {
  border-top-color: rgb(239 68 68 / 0.05) !important;
  border-bottom-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-y-red-500\/50 {
  border-top-color: rgb(239 68 68 / 0.5) !important;
  border-bottom-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-y-red-500\/55 {
  border-top-color: rgb(239 68 68 / 0.55) !important;
  border-bottom-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-y-red-500\/60 {
  border-top-color: rgb(239 68 68 / 0.6) !important;
  border-bottom-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-y-red-500\/65 {
  border-top-color: rgb(239 68 68 / 0.65) !important;
  border-bottom-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-y-red-500\/70 {
  border-top-color: rgb(239 68 68 / 0.7) !important;
  border-bottom-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-y-red-500\/75 {
  border-top-color: rgb(239 68 68 / 0.75) !important;
  border-bottom-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-y-red-500\/80 {
  border-top-color: rgb(239 68 68 / 0.8) !important;
  border-bottom-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-y-red-500\/85 {
  border-top-color: rgb(239 68 68 / 0.85) !important;
  border-bottom-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-y-red-500\/90 {
  border-top-color: rgb(239 68 68 / 0.9) !important;
  border-bottom-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-y-red-500\/95 {
  border-top-color: rgb(239 68 68 / 0.95) !important;
  border-bottom-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-y-red-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-600\/0 {
  border-top-color: rgb(220 38 38 / 0) !important;
  border-bottom-color: rgb(220 38 38 / 0) !important;
}

.tw-border-y-red-600\/10 {
  border-top-color: rgb(220 38 38 / 0.1) !important;
  border-bottom-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-y-red-600\/100 {
  border-top-color: rgb(220 38 38 / 1) !important;
  border-bottom-color: rgb(220 38 38 / 1) !important;
}

.tw-border-y-red-600\/15 {
  border-top-color: rgb(220 38 38 / 0.15) !important;
  border-bottom-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-y-red-600\/20 {
  border-top-color: rgb(220 38 38 / 0.2) !important;
  border-bottom-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-y-red-600\/25 {
  border-top-color: rgb(220 38 38 / 0.25) !important;
  border-bottom-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-y-red-600\/30 {
  border-top-color: rgb(220 38 38 / 0.3) !important;
  border-bottom-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-y-red-600\/35 {
  border-top-color: rgb(220 38 38 / 0.35) !important;
  border-bottom-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-y-red-600\/40 {
  border-top-color: rgb(220 38 38 / 0.4) !important;
  border-bottom-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-y-red-600\/45 {
  border-top-color: rgb(220 38 38 / 0.45) !important;
  border-bottom-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-y-red-600\/5 {
  border-top-color: rgb(220 38 38 / 0.05) !important;
  border-bottom-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-y-red-600\/50 {
  border-top-color: rgb(220 38 38 / 0.5) !important;
  border-bottom-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-y-red-600\/55 {
  border-top-color: rgb(220 38 38 / 0.55) !important;
  border-bottom-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-y-red-600\/60 {
  border-top-color: rgb(220 38 38 / 0.6) !important;
  border-bottom-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-y-red-600\/65 {
  border-top-color: rgb(220 38 38 / 0.65) !important;
  border-bottom-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-y-red-600\/70 {
  border-top-color: rgb(220 38 38 / 0.7) !important;
  border-bottom-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-y-red-600\/75 {
  border-top-color: rgb(220 38 38 / 0.75) !important;
  border-bottom-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-y-red-600\/80 {
  border-top-color: rgb(220 38 38 / 0.8) !important;
  border-bottom-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-y-red-600\/85 {
  border-top-color: rgb(220 38 38 / 0.85) !important;
  border-bottom-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-y-red-600\/90 {
  border-top-color: rgb(220 38 38 / 0.9) !important;
  border-bottom-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-y-red-600\/95 {
  border-top-color: rgb(220 38 38 / 0.95) !important;
  border-bottom-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-y-red-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-700\/0 {
  border-top-color: rgb(185 28 28 / 0) !important;
  border-bottom-color: rgb(185 28 28 / 0) !important;
}

.tw-border-y-red-700\/10 {
  border-top-color: rgb(185 28 28 / 0.1) !important;
  border-bottom-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-y-red-700\/100 {
  border-top-color: rgb(185 28 28 / 1) !important;
  border-bottom-color: rgb(185 28 28 / 1) !important;
}

.tw-border-y-red-700\/15 {
  border-top-color: rgb(185 28 28 / 0.15) !important;
  border-bottom-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-y-red-700\/20 {
  border-top-color: rgb(185 28 28 / 0.2) !important;
  border-bottom-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-y-red-700\/25 {
  border-top-color: rgb(185 28 28 / 0.25) !important;
  border-bottom-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-y-red-700\/30 {
  border-top-color: rgb(185 28 28 / 0.3) !important;
  border-bottom-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-y-red-700\/35 {
  border-top-color: rgb(185 28 28 / 0.35) !important;
  border-bottom-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-y-red-700\/40 {
  border-top-color: rgb(185 28 28 / 0.4) !important;
  border-bottom-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-y-red-700\/45 {
  border-top-color: rgb(185 28 28 / 0.45) !important;
  border-bottom-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-y-red-700\/5 {
  border-top-color: rgb(185 28 28 / 0.05) !important;
  border-bottom-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-y-red-700\/50 {
  border-top-color: rgb(185 28 28 / 0.5) !important;
  border-bottom-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-y-red-700\/55 {
  border-top-color: rgb(185 28 28 / 0.55) !important;
  border-bottom-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-y-red-700\/60 {
  border-top-color: rgb(185 28 28 / 0.6) !important;
  border-bottom-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-y-red-700\/65 {
  border-top-color: rgb(185 28 28 / 0.65) !important;
  border-bottom-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-y-red-700\/70 {
  border-top-color: rgb(185 28 28 / 0.7) !important;
  border-bottom-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-y-red-700\/75 {
  border-top-color: rgb(185 28 28 / 0.75) !important;
  border-bottom-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-y-red-700\/80 {
  border-top-color: rgb(185 28 28 / 0.8) !important;
  border-bottom-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-y-red-700\/85 {
  border-top-color: rgb(185 28 28 / 0.85) !important;
  border-bottom-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-y-red-700\/90 {
  border-top-color: rgb(185 28 28 / 0.9) !important;
  border-bottom-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-y-red-700\/95 {
  border-top-color: rgb(185 28 28 / 0.95) !important;
  border-bottom-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-y-red-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-800\/0 {
  border-top-color: rgb(153 27 27 / 0) !important;
  border-bottom-color: rgb(153 27 27 / 0) !important;
}

.tw-border-y-red-800\/10 {
  border-top-color: rgb(153 27 27 / 0.1) !important;
  border-bottom-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-y-red-800\/100 {
  border-top-color: rgb(153 27 27 / 1) !important;
  border-bottom-color: rgb(153 27 27 / 1) !important;
}

.tw-border-y-red-800\/15 {
  border-top-color: rgb(153 27 27 / 0.15) !important;
  border-bottom-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-y-red-800\/20 {
  border-top-color: rgb(153 27 27 / 0.2) !important;
  border-bottom-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-y-red-800\/25 {
  border-top-color: rgb(153 27 27 / 0.25) !important;
  border-bottom-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-y-red-800\/30 {
  border-top-color: rgb(153 27 27 / 0.3) !important;
  border-bottom-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-y-red-800\/35 {
  border-top-color: rgb(153 27 27 / 0.35) !important;
  border-bottom-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-y-red-800\/40 {
  border-top-color: rgb(153 27 27 / 0.4) !important;
  border-bottom-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-y-red-800\/45 {
  border-top-color: rgb(153 27 27 / 0.45) !important;
  border-bottom-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-y-red-800\/5 {
  border-top-color: rgb(153 27 27 / 0.05) !important;
  border-bottom-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-y-red-800\/50 {
  border-top-color: rgb(153 27 27 / 0.5) !important;
  border-bottom-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-y-red-800\/55 {
  border-top-color: rgb(153 27 27 / 0.55) !important;
  border-bottom-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-y-red-800\/60 {
  border-top-color: rgb(153 27 27 / 0.6) !important;
  border-bottom-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-y-red-800\/65 {
  border-top-color: rgb(153 27 27 / 0.65) !important;
  border-bottom-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-y-red-800\/70 {
  border-top-color: rgb(153 27 27 / 0.7) !important;
  border-bottom-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-y-red-800\/75 {
  border-top-color: rgb(153 27 27 / 0.75) !important;
  border-bottom-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-y-red-800\/80 {
  border-top-color: rgb(153 27 27 / 0.8) !important;
  border-bottom-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-y-red-800\/85 {
  border-top-color: rgb(153 27 27 / 0.85) !important;
  border-bottom-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-y-red-800\/90 {
  border-top-color: rgb(153 27 27 / 0.9) !important;
  border-bottom-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-y-red-800\/95 {
  border-top-color: rgb(153 27 27 / 0.95) !important;
  border-bottom-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-y-red-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-900\/0 {
  border-top-color: rgb(127 29 29 / 0) !important;
  border-bottom-color: rgb(127 29 29 / 0) !important;
}

.tw-border-y-red-900\/10 {
  border-top-color: rgb(127 29 29 / 0.1) !important;
  border-bottom-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-y-red-900\/100 {
  border-top-color: rgb(127 29 29 / 1) !important;
  border-bottom-color: rgb(127 29 29 / 1) !important;
}

.tw-border-y-red-900\/15 {
  border-top-color: rgb(127 29 29 / 0.15) !important;
  border-bottom-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-y-red-900\/20 {
  border-top-color: rgb(127 29 29 / 0.2) !important;
  border-bottom-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-y-red-900\/25 {
  border-top-color: rgb(127 29 29 / 0.25) !important;
  border-bottom-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-y-red-900\/30 {
  border-top-color: rgb(127 29 29 / 0.3) !important;
  border-bottom-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-y-red-900\/35 {
  border-top-color: rgb(127 29 29 / 0.35) !important;
  border-bottom-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-y-red-900\/40 {
  border-top-color: rgb(127 29 29 / 0.4) !important;
  border-bottom-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-y-red-900\/45 {
  border-top-color: rgb(127 29 29 / 0.45) !important;
  border-bottom-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-y-red-900\/5 {
  border-top-color: rgb(127 29 29 / 0.05) !important;
  border-bottom-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-y-red-900\/50 {
  border-top-color: rgb(127 29 29 / 0.5) !important;
  border-bottom-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-y-red-900\/55 {
  border-top-color: rgb(127 29 29 / 0.55) !important;
  border-bottom-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-y-red-900\/60 {
  border-top-color: rgb(127 29 29 / 0.6) !important;
  border-bottom-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-y-red-900\/65 {
  border-top-color: rgb(127 29 29 / 0.65) !important;
  border-bottom-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-y-red-900\/70 {
  border-top-color: rgb(127 29 29 / 0.7) !important;
  border-bottom-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-y-red-900\/75 {
  border-top-color: rgb(127 29 29 / 0.75) !important;
  border-bottom-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-y-red-900\/80 {
  border-top-color: rgb(127 29 29 / 0.8) !important;
  border-bottom-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-y-red-900\/85 {
  border-top-color: rgb(127 29 29 / 0.85) !important;
  border-bottom-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-y-red-900\/90 {
  border-top-color: rgb(127 29 29 / 0.9) !important;
  border-bottom-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-y-red-900\/95 {
  border-top-color: rgb(127 29 29 / 0.95) !important;
  border-bottom-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-y-red-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-y-red-950\/0 {
  border-top-color: rgb(69 10 10 / 0) !important;
  border-bottom-color: rgb(69 10 10 / 0) !important;
}

.tw-border-y-red-950\/10 {
  border-top-color: rgb(69 10 10 / 0.1) !important;
  border-bottom-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-y-red-950\/100 {
  border-top-color: rgb(69 10 10 / 1) !important;
  border-bottom-color: rgb(69 10 10 / 1) !important;
}

.tw-border-y-red-950\/15 {
  border-top-color: rgb(69 10 10 / 0.15) !important;
  border-bottom-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-y-red-950\/20 {
  border-top-color: rgb(69 10 10 / 0.2) !important;
  border-bottom-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-y-red-950\/25 {
  border-top-color: rgb(69 10 10 / 0.25) !important;
  border-bottom-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-y-red-950\/30 {
  border-top-color: rgb(69 10 10 / 0.3) !important;
  border-bottom-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-y-red-950\/35 {
  border-top-color: rgb(69 10 10 / 0.35) !important;
  border-bottom-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-y-red-950\/40 {
  border-top-color: rgb(69 10 10 / 0.4) !important;
  border-bottom-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-y-red-950\/45 {
  border-top-color: rgb(69 10 10 / 0.45) !important;
  border-bottom-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-y-red-950\/5 {
  border-top-color: rgb(69 10 10 / 0.05) !important;
  border-bottom-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-y-red-950\/50 {
  border-top-color: rgb(69 10 10 / 0.5) !important;
  border-bottom-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-y-red-950\/55 {
  border-top-color: rgb(69 10 10 / 0.55) !important;
  border-bottom-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-y-red-950\/60 {
  border-top-color: rgb(69 10 10 / 0.6) !important;
  border-bottom-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-y-red-950\/65 {
  border-top-color: rgb(69 10 10 / 0.65) !important;
  border-bottom-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-y-red-950\/70 {
  border-top-color: rgb(69 10 10 / 0.7) !important;
  border-bottom-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-y-red-950\/75 {
  border-top-color: rgb(69 10 10 / 0.75) !important;
  border-bottom-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-y-red-950\/80 {
  border-top-color: rgb(69 10 10 / 0.8) !important;
  border-bottom-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-y-red-950\/85 {
  border-top-color: rgb(69 10 10 / 0.85) !important;
  border-bottom-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-y-red-950\/90 {
  border-top-color: rgb(69 10 10 / 0.9) !important;
  border-bottom-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-y-red-950\/95 {
  border-top-color: rgb(69 10 10 / 0.95) !important;
  border-bottom-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-y-rose-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-100\/0 {
  border-top-color: rgb(255 228 230 / 0) !important;
  border-bottom-color: rgb(255 228 230 / 0) !important;
}

.tw-border-y-rose-100\/10 {
  border-top-color: rgb(255 228 230 / 0.1) !important;
  border-bottom-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-y-rose-100\/100 {
  border-top-color: rgb(255 228 230 / 1) !important;
  border-bottom-color: rgb(255 228 230 / 1) !important;
}

.tw-border-y-rose-100\/15 {
  border-top-color: rgb(255 228 230 / 0.15) !important;
  border-bottom-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-y-rose-100\/20 {
  border-top-color: rgb(255 228 230 / 0.2) !important;
  border-bottom-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-y-rose-100\/25 {
  border-top-color: rgb(255 228 230 / 0.25) !important;
  border-bottom-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-y-rose-100\/30 {
  border-top-color: rgb(255 228 230 / 0.3) !important;
  border-bottom-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-y-rose-100\/35 {
  border-top-color: rgb(255 228 230 / 0.35) !important;
  border-bottom-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-y-rose-100\/40 {
  border-top-color: rgb(255 228 230 / 0.4) !important;
  border-bottom-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-y-rose-100\/45 {
  border-top-color: rgb(255 228 230 / 0.45) !important;
  border-bottom-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-y-rose-100\/5 {
  border-top-color: rgb(255 228 230 / 0.05) !important;
  border-bottom-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-y-rose-100\/50 {
  border-top-color: rgb(255 228 230 / 0.5) !important;
  border-bottom-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-y-rose-100\/55 {
  border-top-color: rgb(255 228 230 / 0.55) !important;
  border-bottom-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-y-rose-100\/60 {
  border-top-color: rgb(255 228 230 / 0.6) !important;
  border-bottom-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-y-rose-100\/65 {
  border-top-color: rgb(255 228 230 / 0.65) !important;
  border-bottom-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-y-rose-100\/70 {
  border-top-color: rgb(255 228 230 / 0.7) !important;
  border-bottom-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-y-rose-100\/75 {
  border-top-color: rgb(255 228 230 / 0.75) !important;
  border-bottom-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-y-rose-100\/80 {
  border-top-color: rgb(255 228 230 / 0.8) !important;
  border-bottom-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-y-rose-100\/85 {
  border-top-color: rgb(255 228 230 / 0.85) !important;
  border-bottom-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-y-rose-100\/90 {
  border-top-color: rgb(255 228 230 / 0.9) !important;
  border-bottom-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-y-rose-100\/95 {
  border-top-color: rgb(255 228 230 / 0.95) !important;
  border-bottom-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-y-rose-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-200\/0 {
  border-top-color: rgb(254 205 211 / 0) !important;
  border-bottom-color: rgb(254 205 211 / 0) !important;
}

.tw-border-y-rose-200\/10 {
  border-top-color: rgb(254 205 211 / 0.1) !important;
  border-bottom-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-y-rose-200\/100 {
  border-top-color: rgb(254 205 211 / 1) !important;
  border-bottom-color: rgb(254 205 211 / 1) !important;
}

.tw-border-y-rose-200\/15 {
  border-top-color: rgb(254 205 211 / 0.15) !important;
  border-bottom-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-y-rose-200\/20 {
  border-top-color: rgb(254 205 211 / 0.2) !important;
  border-bottom-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-y-rose-200\/25 {
  border-top-color: rgb(254 205 211 / 0.25) !important;
  border-bottom-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-y-rose-200\/30 {
  border-top-color: rgb(254 205 211 / 0.3) !important;
  border-bottom-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-y-rose-200\/35 {
  border-top-color: rgb(254 205 211 / 0.35) !important;
  border-bottom-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-y-rose-200\/40 {
  border-top-color: rgb(254 205 211 / 0.4) !important;
  border-bottom-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-y-rose-200\/45 {
  border-top-color: rgb(254 205 211 / 0.45) !important;
  border-bottom-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-y-rose-200\/5 {
  border-top-color: rgb(254 205 211 / 0.05) !important;
  border-bottom-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-y-rose-200\/50 {
  border-top-color: rgb(254 205 211 / 0.5) !important;
  border-bottom-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-y-rose-200\/55 {
  border-top-color: rgb(254 205 211 / 0.55) !important;
  border-bottom-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-y-rose-200\/60 {
  border-top-color: rgb(254 205 211 / 0.6) !important;
  border-bottom-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-y-rose-200\/65 {
  border-top-color: rgb(254 205 211 / 0.65) !important;
  border-bottom-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-y-rose-200\/70 {
  border-top-color: rgb(254 205 211 / 0.7) !important;
  border-bottom-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-y-rose-200\/75 {
  border-top-color: rgb(254 205 211 / 0.75) !important;
  border-bottom-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-y-rose-200\/80 {
  border-top-color: rgb(254 205 211 / 0.8) !important;
  border-bottom-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-y-rose-200\/85 {
  border-top-color: rgb(254 205 211 / 0.85) !important;
  border-bottom-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-y-rose-200\/90 {
  border-top-color: rgb(254 205 211 / 0.9) !important;
  border-bottom-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-y-rose-200\/95 {
  border-top-color: rgb(254 205 211 / 0.95) !important;
  border-bottom-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-y-rose-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-300\/0 {
  border-top-color: rgb(253 164 175 / 0) !important;
  border-bottom-color: rgb(253 164 175 / 0) !important;
}

.tw-border-y-rose-300\/10 {
  border-top-color: rgb(253 164 175 / 0.1) !important;
  border-bottom-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-y-rose-300\/100 {
  border-top-color: rgb(253 164 175 / 1) !important;
  border-bottom-color: rgb(253 164 175 / 1) !important;
}

.tw-border-y-rose-300\/15 {
  border-top-color: rgb(253 164 175 / 0.15) !important;
  border-bottom-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-y-rose-300\/20 {
  border-top-color: rgb(253 164 175 / 0.2) !important;
  border-bottom-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-y-rose-300\/25 {
  border-top-color: rgb(253 164 175 / 0.25) !important;
  border-bottom-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-y-rose-300\/30 {
  border-top-color: rgb(253 164 175 / 0.3) !important;
  border-bottom-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-y-rose-300\/35 {
  border-top-color: rgb(253 164 175 / 0.35) !important;
  border-bottom-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-y-rose-300\/40 {
  border-top-color: rgb(253 164 175 / 0.4) !important;
  border-bottom-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-y-rose-300\/45 {
  border-top-color: rgb(253 164 175 / 0.45) !important;
  border-bottom-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-y-rose-300\/5 {
  border-top-color: rgb(253 164 175 / 0.05) !important;
  border-bottom-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-y-rose-300\/50 {
  border-top-color: rgb(253 164 175 / 0.5) !important;
  border-bottom-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-y-rose-300\/55 {
  border-top-color: rgb(253 164 175 / 0.55) !important;
  border-bottom-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-y-rose-300\/60 {
  border-top-color: rgb(253 164 175 / 0.6) !important;
  border-bottom-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-y-rose-300\/65 {
  border-top-color: rgb(253 164 175 / 0.65) !important;
  border-bottom-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-y-rose-300\/70 {
  border-top-color: rgb(253 164 175 / 0.7) !important;
  border-bottom-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-y-rose-300\/75 {
  border-top-color: rgb(253 164 175 / 0.75) !important;
  border-bottom-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-y-rose-300\/80 {
  border-top-color: rgb(253 164 175 / 0.8) !important;
  border-bottom-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-y-rose-300\/85 {
  border-top-color: rgb(253 164 175 / 0.85) !important;
  border-bottom-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-y-rose-300\/90 {
  border-top-color: rgb(253 164 175 / 0.9) !important;
  border-bottom-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-y-rose-300\/95 {
  border-top-color: rgb(253 164 175 / 0.95) !important;
  border-bottom-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-y-rose-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-400\/0 {
  border-top-color: rgb(251 113 133 / 0) !important;
  border-bottom-color: rgb(251 113 133 / 0) !important;
}

.tw-border-y-rose-400\/10 {
  border-top-color: rgb(251 113 133 / 0.1) !important;
  border-bottom-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-y-rose-400\/100 {
  border-top-color: rgb(251 113 133 / 1) !important;
  border-bottom-color: rgb(251 113 133 / 1) !important;
}

.tw-border-y-rose-400\/15 {
  border-top-color: rgb(251 113 133 / 0.15) !important;
  border-bottom-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-y-rose-400\/20 {
  border-top-color: rgb(251 113 133 / 0.2) !important;
  border-bottom-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-y-rose-400\/25 {
  border-top-color: rgb(251 113 133 / 0.25) !important;
  border-bottom-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-y-rose-400\/30 {
  border-top-color: rgb(251 113 133 / 0.3) !important;
  border-bottom-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-y-rose-400\/35 {
  border-top-color: rgb(251 113 133 / 0.35) !important;
  border-bottom-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-y-rose-400\/40 {
  border-top-color: rgb(251 113 133 / 0.4) !important;
  border-bottom-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-y-rose-400\/45 {
  border-top-color: rgb(251 113 133 / 0.45) !important;
  border-bottom-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-y-rose-400\/5 {
  border-top-color: rgb(251 113 133 / 0.05) !important;
  border-bottom-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-y-rose-400\/50 {
  border-top-color: rgb(251 113 133 / 0.5) !important;
  border-bottom-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-y-rose-400\/55 {
  border-top-color: rgb(251 113 133 / 0.55) !important;
  border-bottom-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-y-rose-400\/60 {
  border-top-color: rgb(251 113 133 / 0.6) !important;
  border-bottom-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-y-rose-400\/65 {
  border-top-color: rgb(251 113 133 / 0.65) !important;
  border-bottom-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-y-rose-400\/70 {
  border-top-color: rgb(251 113 133 / 0.7) !important;
  border-bottom-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-y-rose-400\/75 {
  border-top-color: rgb(251 113 133 / 0.75) !important;
  border-bottom-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-y-rose-400\/80 {
  border-top-color: rgb(251 113 133 / 0.8) !important;
  border-bottom-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-y-rose-400\/85 {
  border-top-color: rgb(251 113 133 / 0.85) !important;
  border-bottom-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-y-rose-400\/90 {
  border-top-color: rgb(251 113 133 / 0.9) !important;
  border-bottom-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-y-rose-400\/95 {
  border-top-color: rgb(251 113 133 / 0.95) !important;
  border-bottom-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-y-rose-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-50\/0 {
  border-top-color: rgb(255 241 242 / 0) !important;
  border-bottom-color: rgb(255 241 242 / 0) !important;
}

.tw-border-y-rose-50\/10 {
  border-top-color: rgb(255 241 242 / 0.1) !important;
  border-bottom-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-y-rose-50\/100 {
  border-top-color: rgb(255 241 242 / 1) !important;
  border-bottom-color: rgb(255 241 242 / 1) !important;
}

.tw-border-y-rose-50\/15 {
  border-top-color: rgb(255 241 242 / 0.15) !important;
  border-bottom-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-y-rose-50\/20 {
  border-top-color: rgb(255 241 242 / 0.2) !important;
  border-bottom-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-y-rose-50\/25 {
  border-top-color: rgb(255 241 242 / 0.25) !important;
  border-bottom-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-y-rose-50\/30 {
  border-top-color: rgb(255 241 242 / 0.3) !important;
  border-bottom-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-y-rose-50\/35 {
  border-top-color: rgb(255 241 242 / 0.35) !important;
  border-bottom-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-y-rose-50\/40 {
  border-top-color: rgb(255 241 242 / 0.4) !important;
  border-bottom-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-y-rose-50\/45 {
  border-top-color: rgb(255 241 242 / 0.45) !important;
  border-bottom-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-y-rose-50\/5 {
  border-top-color: rgb(255 241 242 / 0.05) !important;
  border-bottom-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-y-rose-50\/50 {
  border-top-color: rgb(255 241 242 / 0.5) !important;
  border-bottom-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-y-rose-50\/55 {
  border-top-color: rgb(255 241 242 / 0.55) !important;
  border-bottom-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-y-rose-50\/60 {
  border-top-color: rgb(255 241 242 / 0.6) !important;
  border-bottom-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-y-rose-50\/65 {
  border-top-color: rgb(255 241 242 / 0.65) !important;
  border-bottom-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-y-rose-50\/70 {
  border-top-color: rgb(255 241 242 / 0.7) !important;
  border-bottom-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-y-rose-50\/75 {
  border-top-color: rgb(255 241 242 / 0.75) !important;
  border-bottom-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-y-rose-50\/80 {
  border-top-color: rgb(255 241 242 / 0.8) !important;
  border-bottom-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-y-rose-50\/85 {
  border-top-color: rgb(255 241 242 / 0.85) !important;
  border-bottom-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-y-rose-50\/90 {
  border-top-color: rgb(255 241 242 / 0.9) !important;
  border-bottom-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-y-rose-50\/95 {
  border-top-color: rgb(255 241 242 / 0.95) !important;
  border-bottom-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-y-rose-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-500\/0 {
  border-top-color: rgb(244 63 94 / 0) !important;
  border-bottom-color: rgb(244 63 94 / 0) !important;
}

.tw-border-y-rose-500\/10 {
  border-top-color: rgb(244 63 94 / 0.1) !important;
  border-bottom-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-y-rose-500\/100 {
  border-top-color: rgb(244 63 94 / 1) !important;
  border-bottom-color: rgb(244 63 94 / 1) !important;
}

.tw-border-y-rose-500\/15 {
  border-top-color: rgb(244 63 94 / 0.15) !important;
  border-bottom-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-y-rose-500\/20 {
  border-top-color: rgb(244 63 94 / 0.2) !important;
  border-bottom-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-y-rose-500\/25 {
  border-top-color: rgb(244 63 94 / 0.25) !important;
  border-bottom-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-y-rose-500\/30 {
  border-top-color: rgb(244 63 94 / 0.3) !important;
  border-bottom-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-y-rose-500\/35 {
  border-top-color: rgb(244 63 94 / 0.35) !important;
  border-bottom-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-y-rose-500\/40 {
  border-top-color: rgb(244 63 94 / 0.4) !important;
  border-bottom-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-y-rose-500\/45 {
  border-top-color: rgb(244 63 94 / 0.45) !important;
  border-bottom-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-y-rose-500\/5 {
  border-top-color: rgb(244 63 94 / 0.05) !important;
  border-bottom-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-y-rose-500\/50 {
  border-top-color: rgb(244 63 94 / 0.5) !important;
  border-bottom-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-y-rose-500\/55 {
  border-top-color: rgb(244 63 94 / 0.55) !important;
  border-bottom-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-y-rose-500\/60 {
  border-top-color: rgb(244 63 94 / 0.6) !important;
  border-bottom-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-y-rose-500\/65 {
  border-top-color: rgb(244 63 94 / 0.65) !important;
  border-bottom-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-y-rose-500\/70 {
  border-top-color: rgb(244 63 94 / 0.7) !important;
  border-bottom-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-y-rose-500\/75 {
  border-top-color: rgb(244 63 94 / 0.75) !important;
  border-bottom-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-y-rose-500\/80 {
  border-top-color: rgb(244 63 94 / 0.8) !important;
  border-bottom-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-y-rose-500\/85 {
  border-top-color: rgb(244 63 94 / 0.85) !important;
  border-bottom-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-y-rose-500\/90 {
  border-top-color: rgb(244 63 94 / 0.9) !important;
  border-bottom-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-y-rose-500\/95 {
  border-top-color: rgb(244 63 94 / 0.95) !important;
  border-bottom-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-y-rose-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-600\/0 {
  border-top-color: rgb(225 29 72 / 0) !important;
  border-bottom-color: rgb(225 29 72 / 0) !important;
}

.tw-border-y-rose-600\/10 {
  border-top-color: rgb(225 29 72 / 0.1) !important;
  border-bottom-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-y-rose-600\/100 {
  border-top-color: rgb(225 29 72 / 1) !important;
  border-bottom-color: rgb(225 29 72 / 1) !important;
}

.tw-border-y-rose-600\/15 {
  border-top-color: rgb(225 29 72 / 0.15) !important;
  border-bottom-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-y-rose-600\/20 {
  border-top-color: rgb(225 29 72 / 0.2) !important;
  border-bottom-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-y-rose-600\/25 {
  border-top-color: rgb(225 29 72 / 0.25) !important;
  border-bottom-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-y-rose-600\/30 {
  border-top-color: rgb(225 29 72 / 0.3) !important;
  border-bottom-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-y-rose-600\/35 {
  border-top-color: rgb(225 29 72 / 0.35) !important;
  border-bottom-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-y-rose-600\/40 {
  border-top-color: rgb(225 29 72 / 0.4) !important;
  border-bottom-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-y-rose-600\/45 {
  border-top-color: rgb(225 29 72 / 0.45) !important;
  border-bottom-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-y-rose-600\/5 {
  border-top-color: rgb(225 29 72 / 0.05) !important;
  border-bottom-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-y-rose-600\/50 {
  border-top-color: rgb(225 29 72 / 0.5) !important;
  border-bottom-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-y-rose-600\/55 {
  border-top-color: rgb(225 29 72 / 0.55) !important;
  border-bottom-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-y-rose-600\/60 {
  border-top-color: rgb(225 29 72 / 0.6) !important;
  border-bottom-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-y-rose-600\/65 {
  border-top-color: rgb(225 29 72 / 0.65) !important;
  border-bottom-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-y-rose-600\/70 {
  border-top-color: rgb(225 29 72 / 0.7) !important;
  border-bottom-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-y-rose-600\/75 {
  border-top-color: rgb(225 29 72 / 0.75) !important;
  border-bottom-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-y-rose-600\/80 {
  border-top-color: rgb(225 29 72 / 0.8) !important;
  border-bottom-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-y-rose-600\/85 {
  border-top-color: rgb(225 29 72 / 0.85) !important;
  border-bottom-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-y-rose-600\/90 {
  border-top-color: rgb(225 29 72 / 0.9) !important;
  border-bottom-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-y-rose-600\/95 {
  border-top-color: rgb(225 29 72 / 0.95) !important;
  border-bottom-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-y-rose-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-700\/0 {
  border-top-color: rgb(190 18 60 / 0) !important;
  border-bottom-color: rgb(190 18 60 / 0) !important;
}

.tw-border-y-rose-700\/10 {
  border-top-color: rgb(190 18 60 / 0.1) !important;
  border-bottom-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-y-rose-700\/100 {
  border-top-color: rgb(190 18 60 / 1) !important;
  border-bottom-color: rgb(190 18 60 / 1) !important;
}

.tw-border-y-rose-700\/15 {
  border-top-color: rgb(190 18 60 / 0.15) !important;
  border-bottom-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-y-rose-700\/20 {
  border-top-color: rgb(190 18 60 / 0.2) !important;
  border-bottom-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-y-rose-700\/25 {
  border-top-color: rgb(190 18 60 / 0.25) !important;
  border-bottom-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-y-rose-700\/30 {
  border-top-color: rgb(190 18 60 / 0.3) !important;
  border-bottom-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-y-rose-700\/35 {
  border-top-color: rgb(190 18 60 / 0.35) !important;
  border-bottom-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-y-rose-700\/40 {
  border-top-color: rgb(190 18 60 / 0.4) !important;
  border-bottom-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-y-rose-700\/45 {
  border-top-color: rgb(190 18 60 / 0.45) !important;
  border-bottom-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-y-rose-700\/5 {
  border-top-color: rgb(190 18 60 / 0.05) !important;
  border-bottom-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-y-rose-700\/50 {
  border-top-color: rgb(190 18 60 / 0.5) !important;
  border-bottom-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-y-rose-700\/55 {
  border-top-color: rgb(190 18 60 / 0.55) !important;
  border-bottom-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-y-rose-700\/60 {
  border-top-color: rgb(190 18 60 / 0.6) !important;
  border-bottom-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-y-rose-700\/65 {
  border-top-color: rgb(190 18 60 / 0.65) !important;
  border-bottom-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-y-rose-700\/70 {
  border-top-color: rgb(190 18 60 / 0.7) !important;
  border-bottom-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-y-rose-700\/75 {
  border-top-color: rgb(190 18 60 / 0.75) !important;
  border-bottom-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-y-rose-700\/80 {
  border-top-color: rgb(190 18 60 / 0.8) !important;
  border-bottom-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-y-rose-700\/85 {
  border-top-color: rgb(190 18 60 / 0.85) !important;
  border-bottom-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-y-rose-700\/90 {
  border-top-color: rgb(190 18 60 / 0.9) !important;
  border-bottom-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-y-rose-700\/95 {
  border-top-color: rgb(190 18 60 / 0.95) !important;
  border-bottom-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-y-rose-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-800\/0 {
  border-top-color: rgb(159 18 57 / 0) !important;
  border-bottom-color: rgb(159 18 57 / 0) !important;
}

.tw-border-y-rose-800\/10 {
  border-top-color: rgb(159 18 57 / 0.1) !important;
  border-bottom-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-y-rose-800\/100 {
  border-top-color: rgb(159 18 57 / 1) !important;
  border-bottom-color: rgb(159 18 57 / 1) !important;
}

.tw-border-y-rose-800\/15 {
  border-top-color: rgb(159 18 57 / 0.15) !important;
  border-bottom-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-y-rose-800\/20 {
  border-top-color: rgb(159 18 57 / 0.2) !important;
  border-bottom-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-y-rose-800\/25 {
  border-top-color: rgb(159 18 57 / 0.25) !important;
  border-bottom-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-y-rose-800\/30 {
  border-top-color: rgb(159 18 57 / 0.3) !important;
  border-bottom-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-y-rose-800\/35 {
  border-top-color: rgb(159 18 57 / 0.35) !important;
  border-bottom-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-y-rose-800\/40 {
  border-top-color: rgb(159 18 57 / 0.4) !important;
  border-bottom-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-y-rose-800\/45 {
  border-top-color: rgb(159 18 57 / 0.45) !important;
  border-bottom-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-y-rose-800\/5 {
  border-top-color: rgb(159 18 57 / 0.05) !important;
  border-bottom-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-y-rose-800\/50 {
  border-top-color: rgb(159 18 57 / 0.5) !important;
  border-bottom-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-y-rose-800\/55 {
  border-top-color: rgb(159 18 57 / 0.55) !important;
  border-bottom-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-y-rose-800\/60 {
  border-top-color: rgb(159 18 57 / 0.6) !important;
  border-bottom-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-y-rose-800\/65 {
  border-top-color: rgb(159 18 57 / 0.65) !important;
  border-bottom-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-y-rose-800\/70 {
  border-top-color: rgb(159 18 57 / 0.7) !important;
  border-bottom-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-y-rose-800\/75 {
  border-top-color: rgb(159 18 57 / 0.75) !important;
  border-bottom-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-y-rose-800\/80 {
  border-top-color: rgb(159 18 57 / 0.8) !important;
  border-bottom-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-y-rose-800\/85 {
  border-top-color: rgb(159 18 57 / 0.85) !important;
  border-bottom-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-y-rose-800\/90 {
  border-top-color: rgb(159 18 57 / 0.9) !important;
  border-bottom-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-y-rose-800\/95 {
  border-top-color: rgb(159 18 57 / 0.95) !important;
  border-bottom-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-y-rose-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-900\/0 {
  border-top-color: rgb(136 19 55 / 0) !important;
  border-bottom-color: rgb(136 19 55 / 0) !important;
}

.tw-border-y-rose-900\/10 {
  border-top-color: rgb(136 19 55 / 0.1) !important;
  border-bottom-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-y-rose-900\/100 {
  border-top-color: rgb(136 19 55 / 1) !important;
  border-bottom-color: rgb(136 19 55 / 1) !important;
}

.tw-border-y-rose-900\/15 {
  border-top-color: rgb(136 19 55 / 0.15) !important;
  border-bottom-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-y-rose-900\/20 {
  border-top-color: rgb(136 19 55 / 0.2) !important;
  border-bottom-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-y-rose-900\/25 {
  border-top-color: rgb(136 19 55 / 0.25) !important;
  border-bottom-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-y-rose-900\/30 {
  border-top-color: rgb(136 19 55 / 0.3) !important;
  border-bottom-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-y-rose-900\/35 {
  border-top-color: rgb(136 19 55 / 0.35) !important;
  border-bottom-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-y-rose-900\/40 {
  border-top-color: rgb(136 19 55 / 0.4) !important;
  border-bottom-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-y-rose-900\/45 {
  border-top-color: rgb(136 19 55 / 0.45) !important;
  border-bottom-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-y-rose-900\/5 {
  border-top-color: rgb(136 19 55 / 0.05) !important;
  border-bottom-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-y-rose-900\/50 {
  border-top-color: rgb(136 19 55 / 0.5) !important;
  border-bottom-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-y-rose-900\/55 {
  border-top-color: rgb(136 19 55 / 0.55) !important;
  border-bottom-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-y-rose-900\/60 {
  border-top-color: rgb(136 19 55 / 0.6) !important;
  border-bottom-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-y-rose-900\/65 {
  border-top-color: rgb(136 19 55 / 0.65) !important;
  border-bottom-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-y-rose-900\/70 {
  border-top-color: rgb(136 19 55 / 0.7) !important;
  border-bottom-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-y-rose-900\/75 {
  border-top-color: rgb(136 19 55 / 0.75) !important;
  border-bottom-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-y-rose-900\/80 {
  border-top-color: rgb(136 19 55 / 0.8) !important;
  border-bottom-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-y-rose-900\/85 {
  border-top-color: rgb(136 19 55 / 0.85) !important;
  border-bottom-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-y-rose-900\/90 {
  border-top-color: rgb(136 19 55 / 0.9) !important;
  border-bottom-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-y-rose-900\/95 {
  border-top-color: rgb(136 19 55 / 0.95) !important;
  border-bottom-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-y-rose-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-y-rose-950\/0 {
  border-top-color: rgb(76 5 25 / 0) !important;
  border-bottom-color: rgb(76 5 25 / 0) !important;
}

.tw-border-y-rose-950\/10 {
  border-top-color: rgb(76 5 25 / 0.1) !important;
  border-bottom-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-y-rose-950\/100 {
  border-top-color: rgb(76 5 25 / 1) !important;
  border-bottom-color: rgb(76 5 25 / 1) !important;
}

.tw-border-y-rose-950\/15 {
  border-top-color: rgb(76 5 25 / 0.15) !important;
  border-bottom-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-y-rose-950\/20 {
  border-top-color: rgb(76 5 25 / 0.2) !important;
  border-bottom-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-y-rose-950\/25 {
  border-top-color: rgb(76 5 25 / 0.25) !important;
  border-bottom-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-y-rose-950\/30 {
  border-top-color: rgb(76 5 25 / 0.3) !important;
  border-bottom-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-y-rose-950\/35 {
  border-top-color: rgb(76 5 25 / 0.35) !important;
  border-bottom-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-y-rose-950\/40 {
  border-top-color: rgb(76 5 25 / 0.4) !important;
  border-bottom-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-y-rose-950\/45 {
  border-top-color: rgb(76 5 25 / 0.45) !important;
  border-bottom-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-y-rose-950\/5 {
  border-top-color: rgb(76 5 25 / 0.05) !important;
  border-bottom-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-y-rose-950\/50 {
  border-top-color: rgb(76 5 25 / 0.5) !important;
  border-bottom-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-y-rose-950\/55 {
  border-top-color: rgb(76 5 25 / 0.55) !important;
  border-bottom-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-y-rose-950\/60 {
  border-top-color: rgb(76 5 25 / 0.6) !important;
  border-bottom-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-y-rose-950\/65 {
  border-top-color: rgb(76 5 25 / 0.65) !important;
  border-bottom-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-y-rose-950\/70 {
  border-top-color: rgb(76 5 25 / 0.7) !important;
  border-bottom-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-y-rose-950\/75 {
  border-top-color: rgb(76 5 25 / 0.75) !important;
  border-bottom-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-y-rose-950\/80 {
  border-top-color: rgb(76 5 25 / 0.8) !important;
  border-bottom-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-y-rose-950\/85 {
  border-top-color: rgb(76 5 25 / 0.85) !important;
  border-bottom-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-y-rose-950\/90 {
  border-top-color: rgb(76 5 25 / 0.9) !important;
  border-bottom-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-y-rose-950\/95 {
  border-top-color: rgb(76 5 25 / 0.95) !important;
  border-bottom-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-y-sky-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-100\/0 {
  border-top-color: rgb(224 242 254 / 0) !important;
  border-bottom-color: rgb(224 242 254 / 0) !important;
}

.tw-border-y-sky-100\/10 {
  border-top-color: rgb(224 242 254 / 0.1) !important;
  border-bottom-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-y-sky-100\/100 {
  border-top-color: rgb(224 242 254 / 1) !important;
  border-bottom-color: rgb(224 242 254 / 1) !important;
}

.tw-border-y-sky-100\/15 {
  border-top-color: rgb(224 242 254 / 0.15) !important;
  border-bottom-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-y-sky-100\/20 {
  border-top-color: rgb(224 242 254 / 0.2) !important;
  border-bottom-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-y-sky-100\/25 {
  border-top-color: rgb(224 242 254 / 0.25) !important;
  border-bottom-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-y-sky-100\/30 {
  border-top-color: rgb(224 242 254 / 0.3) !important;
  border-bottom-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-y-sky-100\/35 {
  border-top-color: rgb(224 242 254 / 0.35) !important;
  border-bottom-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-y-sky-100\/40 {
  border-top-color: rgb(224 242 254 / 0.4) !important;
  border-bottom-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-y-sky-100\/45 {
  border-top-color: rgb(224 242 254 / 0.45) !important;
  border-bottom-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-y-sky-100\/5 {
  border-top-color: rgb(224 242 254 / 0.05) !important;
  border-bottom-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-y-sky-100\/50 {
  border-top-color: rgb(224 242 254 / 0.5) !important;
  border-bottom-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-y-sky-100\/55 {
  border-top-color: rgb(224 242 254 / 0.55) !important;
  border-bottom-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-y-sky-100\/60 {
  border-top-color: rgb(224 242 254 / 0.6) !important;
  border-bottom-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-y-sky-100\/65 {
  border-top-color: rgb(224 242 254 / 0.65) !important;
  border-bottom-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-y-sky-100\/70 {
  border-top-color: rgb(224 242 254 / 0.7) !important;
  border-bottom-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-y-sky-100\/75 {
  border-top-color: rgb(224 242 254 / 0.75) !important;
  border-bottom-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-y-sky-100\/80 {
  border-top-color: rgb(224 242 254 / 0.8) !important;
  border-bottom-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-y-sky-100\/85 {
  border-top-color: rgb(224 242 254 / 0.85) !important;
  border-bottom-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-y-sky-100\/90 {
  border-top-color: rgb(224 242 254 / 0.9) !important;
  border-bottom-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-y-sky-100\/95 {
  border-top-color: rgb(224 242 254 / 0.95) !important;
  border-bottom-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-y-sky-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-200\/0 {
  border-top-color: rgb(186 230 253 / 0) !important;
  border-bottom-color: rgb(186 230 253 / 0) !important;
}

.tw-border-y-sky-200\/10 {
  border-top-color: rgb(186 230 253 / 0.1) !important;
  border-bottom-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-y-sky-200\/100 {
  border-top-color: rgb(186 230 253 / 1) !important;
  border-bottom-color: rgb(186 230 253 / 1) !important;
}

.tw-border-y-sky-200\/15 {
  border-top-color: rgb(186 230 253 / 0.15) !important;
  border-bottom-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-y-sky-200\/20 {
  border-top-color: rgb(186 230 253 / 0.2) !important;
  border-bottom-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-y-sky-200\/25 {
  border-top-color: rgb(186 230 253 / 0.25) !important;
  border-bottom-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-y-sky-200\/30 {
  border-top-color: rgb(186 230 253 / 0.3) !important;
  border-bottom-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-y-sky-200\/35 {
  border-top-color: rgb(186 230 253 / 0.35) !important;
  border-bottom-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-y-sky-200\/40 {
  border-top-color: rgb(186 230 253 / 0.4) !important;
  border-bottom-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-y-sky-200\/45 {
  border-top-color: rgb(186 230 253 / 0.45) !important;
  border-bottom-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-y-sky-200\/5 {
  border-top-color: rgb(186 230 253 / 0.05) !important;
  border-bottom-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-y-sky-200\/50 {
  border-top-color: rgb(186 230 253 / 0.5) !important;
  border-bottom-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-y-sky-200\/55 {
  border-top-color: rgb(186 230 253 / 0.55) !important;
  border-bottom-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-y-sky-200\/60 {
  border-top-color: rgb(186 230 253 / 0.6) !important;
  border-bottom-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-y-sky-200\/65 {
  border-top-color: rgb(186 230 253 / 0.65) !important;
  border-bottom-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-y-sky-200\/70 {
  border-top-color: rgb(186 230 253 / 0.7) !important;
  border-bottom-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-y-sky-200\/75 {
  border-top-color: rgb(186 230 253 / 0.75) !important;
  border-bottom-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-y-sky-200\/80 {
  border-top-color: rgb(186 230 253 / 0.8) !important;
  border-bottom-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-y-sky-200\/85 {
  border-top-color: rgb(186 230 253 / 0.85) !important;
  border-bottom-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-y-sky-200\/90 {
  border-top-color: rgb(186 230 253 / 0.9) !important;
  border-bottom-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-y-sky-200\/95 {
  border-top-color: rgb(186 230 253 / 0.95) !important;
  border-bottom-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-y-sky-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-300\/0 {
  border-top-color: rgb(125 211 252 / 0) !important;
  border-bottom-color: rgb(125 211 252 / 0) !important;
}

.tw-border-y-sky-300\/10 {
  border-top-color: rgb(125 211 252 / 0.1) !important;
  border-bottom-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-y-sky-300\/100 {
  border-top-color: rgb(125 211 252 / 1) !important;
  border-bottom-color: rgb(125 211 252 / 1) !important;
}

.tw-border-y-sky-300\/15 {
  border-top-color: rgb(125 211 252 / 0.15) !important;
  border-bottom-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-y-sky-300\/20 {
  border-top-color: rgb(125 211 252 / 0.2) !important;
  border-bottom-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-y-sky-300\/25 {
  border-top-color: rgb(125 211 252 / 0.25) !important;
  border-bottom-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-y-sky-300\/30 {
  border-top-color: rgb(125 211 252 / 0.3) !important;
  border-bottom-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-y-sky-300\/35 {
  border-top-color: rgb(125 211 252 / 0.35) !important;
  border-bottom-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-y-sky-300\/40 {
  border-top-color: rgb(125 211 252 / 0.4) !important;
  border-bottom-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-y-sky-300\/45 {
  border-top-color: rgb(125 211 252 / 0.45) !important;
  border-bottom-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-y-sky-300\/5 {
  border-top-color: rgb(125 211 252 / 0.05) !important;
  border-bottom-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-y-sky-300\/50 {
  border-top-color: rgb(125 211 252 / 0.5) !important;
  border-bottom-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-y-sky-300\/55 {
  border-top-color: rgb(125 211 252 / 0.55) !important;
  border-bottom-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-y-sky-300\/60 {
  border-top-color: rgb(125 211 252 / 0.6) !important;
  border-bottom-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-y-sky-300\/65 {
  border-top-color: rgb(125 211 252 / 0.65) !important;
  border-bottom-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-y-sky-300\/70 {
  border-top-color: rgb(125 211 252 / 0.7) !important;
  border-bottom-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-y-sky-300\/75 {
  border-top-color: rgb(125 211 252 / 0.75) !important;
  border-bottom-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-y-sky-300\/80 {
  border-top-color: rgb(125 211 252 / 0.8) !important;
  border-bottom-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-y-sky-300\/85 {
  border-top-color: rgb(125 211 252 / 0.85) !important;
  border-bottom-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-y-sky-300\/90 {
  border-top-color: rgb(125 211 252 / 0.9) !important;
  border-bottom-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-y-sky-300\/95 {
  border-top-color: rgb(125 211 252 / 0.95) !important;
  border-bottom-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-y-sky-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-400\/0 {
  border-top-color: rgb(56 189 248 / 0) !important;
  border-bottom-color: rgb(56 189 248 / 0) !important;
}

.tw-border-y-sky-400\/10 {
  border-top-color: rgb(56 189 248 / 0.1) !important;
  border-bottom-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-y-sky-400\/100 {
  border-top-color: rgb(56 189 248 / 1) !important;
  border-bottom-color: rgb(56 189 248 / 1) !important;
}

.tw-border-y-sky-400\/15 {
  border-top-color: rgb(56 189 248 / 0.15) !important;
  border-bottom-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-y-sky-400\/20 {
  border-top-color: rgb(56 189 248 / 0.2) !important;
  border-bottom-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-y-sky-400\/25 {
  border-top-color: rgb(56 189 248 / 0.25) !important;
  border-bottom-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-y-sky-400\/30 {
  border-top-color: rgb(56 189 248 / 0.3) !important;
  border-bottom-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-y-sky-400\/35 {
  border-top-color: rgb(56 189 248 / 0.35) !important;
  border-bottom-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-y-sky-400\/40 {
  border-top-color: rgb(56 189 248 / 0.4) !important;
  border-bottom-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-y-sky-400\/45 {
  border-top-color: rgb(56 189 248 / 0.45) !important;
  border-bottom-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-y-sky-400\/5 {
  border-top-color: rgb(56 189 248 / 0.05) !important;
  border-bottom-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-y-sky-400\/50 {
  border-top-color: rgb(56 189 248 / 0.5) !important;
  border-bottom-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-y-sky-400\/55 {
  border-top-color: rgb(56 189 248 / 0.55) !important;
  border-bottom-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-y-sky-400\/60 {
  border-top-color: rgb(56 189 248 / 0.6) !important;
  border-bottom-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-y-sky-400\/65 {
  border-top-color: rgb(56 189 248 / 0.65) !important;
  border-bottom-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-y-sky-400\/70 {
  border-top-color: rgb(56 189 248 / 0.7) !important;
  border-bottom-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-y-sky-400\/75 {
  border-top-color: rgb(56 189 248 / 0.75) !important;
  border-bottom-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-y-sky-400\/80 {
  border-top-color: rgb(56 189 248 / 0.8) !important;
  border-bottom-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-y-sky-400\/85 {
  border-top-color: rgb(56 189 248 / 0.85) !important;
  border-bottom-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-y-sky-400\/90 {
  border-top-color: rgb(56 189 248 / 0.9) !important;
  border-bottom-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-y-sky-400\/95 {
  border-top-color: rgb(56 189 248 / 0.95) !important;
  border-bottom-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-y-sky-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-50\/0 {
  border-top-color: rgb(240 249 255 / 0) !important;
  border-bottom-color: rgb(240 249 255 / 0) !important;
}

.tw-border-y-sky-50\/10 {
  border-top-color: rgb(240 249 255 / 0.1) !important;
  border-bottom-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-y-sky-50\/100 {
  border-top-color: rgb(240 249 255 / 1) !important;
  border-bottom-color: rgb(240 249 255 / 1) !important;
}

.tw-border-y-sky-50\/15 {
  border-top-color: rgb(240 249 255 / 0.15) !important;
  border-bottom-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-y-sky-50\/20 {
  border-top-color: rgb(240 249 255 / 0.2) !important;
  border-bottom-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-y-sky-50\/25 {
  border-top-color: rgb(240 249 255 / 0.25) !important;
  border-bottom-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-y-sky-50\/30 {
  border-top-color: rgb(240 249 255 / 0.3) !important;
  border-bottom-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-y-sky-50\/35 {
  border-top-color: rgb(240 249 255 / 0.35) !important;
  border-bottom-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-y-sky-50\/40 {
  border-top-color: rgb(240 249 255 / 0.4) !important;
  border-bottom-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-y-sky-50\/45 {
  border-top-color: rgb(240 249 255 / 0.45) !important;
  border-bottom-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-y-sky-50\/5 {
  border-top-color: rgb(240 249 255 / 0.05) !important;
  border-bottom-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-y-sky-50\/50 {
  border-top-color: rgb(240 249 255 / 0.5) !important;
  border-bottom-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-y-sky-50\/55 {
  border-top-color: rgb(240 249 255 / 0.55) !important;
  border-bottom-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-y-sky-50\/60 {
  border-top-color: rgb(240 249 255 / 0.6) !important;
  border-bottom-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-y-sky-50\/65 {
  border-top-color: rgb(240 249 255 / 0.65) !important;
  border-bottom-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-y-sky-50\/70 {
  border-top-color: rgb(240 249 255 / 0.7) !important;
  border-bottom-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-y-sky-50\/75 {
  border-top-color: rgb(240 249 255 / 0.75) !important;
  border-bottom-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-y-sky-50\/80 {
  border-top-color: rgb(240 249 255 / 0.8) !important;
  border-bottom-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-y-sky-50\/85 {
  border-top-color: rgb(240 249 255 / 0.85) !important;
  border-bottom-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-y-sky-50\/90 {
  border-top-color: rgb(240 249 255 / 0.9) !important;
  border-bottom-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-y-sky-50\/95 {
  border-top-color: rgb(240 249 255 / 0.95) !important;
  border-bottom-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-y-sky-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-500\/0 {
  border-top-color: rgb(14 165 233 / 0) !important;
  border-bottom-color: rgb(14 165 233 / 0) !important;
}

.tw-border-y-sky-500\/10 {
  border-top-color: rgb(14 165 233 / 0.1) !important;
  border-bottom-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-y-sky-500\/100 {
  border-top-color: rgb(14 165 233 / 1) !important;
  border-bottom-color: rgb(14 165 233 / 1) !important;
}

.tw-border-y-sky-500\/15 {
  border-top-color: rgb(14 165 233 / 0.15) !important;
  border-bottom-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-y-sky-500\/20 {
  border-top-color: rgb(14 165 233 / 0.2) !important;
  border-bottom-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-y-sky-500\/25 {
  border-top-color: rgb(14 165 233 / 0.25) !important;
  border-bottom-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-y-sky-500\/30 {
  border-top-color: rgb(14 165 233 / 0.3) !important;
  border-bottom-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-y-sky-500\/35 {
  border-top-color: rgb(14 165 233 / 0.35) !important;
  border-bottom-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-y-sky-500\/40 {
  border-top-color: rgb(14 165 233 / 0.4) !important;
  border-bottom-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-y-sky-500\/45 {
  border-top-color: rgb(14 165 233 / 0.45) !important;
  border-bottom-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-y-sky-500\/5 {
  border-top-color: rgb(14 165 233 / 0.05) !important;
  border-bottom-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-y-sky-500\/50 {
  border-top-color: rgb(14 165 233 / 0.5) !important;
  border-bottom-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-y-sky-500\/55 {
  border-top-color: rgb(14 165 233 / 0.55) !important;
  border-bottom-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-y-sky-500\/60 {
  border-top-color: rgb(14 165 233 / 0.6) !important;
  border-bottom-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-y-sky-500\/65 {
  border-top-color: rgb(14 165 233 / 0.65) !important;
  border-bottom-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-y-sky-500\/70 {
  border-top-color: rgb(14 165 233 / 0.7) !important;
  border-bottom-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-y-sky-500\/75 {
  border-top-color: rgb(14 165 233 / 0.75) !important;
  border-bottom-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-y-sky-500\/80 {
  border-top-color: rgb(14 165 233 / 0.8) !important;
  border-bottom-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-y-sky-500\/85 {
  border-top-color: rgb(14 165 233 / 0.85) !important;
  border-bottom-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-y-sky-500\/90 {
  border-top-color: rgb(14 165 233 / 0.9) !important;
  border-bottom-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-y-sky-500\/95 {
  border-top-color: rgb(14 165 233 / 0.95) !important;
  border-bottom-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-y-sky-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-600\/0 {
  border-top-color: rgb(2 132 199 / 0) !important;
  border-bottom-color: rgb(2 132 199 / 0) !important;
}

.tw-border-y-sky-600\/10 {
  border-top-color: rgb(2 132 199 / 0.1) !important;
  border-bottom-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-y-sky-600\/100 {
  border-top-color: rgb(2 132 199 / 1) !important;
  border-bottom-color: rgb(2 132 199 / 1) !important;
}

.tw-border-y-sky-600\/15 {
  border-top-color: rgb(2 132 199 / 0.15) !important;
  border-bottom-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-y-sky-600\/20 {
  border-top-color: rgb(2 132 199 / 0.2) !important;
  border-bottom-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-y-sky-600\/25 {
  border-top-color: rgb(2 132 199 / 0.25) !important;
  border-bottom-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-y-sky-600\/30 {
  border-top-color: rgb(2 132 199 / 0.3) !important;
  border-bottom-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-y-sky-600\/35 {
  border-top-color: rgb(2 132 199 / 0.35) !important;
  border-bottom-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-y-sky-600\/40 {
  border-top-color: rgb(2 132 199 / 0.4) !important;
  border-bottom-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-y-sky-600\/45 {
  border-top-color: rgb(2 132 199 / 0.45) !important;
  border-bottom-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-y-sky-600\/5 {
  border-top-color: rgb(2 132 199 / 0.05) !important;
  border-bottom-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-y-sky-600\/50 {
  border-top-color: rgb(2 132 199 / 0.5) !important;
  border-bottom-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-y-sky-600\/55 {
  border-top-color: rgb(2 132 199 / 0.55) !important;
  border-bottom-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-y-sky-600\/60 {
  border-top-color: rgb(2 132 199 / 0.6) !important;
  border-bottom-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-y-sky-600\/65 {
  border-top-color: rgb(2 132 199 / 0.65) !important;
  border-bottom-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-y-sky-600\/70 {
  border-top-color: rgb(2 132 199 / 0.7) !important;
  border-bottom-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-y-sky-600\/75 {
  border-top-color: rgb(2 132 199 / 0.75) !important;
  border-bottom-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-y-sky-600\/80 {
  border-top-color: rgb(2 132 199 / 0.8) !important;
  border-bottom-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-y-sky-600\/85 {
  border-top-color: rgb(2 132 199 / 0.85) !important;
  border-bottom-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-y-sky-600\/90 {
  border-top-color: rgb(2 132 199 / 0.9) !important;
  border-bottom-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-y-sky-600\/95 {
  border-top-color: rgb(2 132 199 / 0.95) !important;
  border-bottom-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-y-sky-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-700\/0 {
  border-top-color: rgb(3 105 161 / 0) !important;
  border-bottom-color: rgb(3 105 161 / 0) !important;
}

.tw-border-y-sky-700\/10 {
  border-top-color: rgb(3 105 161 / 0.1) !important;
  border-bottom-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-y-sky-700\/100 {
  border-top-color: rgb(3 105 161 / 1) !important;
  border-bottom-color: rgb(3 105 161 / 1) !important;
}

.tw-border-y-sky-700\/15 {
  border-top-color: rgb(3 105 161 / 0.15) !important;
  border-bottom-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-y-sky-700\/20 {
  border-top-color: rgb(3 105 161 / 0.2) !important;
  border-bottom-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-y-sky-700\/25 {
  border-top-color: rgb(3 105 161 / 0.25) !important;
  border-bottom-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-y-sky-700\/30 {
  border-top-color: rgb(3 105 161 / 0.3) !important;
  border-bottom-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-y-sky-700\/35 {
  border-top-color: rgb(3 105 161 / 0.35) !important;
  border-bottom-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-y-sky-700\/40 {
  border-top-color: rgb(3 105 161 / 0.4) !important;
  border-bottom-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-y-sky-700\/45 {
  border-top-color: rgb(3 105 161 / 0.45) !important;
  border-bottom-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-y-sky-700\/5 {
  border-top-color: rgb(3 105 161 / 0.05) !important;
  border-bottom-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-y-sky-700\/50 {
  border-top-color: rgb(3 105 161 / 0.5) !important;
  border-bottom-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-y-sky-700\/55 {
  border-top-color: rgb(3 105 161 / 0.55) !important;
  border-bottom-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-y-sky-700\/60 {
  border-top-color: rgb(3 105 161 / 0.6) !important;
  border-bottom-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-y-sky-700\/65 {
  border-top-color: rgb(3 105 161 / 0.65) !important;
  border-bottom-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-y-sky-700\/70 {
  border-top-color: rgb(3 105 161 / 0.7) !important;
  border-bottom-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-y-sky-700\/75 {
  border-top-color: rgb(3 105 161 / 0.75) !important;
  border-bottom-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-y-sky-700\/80 {
  border-top-color: rgb(3 105 161 / 0.8) !important;
  border-bottom-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-y-sky-700\/85 {
  border-top-color: rgb(3 105 161 / 0.85) !important;
  border-bottom-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-y-sky-700\/90 {
  border-top-color: rgb(3 105 161 / 0.9) !important;
  border-bottom-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-y-sky-700\/95 {
  border-top-color: rgb(3 105 161 / 0.95) !important;
  border-bottom-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-y-sky-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-800\/0 {
  border-top-color: rgb(7 89 133 / 0) !important;
  border-bottom-color: rgb(7 89 133 / 0) !important;
}

.tw-border-y-sky-800\/10 {
  border-top-color: rgb(7 89 133 / 0.1) !important;
  border-bottom-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-y-sky-800\/100 {
  border-top-color: rgb(7 89 133 / 1) !important;
  border-bottom-color: rgb(7 89 133 / 1) !important;
}

.tw-border-y-sky-800\/15 {
  border-top-color: rgb(7 89 133 / 0.15) !important;
  border-bottom-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-y-sky-800\/20 {
  border-top-color: rgb(7 89 133 / 0.2) !important;
  border-bottom-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-y-sky-800\/25 {
  border-top-color: rgb(7 89 133 / 0.25) !important;
  border-bottom-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-y-sky-800\/30 {
  border-top-color: rgb(7 89 133 / 0.3) !important;
  border-bottom-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-y-sky-800\/35 {
  border-top-color: rgb(7 89 133 / 0.35) !important;
  border-bottom-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-y-sky-800\/40 {
  border-top-color: rgb(7 89 133 / 0.4) !important;
  border-bottom-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-y-sky-800\/45 {
  border-top-color: rgb(7 89 133 / 0.45) !important;
  border-bottom-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-y-sky-800\/5 {
  border-top-color: rgb(7 89 133 / 0.05) !important;
  border-bottom-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-y-sky-800\/50 {
  border-top-color: rgb(7 89 133 / 0.5) !important;
  border-bottom-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-y-sky-800\/55 {
  border-top-color: rgb(7 89 133 / 0.55) !important;
  border-bottom-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-y-sky-800\/60 {
  border-top-color: rgb(7 89 133 / 0.6) !important;
  border-bottom-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-y-sky-800\/65 {
  border-top-color: rgb(7 89 133 / 0.65) !important;
  border-bottom-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-y-sky-800\/70 {
  border-top-color: rgb(7 89 133 / 0.7) !important;
  border-bottom-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-y-sky-800\/75 {
  border-top-color: rgb(7 89 133 / 0.75) !important;
  border-bottom-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-y-sky-800\/80 {
  border-top-color: rgb(7 89 133 / 0.8) !important;
  border-bottom-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-y-sky-800\/85 {
  border-top-color: rgb(7 89 133 / 0.85) !important;
  border-bottom-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-y-sky-800\/90 {
  border-top-color: rgb(7 89 133 / 0.9) !important;
  border-bottom-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-y-sky-800\/95 {
  border-top-color: rgb(7 89 133 / 0.95) !important;
  border-bottom-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-y-sky-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-900\/0 {
  border-top-color: rgb(12 74 110 / 0) !important;
  border-bottom-color: rgb(12 74 110 / 0) !important;
}

.tw-border-y-sky-900\/10 {
  border-top-color: rgb(12 74 110 / 0.1) !important;
  border-bottom-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-y-sky-900\/100 {
  border-top-color: rgb(12 74 110 / 1) !important;
  border-bottom-color: rgb(12 74 110 / 1) !important;
}

.tw-border-y-sky-900\/15 {
  border-top-color: rgb(12 74 110 / 0.15) !important;
  border-bottom-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-y-sky-900\/20 {
  border-top-color: rgb(12 74 110 / 0.2) !important;
  border-bottom-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-y-sky-900\/25 {
  border-top-color: rgb(12 74 110 / 0.25) !important;
  border-bottom-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-y-sky-900\/30 {
  border-top-color: rgb(12 74 110 / 0.3) !important;
  border-bottom-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-y-sky-900\/35 {
  border-top-color: rgb(12 74 110 / 0.35) !important;
  border-bottom-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-y-sky-900\/40 {
  border-top-color: rgb(12 74 110 / 0.4) !important;
  border-bottom-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-y-sky-900\/45 {
  border-top-color: rgb(12 74 110 / 0.45) !important;
  border-bottom-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-y-sky-900\/5 {
  border-top-color: rgb(12 74 110 / 0.05) !important;
  border-bottom-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-y-sky-900\/50 {
  border-top-color: rgb(12 74 110 / 0.5) !important;
  border-bottom-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-y-sky-900\/55 {
  border-top-color: rgb(12 74 110 / 0.55) !important;
  border-bottom-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-y-sky-900\/60 {
  border-top-color: rgb(12 74 110 / 0.6) !important;
  border-bottom-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-y-sky-900\/65 {
  border-top-color: rgb(12 74 110 / 0.65) !important;
  border-bottom-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-y-sky-900\/70 {
  border-top-color: rgb(12 74 110 / 0.7) !important;
  border-bottom-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-y-sky-900\/75 {
  border-top-color: rgb(12 74 110 / 0.75) !important;
  border-bottom-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-y-sky-900\/80 {
  border-top-color: rgb(12 74 110 / 0.8) !important;
  border-bottom-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-y-sky-900\/85 {
  border-top-color: rgb(12 74 110 / 0.85) !important;
  border-bottom-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-y-sky-900\/90 {
  border-top-color: rgb(12 74 110 / 0.9) !important;
  border-bottom-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-y-sky-900\/95 {
  border-top-color: rgb(12 74 110 / 0.95) !important;
  border-bottom-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-y-sky-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-y-sky-950\/0 {
  border-top-color: rgb(8 47 73 / 0) !important;
  border-bottom-color: rgb(8 47 73 / 0) !important;
}

.tw-border-y-sky-950\/10 {
  border-top-color: rgb(8 47 73 / 0.1) !important;
  border-bottom-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-y-sky-950\/100 {
  border-top-color: rgb(8 47 73 / 1) !important;
  border-bottom-color: rgb(8 47 73 / 1) !important;
}

.tw-border-y-sky-950\/15 {
  border-top-color: rgb(8 47 73 / 0.15) !important;
  border-bottom-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-y-sky-950\/20 {
  border-top-color: rgb(8 47 73 / 0.2) !important;
  border-bottom-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-y-sky-950\/25 {
  border-top-color: rgb(8 47 73 / 0.25) !important;
  border-bottom-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-y-sky-950\/30 {
  border-top-color: rgb(8 47 73 / 0.3) !important;
  border-bottom-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-y-sky-950\/35 {
  border-top-color: rgb(8 47 73 / 0.35) !important;
  border-bottom-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-y-sky-950\/40 {
  border-top-color: rgb(8 47 73 / 0.4) !important;
  border-bottom-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-y-sky-950\/45 {
  border-top-color: rgb(8 47 73 / 0.45) !important;
  border-bottom-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-y-sky-950\/5 {
  border-top-color: rgb(8 47 73 / 0.05) !important;
  border-bottom-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-y-sky-950\/50 {
  border-top-color: rgb(8 47 73 / 0.5) !important;
  border-bottom-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-y-sky-950\/55 {
  border-top-color: rgb(8 47 73 / 0.55) !important;
  border-bottom-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-y-sky-950\/60 {
  border-top-color: rgb(8 47 73 / 0.6) !important;
  border-bottom-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-y-sky-950\/65 {
  border-top-color: rgb(8 47 73 / 0.65) !important;
  border-bottom-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-y-sky-950\/70 {
  border-top-color: rgb(8 47 73 / 0.7) !important;
  border-bottom-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-y-sky-950\/75 {
  border-top-color: rgb(8 47 73 / 0.75) !important;
  border-bottom-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-y-sky-950\/80 {
  border-top-color: rgb(8 47 73 / 0.8) !important;
  border-bottom-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-y-sky-950\/85 {
  border-top-color: rgb(8 47 73 / 0.85) !important;
  border-bottom-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-y-sky-950\/90 {
  border-top-color: rgb(8 47 73 / 0.9) !important;
  border-bottom-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-y-sky-950\/95 {
  border-top-color: rgb(8 47 73 / 0.95) !important;
  border-bottom-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-y-slate-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-100\/0 {
  border-top-color: rgb(241 245 249 / 0) !important;
  border-bottom-color: rgb(241 245 249 / 0) !important;
}

.tw-border-y-slate-100\/10 {
  border-top-color: rgb(241 245 249 / 0.1) !important;
  border-bottom-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-y-slate-100\/100 {
  border-top-color: rgb(241 245 249 / 1) !important;
  border-bottom-color: rgb(241 245 249 / 1) !important;
}

.tw-border-y-slate-100\/15 {
  border-top-color: rgb(241 245 249 / 0.15) !important;
  border-bottom-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-y-slate-100\/20 {
  border-top-color: rgb(241 245 249 / 0.2) !important;
  border-bottom-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-y-slate-100\/25 {
  border-top-color: rgb(241 245 249 / 0.25) !important;
  border-bottom-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-y-slate-100\/30 {
  border-top-color: rgb(241 245 249 / 0.3) !important;
  border-bottom-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-y-slate-100\/35 {
  border-top-color: rgb(241 245 249 / 0.35) !important;
  border-bottom-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-y-slate-100\/40 {
  border-top-color: rgb(241 245 249 / 0.4) !important;
  border-bottom-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-y-slate-100\/45 {
  border-top-color: rgb(241 245 249 / 0.45) !important;
  border-bottom-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-y-slate-100\/5 {
  border-top-color: rgb(241 245 249 / 0.05) !important;
  border-bottom-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-y-slate-100\/50 {
  border-top-color: rgb(241 245 249 / 0.5) !important;
  border-bottom-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-y-slate-100\/55 {
  border-top-color: rgb(241 245 249 / 0.55) !important;
  border-bottom-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-y-slate-100\/60 {
  border-top-color: rgb(241 245 249 / 0.6) !important;
  border-bottom-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-y-slate-100\/65 {
  border-top-color: rgb(241 245 249 / 0.65) !important;
  border-bottom-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-y-slate-100\/70 {
  border-top-color: rgb(241 245 249 / 0.7) !important;
  border-bottom-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-y-slate-100\/75 {
  border-top-color: rgb(241 245 249 / 0.75) !important;
  border-bottom-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-y-slate-100\/80 {
  border-top-color: rgb(241 245 249 / 0.8) !important;
  border-bottom-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-y-slate-100\/85 {
  border-top-color: rgb(241 245 249 / 0.85) !important;
  border-bottom-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-y-slate-100\/90 {
  border-top-color: rgb(241 245 249 / 0.9) !important;
  border-bottom-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-y-slate-100\/95 {
  border-top-color: rgb(241 245 249 / 0.95) !important;
  border-bottom-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-y-slate-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-200\/0 {
  border-top-color: rgb(226 232 240 / 0) !important;
  border-bottom-color: rgb(226 232 240 / 0) !important;
}

.tw-border-y-slate-200\/10 {
  border-top-color: rgb(226 232 240 / 0.1) !important;
  border-bottom-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-y-slate-200\/100 {
  border-top-color: rgb(226 232 240 / 1) !important;
  border-bottom-color: rgb(226 232 240 / 1) !important;
}

.tw-border-y-slate-200\/15 {
  border-top-color: rgb(226 232 240 / 0.15) !important;
  border-bottom-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-y-slate-200\/20 {
  border-top-color: rgb(226 232 240 / 0.2) !important;
  border-bottom-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-y-slate-200\/25 {
  border-top-color: rgb(226 232 240 / 0.25) !important;
  border-bottom-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-y-slate-200\/30 {
  border-top-color: rgb(226 232 240 / 0.3) !important;
  border-bottom-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-y-slate-200\/35 {
  border-top-color: rgb(226 232 240 / 0.35) !important;
  border-bottom-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-y-slate-200\/40 {
  border-top-color: rgb(226 232 240 / 0.4) !important;
  border-bottom-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-y-slate-200\/45 {
  border-top-color: rgb(226 232 240 / 0.45) !important;
  border-bottom-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-y-slate-200\/5 {
  border-top-color: rgb(226 232 240 / 0.05) !important;
  border-bottom-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-y-slate-200\/50 {
  border-top-color: rgb(226 232 240 / 0.5) !important;
  border-bottom-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-y-slate-200\/55 {
  border-top-color: rgb(226 232 240 / 0.55) !important;
  border-bottom-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-y-slate-200\/60 {
  border-top-color: rgb(226 232 240 / 0.6) !important;
  border-bottom-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-y-slate-200\/65 {
  border-top-color: rgb(226 232 240 / 0.65) !important;
  border-bottom-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-y-slate-200\/70 {
  border-top-color: rgb(226 232 240 / 0.7) !important;
  border-bottom-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-y-slate-200\/75 {
  border-top-color: rgb(226 232 240 / 0.75) !important;
  border-bottom-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-y-slate-200\/80 {
  border-top-color: rgb(226 232 240 / 0.8) !important;
  border-bottom-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-y-slate-200\/85 {
  border-top-color: rgb(226 232 240 / 0.85) !important;
  border-bottom-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-y-slate-200\/90 {
  border-top-color: rgb(226 232 240 / 0.9) !important;
  border-bottom-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-y-slate-200\/95 {
  border-top-color: rgb(226 232 240 / 0.95) !important;
  border-bottom-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-y-slate-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-300\/0 {
  border-top-color: rgb(203 213 225 / 0) !important;
  border-bottom-color: rgb(203 213 225 / 0) !important;
}

.tw-border-y-slate-300\/10 {
  border-top-color: rgb(203 213 225 / 0.1) !important;
  border-bottom-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-y-slate-300\/100 {
  border-top-color: rgb(203 213 225 / 1) !important;
  border-bottom-color: rgb(203 213 225 / 1) !important;
}

.tw-border-y-slate-300\/15 {
  border-top-color: rgb(203 213 225 / 0.15) !important;
  border-bottom-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-y-slate-300\/20 {
  border-top-color: rgb(203 213 225 / 0.2) !important;
  border-bottom-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-y-slate-300\/25 {
  border-top-color: rgb(203 213 225 / 0.25) !important;
  border-bottom-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-y-slate-300\/30 {
  border-top-color: rgb(203 213 225 / 0.3) !important;
  border-bottom-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-y-slate-300\/35 {
  border-top-color: rgb(203 213 225 / 0.35) !important;
  border-bottom-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-y-slate-300\/40 {
  border-top-color: rgb(203 213 225 / 0.4) !important;
  border-bottom-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-y-slate-300\/45 {
  border-top-color: rgb(203 213 225 / 0.45) !important;
  border-bottom-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-y-slate-300\/5 {
  border-top-color: rgb(203 213 225 / 0.05) !important;
  border-bottom-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-y-slate-300\/50 {
  border-top-color: rgb(203 213 225 / 0.5) !important;
  border-bottom-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-y-slate-300\/55 {
  border-top-color: rgb(203 213 225 / 0.55) !important;
  border-bottom-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-y-slate-300\/60 {
  border-top-color: rgb(203 213 225 / 0.6) !important;
  border-bottom-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-y-slate-300\/65 {
  border-top-color: rgb(203 213 225 / 0.65) !important;
  border-bottom-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-y-slate-300\/70 {
  border-top-color: rgb(203 213 225 / 0.7) !important;
  border-bottom-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-y-slate-300\/75 {
  border-top-color: rgb(203 213 225 / 0.75) !important;
  border-bottom-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-y-slate-300\/80 {
  border-top-color: rgb(203 213 225 / 0.8) !important;
  border-bottom-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-y-slate-300\/85 {
  border-top-color: rgb(203 213 225 / 0.85) !important;
  border-bottom-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-y-slate-300\/90 {
  border-top-color: rgb(203 213 225 / 0.9) !important;
  border-bottom-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-y-slate-300\/95 {
  border-top-color: rgb(203 213 225 / 0.95) !important;
  border-bottom-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-y-slate-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-400\/0 {
  border-top-color: rgb(148 163 184 / 0) !important;
  border-bottom-color: rgb(148 163 184 / 0) !important;
}

.tw-border-y-slate-400\/10 {
  border-top-color: rgb(148 163 184 / 0.1) !important;
  border-bottom-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-y-slate-400\/100 {
  border-top-color: rgb(148 163 184 / 1) !important;
  border-bottom-color: rgb(148 163 184 / 1) !important;
}

.tw-border-y-slate-400\/15 {
  border-top-color: rgb(148 163 184 / 0.15) !important;
  border-bottom-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-y-slate-400\/20 {
  border-top-color: rgb(148 163 184 / 0.2) !important;
  border-bottom-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-y-slate-400\/25 {
  border-top-color: rgb(148 163 184 / 0.25) !important;
  border-bottom-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-y-slate-400\/30 {
  border-top-color: rgb(148 163 184 / 0.3) !important;
  border-bottom-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-y-slate-400\/35 {
  border-top-color: rgb(148 163 184 / 0.35) !important;
  border-bottom-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-y-slate-400\/40 {
  border-top-color: rgb(148 163 184 / 0.4) !important;
  border-bottom-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-y-slate-400\/45 {
  border-top-color: rgb(148 163 184 / 0.45) !important;
  border-bottom-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-y-slate-400\/5 {
  border-top-color: rgb(148 163 184 / 0.05) !important;
  border-bottom-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-y-slate-400\/50 {
  border-top-color: rgb(148 163 184 / 0.5) !important;
  border-bottom-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-y-slate-400\/55 {
  border-top-color: rgb(148 163 184 / 0.55) !important;
  border-bottom-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-y-slate-400\/60 {
  border-top-color: rgb(148 163 184 / 0.6) !important;
  border-bottom-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-y-slate-400\/65 {
  border-top-color: rgb(148 163 184 / 0.65) !important;
  border-bottom-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-y-slate-400\/70 {
  border-top-color: rgb(148 163 184 / 0.7) !important;
  border-bottom-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-y-slate-400\/75 {
  border-top-color: rgb(148 163 184 / 0.75) !important;
  border-bottom-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-y-slate-400\/80 {
  border-top-color: rgb(148 163 184 / 0.8) !important;
  border-bottom-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-y-slate-400\/85 {
  border-top-color: rgb(148 163 184 / 0.85) !important;
  border-bottom-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-y-slate-400\/90 {
  border-top-color: rgb(148 163 184 / 0.9) !important;
  border-bottom-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-y-slate-400\/95 {
  border-top-color: rgb(148 163 184 / 0.95) !important;
  border-bottom-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-y-slate-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-50\/0 {
  border-top-color: rgb(248 250 252 / 0) !important;
  border-bottom-color: rgb(248 250 252 / 0) !important;
}

.tw-border-y-slate-50\/10 {
  border-top-color: rgb(248 250 252 / 0.1) !important;
  border-bottom-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-y-slate-50\/100 {
  border-top-color: rgb(248 250 252 / 1) !important;
  border-bottom-color: rgb(248 250 252 / 1) !important;
}

.tw-border-y-slate-50\/15 {
  border-top-color: rgb(248 250 252 / 0.15) !important;
  border-bottom-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-y-slate-50\/20 {
  border-top-color: rgb(248 250 252 / 0.2) !important;
  border-bottom-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-y-slate-50\/25 {
  border-top-color: rgb(248 250 252 / 0.25) !important;
  border-bottom-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-y-slate-50\/30 {
  border-top-color: rgb(248 250 252 / 0.3) !important;
  border-bottom-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-y-slate-50\/35 {
  border-top-color: rgb(248 250 252 / 0.35) !important;
  border-bottom-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-y-slate-50\/40 {
  border-top-color: rgb(248 250 252 / 0.4) !important;
  border-bottom-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-y-slate-50\/45 {
  border-top-color: rgb(248 250 252 / 0.45) !important;
  border-bottom-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-y-slate-50\/5 {
  border-top-color: rgb(248 250 252 / 0.05) !important;
  border-bottom-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-y-slate-50\/50 {
  border-top-color: rgb(248 250 252 / 0.5) !important;
  border-bottom-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-y-slate-50\/55 {
  border-top-color: rgb(248 250 252 / 0.55) !important;
  border-bottom-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-y-slate-50\/60 {
  border-top-color: rgb(248 250 252 / 0.6) !important;
  border-bottom-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-y-slate-50\/65 {
  border-top-color: rgb(248 250 252 / 0.65) !important;
  border-bottom-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-y-slate-50\/70 {
  border-top-color: rgb(248 250 252 / 0.7) !important;
  border-bottom-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-y-slate-50\/75 {
  border-top-color: rgb(248 250 252 / 0.75) !important;
  border-bottom-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-y-slate-50\/80 {
  border-top-color: rgb(248 250 252 / 0.8) !important;
  border-bottom-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-y-slate-50\/85 {
  border-top-color: rgb(248 250 252 / 0.85) !important;
  border-bottom-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-y-slate-50\/90 {
  border-top-color: rgb(248 250 252 / 0.9) !important;
  border-bottom-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-y-slate-50\/95 {
  border-top-color: rgb(248 250 252 / 0.95) !important;
  border-bottom-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-y-slate-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-500\/0 {
  border-top-color: rgb(100 116 139 / 0) !important;
  border-bottom-color: rgb(100 116 139 / 0) !important;
}

.tw-border-y-slate-500\/10 {
  border-top-color: rgb(100 116 139 / 0.1) !important;
  border-bottom-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-y-slate-500\/100 {
  border-top-color: rgb(100 116 139 / 1) !important;
  border-bottom-color: rgb(100 116 139 / 1) !important;
}

.tw-border-y-slate-500\/15 {
  border-top-color: rgb(100 116 139 / 0.15) !important;
  border-bottom-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-y-slate-500\/20 {
  border-top-color: rgb(100 116 139 / 0.2) !important;
  border-bottom-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-y-slate-500\/25 {
  border-top-color: rgb(100 116 139 / 0.25) !important;
  border-bottom-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-y-slate-500\/30 {
  border-top-color: rgb(100 116 139 / 0.3) !important;
  border-bottom-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-y-slate-500\/35 {
  border-top-color: rgb(100 116 139 / 0.35) !important;
  border-bottom-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-y-slate-500\/40 {
  border-top-color: rgb(100 116 139 / 0.4) !important;
  border-bottom-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-y-slate-500\/45 {
  border-top-color: rgb(100 116 139 / 0.45) !important;
  border-bottom-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-y-slate-500\/5 {
  border-top-color: rgb(100 116 139 / 0.05) !important;
  border-bottom-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-y-slate-500\/50 {
  border-top-color: rgb(100 116 139 / 0.5) !important;
  border-bottom-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-y-slate-500\/55 {
  border-top-color: rgb(100 116 139 / 0.55) !important;
  border-bottom-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-y-slate-500\/60 {
  border-top-color: rgb(100 116 139 / 0.6) !important;
  border-bottom-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-y-slate-500\/65 {
  border-top-color: rgb(100 116 139 / 0.65) !important;
  border-bottom-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-y-slate-500\/70 {
  border-top-color: rgb(100 116 139 / 0.7) !important;
  border-bottom-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-y-slate-500\/75 {
  border-top-color: rgb(100 116 139 / 0.75) !important;
  border-bottom-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-y-slate-500\/80 {
  border-top-color: rgb(100 116 139 / 0.8) !important;
  border-bottom-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-y-slate-500\/85 {
  border-top-color: rgb(100 116 139 / 0.85) !important;
  border-bottom-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-y-slate-500\/90 {
  border-top-color: rgb(100 116 139 / 0.9) !important;
  border-bottom-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-y-slate-500\/95 {
  border-top-color: rgb(100 116 139 / 0.95) !important;
  border-bottom-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-y-slate-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-600\/0 {
  border-top-color: rgb(71 85 105 / 0) !important;
  border-bottom-color: rgb(71 85 105 / 0) !important;
}

.tw-border-y-slate-600\/10 {
  border-top-color: rgb(71 85 105 / 0.1) !important;
  border-bottom-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-y-slate-600\/100 {
  border-top-color: rgb(71 85 105 / 1) !important;
  border-bottom-color: rgb(71 85 105 / 1) !important;
}

.tw-border-y-slate-600\/15 {
  border-top-color: rgb(71 85 105 / 0.15) !important;
  border-bottom-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-y-slate-600\/20 {
  border-top-color: rgb(71 85 105 / 0.2) !important;
  border-bottom-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-y-slate-600\/25 {
  border-top-color: rgb(71 85 105 / 0.25) !important;
  border-bottom-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-y-slate-600\/30 {
  border-top-color: rgb(71 85 105 / 0.3) !important;
  border-bottom-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-y-slate-600\/35 {
  border-top-color: rgb(71 85 105 / 0.35) !important;
  border-bottom-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-y-slate-600\/40 {
  border-top-color: rgb(71 85 105 / 0.4) !important;
  border-bottom-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-y-slate-600\/45 {
  border-top-color: rgb(71 85 105 / 0.45) !important;
  border-bottom-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-y-slate-600\/5 {
  border-top-color: rgb(71 85 105 / 0.05) !important;
  border-bottom-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-y-slate-600\/50 {
  border-top-color: rgb(71 85 105 / 0.5) !important;
  border-bottom-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-y-slate-600\/55 {
  border-top-color: rgb(71 85 105 / 0.55) !important;
  border-bottom-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-y-slate-600\/60 {
  border-top-color: rgb(71 85 105 / 0.6) !important;
  border-bottom-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-y-slate-600\/65 {
  border-top-color: rgb(71 85 105 / 0.65) !important;
  border-bottom-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-y-slate-600\/70 {
  border-top-color: rgb(71 85 105 / 0.7) !important;
  border-bottom-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-y-slate-600\/75 {
  border-top-color: rgb(71 85 105 / 0.75) !important;
  border-bottom-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-y-slate-600\/80 {
  border-top-color: rgb(71 85 105 / 0.8) !important;
  border-bottom-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-y-slate-600\/85 {
  border-top-color: rgb(71 85 105 / 0.85) !important;
  border-bottom-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-y-slate-600\/90 {
  border-top-color: rgb(71 85 105 / 0.9) !important;
  border-bottom-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-y-slate-600\/95 {
  border-top-color: rgb(71 85 105 / 0.95) !important;
  border-bottom-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-y-slate-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-700\/0 {
  border-top-color: rgb(51 65 85 / 0) !important;
  border-bottom-color: rgb(51 65 85 / 0) !important;
}

.tw-border-y-slate-700\/10 {
  border-top-color: rgb(51 65 85 / 0.1) !important;
  border-bottom-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-y-slate-700\/100 {
  border-top-color: rgb(51 65 85 / 1) !important;
  border-bottom-color: rgb(51 65 85 / 1) !important;
}

.tw-border-y-slate-700\/15 {
  border-top-color: rgb(51 65 85 / 0.15) !important;
  border-bottom-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-y-slate-700\/20 {
  border-top-color: rgb(51 65 85 / 0.2) !important;
  border-bottom-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-y-slate-700\/25 {
  border-top-color: rgb(51 65 85 / 0.25) !important;
  border-bottom-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-y-slate-700\/30 {
  border-top-color: rgb(51 65 85 / 0.3) !important;
  border-bottom-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-y-slate-700\/35 {
  border-top-color: rgb(51 65 85 / 0.35) !important;
  border-bottom-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-y-slate-700\/40 {
  border-top-color: rgb(51 65 85 / 0.4) !important;
  border-bottom-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-y-slate-700\/45 {
  border-top-color: rgb(51 65 85 / 0.45) !important;
  border-bottom-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-y-slate-700\/5 {
  border-top-color: rgb(51 65 85 / 0.05) !important;
  border-bottom-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-y-slate-700\/50 {
  border-top-color: rgb(51 65 85 / 0.5) !important;
  border-bottom-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-y-slate-700\/55 {
  border-top-color: rgb(51 65 85 / 0.55) !important;
  border-bottom-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-y-slate-700\/60 {
  border-top-color: rgb(51 65 85 / 0.6) !important;
  border-bottom-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-y-slate-700\/65 {
  border-top-color: rgb(51 65 85 / 0.65) !important;
  border-bottom-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-y-slate-700\/70 {
  border-top-color: rgb(51 65 85 / 0.7) !important;
  border-bottom-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-y-slate-700\/75 {
  border-top-color: rgb(51 65 85 / 0.75) !important;
  border-bottom-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-y-slate-700\/80 {
  border-top-color: rgb(51 65 85 / 0.8) !important;
  border-bottom-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-y-slate-700\/85 {
  border-top-color: rgb(51 65 85 / 0.85) !important;
  border-bottom-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-y-slate-700\/90 {
  border-top-color: rgb(51 65 85 / 0.9) !important;
  border-bottom-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-y-slate-700\/95 {
  border-top-color: rgb(51 65 85 / 0.95) !important;
  border-bottom-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-y-slate-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-800\/0 {
  border-top-color: rgb(30 41 59 / 0) !important;
  border-bottom-color: rgb(30 41 59 / 0) !important;
}

.tw-border-y-slate-800\/10 {
  border-top-color: rgb(30 41 59 / 0.1) !important;
  border-bottom-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-y-slate-800\/100 {
  border-top-color: rgb(30 41 59 / 1) !important;
  border-bottom-color: rgb(30 41 59 / 1) !important;
}

.tw-border-y-slate-800\/15 {
  border-top-color: rgb(30 41 59 / 0.15) !important;
  border-bottom-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-y-slate-800\/20 {
  border-top-color: rgb(30 41 59 / 0.2) !important;
  border-bottom-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-y-slate-800\/25 {
  border-top-color: rgb(30 41 59 / 0.25) !important;
  border-bottom-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-y-slate-800\/30 {
  border-top-color: rgb(30 41 59 / 0.3) !important;
  border-bottom-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-y-slate-800\/35 {
  border-top-color: rgb(30 41 59 / 0.35) !important;
  border-bottom-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-y-slate-800\/40 {
  border-top-color: rgb(30 41 59 / 0.4) !important;
  border-bottom-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-y-slate-800\/45 {
  border-top-color: rgb(30 41 59 / 0.45) !important;
  border-bottom-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-y-slate-800\/5 {
  border-top-color: rgb(30 41 59 / 0.05) !important;
  border-bottom-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-y-slate-800\/50 {
  border-top-color: rgb(30 41 59 / 0.5) !important;
  border-bottom-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-y-slate-800\/55 {
  border-top-color: rgb(30 41 59 / 0.55) !important;
  border-bottom-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-y-slate-800\/60 {
  border-top-color: rgb(30 41 59 / 0.6) !important;
  border-bottom-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-y-slate-800\/65 {
  border-top-color: rgb(30 41 59 / 0.65) !important;
  border-bottom-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-y-slate-800\/70 {
  border-top-color: rgb(30 41 59 / 0.7) !important;
  border-bottom-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-y-slate-800\/75 {
  border-top-color: rgb(30 41 59 / 0.75) !important;
  border-bottom-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-y-slate-800\/80 {
  border-top-color: rgb(30 41 59 / 0.8) !important;
  border-bottom-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-y-slate-800\/85 {
  border-top-color: rgb(30 41 59 / 0.85) !important;
  border-bottom-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-y-slate-800\/90 {
  border-top-color: rgb(30 41 59 / 0.9) !important;
  border-bottom-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-y-slate-800\/95 {
  border-top-color: rgb(30 41 59 / 0.95) !important;
  border-bottom-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-y-slate-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-900\/0 {
  border-top-color: rgb(15 23 42 / 0) !important;
  border-bottom-color: rgb(15 23 42 / 0) !important;
}

.tw-border-y-slate-900\/10 {
  border-top-color: rgb(15 23 42 / 0.1) !important;
  border-bottom-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-y-slate-900\/100 {
  border-top-color: rgb(15 23 42 / 1) !important;
  border-bottom-color: rgb(15 23 42 / 1) !important;
}

.tw-border-y-slate-900\/15 {
  border-top-color: rgb(15 23 42 / 0.15) !important;
  border-bottom-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-y-slate-900\/20 {
  border-top-color: rgb(15 23 42 / 0.2) !important;
  border-bottom-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-y-slate-900\/25 {
  border-top-color: rgb(15 23 42 / 0.25) !important;
  border-bottom-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-y-slate-900\/30 {
  border-top-color: rgb(15 23 42 / 0.3) !important;
  border-bottom-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-y-slate-900\/35 {
  border-top-color: rgb(15 23 42 / 0.35) !important;
  border-bottom-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-y-slate-900\/40 {
  border-top-color: rgb(15 23 42 / 0.4) !important;
  border-bottom-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-y-slate-900\/45 {
  border-top-color: rgb(15 23 42 / 0.45) !important;
  border-bottom-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-y-slate-900\/5 {
  border-top-color: rgb(15 23 42 / 0.05) !important;
  border-bottom-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-y-slate-900\/50 {
  border-top-color: rgb(15 23 42 / 0.5) !important;
  border-bottom-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-y-slate-900\/55 {
  border-top-color: rgb(15 23 42 / 0.55) !important;
  border-bottom-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-y-slate-900\/60 {
  border-top-color: rgb(15 23 42 / 0.6) !important;
  border-bottom-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-y-slate-900\/65 {
  border-top-color: rgb(15 23 42 / 0.65) !important;
  border-bottom-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-y-slate-900\/70 {
  border-top-color: rgb(15 23 42 / 0.7) !important;
  border-bottom-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-y-slate-900\/75 {
  border-top-color: rgb(15 23 42 / 0.75) !important;
  border-bottom-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-y-slate-900\/80 {
  border-top-color: rgb(15 23 42 / 0.8) !important;
  border-bottom-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-y-slate-900\/85 {
  border-top-color: rgb(15 23 42 / 0.85) !important;
  border-bottom-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-y-slate-900\/90 {
  border-top-color: rgb(15 23 42 / 0.9) !important;
  border-bottom-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-y-slate-900\/95 {
  border-top-color: rgb(15 23 42 / 0.95) !important;
  border-bottom-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-y-slate-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-y-slate-950\/0 {
  border-top-color: rgb(2 6 23 / 0) !important;
  border-bottom-color: rgb(2 6 23 / 0) !important;
}

.tw-border-y-slate-950\/10 {
  border-top-color: rgb(2 6 23 / 0.1) !important;
  border-bottom-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-y-slate-950\/100 {
  border-top-color: rgb(2 6 23 / 1) !important;
  border-bottom-color: rgb(2 6 23 / 1) !important;
}

.tw-border-y-slate-950\/15 {
  border-top-color: rgb(2 6 23 / 0.15) !important;
  border-bottom-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-y-slate-950\/20 {
  border-top-color: rgb(2 6 23 / 0.2) !important;
  border-bottom-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-y-slate-950\/25 {
  border-top-color: rgb(2 6 23 / 0.25) !important;
  border-bottom-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-y-slate-950\/30 {
  border-top-color: rgb(2 6 23 / 0.3) !important;
  border-bottom-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-y-slate-950\/35 {
  border-top-color: rgb(2 6 23 / 0.35) !important;
  border-bottom-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-y-slate-950\/40 {
  border-top-color: rgb(2 6 23 / 0.4) !important;
  border-bottom-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-y-slate-950\/45 {
  border-top-color: rgb(2 6 23 / 0.45) !important;
  border-bottom-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-y-slate-950\/5 {
  border-top-color: rgb(2 6 23 / 0.05) !important;
  border-bottom-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-y-slate-950\/50 {
  border-top-color: rgb(2 6 23 / 0.5) !important;
  border-bottom-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-y-slate-950\/55 {
  border-top-color: rgb(2 6 23 / 0.55) !important;
  border-bottom-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-y-slate-950\/60 {
  border-top-color: rgb(2 6 23 / 0.6) !important;
  border-bottom-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-y-slate-950\/65 {
  border-top-color: rgb(2 6 23 / 0.65) !important;
  border-bottom-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-y-slate-950\/70 {
  border-top-color: rgb(2 6 23 / 0.7) !important;
  border-bottom-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-y-slate-950\/75 {
  border-top-color: rgb(2 6 23 / 0.75) !important;
  border-bottom-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-y-slate-950\/80 {
  border-top-color: rgb(2 6 23 / 0.8) !important;
  border-bottom-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-y-slate-950\/85 {
  border-top-color: rgb(2 6 23 / 0.85) !important;
  border-bottom-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-y-slate-950\/90 {
  border-top-color: rgb(2 6 23 / 0.9) !important;
  border-bottom-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-y-slate-950\/95 {
  border-top-color: rgb(2 6 23 / 0.95) !important;
  border-bottom-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-y-stone-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-100\/0 {
  border-top-color: rgb(245 245 244 / 0) !important;
  border-bottom-color: rgb(245 245 244 / 0) !important;
}

.tw-border-y-stone-100\/10 {
  border-top-color: rgb(245 245 244 / 0.1) !important;
  border-bottom-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-y-stone-100\/100 {
  border-top-color: rgb(245 245 244 / 1) !important;
  border-bottom-color: rgb(245 245 244 / 1) !important;
}

.tw-border-y-stone-100\/15 {
  border-top-color: rgb(245 245 244 / 0.15) !important;
  border-bottom-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-y-stone-100\/20 {
  border-top-color: rgb(245 245 244 / 0.2) !important;
  border-bottom-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-y-stone-100\/25 {
  border-top-color: rgb(245 245 244 / 0.25) !important;
  border-bottom-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-y-stone-100\/30 {
  border-top-color: rgb(245 245 244 / 0.3) !important;
  border-bottom-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-y-stone-100\/35 {
  border-top-color: rgb(245 245 244 / 0.35) !important;
  border-bottom-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-y-stone-100\/40 {
  border-top-color: rgb(245 245 244 / 0.4) !important;
  border-bottom-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-y-stone-100\/45 {
  border-top-color: rgb(245 245 244 / 0.45) !important;
  border-bottom-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-y-stone-100\/5 {
  border-top-color: rgb(245 245 244 / 0.05) !important;
  border-bottom-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-y-stone-100\/50 {
  border-top-color: rgb(245 245 244 / 0.5) !important;
  border-bottom-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-y-stone-100\/55 {
  border-top-color: rgb(245 245 244 / 0.55) !important;
  border-bottom-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-y-stone-100\/60 {
  border-top-color: rgb(245 245 244 / 0.6) !important;
  border-bottom-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-y-stone-100\/65 {
  border-top-color: rgb(245 245 244 / 0.65) !important;
  border-bottom-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-y-stone-100\/70 {
  border-top-color: rgb(245 245 244 / 0.7) !important;
  border-bottom-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-y-stone-100\/75 {
  border-top-color: rgb(245 245 244 / 0.75) !important;
  border-bottom-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-y-stone-100\/80 {
  border-top-color: rgb(245 245 244 / 0.8) !important;
  border-bottom-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-y-stone-100\/85 {
  border-top-color: rgb(245 245 244 / 0.85) !important;
  border-bottom-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-y-stone-100\/90 {
  border-top-color: rgb(245 245 244 / 0.9) !important;
  border-bottom-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-y-stone-100\/95 {
  border-top-color: rgb(245 245 244 / 0.95) !important;
  border-bottom-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-y-stone-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-200\/0 {
  border-top-color: rgb(231 229 228 / 0) !important;
  border-bottom-color: rgb(231 229 228 / 0) !important;
}

.tw-border-y-stone-200\/10 {
  border-top-color: rgb(231 229 228 / 0.1) !important;
  border-bottom-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-y-stone-200\/100 {
  border-top-color: rgb(231 229 228 / 1) !important;
  border-bottom-color: rgb(231 229 228 / 1) !important;
}

.tw-border-y-stone-200\/15 {
  border-top-color: rgb(231 229 228 / 0.15) !important;
  border-bottom-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-y-stone-200\/20 {
  border-top-color: rgb(231 229 228 / 0.2) !important;
  border-bottom-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-y-stone-200\/25 {
  border-top-color: rgb(231 229 228 / 0.25) !important;
  border-bottom-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-y-stone-200\/30 {
  border-top-color: rgb(231 229 228 / 0.3) !important;
  border-bottom-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-y-stone-200\/35 {
  border-top-color: rgb(231 229 228 / 0.35) !important;
  border-bottom-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-y-stone-200\/40 {
  border-top-color: rgb(231 229 228 / 0.4) !important;
  border-bottom-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-y-stone-200\/45 {
  border-top-color: rgb(231 229 228 / 0.45) !important;
  border-bottom-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-y-stone-200\/5 {
  border-top-color: rgb(231 229 228 / 0.05) !important;
  border-bottom-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-y-stone-200\/50 {
  border-top-color: rgb(231 229 228 / 0.5) !important;
  border-bottom-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-y-stone-200\/55 {
  border-top-color: rgb(231 229 228 / 0.55) !important;
  border-bottom-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-y-stone-200\/60 {
  border-top-color: rgb(231 229 228 / 0.6) !important;
  border-bottom-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-y-stone-200\/65 {
  border-top-color: rgb(231 229 228 / 0.65) !important;
  border-bottom-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-y-stone-200\/70 {
  border-top-color: rgb(231 229 228 / 0.7) !important;
  border-bottom-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-y-stone-200\/75 {
  border-top-color: rgb(231 229 228 / 0.75) !important;
  border-bottom-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-y-stone-200\/80 {
  border-top-color: rgb(231 229 228 / 0.8) !important;
  border-bottom-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-y-stone-200\/85 {
  border-top-color: rgb(231 229 228 / 0.85) !important;
  border-bottom-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-y-stone-200\/90 {
  border-top-color: rgb(231 229 228 / 0.9) !important;
  border-bottom-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-y-stone-200\/95 {
  border-top-color: rgb(231 229 228 / 0.95) !important;
  border-bottom-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-y-stone-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-300\/0 {
  border-top-color: rgb(214 211 209 / 0) !important;
  border-bottom-color: rgb(214 211 209 / 0) !important;
}

.tw-border-y-stone-300\/10 {
  border-top-color: rgb(214 211 209 / 0.1) !important;
  border-bottom-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-y-stone-300\/100 {
  border-top-color: rgb(214 211 209 / 1) !important;
  border-bottom-color: rgb(214 211 209 / 1) !important;
}

.tw-border-y-stone-300\/15 {
  border-top-color: rgb(214 211 209 / 0.15) !important;
  border-bottom-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-y-stone-300\/20 {
  border-top-color: rgb(214 211 209 / 0.2) !important;
  border-bottom-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-y-stone-300\/25 {
  border-top-color: rgb(214 211 209 / 0.25) !important;
  border-bottom-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-y-stone-300\/30 {
  border-top-color: rgb(214 211 209 / 0.3) !important;
  border-bottom-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-y-stone-300\/35 {
  border-top-color: rgb(214 211 209 / 0.35) !important;
  border-bottom-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-y-stone-300\/40 {
  border-top-color: rgb(214 211 209 / 0.4) !important;
  border-bottom-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-y-stone-300\/45 {
  border-top-color: rgb(214 211 209 / 0.45) !important;
  border-bottom-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-y-stone-300\/5 {
  border-top-color: rgb(214 211 209 / 0.05) !important;
  border-bottom-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-y-stone-300\/50 {
  border-top-color: rgb(214 211 209 / 0.5) !important;
  border-bottom-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-y-stone-300\/55 {
  border-top-color: rgb(214 211 209 / 0.55) !important;
  border-bottom-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-y-stone-300\/60 {
  border-top-color: rgb(214 211 209 / 0.6) !important;
  border-bottom-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-y-stone-300\/65 {
  border-top-color: rgb(214 211 209 / 0.65) !important;
  border-bottom-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-y-stone-300\/70 {
  border-top-color: rgb(214 211 209 / 0.7) !important;
  border-bottom-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-y-stone-300\/75 {
  border-top-color: rgb(214 211 209 / 0.75) !important;
  border-bottom-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-y-stone-300\/80 {
  border-top-color: rgb(214 211 209 / 0.8) !important;
  border-bottom-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-y-stone-300\/85 {
  border-top-color: rgb(214 211 209 / 0.85) !important;
  border-bottom-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-y-stone-300\/90 {
  border-top-color: rgb(214 211 209 / 0.9) !important;
  border-bottom-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-y-stone-300\/95 {
  border-top-color: rgb(214 211 209 / 0.95) !important;
  border-bottom-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-y-stone-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-400\/0 {
  border-top-color: rgb(168 162 158 / 0) !important;
  border-bottom-color: rgb(168 162 158 / 0) !important;
}

.tw-border-y-stone-400\/10 {
  border-top-color: rgb(168 162 158 / 0.1) !important;
  border-bottom-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-y-stone-400\/100 {
  border-top-color: rgb(168 162 158 / 1) !important;
  border-bottom-color: rgb(168 162 158 / 1) !important;
}

.tw-border-y-stone-400\/15 {
  border-top-color: rgb(168 162 158 / 0.15) !important;
  border-bottom-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-y-stone-400\/20 {
  border-top-color: rgb(168 162 158 / 0.2) !important;
  border-bottom-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-y-stone-400\/25 {
  border-top-color: rgb(168 162 158 / 0.25) !important;
  border-bottom-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-y-stone-400\/30 {
  border-top-color: rgb(168 162 158 / 0.3) !important;
  border-bottom-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-y-stone-400\/35 {
  border-top-color: rgb(168 162 158 / 0.35) !important;
  border-bottom-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-y-stone-400\/40 {
  border-top-color: rgb(168 162 158 / 0.4) !important;
  border-bottom-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-y-stone-400\/45 {
  border-top-color: rgb(168 162 158 / 0.45) !important;
  border-bottom-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-y-stone-400\/5 {
  border-top-color: rgb(168 162 158 / 0.05) !important;
  border-bottom-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-y-stone-400\/50 {
  border-top-color: rgb(168 162 158 / 0.5) !important;
  border-bottom-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-y-stone-400\/55 {
  border-top-color: rgb(168 162 158 / 0.55) !important;
  border-bottom-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-y-stone-400\/60 {
  border-top-color: rgb(168 162 158 / 0.6) !important;
  border-bottom-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-y-stone-400\/65 {
  border-top-color: rgb(168 162 158 / 0.65) !important;
  border-bottom-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-y-stone-400\/70 {
  border-top-color: rgb(168 162 158 / 0.7) !important;
  border-bottom-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-y-stone-400\/75 {
  border-top-color: rgb(168 162 158 / 0.75) !important;
  border-bottom-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-y-stone-400\/80 {
  border-top-color: rgb(168 162 158 / 0.8) !important;
  border-bottom-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-y-stone-400\/85 {
  border-top-color: rgb(168 162 158 / 0.85) !important;
  border-bottom-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-y-stone-400\/90 {
  border-top-color: rgb(168 162 158 / 0.9) !important;
  border-bottom-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-y-stone-400\/95 {
  border-top-color: rgb(168 162 158 / 0.95) !important;
  border-bottom-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-y-stone-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-50\/0 {
  border-top-color: rgb(250 250 249 / 0) !important;
  border-bottom-color: rgb(250 250 249 / 0) !important;
}

.tw-border-y-stone-50\/10 {
  border-top-color: rgb(250 250 249 / 0.1) !important;
  border-bottom-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-y-stone-50\/100 {
  border-top-color: rgb(250 250 249 / 1) !important;
  border-bottom-color: rgb(250 250 249 / 1) !important;
}

.tw-border-y-stone-50\/15 {
  border-top-color: rgb(250 250 249 / 0.15) !important;
  border-bottom-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-y-stone-50\/20 {
  border-top-color: rgb(250 250 249 / 0.2) !important;
  border-bottom-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-y-stone-50\/25 {
  border-top-color: rgb(250 250 249 / 0.25) !important;
  border-bottom-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-y-stone-50\/30 {
  border-top-color: rgb(250 250 249 / 0.3) !important;
  border-bottom-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-y-stone-50\/35 {
  border-top-color: rgb(250 250 249 / 0.35) !important;
  border-bottom-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-y-stone-50\/40 {
  border-top-color: rgb(250 250 249 / 0.4) !important;
  border-bottom-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-y-stone-50\/45 {
  border-top-color: rgb(250 250 249 / 0.45) !important;
  border-bottom-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-y-stone-50\/5 {
  border-top-color: rgb(250 250 249 / 0.05) !important;
  border-bottom-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-y-stone-50\/50 {
  border-top-color: rgb(250 250 249 / 0.5) !important;
  border-bottom-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-y-stone-50\/55 {
  border-top-color: rgb(250 250 249 / 0.55) !important;
  border-bottom-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-y-stone-50\/60 {
  border-top-color: rgb(250 250 249 / 0.6) !important;
  border-bottom-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-y-stone-50\/65 {
  border-top-color: rgb(250 250 249 / 0.65) !important;
  border-bottom-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-y-stone-50\/70 {
  border-top-color: rgb(250 250 249 / 0.7) !important;
  border-bottom-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-y-stone-50\/75 {
  border-top-color: rgb(250 250 249 / 0.75) !important;
  border-bottom-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-y-stone-50\/80 {
  border-top-color: rgb(250 250 249 / 0.8) !important;
  border-bottom-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-y-stone-50\/85 {
  border-top-color: rgb(250 250 249 / 0.85) !important;
  border-bottom-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-y-stone-50\/90 {
  border-top-color: rgb(250 250 249 / 0.9) !important;
  border-bottom-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-y-stone-50\/95 {
  border-top-color: rgb(250 250 249 / 0.95) !important;
  border-bottom-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-y-stone-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-500\/0 {
  border-top-color: rgb(120 113 108 / 0) !important;
  border-bottom-color: rgb(120 113 108 / 0) !important;
}

.tw-border-y-stone-500\/10 {
  border-top-color: rgb(120 113 108 / 0.1) !important;
  border-bottom-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-y-stone-500\/100 {
  border-top-color: rgb(120 113 108 / 1) !important;
  border-bottom-color: rgb(120 113 108 / 1) !important;
}

.tw-border-y-stone-500\/15 {
  border-top-color: rgb(120 113 108 / 0.15) !important;
  border-bottom-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-y-stone-500\/20 {
  border-top-color: rgb(120 113 108 / 0.2) !important;
  border-bottom-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-y-stone-500\/25 {
  border-top-color: rgb(120 113 108 / 0.25) !important;
  border-bottom-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-y-stone-500\/30 {
  border-top-color: rgb(120 113 108 / 0.3) !important;
  border-bottom-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-y-stone-500\/35 {
  border-top-color: rgb(120 113 108 / 0.35) !important;
  border-bottom-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-y-stone-500\/40 {
  border-top-color: rgb(120 113 108 / 0.4) !important;
  border-bottom-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-y-stone-500\/45 {
  border-top-color: rgb(120 113 108 / 0.45) !important;
  border-bottom-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-y-stone-500\/5 {
  border-top-color: rgb(120 113 108 / 0.05) !important;
  border-bottom-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-y-stone-500\/50 {
  border-top-color: rgb(120 113 108 / 0.5) !important;
  border-bottom-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-y-stone-500\/55 {
  border-top-color: rgb(120 113 108 / 0.55) !important;
  border-bottom-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-y-stone-500\/60 {
  border-top-color: rgb(120 113 108 / 0.6) !important;
  border-bottom-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-y-stone-500\/65 {
  border-top-color: rgb(120 113 108 / 0.65) !important;
  border-bottom-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-y-stone-500\/70 {
  border-top-color: rgb(120 113 108 / 0.7) !important;
  border-bottom-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-y-stone-500\/75 {
  border-top-color: rgb(120 113 108 / 0.75) !important;
  border-bottom-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-y-stone-500\/80 {
  border-top-color: rgb(120 113 108 / 0.8) !important;
  border-bottom-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-y-stone-500\/85 {
  border-top-color: rgb(120 113 108 / 0.85) !important;
  border-bottom-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-y-stone-500\/90 {
  border-top-color: rgb(120 113 108 / 0.9) !important;
  border-bottom-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-y-stone-500\/95 {
  border-top-color: rgb(120 113 108 / 0.95) !important;
  border-bottom-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-y-stone-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-600\/0 {
  border-top-color: rgb(87 83 78 / 0) !important;
  border-bottom-color: rgb(87 83 78 / 0) !important;
}

.tw-border-y-stone-600\/10 {
  border-top-color: rgb(87 83 78 / 0.1) !important;
  border-bottom-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-y-stone-600\/100 {
  border-top-color: rgb(87 83 78 / 1) !important;
  border-bottom-color: rgb(87 83 78 / 1) !important;
}

.tw-border-y-stone-600\/15 {
  border-top-color: rgb(87 83 78 / 0.15) !important;
  border-bottom-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-y-stone-600\/20 {
  border-top-color: rgb(87 83 78 / 0.2) !important;
  border-bottom-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-y-stone-600\/25 {
  border-top-color: rgb(87 83 78 / 0.25) !important;
  border-bottom-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-y-stone-600\/30 {
  border-top-color: rgb(87 83 78 / 0.3) !important;
  border-bottom-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-y-stone-600\/35 {
  border-top-color: rgb(87 83 78 / 0.35) !important;
  border-bottom-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-y-stone-600\/40 {
  border-top-color: rgb(87 83 78 / 0.4) !important;
  border-bottom-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-y-stone-600\/45 {
  border-top-color: rgb(87 83 78 / 0.45) !important;
  border-bottom-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-y-stone-600\/5 {
  border-top-color: rgb(87 83 78 / 0.05) !important;
  border-bottom-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-y-stone-600\/50 {
  border-top-color: rgb(87 83 78 / 0.5) !important;
  border-bottom-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-y-stone-600\/55 {
  border-top-color: rgb(87 83 78 / 0.55) !important;
  border-bottom-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-y-stone-600\/60 {
  border-top-color: rgb(87 83 78 / 0.6) !important;
  border-bottom-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-y-stone-600\/65 {
  border-top-color: rgb(87 83 78 / 0.65) !important;
  border-bottom-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-y-stone-600\/70 {
  border-top-color: rgb(87 83 78 / 0.7) !important;
  border-bottom-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-y-stone-600\/75 {
  border-top-color: rgb(87 83 78 / 0.75) !important;
  border-bottom-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-y-stone-600\/80 {
  border-top-color: rgb(87 83 78 / 0.8) !important;
  border-bottom-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-y-stone-600\/85 {
  border-top-color: rgb(87 83 78 / 0.85) !important;
  border-bottom-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-y-stone-600\/90 {
  border-top-color: rgb(87 83 78 / 0.9) !important;
  border-bottom-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-y-stone-600\/95 {
  border-top-color: rgb(87 83 78 / 0.95) !important;
  border-bottom-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-y-stone-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-700\/0 {
  border-top-color: rgb(68 64 60 / 0) !important;
  border-bottom-color: rgb(68 64 60 / 0) !important;
}

.tw-border-y-stone-700\/10 {
  border-top-color: rgb(68 64 60 / 0.1) !important;
  border-bottom-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-y-stone-700\/100 {
  border-top-color: rgb(68 64 60 / 1) !important;
  border-bottom-color: rgb(68 64 60 / 1) !important;
}

.tw-border-y-stone-700\/15 {
  border-top-color: rgb(68 64 60 / 0.15) !important;
  border-bottom-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-y-stone-700\/20 {
  border-top-color: rgb(68 64 60 / 0.2) !important;
  border-bottom-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-y-stone-700\/25 {
  border-top-color: rgb(68 64 60 / 0.25) !important;
  border-bottom-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-y-stone-700\/30 {
  border-top-color: rgb(68 64 60 / 0.3) !important;
  border-bottom-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-y-stone-700\/35 {
  border-top-color: rgb(68 64 60 / 0.35) !important;
  border-bottom-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-y-stone-700\/40 {
  border-top-color: rgb(68 64 60 / 0.4) !important;
  border-bottom-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-y-stone-700\/45 {
  border-top-color: rgb(68 64 60 / 0.45) !important;
  border-bottom-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-y-stone-700\/5 {
  border-top-color: rgb(68 64 60 / 0.05) !important;
  border-bottom-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-y-stone-700\/50 {
  border-top-color: rgb(68 64 60 / 0.5) !important;
  border-bottom-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-y-stone-700\/55 {
  border-top-color: rgb(68 64 60 / 0.55) !important;
  border-bottom-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-y-stone-700\/60 {
  border-top-color: rgb(68 64 60 / 0.6) !important;
  border-bottom-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-y-stone-700\/65 {
  border-top-color: rgb(68 64 60 / 0.65) !important;
  border-bottom-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-y-stone-700\/70 {
  border-top-color: rgb(68 64 60 / 0.7) !important;
  border-bottom-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-y-stone-700\/75 {
  border-top-color: rgb(68 64 60 / 0.75) !important;
  border-bottom-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-y-stone-700\/80 {
  border-top-color: rgb(68 64 60 / 0.8) !important;
  border-bottom-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-y-stone-700\/85 {
  border-top-color: rgb(68 64 60 / 0.85) !important;
  border-bottom-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-y-stone-700\/90 {
  border-top-color: rgb(68 64 60 / 0.9) !important;
  border-bottom-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-y-stone-700\/95 {
  border-top-color: rgb(68 64 60 / 0.95) !important;
  border-bottom-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-y-stone-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-800\/0 {
  border-top-color: rgb(41 37 36 / 0) !important;
  border-bottom-color: rgb(41 37 36 / 0) !important;
}

.tw-border-y-stone-800\/10 {
  border-top-color: rgb(41 37 36 / 0.1) !important;
  border-bottom-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-y-stone-800\/100 {
  border-top-color: rgb(41 37 36 / 1) !important;
  border-bottom-color: rgb(41 37 36 / 1) !important;
}

.tw-border-y-stone-800\/15 {
  border-top-color: rgb(41 37 36 / 0.15) !important;
  border-bottom-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-y-stone-800\/20 {
  border-top-color: rgb(41 37 36 / 0.2) !important;
  border-bottom-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-y-stone-800\/25 {
  border-top-color: rgb(41 37 36 / 0.25) !important;
  border-bottom-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-y-stone-800\/30 {
  border-top-color: rgb(41 37 36 / 0.3) !important;
  border-bottom-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-y-stone-800\/35 {
  border-top-color: rgb(41 37 36 / 0.35) !important;
  border-bottom-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-y-stone-800\/40 {
  border-top-color: rgb(41 37 36 / 0.4) !important;
  border-bottom-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-y-stone-800\/45 {
  border-top-color: rgb(41 37 36 / 0.45) !important;
  border-bottom-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-y-stone-800\/5 {
  border-top-color: rgb(41 37 36 / 0.05) !important;
  border-bottom-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-y-stone-800\/50 {
  border-top-color: rgb(41 37 36 / 0.5) !important;
  border-bottom-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-y-stone-800\/55 {
  border-top-color: rgb(41 37 36 / 0.55) !important;
  border-bottom-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-y-stone-800\/60 {
  border-top-color: rgb(41 37 36 / 0.6) !important;
  border-bottom-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-y-stone-800\/65 {
  border-top-color: rgb(41 37 36 / 0.65) !important;
  border-bottom-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-y-stone-800\/70 {
  border-top-color: rgb(41 37 36 / 0.7) !important;
  border-bottom-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-y-stone-800\/75 {
  border-top-color: rgb(41 37 36 / 0.75) !important;
  border-bottom-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-y-stone-800\/80 {
  border-top-color: rgb(41 37 36 / 0.8) !important;
  border-bottom-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-y-stone-800\/85 {
  border-top-color: rgb(41 37 36 / 0.85) !important;
  border-bottom-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-y-stone-800\/90 {
  border-top-color: rgb(41 37 36 / 0.9) !important;
  border-bottom-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-y-stone-800\/95 {
  border-top-color: rgb(41 37 36 / 0.95) !important;
  border-bottom-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-y-stone-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-900\/0 {
  border-top-color: rgb(28 25 23 / 0) !important;
  border-bottom-color: rgb(28 25 23 / 0) !important;
}

.tw-border-y-stone-900\/10 {
  border-top-color: rgb(28 25 23 / 0.1) !important;
  border-bottom-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-y-stone-900\/100 {
  border-top-color: rgb(28 25 23 / 1) !important;
  border-bottom-color: rgb(28 25 23 / 1) !important;
}

.tw-border-y-stone-900\/15 {
  border-top-color: rgb(28 25 23 / 0.15) !important;
  border-bottom-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-y-stone-900\/20 {
  border-top-color: rgb(28 25 23 / 0.2) !important;
  border-bottom-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-y-stone-900\/25 {
  border-top-color: rgb(28 25 23 / 0.25) !important;
  border-bottom-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-y-stone-900\/30 {
  border-top-color: rgb(28 25 23 / 0.3) !important;
  border-bottom-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-y-stone-900\/35 {
  border-top-color: rgb(28 25 23 / 0.35) !important;
  border-bottom-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-y-stone-900\/40 {
  border-top-color: rgb(28 25 23 / 0.4) !important;
  border-bottom-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-y-stone-900\/45 {
  border-top-color: rgb(28 25 23 / 0.45) !important;
  border-bottom-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-y-stone-900\/5 {
  border-top-color: rgb(28 25 23 / 0.05) !important;
  border-bottom-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-y-stone-900\/50 {
  border-top-color: rgb(28 25 23 / 0.5) !important;
  border-bottom-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-y-stone-900\/55 {
  border-top-color: rgb(28 25 23 / 0.55) !important;
  border-bottom-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-y-stone-900\/60 {
  border-top-color: rgb(28 25 23 / 0.6) !important;
  border-bottom-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-y-stone-900\/65 {
  border-top-color: rgb(28 25 23 / 0.65) !important;
  border-bottom-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-y-stone-900\/70 {
  border-top-color: rgb(28 25 23 / 0.7) !important;
  border-bottom-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-y-stone-900\/75 {
  border-top-color: rgb(28 25 23 / 0.75) !important;
  border-bottom-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-y-stone-900\/80 {
  border-top-color: rgb(28 25 23 / 0.8) !important;
  border-bottom-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-y-stone-900\/85 {
  border-top-color: rgb(28 25 23 / 0.85) !important;
  border-bottom-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-y-stone-900\/90 {
  border-top-color: rgb(28 25 23 / 0.9) !important;
  border-bottom-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-y-stone-900\/95 {
  border-top-color: rgb(28 25 23 / 0.95) !important;
  border-bottom-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-y-stone-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-y-stone-950\/0 {
  border-top-color: rgb(12 10 9 / 0) !important;
  border-bottom-color: rgb(12 10 9 / 0) !important;
}

.tw-border-y-stone-950\/10 {
  border-top-color: rgb(12 10 9 / 0.1) !important;
  border-bottom-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-y-stone-950\/100 {
  border-top-color: rgb(12 10 9 / 1) !important;
  border-bottom-color: rgb(12 10 9 / 1) !important;
}

.tw-border-y-stone-950\/15 {
  border-top-color: rgb(12 10 9 / 0.15) !important;
  border-bottom-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-y-stone-950\/20 {
  border-top-color: rgb(12 10 9 / 0.2) !important;
  border-bottom-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-y-stone-950\/25 {
  border-top-color: rgb(12 10 9 / 0.25) !important;
  border-bottom-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-y-stone-950\/30 {
  border-top-color: rgb(12 10 9 / 0.3) !important;
  border-bottom-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-y-stone-950\/35 {
  border-top-color: rgb(12 10 9 / 0.35) !important;
  border-bottom-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-y-stone-950\/40 {
  border-top-color: rgb(12 10 9 / 0.4) !important;
  border-bottom-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-y-stone-950\/45 {
  border-top-color: rgb(12 10 9 / 0.45) !important;
  border-bottom-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-y-stone-950\/5 {
  border-top-color: rgb(12 10 9 / 0.05) !important;
  border-bottom-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-y-stone-950\/50 {
  border-top-color: rgb(12 10 9 / 0.5) !important;
  border-bottom-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-y-stone-950\/55 {
  border-top-color: rgb(12 10 9 / 0.55) !important;
  border-bottom-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-y-stone-950\/60 {
  border-top-color: rgb(12 10 9 / 0.6) !important;
  border-bottom-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-y-stone-950\/65 {
  border-top-color: rgb(12 10 9 / 0.65) !important;
  border-bottom-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-y-stone-950\/70 {
  border-top-color: rgb(12 10 9 / 0.7) !important;
  border-bottom-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-y-stone-950\/75 {
  border-top-color: rgb(12 10 9 / 0.75) !important;
  border-bottom-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-y-stone-950\/80 {
  border-top-color: rgb(12 10 9 / 0.8) !important;
  border-bottom-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-y-stone-950\/85 {
  border-top-color: rgb(12 10 9 / 0.85) !important;
  border-bottom-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-y-stone-950\/90 {
  border-top-color: rgb(12 10 9 / 0.9) !important;
  border-bottom-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-y-stone-950\/95 {
  border-top-color: rgb(12 10 9 / 0.95) !important;
  border-bottom-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-y-teal-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-100\/0 {
  border-top-color: rgb(204 251 241 / 0) !important;
  border-bottom-color: rgb(204 251 241 / 0) !important;
}

.tw-border-y-teal-100\/10 {
  border-top-color: rgb(204 251 241 / 0.1) !important;
  border-bottom-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-y-teal-100\/100 {
  border-top-color: rgb(204 251 241 / 1) !important;
  border-bottom-color: rgb(204 251 241 / 1) !important;
}

.tw-border-y-teal-100\/15 {
  border-top-color: rgb(204 251 241 / 0.15) !important;
  border-bottom-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-y-teal-100\/20 {
  border-top-color: rgb(204 251 241 / 0.2) !important;
  border-bottom-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-y-teal-100\/25 {
  border-top-color: rgb(204 251 241 / 0.25) !important;
  border-bottom-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-y-teal-100\/30 {
  border-top-color: rgb(204 251 241 / 0.3) !important;
  border-bottom-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-y-teal-100\/35 {
  border-top-color: rgb(204 251 241 / 0.35) !important;
  border-bottom-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-y-teal-100\/40 {
  border-top-color: rgb(204 251 241 / 0.4) !important;
  border-bottom-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-y-teal-100\/45 {
  border-top-color: rgb(204 251 241 / 0.45) !important;
  border-bottom-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-y-teal-100\/5 {
  border-top-color: rgb(204 251 241 / 0.05) !important;
  border-bottom-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-y-teal-100\/50 {
  border-top-color: rgb(204 251 241 / 0.5) !important;
  border-bottom-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-y-teal-100\/55 {
  border-top-color: rgb(204 251 241 / 0.55) !important;
  border-bottom-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-y-teal-100\/60 {
  border-top-color: rgb(204 251 241 / 0.6) !important;
  border-bottom-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-y-teal-100\/65 {
  border-top-color: rgb(204 251 241 / 0.65) !important;
  border-bottom-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-y-teal-100\/70 {
  border-top-color: rgb(204 251 241 / 0.7) !important;
  border-bottom-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-y-teal-100\/75 {
  border-top-color: rgb(204 251 241 / 0.75) !important;
  border-bottom-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-y-teal-100\/80 {
  border-top-color: rgb(204 251 241 / 0.8) !important;
  border-bottom-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-y-teal-100\/85 {
  border-top-color: rgb(204 251 241 / 0.85) !important;
  border-bottom-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-y-teal-100\/90 {
  border-top-color: rgb(204 251 241 / 0.9) !important;
  border-bottom-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-y-teal-100\/95 {
  border-top-color: rgb(204 251 241 / 0.95) !important;
  border-bottom-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-y-teal-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-200\/0 {
  border-top-color: rgb(153 246 228 / 0) !important;
  border-bottom-color: rgb(153 246 228 / 0) !important;
}

.tw-border-y-teal-200\/10 {
  border-top-color: rgb(153 246 228 / 0.1) !important;
  border-bottom-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-y-teal-200\/100 {
  border-top-color: rgb(153 246 228 / 1) !important;
  border-bottom-color: rgb(153 246 228 / 1) !important;
}

.tw-border-y-teal-200\/15 {
  border-top-color: rgb(153 246 228 / 0.15) !important;
  border-bottom-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-y-teal-200\/20 {
  border-top-color: rgb(153 246 228 / 0.2) !important;
  border-bottom-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-y-teal-200\/25 {
  border-top-color: rgb(153 246 228 / 0.25) !important;
  border-bottom-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-y-teal-200\/30 {
  border-top-color: rgb(153 246 228 / 0.3) !important;
  border-bottom-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-y-teal-200\/35 {
  border-top-color: rgb(153 246 228 / 0.35) !important;
  border-bottom-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-y-teal-200\/40 {
  border-top-color: rgb(153 246 228 / 0.4) !important;
  border-bottom-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-y-teal-200\/45 {
  border-top-color: rgb(153 246 228 / 0.45) !important;
  border-bottom-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-y-teal-200\/5 {
  border-top-color: rgb(153 246 228 / 0.05) !important;
  border-bottom-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-y-teal-200\/50 {
  border-top-color: rgb(153 246 228 / 0.5) !important;
  border-bottom-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-y-teal-200\/55 {
  border-top-color: rgb(153 246 228 / 0.55) !important;
  border-bottom-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-y-teal-200\/60 {
  border-top-color: rgb(153 246 228 / 0.6) !important;
  border-bottom-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-y-teal-200\/65 {
  border-top-color: rgb(153 246 228 / 0.65) !important;
  border-bottom-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-y-teal-200\/70 {
  border-top-color: rgb(153 246 228 / 0.7) !important;
  border-bottom-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-y-teal-200\/75 {
  border-top-color: rgb(153 246 228 / 0.75) !important;
  border-bottom-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-y-teal-200\/80 {
  border-top-color: rgb(153 246 228 / 0.8) !important;
  border-bottom-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-y-teal-200\/85 {
  border-top-color: rgb(153 246 228 / 0.85) !important;
  border-bottom-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-y-teal-200\/90 {
  border-top-color: rgb(153 246 228 / 0.9) !important;
  border-bottom-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-y-teal-200\/95 {
  border-top-color: rgb(153 246 228 / 0.95) !important;
  border-bottom-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-y-teal-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-300\/0 {
  border-top-color: rgb(94 234 212 / 0) !important;
  border-bottom-color: rgb(94 234 212 / 0) !important;
}

.tw-border-y-teal-300\/10 {
  border-top-color: rgb(94 234 212 / 0.1) !important;
  border-bottom-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-y-teal-300\/100 {
  border-top-color: rgb(94 234 212 / 1) !important;
  border-bottom-color: rgb(94 234 212 / 1) !important;
}

.tw-border-y-teal-300\/15 {
  border-top-color: rgb(94 234 212 / 0.15) !important;
  border-bottom-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-y-teal-300\/20 {
  border-top-color: rgb(94 234 212 / 0.2) !important;
  border-bottom-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-y-teal-300\/25 {
  border-top-color: rgb(94 234 212 / 0.25) !important;
  border-bottom-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-y-teal-300\/30 {
  border-top-color: rgb(94 234 212 / 0.3) !important;
  border-bottom-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-y-teal-300\/35 {
  border-top-color: rgb(94 234 212 / 0.35) !important;
  border-bottom-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-y-teal-300\/40 {
  border-top-color: rgb(94 234 212 / 0.4) !important;
  border-bottom-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-y-teal-300\/45 {
  border-top-color: rgb(94 234 212 / 0.45) !important;
  border-bottom-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-y-teal-300\/5 {
  border-top-color: rgb(94 234 212 / 0.05) !important;
  border-bottom-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-y-teal-300\/50 {
  border-top-color: rgb(94 234 212 / 0.5) !important;
  border-bottom-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-y-teal-300\/55 {
  border-top-color: rgb(94 234 212 / 0.55) !important;
  border-bottom-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-y-teal-300\/60 {
  border-top-color: rgb(94 234 212 / 0.6) !important;
  border-bottom-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-y-teal-300\/65 {
  border-top-color: rgb(94 234 212 / 0.65) !important;
  border-bottom-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-y-teal-300\/70 {
  border-top-color: rgb(94 234 212 / 0.7) !important;
  border-bottom-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-y-teal-300\/75 {
  border-top-color: rgb(94 234 212 / 0.75) !important;
  border-bottom-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-y-teal-300\/80 {
  border-top-color: rgb(94 234 212 / 0.8) !important;
  border-bottom-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-y-teal-300\/85 {
  border-top-color: rgb(94 234 212 / 0.85) !important;
  border-bottom-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-y-teal-300\/90 {
  border-top-color: rgb(94 234 212 / 0.9) !important;
  border-bottom-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-y-teal-300\/95 {
  border-top-color: rgb(94 234 212 / 0.95) !important;
  border-bottom-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-y-teal-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-400\/0 {
  border-top-color: rgb(45 212 191 / 0) !important;
  border-bottom-color: rgb(45 212 191 / 0) !important;
}

.tw-border-y-teal-400\/10 {
  border-top-color: rgb(45 212 191 / 0.1) !important;
  border-bottom-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-y-teal-400\/100 {
  border-top-color: rgb(45 212 191 / 1) !important;
  border-bottom-color: rgb(45 212 191 / 1) !important;
}

.tw-border-y-teal-400\/15 {
  border-top-color: rgb(45 212 191 / 0.15) !important;
  border-bottom-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-y-teal-400\/20 {
  border-top-color: rgb(45 212 191 / 0.2) !important;
  border-bottom-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-y-teal-400\/25 {
  border-top-color: rgb(45 212 191 / 0.25) !important;
  border-bottom-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-y-teal-400\/30 {
  border-top-color: rgb(45 212 191 / 0.3) !important;
  border-bottom-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-y-teal-400\/35 {
  border-top-color: rgb(45 212 191 / 0.35) !important;
  border-bottom-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-y-teal-400\/40 {
  border-top-color: rgb(45 212 191 / 0.4) !important;
  border-bottom-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-y-teal-400\/45 {
  border-top-color: rgb(45 212 191 / 0.45) !important;
  border-bottom-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-y-teal-400\/5 {
  border-top-color: rgb(45 212 191 / 0.05) !important;
  border-bottom-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-y-teal-400\/50 {
  border-top-color: rgb(45 212 191 / 0.5) !important;
  border-bottom-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-y-teal-400\/55 {
  border-top-color: rgb(45 212 191 / 0.55) !important;
  border-bottom-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-y-teal-400\/60 {
  border-top-color: rgb(45 212 191 / 0.6) !important;
  border-bottom-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-y-teal-400\/65 {
  border-top-color: rgb(45 212 191 / 0.65) !important;
  border-bottom-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-y-teal-400\/70 {
  border-top-color: rgb(45 212 191 / 0.7) !important;
  border-bottom-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-y-teal-400\/75 {
  border-top-color: rgb(45 212 191 / 0.75) !important;
  border-bottom-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-y-teal-400\/80 {
  border-top-color: rgb(45 212 191 / 0.8) !important;
  border-bottom-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-y-teal-400\/85 {
  border-top-color: rgb(45 212 191 / 0.85) !important;
  border-bottom-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-y-teal-400\/90 {
  border-top-color: rgb(45 212 191 / 0.9) !important;
  border-bottom-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-y-teal-400\/95 {
  border-top-color: rgb(45 212 191 / 0.95) !important;
  border-bottom-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-y-teal-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-50\/0 {
  border-top-color: rgb(240 253 250 / 0) !important;
  border-bottom-color: rgb(240 253 250 / 0) !important;
}

.tw-border-y-teal-50\/10 {
  border-top-color: rgb(240 253 250 / 0.1) !important;
  border-bottom-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-y-teal-50\/100 {
  border-top-color: rgb(240 253 250 / 1) !important;
  border-bottom-color: rgb(240 253 250 / 1) !important;
}

.tw-border-y-teal-50\/15 {
  border-top-color: rgb(240 253 250 / 0.15) !important;
  border-bottom-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-y-teal-50\/20 {
  border-top-color: rgb(240 253 250 / 0.2) !important;
  border-bottom-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-y-teal-50\/25 {
  border-top-color: rgb(240 253 250 / 0.25) !important;
  border-bottom-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-y-teal-50\/30 {
  border-top-color: rgb(240 253 250 / 0.3) !important;
  border-bottom-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-y-teal-50\/35 {
  border-top-color: rgb(240 253 250 / 0.35) !important;
  border-bottom-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-y-teal-50\/40 {
  border-top-color: rgb(240 253 250 / 0.4) !important;
  border-bottom-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-y-teal-50\/45 {
  border-top-color: rgb(240 253 250 / 0.45) !important;
  border-bottom-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-y-teal-50\/5 {
  border-top-color: rgb(240 253 250 / 0.05) !important;
  border-bottom-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-y-teal-50\/50 {
  border-top-color: rgb(240 253 250 / 0.5) !important;
  border-bottom-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-y-teal-50\/55 {
  border-top-color: rgb(240 253 250 / 0.55) !important;
  border-bottom-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-y-teal-50\/60 {
  border-top-color: rgb(240 253 250 / 0.6) !important;
  border-bottom-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-y-teal-50\/65 {
  border-top-color: rgb(240 253 250 / 0.65) !important;
  border-bottom-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-y-teal-50\/70 {
  border-top-color: rgb(240 253 250 / 0.7) !important;
  border-bottom-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-y-teal-50\/75 {
  border-top-color: rgb(240 253 250 / 0.75) !important;
  border-bottom-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-y-teal-50\/80 {
  border-top-color: rgb(240 253 250 / 0.8) !important;
  border-bottom-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-y-teal-50\/85 {
  border-top-color: rgb(240 253 250 / 0.85) !important;
  border-bottom-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-y-teal-50\/90 {
  border-top-color: rgb(240 253 250 / 0.9) !important;
  border-bottom-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-y-teal-50\/95 {
  border-top-color: rgb(240 253 250 / 0.95) !important;
  border-bottom-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-y-teal-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-500\/0 {
  border-top-color: rgb(20 184 166 / 0) !important;
  border-bottom-color: rgb(20 184 166 / 0) !important;
}

.tw-border-y-teal-500\/10 {
  border-top-color: rgb(20 184 166 / 0.1) !important;
  border-bottom-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-y-teal-500\/100 {
  border-top-color: rgb(20 184 166 / 1) !important;
  border-bottom-color: rgb(20 184 166 / 1) !important;
}

.tw-border-y-teal-500\/15 {
  border-top-color: rgb(20 184 166 / 0.15) !important;
  border-bottom-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-y-teal-500\/20 {
  border-top-color: rgb(20 184 166 / 0.2) !important;
  border-bottom-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-y-teal-500\/25 {
  border-top-color: rgb(20 184 166 / 0.25) !important;
  border-bottom-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-y-teal-500\/30 {
  border-top-color: rgb(20 184 166 / 0.3) !important;
  border-bottom-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-y-teal-500\/35 {
  border-top-color: rgb(20 184 166 / 0.35) !important;
  border-bottom-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-y-teal-500\/40 {
  border-top-color: rgb(20 184 166 / 0.4) !important;
  border-bottom-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-y-teal-500\/45 {
  border-top-color: rgb(20 184 166 / 0.45) !important;
  border-bottom-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-y-teal-500\/5 {
  border-top-color: rgb(20 184 166 / 0.05) !important;
  border-bottom-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-y-teal-500\/50 {
  border-top-color: rgb(20 184 166 / 0.5) !important;
  border-bottom-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-y-teal-500\/55 {
  border-top-color: rgb(20 184 166 / 0.55) !important;
  border-bottom-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-y-teal-500\/60 {
  border-top-color: rgb(20 184 166 / 0.6) !important;
  border-bottom-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-y-teal-500\/65 {
  border-top-color: rgb(20 184 166 / 0.65) !important;
  border-bottom-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-y-teal-500\/70 {
  border-top-color: rgb(20 184 166 / 0.7) !important;
  border-bottom-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-y-teal-500\/75 {
  border-top-color: rgb(20 184 166 / 0.75) !important;
  border-bottom-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-y-teal-500\/80 {
  border-top-color: rgb(20 184 166 / 0.8) !important;
  border-bottom-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-y-teal-500\/85 {
  border-top-color: rgb(20 184 166 / 0.85) !important;
  border-bottom-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-y-teal-500\/90 {
  border-top-color: rgb(20 184 166 / 0.9) !important;
  border-bottom-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-y-teal-500\/95 {
  border-top-color: rgb(20 184 166 / 0.95) !important;
  border-bottom-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-y-teal-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-600\/0 {
  border-top-color: rgb(13 148 136 / 0) !important;
  border-bottom-color: rgb(13 148 136 / 0) !important;
}

.tw-border-y-teal-600\/10 {
  border-top-color: rgb(13 148 136 / 0.1) !important;
  border-bottom-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-y-teal-600\/100 {
  border-top-color: rgb(13 148 136 / 1) !important;
  border-bottom-color: rgb(13 148 136 / 1) !important;
}

.tw-border-y-teal-600\/15 {
  border-top-color: rgb(13 148 136 / 0.15) !important;
  border-bottom-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-y-teal-600\/20 {
  border-top-color: rgb(13 148 136 / 0.2) !important;
  border-bottom-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-y-teal-600\/25 {
  border-top-color: rgb(13 148 136 / 0.25) !important;
  border-bottom-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-y-teal-600\/30 {
  border-top-color: rgb(13 148 136 / 0.3) !important;
  border-bottom-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-y-teal-600\/35 {
  border-top-color: rgb(13 148 136 / 0.35) !important;
  border-bottom-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-y-teal-600\/40 {
  border-top-color: rgb(13 148 136 / 0.4) !important;
  border-bottom-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-y-teal-600\/45 {
  border-top-color: rgb(13 148 136 / 0.45) !important;
  border-bottom-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-y-teal-600\/5 {
  border-top-color: rgb(13 148 136 / 0.05) !important;
  border-bottom-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-y-teal-600\/50 {
  border-top-color: rgb(13 148 136 / 0.5) !important;
  border-bottom-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-y-teal-600\/55 {
  border-top-color: rgb(13 148 136 / 0.55) !important;
  border-bottom-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-y-teal-600\/60 {
  border-top-color: rgb(13 148 136 / 0.6) !important;
  border-bottom-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-y-teal-600\/65 {
  border-top-color: rgb(13 148 136 / 0.65) !important;
  border-bottom-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-y-teal-600\/70 {
  border-top-color: rgb(13 148 136 / 0.7) !important;
  border-bottom-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-y-teal-600\/75 {
  border-top-color: rgb(13 148 136 / 0.75) !important;
  border-bottom-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-y-teal-600\/80 {
  border-top-color: rgb(13 148 136 / 0.8) !important;
  border-bottom-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-y-teal-600\/85 {
  border-top-color: rgb(13 148 136 / 0.85) !important;
  border-bottom-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-y-teal-600\/90 {
  border-top-color: rgb(13 148 136 / 0.9) !important;
  border-bottom-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-y-teal-600\/95 {
  border-top-color: rgb(13 148 136 / 0.95) !important;
  border-bottom-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-y-teal-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-700\/0 {
  border-top-color: rgb(15 118 110 / 0) !important;
  border-bottom-color: rgb(15 118 110 / 0) !important;
}

.tw-border-y-teal-700\/10 {
  border-top-color: rgb(15 118 110 / 0.1) !important;
  border-bottom-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-y-teal-700\/100 {
  border-top-color: rgb(15 118 110 / 1) !important;
  border-bottom-color: rgb(15 118 110 / 1) !important;
}

.tw-border-y-teal-700\/15 {
  border-top-color: rgb(15 118 110 / 0.15) !important;
  border-bottom-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-y-teal-700\/20 {
  border-top-color: rgb(15 118 110 / 0.2) !important;
  border-bottom-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-y-teal-700\/25 {
  border-top-color: rgb(15 118 110 / 0.25) !important;
  border-bottom-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-y-teal-700\/30 {
  border-top-color: rgb(15 118 110 / 0.3) !important;
  border-bottom-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-y-teal-700\/35 {
  border-top-color: rgb(15 118 110 / 0.35) !important;
  border-bottom-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-y-teal-700\/40 {
  border-top-color: rgb(15 118 110 / 0.4) !important;
  border-bottom-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-y-teal-700\/45 {
  border-top-color: rgb(15 118 110 / 0.45) !important;
  border-bottom-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-y-teal-700\/5 {
  border-top-color: rgb(15 118 110 / 0.05) !important;
  border-bottom-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-y-teal-700\/50 {
  border-top-color: rgb(15 118 110 / 0.5) !important;
  border-bottom-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-y-teal-700\/55 {
  border-top-color: rgb(15 118 110 / 0.55) !important;
  border-bottom-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-y-teal-700\/60 {
  border-top-color: rgb(15 118 110 / 0.6) !important;
  border-bottom-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-y-teal-700\/65 {
  border-top-color: rgb(15 118 110 / 0.65) !important;
  border-bottom-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-y-teal-700\/70 {
  border-top-color: rgb(15 118 110 / 0.7) !important;
  border-bottom-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-y-teal-700\/75 {
  border-top-color: rgb(15 118 110 / 0.75) !important;
  border-bottom-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-y-teal-700\/80 {
  border-top-color: rgb(15 118 110 / 0.8) !important;
  border-bottom-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-y-teal-700\/85 {
  border-top-color: rgb(15 118 110 / 0.85) !important;
  border-bottom-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-y-teal-700\/90 {
  border-top-color: rgb(15 118 110 / 0.9) !important;
  border-bottom-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-y-teal-700\/95 {
  border-top-color: rgb(15 118 110 / 0.95) !important;
  border-bottom-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-y-teal-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-800\/0 {
  border-top-color: rgb(17 94 89 / 0) !important;
  border-bottom-color: rgb(17 94 89 / 0) !important;
}

.tw-border-y-teal-800\/10 {
  border-top-color: rgb(17 94 89 / 0.1) !important;
  border-bottom-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-y-teal-800\/100 {
  border-top-color: rgb(17 94 89 / 1) !important;
  border-bottom-color: rgb(17 94 89 / 1) !important;
}

.tw-border-y-teal-800\/15 {
  border-top-color: rgb(17 94 89 / 0.15) !important;
  border-bottom-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-y-teal-800\/20 {
  border-top-color: rgb(17 94 89 / 0.2) !important;
  border-bottom-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-y-teal-800\/25 {
  border-top-color: rgb(17 94 89 / 0.25) !important;
  border-bottom-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-y-teal-800\/30 {
  border-top-color: rgb(17 94 89 / 0.3) !important;
  border-bottom-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-y-teal-800\/35 {
  border-top-color: rgb(17 94 89 / 0.35) !important;
  border-bottom-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-y-teal-800\/40 {
  border-top-color: rgb(17 94 89 / 0.4) !important;
  border-bottom-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-y-teal-800\/45 {
  border-top-color: rgb(17 94 89 / 0.45) !important;
  border-bottom-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-y-teal-800\/5 {
  border-top-color: rgb(17 94 89 / 0.05) !important;
  border-bottom-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-y-teal-800\/50 {
  border-top-color: rgb(17 94 89 / 0.5) !important;
  border-bottom-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-y-teal-800\/55 {
  border-top-color: rgb(17 94 89 / 0.55) !important;
  border-bottom-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-y-teal-800\/60 {
  border-top-color: rgb(17 94 89 / 0.6) !important;
  border-bottom-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-y-teal-800\/65 {
  border-top-color: rgb(17 94 89 / 0.65) !important;
  border-bottom-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-y-teal-800\/70 {
  border-top-color: rgb(17 94 89 / 0.7) !important;
  border-bottom-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-y-teal-800\/75 {
  border-top-color: rgb(17 94 89 / 0.75) !important;
  border-bottom-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-y-teal-800\/80 {
  border-top-color: rgb(17 94 89 / 0.8) !important;
  border-bottom-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-y-teal-800\/85 {
  border-top-color: rgb(17 94 89 / 0.85) !important;
  border-bottom-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-y-teal-800\/90 {
  border-top-color: rgb(17 94 89 / 0.9) !important;
  border-bottom-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-y-teal-800\/95 {
  border-top-color: rgb(17 94 89 / 0.95) !important;
  border-bottom-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-y-teal-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-900\/0 {
  border-top-color: rgb(19 78 74 / 0) !important;
  border-bottom-color: rgb(19 78 74 / 0) !important;
}

.tw-border-y-teal-900\/10 {
  border-top-color: rgb(19 78 74 / 0.1) !important;
  border-bottom-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-y-teal-900\/100 {
  border-top-color: rgb(19 78 74 / 1) !important;
  border-bottom-color: rgb(19 78 74 / 1) !important;
}

.tw-border-y-teal-900\/15 {
  border-top-color: rgb(19 78 74 / 0.15) !important;
  border-bottom-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-y-teal-900\/20 {
  border-top-color: rgb(19 78 74 / 0.2) !important;
  border-bottom-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-y-teal-900\/25 {
  border-top-color: rgb(19 78 74 / 0.25) !important;
  border-bottom-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-y-teal-900\/30 {
  border-top-color: rgb(19 78 74 / 0.3) !important;
  border-bottom-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-y-teal-900\/35 {
  border-top-color: rgb(19 78 74 / 0.35) !important;
  border-bottom-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-y-teal-900\/40 {
  border-top-color: rgb(19 78 74 / 0.4) !important;
  border-bottom-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-y-teal-900\/45 {
  border-top-color: rgb(19 78 74 / 0.45) !important;
  border-bottom-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-y-teal-900\/5 {
  border-top-color: rgb(19 78 74 / 0.05) !important;
  border-bottom-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-y-teal-900\/50 {
  border-top-color: rgb(19 78 74 / 0.5) !important;
  border-bottom-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-y-teal-900\/55 {
  border-top-color: rgb(19 78 74 / 0.55) !important;
  border-bottom-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-y-teal-900\/60 {
  border-top-color: rgb(19 78 74 / 0.6) !important;
  border-bottom-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-y-teal-900\/65 {
  border-top-color: rgb(19 78 74 / 0.65) !important;
  border-bottom-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-y-teal-900\/70 {
  border-top-color: rgb(19 78 74 / 0.7) !important;
  border-bottom-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-y-teal-900\/75 {
  border-top-color: rgb(19 78 74 / 0.75) !important;
  border-bottom-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-y-teal-900\/80 {
  border-top-color: rgb(19 78 74 / 0.8) !important;
  border-bottom-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-y-teal-900\/85 {
  border-top-color: rgb(19 78 74 / 0.85) !important;
  border-bottom-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-y-teal-900\/90 {
  border-top-color: rgb(19 78 74 / 0.9) !important;
  border-bottom-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-y-teal-900\/95 {
  border-top-color: rgb(19 78 74 / 0.95) !important;
  border-bottom-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-y-teal-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-y-teal-950\/0 {
  border-top-color: rgb(4 47 46 / 0) !important;
  border-bottom-color: rgb(4 47 46 / 0) !important;
}

.tw-border-y-teal-950\/10 {
  border-top-color: rgb(4 47 46 / 0.1) !important;
  border-bottom-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-y-teal-950\/100 {
  border-top-color: rgb(4 47 46 / 1) !important;
  border-bottom-color: rgb(4 47 46 / 1) !important;
}

.tw-border-y-teal-950\/15 {
  border-top-color: rgb(4 47 46 / 0.15) !important;
  border-bottom-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-y-teal-950\/20 {
  border-top-color: rgb(4 47 46 / 0.2) !important;
  border-bottom-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-y-teal-950\/25 {
  border-top-color: rgb(4 47 46 / 0.25) !important;
  border-bottom-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-y-teal-950\/30 {
  border-top-color: rgb(4 47 46 / 0.3) !important;
  border-bottom-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-y-teal-950\/35 {
  border-top-color: rgb(4 47 46 / 0.35) !important;
  border-bottom-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-y-teal-950\/40 {
  border-top-color: rgb(4 47 46 / 0.4) !important;
  border-bottom-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-y-teal-950\/45 {
  border-top-color: rgb(4 47 46 / 0.45) !important;
  border-bottom-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-y-teal-950\/5 {
  border-top-color: rgb(4 47 46 / 0.05) !important;
  border-bottom-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-y-teal-950\/50 {
  border-top-color: rgb(4 47 46 / 0.5) !important;
  border-bottom-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-y-teal-950\/55 {
  border-top-color: rgb(4 47 46 / 0.55) !important;
  border-bottom-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-y-teal-950\/60 {
  border-top-color: rgb(4 47 46 / 0.6) !important;
  border-bottom-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-y-teal-950\/65 {
  border-top-color: rgb(4 47 46 / 0.65) !important;
  border-bottom-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-y-teal-950\/70 {
  border-top-color: rgb(4 47 46 / 0.7) !important;
  border-bottom-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-y-teal-950\/75 {
  border-top-color: rgb(4 47 46 / 0.75) !important;
  border-bottom-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-y-teal-950\/80 {
  border-top-color: rgb(4 47 46 / 0.8) !important;
  border-bottom-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-y-teal-950\/85 {
  border-top-color: rgb(4 47 46 / 0.85) !important;
  border-bottom-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-y-teal-950\/90 {
  border-top-color: rgb(4 47 46 / 0.9) !important;
  border-bottom-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-y-teal-950\/95 {
  border-top-color: rgb(4 47 46 / 0.95) !important;
  border-bottom-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-y-transparent {
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
}

.tw-border-y-transparent\/0 {
  border-top-color: rgb(0 0 0 / 0) !important;
  border-bottom-color: rgb(0 0 0 / 0) !important;
}

.tw-border-y-transparent\/10 {
  border-top-color: rgb(0 0 0 / 0.1) !important;
  border-bottom-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-y-transparent\/100 {
  border-top-color: rgb(0 0 0 / 1) !important;
  border-bottom-color: rgb(0 0 0 / 1) !important;
}

.tw-border-y-transparent\/15 {
  border-top-color: rgb(0 0 0 / 0.15) !important;
  border-bottom-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-y-transparent\/20 {
  border-top-color: rgb(0 0 0 / 0.2) !important;
  border-bottom-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-y-transparent\/25 {
  border-top-color: rgb(0 0 0 / 0.25) !important;
  border-bottom-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-y-transparent\/30 {
  border-top-color: rgb(0 0 0 / 0.3) !important;
  border-bottom-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-y-transparent\/35 {
  border-top-color: rgb(0 0 0 / 0.35) !important;
  border-bottom-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-y-transparent\/40 {
  border-top-color: rgb(0 0 0 / 0.4) !important;
  border-bottom-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-y-transparent\/45 {
  border-top-color: rgb(0 0 0 / 0.45) !important;
  border-bottom-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-y-transparent\/5 {
  border-top-color: rgb(0 0 0 / 0.05) !important;
  border-bottom-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-y-transparent\/50 {
  border-top-color: rgb(0 0 0 / 0.5) !important;
  border-bottom-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-y-transparent\/55 {
  border-top-color: rgb(0 0 0 / 0.55) !important;
  border-bottom-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-y-transparent\/60 {
  border-top-color: rgb(0 0 0 / 0.6) !important;
  border-bottom-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-y-transparent\/65 {
  border-top-color: rgb(0 0 0 / 0.65) !important;
  border-bottom-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-y-transparent\/70 {
  border-top-color: rgb(0 0 0 / 0.7) !important;
  border-bottom-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-y-transparent\/75 {
  border-top-color: rgb(0 0 0 / 0.75) !important;
  border-bottom-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-y-transparent\/80 {
  border-top-color: rgb(0 0 0 / 0.8) !important;
  border-bottom-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-y-transparent\/85 {
  border-top-color: rgb(0 0 0 / 0.85) !important;
  border-bottom-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-y-transparent\/90 {
  border-top-color: rgb(0 0 0 / 0.9) !important;
  border-bottom-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-y-transparent\/95 {
  border-top-color: rgb(0 0 0 / 0.95) !important;
  border-bottom-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-y-violet-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-100\/0 {
  border-top-color: rgb(237 233 254 / 0) !important;
  border-bottom-color: rgb(237 233 254 / 0) !important;
}

.tw-border-y-violet-100\/10 {
  border-top-color: rgb(237 233 254 / 0.1) !important;
  border-bottom-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-y-violet-100\/100 {
  border-top-color: rgb(237 233 254 / 1) !important;
  border-bottom-color: rgb(237 233 254 / 1) !important;
}

.tw-border-y-violet-100\/15 {
  border-top-color: rgb(237 233 254 / 0.15) !important;
  border-bottom-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-y-violet-100\/20 {
  border-top-color: rgb(237 233 254 / 0.2) !important;
  border-bottom-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-y-violet-100\/25 {
  border-top-color: rgb(237 233 254 / 0.25) !important;
  border-bottom-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-y-violet-100\/30 {
  border-top-color: rgb(237 233 254 / 0.3) !important;
  border-bottom-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-y-violet-100\/35 {
  border-top-color: rgb(237 233 254 / 0.35) !important;
  border-bottom-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-y-violet-100\/40 {
  border-top-color: rgb(237 233 254 / 0.4) !important;
  border-bottom-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-y-violet-100\/45 {
  border-top-color: rgb(237 233 254 / 0.45) !important;
  border-bottom-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-y-violet-100\/5 {
  border-top-color: rgb(237 233 254 / 0.05) !important;
  border-bottom-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-y-violet-100\/50 {
  border-top-color: rgb(237 233 254 / 0.5) !important;
  border-bottom-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-y-violet-100\/55 {
  border-top-color: rgb(237 233 254 / 0.55) !important;
  border-bottom-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-y-violet-100\/60 {
  border-top-color: rgb(237 233 254 / 0.6) !important;
  border-bottom-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-y-violet-100\/65 {
  border-top-color: rgb(237 233 254 / 0.65) !important;
  border-bottom-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-y-violet-100\/70 {
  border-top-color: rgb(237 233 254 / 0.7) !important;
  border-bottom-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-y-violet-100\/75 {
  border-top-color: rgb(237 233 254 / 0.75) !important;
  border-bottom-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-y-violet-100\/80 {
  border-top-color: rgb(237 233 254 / 0.8) !important;
  border-bottom-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-y-violet-100\/85 {
  border-top-color: rgb(237 233 254 / 0.85) !important;
  border-bottom-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-y-violet-100\/90 {
  border-top-color: rgb(237 233 254 / 0.9) !important;
  border-bottom-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-y-violet-100\/95 {
  border-top-color: rgb(237 233 254 / 0.95) !important;
  border-bottom-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-y-violet-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-200\/0 {
  border-top-color: rgb(221 214 254 / 0) !important;
  border-bottom-color: rgb(221 214 254 / 0) !important;
}

.tw-border-y-violet-200\/10 {
  border-top-color: rgb(221 214 254 / 0.1) !important;
  border-bottom-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-y-violet-200\/100 {
  border-top-color: rgb(221 214 254 / 1) !important;
  border-bottom-color: rgb(221 214 254 / 1) !important;
}

.tw-border-y-violet-200\/15 {
  border-top-color: rgb(221 214 254 / 0.15) !important;
  border-bottom-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-y-violet-200\/20 {
  border-top-color: rgb(221 214 254 / 0.2) !important;
  border-bottom-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-y-violet-200\/25 {
  border-top-color: rgb(221 214 254 / 0.25) !important;
  border-bottom-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-y-violet-200\/30 {
  border-top-color: rgb(221 214 254 / 0.3) !important;
  border-bottom-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-y-violet-200\/35 {
  border-top-color: rgb(221 214 254 / 0.35) !important;
  border-bottom-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-y-violet-200\/40 {
  border-top-color: rgb(221 214 254 / 0.4) !important;
  border-bottom-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-y-violet-200\/45 {
  border-top-color: rgb(221 214 254 / 0.45) !important;
  border-bottom-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-y-violet-200\/5 {
  border-top-color: rgb(221 214 254 / 0.05) !important;
  border-bottom-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-y-violet-200\/50 {
  border-top-color: rgb(221 214 254 / 0.5) !important;
  border-bottom-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-y-violet-200\/55 {
  border-top-color: rgb(221 214 254 / 0.55) !important;
  border-bottom-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-y-violet-200\/60 {
  border-top-color: rgb(221 214 254 / 0.6) !important;
  border-bottom-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-y-violet-200\/65 {
  border-top-color: rgb(221 214 254 / 0.65) !important;
  border-bottom-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-y-violet-200\/70 {
  border-top-color: rgb(221 214 254 / 0.7) !important;
  border-bottom-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-y-violet-200\/75 {
  border-top-color: rgb(221 214 254 / 0.75) !important;
  border-bottom-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-y-violet-200\/80 {
  border-top-color: rgb(221 214 254 / 0.8) !important;
  border-bottom-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-y-violet-200\/85 {
  border-top-color: rgb(221 214 254 / 0.85) !important;
  border-bottom-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-y-violet-200\/90 {
  border-top-color: rgb(221 214 254 / 0.9) !important;
  border-bottom-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-y-violet-200\/95 {
  border-top-color: rgb(221 214 254 / 0.95) !important;
  border-bottom-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-y-violet-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-300\/0 {
  border-top-color: rgb(196 181 253 / 0) !important;
  border-bottom-color: rgb(196 181 253 / 0) !important;
}

.tw-border-y-violet-300\/10 {
  border-top-color: rgb(196 181 253 / 0.1) !important;
  border-bottom-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-y-violet-300\/100 {
  border-top-color: rgb(196 181 253 / 1) !important;
  border-bottom-color: rgb(196 181 253 / 1) !important;
}

.tw-border-y-violet-300\/15 {
  border-top-color: rgb(196 181 253 / 0.15) !important;
  border-bottom-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-y-violet-300\/20 {
  border-top-color: rgb(196 181 253 / 0.2) !important;
  border-bottom-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-y-violet-300\/25 {
  border-top-color: rgb(196 181 253 / 0.25) !important;
  border-bottom-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-y-violet-300\/30 {
  border-top-color: rgb(196 181 253 / 0.3) !important;
  border-bottom-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-y-violet-300\/35 {
  border-top-color: rgb(196 181 253 / 0.35) !important;
  border-bottom-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-y-violet-300\/40 {
  border-top-color: rgb(196 181 253 / 0.4) !important;
  border-bottom-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-y-violet-300\/45 {
  border-top-color: rgb(196 181 253 / 0.45) !important;
  border-bottom-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-y-violet-300\/5 {
  border-top-color: rgb(196 181 253 / 0.05) !important;
  border-bottom-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-y-violet-300\/50 {
  border-top-color: rgb(196 181 253 / 0.5) !important;
  border-bottom-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-y-violet-300\/55 {
  border-top-color: rgb(196 181 253 / 0.55) !important;
  border-bottom-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-y-violet-300\/60 {
  border-top-color: rgb(196 181 253 / 0.6) !important;
  border-bottom-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-y-violet-300\/65 {
  border-top-color: rgb(196 181 253 / 0.65) !important;
  border-bottom-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-y-violet-300\/70 {
  border-top-color: rgb(196 181 253 / 0.7) !important;
  border-bottom-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-y-violet-300\/75 {
  border-top-color: rgb(196 181 253 / 0.75) !important;
  border-bottom-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-y-violet-300\/80 {
  border-top-color: rgb(196 181 253 / 0.8) !important;
  border-bottom-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-y-violet-300\/85 {
  border-top-color: rgb(196 181 253 / 0.85) !important;
  border-bottom-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-y-violet-300\/90 {
  border-top-color: rgb(196 181 253 / 0.9) !important;
  border-bottom-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-y-violet-300\/95 {
  border-top-color: rgb(196 181 253 / 0.95) !important;
  border-bottom-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-y-violet-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-400\/0 {
  border-top-color: rgb(167 139 250 / 0) !important;
  border-bottom-color: rgb(167 139 250 / 0) !important;
}

.tw-border-y-violet-400\/10 {
  border-top-color: rgb(167 139 250 / 0.1) !important;
  border-bottom-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-y-violet-400\/100 {
  border-top-color: rgb(167 139 250 / 1) !important;
  border-bottom-color: rgb(167 139 250 / 1) !important;
}

.tw-border-y-violet-400\/15 {
  border-top-color: rgb(167 139 250 / 0.15) !important;
  border-bottom-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-y-violet-400\/20 {
  border-top-color: rgb(167 139 250 / 0.2) !important;
  border-bottom-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-y-violet-400\/25 {
  border-top-color: rgb(167 139 250 / 0.25) !important;
  border-bottom-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-y-violet-400\/30 {
  border-top-color: rgb(167 139 250 / 0.3) !important;
  border-bottom-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-y-violet-400\/35 {
  border-top-color: rgb(167 139 250 / 0.35) !important;
  border-bottom-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-y-violet-400\/40 {
  border-top-color: rgb(167 139 250 / 0.4) !important;
  border-bottom-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-y-violet-400\/45 {
  border-top-color: rgb(167 139 250 / 0.45) !important;
  border-bottom-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-y-violet-400\/5 {
  border-top-color: rgb(167 139 250 / 0.05) !important;
  border-bottom-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-y-violet-400\/50 {
  border-top-color: rgb(167 139 250 / 0.5) !important;
  border-bottom-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-y-violet-400\/55 {
  border-top-color: rgb(167 139 250 / 0.55) !important;
  border-bottom-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-y-violet-400\/60 {
  border-top-color: rgb(167 139 250 / 0.6) !important;
  border-bottom-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-y-violet-400\/65 {
  border-top-color: rgb(167 139 250 / 0.65) !important;
  border-bottom-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-y-violet-400\/70 {
  border-top-color: rgb(167 139 250 / 0.7) !important;
  border-bottom-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-y-violet-400\/75 {
  border-top-color: rgb(167 139 250 / 0.75) !important;
  border-bottom-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-y-violet-400\/80 {
  border-top-color: rgb(167 139 250 / 0.8) !important;
  border-bottom-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-y-violet-400\/85 {
  border-top-color: rgb(167 139 250 / 0.85) !important;
  border-bottom-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-y-violet-400\/90 {
  border-top-color: rgb(167 139 250 / 0.9) !important;
  border-bottom-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-y-violet-400\/95 {
  border-top-color: rgb(167 139 250 / 0.95) !important;
  border-bottom-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-y-violet-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-50\/0 {
  border-top-color: rgb(245 243 255 / 0) !important;
  border-bottom-color: rgb(245 243 255 / 0) !important;
}

.tw-border-y-violet-50\/10 {
  border-top-color: rgb(245 243 255 / 0.1) !important;
  border-bottom-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-y-violet-50\/100 {
  border-top-color: rgb(245 243 255 / 1) !important;
  border-bottom-color: rgb(245 243 255 / 1) !important;
}

.tw-border-y-violet-50\/15 {
  border-top-color: rgb(245 243 255 / 0.15) !important;
  border-bottom-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-y-violet-50\/20 {
  border-top-color: rgb(245 243 255 / 0.2) !important;
  border-bottom-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-y-violet-50\/25 {
  border-top-color: rgb(245 243 255 / 0.25) !important;
  border-bottom-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-y-violet-50\/30 {
  border-top-color: rgb(245 243 255 / 0.3) !important;
  border-bottom-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-y-violet-50\/35 {
  border-top-color: rgb(245 243 255 / 0.35) !important;
  border-bottom-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-y-violet-50\/40 {
  border-top-color: rgb(245 243 255 / 0.4) !important;
  border-bottom-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-y-violet-50\/45 {
  border-top-color: rgb(245 243 255 / 0.45) !important;
  border-bottom-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-y-violet-50\/5 {
  border-top-color: rgb(245 243 255 / 0.05) !important;
  border-bottom-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-y-violet-50\/50 {
  border-top-color: rgb(245 243 255 / 0.5) !important;
  border-bottom-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-y-violet-50\/55 {
  border-top-color: rgb(245 243 255 / 0.55) !important;
  border-bottom-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-y-violet-50\/60 {
  border-top-color: rgb(245 243 255 / 0.6) !important;
  border-bottom-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-y-violet-50\/65 {
  border-top-color: rgb(245 243 255 / 0.65) !important;
  border-bottom-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-y-violet-50\/70 {
  border-top-color: rgb(245 243 255 / 0.7) !important;
  border-bottom-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-y-violet-50\/75 {
  border-top-color: rgb(245 243 255 / 0.75) !important;
  border-bottom-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-y-violet-50\/80 {
  border-top-color: rgb(245 243 255 / 0.8) !important;
  border-bottom-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-y-violet-50\/85 {
  border-top-color: rgb(245 243 255 / 0.85) !important;
  border-bottom-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-y-violet-50\/90 {
  border-top-color: rgb(245 243 255 / 0.9) !important;
  border-bottom-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-y-violet-50\/95 {
  border-top-color: rgb(245 243 255 / 0.95) !important;
  border-bottom-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-y-violet-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-500\/0 {
  border-top-color: rgb(139 92 246 / 0) !important;
  border-bottom-color: rgb(139 92 246 / 0) !important;
}

.tw-border-y-violet-500\/10 {
  border-top-color: rgb(139 92 246 / 0.1) !important;
  border-bottom-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-y-violet-500\/100 {
  border-top-color: rgb(139 92 246 / 1) !important;
  border-bottom-color: rgb(139 92 246 / 1) !important;
}

.tw-border-y-violet-500\/15 {
  border-top-color: rgb(139 92 246 / 0.15) !important;
  border-bottom-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-y-violet-500\/20 {
  border-top-color: rgb(139 92 246 / 0.2) !important;
  border-bottom-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-y-violet-500\/25 {
  border-top-color: rgb(139 92 246 / 0.25) !important;
  border-bottom-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-y-violet-500\/30 {
  border-top-color: rgb(139 92 246 / 0.3) !important;
  border-bottom-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-y-violet-500\/35 {
  border-top-color: rgb(139 92 246 / 0.35) !important;
  border-bottom-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-y-violet-500\/40 {
  border-top-color: rgb(139 92 246 / 0.4) !important;
  border-bottom-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-y-violet-500\/45 {
  border-top-color: rgb(139 92 246 / 0.45) !important;
  border-bottom-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-y-violet-500\/5 {
  border-top-color: rgb(139 92 246 / 0.05) !important;
  border-bottom-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-y-violet-500\/50 {
  border-top-color: rgb(139 92 246 / 0.5) !important;
  border-bottom-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-y-violet-500\/55 {
  border-top-color: rgb(139 92 246 / 0.55) !important;
  border-bottom-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-y-violet-500\/60 {
  border-top-color: rgb(139 92 246 / 0.6) !important;
  border-bottom-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-y-violet-500\/65 {
  border-top-color: rgb(139 92 246 / 0.65) !important;
  border-bottom-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-y-violet-500\/70 {
  border-top-color: rgb(139 92 246 / 0.7) !important;
  border-bottom-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-y-violet-500\/75 {
  border-top-color: rgb(139 92 246 / 0.75) !important;
  border-bottom-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-y-violet-500\/80 {
  border-top-color: rgb(139 92 246 / 0.8) !important;
  border-bottom-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-y-violet-500\/85 {
  border-top-color: rgb(139 92 246 / 0.85) !important;
  border-bottom-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-y-violet-500\/90 {
  border-top-color: rgb(139 92 246 / 0.9) !important;
  border-bottom-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-y-violet-500\/95 {
  border-top-color: rgb(139 92 246 / 0.95) !important;
  border-bottom-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-y-violet-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-600\/0 {
  border-top-color: rgb(124 58 237 / 0) !important;
  border-bottom-color: rgb(124 58 237 / 0) !important;
}

.tw-border-y-violet-600\/10 {
  border-top-color: rgb(124 58 237 / 0.1) !important;
  border-bottom-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-y-violet-600\/100 {
  border-top-color: rgb(124 58 237 / 1) !important;
  border-bottom-color: rgb(124 58 237 / 1) !important;
}

.tw-border-y-violet-600\/15 {
  border-top-color: rgb(124 58 237 / 0.15) !important;
  border-bottom-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-y-violet-600\/20 {
  border-top-color: rgb(124 58 237 / 0.2) !important;
  border-bottom-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-y-violet-600\/25 {
  border-top-color: rgb(124 58 237 / 0.25) !important;
  border-bottom-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-y-violet-600\/30 {
  border-top-color: rgb(124 58 237 / 0.3) !important;
  border-bottom-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-y-violet-600\/35 {
  border-top-color: rgb(124 58 237 / 0.35) !important;
  border-bottom-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-y-violet-600\/40 {
  border-top-color: rgb(124 58 237 / 0.4) !important;
  border-bottom-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-y-violet-600\/45 {
  border-top-color: rgb(124 58 237 / 0.45) !important;
  border-bottom-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-y-violet-600\/5 {
  border-top-color: rgb(124 58 237 / 0.05) !important;
  border-bottom-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-y-violet-600\/50 {
  border-top-color: rgb(124 58 237 / 0.5) !important;
  border-bottom-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-y-violet-600\/55 {
  border-top-color: rgb(124 58 237 / 0.55) !important;
  border-bottom-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-y-violet-600\/60 {
  border-top-color: rgb(124 58 237 / 0.6) !important;
  border-bottom-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-y-violet-600\/65 {
  border-top-color: rgb(124 58 237 / 0.65) !important;
  border-bottom-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-y-violet-600\/70 {
  border-top-color: rgb(124 58 237 / 0.7) !important;
  border-bottom-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-y-violet-600\/75 {
  border-top-color: rgb(124 58 237 / 0.75) !important;
  border-bottom-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-y-violet-600\/80 {
  border-top-color: rgb(124 58 237 / 0.8) !important;
  border-bottom-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-y-violet-600\/85 {
  border-top-color: rgb(124 58 237 / 0.85) !important;
  border-bottom-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-y-violet-600\/90 {
  border-top-color: rgb(124 58 237 / 0.9) !important;
  border-bottom-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-y-violet-600\/95 {
  border-top-color: rgb(124 58 237 / 0.95) !important;
  border-bottom-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-y-violet-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-700\/0 {
  border-top-color: rgb(109 40 217 / 0) !important;
  border-bottom-color: rgb(109 40 217 / 0) !important;
}

.tw-border-y-violet-700\/10 {
  border-top-color: rgb(109 40 217 / 0.1) !important;
  border-bottom-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-y-violet-700\/100 {
  border-top-color: rgb(109 40 217 / 1) !important;
  border-bottom-color: rgb(109 40 217 / 1) !important;
}

.tw-border-y-violet-700\/15 {
  border-top-color: rgb(109 40 217 / 0.15) !important;
  border-bottom-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-y-violet-700\/20 {
  border-top-color: rgb(109 40 217 / 0.2) !important;
  border-bottom-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-y-violet-700\/25 {
  border-top-color: rgb(109 40 217 / 0.25) !important;
  border-bottom-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-y-violet-700\/30 {
  border-top-color: rgb(109 40 217 / 0.3) !important;
  border-bottom-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-y-violet-700\/35 {
  border-top-color: rgb(109 40 217 / 0.35) !important;
  border-bottom-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-y-violet-700\/40 {
  border-top-color: rgb(109 40 217 / 0.4) !important;
  border-bottom-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-y-violet-700\/45 {
  border-top-color: rgb(109 40 217 / 0.45) !important;
  border-bottom-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-y-violet-700\/5 {
  border-top-color: rgb(109 40 217 / 0.05) !important;
  border-bottom-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-y-violet-700\/50 {
  border-top-color: rgb(109 40 217 / 0.5) !important;
  border-bottom-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-y-violet-700\/55 {
  border-top-color: rgb(109 40 217 / 0.55) !important;
  border-bottom-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-y-violet-700\/60 {
  border-top-color: rgb(109 40 217 / 0.6) !important;
  border-bottom-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-y-violet-700\/65 {
  border-top-color: rgb(109 40 217 / 0.65) !important;
  border-bottom-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-y-violet-700\/70 {
  border-top-color: rgb(109 40 217 / 0.7) !important;
  border-bottom-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-y-violet-700\/75 {
  border-top-color: rgb(109 40 217 / 0.75) !important;
  border-bottom-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-y-violet-700\/80 {
  border-top-color: rgb(109 40 217 / 0.8) !important;
  border-bottom-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-y-violet-700\/85 {
  border-top-color: rgb(109 40 217 / 0.85) !important;
  border-bottom-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-y-violet-700\/90 {
  border-top-color: rgb(109 40 217 / 0.9) !important;
  border-bottom-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-y-violet-700\/95 {
  border-top-color: rgb(109 40 217 / 0.95) !important;
  border-bottom-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-y-violet-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-800\/0 {
  border-top-color: rgb(91 33 182 / 0) !important;
  border-bottom-color: rgb(91 33 182 / 0) !important;
}

.tw-border-y-violet-800\/10 {
  border-top-color: rgb(91 33 182 / 0.1) !important;
  border-bottom-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-y-violet-800\/100 {
  border-top-color: rgb(91 33 182 / 1) !important;
  border-bottom-color: rgb(91 33 182 / 1) !important;
}

.tw-border-y-violet-800\/15 {
  border-top-color: rgb(91 33 182 / 0.15) !important;
  border-bottom-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-y-violet-800\/20 {
  border-top-color: rgb(91 33 182 / 0.2) !important;
  border-bottom-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-y-violet-800\/25 {
  border-top-color: rgb(91 33 182 / 0.25) !important;
  border-bottom-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-y-violet-800\/30 {
  border-top-color: rgb(91 33 182 / 0.3) !important;
  border-bottom-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-y-violet-800\/35 {
  border-top-color: rgb(91 33 182 / 0.35) !important;
  border-bottom-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-y-violet-800\/40 {
  border-top-color: rgb(91 33 182 / 0.4) !important;
  border-bottom-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-y-violet-800\/45 {
  border-top-color: rgb(91 33 182 / 0.45) !important;
  border-bottom-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-y-violet-800\/5 {
  border-top-color: rgb(91 33 182 / 0.05) !important;
  border-bottom-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-y-violet-800\/50 {
  border-top-color: rgb(91 33 182 / 0.5) !important;
  border-bottom-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-y-violet-800\/55 {
  border-top-color: rgb(91 33 182 / 0.55) !important;
  border-bottom-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-y-violet-800\/60 {
  border-top-color: rgb(91 33 182 / 0.6) !important;
  border-bottom-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-y-violet-800\/65 {
  border-top-color: rgb(91 33 182 / 0.65) !important;
  border-bottom-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-y-violet-800\/70 {
  border-top-color: rgb(91 33 182 / 0.7) !important;
  border-bottom-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-y-violet-800\/75 {
  border-top-color: rgb(91 33 182 / 0.75) !important;
  border-bottom-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-y-violet-800\/80 {
  border-top-color: rgb(91 33 182 / 0.8) !important;
  border-bottom-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-y-violet-800\/85 {
  border-top-color: rgb(91 33 182 / 0.85) !important;
  border-bottom-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-y-violet-800\/90 {
  border-top-color: rgb(91 33 182 / 0.9) !important;
  border-bottom-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-y-violet-800\/95 {
  border-top-color: rgb(91 33 182 / 0.95) !important;
  border-bottom-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-y-violet-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-900\/0 {
  border-top-color: rgb(76 29 149 / 0) !important;
  border-bottom-color: rgb(76 29 149 / 0) !important;
}

.tw-border-y-violet-900\/10 {
  border-top-color: rgb(76 29 149 / 0.1) !important;
  border-bottom-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-y-violet-900\/100 {
  border-top-color: rgb(76 29 149 / 1) !important;
  border-bottom-color: rgb(76 29 149 / 1) !important;
}

.tw-border-y-violet-900\/15 {
  border-top-color: rgb(76 29 149 / 0.15) !important;
  border-bottom-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-y-violet-900\/20 {
  border-top-color: rgb(76 29 149 / 0.2) !important;
  border-bottom-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-y-violet-900\/25 {
  border-top-color: rgb(76 29 149 / 0.25) !important;
  border-bottom-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-y-violet-900\/30 {
  border-top-color: rgb(76 29 149 / 0.3) !important;
  border-bottom-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-y-violet-900\/35 {
  border-top-color: rgb(76 29 149 / 0.35) !important;
  border-bottom-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-y-violet-900\/40 {
  border-top-color: rgb(76 29 149 / 0.4) !important;
  border-bottom-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-y-violet-900\/45 {
  border-top-color: rgb(76 29 149 / 0.45) !important;
  border-bottom-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-y-violet-900\/5 {
  border-top-color: rgb(76 29 149 / 0.05) !important;
  border-bottom-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-y-violet-900\/50 {
  border-top-color: rgb(76 29 149 / 0.5) !important;
  border-bottom-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-y-violet-900\/55 {
  border-top-color: rgb(76 29 149 / 0.55) !important;
  border-bottom-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-y-violet-900\/60 {
  border-top-color: rgb(76 29 149 / 0.6) !important;
  border-bottom-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-y-violet-900\/65 {
  border-top-color: rgb(76 29 149 / 0.65) !important;
  border-bottom-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-y-violet-900\/70 {
  border-top-color: rgb(76 29 149 / 0.7) !important;
  border-bottom-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-y-violet-900\/75 {
  border-top-color: rgb(76 29 149 / 0.75) !important;
  border-bottom-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-y-violet-900\/80 {
  border-top-color: rgb(76 29 149 / 0.8) !important;
  border-bottom-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-y-violet-900\/85 {
  border-top-color: rgb(76 29 149 / 0.85) !important;
  border-bottom-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-y-violet-900\/90 {
  border-top-color: rgb(76 29 149 / 0.9) !important;
  border-bottom-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-y-violet-900\/95 {
  border-top-color: rgb(76 29 149 / 0.95) !important;
  border-bottom-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-y-violet-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-y-violet-950\/0 {
  border-top-color: rgb(46 16 101 / 0) !important;
  border-bottom-color: rgb(46 16 101 / 0) !important;
}

.tw-border-y-violet-950\/10 {
  border-top-color: rgb(46 16 101 / 0.1) !important;
  border-bottom-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-y-violet-950\/100 {
  border-top-color: rgb(46 16 101 / 1) !important;
  border-bottom-color: rgb(46 16 101 / 1) !important;
}

.tw-border-y-violet-950\/15 {
  border-top-color: rgb(46 16 101 / 0.15) !important;
  border-bottom-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-y-violet-950\/20 {
  border-top-color: rgb(46 16 101 / 0.2) !important;
  border-bottom-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-y-violet-950\/25 {
  border-top-color: rgb(46 16 101 / 0.25) !important;
  border-bottom-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-y-violet-950\/30 {
  border-top-color: rgb(46 16 101 / 0.3) !important;
  border-bottom-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-y-violet-950\/35 {
  border-top-color: rgb(46 16 101 / 0.35) !important;
  border-bottom-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-y-violet-950\/40 {
  border-top-color: rgb(46 16 101 / 0.4) !important;
  border-bottom-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-y-violet-950\/45 {
  border-top-color: rgb(46 16 101 / 0.45) !important;
  border-bottom-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-y-violet-950\/5 {
  border-top-color: rgb(46 16 101 / 0.05) !important;
  border-bottom-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-y-violet-950\/50 {
  border-top-color: rgb(46 16 101 / 0.5) !important;
  border-bottom-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-y-violet-950\/55 {
  border-top-color: rgb(46 16 101 / 0.55) !important;
  border-bottom-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-y-violet-950\/60 {
  border-top-color: rgb(46 16 101 / 0.6) !important;
  border-bottom-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-y-violet-950\/65 {
  border-top-color: rgb(46 16 101 / 0.65) !important;
  border-bottom-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-y-violet-950\/70 {
  border-top-color: rgb(46 16 101 / 0.7) !important;
  border-bottom-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-y-violet-950\/75 {
  border-top-color: rgb(46 16 101 / 0.75) !important;
  border-bottom-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-y-violet-950\/80 {
  border-top-color: rgb(46 16 101 / 0.8) !important;
  border-bottom-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-y-violet-950\/85 {
  border-top-color: rgb(46 16 101 / 0.85) !important;
  border-bottom-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-y-violet-950\/90 {
  border-top-color: rgb(46 16 101 / 0.9) !important;
  border-bottom-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-y-violet-950\/95 {
  border-top-color: rgb(46 16 101 / 0.95) !important;
  border-bottom-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-y-white {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-y-white\/0 {
  border-top-color: rgb(255 255 255 / 0) !important;
  border-bottom-color: rgb(255 255 255 / 0) !important;
}

.tw-border-y-white\/10 {
  border-top-color: rgb(255 255 255 / 0.1) !important;
  border-bottom-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-y-white\/100 {
  border-top-color: rgb(255 255 255 / 1) !important;
  border-bottom-color: rgb(255 255 255 / 1) !important;
}

.tw-border-y-white\/15 {
  border-top-color: rgb(255 255 255 / 0.15) !important;
  border-bottom-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-y-white\/20 {
  border-top-color: rgb(255 255 255 / 0.2) !important;
  border-bottom-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-y-white\/25 {
  border-top-color: rgb(255 255 255 / 0.25) !important;
  border-bottom-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-y-white\/30 {
  border-top-color: rgb(255 255 255 / 0.3) !important;
  border-bottom-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-y-white\/35 {
  border-top-color: rgb(255 255 255 / 0.35) !important;
  border-bottom-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-y-white\/40 {
  border-top-color: rgb(255 255 255 / 0.4) !important;
  border-bottom-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-y-white\/45 {
  border-top-color: rgb(255 255 255 / 0.45) !important;
  border-bottom-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-y-white\/5 {
  border-top-color: rgb(255 255 255 / 0.05) !important;
  border-bottom-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-y-white\/50 {
  border-top-color: rgb(255 255 255 / 0.5) !important;
  border-bottom-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-y-white\/55 {
  border-top-color: rgb(255 255 255 / 0.55) !important;
  border-bottom-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-y-white\/60 {
  border-top-color: rgb(255 255 255 / 0.6) !important;
  border-bottom-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-y-white\/65 {
  border-top-color: rgb(255 255 255 / 0.65) !important;
  border-bottom-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-y-white\/70 {
  border-top-color: rgb(255 255 255 / 0.7) !important;
  border-bottom-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-y-white\/75 {
  border-top-color: rgb(255 255 255 / 0.75) !important;
  border-bottom-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-y-white\/80 {
  border-top-color: rgb(255 255 255 / 0.8) !important;
  border-bottom-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-y-white\/85 {
  border-top-color: rgb(255 255 255 / 0.85) !important;
  border-bottom-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-y-white\/90 {
  border-top-color: rgb(255 255 255 / 0.9) !important;
  border-bottom-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-y-white\/95 {
  border-top-color: rgb(255 255 255 / 0.95) !important;
  border-bottom-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-y-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-100\/0 {
  border-top-color: rgb(254 249 195 / 0) !important;
  border-bottom-color: rgb(254 249 195 / 0) !important;
}

.tw-border-y-yellow-100\/10 {
  border-top-color: rgb(254 249 195 / 0.1) !important;
  border-bottom-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-y-yellow-100\/100 {
  border-top-color: rgb(254 249 195 / 1) !important;
  border-bottom-color: rgb(254 249 195 / 1) !important;
}

.tw-border-y-yellow-100\/15 {
  border-top-color: rgb(254 249 195 / 0.15) !important;
  border-bottom-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-y-yellow-100\/20 {
  border-top-color: rgb(254 249 195 / 0.2) !important;
  border-bottom-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-y-yellow-100\/25 {
  border-top-color: rgb(254 249 195 / 0.25) !important;
  border-bottom-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-y-yellow-100\/30 {
  border-top-color: rgb(254 249 195 / 0.3) !important;
  border-bottom-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-y-yellow-100\/35 {
  border-top-color: rgb(254 249 195 / 0.35) !important;
  border-bottom-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-y-yellow-100\/40 {
  border-top-color: rgb(254 249 195 / 0.4) !important;
  border-bottom-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-y-yellow-100\/45 {
  border-top-color: rgb(254 249 195 / 0.45) !important;
  border-bottom-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-y-yellow-100\/5 {
  border-top-color: rgb(254 249 195 / 0.05) !important;
  border-bottom-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-y-yellow-100\/50 {
  border-top-color: rgb(254 249 195 / 0.5) !important;
  border-bottom-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-y-yellow-100\/55 {
  border-top-color: rgb(254 249 195 / 0.55) !important;
  border-bottom-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-y-yellow-100\/60 {
  border-top-color: rgb(254 249 195 / 0.6) !important;
  border-bottom-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-y-yellow-100\/65 {
  border-top-color: rgb(254 249 195 / 0.65) !important;
  border-bottom-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-y-yellow-100\/70 {
  border-top-color: rgb(254 249 195 / 0.7) !important;
  border-bottom-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-y-yellow-100\/75 {
  border-top-color: rgb(254 249 195 / 0.75) !important;
  border-bottom-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-y-yellow-100\/80 {
  border-top-color: rgb(254 249 195 / 0.8) !important;
  border-bottom-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-y-yellow-100\/85 {
  border-top-color: rgb(254 249 195 / 0.85) !important;
  border-bottom-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-y-yellow-100\/90 {
  border-top-color: rgb(254 249 195 / 0.9) !important;
  border-bottom-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-y-yellow-100\/95 {
  border-top-color: rgb(254 249 195 / 0.95) !important;
  border-bottom-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-y-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-200\/0 {
  border-top-color: rgb(254 240 138 / 0) !important;
  border-bottom-color: rgb(254 240 138 / 0) !important;
}

.tw-border-y-yellow-200\/10 {
  border-top-color: rgb(254 240 138 / 0.1) !important;
  border-bottom-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-y-yellow-200\/100 {
  border-top-color: rgb(254 240 138 / 1) !important;
  border-bottom-color: rgb(254 240 138 / 1) !important;
}

.tw-border-y-yellow-200\/15 {
  border-top-color: rgb(254 240 138 / 0.15) !important;
  border-bottom-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-y-yellow-200\/20 {
  border-top-color: rgb(254 240 138 / 0.2) !important;
  border-bottom-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-y-yellow-200\/25 {
  border-top-color: rgb(254 240 138 / 0.25) !important;
  border-bottom-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-y-yellow-200\/30 {
  border-top-color: rgb(254 240 138 / 0.3) !important;
  border-bottom-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-y-yellow-200\/35 {
  border-top-color: rgb(254 240 138 / 0.35) !important;
  border-bottom-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-y-yellow-200\/40 {
  border-top-color: rgb(254 240 138 / 0.4) !important;
  border-bottom-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-y-yellow-200\/45 {
  border-top-color: rgb(254 240 138 / 0.45) !important;
  border-bottom-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-y-yellow-200\/5 {
  border-top-color: rgb(254 240 138 / 0.05) !important;
  border-bottom-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-y-yellow-200\/50 {
  border-top-color: rgb(254 240 138 / 0.5) !important;
  border-bottom-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-y-yellow-200\/55 {
  border-top-color: rgb(254 240 138 / 0.55) !important;
  border-bottom-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-y-yellow-200\/60 {
  border-top-color: rgb(254 240 138 / 0.6) !important;
  border-bottom-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-y-yellow-200\/65 {
  border-top-color: rgb(254 240 138 / 0.65) !important;
  border-bottom-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-y-yellow-200\/70 {
  border-top-color: rgb(254 240 138 / 0.7) !important;
  border-bottom-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-y-yellow-200\/75 {
  border-top-color: rgb(254 240 138 / 0.75) !important;
  border-bottom-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-y-yellow-200\/80 {
  border-top-color: rgb(254 240 138 / 0.8) !important;
  border-bottom-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-y-yellow-200\/85 {
  border-top-color: rgb(254 240 138 / 0.85) !important;
  border-bottom-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-y-yellow-200\/90 {
  border-top-color: rgb(254 240 138 / 0.9) !important;
  border-bottom-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-y-yellow-200\/95 {
  border-top-color: rgb(254 240 138 / 0.95) !important;
  border-bottom-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-y-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-300\/0 {
  border-top-color: rgb(253 224 71 / 0) !important;
  border-bottom-color: rgb(253 224 71 / 0) !important;
}

.tw-border-y-yellow-300\/10 {
  border-top-color: rgb(253 224 71 / 0.1) !important;
  border-bottom-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-y-yellow-300\/100 {
  border-top-color: rgb(253 224 71 / 1) !important;
  border-bottom-color: rgb(253 224 71 / 1) !important;
}

.tw-border-y-yellow-300\/15 {
  border-top-color: rgb(253 224 71 / 0.15) !important;
  border-bottom-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-y-yellow-300\/20 {
  border-top-color: rgb(253 224 71 / 0.2) !important;
  border-bottom-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-y-yellow-300\/25 {
  border-top-color: rgb(253 224 71 / 0.25) !important;
  border-bottom-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-y-yellow-300\/30 {
  border-top-color: rgb(253 224 71 / 0.3) !important;
  border-bottom-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-y-yellow-300\/35 {
  border-top-color: rgb(253 224 71 / 0.35) !important;
  border-bottom-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-y-yellow-300\/40 {
  border-top-color: rgb(253 224 71 / 0.4) !important;
  border-bottom-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-y-yellow-300\/45 {
  border-top-color: rgb(253 224 71 / 0.45) !important;
  border-bottom-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-y-yellow-300\/5 {
  border-top-color: rgb(253 224 71 / 0.05) !important;
  border-bottom-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-y-yellow-300\/50 {
  border-top-color: rgb(253 224 71 / 0.5) !important;
  border-bottom-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-y-yellow-300\/55 {
  border-top-color: rgb(253 224 71 / 0.55) !important;
  border-bottom-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-y-yellow-300\/60 {
  border-top-color: rgb(253 224 71 / 0.6) !important;
  border-bottom-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-y-yellow-300\/65 {
  border-top-color: rgb(253 224 71 / 0.65) !important;
  border-bottom-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-y-yellow-300\/70 {
  border-top-color: rgb(253 224 71 / 0.7) !important;
  border-bottom-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-y-yellow-300\/75 {
  border-top-color: rgb(253 224 71 / 0.75) !important;
  border-bottom-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-y-yellow-300\/80 {
  border-top-color: rgb(253 224 71 / 0.8) !important;
  border-bottom-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-y-yellow-300\/85 {
  border-top-color: rgb(253 224 71 / 0.85) !important;
  border-bottom-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-y-yellow-300\/90 {
  border-top-color: rgb(253 224 71 / 0.9) !important;
  border-bottom-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-y-yellow-300\/95 {
  border-top-color: rgb(253 224 71 / 0.95) !important;
  border-bottom-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-y-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-400\/0 {
  border-top-color: rgb(250 204 21 / 0) !important;
  border-bottom-color: rgb(250 204 21 / 0) !important;
}

.tw-border-y-yellow-400\/10 {
  border-top-color: rgb(250 204 21 / 0.1) !important;
  border-bottom-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-y-yellow-400\/100 {
  border-top-color: rgb(250 204 21 / 1) !important;
  border-bottom-color: rgb(250 204 21 / 1) !important;
}

.tw-border-y-yellow-400\/15 {
  border-top-color: rgb(250 204 21 / 0.15) !important;
  border-bottom-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-y-yellow-400\/20 {
  border-top-color: rgb(250 204 21 / 0.2) !important;
  border-bottom-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-y-yellow-400\/25 {
  border-top-color: rgb(250 204 21 / 0.25) !important;
  border-bottom-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-y-yellow-400\/30 {
  border-top-color: rgb(250 204 21 / 0.3) !important;
  border-bottom-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-y-yellow-400\/35 {
  border-top-color: rgb(250 204 21 / 0.35) !important;
  border-bottom-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-y-yellow-400\/40 {
  border-top-color: rgb(250 204 21 / 0.4) !important;
  border-bottom-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-y-yellow-400\/45 {
  border-top-color: rgb(250 204 21 / 0.45) !important;
  border-bottom-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-y-yellow-400\/5 {
  border-top-color: rgb(250 204 21 / 0.05) !important;
  border-bottom-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-y-yellow-400\/50 {
  border-top-color: rgb(250 204 21 / 0.5) !important;
  border-bottom-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-y-yellow-400\/55 {
  border-top-color: rgb(250 204 21 / 0.55) !important;
  border-bottom-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-y-yellow-400\/60 {
  border-top-color: rgb(250 204 21 / 0.6) !important;
  border-bottom-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-y-yellow-400\/65 {
  border-top-color: rgb(250 204 21 / 0.65) !important;
  border-bottom-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-y-yellow-400\/70 {
  border-top-color: rgb(250 204 21 / 0.7) !important;
  border-bottom-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-y-yellow-400\/75 {
  border-top-color: rgb(250 204 21 / 0.75) !important;
  border-bottom-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-y-yellow-400\/80 {
  border-top-color: rgb(250 204 21 / 0.8) !important;
  border-bottom-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-y-yellow-400\/85 {
  border-top-color: rgb(250 204 21 / 0.85) !important;
  border-bottom-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-y-yellow-400\/90 {
  border-top-color: rgb(250 204 21 / 0.9) !important;
  border-bottom-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-y-yellow-400\/95 {
  border-top-color: rgb(250 204 21 / 0.95) !important;
  border-bottom-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-y-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-50\/0 {
  border-top-color: rgb(254 252 232 / 0) !important;
  border-bottom-color: rgb(254 252 232 / 0) !important;
}

.tw-border-y-yellow-50\/10 {
  border-top-color: rgb(254 252 232 / 0.1) !important;
  border-bottom-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-y-yellow-50\/100 {
  border-top-color: rgb(254 252 232 / 1) !important;
  border-bottom-color: rgb(254 252 232 / 1) !important;
}

.tw-border-y-yellow-50\/15 {
  border-top-color: rgb(254 252 232 / 0.15) !important;
  border-bottom-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-y-yellow-50\/20 {
  border-top-color: rgb(254 252 232 / 0.2) !important;
  border-bottom-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-y-yellow-50\/25 {
  border-top-color: rgb(254 252 232 / 0.25) !important;
  border-bottom-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-y-yellow-50\/30 {
  border-top-color: rgb(254 252 232 / 0.3) !important;
  border-bottom-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-y-yellow-50\/35 {
  border-top-color: rgb(254 252 232 / 0.35) !important;
  border-bottom-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-y-yellow-50\/40 {
  border-top-color: rgb(254 252 232 / 0.4) !important;
  border-bottom-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-y-yellow-50\/45 {
  border-top-color: rgb(254 252 232 / 0.45) !important;
  border-bottom-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-y-yellow-50\/5 {
  border-top-color: rgb(254 252 232 / 0.05) !important;
  border-bottom-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-y-yellow-50\/50 {
  border-top-color: rgb(254 252 232 / 0.5) !important;
  border-bottom-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-y-yellow-50\/55 {
  border-top-color: rgb(254 252 232 / 0.55) !important;
  border-bottom-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-y-yellow-50\/60 {
  border-top-color: rgb(254 252 232 / 0.6) !important;
  border-bottom-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-y-yellow-50\/65 {
  border-top-color: rgb(254 252 232 / 0.65) !important;
  border-bottom-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-y-yellow-50\/70 {
  border-top-color: rgb(254 252 232 / 0.7) !important;
  border-bottom-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-y-yellow-50\/75 {
  border-top-color: rgb(254 252 232 / 0.75) !important;
  border-bottom-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-y-yellow-50\/80 {
  border-top-color: rgb(254 252 232 / 0.8) !important;
  border-bottom-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-y-yellow-50\/85 {
  border-top-color: rgb(254 252 232 / 0.85) !important;
  border-bottom-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-y-yellow-50\/90 {
  border-top-color: rgb(254 252 232 / 0.9) !important;
  border-bottom-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-y-yellow-50\/95 {
  border-top-color: rgb(254 252 232 / 0.95) !important;
  border-bottom-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-y-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-500\/0 {
  border-top-color: rgb(234 179 8 / 0) !important;
  border-bottom-color: rgb(234 179 8 / 0) !important;
}

.tw-border-y-yellow-500\/10 {
  border-top-color: rgb(234 179 8 / 0.1) !important;
  border-bottom-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-y-yellow-500\/100 {
  border-top-color: rgb(234 179 8 / 1) !important;
  border-bottom-color: rgb(234 179 8 / 1) !important;
}

.tw-border-y-yellow-500\/15 {
  border-top-color: rgb(234 179 8 / 0.15) !important;
  border-bottom-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-y-yellow-500\/20 {
  border-top-color: rgb(234 179 8 / 0.2) !important;
  border-bottom-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-y-yellow-500\/25 {
  border-top-color: rgb(234 179 8 / 0.25) !important;
  border-bottom-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-y-yellow-500\/30 {
  border-top-color: rgb(234 179 8 / 0.3) !important;
  border-bottom-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-y-yellow-500\/35 {
  border-top-color: rgb(234 179 8 / 0.35) !important;
  border-bottom-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-y-yellow-500\/40 {
  border-top-color: rgb(234 179 8 / 0.4) !important;
  border-bottom-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-y-yellow-500\/45 {
  border-top-color: rgb(234 179 8 / 0.45) !important;
  border-bottom-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-y-yellow-500\/5 {
  border-top-color: rgb(234 179 8 / 0.05) !important;
  border-bottom-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-y-yellow-500\/50 {
  border-top-color: rgb(234 179 8 / 0.5) !important;
  border-bottom-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-y-yellow-500\/55 {
  border-top-color: rgb(234 179 8 / 0.55) !important;
  border-bottom-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-y-yellow-500\/60 {
  border-top-color: rgb(234 179 8 / 0.6) !important;
  border-bottom-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-y-yellow-500\/65 {
  border-top-color: rgb(234 179 8 / 0.65) !important;
  border-bottom-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-y-yellow-500\/70 {
  border-top-color: rgb(234 179 8 / 0.7) !important;
  border-bottom-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-y-yellow-500\/75 {
  border-top-color: rgb(234 179 8 / 0.75) !important;
  border-bottom-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-y-yellow-500\/80 {
  border-top-color: rgb(234 179 8 / 0.8) !important;
  border-bottom-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-y-yellow-500\/85 {
  border-top-color: rgb(234 179 8 / 0.85) !important;
  border-bottom-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-y-yellow-500\/90 {
  border-top-color: rgb(234 179 8 / 0.9) !important;
  border-bottom-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-y-yellow-500\/95 {
  border-top-color: rgb(234 179 8 / 0.95) !important;
  border-bottom-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-y-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-600\/0 {
  border-top-color: rgb(202 138 4 / 0) !important;
  border-bottom-color: rgb(202 138 4 / 0) !important;
}

.tw-border-y-yellow-600\/10 {
  border-top-color: rgb(202 138 4 / 0.1) !important;
  border-bottom-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-y-yellow-600\/100 {
  border-top-color: rgb(202 138 4 / 1) !important;
  border-bottom-color: rgb(202 138 4 / 1) !important;
}

.tw-border-y-yellow-600\/15 {
  border-top-color: rgb(202 138 4 / 0.15) !important;
  border-bottom-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-y-yellow-600\/20 {
  border-top-color: rgb(202 138 4 / 0.2) !important;
  border-bottom-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-y-yellow-600\/25 {
  border-top-color: rgb(202 138 4 / 0.25) !important;
  border-bottom-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-y-yellow-600\/30 {
  border-top-color: rgb(202 138 4 / 0.3) !important;
  border-bottom-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-y-yellow-600\/35 {
  border-top-color: rgb(202 138 4 / 0.35) !important;
  border-bottom-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-y-yellow-600\/40 {
  border-top-color: rgb(202 138 4 / 0.4) !important;
  border-bottom-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-y-yellow-600\/45 {
  border-top-color: rgb(202 138 4 / 0.45) !important;
  border-bottom-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-y-yellow-600\/5 {
  border-top-color: rgb(202 138 4 / 0.05) !important;
  border-bottom-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-y-yellow-600\/50 {
  border-top-color: rgb(202 138 4 / 0.5) !important;
  border-bottom-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-y-yellow-600\/55 {
  border-top-color: rgb(202 138 4 / 0.55) !important;
  border-bottom-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-y-yellow-600\/60 {
  border-top-color: rgb(202 138 4 / 0.6) !important;
  border-bottom-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-y-yellow-600\/65 {
  border-top-color: rgb(202 138 4 / 0.65) !important;
  border-bottom-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-y-yellow-600\/70 {
  border-top-color: rgb(202 138 4 / 0.7) !important;
  border-bottom-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-y-yellow-600\/75 {
  border-top-color: rgb(202 138 4 / 0.75) !important;
  border-bottom-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-y-yellow-600\/80 {
  border-top-color: rgb(202 138 4 / 0.8) !important;
  border-bottom-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-y-yellow-600\/85 {
  border-top-color: rgb(202 138 4 / 0.85) !important;
  border-bottom-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-y-yellow-600\/90 {
  border-top-color: rgb(202 138 4 / 0.9) !important;
  border-bottom-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-y-yellow-600\/95 {
  border-top-color: rgb(202 138 4 / 0.95) !important;
  border-bottom-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-y-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-700\/0 {
  border-top-color: rgb(161 98 7 / 0) !important;
  border-bottom-color: rgb(161 98 7 / 0) !important;
}

.tw-border-y-yellow-700\/10 {
  border-top-color: rgb(161 98 7 / 0.1) !important;
  border-bottom-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-y-yellow-700\/100 {
  border-top-color: rgb(161 98 7 / 1) !important;
  border-bottom-color: rgb(161 98 7 / 1) !important;
}

.tw-border-y-yellow-700\/15 {
  border-top-color: rgb(161 98 7 / 0.15) !important;
  border-bottom-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-y-yellow-700\/20 {
  border-top-color: rgb(161 98 7 / 0.2) !important;
  border-bottom-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-y-yellow-700\/25 {
  border-top-color: rgb(161 98 7 / 0.25) !important;
  border-bottom-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-y-yellow-700\/30 {
  border-top-color: rgb(161 98 7 / 0.3) !important;
  border-bottom-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-y-yellow-700\/35 {
  border-top-color: rgb(161 98 7 / 0.35) !important;
  border-bottom-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-y-yellow-700\/40 {
  border-top-color: rgb(161 98 7 / 0.4) !important;
  border-bottom-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-y-yellow-700\/45 {
  border-top-color: rgb(161 98 7 / 0.45) !important;
  border-bottom-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-y-yellow-700\/5 {
  border-top-color: rgb(161 98 7 / 0.05) !important;
  border-bottom-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-y-yellow-700\/50 {
  border-top-color: rgb(161 98 7 / 0.5) !important;
  border-bottom-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-y-yellow-700\/55 {
  border-top-color: rgb(161 98 7 / 0.55) !important;
  border-bottom-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-y-yellow-700\/60 {
  border-top-color: rgb(161 98 7 / 0.6) !important;
  border-bottom-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-y-yellow-700\/65 {
  border-top-color: rgb(161 98 7 / 0.65) !important;
  border-bottom-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-y-yellow-700\/70 {
  border-top-color: rgb(161 98 7 / 0.7) !important;
  border-bottom-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-y-yellow-700\/75 {
  border-top-color: rgb(161 98 7 / 0.75) !important;
  border-bottom-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-y-yellow-700\/80 {
  border-top-color: rgb(161 98 7 / 0.8) !important;
  border-bottom-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-y-yellow-700\/85 {
  border-top-color: rgb(161 98 7 / 0.85) !important;
  border-bottom-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-y-yellow-700\/90 {
  border-top-color: rgb(161 98 7 / 0.9) !important;
  border-bottom-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-y-yellow-700\/95 {
  border-top-color: rgb(161 98 7 / 0.95) !important;
  border-bottom-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-y-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-800\/0 {
  border-top-color: rgb(133 77 14 / 0) !important;
  border-bottom-color: rgb(133 77 14 / 0) !important;
}

.tw-border-y-yellow-800\/10 {
  border-top-color: rgb(133 77 14 / 0.1) !important;
  border-bottom-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-y-yellow-800\/100 {
  border-top-color: rgb(133 77 14 / 1) !important;
  border-bottom-color: rgb(133 77 14 / 1) !important;
}

.tw-border-y-yellow-800\/15 {
  border-top-color: rgb(133 77 14 / 0.15) !important;
  border-bottom-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-y-yellow-800\/20 {
  border-top-color: rgb(133 77 14 / 0.2) !important;
  border-bottom-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-y-yellow-800\/25 {
  border-top-color: rgb(133 77 14 / 0.25) !important;
  border-bottom-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-y-yellow-800\/30 {
  border-top-color: rgb(133 77 14 / 0.3) !important;
  border-bottom-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-y-yellow-800\/35 {
  border-top-color: rgb(133 77 14 / 0.35) !important;
  border-bottom-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-y-yellow-800\/40 {
  border-top-color: rgb(133 77 14 / 0.4) !important;
  border-bottom-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-y-yellow-800\/45 {
  border-top-color: rgb(133 77 14 / 0.45) !important;
  border-bottom-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-y-yellow-800\/5 {
  border-top-color: rgb(133 77 14 / 0.05) !important;
  border-bottom-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-y-yellow-800\/50 {
  border-top-color: rgb(133 77 14 / 0.5) !important;
  border-bottom-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-y-yellow-800\/55 {
  border-top-color: rgb(133 77 14 / 0.55) !important;
  border-bottom-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-y-yellow-800\/60 {
  border-top-color: rgb(133 77 14 / 0.6) !important;
  border-bottom-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-y-yellow-800\/65 {
  border-top-color: rgb(133 77 14 / 0.65) !important;
  border-bottom-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-y-yellow-800\/70 {
  border-top-color: rgb(133 77 14 / 0.7) !important;
  border-bottom-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-y-yellow-800\/75 {
  border-top-color: rgb(133 77 14 / 0.75) !important;
  border-bottom-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-y-yellow-800\/80 {
  border-top-color: rgb(133 77 14 / 0.8) !important;
  border-bottom-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-y-yellow-800\/85 {
  border-top-color: rgb(133 77 14 / 0.85) !important;
  border-bottom-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-y-yellow-800\/90 {
  border-top-color: rgb(133 77 14 / 0.9) !important;
  border-bottom-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-y-yellow-800\/95 {
  border-top-color: rgb(133 77 14 / 0.95) !important;
  border-bottom-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-y-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-900\/0 {
  border-top-color: rgb(113 63 18 / 0) !important;
  border-bottom-color: rgb(113 63 18 / 0) !important;
}

.tw-border-y-yellow-900\/10 {
  border-top-color: rgb(113 63 18 / 0.1) !important;
  border-bottom-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-y-yellow-900\/100 {
  border-top-color: rgb(113 63 18 / 1) !important;
  border-bottom-color: rgb(113 63 18 / 1) !important;
}

.tw-border-y-yellow-900\/15 {
  border-top-color: rgb(113 63 18 / 0.15) !important;
  border-bottom-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-y-yellow-900\/20 {
  border-top-color: rgb(113 63 18 / 0.2) !important;
  border-bottom-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-y-yellow-900\/25 {
  border-top-color: rgb(113 63 18 / 0.25) !important;
  border-bottom-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-y-yellow-900\/30 {
  border-top-color: rgb(113 63 18 / 0.3) !important;
  border-bottom-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-y-yellow-900\/35 {
  border-top-color: rgb(113 63 18 / 0.35) !important;
  border-bottom-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-y-yellow-900\/40 {
  border-top-color: rgb(113 63 18 / 0.4) !important;
  border-bottom-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-y-yellow-900\/45 {
  border-top-color: rgb(113 63 18 / 0.45) !important;
  border-bottom-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-y-yellow-900\/5 {
  border-top-color: rgb(113 63 18 / 0.05) !important;
  border-bottom-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-y-yellow-900\/50 {
  border-top-color: rgb(113 63 18 / 0.5) !important;
  border-bottom-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-y-yellow-900\/55 {
  border-top-color: rgb(113 63 18 / 0.55) !important;
  border-bottom-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-y-yellow-900\/60 {
  border-top-color: rgb(113 63 18 / 0.6) !important;
  border-bottom-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-y-yellow-900\/65 {
  border-top-color: rgb(113 63 18 / 0.65) !important;
  border-bottom-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-y-yellow-900\/70 {
  border-top-color: rgb(113 63 18 / 0.7) !important;
  border-bottom-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-y-yellow-900\/75 {
  border-top-color: rgb(113 63 18 / 0.75) !important;
  border-bottom-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-y-yellow-900\/80 {
  border-top-color: rgb(113 63 18 / 0.8) !important;
  border-bottom-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-y-yellow-900\/85 {
  border-top-color: rgb(113 63 18 / 0.85) !important;
  border-bottom-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-y-yellow-900\/90 {
  border-top-color: rgb(113 63 18 / 0.9) !important;
  border-bottom-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-y-yellow-900\/95 {
  border-top-color: rgb(113 63 18 / 0.95) !important;
  border-bottom-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-y-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-y-yellow-950\/0 {
  border-top-color: rgb(66 32 6 / 0) !important;
  border-bottom-color: rgb(66 32 6 / 0) !important;
}

.tw-border-y-yellow-950\/10 {
  border-top-color: rgb(66 32 6 / 0.1) !important;
  border-bottom-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-y-yellow-950\/100 {
  border-top-color: rgb(66 32 6 / 1) !important;
  border-bottom-color: rgb(66 32 6 / 1) !important;
}

.tw-border-y-yellow-950\/15 {
  border-top-color: rgb(66 32 6 / 0.15) !important;
  border-bottom-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-y-yellow-950\/20 {
  border-top-color: rgb(66 32 6 / 0.2) !important;
  border-bottom-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-y-yellow-950\/25 {
  border-top-color: rgb(66 32 6 / 0.25) !important;
  border-bottom-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-y-yellow-950\/30 {
  border-top-color: rgb(66 32 6 / 0.3) !important;
  border-bottom-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-y-yellow-950\/35 {
  border-top-color: rgb(66 32 6 / 0.35) !important;
  border-bottom-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-y-yellow-950\/40 {
  border-top-color: rgb(66 32 6 / 0.4) !important;
  border-bottom-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-y-yellow-950\/45 {
  border-top-color: rgb(66 32 6 / 0.45) !important;
  border-bottom-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-y-yellow-950\/5 {
  border-top-color: rgb(66 32 6 / 0.05) !important;
  border-bottom-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-y-yellow-950\/50 {
  border-top-color: rgb(66 32 6 / 0.5) !important;
  border-bottom-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-y-yellow-950\/55 {
  border-top-color: rgb(66 32 6 / 0.55) !important;
  border-bottom-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-y-yellow-950\/60 {
  border-top-color: rgb(66 32 6 / 0.6) !important;
  border-bottom-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-y-yellow-950\/65 {
  border-top-color: rgb(66 32 6 / 0.65) !important;
  border-bottom-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-y-yellow-950\/70 {
  border-top-color: rgb(66 32 6 / 0.7) !important;
  border-bottom-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-y-yellow-950\/75 {
  border-top-color: rgb(66 32 6 / 0.75) !important;
  border-bottom-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-y-yellow-950\/80 {
  border-top-color: rgb(66 32 6 / 0.8) !important;
  border-bottom-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-y-yellow-950\/85 {
  border-top-color: rgb(66 32 6 / 0.85) !important;
  border-bottom-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-y-yellow-950\/90 {
  border-top-color: rgb(66 32 6 / 0.9) !important;
  border-bottom-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-y-yellow-950\/95 {
  border-top-color: rgb(66 32 6 / 0.95) !important;
  border-bottom-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-y-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-100\/0 {
  border-top-color: rgb(244 244 245 / 0) !important;
  border-bottom-color: rgb(244 244 245 / 0) !important;
}

.tw-border-y-zinc-100\/10 {
  border-top-color: rgb(244 244 245 / 0.1) !important;
  border-bottom-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-y-zinc-100\/100 {
  border-top-color: rgb(244 244 245 / 1) !important;
  border-bottom-color: rgb(244 244 245 / 1) !important;
}

.tw-border-y-zinc-100\/15 {
  border-top-color: rgb(244 244 245 / 0.15) !important;
  border-bottom-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-y-zinc-100\/20 {
  border-top-color: rgb(244 244 245 / 0.2) !important;
  border-bottom-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-y-zinc-100\/25 {
  border-top-color: rgb(244 244 245 / 0.25) !important;
  border-bottom-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-y-zinc-100\/30 {
  border-top-color: rgb(244 244 245 / 0.3) !important;
  border-bottom-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-y-zinc-100\/35 {
  border-top-color: rgb(244 244 245 / 0.35) !important;
  border-bottom-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-y-zinc-100\/40 {
  border-top-color: rgb(244 244 245 / 0.4) !important;
  border-bottom-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-y-zinc-100\/45 {
  border-top-color: rgb(244 244 245 / 0.45) !important;
  border-bottom-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-y-zinc-100\/5 {
  border-top-color: rgb(244 244 245 / 0.05) !important;
  border-bottom-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-y-zinc-100\/50 {
  border-top-color: rgb(244 244 245 / 0.5) !important;
  border-bottom-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-y-zinc-100\/55 {
  border-top-color: rgb(244 244 245 / 0.55) !important;
  border-bottom-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-y-zinc-100\/60 {
  border-top-color: rgb(244 244 245 / 0.6) !important;
  border-bottom-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-y-zinc-100\/65 {
  border-top-color: rgb(244 244 245 / 0.65) !important;
  border-bottom-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-y-zinc-100\/70 {
  border-top-color: rgb(244 244 245 / 0.7) !important;
  border-bottom-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-y-zinc-100\/75 {
  border-top-color: rgb(244 244 245 / 0.75) !important;
  border-bottom-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-y-zinc-100\/80 {
  border-top-color: rgb(244 244 245 / 0.8) !important;
  border-bottom-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-y-zinc-100\/85 {
  border-top-color: rgb(244 244 245 / 0.85) !important;
  border-bottom-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-y-zinc-100\/90 {
  border-top-color: rgb(244 244 245 / 0.9) !important;
  border-bottom-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-y-zinc-100\/95 {
  border-top-color: rgb(244 244 245 / 0.95) !important;
  border-bottom-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-y-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-200\/0 {
  border-top-color: rgb(228 228 231 / 0) !important;
  border-bottom-color: rgb(228 228 231 / 0) !important;
}

.tw-border-y-zinc-200\/10 {
  border-top-color: rgb(228 228 231 / 0.1) !important;
  border-bottom-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-y-zinc-200\/100 {
  border-top-color: rgb(228 228 231 / 1) !important;
  border-bottom-color: rgb(228 228 231 / 1) !important;
}

.tw-border-y-zinc-200\/15 {
  border-top-color: rgb(228 228 231 / 0.15) !important;
  border-bottom-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-y-zinc-200\/20 {
  border-top-color: rgb(228 228 231 / 0.2) !important;
  border-bottom-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-y-zinc-200\/25 {
  border-top-color: rgb(228 228 231 / 0.25) !important;
  border-bottom-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-y-zinc-200\/30 {
  border-top-color: rgb(228 228 231 / 0.3) !important;
  border-bottom-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-y-zinc-200\/35 {
  border-top-color: rgb(228 228 231 / 0.35) !important;
  border-bottom-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-y-zinc-200\/40 {
  border-top-color: rgb(228 228 231 / 0.4) !important;
  border-bottom-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-y-zinc-200\/45 {
  border-top-color: rgb(228 228 231 / 0.45) !important;
  border-bottom-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-y-zinc-200\/5 {
  border-top-color: rgb(228 228 231 / 0.05) !important;
  border-bottom-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-y-zinc-200\/50 {
  border-top-color: rgb(228 228 231 / 0.5) !important;
  border-bottom-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-y-zinc-200\/55 {
  border-top-color: rgb(228 228 231 / 0.55) !important;
  border-bottom-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-y-zinc-200\/60 {
  border-top-color: rgb(228 228 231 / 0.6) !important;
  border-bottom-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-y-zinc-200\/65 {
  border-top-color: rgb(228 228 231 / 0.65) !important;
  border-bottom-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-y-zinc-200\/70 {
  border-top-color: rgb(228 228 231 / 0.7) !important;
  border-bottom-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-y-zinc-200\/75 {
  border-top-color: rgb(228 228 231 / 0.75) !important;
  border-bottom-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-y-zinc-200\/80 {
  border-top-color: rgb(228 228 231 / 0.8) !important;
  border-bottom-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-y-zinc-200\/85 {
  border-top-color: rgb(228 228 231 / 0.85) !important;
  border-bottom-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-y-zinc-200\/90 {
  border-top-color: rgb(228 228 231 / 0.9) !important;
  border-bottom-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-y-zinc-200\/95 {
  border-top-color: rgb(228 228 231 / 0.95) !important;
  border-bottom-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-y-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-300\/0 {
  border-top-color: rgb(212 212 216 / 0) !important;
  border-bottom-color: rgb(212 212 216 / 0) !important;
}

.tw-border-y-zinc-300\/10 {
  border-top-color: rgb(212 212 216 / 0.1) !important;
  border-bottom-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-y-zinc-300\/100 {
  border-top-color: rgb(212 212 216 / 1) !important;
  border-bottom-color: rgb(212 212 216 / 1) !important;
}

.tw-border-y-zinc-300\/15 {
  border-top-color: rgb(212 212 216 / 0.15) !important;
  border-bottom-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-y-zinc-300\/20 {
  border-top-color: rgb(212 212 216 / 0.2) !important;
  border-bottom-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-y-zinc-300\/25 {
  border-top-color: rgb(212 212 216 / 0.25) !important;
  border-bottom-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-y-zinc-300\/30 {
  border-top-color: rgb(212 212 216 / 0.3) !important;
  border-bottom-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-y-zinc-300\/35 {
  border-top-color: rgb(212 212 216 / 0.35) !important;
  border-bottom-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-y-zinc-300\/40 {
  border-top-color: rgb(212 212 216 / 0.4) !important;
  border-bottom-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-y-zinc-300\/45 {
  border-top-color: rgb(212 212 216 / 0.45) !important;
  border-bottom-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-y-zinc-300\/5 {
  border-top-color: rgb(212 212 216 / 0.05) !important;
  border-bottom-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-y-zinc-300\/50 {
  border-top-color: rgb(212 212 216 / 0.5) !important;
  border-bottom-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-y-zinc-300\/55 {
  border-top-color: rgb(212 212 216 / 0.55) !important;
  border-bottom-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-y-zinc-300\/60 {
  border-top-color: rgb(212 212 216 / 0.6) !important;
  border-bottom-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-y-zinc-300\/65 {
  border-top-color: rgb(212 212 216 / 0.65) !important;
  border-bottom-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-y-zinc-300\/70 {
  border-top-color: rgb(212 212 216 / 0.7) !important;
  border-bottom-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-y-zinc-300\/75 {
  border-top-color: rgb(212 212 216 / 0.75) !important;
  border-bottom-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-y-zinc-300\/80 {
  border-top-color: rgb(212 212 216 / 0.8) !important;
  border-bottom-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-y-zinc-300\/85 {
  border-top-color: rgb(212 212 216 / 0.85) !important;
  border-bottom-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-y-zinc-300\/90 {
  border-top-color: rgb(212 212 216 / 0.9) !important;
  border-bottom-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-y-zinc-300\/95 {
  border-top-color: rgb(212 212 216 / 0.95) !important;
  border-bottom-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-y-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-400\/0 {
  border-top-color: rgb(161 161 170 / 0) !important;
  border-bottom-color: rgb(161 161 170 / 0) !important;
}

.tw-border-y-zinc-400\/10 {
  border-top-color: rgb(161 161 170 / 0.1) !important;
  border-bottom-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-y-zinc-400\/100 {
  border-top-color: rgb(161 161 170 / 1) !important;
  border-bottom-color: rgb(161 161 170 / 1) !important;
}

.tw-border-y-zinc-400\/15 {
  border-top-color: rgb(161 161 170 / 0.15) !important;
  border-bottom-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-y-zinc-400\/20 {
  border-top-color: rgb(161 161 170 / 0.2) !important;
  border-bottom-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-y-zinc-400\/25 {
  border-top-color: rgb(161 161 170 / 0.25) !important;
  border-bottom-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-y-zinc-400\/30 {
  border-top-color: rgb(161 161 170 / 0.3) !important;
  border-bottom-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-y-zinc-400\/35 {
  border-top-color: rgb(161 161 170 / 0.35) !important;
  border-bottom-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-y-zinc-400\/40 {
  border-top-color: rgb(161 161 170 / 0.4) !important;
  border-bottom-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-y-zinc-400\/45 {
  border-top-color: rgb(161 161 170 / 0.45) !important;
  border-bottom-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-y-zinc-400\/5 {
  border-top-color: rgb(161 161 170 / 0.05) !important;
  border-bottom-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-y-zinc-400\/50 {
  border-top-color: rgb(161 161 170 / 0.5) !important;
  border-bottom-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-y-zinc-400\/55 {
  border-top-color: rgb(161 161 170 / 0.55) !important;
  border-bottom-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-y-zinc-400\/60 {
  border-top-color: rgb(161 161 170 / 0.6) !important;
  border-bottom-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-y-zinc-400\/65 {
  border-top-color: rgb(161 161 170 / 0.65) !important;
  border-bottom-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-y-zinc-400\/70 {
  border-top-color: rgb(161 161 170 / 0.7) !important;
  border-bottom-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-y-zinc-400\/75 {
  border-top-color: rgb(161 161 170 / 0.75) !important;
  border-bottom-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-y-zinc-400\/80 {
  border-top-color: rgb(161 161 170 / 0.8) !important;
  border-bottom-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-y-zinc-400\/85 {
  border-top-color: rgb(161 161 170 / 0.85) !important;
  border-bottom-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-y-zinc-400\/90 {
  border-top-color: rgb(161 161 170 / 0.9) !important;
  border-bottom-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-y-zinc-400\/95 {
  border-top-color: rgb(161 161 170 / 0.95) !important;
  border-bottom-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-y-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-50\/0 {
  border-top-color: rgb(250 250 250 / 0) !important;
  border-bottom-color: rgb(250 250 250 / 0) !important;
}

.tw-border-y-zinc-50\/10 {
  border-top-color: rgb(250 250 250 / 0.1) !important;
  border-bottom-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-y-zinc-50\/100 {
  border-top-color: rgb(250 250 250 / 1) !important;
  border-bottom-color: rgb(250 250 250 / 1) !important;
}

.tw-border-y-zinc-50\/15 {
  border-top-color: rgb(250 250 250 / 0.15) !important;
  border-bottom-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-y-zinc-50\/20 {
  border-top-color: rgb(250 250 250 / 0.2) !important;
  border-bottom-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-y-zinc-50\/25 {
  border-top-color: rgb(250 250 250 / 0.25) !important;
  border-bottom-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-y-zinc-50\/30 {
  border-top-color: rgb(250 250 250 / 0.3) !important;
  border-bottom-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-y-zinc-50\/35 {
  border-top-color: rgb(250 250 250 / 0.35) !important;
  border-bottom-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-y-zinc-50\/40 {
  border-top-color: rgb(250 250 250 / 0.4) !important;
  border-bottom-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-y-zinc-50\/45 {
  border-top-color: rgb(250 250 250 / 0.45) !important;
  border-bottom-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-y-zinc-50\/5 {
  border-top-color: rgb(250 250 250 / 0.05) !important;
  border-bottom-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-y-zinc-50\/50 {
  border-top-color: rgb(250 250 250 / 0.5) !important;
  border-bottom-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-y-zinc-50\/55 {
  border-top-color: rgb(250 250 250 / 0.55) !important;
  border-bottom-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-y-zinc-50\/60 {
  border-top-color: rgb(250 250 250 / 0.6) !important;
  border-bottom-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-y-zinc-50\/65 {
  border-top-color: rgb(250 250 250 / 0.65) !important;
  border-bottom-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-y-zinc-50\/70 {
  border-top-color: rgb(250 250 250 / 0.7) !important;
  border-bottom-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-y-zinc-50\/75 {
  border-top-color: rgb(250 250 250 / 0.75) !important;
  border-bottom-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-y-zinc-50\/80 {
  border-top-color: rgb(250 250 250 / 0.8) !important;
  border-bottom-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-y-zinc-50\/85 {
  border-top-color: rgb(250 250 250 / 0.85) !important;
  border-bottom-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-y-zinc-50\/90 {
  border-top-color: rgb(250 250 250 / 0.9) !important;
  border-bottom-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-y-zinc-50\/95 {
  border-top-color: rgb(250 250 250 / 0.95) !important;
  border-bottom-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-y-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-500\/0 {
  border-top-color: rgb(113 113 122 / 0) !important;
  border-bottom-color: rgb(113 113 122 / 0) !important;
}

.tw-border-y-zinc-500\/10 {
  border-top-color: rgb(113 113 122 / 0.1) !important;
  border-bottom-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-y-zinc-500\/100 {
  border-top-color: rgb(113 113 122 / 1) !important;
  border-bottom-color: rgb(113 113 122 / 1) !important;
}

.tw-border-y-zinc-500\/15 {
  border-top-color: rgb(113 113 122 / 0.15) !important;
  border-bottom-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-y-zinc-500\/20 {
  border-top-color: rgb(113 113 122 / 0.2) !important;
  border-bottom-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-y-zinc-500\/25 {
  border-top-color: rgb(113 113 122 / 0.25) !important;
  border-bottom-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-y-zinc-500\/30 {
  border-top-color: rgb(113 113 122 / 0.3) !important;
  border-bottom-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-y-zinc-500\/35 {
  border-top-color: rgb(113 113 122 / 0.35) !important;
  border-bottom-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-y-zinc-500\/40 {
  border-top-color: rgb(113 113 122 / 0.4) !important;
  border-bottom-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-y-zinc-500\/45 {
  border-top-color: rgb(113 113 122 / 0.45) !important;
  border-bottom-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-y-zinc-500\/5 {
  border-top-color: rgb(113 113 122 / 0.05) !important;
  border-bottom-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-y-zinc-500\/50 {
  border-top-color: rgb(113 113 122 / 0.5) !important;
  border-bottom-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-y-zinc-500\/55 {
  border-top-color: rgb(113 113 122 / 0.55) !important;
  border-bottom-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-y-zinc-500\/60 {
  border-top-color: rgb(113 113 122 / 0.6) !important;
  border-bottom-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-y-zinc-500\/65 {
  border-top-color: rgb(113 113 122 / 0.65) !important;
  border-bottom-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-y-zinc-500\/70 {
  border-top-color: rgb(113 113 122 / 0.7) !important;
  border-bottom-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-y-zinc-500\/75 {
  border-top-color: rgb(113 113 122 / 0.75) !important;
  border-bottom-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-y-zinc-500\/80 {
  border-top-color: rgb(113 113 122 / 0.8) !important;
  border-bottom-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-y-zinc-500\/85 {
  border-top-color: rgb(113 113 122 / 0.85) !important;
  border-bottom-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-y-zinc-500\/90 {
  border-top-color: rgb(113 113 122 / 0.9) !important;
  border-bottom-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-y-zinc-500\/95 {
  border-top-color: rgb(113 113 122 / 0.95) !important;
  border-bottom-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-y-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-600\/0 {
  border-top-color: rgb(82 82 91 / 0) !important;
  border-bottom-color: rgb(82 82 91 / 0) !important;
}

.tw-border-y-zinc-600\/10 {
  border-top-color: rgb(82 82 91 / 0.1) !important;
  border-bottom-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-y-zinc-600\/100 {
  border-top-color: rgb(82 82 91 / 1) !important;
  border-bottom-color: rgb(82 82 91 / 1) !important;
}

.tw-border-y-zinc-600\/15 {
  border-top-color: rgb(82 82 91 / 0.15) !important;
  border-bottom-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-y-zinc-600\/20 {
  border-top-color: rgb(82 82 91 / 0.2) !important;
  border-bottom-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-y-zinc-600\/25 {
  border-top-color: rgb(82 82 91 / 0.25) !important;
  border-bottom-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-y-zinc-600\/30 {
  border-top-color: rgb(82 82 91 / 0.3) !important;
  border-bottom-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-y-zinc-600\/35 {
  border-top-color: rgb(82 82 91 / 0.35) !important;
  border-bottom-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-y-zinc-600\/40 {
  border-top-color: rgb(82 82 91 / 0.4) !important;
  border-bottom-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-y-zinc-600\/45 {
  border-top-color: rgb(82 82 91 / 0.45) !important;
  border-bottom-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-y-zinc-600\/5 {
  border-top-color: rgb(82 82 91 / 0.05) !important;
  border-bottom-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-y-zinc-600\/50 {
  border-top-color: rgb(82 82 91 / 0.5) !important;
  border-bottom-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-y-zinc-600\/55 {
  border-top-color: rgb(82 82 91 / 0.55) !important;
  border-bottom-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-y-zinc-600\/60 {
  border-top-color: rgb(82 82 91 / 0.6) !important;
  border-bottom-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-y-zinc-600\/65 {
  border-top-color: rgb(82 82 91 / 0.65) !important;
  border-bottom-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-y-zinc-600\/70 {
  border-top-color: rgb(82 82 91 / 0.7) !important;
  border-bottom-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-y-zinc-600\/75 {
  border-top-color: rgb(82 82 91 / 0.75) !important;
  border-bottom-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-y-zinc-600\/80 {
  border-top-color: rgb(82 82 91 / 0.8) !important;
  border-bottom-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-y-zinc-600\/85 {
  border-top-color: rgb(82 82 91 / 0.85) !important;
  border-bottom-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-y-zinc-600\/90 {
  border-top-color: rgb(82 82 91 / 0.9) !important;
  border-bottom-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-y-zinc-600\/95 {
  border-top-color: rgb(82 82 91 / 0.95) !important;
  border-bottom-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-y-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-700\/0 {
  border-top-color: rgb(63 63 70 / 0) !important;
  border-bottom-color: rgb(63 63 70 / 0) !important;
}

.tw-border-y-zinc-700\/10 {
  border-top-color: rgb(63 63 70 / 0.1) !important;
  border-bottom-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-y-zinc-700\/100 {
  border-top-color: rgb(63 63 70 / 1) !important;
  border-bottom-color: rgb(63 63 70 / 1) !important;
}

.tw-border-y-zinc-700\/15 {
  border-top-color: rgb(63 63 70 / 0.15) !important;
  border-bottom-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-y-zinc-700\/20 {
  border-top-color: rgb(63 63 70 / 0.2) !important;
  border-bottom-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-y-zinc-700\/25 {
  border-top-color: rgb(63 63 70 / 0.25) !important;
  border-bottom-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-y-zinc-700\/30 {
  border-top-color: rgb(63 63 70 / 0.3) !important;
  border-bottom-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-y-zinc-700\/35 {
  border-top-color: rgb(63 63 70 / 0.35) !important;
  border-bottom-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-y-zinc-700\/40 {
  border-top-color: rgb(63 63 70 / 0.4) !important;
  border-bottom-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-y-zinc-700\/45 {
  border-top-color: rgb(63 63 70 / 0.45) !important;
  border-bottom-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-y-zinc-700\/5 {
  border-top-color: rgb(63 63 70 / 0.05) !important;
  border-bottom-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-y-zinc-700\/50 {
  border-top-color: rgb(63 63 70 / 0.5) !important;
  border-bottom-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-y-zinc-700\/55 {
  border-top-color: rgb(63 63 70 / 0.55) !important;
  border-bottom-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-y-zinc-700\/60 {
  border-top-color: rgb(63 63 70 / 0.6) !important;
  border-bottom-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-y-zinc-700\/65 {
  border-top-color: rgb(63 63 70 / 0.65) !important;
  border-bottom-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-y-zinc-700\/70 {
  border-top-color: rgb(63 63 70 / 0.7) !important;
  border-bottom-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-y-zinc-700\/75 {
  border-top-color: rgb(63 63 70 / 0.75) !important;
  border-bottom-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-y-zinc-700\/80 {
  border-top-color: rgb(63 63 70 / 0.8) !important;
  border-bottom-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-y-zinc-700\/85 {
  border-top-color: rgb(63 63 70 / 0.85) !important;
  border-bottom-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-y-zinc-700\/90 {
  border-top-color: rgb(63 63 70 / 0.9) !important;
  border-bottom-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-y-zinc-700\/95 {
  border-top-color: rgb(63 63 70 / 0.95) !important;
  border-bottom-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-y-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-800\/0 {
  border-top-color: rgb(39 39 42 / 0) !important;
  border-bottom-color: rgb(39 39 42 / 0) !important;
}

.tw-border-y-zinc-800\/10 {
  border-top-color: rgb(39 39 42 / 0.1) !important;
  border-bottom-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-y-zinc-800\/100 {
  border-top-color: rgb(39 39 42 / 1) !important;
  border-bottom-color: rgb(39 39 42 / 1) !important;
}

.tw-border-y-zinc-800\/15 {
  border-top-color: rgb(39 39 42 / 0.15) !important;
  border-bottom-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-y-zinc-800\/20 {
  border-top-color: rgb(39 39 42 / 0.2) !important;
  border-bottom-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-y-zinc-800\/25 {
  border-top-color: rgb(39 39 42 / 0.25) !important;
  border-bottom-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-y-zinc-800\/30 {
  border-top-color: rgb(39 39 42 / 0.3) !important;
  border-bottom-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-y-zinc-800\/35 {
  border-top-color: rgb(39 39 42 / 0.35) !important;
  border-bottom-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-y-zinc-800\/40 {
  border-top-color: rgb(39 39 42 / 0.4) !important;
  border-bottom-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-y-zinc-800\/45 {
  border-top-color: rgb(39 39 42 / 0.45) !important;
  border-bottom-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-y-zinc-800\/5 {
  border-top-color: rgb(39 39 42 / 0.05) !important;
  border-bottom-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-y-zinc-800\/50 {
  border-top-color: rgb(39 39 42 / 0.5) !important;
  border-bottom-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-y-zinc-800\/55 {
  border-top-color: rgb(39 39 42 / 0.55) !important;
  border-bottom-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-y-zinc-800\/60 {
  border-top-color: rgb(39 39 42 / 0.6) !important;
  border-bottom-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-y-zinc-800\/65 {
  border-top-color: rgb(39 39 42 / 0.65) !important;
  border-bottom-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-y-zinc-800\/70 {
  border-top-color: rgb(39 39 42 / 0.7) !important;
  border-bottom-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-y-zinc-800\/75 {
  border-top-color: rgb(39 39 42 / 0.75) !important;
  border-bottom-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-y-zinc-800\/80 {
  border-top-color: rgb(39 39 42 / 0.8) !important;
  border-bottom-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-y-zinc-800\/85 {
  border-top-color: rgb(39 39 42 / 0.85) !important;
  border-bottom-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-y-zinc-800\/90 {
  border-top-color: rgb(39 39 42 / 0.9) !important;
  border-bottom-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-y-zinc-800\/95 {
  border-top-color: rgb(39 39 42 / 0.95) !important;
  border-bottom-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-y-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-900\/0 {
  border-top-color: rgb(24 24 27 / 0) !important;
  border-bottom-color: rgb(24 24 27 / 0) !important;
}

.tw-border-y-zinc-900\/10 {
  border-top-color: rgb(24 24 27 / 0.1) !important;
  border-bottom-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-y-zinc-900\/100 {
  border-top-color: rgb(24 24 27 / 1) !important;
  border-bottom-color: rgb(24 24 27 / 1) !important;
}

.tw-border-y-zinc-900\/15 {
  border-top-color: rgb(24 24 27 / 0.15) !important;
  border-bottom-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-y-zinc-900\/20 {
  border-top-color: rgb(24 24 27 / 0.2) !important;
  border-bottom-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-y-zinc-900\/25 {
  border-top-color: rgb(24 24 27 / 0.25) !important;
  border-bottom-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-y-zinc-900\/30 {
  border-top-color: rgb(24 24 27 / 0.3) !important;
  border-bottom-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-y-zinc-900\/35 {
  border-top-color: rgb(24 24 27 / 0.35) !important;
  border-bottom-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-y-zinc-900\/40 {
  border-top-color: rgb(24 24 27 / 0.4) !important;
  border-bottom-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-y-zinc-900\/45 {
  border-top-color: rgb(24 24 27 / 0.45) !important;
  border-bottom-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-y-zinc-900\/5 {
  border-top-color: rgb(24 24 27 / 0.05) !important;
  border-bottom-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-y-zinc-900\/50 {
  border-top-color: rgb(24 24 27 / 0.5) !important;
  border-bottom-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-y-zinc-900\/55 {
  border-top-color: rgb(24 24 27 / 0.55) !important;
  border-bottom-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-y-zinc-900\/60 {
  border-top-color: rgb(24 24 27 / 0.6) !important;
  border-bottom-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-y-zinc-900\/65 {
  border-top-color: rgb(24 24 27 / 0.65) !important;
  border-bottom-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-y-zinc-900\/70 {
  border-top-color: rgb(24 24 27 / 0.7) !important;
  border-bottom-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-y-zinc-900\/75 {
  border-top-color: rgb(24 24 27 / 0.75) !important;
  border-bottom-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-y-zinc-900\/80 {
  border-top-color: rgb(24 24 27 / 0.8) !important;
  border-bottom-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-y-zinc-900\/85 {
  border-top-color: rgb(24 24 27 / 0.85) !important;
  border-bottom-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-y-zinc-900\/90 {
  border-top-color: rgb(24 24 27 / 0.9) !important;
  border-bottom-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-y-zinc-900\/95 {
  border-top-color: rgb(24 24 27 / 0.95) !important;
  border-bottom-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-y-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
  border-bottom-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-y-zinc-950\/0 {
  border-top-color: rgb(9 9 11 / 0) !important;
  border-bottom-color: rgb(9 9 11 / 0) !important;
}

.tw-border-y-zinc-950\/10 {
  border-top-color: rgb(9 9 11 / 0.1) !important;
  border-bottom-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-y-zinc-950\/100 {
  border-top-color: rgb(9 9 11 / 1) !important;
  border-bottom-color: rgb(9 9 11 / 1) !important;
}

.tw-border-y-zinc-950\/15 {
  border-top-color: rgb(9 9 11 / 0.15) !important;
  border-bottom-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-y-zinc-950\/20 {
  border-top-color: rgb(9 9 11 / 0.2) !important;
  border-bottom-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-y-zinc-950\/25 {
  border-top-color: rgb(9 9 11 / 0.25) !important;
  border-bottom-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-y-zinc-950\/30 {
  border-top-color: rgb(9 9 11 / 0.3) !important;
  border-bottom-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-y-zinc-950\/35 {
  border-top-color: rgb(9 9 11 / 0.35) !important;
  border-bottom-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-y-zinc-950\/40 {
  border-top-color: rgb(9 9 11 / 0.4) !important;
  border-bottom-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-y-zinc-950\/45 {
  border-top-color: rgb(9 9 11 / 0.45) !important;
  border-bottom-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-y-zinc-950\/5 {
  border-top-color: rgb(9 9 11 / 0.05) !important;
  border-bottom-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-y-zinc-950\/50 {
  border-top-color: rgb(9 9 11 / 0.5) !important;
  border-bottom-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-y-zinc-950\/55 {
  border-top-color: rgb(9 9 11 / 0.55) !important;
  border-bottom-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-y-zinc-950\/60 {
  border-top-color: rgb(9 9 11 / 0.6) !important;
  border-bottom-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-y-zinc-950\/65 {
  border-top-color: rgb(9 9 11 / 0.65) !important;
  border-bottom-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-y-zinc-950\/70 {
  border-top-color: rgb(9 9 11 / 0.7) !important;
  border-bottom-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-y-zinc-950\/75 {
  border-top-color: rgb(9 9 11 / 0.75) !important;
  border-bottom-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-y-zinc-950\/80 {
  border-top-color: rgb(9 9 11 / 0.8) !important;
  border-bottom-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-y-zinc-950\/85 {
  border-top-color: rgb(9 9 11 / 0.85) !important;
  border-bottom-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-y-zinc-950\/90 {
  border-top-color: rgb(9 9 11 / 0.9) !important;
  border-bottom-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-y-zinc-950\/95 {
  border-top-color: rgb(9 9 11 / 0.95) !important;
  border-bottom-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-b-amber-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-100\/0 {
  border-bottom-color: rgb(254 243 199 / 0) !important;
}

.tw-border-b-amber-100\/10 {
  border-bottom-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-b-amber-100\/100 {
  border-bottom-color: rgb(254 243 199 / 1) !important;
}

.tw-border-b-amber-100\/15 {
  border-bottom-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-b-amber-100\/20 {
  border-bottom-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-b-amber-100\/25 {
  border-bottom-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-b-amber-100\/30 {
  border-bottom-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-b-amber-100\/35 {
  border-bottom-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-b-amber-100\/40 {
  border-bottom-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-b-amber-100\/45 {
  border-bottom-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-b-amber-100\/5 {
  border-bottom-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-b-amber-100\/50 {
  border-bottom-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-b-amber-100\/55 {
  border-bottom-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-b-amber-100\/60 {
  border-bottom-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-b-amber-100\/65 {
  border-bottom-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-b-amber-100\/70 {
  border-bottom-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-b-amber-100\/75 {
  border-bottom-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-b-amber-100\/80 {
  border-bottom-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-b-amber-100\/85 {
  border-bottom-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-b-amber-100\/90 {
  border-bottom-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-b-amber-100\/95 {
  border-bottom-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-b-amber-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-200\/0 {
  border-bottom-color: rgb(253 230 138 / 0) !important;
}

.tw-border-b-amber-200\/10 {
  border-bottom-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-b-amber-200\/100 {
  border-bottom-color: rgb(253 230 138 / 1) !important;
}

.tw-border-b-amber-200\/15 {
  border-bottom-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-b-amber-200\/20 {
  border-bottom-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-b-amber-200\/25 {
  border-bottom-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-b-amber-200\/30 {
  border-bottom-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-b-amber-200\/35 {
  border-bottom-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-b-amber-200\/40 {
  border-bottom-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-b-amber-200\/45 {
  border-bottom-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-b-amber-200\/5 {
  border-bottom-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-b-amber-200\/50 {
  border-bottom-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-b-amber-200\/55 {
  border-bottom-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-b-amber-200\/60 {
  border-bottom-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-b-amber-200\/65 {
  border-bottom-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-b-amber-200\/70 {
  border-bottom-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-b-amber-200\/75 {
  border-bottom-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-b-amber-200\/80 {
  border-bottom-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-b-amber-200\/85 {
  border-bottom-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-b-amber-200\/90 {
  border-bottom-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-b-amber-200\/95 {
  border-bottom-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-b-amber-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-300\/0 {
  border-bottom-color: rgb(252 211 77 / 0) !important;
}

.tw-border-b-amber-300\/10 {
  border-bottom-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-b-amber-300\/100 {
  border-bottom-color: rgb(252 211 77 / 1) !important;
}

.tw-border-b-amber-300\/15 {
  border-bottom-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-b-amber-300\/20 {
  border-bottom-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-b-amber-300\/25 {
  border-bottom-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-b-amber-300\/30 {
  border-bottom-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-b-amber-300\/35 {
  border-bottom-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-b-amber-300\/40 {
  border-bottom-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-b-amber-300\/45 {
  border-bottom-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-b-amber-300\/5 {
  border-bottom-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-b-amber-300\/50 {
  border-bottom-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-b-amber-300\/55 {
  border-bottom-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-b-amber-300\/60 {
  border-bottom-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-b-amber-300\/65 {
  border-bottom-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-b-amber-300\/70 {
  border-bottom-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-b-amber-300\/75 {
  border-bottom-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-b-amber-300\/80 {
  border-bottom-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-b-amber-300\/85 {
  border-bottom-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-b-amber-300\/90 {
  border-bottom-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-b-amber-300\/95 {
  border-bottom-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-b-amber-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-400\/0 {
  border-bottom-color: rgb(251 191 36 / 0) !important;
}

.tw-border-b-amber-400\/10 {
  border-bottom-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-b-amber-400\/100 {
  border-bottom-color: rgb(251 191 36 / 1) !important;
}

.tw-border-b-amber-400\/15 {
  border-bottom-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-b-amber-400\/20 {
  border-bottom-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-b-amber-400\/25 {
  border-bottom-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-b-amber-400\/30 {
  border-bottom-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-b-amber-400\/35 {
  border-bottom-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-b-amber-400\/40 {
  border-bottom-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-b-amber-400\/45 {
  border-bottom-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-b-amber-400\/5 {
  border-bottom-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-b-amber-400\/50 {
  border-bottom-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-b-amber-400\/55 {
  border-bottom-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-b-amber-400\/60 {
  border-bottom-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-b-amber-400\/65 {
  border-bottom-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-b-amber-400\/70 {
  border-bottom-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-b-amber-400\/75 {
  border-bottom-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-b-amber-400\/80 {
  border-bottom-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-b-amber-400\/85 {
  border-bottom-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-b-amber-400\/90 {
  border-bottom-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-b-amber-400\/95 {
  border-bottom-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-b-amber-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-50\/0 {
  border-bottom-color: rgb(255 251 235 / 0) !important;
}

.tw-border-b-amber-50\/10 {
  border-bottom-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-b-amber-50\/100 {
  border-bottom-color: rgb(255 251 235 / 1) !important;
}

.tw-border-b-amber-50\/15 {
  border-bottom-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-b-amber-50\/20 {
  border-bottom-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-b-amber-50\/25 {
  border-bottom-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-b-amber-50\/30 {
  border-bottom-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-b-amber-50\/35 {
  border-bottom-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-b-amber-50\/40 {
  border-bottom-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-b-amber-50\/45 {
  border-bottom-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-b-amber-50\/5 {
  border-bottom-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-b-amber-50\/50 {
  border-bottom-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-b-amber-50\/55 {
  border-bottom-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-b-amber-50\/60 {
  border-bottom-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-b-amber-50\/65 {
  border-bottom-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-b-amber-50\/70 {
  border-bottom-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-b-amber-50\/75 {
  border-bottom-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-b-amber-50\/80 {
  border-bottom-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-b-amber-50\/85 {
  border-bottom-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-b-amber-50\/90 {
  border-bottom-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-b-amber-50\/95 {
  border-bottom-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-b-amber-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-500\/0 {
  border-bottom-color: rgb(245 158 11 / 0) !important;
}

.tw-border-b-amber-500\/10 {
  border-bottom-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-b-amber-500\/100 {
  border-bottom-color: rgb(245 158 11 / 1) !important;
}

.tw-border-b-amber-500\/15 {
  border-bottom-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-b-amber-500\/20 {
  border-bottom-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-b-amber-500\/25 {
  border-bottom-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-b-amber-500\/30 {
  border-bottom-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-b-amber-500\/35 {
  border-bottom-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-b-amber-500\/40 {
  border-bottom-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-b-amber-500\/45 {
  border-bottom-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-b-amber-500\/5 {
  border-bottom-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-b-amber-500\/50 {
  border-bottom-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-b-amber-500\/55 {
  border-bottom-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-b-amber-500\/60 {
  border-bottom-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-b-amber-500\/65 {
  border-bottom-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-b-amber-500\/70 {
  border-bottom-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-b-amber-500\/75 {
  border-bottom-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-b-amber-500\/80 {
  border-bottom-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-b-amber-500\/85 {
  border-bottom-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-b-amber-500\/90 {
  border-bottom-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-b-amber-500\/95 {
  border-bottom-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-b-amber-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-600\/0 {
  border-bottom-color: rgb(217 119 6 / 0) !important;
}

.tw-border-b-amber-600\/10 {
  border-bottom-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-b-amber-600\/100 {
  border-bottom-color: rgb(217 119 6 / 1) !important;
}

.tw-border-b-amber-600\/15 {
  border-bottom-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-b-amber-600\/20 {
  border-bottom-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-b-amber-600\/25 {
  border-bottom-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-b-amber-600\/30 {
  border-bottom-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-b-amber-600\/35 {
  border-bottom-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-b-amber-600\/40 {
  border-bottom-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-b-amber-600\/45 {
  border-bottom-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-b-amber-600\/5 {
  border-bottom-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-b-amber-600\/50 {
  border-bottom-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-b-amber-600\/55 {
  border-bottom-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-b-amber-600\/60 {
  border-bottom-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-b-amber-600\/65 {
  border-bottom-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-b-amber-600\/70 {
  border-bottom-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-b-amber-600\/75 {
  border-bottom-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-b-amber-600\/80 {
  border-bottom-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-b-amber-600\/85 {
  border-bottom-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-b-amber-600\/90 {
  border-bottom-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-b-amber-600\/95 {
  border-bottom-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-b-amber-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-700\/0 {
  border-bottom-color: rgb(180 83 9 / 0) !important;
}

.tw-border-b-amber-700\/10 {
  border-bottom-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-b-amber-700\/100 {
  border-bottom-color: rgb(180 83 9 / 1) !important;
}

.tw-border-b-amber-700\/15 {
  border-bottom-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-b-amber-700\/20 {
  border-bottom-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-b-amber-700\/25 {
  border-bottom-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-b-amber-700\/30 {
  border-bottom-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-b-amber-700\/35 {
  border-bottom-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-b-amber-700\/40 {
  border-bottom-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-b-amber-700\/45 {
  border-bottom-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-b-amber-700\/5 {
  border-bottom-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-b-amber-700\/50 {
  border-bottom-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-b-amber-700\/55 {
  border-bottom-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-b-amber-700\/60 {
  border-bottom-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-b-amber-700\/65 {
  border-bottom-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-b-amber-700\/70 {
  border-bottom-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-b-amber-700\/75 {
  border-bottom-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-b-amber-700\/80 {
  border-bottom-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-b-amber-700\/85 {
  border-bottom-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-b-amber-700\/90 {
  border-bottom-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-b-amber-700\/95 {
  border-bottom-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-b-amber-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-800\/0 {
  border-bottom-color: rgb(146 64 14 / 0) !important;
}

.tw-border-b-amber-800\/10 {
  border-bottom-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-b-amber-800\/100 {
  border-bottom-color: rgb(146 64 14 / 1) !important;
}

.tw-border-b-amber-800\/15 {
  border-bottom-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-b-amber-800\/20 {
  border-bottom-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-b-amber-800\/25 {
  border-bottom-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-b-amber-800\/30 {
  border-bottom-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-b-amber-800\/35 {
  border-bottom-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-b-amber-800\/40 {
  border-bottom-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-b-amber-800\/45 {
  border-bottom-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-b-amber-800\/5 {
  border-bottom-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-b-amber-800\/50 {
  border-bottom-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-b-amber-800\/55 {
  border-bottom-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-b-amber-800\/60 {
  border-bottom-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-b-amber-800\/65 {
  border-bottom-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-b-amber-800\/70 {
  border-bottom-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-b-amber-800\/75 {
  border-bottom-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-b-amber-800\/80 {
  border-bottom-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-b-amber-800\/85 {
  border-bottom-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-b-amber-800\/90 {
  border-bottom-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-b-amber-800\/95 {
  border-bottom-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-b-amber-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-900\/0 {
  border-bottom-color: rgb(120 53 15 / 0) !important;
}

.tw-border-b-amber-900\/10 {
  border-bottom-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-b-amber-900\/100 {
  border-bottom-color: rgb(120 53 15 / 1) !important;
}

.tw-border-b-amber-900\/15 {
  border-bottom-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-b-amber-900\/20 {
  border-bottom-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-b-amber-900\/25 {
  border-bottom-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-b-amber-900\/30 {
  border-bottom-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-b-amber-900\/35 {
  border-bottom-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-b-amber-900\/40 {
  border-bottom-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-b-amber-900\/45 {
  border-bottom-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-b-amber-900\/5 {
  border-bottom-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-b-amber-900\/50 {
  border-bottom-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-b-amber-900\/55 {
  border-bottom-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-b-amber-900\/60 {
  border-bottom-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-b-amber-900\/65 {
  border-bottom-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-b-amber-900\/70 {
  border-bottom-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-b-amber-900\/75 {
  border-bottom-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-b-amber-900\/80 {
  border-bottom-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-b-amber-900\/85 {
  border-bottom-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-b-amber-900\/90 {
  border-bottom-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-b-amber-900\/95 {
  border-bottom-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-b-amber-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-b-amber-950\/0 {
  border-bottom-color: rgb(69 26 3 / 0) !important;
}

.tw-border-b-amber-950\/10 {
  border-bottom-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-b-amber-950\/100 {
  border-bottom-color: rgb(69 26 3 / 1) !important;
}

.tw-border-b-amber-950\/15 {
  border-bottom-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-b-amber-950\/20 {
  border-bottom-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-b-amber-950\/25 {
  border-bottom-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-b-amber-950\/30 {
  border-bottom-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-b-amber-950\/35 {
  border-bottom-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-b-amber-950\/40 {
  border-bottom-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-b-amber-950\/45 {
  border-bottom-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-b-amber-950\/5 {
  border-bottom-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-b-amber-950\/50 {
  border-bottom-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-b-amber-950\/55 {
  border-bottom-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-b-amber-950\/60 {
  border-bottom-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-b-amber-950\/65 {
  border-bottom-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-b-amber-950\/70 {
  border-bottom-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-b-amber-950\/75 {
  border-bottom-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-b-amber-950\/80 {
  border-bottom-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-b-amber-950\/85 {
  border-bottom-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-b-amber-950\/90 {
  border-bottom-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-b-amber-950\/95 {
  border-bottom-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-b-black {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-b-black\/0 {
  border-bottom-color: rgb(0 0 0 / 0) !important;
}

.tw-border-b-black\/10 {
  border-bottom-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-b-black\/100 {
  border-bottom-color: rgb(0 0 0 / 1) !important;
}

.tw-border-b-black\/15 {
  border-bottom-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-b-black\/20 {
  border-bottom-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-b-black\/25 {
  border-bottom-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-b-black\/30 {
  border-bottom-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-b-black\/35 {
  border-bottom-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-b-black\/40 {
  border-bottom-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-b-black\/45 {
  border-bottom-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-b-black\/5 {
  border-bottom-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-b-black\/50 {
  border-bottom-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-b-black\/55 {
  border-bottom-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-b-black\/60 {
  border-bottom-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-b-black\/65 {
  border-bottom-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-b-black\/70 {
  border-bottom-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-b-black\/75 {
  border-bottom-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-b-black\/80 {
  border-bottom-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-b-black\/85 {
  border-bottom-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-b-black\/90 {
  border-bottom-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-b-black\/95 {
  border-bottom-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-b-blue-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-100\/0 {
  border-bottom-color: rgb(219 234 254 / 0) !important;
}

.tw-border-b-blue-100\/10 {
  border-bottom-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-b-blue-100\/100 {
  border-bottom-color: rgb(219 234 254 / 1) !important;
}

.tw-border-b-blue-100\/15 {
  border-bottom-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-b-blue-100\/20 {
  border-bottom-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-b-blue-100\/25 {
  border-bottom-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-b-blue-100\/30 {
  border-bottom-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-b-blue-100\/35 {
  border-bottom-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-b-blue-100\/40 {
  border-bottom-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-b-blue-100\/45 {
  border-bottom-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-b-blue-100\/5 {
  border-bottom-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-b-blue-100\/50 {
  border-bottom-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-b-blue-100\/55 {
  border-bottom-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-b-blue-100\/60 {
  border-bottom-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-b-blue-100\/65 {
  border-bottom-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-b-blue-100\/70 {
  border-bottom-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-b-blue-100\/75 {
  border-bottom-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-b-blue-100\/80 {
  border-bottom-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-b-blue-100\/85 {
  border-bottom-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-b-blue-100\/90 {
  border-bottom-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-b-blue-100\/95 {
  border-bottom-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-b-blue-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-200\/0 {
  border-bottom-color: rgb(191 219 254 / 0) !important;
}

.tw-border-b-blue-200\/10 {
  border-bottom-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-b-blue-200\/100 {
  border-bottom-color: rgb(191 219 254 / 1) !important;
}

.tw-border-b-blue-200\/15 {
  border-bottom-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-b-blue-200\/20 {
  border-bottom-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-b-blue-200\/25 {
  border-bottom-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-b-blue-200\/30 {
  border-bottom-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-b-blue-200\/35 {
  border-bottom-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-b-blue-200\/40 {
  border-bottom-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-b-blue-200\/45 {
  border-bottom-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-b-blue-200\/5 {
  border-bottom-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-b-blue-200\/50 {
  border-bottom-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-b-blue-200\/55 {
  border-bottom-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-b-blue-200\/60 {
  border-bottom-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-b-blue-200\/65 {
  border-bottom-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-b-blue-200\/70 {
  border-bottom-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-b-blue-200\/75 {
  border-bottom-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-b-blue-200\/80 {
  border-bottom-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-b-blue-200\/85 {
  border-bottom-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-b-blue-200\/90 {
  border-bottom-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-b-blue-200\/95 {
  border-bottom-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-b-blue-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-300\/0 {
  border-bottom-color: rgb(147 197 253 / 0) !important;
}

.tw-border-b-blue-300\/10 {
  border-bottom-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-b-blue-300\/100 {
  border-bottom-color: rgb(147 197 253 / 1) !important;
}

.tw-border-b-blue-300\/15 {
  border-bottom-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-b-blue-300\/20 {
  border-bottom-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-b-blue-300\/25 {
  border-bottom-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-b-blue-300\/30 {
  border-bottom-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-b-blue-300\/35 {
  border-bottom-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-b-blue-300\/40 {
  border-bottom-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-b-blue-300\/45 {
  border-bottom-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-b-blue-300\/5 {
  border-bottom-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-b-blue-300\/50 {
  border-bottom-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-b-blue-300\/55 {
  border-bottom-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-b-blue-300\/60 {
  border-bottom-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-b-blue-300\/65 {
  border-bottom-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-b-blue-300\/70 {
  border-bottom-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-b-blue-300\/75 {
  border-bottom-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-b-blue-300\/80 {
  border-bottom-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-b-blue-300\/85 {
  border-bottom-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-b-blue-300\/90 {
  border-bottom-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-b-blue-300\/95 {
  border-bottom-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-b-blue-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-400\/0 {
  border-bottom-color: rgb(96 165 250 / 0) !important;
}

.tw-border-b-blue-400\/10 {
  border-bottom-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-b-blue-400\/100 {
  border-bottom-color: rgb(96 165 250 / 1) !important;
}

.tw-border-b-blue-400\/15 {
  border-bottom-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-b-blue-400\/20 {
  border-bottom-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-b-blue-400\/25 {
  border-bottom-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-b-blue-400\/30 {
  border-bottom-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-b-blue-400\/35 {
  border-bottom-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-b-blue-400\/40 {
  border-bottom-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-b-blue-400\/45 {
  border-bottom-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-b-blue-400\/5 {
  border-bottom-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-b-blue-400\/50 {
  border-bottom-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-b-blue-400\/55 {
  border-bottom-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-b-blue-400\/60 {
  border-bottom-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-b-blue-400\/65 {
  border-bottom-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-b-blue-400\/70 {
  border-bottom-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-b-blue-400\/75 {
  border-bottom-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-b-blue-400\/80 {
  border-bottom-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-b-blue-400\/85 {
  border-bottom-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-b-blue-400\/90 {
  border-bottom-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-b-blue-400\/95 {
  border-bottom-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-b-blue-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-50\/0 {
  border-bottom-color: rgb(239 246 255 / 0) !important;
}

.tw-border-b-blue-50\/10 {
  border-bottom-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-b-blue-50\/100 {
  border-bottom-color: rgb(239 246 255 / 1) !important;
}

.tw-border-b-blue-50\/15 {
  border-bottom-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-b-blue-50\/20 {
  border-bottom-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-b-blue-50\/25 {
  border-bottom-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-b-blue-50\/30 {
  border-bottom-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-b-blue-50\/35 {
  border-bottom-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-b-blue-50\/40 {
  border-bottom-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-b-blue-50\/45 {
  border-bottom-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-b-blue-50\/5 {
  border-bottom-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-b-blue-50\/50 {
  border-bottom-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-b-blue-50\/55 {
  border-bottom-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-b-blue-50\/60 {
  border-bottom-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-b-blue-50\/65 {
  border-bottom-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-b-blue-50\/70 {
  border-bottom-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-b-blue-50\/75 {
  border-bottom-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-b-blue-50\/80 {
  border-bottom-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-b-blue-50\/85 {
  border-bottom-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-b-blue-50\/90 {
  border-bottom-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-b-blue-50\/95 {
  border-bottom-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-b-blue-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-500\/0 {
  border-bottom-color: rgb(59 130 246 / 0) !important;
}

.tw-border-b-blue-500\/10 {
  border-bottom-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-b-blue-500\/100 {
  border-bottom-color: rgb(59 130 246 / 1) !important;
}

.tw-border-b-blue-500\/15 {
  border-bottom-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-b-blue-500\/20 {
  border-bottom-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-b-blue-500\/25 {
  border-bottom-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-b-blue-500\/30 {
  border-bottom-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-b-blue-500\/35 {
  border-bottom-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-b-blue-500\/40 {
  border-bottom-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-b-blue-500\/45 {
  border-bottom-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-b-blue-500\/5 {
  border-bottom-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-b-blue-500\/50 {
  border-bottom-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-b-blue-500\/55 {
  border-bottom-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-b-blue-500\/60 {
  border-bottom-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-b-blue-500\/65 {
  border-bottom-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-b-blue-500\/70 {
  border-bottom-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-b-blue-500\/75 {
  border-bottom-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-b-blue-500\/80 {
  border-bottom-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-b-blue-500\/85 {
  border-bottom-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-b-blue-500\/90 {
  border-bottom-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-b-blue-500\/95 {
  border-bottom-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-b-blue-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-600\/0 {
  border-bottom-color: rgb(37 99 235 / 0) !important;
}

.tw-border-b-blue-600\/10 {
  border-bottom-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-b-blue-600\/100 {
  border-bottom-color: rgb(37 99 235 / 1) !important;
}

.tw-border-b-blue-600\/15 {
  border-bottom-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-b-blue-600\/20 {
  border-bottom-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-b-blue-600\/25 {
  border-bottom-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-b-blue-600\/30 {
  border-bottom-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-b-blue-600\/35 {
  border-bottom-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-b-blue-600\/40 {
  border-bottom-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-b-blue-600\/45 {
  border-bottom-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-b-blue-600\/5 {
  border-bottom-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-b-blue-600\/50 {
  border-bottom-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-b-blue-600\/55 {
  border-bottom-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-b-blue-600\/60 {
  border-bottom-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-b-blue-600\/65 {
  border-bottom-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-b-blue-600\/70 {
  border-bottom-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-b-blue-600\/75 {
  border-bottom-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-b-blue-600\/80 {
  border-bottom-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-b-blue-600\/85 {
  border-bottom-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-b-blue-600\/90 {
  border-bottom-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-b-blue-600\/95 {
  border-bottom-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-b-blue-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-700\/0 {
  border-bottom-color: rgb(29 78 216 / 0) !important;
}

.tw-border-b-blue-700\/10 {
  border-bottom-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-b-blue-700\/100 {
  border-bottom-color: rgb(29 78 216 / 1) !important;
}

.tw-border-b-blue-700\/15 {
  border-bottom-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-b-blue-700\/20 {
  border-bottom-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-b-blue-700\/25 {
  border-bottom-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-b-blue-700\/30 {
  border-bottom-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-b-blue-700\/35 {
  border-bottom-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-b-blue-700\/40 {
  border-bottom-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-b-blue-700\/45 {
  border-bottom-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-b-blue-700\/5 {
  border-bottom-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-b-blue-700\/50 {
  border-bottom-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-b-blue-700\/55 {
  border-bottom-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-b-blue-700\/60 {
  border-bottom-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-b-blue-700\/65 {
  border-bottom-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-b-blue-700\/70 {
  border-bottom-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-b-blue-700\/75 {
  border-bottom-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-b-blue-700\/80 {
  border-bottom-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-b-blue-700\/85 {
  border-bottom-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-b-blue-700\/90 {
  border-bottom-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-b-blue-700\/95 {
  border-bottom-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-b-blue-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-800\/0 {
  border-bottom-color: rgb(30 64 175 / 0) !important;
}

.tw-border-b-blue-800\/10 {
  border-bottom-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-b-blue-800\/100 {
  border-bottom-color: rgb(30 64 175 / 1) !important;
}

.tw-border-b-blue-800\/15 {
  border-bottom-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-b-blue-800\/20 {
  border-bottom-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-b-blue-800\/25 {
  border-bottom-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-b-blue-800\/30 {
  border-bottom-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-b-blue-800\/35 {
  border-bottom-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-b-blue-800\/40 {
  border-bottom-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-b-blue-800\/45 {
  border-bottom-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-b-blue-800\/5 {
  border-bottom-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-b-blue-800\/50 {
  border-bottom-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-b-blue-800\/55 {
  border-bottom-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-b-blue-800\/60 {
  border-bottom-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-b-blue-800\/65 {
  border-bottom-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-b-blue-800\/70 {
  border-bottom-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-b-blue-800\/75 {
  border-bottom-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-b-blue-800\/80 {
  border-bottom-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-b-blue-800\/85 {
  border-bottom-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-b-blue-800\/90 {
  border-bottom-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-b-blue-800\/95 {
  border-bottom-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-b-blue-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-900\/0 {
  border-bottom-color: rgb(30 58 138 / 0) !important;
}

.tw-border-b-blue-900\/10 {
  border-bottom-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-b-blue-900\/100 {
  border-bottom-color: rgb(30 58 138 / 1) !important;
}

.tw-border-b-blue-900\/15 {
  border-bottom-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-b-blue-900\/20 {
  border-bottom-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-b-blue-900\/25 {
  border-bottom-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-b-blue-900\/30 {
  border-bottom-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-b-blue-900\/35 {
  border-bottom-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-b-blue-900\/40 {
  border-bottom-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-b-blue-900\/45 {
  border-bottom-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-b-blue-900\/5 {
  border-bottom-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-b-blue-900\/50 {
  border-bottom-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-b-blue-900\/55 {
  border-bottom-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-b-blue-900\/60 {
  border-bottom-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-b-blue-900\/65 {
  border-bottom-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-b-blue-900\/70 {
  border-bottom-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-b-blue-900\/75 {
  border-bottom-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-b-blue-900\/80 {
  border-bottom-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-b-blue-900\/85 {
  border-bottom-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-b-blue-900\/90 {
  border-bottom-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-b-blue-900\/95 {
  border-bottom-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-b-blue-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-b-blue-950\/0 {
  border-bottom-color: rgb(23 37 84 / 0) !important;
}

.tw-border-b-blue-950\/10 {
  border-bottom-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-b-blue-950\/100 {
  border-bottom-color: rgb(23 37 84 / 1) !important;
}

.tw-border-b-blue-950\/15 {
  border-bottom-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-b-blue-950\/20 {
  border-bottom-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-b-blue-950\/25 {
  border-bottom-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-b-blue-950\/30 {
  border-bottom-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-b-blue-950\/35 {
  border-bottom-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-b-blue-950\/40 {
  border-bottom-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-b-blue-950\/45 {
  border-bottom-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-b-blue-950\/5 {
  border-bottom-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-b-blue-950\/50 {
  border-bottom-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-b-blue-950\/55 {
  border-bottom-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-b-blue-950\/60 {
  border-bottom-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-b-blue-950\/65 {
  border-bottom-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-b-blue-950\/70 {
  border-bottom-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-b-blue-950\/75 {
  border-bottom-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-b-blue-950\/80 {
  border-bottom-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-b-blue-950\/85 {
  border-bottom-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-b-blue-950\/90 {
  border-bottom-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-b-blue-950\/95 {
  border-bottom-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-b-current {
  border-bottom-color: currentColor !important;
}

.tw-border-b-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-100\/0 {
  border-bottom-color: rgb(207 250 254 / 0) !important;
}

.tw-border-b-cyan-100\/10 {
  border-bottom-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-b-cyan-100\/100 {
  border-bottom-color: rgb(207 250 254 / 1) !important;
}

.tw-border-b-cyan-100\/15 {
  border-bottom-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-b-cyan-100\/20 {
  border-bottom-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-b-cyan-100\/25 {
  border-bottom-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-b-cyan-100\/30 {
  border-bottom-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-b-cyan-100\/35 {
  border-bottom-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-b-cyan-100\/40 {
  border-bottom-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-b-cyan-100\/45 {
  border-bottom-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-b-cyan-100\/5 {
  border-bottom-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-b-cyan-100\/50 {
  border-bottom-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-b-cyan-100\/55 {
  border-bottom-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-b-cyan-100\/60 {
  border-bottom-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-b-cyan-100\/65 {
  border-bottom-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-b-cyan-100\/70 {
  border-bottom-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-b-cyan-100\/75 {
  border-bottom-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-b-cyan-100\/80 {
  border-bottom-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-b-cyan-100\/85 {
  border-bottom-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-b-cyan-100\/90 {
  border-bottom-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-b-cyan-100\/95 {
  border-bottom-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-b-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-200\/0 {
  border-bottom-color: rgb(165 243 252 / 0) !important;
}

.tw-border-b-cyan-200\/10 {
  border-bottom-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-b-cyan-200\/100 {
  border-bottom-color: rgb(165 243 252 / 1) !important;
}

.tw-border-b-cyan-200\/15 {
  border-bottom-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-b-cyan-200\/20 {
  border-bottom-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-b-cyan-200\/25 {
  border-bottom-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-b-cyan-200\/30 {
  border-bottom-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-b-cyan-200\/35 {
  border-bottom-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-b-cyan-200\/40 {
  border-bottom-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-b-cyan-200\/45 {
  border-bottom-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-b-cyan-200\/5 {
  border-bottom-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-b-cyan-200\/50 {
  border-bottom-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-b-cyan-200\/55 {
  border-bottom-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-b-cyan-200\/60 {
  border-bottom-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-b-cyan-200\/65 {
  border-bottom-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-b-cyan-200\/70 {
  border-bottom-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-b-cyan-200\/75 {
  border-bottom-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-b-cyan-200\/80 {
  border-bottom-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-b-cyan-200\/85 {
  border-bottom-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-b-cyan-200\/90 {
  border-bottom-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-b-cyan-200\/95 {
  border-bottom-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-b-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-300\/0 {
  border-bottom-color: rgb(103 232 249 / 0) !important;
}

.tw-border-b-cyan-300\/10 {
  border-bottom-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-b-cyan-300\/100 {
  border-bottom-color: rgb(103 232 249 / 1) !important;
}

.tw-border-b-cyan-300\/15 {
  border-bottom-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-b-cyan-300\/20 {
  border-bottom-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-b-cyan-300\/25 {
  border-bottom-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-b-cyan-300\/30 {
  border-bottom-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-b-cyan-300\/35 {
  border-bottom-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-b-cyan-300\/40 {
  border-bottom-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-b-cyan-300\/45 {
  border-bottom-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-b-cyan-300\/5 {
  border-bottom-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-b-cyan-300\/50 {
  border-bottom-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-b-cyan-300\/55 {
  border-bottom-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-b-cyan-300\/60 {
  border-bottom-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-b-cyan-300\/65 {
  border-bottom-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-b-cyan-300\/70 {
  border-bottom-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-b-cyan-300\/75 {
  border-bottom-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-b-cyan-300\/80 {
  border-bottom-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-b-cyan-300\/85 {
  border-bottom-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-b-cyan-300\/90 {
  border-bottom-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-b-cyan-300\/95 {
  border-bottom-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-b-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-400\/0 {
  border-bottom-color: rgb(34 211 238 / 0) !important;
}

.tw-border-b-cyan-400\/10 {
  border-bottom-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-b-cyan-400\/100 {
  border-bottom-color: rgb(34 211 238 / 1) !important;
}

.tw-border-b-cyan-400\/15 {
  border-bottom-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-b-cyan-400\/20 {
  border-bottom-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-b-cyan-400\/25 {
  border-bottom-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-b-cyan-400\/30 {
  border-bottom-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-b-cyan-400\/35 {
  border-bottom-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-b-cyan-400\/40 {
  border-bottom-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-b-cyan-400\/45 {
  border-bottom-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-b-cyan-400\/5 {
  border-bottom-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-b-cyan-400\/50 {
  border-bottom-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-b-cyan-400\/55 {
  border-bottom-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-b-cyan-400\/60 {
  border-bottom-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-b-cyan-400\/65 {
  border-bottom-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-b-cyan-400\/70 {
  border-bottom-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-b-cyan-400\/75 {
  border-bottom-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-b-cyan-400\/80 {
  border-bottom-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-b-cyan-400\/85 {
  border-bottom-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-b-cyan-400\/90 {
  border-bottom-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-b-cyan-400\/95 {
  border-bottom-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-b-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-50\/0 {
  border-bottom-color: rgb(236 254 255 / 0) !important;
}

.tw-border-b-cyan-50\/10 {
  border-bottom-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-b-cyan-50\/100 {
  border-bottom-color: rgb(236 254 255 / 1) !important;
}

.tw-border-b-cyan-50\/15 {
  border-bottom-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-b-cyan-50\/20 {
  border-bottom-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-b-cyan-50\/25 {
  border-bottom-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-b-cyan-50\/30 {
  border-bottom-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-b-cyan-50\/35 {
  border-bottom-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-b-cyan-50\/40 {
  border-bottom-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-b-cyan-50\/45 {
  border-bottom-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-b-cyan-50\/5 {
  border-bottom-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-b-cyan-50\/50 {
  border-bottom-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-b-cyan-50\/55 {
  border-bottom-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-b-cyan-50\/60 {
  border-bottom-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-b-cyan-50\/65 {
  border-bottom-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-b-cyan-50\/70 {
  border-bottom-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-b-cyan-50\/75 {
  border-bottom-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-b-cyan-50\/80 {
  border-bottom-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-b-cyan-50\/85 {
  border-bottom-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-b-cyan-50\/90 {
  border-bottom-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-b-cyan-50\/95 {
  border-bottom-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-b-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-500\/0 {
  border-bottom-color: rgb(6 182 212 / 0) !important;
}

.tw-border-b-cyan-500\/10 {
  border-bottom-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-b-cyan-500\/100 {
  border-bottom-color: rgb(6 182 212 / 1) !important;
}

.tw-border-b-cyan-500\/15 {
  border-bottom-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-b-cyan-500\/20 {
  border-bottom-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-b-cyan-500\/25 {
  border-bottom-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-b-cyan-500\/30 {
  border-bottom-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-b-cyan-500\/35 {
  border-bottom-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-b-cyan-500\/40 {
  border-bottom-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-b-cyan-500\/45 {
  border-bottom-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-b-cyan-500\/5 {
  border-bottom-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-b-cyan-500\/50 {
  border-bottom-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-b-cyan-500\/55 {
  border-bottom-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-b-cyan-500\/60 {
  border-bottom-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-b-cyan-500\/65 {
  border-bottom-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-b-cyan-500\/70 {
  border-bottom-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-b-cyan-500\/75 {
  border-bottom-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-b-cyan-500\/80 {
  border-bottom-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-b-cyan-500\/85 {
  border-bottom-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-b-cyan-500\/90 {
  border-bottom-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-b-cyan-500\/95 {
  border-bottom-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-b-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-600\/0 {
  border-bottom-color: rgb(8 145 178 / 0) !important;
}

.tw-border-b-cyan-600\/10 {
  border-bottom-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-b-cyan-600\/100 {
  border-bottom-color: rgb(8 145 178 / 1) !important;
}

.tw-border-b-cyan-600\/15 {
  border-bottom-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-b-cyan-600\/20 {
  border-bottom-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-b-cyan-600\/25 {
  border-bottom-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-b-cyan-600\/30 {
  border-bottom-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-b-cyan-600\/35 {
  border-bottom-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-b-cyan-600\/40 {
  border-bottom-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-b-cyan-600\/45 {
  border-bottom-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-b-cyan-600\/5 {
  border-bottom-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-b-cyan-600\/50 {
  border-bottom-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-b-cyan-600\/55 {
  border-bottom-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-b-cyan-600\/60 {
  border-bottom-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-b-cyan-600\/65 {
  border-bottom-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-b-cyan-600\/70 {
  border-bottom-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-b-cyan-600\/75 {
  border-bottom-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-b-cyan-600\/80 {
  border-bottom-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-b-cyan-600\/85 {
  border-bottom-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-b-cyan-600\/90 {
  border-bottom-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-b-cyan-600\/95 {
  border-bottom-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-b-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-700\/0 {
  border-bottom-color: rgb(14 116 144 / 0) !important;
}

.tw-border-b-cyan-700\/10 {
  border-bottom-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-b-cyan-700\/100 {
  border-bottom-color: rgb(14 116 144 / 1) !important;
}

.tw-border-b-cyan-700\/15 {
  border-bottom-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-b-cyan-700\/20 {
  border-bottom-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-b-cyan-700\/25 {
  border-bottom-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-b-cyan-700\/30 {
  border-bottom-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-b-cyan-700\/35 {
  border-bottom-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-b-cyan-700\/40 {
  border-bottom-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-b-cyan-700\/45 {
  border-bottom-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-b-cyan-700\/5 {
  border-bottom-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-b-cyan-700\/50 {
  border-bottom-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-b-cyan-700\/55 {
  border-bottom-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-b-cyan-700\/60 {
  border-bottom-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-b-cyan-700\/65 {
  border-bottom-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-b-cyan-700\/70 {
  border-bottom-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-b-cyan-700\/75 {
  border-bottom-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-b-cyan-700\/80 {
  border-bottom-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-b-cyan-700\/85 {
  border-bottom-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-b-cyan-700\/90 {
  border-bottom-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-b-cyan-700\/95 {
  border-bottom-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-b-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-800\/0 {
  border-bottom-color: rgb(21 94 117 / 0) !important;
}

.tw-border-b-cyan-800\/10 {
  border-bottom-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-b-cyan-800\/100 {
  border-bottom-color: rgb(21 94 117 / 1) !important;
}

.tw-border-b-cyan-800\/15 {
  border-bottom-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-b-cyan-800\/20 {
  border-bottom-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-b-cyan-800\/25 {
  border-bottom-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-b-cyan-800\/30 {
  border-bottom-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-b-cyan-800\/35 {
  border-bottom-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-b-cyan-800\/40 {
  border-bottom-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-b-cyan-800\/45 {
  border-bottom-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-b-cyan-800\/5 {
  border-bottom-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-b-cyan-800\/50 {
  border-bottom-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-b-cyan-800\/55 {
  border-bottom-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-b-cyan-800\/60 {
  border-bottom-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-b-cyan-800\/65 {
  border-bottom-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-b-cyan-800\/70 {
  border-bottom-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-b-cyan-800\/75 {
  border-bottom-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-b-cyan-800\/80 {
  border-bottom-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-b-cyan-800\/85 {
  border-bottom-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-b-cyan-800\/90 {
  border-bottom-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-b-cyan-800\/95 {
  border-bottom-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-b-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-900\/0 {
  border-bottom-color: rgb(22 78 99 / 0) !important;
}

.tw-border-b-cyan-900\/10 {
  border-bottom-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-b-cyan-900\/100 {
  border-bottom-color: rgb(22 78 99 / 1) !important;
}

.tw-border-b-cyan-900\/15 {
  border-bottom-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-b-cyan-900\/20 {
  border-bottom-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-b-cyan-900\/25 {
  border-bottom-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-b-cyan-900\/30 {
  border-bottom-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-b-cyan-900\/35 {
  border-bottom-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-b-cyan-900\/40 {
  border-bottom-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-b-cyan-900\/45 {
  border-bottom-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-b-cyan-900\/5 {
  border-bottom-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-b-cyan-900\/50 {
  border-bottom-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-b-cyan-900\/55 {
  border-bottom-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-b-cyan-900\/60 {
  border-bottom-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-b-cyan-900\/65 {
  border-bottom-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-b-cyan-900\/70 {
  border-bottom-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-b-cyan-900\/75 {
  border-bottom-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-b-cyan-900\/80 {
  border-bottom-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-b-cyan-900\/85 {
  border-bottom-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-b-cyan-900\/90 {
  border-bottom-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-b-cyan-900\/95 {
  border-bottom-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-b-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-b-cyan-950\/0 {
  border-bottom-color: rgb(8 51 68 / 0) !important;
}

.tw-border-b-cyan-950\/10 {
  border-bottom-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-b-cyan-950\/100 {
  border-bottom-color: rgb(8 51 68 / 1) !important;
}

.tw-border-b-cyan-950\/15 {
  border-bottom-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-b-cyan-950\/20 {
  border-bottom-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-b-cyan-950\/25 {
  border-bottom-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-b-cyan-950\/30 {
  border-bottom-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-b-cyan-950\/35 {
  border-bottom-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-b-cyan-950\/40 {
  border-bottom-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-b-cyan-950\/45 {
  border-bottom-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-b-cyan-950\/5 {
  border-bottom-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-b-cyan-950\/50 {
  border-bottom-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-b-cyan-950\/55 {
  border-bottom-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-b-cyan-950\/60 {
  border-bottom-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-b-cyan-950\/65 {
  border-bottom-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-b-cyan-950\/70 {
  border-bottom-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-b-cyan-950\/75 {
  border-bottom-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-b-cyan-950\/80 {
  border-bottom-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-b-cyan-950\/85 {
  border-bottom-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-b-cyan-950\/90 {
  border-bottom-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-b-cyan-950\/95 {
  border-bottom-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-b-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-100\/0 {
  border-bottom-color: rgb(209 250 229 / 0) !important;
}

.tw-border-b-emerald-100\/10 {
  border-bottom-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-b-emerald-100\/100 {
  border-bottom-color: rgb(209 250 229 / 1) !important;
}

.tw-border-b-emerald-100\/15 {
  border-bottom-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-b-emerald-100\/20 {
  border-bottom-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-b-emerald-100\/25 {
  border-bottom-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-b-emerald-100\/30 {
  border-bottom-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-b-emerald-100\/35 {
  border-bottom-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-b-emerald-100\/40 {
  border-bottom-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-b-emerald-100\/45 {
  border-bottom-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-b-emerald-100\/5 {
  border-bottom-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-b-emerald-100\/50 {
  border-bottom-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-b-emerald-100\/55 {
  border-bottom-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-b-emerald-100\/60 {
  border-bottom-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-b-emerald-100\/65 {
  border-bottom-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-b-emerald-100\/70 {
  border-bottom-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-b-emerald-100\/75 {
  border-bottom-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-b-emerald-100\/80 {
  border-bottom-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-b-emerald-100\/85 {
  border-bottom-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-b-emerald-100\/90 {
  border-bottom-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-b-emerald-100\/95 {
  border-bottom-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-b-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-200\/0 {
  border-bottom-color: rgb(167 243 208 / 0) !important;
}

.tw-border-b-emerald-200\/10 {
  border-bottom-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-b-emerald-200\/100 {
  border-bottom-color: rgb(167 243 208 / 1) !important;
}

.tw-border-b-emerald-200\/15 {
  border-bottom-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-b-emerald-200\/20 {
  border-bottom-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-b-emerald-200\/25 {
  border-bottom-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-b-emerald-200\/30 {
  border-bottom-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-b-emerald-200\/35 {
  border-bottom-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-b-emerald-200\/40 {
  border-bottom-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-b-emerald-200\/45 {
  border-bottom-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-b-emerald-200\/5 {
  border-bottom-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-b-emerald-200\/50 {
  border-bottom-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-b-emerald-200\/55 {
  border-bottom-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-b-emerald-200\/60 {
  border-bottom-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-b-emerald-200\/65 {
  border-bottom-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-b-emerald-200\/70 {
  border-bottom-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-b-emerald-200\/75 {
  border-bottom-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-b-emerald-200\/80 {
  border-bottom-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-b-emerald-200\/85 {
  border-bottom-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-b-emerald-200\/90 {
  border-bottom-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-b-emerald-200\/95 {
  border-bottom-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-b-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-300\/0 {
  border-bottom-color: rgb(110 231 183 / 0) !important;
}

.tw-border-b-emerald-300\/10 {
  border-bottom-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-b-emerald-300\/100 {
  border-bottom-color: rgb(110 231 183 / 1) !important;
}

.tw-border-b-emerald-300\/15 {
  border-bottom-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-b-emerald-300\/20 {
  border-bottom-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-b-emerald-300\/25 {
  border-bottom-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-b-emerald-300\/30 {
  border-bottom-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-b-emerald-300\/35 {
  border-bottom-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-b-emerald-300\/40 {
  border-bottom-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-b-emerald-300\/45 {
  border-bottom-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-b-emerald-300\/5 {
  border-bottom-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-b-emerald-300\/50 {
  border-bottom-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-b-emerald-300\/55 {
  border-bottom-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-b-emerald-300\/60 {
  border-bottom-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-b-emerald-300\/65 {
  border-bottom-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-b-emerald-300\/70 {
  border-bottom-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-b-emerald-300\/75 {
  border-bottom-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-b-emerald-300\/80 {
  border-bottom-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-b-emerald-300\/85 {
  border-bottom-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-b-emerald-300\/90 {
  border-bottom-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-b-emerald-300\/95 {
  border-bottom-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-b-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-400\/0 {
  border-bottom-color: rgb(52 211 153 / 0) !important;
}

.tw-border-b-emerald-400\/10 {
  border-bottom-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-b-emerald-400\/100 {
  border-bottom-color: rgb(52 211 153 / 1) !important;
}

.tw-border-b-emerald-400\/15 {
  border-bottom-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-b-emerald-400\/20 {
  border-bottom-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-b-emerald-400\/25 {
  border-bottom-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-b-emerald-400\/30 {
  border-bottom-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-b-emerald-400\/35 {
  border-bottom-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-b-emerald-400\/40 {
  border-bottom-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-b-emerald-400\/45 {
  border-bottom-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-b-emerald-400\/5 {
  border-bottom-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-b-emerald-400\/50 {
  border-bottom-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-b-emerald-400\/55 {
  border-bottom-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-b-emerald-400\/60 {
  border-bottom-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-b-emerald-400\/65 {
  border-bottom-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-b-emerald-400\/70 {
  border-bottom-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-b-emerald-400\/75 {
  border-bottom-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-b-emerald-400\/80 {
  border-bottom-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-b-emerald-400\/85 {
  border-bottom-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-b-emerald-400\/90 {
  border-bottom-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-b-emerald-400\/95 {
  border-bottom-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-b-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-50\/0 {
  border-bottom-color: rgb(236 253 245 / 0) !important;
}

.tw-border-b-emerald-50\/10 {
  border-bottom-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-b-emerald-50\/100 {
  border-bottom-color: rgb(236 253 245 / 1) !important;
}

.tw-border-b-emerald-50\/15 {
  border-bottom-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-b-emerald-50\/20 {
  border-bottom-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-b-emerald-50\/25 {
  border-bottom-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-b-emerald-50\/30 {
  border-bottom-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-b-emerald-50\/35 {
  border-bottom-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-b-emerald-50\/40 {
  border-bottom-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-b-emerald-50\/45 {
  border-bottom-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-b-emerald-50\/5 {
  border-bottom-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-b-emerald-50\/50 {
  border-bottom-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-b-emerald-50\/55 {
  border-bottom-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-b-emerald-50\/60 {
  border-bottom-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-b-emerald-50\/65 {
  border-bottom-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-b-emerald-50\/70 {
  border-bottom-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-b-emerald-50\/75 {
  border-bottom-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-b-emerald-50\/80 {
  border-bottom-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-b-emerald-50\/85 {
  border-bottom-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-b-emerald-50\/90 {
  border-bottom-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-b-emerald-50\/95 {
  border-bottom-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-b-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-500\/0 {
  border-bottom-color: rgb(16 185 129 / 0) !important;
}

.tw-border-b-emerald-500\/10 {
  border-bottom-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-b-emerald-500\/100 {
  border-bottom-color: rgb(16 185 129 / 1) !important;
}

.tw-border-b-emerald-500\/15 {
  border-bottom-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-b-emerald-500\/20 {
  border-bottom-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-b-emerald-500\/25 {
  border-bottom-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-b-emerald-500\/30 {
  border-bottom-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-b-emerald-500\/35 {
  border-bottom-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-b-emerald-500\/40 {
  border-bottom-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-b-emerald-500\/45 {
  border-bottom-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-b-emerald-500\/5 {
  border-bottom-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-b-emerald-500\/50 {
  border-bottom-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-b-emerald-500\/55 {
  border-bottom-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-b-emerald-500\/60 {
  border-bottom-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-b-emerald-500\/65 {
  border-bottom-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-b-emerald-500\/70 {
  border-bottom-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-b-emerald-500\/75 {
  border-bottom-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-b-emerald-500\/80 {
  border-bottom-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-b-emerald-500\/85 {
  border-bottom-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-b-emerald-500\/90 {
  border-bottom-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-b-emerald-500\/95 {
  border-bottom-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-b-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-600\/0 {
  border-bottom-color: rgb(5 150 105 / 0) !important;
}

.tw-border-b-emerald-600\/10 {
  border-bottom-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-b-emerald-600\/100 {
  border-bottom-color: rgb(5 150 105 / 1) !important;
}

.tw-border-b-emerald-600\/15 {
  border-bottom-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-b-emerald-600\/20 {
  border-bottom-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-b-emerald-600\/25 {
  border-bottom-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-b-emerald-600\/30 {
  border-bottom-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-b-emerald-600\/35 {
  border-bottom-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-b-emerald-600\/40 {
  border-bottom-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-b-emerald-600\/45 {
  border-bottom-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-b-emerald-600\/5 {
  border-bottom-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-b-emerald-600\/50 {
  border-bottom-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-b-emerald-600\/55 {
  border-bottom-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-b-emerald-600\/60 {
  border-bottom-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-b-emerald-600\/65 {
  border-bottom-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-b-emerald-600\/70 {
  border-bottom-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-b-emerald-600\/75 {
  border-bottom-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-b-emerald-600\/80 {
  border-bottom-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-b-emerald-600\/85 {
  border-bottom-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-b-emerald-600\/90 {
  border-bottom-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-b-emerald-600\/95 {
  border-bottom-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-b-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-700\/0 {
  border-bottom-color: rgb(4 120 87 / 0) !important;
}

.tw-border-b-emerald-700\/10 {
  border-bottom-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-b-emerald-700\/100 {
  border-bottom-color: rgb(4 120 87 / 1) !important;
}

.tw-border-b-emerald-700\/15 {
  border-bottom-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-b-emerald-700\/20 {
  border-bottom-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-b-emerald-700\/25 {
  border-bottom-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-b-emerald-700\/30 {
  border-bottom-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-b-emerald-700\/35 {
  border-bottom-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-b-emerald-700\/40 {
  border-bottom-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-b-emerald-700\/45 {
  border-bottom-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-b-emerald-700\/5 {
  border-bottom-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-b-emerald-700\/50 {
  border-bottom-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-b-emerald-700\/55 {
  border-bottom-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-b-emerald-700\/60 {
  border-bottom-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-b-emerald-700\/65 {
  border-bottom-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-b-emerald-700\/70 {
  border-bottom-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-b-emerald-700\/75 {
  border-bottom-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-b-emerald-700\/80 {
  border-bottom-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-b-emerald-700\/85 {
  border-bottom-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-b-emerald-700\/90 {
  border-bottom-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-b-emerald-700\/95 {
  border-bottom-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-b-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-800\/0 {
  border-bottom-color: rgb(6 95 70 / 0) !important;
}

.tw-border-b-emerald-800\/10 {
  border-bottom-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-b-emerald-800\/100 {
  border-bottom-color: rgb(6 95 70 / 1) !important;
}

.tw-border-b-emerald-800\/15 {
  border-bottom-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-b-emerald-800\/20 {
  border-bottom-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-b-emerald-800\/25 {
  border-bottom-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-b-emerald-800\/30 {
  border-bottom-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-b-emerald-800\/35 {
  border-bottom-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-b-emerald-800\/40 {
  border-bottom-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-b-emerald-800\/45 {
  border-bottom-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-b-emerald-800\/5 {
  border-bottom-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-b-emerald-800\/50 {
  border-bottom-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-b-emerald-800\/55 {
  border-bottom-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-b-emerald-800\/60 {
  border-bottom-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-b-emerald-800\/65 {
  border-bottom-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-b-emerald-800\/70 {
  border-bottom-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-b-emerald-800\/75 {
  border-bottom-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-b-emerald-800\/80 {
  border-bottom-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-b-emerald-800\/85 {
  border-bottom-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-b-emerald-800\/90 {
  border-bottom-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-b-emerald-800\/95 {
  border-bottom-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-b-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-900\/0 {
  border-bottom-color: rgb(6 78 59 / 0) !important;
}

.tw-border-b-emerald-900\/10 {
  border-bottom-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-b-emerald-900\/100 {
  border-bottom-color: rgb(6 78 59 / 1) !important;
}

.tw-border-b-emerald-900\/15 {
  border-bottom-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-b-emerald-900\/20 {
  border-bottom-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-b-emerald-900\/25 {
  border-bottom-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-b-emerald-900\/30 {
  border-bottom-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-b-emerald-900\/35 {
  border-bottom-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-b-emerald-900\/40 {
  border-bottom-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-b-emerald-900\/45 {
  border-bottom-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-b-emerald-900\/5 {
  border-bottom-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-b-emerald-900\/50 {
  border-bottom-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-b-emerald-900\/55 {
  border-bottom-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-b-emerald-900\/60 {
  border-bottom-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-b-emerald-900\/65 {
  border-bottom-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-b-emerald-900\/70 {
  border-bottom-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-b-emerald-900\/75 {
  border-bottom-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-b-emerald-900\/80 {
  border-bottom-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-b-emerald-900\/85 {
  border-bottom-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-b-emerald-900\/90 {
  border-bottom-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-b-emerald-900\/95 {
  border-bottom-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-b-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-b-emerald-950\/0 {
  border-bottom-color: rgb(2 44 34 / 0) !important;
}

.tw-border-b-emerald-950\/10 {
  border-bottom-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-b-emerald-950\/100 {
  border-bottom-color: rgb(2 44 34 / 1) !important;
}

.tw-border-b-emerald-950\/15 {
  border-bottom-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-b-emerald-950\/20 {
  border-bottom-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-b-emerald-950\/25 {
  border-bottom-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-b-emerald-950\/30 {
  border-bottom-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-b-emerald-950\/35 {
  border-bottom-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-b-emerald-950\/40 {
  border-bottom-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-b-emerald-950\/45 {
  border-bottom-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-b-emerald-950\/5 {
  border-bottom-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-b-emerald-950\/50 {
  border-bottom-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-b-emerald-950\/55 {
  border-bottom-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-b-emerald-950\/60 {
  border-bottom-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-b-emerald-950\/65 {
  border-bottom-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-b-emerald-950\/70 {
  border-bottom-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-b-emerald-950\/75 {
  border-bottom-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-b-emerald-950\/80 {
  border-bottom-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-b-emerald-950\/85 {
  border-bottom-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-b-emerald-950\/90 {
  border-bottom-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-b-emerald-950\/95 {
  border-bottom-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-b-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-100\/0 {
  border-bottom-color: rgb(250 232 255 / 0) !important;
}

.tw-border-b-fuchsia-100\/10 {
  border-bottom-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-b-fuchsia-100\/100 {
  border-bottom-color: rgb(250 232 255 / 1) !important;
}

.tw-border-b-fuchsia-100\/15 {
  border-bottom-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-b-fuchsia-100\/20 {
  border-bottom-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-b-fuchsia-100\/25 {
  border-bottom-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-b-fuchsia-100\/30 {
  border-bottom-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-b-fuchsia-100\/35 {
  border-bottom-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-b-fuchsia-100\/40 {
  border-bottom-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-b-fuchsia-100\/45 {
  border-bottom-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-b-fuchsia-100\/5 {
  border-bottom-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-b-fuchsia-100\/50 {
  border-bottom-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-b-fuchsia-100\/55 {
  border-bottom-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-b-fuchsia-100\/60 {
  border-bottom-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-b-fuchsia-100\/65 {
  border-bottom-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-b-fuchsia-100\/70 {
  border-bottom-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-b-fuchsia-100\/75 {
  border-bottom-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-b-fuchsia-100\/80 {
  border-bottom-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-b-fuchsia-100\/85 {
  border-bottom-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-b-fuchsia-100\/90 {
  border-bottom-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-b-fuchsia-100\/95 {
  border-bottom-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-b-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-200\/0 {
  border-bottom-color: rgb(245 208 254 / 0) !important;
}

.tw-border-b-fuchsia-200\/10 {
  border-bottom-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-b-fuchsia-200\/100 {
  border-bottom-color: rgb(245 208 254 / 1) !important;
}

.tw-border-b-fuchsia-200\/15 {
  border-bottom-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-b-fuchsia-200\/20 {
  border-bottom-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-b-fuchsia-200\/25 {
  border-bottom-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-b-fuchsia-200\/30 {
  border-bottom-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-b-fuchsia-200\/35 {
  border-bottom-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-b-fuchsia-200\/40 {
  border-bottom-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-b-fuchsia-200\/45 {
  border-bottom-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-b-fuchsia-200\/5 {
  border-bottom-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-b-fuchsia-200\/50 {
  border-bottom-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-b-fuchsia-200\/55 {
  border-bottom-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-b-fuchsia-200\/60 {
  border-bottom-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-b-fuchsia-200\/65 {
  border-bottom-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-b-fuchsia-200\/70 {
  border-bottom-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-b-fuchsia-200\/75 {
  border-bottom-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-b-fuchsia-200\/80 {
  border-bottom-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-b-fuchsia-200\/85 {
  border-bottom-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-b-fuchsia-200\/90 {
  border-bottom-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-b-fuchsia-200\/95 {
  border-bottom-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-b-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-300\/0 {
  border-bottom-color: rgb(240 171 252 / 0) !important;
}

.tw-border-b-fuchsia-300\/10 {
  border-bottom-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-b-fuchsia-300\/100 {
  border-bottom-color: rgb(240 171 252 / 1) !important;
}

.tw-border-b-fuchsia-300\/15 {
  border-bottom-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-b-fuchsia-300\/20 {
  border-bottom-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-b-fuchsia-300\/25 {
  border-bottom-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-b-fuchsia-300\/30 {
  border-bottom-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-b-fuchsia-300\/35 {
  border-bottom-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-b-fuchsia-300\/40 {
  border-bottom-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-b-fuchsia-300\/45 {
  border-bottom-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-b-fuchsia-300\/5 {
  border-bottom-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-b-fuchsia-300\/50 {
  border-bottom-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-b-fuchsia-300\/55 {
  border-bottom-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-b-fuchsia-300\/60 {
  border-bottom-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-b-fuchsia-300\/65 {
  border-bottom-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-b-fuchsia-300\/70 {
  border-bottom-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-b-fuchsia-300\/75 {
  border-bottom-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-b-fuchsia-300\/80 {
  border-bottom-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-b-fuchsia-300\/85 {
  border-bottom-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-b-fuchsia-300\/90 {
  border-bottom-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-b-fuchsia-300\/95 {
  border-bottom-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-b-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-400\/0 {
  border-bottom-color: rgb(232 121 249 / 0) !important;
}

.tw-border-b-fuchsia-400\/10 {
  border-bottom-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-b-fuchsia-400\/100 {
  border-bottom-color: rgb(232 121 249 / 1) !important;
}

.tw-border-b-fuchsia-400\/15 {
  border-bottom-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-b-fuchsia-400\/20 {
  border-bottom-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-b-fuchsia-400\/25 {
  border-bottom-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-b-fuchsia-400\/30 {
  border-bottom-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-b-fuchsia-400\/35 {
  border-bottom-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-b-fuchsia-400\/40 {
  border-bottom-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-b-fuchsia-400\/45 {
  border-bottom-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-b-fuchsia-400\/5 {
  border-bottom-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-b-fuchsia-400\/50 {
  border-bottom-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-b-fuchsia-400\/55 {
  border-bottom-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-b-fuchsia-400\/60 {
  border-bottom-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-b-fuchsia-400\/65 {
  border-bottom-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-b-fuchsia-400\/70 {
  border-bottom-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-b-fuchsia-400\/75 {
  border-bottom-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-b-fuchsia-400\/80 {
  border-bottom-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-b-fuchsia-400\/85 {
  border-bottom-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-b-fuchsia-400\/90 {
  border-bottom-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-b-fuchsia-400\/95 {
  border-bottom-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-b-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-50\/0 {
  border-bottom-color: rgb(253 244 255 / 0) !important;
}

.tw-border-b-fuchsia-50\/10 {
  border-bottom-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-b-fuchsia-50\/100 {
  border-bottom-color: rgb(253 244 255 / 1) !important;
}

.tw-border-b-fuchsia-50\/15 {
  border-bottom-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-b-fuchsia-50\/20 {
  border-bottom-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-b-fuchsia-50\/25 {
  border-bottom-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-b-fuchsia-50\/30 {
  border-bottom-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-b-fuchsia-50\/35 {
  border-bottom-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-b-fuchsia-50\/40 {
  border-bottom-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-b-fuchsia-50\/45 {
  border-bottom-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-b-fuchsia-50\/5 {
  border-bottom-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-b-fuchsia-50\/50 {
  border-bottom-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-b-fuchsia-50\/55 {
  border-bottom-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-b-fuchsia-50\/60 {
  border-bottom-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-b-fuchsia-50\/65 {
  border-bottom-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-b-fuchsia-50\/70 {
  border-bottom-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-b-fuchsia-50\/75 {
  border-bottom-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-b-fuchsia-50\/80 {
  border-bottom-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-b-fuchsia-50\/85 {
  border-bottom-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-b-fuchsia-50\/90 {
  border-bottom-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-b-fuchsia-50\/95 {
  border-bottom-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-b-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-500\/0 {
  border-bottom-color: rgb(217 70 239 / 0) !important;
}

.tw-border-b-fuchsia-500\/10 {
  border-bottom-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-b-fuchsia-500\/100 {
  border-bottom-color: rgb(217 70 239 / 1) !important;
}

.tw-border-b-fuchsia-500\/15 {
  border-bottom-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-b-fuchsia-500\/20 {
  border-bottom-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-b-fuchsia-500\/25 {
  border-bottom-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-b-fuchsia-500\/30 {
  border-bottom-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-b-fuchsia-500\/35 {
  border-bottom-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-b-fuchsia-500\/40 {
  border-bottom-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-b-fuchsia-500\/45 {
  border-bottom-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-b-fuchsia-500\/5 {
  border-bottom-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-b-fuchsia-500\/50 {
  border-bottom-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-b-fuchsia-500\/55 {
  border-bottom-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-b-fuchsia-500\/60 {
  border-bottom-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-b-fuchsia-500\/65 {
  border-bottom-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-b-fuchsia-500\/70 {
  border-bottom-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-b-fuchsia-500\/75 {
  border-bottom-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-b-fuchsia-500\/80 {
  border-bottom-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-b-fuchsia-500\/85 {
  border-bottom-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-b-fuchsia-500\/90 {
  border-bottom-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-b-fuchsia-500\/95 {
  border-bottom-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-b-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-600\/0 {
  border-bottom-color: rgb(192 38 211 / 0) !important;
}

.tw-border-b-fuchsia-600\/10 {
  border-bottom-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-b-fuchsia-600\/100 {
  border-bottom-color: rgb(192 38 211 / 1) !important;
}

.tw-border-b-fuchsia-600\/15 {
  border-bottom-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-b-fuchsia-600\/20 {
  border-bottom-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-b-fuchsia-600\/25 {
  border-bottom-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-b-fuchsia-600\/30 {
  border-bottom-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-b-fuchsia-600\/35 {
  border-bottom-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-b-fuchsia-600\/40 {
  border-bottom-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-b-fuchsia-600\/45 {
  border-bottom-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-b-fuchsia-600\/5 {
  border-bottom-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-b-fuchsia-600\/50 {
  border-bottom-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-b-fuchsia-600\/55 {
  border-bottom-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-b-fuchsia-600\/60 {
  border-bottom-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-b-fuchsia-600\/65 {
  border-bottom-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-b-fuchsia-600\/70 {
  border-bottom-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-b-fuchsia-600\/75 {
  border-bottom-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-b-fuchsia-600\/80 {
  border-bottom-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-b-fuchsia-600\/85 {
  border-bottom-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-b-fuchsia-600\/90 {
  border-bottom-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-b-fuchsia-600\/95 {
  border-bottom-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-b-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-700\/0 {
  border-bottom-color: rgb(162 28 175 / 0) !important;
}

.tw-border-b-fuchsia-700\/10 {
  border-bottom-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-b-fuchsia-700\/100 {
  border-bottom-color: rgb(162 28 175 / 1) !important;
}

.tw-border-b-fuchsia-700\/15 {
  border-bottom-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-b-fuchsia-700\/20 {
  border-bottom-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-b-fuchsia-700\/25 {
  border-bottom-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-b-fuchsia-700\/30 {
  border-bottom-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-b-fuchsia-700\/35 {
  border-bottom-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-b-fuchsia-700\/40 {
  border-bottom-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-b-fuchsia-700\/45 {
  border-bottom-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-b-fuchsia-700\/5 {
  border-bottom-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-b-fuchsia-700\/50 {
  border-bottom-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-b-fuchsia-700\/55 {
  border-bottom-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-b-fuchsia-700\/60 {
  border-bottom-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-b-fuchsia-700\/65 {
  border-bottom-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-b-fuchsia-700\/70 {
  border-bottom-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-b-fuchsia-700\/75 {
  border-bottom-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-b-fuchsia-700\/80 {
  border-bottom-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-b-fuchsia-700\/85 {
  border-bottom-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-b-fuchsia-700\/90 {
  border-bottom-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-b-fuchsia-700\/95 {
  border-bottom-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-b-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-800\/0 {
  border-bottom-color: rgb(134 25 143 / 0) !important;
}

.tw-border-b-fuchsia-800\/10 {
  border-bottom-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-b-fuchsia-800\/100 {
  border-bottom-color: rgb(134 25 143 / 1) !important;
}

.tw-border-b-fuchsia-800\/15 {
  border-bottom-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-b-fuchsia-800\/20 {
  border-bottom-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-b-fuchsia-800\/25 {
  border-bottom-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-b-fuchsia-800\/30 {
  border-bottom-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-b-fuchsia-800\/35 {
  border-bottom-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-b-fuchsia-800\/40 {
  border-bottom-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-b-fuchsia-800\/45 {
  border-bottom-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-b-fuchsia-800\/5 {
  border-bottom-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-b-fuchsia-800\/50 {
  border-bottom-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-b-fuchsia-800\/55 {
  border-bottom-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-b-fuchsia-800\/60 {
  border-bottom-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-b-fuchsia-800\/65 {
  border-bottom-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-b-fuchsia-800\/70 {
  border-bottom-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-b-fuchsia-800\/75 {
  border-bottom-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-b-fuchsia-800\/80 {
  border-bottom-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-b-fuchsia-800\/85 {
  border-bottom-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-b-fuchsia-800\/90 {
  border-bottom-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-b-fuchsia-800\/95 {
  border-bottom-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-b-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-900\/0 {
  border-bottom-color: rgb(112 26 117 / 0) !important;
}

.tw-border-b-fuchsia-900\/10 {
  border-bottom-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-b-fuchsia-900\/100 {
  border-bottom-color: rgb(112 26 117 / 1) !important;
}

.tw-border-b-fuchsia-900\/15 {
  border-bottom-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-b-fuchsia-900\/20 {
  border-bottom-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-b-fuchsia-900\/25 {
  border-bottom-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-b-fuchsia-900\/30 {
  border-bottom-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-b-fuchsia-900\/35 {
  border-bottom-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-b-fuchsia-900\/40 {
  border-bottom-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-b-fuchsia-900\/45 {
  border-bottom-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-b-fuchsia-900\/5 {
  border-bottom-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-b-fuchsia-900\/50 {
  border-bottom-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-b-fuchsia-900\/55 {
  border-bottom-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-b-fuchsia-900\/60 {
  border-bottom-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-b-fuchsia-900\/65 {
  border-bottom-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-b-fuchsia-900\/70 {
  border-bottom-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-b-fuchsia-900\/75 {
  border-bottom-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-b-fuchsia-900\/80 {
  border-bottom-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-b-fuchsia-900\/85 {
  border-bottom-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-b-fuchsia-900\/90 {
  border-bottom-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-b-fuchsia-900\/95 {
  border-bottom-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-b-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-b-fuchsia-950\/0 {
  border-bottom-color: rgb(74 4 78 / 0) !important;
}

.tw-border-b-fuchsia-950\/10 {
  border-bottom-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-b-fuchsia-950\/100 {
  border-bottom-color: rgb(74 4 78 / 1) !important;
}

.tw-border-b-fuchsia-950\/15 {
  border-bottom-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-b-fuchsia-950\/20 {
  border-bottom-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-b-fuchsia-950\/25 {
  border-bottom-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-b-fuchsia-950\/30 {
  border-bottom-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-b-fuchsia-950\/35 {
  border-bottom-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-b-fuchsia-950\/40 {
  border-bottom-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-b-fuchsia-950\/45 {
  border-bottom-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-b-fuchsia-950\/5 {
  border-bottom-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-b-fuchsia-950\/50 {
  border-bottom-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-b-fuchsia-950\/55 {
  border-bottom-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-b-fuchsia-950\/60 {
  border-bottom-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-b-fuchsia-950\/65 {
  border-bottom-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-b-fuchsia-950\/70 {
  border-bottom-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-b-fuchsia-950\/75 {
  border-bottom-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-b-fuchsia-950\/80 {
  border-bottom-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-b-fuchsia-950\/85 {
  border-bottom-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-b-fuchsia-950\/90 {
  border-bottom-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-b-fuchsia-950\/95 {
  border-bottom-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-b-gray-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-100\/0 {
  border-bottom-color: rgb(243 244 246 / 0) !important;
}

.tw-border-b-gray-100\/10 {
  border-bottom-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-b-gray-100\/100 {
  border-bottom-color: rgb(243 244 246 / 1) !important;
}

.tw-border-b-gray-100\/15 {
  border-bottom-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-b-gray-100\/20 {
  border-bottom-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-b-gray-100\/25 {
  border-bottom-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-b-gray-100\/30 {
  border-bottom-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-b-gray-100\/35 {
  border-bottom-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-b-gray-100\/40 {
  border-bottom-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-b-gray-100\/45 {
  border-bottom-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-b-gray-100\/5 {
  border-bottom-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-b-gray-100\/50 {
  border-bottom-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-b-gray-100\/55 {
  border-bottom-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-b-gray-100\/60 {
  border-bottom-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-b-gray-100\/65 {
  border-bottom-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-b-gray-100\/70 {
  border-bottom-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-b-gray-100\/75 {
  border-bottom-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-b-gray-100\/80 {
  border-bottom-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-b-gray-100\/85 {
  border-bottom-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-b-gray-100\/90 {
  border-bottom-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-b-gray-100\/95 {
  border-bottom-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-b-gray-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-200\/0 {
  border-bottom-color: rgb(229 231 235 / 0) !important;
}

.tw-border-b-gray-200\/10 {
  border-bottom-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-b-gray-200\/100 {
  border-bottom-color: rgb(229 231 235 / 1) !important;
}

.tw-border-b-gray-200\/15 {
  border-bottom-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-b-gray-200\/20 {
  border-bottom-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-b-gray-200\/25 {
  border-bottom-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-b-gray-200\/30 {
  border-bottom-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-b-gray-200\/35 {
  border-bottom-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-b-gray-200\/40 {
  border-bottom-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-b-gray-200\/45 {
  border-bottom-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-b-gray-200\/5 {
  border-bottom-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-b-gray-200\/50 {
  border-bottom-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-b-gray-200\/55 {
  border-bottom-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-b-gray-200\/60 {
  border-bottom-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-b-gray-200\/65 {
  border-bottom-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-b-gray-200\/70 {
  border-bottom-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-b-gray-200\/75 {
  border-bottom-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-b-gray-200\/80 {
  border-bottom-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-b-gray-200\/85 {
  border-bottom-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-b-gray-200\/90 {
  border-bottom-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-b-gray-200\/95 {
  border-bottom-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-b-gray-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-300\/0 {
  border-bottom-color: rgb(209 213 219 / 0) !important;
}

.tw-border-b-gray-300\/10 {
  border-bottom-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-b-gray-300\/100 {
  border-bottom-color: rgb(209 213 219 / 1) !important;
}

.tw-border-b-gray-300\/15 {
  border-bottom-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-b-gray-300\/20 {
  border-bottom-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-b-gray-300\/25 {
  border-bottom-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-b-gray-300\/30 {
  border-bottom-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-b-gray-300\/35 {
  border-bottom-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-b-gray-300\/40 {
  border-bottom-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-b-gray-300\/45 {
  border-bottom-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-b-gray-300\/5 {
  border-bottom-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-b-gray-300\/50 {
  border-bottom-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-b-gray-300\/55 {
  border-bottom-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-b-gray-300\/60 {
  border-bottom-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-b-gray-300\/65 {
  border-bottom-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-b-gray-300\/70 {
  border-bottom-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-b-gray-300\/75 {
  border-bottom-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-b-gray-300\/80 {
  border-bottom-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-b-gray-300\/85 {
  border-bottom-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-b-gray-300\/90 {
  border-bottom-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-b-gray-300\/95 {
  border-bottom-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-b-gray-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-400\/0 {
  border-bottom-color: rgb(156 163 175 / 0) !important;
}

.tw-border-b-gray-400\/10 {
  border-bottom-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-b-gray-400\/100 {
  border-bottom-color: rgb(156 163 175 / 1) !important;
}

.tw-border-b-gray-400\/15 {
  border-bottom-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-b-gray-400\/20 {
  border-bottom-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-b-gray-400\/25 {
  border-bottom-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-b-gray-400\/30 {
  border-bottom-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-b-gray-400\/35 {
  border-bottom-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-b-gray-400\/40 {
  border-bottom-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-b-gray-400\/45 {
  border-bottom-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-b-gray-400\/5 {
  border-bottom-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-b-gray-400\/50 {
  border-bottom-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-b-gray-400\/55 {
  border-bottom-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-b-gray-400\/60 {
  border-bottom-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-b-gray-400\/65 {
  border-bottom-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-b-gray-400\/70 {
  border-bottom-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-b-gray-400\/75 {
  border-bottom-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-b-gray-400\/80 {
  border-bottom-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-b-gray-400\/85 {
  border-bottom-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-b-gray-400\/90 {
  border-bottom-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-b-gray-400\/95 {
  border-bottom-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-b-gray-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-50\/0 {
  border-bottom-color: rgb(249 250 251 / 0) !important;
}

.tw-border-b-gray-50\/10 {
  border-bottom-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-b-gray-50\/100 {
  border-bottom-color: rgb(249 250 251 / 1) !important;
}

.tw-border-b-gray-50\/15 {
  border-bottom-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-b-gray-50\/20 {
  border-bottom-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-b-gray-50\/25 {
  border-bottom-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-b-gray-50\/30 {
  border-bottom-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-b-gray-50\/35 {
  border-bottom-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-b-gray-50\/40 {
  border-bottom-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-b-gray-50\/45 {
  border-bottom-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-b-gray-50\/5 {
  border-bottom-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-b-gray-50\/50 {
  border-bottom-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-b-gray-50\/55 {
  border-bottom-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-b-gray-50\/60 {
  border-bottom-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-b-gray-50\/65 {
  border-bottom-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-b-gray-50\/70 {
  border-bottom-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-b-gray-50\/75 {
  border-bottom-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-b-gray-50\/80 {
  border-bottom-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-b-gray-50\/85 {
  border-bottom-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-b-gray-50\/90 {
  border-bottom-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-b-gray-50\/95 {
  border-bottom-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-b-gray-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-500\/0 {
  border-bottom-color: rgb(107 114 128 / 0) !important;
}

.tw-border-b-gray-500\/10 {
  border-bottom-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-b-gray-500\/100 {
  border-bottom-color: rgb(107 114 128 / 1) !important;
}

.tw-border-b-gray-500\/15 {
  border-bottom-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-b-gray-500\/20 {
  border-bottom-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-b-gray-500\/25 {
  border-bottom-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-b-gray-500\/30 {
  border-bottom-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-b-gray-500\/35 {
  border-bottom-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-b-gray-500\/40 {
  border-bottom-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-b-gray-500\/45 {
  border-bottom-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-b-gray-500\/5 {
  border-bottom-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-b-gray-500\/50 {
  border-bottom-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-b-gray-500\/55 {
  border-bottom-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-b-gray-500\/60 {
  border-bottom-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-b-gray-500\/65 {
  border-bottom-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-b-gray-500\/70 {
  border-bottom-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-b-gray-500\/75 {
  border-bottom-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-b-gray-500\/80 {
  border-bottom-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-b-gray-500\/85 {
  border-bottom-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-b-gray-500\/90 {
  border-bottom-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-b-gray-500\/95 {
  border-bottom-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-b-gray-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-600\/0 {
  border-bottom-color: rgb(75 85 99 / 0) !important;
}

.tw-border-b-gray-600\/10 {
  border-bottom-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-b-gray-600\/100 {
  border-bottom-color: rgb(75 85 99 / 1) !important;
}

.tw-border-b-gray-600\/15 {
  border-bottom-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-b-gray-600\/20 {
  border-bottom-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-b-gray-600\/25 {
  border-bottom-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-b-gray-600\/30 {
  border-bottom-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-b-gray-600\/35 {
  border-bottom-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-b-gray-600\/40 {
  border-bottom-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-b-gray-600\/45 {
  border-bottom-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-b-gray-600\/5 {
  border-bottom-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-b-gray-600\/50 {
  border-bottom-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-b-gray-600\/55 {
  border-bottom-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-b-gray-600\/60 {
  border-bottom-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-b-gray-600\/65 {
  border-bottom-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-b-gray-600\/70 {
  border-bottom-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-b-gray-600\/75 {
  border-bottom-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-b-gray-600\/80 {
  border-bottom-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-b-gray-600\/85 {
  border-bottom-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-b-gray-600\/90 {
  border-bottom-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-b-gray-600\/95 {
  border-bottom-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-b-gray-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-700\/0 {
  border-bottom-color: rgb(55 65 81 / 0) !important;
}

.tw-border-b-gray-700\/10 {
  border-bottom-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-b-gray-700\/100 {
  border-bottom-color: rgb(55 65 81 / 1) !important;
}

.tw-border-b-gray-700\/15 {
  border-bottom-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-b-gray-700\/20 {
  border-bottom-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-b-gray-700\/25 {
  border-bottom-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-b-gray-700\/30 {
  border-bottom-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-b-gray-700\/35 {
  border-bottom-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-b-gray-700\/40 {
  border-bottom-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-b-gray-700\/45 {
  border-bottom-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-b-gray-700\/5 {
  border-bottom-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-b-gray-700\/50 {
  border-bottom-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-b-gray-700\/55 {
  border-bottom-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-b-gray-700\/60 {
  border-bottom-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-b-gray-700\/65 {
  border-bottom-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-b-gray-700\/70 {
  border-bottom-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-b-gray-700\/75 {
  border-bottom-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-b-gray-700\/80 {
  border-bottom-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-b-gray-700\/85 {
  border-bottom-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-b-gray-700\/90 {
  border-bottom-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-b-gray-700\/95 {
  border-bottom-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-b-gray-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-800\/0 {
  border-bottom-color: rgb(31 41 55 / 0) !important;
}

.tw-border-b-gray-800\/10 {
  border-bottom-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-b-gray-800\/100 {
  border-bottom-color: rgb(31 41 55 / 1) !important;
}

.tw-border-b-gray-800\/15 {
  border-bottom-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-b-gray-800\/20 {
  border-bottom-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-b-gray-800\/25 {
  border-bottom-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-b-gray-800\/30 {
  border-bottom-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-b-gray-800\/35 {
  border-bottom-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-b-gray-800\/40 {
  border-bottom-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-b-gray-800\/45 {
  border-bottom-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-b-gray-800\/5 {
  border-bottom-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-b-gray-800\/50 {
  border-bottom-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-b-gray-800\/55 {
  border-bottom-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-b-gray-800\/60 {
  border-bottom-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-b-gray-800\/65 {
  border-bottom-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-b-gray-800\/70 {
  border-bottom-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-b-gray-800\/75 {
  border-bottom-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-b-gray-800\/80 {
  border-bottom-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-b-gray-800\/85 {
  border-bottom-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-b-gray-800\/90 {
  border-bottom-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-b-gray-800\/95 {
  border-bottom-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-b-gray-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-900\/0 {
  border-bottom-color: rgb(17 24 39 / 0) !important;
}

.tw-border-b-gray-900\/10 {
  border-bottom-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-b-gray-900\/100 {
  border-bottom-color: rgb(17 24 39 / 1) !important;
}

.tw-border-b-gray-900\/15 {
  border-bottom-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-b-gray-900\/20 {
  border-bottom-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-b-gray-900\/25 {
  border-bottom-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-b-gray-900\/30 {
  border-bottom-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-b-gray-900\/35 {
  border-bottom-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-b-gray-900\/40 {
  border-bottom-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-b-gray-900\/45 {
  border-bottom-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-b-gray-900\/5 {
  border-bottom-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-b-gray-900\/50 {
  border-bottom-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-b-gray-900\/55 {
  border-bottom-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-b-gray-900\/60 {
  border-bottom-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-b-gray-900\/65 {
  border-bottom-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-b-gray-900\/70 {
  border-bottom-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-b-gray-900\/75 {
  border-bottom-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-b-gray-900\/80 {
  border-bottom-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-b-gray-900\/85 {
  border-bottom-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-b-gray-900\/90 {
  border-bottom-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-b-gray-900\/95 {
  border-bottom-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-b-gray-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-b-gray-950\/0 {
  border-bottom-color: rgb(3 7 18 / 0) !important;
}

.tw-border-b-gray-950\/10 {
  border-bottom-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-b-gray-950\/100 {
  border-bottom-color: rgb(3 7 18 / 1) !important;
}

.tw-border-b-gray-950\/15 {
  border-bottom-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-b-gray-950\/20 {
  border-bottom-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-b-gray-950\/25 {
  border-bottom-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-b-gray-950\/30 {
  border-bottom-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-b-gray-950\/35 {
  border-bottom-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-b-gray-950\/40 {
  border-bottom-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-b-gray-950\/45 {
  border-bottom-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-b-gray-950\/5 {
  border-bottom-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-b-gray-950\/50 {
  border-bottom-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-b-gray-950\/55 {
  border-bottom-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-b-gray-950\/60 {
  border-bottom-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-b-gray-950\/65 {
  border-bottom-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-b-gray-950\/70 {
  border-bottom-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-b-gray-950\/75 {
  border-bottom-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-b-gray-950\/80 {
  border-bottom-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-b-gray-950\/85 {
  border-bottom-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-b-gray-950\/90 {
  border-bottom-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-b-gray-950\/95 {
  border-bottom-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-b-green-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-100\/0 {
  border-bottom-color: rgb(220 252 231 / 0) !important;
}

.tw-border-b-green-100\/10 {
  border-bottom-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-b-green-100\/100 {
  border-bottom-color: rgb(220 252 231 / 1) !important;
}

.tw-border-b-green-100\/15 {
  border-bottom-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-b-green-100\/20 {
  border-bottom-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-b-green-100\/25 {
  border-bottom-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-b-green-100\/30 {
  border-bottom-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-b-green-100\/35 {
  border-bottom-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-b-green-100\/40 {
  border-bottom-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-b-green-100\/45 {
  border-bottom-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-b-green-100\/5 {
  border-bottom-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-b-green-100\/50 {
  border-bottom-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-b-green-100\/55 {
  border-bottom-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-b-green-100\/60 {
  border-bottom-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-b-green-100\/65 {
  border-bottom-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-b-green-100\/70 {
  border-bottom-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-b-green-100\/75 {
  border-bottom-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-b-green-100\/80 {
  border-bottom-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-b-green-100\/85 {
  border-bottom-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-b-green-100\/90 {
  border-bottom-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-b-green-100\/95 {
  border-bottom-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-b-green-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-200\/0 {
  border-bottom-color: rgb(187 247 208 / 0) !important;
}

.tw-border-b-green-200\/10 {
  border-bottom-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-b-green-200\/100 {
  border-bottom-color: rgb(187 247 208 / 1) !important;
}

.tw-border-b-green-200\/15 {
  border-bottom-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-b-green-200\/20 {
  border-bottom-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-b-green-200\/25 {
  border-bottom-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-b-green-200\/30 {
  border-bottom-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-b-green-200\/35 {
  border-bottom-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-b-green-200\/40 {
  border-bottom-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-b-green-200\/45 {
  border-bottom-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-b-green-200\/5 {
  border-bottom-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-b-green-200\/50 {
  border-bottom-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-b-green-200\/55 {
  border-bottom-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-b-green-200\/60 {
  border-bottom-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-b-green-200\/65 {
  border-bottom-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-b-green-200\/70 {
  border-bottom-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-b-green-200\/75 {
  border-bottom-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-b-green-200\/80 {
  border-bottom-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-b-green-200\/85 {
  border-bottom-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-b-green-200\/90 {
  border-bottom-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-b-green-200\/95 {
  border-bottom-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-b-green-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-300\/0 {
  border-bottom-color: rgb(134 239 172 / 0) !important;
}

.tw-border-b-green-300\/10 {
  border-bottom-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-b-green-300\/100 {
  border-bottom-color: rgb(134 239 172 / 1) !important;
}

.tw-border-b-green-300\/15 {
  border-bottom-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-b-green-300\/20 {
  border-bottom-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-b-green-300\/25 {
  border-bottom-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-b-green-300\/30 {
  border-bottom-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-b-green-300\/35 {
  border-bottom-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-b-green-300\/40 {
  border-bottom-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-b-green-300\/45 {
  border-bottom-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-b-green-300\/5 {
  border-bottom-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-b-green-300\/50 {
  border-bottom-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-b-green-300\/55 {
  border-bottom-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-b-green-300\/60 {
  border-bottom-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-b-green-300\/65 {
  border-bottom-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-b-green-300\/70 {
  border-bottom-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-b-green-300\/75 {
  border-bottom-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-b-green-300\/80 {
  border-bottom-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-b-green-300\/85 {
  border-bottom-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-b-green-300\/90 {
  border-bottom-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-b-green-300\/95 {
  border-bottom-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-b-green-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-400\/0 {
  border-bottom-color: rgb(74 222 128 / 0) !important;
}

.tw-border-b-green-400\/10 {
  border-bottom-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-b-green-400\/100 {
  border-bottom-color: rgb(74 222 128 / 1) !important;
}

.tw-border-b-green-400\/15 {
  border-bottom-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-b-green-400\/20 {
  border-bottom-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-b-green-400\/25 {
  border-bottom-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-b-green-400\/30 {
  border-bottom-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-b-green-400\/35 {
  border-bottom-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-b-green-400\/40 {
  border-bottom-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-b-green-400\/45 {
  border-bottom-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-b-green-400\/5 {
  border-bottom-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-b-green-400\/50 {
  border-bottom-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-b-green-400\/55 {
  border-bottom-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-b-green-400\/60 {
  border-bottom-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-b-green-400\/65 {
  border-bottom-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-b-green-400\/70 {
  border-bottom-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-b-green-400\/75 {
  border-bottom-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-b-green-400\/80 {
  border-bottom-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-b-green-400\/85 {
  border-bottom-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-b-green-400\/90 {
  border-bottom-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-b-green-400\/95 {
  border-bottom-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-b-green-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-50\/0 {
  border-bottom-color: rgb(240 253 244 / 0) !important;
}

.tw-border-b-green-50\/10 {
  border-bottom-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-b-green-50\/100 {
  border-bottom-color: rgb(240 253 244 / 1) !important;
}

.tw-border-b-green-50\/15 {
  border-bottom-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-b-green-50\/20 {
  border-bottom-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-b-green-50\/25 {
  border-bottom-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-b-green-50\/30 {
  border-bottom-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-b-green-50\/35 {
  border-bottom-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-b-green-50\/40 {
  border-bottom-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-b-green-50\/45 {
  border-bottom-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-b-green-50\/5 {
  border-bottom-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-b-green-50\/50 {
  border-bottom-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-b-green-50\/55 {
  border-bottom-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-b-green-50\/60 {
  border-bottom-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-b-green-50\/65 {
  border-bottom-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-b-green-50\/70 {
  border-bottom-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-b-green-50\/75 {
  border-bottom-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-b-green-50\/80 {
  border-bottom-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-b-green-50\/85 {
  border-bottom-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-b-green-50\/90 {
  border-bottom-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-b-green-50\/95 {
  border-bottom-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-b-green-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-500\/0 {
  border-bottom-color: rgb(34 197 94 / 0) !important;
}

.tw-border-b-green-500\/10 {
  border-bottom-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-b-green-500\/100 {
  border-bottom-color: rgb(34 197 94 / 1) !important;
}

.tw-border-b-green-500\/15 {
  border-bottom-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-b-green-500\/20 {
  border-bottom-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-b-green-500\/25 {
  border-bottom-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-b-green-500\/30 {
  border-bottom-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-b-green-500\/35 {
  border-bottom-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-b-green-500\/40 {
  border-bottom-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-b-green-500\/45 {
  border-bottom-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-b-green-500\/5 {
  border-bottom-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-b-green-500\/50 {
  border-bottom-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-b-green-500\/55 {
  border-bottom-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-b-green-500\/60 {
  border-bottom-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-b-green-500\/65 {
  border-bottom-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-b-green-500\/70 {
  border-bottom-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-b-green-500\/75 {
  border-bottom-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-b-green-500\/80 {
  border-bottom-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-b-green-500\/85 {
  border-bottom-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-b-green-500\/90 {
  border-bottom-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-b-green-500\/95 {
  border-bottom-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-b-green-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-600\/0 {
  border-bottom-color: rgb(22 163 74 / 0) !important;
}

.tw-border-b-green-600\/10 {
  border-bottom-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-b-green-600\/100 {
  border-bottom-color: rgb(22 163 74 / 1) !important;
}

.tw-border-b-green-600\/15 {
  border-bottom-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-b-green-600\/20 {
  border-bottom-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-b-green-600\/25 {
  border-bottom-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-b-green-600\/30 {
  border-bottom-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-b-green-600\/35 {
  border-bottom-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-b-green-600\/40 {
  border-bottom-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-b-green-600\/45 {
  border-bottom-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-b-green-600\/5 {
  border-bottom-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-b-green-600\/50 {
  border-bottom-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-b-green-600\/55 {
  border-bottom-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-b-green-600\/60 {
  border-bottom-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-b-green-600\/65 {
  border-bottom-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-b-green-600\/70 {
  border-bottom-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-b-green-600\/75 {
  border-bottom-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-b-green-600\/80 {
  border-bottom-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-b-green-600\/85 {
  border-bottom-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-b-green-600\/90 {
  border-bottom-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-b-green-600\/95 {
  border-bottom-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-b-green-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-700\/0 {
  border-bottom-color: rgb(21 128 61 / 0) !important;
}

.tw-border-b-green-700\/10 {
  border-bottom-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-b-green-700\/100 {
  border-bottom-color: rgb(21 128 61 / 1) !important;
}

.tw-border-b-green-700\/15 {
  border-bottom-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-b-green-700\/20 {
  border-bottom-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-b-green-700\/25 {
  border-bottom-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-b-green-700\/30 {
  border-bottom-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-b-green-700\/35 {
  border-bottom-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-b-green-700\/40 {
  border-bottom-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-b-green-700\/45 {
  border-bottom-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-b-green-700\/5 {
  border-bottom-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-b-green-700\/50 {
  border-bottom-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-b-green-700\/55 {
  border-bottom-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-b-green-700\/60 {
  border-bottom-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-b-green-700\/65 {
  border-bottom-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-b-green-700\/70 {
  border-bottom-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-b-green-700\/75 {
  border-bottom-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-b-green-700\/80 {
  border-bottom-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-b-green-700\/85 {
  border-bottom-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-b-green-700\/90 {
  border-bottom-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-b-green-700\/95 {
  border-bottom-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-b-green-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-800\/0 {
  border-bottom-color: rgb(22 101 52 / 0) !important;
}

.tw-border-b-green-800\/10 {
  border-bottom-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-b-green-800\/100 {
  border-bottom-color: rgb(22 101 52 / 1) !important;
}

.tw-border-b-green-800\/15 {
  border-bottom-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-b-green-800\/20 {
  border-bottom-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-b-green-800\/25 {
  border-bottom-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-b-green-800\/30 {
  border-bottom-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-b-green-800\/35 {
  border-bottom-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-b-green-800\/40 {
  border-bottom-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-b-green-800\/45 {
  border-bottom-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-b-green-800\/5 {
  border-bottom-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-b-green-800\/50 {
  border-bottom-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-b-green-800\/55 {
  border-bottom-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-b-green-800\/60 {
  border-bottom-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-b-green-800\/65 {
  border-bottom-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-b-green-800\/70 {
  border-bottom-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-b-green-800\/75 {
  border-bottom-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-b-green-800\/80 {
  border-bottom-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-b-green-800\/85 {
  border-bottom-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-b-green-800\/90 {
  border-bottom-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-b-green-800\/95 {
  border-bottom-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-b-green-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-900\/0 {
  border-bottom-color: rgb(20 83 45 / 0) !important;
}

.tw-border-b-green-900\/10 {
  border-bottom-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-b-green-900\/100 {
  border-bottom-color: rgb(20 83 45 / 1) !important;
}

.tw-border-b-green-900\/15 {
  border-bottom-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-b-green-900\/20 {
  border-bottom-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-b-green-900\/25 {
  border-bottom-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-b-green-900\/30 {
  border-bottom-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-b-green-900\/35 {
  border-bottom-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-b-green-900\/40 {
  border-bottom-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-b-green-900\/45 {
  border-bottom-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-b-green-900\/5 {
  border-bottom-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-b-green-900\/50 {
  border-bottom-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-b-green-900\/55 {
  border-bottom-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-b-green-900\/60 {
  border-bottom-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-b-green-900\/65 {
  border-bottom-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-b-green-900\/70 {
  border-bottom-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-b-green-900\/75 {
  border-bottom-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-b-green-900\/80 {
  border-bottom-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-b-green-900\/85 {
  border-bottom-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-b-green-900\/90 {
  border-bottom-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-b-green-900\/95 {
  border-bottom-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-b-green-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-b-green-950\/0 {
  border-bottom-color: rgb(5 46 22 / 0) !important;
}

.tw-border-b-green-950\/10 {
  border-bottom-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-b-green-950\/100 {
  border-bottom-color: rgb(5 46 22 / 1) !important;
}

.tw-border-b-green-950\/15 {
  border-bottom-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-b-green-950\/20 {
  border-bottom-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-b-green-950\/25 {
  border-bottom-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-b-green-950\/30 {
  border-bottom-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-b-green-950\/35 {
  border-bottom-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-b-green-950\/40 {
  border-bottom-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-b-green-950\/45 {
  border-bottom-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-b-green-950\/5 {
  border-bottom-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-b-green-950\/50 {
  border-bottom-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-b-green-950\/55 {
  border-bottom-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-b-green-950\/60 {
  border-bottom-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-b-green-950\/65 {
  border-bottom-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-b-green-950\/70 {
  border-bottom-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-b-green-950\/75 {
  border-bottom-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-b-green-950\/80 {
  border-bottom-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-b-green-950\/85 {
  border-bottom-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-b-green-950\/90 {
  border-bottom-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-b-green-950\/95 {
  border-bottom-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-b-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-100\/0 {
  border-bottom-color: rgb(224 231 255 / 0) !important;
}

.tw-border-b-indigo-100\/10 {
  border-bottom-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-b-indigo-100\/100 {
  border-bottom-color: rgb(224 231 255 / 1) !important;
}

.tw-border-b-indigo-100\/15 {
  border-bottom-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-b-indigo-100\/20 {
  border-bottom-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-b-indigo-100\/25 {
  border-bottom-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-b-indigo-100\/30 {
  border-bottom-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-b-indigo-100\/35 {
  border-bottom-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-b-indigo-100\/40 {
  border-bottom-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-b-indigo-100\/45 {
  border-bottom-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-b-indigo-100\/5 {
  border-bottom-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-b-indigo-100\/50 {
  border-bottom-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-b-indigo-100\/55 {
  border-bottom-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-b-indigo-100\/60 {
  border-bottom-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-b-indigo-100\/65 {
  border-bottom-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-b-indigo-100\/70 {
  border-bottom-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-b-indigo-100\/75 {
  border-bottom-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-b-indigo-100\/80 {
  border-bottom-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-b-indigo-100\/85 {
  border-bottom-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-b-indigo-100\/90 {
  border-bottom-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-b-indigo-100\/95 {
  border-bottom-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-b-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-200\/0 {
  border-bottom-color: rgb(199 210 254 / 0) !important;
}

.tw-border-b-indigo-200\/10 {
  border-bottom-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-b-indigo-200\/100 {
  border-bottom-color: rgb(199 210 254 / 1) !important;
}

.tw-border-b-indigo-200\/15 {
  border-bottom-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-b-indigo-200\/20 {
  border-bottom-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-b-indigo-200\/25 {
  border-bottom-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-b-indigo-200\/30 {
  border-bottom-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-b-indigo-200\/35 {
  border-bottom-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-b-indigo-200\/40 {
  border-bottom-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-b-indigo-200\/45 {
  border-bottom-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-b-indigo-200\/5 {
  border-bottom-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-b-indigo-200\/50 {
  border-bottom-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-b-indigo-200\/55 {
  border-bottom-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-b-indigo-200\/60 {
  border-bottom-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-b-indigo-200\/65 {
  border-bottom-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-b-indigo-200\/70 {
  border-bottom-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-b-indigo-200\/75 {
  border-bottom-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-b-indigo-200\/80 {
  border-bottom-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-b-indigo-200\/85 {
  border-bottom-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-b-indigo-200\/90 {
  border-bottom-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-b-indigo-200\/95 {
  border-bottom-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-b-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-300\/0 {
  border-bottom-color: rgb(165 180 252 / 0) !important;
}

.tw-border-b-indigo-300\/10 {
  border-bottom-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-b-indigo-300\/100 {
  border-bottom-color: rgb(165 180 252 / 1) !important;
}

.tw-border-b-indigo-300\/15 {
  border-bottom-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-b-indigo-300\/20 {
  border-bottom-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-b-indigo-300\/25 {
  border-bottom-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-b-indigo-300\/30 {
  border-bottom-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-b-indigo-300\/35 {
  border-bottom-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-b-indigo-300\/40 {
  border-bottom-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-b-indigo-300\/45 {
  border-bottom-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-b-indigo-300\/5 {
  border-bottom-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-b-indigo-300\/50 {
  border-bottom-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-b-indigo-300\/55 {
  border-bottom-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-b-indigo-300\/60 {
  border-bottom-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-b-indigo-300\/65 {
  border-bottom-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-b-indigo-300\/70 {
  border-bottom-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-b-indigo-300\/75 {
  border-bottom-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-b-indigo-300\/80 {
  border-bottom-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-b-indigo-300\/85 {
  border-bottom-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-b-indigo-300\/90 {
  border-bottom-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-b-indigo-300\/95 {
  border-bottom-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-b-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-400\/0 {
  border-bottom-color: rgb(129 140 248 / 0) !important;
}

.tw-border-b-indigo-400\/10 {
  border-bottom-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-b-indigo-400\/100 {
  border-bottom-color: rgb(129 140 248 / 1) !important;
}

.tw-border-b-indigo-400\/15 {
  border-bottom-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-b-indigo-400\/20 {
  border-bottom-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-b-indigo-400\/25 {
  border-bottom-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-b-indigo-400\/30 {
  border-bottom-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-b-indigo-400\/35 {
  border-bottom-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-b-indigo-400\/40 {
  border-bottom-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-b-indigo-400\/45 {
  border-bottom-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-b-indigo-400\/5 {
  border-bottom-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-b-indigo-400\/50 {
  border-bottom-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-b-indigo-400\/55 {
  border-bottom-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-b-indigo-400\/60 {
  border-bottom-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-b-indigo-400\/65 {
  border-bottom-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-b-indigo-400\/70 {
  border-bottom-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-b-indigo-400\/75 {
  border-bottom-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-b-indigo-400\/80 {
  border-bottom-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-b-indigo-400\/85 {
  border-bottom-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-b-indigo-400\/90 {
  border-bottom-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-b-indigo-400\/95 {
  border-bottom-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-b-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-50\/0 {
  border-bottom-color: rgb(238 242 255 / 0) !important;
}

.tw-border-b-indigo-50\/10 {
  border-bottom-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-b-indigo-50\/100 {
  border-bottom-color: rgb(238 242 255 / 1) !important;
}

.tw-border-b-indigo-50\/15 {
  border-bottom-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-b-indigo-50\/20 {
  border-bottom-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-b-indigo-50\/25 {
  border-bottom-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-b-indigo-50\/30 {
  border-bottom-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-b-indigo-50\/35 {
  border-bottom-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-b-indigo-50\/40 {
  border-bottom-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-b-indigo-50\/45 {
  border-bottom-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-b-indigo-50\/5 {
  border-bottom-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-b-indigo-50\/50 {
  border-bottom-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-b-indigo-50\/55 {
  border-bottom-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-b-indigo-50\/60 {
  border-bottom-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-b-indigo-50\/65 {
  border-bottom-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-b-indigo-50\/70 {
  border-bottom-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-b-indigo-50\/75 {
  border-bottom-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-b-indigo-50\/80 {
  border-bottom-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-b-indigo-50\/85 {
  border-bottom-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-b-indigo-50\/90 {
  border-bottom-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-b-indigo-50\/95 {
  border-bottom-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-b-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-500\/0 {
  border-bottom-color: rgb(99 102 241 / 0) !important;
}

.tw-border-b-indigo-500\/10 {
  border-bottom-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-b-indigo-500\/100 {
  border-bottom-color: rgb(99 102 241 / 1) !important;
}

.tw-border-b-indigo-500\/15 {
  border-bottom-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-b-indigo-500\/20 {
  border-bottom-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-b-indigo-500\/25 {
  border-bottom-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-b-indigo-500\/30 {
  border-bottom-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-b-indigo-500\/35 {
  border-bottom-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-b-indigo-500\/40 {
  border-bottom-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-b-indigo-500\/45 {
  border-bottom-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-b-indigo-500\/5 {
  border-bottom-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-b-indigo-500\/50 {
  border-bottom-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-b-indigo-500\/55 {
  border-bottom-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-b-indigo-500\/60 {
  border-bottom-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-b-indigo-500\/65 {
  border-bottom-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-b-indigo-500\/70 {
  border-bottom-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-b-indigo-500\/75 {
  border-bottom-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-b-indigo-500\/80 {
  border-bottom-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-b-indigo-500\/85 {
  border-bottom-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-b-indigo-500\/90 {
  border-bottom-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-b-indigo-500\/95 {
  border-bottom-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-b-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-600\/0 {
  border-bottom-color: rgb(79 70 229 / 0) !important;
}

.tw-border-b-indigo-600\/10 {
  border-bottom-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-b-indigo-600\/100 {
  border-bottom-color: rgb(79 70 229 / 1) !important;
}

.tw-border-b-indigo-600\/15 {
  border-bottom-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-b-indigo-600\/20 {
  border-bottom-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-b-indigo-600\/25 {
  border-bottom-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-b-indigo-600\/30 {
  border-bottom-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-b-indigo-600\/35 {
  border-bottom-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-b-indigo-600\/40 {
  border-bottom-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-b-indigo-600\/45 {
  border-bottom-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-b-indigo-600\/5 {
  border-bottom-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-b-indigo-600\/50 {
  border-bottom-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-b-indigo-600\/55 {
  border-bottom-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-b-indigo-600\/60 {
  border-bottom-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-b-indigo-600\/65 {
  border-bottom-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-b-indigo-600\/70 {
  border-bottom-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-b-indigo-600\/75 {
  border-bottom-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-b-indigo-600\/80 {
  border-bottom-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-b-indigo-600\/85 {
  border-bottom-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-b-indigo-600\/90 {
  border-bottom-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-b-indigo-600\/95 {
  border-bottom-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-b-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-700\/0 {
  border-bottom-color: rgb(67 56 202 / 0) !important;
}

.tw-border-b-indigo-700\/10 {
  border-bottom-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-b-indigo-700\/100 {
  border-bottom-color: rgb(67 56 202 / 1) !important;
}

.tw-border-b-indigo-700\/15 {
  border-bottom-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-b-indigo-700\/20 {
  border-bottom-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-b-indigo-700\/25 {
  border-bottom-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-b-indigo-700\/30 {
  border-bottom-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-b-indigo-700\/35 {
  border-bottom-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-b-indigo-700\/40 {
  border-bottom-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-b-indigo-700\/45 {
  border-bottom-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-b-indigo-700\/5 {
  border-bottom-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-b-indigo-700\/50 {
  border-bottom-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-b-indigo-700\/55 {
  border-bottom-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-b-indigo-700\/60 {
  border-bottom-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-b-indigo-700\/65 {
  border-bottom-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-b-indigo-700\/70 {
  border-bottom-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-b-indigo-700\/75 {
  border-bottom-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-b-indigo-700\/80 {
  border-bottom-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-b-indigo-700\/85 {
  border-bottom-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-b-indigo-700\/90 {
  border-bottom-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-b-indigo-700\/95 {
  border-bottom-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-b-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-800\/0 {
  border-bottom-color: rgb(55 48 163 / 0) !important;
}

.tw-border-b-indigo-800\/10 {
  border-bottom-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-b-indigo-800\/100 {
  border-bottom-color: rgb(55 48 163 / 1) !important;
}

.tw-border-b-indigo-800\/15 {
  border-bottom-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-b-indigo-800\/20 {
  border-bottom-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-b-indigo-800\/25 {
  border-bottom-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-b-indigo-800\/30 {
  border-bottom-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-b-indigo-800\/35 {
  border-bottom-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-b-indigo-800\/40 {
  border-bottom-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-b-indigo-800\/45 {
  border-bottom-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-b-indigo-800\/5 {
  border-bottom-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-b-indigo-800\/50 {
  border-bottom-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-b-indigo-800\/55 {
  border-bottom-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-b-indigo-800\/60 {
  border-bottom-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-b-indigo-800\/65 {
  border-bottom-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-b-indigo-800\/70 {
  border-bottom-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-b-indigo-800\/75 {
  border-bottom-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-b-indigo-800\/80 {
  border-bottom-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-b-indigo-800\/85 {
  border-bottom-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-b-indigo-800\/90 {
  border-bottom-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-b-indigo-800\/95 {
  border-bottom-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-b-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-900\/0 {
  border-bottom-color: rgb(49 46 129 / 0) !important;
}

.tw-border-b-indigo-900\/10 {
  border-bottom-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-b-indigo-900\/100 {
  border-bottom-color: rgb(49 46 129 / 1) !important;
}

.tw-border-b-indigo-900\/15 {
  border-bottom-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-b-indigo-900\/20 {
  border-bottom-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-b-indigo-900\/25 {
  border-bottom-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-b-indigo-900\/30 {
  border-bottom-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-b-indigo-900\/35 {
  border-bottom-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-b-indigo-900\/40 {
  border-bottom-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-b-indigo-900\/45 {
  border-bottom-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-b-indigo-900\/5 {
  border-bottom-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-b-indigo-900\/50 {
  border-bottom-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-b-indigo-900\/55 {
  border-bottom-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-b-indigo-900\/60 {
  border-bottom-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-b-indigo-900\/65 {
  border-bottom-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-b-indigo-900\/70 {
  border-bottom-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-b-indigo-900\/75 {
  border-bottom-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-b-indigo-900\/80 {
  border-bottom-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-b-indigo-900\/85 {
  border-bottom-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-b-indigo-900\/90 {
  border-bottom-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-b-indigo-900\/95 {
  border-bottom-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-b-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-b-indigo-950\/0 {
  border-bottom-color: rgb(30 27 75 / 0) !important;
}

.tw-border-b-indigo-950\/10 {
  border-bottom-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-b-indigo-950\/100 {
  border-bottom-color: rgb(30 27 75 / 1) !important;
}

.tw-border-b-indigo-950\/15 {
  border-bottom-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-b-indigo-950\/20 {
  border-bottom-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-b-indigo-950\/25 {
  border-bottom-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-b-indigo-950\/30 {
  border-bottom-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-b-indigo-950\/35 {
  border-bottom-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-b-indigo-950\/40 {
  border-bottom-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-b-indigo-950\/45 {
  border-bottom-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-b-indigo-950\/5 {
  border-bottom-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-b-indigo-950\/50 {
  border-bottom-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-b-indigo-950\/55 {
  border-bottom-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-b-indigo-950\/60 {
  border-bottom-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-b-indigo-950\/65 {
  border-bottom-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-b-indigo-950\/70 {
  border-bottom-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-b-indigo-950\/75 {
  border-bottom-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-b-indigo-950\/80 {
  border-bottom-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-b-indigo-950\/85 {
  border-bottom-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-b-indigo-950\/90 {
  border-bottom-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-b-indigo-950\/95 {
  border-bottom-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-b-inherit {
  border-bottom-color: inherit !important;
}

.tw-border-b-lime-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-100\/0 {
  border-bottom-color: rgb(236 252 203 / 0) !important;
}

.tw-border-b-lime-100\/10 {
  border-bottom-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-b-lime-100\/100 {
  border-bottom-color: rgb(236 252 203 / 1) !important;
}

.tw-border-b-lime-100\/15 {
  border-bottom-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-b-lime-100\/20 {
  border-bottom-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-b-lime-100\/25 {
  border-bottom-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-b-lime-100\/30 {
  border-bottom-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-b-lime-100\/35 {
  border-bottom-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-b-lime-100\/40 {
  border-bottom-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-b-lime-100\/45 {
  border-bottom-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-b-lime-100\/5 {
  border-bottom-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-b-lime-100\/50 {
  border-bottom-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-b-lime-100\/55 {
  border-bottom-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-b-lime-100\/60 {
  border-bottom-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-b-lime-100\/65 {
  border-bottom-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-b-lime-100\/70 {
  border-bottom-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-b-lime-100\/75 {
  border-bottom-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-b-lime-100\/80 {
  border-bottom-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-b-lime-100\/85 {
  border-bottom-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-b-lime-100\/90 {
  border-bottom-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-b-lime-100\/95 {
  border-bottom-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-b-lime-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-200\/0 {
  border-bottom-color: rgb(217 249 157 / 0) !important;
}

.tw-border-b-lime-200\/10 {
  border-bottom-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-b-lime-200\/100 {
  border-bottom-color: rgb(217 249 157 / 1) !important;
}

.tw-border-b-lime-200\/15 {
  border-bottom-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-b-lime-200\/20 {
  border-bottom-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-b-lime-200\/25 {
  border-bottom-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-b-lime-200\/30 {
  border-bottom-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-b-lime-200\/35 {
  border-bottom-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-b-lime-200\/40 {
  border-bottom-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-b-lime-200\/45 {
  border-bottom-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-b-lime-200\/5 {
  border-bottom-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-b-lime-200\/50 {
  border-bottom-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-b-lime-200\/55 {
  border-bottom-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-b-lime-200\/60 {
  border-bottom-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-b-lime-200\/65 {
  border-bottom-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-b-lime-200\/70 {
  border-bottom-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-b-lime-200\/75 {
  border-bottom-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-b-lime-200\/80 {
  border-bottom-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-b-lime-200\/85 {
  border-bottom-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-b-lime-200\/90 {
  border-bottom-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-b-lime-200\/95 {
  border-bottom-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-b-lime-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-300\/0 {
  border-bottom-color: rgb(190 242 100 / 0) !important;
}

.tw-border-b-lime-300\/10 {
  border-bottom-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-b-lime-300\/100 {
  border-bottom-color: rgb(190 242 100 / 1) !important;
}

.tw-border-b-lime-300\/15 {
  border-bottom-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-b-lime-300\/20 {
  border-bottom-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-b-lime-300\/25 {
  border-bottom-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-b-lime-300\/30 {
  border-bottom-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-b-lime-300\/35 {
  border-bottom-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-b-lime-300\/40 {
  border-bottom-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-b-lime-300\/45 {
  border-bottom-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-b-lime-300\/5 {
  border-bottom-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-b-lime-300\/50 {
  border-bottom-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-b-lime-300\/55 {
  border-bottom-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-b-lime-300\/60 {
  border-bottom-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-b-lime-300\/65 {
  border-bottom-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-b-lime-300\/70 {
  border-bottom-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-b-lime-300\/75 {
  border-bottom-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-b-lime-300\/80 {
  border-bottom-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-b-lime-300\/85 {
  border-bottom-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-b-lime-300\/90 {
  border-bottom-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-b-lime-300\/95 {
  border-bottom-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-b-lime-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-400\/0 {
  border-bottom-color: rgb(163 230 53 / 0) !important;
}

.tw-border-b-lime-400\/10 {
  border-bottom-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-b-lime-400\/100 {
  border-bottom-color: rgb(163 230 53 / 1) !important;
}

.tw-border-b-lime-400\/15 {
  border-bottom-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-b-lime-400\/20 {
  border-bottom-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-b-lime-400\/25 {
  border-bottom-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-b-lime-400\/30 {
  border-bottom-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-b-lime-400\/35 {
  border-bottom-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-b-lime-400\/40 {
  border-bottom-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-b-lime-400\/45 {
  border-bottom-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-b-lime-400\/5 {
  border-bottom-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-b-lime-400\/50 {
  border-bottom-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-b-lime-400\/55 {
  border-bottom-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-b-lime-400\/60 {
  border-bottom-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-b-lime-400\/65 {
  border-bottom-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-b-lime-400\/70 {
  border-bottom-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-b-lime-400\/75 {
  border-bottom-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-b-lime-400\/80 {
  border-bottom-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-b-lime-400\/85 {
  border-bottom-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-b-lime-400\/90 {
  border-bottom-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-b-lime-400\/95 {
  border-bottom-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-b-lime-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-50\/0 {
  border-bottom-color: rgb(247 254 231 / 0) !important;
}

.tw-border-b-lime-50\/10 {
  border-bottom-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-b-lime-50\/100 {
  border-bottom-color: rgb(247 254 231 / 1) !important;
}

.tw-border-b-lime-50\/15 {
  border-bottom-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-b-lime-50\/20 {
  border-bottom-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-b-lime-50\/25 {
  border-bottom-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-b-lime-50\/30 {
  border-bottom-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-b-lime-50\/35 {
  border-bottom-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-b-lime-50\/40 {
  border-bottom-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-b-lime-50\/45 {
  border-bottom-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-b-lime-50\/5 {
  border-bottom-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-b-lime-50\/50 {
  border-bottom-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-b-lime-50\/55 {
  border-bottom-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-b-lime-50\/60 {
  border-bottom-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-b-lime-50\/65 {
  border-bottom-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-b-lime-50\/70 {
  border-bottom-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-b-lime-50\/75 {
  border-bottom-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-b-lime-50\/80 {
  border-bottom-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-b-lime-50\/85 {
  border-bottom-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-b-lime-50\/90 {
  border-bottom-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-b-lime-50\/95 {
  border-bottom-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-b-lime-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-500\/0 {
  border-bottom-color: rgb(132 204 22 / 0) !important;
}

.tw-border-b-lime-500\/10 {
  border-bottom-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-b-lime-500\/100 {
  border-bottom-color: rgb(132 204 22 / 1) !important;
}

.tw-border-b-lime-500\/15 {
  border-bottom-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-b-lime-500\/20 {
  border-bottom-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-b-lime-500\/25 {
  border-bottom-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-b-lime-500\/30 {
  border-bottom-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-b-lime-500\/35 {
  border-bottom-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-b-lime-500\/40 {
  border-bottom-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-b-lime-500\/45 {
  border-bottom-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-b-lime-500\/5 {
  border-bottom-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-b-lime-500\/50 {
  border-bottom-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-b-lime-500\/55 {
  border-bottom-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-b-lime-500\/60 {
  border-bottom-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-b-lime-500\/65 {
  border-bottom-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-b-lime-500\/70 {
  border-bottom-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-b-lime-500\/75 {
  border-bottom-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-b-lime-500\/80 {
  border-bottom-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-b-lime-500\/85 {
  border-bottom-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-b-lime-500\/90 {
  border-bottom-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-b-lime-500\/95 {
  border-bottom-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-b-lime-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-600\/0 {
  border-bottom-color: rgb(101 163 13 / 0) !important;
}

.tw-border-b-lime-600\/10 {
  border-bottom-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-b-lime-600\/100 {
  border-bottom-color: rgb(101 163 13 / 1) !important;
}

.tw-border-b-lime-600\/15 {
  border-bottom-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-b-lime-600\/20 {
  border-bottom-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-b-lime-600\/25 {
  border-bottom-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-b-lime-600\/30 {
  border-bottom-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-b-lime-600\/35 {
  border-bottom-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-b-lime-600\/40 {
  border-bottom-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-b-lime-600\/45 {
  border-bottom-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-b-lime-600\/5 {
  border-bottom-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-b-lime-600\/50 {
  border-bottom-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-b-lime-600\/55 {
  border-bottom-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-b-lime-600\/60 {
  border-bottom-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-b-lime-600\/65 {
  border-bottom-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-b-lime-600\/70 {
  border-bottom-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-b-lime-600\/75 {
  border-bottom-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-b-lime-600\/80 {
  border-bottom-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-b-lime-600\/85 {
  border-bottom-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-b-lime-600\/90 {
  border-bottom-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-b-lime-600\/95 {
  border-bottom-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-b-lime-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-700\/0 {
  border-bottom-color: rgb(77 124 15 / 0) !important;
}

.tw-border-b-lime-700\/10 {
  border-bottom-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-b-lime-700\/100 {
  border-bottom-color: rgb(77 124 15 / 1) !important;
}

.tw-border-b-lime-700\/15 {
  border-bottom-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-b-lime-700\/20 {
  border-bottom-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-b-lime-700\/25 {
  border-bottom-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-b-lime-700\/30 {
  border-bottom-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-b-lime-700\/35 {
  border-bottom-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-b-lime-700\/40 {
  border-bottom-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-b-lime-700\/45 {
  border-bottom-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-b-lime-700\/5 {
  border-bottom-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-b-lime-700\/50 {
  border-bottom-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-b-lime-700\/55 {
  border-bottom-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-b-lime-700\/60 {
  border-bottom-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-b-lime-700\/65 {
  border-bottom-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-b-lime-700\/70 {
  border-bottom-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-b-lime-700\/75 {
  border-bottom-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-b-lime-700\/80 {
  border-bottom-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-b-lime-700\/85 {
  border-bottom-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-b-lime-700\/90 {
  border-bottom-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-b-lime-700\/95 {
  border-bottom-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-b-lime-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-800\/0 {
  border-bottom-color: rgb(63 98 18 / 0) !important;
}

.tw-border-b-lime-800\/10 {
  border-bottom-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-b-lime-800\/100 {
  border-bottom-color: rgb(63 98 18 / 1) !important;
}

.tw-border-b-lime-800\/15 {
  border-bottom-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-b-lime-800\/20 {
  border-bottom-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-b-lime-800\/25 {
  border-bottom-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-b-lime-800\/30 {
  border-bottom-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-b-lime-800\/35 {
  border-bottom-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-b-lime-800\/40 {
  border-bottom-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-b-lime-800\/45 {
  border-bottom-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-b-lime-800\/5 {
  border-bottom-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-b-lime-800\/50 {
  border-bottom-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-b-lime-800\/55 {
  border-bottom-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-b-lime-800\/60 {
  border-bottom-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-b-lime-800\/65 {
  border-bottom-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-b-lime-800\/70 {
  border-bottom-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-b-lime-800\/75 {
  border-bottom-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-b-lime-800\/80 {
  border-bottom-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-b-lime-800\/85 {
  border-bottom-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-b-lime-800\/90 {
  border-bottom-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-b-lime-800\/95 {
  border-bottom-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-b-lime-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-900\/0 {
  border-bottom-color: rgb(54 83 20 / 0) !important;
}

.tw-border-b-lime-900\/10 {
  border-bottom-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-b-lime-900\/100 {
  border-bottom-color: rgb(54 83 20 / 1) !important;
}

.tw-border-b-lime-900\/15 {
  border-bottom-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-b-lime-900\/20 {
  border-bottom-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-b-lime-900\/25 {
  border-bottom-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-b-lime-900\/30 {
  border-bottom-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-b-lime-900\/35 {
  border-bottom-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-b-lime-900\/40 {
  border-bottom-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-b-lime-900\/45 {
  border-bottom-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-b-lime-900\/5 {
  border-bottom-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-b-lime-900\/50 {
  border-bottom-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-b-lime-900\/55 {
  border-bottom-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-b-lime-900\/60 {
  border-bottom-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-b-lime-900\/65 {
  border-bottom-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-b-lime-900\/70 {
  border-bottom-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-b-lime-900\/75 {
  border-bottom-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-b-lime-900\/80 {
  border-bottom-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-b-lime-900\/85 {
  border-bottom-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-b-lime-900\/90 {
  border-bottom-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-b-lime-900\/95 {
  border-bottom-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-b-lime-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-b-lime-950\/0 {
  border-bottom-color: rgb(26 46 5 / 0) !important;
}

.tw-border-b-lime-950\/10 {
  border-bottom-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-b-lime-950\/100 {
  border-bottom-color: rgb(26 46 5 / 1) !important;
}

.tw-border-b-lime-950\/15 {
  border-bottom-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-b-lime-950\/20 {
  border-bottom-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-b-lime-950\/25 {
  border-bottom-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-b-lime-950\/30 {
  border-bottom-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-b-lime-950\/35 {
  border-bottom-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-b-lime-950\/40 {
  border-bottom-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-b-lime-950\/45 {
  border-bottom-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-b-lime-950\/5 {
  border-bottom-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-b-lime-950\/50 {
  border-bottom-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-b-lime-950\/55 {
  border-bottom-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-b-lime-950\/60 {
  border-bottom-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-b-lime-950\/65 {
  border-bottom-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-b-lime-950\/70 {
  border-bottom-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-b-lime-950\/75 {
  border-bottom-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-b-lime-950\/80 {
  border-bottom-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-b-lime-950\/85 {
  border-bottom-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-b-lime-950\/90 {
  border-bottom-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-b-lime-950\/95 {
  border-bottom-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-b-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-100\/0 {
  border-bottom-color: rgb(245 245 245 / 0) !important;
}

.tw-border-b-neutral-100\/10 {
  border-bottom-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-b-neutral-100\/100 {
  border-bottom-color: rgb(245 245 245 / 1) !important;
}

.tw-border-b-neutral-100\/15 {
  border-bottom-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-b-neutral-100\/20 {
  border-bottom-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-b-neutral-100\/25 {
  border-bottom-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-b-neutral-100\/30 {
  border-bottom-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-b-neutral-100\/35 {
  border-bottom-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-b-neutral-100\/40 {
  border-bottom-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-b-neutral-100\/45 {
  border-bottom-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-b-neutral-100\/5 {
  border-bottom-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-b-neutral-100\/50 {
  border-bottom-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-b-neutral-100\/55 {
  border-bottom-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-b-neutral-100\/60 {
  border-bottom-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-b-neutral-100\/65 {
  border-bottom-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-b-neutral-100\/70 {
  border-bottom-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-b-neutral-100\/75 {
  border-bottom-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-b-neutral-100\/80 {
  border-bottom-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-b-neutral-100\/85 {
  border-bottom-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-b-neutral-100\/90 {
  border-bottom-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-b-neutral-100\/95 {
  border-bottom-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-b-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-200\/0 {
  border-bottom-color: rgb(229 229 229 / 0) !important;
}

.tw-border-b-neutral-200\/10 {
  border-bottom-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-b-neutral-200\/100 {
  border-bottom-color: rgb(229 229 229 / 1) !important;
}

.tw-border-b-neutral-200\/15 {
  border-bottom-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-b-neutral-200\/20 {
  border-bottom-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-b-neutral-200\/25 {
  border-bottom-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-b-neutral-200\/30 {
  border-bottom-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-b-neutral-200\/35 {
  border-bottom-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-b-neutral-200\/40 {
  border-bottom-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-b-neutral-200\/45 {
  border-bottom-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-b-neutral-200\/5 {
  border-bottom-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-b-neutral-200\/50 {
  border-bottom-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-b-neutral-200\/55 {
  border-bottom-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-b-neutral-200\/60 {
  border-bottom-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-b-neutral-200\/65 {
  border-bottom-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-b-neutral-200\/70 {
  border-bottom-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-b-neutral-200\/75 {
  border-bottom-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-b-neutral-200\/80 {
  border-bottom-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-b-neutral-200\/85 {
  border-bottom-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-b-neutral-200\/90 {
  border-bottom-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-b-neutral-200\/95 {
  border-bottom-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-b-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-300\/0 {
  border-bottom-color: rgb(212 212 212 / 0) !important;
}

.tw-border-b-neutral-300\/10 {
  border-bottom-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-b-neutral-300\/100 {
  border-bottom-color: rgb(212 212 212 / 1) !important;
}

.tw-border-b-neutral-300\/15 {
  border-bottom-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-b-neutral-300\/20 {
  border-bottom-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-b-neutral-300\/25 {
  border-bottom-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-b-neutral-300\/30 {
  border-bottom-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-b-neutral-300\/35 {
  border-bottom-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-b-neutral-300\/40 {
  border-bottom-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-b-neutral-300\/45 {
  border-bottom-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-b-neutral-300\/5 {
  border-bottom-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-b-neutral-300\/50 {
  border-bottom-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-b-neutral-300\/55 {
  border-bottom-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-b-neutral-300\/60 {
  border-bottom-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-b-neutral-300\/65 {
  border-bottom-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-b-neutral-300\/70 {
  border-bottom-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-b-neutral-300\/75 {
  border-bottom-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-b-neutral-300\/80 {
  border-bottom-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-b-neutral-300\/85 {
  border-bottom-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-b-neutral-300\/90 {
  border-bottom-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-b-neutral-300\/95 {
  border-bottom-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-b-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-400\/0 {
  border-bottom-color: rgb(163 163 163 / 0) !important;
}

.tw-border-b-neutral-400\/10 {
  border-bottom-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-b-neutral-400\/100 {
  border-bottom-color: rgb(163 163 163 / 1) !important;
}

.tw-border-b-neutral-400\/15 {
  border-bottom-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-b-neutral-400\/20 {
  border-bottom-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-b-neutral-400\/25 {
  border-bottom-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-b-neutral-400\/30 {
  border-bottom-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-b-neutral-400\/35 {
  border-bottom-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-b-neutral-400\/40 {
  border-bottom-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-b-neutral-400\/45 {
  border-bottom-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-b-neutral-400\/5 {
  border-bottom-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-b-neutral-400\/50 {
  border-bottom-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-b-neutral-400\/55 {
  border-bottom-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-b-neutral-400\/60 {
  border-bottom-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-b-neutral-400\/65 {
  border-bottom-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-b-neutral-400\/70 {
  border-bottom-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-b-neutral-400\/75 {
  border-bottom-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-b-neutral-400\/80 {
  border-bottom-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-b-neutral-400\/85 {
  border-bottom-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-b-neutral-400\/90 {
  border-bottom-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-b-neutral-400\/95 {
  border-bottom-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-b-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-50\/0 {
  border-bottom-color: rgb(250 250 250 / 0) !important;
}

.tw-border-b-neutral-50\/10 {
  border-bottom-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-b-neutral-50\/100 {
  border-bottom-color: rgb(250 250 250 / 1) !important;
}

.tw-border-b-neutral-50\/15 {
  border-bottom-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-b-neutral-50\/20 {
  border-bottom-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-b-neutral-50\/25 {
  border-bottom-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-b-neutral-50\/30 {
  border-bottom-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-b-neutral-50\/35 {
  border-bottom-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-b-neutral-50\/40 {
  border-bottom-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-b-neutral-50\/45 {
  border-bottom-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-b-neutral-50\/5 {
  border-bottom-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-b-neutral-50\/50 {
  border-bottom-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-b-neutral-50\/55 {
  border-bottom-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-b-neutral-50\/60 {
  border-bottom-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-b-neutral-50\/65 {
  border-bottom-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-b-neutral-50\/70 {
  border-bottom-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-b-neutral-50\/75 {
  border-bottom-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-b-neutral-50\/80 {
  border-bottom-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-b-neutral-50\/85 {
  border-bottom-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-b-neutral-50\/90 {
  border-bottom-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-b-neutral-50\/95 {
  border-bottom-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-b-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-500\/0 {
  border-bottom-color: rgb(115 115 115 / 0) !important;
}

.tw-border-b-neutral-500\/10 {
  border-bottom-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-b-neutral-500\/100 {
  border-bottom-color: rgb(115 115 115 / 1) !important;
}

.tw-border-b-neutral-500\/15 {
  border-bottom-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-b-neutral-500\/20 {
  border-bottom-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-b-neutral-500\/25 {
  border-bottom-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-b-neutral-500\/30 {
  border-bottom-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-b-neutral-500\/35 {
  border-bottom-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-b-neutral-500\/40 {
  border-bottom-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-b-neutral-500\/45 {
  border-bottom-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-b-neutral-500\/5 {
  border-bottom-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-b-neutral-500\/50 {
  border-bottom-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-b-neutral-500\/55 {
  border-bottom-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-b-neutral-500\/60 {
  border-bottom-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-b-neutral-500\/65 {
  border-bottom-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-b-neutral-500\/70 {
  border-bottom-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-b-neutral-500\/75 {
  border-bottom-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-b-neutral-500\/80 {
  border-bottom-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-b-neutral-500\/85 {
  border-bottom-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-b-neutral-500\/90 {
  border-bottom-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-b-neutral-500\/95 {
  border-bottom-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-b-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-600\/0 {
  border-bottom-color: rgb(82 82 82 / 0) !important;
}

.tw-border-b-neutral-600\/10 {
  border-bottom-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-b-neutral-600\/100 {
  border-bottom-color: rgb(82 82 82 / 1) !important;
}

.tw-border-b-neutral-600\/15 {
  border-bottom-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-b-neutral-600\/20 {
  border-bottom-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-b-neutral-600\/25 {
  border-bottom-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-b-neutral-600\/30 {
  border-bottom-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-b-neutral-600\/35 {
  border-bottom-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-b-neutral-600\/40 {
  border-bottom-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-b-neutral-600\/45 {
  border-bottom-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-b-neutral-600\/5 {
  border-bottom-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-b-neutral-600\/50 {
  border-bottom-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-b-neutral-600\/55 {
  border-bottom-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-b-neutral-600\/60 {
  border-bottom-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-b-neutral-600\/65 {
  border-bottom-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-b-neutral-600\/70 {
  border-bottom-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-b-neutral-600\/75 {
  border-bottom-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-b-neutral-600\/80 {
  border-bottom-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-b-neutral-600\/85 {
  border-bottom-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-b-neutral-600\/90 {
  border-bottom-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-b-neutral-600\/95 {
  border-bottom-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-b-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-700\/0 {
  border-bottom-color: rgb(64 64 64 / 0) !important;
}

.tw-border-b-neutral-700\/10 {
  border-bottom-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-b-neutral-700\/100 {
  border-bottom-color: rgb(64 64 64 / 1) !important;
}

.tw-border-b-neutral-700\/15 {
  border-bottom-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-b-neutral-700\/20 {
  border-bottom-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-b-neutral-700\/25 {
  border-bottom-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-b-neutral-700\/30 {
  border-bottom-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-b-neutral-700\/35 {
  border-bottom-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-b-neutral-700\/40 {
  border-bottom-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-b-neutral-700\/45 {
  border-bottom-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-b-neutral-700\/5 {
  border-bottom-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-b-neutral-700\/50 {
  border-bottom-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-b-neutral-700\/55 {
  border-bottom-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-b-neutral-700\/60 {
  border-bottom-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-b-neutral-700\/65 {
  border-bottom-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-b-neutral-700\/70 {
  border-bottom-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-b-neutral-700\/75 {
  border-bottom-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-b-neutral-700\/80 {
  border-bottom-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-b-neutral-700\/85 {
  border-bottom-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-b-neutral-700\/90 {
  border-bottom-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-b-neutral-700\/95 {
  border-bottom-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-b-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-800\/0 {
  border-bottom-color: rgb(38 38 38 / 0) !important;
}

.tw-border-b-neutral-800\/10 {
  border-bottom-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-b-neutral-800\/100 {
  border-bottom-color: rgb(38 38 38 / 1) !important;
}

.tw-border-b-neutral-800\/15 {
  border-bottom-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-b-neutral-800\/20 {
  border-bottom-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-b-neutral-800\/25 {
  border-bottom-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-b-neutral-800\/30 {
  border-bottom-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-b-neutral-800\/35 {
  border-bottom-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-b-neutral-800\/40 {
  border-bottom-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-b-neutral-800\/45 {
  border-bottom-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-b-neutral-800\/5 {
  border-bottom-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-b-neutral-800\/50 {
  border-bottom-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-b-neutral-800\/55 {
  border-bottom-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-b-neutral-800\/60 {
  border-bottom-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-b-neutral-800\/65 {
  border-bottom-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-b-neutral-800\/70 {
  border-bottom-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-b-neutral-800\/75 {
  border-bottom-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-b-neutral-800\/80 {
  border-bottom-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-b-neutral-800\/85 {
  border-bottom-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-b-neutral-800\/90 {
  border-bottom-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-b-neutral-800\/95 {
  border-bottom-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-b-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-900\/0 {
  border-bottom-color: rgb(23 23 23 / 0) !important;
}

.tw-border-b-neutral-900\/10 {
  border-bottom-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-b-neutral-900\/100 {
  border-bottom-color: rgb(23 23 23 / 1) !important;
}

.tw-border-b-neutral-900\/15 {
  border-bottom-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-b-neutral-900\/20 {
  border-bottom-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-b-neutral-900\/25 {
  border-bottom-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-b-neutral-900\/30 {
  border-bottom-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-b-neutral-900\/35 {
  border-bottom-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-b-neutral-900\/40 {
  border-bottom-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-b-neutral-900\/45 {
  border-bottom-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-b-neutral-900\/5 {
  border-bottom-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-b-neutral-900\/50 {
  border-bottom-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-b-neutral-900\/55 {
  border-bottom-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-b-neutral-900\/60 {
  border-bottom-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-b-neutral-900\/65 {
  border-bottom-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-b-neutral-900\/70 {
  border-bottom-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-b-neutral-900\/75 {
  border-bottom-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-b-neutral-900\/80 {
  border-bottom-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-b-neutral-900\/85 {
  border-bottom-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-b-neutral-900\/90 {
  border-bottom-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-b-neutral-900\/95 {
  border-bottom-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-b-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-b-neutral-950\/0 {
  border-bottom-color: rgb(10 10 10 / 0) !important;
}

.tw-border-b-neutral-950\/10 {
  border-bottom-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-b-neutral-950\/100 {
  border-bottom-color: rgb(10 10 10 / 1) !important;
}

.tw-border-b-neutral-950\/15 {
  border-bottom-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-b-neutral-950\/20 {
  border-bottom-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-b-neutral-950\/25 {
  border-bottom-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-b-neutral-950\/30 {
  border-bottom-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-b-neutral-950\/35 {
  border-bottom-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-b-neutral-950\/40 {
  border-bottom-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-b-neutral-950\/45 {
  border-bottom-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-b-neutral-950\/5 {
  border-bottom-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-b-neutral-950\/50 {
  border-bottom-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-b-neutral-950\/55 {
  border-bottom-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-b-neutral-950\/60 {
  border-bottom-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-b-neutral-950\/65 {
  border-bottom-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-b-neutral-950\/70 {
  border-bottom-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-b-neutral-950\/75 {
  border-bottom-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-b-neutral-950\/80 {
  border-bottom-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-b-neutral-950\/85 {
  border-bottom-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-b-neutral-950\/90 {
  border-bottom-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-b-neutral-950\/95 {
  border-bottom-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-b-orange-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-100\/0 {
  border-bottom-color: rgb(255 237 213 / 0) !important;
}

.tw-border-b-orange-100\/10 {
  border-bottom-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-b-orange-100\/100 {
  border-bottom-color: rgb(255 237 213 / 1) !important;
}

.tw-border-b-orange-100\/15 {
  border-bottom-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-b-orange-100\/20 {
  border-bottom-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-b-orange-100\/25 {
  border-bottom-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-b-orange-100\/30 {
  border-bottom-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-b-orange-100\/35 {
  border-bottom-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-b-orange-100\/40 {
  border-bottom-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-b-orange-100\/45 {
  border-bottom-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-b-orange-100\/5 {
  border-bottom-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-b-orange-100\/50 {
  border-bottom-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-b-orange-100\/55 {
  border-bottom-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-b-orange-100\/60 {
  border-bottom-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-b-orange-100\/65 {
  border-bottom-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-b-orange-100\/70 {
  border-bottom-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-b-orange-100\/75 {
  border-bottom-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-b-orange-100\/80 {
  border-bottom-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-b-orange-100\/85 {
  border-bottom-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-b-orange-100\/90 {
  border-bottom-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-b-orange-100\/95 {
  border-bottom-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-b-orange-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-200\/0 {
  border-bottom-color: rgb(254 215 170 / 0) !important;
}

.tw-border-b-orange-200\/10 {
  border-bottom-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-b-orange-200\/100 {
  border-bottom-color: rgb(254 215 170 / 1) !important;
}

.tw-border-b-orange-200\/15 {
  border-bottom-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-b-orange-200\/20 {
  border-bottom-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-b-orange-200\/25 {
  border-bottom-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-b-orange-200\/30 {
  border-bottom-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-b-orange-200\/35 {
  border-bottom-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-b-orange-200\/40 {
  border-bottom-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-b-orange-200\/45 {
  border-bottom-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-b-orange-200\/5 {
  border-bottom-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-b-orange-200\/50 {
  border-bottom-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-b-orange-200\/55 {
  border-bottom-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-b-orange-200\/60 {
  border-bottom-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-b-orange-200\/65 {
  border-bottom-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-b-orange-200\/70 {
  border-bottom-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-b-orange-200\/75 {
  border-bottom-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-b-orange-200\/80 {
  border-bottom-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-b-orange-200\/85 {
  border-bottom-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-b-orange-200\/90 {
  border-bottom-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-b-orange-200\/95 {
  border-bottom-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-b-orange-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-300\/0 {
  border-bottom-color: rgb(253 186 116 / 0) !important;
}

.tw-border-b-orange-300\/10 {
  border-bottom-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-b-orange-300\/100 {
  border-bottom-color: rgb(253 186 116 / 1) !important;
}

.tw-border-b-orange-300\/15 {
  border-bottom-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-b-orange-300\/20 {
  border-bottom-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-b-orange-300\/25 {
  border-bottom-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-b-orange-300\/30 {
  border-bottom-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-b-orange-300\/35 {
  border-bottom-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-b-orange-300\/40 {
  border-bottom-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-b-orange-300\/45 {
  border-bottom-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-b-orange-300\/5 {
  border-bottom-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-b-orange-300\/50 {
  border-bottom-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-b-orange-300\/55 {
  border-bottom-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-b-orange-300\/60 {
  border-bottom-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-b-orange-300\/65 {
  border-bottom-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-b-orange-300\/70 {
  border-bottom-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-b-orange-300\/75 {
  border-bottom-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-b-orange-300\/80 {
  border-bottom-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-b-orange-300\/85 {
  border-bottom-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-b-orange-300\/90 {
  border-bottom-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-b-orange-300\/95 {
  border-bottom-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-b-orange-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-400\/0 {
  border-bottom-color: rgb(251 146 60 / 0) !important;
}

.tw-border-b-orange-400\/10 {
  border-bottom-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-b-orange-400\/100 {
  border-bottom-color: rgb(251 146 60 / 1) !important;
}

.tw-border-b-orange-400\/15 {
  border-bottom-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-b-orange-400\/20 {
  border-bottom-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-b-orange-400\/25 {
  border-bottom-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-b-orange-400\/30 {
  border-bottom-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-b-orange-400\/35 {
  border-bottom-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-b-orange-400\/40 {
  border-bottom-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-b-orange-400\/45 {
  border-bottom-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-b-orange-400\/5 {
  border-bottom-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-b-orange-400\/50 {
  border-bottom-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-b-orange-400\/55 {
  border-bottom-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-b-orange-400\/60 {
  border-bottom-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-b-orange-400\/65 {
  border-bottom-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-b-orange-400\/70 {
  border-bottom-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-b-orange-400\/75 {
  border-bottom-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-b-orange-400\/80 {
  border-bottom-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-b-orange-400\/85 {
  border-bottom-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-b-orange-400\/90 {
  border-bottom-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-b-orange-400\/95 {
  border-bottom-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-b-orange-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-50\/0 {
  border-bottom-color: rgb(255 247 237 / 0) !important;
}

.tw-border-b-orange-50\/10 {
  border-bottom-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-b-orange-50\/100 {
  border-bottom-color: rgb(255 247 237 / 1) !important;
}

.tw-border-b-orange-50\/15 {
  border-bottom-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-b-orange-50\/20 {
  border-bottom-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-b-orange-50\/25 {
  border-bottom-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-b-orange-50\/30 {
  border-bottom-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-b-orange-50\/35 {
  border-bottom-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-b-orange-50\/40 {
  border-bottom-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-b-orange-50\/45 {
  border-bottom-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-b-orange-50\/5 {
  border-bottom-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-b-orange-50\/50 {
  border-bottom-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-b-orange-50\/55 {
  border-bottom-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-b-orange-50\/60 {
  border-bottom-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-b-orange-50\/65 {
  border-bottom-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-b-orange-50\/70 {
  border-bottom-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-b-orange-50\/75 {
  border-bottom-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-b-orange-50\/80 {
  border-bottom-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-b-orange-50\/85 {
  border-bottom-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-b-orange-50\/90 {
  border-bottom-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-b-orange-50\/95 {
  border-bottom-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-b-orange-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-500\/0 {
  border-bottom-color: rgb(249 115 22 / 0) !important;
}

.tw-border-b-orange-500\/10 {
  border-bottom-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-b-orange-500\/100 {
  border-bottom-color: rgb(249 115 22 / 1) !important;
}

.tw-border-b-orange-500\/15 {
  border-bottom-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-b-orange-500\/20 {
  border-bottom-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-b-orange-500\/25 {
  border-bottom-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-b-orange-500\/30 {
  border-bottom-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-b-orange-500\/35 {
  border-bottom-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-b-orange-500\/40 {
  border-bottom-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-b-orange-500\/45 {
  border-bottom-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-b-orange-500\/5 {
  border-bottom-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-b-orange-500\/50 {
  border-bottom-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-b-orange-500\/55 {
  border-bottom-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-b-orange-500\/60 {
  border-bottom-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-b-orange-500\/65 {
  border-bottom-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-b-orange-500\/70 {
  border-bottom-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-b-orange-500\/75 {
  border-bottom-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-b-orange-500\/80 {
  border-bottom-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-b-orange-500\/85 {
  border-bottom-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-b-orange-500\/90 {
  border-bottom-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-b-orange-500\/95 {
  border-bottom-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-b-orange-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-600\/0 {
  border-bottom-color: rgb(234 88 12 / 0) !important;
}

.tw-border-b-orange-600\/10 {
  border-bottom-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-b-orange-600\/100 {
  border-bottom-color: rgb(234 88 12 / 1) !important;
}

.tw-border-b-orange-600\/15 {
  border-bottom-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-b-orange-600\/20 {
  border-bottom-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-b-orange-600\/25 {
  border-bottom-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-b-orange-600\/30 {
  border-bottom-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-b-orange-600\/35 {
  border-bottom-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-b-orange-600\/40 {
  border-bottom-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-b-orange-600\/45 {
  border-bottom-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-b-orange-600\/5 {
  border-bottom-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-b-orange-600\/50 {
  border-bottom-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-b-orange-600\/55 {
  border-bottom-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-b-orange-600\/60 {
  border-bottom-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-b-orange-600\/65 {
  border-bottom-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-b-orange-600\/70 {
  border-bottom-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-b-orange-600\/75 {
  border-bottom-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-b-orange-600\/80 {
  border-bottom-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-b-orange-600\/85 {
  border-bottom-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-b-orange-600\/90 {
  border-bottom-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-b-orange-600\/95 {
  border-bottom-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-b-orange-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-700\/0 {
  border-bottom-color: rgb(194 65 12 / 0) !important;
}

.tw-border-b-orange-700\/10 {
  border-bottom-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-b-orange-700\/100 {
  border-bottom-color: rgb(194 65 12 / 1) !important;
}

.tw-border-b-orange-700\/15 {
  border-bottom-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-b-orange-700\/20 {
  border-bottom-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-b-orange-700\/25 {
  border-bottom-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-b-orange-700\/30 {
  border-bottom-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-b-orange-700\/35 {
  border-bottom-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-b-orange-700\/40 {
  border-bottom-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-b-orange-700\/45 {
  border-bottom-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-b-orange-700\/5 {
  border-bottom-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-b-orange-700\/50 {
  border-bottom-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-b-orange-700\/55 {
  border-bottom-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-b-orange-700\/60 {
  border-bottom-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-b-orange-700\/65 {
  border-bottom-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-b-orange-700\/70 {
  border-bottom-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-b-orange-700\/75 {
  border-bottom-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-b-orange-700\/80 {
  border-bottom-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-b-orange-700\/85 {
  border-bottom-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-b-orange-700\/90 {
  border-bottom-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-b-orange-700\/95 {
  border-bottom-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-b-orange-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-800\/0 {
  border-bottom-color: rgb(154 52 18 / 0) !important;
}

.tw-border-b-orange-800\/10 {
  border-bottom-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-b-orange-800\/100 {
  border-bottom-color: rgb(154 52 18 / 1) !important;
}

.tw-border-b-orange-800\/15 {
  border-bottom-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-b-orange-800\/20 {
  border-bottom-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-b-orange-800\/25 {
  border-bottom-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-b-orange-800\/30 {
  border-bottom-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-b-orange-800\/35 {
  border-bottom-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-b-orange-800\/40 {
  border-bottom-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-b-orange-800\/45 {
  border-bottom-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-b-orange-800\/5 {
  border-bottom-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-b-orange-800\/50 {
  border-bottom-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-b-orange-800\/55 {
  border-bottom-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-b-orange-800\/60 {
  border-bottom-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-b-orange-800\/65 {
  border-bottom-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-b-orange-800\/70 {
  border-bottom-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-b-orange-800\/75 {
  border-bottom-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-b-orange-800\/80 {
  border-bottom-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-b-orange-800\/85 {
  border-bottom-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-b-orange-800\/90 {
  border-bottom-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-b-orange-800\/95 {
  border-bottom-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-b-orange-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-900\/0 {
  border-bottom-color: rgb(124 45 18 / 0) !important;
}

.tw-border-b-orange-900\/10 {
  border-bottom-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-b-orange-900\/100 {
  border-bottom-color: rgb(124 45 18 / 1) !important;
}

.tw-border-b-orange-900\/15 {
  border-bottom-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-b-orange-900\/20 {
  border-bottom-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-b-orange-900\/25 {
  border-bottom-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-b-orange-900\/30 {
  border-bottom-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-b-orange-900\/35 {
  border-bottom-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-b-orange-900\/40 {
  border-bottom-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-b-orange-900\/45 {
  border-bottom-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-b-orange-900\/5 {
  border-bottom-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-b-orange-900\/50 {
  border-bottom-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-b-orange-900\/55 {
  border-bottom-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-b-orange-900\/60 {
  border-bottom-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-b-orange-900\/65 {
  border-bottom-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-b-orange-900\/70 {
  border-bottom-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-b-orange-900\/75 {
  border-bottom-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-b-orange-900\/80 {
  border-bottom-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-b-orange-900\/85 {
  border-bottom-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-b-orange-900\/90 {
  border-bottom-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-b-orange-900\/95 {
  border-bottom-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-b-orange-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-b-orange-950\/0 {
  border-bottom-color: rgb(67 20 7 / 0) !important;
}

.tw-border-b-orange-950\/10 {
  border-bottom-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-b-orange-950\/100 {
  border-bottom-color: rgb(67 20 7 / 1) !important;
}

.tw-border-b-orange-950\/15 {
  border-bottom-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-b-orange-950\/20 {
  border-bottom-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-b-orange-950\/25 {
  border-bottom-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-b-orange-950\/30 {
  border-bottom-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-b-orange-950\/35 {
  border-bottom-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-b-orange-950\/40 {
  border-bottom-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-b-orange-950\/45 {
  border-bottom-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-b-orange-950\/5 {
  border-bottom-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-b-orange-950\/50 {
  border-bottom-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-b-orange-950\/55 {
  border-bottom-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-b-orange-950\/60 {
  border-bottom-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-b-orange-950\/65 {
  border-bottom-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-b-orange-950\/70 {
  border-bottom-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-b-orange-950\/75 {
  border-bottom-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-b-orange-950\/80 {
  border-bottom-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-b-orange-950\/85 {
  border-bottom-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-b-orange-950\/90 {
  border-bottom-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-b-orange-950\/95 {
  border-bottom-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-b-pink-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-100\/0 {
  border-bottom-color: rgb(252 231 243 / 0) !important;
}

.tw-border-b-pink-100\/10 {
  border-bottom-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-b-pink-100\/100 {
  border-bottom-color: rgb(252 231 243 / 1) !important;
}

.tw-border-b-pink-100\/15 {
  border-bottom-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-b-pink-100\/20 {
  border-bottom-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-b-pink-100\/25 {
  border-bottom-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-b-pink-100\/30 {
  border-bottom-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-b-pink-100\/35 {
  border-bottom-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-b-pink-100\/40 {
  border-bottom-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-b-pink-100\/45 {
  border-bottom-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-b-pink-100\/5 {
  border-bottom-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-b-pink-100\/50 {
  border-bottom-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-b-pink-100\/55 {
  border-bottom-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-b-pink-100\/60 {
  border-bottom-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-b-pink-100\/65 {
  border-bottom-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-b-pink-100\/70 {
  border-bottom-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-b-pink-100\/75 {
  border-bottom-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-b-pink-100\/80 {
  border-bottom-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-b-pink-100\/85 {
  border-bottom-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-b-pink-100\/90 {
  border-bottom-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-b-pink-100\/95 {
  border-bottom-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-b-pink-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-200\/0 {
  border-bottom-color: rgb(251 207 232 / 0) !important;
}

.tw-border-b-pink-200\/10 {
  border-bottom-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-b-pink-200\/100 {
  border-bottom-color: rgb(251 207 232 / 1) !important;
}

.tw-border-b-pink-200\/15 {
  border-bottom-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-b-pink-200\/20 {
  border-bottom-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-b-pink-200\/25 {
  border-bottom-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-b-pink-200\/30 {
  border-bottom-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-b-pink-200\/35 {
  border-bottom-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-b-pink-200\/40 {
  border-bottom-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-b-pink-200\/45 {
  border-bottom-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-b-pink-200\/5 {
  border-bottom-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-b-pink-200\/50 {
  border-bottom-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-b-pink-200\/55 {
  border-bottom-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-b-pink-200\/60 {
  border-bottom-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-b-pink-200\/65 {
  border-bottom-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-b-pink-200\/70 {
  border-bottom-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-b-pink-200\/75 {
  border-bottom-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-b-pink-200\/80 {
  border-bottom-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-b-pink-200\/85 {
  border-bottom-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-b-pink-200\/90 {
  border-bottom-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-b-pink-200\/95 {
  border-bottom-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-b-pink-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-300\/0 {
  border-bottom-color: rgb(249 168 212 / 0) !important;
}

.tw-border-b-pink-300\/10 {
  border-bottom-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-b-pink-300\/100 {
  border-bottom-color: rgb(249 168 212 / 1) !important;
}

.tw-border-b-pink-300\/15 {
  border-bottom-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-b-pink-300\/20 {
  border-bottom-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-b-pink-300\/25 {
  border-bottom-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-b-pink-300\/30 {
  border-bottom-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-b-pink-300\/35 {
  border-bottom-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-b-pink-300\/40 {
  border-bottom-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-b-pink-300\/45 {
  border-bottom-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-b-pink-300\/5 {
  border-bottom-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-b-pink-300\/50 {
  border-bottom-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-b-pink-300\/55 {
  border-bottom-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-b-pink-300\/60 {
  border-bottom-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-b-pink-300\/65 {
  border-bottom-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-b-pink-300\/70 {
  border-bottom-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-b-pink-300\/75 {
  border-bottom-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-b-pink-300\/80 {
  border-bottom-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-b-pink-300\/85 {
  border-bottom-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-b-pink-300\/90 {
  border-bottom-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-b-pink-300\/95 {
  border-bottom-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-b-pink-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-400\/0 {
  border-bottom-color: rgb(244 114 182 / 0) !important;
}

.tw-border-b-pink-400\/10 {
  border-bottom-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-b-pink-400\/100 {
  border-bottom-color: rgb(244 114 182 / 1) !important;
}

.tw-border-b-pink-400\/15 {
  border-bottom-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-b-pink-400\/20 {
  border-bottom-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-b-pink-400\/25 {
  border-bottom-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-b-pink-400\/30 {
  border-bottom-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-b-pink-400\/35 {
  border-bottom-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-b-pink-400\/40 {
  border-bottom-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-b-pink-400\/45 {
  border-bottom-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-b-pink-400\/5 {
  border-bottom-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-b-pink-400\/50 {
  border-bottom-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-b-pink-400\/55 {
  border-bottom-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-b-pink-400\/60 {
  border-bottom-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-b-pink-400\/65 {
  border-bottom-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-b-pink-400\/70 {
  border-bottom-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-b-pink-400\/75 {
  border-bottom-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-b-pink-400\/80 {
  border-bottom-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-b-pink-400\/85 {
  border-bottom-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-b-pink-400\/90 {
  border-bottom-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-b-pink-400\/95 {
  border-bottom-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-b-pink-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-50\/0 {
  border-bottom-color: rgb(253 242 248 / 0) !important;
}

.tw-border-b-pink-50\/10 {
  border-bottom-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-b-pink-50\/100 {
  border-bottom-color: rgb(253 242 248 / 1) !important;
}

.tw-border-b-pink-50\/15 {
  border-bottom-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-b-pink-50\/20 {
  border-bottom-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-b-pink-50\/25 {
  border-bottom-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-b-pink-50\/30 {
  border-bottom-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-b-pink-50\/35 {
  border-bottom-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-b-pink-50\/40 {
  border-bottom-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-b-pink-50\/45 {
  border-bottom-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-b-pink-50\/5 {
  border-bottom-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-b-pink-50\/50 {
  border-bottom-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-b-pink-50\/55 {
  border-bottom-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-b-pink-50\/60 {
  border-bottom-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-b-pink-50\/65 {
  border-bottom-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-b-pink-50\/70 {
  border-bottom-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-b-pink-50\/75 {
  border-bottom-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-b-pink-50\/80 {
  border-bottom-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-b-pink-50\/85 {
  border-bottom-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-b-pink-50\/90 {
  border-bottom-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-b-pink-50\/95 {
  border-bottom-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-b-pink-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-500\/0 {
  border-bottom-color: rgb(236 72 153 / 0) !important;
}

.tw-border-b-pink-500\/10 {
  border-bottom-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-b-pink-500\/100 {
  border-bottom-color: rgb(236 72 153 / 1) !important;
}

.tw-border-b-pink-500\/15 {
  border-bottom-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-b-pink-500\/20 {
  border-bottom-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-b-pink-500\/25 {
  border-bottom-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-b-pink-500\/30 {
  border-bottom-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-b-pink-500\/35 {
  border-bottom-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-b-pink-500\/40 {
  border-bottom-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-b-pink-500\/45 {
  border-bottom-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-b-pink-500\/5 {
  border-bottom-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-b-pink-500\/50 {
  border-bottom-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-b-pink-500\/55 {
  border-bottom-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-b-pink-500\/60 {
  border-bottom-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-b-pink-500\/65 {
  border-bottom-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-b-pink-500\/70 {
  border-bottom-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-b-pink-500\/75 {
  border-bottom-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-b-pink-500\/80 {
  border-bottom-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-b-pink-500\/85 {
  border-bottom-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-b-pink-500\/90 {
  border-bottom-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-b-pink-500\/95 {
  border-bottom-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-b-pink-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-600\/0 {
  border-bottom-color: rgb(219 39 119 / 0) !important;
}

.tw-border-b-pink-600\/10 {
  border-bottom-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-b-pink-600\/100 {
  border-bottom-color: rgb(219 39 119 / 1) !important;
}

.tw-border-b-pink-600\/15 {
  border-bottom-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-b-pink-600\/20 {
  border-bottom-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-b-pink-600\/25 {
  border-bottom-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-b-pink-600\/30 {
  border-bottom-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-b-pink-600\/35 {
  border-bottom-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-b-pink-600\/40 {
  border-bottom-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-b-pink-600\/45 {
  border-bottom-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-b-pink-600\/5 {
  border-bottom-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-b-pink-600\/50 {
  border-bottom-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-b-pink-600\/55 {
  border-bottom-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-b-pink-600\/60 {
  border-bottom-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-b-pink-600\/65 {
  border-bottom-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-b-pink-600\/70 {
  border-bottom-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-b-pink-600\/75 {
  border-bottom-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-b-pink-600\/80 {
  border-bottom-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-b-pink-600\/85 {
  border-bottom-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-b-pink-600\/90 {
  border-bottom-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-b-pink-600\/95 {
  border-bottom-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-b-pink-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-700\/0 {
  border-bottom-color: rgb(190 24 93 / 0) !important;
}

.tw-border-b-pink-700\/10 {
  border-bottom-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-b-pink-700\/100 {
  border-bottom-color: rgb(190 24 93 / 1) !important;
}

.tw-border-b-pink-700\/15 {
  border-bottom-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-b-pink-700\/20 {
  border-bottom-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-b-pink-700\/25 {
  border-bottom-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-b-pink-700\/30 {
  border-bottom-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-b-pink-700\/35 {
  border-bottom-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-b-pink-700\/40 {
  border-bottom-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-b-pink-700\/45 {
  border-bottom-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-b-pink-700\/5 {
  border-bottom-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-b-pink-700\/50 {
  border-bottom-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-b-pink-700\/55 {
  border-bottom-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-b-pink-700\/60 {
  border-bottom-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-b-pink-700\/65 {
  border-bottom-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-b-pink-700\/70 {
  border-bottom-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-b-pink-700\/75 {
  border-bottom-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-b-pink-700\/80 {
  border-bottom-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-b-pink-700\/85 {
  border-bottom-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-b-pink-700\/90 {
  border-bottom-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-b-pink-700\/95 {
  border-bottom-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-b-pink-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-800\/0 {
  border-bottom-color: rgb(157 23 77 / 0) !important;
}

.tw-border-b-pink-800\/10 {
  border-bottom-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-b-pink-800\/100 {
  border-bottom-color: rgb(157 23 77 / 1) !important;
}

.tw-border-b-pink-800\/15 {
  border-bottom-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-b-pink-800\/20 {
  border-bottom-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-b-pink-800\/25 {
  border-bottom-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-b-pink-800\/30 {
  border-bottom-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-b-pink-800\/35 {
  border-bottom-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-b-pink-800\/40 {
  border-bottom-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-b-pink-800\/45 {
  border-bottom-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-b-pink-800\/5 {
  border-bottom-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-b-pink-800\/50 {
  border-bottom-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-b-pink-800\/55 {
  border-bottom-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-b-pink-800\/60 {
  border-bottom-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-b-pink-800\/65 {
  border-bottom-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-b-pink-800\/70 {
  border-bottom-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-b-pink-800\/75 {
  border-bottom-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-b-pink-800\/80 {
  border-bottom-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-b-pink-800\/85 {
  border-bottom-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-b-pink-800\/90 {
  border-bottom-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-b-pink-800\/95 {
  border-bottom-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-b-pink-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-900\/0 {
  border-bottom-color: rgb(131 24 67 / 0) !important;
}

.tw-border-b-pink-900\/10 {
  border-bottom-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-b-pink-900\/100 {
  border-bottom-color: rgb(131 24 67 / 1) !important;
}

.tw-border-b-pink-900\/15 {
  border-bottom-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-b-pink-900\/20 {
  border-bottom-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-b-pink-900\/25 {
  border-bottom-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-b-pink-900\/30 {
  border-bottom-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-b-pink-900\/35 {
  border-bottom-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-b-pink-900\/40 {
  border-bottom-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-b-pink-900\/45 {
  border-bottom-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-b-pink-900\/5 {
  border-bottom-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-b-pink-900\/50 {
  border-bottom-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-b-pink-900\/55 {
  border-bottom-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-b-pink-900\/60 {
  border-bottom-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-b-pink-900\/65 {
  border-bottom-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-b-pink-900\/70 {
  border-bottom-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-b-pink-900\/75 {
  border-bottom-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-b-pink-900\/80 {
  border-bottom-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-b-pink-900\/85 {
  border-bottom-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-b-pink-900\/90 {
  border-bottom-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-b-pink-900\/95 {
  border-bottom-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-b-pink-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-b-pink-950\/0 {
  border-bottom-color: rgb(80 7 36 / 0) !important;
}

.tw-border-b-pink-950\/10 {
  border-bottom-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-b-pink-950\/100 {
  border-bottom-color: rgb(80 7 36 / 1) !important;
}

.tw-border-b-pink-950\/15 {
  border-bottom-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-b-pink-950\/20 {
  border-bottom-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-b-pink-950\/25 {
  border-bottom-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-b-pink-950\/30 {
  border-bottom-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-b-pink-950\/35 {
  border-bottom-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-b-pink-950\/40 {
  border-bottom-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-b-pink-950\/45 {
  border-bottom-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-b-pink-950\/5 {
  border-bottom-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-b-pink-950\/50 {
  border-bottom-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-b-pink-950\/55 {
  border-bottom-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-b-pink-950\/60 {
  border-bottom-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-b-pink-950\/65 {
  border-bottom-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-b-pink-950\/70 {
  border-bottom-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-b-pink-950\/75 {
  border-bottom-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-b-pink-950\/80 {
  border-bottom-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-b-pink-950\/85 {
  border-bottom-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-b-pink-950\/90 {
  border-bottom-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-b-pink-950\/95 {
  border-bottom-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-b-purple-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-100\/0 {
  border-bottom-color: rgb(243 232 255 / 0) !important;
}

.tw-border-b-purple-100\/10 {
  border-bottom-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-b-purple-100\/100 {
  border-bottom-color: rgb(243 232 255 / 1) !important;
}

.tw-border-b-purple-100\/15 {
  border-bottom-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-b-purple-100\/20 {
  border-bottom-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-b-purple-100\/25 {
  border-bottom-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-b-purple-100\/30 {
  border-bottom-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-b-purple-100\/35 {
  border-bottom-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-b-purple-100\/40 {
  border-bottom-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-b-purple-100\/45 {
  border-bottom-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-b-purple-100\/5 {
  border-bottom-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-b-purple-100\/50 {
  border-bottom-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-b-purple-100\/55 {
  border-bottom-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-b-purple-100\/60 {
  border-bottom-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-b-purple-100\/65 {
  border-bottom-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-b-purple-100\/70 {
  border-bottom-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-b-purple-100\/75 {
  border-bottom-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-b-purple-100\/80 {
  border-bottom-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-b-purple-100\/85 {
  border-bottom-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-b-purple-100\/90 {
  border-bottom-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-b-purple-100\/95 {
  border-bottom-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-b-purple-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-200\/0 {
  border-bottom-color: rgb(233 213 255 / 0) !important;
}

.tw-border-b-purple-200\/10 {
  border-bottom-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-b-purple-200\/100 {
  border-bottom-color: rgb(233 213 255 / 1) !important;
}

.tw-border-b-purple-200\/15 {
  border-bottom-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-b-purple-200\/20 {
  border-bottom-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-b-purple-200\/25 {
  border-bottom-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-b-purple-200\/30 {
  border-bottom-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-b-purple-200\/35 {
  border-bottom-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-b-purple-200\/40 {
  border-bottom-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-b-purple-200\/45 {
  border-bottom-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-b-purple-200\/5 {
  border-bottom-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-b-purple-200\/50 {
  border-bottom-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-b-purple-200\/55 {
  border-bottom-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-b-purple-200\/60 {
  border-bottom-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-b-purple-200\/65 {
  border-bottom-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-b-purple-200\/70 {
  border-bottom-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-b-purple-200\/75 {
  border-bottom-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-b-purple-200\/80 {
  border-bottom-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-b-purple-200\/85 {
  border-bottom-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-b-purple-200\/90 {
  border-bottom-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-b-purple-200\/95 {
  border-bottom-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-b-purple-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-300\/0 {
  border-bottom-color: rgb(216 180 254 / 0) !important;
}

.tw-border-b-purple-300\/10 {
  border-bottom-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-b-purple-300\/100 {
  border-bottom-color: rgb(216 180 254 / 1) !important;
}

.tw-border-b-purple-300\/15 {
  border-bottom-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-b-purple-300\/20 {
  border-bottom-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-b-purple-300\/25 {
  border-bottom-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-b-purple-300\/30 {
  border-bottom-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-b-purple-300\/35 {
  border-bottom-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-b-purple-300\/40 {
  border-bottom-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-b-purple-300\/45 {
  border-bottom-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-b-purple-300\/5 {
  border-bottom-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-b-purple-300\/50 {
  border-bottom-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-b-purple-300\/55 {
  border-bottom-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-b-purple-300\/60 {
  border-bottom-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-b-purple-300\/65 {
  border-bottom-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-b-purple-300\/70 {
  border-bottom-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-b-purple-300\/75 {
  border-bottom-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-b-purple-300\/80 {
  border-bottom-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-b-purple-300\/85 {
  border-bottom-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-b-purple-300\/90 {
  border-bottom-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-b-purple-300\/95 {
  border-bottom-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-b-purple-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-400\/0 {
  border-bottom-color: rgb(192 132 252 / 0) !important;
}

.tw-border-b-purple-400\/10 {
  border-bottom-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-b-purple-400\/100 {
  border-bottom-color: rgb(192 132 252 / 1) !important;
}

.tw-border-b-purple-400\/15 {
  border-bottom-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-b-purple-400\/20 {
  border-bottom-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-b-purple-400\/25 {
  border-bottom-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-b-purple-400\/30 {
  border-bottom-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-b-purple-400\/35 {
  border-bottom-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-b-purple-400\/40 {
  border-bottom-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-b-purple-400\/45 {
  border-bottom-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-b-purple-400\/5 {
  border-bottom-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-b-purple-400\/50 {
  border-bottom-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-b-purple-400\/55 {
  border-bottom-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-b-purple-400\/60 {
  border-bottom-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-b-purple-400\/65 {
  border-bottom-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-b-purple-400\/70 {
  border-bottom-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-b-purple-400\/75 {
  border-bottom-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-b-purple-400\/80 {
  border-bottom-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-b-purple-400\/85 {
  border-bottom-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-b-purple-400\/90 {
  border-bottom-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-b-purple-400\/95 {
  border-bottom-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-b-purple-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-50\/0 {
  border-bottom-color: rgb(250 245 255 / 0) !important;
}

.tw-border-b-purple-50\/10 {
  border-bottom-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-b-purple-50\/100 {
  border-bottom-color: rgb(250 245 255 / 1) !important;
}

.tw-border-b-purple-50\/15 {
  border-bottom-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-b-purple-50\/20 {
  border-bottom-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-b-purple-50\/25 {
  border-bottom-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-b-purple-50\/30 {
  border-bottom-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-b-purple-50\/35 {
  border-bottom-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-b-purple-50\/40 {
  border-bottom-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-b-purple-50\/45 {
  border-bottom-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-b-purple-50\/5 {
  border-bottom-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-b-purple-50\/50 {
  border-bottom-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-b-purple-50\/55 {
  border-bottom-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-b-purple-50\/60 {
  border-bottom-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-b-purple-50\/65 {
  border-bottom-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-b-purple-50\/70 {
  border-bottom-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-b-purple-50\/75 {
  border-bottom-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-b-purple-50\/80 {
  border-bottom-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-b-purple-50\/85 {
  border-bottom-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-b-purple-50\/90 {
  border-bottom-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-b-purple-50\/95 {
  border-bottom-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-b-purple-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-500\/0 {
  border-bottom-color: rgb(168 85 247 / 0) !important;
}

.tw-border-b-purple-500\/10 {
  border-bottom-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-b-purple-500\/100 {
  border-bottom-color: rgb(168 85 247 / 1) !important;
}

.tw-border-b-purple-500\/15 {
  border-bottom-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-b-purple-500\/20 {
  border-bottom-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-b-purple-500\/25 {
  border-bottom-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-b-purple-500\/30 {
  border-bottom-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-b-purple-500\/35 {
  border-bottom-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-b-purple-500\/40 {
  border-bottom-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-b-purple-500\/45 {
  border-bottom-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-b-purple-500\/5 {
  border-bottom-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-b-purple-500\/50 {
  border-bottom-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-b-purple-500\/55 {
  border-bottom-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-b-purple-500\/60 {
  border-bottom-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-b-purple-500\/65 {
  border-bottom-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-b-purple-500\/70 {
  border-bottom-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-b-purple-500\/75 {
  border-bottom-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-b-purple-500\/80 {
  border-bottom-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-b-purple-500\/85 {
  border-bottom-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-b-purple-500\/90 {
  border-bottom-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-b-purple-500\/95 {
  border-bottom-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-b-purple-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-600\/0 {
  border-bottom-color: rgb(147 51 234 / 0) !important;
}

.tw-border-b-purple-600\/10 {
  border-bottom-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-b-purple-600\/100 {
  border-bottom-color: rgb(147 51 234 / 1) !important;
}

.tw-border-b-purple-600\/15 {
  border-bottom-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-b-purple-600\/20 {
  border-bottom-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-b-purple-600\/25 {
  border-bottom-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-b-purple-600\/30 {
  border-bottom-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-b-purple-600\/35 {
  border-bottom-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-b-purple-600\/40 {
  border-bottom-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-b-purple-600\/45 {
  border-bottom-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-b-purple-600\/5 {
  border-bottom-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-b-purple-600\/50 {
  border-bottom-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-b-purple-600\/55 {
  border-bottom-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-b-purple-600\/60 {
  border-bottom-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-b-purple-600\/65 {
  border-bottom-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-b-purple-600\/70 {
  border-bottom-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-b-purple-600\/75 {
  border-bottom-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-b-purple-600\/80 {
  border-bottom-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-b-purple-600\/85 {
  border-bottom-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-b-purple-600\/90 {
  border-bottom-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-b-purple-600\/95 {
  border-bottom-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-b-purple-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-700\/0 {
  border-bottom-color: rgb(126 34 206 / 0) !important;
}

.tw-border-b-purple-700\/10 {
  border-bottom-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-b-purple-700\/100 {
  border-bottom-color: rgb(126 34 206 / 1) !important;
}

.tw-border-b-purple-700\/15 {
  border-bottom-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-b-purple-700\/20 {
  border-bottom-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-b-purple-700\/25 {
  border-bottom-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-b-purple-700\/30 {
  border-bottom-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-b-purple-700\/35 {
  border-bottom-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-b-purple-700\/40 {
  border-bottom-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-b-purple-700\/45 {
  border-bottom-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-b-purple-700\/5 {
  border-bottom-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-b-purple-700\/50 {
  border-bottom-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-b-purple-700\/55 {
  border-bottom-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-b-purple-700\/60 {
  border-bottom-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-b-purple-700\/65 {
  border-bottom-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-b-purple-700\/70 {
  border-bottom-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-b-purple-700\/75 {
  border-bottom-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-b-purple-700\/80 {
  border-bottom-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-b-purple-700\/85 {
  border-bottom-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-b-purple-700\/90 {
  border-bottom-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-b-purple-700\/95 {
  border-bottom-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-b-purple-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-800\/0 {
  border-bottom-color: rgb(107 33 168 / 0) !important;
}

.tw-border-b-purple-800\/10 {
  border-bottom-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-b-purple-800\/100 {
  border-bottom-color: rgb(107 33 168 / 1) !important;
}

.tw-border-b-purple-800\/15 {
  border-bottom-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-b-purple-800\/20 {
  border-bottom-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-b-purple-800\/25 {
  border-bottom-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-b-purple-800\/30 {
  border-bottom-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-b-purple-800\/35 {
  border-bottom-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-b-purple-800\/40 {
  border-bottom-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-b-purple-800\/45 {
  border-bottom-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-b-purple-800\/5 {
  border-bottom-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-b-purple-800\/50 {
  border-bottom-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-b-purple-800\/55 {
  border-bottom-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-b-purple-800\/60 {
  border-bottom-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-b-purple-800\/65 {
  border-bottom-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-b-purple-800\/70 {
  border-bottom-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-b-purple-800\/75 {
  border-bottom-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-b-purple-800\/80 {
  border-bottom-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-b-purple-800\/85 {
  border-bottom-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-b-purple-800\/90 {
  border-bottom-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-b-purple-800\/95 {
  border-bottom-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-b-purple-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-900\/0 {
  border-bottom-color: rgb(88 28 135 / 0) !important;
}

.tw-border-b-purple-900\/10 {
  border-bottom-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-b-purple-900\/100 {
  border-bottom-color: rgb(88 28 135 / 1) !important;
}

.tw-border-b-purple-900\/15 {
  border-bottom-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-b-purple-900\/20 {
  border-bottom-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-b-purple-900\/25 {
  border-bottom-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-b-purple-900\/30 {
  border-bottom-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-b-purple-900\/35 {
  border-bottom-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-b-purple-900\/40 {
  border-bottom-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-b-purple-900\/45 {
  border-bottom-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-b-purple-900\/5 {
  border-bottom-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-b-purple-900\/50 {
  border-bottom-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-b-purple-900\/55 {
  border-bottom-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-b-purple-900\/60 {
  border-bottom-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-b-purple-900\/65 {
  border-bottom-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-b-purple-900\/70 {
  border-bottom-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-b-purple-900\/75 {
  border-bottom-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-b-purple-900\/80 {
  border-bottom-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-b-purple-900\/85 {
  border-bottom-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-b-purple-900\/90 {
  border-bottom-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-b-purple-900\/95 {
  border-bottom-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-b-purple-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-b-purple-950\/0 {
  border-bottom-color: rgb(59 7 100 / 0) !important;
}

.tw-border-b-purple-950\/10 {
  border-bottom-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-b-purple-950\/100 {
  border-bottom-color: rgb(59 7 100 / 1) !important;
}

.tw-border-b-purple-950\/15 {
  border-bottom-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-b-purple-950\/20 {
  border-bottom-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-b-purple-950\/25 {
  border-bottom-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-b-purple-950\/30 {
  border-bottom-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-b-purple-950\/35 {
  border-bottom-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-b-purple-950\/40 {
  border-bottom-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-b-purple-950\/45 {
  border-bottom-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-b-purple-950\/5 {
  border-bottom-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-b-purple-950\/50 {
  border-bottom-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-b-purple-950\/55 {
  border-bottom-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-b-purple-950\/60 {
  border-bottom-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-b-purple-950\/65 {
  border-bottom-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-b-purple-950\/70 {
  border-bottom-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-b-purple-950\/75 {
  border-bottom-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-b-purple-950\/80 {
  border-bottom-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-b-purple-950\/85 {
  border-bottom-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-b-purple-950\/90 {
  border-bottom-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-b-purple-950\/95 {
  border-bottom-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-b-red-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-100\/0 {
  border-bottom-color: rgb(254 226 226 / 0) !important;
}

.tw-border-b-red-100\/10 {
  border-bottom-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-b-red-100\/100 {
  border-bottom-color: rgb(254 226 226 / 1) !important;
}

.tw-border-b-red-100\/15 {
  border-bottom-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-b-red-100\/20 {
  border-bottom-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-b-red-100\/25 {
  border-bottom-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-b-red-100\/30 {
  border-bottom-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-b-red-100\/35 {
  border-bottom-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-b-red-100\/40 {
  border-bottom-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-b-red-100\/45 {
  border-bottom-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-b-red-100\/5 {
  border-bottom-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-b-red-100\/50 {
  border-bottom-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-b-red-100\/55 {
  border-bottom-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-b-red-100\/60 {
  border-bottom-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-b-red-100\/65 {
  border-bottom-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-b-red-100\/70 {
  border-bottom-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-b-red-100\/75 {
  border-bottom-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-b-red-100\/80 {
  border-bottom-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-b-red-100\/85 {
  border-bottom-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-b-red-100\/90 {
  border-bottom-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-b-red-100\/95 {
  border-bottom-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-b-red-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-200\/0 {
  border-bottom-color: rgb(254 202 202 / 0) !important;
}

.tw-border-b-red-200\/10 {
  border-bottom-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-b-red-200\/100 {
  border-bottom-color: rgb(254 202 202 / 1) !important;
}

.tw-border-b-red-200\/15 {
  border-bottom-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-b-red-200\/20 {
  border-bottom-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-b-red-200\/25 {
  border-bottom-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-b-red-200\/30 {
  border-bottom-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-b-red-200\/35 {
  border-bottom-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-b-red-200\/40 {
  border-bottom-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-b-red-200\/45 {
  border-bottom-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-b-red-200\/5 {
  border-bottom-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-b-red-200\/50 {
  border-bottom-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-b-red-200\/55 {
  border-bottom-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-b-red-200\/60 {
  border-bottom-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-b-red-200\/65 {
  border-bottom-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-b-red-200\/70 {
  border-bottom-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-b-red-200\/75 {
  border-bottom-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-b-red-200\/80 {
  border-bottom-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-b-red-200\/85 {
  border-bottom-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-b-red-200\/90 {
  border-bottom-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-b-red-200\/95 {
  border-bottom-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-b-red-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-300\/0 {
  border-bottom-color: rgb(252 165 165 / 0) !important;
}

.tw-border-b-red-300\/10 {
  border-bottom-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-b-red-300\/100 {
  border-bottom-color: rgb(252 165 165 / 1) !important;
}

.tw-border-b-red-300\/15 {
  border-bottom-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-b-red-300\/20 {
  border-bottom-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-b-red-300\/25 {
  border-bottom-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-b-red-300\/30 {
  border-bottom-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-b-red-300\/35 {
  border-bottom-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-b-red-300\/40 {
  border-bottom-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-b-red-300\/45 {
  border-bottom-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-b-red-300\/5 {
  border-bottom-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-b-red-300\/50 {
  border-bottom-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-b-red-300\/55 {
  border-bottom-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-b-red-300\/60 {
  border-bottom-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-b-red-300\/65 {
  border-bottom-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-b-red-300\/70 {
  border-bottom-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-b-red-300\/75 {
  border-bottom-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-b-red-300\/80 {
  border-bottom-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-b-red-300\/85 {
  border-bottom-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-b-red-300\/90 {
  border-bottom-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-b-red-300\/95 {
  border-bottom-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-b-red-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-400\/0 {
  border-bottom-color: rgb(248 113 113 / 0) !important;
}

.tw-border-b-red-400\/10 {
  border-bottom-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-b-red-400\/100 {
  border-bottom-color: rgb(248 113 113 / 1) !important;
}

.tw-border-b-red-400\/15 {
  border-bottom-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-b-red-400\/20 {
  border-bottom-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-b-red-400\/25 {
  border-bottom-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-b-red-400\/30 {
  border-bottom-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-b-red-400\/35 {
  border-bottom-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-b-red-400\/40 {
  border-bottom-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-b-red-400\/45 {
  border-bottom-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-b-red-400\/5 {
  border-bottom-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-b-red-400\/50 {
  border-bottom-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-b-red-400\/55 {
  border-bottom-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-b-red-400\/60 {
  border-bottom-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-b-red-400\/65 {
  border-bottom-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-b-red-400\/70 {
  border-bottom-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-b-red-400\/75 {
  border-bottom-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-b-red-400\/80 {
  border-bottom-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-b-red-400\/85 {
  border-bottom-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-b-red-400\/90 {
  border-bottom-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-b-red-400\/95 {
  border-bottom-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-b-red-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-50\/0 {
  border-bottom-color: rgb(254 242 242 / 0) !important;
}

.tw-border-b-red-50\/10 {
  border-bottom-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-b-red-50\/100 {
  border-bottom-color: rgb(254 242 242 / 1) !important;
}

.tw-border-b-red-50\/15 {
  border-bottom-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-b-red-50\/20 {
  border-bottom-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-b-red-50\/25 {
  border-bottom-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-b-red-50\/30 {
  border-bottom-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-b-red-50\/35 {
  border-bottom-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-b-red-50\/40 {
  border-bottom-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-b-red-50\/45 {
  border-bottom-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-b-red-50\/5 {
  border-bottom-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-b-red-50\/50 {
  border-bottom-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-b-red-50\/55 {
  border-bottom-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-b-red-50\/60 {
  border-bottom-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-b-red-50\/65 {
  border-bottom-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-b-red-50\/70 {
  border-bottom-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-b-red-50\/75 {
  border-bottom-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-b-red-50\/80 {
  border-bottom-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-b-red-50\/85 {
  border-bottom-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-b-red-50\/90 {
  border-bottom-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-b-red-50\/95 {
  border-bottom-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-b-red-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-500\/0 {
  border-bottom-color: rgb(239 68 68 / 0) !important;
}

.tw-border-b-red-500\/10 {
  border-bottom-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-b-red-500\/100 {
  border-bottom-color: rgb(239 68 68 / 1) !important;
}

.tw-border-b-red-500\/15 {
  border-bottom-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-b-red-500\/20 {
  border-bottom-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-b-red-500\/25 {
  border-bottom-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-b-red-500\/30 {
  border-bottom-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-b-red-500\/35 {
  border-bottom-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-b-red-500\/40 {
  border-bottom-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-b-red-500\/45 {
  border-bottom-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-b-red-500\/5 {
  border-bottom-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-b-red-500\/50 {
  border-bottom-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-b-red-500\/55 {
  border-bottom-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-b-red-500\/60 {
  border-bottom-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-b-red-500\/65 {
  border-bottom-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-b-red-500\/70 {
  border-bottom-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-b-red-500\/75 {
  border-bottom-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-b-red-500\/80 {
  border-bottom-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-b-red-500\/85 {
  border-bottom-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-b-red-500\/90 {
  border-bottom-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-b-red-500\/95 {
  border-bottom-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-b-red-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-600\/0 {
  border-bottom-color: rgb(220 38 38 / 0) !important;
}

.tw-border-b-red-600\/10 {
  border-bottom-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-b-red-600\/100 {
  border-bottom-color: rgb(220 38 38 / 1) !important;
}

.tw-border-b-red-600\/15 {
  border-bottom-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-b-red-600\/20 {
  border-bottom-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-b-red-600\/25 {
  border-bottom-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-b-red-600\/30 {
  border-bottom-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-b-red-600\/35 {
  border-bottom-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-b-red-600\/40 {
  border-bottom-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-b-red-600\/45 {
  border-bottom-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-b-red-600\/5 {
  border-bottom-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-b-red-600\/50 {
  border-bottom-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-b-red-600\/55 {
  border-bottom-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-b-red-600\/60 {
  border-bottom-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-b-red-600\/65 {
  border-bottom-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-b-red-600\/70 {
  border-bottom-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-b-red-600\/75 {
  border-bottom-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-b-red-600\/80 {
  border-bottom-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-b-red-600\/85 {
  border-bottom-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-b-red-600\/90 {
  border-bottom-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-b-red-600\/95 {
  border-bottom-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-b-red-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-700\/0 {
  border-bottom-color: rgb(185 28 28 / 0) !important;
}

.tw-border-b-red-700\/10 {
  border-bottom-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-b-red-700\/100 {
  border-bottom-color: rgb(185 28 28 / 1) !important;
}

.tw-border-b-red-700\/15 {
  border-bottom-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-b-red-700\/20 {
  border-bottom-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-b-red-700\/25 {
  border-bottom-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-b-red-700\/30 {
  border-bottom-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-b-red-700\/35 {
  border-bottom-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-b-red-700\/40 {
  border-bottom-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-b-red-700\/45 {
  border-bottom-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-b-red-700\/5 {
  border-bottom-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-b-red-700\/50 {
  border-bottom-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-b-red-700\/55 {
  border-bottom-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-b-red-700\/60 {
  border-bottom-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-b-red-700\/65 {
  border-bottom-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-b-red-700\/70 {
  border-bottom-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-b-red-700\/75 {
  border-bottom-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-b-red-700\/80 {
  border-bottom-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-b-red-700\/85 {
  border-bottom-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-b-red-700\/90 {
  border-bottom-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-b-red-700\/95 {
  border-bottom-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-b-red-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-800\/0 {
  border-bottom-color: rgb(153 27 27 / 0) !important;
}

.tw-border-b-red-800\/10 {
  border-bottom-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-b-red-800\/100 {
  border-bottom-color: rgb(153 27 27 / 1) !important;
}

.tw-border-b-red-800\/15 {
  border-bottom-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-b-red-800\/20 {
  border-bottom-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-b-red-800\/25 {
  border-bottom-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-b-red-800\/30 {
  border-bottom-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-b-red-800\/35 {
  border-bottom-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-b-red-800\/40 {
  border-bottom-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-b-red-800\/45 {
  border-bottom-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-b-red-800\/5 {
  border-bottom-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-b-red-800\/50 {
  border-bottom-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-b-red-800\/55 {
  border-bottom-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-b-red-800\/60 {
  border-bottom-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-b-red-800\/65 {
  border-bottom-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-b-red-800\/70 {
  border-bottom-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-b-red-800\/75 {
  border-bottom-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-b-red-800\/80 {
  border-bottom-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-b-red-800\/85 {
  border-bottom-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-b-red-800\/90 {
  border-bottom-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-b-red-800\/95 {
  border-bottom-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-b-red-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-900\/0 {
  border-bottom-color: rgb(127 29 29 / 0) !important;
}

.tw-border-b-red-900\/10 {
  border-bottom-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-b-red-900\/100 {
  border-bottom-color: rgb(127 29 29 / 1) !important;
}

.tw-border-b-red-900\/15 {
  border-bottom-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-b-red-900\/20 {
  border-bottom-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-b-red-900\/25 {
  border-bottom-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-b-red-900\/30 {
  border-bottom-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-b-red-900\/35 {
  border-bottom-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-b-red-900\/40 {
  border-bottom-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-b-red-900\/45 {
  border-bottom-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-b-red-900\/5 {
  border-bottom-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-b-red-900\/50 {
  border-bottom-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-b-red-900\/55 {
  border-bottom-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-b-red-900\/60 {
  border-bottom-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-b-red-900\/65 {
  border-bottom-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-b-red-900\/70 {
  border-bottom-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-b-red-900\/75 {
  border-bottom-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-b-red-900\/80 {
  border-bottom-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-b-red-900\/85 {
  border-bottom-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-b-red-900\/90 {
  border-bottom-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-b-red-900\/95 {
  border-bottom-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-b-red-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-b-red-950\/0 {
  border-bottom-color: rgb(69 10 10 / 0) !important;
}

.tw-border-b-red-950\/10 {
  border-bottom-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-b-red-950\/100 {
  border-bottom-color: rgb(69 10 10 / 1) !important;
}

.tw-border-b-red-950\/15 {
  border-bottom-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-b-red-950\/20 {
  border-bottom-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-b-red-950\/25 {
  border-bottom-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-b-red-950\/30 {
  border-bottom-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-b-red-950\/35 {
  border-bottom-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-b-red-950\/40 {
  border-bottom-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-b-red-950\/45 {
  border-bottom-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-b-red-950\/5 {
  border-bottom-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-b-red-950\/50 {
  border-bottom-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-b-red-950\/55 {
  border-bottom-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-b-red-950\/60 {
  border-bottom-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-b-red-950\/65 {
  border-bottom-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-b-red-950\/70 {
  border-bottom-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-b-red-950\/75 {
  border-bottom-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-b-red-950\/80 {
  border-bottom-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-b-red-950\/85 {
  border-bottom-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-b-red-950\/90 {
  border-bottom-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-b-red-950\/95 {
  border-bottom-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-b-rose-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-100\/0 {
  border-bottom-color: rgb(255 228 230 / 0) !important;
}

.tw-border-b-rose-100\/10 {
  border-bottom-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-b-rose-100\/100 {
  border-bottom-color: rgb(255 228 230 / 1) !important;
}

.tw-border-b-rose-100\/15 {
  border-bottom-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-b-rose-100\/20 {
  border-bottom-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-b-rose-100\/25 {
  border-bottom-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-b-rose-100\/30 {
  border-bottom-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-b-rose-100\/35 {
  border-bottom-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-b-rose-100\/40 {
  border-bottom-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-b-rose-100\/45 {
  border-bottom-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-b-rose-100\/5 {
  border-bottom-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-b-rose-100\/50 {
  border-bottom-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-b-rose-100\/55 {
  border-bottom-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-b-rose-100\/60 {
  border-bottom-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-b-rose-100\/65 {
  border-bottom-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-b-rose-100\/70 {
  border-bottom-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-b-rose-100\/75 {
  border-bottom-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-b-rose-100\/80 {
  border-bottom-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-b-rose-100\/85 {
  border-bottom-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-b-rose-100\/90 {
  border-bottom-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-b-rose-100\/95 {
  border-bottom-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-b-rose-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-200\/0 {
  border-bottom-color: rgb(254 205 211 / 0) !important;
}

.tw-border-b-rose-200\/10 {
  border-bottom-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-b-rose-200\/100 {
  border-bottom-color: rgb(254 205 211 / 1) !important;
}

.tw-border-b-rose-200\/15 {
  border-bottom-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-b-rose-200\/20 {
  border-bottom-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-b-rose-200\/25 {
  border-bottom-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-b-rose-200\/30 {
  border-bottom-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-b-rose-200\/35 {
  border-bottom-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-b-rose-200\/40 {
  border-bottom-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-b-rose-200\/45 {
  border-bottom-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-b-rose-200\/5 {
  border-bottom-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-b-rose-200\/50 {
  border-bottom-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-b-rose-200\/55 {
  border-bottom-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-b-rose-200\/60 {
  border-bottom-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-b-rose-200\/65 {
  border-bottom-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-b-rose-200\/70 {
  border-bottom-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-b-rose-200\/75 {
  border-bottom-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-b-rose-200\/80 {
  border-bottom-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-b-rose-200\/85 {
  border-bottom-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-b-rose-200\/90 {
  border-bottom-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-b-rose-200\/95 {
  border-bottom-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-b-rose-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-300\/0 {
  border-bottom-color: rgb(253 164 175 / 0) !important;
}

.tw-border-b-rose-300\/10 {
  border-bottom-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-b-rose-300\/100 {
  border-bottom-color: rgb(253 164 175 / 1) !important;
}

.tw-border-b-rose-300\/15 {
  border-bottom-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-b-rose-300\/20 {
  border-bottom-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-b-rose-300\/25 {
  border-bottom-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-b-rose-300\/30 {
  border-bottom-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-b-rose-300\/35 {
  border-bottom-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-b-rose-300\/40 {
  border-bottom-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-b-rose-300\/45 {
  border-bottom-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-b-rose-300\/5 {
  border-bottom-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-b-rose-300\/50 {
  border-bottom-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-b-rose-300\/55 {
  border-bottom-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-b-rose-300\/60 {
  border-bottom-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-b-rose-300\/65 {
  border-bottom-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-b-rose-300\/70 {
  border-bottom-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-b-rose-300\/75 {
  border-bottom-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-b-rose-300\/80 {
  border-bottom-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-b-rose-300\/85 {
  border-bottom-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-b-rose-300\/90 {
  border-bottom-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-b-rose-300\/95 {
  border-bottom-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-b-rose-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-400\/0 {
  border-bottom-color: rgb(251 113 133 / 0) !important;
}

.tw-border-b-rose-400\/10 {
  border-bottom-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-b-rose-400\/100 {
  border-bottom-color: rgb(251 113 133 / 1) !important;
}

.tw-border-b-rose-400\/15 {
  border-bottom-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-b-rose-400\/20 {
  border-bottom-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-b-rose-400\/25 {
  border-bottom-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-b-rose-400\/30 {
  border-bottom-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-b-rose-400\/35 {
  border-bottom-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-b-rose-400\/40 {
  border-bottom-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-b-rose-400\/45 {
  border-bottom-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-b-rose-400\/5 {
  border-bottom-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-b-rose-400\/50 {
  border-bottom-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-b-rose-400\/55 {
  border-bottom-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-b-rose-400\/60 {
  border-bottom-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-b-rose-400\/65 {
  border-bottom-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-b-rose-400\/70 {
  border-bottom-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-b-rose-400\/75 {
  border-bottom-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-b-rose-400\/80 {
  border-bottom-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-b-rose-400\/85 {
  border-bottom-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-b-rose-400\/90 {
  border-bottom-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-b-rose-400\/95 {
  border-bottom-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-b-rose-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-50\/0 {
  border-bottom-color: rgb(255 241 242 / 0) !important;
}

.tw-border-b-rose-50\/10 {
  border-bottom-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-b-rose-50\/100 {
  border-bottom-color: rgb(255 241 242 / 1) !important;
}

.tw-border-b-rose-50\/15 {
  border-bottom-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-b-rose-50\/20 {
  border-bottom-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-b-rose-50\/25 {
  border-bottom-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-b-rose-50\/30 {
  border-bottom-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-b-rose-50\/35 {
  border-bottom-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-b-rose-50\/40 {
  border-bottom-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-b-rose-50\/45 {
  border-bottom-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-b-rose-50\/5 {
  border-bottom-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-b-rose-50\/50 {
  border-bottom-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-b-rose-50\/55 {
  border-bottom-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-b-rose-50\/60 {
  border-bottom-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-b-rose-50\/65 {
  border-bottom-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-b-rose-50\/70 {
  border-bottom-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-b-rose-50\/75 {
  border-bottom-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-b-rose-50\/80 {
  border-bottom-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-b-rose-50\/85 {
  border-bottom-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-b-rose-50\/90 {
  border-bottom-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-b-rose-50\/95 {
  border-bottom-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-b-rose-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-500\/0 {
  border-bottom-color: rgb(244 63 94 / 0) !important;
}

.tw-border-b-rose-500\/10 {
  border-bottom-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-b-rose-500\/100 {
  border-bottom-color: rgb(244 63 94 / 1) !important;
}

.tw-border-b-rose-500\/15 {
  border-bottom-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-b-rose-500\/20 {
  border-bottom-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-b-rose-500\/25 {
  border-bottom-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-b-rose-500\/30 {
  border-bottom-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-b-rose-500\/35 {
  border-bottom-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-b-rose-500\/40 {
  border-bottom-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-b-rose-500\/45 {
  border-bottom-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-b-rose-500\/5 {
  border-bottom-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-b-rose-500\/50 {
  border-bottom-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-b-rose-500\/55 {
  border-bottom-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-b-rose-500\/60 {
  border-bottom-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-b-rose-500\/65 {
  border-bottom-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-b-rose-500\/70 {
  border-bottom-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-b-rose-500\/75 {
  border-bottom-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-b-rose-500\/80 {
  border-bottom-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-b-rose-500\/85 {
  border-bottom-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-b-rose-500\/90 {
  border-bottom-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-b-rose-500\/95 {
  border-bottom-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-b-rose-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-600\/0 {
  border-bottom-color: rgb(225 29 72 / 0) !important;
}

.tw-border-b-rose-600\/10 {
  border-bottom-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-b-rose-600\/100 {
  border-bottom-color: rgb(225 29 72 / 1) !important;
}

.tw-border-b-rose-600\/15 {
  border-bottom-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-b-rose-600\/20 {
  border-bottom-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-b-rose-600\/25 {
  border-bottom-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-b-rose-600\/30 {
  border-bottom-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-b-rose-600\/35 {
  border-bottom-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-b-rose-600\/40 {
  border-bottom-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-b-rose-600\/45 {
  border-bottom-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-b-rose-600\/5 {
  border-bottom-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-b-rose-600\/50 {
  border-bottom-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-b-rose-600\/55 {
  border-bottom-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-b-rose-600\/60 {
  border-bottom-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-b-rose-600\/65 {
  border-bottom-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-b-rose-600\/70 {
  border-bottom-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-b-rose-600\/75 {
  border-bottom-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-b-rose-600\/80 {
  border-bottom-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-b-rose-600\/85 {
  border-bottom-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-b-rose-600\/90 {
  border-bottom-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-b-rose-600\/95 {
  border-bottom-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-b-rose-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-700\/0 {
  border-bottom-color: rgb(190 18 60 / 0) !important;
}

.tw-border-b-rose-700\/10 {
  border-bottom-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-b-rose-700\/100 {
  border-bottom-color: rgb(190 18 60 / 1) !important;
}

.tw-border-b-rose-700\/15 {
  border-bottom-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-b-rose-700\/20 {
  border-bottom-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-b-rose-700\/25 {
  border-bottom-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-b-rose-700\/30 {
  border-bottom-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-b-rose-700\/35 {
  border-bottom-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-b-rose-700\/40 {
  border-bottom-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-b-rose-700\/45 {
  border-bottom-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-b-rose-700\/5 {
  border-bottom-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-b-rose-700\/50 {
  border-bottom-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-b-rose-700\/55 {
  border-bottom-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-b-rose-700\/60 {
  border-bottom-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-b-rose-700\/65 {
  border-bottom-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-b-rose-700\/70 {
  border-bottom-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-b-rose-700\/75 {
  border-bottom-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-b-rose-700\/80 {
  border-bottom-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-b-rose-700\/85 {
  border-bottom-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-b-rose-700\/90 {
  border-bottom-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-b-rose-700\/95 {
  border-bottom-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-b-rose-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-800\/0 {
  border-bottom-color: rgb(159 18 57 / 0) !important;
}

.tw-border-b-rose-800\/10 {
  border-bottom-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-b-rose-800\/100 {
  border-bottom-color: rgb(159 18 57 / 1) !important;
}

.tw-border-b-rose-800\/15 {
  border-bottom-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-b-rose-800\/20 {
  border-bottom-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-b-rose-800\/25 {
  border-bottom-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-b-rose-800\/30 {
  border-bottom-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-b-rose-800\/35 {
  border-bottom-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-b-rose-800\/40 {
  border-bottom-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-b-rose-800\/45 {
  border-bottom-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-b-rose-800\/5 {
  border-bottom-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-b-rose-800\/50 {
  border-bottom-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-b-rose-800\/55 {
  border-bottom-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-b-rose-800\/60 {
  border-bottom-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-b-rose-800\/65 {
  border-bottom-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-b-rose-800\/70 {
  border-bottom-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-b-rose-800\/75 {
  border-bottom-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-b-rose-800\/80 {
  border-bottom-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-b-rose-800\/85 {
  border-bottom-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-b-rose-800\/90 {
  border-bottom-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-b-rose-800\/95 {
  border-bottom-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-b-rose-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-900\/0 {
  border-bottom-color: rgb(136 19 55 / 0) !important;
}

.tw-border-b-rose-900\/10 {
  border-bottom-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-b-rose-900\/100 {
  border-bottom-color: rgb(136 19 55 / 1) !important;
}

.tw-border-b-rose-900\/15 {
  border-bottom-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-b-rose-900\/20 {
  border-bottom-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-b-rose-900\/25 {
  border-bottom-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-b-rose-900\/30 {
  border-bottom-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-b-rose-900\/35 {
  border-bottom-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-b-rose-900\/40 {
  border-bottom-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-b-rose-900\/45 {
  border-bottom-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-b-rose-900\/5 {
  border-bottom-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-b-rose-900\/50 {
  border-bottom-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-b-rose-900\/55 {
  border-bottom-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-b-rose-900\/60 {
  border-bottom-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-b-rose-900\/65 {
  border-bottom-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-b-rose-900\/70 {
  border-bottom-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-b-rose-900\/75 {
  border-bottom-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-b-rose-900\/80 {
  border-bottom-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-b-rose-900\/85 {
  border-bottom-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-b-rose-900\/90 {
  border-bottom-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-b-rose-900\/95 {
  border-bottom-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-b-rose-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-b-rose-950\/0 {
  border-bottom-color: rgb(76 5 25 / 0) !important;
}

.tw-border-b-rose-950\/10 {
  border-bottom-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-b-rose-950\/100 {
  border-bottom-color: rgb(76 5 25 / 1) !important;
}

.tw-border-b-rose-950\/15 {
  border-bottom-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-b-rose-950\/20 {
  border-bottom-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-b-rose-950\/25 {
  border-bottom-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-b-rose-950\/30 {
  border-bottom-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-b-rose-950\/35 {
  border-bottom-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-b-rose-950\/40 {
  border-bottom-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-b-rose-950\/45 {
  border-bottom-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-b-rose-950\/5 {
  border-bottom-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-b-rose-950\/50 {
  border-bottom-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-b-rose-950\/55 {
  border-bottom-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-b-rose-950\/60 {
  border-bottom-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-b-rose-950\/65 {
  border-bottom-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-b-rose-950\/70 {
  border-bottom-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-b-rose-950\/75 {
  border-bottom-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-b-rose-950\/80 {
  border-bottom-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-b-rose-950\/85 {
  border-bottom-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-b-rose-950\/90 {
  border-bottom-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-b-rose-950\/95 {
  border-bottom-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-b-sky-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-100\/0 {
  border-bottom-color: rgb(224 242 254 / 0) !important;
}

.tw-border-b-sky-100\/10 {
  border-bottom-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-b-sky-100\/100 {
  border-bottom-color: rgb(224 242 254 / 1) !important;
}

.tw-border-b-sky-100\/15 {
  border-bottom-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-b-sky-100\/20 {
  border-bottom-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-b-sky-100\/25 {
  border-bottom-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-b-sky-100\/30 {
  border-bottom-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-b-sky-100\/35 {
  border-bottom-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-b-sky-100\/40 {
  border-bottom-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-b-sky-100\/45 {
  border-bottom-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-b-sky-100\/5 {
  border-bottom-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-b-sky-100\/50 {
  border-bottom-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-b-sky-100\/55 {
  border-bottom-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-b-sky-100\/60 {
  border-bottom-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-b-sky-100\/65 {
  border-bottom-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-b-sky-100\/70 {
  border-bottom-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-b-sky-100\/75 {
  border-bottom-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-b-sky-100\/80 {
  border-bottom-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-b-sky-100\/85 {
  border-bottom-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-b-sky-100\/90 {
  border-bottom-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-b-sky-100\/95 {
  border-bottom-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-b-sky-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-200\/0 {
  border-bottom-color: rgb(186 230 253 / 0) !important;
}

.tw-border-b-sky-200\/10 {
  border-bottom-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-b-sky-200\/100 {
  border-bottom-color: rgb(186 230 253 / 1) !important;
}

.tw-border-b-sky-200\/15 {
  border-bottom-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-b-sky-200\/20 {
  border-bottom-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-b-sky-200\/25 {
  border-bottom-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-b-sky-200\/30 {
  border-bottom-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-b-sky-200\/35 {
  border-bottom-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-b-sky-200\/40 {
  border-bottom-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-b-sky-200\/45 {
  border-bottom-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-b-sky-200\/5 {
  border-bottom-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-b-sky-200\/50 {
  border-bottom-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-b-sky-200\/55 {
  border-bottom-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-b-sky-200\/60 {
  border-bottom-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-b-sky-200\/65 {
  border-bottom-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-b-sky-200\/70 {
  border-bottom-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-b-sky-200\/75 {
  border-bottom-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-b-sky-200\/80 {
  border-bottom-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-b-sky-200\/85 {
  border-bottom-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-b-sky-200\/90 {
  border-bottom-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-b-sky-200\/95 {
  border-bottom-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-b-sky-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-300\/0 {
  border-bottom-color: rgb(125 211 252 / 0) !important;
}

.tw-border-b-sky-300\/10 {
  border-bottom-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-b-sky-300\/100 {
  border-bottom-color: rgb(125 211 252 / 1) !important;
}

.tw-border-b-sky-300\/15 {
  border-bottom-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-b-sky-300\/20 {
  border-bottom-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-b-sky-300\/25 {
  border-bottom-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-b-sky-300\/30 {
  border-bottom-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-b-sky-300\/35 {
  border-bottom-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-b-sky-300\/40 {
  border-bottom-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-b-sky-300\/45 {
  border-bottom-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-b-sky-300\/5 {
  border-bottom-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-b-sky-300\/50 {
  border-bottom-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-b-sky-300\/55 {
  border-bottom-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-b-sky-300\/60 {
  border-bottom-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-b-sky-300\/65 {
  border-bottom-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-b-sky-300\/70 {
  border-bottom-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-b-sky-300\/75 {
  border-bottom-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-b-sky-300\/80 {
  border-bottom-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-b-sky-300\/85 {
  border-bottom-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-b-sky-300\/90 {
  border-bottom-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-b-sky-300\/95 {
  border-bottom-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-b-sky-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-400\/0 {
  border-bottom-color: rgb(56 189 248 / 0) !important;
}

.tw-border-b-sky-400\/10 {
  border-bottom-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-b-sky-400\/100 {
  border-bottom-color: rgb(56 189 248 / 1) !important;
}

.tw-border-b-sky-400\/15 {
  border-bottom-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-b-sky-400\/20 {
  border-bottom-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-b-sky-400\/25 {
  border-bottom-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-b-sky-400\/30 {
  border-bottom-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-b-sky-400\/35 {
  border-bottom-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-b-sky-400\/40 {
  border-bottom-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-b-sky-400\/45 {
  border-bottom-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-b-sky-400\/5 {
  border-bottom-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-b-sky-400\/50 {
  border-bottom-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-b-sky-400\/55 {
  border-bottom-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-b-sky-400\/60 {
  border-bottom-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-b-sky-400\/65 {
  border-bottom-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-b-sky-400\/70 {
  border-bottom-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-b-sky-400\/75 {
  border-bottom-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-b-sky-400\/80 {
  border-bottom-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-b-sky-400\/85 {
  border-bottom-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-b-sky-400\/90 {
  border-bottom-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-b-sky-400\/95 {
  border-bottom-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-b-sky-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-50\/0 {
  border-bottom-color: rgb(240 249 255 / 0) !important;
}

.tw-border-b-sky-50\/10 {
  border-bottom-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-b-sky-50\/100 {
  border-bottom-color: rgb(240 249 255 / 1) !important;
}

.tw-border-b-sky-50\/15 {
  border-bottom-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-b-sky-50\/20 {
  border-bottom-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-b-sky-50\/25 {
  border-bottom-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-b-sky-50\/30 {
  border-bottom-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-b-sky-50\/35 {
  border-bottom-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-b-sky-50\/40 {
  border-bottom-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-b-sky-50\/45 {
  border-bottom-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-b-sky-50\/5 {
  border-bottom-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-b-sky-50\/50 {
  border-bottom-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-b-sky-50\/55 {
  border-bottom-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-b-sky-50\/60 {
  border-bottom-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-b-sky-50\/65 {
  border-bottom-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-b-sky-50\/70 {
  border-bottom-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-b-sky-50\/75 {
  border-bottom-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-b-sky-50\/80 {
  border-bottom-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-b-sky-50\/85 {
  border-bottom-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-b-sky-50\/90 {
  border-bottom-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-b-sky-50\/95 {
  border-bottom-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-b-sky-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-500\/0 {
  border-bottom-color: rgb(14 165 233 / 0) !important;
}

.tw-border-b-sky-500\/10 {
  border-bottom-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-b-sky-500\/100 {
  border-bottom-color: rgb(14 165 233 / 1) !important;
}

.tw-border-b-sky-500\/15 {
  border-bottom-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-b-sky-500\/20 {
  border-bottom-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-b-sky-500\/25 {
  border-bottom-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-b-sky-500\/30 {
  border-bottom-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-b-sky-500\/35 {
  border-bottom-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-b-sky-500\/40 {
  border-bottom-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-b-sky-500\/45 {
  border-bottom-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-b-sky-500\/5 {
  border-bottom-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-b-sky-500\/50 {
  border-bottom-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-b-sky-500\/55 {
  border-bottom-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-b-sky-500\/60 {
  border-bottom-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-b-sky-500\/65 {
  border-bottom-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-b-sky-500\/70 {
  border-bottom-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-b-sky-500\/75 {
  border-bottom-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-b-sky-500\/80 {
  border-bottom-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-b-sky-500\/85 {
  border-bottom-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-b-sky-500\/90 {
  border-bottom-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-b-sky-500\/95 {
  border-bottom-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-b-sky-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-600\/0 {
  border-bottom-color: rgb(2 132 199 / 0) !important;
}

.tw-border-b-sky-600\/10 {
  border-bottom-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-b-sky-600\/100 {
  border-bottom-color: rgb(2 132 199 / 1) !important;
}

.tw-border-b-sky-600\/15 {
  border-bottom-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-b-sky-600\/20 {
  border-bottom-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-b-sky-600\/25 {
  border-bottom-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-b-sky-600\/30 {
  border-bottom-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-b-sky-600\/35 {
  border-bottom-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-b-sky-600\/40 {
  border-bottom-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-b-sky-600\/45 {
  border-bottom-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-b-sky-600\/5 {
  border-bottom-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-b-sky-600\/50 {
  border-bottom-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-b-sky-600\/55 {
  border-bottom-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-b-sky-600\/60 {
  border-bottom-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-b-sky-600\/65 {
  border-bottom-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-b-sky-600\/70 {
  border-bottom-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-b-sky-600\/75 {
  border-bottom-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-b-sky-600\/80 {
  border-bottom-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-b-sky-600\/85 {
  border-bottom-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-b-sky-600\/90 {
  border-bottom-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-b-sky-600\/95 {
  border-bottom-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-b-sky-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-700\/0 {
  border-bottom-color: rgb(3 105 161 / 0) !important;
}

.tw-border-b-sky-700\/10 {
  border-bottom-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-b-sky-700\/100 {
  border-bottom-color: rgb(3 105 161 / 1) !important;
}

.tw-border-b-sky-700\/15 {
  border-bottom-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-b-sky-700\/20 {
  border-bottom-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-b-sky-700\/25 {
  border-bottom-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-b-sky-700\/30 {
  border-bottom-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-b-sky-700\/35 {
  border-bottom-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-b-sky-700\/40 {
  border-bottom-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-b-sky-700\/45 {
  border-bottom-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-b-sky-700\/5 {
  border-bottom-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-b-sky-700\/50 {
  border-bottom-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-b-sky-700\/55 {
  border-bottom-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-b-sky-700\/60 {
  border-bottom-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-b-sky-700\/65 {
  border-bottom-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-b-sky-700\/70 {
  border-bottom-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-b-sky-700\/75 {
  border-bottom-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-b-sky-700\/80 {
  border-bottom-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-b-sky-700\/85 {
  border-bottom-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-b-sky-700\/90 {
  border-bottom-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-b-sky-700\/95 {
  border-bottom-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-b-sky-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-800\/0 {
  border-bottom-color: rgb(7 89 133 / 0) !important;
}

.tw-border-b-sky-800\/10 {
  border-bottom-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-b-sky-800\/100 {
  border-bottom-color: rgb(7 89 133 / 1) !important;
}

.tw-border-b-sky-800\/15 {
  border-bottom-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-b-sky-800\/20 {
  border-bottom-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-b-sky-800\/25 {
  border-bottom-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-b-sky-800\/30 {
  border-bottom-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-b-sky-800\/35 {
  border-bottom-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-b-sky-800\/40 {
  border-bottom-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-b-sky-800\/45 {
  border-bottom-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-b-sky-800\/5 {
  border-bottom-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-b-sky-800\/50 {
  border-bottom-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-b-sky-800\/55 {
  border-bottom-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-b-sky-800\/60 {
  border-bottom-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-b-sky-800\/65 {
  border-bottom-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-b-sky-800\/70 {
  border-bottom-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-b-sky-800\/75 {
  border-bottom-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-b-sky-800\/80 {
  border-bottom-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-b-sky-800\/85 {
  border-bottom-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-b-sky-800\/90 {
  border-bottom-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-b-sky-800\/95 {
  border-bottom-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-b-sky-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-900\/0 {
  border-bottom-color: rgb(12 74 110 / 0) !important;
}

.tw-border-b-sky-900\/10 {
  border-bottom-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-b-sky-900\/100 {
  border-bottom-color: rgb(12 74 110 / 1) !important;
}

.tw-border-b-sky-900\/15 {
  border-bottom-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-b-sky-900\/20 {
  border-bottom-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-b-sky-900\/25 {
  border-bottom-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-b-sky-900\/30 {
  border-bottom-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-b-sky-900\/35 {
  border-bottom-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-b-sky-900\/40 {
  border-bottom-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-b-sky-900\/45 {
  border-bottom-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-b-sky-900\/5 {
  border-bottom-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-b-sky-900\/50 {
  border-bottom-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-b-sky-900\/55 {
  border-bottom-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-b-sky-900\/60 {
  border-bottom-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-b-sky-900\/65 {
  border-bottom-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-b-sky-900\/70 {
  border-bottom-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-b-sky-900\/75 {
  border-bottom-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-b-sky-900\/80 {
  border-bottom-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-b-sky-900\/85 {
  border-bottom-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-b-sky-900\/90 {
  border-bottom-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-b-sky-900\/95 {
  border-bottom-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-b-sky-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-b-sky-950\/0 {
  border-bottom-color: rgb(8 47 73 / 0) !important;
}

.tw-border-b-sky-950\/10 {
  border-bottom-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-b-sky-950\/100 {
  border-bottom-color: rgb(8 47 73 / 1) !important;
}

.tw-border-b-sky-950\/15 {
  border-bottom-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-b-sky-950\/20 {
  border-bottom-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-b-sky-950\/25 {
  border-bottom-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-b-sky-950\/30 {
  border-bottom-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-b-sky-950\/35 {
  border-bottom-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-b-sky-950\/40 {
  border-bottom-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-b-sky-950\/45 {
  border-bottom-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-b-sky-950\/5 {
  border-bottom-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-b-sky-950\/50 {
  border-bottom-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-b-sky-950\/55 {
  border-bottom-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-b-sky-950\/60 {
  border-bottom-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-b-sky-950\/65 {
  border-bottom-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-b-sky-950\/70 {
  border-bottom-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-b-sky-950\/75 {
  border-bottom-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-b-sky-950\/80 {
  border-bottom-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-b-sky-950\/85 {
  border-bottom-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-b-sky-950\/90 {
  border-bottom-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-b-sky-950\/95 {
  border-bottom-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-b-slate-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-100\/0 {
  border-bottom-color: rgb(241 245 249 / 0) !important;
}

.tw-border-b-slate-100\/10 {
  border-bottom-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-b-slate-100\/100 {
  border-bottom-color: rgb(241 245 249 / 1) !important;
}

.tw-border-b-slate-100\/15 {
  border-bottom-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-b-slate-100\/20 {
  border-bottom-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-b-slate-100\/25 {
  border-bottom-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-b-slate-100\/30 {
  border-bottom-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-b-slate-100\/35 {
  border-bottom-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-b-slate-100\/40 {
  border-bottom-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-b-slate-100\/45 {
  border-bottom-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-b-slate-100\/5 {
  border-bottom-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-b-slate-100\/50 {
  border-bottom-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-b-slate-100\/55 {
  border-bottom-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-b-slate-100\/60 {
  border-bottom-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-b-slate-100\/65 {
  border-bottom-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-b-slate-100\/70 {
  border-bottom-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-b-slate-100\/75 {
  border-bottom-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-b-slate-100\/80 {
  border-bottom-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-b-slate-100\/85 {
  border-bottom-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-b-slate-100\/90 {
  border-bottom-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-b-slate-100\/95 {
  border-bottom-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-b-slate-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-200\/0 {
  border-bottom-color: rgb(226 232 240 / 0) !important;
}

.tw-border-b-slate-200\/10 {
  border-bottom-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-b-slate-200\/100 {
  border-bottom-color: rgb(226 232 240 / 1) !important;
}

.tw-border-b-slate-200\/15 {
  border-bottom-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-b-slate-200\/20 {
  border-bottom-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-b-slate-200\/25 {
  border-bottom-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-b-slate-200\/30 {
  border-bottom-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-b-slate-200\/35 {
  border-bottom-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-b-slate-200\/40 {
  border-bottom-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-b-slate-200\/45 {
  border-bottom-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-b-slate-200\/5 {
  border-bottom-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-b-slate-200\/50 {
  border-bottom-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-b-slate-200\/55 {
  border-bottom-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-b-slate-200\/60 {
  border-bottom-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-b-slate-200\/65 {
  border-bottom-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-b-slate-200\/70 {
  border-bottom-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-b-slate-200\/75 {
  border-bottom-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-b-slate-200\/80 {
  border-bottom-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-b-slate-200\/85 {
  border-bottom-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-b-slate-200\/90 {
  border-bottom-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-b-slate-200\/95 {
  border-bottom-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-b-slate-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-300\/0 {
  border-bottom-color: rgb(203 213 225 / 0) !important;
}

.tw-border-b-slate-300\/10 {
  border-bottom-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-b-slate-300\/100 {
  border-bottom-color: rgb(203 213 225 / 1) !important;
}

.tw-border-b-slate-300\/15 {
  border-bottom-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-b-slate-300\/20 {
  border-bottom-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-b-slate-300\/25 {
  border-bottom-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-b-slate-300\/30 {
  border-bottom-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-b-slate-300\/35 {
  border-bottom-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-b-slate-300\/40 {
  border-bottom-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-b-slate-300\/45 {
  border-bottom-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-b-slate-300\/5 {
  border-bottom-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-b-slate-300\/50 {
  border-bottom-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-b-slate-300\/55 {
  border-bottom-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-b-slate-300\/60 {
  border-bottom-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-b-slate-300\/65 {
  border-bottom-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-b-slate-300\/70 {
  border-bottom-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-b-slate-300\/75 {
  border-bottom-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-b-slate-300\/80 {
  border-bottom-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-b-slate-300\/85 {
  border-bottom-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-b-slate-300\/90 {
  border-bottom-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-b-slate-300\/95 {
  border-bottom-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-b-slate-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-400\/0 {
  border-bottom-color: rgb(148 163 184 / 0) !important;
}

.tw-border-b-slate-400\/10 {
  border-bottom-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-b-slate-400\/100 {
  border-bottom-color: rgb(148 163 184 / 1) !important;
}

.tw-border-b-slate-400\/15 {
  border-bottom-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-b-slate-400\/20 {
  border-bottom-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-b-slate-400\/25 {
  border-bottom-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-b-slate-400\/30 {
  border-bottom-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-b-slate-400\/35 {
  border-bottom-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-b-slate-400\/40 {
  border-bottom-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-b-slate-400\/45 {
  border-bottom-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-b-slate-400\/5 {
  border-bottom-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-b-slate-400\/50 {
  border-bottom-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-b-slate-400\/55 {
  border-bottom-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-b-slate-400\/60 {
  border-bottom-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-b-slate-400\/65 {
  border-bottom-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-b-slate-400\/70 {
  border-bottom-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-b-slate-400\/75 {
  border-bottom-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-b-slate-400\/80 {
  border-bottom-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-b-slate-400\/85 {
  border-bottom-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-b-slate-400\/90 {
  border-bottom-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-b-slate-400\/95 {
  border-bottom-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-b-slate-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-50\/0 {
  border-bottom-color: rgb(248 250 252 / 0) !important;
}

.tw-border-b-slate-50\/10 {
  border-bottom-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-b-slate-50\/100 {
  border-bottom-color: rgb(248 250 252 / 1) !important;
}

.tw-border-b-slate-50\/15 {
  border-bottom-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-b-slate-50\/20 {
  border-bottom-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-b-slate-50\/25 {
  border-bottom-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-b-slate-50\/30 {
  border-bottom-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-b-slate-50\/35 {
  border-bottom-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-b-slate-50\/40 {
  border-bottom-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-b-slate-50\/45 {
  border-bottom-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-b-slate-50\/5 {
  border-bottom-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-b-slate-50\/50 {
  border-bottom-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-b-slate-50\/55 {
  border-bottom-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-b-slate-50\/60 {
  border-bottom-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-b-slate-50\/65 {
  border-bottom-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-b-slate-50\/70 {
  border-bottom-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-b-slate-50\/75 {
  border-bottom-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-b-slate-50\/80 {
  border-bottom-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-b-slate-50\/85 {
  border-bottom-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-b-slate-50\/90 {
  border-bottom-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-b-slate-50\/95 {
  border-bottom-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-b-slate-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-500\/0 {
  border-bottom-color: rgb(100 116 139 / 0) !important;
}

.tw-border-b-slate-500\/10 {
  border-bottom-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-b-slate-500\/100 {
  border-bottom-color: rgb(100 116 139 / 1) !important;
}

.tw-border-b-slate-500\/15 {
  border-bottom-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-b-slate-500\/20 {
  border-bottom-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-b-slate-500\/25 {
  border-bottom-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-b-slate-500\/30 {
  border-bottom-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-b-slate-500\/35 {
  border-bottom-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-b-slate-500\/40 {
  border-bottom-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-b-slate-500\/45 {
  border-bottom-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-b-slate-500\/5 {
  border-bottom-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-b-slate-500\/50 {
  border-bottom-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-b-slate-500\/55 {
  border-bottom-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-b-slate-500\/60 {
  border-bottom-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-b-slate-500\/65 {
  border-bottom-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-b-slate-500\/70 {
  border-bottom-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-b-slate-500\/75 {
  border-bottom-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-b-slate-500\/80 {
  border-bottom-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-b-slate-500\/85 {
  border-bottom-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-b-slate-500\/90 {
  border-bottom-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-b-slate-500\/95 {
  border-bottom-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-b-slate-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-600\/0 {
  border-bottom-color: rgb(71 85 105 / 0) !important;
}

.tw-border-b-slate-600\/10 {
  border-bottom-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-b-slate-600\/100 {
  border-bottom-color: rgb(71 85 105 / 1) !important;
}

.tw-border-b-slate-600\/15 {
  border-bottom-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-b-slate-600\/20 {
  border-bottom-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-b-slate-600\/25 {
  border-bottom-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-b-slate-600\/30 {
  border-bottom-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-b-slate-600\/35 {
  border-bottom-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-b-slate-600\/40 {
  border-bottom-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-b-slate-600\/45 {
  border-bottom-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-b-slate-600\/5 {
  border-bottom-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-b-slate-600\/50 {
  border-bottom-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-b-slate-600\/55 {
  border-bottom-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-b-slate-600\/60 {
  border-bottom-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-b-slate-600\/65 {
  border-bottom-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-b-slate-600\/70 {
  border-bottom-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-b-slate-600\/75 {
  border-bottom-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-b-slate-600\/80 {
  border-bottom-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-b-slate-600\/85 {
  border-bottom-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-b-slate-600\/90 {
  border-bottom-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-b-slate-600\/95 {
  border-bottom-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-b-slate-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-700\/0 {
  border-bottom-color: rgb(51 65 85 / 0) !important;
}

.tw-border-b-slate-700\/10 {
  border-bottom-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-b-slate-700\/100 {
  border-bottom-color: rgb(51 65 85 / 1) !important;
}

.tw-border-b-slate-700\/15 {
  border-bottom-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-b-slate-700\/20 {
  border-bottom-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-b-slate-700\/25 {
  border-bottom-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-b-slate-700\/30 {
  border-bottom-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-b-slate-700\/35 {
  border-bottom-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-b-slate-700\/40 {
  border-bottom-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-b-slate-700\/45 {
  border-bottom-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-b-slate-700\/5 {
  border-bottom-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-b-slate-700\/50 {
  border-bottom-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-b-slate-700\/55 {
  border-bottom-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-b-slate-700\/60 {
  border-bottom-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-b-slate-700\/65 {
  border-bottom-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-b-slate-700\/70 {
  border-bottom-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-b-slate-700\/75 {
  border-bottom-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-b-slate-700\/80 {
  border-bottom-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-b-slate-700\/85 {
  border-bottom-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-b-slate-700\/90 {
  border-bottom-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-b-slate-700\/95 {
  border-bottom-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-b-slate-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-800\/0 {
  border-bottom-color: rgb(30 41 59 / 0) !important;
}

.tw-border-b-slate-800\/10 {
  border-bottom-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-b-slate-800\/100 {
  border-bottom-color: rgb(30 41 59 / 1) !important;
}

.tw-border-b-slate-800\/15 {
  border-bottom-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-b-slate-800\/20 {
  border-bottom-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-b-slate-800\/25 {
  border-bottom-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-b-slate-800\/30 {
  border-bottom-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-b-slate-800\/35 {
  border-bottom-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-b-slate-800\/40 {
  border-bottom-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-b-slate-800\/45 {
  border-bottom-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-b-slate-800\/5 {
  border-bottom-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-b-slate-800\/50 {
  border-bottom-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-b-slate-800\/55 {
  border-bottom-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-b-slate-800\/60 {
  border-bottom-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-b-slate-800\/65 {
  border-bottom-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-b-slate-800\/70 {
  border-bottom-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-b-slate-800\/75 {
  border-bottom-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-b-slate-800\/80 {
  border-bottom-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-b-slate-800\/85 {
  border-bottom-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-b-slate-800\/90 {
  border-bottom-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-b-slate-800\/95 {
  border-bottom-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-b-slate-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-900\/0 {
  border-bottom-color: rgb(15 23 42 / 0) !important;
}

.tw-border-b-slate-900\/10 {
  border-bottom-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-b-slate-900\/100 {
  border-bottom-color: rgb(15 23 42 / 1) !important;
}

.tw-border-b-slate-900\/15 {
  border-bottom-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-b-slate-900\/20 {
  border-bottom-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-b-slate-900\/25 {
  border-bottom-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-b-slate-900\/30 {
  border-bottom-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-b-slate-900\/35 {
  border-bottom-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-b-slate-900\/40 {
  border-bottom-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-b-slate-900\/45 {
  border-bottom-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-b-slate-900\/5 {
  border-bottom-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-b-slate-900\/50 {
  border-bottom-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-b-slate-900\/55 {
  border-bottom-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-b-slate-900\/60 {
  border-bottom-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-b-slate-900\/65 {
  border-bottom-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-b-slate-900\/70 {
  border-bottom-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-b-slate-900\/75 {
  border-bottom-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-b-slate-900\/80 {
  border-bottom-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-b-slate-900\/85 {
  border-bottom-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-b-slate-900\/90 {
  border-bottom-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-b-slate-900\/95 {
  border-bottom-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-b-slate-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-b-slate-950\/0 {
  border-bottom-color: rgb(2 6 23 / 0) !important;
}

.tw-border-b-slate-950\/10 {
  border-bottom-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-b-slate-950\/100 {
  border-bottom-color: rgb(2 6 23 / 1) !important;
}

.tw-border-b-slate-950\/15 {
  border-bottom-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-b-slate-950\/20 {
  border-bottom-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-b-slate-950\/25 {
  border-bottom-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-b-slate-950\/30 {
  border-bottom-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-b-slate-950\/35 {
  border-bottom-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-b-slate-950\/40 {
  border-bottom-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-b-slate-950\/45 {
  border-bottom-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-b-slate-950\/5 {
  border-bottom-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-b-slate-950\/50 {
  border-bottom-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-b-slate-950\/55 {
  border-bottom-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-b-slate-950\/60 {
  border-bottom-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-b-slate-950\/65 {
  border-bottom-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-b-slate-950\/70 {
  border-bottom-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-b-slate-950\/75 {
  border-bottom-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-b-slate-950\/80 {
  border-bottom-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-b-slate-950\/85 {
  border-bottom-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-b-slate-950\/90 {
  border-bottom-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-b-slate-950\/95 {
  border-bottom-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-b-stone-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-100\/0 {
  border-bottom-color: rgb(245 245 244 / 0) !important;
}

.tw-border-b-stone-100\/10 {
  border-bottom-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-b-stone-100\/100 {
  border-bottom-color: rgb(245 245 244 / 1) !important;
}

.tw-border-b-stone-100\/15 {
  border-bottom-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-b-stone-100\/20 {
  border-bottom-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-b-stone-100\/25 {
  border-bottom-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-b-stone-100\/30 {
  border-bottom-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-b-stone-100\/35 {
  border-bottom-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-b-stone-100\/40 {
  border-bottom-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-b-stone-100\/45 {
  border-bottom-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-b-stone-100\/5 {
  border-bottom-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-b-stone-100\/50 {
  border-bottom-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-b-stone-100\/55 {
  border-bottom-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-b-stone-100\/60 {
  border-bottom-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-b-stone-100\/65 {
  border-bottom-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-b-stone-100\/70 {
  border-bottom-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-b-stone-100\/75 {
  border-bottom-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-b-stone-100\/80 {
  border-bottom-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-b-stone-100\/85 {
  border-bottom-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-b-stone-100\/90 {
  border-bottom-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-b-stone-100\/95 {
  border-bottom-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-b-stone-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-200\/0 {
  border-bottom-color: rgb(231 229 228 / 0) !important;
}

.tw-border-b-stone-200\/10 {
  border-bottom-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-b-stone-200\/100 {
  border-bottom-color: rgb(231 229 228 / 1) !important;
}

.tw-border-b-stone-200\/15 {
  border-bottom-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-b-stone-200\/20 {
  border-bottom-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-b-stone-200\/25 {
  border-bottom-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-b-stone-200\/30 {
  border-bottom-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-b-stone-200\/35 {
  border-bottom-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-b-stone-200\/40 {
  border-bottom-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-b-stone-200\/45 {
  border-bottom-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-b-stone-200\/5 {
  border-bottom-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-b-stone-200\/50 {
  border-bottom-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-b-stone-200\/55 {
  border-bottom-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-b-stone-200\/60 {
  border-bottom-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-b-stone-200\/65 {
  border-bottom-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-b-stone-200\/70 {
  border-bottom-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-b-stone-200\/75 {
  border-bottom-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-b-stone-200\/80 {
  border-bottom-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-b-stone-200\/85 {
  border-bottom-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-b-stone-200\/90 {
  border-bottom-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-b-stone-200\/95 {
  border-bottom-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-b-stone-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-300\/0 {
  border-bottom-color: rgb(214 211 209 / 0) !important;
}

.tw-border-b-stone-300\/10 {
  border-bottom-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-b-stone-300\/100 {
  border-bottom-color: rgb(214 211 209 / 1) !important;
}

.tw-border-b-stone-300\/15 {
  border-bottom-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-b-stone-300\/20 {
  border-bottom-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-b-stone-300\/25 {
  border-bottom-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-b-stone-300\/30 {
  border-bottom-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-b-stone-300\/35 {
  border-bottom-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-b-stone-300\/40 {
  border-bottom-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-b-stone-300\/45 {
  border-bottom-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-b-stone-300\/5 {
  border-bottom-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-b-stone-300\/50 {
  border-bottom-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-b-stone-300\/55 {
  border-bottom-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-b-stone-300\/60 {
  border-bottom-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-b-stone-300\/65 {
  border-bottom-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-b-stone-300\/70 {
  border-bottom-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-b-stone-300\/75 {
  border-bottom-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-b-stone-300\/80 {
  border-bottom-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-b-stone-300\/85 {
  border-bottom-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-b-stone-300\/90 {
  border-bottom-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-b-stone-300\/95 {
  border-bottom-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-b-stone-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-400\/0 {
  border-bottom-color: rgb(168 162 158 / 0) !important;
}

.tw-border-b-stone-400\/10 {
  border-bottom-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-b-stone-400\/100 {
  border-bottom-color: rgb(168 162 158 / 1) !important;
}

.tw-border-b-stone-400\/15 {
  border-bottom-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-b-stone-400\/20 {
  border-bottom-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-b-stone-400\/25 {
  border-bottom-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-b-stone-400\/30 {
  border-bottom-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-b-stone-400\/35 {
  border-bottom-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-b-stone-400\/40 {
  border-bottom-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-b-stone-400\/45 {
  border-bottom-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-b-stone-400\/5 {
  border-bottom-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-b-stone-400\/50 {
  border-bottom-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-b-stone-400\/55 {
  border-bottom-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-b-stone-400\/60 {
  border-bottom-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-b-stone-400\/65 {
  border-bottom-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-b-stone-400\/70 {
  border-bottom-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-b-stone-400\/75 {
  border-bottom-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-b-stone-400\/80 {
  border-bottom-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-b-stone-400\/85 {
  border-bottom-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-b-stone-400\/90 {
  border-bottom-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-b-stone-400\/95 {
  border-bottom-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-b-stone-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-50\/0 {
  border-bottom-color: rgb(250 250 249 / 0) !important;
}

.tw-border-b-stone-50\/10 {
  border-bottom-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-b-stone-50\/100 {
  border-bottom-color: rgb(250 250 249 / 1) !important;
}

.tw-border-b-stone-50\/15 {
  border-bottom-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-b-stone-50\/20 {
  border-bottom-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-b-stone-50\/25 {
  border-bottom-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-b-stone-50\/30 {
  border-bottom-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-b-stone-50\/35 {
  border-bottom-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-b-stone-50\/40 {
  border-bottom-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-b-stone-50\/45 {
  border-bottom-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-b-stone-50\/5 {
  border-bottom-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-b-stone-50\/50 {
  border-bottom-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-b-stone-50\/55 {
  border-bottom-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-b-stone-50\/60 {
  border-bottom-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-b-stone-50\/65 {
  border-bottom-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-b-stone-50\/70 {
  border-bottom-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-b-stone-50\/75 {
  border-bottom-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-b-stone-50\/80 {
  border-bottom-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-b-stone-50\/85 {
  border-bottom-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-b-stone-50\/90 {
  border-bottom-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-b-stone-50\/95 {
  border-bottom-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-b-stone-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-500\/0 {
  border-bottom-color: rgb(120 113 108 / 0) !important;
}

.tw-border-b-stone-500\/10 {
  border-bottom-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-b-stone-500\/100 {
  border-bottom-color: rgb(120 113 108 / 1) !important;
}

.tw-border-b-stone-500\/15 {
  border-bottom-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-b-stone-500\/20 {
  border-bottom-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-b-stone-500\/25 {
  border-bottom-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-b-stone-500\/30 {
  border-bottom-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-b-stone-500\/35 {
  border-bottom-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-b-stone-500\/40 {
  border-bottom-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-b-stone-500\/45 {
  border-bottom-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-b-stone-500\/5 {
  border-bottom-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-b-stone-500\/50 {
  border-bottom-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-b-stone-500\/55 {
  border-bottom-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-b-stone-500\/60 {
  border-bottom-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-b-stone-500\/65 {
  border-bottom-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-b-stone-500\/70 {
  border-bottom-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-b-stone-500\/75 {
  border-bottom-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-b-stone-500\/80 {
  border-bottom-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-b-stone-500\/85 {
  border-bottom-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-b-stone-500\/90 {
  border-bottom-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-b-stone-500\/95 {
  border-bottom-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-b-stone-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-600\/0 {
  border-bottom-color: rgb(87 83 78 / 0) !important;
}

.tw-border-b-stone-600\/10 {
  border-bottom-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-b-stone-600\/100 {
  border-bottom-color: rgb(87 83 78 / 1) !important;
}

.tw-border-b-stone-600\/15 {
  border-bottom-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-b-stone-600\/20 {
  border-bottom-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-b-stone-600\/25 {
  border-bottom-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-b-stone-600\/30 {
  border-bottom-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-b-stone-600\/35 {
  border-bottom-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-b-stone-600\/40 {
  border-bottom-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-b-stone-600\/45 {
  border-bottom-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-b-stone-600\/5 {
  border-bottom-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-b-stone-600\/50 {
  border-bottom-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-b-stone-600\/55 {
  border-bottom-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-b-stone-600\/60 {
  border-bottom-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-b-stone-600\/65 {
  border-bottom-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-b-stone-600\/70 {
  border-bottom-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-b-stone-600\/75 {
  border-bottom-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-b-stone-600\/80 {
  border-bottom-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-b-stone-600\/85 {
  border-bottom-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-b-stone-600\/90 {
  border-bottom-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-b-stone-600\/95 {
  border-bottom-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-b-stone-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-700\/0 {
  border-bottom-color: rgb(68 64 60 / 0) !important;
}

.tw-border-b-stone-700\/10 {
  border-bottom-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-b-stone-700\/100 {
  border-bottom-color: rgb(68 64 60 / 1) !important;
}

.tw-border-b-stone-700\/15 {
  border-bottom-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-b-stone-700\/20 {
  border-bottom-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-b-stone-700\/25 {
  border-bottom-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-b-stone-700\/30 {
  border-bottom-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-b-stone-700\/35 {
  border-bottom-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-b-stone-700\/40 {
  border-bottom-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-b-stone-700\/45 {
  border-bottom-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-b-stone-700\/5 {
  border-bottom-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-b-stone-700\/50 {
  border-bottom-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-b-stone-700\/55 {
  border-bottom-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-b-stone-700\/60 {
  border-bottom-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-b-stone-700\/65 {
  border-bottom-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-b-stone-700\/70 {
  border-bottom-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-b-stone-700\/75 {
  border-bottom-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-b-stone-700\/80 {
  border-bottom-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-b-stone-700\/85 {
  border-bottom-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-b-stone-700\/90 {
  border-bottom-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-b-stone-700\/95 {
  border-bottom-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-b-stone-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-800\/0 {
  border-bottom-color: rgb(41 37 36 / 0) !important;
}

.tw-border-b-stone-800\/10 {
  border-bottom-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-b-stone-800\/100 {
  border-bottom-color: rgb(41 37 36 / 1) !important;
}

.tw-border-b-stone-800\/15 {
  border-bottom-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-b-stone-800\/20 {
  border-bottom-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-b-stone-800\/25 {
  border-bottom-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-b-stone-800\/30 {
  border-bottom-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-b-stone-800\/35 {
  border-bottom-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-b-stone-800\/40 {
  border-bottom-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-b-stone-800\/45 {
  border-bottom-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-b-stone-800\/5 {
  border-bottom-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-b-stone-800\/50 {
  border-bottom-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-b-stone-800\/55 {
  border-bottom-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-b-stone-800\/60 {
  border-bottom-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-b-stone-800\/65 {
  border-bottom-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-b-stone-800\/70 {
  border-bottom-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-b-stone-800\/75 {
  border-bottom-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-b-stone-800\/80 {
  border-bottom-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-b-stone-800\/85 {
  border-bottom-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-b-stone-800\/90 {
  border-bottom-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-b-stone-800\/95 {
  border-bottom-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-b-stone-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-900\/0 {
  border-bottom-color: rgb(28 25 23 / 0) !important;
}

.tw-border-b-stone-900\/10 {
  border-bottom-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-b-stone-900\/100 {
  border-bottom-color: rgb(28 25 23 / 1) !important;
}

.tw-border-b-stone-900\/15 {
  border-bottom-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-b-stone-900\/20 {
  border-bottom-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-b-stone-900\/25 {
  border-bottom-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-b-stone-900\/30 {
  border-bottom-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-b-stone-900\/35 {
  border-bottom-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-b-stone-900\/40 {
  border-bottom-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-b-stone-900\/45 {
  border-bottom-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-b-stone-900\/5 {
  border-bottom-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-b-stone-900\/50 {
  border-bottom-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-b-stone-900\/55 {
  border-bottom-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-b-stone-900\/60 {
  border-bottom-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-b-stone-900\/65 {
  border-bottom-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-b-stone-900\/70 {
  border-bottom-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-b-stone-900\/75 {
  border-bottom-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-b-stone-900\/80 {
  border-bottom-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-b-stone-900\/85 {
  border-bottom-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-b-stone-900\/90 {
  border-bottom-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-b-stone-900\/95 {
  border-bottom-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-b-stone-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-b-stone-950\/0 {
  border-bottom-color: rgb(12 10 9 / 0) !important;
}

.tw-border-b-stone-950\/10 {
  border-bottom-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-b-stone-950\/100 {
  border-bottom-color: rgb(12 10 9 / 1) !important;
}

.tw-border-b-stone-950\/15 {
  border-bottom-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-b-stone-950\/20 {
  border-bottom-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-b-stone-950\/25 {
  border-bottom-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-b-stone-950\/30 {
  border-bottom-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-b-stone-950\/35 {
  border-bottom-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-b-stone-950\/40 {
  border-bottom-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-b-stone-950\/45 {
  border-bottom-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-b-stone-950\/5 {
  border-bottom-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-b-stone-950\/50 {
  border-bottom-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-b-stone-950\/55 {
  border-bottom-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-b-stone-950\/60 {
  border-bottom-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-b-stone-950\/65 {
  border-bottom-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-b-stone-950\/70 {
  border-bottom-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-b-stone-950\/75 {
  border-bottom-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-b-stone-950\/80 {
  border-bottom-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-b-stone-950\/85 {
  border-bottom-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-b-stone-950\/90 {
  border-bottom-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-b-stone-950\/95 {
  border-bottom-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-b-teal-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-100\/0 {
  border-bottom-color: rgb(204 251 241 / 0) !important;
}

.tw-border-b-teal-100\/10 {
  border-bottom-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-b-teal-100\/100 {
  border-bottom-color: rgb(204 251 241 / 1) !important;
}

.tw-border-b-teal-100\/15 {
  border-bottom-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-b-teal-100\/20 {
  border-bottom-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-b-teal-100\/25 {
  border-bottom-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-b-teal-100\/30 {
  border-bottom-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-b-teal-100\/35 {
  border-bottom-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-b-teal-100\/40 {
  border-bottom-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-b-teal-100\/45 {
  border-bottom-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-b-teal-100\/5 {
  border-bottom-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-b-teal-100\/50 {
  border-bottom-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-b-teal-100\/55 {
  border-bottom-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-b-teal-100\/60 {
  border-bottom-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-b-teal-100\/65 {
  border-bottom-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-b-teal-100\/70 {
  border-bottom-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-b-teal-100\/75 {
  border-bottom-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-b-teal-100\/80 {
  border-bottom-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-b-teal-100\/85 {
  border-bottom-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-b-teal-100\/90 {
  border-bottom-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-b-teal-100\/95 {
  border-bottom-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-b-teal-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-200\/0 {
  border-bottom-color: rgb(153 246 228 / 0) !important;
}

.tw-border-b-teal-200\/10 {
  border-bottom-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-b-teal-200\/100 {
  border-bottom-color: rgb(153 246 228 / 1) !important;
}

.tw-border-b-teal-200\/15 {
  border-bottom-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-b-teal-200\/20 {
  border-bottom-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-b-teal-200\/25 {
  border-bottom-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-b-teal-200\/30 {
  border-bottom-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-b-teal-200\/35 {
  border-bottom-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-b-teal-200\/40 {
  border-bottom-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-b-teal-200\/45 {
  border-bottom-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-b-teal-200\/5 {
  border-bottom-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-b-teal-200\/50 {
  border-bottom-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-b-teal-200\/55 {
  border-bottom-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-b-teal-200\/60 {
  border-bottom-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-b-teal-200\/65 {
  border-bottom-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-b-teal-200\/70 {
  border-bottom-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-b-teal-200\/75 {
  border-bottom-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-b-teal-200\/80 {
  border-bottom-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-b-teal-200\/85 {
  border-bottom-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-b-teal-200\/90 {
  border-bottom-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-b-teal-200\/95 {
  border-bottom-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-b-teal-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-300\/0 {
  border-bottom-color: rgb(94 234 212 / 0) !important;
}

.tw-border-b-teal-300\/10 {
  border-bottom-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-b-teal-300\/100 {
  border-bottom-color: rgb(94 234 212 / 1) !important;
}

.tw-border-b-teal-300\/15 {
  border-bottom-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-b-teal-300\/20 {
  border-bottom-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-b-teal-300\/25 {
  border-bottom-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-b-teal-300\/30 {
  border-bottom-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-b-teal-300\/35 {
  border-bottom-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-b-teal-300\/40 {
  border-bottom-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-b-teal-300\/45 {
  border-bottom-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-b-teal-300\/5 {
  border-bottom-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-b-teal-300\/50 {
  border-bottom-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-b-teal-300\/55 {
  border-bottom-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-b-teal-300\/60 {
  border-bottom-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-b-teal-300\/65 {
  border-bottom-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-b-teal-300\/70 {
  border-bottom-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-b-teal-300\/75 {
  border-bottom-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-b-teal-300\/80 {
  border-bottom-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-b-teal-300\/85 {
  border-bottom-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-b-teal-300\/90 {
  border-bottom-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-b-teal-300\/95 {
  border-bottom-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-b-teal-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-400\/0 {
  border-bottom-color: rgb(45 212 191 / 0) !important;
}

.tw-border-b-teal-400\/10 {
  border-bottom-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-b-teal-400\/100 {
  border-bottom-color: rgb(45 212 191 / 1) !important;
}

.tw-border-b-teal-400\/15 {
  border-bottom-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-b-teal-400\/20 {
  border-bottom-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-b-teal-400\/25 {
  border-bottom-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-b-teal-400\/30 {
  border-bottom-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-b-teal-400\/35 {
  border-bottom-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-b-teal-400\/40 {
  border-bottom-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-b-teal-400\/45 {
  border-bottom-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-b-teal-400\/5 {
  border-bottom-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-b-teal-400\/50 {
  border-bottom-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-b-teal-400\/55 {
  border-bottom-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-b-teal-400\/60 {
  border-bottom-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-b-teal-400\/65 {
  border-bottom-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-b-teal-400\/70 {
  border-bottom-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-b-teal-400\/75 {
  border-bottom-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-b-teal-400\/80 {
  border-bottom-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-b-teal-400\/85 {
  border-bottom-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-b-teal-400\/90 {
  border-bottom-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-b-teal-400\/95 {
  border-bottom-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-b-teal-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-50\/0 {
  border-bottom-color: rgb(240 253 250 / 0) !important;
}

.tw-border-b-teal-50\/10 {
  border-bottom-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-b-teal-50\/100 {
  border-bottom-color: rgb(240 253 250 / 1) !important;
}

.tw-border-b-teal-50\/15 {
  border-bottom-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-b-teal-50\/20 {
  border-bottom-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-b-teal-50\/25 {
  border-bottom-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-b-teal-50\/30 {
  border-bottom-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-b-teal-50\/35 {
  border-bottom-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-b-teal-50\/40 {
  border-bottom-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-b-teal-50\/45 {
  border-bottom-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-b-teal-50\/5 {
  border-bottom-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-b-teal-50\/50 {
  border-bottom-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-b-teal-50\/55 {
  border-bottom-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-b-teal-50\/60 {
  border-bottom-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-b-teal-50\/65 {
  border-bottom-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-b-teal-50\/70 {
  border-bottom-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-b-teal-50\/75 {
  border-bottom-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-b-teal-50\/80 {
  border-bottom-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-b-teal-50\/85 {
  border-bottom-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-b-teal-50\/90 {
  border-bottom-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-b-teal-50\/95 {
  border-bottom-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-b-teal-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-500\/0 {
  border-bottom-color: rgb(20 184 166 / 0) !important;
}

.tw-border-b-teal-500\/10 {
  border-bottom-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-b-teal-500\/100 {
  border-bottom-color: rgb(20 184 166 / 1) !important;
}

.tw-border-b-teal-500\/15 {
  border-bottom-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-b-teal-500\/20 {
  border-bottom-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-b-teal-500\/25 {
  border-bottom-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-b-teal-500\/30 {
  border-bottom-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-b-teal-500\/35 {
  border-bottom-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-b-teal-500\/40 {
  border-bottom-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-b-teal-500\/45 {
  border-bottom-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-b-teal-500\/5 {
  border-bottom-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-b-teal-500\/50 {
  border-bottom-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-b-teal-500\/55 {
  border-bottom-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-b-teal-500\/60 {
  border-bottom-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-b-teal-500\/65 {
  border-bottom-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-b-teal-500\/70 {
  border-bottom-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-b-teal-500\/75 {
  border-bottom-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-b-teal-500\/80 {
  border-bottom-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-b-teal-500\/85 {
  border-bottom-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-b-teal-500\/90 {
  border-bottom-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-b-teal-500\/95 {
  border-bottom-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-b-teal-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-600\/0 {
  border-bottom-color: rgb(13 148 136 / 0) !important;
}

.tw-border-b-teal-600\/10 {
  border-bottom-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-b-teal-600\/100 {
  border-bottom-color: rgb(13 148 136 / 1) !important;
}

.tw-border-b-teal-600\/15 {
  border-bottom-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-b-teal-600\/20 {
  border-bottom-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-b-teal-600\/25 {
  border-bottom-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-b-teal-600\/30 {
  border-bottom-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-b-teal-600\/35 {
  border-bottom-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-b-teal-600\/40 {
  border-bottom-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-b-teal-600\/45 {
  border-bottom-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-b-teal-600\/5 {
  border-bottom-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-b-teal-600\/50 {
  border-bottom-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-b-teal-600\/55 {
  border-bottom-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-b-teal-600\/60 {
  border-bottom-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-b-teal-600\/65 {
  border-bottom-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-b-teal-600\/70 {
  border-bottom-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-b-teal-600\/75 {
  border-bottom-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-b-teal-600\/80 {
  border-bottom-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-b-teal-600\/85 {
  border-bottom-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-b-teal-600\/90 {
  border-bottom-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-b-teal-600\/95 {
  border-bottom-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-b-teal-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-700\/0 {
  border-bottom-color: rgb(15 118 110 / 0) !important;
}

.tw-border-b-teal-700\/10 {
  border-bottom-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-b-teal-700\/100 {
  border-bottom-color: rgb(15 118 110 / 1) !important;
}

.tw-border-b-teal-700\/15 {
  border-bottom-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-b-teal-700\/20 {
  border-bottom-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-b-teal-700\/25 {
  border-bottom-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-b-teal-700\/30 {
  border-bottom-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-b-teal-700\/35 {
  border-bottom-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-b-teal-700\/40 {
  border-bottom-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-b-teal-700\/45 {
  border-bottom-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-b-teal-700\/5 {
  border-bottom-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-b-teal-700\/50 {
  border-bottom-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-b-teal-700\/55 {
  border-bottom-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-b-teal-700\/60 {
  border-bottom-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-b-teal-700\/65 {
  border-bottom-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-b-teal-700\/70 {
  border-bottom-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-b-teal-700\/75 {
  border-bottom-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-b-teal-700\/80 {
  border-bottom-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-b-teal-700\/85 {
  border-bottom-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-b-teal-700\/90 {
  border-bottom-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-b-teal-700\/95 {
  border-bottom-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-b-teal-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-800\/0 {
  border-bottom-color: rgb(17 94 89 / 0) !important;
}

.tw-border-b-teal-800\/10 {
  border-bottom-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-b-teal-800\/100 {
  border-bottom-color: rgb(17 94 89 / 1) !important;
}

.tw-border-b-teal-800\/15 {
  border-bottom-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-b-teal-800\/20 {
  border-bottom-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-b-teal-800\/25 {
  border-bottom-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-b-teal-800\/30 {
  border-bottom-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-b-teal-800\/35 {
  border-bottom-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-b-teal-800\/40 {
  border-bottom-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-b-teal-800\/45 {
  border-bottom-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-b-teal-800\/5 {
  border-bottom-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-b-teal-800\/50 {
  border-bottom-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-b-teal-800\/55 {
  border-bottom-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-b-teal-800\/60 {
  border-bottom-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-b-teal-800\/65 {
  border-bottom-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-b-teal-800\/70 {
  border-bottom-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-b-teal-800\/75 {
  border-bottom-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-b-teal-800\/80 {
  border-bottom-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-b-teal-800\/85 {
  border-bottom-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-b-teal-800\/90 {
  border-bottom-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-b-teal-800\/95 {
  border-bottom-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-b-teal-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-900\/0 {
  border-bottom-color: rgb(19 78 74 / 0) !important;
}

.tw-border-b-teal-900\/10 {
  border-bottom-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-b-teal-900\/100 {
  border-bottom-color: rgb(19 78 74 / 1) !important;
}

.tw-border-b-teal-900\/15 {
  border-bottom-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-b-teal-900\/20 {
  border-bottom-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-b-teal-900\/25 {
  border-bottom-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-b-teal-900\/30 {
  border-bottom-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-b-teal-900\/35 {
  border-bottom-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-b-teal-900\/40 {
  border-bottom-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-b-teal-900\/45 {
  border-bottom-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-b-teal-900\/5 {
  border-bottom-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-b-teal-900\/50 {
  border-bottom-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-b-teal-900\/55 {
  border-bottom-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-b-teal-900\/60 {
  border-bottom-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-b-teal-900\/65 {
  border-bottom-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-b-teal-900\/70 {
  border-bottom-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-b-teal-900\/75 {
  border-bottom-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-b-teal-900\/80 {
  border-bottom-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-b-teal-900\/85 {
  border-bottom-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-b-teal-900\/90 {
  border-bottom-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-b-teal-900\/95 {
  border-bottom-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-b-teal-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-b-teal-950\/0 {
  border-bottom-color: rgb(4 47 46 / 0) !important;
}

.tw-border-b-teal-950\/10 {
  border-bottom-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-b-teal-950\/100 {
  border-bottom-color: rgb(4 47 46 / 1) !important;
}

.tw-border-b-teal-950\/15 {
  border-bottom-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-b-teal-950\/20 {
  border-bottom-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-b-teal-950\/25 {
  border-bottom-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-b-teal-950\/30 {
  border-bottom-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-b-teal-950\/35 {
  border-bottom-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-b-teal-950\/40 {
  border-bottom-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-b-teal-950\/45 {
  border-bottom-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-b-teal-950\/5 {
  border-bottom-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-b-teal-950\/50 {
  border-bottom-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-b-teal-950\/55 {
  border-bottom-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-b-teal-950\/60 {
  border-bottom-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-b-teal-950\/65 {
  border-bottom-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-b-teal-950\/70 {
  border-bottom-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-b-teal-950\/75 {
  border-bottom-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-b-teal-950\/80 {
  border-bottom-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-b-teal-950\/85 {
  border-bottom-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-b-teal-950\/90 {
  border-bottom-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-b-teal-950\/95 {
  border-bottom-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-b-transparent {
  border-bottom-color: transparent !important;
}

.tw-border-b-transparent\/0 {
  border-bottom-color: rgb(0 0 0 / 0) !important;
}

.tw-border-b-transparent\/10 {
  border-bottom-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-b-transparent\/100 {
  border-bottom-color: rgb(0 0 0 / 1) !important;
}

.tw-border-b-transparent\/15 {
  border-bottom-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-b-transparent\/20 {
  border-bottom-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-b-transparent\/25 {
  border-bottom-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-b-transparent\/30 {
  border-bottom-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-b-transparent\/35 {
  border-bottom-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-b-transparent\/40 {
  border-bottom-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-b-transparent\/45 {
  border-bottom-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-b-transparent\/5 {
  border-bottom-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-b-transparent\/50 {
  border-bottom-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-b-transparent\/55 {
  border-bottom-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-b-transparent\/60 {
  border-bottom-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-b-transparent\/65 {
  border-bottom-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-b-transparent\/70 {
  border-bottom-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-b-transparent\/75 {
  border-bottom-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-b-transparent\/80 {
  border-bottom-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-b-transparent\/85 {
  border-bottom-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-b-transparent\/90 {
  border-bottom-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-b-transparent\/95 {
  border-bottom-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-b-violet-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-100\/0 {
  border-bottom-color: rgb(237 233 254 / 0) !important;
}

.tw-border-b-violet-100\/10 {
  border-bottom-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-b-violet-100\/100 {
  border-bottom-color: rgb(237 233 254 / 1) !important;
}

.tw-border-b-violet-100\/15 {
  border-bottom-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-b-violet-100\/20 {
  border-bottom-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-b-violet-100\/25 {
  border-bottom-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-b-violet-100\/30 {
  border-bottom-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-b-violet-100\/35 {
  border-bottom-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-b-violet-100\/40 {
  border-bottom-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-b-violet-100\/45 {
  border-bottom-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-b-violet-100\/5 {
  border-bottom-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-b-violet-100\/50 {
  border-bottom-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-b-violet-100\/55 {
  border-bottom-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-b-violet-100\/60 {
  border-bottom-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-b-violet-100\/65 {
  border-bottom-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-b-violet-100\/70 {
  border-bottom-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-b-violet-100\/75 {
  border-bottom-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-b-violet-100\/80 {
  border-bottom-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-b-violet-100\/85 {
  border-bottom-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-b-violet-100\/90 {
  border-bottom-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-b-violet-100\/95 {
  border-bottom-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-b-violet-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-200\/0 {
  border-bottom-color: rgb(221 214 254 / 0) !important;
}

.tw-border-b-violet-200\/10 {
  border-bottom-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-b-violet-200\/100 {
  border-bottom-color: rgb(221 214 254 / 1) !important;
}

.tw-border-b-violet-200\/15 {
  border-bottom-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-b-violet-200\/20 {
  border-bottom-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-b-violet-200\/25 {
  border-bottom-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-b-violet-200\/30 {
  border-bottom-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-b-violet-200\/35 {
  border-bottom-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-b-violet-200\/40 {
  border-bottom-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-b-violet-200\/45 {
  border-bottom-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-b-violet-200\/5 {
  border-bottom-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-b-violet-200\/50 {
  border-bottom-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-b-violet-200\/55 {
  border-bottom-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-b-violet-200\/60 {
  border-bottom-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-b-violet-200\/65 {
  border-bottom-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-b-violet-200\/70 {
  border-bottom-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-b-violet-200\/75 {
  border-bottom-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-b-violet-200\/80 {
  border-bottom-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-b-violet-200\/85 {
  border-bottom-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-b-violet-200\/90 {
  border-bottom-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-b-violet-200\/95 {
  border-bottom-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-b-violet-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-300\/0 {
  border-bottom-color: rgb(196 181 253 / 0) !important;
}

.tw-border-b-violet-300\/10 {
  border-bottom-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-b-violet-300\/100 {
  border-bottom-color: rgb(196 181 253 / 1) !important;
}

.tw-border-b-violet-300\/15 {
  border-bottom-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-b-violet-300\/20 {
  border-bottom-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-b-violet-300\/25 {
  border-bottom-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-b-violet-300\/30 {
  border-bottom-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-b-violet-300\/35 {
  border-bottom-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-b-violet-300\/40 {
  border-bottom-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-b-violet-300\/45 {
  border-bottom-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-b-violet-300\/5 {
  border-bottom-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-b-violet-300\/50 {
  border-bottom-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-b-violet-300\/55 {
  border-bottom-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-b-violet-300\/60 {
  border-bottom-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-b-violet-300\/65 {
  border-bottom-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-b-violet-300\/70 {
  border-bottom-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-b-violet-300\/75 {
  border-bottom-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-b-violet-300\/80 {
  border-bottom-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-b-violet-300\/85 {
  border-bottom-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-b-violet-300\/90 {
  border-bottom-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-b-violet-300\/95 {
  border-bottom-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-b-violet-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-400\/0 {
  border-bottom-color: rgb(167 139 250 / 0) !important;
}

.tw-border-b-violet-400\/10 {
  border-bottom-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-b-violet-400\/100 {
  border-bottom-color: rgb(167 139 250 / 1) !important;
}

.tw-border-b-violet-400\/15 {
  border-bottom-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-b-violet-400\/20 {
  border-bottom-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-b-violet-400\/25 {
  border-bottom-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-b-violet-400\/30 {
  border-bottom-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-b-violet-400\/35 {
  border-bottom-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-b-violet-400\/40 {
  border-bottom-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-b-violet-400\/45 {
  border-bottom-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-b-violet-400\/5 {
  border-bottom-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-b-violet-400\/50 {
  border-bottom-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-b-violet-400\/55 {
  border-bottom-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-b-violet-400\/60 {
  border-bottom-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-b-violet-400\/65 {
  border-bottom-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-b-violet-400\/70 {
  border-bottom-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-b-violet-400\/75 {
  border-bottom-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-b-violet-400\/80 {
  border-bottom-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-b-violet-400\/85 {
  border-bottom-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-b-violet-400\/90 {
  border-bottom-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-b-violet-400\/95 {
  border-bottom-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-b-violet-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-50\/0 {
  border-bottom-color: rgb(245 243 255 / 0) !important;
}

.tw-border-b-violet-50\/10 {
  border-bottom-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-b-violet-50\/100 {
  border-bottom-color: rgb(245 243 255 / 1) !important;
}

.tw-border-b-violet-50\/15 {
  border-bottom-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-b-violet-50\/20 {
  border-bottom-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-b-violet-50\/25 {
  border-bottom-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-b-violet-50\/30 {
  border-bottom-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-b-violet-50\/35 {
  border-bottom-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-b-violet-50\/40 {
  border-bottom-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-b-violet-50\/45 {
  border-bottom-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-b-violet-50\/5 {
  border-bottom-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-b-violet-50\/50 {
  border-bottom-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-b-violet-50\/55 {
  border-bottom-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-b-violet-50\/60 {
  border-bottom-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-b-violet-50\/65 {
  border-bottom-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-b-violet-50\/70 {
  border-bottom-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-b-violet-50\/75 {
  border-bottom-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-b-violet-50\/80 {
  border-bottom-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-b-violet-50\/85 {
  border-bottom-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-b-violet-50\/90 {
  border-bottom-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-b-violet-50\/95 {
  border-bottom-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-b-violet-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-500\/0 {
  border-bottom-color: rgb(139 92 246 / 0) !important;
}

.tw-border-b-violet-500\/10 {
  border-bottom-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-b-violet-500\/100 {
  border-bottom-color: rgb(139 92 246 / 1) !important;
}

.tw-border-b-violet-500\/15 {
  border-bottom-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-b-violet-500\/20 {
  border-bottom-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-b-violet-500\/25 {
  border-bottom-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-b-violet-500\/30 {
  border-bottom-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-b-violet-500\/35 {
  border-bottom-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-b-violet-500\/40 {
  border-bottom-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-b-violet-500\/45 {
  border-bottom-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-b-violet-500\/5 {
  border-bottom-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-b-violet-500\/50 {
  border-bottom-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-b-violet-500\/55 {
  border-bottom-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-b-violet-500\/60 {
  border-bottom-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-b-violet-500\/65 {
  border-bottom-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-b-violet-500\/70 {
  border-bottom-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-b-violet-500\/75 {
  border-bottom-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-b-violet-500\/80 {
  border-bottom-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-b-violet-500\/85 {
  border-bottom-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-b-violet-500\/90 {
  border-bottom-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-b-violet-500\/95 {
  border-bottom-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-b-violet-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-600\/0 {
  border-bottom-color: rgb(124 58 237 / 0) !important;
}

.tw-border-b-violet-600\/10 {
  border-bottom-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-b-violet-600\/100 {
  border-bottom-color: rgb(124 58 237 / 1) !important;
}

.tw-border-b-violet-600\/15 {
  border-bottom-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-b-violet-600\/20 {
  border-bottom-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-b-violet-600\/25 {
  border-bottom-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-b-violet-600\/30 {
  border-bottom-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-b-violet-600\/35 {
  border-bottom-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-b-violet-600\/40 {
  border-bottom-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-b-violet-600\/45 {
  border-bottom-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-b-violet-600\/5 {
  border-bottom-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-b-violet-600\/50 {
  border-bottom-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-b-violet-600\/55 {
  border-bottom-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-b-violet-600\/60 {
  border-bottom-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-b-violet-600\/65 {
  border-bottom-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-b-violet-600\/70 {
  border-bottom-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-b-violet-600\/75 {
  border-bottom-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-b-violet-600\/80 {
  border-bottom-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-b-violet-600\/85 {
  border-bottom-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-b-violet-600\/90 {
  border-bottom-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-b-violet-600\/95 {
  border-bottom-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-b-violet-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-700\/0 {
  border-bottom-color: rgb(109 40 217 / 0) !important;
}

.tw-border-b-violet-700\/10 {
  border-bottom-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-b-violet-700\/100 {
  border-bottom-color: rgb(109 40 217 / 1) !important;
}

.tw-border-b-violet-700\/15 {
  border-bottom-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-b-violet-700\/20 {
  border-bottom-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-b-violet-700\/25 {
  border-bottom-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-b-violet-700\/30 {
  border-bottom-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-b-violet-700\/35 {
  border-bottom-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-b-violet-700\/40 {
  border-bottom-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-b-violet-700\/45 {
  border-bottom-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-b-violet-700\/5 {
  border-bottom-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-b-violet-700\/50 {
  border-bottom-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-b-violet-700\/55 {
  border-bottom-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-b-violet-700\/60 {
  border-bottom-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-b-violet-700\/65 {
  border-bottom-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-b-violet-700\/70 {
  border-bottom-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-b-violet-700\/75 {
  border-bottom-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-b-violet-700\/80 {
  border-bottom-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-b-violet-700\/85 {
  border-bottom-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-b-violet-700\/90 {
  border-bottom-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-b-violet-700\/95 {
  border-bottom-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-b-violet-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-800\/0 {
  border-bottom-color: rgb(91 33 182 / 0) !important;
}

.tw-border-b-violet-800\/10 {
  border-bottom-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-b-violet-800\/100 {
  border-bottom-color: rgb(91 33 182 / 1) !important;
}

.tw-border-b-violet-800\/15 {
  border-bottom-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-b-violet-800\/20 {
  border-bottom-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-b-violet-800\/25 {
  border-bottom-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-b-violet-800\/30 {
  border-bottom-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-b-violet-800\/35 {
  border-bottom-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-b-violet-800\/40 {
  border-bottom-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-b-violet-800\/45 {
  border-bottom-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-b-violet-800\/5 {
  border-bottom-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-b-violet-800\/50 {
  border-bottom-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-b-violet-800\/55 {
  border-bottom-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-b-violet-800\/60 {
  border-bottom-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-b-violet-800\/65 {
  border-bottom-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-b-violet-800\/70 {
  border-bottom-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-b-violet-800\/75 {
  border-bottom-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-b-violet-800\/80 {
  border-bottom-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-b-violet-800\/85 {
  border-bottom-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-b-violet-800\/90 {
  border-bottom-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-b-violet-800\/95 {
  border-bottom-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-b-violet-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-900\/0 {
  border-bottom-color: rgb(76 29 149 / 0) !important;
}

.tw-border-b-violet-900\/10 {
  border-bottom-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-b-violet-900\/100 {
  border-bottom-color: rgb(76 29 149 / 1) !important;
}

.tw-border-b-violet-900\/15 {
  border-bottom-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-b-violet-900\/20 {
  border-bottom-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-b-violet-900\/25 {
  border-bottom-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-b-violet-900\/30 {
  border-bottom-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-b-violet-900\/35 {
  border-bottom-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-b-violet-900\/40 {
  border-bottom-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-b-violet-900\/45 {
  border-bottom-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-b-violet-900\/5 {
  border-bottom-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-b-violet-900\/50 {
  border-bottom-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-b-violet-900\/55 {
  border-bottom-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-b-violet-900\/60 {
  border-bottom-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-b-violet-900\/65 {
  border-bottom-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-b-violet-900\/70 {
  border-bottom-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-b-violet-900\/75 {
  border-bottom-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-b-violet-900\/80 {
  border-bottom-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-b-violet-900\/85 {
  border-bottom-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-b-violet-900\/90 {
  border-bottom-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-b-violet-900\/95 {
  border-bottom-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-b-violet-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-b-violet-950\/0 {
  border-bottom-color: rgb(46 16 101 / 0) !important;
}

.tw-border-b-violet-950\/10 {
  border-bottom-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-b-violet-950\/100 {
  border-bottom-color: rgb(46 16 101 / 1) !important;
}

.tw-border-b-violet-950\/15 {
  border-bottom-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-b-violet-950\/20 {
  border-bottom-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-b-violet-950\/25 {
  border-bottom-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-b-violet-950\/30 {
  border-bottom-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-b-violet-950\/35 {
  border-bottom-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-b-violet-950\/40 {
  border-bottom-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-b-violet-950\/45 {
  border-bottom-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-b-violet-950\/5 {
  border-bottom-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-b-violet-950\/50 {
  border-bottom-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-b-violet-950\/55 {
  border-bottom-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-b-violet-950\/60 {
  border-bottom-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-b-violet-950\/65 {
  border-bottom-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-b-violet-950\/70 {
  border-bottom-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-b-violet-950\/75 {
  border-bottom-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-b-violet-950\/80 {
  border-bottom-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-b-violet-950\/85 {
  border-bottom-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-b-violet-950\/90 {
  border-bottom-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-b-violet-950\/95 {
  border-bottom-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-b-white {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-b-white\/0 {
  border-bottom-color: rgb(255 255 255 / 0) !important;
}

.tw-border-b-white\/10 {
  border-bottom-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-b-white\/100 {
  border-bottom-color: rgb(255 255 255 / 1) !important;
}

.tw-border-b-white\/15 {
  border-bottom-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-b-white\/20 {
  border-bottom-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-b-white\/25 {
  border-bottom-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-b-white\/30 {
  border-bottom-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-b-white\/35 {
  border-bottom-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-b-white\/40 {
  border-bottom-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-b-white\/45 {
  border-bottom-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-b-white\/5 {
  border-bottom-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-b-white\/50 {
  border-bottom-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-b-white\/55 {
  border-bottom-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-b-white\/60 {
  border-bottom-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-b-white\/65 {
  border-bottom-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-b-white\/70 {
  border-bottom-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-b-white\/75 {
  border-bottom-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-b-white\/80 {
  border-bottom-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-b-white\/85 {
  border-bottom-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-b-white\/90 {
  border-bottom-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-b-white\/95 {
  border-bottom-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-b-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-100\/0 {
  border-bottom-color: rgb(254 249 195 / 0) !important;
}

.tw-border-b-yellow-100\/10 {
  border-bottom-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-b-yellow-100\/100 {
  border-bottom-color: rgb(254 249 195 / 1) !important;
}

.tw-border-b-yellow-100\/15 {
  border-bottom-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-b-yellow-100\/20 {
  border-bottom-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-b-yellow-100\/25 {
  border-bottom-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-b-yellow-100\/30 {
  border-bottom-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-b-yellow-100\/35 {
  border-bottom-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-b-yellow-100\/40 {
  border-bottom-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-b-yellow-100\/45 {
  border-bottom-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-b-yellow-100\/5 {
  border-bottom-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-b-yellow-100\/50 {
  border-bottom-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-b-yellow-100\/55 {
  border-bottom-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-b-yellow-100\/60 {
  border-bottom-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-b-yellow-100\/65 {
  border-bottom-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-b-yellow-100\/70 {
  border-bottom-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-b-yellow-100\/75 {
  border-bottom-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-b-yellow-100\/80 {
  border-bottom-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-b-yellow-100\/85 {
  border-bottom-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-b-yellow-100\/90 {
  border-bottom-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-b-yellow-100\/95 {
  border-bottom-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-b-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-200\/0 {
  border-bottom-color: rgb(254 240 138 / 0) !important;
}

.tw-border-b-yellow-200\/10 {
  border-bottom-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-b-yellow-200\/100 {
  border-bottom-color: rgb(254 240 138 / 1) !important;
}

.tw-border-b-yellow-200\/15 {
  border-bottom-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-b-yellow-200\/20 {
  border-bottom-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-b-yellow-200\/25 {
  border-bottom-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-b-yellow-200\/30 {
  border-bottom-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-b-yellow-200\/35 {
  border-bottom-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-b-yellow-200\/40 {
  border-bottom-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-b-yellow-200\/45 {
  border-bottom-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-b-yellow-200\/5 {
  border-bottom-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-b-yellow-200\/50 {
  border-bottom-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-b-yellow-200\/55 {
  border-bottom-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-b-yellow-200\/60 {
  border-bottom-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-b-yellow-200\/65 {
  border-bottom-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-b-yellow-200\/70 {
  border-bottom-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-b-yellow-200\/75 {
  border-bottom-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-b-yellow-200\/80 {
  border-bottom-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-b-yellow-200\/85 {
  border-bottom-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-b-yellow-200\/90 {
  border-bottom-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-b-yellow-200\/95 {
  border-bottom-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-b-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-300\/0 {
  border-bottom-color: rgb(253 224 71 / 0) !important;
}

.tw-border-b-yellow-300\/10 {
  border-bottom-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-b-yellow-300\/100 {
  border-bottom-color: rgb(253 224 71 / 1) !important;
}

.tw-border-b-yellow-300\/15 {
  border-bottom-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-b-yellow-300\/20 {
  border-bottom-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-b-yellow-300\/25 {
  border-bottom-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-b-yellow-300\/30 {
  border-bottom-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-b-yellow-300\/35 {
  border-bottom-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-b-yellow-300\/40 {
  border-bottom-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-b-yellow-300\/45 {
  border-bottom-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-b-yellow-300\/5 {
  border-bottom-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-b-yellow-300\/50 {
  border-bottom-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-b-yellow-300\/55 {
  border-bottom-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-b-yellow-300\/60 {
  border-bottom-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-b-yellow-300\/65 {
  border-bottom-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-b-yellow-300\/70 {
  border-bottom-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-b-yellow-300\/75 {
  border-bottom-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-b-yellow-300\/80 {
  border-bottom-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-b-yellow-300\/85 {
  border-bottom-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-b-yellow-300\/90 {
  border-bottom-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-b-yellow-300\/95 {
  border-bottom-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-b-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-400\/0 {
  border-bottom-color: rgb(250 204 21 / 0) !important;
}

.tw-border-b-yellow-400\/10 {
  border-bottom-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-b-yellow-400\/100 {
  border-bottom-color: rgb(250 204 21 / 1) !important;
}

.tw-border-b-yellow-400\/15 {
  border-bottom-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-b-yellow-400\/20 {
  border-bottom-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-b-yellow-400\/25 {
  border-bottom-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-b-yellow-400\/30 {
  border-bottom-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-b-yellow-400\/35 {
  border-bottom-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-b-yellow-400\/40 {
  border-bottom-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-b-yellow-400\/45 {
  border-bottom-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-b-yellow-400\/5 {
  border-bottom-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-b-yellow-400\/50 {
  border-bottom-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-b-yellow-400\/55 {
  border-bottom-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-b-yellow-400\/60 {
  border-bottom-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-b-yellow-400\/65 {
  border-bottom-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-b-yellow-400\/70 {
  border-bottom-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-b-yellow-400\/75 {
  border-bottom-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-b-yellow-400\/80 {
  border-bottom-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-b-yellow-400\/85 {
  border-bottom-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-b-yellow-400\/90 {
  border-bottom-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-b-yellow-400\/95 {
  border-bottom-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-b-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-50\/0 {
  border-bottom-color: rgb(254 252 232 / 0) !important;
}

.tw-border-b-yellow-50\/10 {
  border-bottom-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-b-yellow-50\/100 {
  border-bottom-color: rgb(254 252 232 / 1) !important;
}

.tw-border-b-yellow-50\/15 {
  border-bottom-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-b-yellow-50\/20 {
  border-bottom-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-b-yellow-50\/25 {
  border-bottom-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-b-yellow-50\/30 {
  border-bottom-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-b-yellow-50\/35 {
  border-bottom-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-b-yellow-50\/40 {
  border-bottom-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-b-yellow-50\/45 {
  border-bottom-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-b-yellow-50\/5 {
  border-bottom-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-b-yellow-50\/50 {
  border-bottom-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-b-yellow-50\/55 {
  border-bottom-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-b-yellow-50\/60 {
  border-bottom-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-b-yellow-50\/65 {
  border-bottom-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-b-yellow-50\/70 {
  border-bottom-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-b-yellow-50\/75 {
  border-bottom-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-b-yellow-50\/80 {
  border-bottom-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-b-yellow-50\/85 {
  border-bottom-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-b-yellow-50\/90 {
  border-bottom-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-b-yellow-50\/95 {
  border-bottom-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-b-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-500\/0 {
  border-bottom-color: rgb(234 179 8 / 0) !important;
}

.tw-border-b-yellow-500\/10 {
  border-bottom-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-b-yellow-500\/100 {
  border-bottom-color: rgb(234 179 8 / 1) !important;
}

.tw-border-b-yellow-500\/15 {
  border-bottom-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-b-yellow-500\/20 {
  border-bottom-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-b-yellow-500\/25 {
  border-bottom-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-b-yellow-500\/30 {
  border-bottom-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-b-yellow-500\/35 {
  border-bottom-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-b-yellow-500\/40 {
  border-bottom-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-b-yellow-500\/45 {
  border-bottom-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-b-yellow-500\/5 {
  border-bottom-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-b-yellow-500\/50 {
  border-bottom-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-b-yellow-500\/55 {
  border-bottom-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-b-yellow-500\/60 {
  border-bottom-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-b-yellow-500\/65 {
  border-bottom-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-b-yellow-500\/70 {
  border-bottom-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-b-yellow-500\/75 {
  border-bottom-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-b-yellow-500\/80 {
  border-bottom-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-b-yellow-500\/85 {
  border-bottom-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-b-yellow-500\/90 {
  border-bottom-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-b-yellow-500\/95 {
  border-bottom-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-b-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-600\/0 {
  border-bottom-color: rgb(202 138 4 / 0) !important;
}

.tw-border-b-yellow-600\/10 {
  border-bottom-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-b-yellow-600\/100 {
  border-bottom-color: rgb(202 138 4 / 1) !important;
}

.tw-border-b-yellow-600\/15 {
  border-bottom-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-b-yellow-600\/20 {
  border-bottom-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-b-yellow-600\/25 {
  border-bottom-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-b-yellow-600\/30 {
  border-bottom-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-b-yellow-600\/35 {
  border-bottom-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-b-yellow-600\/40 {
  border-bottom-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-b-yellow-600\/45 {
  border-bottom-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-b-yellow-600\/5 {
  border-bottom-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-b-yellow-600\/50 {
  border-bottom-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-b-yellow-600\/55 {
  border-bottom-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-b-yellow-600\/60 {
  border-bottom-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-b-yellow-600\/65 {
  border-bottom-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-b-yellow-600\/70 {
  border-bottom-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-b-yellow-600\/75 {
  border-bottom-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-b-yellow-600\/80 {
  border-bottom-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-b-yellow-600\/85 {
  border-bottom-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-b-yellow-600\/90 {
  border-bottom-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-b-yellow-600\/95 {
  border-bottom-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-b-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-700\/0 {
  border-bottom-color: rgb(161 98 7 / 0) !important;
}

.tw-border-b-yellow-700\/10 {
  border-bottom-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-b-yellow-700\/100 {
  border-bottom-color: rgb(161 98 7 / 1) !important;
}

.tw-border-b-yellow-700\/15 {
  border-bottom-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-b-yellow-700\/20 {
  border-bottom-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-b-yellow-700\/25 {
  border-bottom-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-b-yellow-700\/30 {
  border-bottom-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-b-yellow-700\/35 {
  border-bottom-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-b-yellow-700\/40 {
  border-bottom-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-b-yellow-700\/45 {
  border-bottom-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-b-yellow-700\/5 {
  border-bottom-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-b-yellow-700\/50 {
  border-bottom-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-b-yellow-700\/55 {
  border-bottom-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-b-yellow-700\/60 {
  border-bottom-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-b-yellow-700\/65 {
  border-bottom-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-b-yellow-700\/70 {
  border-bottom-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-b-yellow-700\/75 {
  border-bottom-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-b-yellow-700\/80 {
  border-bottom-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-b-yellow-700\/85 {
  border-bottom-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-b-yellow-700\/90 {
  border-bottom-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-b-yellow-700\/95 {
  border-bottom-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-b-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-800\/0 {
  border-bottom-color: rgb(133 77 14 / 0) !important;
}

.tw-border-b-yellow-800\/10 {
  border-bottom-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-b-yellow-800\/100 {
  border-bottom-color: rgb(133 77 14 / 1) !important;
}

.tw-border-b-yellow-800\/15 {
  border-bottom-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-b-yellow-800\/20 {
  border-bottom-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-b-yellow-800\/25 {
  border-bottom-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-b-yellow-800\/30 {
  border-bottom-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-b-yellow-800\/35 {
  border-bottom-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-b-yellow-800\/40 {
  border-bottom-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-b-yellow-800\/45 {
  border-bottom-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-b-yellow-800\/5 {
  border-bottom-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-b-yellow-800\/50 {
  border-bottom-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-b-yellow-800\/55 {
  border-bottom-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-b-yellow-800\/60 {
  border-bottom-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-b-yellow-800\/65 {
  border-bottom-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-b-yellow-800\/70 {
  border-bottom-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-b-yellow-800\/75 {
  border-bottom-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-b-yellow-800\/80 {
  border-bottom-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-b-yellow-800\/85 {
  border-bottom-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-b-yellow-800\/90 {
  border-bottom-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-b-yellow-800\/95 {
  border-bottom-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-b-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-900\/0 {
  border-bottom-color: rgb(113 63 18 / 0) !important;
}

.tw-border-b-yellow-900\/10 {
  border-bottom-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-b-yellow-900\/100 {
  border-bottom-color: rgb(113 63 18 / 1) !important;
}

.tw-border-b-yellow-900\/15 {
  border-bottom-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-b-yellow-900\/20 {
  border-bottom-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-b-yellow-900\/25 {
  border-bottom-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-b-yellow-900\/30 {
  border-bottom-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-b-yellow-900\/35 {
  border-bottom-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-b-yellow-900\/40 {
  border-bottom-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-b-yellow-900\/45 {
  border-bottom-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-b-yellow-900\/5 {
  border-bottom-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-b-yellow-900\/50 {
  border-bottom-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-b-yellow-900\/55 {
  border-bottom-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-b-yellow-900\/60 {
  border-bottom-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-b-yellow-900\/65 {
  border-bottom-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-b-yellow-900\/70 {
  border-bottom-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-b-yellow-900\/75 {
  border-bottom-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-b-yellow-900\/80 {
  border-bottom-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-b-yellow-900\/85 {
  border-bottom-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-b-yellow-900\/90 {
  border-bottom-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-b-yellow-900\/95 {
  border-bottom-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-b-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-b-yellow-950\/0 {
  border-bottom-color: rgb(66 32 6 / 0) !important;
}

.tw-border-b-yellow-950\/10 {
  border-bottom-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-b-yellow-950\/100 {
  border-bottom-color: rgb(66 32 6 / 1) !important;
}

.tw-border-b-yellow-950\/15 {
  border-bottom-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-b-yellow-950\/20 {
  border-bottom-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-b-yellow-950\/25 {
  border-bottom-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-b-yellow-950\/30 {
  border-bottom-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-b-yellow-950\/35 {
  border-bottom-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-b-yellow-950\/40 {
  border-bottom-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-b-yellow-950\/45 {
  border-bottom-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-b-yellow-950\/5 {
  border-bottom-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-b-yellow-950\/50 {
  border-bottom-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-b-yellow-950\/55 {
  border-bottom-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-b-yellow-950\/60 {
  border-bottom-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-b-yellow-950\/65 {
  border-bottom-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-b-yellow-950\/70 {
  border-bottom-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-b-yellow-950\/75 {
  border-bottom-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-b-yellow-950\/80 {
  border-bottom-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-b-yellow-950\/85 {
  border-bottom-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-b-yellow-950\/90 {
  border-bottom-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-b-yellow-950\/95 {
  border-bottom-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-b-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-100\/0 {
  border-bottom-color: rgb(244 244 245 / 0) !important;
}

.tw-border-b-zinc-100\/10 {
  border-bottom-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-b-zinc-100\/100 {
  border-bottom-color: rgb(244 244 245 / 1) !important;
}

.tw-border-b-zinc-100\/15 {
  border-bottom-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-b-zinc-100\/20 {
  border-bottom-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-b-zinc-100\/25 {
  border-bottom-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-b-zinc-100\/30 {
  border-bottom-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-b-zinc-100\/35 {
  border-bottom-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-b-zinc-100\/40 {
  border-bottom-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-b-zinc-100\/45 {
  border-bottom-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-b-zinc-100\/5 {
  border-bottom-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-b-zinc-100\/50 {
  border-bottom-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-b-zinc-100\/55 {
  border-bottom-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-b-zinc-100\/60 {
  border-bottom-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-b-zinc-100\/65 {
  border-bottom-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-b-zinc-100\/70 {
  border-bottom-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-b-zinc-100\/75 {
  border-bottom-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-b-zinc-100\/80 {
  border-bottom-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-b-zinc-100\/85 {
  border-bottom-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-b-zinc-100\/90 {
  border-bottom-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-b-zinc-100\/95 {
  border-bottom-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-b-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-200\/0 {
  border-bottom-color: rgb(228 228 231 / 0) !important;
}

.tw-border-b-zinc-200\/10 {
  border-bottom-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-b-zinc-200\/100 {
  border-bottom-color: rgb(228 228 231 / 1) !important;
}

.tw-border-b-zinc-200\/15 {
  border-bottom-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-b-zinc-200\/20 {
  border-bottom-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-b-zinc-200\/25 {
  border-bottom-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-b-zinc-200\/30 {
  border-bottom-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-b-zinc-200\/35 {
  border-bottom-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-b-zinc-200\/40 {
  border-bottom-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-b-zinc-200\/45 {
  border-bottom-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-b-zinc-200\/5 {
  border-bottom-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-b-zinc-200\/50 {
  border-bottom-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-b-zinc-200\/55 {
  border-bottom-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-b-zinc-200\/60 {
  border-bottom-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-b-zinc-200\/65 {
  border-bottom-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-b-zinc-200\/70 {
  border-bottom-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-b-zinc-200\/75 {
  border-bottom-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-b-zinc-200\/80 {
  border-bottom-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-b-zinc-200\/85 {
  border-bottom-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-b-zinc-200\/90 {
  border-bottom-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-b-zinc-200\/95 {
  border-bottom-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-b-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-300\/0 {
  border-bottom-color: rgb(212 212 216 / 0) !important;
}

.tw-border-b-zinc-300\/10 {
  border-bottom-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-b-zinc-300\/100 {
  border-bottom-color: rgb(212 212 216 / 1) !important;
}

.tw-border-b-zinc-300\/15 {
  border-bottom-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-b-zinc-300\/20 {
  border-bottom-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-b-zinc-300\/25 {
  border-bottom-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-b-zinc-300\/30 {
  border-bottom-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-b-zinc-300\/35 {
  border-bottom-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-b-zinc-300\/40 {
  border-bottom-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-b-zinc-300\/45 {
  border-bottom-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-b-zinc-300\/5 {
  border-bottom-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-b-zinc-300\/50 {
  border-bottom-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-b-zinc-300\/55 {
  border-bottom-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-b-zinc-300\/60 {
  border-bottom-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-b-zinc-300\/65 {
  border-bottom-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-b-zinc-300\/70 {
  border-bottom-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-b-zinc-300\/75 {
  border-bottom-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-b-zinc-300\/80 {
  border-bottom-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-b-zinc-300\/85 {
  border-bottom-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-b-zinc-300\/90 {
  border-bottom-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-b-zinc-300\/95 {
  border-bottom-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-b-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-400\/0 {
  border-bottom-color: rgb(161 161 170 / 0) !important;
}

.tw-border-b-zinc-400\/10 {
  border-bottom-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-b-zinc-400\/100 {
  border-bottom-color: rgb(161 161 170 / 1) !important;
}

.tw-border-b-zinc-400\/15 {
  border-bottom-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-b-zinc-400\/20 {
  border-bottom-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-b-zinc-400\/25 {
  border-bottom-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-b-zinc-400\/30 {
  border-bottom-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-b-zinc-400\/35 {
  border-bottom-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-b-zinc-400\/40 {
  border-bottom-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-b-zinc-400\/45 {
  border-bottom-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-b-zinc-400\/5 {
  border-bottom-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-b-zinc-400\/50 {
  border-bottom-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-b-zinc-400\/55 {
  border-bottom-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-b-zinc-400\/60 {
  border-bottom-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-b-zinc-400\/65 {
  border-bottom-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-b-zinc-400\/70 {
  border-bottom-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-b-zinc-400\/75 {
  border-bottom-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-b-zinc-400\/80 {
  border-bottom-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-b-zinc-400\/85 {
  border-bottom-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-b-zinc-400\/90 {
  border-bottom-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-b-zinc-400\/95 {
  border-bottom-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-b-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-50\/0 {
  border-bottom-color: rgb(250 250 250 / 0) !important;
}

.tw-border-b-zinc-50\/10 {
  border-bottom-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-b-zinc-50\/100 {
  border-bottom-color: rgb(250 250 250 / 1) !important;
}

.tw-border-b-zinc-50\/15 {
  border-bottom-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-b-zinc-50\/20 {
  border-bottom-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-b-zinc-50\/25 {
  border-bottom-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-b-zinc-50\/30 {
  border-bottom-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-b-zinc-50\/35 {
  border-bottom-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-b-zinc-50\/40 {
  border-bottom-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-b-zinc-50\/45 {
  border-bottom-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-b-zinc-50\/5 {
  border-bottom-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-b-zinc-50\/50 {
  border-bottom-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-b-zinc-50\/55 {
  border-bottom-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-b-zinc-50\/60 {
  border-bottom-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-b-zinc-50\/65 {
  border-bottom-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-b-zinc-50\/70 {
  border-bottom-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-b-zinc-50\/75 {
  border-bottom-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-b-zinc-50\/80 {
  border-bottom-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-b-zinc-50\/85 {
  border-bottom-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-b-zinc-50\/90 {
  border-bottom-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-b-zinc-50\/95 {
  border-bottom-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-b-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-500\/0 {
  border-bottom-color: rgb(113 113 122 / 0) !important;
}

.tw-border-b-zinc-500\/10 {
  border-bottom-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-b-zinc-500\/100 {
  border-bottom-color: rgb(113 113 122 / 1) !important;
}

.tw-border-b-zinc-500\/15 {
  border-bottom-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-b-zinc-500\/20 {
  border-bottom-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-b-zinc-500\/25 {
  border-bottom-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-b-zinc-500\/30 {
  border-bottom-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-b-zinc-500\/35 {
  border-bottom-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-b-zinc-500\/40 {
  border-bottom-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-b-zinc-500\/45 {
  border-bottom-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-b-zinc-500\/5 {
  border-bottom-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-b-zinc-500\/50 {
  border-bottom-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-b-zinc-500\/55 {
  border-bottom-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-b-zinc-500\/60 {
  border-bottom-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-b-zinc-500\/65 {
  border-bottom-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-b-zinc-500\/70 {
  border-bottom-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-b-zinc-500\/75 {
  border-bottom-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-b-zinc-500\/80 {
  border-bottom-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-b-zinc-500\/85 {
  border-bottom-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-b-zinc-500\/90 {
  border-bottom-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-b-zinc-500\/95 {
  border-bottom-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-b-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-600\/0 {
  border-bottom-color: rgb(82 82 91 / 0) !important;
}

.tw-border-b-zinc-600\/10 {
  border-bottom-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-b-zinc-600\/100 {
  border-bottom-color: rgb(82 82 91 / 1) !important;
}

.tw-border-b-zinc-600\/15 {
  border-bottom-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-b-zinc-600\/20 {
  border-bottom-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-b-zinc-600\/25 {
  border-bottom-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-b-zinc-600\/30 {
  border-bottom-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-b-zinc-600\/35 {
  border-bottom-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-b-zinc-600\/40 {
  border-bottom-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-b-zinc-600\/45 {
  border-bottom-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-b-zinc-600\/5 {
  border-bottom-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-b-zinc-600\/50 {
  border-bottom-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-b-zinc-600\/55 {
  border-bottom-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-b-zinc-600\/60 {
  border-bottom-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-b-zinc-600\/65 {
  border-bottom-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-b-zinc-600\/70 {
  border-bottom-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-b-zinc-600\/75 {
  border-bottom-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-b-zinc-600\/80 {
  border-bottom-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-b-zinc-600\/85 {
  border-bottom-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-b-zinc-600\/90 {
  border-bottom-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-b-zinc-600\/95 {
  border-bottom-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-b-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-700\/0 {
  border-bottom-color: rgb(63 63 70 / 0) !important;
}

.tw-border-b-zinc-700\/10 {
  border-bottom-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-b-zinc-700\/100 {
  border-bottom-color: rgb(63 63 70 / 1) !important;
}

.tw-border-b-zinc-700\/15 {
  border-bottom-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-b-zinc-700\/20 {
  border-bottom-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-b-zinc-700\/25 {
  border-bottom-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-b-zinc-700\/30 {
  border-bottom-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-b-zinc-700\/35 {
  border-bottom-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-b-zinc-700\/40 {
  border-bottom-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-b-zinc-700\/45 {
  border-bottom-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-b-zinc-700\/5 {
  border-bottom-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-b-zinc-700\/50 {
  border-bottom-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-b-zinc-700\/55 {
  border-bottom-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-b-zinc-700\/60 {
  border-bottom-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-b-zinc-700\/65 {
  border-bottom-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-b-zinc-700\/70 {
  border-bottom-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-b-zinc-700\/75 {
  border-bottom-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-b-zinc-700\/80 {
  border-bottom-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-b-zinc-700\/85 {
  border-bottom-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-b-zinc-700\/90 {
  border-bottom-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-b-zinc-700\/95 {
  border-bottom-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-b-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-800\/0 {
  border-bottom-color: rgb(39 39 42 / 0) !important;
}

.tw-border-b-zinc-800\/10 {
  border-bottom-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-b-zinc-800\/100 {
  border-bottom-color: rgb(39 39 42 / 1) !important;
}

.tw-border-b-zinc-800\/15 {
  border-bottom-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-b-zinc-800\/20 {
  border-bottom-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-b-zinc-800\/25 {
  border-bottom-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-b-zinc-800\/30 {
  border-bottom-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-b-zinc-800\/35 {
  border-bottom-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-b-zinc-800\/40 {
  border-bottom-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-b-zinc-800\/45 {
  border-bottom-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-b-zinc-800\/5 {
  border-bottom-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-b-zinc-800\/50 {
  border-bottom-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-b-zinc-800\/55 {
  border-bottom-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-b-zinc-800\/60 {
  border-bottom-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-b-zinc-800\/65 {
  border-bottom-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-b-zinc-800\/70 {
  border-bottom-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-b-zinc-800\/75 {
  border-bottom-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-b-zinc-800\/80 {
  border-bottom-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-b-zinc-800\/85 {
  border-bottom-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-b-zinc-800\/90 {
  border-bottom-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-b-zinc-800\/95 {
  border-bottom-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-b-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-900\/0 {
  border-bottom-color: rgb(24 24 27 / 0) !important;
}

.tw-border-b-zinc-900\/10 {
  border-bottom-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-b-zinc-900\/100 {
  border-bottom-color: rgb(24 24 27 / 1) !important;
}

.tw-border-b-zinc-900\/15 {
  border-bottom-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-b-zinc-900\/20 {
  border-bottom-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-b-zinc-900\/25 {
  border-bottom-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-b-zinc-900\/30 {
  border-bottom-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-b-zinc-900\/35 {
  border-bottom-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-b-zinc-900\/40 {
  border-bottom-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-b-zinc-900\/45 {
  border-bottom-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-b-zinc-900\/5 {
  border-bottom-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-b-zinc-900\/50 {
  border-bottom-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-b-zinc-900\/55 {
  border-bottom-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-b-zinc-900\/60 {
  border-bottom-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-b-zinc-900\/65 {
  border-bottom-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-b-zinc-900\/70 {
  border-bottom-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-b-zinc-900\/75 {
  border-bottom-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-b-zinc-900\/80 {
  border-bottom-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-b-zinc-900\/85 {
  border-bottom-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-b-zinc-900\/90 {
  border-bottom-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-b-zinc-900\/95 {
  border-bottom-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-b-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-bottom-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-b-zinc-950\/0 {
  border-bottom-color: rgb(9 9 11 / 0) !important;
}

.tw-border-b-zinc-950\/10 {
  border-bottom-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-b-zinc-950\/100 {
  border-bottom-color: rgb(9 9 11 / 1) !important;
}

.tw-border-b-zinc-950\/15 {
  border-bottom-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-b-zinc-950\/20 {
  border-bottom-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-b-zinc-950\/25 {
  border-bottom-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-b-zinc-950\/30 {
  border-bottom-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-b-zinc-950\/35 {
  border-bottom-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-b-zinc-950\/40 {
  border-bottom-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-b-zinc-950\/45 {
  border-bottom-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-b-zinc-950\/5 {
  border-bottom-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-b-zinc-950\/50 {
  border-bottom-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-b-zinc-950\/55 {
  border-bottom-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-b-zinc-950\/60 {
  border-bottom-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-b-zinc-950\/65 {
  border-bottom-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-b-zinc-950\/70 {
  border-bottom-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-b-zinc-950\/75 {
  border-bottom-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-b-zinc-950\/80 {
  border-bottom-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-b-zinc-950\/85 {
  border-bottom-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-b-zinc-950\/90 {
  border-bottom-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-b-zinc-950\/95 {
  border-bottom-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-e-amber-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-100\/0 {
  border-inline-end-color: rgb(254 243 199 / 0) !important;
}

.tw-border-e-amber-100\/10 {
  border-inline-end-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-e-amber-100\/100 {
  border-inline-end-color: rgb(254 243 199 / 1) !important;
}

.tw-border-e-amber-100\/15 {
  border-inline-end-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-e-amber-100\/20 {
  border-inline-end-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-e-amber-100\/25 {
  border-inline-end-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-e-amber-100\/30 {
  border-inline-end-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-e-amber-100\/35 {
  border-inline-end-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-e-amber-100\/40 {
  border-inline-end-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-e-amber-100\/45 {
  border-inline-end-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-e-amber-100\/5 {
  border-inline-end-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-e-amber-100\/50 {
  border-inline-end-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-e-amber-100\/55 {
  border-inline-end-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-e-amber-100\/60 {
  border-inline-end-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-e-amber-100\/65 {
  border-inline-end-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-e-amber-100\/70 {
  border-inline-end-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-e-amber-100\/75 {
  border-inline-end-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-e-amber-100\/80 {
  border-inline-end-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-e-amber-100\/85 {
  border-inline-end-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-e-amber-100\/90 {
  border-inline-end-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-e-amber-100\/95 {
  border-inline-end-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-e-amber-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-200\/0 {
  border-inline-end-color: rgb(253 230 138 / 0) !important;
}

.tw-border-e-amber-200\/10 {
  border-inline-end-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-e-amber-200\/100 {
  border-inline-end-color: rgb(253 230 138 / 1) !important;
}

.tw-border-e-amber-200\/15 {
  border-inline-end-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-e-amber-200\/20 {
  border-inline-end-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-e-amber-200\/25 {
  border-inline-end-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-e-amber-200\/30 {
  border-inline-end-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-e-amber-200\/35 {
  border-inline-end-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-e-amber-200\/40 {
  border-inline-end-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-e-amber-200\/45 {
  border-inline-end-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-e-amber-200\/5 {
  border-inline-end-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-e-amber-200\/50 {
  border-inline-end-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-e-amber-200\/55 {
  border-inline-end-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-e-amber-200\/60 {
  border-inline-end-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-e-amber-200\/65 {
  border-inline-end-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-e-amber-200\/70 {
  border-inline-end-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-e-amber-200\/75 {
  border-inline-end-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-e-amber-200\/80 {
  border-inline-end-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-e-amber-200\/85 {
  border-inline-end-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-e-amber-200\/90 {
  border-inline-end-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-e-amber-200\/95 {
  border-inline-end-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-e-amber-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-300\/0 {
  border-inline-end-color: rgb(252 211 77 / 0) !important;
}

.tw-border-e-amber-300\/10 {
  border-inline-end-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-e-amber-300\/100 {
  border-inline-end-color: rgb(252 211 77 / 1) !important;
}

.tw-border-e-amber-300\/15 {
  border-inline-end-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-e-amber-300\/20 {
  border-inline-end-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-e-amber-300\/25 {
  border-inline-end-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-e-amber-300\/30 {
  border-inline-end-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-e-amber-300\/35 {
  border-inline-end-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-e-amber-300\/40 {
  border-inline-end-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-e-amber-300\/45 {
  border-inline-end-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-e-amber-300\/5 {
  border-inline-end-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-e-amber-300\/50 {
  border-inline-end-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-e-amber-300\/55 {
  border-inline-end-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-e-amber-300\/60 {
  border-inline-end-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-e-amber-300\/65 {
  border-inline-end-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-e-amber-300\/70 {
  border-inline-end-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-e-amber-300\/75 {
  border-inline-end-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-e-amber-300\/80 {
  border-inline-end-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-e-amber-300\/85 {
  border-inline-end-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-e-amber-300\/90 {
  border-inline-end-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-e-amber-300\/95 {
  border-inline-end-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-e-amber-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-400\/0 {
  border-inline-end-color: rgb(251 191 36 / 0) !important;
}

.tw-border-e-amber-400\/10 {
  border-inline-end-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-e-amber-400\/100 {
  border-inline-end-color: rgb(251 191 36 / 1) !important;
}

.tw-border-e-amber-400\/15 {
  border-inline-end-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-e-amber-400\/20 {
  border-inline-end-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-e-amber-400\/25 {
  border-inline-end-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-e-amber-400\/30 {
  border-inline-end-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-e-amber-400\/35 {
  border-inline-end-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-e-amber-400\/40 {
  border-inline-end-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-e-amber-400\/45 {
  border-inline-end-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-e-amber-400\/5 {
  border-inline-end-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-e-amber-400\/50 {
  border-inline-end-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-e-amber-400\/55 {
  border-inline-end-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-e-amber-400\/60 {
  border-inline-end-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-e-amber-400\/65 {
  border-inline-end-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-e-amber-400\/70 {
  border-inline-end-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-e-amber-400\/75 {
  border-inline-end-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-e-amber-400\/80 {
  border-inline-end-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-e-amber-400\/85 {
  border-inline-end-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-e-amber-400\/90 {
  border-inline-end-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-e-amber-400\/95 {
  border-inline-end-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-e-amber-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-50\/0 {
  border-inline-end-color: rgb(255 251 235 / 0) !important;
}

.tw-border-e-amber-50\/10 {
  border-inline-end-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-e-amber-50\/100 {
  border-inline-end-color: rgb(255 251 235 / 1) !important;
}

.tw-border-e-amber-50\/15 {
  border-inline-end-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-e-amber-50\/20 {
  border-inline-end-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-e-amber-50\/25 {
  border-inline-end-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-e-amber-50\/30 {
  border-inline-end-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-e-amber-50\/35 {
  border-inline-end-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-e-amber-50\/40 {
  border-inline-end-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-e-amber-50\/45 {
  border-inline-end-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-e-amber-50\/5 {
  border-inline-end-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-e-amber-50\/50 {
  border-inline-end-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-e-amber-50\/55 {
  border-inline-end-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-e-amber-50\/60 {
  border-inline-end-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-e-amber-50\/65 {
  border-inline-end-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-e-amber-50\/70 {
  border-inline-end-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-e-amber-50\/75 {
  border-inline-end-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-e-amber-50\/80 {
  border-inline-end-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-e-amber-50\/85 {
  border-inline-end-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-e-amber-50\/90 {
  border-inline-end-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-e-amber-50\/95 {
  border-inline-end-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-e-amber-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-500\/0 {
  border-inline-end-color: rgb(245 158 11 / 0) !important;
}

.tw-border-e-amber-500\/10 {
  border-inline-end-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-e-amber-500\/100 {
  border-inline-end-color: rgb(245 158 11 / 1) !important;
}

.tw-border-e-amber-500\/15 {
  border-inline-end-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-e-amber-500\/20 {
  border-inline-end-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-e-amber-500\/25 {
  border-inline-end-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-e-amber-500\/30 {
  border-inline-end-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-e-amber-500\/35 {
  border-inline-end-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-e-amber-500\/40 {
  border-inline-end-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-e-amber-500\/45 {
  border-inline-end-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-e-amber-500\/5 {
  border-inline-end-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-e-amber-500\/50 {
  border-inline-end-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-e-amber-500\/55 {
  border-inline-end-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-e-amber-500\/60 {
  border-inline-end-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-e-amber-500\/65 {
  border-inline-end-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-e-amber-500\/70 {
  border-inline-end-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-e-amber-500\/75 {
  border-inline-end-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-e-amber-500\/80 {
  border-inline-end-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-e-amber-500\/85 {
  border-inline-end-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-e-amber-500\/90 {
  border-inline-end-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-e-amber-500\/95 {
  border-inline-end-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-e-amber-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-600\/0 {
  border-inline-end-color: rgb(217 119 6 / 0) !important;
}

.tw-border-e-amber-600\/10 {
  border-inline-end-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-e-amber-600\/100 {
  border-inline-end-color: rgb(217 119 6 / 1) !important;
}

.tw-border-e-amber-600\/15 {
  border-inline-end-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-e-amber-600\/20 {
  border-inline-end-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-e-amber-600\/25 {
  border-inline-end-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-e-amber-600\/30 {
  border-inline-end-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-e-amber-600\/35 {
  border-inline-end-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-e-amber-600\/40 {
  border-inline-end-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-e-amber-600\/45 {
  border-inline-end-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-e-amber-600\/5 {
  border-inline-end-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-e-amber-600\/50 {
  border-inline-end-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-e-amber-600\/55 {
  border-inline-end-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-e-amber-600\/60 {
  border-inline-end-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-e-amber-600\/65 {
  border-inline-end-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-e-amber-600\/70 {
  border-inline-end-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-e-amber-600\/75 {
  border-inline-end-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-e-amber-600\/80 {
  border-inline-end-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-e-amber-600\/85 {
  border-inline-end-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-e-amber-600\/90 {
  border-inline-end-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-e-amber-600\/95 {
  border-inline-end-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-e-amber-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-700\/0 {
  border-inline-end-color: rgb(180 83 9 / 0) !important;
}

.tw-border-e-amber-700\/10 {
  border-inline-end-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-e-amber-700\/100 {
  border-inline-end-color: rgb(180 83 9 / 1) !important;
}

.tw-border-e-amber-700\/15 {
  border-inline-end-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-e-amber-700\/20 {
  border-inline-end-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-e-amber-700\/25 {
  border-inline-end-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-e-amber-700\/30 {
  border-inline-end-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-e-amber-700\/35 {
  border-inline-end-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-e-amber-700\/40 {
  border-inline-end-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-e-amber-700\/45 {
  border-inline-end-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-e-amber-700\/5 {
  border-inline-end-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-e-amber-700\/50 {
  border-inline-end-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-e-amber-700\/55 {
  border-inline-end-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-e-amber-700\/60 {
  border-inline-end-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-e-amber-700\/65 {
  border-inline-end-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-e-amber-700\/70 {
  border-inline-end-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-e-amber-700\/75 {
  border-inline-end-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-e-amber-700\/80 {
  border-inline-end-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-e-amber-700\/85 {
  border-inline-end-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-e-amber-700\/90 {
  border-inline-end-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-e-amber-700\/95 {
  border-inline-end-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-e-amber-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-800\/0 {
  border-inline-end-color: rgb(146 64 14 / 0) !important;
}

.tw-border-e-amber-800\/10 {
  border-inline-end-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-e-amber-800\/100 {
  border-inline-end-color: rgb(146 64 14 / 1) !important;
}

.tw-border-e-amber-800\/15 {
  border-inline-end-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-e-amber-800\/20 {
  border-inline-end-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-e-amber-800\/25 {
  border-inline-end-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-e-amber-800\/30 {
  border-inline-end-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-e-amber-800\/35 {
  border-inline-end-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-e-amber-800\/40 {
  border-inline-end-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-e-amber-800\/45 {
  border-inline-end-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-e-amber-800\/5 {
  border-inline-end-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-e-amber-800\/50 {
  border-inline-end-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-e-amber-800\/55 {
  border-inline-end-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-e-amber-800\/60 {
  border-inline-end-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-e-amber-800\/65 {
  border-inline-end-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-e-amber-800\/70 {
  border-inline-end-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-e-amber-800\/75 {
  border-inline-end-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-e-amber-800\/80 {
  border-inline-end-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-e-amber-800\/85 {
  border-inline-end-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-e-amber-800\/90 {
  border-inline-end-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-e-amber-800\/95 {
  border-inline-end-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-e-amber-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-900\/0 {
  border-inline-end-color: rgb(120 53 15 / 0) !important;
}

.tw-border-e-amber-900\/10 {
  border-inline-end-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-e-amber-900\/100 {
  border-inline-end-color: rgb(120 53 15 / 1) !important;
}

.tw-border-e-amber-900\/15 {
  border-inline-end-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-e-amber-900\/20 {
  border-inline-end-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-e-amber-900\/25 {
  border-inline-end-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-e-amber-900\/30 {
  border-inline-end-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-e-amber-900\/35 {
  border-inline-end-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-e-amber-900\/40 {
  border-inline-end-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-e-amber-900\/45 {
  border-inline-end-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-e-amber-900\/5 {
  border-inline-end-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-e-amber-900\/50 {
  border-inline-end-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-e-amber-900\/55 {
  border-inline-end-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-e-amber-900\/60 {
  border-inline-end-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-e-amber-900\/65 {
  border-inline-end-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-e-amber-900\/70 {
  border-inline-end-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-e-amber-900\/75 {
  border-inline-end-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-e-amber-900\/80 {
  border-inline-end-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-e-amber-900\/85 {
  border-inline-end-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-e-amber-900\/90 {
  border-inline-end-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-e-amber-900\/95 {
  border-inline-end-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-e-amber-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-e-amber-950\/0 {
  border-inline-end-color: rgb(69 26 3 / 0) !important;
}

.tw-border-e-amber-950\/10 {
  border-inline-end-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-e-amber-950\/100 {
  border-inline-end-color: rgb(69 26 3 / 1) !important;
}

.tw-border-e-amber-950\/15 {
  border-inline-end-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-e-amber-950\/20 {
  border-inline-end-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-e-amber-950\/25 {
  border-inline-end-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-e-amber-950\/30 {
  border-inline-end-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-e-amber-950\/35 {
  border-inline-end-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-e-amber-950\/40 {
  border-inline-end-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-e-amber-950\/45 {
  border-inline-end-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-e-amber-950\/5 {
  border-inline-end-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-e-amber-950\/50 {
  border-inline-end-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-e-amber-950\/55 {
  border-inline-end-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-e-amber-950\/60 {
  border-inline-end-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-e-amber-950\/65 {
  border-inline-end-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-e-amber-950\/70 {
  border-inline-end-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-e-amber-950\/75 {
  border-inline-end-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-e-amber-950\/80 {
  border-inline-end-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-e-amber-950\/85 {
  border-inline-end-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-e-amber-950\/90 {
  border-inline-end-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-e-amber-950\/95 {
  border-inline-end-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-e-black {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-e-black\/0 {
  border-inline-end-color: rgb(0 0 0 / 0) !important;
}

.tw-border-e-black\/10 {
  border-inline-end-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-e-black\/100 {
  border-inline-end-color: rgb(0 0 0 / 1) !important;
}

.tw-border-e-black\/15 {
  border-inline-end-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-e-black\/20 {
  border-inline-end-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-e-black\/25 {
  border-inline-end-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-e-black\/30 {
  border-inline-end-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-e-black\/35 {
  border-inline-end-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-e-black\/40 {
  border-inline-end-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-e-black\/45 {
  border-inline-end-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-e-black\/5 {
  border-inline-end-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-e-black\/50 {
  border-inline-end-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-e-black\/55 {
  border-inline-end-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-e-black\/60 {
  border-inline-end-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-e-black\/65 {
  border-inline-end-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-e-black\/70 {
  border-inline-end-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-e-black\/75 {
  border-inline-end-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-e-black\/80 {
  border-inline-end-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-e-black\/85 {
  border-inline-end-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-e-black\/90 {
  border-inline-end-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-e-black\/95 {
  border-inline-end-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-e-blue-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-100\/0 {
  border-inline-end-color: rgb(219 234 254 / 0) !important;
}

.tw-border-e-blue-100\/10 {
  border-inline-end-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-e-blue-100\/100 {
  border-inline-end-color: rgb(219 234 254 / 1) !important;
}

.tw-border-e-blue-100\/15 {
  border-inline-end-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-e-blue-100\/20 {
  border-inline-end-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-e-blue-100\/25 {
  border-inline-end-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-e-blue-100\/30 {
  border-inline-end-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-e-blue-100\/35 {
  border-inline-end-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-e-blue-100\/40 {
  border-inline-end-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-e-blue-100\/45 {
  border-inline-end-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-e-blue-100\/5 {
  border-inline-end-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-e-blue-100\/50 {
  border-inline-end-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-e-blue-100\/55 {
  border-inline-end-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-e-blue-100\/60 {
  border-inline-end-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-e-blue-100\/65 {
  border-inline-end-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-e-blue-100\/70 {
  border-inline-end-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-e-blue-100\/75 {
  border-inline-end-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-e-blue-100\/80 {
  border-inline-end-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-e-blue-100\/85 {
  border-inline-end-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-e-blue-100\/90 {
  border-inline-end-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-e-blue-100\/95 {
  border-inline-end-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-e-blue-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-200\/0 {
  border-inline-end-color: rgb(191 219 254 / 0) !important;
}

.tw-border-e-blue-200\/10 {
  border-inline-end-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-e-blue-200\/100 {
  border-inline-end-color: rgb(191 219 254 / 1) !important;
}

.tw-border-e-blue-200\/15 {
  border-inline-end-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-e-blue-200\/20 {
  border-inline-end-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-e-blue-200\/25 {
  border-inline-end-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-e-blue-200\/30 {
  border-inline-end-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-e-blue-200\/35 {
  border-inline-end-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-e-blue-200\/40 {
  border-inline-end-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-e-blue-200\/45 {
  border-inline-end-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-e-blue-200\/5 {
  border-inline-end-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-e-blue-200\/50 {
  border-inline-end-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-e-blue-200\/55 {
  border-inline-end-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-e-blue-200\/60 {
  border-inline-end-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-e-blue-200\/65 {
  border-inline-end-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-e-blue-200\/70 {
  border-inline-end-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-e-blue-200\/75 {
  border-inline-end-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-e-blue-200\/80 {
  border-inline-end-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-e-blue-200\/85 {
  border-inline-end-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-e-blue-200\/90 {
  border-inline-end-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-e-blue-200\/95 {
  border-inline-end-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-e-blue-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-300\/0 {
  border-inline-end-color: rgb(147 197 253 / 0) !important;
}

.tw-border-e-blue-300\/10 {
  border-inline-end-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-e-blue-300\/100 {
  border-inline-end-color: rgb(147 197 253 / 1) !important;
}

.tw-border-e-blue-300\/15 {
  border-inline-end-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-e-blue-300\/20 {
  border-inline-end-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-e-blue-300\/25 {
  border-inline-end-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-e-blue-300\/30 {
  border-inline-end-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-e-blue-300\/35 {
  border-inline-end-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-e-blue-300\/40 {
  border-inline-end-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-e-blue-300\/45 {
  border-inline-end-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-e-blue-300\/5 {
  border-inline-end-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-e-blue-300\/50 {
  border-inline-end-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-e-blue-300\/55 {
  border-inline-end-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-e-blue-300\/60 {
  border-inline-end-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-e-blue-300\/65 {
  border-inline-end-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-e-blue-300\/70 {
  border-inline-end-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-e-blue-300\/75 {
  border-inline-end-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-e-blue-300\/80 {
  border-inline-end-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-e-blue-300\/85 {
  border-inline-end-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-e-blue-300\/90 {
  border-inline-end-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-e-blue-300\/95 {
  border-inline-end-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-e-blue-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-400\/0 {
  border-inline-end-color: rgb(96 165 250 / 0) !important;
}

.tw-border-e-blue-400\/10 {
  border-inline-end-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-e-blue-400\/100 {
  border-inline-end-color: rgb(96 165 250 / 1) !important;
}

.tw-border-e-blue-400\/15 {
  border-inline-end-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-e-blue-400\/20 {
  border-inline-end-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-e-blue-400\/25 {
  border-inline-end-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-e-blue-400\/30 {
  border-inline-end-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-e-blue-400\/35 {
  border-inline-end-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-e-blue-400\/40 {
  border-inline-end-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-e-blue-400\/45 {
  border-inline-end-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-e-blue-400\/5 {
  border-inline-end-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-e-blue-400\/50 {
  border-inline-end-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-e-blue-400\/55 {
  border-inline-end-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-e-blue-400\/60 {
  border-inline-end-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-e-blue-400\/65 {
  border-inline-end-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-e-blue-400\/70 {
  border-inline-end-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-e-blue-400\/75 {
  border-inline-end-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-e-blue-400\/80 {
  border-inline-end-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-e-blue-400\/85 {
  border-inline-end-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-e-blue-400\/90 {
  border-inline-end-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-e-blue-400\/95 {
  border-inline-end-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-e-blue-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-50\/0 {
  border-inline-end-color: rgb(239 246 255 / 0) !important;
}

.tw-border-e-blue-50\/10 {
  border-inline-end-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-e-blue-50\/100 {
  border-inline-end-color: rgb(239 246 255 / 1) !important;
}

.tw-border-e-blue-50\/15 {
  border-inline-end-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-e-blue-50\/20 {
  border-inline-end-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-e-blue-50\/25 {
  border-inline-end-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-e-blue-50\/30 {
  border-inline-end-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-e-blue-50\/35 {
  border-inline-end-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-e-blue-50\/40 {
  border-inline-end-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-e-blue-50\/45 {
  border-inline-end-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-e-blue-50\/5 {
  border-inline-end-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-e-blue-50\/50 {
  border-inline-end-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-e-blue-50\/55 {
  border-inline-end-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-e-blue-50\/60 {
  border-inline-end-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-e-blue-50\/65 {
  border-inline-end-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-e-blue-50\/70 {
  border-inline-end-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-e-blue-50\/75 {
  border-inline-end-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-e-blue-50\/80 {
  border-inline-end-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-e-blue-50\/85 {
  border-inline-end-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-e-blue-50\/90 {
  border-inline-end-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-e-blue-50\/95 {
  border-inline-end-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-e-blue-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-500\/0 {
  border-inline-end-color: rgb(59 130 246 / 0) !important;
}

.tw-border-e-blue-500\/10 {
  border-inline-end-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-e-blue-500\/100 {
  border-inline-end-color: rgb(59 130 246 / 1) !important;
}

.tw-border-e-blue-500\/15 {
  border-inline-end-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-e-blue-500\/20 {
  border-inline-end-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-e-blue-500\/25 {
  border-inline-end-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-e-blue-500\/30 {
  border-inline-end-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-e-blue-500\/35 {
  border-inline-end-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-e-blue-500\/40 {
  border-inline-end-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-e-blue-500\/45 {
  border-inline-end-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-e-blue-500\/5 {
  border-inline-end-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-e-blue-500\/50 {
  border-inline-end-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-e-blue-500\/55 {
  border-inline-end-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-e-blue-500\/60 {
  border-inline-end-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-e-blue-500\/65 {
  border-inline-end-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-e-blue-500\/70 {
  border-inline-end-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-e-blue-500\/75 {
  border-inline-end-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-e-blue-500\/80 {
  border-inline-end-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-e-blue-500\/85 {
  border-inline-end-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-e-blue-500\/90 {
  border-inline-end-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-e-blue-500\/95 {
  border-inline-end-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-e-blue-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-600\/0 {
  border-inline-end-color: rgb(37 99 235 / 0) !important;
}

.tw-border-e-blue-600\/10 {
  border-inline-end-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-e-blue-600\/100 {
  border-inline-end-color: rgb(37 99 235 / 1) !important;
}

.tw-border-e-blue-600\/15 {
  border-inline-end-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-e-blue-600\/20 {
  border-inline-end-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-e-blue-600\/25 {
  border-inline-end-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-e-blue-600\/30 {
  border-inline-end-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-e-blue-600\/35 {
  border-inline-end-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-e-blue-600\/40 {
  border-inline-end-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-e-blue-600\/45 {
  border-inline-end-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-e-blue-600\/5 {
  border-inline-end-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-e-blue-600\/50 {
  border-inline-end-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-e-blue-600\/55 {
  border-inline-end-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-e-blue-600\/60 {
  border-inline-end-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-e-blue-600\/65 {
  border-inline-end-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-e-blue-600\/70 {
  border-inline-end-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-e-blue-600\/75 {
  border-inline-end-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-e-blue-600\/80 {
  border-inline-end-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-e-blue-600\/85 {
  border-inline-end-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-e-blue-600\/90 {
  border-inline-end-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-e-blue-600\/95 {
  border-inline-end-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-e-blue-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-700\/0 {
  border-inline-end-color: rgb(29 78 216 / 0) !important;
}

.tw-border-e-blue-700\/10 {
  border-inline-end-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-e-blue-700\/100 {
  border-inline-end-color: rgb(29 78 216 / 1) !important;
}

.tw-border-e-blue-700\/15 {
  border-inline-end-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-e-blue-700\/20 {
  border-inline-end-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-e-blue-700\/25 {
  border-inline-end-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-e-blue-700\/30 {
  border-inline-end-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-e-blue-700\/35 {
  border-inline-end-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-e-blue-700\/40 {
  border-inline-end-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-e-blue-700\/45 {
  border-inline-end-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-e-blue-700\/5 {
  border-inline-end-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-e-blue-700\/50 {
  border-inline-end-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-e-blue-700\/55 {
  border-inline-end-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-e-blue-700\/60 {
  border-inline-end-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-e-blue-700\/65 {
  border-inline-end-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-e-blue-700\/70 {
  border-inline-end-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-e-blue-700\/75 {
  border-inline-end-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-e-blue-700\/80 {
  border-inline-end-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-e-blue-700\/85 {
  border-inline-end-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-e-blue-700\/90 {
  border-inline-end-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-e-blue-700\/95 {
  border-inline-end-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-e-blue-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-800\/0 {
  border-inline-end-color: rgb(30 64 175 / 0) !important;
}

.tw-border-e-blue-800\/10 {
  border-inline-end-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-e-blue-800\/100 {
  border-inline-end-color: rgb(30 64 175 / 1) !important;
}

.tw-border-e-blue-800\/15 {
  border-inline-end-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-e-blue-800\/20 {
  border-inline-end-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-e-blue-800\/25 {
  border-inline-end-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-e-blue-800\/30 {
  border-inline-end-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-e-blue-800\/35 {
  border-inline-end-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-e-blue-800\/40 {
  border-inline-end-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-e-blue-800\/45 {
  border-inline-end-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-e-blue-800\/5 {
  border-inline-end-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-e-blue-800\/50 {
  border-inline-end-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-e-blue-800\/55 {
  border-inline-end-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-e-blue-800\/60 {
  border-inline-end-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-e-blue-800\/65 {
  border-inline-end-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-e-blue-800\/70 {
  border-inline-end-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-e-blue-800\/75 {
  border-inline-end-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-e-blue-800\/80 {
  border-inline-end-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-e-blue-800\/85 {
  border-inline-end-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-e-blue-800\/90 {
  border-inline-end-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-e-blue-800\/95 {
  border-inline-end-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-e-blue-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-900\/0 {
  border-inline-end-color: rgb(30 58 138 / 0) !important;
}

.tw-border-e-blue-900\/10 {
  border-inline-end-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-e-blue-900\/100 {
  border-inline-end-color: rgb(30 58 138 / 1) !important;
}

.tw-border-e-blue-900\/15 {
  border-inline-end-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-e-blue-900\/20 {
  border-inline-end-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-e-blue-900\/25 {
  border-inline-end-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-e-blue-900\/30 {
  border-inline-end-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-e-blue-900\/35 {
  border-inline-end-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-e-blue-900\/40 {
  border-inline-end-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-e-blue-900\/45 {
  border-inline-end-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-e-blue-900\/5 {
  border-inline-end-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-e-blue-900\/50 {
  border-inline-end-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-e-blue-900\/55 {
  border-inline-end-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-e-blue-900\/60 {
  border-inline-end-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-e-blue-900\/65 {
  border-inline-end-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-e-blue-900\/70 {
  border-inline-end-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-e-blue-900\/75 {
  border-inline-end-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-e-blue-900\/80 {
  border-inline-end-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-e-blue-900\/85 {
  border-inline-end-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-e-blue-900\/90 {
  border-inline-end-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-e-blue-900\/95 {
  border-inline-end-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-e-blue-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-e-blue-950\/0 {
  border-inline-end-color: rgb(23 37 84 / 0) !important;
}

.tw-border-e-blue-950\/10 {
  border-inline-end-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-e-blue-950\/100 {
  border-inline-end-color: rgb(23 37 84 / 1) !important;
}

.tw-border-e-blue-950\/15 {
  border-inline-end-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-e-blue-950\/20 {
  border-inline-end-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-e-blue-950\/25 {
  border-inline-end-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-e-blue-950\/30 {
  border-inline-end-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-e-blue-950\/35 {
  border-inline-end-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-e-blue-950\/40 {
  border-inline-end-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-e-blue-950\/45 {
  border-inline-end-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-e-blue-950\/5 {
  border-inline-end-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-e-blue-950\/50 {
  border-inline-end-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-e-blue-950\/55 {
  border-inline-end-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-e-blue-950\/60 {
  border-inline-end-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-e-blue-950\/65 {
  border-inline-end-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-e-blue-950\/70 {
  border-inline-end-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-e-blue-950\/75 {
  border-inline-end-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-e-blue-950\/80 {
  border-inline-end-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-e-blue-950\/85 {
  border-inline-end-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-e-blue-950\/90 {
  border-inline-end-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-e-blue-950\/95 {
  border-inline-end-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-e-current {
  border-inline-end-color: currentColor !important;
}

.tw-border-e-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-100\/0 {
  border-inline-end-color: rgb(207 250 254 / 0) !important;
}

.tw-border-e-cyan-100\/10 {
  border-inline-end-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-e-cyan-100\/100 {
  border-inline-end-color: rgb(207 250 254 / 1) !important;
}

.tw-border-e-cyan-100\/15 {
  border-inline-end-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-e-cyan-100\/20 {
  border-inline-end-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-e-cyan-100\/25 {
  border-inline-end-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-e-cyan-100\/30 {
  border-inline-end-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-e-cyan-100\/35 {
  border-inline-end-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-e-cyan-100\/40 {
  border-inline-end-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-e-cyan-100\/45 {
  border-inline-end-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-e-cyan-100\/5 {
  border-inline-end-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-e-cyan-100\/50 {
  border-inline-end-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-e-cyan-100\/55 {
  border-inline-end-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-e-cyan-100\/60 {
  border-inline-end-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-e-cyan-100\/65 {
  border-inline-end-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-e-cyan-100\/70 {
  border-inline-end-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-e-cyan-100\/75 {
  border-inline-end-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-e-cyan-100\/80 {
  border-inline-end-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-e-cyan-100\/85 {
  border-inline-end-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-e-cyan-100\/90 {
  border-inline-end-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-e-cyan-100\/95 {
  border-inline-end-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-e-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-200\/0 {
  border-inline-end-color: rgb(165 243 252 / 0) !important;
}

.tw-border-e-cyan-200\/10 {
  border-inline-end-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-e-cyan-200\/100 {
  border-inline-end-color: rgb(165 243 252 / 1) !important;
}

.tw-border-e-cyan-200\/15 {
  border-inline-end-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-e-cyan-200\/20 {
  border-inline-end-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-e-cyan-200\/25 {
  border-inline-end-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-e-cyan-200\/30 {
  border-inline-end-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-e-cyan-200\/35 {
  border-inline-end-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-e-cyan-200\/40 {
  border-inline-end-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-e-cyan-200\/45 {
  border-inline-end-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-e-cyan-200\/5 {
  border-inline-end-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-e-cyan-200\/50 {
  border-inline-end-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-e-cyan-200\/55 {
  border-inline-end-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-e-cyan-200\/60 {
  border-inline-end-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-e-cyan-200\/65 {
  border-inline-end-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-e-cyan-200\/70 {
  border-inline-end-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-e-cyan-200\/75 {
  border-inline-end-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-e-cyan-200\/80 {
  border-inline-end-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-e-cyan-200\/85 {
  border-inline-end-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-e-cyan-200\/90 {
  border-inline-end-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-e-cyan-200\/95 {
  border-inline-end-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-e-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-300\/0 {
  border-inline-end-color: rgb(103 232 249 / 0) !important;
}

.tw-border-e-cyan-300\/10 {
  border-inline-end-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-e-cyan-300\/100 {
  border-inline-end-color: rgb(103 232 249 / 1) !important;
}

.tw-border-e-cyan-300\/15 {
  border-inline-end-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-e-cyan-300\/20 {
  border-inline-end-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-e-cyan-300\/25 {
  border-inline-end-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-e-cyan-300\/30 {
  border-inline-end-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-e-cyan-300\/35 {
  border-inline-end-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-e-cyan-300\/40 {
  border-inline-end-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-e-cyan-300\/45 {
  border-inline-end-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-e-cyan-300\/5 {
  border-inline-end-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-e-cyan-300\/50 {
  border-inline-end-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-e-cyan-300\/55 {
  border-inline-end-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-e-cyan-300\/60 {
  border-inline-end-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-e-cyan-300\/65 {
  border-inline-end-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-e-cyan-300\/70 {
  border-inline-end-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-e-cyan-300\/75 {
  border-inline-end-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-e-cyan-300\/80 {
  border-inline-end-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-e-cyan-300\/85 {
  border-inline-end-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-e-cyan-300\/90 {
  border-inline-end-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-e-cyan-300\/95 {
  border-inline-end-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-e-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-400\/0 {
  border-inline-end-color: rgb(34 211 238 / 0) !important;
}

.tw-border-e-cyan-400\/10 {
  border-inline-end-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-e-cyan-400\/100 {
  border-inline-end-color: rgb(34 211 238 / 1) !important;
}

.tw-border-e-cyan-400\/15 {
  border-inline-end-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-e-cyan-400\/20 {
  border-inline-end-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-e-cyan-400\/25 {
  border-inline-end-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-e-cyan-400\/30 {
  border-inline-end-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-e-cyan-400\/35 {
  border-inline-end-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-e-cyan-400\/40 {
  border-inline-end-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-e-cyan-400\/45 {
  border-inline-end-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-e-cyan-400\/5 {
  border-inline-end-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-e-cyan-400\/50 {
  border-inline-end-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-e-cyan-400\/55 {
  border-inline-end-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-e-cyan-400\/60 {
  border-inline-end-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-e-cyan-400\/65 {
  border-inline-end-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-e-cyan-400\/70 {
  border-inline-end-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-e-cyan-400\/75 {
  border-inline-end-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-e-cyan-400\/80 {
  border-inline-end-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-e-cyan-400\/85 {
  border-inline-end-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-e-cyan-400\/90 {
  border-inline-end-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-e-cyan-400\/95 {
  border-inline-end-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-e-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-50\/0 {
  border-inline-end-color: rgb(236 254 255 / 0) !important;
}

.tw-border-e-cyan-50\/10 {
  border-inline-end-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-e-cyan-50\/100 {
  border-inline-end-color: rgb(236 254 255 / 1) !important;
}

.tw-border-e-cyan-50\/15 {
  border-inline-end-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-e-cyan-50\/20 {
  border-inline-end-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-e-cyan-50\/25 {
  border-inline-end-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-e-cyan-50\/30 {
  border-inline-end-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-e-cyan-50\/35 {
  border-inline-end-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-e-cyan-50\/40 {
  border-inline-end-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-e-cyan-50\/45 {
  border-inline-end-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-e-cyan-50\/5 {
  border-inline-end-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-e-cyan-50\/50 {
  border-inline-end-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-e-cyan-50\/55 {
  border-inline-end-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-e-cyan-50\/60 {
  border-inline-end-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-e-cyan-50\/65 {
  border-inline-end-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-e-cyan-50\/70 {
  border-inline-end-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-e-cyan-50\/75 {
  border-inline-end-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-e-cyan-50\/80 {
  border-inline-end-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-e-cyan-50\/85 {
  border-inline-end-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-e-cyan-50\/90 {
  border-inline-end-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-e-cyan-50\/95 {
  border-inline-end-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-e-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-500\/0 {
  border-inline-end-color: rgb(6 182 212 / 0) !important;
}

.tw-border-e-cyan-500\/10 {
  border-inline-end-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-e-cyan-500\/100 {
  border-inline-end-color: rgb(6 182 212 / 1) !important;
}

.tw-border-e-cyan-500\/15 {
  border-inline-end-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-e-cyan-500\/20 {
  border-inline-end-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-e-cyan-500\/25 {
  border-inline-end-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-e-cyan-500\/30 {
  border-inline-end-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-e-cyan-500\/35 {
  border-inline-end-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-e-cyan-500\/40 {
  border-inline-end-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-e-cyan-500\/45 {
  border-inline-end-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-e-cyan-500\/5 {
  border-inline-end-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-e-cyan-500\/50 {
  border-inline-end-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-e-cyan-500\/55 {
  border-inline-end-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-e-cyan-500\/60 {
  border-inline-end-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-e-cyan-500\/65 {
  border-inline-end-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-e-cyan-500\/70 {
  border-inline-end-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-e-cyan-500\/75 {
  border-inline-end-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-e-cyan-500\/80 {
  border-inline-end-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-e-cyan-500\/85 {
  border-inline-end-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-e-cyan-500\/90 {
  border-inline-end-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-e-cyan-500\/95 {
  border-inline-end-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-e-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-600\/0 {
  border-inline-end-color: rgb(8 145 178 / 0) !important;
}

.tw-border-e-cyan-600\/10 {
  border-inline-end-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-e-cyan-600\/100 {
  border-inline-end-color: rgb(8 145 178 / 1) !important;
}

.tw-border-e-cyan-600\/15 {
  border-inline-end-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-e-cyan-600\/20 {
  border-inline-end-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-e-cyan-600\/25 {
  border-inline-end-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-e-cyan-600\/30 {
  border-inline-end-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-e-cyan-600\/35 {
  border-inline-end-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-e-cyan-600\/40 {
  border-inline-end-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-e-cyan-600\/45 {
  border-inline-end-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-e-cyan-600\/5 {
  border-inline-end-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-e-cyan-600\/50 {
  border-inline-end-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-e-cyan-600\/55 {
  border-inline-end-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-e-cyan-600\/60 {
  border-inline-end-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-e-cyan-600\/65 {
  border-inline-end-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-e-cyan-600\/70 {
  border-inline-end-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-e-cyan-600\/75 {
  border-inline-end-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-e-cyan-600\/80 {
  border-inline-end-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-e-cyan-600\/85 {
  border-inline-end-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-e-cyan-600\/90 {
  border-inline-end-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-e-cyan-600\/95 {
  border-inline-end-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-e-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-700\/0 {
  border-inline-end-color: rgb(14 116 144 / 0) !important;
}

.tw-border-e-cyan-700\/10 {
  border-inline-end-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-e-cyan-700\/100 {
  border-inline-end-color: rgb(14 116 144 / 1) !important;
}

.tw-border-e-cyan-700\/15 {
  border-inline-end-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-e-cyan-700\/20 {
  border-inline-end-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-e-cyan-700\/25 {
  border-inline-end-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-e-cyan-700\/30 {
  border-inline-end-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-e-cyan-700\/35 {
  border-inline-end-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-e-cyan-700\/40 {
  border-inline-end-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-e-cyan-700\/45 {
  border-inline-end-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-e-cyan-700\/5 {
  border-inline-end-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-e-cyan-700\/50 {
  border-inline-end-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-e-cyan-700\/55 {
  border-inline-end-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-e-cyan-700\/60 {
  border-inline-end-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-e-cyan-700\/65 {
  border-inline-end-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-e-cyan-700\/70 {
  border-inline-end-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-e-cyan-700\/75 {
  border-inline-end-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-e-cyan-700\/80 {
  border-inline-end-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-e-cyan-700\/85 {
  border-inline-end-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-e-cyan-700\/90 {
  border-inline-end-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-e-cyan-700\/95 {
  border-inline-end-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-e-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-800\/0 {
  border-inline-end-color: rgb(21 94 117 / 0) !important;
}

.tw-border-e-cyan-800\/10 {
  border-inline-end-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-e-cyan-800\/100 {
  border-inline-end-color: rgb(21 94 117 / 1) !important;
}

.tw-border-e-cyan-800\/15 {
  border-inline-end-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-e-cyan-800\/20 {
  border-inline-end-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-e-cyan-800\/25 {
  border-inline-end-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-e-cyan-800\/30 {
  border-inline-end-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-e-cyan-800\/35 {
  border-inline-end-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-e-cyan-800\/40 {
  border-inline-end-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-e-cyan-800\/45 {
  border-inline-end-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-e-cyan-800\/5 {
  border-inline-end-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-e-cyan-800\/50 {
  border-inline-end-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-e-cyan-800\/55 {
  border-inline-end-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-e-cyan-800\/60 {
  border-inline-end-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-e-cyan-800\/65 {
  border-inline-end-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-e-cyan-800\/70 {
  border-inline-end-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-e-cyan-800\/75 {
  border-inline-end-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-e-cyan-800\/80 {
  border-inline-end-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-e-cyan-800\/85 {
  border-inline-end-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-e-cyan-800\/90 {
  border-inline-end-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-e-cyan-800\/95 {
  border-inline-end-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-e-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-900\/0 {
  border-inline-end-color: rgb(22 78 99 / 0) !important;
}

.tw-border-e-cyan-900\/10 {
  border-inline-end-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-e-cyan-900\/100 {
  border-inline-end-color: rgb(22 78 99 / 1) !important;
}

.tw-border-e-cyan-900\/15 {
  border-inline-end-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-e-cyan-900\/20 {
  border-inline-end-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-e-cyan-900\/25 {
  border-inline-end-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-e-cyan-900\/30 {
  border-inline-end-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-e-cyan-900\/35 {
  border-inline-end-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-e-cyan-900\/40 {
  border-inline-end-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-e-cyan-900\/45 {
  border-inline-end-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-e-cyan-900\/5 {
  border-inline-end-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-e-cyan-900\/50 {
  border-inline-end-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-e-cyan-900\/55 {
  border-inline-end-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-e-cyan-900\/60 {
  border-inline-end-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-e-cyan-900\/65 {
  border-inline-end-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-e-cyan-900\/70 {
  border-inline-end-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-e-cyan-900\/75 {
  border-inline-end-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-e-cyan-900\/80 {
  border-inline-end-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-e-cyan-900\/85 {
  border-inline-end-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-e-cyan-900\/90 {
  border-inline-end-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-e-cyan-900\/95 {
  border-inline-end-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-e-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-e-cyan-950\/0 {
  border-inline-end-color: rgb(8 51 68 / 0) !important;
}

.tw-border-e-cyan-950\/10 {
  border-inline-end-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-e-cyan-950\/100 {
  border-inline-end-color: rgb(8 51 68 / 1) !important;
}

.tw-border-e-cyan-950\/15 {
  border-inline-end-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-e-cyan-950\/20 {
  border-inline-end-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-e-cyan-950\/25 {
  border-inline-end-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-e-cyan-950\/30 {
  border-inline-end-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-e-cyan-950\/35 {
  border-inline-end-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-e-cyan-950\/40 {
  border-inline-end-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-e-cyan-950\/45 {
  border-inline-end-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-e-cyan-950\/5 {
  border-inline-end-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-e-cyan-950\/50 {
  border-inline-end-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-e-cyan-950\/55 {
  border-inline-end-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-e-cyan-950\/60 {
  border-inline-end-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-e-cyan-950\/65 {
  border-inline-end-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-e-cyan-950\/70 {
  border-inline-end-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-e-cyan-950\/75 {
  border-inline-end-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-e-cyan-950\/80 {
  border-inline-end-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-e-cyan-950\/85 {
  border-inline-end-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-e-cyan-950\/90 {
  border-inline-end-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-e-cyan-950\/95 {
  border-inline-end-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-e-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-100\/0 {
  border-inline-end-color: rgb(209 250 229 / 0) !important;
}

.tw-border-e-emerald-100\/10 {
  border-inline-end-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-e-emerald-100\/100 {
  border-inline-end-color: rgb(209 250 229 / 1) !important;
}

.tw-border-e-emerald-100\/15 {
  border-inline-end-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-e-emerald-100\/20 {
  border-inline-end-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-e-emerald-100\/25 {
  border-inline-end-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-e-emerald-100\/30 {
  border-inline-end-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-e-emerald-100\/35 {
  border-inline-end-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-e-emerald-100\/40 {
  border-inline-end-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-e-emerald-100\/45 {
  border-inline-end-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-e-emerald-100\/5 {
  border-inline-end-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-e-emerald-100\/50 {
  border-inline-end-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-e-emerald-100\/55 {
  border-inline-end-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-e-emerald-100\/60 {
  border-inline-end-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-e-emerald-100\/65 {
  border-inline-end-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-e-emerald-100\/70 {
  border-inline-end-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-e-emerald-100\/75 {
  border-inline-end-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-e-emerald-100\/80 {
  border-inline-end-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-e-emerald-100\/85 {
  border-inline-end-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-e-emerald-100\/90 {
  border-inline-end-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-e-emerald-100\/95 {
  border-inline-end-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-e-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-200\/0 {
  border-inline-end-color: rgb(167 243 208 / 0) !important;
}

.tw-border-e-emerald-200\/10 {
  border-inline-end-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-e-emerald-200\/100 {
  border-inline-end-color: rgb(167 243 208 / 1) !important;
}

.tw-border-e-emerald-200\/15 {
  border-inline-end-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-e-emerald-200\/20 {
  border-inline-end-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-e-emerald-200\/25 {
  border-inline-end-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-e-emerald-200\/30 {
  border-inline-end-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-e-emerald-200\/35 {
  border-inline-end-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-e-emerald-200\/40 {
  border-inline-end-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-e-emerald-200\/45 {
  border-inline-end-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-e-emerald-200\/5 {
  border-inline-end-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-e-emerald-200\/50 {
  border-inline-end-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-e-emerald-200\/55 {
  border-inline-end-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-e-emerald-200\/60 {
  border-inline-end-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-e-emerald-200\/65 {
  border-inline-end-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-e-emerald-200\/70 {
  border-inline-end-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-e-emerald-200\/75 {
  border-inline-end-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-e-emerald-200\/80 {
  border-inline-end-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-e-emerald-200\/85 {
  border-inline-end-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-e-emerald-200\/90 {
  border-inline-end-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-e-emerald-200\/95 {
  border-inline-end-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-e-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-300\/0 {
  border-inline-end-color: rgb(110 231 183 / 0) !important;
}

.tw-border-e-emerald-300\/10 {
  border-inline-end-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-e-emerald-300\/100 {
  border-inline-end-color: rgb(110 231 183 / 1) !important;
}

.tw-border-e-emerald-300\/15 {
  border-inline-end-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-e-emerald-300\/20 {
  border-inline-end-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-e-emerald-300\/25 {
  border-inline-end-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-e-emerald-300\/30 {
  border-inline-end-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-e-emerald-300\/35 {
  border-inline-end-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-e-emerald-300\/40 {
  border-inline-end-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-e-emerald-300\/45 {
  border-inline-end-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-e-emerald-300\/5 {
  border-inline-end-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-e-emerald-300\/50 {
  border-inline-end-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-e-emerald-300\/55 {
  border-inline-end-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-e-emerald-300\/60 {
  border-inline-end-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-e-emerald-300\/65 {
  border-inline-end-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-e-emerald-300\/70 {
  border-inline-end-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-e-emerald-300\/75 {
  border-inline-end-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-e-emerald-300\/80 {
  border-inline-end-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-e-emerald-300\/85 {
  border-inline-end-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-e-emerald-300\/90 {
  border-inline-end-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-e-emerald-300\/95 {
  border-inline-end-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-e-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-400\/0 {
  border-inline-end-color: rgb(52 211 153 / 0) !important;
}

.tw-border-e-emerald-400\/10 {
  border-inline-end-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-e-emerald-400\/100 {
  border-inline-end-color: rgb(52 211 153 / 1) !important;
}

.tw-border-e-emerald-400\/15 {
  border-inline-end-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-e-emerald-400\/20 {
  border-inline-end-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-e-emerald-400\/25 {
  border-inline-end-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-e-emerald-400\/30 {
  border-inline-end-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-e-emerald-400\/35 {
  border-inline-end-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-e-emerald-400\/40 {
  border-inline-end-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-e-emerald-400\/45 {
  border-inline-end-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-e-emerald-400\/5 {
  border-inline-end-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-e-emerald-400\/50 {
  border-inline-end-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-e-emerald-400\/55 {
  border-inline-end-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-e-emerald-400\/60 {
  border-inline-end-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-e-emerald-400\/65 {
  border-inline-end-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-e-emerald-400\/70 {
  border-inline-end-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-e-emerald-400\/75 {
  border-inline-end-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-e-emerald-400\/80 {
  border-inline-end-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-e-emerald-400\/85 {
  border-inline-end-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-e-emerald-400\/90 {
  border-inline-end-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-e-emerald-400\/95 {
  border-inline-end-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-e-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-50\/0 {
  border-inline-end-color: rgb(236 253 245 / 0) !important;
}

.tw-border-e-emerald-50\/10 {
  border-inline-end-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-e-emerald-50\/100 {
  border-inline-end-color: rgb(236 253 245 / 1) !important;
}

.tw-border-e-emerald-50\/15 {
  border-inline-end-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-e-emerald-50\/20 {
  border-inline-end-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-e-emerald-50\/25 {
  border-inline-end-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-e-emerald-50\/30 {
  border-inline-end-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-e-emerald-50\/35 {
  border-inline-end-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-e-emerald-50\/40 {
  border-inline-end-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-e-emerald-50\/45 {
  border-inline-end-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-e-emerald-50\/5 {
  border-inline-end-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-e-emerald-50\/50 {
  border-inline-end-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-e-emerald-50\/55 {
  border-inline-end-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-e-emerald-50\/60 {
  border-inline-end-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-e-emerald-50\/65 {
  border-inline-end-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-e-emerald-50\/70 {
  border-inline-end-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-e-emerald-50\/75 {
  border-inline-end-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-e-emerald-50\/80 {
  border-inline-end-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-e-emerald-50\/85 {
  border-inline-end-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-e-emerald-50\/90 {
  border-inline-end-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-e-emerald-50\/95 {
  border-inline-end-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-e-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-500\/0 {
  border-inline-end-color: rgb(16 185 129 / 0) !important;
}

.tw-border-e-emerald-500\/10 {
  border-inline-end-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-e-emerald-500\/100 {
  border-inline-end-color: rgb(16 185 129 / 1) !important;
}

.tw-border-e-emerald-500\/15 {
  border-inline-end-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-e-emerald-500\/20 {
  border-inline-end-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-e-emerald-500\/25 {
  border-inline-end-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-e-emerald-500\/30 {
  border-inline-end-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-e-emerald-500\/35 {
  border-inline-end-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-e-emerald-500\/40 {
  border-inline-end-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-e-emerald-500\/45 {
  border-inline-end-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-e-emerald-500\/5 {
  border-inline-end-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-e-emerald-500\/50 {
  border-inline-end-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-e-emerald-500\/55 {
  border-inline-end-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-e-emerald-500\/60 {
  border-inline-end-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-e-emerald-500\/65 {
  border-inline-end-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-e-emerald-500\/70 {
  border-inline-end-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-e-emerald-500\/75 {
  border-inline-end-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-e-emerald-500\/80 {
  border-inline-end-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-e-emerald-500\/85 {
  border-inline-end-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-e-emerald-500\/90 {
  border-inline-end-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-e-emerald-500\/95 {
  border-inline-end-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-e-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-600\/0 {
  border-inline-end-color: rgb(5 150 105 / 0) !important;
}

.tw-border-e-emerald-600\/10 {
  border-inline-end-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-e-emerald-600\/100 {
  border-inline-end-color: rgb(5 150 105 / 1) !important;
}

.tw-border-e-emerald-600\/15 {
  border-inline-end-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-e-emerald-600\/20 {
  border-inline-end-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-e-emerald-600\/25 {
  border-inline-end-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-e-emerald-600\/30 {
  border-inline-end-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-e-emerald-600\/35 {
  border-inline-end-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-e-emerald-600\/40 {
  border-inline-end-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-e-emerald-600\/45 {
  border-inline-end-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-e-emerald-600\/5 {
  border-inline-end-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-e-emerald-600\/50 {
  border-inline-end-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-e-emerald-600\/55 {
  border-inline-end-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-e-emerald-600\/60 {
  border-inline-end-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-e-emerald-600\/65 {
  border-inline-end-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-e-emerald-600\/70 {
  border-inline-end-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-e-emerald-600\/75 {
  border-inline-end-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-e-emerald-600\/80 {
  border-inline-end-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-e-emerald-600\/85 {
  border-inline-end-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-e-emerald-600\/90 {
  border-inline-end-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-e-emerald-600\/95 {
  border-inline-end-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-e-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-700\/0 {
  border-inline-end-color: rgb(4 120 87 / 0) !important;
}

.tw-border-e-emerald-700\/10 {
  border-inline-end-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-e-emerald-700\/100 {
  border-inline-end-color: rgb(4 120 87 / 1) !important;
}

.tw-border-e-emerald-700\/15 {
  border-inline-end-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-e-emerald-700\/20 {
  border-inline-end-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-e-emerald-700\/25 {
  border-inline-end-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-e-emerald-700\/30 {
  border-inline-end-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-e-emerald-700\/35 {
  border-inline-end-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-e-emerald-700\/40 {
  border-inline-end-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-e-emerald-700\/45 {
  border-inline-end-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-e-emerald-700\/5 {
  border-inline-end-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-e-emerald-700\/50 {
  border-inline-end-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-e-emerald-700\/55 {
  border-inline-end-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-e-emerald-700\/60 {
  border-inline-end-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-e-emerald-700\/65 {
  border-inline-end-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-e-emerald-700\/70 {
  border-inline-end-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-e-emerald-700\/75 {
  border-inline-end-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-e-emerald-700\/80 {
  border-inline-end-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-e-emerald-700\/85 {
  border-inline-end-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-e-emerald-700\/90 {
  border-inline-end-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-e-emerald-700\/95 {
  border-inline-end-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-e-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-800\/0 {
  border-inline-end-color: rgb(6 95 70 / 0) !important;
}

.tw-border-e-emerald-800\/10 {
  border-inline-end-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-e-emerald-800\/100 {
  border-inline-end-color: rgb(6 95 70 / 1) !important;
}

.tw-border-e-emerald-800\/15 {
  border-inline-end-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-e-emerald-800\/20 {
  border-inline-end-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-e-emerald-800\/25 {
  border-inline-end-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-e-emerald-800\/30 {
  border-inline-end-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-e-emerald-800\/35 {
  border-inline-end-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-e-emerald-800\/40 {
  border-inline-end-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-e-emerald-800\/45 {
  border-inline-end-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-e-emerald-800\/5 {
  border-inline-end-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-e-emerald-800\/50 {
  border-inline-end-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-e-emerald-800\/55 {
  border-inline-end-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-e-emerald-800\/60 {
  border-inline-end-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-e-emerald-800\/65 {
  border-inline-end-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-e-emerald-800\/70 {
  border-inline-end-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-e-emerald-800\/75 {
  border-inline-end-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-e-emerald-800\/80 {
  border-inline-end-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-e-emerald-800\/85 {
  border-inline-end-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-e-emerald-800\/90 {
  border-inline-end-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-e-emerald-800\/95 {
  border-inline-end-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-e-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-900\/0 {
  border-inline-end-color: rgb(6 78 59 / 0) !important;
}

.tw-border-e-emerald-900\/10 {
  border-inline-end-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-e-emerald-900\/100 {
  border-inline-end-color: rgb(6 78 59 / 1) !important;
}

.tw-border-e-emerald-900\/15 {
  border-inline-end-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-e-emerald-900\/20 {
  border-inline-end-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-e-emerald-900\/25 {
  border-inline-end-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-e-emerald-900\/30 {
  border-inline-end-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-e-emerald-900\/35 {
  border-inline-end-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-e-emerald-900\/40 {
  border-inline-end-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-e-emerald-900\/45 {
  border-inline-end-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-e-emerald-900\/5 {
  border-inline-end-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-e-emerald-900\/50 {
  border-inline-end-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-e-emerald-900\/55 {
  border-inline-end-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-e-emerald-900\/60 {
  border-inline-end-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-e-emerald-900\/65 {
  border-inline-end-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-e-emerald-900\/70 {
  border-inline-end-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-e-emerald-900\/75 {
  border-inline-end-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-e-emerald-900\/80 {
  border-inline-end-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-e-emerald-900\/85 {
  border-inline-end-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-e-emerald-900\/90 {
  border-inline-end-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-e-emerald-900\/95 {
  border-inline-end-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-e-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-e-emerald-950\/0 {
  border-inline-end-color: rgb(2 44 34 / 0) !important;
}

.tw-border-e-emerald-950\/10 {
  border-inline-end-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-e-emerald-950\/100 {
  border-inline-end-color: rgb(2 44 34 / 1) !important;
}

.tw-border-e-emerald-950\/15 {
  border-inline-end-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-e-emerald-950\/20 {
  border-inline-end-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-e-emerald-950\/25 {
  border-inline-end-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-e-emerald-950\/30 {
  border-inline-end-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-e-emerald-950\/35 {
  border-inline-end-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-e-emerald-950\/40 {
  border-inline-end-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-e-emerald-950\/45 {
  border-inline-end-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-e-emerald-950\/5 {
  border-inline-end-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-e-emerald-950\/50 {
  border-inline-end-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-e-emerald-950\/55 {
  border-inline-end-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-e-emerald-950\/60 {
  border-inline-end-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-e-emerald-950\/65 {
  border-inline-end-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-e-emerald-950\/70 {
  border-inline-end-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-e-emerald-950\/75 {
  border-inline-end-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-e-emerald-950\/80 {
  border-inline-end-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-e-emerald-950\/85 {
  border-inline-end-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-e-emerald-950\/90 {
  border-inline-end-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-e-emerald-950\/95 {
  border-inline-end-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-e-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-100\/0 {
  border-inline-end-color: rgb(250 232 255 / 0) !important;
}

.tw-border-e-fuchsia-100\/10 {
  border-inline-end-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-e-fuchsia-100\/100 {
  border-inline-end-color: rgb(250 232 255 / 1) !important;
}

.tw-border-e-fuchsia-100\/15 {
  border-inline-end-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-e-fuchsia-100\/20 {
  border-inline-end-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-e-fuchsia-100\/25 {
  border-inline-end-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-e-fuchsia-100\/30 {
  border-inline-end-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-e-fuchsia-100\/35 {
  border-inline-end-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-e-fuchsia-100\/40 {
  border-inline-end-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-e-fuchsia-100\/45 {
  border-inline-end-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-e-fuchsia-100\/5 {
  border-inline-end-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-e-fuchsia-100\/50 {
  border-inline-end-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-e-fuchsia-100\/55 {
  border-inline-end-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-e-fuchsia-100\/60 {
  border-inline-end-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-e-fuchsia-100\/65 {
  border-inline-end-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-e-fuchsia-100\/70 {
  border-inline-end-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-e-fuchsia-100\/75 {
  border-inline-end-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-e-fuchsia-100\/80 {
  border-inline-end-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-e-fuchsia-100\/85 {
  border-inline-end-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-e-fuchsia-100\/90 {
  border-inline-end-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-e-fuchsia-100\/95 {
  border-inline-end-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-e-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-200\/0 {
  border-inline-end-color: rgb(245 208 254 / 0) !important;
}

.tw-border-e-fuchsia-200\/10 {
  border-inline-end-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-e-fuchsia-200\/100 {
  border-inline-end-color: rgb(245 208 254 / 1) !important;
}

.tw-border-e-fuchsia-200\/15 {
  border-inline-end-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-e-fuchsia-200\/20 {
  border-inline-end-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-e-fuchsia-200\/25 {
  border-inline-end-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-e-fuchsia-200\/30 {
  border-inline-end-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-e-fuchsia-200\/35 {
  border-inline-end-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-e-fuchsia-200\/40 {
  border-inline-end-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-e-fuchsia-200\/45 {
  border-inline-end-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-e-fuchsia-200\/5 {
  border-inline-end-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-e-fuchsia-200\/50 {
  border-inline-end-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-e-fuchsia-200\/55 {
  border-inline-end-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-e-fuchsia-200\/60 {
  border-inline-end-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-e-fuchsia-200\/65 {
  border-inline-end-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-e-fuchsia-200\/70 {
  border-inline-end-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-e-fuchsia-200\/75 {
  border-inline-end-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-e-fuchsia-200\/80 {
  border-inline-end-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-e-fuchsia-200\/85 {
  border-inline-end-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-e-fuchsia-200\/90 {
  border-inline-end-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-e-fuchsia-200\/95 {
  border-inline-end-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-e-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-300\/0 {
  border-inline-end-color: rgb(240 171 252 / 0) !important;
}

.tw-border-e-fuchsia-300\/10 {
  border-inline-end-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-e-fuchsia-300\/100 {
  border-inline-end-color: rgb(240 171 252 / 1) !important;
}

.tw-border-e-fuchsia-300\/15 {
  border-inline-end-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-e-fuchsia-300\/20 {
  border-inline-end-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-e-fuchsia-300\/25 {
  border-inline-end-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-e-fuchsia-300\/30 {
  border-inline-end-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-e-fuchsia-300\/35 {
  border-inline-end-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-e-fuchsia-300\/40 {
  border-inline-end-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-e-fuchsia-300\/45 {
  border-inline-end-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-e-fuchsia-300\/5 {
  border-inline-end-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-e-fuchsia-300\/50 {
  border-inline-end-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-e-fuchsia-300\/55 {
  border-inline-end-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-e-fuchsia-300\/60 {
  border-inline-end-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-e-fuchsia-300\/65 {
  border-inline-end-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-e-fuchsia-300\/70 {
  border-inline-end-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-e-fuchsia-300\/75 {
  border-inline-end-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-e-fuchsia-300\/80 {
  border-inline-end-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-e-fuchsia-300\/85 {
  border-inline-end-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-e-fuchsia-300\/90 {
  border-inline-end-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-e-fuchsia-300\/95 {
  border-inline-end-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-e-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-400\/0 {
  border-inline-end-color: rgb(232 121 249 / 0) !important;
}

.tw-border-e-fuchsia-400\/10 {
  border-inline-end-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-e-fuchsia-400\/100 {
  border-inline-end-color: rgb(232 121 249 / 1) !important;
}

.tw-border-e-fuchsia-400\/15 {
  border-inline-end-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-e-fuchsia-400\/20 {
  border-inline-end-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-e-fuchsia-400\/25 {
  border-inline-end-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-e-fuchsia-400\/30 {
  border-inline-end-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-e-fuchsia-400\/35 {
  border-inline-end-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-e-fuchsia-400\/40 {
  border-inline-end-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-e-fuchsia-400\/45 {
  border-inline-end-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-e-fuchsia-400\/5 {
  border-inline-end-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-e-fuchsia-400\/50 {
  border-inline-end-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-e-fuchsia-400\/55 {
  border-inline-end-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-e-fuchsia-400\/60 {
  border-inline-end-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-e-fuchsia-400\/65 {
  border-inline-end-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-e-fuchsia-400\/70 {
  border-inline-end-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-e-fuchsia-400\/75 {
  border-inline-end-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-e-fuchsia-400\/80 {
  border-inline-end-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-e-fuchsia-400\/85 {
  border-inline-end-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-e-fuchsia-400\/90 {
  border-inline-end-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-e-fuchsia-400\/95 {
  border-inline-end-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-e-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-50\/0 {
  border-inline-end-color: rgb(253 244 255 / 0) !important;
}

.tw-border-e-fuchsia-50\/10 {
  border-inline-end-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-e-fuchsia-50\/100 {
  border-inline-end-color: rgb(253 244 255 / 1) !important;
}

.tw-border-e-fuchsia-50\/15 {
  border-inline-end-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-e-fuchsia-50\/20 {
  border-inline-end-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-e-fuchsia-50\/25 {
  border-inline-end-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-e-fuchsia-50\/30 {
  border-inline-end-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-e-fuchsia-50\/35 {
  border-inline-end-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-e-fuchsia-50\/40 {
  border-inline-end-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-e-fuchsia-50\/45 {
  border-inline-end-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-e-fuchsia-50\/5 {
  border-inline-end-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-e-fuchsia-50\/50 {
  border-inline-end-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-e-fuchsia-50\/55 {
  border-inline-end-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-e-fuchsia-50\/60 {
  border-inline-end-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-e-fuchsia-50\/65 {
  border-inline-end-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-e-fuchsia-50\/70 {
  border-inline-end-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-e-fuchsia-50\/75 {
  border-inline-end-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-e-fuchsia-50\/80 {
  border-inline-end-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-e-fuchsia-50\/85 {
  border-inline-end-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-e-fuchsia-50\/90 {
  border-inline-end-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-e-fuchsia-50\/95 {
  border-inline-end-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-e-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-500\/0 {
  border-inline-end-color: rgb(217 70 239 / 0) !important;
}

.tw-border-e-fuchsia-500\/10 {
  border-inline-end-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-e-fuchsia-500\/100 {
  border-inline-end-color: rgb(217 70 239 / 1) !important;
}

.tw-border-e-fuchsia-500\/15 {
  border-inline-end-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-e-fuchsia-500\/20 {
  border-inline-end-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-e-fuchsia-500\/25 {
  border-inline-end-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-e-fuchsia-500\/30 {
  border-inline-end-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-e-fuchsia-500\/35 {
  border-inline-end-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-e-fuchsia-500\/40 {
  border-inline-end-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-e-fuchsia-500\/45 {
  border-inline-end-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-e-fuchsia-500\/5 {
  border-inline-end-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-e-fuchsia-500\/50 {
  border-inline-end-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-e-fuchsia-500\/55 {
  border-inline-end-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-e-fuchsia-500\/60 {
  border-inline-end-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-e-fuchsia-500\/65 {
  border-inline-end-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-e-fuchsia-500\/70 {
  border-inline-end-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-e-fuchsia-500\/75 {
  border-inline-end-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-e-fuchsia-500\/80 {
  border-inline-end-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-e-fuchsia-500\/85 {
  border-inline-end-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-e-fuchsia-500\/90 {
  border-inline-end-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-e-fuchsia-500\/95 {
  border-inline-end-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-e-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-600\/0 {
  border-inline-end-color: rgb(192 38 211 / 0) !important;
}

.tw-border-e-fuchsia-600\/10 {
  border-inline-end-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-e-fuchsia-600\/100 {
  border-inline-end-color: rgb(192 38 211 / 1) !important;
}

.tw-border-e-fuchsia-600\/15 {
  border-inline-end-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-e-fuchsia-600\/20 {
  border-inline-end-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-e-fuchsia-600\/25 {
  border-inline-end-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-e-fuchsia-600\/30 {
  border-inline-end-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-e-fuchsia-600\/35 {
  border-inline-end-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-e-fuchsia-600\/40 {
  border-inline-end-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-e-fuchsia-600\/45 {
  border-inline-end-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-e-fuchsia-600\/5 {
  border-inline-end-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-e-fuchsia-600\/50 {
  border-inline-end-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-e-fuchsia-600\/55 {
  border-inline-end-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-e-fuchsia-600\/60 {
  border-inline-end-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-e-fuchsia-600\/65 {
  border-inline-end-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-e-fuchsia-600\/70 {
  border-inline-end-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-e-fuchsia-600\/75 {
  border-inline-end-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-e-fuchsia-600\/80 {
  border-inline-end-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-e-fuchsia-600\/85 {
  border-inline-end-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-e-fuchsia-600\/90 {
  border-inline-end-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-e-fuchsia-600\/95 {
  border-inline-end-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-e-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-700\/0 {
  border-inline-end-color: rgb(162 28 175 / 0) !important;
}

.tw-border-e-fuchsia-700\/10 {
  border-inline-end-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-e-fuchsia-700\/100 {
  border-inline-end-color: rgb(162 28 175 / 1) !important;
}

.tw-border-e-fuchsia-700\/15 {
  border-inline-end-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-e-fuchsia-700\/20 {
  border-inline-end-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-e-fuchsia-700\/25 {
  border-inline-end-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-e-fuchsia-700\/30 {
  border-inline-end-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-e-fuchsia-700\/35 {
  border-inline-end-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-e-fuchsia-700\/40 {
  border-inline-end-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-e-fuchsia-700\/45 {
  border-inline-end-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-e-fuchsia-700\/5 {
  border-inline-end-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-e-fuchsia-700\/50 {
  border-inline-end-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-e-fuchsia-700\/55 {
  border-inline-end-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-e-fuchsia-700\/60 {
  border-inline-end-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-e-fuchsia-700\/65 {
  border-inline-end-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-e-fuchsia-700\/70 {
  border-inline-end-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-e-fuchsia-700\/75 {
  border-inline-end-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-e-fuchsia-700\/80 {
  border-inline-end-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-e-fuchsia-700\/85 {
  border-inline-end-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-e-fuchsia-700\/90 {
  border-inline-end-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-e-fuchsia-700\/95 {
  border-inline-end-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-e-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-800\/0 {
  border-inline-end-color: rgb(134 25 143 / 0) !important;
}

.tw-border-e-fuchsia-800\/10 {
  border-inline-end-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-e-fuchsia-800\/100 {
  border-inline-end-color: rgb(134 25 143 / 1) !important;
}

.tw-border-e-fuchsia-800\/15 {
  border-inline-end-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-e-fuchsia-800\/20 {
  border-inline-end-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-e-fuchsia-800\/25 {
  border-inline-end-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-e-fuchsia-800\/30 {
  border-inline-end-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-e-fuchsia-800\/35 {
  border-inline-end-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-e-fuchsia-800\/40 {
  border-inline-end-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-e-fuchsia-800\/45 {
  border-inline-end-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-e-fuchsia-800\/5 {
  border-inline-end-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-e-fuchsia-800\/50 {
  border-inline-end-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-e-fuchsia-800\/55 {
  border-inline-end-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-e-fuchsia-800\/60 {
  border-inline-end-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-e-fuchsia-800\/65 {
  border-inline-end-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-e-fuchsia-800\/70 {
  border-inline-end-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-e-fuchsia-800\/75 {
  border-inline-end-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-e-fuchsia-800\/80 {
  border-inline-end-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-e-fuchsia-800\/85 {
  border-inline-end-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-e-fuchsia-800\/90 {
  border-inline-end-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-e-fuchsia-800\/95 {
  border-inline-end-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-e-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-900\/0 {
  border-inline-end-color: rgb(112 26 117 / 0) !important;
}

.tw-border-e-fuchsia-900\/10 {
  border-inline-end-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-e-fuchsia-900\/100 {
  border-inline-end-color: rgb(112 26 117 / 1) !important;
}

.tw-border-e-fuchsia-900\/15 {
  border-inline-end-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-e-fuchsia-900\/20 {
  border-inline-end-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-e-fuchsia-900\/25 {
  border-inline-end-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-e-fuchsia-900\/30 {
  border-inline-end-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-e-fuchsia-900\/35 {
  border-inline-end-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-e-fuchsia-900\/40 {
  border-inline-end-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-e-fuchsia-900\/45 {
  border-inline-end-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-e-fuchsia-900\/5 {
  border-inline-end-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-e-fuchsia-900\/50 {
  border-inline-end-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-e-fuchsia-900\/55 {
  border-inline-end-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-e-fuchsia-900\/60 {
  border-inline-end-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-e-fuchsia-900\/65 {
  border-inline-end-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-e-fuchsia-900\/70 {
  border-inline-end-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-e-fuchsia-900\/75 {
  border-inline-end-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-e-fuchsia-900\/80 {
  border-inline-end-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-e-fuchsia-900\/85 {
  border-inline-end-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-e-fuchsia-900\/90 {
  border-inline-end-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-e-fuchsia-900\/95 {
  border-inline-end-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-e-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-e-fuchsia-950\/0 {
  border-inline-end-color: rgb(74 4 78 / 0) !important;
}

.tw-border-e-fuchsia-950\/10 {
  border-inline-end-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-e-fuchsia-950\/100 {
  border-inline-end-color: rgb(74 4 78 / 1) !important;
}

.tw-border-e-fuchsia-950\/15 {
  border-inline-end-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-e-fuchsia-950\/20 {
  border-inline-end-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-e-fuchsia-950\/25 {
  border-inline-end-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-e-fuchsia-950\/30 {
  border-inline-end-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-e-fuchsia-950\/35 {
  border-inline-end-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-e-fuchsia-950\/40 {
  border-inline-end-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-e-fuchsia-950\/45 {
  border-inline-end-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-e-fuchsia-950\/5 {
  border-inline-end-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-e-fuchsia-950\/50 {
  border-inline-end-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-e-fuchsia-950\/55 {
  border-inline-end-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-e-fuchsia-950\/60 {
  border-inline-end-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-e-fuchsia-950\/65 {
  border-inline-end-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-e-fuchsia-950\/70 {
  border-inline-end-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-e-fuchsia-950\/75 {
  border-inline-end-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-e-fuchsia-950\/80 {
  border-inline-end-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-e-fuchsia-950\/85 {
  border-inline-end-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-e-fuchsia-950\/90 {
  border-inline-end-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-e-fuchsia-950\/95 {
  border-inline-end-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-e-gray-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-100\/0 {
  border-inline-end-color: rgb(243 244 246 / 0) !important;
}

.tw-border-e-gray-100\/10 {
  border-inline-end-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-e-gray-100\/100 {
  border-inline-end-color: rgb(243 244 246 / 1) !important;
}

.tw-border-e-gray-100\/15 {
  border-inline-end-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-e-gray-100\/20 {
  border-inline-end-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-e-gray-100\/25 {
  border-inline-end-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-e-gray-100\/30 {
  border-inline-end-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-e-gray-100\/35 {
  border-inline-end-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-e-gray-100\/40 {
  border-inline-end-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-e-gray-100\/45 {
  border-inline-end-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-e-gray-100\/5 {
  border-inline-end-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-e-gray-100\/50 {
  border-inline-end-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-e-gray-100\/55 {
  border-inline-end-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-e-gray-100\/60 {
  border-inline-end-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-e-gray-100\/65 {
  border-inline-end-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-e-gray-100\/70 {
  border-inline-end-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-e-gray-100\/75 {
  border-inline-end-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-e-gray-100\/80 {
  border-inline-end-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-e-gray-100\/85 {
  border-inline-end-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-e-gray-100\/90 {
  border-inline-end-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-e-gray-100\/95 {
  border-inline-end-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-e-gray-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-200\/0 {
  border-inline-end-color: rgb(229 231 235 / 0) !important;
}

.tw-border-e-gray-200\/10 {
  border-inline-end-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-e-gray-200\/100 {
  border-inline-end-color: rgb(229 231 235 / 1) !important;
}

.tw-border-e-gray-200\/15 {
  border-inline-end-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-e-gray-200\/20 {
  border-inline-end-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-e-gray-200\/25 {
  border-inline-end-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-e-gray-200\/30 {
  border-inline-end-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-e-gray-200\/35 {
  border-inline-end-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-e-gray-200\/40 {
  border-inline-end-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-e-gray-200\/45 {
  border-inline-end-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-e-gray-200\/5 {
  border-inline-end-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-e-gray-200\/50 {
  border-inline-end-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-e-gray-200\/55 {
  border-inline-end-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-e-gray-200\/60 {
  border-inline-end-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-e-gray-200\/65 {
  border-inline-end-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-e-gray-200\/70 {
  border-inline-end-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-e-gray-200\/75 {
  border-inline-end-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-e-gray-200\/80 {
  border-inline-end-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-e-gray-200\/85 {
  border-inline-end-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-e-gray-200\/90 {
  border-inline-end-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-e-gray-200\/95 {
  border-inline-end-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-e-gray-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-300\/0 {
  border-inline-end-color: rgb(209 213 219 / 0) !important;
}

.tw-border-e-gray-300\/10 {
  border-inline-end-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-e-gray-300\/100 {
  border-inline-end-color: rgb(209 213 219 / 1) !important;
}

.tw-border-e-gray-300\/15 {
  border-inline-end-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-e-gray-300\/20 {
  border-inline-end-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-e-gray-300\/25 {
  border-inline-end-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-e-gray-300\/30 {
  border-inline-end-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-e-gray-300\/35 {
  border-inline-end-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-e-gray-300\/40 {
  border-inline-end-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-e-gray-300\/45 {
  border-inline-end-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-e-gray-300\/5 {
  border-inline-end-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-e-gray-300\/50 {
  border-inline-end-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-e-gray-300\/55 {
  border-inline-end-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-e-gray-300\/60 {
  border-inline-end-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-e-gray-300\/65 {
  border-inline-end-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-e-gray-300\/70 {
  border-inline-end-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-e-gray-300\/75 {
  border-inline-end-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-e-gray-300\/80 {
  border-inline-end-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-e-gray-300\/85 {
  border-inline-end-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-e-gray-300\/90 {
  border-inline-end-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-e-gray-300\/95 {
  border-inline-end-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-e-gray-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-400\/0 {
  border-inline-end-color: rgb(156 163 175 / 0) !important;
}

.tw-border-e-gray-400\/10 {
  border-inline-end-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-e-gray-400\/100 {
  border-inline-end-color: rgb(156 163 175 / 1) !important;
}

.tw-border-e-gray-400\/15 {
  border-inline-end-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-e-gray-400\/20 {
  border-inline-end-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-e-gray-400\/25 {
  border-inline-end-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-e-gray-400\/30 {
  border-inline-end-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-e-gray-400\/35 {
  border-inline-end-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-e-gray-400\/40 {
  border-inline-end-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-e-gray-400\/45 {
  border-inline-end-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-e-gray-400\/5 {
  border-inline-end-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-e-gray-400\/50 {
  border-inline-end-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-e-gray-400\/55 {
  border-inline-end-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-e-gray-400\/60 {
  border-inline-end-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-e-gray-400\/65 {
  border-inline-end-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-e-gray-400\/70 {
  border-inline-end-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-e-gray-400\/75 {
  border-inline-end-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-e-gray-400\/80 {
  border-inline-end-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-e-gray-400\/85 {
  border-inline-end-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-e-gray-400\/90 {
  border-inline-end-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-e-gray-400\/95 {
  border-inline-end-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-e-gray-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-50\/0 {
  border-inline-end-color: rgb(249 250 251 / 0) !important;
}

.tw-border-e-gray-50\/10 {
  border-inline-end-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-e-gray-50\/100 {
  border-inline-end-color: rgb(249 250 251 / 1) !important;
}

.tw-border-e-gray-50\/15 {
  border-inline-end-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-e-gray-50\/20 {
  border-inline-end-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-e-gray-50\/25 {
  border-inline-end-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-e-gray-50\/30 {
  border-inline-end-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-e-gray-50\/35 {
  border-inline-end-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-e-gray-50\/40 {
  border-inline-end-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-e-gray-50\/45 {
  border-inline-end-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-e-gray-50\/5 {
  border-inline-end-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-e-gray-50\/50 {
  border-inline-end-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-e-gray-50\/55 {
  border-inline-end-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-e-gray-50\/60 {
  border-inline-end-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-e-gray-50\/65 {
  border-inline-end-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-e-gray-50\/70 {
  border-inline-end-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-e-gray-50\/75 {
  border-inline-end-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-e-gray-50\/80 {
  border-inline-end-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-e-gray-50\/85 {
  border-inline-end-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-e-gray-50\/90 {
  border-inline-end-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-e-gray-50\/95 {
  border-inline-end-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-e-gray-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-500\/0 {
  border-inline-end-color: rgb(107 114 128 / 0) !important;
}

.tw-border-e-gray-500\/10 {
  border-inline-end-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-e-gray-500\/100 {
  border-inline-end-color: rgb(107 114 128 / 1) !important;
}

.tw-border-e-gray-500\/15 {
  border-inline-end-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-e-gray-500\/20 {
  border-inline-end-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-e-gray-500\/25 {
  border-inline-end-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-e-gray-500\/30 {
  border-inline-end-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-e-gray-500\/35 {
  border-inline-end-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-e-gray-500\/40 {
  border-inline-end-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-e-gray-500\/45 {
  border-inline-end-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-e-gray-500\/5 {
  border-inline-end-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-e-gray-500\/50 {
  border-inline-end-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-e-gray-500\/55 {
  border-inline-end-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-e-gray-500\/60 {
  border-inline-end-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-e-gray-500\/65 {
  border-inline-end-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-e-gray-500\/70 {
  border-inline-end-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-e-gray-500\/75 {
  border-inline-end-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-e-gray-500\/80 {
  border-inline-end-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-e-gray-500\/85 {
  border-inline-end-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-e-gray-500\/90 {
  border-inline-end-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-e-gray-500\/95 {
  border-inline-end-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-e-gray-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-600\/0 {
  border-inline-end-color: rgb(75 85 99 / 0) !important;
}

.tw-border-e-gray-600\/10 {
  border-inline-end-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-e-gray-600\/100 {
  border-inline-end-color: rgb(75 85 99 / 1) !important;
}

.tw-border-e-gray-600\/15 {
  border-inline-end-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-e-gray-600\/20 {
  border-inline-end-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-e-gray-600\/25 {
  border-inline-end-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-e-gray-600\/30 {
  border-inline-end-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-e-gray-600\/35 {
  border-inline-end-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-e-gray-600\/40 {
  border-inline-end-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-e-gray-600\/45 {
  border-inline-end-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-e-gray-600\/5 {
  border-inline-end-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-e-gray-600\/50 {
  border-inline-end-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-e-gray-600\/55 {
  border-inline-end-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-e-gray-600\/60 {
  border-inline-end-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-e-gray-600\/65 {
  border-inline-end-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-e-gray-600\/70 {
  border-inline-end-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-e-gray-600\/75 {
  border-inline-end-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-e-gray-600\/80 {
  border-inline-end-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-e-gray-600\/85 {
  border-inline-end-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-e-gray-600\/90 {
  border-inline-end-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-e-gray-600\/95 {
  border-inline-end-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-e-gray-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-700\/0 {
  border-inline-end-color: rgb(55 65 81 / 0) !important;
}

.tw-border-e-gray-700\/10 {
  border-inline-end-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-e-gray-700\/100 {
  border-inline-end-color: rgb(55 65 81 / 1) !important;
}

.tw-border-e-gray-700\/15 {
  border-inline-end-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-e-gray-700\/20 {
  border-inline-end-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-e-gray-700\/25 {
  border-inline-end-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-e-gray-700\/30 {
  border-inline-end-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-e-gray-700\/35 {
  border-inline-end-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-e-gray-700\/40 {
  border-inline-end-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-e-gray-700\/45 {
  border-inline-end-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-e-gray-700\/5 {
  border-inline-end-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-e-gray-700\/50 {
  border-inline-end-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-e-gray-700\/55 {
  border-inline-end-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-e-gray-700\/60 {
  border-inline-end-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-e-gray-700\/65 {
  border-inline-end-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-e-gray-700\/70 {
  border-inline-end-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-e-gray-700\/75 {
  border-inline-end-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-e-gray-700\/80 {
  border-inline-end-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-e-gray-700\/85 {
  border-inline-end-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-e-gray-700\/90 {
  border-inline-end-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-e-gray-700\/95 {
  border-inline-end-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-e-gray-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-800\/0 {
  border-inline-end-color: rgb(31 41 55 / 0) !important;
}

.tw-border-e-gray-800\/10 {
  border-inline-end-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-e-gray-800\/100 {
  border-inline-end-color: rgb(31 41 55 / 1) !important;
}

.tw-border-e-gray-800\/15 {
  border-inline-end-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-e-gray-800\/20 {
  border-inline-end-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-e-gray-800\/25 {
  border-inline-end-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-e-gray-800\/30 {
  border-inline-end-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-e-gray-800\/35 {
  border-inline-end-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-e-gray-800\/40 {
  border-inline-end-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-e-gray-800\/45 {
  border-inline-end-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-e-gray-800\/5 {
  border-inline-end-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-e-gray-800\/50 {
  border-inline-end-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-e-gray-800\/55 {
  border-inline-end-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-e-gray-800\/60 {
  border-inline-end-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-e-gray-800\/65 {
  border-inline-end-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-e-gray-800\/70 {
  border-inline-end-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-e-gray-800\/75 {
  border-inline-end-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-e-gray-800\/80 {
  border-inline-end-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-e-gray-800\/85 {
  border-inline-end-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-e-gray-800\/90 {
  border-inline-end-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-e-gray-800\/95 {
  border-inline-end-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-e-gray-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-900\/0 {
  border-inline-end-color: rgb(17 24 39 / 0) !important;
}

.tw-border-e-gray-900\/10 {
  border-inline-end-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-e-gray-900\/100 {
  border-inline-end-color: rgb(17 24 39 / 1) !important;
}

.tw-border-e-gray-900\/15 {
  border-inline-end-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-e-gray-900\/20 {
  border-inline-end-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-e-gray-900\/25 {
  border-inline-end-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-e-gray-900\/30 {
  border-inline-end-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-e-gray-900\/35 {
  border-inline-end-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-e-gray-900\/40 {
  border-inline-end-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-e-gray-900\/45 {
  border-inline-end-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-e-gray-900\/5 {
  border-inline-end-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-e-gray-900\/50 {
  border-inline-end-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-e-gray-900\/55 {
  border-inline-end-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-e-gray-900\/60 {
  border-inline-end-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-e-gray-900\/65 {
  border-inline-end-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-e-gray-900\/70 {
  border-inline-end-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-e-gray-900\/75 {
  border-inline-end-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-e-gray-900\/80 {
  border-inline-end-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-e-gray-900\/85 {
  border-inline-end-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-e-gray-900\/90 {
  border-inline-end-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-e-gray-900\/95 {
  border-inline-end-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-e-gray-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-e-gray-950\/0 {
  border-inline-end-color: rgb(3 7 18 / 0) !important;
}

.tw-border-e-gray-950\/10 {
  border-inline-end-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-e-gray-950\/100 {
  border-inline-end-color: rgb(3 7 18 / 1) !important;
}

.tw-border-e-gray-950\/15 {
  border-inline-end-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-e-gray-950\/20 {
  border-inline-end-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-e-gray-950\/25 {
  border-inline-end-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-e-gray-950\/30 {
  border-inline-end-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-e-gray-950\/35 {
  border-inline-end-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-e-gray-950\/40 {
  border-inline-end-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-e-gray-950\/45 {
  border-inline-end-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-e-gray-950\/5 {
  border-inline-end-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-e-gray-950\/50 {
  border-inline-end-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-e-gray-950\/55 {
  border-inline-end-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-e-gray-950\/60 {
  border-inline-end-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-e-gray-950\/65 {
  border-inline-end-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-e-gray-950\/70 {
  border-inline-end-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-e-gray-950\/75 {
  border-inline-end-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-e-gray-950\/80 {
  border-inline-end-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-e-gray-950\/85 {
  border-inline-end-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-e-gray-950\/90 {
  border-inline-end-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-e-gray-950\/95 {
  border-inline-end-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-e-green-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-100\/0 {
  border-inline-end-color: rgb(220 252 231 / 0) !important;
}

.tw-border-e-green-100\/10 {
  border-inline-end-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-e-green-100\/100 {
  border-inline-end-color: rgb(220 252 231 / 1) !important;
}

.tw-border-e-green-100\/15 {
  border-inline-end-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-e-green-100\/20 {
  border-inline-end-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-e-green-100\/25 {
  border-inline-end-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-e-green-100\/30 {
  border-inline-end-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-e-green-100\/35 {
  border-inline-end-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-e-green-100\/40 {
  border-inline-end-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-e-green-100\/45 {
  border-inline-end-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-e-green-100\/5 {
  border-inline-end-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-e-green-100\/50 {
  border-inline-end-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-e-green-100\/55 {
  border-inline-end-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-e-green-100\/60 {
  border-inline-end-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-e-green-100\/65 {
  border-inline-end-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-e-green-100\/70 {
  border-inline-end-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-e-green-100\/75 {
  border-inline-end-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-e-green-100\/80 {
  border-inline-end-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-e-green-100\/85 {
  border-inline-end-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-e-green-100\/90 {
  border-inline-end-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-e-green-100\/95 {
  border-inline-end-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-e-green-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-200\/0 {
  border-inline-end-color: rgb(187 247 208 / 0) !important;
}

.tw-border-e-green-200\/10 {
  border-inline-end-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-e-green-200\/100 {
  border-inline-end-color: rgb(187 247 208 / 1) !important;
}

.tw-border-e-green-200\/15 {
  border-inline-end-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-e-green-200\/20 {
  border-inline-end-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-e-green-200\/25 {
  border-inline-end-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-e-green-200\/30 {
  border-inline-end-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-e-green-200\/35 {
  border-inline-end-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-e-green-200\/40 {
  border-inline-end-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-e-green-200\/45 {
  border-inline-end-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-e-green-200\/5 {
  border-inline-end-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-e-green-200\/50 {
  border-inline-end-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-e-green-200\/55 {
  border-inline-end-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-e-green-200\/60 {
  border-inline-end-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-e-green-200\/65 {
  border-inline-end-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-e-green-200\/70 {
  border-inline-end-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-e-green-200\/75 {
  border-inline-end-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-e-green-200\/80 {
  border-inline-end-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-e-green-200\/85 {
  border-inline-end-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-e-green-200\/90 {
  border-inline-end-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-e-green-200\/95 {
  border-inline-end-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-e-green-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-300\/0 {
  border-inline-end-color: rgb(134 239 172 / 0) !important;
}

.tw-border-e-green-300\/10 {
  border-inline-end-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-e-green-300\/100 {
  border-inline-end-color: rgb(134 239 172 / 1) !important;
}

.tw-border-e-green-300\/15 {
  border-inline-end-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-e-green-300\/20 {
  border-inline-end-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-e-green-300\/25 {
  border-inline-end-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-e-green-300\/30 {
  border-inline-end-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-e-green-300\/35 {
  border-inline-end-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-e-green-300\/40 {
  border-inline-end-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-e-green-300\/45 {
  border-inline-end-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-e-green-300\/5 {
  border-inline-end-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-e-green-300\/50 {
  border-inline-end-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-e-green-300\/55 {
  border-inline-end-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-e-green-300\/60 {
  border-inline-end-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-e-green-300\/65 {
  border-inline-end-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-e-green-300\/70 {
  border-inline-end-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-e-green-300\/75 {
  border-inline-end-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-e-green-300\/80 {
  border-inline-end-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-e-green-300\/85 {
  border-inline-end-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-e-green-300\/90 {
  border-inline-end-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-e-green-300\/95 {
  border-inline-end-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-e-green-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-400\/0 {
  border-inline-end-color: rgb(74 222 128 / 0) !important;
}

.tw-border-e-green-400\/10 {
  border-inline-end-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-e-green-400\/100 {
  border-inline-end-color: rgb(74 222 128 / 1) !important;
}

.tw-border-e-green-400\/15 {
  border-inline-end-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-e-green-400\/20 {
  border-inline-end-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-e-green-400\/25 {
  border-inline-end-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-e-green-400\/30 {
  border-inline-end-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-e-green-400\/35 {
  border-inline-end-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-e-green-400\/40 {
  border-inline-end-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-e-green-400\/45 {
  border-inline-end-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-e-green-400\/5 {
  border-inline-end-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-e-green-400\/50 {
  border-inline-end-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-e-green-400\/55 {
  border-inline-end-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-e-green-400\/60 {
  border-inline-end-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-e-green-400\/65 {
  border-inline-end-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-e-green-400\/70 {
  border-inline-end-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-e-green-400\/75 {
  border-inline-end-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-e-green-400\/80 {
  border-inline-end-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-e-green-400\/85 {
  border-inline-end-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-e-green-400\/90 {
  border-inline-end-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-e-green-400\/95 {
  border-inline-end-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-e-green-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-50\/0 {
  border-inline-end-color: rgb(240 253 244 / 0) !important;
}

.tw-border-e-green-50\/10 {
  border-inline-end-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-e-green-50\/100 {
  border-inline-end-color: rgb(240 253 244 / 1) !important;
}

.tw-border-e-green-50\/15 {
  border-inline-end-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-e-green-50\/20 {
  border-inline-end-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-e-green-50\/25 {
  border-inline-end-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-e-green-50\/30 {
  border-inline-end-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-e-green-50\/35 {
  border-inline-end-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-e-green-50\/40 {
  border-inline-end-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-e-green-50\/45 {
  border-inline-end-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-e-green-50\/5 {
  border-inline-end-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-e-green-50\/50 {
  border-inline-end-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-e-green-50\/55 {
  border-inline-end-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-e-green-50\/60 {
  border-inline-end-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-e-green-50\/65 {
  border-inline-end-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-e-green-50\/70 {
  border-inline-end-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-e-green-50\/75 {
  border-inline-end-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-e-green-50\/80 {
  border-inline-end-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-e-green-50\/85 {
  border-inline-end-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-e-green-50\/90 {
  border-inline-end-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-e-green-50\/95 {
  border-inline-end-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-e-green-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-500\/0 {
  border-inline-end-color: rgb(34 197 94 / 0) !important;
}

.tw-border-e-green-500\/10 {
  border-inline-end-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-e-green-500\/100 {
  border-inline-end-color: rgb(34 197 94 / 1) !important;
}

.tw-border-e-green-500\/15 {
  border-inline-end-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-e-green-500\/20 {
  border-inline-end-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-e-green-500\/25 {
  border-inline-end-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-e-green-500\/30 {
  border-inline-end-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-e-green-500\/35 {
  border-inline-end-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-e-green-500\/40 {
  border-inline-end-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-e-green-500\/45 {
  border-inline-end-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-e-green-500\/5 {
  border-inline-end-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-e-green-500\/50 {
  border-inline-end-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-e-green-500\/55 {
  border-inline-end-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-e-green-500\/60 {
  border-inline-end-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-e-green-500\/65 {
  border-inline-end-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-e-green-500\/70 {
  border-inline-end-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-e-green-500\/75 {
  border-inline-end-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-e-green-500\/80 {
  border-inline-end-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-e-green-500\/85 {
  border-inline-end-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-e-green-500\/90 {
  border-inline-end-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-e-green-500\/95 {
  border-inline-end-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-e-green-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-600\/0 {
  border-inline-end-color: rgb(22 163 74 / 0) !important;
}

.tw-border-e-green-600\/10 {
  border-inline-end-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-e-green-600\/100 {
  border-inline-end-color: rgb(22 163 74 / 1) !important;
}

.tw-border-e-green-600\/15 {
  border-inline-end-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-e-green-600\/20 {
  border-inline-end-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-e-green-600\/25 {
  border-inline-end-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-e-green-600\/30 {
  border-inline-end-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-e-green-600\/35 {
  border-inline-end-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-e-green-600\/40 {
  border-inline-end-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-e-green-600\/45 {
  border-inline-end-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-e-green-600\/5 {
  border-inline-end-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-e-green-600\/50 {
  border-inline-end-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-e-green-600\/55 {
  border-inline-end-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-e-green-600\/60 {
  border-inline-end-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-e-green-600\/65 {
  border-inline-end-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-e-green-600\/70 {
  border-inline-end-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-e-green-600\/75 {
  border-inline-end-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-e-green-600\/80 {
  border-inline-end-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-e-green-600\/85 {
  border-inline-end-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-e-green-600\/90 {
  border-inline-end-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-e-green-600\/95 {
  border-inline-end-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-e-green-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-700\/0 {
  border-inline-end-color: rgb(21 128 61 / 0) !important;
}

.tw-border-e-green-700\/10 {
  border-inline-end-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-e-green-700\/100 {
  border-inline-end-color: rgb(21 128 61 / 1) !important;
}

.tw-border-e-green-700\/15 {
  border-inline-end-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-e-green-700\/20 {
  border-inline-end-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-e-green-700\/25 {
  border-inline-end-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-e-green-700\/30 {
  border-inline-end-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-e-green-700\/35 {
  border-inline-end-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-e-green-700\/40 {
  border-inline-end-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-e-green-700\/45 {
  border-inline-end-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-e-green-700\/5 {
  border-inline-end-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-e-green-700\/50 {
  border-inline-end-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-e-green-700\/55 {
  border-inline-end-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-e-green-700\/60 {
  border-inline-end-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-e-green-700\/65 {
  border-inline-end-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-e-green-700\/70 {
  border-inline-end-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-e-green-700\/75 {
  border-inline-end-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-e-green-700\/80 {
  border-inline-end-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-e-green-700\/85 {
  border-inline-end-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-e-green-700\/90 {
  border-inline-end-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-e-green-700\/95 {
  border-inline-end-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-e-green-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-800\/0 {
  border-inline-end-color: rgb(22 101 52 / 0) !important;
}

.tw-border-e-green-800\/10 {
  border-inline-end-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-e-green-800\/100 {
  border-inline-end-color: rgb(22 101 52 / 1) !important;
}

.tw-border-e-green-800\/15 {
  border-inline-end-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-e-green-800\/20 {
  border-inline-end-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-e-green-800\/25 {
  border-inline-end-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-e-green-800\/30 {
  border-inline-end-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-e-green-800\/35 {
  border-inline-end-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-e-green-800\/40 {
  border-inline-end-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-e-green-800\/45 {
  border-inline-end-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-e-green-800\/5 {
  border-inline-end-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-e-green-800\/50 {
  border-inline-end-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-e-green-800\/55 {
  border-inline-end-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-e-green-800\/60 {
  border-inline-end-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-e-green-800\/65 {
  border-inline-end-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-e-green-800\/70 {
  border-inline-end-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-e-green-800\/75 {
  border-inline-end-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-e-green-800\/80 {
  border-inline-end-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-e-green-800\/85 {
  border-inline-end-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-e-green-800\/90 {
  border-inline-end-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-e-green-800\/95 {
  border-inline-end-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-e-green-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-900\/0 {
  border-inline-end-color: rgb(20 83 45 / 0) !important;
}

.tw-border-e-green-900\/10 {
  border-inline-end-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-e-green-900\/100 {
  border-inline-end-color: rgb(20 83 45 / 1) !important;
}

.tw-border-e-green-900\/15 {
  border-inline-end-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-e-green-900\/20 {
  border-inline-end-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-e-green-900\/25 {
  border-inline-end-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-e-green-900\/30 {
  border-inline-end-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-e-green-900\/35 {
  border-inline-end-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-e-green-900\/40 {
  border-inline-end-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-e-green-900\/45 {
  border-inline-end-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-e-green-900\/5 {
  border-inline-end-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-e-green-900\/50 {
  border-inline-end-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-e-green-900\/55 {
  border-inline-end-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-e-green-900\/60 {
  border-inline-end-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-e-green-900\/65 {
  border-inline-end-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-e-green-900\/70 {
  border-inline-end-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-e-green-900\/75 {
  border-inline-end-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-e-green-900\/80 {
  border-inline-end-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-e-green-900\/85 {
  border-inline-end-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-e-green-900\/90 {
  border-inline-end-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-e-green-900\/95 {
  border-inline-end-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-e-green-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-e-green-950\/0 {
  border-inline-end-color: rgb(5 46 22 / 0) !important;
}

.tw-border-e-green-950\/10 {
  border-inline-end-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-e-green-950\/100 {
  border-inline-end-color: rgb(5 46 22 / 1) !important;
}

.tw-border-e-green-950\/15 {
  border-inline-end-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-e-green-950\/20 {
  border-inline-end-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-e-green-950\/25 {
  border-inline-end-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-e-green-950\/30 {
  border-inline-end-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-e-green-950\/35 {
  border-inline-end-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-e-green-950\/40 {
  border-inline-end-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-e-green-950\/45 {
  border-inline-end-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-e-green-950\/5 {
  border-inline-end-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-e-green-950\/50 {
  border-inline-end-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-e-green-950\/55 {
  border-inline-end-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-e-green-950\/60 {
  border-inline-end-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-e-green-950\/65 {
  border-inline-end-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-e-green-950\/70 {
  border-inline-end-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-e-green-950\/75 {
  border-inline-end-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-e-green-950\/80 {
  border-inline-end-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-e-green-950\/85 {
  border-inline-end-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-e-green-950\/90 {
  border-inline-end-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-e-green-950\/95 {
  border-inline-end-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-e-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-100\/0 {
  border-inline-end-color: rgb(224 231 255 / 0) !important;
}

.tw-border-e-indigo-100\/10 {
  border-inline-end-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-e-indigo-100\/100 {
  border-inline-end-color: rgb(224 231 255 / 1) !important;
}

.tw-border-e-indigo-100\/15 {
  border-inline-end-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-e-indigo-100\/20 {
  border-inline-end-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-e-indigo-100\/25 {
  border-inline-end-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-e-indigo-100\/30 {
  border-inline-end-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-e-indigo-100\/35 {
  border-inline-end-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-e-indigo-100\/40 {
  border-inline-end-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-e-indigo-100\/45 {
  border-inline-end-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-e-indigo-100\/5 {
  border-inline-end-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-e-indigo-100\/50 {
  border-inline-end-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-e-indigo-100\/55 {
  border-inline-end-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-e-indigo-100\/60 {
  border-inline-end-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-e-indigo-100\/65 {
  border-inline-end-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-e-indigo-100\/70 {
  border-inline-end-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-e-indigo-100\/75 {
  border-inline-end-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-e-indigo-100\/80 {
  border-inline-end-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-e-indigo-100\/85 {
  border-inline-end-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-e-indigo-100\/90 {
  border-inline-end-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-e-indigo-100\/95 {
  border-inline-end-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-e-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-200\/0 {
  border-inline-end-color: rgb(199 210 254 / 0) !important;
}

.tw-border-e-indigo-200\/10 {
  border-inline-end-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-e-indigo-200\/100 {
  border-inline-end-color: rgb(199 210 254 / 1) !important;
}

.tw-border-e-indigo-200\/15 {
  border-inline-end-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-e-indigo-200\/20 {
  border-inline-end-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-e-indigo-200\/25 {
  border-inline-end-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-e-indigo-200\/30 {
  border-inline-end-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-e-indigo-200\/35 {
  border-inline-end-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-e-indigo-200\/40 {
  border-inline-end-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-e-indigo-200\/45 {
  border-inline-end-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-e-indigo-200\/5 {
  border-inline-end-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-e-indigo-200\/50 {
  border-inline-end-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-e-indigo-200\/55 {
  border-inline-end-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-e-indigo-200\/60 {
  border-inline-end-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-e-indigo-200\/65 {
  border-inline-end-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-e-indigo-200\/70 {
  border-inline-end-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-e-indigo-200\/75 {
  border-inline-end-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-e-indigo-200\/80 {
  border-inline-end-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-e-indigo-200\/85 {
  border-inline-end-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-e-indigo-200\/90 {
  border-inline-end-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-e-indigo-200\/95 {
  border-inline-end-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-e-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-300\/0 {
  border-inline-end-color: rgb(165 180 252 / 0) !important;
}

.tw-border-e-indigo-300\/10 {
  border-inline-end-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-e-indigo-300\/100 {
  border-inline-end-color: rgb(165 180 252 / 1) !important;
}

.tw-border-e-indigo-300\/15 {
  border-inline-end-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-e-indigo-300\/20 {
  border-inline-end-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-e-indigo-300\/25 {
  border-inline-end-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-e-indigo-300\/30 {
  border-inline-end-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-e-indigo-300\/35 {
  border-inline-end-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-e-indigo-300\/40 {
  border-inline-end-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-e-indigo-300\/45 {
  border-inline-end-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-e-indigo-300\/5 {
  border-inline-end-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-e-indigo-300\/50 {
  border-inline-end-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-e-indigo-300\/55 {
  border-inline-end-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-e-indigo-300\/60 {
  border-inline-end-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-e-indigo-300\/65 {
  border-inline-end-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-e-indigo-300\/70 {
  border-inline-end-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-e-indigo-300\/75 {
  border-inline-end-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-e-indigo-300\/80 {
  border-inline-end-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-e-indigo-300\/85 {
  border-inline-end-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-e-indigo-300\/90 {
  border-inline-end-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-e-indigo-300\/95 {
  border-inline-end-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-e-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-400\/0 {
  border-inline-end-color: rgb(129 140 248 / 0) !important;
}

.tw-border-e-indigo-400\/10 {
  border-inline-end-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-e-indigo-400\/100 {
  border-inline-end-color: rgb(129 140 248 / 1) !important;
}

.tw-border-e-indigo-400\/15 {
  border-inline-end-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-e-indigo-400\/20 {
  border-inline-end-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-e-indigo-400\/25 {
  border-inline-end-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-e-indigo-400\/30 {
  border-inline-end-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-e-indigo-400\/35 {
  border-inline-end-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-e-indigo-400\/40 {
  border-inline-end-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-e-indigo-400\/45 {
  border-inline-end-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-e-indigo-400\/5 {
  border-inline-end-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-e-indigo-400\/50 {
  border-inline-end-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-e-indigo-400\/55 {
  border-inline-end-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-e-indigo-400\/60 {
  border-inline-end-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-e-indigo-400\/65 {
  border-inline-end-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-e-indigo-400\/70 {
  border-inline-end-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-e-indigo-400\/75 {
  border-inline-end-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-e-indigo-400\/80 {
  border-inline-end-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-e-indigo-400\/85 {
  border-inline-end-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-e-indigo-400\/90 {
  border-inline-end-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-e-indigo-400\/95 {
  border-inline-end-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-e-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-50\/0 {
  border-inline-end-color: rgb(238 242 255 / 0) !important;
}

.tw-border-e-indigo-50\/10 {
  border-inline-end-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-e-indigo-50\/100 {
  border-inline-end-color: rgb(238 242 255 / 1) !important;
}

.tw-border-e-indigo-50\/15 {
  border-inline-end-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-e-indigo-50\/20 {
  border-inline-end-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-e-indigo-50\/25 {
  border-inline-end-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-e-indigo-50\/30 {
  border-inline-end-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-e-indigo-50\/35 {
  border-inline-end-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-e-indigo-50\/40 {
  border-inline-end-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-e-indigo-50\/45 {
  border-inline-end-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-e-indigo-50\/5 {
  border-inline-end-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-e-indigo-50\/50 {
  border-inline-end-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-e-indigo-50\/55 {
  border-inline-end-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-e-indigo-50\/60 {
  border-inline-end-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-e-indigo-50\/65 {
  border-inline-end-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-e-indigo-50\/70 {
  border-inline-end-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-e-indigo-50\/75 {
  border-inline-end-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-e-indigo-50\/80 {
  border-inline-end-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-e-indigo-50\/85 {
  border-inline-end-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-e-indigo-50\/90 {
  border-inline-end-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-e-indigo-50\/95 {
  border-inline-end-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-e-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-500\/0 {
  border-inline-end-color: rgb(99 102 241 / 0) !important;
}

.tw-border-e-indigo-500\/10 {
  border-inline-end-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-e-indigo-500\/100 {
  border-inline-end-color: rgb(99 102 241 / 1) !important;
}

.tw-border-e-indigo-500\/15 {
  border-inline-end-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-e-indigo-500\/20 {
  border-inline-end-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-e-indigo-500\/25 {
  border-inline-end-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-e-indigo-500\/30 {
  border-inline-end-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-e-indigo-500\/35 {
  border-inline-end-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-e-indigo-500\/40 {
  border-inline-end-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-e-indigo-500\/45 {
  border-inline-end-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-e-indigo-500\/5 {
  border-inline-end-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-e-indigo-500\/50 {
  border-inline-end-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-e-indigo-500\/55 {
  border-inline-end-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-e-indigo-500\/60 {
  border-inline-end-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-e-indigo-500\/65 {
  border-inline-end-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-e-indigo-500\/70 {
  border-inline-end-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-e-indigo-500\/75 {
  border-inline-end-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-e-indigo-500\/80 {
  border-inline-end-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-e-indigo-500\/85 {
  border-inline-end-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-e-indigo-500\/90 {
  border-inline-end-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-e-indigo-500\/95 {
  border-inline-end-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-e-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-600\/0 {
  border-inline-end-color: rgb(79 70 229 / 0) !important;
}

.tw-border-e-indigo-600\/10 {
  border-inline-end-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-e-indigo-600\/100 {
  border-inline-end-color: rgb(79 70 229 / 1) !important;
}

.tw-border-e-indigo-600\/15 {
  border-inline-end-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-e-indigo-600\/20 {
  border-inline-end-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-e-indigo-600\/25 {
  border-inline-end-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-e-indigo-600\/30 {
  border-inline-end-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-e-indigo-600\/35 {
  border-inline-end-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-e-indigo-600\/40 {
  border-inline-end-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-e-indigo-600\/45 {
  border-inline-end-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-e-indigo-600\/5 {
  border-inline-end-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-e-indigo-600\/50 {
  border-inline-end-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-e-indigo-600\/55 {
  border-inline-end-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-e-indigo-600\/60 {
  border-inline-end-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-e-indigo-600\/65 {
  border-inline-end-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-e-indigo-600\/70 {
  border-inline-end-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-e-indigo-600\/75 {
  border-inline-end-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-e-indigo-600\/80 {
  border-inline-end-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-e-indigo-600\/85 {
  border-inline-end-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-e-indigo-600\/90 {
  border-inline-end-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-e-indigo-600\/95 {
  border-inline-end-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-e-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-700\/0 {
  border-inline-end-color: rgb(67 56 202 / 0) !important;
}

.tw-border-e-indigo-700\/10 {
  border-inline-end-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-e-indigo-700\/100 {
  border-inline-end-color: rgb(67 56 202 / 1) !important;
}

.tw-border-e-indigo-700\/15 {
  border-inline-end-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-e-indigo-700\/20 {
  border-inline-end-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-e-indigo-700\/25 {
  border-inline-end-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-e-indigo-700\/30 {
  border-inline-end-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-e-indigo-700\/35 {
  border-inline-end-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-e-indigo-700\/40 {
  border-inline-end-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-e-indigo-700\/45 {
  border-inline-end-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-e-indigo-700\/5 {
  border-inline-end-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-e-indigo-700\/50 {
  border-inline-end-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-e-indigo-700\/55 {
  border-inline-end-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-e-indigo-700\/60 {
  border-inline-end-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-e-indigo-700\/65 {
  border-inline-end-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-e-indigo-700\/70 {
  border-inline-end-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-e-indigo-700\/75 {
  border-inline-end-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-e-indigo-700\/80 {
  border-inline-end-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-e-indigo-700\/85 {
  border-inline-end-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-e-indigo-700\/90 {
  border-inline-end-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-e-indigo-700\/95 {
  border-inline-end-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-e-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-800\/0 {
  border-inline-end-color: rgb(55 48 163 / 0) !important;
}

.tw-border-e-indigo-800\/10 {
  border-inline-end-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-e-indigo-800\/100 {
  border-inline-end-color: rgb(55 48 163 / 1) !important;
}

.tw-border-e-indigo-800\/15 {
  border-inline-end-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-e-indigo-800\/20 {
  border-inline-end-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-e-indigo-800\/25 {
  border-inline-end-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-e-indigo-800\/30 {
  border-inline-end-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-e-indigo-800\/35 {
  border-inline-end-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-e-indigo-800\/40 {
  border-inline-end-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-e-indigo-800\/45 {
  border-inline-end-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-e-indigo-800\/5 {
  border-inline-end-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-e-indigo-800\/50 {
  border-inline-end-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-e-indigo-800\/55 {
  border-inline-end-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-e-indigo-800\/60 {
  border-inline-end-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-e-indigo-800\/65 {
  border-inline-end-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-e-indigo-800\/70 {
  border-inline-end-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-e-indigo-800\/75 {
  border-inline-end-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-e-indigo-800\/80 {
  border-inline-end-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-e-indigo-800\/85 {
  border-inline-end-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-e-indigo-800\/90 {
  border-inline-end-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-e-indigo-800\/95 {
  border-inline-end-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-e-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-900\/0 {
  border-inline-end-color: rgb(49 46 129 / 0) !important;
}

.tw-border-e-indigo-900\/10 {
  border-inline-end-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-e-indigo-900\/100 {
  border-inline-end-color: rgb(49 46 129 / 1) !important;
}

.tw-border-e-indigo-900\/15 {
  border-inline-end-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-e-indigo-900\/20 {
  border-inline-end-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-e-indigo-900\/25 {
  border-inline-end-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-e-indigo-900\/30 {
  border-inline-end-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-e-indigo-900\/35 {
  border-inline-end-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-e-indigo-900\/40 {
  border-inline-end-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-e-indigo-900\/45 {
  border-inline-end-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-e-indigo-900\/5 {
  border-inline-end-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-e-indigo-900\/50 {
  border-inline-end-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-e-indigo-900\/55 {
  border-inline-end-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-e-indigo-900\/60 {
  border-inline-end-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-e-indigo-900\/65 {
  border-inline-end-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-e-indigo-900\/70 {
  border-inline-end-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-e-indigo-900\/75 {
  border-inline-end-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-e-indigo-900\/80 {
  border-inline-end-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-e-indigo-900\/85 {
  border-inline-end-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-e-indigo-900\/90 {
  border-inline-end-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-e-indigo-900\/95 {
  border-inline-end-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-e-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-e-indigo-950\/0 {
  border-inline-end-color: rgb(30 27 75 / 0) !important;
}

.tw-border-e-indigo-950\/10 {
  border-inline-end-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-e-indigo-950\/100 {
  border-inline-end-color: rgb(30 27 75 / 1) !important;
}

.tw-border-e-indigo-950\/15 {
  border-inline-end-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-e-indigo-950\/20 {
  border-inline-end-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-e-indigo-950\/25 {
  border-inline-end-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-e-indigo-950\/30 {
  border-inline-end-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-e-indigo-950\/35 {
  border-inline-end-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-e-indigo-950\/40 {
  border-inline-end-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-e-indigo-950\/45 {
  border-inline-end-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-e-indigo-950\/5 {
  border-inline-end-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-e-indigo-950\/50 {
  border-inline-end-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-e-indigo-950\/55 {
  border-inline-end-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-e-indigo-950\/60 {
  border-inline-end-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-e-indigo-950\/65 {
  border-inline-end-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-e-indigo-950\/70 {
  border-inline-end-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-e-indigo-950\/75 {
  border-inline-end-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-e-indigo-950\/80 {
  border-inline-end-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-e-indigo-950\/85 {
  border-inline-end-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-e-indigo-950\/90 {
  border-inline-end-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-e-indigo-950\/95 {
  border-inline-end-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-e-inherit {
  border-inline-end-color: inherit !important;
}

.tw-border-e-lime-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-100\/0 {
  border-inline-end-color: rgb(236 252 203 / 0) !important;
}

.tw-border-e-lime-100\/10 {
  border-inline-end-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-e-lime-100\/100 {
  border-inline-end-color: rgb(236 252 203 / 1) !important;
}

.tw-border-e-lime-100\/15 {
  border-inline-end-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-e-lime-100\/20 {
  border-inline-end-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-e-lime-100\/25 {
  border-inline-end-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-e-lime-100\/30 {
  border-inline-end-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-e-lime-100\/35 {
  border-inline-end-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-e-lime-100\/40 {
  border-inline-end-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-e-lime-100\/45 {
  border-inline-end-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-e-lime-100\/5 {
  border-inline-end-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-e-lime-100\/50 {
  border-inline-end-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-e-lime-100\/55 {
  border-inline-end-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-e-lime-100\/60 {
  border-inline-end-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-e-lime-100\/65 {
  border-inline-end-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-e-lime-100\/70 {
  border-inline-end-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-e-lime-100\/75 {
  border-inline-end-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-e-lime-100\/80 {
  border-inline-end-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-e-lime-100\/85 {
  border-inline-end-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-e-lime-100\/90 {
  border-inline-end-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-e-lime-100\/95 {
  border-inline-end-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-e-lime-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-200\/0 {
  border-inline-end-color: rgb(217 249 157 / 0) !important;
}

.tw-border-e-lime-200\/10 {
  border-inline-end-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-e-lime-200\/100 {
  border-inline-end-color: rgb(217 249 157 / 1) !important;
}

.tw-border-e-lime-200\/15 {
  border-inline-end-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-e-lime-200\/20 {
  border-inline-end-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-e-lime-200\/25 {
  border-inline-end-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-e-lime-200\/30 {
  border-inline-end-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-e-lime-200\/35 {
  border-inline-end-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-e-lime-200\/40 {
  border-inline-end-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-e-lime-200\/45 {
  border-inline-end-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-e-lime-200\/5 {
  border-inline-end-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-e-lime-200\/50 {
  border-inline-end-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-e-lime-200\/55 {
  border-inline-end-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-e-lime-200\/60 {
  border-inline-end-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-e-lime-200\/65 {
  border-inline-end-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-e-lime-200\/70 {
  border-inline-end-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-e-lime-200\/75 {
  border-inline-end-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-e-lime-200\/80 {
  border-inline-end-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-e-lime-200\/85 {
  border-inline-end-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-e-lime-200\/90 {
  border-inline-end-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-e-lime-200\/95 {
  border-inline-end-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-e-lime-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-300\/0 {
  border-inline-end-color: rgb(190 242 100 / 0) !important;
}

.tw-border-e-lime-300\/10 {
  border-inline-end-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-e-lime-300\/100 {
  border-inline-end-color: rgb(190 242 100 / 1) !important;
}

.tw-border-e-lime-300\/15 {
  border-inline-end-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-e-lime-300\/20 {
  border-inline-end-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-e-lime-300\/25 {
  border-inline-end-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-e-lime-300\/30 {
  border-inline-end-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-e-lime-300\/35 {
  border-inline-end-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-e-lime-300\/40 {
  border-inline-end-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-e-lime-300\/45 {
  border-inline-end-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-e-lime-300\/5 {
  border-inline-end-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-e-lime-300\/50 {
  border-inline-end-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-e-lime-300\/55 {
  border-inline-end-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-e-lime-300\/60 {
  border-inline-end-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-e-lime-300\/65 {
  border-inline-end-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-e-lime-300\/70 {
  border-inline-end-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-e-lime-300\/75 {
  border-inline-end-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-e-lime-300\/80 {
  border-inline-end-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-e-lime-300\/85 {
  border-inline-end-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-e-lime-300\/90 {
  border-inline-end-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-e-lime-300\/95 {
  border-inline-end-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-e-lime-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-400\/0 {
  border-inline-end-color: rgb(163 230 53 / 0) !important;
}

.tw-border-e-lime-400\/10 {
  border-inline-end-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-e-lime-400\/100 {
  border-inline-end-color: rgb(163 230 53 / 1) !important;
}

.tw-border-e-lime-400\/15 {
  border-inline-end-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-e-lime-400\/20 {
  border-inline-end-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-e-lime-400\/25 {
  border-inline-end-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-e-lime-400\/30 {
  border-inline-end-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-e-lime-400\/35 {
  border-inline-end-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-e-lime-400\/40 {
  border-inline-end-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-e-lime-400\/45 {
  border-inline-end-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-e-lime-400\/5 {
  border-inline-end-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-e-lime-400\/50 {
  border-inline-end-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-e-lime-400\/55 {
  border-inline-end-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-e-lime-400\/60 {
  border-inline-end-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-e-lime-400\/65 {
  border-inline-end-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-e-lime-400\/70 {
  border-inline-end-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-e-lime-400\/75 {
  border-inline-end-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-e-lime-400\/80 {
  border-inline-end-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-e-lime-400\/85 {
  border-inline-end-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-e-lime-400\/90 {
  border-inline-end-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-e-lime-400\/95 {
  border-inline-end-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-e-lime-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-50\/0 {
  border-inline-end-color: rgb(247 254 231 / 0) !important;
}

.tw-border-e-lime-50\/10 {
  border-inline-end-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-e-lime-50\/100 {
  border-inline-end-color: rgb(247 254 231 / 1) !important;
}

.tw-border-e-lime-50\/15 {
  border-inline-end-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-e-lime-50\/20 {
  border-inline-end-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-e-lime-50\/25 {
  border-inline-end-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-e-lime-50\/30 {
  border-inline-end-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-e-lime-50\/35 {
  border-inline-end-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-e-lime-50\/40 {
  border-inline-end-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-e-lime-50\/45 {
  border-inline-end-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-e-lime-50\/5 {
  border-inline-end-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-e-lime-50\/50 {
  border-inline-end-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-e-lime-50\/55 {
  border-inline-end-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-e-lime-50\/60 {
  border-inline-end-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-e-lime-50\/65 {
  border-inline-end-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-e-lime-50\/70 {
  border-inline-end-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-e-lime-50\/75 {
  border-inline-end-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-e-lime-50\/80 {
  border-inline-end-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-e-lime-50\/85 {
  border-inline-end-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-e-lime-50\/90 {
  border-inline-end-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-e-lime-50\/95 {
  border-inline-end-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-e-lime-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-500\/0 {
  border-inline-end-color: rgb(132 204 22 / 0) !important;
}

.tw-border-e-lime-500\/10 {
  border-inline-end-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-e-lime-500\/100 {
  border-inline-end-color: rgb(132 204 22 / 1) !important;
}

.tw-border-e-lime-500\/15 {
  border-inline-end-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-e-lime-500\/20 {
  border-inline-end-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-e-lime-500\/25 {
  border-inline-end-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-e-lime-500\/30 {
  border-inline-end-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-e-lime-500\/35 {
  border-inline-end-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-e-lime-500\/40 {
  border-inline-end-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-e-lime-500\/45 {
  border-inline-end-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-e-lime-500\/5 {
  border-inline-end-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-e-lime-500\/50 {
  border-inline-end-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-e-lime-500\/55 {
  border-inline-end-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-e-lime-500\/60 {
  border-inline-end-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-e-lime-500\/65 {
  border-inline-end-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-e-lime-500\/70 {
  border-inline-end-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-e-lime-500\/75 {
  border-inline-end-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-e-lime-500\/80 {
  border-inline-end-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-e-lime-500\/85 {
  border-inline-end-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-e-lime-500\/90 {
  border-inline-end-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-e-lime-500\/95 {
  border-inline-end-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-e-lime-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-600\/0 {
  border-inline-end-color: rgb(101 163 13 / 0) !important;
}

.tw-border-e-lime-600\/10 {
  border-inline-end-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-e-lime-600\/100 {
  border-inline-end-color: rgb(101 163 13 / 1) !important;
}

.tw-border-e-lime-600\/15 {
  border-inline-end-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-e-lime-600\/20 {
  border-inline-end-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-e-lime-600\/25 {
  border-inline-end-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-e-lime-600\/30 {
  border-inline-end-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-e-lime-600\/35 {
  border-inline-end-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-e-lime-600\/40 {
  border-inline-end-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-e-lime-600\/45 {
  border-inline-end-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-e-lime-600\/5 {
  border-inline-end-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-e-lime-600\/50 {
  border-inline-end-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-e-lime-600\/55 {
  border-inline-end-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-e-lime-600\/60 {
  border-inline-end-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-e-lime-600\/65 {
  border-inline-end-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-e-lime-600\/70 {
  border-inline-end-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-e-lime-600\/75 {
  border-inline-end-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-e-lime-600\/80 {
  border-inline-end-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-e-lime-600\/85 {
  border-inline-end-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-e-lime-600\/90 {
  border-inline-end-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-e-lime-600\/95 {
  border-inline-end-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-e-lime-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-700\/0 {
  border-inline-end-color: rgb(77 124 15 / 0) !important;
}

.tw-border-e-lime-700\/10 {
  border-inline-end-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-e-lime-700\/100 {
  border-inline-end-color: rgb(77 124 15 / 1) !important;
}

.tw-border-e-lime-700\/15 {
  border-inline-end-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-e-lime-700\/20 {
  border-inline-end-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-e-lime-700\/25 {
  border-inline-end-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-e-lime-700\/30 {
  border-inline-end-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-e-lime-700\/35 {
  border-inline-end-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-e-lime-700\/40 {
  border-inline-end-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-e-lime-700\/45 {
  border-inline-end-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-e-lime-700\/5 {
  border-inline-end-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-e-lime-700\/50 {
  border-inline-end-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-e-lime-700\/55 {
  border-inline-end-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-e-lime-700\/60 {
  border-inline-end-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-e-lime-700\/65 {
  border-inline-end-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-e-lime-700\/70 {
  border-inline-end-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-e-lime-700\/75 {
  border-inline-end-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-e-lime-700\/80 {
  border-inline-end-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-e-lime-700\/85 {
  border-inline-end-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-e-lime-700\/90 {
  border-inline-end-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-e-lime-700\/95 {
  border-inline-end-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-e-lime-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-800\/0 {
  border-inline-end-color: rgb(63 98 18 / 0) !important;
}

.tw-border-e-lime-800\/10 {
  border-inline-end-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-e-lime-800\/100 {
  border-inline-end-color: rgb(63 98 18 / 1) !important;
}

.tw-border-e-lime-800\/15 {
  border-inline-end-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-e-lime-800\/20 {
  border-inline-end-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-e-lime-800\/25 {
  border-inline-end-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-e-lime-800\/30 {
  border-inline-end-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-e-lime-800\/35 {
  border-inline-end-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-e-lime-800\/40 {
  border-inline-end-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-e-lime-800\/45 {
  border-inline-end-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-e-lime-800\/5 {
  border-inline-end-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-e-lime-800\/50 {
  border-inline-end-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-e-lime-800\/55 {
  border-inline-end-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-e-lime-800\/60 {
  border-inline-end-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-e-lime-800\/65 {
  border-inline-end-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-e-lime-800\/70 {
  border-inline-end-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-e-lime-800\/75 {
  border-inline-end-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-e-lime-800\/80 {
  border-inline-end-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-e-lime-800\/85 {
  border-inline-end-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-e-lime-800\/90 {
  border-inline-end-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-e-lime-800\/95 {
  border-inline-end-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-e-lime-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-900\/0 {
  border-inline-end-color: rgb(54 83 20 / 0) !important;
}

.tw-border-e-lime-900\/10 {
  border-inline-end-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-e-lime-900\/100 {
  border-inline-end-color: rgb(54 83 20 / 1) !important;
}

.tw-border-e-lime-900\/15 {
  border-inline-end-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-e-lime-900\/20 {
  border-inline-end-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-e-lime-900\/25 {
  border-inline-end-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-e-lime-900\/30 {
  border-inline-end-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-e-lime-900\/35 {
  border-inline-end-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-e-lime-900\/40 {
  border-inline-end-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-e-lime-900\/45 {
  border-inline-end-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-e-lime-900\/5 {
  border-inline-end-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-e-lime-900\/50 {
  border-inline-end-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-e-lime-900\/55 {
  border-inline-end-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-e-lime-900\/60 {
  border-inline-end-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-e-lime-900\/65 {
  border-inline-end-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-e-lime-900\/70 {
  border-inline-end-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-e-lime-900\/75 {
  border-inline-end-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-e-lime-900\/80 {
  border-inline-end-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-e-lime-900\/85 {
  border-inline-end-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-e-lime-900\/90 {
  border-inline-end-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-e-lime-900\/95 {
  border-inline-end-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-e-lime-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-e-lime-950\/0 {
  border-inline-end-color: rgb(26 46 5 / 0) !important;
}

.tw-border-e-lime-950\/10 {
  border-inline-end-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-e-lime-950\/100 {
  border-inline-end-color: rgb(26 46 5 / 1) !important;
}

.tw-border-e-lime-950\/15 {
  border-inline-end-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-e-lime-950\/20 {
  border-inline-end-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-e-lime-950\/25 {
  border-inline-end-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-e-lime-950\/30 {
  border-inline-end-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-e-lime-950\/35 {
  border-inline-end-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-e-lime-950\/40 {
  border-inline-end-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-e-lime-950\/45 {
  border-inline-end-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-e-lime-950\/5 {
  border-inline-end-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-e-lime-950\/50 {
  border-inline-end-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-e-lime-950\/55 {
  border-inline-end-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-e-lime-950\/60 {
  border-inline-end-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-e-lime-950\/65 {
  border-inline-end-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-e-lime-950\/70 {
  border-inline-end-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-e-lime-950\/75 {
  border-inline-end-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-e-lime-950\/80 {
  border-inline-end-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-e-lime-950\/85 {
  border-inline-end-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-e-lime-950\/90 {
  border-inline-end-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-e-lime-950\/95 {
  border-inline-end-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-e-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-100\/0 {
  border-inline-end-color: rgb(245 245 245 / 0) !important;
}

.tw-border-e-neutral-100\/10 {
  border-inline-end-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-e-neutral-100\/100 {
  border-inline-end-color: rgb(245 245 245 / 1) !important;
}

.tw-border-e-neutral-100\/15 {
  border-inline-end-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-e-neutral-100\/20 {
  border-inline-end-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-e-neutral-100\/25 {
  border-inline-end-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-e-neutral-100\/30 {
  border-inline-end-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-e-neutral-100\/35 {
  border-inline-end-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-e-neutral-100\/40 {
  border-inline-end-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-e-neutral-100\/45 {
  border-inline-end-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-e-neutral-100\/5 {
  border-inline-end-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-e-neutral-100\/50 {
  border-inline-end-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-e-neutral-100\/55 {
  border-inline-end-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-e-neutral-100\/60 {
  border-inline-end-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-e-neutral-100\/65 {
  border-inline-end-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-e-neutral-100\/70 {
  border-inline-end-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-e-neutral-100\/75 {
  border-inline-end-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-e-neutral-100\/80 {
  border-inline-end-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-e-neutral-100\/85 {
  border-inline-end-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-e-neutral-100\/90 {
  border-inline-end-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-e-neutral-100\/95 {
  border-inline-end-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-e-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-200\/0 {
  border-inline-end-color: rgb(229 229 229 / 0) !important;
}

.tw-border-e-neutral-200\/10 {
  border-inline-end-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-e-neutral-200\/100 {
  border-inline-end-color: rgb(229 229 229 / 1) !important;
}

.tw-border-e-neutral-200\/15 {
  border-inline-end-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-e-neutral-200\/20 {
  border-inline-end-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-e-neutral-200\/25 {
  border-inline-end-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-e-neutral-200\/30 {
  border-inline-end-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-e-neutral-200\/35 {
  border-inline-end-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-e-neutral-200\/40 {
  border-inline-end-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-e-neutral-200\/45 {
  border-inline-end-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-e-neutral-200\/5 {
  border-inline-end-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-e-neutral-200\/50 {
  border-inline-end-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-e-neutral-200\/55 {
  border-inline-end-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-e-neutral-200\/60 {
  border-inline-end-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-e-neutral-200\/65 {
  border-inline-end-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-e-neutral-200\/70 {
  border-inline-end-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-e-neutral-200\/75 {
  border-inline-end-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-e-neutral-200\/80 {
  border-inline-end-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-e-neutral-200\/85 {
  border-inline-end-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-e-neutral-200\/90 {
  border-inline-end-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-e-neutral-200\/95 {
  border-inline-end-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-e-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-300\/0 {
  border-inline-end-color: rgb(212 212 212 / 0) !important;
}

.tw-border-e-neutral-300\/10 {
  border-inline-end-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-e-neutral-300\/100 {
  border-inline-end-color: rgb(212 212 212 / 1) !important;
}

.tw-border-e-neutral-300\/15 {
  border-inline-end-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-e-neutral-300\/20 {
  border-inline-end-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-e-neutral-300\/25 {
  border-inline-end-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-e-neutral-300\/30 {
  border-inline-end-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-e-neutral-300\/35 {
  border-inline-end-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-e-neutral-300\/40 {
  border-inline-end-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-e-neutral-300\/45 {
  border-inline-end-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-e-neutral-300\/5 {
  border-inline-end-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-e-neutral-300\/50 {
  border-inline-end-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-e-neutral-300\/55 {
  border-inline-end-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-e-neutral-300\/60 {
  border-inline-end-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-e-neutral-300\/65 {
  border-inline-end-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-e-neutral-300\/70 {
  border-inline-end-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-e-neutral-300\/75 {
  border-inline-end-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-e-neutral-300\/80 {
  border-inline-end-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-e-neutral-300\/85 {
  border-inline-end-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-e-neutral-300\/90 {
  border-inline-end-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-e-neutral-300\/95 {
  border-inline-end-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-e-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-400\/0 {
  border-inline-end-color: rgb(163 163 163 / 0) !important;
}

.tw-border-e-neutral-400\/10 {
  border-inline-end-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-e-neutral-400\/100 {
  border-inline-end-color: rgb(163 163 163 / 1) !important;
}

.tw-border-e-neutral-400\/15 {
  border-inline-end-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-e-neutral-400\/20 {
  border-inline-end-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-e-neutral-400\/25 {
  border-inline-end-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-e-neutral-400\/30 {
  border-inline-end-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-e-neutral-400\/35 {
  border-inline-end-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-e-neutral-400\/40 {
  border-inline-end-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-e-neutral-400\/45 {
  border-inline-end-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-e-neutral-400\/5 {
  border-inline-end-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-e-neutral-400\/50 {
  border-inline-end-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-e-neutral-400\/55 {
  border-inline-end-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-e-neutral-400\/60 {
  border-inline-end-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-e-neutral-400\/65 {
  border-inline-end-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-e-neutral-400\/70 {
  border-inline-end-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-e-neutral-400\/75 {
  border-inline-end-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-e-neutral-400\/80 {
  border-inline-end-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-e-neutral-400\/85 {
  border-inline-end-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-e-neutral-400\/90 {
  border-inline-end-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-e-neutral-400\/95 {
  border-inline-end-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-e-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-50\/0 {
  border-inline-end-color: rgb(250 250 250 / 0) !important;
}

.tw-border-e-neutral-50\/10 {
  border-inline-end-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-e-neutral-50\/100 {
  border-inline-end-color: rgb(250 250 250 / 1) !important;
}

.tw-border-e-neutral-50\/15 {
  border-inline-end-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-e-neutral-50\/20 {
  border-inline-end-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-e-neutral-50\/25 {
  border-inline-end-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-e-neutral-50\/30 {
  border-inline-end-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-e-neutral-50\/35 {
  border-inline-end-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-e-neutral-50\/40 {
  border-inline-end-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-e-neutral-50\/45 {
  border-inline-end-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-e-neutral-50\/5 {
  border-inline-end-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-e-neutral-50\/50 {
  border-inline-end-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-e-neutral-50\/55 {
  border-inline-end-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-e-neutral-50\/60 {
  border-inline-end-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-e-neutral-50\/65 {
  border-inline-end-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-e-neutral-50\/70 {
  border-inline-end-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-e-neutral-50\/75 {
  border-inline-end-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-e-neutral-50\/80 {
  border-inline-end-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-e-neutral-50\/85 {
  border-inline-end-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-e-neutral-50\/90 {
  border-inline-end-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-e-neutral-50\/95 {
  border-inline-end-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-e-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-500\/0 {
  border-inline-end-color: rgb(115 115 115 / 0) !important;
}

.tw-border-e-neutral-500\/10 {
  border-inline-end-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-e-neutral-500\/100 {
  border-inline-end-color: rgb(115 115 115 / 1) !important;
}

.tw-border-e-neutral-500\/15 {
  border-inline-end-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-e-neutral-500\/20 {
  border-inline-end-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-e-neutral-500\/25 {
  border-inline-end-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-e-neutral-500\/30 {
  border-inline-end-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-e-neutral-500\/35 {
  border-inline-end-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-e-neutral-500\/40 {
  border-inline-end-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-e-neutral-500\/45 {
  border-inline-end-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-e-neutral-500\/5 {
  border-inline-end-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-e-neutral-500\/50 {
  border-inline-end-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-e-neutral-500\/55 {
  border-inline-end-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-e-neutral-500\/60 {
  border-inline-end-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-e-neutral-500\/65 {
  border-inline-end-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-e-neutral-500\/70 {
  border-inline-end-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-e-neutral-500\/75 {
  border-inline-end-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-e-neutral-500\/80 {
  border-inline-end-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-e-neutral-500\/85 {
  border-inline-end-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-e-neutral-500\/90 {
  border-inline-end-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-e-neutral-500\/95 {
  border-inline-end-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-e-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-600\/0 {
  border-inline-end-color: rgb(82 82 82 / 0) !important;
}

.tw-border-e-neutral-600\/10 {
  border-inline-end-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-e-neutral-600\/100 {
  border-inline-end-color: rgb(82 82 82 / 1) !important;
}

.tw-border-e-neutral-600\/15 {
  border-inline-end-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-e-neutral-600\/20 {
  border-inline-end-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-e-neutral-600\/25 {
  border-inline-end-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-e-neutral-600\/30 {
  border-inline-end-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-e-neutral-600\/35 {
  border-inline-end-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-e-neutral-600\/40 {
  border-inline-end-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-e-neutral-600\/45 {
  border-inline-end-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-e-neutral-600\/5 {
  border-inline-end-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-e-neutral-600\/50 {
  border-inline-end-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-e-neutral-600\/55 {
  border-inline-end-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-e-neutral-600\/60 {
  border-inline-end-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-e-neutral-600\/65 {
  border-inline-end-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-e-neutral-600\/70 {
  border-inline-end-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-e-neutral-600\/75 {
  border-inline-end-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-e-neutral-600\/80 {
  border-inline-end-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-e-neutral-600\/85 {
  border-inline-end-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-e-neutral-600\/90 {
  border-inline-end-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-e-neutral-600\/95 {
  border-inline-end-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-e-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-700\/0 {
  border-inline-end-color: rgb(64 64 64 / 0) !important;
}

.tw-border-e-neutral-700\/10 {
  border-inline-end-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-e-neutral-700\/100 {
  border-inline-end-color: rgb(64 64 64 / 1) !important;
}

.tw-border-e-neutral-700\/15 {
  border-inline-end-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-e-neutral-700\/20 {
  border-inline-end-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-e-neutral-700\/25 {
  border-inline-end-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-e-neutral-700\/30 {
  border-inline-end-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-e-neutral-700\/35 {
  border-inline-end-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-e-neutral-700\/40 {
  border-inline-end-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-e-neutral-700\/45 {
  border-inline-end-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-e-neutral-700\/5 {
  border-inline-end-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-e-neutral-700\/50 {
  border-inline-end-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-e-neutral-700\/55 {
  border-inline-end-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-e-neutral-700\/60 {
  border-inline-end-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-e-neutral-700\/65 {
  border-inline-end-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-e-neutral-700\/70 {
  border-inline-end-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-e-neutral-700\/75 {
  border-inline-end-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-e-neutral-700\/80 {
  border-inline-end-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-e-neutral-700\/85 {
  border-inline-end-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-e-neutral-700\/90 {
  border-inline-end-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-e-neutral-700\/95 {
  border-inline-end-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-e-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-800\/0 {
  border-inline-end-color: rgb(38 38 38 / 0) !important;
}

.tw-border-e-neutral-800\/10 {
  border-inline-end-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-e-neutral-800\/100 {
  border-inline-end-color: rgb(38 38 38 / 1) !important;
}

.tw-border-e-neutral-800\/15 {
  border-inline-end-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-e-neutral-800\/20 {
  border-inline-end-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-e-neutral-800\/25 {
  border-inline-end-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-e-neutral-800\/30 {
  border-inline-end-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-e-neutral-800\/35 {
  border-inline-end-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-e-neutral-800\/40 {
  border-inline-end-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-e-neutral-800\/45 {
  border-inline-end-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-e-neutral-800\/5 {
  border-inline-end-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-e-neutral-800\/50 {
  border-inline-end-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-e-neutral-800\/55 {
  border-inline-end-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-e-neutral-800\/60 {
  border-inline-end-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-e-neutral-800\/65 {
  border-inline-end-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-e-neutral-800\/70 {
  border-inline-end-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-e-neutral-800\/75 {
  border-inline-end-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-e-neutral-800\/80 {
  border-inline-end-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-e-neutral-800\/85 {
  border-inline-end-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-e-neutral-800\/90 {
  border-inline-end-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-e-neutral-800\/95 {
  border-inline-end-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-e-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-900\/0 {
  border-inline-end-color: rgb(23 23 23 / 0) !important;
}

.tw-border-e-neutral-900\/10 {
  border-inline-end-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-e-neutral-900\/100 {
  border-inline-end-color: rgb(23 23 23 / 1) !important;
}

.tw-border-e-neutral-900\/15 {
  border-inline-end-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-e-neutral-900\/20 {
  border-inline-end-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-e-neutral-900\/25 {
  border-inline-end-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-e-neutral-900\/30 {
  border-inline-end-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-e-neutral-900\/35 {
  border-inline-end-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-e-neutral-900\/40 {
  border-inline-end-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-e-neutral-900\/45 {
  border-inline-end-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-e-neutral-900\/5 {
  border-inline-end-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-e-neutral-900\/50 {
  border-inline-end-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-e-neutral-900\/55 {
  border-inline-end-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-e-neutral-900\/60 {
  border-inline-end-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-e-neutral-900\/65 {
  border-inline-end-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-e-neutral-900\/70 {
  border-inline-end-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-e-neutral-900\/75 {
  border-inline-end-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-e-neutral-900\/80 {
  border-inline-end-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-e-neutral-900\/85 {
  border-inline-end-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-e-neutral-900\/90 {
  border-inline-end-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-e-neutral-900\/95 {
  border-inline-end-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-e-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-e-neutral-950\/0 {
  border-inline-end-color: rgb(10 10 10 / 0) !important;
}

.tw-border-e-neutral-950\/10 {
  border-inline-end-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-e-neutral-950\/100 {
  border-inline-end-color: rgb(10 10 10 / 1) !important;
}

.tw-border-e-neutral-950\/15 {
  border-inline-end-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-e-neutral-950\/20 {
  border-inline-end-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-e-neutral-950\/25 {
  border-inline-end-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-e-neutral-950\/30 {
  border-inline-end-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-e-neutral-950\/35 {
  border-inline-end-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-e-neutral-950\/40 {
  border-inline-end-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-e-neutral-950\/45 {
  border-inline-end-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-e-neutral-950\/5 {
  border-inline-end-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-e-neutral-950\/50 {
  border-inline-end-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-e-neutral-950\/55 {
  border-inline-end-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-e-neutral-950\/60 {
  border-inline-end-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-e-neutral-950\/65 {
  border-inline-end-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-e-neutral-950\/70 {
  border-inline-end-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-e-neutral-950\/75 {
  border-inline-end-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-e-neutral-950\/80 {
  border-inline-end-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-e-neutral-950\/85 {
  border-inline-end-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-e-neutral-950\/90 {
  border-inline-end-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-e-neutral-950\/95 {
  border-inline-end-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-e-orange-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-100\/0 {
  border-inline-end-color: rgb(255 237 213 / 0) !important;
}

.tw-border-e-orange-100\/10 {
  border-inline-end-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-e-orange-100\/100 {
  border-inline-end-color: rgb(255 237 213 / 1) !important;
}

.tw-border-e-orange-100\/15 {
  border-inline-end-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-e-orange-100\/20 {
  border-inline-end-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-e-orange-100\/25 {
  border-inline-end-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-e-orange-100\/30 {
  border-inline-end-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-e-orange-100\/35 {
  border-inline-end-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-e-orange-100\/40 {
  border-inline-end-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-e-orange-100\/45 {
  border-inline-end-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-e-orange-100\/5 {
  border-inline-end-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-e-orange-100\/50 {
  border-inline-end-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-e-orange-100\/55 {
  border-inline-end-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-e-orange-100\/60 {
  border-inline-end-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-e-orange-100\/65 {
  border-inline-end-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-e-orange-100\/70 {
  border-inline-end-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-e-orange-100\/75 {
  border-inline-end-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-e-orange-100\/80 {
  border-inline-end-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-e-orange-100\/85 {
  border-inline-end-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-e-orange-100\/90 {
  border-inline-end-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-e-orange-100\/95 {
  border-inline-end-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-e-orange-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-200\/0 {
  border-inline-end-color: rgb(254 215 170 / 0) !important;
}

.tw-border-e-orange-200\/10 {
  border-inline-end-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-e-orange-200\/100 {
  border-inline-end-color: rgb(254 215 170 / 1) !important;
}

.tw-border-e-orange-200\/15 {
  border-inline-end-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-e-orange-200\/20 {
  border-inline-end-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-e-orange-200\/25 {
  border-inline-end-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-e-orange-200\/30 {
  border-inline-end-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-e-orange-200\/35 {
  border-inline-end-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-e-orange-200\/40 {
  border-inline-end-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-e-orange-200\/45 {
  border-inline-end-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-e-orange-200\/5 {
  border-inline-end-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-e-orange-200\/50 {
  border-inline-end-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-e-orange-200\/55 {
  border-inline-end-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-e-orange-200\/60 {
  border-inline-end-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-e-orange-200\/65 {
  border-inline-end-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-e-orange-200\/70 {
  border-inline-end-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-e-orange-200\/75 {
  border-inline-end-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-e-orange-200\/80 {
  border-inline-end-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-e-orange-200\/85 {
  border-inline-end-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-e-orange-200\/90 {
  border-inline-end-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-e-orange-200\/95 {
  border-inline-end-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-e-orange-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-300\/0 {
  border-inline-end-color: rgb(253 186 116 / 0) !important;
}

.tw-border-e-orange-300\/10 {
  border-inline-end-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-e-orange-300\/100 {
  border-inline-end-color: rgb(253 186 116 / 1) !important;
}

.tw-border-e-orange-300\/15 {
  border-inline-end-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-e-orange-300\/20 {
  border-inline-end-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-e-orange-300\/25 {
  border-inline-end-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-e-orange-300\/30 {
  border-inline-end-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-e-orange-300\/35 {
  border-inline-end-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-e-orange-300\/40 {
  border-inline-end-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-e-orange-300\/45 {
  border-inline-end-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-e-orange-300\/5 {
  border-inline-end-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-e-orange-300\/50 {
  border-inline-end-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-e-orange-300\/55 {
  border-inline-end-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-e-orange-300\/60 {
  border-inline-end-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-e-orange-300\/65 {
  border-inline-end-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-e-orange-300\/70 {
  border-inline-end-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-e-orange-300\/75 {
  border-inline-end-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-e-orange-300\/80 {
  border-inline-end-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-e-orange-300\/85 {
  border-inline-end-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-e-orange-300\/90 {
  border-inline-end-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-e-orange-300\/95 {
  border-inline-end-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-e-orange-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-400\/0 {
  border-inline-end-color: rgb(251 146 60 / 0) !important;
}

.tw-border-e-orange-400\/10 {
  border-inline-end-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-e-orange-400\/100 {
  border-inline-end-color: rgb(251 146 60 / 1) !important;
}

.tw-border-e-orange-400\/15 {
  border-inline-end-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-e-orange-400\/20 {
  border-inline-end-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-e-orange-400\/25 {
  border-inline-end-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-e-orange-400\/30 {
  border-inline-end-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-e-orange-400\/35 {
  border-inline-end-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-e-orange-400\/40 {
  border-inline-end-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-e-orange-400\/45 {
  border-inline-end-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-e-orange-400\/5 {
  border-inline-end-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-e-orange-400\/50 {
  border-inline-end-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-e-orange-400\/55 {
  border-inline-end-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-e-orange-400\/60 {
  border-inline-end-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-e-orange-400\/65 {
  border-inline-end-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-e-orange-400\/70 {
  border-inline-end-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-e-orange-400\/75 {
  border-inline-end-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-e-orange-400\/80 {
  border-inline-end-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-e-orange-400\/85 {
  border-inline-end-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-e-orange-400\/90 {
  border-inline-end-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-e-orange-400\/95 {
  border-inline-end-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-e-orange-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-50\/0 {
  border-inline-end-color: rgb(255 247 237 / 0) !important;
}

.tw-border-e-orange-50\/10 {
  border-inline-end-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-e-orange-50\/100 {
  border-inline-end-color: rgb(255 247 237 / 1) !important;
}

.tw-border-e-orange-50\/15 {
  border-inline-end-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-e-orange-50\/20 {
  border-inline-end-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-e-orange-50\/25 {
  border-inline-end-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-e-orange-50\/30 {
  border-inline-end-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-e-orange-50\/35 {
  border-inline-end-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-e-orange-50\/40 {
  border-inline-end-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-e-orange-50\/45 {
  border-inline-end-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-e-orange-50\/5 {
  border-inline-end-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-e-orange-50\/50 {
  border-inline-end-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-e-orange-50\/55 {
  border-inline-end-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-e-orange-50\/60 {
  border-inline-end-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-e-orange-50\/65 {
  border-inline-end-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-e-orange-50\/70 {
  border-inline-end-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-e-orange-50\/75 {
  border-inline-end-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-e-orange-50\/80 {
  border-inline-end-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-e-orange-50\/85 {
  border-inline-end-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-e-orange-50\/90 {
  border-inline-end-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-e-orange-50\/95 {
  border-inline-end-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-e-orange-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-500\/0 {
  border-inline-end-color: rgb(249 115 22 / 0) !important;
}

.tw-border-e-orange-500\/10 {
  border-inline-end-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-e-orange-500\/100 {
  border-inline-end-color: rgb(249 115 22 / 1) !important;
}

.tw-border-e-orange-500\/15 {
  border-inline-end-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-e-orange-500\/20 {
  border-inline-end-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-e-orange-500\/25 {
  border-inline-end-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-e-orange-500\/30 {
  border-inline-end-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-e-orange-500\/35 {
  border-inline-end-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-e-orange-500\/40 {
  border-inline-end-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-e-orange-500\/45 {
  border-inline-end-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-e-orange-500\/5 {
  border-inline-end-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-e-orange-500\/50 {
  border-inline-end-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-e-orange-500\/55 {
  border-inline-end-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-e-orange-500\/60 {
  border-inline-end-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-e-orange-500\/65 {
  border-inline-end-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-e-orange-500\/70 {
  border-inline-end-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-e-orange-500\/75 {
  border-inline-end-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-e-orange-500\/80 {
  border-inline-end-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-e-orange-500\/85 {
  border-inline-end-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-e-orange-500\/90 {
  border-inline-end-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-e-orange-500\/95 {
  border-inline-end-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-e-orange-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-600\/0 {
  border-inline-end-color: rgb(234 88 12 / 0) !important;
}

.tw-border-e-orange-600\/10 {
  border-inline-end-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-e-orange-600\/100 {
  border-inline-end-color: rgb(234 88 12 / 1) !important;
}

.tw-border-e-orange-600\/15 {
  border-inline-end-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-e-orange-600\/20 {
  border-inline-end-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-e-orange-600\/25 {
  border-inline-end-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-e-orange-600\/30 {
  border-inline-end-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-e-orange-600\/35 {
  border-inline-end-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-e-orange-600\/40 {
  border-inline-end-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-e-orange-600\/45 {
  border-inline-end-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-e-orange-600\/5 {
  border-inline-end-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-e-orange-600\/50 {
  border-inline-end-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-e-orange-600\/55 {
  border-inline-end-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-e-orange-600\/60 {
  border-inline-end-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-e-orange-600\/65 {
  border-inline-end-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-e-orange-600\/70 {
  border-inline-end-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-e-orange-600\/75 {
  border-inline-end-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-e-orange-600\/80 {
  border-inline-end-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-e-orange-600\/85 {
  border-inline-end-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-e-orange-600\/90 {
  border-inline-end-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-e-orange-600\/95 {
  border-inline-end-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-e-orange-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-700\/0 {
  border-inline-end-color: rgb(194 65 12 / 0) !important;
}

.tw-border-e-orange-700\/10 {
  border-inline-end-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-e-orange-700\/100 {
  border-inline-end-color: rgb(194 65 12 / 1) !important;
}

.tw-border-e-orange-700\/15 {
  border-inline-end-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-e-orange-700\/20 {
  border-inline-end-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-e-orange-700\/25 {
  border-inline-end-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-e-orange-700\/30 {
  border-inline-end-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-e-orange-700\/35 {
  border-inline-end-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-e-orange-700\/40 {
  border-inline-end-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-e-orange-700\/45 {
  border-inline-end-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-e-orange-700\/5 {
  border-inline-end-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-e-orange-700\/50 {
  border-inline-end-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-e-orange-700\/55 {
  border-inline-end-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-e-orange-700\/60 {
  border-inline-end-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-e-orange-700\/65 {
  border-inline-end-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-e-orange-700\/70 {
  border-inline-end-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-e-orange-700\/75 {
  border-inline-end-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-e-orange-700\/80 {
  border-inline-end-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-e-orange-700\/85 {
  border-inline-end-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-e-orange-700\/90 {
  border-inline-end-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-e-orange-700\/95 {
  border-inline-end-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-e-orange-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-800\/0 {
  border-inline-end-color: rgb(154 52 18 / 0) !important;
}

.tw-border-e-orange-800\/10 {
  border-inline-end-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-e-orange-800\/100 {
  border-inline-end-color: rgb(154 52 18 / 1) !important;
}

.tw-border-e-orange-800\/15 {
  border-inline-end-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-e-orange-800\/20 {
  border-inline-end-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-e-orange-800\/25 {
  border-inline-end-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-e-orange-800\/30 {
  border-inline-end-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-e-orange-800\/35 {
  border-inline-end-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-e-orange-800\/40 {
  border-inline-end-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-e-orange-800\/45 {
  border-inline-end-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-e-orange-800\/5 {
  border-inline-end-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-e-orange-800\/50 {
  border-inline-end-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-e-orange-800\/55 {
  border-inline-end-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-e-orange-800\/60 {
  border-inline-end-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-e-orange-800\/65 {
  border-inline-end-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-e-orange-800\/70 {
  border-inline-end-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-e-orange-800\/75 {
  border-inline-end-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-e-orange-800\/80 {
  border-inline-end-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-e-orange-800\/85 {
  border-inline-end-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-e-orange-800\/90 {
  border-inline-end-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-e-orange-800\/95 {
  border-inline-end-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-e-orange-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-900\/0 {
  border-inline-end-color: rgb(124 45 18 / 0) !important;
}

.tw-border-e-orange-900\/10 {
  border-inline-end-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-e-orange-900\/100 {
  border-inline-end-color: rgb(124 45 18 / 1) !important;
}

.tw-border-e-orange-900\/15 {
  border-inline-end-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-e-orange-900\/20 {
  border-inline-end-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-e-orange-900\/25 {
  border-inline-end-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-e-orange-900\/30 {
  border-inline-end-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-e-orange-900\/35 {
  border-inline-end-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-e-orange-900\/40 {
  border-inline-end-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-e-orange-900\/45 {
  border-inline-end-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-e-orange-900\/5 {
  border-inline-end-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-e-orange-900\/50 {
  border-inline-end-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-e-orange-900\/55 {
  border-inline-end-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-e-orange-900\/60 {
  border-inline-end-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-e-orange-900\/65 {
  border-inline-end-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-e-orange-900\/70 {
  border-inline-end-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-e-orange-900\/75 {
  border-inline-end-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-e-orange-900\/80 {
  border-inline-end-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-e-orange-900\/85 {
  border-inline-end-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-e-orange-900\/90 {
  border-inline-end-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-e-orange-900\/95 {
  border-inline-end-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-e-orange-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-e-orange-950\/0 {
  border-inline-end-color: rgb(67 20 7 / 0) !important;
}

.tw-border-e-orange-950\/10 {
  border-inline-end-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-e-orange-950\/100 {
  border-inline-end-color: rgb(67 20 7 / 1) !important;
}

.tw-border-e-orange-950\/15 {
  border-inline-end-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-e-orange-950\/20 {
  border-inline-end-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-e-orange-950\/25 {
  border-inline-end-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-e-orange-950\/30 {
  border-inline-end-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-e-orange-950\/35 {
  border-inline-end-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-e-orange-950\/40 {
  border-inline-end-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-e-orange-950\/45 {
  border-inline-end-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-e-orange-950\/5 {
  border-inline-end-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-e-orange-950\/50 {
  border-inline-end-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-e-orange-950\/55 {
  border-inline-end-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-e-orange-950\/60 {
  border-inline-end-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-e-orange-950\/65 {
  border-inline-end-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-e-orange-950\/70 {
  border-inline-end-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-e-orange-950\/75 {
  border-inline-end-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-e-orange-950\/80 {
  border-inline-end-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-e-orange-950\/85 {
  border-inline-end-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-e-orange-950\/90 {
  border-inline-end-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-e-orange-950\/95 {
  border-inline-end-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-e-pink-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-100\/0 {
  border-inline-end-color: rgb(252 231 243 / 0) !important;
}

.tw-border-e-pink-100\/10 {
  border-inline-end-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-e-pink-100\/100 {
  border-inline-end-color: rgb(252 231 243 / 1) !important;
}

.tw-border-e-pink-100\/15 {
  border-inline-end-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-e-pink-100\/20 {
  border-inline-end-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-e-pink-100\/25 {
  border-inline-end-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-e-pink-100\/30 {
  border-inline-end-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-e-pink-100\/35 {
  border-inline-end-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-e-pink-100\/40 {
  border-inline-end-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-e-pink-100\/45 {
  border-inline-end-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-e-pink-100\/5 {
  border-inline-end-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-e-pink-100\/50 {
  border-inline-end-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-e-pink-100\/55 {
  border-inline-end-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-e-pink-100\/60 {
  border-inline-end-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-e-pink-100\/65 {
  border-inline-end-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-e-pink-100\/70 {
  border-inline-end-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-e-pink-100\/75 {
  border-inline-end-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-e-pink-100\/80 {
  border-inline-end-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-e-pink-100\/85 {
  border-inline-end-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-e-pink-100\/90 {
  border-inline-end-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-e-pink-100\/95 {
  border-inline-end-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-e-pink-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-200\/0 {
  border-inline-end-color: rgb(251 207 232 / 0) !important;
}

.tw-border-e-pink-200\/10 {
  border-inline-end-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-e-pink-200\/100 {
  border-inline-end-color: rgb(251 207 232 / 1) !important;
}

.tw-border-e-pink-200\/15 {
  border-inline-end-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-e-pink-200\/20 {
  border-inline-end-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-e-pink-200\/25 {
  border-inline-end-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-e-pink-200\/30 {
  border-inline-end-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-e-pink-200\/35 {
  border-inline-end-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-e-pink-200\/40 {
  border-inline-end-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-e-pink-200\/45 {
  border-inline-end-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-e-pink-200\/5 {
  border-inline-end-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-e-pink-200\/50 {
  border-inline-end-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-e-pink-200\/55 {
  border-inline-end-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-e-pink-200\/60 {
  border-inline-end-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-e-pink-200\/65 {
  border-inline-end-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-e-pink-200\/70 {
  border-inline-end-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-e-pink-200\/75 {
  border-inline-end-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-e-pink-200\/80 {
  border-inline-end-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-e-pink-200\/85 {
  border-inline-end-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-e-pink-200\/90 {
  border-inline-end-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-e-pink-200\/95 {
  border-inline-end-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-e-pink-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-300\/0 {
  border-inline-end-color: rgb(249 168 212 / 0) !important;
}

.tw-border-e-pink-300\/10 {
  border-inline-end-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-e-pink-300\/100 {
  border-inline-end-color: rgb(249 168 212 / 1) !important;
}

.tw-border-e-pink-300\/15 {
  border-inline-end-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-e-pink-300\/20 {
  border-inline-end-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-e-pink-300\/25 {
  border-inline-end-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-e-pink-300\/30 {
  border-inline-end-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-e-pink-300\/35 {
  border-inline-end-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-e-pink-300\/40 {
  border-inline-end-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-e-pink-300\/45 {
  border-inline-end-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-e-pink-300\/5 {
  border-inline-end-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-e-pink-300\/50 {
  border-inline-end-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-e-pink-300\/55 {
  border-inline-end-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-e-pink-300\/60 {
  border-inline-end-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-e-pink-300\/65 {
  border-inline-end-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-e-pink-300\/70 {
  border-inline-end-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-e-pink-300\/75 {
  border-inline-end-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-e-pink-300\/80 {
  border-inline-end-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-e-pink-300\/85 {
  border-inline-end-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-e-pink-300\/90 {
  border-inline-end-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-e-pink-300\/95 {
  border-inline-end-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-e-pink-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-400\/0 {
  border-inline-end-color: rgb(244 114 182 / 0) !important;
}

.tw-border-e-pink-400\/10 {
  border-inline-end-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-e-pink-400\/100 {
  border-inline-end-color: rgb(244 114 182 / 1) !important;
}

.tw-border-e-pink-400\/15 {
  border-inline-end-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-e-pink-400\/20 {
  border-inline-end-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-e-pink-400\/25 {
  border-inline-end-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-e-pink-400\/30 {
  border-inline-end-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-e-pink-400\/35 {
  border-inline-end-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-e-pink-400\/40 {
  border-inline-end-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-e-pink-400\/45 {
  border-inline-end-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-e-pink-400\/5 {
  border-inline-end-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-e-pink-400\/50 {
  border-inline-end-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-e-pink-400\/55 {
  border-inline-end-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-e-pink-400\/60 {
  border-inline-end-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-e-pink-400\/65 {
  border-inline-end-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-e-pink-400\/70 {
  border-inline-end-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-e-pink-400\/75 {
  border-inline-end-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-e-pink-400\/80 {
  border-inline-end-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-e-pink-400\/85 {
  border-inline-end-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-e-pink-400\/90 {
  border-inline-end-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-e-pink-400\/95 {
  border-inline-end-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-e-pink-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-50\/0 {
  border-inline-end-color: rgb(253 242 248 / 0) !important;
}

.tw-border-e-pink-50\/10 {
  border-inline-end-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-e-pink-50\/100 {
  border-inline-end-color: rgb(253 242 248 / 1) !important;
}

.tw-border-e-pink-50\/15 {
  border-inline-end-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-e-pink-50\/20 {
  border-inline-end-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-e-pink-50\/25 {
  border-inline-end-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-e-pink-50\/30 {
  border-inline-end-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-e-pink-50\/35 {
  border-inline-end-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-e-pink-50\/40 {
  border-inline-end-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-e-pink-50\/45 {
  border-inline-end-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-e-pink-50\/5 {
  border-inline-end-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-e-pink-50\/50 {
  border-inline-end-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-e-pink-50\/55 {
  border-inline-end-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-e-pink-50\/60 {
  border-inline-end-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-e-pink-50\/65 {
  border-inline-end-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-e-pink-50\/70 {
  border-inline-end-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-e-pink-50\/75 {
  border-inline-end-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-e-pink-50\/80 {
  border-inline-end-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-e-pink-50\/85 {
  border-inline-end-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-e-pink-50\/90 {
  border-inline-end-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-e-pink-50\/95 {
  border-inline-end-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-e-pink-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-500\/0 {
  border-inline-end-color: rgb(236 72 153 / 0) !important;
}

.tw-border-e-pink-500\/10 {
  border-inline-end-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-e-pink-500\/100 {
  border-inline-end-color: rgb(236 72 153 / 1) !important;
}

.tw-border-e-pink-500\/15 {
  border-inline-end-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-e-pink-500\/20 {
  border-inline-end-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-e-pink-500\/25 {
  border-inline-end-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-e-pink-500\/30 {
  border-inline-end-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-e-pink-500\/35 {
  border-inline-end-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-e-pink-500\/40 {
  border-inline-end-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-e-pink-500\/45 {
  border-inline-end-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-e-pink-500\/5 {
  border-inline-end-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-e-pink-500\/50 {
  border-inline-end-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-e-pink-500\/55 {
  border-inline-end-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-e-pink-500\/60 {
  border-inline-end-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-e-pink-500\/65 {
  border-inline-end-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-e-pink-500\/70 {
  border-inline-end-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-e-pink-500\/75 {
  border-inline-end-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-e-pink-500\/80 {
  border-inline-end-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-e-pink-500\/85 {
  border-inline-end-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-e-pink-500\/90 {
  border-inline-end-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-e-pink-500\/95 {
  border-inline-end-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-e-pink-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-600\/0 {
  border-inline-end-color: rgb(219 39 119 / 0) !important;
}

.tw-border-e-pink-600\/10 {
  border-inline-end-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-e-pink-600\/100 {
  border-inline-end-color: rgb(219 39 119 / 1) !important;
}

.tw-border-e-pink-600\/15 {
  border-inline-end-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-e-pink-600\/20 {
  border-inline-end-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-e-pink-600\/25 {
  border-inline-end-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-e-pink-600\/30 {
  border-inline-end-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-e-pink-600\/35 {
  border-inline-end-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-e-pink-600\/40 {
  border-inline-end-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-e-pink-600\/45 {
  border-inline-end-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-e-pink-600\/5 {
  border-inline-end-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-e-pink-600\/50 {
  border-inline-end-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-e-pink-600\/55 {
  border-inline-end-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-e-pink-600\/60 {
  border-inline-end-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-e-pink-600\/65 {
  border-inline-end-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-e-pink-600\/70 {
  border-inline-end-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-e-pink-600\/75 {
  border-inline-end-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-e-pink-600\/80 {
  border-inline-end-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-e-pink-600\/85 {
  border-inline-end-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-e-pink-600\/90 {
  border-inline-end-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-e-pink-600\/95 {
  border-inline-end-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-e-pink-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-700\/0 {
  border-inline-end-color: rgb(190 24 93 / 0) !important;
}

.tw-border-e-pink-700\/10 {
  border-inline-end-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-e-pink-700\/100 {
  border-inline-end-color: rgb(190 24 93 / 1) !important;
}

.tw-border-e-pink-700\/15 {
  border-inline-end-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-e-pink-700\/20 {
  border-inline-end-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-e-pink-700\/25 {
  border-inline-end-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-e-pink-700\/30 {
  border-inline-end-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-e-pink-700\/35 {
  border-inline-end-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-e-pink-700\/40 {
  border-inline-end-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-e-pink-700\/45 {
  border-inline-end-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-e-pink-700\/5 {
  border-inline-end-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-e-pink-700\/50 {
  border-inline-end-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-e-pink-700\/55 {
  border-inline-end-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-e-pink-700\/60 {
  border-inline-end-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-e-pink-700\/65 {
  border-inline-end-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-e-pink-700\/70 {
  border-inline-end-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-e-pink-700\/75 {
  border-inline-end-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-e-pink-700\/80 {
  border-inline-end-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-e-pink-700\/85 {
  border-inline-end-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-e-pink-700\/90 {
  border-inline-end-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-e-pink-700\/95 {
  border-inline-end-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-e-pink-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-800\/0 {
  border-inline-end-color: rgb(157 23 77 / 0) !important;
}

.tw-border-e-pink-800\/10 {
  border-inline-end-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-e-pink-800\/100 {
  border-inline-end-color: rgb(157 23 77 / 1) !important;
}

.tw-border-e-pink-800\/15 {
  border-inline-end-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-e-pink-800\/20 {
  border-inline-end-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-e-pink-800\/25 {
  border-inline-end-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-e-pink-800\/30 {
  border-inline-end-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-e-pink-800\/35 {
  border-inline-end-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-e-pink-800\/40 {
  border-inline-end-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-e-pink-800\/45 {
  border-inline-end-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-e-pink-800\/5 {
  border-inline-end-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-e-pink-800\/50 {
  border-inline-end-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-e-pink-800\/55 {
  border-inline-end-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-e-pink-800\/60 {
  border-inline-end-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-e-pink-800\/65 {
  border-inline-end-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-e-pink-800\/70 {
  border-inline-end-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-e-pink-800\/75 {
  border-inline-end-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-e-pink-800\/80 {
  border-inline-end-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-e-pink-800\/85 {
  border-inline-end-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-e-pink-800\/90 {
  border-inline-end-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-e-pink-800\/95 {
  border-inline-end-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-e-pink-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-900\/0 {
  border-inline-end-color: rgb(131 24 67 / 0) !important;
}

.tw-border-e-pink-900\/10 {
  border-inline-end-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-e-pink-900\/100 {
  border-inline-end-color: rgb(131 24 67 / 1) !important;
}

.tw-border-e-pink-900\/15 {
  border-inline-end-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-e-pink-900\/20 {
  border-inline-end-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-e-pink-900\/25 {
  border-inline-end-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-e-pink-900\/30 {
  border-inline-end-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-e-pink-900\/35 {
  border-inline-end-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-e-pink-900\/40 {
  border-inline-end-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-e-pink-900\/45 {
  border-inline-end-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-e-pink-900\/5 {
  border-inline-end-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-e-pink-900\/50 {
  border-inline-end-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-e-pink-900\/55 {
  border-inline-end-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-e-pink-900\/60 {
  border-inline-end-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-e-pink-900\/65 {
  border-inline-end-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-e-pink-900\/70 {
  border-inline-end-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-e-pink-900\/75 {
  border-inline-end-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-e-pink-900\/80 {
  border-inline-end-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-e-pink-900\/85 {
  border-inline-end-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-e-pink-900\/90 {
  border-inline-end-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-e-pink-900\/95 {
  border-inline-end-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-e-pink-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-e-pink-950\/0 {
  border-inline-end-color: rgb(80 7 36 / 0) !important;
}

.tw-border-e-pink-950\/10 {
  border-inline-end-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-e-pink-950\/100 {
  border-inline-end-color: rgb(80 7 36 / 1) !important;
}

.tw-border-e-pink-950\/15 {
  border-inline-end-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-e-pink-950\/20 {
  border-inline-end-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-e-pink-950\/25 {
  border-inline-end-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-e-pink-950\/30 {
  border-inline-end-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-e-pink-950\/35 {
  border-inline-end-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-e-pink-950\/40 {
  border-inline-end-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-e-pink-950\/45 {
  border-inline-end-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-e-pink-950\/5 {
  border-inline-end-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-e-pink-950\/50 {
  border-inline-end-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-e-pink-950\/55 {
  border-inline-end-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-e-pink-950\/60 {
  border-inline-end-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-e-pink-950\/65 {
  border-inline-end-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-e-pink-950\/70 {
  border-inline-end-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-e-pink-950\/75 {
  border-inline-end-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-e-pink-950\/80 {
  border-inline-end-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-e-pink-950\/85 {
  border-inline-end-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-e-pink-950\/90 {
  border-inline-end-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-e-pink-950\/95 {
  border-inline-end-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-e-purple-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-100\/0 {
  border-inline-end-color: rgb(243 232 255 / 0) !important;
}

.tw-border-e-purple-100\/10 {
  border-inline-end-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-e-purple-100\/100 {
  border-inline-end-color: rgb(243 232 255 / 1) !important;
}

.tw-border-e-purple-100\/15 {
  border-inline-end-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-e-purple-100\/20 {
  border-inline-end-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-e-purple-100\/25 {
  border-inline-end-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-e-purple-100\/30 {
  border-inline-end-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-e-purple-100\/35 {
  border-inline-end-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-e-purple-100\/40 {
  border-inline-end-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-e-purple-100\/45 {
  border-inline-end-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-e-purple-100\/5 {
  border-inline-end-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-e-purple-100\/50 {
  border-inline-end-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-e-purple-100\/55 {
  border-inline-end-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-e-purple-100\/60 {
  border-inline-end-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-e-purple-100\/65 {
  border-inline-end-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-e-purple-100\/70 {
  border-inline-end-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-e-purple-100\/75 {
  border-inline-end-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-e-purple-100\/80 {
  border-inline-end-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-e-purple-100\/85 {
  border-inline-end-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-e-purple-100\/90 {
  border-inline-end-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-e-purple-100\/95 {
  border-inline-end-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-e-purple-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-200\/0 {
  border-inline-end-color: rgb(233 213 255 / 0) !important;
}

.tw-border-e-purple-200\/10 {
  border-inline-end-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-e-purple-200\/100 {
  border-inline-end-color: rgb(233 213 255 / 1) !important;
}

.tw-border-e-purple-200\/15 {
  border-inline-end-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-e-purple-200\/20 {
  border-inline-end-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-e-purple-200\/25 {
  border-inline-end-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-e-purple-200\/30 {
  border-inline-end-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-e-purple-200\/35 {
  border-inline-end-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-e-purple-200\/40 {
  border-inline-end-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-e-purple-200\/45 {
  border-inline-end-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-e-purple-200\/5 {
  border-inline-end-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-e-purple-200\/50 {
  border-inline-end-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-e-purple-200\/55 {
  border-inline-end-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-e-purple-200\/60 {
  border-inline-end-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-e-purple-200\/65 {
  border-inline-end-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-e-purple-200\/70 {
  border-inline-end-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-e-purple-200\/75 {
  border-inline-end-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-e-purple-200\/80 {
  border-inline-end-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-e-purple-200\/85 {
  border-inline-end-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-e-purple-200\/90 {
  border-inline-end-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-e-purple-200\/95 {
  border-inline-end-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-e-purple-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-300\/0 {
  border-inline-end-color: rgb(216 180 254 / 0) !important;
}

.tw-border-e-purple-300\/10 {
  border-inline-end-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-e-purple-300\/100 {
  border-inline-end-color: rgb(216 180 254 / 1) !important;
}

.tw-border-e-purple-300\/15 {
  border-inline-end-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-e-purple-300\/20 {
  border-inline-end-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-e-purple-300\/25 {
  border-inline-end-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-e-purple-300\/30 {
  border-inline-end-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-e-purple-300\/35 {
  border-inline-end-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-e-purple-300\/40 {
  border-inline-end-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-e-purple-300\/45 {
  border-inline-end-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-e-purple-300\/5 {
  border-inline-end-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-e-purple-300\/50 {
  border-inline-end-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-e-purple-300\/55 {
  border-inline-end-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-e-purple-300\/60 {
  border-inline-end-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-e-purple-300\/65 {
  border-inline-end-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-e-purple-300\/70 {
  border-inline-end-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-e-purple-300\/75 {
  border-inline-end-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-e-purple-300\/80 {
  border-inline-end-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-e-purple-300\/85 {
  border-inline-end-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-e-purple-300\/90 {
  border-inline-end-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-e-purple-300\/95 {
  border-inline-end-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-e-purple-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-400\/0 {
  border-inline-end-color: rgb(192 132 252 / 0) !important;
}

.tw-border-e-purple-400\/10 {
  border-inline-end-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-e-purple-400\/100 {
  border-inline-end-color: rgb(192 132 252 / 1) !important;
}

.tw-border-e-purple-400\/15 {
  border-inline-end-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-e-purple-400\/20 {
  border-inline-end-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-e-purple-400\/25 {
  border-inline-end-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-e-purple-400\/30 {
  border-inline-end-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-e-purple-400\/35 {
  border-inline-end-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-e-purple-400\/40 {
  border-inline-end-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-e-purple-400\/45 {
  border-inline-end-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-e-purple-400\/5 {
  border-inline-end-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-e-purple-400\/50 {
  border-inline-end-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-e-purple-400\/55 {
  border-inline-end-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-e-purple-400\/60 {
  border-inline-end-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-e-purple-400\/65 {
  border-inline-end-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-e-purple-400\/70 {
  border-inline-end-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-e-purple-400\/75 {
  border-inline-end-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-e-purple-400\/80 {
  border-inline-end-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-e-purple-400\/85 {
  border-inline-end-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-e-purple-400\/90 {
  border-inline-end-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-e-purple-400\/95 {
  border-inline-end-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-e-purple-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-50\/0 {
  border-inline-end-color: rgb(250 245 255 / 0) !important;
}

.tw-border-e-purple-50\/10 {
  border-inline-end-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-e-purple-50\/100 {
  border-inline-end-color: rgb(250 245 255 / 1) !important;
}

.tw-border-e-purple-50\/15 {
  border-inline-end-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-e-purple-50\/20 {
  border-inline-end-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-e-purple-50\/25 {
  border-inline-end-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-e-purple-50\/30 {
  border-inline-end-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-e-purple-50\/35 {
  border-inline-end-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-e-purple-50\/40 {
  border-inline-end-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-e-purple-50\/45 {
  border-inline-end-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-e-purple-50\/5 {
  border-inline-end-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-e-purple-50\/50 {
  border-inline-end-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-e-purple-50\/55 {
  border-inline-end-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-e-purple-50\/60 {
  border-inline-end-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-e-purple-50\/65 {
  border-inline-end-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-e-purple-50\/70 {
  border-inline-end-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-e-purple-50\/75 {
  border-inline-end-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-e-purple-50\/80 {
  border-inline-end-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-e-purple-50\/85 {
  border-inline-end-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-e-purple-50\/90 {
  border-inline-end-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-e-purple-50\/95 {
  border-inline-end-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-e-purple-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-500\/0 {
  border-inline-end-color: rgb(168 85 247 / 0) !important;
}

.tw-border-e-purple-500\/10 {
  border-inline-end-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-e-purple-500\/100 {
  border-inline-end-color: rgb(168 85 247 / 1) !important;
}

.tw-border-e-purple-500\/15 {
  border-inline-end-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-e-purple-500\/20 {
  border-inline-end-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-e-purple-500\/25 {
  border-inline-end-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-e-purple-500\/30 {
  border-inline-end-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-e-purple-500\/35 {
  border-inline-end-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-e-purple-500\/40 {
  border-inline-end-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-e-purple-500\/45 {
  border-inline-end-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-e-purple-500\/5 {
  border-inline-end-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-e-purple-500\/50 {
  border-inline-end-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-e-purple-500\/55 {
  border-inline-end-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-e-purple-500\/60 {
  border-inline-end-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-e-purple-500\/65 {
  border-inline-end-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-e-purple-500\/70 {
  border-inline-end-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-e-purple-500\/75 {
  border-inline-end-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-e-purple-500\/80 {
  border-inline-end-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-e-purple-500\/85 {
  border-inline-end-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-e-purple-500\/90 {
  border-inline-end-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-e-purple-500\/95 {
  border-inline-end-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-e-purple-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-600\/0 {
  border-inline-end-color: rgb(147 51 234 / 0) !important;
}

.tw-border-e-purple-600\/10 {
  border-inline-end-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-e-purple-600\/100 {
  border-inline-end-color: rgb(147 51 234 / 1) !important;
}

.tw-border-e-purple-600\/15 {
  border-inline-end-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-e-purple-600\/20 {
  border-inline-end-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-e-purple-600\/25 {
  border-inline-end-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-e-purple-600\/30 {
  border-inline-end-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-e-purple-600\/35 {
  border-inline-end-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-e-purple-600\/40 {
  border-inline-end-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-e-purple-600\/45 {
  border-inline-end-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-e-purple-600\/5 {
  border-inline-end-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-e-purple-600\/50 {
  border-inline-end-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-e-purple-600\/55 {
  border-inline-end-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-e-purple-600\/60 {
  border-inline-end-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-e-purple-600\/65 {
  border-inline-end-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-e-purple-600\/70 {
  border-inline-end-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-e-purple-600\/75 {
  border-inline-end-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-e-purple-600\/80 {
  border-inline-end-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-e-purple-600\/85 {
  border-inline-end-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-e-purple-600\/90 {
  border-inline-end-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-e-purple-600\/95 {
  border-inline-end-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-e-purple-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-700\/0 {
  border-inline-end-color: rgb(126 34 206 / 0) !important;
}

.tw-border-e-purple-700\/10 {
  border-inline-end-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-e-purple-700\/100 {
  border-inline-end-color: rgb(126 34 206 / 1) !important;
}

.tw-border-e-purple-700\/15 {
  border-inline-end-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-e-purple-700\/20 {
  border-inline-end-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-e-purple-700\/25 {
  border-inline-end-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-e-purple-700\/30 {
  border-inline-end-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-e-purple-700\/35 {
  border-inline-end-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-e-purple-700\/40 {
  border-inline-end-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-e-purple-700\/45 {
  border-inline-end-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-e-purple-700\/5 {
  border-inline-end-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-e-purple-700\/50 {
  border-inline-end-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-e-purple-700\/55 {
  border-inline-end-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-e-purple-700\/60 {
  border-inline-end-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-e-purple-700\/65 {
  border-inline-end-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-e-purple-700\/70 {
  border-inline-end-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-e-purple-700\/75 {
  border-inline-end-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-e-purple-700\/80 {
  border-inline-end-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-e-purple-700\/85 {
  border-inline-end-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-e-purple-700\/90 {
  border-inline-end-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-e-purple-700\/95 {
  border-inline-end-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-e-purple-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-800\/0 {
  border-inline-end-color: rgb(107 33 168 / 0) !important;
}

.tw-border-e-purple-800\/10 {
  border-inline-end-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-e-purple-800\/100 {
  border-inline-end-color: rgb(107 33 168 / 1) !important;
}

.tw-border-e-purple-800\/15 {
  border-inline-end-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-e-purple-800\/20 {
  border-inline-end-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-e-purple-800\/25 {
  border-inline-end-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-e-purple-800\/30 {
  border-inline-end-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-e-purple-800\/35 {
  border-inline-end-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-e-purple-800\/40 {
  border-inline-end-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-e-purple-800\/45 {
  border-inline-end-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-e-purple-800\/5 {
  border-inline-end-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-e-purple-800\/50 {
  border-inline-end-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-e-purple-800\/55 {
  border-inline-end-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-e-purple-800\/60 {
  border-inline-end-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-e-purple-800\/65 {
  border-inline-end-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-e-purple-800\/70 {
  border-inline-end-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-e-purple-800\/75 {
  border-inline-end-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-e-purple-800\/80 {
  border-inline-end-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-e-purple-800\/85 {
  border-inline-end-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-e-purple-800\/90 {
  border-inline-end-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-e-purple-800\/95 {
  border-inline-end-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-e-purple-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-900\/0 {
  border-inline-end-color: rgb(88 28 135 / 0) !important;
}

.tw-border-e-purple-900\/10 {
  border-inline-end-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-e-purple-900\/100 {
  border-inline-end-color: rgb(88 28 135 / 1) !important;
}

.tw-border-e-purple-900\/15 {
  border-inline-end-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-e-purple-900\/20 {
  border-inline-end-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-e-purple-900\/25 {
  border-inline-end-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-e-purple-900\/30 {
  border-inline-end-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-e-purple-900\/35 {
  border-inline-end-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-e-purple-900\/40 {
  border-inline-end-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-e-purple-900\/45 {
  border-inline-end-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-e-purple-900\/5 {
  border-inline-end-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-e-purple-900\/50 {
  border-inline-end-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-e-purple-900\/55 {
  border-inline-end-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-e-purple-900\/60 {
  border-inline-end-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-e-purple-900\/65 {
  border-inline-end-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-e-purple-900\/70 {
  border-inline-end-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-e-purple-900\/75 {
  border-inline-end-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-e-purple-900\/80 {
  border-inline-end-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-e-purple-900\/85 {
  border-inline-end-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-e-purple-900\/90 {
  border-inline-end-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-e-purple-900\/95 {
  border-inline-end-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-e-purple-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-e-purple-950\/0 {
  border-inline-end-color: rgb(59 7 100 / 0) !important;
}

.tw-border-e-purple-950\/10 {
  border-inline-end-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-e-purple-950\/100 {
  border-inline-end-color: rgb(59 7 100 / 1) !important;
}

.tw-border-e-purple-950\/15 {
  border-inline-end-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-e-purple-950\/20 {
  border-inline-end-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-e-purple-950\/25 {
  border-inline-end-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-e-purple-950\/30 {
  border-inline-end-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-e-purple-950\/35 {
  border-inline-end-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-e-purple-950\/40 {
  border-inline-end-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-e-purple-950\/45 {
  border-inline-end-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-e-purple-950\/5 {
  border-inline-end-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-e-purple-950\/50 {
  border-inline-end-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-e-purple-950\/55 {
  border-inline-end-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-e-purple-950\/60 {
  border-inline-end-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-e-purple-950\/65 {
  border-inline-end-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-e-purple-950\/70 {
  border-inline-end-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-e-purple-950\/75 {
  border-inline-end-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-e-purple-950\/80 {
  border-inline-end-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-e-purple-950\/85 {
  border-inline-end-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-e-purple-950\/90 {
  border-inline-end-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-e-purple-950\/95 {
  border-inline-end-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-e-red-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-100\/0 {
  border-inline-end-color: rgb(254 226 226 / 0) !important;
}

.tw-border-e-red-100\/10 {
  border-inline-end-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-e-red-100\/100 {
  border-inline-end-color: rgb(254 226 226 / 1) !important;
}

.tw-border-e-red-100\/15 {
  border-inline-end-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-e-red-100\/20 {
  border-inline-end-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-e-red-100\/25 {
  border-inline-end-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-e-red-100\/30 {
  border-inline-end-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-e-red-100\/35 {
  border-inline-end-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-e-red-100\/40 {
  border-inline-end-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-e-red-100\/45 {
  border-inline-end-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-e-red-100\/5 {
  border-inline-end-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-e-red-100\/50 {
  border-inline-end-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-e-red-100\/55 {
  border-inline-end-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-e-red-100\/60 {
  border-inline-end-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-e-red-100\/65 {
  border-inline-end-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-e-red-100\/70 {
  border-inline-end-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-e-red-100\/75 {
  border-inline-end-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-e-red-100\/80 {
  border-inline-end-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-e-red-100\/85 {
  border-inline-end-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-e-red-100\/90 {
  border-inline-end-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-e-red-100\/95 {
  border-inline-end-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-e-red-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-200\/0 {
  border-inline-end-color: rgb(254 202 202 / 0) !important;
}

.tw-border-e-red-200\/10 {
  border-inline-end-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-e-red-200\/100 {
  border-inline-end-color: rgb(254 202 202 / 1) !important;
}

.tw-border-e-red-200\/15 {
  border-inline-end-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-e-red-200\/20 {
  border-inline-end-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-e-red-200\/25 {
  border-inline-end-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-e-red-200\/30 {
  border-inline-end-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-e-red-200\/35 {
  border-inline-end-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-e-red-200\/40 {
  border-inline-end-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-e-red-200\/45 {
  border-inline-end-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-e-red-200\/5 {
  border-inline-end-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-e-red-200\/50 {
  border-inline-end-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-e-red-200\/55 {
  border-inline-end-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-e-red-200\/60 {
  border-inline-end-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-e-red-200\/65 {
  border-inline-end-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-e-red-200\/70 {
  border-inline-end-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-e-red-200\/75 {
  border-inline-end-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-e-red-200\/80 {
  border-inline-end-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-e-red-200\/85 {
  border-inline-end-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-e-red-200\/90 {
  border-inline-end-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-e-red-200\/95 {
  border-inline-end-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-e-red-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-300\/0 {
  border-inline-end-color: rgb(252 165 165 / 0) !important;
}

.tw-border-e-red-300\/10 {
  border-inline-end-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-e-red-300\/100 {
  border-inline-end-color: rgb(252 165 165 / 1) !important;
}

.tw-border-e-red-300\/15 {
  border-inline-end-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-e-red-300\/20 {
  border-inline-end-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-e-red-300\/25 {
  border-inline-end-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-e-red-300\/30 {
  border-inline-end-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-e-red-300\/35 {
  border-inline-end-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-e-red-300\/40 {
  border-inline-end-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-e-red-300\/45 {
  border-inline-end-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-e-red-300\/5 {
  border-inline-end-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-e-red-300\/50 {
  border-inline-end-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-e-red-300\/55 {
  border-inline-end-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-e-red-300\/60 {
  border-inline-end-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-e-red-300\/65 {
  border-inline-end-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-e-red-300\/70 {
  border-inline-end-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-e-red-300\/75 {
  border-inline-end-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-e-red-300\/80 {
  border-inline-end-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-e-red-300\/85 {
  border-inline-end-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-e-red-300\/90 {
  border-inline-end-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-e-red-300\/95 {
  border-inline-end-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-e-red-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-400\/0 {
  border-inline-end-color: rgb(248 113 113 / 0) !important;
}

.tw-border-e-red-400\/10 {
  border-inline-end-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-e-red-400\/100 {
  border-inline-end-color: rgb(248 113 113 / 1) !important;
}

.tw-border-e-red-400\/15 {
  border-inline-end-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-e-red-400\/20 {
  border-inline-end-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-e-red-400\/25 {
  border-inline-end-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-e-red-400\/30 {
  border-inline-end-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-e-red-400\/35 {
  border-inline-end-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-e-red-400\/40 {
  border-inline-end-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-e-red-400\/45 {
  border-inline-end-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-e-red-400\/5 {
  border-inline-end-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-e-red-400\/50 {
  border-inline-end-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-e-red-400\/55 {
  border-inline-end-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-e-red-400\/60 {
  border-inline-end-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-e-red-400\/65 {
  border-inline-end-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-e-red-400\/70 {
  border-inline-end-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-e-red-400\/75 {
  border-inline-end-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-e-red-400\/80 {
  border-inline-end-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-e-red-400\/85 {
  border-inline-end-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-e-red-400\/90 {
  border-inline-end-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-e-red-400\/95 {
  border-inline-end-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-e-red-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-50\/0 {
  border-inline-end-color: rgb(254 242 242 / 0) !important;
}

.tw-border-e-red-50\/10 {
  border-inline-end-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-e-red-50\/100 {
  border-inline-end-color: rgb(254 242 242 / 1) !important;
}

.tw-border-e-red-50\/15 {
  border-inline-end-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-e-red-50\/20 {
  border-inline-end-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-e-red-50\/25 {
  border-inline-end-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-e-red-50\/30 {
  border-inline-end-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-e-red-50\/35 {
  border-inline-end-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-e-red-50\/40 {
  border-inline-end-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-e-red-50\/45 {
  border-inline-end-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-e-red-50\/5 {
  border-inline-end-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-e-red-50\/50 {
  border-inline-end-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-e-red-50\/55 {
  border-inline-end-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-e-red-50\/60 {
  border-inline-end-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-e-red-50\/65 {
  border-inline-end-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-e-red-50\/70 {
  border-inline-end-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-e-red-50\/75 {
  border-inline-end-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-e-red-50\/80 {
  border-inline-end-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-e-red-50\/85 {
  border-inline-end-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-e-red-50\/90 {
  border-inline-end-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-e-red-50\/95 {
  border-inline-end-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-e-red-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-500\/0 {
  border-inline-end-color: rgb(239 68 68 / 0) !important;
}

.tw-border-e-red-500\/10 {
  border-inline-end-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-e-red-500\/100 {
  border-inline-end-color: rgb(239 68 68 / 1) !important;
}

.tw-border-e-red-500\/15 {
  border-inline-end-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-e-red-500\/20 {
  border-inline-end-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-e-red-500\/25 {
  border-inline-end-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-e-red-500\/30 {
  border-inline-end-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-e-red-500\/35 {
  border-inline-end-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-e-red-500\/40 {
  border-inline-end-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-e-red-500\/45 {
  border-inline-end-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-e-red-500\/5 {
  border-inline-end-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-e-red-500\/50 {
  border-inline-end-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-e-red-500\/55 {
  border-inline-end-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-e-red-500\/60 {
  border-inline-end-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-e-red-500\/65 {
  border-inline-end-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-e-red-500\/70 {
  border-inline-end-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-e-red-500\/75 {
  border-inline-end-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-e-red-500\/80 {
  border-inline-end-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-e-red-500\/85 {
  border-inline-end-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-e-red-500\/90 {
  border-inline-end-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-e-red-500\/95 {
  border-inline-end-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-e-red-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-600\/0 {
  border-inline-end-color: rgb(220 38 38 / 0) !important;
}

.tw-border-e-red-600\/10 {
  border-inline-end-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-e-red-600\/100 {
  border-inline-end-color: rgb(220 38 38 / 1) !important;
}

.tw-border-e-red-600\/15 {
  border-inline-end-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-e-red-600\/20 {
  border-inline-end-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-e-red-600\/25 {
  border-inline-end-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-e-red-600\/30 {
  border-inline-end-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-e-red-600\/35 {
  border-inline-end-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-e-red-600\/40 {
  border-inline-end-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-e-red-600\/45 {
  border-inline-end-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-e-red-600\/5 {
  border-inline-end-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-e-red-600\/50 {
  border-inline-end-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-e-red-600\/55 {
  border-inline-end-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-e-red-600\/60 {
  border-inline-end-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-e-red-600\/65 {
  border-inline-end-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-e-red-600\/70 {
  border-inline-end-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-e-red-600\/75 {
  border-inline-end-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-e-red-600\/80 {
  border-inline-end-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-e-red-600\/85 {
  border-inline-end-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-e-red-600\/90 {
  border-inline-end-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-e-red-600\/95 {
  border-inline-end-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-e-red-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-700\/0 {
  border-inline-end-color: rgb(185 28 28 / 0) !important;
}

.tw-border-e-red-700\/10 {
  border-inline-end-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-e-red-700\/100 {
  border-inline-end-color: rgb(185 28 28 / 1) !important;
}

.tw-border-e-red-700\/15 {
  border-inline-end-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-e-red-700\/20 {
  border-inline-end-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-e-red-700\/25 {
  border-inline-end-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-e-red-700\/30 {
  border-inline-end-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-e-red-700\/35 {
  border-inline-end-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-e-red-700\/40 {
  border-inline-end-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-e-red-700\/45 {
  border-inline-end-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-e-red-700\/5 {
  border-inline-end-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-e-red-700\/50 {
  border-inline-end-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-e-red-700\/55 {
  border-inline-end-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-e-red-700\/60 {
  border-inline-end-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-e-red-700\/65 {
  border-inline-end-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-e-red-700\/70 {
  border-inline-end-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-e-red-700\/75 {
  border-inline-end-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-e-red-700\/80 {
  border-inline-end-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-e-red-700\/85 {
  border-inline-end-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-e-red-700\/90 {
  border-inline-end-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-e-red-700\/95 {
  border-inline-end-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-e-red-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-800\/0 {
  border-inline-end-color: rgb(153 27 27 / 0) !important;
}

.tw-border-e-red-800\/10 {
  border-inline-end-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-e-red-800\/100 {
  border-inline-end-color: rgb(153 27 27 / 1) !important;
}

.tw-border-e-red-800\/15 {
  border-inline-end-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-e-red-800\/20 {
  border-inline-end-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-e-red-800\/25 {
  border-inline-end-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-e-red-800\/30 {
  border-inline-end-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-e-red-800\/35 {
  border-inline-end-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-e-red-800\/40 {
  border-inline-end-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-e-red-800\/45 {
  border-inline-end-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-e-red-800\/5 {
  border-inline-end-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-e-red-800\/50 {
  border-inline-end-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-e-red-800\/55 {
  border-inline-end-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-e-red-800\/60 {
  border-inline-end-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-e-red-800\/65 {
  border-inline-end-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-e-red-800\/70 {
  border-inline-end-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-e-red-800\/75 {
  border-inline-end-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-e-red-800\/80 {
  border-inline-end-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-e-red-800\/85 {
  border-inline-end-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-e-red-800\/90 {
  border-inline-end-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-e-red-800\/95 {
  border-inline-end-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-e-red-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-900\/0 {
  border-inline-end-color: rgb(127 29 29 / 0) !important;
}

.tw-border-e-red-900\/10 {
  border-inline-end-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-e-red-900\/100 {
  border-inline-end-color: rgb(127 29 29 / 1) !important;
}

.tw-border-e-red-900\/15 {
  border-inline-end-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-e-red-900\/20 {
  border-inline-end-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-e-red-900\/25 {
  border-inline-end-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-e-red-900\/30 {
  border-inline-end-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-e-red-900\/35 {
  border-inline-end-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-e-red-900\/40 {
  border-inline-end-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-e-red-900\/45 {
  border-inline-end-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-e-red-900\/5 {
  border-inline-end-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-e-red-900\/50 {
  border-inline-end-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-e-red-900\/55 {
  border-inline-end-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-e-red-900\/60 {
  border-inline-end-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-e-red-900\/65 {
  border-inline-end-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-e-red-900\/70 {
  border-inline-end-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-e-red-900\/75 {
  border-inline-end-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-e-red-900\/80 {
  border-inline-end-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-e-red-900\/85 {
  border-inline-end-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-e-red-900\/90 {
  border-inline-end-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-e-red-900\/95 {
  border-inline-end-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-e-red-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-e-red-950\/0 {
  border-inline-end-color: rgb(69 10 10 / 0) !important;
}

.tw-border-e-red-950\/10 {
  border-inline-end-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-e-red-950\/100 {
  border-inline-end-color: rgb(69 10 10 / 1) !important;
}

.tw-border-e-red-950\/15 {
  border-inline-end-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-e-red-950\/20 {
  border-inline-end-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-e-red-950\/25 {
  border-inline-end-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-e-red-950\/30 {
  border-inline-end-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-e-red-950\/35 {
  border-inline-end-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-e-red-950\/40 {
  border-inline-end-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-e-red-950\/45 {
  border-inline-end-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-e-red-950\/5 {
  border-inline-end-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-e-red-950\/50 {
  border-inline-end-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-e-red-950\/55 {
  border-inline-end-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-e-red-950\/60 {
  border-inline-end-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-e-red-950\/65 {
  border-inline-end-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-e-red-950\/70 {
  border-inline-end-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-e-red-950\/75 {
  border-inline-end-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-e-red-950\/80 {
  border-inline-end-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-e-red-950\/85 {
  border-inline-end-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-e-red-950\/90 {
  border-inline-end-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-e-red-950\/95 {
  border-inline-end-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-e-rose-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-100\/0 {
  border-inline-end-color: rgb(255 228 230 / 0) !important;
}

.tw-border-e-rose-100\/10 {
  border-inline-end-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-e-rose-100\/100 {
  border-inline-end-color: rgb(255 228 230 / 1) !important;
}

.tw-border-e-rose-100\/15 {
  border-inline-end-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-e-rose-100\/20 {
  border-inline-end-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-e-rose-100\/25 {
  border-inline-end-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-e-rose-100\/30 {
  border-inline-end-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-e-rose-100\/35 {
  border-inline-end-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-e-rose-100\/40 {
  border-inline-end-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-e-rose-100\/45 {
  border-inline-end-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-e-rose-100\/5 {
  border-inline-end-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-e-rose-100\/50 {
  border-inline-end-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-e-rose-100\/55 {
  border-inline-end-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-e-rose-100\/60 {
  border-inline-end-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-e-rose-100\/65 {
  border-inline-end-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-e-rose-100\/70 {
  border-inline-end-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-e-rose-100\/75 {
  border-inline-end-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-e-rose-100\/80 {
  border-inline-end-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-e-rose-100\/85 {
  border-inline-end-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-e-rose-100\/90 {
  border-inline-end-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-e-rose-100\/95 {
  border-inline-end-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-e-rose-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-200\/0 {
  border-inline-end-color: rgb(254 205 211 / 0) !important;
}

.tw-border-e-rose-200\/10 {
  border-inline-end-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-e-rose-200\/100 {
  border-inline-end-color: rgb(254 205 211 / 1) !important;
}

.tw-border-e-rose-200\/15 {
  border-inline-end-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-e-rose-200\/20 {
  border-inline-end-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-e-rose-200\/25 {
  border-inline-end-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-e-rose-200\/30 {
  border-inline-end-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-e-rose-200\/35 {
  border-inline-end-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-e-rose-200\/40 {
  border-inline-end-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-e-rose-200\/45 {
  border-inline-end-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-e-rose-200\/5 {
  border-inline-end-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-e-rose-200\/50 {
  border-inline-end-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-e-rose-200\/55 {
  border-inline-end-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-e-rose-200\/60 {
  border-inline-end-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-e-rose-200\/65 {
  border-inline-end-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-e-rose-200\/70 {
  border-inline-end-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-e-rose-200\/75 {
  border-inline-end-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-e-rose-200\/80 {
  border-inline-end-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-e-rose-200\/85 {
  border-inline-end-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-e-rose-200\/90 {
  border-inline-end-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-e-rose-200\/95 {
  border-inline-end-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-e-rose-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-300\/0 {
  border-inline-end-color: rgb(253 164 175 / 0) !important;
}

.tw-border-e-rose-300\/10 {
  border-inline-end-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-e-rose-300\/100 {
  border-inline-end-color: rgb(253 164 175 / 1) !important;
}

.tw-border-e-rose-300\/15 {
  border-inline-end-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-e-rose-300\/20 {
  border-inline-end-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-e-rose-300\/25 {
  border-inline-end-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-e-rose-300\/30 {
  border-inline-end-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-e-rose-300\/35 {
  border-inline-end-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-e-rose-300\/40 {
  border-inline-end-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-e-rose-300\/45 {
  border-inline-end-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-e-rose-300\/5 {
  border-inline-end-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-e-rose-300\/50 {
  border-inline-end-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-e-rose-300\/55 {
  border-inline-end-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-e-rose-300\/60 {
  border-inline-end-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-e-rose-300\/65 {
  border-inline-end-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-e-rose-300\/70 {
  border-inline-end-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-e-rose-300\/75 {
  border-inline-end-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-e-rose-300\/80 {
  border-inline-end-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-e-rose-300\/85 {
  border-inline-end-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-e-rose-300\/90 {
  border-inline-end-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-e-rose-300\/95 {
  border-inline-end-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-e-rose-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-400\/0 {
  border-inline-end-color: rgb(251 113 133 / 0) !important;
}

.tw-border-e-rose-400\/10 {
  border-inline-end-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-e-rose-400\/100 {
  border-inline-end-color: rgb(251 113 133 / 1) !important;
}

.tw-border-e-rose-400\/15 {
  border-inline-end-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-e-rose-400\/20 {
  border-inline-end-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-e-rose-400\/25 {
  border-inline-end-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-e-rose-400\/30 {
  border-inline-end-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-e-rose-400\/35 {
  border-inline-end-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-e-rose-400\/40 {
  border-inline-end-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-e-rose-400\/45 {
  border-inline-end-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-e-rose-400\/5 {
  border-inline-end-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-e-rose-400\/50 {
  border-inline-end-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-e-rose-400\/55 {
  border-inline-end-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-e-rose-400\/60 {
  border-inline-end-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-e-rose-400\/65 {
  border-inline-end-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-e-rose-400\/70 {
  border-inline-end-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-e-rose-400\/75 {
  border-inline-end-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-e-rose-400\/80 {
  border-inline-end-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-e-rose-400\/85 {
  border-inline-end-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-e-rose-400\/90 {
  border-inline-end-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-e-rose-400\/95 {
  border-inline-end-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-e-rose-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-50\/0 {
  border-inline-end-color: rgb(255 241 242 / 0) !important;
}

.tw-border-e-rose-50\/10 {
  border-inline-end-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-e-rose-50\/100 {
  border-inline-end-color: rgb(255 241 242 / 1) !important;
}

.tw-border-e-rose-50\/15 {
  border-inline-end-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-e-rose-50\/20 {
  border-inline-end-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-e-rose-50\/25 {
  border-inline-end-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-e-rose-50\/30 {
  border-inline-end-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-e-rose-50\/35 {
  border-inline-end-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-e-rose-50\/40 {
  border-inline-end-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-e-rose-50\/45 {
  border-inline-end-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-e-rose-50\/5 {
  border-inline-end-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-e-rose-50\/50 {
  border-inline-end-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-e-rose-50\/55 {
  border-inline-end-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-e-rose-50\/60 {
  border-inline-end-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-e-rose-50\/65 {
  border-inline-end-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-e-rose-50\/70 {
  border-inline-end-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-e-rose-50\/75 {
  border-inline-end-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-e-rose-50\/80 {
  border-inline-end-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-e-rose-50\/85 {
  border-inline-end-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-e-rose-50\/90 {
  border-inline-end-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-e-rose-50\/95 {
  border-inline-end-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-e-rose-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-500\/0 {
  border-inline-end-color: rgb(244 63 94 / 0) !important;
}

.tw-border-e-rose-500\/10 {
  border-inline-end-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-e-rose-500\/100 {
  border-inline-end-color: rgb(244 63 94 / 1) !important;
}

.tw-border-e-rose-500\/15 {
  border-inline-end-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-e-rose-500\/20 {
  border-inline-end-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-e-rose-500\/25 {
  border-inline-end-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-e-rose-500\/30 {
  border-inline-end-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-e-rose-500\/35 {
  border-inline-end-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-e-rose-500\/40 {
  border-inline-end-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-e-rose-500\/45 {
  border-inline-end-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-e-rose-500\/5 {
  border-inline-end-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-e-rose-500\/50 {
  border-inline-end-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-e-rose-500\/55 {
  border-inline-end-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-e-rose-500\/60 {
  border-inline-end-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-e-rose-500\/65 {
  border-inline-end-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-e-rose-500\/70 {
  border-inline-end-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-e-rose-500\/75 {
  border-inline-end-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-e-rose-500\/80 {
  border-inline-end-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-e-rose-500\/85 {
  border-inline-end-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-e-rose-500\/90 {
  border-inline-end-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-e-rose-500\/95 {
  border-inline-end-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-e-rose-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-600\/0 {
  border-inline-end-color: rgb(225 29 72 / 0) !important;
}

.tw-border-e-rose-600\/10 {
  border-inline-end-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-e-rose-600\/100 {
  border-inline-end-color: rgb(225 29 72 / 1) !important;
}

.tw-border-e-rose-600\/15 {
  border-inline-end-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-e-rose-600\/20 {
  border-inline-end-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-e-rose-600\/25 {
  border-inline-end-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-e-rose-600\/30 {
  border-inline-end-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-e-rose-600\/35 {
  border-inline-end-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-e-rose-600\/40 {
  border-inline-end-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-e-rose-600\/45 {
  border-inline-end-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-e-rose-600\/5 {
  border-inline-end-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-e-rose-600\/50 {
  border-inline-end-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-e-rose-600\/55 {
  border-inline-end-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-e-rose-600\/60 {
  border-inline-end-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-e-rose-600\/65 {
  border-inline-end-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-e-rose-600\/70 {
  border-inline-end-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-e-rose-600\/75 {
  border-inline-end-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-e-rose-600\/80 {
  border-inline-end-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-e-rose-600\/85 {
  border-inline-end-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-e-rose-600\/90 {
  border-inline-end-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-e-rose-600\/95 {
  border-inline-end-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-e-rose-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-700\/0 {
  border-inline-end-color: rgb(190 18 60 / 0) !important;
}

.tw-border-e-rose-700\/10 {
  border-inline-end-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-e-rose-700\/100 {
  border-inline-end-color: rgb(190 18 60 / 1) !important;
}

.tw-border-e-rose-700\/15 {
  border-inline-end-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-e-rose-700\/20 {
  border-inline-end-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-e-rose-700\/25 {
  border-inline-end-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-e-rose-700\/30 {
  border-inline-end-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-e-rose-700\/35 {
  border-inline-end-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-e-rose-700\/40 {
  border-inline-end-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-e-rose-700\/45 {
  border-inline-end-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-e-rose-700\/5 {
  border-inline-end-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-e-rose-700\/50 {
  border-inline-end-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-e-rose-700\/55 {
  border-inline-end-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-e-rose-700\/60 {
  border-inline-end-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-e-rose-700\/65 {
  border-inline-end-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-e-rose-700\/70 {
  border-inline-end-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-e-rose-700\/75 {
  border-inline-end-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-e-rose-700\/80 {
  border-inline-end-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-e-rose-700\/85 {
  border-inline-end-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-e-rose-700\/90 {
  border-inline-end-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-e-rose-700\/95 {
  border-inline-end-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-e-rose-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-800\/0 {
  border-inline-end-color: rgb(159 18 57 / 0) !important;
}

.tw-border-e-rose-800\/10 {
  border-inline-end-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-e-rose-800\/100 {
  border-inline-end-color: rgb(159 18 57 / 1) !important;
}

.tw-border-e-rose-800\/15 {
  border-inline-end-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-e-rose-800\/20 {
  border-inline-end-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-e-rose-800\/25 {
  border-inline-end-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-e-rose-800\/30 {
  border-inline-end-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-e-rose-800\/35 {
  border-inline-end-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-e-rose-800\/40 {
  border-inline-end-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-e-rose-800\/45 {
  border-inline-end-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-e-rose-800\/5 {
  border-inline-end-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-e-rose-800\/50 {
  border-inline-end-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-e-rose-800\/55 {
  border-inline-end-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-e-rose-800\/60 {
  border-inline-end-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-e-rose-800\/65 {
  border-inline-end-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-e-rose-800\/70 {
  border-inline-end-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-e-rose-800\/75 {
  border-inline-end-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-e-rose-800\/80 {
  border-inline-end-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-e-rose-800\/85 {
  border-inline-end-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-e-rose-800\/90 {
  border-inline-end-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-e-rose-800\/95 {
  border-inline-end-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-e-rose-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-900\/0 {
  border-inline-end-color: rgb(136 19 55 / 0) !important;
}

.tw-border-e-rose-900\/10 {
  border-inline-end-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-e-rose-900\/100 {
  border-inline-end-color: rgb(136 19 55 / 1) !important;
}

.tw-border-e-rose-900\/15 {
  border-inline-end-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-e-rose-900\/20 {
  border-inline-end-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-e-rose-900\/25 {
  border-inline-end-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-e-rose-900\/30 {
  border-inline-end-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-e-rose-900\/35 {
  border-inline-end-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-e-rose-900\/40 {
  border-inline-end-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-e-rose-900\/45 {
  border-inline-end-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-e-rose-900\/5 {
  border-inline-end-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-e-rose-900\/50 {
  border-inline-end-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-e-rose-900\/55 {
  border-inline-end-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-e-rose-900\/60 {
  border-inline-end-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-e-rose-900\/65 {
  border-inline-end-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-e-rose-900\/70 {
  border-inline-end-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-e-rose-900\/75 {
  border-inline-end-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-e-rose-900\/80 {
  border-inline-end-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-e-rose-900\/85 {
  border-inline-end-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-e-rose-900\/90 {
  border-inline-end-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-e-rose-900\/95 {
  border-inline-end-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-e-rose-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-e-rose-950\/0 {
  border-inline-end-color: rgb(76 5 25 / 0) !important;
}

.tw-border-e-rose-950\/10 {
  border-inline-end-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-e-rose-950\/100 {
  border-inline-end-color: rgb(76 5 25 / 1) !important;
}

.tw-border-e-rose-950\/15 {
  border-inline-end-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-e-rose-950\/20 {
  border-inline-end-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-e-rose-950\/25 {
  border-inline-end-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-e-rose-950\/30 {
  border-inline-end-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-e-rose-950\/35 {
  border-inline-end-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-e-rose-950\/40 {
  border-inline-end-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-e-rose-950\/45 {
  border-inline-end-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-e-rose-950\/5 {
  border-inline-end-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-e-rose-950\/50 {
  border-inline-end-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-e-rose-950\/55 {
  border-inline-end-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-e-rose-950\/60 {
  border-inline-end-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-e-rose-950\/65 {
  border-inline-end-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-e-rose-950\/70 {
  border-inline-end-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-e-rose-950\/75 {
  border-inline-end-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-e-rose-950\/80 {
  border-inline-end-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-e-rose-950\/85 {
  border-inline-end-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-e-rose-950\/90 {
  border-inline-end-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-e-rose-950\/95 {
  border-inline-end-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-e-sky-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-100\/0 {
  border-inline-end-color: rgb(224 242 254 / 0) !important;
}

.tw-border-e-sky-100\/10 {
  border-inline-end-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-e-sky-100\/100 {
  border-inline-end-color: rgb(224 242 254 / 1) !important;
}

.tw-border-e-sky-100\/15 {
  border-inline-end-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-e-sky-100\/20 {
  border-inline-end-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-e-sky-100\/25 {
  border-inline-end-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-e-sky-100\/30 {
  border-inline-end-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-e-sky-100\/35 {
  border-inline-end-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-e-sky-100\/40 {
  border-inline-end-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-e-sky-100\/45 {
  border-inline-end-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-e-sky-100\/5 {
  border-inline-end-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-e-sky-100\/50 {
  border-inline-end-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-e-sky-100\/55 {
  border-inline-end-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-e-sky-100\/60 {
  border-inline-end-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-e-sky-100\/65 {
  border-inline-end-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-e-sky-100\/70 {
  border-inline-end-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-e-sky-100\/75 {
  border-inline-end-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-e-sky-100\/80 {
  border-inline-end-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-e-sky-100\/85 {
  border-inline-end-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-e-sky-100\/90 {
  border-inline-end-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-e-sky-100\/95 {
  border-inline-end-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-e-sky-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-200\/0 {
  border-inline-end-color: rgb(186 230 253 / 0) !important;
}

.tw-border-e-sky-200\/10 {
  border-inline-end-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-e-sky-200\/100 {
  border-inline-end-color: rgb(186 230 253 / 1) !important;
}

.tw-border-e-sky-200\/15 {
  border-inline-end-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-e-sky-200\/20 {
  border-inline-end-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-e-sky-200\/25 {
  border-inline-end-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-e-sky-200\/30 {
  border-inline-end-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-e-sky-200\/35 {
  border-inline-end-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-e-sky-200\/40 {
  border-inline-end-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-e-sky-200\/45 {
  border-inline-end-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-e-sky-200\/5 {
  border-inline-end-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-e-sky-200\/50 {
  border-inline-end-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-e-sky-200\/55 {
  border-inline-end-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-e-sky-200\/60 {
  border-inline-end-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-e-sky-200\/65 {
  border-inline-end-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-e-sky-200\/70 {
  border-inline-end-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-e-sky-200\/75 {
  border-inline-end-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-e-sky-200\/80 {
  border-inline-end-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-e-sky-200\/85 {
  border-inline-end-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-e-sky-200\/90 {
  border-inline-end-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-e-sky-200\/95 {
  border-inline-end-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-e-sky-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-300\/0 {
  border-inline-end-color: rgb(125 211 252 / 0) !important;
}

.tw-border-e-sky-300\/10 {
  border-inline-end-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-e-sky-300\/100 {
  border-inline-end-color: rgb(125 211 252 / 1) !important;
}

.tw-border-e-sky-300\/15 {
  border-inline-end-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-e-sky-300\/20 {
  border-inline-end-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-e-sky-300\/25 {
  border-inline-end-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-e-sky-300\/30 {
  border-inline-end-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-e-sky-300\/35 {
  border-inline-end-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-e-sky-300\/40 {
  border-inline-end-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-e-sky-300\/45 {
  border-inline-end-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-e-sky-300\/5 {
  border-inline-end-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-e-sky-300\/50 {
  border-inline-end-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-e-sky-300\/55 {
  border-inline-end-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-e-sky-300\/60 {
  border-inline-end-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-e-sky-300\/65 {
  border-inline-end-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-e-sky-300\/70 {
  border-inline-end-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-e-sky-300\/75 {
  border-inline-end-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-e-sky-300\/80 {
  border-inline-end-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-e-sky-300\/85 {
  border-inline-end-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-e-sky-300\/90 {
  border-inline-end-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-e-sky-300\/95 {
  border-inline-end-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-e-sky-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-400\/0 {
  border-inline-end-color: rgb(56 189 248 / 0) !important;
}

.tw-border-e-sky-400\/10 {
  border-inline-end-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-e-sky-400\/100 {
  border-inline-end-color: rgb(56 189 248 / 1) !important;
}

.tw-border-e-sky-400\/15 {
  border-inline-end-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-e-sky-400\/20 {
  border-inline-end-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-e-sky-400\/25 {
  border-inline-end-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-e-sky-400\/30 {
  border-inline-end-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-e-sky-400\/35 {
  border-inline-end-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-e-sky-400\/40 {
  border-inline-end-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-e-sky-400\/45 {
  border-inline-end-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-e-sky-400\/5 {
  border-inline-end-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-e-sky-400\/50 {
  border-inline-end-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-e-sky-400\/55 {
  border-inline-end-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-e-sky-400\/60 {
  border-inline-end-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-e-sky-400\/65 {
  border-inline-end-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-e-sky-400\/70 {
  border-inline-end-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-e-sky-400\/75 {
  border-inline-end-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-e-sky-400\/80 {
  border-inline-end-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-e-sky-400\/85 {
  border-inline-end-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-e-sky-400\/90 {
  border-inline-end-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-e-sky-400\/95 {
  border-inline-end-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-e-sky-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-50\/0 {
  border-inline-end-color: rgb(240 249 255 / 0) !important;
}

.tw-border-e-sky-50\/10 {
  border-inline-end-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-e-sky-50\/100 {
  border-inline-end-color: rgb(240 249 255 / 1) !important;
}

.tw-border-e-sky-50\/15 {
  border-inline-end-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-e-sky-50\/20 {
  border-inline-end-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-e-sky-50\/25 {
  border-inline-end-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-e-sky-50\/30 {
  border-inline-end-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-e-sky-50\/35 {
  border-inline-end-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-e-sky-50\/40 {
  border-inline-end-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-e-sky-50\/45 {
  border-inline-end-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-e-sky-50\/5 {
  border-inline-end-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-e-sky-50\/50 {
  border-inline-end-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-e-sky-50\/55 {
  border-inline-end-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-e-sky-50\/60 {
  border-inline-end-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-e-sky-50\/65 {
  border-inline-end-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-e-sky-50\/70 {
  border-inline-end-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-e-sky-50\/75 {
  border-inline-end-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-e-sky-50\/80 {
  border-inline-end-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-e-sky-50\/85 {
  border-inline-end-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-e-sky-50\/90 {
  border-inline-end-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-e-sky-50\/95 {
  border-inline-end-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-e-sky-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-500\/0 {
  border-inline-end-color: rgb(14 165 233 / 0) !important;
}

.tw-border-e-sky-500\/10 {
  border-inline-end-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-e-sky-500\/100 {
  border-inline-end-color: rgb(14 165 233 / 1) !important;
}

.tw-border-e-sky-500\/15 {
  border-inline-end-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-e-sky-500\/20 {
  border-inline-end-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-e-sky-500\/25 {
  border-inline-end-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-e-sky-500\/30 {
  border-inline-end-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-e-sky-500\/35 {
  border-inline-end-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-e-sky-500\/40 {
  border-inline-end-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-e-sky-500\/45 {
  border-inline-end-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-e-sky-500\/5 {
  border-inline-end-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-e-sky-500\/50 {
  border-inline-end-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-e-sky-500\/55 {
  border-inline-end-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-e-sky-500\/60 {
  border-inline-end-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-e-sky-500\/65 {
  border-inline-end-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-e-sky-500\/70 {
  border-inline-end-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-e-sky-500\/75 {
  border-inline-end-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-e-sky-500\/80 {
  border-inline-end-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-e-sky-500\/85 {
  border-inline-end-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-e-sky-500\/90 {
  border-inline-end-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-e-sky-500\/95 {
  border-inline-end-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-e-sky-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-600\/0 {
  border-inline-end-color: rgb(2 132 199 / 0) !important;
}

.tw-border-e-sky-600\/10 {
  border-inline-end-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-e-sky-600\/100 {
  border-inline-end-color: rgb(2 132 199 / 1) !important;
}

.tw-border-e-sky-600\/15 {
  border-inline-end-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-e-sky-600\/20 {
  border-inline-end-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-e-sky-600\/25 {
  border-inline-end-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-e-sky-600\/30 {
  border-inline-end-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-e-sky-600\/35 {
  border-inline-end-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-e-sky-600\/40 {
  border-inline-end-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-e-sky-600\/45 {
  border-inline-end-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-e-sky-600\/5 {
  border-inline-end-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-e-sky-600\/50 {
  border-inline-end-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-e-sky-600\/55 {
  border-inline-end-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-e-sky-600\/60 {
  border-inline-end-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-e-sky-600\/65 {
  border-inline-end-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-e-sky-600\/70 {
  border-inline-end-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-e-sky-600\/75 {
  border-inline-end-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-e-sky-600\/80 {
  border-inline-end-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-e-sky-600\/85 {
  border-inline-end-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-e-sky-600\/90 {
  border-inline-end-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-e-sky-600\/95 {
  border-inline-end-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-e-sky-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-700\/0 {
  border-inline-end-color: rgb(3 105 161 / 0) !important;
}

.tw-border-e-sky-700\/10 {
  border-inline-end-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-e-sky-700\/100 {
  border-inline-end-color: rgb(3 105 161 / 1) !important;
}

.tw-border-e-sky-700\/15 {
  border-inline-end-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-e-sky-700\/20 {
  border-inline-end-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-e-sky-700\/25 {
  border-inline-end-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-e-sky-700\/30 {
  border-inline-end-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-e-sky-700\/35 {
  border-inline-end-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-e-sky-700\/40 {
  border-inline-end-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-e-sky-700\/45 {
  border-inline-end-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-e-sky-700\/5 {
  border-inline-end-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-e-sky-700\/50 {
  border-inline-end-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-e-sky-700\/55 {
  border-inline-end-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-e-sky-700\/60 {
  border-inline-end-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-e-sky-700\/65 {
  border-inline-end-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-e-sky-700\/70 {
  border-inline-end-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-e-sky-700\/75 {
  border-inline-end-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-e-sky-700\/80 {
  border-inline-end-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-e-sky-700\/85 {
  border-inline-end-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-e-sky-700\/90 {
  border-inline-end-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-e-sky-700\/95 {
  border-inline-end-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-e-sky-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-800\/0 {
  border-inline-end-color: rgb(7 89 133 / 0) !important;
}

.tw-border-e-sky-800\/10 {
  border-inline-end-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-e-sky-800\/100 {
  border-inline-end-color: rgb(7 89 133 / 1) !important;
}

.tw-border-e-sky-800\/15 {
  border-inline-end-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-e-sky-800\/20 {
  border-inline-end-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-e-sky-800\/25 {
  border-inline-end-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-e-sky-800\/30 {
  border-inline-end-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-e-sky-800\/35 {
  border-inline-end-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-e-sky-800\/40 {
  border-inline-end-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-e-sky-800\/45 {
  border-inline-end-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-e-sky-800\/5 {
  border-inline-end-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-e-sky-800\/50 {
  border-inline-end-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-e-sky-800\/55 {
  border-inline-end-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-e-sky-800\/60 {
  border-inline-end-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-e-sky-800\/65 {
  border-inline-end-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-e-sky-800\/70 {
  border-inline-end-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-e-sky-800\/75 {
  border-inline-end-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-e-sky-800\/80 {
  border-inline-end-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-e-sky-800\/85 {
  border-inline-end-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-e-sky-800\/90 {
  border-inline-end-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-e-sky-800\/95 {
  border-inline-end-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-e-sky-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-900\/0 {
  border-inline-end-color: rgb(12 74 110 / 0) !important;
}

.tw-border-e-sky-900\/10 {
  border-inline-end-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-e-sky-900\/100 {
  border-inline-end-color: rgb(12 74 110 / 1) !important;
}

.tw-border-e-sky-900\/15 {
  border-inline-end-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-e-sky-900\/20 {
  border-inline-end-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-e-sky-900\/25 {
  border-inline-end-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-e-sky-900\/30 {
  border-inline-end-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-e-sky-900\/35 {
  border-inline-end-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-e-sky-900\/40 {
  border-inline-end-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-e-sky-900\/45 {
  border-inline-end-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-e-sky-900\/5 {
  border-inline-end-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-e-sky-900\/50 {
  border-inline-end-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-e-sky-900\/55 {
  border-inline-end-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-e-sky-900\/60 {
  border-inline-end-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-e-sky-900\/65 {
  border-inline-end-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-e-sky-900\/70 {
  border-inline-end-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-e-sky-900\/75 {
  border-inline-end-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-e-sky-900\/80 {
  border-inline-end-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-e-sky-900\/85 {
  border-inline-end-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-e-sky-900\/90 {
  border-inline-end-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-e-sky-900\/95 {
  border-inline-end-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-e-sky-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-e-sky-950\/0 {
  border-inline-end-color: rgb(8 47 73 / 0) !important;
}

.tw-border-e-sky-950\/10 {
  border-inline-end-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-e-sky-950\/100 {
  border-inline-end-color: rgb(8 47 73 / 1) !important;
}

.tw-border-e-sky-950\/15 {
  border-inline-end-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-e-sky-950\/20 {
  border-inline-end-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-e-sky-950\/25 {
  border-inline-end-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-e-sky-950\/30 {
  border-inline-end-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-e-sky-950\/35 {
  border-inline-end-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-e-sky-950\/40 {
  border-inline-end-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-e-sky-950\/45 {
  border-inline-end-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-e-sky-950\/5 {
  border-inline-end-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-e-sky-950\/50 {
  border-inline-end-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-e-sky-950\/55 {
  border-inline-end-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-e-sky-950\/60 {
  border-inline-end-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-e-sky-950\/65 {
  border-inline-end-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-e-sky-950\/70 {
  border-inline-end-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-e-sky-950\/75 {
  border-inline-end-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-e-sky-950\/80 {
  border-inline-end-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-e-sky-950\/85 {
  border-inline-end-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-e-sky-950\/90 {
  border-inline-end-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-e-sky-950\/95 {
  border-inline-end-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-e-slate-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-100\/0 {
  border-inline-end-color: rgb(241 245 249 / 0) !important;
}

.tw-border-e-slate-100\/10 {
  border-inline-end-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-e-slate-100\/100 {
  border-inline-end-color: rgb(241 245 249 / 1) !important;
}

.tw-border-e-slate-100\/15 {
  border-inline-end-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-e-slate-100\/20 {
  border-inline-end-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-e-slate-100\/25 {
  border-inline-end-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-e-slate-100\/30 {
  border-inline-end-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-e-slate-100\/35 {
  border-inline-end-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-e-slate-100\/40 {
  border-inline-end-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-e-slate-100\/45 {
  border-inline-end-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-e-slate-100\/5 {
  border-inline-end-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-e-slate-100\/50 {
  border-inline-end-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-e-slate-100\/55 {
  border-inline-end-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-e-slate-100\/60 {
  border-inline-end-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-e-slate-100\/65 {
  border-inline-end-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-e-slate-100\/70 {
  border-inline-end-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-e-slate-100\/75 {
  border-inline-end-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-e-slate-100\/80 {
  border-inline-end-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-e-slate-100\/85 {
  border-inline-end-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-e-slate-100\/90 {
  border-inline-end-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-e-slate-100\/95 {
  border-inline-end-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-e-slate-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-200\/0 {
  border-inline-end-color: rgb(226 232 240 / 0) !important;
}

.tw-border-e-slate-200\/10 {
  border-inline-end-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-e-slate-200\/100 {
  border-inline-end-color: rgb(226 232 240 / 1) !important;
}

.tw-border-e-slate-200\/15 {
  border-inline-end-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-e-slate-200\/20 {
  border-inline-end-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-e-slate-200\/25 {
  border-inline-end-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-e-slate-200\/30 {
  border-inline-end-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-e-slate-200\/35 {
  border-inline-end-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-e-slate-200\/40 {
  border-inline-end-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-e-slate-200\/45 {
  border-inline-end-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-e-slate-200\/5 {
  border-inline-end-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-e-slate-200\/50 {
  border-inline-end-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-e-slate-200\/55 {
  border-inline-end-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-e-slate-200\/60 {
  border-inline-end-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-e-slate-200\/65 {
  border-inline-end-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-e-slate-200\/70 {
  border-inline-end-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-e-slate-200\/75 {
  border-inline-end-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-e-slate-200\/80 {
  border-inline-end-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-e-slate-200\/85 {
  border-inline-end-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-e-slate-200\/90 {
  border-inline-end-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-e-slate-200\/95 {
  border-inline-end-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-e-slate-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-300\/0 {
  border-inline-end-color: rgb(203 213 225 / 0) !important;
}

.tw-border-e-slate-300\/10 {
  border-inline-end-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-e-slate-300\/100 {
  border-inline-end-color: rgb(203 213 225 / 1) !important;
}

.tw-border-e-slate-300\/15 {
  border-inline-end-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-e-slate-300\/20 {
  border-inline-end-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-e-slate-300\/25 {
  border-inline-end-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-e-slate-300\/30 {
  border-inline-end-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-e-slate-300\/35 {
  border-inline-end-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-e-slate-300\/40 {
  border-inline-end-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-e-slate-300\/45 {
  border-inline-end-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-e-slate-300\/5 {
  border-inline-end-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-e-slate-300\/50 {
  border-inline-end-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-e-slate-300\/55 {
  border-inline-end-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-e-slate-300\/60 {
  border-inline-end-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-e-slate-300\/65 {
  border-inline-end-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-e-slate-300\/70 {
  border-inline-end-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-e-slate-300\/75 {
  border-inline-end-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-e-slate-300\/80 {
  border-inline-end-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-e-slate-300\/85 {
  border-inline-end-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-e-slate-300\/90 {
  border-inline-end-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-e-slate-300\/95 {
  border-inline-end-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-e-slate-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-400\/0 {
  border-inline-end-color: rgb(148 163 184 / 0) !important;
}

.tw-border-e-slate-400\/10 {
  border-inline-end-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-e-slate-400\/100 {
  border-inline-end-color: rgb(148 163 184 / 1) !important;
}

.tw-border-e-slate-400\/15 {
  border-inline-end-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-e-slate-400\/20 {
  border-inline-end-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-e-slate-400\/25 {
  border-inline-end-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-e-slate-400\/30 {
  border-inline-end-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-e-slate-400\/35 {
  border-inline-end-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-e-slate-400\/40 {
  border-inline-end-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-e-slate-400\/45 {
  border-inline-end-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-e-slate-400\/5 {
  border-inline-end-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-e-slate-400\/50 {
  border-inline-end-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-e-slate-400\/55 {
  border-inline-end-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-e-slate-400\/60 {
  border-inline-end-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-e-slate-400\/65 {
  border-inline-end-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-e-slate-400\/70 {
  border-inline-end-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-e-slate-400\/75 {
  border-inline-end-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-e-slate-400\/80 {
  border-inline-end-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-e-slate-400\/85 {
  border-inline-end-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-e-slate-400\/90 {
  border-inline-end-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-e-slate-400\/95 {
  border-inline-end-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-e-slate-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-50\/0 {
  border-inline-end-color: rgb(248 250 252 / 0) !important;
}

.tw-border-e-slate-50\/10 {
  border-inline-end-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-e-slate-50\/100 {
  border-inline-end-color: rgb(248 250 252 / 1) !important;
}

.tw-border-e-slate-50\/15 {
  border-inline-end-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-e-slate-50\/20 {
  border-inline-end-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-e-slate-50\/25 {
  border-inline-end-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-e-slate-50\/30 {
  border-inline-end-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-e-slate-50\/35 {
  border-inline-end-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-e-slate-50\/40 {
  border-inline-end-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-e-slate-50\/45 {
  border-inline-end-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-e-slate-50\/5 {
  border-inline-end-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-e-slate-50\/50 {
  border-inline-end-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-e-slate-50\/55 {
  border-inline-end-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-e-slate-50\/60 {
  border-inline-end-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-e-slate-50\/65 {
  border-inline-end-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-e-slate-50\/70 {
  border-inline-end-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-e-slate-50\/75 {
  border-inline-end-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-e-slate-50\/80 {
  border-inline-end-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-e-slate-50\/85 {
  border-inline-end-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-e-slate-50\/90 {
  border-inline-end-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-e-slate-50\/95 {
  border-inline-end-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-e-slate-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-500\/0 {
  border-inline-end-color: rgb(100 116 139 / 0) !important;
}

.tw-border-e-slate-500\/10 {
  border-inline-end-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-e-slate-500\/100 {
  border-inline-end-color: rgb(100 116 139 / 1) !important;
}

.tw-border-e-slate-500\/15 {
  border-inline-end-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-e-slate-500\/20 {
  border-inline-end-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-e-slate-500\/25 {
  border-inline-end-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-e-slate-500\/30 {
  border-inline-end-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-e-slate-500\/35 {
  border-inline-end-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-e-slate-500\/40 {
  border-inline-end-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-e-slate-500\/45 {
  border-inline-end-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-e-slate-500\/5 {
  border-inline-end-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-e-slate-500\/50 {
  border-inline-end-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-e-slate-500\/55 {
  border-inline-end-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-e-slate-500\/60 {
  border-inline-end-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-e-slate-500\/65 {
  border-inline-end-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-e-slate-500\/70 {
  border-inline-end-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-e-slate-500\/75 {
  border-inline-end-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-e-slate-500\/80 {
  border-inline-end-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-e-slate-500\/85 {
  border-inline-end-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-e-slate-500\/90 {
  border-inline-end-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-e-slate-500\/95 {
  border-inline-end-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-e-slate-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-600\/0 {
  border-inline-end-color: rgb(71 85 105 / 0) !important;
}

.tw-border-e-slate-600\/10 {
  border-inline-end-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-e-slate-600\/100 {
  border-inline-end-color: rgb(71 85 105 / 1) !important;
}

.tw-border-e-slate-600\/15 {
  border-inline-end-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-e-slate-600\/20 {
  border-inline-end-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-e-slate-600\/25 {
  border-inline-end-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-e-slate-600\/30 {
  border-inline-end-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-e-slate-600\/35 {
  border-inline-end-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-e-slate-600\/40 {
  border-inline-end-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-e-slate-600\/45 {
  border-inline-end-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-e-slate-600\/5 {
  border-inline-end-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-e-slate-600\/50 {
  border-inline-end-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-e-slate-600\/55 {
  border-inline-end-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-e-slate-600\/60 {
  border-inline-end-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-e-slate-600\/65 {
  border-inline-end-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-e-slate-600\/70 {
  border-inline-end-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-e-slate-600\/75 {
  border-inline-end-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-e-slate-600\/80 {
  border-inline-end-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-e-slate-600\/85 {
  border-inline-end-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-e-slate-600\/90 {
  border-inline-end-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-e-slate-600\/95 {
  border-inline-end-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-e-slate-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-700\/0 {
  border-inline-end-color: rgb(51 65 85 / 0) !important;
}

.tw-border-e-slate-700\/10 {
  border-inline-end-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-e-slate-700\/100 {
  border-inline-end-color: rgb(51 65 85 / 1) !important;
}

.tw-border-e-slate-700\/15 {
  border-inline-end-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-e-slate-700\/20 {
  border-inline-end-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-e-slate-700\/25 {
  border-inline-end-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-e-slate-700\/30 {
  border-inline-end-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-e-slate-700\/35 {
  border-inline-end-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-e-slate-700\/40 {
  border-inline-end-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-e-slate-700\/45 {
  border-inline-end-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-e-slate-700\/5 {
  border-inline-end-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-e-slate-700\/50 {
  border-inline-end-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-e-slate-700\/55 {
  border-inline-end-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-e-slate-700\/60 {
  border-inline-end-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-e-slate-700\/65 {
  border-inline-end-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-e-slate-700\/70 {
  border-inline-end-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-e-slate-700\/75 {
  border-inline-end-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-e-slate-700\/80 {
  border-inline-end-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-e-slate-700\/85 {
  border-inline-end-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-e-slate-700\/90 {
  border-inline-end-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-e-slate-700\/95 {
  border-inline-end-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-e-slate-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-800\/0 {
  border-inline-end-color: rgb(30 41 59 / 0) !important;
}

.tw-border-e-slate-800\/10 {
  border-inline-end-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-e-slate-800\/100 {
  border-inline-end-color: rgb(30 41 59 / 1) !important;
}

.tw-border-e-slate-800\/15 {
  border-inline-end-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-e-slate-800\/20 {
  border-inline-end-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-e-slate-800\/25 {
  border-inline-end-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-e-slate-800\/30 {
  border-inline-end-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-e-slate-800\/35 {
  border-inline-end-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-e-slate-800\/40 {
  border-inline-end-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-e-slate-800\/45 {
  border-inline-end-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-e-slate-800\/5 {
  border-inline-end-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-e-slate-800\/50 {
  border-inline-end-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-e-slate-800\/55 {
  border-inline-end-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-e-slate-800\/60 {
  border-inline-end-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-e-slate-800\/65 {
  border-inline-end-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-e-slate-800\/70 {
  border-inline-end-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-e-slate-800\/75 {
  border-inline-end-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-e-slate-800\/80 {
  border-inline-end-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-e-slate-800\/85 {
  border-inline-end-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-e-slate-800\/90 {
  border-inline-end-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-e-slate-800\/95 {
  border-inline-end-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-e-slate-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-900\/0 {
  border-inline-end-color: rgb(15 23 42 / 0) !important;
}

.tw-border-e-slate-900\/10 {
  border-inline-end-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-e-slate-900\/100 {
  border-inline-end-color: rgb(15 23 42 / 1) !important;
}

.tw-border-e-slate-900\/15 {
  border-inline-end-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-e-slate-900\/20 {
  border-inline-end-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-e-slate-900\/25 {
  border-inline-end-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-e-slate-900\/30 {
  border-inline-end-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-e-slate-900\/35 {
  border-inline-end-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-e-slate-900\/40 {
  border-inline-end-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-e-slate-900\/45 {
  border-inline-end-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-e-slate-900\/5 {
  border-inline-end-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-e-slate-900\/50 {
  border-inline-end-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-e-slate-900\/55 {
  border-inline-end-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-e-slate-900\/60 {
  border-inline-end-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-e-slate-900\/65 {
  border-inline-end-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-e-slate-900\/70 {
  border-inline-end-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-e-slate-900\/75 {
  border-inline-end-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-e-slate-900\/80 {
  border-inline-end-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-e-slate-900\/85 {
  border-inline-end-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-e-slate-900\/90 {
  border-inline-end-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-e-slate-900\/95 {
  border-inline-end-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-e-slate-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-e-slate-950\/0 {
  border-inline-end-color: rgb(2 6 23 / 0) !important;
}

.tw-border-e-slate-950\/10 {
  border-inline-end-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-e-slate-950\/100 {
  border-inline-end-color: rgb(2 6 23 / 1) !important;
}

.tw-border-e-slate-950\/15 {
  border-inline-end-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-e-slate-950\/20 {
  border-inline-end-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-e-slate-950\/25 {
  border-inline-end-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-e-slate-950\/30 {
  border-inline-end-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-e-slate-950\/35 {
  border-inline-end-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-e-slate-950\/40 {
  border-inline-end-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-e-slate-950\/45 {
  border-inline-end-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-e-slate-950\/5 {
  border-inline-end-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-e-slate-950\/50 {
  border-inline-end-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-e-slate-950\/55 {
  border-inline-end-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-e-slate-950\/60 {
  border-inline-end-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-e-slate-950\/65 {
  border-inline-end-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-e-slate-950\/70 {
  border-inline-end-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-e-slate-950\/75 {
  border-inline-end-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-e-slate-950\/80 {
  border-inline-end-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-e-slate-950\/85 {
  border-inline-end-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-e-slate-950\/90 {
  border-inline-end-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-e-slate-950\/95 {
  border-inline-end-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-e-stone-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-100\/0 {
  border-inline-end-color: rgb(245 245 244 / 0) !important;
}

.tw-border-e-stone-100\/10 {
  border-inline-end-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-e-stone-100\/100 {
  border-inline-end-color: rgb(245 245 244 / 1) !important;
}

.tw-border-e-stone-100\/15 {
  border-inline-end-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-e-stone-100\/20 {
  border-inline-end-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-e-stone-100\/25 {
  border-inline-end-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-e-stone-100\/30 {
  border-inline-end-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-e-stone-100\/35 {
  border-inline-end-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-e-stone-100\/40 {
  border-inline-end-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-e-stone-100\/45 {
  border-inline-end-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-e-stone-100\/5 {
  border-inline-end-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-e-stone-100\/50 {
  border-inline-end-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-e-stone-100\/55 {
  border-inline-end-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-e-stone-100\/60 {
  border-inline-end-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-e-stone-100\/65 {
  border-inline-end-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-e-stone-100\/70 {
  border-inline-end-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-e-stone-100\/75 {
  border-inline-end-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-e-stone-100\/80 {
  border-inline-end-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-e-stone-100\/85 {
  border-inline-end-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-e-stone-100\/90 {
  border-inline-end-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-e-stone-100\/95 {
  border-inline-end-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-e-stone-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-200\/0 {
  border-inline-end-color: rgb(231 229 228 / 0) !important;
}

.tw-border-e-stone-200\/10 {
  border-inline-end-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-e-stone-200\/100 {
  border-inline-end-color: rgb(231 229 228 / 1) !important;
}

.tw-border-e-stone-200\/15 {
  border-inline-end-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-e-stone-200\/20 {
  border-inline-end-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-e-stone-200\/25 {
  border-inline-end-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-e-stone-200\/30 {
  border-inline-end-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-e-stone-200\/35 {
  border-inline-end-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-e-stone-200\/40 {
  border-inline-end-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-e-stone-200\/45 {
  border-inline-end-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-e-stone-200\/5 {
  border-inline-end-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-e-stone-200\/50 {
  border-inline-end-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-e-stone-200\/55 {
  border-inline-end-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-e-stone-200\/60 {
  border-inline-end-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-e-stone-200\/65 {
  border-inline-end-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-e-stone-200\/70 {
  border-inline-end-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-e-stone-200\/75 {
  border-inline-end-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-e-stone-200\/80 {
  border-inline-end-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-e-stone-200\/85 {
  border-inline-end-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-e-stone-200\/90 {
  border-inline-end-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-e-stone-200\/95 {
  border-inline-end-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-e-stone-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-300\/0 {
  border-inline-end-color: rgb(214 211 209 / 0) !important;
}

.tw-border-e-stone-300\/10 {
  border-inline-end-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-e-stone-300\/100 {
  border-inline-end-color: rgb(214 211 209 / 1) !important;
}

.tw-border-e-stone-300\/15 {
  border-inline-end-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-e-stone-300\/20 {
  border-inline-end-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-e-stone-300\/25 {
  border-inline-end-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-e-stone-300\/30 {
  border-inline-end-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-e-stone-300\/35 {
  border-inline-end-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-e-stone-300\/40 {
  border-inline-end-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-e-stone-300\/45 {
  border-inline-end-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-e-stone-300\/5 {
  border-inline-end-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-e-stone-300\/50 {
  border-inline-end-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-e-stone-300\/55 {
  border-inline-end-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-e-stone-300\/60 {
  border-inline-end-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-e-stone-300\/65 {
  border-inline-end-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-e-stone-300\/70 {
  border-inline-end-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-e-stone-300\/75 {
  border-inline-end-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-e-stone-300\/80 {
  border-inline-end-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-e-stone-300\/85 {
  border-inline-end-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-e-stone-300\/90 {
  border-inline-end-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-e-stone-300\/95 {
  border-inline-end-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-e-stone-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-400\/0 {
  border-inline-end-color: rgb(168 162 158 / 0) !important;
}

.tw-border-e-stone-400\/10 {
  border-inline-end-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-e-stone-400\/100 {
  border-inline-end-color: rgb(168 162 158 / 1) !important;
}

.tw-border-e-stone-400\/15 {
  border-inline-end-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-e-stone-400\/20 {
  border-inline-end-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-e-stone-400\/25 {
  border-inline-end-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-e-stone-400\/30 {
  border-inline-end-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-e-stone-400\/35 {
  border-inline-end-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-e-stone-400\/40 {
  border-inline-end-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-e-stone-400\/45 {
  border-inline-end-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-e-stone-400\/5 {
  border-inline-end-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-e-stone-400\/50 {
  border-inline-end-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-e-stone-400\/55 {
  border-inline-end-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-e-stone-400\/60 {
  border-inline-end-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-e-stone-400\/65 {
  border-inline-end-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-e-stone-400\/70 {
  border-inline-end-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-e-stone-400\/75 {
  border-inline-end-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-e-stone-400\/80 {
  border-inline-end-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-e-stone-400\/85 {
  border-inline-end-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-e-stone-400\/90 {
  border-inline-end-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-e-stone-400\/95 {
  border-inline-end-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-e-stone-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-50\/0 {
  border-inline-end-color: rgb(250 250 249 / 0) !important;
}

.tw-border-e-stone-50\/10 {
  border-inline-end-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-e-stone-50\/100 {
  border-inline-end-color: rgb(250 250 249 / 1) !important;
}

.tw-border-e-stone-50\/15 {
  border-inline-end-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-e-stone-50\/20 {
  border-inline-end-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-e-stone-50\/25 {
  border-inline-end-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-e-stone-50\/30 {
  border-inline-end-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-e-stone-50\/35 {
  border-inline-end-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-e-stone-50\/40 {
  border-inline-end-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-e-stone-50\/45 {
  border-inline-end-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-e-stone-50\/5 {
  border-inline-end-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-e-stone-50\/50 {
  border-inline-end-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-e-stone-50\/55 {
  border-inline-end-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-e-stone-50\/60 {
  border-inline-end-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-e-stone-50\/65 {
  border-inline-end-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-e-stone-50\/70 {
  border-inline-end-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-e-stone-50\/75 {
  border-inline-end-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-e-stone-50\/80 {
  border-inline-end-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-e-stone-50\/85 {
  border-inline-end-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-e-stone-50\/90 {
  border-inline-end-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-e-stone-50\/95 {
  border-inline-end-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-e-stone-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-500\/0 {
  border-inline-end-color: rgb(120 113 108 / 0) !important;
}

.tw-border-e-stone-500\/10 {
  border-inline-end-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-e-stone-500\/100 {
  border-inline-end-color: rgb(120 113 108 / 1) !important;
}

.tw-border-e-stone-500\/15 {
  border-inline-end-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-e-stone-500\/20 {
  border-inline-end-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-e-stone-500\/25 {
  border-inline-end-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-e-stone-500\/30 {
  border-inline-end-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-e-stone-500\/35 {
  border-inline-end-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-e-stone-500\/40 {
  border-inline-end-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-e-stone-500\/45 {
  border-inline-end-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-e-stone-500\/5 {
  border-inline-end-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-e-stone-500\/50 {
  border-inline-end-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-e-stone-500\/55 {
  border-inline-end-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-e-stone-500\/60 {
  border-inline-end-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-e-stone-500\/65 {
  border-inline-end-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-e-stone-500\/70 {
  border-inline-end-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-e-stone-500\/75 {
  border-inline-end-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-e-stone-500\/80 {
  border-inline-end-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-e-stone-500\/85 {
  border-inline-end-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-e-stone-500\/90 {
  border-inline-end-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-e-stone-500\/95 {
  border-inline-end-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-e-stone-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-600\/0 {
  border-inline-end-color: rgb(87 83 78 / 0) !important;
}

.tw-border-e-stone-600\/10 {
  border-inline-end-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-e-stone-600\/100 {
  border-inline-end-color: rgb(87 83 78 / 1) !important;
}

.tw-border-e-stone-600\/15 {
  border-inline-end-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-e-stone-600\/20 {
  border-inline-end-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-e-stone-600\/25 {
  border-inline-end-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-e-stone-600\/30 {
  border-inline-end-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-e-stone-600\/35 {
  border-inline-end-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-e-stone-600\/40 {
  border-inline-end-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-e-stone-600\/45 {
  border-inline-end-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-e-stone-600\/5 {
  border-inline-end-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-e-stone-600\/50 {
  border-inline-end-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-e-stone-600\/55 {
  border-inline-end-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-e-stone-600\/60 {
  border-inline-end-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-e-stone-600\/65 {
  border-inline-end-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-e-stone-600\/70 {
  border-inline-end-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-e-stone-600\/75 {
  border-inline-end-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-e-stone-600\/80 {
  border-inline-end-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-e-stone-600\/85 {
  border-inline-end-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-e-stone-600\/90 {
  border-inline-end-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-e-stone-600\/95 {
  border-inline-end-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-e-stone-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-700\/0 {
  border-inline-end-color: rgb(68 64 60 / 0) !important;
}

.tw-border-e-stone-700\/10 {
  border-inline-end-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-e-stone-700\/100 {
  border-inline-end-color: rgb(68 64 60 / 1) !important;
}

.tw-border-e-stone-700\/15 {
  border-inline-end-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-e-stone-700\/20 {
  border-inline-end-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-e-stone-700\/25 {
  border-inline-end-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-e-stone-700\/30 {
  border-inline-end-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-e-stone-700\/35 {
  border-inline-end-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-e-stone-700\/40 {
  border-inline-end-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-e-stone-700\/45 {
  border-inline-end-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-e-stone-700\/5 {
  border-inline-end-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-e-stone-700\/50 {
  border-inline-end-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-e-stone-700\/55 {
  border-inline-end-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-e-stone-700\/60 {
  border-inline-end-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-e-stone-700\/65 {
  border-inline-end-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-e-stone-700\/70 {
  border-inline-end-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-e-stone-700\/75 {
  border-inline-end-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-e-stone-700\/80 {
  border-inline-end-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-e-stone-700\/85 {
  border-inline-end-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-e-stone-700\/90 {
  border-inline-end-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-e-stone-700\/95 {
  border-inline-end-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-e-stone-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-800\/0 {
  border-inline-end-color: rgb(41 37 36 / 0) !important;
}

.tw-border-e-stone-800\/10 {
  border-inline-end-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-e-stone-800\/100 {
  border-inline-end-color: rgb(41 37 36 / 1) !important;
}

.tw-border-e-stone-800\/15 {
  border-inline-end-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-e-stone-800\/20 {
  border-inline-end-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-e-stone-800\/25 {
  border-inline-end-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-e-stone-800\/30 {
  border-inline-end-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-e-stone-800\/35 {
  border-inline-end-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-e-stone-800\/40 {
  border-inline-end-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-e-stone-800\/45 {
  border-inline-end-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-e-stone-800\/5 {
  border-inline-end-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-e-stone-800\/50 {
  border-inline-end-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-e-stone-800\/55 {
  border-inline-end-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-e-stone-800\/60 {
  border-inline-end-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-e-stone-800\/65 {
  border-inline-end-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-e-stone-800\/70 {
  border-inline-end-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-e-stone-800\/75 {
  border-inline-end-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-e-stone-800\/80 {
  border-inline-end-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-e-stone-800\/85 {
  border-inline-end-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-e-stone-800\/90 {
  border-inline-end-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-e-stone-800\/95 {
  border-inline-end-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-e-stone-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-900\/0 {
  border-inline-end-color: rgb(28 25 23 / 0) !important;
}

.tw-border-e-stone-900\/10 {
  border-inline-end-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-e-stone-900\/100 {
  border-inline-end-color: rgb(28 25 23 / 1) !important;
}

.tw-border-e-stone-900\/15 {
  border-inline-end-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-e-stone-900\/20 {
  border-inline-end-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-e-stone-900\/25 {
  border-inline-end-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-e-stone-900\/30 {
  border-inline-end-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-e-stone-900\/35 {
  border-inline-end-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-e-stone-900\/40 {
  border-inline-end-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-e-stone-900\/45 {
  border-inline-end-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-e-stone-900\/5 {
  border-inline-end-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-e-stone-900\/50 {
  border-inline-end-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-e-stone-900\/55 {
  border-inline-end-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-e-stone-900\/60 {
  border-inline-end-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-e-stone-900\/65 {
  border-inline-end-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-e-stone-900\/70 {
  border-inline-end-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-e-stone-900\/75 {
  border-inline-end-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-e-stone-900\/80 {
  border-inline-end-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-e-stone-900\/85 {
  border-inline-end-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-e-stone-900\/90 {
  border-inline-end-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-e-stone-900\/95 {
  border-inline-end-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-e-stone-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-e-stone-950\/0 {
  border-inline-end-color: rgb(12 10 9 / 0) !important;
}

.tw-border-e-stone-950\/10 {
  border-inline-end-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-e-stone-950\/100 {
  border-inline-end-color: rgb(12 10 9 / 1) !important;
}

.tw-border-e-stone-950\/15 {
  border-inline-end-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-e-stone-950\/20 {
  border-inline-end-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-e-stone-950\/25 {
  border-inline-end-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-e-stone-950\/30 {
  border-inline-end-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-e-stone-950\/35 {
  border-inline-end-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-e-stone-950\/40 {
  border-inline-end-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-e-stone-950\/45 {
  border-inline-end-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-e-stone-950\/5 {
  border-inline-end-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-e-stone-950\/50 {
  border-inline-end-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-e-stone-950\/55 {
  border-inline-end-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-e-stone-950\/60 {
  border-inline-end-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-e-stone-950\/65 {
  border-inline-end-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-e-stone-950\/70 {
  border-inline-end-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-e-stone-950\/75 {
  border-inline-end-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-e-stone-950\/80 {
  border-inline-end-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-e-stone-950\/85 {
  border-inline-end-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-e-stone-950\/90 {
  border-inline-end-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-e-stone-950\/95 {
  border-inline-end-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-e-teal-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-100\/0 {
  border-inline-end-color: rgb(204 251 241 / 0) !important;
}

.tw-border-e-teal-100\/10 {
  border-inline-end-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-e-teal-100\/100 {
  border-inline-end-color: rgb(204 251 241 / 1) !important;
}

.tw-border-e-teal-100\/15 {
  border-inline-end-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-e-teal-100\/20 {
  border-inline-end-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-e-teal-100\/25 {
  border-inline-end-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-e-teal-100\/30 {
  border-inline-end-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-e-teal-100\/35 {
  border-inline-end-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-e-teal-100\/40 {
  border-inline-end-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-e-teal-100\/45 {
  border-inline-end-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-e-teal-100\/5 {
  border-inline-end-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-e-teal-100\/50 {
  border-inline-end-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-e-teal-100\/55 {
  border-inline-end-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-e-teal-100\/60 {
  border-inline-end-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-e-teal-100\/65 {
  border-inline-end-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-e-teal-100\/70 {
  border-inline-end-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-e-teal-100\/75 {
  border-inline-end-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-e-teal-100\/80 {
  border-inline-end-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-e-teal-100\/85 {
  border-inline-end-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-e-teal-100\/90 {
  border-inline-end-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-e-teal-100\/95 {
  border-inline-end-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-e-teal-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-200\/0 {
  border-inline-end-color: rgb(153 246 228 / 0) !important;
}

.tw-border-e-teal-200\/10 {
  border-inline-end-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-e-teal-200\/100 {
  border-inline-end-color: rgb(153 246 228 / 1) !important;
}

.tw-border-e-teal-200\/15 {
  border-inline-end-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-e-teal-200\/20 {
  border-inline-end-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-e-teal-200\/25 {
  border-inline-end-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-e-teal-200\/30 {
  border-inline-end-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-e-teal-200\/35 {
  border-inline-end-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-e-teal-200\/40 {
  border-inline-end-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-e-teal-200\/45 {
  border-inline-end-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-e-teal-200\/5 {
  border-inline-end-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-e-teal-200\/50 {
  border-inline-end-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-e-teal-200\/55 {
  border-inline-end-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-e-teal-200\/60 {
  border-inline-end-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-e-teal-200\/65 {
  border-inline-end-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-e-teal-200\/70 {
  border-inline-end-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-e-teal-200\/75 {
  border-inline-end-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-e-teal-200\/80 {
  border-inline-end-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-e-teal-200\/85 {
  border-inline-end-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-e-teal-200\/90 {
  border-inline-end-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-e-teal-200\/95 {
  border-inline-end-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-e-teal-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-300\/0 {
  border-inline-end-color: rgb(94 234 212 / 0) !important;
}

.tw-border-e-teal-300\/10 {
  border-inline-end-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-e-teal-300\/100 {
  border-inline-end-color: rgb(94 234 212 / 1) !important;
}

.tw-border-e-teal-300\/15 {
  border-inline-end-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-e-teal-300\/20 {
  border-inline-end-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-e-teal-300\/25 {
  border-inline-end-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-e-teal-300\/30 {
  border-inline-end-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-e-teal-300\/35 {
  border-inline-end-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-e-teal-300\/40 {
  border-inline-end-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-e-teal-300\/45 {
  border-inline-end-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-e-teal-300\/5 {
  border-inline-end-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-e-teal-300\/50 {
  border-inline-end-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-e-teal-300\/55 {
  border-inline-end-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-e-teal-300\/60 {
  border-inline-end-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-e-teal-300\/65 {
  border-inline-end-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-e-teal-300\/70 {
  border-inline-end-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-e-teal-300\/75 {
  border-inline-end-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-e-teal-300\/80 {
  border-inline-end-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-e-teal-300\/85 {
  border-inline-end-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-e-teal-300\/90 {
  border-inline-end-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-e-teal-300\/95 {
  border-inline-end-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-e-teal-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-400\/0 {
  border-inline-end-color: rgb(45 212 191 / 0) !important;
}

.tw-border-e-teal-400\/10 {
  border-inline-end-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-e-teal-400\/100 {
  border-inline-end-color: rgb(45 212 191 / 1) !important;
}

.tw-border-e-teal-400\/15 {
  border-inline-end-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-e-teal-400\/20 {
  border-inline-end-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-e-teal-400\/25 {
  border-inline-end-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-e-teal-400\/30 {
  border-inline-end-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-e-teal-400\/35 {
  border-inline-end-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-e-teal-400\/40 {
  border-inline-end-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-e-teal-400\/45 {
  border-inline-end-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-e-teal-400\/5 {
  border-inline-end-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-e-teal-400\/50 {
  border-inline-end-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-e-teal-400\/55 {
  border-inline-end-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-e-teal-400\/60 {
  border-inline-end-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-e-teal-400\/65 {
  border-inline-end-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-e-teal-400\/70 {
  border-inline-end-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-e-teal-400\/75 {
  border-inline-end-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-e-teal-400\/80 {
  border-inline-end-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-e-teal-400\/85 {
  border-inline-end-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-e-teal-400\/90 {
  border-inline-end-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-e-teal-400\/95 {
  border-inline-end-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-e-teal-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-50\/0 {
  border-inline-end-color: rgb(240 253 250 / 0) !important;
}

.tw-border-e-teal-50\/10 {
  border-inline-end-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-e-teal-50\/100 {
  border-inline-end-color: rgb(240 253 250 / 1) !important;
}

.tw-border-e-teal-50\/15 {
  border-inline-end-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-e-teal-50\/20 {
  border-inline-end-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-e-teal-50\/25 {
  border-inline-end-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-e-teal-50\/30 {
  border-inline-end-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-e-teal-50\/35 {
  border-inline-end-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-e-teal-50\/40 {
  border-inline-end-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-e-teal-50\/45 {
  border-inline-end-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-e-teal-50\/5 {
  border-inline-end-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-e-teal-50\/50 {
  border-inline-end-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-e-teal-50\/55 {
  border-inline-end-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-e-teal-50\/60 {
  border-inline-end-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-e-teal-50\/65 {
  border-inline-end-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-e-teal-50\/70 {
  border-inline-end-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-e-teal-50\/75 {
  border-inline-end-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-e-teal-50\/80 {
  border-inline-end-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-e-teal-50\/85 {
  border-inline-end-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-e-teal-50\/90 {
  border-inline-end-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-e-teal-50\/95 {
  border-inline-end-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-e-teal-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-500\/0 {
  border-inline-end-color: rgb(20 184 166 / 0) !important;
}

.tw-border-e-teal-500\/10 {
  border-inline-end-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-e-teal-500\/100 {
  border-inline-end-color: rgb(20 184 166 / 1) !important;
}

.tw-border-e-teal-500\/15 {
  border-inline-end-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-e-teal-500\/20 {
  border-inline-end-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-e-teal-500\/25 {
  border-inline-end-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-e-teal-500\/30 {
  border-inline-end-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-e-teal-500\/35 {
  border-inline-end-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-e-teal-500\/40 {
  border-inline-end-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-e-teal-500\/45 {
  border-inline-end-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-e-teal-500\/5 {
  border-inline-end-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-e-teal-500\/50 {
  border-inline-end-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-e-teal-500\/55 {
  border-inline-end-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-e-teal-500\/60 {
  border-inline-end-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-e-teal-500\/65 {
  border-inline-end-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-e-teal-500\/70 {
  border-inline-end-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-e-teal-500\/75 {
  border-inline-end-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-e-teal-500\/80 {
  border-inline-end-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-e-teal-500\/85 {
  border-inline-end-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-e-teal-500\/90 {
  border-inline-end-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-e-teal-500\/95 {
  border-inline-end-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-e-teal-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-600\/0 {
  border-inline-end-color: rgb(13 148 136 / 0) !important;
}

.tw-border-e-teal-600\/10 {
  border-inline-end-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-e-teal-600\/100 {
  border-inline-end-color: rgb(13 148 136 / 1) !important;
}

.tw-border-e-teal-600\/15 {
  border-inline-end-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-e-teal-600\/20 {
  border-inline-end-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-e-teal-600\/25 {
  border-inline-end-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-e-teal-600\/30 {
  border-inline-end-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-e-teal-600\/35 {
  border-inline-end-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-e-teal-600\/40 {
  border-inline-end-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-e-teal-600\/45 {
  border-inline-end-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-e-teal-600\/5 {
  border-inline-end-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-e-teal-600\/50 {
  border-inline-end-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-e-teal-600\/55 {
  border-inline-end-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-e-teal-600\/60 {
  border-inline-end-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-e-teal-600\/65 {
  border-inline-end-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-e-teal-600\/70 {
  border-inline-end-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-e-teal-600\/75 {
  border-inline-end-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-e-teal-600\/80 {
  border-inline-end-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-e-teal-600\/85 {
  border-inline-end-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-e-teal-600\/90 {
  border-inline-end-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-e-teal-600\/95 {
  border-inline-end-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-e-teal-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-700\/0 {
  border-inline-end-color: rgb(15 118 110 / 0) !important;
}

.tw-border-e-teal-700\/10 {
  border-inline-end-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-e-teal-700\/100 {
  border-inline-end-color: rgb(15 118 110 / 1) !important;
}

.tw-border-e-teal-700\/15 {
  border-inline-end-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-e-teal-700\/20 {
  border-inline-end-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-e-teal-700\/25 {
  border-inline-end-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-e-teal-700\/30 {
  border-inline-end-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-e-teal-700\/35 {
  border-inline-end-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-e-teal-700\/40 {
  border-inline-end-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-e-teal-700\/45 {
  border-inline-end-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-e-teal-700\/5 {
  border-inline-end-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-e-teal-700\/50 {
  border-inline-end-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-e-teal-700\/55 {
  border-inline-end-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-e-teal-700\/60 {
  border-inline-end-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-e-teal-700\/65 {
  border-inline-end-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-e-teal-700\/70 {
  border-inline-end-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-e-teal-700\/75 {
  border-inline-end-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-e-teal-700\/80 {
  border-inline-end-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-e-teal-700\/85 {
  border-inline-end-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-e-teal-700\/90 {
  border-inline-end-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-e-teal-700\/95 {
  border-inline-end-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-e-teal-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-800\/0 {
  border-inline-end-color: rgb(17 94 89 / 0) !important;
}

.tw-border-e-teal-800\/10 {
  border-inline-end-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-e-teal-800\/100 {
  border-inline-end-color: rgb(17 94 89 / 1) !important;
}

.tw-border-e-teal-800\/15 {
  border-inline-end-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-e-teal-800\/20 {
  border-inline-end-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-e-teal-800\/25 {
  border-inline-end-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-e-teal-800\/30 {
  border-inline-end-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-e-teal-800\/35 {
  border-inline-end-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-e-teal-800\/40 {
  border-inline-end-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-e-teal-800\/45 {
  border-inline-end-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-e-teal-800\/5 {
  border-inline-end-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-e-teal-800\/50 {
  border-inline-end-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-e-teal-800\/55 {
  border-inline-end-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-e-teal-800\/60 {
  border-inline-end-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-e-teal-800\/65 {
  border-inline-end-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-e-teal-800\/70 {
  border-inline-end-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-e-teal-800\/75 {
  border-inline-end-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-e-teal-800\/80 {
  border-inline-end-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-e-teal-800\/85 {
  border-inline-end-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-e-teal-800\/90 {
  border-inline-end-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-e-teal-800\/95 {
  border-inline-end-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-e-teal-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-900\/0 {
  border-inline-end-color: rgb(19 78 74 / 0) !important;
}

.tw-border-e-teal-900\/10 {
  border-inline-end-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-e-teal-900\/100 {
  border-inline-end-color: rgb(19 78 74 / 1) !important;
}

.tw-border-e-teal-900\/15 {
  border-inline-end-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-e-teal-900\/20 {
  border-inline-end-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-e-teal-900\/25 {
  border-inline-end-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-e-teal-900\/30 {
  border-inline-end-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-e-teal-900\/35 {
  border-inline-end-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-e-teal-900\/40 {
  border-inline-end-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-e-teal-900\/45 {
  border-inline-end-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-e-teal-900\/5 {
  border-inline-end-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-e-teal-900\/50 {
  border-inline-end-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-e-teal-900\/55 {
  border-inline-end-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-e-teal-900\/60 {
  border-inline-end-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-e-teal-900\/65 {
  border-inline-end-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-e-teal-900\/70 {
  border-inline-end-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-e-teal-900\/75 {
  border-inline-end-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-e-teal-900\/80 {
  border-inline-end-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-e-teal-900\/85 {
  border-inline-end-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-e-teal-900\/90 {
  border-inline-end-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-e-teal-900\/95 {
  border-inline-end-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-e-teal-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-e-teal-950\/0 {
  border-inline-end-color: rgb(4 47 46 / 0) !important;
}

.tw-border-e-teal-950\/10 {
  border-inline-end-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-e-teal-950\/100 {
  border-inline-end-color: rgb(4 47 46 / 1) !important;
}

.tw-border-e-teal-950\/15 {
  border-inline-end-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-e-teal-950\/20 {
  border-inline-end-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-e-teal-950\/25 {
  border-inline-end-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-e-teal-950\/30 {
  border-inline-end-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-e-teal-950\/35 {
  border-inline-end-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-e-teal-950\/40 {
  border-inline-end-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-e-teal-950\/45 {
  border-inline-end-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-e-teal-950\/5 {
  border-inline-end-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-e-teal-950\/50 {
  border-inline-end-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-e-teal-950\/55 {
  border-inline-end-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-e-teal-950\/60 {
  border-inline-end-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-e-teal-950\/65 {
  border-inline-end-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-e-teal-950\/70 {
  border-inline-end-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-e-teal-950\/75 {
  border-inline-end-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-e-teal-950\/80 {
  border-inline-end-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-e-teal-950\/85 {
  border-inline-end-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-e-teal-950\/90 {
  border-inline-end-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-e-teal-950\/95 {
  border-inline-end-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-e-transparent {
  border-inline-end-color: transparent !important;
}

.tw-border-e-transparent\/0 {
  border-inline-end-color: rgb(0 0 0 / 0) !important;
}

.tw-border-e-transparent\/10 {
  border-inline-end-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-e-transparent\/100 {
  border-inline-end-color: rgb(0 0 0 / 1) !important;
}

.tw-border-e-transparent\/15 {
  border-inline-end-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-e-transparent\/20 {
  border-inline-end-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-e-transparent\/25 {
  border-inline-end-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-e-transparent\/30 {
  border-inline-end-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-e-transparent\/35 {
  border-inline-end-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-e-transparent\/40 {
  border-inline-end-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-e-transparent\/45 {
  border-inline-end-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-e-transparent\/5 {
  border-inline-end-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-e-transparent\/50 {
  border-inline-end-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-e-transparent\/55 {
  border-inline-end-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-e-transparent\/60 {
  border-inline-end-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-e-transparent\/65 {
  border-inline-end-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-e-transparent\/70 {
  border-inline-end-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-e-transparent\/75 {
  border-inline-end-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-e-transparent\/80 {
  border-inline-end-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-e-transparent\/85 {
  border-inline-end-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-e-transparent\/90 {
  border-inline-end-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-e-transparent\/95 {
  border-inline-end-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-e-violet-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-100\/0 {
  border-inline-end-color: rgb(237 233 254 / 0) !important;
}

.tw-border-e-violet-100\/10 {
  border-inline-end-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-e-violet-100\/100 {
  border-inline-end-color: rgb(237 233 254 / 1) !important;
}

.tw-border-e-violet-100\/15 {
  border-inline-end-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-e-violet-100\/20 {
  border-inline-end-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-e-violet-100\/25 {
  border-inline-end-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-e-violet-100\/30 {
  border-inline-end-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-e-violet-100\/35 {
  border-inline-end-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-e-violet-100\/40 {
  border-inline-end-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-e-violet-100\/45 {
  border-inline-end-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-e-violet-100\/5 {
  border-inline-end-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-e-violet-100\/50 {
  border-inline-end-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-e-violet-100\/55 {
  border-inline-end-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-e-violet-100\/60 {
  border-inline-end-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-e-violet-100\/65 {
  border-inline-end-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-e-violet-100\/70 {
  border-inline-end-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-e-violet-100\/75 {
  border-inline-end-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-e-violet-100\/80 {
  border-inline-end-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-e-violet-100\/85 {
  border-inline-end-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-e-violet-100\/90 {
  border-inline-end-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-e-violet-100\/95 {
  border-inline-end-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-e-violet-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-200\/0 {
  border-inline-end-color: rgb(221 214 254 / 0) !important;
}

.tw-border-e-violet-200\/10 {
  border-inline-end-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-e-violet-200\/100 {
  border-inline-end-color: rgb(221 214 254 / 1) !important;
}

.tw-border-e-violet-200\/15 {
  border-inline-end-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-e-violet-200\/20 {
  border-inline-end-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-e-violet-200\/25 {
  border-inline-end-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-e-violet-200\/30 {
  border-inline-end-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-e-violet-200\/35 {
  border-inline-end-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-e-violet-200\/40 {
  border-inline-end-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-e-violet-200\/45 {
  border-inline-end-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-e-violet-200\/5 {
  border-inline-end-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-e-violet-200\/50 {
  border-inline-end-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-e-violet-200\/55 {
  border-inline-end-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-e-violet-200\/60 {
  border-inline-end-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-e-violet-200\/65 {
  border-inline-end-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-e-violet-200\/70 {
  border-inline-end-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-e-violet-200\/75 {
  border-inline-end-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-e-violet-200\/80 {
  border-inline-end-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-e-violet-200\/85 {
  border-inline-end-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-e-violet-200\/90 {
  border-inline-end-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-e-violet-200\/95 {
  border-inline-end-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-e-violet-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-300\/0 {
  border-inline-end-color: rgb(196 181 253 / 0) !important;
}

.tw-border-e-violet-300\/10 {
  border-inline-end-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-e-violet-300\/100 {
  border-inline-end-color: rgb(196 181 253 / 1) !important;
}

.tw-border-e-violet-300\/15 {
  border-inline-end-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-e-violet-300\/20 {
  border-inline-end-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-e-violet-300\/25 {
  border-inline-end-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-e-violet-300\/30 {
  border-inline-end-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-e-violet-300\/35 {
  border-inline-end-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-e-violet-300\/40 {
  border-inline-end-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-e-violet-300\/45 {
  border-inline-end-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-e-violet-300\/5 {
  border-inline-end-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-e-violet-300\/50 {
  border-inline-end-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-e-violet-300\/55 {
  border-inline-end-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-e-violet-300\/60 {
  border-inline-end-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-e-violet-300\/65 {
  border-inline-end-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-e-violet-300\/70 {
  border-inline-end-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-e-violet-300\/75 {
  border-inline-end-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-e-violet-300\/80 {
  border-inline-end-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-e-violet-300\/85 {
  border-inline-end-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-e-violet-300\/90 {
  border-inline-end-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-e-violet-300\/95 {
  border-inline-end-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-e-violet-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-400\/0 {
  border-inline-end-color: rgb(167 139 250 / 0) !important;
}

.tw-border-e-violet-400\/10 {
  border-inline-end-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-e-violet-400\/100 {
  border-inline-end-color: rgb(167 139 250 / 1) !important;
}

.tw-border-e-violet-400\/15 {
  border-inline-end-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-e-violet-400\/20 {
  border-inline-end-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-e-violet-400\/25 {
  border-inline-end-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-e-violet-400\/30 {
  border-inline-end-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-e-violet-400\/35 {
  border-inline-end-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-e-violet-400\/40 {
  border-inline-end-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-e-violet-400\/45 {
  border-inline-end-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-e-violet-400\/5 {
  border-inline-end-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-e-violet-400\/50 {
  border-inline-end-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-e-violet-400\/55 {
  border-inline-end-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-e-violet-400\/60 {
  border-inline-end-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-e-violet-400\/65 {
  border-inline-end-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-e-violet-400\/70 {
  border-inline-end-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-e-violet-400\/75 {
  border-inline-end-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-e-violet-400\/80 {
  border-inline-end-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-e-violet-400\/85 {
  border-inline-end-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-e-violet-400\/90 {
  border-inline-end-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-e-violet-400\/95 {
  border-inline-end-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-e-violet-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-50\/0 {
  border-inline-end-color: rgb(245 243 255 / 0) !important;
}

.tw-border-e-violet-50\/10 {
  border-inline-end-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-e-violet-50\/100 {
  border-inline-end-color: rgb(245 243 255 / 1) !important;
}

.tw-border-e-violet-50\/15 {
  border-inline-end-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-e-violet-50\/20 {
  border-inline-end-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-e-violet-50\/25 {
  border-inline-end-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-e-violet-50\/30 {
  border-inline-end-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-e-violet-50\/35 {
  border-inline-end-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-e-violet-50\/40 {
  border-inline-end-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-e-violet-50\/45 {
  border-inline-end-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-e-violet-50\/5 {
  border-inline-end-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-e-violet-50\/50 {
  border-inline-end-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-e-violet-50\/55 {
  border-inline-end-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-e-violet-50\/60 {
  border-inline-end-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-e-violet-50\/65 {
  border-inline-end-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-e-violet-50\/70 {
  border-inline-end-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-e-violet-50\/75 {
  border-inline-end-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-e-violet-50\/80 {
  border-inline-end-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-e-violet-50\/85 {
  border-inline-end-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-e-violet-50\/90 {
  border-inline-end-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-e-violet-50\/95 {
  border-inline-end-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-e-violet-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-500\/0 {
  border-inline-end-color: rgb(139 92 246 / 0) !important;
}

.tw-border-e-violet-500\/10 {
  border-inline-end-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-e-violet-500\/100 {
  border-inline-end-color: rgb(139 92 246 / 1) !important;
}

.tw-border-e-violet-500\/15 {
  border-inline-end-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-e-violet-500\/20 {
  border-inline-end-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-e-violet-500\/25 {
  border-inline-end-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-e-violet-500\/30 {
  border-inline-end-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-e-violet-500\/35 {
  border-inline-end-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-e-violet-500\/40 {
  border-inline-end-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-e-violet-500\/45 {
  border-inline-end-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-e-violet-500\/5 {
  border-inline-end-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-e-violet-500\/50 {
  border-inline-end-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-e-violet-500\/55 {
  border-inline-end-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-e-violet-500\/60 {
  border-inline-end-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-e-violet-500\/65 {
  border-inline-end-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-e-violet-500\/70 {
  border-inline-end-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-e-violet-500\/75 {
  border-inline-end-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-e-violet-500\/80 {
  border-inline-end-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-e-violet-500\/85 {
  border-inline-end-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-e-violet-500\/90 {
  border-inline-end-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-e-violet-500\/95 {
  border-inline-end-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-e-violet-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-600\/0 {
  border-inline-end-color: rgb(124 58 237 / 0) !important;
}

.tw-border-e-violet-600\/10 {
  border-inline-end-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-e-violet-600\/100 {
  border-inline-end-color: rgb(124 58 237 / 1) !important;
}

.tw-border-e-violet-600\/15 {
  border-inline-end-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-e-violet-600\/20 {
  border-inline-end-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-e-violet-600\/25 {
  border-inline-end-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-e-violet-600\/30 {
  border-inline-end-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-e-violet-600\/35 {
  border-inline-end-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-e-violet-600\/40 {
  border-inline-end-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-e-violet-600\/45 {
  border-inline-end-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-e-violet-600\/5 {
  border-inline-end-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-e-violet-600\/50 {
  border-inline-end-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-e-violet-600\/55 {
  border-inline-end-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-e-violet-600\/60 {
  border-inline-end-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-e-violet-600\/65 {
  border-inline-end-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-e-violet-600\/70 {
  border-inline-end-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-e-violet-600\/75 {
  border-inline-end-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-e-violet-600\/80 {
  border-inline-end-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-e-violet-600\/85 {
  border-inline-end-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-e-violet-600\/90 {
  border-inline-end-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-e-violet-600\/95 {
  border-inline-end-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-e-violet-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-700\/0 {
  border-inline-end-color: rgb(109 40 217 / 0) !important;
}

.tw-border-e-violet-700\/10 {
  border-inline-end-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-e-violet-700\/100 {
  border-inline-end-color: rgb(109 40 217 / 1) !important;
}

.tw-border-e-violet-700\/15 {
  border-inline-end-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-e-violet-700\/20 {
  border-inline-end-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-e-violet-700\/25 {
  border-inline-end-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-e-violet-700\/30 {
  border-inline-end-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-e-violet-700\/35 {
  border-inline-end-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-e-violet-700\/40 {
  border-inline-end-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-e-violet-700\/45 {
  border-inline-end-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-e-violet-700\/5 {
  border-inline-end-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-e-violet-700\/50 {
  border-inline-end-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-e-violet-700\/55 {
  border-inline-end-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-e-violet-700\/60 {
  border-inline-end-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-e-violet-700\/65 {
  border-inline-end-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-e-violet-700\/70 {
  border-inline-end-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-e-violet-700\/75 {
  border-inline-end-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-e-violet-700\/80 {
  border-inline-end-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-e-violet-700\/85 {
  border-inline-end-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-e-violet-700\/90 {
  border-inline-end-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-e-violet-700\/95 {
  border-inline-end-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-e-violet-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-800\/0 {
  border-inline-end-color: rgb(91 33 182 / 0) !important;
}

.tw-border-e-violet-800\/10 {
  border-inline-end-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-e-violet-800\/100 {
  border-inline-end-color: rgb(91 33 182 / 1) !important;
}

.tw-border-e-violet-800\/15 {
  border-inline-end-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-e-violet-800\/20 {
  border-inline-end-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-e-violet-800\/25 {
  border-inline-end-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-e-violet-800\/30 {
  border-inline-end-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-e-violet-800\/35 {
  border-inline-end-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-e-violet-800\/40 {
  border-inline-end-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-e-violet-800\/45 {
  border-inline-end-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-e-violet-800\/5 {
  border-inline-end-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-e-violet-800\/50 {
  border-inline-end-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-e-violet-800\/55 {
  border-inline-end-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-e-violet-800\/60 {
  border-inline-end-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-e-violet-800\/65 {
  border-inline-end-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-e-violet-800\/70 {
  border-inline-end-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-e-violet-800\/75 {
  border-inline-end-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-e-violet-800\/80 {
  border-inline-end-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-e-violet-800\/85 {
  border-inline-end-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-e-violet-800\/90 {
  border-inline-end-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-e-violet-800\/95 {
  border-inline-end-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-e-violet-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-900\/0 {
  border-inline-end-color: rgb(76 29 149 / 0) !important;
}

.tw-border-e-violet-900\/10 {
  border-inline-end-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-e-violet-900\/100 {
  border-inline-end-color: rgb(76 29 149 / 1) !important;
}

.tw-border-e-violet-900\/15 {
  border-inline-end-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-e-violet-900\/20 {
  border-inline-end-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-e-violet-900\/25 {
  border-inline-end-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-e-violet-900\/30 {
  border-inline-end-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-e-violet-900\/35 {
  border-inline-end-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-e-violet-900\/40 {
  border-inline-end-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-e-violet-900\/45 {
  border-inline-end-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-e-violet-900\/5 {
  border-inline-end-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-e-violet-900\/50 {
  border-inline-end-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-e-violet-900\/55 {
  border-inline-end-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-e-violet-900\/60 {
  border-inline-end-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-e-violet-900\/65 {
  border-inline-end-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-e-violet-900\/70 {
  border-inline-end-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-e-violet-900\/75 {
  border-inline-end-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-e-violet-900\/80 {
  border-inline-end-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-e-violet-900\/85 {
  border-inline-end-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-e-violet-900\/90 {
  border-inline-end-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-e-violet-900\/95 {
  border-inline-end-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-e-violet-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-e-violet-950\/0 {
  border-inline-end-color: rgb(46 16 101 / 0) !important;
}

.tw-border-e-violet-950\/10 {
  border-inline-end-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-e-violet-950\/100 {
  border-inline-end-color: rgb(46 16 101 / 1) !important;
}

.tw-border-e-violet-950\/15 {
  border-inline-end-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-e-violet-950\/20 {
  border-inline-end-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-e-violet-950\/25 {
  border-inline-end-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-e-violet-950\/30 {
  border-inline-end-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-e-violet-950\/35 {
  border-inline-end-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-e-violet-950\/40 {
  border-inline-end-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-e-violet-950\/45 {
  border-inline-end-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-e-violet-950\/5 {
  border-inline-end-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-e-violet-950\/50 {
  border-inline-end-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-e-violet-950\/55 {
  border-inline-end-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-e-violet-950\/60 {
  border-inline-end-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-e-violet-950\/65 {
  border-inline-end-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-e-violet-950\/70 {
  border-inline-end-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-e-violet-950\/75 {
  border-inline-end-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-e-violet-950\/80 {
  border-inline-end-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-e-violet-950\/85 {
  border-inline-end-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-e-violet-950\/90 {
  border-inline-end-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-e-violet-950\/95 {
  border-inline-end-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-e-white {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-e-white\/0 {
  border-inline-end-color: rgb(255 255 255 / 0) !important;
}

.tw-border-e-white\/10 {
  border-inline-end-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-e-white\/100 {
  border-inline-end-color: rgb(255 255 255 / 1) !important;
}

.tw-border-e-white\/15 {
  border-inline-end-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-e-white\/20 {
  border-inline-end-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-e-white\/25 {
  border-inline-end-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-e-white\/30 {
  border-inline-end-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-e-white\/35 {
  border-inline-end-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-e-white\/40 {
  border-inline-end-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-e-white\/45 {
  border-inline-end-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-e-white\/5 {
  border-inline-end-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-e-white\/50 {
  border-inline-end-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-e-white\/55 {
  border-inline-end-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-e-white\/60 {
  border-inline-end-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-e-white\/65 {
  border-inline-end-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-e-white\/70 {
  border-inline-end-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-e-white\/75 {
  border-inline-end-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-e-white\/80 {
  border-inline-end-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-e-white\/85 {
  border-inline-end-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-e-white\/90 {
  border-inline-end-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-e-white\/95 {
  border-inline-end-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-e-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-100\/0 {
  border-inline-end-color: rgb(254 249 195 / 0) !important;
}

.tw-border-e-yellow-100\/10 {
  border-inline-end-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-e-yellow-100\/100 {
  border-inline-end-color: rgb(254 249 195 / 1) !important;
}

.tw-border-e-yellow-100\/15 {
  border-inline-end-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-e-yellow-100\/20 {
  border-inline-end-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-e-yellow-100\/25 {
  border-inline-end-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-e-yellow-100\/30 {
  border-inline-end-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-e-yellow-100\/35 {
  border-inline-end-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-e-yellow-100\/40 {
  border-inline-end-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-e-yellow-100\/45 {
  border-inline-end-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-e-yellow-100\/5 {
  border-inline-end-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-e-yellow-100\/50 {
  border-inline-end-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-e-yellow-100\/55 {
  border-inline-end-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-e-yellow-100\/60 {
  border-inline-end-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-e-yellow-100\/65 {
  border-inline-end-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-e-yellow-100\/70 {
  border-inline-end-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-e-yellow-100\/75 {
  border-inline-end-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-e-yellow-100\/80 {
  border-inline-end-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-e-yellow-100\/85 {
  border-inline-end-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-e-yellow-100\/90 {
  border-inline-end-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-e-yellow-100\/95 {
  border-inline-end-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-e-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-200\/0 {
  border-inline-end-color: rgb(254 240 138 / 0) !important;
}

.tw-border-e-yellow-200\/10 {
  border-inline-end-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-e-yellow-200\/100 {
  border-inline-end-color: rgb(254 240 138 / 1) !important;
}

.tw-border-e-yellow-200\/15 {
  border-inline-end-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-e-yellow-200\/20 {
  border-inline-end-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-e-yellow-200\/25 {
  border-inline-end-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-e-yellow-200\/30 {
  border-inline-end-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-e-yellow-200\/35 {
  border-inline-end-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-e-yellow-200\/40 {
  border-inline-end-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-e-yellow-200\/45 {
  border-inline-end-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-e-yellow-200\/5 {
  border-inline-end-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-e-yellow-200\/50 {
  border-inline-end-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-e-yellow-200\/55 {
  border-inline-end-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-e-yellow-200\/60 {
  border-inline-end-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-e-yellow-200\/65 {
  border-inline-end-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-e-yellow-200\/70 {
  border-inline-end-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-e-yellow-200\/75 {
  border-inline-end-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-e-yellow-200\/80 {
  border-inline-end-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-e-yellow-200\/85 {
  border-inline-end-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-e-yellow-200\/90 {
  border-inline-end-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-e-yellow-200\/95 {
  border-inline-end-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-e-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-300\/0 {
  border-inline-end-color: rgb(253 224 71 / 0) !important;
}

.tw-border-e-yellow-300\/10 {
  border-inline-end-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-e-yellow-300\/100 {
  border-inline-end-color: rgb(253 224 71 / 1) !important;
}

.tw-border-e-yellow-300\/15 {
  border-inline-end-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-e-yellow-300\/20 {
  border-inline-end-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-e-yellow-300\/25 {
  border-inline-end-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-e-yellow-300\/30 {
  border-inline-end-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-e-yellow-300\/35 {
  border-inline-end-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-e-yellow-300\/40 {
  border-inline-end-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-e-yellow-300\/45 {
  border-inline-end-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-e-yellow-300\/5 {
  border-inline-end-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-e-yellow-300\/50 {
  border-inline-end-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-e-yellow-300\/55 {
  border-inline-end-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-e-yellow-300\/60 {
  border-inline-end-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-e-yellow-300\/65 {
  border-inline-end-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-e-yellow-300\/70 {
  border-inline-end-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-e-yellow-300\/75 {
  border-inline-end-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-e-yellow-300\/80 {
  border-inline-end-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-e-yellow-300\/85 {
  border-inline-end-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-e-yellow-300\/90 {
  border-inline-end-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-e-yellow-300\/95 {
  border-inline-end-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-e-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-400\/0 {
  border-inline-end-color: rgb(250 204 21 / 0) !important;
}

.tw-border-e-yellow-400\/10 {
  border-inline-end-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-e-yellow-400\/100 {
  border-inline-end-color: rgb(250 204 21 / 1) !important;
}

.tw-border-e-yellow-400\/15 {
  border-inline-end-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-e-yellow-400\/20 {
  border-inline-end-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-e-yellow-400\/25 {
  border-inline-end-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-e-yellow-400\/30 {
  border-inline-end-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-e-yellow-400\/35 {
  border-inline-end-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-e-yellow-400\/40 {
  border-inline-end-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-e-yellow-400\/45 {
  border-inline-end-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-e-yellow-400\/5 {
  border-inline-end-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-e-yellow-400\/50 {
  border-inline-end-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-e-yellow-400\/55 {
  border-inline-end-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-e-yellow-400\/60 {
  border-inline-end-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-e-yellow-400\/65 {
  border-inline-end-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-e-yellow-400\/70 {
  border-inline-end-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-e-yellow-400\/75 {
  border-inline-end-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-e-yellow-400\/80 {
  border-inline-end-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-e-yellow-400\/85 {
  border-inline-end-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-e-yellow-400\/90 {
  border-inline-end-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-e-yellow-400\/95 {
  border-inline-end-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-e-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-50\/0 {
  border-inline-end-color: rgb(254 252 232 / 0) !important;
}

.tw-border-e-yellow-50\/10 {
  border-inline-end-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-e-yellow-50\/100 {
  border-inline-end-color: rgb(254 252 232 / 1) !important;
}

.tw-border-e-yellow-50\/15 {
  border-inline-end-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-e-yellow-50\/20 {
  border-inline-end-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-e-yellow-50\/25 {
  border-inline-end-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-e-yellow-50\/30 {
  border-inline-end-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-e-yellow-50\/35 {
  border-inline-end-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-e-yellow-50\/40 {
  border-inline-end-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-e-yellow-50\/45 {
  border-inline-end-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-e-yellow-50\/5 {
  border-inline-end-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-e-yellow-50\/50 {
  border-inline-end-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-e-yellow-50\/55 {
  border-inline-end-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-e-yellow-50\/60 {
  border-inline-end-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-e-yellow-50\/65 {
  border-inline-end-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-e-yellow-50\/70 {
  border-inline-end-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-e-yellow-50\/75 {
  border-inline-end-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-e-yellow-50\/80 {
  border-inline-end-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-e-yellow-50\/85 {
  border-inline-end-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-e-yellow-50\/90 {
  border-inline-end-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-e-yellow-50\/95 {
  border-inline-end-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-e-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-500\/0 {
  border-inline-end-color: rgb(234 179 8 / 0) !important;
}

.tw-border-e-yellow-500\/10 {
  border-inline-end-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-e-yellow-500\/100 {
  border-inline-end-color: rgb(234 179 8 / 1) !important;
}

.tw-border-e-yellow-500\/15 {
  border-inline-end-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-e-yellow-500\/20 {
  border-inline-end-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-e-yellow-500\/25 {
  border-inline-end-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-e-yellow-500\/30 {
  border-inline-end-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-e-yellow-500\/35 {
  border-inline-end-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-e-yellow-500\/40 {
  border-inline-end-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-e-yellow-500\/45 {
  border-inline-end-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-e-yellow-500\/5 {
  border-inline-end-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-e-yellow-500\/50 {
  border-inline-end-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-e-yellow-500\/55 {
  border-inline-end-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-e-yellow-500\/60 {
  border-inline-end-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-e-yellow-500\/65 {
  border-inline-end-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-e-yellow-500\/70 {
  border-inline-end-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-e-yellow-500\/75 {
  border-inline-end-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-e-yellow-500\/80 {
  border-inline-end-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-e-yellow-500\/85 {
  border-inline-end-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-e-yellow-500\/90 {
  border-inline-end-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-e-yellow-500\/95 {
  border-inline-end-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-e-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-600\/0 {
  border-inline-end-color: rgb(202 138 4 / 0) !important;
}

.tw-border-e-yellow-600\/10 {
  border-inline-end-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-e-yellow-600\/100 {
  border-inline-end-color: rgb(202 138 4 / 1) !important;
}

.tw-border-e-yellow-600\/15 {
  border-inline-end-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-e-yellow-600\/20 {
  border-inline-end-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-e-yellow-600\/25 {
  border-inline-end-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-e-yellow-600\/30 {
  border-inline-end-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-e-yellow-600\/35 {
  border-inline-end-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-e-yellow-600\/40 {
  border-inline-end-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-e-yellow-600\/45 {
  border-inline-end-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-e-yellow-600\/5 {
  border-inline-end-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-e-yellow-600\/50 {
  border-inline-end-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-e-yellow-600\/55 {
  border-inline-end-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-e-yellow-600\/60 {
  border-inline-end-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-e-yellow-600\/65 {
  border-inline-end-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-e-yellow-600\/70 {
  border-inline-end-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-e-yellow-600\/75 {
  border-inline-end-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-e-yellow-600\/80 {
  border-inline-end-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-e-yellow-600\/85 {
  border-inline-end-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-e-yellow-600\/90 {
  border-inline-end-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-e-yellow-600\/95 {
  border-inline-end-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-e-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-700\/0 {
  border-inline-end-color: rgb(161 98 7 / 0) !important;
}

.tw-border-e-yellow-700\/10 {
  border-inline-end-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-e-yellow-700\/100 {
  border-inline-end-color: rgb(161 98 7 / 1) !important;
}

.tw-border-e-yellow-700\/15 {
  border-inline-end-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-e-yellow-700\/20 {
  border-inline-end-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-e-yellow-700\/25 {
  border-inline-end-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-e-yellow-700\/30 {
  border-inline-end-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-e-yellow-700\/35 {
  border-inline-end-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-e-yellow-700\/40 {
  border-inline-end-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-e-yellow-700\/45 {
  border-inline-end-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-e-yellow-700\/5 {
  border-inline-end-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-e-yellow-700\/50 {
  border-inline-end-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-e-yellow-700\/55 {
  border-inline-end-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-e-yellow-700\/60 {
  border-inline-end-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-e-yellow-700\/65 {
  border-inline-end-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-e-yellow-700\/70 {
  border-inline-end-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-e-yellow-700\/75 {
  border-inline-end-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-e-yellow-700\/80 {
  border-inline-end-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-e-yellow-700\/85 {
  border-inline-end-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-e-yellow-700\/90 {
  border-inline-end-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-e-yellow-700\/95 {
  border-inline-end-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-e-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-800\/0 {
  border-inline-end-color: rgb(133 77 14 / 0) !important;
}

.tw-border-e-yellow-800\/10 {
  border-inline-end-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-e-yellow-800\/100 {
  border-inline-end-color: rgb(133 77 14 / 1) !important;
}

.tw-border-e-yellow-800\/15 {
  border-inline-end-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-e-yellow-800\/20 {
  border-inline-end-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-e-yellow-800\/25 {
  border-inline-end-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-e-yellow-800\/30 {
  border-inline-end-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-e-yellow-800\/35 {
  border-inline-end-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-e-yellow-800\/40 {
  border-inline-end-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-e-yellow-800\/45 {
  border-inline-end-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-e-yellow-800\/5 {
  border-inline-end-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-e-yellow-800\/50 {
  border-inline-end-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-e-yellow-800\/55 {
  border-inline-end-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-e-yellow-800\/60 {
  border-inline-end-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-e-yellow-800\/65 {
  border-inline-end-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-e-yellow-800\/70 {
  border-inline-end-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-e-yellow-800\/75 {
  border-inline-end-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-e-yellow-800\/80 {
  border-inline-end-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-e-yellow-800\/85 {
  border-inline-end-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-e-yellow-800\/90 {
  border-inline-end-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-e-yellow-800\/95 {
  border-inline-end-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-e-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-900\/0 {
  border-inline-end-color: rgb(113 63 18 / 0) !important;
}

.tw-border-e-yellow-900\/10 {
  border-inline-end-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-e-yellow-900\/100 {
  border-inline-end-color: rgb(113 63 18 / 1) !important;
}

.tw-border-e-yellow-900\/15 {
  border-inline-end-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-e-yellow-900\/20 {
  border-inline-end-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-e-yellow-900\/25 {
  border-inline-end-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-e-yellow-900\/30 {
  border-inline-end-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-e-yellow-900\/35 {
  border-inline-end-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-e-yellow-900\/40 {
  border-inline-end-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-e-yellow-900\/45 {
  border-inline-end-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-e-yellow-900\/5 {
  border-inline-end-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-e-yellow-900\/50 {
  border-inline-end-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-e-yellow-900\/55 {
  border-inline-end-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-e-yellow-900\/60 {
  border-inline-end-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-e-yellow-900\/65 {
  border-inline-end-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-e-yellow-900\/70 {
  border-inline-end-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-e-yellow-900\/75 {
  border-inline-end-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-e-yellow-900\/80 {
  border-inline-end-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-e-yellow-900\/85 {
  border-inline-end-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-e-yellow-900\/90 {
  border-inline-end-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-e-yellow-900\/95 {
  border-inline-end-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-e-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-e-yellow-950\/0 {
  border-inline-end-color: rgb(66 32 6 / 0) !important;
}

.tw-border-e-yellow-950\/10 {
  border-inline-end-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-e-yellow-950\/100 {
  border-inline-end-color: rgb(66 32 6 / 1) !important;
}

.tw-border-e-yellow-950\/15 {
  border-inline-end-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-e-yellow-950\/20 {
  border-inline-end-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-e-yellow-950\/25 {
  border-inline-end-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-e-yellow-950\/30 {
  border-inline-end-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-e-yellow-950\/35 {
  border-inline-end-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-e-yellow-950\/40 {
  border-inline-end-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-e-yellow-950\/45 {
  border-inline-end-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-e-yellow-950\/5 {
  border-inline-end-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-e-yellow-950\/50 {
  border-inline-end-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-e-yellow-950\/55 {
  border-inline-end-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-e-yellow-950\/60 {
  border-inline-end-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-e-yellow-950\/65 {
  border-inline-end-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-e-yellow-950\/70 {
  border-inline-end-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-e-yellow-950\/75 {
  border-inline-end-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-e-yellow-950\/80 {
  border-inline-end-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-e-yellow-950\/85 {
  border-inline-end-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-e-yellow-950\/90 {
  border-inline-end-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-e-yellow-950\/95 {
  border-inline-end-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-e-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-100\/0 {
  border-inline-end-color: rgb(244 244 245 / 0) !important;
}

.tw-border-e-zinc-100\/10 {
  border-inline-end-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-e-zinc-100\/100 {
  border-inline-end-color: rgb(244 244 245 / 1) !important;
}

.tw-border-e-zinc-100\/15 {
  border-inline-end-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-e-zinc-100\/20 {
  border-inline-end-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-e-zinc-100\/25 {
  border-inline-end-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-e-zinc-100\/30 {
  border-inline-end-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-e-zinc-100\/35 {
  border-inline-end-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-e-zinc-100\/40 {
  border-inline-end-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-e-zinc-100\/45 {
  border-inline-end-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-e-zinc-100\/5 {
  border-inline-end-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-e-zinc-100\/50 {
  border-inline-end-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-e-zinc-100\/55 {
  border-inline-end-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-e-zinc-100\/60 {
  border-inline-end-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-e-zinc-100\/65 {
  border-inline-end-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-e-zinc-100\/70 {
  border-inline-end-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-e-zinc-100\/75 {
  border-inline-end-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-e-zinc-100\/80 {
  border-inline-end-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-e-zinc-100\/85 {
  border-inline-end-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-e-zinc-100\/90 {
  border-inline-end-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-e-zinc-100\/95 {
  border-inline-end-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-e-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-200\/0 {
  border-inline-end-color: rgb(228 228 231 / 0) !important;
}

.tw-border-e-zinc-200\/10 {
  border-inline-end-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-e-zinc-200\/100 {
  border-inline-end-color: rgb(228 228 231 / 1) !important;
}

.tw-border-e-zinc-200\/15 {
  border-inline-end-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-e-zinc-200\/20 {
  border-inline-end-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-e-zinc-200\/25 {
  border-inline-end-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-e-zinc-200\/30 {
  border-inline-end-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-e-zinc-200\/35 {
  border-inline-end-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-e-zinc-200\/40 {
  border-inline-end-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-e-zinc-200\/45 {
  border-inline-end-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-e-zinc-200\/5 {
  border-inline-end-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-e-zinc-200\/50 {
  border-inline-end-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-e-zinc-200\/55 {
  border-inline-end-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-e-zinc-200\/60 {
  border-inline-end-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-e-zinc-200\/65 {
  border-inline-end-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-e-zinc-200\/70 {
  border-inline-end-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-e-zinc-200\/75 {
  border-inline-end-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-e-zinc-200\/80 {
  border-inline-end-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-e-zinc-200\/85 {
  border-inline-end-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-e-zinc-200\/90 {
  border-inline-end-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-e-zinc-200\/95 {
  border-inline-end-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-e-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-300\/0 {
  border-inline-end-color: rgb(212 212 216 / 0) !important;
}

.tw-border-e-zinc-300\/10 {
  border-inline-end-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-e-zinc-300\/100 {
  border-inline-end-color: rgb(212 212 216 / 1) !important;
}

.tw-border-e-zinc-300\/15 {
  border-inline-end-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-e-zinc-300\/20 {
  border-inline-end-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-e-zinc-300\/25 {
  border-inline-end-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-e-zinc-300\/30 {
  border-inline-end-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-e-zinc-300\/35 {
  border-inline-end-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-e-zinc-300\/40 {
  border-inline-end-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-e-zinc-300\/45 {
  border-inline-end-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-e-zinc-300\/5 {
  border-inline-end-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-e-zinc-300\/50 {
  border-inline-end-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-e-zinc-300\/55 {
  border-inline-end-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-e-zinc-300\/60 {
  border-inline-end-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-e-zinc-300\/65 {
  border-inline-end-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-e-zinc-300\/70 {
  border-inline-end-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-e-zinc-300\/75 {
  border-inline-end-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-e-zinc-300\/80 {
  border-inline-end-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-e-zinc-300\/85 {
  border-inline-end-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-e-zinc-300\/90 {
  border-inline-end-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-e-zinc-300\/95 {
  border-inline-end-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-e-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-400\/0 {
  border-inline-end-color: rgb(161 161 170 / 0) !important;
}

.tw-border-e-zinc-400\/10 {
  border-inline-end-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-e-zinc-400\/100 {
  border-inline-end-color: rgb(161 161 170 / 1) !important;
}

.tw-border-e-zinc-400\/15 {
  border-inline-end-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-e-zinc-400\/20 {
  border-inline-end-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-e-zinc-400\/25 {
  border-inline-end-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-e-zinc-400\/30 {
  border-inline-end-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-e-zinc-400\/35 {
  border-inline-end-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-e-zinc-400\/40 {
  border-inline-end-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-e-zinc-400\/45 {
  border-inline-end-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-e-zinc-400\/5 {
  border-inline-end-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-e-zinc-400\/50 {
  border-inline-end-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-e-zinc-400\/55 {
  border-inline-end-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-e-zinc-400\/60 {
  border-inline-end-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-e-zinc-400\/65 {
  border-inline-end-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-e-zinc-400\/70 {
  border-inline-end-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-e-zinc-400\/75 {
  border-inline-end-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-e-zinc-400\/80 {
  border-inline-end-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-e-zinc-400\/85 {
  border-inline-end-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-e-zinc-400\/90 {
  border-inline-end-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-e-zinc-400\/95 {
  border-inline-end-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-e-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-50\/0 {
  border-inline-end-color: rgb(250 250 250 / 0) !important;
}

.tw-border-e-zinc-50\/10 {
  border-inline-end-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-e-zinc-50\/100 {
  border-inline-end-color: rgb(250 250 250 / 1) !important;
}

.tw-border-e-zinc-50\/15 {
  border-inline-end-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-e-zinc-50\/20 {
  border-inline-end-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-e-zinc-50\/25 {
  border-inline-end-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-e-zinc-50\/30 {
  border-inline-end-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-e-zinc-50\/35 {
  border-inline-end-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-e-zinc-50\/40 {
  border-inline-end-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-e-zinc-50\/45 {
  border-inline-end-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-e-zinc-50\/5 {
  border-inline-end-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-e-zinc-50\/50 {
  border-inline-end-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-e-zinc-50\/55 {
  border-inline-end-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-e-zinc-50\/60 {
  border-inline-end-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-e-zinc-50\/65 {
  border-inline-end-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-e-zinc-50\/70 {
  border-inline-end-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-e-zinc-50\/75 {
  border-inline-end-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-e-zinc-50\/80 {
  border-inline-end-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-e-zinc-50\/85 {
  border-inline-end-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-e-zinc-50\/90 {
  border-inline-end-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-e-zinc-50\/95 {
  border-inline-end-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-e-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-500\/0 {
  border-inline-end-color: rgb(113 113 122 / 0) !important;
}

.tw-border-e-zinc-500\/10 {
  border-inline-end-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-e-zinc-500\/100 {
  border-inline-end-color: rgb(113 113 122 / 1) !important;
}

.tw-border-e-zinc-500\/15 {
  border-inline-end-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-e-zinc-500\/20 {
  border-inline-end-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-e-zinc-500\/25 {
  border-inline-end-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-e-zinc-500\/30 {
  border-inline-end-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-e-zinc-500\/35 {
  border-inline-end-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-e-zinc-500\/40 {
  border-inline-end-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-e-zinc-500\/45 {
  border-inline-end-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-e-zinc-500\/5 {
  border-inline-end-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-e-zinc-500\/50 {
  border-inline-end-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-e-zinc-500\/55 {
  border-inline-end-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-e-zinc-500\/60 {
  border-inline-end-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-e-zinc-500\/65 {
  border-inline-end-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-e-zinc-500\/70 {
  border-inline-end-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-e-zinc-500\/75 {
  border-inline-end-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-e-zinc-500\/80 {
  border-inline-end-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-e-zinc-500\/85 {
  border-inline-end-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-e-zinc-500\/90 {
  border-inline-end-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-e-zinc-500\/95 {
  border-inline-end-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-e-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-600\/0 {
  border-inline-end-color: rgb(82 82 91 / 0) !important;
}

.tw-border-e-zinc-600\/10 {
  border-inline-end-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-e-zinc-600\/100 {
  border-inline-end-color: rgb(82 82 91 / 1) !important;
}

.tw-border-e-zinc-600\/15 {
  border-inline-end-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-e-zinc-600\/20 {
  border-inline-end-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-e-zinc-600\/25 {
  border-inline-end-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-e-zinc-600\/30 {
  border-inline-end-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-e-zinc-600\/35 {
  border-inline-end-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-e-zinc-600\/40 {
  border-inline-end-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-e-zinc-600\/45 {
  border-inline-end-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-e-zinc-600\/5 {
  border-inline-end-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-e-zinc-600\/50 {
  border-inline-end-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-e-zinc-600\/55 {
  border-inline-end-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-e-zinc-600\/60 {
  border-inline-end-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-e-zinc-600\/65 {
  border-inline-end-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-e-zinc-600\/70 {
  border-inline-end-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-e-zinc-600\/75 {
  border-inline-end-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-e-zinc-600\/80 {
  border-inline-end-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-e-zinc-600\/85 {
  border-inline-end-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-e-zinc-600\/90 {
  border-inline-end-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-e-zinc-600\/95 {
  border-inline-end-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-e-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-700\/0 {
  border-inline-end-color: rgb(63 63 70 / 0) !important;
}

.tw-border-e-zinc-700\/10 {
  border-inline-end-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-e-zinc-700\/100 {
  border-inline-end-color: rgb(63 63 70 / 1) !important;
}

.tw-border-e-zinc-700\/15 {
  border-inline-end-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-e-zinc-700\/20 {
  border-inline-end-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-e-zinc-700\/25 {
  border-inline-end-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-e-zinc-700\/30 {
  border-inline-end-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-e-zinc-700\/35 {
  border-inline-end-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-e-zinc-700\/40 {
  border-inline-end-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-e-zinc-700\/45 {
  border-inline-end-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-e-zinc-700\/5 {
  border-inline-end-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-e-zinc-700\/50 {
  border-inline-end-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-e-zinc-700\/55 {
  border-inline-end-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-e-zinc-700\/60 {
  border-inline-end-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-e-zinc-700\/65 {
  border-inline-end-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-e-zinc-700\/70 {
  border-inline-end-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-e-zinc-700\/75 {
  border-inline-end-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-e-zinc-700\/80 {
  border-inline-end-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-e-zinc-700\/85 {
  border-inline-end-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-e-zinc-700\/90 {
  border-inline-end-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-e-zinc-700\/95 {
  border-inline-end-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-e-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-800\/0 {
  border-inline-end-color: rgb(39 39 42 / 0) !important;
}

.tw-border-e-zinc-800\/10 {
  border-inline-end-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-e-zinc-800\/100 {
  border-inline-end-color: rgb(39 39 42 / 1) !important;
}

.tw-border-e-zinc-800\/15 {
  border-inline-end-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-e-zinc-800\/20 {
  border-inline-end-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-e-zinc-800\/25 {
  border-inline-end-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-e-zinc-800\/30 {
  border-inline-end-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-e-zinc-800\/35 {
  border-inline-end-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-e-zinc-800\/40 {
  border-inline-end-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-e-zinc-800\/45 {
  border-inline-end-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-e-zinc-800\/5 {
  border-inline-end-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-e-zinc-800\/50 {
  border-inline-end-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-e-zinc-800\/55 {
  border-inline-end-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-e-zinc-800\/60 {
  border-inline-end-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-e-zinc-800\/65 {
  border-inline-end-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-e-zinc-800\/70 {
  border-inline-end-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-e-zinc-800\/75 {
  border-inline-end-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-e-zinc-800\/80 {
  border-inline-end-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-e-zinc-800\/85 {
  border-inline-end-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-e-zinc-800\/90 {
  border-inline-end-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-e-zinc-800\/95 {
  border-inline-end-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-e-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-900\/0 {
  border-inline-end-color: rgb(24 24 27 / 0) !important;
}

.tw-border-e-zinc-900\/10 {
  border-inline-end-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-e-zinc-900\/100 {
  border-inline-end-color: rgb(24 24 27 / 1) !important;
}

.tw-border-e-zinc-900\/15 {
  border-inline-end-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-e-zinc-900\/20 {
  border-inline-end-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-e-zinc-900\/25 {
  border-inline-end-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-e-zinc-900\/30 {
  border-inline-end-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-e-zinc-900\/35 {
  border-inline-end-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-e-zinc-900\/40 {
  border-inline-end-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-e-zinc-900\/45 {
  border-inline-end-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-e-zinc-900\/5 {
  border-inline-end-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-e-zinc-900\/50 {
  border-inline-end-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-e-zinc-900\/55 {
  border-inline-end-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-e-zinc-900\/60 {
  border-inline-end-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-e-zinc-900\/65 {
  border-inline-end-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-e-zinc-900\/70 {
  border-inline-end-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-e-zinc-900\/75 {
  border-inline-end-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-e-zinc-900\/80 {
  border-inline-end-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-e-zinc-900\/85 {
  border-inline-end-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-e-zinc-900\/90 {
  border-inline-end-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-e-zinc-900\/95 {
  border-inline-end-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-e-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-inline-end-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-e-zinc-950\/0 {
  border-inline-end-color: rgb(9 9 11 / 0) !important;
}

.tw-border-e-zinc-950\/10 {
  border-inline-end-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-e-zinc-950\/100 {
  border-inline-end-color: rgb(9 9 11 / 1) !important;
}

.tw-border-e-zinc-950\/15 {
  border-inline-end-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-e-zinc-950\/20 {
  border-inline-end-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-e-zinc-950\/25 {
  border-inline-end-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-e-zinc-950\/30 {
  border-inline-end-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-e-zinc-950\/35 {
  border-inline-end-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-e-zinc-950\/40 {
  border-inline-end-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-e-zinc-950\/45 {
  border-inline-end-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-e-zinc-950\/5 {
  border-inline-end-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-e-zinc-950\/50 {
  border-inline-end-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-e-zinc-950\/55 {
  border-inline-end-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-e-zinc-950\/60 {
  border-inline-end-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-e-zinc-950\/65 {
  border-inline-end-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-e-zinc-950\/70 {
  border-inline-end-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-e-zinc-950\/75 {
  border-inline-end-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-e-zinc-950\/80 {
  border-inline-end-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-e-zinc-950\/85 {
  border-inline-end-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-e-zinc-950\/90 {
  border-inline-end-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-e-zinc-950\/95 {
  border-inline-end-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-l-amber-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-100\/0 {
  border-left-color: rgb(254 243 199 / 0) !important;
}

.tw-border-l-amber-100\/10 {
  border-left-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-l-amber-100\/100 {
  border-left-color: rgb(254 243 199 / 1) !important;
}

.tw-border-l-amber-100\/15 {
  border-left-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-l-amber-100\/20 {
  border-left-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-l-amber-100\/25 {
  border-left-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-l-amber-100\/30 {
  border-left-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-l-amber-100\/35 {
  border-left-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-l-amber-100\/40 {
  border-left-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-l-amber-100\/45 {
  border-left-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-l-amber-100\/5 {
  border-left-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-l-amber-100\/50 {
  border-left-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-l-amber-100\/55 {
  border-left-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-l-amber-100\/60 {
  border-left-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-l-amber-100\/65 {
  border-left-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-l-amber-100\/70 {
  border-left-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-l-amber-100\/75 {
  border-left-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-l-amber-100\/80 {
  border-left-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-l-amber-100\/85 {
  border-left-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-l-amber-100\/90 {
  border-left-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-l-amber-100\/95 {
  border-left-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-l-amber-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-200\/0 {
  border-left-color: rgb(253 230 138 / 0) !important;
}

.tw-border-l-amber-200\/10 {
  border-left-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-l-amber-200\/100 {
  border-left-color: rgb(253 230 138 / 1) !important;
}

.tw-border-l-amber-200\/15 {
  border-left-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-l-amber-200\/20 {
  border-left-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-l-amber-200\/25 {
  border-left-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-l-amber-200\/30 {
  border-left-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-l-amber-200\/35 {
  border-left-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-l-amber-200\/40 {
  border-left-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-l-amber-200\/45 {
  border-left-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-l-amber-200\/5 {
  border-left-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-l-amber-200\/50 {
  border-left-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-l-amber-200\/55 {
  border-left-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-l-amber-200\/60 {
  border-left-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-l-amber-200\/65 {
  border-left-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-l-amber-200\/70 {
  border-left-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-l-amber-200\/75 {
  border-left-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-l-amber-200\/80 {
  border-left-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-l-amber-200\/85 {
  border-left-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-l-amber-200\/90 {
  border-left-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-l-amber-200\/95 {
  border-left-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-l-amber-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-300\/0 {
  border-left-color: rgb(252 211 77 / 0) !important;
}

.tw-border-l-amber-300\/10 {
  border-left-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-l-amber-300\/100 {
  border-left-color: rgb(252 211 77 / 1) !important;
}

.tw-border-l-amber-300\/15 {
  border-left-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-l-amber-300\/20 {
  border-left-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-l-amber-300\/25 {
  border-left-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-l-amber-300\/30 {
  border-left-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-l-amber-300\/35 {
  border-left-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-l-amber-300\/40 {
  border-left-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-l-amber-300\/45 {
  border-left-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-l-amber-300\/5 {
  border-left-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-l-amber-300\/50 {
  border-left-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-l-amber-300\/55 {
  border-left-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-l-amber-300\/60 {
  border-left-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-l-amber-300\/65 {
  border-left-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-l-amber-300\/70 {
  border-left-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-l-amber-300\/75 {
  border-left-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-l-amber-300\/80 {
  border-left-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-l-amber-300\/85 {
  border-left-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-l-amber-300\/90 {
  border-left-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-l-amber-300\/95 {
  border-left-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-l-amber-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-400\/0 {
  border-left-color: rgb(251 191 36 / 0) !important;
}

.tw-border-l-amber-400\/10 {
  border-left-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-l-amber-400\/100 {
  border-left-color: rgb(251 191 36 / 1) !important;
}

.tw-border-l-amber-400\/15 {
  border-left-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-l-amber-400\/20 {
  border-left-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-l-amber-400\/25 {
  border-left-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-l-amber-400\/30 {
  border-left-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-l-amber-400\/35 {
  border-left-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-l-amber-400\/40 {
  border-left-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-l-amber-400\/45 {
  border-left-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-l-amber-400\/5 {
  border-left-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-l-amber-400\/50 {
  border-left-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-l-amber-400\/55 {
  border-left-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-l-amber-400\/60 {
  border-left-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-l-amber-400\/65 {
  border-left-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-l-amber-400\/70 {
  border-left-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-l-amber-400\/75 {
  border-left-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-l-amber-400\/80 {
  border-left-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-l-amber-400\/85 {
  border-left-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-l-amber-400\/90 {
  border-left-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-l-amber-400\/95 {
  border-left-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-l-amber-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-50\/0 {
  border-left-color: rgb(255 251 235 / 0) !important;
}

.tw-border-l-amber-50\/10 {
  border-left-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-l-amber-50\/100 {
  border-left-color: rgb(255 251 235 / 1) !important;
}

.tw-border-l-amber-50\/15 {
  border-left-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-l-amber-50\/20 {
  border-left-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-l-amber-50\/25 {
  border-left-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-l-amber-50\/30 {
  border-left-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-l-amber-50\/35 {
  border-left-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-l-amber-50\/40 {
  border-left-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-l-amber-50\/45 {
  border-left-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-l-amber-50\/5 {
  border-left-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-l-amber-50\/50 {
  border-left-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-l-amber-50\/55 {
  border-left-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-l-amber-50\/60 {
  border-left-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-l-amber-50\/65 {
  border-left-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-l-amber-50\/70 {
  border-left-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-l-amber-50\/75 {
  border-left-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-l-amber-50\/80 {
  border-left-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-l-amber-50\/85 {
  border-left-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-l-amber-50\/90 {
  border-left-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-l-amber-50\/95 {
  border-left-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-l-amber-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-500\/0 {
  border-left-color: rgb(245 158 11 / 0) !important;
}

.tw-border-l-amber-500\/10 {
  border-left-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-l-amber-500\/100 {
  border-left-color: rgb(245 158 11 / 1) !important;
}

.tw-border-l-amber-500\/15 {
  border-left-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-l-amber-500\/20 {
  border-left-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-l-amber-500\/25 {
  border-left-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-l-amber-500\/30 {
  border-left-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-l-amber-500\/35 {
  border-left-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-l-amber-500\/40 {
  border-left-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-l-amber-500\/45 {
  border-left-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-l-amber-500\/5 {
  border-left-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-l-amber-500\/50 {
  border-left-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-l-amber-500\/55 {
  border-left-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-l-amber-500\/60 {
  border-left-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-l-amber-500\/65 {
  border-left-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-l-amber-500\/70 {
  border-left-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-l-amber-500\/75 {
  border-left-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-l-amber-500\/80 {
  border-left-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-l-amber-500\/85 {
  border-left-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-l-amber-500\/90 {
  border-left-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-l-amber-500\/95 {
  border-left-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-l-amber-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-600\/0 {
  border-left-color: rgb(217 119 6 / 0) !important;
}

.tw-border-l-amber-600\/10 {
  border-left-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-l-amber-600\/100 {
  border-left-color: rgb(217 119 6 / 1) !important;
}

.tw-border-l-amber-600\/15 {
  border-left-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-l-amber-600\/20 {
  border-left-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-l-amber-600\/25 {
  border-left-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-l-amber-600\/30 {
  border-left-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-l-amber-600\/35 {
  border-left-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-l-amber-600\/40 {
  border-left-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-l-amber-600\/45 {
  border-left-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-l-amber-600\/5 {
  border-left-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-l-amber-600\/50 {
  border-left-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-l-amber-600\/55 {
  border-left-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-l-amber-600\/60 {
  border-left-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-l-amber-600\/65 {
  border-left-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-l-amber-600\/70 {
  border-left-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-l-amber-600\/75 {
  border-left-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-l-amber-600\/80 {
  border-left-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-l-amber-600\/85 {
  border-left-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-l-amber-600\/90 {
  border-left-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-l-amber-600\/95 {
  border-left-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-l-amber-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-700\/0 {
  border-left-color: rgb(180 83 9 / 0) !important;
}

.tw-border-l-amber-700\/10 {
  border-left-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-l-amber-700\/100 {
  border-left-color: rgb(180 83 9 / 1) !important;
}

.tw-border-l-amber-700\/15 {
  border-left-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-l-amber-700\/20 {
  border-left-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-l-amber-700\/25 {
  border-left-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-l-amber-700\/30 {
  border-left-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-l-amber-700\/35 {
  border-left-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-l-amber-700\/40 {
  border-left-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-l-amber-700\/45 {
  border-left-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-l-amber-700\/5 {
  border-left-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-l-amber-700\/50 {
  border-left-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-l-amber-700\/55 {
  border-left-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-l-amber-700\/60 {
  border-left-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-l-amber-700\/65 {
  border-left-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-l-amber-700\/70 {
  border-left-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-l-amber-700\/75 {
  border-left-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-l-amber-700\/80 {
  border-left-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-l-amber-700\/85 {
  border-left-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-l-amber-700\/90 {
  border-left-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-l-amber-700\/95 {
  border-left-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-l-amber-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-800\/0 {
  border-left-color: rgb(146 64 14 / 0) !important;
}

.tw-border-l-amber-800\/10 {
  border-left-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-l-amber-800\/100 {
  border-left-color: rgb(146 64 14 / 1) !important;
}

.tw-border-l-amber-800\/15 {
  border-left-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-l-amber-800\/20 {
  border-left-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-l-amber-800\/25 {
  border-left-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-l-amber-800\/30 {
  border-left-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-l-amber-800\/35 {
  border-left-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-l-amber-800\/40 {
  border-left-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-l-amber-800\/45 {
  border-left-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-l-amber-800\/5 {
  border-left-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-l-amber-800\/50 {
  border-left-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-l-amber-800\/55 {
  border-left-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-l-amber-800\/60 {
  border-left-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-l-amber-800\/65 {
  border-left-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-l-amber-800\/70 {
  border-left-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-l-amber-800\/75 {
  border-left-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-l-amber-800\/80 {
  border-left-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-l-amber-800\/85 {
  border-left-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-l-amber-800\/90 {
  border-left-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-l-amber-800\/95 {
  border-left-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-l-amber-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-900\/0 {
  border-left-color: rgb(120 53 15 / 0) !important;
}

.tw-border-l-amber-900\/10 {
  border-left-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-l-amber-900\/100 {
  border-left-color: rgb(120 53 15 / 1) !important;
}

.tw-border-l-amber-900\/15 {
  border-left-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-l-amber-900\/20 {
  border-left-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-l-amber-900\/25 {
  border-left-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-l-amber-900\/30 {
  border-left-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-l-amber-900\/35 {
  border-left-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-l-amber-900\/40 {
  border-left-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-l-amber-900\/45 {
  border-left-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-l-amber-900\/5 {
  border-left-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-l-amber-900\/50 {
  border-left-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-l-amber-900\/55 {
  border-left-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-l-amber-900\/60 {
  border-left-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-l-amber-900\/65 {
  border-left-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-l-amber-900\/70 {
  border-left-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-l-amber-900\/75 {
  border-left-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-l-amber-900\/80 {
  border-left-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-l-amber-900\/85 {
  border-left-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-l-amber-900\/90 {
  border-left-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-l-amber-900\/95 {
  border-left-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-l-amber-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-l-amber-950\/0 {
  border-left-color: rgb(69 26 3 / 0) !important;
}

.tw-border-l-amber-950\/10 {
  border-left-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-l-amber-950\/100 {
  border-left-color: rgb(69 26 3 / 1) !important;
}

.tw-border-l-amber-950\/15 {
  border-left-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-l-amber-950\/20 {
  border-left-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-l-amber-950\/25 {
  border-left-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-l-amber-950\/30 {
  border-left-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-l-amber-950\/35 {
  border-left-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-l-amber-950\/40 {
  border-left-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-l-amber-950\/45 {
  border-left-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-l-amber-950\/5 {
  border-left-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-l-amber-950\/50 {
  border-left-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-l-amber-950\/55 {
  border-left-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-l-amber-950\/60 {
  border-left-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-l-amber-950\/65 {
  border-left-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-l-amber-950\/70 {
  border-left-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-l-amber-950\/75 {
  border-left-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-l-amber-950\/80 {
  border-left-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-l-amber-950\/85 {
  border-left-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-l-amber-950\/90 {
  border-left-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-l-amber-950\/95 {
  border-left-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-l-black {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-l-black\/0 {
  border-left-color: rgb(0 0 0 / 0) !important;
}

.tw-border-l-black\/10 {
  border-left-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-l-black\/100 {
  border-left-color: rgb(0 0 0 / 1) !important;
}

.tw-border-l-black\/15 {
  border-left-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-l-black\/20 {
  border-left-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-l-black\/25 {
  border-left-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-l-black\/30 {
  border-left-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-l-black\/35 {
  border-left-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-l-black\/40 {
  border-left-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-l-black\/45 {
  border-left-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-l-black\/5 {
  border-left-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-l-black\/50 {
  border-left-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-l-black\/55 {
  border-left-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-l-black\/60 {
  border-left-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-l-black\/65 {
  border-left-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-l-black\/70 {
  border-left-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-l-black\/75 {
  border-left-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-l-black\/80 {
  border-left-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-l-black\/85 {
  border-left-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-l-black\/90 {
  border-left-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-l-black\/95 {
  border-left-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-l-blue-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-100\/0 {
  border-left-color: rgb(219 234 254 / 0) !important;
}

.tw-border-l-blue-100\/10 {
  border-left-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-l-blue-100\/100 {
  border-left-color: rgb(219 234 254 / 1) !important;
}

.tw-border-l-blue-100\/15 {
  border-left-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-l-blue-100\/20 {
  border-left-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-l-blue-100\/25 {
  border-left-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-l-blue-100\/30 {
  border-left-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-l-blue-100\/35 {
  border-left-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-l-blue-100\/40 {
  border-left-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-l-blue-100\/45 {
  border-left-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-l-blue-100\/5 {
  border-left-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-l-blue-100\/50 {
  border-left-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-l-blue-100\/55 {
  border-left-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-l-blue-100\/60 {
  border-left-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-l-blue-100\/65 {
  border-left-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-l-blue-100\/70 {
  border-left-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-l-blue-100\/75 {
  border-left-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-l-blue-100\/80 {
  border-left-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-l-blue-100\/85 {
  border-left-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-l-blue-100\/90 {
  border-left-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-l-blue-100\/95 {
  border-left-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-l-blue-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-200\/0 {
  border-left-color: rgb(191 219 254 / 0) !important;
}

.tw-border-l-blue-200\/10 {
  border-left-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-l-blue-200\/100 {
  border-left-color: rgb(191 219 254 / 1) !important;
}

.tw-border-l-blue-200\/15 {
  border-left-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-l-blue-200\/20 {
  border-left-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-l-blue-200\/25 {
  border-left-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-l-blue-200\/30 {
  border-left-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-l-blue-200\/35 {
  border-left-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-l-blue-200\/40 {
  border-left-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-l-blue-200\/45 {
  border-left-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-l-blue-200\/5 {
  border-left-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-l-blue-200\/50 {
  border-left-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-l-blue-200\/55 {
  border-left-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-l-blue-200\/60 {
  border-left-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-l-blue-200\/65 {
  border-left-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-l-blue-200\/70 {
  border-left-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-l-blue-200\/75 {
  border-left-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-l-blue-200\/80 {
  border-left-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-l-blue-200\/85 {
  border-left-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-l-blue-200\/90 {
  border-left-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-l-blue-200\/95 {
  border-left-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-l-blue-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-300\/0 {
  border-left-color: rgb(147 197 253 / 0) !important;
}

.tw-border-l-blue-300\/10 {
  border-left-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-l-blue-300\/100 {
  border-left-color: rgb(147 197 253 / 1) !important;
}

.tw-border-l-blue-300\/15 {
  border-left-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-l-blue-300\/20 {
  border-left-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-l-blue-300\/25 {
  border-left-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-l-blue-300\/30 {
  border-left-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-l-blue-300\/35 {
  border-left-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-l-blue-300\/40 {
  border-left-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-l-blue-300\/45 {
  border-left-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-l-blue-300\/5 {
  border-left-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-l-blue-300\/50 {
  border-left-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-l-blue-300\/55 {
  border-left-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-l-blue-300\/60 {
  border-left-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-l-blue-300\/65 {
  border-left-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-l-blue-300\/70 {
  border-left-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-l-blue-300\/75 {
  border-left-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-l-blue-300\/80 {
  border-left-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-l-blue-300\/85 {
  border-left-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-l-blue-300\/90 {
  border-left-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-l-blue-300\/95 {
  border-left-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-l-blue-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-400\/0 {
  border-left-color: rgb(96 165 250 / 0) !important;
}

.tw-border-l-blue-400\/10 {
  border-left-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-l-blue-400\/100 {
  border-left-color: rgb(96 165 250 / 1) !important;
}

.tw-border-l-blue-400\/15 {
  border-left-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-l-blue-400\/20 {
  border-left-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-l-blue-400\/25 {
  border-left-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-l-blue-400\/30 {
  border-left-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-l-blue-400\/35 {
  border-left-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-l-blue-400\/40 {
  border-left-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-l-blue-400\/45 {
  border-left-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-l-blue-400\/5 {
  border-left-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-l-blue-400\/50 {
  border-left-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-l-blue-400\/55 {
  border-left-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-l-blue-400\/60 {
  border-left-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-l-blue-400\/65 {
  border-left-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-l-blue-400\/70 {
  border-left-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-l-blue-400\/75 {
  border-left-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-l-blue-400\/80 {
  border-left-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-l-blue-400\/85 {
  border-left-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-l-blue-400\/90 {
  border-left-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-l-blue-400\/95 {
  border-left-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-l-blue-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-50\/0 {
  border-left-color: rgb(239 246 255 / 0) !important;
}

.tw-border-l-blue-50\/10 {
  border-left-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-l-blue-50\/100 {
  border-left-color: rgb(239 246 255 / 1) !important;
}

.tw-border-l-blue-50\/15 {
  border-left-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-l-blue-50\/20 {
  border-left-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-l-blue-50\/25 {
  border-left-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-l-blue-50\/30 {
  border-left-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-l-blue-50\/35 {
  border-left-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-l-blue-50\/40 {
  border-left-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-l-blue-50\/45 {
  border-left-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-l-blue-50\/5 {
  border-left-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-l-blue-50\/50 {
  border-left-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-l-blue-50\/55 {
  border-left-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-l-blue-50\/60 {
  border-left-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-l-blue-50\/65 {
  border-left-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-l-blue-50\/70 {
  border-left-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-l-blue-50\/75 {
  border-left-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-l-blue-50\/80 {
  border-left-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-l-blue-50\/85 {
  border-left-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-l-blue-50\/90 {
  border-left-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-l-blue-50\/95 {
  border-left-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-l-blue-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-500\/0 {
  border-left-color: rgb(59 130 246 / 0) !important;
}

.tw-border-l-blue-500\/10 {
  border-left-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-l-blue-500\/100 {
  border-left-color: rgb(59 130 246 / 1) !important;
}

.tw-border-l-blue-500\/15 {
  border-left-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-l-blue-500\/20 {
  border-left-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-l-blue-500\/25 {
  border-left-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-l-blue-500\/30 {
  border-left-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-l-blue-500\/35 {
  border-left-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-l-blue-500\/40 {
  border-left-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-l-blue-500\/45 {
  border-left-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-l-blue-500\/5 {
  border-left-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-l-blue-500\/50 {
  border-left-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-l-blue-500\/55 {
  border-left-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-l-blue-500\/60 {
  border-left-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-l-blue-500\/65 {
  border-left-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-l-blue-500\/70 {
  border-left-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-l-blue-500\/75 {
  border-left-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-l-blue-500\/80 {
  border-left-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-l-blue-500\/85 {
  border-left-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-l-blue-500\/90 {
  border-left-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-l-blue-500\/95 {
  border-left-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-l-blue-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-600\/0 {
  border-left-color: rgb(37 99 235 / 0) !important;
}

.tw-border-l-blue-600\/10 {
  border-left-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-l-blue-600\/100 {
  border-left-color: rgb(37 99 235 / 1) !important;
}

.tw-border-l-blue-600\/15 {
  border-left-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-l-blue-600\/20 {
  border-left-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-l-blue-600\/25 {
  border-left-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-l-blue-600\/30 {
  border-left-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-l-blue-600\/35 {
  border-left-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-l-blue-600\/40 {
  border-left-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-l-blue-600\/45 {
  border-left-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-l-blue-600\/5 {
  border-left-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-l-blue-600\/50 {
  border-left-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-l-blue-600\/55 {
  border-left-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-l-blue-600\/60 {
  border-left-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-l-blue-600\/65 {
  border-left-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-l-blue-600\/70 {
  border-left-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-l-blue-600\/75 {
  border-left-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-l-blue-600\/80 {
  border-left-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-l-blue-600\/85 {
  border-left-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-l-blue-600\/90 {
  border-left-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-l-blue-600\/95 {
  border-left-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-l-blue-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-700\/0 {
  border-left-color: rgb(29 78 216 / 0) !important;
}

.tw-border-l-blue-700\/10 {
  border-left-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-l-blue-700\/100 {
  border-left-color: rgb(29 78 216 / 1) !important;
}

.tw-border-l-blue-700\/15 {
  border-left-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-l-blue-700\/20 {
  border-left-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-l-blue-700\/25 {
  border-left-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-l-blue-700\/30 {
  border-left-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-l-blue-700\/35 {
  border-left-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-l-blue-700\/40 {
  border-left-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-l-blue-700\/45 {
  border-left-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-l-blue-700\/5 {
  border-left-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-l-blue-700\/50 {
  border-left-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-l-blue-700\/55 {
  border-left-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-l-blue-700\/60 {
  border-left-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-l-blue-700\/65 {
  border-left-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-l-blue-700\/70 {
  border-left-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-l-blue-700\/75 {
  border-left-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-l-blue-700\/80 {
  border-left-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-l-blue-700\/85 {
  border-left-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-l-blue-700\/90 {
  border-left-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-l-blue-700\/95 {
  border-left-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-l-blue-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-800\/0 {
  border-left-color: rgb(30 64 175 / 0) !important;
}

.tw-border-l-blue-800\/10 {
  border-left-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-l-blue-800\/100 {
  border-left-color: rgb(30 64 175 / 1) !important;
}

.tw-border-l-blue-800\/15 {
  border-left-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-l-blue-800\/20 {
  border-left-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-l-blue-800\/25 {
  border-left-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-l-blue-800\/30 {
  border-left-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-l-blue-800\/35 {
  border-left-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-l-blue-800\/40 {
  border-left-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-l-blue-800\/45 {
  border-left-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-l-blue-800\/5 {
  border-left-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-l-blue-800\/50 {
  border-left-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-l-blue-800\/55 {
  border-left-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-l-blue-800\/60 {
  border-left-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-l-blue-800\/65 {
  border-left-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-l-blue-800\/70 {
  border-left-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-l-blue-800\/75 {
  border-left-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-l-blue-800\/80 {
  border-left-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-l-blue-800\/85 {
  border-left-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-l-blue-800\/90 {
  border-left-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-l-blue-800\/95 {
  border-left-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-l-blue-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-900\/0 {
  border-left-color: rgb(30 58 138 / 0) !important;
}

.tw-border-l-blue-900\/10 {
  border-left-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-l-blue-900\/100 {
  border-left-color: rgb(30 58 138 / 1) !important;
}

.tw-border-l-blue-900\/15 {
  border-left-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-l-blue-900\/20 {
  border-left-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-l-blue-900\/25 {
  border-left-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-l-blue-900\/30 {
  border-left-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-l-blue-900\/35 {
  border-left-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-l-blue-900\/40 {
  border-left-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-l-blue-900\/45 {
  border-left-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-l-blue-900\/5 {
  border-left-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-l-blue-900\/50 {
  border-left-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-l-blue-900\/55 {
  border-left-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-l-blue-900\/60 {
  border-left-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-l-blue-900\/65 {
  border-left-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-l-blue-900\/70 {
  border-left-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-l-blue-900\/75 {
  border-left-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-l-blue-900\/80 {
  border-left-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-l-blue-900\/85 {
  border-left-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-l-blue-900\/90 {
  border-left-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-l-blue-900\/95 {
  border-left-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-l-blue-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-l-blue-950\/0 {
  border-left-color: rgb(23 37 84 / 0) !important;
}

.tw-border-l-blue-950\/10 {
  border-left-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-l-blue-950\/100 {
  border-left-color: rgb(23 37 84 / 1) !important;
}

.tw-border-l-blue-950\/15 {
  border-left-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-l-blue-950\/20 {
  border-left-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-l-blue-950\/25 {
  border-left-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-l-blue-950\/30 {
  border-left-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-l-blue-950\/35 {
  border-left-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-l-blue-950\/40 {
  border-left-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-l-blue-950\/45 {
  border-left-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-l-blue-950\/5 {
  border-left-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-l-blue-950\/50 {
  border-left-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-l-blue-950\/55 {
  border-left-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-l-blue-950\/60 {
  border-left-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-l-blue-950\/65 {
  border-left-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-l-blue-950\/70 {
  border-left-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-l-blue-950\/75 {
  border-left-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-l-blue-950\/80 {
  border-left-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-l-blue-950\/85 {
  border-left-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-l-blue-950\/90 {
  border-left-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-l-blue-950\/95 {
  border-left-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-l-current {
  border-left-color: currentColor !important;
}

.tw-border-l-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-100\/0 {
  border-left-color: rgb(207 250 254 / 0) !important;
}

.tw-border-l-cyan-100\/10 {
  border-left-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-l-cyan-100\/100 {
  border-left-color: rgb(207 250 254 / 1) !important;
}

.tw-border-l-cyan-100\/15 {
  border-left-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-l-cyan-100\/20 {
  border-left-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-l-cyan-100\/25 {
  border-left-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-l-cyan-100\/30 {
  border-left-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-l-cyan-100\/35 {
  border-left-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-l-cyan-100\/40 {
  border-left-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-l-cyan-100\/45 {
  border-left-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-l-cyan-100\/5 {
  border-left-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-l-cyan-100\/50 {
  border-left-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-l-cyan-100\/55 {
  border-left-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-l-cyan-100\/60 {
  border-left-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-l-cyan-100\/65 {
  border-left-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-l-cyan-100\/70 {
  border-left-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-l-cyan-100\/75 {
  border-left-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-l-cyan-100\/80 {
  border-left-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-l-cyan-100\/85 {
  border-left-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-l-cyan-100\/90 {
  border-left-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-l-cyan-100\/95 {
  border-left-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-l-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-200\/0 {
  border-left-color: rgb(165 243 252 / 0) !important;
}

.tw-border-l-cyan-200\/10 {
  border-left-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-l-cyan-200\/100 {
  border-left-color: rgb(165 243 252 / 1) !important;
}

.tw-border-l-cyan-200\/15 {
  border-left-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-l-cyan-200\/20 {
  border-left-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-l-cyan-200\/25 {
  border-left-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-l-cyan-200\/30 {
  border-left-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-l-cyan-200\/35 {
  border-left-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-l-cyan-200\/40 {
  border-left-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-l-cyan-200\/45 {
  border-left-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-l-cyan-200\/5 {
  border-left-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-l-cyan-200\/50 {
  border-left-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-l-cyan-200\/55 {
  border-left-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-l-cyan-200\/60 {
  border-left-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-l-cyan-200\/65 {
  border-left-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-l-cyan-200\/70 {
  border-left-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-l-cyan-200\/75 {
  border-left-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-l-cyan-200\/80 {
  border-left-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-l-cyan-200\/85 {
  border-left-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-l-cyan-200\/90 {
  border-left-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-l-cyan-200\/95 {
  border-left-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-l-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-300\/0 {
  border-left-color: rgb(103 232 249 / 0) !important;
}

.tw-border-l-cyan-300\/10 {
  border-left-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-l-cyan-300\/100 {
  border-left-color: rgb(103 232 249 / 1) !important;
}

.tw-border-l-cyan-300\/15 {
  border-left-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-l-cyan-300\/20 {
  border-left-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-l-cyan-300\/25 {
  border-left-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-l-cyan-300\/30 {
  border-left-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-l-cyan-300\/35 {
  border-left-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-l-cyan-300\/40 {
  border-left-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-l-cyan-300\/45 {
  border-left-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-l-cyan-300\/5 {
  border-left-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-l-cyan-300\/50 {
  border-left-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-l-cyan-300\/55 {
  border-left-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-l-cyan-300\/60 {
  border-left-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-l-cyan-300\/65 {
  border-left-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-l-cyan-300\/70 {
  border-left-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-l-cyan-300\/75 {
  border-left-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-l-cyan-300\/80 {
  border-left-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-l-cyan-300\/85 {
  border-left-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-l-cyan-300\/90 {
  border-left-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-l-cyan-300\/95 {
  border-left-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-l-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-400\/0 {
  border-left-color: rgb(34 211 238 / 0) !important;
}

.tw-border-l-cyan-400\/10 {
  border-left-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-l-cyan-400\/100 {
  border-left-color: rgb(34 211 238 / 1) !important;
}

.tw-border-l-cyan-400\/15 {
  border-left-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-l-cyan-400\/20 {
  border-left-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-l-cyan-400\/25 {
  border-left-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-l-cyan-400\/30 {
  border-left-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-l-cyan-400\/35 {
  border-left-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-l-cyan-400\/40 {
  border-left-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-l-cyan-400\/45 {
  border-left-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-l-cyan-400\/5 {
  border-left-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-l-cyan-400\/50 {
  border-left-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-l-cyan-400\/55 {
  border-left-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-l-cyan-400\/60 {
  border-left-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-l-cyan-400\/65 {
  border-left-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-l-cyan-400\/70 {
  border-left-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-l-cyan-400\/75 {
  border-left-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-l-cyan-400\/80 {
  border-left-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-l-cyan-400\/85 {
  border-left-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-l-cyan-400\/90 {
  border-left-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-l-cyan-400\/95 {
  border-left-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-l-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-50\/0 {
  border-left-color: rgb(236 254 255 / 0) !important;
}

.tw-border-l-cyan-50\/10 {
  border-left-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-l-cyan-50\/100 {
  border-left-color: rgb(236 254 255 / 1) !important;
}

.tw-border-l-cyan-50\/15 {
  border-left-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-l-cyan-50\/20 {
  border-left-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-l-cyan-50\/25 {
  border-left-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-l-cyan-50\/30 {
  border-left-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-l-cyan-50\/35 {
  border-left-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-l-cyan-50\/40 {
  border-left-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-l-cyan-50\/45 {
  border-left-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-l-cyan-50\/5 {
  border-left-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-l-cyan-50\/50 {
  border-left-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-l-cyan-50\/55 {
  border-left-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-l-cyan-50\/60 {
  border-left-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-l-cyan-50\/65 {
  border-left-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-l-cyan-50\/70 {
  border-left-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-l-cyan-50\/75 {
  border-left-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-l-cyan-50\/80 {
  border-left-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-l-cyan-50\/85 {
  border-left-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-l-cyan-50\/90 {
  border-left-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-l-cyan-50\/95 {
  border-left-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-l-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-500\/0 {
  border-left-color: rgb(6 182 212 / 0) !important;
}

.tw-border-l-cyan-500\/10 {
  border-left-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-l-cyan-500\/100 {
  border-left-color: rgb(6 182 212 / 1) !important;
}

.tw-border-l-cyan-500\/15 {
  border-left-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-l-cyan-500\/20 {
  border-left-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-l-cyan-500\/25 {
  border-left-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-l-cyan-500\/30 {
  border-left-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-l-cyan-500\/35 {
  border-left-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-l-cyan-500\/40 {
  border-left-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-l-cyan-500\/45 {
  border-left-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-l-cyan-500\/5 {
  border-left-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-l-cyan-500\/50 {
  border-left-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-l-cyan-500\/55 {
  border-left-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-l-cyan-500\/60 {
  border-left-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-l-cyan-500\/65 {
  border-left-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-l-cyan-500\/70 {
  border-left-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-l-cyan-500\/75 {
  border-left-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-l-cyan-500\/80 {
  border-left-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-l-cyan-500\/85 {
  border-left-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-l-cyan-500\/90 {
  border-left-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-l-cyan-500\/95 {
  border-left-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-l-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-600\/0 {
  border-left-color: rgb(8 145 178 / 0) !important;
}

.tw-border-l-cyan-600\/10 {
  border-left-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-l-cyan-600\/100 {
  border-left-color: rgb(8 145 178 / 1) !important;
}

.tw-border-l-cyan-600\/15 {
  border-left-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-l-cyan-600\/20 {
  border-left-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-l-cyan-600\/25 {
  border-left-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-l-cyan-600\/30 {
  border-left-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-l-cyan-600\/35 {
  border-left-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-l-cyan-600\/40 {
  border-left-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-l-cyan-600\/45 {
  border-left-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-l-cyan-600\/5 {
  border-left-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-l-cyan-600\/50 {
  border-left-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-l-cyan-600\/55 {
  border-left-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-l-cyan-600\/60 {
  border-left-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-l-cyan-600\/65 {
  border-left-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-l-cyan-600\/70 {
  border-left-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-l-cyan-600\/75 {
  border-left-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-l-cyan-600\/80 {
  border-left-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-l-cyan-600\/85 {
  border-left-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-l-cyan-600\/90 {
  border-left-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-l-cyan-600\/95 {
  border-left-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-l-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-700\/0 {
  border-left-color: rgb(14 116 144 / 0) !important;
}

.tw-border-l-cyan-700\/10 {
  border-left-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-l-cyan-700\/100 {
  border-left-color: rgb(14 116 144 / 1) !important;
}

.tw-border-l-cyan-700\/15 {
  border-left-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-l-cyan-700\/20 {
  border-left-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-l-cyan-700\/25 {
  border-left-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-l-cyan-700\/30 {
  border-left-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-l-cyan-700\/35 {
  border-left-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-l-cyan-700\/40 {
  border-left-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-l-cyan-700\/45 {
  border-left-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-l-cyan-700\/5 {
  border-left-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-l-cyan-700\/50 {
  border-left-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-l-cyan-700\/55 {
  border-left-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-l-cyan-700\/60 {
  border-left-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-l-cyan-700\/65 {
  border-left-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-l-cyan-700\/70 {
  border-left-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-l-cyan-700\/75 {
  border-left-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-l-cyan-700\/80 {
  border-left-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-l-cyan-700\/85 {
  border-left-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-l-cyan-700\/90 {
  border-left-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-l-cyan-700\/95 {
  border-left-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-l-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-800\/0 {
  border-left-color: rgb(21 94 117 / 0) !important;
}

.tw-border-l-cyan-800\/10 {
  border-left-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-l-cyan-800\/100 {
  border-left-color: rgb(21 94 117 / 1) !important;
}

.tw-border-l-cyan-800\/15 {
  border-left-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-l-cyan-800\/20 {
  border-left-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-l-cyan-800\/25 {
  border-left-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-l-cyan-800\/30 {
  border-left-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-l-cyan-800\/35 {
  border-left-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-l-cyan-800\/40 {
  border-left-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-l-cyan-800\/45 {
  border-left-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-l-cyan-800\/5 {
  border-left-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-l-cyan-800\/50 {
  border-left-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-l-cyan-800\/55 {
  border-left-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-l-cyan-800\/60 {
  border-left-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-l-cyan-800\/65 {
  border-left-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-l-cyan-800\/70 {
  border-left-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-l-cyan-800\/75 {
  border-left-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-l-cyan-800\/80 {
  border-left-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-l-cyan-800\/85 {
  border-left-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-l-cyan-800\/90 {
  border-left-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-l-cyan-800\/95 {
  border-left-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-l-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-900\/0 {
  border-left-color: rgb(22 78 99 / 0) !important;
}

.tw-border-l-cyan-900\/10 {
  border-left-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-l-cyan-900\/100 {
  border-left-color: rgb(22 78 99 / 1) !important;
}

.tw-border-l-cyan-900\/15 {
  border-left-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-l-cyan-900\/20 {
  border-left-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-l-cyan-900\/25 {
  border-left-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-l-cyan-900\/30 {
  border-left-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-l-cyan-900\/35 {
  border-left-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-l-cyan-900\/40 {
  border-left-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-l-cyan-900\/45 {
  border-left-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-l-cyan-900\/5 {
  border-left-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-l-cyan-900\/50 {
  border-left-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-l-cyan-900\/55 {
  border-left-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-l-cyan-900\/60 {
  border-left-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-l-cyan-900\/65 {
  border-left-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-l-cyan-900\/70 {
  border-left-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-l-cyan-900\/75 {
  border-left-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-l-cyan-900\/80 {
  border-left-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-l-cyan-900\/85 {
  border-left-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-l-cyan-900\/90 {
  border-left-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-l-cyan-900\/95 {
  border-left-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-l-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-l-cyan-950\/0 {
  border-left-color: rgb(8 51 68 / 0) !important;
}

.tw-border-l-cyan-950\/10 {
  border-left-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-l-cyan-950\/100 {
  border-left-color: rgb(8 51 68 / 1) !important;
}

.tw-border-l-cyan-950\/15 {
  border-left-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-l-cyan-950\/20 {
  border-left-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-l-cyan-950\/25 {
  border-left-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-l-cyan-950\/30 {
  border-left-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-l-cyan-950\/35 {
  border-left-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-l-cyan-950\/40 {
  border-left-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-l-cyan-950\/45 {
  border-left-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-l-cyan-950\/5 {
  border-left-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-l-cyan-950\/50 {
  border-left-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-l-cyan-950\/55 {
  border-left-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-l-cyan-950\/60 {
  border-left-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-l-cyan-950\/65 {
  border-left-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-l-cyan-950\/70 {
  border-left-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-l-cyan-950\/75 {
  border-left-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-l-cyan-950\/80 {
  border-left-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-l-cyan-950\/85 {
  border-left-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-l-cyan-950\/90 {
  border-left-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-l-cyan-950\/95 {
  border-left-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-l-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-100\/0 {
  border-left-color: rgb(209 250 229 / 0) !important;
}

.tw-border-l-emerald-100\/10 {
  border-left-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-l-emerald-100\/100 {
  border-left-color: rgb(209 250 229 / 1) !important;
}

.tw-border-l-emerald-100\/15 {
  border-left-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-l-emerald-100\/20 {
  border-left-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-l-emerald-100\/25 {
  border-left-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-l-emerald-100\/30 {
  border-left-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-l-emerald-100\/35 {
  border-left-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-l-emerald-100\/40 {
  border-left-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-l-emerald-100\/45 {
  border-left-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-l-emerald-100\/5 {
  border-left-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-l-emerald-100\/50 {
  border-left-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-l-emerald-100\/55 {
  border-left-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-l-emerald-100\/60 {
  border-left-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-l-emerald-100\/65 {
  border-left-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-l-emerald-100\/70 {
  border-left-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-l-emerald-100\/75 {
  border-left-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-l-emerald-100\/80 {
  border-left-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-l-emerald-100\/85 {
  border-left-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-l-emerald-100\/90 {
  border-left-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-l-emerald-100\/95 {
  border-left-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-l-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-200\/0 {
  border-left-color: rgb(167 243 208 / 0) !important;
}

.tw-border-l-emerald-200\/10 {
  border-left-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-l-emerald-200\/100 {
  border-left-color: rgb(167 243 208 / 1) !important;
}

.tw-border-l-emerald-200\/15 {
  border-left-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-l-emerald-200\/20 {
  border-left-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-l-emerald-200\/25 {
  border-left-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-l-emerald-200\/30 {
  border-left-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-l-emerald-200\/35 {
  border-left-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-l-emerald-200\/40 {
  border-left-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-l-emerald-200\/45 {
  border-left-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-l-emerald-200\/5 {
  border-left-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-l-emerald-200\/50 {
  border-left-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-l-emerald-200\/55 {
  border-left-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-l-emerald-200\/60 {
  border-left-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-l-emerald-200\/65 {
  border-left-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-l-emerald-200\/70 {
  border-left-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-l-emerald-200\/75 {
  border-left-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-l-emerald-200\/80 {
  border-left-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-l-emerald-200\/85 {
  border-left-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-l-emerald-200\/90 {
  border-left-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-l-emerald-200\/95 {
  border-left-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-l-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-300\/0 {
  border-left-color: rgb(110 231 183 / 0) !important;
}

.tw-border-l-emerald-300\/10 {
  border-left-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-l-emerald-300\/100 {
  border-left-color: rgb(110 231 183 / 1) !important;
}

.tw-border-l-emerald-300\/15 {
  border-left-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-l-emerald-300\/20 {
  border-left-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-l-emerald-300\/25 {
  border-left-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-l-emerald-300\/30 {
  border-left-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-l-emerald-300\/35 {
  border-left-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-l-emerald-300\/40 {
  border-left-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-l-emerald-300\/45 {
  border-left-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-l-emerald-300\/5 {
  border-left-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-l-emerald-300\/50 {
  border-left-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-l-emerald-300\/55 {
  border-left-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-l-emerald-300\/60 {
  border-left-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-l-emerald-300\/65 {
  border-left-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-l-emerald-300\/70 {
  border-left-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-l-emerald-300\/75 {
  border-left-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-l-emerald-300\/80 {
  border-left-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-l-emerald-300\/85 {
  border-left-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-l-emerald-300\/90 {
  border-left-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-l-emerald-300\/95 {
  border-left-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-l-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-400\/0 {
  border-left-color: rgb(52 211 153 / 0) !important;
}

.tw-border-l-emerald-400\/10 {
  border-left-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-l-emerald-400\/100 {
  border-left-color: rgb(52 211 153 / 1) !important;
}

.tw-border-l-emerald-400\/15 {
  border-left-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-l-emerald-400\/20 {
  border-left-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-l-emerald-400\/25 {
  border-left-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-l-emerald-400\/30 {
  border-left-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-l-emerald-400\/35 {
  border-left-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-l-emerald-400\/40 {
  border-left-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-l-emerald-400\/45 {
  border-left-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-l-emerald-400\/5 {
  border-left-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-l-emerald-400\/50 {
  border-left-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-l-emerald-400\/55 {
  border-left-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-l-emerald-400\/60 {
  border-left-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-l-emerald-400\/65 {
  border-left-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-l-emerald-400\/70 {
  border-left-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-l-emerald-400\/75 {
  border-left-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-l-emerald-400\/80 {
  border-left-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-l-emerald-400\/85 {
  border-left-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-l-emerald-400\/90 {
  border-left-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-l-emerald-400\/95 {
  border-left-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-l-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-50\/0 {
  border-left-color: rgb(236 253 245 / 0) !important;
}

.tw-border-l-emerald-50\/10 {
  border-left-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-l-emerald-50\/100 {
  border-left-color: rgb(236 253 245 / 1) !important;
}

.tw-border-l-emerald-50\/15 {
  border-left-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-l-emerald-50\/20 {
  border-left-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-l-emerald-50\/25 {
  border-left-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-l-emerald-50\/30 {
  border-left-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-l-emerald-50\/35 {
  border-left-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-l-emerald-50\/40 {
  border-left-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-l-emerald-50\/45 {
  border-left-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-l-emerald-50\/5 {
  border-left-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-l-emerald-50\/50 {
  border-left-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-l-emerald-50\/55 {
  border-left-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-l-emerald-50\/60 {
  border-left-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-l-emerald-50\/65 {
  border-left-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-l-emerald-50\/70 {
  border-left-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-l-emerald-50\/75 {
  border-left-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-l-emerald-50\/80 {
  border-left-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-l-emerald-50\/85 {
  border-left-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-l-emerald-50\/90 {
  border-left-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-l-emerald-50\/95 {
  border-left-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-l-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-500\/0 {
  border-left-color: rgb(16 185 129 / 0) !important;
}

.tw-border-l-emerald-500\/10 {
  border-left-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-l-emerald-500\/100 {
  border-left-color: rgb(16 185 129 / 1) !important;
}

.tw-border-l-emerald-500\/15 {
  border-left-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-l-emerald-500\/20 {
  border-left-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-l-emerald-500\/25 {
  border-left-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-l-emerald-500\/30 {
  border-left-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-l-emerald-500\/35 {
  border-left-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-l-emerald-500\/40 {
  border-left-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-l-emerald-500\/45 {
  border-left-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-l-emerald-500\/5 {
  border-left-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-l-emerald-500\/50 {
  border-left-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-l-emerald-500\/55 {
  border-left-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-l-emerald-500\/60 {
  border-left-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-l-emerald-500\/65 {
  border-left-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-l-emerald-500\/70 {
  border-left-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-l-emerald-500\/75 {
  border-left-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-l-emerald-500\/80 {
  border-left-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-l-emerald-500\/85 {
  border-left-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-l-emerald-500\/90 {
  border-left-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-l-emerald-500\/95 {
  border-left-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-l-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-600\/0 {
  border-left-color: rgb(5 150 105 / 0) !important;
}

.tw-border-l-emerald-600\/10 {
  border-left-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-l-emerald-600\/100 {
  border-left-color: rgb(5 150 105 / 1) !important;
}

.tw-border-l-emerald-600\/15 {
  border-left-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-l-emerald-600\/20 {
  border-left-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-l-emerald-600\/25 {
  border-left-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-l-emerald-600\/30 {
  border-left-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-l-emerald-600\/35 {
  border-left-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-l-emerald-600\/40 {
  border-left-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-l-emerald-600\/45 {
  border-left-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-l-emerald-600\/5 {
  border-left-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-l-emerald-600\/50 {
  border-left-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-l-emerald-600\/55 {
  border-left-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-l-emerald-600\/60 {
  border-left-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-l-emerald-600\/65 {
  border-left-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-l-emerald-600\/70 {
  border-left-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-l-emerald-600\/75 {
  border-left-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-l-emerald-600\/80 {
  border-left-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-l-emerald-600\/85 {
  border-left-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-l-emerald-600\/90 {
  border-left-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-l-emerald-600\/95 {
  border-left-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-l-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-700\/0 {
  border-left-color: rgb(4 120 87 / 0) !important;
}

.tw-border-l-emerald-700\/10 {
  border-left-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-l-emerald-700\/100 {
  border-left-color: rgb(4 120 87 / 1) !important;
}

.tw-border-l-emerald-700\/15 {
  border-left-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-l-emerald-700\/20 {
  border-left-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-l-emerald-700\/25 {
  border-left-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-l-emerald-700\/30 {
  border-left-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-l-emerald-700\/35 {
  border-left-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-l-emerald-700\/40 {
  border-left-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-l-emerald-700\/45 {
  border-left-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-l-emerald-700\/5 {
  border-left-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-l-emerald-700\/50 {
  border-left-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-l-emerald-700\/55 {
  border-left-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-l-emerald-700\/60 {
  border-left-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-l-emerald-700\/65 {
  border-left-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-l-emerald-700\/70 {
  border-left-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-l-emerald-700\/75 {
  border-left-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-l-emerald-700\/80 {
  border-left-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-l-emerald-700\/85 {
  border-left-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-l-emerald-700\/90 {
  border-left-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-l-emerald-700\/95 {
  border-left-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-l-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-800\/0 {
  border-left-color: rgb(6 95 70 / 0) !important;
}

.tw-border-l-emerald-800\/10 {
  border-left-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-l-emerald-800\/100 {
  border-left-color: rgb(6 95 70 / 1) !important;
}

.tw-border-l-emerald-800\/15 {
  border-left-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-l-emerald-800\/20 {
  border-left-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-l-emerald-800\/25 {
  border-left-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-l-emerald-800\/30 {
  border-left-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-l-emerald-800\/35 {
  border-left-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-l-emerald-800\/40 {
  border-left-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-l-emerald-800\/45 {
  border-left-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-l-emerald-800\/5 {
  border-left-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-l-emerald-800\/50 {
  border-left-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-l-emerald-800\/55 {
  border-left-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-l-emerald-800\/60 {
  border-left-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-l-emerald-800\/65 {
  border-left-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-l-emerald-800\/70 {
  border-left-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-l-emerald-800\/75 {
  border-left-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-l-emerald-800\/80 {
  border-left-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-l-emerald-800\/85 {
  border-left-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-l-emerald-800\/90 {
  border-left-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-l-emerald-800\/95 {
  border-left-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-l-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-900\/0 {
  border-left-color: rgb(6 78 59 / 0) !important;
}

.tw-border-l-emerald-900\/10 {
  border-left-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-l-emerald-900\/100 {
  border-left-color: rgb(6 78 59 / 1) !important;
}

.tw-border-l-emerald-900\/15 {
  border-left-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-l-emerald-900\/20 {
  border-left-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-l-emerald-900\/25 {
  border-left-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-l-emerald-900\/30 {
  border-left-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-l-emerald-900\/35 {
  border-left-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-l-emerald-900\/40 {
  border-left-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-l-emerald-900\/45 {
  border-left-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-l-emerald-900\/5 {
  border-left-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-l-emerald-900\/50 {
  border-left-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-l-emerald-900\/55 {
  border-left-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-l-emerald-900\/60 {
  border-left-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-l-emerald-900\/65 {
  border-left-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-l-emerald-900\/70 {
  border-left-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-l-emerald-900\/75 {
  border-left-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-l-emerald-900\/80 {
  border-left-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-l-emerald-900\/85 {
  border-left-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-l-emerald-900\/90 {
  border-left-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-l-emerald-900\/95 {
  border-left-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-l-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-l-emerald-950\/0 {
  border-left-color: rgb(2 44 34 / 0) !important;
}

.tw-border-l-emerald-950\/10 {
  border-left-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-l-emerald-950\/100 {
  border-left-color: rgb(2 44 34 / 1) !important;
}

.tw-border-l-emerald-950\/15 {
  border-left-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-l-emerald-950\/20 {
  border-left-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-l-emerald-950\/25 {
  border-left-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-l-emerald-950\/30 {
  border-left-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-l-emerald-950\/35 {
  border-left-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-l-emerald-950\/40 {
  border-left-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-l-emerald-950\/45 {
  border-left-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-l-emerald-950\/5 {
  border-left-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-l-emerald-950\/50 {
  border-left-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-l-emerald-950\/55 {
  border-left-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-l-emerald-950\/60 {
  border-left-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-l-emerald-950\/65 {
  border-left-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-l-emerald-950\/70 {
  border-left-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-l-emerald-950\/75 {
  border-left-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-l-emerald-950\/80 {
  border-left-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-l-emerald-950\/85 {
  border-left-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-l-emerald-950\/90 {
  border-left-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-l-emerald-950\/95 {
  border-left-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-l-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-100\/0 {
  border-left-color: rgb(250 232 255 / 0) !important;
}

.tw-border-l-fuchsia-100\/10 {
  border-left-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-l-fuchsia-100\/100 {
  border-left-color: rgb(250 232 255 / 1) !important;
}

.tw-border-l-fuchsia-100\/15 {
  border-left-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-l-fuchsia-100\/20 {
  border-left-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-l-fuchsia-100\/25 {
  border-left-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-l-fuchsia-100\/30 {
  border-left-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-l-fuchsia-100\/35 {
  border-left-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-l-fuchsia-100\/40 {
  border-left-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-l-fuchsia-100\/45 {
  border-left-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-l-fuchsia-100\/5 {
  border-left-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-l-fuchsia-100\/50 {
  border-left-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-l-fuchsia-100\/55 {
  border-left-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-l-fuchsia-100\/60 {
  border-left-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-l-fuchsia-100\/65 {
  border-left-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-l-fuchsia-100\/70 {
  border-left-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-l-fuchsia-100\/75 {
  border-left-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-l-fuchsia-100\/80 {
  border-left-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-l-fuchsia-100\/85 {
  border-left-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-l-fuchsia-100\/90 {
  border-left-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-l-fuchsia-100\/95 {
  border-left-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-l-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-200\/0 {
  border-left-color: rgb(245 208 254 / 0) !important;
}

.tw-border-l-fuchsia-200\/10 {
  border-left-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-l-fuchsia-200\/100 {
  border-left-color: rgb(245 208 254 / 1) !important;
}

.tw-border-l-fuchsia-200\/15 {
  border-left-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-l-fuchsia-200\/20 {
  border-left-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-l-fuchsia-200\/25 {
  border-left-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-l-fuchsia-200\/30 {
  border-left-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-l-fuchsia-200\/35 {
  border-left-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-l-fuchsia-200\/40 {
  border-left-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-l-fuchsia-200\/45 {
  border-left-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-l-fuchsia-200\/5 {
  border-left-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-l-fuchsia-200\/50 {
  border-left-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-l-fuchsia-200\/55 {
  border-left-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-l-fuchsia-200\/60 {
  border-left-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-l-fuchsia-200\/65 {
  border-left-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-l-fuchsia-200\/70 {
  border-left-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-l-fuchsia-200\/75 {
  border-left-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-l-fuchsia-200\/80 {
  border-left-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-l-fuchsia-200\/85 {
  border-left-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-l-fuchsia-200\/90 {
  border-left-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-l-fuchsia-200\/95 {
  border-left-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-l-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-300\/0 {
  border-left-color: rgb(240 171 252 / 0) !important;
}

.tw-border-l-fuchsia-300\/10 {
  border-left-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-l-fuchsia-300\/100 {
  border-left-color: rgb(240 171 252 / 1) !important;
}

.tw-border-l-fuchsia-300\/15 {
  border-left-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-l-fuchsia-300\/20 {
  border-left-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-l-fuchsia-300\/25 {
  border-left-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-l-fuchsia-300\/30 {
  border-left-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-l-fuchsia-300\/35 {
  border-left-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-l-fuchsia-300\/40 {
  border-left-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-l-fuchsia-300\/45 {
  border-left-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-l-fuchsia-300\/5 {
  border-left-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-l-fuchsia-300\/50 {
  border-left-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-l-fuchsia-300\/55 {
  border-left-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-l-fuchsia-300\/60 {
  border-left-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-l-fuchsia-300\/65 {
  border-left-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-l-fuchsia-300\/70 {
  border-left-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-l-fuchsia-300\/75 {
  border-left-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-l-fuchsia-300\/80 {
  border-left-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-l-fuchsia-300\/85 {
  border-left-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-l-fuchsia-300\/90 {
  border-left-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-l-fuchsia-300\/95 {
  border-left-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-l-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-400\/0 {
  border-left-color: rgb(232 121 249 / 0) !important;
}

.tw-border-l-fuchsia-400\/10 {
  border-left-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-l-fuchsia-400\/100 {
  border-left-color: rgb(232 121 249 / 1) !important;
}

.tw-border-l-fuchsia-400\/15 {
  border-left-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-l-fuchsia-400\/20 {
  border-left-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-l-fuchsia-400\/25 {
  border-left-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-l-fuchsia-400\/30 {
  border-left-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-l-fuchsia-400\/35 {
  border-left-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-l-fuchsia-400\/40 {
  border-left-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-l-fuchsia-400\/45 {
  border-left-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-l-fuchsia-400\/5 {
  border-left-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-l-fuchsia-400\/50 {
  border-left-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-l-fuchsia-400\/55 {
  border-left-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-l-fuchsia-400\/60 {
  border-left-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-l-fuchsia-400\/65 {
  border-left-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-l-fuchsia-400\/70 {
  border-left-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-l-fuchsia-400\/75 {
  border-left-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-l-fuchsia-400\/80 {
  border-left-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-l-fuchsia-400\/85 {
  border-left-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-l-fuchsia-400\/90 {
  border-left-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-l-fuchsia-400\/95 {
  border-left-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-l-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-50\/0 {
  border-left-color: rgb(253 244 255 / 0) !important;
}

.tw-border-l-fuchsia-50\/10 {
  border-left-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-l-fuchsia-50\/100 {
  border-left-color: rgb(253 244 255 / 1) !important;
}

.tw-border-l-fuchsia-50\/15 {
  border-left-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-l-fuchsia-50\/20 {
  border-left-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-l-fuchsia-50\/25 {
  border-left-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-l-fuchsia-50\/30 {
  border-left-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-l-fuchsia-50\/35 {
  border-left-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-l-fuchsia-50\/40 {
  border-left-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-l-fuchsia-50\/45 {
  border-left-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-l-fuchsia-50\/5 {
  border-left-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-l-fuchsia-50\/50 {
  border-left-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-l-fuchsia-50\/55 {
  border-left-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-l-fuchsia-50\/60 {
  border-left-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-l-fuchsia-50\/65 {
  border-left-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-l-fuchsia-50\/70 {
  border-left-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-l-fuchsia-50\/75 {
  border-left-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-l-fuchsia-50\/80 {
  border-left-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-l-fuchsia-50\/85 {
  border-left-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-l-fuchsia-50\/90 {
  border-left-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-l-fuchsia-50\/95 {
  border-left-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-l-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-500\/0 {
  border-left-color: rgb(217 70 239 / 0) !important;
}

.tw-border-l-fuchsia-500\/10 {
  border-left-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-l-fuchsia-500\/100 {
  border-left-color: rgb(217 70 239 / 1) !important;
}

.tw-border-l-fuchsia-500\/15 {
  border-left-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-l-fuchsia-500\/20 {
  border-left-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-l-fuchsia-500\/25 {
  border-left-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-l-fuchsia-500\/30 {
  border-left-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-l-fuchsia-500\/35 {
  border-left-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-l-fuchsia-500\/40 {
  border-left-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-l-fuchsia-500\/45 {
  border-left-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-l-fuchsia-500\/5 {
  border-left-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-l-fuchsia-500\/50 {
  border-left-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-l-fuchsia-500\/55 {
  border-left-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-l-fuchsia-500\/60 {
  border-left-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-l-fuchsia-500\/65 {
  border-left-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-l-fuchsia-500\/70 {
  border-left-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-l-fuchsia-500\/75 {
  border-left-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-l-fuchsia-500\/80 {
  border-left-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-l-fuchsia-500\/85 {
  border-left-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-l-fuchsia-500\/90 {
  border-left-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-l-fuchsia-500\/95 {
  border-left-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-l-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-600\/0 {
  border-left-color: rgb(192 38 211 / 0) !important;
}

.tw-border-l-fuchsia-600\/10 {
  border-left-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-l-fuchsia-600\/100 {
  border-left-color: rgb(192 38 211 / 1) !important;
}

.tw-border-l-fuchsia-600\/15 {
  border-left-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-l-fuchsia-600\/20 {
  border-left-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-l-fuchsia-600\/25 {
  border-left-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-l-fuchsia-600\/30 {
  border-left-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-l-fuchsia-600\/35 {
  border-left-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-l-fuchsia-600\/40 {
  border-left-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-l-fuchsia-600\/45 {
  border-left-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-l-fuchsia-600\/5 {
  border-left-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-l-fuchsia-600\/50 {
  border-left-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-l-fuchsia-600\/55 {
  border-left-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-l-fuchsia-600\/60 {
  border-left-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-l-fuchsia-600\/65 {
  border-left-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-l-fuchsia-600\/70 {
  border-left-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-l-fuchsia-600\/75 {
  border-left-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-l-fuchsia-600\/80 {
  border-left-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-l-fuchsia-600\/85 {
  border-left-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-l-fuchsia-600\/90 {
  border-left-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-l-fuchsia-600\/95 {
  border-left-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-l-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-700\/0 {
  border-left-color: rgb(162 28 175 / 0) !important;
}

.tw-border-l-fuchsia-700\/10 {
  border-left-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-l-fuchsia-700\/100 {
  border-left-color: rgb(162 28 175 / 1) !important;
}

.tw-border-l-fuchsia-700\/15 {
  border-left-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-l-fuchsia-700\/20 {
  border-left-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-l-fuchsia-700\/25 {
  border-left-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-l-fuchsia-700\/30 {
  border-left-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-l-fuchsia-700\/35 {
  border-left-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-l-fuchsia-700\/40 {
  border-left-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-l-fuchsia-700\/45 {
  border-left-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-l-fuchsia-700\/5 {
  border-left-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-l-fuchsia-700\/50 {
  border-left-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-l-fuchsia-700\/55 {
  border-left-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-l-fuchsia-700\/60 {
  border-left-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-l-fuchsia-700\/65 {
  border-left-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-l-fuchsia-700\/70 {
  border-left-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-l-fuchsia-700\/75 {
  border-left-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-l-fuchsia-700\/80 {
  border-left-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-l-fuchsia-700\/85 {
  border-left-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-l-fuchsia-700\/90 {
  border-left-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-l-fuchsia-700\/95 {
  border-left-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-l-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-800\/0 {
  border-left-color: rgb(134 25 143 / 0) !important;
}

.tw-border-l-fuchsia-800\/10 {
  border-left-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-l-fuchsia-800\/100 {
  border-left-color: rgb(134 25 143 / 1) !important;
}

.tw-border-l-fuchsia-800\/15 {
  border-left-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-l-fuchsia-800\/20 {
  border-left-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-l-fuchsia-800\/25 {
  border-left-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-l-fuchsia-800\/30 {
  border-left-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-l-fuchsia-800\/35 {
  border-left-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-l-fuchsia-800\/40 {
  border-left-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-l-fuchsia-800\/45 {
  border-left-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-l-fuchsia-800\/5 {
  border-left-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-l-fuchsia-800\/50 {
  border-left-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-l-fuchsia-800\/55 {
  border-left-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-l-fuchsia-800\/60 {
  border-left-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-l-fuchsia-800\/65 {
  border-left-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-l-fuchsia-800\/70 {
  border-left-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-l-fuchsia-800\/75 {
  border-left-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-l-fuchsia-800\/80 {
  border-left-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-l-fuchsia-800\/85 {
  border-left-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-l-fuchsia-800\/90 {
  border-left-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-l-fuchsia-800\/95 {
  border-left-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-l-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-900\/0 {
  border-left-color: rgb(112 26 117 / 0) !important;
}

.tw-border-l-fuchsia-900\/10 {
  border-left-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-l-fuchsia-900\/100 {
  border-left-color: rgb(112 26 117 / 1) !important;
}

.tw-border-l-fuchsia-900\/15 {
  border-left-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-l-fuchsia-900\/20 {
  border-left-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-l-fuchsia-900\/25 {
  border-left-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-l-fuchsia-900\/30 {
  border-left-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-l-fuchsia-900\/35 {
  border-left-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-l-fuchsia-900\/40 {
  border-left-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-l-fuchsia-900\/45 {
  border-left-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-l-fuchsia-900\/5 {
  border-left-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-l-fuchsia-900\/50 {
  border-left-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-l-fuchsia-900\/55 {
  border-left-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-l-fuchsia-900\/60 {
  border-left-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-l-fuchsia-900\/65 {
  border-left-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-l-fuchsia-900\/70 {
  border-left-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-l-fuchsia-900\/75 {
  border-left-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-l-fuchsia-900\/80 {
  border-left-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-l-fuchsia-900\/85 {
  border-left-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-l-fuchsia-900\/90 {
  border-left-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-l-fuchsia-900\/95 {
  border-left-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-l-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-l-fuchsia-950\/0 {
  border-left-color: rgb(74 4 78 / 0) !important;
}

.tw-border-l-fuchsia-950\/10 {
  border-left-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-l-fuchsia-950\/100 {
  border-left-color: rgb(74 4 78 / 1) !important;
}

.tw-border-l-fuchsia-950\/15 {
  border-left-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-l-fuchsia-950\/20 {
  border-left-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-l-fuchsia-950\/25 {
  border-left-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-l-fuchsia-950\/30 {
  border-left-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-l-fuchsia-950\/35 {
  border-left-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-l-fuchsia-950\/40 {
  border-left-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-l-fuchsia-950\/45 {
  border-left-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-l-fuchsia-950\/5 {
  border-left-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-l-fuchsia-950\/50 {
  border-left-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-l-fuchsia-950\/55 {
  border-left-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-l-fuchsia-950\/60 {
  border-left-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-l-fuchsia-950\/65 {
  border-left-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-l-fuchsia-950\/70 {
  border-left-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-l-fuchsia-950\/75 {
  border-left-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-l-fuchsia-950\/80 {
  border-left-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-l-fuchsia-950\/85 {
  border-left-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-l-fuchsia-950\/90 {
  border-left-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-l-fuchsia-950\/95 {
  border-left-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-l-gray-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-100\/0 {
  border-left-color: rgb(243 244 246 / 0) !important;
}

.tw-border-l-gray-100\/10 {
  border-left-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-l-gray-100\/100 {
  border-left-color: rgb(243 244 246 / 1) !important;
}

.tw-border-l-gray-100\/15 {
  border-left-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-l-gray-100\/20 {
  border-left-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-l-gray-100\/25 {
  border-left-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-l-gray-100\/30 {
  border-left-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-l-gray-100\/35 {
  border-left-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-l-gray-100\/40 {
  border-left-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-l-gray-100\/45 {
  border-left-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-l-gray-100\/5 {
  border-left-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-l-gray-100\/50 {
  border-left-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-l-gray-100\/55 {
  border-left-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-l-gray-100\/60 {
  border-left-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-l-gray-100\/65 {
  border-left-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-l-gray-100\/70 {
  border-left-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-l-gray-100\/75 {
  border-left-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-l-gray-100\/80 {
  border-left-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-l-gray-100\/85 {
  border-left-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-l-gray-100\/90 {
  border-left-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-l-gray-100\/95 {
  border-left-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-l-gray-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-200\/0 {
  border-left-color: rgb(229 231 235 / 0) !important;
}

.tw-border-l-gray-200\/10 {
  border-left-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-l-gray-200\/100 {
  border-left-color: rgb(229 231 235 / 1) !important;
}

.tw-border-l-gray-200\/15 {
  border-left-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-l-gray-200\/20 {
  border-left-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-l-gray-200\/25 {
  border-left-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-l-gray-200\/30 {
  border-left-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-l-gray-200\/35 {
  border-left-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-l-gray-200\/40 {
  border-left-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-l-gray-200\/45 {
  border-left-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-l-gray-200\/5 {
  border-left-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-l-gray-200\/50 {
  border-left-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-l-gray-200\/55 {
  border-left-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-l-gray-200\/60 {
  border-left-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-l-gray-200\/65 {
  border-left-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-l-gray-200\/70 {
  border-left-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-l-gray-200\/75 {
  border-left-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-l-gray-200\/80 {
  border-left-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-l-gray-200\/85 {
  border-left-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-l-gray-200\/90 {
  border-left-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-l-gray-200\/95 {
  border-left-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-l-gray-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-300\/0 {
  border-left-color: rgb(209 213 219 / 0) !important;
}

.tw-border-l-gray-300\/10 {
  border-left-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-l-gray-300\/100 {
  border-left-color: rgb(209 213 219 / 1) !important;
}

.tw-border-l-gray-300\/15 {
  border-left-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-l-gray-300\/20 {
  border-left-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-l-gray-300\/25 {
  border-left-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-l-gray-300\/30 {
  border-left-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-l-gray-300\/35 {
  border-left-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-l-gray-300\/40 {
  border-left-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-l-gray-300\/45 {
  border-left-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-l-gray-300\/5 {
  border-left-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-l-gray-300\/50 {
  border-left-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-l-gray-300\/55 {
  border-left-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-l-gray-300\/60 {
  border-left-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-l-gray-300\/65 {
  border-left-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-l-gray-300\/70 {
  border-left-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-l-gray-300\/75 {
  border-left-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-l-gray-300\/80 {
  border-left-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-l-gray-300\/85 {
  border-left-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-l-gray-300\/90 {
  border-left-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-l-gray-300\/95 {
  border-left-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-l-gray-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-400\/0 {
  border-left-color: rgb(156 163 175 / 0) !important;
}

.tw-border-l-gray-400\/10 {
  border-left-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-l-gray-400\/100 {
  border-left-color: rgb(156 163 175 / 1) !important;
}

.tw-border-l-gray-400\/15 {
  border-left-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-l-gray-400\/20 {
  border-left-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-l-gray-400\/25 {
  border-left-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-l-gray-400\/30 {
  border-left-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-l-gray-400\/35 {
  border-left-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-l-gray-400\/40 {
  border-left-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-l-gray-400\/45 {
  border-left-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-l-gray-400\/5 {
  border-left-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-l-gray-400\/50 {
  border-left-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-l-gray-400\/55 {
  border-left-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-l-gray-400\/60 {
  border-left-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-l-gray-400\/65 {
  border-left-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-l-gray-400\/70 {
  border-left-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-l-gray-400\/75 {
  border-left-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-l-gray-400\/80 {
  border-left-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-l-gray-400\/85 {
  border-left-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-l-gray-400\/90 {
  border-left-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-l-gray-400\/95 {
  border-left-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-l-gray-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-50\/0 {
  border-left-color: rgb(249 250 251 / 0) !important;
}

.tw-border-l-gray-50\/10 {
  border-left-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-l-gray-50\/100 {
  border-left-color: rgb(249 250 251 / 1) !important;
}

.tw-border-l-gray-50\/15 {
  border-left-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-l-gray-50\/20 {
  border-left-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-l-gray-50\/25 {
  border-left-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-l-gray-50\/30 {
  border-left-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-l-gray-50\/35 {
  border-left-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-l-gray-50\/40 {
  border-left-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-l-gray-50\/45 {
  border-left-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-l-gray-50\/5 {
  border-left-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-l-gray-50\/50 {
  border-left-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-l-gray-50\/55 {
  border-left-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-l-gray-50\/60 {
  border-left-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-l-gray-50\/65 {
  border-left-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-l-gray-50\/70 {
  border-left-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-l-gray-50\/75 {
  border-left-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-l-gray-50\/80 {
  border-left-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-l-gray-50\/85 {
  border-left-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-l-gray-50\/90 {
  border-left-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-l-gray-50\/95 {
  border-left-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-l-gray-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-500\/0 {
  border-left-color: rgb(107 114 128 / 0) !important;
}

.tw-border-l-gray-500\/10 {
  border-left-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-l-gray-500\/100 {
  border-left-color: rgb(107 114 128 / 1) !important;
}

.tw-border-l-gray-500\/15 {
  border-left-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-l-gray-500\/20 {
  border-left-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-l-gray-500\/25 {
  border-left-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-l-gray-500\/30 {
  border-left-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-l-gray-500\/35 {
  border-left-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-l-gray-500\/40 {
  border-left-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-l-gray-500\/45 {
  border-left-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-l-gray-500\/5 {
  border-left-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-l-gray-500\/50 {
  border-left-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-l-gray-500\/55 {
  border-left-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-l-gray-500\/60 {
  border-left-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-l-gray-500\/65 {
  border-left-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-l-gray-500\/70 {
  border-left-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-l-gray-500\/75 {
  border-left-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-l-gray-500\/80 {
  border-left-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-l-gray-500\/85 {
  border-left-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-l-gray-500\/90 {
  border-left-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-l-gray-500\/95 {
  border-left-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-l-gray-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-600\/0 {
  border-left-color: rgb(75 85 99 / 0) !important;
}

.tw-border-l-gray-600\/10 {
  border-left-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-l-gray-600\/100 {
  border-left-color: rgb(75 85 99 / 1) !important;
}

.tw-border-l-gray-600\/15 {
  border-left-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-l-gray-600\/20 {
  border-left-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-l-gray-600\/25 {
  border-left-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-l-gray-600\/30 {
  border-left-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-l-gray-600\/35 {
  border-left-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-l-gray-600\/40 {
  border-left-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-l-gray-600\/45 {
  border-left-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-l-gray-600\/5 {
  border-left-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-l-gray-600\/50 {
  border-left-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-l-gray-600\/55 {
  border-left-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-l-gray-600\/60 {
  border-left-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-l-gray-600\/65 {
  border-left-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-l-gray-600\/70 {
  border-left-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-l-gray-600\/75 {
  border-left-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-l-gray-600\/80 {
  border-left-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-l-gray-600\/85 {
  border-left-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-l-gray-600\/90 {
  border-left-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-l-gray-600\/95 {
  border-left-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-l-gray-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-700\/0 {
  border-left-color: rgb(55 65 81 / 0) !important;
}

.tw-border-l-gray-700\/10 {
  border-left-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-l-gray-700\/100 {
  border-left-color: rgb(55 65 81 / 1) !important;
}

.tw-border-l-gray-700\/15 {
  border-left-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-l-gray-700\/20 {
  border-left-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-l-gray-700\/25 {
  border-left-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-l-gray-700\/30 {
  border-left-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-l-gray-700\/35 {
  border-left-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-l-gray-700\/40 {
  border-left-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-l-gray-700\/45 {
  border-left-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-l-gray-700\/5 {
  border-left-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-l-gray-700\/50 {
  border-left-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-l-gray-700\/55 {
  border-left-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-l-gray-700\/60 {
  border-left-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-l-gray-700\/65 {
  border-left-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-l-gray-700\/70 {
  border-left-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-l-gray-700\/75 {
  border-left-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-l-gray-700\/80 {
  border-left-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-l-gray-700\/85 {
  border-left-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-l-gray-700\/90 {
  border-left-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-l-gray-700\/95 {
  border-left-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-l-gray-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-800\/0 {
  border-left-color: rgb(31 41 55 / 0) !important;
}

.tw-border-l-gray-800\/10 {
  border-left-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-l-gray-800\/100 {
  border-left-color: rgb(31 41 55 / 1) !important;
}

.tw-border-l-gray-800\/15 {
  border-left-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-l-gray-800\/20 {
  border-left-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-l-gray-800\/25 {
  border-left-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-l-gray-800\/30 {
  border-left-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-l-gray-800\/35 {
  border-left-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-l-gray-800\/40 {
  border-left-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-l-gray-800\/45 {
  border-left-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-l-gray-800\/5 {
  border-left-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-l-gray-800\/50 {
  border-left-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-l-gray-800\/55 {
  border-left-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-l-gray-800\/60 {
  border-left-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-l-gray-800\/65 {
  border-left-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-l-gray-800\/70 {
  border-left-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-l-gray-800\/75 {
  border-left-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-l-gray-800\/80 {
  border-left-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-l-gray-800\/85 {
  border-left-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-l-gray-800\/90 {
  border-left-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-l-gray-800\/95 {
  border-left-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-l-gray-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-900\/0 {
  border-left-color: rgb(17 24 39 / 0) !important;
}

.tw-border-l-gray-900\/10 {
  border-left-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-l-gray-900\/100 {
  border-left-color: rgb(17 24 39 / 1) !important;
}

.tw-border-l-gray-900\/15 {
  border-left-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-l-gray-900\/20 {
  border-left-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-l-gray-900\/25 {
  border-left-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-l-gray-900\/30 {
  border-left-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-l-gray-900\/35 {
  border-left-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-l-gray-900\/40 {
  border-left-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-l-gray-900\/45 {
  border-left-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-l-gray-900\/5 {
  border-left-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-l-gray-900\/50 {
  border-left-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-l-gray-900\/55 {
  border-left-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-l-gray-900\/60 {
  border-left-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-l-gray-900\/65 {
  border-left-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-l-gray-900\/70 {
  border-left-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-l-gray-900\/75 {
  border-left-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-l-gray-900\/80 {
  border-left-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-l-gray-900\/85 {
  border-left-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-l-gray-900\/90 {
  border-left-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-l-gray-900\/95 {
  border-left-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-l-gray-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-l-gray-950\/0 {
  border-left-color: rgb(3 7 18 / 0) !important;
}

.tw-border-l-gray-950\/10 {
  border-left-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-l-gray-950\/100 {
  border-left-color: rgb(3 7 18 / 1) !important;
}

.tw-border-l-gray-950\/15 {
  border-left-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-l-gray-950\/20 {
  border-left-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-l-gray-950\/25 {
  border-left-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-l-gray-950\/30 {
  border-left-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-l-gray-950\/35 {
  border-left-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-l-gray-950\/40 {
  border-left-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-l-gray-950\/45 {
  border-left-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-l-gray-950\/5 {
  border-left-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-l-gray-950\/50 {
  border-left-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-l-gray-950\/55 {
  border-left-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-l-gray-950\/60 {
  border-left-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-l-gray-950\/65 {
  border-left-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-l-gray-950\/70 {
  border-left-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-l-gray-950\/75 {
  border-left-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-l-gray-950\/80 {
  border-left-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-l-gray-950\/85 {
  border-left-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-l-gray-950\/90 {
  border-left-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-l-gray-950\/95 {
  border-left-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-l-green-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-100\/0 {
  border-left-color: rgb(220 252 231 / 0) !important;
}

.tw-border-l-green-100\/10 {
  border-left-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-l-green-100\/100 {
  border-left-color: rgb(220 252 231 / 1) !important;
}

.tw-border-l-green-100\/15 {
  border-left-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-l-green-100\/20 {
  border-left-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-l-green-100\/25 {
  border-left-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-l-green-100\/30 {
  border-left-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-l-green-100\/35 {
  border-left-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-l-green-100\/40 {
  border-left-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-l-green-100\/45 {
  border-left-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-l-green-100\/5 {
  border-left-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-l-green-100\/50 {
  border-left-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-l-green-100\/55 {
  border-left-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-l-green-100\/60 {
  border-left-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-l-green-100\/65 {
  border-left-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-l-green-100\/70 {
  border-left-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-l-green-100\/75 {
  border-left-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-l-green-100\/80 {
  border-left-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-l-green-100\/85 {
  border-left-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-l-green-100\/90 {
  border-left-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-l-green-100\/95 {
  border-left-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-l-green-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-200\/0 {
  border-left-color: rgb(187 247 208 / 0) !important;
}

.tw-border-l-green-200\/10 {
  border-left-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-l-green-200\/100 {
  border-left-color: rgb(187 247 208 / 1) !important;
}

.tw-border-l-green-200\/15 {
  border-left-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-l-green-200\/20 {
  border-left-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-l-green-200\/25 {
  border-left-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-l-green-200\/30 {
  border-left-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-l-green-200\/35 {
  border-left-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-l-green-200\/40 {
  border-left-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-l-green-200\/45 {
  border-left-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-l-green-200\/5 {
  border-left-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-l-green-200\/50 {
  border-left-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-l-green-200\/55 {
  border-left-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-l-green-200\/60 {
  border-left-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-l-green-200\/65 {
  border-left-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-l-green-200\/70 {
  border-left-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-l-green-200\/75 {
  border-left-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-l-green-200\/80 {
  border-left-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-l-green-200\/85 {
  border-left-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-l-green-200\/90 {
  border-left-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-l-green-200\/95 {
  border-left-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-l-green-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-300\/0 {
  border-left-color: rgb(134 239 172 / 0) !important;
}

.tw-border-l-green-300\/10 {
  border-left-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-l-green-300\/100 {
  border-left-color: rgb(134 239 172 / 1) !important;
}

.tw-border-l-green-300\/15 {
  border-left-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-l-green-300\/20 {
  border-left-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-l-green-300\/25 {
  border-left-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-l-green-300\/30 {
  border-left-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-l-green-300\/35 {
  border-left-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-l-green-300\/40 {
  border-left-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-l-green-300\/45 {
  border-left-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-l-green-300\/5 {
  border-left-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-l-green-300\/50 {
  border-left-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-l-green-300\/55 {
  border-left-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-l-green-300\/60 {
  border-left-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-l-green-300\/65 {
  border-left-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-l-green-300\/70 {
  border-left-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-l-green-300\/75 {
  border-left-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-l-green-300\/80 {
  border-left-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-l-green-300\/85 {
  border-left-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-l-green-300\/90 {
  border-left-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-l-green-300\/95 {
  border-left-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-l-green-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-400\/0 {
  border-left-color: rgb(74 222 128 / 0) !important;
}

.tw-border-l-green-400\/10 {
  border-left-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-l-green-400\/100 {
  border-left-color: rgb(74 222 128 / 1) !important;
}

.tw-border-l-green-400\/15 {
  border-left-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-l-green-400\/20 {
  border-left-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-l-green-400\/25 {
  border-left-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-l-green-400\/30 {
  border-left-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-l-green-400\/35 {
  border-left-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-l-green-400\/40 {
  border-left-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-l-green-400\/45 {
  border-left-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-l-green-400\/5 {
  border-left-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-l-green-400\/50 {
  border-left-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-l-green-400\/55 {
  border-left-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-l-green-400\/60 {
  border-left-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-l-green-400\/65 {
  border-left-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-l-green-400\/70 {
  border-left-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-l-green-400\/75 {
  border-left-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-l-green-400\/80 {
  border-left-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-l-green-400\/85 {
  border-left-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-l-green-400\/90 {
  border-left-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-l-green-400\/95 {
  border-left-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-l-green-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-50\/0 {
  border-left-color: rgb(240 253 244 / 0) !important;
}

.tw-border-l-green-50\/10 {
  border-left-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-l-green-50\/100 {
  border-left-color: rgb(240 253 244 / 1) !important;
}

.tw-border-l-green-50\/15 {
  border-left-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-l-green-50\/20 {
  border-left-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-l-green-50\/25 {
  border-left-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-l-green-50\/30 {
  border-left-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-l-green-50\/35 {
  border-left-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-l-green-50\/40 {
  border-left-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-l-green-50\/45 {
  border-left-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-l-green-50\/5 {
  border-left-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-l-green-50\/50 {
  border-left-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-l-green-50\/55 {
  border-left-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-l-green-50\/60 {
  border-left-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-l-green-50\/65 {
  border-left-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-l-green-50\/70 {
  border-left-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-l-green-50\/75 {
  border-left-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-l-green-50\/80 {
  border-left-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-l-green-50\/85 {
  border-left-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-l-green-50\/90 {
  border-left-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-l-green-50\/95 {
  border-left-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-l-green-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-500\/0 {
  border-left-color: rgb(34 197 94 / 0) !important;
}

.tw-border-l-green-500\/10 {
  border-left-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-l-green-500\/100 {
  border-left-color: rgb(34 197 94 / 1) !important;
}

.tw-border-l-green-500\/15 {
  border-left-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-l-green-500\/20 {
  border-left-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-l-green-500\/25 {
  border-left-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-l-green-500\/30 {
  border-left-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-l-green-500\/35 {
  border-left-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-l-green-500\/40 {
  border-left-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-l-green-500\/45 {
  border-left-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-l-green-500\/5 {
  border-left-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-l-green-500\/50 {
  border-left-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-l-green-500\/55 {
  border-left-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-l-green-500\/60 {
  border-left-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-l-green-500\/65 {
  border-left-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-l-green-500\/70 {
  border-left-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-l-green-500\/75 {
  border-left-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-l-green-500\/80 {
  border-left-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-l-green-500\/85 {
  border-left-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-l-green-500\/90 {
  border-left-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-l-green-500\/95 {
  border-left-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-l-green-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-600\/0 {
  border-left-color: rgb(22 163 74 / 0) !important;
}

.tw-border-l-green-600\/10 {
  border-left-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-l-green-600\/100 {
  border-left-color: rgb(22 163 74 / 1) !important;
}

.tw-border-l-green-600\/15 {
  border-left-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-l-green-600\/20 {
  border-left-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-l-green-600\/25 {
  border-left-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-l-green-600\/30 {
  border-left-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-l-green-600\/35 {
  border-left-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-l-green-600\/40 {
  border-left-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-l-green-600\/45 {
  border-left-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-l-green-600\/5 {
  border-left-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-l-green-600\/50 {
  border-left-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-l-green-600\/55 {
  border-left-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-l-green-600\/60 {
  border-left-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-l-green-600\/65 {
  border-left-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-l-green-600\/70 {
  border-left-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-l-green-600\/75 {
  border-left-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-l-green-600\/80 {
  border-left-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-l-green-600\/85 {
  border-left-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-l-green-600\/90 {
  border-left-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-l-green-600\/95 {
  border-left-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-l-green-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-700\/0 {
  border-left-color: rgb(21 128 61 / 0) !important;
}

.tw-border-l-green-700\/10 {
  border-left-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-l-green-700\/100 {
  border-left-color: rgb(21 128 61 / 1) !important;
}

.tw-border-l-green-700\/15 {
  border-left-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-l-green-700\/20 {
  border-left-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-l-green-700\/25 {
  border-left-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-l-green-700\/30 {
  border-left-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-l-green-700\/35 {
  border-left-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-l-green-700\/40 {
  border-left-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-l-green-700\/45 {
  border-left-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-l-green-700\/5 {
  border-left-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-l-green-700\/50 {
  border-left-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-l-green-700\/55 {
  border-left-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-l-green-700\/60 {
  border-left-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-l-green-700\/65 {
  border-left-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-l-green-700\/70 {
  border-left-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-l-green-700\/75 {
  border-left-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-l-green-700\/80 {
  border-left-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-l-green-700\/85 {
  border-left-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-l-green-700\/90 {
  border-left-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-l-green-700\/95 {
  border-left-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-l-green-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-800\/0 {
  border-left-color: rgb(22 101 52 / 0) !important;
}

.tw-border-l-green-800\/10 {
  border-left-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-l-green-800\/100 {
  border-left-color: rgb(22 101 52 / 1) !important;
}

.tw-border-l-green-800\/15 {
  border-left-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-l-green-800\/20 {
  border-left-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-l-green-800\/25 {
  border-left-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-l-green-800\/30 {
  border-left-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-l-green-800\/35 {
  border-left-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-l-green-800\/40 {
  border-left-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-l-green-800\/45 {
  border-left-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-l-green-800\/5 {
  border-left-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-l-green-800\/50 {
  border-left-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-l-green-800\/55 {
  border-left-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-l-green-800\/60 {
  border-left-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-l-green-800\/65 {
  border-left-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-l-green-800\/70 {
  border-left-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-l-green-800\/75 {
  border-left-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-l-green-800\/80 {
  border-left-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-l-green-800\/85 {
  border-left-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-l-green-800\/90 {
  border-left-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-l-green-800\/95 {
  border-left-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-l-green-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-900\/0 {
  border-left-color: rgb(20 83 45 / 0) !important;
}

.tw-border-l-green-900\/10 {
  border-left-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-l-green-900\/100 {
  border-left-color: rgb(20 83 45 / 1) !important;
}

.tw-border-l-green-900\/15 {
  border-left-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-l-green-900\/20 {
  border-left-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-l-green-900\/25 {
  border-left-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-l-green-900\/30 {
  border-left-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-l-green-900\/35 {
  border-left-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-l-green-900\/40 {
  border-left-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-l-green-900\/45 {
  border-left-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-l-green-900\/5 {
  border-left-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-l-green-900\/50 {
  border-left-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-l-green-900\/55 {
  border-left-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-l-green-900\/60 {
  border-left-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-l-green-900\/65 {
  border-left-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-l-green-900\/70 {
  border-left-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-l-green-900\/75 {
  border-left-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-l-green-900\/80 {
  border-left-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-l-green-900\/85 {
  border-left-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-l-green-900\/90 {
  border-left-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-l-green-900\/95 {
  border-left-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-l-green-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-l-green-950\/0 {
  border-left-color: rgb(5 46 22 / 0) !important;
}

.tw-border-l-green-950\/10 {
  border-left-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-l-green-950\/100 {
  border-left-color: rgb(5 46 22 / 1) !important;
}

.tw-border-l-green-950\/15 {
  border-left-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-l-green-950\/20 {
  border-left-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-l-green-950\/25 {
  border-left-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-l-green-950\/30 {
  border-left-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-l-green-950\/35 {
  border-left-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-l-green-950\/40 {
  border-left-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-l-green-950\/45 {
  border-left-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-l-green-950\/5 {
  border-left-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-l-green-950\/50 {
  border-left-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-l-green-950\/55 {
  border-left-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-l-green-950\/60 {
  border-left-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-l-green-950\/65 {
  border-left-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-l-green-950\/70 {
  border-left-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-l-green-950\/75 {
  border-left-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-l-green-950\/80 {
  border-left-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-l-green-950\/85 {
  border-left-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-l-green-950\/90 {
  border-left-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-l-green-950\/95 {
  border-left-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-l-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-100\/0 {
  border-left-color: rgb(224 231 255 / 0) !important;
}

.tw-border-l-indigo-100\/10 {
  border-left-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-l-indigo-100\/100 {
  border-left-color: rgb(224 231 255 / 1) !important;
}

.tw-border-l-indigo-100\/15 {
  border-left-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-l-indigo-100\/20 {
  border-left-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-l-indigo-100\/25 {
  border-left-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-l-indigo-100\/30 {
  border-left-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-l-indigo-100\/35 {
  border-left-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-l-indigo-100\/40 {
  border-left-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-l-indigo-100\/45 {
  border-left-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-l-indigo-100\/5 {
  border-left-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-l-indigo-100\/50 {
  border-left-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-l-indigo-100\/55 {
  border-left-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-l-indigo-100\/60 {
  border-left-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-l-indigo-100\/65 {
  border-left-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-l-indigo-100\/70 {
  border-left-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-l-indigo-100\/75 {
  border-left-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-l-indigo-100\/80 {
  border-left-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-l-indigo-100\/85 {
  border-left-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-l-indigo-100\/90 {
  border-left-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-l-indigo-100\/95 {
  border-left-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-l-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-200\/0 {
  border-left-color: rgb(199 210 254 / 0) !important;
}

.tw-border-l-indigo-200\/10 {
  border-left-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-l-indigo-200\/100 {
  border-left-color: rgb(199 210 254 / 1) !important;
}

.tw-border-l-indigo-200\/15 {
  border-left-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-l-indigo-200\/20 {
  border-left-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-l-indigo-200\/25 {
  border-left-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-l-indigo-200\/30 {
  border-left-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-l-indigo-200\/35 {
  border-left-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-l-indigo-200\/40 {
  border-left-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-l-indigo-200\/45 {
  border-left-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-l-indigo-200\/5 {
  border-left-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-l-indigo-200\/50 {
  border-left-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-l-indigo-200\/55 {
  border-left-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-l-indigo-200\/60 {
  border-left-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-l-indigo-200\/65 {
  border-left-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-l-indigo-200\/70 {
  border-left-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-l-indigo-200\/75 {
  border-left-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-l-indigo-200\/80 {
  border-left-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-l-indigo-200\/85 {
  border-left-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-l-indigo-200\/90 {
  border-left-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-l-indigo-200\/95 {
  border-left-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-l-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-300\/0 {
  border-left-color: rgb(165 180 252 / 0) !important;
}

.tw-border-l-indigo-300\/10 {
  border-left-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-l-indigo-300\/100 {
  border-left-color: rgb(165 180 252 / 1) !important;
}

.tw-border-l-indigo-300\/15 {
  border-left-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-l-indigo-300\/20 {
  border-left-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-l-indigo-300\/25 {
  border-left-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-l-indigo-300\/30 {
  border-left-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-l-indigo-300\/35 {
  border-left-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-l-indigo-300\/40 {
  border-left-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-l-indigo-300\/45 {
  border-left-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-l-indigo-300\/5 {
  border-left-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-l-indigo-300\/50 {
  border-left-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-l-indigo-300\/55 {
  border-left-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-l-indigo-300\/60 {
  border-left-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-l-indigo-300\/65 {
  border-left-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-l-indigo-300\/70 {
  border-left-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-l-indigo-300\/75 {
  border-left-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-l-indigo-300\/80 {
  border-left-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-l-indigo-300\/85 {
  border-left-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-l-indigo-300\/90 {
  border-left-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-l-indigo-300\/95 {
  border-left-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-l-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-400\/0 {
  border-left-color: rgb(129 140 248 / 0) !important;
}

.tw-border-l-indigo-400\/10 {
  border-left-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-l-indigo-400\/100 {
  border-left-color: rgb(129 140 248 / 1) !important;
}

.tw-border-l-indigo-400\/15 {
  border-left-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-l-indigo-400\/20 {
  border-left-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-l-indigo-400\/25 {
  border-left-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-l-indigo-400\/30 {
  border-left-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-l-indigo-400\/35 {
  border-left-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-l-indigo-400\/40 {
  border-left-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-l-indigo-400\/45 {
  border-left-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-l-indigo-400\/5 {
  border-left-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-l-indigo-400\/50 {
  border-left-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-l-indigo-400\/55 {
  border-left-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-l-indigo-400\/60 {
  border-left-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-l-indigo-400\/65 {
  border-left-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-l-indigo-400\/70 {
  border-left-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-l-indigo-400\/75 {
  border-left-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-l-indigo-400\/80 {
  border-left-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-l-indigo-400\/85 {
  border-left-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-l-indigo-400\/90 {
  border-left-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-l-indigo-400\/95 {
  border-left-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-l-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-50\/0 {
  border-left-color: rgb(238 242 255 / 0) !important;
}

.tw-border-l-indigo-50\/10 {
  border-left-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-l-indigo-50\/100 {
  border-left-color: rgb(238 242 255 / 1) !important;
}

.tw-border-l-indigo-50\/15 {
  border-left-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-l-indigo-50\/20 {
  border-left-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-l-indigo-50\/25 {
  border-left-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-l-indigo-50\/30 {
  border-left-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-l-indigo-50\/35 {
  border-left-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-l-indigo-50\/40 {
  border-left-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-l-indigo-50\/45 {
  border-left-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-l-indigo-50\/5 {
  border-left-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-l-indigo-50\/50 {
  border-left-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-l-indigo-50\/55 {
  border-left-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-l-indigo-50\/60 {
  border-left-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-l-indigo-50\/65 {
  border-left-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-l-indigo-50\/70 {
  border-left-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-l-indigo-50\/75 {
  border-left-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-l-indigo-50\/80 {
  border-left-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-l-indigo-50\/85 {
  border-left-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-l-indigo-50\/90 {
  border-left-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-l-indigo-50\/95 {
  border-left-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-l-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-500\/0 {
  border-left-color: rgb(99 102 241 / 0) !important;
}

.tw-border-l-indigo-500\/10 {
  border-left-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-l-indigo-500\/100 {
  border-left-color: rgb(99 102 241 / 1) !important;
}

.tw-border-l-indigo-500\/15 {
  border-left-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-l-indigo-500\/20 {
  border-left-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-l-indigo-500\/25 {
  border-left-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-l-indigo-500\/30 {
  border-left-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-l-indigo-500\/35 {
  border-left-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-l-indigo-500\/40 {
  border-left-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-l-indigo-500\/45 {
  border-left-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-l-indigo-500\/5 {
  border-left-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-l-indigo-500\/50 {
  border-left-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-l-indigo-500\/55 {
  border-left-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-l-indigo-500\/60 {
  border-left-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-l-indigo-500\/65 {
  border-left-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-l-indigo-500\/70 {
  border-left-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-l-indigo-500\/75 {
  border-left-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-l-indigo-500\/80 {
  border-left-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-l-indigo-500\/85 {
  border-left-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-l-indigo-500\/90 {
  border-left-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-l-indigo-500\/95 {
  border-left-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-l-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-600\/0 {
  border-left-color: rgb(79 70 229 / 0) !important;
}

.tw-border-l-indigo-600\/10 {
  border-left-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-l-indigo-600\/100 {
  border-left-color: rgb(79 70 229 / 1) !important;
}

.tw-border-l-indigo-600\/15 {
  border-left-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-l-indigo-600\/20 {
  border-left-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-l-indigo-600\/25 {
  border-left-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-l-indigo-600\/30 {
  border-left-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-l-indigo-600\/35 {
  border-left-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-l-indigo-600\/40 {
  border-left-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-l-indigo-600\/45 {
  border-left-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-l-indigo-600\/5 {
  border-left-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-l-indigo-600\/50 {
  border-left-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-l-indigo-600\/55 {
  border-left-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-l-indigo-600\/60 {
  border-left-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-l-indigo-600\/65 {
  border-left-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-l-indigo-600\/70 {
  border-left-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-l-indigo-600\/75 {
  border-left-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-l-indigo-600\/80 {
  border-left-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-l-indigo-600\/85 {
  border-left-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-l-indigo-600\/90 {
  border-left-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-l-indigo-600\/95 {
  border-left-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-l-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-700\/0 {
  border-left-color: rgb(67 56 202 / 0) !important;
}

.tw-border-l-indigo-700\/10 {
  border-left-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-l-indigo-700\/100 {
  border-left-color: rgb(67 56 202 / 1) !important;
}

.tw-border-l-indigo-700\/15 {
  border-left-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-l-indigo-700\/20 {
  border-left-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-l-indigo-700\/25 {
  border-left-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-l-indigo-700\/30 {
  border-left-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-l-indigo-700\/35 {
  border-left-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-l-indigo-700\/40 {
  border-left-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-l-indigo-700\/45 {
  border-left-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-l-indigo-700\/5 {
  border-left-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-l-indigo-700\/50 {
  border-left-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-l-indigo-700\/55 {
  border-left-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-l-indigo-700\/60 {
  border-left-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-l-indigo-700\/65 {
  border-left-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-l-indigo-700\/70 {
  border-left-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-l-indigo-700\/75 {
  border-left-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-l-indigo-700\/80 {
  border-left-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-l-indigo-700\/85 {
  border-left-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-l-indigo-700\/90 {
  border-left-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-l-indigo-700\/95 {
  border-left-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-l-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-800\/0 {
  border-left-color: rgb(55 48 163 / 0) !important;
}

.tw-border-l-indigo-800\/10 {
  border-left-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-l-indigo-800\/100 {
  border-left-color: rgb(55 48 163 / 1) !important;
}

.tw-border-l-indigo-800\/15 {
  border-left-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-l-indigo-800\/20 {
  border-left-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-l-indigo-800\/25 {
  border-left-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-l-indigo-800\/30 {
  border-left-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-l-indigo-800\/35 {
  border-left-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-l-indigo-800\/40 {
  border-left-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-l-indigo-800\/45 {
  border-left-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-l-indigo-800\/5 {
  border-left-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-l-indigo-800\/50 {
  border-left-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-l-indigo-800\/55 {
  border-left-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-l-indigo-800\/60 {
  border-left-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-l-indigo-800\/65 {
  border-left-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-l-indigo-800\/70 {
  border-left-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-l-indigo-800\/75 {
  border-left-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-l-indigo-800\/80 {
  border-left-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-l-indigo-800\/85 {
  border-left-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-l-indigo-800\/90 {
  border-left-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-l-indigo-800\/95 {
  border-left-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-l-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-900\/0 {
  border-left-color: rgb(49 46 129 / 0) !important;
}

.tw-border-l-indigo-900\/10 {
  border-left-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-l-indigo-900\/100 {
  border-left-color: rgb(49 46 129 / 1) !important;
}

.tw-border-l-indigo-900\/15 {
  border-left-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-l-indigo-900\/20 {
  border-left-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-l-indigo-900\/25 {
  border-left-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-l-indigo-900\/30 {
  border-left-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-l-indigo-900\/35 {
  border-left-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-l-indigo-900\/40 {
  border-left-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-l-indigo-900\/45 {
  border-left-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-l-indigo-900\/5 {
  border-left-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-l-indigo-900\/50 {
  border-left-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-l-indigo-900\/55 {
  border-left-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-l-indigo-900\/60 {
  border-left-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-l-indigo-900\/65 {
  border-left-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-l-indigo-900\/70 {
  border-left-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-l-indigo-900\/75 {
  border-left-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-l-indigo-900\/80 {
  border-left-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-l-indigo-900\/85 {
  border-left-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-l-indigo-900\/90 {
  border-left-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-l-indigo-900\/95 {
  border-left-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-l-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-l-indigo-950\/0 {
  border-left-color: rgb(30 27 75 / 0) !important;
}

.tw-border-l-indigo-950\/10 {
  border-left-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-l-indigo-950\/100 {
  border-left-color: rgb(30 27 75 / 1) !important;
}

.tw-border-l-indigo-950\/15 {
  border-left-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-l-indigo-950\/20 {
  border-left-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-l-indigo-950\/25 {
  border-left-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-l-indigo-950\/30 {
  border-left-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-l-indigo-950\/35 {
  border-left-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-l-indigo-950\/40 {
  border-left-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-l-indigo-950\/45 {
  border-left-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-l-indigo-950\/5 {
  border-left-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-l-indigo-950\/50 {
  border-left-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-l-indigo-950\/55 {
  border-left-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-l-indigo-950\/60 {
  border-left-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-l-indigo-950\/65 {
  border-left-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-l-indigo-950\/70 {
  border-left-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-l-indigo-950\/75 {
  border-left-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-l-indigo-950\/80 {
  border-left-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-l-indigo-950\/85 {
  border-left-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-l-indigo-950\/90 {
  border-left-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-l-indigo-950\/95 {
  border-left-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-l-inherit {
  border-left-color: inherit !important;
}

.tw-border-l-lime-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-100\/0 {
  border-left-color: rgb(236 252 203 / 0) !important;
}

.tw-border-l-lime-100\/10 {
  border-left-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-l-lime-100\/100 {
  border-left-color: rgb(236 252 203 / 1) !important;
}

.tw-border-l-lime-100\/15 {
  border-left-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-l-lime-100\/20 {
  border-left-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-l-lime-100\/25 {
  border-left-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-l-lime-100\/30 {
  border-left-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-l-lime-100\/35 {
  border-left-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-l-lime-100\/40 {
  border-left-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-l-lime-100\/45 {
  border-left-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-l-lime-100\/5 {
  border-left-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-l-lime-100\/50 {
  border-left-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-l-lime-100\/55 {
  border-left-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-l-lime-100\/60 {
  border-left-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-l-lime-100\/65 {
  border-left-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-l-lime-100\/70 {
  border-left-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-l-lime-100\/75 {
  border-left-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-l-lime-100\/80 {
  border-left-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-l-lime-100\/85 {
  border-left-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-l-lime-100\/90 {
  border-left-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-l-lime-100\/95 {
  border-left-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-l-lime-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-200\/0 {
  border-left-color: rgb(217 249 157 / 0) !important;
}

.tw-border-l-lime-200\/10 {
  border-left-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-l-lime-200\/100 {
  border-left-color: rgb(217 249 157 / 1) !important;
}

.tw-border-l-lime-200\/15 {
  border-left-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-l-lime-200\/20 {
  border-left-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-l-lime-200\/25 {
  border-left-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-l-lime-200\/30 {
  border-left-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-l-lime-200\/35 {
  border-left-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-l-lime-200\/40 {
  border-left-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-l-lime-200\/45 {
  border-left-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-l-lime-200\/5 {
  border-left-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-l-lime-200\/50 {
  border-left-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-l-lime-200\/55 {
  border-left-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-l-lime-200\/60 {
  border-left-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-l-lime-200\/65 {
  border-left-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-l-lime-200\/70 {
  border-left-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-l-lime-200\/75 {
  border-left-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-l-lime-200\/80 {
  border-left-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-l-lime-200\/85 {
  border-left-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-l-lime-200\/90 {
  border-left-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-l-lime-200\/95 {
  border-left-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-l-lime-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-300\/0 {
  border-left-color: rgb(190 242 100 / 0) !important;
}

.tw-border-l-lime-300\/10 {
  border-left-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-l-lime-300\/100 {
  border-left-color: rgb(190 242 100 / 1) !important;
}

.tw-border-l-lime-300\/15 {
  border-left-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-l-lime-300\/20 {
  border-left-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-l-lime-300\/25 {
  border-left-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-l-lime-300\/30 {
  border-left-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-l-lime-300\/35 {
  border-left-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-l-lime-300\/40 {
  border-left-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-l-lime-300\/45 {
  border-left-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-l-lime-300\/5 {
  border-left-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-l-lime-300\/50 {
  border-left-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-l-lime-300\/55 {
  border-left-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-l-lime-300\/60 {
  border-left-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-l-lime-300\/65 {
  border-left-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-l-lime-300\/70 {
  border-left-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-l-lime-300\/75 {
  border-left-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-l-lime-300\/80 {
  border-left-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-l-lime-300\/85 {
  border-left-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-l-lime-300\/90 {
  border-left-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-l-lime-300\/95 {
  border-left-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-l-lime-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-400\/0 {
  border-left-color: rgb(163 230 53 / 0) !important;
}

.tw-border-l-lime-400\/10 {
  border-left-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-l-lime-400\/100 {
  border-left-color: rgb(163 230 53 / 1) !important;
}

.tw-border-l-lime-400\/15 {
  border-left-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-l-lime-400\/20 {
  border-left-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-l-lime-400\/25 {
  border-left-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-l-lime-400\/30 {
  border-left-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-l-lime-400\/35 {
  border-left-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-l-lime-400\/40 {
  border-left-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-l-lime-400\/45 {
  border-left-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-l-lime-400\/5 {
  border-left-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-l-lime-400\/50 {
  border-left-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-l-lime-400\/55 {
  border-left-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-l-lime-400\/60 {
  border-left-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-l-lime-400\/65 {
  border-left-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-l-lime-400\/70 {
  border-left-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-l-lime-400\/75 {
  border-left-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-l-lime-400\/80 {
  border-left-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-l-lime-400\/85 {
  border-left-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-l-lime-400\/90 {
  border-left-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-l-lime-400\/95 {
  border-left-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-l-lime-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-50\/0 {
  border-left-color: rgb(247 254 231 / 0) !important;
}

.tw-border-l-lime-50\/10 {
  border-left-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-l-lime-50\/100 {
  border-left-color: rgb(247 254 231 / 1) !important;
}

.tw-border-l-lime-50\/15 {
  border-left-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-l-lime-50\/20 {
  border-left-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-l-lime-50\/25 {
  border-left-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-l-lime-50\/30 {
  border-left-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-l-lime-50\/35 {
  border-left-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-l-lime-50\/40 {
  border-left-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-l-lime-50\/45 {
  border-left-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-l-lime-50\/5 {
  border-left-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-l-lime-50\/50 {
  border-left-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-l-lime-50\/55 {
  border-left-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-l-lime-50\/60 {
  border-left-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-l-lime-50\/65 {
  border-left-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-l-lime-50\/70 {
  border-left-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-l-lime-50\/75 {
  border-left-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-l-lime-50\/80 {
  border-left-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-l-lime-50\/85 {
  border-left-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-l-lime-50\/90 {
  border-left-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-l-lime-50\/95 {
  border-left-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-l-lime-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-500\/0 {
  border-left-color: rgb(132 204 22 / 0) !important;
}

.tw-border-l-lime-500\/10 {
  border-left-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-l-lime-500\/100 {
  border-left-color: rgb(132 204 22 / 1) !important;
}

.tw-border-l-lime-500\/15 {
  border-left-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-l-lime-500\/20 {
  border-left-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-l-lime-500\/25 {
  border-left-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-l-lime-500\/30 {
  border-left-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-l-lime-500\/35 {
  border-left-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-l-lime-500\/40 {
  border-left-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-l-lime-500\/45 {
  border-left-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-l-lime-500\/5 {
  border-left-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-l-lime-500\/50 {
  border-left-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-l-lime-500\/55 {
  border-left-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-l-lime-500\/60 {
  border-left-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-l-lime-500\/65 {
  border-left-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-l-lime-500\/70 {
  border-left-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-l-lime-500\/75 {
  border-left-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-l-lime-500\/80 {
  border-left-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-l-lime-500\/85 {
  border-left-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-l-lime-500\/90 {
  border-left-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-l-lime-500\/95 {
  border-left-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-l-lime-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-600\/0 {
  border-left-color: rgb(101 163 13 / 0) !important;
}

.tw-border-l-lime-600\/10 {
  border-left-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-l-lime-600\/100 {
  border-left-color: rgb(101 163 13 / 1) !important;
}

.tw-border-l-lime-600\/15 {
  border-left-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-l-lime-600\/20 {
  border-left-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-l-lime-600\/25 {
  border-left-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-l-lime-600\/30 {
  border-left-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-l-lime-600\/35 {
  border-left-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-l-lime-600\/40 {
  border-left-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-l-lime-600\/45 {
  border-left-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-l-lime-600\/5 {
  border-left-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-l-lime-600\/50 {
  border-left-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-l-lime-600\/55 {
  border-left-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-l-lime-600\/60 {
  border-left-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-l-lime-600\/65 {
  border-left-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-l-lime-600\/70 {
  border-left-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-l-lime-600\/75 {
  border-left-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-l-lime-600\/80 {
  border-left-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-l-lime-600\/85 {
  border-left-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-l-lime-600\/90 {
  border-left-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-l-lime-600\/95 {
  border-left-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-l-lime-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-700\/0 {
  border-left-color: rgb(77 124 15 / 0) !important;
}

.tw-border-l-lime-700\/10 {
  border-left-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-l-lime-700\/100 {
  border-left-color: rgb(77 124 15 / 1) !important;
}

.tw-border-l-lime-700\/15 {
  border-left-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-l-lime-700\/20 {
  border-left-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-l-lime-700\/25 {
  border-left-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-l-lime-700\/30 {
  border-left-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-l-lime-700\/35 {
  border-left-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-l-lime-700\/40 {
  border-left-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-l-lime-700\/45 {
  border-left-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-l-lime-700\/5 {
  border-left-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-l-lime-700\/50 {
  border-left-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-l-lime-700\/55 {
  border-left-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-l-lime-700\/60 {
  border-left-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-l-lime-700\/65 {
  border-left-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-l-lime-700\/70 {
  border-left-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-l-lime-700\/75 {
  border-left-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-l-lime-700\/80 {
  border-left-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-l-lime-700\/85 {
  border-left-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-l-lime-700\/90 {
  border-left-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-l-lime-700\/95 {
  border-left-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-l-lime-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-800\/0 {
  border-left-color: rgb(63 98 18 / 0) !important;
}

.tw-border-l-lime-800\/10 {
  border-left-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-l-lime-800\/100 {
  border-left-color: rgb(63 98 18 / 1) !important;
}

.tw-border-l-lime-800\/15 {
  border-left-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-l-lime-800\/20 {
  border-left-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-l-lime-800\/25 {
  border-left-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-l-lime-800\/30 {
  border-left-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-l-lime-800\/35 {
  border-left-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-l-lime-800\/40 {
  border-left-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-l-lime-800\/45 {
  border-left-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-l-lime-800\/5 {
  border-left-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-l-lime-800\/50 {
  border-left-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-l-lime-800\/55 {
  border-left-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-l-lime-800\/60 {
  border-left-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-l-lime-800\/65 {
  border-left-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-l-lime-800\/70 {
  border-left-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-l-lime-800\/75 {
  border-left-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-l-lime-800\/80 {
  border-left-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-l-lime-800\/85 {
  border-left-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-l-lime-800\/90 {
  border-left-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-l-lime-800\/95 {
  border-left-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-l-lime-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-900\/0 {
  border-left-color: rgb(54 83 20 / 0) !important;
}

.tw-border-l-lime-900\/10 {
  border-left-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-l-lime-900\/100 {
  border-left-color: rgb(54 83 20 / 1) !important;
}

.tw-border-l-lime-900\/15 {
  border-left-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-l-lime-900\/20 {
  border-left-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-l-lime-900\/25 {
  border-left-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-l-lime-900\/30 {
  border-left-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-l-lime-900\/35 {
  border-left-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-l-lime-900\/40 {
  border-left-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-l-lime-900\/45 {
  border-left-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-l-lime-900\/5 {
  border-left-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-l-lime-900\/50 {
  border-left-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-l-lime-900\/55 {
  border-left-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-l-lime-900\/60 {
  border-left-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-l-lime-900\/65 {
  border-left-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-l-lime-900\/70 {
  border-left-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-l-lime-900\/75 {
  border-left-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-l-lime-900\/80 {
  border-left-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-l-lime-900\/85 {
  border-left-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-l-lime-900\/90 {
  border-left-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-l-lime-900\/95 {
  border-left-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-l-lime-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-l-lime-950\/0 {
  border-left-color: rgb(26 46 5 / 0) !important;
}

.tw-border-l-lime-950\/10 {
  border-left-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-l-lime-950\/100 {
  border-left-color: rgb(26 46 5 / 1) !important;
}

.tw-border-l-lime-950\/15 {
  border-left-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-l-lime-950\/20 {
  border-left-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-l-lime-950\/25 {
  border-left-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-l-lime-950\/30 {
  border-left-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-l-lime-950\/35 {
  border-left-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-l-lime-950\/40 {
  border-left-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-l-lime-950\/45 {
  border-left-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-l-lime-950\/5 {
  border-left-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-l-lime-950\/50 {
  border-left-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-l-lime-950\/55 {
  border-left-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-l-lime-950\/60 {
  border-left-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-l-lime-950\/65 {
  border-left-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-l-lime-950\/70 {
  border-left-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-l-lime-950\/75 {
  border-left-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-l-lime-950\/80 {
  border-left-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-l-lime-950\/85 {
  border-left-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-l-lime-950\/90 {
  border-left-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-l-lime-950\/95 {
  border-left-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-l-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-100\/0 {
  border-left-color: rgb(245 245 245 / 0) !important;
}

.tw-border-l-neutral-100\/10 {
  border-left-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-l-neutral-100\/100 {
  border-left-color: rgb(245 245 245 / 1) !important;
}

.tw-border-l-neutral-100\/15 {
  border-left-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-l-neutral-100\/20 {
  border-left-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-l-neutral-100\/25 {
  border-left-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-l-neutral-100\/30 {
  border-left-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-l-neutral-100\/35 {
  border-left-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-l-neutral-100\/40 {
  border-left-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-l-neutral-100\/45 {
  border-left-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-l-neutral-100\/5 {
  border-left-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-l-neutral-100\/50 {
  border-left-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-l-neutral-100\/55 {
  border-left-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-l-neutral-100\/60 {
  border-left-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-l-neutral-100\/65 {
  border-left-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-l-neutral-100\/70 {
  border-left-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-l-neutral-100\/75 {
  border-left-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-l-neutral-100\/80 {
  border-left-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-l-neutral-100\/85 {
  border-left-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-l-neutral-100\/90 {
  border-left-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-l-neutral-100\/95 {
  border-left-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-l-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-200\/0 {
  border-left-color: rgb(229 229 229 / 0) !important;
}

.tw-border-l-neutral-200\/10 {
  border-left-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-l-neutral-200\/100 {
  border-left-color: rgb(229 229 229 / 1) !important;
}

.tw-border-l-neutral-200\/15 {
  border-left-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-l-neutral-200\/20 {
  border-left-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-l-neutral-200\/25 {
  border-left-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-l-neutral-200\/30 {
  border-left-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-l-neutral-200\/35 {
  border-left-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-l-neutral-200\/40 {
  border-left-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-l-neutral-200\/45 {
  border-left-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-l-neutral-200\/5 {
  border-left-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-l-neutral-200\/50 {
  border-left-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-l-neutral-200\/55 {
  border-left-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-l-neutral-200\/60 {
  border-left-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-l-neutral-200\/65 {
  border-left-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-l-neutral-200\/70 {
  border-left-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-l-neutral-200\/75 {
  border-left-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-l-neutral-200\/80 {
  border-left-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-l-neutral-200\/85 {
  border-left-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-l-neutral-200\/90 {
  border-left-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-l-neutral-200\/95 {
  border-left-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-l-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-300\/0 {
  border-left-color: rgb(212 212 212 / 0) !important;
}

.tw-border-l-neutral-300\/10 {
  border-left-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-l-neutral-300\/100 {
  border-left-color: rgb(212 212 212 / 1) !important;
}

.tw-border-l-neutral-300\/15 {
  border-left-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-l-neutral-300\/20 {
  border-left-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-l-neutral-300\/25 {
  border-left-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-l-neutral-300\/30 {
  border-left-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-l-neutral-300\/35 {
  border-left-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-l-neutral-300\/40 {
  border-left-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-l-neutral-300\/45 {
  border-left-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-l-neutral-300\/5 {
  border-left-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-l-neutral-300\/50 {
  border-left-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-l-neutral-300\/55 {
  border-left-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-l-neutral-300\/60 {
  border-left-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-l-neutral-300\/65 {
  border-left-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-l-neutral-300\/70 {
  border-left-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-l-neutral-300\/75 {
  border-left-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-l-neutral-300\/80 {
  border-left-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-l-neutral-300\/85 {
  border-left-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-l-neutral-300\/90 {
  border-left-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-l-neutral-300\/95 {
  border-left-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-l-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-400\/0 {
  border-left-color: rgb(163 163 163 / 0) !important;
}

.tw-border-l-neutral-400\/10 {
  border-left-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-l-neutral-400\/100 {
  border-left-color: rgb(163 163 163 / 1) !important;
}

.tw-border-l-neutral-400\/15 {
  border-left-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-l-neutral-400\/20 {
  border-left-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-l-neutral-400\/25 {
  border-left-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-l-neutral-400\/30 {
  border-left-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-l-neutral-400\/35 {
  border-left-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-l-neutral-400\/40 {
  border-left-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-l-neutral-400\/45 {
  border-left-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-l-neutral-400\/5 {
  border-left-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-l-neutral-400\/50 {
  border-left-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-l-neutral-400\/55 {
  border-left-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-l-neutral-400\/60 {
  border-left-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-l-neutral-400\/65 {
  border-left-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-l-neutral-400\/70 {
  border-left-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-l-neutral-400\/75 {
  border-left-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-l-neutral-400\/80 {
  border-left-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-l-neutral-400\/85 {
  border-left-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-l-neutral-400\/90 {
  border-left-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-l-neutral-400\/95 {
  border-left-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-l-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-50\/0 {
  border-left-color: rgb(250 250 250 / 0) !important;
}

.tw-border-l-neutral-50\/10 {
  border-left-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-l-neutral-50\/100 {
  border-left-color: rgb(250 250 250 / 1) !important;
}

.tw-border-l-neutral-50\/15 {
  border-left-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-l-neutral-50\/20 {
  border-left-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-l-neutral-50\/25 {
  border-left-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-l-neutral-50\/30 {
  border-left-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-l-neutral-50\/35 {
  border-left-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-l-neutral-50\/40 {
  border-left-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-l-neutral-50\/45 {
  border-left-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-l-neutral-50\/5 {
  border-left-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-l-neutral-50\/50 {
  border-left-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-l-neutral-50\/55 {
  border-left-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-l-neutral-50\/60 {
  border-left-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-l-neutral-50\/65 {
  border-left-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-l-neutral-50\/70 {
  border-left-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-l-neutral-50\/75 {
  border-left-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-l-neutral-50\/80 {
  border-left-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-l-neutral-50\/85 {
  border-left-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-l-neutral-50\/90 {
  border-left-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-l-neutral-50\/95 {
  border-left-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-l-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-500\/0 {
  border-left-color: rgb(115 115 115 / 0) !important;
}

.tw-border-l-neutral-500\/10 {
  border-left-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-l-neutral-500\/100 {
  border-left-color: rgb(115 115 115 / 1) !important;
}

.tw-border-l-neutral-500\/15 {
  border-left-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-l-neutral-500\/20 {
  border-left-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-l-neutral-500\/25 {
  border-left-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-l-neutral-500\/30 {
  border-left-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-l-neutral-500\/35 {
  border-left-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-l-neutral-500\/40 {
  border-left-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-l-neutral-500\/45 {
  border-left-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-l-neutral-500\/5 {
  border-left-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-l-neutral-500\/50 {
  border-left-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-l-neutral-500\/55 {
  border-left-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-l-neutral-500\/60 {
  border-left-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-l-neutral-500\/65 {
  border-left-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-l-neutral-500\/70 {
  border-left-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-l-neutral-500\/75 {
  border-left-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-l-neutral-500\/80 {
  border-left-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-l-neutral-500\/85 {
  border-left-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-l-neutral-500\/90 {
  border-left-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-l-neutral-500\/95 {
  border-left-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-l-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-600\/0 {
  border-left-color: rgb(82 82 82 / 0) !important;
}

.tw-border-l-neutral-600\/10 {
  border-left-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-l-neutral-600\/100 {
  border-left-color: rgb(82 82 82 / 1) !important;
}

.tw-border-l-neutral-600\/15 {
  border-left-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-l-neutral-600\/20 {
  border-left-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-l-neutral-600\/25 {
  border-left-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-l-neutral-600\/30 {
  border-left-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-l-neutral-600\/35 {
  border-left-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-l-neutral-600\/40 {
  border-left-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-l-neutral-600\/45 {
  border-left-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-l-neutral-600\/5 {
  border-left-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-l-neutral-600\/50 {
  border-left-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-l-neutral-600\/55 {
  border-left-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-l-neutral-600\/60 {
  border-left-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-l-neutral-600\/65 {
  border-left-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-l-neutral-600\/70 {
  border-left-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-l-neutral-600\/75 {
  border-left-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-l-neutral-600\/80 {
  border-left-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-l-neutral-600\/85 {
  border-left-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-l-neutral-600\/90 {
  border-left-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-l-neutral-600\/95 {
  border-left-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-l-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-700\/0 {
  border-left-color: rgb(64 64 64 / 0) !important;
}

.tw-border-l-neutral-700\/10 {
  border-left-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-l-neutral-700\/100 {
  border-left-color: rgb(64 64 64 / 1) !important;
}

.tw-border-l-neutral-700\/15 {
  border-left-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-l-neutral-700\/20 {
  border-left-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-l-neutral-700\/25 {
  border-left-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-l-neutral-700\/30 {
  border-left-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-l-neutral-700\/35 {
  border-left-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-l-neutral-700\/40 {
  border-left-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-l-neutral-700\/45 {
  border-left-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-l-neutral-700\/5 {
  border-left-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-l-neutral-700\/50 {
  border-left-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-l-neutral-700\/55 {
  border-left-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-l-neutral-700\/60 {
  border-left-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-l-neutral-700\/65 {
  border-left-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-l-neutral-700\/70 {
  border-left-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-l-neutral-700\/75 {
  border-left-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-l-neutral-700\/80 {
  border-left-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-l-neutral-700\/85 {
  border-left-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-l-neutral-700\/90 {
  border-left-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-l-neutral-700\/95 {
  border-left-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-l-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-800\/0 {
  border-left-color: rgb(38 38 38 / 0) !important;
}

.tw-border-l-neutral-800\/10 {
  border-left-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-l-neutral-800\/100 {
  border-left-color: rgb(38 38 38 / 1) !important;
}

.tw-border-l-neutral-800\/15 {
  border-left-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-l-neutral-800\/20 {
  border-left-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-l-neutral-800\/25 {
  border-left-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-l-neutral-800\/30 {
  border-left-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-l-neutral-800\/35 {
  border-left-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-l-neutral-800\/40 {
  border-left-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-l-neutral-800\/45 {
  border-left-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-l-neutral-800\/5 {
  border-left-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-l-neutral-800\/50 {
  border-left-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-l-neutral-800\/55 {
  border-left-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-l-neutral-800\/60 {
  border-left-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-l-neutral-800\/65 {
  border-left-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-l-neutral-800\/70 {
  border-left-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-l-neutral-800\/75 {
  border-left-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-l-neutral-800\/80 {
  border-left-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-l-neutral-800\/85 {
  border-left-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-l-neutral-800\/90 {
  border-left-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-l-neutral-800\/95 {
  border-left-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-l-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-900\/0 {
  border-left-color: rgb(23 23 23 / 0) !important;
}

.tw-border-l-neutral-900\/10 {
  border-left-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-l-neutral-900\/100 {
  border-left-color: rgb(23 23 23 / 1) !important;
}

.tw-border-l-neutral-900\/15 {
  border-left-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-l-neutral-900\/20 {
  border-left-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-l-neutral-900\/25 {
  border-left-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-l-neutral-900\/30 {
  border-left-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-l-neutral-900\/35 {
  border-left-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-l-neutral-900\/40 {
  border-left-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-l-neutral-900\/45 {
  border-left-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-l-neutral-900\/5 {
  border-left-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-l-neutral-900\/50 {
  border-left-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-l-neutral-900\/55 {
  border-left-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-l-neutral-900\/60 {
  border-left-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-l-neutral-900\/65 {
  border-left-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-l-neutral-900\/70 {
  border-left-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-l-neutral-900\/75 {
  border-left-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-l-neutral-900\/80 {
  border-left-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-l-neutral-900\/85 {
  border-left-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-l-neutral-900\/90 {
  border-left-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-l-neutral-900\/95 {
  border-left-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-l-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-l-neutral-950\/0 {
  border-left-color: rgb(10 10 10 / 0) !important;
}

.tw-border-l-neutral-950\/10 {
  border-left-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-l-neutral-950\/100 {
  border-left-color: rgb(10 10 10 / 1) !important;
}

.tw-border-l-neutral-950\/15 {
  border-left-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-l-neutral-950\/20 {
  border-left-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-l-neutral-950\/25 {
  border-left-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-l-neutral-950\/30 {
  border-left-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-l-neutral-950\/35 {
  border-left-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-l-neutral-950\/40 {
  border-left-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-l-neutral-950\/45 {
  border-left-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-l-neutral-950\/5 {
  border-left-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-l-neutral-950\/50 {
  border-left-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-l-neutral-950\/55 {
  border-left-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-l-neutral-950\/60 {
  border-left-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-l-neutral-950\/65 {
  border-left-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-l-neutral-950\/70 {
  border-left-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-l-neutral-950\/75 {
  border-left-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-l-neutral-950\/80 {
  border-left-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-l-neutral-950\/85 {
  border-left-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-l-neutral-950\/90 {
  border-left-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-l-neutral-950\/95 {
  border-left-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-l-orange-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-100\/0 {
  border-left-color: rgb(255 237 213 / 0) !important;
}

.tw-border-l-orange-100\/10 {
  border-left-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-l-orange-100\/100 {
  border-left-color: rgb(255 237 213 / 1) !important;
}

.tw-border-l-orange-100\/15 {
  border-left-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-l-orange-100\/20 {
  border-left-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-l-orange-100\/25 {
  border-left-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-l-orange-100\/30 {
  border-left-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-l-orange-100\/35 {
  border-left-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-l-orange-100\/40 {
  border-left-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-l-orange-100\/45 {
  border-left-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-l-orange-100\/5 {
  border-left-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-l-orange-100\/50 {
  border-left-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-l-orange-100\/55 {
  border-left-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-l-orange-100\/60 {
  border-left-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-l-orange-100\/65 {
  border-left-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-l-orange-100\/70 {
  border-left-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-l-orange-100\/75 {
  border-left-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-l-orange-100\/80 {
  border-left-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-l-orange-100\/85 {
  border-left-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-l-orange-100\/90 {
  border-left-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-l-orange-100\/95 {
  border-left-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-l-orange-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-200\/0 {
  border-left-color: rgb(254 215 170 / 0) !important;
}

.tw-border-l-orange-200\/10 {
  border-left-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-l-orange-200\/100 {
  border-left-color: rgb(254 215 170 / 1) !important;
}

.tw-border-l-orange-200\/15 {
  border-left-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-l-orange-200\/20 {
  border-left-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-l-orange-200\/25 {
  border-left-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-l-orange-200\/30 {
  border-left-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-l-orange-200\/35 {
  border-left-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-l-orange-200\/40 {
  border-left-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-l-orange-200\/45 {
  border-left-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-l-orange-200\/5 {
  border-left-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-l-orange-200\/50 {
  border-left-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-l-orange-200\/55 {
  border-left-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-l-orange-200\/60 {
  border-left-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-l-orange-200\/65 {
  border-left-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-l-orange-200\/70 {
  border-left-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-l-orange-200\/75 {
  border-left-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-l-orange-200\/80 {
  border-left-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-l-orange-200\/85 {
  border-left-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-l-orange-200\/90 {
  border-left-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-l-orange-200\/95 {
  border-left-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-l-orange-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-300\/0 {
  border-left-color: rgb(253 186 116 / 0) !important;
}

.tw-border-l-orange-300\/10 {
  border-left-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-l-orange-300\/100 {
  border-left-color: rgb(253 186 116 / 1) !important;
}

.tw-border-l-orange-300\/15 {
  border-left-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-l-orange-300\/20 {
  border-left-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-l-orange-300\/25 {
  border-left-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-l-orange-300\/30 {
  border-left-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-l-orange-300\/35 {
  border-left-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-l-orange-300\/40 {
  border-left-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-l-orange-300\/45 {
  border-left-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-l-orange-300\/5 {
  border-left-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-l-orange-300\/50 {
  border-left-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-l-orange-300\/55 {
  border-left-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-l-orange-300\/60 {
  border-left-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-l-orange-300\/65 {
  border-left-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-l-orange-300\/70 {
  border-left-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-l-orange-300\/75 {
  border-left-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-l-orange-300\/80 {
  border-left-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-l-orange-300\/85 {
  border-left-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-l-orange-300\/90 {
  border-left-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-l-orange-300\/95 {
  border-left-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-l-orange-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-400\/0 {
  border-left-color: rgb(251 146 60 / 0) !important;
}

.tw-border-l-orange-400\/10 {
  border-left-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-l-orange-400\/100 {
  border-left-color: rgb(251 146 60 / 1) !important;
}

.tw-border-l-orange-400\/15 {
  border-left-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-l-orange-400\/20 {
  border-left-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-l-orange-400\/25 {
  border-left-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-l-orange-400\/30 {
  border-left-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-l-orange-400\/35 {
  border-left-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-l-orange-400\/40 {
  border-left-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-l-orange-400\/45 {
  border-left-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-l-orange-400\/5 {
  border-left-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-l-orange-400\/50 {
  border-left-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-l-orange-400\/55 {
  border-left-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-l-orange-400\/60 {
  border-left-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-l-orange-400\/65 {
  border-left-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-l-orange-400\/70 {
  border-left-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-l-orange-400\/75 {
  border-left-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-l-orange-400\/80 {
  border-left-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-l-orange-400\/85 {
  border-left-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-l-orange-400\/90 {
  border-left-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-l-orange-400\/95 {
  border-left-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-l-orange-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-50\/0 {
  border-left-color: rgb(255 247 237 / 0) !important;
}

.tw-border-l-orange-50\/10 {
  border-left-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-l-orange-50\/100 {
  border-left-color: rgb(255 247 237 / 1) !important;
}

.tw-border-l-orange-50\/15 {
  border-left-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-l-orange-50\/20 {
  border-left-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-l-orange-50\/25 {
  border-left-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-l-orange-50\/30 {
  border-left-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-l-orange-50\/35 {
  border-left-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-l-orange-50\/40 {
  border-left-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-l-orange-50\/45 {
  border-left-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-l-orange-50\/5 {
  border-left-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-l-orange-50\/50 {
  border-left-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-l-orange-50\/55 {
  border-left-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-l-orange-50\/60 {
  border-left-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-l-orange-50\/65 {
  border-left-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-l-orange-50\/70 {
  border-left-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-l-orange-50\/75 {
  border-left-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-l-orange-50\/80 {
  border-left-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-l-orange-50\/85 {
  border-left-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-l-orange-50\/90 {
  border-left-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-l-orange-50\/95 {
  border-left-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-l-orange-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-500\/0 {
  border-left-color: rgb(249 115 22 / 0) !important;
}

.tw-border-l-orange-500\/10 {
  border-left-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-l-orange-500\/100 {
  border-left-color: rgb(249 115 22 / 1) !important;
}

.tw-border-l-orange-500\/15 {
  border-left-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-l-orange-500\/20 {
  border-left-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-l-orange-500\/25 {
  border-left-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-l-orange-500\/30 {
  border-left-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-l-orange-500\/35 {
  border-left-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-l-orange-500\/40 {
  border-left-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-l-orange-500\/45 {
  border-left-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-l-orange-500\/5 {
  border-left-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-l-orange-500\/50 {
  border-left-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-l-orange-500\/55 {
  border-left-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-l-orange-500\/60 {
  border-left-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-l-orange-500\/65 {
  border-left-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-l-orange-500\/70 {
  border-left-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-l-orange-500\/75 {
  border-left-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-l-orange-500\/80 {
  border-left-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-l-orange-500\/85 {
  border-left-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-l-orange-500\/90 {
  border-left-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-l-orange-500\/95 {
  border-left-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-l-orange-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-600\/0 {
  border-left-color: rgb(234 88 12 / 0) !important;
}

.tw-border-l-orange-600\/10 {
  border-left-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-l-orange-600\/100 {
  border-left-color: rgb(234 88 12 / 1) !important;
}

.tw-border-l-orange-600\/15 {
  border-left-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-l-orange-600\/20 {
  border-left-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-l-orange-600\/25 {
  border-left-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-l-orange-600\/30 {
  border-left-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-l-orange-600\/35 {
  border-left-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-l-orange-600\/40 {
  border-left-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-l-orange-600\/45 {
  border-left-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-l-orange-600\/5 {
  border-left-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-l-orange-600\/50 {
  border-left-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-l-orange-600\/55 {
  border-left-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-l-orange-600\/60 {
  border-left-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-l-orange-600\/65 {
  border-left-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-l-orange-600\/70 {
  border-left-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-l-orange-600\/75 {
  border-left-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-l-orange-600\/80 {
  border-left-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-l-orange-600\/85 {
  border-left-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-l-orange-600\/90 {
  border-left-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-l-orange-600\/95 {
  border-left-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-l-orange-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-700\/0 {
  border-left-color: rgb(194 65 12 / 0) !important;
}

.tw-border-l-orange-700\/10 {
  border-left-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-l-orange-700\/100 {
  border-left-color: rgb(194 65 12 / 1) !important;
}

.tw-border-l-orange-700\/15 {
  border-left-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-l-orange-700\/20 {
  border-left-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-l-orange-700\/25 {
  border-left-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-l-orange-700\/30 {
  border-left-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-l-orange-700\/35 {
  border-left-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-l-orange-700\/40 {
  border-left-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-l-orange-700\/45 {
  border-left-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-l-orange-700\/5 {
  border-left-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-l-orange-700\/50 {
  border-left-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-l-orange-700\/55 {
  border-left-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-l-orange-700\/60 {
  border-left-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-l-orange-700\/65 {
  border-left-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-l-orange-700\/70 {
  border-left-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-l-orange-700\/75 {
  border-left-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-l-orange-700\/80 {
  border-left-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-l-orange-700\/85 {
  border-left-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-l-orange-700\/90 {
  border-left-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-l-orange-700\/95 {
  border-left-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-l-orange-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-800\/0 {
  border-left-color: rgb(154 52 18 / 0) !important;
}

.tw-border-l-orange-800\/10 {
  border-left-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-l-orange-800\/100 {
  border-left-color: rgb(154 52 18 / 1) !important;
}

.tw-border-l-orange-800\/15 {
  border-left-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-l-orange-800\/20 {
  border-left-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-l-orange-800\/25 {
  border-left-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-l-orange-800\/30 {
  border-left-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-l-orange-800\/35 {
  border-left-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-l-orange-800\/40 {
  border-left-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-l-orange-800\/45 {
  border-left-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-l-orange-800\/5 {
  border-left-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-l-orange-800\/50 {
  border-left-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-l-orange-800\/55 {
  border-left-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-l-orange-800\/60 {
  border-left-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-l-orange-800\/65 {
  border-left-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-l-orange-800\/70 {
  border-left-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-l-orange-800\/75 {
  border-left-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-l-orange-800\/80 {
  border-left-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-l-orange-800\/85 {
  border-left-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-l-orange-800\/90 {
  border-left-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-l-orange-800\/95 {
  border-left-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-l-orange-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-900\/0 {
  border-left-color: rgb(124 45 18 / 0) !important;
}

.tw-border-l-orange-900\/10 {
  border-left-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-l-orange-900\/100 {
  border-left-color: rgb(124 45 18 / 1) !important;
}

.tw-border-l-orange-900\/15 {
  border-left-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-l-orange-900\/20 {
  border-left-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-l-orange-900\/25 {
  border-left-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-l-orange-900\/30 {
  border-left-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-l-orange-900\/35 {
  border-left-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-l-orange-900\/40 {
  border-left-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-l-orange-900\/45 {
  border-left-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-l-orange-900\/5 {
  border-left-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-l-orange-900\/50 {
  border-left-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-l-orange-900\/55 {
  border-left-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-l-orange-900\/60 {
  border-left-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-l-orange-900\/65 {
  border-left-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-l-orange-900\/70 {
  border-left-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-l-orange-900\/75 {
  border-left-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-l-orange-900\/80 {
  border-left-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-l-orange-900\/85 {
  border-left-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-l-orange-900\/90 {
  border-left-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-l-orange-900\/95 {
  border-left-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-l-orange-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-l-orange-950\/0 {
  border-left-color: rgb(67 20 7 / 0) !important;
}

.tw-border-l-orange-950\/10 {
  border-left-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-l-orange-950\/100 {
  border-left-color: rgb(67 20 7 / 1) !important;
}

.tw-border-l-orange-950\/15 {
  border-left-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-l-orange-950\/20 {
  border-left-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-l-orange-950\/25 {
  border-left-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-l-orange-950\/30 {
  border-left-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-l-orange-950\/35 {
  border-left-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-l-orange-950\/40 {
  border-left-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-l-orange-950\/45 {
  border-left-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-l-orange-950\/5 {
  border-left-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-l-orange-950\/50 {
  border-left-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-l-orange-950\/55 {
  border-left-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-l-orange-950\/60 {
  border-left-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-l-orange-950\/65 {
  border-left-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-l-orange-950\/70 {
  border-left-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-l-orange-950\/75 {
  border-left-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-l-orange-950\/80 {
  border-left-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-l-orange-950\/85 {
  border-left-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-l-orange-950\/90 {
  border-left-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-l-orange-950\/95 {
  border-left-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-l-pink-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-100\/0 {
  border-left-color: rgb(252 231 243 / 0) !important;
}

.tw-border-l-pink-100\/10 {
  border-left-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-l-pink-100\/100 {
  border-left-color: rgb(252 231 243 / 1) !important;
}

.tw-border-l-pink-100\/15 {
  border-left-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-l-pink-100\/20 {
  border-left-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-l-pink-100\/25 {
  border-left-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-l-pink-100\/30 {
  border-left-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-l-pink-100\/35 {
  border-left-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-l-pink-100\/40 {
  border-left-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-l-pink-100\/45 {
  border-left-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-l-pink-100\/5 {
  border-left-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-l-pink-100\/50 {
  border-left-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-l-pink-100\/55 {
  border-left-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-l-pink-100\/60 {
  border-left-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-l-pink-100\/65 {
  border-left-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-l-pink-100\/70 {
  border-left-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-l-pink-100\/75 {
  border-left-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-l-pink-100\/80 {
  border-left-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-l-pink-100\/85 {
  border-left-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-l-pink-100\/90 {
  border-left-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-l-pink-100\/95 {
  border-left-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-l-pink-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-200\/0 {
  border-left-color: rgb(251 207 232 / 0) !important;
}

.tw-border-l-pink-200\/10 {
  border-left-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-l-pink-200\/100 {
  border-left-color: rgb(251 207 232 / 1) !important;
}

.tw-border-l-pink-200\/15 {
  border-left-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-l-pink-200\/20 {
  border-left-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-l-pink-200\/25 {
  border-left-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-l-pink-200\/30 {
  border-left-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-l-pink-200\/35 {
  border-left-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-l-pink-200\/40 {
  border-left-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-l-pink-200\/45 {
  border-left-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-l-pink-200\/5 {
  border-left-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-l-pink-200\/50 {
  border-left-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-l-pink-200\/55 {
  border-left-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-l-pink-200\/60 {
  border-left-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-l-pink-200\/65 {
  border-left-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-l-pink-200\/70 {
  border-left-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-l-pink-200\/75 {
  border-left-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-l-pink-200\/80 {
  border-left-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-l-pink-200\/85 {
  border-left-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-l-pink-200\/90 {
  border-left-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-l-pink-200\/95 {
  border-left-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-l-pink-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-300\/0 {
  border-left-color: rgb(249 168 212 / 0) !important;
}

.tw-border-l-pink-300\/10 {
  border-left-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-l-pink-300\/100 {
  border-left-color: rgb(249 168 212 / 1) !important;
}

.tw-border-l-pink-300\/15 {
  border-left-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-l-pink-300\/20 {
  border-left-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-l-pink-300\/25 {
  border-left-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-l-pink-300\/30 {
  border-left-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-l-pink-300\/35 {
  border-left-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-l-pink-300\/40 {
  border-left-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-l-pink-300\/45 {
  border-left-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-l-pink-300\/5 {
  border-left-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-l-pink-300\/50 {
  border-left-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-l-pink-300\/55 {
  border-left-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-l-pink-300\/60 {
  border-left-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-l-pink-300\/65 {
  border-left-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-l-pink-300\/70 {
  border-left-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-l-pink-300\/75 {
  border-left-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-l-pink-300\/80 {
  border-left-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-l-pink-300\/85 {
  border-left-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-l-pink-300\/90 {
  border-left-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-l-pink-300\/95 {
  border-left-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-l-pink-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-400\/0 {
  border-left-color: rgb(244 114 182 / 0) !important;
}

.tw-border-l-pink-400\/10 {
  border-left-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-l-pink-400\/100 {
  border-left-color: rgb(244 114 182 / 1) !important;
}

.tw-border-l-pink-400\/15 {
  border-left-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-l-pink-400\/20 {
  border-left-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-l-pink-400\/25 {
  border-left-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-l-pink-400\/30 {
  border-left-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-l-pink-400\/35 {
  border-left-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-l-pink-400\/40 {
  border-left-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-l-pink-400\/45 {
  border-left-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-l-pink-400\/5 {
  border-left-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-l-pink-400\/50 {
  border-left-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-l-pink-400\/55 {
  border-left-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-l-pink-400\/60 {
  border-left-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-l-pink-400\/65 {
  border-left-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-l-pink-400\/70 {
  border-left-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-l-pink-400\/75 {
  border-left-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-l-pink-400\/80 {
  border-left-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-l-pink-400\/85 {
  border-left-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-l-pink-400\/90 {
  border-left-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-l-pink-400\/95 {
  border-left-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-l-pink-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-50\/0 {
  border-left-color: rgb(253 242 248 / 0) !important;
}

.tw-border-l-pink-50\/10 {
  border-left-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-l-pink-50\/100 {
  border-left-color: rgb(253 242 248 / 1) !important;
}

.tw-border-l-pink-50\/15 {
  border-left-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-l-pink-50\/20 {
  border-left-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-l-pink-50\/25 {
  border-left-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-l-pink-50\/30 {
  border-left-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-l-pink-50\/35 {
  border-left-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-l-pink-50\/40 {
  border-left-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-l-pink-50\/45 {
  border-left-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-l-pink-50\/5 {
  border-left-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-l-pink-50\/50 {
  border-left-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-l-pink-50\/55 {
  border-left-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-l-pink-50\/60 {
  border-left-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-l-pink-50\/65 {
  border-left-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-l-pink-50\/70 {
  border-left-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-l-pink-50\/75 {
  border-left-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-l-pink-50\/80 {
  border-left-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-l-pink-50\/85 {
  border-left-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-l-pink-50\/90 {
  border-left-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-l-pink-50\/95 {
  border-left-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-l-pink-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-500\/0 {
  border-left-color: rgb(236 72 153 / 0) !important;
}

.tw-border-l-pink-500\/10 {
  border-left-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-l-pink-500\/100 {
  border-left-color: rgb(236 72 153 / 1) !important;
}

.tw-border-l-pink-500\/15 {
  border-left-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-l-pink-500\/20 {
  border-left-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-l-pink-500\/25 {
  border-left-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-l-pink-500\/30 {
  border-left-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-l-pink-500\/35 {
  border-left-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-l-pink-500\/40 {
  border-left-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-l-pink-500\/45 {
  border-left-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-l-pink-500\/5 {
  border-left-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-l-pink-500\/50 {
  border-left-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-l-pink-500\/55 {
  border-left-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-l-pink-500\/60 {
  border-left-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-l-pink-500\/65 {
  border-left-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-l-pink-500\/70 {
  border-left-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-l-pink-500\/75 {
  border-left-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-l-pink-500\/80 {
  border-left-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-l-pink-500\/85 {
  border-left-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-l-pink-500\/90 {
  border-left-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-l-pink-500\/95 {
  border-left-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-l-pink-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-600\/0 {
  border-left-color: rgb(219 39 119 / 0) !important;
}

.tw-border-l-pink-600\/10 {
  border-left-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-l-pink-600\/100 {
  border-left-color: rgb(219 39 119 / 1) !important;
}

.tw-border-l-pink-600\/15 {
  border-left-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-l-pink-600\/20 {
  border-left-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-l-pink-600\/25 {
  border-left-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-l-pink-600\/30 {
  border-left-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-l-pink-600\/35 {
  border-left-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-l-pink-600\/40 {
  border-left-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-l-pink-600\/45 {
  border-left-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-l-pink-600\/5 {
  border-left-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-l-pink-600\/50 {
  border-left-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-l-pink-600\/55 {
  border-left-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-l-pink-600\/60 {
  border-left-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-l-pink-600\/65 {
  border-left-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-l-pink-600\/70 {
  border-left-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-l-pink-600\/75 {
  border-left-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-l-pink-600\/80 {
  border-left-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-l-pink-600\/85 {
  border-left-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-l-pink-600\/90 {
  border-left-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-l-pink-600\/95 {
  border-left-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-l-pink-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-700\/0 {
  border-left-color: rgb(190 24 93 / 0) !important;
}

.tw-border-l-pink-700\/10 {
  border-left-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-l-pink-700\/100 {
  border-left-color: rgb(190 24 93 / 1) !important;
}

.tw-border-l-pink-700\/15 {
  border-left-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-l-pink-700\/20 {
  border-left-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-l-pink-700\/25 {
  border-left-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-l-pink-700\/30 {
  border-left-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-l-pink-700\/35 {
  border-left-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-l-pink-700\/40 {
  border-left-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-l-pink-700\/45 {
  border-left-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-l-pink-700\/5 {
  border-left-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-l-pink-700\/50 {
  border-left-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-l-pink-700\/55 {
  border-left-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-l-pink-700\/60 {
  border-left-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-l-pink-700\/65 {
  border-left-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-l-pink-700\/70 {
  border-left-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-l-pink-700\/75 {
  border-left-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-l-pink-700\/80 {
  border-left-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-l-pink-700\/85 {
  border-left-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-l-pink-700\/90 {
  border-left-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-l-pink-700\/95 {
  border-left-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-l-pink-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-800\/0 {
  border-left-color: rgb(157 23 77 / 0) !important;
}

.tw-border-l-pink-800\/10 {
  border-left-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-l-pink-800\/100 {
  border-left-color: rgb(157 23 77 / 1) !important;
}

.tw-border-l-pink-800\/15 {
  border-left-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-l-pink-800\/20 {
  border-left-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-l-pink-800\/25 {
  border-left-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-l-pink-800\/30 {
  border-left-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-l-pink-800\/35 {
  border-left-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-l-pink-800\/40 {
  border-left-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-l-pink-800\/45 {
  border-left-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-l-pink-800\/5 {
  border-left-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-l-pink-800\/50 {
  border-left-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-l-pink-800\/55 {
  border-left-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-l-pink-800\/60 {
  border-left-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-l-pink-800\/65 {
  border-left-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-l-pink-800\/70 {
  border-left-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-l-pink-800\/75 {
  border-left-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-l-pink-800\/80 {
  border-left-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-l-pink-800\/85 {
  border-left-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-l-pink-800\/90 {
  border-left-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-l-pink-800\/95 {
  border-left-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-l-pink-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-900\/0 {
  border-left-color: rgb(131 24 67 / 0) !important;
}

.tw-border-l-pink-900\/10 {
  border-left-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-l-pink-900\/100 {
  border-left-color: rgb(131 24 67 / 1) !important;
}

.tw-border-l-pink-900\/15 {
  border-left-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-l-pink-900\/20 {
  border-left-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-l-pink-900\/25 {
  border-left-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-l-pink-900\/30 {
  border-left-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-l-pink-900\/35 {
  border-left-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-l-pink-900\/40 {
  border-left-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-l-pink-900\/45 {
  border-left-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-l-pink-900\/5 {
  border-left-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-l-pink-900\/50 {
  border-left-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-l-pink-900\/55 {
  border-left-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-l-pink-900\/60 {
  border-left-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-l-pink-900\/65 {
  border-left-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-l-pink-900\/70 {
  border-left-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-l-pink-900\/75 {
  border-left-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-l-pink-900\/80 {
  border-left-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-l-pink-900\/85 {
  border-left-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-l-pink-900\/90 {
  border-left-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-l-pink-900\/95 {
  border-left-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-l-pink-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-l-pink-950\/0 {
  border-left-color: rgb(80 7 36 / 0) !important;
}

.tw-border-l-pink-950\/10 {
  border-left-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-l-pink-950\/100 {
  border-left-color: rgb(80 7 36 / 1) !important;
}

.tw-border-l-pink-950\/15 {
  border-left-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-l-pink-950\/20 {
  border-left-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-l-pink-950\/25 {
  border-left-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-l-pink-950\/30 {
  border-left-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-l-pink-950\/35 {
  border-left-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-l-pink-950\/40 {
  border-left-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-l-pink-950\/45 {
  border-left-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-l-pink-950\/5 {
  border-left-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-l-pink-950\/50 {
  border-left-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-l-pink-950\/55 {
  border-left-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-l-pink-950\/60 {
  border-left-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-l-pink-950\/65 {
  border-left-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-l-pink-950\/70 {
  border-left-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-l-pink-950\/75 {
  border-left-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-l-pink-950\/80 {
  border-left-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-l-pink-950\/85 {
  border-left-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-l-pink-950\/90 {
  border-left-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-l-pink-950\/95 {
  border-left-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-l-purple-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-100\/0 {
  border-left-color: rgb(243 232 255 / 0) !important;
}

.tw-border-l-purple-100\/10 {
  border-left-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-l-purple-100\/100 {
  border-left-color: rgb(243 232 255 / 1) !important;
}

.tw-border-l-purple-100\/15 {
  border-left-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-l-purple-100\/20 {
  border-left-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-l-purple-100\/25 {
  border-left-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-l-purple-100\/30 {
  border-left-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-l-purple-100\/35 {
  border-left-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-l-purple-100\/40 {
  border-left-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-l-purple-100\/45 {
  border-left-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-l-purple-100\/5 {
  border-left-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-l-purple-100\/50 {
  border-left-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-l-purple-100\/55 {
  border-left-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-l-purple-100\/60 {
  border-left-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-l-purple-100\/65 {
  border-left-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-l-purple-100\/70 {
  border-left-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-l-purple-100\/75 {
  border-left-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-l-purple-100\/80 {
  border-left-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-l-purple-100\/85 {
  border-left-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-l-purple-100\/90 {
  border-left-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-l-purple-100\/95 {
  border-left-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-l-purple-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-200\/0 {
  border-left-color: rgb(233 213 255 / 0) !important;
}

.tw-border-l-purple-200\/10 {
  border-left-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-l-purple-200\/100 {
  border-left-color: rgb(233 213 255 / 1) !important;
}

.tw-border-l-purple-200\/15 {
  border-left-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-l-purple-200\/20 {
  border-left-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-l-purple-200\/25 {
  border-left-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-l-purple-200\/30 {
  border-left-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-l-purple-200\/35 {
  border-left-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-l-purple-200\/40 {
  border-left-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-l-purple-200\/45 {
  border-left-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-l-purple-200\/5 {
  border-left-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-l-purple-200\/50 {
  border-left-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-l-purple-200\/55 {
  border-left-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-l-purple-200\/60 {
  border-left-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-l-purple-200\/65 {
  border-left-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-l-purple-200\/70 {
  border-left-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-l-purple-200\/75 {
  border-left-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-l-purple-200\/80 {
  border-left-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-l-purple-200\/85 {
  border-left-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-l-purple-200\/90 {
  border-left-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-l-purple-200\/95 {
  border-left-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-l-purple-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-300\/0 {
  border-left-color: rgb(216 180 254 / 0) !important;
}

.tw-border-l-purple-300\/10 {
  border-left-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-l-purple-300\/100 {
  border-left-color: rgb(216 180 254 / 1) !important;
}

.tw-border-l-purple-300\/15 {
  border-left-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-l-purple-300\/20 {
  border-left-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-l-purple-300\/25 {
  border-left-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-l-purple-300\/30 {
  border-left-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-l-purple-300\/35 {
  border-left-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-l-purple-300\/40 {
  border-left-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-l-purple-300\/45 {
  border-left-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-l-purple-300\/5 {
  border-left-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-l-purple-300\/50 {
  border-left-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-l-purple-300\/55 {
  border-left-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-l-purple-300\/60 {
  border-left-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-l-purple-300\/65 {
  border-left-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-l-purple-300\/70 {
  border-left-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-l-purple-300\/75 {
  border-left-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-l-purple-300\/80 {
  border-left-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-l-purple-300\/85 {
  border-left-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-l-purple-300\/90 {
  border-left-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-l-purple-300\/95 {
  border-left-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-l-purple-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-400\/0 {
  border-left-color: rgb(192 132 252 / 0) !important;
}

.tw-border-l-purple-400\/10 {
  border-left-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-l-purple-400\/100 {
  border-left-color: rgb(192 132 252 / 1) !important;
}

.tw-border-l-purple-400\/15 {
  border-left-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-l-purple-400\/20 {
  border-left-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-l-purple-400\/25 {
  border-left-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-l-purple-400\/30 {
  border-left-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-l-purple-400\/35 {
  border-left-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-l-purple-400\/40 {
  border-left-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-l-purple-400\/45 {
  border-left-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-l-purple-400\/5 {
  border-left-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-l-purple-400\/50 {
  border-left-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-l-purple-400\/55 {
  border-left-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-l-purple-400\/60 {
  border-left-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-l-purple-400\/65 {
  border-left-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-l-purple-400\/70 {
  border-left-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-l-purple-400\/75 {
  border-left-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-l-purple-400\/80 {
  border-left-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-l-purple-400\/85 {
  border-left-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-l-purple-400\/90 {
  border-left-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-l-purple-400\/95 {
  border-left-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-l-purple-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-50\/0 {
  border-left-color: rgb(250 245 255 / 0) !important;
}

.tw-border-l-purple-50\/10 {
  border-left-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-l-purple-50\/100 {
  border-left-color: rgb(250 245 255 / 1) !important;
}

.tw-border-l-purple-50\/15 {
  border-left-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-l-purple-50\/20 {
  border-left-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-l-purple-50\/25 {
  border-left-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-l-purple-50\/30 {
  border-left-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-l-purple-50\/35 {
  border-left-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-l-purple-50\/40 {
  border-left-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-l-purple-50\/45 {
  border-left-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-l-purple-50\/5 {
  border-left-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-l-purple-50\/50 {
  border-left-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-l-purple-50\/55 {
  border-left-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-l-purple-50\/60 {
  border-left-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-l-purple-50\/65 {
  border-left-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-l-purple-50\/70 {
  border-left-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-l-purple-50\/75 {
  border-left-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-l-purple-50\/80 {
  border-left-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-l-purple-50\/85 {
  border-left-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-l-purple-50\/90 {
  border-left-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-l-purple-50\/95 {
  border-left-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-l-purple-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-500\/0 {
  border-left-color: rgb(168 85 247 / 0) !important;
}

.tw-border-l-purple-500\/10 {
  border-left-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-l-purple-500\/100 {
  border-left-color: rgb(168 85 247 / 1) !important;
}

.tw-border-l-purple-500\/15 {
  border-left-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-l-purple-500\/20 {
  border-left-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-l-purple-500\/25 {
  border-left-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-l-purple-500\/30 {
  border-left-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-l-purple-500\/35 {
  border-left-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-l-purple-500\/40 {
  border-left-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-l-purple-500\/45 {
  border-left-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-l-purple-500\/5 {
  border-left-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-l-purple-500\/50 {
  border-left-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-l-purple-500\/55 {
  border-left-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-l-purple-500\/60 {
  border-left-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-l-purple-500\/65 {
  border-left-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-l-purple-500\/70 {
  border-left-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-l-purple-500\/75 {
  border-left-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-l-purple-500\/80 {
  border-left-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-l-purple-500\/85 {
  border-left-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-l-purple-500\/90 {
  border-left-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-l-purple-500\/95 {
  border-left-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-l-purple-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-600\/0 {
  border-left-color: rgb(147 51 234 / 0) !important;
}

.tw-border-l-purple-600\/10 {
  border-left-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-l-purple-600\/100 {
  border-left-color: rgb(147 51 234 / 1) !important;
}

.tw-border-l-purple-600\/15 {
  border-left-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-l-purple-600\/20 {
  border-left-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-l-purple-600\/25 {
  border-left-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-l-purple-600\/30 {
  border-left-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-l-purple-600\/35 {
  border-left-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-l-purple-600\/40 {
  border-left-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-l-purple-600\/45 {
  border-left-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-l-purple-600\/5 {
  border-left-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-l-purple-600\/50 {
  border-left-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-l-purple-600\/55 {
  border-left-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-l-purple-600\/60 {
  border-left-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-l-purple-600\/65 {
  border-left-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-l-purple-600\/70 {
  border-left-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-l-purple-600\/75 {
  border-left-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-l-purple-600\/80 {
  border-left-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-l-purple-600\/85 {
  border-left-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-l-purple-600\/90 {
  border-left-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-l-purple-600\/95 {
  border-left-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-l-purple-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-700\/0 {
  border-left-color: rgb(126 34 206 / 0) !important;
}

.tw-border-l-purple-700\/10 {
  border-left-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-l-purple-700\/100 {
  border-left-color: rgb(126 34 206 / 1) !important;
}

.tw-border-l-purple-700\/15 {
  border-left-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-l-purple-700\/20 {
  border-left-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-l-purple-700\/25 {
  border-left-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-l-purple-700\/30 {
  border-left-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-l-purple-700\/35 {
  border-left-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-l-purple-700\/40 {
  border-left-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-l-purple-700\/45 {
  border-left-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-l-purple-700\/5 {
  border-left-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-l-purple-700\/50 {
  border-left-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-l-purple-700\/55 {
  border-left-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-l-purple-700\/60 {
  border-left-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-l-purple-700\/65 {
  border-left-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-l-purple-700\/70 {
  border-left-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-l-purple-700\/75 {
  border-left-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-l-purple-700\/80 {
  border-left-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-l-purple-700\/85 {
  border-left-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-l-purple-700\/90 {
  border-left-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-l-purple-700\/95 {
  border-left-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-l-purple-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-800\/0 {
  border-left-color: rgb(107 33 168 / 0) !important;
}

.tw-border-l-purple-800\/10 {
  border-left-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-l-purple-800\/100 {
  border-left-color: rgb(107 33 168 / 1) !important;
}

.tw-border-l-purple-800\/15 {
  border-left-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-l-purple-800\/20 {
  border-left-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-l-purple-800\/25 {
  border-left-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-l-purple-800\/30 {
  border-left-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-l-purple-800\/35 {
  border-left-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-l-purple-800\/40 {
  border-left-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-l-purple-800\/45 {
  border-left-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-l-purple-800\/5 {
  border-left-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-l-purple-800\/50 {
  border-left-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-l-purple-800\/55 {
  border-left-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-l-purple-800\/60 {
  border-left-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-l-purple-800\/65 {
  border-left-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-l-purple-800\/70 {
  border-left-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-l-purple-800\/75 {
  border-left-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-l-purple-800\/80 {
  border-left-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-l-purple-800\/85 {
  border-left-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-l-purple-800\/90 {
  border-left-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-l-purple-800\/95 {
  border-left-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-l-purple-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-900\/0 {
  border-left-color: rgb(88 28 135 / 0) !important;
}

.tw-border-l-purple-900\/10 {
  border-left-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-l-purple-900\/100 {
  border-left-color: rgb(88 28 135 / 1) !important;
}

.tw-border-l-purple-900\/15 {
  border-left-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-l-purple-900\/20 {
  border-left-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-l-purple-900\/25 {
  border-left-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-l-purple-900\/30 {
  border-left-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-l-purple-900\/35 {
  border-left-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-l-purple-900\/40 {
  border-left-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-l-purple-900\/45 {
  border-left-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-l-purple-900\/5 {
  border-left-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-l-purple-900\/50 {
  border-left-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-l-purple-900\/55 {
  border-left-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-l-purple-900\/60 {
  border-left-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-l-purple-900\/65 {
  border-left-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-l-purple-900\/70 {
  border-left-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-l-purple-900\/75 {
  border-left-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-l-purple-900\/80 {
  border-left-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-l-purple-900\/85 {
  border-left-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-l-purple-900\/90 {
  border-left-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-l-purple-900\/95 {
  border-left-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-l-purple-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-l-purple-950\/0 {
  border-left-color: rgb(59 7 100 / 0) !important;
}

.tw-border-l-purple-950\/10 {
  border-left-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-l-purple-950\/100 {
  border-left-color: rgb(59 7 100 / 1) !important;
}

.tw-border-l-purple-950\/15 {
  border-left-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-l-purple-950\/20 {
  border-left-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-l-purple-950\/25 {
  border-left-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-l-purple-950\/30 {
  border-left-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-l-purple-950\/35 {
  border-left-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-l-purple-950\/40 {
  border-left-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-l-purple-950\/45 {
  border-left-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-l-purple-950\/5 {
  border-left-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-l-purple-950\/50 {
  border-left-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-l-purple-950\/55 {
  border-left-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-l-purple-950\/60 {
  border-left-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-l-purple-950\/65 {
  border-left-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-l-purple-950\/70 {
  border-left-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-l-purple-950\/75 {
  border-left-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-l-purple-950\/80 {
  border-left-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-l-purple-950\/85 {
  border-left-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-l-purple-950\/90 {
  border-left-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-l-purple-950\/95 {
  border-left-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-l-red-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-100\/0 {
  border-left-color: rgb(254 226 226 / 0) !important;
}

.tw-border-l-red-100\/10 {
  border-left-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-l-red-100\/100 {
  border-left-color: rgb(254 226 226 / 1) !important;
}

.tw-border-l-red-100\/15 {
  border-left-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-l-red-100\/20 {
  border-left-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-l-red-100\/25 {
  border-left-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-l-red-100\/30 {
  border-left-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-l-red-100\/35 {
  border-left-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-l-red-100\/40 {
  border-left-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-l-red-100\/45 {
  border-left-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-l-red-100\/5 {
  border-left-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-l-red-100\/50 {
  border-left-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-l-red-100\/55 {
  border-left-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-l-red-100\/60 {
  border-left-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-l-red-100\/65 {
  border-left-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-l-red-100\/70 {
  border-left-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-l-red-100\/75 {
  border-left-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-l-red-100\/80 {
  border-left-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-l-red-100\/85 {
  border-left-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-l-red-100\/90 {
  border-left-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-l-red-100\/95 {
  border-left-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-l-red-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-200\/0 {
  border-left-color: rgb(254 202 202 / 0) !important;
}

.tw-border-l-red-200\/10 {
  border-left-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-l-red-200\/100 {
  border-left-color: rgb(254 202 202 / 1) !important;
}

.tw-border-l-red-200\/15 {
  border-left-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-l-red-200\/20 {
  border-left-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-l-red-200\/25 {
  border-left-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-l-red-200\/30 {
  border-left-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-l-red-200\/35 {
  border-left-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-l-red-200\/40 {
  border-left-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-l-red-200\/45 {
  border-left-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-l-red-200\/5 {
  border-left-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-l-red-200\/50 {
  border-left-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-l-red-200\/55 {
  border-left-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-l-red-200\/60 {
  border-left-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-l-red-200\/65 {
  border-left-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-l-red-200\/70 {
  border-left-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-l-red-200\/75 {
  border-left-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-l-red-200\/80 {
  border-left-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-l-red-200\/85 {
  border-left-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-l-red-200\/90 {
  border-left-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-l-red-200\/95 {
  border-left-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-l-red-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-300\/0 {
  border-left-color: rgb(252 165 165 / 0) !important;
}

.tw-border-l-red-300\/10 {
  border-left-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-l-red-300\/100 {
  border-left-color: rgb(252 165 165 / 1) !important;
}

.tw-border-l-red-300\/15 {
  border-left-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-l-red-300\/20 {
  border-left-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-l-red-300\/25 {
  border-left-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-l-red-300\/30 {
  border-left-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-l-red-300\/35 {
  border-left-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-l-red-300\/40 {
  border-left-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-l-red-300\/45 {
  border-left-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-l-red-300\/5 {
  border-left-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-l-red-300\/50 {
  border-left-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-l-red-300\/55 {
  border-left-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-l-red-300\/60 {
  border-left-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-l-red-300\/65 {
  border-left-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-l-red-300\/70 {
  border-left-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-l-red-300\/75 {
  border-left-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-l-red-300\/80 {
  border-left-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-l-red-300\/85 {
  border-left-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-l-red-300\/90 {
  border-left-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-l-red-300\/95 {
  border-left-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-l-red-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-400\/0 {
  border-left-color: rgb(248 113 113 / 0) !important;
}

.tw-border-l-red-400\/10 {
  border-left-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-l-red-400\/100 {
  border-left-color: rgb(248 113 113 / 1) !important;
}

.tw-border-l-red-400\/15 {
  border-left-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-l-red-400\/20 {
  border-left-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-l-red-400\/25 {
  border-left-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-l-red-400\/30 {
  border-left-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-l-red-400\/35 {
  border-left-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-l-red-400\/40 {
  border-left-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-l-red-400\/45 {
  border-left-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-l-red-400\/5 {
  border-left-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-l-red-400\/50 {
  border-left-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-l-red-400\/55 {
  border-left-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-l-red-400\/60 {
  border-left-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-l-red-400\/65 {
  border-left-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-l-red-400\/70 {
  border-left-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-l-red-400\/75 {
  border-left-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-l-red-400\/80 {
  border-left-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-l-red-400\/85 {
  border-left-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-l-red-400\/90 {
  border-left-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-l-red-400\/95 {
  border-left-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-l-red-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-50\/0 {
  border-left-color: rgb(254 242 242 / 0) !important;
}

.tw-border-l-red-50\/10 {
  border-left-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-l-red-50\/100 {
  border-left-color: rgb(254 242 242 / 1) !important;
}

.tw-border-l-red-50\/15 {
  border-left-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-l-red-50\/20 {
  border-left-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-l-red-50\/25 {
  border-left-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-l-red-50\/30 {
  border-left-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-l-red-50\/35 {
  border-left-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-l-red-50\/40 {
  border-left-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-l-red-50\/45 {
  border-left-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-l-red-50\/5 {
  border-left-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-l-red-50\/50 {
  border-left-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-l-red-50\/55 {
  border-left-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-l-red-50\/60 {
  border-left-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-l-red-50\/65 {
  border-left-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-l-red-50\/70 {
  border-left-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-l-red-50\/75 {
  border-left-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-l-red-50\/80 {
  border-left-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-l-red-50\/85 {
  border-left-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-l-red-50\/90 {
  border-left-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-l-red-50\/95 {
  border-left-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-l-red-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-500\/0 {
  border-left-color: rgb(239 68 68 / 0) !important;
}

.tw-border-l-red-500\/10 {
  border-left-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-l-red-500\/100 {
  border-left-color: rgb(239 68 68 / 1) !important;
}

.tw-border-l-red-500\/15 {
  border-left-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-l-red-500\/20 {
  border-left-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-l-red-500\/25 {
  border-left-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-l-red-500\/30 {
  border-left-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-l-red-500\/35 {
  border-left-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-l-red-500\/40 {
  border-left-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-l-red-500\/45 {
  border-left-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-l-red-500\/5 {
  border-left-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-l-red-500\/50 {
  border-left-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-l-red-500\/55 {
  border-left-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-l-red-500\/60 {
  border-left-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-l-red-500\/65 {
  border-left-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-l-red-500\/70 {
  border-left-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-l-red-500\/75 {
  border-left-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-l-red-500\/80 {
  border-left-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-l-red-500\/85 {
  border-left-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-l-red-500\/90 {
  border-left-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-l-red-500\/95 {
  border-left-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-l-red-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-600\/0 {
  border-left-color: rgb(220 38 38 / 0) !important;
}

.tw-border-l-red-600\/10 {
  border-left-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-l-red-600\/100 {
  border-left-color: rgb(220 38 38 / 1) !important;
}

.tw-border-l-red-600\/15 {
  border-left-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-l-red-600\/20 {
  border-left-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-l-red-600\/25 {
  border-left-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-l-red-600\/30 {
  border-left-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-l-red-600\/35 {
  border-left-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-l-red-600\/40 {
  border-left-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-l-red-600\/45 {
  border-left-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-l-red-600\/5 {
  border-left-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-l-red-600\/50 {
  border-left-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-l-red-600\/55 {
  border-left-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-l-red-600\/60 {
  border-left-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-l-red-600\/65 {
  border-left-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-l-red-600\/70 {
  border-left-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-l-red-600\/75 {
  border-left-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-l-red-600\/80 {
  border-left-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-l-red-600\/85 {
  border-left-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-l-red-600\/90 {
  border-left-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-l-red-600\/95 {
  border-left-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-l-red-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-700\/0 {
  border-left-color: rgb(185 28 28 / 0) !important;
}

.tw-border-l-red-700\/10 {
  border-left-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-l-red-700\/100 {
  border-left-color: rgb(185 28 28 / 1) !important;
}

.tw-border-l-red-700\/15 {
  border-left-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-l-red-700\/20 {
  border-left-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-l-red-700\/25 {
  border-left-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-l-red-700\/30 {
  border-left-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-l-red-700\/35 {
  border-left-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-l-red-700\/40 {
  border-left-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-l-red-700\/45 {
  border-left-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-l-red-700\/5 {
  border-left-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-l-red-700\/50 {
  border-left-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-l-red-700\/55 {
  border-left-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-l-red-700\/60 {
  border-left-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-l-red-700\/65 {
  border-left-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-l-red-700\/70 {
  border-left-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-l-red-700\/75 {
  border-left-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-l-red-700\/80 {
  border-left-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-l-red-700\/85 {
  border-left-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-l-red-700\/90 {
  border-left-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-l-red-700\/95 {
  border-left-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-l-red-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-800\/0 {
  border-left-color: rgb(153 27 27 / 0) !important;
}

.tw-border-l-red-800\/10 {
  border-left-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-l-red-800\/100 {
  border-left-color: rgb(153 27 27 / 1) !important;
}

.tw-border-l-red-800\/15 {
  border-left-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-l-red-800\/20 {
  border-left-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-l-red-800\/25 {
  border-left-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-l-red-800\/30 {
  border-left-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-l-red-800\/35 {
  border-left-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-l-red-800\/40 {
  border-left-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-l-red-800\/45 {
  border-left-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-l-red-800\/5 {
  border-left-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-l-red-800\/50 {
  border-left-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-l-red-800\/55 {
  border-left-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-l-red-800\/60 {
  border-left-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-l-red-800\/65 {
  border-left-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-l-red-800\/70 {
  border-left-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-l-red-800\/75 {
  border-left-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-l-red-800\/80 {
  border-left-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-l-red-800\/85 {
  border-left-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-l-red-800\/90 {
  border-left-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-l-red-800\/95 {
  border-left-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-l-red-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-900\/0 {
  border-left-color: rgb(127 29 29 / 0) !important;
}

.tw-border-l-red-900\/10 {
  border-left-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-l-red-900\/100 {
  border-left-color: rgb(127 29 29 / 1) !important;
}

.tw-border-l-red-900\/15 {
  border-left-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-l-red-900\/20 {
  border-left-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-l-red-900\/25 {
  border-left-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-l-red-900\/30 {
  border-left-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-l-red-900\/35 {
  border-left-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-l-red-900\/40 {
  border-left-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-l-red-900\/45 {
  border-left-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-l-red-900\/5 {
  border-left-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-l-red-900\/50 {
  border-left-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-l-red-900\/55 {
  border-left-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-l-red-900\/60 {
  border-left-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-l-red-900\/65 {
  border-left-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-l-red-900\/70 {
  border-left-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-l-red-900\/75 {
  border-left-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-l-red-900\/80 {
  border-left-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-l-red-900\/85 {
  border-left-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-l-red-900\/90 {
  border-left-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-l-red-900\/95 {
  border-left-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-l-red-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-l-red-950\/0 {
  border-left-color: rgb(69 10 10 / 0) !important;
}

.tw-border-l-red-950\/10 {
  border-left-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-l-red-950\/100 {
  border-left-color: rgb(69 10 10 / 1) !important;
}

.tw-border-l-red-950\/15 {
  border-left-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-l-red-950\/20 {
  border-left-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-l-red-950\/25 {
  border-left-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-l-red-950\/30 {
  border-left-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-l-red-950\/35 {
  border-left-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-l-red-950\/40 {
  border-left-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-l-red-950\/45 {
  border-left-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-l-red-950\/5 {
  border-left-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-l-red-950\/50 {
  border-left-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-l-red-950\/55 {
  border-left-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-l-red-950\/60 {
  border-left-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-l-red-950\/65 {
  border-left-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-l-red-950\/70 {
  border-left-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-l-red-950\/75 {
  border-left-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-l-red-950\/80 {
  border-left-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-l-red-950\/85 {
  border-left-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-l-red-950\/90 {
  border-left-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-l-red-950\/95 {
  border-left-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-l-rose-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-100\/0 {
  border-left-color: rgb(255 228 230 / 0) !important;
}

.tw-border-l-rose-100\/10 {
  border-left-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-l-rose-100\/100 {
  border-left-color: rgb(255 228 230 / 1) !important;
}

.tw-border-l-rose-100\/15 {
  border-left-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-l-rose-100\/20 {
  border-left-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-l-rose-100\/25 {
  border-left-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-l-rose-100\/30 {
  border-left-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-l-rose-100\/35 {
  border-left-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-l-rose-100\/40 {
  border-left-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-l-rose-100\/45 {
  border-left-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-l-rose-100\/5 {
  border-left-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-l-rose-100\/50 {
  border-left-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-l-rose-100\/55 {
  border-left-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-l-rose-100\/60 {
  border-left-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-l-rose-100\/65 {
  border-left-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-l-rose-100\/70 {
  border-left-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-l-rose-100\/75 {
  border-left-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-l-rose-100\/80 {
  border-left-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-l-rose-100\/85 {
  border-left-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-l-rose-100\/90 {
  border-left-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-l-rose-100\/95 {
  border-left-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-l-rose-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-200\/0 {
  border-left-color: rgb(254 205 211 / 0) !important;
}

.tw-border-l-rose-200\/10 {
  border-left-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-l-rose-200\/100 {
  border-left-color: rgb(254 205 211 / 1) !important;
}

.tw-border-l-rose-200\/15 {
  border-left-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-l-rose-200\/20 {
  border-left-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-l-rose-200\/25 {
  border-left-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-l-rose-200\/30 {
  border-left-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-l-rose-200\/35 {
  border-left-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-l-rose-200\/40 {
  border-left-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-l-rose-200\/45 {
  border-left-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-l-rose-200\/5 {
  border-left-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-l-rose-200\/50 {
  border-left-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-l-rose-200\/55 {
  border-left-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-l-rose-200\/60 {
  border-left-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-l-rose-200\/65 {
  border-left-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-l-rose-200\/70 {
  border-left-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-l-rose-200\/75 {
  border-left-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-l-rose-200\/80 {
  border-left-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-l-rose-200\/85 {
  border-left-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-l-rose-200\/90 {
  border-left-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-l-rose-200\/95 {
  border-left-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-l-rose-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-300\/0 {
  border-left-color: rgb(253 164 175 / 0) !important;
}

.tw-border-l-rose-300\/10 {
  border-left-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-l-rose-300\/100 {
  border-left-color: rgb(253 164 175 / 1) !important;
}

.tw-border-l-rose-300\/15 {
  border-left-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-l-rose-300\/20 {
  border-left-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-l-rose-300\/25 {
  border-left-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-l-rose-300\/30 {
  border-left-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-l-rose-300\/35 {
  border-left-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-l-rose-300\/40 {
  border-left-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-l-rose-300\/45 {
  border-left-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-l-rose-300\/5 {
  border-left-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-l-rose-300\/50 {
  border-left-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-l-rose-300\/55 {
  border-left-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-l-rose-300\/60 {
  border-left-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-l-rose-300\/65 {
  border-left-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-l-rose-300\/70 {
  border-left-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-l-rose-300\/75 {
  border-left-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-l-rose-300\/80 {
  border-left-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-l-rose-300\/85 {
  border-left-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-l-rose-300\/90 {
  border-left-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-l-rose-300\/95 {
  border-left-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-l-rose-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-400\/0 {
  border-left-color: rgb(251 113 133 / 0) !important;
}

.tw-border-l-rose-400\/10 {
  border-left-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-l-rose-400\/100 {
  border-left-color: rgb(251 113 133 / 1) !important;
}

.tw-border-l-rose-400\/15 {
  border-left-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-l-rose-400\/20 {
  border-left-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-l-rose-400\/25 {
  border-left-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-l-rose-400\/30 {
  border-left-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-l-rose-400\/35 {
  border-left-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-l-rose-400\/40 {
  border-left-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-l-rose-400\/45 {
  border-left-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-l-rose-400\/5 {
  border-left-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-l-rose-400\/50 {
  border-left-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-l-rose-400\/55 {
  border-left-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-l-rose-400\/60 {
  border-left-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-l-rose-400\/65 {
  border-left-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-l-rose-400\/70 {
  border-left-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-l-rose-400\/75 {
  border-left-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-l-rose-400\/80 {
  border-left-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-l-rose-400\/85 {
  border-left-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-l-rose-400\/90 {
  border-left-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-l-rose-400\/95 {
  border-left-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-l-rose-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-50\/0 {
  border-left-color: rgb(255 241 242 / 0) !important;
}

.tw-border-l-rose-50\/10 {
  border-left-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-l-rose-50\/100 {
  border-left-color: rgb(255 241 242 / 1) !important;
}

.tw-border-l-rose-50\/15 {
  border-left-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-l-rose-50\/20 {
  border-left-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-l-rose-50\/25 {
  border-left-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-l-rose-50\/30 {
  border-left-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-l-rose-50\/35 {
  border-left-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-l-rose-50\/40 {
  border-left-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-l-rose-50\/45 {
  border-left-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-l-rose-50\/5 {
  border-left-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-l-rose-50\/50 {
  border-left-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-l-rose-50\/55 {
  border-left-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-l-rose-50\/60 {
  border-left-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-l-rose-50\/65 {
  border-left-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-l-rose-50\/70 {
  border-left-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-l-rose-50\/75 {
  border-left-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-l-rose-50\/80 {
  border-left-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-l-rose-50\/85 {
  border-left-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-l-rose-50\/90 {
  border-left-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-l-rose-50\/95 {
  border-left-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-l-rose-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-500\/0 {
  border-left-color: rgb(244 63 94 / 0) !important;
}

.tw-border-l-rose-500\/10 {
  border-left-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-l-rose-500\/100 {
  border-left-color: rgb(244 63 94 / 1) !important;
}

.tw-border-l-rose-500\/15 {
  border-left-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-l-rose-500\/20 {
  border-left-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-l-rose-500\/25 {
  border-left-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-l-rose-500\/30 {
  border-left-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-l-rose-500\/35 {
  border-left-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-l-rose-500\/40 {
  border-left-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-l-rose-500\/45 {
  border-left-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-l-rose-500\/5 {
  border-left-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-l-rose-500\/50 {
  border-left-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-l-rose-500\/55 {
  border-left-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-l-rose-500\/60 {
  border-left-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-l-rose-500\/65 {
  border-left-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-l-rose-500\/70 {
  border-left-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-l-rose-500\/75 {
  border-left-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-l-rose-500\/80 {
  border-left-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-l-rose-500\/85 {
  border-left-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-l-rose-500\/90 {
  border-left-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-l-rose-500\/95 {
  border-left-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-l-rose-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-600\/0 {
  border-left-color: rgb(225 29 72 / 0) !important;
}

.tw-border-l-rose-600\/10 {
  border-left-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-l-rose-600\/100 {
  border-left-color: rgb(225 29 72 / 1) !important;
}

.tw-border-l-rose-600\/15 {
  border-left-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-l-rose-600\/20 {
  border-left-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-l-rose-600\/25 {
  border-left-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-l-rose-600\/30 {
  border-left-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-l-rose-600\/35 {
  border-left-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-l-rose-600\/40 {
  border-left-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-l-rose-600\/45 {
  border-left-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-l-rose-600\/5 {
  border-left-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-l-rose-600\/50 {
  border-left-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-l-rose-600\/55 {
  border-left-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-l-rose-600\/60 {
  border-left-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-l-rose-600\/65 {
  border-left-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-l-rose-600\/70 {
  border-left-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-l-rose-600\/75 {
  border-left-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-l-rose-600\/80 {
  border-left-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-l-rose-600\/85 {
  border-left-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-l-rose-600\/90 {
  border-left-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-l-rose-600\/95 {
  border-left-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-l-rose-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-700\/0 {
  border-left-color: rgb(190 18 60 / 0) !important;
}

.tw-border-l-rose-700\/10 {
  border-left-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-l-rose-700\/100 {
  border-left-color: rgb(190 18 60 / 1) !important;
}

.tw-border-l-rose-700\/15 {
  border-left-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-l-rose-700\/20 {
  border-left-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-l-rose-700\/25 {
  border-left-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-l-rose-700\/30 {
  border-left-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-l-rose-700\/35 {
  border-left-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-l-rose-700\/40 {
  border-left-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-l-rose-700\/45 {
  border-left-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-l-rose-700\/5 {
  border-left-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-l-rose-700\/50 {
  border-left-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-l-rose-700\/55 {
  border-left-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-l-rose-700\/60 {
  border-left-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-l-rose-700\/65 {
  border-left-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-l-rose-700\/70 {
  border-left-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-l-rose-700\/75 {
  border-left-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-l-rose-700\/80 {
  border-left-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-l-rose-700\/85 {
  border-left-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-l-rose-700\/90 {
  border-left-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-l-rose-700\/95 {
  border-left-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-l-rose-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-800\/0 {
  border-left-color: rgb(159 18 57 / 0) !important;
}

.tw-border-l-rose-800\/10 {
  border-left-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-l-rose-800\/100 {
  border-left-color: rgb(159 18 57 / 1) !important;
}

.tw-border-l-rose-800\/15 {
  border-left-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-l-rose-800\/20 {
  border-left-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-l-rose-800\/25 {
  border-left-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-l-rose-800\/30 {
  border-left-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-l-rose-800\/35 {
  border-left-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-l-rose-800\/40 {
  border-left-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-l-rose-800\/45 {
  border-left-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-l-rose-800\/5 {
  border-left-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-l-rose-800\/50 {
  border-left-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-l-rose-800\/55 {
  border-left-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-l-rose-800\/60 {
  border-left-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-l-rose-800\/65 {
  border-left-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-l-rose-800\/70 {
  border-left-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-l-rose-800\/75 {
  border-left-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-l-rose-800\/80 {
  border-left-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-l-rose-800\/85 {
  border-left-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-l-rose-800\/90 {
  border-left-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-l-rose-800\/95 {
  border-left-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-l-rose-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-900\/0 {
  border-left-color: rgb(136 19 55 / 0) !important;
}

.tw-border-l-rose-900\/10 {
  border-left-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-l-rose-900\/100 {
  border-left-color: rgb(136 19 55 / 1) !important;
}

.tw-border-l-rose-900\/15 {
  border-left-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-l-rose-900\/20 {
  border-left-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-l-rose-900\/25 {
  border-left-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-l-rose-900\/30 {
  border-left-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-l-rose-900\/35 {
  border-left-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-l-rose-900\/40 {
  border-left-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-l-rose-900\/45 {
  border-left-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-l-rose-900\/5 {
  border-left-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-l-rose-900\/50 {
  border-left-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-l-rose-900\/55 {
  border-left-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-l-rose-900\/60 {
  border-left-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-l-rose-900\/65 {
  border-left-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-l-rose-900\/70 {
  border-left-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-l-rose-900\/75 {
  border-left-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-l-rose-900\/80 {
  border-left-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-l-rose-900\/85 {
  border-left-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-l-rose-900\/90 {
  border-left-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-l-rose-900\/95 {
  border-left-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-l-rose-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-l-rose-950\/0 {
  border-left-color: rgb(76 5 25 / 0) !important;
}

.tw-border-l-rose-950\/10 {
  border-left-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-l-rose-950\/100 {
  border-left-color: rgb(76 5 25 / 1) !important;
}

.tw-border-l-rose-950\/15 {
  border-left-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-l-rose-950\/20 {
  border-left-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-l-rose-950\/25 {
  border-left-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-l-rose-950\/30 {
  border-left-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-l-rose-950\/35 {
  border-left-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-l-rose-950\/40 {
  border-left-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-l-rose-950\/45 {
  border-left-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-l-rose-950\/5 {
  border-left-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-l-rose-950\/50 {
  border-left-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-l-rose-950\/55 {
  border-left-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-l-rose-950\/60 {
  border-left-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-l-rose-950\/65 {
  border-left-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-l-rose-950\/70 {
  border-left-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-l-rose-950\/75 {
  border-left-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-l-rose-950\/80 {
  border-left-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-l-rose-950\/85 {
  border-left-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-l-rose-950\/90 {
  border-left-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-l-rose-950\/95 {
  border-left-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-l-sky-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-100\/0 {
  border-left-color: rgb(224 242 254 / 0) !important;
}

.tw-border-l-sky-100\/10 {
  border-left-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-l-sky-100\/100 {
  border-left-color: rgb(224 242 254 / 1) !important;
}

.tw-border-l-sky-100\/15 {
  border-left-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-l-sky-100\/20 {
  border-left-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-l-sky-100\/25 {
  border-left-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-l-sky-100\/30 {
  border-left-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-l-sky-100\/35 {
  border-left-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-l-sky-100\/40 {
  border-left-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-l-sky-100\/45 {
  border-left-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-l-sky-100\/5 {
  border-left-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-l-sky-100\/50 {
  border-left-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-l-sky-100\/55 {
  border-left-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-l-sky-100\/60 {
  border-left-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-l-sky-100\/65 {
  border-left-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-l-sky-100\/70 {
  border-left-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-l-sky-100\/75 {
  border-left-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-l-sky-100\/80 {
  border-left-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-l-sky-100\/85 {
  border-left-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-l-sky-100\/90 {
  border-left-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-l-sky-100\/95 {
  border-left-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-l-sky-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-200\/0 {
  border-left-color: rgb(186 230 253 / 0) !important;
}

.tw-border-l-sky-200\/10 {
  border-left-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-l-sky-200\/100 {
  border-left-color: rgb(186 230 253 / 1) !important;
}

.tw-border-l-sky-200\/15 {
  border-left-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-l-sky-200\/20 {
  border-left-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-l-sky-200\/25 {
  border-left-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-l-sky-200\/30 {
  border-left-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-l-sky-200\/35 {
  border-left-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-l-sky-200\/40 {
  border-left-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-l-sky-200\/45 {
  border-left-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-l-sky-200\/5 {
  border-left-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-l-sky-200\/50 {
  border-left-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-l-sky-200\/55 {
  border-left-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-l-sky-200\/60 {
  border-left-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-l-sky-200\/65 {
  border-left-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-l-sky-200\/70 {
  border-left-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-l-sky-200\/75 {
  border-left-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-l-sky-200\/80 {
  border-left-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-l-sky-200\/85 {
  border-left-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-l-sky-200\/90 {
  border-left-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-l-sky-200\/95 {
  border-left-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-l-sky-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-300\/0 {
  border-left-color: rgb(125 211 252 / 0) !important;
}

.tw-border-l-sky-300\/10 {
  border-left-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-l-sky-300\/100 {
  border-left-color: rgb(125 211 252 / 1) !important;
}

.tw-border-l-sky-300\/15 {
  border-left-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-l-sky-300\/20 {
  border-left-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-l-sky-300\/25 {
  border-left-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-l-sky-300\/30 {
  border-left-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-l-sky-300\/35 {
  border-left-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-l-sky-300\/40 {
  border-left-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-l-sky-300\/45 {
  border-left-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-l-sky-300\/5 {
  border-left-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-l-sky-300\/50 {
  border-left-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-l-sky-300\/55 {
  border-left-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-l-sky-300\/60 {
  border-left-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-l-sky-300\/65 {
  border-left-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-l-sky-300\/70 {
  border-left-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-l-sky-300\/75 {
  border-left-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-l-sky-300\/80 {
  border-left-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-l-sky-300\/85 {
  border-left-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-l-sky-300\/90 {
  border-left-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-l-sky-300\/95 {
  border-left-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-l-sky-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-400\/0 {
  border-left-color: rgb(56 189 248 / 0) !important;
}

.tw-border-l-sky-400\/10 {
  border-left-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-l-sky-400\/100 {
  border-left-color: rgb(56 189 248 / 1) !important;
}

.tw-border-l-sky-400\/15 {
  border-left-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-l-sky-400\/20 {
  border-left-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-l-sky-400\/25 {
  border-left-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-l-sky-400\/30 {
  border-left-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-l-sky-400\/35 {
  border-left-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-l-sky-400\/40 {
  border-left-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-l-sky-400\/45 {
  border-left-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-l-sky-400\/5 {
  border-left-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-l-sky-400\/50 {
  border-left-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-l-sky-400\/55 {
  border-left-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-l-sky-400\/60 {
  border-left-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-l-sky-400\/65 {
  border-left-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-l-sky-400\/70 {
  border-left-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-l-sky-400\/75 {
  border-left-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-l-sky-400\/80 {
  border-left-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-l-sky-400\/85 {
  border-left-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-l-sky-400\/90 {
  border-left-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-l-sky-400\/95 {
  border-left-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-l-sky-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-50\/0 {
  border-left-color: rgb(240 249 255 / 0) !important;
}

.tw-border-l-sky-50\/10 {
  border-left-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-l-sky-50\/100 {
  border-left-color: rgb(240 249 255 / 1) !important;
}

.tw-border-l-sky-50\/15 {
  border-left-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-l-sky-50\/20 {
  border-left-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-l-sky-50\/25 {
  border-left-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-l-sky-50\/30 {
  border-left-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-l-sky-50\/35 {
  border-left-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-l-sky-50\/40 {
  border-left-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-l-sky-50\/45 {
  border-left-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-l-sky-50\/5 {
  border-left-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-l-sky-50\/50 {
  border-left-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-l-sky-50\/55 {
  border-left-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-l-sky-50\/60 {
  border-left-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-l-sky-50\/65 {
  border-left-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-l-sky-50\/70 {
  border-left-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-l-sky-50\/75 {
  border-left-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-l-sky-50\/80 {
  border-left-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-l-sky-50\/85 {
  border-left-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-l-sky-50\/90 {
  border-left-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-l-sky-50\/95 {
  border-left-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-l-sky-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-500\/0 {
  border-left-color: rgb(14 165 233 / 0) !important;
}

.tw-border-l-sky-500\/10 {
  border-left-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-l-sky-500\/100 {
  border-left-color: rgb(14 165 233 / 1) !important;
}

.tw-border-l-sky-500\/15 {
  border-left-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-l-sky-500\/20 {
  border-left-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-l-sky-500\/25 {
  border-left-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-l-sky-500\/30 {
  border-left-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-l-sky-500\/35 {
  border-left-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-l-sky-500\/40 {
  border-left-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-l-sky-500\/45 {
  border-left-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-l-sky-500\/5 {
  border-left-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-l-sky-500\/50 {
  border-left-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-l-sky-500\/55 {
  border-left-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-l-sky-500\/60 {
  border-left-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-l-sky-500\/65 {
  border-left-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-l-sky-500\/70 {
  border-left-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-l-sky-500\/75 {
  border-left-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-l-sky-500\/80 {
  border-left-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-l-sky-500\/85 {
  border-left-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-l-sky-500\/90 {
  border-left-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-l-sky-500\/95 {
  border-left-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-l-sky-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-600\/0 {
  border-left-color: rgb(2 132 199 / 0) !important;
}

.tw-border-l-sky-600\/10 {
  border-left-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-l-sky-600\/100 {
  border-left-color: rgb(2 132 199 / 1) !important;
}

.tw-border-l-sky-600\/15 {
  border-left-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-l-sky-600\/20 {
  border-left-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-l-sky-600\/25 {
  border-left-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-l-sky-600\/30 {
  border-left-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-l-sky-600\/35 {
  border-left-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-l-sky-600\/40 {
  border-left-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-l-sky-600\/45 {
  border-left-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-l-sky-600\/5 {
  border-left-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-l-sky-600\/50 {
  border-left-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-l-sky-600\/55 {
  border-left-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-l-sky-600\/60 {
  border-left-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-l-sky-600\/65 {
  border-left-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-l-sky-600\/70 {
  border-left-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-l-sky-600\/75 {
  border-left-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-l-sky-600\/80 {
  border-left-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-l-sky-600\/85 {
  border-left-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-l-sky-600\/90 {
  border-left-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-l-sky-600\/95 {
  border-left-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-l-sky-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-700\/0 {
  border-left-color: rgb(3 105 161 / 0) !important;
}

.tw-border-l-sky-700\/10 {
  border-left-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-l-sky-700\/100 {
  border-left-color: rgb(3 105 161 / 1) !important;
}

.tw-border-l-sky-700\/15 {
  border-left-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-l-sky-700\/20 {
  border-left-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-l-sky-700\/25 {
  border-left-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-l-sky-700\/30 {
  border-left-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-l-sky-700\/35 {
  border-left-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-l-sky-700\/40 {
  border-left-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-l-sky-700\/45 {
  border-left-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-l-sky-700\/5 {
  border-left-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-l-sky-700\/50 {
  border-left-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-l-sky-700\/55 {
  border-left-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-l-sky-700\/60 {
  border-left-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-l-sky-700\/65 {
  border-left-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-l-sky-700\/70 {
  border-left-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-l-sky-700\/75 {
  border-left-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-l-sky-700\/80 {
  border-left-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-l-sky-700\/85 {
  border-left-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-l-sky-700\/90 {
  border-left-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-l-sky-700\/95 {
  border-left-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-l-sky-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-800\/0 {
  border-left-color: rgb(7 89 133 / 0) !important;
}

.tw-border-l-sky-800\/10 {
  border-left-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-l-sky-800\/100 {
  border-left-color: rgb(7 89 133 / 1) !important;
}

.tw-border-l-sky-800\/15 {
  border-left-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-l-sky-800\/20 {
  border-left-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-l-sky-800\/25 {
  border-left-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-l-sky-800\/30 {
  border-left-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-l-sky-800\/35 {
  border-left-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-l-sky-800\/40 {
  border-left-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-l-sky-800\/45 {
  border-left-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-l-sky-800\/5 {
  border-left-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-l-sky-800\/50 {
  border-left-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-l-sky-800\/55 {
  border-left-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-l-sky-800\/60 {
  border-left-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-l-sky-800\/65 {
  border-left-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-l-sky-800\/70 {
  border-left-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-l-sky-800\/75 {
  border-left-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-l-sky-800\/80 {
  border-left-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-l-sky-800\/85 {
  border-left-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-l-sky-800\/90 {
  border-left-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-l-sky-800\/95 {
  border-left-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-l-sky-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-900\/0 {
  border-left-color: rgb(12 74 110 / 0) !important;
}

.tw-border-l-sky-900\/10 {
  border-left-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-l-sky-900\/100 {
  border-left-color: rgb(12 74 110 / 1) !important;
}

.tw-border-l-sky-900\/15 {
  border-left-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-l-sky-900\/20 {
  border-left-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-l-sky-900\/25 {
  border-left-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-l-sky-900\/30 {
  border-left-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-l-sky-900\/35 {
  border-left-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-l-sky-900\/40 {
  border-left-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-l-sky-900\/45 {
  border-left-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-l-sky-900\/5 {
  border-left-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-l-sky-900\/50 {
  border-left-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-l-sky-900\/55 {
  border-left-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-l-sky-900\/60 {
  border-left-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-l-sky-900\/65 {
  border-left-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-l-sky-900\/70 {
  border-left-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-l-sky-900\/75 {
  border-left-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-l-sky-900\/80 {
  border-left-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-l-sky-900\/85 {
  border-left-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-l-sky-900\/90 {
  border-left-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-l-sky-900\/95 {
  border-left-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-l-sky-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-l-sky-950\/0 {
  border-left-color: rgb(8 47 73 / 0) !important;
}

.tw-border-l-sky-950\/10 {
  border-left-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-l-sky-950\/100 {
  border-left-color: rgb(8 47 73 / 1) !important;
}

.tw-border-l-sky-950\/15 {
  border-left-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-l-sky-950\/20 {
  border-left-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-l-sky-950\/25 {
  border-left-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-l-sky-950\/30 {
  border-left-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-l-sky-950\/35 {
  border-left-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-l-sky-950\/40 {
  border-left-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-l-sky-950\/45 {
  border-left-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-l-sky-950\/5 {
  border-left-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-l-sky-950\/50 {
  border-left-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-l-sky-950\/55 {
  border-left-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-l-sky-950\/60 {
  border-left-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-l-sky-950\/65 {
  border-left-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-l-sky-950\/70 {
  border-left-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-l-sky-950\/75 {
  border-left-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-l-sky-950\/80 {
  border-left-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-l-sky-950\/85 {
  border-left-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-l-sky-950\/90 {
  border-left-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-l-sky-950\/95 {
  border-left-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-l-slate-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-100\/0 {
  border-left-color: rgb(241 245 249 / 0) !important;
}

.tw-border-l-slate-100\/10 {
  border-left-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-l-slate-100\/100 {
  border-left-color: rgb(241 245 249 / 1) !important;
}

.tw-border-l-slate-100\/15 {
  border-left-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-l-slate-100\/20 {
  border-left-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-l-slate-100\/25 {
  border-left-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-l-slate-100\/30 {
  border-left-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-l-slate-100\/35 {
  border-left-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-l-slate-100\/40 {
  border-left-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-l-slate-100\/45 {
  border-left-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-l-slate-100\/5 {
  border-left-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-l-slate-100\/50 {
  border-left-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-l-slate-100\/55 {
  border-left-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-l-slate-100\/60 {
  border-left-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-l-slate-100\/65 {
  border-left-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-l-slate-100\/70 {
  border-left-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-l-slate-100\/75 {
  border-left-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-l-slate-100\/80 {
  border-left-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-l-slate-100\/85 {
  border-left-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-l-slate-100\/90 {
  border-left-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-l-slate-100\/95 {
  border-left-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-l-slate-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-200\/0 {
  border-left-color: rgb(226 232 240 / 0) !important;
}

.tw-border-l-slate-200\/10 {
  border-left-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-l-slate-200\/100 {
  border-left-color: rgb(226 232 240 / 1) !important;
}

.tw-border-l-slate-200\/15 {
  border-left-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-l-slate-200\/20 {
  border-left-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-l-slate-200\/25 {
  border-left-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-l-slate-200\/30 {
  border-left-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-l-slate-200\/35 {
  border-left-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-l-slate-200\/40 {
  border-left-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-l-slate-200\/45 {
  border-left-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-l-slate-200\/5 {
  border-left-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-l-slate-200\/50 {
  border-left-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-l-slate-200\/55 {
  border-left-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-l-slate-200\/60 {
  border-left-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-l-slate-200\/65 {
  border-left-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-l-slate-200\/70 {
  border-left-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-l-slate-200\/75 {
  border-left-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-l-slate-200\/80 {
  border-left-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-l-slate-200\/85 {
  border-left-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-l-slate-200\/90 {
  border-left-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-l-slate-200\/95 {
  border-left-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-l-slate-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-300\/0 {
  border-left-color: rgb(203 213 225 / 0) !important;
}

.tw-border-l-slate-300\/10 {
  border-left-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-l-slate-300\/100 {
  border-left-color: rgb(203 213 225 / 1) !important;
}

.tw-border-l-slate-300\/15 {
  border-left-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-l-slate-300\/20 {
  border-left-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-l-slate-300\/25 {
  border-left-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-l-slate-300\/30 {
  border-left-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-l-slate-300\/35 {
  border-left-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-l-slate-300\/40 {
  border-left-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-l-slate-300\/45 {
  border-left-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-l-slate-300\/5 {
  border-left-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-l-slate-300\/50 {
  border-left-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-l-slate-300\/55 {
  border-left-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-l-slate-300\/60 {
  border-left-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-l-slate-300\/65 {
  border-left-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-l-slate-300\/70 {
  border-left-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-l-slate-300\/75 {
  border-left-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-l-slate-300\/80 {
  border-left-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-l-slate-300\/85 {
  border-left-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-l-slate-300\/90 {
  border-left-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-l-slate-300\/95 {
  border-left-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-l-slate-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-400\/0 {
  border-left-color: rgb(148 163 184 / 0) !important;
}

.tw-border-l-slate-400\/10 {
  border-left-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-l-slate-400\/100 {
  border-left-color: rgb(148 163 184 / 1) !important;
}

.tw-border-l-slate-400\/15 {
  border-left-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-l-slate-400\/20 {
  border-left-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-l-slate-400\/25 {
  border-left-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-l-slate-400\/30 {
  border-left-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-l-slate-400\/35 {
  border-left-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-l-slate-400\/40 {
  border-left-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-l-slate-400\/45 {
  border-left-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-l-slate-400\/5 {
  border-left-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-l-slate-400\/50 {
  border-left-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-l-slate-400\/55 {
  border-left-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-l-slate-400\/60 {
  border-left-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-l-slate-400\/65 {
  border-left-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-l-slate-400\/70 {
  border-left-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-l-slate-400\/75 {
  border-left-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-l-slate-400\/80 {
  border-left-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-l-slate-400\/85 {
  border-left-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-l-slate-400\/90 {
  border-left-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-l-slate-400\/95 {
  border-left-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-l-slate-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-50\/0 {
  border-left-color: rgb(248 250 252 / 0) !important;
}

.tw-border-l-slate-50\/10 {
  border-left-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-l-slate-50\/100 {
  border-left-color: rgb(248 250 252 / 1) !important;
}

.tw-border-l-slate-50\/15 {
  border-left-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-l-slate-50\/20 {
  border-left-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-l-slate-50\/25 {
  border-left-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-l-slate-50\/30 {
  border-left-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-l-slate-50\/35 {
  border-left-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-l-slate-50\/40 {
  border-left-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-l-slate-50\/45 {
  border-left-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-l-slate-50\/5 {
  border-left-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-l-slate-50\/50 {
  border-left-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-l-slate-50\/55 {
  border-left-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-l-slate-50\/60 {
  border-left-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-l-slate-50\/65 {
  border-left-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-l-slate-50\/70 {
  border-left-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-l-slate-50\/75 {
  border-left-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-l-slate-50\/80 {
  border-left-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-l-slate-50\/85 {
  border-left-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-l-slate-50\/90 {
  border-left-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-l-slate-50\/95 {
  border-left-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-l-slate-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-500\/0 {
  border-left-color: rgb(100 116 139 / 0) !important;
}

.tw-border-l-slate-500\/10 {
  border-left-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-l-slate-500\/100 {
  border-left-color: rgb(100 116 139 / 1) !important;
}

.tw-border-l-slate-500\/15 {
  border-left-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-l-slate-500\/20 {
  border-left-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-l-slate-500\/25 {
  border-left-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-l-slate-500\/30 {
  border-left-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-l-slate-500\/35 {
  border-left-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-l-slate-500\/40 {
  border-left-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-l-slate-500\/45 {
  border-left-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-l-slate-500\/5 {
  border-left-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-l-slate-500\/50 {
  border-left-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-l-slate-500\/55 {
  border-left-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-l-slate-500\/60 {
  border-left-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-l-slate-500\/65 {
  border-left-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-l-slate-500\/70 {
  border-left-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-l-slate-500\/75 {
  border-left-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-l-slate-500\/80 {
  border-left-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-l-slate-500\/85 {
  border-left-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-l-slate-500\/90 {
  border-left-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-l-slate-500\/95 {
  border-left-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-l-slate-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-600\/0 {
  border-left-color: rgb(71 85 105 / 0) !important;
}

.tw-border-l-slate-600\/10 {
  border-left-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-l-slate-600\/100 {
  border-left-color: rgb(71 85 105 / 1) !important;
}

.tw-border-l-slate-600\/15 {
  border-left-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-l-slate-600\/20 {
  border-left-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-l-slate-600\/25 {
  border-left-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-l-slate-600\/30 {
  border-left-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-l-slate-600\/35 {
  border-left-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-l-slate-600\/40 {
  border-left-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-l-slate-600\/45 {
  border-left-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-l-slate-600\/5 {
  border-left-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-l-slate-600\/50 {
  border-left-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-l-slate-600\/55 {
  border-left-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-l-slate-600\/60 {
  border-left-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-l-slate-600\/65 {
  border-left-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-l-slate-600\/70 {
  border-left-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-l-slate-600\/75 {
  border-left-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-l-slate-600\/80 {
  border-left-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-l-slate-600\/85 {
  border-left-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-l-slate-600\/90 {
  border-left-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-l-slate-600\/95 {
  border-left-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-l-slate-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-700\/0 {
  border-left-color: rgb(51 65 85 / 0) !important;
}

.tw-border-l-slate-700\/10 {
  border-left-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-l-slate-700\/100 {
  border-left-color: rgb(51 65 85 / 1) !important;
}

.tw-border-l-slate-700\/15 {
  border-left-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-l-slate-700\/20 {
  border-left-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-l-slate-700\/25 {
  border-left-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-l-slate-700\/30 {
  border-left-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-l-slate-700\/35 {
  border-left-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-l-slate-700\/40 {
  border-left-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-l-slate-700\/45 {
  border-left-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-l-slate-700\/5 {
  border-left-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-l-slate-700\/50 {
  border-left-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-l-slate-700\/55 {
  border-left-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-l-slate-700\/60 {
  border-left-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-l-slate-700\/65 {
  border-left-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-l-slate-700\/70 {
  border-left-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-l-slate-700\/75 {
  border-left-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-l-slate-700\/80 {
  border-left-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-l-slate-700\/85 {
  border-left-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-l-slate-700\/90 {
  border-left-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-l-slate-700\/95 {
  border-left-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-l-slate-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-800\/0 {
  border-left-color: rgb(30 41 59 / 0) !important;
}

.tw-border-l-slate-800\/10 {
  border-left-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-l-slate-800\/100 {
  border-left-color: rgb(30 41 59 / 1) !important;
}

.tw-border-l-slate-800\/15 {
  border-left-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-l-slate-800\/20 {
  border-left-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-l-slate-800\/25 {
  border-left-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-l-slate-800\/30 {
  border-left-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-l-slate-800\/35 {
  border-left-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-l-slate-800\/40 {
  border-left-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-l-slate-800\/45 {
  border-left-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-l-slate-800\/5 {
  border-left-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-l-slate-800\/50 {
  border-left-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-l-slate-800\/55 {
  border-left-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-l-slate-800\/60 {
  border-left-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-l-slate-800\/65 {
  border-left-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-l-slate-800\/70 {
  border-left-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-l-slate-800\/75 {
  border-left-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-l-slate-800\/80 {
  border-left-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-l-slate-800\/85 {
  border-left-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-l-slate-800\/90 {
  border-left-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-l-slate-800\/95 {
  border-left-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-l-slate-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-900\/0 {
  border-left-color: rgb(15 23 42 / 0) !important;
}

.tw-border-l-slate-900\/10 {
  border-left-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-l-slate-900\/100 {
  border-left-color: rgb(15 23 42 / 1) !important;
}

.tw-border-l-slate-900\/15 {
  border-left-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-l-slate-900\/20 {
  border-left-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-l-slate-900\/25 {
  border-left-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-l-slate-900\/30 {
  border-left-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-l-slate-900\/35 {
  border-left-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-l-slate-900\/40 {
  border-left-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-l-slate-900\/45 {
  border-left-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-l-slate-900\/5 {
  border-left-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-l-slate-900\/50 {
  border-left-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-l-slate-900\/55 {
  border-left-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-l-slate-900\/60 {
  border-left-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-l-slate-900\/65 {
  border-left-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-l-slate-900\/70 {
  border-left-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-l-slate-900\/75 {
  border-left-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-l-slate-900\/80 {
  border-left-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-l-slate-900\/85 {
  border-left-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-l-slate-900\/90 {
  border-left-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-l-slate-900\/95 {
  border-left-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-l-slate-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-l-slate-950\/0 {
  border-left-color: rgb(2 6 23 / 0) !important;
}

.tw-border-l-slate-950\/10 {
  border-left-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-l-slate-950\/100 {
  border-left-color: rgb(2 6 23 / 1) !important;
}

.tw-border-l-slate-950\/15 {
  border-left-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-l-slate-950\/20 {
  border-left-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-l-slate-950\/25 {
  border-left-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-l-slate-950\/30 {
  border-left-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-l-slate-950\/35 {
  border-left-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-l-slate-950\/40 {
  border-left-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-l-slate-950\/45 {
  border-left-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-l-slate-950\/5 {
  border-left-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-l-slate-950\/50 {
  border-left-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-l-slate-950\/55 {
  border-left-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-l-slate-950\/60 {
  border-left-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-l-slate-950\/65 {
  border-left-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-l-slate-950\/70 {
  border-left-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-l-slate-950\/75 {
  border-left-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-l-slate-950\/80 {
  border-left-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-l-slate-950\/85 {
  border-left-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-l-slate-950\/90 {
  border-left-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-l-slate-950\/95 {
  border-left-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-l-stone-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-100\/0 {
  border-left-color: rgb(245 245 244 / 0) !important;
}

.tw-border-l-stone-100\/10 {
  border-left-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-l-stone-100\/100 {
  border-left-color: rgb(245 245 244 / 1) !important;
}

.tw-border-l-stone-100\/15 {
  border-left-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-l-stone-100\/20 {
  border-left-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-l-stone-100\/25 {
  border-left-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-l-stone-100\/30 {
  border-left-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-l-stone-100\/35 {
  border-left-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-l-stone-100\/40 {
  border-left-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-l-stone-100\/45 {
  border-left-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-l-stone-100\/5 {
  border-left-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-l-stone-100\/50 {
  border-left-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-l-stone-100\/55 {
  border-left-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-l-stone-100\/60 {
  border-left-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-l-stone-100\/65 {
  border-left-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-l-stone-100\/70 {
  border-left-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-l-stone-100\/75 {
  border-left-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-l-stone-100\/80 {
  border-left-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-l-stone-100\/85 {
  border-left-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-l-stone-100\/90 {
  border-left-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-l-stone-100\/95 {
  border-left-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-l-stone-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-200\/0 {
  border-left-color: rgb(231 229 228 / 0) !important;
}

.tw-border-l-stone-200\/10 {
  border-left-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-l-stone-200\/100 {
  border-left-color: rgb(231 229 228 / 1) !important;
}

.tw-border-l-stone-200\/15 {
  border-left-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-l-stone-200\/20 {
  border-left-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-l-stone-200\/25 {
  border-left-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-l-stone-200\/30 {
  border-left-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-l-stone-200\/35 {
  border-left-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-l-stone-200\/40 {
  border-left-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-l-stone-200\/45 {
  border-left-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-l-stone-200\/5 {
  border-left-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-l-stone-200\/50 {
  border-left-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-l-stone-200\/55 {
  border-left-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-l-stone-200\/60 {
  border-left-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-l-stone-200\/65 {
  border-left-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-l-stone-200\/70 {
  border-left-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-l-stone-200\/75 {
  border-left-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-l-stone-200\/80 {
  border-left-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-l-stone-200\/85 {
  border-left-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-l-stone-200\/90 {
  border-left-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-l-stone-200\/95 {
  border-left-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-l-stone-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-300\/0 {
  border-left-color: rgb(214 211 209 / 0) !important;
}

.tw-border-l-stone-300\/10 {
  border-left-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-l-stone-300\/100 {
  border-left-color: rgb(214 211 209 / 1) !important;
}

.tw-border-l-stone-300\/15 {
  border-left-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-l-stone-300\/20 {
  border-left-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-l-stone-300\/25 {
  border-left-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-l-stone-300\/30 {
  border-left-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-l-stone-300\/35 {
  border-left-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-l-stone-300\/40 {
  border-left-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-l-stone-300\/45 {
  border-left-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-l-stone-300\/5 {
  border-left-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-l-stone-300\/50 {
  border-left-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-l-stone-300\/55 {
  border-left-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-l-stone-300\/60 {
  border-left-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-l-stone-300\/65 {
  border-left-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-l-stone-300\/70 {
  border-left-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-l-stone-300\/75 {
  border-left-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-l-stone-300\/80 {
  border-left-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-l-stone-300\/85 {
  border-left-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-l-stone-300\/90 {
  border-left-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-l-stone-300\/95 {
  border-left-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-l-stone-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-400\/0 {
  border-left-color: rgb(168 162 158 / 0) !important;
}

.tw-border-l-stone-400\/10 {
  border-left-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-l-stone-400\/100 {
  border-left-color: rgb(168 162 158 / 1) !important;
}

.tw-border-l-stone-400\/15 {
  border-left-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-l-stone-400\/20 {
  border-left-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-l-stone-400\/25 {
  border-left-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-l-stone-400\/30 {
  border-left-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-l-stone-400\/35 {
  border-left-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-l-stone-400\/40 {
  border-left-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-l-stone-400\/45 {
  border-left-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-l-stone-400\/5 {
  border-left-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-l-stone-400\/50 {
  border-left-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-l-stone-400\/55 {
  border-left-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-l-stone-400\/60 {
  border-left-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-l-stone-400\/65 {
  border-left-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-l-stone-400\/70 {
  border-left-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-l-stone-400\/75 {
  border-left-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-l-stone-400\/80 {
  border-left-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-l-stone-400\/85 {
  border-left-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-l-stone-400\/90 {
  border-left-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-l-stone-400\/95 {
  border-left-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-l-stone-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-50\/0 {
  border-left-color: rgb(250 250 249 / 0) !important;
}

.tw-border-l-stone-50\/10 {
  border-left-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-l-stone-50\/100 {
  border-left-color: rgb(250 250 249 / 1) !important;
}

.tw-border-l-stone-50\/15 {
  border-left-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-l-stone-50\/20 {
  border-left-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-l-stone-50\/25 {
  border-left-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-l-stone-50\/30 {
  border-left-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-l-stone-50\/35 {
  border-left-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-l-stone-50\/40 {
  border-left-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-l-stone-50\/45 {
  border-left-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-l-stone-50\/5 {
  border-left-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-l-stone-50\/50 {
  border-left-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-l-stone-50\/55 {
  border-left-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-l-stone-50\/60 {
  border-left-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-l-stone-50\/65 {
  border-left-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-l-stone-50\/70 {
  border-left-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-l-stone-50\/75 {
  border-left-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-l-stone-50\/80 {
  border-left-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-l-stone-50\/85 {
  border-left-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-l-stone-50\/90 {
  border-left-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-l-stone-50\/95 {
  border-left-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-l-stone-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-500\/0 {
  border-left-color: rgb(120 113 108 / 0) !important;
}

.tw-border-l-stone-500\/10 {
  border-left-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-l-stone-500\/100 {
  border-left-color: rgb(120 113 108 / 1) !important;
}

.tw-border-l-stone-500\/15 {
  border-left-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-l-stone-500\/20 {
  border-left-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-l-stone-500\/25 {
  border-left-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-l-stone-500\/30 {
  border-left-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-l-stone-500\/35 {
  border-left-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-l-stone-500\/40 {
  border-left-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-l-stone-500\/45 {
  border-left-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-l-stone-500\/5 {
  border-left-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-l-stone-500\/50 {
  border-left-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-l-stone-500\/55 {
  border-left-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-l-stone-500\/60 {
  border-left-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-l-stone-500\/65 {
  border-left-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-l-stone-500\/70 {
  border-left-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-l-stone-500\/75 {
  border-left-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-l-stone-500\/80 {
  border-left-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-l-stone-500\/85 {
  border-left-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-l-stone-500\/90 {
  border-left-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-l-stone-500\/95 {
  border-left-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-l-stone-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-600\/0 {
  border-left-color: rgb(87 83 78 / 0) !important;
}

.tw-border-l-stone-600\/10 {
  border-left-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-l-stone-600\/100 {
  border-left-color: rgb(87 83 78 / 1) !important;
}

.tw-border-l-stone-600\/15 {
  border-left-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-l-stone-600\/20 {
  border-left-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-l-stone-600\/25 {
  border-left-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-l-stone-600\/30 {
  border-left-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-l-stone-600\/35 {
  border-left-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-l-stone-600\/40 {
  border-left-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-l-stone-600\/45 {
  border-left-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-l-stone-600\/5 {
  border-left-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-l-stone-600\/50 {
  border-left-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-l-stone-600\/55 {
  border-left-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-l-stone-600\/60 {
  border-left-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-l-stone-600\/65 {
  border-left-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-l-stone-600\/70 {
  border-left-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-l-stone-600\/75 {
  border-left-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-l-stone-600\/80 {
  border-left-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-l-stone-600\/85 {
  border-left-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-l-stone-600\/90 {
  border-left-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-l-stone-600\/95 {
  border-left-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-l-stone-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-700\/0 {
  border-left-color: rgb(68 64 60 / 0) !important;
}

.tw-border-l-stone-700\/10 {
  border-left-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-l-stone-700\/100 {
  border-left-color: rgb(68 64 60 / 1) !important;
}

.tw-border-l-stone-700\/15 {
  border-left-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-l-stone-700\/20 {
  border-left-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-l-stone-700\/25 {
  border-left-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-l-stone-700\/30 {
  border-left-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-l-stone-700\/35 {
  border-left-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-l-stone-700\/40 {
  border-left-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-l-stone-700\/45 {
  border-left-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-l-stone-700\/5 {
  border-left-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-l-stone-700\/50 {
  border-left-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-l-stone-700\/55 {
  border-left-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-l-stone-700\/60 {
  border-left-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-l-stone-700\/65 {
  border-left-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-l-stone-700\/70 {
  border-left-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-l-stone-700\/75 {
  border-left-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-l-stone-700\/80 {
  border-left-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-l-stone-700\/85 {
  border-left-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-l-stone-700\/90 {
  border-left-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-l-stone-700\/95 {
  border-left-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-l-stone-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-800\/0 {
  border-left-color: rgb(41 37 36 / 0) !important;
}

.tw-border-l-stone-800\/10 {
  border-left-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-l-stone-800\/100 {
  border-left-color: rgb(41 37 36 / 1) !important;
}

.tw-border-l-stone-800\/15 {
  border-left-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-l-stone-800\/20 {
  border-left-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-l-stone-800\/25 {
  border-left-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-l-stone-800\/30 {
  border-left-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-l-stone-800\/35 {
  border-left-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-l-stone-800\/40 {
  border-left-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-l-stone-800\/45 {
  border-left-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-l-stone-800\/5 {
  border-left-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-l-stone-800\/50 {
  border-left-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-l-stone-800\/55 {
  border-left-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-l-stone-800\/60 {
  border-left-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-l-stone-800\/65 {
  border-left-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-l-stone-800\/70 {
  border-left-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-l-stone-800\/75 {
  border-left-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-l-stone-800\/80 {
  border-left-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-l-stone-800\/85 {
  border-left-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-l-stone-800\/90 {
  border-left-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-l-stone-800\/95 {
  border-left-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-l-stone-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-900\/0 {
  border-left-color: rgb(28 25 23 / 0) !important;
}

.tw-border-l-stone-900\/10 {
  border-left-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-l-stone-900\/100 {
  border-left-color: rgb(28 25 23 / 1) !important;
}

.tw-border-l-stone-900\/15 {
  border-left-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-l-stone-900\/20 {
  border-left-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-l-stone-900\/25 {
  border-left-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-l-stone-900\/30 {
  border-left-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-l-stone-900\/35 {
  border-left-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-l-stone-900\/40 {
  border-left-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-l-stone-900\/45 {
  border-left-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-l-stone-900\/5 {
  border-left-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-l-stone-900\/50 {
  border-left-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-l-stone-900\/55 {
  border-left-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-l-stone-900\/60 {
  border-left-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-l-stone-900\/65 {
  border-left-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-l-stone-900\/70 {
  border-left-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-l-stone-900\/75 {
  border-left-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-l-stone-900\/80 {
  border-left-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-l-stone-900\/85 {
  border-left-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-l-stone-900\/90 {
  border-left-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-l-stone-900\/95 {
  border-left-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-l-stone-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-l-stone-950\/0 {
  border-left-color: rgb(12 10 9 / 0) !important;
}

.tw-border-l-stone-950\/10 {
  border-left-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-l-stone-950\/100 {
  border-left-color: rgb(12 10 9 / 1) !important;
}

.tw-border-l-stone-950\/15 {
  border-left-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-l-stone-950\/20 {
  border-left-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-l-stone-950\/25 {
  border-left-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-l-stone-950\/30 {
  border-left-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-l-stone-950\/35 {
  border-left-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-l-stone-950\/40 {
  border-left-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-l-stone-950\/45 {
  border-left-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-l-stone-950\/5 {
  border-left-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-l-stone-950\/50 {
  border-left-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-l-stone-950\/55 {
  border-left-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-l-stone-950\/60 {
  border-left-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-l-stone-950\/65 {
  border-left-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-l-stone-950\/70 {
  border-left-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-l-stone-950\/75 {
  border-left-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-l-stone-950\/80 {
  border-left-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-l-stone-950\/85 {
  border-left-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-l-stone-950\/90 {
  border-left-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-l-stone-950\/95 {
  border-left-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-l-teal-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-100\/0 {
  border-left-color: rgb(204 251 241 / 0) !important;
}

.tw-border-l-teal-100\/10 {
  border-left-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-l-teal-100\/100 {
  border-left-color: rgb(204 251 241 / 1) !important;
}

.tw-border-l-teal-100\/15 {
  border-left-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-l-teal-100\/20 {
  border-left-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-l-teal-100\/25 {
  border-left-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-l-teal-100\/30 {
  border-left-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-l-teal-100\/35 {
  border-left-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-l-teal-100\/40 {
  border-left-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-l-teal-100\/45 {
  border-left-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-l-teal-100\/5 {
  border-left-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-l-teal-100\/50 {
  border-left-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-l-teal-100\/55 {
  border-left-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-l-teal-100\/60 {
  border-left-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-l-teal-100\/65 {
  border-left-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-l-teal-100\/70 {
  border-left-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-l-teal-100\/75 {
  border-left-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-l-teal-100\/80 {
  border-left-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-l-teal-100\/85 {
  border-left-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-l-teal-100\/90 {
  border-left-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-l-teal-100\/95 {
  border-left-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-l-teal-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-200\/0 {
  border-left-color: rgb(153 246 228 / 0) !important;
}

.tw-border-l-teal-200\/10 {
  border-left-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-l-teal-200\/100 {
  border-left-color: rgb(153 246 228 / 1) !important;
}

.tw-border-l-teal-200\/15 {
  border-left-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-l-teal-200\/20 {
  border-left-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-l-teal-200\/25 {
  border-left-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-l-teal-200\/30 {
  border-left-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-l-teal-200\/35 {
  border-left-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-l-teal-200\/40 {
  border-left-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-l-teal-200\/45 {
  border-left-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-l-teal-200\/5 {
  border-left-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-l-teal-200\/50 {
  border-left-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-l-teal-200\/55 {
  border-left-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-l-teal-200\/60 {
  border-left-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-l-teal-200\/65 {
  border-left-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-l-teal-200\/70 {
  border-left-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-l-teal-200\/75 {
  border-left-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-l-teal-200\/80 {
  border-left-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-l-teal-200\/85 {
  border-left-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-l-teal-200\/90 {
  border-left-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-l-teal-200\/95 {
  border-left-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-l-teal-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-300\/0 {
  border-left-color: rgb(94 234 212 / 0) !important;
}

.tw-border-l-teal-300\/10 {
  border-left-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-l-teal-300\/100 {
  border-left-color: rgb(94 234 212 / 1) !important;
}

.tw-border-l-teal-300\/15 {
  border-left-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-l-teal-300\/20 {
  border-left-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-l-teal-300\/25 {
  border-left-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-l-teal-300\/30 {
  border-left-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-l-teal-300\/35 {
  border-left-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-l-teal-300\/40 {
  border-left-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-l-teal-300\/45 {
  border-left-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-l-teal-300\/5 {
  border-left-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-l-teal-300\/50 {
  border-left-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-l-teal-300\/55 {
  border-left-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-l-teal-300\/60 {
  border-left-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-l-teal-300\/65 {
  border-left-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-l-teal-300\/70 {
  border-left-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-l-teal-300\/75 {
  border-left-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-l-teal-300\/80 {
  border-left-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-l-teal-300\/85 {
  border-left-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-l-teal-300\/90 {
  border-left-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-l-teal-300\/95 {
  border-left-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-l-teal-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-400\/0 {
  border-left-color: rgb(45 212 191 / 0) !important;
}

.tw-border-l-teal-400\/10 {
  border-left-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-l-teal-400\/100 {
  border-left-color: rgb(45 212 191 / 1) !important;
}

.tw-border-l-teal-400\/15 {
  border-left-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-l-teal-400\/20 {
  border-left-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-l-teal-400\/25 {
  border-left-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-l-teal-400\/30 {
  border-left-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-l-teal-400\/35 {
  border-left-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-l-teal-400\/40 {
  border-left-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-l-teal-400\/45 {
  border-left-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-l-teal-400\/5 {
  border-left-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-l-teal-400\/50 {
  border-left-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-l-teal-400\/55 {
  border-left-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-l-teal-400\/60 {
  border-left-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-l-teal-400\/65 {
  border-left-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-l-teal-400\/70 {
  border-left-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-l-teal-400\/75 {
  border-left-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-l-teal-400\/80 {
  border-left-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-l-teal-400\/85 {
  border-left-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-l-teal-400\/90 {
  border-left-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-l-teal-400\/95 {
  border-left-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-l-teal-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-50\/0 {
  border-left-color: rgb(240 253 250 / 0) !important;
}

.tw-border-l-teal-50\/10 {
  border-left-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-l-teal-50\/100 {
  border-left-color: rgb(240 253 250 / 1) !important;
}

.tw-border-l-teal-50\/15 {
  border-left-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-l-teal-50\/20 {
  border-left-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-l-teal-50\/25 {
  border-left-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-l-teal-50\/30 {
  border-left-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-l-teal-50\/35 {
  border-left-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-l-teal-50\/40 {
  border-left-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-l-teal-50\/45 {
  border-left-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-l-teal-50\/5 {
  border-left-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-l-teal-50\/50 {
  border-left-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-l-teal-50\/55 {
  border-left-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-l-teal-50\/60 {
  border-left-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-l-teal-50\/65 {
  border-left-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-l-teal-50\/70 {
  border-left-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-l-teal-50\/75 {
  border-left-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-l-teal-50\/80 {
  border-left-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-l-teal-50\/85 {
  border-left-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-l-teal-50\/90 {
  border-left-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-l-teal-50\/95 {
  border-left-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-l-teal-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-500\/0 {
  border-left-color: rgb(20 184 166 / 0) !important;
}

.tw-border-l-teal-500\/10 {
  border-left-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-l-teal-500\/100 {
  border-left-color: rgb(20 184 166 / 1) !important;
}

.tw-border-l-teal-500\/15 {
  border-left-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-l-teal-500\/20 {
  border-left-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-l-teal-500\/25 {
  border-left-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-l-teal-500\/30 {
  border-left-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-l-teal-500\/35 {
  border-left-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-l-teal-500\/40 {
  border-left-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-l-teal-500\/45 {
  border-left-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-l-teal-500\/5 {
  border-left-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-l-teal-500\/50 {
  border-left-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-l-teal-500\/55 {
  border-left-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-l-teal-500\/60 {
  border-left-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-l-teal-500\/65 {
  border-left-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-l-teal-500\/70 {
  border-left-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-l-teal-500\/75 {
  border-left-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-l-teal-500\/80 {
  border-left-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-l-teal-500\/85 {
  border-left-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-l-teal-500\/90 {
  border-left-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-l-teal-500\/95 {
  border-left-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-l-teal-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-600\/0 {
  border-left-color: rgb(13 148 136 / 0) !important;
}

.tw-border-l-teal-600\/10 {
  border-left-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-l-teal-600\/100 {
  border-left-color: rgb(13 148 136 / 1) !important;
}

.tw-border-l-teal-600\/15 {
  border-left-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-l-teal-600\/20 {
  border-left-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-l-teal-600\/25 {
  border-left-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-l-teal-600\/30 {
  border-left-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-l-teal-600\/35 {
  border-left-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-l-teal-600\/40 {
  border-left-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-l-teal-600\/45 {
  border-left-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-l-teal-600\/5 {
  border-left-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-l-teal-600\/50 {
  border-left-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-l-teal-600\/55 {
  border-left-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-l-teal-600\/60 {
  border-left-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-l-teal-600\/65 {
  border-left-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-l-teal-600\/70 {
  border-left-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-l-teal-600\/75 {
  border-left-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-l-teal-600\/80 {
  border-left-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-l-teal-600\/85 {
  border-left-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-l-teal-600\/90 {
  border-left-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-l-teal-600\/95 {
  border-left-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-l-teal-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-700\/0 {
  border-left-color: rgb(15 118 110 / 0) !important;
}

.tw-border-l-teal-700\/10 {
  border-left-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-l-teal-700\/100 {
  border-left-color: rgb(15 118 110 / 1) !important;
}

.tw-border-l-teal-700\/15 {
  border-left-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-l-teal-700\/20 {
  border-left-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-l-teal-700\/25 {
  border-left-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-l-teal-700\/30 {
  border-left-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-l-teal-700\/35 {
  border-left-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-l-teal-700\/40 {
  border-left-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-l-teal-700\/45 {
  border-left-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-l-teal-700\/5 {
  border-left-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-l-teal-700\/50 {
  border-left-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-l-teal-700\/55 {
  border-left-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-l-teal-700\/60 {
  border-left-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-l-teal-700\/65 {
  border-left-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-l-teal-700\/70 {
  border-left-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-l-teal-700\/75 {
  border-left-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-l-teal-700\/80 {
  border-left-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-l-teal-700\/85 {
  border-left-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-l-teal-700\/90 {
  border-left-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-l-teal-700\/95 {
  border-left-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-l-teal-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-800\/0 {
  border-left-color: rgb(17 94 89 / 0) !important;
}

.tw-border-l-teal-800\/10 {
  border-left-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-l-teal-800\/100 {
  border-left-color: rgb(17 94 89 / 1) !important;
}

.tw-border-l-teal-800\/15 {
  border-left-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-l-teal-800\/20 {
  border-left-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-l-teal-800\/25 {
  border-left-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-l-teal-800\/30 {
  border-left-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-l-teal-800\/35 {
  border-left-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-l-teal-800\/40 {
  border-left-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-l-teal-800\/45 {
  border-left-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-l-teal-800\/5 {
  border-left-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-l-teal-800\/50 {
  border-left-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-l-teal-800\/55 {
  border-left-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-l-teal-800\/60 {
  border-left-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-l-teal-800\/65 {
  border-left-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-l-teal-800\/70 {
  border-left-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-l-teal-800\/75 {
  border-left-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-l-teal-800\/80 {
  border-left-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-l-teal-800\/85 {
  border-left-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-l-teal-800\/90 {
  border-left-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-l-teal-800\/95 {
  border-left-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-l-teal-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-900\/0 {
  border-left-color: rgb(19 78 74 / 0) !important;
}

.tw-border-l-teal-900\/10 {
  border-left-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-l-teal-900\/100 {
  border-left-color: rgb(19 78 74 / 1) !important;
}

.tw-border-l-teal-900\/15 {
  border-left-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-l-teal-900\/20 {
  border-left-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-l-teal-900\/25 {
  border-left-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-l-teal-900\/30 {
  border-left-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-l-teal-900\/35 {
  border-left-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-l-teal-900\/40 {
  border-left-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-l-teal-900\/45 {
  border-left-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-l-teal-900\/5 {
  border-left-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-l-teal-900\/50 {
  border-left-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-l-teal-900\/55 {
  border-left-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-l-teal-900\/60 {
  border-left-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-l-teal-900\/65 {
  border-left-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-l-teal-900\/70 {
  border-left-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-l-teal-900\/75 {
  border-left-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-l-teal-900\/80 {
  border-left-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-l-teal-900\/85 {
  border-left-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-l-teal-900\/90 {
  border-left-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-l-teal-900\/95 {
  border-left-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-l-teal-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-l-teal-950\/0 {
  border-left-color: rgb(4 47 46 / 0) !important;
}

.tw-border-l-teal-950\/10 {
  border-left-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-l-teal-950\/100 {
  border-left-color: rgb(4 47 46 / 1) !important;
}

.tw-border-l-teal-950\/15 {
  border-left-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-l-teal-950\/20 {
  border-left-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-l-teal-950\/25 {
  border-left-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-l-teal-950\/30 {
  border-left-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-l-teal-950\/35 {
  border-left-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-l-teal-950\/40 {
  border-left-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-l-teal-950\/45 {
  border-left-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-l-teal-950\/5 {
  border-left-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-l-teal-950\/50 {
  border-left-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-l-teal-950\/55 {
  border-left-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-l-teal-950\/60 {
  border-left-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-l-teal-950\/65 {
  border-left-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-l-teal-950\/70 {
  border-left-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-l-teal-950\/75 {
  border-left-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-l-teal-950\/80 {
  border-left-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-l-teal-950\/85 {
  border-left-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-l-teal-950\/90 {
  border-left-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-l-teal-950\/95 {
  border-left-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-l-transparent {
  border-left-color: transparent !important;
}

.tw-border-l-transparent\/0 {
  border-left-color: rgb(0 0 0 / 0) !important;
}

.tw-border-l-transparent\/10 {
  border-left-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-l-transparent\/100 {
  border-left-color: rgb(0 0 0 / 1) !important;
}

.tw-border-l-transparent\/15 {
  border-left-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-l-transparent\/20 {
  border-left-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-l-transparent\/25 {
  border-left-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-l-transparent\/30 {
  border-left-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-l-transparent\/35 {
  border-left-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-l-transparent\/40 {
  border-left-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-l-transparent\/45 {
  border-left-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-l-transparent\/5 {
  border-left-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-l-transparent\/50 {
  border-left-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-l-transparent\/55 {
  border-left-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-l-transparent\/60 {
  border-left-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-l-transparent\/65 {
  border-left-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-l-transparent\/70 {
  border-left-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-l-transparent\/75 {
  border-left-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-l-transparent\/80 {
  border-left-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-l-transparent\/85 {
  border-left-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-l-transparent\/90 {
  border-left-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-l-transparent\/95 {
  border-left-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-l-violet-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-100\/0 {
  border-left-color: rgb(237 233 254 / 0) !important;
}

.tw-border-l-violet-100\/10 {
  border-left-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-l-violet-100\/100 {
  border-left-color: rgb(237 233 254 / 1) !important;
}

.tw-border-l-violet-100\/15 {
  border-left-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-l-violet-100\/20 {
  border-left-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-l-violet-100\/25 {
  border-left-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-l-violet-100\/30 {
  border-left-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-l-violet-100\/35 {
  border-left-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-l-violet-100\/40 {
  border-left-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-l-violet-100\/45 {
  border-left-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-l-violet-100\/5 {
  border-left-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-l-violet-100\/50 {
  border-left-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-l-violet-100\/55 {
  border-left-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-l-violet-100\/60 {
  border-left-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-l-violet-100\/65 {
  border-left-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-l-violet-100\/70 {
  border-left-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-l-violet-100\/75 {
  border-left-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-l-violet-100\/80 {
  border-left-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-l-violet-100\/85 {
  border-left-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-l-violet-100\/90 {
  border-left-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-l-violet-100\/95 {
  border-left-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-l-violet-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-200\/0 {
  border-left-color: rgb(221 214 254 / 0) !important;
}

.tw-border-l-violet-200\/10 {
  border-left-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-l-violet-200\/100 {
  border-left-color: rgb(221 214 254 / 1) !important;
}

.tw-border-l-violet-200\/15 {
  border-left-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-l-violet-200\/20 {
  border-left-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-l-violet-200\/25 {
  border-left-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-l-violet-200\/30 {
  border-left-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-l-violet-200\/35 {
  border-left-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-l-violet-200\/40 {
  border-left-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-l-violet-200\/45 {
  border-left-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-l-violet-200\/5 {
  border-left-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-l-violet-200\/50 {
  border-left-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-l-violet-200\/55 {
  border-left-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-l-violet-200\/60 {
  border-left-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-l-violet-200\/65 {
  border-left-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-l-violet-200\/70 {
  border-left-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-l-violet-200\/75 {
  border-left-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-l-violet-200\/80 {
  border-left-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-l-violet-200\/85 {
  border-left-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-l-violet-200\/90 {
  border-left-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-l-violet-200\/95 {
  border-left-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-l-violet-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-300\/0 {
  border-left-color: rgb(196 181 253 / 0) !important;
}

.tw-border-l-violet-300\/10 {
  border-left-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-l-violet-300\/100 {
  border-left-color: rgb(196 181 253 / 1) !important;
}

.tw-border-l-violet-300\/15 {
  border-left-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-l-violet-300\/20 {
  border-left-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-l-violet-300\/25 {
  border-left-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-l-violet-300\/30 {
  border-left-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-l-violet-300\/35 {
  border-left-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-l-violet-300\/40 {
  border-left-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-l-violet-300\/45 {
  border-left-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-l-violet-300\/5 {
  border-left-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-l-violet-300\/50 {
  border-left-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-l-violet-300\/55 {
  border-left-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-l-violet-300\/60 {
  border-left-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-l-violet-300\/65 {
  border-left-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-l-violet-300\/70 {
  border-left-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-l-violet-300\/75 {
  border-left-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-l-violet-300\/80 {
  border-left-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-l-violet-300\/85 {
  border-left-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-l-violet-300\/90 {
  border-left-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-l-violet-300\/95 {
  border-left-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-l-violet-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-400\/0 {
  border-left-color: rgb(167 139 250 / 0) !important;
}

.tw-border-l-violet-400\/10 {
  border-left-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-l-violet-400\/100 {
  border-left-color: rgb(167 139 250 / 1) !important;
}

.tw-border-l-violet-400\/15 {
  border-left-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-l-violet-400\/20 {
  border-left-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-l-violet-400\/25 {
  border-left-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-l-violet-400\/30 {
  border-left-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-l-violet-400\/35 {
  border-left-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-l-violet-400\/40 {
  border-left-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-l-violet-400\/45 {
  border-left-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-l-violet-400\/5 {
  border-left-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-l-violet-400\/50 {
  border-left-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-l-violet-400\/55 {
  border-left-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-l-violet-400\/60 {
  border-left-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-l-violet-400\/65 {
  border-left-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-l-violet-400\/70 {
  border-left-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-l-violet-400\/75 {
  border-left-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-l-violet-400\/80 {
  border-left-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-l-violet-400\/85 {
  border-left-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-l-violet-400\/90 {
  border-left-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-l-violet-400\/95 {
  border-left-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-l-violet-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-50\/0 {
  border-left-color: rgb(245 243 255 / 0) !important;
}

.tw-border-l-violet-50\/10 {
  border-left-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-l-violet-50\/100 {
  border-left-color: rgb(245 243 255 / 1) !important;
}

.tw-border-l-violet-50\/15 {
  border-left-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-l-violet-50\/20 {
  border-left-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-l-violet-50\/25 {
  border-left-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-l-violet-50\/30 {
  border-left-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-l-violet-50\/35 {
  border-left-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-l-violet-50\/40 {
  border-left-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-l-violet-50\/45 {
  border-left-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-l-violet-50\/5 {
  border-left-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-l-violet-50\/50 {
  border-left-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-l-violet-50\/55 {
  border-left-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-l-violet-50\/60 {
  border-left-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-l-violet-50\/65 {
  border-left-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-l-violet-50\/70 {
  border-left-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-l-violet-50\/75 {
  border-left-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-l-violet-50\/80 {
  border-left-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-l-violet-50\/85 {
  border-left-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-l-violet-50\/90 {
  border-left-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-l-violet-50\/95 {
  border-left-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-l-violet-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-500\/0 {
  border-left-color: rgb(139 92 246 / 0) !important;
}

.tw-border-l-violet-500\/10 {
  border-left-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-l-violet-500\/100 {
  border-left-color: rgb(139 92 246 / 1) !important;
}

.tw-border-l-violet-500\/15 {
  border-left-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-l-violet-500\/20 {
  border-left-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-l-violet-500\/25 {
  border-left-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-l-violet-500\/30 {
  border-left-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-l-violet-500\/35 {
  border-left-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-l-violet-500\/40 {
  border-left-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-l-violet-500\/45 {
  border-left-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-l-violet-500\/5 {
  border-left-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-l-violet-500\/50 {
  border-left-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-l-violet-500\/55 {
  border-left-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-l-violet-500\/60 {
  border-left-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-l-violet-500\/65 {
  border-left-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-l-violet-500\/70 {
  border-left-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-l-violet-500\/75 {
  border-left-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-l-violet-500\/80 {
  border-left-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-l-violet-500\/85 {
  border-left-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-l-violet-500\/90 {
  border-left-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-l-violet-500\/95 {
  border-left-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-l-violet-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-600\/0 {
  border-left-color: rgb(124 58 237 / 0) !important;
}

.tw-border-l-violet-600\/10 {
  border-left-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-l-violet-600\/100 {
  border-left-color: rgb(124 58 237 / 1) !important;
}

.tw-border-l-violet-600\/15 {
  border-left-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-l-violet-600\/20 {
  border-left-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-l-violet-600\/25 {
  border-left-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-l-violet-600\/30 {
  border-left-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-l-violet-600\/35 {
  border-left-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-l-violet-600\/40 {
  border-left-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-l-violet-600\/45 {
  border-left-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-l-violet-600\/5 {
  border-left-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-l-violet-600\/50 {
  border-left-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-l-violet-600\/55 {
  border-left-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-l-violet-600\/60 {
  border-left-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-l-violet-600\/65 {
  border-left-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-l-violet-600\/70 {
  border-left-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-l-violet-600\/75 {
  border-left-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-l-violet-600\/80 {
  border-left-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-l-violet-600\/85 {
  border-left-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-l-violet-600\/90 {
  border-left-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-l-violet-600\/95 {
  border-left-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-l-violet-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-700\/0 {
  border-left-color: rgb(109 40 217 / 0) !important;
}

.tw-border-l-violet-700\/10 {
  border-left-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-l-violet-700\/100 {
  border-left-color: rgb(109 40 217 / 1) !important;
}

.tw-border-l-violet-700\/15 {
  border-left-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-l-violet-700\/20 {
  border-left-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-l-violet-700\/25 {
  border-left-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-l-violet-700\/30 {
  border-left-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-l-violet-700\/35 {
  border-left-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-l-violet-700\/40 {
  border-left-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-l-violet-700\/45 {
  border-left-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-l-violet-700\/5 {
  border-left-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-l-violet-700\/50 {
  border-left-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-l-violet-700\/55 {
  border-left-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-l-violet-700\/60 {
  border-left-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-l-violet-700\/65 {
  border-left-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-l-violet-700\/70 {
  border-left-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-l-violet-700\/75 {
  border-left-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-l-violet-700\/80 {
  border-left-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-l-violet-700\/85 {
  border-left-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-l-violet-700\/90 {
  border-left-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-l-violet-700\/95 {
  border-left-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-l-violet-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-800\/0 {
  border-left-color: rgb(91 33 182 / 0) !important;
}

.tw-border-l-violet-800\/10 {
  border-left-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-l-violet-800\/100 {
  border-left-color: rgb(91 33 182 / 1) !important;
}

.tw-border-l-violet-800\/15 {
  border-left-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-l-violet-800\/20 {
  border-left-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-l-violet-800\/25 {
  border-left-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-l-violet-800\/30 {
  border-left-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-l-violet-800\/35 {
  border-left-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-l-violet-800\/40 {
  border-left-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-l-violet-800\/45 {
  border-left-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-l-violet-800\/5 {
  border-left-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-l-violet-800\/50 {
  border-left-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-l-violet-800\/55 {
  border-left-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-l-violet-800\/60 {
  border-left-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-l-violet-800\/65 {
  border-left-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-l-violet-800\/70 {
  border-left-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-l-violet-800\/75 {
  border-left-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-l-violet-800\/80 {
  border-left-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-l-violet-800\/85 {
  border-left-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-l-violet-800\/90 {
  border-left-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-l-violet-800\/95 {
  border-left-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-l-violet-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-900\/0 {
  border-left-color: rgb(76 29 149 / 0) !important;
}

.tw-border-l-violet-900\/10 {
  border-left-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-l-violet-900\/100 {
  border-left-color: rgb(76 29 149 / 1) !important;
}

.tw-border-l-violet-900\/15 {
  border-left-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-l-violet-900\/20 {
  border-left-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-l-violet-900\/25 {
  border-left-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-l-violet-900\/30 {
  border-left-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-l-violet-900\/35 {
  border-left-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-l-violet-900\/40 {
  border-left-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-l-violet-900\/45 {
  border-left-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-l-violet-900\/5 {
  border-left-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-l-violet-900\/50 {
  border-left-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-l-violet-900\/55 {
  border-left-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-l-violet-900\/60 {
  border-left-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-l-violet-900\/65 {
  border-left-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-l-violet-900\/70 {
  border-left-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-l-violet-900\/75 {
  border-left-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-l-violet-900\/80 {
  border-left-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-l-violet-900\/85 {
  border-left-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-l-violet-900\/90 {
  border-left-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-l-violet-900\/95 {
  border-left-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-l-violet-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-l-violet-950\/0 {
  border-left-color: rgb(46 16 101 / 0) !important;
}

.tw-border-l-violet-950\/10 {
  border-left-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-l-violet-950\/100 {
  border-left-color: rgb(46 16 101 / 1) !important;
}

.tw-border-l-violet-950\/15 {
  border-left-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-l-violet-950\/20 {
  border-left-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-l-violet-950\/25 {
  border-left-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-l-violet-950\/30 {
  border-left-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-l-violet-950\/35 {
  border-left-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-l-violet-950\/40 {
  border-left-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-l-violet-950\/45 {
  border-left-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-l-violet-950\/5 {
  border-left-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-l-violet-950\/50 {
  border-left-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-l-violet-950\/55 {
  border-left-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-l-violet-950\/60 {
  border-left-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-l-violet-950\/65 {
  border-left-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-l-violet-950\/70 {
  border-left-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-l-violet-950\/75 {
  border-left-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-l-violet-950\/80 {
  border-left-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-l-violet-950\/85 {
  border-left-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-l-violet-950\/90 {
  border-left-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-l-violet-950\/95 {
  border-left-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-l-white {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-l-white\/0 {
  border-left-color: rgb(255 255 255 / 0) !important;
}

.tw-border-l-white\/10 {
  border-left-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-l-white\/100 {
  border-left-color: rgb(255 255 255 / 1) !important;
}

.tw-border-l-white\/15 {
  border-left-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-l-white\/20 {
  border-left-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-l-white\/25 {
  border-left-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-l-white\/30 {
  border-left-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-l-white\/35 {
  border-left-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-l-white\/40 {
  border-left-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-l-white\/45 {
  border-left-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-l-white\/5 {
  border-left-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-l-white\/50 {
  border-left-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-l-white\/55 {
  border-left-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-l-white\/60 {
  border-left-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-l-white\/65 {
  border-left-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-l-white\/70 {
  border-left-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-l-white\/75 {
  border-left-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-l-white\/80 {
  border-left-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-l-white\/85 {
  border-left-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-l-white\/90 {
  border-left-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-l-white\/95 {
  border-left-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-l-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-100\/0 {
  border-left-color: rgb(254 249 195 / 0) !important;
}

.tw-border-l-yellow-100\/10 {
  border-left-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-l-yellow-100\/100 {
  border-left-color: rgb(254 249 195 / 1) !important;
}

.tw-border-l-yellow-100\/15 {
  border-left-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-l-yellow-100\/20 {
  border-left-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-l-yellow-100\/25 {
  border-left-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-l-yellow-100\/30 {
  border-left-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-l-yellow-100\/35 {
  border-left-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-l-yellow-100\/40 {
  border-left-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-l-yellow-100\/45 {
  border-left-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-l-yellow-100\/5 {
  border-left-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-l-yellow-100\/50 {
  border-left-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-l-yellow-100\/55 {
  border-left-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-l-yellow-100\/60 {
  border-left-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-l-yellow-100\/65 {
  border-left-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-l-yellow-100\/70 {
  border-left-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-l-yellow-100\/75 {
  border-left-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-l-yellow-100\/80 {
  border-left-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-l-yellow-100\/85 {
  border-left-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-l-yellow-100\/90 {
  border-left-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-l-yellow-100\/95 {
  border-left-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-l-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-200\/0 {
  border-left-color: rgb(254 240 138 / 0) !important;
}

.tw-border-l-yellow-200\/10 {
  border-left-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-l-yellow-200\/100 {
  border-left-color: rgb(254 240 138 / 1) !important;
}

.tw-border-l-yellow-200\/15 {
  border-left-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-l-yellow-200\/20 {
  border-left-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-l-yellow-200\/25 {
  border-left-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-l-yellow-200\/30 {
  border-left-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-l-yellow-200\/35 {
  border-left-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-l-yellow-200\/40 {
  border-left-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-l-yellow-200\/45 {
  border-left-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-l-yellow-200\/5 {
  border-left-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-l-yellow-200\/50 {
  border-left-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-l-yellow-200\/55 {
  border-left-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-l-yellow-200\/60 {
  border-left-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-l-yellow-200\/65 {
  border-left-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-l-yellow-200\/70 {
  border-left-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-l-yellow-200\/75 {
  border-left-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-l-yellow-200\/80 {
  border-left-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-l-yellow-200\/85 {
  border-left-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-l-yellow-200\/90 {
  border-left-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-l-yellow-200\/95 {
  border-left-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-l-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-300\/0 {
  border-left-color: rgb(253 224 71 / 0) !important;
}

.tw-border-l-yellow-300\/10 {
  border-left-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-l-yellow-300\/100 {
  border-left-color: rgb(253 224 71 / 1) !important;
}

.tw-border-l-yellow-300\/15 {
  border-left-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-l-yellow-300\/20 {
  border-left-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-l-yellow-300\/25 {
  border-left-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-l-yellow-300\/30 {
  border-left-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-l-yellow-300\/35 {
  border-left-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-l-yellow-300\/40 {
  border-left-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-l-yellow-300\/45 {
  border-left-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-l-yellow-300\/5 {
  border-left-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-l-yellow-300\/50 {
  border-left-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-l-yellow-300\/55 {
  border-left-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-l-yellow-300\/60 {
  border-left-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-l-yellow-300\/65 {
  border-left-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-l-yellow-300\/70 {
  border-left-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-l-yellow-300\/75 {
  border-left-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-l-yellow-300\/80 {
  border-left-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-l-yellow-300\/85 {
  border-left-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-l-yellow-300\/90 {
  border-left-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-l-yellow-300\/95 {
  border-left-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-l-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-400\/0 {
  border-left-color: rgb(250 204 21 / 0) !important;
}

.tw-border-l-yellow-400\/10 {
  border-left-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-l-yellow-400\/100 {
  border-left-color: rgb(250 204 21 / 1) !important;
}

.tw-border-l-yellow-400\/15 {
  border-left-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-l-yellow-400\/20 {
  border-left-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-l-yellow-400\/25 {
  border-left-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-l-yellow-400\/30 {
  border-left-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-l-yellow-400\/35 {
  border-left-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-l-yellow-400\/40 {
  border-left-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-l-yellow-400\/45 {
  border-left-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-l-yellow-400\/5 {
  border-left-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-l-yellow-400\/50 {
  border-left-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-l-yellow-400\/55 {
  border-left-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-l-yellow-400\/60 {
  border-left-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-l-yellow-400\/65 {
  border-left-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-l-yellow-400\/70 {
  border-left-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-l-yellow-400\/75 {
  border-left-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-l-yellow-400\/80 {
  border-left-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-l-yellow-400\/85 {
  border-left-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-l-yellow-400\/90 {
  border-left-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-l-yellow-400\/95 {
  border-left-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-l-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-50\/0 {
  border-left-color: rgb(254 252 232 / 0) !important;
}

.tw-border-l-yellow-50\/10 {
  border-left-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-l-yellow-50\/100 {
  border-left-color: rgb(254 252 232 / 1) !important;
}

.tw-border-l-yellow-50\/15 {
  border-left-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-l-yellow-50\/20 {
  border-left-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-l-yellow-50\/25 {
  border-left-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-l-yellow-50\/30 {
  border-left-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-l-yellow-50\/35 {
  border-left-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-l-yellow-50\/40 {
  border-left-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-l-yellow-50\/45 {
  border-left-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-l-yellow-50\/5 {
  border-left-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-l-yellow-50\/50 {
  border-left-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-l-yellow-50\/55 {
  border-left-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-l-yellow-50\/60 {
  border-left-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-l-yellow-50\/65 {
  border-left-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-l-yellow-50\/70 {
  border-left-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-l-yellow-50\/75 {
  border-left-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-l-yellow-50\/80 {
  border-left-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-l-yellow-50\/85 {
  border-left-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-l-yellow-50\/90 {
  border-left-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-l-yellow-50\/95 {
  border-left-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-l-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-500\/0 {
  border-left-color: rgb(234 179 8 / 0) !important;
}

.tw-border-l-yellow-500\/10 {
  border-left-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-l-yellow-500\/100 {
  border-left-color: rgb(234 179 8 / 1) !important;
}

.tw-border-l-yellow-500\/15 {
  border-left-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-l-yellow-500\/20 {
  border-left-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-l-yellow-500\/25 {
  border-left-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-l-yellow-500\/30 {
  border-left-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-l-yellow-500\/35 {
  border-left-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-l-yellow-500\/40 {
  border-left-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-l-yellow-500\/45 {
  border-left-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-l-yellow-500\/5 {
  border-left-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-l-yellow-500\/50 {
  border-left-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-l-yellow-500\/55 {
  border-left-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-l-yellow-500\/60 {
  border-left-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-l-yellow-500\/65 {
  border-left-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-l-yellow-500\/70 {
  border-left-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-l-yellow-500\/75 {
  border-left-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-l-yellow-500\/80 {
  border-left-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-l-yellow-500\/85 {
  border-left-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-l-yellow-500\/90 {
  border-left-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-l-yellow-500\/95 {
  border-left-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-l-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-600\/0 {
  border-left-color: rgb(202 138 4 / 0) !important;
}

.tw-border-l-yellow-600\/10 {
  border-left-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-l-yellow-600\/100 {
  border-left-color: rgb(202 138 4 / 1) !important;
}

.tw-border-l-yellow-600\/15 {
  border-left-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-l-yellow-600\/20 {
  border-left-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-l-yellow-600\/25 {
  border-left-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-l-yellow-600\/30 {
  border-left-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-l-yellow-600\/35 {
  border-left-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-l-yellow-600\/40 {
  border-left-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-l-yellow-600\/45 {
  border-left-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-l-yellow-600\/5 {
  border-left-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-l-yellow-600\/50 {
  border-left-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-l-yellow-600\/55 {
  border-left-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-l-yellow-600\/60 {
  border-left-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-l-yellow-600\/65 {
  border-left-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-l-yellow-600\/70 {
  border-left-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-l-yellow-600\/75 {
  border-left-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-l-yellow-600\/80 {
  border-left-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-l-yellow-600\/85 {
  border-left-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-l-yellow-600\/90 {
  border-left-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-l-yellow-600\/95 {
  border-left-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-l-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-700\/0 {
  border-left-color: rgb(161 98 7 / 0) !important;
}

.tw-border-l-yellow-700\/10 {
  border-left-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-l-yellow-700\/100 {
  border-left-color: rgb(161 98 7 / 1) !important;
}

.tw-border-l-yellow-700\/15 {
  border-left-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-l-yellow-700\/20 {
  border-left-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-l-yellow-700\/25 {
  border-left-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-l-yellow-700\/30 {
  border-left-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-l-yellow-700\/35 {
  border-left-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-l-yellow-700\/40 {
  border-left-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-l-yellow-700\/45 {
  border-left-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-l-yellow-700\/5 {
  border-left-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-l-yellow-700\/50 {
  border-left-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-l-yellow-700\/55 {
  border-left-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-l-yellow-700\/60 {
  border-left-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-l-yellow-700\/65 {
  border-left-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-l-yellow-700\/70 {
  border-left-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-l-yellow-700\/75 {
  border-left-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-l-yellow-700\/80 {
  border-left-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-l-yellow-700\/85 {
  border-left-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-l-yellow-700\/90 {
  border-left-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-l-yellow-700\/95 {
  border-left-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-l-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-800\/0 {
  border-left-color: rgb(133 77 14 / 0) !important;
}

.tw-border-l-yellow-800\/10 {
  border-left-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-l-yellow-800\/100 {
  border-left-color: rgb(133 77 14 / 1) !important;
}

.tw-border-l-yellow-800\/15 {
  border-left-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-l-yellow-800\/20 {
  border-left-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-l-yellow-800\/25 {
  border-left-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-l-yellow-800\/30 {
  border-left-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-l-yellow-800\/35 {
  border-left-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-l-yellow-800\/40 {
  border-left-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-l-yellow-800\/45 {
  border-left-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-l-yellow-800\/5 {
  border-left-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-l-yellow-800\/50 {
  border-left-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-l-yellow-800\/55 {
  border-left-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-l-yellow-800\/60 {
  border-left-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-l-yellow-800\/65 {
  border-left-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-l-yellow-800\/70 {
  border-left-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-l-yellow-800\/75 {
  border-left-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-l-yellow-800\/80 {
  border-left-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-l-yellow-800\/85 {
  border-left-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-l-yellow-800\/90 {
  border-left-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-l-yellow-800\/95 {
  border-left-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-l-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-900\/0 {
  border-left-color: rgb(113 63 18 / 0) !important;
}

.tw-border-l-yellow-900\/10 {
  border-left-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-l-yellow-900\/100 {
  border-left-color: rgb(113 63 18 / 1) !important;
}

.tw-border-l-yellow-900\/15 {
  border-left-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-l-yellow-900\/20 {
  border-left-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-l-yellow-900\/25 {
  border-left-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-l-yellow-900\/30 {
  border-left-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-l-yellow-900\/35 {
  border-left-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-l-yellow-900\/40 {
  border-left-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-l-yellow-900\/45 {
  border-left-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-l-yellow-900\/5 {
  border-left-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-l-yellow-900\/50 {
  border-left-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-l-yellow-900\/55 {
  border-left-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-l-yellow-900\/60 {
  border-left-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-l-yellow-900\/65 {
  border-left-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-l-yellow-900\/70 {
  border-left-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-l-yellow-900\/75 {
  border-left-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-l-yellow-900\/80 {
  border-left-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-l-yellow-900\/85 {
  border-left-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-l-yellow-900\/90 {
  border-left-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-l-yellow-900\/95 {
  border-left-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-l-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-l-yellow-950\/0 {
  border-left-color: rgb(66 32 6 / 0) !important;
}

.tw-border-l-yellow-950\/10 {
  border-left-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-l-yellow-950\/100 {
  border-left-color: rgb(66 32 6 / 1) !important;
}

.tw-border-l-yellow-950\/15 {
  border-left-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-l-yellow-950\/20 {
  border-left-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-l-yellow-950\/25 {
  border-left-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-l-yellow-950\/30 {
  border-left-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-l-yellow-950\/35 {
  border-left-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-l-yellow-950\/40 {
  border-left-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-l-yellow-950\/45 {
  border-left-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-l-yellow-950\/5 {
  border-left-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-l-yellow-950\/50 {
  border-left-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-l-yellow-950\/55 {
  border-left-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-l-yellow-950\/60 {
  border-left-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-l-yellow-950\/65 {
  border-left-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-l-yellow-950\/70 {
  border-left-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-l-yellow-950\/75 {
  border-left-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-l-yellow-950\/80 {
  border-left-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-l-yellow-950\/85 {
  border-left-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-l-yellow-950\/90 {
  border-left-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-l-yellow-950\/95 {
  border-left-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-l-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-100\/0 {
  border-left-color: rgb(244 244 245 / 0) !important;
}

.tw-border-l-zinc-100\/10 {
  border-left-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-l-zinc-100\/100 {
  border-left-color: rgb(244 244 245 / 1) !important;
}

.tw-border-l-zinc-100\/15 {
  border-left-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-l-zinc-100\/20 {
  border-left-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-l-zinc-100\/25 {
  border-left-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-l-zinc-100\/30 {
  border-left-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-l-zinc-100\/35 {
  border-left-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-l-zinc-100\/40 {
  border-left-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-l-zinc-100\/45 {
  border-left-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-l-zinc-100\/5 {
  border-left-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-l-zinc-100\/50 {
  border-left-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-l-zinc-100\/55 {
  border-left-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-l-zinc-100\/60 {
  border-left-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-l-zinc-100\/65 {
  border-left-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-l-zinc-100\/70 {
  border-left-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-l-zinc-100\/75 {
  border-left-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-l-zinc-100\/80 {
  border-left-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-l-zinc-100\/85 {
  border-left-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-l-zinc-100\/90 {
  border-left-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-l-zinc-100\/95 {
  border-left-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-l-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-200\/0 {
  border-left-color: rgb(228 228 231 / 0) !important;
}

.tw-border-l-zinc-200\/10 {
  border-left-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-l-zinc-200\/100 {
  border-left-color: rgb(228 228 231 / 1) !important;
}

.tw-border-l-zinc-200\/15 {
  border-left-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-l-zinc-200\/20 {
  border-left-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-l-zinc-200\/25 {
  border-left-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-l-zinc-200\/30 {
  border-left-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-l-zinc-200\/35 {
  border-left-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-l-zinc-200\/40 {
  border-left-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-l-zinc-200\/45 {
  border-left-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-l-zinc-200\/5 {
  border-left-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-l-zinc-200\/50 {
  border-left-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-l-zinc-200\/55 {
  border-left-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-l-zinc-200\/60 {
  border-left-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-l-zinc-200\/65 {
  border-left-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-l-zinc-200\/70 {
  border-left-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-l-zinc-200\/75 {
  border-left-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-l-zinc-200\/80 {
  border-left-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-l-zinc-200\/85 {
  border-left-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-l-zinc-200\/90 {
  border-left-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-l-zinc-200\/95 {
  border-left-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-l-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-300\/0 {
  border-left-color: rgb(212 212 216 / 0) !important;
}

.tw-border-l-zinc-300\/10 {
  border-left-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-l-zinc-300\/100 {
  border-left-color: rgb(212 212 216 / 1) !important;
}

.tw-border-l-zinc-300\/15 {
  border-left-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-l-zinc-300\/20 {
  border-left-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-l-zinc-300\/25 {
  border-left-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-l-zinc-300\/30 {
  border-left-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-l-zinc-300\/35 {
  border-left-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-l-zinc-300\/40 {
  border-left-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-l-zinc-300\/45 {
  border-left-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-l-zinc-300\/5 {
  border-left-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-l-zinc-300\/50 {
  border-left-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-l-zinc-300\/55 {
  border-left-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-l-zinc-300\/60 {
  border-left-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-l-zinc-300\/65 {
  border-left-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-l-zinc-300\/70 {
  border-left-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-l-zinc-300\/75 {
  border-left-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-l-zinc-300\/80 {
  border-left-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-l-zinc-300\/85 {
  border-left-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-l-zinc-300\/90 {
  border-left-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-l-zinc-300\/95 {
  border-left-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-l-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-400\/0 {
  border-left-color: rgb(161 161 170 / 0) !important;
}

.tw-border-l-zinc-400\/10 {
  border-left-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-l-zinc-400\/100 {
  border-left-color: rgb(161 161 170 / 1) !important;
}

.tw-border-l-zinc-400\/15 {
  border-left-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-l-zinc-400\/20 {
  border-left-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-l-zinc-400\/25 {
  border-left-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-l-zinc-400\/30 {
  border-left-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-l-zinc-400\/35 {
  border-left-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-l-zinc-400\/40 {
  border-left-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-l-zinc-400\/45 {
  border-left-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-l-zinc-400\/5 {
  border-left-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-l-zinc-400\/50 {
  border-left-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-l-zinc-400\/55 {
  border-left-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-l-zinc-400\/60 {
  border-left-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-l-zinc-400\/65 {
  border-left-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-l-zinc-400\/70 {
  border-left-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-l-zinc-400\/75 {
  border-left-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-l-zinc-400\/80 {
  border-left-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-l-zinc-400\/85 {
  border-left-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-l-zinc-400\/90 {
  border-left-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-l-zinc-400\/95 {
  border-left-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-l-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-50\/0 {
  border-left-color: rgb(250 250 250 / 0) !important;
}

.tw-border-l-zinc-50\/10 {
  border-left-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-l-zinc-50\/100 {
  border-left-color: rgb(250 250 250 / 1) !important;
}

.tw-border-l-zinc-50\/15 {
  border-left-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-l-zinc-50\/20 {
  border-left-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-l-zinc-50\/25 {
  border-left-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-l-zinc-50\/30 {
  border-left-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-l-zinc-50\/35 {
  border-left-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-l-zinc-50\/40 {
  border-left-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-l-zinc-50\/45 {
  border-left-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-l-zinc-50\/5 {
  border-left-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-l-zinc-50\/50 {
  border-left-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-l-zinc-50\/55 {
  border-left-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-l-zinc-50\/60 {
  border-left-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-l-zinc-50\/65 {
  border-left-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-l-zinc-50\/70 {
  border-left-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-l-zinc-50\/75 {
  border-left-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-l-zinc-50\/80 {
  border-left-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-l-zinc-50\/85 {
  border-left-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-l-zinc-50\/90 {
  border-left-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-l-zinc-50\/95 {
  border-left-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-l-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-500\/0 {
  border-left-color: rgb(113 113 122 / 0) !important;
}

.tw-border-l-zinc-500\/10 {
  border-left-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-l-zinc-500\/100 {
  border-left-color: rgb(113 113 122 / 1) !important;
}

.tw-border-l-zinc-500\/15 {
  border-left-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-l-zinc-500\/20 {
  border-left-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-l-zinc-500\/25 {
  border-left-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-l-zinc-500\/30 {
  border-left-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-l-zinc-500\/35 {
  border-left-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-l-zinc-500\/40 {
  border-left-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-l-zinc-500\/45 {
  border-left-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-l-zinc-500\/5 {
  border-left-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-l-zinc-500\/50 {
  border-left-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-l-zinc-500\/55 {
  border-left-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-l-zinc-500\/60 {
  border-left-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-l-zinc-500\/65 {
  border-left-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-l-zinc-500\/70 {
  border-left-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-l-zinc-500\/75 {
  border-left-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-l-zinc-500\/80 {
  border-left-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-l-zinc-500\/85 {
  border-left-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-l-zinc-500\/90 {
  border-left-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-l-zinc-500\/95 {
  border-left-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-l-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-600\/0 {
  border-left-color: rgb(82 82 91 / 0) !important;
}

.tw-border-l-zinc-600\/10 {
  border-left-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-l-zinc-600\/100 {
  border-left-color: rgb(82 82 91 / 1) !important;
}

.tw-border-l-zinc-600\/15 {
  border-left-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-l-zinc-600\/20 {
  border-left-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-l-zinc-600\/25 {
  border-left-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-l-zinc-600\/30 {
  border-left-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-l-zinc-600\/35 {
  border-left-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-l-zinc-600\/40 {
  border-left-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-l-zinc-600\/45 {
  border-left-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-l-zinc-600\/5 {
  border-left-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-l-zinc-600\/50 {
  border-left-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-l-zinc-600\/55 {
  border-left-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-l-zinc-600\/60 {
  border-left-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-l-zinc-600\/65 {
  border-left-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-l-zinc-600\/70 {
  border-left-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-l-zinc-600\/75 {
  border-left-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-l-zinc-600\/80 {
  border-left-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-l-zinc-600\/85 {
  border-left-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-l-zinc-600\/90 {
  border-left-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-l-zinc-600\/95 {
  border-left-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-l-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-700\/0 {
  border-left-color: rgb(63 63 70 / 0) !important;
}

.tw-border-l-zinc-700\/10 {
  border-left-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-l-zinc-700\/100 {
  border-left-color: rgb(63 63 70 / 1) !important;
}

.tw-border-l-zinc-700\/15 {
  border-left-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-l-zinc-700\/20 {
  border-left-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-l-zinc-700\/25 {
  border-left-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-l-zinc-700\/30 {
  border-left-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-l-zinc-700\/35 {
  border-left-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-l-zinc-700\/40 {
  border-left-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-l-zinc-700\/45 {
  border-left-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-l-zinc-700\/5 {
  border-left-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-l-zinc-700\/50 {
  border-left-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-l-zinc-700\/55 {
  border-left-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-l-zinc-700\/60 {
  border-left-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-l-zinc-700\/65 {
  border-left-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-l-zinc-700\/70 {
  border-left-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-l-zinc-700\/75 {
  border-left-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-l-zinc-700\/80 {
  border-left-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-l-zinc-700\/85 {
  border-left-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-l-zinc-700\/90 {
  border-left-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-l-zinc-700\/95 {
  border-left-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-l-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-800\/0 {
  border-left-color: rgb(39 39 42 / 0) !important;
}

.tw-border-l-zinc-800\/10 {
  border-left-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-l-zinc-800\/100 {
  border-left-color: rgb(39 39 42 / 1) !important;
}

.tw-border-l-zinc-800\/15 {
  border-left-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-l-zinc-800\/20 {
  border-left-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-l-zinc-800\/25 {
  border-left-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-l-zinc-800\/30 {
  border-left-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-l-zinc-800\/35 {
  border-left-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-l-zinc-800\/40 {
  border-left-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-l-zinc-800\/45 {
  border-left-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-l-zinc-800\/5 {
  border-left-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-l-zinc-800\/50 {
  border-left-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-l-zinc-800\/55 {
  border-left-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-l-zinc-800\/60 {
  border-left-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-l-zinc-800\/65 {
  border-left-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-l-zinc-800\/70 {
  border-left-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-l-zinc-800\/75 {
  border-left-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-l-zinc-800\/80 {
  border-left-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-l-zinc-800\/85 {
  border-left-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-l-zinc-800\/90 {
  border-left-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-l-zinc-800\/95 {
  border-left-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-l-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-900\/0 {
  border-left-color: rgb(24 24 27 / 0) !important;
}

.tw-border-l-zinc-900\/10 {
  border-left-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-l-zinc-900\/100 {
  border-left-color: rgb(24 24 27 / 1) !important;
}

.tw-border-l-zinc-900\/15 {
  border-left-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-l-zinc-900\/20 {
  border-left-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-l-zinc-900\/25 {
  border-left-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-l-zinc-900\/30 {
  border-left-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-l-zinc-900\/35 {
  border-left-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-l-zinc-900\/40 {
  border-left-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-l-zinc-900\/45 {
  border-left-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-l-zinc-900\/5 {
  border-left-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-l-zinc-900\/50 {
  border-left-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-l-zinc-900\/55 {
  border-left-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-l-zinc-900\/60 {
  border-left-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-l-zinc-900\/65 {
  border-left-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-l-zinc-900\/70 {
  border-left-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-l-zinc-900\/75 {
  border-left-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-l-zinc-900\/80 {
  border-left-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-l-zinc-900\/85 {
  border-left-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-l-zinc-900\/90 {
  border-left-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-l-zinc-900\/95 {
  border-left-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-l-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-left-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-l-zinc-950\/0 {
  border-left-color: rgb(9 9 11 / 0) !important;
}

.tw-border-l-zinc-950\/10 {
  border-left-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-l-zinc-950\/100 {
  border-left-color: rgb(9 9 11 / 1) !important;
}

.tw-border-l-zinc-950\/15 {
  border-left-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-l-zinc-950\/20 {
  border-left-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-l-zinc-950\/25 {
  border-left-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-l-zinc-950\/30 {
  border-left-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-l-zinc-950\/35 {
  border-left-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-l-zinc-950\/40 {
  border-left-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-l-zinc-950\/45 {
  border-left-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-l-zinc-950\/5 {
  border-left-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-l-zinc-950\/50 {
  border-left-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-l-zinc-950\/55 {
  border-left-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-l-zinc-950\/60 {
  border-left-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-l-zinc-950\/65 {
  border-left-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-l-zinc-950\/70 {
  border-left-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-l-zinc-950\/75 {
  border-left-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-l-zinc-950\/80 {
  border-left-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-l-zinc-950\/85 {
  border-left-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-l-zinc-950\/90 {
  border-left-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-l-zinc-950\/95 {
  border-left-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-r-amber-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-100\/0 {
  border-right-color: rgb(254 243 199 / 0) !important;
}

.tw-border-r-amber-100\/10 {
  border-right-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-r-amber-100\/100 {
  border-right-color: rgb(254 243 199 / 1) !important;
}

.tw-border-r-amber-100\/15 {
  border-right-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-r-amber-100\/20 {
  border-right-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-r-amber-100\/25 {
  border-right-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-r-amber-100\/30 {
  border-right-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-r-amber-100\/35 {
  border-right-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-r-amber-100\/40 {
  border-right-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-r-amber-100\/45 {
  border-right-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-r-amber-100\/5 {
  border-right-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-r-amber-100\/50 {
  border-right-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-r-amber-100\/55 {
  border-right-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-r-amber-100\/60 {
  border-right-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-r-amber-100\/65 {
  border-right-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-r-amber-100\/70 {
  border-right-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-r-amber-100\/75 {
  border-right-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-r-amber-100\/80 {
  border-right-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-r-amber-100\/85 {
  border-right-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-r-amber-100\/90 {
  border-right-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-r-amber-100\/95 {
  border-right-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-r-amber-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-200\/0 {
  border-right-color: rgb(253 230 138 / 0) !important;
}

.tw-border-r-amber-200\/10 {
  border-right-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-r-amber-200\/100 {
  border-right-color: rgb(253 230 138 / 1) !important;
}

.tw-border-r-amber-200\/15 {
  border-right-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-r-amber-200\/20 {
  border-right-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-r-amber-200\/25 {
  border-right-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-r-amber-200\/30 {
  border-right-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-r-amber-200\/35 {
  border-right-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-r-amber-200\/40 {
  border-right-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-r-amber-200\/45 {
  border-right-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-r-amber-200\/5 {
  border-right-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-r-amber-200\/50 {
  border-right-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-r-amber-200\/55 {
  border-right-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-r-amber-200\/60 {
  border-right-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-r-amber-200\/65 {
  border-right-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-r-amber-200\/70 {
  border-right-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-r-amber-200\/75 {
  border-right-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-r-amber-200\/80 {
  border-right-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-r-amber-200\/85 {
  border-right-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-r-amber-200\/90 {
  border-right-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-r-amber-200\/95 {
  border-right-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-r-amber-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-300\/0 {
  border-right-color: rgb(252 211 77 / 0) !important;
}

.tw-border-r-amber-300\/10 {
  border-right-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-r-amber-300\/100 {
  border-right-color: rgb(252 211 77 / 1) !important;
}

.tw-border-r-amber-300\/15 {
  border-right-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-r-amber-300\/20 {
  border-right-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-r-amber-300\/25 {
  border-right-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-r-amber-300\/30 {
  border-right-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-r-amber-300\/35 {
  border-right-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-r-amber-300\/40 {
  border-right-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-r-amber-300\/45 {
  border-right-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-r-amber-300\/5 {
  border-right-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-r-amber-300\/50 {
  border-right-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-r-amber-300\/55 {
  border-right-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-r-amber-300\/60 {
  border-right-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-r-amber-300\/65 {
  border-right-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-r-amber-300\/70 {
  border-right-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-r-amber-300\/75 {
  border-right-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-r-amber-300\/80 {
  border-right-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-r-amber-300\/85 {
  border-right-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-r-amber-300\/90 {
  border-right-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-r-amber-300\/95 {
  border-right-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-r-amber-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-400\/0 {
  border-right-color: rgb(251 191 36 / 0) !important;
}

.tw-border-r-amber-400\/10 {
  border-right-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-r-amber-400\/100 {
  border-right-color: rgb(251 191 36 / 1) !important;
}

.tw-border-r-amber-400\/15 {
  border-right-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-r-amber-400\/20 {
  border-right-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-r-amber-400\/25 {
  border-right-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-r-amber-400\/30 {
  border-right-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-r-amber-400\/35 {
  border-right-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-r-amber-400\/40 {
  border-right-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-r-amber-400\/45 {
  border-right-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-r-amber-400\/5 {
  border-right-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-r-amber-400\/50 {
  border-right-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-r-amber-400\/55 {
  border-right-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-r-amber-400\/60 {
  border-right-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-r-amber-400\/65 {
  border-right-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-r-amber-400\/70 {
  border-right-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-r-amber-400\/75 {
  border-right-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-r-amber-400\/80 {
  border-right-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-r-amber-400\/85 {
  border-right-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-r-amber-400\/90 {
  border-right-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-r-amber-400\/95 {
  border-right-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-r-amber-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-50\/0 {
  border-right-color: rgb(255 251 235 / 0) !important;
}

.tw-border-r-amber-50\/10 {
  border-right-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-r-amber-50\/100 {
  border-right-color: rgb(255 251 235 / 1) !important;
}

.tw-border-r-amber-50\/15 {
  border-right-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-r-amber-50\/20 {
  border-right-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-r-amber-50\/25 {
  border-right-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-r-amber-50\/30 {
  border-right-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-r-amber-50\/35 {
  border-right-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-r-amber-50\/40 {
  border-right-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-r-amber-50\/45 {
  border-right-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-r-amber-50\/5 {
  border-right-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-r-amber-50\/50 {
  border-right-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-r-amber-50\/55 {
  border-right-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-r-amber-50\/60 {
  border-right-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-r-amber-50\/65 {
  border-right-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-r-amber-50\/70 {
  border-right-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-r-amber-50\/75 {
  border-right-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-r-amber-50\/80 {
  border-right-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-r-amber-50\/85 {
  border-right-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-r-amber-50\/90 {
  border-right-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-r-amber-50\/95 {
  border-right-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-r-amber-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-500\/0 {
  border-right-color: rgb(245 158 11 / 0) !important;
}

.tw-border-r-amber-500\/10 {
  border-right-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-r-amber-500\/100 {
  border-right-color: rgb(245 158 11 / 1) !important;
}

.tw-border-r-amber-500\/15 {
  border-right-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-r-amber-500\/20 {
  border-right-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-r-amber-500\/25 {
  border-right-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-r-amber-500\/30 {
  border-right-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-r-amber-500\/35 {
  border-right-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-r-amber-500\/40 {
  border-right-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-r-amber-500\/45 {
  border-right-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-r-amber-500\/5 {
  border-right-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-r-amber-500\/50 {
  border-right-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-r-amber-500\/55 {
  border-right-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-r-amber-500\/60 {
  border-right-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-r-amber-500\/65 {
  border-right-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-r-amber-500\/70 {
  border-right-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-r-amber-500\/75 {
  border-right-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-r-amber-500\/80 {
  border-right-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-r-amber-500\/85 {
  border-right-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-r-amber-500\/90 {
  border-right-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-r-amber-500\/95 {
  border-right-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-r-amber-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-600\/0 {
  border-right-color: rgb(217 119 6 / 0) !important;
}

.tw-border-r-amber-600\/10 {
  border-right-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-r-amber-600\/100 {
  border-right-color: rgb(217 119 6 / 1) !important;
}

.tw-border-r-amber-600\/15 {
  border-right-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-r-amber-600\/20 {
  border-right-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-r-amber-600\/25 {
  border-right-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-r-amber-600\/30 {
  border-right-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-r-amber-600\/35 {
  border-right-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-r-amber-600\/40 {
  border-right-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-r-amber-600\/45 {
  border-right-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-r-amber-600\/5 {
  border-right-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-r-amber-600\/50 {
  border-right-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-r-amber-600\/55 {
  border-right-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-r-amber-600\/60 {
  border-right-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-r-amber-600\/65 {
  border-right-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-r-amber-600\/70 {
  border-right-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-r-amber-600\/75 {
  border-right-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-r-amber-600\/80 {
  border-right-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-r-amber-600\/85 {
  border-right-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-r-amber-600\/90 {
  border-right-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-r-amber-600\/95 {
  border-right-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-r-amber-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-700\/0 {
  border-right-color: rgb(180 83 9 / 0) !important;
}

.tw-border-r-amber-700\/10 {
  border-right-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-r-amber-700\/100 {
  border-right-color: rgb(180 83 9 / 1) !important;
}

.tw-border-r-amber-700\/15 {
  border-right-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-r-amber-700\/20 {
  border-right-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-r-amber-700\/25 {
  border-right-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-r-amber-700\/30 {
  border-right-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-r-amber-700\/35 {
  border-right-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-r-amber-700\/40 {
  border-right-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-r-amber-700\/45 {
  border-right-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-r-amber-700\/5 {
  border-right-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-r-amber-700\/50 {
  border-right-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-r-amber-700\/55 {
  border-right-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-r-amber-700\/60 {
  border-right-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-r-amber-700\/65 {
  border-right-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-r-amber-700\/70 {
  border-right-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-r-amber-700\/75 {
  border-right-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-r-amber-700\/80 {
  border-right-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-r-amber-700\/85 {
  border-right-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-r-amber-700\/90 {
  border-right-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-r-amber-700\/95 {
  border-right-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-r-amber-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-800\/0 {
  border-right-color: rgb(146 64 14 / 0) !important;
}

.tw-border-r-amber-800\/10 {
  border-right-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-r-amber-800\/100 {
  border-right-color: rgb(146 64 14 / 1) !important;
}

.tw-border-r-amber-800\/15 {
  border-right-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-r-amber-800\/20 {
  border-right-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-r-amber-800\/25 {
  border-right-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-r-amber-800\/30 {
  border-right-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-r-amber-800\/35 {
  border-right-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-r-amber-800\/40 {
  border-right-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-r-amber-800\/45 {
  border-right-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-r-amber-800\/5 {
  border-right-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-r-amber-800\/50 {
  border-right-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-r-amber-800\/55 {
  border-right-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-r-amber-800\/60 {
  border-right-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-r-amber-800\/65 {
  border-right-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-r-amber-800\/70 {
  border-right-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-r-amber-800\/75 {
  border-right-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-r-amber-800\/80 {
  border-right-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-r-amber-800\/85 {
  border-right-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-r-amber-800\/90 {
  border-right-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-r-amber-800\/95 {
  border-right-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-r-amber-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-900\/0 {
  border-right-color: rgb(120 53 15 / 0) !important;
}

.tw-border-r-amber-900\/10 {
  border-right-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-r-amber-900\/100 {
  border-right-color: rgb(120 53 15 / 1) !important;
}

.tw-border-r-amber-900\/15 {
  border-right-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-r-amber-900\/20 {
  border-right-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-r-amber-900\/25 {
  border-right-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-r-amber-900\/30 {
  border-right-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-r-amber-900\/35 {
  border-right-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-r-amber-900\/40 {
  border-right-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-r-amber-900\/45 {
  border-right-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-r-amber-900\/5 {
  border-right-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-r-amber-900\/50 {
  border-right-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-r-amber-900\/55 {
  border-right-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-r-amber-900\/60 {
  border-right-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-r-amber-900\/65 {
  border-right-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-r-amber-900\/70 {
  border-right-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-r-amber-900\/75 {
  border-right-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-r-amber-900\/80 {
  border-right-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-r-amber-900\/85 {
  border-right-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-r-amber-900\/90 {
  border-right-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-r-amber-900\/95 {
  border-right-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-r-amber-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-r-amber-950\/0 {
  border-right-color: rgb(69 26 3 / 0) !important;
}

.tw-border-r-amber-950\/10 {
  border-right-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-r-amber-950\/100 {
  border-right-color: rgb(69 26 3 / 1) !important;
}

.tw-border-r-amber-950\/15 {
  border-right-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-r-amber-950\/20 {
  border-right-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-r-amber-950\/25 {
  border-right-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-r-amber-950\/30 {
  border-right-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-r-amber-950\/35 {
  border-right-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-r-amber-950\/40 {
  border-right-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-r-amber-950\/45 {
  border-right-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-r-amber-950\/5 {
  border-right-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-r-amber-950\/50 {
  border-right-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-r-amber-950\/55 {
  border-right-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-r-amber-950\/60 {
  border-right-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-r-amber-950\/65 {
  border-right-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-r-amber-950\/70 {
  border-right-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-r-amber-950\/75 {
  border-right-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-r-amber-950\/80 {
  border-right-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-r-amber-950\/85 {
  border-right-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-r-amber-950\/90 {
  border-right-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-r-amber-950\/95 {
  border-right-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-r-black {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-r-black\/0 {
  border-right-color: rgb(0 0 0 / 0) !important;
}

.tw-border-r-black\/10 {
  border-right-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-r-black\/100 {
  border-right-color: rgb(0 0 0 / 1) !important;
}

.tw-border-r-black\/15 {
  border-right-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-r-black\/20 {
  border-right-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-r-black\/25 {
  border-right-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-r-black\/30 {
  border-right-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-r-black\/35 {
  border-right-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-r-black\/40 {
  border-right-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-r-black\/45 {
  border-right-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-r-black\/5 {
  border-right-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-r-black\/50 {
  border-right-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-r-black\/55 {
  border-right-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-r-black\/60 {
  border-right-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-r-black\/65 {
  border-right-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-r-black\/70 {
  border-right-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-r-black\/75 {
  border-right-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-r-black\/80 {
  border-right-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-r-black\/85 {
  border-right-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-r-black\/90 {
  border-right-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-r-black\/95 {
  border-right-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-r-blue-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-100\/0 {
  border-right-color: rgb(219 234 254 / 0) !important;
}

.tw-border-r-blue-100\/10 {
  border-right-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-r-blue-100\/100 {
  border-right-color: rgb(219 234 254 / 1) !important;
}

.tw-border-r-blue-100\/15 {
  border-right-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-r-blue-100\/20 {
  border-right-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-r-blue-100\/25 {
  border-right-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-r-blue-100\/30 {
  border-right-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-r-blue-100\/35 {
  border-right-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-r-blue-100\/40 {
  border-right-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-r-blue-100\/45 {
  border-right-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-r-blue-100\/5 {
  border-right-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-r-blue-100\/50 {
  border-right-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-r-blue-100\/55 {
  border-right-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-r-blue-100\/60 {
  border-right-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-r-blue-100\/65 {
  border-right-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-r-blue-100\/70 {
  border-right-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-r-blue-100\/75 {
  border-right-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-r-blue-100\/80 {
  border-right-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-r-blue-100\/85 {
  border-right-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-r-blue-100\/90 {
  border-right-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-r-blue-100\/95 {
  border-right-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-r-blue-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-200\/0 {
  border-right-color: rgb(191 219 254 / 0) !important;
}

.tw-border-r-blue-200\/10 {
  border-right-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-r-blue-200\/100 {
  border-right-color: rgb(191 219 254 / 1) !important;
}

.tw-border-r-blue-200\/15 {
  border-right-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-r-blue-200\/20 {
  border-right-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-r-blue-200\/25 {
  border-right-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-r-blue-200\/30 {
  border-right-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-r-blue-200\/35 {
  border-right-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-r-blue-200\/40 {
  border-right-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-r-blue-200\/45 {
  border-right-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-r-blue-200\/5 {
  border-right-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-r-blue-200\/50 {
  border-right-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-r-blue-200\/55 {
  border-right-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-r-blue-200\/60 {
  border-right-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-r-blue-200\/65 {
  border-right-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-r-blue-200\/70 {
  border-right-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-r-blue-200\/75 {
  border-right-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-r-blue-200\/80 {
  border-right-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-r-blue-200\/85 {
  border-right-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-r-blue-200\/90 {
  border-right-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-r-blue-200\/95 {
  border-right-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-r-blue-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-300\/0 {
  border-right-color: rgb(147 197 253 / 0) !important;
}

.tw-border-r-blue-300\/10 {
  border-right-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-r-blue-300\/100 {
  border-right-color: rgb(147 197 253 / 1) !important;
}

.tw-border-r-blue-300\/15 {
  border-right-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-r-blue-300\/20 {
  border-right-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-r-blue-300\/25 {
  border-right-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-r-blue-300\/30 {
  border-right-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-r-blue-300\/35 {
  border-right-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-r-blue-300\/40 {
  border-right-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-r-blue-300\/45 {
  border-right-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-r-blue-300\/5 {
  border-right-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-r-blue-300\/50 {
  border-right-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-r-blue-300\/55 {
  border-right-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-r-blue-300\/60 {
  border-right-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-r-blue-300\/65 {
  border-right-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-r-blue-300\/70 {
  border-right-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-r-blue-300\/75 {
  border-right-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-r-blue-300\/80 {
  border-right-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-r-blue-300\/85 {
  border-right-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-r-blue-300\/90 {
  border-right-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-r-blue-300\/95 {
  border-right-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-r-blue-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-400\/0 {
  border-right-color: rgb(96 165 250 / 0) !important;
}

.tw-border-r-blue-400\/10 {
  border-right-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-r-blue-400\/100 {
  border-right-color: rgb(96 165 250 / 1) !important;
}

.tw-border-r-blue-400\/15 {
  border-right-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-r-blue-400\/20 {
  border-right-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-r-blue-400\/25 {
  border-right-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-r-blue-400\/30 {
  border-right-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-r-blue-400\/35 {
  border-right-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-r-blue-400\/40 {
  border-right-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-r-blue-400\/45 {
  border-right-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-r-blue-400\/5 {
  border-right-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-r-blue-400\/50 {
  border-right-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-r-blue-400\/55 {
  border-right-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-r-blue-400\/60 {
  border-right-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-r-blue-400\/65 {
  border-right-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-r-blue-400\/70 {
  border-right-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-r-blue-400\/75 {
  border-right-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-r-blue-400\/80 {
  border-right-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-r-blue-400\/85 {
  border-right-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-r-blue-400\/90 {
  border-right-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-r-blue-400\/95 {
  border-right-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-r-blue-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-50\/0 {
  border-right-color: rgb(239 246 255 / 0) !important;
}

.tw-border-r-blue-50\/10 {
  border-right-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-r-blue-50\/100 {
  border-right-color: rgb(239 246 255 / 1) !important;
}

.tw-border-r-blue-50\/15 {
  border-right-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-r-blue-50\/20 {
  border-right-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-r-blue-50\/25 {
  border-right-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-r-blue-50\/30 {
  border-right-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-r-blue-50\/35 {
  border-right-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-r-blue-50\/40 {
  border-right-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-r-blue-50\/45 {
  border-right-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-r-blue-50\/5 {
  border-right-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-r-blue-50\/50 {
  border-right-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-r-blue-50\/55 {
  border-right-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-r-blue-50\/60 {
  border-right-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-r-blue-50\/65 {
  border-right-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-r-blue-50\/70 {
  border-right-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-r-blue-50\/75 {
  border-right-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-r-blue-50\/80 {
  border-right-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-r-blue-50\/85 {
  border-right-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-r-blue-50\/90 {
  border-right-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-r-blue-50\/95 {
  border-right-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-r-blue-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-500\/0 {
  border-right-color: rgb(59 130 246 / 0) !important;
}

.tw-border-r-blue-500\/10 {
  border-right-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-r-blue-500\/100 {
  border-right-color: rgb(59 130 246 / 1) !important;
}

.tw-border-r-blue-500\/15 {
  border-right-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-r-blue-500\/20 {
  border-right-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-r-blue-500\/25 {
  border-right-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-r-blue-500\/30 {
  border-right-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-r-blue-500\/35 {
  border-right-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-r-blue-500\/40 {
  border-right-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-r-blue-500\/45 {
  border-right-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-r-blue-500\/5 {
  border-right-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-r-blue-500\/50 {
  border-right-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-r-blue-500\/55 {
  border-right-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-r-blue-500\/60 {
  border-right-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-r-blue-500\/65 {
  border-right-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-r-blue-500\/70 {
  border-right-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-r-blue-500\/75 {
  border-right-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-r-blue-500\/80 {
  border-right-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-r-blue-500\/85 {
  border-right-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-r-blue-500\/90 {
  border-right-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-r-blue-500\/95 {
  border-right-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-r-blue-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-600\/0 {
  border-right-color: rgb(37 99 235 / 0) !important;
}

.tw-border-r-blue-600\/10 {
  border-right-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-r-blue-600\/100 {
  border-right-color: rgb(37 99 235 / 1) !important;
}

.tw-border-r-blue-600\/15 {
  border-right-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-r-blue-600\/20 {
  border-right-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-r-blue-600\/25 {
  border-right-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-r-blue-600\/30 {
  border-right-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-r-blue-600\/35 {
  border-right-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-r-blue-600\/40 {
  border-right-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-r-blue-600\/45 {
  border-right-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-r-blue-600\/5 {
  border-right-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-r-blue-600\/50 {
  border-right-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-r-blue-600\/55 {
  border-right-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-r-blue-600\/60 {
  border-right-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-r-blue-600\/65 {
  border-right-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-r-blue-600\/70 {
  border-right-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-r-blue-600\/75 {
  border-right-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-r-blue-600\/80 {
  border-right-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-r-blue-600\/85 {
  border-right-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-r-blue-600\/90 {
  border-right-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-r-blue-600\/95 {
  border-right-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-r-blue-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-700\/0 {
  border-right-color: rgb(29 78 216 / 0) !important;
}

.tw-border-r-blue-700\/10 {
  border-right-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-r-blue-700\/100 {
  border-right-color: rgb(29 78 216 / 1) !important;
}

.tw-border-r-blue-700\/15 {
  border-right-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-r-blue-700\/20 {
  border-right-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-r-blue-700\/25 {
  border-right-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-r-blue-700\/30 {
  border-right-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-r-blue-700\/35 {
  border-right-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-r-blue-700\/40 {
  border-right-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-r-blue-700\/45 {
  border-right-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-r-blue-700\/5 {
  border-right-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-r-blue-700\/50 {
  border-right-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-r-blue-700\/55 {
  border-right-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-r-blue-700\/60 {
  border-right-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-r-blue-700\/65 {
  border-right-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-r-blue-700\/70 {
  border-right-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-r-blue-700\/75 {
  border-right-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-r-blue-700\/80 {
  border-right-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-r-blue-700\/85 {
  border-right-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-r-blue-700\/90 {
  border-right-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-r-blue-700\/95 {
  border-right-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-r-blue-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-800\/0 {
  border-right-color: rgb(30 64 175 / 0) !important;
}

.tw-border-r-blue-800\/10 {
  border-right-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-r-blue-800\/100 {
  border-right-color: rgb(30 64 175 / 1) !important;
}

.tw-border-r-blue-800\/15 {
  border-right-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-r-blue-800\/20 {
  border-right-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-r-blue-800\/25 {
  border-right-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-r-blue-800\/30 {
  border-right-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-r-blue-800\/35 {
  border-right-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-r-blue-800\/40 {
  border-right-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-r-blue-800\/45 {
  border-right-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-r-blue-800\/5 {
  border-right-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-r-blue-800\/50 {
  border-right-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-r-blue-800\/55 {
  border-right-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-r-blue-800\/60 {
  border-right-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-r-blue-800\/65 {
  border-right-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-r-blue-800\/70 {
  border-right-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-r-blue-800\/75 {
  border-right-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-r-blue-800\/80 {
  border-right-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-r-blue-800\/85 {
  border-right-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-r-blue-800\/90 {
  border-right-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-r-blue-800\/95 {
  border-right-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-r-blue-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-900\/0 {
  border-right-color: rgb(30 58 138 / 0) !important;
}

.tw-border-r-blue-900\/10 {
  border-right-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-r-blue-900\/100 {
  border-right-color: rgb(30 58 138 / 1) !important;
}

.tw-border-r-blue-900\/15 {
  border-right-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-r-blue-900\/20 {
  border-right-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-r-blue-900\/25 {
  border-right-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-r-blue-900\/30 {
  border-right-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-r-blue-900\/35 {
  border-right-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-r-blue-900\/40 {
  border-right-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-r-blue-900\/45 {
  border-right-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-r-blue-900\/5 {
  border-right-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-r-blue-900\/50 {
  border-right-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-r-blue-900\/55 {
  border-right-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-r-blue-900\/60 {
  border-right-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-r-blue-900\/65 {
  border-right-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-r-blue-900\/70 {
  border-right-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-r-blue-900\/75 {
  border-right-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-r-blue-900\/80 {
  border-right-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-r-blue-900\/85 {
  border-right-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-r-blue-900\/90 {
  border-right-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-r-blue-900\/95 {
  border-right-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-r-blue-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-r-blue-950\/0 {
  border-right-color: rgb(23 37 84 / 0) !important;
}

.tw-border-r-blue-950\/10 {
  border-right-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-r-blue-950\/100 {
  border-right-color: rgb(23 37 84 / 1) !important;
}

.tw-border-r-blue-950\/15 {
  border-right-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-r-blue-950\/20 {
  border-right-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-r-blue-950\/25 {
  border-right-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-r-blue-950\/30 {
  border-right-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-r-blue-950\/35 {
  border-right-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-r-blue-950\/40 {
  border-right-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-r-blue-950\/45 {
  border-right-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-r-blue-950\/5 {
  border-right-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-r-blue-950\/50 {
  border-right-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-r-blue-950\/55 {
  border-right-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-r-blue-950\/60 {
  border-right-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-r-blue-950\/65 {
  border-right-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-r-blue-950\/70 {
  border-right-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-r-blue-950\/75 {
  border-right-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-r-blue-950\/80 {
  border-right-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-r-blue-950\/85 {
  border-right-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-r-blue-950\/90 {
  border-right-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-r-blue-950\/95 {
  border-right-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-r-current {
  border-right-color: currentColor !important;
}

.tw-border-r-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-100\/0 {
  border-right-color: rgb(207 250 254 / 0) !important;
}

.tw-border-r-cyan-100\/10 {
  border-right-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-r-cyan-100\/100 {
  border-right-color: rgb(207 250 254 / 1) !important;
}

.tw-border-r-cyan-100\/15 {
  border-right-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-r-cyan-100\/20 {
  border-right-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-r-cyan-100\/25 {
  border-right-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-r-cyan-100\/30 {
  border-right-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-r-cyan-100\/35 {
  border-right-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-r-cyan-100\/40 {
  border-right-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-r-cyan-100\/45 {
  border-right-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-r-cyan-100\/5 {
  border-right-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-r-cyan-100\/50 {
  border-right-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-r-cyan-100\/55 {
  border-right-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-r-cyan-100\/60 {
  border-right-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-r-cyan-100\/65 {
  border-right-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-r-cyan-100\/70 {
  border-right-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-r-cyan-100\/75 {
  border-right-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-r-cyan-100\/80 {
  border-right-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-r-cyan-100\/85 {
  border-right-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-r-cyan-100\/90 {
  border-right-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-r-cyan-100\/95 {
  border-right-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-r-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-200\/0 {
  border-right-color: rgb(165 243 252 / 0) !important;
}

.tw-border-r-cyan-200\/10 {
  border-right-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-r-cyan-200\/100 {
  border-right-color: rgb(165 243 252 / 1) !important;
}

.tw-border-r-cyan-200\/15 {
  border-right-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-r-cyan-200\/20 {
  border-right-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-r-cyan-200\/25 {
  border-right-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-r-cyan-200\/30 {
  border-right-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-r-cyan-200\/35 {
  border-right-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-r-cyan-200\/40 {
  border-right-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-r-cyan-200\/45 {
  border-right-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-r-cyan-200\/5 {
  border-right-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-r-cyan-200\/50 {
  border-right-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-r-cyan-200\/55 {
  border-right-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-r-cyan-200\/60 {
  border-right-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-r-cyan-200\/65 {
  border-right-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-r-cyan-200\/70 {
  border-right-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-r-cyan-200\/75 {
  border-right-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-r-cyan-200\/80 {
  border-right-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-r-cyan-200\/85 {
  border-right-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-r-cyan-200\/90 {
  border-right-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-r-cyan-200\/95 {
  border-right-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-r-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-300\/0 {
  border-right-color: rgb(103 232 249 / 0) !important;
}

.tw-border-r-cyan-300\/10 {
  border-right-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-r-cyan-300\/100 {
  border-right-color: rgb(103 232 249 / 1) !important;
}

.tw-border-r-cyan-300\/15 {
  border-right-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-r-cyan-300\/20 {
  border-right-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-r-cyan-300\/25 {
  border-right-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-r-cyan-300\/30 {
  border-right-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-r-cyan-300\/35 {
  border-right-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-r-cyan-300\/40 {
  border-right-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-r-cyan-300\/45 {
  border-right-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-r-cyan-300\/5 {
  border-right-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-r-cyan-300\/50 {
  border-right-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-r-cyan-300\/55 {
  border-right-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-r-cyan-300\/60 {
  border-right-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-r-cyan-300\/65 {
  border-right-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-r-cyan-300\/70 {
  border-right-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-r-cyan-300\/75 {
  border-right-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-r-cyan-300\/80 {
  border-right-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-r-cyan-300\/85 {
  border-right-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-r-cyan-300\/90 {
  border-right-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-r-cyan-300\/95 {
  border-right-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-r-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-400\/0 {
  border-right-color: rgb(34 211 238 / 0) !important;
}

.tw-border-r-cyan-400\/10 {
  border-right-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-r-cyan-400\/100 {
  border-right-color: rgb(34 211 238 / 1) !important;
}

.tw-border-r-cyan-400\/15 {
  border-right-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-r-cyan-400\/20 {
  border-right-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-r-cyan-400\/25 {
  border-right-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-r-cyan-400\/30 {
  border-right-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-r-cyan-400\/35 {
  border-right-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-r-cyan-400\/40 {
  border-right-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-r-cyan-400\/45 {
  border-right-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-r-cyan-400\/5 {
  border-right-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-r-cyan-400\/50 {
  border-right-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-r-cyan-400\/55 {
  border-right-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-r-cyan-400\/60 {
  border-right-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-r-cyan-400\/65 {
  border-right-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-r-cyan-400\/70 {
  border-right-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-r-cyan-400\/75 {
  border-right-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-r-cyan-400\/80 {
  border-right-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-r-cyan-400\/85 {
  border-right-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-r-cyan-400\/90 {
  border-right-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-r-cyan-400\/95 {
  border-right-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-r-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-50\/0 {
  border-right-color: rgb(236 254 255 / 0) !important;
}

.tw-border-r-cyan-50\/10 {
  border-right-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-r-cyan-50\/100 {
  border-right-color: rgb(236 254 255 / 1) !important;
}

.tw-border-r-cyan-50\/15 {
  border-right-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-r-cyan-50\/20 {
  border-right-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-r-cyan-50\/25 {
  border-right-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-r-cyan-50\/30 {
  border-right-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-r-cyan-50\/35 {
  border-right-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-r-cyan-50\/40 {
  border-right-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-r-cyan-50\/45 {
  border-right-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-r-cyan-50\/5 {
  border-right-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-r-cyan-50\/50 {
  border-right-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-r-cyan-50\/55 {
  border-right-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-r-cyan-50\/60 {
  border-right-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-r-cyan-50\/65 {
  border-right-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-r-cyan-50\/70 {
  border-right-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-r-cyan-50\/75 {
  border-right-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-r-cyan-50\/80 {
  border-right-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-r-cyan-50\/85 {
  border-right-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-r-cyan-50\/90 {
  border-right-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-r-cyan-50\/95 {
  border-right-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-r-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-500\/0 {
  border-right-color: rgb(6 182 212 / 0) !important;
}

.tw-border-r-cyan-500\/10 {
  border-right-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-r-cyan-500\/100 {
  border-right-color: rgb(6 182 212 / 1) !important;
}

.tw-border-r-cyan-500\/15 {
  border-right-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-r-cyan-500\/20 {
  border-right-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-r-cyan-500\/25 {
  border-right-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-r-cyan-500\/30 {
  border-right-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-r-cyan-500\/35 {
  border-right-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-r-cyan-500\/40 {
  border-right-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-r-cyan-500\/45 {
  border-right-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-r-cyan-500\/5 {
  border-right-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-r-cyan-500\/50 {
  border-right-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-r-cyan-500\/55 {
  border-right-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-r-cyan-500\/60 {
  border-right-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-r-cyan-500\/65 {
  border-right-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-r-cyan-500\/70 {
  border-right-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-r-cyan-500\/75 {
  border-right-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-r-cyan-500\/80 {
  border-right-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-r-cyan-500\/85 {
  border-right-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-r-cyan-500\/90 {
  border-right-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-r-cyan-500\/95 {
  border-right-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-r-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-600\/0 {
  border-right-color: rgb(8 145 178 / 0) !important;
}

.tw-border-r-cyan-600\/10 {
  border-right-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-r-cyan-600\/100 {
  border-right-color: rgb(8 145 178 / 1) !important;
}

.tw-border-r-cyan-600\/15 {
  border-right-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-r-cyan-600\/20 {
  border-right-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-r-cyan-600\/25 {
  border-right-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-r-cyan-600\/30 {
  border-right-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-r-cyan-600\/35 {
  border-right-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-r-cyan-600\/40 {
  border-right-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-r-cyan-600\/45 {
  border-right-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-r-cyan-600\/5 {
  border-right-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-r-cyan-600\/50 {
  border-right-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-r-cyan-600\/55 {
  border-right-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-r-cyan-600\/60 {
  border-right-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-r-cyan-600\/65 {
  border-right-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-r-cyan-600\/70 {
  border-right-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-r-cyan-600\/75 {
  border-right-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-r-cyan-600\/80 {
  border-right-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-r-cyan-600\/85 {
  border-right-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-r-cyan-600\/90 {
  border-right-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-r-cyan-600\/95 {
  border-right-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-r-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-700\/0 {
  border-right-color: rgb(14 116 144 / 0) !important;
}

.tw-border-r-cyan-700\/10 {
  border-right-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-r-cyan-700\/100 {
  border-right-color: rgb(14 116 144 / 1) !important;
}

.tw-border-r-cyan-700\/15 {
  border-right-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-r-cyan-700\/20 {
  border-right-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-r-cyan-700\/25 {
  border-right-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-r-cyan-700\/30 {
  border-right-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-r-cyan-700\/35 {
  border-right-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-r-cyan-700\/40 {
  border-right-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-r-cyan-700\/45 {
  border-right-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-r-cyan-700\/5 {
  border-right-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-r-cyan-700\/50 {
  border-right-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-r-cyan-700\/55 {
  border-right-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-r-cyan-700\/60 {
  border-right-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-r-cyan-700\/65 {
  border-right-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-r-cyan-700\/70 {
  border-right-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-r-cyan-700\/75 {
  border-right-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-r-cyan-700\/80 {
  border-right-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-r-cyan-700\/85 {
  border-right-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-r-cyan-700\/90 {
  border-right-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-r-cyan-700\/95 {
  border-right-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-r-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-800\/0 {
  border-right-color: rgb(21 94 117 / 0) !important;
}

.tw-border-r-cyan-800\/10 {
  border-right-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-r-cyan-800\/100 {
  border-right-color: rgb(21 94 117 / 1) !important;
}

.tw-border-r-cyan-800\/15 {
  border-right-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-r-cyan-800\/20 {
  border-right-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-r-cyan-800\/25 {
  border-right-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-r-cyan-800\/30 {
  border-right-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-r-cyan-800\/35 {
  border-right-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-r-cyan-800\/40 {
  border-right-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-r-cyan-800\/45 {
  border-right-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-r-cyan-800\/5 {
  border-right-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-r-cyan-800\/50 {
  border-right-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-r-cyan-800\/55 {
  border-right-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-r-cyan-800\/60 {
  border-right-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-r-cyan-800\/65 {
  border-right-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-r-cyan-800\/70 {
  border-right-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-r-cyan-800\/75 {
  border-right-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-r-cyan-800\/80 {
  border-right-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-r-cyan-800\/85 {
  border-right-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-r-cyan-800\/90 {
  border-right-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-r-cyan-800\/95 {
  border-right-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-r-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-900\/0 {
  border-right-color: rgb(22 78 99 / 0) !important;
}

.tw-border-r-cyan-900\/10 {
  border-right-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-r-cyan-900\/100 {
  border-right-color: rgb(22 78 99 / 1) !important;
}

.tw-border-r-cyan-900\/15 {
  border-right-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-r-cyan-900\/20 {
  border-right-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-r-cyan-900\/25 {
  border-right-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-r-cyan-900\/30 {
  border-right-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-r-cyan-900\/35 {
  border-right-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-r-cyan-900\/40 {
  border-right-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-r-cyan-900\/45 {
  border-right-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-r-cyan-900\/5 {
  border-right-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-r-cyan-900\/50 {
  border-right-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-r-cyan-900\/55 {
  border-right-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-r-cyan-900\/60 {
  border-right-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-r-cyan-900\/65 {
  border-right-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-r-cyan-900\/70 {
  border-right-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-r-cyan-900\/75 {
  border-right-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-r-cyan-900\/80 {
  border-right-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-r-cyan-900\/85 {
  border-right-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-r-cyan-900\/90 {
  border-right-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-r-cyan-900\/95 {
  border-right-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-r-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-r-cyan-950\/0 {
  border-right-color: rgb(8 51 68 / 0) !important;
}

.tw-border-r-cyan-950\/10 {
  border-right-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-r-cyan-950\/100 {
  border-right-color: rgb(8 51 68 / 1) !important;
}

.tw-border-r-cyan-950\/15 {
  border-right-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-r-cyan-950\/20 {
  border-right-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-r-cyan-950\/25 {
  border-right-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-r-cyan-950\/30 {
  border-right-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-r-cyan-950\/35 {
  border-right-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-r-cyan-950\/40 {
  border-right-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-r-cyan-950\/45 {
  border-right-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-r-cyan-950\/5 {
  border-right-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-r-cyan-950\/50 {
  border-right-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-r-cyan-950\/55 {
  border-right-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-r-cyan-950\/60 {
  border-right-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-r-cyan-950\/65 {
  border-right-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-r-cyan-950\/70 {
  border-right-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-r-cyan-950\/75 {
  border-right-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-r-cyan-950\/80 {
  border-right-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-r-cyan-950\/85 {
  border-right-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-r-cyan-950\/90 {
  border-right-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-r-cyan-950\/95 {
  border-right-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-r-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-100\/0 {
  border-right-color: rgb(209 250 229 / 0) !important;
}

.tw-border-r-emerald-100\/10 {
  border-right-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-r-emerald-100\/100 {
  border-right-color: rgb(209 250 229 / 1) !important;
}

.tw-border-r-emerald-100\/15 {
  border-right-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-r-emerald-100\/20 {
  border-right-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-r-emerald-100\/25 {
  border-right-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-r-emerald-100\/30 {
  border-right-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-r-emerald-100\/35 {
  border-right-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-r-emerald-100\/40 {
  border-right-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-r-emerald-100\/45 {
  border-right-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-r-emerald-100\/5 {
  border-right-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-r-emerald-100\/50 {
  border-right-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-r-emerald-100\/55 {
  border-right-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-r-emerald-100\/60 {
  border-right-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-r-emerald-100\/65 {
  border-right-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-r-emerald-100\/70 {
  border-right-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-r-emerald-100\/75 {
  border-right-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-r-emerald-100\/80 {
  border-right-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-r-emerald-100\/85 {
  border-right-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-r-emerald-100\/90 {
  border-right-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-r-emerald-100\/95 {
  border-right-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-r-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-200\/0 {
  border-right-color: rgb(167 243 208 / 0) !important;
}

.tw-border-r-emerald-200\/10 {
  border-right-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-r-emerald-200\/100 {
  border-right-color: rgb(167 243 208 / 1) !important;
}

.tw-border-r-emerald-200\/15 {
  border-right-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-r-emerald-200\/20 {
  border-right-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-r-emerald-200\/25 {
  border-right-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-r-emerald-200\/30 {
  border-right-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-r-emerald-200\/35 {
  border-right-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-r-emerald-200\/40 {
  border-right-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-r-emerald-200\/45 {
  border-right-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-r-emerald-200\/5 {
  border-right-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-r-emerald-200\/50 {
  border-right-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-r-emerald-200\/55 {
  border-right-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-r-emerald-200\/60 {
  border-right-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-r-emerald-200\/65 {
  border-right-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-r-emerald-200\/70 {
  border-right-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-r-emerald-200\/75 {
  border-right-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-r-emerald-200\/80 {
  border-right-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-r-emerald-200\/85 {
  border-right-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-r-emerald-200\/90 {
  border-right-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-r-emerald-200\/95 {
  border-right-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-r-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-300\/0 {
  border-right-color: rgb(110 231 183 / 0) !important;
}

.tw-border-r-emerald-300\/10 {
  border-right-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-r-emerald-300\/100 {
  border-right-color: rgb(110 231 183 / 1) !important;
}

.tw-border-r-emerald-300\/15 {
  border-right-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-r-emerald-300\/20 {
  border-right-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-r-emerald-300\/25 {
  border-right-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-r-emerald-300\/30 {
  border-right-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-r-emerald-300\/35 {
  border-right-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-r-emerald-300\/40 {
  border-right-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-r-emerald-300\/45 {
  border-right-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-r-emerald-300\/5 {
  border-right-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-r-emerald-300\/50 {
  border-right-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-r-emerald-300\/55 {
  border-right-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-r-emerald-300\/60 {
  border-right-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-r-emerald-300\/65 {
  border-right-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-r-emerald-300\/70 {
  border-right-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-r-emerald-300\/75 {
  border-right-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-r-emerald-300\/80 {
  border-right-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-r-emerald-300\/85 {
  border-right-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-r-emerald-300\/90 {
  border-right-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-r-emerald-300\/95 {
  border-right-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-r-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-400\/0 {
  border-right-color: rgb(52 211 153 / 0) !important;
}

.tw-border-r-emerald-400\/10 {
  border-right-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-r-emerald-400\/100 {
  border-right-color: rgb(52 211 153 / 1) !important;
}

.tw-border-r-emerald-400\/15 {
  border-right-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-r-emerald-400\/20 {
  border-right-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-r-emerald-400\/25 {
  border-right-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-r-emerald-400\/30 {
  border-right-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-r-emerald-400\/35 {
  border-right-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-r-emerald-400\/40 {
  border-right-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-r-emerald-400\/45 {
  border-right-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-r-emerald-400\/5 {
  border-right-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-r-emerald-400\/50 {
  border-right-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-r-emerald-400\/55 {
  border-right-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-r-emerald-400\/60 {
  border-right-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-r-emerald-400\/65 {
  border-right-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-r-emerald-400\/70 {
  border-right-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-r-emerald-400\/75 {
  border-right-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-r-emerald-400\/80 {
  border-right-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-r-emerald-400\/85 {
  border-right-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-r-emerald-400\/90 {
  border-right-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-r-emerald-400\/95 {
  border-right-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-r-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-50\/0 {
  border-right-color: rgb(236 253 245 / 0) !important;
}

.tw-border-r-emerald-50\/10 {
  border-right-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-r-emerald-50\/100 {
  border-right-color: rgb(236 253 245 / 1) !important;
}

.tw-border-r-emerald-50\/15 {
  border-right-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-r-emerald-50\/20 {
  border-right-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-r-emerald-50\/25 {
  border-right-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-r-emerald-50\/30 {
  border-right-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-r-emerald-50\/35 {
  border-right-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-r-emerald-50\/40 {
  border-right-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-r-emerald-50\/45 {
  border-right-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-r-emerald-50\/5 {
  border-right-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-r-emerald-50\/50 {
  border-right-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-r-emerald-50\/55 {
  border-right-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-r-emerald-50\/60 {
  border-right-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-r-emerald-50\/65 {
  border-right-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-r-emerald-50\/70 {
  border-right-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-r-emerald-50\/75 {
  border-right-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-r-emerald-50\/80 {
  border-right-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-r-emerald-50\/85 {
  border-right-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-r-emerald-50\/90 {
  border-right-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-r-emerald-50\/95 {
  border-right-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-r-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-500\/0 {
  border-right-color: rgb(16 185 129 / 0) !important;
}

.tw-border-r-emerald-500\/10 {
  border-right-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-r-emerald-500\/100 {
  border-right-color: rgb(16 185 129 / 1) !important;
}

.tw-border-r-emerald-500\/15 {
  border-right-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-r-emerald-500\/20 {
  border-right-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-r-emerald-500\/25 {
  border-right-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-r-emerald-500\/30 {
  border-right-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-r-emerald-500\/35 {
  border-right-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-r-emerald-500\/40 {
  border-right-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-r-emerald-500\/45 {
  border-right-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-r-emerald-500\/5 {
  border-right-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-r-emerald-500\/50 {
  border-right-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-r-emerald-500\/55 {
  border-right-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-r-emerald-500\/60 {
  border-right-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-r-emerald-500\/65 {
  border-right-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-r-emerald-500\/70 {
  border-right-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-r-emerald-500\/75 {
  border-right-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-r-emerald-500\/80 {
  border-right-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-r-emerald-500\/85 {
  border-right-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-r-emerald-500\/90 {
  border-right-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-r-emerald-500\/95 {
  border-right-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-r-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-600\/0 {
  border-right-color: rgb(5 150 105 / 0) !important;
}

.tw-border-r-emerald-600\/10 {
  border-right-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-r-emerald-600\/100 {
  border-right-color: rgb(5 150 105 / 1) !important;
}

.tw-border-r-emerald-600\/15 {
  border-right-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-r-emerald-600\/20 {
  border-right-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-r-emerald-600\/25 {
  border-right-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-r-emerald-600\/30 {
  border-right-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-r-emerald-600\/35 {
  border-right-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-r-emerald-600\/40 {
  border-right-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-r-emerald-600\/45 {
  border-right-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-r-emerald-600\/5 {
  border-right-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-r-emerald-600\/50 {
  border-right-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-r-emerald-600\/55 {
  border-right-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-r-emerald-600\/60 {
  border-right-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-r-emerald-600\/65 {
  border-right-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-r-emerald-600\/70 {
  border-right-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-r-emerald-600\/75 {
  border-right-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-r-emerald-600\/80 {
  border-right-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-r-emerald-600\/85 {
  border-right-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-r-emerald-600\/90 {
  border-right-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-r-emerald-600\/95 {
  border-right-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-r-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-700\/0 {
  border-right-color: rgb(4 120 87 / 0) !important;
}

.tw-border-r-emerald-700\/10 {
  border-right-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-r-emerald-700\/100 {
  border-right-color: rgb(4 120 87 / 1) !important;
}

.tw-border-r-emerald-700\/15 {
  border-right-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-r-emerald-700\/20 {
  border-right-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-r-emerald-700\/25 {
  border-right-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-r-emerald-700\/30 {
  border-right-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-r-emerald-700\/35 {
  border-right-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-r-emerald-700\/40 {
  border-right-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-r-emerald-700\/45 {
  border-right-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-r-emerald-700\/5 {
  border-right-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-r-emerald-700\/50 {
  border-right-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-r-emerald-700\/55 {
  border-right-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-r-emerald-700\/60 {
  border-right-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-r-emerald-700\/65 {
  border-right-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-r-emerald-700\/70 {
  border-right-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-r-emerald-700\/75 {
  border-right-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-r-emerald-700\/80 {
  border-right-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-r-emerald-700\/85 {
  border-right-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-r-emerald-700\/90 {
  border-right-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-r-emerald-700\/95 {
  border-right-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-r-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-800\/0 {
  border-right-color: rgb(6 95 70 / 0) !important;
}

.tw-border-r-emerald-800\/10 {
  border-right-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-r-emerald-800\/100 {
  border-right-color: rgb(6 95 70 / 1) !important;
}

.tw-border-r-emerald-800\/15 {
  border-right-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-r-emerald-800\/20 {
  border-right-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-r-emerald-800\/25 {
  border-right-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-r-emerald-800\/30 {
  border-right-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-r-emerald-800\/35 {
  border-right-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-r-emerald-800\/40 {
  border-right-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-r-emerald-800\/45 {
  border-right-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-r-emerald-800\/5 {
  border-right-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-r-emerald-800\/50 {
  border-right-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-r-emerald-800\/55 {
  border-right-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-r-emerald-800\/60 {
  border-right-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-r-emerald-800\/65 {
  border-right-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-r-emerald-800\/70 {
  border-right-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-r-emerald-800\/75 {
  border-right-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-r-emerald-800\/80 {
  border-right-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-r-emerald-800\/85 {
  border-right-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-r-emerald-800\/90 {
  border-right-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-r-emerald-800\/95 {
  border-right-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-r-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-900\/0 {
  border-right-color: rgb(6 78 59 / 0) !important;
}

.tw-border-r-emerald-900\/10 {
  border-right-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-r-emerald-900\/100 {
  border-right-color: rgb(6 78 59 / 1) !important;
}

.tw-border-r-emerald-900\/15 {
  border-right-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-r-emerald-900\/20 {
  border-right-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-r-emerald-900\/25 {
  border-right-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-r-emerald-900\/30 {
  border-right-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-r-emerald-900\/35 {
  border-right-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-r-emerald-900\/40 {
  border-right-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-r-emerald-900\/45 {
  border-right-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-r-emerald-900\/5 {
  border-right-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-r-emerald-900\/50 {
  border-right-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-r-emerald-900\/55 {
  border-right-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-r-emerald-900\/60 {
  border-right-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-r-emerald-900\/65 {
  border-right-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-r-emerald-900\/70 {
  border-right-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-r-emerald-900\/75 {
  border-right-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-r-emerald-900\/80 {
  border-right-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-r-emerald-900\/85 {
  border-right-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-r-emerald-900\/90 {
  border-right-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-r-emerald-900\/95 {
  border-right-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-r-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-r-emerald-950\/0 {
  border-right-color: rgb(2 44 34 / 0) !important;
}

.tw-border-r-emerald-950\/10 {
  border-right-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-r-emerald-950\/100 {
  border-right-color: rgb(2 44 34 / 1) !important;
}

.tw-border-r-emerald-950\/15 {
  border-right-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-r-emerald-950\/20 {
  border-right-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-r-emerald-950\/25 {
  border-right-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-r-emerald-950\/30 {
  border-right-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-r-emerald-950\/35 {
  border-right-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-r-emerald-950\/40 {
  border-right-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-r-emerald-950\/45 {
  border-right-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-r-emerald-950\/5 {
  border-right-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-r-emerald-950\/50 {
  border-right-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-r-emerald-950\/55 {
  border-right-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-r-emerald-950\/60 {
  border-right-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-r-emerald-950\/65 {
  border-right-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-r-emerald-950\/70 {
  border-right-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-r-emerald-950\/75 {
  border-right-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-r-emerald-950\/80 {
  border-right-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-r-emerald-950\/85 {
  border-right-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-r-emerald-950\/90 {
  border-right-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-r-emerald-950\/95 {
  border-right-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-r-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-100\/0 {
  border-right-color: rgb(250 232 255 / 0) !important;
}

.tw-border-r-fuchsia-100\/10 {
  border-right-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-r-fuchsia-100\/100 {
  border-right-color: rgb(250 232 255 / 1) !important;
}

.tw-border-r-fuchsia-100\/15 {
  border-right-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-r-fuchsia-100\/20 {
  border-right-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-r-fuchsia-100\/25 {
  border-right-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-r-fuchsia-100\/30 {
  border-right-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-r-fuchsia-100\/35 {
  border-right-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-r-fuchsia-100\/40 {
  border-right-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-r-fuchsia-100\/45 {
  border-right-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-r-fuchsia-100\/5 {
  border-right-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-r-fuchsia-100\/50 {
  border-right-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-r-fuchsia-100\/55 {
  border-right-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-r-fuchsia-100\/60 {
  border-right-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-r-fuchsia-100\/65 {
  border-right-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-r-fuchsia-100\/70 {
  border-right-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-r-fuchsia-100\/75 {
  border-right-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-r-fuchsia-100\/80 {
  border-right-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-r-fuchsia-100\/85 {
  border-right-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-r-fuchsia-100\/90 {
  border-right-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-r-fuchsia-100\/95 {
  border-right-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-r-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-200\/0 {
  border-right-color: rgb(245 208 254 / 0) !important;
}

.tw-border-r-fuchsia-200\/10 {
  border-right-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-r-fuchsia-200\/100 {
  border-right-color: rgb(245 208 254 / 1) !important;
}

.tw-border-r-fuchsia-200\/15 {
  border-right-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-r-fuchsia-200\/20 {
  border-right-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-r-fuchsia-200\/25 {
  border-right-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-r-fuchsia-200\/30 {
  border-right-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-r-fuchsia-200\/35 {
  border-right-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-r-fuchsia-200\/40 {
  border-right-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-r-fuchsia-200\/45 {
  border-right-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-r-fuchsia-200\/5 {
  border-right-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-r-fuchsia-200\/50 {
  border-right-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-r-fuchsia-200\/55 {
  border-right-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-r-fuchsia-200\/60 {
  border-right-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-r-fuchsia-200\/65 {
  border-right-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-r-fuchsia-200\/70 {
  border-right-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-r-fuchsia-200\/75 {
  border-right-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-r-fuchsia-200\/80 {
  border-right-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-r-fuchsia-200\/85 {
  border-right-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-r-fuchsia-200\/90 {
  border-right-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-r-fuchsia-200\/95 {
  border-right-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-r-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-300\/0 {
  border-right-color: rgb(240 171 252 / 0) !important;
}

.tw-border-r-fuchsia-300\/10 {
  border-right-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-r-fuchsia-300\/100 {
  border-right-color: rgb(240 171 252 / 1) !important;
}

.tw-border-r-fuchsia-300\/15 {
  border-right-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-r-fuchsia-300\/20 {
  border-right-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-r-fuchsia-300\/25 {
  border-right-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-r-fuchsia-300\/30 {
  border-right-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-r-fuchsia-300\/35 {
  border-right-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-r-fuchsia-300\/40 {
  border-right-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-r-fuchsia-300\/45 {
  border-right-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-r-fuchsia-300\/5 {
  border-right-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-r-fuchsia-300\/50 {
  border-right-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-r-fuchsia-300\/55 {
  border-right-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-r-fuchsia-300\/60 {
  border-right-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-r-fuchsia-300\/65 {
  border-right-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-r-fuchsia-300\/70 {
  border-right-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-r-fuchsia-300\/75 {
  border-right-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-r-fuchsia-300\/80 {
  border-right-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-r-fuchsia-300\/85 {
  border-right-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-r-fuchsia-300\/90 {
  border-right-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-r-fuchsia-300\/95 {
  border-right-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-r-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-400\/0 {
  border-right-color: rgb(232 121 249 / 0) !important;
}

.tw-border-r-fuchsia-400\/10 {
  border-right-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-r-fuchsia-400\/100 {
  border-right-color: rgb(232 121 249 / 1) !important;
}

.tw-border-r-fuchsia-400\/15 {
  border-right-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-r-fuchsia-400\/20 {
  border-right-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-r-fuchsia-400\/25 {
  border-right-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-r-fuchsia-400\/30 {
  border-right-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-r-fuchsia-400\/35 {
  border-right-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-r-fuchsia-400\/40 {
  border-right-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-r-fuchsia-400\/45 {
  border-right-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-r-fuchsia-400\/5 {
  border-right-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-r-fuchsia-400\/50 {
  border-right-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-r-fuchsia-400\/55 {
  border-right-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-r-fuchsia-400\/60 {
  border-right-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-r-fuchsia-400\/65 {
  border-right-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-r-fuchsia-400\/70 {
  border-right-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-r-fuchsia-400\/75 {
  border-right-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-r-fuchsia-400\/80 {
  border-right-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-r-fuchsia-400\/85 {
  border-right-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-r-fuchsia-400\/90 {
  border-right-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-r-fuchsia-400\/95 {
  border-right-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-r-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-50\/0 {
  border-right-color: rgb(253 244 255 / 0) !important;
}

.tw-border-r-fuchsia-50\/10 {
  border-right-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-r-fuchsia-50\/100 {
  border-right-color: rgb(253 244 255 / 1) !important;
}

.tw-border-r-fuchsia-50\/15 {
  border-right-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-r-fuchsia-50\/20 {
  border-right-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-r-fuchsia-50\/25 {
  border-right-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-r-fuchsia-50\/30 {
  border-right-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-r-fuchsia-50\/35 {
  border-right-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-r-fuchsia-50\/40 {
  border-right-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-r-fuchsia-50\/45 {
  border-right-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-r-fuchsia-50\/5 {
  border-right-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-r-fuchsia-50\/50 {
  border-right-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-r-fuchsia-50\/55 {
  border-right-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-r-fuchsia-50\/60 {
  border-right-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-r-fuchsia-50\/65 {
  border-right-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-r-fuchsia-50\/70 {
  border-right-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-r-fuchsia-50\/75 {
  border-right-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-r-fuchsia-50\/80 {
  border-right-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-r-fuchsia-50\/85 {
  border-right-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-r-fuchsia-50\/90 {
  border-right-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-r-fuchsia-50\/95 {
  border-right-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-r-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-500\/0 {
  border-right-color: rgb(217 70 239 / 0) !important;
}

.tw-border-r-fuchsia-500\/10 {
  border-right-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-r-fuchsia-500\/100 {
  border-right-color: rgb(217 70 239 / 1) !important;
}

.tw-border-r-fuchsia-500\/15 {
  border-right-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-r-fuchsia-500\/20 {
  border-right-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-r-fuchsia-500\/25 {
  border-right-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-r-fuchsia-500\/30 {
  border-right-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-r-fuchsia-500\/35 {
  border-right-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-r-fuchsia-500\/40 {
  border-right-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-r-fuchsia-500\/45 {
  border-right-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-r-fuchsia-500\/5 {
  border-right-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-r-fuchsia-500\/50 {
  border-right-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-r-fuchsia-500\/55 {
  border-right-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-r-fuchsia-500\/60 {
  border-right-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-r-fuchsia-500\/65 {
  border-right-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-r-fuchsia-500\/70 {
  border-right-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-r-fuchsia-500\/75 {
  border-right-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-r-fuchsia-500\/80 {
  border-right-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-r-fuchsia-500\/85 {
  border-right-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-r-fuchsia-500\/90 {
  border-right-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-r-fuchsia-500\/95 {
  border-right-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-r-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-600\/0 {
  border-right-color: rgb(192 38 211 / 0) !important;
}

.tw-border-r-fuchsia-600\/10 {
  border-right-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-r-fuchsia-600\/100 {
  border-right-color: rgb(192 38 211 / 1) !important;
}

.tw-border-r-fuchsia-600\/15 {
  border-right-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-r-fuchsia-600\/20 {
  border-right-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-r-fuchsia-600\/25 {
  border-right-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-r-fuchsia-600\/30 {
  border-right-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-r-fuchsia-600\/35 {
  border-right-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-r-fuchsia-600\/40 {
  border-right-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-r-fuchsia-600\/45 {
  border-right-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-r-fuchsia-600\/5 {
  border-right-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-r-fuchsia-600\/50 {
  border-right-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-r-fuchsia-600\/55 {
  border-right-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-r-fuchsia-600\/60 {
  border-right-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-r-fuchsia-600\/65 {
  border-right-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-r-fuchsia-600\/70 {
  border-right-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-r-fuchsia-600\/75 {
  border-right-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-r-fuchsia-600\/80 {
  border-right-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-r-fuchsia-600\/85 {
  border-right-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-r-fuchsia-600\/90 {
  border-right-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-r-fuchsia-600\/95 {
  border-right-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-r-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-700\/0 {
  border-right-color: rgb(162 28 175 / 0) !important;
}

.tw-border-r-fuchsia-700\/10 {
  border-right-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-r-fuchsia-700\/100 {
  border-right-color: rgb(162 28 175 / 1) !important;
}

.tw-border-r-fuchsia-700\/15 {
  border-right-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-r-fuchsia-700\/20 {
  border-right-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-r-fuchsia-700\/25 {
  border-right-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-r-fuchsia-700\/30 {
  border-right-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-r-fuchsia-700\/35 {
  border-right-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-r-fuchsia-700\/40 {
  border-right-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-r-fuchsia-700\/45 {
  border-right-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-r-fuchsia-700\/5 {
  border-right-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-r-fuchsia-700\/50 {
  border-right-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-r-fuchsia-700\/55 {
  border-right-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-r-fuchsia-700\/60 {
  border-right-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-r-fuchsia-700\/65 {
  border-right-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-r-fuchsia-700\/70 {
  border-right-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-r-fuchsia-700\/75 {
  border-right-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-r-fuchsia-700\/80 {
  border-right-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-r-fuchsia-700\/85 {
  border-right-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-r-fuchsia-700\/90 {
  border-right-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-r-fuchsia-700\/95 {
  border-right-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-r-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-800\/0 {
  border-right-color: rgb(134 25 143 / 0) !important;
}

.tw-border-r-fuchsia-800\/10 {
  border-right-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-r-fuchsia-800\/100 {
  border-right-color: rgb(134 25 143 / 1) !important;
}

.tw-border-r-fuchsia-800\/15 {
  border-right-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-r-fuchsia-800\/20 {
  border-right-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-r-fuchsia-800\/25 {
  border-right-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-r-fuchsia-800\/30 {
  border-right-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-r-fuchsia-800\/35 {
  border-right-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-r-fuchsia-800\/40 {
  border-right-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-r-fuchsia-800\/45 {
  border-right-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-r-fuchsia-800\/5 {
  border-right-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-r-fuchsia-800\/50 {
  border-right-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-r-fuchsia-800\/55 {
  border-right-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-r-fuchsia-800\/60 {
  border-right-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-r-fuchsia-800\/65 {
  border-right-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-r-fuchsia-800\/70 {
  border-right-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-r-fuchsia-800\/75 {
  border-right-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-r-fuchsia-800\/80 {
  border-right-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-r-fuchsia-800\/85 {
  border-right-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-r-fuchsia-800\/90 {
  border-right-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-r-fuchsia-800\/95 {
  border-right-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-r-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-900\/0 {
  border-right-color: rgb(112 26 117 / 0) !important;
}

.tw-border-r-fuchsia-900\/10 {
  border-right-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-r-fuchsia-900\/100 {
  border-right-color: rgb(112 26 117 / 1) !important;
}

.tw-border-r-fuchsia-900\/15 {
  border-right-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-r-fuchsia-900\/20 {
  border-right-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-r-fuchsia-900\/25 {
  border-right-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-r-fuchsia-900\/30 {
  border-right-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-r-fuchsia-900\/35 {
  border-right-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-r-fuchsia-900\/40 {
  border-right-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-r-fuchsia-900\/45 {
  border-right-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-r-fuchsia-900\/5 {
  border-right-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-r-fuchsia-900\/50 {
  border-right-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-r-fuchsia-900\/55 {
  border-right-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-r-fuchsia-900\/60 {
  border-right-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-r-fuchsia-900\/65 {
  border-right-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-r-fuchsia-900\/70 {
  border-right-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-r-fuchsia-900\/75 {
  border-right-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-r-fuchsia-900\/80 {
  border-right-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-r-fuchsia-900\/85 {
  border-right-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-r-fuchsia-900\/90 {
  border-right-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-r-fuchsia-900\/95 {
  border-right-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-r-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-r-fuchsia-950\/0 {
  border-right-color: rgb(74 4 78 / 0) !important;
}

.tw-border-r-fuchsia-950\/10 {
  border-right-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-r-fuchsia-950\/100 {
  border-right-color: rgb(74 4 78 / 1) !important;
}

.tw-border-r-fuchsia-950\/15 {
  border-right-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-r-fuchsia-950\/20 {
  border-right-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-r-fuchsia-950\/25 {
  border-right-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-r-fuchsia-950\/30 {
  border-right-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-r-fuchsia-950\/35 {
  border-right-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-r-fuchsia-950\/40 {
  border-right-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-r-fuchsia-950\/45 {
  border-right-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-r-fuchsia-950\/5 {
  border-right-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-r-fuchsia-950\/50 {
  border-right-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-r-fuchsia-950\/55 {
  border-right-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-r-fuchsia-950\/60 {
  border-right-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-r-fuchsia-950\/65 {
  border-right-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-r-fuchsia-950\/70 {
  border-right-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-r-fuchsia-950\/75 {
  border-right-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-r-fuchsia-950\/80 {
  border-right-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-r-fuchsia-950\/85 {
  border-right-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-r-fuchsia-950\/90 {
  border-right-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-r-fuchsia-950\/95 {
  border-right-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-r-gray-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-100\/0 {
  border-right-color: rgb(243 244 246 / 0) !important;
}

.tw-border-r-gray-100\/10 {
  border-right-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-r-gray-100\/100 {
  border-right-color: rgb(243 244 246 / 1) !important;
}

.tw-border-r-gray-100\/15 {
  border-right-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-r-gray-100\/20 {
  border-right-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-r-gray-100\/25 {
  border-right-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-r-gray-100\/30 {
  border-right-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-r-gray-100\/35 {
  border-right-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-r-gray-100\/40 {
  border-right-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-r-gray-100\/45 {
  border-right-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-r-gray-100\/5 {
  border-right-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-r-gray-100\/50 {
  border-right-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-r-gray-100\/55 {
  border-right-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-r-gray-100\/60 {
  border-right-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-r-gray-100\/65 {
  border-right-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-r-gray-100\/70 {
  border-right-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-r-gray-100\/75 {
  border-right-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-r-gray-100\/80 {
  border-right-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-r-gray-100\/85 {
  border-right-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-r-gray-100\/90 {
  border-right-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-r-gray-100\/95 {
  border-right-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-r-gray-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-200\/0 {
  border-right-color: rgb(229 231 235 / 0) !important;
}

.tw-border-r-gray-200\/10 {
  border-right-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-r-gray-200\/100 {
  border-right-color: rgb(229 231 235 / 1) !important;
}

.tw-border-r-gray-200\/15 {
  border-right-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-r-gray-200\/20 {
  border-right-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-r-gray-200\/25 {
  border-right-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-r-gray-200\/30 {
  border-right-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-r-gray-200\/35 {
  border-right-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-r-gray-200\/40 {
  border-right-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-r-gray-200\/45 {
  border-right-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-r-gray-200\/5 {
  border-right-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-r-gray-200\/50 {
  border-right-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-r-gray-200\/55 {
  border-right-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-r-gray-200\/60 {
  border-right-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-r-gray-200\/65 {
  border-right-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-r-gray-200\/70 {
  border-right-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-r-gray-200\/75 {
  border-right-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-r-gray-200\/80 {
  border-right-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-r-gray-200\/85 {
  border-right-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-r-gray-200\/90 {
  border-right-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-r-gray-200\/95 {
  border-right-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-r-gray-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-300\/0 {
  border-right-color: rgb(209 213 219 / 0) !important;
}

.tw-border-r-gray-300\/10 {
  border-right-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-r-gray-300\/100 {
  border-right-color: rgb(209 213 219 / 1) !important;
}

.tw-border-r-gray-300\/15 {
  border-right-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-r-gray-300\/20 {
  border-right-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-r-gray-300\/25 {
  border-right-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-r-gray-300\/30 {
  border-right-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-r-gray-300\/35 {
  border-right-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-r-gray-300\/40 {
  border-right-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-r-gray-300\/45 {
  border-right-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-r-gray-300\/5 {
  border-right-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-r-gray-300\/50 {
  border-right-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-r-gray-300\/55 {
  border-right-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-r-gray-300\/60 {
  border-right-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-r-gray-300\/65 {
  border-right-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-r-gray-300\/70 {
  border-right-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-r-gray-300\/75 {
  border-right-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-r-gray-300\/80 {
  border-right-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-r-gray-300\/85 {
  border-right-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-r-gray-300\/90 {
  border-right-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-r-gray-300\/95 {
  border-right-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-r-gray-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-400\/0 {
  border-right-color: rgb(156 163 175 / 0) !important;
}

.tw-border-r-gray-400\/10 {
  border-right-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-r-gray-400\/100 {
  border-right-color: rgb(156 163 175 / 1) !important;
}

.tw-border-r-gray-400\/15 {
  border-right-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-r-gray-400\/20 {
  border-right-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-r-gray-400\/25 {
  border-right-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-r-gray-400\/30 {
  border-right-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-r-gray-400\/35 {
  border-right-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-r-gray-400\/40 {
  border-right-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-r-gray-400\/45 {
  border-right-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-r-gray-400\/5 {
  border-right-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-r-gray-400\/50 {
  border-right-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-r-gray-400\/55 {
  border-right-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-r-gray-400\/60 {
  border-right-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-r-gray-400\/65 {
  border-right-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-r-gray-400\/70 {
  border-right-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-r-gray-400\/75 {
  border-right-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-r-gray-400\/80 {
  border-right-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-r-gray-400\/85 {
  border-right-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-r-gray-400\/90 {
  border-right-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-r-gray-400\/95 {
  border-right-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-r-gray-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-50\/0 {
  border-right-color: rgb(249 250 251 / 0) !important;
}

.tw-border-r-gray-50\/10 {
  border-right-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-r-gray-50\/100 {
  border-right-color: rgb(249 250 251 / 1) !important;
}

.tw-border-r-gray-50\/15 {
  border-right-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-r-gray-50\/20 {
  border-right-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-r-gray-50\/25 {
  border-right-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-r-gray-50\/30 {
  border-right-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-r-gray-50\/35 {
  border-right-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-r-gray-50\/40 {
  border-right-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-r-gray-50\/45 {
  border-right-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-r-gray-50\/5 {
  border-right-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-r-gray-50\/50 {
  border-right-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-r-gray-50\/55 {
  border-right-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-r-gray-50\/60 {
  border-right-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-r-gray-50\/65 {
  border-right-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-r-gray-50\/70 {
  border-right-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-r-gray-50\/75 {
  border-right-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-r-gray-50\/80 {
  border-right-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-r-gray-50\/85 {
  border-right-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-r-gray-50\/90 {
  border-right-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-r-gray-50\/95 {
  border-right-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-r-gray-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-500\/0 {
  border-right-color: rgb(107 114 128 / 0) !important;
}

.tw-border-r-gray-500\/10 {
  border-right-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-r-gray-500\/100 {
  border-right-color: rgb(107 114 128 / 1) !important;
}

.tw-border-r-gray-500\/15 {
  border-right-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-r-gray-500\/20 {
  border-right-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-r-gray-500\/25 {
  border-right-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-r-gray-500\/30 {
  border-right-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-r-gray-500\/35 {
  border-right-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-r-gray-500\/40 {
  border-right-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-r-gray-500\/45 {
  border-right-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-r-gray-500\/5 {
  border-right-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-r-gray-500\/50 {
  border-right-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-r-gray-500\/55 {
  border-right-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-r-gray-500\/60 {
  border-right-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-r-gray-500\/65 {
  border-right-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-r-gray-500\/70 {
  border-right-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-r-gray-500\/75 {
  border-right-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-r-gray-500\/80 {
  border-right-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-r-gray-500\/85 {
  border-right-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-r-gray-500\/90 {
  border-right-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-r-gray-500\/95 {
  border-right-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-r-gray-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-600\/0 {
  border-right-color: rgb(75 85 99 / 0) !important;
}

.tw-border-r-gray-600\/10 {
  border-right-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-r-gray-600\/100 {
  border-right-color: rgb(75 85 99 / 1) !important;
}

.tw-border-r-gray-600\/15 {
  border-right-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-r-gray-600\/20 {
  border-right-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-r-gray-600\/25 {
  border-right-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-r-gray-600\/30 {
  border-right-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-r-gray-600\/35 {
  border-right-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-r-gray-600\/40 {
  border-right-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-r-gray-600\/45 {
  border-right-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-r-gray-600\/5 {
  border-right-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-r-gray-600\/50 {
  border-right-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-r-gray-600\/55 {
  border-right-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-r-gray-600\/60 {
  border-right-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-r-gray-600\/65 {
  border-right-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-r-gray-600\/70 {
  border-right-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-r-gray-600\/75 {
  border-right-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-r-gray-600\/80 {
  border-right-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-r-gray-600\/85 {
  border-right-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-r-gray-600\/90 {
  border-right-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-r-gray-600\/95 {
  border-right-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-r-gray-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-700\/0 {
  border-right-color: rgb(55 65 81 / 0) !important;
}

.tw-border-r-gray-700\/10 {
  border-right-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-r-gray-700\/100 {
  border-right-color: rgb(55 65 81 / 1) !important;
}

.tw-border-r-gray-700\/15 {
  border-right-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-r-gray-700\/20 {
  border-right-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-r-gray-700\/25 {
  border-right-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-r-gray-700\/30 {
  border-right-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-r-gray-700\/35 {
  border-right-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-r-gray-700\/40 {
  border-right-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-r-gray-700\/45 {
  border-right-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-r-gray-700\/5 {
  border-right-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-r-gray-700\/50 {
  border-right-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-r-gray-700\/55 {
  border-right-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-r-gray-700\/60 {
  border-right-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-r-gray-700\/65 {
  border-right-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-r-gray-700\/70 {
  border-right-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-r-gray-700\/75 {
  border-right-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-r-gray-700\/80 {
  border-right-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-r-gray-700\/85 {
  border-right-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-r-gray-700\/90 {
  border-right-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-r-gray-700\/95 {
  border-right-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-r-gray-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-800\/0 {
  border-right-color: rgb(31 41 55 / 0) !important;
}

.tw-border-r-gray-800\/10 {
  border-right-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-r-gray-800\/100 {
  border-right-color: rgb(31 41 55 / 1) !important;
}

.tw-border-r-gray-800\/15 {
  border-right-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-r-gray-800\/20 {
  border-right-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-r-gray-800\/25 {
  border-right-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-r-gray-800\/30 {
  border-right-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-r-gray-800\/35 {
  border-right-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-r-gray-800\/40 {
  border-right-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-r-gray-800\/45 {
  border-right-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-r-gray-800\/5 {
  border-right-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-r-gray-800\/50 {
  border-right-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-r-gray-800\/55 {
  border-right-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-r-gray-800\/60 {
  border-right-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-r-gray-800\/65 {
  border-right-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-r-gray-800\/70 {
  border-right-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-r-gray-800\/75 {
  border-right-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-r-gray-800\/80 {
  border-right-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-r-gray-800\/85 {
  border-right-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-r-gray-800\/90 {
  border-right-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-r-gray-800\/95 {
  border-right-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-r-gray-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-900\/0 {
  border-right-color: rgb(17 24 39 / 0) !important;
}

.tw-border-r-gray-900\/10 {
  border-right-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-r-gray-900\/100 {
  border-right-color: rgb(17 24 39 / 1) !important;
}

.tw-border-r-gray-900\/15 {
  border-right-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-r-gray-900\/20 {
  border-right-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-r-gray-900\/25 {
  border-right-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-r-gray-900\/30 {
  border-right-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-r-gray-900\/35 {
  border-right-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-r-gray-900\/40 {
  border-right-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-r-gray-900\/45 {
  border-right-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-r-gray-900\/5 {
  border-right-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-r-gray-900\/50 {
  border-right-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-r-gray-900\/55 {
  border-right-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-r-gray-900\/60 {
  border-right-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-r-gray-900\/65 {
  border-right-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-r-gray-900\/70 {
  border-right-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-r-gray-900\/75 {
  border-right-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-r-gray-900\/80 {
  border-right-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-r-gray-900\/85 {
  border-right-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-r-gray-900\/90 {
  border-right-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-r-gray-900\/95 {
  border-right-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-r-gray-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-r-gray-950\/0 {
  border-right-color: rgb(3 7 18 / 0) !important;
}

.tw-border-r-gray-950\/10 {
  border-right-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-r-gray-950\/100 {
  border-right-color: rgb(3 7 18 / 1) !important;
}

.tw-border-r-gray-950\/15 {
  border-right-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-r-gray-950\/20 {
  border-right-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-r-gray-950\/25 {
  border-right-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-r-gray-950\/30 {
  border-right-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-r-gray-950\/35 {
  border-right-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-r-gray-950\/40 {
  border-right-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-r-gray-950\/45 {
  border-right-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-r-gray-950\/5 {
  border-right-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-r-gray-950\/50 {
  border-right-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-r-gray-950\/55 {
  border-right-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-r-gray-950\/60 {
  border-right-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-r-gray-950\/65 {
  border-right-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-r-gray-950\/70 {
  border-right-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-r-gray-950\/75 {
  border-right-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-r-gray-950\/80 {
  border-right-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-r-gray-950\/85 {
  border-right-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-r-gray-950\/90 {
  border-right-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-r-gray-950\/95 {
  border-right-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-r-green-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-100\/0 {
  border-right-color: rgb(220 252 231 / 0) !important;
}

.tw-border-r-green-100\/10 {
  border-right-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-r-green-100\/100 {
  border-right-color: rgb(220 252 231 / 1) !important;
}

.tw-border-r-green-100\/15 {
  border-right-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-r-green-100\/20 {
  border-right-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-r-green-100\/25 {
  border-right-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-r-green-100\/30 {
  border-right-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-r-green-100\/35 {
  border-right-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-r-green-100\/40 {
  border-right-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-r-green-100\/45 {
  border-right-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-r-green-100\/5 {
  border-right-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-r-green-100\/50 {
  border-right-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-r-green-100\/55 {
  border-right-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-r-green-100\/60 {
  border-right-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-r-green-100\/65 {
  border-right-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-r-green-100\/70 {
  border-right-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-r-green-100\/75 {
  border-right-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-r-green-100\/80 {
  border-right-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-r-green-100\/85 {
  border-right-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-r-green-100\/90 {
  border-right-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-r-green-100\/95 {
  border-right-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-r-green-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-200\/0 {
  border-right-color: rgb(187 247 208 / 0) !important;
}

.tw-border-r-green-200\/10 {
  border-right-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-r-green-200\/100 {
  border-right-color: rgb(187 247 208 / 1) !important;
}

.tw-border-r-green-200\/15 {
  border-right-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-r-green-200\/20 {
  border-right-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-r-green-200\/25 {
  border-right-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-r-green-200\/30 {
  border-right-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-r-green-200\/35 {
  border-right-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-r-green-200\/40 {
  border-right-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-r-green-200\/45 {
  border-right-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-r-green-200\/5 {
  border-right-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-r-green-200\/50 {
  border-right-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-r-green-200\/55 {
  border-right-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-r-green-200\/60 {
  border-right-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-r-green-200\/65 {
  border-right-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-r-green-200\/70 {
  border-right-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-r-green-200\/75 {
  border-right-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-r-green-200\/80 {
  border-right-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-r-green-200\/85 {
  border-right-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-r-green-200\/90 {
  border-right-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-r-green-200\/95 {
  border-right-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-r-green-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-300\/0 {
  border-right-color: rgb(134 239 172 / 0) !important;
}

.tw-border-r-green-300\/10 {
  border-right-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-r-green-300\/100 {
  border-right-color: rgb(134 239 172 / 1) !important;
}

.tw-border-r-green-300\/15 {
  border-right-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-r-green-300\/20 {
  border-right-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-r-green-300\/25 {
  border-right-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-r-green-300\/30 {
  border-right-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-r-green-300\/35 {
  border-right-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-r-green-300\/40 {
  border-right-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-r-green-300\/45 {
  border-right-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-r-green-300\/5 {
  border-right-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-r-green-300\/50 {
  border-right-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-r-green-300\/55 {
  border-right-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-r-green-300\/60 {
  border-right-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-r-green-300\/65 {
  border-right-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-r-green-300\/70 {
  border-right-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-r-green-300\/75 {
  border-right-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-r-green-300\/80 {
  border-right-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-r-green-300\/85 {
  border-right-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-r-green-300\/90 {
  border-right-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-r-green-300\/95 {
  border-right-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-r-green-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-400\/0 {
  border-right-color: rgb(74 222 128 / 0) !important;
}

.tw-border-r-green-400\/10 {
  border-right-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-r-green-400\/100 {
  border-right-color: rgb(74 222 128 / 1) !important;
}

.tw-border-r-green-400\/15 {
  border-right-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-r-green-400\/20 {
  border-right-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-r-green-400\/25 {
  border-right-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-r-green-400\/30 {
  border-right-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-r-green-400\/35 {
  border-right-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-r-green-400\/40 {
  border-right-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-r-green-400\/45 {
  border-right-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-r-green-400\/5 {
  border-right-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-r-green-400\/50 {
  border-right-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-r-green-400\/55 {
  border-right-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-r-green-400\/60 {
  border-right-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-r-green-400\/65 {
  border-right-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-r-green-400\/70 {
  border-right-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-r-green-400\/75 {
  border-right-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-r-green-400\/80 {
  border-right-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-r-green-400\/85 {
  border-right-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-r-green-400\/90 {
  border-right-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-r-green-400\/95 {
  border-right-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-r-green-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-50\/0 {
  border-right-color: rgb(240 253 244 / 0) !important;
}

.tw-border-r-green-50\/10 {
  border-right-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-r-green-50\/100 {
  border-right-color: rgb(240 253 244 / 1) !important;
}

.tw-border-r-green-50\/15 {
  border-right-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-r-green-50\/20 {
  border-right-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-r-green-50\/25 {
  border-right-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-r-green-50\/30 {
  border-right-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-r-green-50\/35 {
  border-right-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-r-green-50\/40 {
  border-right-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-r-green-50\/45 {
  border-right-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-r-green-50\/5 {
  border-right-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-r-green-50\/50 {
  border-right-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-r-green-50\/55 {
  border-right-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-r-green-50\/60 {
  border-right-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-r-green-50\/65 {
  border-right-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-r-green-50\/70 {
  border-right-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-r-green-50\/75 {
  border-right-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-r-green-50\/80 {
  border-right-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-r-green-50\/85 {
  border-right-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-r-green-50\/90 {
  border-right-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-r-green-50\/95 {
  border-right-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-r-green-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-500\/0 {
  border-right-color: rgb(34 197 94 / 0) !important;
}

.tw-border-r-green-500\/10 {
  border-right-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-r-green-500\/100 {
  border-right-color: rgb(34 197 94 / 1) !important;
}

.tw-border-r-green-500\/15 {
  border-right-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-r-green-500\/20 {
  border-right-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-r-green-500\/25 {
  border-right-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-r-green-500\/30 {
  border-right-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-r-green-500\/35 {
  border-right-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-r-green-500\/40 {
  border-right-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-r-green-500\/45 {
  border-right-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-r-green-500\/5 {
  border-right-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-r-green-500\/50 {
  border-right-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-r-green-500\/55 {
  border-right-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-r-green-500\/60 {
  border-right-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-r-green-500\/65 {
  border-right-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-r-green-500\/70 {
  border-right-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-r-green-500\/75 {
  border-right-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-r-green-500\/80 {
  border-right-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-r-green-500\/85 {
  border-right-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-r-green-500\/90 {
  border-right-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-r-green-500\/95 {
  border-right-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-r-green-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-600\/0 {
  border-right-color: rgb(22 163 74 / 0) !important;
}

.tw-border-r-green-600\/10 {
  border-right-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-r-green-600\/100 {
  border-right-color: rgb(22 163 74 / 1) !important;
}

.tw-border-r-green-600\/15 {
  border-right-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-r-green-600\/20 {
  border-right-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-r-green-600\/25 {
  border-right-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-r-green-600\/30 {
  border-right-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-r-green-600\/35 {
  border-right-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-r-green-600\/40 {
  border-right-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-r-green-600\/45 {
  border-right-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-r-green-600\/5 {
  border-right-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-r-green-600\/50 {
  border-right-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-r-green-600\/55 {
  border-right-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-r-green-600\/60 {
  border-right-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-r-green-600\/65 {
  border-right-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-r-green-600\/70 {
  border-right-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-r-green-600\/75 {
  border-right-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-r-green-600\/80 {
  border-right-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-r-green-600\/85 {
  border-right-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-r-green-600\/90 {
  border-right-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-r-green-600\/95 {
  border-right-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-r-green-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-700\/0 {
  border-right-color: rgb(21 128 61 / 0) !important;
}

.tw-border-r-green-700\/10 {
  border-right-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-r-green-700\/100 {
  border-right-color: rgb(21 128 61 / 1) !important;
}

.tw-border-r-green-700\/15 {
  border-right-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-r-green-700\/20 {
  border-right-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-r-green-700\/25 {
  border-right-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-r-green-700\/30 {
  border-right-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-r-green-700\/35 {
  border-right-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-r-green-700\/40 {
  border-right-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-r-green-700\/45 {
  border-right-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-r-green-700\/5 {
  border-right-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-r-green-700\/50 {
  border-right-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-r-green-700\/55 {
  border-right-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-r-green-700\/60 {
  border-right-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-r-green-700\/65 {
  border-right-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-r-green-700\/70 {
  border-right-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-r-green-700\/75 {
  border-right-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-r-green-700\/80 {
  border-right-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-r-green-700\/85 {
  border-right-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-r-green-700\/90 {
  border-right-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-r-green-700\/95 {
  border-right-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-r-green-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-800\/0 {
  border-right-color: rgb(22 101 52 / 0) !important;
}

.tw-border-r-green-800\/10 {
  border-right-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-r-green-800\/100 {
  border-right-color: rgb(22 101 52 / 1) !important;
}

.tw-border-r-green-800\/15 {
  border-right-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-r-green-800\/20 {
  border-right-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-r-green-800\/25 {
  border-right-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-r-green-800\/30 {
  border-right-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-r-green-800\/35 {
  border-right-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-r-green-800\/40 {
  border-right-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-r-green-800\/45 {
  border-right-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-r-green-800\/5 {
  border-right-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-r-green-800\/50 {
  border-right-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-r-green-800\/55 {
  border-right-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-r-green-800\/60 {
  border-right-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-r-green-800\/65 {
  border-right-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-r-green-800\/70 {
  border-right-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-r-green-800\/75 {
  border-right-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-r-green-800\/80 {
  border-right-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-r-green-800\/85 {
  border-right-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-r-green-800\/90 {
  border-right-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-r-green-800\/95 {
  border-right-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-r-green-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-900\/0 {
  border-right-color: rgb(20 83 45 / 0) !important;
}

.tw-border-r-green-900\/10 {
  border-right-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-r-green-900\/100 {
  border-right-color: rgb(20 83 45 / 1) !important;
}

.tw-border-r-green-900\/15 {
  border-right-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-r-green-900\/20 {
  border-right-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-r-green-900\/25 {
  border-right-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-r-green-900\/30 {
  border-right-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-r-green-900\/35 {
  border-right-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-r-green-900\/40 {
  border-right-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-r-green-900\/45 {
  border-right-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-r-green-900\/5 {
  border-right-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-r-green-900\/50 {
  border-right-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-r-green-900\/55 {
  border-right-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-r-green-900\/60 {
  border-right-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-r-green-900\/65 {
  border-right-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-r-green-900\/70 {
  border-right-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-r-green-900\/75 {
  border-right-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-r-green-900\/80 {
  border-right-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-r-green-900\/85 {
  border-right-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-r-green-900\/90 {
  border-right-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-r-green-900\/95 {
  border-right-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-r-green-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-r-green-950\/0 {
  border-right-color: rgb(5 46 22 / 0) !important;
}

.tw-border-r-green-950\/10 {
  border-right-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-r-green-950\/100 {
  border-right-color: rgb(5 46 22 / 1) !important;
}

.tw-border-r-green-950\/15 {
  border-right-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-r-green-950\/20 {
  border-right-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-r-green-950\/25 {
  border-right-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-r-green-950\/30 {
  border-right-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-r-green-950\/35 {
  border-right-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-r-green-950\/40 {
  border-right-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-r-green-950\/45 {
  border-right-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-r-green-950\/5 {
  border-right-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-r-green-950\/50 {
  border-right-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-r-green-950\/55 {
  border-right-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-r-green-950\/60 {
  border-right-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-r-green-950\/65 {
  border-right-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-r-green-950\/70 {
  border-right-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-r-green-950\/75 {
  border-right-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-r-green-950\/80 {
  border-right-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-r-green-950\/85 {
  border-right-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-r-green-950\/90 {
  border-right-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-r-green-950\/95 {
  border-right-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-r-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-100\/0 {
  border-right-color: rgb(224 231 255 / 0) !important;
}

.tw-border-r-indigo-100\/10 {
  border-right-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-r-indigo-100\/100 {
  border-right-color: rgb(224 231 255 / 1) !important;
}

.tw-border-r-indigo-100\/15 {
  border-right-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-r-indigo-100\/20 {
  border-right-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-r-indigo-100\/25 {
  border-right-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-r-indigo-100\/30 {
  border-right-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-r-indigo-100\/35 {
  border-right-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-r-indigo-100\/40 {
  border-right-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-r-indigo-100\/45 {
  border-right-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-r-indigo-100\/5 {
  border-right-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-r-indigo-100\/50 {
  border-right-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-r-indigo-100\/55 {
  border-right-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-r-indigo-100\/60 {
  border-right-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-r-indigo-100\/65 {
  border-right-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-r-indigo-100\/70 {
  border-right-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-r-indigo-100\/75 {
  border-right-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-r-indigo-100\/80 {
  border-right-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-r-indigo-100\/85 {
  border-right-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-r-indigo-100\/90 {
  border-right-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-r-indigo-100\/95 {
  border-right-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-r-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-200\/0 {
  border-right-color: rgb(199 210 254 / 0) !important;
}

.tw-border-r-indigo-200\/10 {
  border-right-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-r-indigo-200\/100 {
  border-right-color: rgb(199 210 254 / 1) !important;
}

.tw-border-r-indigo-200\/15 {
  border-right-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-r-indigo-200\/20 {
  border-right-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-r-indigo-200\/25 {
  border-right-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-r-indigo-200\/30 {
  border-right-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-r-indigo-200\/35 {
  border-right-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-r-indigo-200\/40 {
  border-right-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-r-indigo-200\/45 {
  border-right-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-r-indigo-200\/5 {
  border-right-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-r-indigo-200\/50 {
  border-right-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-r-indigo-200\/55 {
  border-right-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-r-indigo-200\/60 {
  border-right-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-r-indigo-200\/65 {
  border-right-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-r-indigo-200\/70 {
  border-right-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-r-indigo-200\/75 {
  border-right-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-r-indigo-200\/80 {
  border-right-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-r-indigo-200\/85 {
  border-right-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-r-indigo-200\/90 {
  border-right-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-r-indigo-200\/95 {
  border-right-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-r-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-300\/0 {
  border-right-color: rgb(165 180 252 / 0) !important;
}

.tw-border-r-indigo-300\/10 {
  border-right-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-r-indigo-300\/100 {
  border-right-color: rgb(165 180 252 / 1) !important;
}

.tw-border-r-indigo-300\/15 {
  border-right-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-r-indigo-300\/20 {
  border-right-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-r-indigo-300\/25 {
  border-right-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-r-indigo-300\/30 {
  border-right-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-r-indigo-300\/35 {
  border-right-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-r-indigo-300\/40 {
  border-right-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-r-indigo-300\/45 {
  border-right-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-r-indigo-300\/5 {
  border-right-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-r-indigo-300\/50 {
  border-right-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-r-indigo-300\/55 {
  border-right-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-r-indigo-300\/60 {
  border-right-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-r-indigo-300\/65 {
  border-right-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-r-indigo-300\/70 {
  border-right-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-r-indigo-300\/75 {
  border-right-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-r-indigo-300\/80 {
  border-right-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-r-indigo-300\/85 {
  border-right-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-r-indigo-300\/90 {
  border-right-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-r-indigo-300\/95 {
  border-right-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-r-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-400\/0 {
  border-right-color: rgb(129 140 248 / 0) !important;
}

.tw-border-r-indigo-400\/10 {
  border-right-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-r-indigo-400\/100 {
  border-right-color: rgb(129 140 248 / 1) !important;
}

.tw-border-r-indigo-400\/15 {
  border-right-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-r-indigo-400\/20 {
  border-right-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-r-indigo-400\/25 {
  border-right-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-r-indigo-400\/30 {
  border-right-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-r-indigo-400\/35 {
  border-right-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-r-indigo-400\/40 {
  border-right-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-r-indigo-400\/45 {
  border-right-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-r-indigo-400\/5 {
  border-right-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-r-indigo-400\/50 {
  border-right-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-r-indigo-400\/55 {
  border-right-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-r-indigo-400\/60 {
  border-right-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-r-indigo-400\/65 {
  border-right-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-r-indigo-400\/70 {
  border-right-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-r-indigo-400\/75 {
  border-right-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-r-indigo-400\/80 {
  border-right-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-r-indigo-400\/85 {
  border-right-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-r-indigo-400\/90 {
  border-right-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-r-indigo-400\/95 {
  border-right-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-r-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-50\/0 {
  border-right-color: rgb(238 242 255 / 0) !important;
}

.tw-border-r-indigo-50\/10 {
  border-right-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-r-indigo-50\/100 {
  border-right-color: rgb(238 242 255 / 1) !important;
}

.tw-border-r-indigo-50\/15 {
  border-right-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-r-indigo-50\/20 {
  border-right-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-r-indigo-50\/25 {
  border-right-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-r-indigo-50\/30 {
  border-right-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-r-indigo-50\/35 {
  border-right-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-r-indigo-50\/40 {
  border-right-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-r-indigo-50\/45 {
  border-right-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-r-indigo-50\/5 {
  border-right-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-r-indigo-50\/50 {
  border-right-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-r-indigo-50\/55 {
  border-right-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-r-indigo-50\/60 {
  border-right-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-r-indigo-50\/65 {
  border-right-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-r-indigo-50\/70 {
  border-right-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-r-indigo-50\/75 {
  border-right-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-r-indigo-50\/80 {
  border-right-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-r-indigo-50\/85 {
  border-right-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-r-indigo-50\/90 {
  border-right-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-r-indigo-50\/95 {
  border-right-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-r-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-500\/0 {
  border-right-color: rgb(99 102 241 / 0) !important;
}

.tw-border-r-indigo-500\/10 {
  border-right-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-r-indigo-500\/100 {
  border-right-color: rgb(99 102 241 / 1) !important;
}

.tw-border-r-indigo-500\/15 {
  border-right-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-r-indigo-500\/20 {
  border-right-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-r-indigo-500\/25 {
  border-right-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-r-indigo-500\/30 {
  border-right-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-r-indigo-500\/35 {
  border-right-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-r-indigo-500\/40 {
  border-right-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-r-indigo-500\/45 {
  border-right-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-r-indigo-500\/5 {
  border-right-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-r-indigo-500\/50 {
  border-right-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-r-indigo-500\/55 {
  border-right-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-r-indigo-500\/60 {
  border-right-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-r-indigo-500\/65 {
  border-right-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-r-indigo-500\/70 {
  border-right-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-r-indigo-500\/75 {
  border-right-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-r-indigo-500\/80 {
  border-right-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-r-indigo-500\/85 {
  border-right-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-r-indigo-500\/90 {
  border-right-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-r-indigo-500\/95 {
  border-right-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-r-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-600\/0 {
  border-right-color: rgb(79 70 229 / 0) !important;
}

.tw-border-r-indigo-600\/10 {
  border-right-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-r-indigo-600\/100 {
  border-right-color: rgb(79 70 229 / 1) !important;
}

.tw-border-r-indigo-600\/15 {
  border-right-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-r-indigo-600\/20 {
  border-right-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-r-indigo-600\/25 {
  border-right-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-r-indigo-600\/30 {
  border-right-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-r-indigo-600\/35 {
  border-right-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-r-indigo-600\/40 {
  border-right-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-r-indigo-600\/45 {
  border-right-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-r-indigo-600\/5 {
  border-right-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-r-indigo-600\/50 {
  border-right-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-r-indigo-600\/55 {
  border-right-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-r-indigo-600\/60 {
  border-right-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-r-indigo-600\/65 {
  border-right-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-r-indigo-600\/70 {
  border-right-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-r-indigo-600\/75 {
  border-right-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-r-indigo-600\/80 {
  border-right-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-r-indigo-600\/85 {
  border-right-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-r-indigo-600\/90 {
  border-right-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-r-indigo-600\/95 {
  border-right-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-r-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-700\/0 {
  border-right-color: rgb(67 56 202 / 0) !important;
}

.tw-border-r-indigo-700\/10 {
  border-right-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-r-indigo-700\/100 {
  border-right-color: rgb(67 56 202 / 1) !important;
}

.tw-border-r-indigo-700\/15 {
  border-right-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-r-indigo-700\/20 {
  border-right-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-r-indigo-700\/25 {
  border-right-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-r-indigo-700\/30 {
  border-right-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-r-indigo-700\/35 {
  border-right-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-r-indigo-700\/40 {
  border-right-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-r-indigo-700\/45 {
  border-right-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-r-indigo-700\/5 {
  border-right-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-r-indigo-700\/50 {
  border-right-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-r-indigo-700\/55 {
  border-right-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-r-indigo-700\/60 {
  border-right-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-r-indigo-700\/65 {
  border-right-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-r-indigo-700\/70 {
  border-right-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-r-indigo-700\/75 {
  border-right-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-r-indigo-700\/80 {
  border-right-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-r-indigo-700\/85 {
  border-right-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-r-indigo-700\/90 {
  border-right-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-r-indigo-700\/95 {
  border-right-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-r-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-800\/0 {
  border-right-color: rgb(55 48 163 / 0) !important;
}

.tw-border-r-indigo-800\/10 {
  border-right-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-r-indigo-800\/100 {
  border-right-color: rgb(55 48 163 / 1) !important;
}

.tw-border-r-indigo-800\/15 {
  border-right-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-r-indigo-800\/20 {
  border-right-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-r-indigo-800\/25 {
  border-right-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-r-indigo-800\/30 {
  border-right-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-r-indigo-800\/35 {
  border-right-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-r-indigo-800\/40 {
  border-right-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-r-indigo-800\/45 {
  border-right-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-r-indigo-800\/5 {
  border-right-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-r-indigo-800\/50 {
  border-right-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-r-indigo-800\/55 {
  border-right-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-r-indigo-800\/60 {
  border-right-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-r-indigo-800\/65 {
  border-right-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-r-indigo-800\/70 {
  border-right-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-r-indigo-800\/75 {
  border-right-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-r-indigo-800\/80 {
  border-right-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-r-indigo-800\/85 {
  border-right-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-r-indigo-800\/90 {
  border-right-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-r-indigo-800\/95 {
  border-right-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-r-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-900\/0 {
  border-right-color: rgb(49 46 129 / 0) !important;
}

.tw-border-r-indigo-900\/10 {
  border-right-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-r-indigo-900\/100 {
  border-right-color: rgb(49 46 129 / 1) !important;
}

.tw-border-r-indigo-900\/15 {
  border-right-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-r-indigo-900\/20 {
  border-right-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-r-indigo-900\/25 {
  border-right-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-r-indigo-900\/30 {
  border-right-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-r-indigo-900\/35 {
  border-right-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-r-indigo-900\/40 {
  border-right-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-r-indigo-900\/45 {
  border-right-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-r-indigo-900\/5 {
  border-right-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-r-indigo-900\/50 {
  border-right-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-r-indigo-900\/55 {
  border-right-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-r-indigo-900\/60 {
  border-right-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-r-indigo-900\/65 {
  border-right-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-r-indigo-900\/70 {
  border-right-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-r-indigo-900\/75 {
  border-right-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-r-indigo-900\/80 {
  border-right-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-r-indigo-900\/85 {
  border-right-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-r-indigo-900\/90 {
  border-right-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-r-indigo-900\/95 {
  border-right-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-r-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-r-indigo-950\/0 {
  border-right-color: rgb(30 27 75 / 0) !important;
}

.tw-border-r-indigo-950\/10 {
  border-right-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-r-indigo-950\/100 {
  border-right-color: rgb(30 27 75 / 1) !important;
}

.tw-border-r-indigo-950\/15 {
  border-right-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-r-indigo-950\/20 {
  border-right-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-r-indigo-950\/25 {
  border-right-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-r-indigo-950\/30 {
  border-right-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-r-indigo-950\/35 {
  border-right-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-r-indigo-950\/40 {
  border-right-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-r-indigo-950\/45 {
  border-right-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-r-indigo-950\/5 {
  border-right-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-r-indigo-950\/50 {
  border-right-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-r-indigo-950\/55 {
  border-right-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-r-indigo-950\/60 {
  border-right-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-r-indigo-950\/65 {
  border-right-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-r-indigo-950\/70 {
  border-right-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-r-indigo-950\/75 {
  border-right-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-r-indigo-950\/80 {
  border-right-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-r-indigo-950\/85 {
  border-right-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-r-indigo-950\/90 {
  border-right-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-r-indigo-950\/95 {
  border-right-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-r-inherit {
  border-right-color: inherit !important;
}

.tw-border-r-lime-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-100\/0 {
  border-right-color: rgb(236 252 203 / 0) !important;
}

.tw-border-r-lime-100\/10 {
  border-right-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-r-lime-100\/100 {
  border-right-color: rgb(236 252 203 / 1) !important;
}

.tw-border-r-lime-100\/15 {
  border-right-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-r-lime-100\/20 {
  border-right-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-r-lime-100\/25 {
  border-right-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-r-lime-100\/30 {
  border-right-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-r-lime-100\/35 {
  border-right-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-r-lime-100\/40 {
  border-right-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-r-lime-100\/45 {
  border-right-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-r-lime-100\/5 {
  border-right-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-r-lime-100\/50 {
  border-right-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-r-lime-100\/55 {
  border-right-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-r-lime-100\/60 {
  border-right-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-r-lime-100\/65 {
  border-right-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-r-lime-100\/70 {
  border-right-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-r-lime-100\/75 {
  border-right-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-r-lime-100\/80 {
  border-right-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-r-lime-100\/85 {
  border-right-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-r-lime-100\/90 {
  border-right-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-r-lime-100\/95 {
  border-right-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-r-lime-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-200\/0 {
  border-right-color: rgb(217 249 157 / 0) !important;
}

.tw-border-r-lime-200\/10 {
  border-right-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-r-lime-200\/100 {
  border-right-color: rgb(217 249 157 / 1) !important;
}

.tw-border-r-lime-200\/15 {
  border-right-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-r-lime-200\/20 {
  border-right-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-r-lime-200\/25 {
  border-right-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-r-lime-200\/30 {
  border-right-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-r-lime-200\/35 {
  border-right-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-r-lime-200\/40 {
  border-right-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-r-lime-200\/45 {
  border-right-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-r-lime-200\/5 {
  border-right-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-r-lime-200\/50 {
  border-right-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-r-lime-200\/55 {
  border-right-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-r-lime-200\/60 {
  border-right-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-r-lime-200\/65 {
  border-right-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-r-lime-200\/70 {
  border-right-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-r-lime-200\/75 {
  border-right-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-r-lime-200\/80 {
  border-right-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-r-lime-200\/85 {
  border-right-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-r-lime-200\/90 {
  border-right-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-r-lime-200\/95 {
  border-right-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-r-lime-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-300\/0 {
  border-right-color: rgb(190 242 100 / 0) !important;
}

.tw-border-r-lime-300\/10 {
  border-right-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-r-lime-300\/100 {
  border-right-color: rgb(190 242 100 / 1) !important;
}

.tw-border-r-lime-300\/15 {
  border-right-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-r-lime-300\/20 {
  border-right-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-r-lime-300\/25 {
  border-right-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-r-lime-300\/30 {
  border-right-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-r-lime-300\/35 {
  border-right-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-r-lime-300\/40 {
  border-right-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-r-lime-300\/45 {
  border-right-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-r-lime-300\/5 {
  border-right-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-r-lime-300\/50 {
  border-right-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-r-lime-300\/55 {
  border-right-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-r-lime-300\/60 {
  border-right-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-r-lime-300\/65 {
  border-right-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-r-lime-300\/70 {
  border-right-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-r-lime-300\/75 {
  border-right-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-r-lime-300\/80 {
  border-right-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-r-lime-300\/85 {
  border-right-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-r-lime-300\/90 {
  border-right-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-r-lime-300\/95 {
  border-right-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-r-lime-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-400\/0 {
  border-right-color: rgb(163 230 53 / 0) !important;
}

.tw-border-r-lime-400\/10 {
  border-right-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-r-lime-400\/100 {
  border-right-color: rgb(163 230 53 / 1) !important;
}

.tw-border-r-lime-400\/15 {
  border-right-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-r-lime-400\/20 {
  border-right-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-r-lime-400\/25 {
  border-right-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-r-lime-400\/30 {
  border-right-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-r-lime-400\/35 {
  border-right-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-r-lime-400\/40 {
  border-right-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-r-lime-400\/45 {
  border-right-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-r-lime-400\/5 {
  border-right-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-r-lime-400\/50 {
  border-right-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-r-lime-400\/55 {
  border-right-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-r-lime-400\/60 {
  border-right-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-r-lime-400\/65 {
  border-right-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-r-lime-400\/70 {
  border-right-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-r-lime-400\/75 {
  border-right-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-r-lime-400\/80 {
  border-right-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-r-lime-400\/85 {
  border-right-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-r-lime-400\/90 {
  border-right-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-r-lime-400\/95 {
  border-right-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-r-lime-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-50\/0 {
  border-right-color: rgb(247 254 231 / 0) !important;
}

.tw-border-r-lime-50\/10 {
  border-right-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-r-lime-50\/100 {
  border-right-color: rgb(247 254 231 / 1) !important;
}

.tw-border-r-lime-50\/15 {
  border-right-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-r-lime-50\/20 {
  border-right-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-r-lime-50\/25 {
  border-right-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-r-lime-50\/30 {
  border-right-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-r-lime-50\/35 {
  border-right-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-r-lime-50\/40 {
  border-right-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-r-lime-50\/45 {
  border-right-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-r-lime-50\/5 {
  border-right-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-r-lime-50\/50 {
  border-right-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-r-lime-50\/55 {
  border-right-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-r-lime-50\/60 {
  border-right-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-r-lime-50\/65 {
  border-right-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-r-lime-50\/70 {
  border-right-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-r-lime-50\/75 {
  border-right-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-r-lime-50\/80 {
  border-right-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-r-lime-50\/85 {
  border-right-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-r-lime-50\/90 {
  border-right-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-r-lime-50\/95 {
  border-right-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-r-lime-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-500\/0 {
  border-right-color: rgb(132 204 22 / 0) !important;
}

.tw-border-r-lime-500\/10 {
  border-right-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-r-lime-500\/100 {
  border-right-color: rgb(132 204 22 / 1) !important;
}

.tw-border-r-lime-500\/15 {
  border-right-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-r-lime-500\/20 {
  border-right-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-r-lime-500\/25 {
  border-right-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-r-lime-500\/30 {
  border-right-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-r-lime-500\/35 {
  border-right-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-r-lime-500\/40 {
  border-right-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-r-lime-500\/45 {
  border-right-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-r-lime-500\/5 {
  border-right-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-r-lime-500\/50 {
  border-right-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-r-lime-500\/55 {
  border-right-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-r-lime-500\/60 {
  border-right-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-r-lime-500\/65 {
  border-right-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-r-lime-500\/70 {
  border-right-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-r-lime-500\/75 {
  border-right-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-r-lime-500\/80 {
  border-right-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-r-lime-500\/85 {
  border-right-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-r-lime-500\/90 {
  border-right-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-r-lime-500\/95 {
  border-right-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-r-lime-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-600\/0 {
  border-right-color: rgb(101 163 13 / 0) !important;
}

.tw-border-r-lime-600\/10 {
  border-right-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-r-lime-600\/100 {
  border-right-color: rgb(101 163 13 / 1) !important;
}

.tw-border-r-lime-600\/15 {
  border-right-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-r-lime-600\/20 {
  border-right-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-r-lime-600\/25 {
  border-right-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-r-lime-600\/30 {
  border-right-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-r-lime-600\/35 {
  border-right-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-r-lime-600\/40 {
  border-right-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-r-lime-600\/45 {
  border-right-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-r-lime-600\/5 {
  border-right-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-r-lime-600\/50 {
  border-right-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-r-lime-600\/55 {
  border-right-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-r-lime-600\/60 {
  border-right-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-r-lime-600\/65 {
  border-right-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-r-lime-600\/70 {
  border-right-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-r-lime-600\/75 {
  border-right-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-r-lime-600\/80 {
  border-right-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-r-lime-600\/85 {
  border-right-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-r-lime-600\/90 {
  border-right-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-r-lime-600\/95 {
  border-right-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-r-lime-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-700\/0 {
  border-right-color: rgb(77 124 15 / 0) !important;
}

.tw-border-r-lime-700\/10 {
  border-right-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-r-lime-700\/100 {
  border-right-color: rgb(77 124 15 / 1) !important;
}

.tw-border-r-lime-700\/15 {
  border-right-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-r-lime-700\/20 {
  border-right-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-r-lime-700\/25 {
  border-right-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-r-lime-700\/30 {
  border-right-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-r-lime-700\/35 {
  border-right-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-r-lime-700\/40 {
  border-right-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-r-lime-700\/45 {
  border-right-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-r-lime-700\/5 {
  border-right-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-r-lime-700\/50 {
  border-right-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-r-lime-700\/55 {
  border-right-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-r-lime-700\/60 {
  border-right-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-r-lime-700\/65 {
  border-right-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-r-lime-700\/70 {
  border-right-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-r-lime-700\/75 {
  border-right-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-r-lime-700\/80 {
  border-right-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-r-lime-700\/85 {
  border-right-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-r-lime-700\/90 {
  border-right-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-r-lime-700\/95 {
  border-right-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-r-lime-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-800\/0 {
  border-right-color: rgb(63 98 18 / 0) !important;
}

.tw-border-r-lime-800\/10 {
  border-right-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-r-lime-800\/100 {
  border-right-color: rgb(63 98 18 / 1) !important;
}

.tw-border-r-lime-800\/15 {
  border-right-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-r-lime-800\/20 {
  border-right-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-r-lime-800\/25 {
  border-right-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-r-lime-800\/30 {
  border-right-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-r-lime-800\/35 {
  border-right-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-r-lime-800\/40 {
  border-right-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-r-lime-800\/45 {
  border-right-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-r-lime-800\/5 {
  border-right-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-r-lime-800\/50 {
  border-right-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-r-lime-800\/55 {
  border-right-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-r-lime-800\/60 {
  border-right-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-r-lime-800\/65 {
  border-right-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-r-lime-800\/70 {
  border-right-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-r-lime-800\/75 {
  border-right-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-r-lime-800\/80 {
  border-right-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-r-lime-800\/85 {
  border-right-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-r-lime-800\/90 {
  border-right-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-r-lime-800\/95 {
  border-right-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-r-lime-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-900\/0 {
  border-right-color: rgb(54 83 20 / 0) !important;
}

.tw-border-r-lime-900\/10 {
  border-right-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-r-lime-900\/100 {
  border-right-color: rgb(54 83 20 / 1) !important;
}

.tw-border-r-lime-900\/15 {
  border-right-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-r-lime-900\/20 {
  border-right-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-r-lime-900\/25 {
  border-right-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-r-lime-900\/30 {
  border-right-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-r-lime-900\/35 {
  border-right-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-r-lime-900\/40 {
  border-right-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-r-lime-900\/45 {
  border-right-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-r-lime-900\/5 {
  border-right-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-r-lime-900\/50 {
  border-right-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-r-lime-900\/55 {
  border-right-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-r-lime-900\/60 {
  border-right-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-r-lime-900\/65 {
  border-right-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-r-lime-900\/70 {
  border-right-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-r-lime-900\/75 {
  border-right-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-r-lime-900\/80 {
  border-right-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-r-lime-900\/85 {
  border-right-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-r-lime-900\/90 {
  border-right-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-r-lime-900\/95 {
  border-right-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-r-lime-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-r-lime-950\/0 {
  border-right-color: rgb(26 46 5 / 0) !important;
}

.tw-border-r-lime-950\/10 {
  border-right-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-r-lime-950\/100 {
  border-right-color: rgb(26 46 5 / 1) !important;
}

.tw-border-r-lime-950\/15 {
  border-right-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-r-lime-950\/20 {
  border-right-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-r-lime-950\/25 {
  border-right-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-r-lime-950\/30 {
  border-right-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-r-lime-950\/35 {
  border-right-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-r-lime-950\/40 {
  border-right-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-r-lime-950\/45 {
  border-right-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-r-lime-950\/5 {
  border-right-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-r-lime-950\/50 {
  border-right-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-r-lime-950\/55 {
  border-right-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-r-lime-950\/60 {
  border-right-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-r-lime-950\/65 {
  border-right-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-r-lime-950\/70 {
  border-right-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-r-lime-950\/75 {
  border-right-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-r-lime-950\/80 {
  border-right-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-r-lime-950\/85 {
  border-right-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-r-lime-950\/90 {
  border-right-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-r-lime-950\/95 {
  border-right-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-r-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-100\/0 {
  border-right-color: rgb(245 245 245 / 0) !important;
}

.tw-border-r-neutral-100\/10 {
  border-right-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-r-neutral-100\/100 {
  border-right-color: rgb(245 245 245 / 1) !important;
}

.tw-border-r-neutral-100\/15 {
  border-right-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-r-neutral-100\/20 {
  border-right-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-r-neutral-100\/25 {
  border-right-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-r-neutral-100\/30 {
  border-right-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-r-neutral-100\/35 {
  border-right-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-r-neutral-100\/40 {
  border-right-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-r-neutral-100\/45 {
  border-right-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-r-neutral-100\/5 {
  border-right-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-r-neutral-100\/50 {
  border-right-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-r-neutral-100\/55 {
  border-right-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-r-neutral-100\/60 {
  border-right-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-r-neutral-100\/65 {
  border-right-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-r-neutral-100\/70 {
  border-right-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-r-neutral-100\/75 {
  border-right-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-r-neutral-100\/80 {
  border-right-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-r-neutral-100\/85 {
  border-right-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-r-neutral-100\/90 {
  border-right-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-r-neutral-100\/95 {
  border-right-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-r-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-200\/0 {
  border-right-color: rgb(229 229 229 / 0) !important;
}

.tw-border-r-neutral-200\/10 {
  border-right-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-r-neutral-200\/100 {
  border-right-color: rgb(229 229 229 / 1) !important;
}

.tw-border-r-neutral-200\/15 {
  border-right-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-r-neutral-200\/20 {
  border-right-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-r-neutral-200\/25 {
  border-right-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-r-neutral-200\/30 {
  border-right-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-r-neutral-200\/35 {
  border-right-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-r-neutral-200\/40 {
  border-right-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-r-neutral-200\/45 {
  border-right-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-r-neutral-200\/5 {
  border-right-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-r-neutral-200\/50 {
  border-right-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-r-neutral-200\/55 {
  border-right-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-r-neutral-200\/60 {
  border-right-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-r-neutral-200\/65 {
  border-right-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-r-neutral-200\/70 {
  border-right-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-r-neutral-200\/75 {
  border-right-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-r-neutral-200\/80 {
  border-right-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-r-neutral-200\/85 {
  border-right-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-r-neutral-200\/90 {
  border-right-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-r-neutral-200\/95 {
  border-right-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-r-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-300\/0 {
  border-right-color: rgb(212 212 212 / 0) !important;
}

.tw-border-r-neutral-300\/10 {
  border-right-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-r-neutral-300\/100 {
  border-right-color: rgb(212 212 212 / 1) !important;
}

.tw-border-r-neutral-300\/15 {
  border-right-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-r-neutral-300\/20 {
  border-right-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-r-neutral-300\/25 {
  border-right-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-r-neutral-300\/30 {
  border-right-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-r-neutral-300\/35 {
  border-right-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-r-neutral-300\/40 {
  border-right-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-r-neutral-300\/45 {
  border-right-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-r-neutral-300\/5 {
  border-right-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-r-neutral-300\/50 {
  border-right-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-r-neutral-300\/55 {
  border-right-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-r-neutral-300\/60 {
  border-right-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-r-neutral-300\/65 {
  border-right-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-r-neutral-300\/70 {
  border-right-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-r-neutral-300\/75 {
  border-right-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-r-neutral-300\/80 {
  border-right-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-r-neutral-300\/85 {
  border-right-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-r-neutral-300\/90 {
  border-right-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-r-neutral-300\/95 {
  border-right-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-r-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-400\/0 {
  border-right-color: rgb(163 163 163 / 0) !important;
}

.tw-border-r-neutral-400\/10 {
  border-right-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-r-neutral-400\/100 {
  border-right-color: rgb(163 163 163 / 1) !important;
}

.tw-border-r-neutral-400\/15 {
  border-right-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-r-neutral-400\/20 {
  border-right-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-r-neutral-400\/25 {
  border-right-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-r-neutral-400\/30 {
  border-right-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-r-neutral-400\/35 {
  border-right-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-r-neutral-400\/40 {
  border-right-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-r-neutral-400\/45 {
  border-right-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-r-neutral-400\/5 {
  border-right-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-r-neutral-400\/50 {
  border-right-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-r-neutral-400\/55 {
  border-right-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-r-neutral-400\/60 {
  border-right-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-r-neutral-400\/65 {
  border-right-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-r-neutral-400\/70 {
  border-right-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-r-neutral-400\/75 {
  border-right-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-r-neutral-400\/80 {
  border-right-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-r-neutral-400\/85 {
  border-right-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-r-neutral-400\/90 {
  border-right-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-r-neutral-400\/95 {
  border-right-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-r-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-50\/0 {
  border-right-color: rgb(250 250 250 / 0) !important;
}

.tw-border-r-neutral-50\/10 {
  border-right-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-r-neutral-50\/100 {
  border-right-color: rgb(250 250 250 / 1) !important;
}

.tw-border-r-neutral-50\/15 {
  border-right-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-r-neutral-50\/20 {
  border-right-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-r-neutral-50\/25 {
  border-right-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-r-neutral-50\/30 {
  border-right-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-r-neutral-50\/35 {
  border-right-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-r-neutral-50\/40 {
  border-right-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-r-neutral-50\/45 {
  border-right-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-r-neutral-50\/5 {
  border-right-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-r-neutral-50\/50 {
  border-right-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-r-neutral-50\/55 {
  border-right-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-r-neutral-50\/60 {
  border-right-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-r-neutral-50\/65 {
  border-right-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-r-neutral-50\/70 {
  border-right-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-r-neutral-50\/75 {
  border-right-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-r-neutral-50\/80 {
  border-right-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-r-neutral-50\/85 {
  border-right-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-r-neutral-50\/90 {
  border-right-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-r-neutral-50\/95 {
  border-right-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-r-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-500\/0 {
  border-right-color: rgb(115 115 115 / 0) !important;
}

.tw-border-r-neutral-500\/10 {
  border-right-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-r-neutral-500\/100 {
  border-right-color: rgb(115 115 115 / 1) !important;
}

.tw-border-r-neutral-500\/15 {
  border-right-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-r-neutral-500\/20 {
  border-right-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-r-neutral-500\/25 {
  border-right-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-r-neutral-500\/30 {
  border-right-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-r-neutral-500\/35 {
  border-right-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-r-neutral-500\/40 {
  border-right-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-r-neutral-500\/45 {
  border-right-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-r-neutral-500\/5 {
  border-right-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-r-neutral-500\/50 {
  border-right-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-r-neutral-500\/55 {
  border-right-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-r-neutral-500\/60 {
  border-right-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-r-neutral-500\/65 {
  border-right-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-r-neutral-500\/70 {
  border-right-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-r-neutral-500\/75 {
  border-right-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-r-neutral-500\/80 {
  border-right-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-r-neutral-500\/85 {
  border-right-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-r-neutral-500\/90 {
  border-right-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-r-neutral-500\/95 {
  border-right-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-r-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-600\/0 {
  border-right-color: rgb(82 82 82 / 0) !important;
}

.tw-border-r-neutral-600\/10 {
  border-right-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-r-neutral-600\/100 {
  border-right-color: rgb(82 82 82 / 1) !important;
}

.tw-border-r-neutral-600\/15 {
  border-right-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-r-neutral-600\/20 {
  border-right-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-r-neutral-600\/25 {
  border-right-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-r-neutral-600\/30 {
  border-right-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-r-neutral-600\/35 {
  border-right-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-r-neutral-600\/40 {
  border-right-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-r-neutral-600\/45 {
  border-right-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-r-neutral-600\/5 {
  border-right-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-r-neutral-600\/50 {
  border-right-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-r-neutral-600\/55 {
  border-right-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-r-neutral-600\/60 {
  border-right-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-r-neutral-600\/65 {
  border-right-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-r-neutral-600\/70 {
  border-right-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-r-neutral-600\/75 {
  border-right-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-r-neutral-600\/80 {
  border-right-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-r-neutral-600\/85 {
  border-right-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-r-neutral-600\/90 {
  border-right-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-r-neutral-600\/95 {
  border-right-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-r-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-700\/0 {
  border-right-color: rgb(64 64 64 / 0) !important;
}

.tw-border-r-neutral-700\/10 {
  border-right-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-r-neutral-700\/100 {
  border-right-color: rgb(64 64 64 / 1) !important;
}

.tw-border-r-neutral-700\/15 {
  border-right-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-r-neutral-700\/20 {
  border-right-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-r-neutral-700\/25 {
  border-right-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-r-neutral-700\/30 {
  border-right-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-r-neutral-700\/35 {
  border-right-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-r-neutral-700\/40 {
  border-right-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-r-neutral-700\/45 {
  border-right-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-r-neutral-700\/5 {
  border-right-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-r-neutral-700\/50 {
  border-right-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-r-neutral-700\/55 {
  border-right-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-r-neutral-700\/60 {
  border-right-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-r-neutral-700\/65 {
  border-right-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-r-neutral-700\/70 {
  border-right-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-r-neutral-700\/75 {
  border-right-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-r-neutral-700\/80 {
  border-right-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-r-neutral-700\/85 {
  border-right-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-r-neutral-700\/90 {
  border-right-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-r-neutral-700\/95 {
  border-right-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-r-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-800\/0 {
  border-right-color: rgb(38 38 38 / 0) !important;
}

.tw-border-r-neutral-800\/10 {
  border-right-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-r-neutral-800\/100 {
  border-right-color: rgb(38 38 38 / 1) !important;
}

.tw-border-r-neutral-800\/15 {
  border-right-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-r-neutral-800\/20 {
  border-right-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-r-neutral-800\/25 {
  border-right-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-r-neutral-800\/30 {
  border-right-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-r-neutral-800\/35 {
  border-right-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-r-neutral-800\/40 {
  border-right-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-r-neutral-800\/45 {
  border-right-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-r-neutral-800\/5 {
  border-right-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-r-neutral-800\/50 {
  border-right-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-r-neutral-800\/55 {
  border-right-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-r-neutral-800\/60 {
  border-right-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-r-neutral-800\/65 {
  border-right-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-r-neutral-800\/70 {
  border-right-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-r-neutral-800\/75 {
  border-right-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-r-neutral-800\/80 {
  border-right-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-r-neutral-800\/85 {
  border-right-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-r-neutral-800\/90 {
  border-right-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-r-neutral-800\/95 {
  border-right-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-r-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-900\/0 {
  border-right-color: rgb(23 23 23 / 0) !important;
}

.tw-border-r-neutral-900\/10 {
  border-right-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-r-neutral-900\/100 {
  border-right-color: rgb(23 23 23 / 1) !important;
}

.tw-border-r-neutral-900\/15 {
  border-right-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-r-neutral-900\/20 {
  border-right-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-r-neutral-900\/25 {
  border-right-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-r-neutral-900\/30 {
  border-right-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-r-neutral-900\/35 {
  border-right-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-r-neutral-900\/40 {
  border-right-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-r-neutral-900\/45 {
  border-right-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-r-neutral-900\/5 {
  border-right-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-r-neutral-900\/50 {
  border-right-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-r-neutral-900\/55 {
  border-right-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-r-neutral-900\/60 {
  border-right-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-r-neutral-900\/65 {
  border-right-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-r-neutral-900\/70 {
  border-right-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-r-neutral-900\/75 {
  border-right-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-r-neutral-900\/80 {
  border-right-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-r-neutral-900\/85 {
  border-right-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-r-neutral-900\/90 {
  border-right-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-r-neutral-900\/95 {
  border-right-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-r-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-r-neutral-950\/0 {
  border-right-color: rgb(10 10 10 / 0) !important;
}

.tw-border-r-neutral-950\/10 {
  border-right-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-r-neutral-950\/100 {
  border-right-color: rgb(10 10 10 / 1) !important;
}

.tw-border-r-neutral-950\/15 {
  border-right-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-r-neutral-950\/20 {
  border-right-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-r-neutral-950\/25 {
  border-right-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-r-neutral-950\/30 {
  border-right-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-r-neutral-950\/35 {
  border-right-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-r-neutral-950\/40 {
  border-right-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-r-neutral-950\/45 {
  border-right-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-r-neutral-950\/5 {
  border-right-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-r-neutral-950\/50 {
  border-right-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-r-neutral-950\/55 {
  border-right-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-r-neutral-950\/60 {
  border-right-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-r-neutral-950\/65 {
  border-right-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-r-neutral-950\/70 {
  border-right-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-r-neutral-950\/75 {
  border-right-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-r-neutral-950\/80 {
  border-right-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-r-neutral-950\/85 {
  border-right-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-r-neutral-950\/90 {
  border-right-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-r-neutral-950\/95 {
  border-right-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-r-orange-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-100\/0 {
  border-right-color: rgb(255 237 213 / 0) !important;
}

.tw-border-r-orange-100\/10 {
  border-right-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-r-orange-100\/100 {
  border-right-color: rgb(255 237 213 / 1) !important;
}

.tw-border-r-orange-100\/15 {
  border-right-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-r-orange-100\/20 {
  border-right-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-r-orange-100\/25 {
  border-right-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-r-orange-100\/30 {
  border-right-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-r-orange-100\/35 {
  border-right-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-r-orange-100\/40 {
  border-right-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-r-orange-100\/45 {
  border-right-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-r-orange-100\/5 {
  border-right-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-r-orange-100\/50 {
  border-right-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-r-orange-100\/55 {
  border-right-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-r-orange-100\/60 {
  border-right-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-r-orange-100\/65 {
  border-right-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-r-orange-100\/70 {
  border-right-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-r-orange-100\/75 {
  border-right-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-r-orange-100\/80 {
  border-right-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-r-orange-100\/85 {
  border-right-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-r-orange-100\/90 {
  border-right-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-r-orange-100\/95 {
  border-right-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-r-orange-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-200\/0 {
  border-right-color: rgb(254 215 170 / 0) !important;
}

.tw-border-r-orange-200\/10 {
  border-right-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-r-orange-200\/100 {
  border-right-color: rgb(254 215 170 / 1) !important;
}

.tw-border-r-orange-200\/15 {
  border-right-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-r-orange-200\/20 {
  border-right-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-r-orange-200\/25 {
  border-right-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-r-orange-200\/30 {
  border-right-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-r-orange-200\/35 {
  border-right-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-r-orange-200\/40 {
  border-right-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-r-orange-200\/45 {
  border-right-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-r-orange-200\/5 {
  border-right-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-r-orange-200\/50 {
  border-right-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-r-orange-200\/55 {
  border-right-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-r-orange-200\/60 {
  border-right-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-r-orange-200\/65 {
  border-right-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-r-orange-200\/70 {
  border-right-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-r-orange-200\/75 {
  border-right-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-r-orange-200\/80 {
  border-right-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-r-orange-200\/85 {
  border-right-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-r-orange-200\/90 {
  border-right-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-r-orange-200\/95 {
  border-right-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-r-orange-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-300\/0 {
  border-right-color: rgb(253 186 116 / 0) !important;
}

.tw-border-r-orange-300\/10 {
  border-right-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-r-orange-300\/100 {
  border-right-color: rgb(253 186 116 / 1) !important;
}

.tw-border-r-orange-300\/15 {
  border-right-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-r-orange-300\/20 {
  border-right-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-r-orange-300\/25 {
  border-right-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-r-orange-300\/30 {
  border-right-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-r-orange-300\/35 {
  border-right-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-r-orange-300\/40 {
  border-right-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-r-orange-300\/45 {
  border-right-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-r-orange-300\/5 {
  border-right-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-r-orange-300\/50 {
  border-right-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-r-orange-300\/55 {
  border-right-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-r-orange-300\/60 {
  border-right-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-r-orange-300\/65 {
  border-right-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-r-orange-300\/70 {
  border-right-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-r-orange-300\/75 {
  border-right-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-r-orange-300\/80 {
  border-right-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-r-orange-300\/85 {
  border-right-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-r-orange-300\/90 {
  border-right-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-r-orange-300\/95 {
  border-right-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-r-orange-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-400\/0 {
  border-right-color: rgb(251 146 60 / 0) !important;
}

.tw-border-r-orange-400\/10 {
  border-right-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-r-orange-400\/100 {
  border-right-color: rgb(251 146 60 / 1) !important;
}

.tw-border-r-orange-400\/15 {
  border-right-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-r-orange-400\/20 {
  border-right-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-r-orange-400\/25 {
  border-right-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-r-orange-400\/30 {
  border-right-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-r-orange-400\/35 {
  border-right-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-r-orange-400\/40 {
  border-right-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-r-orange-400\/45 {
  border-right-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-r-orange-400\/5 {
  border-right-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-r-orange-400\/50 {
  border-right-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-r-orange-400\/55 {
  border-right-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-r-orange-400\/60 {
  border-right-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-r-orange-400\/65 {
  border-right-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-r-orange-400\/70 {
  border-right-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-r-orange-400\/75 {
  border-right-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-r-orange-400\/80 {
  border-right-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-r-orange-400\/85 {
  border-right-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-r-orange-400\/90 {
  border-right-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-r-orange-400\/95 {
  border-right-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-r-orange-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-50\/0 {
  border-right-color: rgb(255 247 237 / 0) !important;
}

.tw-border-r-orange-50\/10 {
  border-right-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-r-orange-50\/100 {
  border-right-color: rgb(255 247 237 / 1) !important;
}

.tw-border-r-orange-50\/15 {
  border-right-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-r-orange-50\/20 {
  border-right-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-r-orange-50\/25 {
  border-right-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-r-orange-50\/30 {
  border-right-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-r-orange-50\/35 {
  border-right-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-r-orange-50\/40 {
  border-right-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-r-orange-50\/45 {
  border-right-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-r-orange-50\/5 {
  border-right-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-r-orange-50\/50 {
  border-right-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-r-orange-50\/55 {
  border-right-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-r-orange-50\/60 {
  border-right-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-r-orange-50\/65 {
  border-right-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-r-orange-50\/70 {
  border-right-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-r-orange-50\/75 {
  border-right-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-r-orange-50\/80 {
  border-right-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-r-orange-50\/85 {
  border-right-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-r-orange-50\/90 {
  border-right-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-r-orange-50\/95 {
  border-right-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-r-orange-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-500\/0 {
  border-right-color: rgb(249 115 22 / 0) !important;
}

.tw-border-r-orange-500\/10 {
  border-right-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-r-orange-500\/100 {
  border-right-color: rgb(249 115 22 / 1) !important;
}

.tw-border-r-orange-500\/15 {
  border-right-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-r-orange-500\/20 {
  border-right-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-r-orange-500\/25 {
  border-right-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-r-orange-500\/30 {
  border-right-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-r-orange-500\/35 {
  border-right-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-r-orange-500\/40 {
  border-right-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-r-orange-500\/45 {
  border-right-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-r-orange-500\/5 {
  border-right-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-r-orange-500\/50 {
  border-right-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-r-orange-500\/55 {
  border-right-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-r-orange-500\/60 {
  border-right-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-r-orange-500\/65 {
  border-right-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-r-orange-500\/70 {
  border-right-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-r-orange-500\/75 {
  border-right-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-r-orange-500\/80 {
  border-right-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-r-orange-500\/85 {
  border-right-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-r-orange-500\/90 {
  border-right-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-r-orange-500\/95 {
  border-right-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-r-orange-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-600\/0 {
  border-right-color: rgb(234 88 12 / 0) !important;
}

.tw-border-r-orange-600\/10 {
  border-right-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-r-orange-600\/100 {
  border-right-color: rgb(234 88 12 / 1) !important;
}

.tw-border-r-orange-600\/15 {
  border-right-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-r-orange-600\/20 {
  border-right-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-r-orange-600\/25 {
  border-right-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-r-orange-600\/30 {
  border-right-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-r-orange-600\/35 {
  border-right-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-r-orange-600\/40 {
  border-right-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-r-orange-600\/45 {
  border-right-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-r-orange-600\/5 {
  border-right-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-r-orange-600\/50 {
  border-right-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-r-orange-600\/55 {
  border-right-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-r-orange-600\/60 {
  border-right-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-r-orange-600\/65 {
  border-right-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-r-orange-600\/70 {
  border-right-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-r-orange-600\/75 {
  border-right-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-r-orange-600\/80 {
  border-right-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-r-orange-600\/85 {
  border-right-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-r-orange-600\/90 {
  border-right-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-r-orange-600\/95 {
  border-right-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-r-orange-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-700\/0 {
  border-right-color: rgb(194 65 12 / 0) !important;
}

.tw-border-r-orange-700\/10 {
  border-right-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-r-orange-700\/100 {
  border-right-color: rgb(194 65 12 / 1) !important;
}

.tw-border-r-orange-700\/15 {
  border-right-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-r-orange-700\/20 {
  border-right-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-r-orange-700\/25 {
  border-right-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-r-orange-700\/30 {
  border-right-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-r-orange-700\/35 {
  border-right-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-r-orange-700\/40 {
  border-right-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-r-orange-700\/45 {
  border-right-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-r-orange-700\/5 {
  border-right-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-r-orange-700\/50 {
  border-right-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-r-orange-700\/55 {
  border-right-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-r-orange-700\/60 {
  border-right-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-r-orange-700\/65 {
  border-right-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-r-orange-700\/70 {
  border-right-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-r-orange-700\/75 {
  border-right-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-r-orange-700\/80 {
  border-right-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-r-orange-700\/85 {
  border-right-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-r-orange-700\/90 {
  border-right-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-r-orange-700\/95 {
  border-right-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-r-orange-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-800\/0 {
  border-right-color: rgb(154 52 18 / 0) !important;
}

.tw-border-r-orange-800\/10 {
  border-right-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-r-orange-800\/100 {
  border-right-color: rgb(154 52 18 / 1) !important;
}

.tw-border-r-orange-800\/15 {
  border-right-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-r-orange-800\/20 {
  border-right-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-r-orange-800\/25 {
  border-right-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-r-orange-800\/30 {
  border-right-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-r-orange-800\/35 {
  border-right-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-r-orange-800\/40 {
  border-right-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-r-orange-800\/45 {
  border-right-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-r-orange-800\/5 {
  border-right-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-r-orange-800\/50 {
  border-right-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-r-orange-800\/55 {
  border-right-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-r-orange-800\/60 {
  border-right-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-r-orange-800\/65 {
  border-right-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-r-orange-800\/70 {
  border-right-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-r-orange-800\/75 {
  border-right-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-r-orange-800\/80 {
  border-right-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-r-orange-800\/85 {
  border-right-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-r-orange-800\/90 {
  border-right-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-r-orange-800\/95 {
  border-right-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-r-orange-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-900\/0 {
  border-right-color: rgb(124 45 18 / 0) !important;
}

.tw-border-r-orange-900\/10 {
  border-right-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-r-orange-900\/100 {
  border-right-color: rgb(124 45 18 / 1) !important;
}

.tw-border-r-orange-900\/15 {
  border-right-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-r-orange-900\/20 {
  border-right-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-r-orange-900\/25 {
  border-right-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-r-orange-900\/30 {
  border-right-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-r-orange-900\/35 {
  border-right-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-r-orange-900\/40 {
  border-right-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-r-orange-900\/45 {
  border-right-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-r-orange-900\/5 {
  border-right-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-r-orange-900\/50 {
  border-right-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-r-orange-900\/55 {
  border-right-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-r-orange-900\/60 {
  border-right-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-r-orange-900\/65 {
  border-right-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-r-orange-900\/70 {
  border-right-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-r-orange-900\/75 {
  border-right-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-r-orange-900\/80 {
  border-right-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-r-orange-900\/85 {
  border-right-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-r-orange-900\/90 {
  border-right-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-r-orange-900\/95 {
  border-right-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-r-orange-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-r-orange-950\/0 {
  border-right-color: rgb(67 20 7 / 0) !important;
}

.tw-border-r-orange-950\/10 {
  border-right-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-r-orange-950\/100 {
  border-right-color: rgb(67 20 7 / 1) !important;
}

.tw-border-r-orange-950\/15 {
  border-right-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-r-orange-950\/20 {
  border-right-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-r-orange-950\/25 {
  border-right-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-r-orange-950\/30 {
  border-right-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-r-orange-950\/35 {
  border-right-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-r-orange-950\/40 {
  border-right-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-r-orange-950\/45 {
  border-right-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-r-orange-950\/5 {
  border-right-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-r-orange-950\/50 {
  border-right-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-r-orange-950\/55 {
  border-right-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-r-orange-950\/60 {
  border-right-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-r-orange-950\/65 {
  border-right-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-r-orange-950\/70 {
  border-right-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-r-orange-950\/75 {
  border-right-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-r-orange-950\/80 {
  border-right-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-r-orange-950\/85 {
  border-right-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-r-orange-950\/90 {
  border-right-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-r-orange-950\/95 {
  border-right-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-r-pink-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-100\/0 {
  border-right-color: rgb(252 231 243 / 0) !important;
}

.tw-border-r-pink-100\/10 {
  border-right-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-r-pink-100\/100 {
  border-right-color: rgb(252 231 243 / 1) !important;
}

.tw-border-r-pink-100\/15 {
  border-right-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-r-pink-100\/20 {
  border-right-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-r-pink-100\/25 {
  border-right-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-r-pink-100\/30 {
  border-right-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-r-pink-100\/35 {
  border-right-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-r-pink-100\/40 {
  border-right-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-r-pink-100\/45 {
  border-right-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-r-pink-100\/5 {
  border-right-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-r-pink-100\/50 {
  border-right-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-r-pink-100\/55 {
  border-right-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-r-pink-100\/60 {
  border-right-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-r-pink-100\/65 {
  border-right-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-r-pink-100\/70 {
  border-right-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-r-pink-100\/75 {
  border-right-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-r-pink-100\/80 {
  border-right-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-r-pink-100\/85 {
  border-right-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-r-pink-100\/90 {
  border-right-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-r-pink-100\/95 {
  border-right-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-r-pink-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-200\/0 {
  border-right-color: rgb(251 207 232 / 0) !important;
}

.tw-border-r-pink-200\/10 {
  border-right-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-r-pink-200\/100 {
  border-right-color: rgb(251 207 232 / 1) !important;
}

.tw-border-r-pink-200\/15 {
  border-right-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-r-pink-200\/20 {
  border-right-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-r-pink-200\/25 {
  border-right-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-r-pink-200\/30 {
  border-right-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-r-pink-200\/35 {
  border-right-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-r-pink-200\/40 {
  border-right-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-r-pink-200\/45 {
  border-right-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-r-pink-200\/5 {
  border-right-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-r-pink-200\/50 {
  border-right-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-r-pink-200\/55 {
  border-right-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-r-pink-200\/60 {
  border-right-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-r-pink-200\/65 {
  border-right-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-r-pink-200\/70 {
  border-right-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-r-pink-200\/75 {
  border-right-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-r-pink-200\/80 {
  border-right-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-r-pink-200\/85 {
  border-right-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-r-pink-200\/90 {
  border-right-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-r-pink-200\/95 {
  border-right-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-r-pink-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-300\/0 {
  border-right-color: rgb(249 168 212 / 0) !important;
}

.tw-border-r-pink-300\/10 {
  border-right-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-r-pink-300\/100 {
  border-right-color: rgb(249 168 212 / 1) !important;
}

.tw-border-r-pink-300\/15 {
  border-right-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-r-pink-300\/20 {
  border-right-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-r-pink-300\/25 {
  border-right-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-r-pink-300\/30 {
  border-right-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-r-pink-300\/35 {
  border-right-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-r-pink-300\/40 {
  border-right-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-r-pink-300\/45 {
  border-right-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-r-pink-300\/5 {
  border-right-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-r-pink-300\/50 {
  border-right-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-r-pink-300\/55 {
  border-right-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-r-pink-300\/60 {
  border-right-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-r-pink-300\/65 {
  border-right-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-r-pink-300\/70 {
  border-right-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-r-pink-300\/75 {
  border-right-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-r-pink-300\/80 {
  border-right-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-r-pink-300\/85 {
  border-right-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-r-pink-300\/90 {
  border-right-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-r-pink-300\/95 {
  border-right-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-r-pink-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-400\/0 {
  border-right-color: rgb(244 114 182 / 0) !important;
}

.tw-border-r-pink-400\/10 {
  border-right-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-r-pink-400\/100 {
  border-right-color: rgb(244 114 182 / 1) !important;
}

.tw-border-r-pink-400\/15 {
  border-right-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-r-pink-400\/20 {
  border-right-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-r-pink-400\/25 {
  border-right-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-r-pink-400\/30 {
  border-right-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-r-pink-400\/35 {
  border-right-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-r-pink-400\/40 {
  border-right-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-r-pink-400\/45 {
  border-right-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-r-pink-400\/5 {
  border-right-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-r-pink-400\/50 {
  border-right-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-r-pink-400\/55 {
  border-right-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-r-pink-400\/60 {
  border-right-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-r-pink-400\/65 {
  border-right-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-r-pink-400\/70 {
  border-right-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-r-pink-400\/75 {
  border-right-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-r-pink-400\/80 {
  border-right-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-r-pink-400\/85 {
  border-right-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-r-pink-400\/90 {
  border-right-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-r-pink-400\/95 {
  border-right-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-r-pink-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-50\/0 {
  border-right-color: rgb(253 242 248 / 0) !important;
}

.tw-border-r-pink-50\/10 {
  border-right-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-r-pink-50\/100 {
  border-right-color: rgb(253 242 248 / 1) !important;
}

.tw-border-r-pink-50\/15 {
  border-right-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-r-pink-50\/20 {
  border-right-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-r-pink-50\/25 {
  border-right-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-r-pink-50\/30 {
  border-right-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-r-pink-50\/35 {
  border-right-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-r-pink-50\/40 {
  border-right-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-r-pink-50\/45 {
  border-right-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-r-pink-50\/5 {
  border-right-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-r-pink-50\/50 {
  border-right-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-r-pink-50\/55 {
  border-right-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-r-pink-50\/60 {
  border-right-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-r-pink-50\/65 {
  border-right-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-r-pink-50\/70 {
  border-right-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-r-pink-50\/75 {
  border-right-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-r-pink-50\/80 {
  border-right-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-r-pink-50\/85 {
  border-right-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-r-pink-50\/90 {
  border-right-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-r-pink-50\/95 {
  border-right-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-r-pink-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-500\/0 {
  border-right-color: rgb(236 72 153 / 0) !important;
}

.tw-border-r-pink-500\/10 {
  border-right-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-r-pink-500\/100 {
  border-right-color: rgb(236 72 153 / 1) !important;
}

.tw-border-r-pink-500\/15 {
  border-right-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-r-pink-500\/20 {
  border-right-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-r-pink-500\/25 {
  border-right-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-r-pink-500\/30 {
  border-right-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-r-pink-500\/35 {
  border-right-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-r-pink-500\/40 {
  border-right-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-r-pink-500\/45 {
  border-right-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-r-pink-500\/5 {
  border-right-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-r-pink-500\/50 {
  border-right-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-r-pink-500\/55 {
  border-right-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-r-pink-500\/60 {
  border-right-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-r-pink-500\/65 {
  border-right-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-r-pink-500\/70 {
  border-right-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-r-pink-500\/75 {
  border-right-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-r-pink-500\/80 {
  border-right-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-r-pink-500\/85 {
  border-right-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-r-pink-500\/90 {
  border-right-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-r-pink-500\/95 {
  border-right-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-r-pink-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-600\/0 {
  border-right-color: rgb(219 39 119 / 0) !important;
}

.tw-border-r-pink-600\/10 {
  border-right-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-r-pink-600\/100 {
  border-right-color: rgb(219 39 119 / 1) !important;
}

.tw-border-r-pink-600\/15 {
  border-right-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-r-pink-600\/20 {
  border-right-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-r-pink-600\/25 {
  border-right-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-r-pink-600\/30 {
  border-right-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-r-pink-600\/35 {
  border-right-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-r-pink-600\/40 {
  border-right-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-r-pink-600\/45 {
  border-right-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-r-pink-600\/5 {
  border-right-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-r-pink-600\/50 {
  border-right-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-r-pink-600\/55 {
  border-right-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-r-pink-600\/60 {
  border-right-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-r-pink-600\/65 {
  border-right-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-r-pink-600\/70 {
  border-right-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-r-pink-600\/75 {
  border-right-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-r-pink-600\/80 {
  border-right-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-r-pink-600\/85 {
  border-right-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-r-pink-600\/90 {
  border-right-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-r-pink-600\/95 {
  border-right-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-r-pink-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-700\/0 {
  border-right-color: rgb(190 24 93 / 0) !important;
}

.tw-border-r-pink-700\/10 {
  border-right-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-r-pink-700\/100 {
  border-right-color: rgb(190 24 93 / 1) !important;
}

.tw-border-r-pink-700\/15 {
  border-right-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-r-pink-700\/20 {
  border-right-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-r-pink-700\/25 {
  border-right-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-r-pink-700\/30 {
  border-right-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-r-pink-700\/35 {
  border-right-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-r-pink-700\/40 {
  border-right-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-r-pink-700\/45 {
  border-right-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-r-pink-700\/5 {
  border-right-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-r-pink-700\/50 {
  border-right-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-r-pink-700\/55 {
  border-right-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-r-pink-700\/60 {
  border-right-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-r-pink-700\/65 {
  border-right-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-r-pink-700\/70 {
  border-right-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-r-pink-700\/75 {
  border-right-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-r-pink-700\/80 {
  border-right-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-r-pink-700\/85 {
  border-right-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-r-pink-700\/90 {
  border-right-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-r-pink-700\/95 {
  border-right-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-r-pink-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-800\/0 {
  border-right-color: rgb(157 23 77 / 0) !important;
}

.tw-border-r-pink-800\/10 {
  border-right-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-r-pink-800\/100 {
  border-right-color: rgb(157 23 77 / 1) !important;
}

.tw-border-r-pink-800\/15 {
  border-right-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-r-pink-800\/20 {
  border-right-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-r-pink-800\/25 {
  border-right-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-r-pink-800\/30 {
  border-right-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-r-pink-800\/35 {
  border-right-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-r-pink-800\/40 {
  border-right-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-r-pink-800\/45 {
  border-right-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-r-pink-800\/5 {
  border-right-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-r-pink-800\/50 {
  border-right-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-r-pink-800\/55 {
  border-right-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-r-pink-800\/60 {
  border-right-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-r-pink-800\/65 {
  border-right-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-r-pink-800\/70 {
  border-right-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-r-pink-800\/75 {
  border-right-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-r-pink-800\/80 {
  border-right-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-r-pink-800\/85 {
  border-right-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-r-pink-800\/90 {
  border-right-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-r-pink-800\/95 {
  border-right-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-r-pink-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-900\/0 {
  border-right-color: rgb(131 24 67 / 0) !important;
}

.tw-border-r-pink-900\/10 {
  border-right-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-r-pink-900\/100 {
  border-right-color: rgb(131 24 67 / 1) !important;
}

.tw-border-r-pink-900\/15 {
  border-right-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-r-pink-900\/20 {
  border-right-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-r-pink-900\/25 {
  border-right-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-r-pink-900\/30 {
  border-right-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-r-pink-900\/35 {
  border-right-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-r-pink-900\/40 {
  border-right-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-r-pink-900\/45 {
  border-right-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-r-pink-900\/5 {
  border-right-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-r-pink-900\/50 {
  border-right-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-r-pink-900\/55 {
  border-right-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-r-pink-900\/60 {
  border-right-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-r-pink-900\/65 {
  border-right-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-r-pink-900\/70 {
  border-right-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-r-pink-900\/75 {
  border-right-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-r-pink-900\/80 {
  border-right-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-r-pink-900\/85 {
  border-right-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-r-pink-900\/90 {
  border-right-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-r-pink-900\/95 {
  border-right-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-r-pink-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-r-pink-950\/0 {
  border-right-color: rgb(80 7 36 / 0) !important;
}

.tw-border-r-pink-950\/10 {
  border-right-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-r-pink-950\/100 {
  border-right-color: rgb(80 7 36 / 1) !important;
}

.tw-border-r-pink-950\/15 {
  border-right-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-r-pink-950\/20 {
  border-right-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-r-pink-950\/25 {
  border-right-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-r-pink-950\/30 {
  border-right-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-r-pink-950\/35 {
  border-right-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-r-pink-950\/40 {
  border-right-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-r-pink-950\/45 {
  border-right-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-r-pink-950\/5 {
  border-right-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-r-pink-950\/50 {
  border-right-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-r-pink-950\/55 {
  border-right-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-r-pink-950\/60 {
  border-right-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-r-pink-950\/65 {
  border-right-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-r-pink-950\/70 {
  border-right-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-r-pink-950\/75 {
  border-right-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-r-pink-950\/80 {
  border-right-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-r-pink-950\/85 {
  border-right-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-r-pink-950\/90 {
  border-right-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-r-pink-950\/95 {
  border-right-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-r-purple-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-100\/0 {
  border-right-color: rgb(243 232 255 / 0) !important;
}

.tw-border-r-purple-100\/10 {
  border-right-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-r-purple-100\/100 {
  border-right-color: rgb(243 232 255 / 1) !important;
}

.tw-border-r-purple-100\/15 {
  border-right-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-r-purple-100\/20 {
  border-right-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-r-purple-100\/25 {
  border-right-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-r-purple-100\/30 {
  border-right-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-r-purple-100\/35 {
  border-right-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-r-purple-100\/40 {
  border-right-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-r-purple-100\/45 {
  border-right-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-r-purple-100\/5 {
  border-right-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-r-purple-100\/50 {
  border-right-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-r-purple-100\/55 {
  border-right-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-r-purple-100\/60 {
  border-right-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-r-purple-100\/65 {
  border-right-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-r-purple-100\/70 {
  border-right-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-r-purple-100\/75 {
  border-right-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-r-purple-100\/80 {
  border-right-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-r-purple-100\/85 {
  border-right-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-r-purple-100\/90 {
  border-right-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-r-purple-100\/95 {
  border-right-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-r-purple-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-200\/0 {
  border-right-color: rgb(233 213 255 / 0) !important;
}

.tw-border-r-purple-200\/10 {
  border-right-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-r-purple-200\/100 {
  border-right-color: rgb(233 213 255 / 1) !important;
}

.tw-border-r-purple-200\/15 {
  border-right-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-r-purple-200\/20 {
  border-right-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-r-purple-200\/25 {
  border-right-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-r-purple-200\/30 {
  border-right-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-r-purple-200\/35 {
  border-right-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-r-purple-200\/40 {
  border-right-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-r-purple-200\/45 {
  border-right-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-r-purple-200\/5 {
  border-right-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-r-purple-200\/50 {
  border-right-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-r-purple-200\/55 {
  border-right-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-r-purple-200\/60 {
  border-right-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-r-purple-200\/65 {
  border-right-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-r-purple-200\/70 {
  border-right-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-r-purple-200\/75 {
  border-right-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-r-purple-200\/80 {
  border-right-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-r-purple-200\/85 {
  border-right-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-r-purple-200\/90 {
  border-right-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-r-purple-200\/95 {
  border-right-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-r-purple-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-300\/0 {
  border-right-color: rgb(216 180 254 / 0) !important;
}

.tw-border-r-purple-300\/10 {
  border-right-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-r-purple-300\/100 {
  border-right-color: rgb(216 180 254 / 1) !important;
}

.tw-border-r-purple-300\/15 {
  border-right-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-r-purple-300\/20 {
  border-right-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-r-purple-300\/25 {
  border-right-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-r-purple-300\/30 {
  border-right-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-r-purple-300\/35 {
  border-right-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-r-purple-300\/40 {
  border-right-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-r-purple-300\/45 {
  border-right-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-r-purple-300\/5 {
  border-right-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-r-purple-300\/50 {
  border-right-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-r-purple-300\/55 {
  border-right-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-r-purple-300\/60 {
  border-right-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-r-purple-300\/65 {
  border-right-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-r-purple-300\/70 {
  border-right-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-r-purple-300\/75 {
  border-right-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-r-purple-300\/80 {
  border-right-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-r-purple-300\/85 {
  border-right-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-r-purple-300\/90 {
  border-right-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-r-purple-300\/95 {
  border-right-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-r-purple-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-400\/0 {
  border-right-color: rgb(192 132 252 / 0) !important;
}

.tw-border-r-purple-400\/10 {
  border-right-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-r-purple-400\/100 {
  border-right-color: rgb(192 132 252 / 1) !important;
}

.tw-border-r-purple-400\/15 {
  border-right-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-r-purple-400\/20 {
  border-right-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-r-purple-400\/25 {
  border-right-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-r-purple-400\/30 {
  border-right-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-r-purple-400\/35 {
  border-right-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-r-purple-400\/40 {
  border-right-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-r-purple-400\/45 {
  border-right-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-r-purple-400\/5 {
  border-right-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-r-purple-400\/50 {
  border-right-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-r-purple-400\/55 {
  border-right-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-r-purple-400\/60 {
  border-right-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-r-purple-400\/65 {
  border-right-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-r-purple-400\/70 {
  border-right-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-r-purple-400\/75 {
  border-right-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-r-purple-400\/80 {
  border-right-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-r-purple-400\/85 {
  border-right-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-r-purple-400\/90 {
  border-right-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-r-purple-400\/95 {
  border-right-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-r-purple-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-50\/0 {
  border-right-color: rgb(250 245 255 / 0) !important;
}

.tw-border-r-purple-50\/10 {
  border-right-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-r-purple-50\/100 {
  border-right-color: rgb(250 245 255 / 1) !important;
}

.tw-border-r-purple-50\/15 {
  border-right-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-r-purple-50\/20 {
  border-right-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-r-purple-50\/25 {
  border-right-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-r-purple-50\/30 {
  border-right-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-r-purple-50\/35 {
  border-right-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-r-purple-50\/40 {
  border-right-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-r-purple-50\/45 {
  border-right-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-r-purple-50\/5 {
  border-right-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-r-purple-50\/50 {
  border-right-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-r-purple-50\/55 {
  border-right-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-r-purple-50\/60 {
  border-right-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-r-purple-50\/65 {
  border-right-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-r-purple-50\/70 {
  border-right-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-r-purple-50\/75 {
  border-right-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-r-purple-50\/80 {
  border-right-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-r-purple-50\/85 {
  border-right-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-r-purple-50\/90 {
  border-right-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-r-purple-50\/95 {
  border-right-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-r-purple-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-500\/0 {
  border-right-color: rgb(168 85 247 / 0) !important;
}

.tw-border-r-purple-500\/10 {
  border-right-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-r-purple-500\/100 {
  border-right-color: rgb(168 85 247 / 1) !important;
}

.tw-border-r-purple-500\/15 {
  border-right-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-r-purple-500\/20 {
  border-right-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-r-purple-500\/25 {
  border-right-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-r-purple-500\/30 {
  border-right-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-r-purple-500\/35 {
  border-right-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-r-purple-500\/40 {
  border-right-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-r-purple-500\/45 {
  border-right-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-r-purple-500\/5 {
  border-right-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-r-purple-500\/50 {
  border-right-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-r-purple-500\/55 {
  border-right-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-r-purple-500\/60 {
  border-right-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-r-purple-500\/65 {
  border-right-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-r-purple-500\/70 {
  border-right-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-r-purple-500\/75 {
  border-right-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-r-purple-500\/80 {
  border-right-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-r-purple-500\/85 {
  border-right-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-r-purple-500\/90 {
  border-right-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-r-purple-500\/95 {
  border-right-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-r-purple-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-600\/0 {
  border-right-color: rgb(147 51 234 / 0) !important;
}

.tw-border-r-purple-600\/10 {
  border-right-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-r-purple-600\/100 {
  border-right-color: rgb(147 51 234 / 1) !important;
}

.tw-border-r-purple-600\/15 {
  border-right-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-r-purple-600\/20 {
  border-right-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-r-purple-600\/25 {
  border-right-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-r-purple-600\/30 {
  border-right-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-r-purple-600\/35 {
  border-right-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-r-purple-600\/40 {
  border-right-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-r-purple-600\/45 {
  border-right-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-r-purple-600\/5 {
  border-right-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-r-purple-600\/50 {
  border-right-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-r-purple-600\/55 {
  border-right-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-r-purple-600\/60 {
  border-right-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-r-purple-600\/65 {
  border-right-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-r-purple-600\/70 {
  border-right-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-r-purple-600\/75 {
  border-right-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-r-purple-600\/80 {
  border-right-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-r-purple-600\/85 {
  border-right-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-r-purple-600\/90 {
  border-right-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-r-purple-600\/95 {
  border-right-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-r-purple-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-700\/0 {
  border-right-color: rgb(126 34 206 / 0) !important;
}

.tw-border-r-purple-700\/10 {
  border-right-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-r-purple-700\/100 {
  border-right-color: rgb(126 34 206 / 1) !important;
}

.tw-border-r-purple-700\/15 {
  border-right-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-r-purple-700\/20 {
  border-right-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-r-purple-700\/25 {
  border-right-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-r-purple-700\/30 {
  border-right-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-r-purple-700\/35 {
  border-right-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-r-purple-700\/40 {
  border-right-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-r-purple-700\/45 {
  border-right-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-r-purple-700\/5 {
  border-right-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-r-purple-700\/50 {
  border-right-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-r-purple-700\/55 {
  border-right-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-r-purple-700\/60 {
  border-right-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-r-purple-700\/65 {
  border-right-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-r-purple-700\/70 {
  border-right-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-r-purple-700\/75 {
  border-right-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-r-purple-700\/80 {
  border-right-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-r-purple-700\/85 {
  border-right-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-r-purple-700\/90 {
  border-right-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-r-purple-700\/95 {
  border-right-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-r-purple-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-800\/0 {
  border-right-color: rgb(107 33 168 / 0) !important;
}

.tw-border-r-purple-800\/10 {
  border-right-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-r-purple-800\/100 {
  border-right-color: rgb(107 33 168 / 1) !important;
}

.tw-border-r-purple-800\/15 {
  border-right-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-r-purple-800\/20 {
  border-right-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-r-purple-800\/25 {
  border-right-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-r-purple-800\/30 {
  border-right-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-r-purple-800\/35 {
  border-right-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-r-purple-800\/40 {
  border-right-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-r-purple-800\/45 {
  border-right-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-r-purple-800\/5 {
  border-right-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-r-purple-800\/50 {
  border-right-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-r-purple-800\/55 {
  border-right-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-r-purple-800\/60 {
  border-right-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-r-purple-800\/65 {
  border-right-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-r-purple-800\/70 {
  border-right-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-r-purple-800\/75 {
  border-right-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-r-purple-800\/80 {
  border-right-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-r-purple-800\/85 {
  border-right-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-r-purple-800\/90 {
  border-right-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-r-purple-800\/95 {
  border-right-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-r-purple-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-900\/0 {
  border-right-color: rgb(88 28 135 / 0) !important;
}

.tw-border-r-purple-900\/10 {
  border-right-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-r-purple-900\/100 {
  border-right-color: rgb(88 28 135 / 1) !important;
}

.tw-border-r-purple-900\/15 {
  border-right-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-r-purple-900\/20 {
  border-right-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-r-purple-900\/25 {
  border-right-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-r-purple-900\/30 {
  border-right-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-r-purple-900\/35 {
  border-right-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-r-purple-900\/40 {
  border-right-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-r-purple-900\/45 {
  border-right-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-r-purple-900\/5 {
  border-right-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-r-purple-900\/50 {
  border-right-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-r-purple-900\/55 {
  border-right-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-r-purple-900\/60 {
  border-right-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-r-purple-900\/65 {
  border-right-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-r-purple-900\/70 {
  border-right-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-r-purple-900\/75 {
  border-right-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-r-purple-900\/80 {
  border-right-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-r-purple-900\/85 {
  border-right-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-r-purple-900\/90 {
  border-right-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-r-purple-900\/95 {
  border-right-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-r-purple-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-r-purple-950\/0 {
  border-right-color: rgb(59 7 100 / 0) !important;
}

.tw-border-r-purple-950\/10 {
  border-right-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-r-purple-950\/100 {
  border-right-color: rgb(59 7 100 / 1) !important;
}

.tw-border-r-purple-950\/15 {
  border-right-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-r-purple-950\/20 {
  border-right-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-r-purple-950\/25 {
  border-right-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-r-purple-950\/30 {
  border-right-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-r-purple-950\/35 {
  border-right-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-r-purple-950\/40 {
  border-right-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-r-purple-950\/45 {
  border-right-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-r-purple-950\/5 {
  border-right-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-r-purple-950\/50 {
  border-right-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-r-purple-950\/55 {
  border-right-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-r-purple-950\/60 {
  border-right-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-r-purple-950\/65 {
  border-right-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-r-purple-950\/70 {
  border-right-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-r-purple-950\/75 {
  border-right-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-r-purple-950\/80 {
  border-right-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-r-purple-950\/85 {
  border-right-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-r-purple-950\/90 {
  border-right-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-r-purple-950\/95 {
  border-right-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-r-red-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-100\/0 {
  border-right-color: rgb(254 226 226 / 0) !important;
}

.tw-border-r-red-100\/10 {
  border-right-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-r-red-100\/100 {
  border-right-color: rgb(254 226 226 / 1) !important;
}

.tw-border-r-red-100\/15 {
  border-right-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-r-red-100\/20 {
  border-right-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-r-red-100\/25 {
  border-right-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-r-red-100\/30 {
  border-right-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-r-red-100\/35 {
  border-right-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-r-red-100\/40 {
  border-right-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-r-red-100\/45 {
  border-right-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-r-red-100\/5 {
  border-right-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-r-red-100\/50 {
  border-right-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-r-red-100\/55 {
  border-right-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-r-red-100\/60 {
  border-right-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-r-red-100\/65 {
  border-right-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-r-red-100\/70 {
  border-right-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-r-red-100\/75 {
  border-right-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-r-red-100\/80 {
  border-right-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-r-red-100\/85 {
  border-right-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-r-red-100\/90 {
  border-right-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-r-red-100\/95 {
  border-right-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-r-red-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-200\/0 {
  border-right-color: rgb(254 202 202 / 0) !important;
}

.tw-border-r-red-200\/10 {
  border-right-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-r-red-200\/100 {
  border-right-color: rgb(254 202 202 / 1) !important;
}

.tw-border-r-red-200\/15 {
  border-right-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-r-red-200\/20 {
  border-right-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-r-red-200\/25 {
  border-right-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-r-red-200\/30 {
  border-right-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-r-red-200\/35 {
  border-right-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-r-red-200\/40 {
  border-right-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-r-red-200\/45 {
  border-right-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-r-red-200\/5 {
  border-right-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-r-red-200\/50 {
  border-right-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-r-red-200\/55 {
  border-right-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-r-red-200\/60 {
  border-right-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-r-red-200\/65 {
  border-right-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-r-red-200\/70 {
  border-right-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-r-red-200\/75 {
  border-right-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-r-red-200\/80 {
  border-right-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-r-red-200\/85 {
  border-right-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-r-red-200\/90 {
  border-right-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-r-red-200\/95 {
  border-right-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-r-red-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-300\/0 {
  border-right-color: rgb(252 165 165 / 0) !important;
}

.tw-border-r-red-300\/10 {
  border-right-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-r-red-300\/100 {
  border-right-color: rgb(252 165 165 / 1) !important;
}

.tw-border-r-red-300\/15 {
  border-right-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-r-red-300\/20 {
  border-right-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-r-red-300\/25 {
  border-right-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-r-red-300\/30 {
  border-right-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-r-red-300\/35 {
  border-right-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-r-red-300\/40 {
  border-right-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-r-red-300\/45 {
  border-right-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-r-red-300\/5 {
  border-right-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-r-red-300\/50 {
  border-right-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-r-red-300\/55 {
  border-right-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-r-red-300\/60 {
  border-right-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-r-red-300\/65 {
  border-right-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-r-red-300\/70 {
  border-right-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-r-red-300\/75 {
  border-right-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-r-red-300\/80 {
  border-right-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-r-red-300\/85 {
  border-right-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-r-red-300\/90 {
  border-right-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-r-red-300\/95 {
  border-right-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-r-red-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-400\/0 {
  border-right-color: rgb(248 113 113 / 0) !important;
}

.tw-border-r-red-400\/10 {
  border-right-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-r-red-400\/100 {
  border-right-color: rgb(248 113 113 / 1) !important;
}

.tw-border-r-red-400\/15 {
  border-right-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-r-red-400\/20 {
  border-right-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-r-red-400\/25 {
  border-right-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-r-red-400\/30 {
  border-right-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-r-red-400\/35 {
  border-right-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-r-red-400\/40 {
  border-right-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-r-red-400\/45 {
  border-right-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-r-red-400\/5 {
  border-right-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-r-red-400\/50 {
  border-right-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-r-red-400\/55 {
  border-right-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-r-red-400\/60 {
  border-right-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-r-red-400\/65 {
  border-right-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-r-red-400\/70 {
  border-right-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-r-red-400\/75 {
  border-right-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-r-red-400\/80 {
  border-right-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-r-red-400\/85 {
  border-right-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-r-red-400\/90 {
  border-right-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-r-red-400\/95 {
  border-right-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-r-red-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-50\/0 {
  border-right-color: rgb(254 242 242 / 0) !important;
}

.tw-border-r-red-50\/10 {
  border-right-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-r-red-50\/100 {
  border-right-color: rgb(254 242 242 / 1) !important;
}

.tw-border-r-red-50\/15 {
  border-right-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-r-red-50\/20 {
  border-right-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-r-red-50\/25 {
  border-right-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-r-red-50\/30 {
  border-right-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-r-red-50\/35 {
  border-right-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-r-red-50\/40 {
  border-right-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-r-red-50\/45 {
  border-right-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-r-red-50\/5 {
  border-right-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-r-red-50\/50 {
  border-right-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-r-red-50\/55 {
  border-right-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-r-red-50\/60 {
  border-right-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-r-red-50\/65 {
  border-right-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-r-red-50\/70 {
  border-right-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-r-red-50\/75 {
  border-right-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-r-red-50\/80 {
  border-right-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-r-red-50\/85 {
  border-right-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-r-red-50\/90 {
  border-right-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-r-red-50\/95 {
  border-right-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-r-red-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-500\/0 {
  border-right-color: rgb(239 68 68 / 0) !important;
}

.tw-border-r-red-500\/10 {
  border-right-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-r-red-500\/100 {
  border-right-color: rgb(239 68 68 / 1) !important;
}

.tw-border-r-red-500\/15 {
  border-right-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-r-red-500\/20 {
  border-right-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-r-red-500\/25 {
  border-right-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-r-red-500\/30 {
  border-right-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-r-red-500\/35 {
  border-right-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-r-red-500\/40 {
  border-right-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-r-red-500\/45 {
  border-right-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-r-red-500\/5 {
  border-right-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-r-red-500\/50 {
  border-right-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-r-red-500\/55 {
  border-right-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-r-red-500\/60 {
  border-right-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-r-red-500\/65 {
  border-right-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-r-red-500\/70 {
  border-right-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-r-red-500\/75 {
  border-right-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-r-red-500\/80 {
  border-right-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-r-red-500\/85 {
  border-right-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-r-red-500\/90 {
  border-right-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-r-red-500\/95 {
  border-right-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-r-red-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-600\/0 {
  border-right-color: rgb(220 38 38 / 0) !important;
}

.tw-border-r-red-600\/10 {
  border-right-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-r-red-600\/100 {
  border-right-color: rgb(220 38 38 / 1) !important;
}

.tw-border-r-red-600\/15 {
  border-right-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-r-red-600\/20 {
  border-right-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-r-red-600\/25 {
  border-right-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-r-red-600\/30 {
  border-right-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-r-red-600\/35 {
  border-right-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-r-red-600\/40 {
  border-right-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-r-red-600\/45 {
  border-right-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-r-red-600\/5 {
  border-right-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-r-red-600\/50 {
  border-right-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-r-red-600\/55 {
  border-right-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-r-red-600\/60 {
  border-right-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-r-red-600\/65 {
  border-right-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-r-red-600\/70 {
  border-right-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-r-red-600\/75 {
  border-right-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-r-red-600\/80 {
  border-right-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-r-red-600\/85 {
  border-right-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-r-red-600\/90 {
  border-right-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-r-red-600\/95 {
  border-right-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-r-red-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-700\/0 {
  border-right-color: rgb(185 28 28 / 0) !important;
}

.tw-border-r-red-700\/10 {
  border-right-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-r-red-700\/100 {
  border-right-color: rgb(185 28 28 / 1) !important;
}

.tw-border-r-red-700\/15 {
  border-right-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-r-red-700\/20 {
  border-right-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-r-red-700\/25 {
  border-right-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-r-red-700\/30 {
  border-right-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-r-red-700\/35 {
  border-right-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-r-red-700\/40 {
  border-right-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-r-red-700\/45 {
  border-right-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-r-red-700\/5 {
  border-right-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-r-red-700\/50 {
  border-right-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-r-red-700\/55 {
  border-right-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-r-red-700\/60 {
  border-right-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-r-red-700\/65 {
  border-right-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-r-red-700\/70 {
  border-right-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-r-red-700\/75 {
  border-right-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-r-red-700\/80 {
  border-right-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-r-red-700\/85 {
  border-right-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-r-red-700\/90 {
  border-right-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-r-red-700\/95 {
  border-right-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-r-red-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-800\/0 {
  border-right-color: rgb(153 27 27 / 0) !important;
}

.tw-border-r-red-800\/10 {
  border-right-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-r-red-800\/100 {
  border-right-color: rgb(153 27 27 / 1) !important;
}

.tw-border-r-red-800\/15 {
  border-right-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-r-red-800\/20 {
  border-right-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-r-red-800\/25 {
  border-right-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-r-red-800\/30 {
  border-right-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-r-red-800\/35 {
  border-right-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-r-red-800\/40 {
  border-right-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-r-red-800\/45 {
  border-right-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-r-red-800\/5 {
  border-right-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-r-red-800\/50 {
  border-right-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-r-red-800\/55 {
  border-right-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-r-red-800\/60 {
  border-right-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-r-red-800\/65 {
  border-right-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-r-red-800\/70 {
  border-right-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-r-red-800\/75 {
  border-right-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-r-red-800\/80 {
  border-right-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-r-red-800\/85 {
  border-right-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-r-red-800\/90 {
  border-right-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-r-red-800\/95 {
  border-right-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-r-red-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-900\/0 {
  border-right-color: rgb(127 29 29 / 0) !important;
}

.tw-border-r-red-900\/10 {
  border-right-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-r-red-900\/100 {
  border-right-color: rgb(127 29 29 / 1) !important;
}

.tw-border-r-red-900\/15 {
  border-right-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-r-red-900\/20 {
  border-right-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-r-red-900\/25 {
  border-right-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-r-red-900\/30 {
  border-right-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-r-red-900\/35 {
  border-right-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-r-red-900\/40 {
  border-right-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-r-red-900\/45 {
  border-right-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-r-red-900\/5 {
  border-right-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-r-red-900\/50 {
  border-right-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-r-red-900\/55 {
  border-right-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-r-red-900\/60 {
  border-right-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-r-red-900\/65 {
  border-right-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-r-red-900\/70 {
  border-right-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-r-red-900\/75 {
  border-right-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-r-red-900\/80 {
  border-right-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-r-red-900\/85 {
  border-right-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-r-red-900\/90 {
  border-right-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-r-red-900\/95 {
  border-right-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-r-red-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-r-red-950\/0 {
  border-right-color: rgb(69 10 10 / 0) !important;
}

.tw-border-r-red-950\/10 {
  border-right-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-r-red-950\/100 {
  border-right-color: rgb(69 10 10 / 1) !important;
}

.tw-border-r-red-950\/15 {
  border-right-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-r-red-950\/20 {
  border-right-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-r-red-950\/25 {
  border-right-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-r-red-950\/30 {
  border-right-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-r-red-950\/35 {
  border-right-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-r-red-950\/40 {
  border-right-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-r-red-950\/45 {
  border-right-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-r-red-950\/5 {
  border-right-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-r-red-950\/50 {
  border-right-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-r-red-950\/55 {
  border-right-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-r-red-950\/60 {
  border-right-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-r-red-950\/65 {
  border-right-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-r-red-950\/70 {
  border-right-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-r-red-950\/75 {
  border-right-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-r-red-950\/80 {
  border-right-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-r-red-950\/85 {
  border-right-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-r-red-950\/90 {
  border-right-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-r-red-950\/95 {
  border-right-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-r-rose-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-100\/0 {
  border-right-color: rgb(255 228 230 / 0) !important;
}

.tw-border-r-rose-100\/10 {
  border-right-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-r-rose-100\/100 {
  border-right-color: rgb(255 228 230 / 1) !important;
}

.tw-border-r-rose-100\/15 {
  border-right-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-r-rose-100\/20 {
  border-right-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-r-rose-100\/25 {
  border-right-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-r-rose-100\/30 {
  border-right-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-r-rose-100\/35 {
  border-right-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-r-rose-100\/40 {
  border-right-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-r-rose-100\/45 {
  border-right-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-r-rose-100\/5 {
  border-right-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-r-rose-100\/50 {
  border-right-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-r-rose-100\/55 {
  border-right-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-r-rose-100\/60 {
  border-right-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-r-rose-100\/65 {
  border-right-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-r-rose-100\/70 {
  border-right-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-r-rose-100\/75 {
  border-right-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-r-rose-100\/80 {
  border-right-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-r-rose-100\/85 {
  border-right-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-r-rose-100\/90 {
  border-right-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-r-rose-100\/95 {
  border-right-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-r-rose-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-200\/0 {
  border-right-color: rgb(254 205 211 / 0) !important;
}

.tw-border-r-rose-200\/10 {
  border-right-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-r-rose-200\/100 {
  border-right-color: rgb(254 205 211 / 1) !important;
}

.tw-border-r-rose-200\/15 {
  border-right-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-r-rose-200\/20 {
  border-right-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-r-rose-200\/25 {
  border-right-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-r-rose-200\/30 {
  border-right-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-r-rose-200\/35 {
  border-right-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-r-rose-200\/40 {
  border-right-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-r-rose-200\/45 {
  border-right-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-r-rose-200\/5 {
  border-right-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-r-rose-200\/50 {
  border-right-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-r-rose-200\/55 {
  border-right-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-r-rose-200\/60 {
  border-right-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-r-rose-200\/65 {
  border-right-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-r-rose-200\/70 {
  border-right-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-r-rose-200\/75 {
  border-right-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-r-rose-200\/80 {
  border-right-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-r-rose-200\/85 {
  border-right-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-r-rose-200\/90 {
  border-right-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-r-rose-200\/95 {
  border-right-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-r-rose-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-300\/0 {
  border-right-color: rgb(253 164 175 / 0) !important;
}

.tw-border-r-rose-300\/10 {
  border-right-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-r-rose-300\/100 {
  border-right-color: rgb(253 164 175 / 1) !important;
}

.tw-border-r-rose-300\/15 {
  border-right-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-r-rose-300\/20 {
  border-right-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-r-rose-300\/25 {
  border-right-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-r-rose-300\/30 {
  border-right-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-r-rose-300\/35 {
  border-right-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-r-rose-300\/40 {
  border-right-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-r-rose-300\/45 {
  border-right-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-r-rose-300\/5 {
  border-right-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-r-rose-300\/50 {
  border-right-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-r-rose-300\/55 {
  border-right-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-r-rose-300\/60 {
  border-right-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-r-rose-300\/65 {
  border-right-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-r-rose-300\/70 {
  border-right-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-r-rose-300\/75 {
  border-right-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-r-rose-300\/80 {
  border-right-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-r-rose-300\/85 {
  border-right-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-r-rose-300\/90 {
  border-right-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-r-rose-300\/95 {
  border-right-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-r-rose-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-400\/0 {
  border-right-color: rgb(251 113 133 / 0) !important;
}

.tw-border-r-rose-400\/10 {
  border-right-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-r-rose-400\/100 {
  border-right-color: rgb(251 113 133 / 1) !important;
}

.tw-border-r-rose-400\/15 {
  border-right-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-r-rose-400\/20 {
  border-right-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-r-rose-400\/25 {
  border-right-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-r-rose-400\/30 {
  border-right-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-r-rose-400\/35 {
  border-right-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-r-rose-400\/40 {
  border-right-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-r-rose-400\/45 {
  border-right-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-r-rose-400\/5 {
  border-right-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-r-rose-400\/50 {
  border-right-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-r-rose-400\/55 {
  border-right-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-r-rose-400\/60 {
  border-right-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-r-rose-400\/65 {
  border-right-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-r-rose-400\/70 {
  border-right-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-r-rose-400\/75 {
  border-right-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-r-rose-400\/80 {
  border-right-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-r-rose-400\/85 {
  border-right-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-r-rose-400\/90 {
  border-right-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-r-rose-400\/95 {
  border-right-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-r-rose-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-50\/0 {
  border-right-color: rgb(255 241 242 / 0) !important;
}

.tw-border-r-rose-50\/10 {
  border-right-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-r-rose-50\/100 {
  border-right-color: rgb(255 241 242 / 1) !important;
}

.tw-border-r-rose-50\/15 {
  border-right-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-r-rose-50\/20 {
  border-right-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-r-rose-50\/25 {
  border-right-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-r-rose-50\/30 {
  border-right-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-r-rose-50\/35 {
  border-right-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-r-rose-50\/40 {
  border-right-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-r-rose-50\/45 {
  border-right-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-r-rose-50\/5 {
  border-right-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-r-rose-50\/50 {
  border-right-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-r-rose-50\/55 {
  border-right-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-r-rose-50\/60 {
  border-right-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-r-rose-50\/65 {
  border-right-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-r-rose-50\/70 {
  border-right-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-r-rose-50\/75 {
  border-right-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-r-rose-50\/80 {
  border-right-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-r-rose-50\/85 {
  border-right-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-r-rose-50\/90 {
  border-right-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-r-rose-50\/95 {
  border-right-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-r-rose-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-500\/0 {
  border-right-color: rgb(244 63 94 / 0) !important;
}

.tw-border-r-rose-500\/10 {
  border-right-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-r-rose-500\/100 {
  border-right-color: rgb(244 63 94 / 1) !important;
}

.tw-border-r-rose-500\/15 {
  border-right-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-r-rose-500\/20 {
  border-right-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-r-rose-500\/25 {
  border-right-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-r-rose-500\/30 {
  border-right-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-r-rose-500\/35 {
  border-right-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-r-rose-500\/40 {
  border-right-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-r-rose-500\/45 {
  border-right-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-r-rose-500\/5 {
  border-right-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-r-rose-500\/50 {
  border-right-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-r-rose-500\/55 {
  border-right-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-r-rose-500\/60 {
  border-right-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-r-rose-500\/65 {
  border-right-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-r-rose-500\/70 {
  border-right-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-r-rose-500\/75 {
  border-right-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-r-rose-500\/80 {
  border-right-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-r-rose-500\/85 {
  border-right-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-r-rose-500\/90 {
  border-right-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-r-rose-500\/95 {
  border-right-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-r-rose-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-600\/0 {
  border-right-color: rgb(225 29 72 / 0) !important;
}

.tw-border-r-rose-600\/10 {
  border-right-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-r-rose-600\/100 {
  border-right-color: rgb(225 29 72 / 1) !important;
}

.tw-border-r-rose-600\/15 {
  border-right-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-r-rose-600\/20 {
  border-right-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-r-rose-600\/25 {
  border-right-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-r-rose-600\/30 {
  border-right-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-r-rose-600\/35 {
  border-right-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-r-rose-600\/40 {
  border-right-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-r-rose-600\/45 {
  border-right-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-r-rose-600\/5 {
  border-right-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-r-rose-600\/50 {
  border-right-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-r-rose-600\/55 {
  border-right-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-r-rose-600\/60 {
  border-right-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-r-rose-600\/65 {
  border-right-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-r-rose-600\/70 {
  border-right-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-r-rose-600\/75 {
  border-right-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-r-rose-600\/80 {
  border-right-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-r-rose-600\/85 {
  border-right-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-r-rose-600\/90 {
  border-right-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-r-rose-600\/95 {
  border-right-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-r-rose-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-700\/0 {
  border-right-color: rgb(190 18 60 / 0) !important;
}

.tw-border-r-rose-700\/10 {
  border-right-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-r-rose-700\/100 {
  border-right-color: rgb(190 18 60 / 1) !important;
}

.tw-border-r-rose-700\/15 {
  border-right-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-r-rose-700\/20 {
  border-right-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-r-rose-700\/25 {
  border-right-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-r-rose-700\/30 {
  border-right-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-r-rose-700\/35 {
  border-right-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-r-rose-700\/40 {
  border-right-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-r-rose-700\/45 {
  border-right-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-r-rose-700\/5 {
  border-right-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-r-rose-700\/50 {
  border-right-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-r-rose-700\/55 {
  border-right-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-r-rose-700\/60 {
  border-right-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-r-rose-700\/65 {
  border-right-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-r-rose-700\/70 {
  border-right-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-r-rose-700\/75 {
  border-right-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-r-rose-700\/80 {
  border-right-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-r-rose-700\/85 {
  border-right-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-r-rose-700\/90 {
  border-right-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-r-rose-700\/95 {
  border-right-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-r-rose-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-800\/0 {
  border-right-color: rgb(159 18 57 / 0) !important;
}

.tw-border-r-rose-800\/10 {
  border-right-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-r-rose-800\/100 {
  border-right-color: rgb(159 18 57 / 1) !important;
}

.tw-border-r-rose-800\/15 {
  border-right-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-r-rose-800\/20 {
  border-right-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-r-rose-800\/25 {
  border-right-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-r-rose-800\/30 {
  border-right-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-r-rose-800\/35 {
  border-right-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-r-rose-800\/40 {
  border-right-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-r-rose-800\/45 {
  border-right-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-r-rose-800\/5 {
  border-right-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-r-rose-800\/50 {
  border-right-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-r-rose-800\/55 {
  border-right-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-r-rose-800\/60 {
  border-right-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-r-rose-800\/65 {
  border-right-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-r-rose-800\/70 {
  border-right-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-r-rose-800\/75 {
  border-right-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-r-rose-800\/80 {
  border-right-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-r-rose-800\/85 {
  border-right-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-r-rose-800\/90 {
  border-right-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-r-rose-800\/95 {
  border-right-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-r-rose-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-900\/0 {
  border-right-color: rgb(136 19 55 / 0) !important;
}

.tw-border-r-rose-900\/10 {
  border-right-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-r-rose-900\/100 {
  border-right-color: rgb(136 19 55 / 1) !important;
}

.tw-border-r-rose-900\/15 {
  border-right-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-r-rose-900\/20 {
  border-right-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-r-rose-900\/25 {
  border-right-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-r-rose-900\/30 {
  border-right-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-r-rose-900\/35 {
  border-right-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-r-rose-900\/40 {
  border-right-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-r-rose-900\/45 {
  border-right-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-r-rose-900\/5 {
  border-right-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-r-rose-900\/50 {
  border-right-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-r-rose-900\/55 {
  border-right-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-r-rose-900\/60 {
  border-right-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-r-rose-900\/65 {
  border-right-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-r-rose-900\/70 {
  border-right-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-r-rose-900\/75 {
  border-right-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-r-rose-900\/80 {
  border-right-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-r-rose-900\/85 {
  border-right-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-r-rose-900\/90 {
  border-right-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-r-rose-900\/95 {
  border-right-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-r-rose-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-r-rose-950\/0 {
  border-right-color: rgb(76 5 25 / 0) !important;
}

.tw-border-r-rose-950\/10 {
  border-right-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-r-rose-950\/100 {
  border-right-color: rgb(76 5 25 / 1) !important;
}

.tw-border-r-rose-950\/15 {
  border-right-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-r-rose-950\/20 {
  border-right-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-r-rose-950\/25 {
  border-right-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-r-rose-950\/30 {
  border-right-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-r-rose-950\/35 {
  border-right-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-r-rose-950\/40 {
  border-right-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-r-rose-950\/45 {
  border-right-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-r-rose-950\/5 {
  border-right-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-r-rose-950\/50 {
  border-right-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-r-rose-950\/55 {
  border-right-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-r-rose-950\/60 {
  border-right-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-r-rose-950\/65 {
  border-right-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-r-rose-950\/70 {
  border-right-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-r-rose-950\/75 {
  border-right-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-r-rose-950\/80 {
  border-right-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-r-rose-950\/85 {
  border-right-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-r-rose-950\/90 {
  border-right-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-r-rose-950\/95 {
  border-right-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-r-sky-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-100\/0 {
  border-right-color: rgb(224 242 254 / 0) !important;
}

.tw-border-r-sky-100\/10 {
  border-right-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-r-sky-100\/100 {
  border-right-color: rgb(224 242 254 / 1) !important;
}

.tw-border-r-sky-100\/15 {
  border-right-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-r-sky-100\/20 {
  border-right-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-r-sky-100\/25 {
  border-right-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-r-sky-100\/30 {
  border-right-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-r-sky-100\/35 {
  border-right-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-r-sky-100\/40 {
  border-right-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-r-sky-100\/45 {
  border-right-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-r-sky-100\/5 {
  border-right-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-r-sky-100\/50 {
  border-right-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-r-sky-100\/55 {
  border-right-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-r-sky-100\/60 {
  border-right-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-r-sky-100\/65 {
  border-right-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-r-sky-100\/70 {
  border-right-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-r-sky-100\/75 {
  border-right-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-r-sky-100\/80 {
  border-right-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-r-sky-100\/85 {
  border-right-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-r-sky-100\/90 {
  border-right-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-r-sky-100\/95 {
  border-right-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-r-sky-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-200\/0 {
  border-right-color: rgb(186 230 253 / 0) !important;
}

.tw-border-r-sky-200\/10 {
  border-right-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-r-sky-200\/100 {
  border-right-color: rgb(186 230 253 / 1) !important;
}

.tw-border-r-sky-200\/15 {
  border-right-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-r-sky-200\/20 {
  border-right-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-r-sky-200\/25 {
  border-right-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-r-sky-200\/30 {
  border-right-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-r-sky-200\/35 {
  border-right-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-r-sky-200\/40 {
  border-right-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-r-sky-200\/45 {
  border-right-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-r-sky-200\/5 {
  border-right-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-r-sky-200\/50 {
  border-right-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-r-sky-200\/55 {
  border-right-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-r-sky-200\/60 {
  border-right-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-r-sky-200\/65 {
  border-right-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-r-sky-200\/70 {
  border-right-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-r-sky-200\/75 {
  border-right-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-r-sky-200\/80 {
  border-right-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-r-sky-200\/85 {
  border-right-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-r-sky-200\/90 {
  border-right-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-r-sky-200\/95 {
  border-right-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-r-sky-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-300\/0 {
  border-right-color: rgb(125 211 252 / 0) !important;
}

.tw-border-r-sky-300\/10 {
  border-right-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-r-sky-300\/100 {
  border-right-color: rgb(125 211 252 / 1) !important;
}

.tw-border-r-sky-300\/15 {
  border-right-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-r-sky-300\/20 {
  border-right-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-r-sky-300\/25 {
  border-right-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-r-sky-300\/30 {
  border-right-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-r-sky-300\/35 {
  border-right-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-r-sky-300\/40 {
  border-right-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-r-sky-300\/45 {
  border-right-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-r-sky-300\/5 {
  border-right-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-r-sky-300\/50 {
  border-right-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-r-sky-300\/55 {
  border-right-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-r-sky-300\/60 {
  border-right-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-r-sky-300\/65 {
  border-right-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-r-sky-300\/70 {
  border-right-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-r-sky-300\/75 {
  border-right-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-r-sky-300\/80 {
  border-right-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-r-sky-300\/85 {
  border-right-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-r-sky-300\/90 {
  border-right-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-r-sky-300\/95 {
  border-right-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-r-sky-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-400\/0 {
  border-right-color: rgb(56 189 248 / 0) !important;
}

.tw-border-r-sky-400\/10 {
  border-right-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-r-sky-400\/100 {
  border-right-color: rgb(56 189 248 / 1) !important;
}

.tw-border-r-sky-400\/15 {
  border-right-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-r-sky-400\/20 {
  border-right-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-r-sky-400\/25 {
  border-right-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-r-sky-400\/30 {
  border-right-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-r-sky-400\/35 {
  border-right-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-r-sky-400\/40 {
  border-right-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-r-sky-400\/45 {
  border-right-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-r-sky-400\/5 {
  border-right-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-r-sky-400\/50 {
  border-right-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-r-sky-400\/55 {
  border-right-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-r-sky-400\/60 {
  border-right-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-r-sky-400\/65 {
  border-right-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-r-sky-400\/70 {
  border-right-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-r-sky-400\/75 {
  border-right-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-r-sky-400\/80 {
  border-right-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-r-sky-400\/85 {
  border-right-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-r-sky-400\/90 {
  border-right-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-r-sky-400\/95 {
  border-right-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-r-sky-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-50\/0 {
  border-right-color: rgb(240 249 255 / 0) !important;
}

.tw-border-r-sky-50\/10 {
  border-right-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-r-sky-50\/100 {
  border-right-color: rgb(240 249 255 / 1) !important;
}

.tw-border-r-sky-50\/15 {
  border-right-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-r-sky-50\/20 {
  border-right-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-r-sky-50\/25 {
  border-right-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-r-sky-50\/30 {
  border-right-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-r-sky-50\/35 {
  border-right-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-r-sky-50\/40 {
  border-right-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-r-sky-50\/45 {
  border-right-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-r-sky-50\/5 {
  border-right-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-r-sky-50\/50 {
  border-right-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-r-sky-50\/55 {
  border-right-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-r-sky-50\/60 {
  border-right-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-r-sky-50\/65 {
  border-right-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-r-sky-50\/70 {
  border-right-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-r-sky-50\/75 {
  border-right-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-r-sky-50\/80 {
  border-right-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-r-sky-50\/85 {
  border-right-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-r-sky-50\/90 {
  border-right-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-r-sky-50\/95 {
  border-right-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-r-sky-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-500\/0 {
  border-right-color: rgb(14 165 233 / 0) !important;
}

.tw-border-r-sky-500\/10 {
  border-right-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-r-sky-500\/100 {
  border-right-color: rgb(14 165 233 / 1) !important;
}

.tw-border-r-sky-500\/15 {
  border-right-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-r-sky-500\/20 {
  border-right-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-r-sky-500\/25 {
  border-right-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-r-sky-500\/30 {
  border-right-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-r-sky-500\/35 {
  border-right-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-r-sky-500\/40 {
  border-right-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-r-sky-500\/45 {
  border-right-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-r-sky-500\/5 {
  border-right-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-r-sky-500\/50 {
  border-right-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-r-sky-500\/55 {
  border-right-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-r-sky-500\/60 {
  border-right-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-r-sky-500\/65 {
  border-right-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-r-sky-500\/70 {
  border-right-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-r-sky-500\/75 {
  border-right-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-r-sky-500\/80 {
  border-right-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-r-sky-500\/85 {
  border-right-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-r-sky-500\/90 {
  border-right-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-r-sky-500\/95 {
  border-right-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-r-sky-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-600\/0 {
  border-right-color: rgb(2 132 199 / 0) !important;
}

.tw-border-r-sky-600\/10 {
  border-right-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-r-sky-600\/100 {
  border-right-color: rgb(2 132 199 / 1) !important;
}

.tw-border-r-sky-600\/15 {
  border-right-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-r-sky-600\/20 {
  border-right-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-r-sky-600\/25 {
  border-right-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-r-sky-600\/30 {
  border-right-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-r-sky-600\/35 {
  border-right-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-r-sky-600\/40 {
  border-right-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-r-sky-600\/45 {
  border-right-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-r-sky-600\/5 {
  border-right-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-r-sky-600\/50 {
  border-right-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-r-sky-600\/55 {
  border-right-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-r-sky-600\/60 {
  border-right-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-r-sky-600\/65 {
  border-right-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-r-sky-600\/70 {
  border-right-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-r-sky-600\/75 {
  border-right-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-r-sky-600\/80 {
  border-right-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-r-sky-600\/85 {
  border-right-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-r-sky-600\/90 {
  border-right-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-r-sky-600\/95 {
  border-right-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-r-sky-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-700\/0 {
  border-right-color: rgb(3 105 161 / 0) !important;
}

.tw-border-r-sky-700\/10 {
  border-right-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-r-sky-700\/100 {
  border-right-color: rgb(3 105 161 / 1) !important;
}

.tw-border-r-sky-700\/15 {
  border-right-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-r-sky-700\/20 {
  border-right-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-r-sky-700\/25 {
  border-right-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-r-sky-700\/30 {
  border-right-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-r-sky-700\/35 {
  border-right-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-r-sky-700\/40 {
  border-right-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-r-sky-700\/45 {
  border-right-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-r-sky-700\/5 {
  border-right-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-r-sky-700\/50 {
  border-right-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-r-sky-700\/55 {
  border-right-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-r-sky-700\/60 {
  border-right-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-r-sky-700\/65 {
  border-right-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-r-sky-700\/70 {
  border-right-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-r-sky-700\/75 {
  border-right-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-r-sky-700\/80 {
  border-right-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-r-sky-700\/85 {
  border-right-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-r-sky-700\/90 {
  border-right-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-r-sky-700\/95 {
  border-right-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-r-sky-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-800\/0 {
  border-right-color: rgb(7 89 133 / 0) !important;
}

.tw-border-r-sky-800\/10 {
  border-right-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-r-sky-800\/100 {
  border-right-color: rgb(7 89 133 / 1) !important;
}

.tw-border-r-sky-800\/15 {
  border-right-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-r-sky-800\/20 {
  border-right-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-r-sky-800\/25 {
  border-right-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-r-sky-800\/30 {
  border-right-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-r-sky-800\/35 {
  border-right-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-r-sky-800\/40 {
  border-right-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-r-sky-800\/45 {
  border-right-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-r-sky-800\/5 {
  border-right-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-r-sky-800\/50 {
  border-right-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-r-sky-800\/55 {
  border-right-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-r-sky-800\/60 {
  border-right-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-r-sky-800\/65 {
  border-right-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-r-sky-800\/70 {
  border-right-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-r-sky-800\/75 {
  border-right-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-r-sky-800\/80 {
  border-right-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-r-sky-800\/85 {
  border-right-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-r-sky-800\/90 {
  border-right-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-r-sky-800\/95 {
  border-right-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-r-sky-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-900\/0 {
  border-right-color: rgb(12 74 110 / 0) !important;
}

.tw-border-r-sky-900\/10 {
  border-right-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-r-sky-900\/100 {
  border-right-color: rgb(12 74 110 / 1) !important;
}

.tw-border-r-sky-900\/15 {
  border-right-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-r-sky-900\/20 {
  border-right-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-r-sky-900\/25 {
  border-right-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-r-sky-900\/30 {
  border-right-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-r-sky-900\/35 {
  border-right-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-r-sky-900\/40 {
  border-right-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-r-sky-900\/45 {
  border-right-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-r-sky-900\/5 {
  border-right-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-r-sky-900\/50 {
  border-right-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-r-sky-900\/55 {
  border-right-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-r-sky-900\/60 {
  border-right-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-r-sky-900\/65 {
  border-right-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-r-sky-900\/70 {
  border-right-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-r-sky-900\/75 {
  border-right-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-r-sky-900\/80 {
  border-right-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-r-sky-900\/85 {
  border-right-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-r-sky-900\/90 {
  border-right-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-r-sky-900\/95 {
  border-right-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-r-sky-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-r-sky-950\/0 {
  border-right-color: rgb(8 47 73 / 0) !important;
}

.tw-border-r-sky-950\/10 {
  border-right-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-r-sky-950\/100 {
  border-right-color: rgb(8 47 73 / 1) !important;
}

.tw-border-r-sky-950\/15 {
  border-right-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-r-sky-950\/20 {
  border-right-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-r-sky-950\/25 {
  border-right-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-r-sky-950\/30 {
  border-right-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-r-sky-950\/35 {
  border-right-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-r-sky-950\/40 {
  border-right-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-r-sky-950\/45 {
  border-right-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-r-sky-950\/5 {
  border-right-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-r-sky-950\/50 {
  border-right-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-r-sky-950\/55 {
  border-right-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-r-sky-950\/60 {
  border-right-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-r-sky-950\/65 {
  border-right-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-r-sky-950\/70 {
  border-right-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-r-sky-950\/75 {
  border-right-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-r-sky-950\/80 {
  border-right-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-r-sky-950\/85 {
  border-right-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-r-sky-950\/90 {
  border-right-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-r-sky-950\/95 {
  border-right-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-r-slate-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-100\/0 {
  border-right-color: rgb(241 245 249 / 0) !important;
}

.tw-border-r-slate-100\/10 {
  border-right-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-r-slate-100\/100 {
  border-right-color: rgb(241 245 249 / 1) !important;
}

.tw-border-r-slate-100\/15 {
  border-right-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-r-slate-100\/20 {
  border-right-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-r-slate-100\/25 {
  border-right-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-r-slate-100\/30 {
  border-right-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-r-slate-100\/35 {
  border-right-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-r-slate-100\/40 {
  border-right-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-r-slate-100\/45 {
  border-right-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-r-slate-100\/5 {
  border-right-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-r-slate-100\/50 {
  border-right-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-r-slate-100\/55 {
  border-right-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-r-slate-100\/60 {
  border-right-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-r-slate-100\/65 {
  border-right-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-r-slate-100\/70 {
  border-right-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-r-slate-100\/75 {
  border-right-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-r-slate-100\/80 {
  border-right-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-r-slate-100\/85 {
  border-right-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-r-slate-100\/90 {
  border-right-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-r-slate-100\/95 {
  border-right-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-r-slate-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-200\/0 {
  border-right-color: rgb(226 232 240 / 0) !important;
}

.tw-border-r-slate-200\/10 {
  border-right-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-r-slate-200\/100 {
  border-right-color: rgb(226 232 240 / 1) !important;
}

.tw-border-r-slate-200\/15 {
  border-right-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-r-slate-200\/20 {
  border-right-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-r-slate-200\/25 {
  border-right-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-r-slate-200\/30 {
  border-right-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-r-slate-200\/35 {
  border-right-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-r-slate-200\/40 {
  border-right-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-r-slate-200\/45 {
  border-right-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-r-slate-200\/5 {
  border-right-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-r-slate-200\/50 {
  border-right-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-r-slate-200\/55 {
  border-right-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-r-slate-200\/60 {
  border-right-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-r-slate-200\/65 {
  border-right-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-r-slate-200\/70 {
  border-right-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-r-slate-200\/75 {
  border-right-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-r-slate-200\/80 {
  border-right-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-r-slate-200\/85 {
  border-right-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-r-slate-200\/90 {
  border-right-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-r-slate-200\/95 {
  border-right-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-r-slate-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-300\/0 {
  border-right-color: rgb(203 213 225 / 0) !important;
}

.tw-border-r-slate-300\/10 {
  border-right-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-r-slate-300\/100 {
  border-right-color: rgb(203 213 225 / 1) !important;
}

.tw-border-r-slate-300\/15 {
  border-right-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-r-slate-300\/20 {
  border-right-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-r-slate-300\/25 {
  border-right-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-r-slate-300\/30 {
  border-right-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-r-slate-300\/35 {
  border-right-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-r-slate-300\/40 {
  border-right-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-r-slate-300\/45 {
  border-right-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-r-slate-300\/5 {
  border-right-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-r-slate-300\/50 {
  border-right-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-r-slate-300\/55 {
  border-right-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-r-slate-300\/60 {
  border-right-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-r-slate-300\/65 {
  border-right-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-r-slate-300\/70 {
  border-right-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-r-slate-300\/75 {
  border-right-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-r-slate-300\/80 {
  border-right-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-r-slate-300\/85 {
  border-right-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-r-slate-300\/90 {
  border-right-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-r-slate-300\/95 {
  border-right-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-r-slate-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-400\/0 {
  border-right-color: rgb(148 163 184 / 0) !important;
}

.tw-border-r-slate-400\/10 {
  border-right-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-r-slate-400\/100 {
  border-right-color: rgb(148 163 184 / 1) !important;
}

.tw-border-r-slate-400\/15 {
  border-right-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-r-slate-400\/20 {
  border-right-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-r-slate-400\/25 {
  border-right-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-r-slate-400\/30 {
  border-right-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-r-slate-400\/35 {
  border-right-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-r-slate-400\/40 {
  border-right-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-r-slate-400\/45 {
  border-right-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-r-slate-400\/5 {
  border-right-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-r-slate-400\/50 {
  border-right-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-r-slate-400\/55 {
  border-right-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-r-slate-400\/60 {
  border-right-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-r-slate-400\/65 {
  border-right-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-r-slate-400\/70 {
  border-right-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-r-slate-400\/75 {
  border-right-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-r-slate-400\/80 {
  border-right-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-r-slate-400\/85 {
  border-right-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-r-slate-400\/90 {
  border-right-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-r-slate-400\/95 {
  border-right-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-r-slate-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-50\/0 {
  border-right-color: rgb(248 250 252 / 0) !important;
}

.tw-border-r-slate-50\/10 {
  border-right-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-r-slate-50\/100 {
  border-right-color: rgb(248 250 252 / 1) !important;
}

.tw-border-r-slate-50\/15 {
  border-right-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-r-slate-50\/20 {
  border-right-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-r-slate-50\/25 {
  border-right-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-r-slate-50\/30 {
  border-right-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-r-slate-50\/35 {
  border-right-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-r-slate-50\/40 {
  border-right-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-r-slate-50\/45 {
  border-right-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-r-slate-50\/5 {
  border-right-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-r-slate-50\/50 {
  border-right-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-r-slate-50\/55 {
  border-right-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-r-slate-50\/60 {
  border-right-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-r-slate-50\/65 {
  border-right-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-r-slate-50\/70 {
  border-right-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-r-slate-50\/75 {
  border-right-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-r-slate-50\/80 {
  border-right-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-r-slate-50\/85 {
  border-right-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-r-slate-50\/90 {
  border-right-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-r-slate-50\/95 {
  border-right-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-r-slate-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-500\/0 {
  border-right-color: rgb(100 116 139 / 0) !important;
}

.tw-border-r-slate-500\/10 {
  border-right-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-r-slate-500\/100 {
  border-right-color: rgb(100 116 139 / 1) !important;
}

.tw-border-r-slate-500\/15 {
  border-right-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-r-slate-500\/20 {
  border-right-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-r-slate-500\/25 {
  border-right-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-r-slate-500\/30 {
  border-right-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-r-slate-500\/35 {
  border-right-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-r-slate-500\/40 {
  border-right-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-r-slate-500\/45 {
  border-right-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-r-slate-500\/5 {
  border-right-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-r-slate-500\/50 {
  border-right-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-r-slate-500\/55 {
  border-right-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-r-slate-500\/60 {
  border-right-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-r-slate-500\/65 {
  border-right-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-r-slate-500\/70 {
  border-right-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-r-slate-500\/75 {
  border-right-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-r-slate-500\/80 {
  border-right-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-r-slate-500\/85 {
  border-right-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-r-slate-500\/90 {
  border-right-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-r-slate-500\/95 {
  border-right-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-r-slate-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-600\/0 {
  border-right-color: rgb(71 85 105 / 0) !important;
}

.tw-border-r-slate-600\/10 {
  border-right-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-r-slate-600\/100 {
  border-right-color: rgb(71 85 105 / 1) !important;
}

.tw-border-r-slate-600\/15 {
  border-right-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-r-slate-600\/20 {
  border-right-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-r-slate-600\/25 {
  border-right-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-r-slate-600\/30 {
  border-right-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-r-slate-600\/35 {
  border-right-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-r-slate-600\/40 {
  border-right-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-r-slate-600\/45 {
  border-right-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-r-slate-600\/5 {
  border-right-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-r-slate-600\/50 {
  border-right-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-r-slate-600\/55 {
  border-right-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-r-slate-600\/60 {
  border-right-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-r-slate-600\/65 {
  border-right-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-r-slate-600\/70 {
  border-right-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-r-slate-600\/75 {
  border-right-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-r-slate-600\/80 {
  border-right-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-r-slate-600\/85 {
  border-right-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-r-slate-600\/90 {
  border-right-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-r-slate-600\/95 {
  border-right-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-r-slate-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-700\/0 {
  border-right-color: rgb(51 65 85 / 0) !important;
}

.tw-border-r-slate-700\/10 {
  border-right-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-r-slate-700\/100 {
  border-right-color: rgb(51 65 85 / 1) !important;
}

.tw-border-r-slate-700\/15 {
  border-right-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-r-slate-700\/20 {
  border-right-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-r-slate-700\/25 {
  border-right-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-r-slate-700\/30 {
  border-right-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-r-slate-700\/35 {
  border-right-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-r-slate-700\/40 {
  border-right-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-r-slate-700\/45 {
  border-right-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-r-slate-700\/5 {
  border-right-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-r-slate-700\/50 {
  border-right-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-r-slate-700\/55 {
  border-right-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-r-slate-700\/60 {
  border-right-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-r-slate-700\/65 {
  border-right-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-r-slate-700\/70 {
  border-right-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-r-slate-700\/75 {
  border-right-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-r-slate-700\/80 {
  border-right-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-r-slate-700\/85 {
  border-right-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-r-slate-700\/90 {
  border-right-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-r-slate-700\/95 {
  border-right-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-r-slate-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-800\/0 {
  border-right-color: rgb(30 41 59 / 0) !important;
}

.tw-border-r-slate-800\/10 {
  border-right-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-r-slate-800\/100 {
  border-right-color: rgb(30 41 59 / 1) !important;
}

.tw-border-r-slate-800\/15 {
  border-right-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-r-slate-800\/20 {
  border-right-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-r-slate-800\/25 {
  border-right-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-r-slate-800\/30 {
  border-right-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-r-slate-800\/35 {
  border-right-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-r-slate-800\/40 {
  border-right-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-r-slate-800\/45 {
  border-right-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-r-slate-800\/5 {
  border-right-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-r-slate-800\/50 {
  border-right-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-r-slate-800\/55 {
  border-right-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-r-slate-800\/60 {
  border-right-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-r-slate-800\/65 {
  border-right-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-r-slate-800\/70 {
  border-right-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-r-slate-800\/75 {
  border-right-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-r-slate-800\/80 {
  border-right-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-r-slate-800\/85 {
  border-right-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-r-slate-800\/90 {
  border-right-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-r-slate-800\/95 {
  border-right-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-r-slate-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-900\/0 {
  border-right-color: rgb(15 23 42 / 0) !important;
}

.tw-border-r-slate-900\/10 {
  border-right-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-r-slate-900\/100 {
  border-right-color: rgb(15 23 42 / 1) !important;
}

.tw-border-r-slate-900\/15 {
  border-right-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-r-slate-900\/20 {
  border-right-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-r-slate-900\/25 {
  border-right-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-r-slate-900\/30 {
  border-right-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-r-slate-900\/35 {
  border-right-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-r-slate-900\/40 {
  border-right-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-r-slate-900\/45 {
  border-right-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-r-slate-900\/5 {
  border-right-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-r-slate-900\/50 {
  border-right-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-r-slate-900\/55 {
  border-right-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-r-slate-900\/60 {
  border-right-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-r-slate-900\/65 {
  border-right-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-r-slate-900\/70 {
  border-right-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-r-slate-900\/75 {
  border-right-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-r-slate-900\/80 {
  border-right-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-r-slate-900\/85 {
  border-right-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-r-slate-900\/90 {
  border-right-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-r-slate-900\/95 {
  border-right-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-r-slate-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-r-slate-950\/0 {
  border-right-color: rgb(2 6 23 / 0) !important;
}

.tw-border-r-slate-950\/10 {
  border-right-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-r-slate-950\/100 {
  border-right-color: rgb(2 6 23 / 1) !important;
}

.tw-border-r-slate-950\/15 {
  border-right-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-r-slate-950\/20 {
  border-right-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-r-slate-950\/25 {
  border-right-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-r-slate-950\/30 {
  border-right-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-r-slate-950\/35 {
  border-right-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-r-slate-950\/40 {
  border-right-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-r-slate-950\/45 {
  border-right-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-r-slate-950\/5 {
  border-right-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-r-slate-950\/50 {
  border-right-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-r-slate-950\/55 {
  border-right-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-r-slate-950\/60 {
  border-right-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-r-slate-950\/65 {
  border-right-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-r-slate-950\/70 {
  border-right-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-r-slate-950\/75 {
  border-right-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-r-slate-950\/80 {
  border-right-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-r-slate-950\/85 {
  border-right-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-r-slate-950\/90 {
  border-right-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-r-slate-950\/95 {
  border-right-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-r-stone-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-100\/0 {
  border-right-color: rgb(245 245 244 / 0) !important;
}

.tw-border-r-stone-100\/10 {
  border-right-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-r-stone-100\/100 {
  border-right-color: rgb(245 245 244 / 1) !important;
}

.tw-border-r-stone-100\/15 {
  border-right-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-r-stone-100\/20 {
  border-right-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-r-stone-100\/25 {
  border-right-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-r-stone-100\/30 {
  border-right-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-r-stone-100\/35 {
  border-right-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-r-stone-100\/40 {
  border-right-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-r-stone-100\/45 {
  border-right-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-r-stone-100\/5 {
  border-right-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-r-stone-100\/50 {
  border-right-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-r-stone-100\/55 {
  border-right-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-r-stone-100\/60 {
  border-right-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-r-stone-100\/65 {
  border-right-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-r-stone-100\/70 {
  border-right-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-r-stone-100\/75 {
  border-right-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-r-stone-100\/80 {
  border-right-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-r-stone-100\/85 {
  border-right-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-r-stone-100\/90 {
  border-right-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-r-stone-100\/95 {
  border-right-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-r-stone-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-200\/0 {
  border-right-color: rgb(231 229 228 / 0) !important;
}

.tw-border-r-stone-200\/10 {
  border-right-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-r-stone-200\/100 {
  border-right-color: rgb(231 229 228 / 1) !important;
}

.tw-border-r-stone-200\/15 {
  border-right-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-r-stone-200\/20 {
  border-right-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-r-stone-200\/25 {
  border-right-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-r-stone-200\/30 {
  border-right-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-r-stone-200\/35 {
  border-right-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-r-stone-200\/40 {
  border-right-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-r-stone-200\/45 {
  border-right-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-r-stone-200\/5 {
  border-right-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-r-stone-200\/50 {
  border-right-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-r-stone-200\/55 {
  border-right-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-r-stone-200\/60 {
  border-right-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-r-stone-200\/65 {
  border-right-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-r-stone-200\/70 {
  border-right-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-r-stone-200\/75 {
  border-right-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-r-stone-200\/80 {
  border-right-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-r-stone-200\/85 {
  border-right-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-r-stone-200\/90 {
  border-right-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-r-stone-200\/95 {
  border-right-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-r-stone-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-300\/0 {
  border-right-color: rgb(214 211 209 / 0) !important;
}

.tw-border-r-stone-300\/10 {
  border-right-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-r-stone-300\/100 {
  border-right-color: rgb(214 211 209 / 1) !important;
}

.tw-border-r-stone-300\/15 {
  border-right-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-r-stone-300\/20 {
  border-right-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-r-stone-300\/25 {
  border-right-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-r-stone-300\/30 {
  border-right-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-r-stone-300\/35 {
  border-right-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-r-stone-300\/40 {
  border-right-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-r-stone-300\/45 {
  border-right-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-r-stone-300\/5 {
  border-right-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-r-stone-300\/50 {
  border-right-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-r-stone-300\/55 {
  border-right-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-r-stone-300\/60 {
  border-right-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-r-stone-300\/65 {
  border-right-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-r-stone-300\/70 {
  border-right-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-r-stone-300\/75 {
  border-right-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-r-stone-300\/80 {
  border-right-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-r-stone-300\/85 {
  border-right-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-r-stone-300\/90 {
  border-right-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-r-stone-300\/95 {
  border-right-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-r-stone-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-400\/0 {
  border-right-color: rgb(168 162 158 / 0) !important;
}

.tw-border-r-stone-400\/10 {
  border-right-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-r-stone-400\/100 {
  border-right-color: rgb(168 162 158 / 1) !important;
}

.tw-border-r-stone-400\/15 {
  border-right-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-r-stone-400\/20 {
  border-right-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-r-stone-400\/25 {
  border-right-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-r-stone-400\/30 {
  border-right-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-r-stone-400\/35 {
  border-right-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-r-stone-400\/40 {
  border-right-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-r-stone-400\/45 {
  border-right-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-r-stone-400\/5 {
  border-right-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-r-stone-400\/50 {
  border-right-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-r-stone-400\/55 {
  border-right-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-r-stone-400\/60 {
  border-right-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-r-stone-400\/65 {
  border-right-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-r-stone-400\/70 {
  border-right-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-r-stone-400\/75 {
  border-right-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-r-stone-400\/80 {
  border-right-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-r-stone-400\/85 {
  border-right-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-r-stone-400\/90 {
  border-right-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-r-stone-400\/95 {
  border-right-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-r-stone-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-50\/0 {
  border-right-color: rgb(250 250 249 / 0) !important;
}

.tw-border-r-stone-50\/10 {
  border-right-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-r-stone-50\/100 {
  border-right-color: rgb(250 250 249 / 1) !important;
}

.tw-border-r-stone-50\/15 {
  border-right-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-r-stone-50\/20 {
  border-right-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-r-stone-50\/25 {
  border-right-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-r-stone-50\/30 {
  border-right-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-r-stone-50\/35 {
  border-right-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-r-stone-50\/40 {
  border-right-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-r-stone-50\/45 {
  border-right-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-r-stone-50\/5 {
  border-right-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-r-stone-50\/50 {
  border-right-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-r-stone-50\/55 {
  border-right-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-r-stone-50\/60 {
  border-right-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-r-stone-50\/65 {
  border-right-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-r-stone-50\/70 {
  border-right-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-r-stone-50\/75 {
  border-right-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-r-stone-50\/80 {
  border-right-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-r-stone-50\/85 {
  border-right-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-r-stone-50\/90 {
  border-right-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-r-stone-50\/95 {
  border-right-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-r-stone-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-500\/0 {
  border-right-color: rgb(120 113 108 / 0) !important;
}

.tw-border-r-stone-500\/10 {
  border-right-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-r-stone-500\/100 {
  border-right-color: rgb(120 113 108 / 1) !important;
}

.tw-border-r-stone-500\/15 {
  border-right-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-r-stone-500\/20 {
  border-right-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-r-stone-500\/25 {
  border-right-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-r-stone-500\/30 {
  border-right-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-r-stone-500\/35 {
  border-right-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-r-stone-500\/40 {
  border-right-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-r-stone-500\/45 {
  border-right-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-r-stone-500\/5 {
  border-right-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-r-stone-500\/50 {
  border-right-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-r-stone-500\/55 {
  border-right-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-r-stone-500\/60 {
  border-right-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-r-stone-500\/65 {
  border-right-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-r-stone-500\/70 {
  border-right-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-r-stone-500\/75 {
  border-right-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-r-stone-500\/80 {
  border-right-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-r-stone-500\/85 {
  border-right-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-r-stone-500\/90 {
  border-right-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-r-stone-500\/95 {
  border-right-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-r-stone-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-600\/0 {
  border-right-color: rgb(87 83 78 / 0) !important;
}

.tw-border-r-stone-600\/10 {
  border-right-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-r-stone-600\/100 {
  border-right-color: rgb(87 83 78 / 1) !important;
}

.tw-border-r-stone-600\/15 {
  border-right-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-r-stone-600\/20 {
  border-right-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-r-stone-600\/25 {
  border-right-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-r-stone-600\/30 {
  border-right-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-r-stone-600\/35 {
  border-right-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-r-stone-600\/40 {
  border-right-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-r-stone-600\/45 {
  border-right-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-r-stone-600\/5 {
  border-right-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-r-stone-600\/50 {
  border-right-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-r-stone-600\/55 {
  border-right-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-r-stone-600\/60 {
  border-right-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-r-stone-600\/65 {
  border-right-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-r-stone-600\/70 {
  border-right-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-r-stone-600\/75 {
  border-right-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-r-stone-600\/80 {
  border-right-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-r-stone-600\/85 {
  border-right-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-r-stone-600\/90 {
  border-right-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-r-stone-600\/95 {
  border-right-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-r-stone-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-700\/0 {
  border-right-color: rgb(68 64 60 / 0) !important;
}

.tw-border-r-stone-700\/10 {
  border-right-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-r-stone-700\/100 {
  border-right-color: rgb(68 64 60 / 1) !important;
}

.tw-border-r-stone-700\/15 {
  border-right-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-r-stone-700\/20 {
  border-right-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-r-stone-700\/25 {
  border-right-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-r-stone-700\/30 {
  border-right-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-r-stone-700\/35 {
  border-right-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-r-stone-700\/40 {
  border-right-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-r-stone-700\/45 {
  border-right-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-r-stone-700\/5 {
  border-right-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-r-stone-700\/50 {
  border-right-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-r-stone-700\/55 {
  border-right-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-r-stone-700\/60 {
  border-right-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-r-stone-700\/65 {
  border-right-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-r-stone-700\/70 {
  border-right-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-r-stone-700\/75 {
  border-right-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-r-stone-700\/80 {
  border-right-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-r-stone-700\/85 {
  border-right-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-r-stone-700\/90 {
  border-right-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-r-stone-700\/95 {
  border-right-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-r-stone-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-800\/0 {
  border-right-color: rgb(41 37 36 / 0) !important;
}

.tw-border-r-stone-800\/10 {
  border-right-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-r-stone-800\/100 {
  border-right-color: rgb(41 37 36 / 1) !important;
}

.tw-border-r-stone-800\/15 {
  border-right-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-r-stone-800\/20 {
  border-right-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-r-stone-800\/25 {
  border-right-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-r-stone-800\/30 {
  border-right-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-r-stone-800\/35 {
  border-right-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-r-stone-800\/40 {
  border-right-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-r-stone-800\/45 {
  border-right-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-r-stone-800\/5 {
  border-right-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-r-stone-800\/50 {
  border-right-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-r-stone-800\/55 {
  border-right-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-r-stone-800\/60 {
  border-right-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-r-stone-800\/65 {
  border-right-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-r-stone-800\/70 {
  border-right-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-r-stone-800\/75 {
  border-right-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-r-stone-800\/80 {
  border-right-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-r-stone-800\/85 {
  border-right-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-r-stone-800\/90 {
  border-right-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-r-stone-800\/95 {
  border-right-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-r-stone-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-900\/0 {
  border-right-color: rgb(28 25 23 / 0) !important;
}

.tw-border-r-stone-900\/10 {
  border-right-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-r-stone-900\/100 {
  border-right-color: rgb(28 25 23 / 1) !important;
}

.tw-border-r-stone-900\/15 {
  border-right-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-r-stone-900\/20 {
  border-right-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-r-stone-900\/25 {
  border-right-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-r-stone-900\/30 {
  border-right-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-r-stone-900\/35 {
  border-right-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-r-stone-900\/40 {
  border-right-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-r-stone-900\/45 {
  border-right-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-r-stone-900\/5 {
  border-right-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-r-stone-900\/50 {
  border-right-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-r-stone-900\/55 {
  border-right-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-r-stone-900\/60 {
  border-right-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-r-stone-900\/65 {
  border-right-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-r-stone-900\/70 {
  border-right-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-r-stone-900\/75 {
  border-right-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-r-stone-900\/80 {
  border-right-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-r-stone-900\/85 {
  border-right-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-r-stone-900\/90 {
  border-right-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-r-stone-900\/95 {
  border-right-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-r-stone-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-r-stone-950\/0 {
  border-right-color: rgb(12 10 9 / 0) !important;
}

.tw-border-r-stone-950\/10 {
  border-right-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-r-stone-950\/100 {
  border-right-color: rgb(12 10 9 / 1) !important;
}

.tw-border-r-stone-950\/15 {
  border-right-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-r-stone-950\/20 {
  border-right-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-r-stone-950\/25 {
  border-right-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-r-stone-950\/30 {
  border-right-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-r-stone-950\/35 {
  border-right-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-r-stone-950\/40 {
  border-right-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-r-stone-950\/45 {
  border-right-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-r-stone-950\/5 {
  border-right-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-r-stone-950\/50 {
  border-right-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-r-stone-950\/55 {
  border-right-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-r-stone-950\/60 {
  border-right-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-r-stone-950\/65 {
  border-right-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-r-stone-950\/70 {
  border-right-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-r-stone-950\/75 {
  border-right-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-r-stone-950\/80 {
  border-right-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-r-stone-950\/85 {
  border-right-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-r-stone-950\/90 {
  border-right-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-r-stone-950\/95 {
  border-right-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-r-teal-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-100\/0 {
  border-right-color: rgb(204 251 241 / 0) !important;
}

.tw-border-r-teal-100\/10 {
  border-right-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-r-teal-100\/100 {
  border-right-color: rgb(204 251 241 / 1) !important;
}

.tw-border-r-teal-100\/15 {
  border-right-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-r-teal-100\/20 {
  border-right-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-r-teal-100\/25 {
  border-right-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-r-teal-100\/30 {
  border-right-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-r-teal-100\/35 {
  border-right-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-r-teal-100\/40 {
  border-right-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-r-teal-100\/45 {
  border-right-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-r-teal-100\/5 {
  border-right-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-r-teal-100\/50 {
  border-right-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-r-teal-100\/55 {
  border-right-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-r-teal-100\/60 {
  border-right-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-r-teal-100\/65 {
  border-right-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-r-teal-100\/70 {
  border-right-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-r-teal-100\/75 {
  border-right-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-r-teal-100\/80 {
  border-right-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-r-teal-100\/85 {
  border-right-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-r-teal-100\/90 {
  border-right-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-r-teal-100\/95 {
  border-right-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-r-teal-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-200\/0 {
  border-right-color: rgb(153 246 228 / 0) !important;
}

.tw-border-r-teal-200\/10 {
  border-right-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-r-teal-200\/100 {
  border-right-color: rgb(153 246 228 / 1) !important;
}

.tw-border-r-teal-200\/15 {
  border-right-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-r-teal-200\/20 {
  border-right-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-r-teal-200\/25 {
  border-right-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-r-teal-200\/30 {
  border-right-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-r-teal-200\/35 {
  border-right-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-r-teal-200\/40 {
  border-right-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-r-teal-200\/45 {
  border-right-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-r-teal-200\/5 {
  border-right-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-r-teal-200\/50 {
  border-right-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-r-teal-200\/55 {
  border-right-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-r-teal-200\/60 {
  border-right-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-r-teal-200\/65 {
  border-right-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-r-teal-200\/70 {
  border-right-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-r-teal-200\/75 {
  border-right-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-r-teal-200\/80 {
  border-right-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-r-teal-200\/85 {
  border-right-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-r-teal-200\/90 {
  border-right-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-r-teal-200\/95 {
  border-right-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-r-teal-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-300\/0 {
  border-right-color: rgb(94 234 212 / 0) !important;
}

.tw-border-r-teal-300\/10 {
  border-right-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-r-teal-300\/100 {
  border-right-color: rgb(94 234 212 / 1) !important;
}

.tw-border-r-teal-300\/15 {
  border-right-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-r-teal-300\/20 {
  border-right-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-r-teal-300\/25 {
  border-right-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-r-teal-300\/30 {
  border-right-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-r-teal-300\/35 {
  border-right-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-r-teal-300\/40 {
  border-right-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-r-teal-300\/45 {
  border-right-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-r-teal-300\/5 {
  border-right-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-r-teal-300\/50 {
  border-right-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-r-teal-300\/55 {
  border-right-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-r-teal-300\/60 {
  border-right-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-r-teal-300\/65 {
  border-right-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-r-teal-300\/70 {
  border-right-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-r-teal-300\/75 {
  border-right-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-r-teal-300\/80 {
  border-right-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-r-teal-300\/85 {
  border-right-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-r-teal-300\/90 {
  border-right-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-r-teal-300\/95 {
  border-right-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-r-teal-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-400\/0 {
  border-right-color: rgb(45 212 191 / 0) !important;
}

.tw-border-r-teal-400\/10 {
  border-right-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-r-teal-400\/100 {
  border-right-color: rgb(45 212 191 / 1) !important;
}

.tw-border-r-teal-400\/15 {
  border-right-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-r-teal-400\/20 {
  border-right-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-r-teal-400\/25 {
  border-right-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-r-teal-400\/30 {
  border-right-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-r-teal-400\/35 {
  border-right-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-r-teal-400\/40 {
  border-right-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-r-teal-400\/45 {
  border-right-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-r-teal-400\/5 {
  border-right-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-r-teal-400\/50 {
  border-right-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-r-teal-400\/55 {
  border-right-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-r-teal-400\/60 {
  border-right-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-r-teal-400\/65 {
  border-right-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-r-teal-400\/70 {
  border-right-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-r-teal-400\/75 {
  border-right-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-r-teal-400\/80 {
  border-right-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-r-teal-400\/85 {
  border-right-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-r-teal-400\/90 {
  border-right-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-r-teal-400\/95 {
  border-right-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-r-teal-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-50\/0 {
  border-right-color: rgb(240 253 250 / 0) !important;
}

.tw-border-r-teal-50\/10 {
  border-right-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-r-teal-50\/100 {
  border-right-color: rgb(240 253 250 / 1) !important;
}

.tw-border-r-teal-50\/15 {
  border-right-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-r-teal-50\/20 {
  border-right-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-r-teal-50\/25 {
  border-right-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-r-teal-50\/30 {
  border-right-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-r-teal-50\/35 {
  border-right-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-r-teal-50\/40 {
  border-right-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-r-teal-50\/45 {
  border-right-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-r-teal-50\/5 {
  border-right-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-r-teal-50\/50 {
  border-right-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-r-teal-50\/55 {
  border-right-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-r-teal-50\/60 {
  border-right-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-r-teal-50\/65 {
  border-right-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-r-teal-50\/70 {
  border-right-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-r-teal-50\/75 {
  border-right-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-r-teal-50\/80 {
  border-right-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-r-teal-50\/85 {
  border-right-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-r-teal-50\/90 {
  border-right-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-r-teal-50\/95 {
  border-right-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-r-teal-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-500\/0 {
  border-right-color: rgb(20 184 166 / 0) !important;
}

.tw-border-r-teal-500\/10 {
  border-right-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-r-teal-500\/100 {
  border-right-color: rgb(20 184 166 / 1) !important;
}

.tw-border-r-teal-500\/15 {
  border-right-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-r-teal-500\/20 {
  border-right-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-r-teal-500\/25 {
  border-right-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-r-teal-500\/30 {
  border-right-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-r-teal-500\/35 {
  border-right-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-r-teal-500\/40 {
  border-right-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-r-teal-500\/45 {
  border-right-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-r-teal-500\/5 {
  border-right-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-r-teal-500\/50 {
  border-right-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-r-teal-500\/55 {
  border-right-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-r-teal-500\/60 {
  border-right-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-r-teal-500\/65 {
  border-right-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-r-teal-500\/70 {
  border-right-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-r-teal-500\/75 {
  border-right-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-r-teal-500\/80 {
  border-right-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-r-teal-500\/85 {
  border-right-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-r-teal-500\/90 {
  border-right-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-r-teal-500\/95 {
  border-right-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-r-teal-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-600\/0 {
  border-right-color: rgb(13 148 136 / 0) !important;
}

.tw-border-r-teal-600\/10 {
  border-right-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-r-teal-600\/100 {
  border-right-color: rgb(13 148 136 / 1) !important;
}

.tw-border-r-teal-600\/15 {
  border-right-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-r-teal-600\/20 {
  border-right-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-r-teal-600\/25 {
  border-right-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-r-teal-600\/30 {
  border-right-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-r-teal-600\/35 {
  border-right-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-r-teal-600\/40 {
  border-right-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-r-teal-600\/45 {
  border-right-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-r-teal-600\/5 {
  border-right-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-r-teal-600\/50 {
  border-right-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-r-teal-600\/55 {
  border-right-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-r-teal-600\/60 {
  border-right-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-r-teal-600\/65 {
  border-right-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-r-teal-600\/70 {
  border-right-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-r-teal-600\/75 {
  border-right-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-r-teal-600\/80 {
  border-right-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-r-teal-600\/85 {
  border-right-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-r-teal-600\/90 {
  border-right-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-r-teal-600\/95 {
  border-right-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-r-teal-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-700\/0 {
  border-right-color: rgb(15 118 110 / 0) !important;
}

.tw-border-r-teal-700\/10 {
  border-right-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-r-teal-700\/100 {
  border-right-color: rgb(15 118 110 / 1) !important;
}

.tw-border-r-teal-700\/15 {
  border-right-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-r-teal-700\/20 {
  border-right-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-r-teal-700\/25 {
  border-right-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-r-teal-700\/30 {
  border-right-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-r-teal-700\/35 {
  border-right-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-r-teal-700\/40 {
  border-right-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-r-teal-700\/45 {
  border-right-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-r-teal-700\/5 {
  border-right-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-r-teal-700\/50 {
  border-right-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-r-teal-700\/55 {
  border-right-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-r-teal-700\/60 {
  border-right-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-r-teal-700\/65 {
  border-right-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-r-teal-700\/70 {
  border-right-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-r-teal-700\/75 {
  border-right-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-r-teal-700\/80 {
  border-right-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-r-teal-700\/85 {
  border-right-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-r-teal-700\/90 {
  border-right-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-r-teal-700\/95 {
  border-right-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-r-teal-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-800\/0 {
  border-right-color: rgb(17 94 89 / 0) !important;
}

.tw-border-r-teal-800\/10 {
  border-right-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-r-teal-800\/100 {
  border-right-color: rgb(17 94 89 / 1) !important;
}

.tw-border-r-teal-800\/15 {
  border-right-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-r-teal-800\/20 {
  border-right-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-r-teal-800\/25 {
  border-right-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-r-teal-800\/30 {
  border-right-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-r-teal-800\/35 {
  border-right-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-r-teal-800\/40 {
  border-right-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-r-teal-800\/45 {
  border-right-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-r-teal-800\/5 {
  border-right-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-r-teal-800\/50 {
  border-right-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-r-teal-800\/55 {
  border-right-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-r-teal-800\/60 {
  border-right-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-r-teal-800\/65 {
  border-right-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-r-teal-800\/70 {
  border-right-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-r-teal-800\/75 {
  border-right-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-r-teal-800\/80 {
  border-right-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-r-teal-800\/85 {
  border-right-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-r-teal-800\/90 {
  border-right-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-r-teal-800\/95 {
  border-right-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-r-teal-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-900\/0 {
  border-right-color: rgb(19 78 74 / 0) !important;
}

.tw-border-r-teal-900\/10 {
  border-right-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-r-teal-900\/100 {
  border-right-color: rgb(19 78 74 / 1) !important;
}

.tw-border-r-teal-900\/15 {
  border-right-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-r-teal-900\/20 {
  border-right-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-r-teal-900\/25 {
  border-right-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-r-teal-900\/30 {
  border-right-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-r-teal-900\/35 {
  border-right-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-r-teal-900\/40 {
  border-right-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-r-teal-900\/45 {
  border-right-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-r-teal-900\/5 {
  border-right-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-r-teal-900\/50 {
  border-right-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-r-teal-900\/55 {
  border-right-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-r-teal-900\/60 {
  border-right-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-r-teal-900\/65 {
  border-right-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-r-teal-900\/70 {
  border-right-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-r-teal-900\/75 {
  border-right-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-r-teal-900\/80 {
  border-right-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-r-teal-900\/85 {
  border-right-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-r-teal-900\/90 {
  border-right-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-r-teal-900\/95 {
  border-right-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-r-teal-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-r-teal-950\/0 {
  border-right-color: rgb(4 47 46 / 0) !important;
}

.tw-border-r-teal-950\/10 {
  border-right-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-r-teal-950\/100 {
  border-right-color: rgb(4 47 46 / 1) !important;
}

.tw-border-r-teal-950\/15 {
  border-right-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-r-teal-950\/20 {
  border-right-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-r-teal-950\/25 {
  border-right-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-r-teal-950\/30 {
  border-right-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-r-teal-950\/35 {
  border-right-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-r-teal-950\/40 {
  border-right-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-r-teal-950\/45 {
  border-right-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-r-teal-950\/5 {
  border-right-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-r-teal-950\/50 {
  border-right-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-r-teal-950\/55 {
  border-right-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-r-teal-950\/60 {
  border-right-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-r-teal-950\/65 {
  border-right-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-r-teal-950\/70 {
  border-right-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-r-teal-950\/75 {
  border-right-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-r-teal-950\/80 {
  border-right-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-r-teal-950\/85 {
  border-right-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-r-teal-950\/90 {
  border-right-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-r-teal-950\/95 {
  border-right-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-r-transparent {
  border-right-color: transparent !important;
}

.tw-border-r-transparent\/0 {
  border-right-color: rgb(0 0 0 / 0) !important;
}

.tw-border-r-transparent\/10 {
  border-right-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-r-transparent\/100 {
  border-right-color: rgb(0 0 0 / 1) !important;
}

.tw-border-r-transparent\/15 {
  border-right-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-r-transparent\/20 {
  border-right-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-r-transparent\/25 {
  border-right-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-r-transparent\/30 {
  border-right-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-r-transparent\/35 {
  border-right-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-r-transparent\/40 {
  border-right-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-r-transparent\/45 {
  border-right-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-r-transparent\/5 {
  border-right-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-r-transparent\/50 {
  border-right-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-r-transparent\/55 {
  border-right-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-r-transparent\/60 {
  border-right-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-r-transparent\/65 {
  border-right-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-r-transparent\/70 {
  border-right-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-r-transparent\/75 {
  border-right-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-r-transparent\/80 {
  border-right-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-r-transparent\/85 {
  border-right-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-r-transparent\/90 {
  border-right-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-r-transparent\/95 {
  border-right-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-r-violet-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-100\/0 {
  border-right-color: rgb(237 233 254 / 0) !important;
}

.tw-border-r-violet-100\/10 {
  border-right-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-r-violet-100\/100 {
  border-right-color: rgb(237 233 254 / 1) !important;
}

.tw-border-r-violet-100\/15 {
  border-right-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-r-violet-100\/20 {
  border-right-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-r-violet-100\/25 {
  border-right-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-r-violet-100\/30 {
  border-right-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-r-violet-100\/35 {
  border-right-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-r-violet-100\/40 {
  border-right-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-r-violet-100\/45 {
  border-right-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-r-violet-100\/5 {
  border-right-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-r-violet-100\/50 {
  border-right-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-r-violet-100\/55 {
  border-right-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-r-violet-100\/60 {
  border-right-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-r-violet-100\/65 {
  border-right-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-r-violet-100\/70 {
  border-right-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-r-violet-100\/75 {
  border-right-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-r-violet-100\/80 {
  border-right-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-r-violet-100\/85 {
  border-right-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-r-violet-100\/90 {
  border-right-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-r-violet-100\/95 {
  border-right-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-r-violet-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-200\/0 {
  border-right-color: rgb(221 214 254 / 0) !important;
}

.tw-border-r-violet-200\/10 {
  border-right-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-r-violet-200\/100 {
  border-right-color: rgb(221 214 254 / 1) !important;
}

.tw-border-r-violet-200\/15 {
  border-right-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-r-violet-200\/20 {
  border-right-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-r-violet-200\/25 {
  border-right-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-r-violet-200\/30 {
  border-right-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-r-violet-200\/35 {
  border-right-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-r-violet-200\/40 {
  border-right-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-r-violet-200\/45 {
  border-right-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-r-violet-200\/5 {
  border-right-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-r-violet-200\/50 {
  border-right-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-r-violet-200\/55 {
  border-right-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-r-violet-200\/60 {
  border-right-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-r-violet-200\/65 {
  border-right-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-r-violet-200\/70 {
  border-right-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-r-violet-200\/75 {
  border-right-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-r-violet-200\/80 {
  border-right-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-r-violet-200\/85 {
  border-right-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-r-violet-200\/90 {
  border-right-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-r-violet-200\/95 {
  border-right-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-r-violet-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-300\/0 {
  border-right-color: rgb(196 181 253 / 0) !important;
}

.tw-border-r-violet-300\/10 {
  border-right-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-r-violet-300\/100 {
  border-right-color: rgb(196 181 253 / 1) !important;
}

.tw-border-r-violet-300\/15 {
  border-right-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-r-violet-300\/20 {
  border-right-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-r-violet-300\/25 {
  border-right-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-r-violet-300\/30 {
  border-right-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-r-violet-300\/35 {
  border-right-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-r-violet-300\/40 {
  border-right-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-r-violet-300\/45 {
  border-right-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-r-violet-300\/5 {
  border-right-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-r-violet-300\/50 {
  border-right-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-r-violet-300\/55 {
  border-right-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-r-violet-300\/60 {
  border-right-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-r-violet-300\/65 {
  border-right-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-r-violet-300\/70 {
  border-right-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-r-violet-300\/75 {
  border-right-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-r-violet-300\/80 {
  border-right-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-r-violet-300\/85 {
  border-right-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-r-violet-300\/90 {
  border-right-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-r-violet-300\/95 {
  border-right-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-r-violet-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-400\/0 {
  border-right-color: rgb(167 139 250 / 0) !important;
}

.tw-border-r-violet-400\/10 {
  border-right-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-r-violet-400\/100 {
  border-right-color: rgb(167 139 250 / 1) !important;
}

.tw-border-r-violet-400\/15 {
  border-right-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-r-violet-400\/20 {
  border-right-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-r-violet-400\/25 {
  border-right-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-r-violet-400\/30 {
  border-right-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-r-violet-400\/35 {
  border-right-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-r-violet-400\/40 {
  border-right-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-r-violet-400\/45 {
  border-right-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-r-violet-400\/5 {
  border-right-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-r-violet-400\/50 {
  border-right-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-r-violet-400\/55 {
  border-right-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-r-violet-400\/60 {
  border-right-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-r-violet-400\/65 {
  border-right-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-r-violet-400\/70 {
  border-right-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-r-violet-400\/75 {
  border-right-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-r-violet-400\/80 {
  border-right-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-r-violet-400\/85 {
  border-right-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-r-violet-400\/90 {
  border-right-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-r-violet-400\/95 {
  border-right-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-r-violet-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-50\/0 {
  border-right-color: rgb(245 243 255 / 0) !important;
}

.tw-border-r-violet-50\/10 {
  border-right-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-r-violet-50\/100 {
  border-right-color: rgb(245 243 255 / 1) !important;
}

.tw-border-r-violet-50\/15 {
  border-right-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-r-violet-50\/20 {
  border-right-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-r-violet-50\/25 {
  border-right-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-r-violet-50\/30 {
  border-right-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-r-violet-50\/35 {
  border-right-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-r-violet-50\/40 {
  border-right-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-r-violet-50\/45 {
  border-right-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-r-violet-50\/5 {
  border-right-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-r-violet-50\/50 {
  border-right-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-r-violet-50\/55 {
  border-right-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-r-violet-50\/60 {
  border-right-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-r-violet-50\/65 {
  border-right-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-r-violet-50\/70 {
  border-right-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-r-violet-50\/75 {
  border-right-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-r-violet-50\/80 {
  border-right-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-r-violet-50\/85 {
  border-right-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-r-violet-50\/90 {
  border-right-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-r-violet-50\/95 {
  border-right-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-r-violet-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-500\/0 {
  border-right-color: rgb(139 92 246 / 0) !important;
}

.tw-border-r-violet-500\/10 {
  border-right-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-r-violet-500\/100 {
  border-right-color: rgb(139 92 246 / 1) !important;
}

.tw-border-r-violet-500\/15 {
  border-right-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-r-violet-500\/20 {
  border-right-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-r-violet-500\/25 {
  border-right-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-r-violet-500\/30 {
  border-right-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-r-violet-500\/35 {
  border-right-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-r-violet-500\/40 {
  border-right-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-r-violet-500\/45 {
  border-right-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-r-violet-500\/5 {
  border-right-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-r-violet-500\/50 {
  border-right-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-r-violet-500\/55 {
  border-right-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-r-violet-500\/60 {
  border-right-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-r-violet-500\/65 {
  border-right-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-r-violet-500\/70 {
  border-right-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-r-violet-500\/75 {
  border-right-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-r-violet-500\/80 {
  border-right-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-r-violet-500\/85 {
  border-right-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-r-violet-500\/90 {
  border-right-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-r-violet-500\/95 {
  border-right-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-r-violet-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-600\/0 {
  border-right-color: rgb(124 58 237 / 0) !important;
}

.tw-border-r-violet-600\/10 {
  border-right-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-r-violet-600\/100 {
  border-right-color: rgb(124 58 237 / 1) !important;
}

.tw-border-r-violet-600\/15 {
  border-right-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-r-violet-600\/20 {
  border-right-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-r-violet-600\/25 {
  border-right-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-r-violet-600\/30 {
  border-right-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-r-violet-600\/35 {
  border-right-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-r-violet-600\/40 {
  border-right-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-r-violet-600\/45 {
  border-right-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-r-violet-600\/5 {
  border-right-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-r-violet-600\/50 {
  border-right-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-r-violet-600\/55 {
  border-right-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-r-violet-600\/60 {
  border-right-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-r-violet-600\/65 {
  border-right-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-r-violet-600\/70 {
  border-right-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-r-violet-600\/75 {
  border-right-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-r-violet-600\/80 {
  border-right-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-r-violet-600\/85 {
  border-right-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-r-violet-600\/90 {
  border-right-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-r-violet-600\/95 {
  border-right-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-r-violet-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-700\/0 {
  border-right-color: rgb(109 40 217 / 0) !important;
}

.tw-border-r-violet-700\/10 {
  border-right-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-r-violet-700\/100 {
  border-right-color: rgb(109 40 217 / 1) !important;
}

.tw-border-r-violet-700\/15 {
  border-right-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-r-violet-700\/20 {
  border-right-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-r-violet-700\/25 {
  border-right-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-r-violet-700\/30 {
  border-right-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-r-violet-700\/35 {
  border-right-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-r-violet-700\/40 {
  border-right-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-r-violet-700\/45 {
  border-right-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-r-violet-700\/5 {
  border-right-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-r-violet-700\/50 {
  border-right-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-r-violet-700\/55 {
  border-right-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-r-violet-700\/60 {
  border-right-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-r-violet-700\/65 {
  border-right-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-r-violet-700\/70 {
  border-right-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-r-violet-700\/75 {
  border-right-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-r-violet-700\/80 {
  border-right-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-r-violet-700\/85 {
  border-right-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-r-violet-700\/90 {
  border-right-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-r-violet-700\/95 {
  border-right-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-r-violet-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-800\/0 {
  border-right-color: rgb(91 33 182 / 0) !important;
}

.tw-border-r-violet-800\/10 {
  border-right-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-r-violet-800\/100 {
  border-right-color: rgb(91 33 182 / 1) !important;
}

.tw-border-r-violet-800\/15 {
  border-right-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-r-violet-800\/20 {
  border-right-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-r-violet-800\/25 {
  border-right-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-r-violet-800\/30 {
  border-right-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-r-violet-800\/35 {
  border-right-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-r-violet-800\/40 {
  border-right-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-r-violet-800\/45 {
  border-right-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-r-violet-800\/5 {
  border-right-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-r-violet-800\/50 {
  border-right-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-r-violet-800\/55 {
  border-right-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-r-violet-800\/60 {
  border-right-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-r-violet-800\/65 {
  border-right-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-r-violet-800\/70 {
  border-right-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-r-violet-800\/75 {
  border-right-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-r-violet-800\/80 {
  border-right-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-r-violet-800\/85 {
  border-right-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-r-violet-800\/90 {
  border-right-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-r-violet-800\/95 {
  border-right-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-r-violet-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-900\/0 {
  border-right-color: rgb(76 29 149 / 0) !important;
}

.tw-border-r-violet-900\/10 {
  border-right-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-r-violet-900\/100 {
  border-right-color: rgb(76 29 149 / 1) !important;
}

.tw-border-r-violet-900\/15 {
  border-right-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-r-violet-900\/20 {
  border-right-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-r-violet-900\/25 {
  border-right-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-r-violet-900\/30 {
  border-right-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-r-violet-900\/35 {
  border-right-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-r-violet-900\/40 {
  border-right-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-r-violet-900\/45 {
  border-right-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-r-violet-900\/5 {
  border-right-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-r-violet-900\/50 {
  border-right-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-r-violet-900\/55 {
  border-right-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-r-violet-900\/60 {
  border-right-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-r-violet-900\/65 {
  border-right-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-r-violet-900\/70 {
  border-right-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-r-violet-900\/75 {
  border-right-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-r-violet-900\/80 {
  border-right-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-r-violet-900\/85 {
  border-right-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-r-violet-900\/90 {
  border-right-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-r-violet-900\/95 {
  border-right-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-r-violet-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-r-violet-950\/0 {
  border-right-color: rgb(46 16 101 / 0) !important;
}

.tw-border-r-violet-950\/10 {
  border-right-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-r-violet-950\/100 {
  border-right-color: rgb(46 16 101 / 1) !important;
}

.tw-border-r-violet-950\/15 {
  border-right-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-r-violet-950\/20 {
  border-right-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-r-violet-950\/25 {
  border-right-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-r-violet-950\/30 {
  border-right-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-r-violet-950\/35 {
  border-right-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-r-violet-950\/40 {
  border-right-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-r-violet-950\/45 {
  border-right-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-r-violet-950\/5 {
  border-right-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-r-violet-950\/50 {
  border-right-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-r-violet-950\/55 {
  border-right-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-r-violet-950\/60 {
  border-right-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-r-violet-950\/65 {
  border-right-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-r-violet-950\/70 {
  border-right-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-r-violet-950\/75 {
  border-right-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-r-violet-950\/80 {
  border-right-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-r-violet-950\/85 {
  border-right-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-r-violet-950\/90 {
  border-right-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-r-violet-950\/95 {
  border-right-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-r-white {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-r-white\/0 {
  border-right-color: rgb(255 255 255 / 0) !important;
}

.tw-border-r-white\/10 {
  border-right-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-r-white\/100 {
  border-right-color: rgb(255 255 255 / 1) !important;
}

.tw-border-r-white\/15 {
  border-right-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-r-white\/20 {
  border-right-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-r-white\/25 {
  border-right-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-r-white\/30 {
  border-right-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-r-white\/35 {
  border-right-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-r-white\/40 {
  border-right-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-r-white\/45 {
  border-right-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-r-white\/5 {
  border-right-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-r-white\/50 {
  border-right-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-r-white\/55 {
  border-right-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-r-white\/60 {
  border-right-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-r-white\/65 {
  border-right-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-r-white\/70 {
  border-right-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-r-white\/75 {
  border-right-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-r-white\/80 {
  border-right-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-r-white\/85 {
  border-right-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-r-white\/90 {
  border-right-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-r-white\/95 {
  border-right-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-r-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-100\/0 {
  border-right-color: rgb(254 249 195 / 0) !important;
}

.tw-border-r-yellow-100\/10 {
  border-right-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-r-yellow-100\/100 {
  border-right-color: rgb(254 249 195 / 1) !important;
}

.tw-border-r-yellow-100\/15 {
  border-right-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-r-yellow-100\/20 {
  border-right-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-r-yellow-100\/25 {
  border-right-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-r-yellow-100\/30 {
  border-right-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-r-yellow-100\/35 {
  border-right-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-r-yellow-100\/40 {
  border-right-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-r-yellow-100\/45 {
  border-right-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-r-yellow-100\/5 {
  border-right-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-r-yellow-100\/50 {
  border-right-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-r-yellow-100\/55 {
  border-right-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-r-yellow-100\/60 {
  border-right-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-r-yellow-100\/65 {
  border-right-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-r-yellow-100\/70 {
  border-right-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-r-yellow-100\/75 {
  border-right-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-r-yellow-100\/80 {
  border-right-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-r-yellow-100\/85 {
  border-right-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-r-yellow-100\/90 {
  border-right-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-r-yellow-100\/95 {
  border-right-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-r-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-200\/0 {
  border-right-color: rgb(254 240 138 / 0) !important;
}

.tw-border-r-yellow-200\/10 {
  border-right-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-r-yellow-200\/100 {
  border-right-color: rgb(254 240 138 / 1) !important;
}

.tw-border-r-yellow-200\/15 {
  border-right-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-r-yellow-200\/20 {
  border-right-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-r-yellow-200\/25 {
  border-right-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-r-yellow-200\/30 {
  border-right-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-r-yellow-200\/35 {
  border-right-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-r-yellow-200\/40 {
  border-right-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-r-yellow-200\/45 {
  border-right-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-r-yellow-200\/5 {
  border-right-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-r-yellow-200\/50 {
  border-right-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-r-yellow-200\/55 {
  border-right-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-r-yellow-200\/60 {
  border-right-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-r-yellow-200\/65 {
  border-right-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-r-yellow-200\/70 {
  border-right-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-r-yellow-200\/75 {
  border-right-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-r-yellow-200\/80 {
  border-right-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-r-yellow-200\/85 {
  border-right-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-r-yellow-200\/90 {
  border-right-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-r-yellow-200\/95 {
  border-right-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-r-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-300\/0 {
  border-right-color: rgb(253 224 71 / 0) !important;
}

.tw-border-r-yellow-300\/10 {
  border-right-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-r-yellow-300\/100 {
  border-right-color: rgb(253 224 71 / 1) !important;
}

.tw-border-r-yellow-300\/15 {
  border-right-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-r-yellow-300\/20 {
  border-right-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-r-yellow-300\/25 {
  border-right-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-r-yellow-300\/30 {
  border-right-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-r-yellow-300\/35 {
  border-right-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-r-yellow-300\/40 {
  border-right-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-r-yellow-300\/45 {
  border-right-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-r-yellow-300\/5 {
  border-right-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-r-yellow-300\/50 {
  border-right-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-r-yellow-300\/55 {
  border-right-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-r-yellow-300\/60 {
  border-right-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-r-yellow-300\/65 {
  border-right-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-r-yellow-300\/70 {
  border-right-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-r-yellow-300\/75 {
  border-right-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-r-yellow-300\/80 {
  border-right-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-r-yellow-300\/85 {
  border-right-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-r-yellow-300\/90 {
  border-right-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-r-yellow-300\/95 {
  border-right-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-r-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-400\/0 {
  border-right-color: rgb(250 204 21 / 0) !important;
}

.tw-border-r-yellow-400\/10 {
  border-right-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-r-yellow-400\/100 {
  border-right-color: rgb(250 204 21 / 1) !important;
}

.tw-border-r-yellow-400\/15 {
  border-right-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-r-yellow-400\/20 {
  border-right-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-r-yellow-400\/25 {
  border-right-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-r-yellow-400\/30 {
  border-right-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-r-yellow-400\/35 {
  border-right-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-r-yellow-400\/40 {
  border-right-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-r-yellow-400\/45 {
  border-right-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-r-yellow-400\/5 {
  border-right-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-r-yellow-400\/50 {
  border-right-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-r-yellow-400\/55 {
  border-right-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-r-yellow-400\/60 {
  border-right-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-r-yellow-400\/65 {
  border-right-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-r-yellow-400\/70 {
  border-right-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-r-yellow-400\/75 {
  border-right-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-r-yellow-400\/80 {
  border-right-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-r-yellow-400\/85 {
  border-right-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-r-yellow-400\/90 {
  border-right-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-r-yellow-400\/95 {
  border-right-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-r-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-50\/0 {
  border-right-color: rgb(254 252 232 / 0) !important;
}

.tw-border-r-yellow-50\/10 {
  border-right-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-r-yellow-50\/100 {
  border-right-color: rgb(254 252 232 / 1) !important;
}

.tw-border-r-yellow-50\/15 {
  border-right-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-r-yellow-50\/20 {
  border-right-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-r-yellow-50\/25 {
  border-right-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-r-yellow-50\/30 {
  border-right-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-r-yellow-50\/35 {
  border-right-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-r-yellow-50\/40 {
  border-right-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-r-yellow-50\/45 {
  border-right-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-r-yellow-50\/5 {
  border-right-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-r-yellow-50\/50 {
  border-right-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-r-yellow-50\/55 {
  border-right-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-r-yellow-50\/60 {
  border-right-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-r-yellow-50\/65 {
  border-right-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-r-yellow-50\/70 {
  border-right-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-r-yellow-50\/75 {
  border-right-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-r-yellow-50\/80 {
  border-right-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-r-yellow-50\/85 {
  border-right-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-r-yellow-50\/90 {
  border-right-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-r-yellow-50\/95 {
  border-right-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-r-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-500\/0 {
  border-right-color: rgb(234 179 8 / 0) !important;
}

.tw-border-r-yellow-500\/10 {
  border-right-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-r-yellow-500\/100 {
  border-right-color: rgb(234 179 8 / 1) !important;
}

.tw-border-r-yellow-500\/15 {
  border-right-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-r-yellow-500\/20 {
  border-right-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-r-yellow-500\/25 {
  border-right-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-r-yellow-500\/30 {
  border-right-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-r-yellow-500\/35 {
  border-right-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-r-yellow-500\/40 {
  border-right-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-r-yellow-500\/45 {
  border-right-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-r-yellow-500\/5 {
  border-right-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-r-yellow-500\/50 {
  border-right-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-r-yellow-500\/55 {
  border-right-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-r-yellow-500\/60 {
  border-right-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-r-yellow-500\/65 {
  border-right-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-r-yellow-500\/70 {
  border-right-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-r-yellow-500\/75 {
  border-right-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-r-yellow-500\/80 {
  border-right-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-r-yellow-500\/85 {
  border-right-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-r-yellow-500\/90 {
  border-right-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-r-yellow-500\/95 {
  border-right-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-r-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-600\/0 {
  border-right-color: rgb(202 138 4 / 0) !important;
}

.tw-border-r-yellow-600\/10 {
  border-right-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-r-yellow-600\/100 {
  border-right-color: rgb(202 138 4 / 1) !important;
}

.tw-border-r-yellow-600\/15 {
  border-right-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-r-yellow-600\/20 {
  border-right-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-r-yellow-600\/25 {
  border-right-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-r-yellow-600\/30 {
  border-right-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-r-yellow-600\/35 {
  border-right-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-r-yellow-600\/40 {
  border-right-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-r-yellow-600\/45 {
  border-right-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-r-yellow-600\/5 {
  border-right-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-r-yellow-600\/50 {
  border-right-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-r-yellow-600\/55 {
  border-right-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-r-yellow-600\/60 {
  border-right-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-r-yellow-600\/65 {
  border-right-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-r-yellow-600\/70 {
  border-right-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-r-yellow-600\/75 {
  border-right-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-r-yellow-600\/80 {
  border-right-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-r-yellow-600\/85 {
  border-right-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-r-yellow-600\/90 {
  border-right-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-r-yellow-600\/95 {
  border-right-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-r-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-700\/0 {
  border-right-color: rgb(161 98 7 / 0) !important;
}

.tw-border-r-yellow-700\/10 {
  border-right-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-r-yellow-700\/100 {
  border-right-color: rgb(161 98 7 / 1) !important;
}

.tw-border-r-yellow-700\/15 {
  border-right-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-r-yellow-700\/20 {
  border-right-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-r-yellow-700\/25 {
  border-right-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-r-yellow-700\/30 {
  border-right-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-r-yellow-700\/35 {
  border-right-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-r-yellow-700\/40 {
  border-right-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-r-yellow-700\/45 {
  border-right-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-r-yellow-700\/5 {
  border-right-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-r-yellow-700\/50 {
  border-right-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-r-yellow-700\/55 {
  border-right-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-r-yellow-700\/60 {
  border-right-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-r-yellow-700\/65 {
  border-right-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-r-yellow-700\/70 {
  border-right-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-r-yellow-700\/75 {
  border-right-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-r-yellow-700\/80 {
  border-right-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-r-yellow-700\/85 {
  border-right-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-r-yellow-700\/90 {
  border-right-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-r-yellow-700\/95 {
  border-right-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-r-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-800\/0 {
  border-right-color: rgb(133 77 14 / 0) !important;
}

.tw-border-r-yellow-800\/10 {
  border-right-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-r-yellow-800\/100 {
  border-right-color: rgb(133 77 14 / 1) !important;
}

.tw-border-r-yellow-800\/15 {
  border-right-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-r-yellow-800\/20 {
  border-right-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-r-yellow-800\/25 {
  border-right-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-r-yellow-800\/30 {
  border-right-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-r-yellow-800\/35 {
  border-right-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-r-yellow-800\/40 {
  border-right-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-r-yellow-800\/45 {
  border-right-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-r-yellow-800\/5 {
  border-right-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-r-yellow-800\/50 {
  border-right-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-r-yellow-800\/55 {
  border-right-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-r-yellow-800\/60 {
  border-right-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-r-yellow-800\/65 {
  border-right-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-r-yellow-800\/70 {
  border-right-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-r-yellow-800\/75 {
  border-right-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-r-yellow-800\/80 {
  border-right-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-r-yellow-800\/85 {
  border-right-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-r-yellow-800\/90 {
  border-right-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-r-yellow-800\/95 {
  border-right-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-r-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-900\/0 {
  border-right-color: rgb(113 63 18 / 0) !important;
}

.tw-border-r-yellow-900\/10 {
  border-right-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-r-yellow-900\/100 {
  border-right-color: rgb(113 63 18 / 1) !important;
}

.tw-border-r-yellow-900\/15 {
  border-right-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-r-yellow-900\/20 {
  border-right-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-r-yellow-900\/25 {
  border-right-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-r-yellow-900\/30 {
  border-right-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-r-yellow-900\/35 {
  border-right-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-r-yellow-900\/40 {
  border-right-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-r-yellow-900\/45 {
  border-right-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-r-yellow-900\/5 {
  border-right-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-r-yellow-900\/50 {
  border-right-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-r-yellow-900\/55 {
  border-right-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-r-yellow-900\/60 {
  border-right-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-r-yellow-900\/65 {
  border-right-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-r-yellow-900\/70 {
  border-right-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-r-yellow-900\/75 {
  border-right-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-r-yellow-900\/80 {
  border-right-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-r-yellow-900\/85 {
  border-right-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-r-yellow-900\/90 {
  border-right-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-r-yellow-900\/95 {
  border-right-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-r-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-r-yellow-950\/0 {
  border-right-color: rgb(66 32 6 / 0) !important;
}

.tw-border-r-yellow-950\/10 {
  border-right-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-r-yellow-950\/100 {
  border-right-color: rgb(66 32 6 / 1) !important;
}

.tw-border-r-yellow-950\/15 {
  border-right-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-r-yellow-950\/20 {
  border-right-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-r-yellow-950\/25 {
  border-right-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-r-yellow-950\/30 {
  border-right-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-r-yellow-950\/35 {
  border-right-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-r-yellow-950\/40 {
  border-right-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-r-yellow-950\/45 {
  border-right-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-r-yellow-950\/5 {
  border-right-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-r-yellow-950\/50 {
  border-right-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-r-yellow-950\/55 {
  border-right-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-r-yellow-950\/60 {
  border-right-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-r-yellow-950\/65 {
  border-right-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-r-yellow-950\/70 {
  border-right-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-r-yellow-950\/75 {
  border-right-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-r-yellow-950\/80 {
  border-right-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-r-yellow-950\/85 {
  border-right-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-r-yellow-950\/90 {
  border-right-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-r-yellow-950\/95 {
  border-right-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-r-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-100\/0 {
  border-right-color: rgb(244 244 245 / 0) !important;
}

.tw-border-r-zinc-100\/10 {
  border-right-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-r-zinc-100\/100 {
  border-right-color: rgb(244 244 245 / 1) !important;
}

.tw-border-r-zinc-100\/15 {
  border-right-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-r-zinc-100\/20 {
  border-right-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-r-zinc-100\/25 {
  border-right-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-r-zinc-100\/30 {
  border-right-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-r-zinc-100\/35 {
  border-right-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-r-zinc-100\/40 {
  border-right-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-r-zinc-100\/45 {
  border-right-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-r-zinc-100\/5 {
  border-right-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-r-zinc-100\/50 {
  border-right-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-r-zinc-100\/55 {
  border-right-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-r-zinc-100\/60 {
  border-right-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-r-zinc-100\/65 {
  border-right-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-r-zinc-100\/70 {
  border-right-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-r-zinc-100\/75 {
  border-right-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-r-zinc-100\/80 {
  border-right-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-r-zinc-100\/85 {
  border-right-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-r-zinc-100\/90 {
  border-right-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-r-zinc-100\/95 {
  border-right-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-r-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-200\/0 {
  border-right-color: rgb(228 228 231 / 0) !important;
}

.tw-border-r-zinc-200\/10 {
  border-right-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-r-zinc-200\/100 {
  border-right-color: rgb(228 228 231 / 1) !important;
}

.tw-border-r-zinc-200\/15 {
  border-right-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-r-zinc-200\/20 {
  border-right-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-r-zinc-200\/25 {
  border-right-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-r-zinc-200\/30 {
  border-right-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-r-zinc-200\/35 {
  border-right-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-r-zinc-200\/40 {
  border-right-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-r-zinc-200\/45 {
  border-right-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-r-zinc-200\/5 {
  border-right-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-r-zinc-200\/50 {
  border-right-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-r-zinc-200\/55 {
  border-right-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-r-zinc-200\/60 {
  border-right-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-r-zinc-200\/65 {
  border-right-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-r-zinc-200\/70 {
  border-right-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-r-zinc-200\/75 {
  border-right-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-r-zinc-200\/80 {
  border-right-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-r-zinc-200\/85 {
  border-right-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-r-zinc-200\/90 {
  border-right-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-r-zinc-200\/95 {
  border-right-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-r-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-300\/0 {
  border-right-color: rgb(212 212 216 / 0) !important;
}

.tw-border-r-zinc-300\/10 {
  border-right-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-r-zinc-300\/100 {
  border-right-color: rgb(212 212 216 / 1) !important;
}

.tw-border-r-zinc-300\/15 {
  border-right-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-r-zinc-300\/20 {
  border-right-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-r-zinc-300\/25 {
  border-right-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-r-zinc-300\/30 {
  border-right-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-r-zinc-300\/35 {
  border-right-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-r-zinc-300\/40 {
  border-right-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-r-zinc-300\/45 {
  border-right-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-r-zinc-300\/5 {
  border-right-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-r-zinc-300\/50 {
  border-right-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-r-zinc-300\/55 {
  border-right-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-r-zinc-300\/60 {
  border-right-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-r-zinc-300\/65 {
  border-right-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-r-zinc-300\/70 {
  border-right-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-r-zinc-300\/75 {
  border-right-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-r-zinc-300\/80 {
  border-right-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-r-zinc-300\/85 {
  border-right-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-r-zinc-300\/90 {
  border-right-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-r-zinc-300\/95 {
  border-right-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-r-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-400\/0 {
  border-right-color: rgb(161 161 170 / 0) !important;
}

.tw-border-r-zinc-400\/10 {
  border-right-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-r-zinc-400\/100 {
  border-right-color: rgb(161 161 170 / 1) !important;
}

.tw-border-r-zinc-400\/15 {
  border-right-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-r-zinc-400\/20 {
  border-right-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-r-zinc-400\/25 {
  border-right-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-r-zinc-400\/30 {
  border-right-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-r-zinc-400\/35 {
  border-right-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-r-zinc-400\/40 {
  border-right-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-r-zinc-400\/45 {
  border-right-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-r-zinc-400\/5 {
  border-right-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-r-zinc-400\/50 {
  border-right-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-r-zinc-400\/55 {
  border-right-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-r-zinc-400\/60 {
  border-right-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-r-zinc-400\/65 {
  border-right-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-r-zinc-400\/70 {
  border-right-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-r-zinc-400\/75 {
  border-right-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-r-zinc-400\/80 {
  border-right-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-r-zinc-400\/85 {
  border-right-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-r-zinc-400\/90 {
  border-right-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-r-zinc-400\/95 {
  border-right-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-r-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-50\/0 {
  border-right-color: rgb(250 250 250 / 0) !important;
}

.tw-border-r-zinc-50\/10 {
  border-right-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-r-zinc-50\/100 {
  border-right-color: rgb(250 250 250 / 1) !important;
}

.tw-border-r-zinc-50\/15 {
  border-right-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-r-zinc-50\/20 {
  border-right-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-r-zinc-50\/25 {
  border-right-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-r-zinc-50\/30 {
  border-right-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-r-zinc-50\/35 {
  border-right-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-r-zinc-50\/40 {
  border-right-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-r-zinc-50\/45 {
  border-right-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-r-zinc-50\/5 {
  border-right-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-r-zinc-50\/50 {
  border-right-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-r-zinc-50\/55 {
  border-right-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-r-zinc-50\/60 {
  border-right-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-r-zinc-50\/65 {
  border-right-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-r-zinc-50\/70 {
  border-right-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-r-zinc-50\/75 {
  border-right-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-r-zinc-50\/80 {
  border-right-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-r-zinc-50\/85 {
  border-right-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-r-zinc-50\/90 {
  border-right-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-r-zinc-50\/95 {
  border-right-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-r-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-500\/0 {
  border-right-color: rgb(113 113 122 / 0) !important;
}

.tw-border-r-zinc-500\/10 {
  border-right-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-r-zinc-500\/100 {
  border-right-color: rgb(113 113 122 / 1) !important;
}

.tw-border-r-zinc-500\/15 {
  border-right-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-r-zinc-500\/20 {
  border-right-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-r-zinc-500\/25 {
  border-right-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-r-zinc-500\/30 {
  border-right-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-r-zinc-500\/35 {
  border-right-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-r-zinc-500\/40 {
  border-right-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-r-zinc-500\/45 {
  border-right-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-r-zinc-500\/5 {
  border-right-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-r-zinc-500\/50 {
  border-right-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-r-zinc-500\/55 {
  border-right-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-r-zinc-500\/60 {
  border-right-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-r-zinc-500\/65 {
  border-right-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-r-zinc-500\/70 {
  border-right-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-r-zinc-500\/75 {
  border-right-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-r-zinc-500\/80 {
  border-right-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-r-zinc-500\/85 {
  border-right-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-r-zinc-500\/90 {
  border-right-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-r-zinc-500\/95 {
  border-right-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-r-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-600\/0 {
  border-right-color: rgb(82 82 91 / 0) !important;
}

.tw-border-r-zinc-600\/10 {
  border-right-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-r-zinc-600\/100 {
  border-right-color: rgb(82 82 91 / 1) !important;
}

.tw-border-r-zinc-600\/15 {
  border-right-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-r-zinc-600\/20 {
  border-right-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-r-zinc-600\/25 {
  border-right-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-r-zinc-600\/30 {
  border-right-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-r-zinc-600\/35 {
  border-right-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-r-zinc-600\/40 {
  border-right-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-r-zinc-600\/45 {
  border-right-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-r-zinc-600\/5 {
  border-right-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-r-zinc-600\/50 {
  border-right-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-r-zinc-600\/55 {
  border-right-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-r-zinc-600\/60 {
  border-right-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-r-zinc-600\/65 {
  border-right-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-r-zinc-600\/70 {
  border-right-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-r-zinc-600\/75 {
  border-right-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-r-zinc-600\/80 {
  border-right-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-r-zinc-600\/85 {
  border-right-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-r-zinc-600\/90 {
  border-right-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-r-zinc-600\/95 {
  border-right-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-r-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-700\/0 {
  border-right-color: rgb(63 63 70 / 0) !important;
}

.tw-border-r-zinc-700\/10 {
  border-right-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-r-zinc-700\/100 {
  border-right-color: rgb(63 63 70 / 1) !important;
}

.tw-border-r-zinc-700\/15 {
  border-right-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-r-zinc-700\/20 {
  border-right-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-r-zinc-700\/25 {
  border-right-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-r-zinc-700\/30 {
  border-right-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-r-zinc-700\/35 {
  border-right-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-r-zinc-700\/40 {
  border-right-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-r-zinc-700\/45 {
  border-right-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-r-zinc-700\/5 {
  border-right-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-r-zinc-700\/50 {
  border-right-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-r-zinc-700\/55 {
  border-right-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-r-zinc-700\/60 {
  border-right-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-r-zinc-700\/65 {
  border-right-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-r-zinc-700\/70 {
  border-right-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-r-zinc-700\/75 {
  border-right-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-r-zinc-700\/80 {
  border-right-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-r-zinc-700\/85 {
  border-right-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-r-zinc-700\/90 {
  border-right-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-r-zinc-700\/95 {
  border-right-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-r-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-800\/0 {
  border-right-color: rgb(39 39 42 / 0) !important;
}

.tw-border-r-zinc-800\/10 {
  border-right-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-r-zinc-800\/100 {
  border-right-color: rgb(39 39 42 / 1) !important;
}

.tw-border-r-zinc-800\/15 {
  border-right-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-r-zinc-800\/20 {
  border-right-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-r-zinc-800\/25 {
  border-right-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-r-zinc-800\/30 {
  border-right-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-r-zinc-800\/35 {
  border-right-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-r-zinc-800\/40 {
  border-right-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-r-zinc-800\/45 {
  border-right-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-r-zinc-800\/5 {
  border-right-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-r-zinc-800\/50 {
  border-right-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-r-zinc-800\/55 {
  border-right-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-r-zinc-800\/60 {
  border-right-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-r-zinc-800\/65 {
  border-right-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-r-zinc-800\/70 {
  border-right-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-r-zinc-800\/75 {
  border-right-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-r-zinc-800\/80 {
  border-right-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-r-zinc-800\/85 {
  border-right-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-r-zinc-800\/90 {
  border-right-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-r-zinc-800\/95 {
  border-right-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-r-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-900\/0 {
  border-right-color: rgb(24 24 27 / 0) !important;
}

.tw-border-r-zinc-900\/10 {
  border-right-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-r-zinc-900\/100 {
  border-right-color: rgb(24 24 27 / 1) !important;
}

.tw-border-r-zinc-900\/15 {
  border-right-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-r-zinc-900\/20 {
  border-right-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-r-zinc-900\/25 {
  border-right-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-r-zinc-900\/30 {
  border-right-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-r-zinc-900\/35 {
  border-right-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-r-zinc-900\/40 {
  border-right-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-r-zinc-900\/45 {
  border-right-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-r-zinc-900\/5 {
  border-right-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-r-zinc-900\/50 {
  border-right-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-r-zinc-900\/55 {
  border-right-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-r-zinc-900\/60 {
  border-right-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-r-zinc-900\/65 {
  border-right-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-r-zinc-900\/70 {
  border-right-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-r-zinc-900\/75 {
  border-right-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-r-zinc-900\/80 {
  border-right-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-r-zinc-900\/85 {
  border-right-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-r-zinc-900\/90 {
  border-right-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-r-zinc-900\/95 {
  border-right-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-r-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-right-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-r-zinc-950\/0 {
  border-right-color: rgb(9 9 11 / 0) !important;
}

.tw-border-r-zinc-950\/10 {
  border-right-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-r-zinc-950\/100 {
  border-right-color: rgb(9 9 11 / 1) !important;
}

.tw-border-r-zinc-950\/15 {
  border-right-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-r-zinc-950\/20 {
  border-right-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-r-zinc-950\/25 {
  border-right-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-r-zinc-950\/30 {
  border-right-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-r-zinc-950\/35 {
  border-right-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-r-zinc-950\/40 {
  border-right-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-r-zinc-950\/45 {
  border-right-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-r-zinc-950\/5 {
  border-right-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-r-zinc-950\/50 {
  border-right-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-r-zinc-950\/55 {
  border-right-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-r-zinc-950\/60 {
  border-right-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-r-zinc-950\/65 {
  border-right-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-r-zinc-950\/70 {
  border-right-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-r-zinc-950\/75 {
  border-right-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-r-zinc-950\/80 {
  border-right-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-r-zinc-950\/85 {
  border-right-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-r-zinc-950\/90 {
  border-right-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-r-zinc-950\/95 {
  border-right-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-s-amber-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-100\/0 {
  border-inline-start-color: rgb(254 243 199 / 0) !important;
}

.tw-border-s-amber-100\/10 {
  border-inline-start-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-s-amber-100\/100 {
  border-inline-start-color: rgb(254 243 199 / 1) !important;
}

.tw-border-s-amber-100\/15 {
  border-inline-start-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-s-amber-100\/20 {
  border-inline-start-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-s-amber-100\/25 {
  border-inline-start-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-s-amber-100\/30 {
  border-inline-start-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-s-amber-100\/35 {
  border-inline-start-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-s-amber-100\/40 {
  border-inline-start-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-s-amber-100\/45 {
  border-inline-start-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-s-amber-100\/5 {
  border-inline-start-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-s-amber-100\/50 {
  border-inline-start-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-s-amber-100\/55 {
  border-inline-start-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-s-amber-100\/60 {
  border-inline-start-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-s-amber-100\/65 {
  border-inline-start-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-s-amber-100\/70 {
  border-inline-start-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-s-amber-100\/75 {
  border-inline-start-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-s-amber-100\/80 {
  border-inline-start-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-s-amber-100\/85 {
  border-inline-start-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-s-amber-100\/90 {
  border-inline-start-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-s-amber-100\/95 {
  border-inline-start-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-s-amber-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-200\/0 {
  border-inline-start-color: rgb(253 230 138 / 0) !important;
}

.tw-border-s-amber-200\/10 {
  border-inline-start-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-s-amber-200\/100 {
  border-inline-start-color: rgb(253 230 138 / 1) !important;
}

.tw-border-s-amber-200\/15 {
  border-inline-start-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-s-amber-200\/20 {
  border-inline-start-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-s-amber-200\/25 {
  border-inline-start-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-s-amber-200\/30 {
  border-inline-start-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-s-amber-200\/35 {
  border-inline-start-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-s-amber-200\/40 {
  border-inline-start-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-s-amber-200\/45 {
  border-inline-start-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-s-amber-200\/5 {
  border-inline-start-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-s-amber-200\/50 {
  border-inline-start-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-s-amber-200\/55 {
  border-inline-start-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-s-amber-200\/60 {
  border-inline-start-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-s-amber-200\/65 {
  border-inline-start-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-s-amber-200\/70 {
  border-inline-start-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-s-amber-200\/75 {
  border-inline-start-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-s-amber-200\/80 {
  border-inline-start-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-s-amber-200\/85 {
  border-inline-start-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-s-amber-200\/90 {
  border-inline-start-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-s-amber-200\/95 {
  border-inline-start-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-s-amber-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-300\/0 {
  border-inline-start-color: rgb(252 211 77 / 0) !important;
}

.tw-border-s-amber-300\/10 {
  border-inline-start-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-s-amber-300\/100 {
  border-inline-start-color: rgb(252 211 77 / 1) !important;
}

.tw-border-s-amber-300\/15 {
  border-inline-start-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-s-amber-300\/20 {
  border-inline-start-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-s-amber-300\/25 {
  border-inline-start-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-s-amber-300\/30 {
  border-inline-start-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-s-amber-300\/35 {
  border-inline-start-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-s-amber-300\/40 {
  border-inline-start-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-s-amber-300\/45 {
  border-inline-start-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-s-amber-300\/5 {
  border-inline-start-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-s-amber-300\/50 {
  border-inline-start-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-s-amber-300\/55 {
  border-inline-start-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-s-amber-300\/60 {
  border-inline-start-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-s-amber-300\/65 {
  border-inline-start-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-s-amber-300\/70 {
  border-inline-start-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-s-amber-300\/75 {
  border-inline-start-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-s-amber-300\/80 {
  border-inline-start-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-s-amber-300\/85 {
  border-inline-start-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-s-amber-300\/90 {
  border-inline-start-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-s-amber-300\/95 {
  border-inline-start-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-s-amber-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-400\/0 {
  border-inline-start-color: rgb(251 191 36 / 0) !important;
}

.tw-border-s-amber-400\/10 {
  border-inline-start-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-s-amber-400\/100 {
  border-inline-start-color: rgb(251 191 36 / 1) !important;
}

.tw-border-s-amber-400\/15 {
  border-inline-start-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-s-amber-400\/20 {
  border-inline-start-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-s-amber-400\/25 {
  border-inline-start-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-s-amber-400\/30 {
  border-inline-start-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-s-amber-400\/35 {
  border-inline-start-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-s-amber-400\/40 {
  border-inline-start-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-s-amber-400\/45 {
  border-inline-start-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-s-amber-400\/5 {
  border-inline-start-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-s-amber-400\/50 {
  border-inline-start-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-s-amber-400\/55 {
  border-inline-start-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-s-amber-400\/60 {
  border-inline-start-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-s-amber-400\/65 {
  border-inline-start-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-s-amber-400\/70 {
  border-inline-start-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-s-amber-400\/75 {
  border-inline-start-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-s-amber-400\/80 {
  border-inline-start-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-s-amber-400\/85 {
  border-inline-start-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-s-amber-400\/90 {
  border-inline-start-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-s-amber-400\/95 {
  border-inline-start-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-s-amber-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-50\/0 {
  border-inline-start-color: rgb(255 251 235 / 0) !important;
}

.tw-border-s-amber-50\/10 {
  border-inline-start-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-s-amber-50\/100 {
  border-inline-start-color: rgb(255 251 235 / 1) !important;
}

.tw-border-s-amber-50\/15 {
  border-inline-start-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-s-amber-50\/20 {
  border-inline-start-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-s-amber-50\/25 {
  border-inline-start-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-s-amber-50\/30 {
  border-inline-start-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-s-amber-50\/35 {
  border-inline-start-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-s-amber-50\/40 {
  border-inline-start-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-s-amber-50\/45 {
  border-inline-start-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-s-amber-50\/5 {
  border-inline-start-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-s-amber-50\/50 {
  border-inline-start-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-s-amber-50\/55 {
  border-inline-start-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-s-amber-50\/60 {
  border-inline-start-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-s-amber-50\/65 {
  border-inline-start-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-s-amber-50\/70 {
  border-inline-start-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-s-amber-50\/75 {
  border-inline-start-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-s-amber-50\/80 {
  border-inline-start-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-s-amber-50\/85 {
  border-inline-start-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-s-amber-50\/90 {
  border-inline-start-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-s-amber-50\/95 {
  border-inline-start-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-s-amber-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-500\/0 {
  border-inline-start-color: rgb(245 158 11 / 0) !important;
}

.tw-border-s-amber-500\/10 {
  border-inline-start-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-s-amber-500\/100 {
  border-inline-start-color: rgb(245 158 11 / 1) !important;
}

.tw-border-s-amber-500\/15 {
  border-inline-start-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-s-amber-500\/20 {
  border-inline-start-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-s-amber-500\/25 {
  border-inline-start-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-s-amber-500\/30 {
  border-inline-start-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-s-amber-500\/35 {
  border-inline-start-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-s-amber-500\/40 {
  border-inline-start-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-s-amber-500\/45 {
  border-inline-start-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-s-amber-500\/5 {
  border-inline-start-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-s-amber-500\/50 {
  border-inline-start-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-s-amber-500\/55 {
  border-inline-start-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-s-amber-500\/60 {
  border-inline-start-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-s-amber-500\/65 {
  border-inline-start-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-s-amber-500\/70 {
  border-inline-start-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-s-amber-500\/75 {
  border-inline-start-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-s-amber-500\/80 {
  border-inline-start-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-s-amber-500\/85 {
  border-inline-start-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-s-amber-500\/90 {
  border-inline-start-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-s-amber-500\/95 {
  border-inline-start-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-s-amber-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-600\/0 {
  border-inline-start-color: rgb(217 119 6 / 0) !important;
}

.tw-border-s-amber-600\/10 {
  border-inline-start-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-s-amber-600\/100 {
  border-inline-start-color: rgb(217 119 6 / 1) !important;
}

.tw-border-s-amber-600\/15 {
  border-inline-start-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-s-amber-600\/20 {
  border-inline-start-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-s-amber-600\/25 {
  border-inline-start-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-s-amber-600\/30 {
  border-inline-start-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-s-amber-600\/35 {
  border-inline-start-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-s-amber-600\/40 {
  border-inline-start-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-s-amber-600\/45 {
  border-inline-start-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-s-amber-600\/5 {
  border-inline-start-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-s-amber-600\/50 {
  border-inline-start-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-s-amber-600\/55 {
  border-inline-start-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-s-amber-600\/60 {
  border-inline-start-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-s-amber-600\/65 {
  border-inline-start-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-s-amber-600\/70 {
  border-inline-start-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-s-amber-600\/75 {
  border-inline-start-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-s-amber-600\/80 {
  border-inline-start-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-s-amber-600\/85 {
  border-inline-start-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-s-amber-600\/90 {
  border-inline-start-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-s-amber-600\/95 {
  border-inline-start-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-s-amber-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-700\/0 {
  border-inline-start-color: rgb(180 83 9 / 0) !important;
}

.tw-border-s-amber-700\/10 {
  border-inline-start-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-s-amber-700\/100 {
  border-inline-start-color: rgb(180 83 9 / 1) !important;
}

.tw-border-s-amber-700\/15 {
  border-inline-start-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-s-amber-700\/20 {
  border-inline-start-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-s-amber-700\/25 {
  border-inline-start-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-s-amber-700\/30 {
  border-inline-start-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-s-amber-700\/35 {
  border-inline-start-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-s-amber-700\/40 {
  border-inline-start-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-s-amber-700\/45 {
  border-inline-start-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-s-amber-700\/5 {
  border-inline-start-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-s-amber-700\/50 {
  border-inline-start-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-s-amber-700\/55 {
  border-inline-start-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-s-amber-700\/60 {
  border-inline-start-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-s-amber-700\/65 {
  border-inline-start-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-s-amber-700\/70 {
  border-inline-start-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-s-amber-700\/75 {
  border-inline-start-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-s-amber-700\/80 {
  border-inline-start-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-s-amber-700\/85 {
  border-inline-start-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-s-amber-700\/90 {
  border-inline-start-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-s-amber-700\/95 {
  border-inline-start-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-s-amber-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-800\/0 {
  border-inline-start-color: rgb(146 64 14 / 0) !important;
}

.tw-border-s-amber-800\/10 {
  border-inline-start-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-s-amber-800\/100 {
  border-inline-start-color: rgb(146 64 14 / 1) !important;
}

.tw-border-s-amber-800\/15 {
  border-inline-start-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-s-amber-800\/20 {
  border-inline-start-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-s-amber-800\/25 {
  border-inline-start-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-s-amber-800\/30 {
  border-inline-start-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-s-amber-800\/35 {
  border-inline-start-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-s-amber-800\/40 {
  border-inline-start-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-s-amber-800\/45 {
  border-inline-start-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-s-amber-800\/5 {
  border-inline-start-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-s-amber-800\/50 {
  border-inline-start-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-s-amber-800\/55 {
  border-inline-start-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-s-amber-800\/60 {
  border-inline-start-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-s-amber-800\/65 {
  border-inline-start-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-s-amber-800\/70 {
  border-inline-start-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-s-amber-800\/75 {
  border-inline-start-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-s-amber-800\/80 {
  border-inline-start-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-s-amber-800\/85 {
  border-inline-start-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-s-amber-800\/90 {
  border-inline-start-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-s-amber-800\/95 {
  border-inline-start-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-s-amber-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-900\/0 {
  border-inline-start-color: rgb(120 53 15 / 0) !important;
}

.tw-border-s-amber-900\/10 {
  border-inline-start-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-s-amber-900\/100 {
  border-inline-start-color: rgb(120 53 15 / 1) !important;
}

.tw-border-s-amber-900\/15 {
  border-inline-start-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-s-amber-900\/20 {
  border-inline-start-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-s-amber-900\/25 {
  border-inline-start-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-s-amber-900\/30 {
  border-inline-start-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-s-amber-900\/35 {
  border-inline-start-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-s-amber-900\/40 {
  border-inline-start-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-s-amber-900\/45 {
  border-inline-start-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-s-amber-900\/5 {
  border-inline-start-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-s-amber-900\/50 {
  border-inline-start-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-s-amber-900\/55 {
  border-inline-start-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-s-amber-900\/60 {
  border-inline-start-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-s-amber-900\/65 {
  border-inline-start-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-s-amber-900\/70 {
  border-inline-start-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-s-amber-900\/75 {
  border-inline-start-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-s-amber-900\/80 {
  border-inline-start-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-s-amber-900\/85 {
  border-inline-start-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-s-amber-900\/90 {
  border-inline-start-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-s-amber-900\/95 {
  border-inline-start-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-s-amber-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-s-amber-950\/0 {
  border-inline-start-color: rgb(69 26 3 / 0) !important;
}

.tw-border-s-amber-950\/10 {
  border-inline-start-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-s-amber-950\/100 {
  border-inline-start-color: rgb(69 26 3 / 1) !important;
}

.tw-border-s-amber-950\/15 {
  border-inline-start-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-s-amber-950\/20 {
  border-inline-start-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-s-amber-950\/25 {
  border-inline-start-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-s-amber-950\/30 {
  border-inline-start-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-s-amber-950\/35 {
  border-inline-start-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-s-amber-950\/40 {
  border-inline-start-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-s-amber-950\/45 {
  border-inline-start-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-s-amber-950\/5 {
  border-inline-start-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-s-amber-950\/50 {
  border-inline-start-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-s-amber-950\/55 {
  border-inline-start-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-s-amber-950\/60 {
  border-inline-start-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-s-amber-950\/65 {
  border-inline-start-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-s-amber-950\/70 {
  border-inline-start-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-s-amber-950\/75 {
  border-inline-start-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-s-amber-950\/80 {
  border-inline-start-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-s-amber-950\/85 {
  border-inline-start-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-s-amber-950\/90 {
  border-inline-start-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-s-amber-950\/95 {
  border-inline-start-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-s-black {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-s-black\/0 {
  border-inline-start-color: rgb(0 0 0 / 0) !important;
}

.tw-border-s-black\/10 {
  border-inline-start-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-s-black\/100 {
  border-inline-start-color: rgb(0 0 0 / 1) !important;
}

.tw-border-s-black\/15 {
  border-inline-start-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-s-black\/20 {
  border-inline-start-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-s-black\/25 {
  border-inline-start-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-s-black\/30 {
  border-inline-start-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-s-black\/35 {
  border-inline-start-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-s-black\/40 {
  border-inline-start-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-s-black\/45 {
  border-inline-start-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-s-black\/5 {
  border-inline-start-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-s-black\/50 {
  border-inline-start-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-s-black\/55 {
  border-inline-start-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-s-black\/60 {
  border-inline-start-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-s-black\/65 {
  border-inline-start-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-s-black\/70 {
  border-inline-start-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-s-black\/75 {
  border-inline-start-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-s-black\/80 {
  border-inline-start-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-s-black\/85 {
  border-inline-start-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-s-black\/90 {
  border-inline-start-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-s-black\/95 {
  border-inline-start-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-s-blue-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-100\/0 {
  border-inline-start-color: rgb(219 234 254 / 0) !important;
}

.tw-border-s-blue-100\/10 {
  border-inline-start-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-s-blue-100\/100 {
  border-inline-start-color: rgb(219 234 254 / 1) !important;
}

.tw-border-s-blue-100\/15 {
  border-inline-start-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-s-blue-100\/20 {
  border-inline-start-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-s-blue-100\/25 {
  border-inline-start-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-s-blue-100\/30 {
  border-inline-start-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-s-blue-100\/35 {
  border-inline-start-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-s-blue-100\/40 {
  border-inline-start-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-s-blue-100\/45 {
  border-inline-start-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-s-blue-100\/5 {
  border-inline-start-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-s-blue-100\/50 {
  border-inline-start-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-s-blue-100\/55 {
  border-inline-start-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-s-blue-100\/60 {
  border-inline-start-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-s-blue-100\/65 {
  border-inline-start-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-s-blue-100\/70 {
  border-inline-start-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-s-blue-100\/75 {
  border-inline-start-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-s-blue-100\/80 {
  border-inline-start-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-s-blue-100\/85 {
  border-inline-start-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-s-blue-100\/90 {
  border-inline-start-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-s-blue-100\/95 {
  border-inline-start-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-s-blue-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-200\/0 {
  border-inline-start-color: rgb(191 219 254 / 0) !important;
}

.tw-border-s-blue-200\/10 {
  border-inline-start-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-s-blue-200\/100 {
  border-inline-start-color: rgb(191 219 254 / 1) !important;
}

.tw-border-s-blue-200\/15 {
  border-inline-start-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-s-blue-200\/20 {
  border-inline-start-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-s-blue-200\/25 {
  border-inline-start-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-s-blue-200\/30 {
  border-inline-start-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-s-blue-200\/35 {
  border-inline-start-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-s-blue-200\/40 {
  border-inline-start-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-s-blue-200\/45 {
  border-inline-start-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-s-blue-200\/5 {
  border-inline-start-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-s-blue-200\/50 {
  border-inline-start-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-s-blue-200\/55 {
  border-inline-start-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-s-blue-200\/60 {
  border-inline-start-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-s-blue-200\/65 {
  border-inline-start-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-s-blue-200\/70 {
  border-inline-start-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-s-blue-200\/75 {
  border-inline-start-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-s-blue-200\/80 {
  border-inline-start-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-s-blue-200\/85 {
  border-inline-start-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-s-blue-200\/90 {
  border-inline-start-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-s-blue-200\/95 {
  border-inline-start-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-s-blue-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-300\/0 {
  border-inline-start-color: rgb(147 197 253 / 0) !important;
}

.tw-border-s-blue-300\/10 {
  border-inline-start-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-s-blue-300\/100 {
  border-inline-start-color: rgb(147 197 253 / 1) !important;
}

.tw-border-s-blue-300\/15 {
  border-inline-start-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-s-blue-300\/20 {
  border-inline-start-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-s-blue-300\/25 {
  border-inline-start-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-s-blue-300\/30 {
  border-inline-start-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-s-blue-300\/35 {
  border-inline-start-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-s-blue-300\/40 {
  border-inline-start-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-s-blue-300\/45 {
  border-inline-start-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-s-blue-300\/5 {
  border-inline-start-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-s-blue-300\/50 {
  border-inline-start-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-s-blue-300\/55 {
  border-inline-start-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-s-blue-300\/60 {
  border-inline-start-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-s-blue-300\/65 {
  border-inline-start-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-s-blue-300\/70 {
  border-inline-start-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-s-blue-300\/75 {
  border-inline-start-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-s-blue-300\/80 {
  border-inline-start-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-s-blue-300\/85 {
  border-inline-start-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-s-blue-300\/90 {
  border-inline-start-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-s-blue-300\/95 {
  border-inline-start-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-s-blue-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-400\/0 {
  border-inline-start-color: rgb(96 165 250 / 0) !important;
}

.tw-border-s-blue-400\/10 {
  border-inline-start-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-s-blue-400\/100 {
  border-inline-start-color: rgb(96 165 250 / 1) !important;
}

.tw-border-s-blue-400\/15 {
  border-inline-start-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-s-blue-400\/20 {
  border-inline-start-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-s-blue-400\/25 {
  border-inline-start-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-s-blue-400\/30 {
  border-inline-start-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-s-blue-400\/35 {
  border-inline-start-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-s-blue-400\/40 {
  border-inline-start-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-s-blue-400\/45 {
  border-inline-start-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-s-blue-400\/5 {
  border-inline-start-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-s-blue-400\/50 {
  border-inline-start-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-s-blue-400\/55 {
  border-inline-start-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-s-blue-400\/60 {
  border-inline-start-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-s-blue-400\/65 {
  border-inline-start-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-s-blue-400\/70 {
  border-inline-start-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-s-blue-400\/75 {
  border-inline-start-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-s-blue-400\/80 {
  border-inline-start-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-s-blue-400\/85 {
  border-inline-start-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-s-blue-400\/90 {
  border-inline-start-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-s-blue-400\/95 {
  border-inline-start-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-s-blue-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-50\/0 {
  border-inline-start-color: rgb(239 246 255 / 0) !important;
}

.tw-border-s-blue-50\/10 {
  border-inline-start-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-s-blue-50\/100 {
  border-inline-start-color: rgb(239 246 255 / 1) !important;
}

.tw-border-s-blue-50\/15 {
  border-inline-start-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-s-blue-50\/20 {
  border-inline-start-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-s-blue-50\/25 {
  border-inline-start-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-s-blue-50\/30 {
  border-inline-start-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-s-blue-50\/35 {
  border-inline-start-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-s-blue-50\/40 {
  border-inline-start-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-s-blue-50\/45 {
  border-inline-start-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-s-blue-50\/5 {
  border-inline-start-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-s-blue-50\/50 {
  border-inline-start-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-s-blue-50\/55 {
  border-inline-start-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-s-blue-50\/60 {
  border-inline-start-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-s-blue-50\/65 {
  border-inline-start-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-s-blue-50\/70 {
  border-inline-start-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-s-blue-50\/75 {
  border-inline-start-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-s-blue-50\/80 {
  border-inline-start-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-s-blue-50\/85 {
  border-inline-start-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-s-blue-50\/90 {
  border-inline-start-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-s-blue-50\/95 {
  border-inline-start-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-s-blue-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-500\/0 {
  border-inline-start-color: rgb(59 130 246 / 0) !important;
}

.tw-border-s-blue-500\/10 {
  border-inline-start-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-s-blue-500\/100 {
  border-inline-start-color: rgb(59 130 246 / 1) !important;
}

.tw-border-s-blue-500\/15 {
  border-inline-start-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-s-blue-500\/20 {
  border-inline-start-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-s-blue-500\/25 {
  border-inline-start-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-s-blue-500\/30 {
  border-inline-start-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-s-blue-500\/35 {
  border-inline-start-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-s-blue-500\/40 {
  border-inline-start-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-s-blue-500\/45 {
  border-inline-start-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-s-blue-500\/5 {
  border-inline-start-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-s-blue-500\/50 {
  border-inline-start-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-s-blue-500\/55 {
  border-inline-start-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-s-blue-500\/60 {
  border-inline-start-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-s-blue-500\/65 {
  border-inline-start-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-s-blue-500\/70 {
  border-inline-start-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-s-blue-500\/75 {
  border-inline-start-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-s-blue-500\/80 {
  border-inline-start-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-s-blue-500\/85 {
  border-inline-start-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-s-blue-500\/90 {
  border-inline-start-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-s-blue-500\/95 {
  border-inline-start-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-s-blue-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-600\/0 {
  border-inline-start-color: rgb(37 99 235 / 0) !important;
}

.tw-border-s-blue-600\/10 {
  border-inline-start-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-s-blue-600\/100 {
  border-inline-start-color: rgb(37 99 235 / 1) !important;
}

.tw-border-s-blue-600\/15 {
  border-inline-start-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-s-blue-600\/20 {
  border-inline-start-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-s-blue-600\/25 {
  border-inline-start-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-s-blue-600\/30 {
  border-inline-start-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-s-blue-600\/35 {
  border-inline-start-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-s-blue-600\/40 {
  border-inline-start-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-s-blue-600\/45 {
  border-inline-start-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-s-blue-600\/5 {
  border-inline-start-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-s-blue-600\/50 {
  border-inline-start-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-s-blue-600\/55 {
  border-inline-start-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-s-blue-600\/60 {
  border-inline-start-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-s-blue-600\/65 {
  border-inline-start-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-s-blue-600\/70 {
  border-inline-start-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-s-blue-600\/75 {
  border-inline-start-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-s-blue-600\/80 {
  border-inline-start-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-s-blue-600\/85 {
  border-inline-start-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-s-blue-600\/90 {
  border-inline-start-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-s-blue-600\/95 {
  border-inline-start-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-s-blue-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-700\/0 {
  border-inline-start-color: rgb(29 78 216 / 0) !important;
}

.tw-border-s-blue-700\/10 {
  border-inline-start-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-s-blue-700\/100 {
  border-inline-start-color: rgb(29 78 216 / 1) !important;
}

.tw-border-s-blue-700\/15 {
  border-inline-start-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-s-blue-700\/20 {
  border-inline-start-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-s-blue-700\/25 {
  border-inline-start-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-s-blue-700\/30 {
  border-inline-start-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-s-blue-700\/35 {
  border-inline-start-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-s-blue-700\/40 {
  border-inline-start-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-s-blue-700\/45 {
  border-inline-start-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-s-blue-700\/5 {
  border-inline-start-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-s-blue-700\/50 {
  border-inline-start-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-s-blue-700\/55 {
  border-inline-start-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-s-blue-700\/60 {
  border-inline-start-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-s-blue-700\/65 {
  border-inline-start-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-s-blue-700\/70 {
  border-inline-start-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-s-blue-700\/75 {
  border-inline-start-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-s-blue-700\/80 {
  border-inline-start-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-s-blue-700\/85 {
  border-inline-start-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-s-blue-700\/90 {
  border-inline-start-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-s-blue-700\/95 {
  border-inline-start-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-s-blue-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-800\/0 {
  border-inline-start-color: rgb(30 64 175 / 0) !important;
}

.tw-border-s-blue-800\/10 {
  border-inline-start-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-s-blue-800\/100 {
  border-inline-start-color: rgb(30 64 175 / 1) !important;
}

.tw-border-s-blue-800\/15 {
  border-inline-start-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-s-blue-800\/20 {
  border-inline-start-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-s-blue-800\/25 {
  border-inline-start-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-s-blue-800\/30 {
  border-inline-start-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-s-blue-800\/35 {
  border-inline-start-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-s-blue-800\/40 {
  border-inline-start-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-s-blue-800\/45 {
  border-inline-start-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-s-blue-800\/5 {
  border-inline-start-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-s-blue-800\/50 {
  border-inline-start-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-s-blue-800\/55 {
  border-inline-start-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-s-blue-800\/60 {
  border-inline-start-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-s-blue-800\/65 {
  border-inline-start-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-s-blue-800\/70 {
  border-inline-start-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-s-blue-800\/75 {
  border-inline-start-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-s-blue-800\/80 {
  border-inline-start-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-s-blue-800\/85 {
  border-inline-start-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-s-blue-800\/90 {
  border-inline-start-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-s-blue-800\/95 {
  border-inline-start-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-s-blue-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-900\/0 {
  border-inline-start-color: rgb(30 58 138 / 0) !important;
}

.tw-border-s-blue-900\/10 {
  border-inline-start-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-s-blue-900\/100 {
  border-inline-start-color: rgb(30 58 138 / 1) !important;
}

.tw-border-s-blue-900\/15 {
  border-inline-start-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-s-blue-900\/20 {
  border-inline-start-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-s-blue-900\/25 {
  border-inline-start-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-s-blue-900\/30 {
  border-inline-start-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-s-blue-900\/35 {
  border-inline-start-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-s-blue-900\/40 {
  border-inline-start-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-s-blue-900\/45 {
  border-inline-start-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-s-blue-900\/5 {
  border-inline-start-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-s-blue-900\/50 {
  border-inline-start-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-s-blue-900\/55 {
  border-inline-start-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-s-blue-900\/60 {
  border-inline-start-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-s-blue-900\/65 {
  border-inline-start-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-s-blue-900\/70 {
  border-inline-start-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-s-blue-900\/75 {
  border-inline-start-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-s-blue-900\/80 {
  border-inline-start-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-s-blue-900\/85 {
  border-inline-start-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-s-blue-900\/90 {
  border-inline-start-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-s-blue-900\/95 {
  border-inline-start-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-s-blue-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-s-blue-950\/0 {
  border-inline-start-color: rgb(23 37 84 / 0) !important;
}

.tw-border-s-blue-950\/10 {
  border-inline-start-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-s-blue-950\/100 {
  border-inline-start-color: rgb(23 37 84 / 1) !important;
}

.tw-border-s-blue-950\/15 {
  border-inline-start-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-s-blue-950\/20 {
  border-inline-start-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-s-blue-950\/25 {
  border-inline-start-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-s-blue-950\/30 {
  border-inline-start-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-s-blue-950\/35 {
  border-inline-start-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-s-blue-950\/40 {
  border-inline-start-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-s-blue-950\/45 {
  border-inline-start-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-s-blue-950\/5 {
  border-inline-start-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-s-blue-950\/50 {
  border-inline-start-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-s-blue-950\/55 {
  border-inline-start-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-s-blue-950\/60 {
  border-inline-start-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-s-blue-950\/65 {
  border-inline-start-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-s-blue-950\/70 {
  border-inline-start-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-s-blue-950\/75 {
  border-inline-start-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-s-blue-950\/80 {
  border-inline-start-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-s-blue-950\/85 {
  border-inline-start-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-s-blue-950\/90 {
  border-inline-start-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-s-blue-950\/95 {
  border-inline-start-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-s-current {
  border-inline-start-color: currentColor !important;
}

.tw-border-s-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-100\/0 {
  border-inline-start-color: rgb(207 250 254 / 0) !important;
}

.tw-border-s-cyan-100\/10 {
  border-inline-start-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-s-cyan-100\/100 {
  border-inline-start-color: rgb(207 250 254 / 1) !important;
}

.tw-border-s-cyan-100\/15 {
  border-inline-start-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-s-cyan-100\/20 {
  border-inline-start-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-s-cyan-100\/25 {
  border-inline-start-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-s-cyan-100\/30 {
  border-inline-start-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-s-cyan-100\/35 {
  border-inline-start-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-s-cyan-100\/40 {
  border-inline-start-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-s-cyan-100\/45 {
  border-inline-start-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-s-cyan-100\/5 {
  border-inline-start-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-s-cyan-100\/50 {
  border-inline-start-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-s-cyan-100\/55 {
  border-inline-start-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-s-cyan-100\/60 {
  border-inline-start-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-s-cyan-100\/65 {
  border-inline-start-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-s-cyan-100\/70 {
  border-inline-start-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-s-cyan-100\/75 {
  border-inline-start-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-s-cyan-100\/80 {
  border-inline-start-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-s-cyan-100\/85 {
  border-inline-start-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-s-cyan-100\/90 {
  border-inline-start-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-s-cyan-100\/95 {
  border-inline-start-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-s-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-200\/0 {
  border-inline-start-color: rgb(165 243 252 / 0) !important;
}

.tw-border-s-cyan-200\/10 {
  border-inline-start-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-s-cyan-200\/100 {
  border-inline-start-color: rgb(165 243 252 / 1) !important;
}

.tw-border-s-cyan-200\/15 {
  border-inline-start-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-s-cyan-200\/20 {
  border-inline-start-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-s-cyan-200\/25 {
  border-inline-start-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-s-cyan-200\/30 {
  border-inline-start-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-s-cyan-200\/35 {
  border-inline-start-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-s-cyan-200\/40 {
  border-inline-start-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-s-cyan-200\/45 {
  border-inline-start-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-s-cyan-200\/5 {
  border-inline-start-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-s-cyan-200\/50 {
  border-inline-start-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-s-cyan-200\/55 {
  border-inline-start-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-s-cyan-200\/60 {
  border-inline-start-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-s-cyan-200\/65 {
  border-inline-start-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-s-cyan-200\/70 {
  border-inline-start-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-s-cyan-200\/75 {
  border-inline-start-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-s-cyan-200\/80 {
  border-inline-start-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-s-cyan-200\/85 {
  border-inline-start-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-s-cyan-200\/90 {
  border-inline-start-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-s-cyan-200\/95 {
  border-inline-start-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-s-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-300\/0 {
  border-inline-start-color: rgb(103 232 249 / 0) !important;
}

.tw-border-s-cyan-300\/10 {
  border-inline-start-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-s-cyan-300\/100 {
  border-inline-start-color: rgb(103 232 249 / 1) !important;
}

.tw-border-s-cyan-300\/15 {
  border-inline-start-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-s-cyan-300\/20 {
  border-inline-start-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-s-cyan-300\/25 {
  border-inline-start-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-s-cyan-300\/30 {
  border-inline-start-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-s-cyan-300\/35 {
  border-inline-start-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-s-cyan-300\/40 {
  border-inline-start-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-s-cyan-300\/45 {
  border-inline-start-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-s-cyan-300\/5 {
  border-inline-start-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-s-cyan-300\/50 {
  border-inline-start-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-s-cyan-300\/55 {
  border-inline-start-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-s-cyan-300\/60 {
  border-inline-start-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-s-cyan-300\/65 {
  border-inline-start-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-s-cyan-300\/70 {
  border-inline-start-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-s-cyan-300\/75 {
  border-inline-start-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-s-cyan-300\/80 {
  border-inline-start-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-s-cyan-300\/85 {
  border-inline-start-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-s-cyan-300\/90 {
  border-inline-start-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-s-cyan-300\/95 {
  border-inline-start-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-s-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-400\/0 {
  border-inline-start-color: rgb(34 211 238 / 0) !important;
}

.tw-border-s-cyan-400\/10 {
  border-inline-start-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-s-cyan-400\/100 {
  border-inline-start-color: rgb(34 211 238 / 1) !important;
}

.tw-border-s-cyan-400\/15 {
  border-inline-start-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-s-cyan-400\/20 {
  border-inline-start-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-s-cyan-400\/25 {
  border-inline-start-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-s-cyan-400\/30 {
  border-inline-start-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-s-cyan-400\/35 {
  border-inline-start-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-s-cyan-400\/40 {
  border-inline-start-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-s-cyan-400\/45 {
  border-inline-start-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-s-cyan-400\/5 {
  border-inline-start-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-s-cyan-400\/50 {
  border-inline-start-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-s-cyan-400\/55 {
  border-inline-start-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-s-cyan-400\/60 {
  border-inline-start-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-s-cyan-400\/65 {
  border-inline-start-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-s-cyan-400\/70 {
  border-inline-start-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-s-cyan-400\/75 {
  border-inline-start-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-s-cyan-400\/80 {
  border-inline-start-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-s-cyan-400\/85 {
  border-inline-start-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-s-cyan-400\/90 {
  border-inline-start-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-s-cyan-400\/95 {
  border-inline-start-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-s-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-50\/0 {
  border-inline-start-color: rgb(236 254 255 / 0) !important;
}

.tw-border-s-cyan-50\/10 {
  border-inline-start-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-s-cyan-50\/100 {
  border-inline-start-color: rgb(236 254 255 / 1) !important;
}

.tw-border-s-cyan-50\/15 {
  border-inline-start-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-s-cyan-50\/20 {
  border-inline-start-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-s-cyan-50\/25 {
  border-inline-start-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-s-cyan-50\/30 {
  border-inline-start-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-s-cyan-50\/35 {
  border-inline-start-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-s-cyan-50\/40 {
  border-inline-start-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-s-cyan-50\/45 {
  border-inline-start-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-s-cyan-50\/5 {
  border-inline-start-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-s-cyan-50\/50 {
  border-inline-start-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-s-cyan-50\/55 {
  border-inline-start-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-s-cyan-50\/60 {
  border-inline-start-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-s-cyan-50\/65 {
  border-inline-start-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-s-cyan-50\/70 {
  border-inline-start-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-s-cyan-50\/75 {
  border-inline-start-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-s-cyan-50\/80 {
  border-inline-start-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-s-cyan-50\/85 {
  border-inline-start-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-s-cyan-50\/90 {
  border-inline-start-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-s-cyan-50\/95 {
  border-inline-start-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-s-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-500\/0 {
  border-inline-start-color: rgb(6 182 212 / 0) !important;
}

.tw-border-s-cyan-500\/10 {
  border-inline-start-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-s-cyan-500\/100 {
  border-inline-start-color: rgb(6 182 212 / 1) !important;
}

.tw-border-s-cyan-500\/15 {
  border-inline-start-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-s-cyan-500\/20 {
  border-inline-start-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-s-cyan-500\/25 {
  border-inline-start-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-s-cyan-500\/30 {
  border-inline-start-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-s-cyan-500\/35 {
  border-inline-start-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-s-cyan-500\/40 {
  border-inline-start-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-s-cyan-500\/45 {
  border-inline-start-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-s-cyan-500\/5 {
  border-inline-start-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-s-cyan-500\/50 {
  border-inline-start-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-s-cyan-500\/55 {
  border-inline-start-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-s-cyan-500\/60 {
  border-inline-start-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-s-cyan-500\/65 {
  border-inline-start-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-s-cyan-500\/70 {
  border-inline-start-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-s-cyan-500\/75 {
  border-inline-start-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-s-cyan-500\/80 {
  border-inline-start-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-s-cyan-500\/85 {
  border-inline-start-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-s-cyan-500\/90 {
  border-inline-start-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-s-cyan-500\/95 {
  border-inline-start-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-s-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-600\/0 {
  border-inline-start-color: rgb(8 145 178 / 0) !important;
}

.tw-border-s-cyan-600\/10 {
  border-inline-start-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-s-cyan-600\/100 {
  border-inline-start-color: rgb(8 145 178 / 1) !important;
}

.tw-border-s-cyan-600\/15 {
  border-inline-start-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-s-cyan-600\/20 {
  border-inline-start-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-s-cyan-600\/25 {
  border-inline-start-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-s-cyan-600\/30 {
  border-inline-start-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-s-cyan-600\/35 {
  border-inline-start-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-s-cyan-600\/40 {
  border-inline-start-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-s-cyan-600\/45 {
  border-inline-start-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-s-cyan-600\/5 {
  border-inline-start-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-s-cyan-600\/50 {
  border-inline-start-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-s-cyan-600\/55 {
  border-inline-start-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-s-cyan-600\/60 {
  border-inline-start-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-s-cyan-600\/65 {
  border-inline-start-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-s-cyan-600\/70 {
  border-inline-start-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-s-cyan-600\/75 {
  border-inline-start-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-s-cyan-600\/80 {
  border-inline-start-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-s-cyan-600\/85 {
  border-inline-start-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-s-cyan-600\/90 {
  border-inline-start-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-s-cyan-600\/95 {
  border-inline-start-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-s-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-700\/0 {
  border-inline-start-color: rgb(14 116 144 / 0) !important;
}

.tw-border-s-cyan-700\/10 {
  border-inline-start-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-s-cyan-700\/100 {
  border-inline-start-color: rgb(14 116 144 / 1) !important;
}

.tw-border-s-cyan-700\/15 {
  border-inline-start-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-s-cyan-700\/20 {
  border-inline-start-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-s-cyan-700\/25 {
  border-inline-start-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-s-cyan-700\/30 {
  border-inline-start-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-s-cyan-700\/35 {
  border-inline-start-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-s-cyan-700\/40 {
  border-inline-start-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-s-cyan-700\/45 {
  border-inline-start-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-s-cyan-700\/5 {
  border-inline-start-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-s-cyan-700\/50 {
  border-inline-start-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-s-cyan-700\/55 {
  border-inline-start-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-s-cyan-700\/60 {
  border-inline-start-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-s-cyan-700\/65 {
  border-inline-start-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-s-cyan-700\/70 {
  border-inline-start-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-s-cyan-700\/75 {
  border-inline-start-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-s-cyan-700\/80 {
  border-inline-start-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-s-cyan-700\/85 {
  border-inline-start-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-s-cyan-700\/90 {
  border-inline-start-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-s-cyan-700\/95 {
  border-inline-start-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-s-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-800\/0 {
  border-inline-start-color: rgb(21 94 117 / 0) !important;
}

.tw-border-s-cyan-800\/10 {
  border-inline-start-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-s-cyan-800\/100 {
  border-inline-start-color: rgb(21 94 117 / 1) !important;
}

.tw-border-s-cyan-800\/15 {
  border-inline-start-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-s-cyan-800\/20 {
  border-inline-start-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-s-cyan-800\/25 {
  border-inline-start-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-s-cyan-800\/30 {
  border-inline-start-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-s-cyan-800\/35 {
  border-inline-start-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-s-cyan-800\/40 {
  border-inline-start-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-s-cyan-800\/45 {
  border-inline-start-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-s-cyan-800\/5 {
  border-inline-start-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-s-cyan-800\/50 {
  border-inline-start-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-s-cyan-800\/55 {
  border-inline-start-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-s-cyan-800\/60 {
  border-inline-start-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-s-cyan-800\/65 {
  border-inline-start-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-s-cyan-800\/70 {
  border-inline-start-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-s-cyan-800\/75 {
  border-inline-start-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-s-cyan-800\/80 {
  border-inline-start-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-s-cyan-800\/85 {
  border-inline-start-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-s-cyan-800\/90 {
  border-inline-start-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-s-cyan-800\/95 {
  border-inline-start-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-s-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-900\/0 {
  border-inline-start-color: rgb(22 78 99 / 0) !important;
}

.tw-border-s-cyan-900\/10 {
  border-inline-start-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-s-cyan-900\/100 {
  border-inline-start-color: rgb(22 78 99 / 1) !important;
}

.tw-border-s-cyan-900\/15 {
  border-inline-start-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-s-cyan-900\/20 {
  border-inline-start-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-s-cyan-900\/25 {
  border-inline-start-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-s-cyan-900\/30 {
  border-inline-start-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-s-cyan-900\/35 {
  border-inline-start-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-s-cyan-900\/40 {
  border-inline-start-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-s-cyan-900\/45 {
  border-inline-start-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-s-cyan-900\/5 {
  border-inline-start-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-s-cyan-900\/50 {
  border-inline-start-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-s-cyan-900\/55 {
  border-inline-start-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-s-cyan-900\/60 {
  border-inline-start-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-s-cyan-900\/65 {
  border-inline-start-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-s-cyan-900\/70 {
  border-inline-start-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-s-cyan-900\/75 {
  border-inline-start-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-s-cyan-900\/80 {
  border-inline-start-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-s-cyan-900\/85 {
  border-inline-start-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-s-cyan-900\/90 {
  border-inline-start-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-s-cyan-900\/95 {
  border-inline-start-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-s-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-s-cyan-950\/0 {
  border-inline-start-color: rgb(8 51 68 / 0) !important;
}

.tw-border-s-cyan-950\/10 {
  border-inline-start-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-s-cyan-950\/100 {
  border-inline-start-color: rgb(8 51 68 / 1) !important;
}

.tw-border-s-cyan-950\/15 {
  border-inline-start-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-s-cyan-950\/20 {
  border-inline-start-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-s-cyan-950\/25 {
  border-inline-start-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-s-cyan-950\/30 {
  border-inline-start-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-s-cyan-950\/35 {
  border-inline-start-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-s-cyan-950\/40 {
  border-inline-start-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-s-cyan-950\/45 {
  border-inline-start-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-s-cyan-950\/5 {
  border-inline-start-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-s-cyan-950\/50 {
  border-inline-start-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-s-cyan-950\/55 {
  border-inline-start-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-s-cyan-950\/60 {
  border-inline-start-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-s-cyan-950\/65 {
  border-inline-start-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-s-cyan-950\/70 {
  border-inline-start-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-s-cyan-950\/75 {
  border-inline-start-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-s-cyan-950\/80 {
  border-inline-start-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-s-cyan-950\/85 {
  border-inline-start-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-s-cyan-950\/90 {
  border-inline-start-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-s-cyan-950\/95 {
  border-inline-start-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-s-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-100\/0 {
  border-inline-start-color: rgb(209 250 229 / 0) !important;
}

.tw-border-s-emerald-100\/10 {
  border-inline-start-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-s-emerald-100\/100 {
  border-inline-start-color: rgb(209 250 229 / 1) !important;
}

.tw-border-s-emerald-100\/15 {
  border-inline-start-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-s-emerald-100\/20 {
  border-inline-start-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-s-emerald-100\/25 {
  border-inline-start-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-s-emerald-100\/30 {
  border-inline-start-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-s-emerald-100\/35 {
  border-inline-start-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-s-emerald-100\/40 {
  border-inline-start-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-s-emerald-100\/45 {
  border-inline-start-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-s-emerald-100\/5 {
  border-inline-start-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-s-emerald-100\/50 {
  border-inline-start-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-s-emerald-100\/55 {
  border-inline-start-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-s-emerald-100\/60 {
  border-inline-start-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-s-emerald-100\/65 {
  border-inline-start-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-s-emerald-100\/70 {
  border-inline-start-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-s-emerald-100\/75 {
  border-inline-start-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-s-emerald-100\/80 {
  border-inline-start-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-s-emerald-100\/85 {
  border-inline-start-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-s-emerald-100\/90 {
  border-inline-start-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-s-emerald-100\/95 {
  border-inline-start-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-s-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-200\/0 {
  border-inline-start-color: rgb(167 243 208 / 0) !important;
}

.tw-border-s-emerald-200\/10 {
  border-inline-start-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-s-emerald-200\/100 {
  border-inline-start-color: rgb(167 243 208 / 1) !important;
}

.tw-border-s-emerald-200\/15 {
  border-inline-start-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-s-emerald-200\/20 {
  border-inline-start-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-s-emerald-200\/25 {
  border-inline-start-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-s-emerald-200\/30 {
  border-inline-start-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-s-emerald-200\/35 {
  border-inline-start-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-s-emerald-200\/40 {
  border-inline-start-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-s-emerald-200\/45 {
  border-inline-start-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-s-emerald-200\/5 {
  border-inline-start-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-s-emerald-200\/50 {
  border-inline-start-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-s-emerald-200\/55 {
  border-inline-start-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-s-emerald-200\/60 {
  border-inline-start-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-s-emerald-200\/65 {
  border-inline-start-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-s-emerald-200\/70 {
  border-inline-start-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-s-emerald-200\/75 {
  border-inline-start-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-s-emerald-200\/80 {
  border-inline-start-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-s-emerald-200\/85 {
  border-inline-start-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-s-emerald-200\/90 {
  border-inline-start-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-s-emerald-200\/95 {
  border-inline-start-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-s-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-300\/0 {
  border-inline-start-color: rgb(110 231 183 / 0) !important;
}

.tw-border-s-emerald-300\/10 {
  border-inline-start-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-s-emerald-300\/100 {
  border-inline-start-color: rgb(110 231 183 / 1) !important;
}

.tw-border-s-emerald-300\/15 {
  border-inline-start-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-s-emerald-300\/20 {
  border-inline-start-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-s-emerald-300\/25 {
  border-inline-start-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-s-emerald-300\/30 {
  border-inline-start-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-s-emerald-300\/35 {
  border-inline-start-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-s-emerald-300\/40 {
  border-inline-start-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-s-emerald-300\/45 {
  border-inline-start-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-s-emerald-300\/5 {
  border-inline-start-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-s-emerald-300\/50 {
  border-inline-start-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-s-emerald-300\/55 {
  border-inline-start-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-s-emerald-300\/60 {
  border-inline-start-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-s-emerald-300\/65 {
  border-inline-start-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-s-emerald-300\/70 {
  border-inline-start-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-s-emerald-300\/75 {
  border-inline-start-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-s-emerald-300\/80 {
  border-inline-start-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-s-emerald-300\/85 {
  border-inline-start-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-s-emerald-300\/90 {
  border-inline-start-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-s-emerald-300\/95 {
  border-inline-start-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-s-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-400\/0 {
  border-inline-start-color: rgb(52 211 153 / 0) !important;
}

.tw-border-s-emerald-400\/10 {
  border-inline-start-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-s-emerald-400\/100 {
  border-inline-start-color: rgb(52 211 153 / 1) !important;
}

.tw-border-s-emerald-400\/15 {
  border-inline-start-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-s-emerald-400\/20 {
  border-inline-start-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-s-emerald-400\/25 {
  border-inline-start-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-s-emerald-400\/30 {
  border-inline-start-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-s-emerald-400\/35 {
  border-inline-start-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-s-emerald-400\/40 {
  border-inline-start-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-s-emerald-400\/45 {
  border-inline-start-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-s-emerald-400\/5 {
  border-inline-start-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-s-emerald-400\/50 {
  border-inline-start-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-s-emerald-400\/55 {
  border-inline-start-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-s-emerald-400\/60 {
  border-inline-start-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-s-emerald-400\/65 {
  border-inline-start-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-s-emerald-400\/70 {
  border-inline-start-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-s-emerald-400\/75 {
  border-inline-start-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-s-emerald-400\/80 {
  border-inline-start-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-s-emerald-400\/85 {
  border-inline-start-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-s-emerald-400\/90 {
  border-inline-start-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-s-emerald-400\/95 {
  border-inline-start-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-s-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-50\/0 {
  border-inline-start-color: rgb(236 253 245 / 0) !important;
}

.tw-border-s-emerald-50\/10 {
  border-inline-start-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-s-emerald-50\/100 {
  border-inline-start-color: rgb(236 253 245 / 1) !important;
}

.tw-border-s-emerald-50\/15 {
  border-inline-start-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-s-emerald-50\/20 {
  border-inline-start-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-s-emerald-50\/25 {
  border-inline-start-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-s-emerald-50\/30 {
  border-inline-start-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-s-emerald-50\/35 {
  border-inline-start-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-s-emerald-50\/40 {
  border-inline-start-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-s-emerald-50\/45 {
  border-inline-start-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-s-emerald-50\/5 {
  border-inline-start-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-s-emerald-50\/50 {
  border-inline-start-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-s-emerald-50\/55 {
  border-inline-start-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-s-emerald-50\/60 {
  border-inline-start-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-s-emerald-50\/65 {
  border-inline-start-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-s-emerald-50\/70 {
  border-inline-start-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-s-emerald-50\/75 {
  border-inline-start-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-s-emerald-50\/80 {
  border-inline-start-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-s-emerald-50\/85 {
  border-inline-start-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-s-emerald-50\/90 {
  border-inline-start-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-s-emerald-50\/95 {
  border-inline-start-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-s-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-500\/0 {
  border-inline-start-color: rgb(16 185 129 / 0) !important;
}

.tw-border-s-emerald-500\/10 {
  border-inline-start-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-s-emerald-500\/100 {
  border-inline-start-color: rgb(16 185 129 / 1) !important;
}

.tw-border-s-emerald-500\/15 {
  border-inline-start-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-s-emerald-500\/20 {
  border-inline-start-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-s-emerald-500\/25 {
  border-inline-start-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-s-emerald-500\/30 {
  border-inline-start-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-s-emerald-500\/35 {
  border-inline-start-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-s-emerald-500\/40 {
  border-inline-start-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-s-emerald-500\/45 {
  border-inline-start-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-s-emerald-500\/5 {
  border-inline-start-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-s-emerald-500\/50 {
  border-inline-start-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-s-emerald-500\/55 {
  border-inline-start-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-s-emerald-500\/60 {
  border-inline-start-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-s-emerald-500\/65 {
  border-inline-start-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-s-emerald-500\/70 {
  border-inline-start-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-s-emerald-500\/75 {
  border-inline-start-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-s-emerald-500\/80 {
  border-inline-start-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-s-emerald-500\/85 {
  border-inline-start-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-s-emerald-500\/90 {
  border-inline-start-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-s-emerald-500\/95 {
  border-inline-start-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-s-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-600\/0 {
  border-inline-start-color: rgb(5 150 105 / 0) !important;
}

.tw-border-s-emerald-600\/10 {
  border-inline-start-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-s-emerald-600\/100 {
  border-inline-start-color: rgb(5 150 105 / 1) !important;
}

.tw-border-s-emerald-600\/15 {
  border-inline-start-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-s-emerald-600\/20 {
  border-inline-start-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-s-emerald-600\/25 {
  border-inline-start-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-s-emerald-600\/30 {
  border-inline-start-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-s-emerald-600\/35 {
  border-inline-start-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-s-emerald-600\/40 {
  border-inline-start-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-s-emerald-600\/45 {
  border-inline-start-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-s-emerald-600\/5 {
  border-inline-start-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-s-emerald-600\/50 {
  border-inline-start-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-s-emerald-600\/55 {
  border-inline-start-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-s-emerald-600\/60 {
  border-inline-start-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-s-emerald-600\/65 {
  border-inline-start-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-s-emerald-600\/70 {
  border-inline-start-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-s-emerald-600\/75 {
  border-inline-start-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-s-emerald-600\/80 {
  border-inline-start-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-s-emerald-600\/85 {
  border-inline-start-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-s-emerald-600\/90 {
  border-inline-start-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-s-emerald-600\/95 {
  border-inline-start-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-s-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-700\/0 {
  border-inline-start-color: rgb(4 120 87 / 0) !important;
}

.tw-border-s-emerald-700\/10 {
  border-inline-start-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-s-emerald-700\/100 {
  border-inline-start-color: rgb(4 120 87 / 1) !important;
}

.tw-border-s-emerald-700\/15 {
  border-inline-start-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-s-emerald-700\/20 {
  border-inline-start-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-s-emerald-700\/25 {
  border-inline-start-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-s-emerald-700\/30 {
  border-inline-start-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-s-emerald-700\/35 {
  border-inline-start-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-s-emerald-700\/40 {
  border-inline-start-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-s-emerald-700\/45 {
  border-inline-start-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-s-emerald-700\/5 {
  border-inline-start-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-s-emerald-700\/50 {
  border-inline-start-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-s-emerald-700\/55 {
  border-inline-start-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-s-emerald-700\/60 {
  border-inline-start-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-s-emerald-700\/65 {
  border-inline-start-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-s-emerald-700\/70 {
  border-inline-start-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-s-emerald-700\/75 {
  border-inline-start-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-s-emerald-700\/80 {
  border-inline-start-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-s-emerald-700\/85 {
  border-inline-start-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-s-emerald-700\/90 {
  border-inline-start-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-s-emerald-700\/95 {
  border-inline-start-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-s-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-800\/0 {
  border-inline-start-color: rgb(6 95 70 / 0) !important;
}

.tw-border-s-emerald-800\/10 {
  border-inline-start-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-s-emerald-800\/100 {
  border-inline-start-color: rgb(6 95 70 / 1) !important;
}

.tw-border-s-emerald-800\/15 {
  border-inline-start-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-s-emerald-800\/20 {
  border-inline-start-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-s-emerald-800\/25 {
  border-inline-start-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-s-emerald-800\/30 {
  border-inline-start-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-s-emerald-800\/35 {
  border-inline-start-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-s-emerald-800\/40 {
  border-inline-start-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-s-emerald-800\/45 {
  border-inline-start-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-s-emerald-800\/5 {
  border-inline-start-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-s-emerald-800\/50 {
  border-inline-start-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-s-emerald-800\/55 {
  border-inline-start-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-s-emerald-800\/60 {
  border-inline-start-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-s-emerald-800\/65 {
  border-inline-start-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-s-emerald-800\/70 {
  border-inline-start-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-s-emerald-800\/75 {
  border-inline-start-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-s-emerald-800\/80 {
  border-inline-start-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-s-emerald-800\/85 {
  border-inline-start-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-s-emerald-800\/90 {
  border-inline-start-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-s-emerald-800\/95 {
  border-inline-start-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-s-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-900\/0 {
  border-inline-start-color: rgb(6 78 59 / 0) !important;
}

.tw-border-s-emerald-900\/10 {
  border-inline-start-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-s-emerald-900\/100 {
  border-inline-start-color: rgb(6 78 59 / 1) !important;
}

.tw-border-s-emerald-900\/15 {
  border-inline-start-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-s-emerald-900\/20 {
  border-inline-start-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-s-emerald-900\/25 {
  border-inline-start-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-s-emerald-900\/30 {
  border-inline-start-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-s-emerald-900\/35 {
  border-inline-start-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-s-emerald-900\/40 {
  border-inline-start-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-s-emerald-900\/45 {
  border-inline-start-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-s-emerald-900\/5 {
  border-inline-start-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-s-emerald-900\/50 {
  border-inline-start-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-s-emerald-900\/55 {
  border-inline-start-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-s-emerald-900\/60 {
  border-inline-start-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-s-emerald-900\/65 {
  border-inline-start-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-s-emerald-900\/70 {
  border-inline-start-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-s-emerald-900\/75 {
  border-inline-start-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-s-emerald-900\/80 {
  border-inline-start-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-s-emerald-900\/85 {
  border-inline-start-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-s-emerald-900\/90 {
  border-inline-start-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-s-emerald-900\/95 {
  border-inline-start-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-s-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-s-emerald-950\/0 {
  border-inline-start-color: rgb(2 44 34 / 0) !important;
}

.tw-border-s-emerald-950\/10 {
  border-inline-start-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-s-emerald-950\/100 {
  border-inline-start-color: rgb(2 44 34 / 1) !important;
}

.tw-border-s-emerald-950\/15 {
  border-inline-start-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-s-emerald-950\/20 {
  border-inline-start-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-s-emerald-950\/25 {
  border-inline-start-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-s-emerald-950\/30 {
  border-inline-start-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-s-emerald-950\/35 {
  border-inline-start-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-s-emerald-950\/40 {
  border-inline-start-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-s-emerald-950\/45 {
  border-inline-start-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-s-emerald-950\/5 {
  border-inline-start-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-s-emerald-950\/50 {
  border-inline-start-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-s-emerald-950\/55 {
  border-inline-start-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-s-emerald-950\/60 {
  border-inline-start-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-s-emerald-950\/65 {
  border-inline-start-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-s-emerald-950\/70 {
  border-inline-start-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-s-emerald-950\/75 {
  border-inline-start-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-s-emerald-950\/80 {
  border-inline-start-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-s-emerald-950\/85 {
  border-inline-start-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-s-emerald-950\/90 {
  border-inline-start-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-s-emerald-950\/95 {
  border-inline-start-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-s-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-100\/0 {
  border-inline-start-color: rgb(250 232 255 / 0) !important;
}

.tw-border-s-fuchsia-100\/10 {
  border-inline-start-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-s-fuchsia-100\/100 {
  border-inline-start-color: rgb(250 232 255 / 1) !important;
}

.tw-border-s-fuchsia-100\/15 {
  border-inline-start-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-s-fuchsia-100\/20 {
  border-inline-start-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-s-fuchsia-100\/25 {
  border-inline-start-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-s-fuchsia-100\/30 {
  border-inline-start-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-s-fuchsia-100\/35 {
  border-inline-start-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-s-fuchsia-100\/40 {
  border-inline-start-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-s-fuchsia-100\/45 {
  border-inline-start-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-s-fuchsia-100\/5 {
  border-inline-start-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-s-fuchsia-100\/50 {
  border-inline-start-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-s-fuchsia-100\/55 {
  border-inline-start-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-s-fuchsia-100\/60 {
  border-inline-start-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-s-fuchsia-100\/65 {
  border-inline-start-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-s-fuchsia-100\/70 {
  border-inline-start-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-s-fuchsia-100\/75 {
  border-inline-start-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-s-fuchsia-100\/80 {
  border-inline-start-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-s-fuchsia-100\/85 {
  border-inline-start-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-s-fuchsia-100\/90 {
  border-inline-start-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-s-fuchsia-100\/95 {
  border-inline-start-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-s-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-200\/0 {
  border-inline-start-color: rgb(245 208 254 / 0) !important;
}

.tw-border-s-fuchsia-200\/10 {
  border-inline-start-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-s-fuchsia-200\/100 {
  border-inline-start-color: rgb(245 208 254 / 1) !important;
}

.tw-border-s-fuchsia-200\/15 {
  border-inline-start-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-s-fuchsia-200\/20 {
  border-inline-start-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-s-fuchsia-200\/25 {
  border-inline-start-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-s-fuchsia-200\/30 {
  border-inline-start-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-s-fuchsia-200\/35 {
  border-inline-start-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-s-fuchsia-200\/40 {
  border-inline-start-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-s-fuchsia-200\/45 {
  border-inline-start-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-s-fuchsia-200\/5 {
  border-inline-start-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-s-fuchsia-200\/50 {
  border-inline-start-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-s-fuchsia-200\/55 {
  border-inline-start-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-s-fuchsia-200\/60 {
  border-inline-start-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-s-fuchsia-200\/65 {
  border-inline-start-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-s-fuchsia-200\/70 {
  border-inline-start-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-s-fuchsia-200\/75 {
  border-inline-start-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-s-fuchsia-200\/80 {
  border-inline-start-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-s-fuchsia-200\/85 {
  border-inline-start-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-s-fuchsia-200\/90 {
  border-inline-start-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-s-fuchsia-200\/95 {
  border-inline-start-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-s-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-300\/0 {
  border-inline-start-color: rgb(240 171 252 / 0) !important;
}

.tw-border-s-fuchsia-300\/10 {
  border-inline-start-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-s-fuchsia-300\/100 {
  border-inline-start-color: rgb(240 171 252 / 1) !important;
}

.tw-border-s-fuchsia-300\/15 {
  border-inline-start-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-s-fuchsia-300\/20 {
  border-inline-start-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-s-fuchsia-300\/25 {
  border-inline-start-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-s-fuchsia-300\/30 {
  border-inline-start-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-s-fuchsia-300\/35 {
  border-inline-start-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-s-fuchsia-300\/40 {
  border-inline-start-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-s-fuchsia-300\/45 {
  border-inline-start-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-s-fuchsia-300\/5 {
  border-inline-start-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-s-fuchsia-300\/50 {
  border-inline-start-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-s-fuchsia-300\/55 {
  border-inline-start-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-s-fuchsia-300\/60 {
  border-inline-start-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-s-fuchsia-300\/65 {
  border-inline-start-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-s-fuchsia-300\/70 {
  border-inline-start-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-s-fuchsia-300\/75 {
  border-inline-start-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-s-fuchsia-300\/80 {
  border-inline-start-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-s-fuchsia-300\/85 {
  border-inline-start-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-s-fuchsia-300\/90 {
  border-inline-start-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-s-fuchsia-300\/95 {
  border-inline-start-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-s-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-400\/0 {
  border-inline-start-color: rgb(232 121 249 / 0) !important;
}

.tw-border-s-fuchsia-400\/10 {
  border-inline-start-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-s-fuchsia-400\/100 {
  border-inline-start-color: rgb(232 121 249 / 1) !important;
}

.tw-border-s-fuchsia-400\/15 {
  border-inline-start-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-s-fuchsia-400\/20 {
  border-inline-start-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-s-fuchsia-400\/25 {
  border-inline-start-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-s-fuchsia-400\/30 {
  border-inline-start-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-s-fuchsia-400\/35 {
  border-inline-start-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-s-fuchsia-400\/40 {
  border-inline-start-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-s-fuchsia-400\/45 {
  border-inline-start-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-s-fuchsia-400\/5 {
  border-inline-start-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-s-fuchsia-400\/50 {
  border-inline-start-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-s-fuchsia-400\/55 {
  border-inline-start-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-s-fuchsia-400\/60 {
  border-inline-start-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-s-fuchsia-400\/65 {
  border-inline-start-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-s-fuchsia-400\/70 {
  border-inline-start-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-s-fuchsia-400\/75 {
  border-inline-start-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-s-fuchsia-400\/80 {
  border-inline-start-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-s-fuchsia-400\/85 {
  border-inline-start-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-s-fuchsia-400\/90 {
  border-inline-start-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-s-fuchsia-400\/95 {
  border-inline-start-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-s-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-50\/0 {
  border-inline-start-color: rgb(253 244 255 / 0) !important;
}

.tw-border-s-fuchsia-50\/10 {
  border-inline-start-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-s-fuchsia-50\/100 {
  border-inline-start-color: rgb(253 244 255 / 1) !important;
}

.tw-border-s-fuchsia-50\/15 {
  border-inline-start-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-s-fuchsia-50\/20 {
  border-inline-start-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-s-fuchsia-50\/25 {
  border-inline-start-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-s-fuchsia-50\/30 {
  border-inline-start-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-s-fuchsia-50\/35 {
  border-inline-start-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-s-fuchsia-50\/40 {
  border-inline-start-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-s-fuchsia-50\/45 {
  border-inline-start-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-s-fuchsia-50\/5 {
  border-inline-start-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-s-fuchsia-50\/50 {
  border-inline-start-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-s-fuchsia-50\/55 {
  border-inline-start-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-s-fuchsia-50\/60 {
  border-inline-start-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-s-fuchsia-50\/65 {
  border-inline-start-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-s-fuchsia-50\/70 {
  border-inline-start-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-s-fuchsia-50\/75 {
  border-inline-start-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-s-fuchsia-50\/80 {
  border-inline-start-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-s-fuchsia-50\/85 {
  border-inline-start-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-s-fuchsia-50\/90 {
  border-inline-start-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-s-fuchsia-50\/95 {
  border-inline-start-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-s-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-500\/0 {
  border-inline-start-color: rgb(217 70 239 / 0) !important;
}

.tw-border-s-fuchsia-500\/10 {
  border-inline-start-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-s-fuchsia-500\/100 {
  border-inline-start-color: rgb(217 70 239 / 1) !important;
}

.tw-border-s-fuchsia-500\/15 {
  border-inline-start-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-s-fuchsia-500\/20 {
  border-inline-start-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-s-fuchsia-500\/25 {
  border-inline-start-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-s-fuchsia-500\/30 {
  border-inline-start-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-s-fuchsia-500\/35 {
  border-inline-start-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-s-fuchsia-500\/40 {
  border-inline-start-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-s-fuchsia-500\/45 {
  border-inline-start-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-s-fuchsia-500\/5 {
  border-inline-start-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-s-fuchsia-500\/50 {
  border-inline-start-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-s-fuchsia-500\/55 {
  border-inline-start-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-s-fuchsia-500\/60 {
  border-inline-start-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-s-fuchsia-500\/65 {
  border-inline-start-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-s-fuchsia-500\/70 {
  border-inline-start-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-s-fuchsia-500\/75 {
  border-inline-start-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-s-fuchsia-500\/80 {
  border-inline-start-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-s-fuchsia-500\/85 {
  border-inline-start-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-s-fuchsia-500\/90 {
  border-inline-start-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-s-fuchsia-500\/95 {
  border-inline-start-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-s-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-600\/0 {
  border-inline-start-color: rgb(192 38 211 / 0) !important;
}

.tw-border-s-fuchsia-600\/10 {
  border-inline-start-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-s-fuchsia-600\/100 {
  border-inline-start-color: rgb(192 38 211 / 1) !important;
}

.tw-border-s-fuchsia-600\/15 {
  border-inline-start-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-s-fuchsia-600\/20 {
  border-inline-start-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-s-fuchsia-600\/25 {
  border-inline-start-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-s-fuchsia-600\/30 {
  border-inline-start-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-s-fuchsia-600\/35 {
  border-inline-start-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-s-fuchsia-600\/40 {
  border-inline-start-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-s-fuchsia-600\/45 {
  border-inline-start-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-s-fuchsia-600\/5 {
  border-inline-start-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-s-fuchsia-600\/50 {
  border-inline-start-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-s-fuchsia-600\/55 {
  border-inline-start-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-s-fuchsia-600\/60 {
  border-inline-start-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-s-fuchsia-600\/65 {
  border-inline-start-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-s-fuchsia-600\/70 {
  border-inline-start-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-s-fuchsia-600\/75 {
  border-inline-start-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-s-fuchsia-600\/80 {
  border-inline-start-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-s-fuchsia-600\/85 {
  border-inline-start-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-s-fuchsia-600\/90 {
  border-inline-start-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-s-fuchsia-600\/95 {
  border-inline-start-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-s-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-700\/0 {
  border-inline-start-color: rgb(162 28 175 / 0) !important;
}

.tw-border-s-fuchsia-700\/10 {
  border-inline-start-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-s-fuchsia-700\/100 {
  border-inline-start-color: rgb(162 28 175 / 1) !important;
}

.tw-border-s-fuchsia-700\/15 {
  border-inline-start-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-s-fuchsia-700\/20 {
  border-inline-start-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-s-fuchsia-700\/25 {
  border-inline-start-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-s-fuchsia-700\/30 {
  border-inline-start-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-s-fuchsia-700\/35 {
  border-inline-start-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-s-fuchsia-700\/40 {
  border-inline-start-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-s-fuchsia-700\/45 {
  border-inline-start-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-s-fuchsia-700\/5 {
  border-inline-start-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-s-fuchsia-700\/50 {
  border-inline-start-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-s-fuchsia-700\/55 {
  border-inline-start-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-s-fuchsia-700\/60 {
  border-inline-start-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-s-fuchsia-700\/65 {
  border-inline-start-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-s-fuchsia-700\/70 {
  border-inline-start-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-s-fuchsia-700\/75 {
  border-inline-start-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-s-fuchsia-700\/80 {
  border-inline-start-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-s-fuchsia-700\/85 {
  border-inline-start-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-s-fuchsia-700\/90 {
  border-inline-start-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-s-fuchsia-700\/95 {
  border-inline-start-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-s-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-800\/0 {
  border-inline-start-color: rgb(134 25 143 / 0) !important;
}

.tw-border-s-fuchsia-800\/10 {
  border-inline-start-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-s-fuchsia-800\/100 {
  border-inline-start-color: rgb(134 25 143 / 1) !important;
}

.tw-border-s-fuchsia-800\/15 {
  border-inline-start-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-s-fuchsia-800\/20 {
  border-inline-start-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-s-fuchsia-800\/25 {
  border-inline-start-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-s-fuchsia-800\/30 {
  border-inline-start-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-s-fuchsia-800\/35 {
  border-inline-start-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-s-fuchsia-800\/40 {
  border-inline-start-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-s-fuchsia-800\/45 {
  border-inline-start-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-s-fuchsia-800\/5 {
  border-inline-start-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-s-fuchsia-800\/50 {
  border-inline-start-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-s-fuchsia-800\/55 {
  border-inline-start-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-s-fuchsia-800\/60 {
  border-inline-start-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-s-fuchsia-800\/65 {
  border-inline-start-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-s-fuchsia-800\/70 {
  border-inline-start-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-s-fuchsia-800\/75 {
  border-inline-start-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-s-fuchsia-800\/80 {
  border-inline-start-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-s-fuchsia-800\/85 {
  border-inline-start-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-s-fuchsia-800\/90 {
  border-inline-start-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-s-fuchsia-800\/95 {
  border-inline-start-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-s-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-900\/0 {
  border-inline-start-color: rgb(112 26 117 / 0) !important;
}

.tw-border-s-fuchsia-900\/10 {
  border-inline-start-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-s-fuchsia-900\/100 {
  border-inline-start-color: rgb(112 26 117 / 1) !important;
}

.tw-border-s-fuchsia-900\/15 {
  border-inline-start-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-s-fuchsia-900\/20 {
  border-inline-start-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-s-fuchsia-900\/25 {
  border-inline-start-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-s-fuchsia-900\/30 {
  border-inline-start-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-s-fuchsia-900\/35 {
  border-inline-start-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-s-fuchsia-900\/40 {
  border-inline-start-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-s-fuchsia-900\/45 {
  border-inline-start-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-s-fuchsia-900\/5 {
  border-inline-start-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-s-fuchsia-900\/50 {
  border-inline-start-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-s-fuchsia-900\/55 {
  border-inline-start-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-s-fuchsia-900\/60 {
  border-inline-start-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-s-fuchsia-900\/65 {
  border-inline-start-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-s-fuchsia-900\/70 {
  border-inline-start-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-s-fuchsia-900\/75 {
  border-inline-start-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-s-fuchsia-900\/80 {
  border-inline-start-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-s-fuchsia-900\/85 {
  border-inline-start-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-s-fuchsia-900\/90 {
  border-inline-start-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-s-fuchsia-900\/95 {
  border-inline-start-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-s-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-s-fuchsia-950\/0 {
  border-inline-start-color: rgb(74 4 78 / 0) !important;
}

.tw-border-s-fuchsia-950\/10 {
  border-inline-start-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-s-fuchsia-950\/100 {
  border-inline-start-color: rgb(74 4 78 / 1) !important;
}

.tw-border-s-fuchsia-950\/15 {
  border-inline-start-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-s-fuchsia-950\/20 {
  border-inline-start-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-s-fuchsia-950\/25 {
  border-inline-start-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-s-fuchsia-950\/30 {
  border-inline-start-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-s-fuchsia-950\/35 {
  border-inline-start-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-s-fuchsia-950\/40 {
  border-inline-start-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-s-fuchsia-950\/45 {
  border-inline-start-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-s-fuchsia-950\/5 {
  border-inline-start-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-s-fuchsia-950\/50 {
  border-inline-start-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-s-fuchsia-950\/55 {
  border-inline-start-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-s-fuchsia-950\/60 {
  border-inline-start-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-s-fuchsia-950\/65 {
  border-inline-start-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-s-fuchsia-950\/70 {
  border-inline-start-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-s-fuchsia-950\/75 {
  border-inline-start-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-s-fuchsia-950\/80 {
  border-inline-start-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-s-fuchsia-950\/85 {
  border-inline-start-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-s-fuchsia-950\/90 {
  border-inline-start-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-s-fuchsia-950\/95 {
  border-inline-start-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-s-gray-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-100\/0 {
  border-inline-start-color: rgb(243 244 246 / 0) !important;
}

.tw-border-s-gray-100\/10 {
  border-inline-start-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-s-gray-100\/100 {
  border-inline-start-color: rgb(243 244 246 / 1) !important;
}

.tw-border-s-gray-100\/15 {
  border-inline-start-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-s-gray-100\/20 {
  border-inline-start-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-s-gray-100\/25 {
  border-inline-start-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-s-gray-100\/30 {
  border-inline-start-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-s-gray-100\/35 {
  border-inline-start-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-s-gray-100\/40 {
  border-inline-start-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-s-gray-100\/45 {
  border-inline-start-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-s-gray-100\/5 {
  border-inline-start-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-s-gray-100\/50 {
  border-inline-start-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-s-gray-100\/55 {
  border-inline-start-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-s-gray-100\/60 {
  border-inline-start-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-s-gray-100\/65 {
  border-inline-start-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-s-gray-100\/70 {
  border-inline-start-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-s-gray-100\/75 {
  border-inline-start-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-s-gray-100\/80 {
  border-inline-start-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-s-gray-100\/85 {
  border-inline-start-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-s-gray-100\/90 {
  border-inline-start-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-s-gray-100\/95 {
  border-inline-start-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-s-gray-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-200\/0 {
  border-inline-start-color: rgb(229 231 235 / 0) !important;
}

.tw-border-s-gray-200\/10 {
  border-inline-start-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-s-gray-200\/100 {
  border-inline-start-color: rgb(229 231 235 / 1) !important;
}

.tw-border-s-gray-200\/15 {
  border-inline-start-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-s-gray-200\/20 {
  border-inline-start-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-s-gray-200\/25 {
  border-inline-start-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-s-gray-200\/30 {
  border-inline-start-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-s-gray-200\/35 {
  border-inline-start-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-s-gray-200\/40 {
  border-inline-start-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-s-gray-200\/45 {
  border-inline-start-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-s-gray-200\/5 {
  border-inline-start-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-s-gray-200\/50 {
  border-inline-start-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-s-gray-200\/55 {
  border-inline-start-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-s-gray-200\/60 {
  border-inline-start-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-s-gray-200\/65 {
  border-inline-start-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-s-gray-200\/70 {
  border-inline-start-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-s-gray-200\/75 {
  border-inline-start-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-s-gray-200\/80 {
  border-inline-start-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-s-gray-200\/85 {
  border-inline-start-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-s-gray-200\/90 {
  border-inline-start-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-s-gray-200\/95 {
  border-inline-start-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-s-gray-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-300\/0 {
  border-inline-start-color: rgb(209 213 219 / 0) !important;
}

.tw-border-s-gray-300\/10 {
  border-inline-start-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-s-gray-300\/100 {
  border-inline-start-color: rgb(209 213 219 / 1) !important;
}

.tw-border-s-gray-300\/15 {
  border-inline-start-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-s-gray-300\/20 {
  border-inline-start-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-s-gray-300\/25 {
  border-inline-start-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-s-gray-300\/30 {
  border-inline-start-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-s-gray-300\/35 {
  border-inline-start-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-s-gray-300\/40 {
  border-inline-start-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-s-gray-300\/45 {
  border-inline-start-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-s-gray-300\/5 {
  border-inline-start-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-s-gray-300\/50 {
  border-inline-start-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-s-gray-300\/55 {
  border-inline-start-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-s-gray-300\/60 {
  border-inline-start-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-s-gray-300\/65 {
  border-inline-start-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-s-gray-300\/70 {
  border-inline-start-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-s-gray-300\/75 {
  border-inline-start-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-s-gray-300\/80 {
  border-inline-start-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-s-gray-300\/85 {
  border-inline-start-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-s-gray-300\/90 {
  border-inline-start-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-s-gray-300\/95 {
  border-inline-start-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-s-gray-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-400\/0 {
  border-inline-start-color: rgb(156 163 175 / 0) !important;
}

.tw-border-s-gray-400\/10 {
  border-inline-start-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-s-gray-400\/100 {
  border-inline-start-color: rgb(156 163 175 / 1) !important;
}

.tw-border-s-gray-400\/15 {
  border-inline-start-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-s-gray-400\/20 {
  border-inline-start-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-s-gray-400\/25 {
  border-inline-start-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-s-gray-400\/30 {
  border-inline-start-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-s-gray-400\/35 {
  border-inline-start-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-s-gray-400\/40 {
  border-inline-start-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-s-gray-400\/45 {
  border-inline-start-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-s-gray-400\/5 {
  border-inline-start-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-s-gray-400\/50 {
  border-inline-start-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-s-gray-400\/55 {
  border-inline-start-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-s-gray-400\/60 {
  border-inline-start-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-s-gray-400\/65 {
  border-inline-start-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-s-gray-400\/70 {
  border-inline-start-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-s-gray-400\/75 {
  border-inline-start-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-s-gray-400\/80 {
  border-inline-start-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-s-gray-400\/85 {
  border-inline-start-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-s-gray-400\/90 {
  border-inline-start-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-s-gray-400\/95 {
  border-inline-start-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-s-gray-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-50\/0 {
  border-inline-start-color: rgb(249 250 251 / 0) !important;
}

.tw-border-s-gray-50\/10 {
  border-inline-start-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-s-gray-50\/100 {
  border-inline-start-color: rgb(249 250 251 / 1) !important;
}

.tw-border-s-gray-50\/15 {
  border-inline-start-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-s-gray-50\/20 {
  border-inline-start-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-s-gray-50\/25 {
  border-inline-start-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-s-gray-50\/30 {
  border-inline-start-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-s-gray-50\/35 {
  border-inline-start-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-s-gray-50\/40 {
  border-inline-start-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-s-gray-50\/45 {
  border-inline-start-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-s-gray-50\/5 {
  border-inline-start-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-s-gray-50\/50 {
  border-inline-start-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-s-gray-50\/55 {
  border-inline-start-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-s-gray-50\/60 {
  border-inline-start-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-s-gray-50\/65 {
  border-inline-start-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-s-gray-50\/70 {
  border-inline-start-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-s-gray-50\/75 {
  border-inline-start-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-s-gray-50\/80 {
  border-inline-start-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-s-gray-50\/85 {
  border-inline-start-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-s-gray-50\/90 {
  border-inline-start-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-s-gray-50\/95 {
  border-inline-start-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-s-gray-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-500\/0 {
  border-inline-start-color: rgb(107 114 128 / 0) !important;
}

.tw-border-s-gray-500\/10 {
  border-inline-start-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-s-gray-500\/100 {
  border-inline-start-color: rgb(107 114 128 / 1) !important;
}

.tw-border-s-gray-500\/15 {
  border-inline-start-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-s-gray-500\/20 {
  border-inline-start-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-s-gray-500\/25 {
  border-inline-start-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-s-gray-500\/30 {
  border-inline-start-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-s-gray-500\/35 {
  border-inline-start-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-s-gray-500\/40 {
  border-inline-start-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-s-gray-500\/45 {
  border-inline-start-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-s-gray-500\/5 {
  border-inline-start-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-s-gray-500\/50 {
  border-inline-start-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-s-gray-500\/55 {
  border-inline-start-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-s-gray-500\/60 {
  border-inline-start-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-s-gray-500\/65 {
  border-inline-start-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-s-gray-500\/70 {
  border-inline-start-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-s-gray-500\/75 {
  border-inline-start-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-s-gray-500\/80 {
  border-inline-start-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-s-gray-500\/85 {
  border-inline-start-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-s-gray-500\/90 {
  border-inline-start-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-s-gray-500\/95 {
  border-inline-start-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-s-gray-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-600\/0 {
  border-inline-start-color: rgb(75 85 99 / 0) !important;
}

.tw-border-s-gray-600\/10 {
  border-inline-start-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-s-gray-600\/100 {
  border-inline-start-color: rgb(75 85 99 / 1) !important;
}

.tw-border-s-gray-600\/15 {
  border-inline-start-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-s-gray-600\/20 {
  border-inline-start-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-s-gray-600\/25 {
  border-inline-start-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-s-gray-600\/30 {
  border-inline-start-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-s-gray-600\/35 {
  border-inline-start-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-s-gray-600\/40 {
  border-inline-start-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-s-gray-600\/45 {
  border-inline-start-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-s-gray-600\/5 {
  border-inline-start-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-s-gray-600\/50 {
  border-inline-start-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-s-gray-600\/55 {
  border-inline-start-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-s-gray-600\/60 {
  border-inline-start-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-s-gray-600\/65 {
  border-inline-start-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-s-gray-600\/70 {
  border-inline-start-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-s-gray-600\/75 {
  border-inline-start-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-s-gray-600\/80 {
  border-inline-start-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-s-gray-600\/85 {
  border-inline-start-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-s-gray-600\/90 {
  border-inline-start-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-s-gray-600\/95 {
  border-inline-start-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-s-gray-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-700\/0 {
  border-inline-start-color: rgb(55 65 81 / 0) !important;
}

.tw-border-s-gray-700\/10 {
  border-inline-start-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-s-gray-700\/100 {
  border-inline-start-color: rgb(55 65 81 / 1) !important;
}

.tw-border-s-gray-700\/15 {
  border-inline-start-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-s-gray-700\/20 {
  border-inline-start-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-s-gray-700\/25 {
  border-inline-start-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-s-gray-700\/30 {
  border-inline-start-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-s-gray-700\/35 {
  border-inline-start-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-s-gray-700\/40 {
  border-inline-start-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-s-gray-700\/45 {
  border-inline-start-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-s-gray-700\/5 {
  border-inline-start-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-s-gray-700\/50 {
  border-inline-start-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-s-gray-700\/55 {
  border-inline-start-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-s-gray-700\/60 {
  border-inline-start-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-s-gray-700\/65 {
  border-inline-start-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-s-gray-700\/70 {
  border-inline-start-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-s-gray-700\/75 {
  border-inline-start-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-s-gray-700\/80 {
  border-inline-start-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-s-gray-700\/85 {
  border-inline-start-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-s-gray-700\/90 {
  border-inline-start-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-s-gray-700\/95 {
  border-inline-start-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-s-gray-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-800\/0 {
  border-inline-start-color: rgb(31 41 55 / 0) !important;
}

.tw-border-s-gray-800\/10 {
  border-inline-start-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-s-gray-800\/100 {
  border-inline-start-color: rgb(31 41 55 / 1) !important;
}

.tw-border-s-gray-800\/15 {
  border-inline-start-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-s-gray-800\/20 {
  border-inline-start-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-s-gray-800\/25 {
  border-inline-start-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-s-gray-800\/30 {
  border-inline-start-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-s-gray-800\/35 {
  border-inline-start-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-s-gray-800\/40 {
  border-inline-start-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-s-gray-800\/45 {
  border-inline-start-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-s-gray-800\/5 {
  border-inline-start-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-s-gray-800\/50 {
  border-inline-start-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-s-gray-800\/55 {
  border-inline-start-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-s-gray-800\/60 {
  border-inline-start-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-s-gray-800\/65 {
  border-inline-start-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-s-gray-800\/70 {
  border-inline-start-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-s-gray-800\/75 {
  border-inline-start-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-s-gray-800\/80 {
  border-inline-start-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-s-gray-800\/85 {
  border-inline-start-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-s-gray-800\/90 {
  border-inline-start-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-s-gray-800\/95 {
  border-inline-start-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-s-gray-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-900\/0 {
  border-inline-start-color: rgb(17 24 39 / 0) !important;
}

.tw-border-s-gray-900\/10 {
  border-inline-start-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-s-gray-900\/100 {
  border-inline-start-color: rgb(17 24 39 / 1) !important;
}

.tw-border-s-gray-900\/15 {
  border-inline-start-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-s-gray-900\/20 {
  border-inline-start-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-s-gray-900\/25 {
  border-inline-start-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-s-gray-900\/30 {
  border-inline-start-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-s-gray-900\/35 {
  border-inline-start-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-s-gray-900\/40 {
  border-inline-start-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-s-gray-900\/45 {
  border-inline-start-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-s-gray-900\/5 {
  border-inline-start-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-s-gray-900\/50 {
  border-inline-start-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-s-gray-900\/55 {
  border-inline-start-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-s-gray-900\/60 {
  border-inline-start-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-s-gray-900\/65 {
  border-inline-start-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-s-gray-900\/70 {
  border-inline-start-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-s-gray-900\/75 {
  border-inline-start-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-s-gray-900\/80 {
  border-inline-start-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-s-gray-900\/85 {
  border-inline-start-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-s-gray-900\/90 {
  border-inline-start-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-s-gray-900\/95 {
  border-inline-start-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-s-gray-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-s-gray-950\/0 {
  border-inline-start-color: rgb(3 7 18 / 0) !important;
}

.tw-border-s-gray-950\/10 {
  border-inline-start-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-s-gray-950\/100 {
  border-inline-start-color: rgb(3 7 18 / 1) !important;
}

.tw-border-s-gray-950\/15 {
  border-inline-start-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-s-gray-950\/20 {
  border-inline-start-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-s-gray-950\/25 {
  border-inline-start-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-s-gray-950\/30 {
  border-inline-start-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-s-gray-950\/35 {
  border-inline-start-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-s-gray-950\/40 {
  border-inline-start-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-s-gray-950\/45 {
  border-inline-start-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-s-gray-950\/5 {
  border-inline-start-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-s-gray-950\/50 {
  border-inline-start-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-s-gray-950\/55 {
  border-inline-start-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-s-gray-950\/60 {
  border-inline-start-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-s-gray-950\/65 {
  border-inline-start-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-s-gray-950\/70 {
  border-inline-start-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-s-gray-950\/75 {
  border-inline-start-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-s-gray-950\/80 {
  border-inline-start-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-s-gray-950\/85 {
  border-inline-start-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-s-gray-950\/90 {
  border-inline-start-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-s-gray-950\/95 {
  border-inline-start-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-s-green-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-100\/0 {
  border-inline-start-color: rgb(220 252 231 / 0) !important;
}

.tw-border-s-green-100\/10 {
  border-inline-start-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-s-green-100\/100 {
  border-inline-start-color: rgb(220 252 231 / 1) !important;
}

.tw-border-s-green-100\/15 {
  border-inline-start-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-s-green-100\/20 {
  border-inline-start-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-s-green-100\/25 {
  border-inline-start-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-s-green-100\/30 {
  border-inline-start-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-s-green-100\/35 {
  border-inline-start-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-s-green-100\/40 {
  border-inline-start-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-s-green-100\/45 {
  border-inline-start-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-s-green-100\/5 {
  border-inline-start-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-s-green-100\/50 {
  border-inline-start-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-s-green-100\/55 {
  border-inline-start-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-s-green-100\/60 {
  border-inline-start-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-s-green-100\/65 {
  border-inline-start-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-s-green-100\/70 {
  border-inline-start-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-s-green-100\/75 {
  border-inline-start-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-s-green-100\/80 {
  border-inline-start-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-s-green-100\/85 {
  border-inline-start-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-s-green-100\/90 {
  border-inline-start-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-s-green-100\/95 {
  border-inline-start-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-s-green-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-200\/0 {
  border-inline-start-color: rgb(187 247 208 / 0) !important;
}

.tw-border-s-green-200\/10 {
  border-inline-start-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-s-green-200\/100 {
  border-inline-start-color: rgb(187 247 208 / 1) !important;
}

.tw-border-s-green-200\/15 {
  border-inline-start-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-s-green-200\/20 {
  border-inline-start-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-s-green-200\/25 {
  border-inline-start-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-s-green-200\/30 {
  border-inline-start-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-s-green-200\/35 {
  border-inline-start-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-s-green-200\/40 {
  border-inline-start-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-s-green-200\/45 {
  border-inline-start-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-s-green-200\/5 {
  border-inline-start-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-s-green-200\/50 {
  border-inline-start-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-s-green-200\/55 {
  border-inline-start-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-s-green-200\/60 {
  border-inline-start-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-s-green-200\/65 {
  border-inline-start-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-s-green-200\/70 {
  border-inline-start-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-s-green-200\/75 {
  border-inline-start-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-s-green-200\/80 {
  border-inline-start-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-s-green-200\/85 {
  border-inline-start-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-s-green-200\/90 {
  border-inline-start-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-s-green-200\/95 {
  border-inline-start-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-s-green-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-300\/0 {
  border-inline-start-color: rgb(134 239 172 / 0) !important;
}

.tw-border-s-green-300\/10 {
  border-inline-start-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-s-green-300\/100 {
  border-inline-start-color: rgb(134 239 172 / 1) !important;
}

.tw-border-s-green-300\/15 {
  border-inline-start-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-s-green-300\/20 {
  border-inline-start-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-s-green-300\/25 {
  border-inline-start-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-s-green-300\/30 {
  border-inline-start-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-s-green-300\/35 {
  border-inline-start-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-s-green-300\/40 {
  border-inline-start-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-s-green-300\/45 {
  border-inline-start-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-s-green-300\/5 {
  border-inline-start-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-s-green-300\/50 {
  border-inline-start-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-s-green-300\/55 {
  border-inline-start-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-s-green-300\/60 {
  border-inline-start-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-s-green-300\/65 {
  border-inline-start-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-s-green-300\/70 {
  border-inline-start-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-s-green-300\/75 {
  border-inline-start-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-s-green-300\/80 {
  border-inline-start-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-s-green-300\/85 {
  border-inline-start-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-s-green-300\/90 {
  border-inline-start-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-s-green-300\/95 {
  border-inline-start-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-s-green-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-400\/0 {
  border-inline-start-color: rgb(74 222 128 / 0) !important;
}

.tw-border-s-green-400\/10 {
  border-inline-start-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-s-green-400\/100 {
  border-inline-start-color: rgb(74 222 128 / 1) !important;
}

.tw-border-s-green-400\/15 {
  border-inline-start-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-s-green-400\/20 {
  border-inline-start-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-s-green-400\/25 {
  border-inline-start-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-s-green-400\/30 {
  border-inline-start-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-s-green-400\/35 {
  border-inline-start-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-s-green-400\/40 {
  border-inline-start-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-s-green-400\/45 {
  border-inline-start-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-s-green-400\/5 {
  border-inline-start-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-s-green-400\/50 {
  border-inline-start-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-s-green-400\/55 {
  border-inline-start-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-s-green-400\/60 {
  border-inline-start-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-s-green-400\/65 {
  border-inline-start-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-s-green-400\/70 {
  border-inline-start-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-s-green-400\/75 {
  border-inline-start-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-s-green-400\/80 {
  border-inline-start-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-s-green-400\/85 {
  border-inline-start-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-s-green-400\/90 {
  border-inline-start-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-s-green-400\/95 {
  border-inline-start-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-s-green-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-50\/0 {
  border-inline-start-color: rgb(240 253 244 / 0) !important;
}

.tw-border-s-green-50\/10 {
  border-inline-start-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-s-green-50\/100 {
  border-inline-start-color: rgb(240 253 244 / 1) !important;
}

.tw-border-s-green-50\/15 {
  border-inline-start-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-s-green-50\/20 {
  border-inline-start-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-s-green-50\/25 {
  border-inline-start-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-s-green-50\/30 {
  border-inline-start-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-s-green-50\/35 {
  border-inline-start-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-s-green-50\/40 {
  border-inline-start-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-s-green-50\/45 {
  border-inline-start-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-s-green-50\/5 {
  border-inline-start-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-s-green-50\/50 {
  border-inline-start-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-s-green-50\/55 {
  border-inline-start-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-s-green-50\/60 {
  border-inline-start-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-s-green-50\/65 {
  border-inline-start-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-s-green-50\/70 {
  border-inline-start-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-s-green-50\/75 {
  border-inline-start-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-s-green-50\/80 {
  border-inline-start-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-s-green-50\/85 {
  border-inline-start-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-s-green-50\/90 {
  border-inline-start-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-s-green-50\/95 {
  border-inline-start-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-s-green-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-500\/0 {
  border-inline-start-color: rgb(34 197 94 / 0) !important;
}

.tw-border-s-green-500\/10 {
  border-inline-start-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-s-green-500\/100 {
  border-inline-start-color: rgb(34 197 94 / 1) !important;
}

.tw-border-s-green-500\/15 {
  border-inline-start-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-s-green-500\/20 {
  border-inline-start-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-s-green-500\/25 {
  border-inline-start-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-s-green-500\/30 {
  border-inline-start-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-s-green-500\/35 {
  border-inline-start-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-s-green-500\/40 {
  border-inline-start-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-s-green-500\/45 {
  border-inline-start-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-s-green-500\/5 {
  border-inline-start-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-s-green-500\/50 {
  border-inline-start-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-s-green-500\/55 {
  border-inline-start-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-s-green-500\/60 {
  border-inline-start-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-s-green-500\/65 {
  border-inline-start-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-s-green-500\/70 {
  border-inline-start-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-s-green-500\/75 {
  border-inline-start-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-s-green-500\/80 {
  border-inline-start-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-s-green-500\/85 {
  border-inline-start-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-s-green-500\/90 {
  border-inline-start-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-s-green-500\/95 {
  border-inline-start-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-s-green-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-600\/0 {
  border-inline-start-color: rgb(22 163 74 / 0) !important;
}

.tw-border-s-green-600\/10 {
  border-inline-start-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-s-green-600\/100 {
  border-inline-start-color: rgb(22 163 74 / 1) !important;
}

.tw-border-s-green-600\/15 {
  border-inline-start-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-s-green-600\/20 {
  border-inline-start-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-s-green-600\/25 {
  border-inline-start-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-s-green-600\/30 {
  border-inline-start-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-s-green-600\/35 {
  border-inline-start-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-s-green-600\/40 {
  border-inline-start-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-s-green-600\/45 {
  border-inline-start-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-s-green-600\/5 {
  border-inline-start-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-s-green-600\/50 {
  border-inline-start-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-s-green-600\/55 {
  border-inline-start-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-s-green-600\/60 {
  border-inline-start-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-s-green-600\/65 {
  border-inline-start-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-s-green-600\/70 {
  border-inline-start-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-s-green-600\/75 {
  border-inline-start-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-s-green-600\/80 {
  border-inline-start-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-s-green-600\/85 {
  border-inline-start-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-s-green-600\/90 {
  border-inline-start-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-s-green-600\/95 {
  border-inline-start-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-s-green-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-700\/0 {
  border-inline-start-color: rgb(21 128 61 / 0) !important;
}

.tw-border-s-green-700\/10 {
  border-inline-start-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-s-green-700\/100 {
  border-inline-start-color: rgb(21 128 61 / 1) !important;
}

.tw-border-s-green-700\/15 {
  border-inline-start-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-s-green-700\/20 {
  border-inline-start-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-s-green-700\/25 {
  border-inline-start-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-s-green-700\/30 {
  border-inline-start-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-s-green-700\/35 {
  border-inline-start-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-s-green-700\/40 {
  border-inline-start-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-s-green-700\/45 {
  border-inline-start-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-s-green-700\/5 {
  border-inline-start-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-s-green-700\/50 {
  border-inline-start-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-s-green-700\/55 {
  border-inline-start-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-s-green-700\/60 {
  border-inline-start-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-s-green-700\/65 {
  border-inline-start-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-s-green-700\/70 {
  border-inline-start-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-s-green-700\/75 {
  border-inline-start-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-s-green-700\/80 {
  border-inline-start-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-s-green-700\/85 {
  border-inline-start-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-s-green-700\/90 {
  border-inline-start-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-s-green-700\/95 {
  border-inline-start-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-s-green-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-800\/0 {
  border-inline-start-color: rgb(22 101 52 / 0) !important;
}

.tw-border-s-green-800\/10 {
  border-inline-start-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-s-green-800\/100 {
  border-inline-start-color: rgb(22 101 52 / 1) !important;
}

.tw-border-s-green-800\/15 {
  border-inline-start-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-s-green-800\/20 {
  border-inline-start-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-s-green-800\/25 {
  border-inline-start-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-s-green-800\/30 {
  border-inline-start-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-s-green-800\/35 {
  border-inline-start-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-s-green-800\/40 {
  border-inline-start-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-s-green-800\/45 {
  border-inline-start-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-s-green-800\/5 {
  border-inline-start-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-s-green-800\/50 {
  border-inline-start-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-s-green-800\/55 {
  border-inline-start-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-s-green-800\/60 {
  border-inline-start-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-s-green-800\/65 {
  border-inline-start-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-s-green-800\/70 {
  border-inline-start-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-s-green-800\/75 {
  border-inline-start-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-s-green-800\/80 {
  border-inline-start-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-s-green-800\/85 {
  border-inline-start-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-s-green-800\/90 {
  border-inline-start-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-s-green-800\/95 {
  border-inline-start-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-s-green-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-900\/0 {
  border-inline-start-color: rgb(20 83 45 / 0) !important;
}

.tw-border-s-green-900\/10 {
  border-inline-start-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-s-green-900\/100 {
  border-inline-start-color: rgb(20 83 45 / 1) !important;
}

.tw-border-s-green-900\/15 {
  border-inline-start-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-s-green-900\/20 {
  border-inline-start-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-s-green-900\/25 {
  border-inline-start-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-s-green-900\/30 {
  border-inline-start-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-s-green-900\/35 {
  border-inline-start-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-s-green-900\/40 {
  border-inline-start-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-s-green-900\/45 {
  border-inline-start-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-s-green-900\/5 {
  border-inline-start-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-s-green-900\/50 {
  border-inline-start-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-s-green-900\/55 {
  border-inline-start-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-s-green-900\/60 {
  border-inline-start-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-s-green-900\/65 {
  border-inline-start-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-s-green-900\/70 {
  border-inline-start-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-s-green-900\/75 {
  border-inline-start-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-s-green-900\/80 {
  border-inline-start-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-s-green-900\/85 {
  border-inline-start-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-s-green-900\/90 {
  border-inline-start-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-s-green-900\/95 {
  border-inline-start-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-s-green-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-s-green-950\/0 {
  border-inline-start-color: rgb(5 46 22 / 0) !important;
}

.tw-border-s-green-950\/10 {
  border-inline-start-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-s-green-950\/100 {
  border-inline-start-color: rgb(5 46 22 / 1) !important;
}

.tw-border-s-green-950\/15 {
  border-inline-start-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-s-green-950\/20 {
  border-inline-start-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-s-green-950\/25 {
  border-inline-start-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-s-green-950\/30 {
  border-inline-start-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-s-green-950\/35 {
  border-inline-start-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-s-green-950\/40 {
  border-inline-start-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-s-green-950\/45 {
  border-inline-start-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-s-green-950\/5 {
  border-inline-start-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-s-green-950\/50 {
  border-inline-start-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-s-green-950\/55 {
  border-inline-start-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-s-green-950\/60 {
  border-inline-start-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-s-green-950\/65 {
  border-inline-start-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-s-green-950\/70 {
  border-inline-start-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-s-green-950\/75 {
  border-inline-start-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-s-green-950\/80 {
  border-inline-start-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-s-green-950\/85 {
  border-inline-start-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-s-green-950\/90 {
  border-inline-start-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-s-green-950\/95 {
  border-inline-start-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-s-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-100\/0 {
  border-inline-start-color: rgb(224 231 255 / 0) !important;
}

.tw-border-s-indigo-100\/10 {
  border-inline-start-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-s-indigo-100\/100 {
  border-inline-start-color: rgb(224 231 255 / 1) !important;
}

.tw-border-s-indigo-100\/15 {
  border-inline-start-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-s-indigo-100\/20 {
  border-inline-start-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-s-indigo-100\/25 {
  border-inline-start-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-s-indigo-100\/30 {
  border-inline-start-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-s-indigo-100\/35 {
  border-inline-start-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-s-indigo-100\/40 {
  border-inline-start-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-s-indigo-100\/45 {
  border-inline-start-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-s-indigo-100\/5 {
  border-inline-start-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-s-indigo-100\/50 {
  border-inline-start-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-s-indigo-100\/55 {
  border-inline-start-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-s-indigo-100\/60 {
  border-inline-start-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-s-indigo-100\/65 {
  border-inline-start-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-s-indigo-100\/70 {
  border-inline-start-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-s-indigo-100\/75 {
  border-inline-start-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-s-indigo-100\/80 {
  border-inline-start-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-s-indigo-100\/85 {
  border-inline-start-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-s-indigo-100\/90 {
  border-inline-start-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-s-indigo-100\/95 {
  border-inline-start-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-s-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-200\/0 {
  border-inline-start-color: rgb(199 210 254 / 0) !important;
}

.tw-border-s-indigo-200\/10 {
  border-inline-start-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-s-indigo-200\/100 {
  border-inline-start-color: rgb(199 210 254 / 1) !important;
}

.tw-border-s-indigo-200\/15 {
  border-inline-start-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-s-indigo-200\/20 {
  border-inline-start-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-s-indigo-200\/25 {
  border-inline-start-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-s-indigo-200\/30 {
  border-inline-start-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-s-indigo-200\/35 {
  border-inline-start-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-s-indigo-200\/40 {
  border-inline-start-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-s-indigo-200\/45 {
  border-inline-start-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-s-indigo-200\/5 {
  border-inline-start-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-s-indigo-200\/50 {
  border-inline-start-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-s-indigo-200\/55 {
  border-inline-start-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-s-indigo-200\/60 {
  border-inline-start-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-s-indigo-200\/65 {
  border-inline-start-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-s-indigo-200\/70 {
  border-inline-start-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-s-indigo-200\/75 {
  border-inline-start-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-s-indigo-200\/80 {
  border-inline-start-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-s-indigo-200\/85 {
  border-inline-start-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-s-indigo-200\/90 {
  border-inline-start-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-s-indigo-200\/95 {
  border-inline-start-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-s-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-300\/0 {
  border-inline-start-color: rgb(165 180 252 / 0) !important;
}

.tw-border-s-indigo-300\/10 {
  border-inline-start-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-s-indigo-300\/100 {
  border-inline-start-color: rgb(165 180 252 / 1) !important;
}

.tw-border-s-indigo-300\/15 {
  border-inline-start-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-s-indigo-300\/20 {
  border-inline-start-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-s-indigo-300\/25 {
  border-inline-start-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-s-indigo-300\/30 {
  border-inline-start-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-s-indigo-300\/35 {
  border-inline-start-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-s-indigo-300\/40 {
  border-inline-start-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-s-indigo-300\/45 {
  border-inline-start-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-s-indigo-300\/5 {
  border-inline-start-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-s-indigo-300\/50 {
  border-inline-start-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-s-indigo-300\/55 {
  border-inline-start-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-s-indigo-300\/60 {
  border-inline-start-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-s-indigo-300\/65 {
  border-inline-start-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-s-indigo-300\/70 {
  border-inline-start-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-s-indigo-300\/75 {
  border-inline-start-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-s-indigo-300\/80 {
  border-inline-start-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-s-indigo-300\/85 {
  border-inline-start-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-s-indigo-300\/90 {
  border-inline-start-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-s-indigo-300\/95 {
  border-inline-start-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-s-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-400\/0 {
  border-inline-start-color: rgb(129 140 248 / 0) !important;
}

.tw-border-s-indigo-400\/10 {
  border-inline-start-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-s-indigo-400\/100 {
  border-inline-start-color: rgb(129 140 248 / 1) !important;
}

.tw-border-s-indigo-400\/15 {
  border-inline-start-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-s-indigo-400\/20 {
  border-inline-start-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-s-indigo-400\/25 {
  border-inline-start-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-s-indigo-400\/30 {
  border-inline-start-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-s-indigo-400\/35 {
  border-inline-start-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-s-indigo-400\/40 {
  border-inline-start-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-s-indigo-400\/45 {
  border-inline-start-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-s-indigo-400\/5 {
  border-inline-start-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-s-indigo-400\/50 {
  border-inline-start-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-s-indigo-400\/55 {
  border-inline-start-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-s-indigo-400\/60 {
  border-inline-start-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-s-indigo-400\/65 {
  border-inline-start-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-s-indigo-400\/70 {
  border-inline-start-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-s-indigo-400\/75 {
  border-inline-start-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-s-indigo-400\/80 {
  border-inline-start-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-s-indigo-400\/85 {
  border-inline-start-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-s-indigo-400\/90 {
  border-inline-start-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-s-indigo-400\/95 {
  border-inline-start-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-s-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-50\/0 {
  border-inline-start-color: rgb(238 242 255 / 0) !important;
}

.tw-border-s-indigo-50\/10 {
  border-inline-start-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-s-indigo-50\/100 {
  border-inline-start-color: rgb(238 242 255 / 1) !important;
}

.tw-border-s-indigo-50\/15 {
  border-inline-start-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-s-indigo-50\/20 {
  border-inline-start-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-s-indigo-50\/25 {
  border-inline-start-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-s-indigo-50\/30 {
  border-inline-start-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-s-indigo-50\/35 {
  border-inline-start-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-s-indigo-50\/40 {
  border-inline-start-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-s-indigo-50\/45 {
  border-inline-start-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-s-indigo-50\/5 {
  border-inline-start-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-s-indigo-50\/50 {
  border-inline-start-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-s-indigo-50\/55 {
  border-inline-start-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-s-indigo-50\/60 {
  border-inline-start-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-s-indigo-50\/65 {
  border-inline-start-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-s-indigo-50\/70 {
  border-inline-start-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-s-indigo-50\/75 {
  border-inline-start-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-s-indigo-50\/80 {
  border-inline-start-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-s-indigo-50\/85 {
  border-inline-start-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-s-indigo-50\/90 {
  border-inline-start-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-s-indigo-50\/95 {
  border-inline-start-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-s-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-500\/0 {
  border-inline-start-color: rgb(99 102 241 / 0) !important;
}

.tw-border-s-indigo-500\/10 {
  border-inline-start-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-s-indigo-500\/100 {
  border-inline-start-color: rgb(99 102 241 / 1) !important;
}

.tw-border-s-indigo-500\/15 {
  border-inline-start-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-s-indigo-500\/20 {
  border-inline-start-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-s-indigo-500\/25 {
  border-inline-start-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-s-indigo-500\/30 {
  border-inline-start-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-s-indigo-500\/35 {
  border-inline-start-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-s-indigo-500\/40 {
  border-inline-start-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-s-indigo-500\/45 {
  border-inline-start-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-s-indigo-500\/5 {
  border-inline-start-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-s-indigo-500\/50 {
  border-inline-start-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-s-indigo-500\/55 {
  border-inline-start-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-s-indigo-500\/60 {
  border-inline-start-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-s-indigo-500\/65 {
  border-inline-start-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-s-indigo-500\/70 {
  border-inline-start-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-s-indigo-500\/75 {
  border-inline-start-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-s-indigo-500\/80 {
  border-inline-start-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-s-indigo-500\/85 {
  border-inline-start-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-s-indigo-500\/90 {
  border-inline-start-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-s-indigo-500\/95 {
  border-inline-start-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-s-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-600\/0 {
  border-inline-start-color: rgb(79 70 229 / 0) !important;
}

.tw-border-s-indigo-600\/10 {
  border-inline-start-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-s-indigo-600\/100 {
  border-inline-start-color: rgb(79 70 229 / 1) !important;
}

.tw-border-s-indigo-600\/15 {
  border-inline-start-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-s-indigo-600\/20 {
  border-inline-start-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-s-indigo-600\/25 {
  border-inline-start-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-s-indigo-600\/30 {
  border-inline-start-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-s-indigo-600\/35 {
  border-inline-start-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-s-indigo-600\/40 {
  border-inline-start-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-s-indigo-600\/45 {
  border-inline-start-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-s-indigo-600\/5 {
  border-inline-start-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-s-indigo-600\/50 {
  border-inline-start-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-s-indigo-600\/55 {
  border-inline-start-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-s-indigo-600\/60 {
  border-inline-start-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-s-indigo-600\/65 {
  border-inline-start-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-s-indigo-600\/70 {
  border-inline-start-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-s-indigo-600\/75 {
  border-inline-start-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-s-indigo-600\/80 {
  border-inline-start-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-s-indigo-600\/85 {
  border-inline-start-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-s-indigo-600\/90 {
  border-inline-start-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-s-indigo-600\/95 {
  border-inline-start-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-s-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-700\/0 {
  border-inline-start-color: rgb(67 56 202 / 0) !important;
}

.tw-border-s-indigo-700\/10 {
  border-inline-start-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-s-indigo-700\/100 {
  border-inline-start-color: rgb(67 56 202 / 1) !important;
}

.tw-border-s-indigo-700\/15 {
  border-inline-start-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-s-indigo-700\/20 {
  border-inline-start-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-s-indigo-700\/25 {
  border-inline-start-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-s-indigo-700\/30 {
  border-inline-start-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-s-indigo-700\/35 {
  border-inline-start-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-s-indigo-700\/40 {
  border-inline-start-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-s-indigo-700\/45 {
  border-inline-start-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-s-indigo-700\/5 {
  border-inline-start-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-s-indigo-700\/50 {
  border-inline-start-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-s-indigo-700\/55 {
  border-inline-start-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-s-indigo-700\/60 {
  border-inline-start-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-s-indigo-700\/65 {
  border-inline-start-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-s-indigo-700\/70 {
  border-inline-start-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-s-indigo-700\/75 {
  border-inline-start-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-s-indigo-700\/80 {
  border-inline-start-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-s-indigo-700\/85 {
  border-inline-start-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-s-indigo-700\/90 {
  border-inline-start-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-s-indigo-700\/95 {
  border-inline-start-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-s-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-800\/0 {
  border-inline-start-color: rgb(55 48 163 / 0) !important;
}

.tw-border-s-indigo-800\/10 {
  border-inline-start-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-s-indigo-800\/100 {
  border-inline-start-color: rgb(55 48 163 / 1) !important;
}

.tw-border-s-indigo-800\/15 {
  border-inline-start-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-s-indigo-800\/20 {
  border-inline-start-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-s-indigo-800\/25 {
  border-inline-start-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-s-indigo-800\/30 {
  border-inline-start-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-s-indigo-800\/35 {
  border-inline-start-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-s-indigo-800\/40 {
  border-inline-start-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-s-indigo-800\/45 {
  border-inline-start-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-s-indigo-800\/5 {
  border-inline-start-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-s-indigo-800\/50 {
  border-inline-start-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-s-indigo-800\/55 {
  border-inline-start-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-s-indigo-800\/60 {
  border-inline-start-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-s-indigo-800\/65 {
  border-inline-start-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-s-indigo-800\/70 {
  border-inline-start-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-s-indigo-800\/75 {
  border-inline-start-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-s-indigo-800\/80 {
  border-inline-start-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-s-indigo-800\/85 {
  border-inline-start-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-s-indigo-800\/90 {
  border-inline-start-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-s-indigo-800\/95 {
  border-inline-start-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-s-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-900\/0 {
  border-inline-start-color: rgb(49 46 129 / 0) !important;
}

.tw-border-s-indigo-900\/10 {
  border-inline-start-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-s-indigo-900\/100 {
  border-inline-start-color: rgb(49 46 129 / 1) !important;
}

.tw-border-s-indigo-900\/15 {
  border-inline-start-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-s-indigo-900\/20 {
  border-inline-start-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-s-indigo-900\/25 {
  border-inline-start-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-s-indigo-900\/30 {
  border-inline-start-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-s-indigo-900\/35 {
  border-inline-start-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-s-indigo-900\/40 {
  border-inline-start-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-s-indigo-900\/45 {
  border-inline-start-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-s-indigo-900\/5 {
  border-inline-start-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-s-indigo-900\/50 {
  border-inline-start-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-s-indigo-900\/55 {
  border-inline-start-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-s-indigo-900\/60 {
  border-inline-start-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-s-indigo-900\/65 {
  border-inline-start-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-s-indigo-900\/70 {
  border-inline-start-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-s-indigo-900\/75 {
  border-inline-start-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-s-indigo-900\/80 {
  border-inline-start-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-s-indigo-900\/85 {
  border-inline-start-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-s-indigo-900\/90 {
  border-inline-start-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-s-indigo-900\/95 {
  border-inline-start-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-s-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-s-indigo-950\/0 {
  border-inline-start-color: rgb(30 27 75 / 0) !important;
}

.tw-border-s-indigo-950\/10 {
  border-inline-start-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-s-indigo-950\/100 {
  border-inline-start-color: rgb(30 27 75 / 1) !important;
}

.tw-border-s-indigo-950\/15 {
  border-inline-start-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-s-indigo-950\/20 {
  border-inline-start-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-s-indigo-950\/25 {
  border-inline-start-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-s-indigo-950\/30 {
  border-inline-start-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-s-indigo-950\/35 {
  border-inline-start-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-s-indigo-950\/40 {
  border-inline-start-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-s-indigo-950\/45 {
  border-inline-start-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-s-indigo-950\/5 {
  border-inline-start-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-s-indigo-950\/50 {
  border-inline-start-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-s-indigo-950\/55 {
  border-inline-start-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-s-indigo-950\/60 {
  border-inline-start-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-s-indigo-950\/65 {
  border-inline-start-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-s-indigo-950\/70 {
  border-inline-start-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-s-indigo-950\/75 {
  border-inline-start-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-s-indigo-950\/80 {
  border-inline-start-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-s-indigo-950\/85 {
  border-inline-start-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-s-indigo-950\/90 {
  border-inline-start-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-s-indigo-950\/95 {
  border-inline-start-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-s-inherit {
  border-inline-start-color: inherit !important;
}

.tw-border-s-lime-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-100\/0 {
  border-inline-start-color: rgb(236 252 203 / 0) !important;
}

.tw-border-s-lime-100\/10 {
  border-inline-start-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-s-lime-100\/100 {
  border-inline-start-color: rgb(236 252 203 / 1) !important;
}

.tw-border-s-lime-100\/15 {
  border-inline-start-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-s-lime-100\/20 {
  border-inline-start-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-s-lime-100\/25 {
  border-inline-start-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-s-lime-100\/30 {
  border-inline-start-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-s-lime-100\/35 {
  border-inline-start-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-s-lime-100\/40 {
  border-inline-start-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-s-lime-100\/45 {
  border-inline-start-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-s-lime-100\/5 {
  border-inline-start-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-s-lime-100\/50 {
  border-inline-start-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-s-lime-100\/55 {
  border-inline-start-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-s-lime-100\/60 {
  border-inline-start-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-s-lime-100\/65 {
  border-inline-start-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-s-lime-100\/70 {
  border-inline-start-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-s-lime-100\/75 {
  border-inline-start-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-s-lime-100\/80 {
  border-inline-start-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-s-lime-100\/85 {
  border-inline-start-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-s-lime-100\/90 {
  border-inline-start-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-s-lime-100\/95 {
  border-inline-start-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-s-lime-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-200\/0 {
  border-inline-start-color: rgb(217 249 157 / 0) !important;
}

.tw-border-s-lime-200\/10 {
  border-inline-start-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-s-lime-200\/100 {
  border-inline-start-color: rgb(217 249 157 / 1) !important;
}

.tw-border-s-lime-200\/15 {
  border-inline-start-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-s-lime-200\/20 {
  border-inline-start-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-s-lime-200\/25 {
  border-inline-start-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-s-lime-200\/30 {
  border-inline-start-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-s-lime-200\/35 {
  border-inline-start-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-s-lime-200\/40 {
  border-inline-start-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-s-lime-200\/45 {
  border-inline-start-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-s-lime-200\/5 {
  border-inline-start-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-s-lime-200\/50 {
  border-inline-start-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-s-lime-200\/55 {
  border-inline-start-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-s-lime-200\/60 {
  border-inline-start-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-s-lime-200\/65 {
  border-inline-start-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-s-lime-200\/70 {
  border-inline-start-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-s-lime-200\/75 {
  border-inline-start-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-s-lime-200\/80 {
  border-inline-start-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-s-lime-200\/85 {
  border-inline-start-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-s-lime-200\/90 {
  border-inline-start-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-s-lime-200\/95 {
  border-inline-start-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-s-lime-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-300\/0 {
  border-inline-start-color: rgb(190 242 100 / 0) !important;
}

.tw-border-s-lime-300\/10 {
  border-inline-start-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-s-lime-300\/100 {
  border-inline-start-color: rgb(190 242 100 / 1) !important;
}

.tw-border-s-lime-300\/15 {
  border-inline-start-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-s-lime-300\/20 {
  border-inline-start-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-s-lime-300\/25 {
  border-inline-start-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-s-lime-300\/30 {
  border-inline-start-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-s-lime-300\/35 {
  border-inline-start-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-s-lime-300\/40 {
  border-inline-start-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-s-lime-300\/45 {
  border-inline-start-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-s-lime-300\/5 {
  border-inline-start-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-s-lime-300\/50 {
  border-inline-start-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-s-lime-300\/55 {
  border-inline-start-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-s-lime-300\/60 {
  border-inline-start-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-s-lime-300\/65 {
  border-inline-start-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-s-lime-300\/70 {
  border-inline-start-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-s-lime-300\/75 {
  border-inline-start-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-s-lime-300\/80 {
  border-inline-start-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-s-lime-300\/85 {
  border-inline-start-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-s-lime-300\/90 {
  border-inline-start-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-s-lime-300\/95 {
  border-inline-start-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-s-lime-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-400\/0 {
  border-inline-start-color: rgb(163 230 53 / 0) !important;
}

.tw-border-s-lime-400\/10 {
  border-inline-start-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-s-lime-400\/100 {
  border-inline-start-color: rgb(163 230 53 / 1) !important;
}

.tw-border-s-lime-400\/15 {
  border-inline-start-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-s-lime-400\/20 {
  border-inline-start-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-s-lime-400\/25 {
  border-inline-start-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-s-lime-400\/30 {
  border-inline-start-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-s-lime-400\/35 {
  border-inline-start-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-s-lime-400\/40 {
  border-inline-start-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-s-lime-400\/45 {
  border-inline-start-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-s-lime-400\/5 {
  border-inline-start-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-s-lime-400\/50 {
  border-inline-start-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-s-lime-400\/55 {
  border-inline-start-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-s-lime-400\/60 {
  border-inline-start-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-s-lime-400\/65 {
  border-inline-start-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-s-lime-400\/70 {
  border-inline-start-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-s-lime-400\/75 {
  border-inline-start-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-s-lime-400\/80 {
  border-inline-start-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-s-lime-400\/85 {
  border-inline-start-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-s-lime-400\/90 {
  border-inline-start-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-s-lime-400\/95 {
  border-inline-start-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-s-lime-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-50\/0 {
  border-inline-start-color: rgb(247 254 231 / 0) !important;
}

.tw-border-s-lime-50\/10 {
  border-inline-start-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-s-lime-50\/100 {
  border-inline-start-color: rgb(247 254 231 / 1) !important;
}

.tw-border-s-lime-50\/15 {
  border-inline-start-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-s-lime-50\/20 {
  border-inline-start-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-s-lime-50\/25 {
  border-inline-start-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-s-lime-50\/30 {
  border-inline-start-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-s-lime-50\/35 {
  border-inline-start-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-s-lime-50\/40 {
  border-inline-start-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-s-lime-50\/45 {
  border-inline-start-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-s-lime-50\/5 {
  border-inline-start-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-s-lime-50\/50 {
  border-inline-start-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-s-lime-50\/55 {
  border-inline-start-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-s-lime-50\/60 {
  border-inline-start-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-s-lime-50\/65 {
  border-inline-start-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-s-lime-50\/70 {
  border-inline-start-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-s-lime-50\/75 {
  border-inline-start-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-s-lime-50\/80 {
  border-inline-start-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-s-lime-50\/85 {
  border-inline-start-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-s-lime-50\/90 {
  border-inline-start-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-s-lime-50\/95 {
  border-inline-start-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-s-lime-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-500\/0 {
  border-inline-start-color: rgb(132 204 22 / 0) !important;
}

.tw-border-s-lime-500\/10 {
  border-inline-start-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-s-lime-500\/100 {
  border-inline-start-color: rgb(132 204 22 / 1) !important;
}

.tw-border-s-lime-500\/15 {
  border-inline-start-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-s-lime-500\/20 {
  border-inline-start-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-s-lime-500\/25 {
  border-inline-start-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-s-lime-500\/30 {
  border-inline-start-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-s-lime-500\/35 {
  border-inline-start-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-s-lime-500\/40 {
  border-inline-start-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-s-lime-500\/45 {
  border-inline-start-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-s-lime-500\/5 {
  border-inline-start-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-s-lime-500\/50 {
  border-inline-start-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-s-lime-500\/55 {
  border-inline-start-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-s-lime-500\/60 {
  border-inline-start-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-s-lime-500\/65 {
  border-inline-start-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-s-lime-500\/70 {
  border-inline-start-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-s-lime-500\/75 {
  border-inline-start-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-s-lime-500\/80 {
  border-inline-start-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-s-lime-500\/85 {
  border-inline-start-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-s-lime-500\/90 {
  border-inline-start-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-s-lime-500\/95 {
  border-inline-start-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-s-lime-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-600\/0 {
  border-inline-start-color: rgb(101 163 13 / 0) !important;
}

.tw-border-s-lime-600\/10 {
  border-inline-start-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-s-lime-600\/100 {
  border-inline-start-color: rgb(101 163 13 / 1) !important;
}

.tw-border-s-lime-600\/15 {
  border-inline-start-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-s-lime-600\/20 {
  border-inline-start-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-s-lime-600\/25 {
  border-inline-start-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-s-lime-600\/30 {
  border-inline-start-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-s-lime-600\/35 {
  border-inline-start-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-s-lime-600\/40 {
  border-inline-start-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-s-lime-600\/45 {
  border-inline-start-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-s-lime-600\/5 {
  border-inline-start-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-s-lime-600\/50 {
  border-inline-start-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-s-lime-600\/55 {
  border-inline-start-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-s-lime-600\/60 {
  border-inline-start-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-s-lime-600\/65 {
  border-inline-start-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-s-lime-600\/70 {
  border-inline-start-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-s-lime-600\/75 {
  border-inline-start-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-s-lime-600\/80 {
  border-inline-start-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-s-lime-600\/85 {
  border-inline-start-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-s-lime-600\/90 {
  border-inline-start-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-s-lime-600\/95 {
  border-inline-start-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-s-lime-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-700\/0 {
  border-inline-start-color: rgb(77 124 15 / 0) !important;
}

.tw-border-s-lime-700\/10 {
  border-inline-start-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-s-lime-700\/100 {
  border-inline-start-color: rgb(77 124 15 / 1) !important;
}

.tw-border-s-lime-700\/15 {
  border-inline-start-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-s-lime-700\/20 {
  border-inline-start-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-s-lime-700\/25 {
  border-inline-start-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-s-lime-700\/30 {
  border-inline-start-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-s-lime-700\/35 {
  border-inline-start-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-s-lime-700\/40 {
  border-inline-start-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-s-lime-700\/45 {
  border-inline-start-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-s-lime-700\/5 {
  border-inline-start-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-s-lime-700\/50 {
  border-inline-start-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-s-lime-700\/55 {
  border-inline-start-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-s-lime-700\/60 {
  border-inline-start-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-s-lime-700\/65 {
  border-inline-start-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-s-lime-700\/70 {
  border-inline-start-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-s-lime-700\/75 {
  border-inline-start-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-s-lime-700\/80 {
  border-inline-start-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-s-lime-700\/85 {
  border-inline-start-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-s-lime-700\/90 {
  border-inline-start-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-s-lime-700\/95 {
  border-inline-start-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-s-lime-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-800\/0 {
  border-inline-start-color: rgb(63 98 18 / 0) !important;
}

.tw-border-s-lime-800\/10 {
  border-inline-start-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-s-lime-800\/100 {
  border-inline-start-color: rgb(63 98 18 / 1) !important;
}

.tw-border-s-lime-800\/15 {
  border-inline-start-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-s-lime-800\/20 {
  border-inline-start-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-s-lime-800\/25 {
  border-inline-start-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-s-lime-800\/30 {
  border-inline-start-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-s-lime-800\/35 {
  border-inline-start-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-s-lime-800\/40 {
  border-inline-start-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-s-lime-800\/45 {
  border-inline-start-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-s-lime-800\/5 {
  border-inline-start-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-s-lime-800\/50 {
  border-inline-start-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-s-lime-800\/55 {
  border-inline-start-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-s-lime-800\/60 {
  border-inline-start-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-s-lime-800\/65 {
  border-inline-start-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-s-lime-800\/70 {
  border-inline-start-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-s-lime-800\/75 {
  border-inline-start-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-s-lime-800\/80 {
  border-inline-start-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-s-lime-800\/85 {
  border-inline-start-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-s-lime-800\/90 {
  border-inline-start-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-s-lime-800\/95 {
  border-inline-start-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-s-lime-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-900\/0 {
  border-inline-start-color: rgb(54 83 20 / 0) !important;
}

.tw-border-s-lime-900\/10 {
  border-inline-start-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-s-lime-900\/100 {
  border-inline-start-color: rgb(54 83 20 / 1) !important;
}

.tw-border-s-lime-900\/15 {
  border-inline-start-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-s-lime-900\/20 {
  border-inline-start-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-s-lime-900\/25 {
  border-inline-start-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-s-lime-900\/30 {
  border-inline-start-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-s-lime-900\/35 {
  border-inline-start-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-s-lime-900\/40 {
  border-inline-start-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-s-lime-900\/45 {
  border-inline-start-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-s-lime-900\/5 {
  border-inline-start-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-s-lime-900\/50 {
  border-inline-start-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-s-lime-900\/55 {
  border-inline-start-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-s-lime-900\/60 {
  border-inline-start-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-s-lime-900\/65 {
  border-inline-start-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-s-lime-900\/70 {
  border-inline-start-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-s-lime-900\/75 {
  border-inline-start-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-s-lime-900\/80 {
  border-inline-start-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-s-lime-900\/85 {
  border-inline-start-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-s-lime-900\/90 {
  border-inline-start-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-s-lime-900\/95 {
  border-inline-start-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-s-lime-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-s-lime-950\/0 {
  border-inline-start-color: rgb(26 46 5 / 0) !important;
}

.tw-border-s-lime-950\/10 {
  border-inline-start-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-s-lime-950\/100 {
  border-inline-start-color: rgb(26 46 5 / 1) !important;
}

.tw-border-s-lime-950\/15 {
  border-inline-start-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-s-lime-950\/20 {
  border-inline-start-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-s-lime-950\/25 {
  border-inline-start-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-s-lime-950\/30 {
  border-inline-start-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-s-lime-950\/35 {
  border-inline-start-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-s-lime-950\/40 {
  border-inline-start-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-s-lime-950\/45 {
  border-inline-start-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-s-lime-950\/5 {
  border-inline-start-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-s-lime-950\/50 {
  border-inline-start-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-s-lime-950\/55 {
  border-inline-start-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-s-lime-950\/60 {
  border-inline-start-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-s-lime-950\/65 {
  border-inline-start-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-s-lime-950\/70 {
  border-inline-start-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-s-lime-950\/75 {
  border-inline-start-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-s-lime-950\/80 {
  border-inline-start-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-s-lime-950\/85 {
  border-inline-start-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-s-lime-950\/90 {
  border-inline-start-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-s-lime-950\/95 {
  border-inline-start-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-s-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-100\/0 {
  border-inline-start-color: rgb(245 245 245 / 0) !important;
}

.tw-border-s-neutral-100\/10 {
  border-inline-start-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-s-neutral-100\/100 {
  border-inline-start-color: rgb(245 245 245 / 1) !important;
}

.tw-border-s-neutral-100\/15 {
  border-inline-start-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-s-neutral-100\/20 {
  border-inline-start-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-s-neutral-100\/25 {
  border-inline-start-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-s-neutral-100\/30 {
  border-inline-start-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-s-neutral-100\/35 {
  border-inline-start-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-s-neutral-100\/40 {
  border-inline-start-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-s-neutral-100\/45 {
  border-inline-start-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-s-neutral-100\/5 {
  border-inline-start-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-s-neutral-100\/50 {
  border-inline-start-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-s-neutral-100\/55 {
  border-inline-start-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-s-neutral-100\/60 {
  border-inline-start-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-s-neutral-100\/65 {
  border-inline-start-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-s-neutral-100\/70 {
  border-inline-start-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-s-neutral-100\/75 {
  border-inline-start-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-s-neutral-100\/80 {
  border-inline-start-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-s-neutral-100\/85 {
  border-inline-start-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-s-neutral-100\/90 {
  border-inline-start-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-s-neutral-100\/95 {
  border-inline-start-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-s-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-200\/0 {
  border-inline-start-color: rgb(229 229 229 / 0) !important;
}

.tw-border-s-neutral-200\/10 {
  border-inline-start-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-s-neutral-200\/100 {
  border-inline-start-color: rgb(229 229 229 / 1) !important;
}

.tw-border-s-neutral-200\/15 {
  border-inline-start-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-s-neutral-200\/20 {
  border-inline-start-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-s-neutral-200\/25 {
  border-inline-start-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-s-neutral-200\/30 {
  border-inline-start-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-s-neutral-200\/35 {
  border-inline-start-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-s-neutral-200\/40 {
  border-inline-start-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-s-neutral-200\/45 {
  border-inline-start-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-s-neutral-200\/5 {
  border-inline-start-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-s-neutral-200\/50 {
  border-inline-start-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-s-neutral-200\/55 {
  border-inline-start-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-s-neutral-200\/60 {
  border-inline-start-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-s-neutral-200\/65 {
  border-inline-start-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-s-neutral-200\/70 {
  border-inline-start-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-s-neutral-200\/75 {
  border-inline-start-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-s-neutral-200\/80 {
  border-inline-start-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-s-neutral-200\/85 {
  border-inline-start-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-s-neutral-200\/90 {
  border-inline-start-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-s-neutral-200\/95 {
  border-inline-start-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-s-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-300\/0 {
  border-inline-start-color: rgb(212 212 212 / 0) !important;
}

.tw-border-s-neutral-300\/10 {
  border-inline-start-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-s-neutral-300\/100 {
  border-inline-start-color: rgb(212 212 212 / 1) !important;
}

.tw-border-s-neutral-300\/15 {
  border-inline-start-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-s-neutral-300\/20 {
  border-inline-start-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-s-neutral-300\/25 {
  border-inline-start-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-s-neutral-300\/30 {
  border-inline-start-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-s-neutral-300\/35 {
  border-inline-start-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-s-neutral-300\/40 {
  border-inline-start-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-s-neutral-300\/45 {
  border-inline-start-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-s-neutral-300\/5 {
  border-inline-start-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-s-neutral-300\/50 {
  border-inline-start-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-s-neutral-300\/55 {
  border-inline-start-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-s-neutral-300\/60 {
  border-inline-start-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-s-neutral-300\/65 {
  border-inline-start-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-s-neutral-300\/70 {
  border-inline-start-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-s-neutral-300\/75 {
  border-inline-start-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-s-neutral-300\/80 {
  border-inline-start-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-s-neutral-300\/85 {
  border-inline-start-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-s-neutral-300\/90 {
  border-inline-start-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-s-neutral-300\/95 {
  border-inline-start-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-s-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-400\/0 {
  border-inline-start-color: rgb(163 163 163 / 0) !important;
}

.tw-border-s-neutral-400\/10 {
  border-inline-start-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-s-neutral-400\/100 {
  border-inline-start-color: rgb(163 163 163 / 1) !important;
}

.tw-border-s-neutral-400\/15 {
  border-inline-start-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-s-neutral-400\/20 {
  border-inline-start-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-s-neutral-400\/25 {
  border-inline-start-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-s-neutral-400\/30 {
  border-inline-start-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-s-neutral-400\/35 {
  border-inline-start-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-s-neutral-400\/40 {
  border-inline-start-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-s-neutral-400\/45 {
  border-inline-start-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-s-neutral-400\/5 {
  border-inline-start-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-s-neutral-400\/50 {
  border-inline-start-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-s-neutral-400\/55 {
  border-inline-start-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-s-neutral-400\/60 {
  border-inline-start-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-s-neutral-400\/65 {
  border-inline-start-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-s-neutral-400\/70 {
  border-inline-start-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-s-neutral-400\/75 {
  border-inline-start-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-s-neutral-400\/80 {
  border-inline-start-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-s-neutral-400\/85 {
  border-inline-start-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-s-neutral-400\/90 {
  border-inline-start-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-s-neutral-400\/95 {
  border-inline-start-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-s-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-50\/0 {
  border-inline-start-color: rgb(250 250 250 / 0) !important;
}

.tw-border-s-neutral-50\/10 {
  border-inline-start-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-s-neutral-50\/100 {
  border-inline-start-color: rgb(250 250 250 / 1) !important;
}

.tw-border-s-neutral-50\/15 {
  border-inline-start-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-s-neutral-50\/20 {
  border-inline-start-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-s-neutral-50\/25 {
  border-inline-start-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-s-neutral-50\/30 {
  border-inline-start-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-s-neutral-50\/35 {
  border-inline-start-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-s-neutral-50\/40 {
  border-inline-start-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-s-neutral-50\/45 {
  border-inline-start-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-s-neutral-50\/5 {
  border-inline-start-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-s-neutral-50\/50 {
  border-inline-start-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-s-neutral-50\/55 {
  border-inline-start-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-s-neutral-50\/60 {
  border-inline-start-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-s-neutral-50\/65 {
  border-inline-start-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-s-neutral-50\/70 {
  border-inline-start-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-s-neutral-50\/75 {
  border-inline-start-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-s-neutral-50\/80 {
  border-inline-start-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-s-neutral-50\/85 {
  border-inline-start-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-s-neutral-50\/90 {
  border-inline-start-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-s-neutral-50\/95 {
  border-inline-start-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-s-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-500\/0 {
  border-inline-start-color: rgb(115 115 115 / 0) !important;
}

.tw-border-s-neutral-500\/10 {
  border-inline-start-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-s-neutral-500\/100 {
  border-inline-start-color: rgb(115 115 115 / 1) !important;
}

.tw-border-s-neutral-500\/15 {
  border-inline-start-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-s-neutral-500\/20 {
  border-inline-start-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-s-neutral-500\/25 {
  border-inline-start-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-s-neutral-500\/30 {
  border-inline-start-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-s-neutral-500\/35 {
  border-inline-start-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-s-neutral-500\/40 {
  border-inline-start-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-s-neutral-500\/45 {
  border-inline-start-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-s-neutral-500\/5 {
  border-inline-start-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-s-neutral-500\/50 {
  border-inline-start-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-s-neutral-500\/55 {
  border-inline-start-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-s-neutral-500\/60 {
  border-inline-start-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-s-neutral-500\/65 {
  border-inline-start-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-s-neutral-500\/70 {
  border-inline-start-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-s-neutral-500\/75 {
  border-inline-start-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-s-neutral-500\/80 {
  border-inline-start-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-s-neutral-500\/85 {
  border-inline-start-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-s-neutral-500\/90 {
  border-inline-start-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-s-neutral-500\/95 {
  border-inline-start-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-s-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-600\/0 {
  border-inline-start-color: rgb(82 82 82 / 0) !important;
}

.tw-border-s-neutral-600\/10 {
  border-inline-start-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-s-neutral-600\/100 {
  border-inline-start-color: rgb(82 82 82 / 1) !important;
}

.tw-border-s-neutral-600\/15 {
  border-inline-start-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-s-neutral-600\/20 {
  border-inline-start-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-s-neutral-600\/25 {
  border-inline-start-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-s-neutral-600\/30 {
  border-inline-start-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-s-neutral-600\/35 {
  border-inline-start-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-s-neutral-600\/40 {
  border-inline-start-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-s-neutral-600\/45 {
  border-inline-start-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-s-neutral-600\/5 {
  border-inline-start-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-s-neutral-600\/50 {
  border-inline-start-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-s-neutral-600\/55 {
  border-inline-start-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-s-neutral-600\/60 {
  border-inline-start-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-s-neutral-600\/65 {
  border-inline-start-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-s-neutral-600\/70 {
  border-inline-start-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-s-neutral-600\/75 {
  border-inline-start-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-s-neutral-600\/80 {
  border-inline-start-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-s-neutral-600\/85 {
  border-inline-start-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-s-neutral-600\/90 {
  border-inline-start-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-s-neutral-600\/95 {
  border-inline-start-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-s-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-700\/0 {
  border-inline-start-color: rgb(64 64 64 / 0) !important;
}

.tw-border-s-neutral-700\/10 {
  border-inline-start-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-s-neutral-700\/100 {
  border-inline-start-color: rgb(64 64 64 / 1) !important;
}

.tw-border-s-neutral-700\/15 {
  border-inline-start-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-s-neutral-700\/20 {
  border-inline-start-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-s-neutral-700\/25 {
  border-inline-start-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-s-neutral-700\/30 {
  border-inline-start-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-s-neutral-700\/35 {
  border-inline-start-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-s-neutral-700\/40 {
  border-inline-start-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-s-neutral-700\/45 {
  border-inline-start-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-s-neutral-700\/5 {
  border-inline-start-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-s-neutral-700\/50 {
  border-inline-start-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-s-neutral-700\/55 {
  border-inline-start-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-s-neutral-700\/60 {
  border-inline-start-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-s-neutral-700\/65 {
  border-inline-start-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-s-neutral-700\/70 {
  border-inline-start-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-s-neutral-700\/75 {
  border-inline-start-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-s-neutral-700\/80 {
  border-inline-start-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-s-neutral-700\/85 {
  border-inline-start-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-s-neutral-700\/90 {
  border-inline-start-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-s-neutral-700\/95 {
  border-inline-start-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-s-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-800\/0 {
  border-inline-start-color: rgb(38 38 38 / 0) !important;
}

.tw-border-s-neutral-800\/10 {
  border-inline-start-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-s-neutral-800\/100 {
  border-inline-start-color: rgb(38 38 38 / 1) !important;
}

.tw-border-s-neutral-800\/15 {
  border-inline-start-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-s-neutral-800\/20 {
  border-inline-start-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-s-neutral-800\/25 {
  border-inline-start-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-s-neutral-800\/30 {
  border-inline-start-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-s-neutral-800\/35 {
  border-inline-start-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-s-neutral-800\/40 {
  border-inline-start-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-s-neutral-800\/45 {
  border-inline-start-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-s-neutral-800\/5 {
  border-inline-start-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-s-neutral-800\/50 {
  border-inline-start-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-s-neutral-800\/55 {
  border-inline-start-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-s-neutral-800\/60 {
  border-inline-start-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-s-neutral-800\/65 {
  border-inline-start-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-s-neutral-800\/70 {
  border-inline-start-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-s-neutral-800\/75 {
  border-inline-start-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-s-neutral-800\/80 {
  border-inline-start-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-s-neutral-800\/85 {
  border-inline-start-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-s-neutral-800\/90 {
  border-inline-start-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-s-neutral-800\/95 {
  border-inline-start-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-s-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-900\/0 {
  border-inline-start-color: rgb(23 23 23 / 0) !important;
}

.tw-border-s-neutral-900\/10 {
  border-inline-start-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-s-neutral-900\/100 {
  border-inline-start-color: rgb(23 23 23 / 1) !important;
}

.tw-border-s-neutral-900\/15 {
  border-inline-start-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-s-neutral-900\/20 {
  border-inline-start-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-s-neutral-900\/25 {
  border-inline-start-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-s-neutral-900\/30 {
  border-inline-start-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-s-neutral-900\/35 {
  border-inline-start-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-s-neutral-900\/40 {
  border-inline-start-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-s-neutral-900\/45 {
  border-inline-start-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-s-neutral-900\/5 {
  border-inline-start-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-s-neutral-900\/50 {
  border-inline-start-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-s-neutral-900\/55 {
  border-inline-start-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-s-neutral-900\/60 {
  border-inline-start-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-s-neutral-900\/65 {
  border-inline-start-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-s-neutral-900\/70 {
  border-inline-start-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-s-neutral-900\/75 {
  border-inline-start-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-s-neutral-900\/80 {
  border-inline-start-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-s-neutral-900\/85 {
  border-inline-start-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-s-neutral-900\/90 {
  border-inline-start-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-s-neutral-900\/95 {
  border-inline-start-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-s-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-s-neutral-950\/0 {
  border-inline-start-color: rgb(10 10 10 / 0) !important;
}

.tw-border-s-neutral-950\/10 {
  border-inline-start-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-s-neutral-950\/100 {
  border-inline-start-color: rgb(10 10 10 / 1) !important;
}

.tw-border-s-neutral-950\/15 {
  border-inline-start-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-s-neutral-950\/20 {
  border-inline-start-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-s-neutral-950\/25 {
  border-inline-start-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-s-neutral-950\/30 {
  border-inline-start-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-s-neutral-950\/35 {
  border-inline-start-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-s-neutral-950\/40 {
  border-inline-start-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-s-neutral-950\/45 {
  border-inline-start-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-s-neutral-950\/5 {
  border-inline-start-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-s-neutral-950\/50 {
  border-inline-start-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-s-neutral-950\/55 {
  border-inline-start-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-s-neutral-950\/60 {
  border-inline-start-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-s-neutral-950\/65 {
  border-inline-start-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-s-neutral-950\/70 {
  border-inline-start-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-s-neutral-950\/75 {
  border-inline-start-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-s-neutral-950\/80 {
  border-inline-start-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-s-neutral-950\/85 {
  border-inline-start-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-s-neutral-950\/90 {
  border-inline-start-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-s-neutral-950\/95 {
  border-inline-start-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-s-orange-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-100\/0 {
  border-inline-start-color: rgb(255 237 213 / 0) !important;
}

.tw-border-s-orange-100\/10 {
  border-inline-start-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-s-orange-100\/100 {
  border-inline-start-color: rgb(255 237 213 / 1) !important;
}

.tw-border-s-orange-100\/15 {
  border-inline-start-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-s-orange-100\/20 {
  border-inline-start-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-s-orange-100\/25 {
  border-inline-start-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-s-orange-100\/30 {
  border-inline-start-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-s-orange-100\/35 {
  border-inline-start-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-s-orange-100\/40 {
  border-inline-start-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-s-orange-100\/45 {
  border-inline-start-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-s-orange-100\/5 {
  border-inline-start-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-s-orange-100\/50 {
  border-inline-start-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-s-orange-100\/55 {
  border-inline-start-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-s-orange-100\/60 {
  border-inline-start-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-s-orange-100\/65 {
  border-inline-start-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-s-orange-100\/70 {
  border-inline-start-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-s-orange-100\/75 {
  border-inline-start-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-s-orange-100\/80 {
  border-inline-start-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-s-orange-100\/85 {
  border-inline-start-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-s-orange-100\/90 {
  border-inline-start-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-s-orange-100\/95 {
  border-inline-start-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-s-orange-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-200\/0 {
  border-inline-start-color: rgb(254 215 170 / 0) !important;
}

.tw-border-s-orange-200\/10 {
  border-inline-start-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-s-orange-200\/100 {
  border-inline-start-color: rgb(254 215 170 / 1) !important;
}

.tw-border-s-orange-200\/15 {
  border-inline-start-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-s-orange-200\/20 {
  border-inline-start-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-s-orange-200\/25 {
  border-inline-start-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-s-orange-200\/30 {
  border-inline-start-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-s-orange-200\/35 {
  border-inline-start-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-s-orange-200\/40 {
  border-inline-start-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-s-orange-200\/45 {
  border-inline-start-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-s-orange-200\/5 {
  border-inline-start-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-s-orange-200\/50 {
  border-inline-start-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-s-orange-200\/55 {
  border-inline-start-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-s-orange-200\/60 {
  border-inline-start-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-s-orange-200\/65 {
  border-inline-start-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-s-orange-200\/70 {
  border-inline-start-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-s-orange-200\/75 {
  border-inline-start-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-s-orange-200\/80 {
  border-inline-start-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-s-orange-200\/85 {
  border-inline-start-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-s-orange-200\/90 {
  border-inline-start-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-s-orange-200\/95 {
  border-inline-start-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-s-orange-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-300\/0 {
  border-inline-start-color: rgb(253 186 116 / 0) !important;
}

.tw-border-s-orange-300\/10 {
  border-inline-start-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-s-orange-300\/100 {
  border-inline-start-color: rgb(253 186 116 / 1) !important;
}

.tw-border-s-orange-300\/15 {
  border-inline-start-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-s-orange-300\/20 {
  border-inline-start-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-s-orange-300\/25 {
  border-inline-start-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-s-orange-300\/30 {
  border-inline-start-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-s-orange-300\/35 {
  border-inline-start-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-s-orange-300\/40 {
  border-inline-start-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-s-orange-300\/45 {
  border-inline-start-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-s-orange-300\/5 {
  border-inline-start-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-s-orange-300\/50 {
  border-inline-start-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-s-orange-300\/55 {
  border-inline-start-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-s-orange-300\/60 {
  border-inline-start-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-s-orange-300\/65 {
  border-inline-start-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-s-orange-300\/70 {
  border-inline-start-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-s-orange-300\/75 {
  border-inline-start-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-s-orange-300\/80 {
  border-inline-start-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-s-orange-300\/85 {
  border-inline-start-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-s-orange-300\/90 {
  border-inline-start-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-s-orange-300\/95 {
  border-inline-start-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-s-orange-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-400\/0 {
  border-inline-start-color: rgb(251 146 60 / 0) !important;
}

.tw-border-s-orange-400\/10 {
  border-inline-start-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-s-orange-400\/100 {
  border-inline-start-color: rgb(251 146 60 / 1) !important;
}

.tw-border-s-orange-400\/15 {
  border-inline-start-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-s-orange-400\/20 {
  border-inline-start-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-s-orange-400\/25 {
  border-inline-start-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-s-orange-400\/30 {
  border-inline-start-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-s-orange-400\/35 {
  border-inline-start-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-s-orange-400\/40 {
  border-inline-start-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-s-orange-400\/45 {
  border-inline-start-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-s-orange-400\/5 {
  border-inline-start-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-s-orange-400\/50 {
  border-inline-start-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-s-orange-400\/55 {
  border-inline-start-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-s-orange-400\/60 {
  border-inline-start-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-s-orange-400\/65 {
  border-inline-start-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-s-orange-400\/70 {
  border-inline-start-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-s-orange-400\/75 {
  border-inline-start-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-s-orange-400\/80 {
  border-inline-start-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-s-orange-400\/85 {
  border-inline-start-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-s-orange-400\/90 {
  border-inline-start-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-s-orange-400\/95 {
  border-inline-start-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-s-orange-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-50\/0 {
  border-inline-start-color: rgb(255 247 237 / 0) !important;
}

.tw-border-s-orange-50\/10 {
  border-inline-start-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-s-orange-50\/100 {
  border-inline-start-color: rgb(255 247 237 / 1) !important;
}

.tw-border-s-orange-50\/15 {
  border-inline-start-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-s-orange-50\/20 {
  border-inline-start-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-s-orange-50\/25 {
  border-inline-start-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-s-orange-50\/30 {
  border-inline-start-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-s-orange-50\/35 {
  border-inline-start-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-s-orange-50\/40 {
  border-inline-start-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-s-orange-50\/45 {
  border-inline-start-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-s-orange-50\/5 {
  border-inline-start-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-s-orange-50\/50 {
  border-inline-start-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-s-orange-50\/55 {
  border-inline-start-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-s-orange-50\/60 {
  border-inline-start-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-s-orange-50\/65 {
  border-inline-start-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-s-orange-50\/70 {
  border-inline-start-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-s-orange-50\/75 {
  border-inline-start-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-s-orange-50\/80 {
  border-inline-start-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-s-orange-50\/85 {
  border-inline-start-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-s-orange-50\/90 {
  border-inline-start-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-s-orange-50\/95 {
  border-inline-start-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-s-orange-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-500\/0 {
  border-inline-start-color: rgb(249 115 22 / 0) !important;
}

.tw-border-s-orange-500\/10 {
  border-inline-start-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-s-orange-500\/100 {
  border-inline-start-color: rgb(249 115 22 / 1) !important;
}

.tw-border-s-orange-500\/15 {
  border-inline-start-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-s-orange-500\/20 {
  border-inline-start-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-s-orange-500\/25 {
  border-inline-start-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-s-orange-500\/30 {
  border-inline-start-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-s-orange-500\/35 {
  border-inline-start-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-s-orange-500\/40 {
  border-inline-start-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-s-orange-500\/45 {
  border-inline-start-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-s-orange-500\/5 {
  border-inline-start-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-s-orange-500\/50 {
  border-inline-start-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-s-orange-500\/55 {
  border-inline-start-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-s-orange-500\/60 {
  border-inline-start-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-s-orange-500\/65 {
  border-inline-start-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-s-orange-500\/70 {
  border-inline-start-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-s-orange-500\/75 {
  border-inline-start-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-s-orange-500\/80 {
  border-inline-start-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-s-orange-500\/85 {
  border-inline-start-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-s-orange-500\/90 {
  border-inline-start-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-s-orange-500\/95 {
  border-inline-start-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-s-orange-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-600\/0 {
  border-inline-start-color: rgb(234 88 12 / 0) !important;
}

.tw-border-s-orange-600\/10 {
  border-inline-start-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-s-orange-600\/100 {
  border-inline-start-color: rgb(234 88 12 / 1) !important;
}

.tw-border-s-orange-600\/15 {
  border-inline-start-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-s-orange-600\/20 {
  border-inline-start-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-s-orange-600\/25 {
  border-inline-start-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-s-orange-600\/30 {
  border-inline-start-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-s-orange-600\/35 {
  border-inline-start-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-s-orange-600\/40 {
  border-inline-start-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-s-orange-600\/45 {
  border-inline-start-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-s-orange-600\/5 {
  border-inline-start-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-s-orange-600\/50 {
  border-inline-start-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-s-orange-600\/55 {
  border-inline-start-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-s-orange-600\/60 {
  border-inline-start-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-s-orange-600\/65 {
  border-inline-start-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-s-orange-600\/70 {
  border-inline-start-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-s-orange-600\/75 {
  border-inline-start-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-s-orange-600\/80 {
  border-inline-start-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-s-orange-600\/85 {
  border-inline-start-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-s-orange-600\/90 {
  border-inline-start-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-s-orange-600\/95 {
  border-inline-start-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-s-orange-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-700\/0 {
  border-inline-start-color: rgb(194 65 12 / 0) !important;
}

.tw-border-s-orange-700\/10 {
  border-inline-start-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-s-orange-700\/100 {
  border-inline-start-color: rgb(194 65 12 / 1) !important;
}

.tw-border-s-orange-700\/15 {
  border-inline-start-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-s-orange-700\/20 {
  border-inline-start-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-s-orange-700\/25 {
  border-inline-start-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-s-orange-700\/30 {
  border-inline-start-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-s-orange-700\/35 {
  border-inline-start-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-s-orange-700\/40 {
  border-inline-start-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-s-orange-700\/45 {
  border-inline-start-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-s-orange-700\/5 {
  border-inline-start-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-s-orange-700\/50 {
  border-inline-start-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-s-orange-700\/55 {
  border-inline-start-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-s-orange-700\/60 {
  border-inline-start-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-s-orange-700\/65 {
  border-inline-start-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-s-orange-700\/70 {
  border-inline-start-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-s-orange-700\/75 {
  border-inline-start-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-s-orange-700\/80 {
  border-inline-start-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-s-orange-700\/85 {
  border-inline-start-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-s-orange-700\/90 {
  border-inline-start-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-s-orange-700\/95 {
  border-inline-start-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-s-orange-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-800\/0 {
  border-inline-start-color: rgb(154 52 18 / 0) !important;
}

.tw-border-s-orange-800\/10 {
  border-inline-start-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-s-orange-800\/100 {
  border-inline-start-color: rgb(154 52 18 / 1) !important;
}

.tw-border-s-orange-800\/15 {
  border-inline-start-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-s-orange-800\/20 {
  border-inline-start-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-s-orange-800\/25 {
  border-inline-start-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-s-orange-800\/30 {
  border-inline-start-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-s-orange-800\/35 {
  border-inline-start-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-s-orange-800\/40 {
  border-inline-start-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-s-orange-800\/45 {
  border-inline-start-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-s-orange-800\/5 {
  border-inline-start-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-s-orange-800\/50 {
  border-inline-start-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-s-orange-800\/55 {
  border-inline-start-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-s-orange-800\/60 {
  border-inline-start-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-s-orange-800\/65 {
  border-inline-start-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-s-orange-800\/70 {
  border-inline-start-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-s-orange-800\/75 {
  border-inline-start-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-s-orange-800\/80 {
  border-inline-start-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-s-orange-800\/85 {
  border-inline-start-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-s-orange-800\/90 {
  border-inline-start-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-s-orange-800\/95 {
  border-inline-start-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-s-orange-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-900\/0 {
  border-inline-start-color: rgb(124 45 18 / 0) !important;
}

.tw-border-s-orange-900\/10 {
  border-inline-start-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-s-orange-900\/100 {
  border-inline-start-color: rgb(124 45 18 / 1) !important;
}

.tw-border-s-orange-900\/15 {
  border-inline-start-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-s-orange-900\/20 {
  border-inline-start-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-s-orange-900\/25 {
  border-inline-start-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-s-orange-900\/30 {
  border-inline-start-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-s-orange-900\/35 {
  border-inline-start-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-s-orange-900\/40 {
  border-inline-start-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-s-orange-900\/45 {
  border-inline-start-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-s-orange-900\/5 {
  border-inline-start-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-s-orange-900\/50 {
  border-inline-start-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-s-orange-900\/55 {
  border-inline-start-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-s-orange-900\/60 {
  border-inline-start-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-s-orange-900\/65 {
  border-inline-start-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-s-orange-900\/70 {
  border-inline-start-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-s-orange-900\/75 {
  border-inline-start-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-s-orange-900\/80 {
  border-inline-start-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-s-orange-900\/85 {
  border-inline-start-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-s-orange-900\/90 {
  border-inline-start-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-s-orange-900\/95 {
  border-inline-start-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-s-orange-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-s-orange-950\/0 {
  border-inline-start-color: rgb(67 20 7 / 0) !important;
}

.tw-border-s-orange-950\/10 {
  border-inline-start-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-s-orange-950\/100 {
  border-inline-start-color: rgb(67 20 7 / 1) !important;
}

.tw-border-s-orange-950\/15 {
  border-inline-start-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-s-orange-950\/20 {
  border-inline-start-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-s-orange-950\/25 {
  border-inline-start-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-s-orange-950\/30 {
  border-inline-start-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-s-orange-950\/35 {
  border-inline-start-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-s-orange-950\/40 {
  border-inline-start-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-s-orange-950\/45 {
  border-inline-start-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-s-orange-950\/5 {
  border-inline-start-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-s-orange-950\/50 {
  border-inline-start-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-s-orange-950\/55 {
  border-inline-start-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-s-orange-950\/60 {
  border-inline-start-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-s-orange-950\/65 {
  border-inline-start-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-s-orange-950\/70 {
  border-inline-start-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-s-orange-950\/75 {
  border-inline-start-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-s-orange-950\/80 {
  border-inline-start-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-s-orange-950\/85 {
  border-inline-start-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-s-orange-950\/90 {
  border-inline-start-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-s-orange-950\/95 {
  border-inline-start-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-s-pink-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-100\/0 {
  border-inline-start-color: rgb(252 231 243 / 0) !important;
}

.tw-border-s-pink-100\/10 {
  border-inline-start-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-s-pink-100\/100 {
  border-inline-start-color: rgb(252 231 243 / 1) !important;
}

.tw-border-s-pink-100\/15 {
  border-inline-start-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-s-pink-100\/20 {
  border-inline-start-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-s-pink-100\/25 {
  border-inline-start-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-s-pink-100\/30 {
  border-inline-start-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-s-pink-100\/35 {
  border-inline-start-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-s-pink-100\/40 {
  border-inline-start-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-s-pink-100\/45 {
  border-inline-start-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-s-pink-100\/5 {
  border-inline-start-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-s-pink-100\/50 {
  border-inline-start-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-s-pink-100\/55 {
  border-inline-start-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-s-pink-100\/60 {
  border-inline-start-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-s-pink-100\/65 {
  border-inline-start-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-s-pink-100\/70 {
  border-inline-start-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-s-pink-100\/75 {
  border-inline-start-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-s-pink-100\/80 {
  border-inline-start-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-s-pink-100\/85 {
  border-inline-start-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-s-pink-100\/90 {
  border-inline-start-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-s-pink-100\/95 {
  border-inline-start-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-s-pink-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-200\/0 {
  border-inline-start-color: rgb(251 207 232 / 0) !important;
}

.tw-border-s-pink-200\/10 {
  border-inline-start-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-s-pink-200\/100 {
  border-inline-start-color: rgb(251 207 232 / 1) !important;
}

.tw-border-s-pink-200\/15 {
  border-inline-start-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-s-pink-200\/20 {
  border-inline-start-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-s-pink-200\/25 {
  border-inline-start-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-s-pink-200\/30 {
  border-inline-start-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-s-pink-200\/35 {
  border-inline-start-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-s-pink-200\/40 {
  border-inline-start-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-s-pink-200\/45 {
  border-inline-start-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-s-pink-200\/5 {
  border-inline-start-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-s-pink-200\/50 {
  border-inline-start-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-s-pink-200\/55 {
  border-inline-start-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-s-pink-200\/60 {
  border-inline-start-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-s-pink-200\/65 {
  border-inline-start-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-s-pink-200\/70 {
  border-inline-start-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-s-pink-200\/75 {
  border-inline-start-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-s-pink-200\/80 {
  border-inline-start-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-s-pink-200\/85 {
  border-inline-start-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-s-pink-200\/90 {
  border-inline-start-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-s-pink-200\/95 {
  border-inline-start-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-s-pink-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-300\/0 {
  border-inline-start-color: rgb(249 168 212 / 0) !important;
}

.tw-border-s-pink-300\/10 {
  border-inline-start-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-s-pink-300\/100 {
  border-inline-start-color: rgb(249 168 212 / 1) !important;
}

.tw-border-s-pink-300\/15 {
  border-inline-start-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-s-pink-300\/20 {
  border-inline-start-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-s-pink-300\/25 {
  border-inline-start-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-s-pink-300\/30 {
  border-inline-start-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-s-pink-300\/35 {
  border-inline-start-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-s-pink-300\/40 {
  border-inline-start-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-s-pink-300\/45 {
  border-inline-start-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-s-pink-300\/5 {
  border-inline-start-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-s-pink-300\/50 {
  border-inline-start-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-s-pink-300\/55 {
  border-inline-start-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-s-pink-300\/60 {
  border-inline-start-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-s-pink-300\/65 {
  border-inline-start-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-s-pink-300\/70 {
  border-inline-start-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-s-pink-300\/75 {
  border-inline-start-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-s-pink-300\/80 {
  border-inline-start-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-s-pink-300\/85 {
  border-inline-start-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-s-pink-300\/90 {
  border-inline-start-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-s-pink-300\/95 {
  border-inline-start-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-s-pink-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-400\/0 {
  border-inline-start-color: rgb(244 114 182 / 0) !important;
}

.tw-border-s-pink-400\/10 {
  border-inline-start-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-s-pink-400\/100 {
  border-inline-start-color: rgb(244 114 182 / 1) !important;
}

.tw-border-s-pink-400\/15 {
  border-inline-start-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-s-pink-400\/20 {
  border-inline-start-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-s-pink-400\/25 {
  border-inline-start-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-s-pink-400\/30 {
  border-inline-start-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-s-pink-400\/35 {
  border-inline-start-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-s-pink-400\/40 {
  border-inline-start-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-s-pink-400\/45 {
  border-inline-start-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-s-pink-400\/5 {
  border-inline-start-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-s-pink-400\/50 {
  border-inline-start-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-s-pink-400\/55 {
  border-inline-start-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-s-pink-400\/60 {
  border-inline-start-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-s-pink-400\/65 {
  border-inline-start-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-s-pink-400\/70 {
  border-inline-start-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-s-pink-400\/75 {
  border-inline-start-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-s-pink-400\/80 {
  border-inline-start-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-s-pink-400\/85 {
  border-inline-start-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-s-pink-400\/90 {
  border-inline-start-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-s-pink-400\/95 {
  border-inline-start-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-s-pink-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-50\/0 {
  border-inline-start-color: rgb(253 242 248 / 0) !important;
}

.tw-border-s-pink-50\/10 {
  border-inline-start-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-s-pink-50\/100 {
  border-inline-start-color: rgb(253 242 248 / 1) !important;
}

.tw-border-s-pink-50\/15 {
  border-inline-start-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-s-pink-50\/20 {
  border-inline-start-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-s-pink-50\/25 {
  border-inline-start-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-s-pink-50\/30 {
  border-inline-start-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-s-pink-50\/35 {
  border-inline-start-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-s-pink-50\/40 {
  border-inline-start-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-s-pink-50\/45 {
  border-inline-start-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-s-pink-50\/5 {
  border-inline-start-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-s-pink-50\/50 {
  border-inline-start-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-s-pink-50\/55 {
  border-inline-start-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-s-pink-50\/60 {
  border-inline-start-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-s-pink-50\/65 {
  border-inline-start-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-s-pink-50\/70 {
  border-inline-start-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-s-pink-50\/75 {
  border-inline-start-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-s-pink-50\/80 {
  border-inline-start-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-s-pink-50\/85 {
  border-inline-start-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-s-pink-50\/90 {
  border-inline-start-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-s-pink-50\/95 {
  border-inline-start-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-s-pink-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-500\/0 {
  border-inline-start-color: rgb(236 72 153 / 0) !important;
}

.tw-border-s-pink-500\/10 {
  border-inline-start-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-s-pink-500\/100 {
  border-inline-start-color: rgb(236 72 153 / 1) !important;
}

.tw-border-s-pink-500\/15 {
  border-inline-start-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-s-pink-500\/20 {
  border-inline-start-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-s-pink-500\/25 {
  border-inline-start-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-s-pink-500\/30 {
  border-inline-start-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-s-pink-500\/35 {
  border-inline-start-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-s-pink-500\/40 {
  border-inline-start-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-s-pink-500\/45 {
  border-inline-start-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-s-pink-500\/5 {
  border-inline-start-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-s-pink-500\/50 {
  border-inline-start-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-s-pink-500\/55 {
  border-inline-start-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-s-pink-500\/60 {
  border-inline-start-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-s-pink-500\/65 {
  border-inline-start-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-s-pink-500\/70 {
  border-inline-start-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-s-pink-500\/75 {
  border-inline-start-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-s-pink-500\/80 {
  border-inline-start-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-s-pink-500\/85 {
  border-inline-start-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-s-pink-500\/90 {
  border-inline-start-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-s-pink-500\/95 {
  border-inline-start-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-s-pink-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-600\/0 {
  border-inline-start-color: rgb(219 39 119 / 0) !important;
}

.tw-border-s-pink-600\/10 {
  border-inline-start-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-s-pink-600\/100 {
  border-inline-start-color: rgb(219 39 119 / 1) !important;
}

.tw-border-s-pink-600\/15 {
  border-inline-start-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-s-pink-600\/20 {
  border-inline-start-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-s-pink-600\/25 {
  border-inline-start-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-s-pink-600\/30 {
  border-inline-start-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-s-pink-600\/35 {
  border-inline-start-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-s-pink-600\/40 {
  border-inline-start-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-s-pink-600\/45 {
  border-inline-start-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-s-pink-600\/5 {
  border-inline-start-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-s-pink-600\/50 {
  border-inline-start-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-s-pink-600\/55 {
  border-inline-start-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-s-pink-600\/60 {
  border-inline-start-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-s-pink-600\/65 {
  border-inline-start-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-s-pink-600\/70 {
  border-inline-start-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-s-pink-600\/75 {
  border-inline-start-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-s-pink-600\/80 {
  border-inline-start-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-s-pink-600\/85 {
  border-inline-start-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-s-pink-600\/90 {
  border-inline-start-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-s-pink-600\/95 {
  border-inline-start-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-s-pink-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-700\/0 {
  border-inline-start-color: rgb(190 24 93 / 0) !important;
}

.tw-border-s-pink-700\/10 {
  border-inline-start-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-s-pink-700\/100 {
  border-inline-start-color: rgb(190 24 93 / 1) !important;
}

.tw-border-s-pink-700\/15 {
  border-inline-start-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-s-pink-700\/20 {
  border-inline-start-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-s-pink-700\/25 {
  border-inline-start-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-s-pink-700\/30 {
  border-inline-start-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-s-pink-700\/35 {
  border-inline-start-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-s-pink-700\/40 {
  border-inline-start-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-s-pink-700\/45 {
  border-inline-start-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-s-pink-700\/5 {
  border-inline-start-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-s-pink-700\/50 {
  border-inline-start-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-s-pink-700\/55 {
  border-inline-start-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-s-pink-700\/60 {
  border-inline-start-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-s-pink-700\/65 {
  border-inline-start-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-s-pink-700\/70 {
  border-inline-start-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-s-pink-700\/75 {
  border-inline-start-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-s-pink-700\/80 {
  border-inline-start-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-s-pink-700\/85 {
  border-inline-start-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-s-pink-700\/90 {
  border-inline-start-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-s-pink-700\/95 {
  border-inline-start-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-s-pink-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-800\/0 {
  border-inline-start-color: rgb(157 23 77 / 0) !important;
}

.tw-border-s-pink-800\/10 {
  border-inline-start-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-s-pink-800\/100 {
  border-inline-start-color: rgb(157 23 77 / 1) !important;
}

.tw-border-s-pink-800\/15 {
  border-inline-start-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-s-pink-800\/20 {
  border-inline-start-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-s-pink-800\/25 {
  border-inline-start-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-s-pink-800\/30 {
  border-inline-start-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-s-pink-800\/35 {
  border-inline-start-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-s-pink-800\/40 {
  border-inline-start-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-s-pink-800\/45 {
  border-inline-start-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-s-pink-800\/5 {
  border-inline-start-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-s-pink-800\/50 {
  border-inline-start-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-s-pink-800\/55 {
  border-inline-start-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-s-pink-800\/60 {
  border-inline-start-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-s-pink-800\/65 {
  border-inline-start-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-s-pink-800\/70 {
  border-inline-start-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-s-pink-800\/75 {
  border-inline-start-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-s-pink-800\/80 {
  border-inline-start-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-s-pink-800\/85 {
  border-inline-start-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-s-pink-800\/90 {
  border-inline-start-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-s-pink-800\/95 {
  border-inline-start-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-s-pink-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-900\/0 {
  border-inline-start-color: rgb(131 24 67 / 0) !important;
}

.tw-border-s-pink-900\/10 {
  border-inline-start-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-s-pink-900\/100 {
  border-inline-start-color: rgb(131 24 67 / 1) !important;
}

.tw-border-s-pink-900\/15 {
  border-inline-start-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-s-pink-900\/20 {
  border-inline-start-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-s-pink-900\/25 {
  border-inline-start-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-s-pink-900\/30 {
  border-inline-start-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-s-pink-900\/35 {
  border-inline-start-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-s-pink-900\/40 {
  border-inline-start-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-s-pink-900\/45 {
  border-inline-start-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-s-pink-900\/5 {
  border-inline-start-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-s-pink-900\/50 {
  border-inline-start-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-s-pink-900\/55 {
  border-inline-start-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-s-pink-900\/60 {
  border-inline-start-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-s-pink-900\/65 {
  border-inline-start-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-s-pink-900\/70 {
  border-inline-start-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-s-pink-900\/75 {
  border-inline-start-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-s-pink-900\/80 {
  border-inline-start-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-s-pink-900\/85 {
  border-inline-start-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-s-pink-900\/90 {
  border-inline-start-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-s-pink-900\/95 {
  border-inline-start-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-s-pink-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-s-pink-950\/0 {
  border-inline-start-color: rgb(80 7 36 / 0) !important;
}

.tw-border-s-pink-950\/10 {
  border-inline-start-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-s-pink-950\/100 {
  border-inline-start-color: rgb(80 7 36 / 1) !important;
}

.tw-border-s-pink-950\/15 {
  border-inline-start-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-s-pink-950\/20 {
  border-inline-start-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-s-pink-950\/25 {
  border-inline-start-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-s-pink-950\/30 {
  border-inline-start-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-s-pink-950\/35 {
  border-inline-start-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-s-pink-950\/40 {
  border-inline-start-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-s-pink-950\/45 {
  border-inline-start-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-s-pink-950\/5 {
  border-inline-start-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-s-pink-950\/50 {
  border-inline-start-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-s-pink-950\/55 {
  border-inline-start-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-s-pink-950\/60 {
  border-inline-start-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-s-pink-950\/65 {
  border-inline-start-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-s-pink-950\/70 {
  border-inline-start-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-s-pink-950\/75 {
  border-inline-start-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-s-pink-950\/80 {
  border-inline-start-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-s-pink-950\/85 {
  border-inline-start-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-s-pink-950\/90 {
  border-inline-start-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-s-pink-950\/95 {
  border-inline-start-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-s-purple-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-100\/0 {
  border-inline-start-color: rgb(243 232 255 / 0) !important;
}

.tw-border-s-purple-100\/10 {
  border-inline-start-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-s-purple-100\/100 {
  border-inline-start-color: rgb(243 232 255 / 1) !important;
}

.tw-border-s-purple-100\/15 {
  border-inline-start-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-s-purple-100\/20 {
  border-inline-start-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-s-purple-100\/25 {
  border-inline-start-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-s-purple-100\/30 {
  border-inline-start-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-s-purple-100\/35 {
  border-inline-start-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-s-purple-100\/40 {
  border-inline-start-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-s-purple-100\/45 {
  border-inline-start-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-s-purple-100\/5 {
  border-inline-start-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-s-purple-100\/50 {
  border-inline-start-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-s-purple-100\/55 {
  border-inline-start-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-s-purple-100\/60 {
  border-inline-start-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-s-purple-100\/65 {
  border-inline-start-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-s-purple-100\/70 {
  border-inline-start-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-s-purple-100\/75 {
  border-inline-start-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-s-purple-100\/80 {
  border-inline-start-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-s-purple-100\/85 {
  border-inline-start-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-s-purple-100\/90 {
  border-inline-start-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-s-purple-100\/95 {
  border-inline-start-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-s-purple-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-200\/0 {
  border-inline-start-color: rgb(233 213 255 / 0) !important;
}

.tw-border-s-purple-200\/10 {
  border-inline-start-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-s-purple-200\/100 {
  border-inline-start-color: rgb(233 213 255 / 1) !important;
}

.tw-border-s-purple-200\/15 {
  border-inline-start-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-s-purple-200\/20 {
  border-inline-start-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-s-purple-200\/25 {
  border-inline-start-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-s-purple-200\/30 {
  border-inline-start-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-s-purple-200\/35 {
  border-inline-start-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-s-purple-200\/40 {
  border-inline-start-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-s-purple-200\/45 {
  border-inline-start-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-s-purple-200\/5 {
  border-inline-start-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-s-purple-200\/50 {
  border-inline-start-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-s-purple-200\/55 {
  border-inline-start-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-s-purple-200\/60 {
  border-inline-start-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-s-purple-200\/65 {
  border-inline-start-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-s-purple-200\/70 {
  border-inline-start-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-s-purple-200\/75 {
  border-inline-start-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-s-purple-200\/80 {
  border-inline-start-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-s-purple-200\/85 {
  border-inline-start-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-s-purple-200\/90 {
  border-inline-start-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-s-purple-200\/95 {
  border-inline-start-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-s-purple-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-300\/0 {
  border-inline-start-color: rgb(216 180 254 / 0) !important;
}

.tw-border-s-purple-300\/10 {
  border-inline-start-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-s-purple-300\/100 {
  border-inline-start-color: rgb(216 180 254 / 1) !important;
}

.tw-border-s-purple-300\/15 {
  border-inline-start-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-s-purple-300\/20 {
  border-inline-start-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-s-purple-300\/25 {
  border-inline-start-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-s-purple-300\/30 {
  border-inline-start-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-s-purple-300\/35 {
  border-inline-start-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-s-purple-300\/40 {
  border-inline-start-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-s-purple-300\/45 {
  border-inline-start-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-s-purple-300\/5 {
  border-inline-start-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-s-purple-300\/50 {
  border-inline-start-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-s-purple-300\/55 {
  border-inline-start-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-s-purple-300\/60 {
  border-inline-start-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-s-purple-300\/65 {
  border-inline-start-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-s-purple-300\/70 {
  border-inline-start-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-s-purple-300\/75 {
  border-inline-start-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-s-purple-300\/80 {
  border-inline-start-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-s-purple-300\/85 {
  border-inline-start-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-s-purple-300\/90 {
  border-inline-start-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-s-purple-300\/95 {
  border-inline-start-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-s-purple-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-400\/0 {
  border-inline-start-color: rgb(192 132 252 / 0) !important;
}

.tw-border-s-purple-400\/10 {
  border-inline-start-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-s-purple-400\/100 {
  border-inline-start-color: rgb(192 132 252 / 1) !important;
}

.tw-border-s-purple-400\/15 {
  border-inline-start-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-s-purple-400\/20 {
  border-inline-start-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-s-purple-400\/25 {
  border-inline-start-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-s-purple-400\/30 {
  border-inline-start-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-s-purple-400\/35 {
  border-inline-start-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-s-purple-400\/40 {
  border-inline-start-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-s-purple-400\/45 {
  border-inline-start-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-s-purple-400\/5 {
  border-inline-start-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-s-purple-400\/50 {
  border-inline-start-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-s-purple-400\/55 {
  border-inline-start-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-s-purple-400\/60 {
  border-inline-start-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-s-purple-400\/65 {
  border-inline-start-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-s-purple-400\/70 {
  border-inline-start-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-s-purple-400\/75 {
  border-inline-start-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-s-purple-400\/80 {
  border-inline-start-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-s-purple-400\/85 {
  border-inline-start-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-s-purple-400\/90 {
  border-inline-start-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-s-purple-400\/95 {
  border-inline-start-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-s-purple-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-50\/0 {
  border-inline-start-color: rgb(250 245 255 / 0) !important;
}

.tw-border-s-purple-50\/10 {
  border-inline-start-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-s-purple-50\/100 {
  border-inline-start-color: rgb(250 245 255 / 1) !important;
}

.tw-border-s-purple-50\/15 {
  border-inline-start-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-s-purple-50\/20 {
  border-inline-start-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-s-purple-50\/25 {
  border-inline-start-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-s-purple-50\/30 {
  border-inline-start-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-s-purple-50\/35 {
  border-inline-start-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-s-purple-50\/40 {
  border-inline-start-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-s-purple-50\/45 {
  border-inline-start-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-s-purple-50\/5 {
  border-inline-start-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-s-purple-50\/50 {
  border-inline-start-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-s-purple-50\/55 {
  border-inline-start-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-s-purple-50\/60 {
  border-inline-start-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-s-purple-50\/65 {
  border-inline-start-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-s-purple-50\/70 {
  border-inline-start-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-s-purple-50\/75 {
  border-inline-start-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-s-purple-50\/80 {
  border-inline-start-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-s-purple-50\/85 {
  border-inline-start-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-s-purple-50\/90 {
  border-inline-start-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-s-purple-50\/95 {
  border-inline-start-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-s-purple-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-500\/0 {
  border-inline-start-color: rgb(168 85 247 / 0) !important;
}

.tw-border-s-purple-500\/10 {
  border-inline-start-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-s-purple-500\/100 {
  border-inline-start-color: rgb(168 85 247 / 1) !important;
}

.tw-border-s-purple-500\/15 {
  border-inline-start-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-s-purple-500\/20 {
  border-inline-start-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-s-purple-500\/25 {
  border-inline-start-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-s-purple-500\/30 {
  border-inline-start-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-s-purple-500\/35 {
  border-inline-start-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-s-purple-500\/40 {
  border-inline-start-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-s-purple-500\/45 {
  border-inline-start-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-s-purple-500\/5 {
  border-inline-start-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-s-purple-500\/50 {
  border-inline-start-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-s-purple-500\/55 {
  border-inline-start-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-s-purple-500\/60 {
  border-inline-start-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-s-purple-500\/65 {
  border-inline-start-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-s-purple-500\/70 {
  border-inline-start-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-s-purple-500\/75 {
  border-inline-start-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-s-purple-500\/80 {
  border-inline-start-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-s-purple-500\/85 {
  border-inline-start-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-s-purple-500\/90 {
  border-inline-start-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-s-purple-500\/95 {
  border-inline-start-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-s-purple-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-600\/0 {
  border-inline-start-color: rgb(147 51 234 / 0) !important;
}

.tw-border-s-purple-600\/10 {
  border-inline-start-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-s-purple-600\/100 {
  border-inline-start-color: rgb(147 51 234 / 1) !important;
}

.tw-border-s-purple-600\/15 {
  border-inline-start-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-s-purple-600\/20 {
  border-inline-start-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-s-purple-600\/25 {
  border-inline-start-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-s-purple-600\/30 {
  border-inline-start-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-s-purple-600\/35 {
  border-inline-start-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-s-purple-600\/40 {
  border-inline-start-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-s-purple-600\/45 {
  border-inline-start-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-s-purple-600\/5 {
  border-inline-start-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-s-purple-600\/50 {
  border-inline-start-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-s-purple-600\/55 {
  border-inline-start-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-s-purple-600\/60 {
  border-inline-start-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-s-purple-600\/65 {
  border-inline-start-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-s-purple-600\/70 {
  border-inline-start-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-s-purple-600\/75 {
  border-inline-start-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-s-purple-600\/80 {
  border-inline-start-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-s-purple-600\/85 {
  border-inline-start-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-s-purple-600\/90 {
  border-inline-start-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-s-purple-600\/95 {
  border-inline-start-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-s-purple-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-700\/0 {
  border-inline-start-color: rgb(126 34 206 / 0) !important;
}

.tw-border-s-purple-700\/10 {
  border-inline-start-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-s-purple-700\/100 {
  border-inline-start-color: rgb(126 34 206 / 1) !important;
}

.tw-border-s-purple-700\/15 {
  border-inline-start-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-s-purple-700\/20 {
  border-inline-start-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-s-purple-700\/25 {
  border-inline-start-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-s-purple-700\/30 {
  border-inline-start-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-s-purple-700\/35 {
  border-inline-start-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-s-purple-700\/40 {
  border-inline-start-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-s-purple-700\/45 {
  border-inline-start-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-s-purple-700\/5 {
  border-inline-start-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-s-purple-700\/50 {
  border-inline-start-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-s-purple-700\/55 {
  border-inline-start-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-s-purple-700\/60 {
  border-inline-start-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-s-purple-700\/65 {
  border-inline-start-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-s-purple-700\/70 {
  border-inline-start-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-s-purple-700\/75 {
  border-inline-start-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-s-purple-700\/80 {
  border-inline-start-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-s-purple-700\/85 {
  border-inline-start-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-s-purple-700\/90 {
  border-inline-start-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-s-purple-700\/95 {
  border-inline-start-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-s-purple-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-800\/0 {
  border-inline-start-color: rgb(107 33 168 / 0) !important;
}

.tw-border-s-purple-800\/10 {
  border-inline-start-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-s-purple-800\/100 {
  border-inline-start-color: rgb(107 33 168 / 1) !important;
}

.tw-border-s-purple-800\/15 {
  border-inline-start-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-s-purple-800\/20 {
  border-inline-start-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-s-purple-800\/25 {
  border-inline-start-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-s-purple-800\/30 {
  border-inline-start-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-s-purple-800\/35 {
  border-inline-start-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-s-purple-800\/40 {
  border-inline-start-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-s-purple-800\/45 {
  border-inline-start-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-s-purple-800\/5 {
  border-inline-start-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-s-purple-800\/50 {
  border-inline-start-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-s-purple-800\/55 {
  border-inline-start-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-s-purple-800\/60 {
  border-inline-start-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-s-purple-800\/65 {
  border-inline-start-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-s-purple-800\/70 {
  border-inline-start-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-s-purple-800\/75 {
  border-inline-start-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-s-purple-800\/80 {
  border-inline-start-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-s-purple-800\/85 {
  border-inline-start-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-s-purple-800\/90 {
  border-inline-start-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-s-purple-800\/95 {
  border-inline-start-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-s-purple-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-900\/0 {
  border-inline-start-color: rgb(88 28 135 / 0) !important;
}

.tw-border-s-purple-900\/10 {
  border-inline-start-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-s-purple-900\/100 {
  border-inline-start-color: rgb(88 28 135 / 1) !important;
}

.tw-border-s-purple-900\/15 {
  border-inline-start-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-s-purple-900\/20 {
  border-inline-start-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-s-purple-900\/25 {
  border-inline-start-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-s-purple-900\/30 {
  border-inline-start-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-s-purple-900\/35 {
  border-inline-start-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-s-purple-900\/40 {
  border-inline-start-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-s-purple-900\/45 {
  border-inline-start-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-s-purple-900\/5 {
  border-inline-start-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-s-purple-900\/50 {
  border-inline-start-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-s-purple-900\/55 {
  border-inline-start-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-s-purple-900\/60 {
  border-inline-start-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-s-purple-900\/65 {
  border-inline-start-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-s-purple-900\/70 {
  border-inline-start-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-s-purple-900\/75 {
  border-inline-start-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-s-purple-900\/80 {
  border-inline-start-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-s-purple-900\/85 {
  border-inline-start-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-s-purple-900\/90 {
  border-inline-start-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-s-purple-900\/95 {
  border-inline-start-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-s-purple-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-s-purple-950\/0 {
  border-inline-start-color: rgb(59 7 100 / 0) !important;
}

.tw-border-s-purple-950\/10 {
  border-inline-start-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-s-purple-950\/100 {
  border-inline-start-color: rgb(59 7 100 / 1) !important;
}

.tw-border-s-purple-950\/15 {
  border-inline-start-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-s-purple-950\/20 {
  border-inline-start-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-s-purple-950\/25 {
  border-inline-start-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-s-purple-950\/30 {
  border-inline-start-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-s-purple-950\/35 {
  border-inline-start-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-s-purple-950\/40 {
  border-inline-start-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-s-purple-950\/45 {
  border-inline-start-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-s-purple-950\/5 {
  border-inline-start-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-s-purple-950\/50 {
  border-inline-start-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-s-purple-950\/55 {
  border-inline-start-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-s-purple-950\/60 {
  border-inline-start-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-s-purple-950\/65 {
  border-inline-start-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-s-purple-950\/70 {
  border-inline-start-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-s-purple-950\/75 {
  border-inline-start-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-s-purple-950\/80 {
  border-inline-start-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-s-purple-950\/85 {
  border-inline-start-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-s-purple-950\/90 {
  border-inline-start-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-s-purple-950\/95 {
  border-inline-start-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-s-red-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-100\/0 {
  border-inline-start-color: rgb(254 226 226 / 0) !important;
}

.tw-border-s-red-100\/10 {
  border-inline-start-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-s-red-100\/100 {
  border-inline-start-color: rgb(254 226 226 / 1) !important;
}

.tw-border-s-red-100\/15 {
  border-inline-start-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-s-red-100\/20 {
  border-inline-start-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-s-red-100\/25 {
  border-inline-start-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-s-red-100\/30 {
  border-inline-start-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-s-red-100\/35 {
  border-inline-start-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-s-red-100\/40 {
  border-inline-start-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-s-red-100\/45 {
  border-inline-start-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-s-red-100\/5 {
  border-inline-start-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-s-red-100\/50 {
  border-inline-start-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-s-red-100\/55 {
  border-inline-start-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-s-red-100\/60 {
  border-inline-start-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-s-red-100\/65 {
  border-inline-start-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-s-red-100\/70 {
  border-inline-start-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-s-red-100\/75 {
  border-inline-start-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-s-red-100\/80 {
  border-inline-start-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-s-red-100\/85 {
  border-inline-start-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-s-red-100\/90 {
  border-inline-start-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-s-red-100\/95 {
  border-inline-start-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-s-red-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-200\/0 {
  border-inline-start-color: rgb(254 202 202 / 0) !important;
}

.tw-border-s-red-200\/10 {
  border-inline-start-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-s-red-200\/100 {
  border-inline-start-color: rgb(254 202 202 / 1) !important;
}

.tw-border-s-red-200\/15 {
  border-inline-start-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-s-red-200\/20 {
  border-inline-start-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-s-red-200\/25 {
  border-inline-start-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-s-red-200\/30 {
  border-inline-start-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-s-red-200\/35 {
  border-inline-start-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-s-red-200\/40 {
  border-inline-start-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-s-red-200\/45 {
  border-inline-start-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-s-red-200\/5 {
  border-inline-start-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-s-red-200\/50 {
  border-inline-start-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-s-red-200\/55 {
  border-inline-start-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-s-red-200\/60 {
  border-inline-start-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-s-red-200\/65 {
  border-inline-start-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-s-red-200\/70 {
  border-inline-start-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-s-red-200\/75 {
  border-inline-start-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-s-red-200\/80 {
  border-inline-start-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-s-red-200\/85 {
  border-inline-start-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-s-red-200\/90 {
  border-inline-start-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-s-red-200\/95 {
  border-inline-start-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-s-red-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-300\/0 {
  border-inline-start-color: rgb(252 165 165 / 0) !important;
}

.tw-border-s-red-300\/10 {
  border-inline-start-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-s-red-300\/100 {
  border-inline-start-color: rgb(252 165 165 / 1) !important;
}

.tw-border-s-red-300\/15 {
  border-inline-start-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-s-red-300\/20 {
  border-inline-start-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-s-red-300\/25 {
  border-inline-start-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-s-red-300\/30 {
  border-inline-start-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-s-red-300\/35 {
  border-inline-start-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-s-red-300\/40 {
  border-inline-start-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-s-red-300\/45 {
  border-inline-start-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-s-red-300\/5 {
  border-inline-start-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-s-red-300\/50 {
  border-inline-start-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-s-red-300\/55 {
  border-inline-start-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-s-red-300\/60 {
  border-inline-start-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-s-red-300\/65 {
  border-inline-start-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-s-red-300\/70 {
  border-inline-start-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-s-red-300\/75 {
  border-inline-start-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-s-red-300\/80 {
  border-inline-start-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-s-red-300\/85 {
  border-inline-start-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-s-red-300\/90 {
  border-inline-start-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-s-red-300\/95 {
  border-inline-start-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-s-red-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-400\/0 {
  border-inline-start-color: rgb(248 113 113 / 0) !important;
}

.tw-border-s-red-400\/10 {
  border-inline-start-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-s-red-400\/100 {
  border-inline-start-color: rgb(248 113 113 / 1) !important;
}

.tw-border-s-red-400\/15 {
  border-inline-start-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-s-red-400\/20 {
  border-inline-start-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-s-red-400\/25 {
  border-inline-start-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-s-red-400\/30 {
  border-inline-start-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-s-red-400\/35 {
  border-inline-start-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-s-red-400\/40 {
  border-inline-start-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-s-red-400\/45 {
  border-inline-start-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-s-red-400\/5 {
  border-inline-start-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-s-red-400\/50 {
  border-inline-start-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-s-red-400\/55 {
  border-inline-start-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-s-red-400\/60 {
  border-inline-start-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-s-red-400\/65 {
  border-inline-start-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-s-red-400\/70 {
  border-inline-start-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-s-red-400\/75 {
  border-inline-start-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-s-red-400\/80 {
  border-inline-start-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-s-red-400\/85 {
  border-inline-start-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-s-red-400\/90 {
  border-inline-start-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-s-red-400\/95 {
  border-inline-start-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-s-red-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-50\/0 {
  border-inline-start-color: rgb(254 242 242 / 0) !important;
}

.tw-border-s-red-50\/10 {
  border-inline-start-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-s-red-50\/100 {
  border-inline-start-color: rgb(254 242 242 / 1) !important;
}

.tw-border-s-red-50\/15 {
  border-inline-start-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-s-red-50\/20 {
  border-inline-start-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-s-red-50\/25 {
  border-inline-start-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-s-red-50\/30 {
  border-inline-start-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-s-red-50\/35 {
  border-inline-start-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-s-red-50\/40 {
  border-inline-start-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-s-red-50\/45 {
  border-inline-start-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-s-red-50\/5 {
  border-inline-start-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-s-red-50\/50 {
  border-inline-start-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-s-red-50\/55 {
  border-inline-start-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-s-red-50\/60 {
  border-inline-start-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-s-red-50\/65 {
  border-inline-start-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-s-red-50\/70 {
  border-inline-start-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-s-red-50\/75 {
  border-inline-start-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-s-red-50\/80 {
  border-inline-start-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-s-red-50\/85 {
  border-inline-start-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-s-red-50\/90 {
  border-inline-start-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-s-red-50\/95 {
  border-inline-start-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-s-red-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-500\/0 {
  border-inline-start-color: rgb(239 68 68 / 0) !important;
}

.tw-border-s-red-500\/10 {
  border-inline-start-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-s-red-500\/100 {
  border-inline-start-color: rgb(239 68 68 / 1) !important;
}

.tw-border-s-red-500\/15 {
  border-inline-start-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-s-red-500\/20 {
  border-inline-start-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-s-red-500\/25 {
  border-inline-start-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-s-red-500\/30 {
  border-inline-start-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-s-red-500\/35 {
  border-inline-start-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-s-red-500\/40 {
  border-inline-start-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-s-red-500\/45 {
  border-inline-start-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-s-red-500\/5 {
  border-inline-start-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-s-red-500\/50 {
  border-inline-start-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-s-red-500\/55 {
  border-inline-start-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-s-red-500\/60 {
  border-inline-start-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-s-red-500\/65 {
  border-inline-start-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-s-red-500\/70 {
  border-inline-start-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-s-red-500\/75 {
  border-inline-start-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-s-red-500\/80 {
  border-inline-start-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-s-red-500\/85 {
  border-inline-start-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-s-red-500\/90 {
  border-inline-start-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-s-red-500\/95 {
  border-inline-start-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-s-red-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-600\/0 {
  border-inline-start-color: rgb(220 38 38 / 0) !important;
}

.tw-border-s-red-600\/10 {
  border-inline-start-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-s-red-600\/100 {
  border-inline-start-color: rgb(220 38 38 / 1) !important;
}

.tw-border-s-red-600\/15 {
  border-inline-start-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-s-red-600\/20 {
  border-inline-start-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-s-red-600\/25 {
  border-inline-start-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-s-red-600\/30 {
  border-inline-start-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-s-red-600\/35 {
  border-inline-start-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-s-red-600\/40 {
  border-inline-start-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-s-red-600\/45 {
  border-inline-start-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-s-red-600\/5 {
  border-inline-start-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-s-red-600\/50 {
  border-inline-start-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-s-red-600\/55 {
  border-inline-start-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-s-red-600\/60 {
  border-inline-start-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-s-red-600\/65 {
  border-inline-start-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-s-red-600\/70 {
  border-inline-start-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-s-red-600\/75 {
  border-inline-start-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-s-red-600\/80 {
  border-inline-start-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-s-red-600\/85 {
  border-inline-start-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-s-red-600\/90 {
  border-inline-start-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-s-red-600\/95 {
  border-inline-start-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-s-red-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-700\/0 {
  border-inline-start-color: rgb(185 28 28 / 0) !important;
}

.tw-border-s-red-700\/10 {
  border-inline-start-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-s-red-700\/100 {
  border-inline-start-color: rgb(185 28 28 / 1) !important;
}

.tw-border-s-red-700\/15 {
  border-inline-start-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-s-red-700\/20 {
  border-inline-start-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-s-red-700\/25 {
  border-inline-start-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-s-red-700\/30 {
  border-inline-start-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-s-red-700\/35 {
  border-inline-start-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-s-red-700\/40 {
  border-inline-start-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-s-red-700\/45 {
  border-inline-start-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-s-red-700\/5 {
  border-inline-start-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-s-red-700\/50 {
  border-inline-start-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-s-red-700\/55 {
  border-inline-start-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-s-red-700\/60 {
  border-inline-start-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-s-red-700\/65 {
  border-inline-start-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-s-red-700\/70 {
  border-inline-start-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-s-red-700\/75 {
  border-inline-start-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-s-red-700\/80 {
  border-inline-start-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-s-red-700\/85 {
  border-inline-start-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-s-red-700\/90 {
  border-inline-start-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-s-red-700\/95 {
  border-inline-start-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-s-red-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-800\/0 {
  border-inline-start-color: rgb(153 27 27 / 0) !important;
}

.tw-border-s-red-800\/10 {
  border-inline-start-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-s-red-800\/100 {
  border-inline-start-color: rgb(153 27 27 / 1) !important;
}

.tw-border-s-red-800\/15 {
  border-inline-start-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-s-red-800\/20 {
  border-inline-start-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-s-red-800\/25 {
  border-inline-start-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-s-red-800\/30 {
  border-inline-start-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-s-red-800\/35 {
  border-inline-start-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-s-red-800\/40 {
  border-inline-start-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-s-red-800\/45 {
  border-inline-start-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-s-red-800\/5 {
  border-inline-start-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-s-red-800\/50 {
  border-inline-start-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-s-red-800\/55 {
  border-inline-start-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-s-red-800\/60 {
  border-inline-start-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-s-red-800\/65 {
  border-inline-start-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-s-red-800\/70 {
  border-inline-start-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-s-red-800\/75 {
  border-inline-start-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-s-red-800\/80 {
  border-inline-start-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-s-red-800\/85 {
  border-inline-start-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-s-red-800\/90 {
  border-inline-start-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-s-red-800\/95 {
  border-inline-start-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-s-red-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-900\/0 {
  border-inline-start-color: rgb(127 29 29 / 0) !important;
}

.tw-border-s-red-900\/10 {
  border-inline-start-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-s-red-900\/100 {
  border-inline-start-color: rgb(127 29 29 / 1) !important;
}

.tw-border-s-red-900\/15 {
  border-inline-start-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-s-red-900\/20 {
  border-inline-start-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-s-red-900\/25 {
  border-inline-start-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-s-red-900\/30 {
  border-inline-start-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-s-red-900\/35 {
  border-inline-start-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-s-red-900\/40 {
  border-inline-start-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-s-red-900\/45 {
  border-inline-start-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-s-red-900\/5 {
  border-inline-start-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-s-red-900\/50 {
  border-inline-start-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-s-red-900\/55 {
  border-inline-start-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-s-red-900\/60 {
  border-inline-start-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-s-red-900\/65 {
  border-inline-start-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-s-red-900\/70 {
  border-inline-start-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-s-red-900\/75 {
  border-inline-start-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-s-red-900\/80 {
  border-inline-start-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-s-red-900\/85 {
  border-inline-start-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-s-red-900\/90 {
  border-inline-start-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-s-red-900\/95 {
  border-inline-start-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-s-red-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-s-red-950\/0 {
  border-inline-start-color: rgb(69 10 10 / 0) !important;
}

.tw-border-s-red-950\/10 {
  border-inline-start-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-s-red-950\/100 {
  border-inline-start-color: rgb(69 10 10 / 1) !important;
}

.tw-border-s-red-950\/15 {
  border-inline-start-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-s-red-950\/20 {
  border-inline-start-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-s-red-950\/25 {
  border-inline-start-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-s-red-950\/30 {
  border-inline-start-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-s-red-950\/35 {
  border-inline-start-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-s-red-950\/40 {
  border-inline-start-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-s-red-950\/45 {
  border-inline-start-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-s-red-950\/5 {
  border-inline-start-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-s-red-950\/50 {
  border-inline-start-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-s-red-950\/55 {
  border-inline-start-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-s-red-950\/60 {
  border-inline-start-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-s-red-950\/65 {
  border-inline-start-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-s-red-950\/70 {
  border-inline-start-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-s-red-950\/75 {
  border-inline-start-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-s-red-950\/80 {
  border-inline-start-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-s-red-950\/85 {
  border-inline-start-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-s-red-950\/90 {
  border-inline-start-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-s-red-950\/95 {
  border-inline-start-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-s-rose-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-100\/0 {
  border-inline-start-color: rgb(255 228 230 / 0) !important;
}

.tw-border-s-rose-100\/10 {
  border-inline-start-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-s-rose-100\/100 {
  border-inline-start-color: rgb(255 228 230 / 1) !important;
}

.tw-border-s-rose-100\/15 {
  border-inline-start-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-s-rose-100\/20 {
  border-inline-start-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-s-rose-100\/25 {
  border-inline-start-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-s-rose-100\/30 {
  border-inline-start-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-s-rose-100\/35 {
  border-inline-start-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-s-rose-100\/40 {
  border-inline-start-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-s-rose-100\/45 {
  border-inline-start-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-s-rose-100\/5 {
  border-inline-start-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-s-rose-100\/50 {
  border-inline-start-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-s-rose-100\/55 {
  border-inline-start-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-s-rose-100\/60 {
  border-inline-start-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-s-rose-100\/65 {
  border-inline-start-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-s-rose-100\/70 {
  border-inline-start-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-s-rose-100\/75 {
  border-inline-start-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-s-rose-100\/80 {
  border-inline-start-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-s-rose-100\/85 {
  border-inline-start-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-s-rose-100\/90 {
  border-inline-start-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-s-rose-100\/95 {
  border-inline-start-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-s-rose-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-200\/0 {
  border-inline-start-color: rgb(254 205 211 / 0) !important;
}

.tw-border-s-rose-200\/10 {
  border-inline-start-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-s-rose-200\/100 {
  border-inline-start-color: rgb(254 205 211 / 1) !important;
}

.tw-border-s-rose-200\/15 {
  border-inline-start-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-s-rose-200\/20 {
  border-inline-start-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-s-rose-200\/25 {
  border-inline-start-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-s-rose-200\/30 {
  border-inline-start-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-s-rose-200\/35 {
  border-inline-start-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-s-rose-200\/40 {
  border-inline-start-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-s-rose-200\/45 {
  border-inline-start-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-s-rose-200\/5 {
  border-inline-start-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-s-rose-200\/50 {
  border-inline-start-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-s-rose-200\/55 {
  border-inline-start-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-s-rose-200\/60 {
  border-inline-start-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-s-rose-200\/65 {
  border-inline-start-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-s-rose-200\/70 {
  border-inline-start-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-s-rose-200\/75 {
  border-inline-start-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-s-rose-200\/80 {
  border-inline-start-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-s-rose-200\/85 {
  border-inline-start-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-s-rose-200\/90 {
  border-inline-start-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-s-rose-200\/95 {
  border-inline-start-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-s-rose-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-300\/0 {
  border-inline-start-color: rgb(253 164 175 / 0) !important;
}

.tw-border-s-rose-300\/10 {
  border-inline-start-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-s-rose-300\/100 {
  border-inline-start-color: rgb(253 164 175 / 1) !important;
}

.tw-border-s-rose-300\/15 {
  border-inline-start-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-s-rose-300\/20 {
  border-inline-start-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-s-rose-300\/25 {
  border-inline-start-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-s-rose-300\/30 {
  border-inline-start-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-s-rose-300\/35 {
  border-inline-start-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-s-rose-300\/40 {
  border-inline-start-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-s-rose-300\/45 {
  border-inline-start-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-s-rose-300\/5 {
  border-inline-start-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-s-rose-300\/50 {
  border-inline-start-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-s-rose-300\/55 {
  border-inline-start-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-s-rose-300\/60 {
  border-inline-start-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-s-rose-300\/65 {
  border-inline-start-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-s-rose-300\/70 {
  border-inline-start-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-s-rose-300\/75 {
  border-inline-start-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-s-rose-300\/80 {
  border-inline-start-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-s-rose-300\/85 {
  border-inline-start-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-s-rose-300\/90 {
  border-inline-start-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-s-rose-300\/95 {
  border-inline-start-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-s-rose-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-400\/0 {
  border-inline-start-color: rgb(251 113 133 / 0) !important;
}

.tw-border-s-rose-400\/10 {
  border-inline-start-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-s-rose-400\/100 {
  border-inline-start-color: rgb(251 113 133 / 1) !important;
}

.tw-border-s-rose-400\/15 {
  border-inline-start-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-s-rose-400\/20 {
  border-inline-start-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-s-rose-400\/25 {
  border-inline-start-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-s-rose-400\/30 {
  border-inline-start-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-s-rose-400\/35 {
  border-inline-start-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-s-rose-400\/40 {
  border-inline-start-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-s-rose-400\/45 {
  border-inline-start-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-s-rose-400\/5 {
  border-inline-start-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-s-rose-400\/50 {
  border-inline-start-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-s-rose-400\/55 {
  border-inline-start-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-s-rose-400\/60 {
  border-inline-start-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-s-rose-400\/65 {
  border-inline-start-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-s-rose-400\/70 {
  border-inline-start-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-s-rose-400\/75 {
  border-inline-start-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-s-rose-400\/80 {
  border-inline-start-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-s-rose-400\/85 {
  border-inline-start-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-s-rose-400\/90 {
  border-inline-start-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-s-rose-400\/95 {
  border-inline-start-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-s-rose-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-50\/0 {
  border-inline-start-color: rgb(255 241 242 / 0) !important;
}

.tw-border-s-rose-50\/10 {
  border-inline-start-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-s-rose-50\/100 {
  border-inline-start-color: rgb(255 241 242 / 1) !important;
}

.tw-border-s-rose-50\/15 {
  border-inline-start-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-s-rose-50\/20 {
  border-inline-start-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-s-rose-50\/25 {
  border-inline-start-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-s-rose-50\/30 {
  border-inline-start-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-s-rose-50\/35 {
  border-inline-start-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-s-rose-50\/40 {
  border-inline-start-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-s-rose-50\/45 {
  border-inline-start-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-s-rose-50\/5 {
  border-inline-start-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-s-rose-50\/50 {
  border-inline-start-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-s-rose-50\/55 {
  border-inline-start-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-s-rose-50\/60 {
  border-inline-start-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-s-rose-50\/65 {
  border-inline-start-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-s-rose-50\/70 {
  border-inline-start-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-s-rose-50\/75 {
  border-inline-start-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-s-rose-50\/80 {
  border-inline-start-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-s-rose-50\/85 {
  border-inline-start-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-s-rose-50\/90 {
  border-inline-start-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-s-rose-50\/95 {
  border-inline-start-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-s-rose-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-500\/0 {
  border-inline-start-color: rgb(244 63 94 / 0) !important;
}

.tw-border-s-rose-500\/10 {
  border-inline-start-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-s-rose-500\/100 {
  border-inline-start-color: rgb(244 63 94 / 1) !important;
}

.tw-border-s-rose-500\/15 {
  border-inline-start-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-s-rose-500\/20 {
  border-inline-start-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-s-rose-500\/25 {
  border-inline-start-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-s-rose-500\/30 {
  border-inline-start-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-s-rose-500\/35 {
  border-inline-start-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-s-rose-500\/40 {
  border-inline-start-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-s-rose-500\/45 {
  border-inline-start-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-s-rose-500\/5 {
  border-inline-start-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-s-rose-500\/50 {
  border-inline-start-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-s-rose-500\/55 {
  border-inline-start-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-s-rose-500\/60 {
  border-inline-start-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-s-rose-500\/65 {
  border-inline-start-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-s-rose-500\/70 {
  border-inline-start-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-s-rose-500\/75 {
  border-inline-start-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-s-rose-500\/80 {
  border-inline-start-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-s-rose-500\/85 {
  border-inline-start-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-s-rose-500\/90 {
  border-inline-start-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-s-rose-500\/95 {
  border-inline-start-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-s-rose-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-600\/0 {
  border-inline-start-color: rgb(225 29 72 / 0) !important;
}

.tw-border-s-rose-600\/10 {
  border-inline-start-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-s-rose-600\/100 {
  border-inline-start-color: rgb(225 29 72 / 1) !important;
}

.tw-border-s-rose-600\/15 {
  border-inline-start-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-s-rose-600\/20 {
  border-inline-start-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-s-rose-600\/25 {
  border-inline-start-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-s-rose-600\/30 {
  border-inline-start-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-s-rose-600\/35 {
  border-inline-start-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-s-rose-600\/40 {
  border-inline-start-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-s-rose-600\/45 {
  border-inline-start-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-s-rose-600\/5 {
  border-inline-start-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-s-rose-600\/50 {
  border-inline-start-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-s-rose-600\/55 {
  border-inline-start-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-s-rose-600\/60 {
  border-inline-start-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-s-rose-600\/65 {
  border-inline-start-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-s-rose-600\/70 {
  border-inline-start-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-s-rose-600\/75 {
  border-inline-start-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-s-rose-600\/80 {
  border-inline-start-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-s-rose-600\/85 {
  border-inline-start-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-s-rose-600\/90 {
  border-inline-start-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-s-rose-600\/95 {
  border-inline-start-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-s-rose-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-700\/0 {
  border-inline-start-color: rgb(190 18 60 / 0) !important;
}

.tw-border-s-rose-700\/10 {
  border-inline-start-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-s-rose-700\/100 {
  border-inline-start-color: rgb(190 18 60 / 1) !important;
}

.tw-border-s-rose-700\/15 {
  border-inline-start-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-s-rose-700\/20 {
  border-inline-start-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-s-rose-700\/25 {
  border-inline-start-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-s-rose-700\/30 {
  border-inline-start-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-s-rose-700\/35 {
  border-inline-start-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-s-rose-700\/40 {
  border-inline-start-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-s-rose-700\/45 {
  border-inline-start-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-s-rose-700\/5 {
  border-inline-start-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-s-rose-700\/50 {
  border-inline-start-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-s-rose-700\/55 {
  border-inline-start-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-s-rose-700\/60 {
  border-inline-start-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-s-rose-700\/65 {
  border-inline-start-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-s-rose-700\/70 {
  border-inline-start-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-s-rose-700\/75 {
  border-inline-start-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-s-rose-700\/80 {
  border-inline-start-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-s-rose-700\/85 {
  border-inline-start-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-s-rose-700\/90 {
  border-inline-start-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-s-rose-700\/95 {
  border-inline-start-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-s-rose-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-800\/0 {
  border-inline-start-color: rgb(159 18 57 / 0) !important;
}

.tw-border-s-rose-800\/10 {
  border-inline-start-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-s-rose-800\/100 {
  border-inline-start-color: rgb(159 18 57 / 1) !important;
}

.tw-border-s-rose-800\/15 {
  border-inline-start-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-s-rose-800\/20 {
  border-inline-start-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-s-rose-800\/25 {
  border-inline-start-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-s-rose-800\/30 {
  border-inline-start-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-s-rose-800\/35 {
  border-inline-start-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-s-rose-800\/40 {
  border-inline-start-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-s-rose-800\/45 {
  border-inline-start-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-s-rose-800\/5 {
  border-inline-start-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-s-rose-800\/50 {
  border-inline-start-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-s-rose-800\/55 {
  border-inline-start-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-s-rose-800\/60 {
  border-inline-start-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-s-rose-800\/65 {
  border-inline-start-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-s-rose-800\/70 {
  border-inline-start-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-s-rose-800\/75 {
  border-inline-start-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-s-rose-800\/80 {
  border-inline-start-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-s-rose-800\/85 {
  border-inline-start-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-s-rose-800\/90 {
  border-inline-start-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-s-rose-800\/95 {
  border-inline-start-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-s-rose-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-900\/0 {
  border-inline-start-color: rgb(136 19 55 / 0) !important;
}

.tw-border-s-rose-900\/10 {
  border-inline-start-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-s-rose-900\/100 {
  border-inline-start-color: rgb(136 19 55 / 1) !important;
}

.tw-border-s-rose-900\/15 {
  border-inline-start-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-s-rose-900\/20 {
  border-inline-start-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-s-rose-900\/25 {
  border-inline-start-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-s-rose-900\/30 {
  border-inline-start-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-s-rose-900\/35 {
  border-inline-start-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-s-rose-900\/40 {
  border-inline-start-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-s-rose-900\/45 {
  border-inline-start-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-s-rose-900\/5 {
  border-inline-start-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-s-rose-900\/50 {
  border-inline-start-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-s-rose-900\/55 {
  border-inline-start-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-s-rose-900\/60 {
  border-inline-start-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-s-rose-900\/65 {
  border-inline-start-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-s-rose-900\/70 {
  border-inline-start-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-s-rose-900\/75 {
  border-inline-start-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-s-rose-900\/80 {
  border-inline-start-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-s-rose-900\/85 {
  border-inline-start-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-s-rose-900\/90 {
  border-inline-start-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-s-rose-900\/95 {
  border-inline-start-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-s-rose-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-s-rose-950\/0 {
  border-inline-start-color: rgb(76 5 25 / 0) !important;
}

.tw-border-s-rose-950\/10 {
  border-inline-start-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-s-rose-950\/100 {
  border-inline-start-color: rgb(76 5 25 / 1) !important;
}

.tw-border-s-rose-950\/15 {
  border-inline-start-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-s-rose-950\/20 {
  border-inline-start-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-s-rose-950\/25 {
  border-inline-start-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-s-rose-950\/30 {
  border-inline-start-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-s-rose-950\/35 {
  border-inline-start-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-s-rose-950\/40 {
  border-inline-start-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-s-rose-950\/45 {
  border-inline-start-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-s-rose-950\/5 {
  border-inline-start-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-s-rose-950\/50 {
  border-inline-start-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-s-rose-950\/55 {
  border-inline-start-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-s-rose-950\/60 {
  border-inline-start-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-s-rose-950\/65 {
  border-inline-start-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-s-rose-950\/70 {
  border-inline-start-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-s-rose-950\/75 {
  border-inline-start-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-s-rose-950\/80 {
  border-inline-start-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-s-rose-950\/85 {
  border-inline-start-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-s-rose-950\/90 {
  border-inline-start-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-s-rose-950\/95 {
  border-inline-start-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-s-sky-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-100\/0 {
  border-inline-start-color: rgb(224 242 254 / 0) !important;
}

.tw-border-s-sky-100\/10 {
  border-inline-start-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-s-sky-100\/100 {
  border-inline-start-color: rgb(224 242 254 / 1) !important;
}

.tw-border-s-sky-100\/15 {
  border-inline-start-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-s-sky-100\/20 {
  border-inline-start-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-s-sky-100\/25 {
  border-inline-start-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-s-sky-100\/30 {
  border-inline-start-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-s-sky-100\/35 {
  border-inline-start-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-s-sky-100\/40 {
  border-inline-start-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-s-sky-100\/45 {
  border-inline-start-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-s-sky-100\/5 {
  border-inline-start-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-s-sky-100\/50 {
  border-inline-start-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-s-sky-100\/55 {
  border-inline-start-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-s-sky-100\/60 {
  border-inline-start-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-s-sky-100\/65 {
  border-inline-start-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-s-sky-100\/70 {
  border-inline-start-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-s-sky-100\/75 {
  border-inline-start-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-s-sky-100\/80 {
  border-inline-start-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-s-sky-100\/85 {
  border-inline-start-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-s-sky-100\/90 {
  border-inline-start-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-s-sky-100\/95 {
  border-inline-start-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-s-sky-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-200\/0 {
  border-inline-start-color: rgb(186 230 253 / 0) !important;
}

.tw-border-s-sky-200\/10 {
  border-inline-start-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-s-sky-200\/100 {
  border-inline-start-color: rgb(186 230 253 / 1) !important;
}

.tw-border-s-sky-200\/15 {
  border-inline-start-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-s-sky-200\/20 {
  border-inline-start-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-s-sky-200\/25 {
  border-inline-start-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-s-sky-200\/30 {
  border-inline-start-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-s-sky-200\/35 {
  border-inline-start-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-s-sky-200\/40 {
  border-inline-start-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-s-sky-200\/45 {
  border-inline-start-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-s-sky-200\/5 {
  border-inline-start-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-s-sky-200\/50 {
  border-inline-start-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-s-sky-200\/55 {
  border-inline-start-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-s-sky-200\/60 {
  border-inline-start-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-s-sky-200\/65 {
  border-inline-start-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-s-sky-200\/70 {
  border-inline-start-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-s-sky-200\/75 {
  border-inline-start-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-s-sky-200\/80 {
  border-inline-start-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-s-sky-200\/85 {
  border-inline-start-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-s-sky-200\/90 {
  border-inline-start-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-s-sky-200\/95 {
  border-inline-start-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-s-sky-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-300\/0 {
  border-inline-start-color: rgb(125 211 252 / 0) !important;
}

.tw-border-s-sky-300\/10 {
  border-inline-start-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-s-sky-300\/100 {
  border-inline-start-color: rgb(125 211 252 / 1) !important;
}

.tw-border-s-sky-300\/15 {
  border-inline-start-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-s-sky-300\/20 {
  border-inline-start-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-s-sky-300\/25 {
  border-inline-start-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-s-sky-300\/30 {
  border-inline-start-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-s-sky-300\/35 {
  border-inline-start-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-s-sky-300\/40 {
  border-inline-start-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-s-sky-300\/45 {
  border-inline-start-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-s-sky-300\/5 {
  border-inline-start-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-s-sky-300\/50 {
  border-inline-start-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-s-sky-300\/55 {
  border-inline-start-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-s-sky-300\/60 {
  border-inline-start-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-s-sky-300\/65 {
  border-inline-start-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-s-sky-300\/70 {
  border-inline-start-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-s-sky-300\/75 {
  border-inline-start-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-s-sky-300\/80 {
  border-inline-start-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-s-sky-300\/85 {
  border-inline-start-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-s-sky-300\/90 {
  border-inline-start-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-s-sky-300\/95 {
  border-inline-start-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-s-sky-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-400\/0 {
  border-inline-start-color: rgb(56 189 248 / 0) !important;
}

.tw-border-s-sky-400\/10 {
  border-inline-start-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-s-sky-400\/100 {
  border-inline-start-color: rgb(56 189 248 / 1) !important;
}

.tw-border-s-sky-400\/15 {
  border-inline-start-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-s-sky-400\/20 {
  border-inline-start-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-s-sky-400\/25 {
  border-inline-start-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-s-sky-400\/30 {
  border-inline-start-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-s-sky-400\/35 {
  border-inline-start-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-s-sky-400\/40 {
  border-inline-start-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-s-sky-400\/45 {
  border-inline-start-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-s-sky-400\/5 {
  border-inline-start-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-s-sky-400\/50 {
  border-inline-start-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-s-sky-400\/55 {
  border-inline-start-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-s-sky-400\/60 {
  border-inline-start-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-s-sky-400\/65 {
  border-inline-start-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-s-sky-400\/70 {
  border-inline-start-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-s-sky-400\/75 {
  border-inline-start-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-s-sky-400\/80 {
  border-inline-start-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-s-sky-400\/85 {
  border-inline-start-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-s-sky-400\/90 {
  border-inline-start-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-s-sky-400\/95 {
  border-inline-start-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-s-sky-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-50\/0 {
  border-inline-start-color: rgb(240 249 255 / 0) !important;
}

.tw-border-s-sky-50\/10 {
  border-inline-start-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-s-sky-50\/100 {
  border-inline-start-color: rgb(240 249 255 / 1) !important;
}

.tw-border-s-sky-50\/15 {
  border-inline-start-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-s-sky-50\/20 {
  border-inline-start-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-s-sky-50\/25 {
  border-inline-start-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-s-sky-50\/30 {
  border-inline-start-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-s-sky-50\/35 {
  border-inline-start-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-s-sky-50\/40 {
  border-inline-start-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-s-sky-50\/45 {
  border-inline-start-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-s-sky-50\/5 {
  border-inline-start-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-s-sky-50\/50 {
  border-inline-start-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-s-sky-50\/55 {
  border-inline-start-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-s-sky-50\/60 {
  border-inline-start-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-s-sky-50\/65 {
  border-inline-start-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-s-sky-50\/70 {
  border-inline-start-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-s-sky-50\/75 {
  border-inline-start-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-s-sky-50\/80 {
  border-inline-start-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-s-sky-50\/85 {
  border-inline-start-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-s-sky-50\/90 {
  border-inline-start-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-s-sky-50\/95 {
  border-inline-start-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-s-sky-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-500\/0 {
  border-inline-start-color: rgb(14 165 233 / 0) !important;
}

.tw-border-s-sky-500\/10 {
  border-inline-start-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-s-sky-500\/100 {
  border-inline-start-color: rgb(14 165 233 / 1) !important;
}

.tw-border-s-sky-500\/15 {
  border-inline-start-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-s-sky-500\/20 {
  border-inline-start-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-s-sky-500\/25 {
  border-inline-start-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-s-sky-500\/30 {
  border-inline-start-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-s-sky-500\/35 {
  border-inline-start-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-s-sky-500\/40 {
  border-inline-start-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-s-sky-500\/45 {
  border-inline-start-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-s-sky-500\/5 {
  border-inline-start-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-s-sky-500\/50 {
  border-inline-start-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-s-sky-500\/55 {
  border-inline-start-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-s-sky-500\/60 {
  border-inline-start-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-s-sky-500\/65 {
  border-inline-start-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-s-sky-500\/70 {
  border-inline-start-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-s-sky-500\/75 {
  border-inline-start-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-s-sky-500\/80 {
  border-inline-start-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-s-sky-500\/85 {
  border-inline-start-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-s-sky-500\/90 {
  border-inline-start-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-s-sky-500\/95 {
  border-inline-start-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-s-sky-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-600\/0 {
  border-inline-start-color: rgb(2 132 199 / 0) !important;
}

.tw-border-s-sky-600\/10 {
  border-inline-start-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-s-sky-600\/100 {
  border-inline-start-color: rgb(2 132 199 / 1) !important;
}

.tw-border-s-sky-600\/15 {
  border-inline-start-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-s-sky-600\/20 {
  border-inline-start-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-s-sky-600\/25 {
  border-inline-start-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-s-sky-600\/30 {
  border-inline-start-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-s-sky-600\/35 {
  border-inline-start-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-s-sky-600\/40 {
  border-inline-start-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-s-sky-600\/45 {
  border-inline-start-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-s-sky-600\/5 {
  border-inline-start-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-s-sky-600\/50 {
  border-inline-start-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-s-sky-600\/55 {
  border-inline-start-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-s-sky-600\/60 {
  border-inline-start-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-s-sky-600\/65 {
  border-inline-start-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-s-sky-600\/70 {
  border-inline-start-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-s-sky-600\/75 {
  border-inline-start-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-s-sky-600\/80 {
  border-inline-start-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-s-sky-600\/85 {
  border-inline-start-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-s-sky-600\/90 {
  border-inline-start-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-s-sky-600\/95 {
  border-inline-start-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-s-sky-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-700\/0 {
  border-inline-start-color: rgb(3 105 161 / 0) !important;
}

.tw-border-s-sky-700\/10 {
  border-inline-start-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-s-sky-700\/100 {
  border-inline-start-color: rgb(3 105 161 / 1) !important;
}

.tw-border-s-sky-700\/15 {
  border-inline-start-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-s-sky-700\/20 {
  border-inline-start-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-s-sky-700\/25 {
  border-inline-start-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-s-sky-700\/30 {
  border-inline-start-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-s-sky-700\/35 {
  border-inline-start-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-s-sky-700\/40 {
  border-inline-start-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-s-sky-700\/45 {
  border-inline-start-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-s-sky-700\/5 {
  border-inline-start-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-s-sky-700\/50 {
  border-inline-start-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-s-sky-700\/55 {
  border-inline-start-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-s-sky-700\/60 {
  border-inline-start-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-s-sky-700\/65 {
  border-inline-start-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-s-sky-700\/70 {
  border-inline-start-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-s-sky-700\/75 {
  border-inline-start-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-s-sky-700\/80 {
  border-inline-start-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-s-sky-700\/85 {
  border-inline-start-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-s-sky-700\/90 {
  border-inline-start-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-s-sky-700\/95 {
  border-inline-start-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-s-sky-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-800\/0 {
  border-inline-start-color: rgb(7 89 133 / 0) !important;
}

.tw-border-s-sky-800\/10 {
  border-inline-start-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-s-sky-800\/100 {
  border-inline-start-color: rgb(7 89 133 / 1) !important;
}

.tw-border-s-sky-800\/15 {
  border-inline-start-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-s-sky-800\/20 {
  border-inline-start-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-s-sky-800\/25 {
  border-inline-start-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-s-sky-800\/30 {
  border-inline-start-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-s-sky-800\/35 {
  border-inline-start-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-s-sky-800\/40 {
  border-inline-start-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-s-sky-800\/45 {
  border-inline-start-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-s-sky-800\/5 {
  border-inline-start-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-s-sky-800\/50 {
  border-inline-start-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-s-sky-800\/55 {
  border-inline-start-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-s-sky-800\/60 {
  border-inline-start-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-s-sky-800\/65 {
  border-inline-start-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-s-sky-800\/70 {
  border-inline-start-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-s-sky-800\/75 {
  border-inline-start-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-s-sky-800\/80 {
  border-inline-start-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-s-sky-800\/85 {
  border-inline-start-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-s-sky-800\/90 {
  border-inline-start-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-s-sky-800\/95 {
  border-inline-start-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-s-sky-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-900\/0 {
  border-inline-start-color: rgb(12 74 110 / 0) !important;
}

.tw-border-s-sky-900\/10 {
  border-inline-start-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-s-sky-900\/100 {
  border-inline-start-color: rgb(12 74 110 / 1) !important;
}

.tw-border-s-sky-900\/15 {
  border-inline-start-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-s-sky-900\/20 {
  border-inline-start-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-s-sky-900\/25 {
  border-inline-start-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-s-sky-900\/30 {
  border-inline-start-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-s-sky-900\/35 {
  border-inline-start-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-s-sky-900\/40 {
  border-inline-start-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-s-sky-900\/45 {
  border-inline-start-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-s-sky-900\/5 {
  border-inline-start-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-s-sky-900\/50 {
  border-inline-start-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-s-sky-900\/55 {
  border-inline-start-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-s-sky-900\/60 {
  border-inline-start-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-s-sky-900\/65 {
  border-inline-start-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-s-sky-900\/70 {
  border-inline-start-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-s-sky-900\/75 {
  border-inline-start-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-s-sky-900\/80 {
  border-inline-start-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-s-sky-900\/85 {
  border-inline-start-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-s-sky-900\/90 {
  border-inline-start-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-s-sky-900\/95 {
  border-inline-start-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-s-sky-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-s-sky-950\/0 {
  border-inline-start-color: rgb(8 47 73 / 0) !important;
}

.tw-border-s-sky-950\/10 {
  border-inline-start-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-s-sky-950\/100 {
  border-inline-start-color: rgb(8 47 73 / 1) !important;
}

.tw-border-s-sky-950\/15 {
  border-inline-start-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-s-sky-950\/20 {
  border-inline-start-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-s-sky-950\/25 {
  border-inline-start-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-s-sky-950\/30 {
  border-inline-start-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-s-sky-950\/35 {
  border-inline-start-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-s-sky-950\/40 {
  border-inline-start-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-s-sky-950\/45 {
  border-inline-start-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-s-sky-950\/5 {
  border-inline-start-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-s-sky-950\/50 {
  border-inline-start-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-s-sky-950\/55 {
  border-inline-start-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-s-sky-950\/60 {
  border-inline-start-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-s-sky-950\/65 {
  border-inline-start-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-s-sky-950\/70 {
  border-inline-start-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-s-sky-950\/75 {
  border-inline-start-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-s-sky-950\/80 {
  border-inline-start-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-s-sky-950\/85 {
  border-inline-start-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-s-sky-950\/90 {
  border-inline-start-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-s-sky-950\/95 {
  border-inline-start-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-s-slate-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-100\/0 {
  border-inline-start-color: rgb(241 245 249 / 0) !important;
}

.tw-border-s-slate-100\/10 {
  border-inline-start-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-s-slate-100\/100 {
  border-inline-start-color: rgb(241 245 249 / 1) !important;
}

.tw-border-s-slate-100\/15 {
  border-inline-start-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-s-slate-100\/20 {
  border-inline-start-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-s-slate-100\/25 {
  border-inline-start-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-s-slate-100\/30 {
  border-inline-start-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-s-slate-100\/35 {
  border-inline-start-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-s-slate-100\/40 {
  border-inline-start-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-s-slate-100\/45 {
  border-inline-start-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-s-slate-100\/5 {
  border-inline-start-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-s-slate-100\/50 {
  border-inline-start-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-s-slate-100\/55 {
  border-inline-start-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-s-slate-100\/60 {
  border-inline-start-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-s-slate-100\/65 {
  border-inline-start-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-s-slate-100\/70 {
  border-inline-start-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-s-slate-100\/75 {
  border-inline-start-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-s-slate-100\/80 {
  border-inline-start-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-s-slate-100\/85 {
  border-inline-start-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-s-slate-100\/90 {
  border-inline-start-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-s-slate-100\/95 {
  border-inline-start-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-s-slate-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-200\/0 {
  border-inline-start-color: rgb(226 232 240 / 0) !important;
}

.tw-border-s-slate-200\/10 {
  border-inline-start-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-s-slate-200\/100 {
  border-inline-start-color: rgb(226 232 240 / 1) !important;
}

.tw-border-s-slate-200\/15 {
  border-inline-start-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-s-slate-200\/20 {
  border-inline-start-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-s-slate-200\/25 {
  border-inline-start-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-s-slate-200\/30 {
  border-inline-start-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-s-slate-200\/35 {
  border-inline-start-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-s-slate-200\/40 {
  border-inline-start-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-s-slate-200\/45 {
  border-inline-start-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-s-slate-200\/5 {
  border-inline-start-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-s-slate-200\/50 {
  border-inline-start-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-s-slate-200\/55 {
  border-inline-start-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-s-slate-200\/60 {
  border-inline-start-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-s-slate-200\/65 {
  border-inline-start-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-s-slate-200\/70 {
  border-inline-start-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-s-slate-200\/75 {
  border-inline-start-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-s-slate-200\/80 {
  border-inline-start-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-s-slate-200\/85 {
  border-inline-start-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-s-slate-200\/90 {
  border-inline-start-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-s-slate-200\/95 {
  border-inline-start-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-s-slate-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-300\/0 {
  border-inline-start-color: rgb(203 213 225 / 0) !important;
}

.tw-border-s-slate-300\/10 {
  border-inline-start-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-s-slate-300\/100 {
  border-inline-start-color: rgb(203 213 225 / 1) !important;
}

.tw-border-s-slate-300\/15 {
  border-inline-start-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-s-slate-300\/20 {
  border-inline-start-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-s-slate-300\/25 {
  border-inline-start-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-s-slate-300\/30 {
  border-inline-start-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-s-slate-300\/35 {
  border-inline-start-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-s-slate-300\/40 {
  border-inline-start-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-s-slate-300\/45 {
  border-inline-start-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-s-slate-300\/5 {
  border-inline-start-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-s-slate-300\/50 {
  border-inline-start-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-s-slate-300\/55 {
  border-inline-start-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-s-slate-300\/60 {
  border-inline-start-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-s-slate-300\/65 {
  border-inline-start-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-s-slate-300\/70 {
  border-inline-start-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-s-slate-300\/75 {
  border-inline-start-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-s-slate-300\/80 {
  border-inline-start-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-s-slate-300\/85 {
  border-inline-start-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-s-slate-300\/90 {
  border-inline-start-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-s-slate-300\/95 {
  border-inline-start-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-s-slate-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-400\/0 {
  border-inline-start-color: rgb(148 163 184 / 0) !important;
}

.tw-border-s-slate-400\/10 {
  border-inline-start-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-s-slate-400\/100 {
  border-inline-start-color: rgb(148 163 184 / 1) !important;
}

.tw-border-s-slate-400\/15 {
  border-inline-start-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-s-slate-400\/20 {
  border-inline-start-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-s-slate-400\/25 {
  border-inline-start-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-s-slate-400\/30 {
  border-inline-start-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-s-slate-400\/35 {
  border-inline-start-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-s-slate-400\/40 {
  border-inline-start-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-s-slate-400\/45 {
  border-inline-start-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-s-slate-400\/5 {
  border-inline-start-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-s-slate-400\/50 {
  border-inline-start-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-s-slate-400\/55 {
  border-inline-start-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-s-slate-400\/60 {
  border-inline-start-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-s-slate-400\/65 {
  border-inline-start-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-s-slate-400\/70 {
  border-inline-start-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-s-slate-400\/75 {
  border-inline-start-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-s-slate-400\/80 {
  border-inline-start-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-s-slate-400\/85 {
  border-inline-start-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-s-slate-400\/90 {
  border-inline-start-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-s-slate-400\/95 {
  border-inline-start-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-s-slate-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-50\/0 {
  border-inline-start-color: rgb(248 250 252 / 0) !important;
}

.tw-border-s-slate-50\/10 {
  border-inline-start-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-s-slate-50\/100 {
  border-inline-start-color: rgb(248 250 252 / 1) !important;
}

.tw-border-s-slate-50\/15 {
  border-inline-start-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-s-slate-50\/20 {
  border-inline-start-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-s-slate-50\/25 {
  border-inline-start-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-s-slate-50\/30 {
  border-inline-start-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-s-slate-50\/35 {
  border-inline-start-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-s-slate-50\/40 {
  border-inline-start-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-s-slate-50\/45 {
  border-inline-start-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-s-slate-50\/5 {
  border-inline-start-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-s-slate-50\/50 {
  border-inline-start-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-s-slate-50\/55 {
  border-inline-start-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-s-slate-50\/60 {
  border-inline-start-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-s-slate-50\/65 {
  border-inline-start-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-s-slate-50\/70 {
  border-inline-start-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-s-slate-50\/75 {
  border-inline-start-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-s-slate-50\/80 {
  border-inline-start-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-s-slate-50\/85 {
  border-inline-start-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-s-slate-50\/90 {
  border-inline-start-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-s-slate-50\/95 {
  border-inline-start-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-s-slate-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-500\/0 {
  border-inline-start-color: rgb(100 116 139 / 0) !important;
}

.tw-border-s-slate-500\/10 {
  border-inline-start-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-s-slate-500\/100 {
  border-inline-start-color: rgb(100 116 139 / 1) !important;
}

.tw-border-s-slate-500\/15 {
  border-inline-start-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-s-slate-500\/20 {
  border-inline-start-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-s-slate-500\/25 {
  border-inline-start-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-s-slate-500\/30 {
  border-inline-start-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-s-slate-500\/35 {
  border-inline-start-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-s-slate-500\/40 {
  border-inline-start-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-s-slate-500\/45 {
  border-inline-start-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-s-slate-500\/5 {
  border-inline-start-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-s-slate-500\/50 {
  border-inline-start-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-s-slate-500\/55 {
  border-inline-start-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-s-slate-500\/60 {
  border-inline-start-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-s-slate-500\/65 {
  border-inline-start-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-s-slate-500\/70 {
  border-inline-start-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-s-slate-500\/75 {
  border-inline-start-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-s-slate-500\/80 {
  border-inline-start-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-s-slate-500\/85 {
  border-inline-start-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-s-slate-500\/90 {
  border-inline-start-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-s-slate-500\/95 {
  border-inline-start-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-s-slate-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-600\/0 {
  border-inline-start-color: rgb(71 85 105 / 0) !important;
}

.tw-border-s-slate-600\/10 {
  border-inline-start-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-s-slate-600\/100 {
  border-inline-start-color: rgb(71 85 105 / 1) !important;
}

.tw-border-s-slate-600\/15 {
  border-inline-start-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-s-slate-600\/20 {
  border-inline-start-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-s-slate-600\/25 {
  border-inline-start-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-s-slate-600\/30 {
  border-inline-start-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-s-slate-600\/35 {
  border-inline-start-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-s-slate-600\/40 {
  border-inline-start-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-s-slate-600\/45 {
  border-inline-start-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-s-slate-600\/5 {
  border-inline-start-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-s-slate-600\/50 {
  border-inline-start-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-s-slate-600\/55 {
  border-inline-start-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-s-slate-600\/60 {
  border-inline-start-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-s-slate-600\/65 {
  border-inline-start-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-s-slate-600\/70 {
  border-inline-start-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-s-slate-600\/75 {
  border-inline-start-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-s-slate-600\/80 {
  border-inline-start-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-s-slate-600\/85 {
  border-inline-start-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-s-slate-600\/90 {
  border-inline-start-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-s-slate-600\/95 {
  border-inline-start-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-s-slate-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-700\/0 {
  border-inline-start-color: rgb(51 65 85 / 0) !important;
}

.tw-border-s-slate-700\/10 {
  border-inline-start-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-s-slate-700\/100 {
  border-inline-start-color: rgb(51 65 85 / 1) !important;
}

.tw-border-s-slate-700\/15 {
  border-inline-start-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-s-slate-700\/20 {
  border-inline-start-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-s-slate-700\/25 {
  border-inline-start-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-s-slate-700\/30 {
  border-inline-start-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-s-slate-700\/35 {
  border-inline-start-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-s-slate-700\/40 {
  border-inline-start-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-s-slate-700\/45 {
  border-inline-start-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-s-slate-700\/5 {
  border-inline-start-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-s-slate-700\/50 {
  border-inline-start-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-s-slate-700\/55 {
  border-inline-start-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-s-slate-700\/60 {
  border-inline-start-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-s-slate-700\/65 {
  border-inline-start-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-s-slate-700\/70 {
  border-inline-start-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-s-slate-700\/75 {
  border-inline-start-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-s-slate-700\/80 {
  border-inline-start-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-s-slate-700\/85 {
  border-inline-start-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-s-slate-700\/90 {
  border-inline-start-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-s-slate-700\/95 {
  border-inline-start-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-s-slate-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-800\/0 {
  border-inline-start-color: rgb(30 41 59 / 0) !important;
}

.tw-border-s-slate-800\/10 {
  border-inline-start-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-s-slate-800\/100 {
  border-inline-start-color: rgb(30 41 59 / 1) !important;
}

.tw-border-s-slate-800\/15 {
  border-inline-start-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-s-slate-800\/20 {
  border-inline-start-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-s-slate-800\/25 {
  border-inline-start-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-s-slate-800\/30 {
  border-inline-start-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-s-slate-800\/35 {
  border-inline-start-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-s-slate-800\/40 {
  border-inline-start-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-s-slate-800\/45 {
  border-inline-start-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-s-slate-800\/5 {
  border-inline-start-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-s-slate-800\/50 {
  border-inline-start-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-s-slate-800\/55 {
  border-inline-start-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-s-slate-800\/60 {
  border-inline-start-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-s-slate-800\/65 {
  border-inline-start-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-s-slate-800\/70 {
  border-inline-start-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-s-slate-800\/75 {
  border-inline-start-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-s-slate-800\/80 {
  border-inline-start-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-s-slate-800\/85 {
  border-inline-start-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-s-slate-800\/90 {
  border-inline-start-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-s-slate-800\/95 {
  border-inline-start-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-s-slate-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-900\/0 {
  border-inline-start-color: rgb(15 23 42 / 0) !important;
}

.tw-border-s-slate-900\/10 {
  border-inline-start-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-s-slate-900\/100 {
  border-inline-start-color: rgb(15 23 42 / 1) !important;
}

.tw-border-s-slate-900\/15 {
  border-inline-start-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-s-slate-900\/20 {
  border-inline-start-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-s-slate-900\/25 {
  border-inline-start-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-s-slate-900\/30 {
  border-inline-start-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-s-slate-900\/35 {
  border-inline-start-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-s-slate-900\/40 {
  border-inline-start-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-s-slate-900\/45 {
  border-inline-start-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-s-slate-900\/5 {
  border-inline-start-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-s-slate-900\/50 {
  border-inline-start-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-s-slate-900\/55 {
  border-inline-start-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-s-slate-900\/60 {
  border-inline-start-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-s-slate-900\/65 {
  border-inline-start-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-s-slate-900\/70 {
  border-inline-start-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-s-slate-900\/75 {
  border-inline-start-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-s-slate-900\/80 {
  border-inline-start-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-s-slate-900\/85 {
  border-inline-start-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-s-slate-900\/90 {
  border-inline-start-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-s-slate-900\/95 {
  border-inline-start-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-s-slate-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-s-slate-950\/0 {
  border-inline-start-color: rgb(2 6 23 / 0) !important;
}

.tw-border-s-slate-950\/10 {
  border-inline-start-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-s-slate-950\/100 {
  border-inline-start-color: rgb(2 6 23 / 1) !important;
}

.tw-border-s-slate-950\/15 {
  border-inline-start-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-s-slate-950\/20 {
  border-inline-start-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-s-slate-950\/25 {
  border-inline-start-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-s-slate-950\/30 {
  border-inline-start-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-s-slate-950\/35 {
  border-inline-start-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-s-slate-950\/40 {
  border-inline-start-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-s-slate-950\/45 {
  border-inline-start-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-s-slate-950\/5 {
  border-inline-start-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-s-slate-950\/50 {
  border-inline-start-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-s-slate-950\/55 {
  border-inline-start-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-s-slate-950\/60 {
  border-inline-start-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-s-slate-950\/65 {
  border-inline-start-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-s-slate-950\/70 {
  border-inline-start-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-s-slate-950\/75 {
  border-inline-start-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-s-slate-950\/80 {
  border-inline-start-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-s-slate-950\/85 {
  border-inline-start-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-s-slate-950\/90 {
  border-inline-start-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-s-slate-950\/95 {
  border-inline-start-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-s-stone-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-100\/0 {
  border-inline-start-color: rgb(245 245 244 / 0) !important;
}

.tw-border-s-stone-100\/10 {
  border-inline-start-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-s-stone-100\/100 {
  border-inline-start-color: rgb(245 245 244 / 1) !important;
}

.tw-border-s-stone-100\/15 {
  border-inline-start-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-s-stone-100\/20 {
  border-inline-start-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-s-stone-100\/25 {
  border-inline-start-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-s-stone-100\/30 {
  border-inline-start-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-s-stone-100\/35 {
  border-inline-start-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-s-stone-100\/40 {
  border-inline-start-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-s-stone-100\/45 {
  border-inline-start-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-s-stone-100\/5 {
  border-inline-start-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-s-stone-100\/50 {
  border-inline-start-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-s-stone-100\/55 {
  border-inline-start-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-s-stone-100\/60 {
  border-inline-start-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-s-stone-100\/65 {
  border-inline-start-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-s-stone-100\/70 {
  border-inline-start-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-s-stone-100\/75 {
  border-inline-start-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-s-stone-100\/80 {
  border-inline-start-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-s-stone-100\/85 {
  border-inline-start-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-s-stone-100\/90 {
  border-inline-start-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-s-stone-100\/95 {
  border-inline-start-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-s-stone-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-200\/0 {
  border-inline-start-color: rgb(231 229 228 / 0) !important;
}

.tw-border-s-stone-200\/10 {
  border-inline-start-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-s-stone-200\/100 {
  border-inline-start-color: rgb(231 229 228 / 1) !important;
}

.tw-border-s-stone-200\/15 {
  border-inline-start-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-s-stone-200\/20 {
  border-inline-start-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-s-stone-200\/25 {
  border-inline-start-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-s-stone-200\/30 {
  border-inline-start-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-s-stone-200\/35 {
  border-inline-start-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-s-stone-200\/40 {
  border-inline-start-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-s-stone-200\/45 {
  border-inline-start-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-s-stone-200\/5 {
  border-inline-start-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-s-stone-200\/50 {
  border-inline-start-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-s-stone-200\/55 {
  border-inline-start-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-s-stone-200\/60 {
  border-inline-start-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-s-stone-200\/65 {
  border-inline-start-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-s-stone-200\/70 {
  border-inline-start-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-s-stone-200\/75 {
  border-inline-start-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-s-stone-200\/80 {
  border-inline-start-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-s-stone-200\/85 {
  border-inline-start-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-s-stone-200\/90 {
  border-inline-start-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-s-stone-200\/95 {
  border-inline-start-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-s-stone-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-300\/0 {
  border-inline-start-color: rgb(214 211 209 / 0) !important;
}

.tw-border-s-stone-300\/10 {
  border-inline-start-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-s-stone-300\/100 {
  border-inline-start-color: rgb(214 211 209 / 1) !important;
}

.tw-border-s-stone-300\/15 {
  border-inline-start-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-s-stone-300\/20 {
  border-inline-start-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-s-stone-300\/25 {
  border-inline-start-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-s-stone-300\/30 {
  border-inline-start-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-s-stone-300\/35 {
  border-inline-start-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-s-stone-300\/40 {
  border-inline-start-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-s-stone-300\/45 {
  border-inline-start-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-s-stone-300\/5 {
  border-inline-start-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-s-stone-300\/50 {
  border-inline-start-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-s-stone-300\/55 {
  border-inline-start-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-s-stone-300\/60 {
  border-inline-start-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-s-stone-300\/65 {
  border-inline-start-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-s-stone-300\/70 {
  border-inline-start-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-s-stone-300\/75 {
  border-inline-start-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-s-stone-300\/80 {
  border-inline-start-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-s-stone-300\/85 {
  border-inline-start-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-s-stone-300\/90 {
  border-inline-start-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-s-stone-300\/95 {
  border-inline-start-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-s-stone-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-400\/0 {
  border-inline-start-color: rgb(168 162 158 / 0) !important;
}

.tw-border-s-stone-400\/10 {
  border-inline-start-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-s-stone-400\/100 {
  border-inline-start-color: rgb(168 162 158 / 1) !important;
}

.tw-border-s-stone-400\/15 {
  border-inline-start-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-s-stone-400\/20 {
  border-inline-start-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-s-stone-400\/25 {
  border-inline-start-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-s-stone-400\/30 {
  border-inline-start-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-s-stone-400\/35 {
  border-inline-start-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-s-stone-400\/40 {
  border-inline-start-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-s-stone-400\/45 {
  border-inline-start-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-s-stone-400\/5 {
  border-inline-start-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-s-stone-400\/50 {
  border-inline-start-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-s-stone-400\/55 {
  border-inline-start-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-s-stone-400\/60 {
  border-inline-start-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-s-stone-400\/65 {
  border-inline-start-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-s-stone-400\/70 {
  border-inline-start-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-s-stone-400\/75 {
  border-inline-start-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-s-stone-400\/80 {
  border-inline-start-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-s-stone-400\/85 {
  border-inline-start-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-s-stone-400\/90 {
  border-inline-start-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-s-stone-400\/95 {
  border-inline-start-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-s-stone-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-50\/0 {
  border-inline-start-color: rgb(250 250 249 / 0) !important;
}

.tw-border-s-stone-50\/10 {
  border-inline-start-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-s-stone-50\/100 {
  border-inline-start-color: rgb(250 250 249 / 1) !important;
}

.tw-border-s-stone-50\/15 {
  border-inline-start-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-s-stone-50\/20 {
  border-inline-start-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-s-stone-50\/25 {
  border-inline-start-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-s-stone-50\/30 {
  border-inline-start-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-s-stone-50\/35 {
  border-inline-start-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-s-stone-50\/40 {
  border-inline-start-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-s-stone-50\/45 {
  border-inline-start-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-s-stone-50\/5 {
  border-inline-start-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-s-stone-50\/50 {
  border-inline-start-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-s-stone-50\/55 {
  border-inline-start-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-s-stone-50\/60 {
  border-inline-start-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-s-stone-50\/65 {
  border-inline-start-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-s-stone-50\/70 {
  border-inline-start-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-s-stone-50\/75 {
  border-inline-start-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-s-stone-50\/80 {
  border-inline-start-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-s-stone-50\/85 {
  border-inline-start-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-s-stone-50\/90 {
  border-inline-start-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-s-stone-50\/95 {
  border-inline-start-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-s-stone-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-500\/0 {
  border-inline-start-color: rgb(120 113 108 / 0) !important;
}

.tw-border-s-stone-500\/10 {
  border-inline-start-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-s-stone-500\/100 {
  border-inline-start-color: rgb(120 113 108 / 1) !important;
}

.tw-border-s-stone-500\/15 {
  border-inline-start-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-s-stone-500\/20 {
  border-inline-start-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-s-stone-500\/25 {
  border-inline-start-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-s-stone-500\/30 {
  border-inline-start-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-s-stone-500\/35 {
  border-inline-start-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-s-stone-500\/40 {
  border-inline-start-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-s-stone-500\/45 {
  border-inline-start-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-s-stone-500\/5 {
  border-inline-start-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-s-stone-500\/50 {
  border-inline-start-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-s-stone-500\/55 {
  border-inline-start-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-s-stone-500\/60 {
  border-inline-start-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-s-stone-500\/65 {
  border-inline-start-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-s-stone-500\/70 {
  border-inline-start-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-s-stone-500\/75 {
  border-inline-start-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-s-stone-500\/80 {
  border-inline-start-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-s-stone-500\/85 {
  border-inline-start-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-s-stone-500\/90 {
  border-inline-start-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-s-stone-500\/95 {
  border-inline-start-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-s-stone-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-600\/0 {
  border-inline-start-color: rgb(87 83 78 / 0) !important;
}

.tw-border-s-stone-600\/10 {
  border-inline-start-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-s-stone-600\/100 {
  border-inline-start-color: rgb(87 83 78 / 1) !important;
}

.tw-border-s-stone-600\/15 {
  border-inline-start-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-s-stone-600\/20 {
  border-inline-start-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-s-stone-600\/25 {
  border-inline-start-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-s-stone-600\/30 {
  border-inline-start-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-s-stone-600\/35 {
  border-inline-start-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-s-stone-600\/40 {
  border-inline-start-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-s-stone-600\/45 {
  border-inline-start-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-s-stone-600\/5 {
  border-inline-start-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-s-stone-600\/50 {
  border-inline-start-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-s-stone-600\/55 {
  border-inline-start-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-s-stone-600\/60 {
  border-inline-start-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-s-stone-600\/65 {
  border-inline-start-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-s-stone-600\/70 {
  border-inline-start-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-s-stone-600\/75 {
  border-inline-start-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-s-stone-600\/80 {
  border-inline-start-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-s-stone-600\/85 {
  border-inline-start-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-s-stone-600\/90 {
  border-inline-start-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-s-stone-600\/95 {
  border-inline-start-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-s-stone-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-700\/0 {
  border-inline-start-color: rgb(68 64 60 / 0) !important;
}

.tw-border-s-stone-700\/10 {
  border-inline-start-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-s-stone-700\/100 {
  border-inline-start-color: rgb(68 64 60 / 1) !important;
}

.tw-border-s-stone-700\/15 {
  border-inline-start-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-s-stone-700\/20 {
  border-inline-start-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-s-stone-700\/25 {
  border-inline-start-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-s-stone-700\/30 {
  border-inline-start-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-s-stone-700\/35 {
  border-inline-start-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-s-stone-700\/40 {
  border-inline-start-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-s-stone-700\/45 {
  border-inline-start-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-s-stone-700\/5 {
  border-inline-start-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-s-stone-700\/50 {
  border-inline-start-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-s-stone-700\/55 {
  border-inline-start-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-s-stone-700\/60 {
  border-inline-start-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-s-stone-700\/65 {
  border-inline-start-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-s-stone-700\/70 {
  border-inline-start-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-s-stone-700\/75 {
  border-inline-start-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-s-stone-700\/80 {
  border-inline-start-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-s-stone-700\/85 {
  border-inline-start-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-s-stone-700\/90 {
  border-inline-start-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-s-stone-700\/95 {
  border-inline-start-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-s-stone-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-800\/0 {
  border-inline-start-color: rgb(41 37 36 / 0) !important;
}

.tw-border-s-stone-800\/10 {
  border-inline-start-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-s-stone-800\/100 {
  border-inline-start-color: rgb(41 37 36 / 1) !important;
}

.tw-border-s-stone-800\/15 {
  border-inline-start-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-s-stone-800\/20 {
  border-inline-start-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-s-stone-800\/25 {
  border-inline-start-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-s-stone-800\/30 {
  border-inline-start-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-s-stone-800\/35 {
  border-inline-start-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-s-stone-800\/40 {
  border-inline-start-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-s-stone-800\/45 {
  border-inline-start-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-s-stone-800\/5 {
  border-inline-start-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-s-stone-800\/50 {
  border-inline-start-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-s-stone-800\/55 {
  border-inline-start-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-s-stone-800\/60 {
  border-inline-start-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-s-stone-800\/65 {
  border-inline-start-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-s-stone-800\/70 {
  border-inline-start-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-s-stone-800\/75 {
  border-inline-start-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-s-stone-800\/80 {
  border-inline-start-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-s-stone-800\/85 {
  border-inline-start-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-s-stone-800\/90 {
  border-inline-start-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-s-stone-800\/95 {
  border-inline-start-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-s-stone-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-900\/0 {
  border-inline-start-color: rgb(28 25 23 / 0) !important;
}

.tw-border-s-stone-900\/10 {
  border-inline-start-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-s-stone-900\/100 {
  border-inline-start-color: rgb(28 25 23 / 1) !important;
}

.tw-border-s-stone-900\/15 {
  border-inline-start-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-s-stone-900\/20 {
  border-inline-start-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-s-stone-900\/25 {
  border-inline-start-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-s-stone-900\/30 {
  border-inline-start-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-s-stone-900\/35 {
  border-inline-start-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-s-stone-900\/40 {
  border-inline-start-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-s-stone-900\/45 {
  border-inline-start-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-s-stone-900\/5 {
  border-inline-start-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-s-stone-900\/50 {
  border-inline-start-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-s-stone-900\/55 {
  border-inline-start-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-s-stone-900\/60 {
  border-inline-start-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-s-stone-900\/65 {
  border-inline-start-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-s-stone-900\/70 {
  border-inline-start-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-s-stone-900\/75 {
  border-inline-start-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-s-stone-900\/80 {
  border-inline-start-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-s-stone-900\/85 {
  border-inline-start-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-s-stone-900\/90 {
  border-inline-start-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-s-stone-900\/95 {
  border-inline-start-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-s-stone-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-s-stone-950\/0 {
  border-inline-start-color: rgb(12 10 9 / 0) !important;
}

.tw-border-s-stone-950\/10 {
  border-inline-start-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-s-stone-950\/100 {
  border-inline-start-color: rgb(12 10 9 / 1) !important;
}

.tw-border-s-stone-950\/15 {
  border-inline-start-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-s-stone-950\/20 {
  border-inline-start-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-s-stone-950\/25 {
  border-inline-start-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-s-stone-950\/30 {
  border-inline-start-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-s-stone-950\/35 {
  border-inline-start-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-s-stone-950\/40 {
  border-inline-start-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-s-stone-950\/45 {
  border-inline-start-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-s-stone-950\/5 {
  border-inline-start-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-s-stone-950\/50 {
  border-inline-start-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-s-stone-950\/55 {
  border-inline-start-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-s-stone-950\/60 {
  border-inline-start-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-s-stone-950\/65 {
  border-inline-start-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-s-stone-950\/70 {
  border-inline-start-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-s-stone-950\/75 {
  border-inline-start-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-s-stone-950\/80 {
  border-inline-start-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-s-stone-950\/85 {
  border-inline-start-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-s-stone-950\/90 {
  border-inline-start-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-s-stone-950\/95 {
  border-inline-start-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-s-teal-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-100\/0 {
  border-inline-start-color: rgb(204 251 241 / 0) !important;
}

.tw-border-s-teal-100\/10 {
  border-inline-start-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-s-teal-100\/100 {
  border-inline-start-color: rgb(204 251 241 / 1) !important;
}

.tw-border-s-teal-100\/15 {
  border-inline-start-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-s-teal-100\/20 {
  border-inline-start-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-s-teal-100\/25 {
  border-inline-start-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-s-teal-100\/30 {
  border-inline-start-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-s-teal-100\/35 {
  border-inline-start-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-s-teal-100\/40 {
  border-inline-start-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-s-teal-100\/45 {
  border-inline-start-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-s-teal-100\/5 {
  border-inline-start-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-s-teal-100\/50 {
  border-inline-start-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-s-teal-100\/55 {
  border-inline-start-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-s-teal-100\/60 {
  border-inline-start-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-s-teal-100\/65 {
  border-inline-start-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-s-teal-100\/70 {
  border-inline-start-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-s-teal-100\/75 {
  border-inline-start-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-s-teal-100\/80 {
  border-inline-start-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-s-teal-100\/85 {
  border-inline-start-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-s-teal-100\/90 {
  border-inline-start-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-s-teal-100\/95 {
  border-inline-start-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-s-teal-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-200\/0 {
  border-inline-start-color: rgb(153 246 228 / 0) !important;
}

.tw-border-s-teal-200\/10 {
  border-inline-start-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-s-teal-200\/100 {
  border-inline-start-color: rgb(153 246 228 / 1) !important;
}

.tw-border-s-teal-200\/15 {
  border-inline-start-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-s-teal-200\/20 {
  border-inline-start-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-s-teal-200\/25 {
  border-inline-start-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-s-teal-200\/30 {
  border-inline-start-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-s-teal-200\/35 {
  border-inline-start-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-s-teal-200\/40 {
  border-inline-start-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-s-teal-200\/45 {
  border-inline-start-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-s-teal-200\/5 {
  border-inline-start-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-s-teal-200\/50 {
  border-inline-start-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-s-teal-200\/55 {
  border-inline-start-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-s-teal-200\/60 {
  border-inline-start-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-s-teal-200\/65 {
  border-inline-start-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-s-teal-200\/70 {
  border-inline-start-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-s-teal-200\/75 {
  border-inline-start-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-s-teal-200\/80 {
  border-inline-start-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-s-teal-200\/85 {
  border-inline-start-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-s-teal-200\/90 {
  border-inline-start-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-s-teal-200\/95 {
  border-inline-start-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-s-teal-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-300\/0 {
  border-inline-start-color: rgb(94 234 212 / 0) !important;
}

.tw-border-s-teal-300\/10 {
  border-inline-start-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-s-teal-300\/100 {
  border-inline-start-color: rgb(94 234 212 / 1) !important;
}

.tw-border-s-teal-300\/15 {
  border-inline-start-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-s-teal-300\/20 {
  border-inline-start-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-s-teal-300\/25 {
  border-inline-start-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-s-teal-300\/30 {
  border-inline-start-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-s-teal-300\/35 {
  border-inline-start-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-s-teal-300\/40 {
  border-inline-start-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-s-teal-300\/45 {
  border-inline-start-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-s-teal-300\/5 {
  border-inline-start-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-s-teal-300\/50 {
  border-inline-start-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-s-teal-300\/55 {
  border-inline-start-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-s-teal-300\/60 {
  border-inline-start-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-s-teal-300\/65 {
  border-inline-start-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-s-teal-300\/70 {
  border-inline-start-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-s-teal-300\/75 {
  border-inline-start-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-s-teal-300\/80 {
  border-inline-start-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-s-teal-300\/85 {
  border-inline-start-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-s-teal-300\/90 {
  border-inline-start-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-s-teal-300\/95 {
  border-inline-start-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-s-teal-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-400\/0 {
  border-inline-start-color: rgb(45 212 191 / 0) !important;
}

.tw-border-s-teal-400\/10 {
  border-inline-start-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-s-teal-400\/100 {
  border-inline-start-color: rgb(45 212 191 / 1) !important;
}

.tw-border-s-teal-400\/15 {
  border-inline-start-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-s-teal-400\/20 {
  border-inline-start-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-s-teal-400\/25 {
  border-inline-start-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-s-teal-400\/30 {
  border-inline-start-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-s-teal-400\/35 {
  border-inline-start-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-s-teal-400\/40 {
  border-inline-start-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-s-teal-400\/45 {
  border-inline-start-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-s-teal-400\/5 {
  border-inline-start-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-s-teal-400\/50 {
  border-inline-start-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-s-teal-400\/55 {
  border-inline-start-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-s-teal-400\/60 {
  border-inline-start-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-s-teal-400\/65 {
  border-inline-start-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-s-teal-400\/70 {
  border-inline-start-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-s-teal-400\/75 {
  border-inline-start-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-s-teal-400\/80 {
  border-inline-start-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-s-teal-400\/85 {
  border-inline-start-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-s-teal-400\/90 {
  border-inline-start-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-s-teal-400\/95 {
  border-inline-start-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-s-teal-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-50\/0 {
  border-inline-start-color: rgb(240 253 250 / 0) !important;
}

.tw-border-s-teal-50\/10 {
  border-inline-start-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-s-teal-50\/100 {
  border-inline-start-color: rgb(240 253 250 / 1) !important;
}

.tw-border-s-teal-50\/15 {
  border-inline-start-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-s-teal-50\/20 {
  border-inline-start-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-s-teal-50\/25 {
  border-inline-start-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-s-teal-50\/30 {
  border-inline-start-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-s-teal-50\/35 {
  border-inline-start-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-s-teal-50\/40 {
  border-inline-start-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-s-teal-50\/45 {
  border-inline-start-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-s-teal-50\/5 {
  border-inline-start-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-s-teal-50\/50 {
  border-inline-start-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-s-teal-50\/55 {
  border-inline-start-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-s-teal-50\/60 {
  border-inline-start-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-s-teal-50\/65 {
  border-inline-start-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-s-teal-50\/70 {
  border-inline-start-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-s-teal-50\/75 {
  border-inline-start-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-s-teal-50\/80 {
  border-inline-start-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-s-teal-50\/85 {
  border-inline-start-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-s-teal-50\/90 {
  border-inline-start-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-s-teal-50\/95 {
  border-inline-start-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-s-teal-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-500\/0 {
  border-inline-start-color: rgb(20 184 166 / 0) !important;
}

.tw-border-s-teal-500\/10 {
  border-inline-start-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-s-teal-500\/100 {
  border-inline-start-color: rgb(20 184 166 / 1) !important;
}

.tw-border-s-teal-500\/15 {
  border-inline-start-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-s-teal-500\/20 {
  border-inline-start-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-s-teal-500\/25 {
  border-inline-start-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-s-teal-500\/30 {
  border-inline-start-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-s-teal-500\/35 {
  border-inline-start-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-s-teal-500\/40 {
  border-inline-start-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-s-teal-500\/45 {
  border-inline-start-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-s-teal-500\/5 {
  border-inline-start-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-s-teal-500\/50 {
  border-inline-start-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-s-teal-500\/55 {
  border-inline-start-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-s-teal-500\/60 {
  border-inline-start-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-s-teal-500\/65 {
  border-inline-start-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-s-teal-500\/70 {
  border-inline-start-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-s-teal-500\/75 {
  border-inline-start-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-s-teal-500\/80 {
  border-inline-start-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-s-teal-500\/85 {
  border-inline-start-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-s-teal-500\/90 {
  border-inline-start-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-s-teal-500\/95 {
  border-inline-start-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-s-teal-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-600\/0 {
  border-inline-start-color: rgb(13 148 136 / 0) !important;
}

.tw-border-s-teal-600\/10 {
  border-inline-start-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-s-teal-600\/100 {
  border-inline-start-color: rgb(13 148 136 / 1) !important;
}

.tw-border-s-teal-600\/15 {
  border-inline-start-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-s-teal-600\/20 {
  border-inline-start-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-s-teal-600\/25 {
  border-inline-start-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-s-teal-600\/30 {
  border-inline-start-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-s-teal-600\/35 {
  border-inline-start-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-s-teal-600\/40 {
  border-inline-start-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-s-teal-600\/45 {
  border-inline-start-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-s-teal-600\/5 {
  border-inline-start-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-s-teal-600\/50 {
  border-inline-start-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-s-teal-600\/55 {
  border-inline-start-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-s-teal-600\/60 {
  border-inline-start-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-s-teal-600\/65 {
  border-inline-start-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-s-teal-600\/70 {
  border-inline-start-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-s-teal-600\/75 {
  border-inline-start-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-s-teal-600\/80 {
  border-inline-start-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-s-teal-600\/85 {
  border-inline-start-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-s-teal-600\/90 {
  border-inline-start-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-s-teal-600\/95 {
  border-inline-start-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-s-teal-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-700\/0 {
  border-inline-start-color: rgb(15 118 110 / 0) !important;
}

.tw-border-s-teal-700\/10 {
  border-inline-start-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-s-teal-700\/100 {
  border-inline-start-color: rgb(15 118 110 / 1) !important;
}

.tw-border-s-teal-700\/15 {
  border-inline-start-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-s-teal-700\/20 {
  border-inline-start-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-s-teal-700\/25 {
  border-inline-start-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-s-teal-700\/30 {
  border-inline-start-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-s-teal-700\/35 {
  border-inline-start-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-s-teal-700\/40 {
  border-inline-start-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-s-teal-700\/45 {
  border-inline-start-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-s-teal-700\/5 {
  border-inline-start-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-s-teal-700\/50 {
  border-inline-start-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-s-teal-700\/55 {
  border-inline-start-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-s-teal-700\/60 {
  border-inline-start-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-s-teal-700\/65 {
  border-inline-start-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-s-teal-700\/70 {
  border-inline-start-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-s-teal-700\/75 {
  border-inline-start-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-s-teal-700\/80 {
  border-inline-start-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-s-teal-700\/85 {
  border-inline-start-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-s-teal-700\/90 {
  border-inline-start-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-s-teal-700\/95 {
  border-inline-start-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-s-teal-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-800\/0 {
  border-inline-start-color: rgb(17 94 89 / 0) !important;
}

.tw-border-s-teal-800\/10 {
  border-inline-start-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-s-teal-800\/100 {
  border-inline-start-color: rgb(17 94 89 / 1) !important;
}

.tw-border-s-teal-800\/15 {
  border-inline-start-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-s-teal-800\/20 {
  border-inline-start-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-s-teal-800\/25 {
  border-inline-start-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-s-teal-800\/30 {
  border-inline-start-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-s-teal-800\/35 {
  border-inline-start-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-s-teal-800\/40 {
  border-inline-start-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-s-teal-800\/45 {
  border-inline-start-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-s-teal-800\/5 {
  border-inline-start-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-s-teal-800\/50 {
  border-inline-start-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-s-teal-800\/55 {
  border-inline-start-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-s-teal-800\/60 {
  border-inline-start-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-s-teal-800\/65 {
  border-inline-start-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-s-teal-800\/70 {
  border-inline-start-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-s-teal-800\/75 {
  border-inline-start-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-s-teal-800\/80 {
  border-inline-start-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-s-teal-800\/85 {
  border-inline-start-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-s-teal-800\/90 {
  border-inline-start-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-s-teal-800\/95 {
  border-inline-start-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-s-teal-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-900\/0 {
  border-inline-start-color: rgb(19 78 74 / 0) !important;
}

.tw-border-s-teal-900\/10 {
  border-inline-start-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-s-teal-900\/100 {
  border-inline-start-color: rgb(19 78 74 / 1) !important;
}

.tw-border-s-teal-900\/15 {
  border-inline-start-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-s-teal-900\/20 {
  border-inline-start-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-s-teal-900\/25 {
  border-inline-start-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-s-teal-900\/30 {
  border-inline-start-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-s-teal-900\/35 {
  border-inline-start-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-s-teal-900\/40 {
  border-inline-start-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-s-teal-900\/45 {
  border-inline-start-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-s-teal-900\/5 {
  border-inline-start-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-s-teal-900\/50 {
  border-inline-start-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-s-teal-900\/55 {
  border-inline-start-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-s-teal-900\/60 {
  border-inline-start-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-s-teal-900\/65 {
  border-inline-start-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-s-teal-900\/70 {
  border-inline-start-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-s-teal-900\/75 {
  border-inline-start-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-s-teal-900\/80 {
  border-inline-start-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-s-teal-900\/85 {
  border-inline-start-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-s-teal-900\/90 {
  border-inline-start-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-s-teal-900\/95 {
  border-inline-start-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-s-teal-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-s-teal-950\/0 {
  border-inline-start-color: rgb(4 47 46 / 0) !important;
}

.tw-border-s-teal-950\/10 {
  border-inline-start-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-s-teal-950\/100 {
  border-inline-start-color: rgb(4 47 46 / 1) !important;
}

.tw-border-s-teal-950\/15 {
  border-inline-start-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-s-teal-950\/20 {
  border-inline-start-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-s-teal-950\/25 {
  border-inline-start-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-s-teal-950\/30 {
  border-inline-start-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-s-teal-950\/35 {
  border-inline-start-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-s-teal-950\/40 {
  border-inline-start-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-s-teal-950\/45 {
  border-inline-start-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-s-teal-950\/5 {
  border-inline-start-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-s-teal-950\/50 {
  border-inline-start-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-s-teal-950\/55 {
  border-inline-start-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-s-teal-950\/60 {
  border-inline-start-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-s-teal-950\/65 {
  border-inline-start-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-s-teal-950\/70 {
  border-inline-start-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-s-teal-950\/75 {
  border-inline-start-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-s-teal-950\/80 {
  border-inline-start-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-s-teal-950\/85 {
  border-inline-start-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-s-teal-950\/90 {
  border-inline-start-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-s-teal-950\/95 {
  border-inline-start-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-s-transparent {
  border-inline-start-color: transparent !important;
}

.tw-border-s-transparent\/0 {
  border-inline-start-color: rgb(0 0 0 / 0) !important;
}

.tw-border-s-transparent\/10 {
  border-inline-start-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-s-transparent\/100 {
  border-inline-start-color: rgb(0 0 0 / 1) !important;
}

.tw-border-s-transparent\/15 {
  border-inline-start-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-s-transparent\/20 {
  border-inline-start-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-s-transparent\/25 {
  border-inline-start-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-s-transparent\/30 {
  border-inline-start-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-s-transparent\/35 {
  border-inline-start-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-s-transparent\/40 {
  border-inline-start-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-s-transparent\/45 {
  border-inline-start-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-s-transparent\/5 {
  border-inline-start-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-s-transparent\/50 {
  border-inline-start-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-s-transparent\/55 {
  border-inline-start-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-s-transparent\/60 {
  border-inline-start-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-s-transparent\/65 {
  border-inline-start-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-s-transparent\/70 {
  border-inline-start-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-s-transparent\/75 {
  border-inline-start-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-s-transparent\/80 {
  border-inline-start-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-s-transparent\/85 {
  border-inline-start-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-s-transparent\/90 {
  border-inline-start-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-s-transparent\/95 {
  border-inline-start-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-s-violet-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-100\/0 {
  border-inline-start-color: rgb(237 233 254 / 0) !important;
}

.tw-border-s-violet-100\/10 {
  border-inline-start-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-s-violet-100\/100 {
  border-inline-start-color: rgb(237 233 254 / 1) !important;
}

.tw-border-s-violet-100\/15 {
  border-inline-start-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-s-violet-100\/20 {
  border-inline-start-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-s-violet-100\/25 {
  border-inline-start-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-s-violet-100\/30 {
  border-inline-start-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-s-violet-100\/35 {
  border-inline-start-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-s-violet-100\/40 {
  border-inline-start-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-s-violet-100\/45 {
  border-inline-start-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-s-violet-100\/5 {
  border-inline-start-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-s-violet-100\/50 {
  border-inline-start-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-s-violet-100\/55 {
  border-inline-start-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-s-violet-100\/60 {
  border-inline-start-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-s-violet-100\/65 {
  border-inline-start-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-s-violet-100\/70 {
  border-inline-start-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-s-violet-100\/75 {
  border-inline-start-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-s-violet-100\/80 {
  border-inline-start-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-s-violet-100\/85 {
  border-inline-start-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-s-violet-100\/90 {
  border-inline-start-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-s-violet-100\/95 {
  border-inline-start-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-s-violet-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-200\/0 {
  border-inline-start-color: rgb(221 214 254 / 0) !important;
}

.tw-border-s-violet-200\/10 {
  border-inline-start-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-s-violet-200\/100 {
  border-inline-start-color: rgb(221 214 254 / 1) !important;
}

.tw-border-s-violet-200\/15 {
  border-inline-start-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-s-violet-200\/20 {
  border-inline-start-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-s-violet-200\/25 {
  border-inline-start-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-s-violet-200\/30 {
  border-inline-start-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-s-violet-200\/35 {
  border-inline-start-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-s-violet-200\/40 {
  border-inline-start-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-s-violet-200\/45 {
  border-inline-start-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-s-violet-200\/5 {
  border-inline-start-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-s-violet-200\/50 {
  border-inline-start-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-s-violet-200\/55 {
  border-inline-start-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-s-violet-200\/60 {
  border-inline-start-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-s-violet-200\/65 {
  border-inline-start-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-s-violet-200\/70 {
  border-inline-start-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-s-violet-200\/75 {
  border-inline-start-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-s-violet-200\/80 {
  border-inline-start-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-s-violet-200\/85 {
  border-inline-start-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-s-violet-200\/90 {
  border-inline-start-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-s-violet-200\/95 {
  border-inline-start-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-s-violet-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-300\/0 {
  border-inline-start-color: rgb(196 181 253 / 0) !important;
}

.tw-border-s-violet-300\/10 {
  border-inline-start-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-s-violet-300\/100 {
  border-inline-start-color: rgb(196 181 253 / 1) !important;
}

.tw-border-s-violet-300\/15 {
  border-inline-start-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-s-violet-300\/20 {
  border-inline-start-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-s-violet-300\/25 {
  border-inline-start-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-s-violet-300\/30 {
  border-inline-start-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-s-violet-300\/35 {
  border-inline-start-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-s-violet-300\/40 {
  border-inline-start-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-s-violet-300\/45 {
  border-inline-start-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-s-violet-300\/5 {
  border-inline-start-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-s-violet-300\/50 {
  border-inline-start-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-s-violet-300\/55 {
  border-inline-start-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-s-violet-300\/60 {
  border-inline-start-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-s-violet-300\/65 {
  border-inline-start-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-s-violet-300\/70 {
  border-inline-start-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-s-violet-300\/75 {
  border-inline-start-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-s-violet-300\/80 {
  border-inline-start-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-s-violet-300\/85 {
  border-inline-start-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-s-violet-300\/90 {
  border-inline-start-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-s-violet-300\/95 {
  border-inline-start-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-s-violet-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-400\/0 {
  border-inline-start-color: rgb(167 139 250 / 0) !important;
}

.tw-border-s-violet-400\/10 {
  border-inline-start-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-s-violet-400\/100 {
  border-inline-start-color: rgb(167 139 250 / 1) !important;
}

.tw-border-s-violet-400\/15 {
  border-inline-start-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-s-violet-400\/20 {
  border-inline-start-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-s-violet-400\/25 {
  border-inline-start-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-s-violet-400\/30 {
  border-inline-start-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-s-violet-400\/35 {
  border-inline-start-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-s-violet-400\/40 {
  border-inline-start-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-s-violet-400\/45 {
  border-inline-start-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-s-violet-400\/5 {
  border-inline-start-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-s-violet-400\/50 {
  border-inline-start-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-s-violet-400\/55 {
  border-inline-start-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-s-violet-400\/60 {
  border-inline-start-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-s-violet-400\/65 {
  border-inline-start-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-s-violet-400\/70 {
  border-inline-start-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-s-violet-400\/75 {
  border-inline-start-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-s-violet-400\/80 {
  border-inline-start-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-s-violet-400\/85 {
  border-inline-start-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-s-violet-400\/90 {
  border-inline-start-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-s-violet-400\/95 {
  border-inline-start-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-s-violet-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-50\/0 {
  border-inline-start-color: rgb(245 243 255 / 0) !important;
}

.tw-border-s-violet-50\/10 {
  border-inline-start-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-s-violet-50\/100 {
  border-inline-start-color: rgb(245 243 255 / 1) !important;
}

.tw-border-s-violet-50\/15 {
  border-inline-start-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-s-violet-50\/20 {
  border-inline-start-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-s-violet-50\/25 {
  border-inline-start-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-s-violet-50\/30 {
  border-inline-start-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-s-violet-50\/35 {
  border-inline-start-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-s-violet-50\/40 {
  border-inline-start-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-s-violet-50\/45 {
  border-inline-start-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-s-violet-50\/5 {
  border-inline-start-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-s-violet-50\/50 {
  border-inline-start-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-s-violet-50\/55 {
  border-inline-start-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-s-violet-50\/60 {
  border-inline-start-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-s-violet-50\/65 {
  border-inline-start-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-s-violet-50\/70 {
  border-inline-start-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-s-violet-50\/75 {
  border-inline-start-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-s-violet-50\/80 {
  border-inline-start-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-s-violet-50\/85 {
  border-inline-start-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-s-violet-50\/90 {
  border-inline-start-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-s-violet-50\/95 {
  border-inline-start-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-s-violet-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-500\/0 {
  border-inline-start-color: rgb(139 92 246 / 0) !important;
}

.tw-border-s-violet-500\/10 {
  border-inline-start-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-s-violet-500\/100 {
  border-inline-start-color: rgb(139 92 246 / 1) !important;
}

.tw-border-s-violet-500\/15 {
  border-inline-start-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-s-violet-500\/20 {
  border-inline-start-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-s-violet-500\/25 {
  border-inline-start-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-s-violet-500\/30 {
  border-inline-start-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-s-violet-500\/35 {
  border-inline-start-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-s-violet-500\/40 {
  border-inline-start-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-s-violet-500\/45 {
  border-inline-start-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-s-violet-500\/5 {
  border-inline-start-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-s-violet-500\/50 {
  border-inline-start-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-s-violet-500\/55 {
  border-inline-start-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-s-violet-500\/60 {
  border-inline-start-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-s-violet-500\/65 {
  border-inline-start-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-s-violet-500\/70 {
  border-inline-start-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-s-violet-500\/75 {
  border-inline-start-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-s-violet-500\/80 {
  border-inline-start-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-s-violet-500\/85 {
  border-inline-start-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-s-violet-500\/90 {
  border-inline-start-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-s-violet-500\/95 {
  border-inline-start-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-s-violet-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-600\/0 {
  border-inline-start-color: rgb(124 58 237 / 0) !important;
}

.tw-border-s-violet-600\/10 {
  border-inline-start-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-s-violet-600\/100 {
  border-inline-start-color: rgb(124 58 237 / 1) !important;
}

.tw-border-s-violet-600\/15 {
  border-inline-start-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-s-violet-600\/20 {
  border-inline-start-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-s-violet-600\/25 {
  border-inline-start-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-s-violet-600\/30 {
  border-inline-start-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-s-violet-600\/35 {
  border-inline-start-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-s-violet-600\/40 {
  border-inline-start-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-s-violet-600\/45 {
  border-inline-start-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-s-violet-600\/5 {
  border-inline-start-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-s-violet-600\/50 {
  border-inline-start-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-s-violet-600\/55 {
  border-inline-start-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-s-violet-600\/60 {
  border-inline-start-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-s-violet-600\/65 {
  border-inline-start-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-s-violet-600\/70 {
  border-inline-start-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-s-violet-600\/75 {
  border-inline-start-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-s-violet-600\/80 {
  border-inline-start-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-s-violet-600\/85 {
  border-inline-start-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-s-violet-600\/90 {
  border-inline-start-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-s-violet-600\/95 {
  border-inline-start-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-s-violet-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-700\/0 {
  border-inline-start-color: rgb(109 40 217 / 0) !important;
}

.tw-border-s-violet-700\/10 {
  border-inline-start-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-s-violet-700\/100 {
  border-inline-start-color: rgb(109 40 217 / 1) !important;
}

.tw-border-s-violet-700\/15 {
  border-inline-start-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-s-violet-700\/20 {
  border-inline-start-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-s-violet-700\/25 {
  border-inline-start-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-s-violet-700\/30 {
  border-inline-start-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-s-violet-700\/35 {
  border-inline-start-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-s-violet-700\/40 {
  border-inline-start-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-s-violet-700\/45 {
  border-inline-start-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-s-violet-700\/5 {
  border-inline-start-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-s-violet-700\/50 {
  border-inline-start-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-s-violet-700\/55 {
  border-inline-start-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-s-violet-700\/60 {
  border-inline-start-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-s-violet-700\/65 {
  border-inline-start-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-s-violet-700\/70 {
  border-inline-start-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-s-violet-700\/75 {
  border-inline-start-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-s-violet-700\/80 {
  border-inline-start-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-s-violet-700\/85 {
  border-inline-start-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-s-violet-700\/90 {
  border-inline-start-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-s-violet-700\/95 {
  border-inline-start-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-s-violet-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-800\/0 {
  border-inline-start-color: rgb(91 33 182 / 0) !important;
}

.tw-border-s-violet-800\/10 {
  border-inline-start-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-s-violet-800\/100 {
  border-inline-start-color: rgb(91 33 182 / 1) !important;
}

.tw-border-s-violet-800\/15 {
  border-inline-start-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-s-violet-800\/20 {
  border-inline-start-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-s-violet-800\/25 {
  border-inline-start-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-s-violet-800\/30 {
  border-inline-start-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-s-violet-800\/35 {
  border-inline-start-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-s-violet-800\/40 {
  border-inline-start-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-s-violet-800\/45 {
  border-inline-start-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-s-violet-800\/5 {
  border-inline-start-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-s-violet-800\/50 {
  border-inline-start-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-s-violet-800\/55 {
  border-inline-start-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-s-violet-800\/60 {
  border-inline-start-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-s-violet-800\/65 {
  border-inline-start-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-s-violet-800\/70 {
  border-inline-start-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-s-violet-800\/75 {
  border-inline-start-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-s-violet-800\/80 {
  border-inline-start-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-s-violet-800\/85 {
  border-inline-start-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-s-violet-800\/90 {
  border-inline-start-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-s-violet-800\/95 {
  border-inline-start-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-s-violet-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-900\/0 {
  border-inline-start-color: rgb(76 29 149 / 0) !important;
}

.tw-border-s-violet-900\/10 {
  border-inline-start-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-s-violet-900\/100 {
  border-inline-start-color: rgb(76 29 149 / 1) !important;
}

.tw-border-s-violet-900\/15 {
  border-inline-start-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-s-violet-900\/20 {
  border-inline-start-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-s-violet-900\/25 {
  border-inline-start-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-s-violet-900\/30 {
  border-inline-start-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-s-violet-900\/35 {
  border-inline-start-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-s-violet-900\/40 {
  border-inline-start-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-s-violet-900\/45 {
  border-inline-start-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-s-violet-900\/5 {
  border-inline-start-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-s-violet-900\/50 {
  border-inline-start-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-s-violet-900\/55 {
  border-inline-start-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-s-violet-900\/60 {
  border-inline-start-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-s-violet-900\/65 {
  border-inline-start-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-s-violet-900\/70 {
  border-inline-start-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-s-violet-900\/75 {
  border-inline-start-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-s-violet-900\/80 {
  border-inline-start-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-s-violet-900\/85 {
  border-inline-start-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-s-violet-900\/90 {
  border-inline-start-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-s-violet-900\/95 {
  border-inline-start-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-s-violet-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-s-violet-950\/0 {
  border-inline-start-color: rgb(46 16 101 / 0) !important;
}

.tw-border-s-violet-950\/10 {
  border-inline-start-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-s-violet-950\/100 {
  border-inline-start-color: rgb(46 16 101 / 1) !important;
}

.tw-border-s-violet-950\/15 {
  border-inline-start-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-s-violet-950\/20 {
  border-inline-start-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-s-violet-950\/25 {
  border-inline-start-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-s-violet-950\/30 {
  border-inline-start-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-s-violet-950\/35 {
  border-inline-start-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-s-violet-950\/40 {
  border-inline-start-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-s-violet-950\/45 {
  border-inline-start-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-s-violet-950\/5 {
  border-inline-start-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-s-violet-950\/50 {
  border-inline-start-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-s-violet-950\/55 {
  border-inline-start-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-s-violet-950\/60 {
  border-inline-start-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-s-violet-950\/65 {
  border-inline-start-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-s-violet-950\/70 {
  border-inline-start-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-s-violet-950\/75 {
  border-inline-start-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-s-violet-950\/80 {
  border-inline-start-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-s-violet-950\/85 {
  border-inline-start-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-s-violet-950\/90 {
  border-inline-start-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-s-violet-950\/95 {
  border-inline-start-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-s-white {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-s-white\/0 {
  border-inline-start-color: rgb(255 255 255 / 0) !important;
}

.tw-border-s-white\/10 {
  border-inline-start-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-s-white\/100 {
  border-inline-start-color: rgb(255 255 255 / 1) !important;
}

.tw-border-s-white\/15 {
  border-inline-start-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-s-white\/20 {
  border-inline-start-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-s-white\/25 {
  border-inline-start-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-s-white\/30 {
  border-inline-start-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-s-white\/35 {
  border-inline-start-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-s-white\/40 {
  border-inline-start-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-s-white\/45 {
  border-inline-start-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-s-white\/5 {
  border-inline-start-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-s-white\/50 {
  border-inline-start-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-s-white\/55 {
  border-inline-start-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-s-white\/60 {
  border-inline-start-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-s-white\/65 {
  border-inline-start-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-s-white\/70 {
  border-inline-start-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-s-white\/75 {
  border-inline-start-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-s-white\/80 {
  border-inline-start-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-s-white\/85 {
  border-inline-start-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-s-white\/90 {
  border-inline-start-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-s-white\/95 {
  border-inline-start-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-s-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-100\/0 {
  border-inline-start-color: rgb(254 249 195 / 0) !important;
}

.tw-border-s-yellow-100\/10 {
  border-inline-start-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-s-yellow-100\/100 {
  border-inline-start-color: rgb(254 249 195 / 1) !important;
}

.tw-border-s-yellow-100\/15 {
  border-inline-start-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-s-yellow-100\/20 {
  border-inline-start-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-s-yellow-100\/25 {
  border-inline-start-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-s-yellow-100\/30 {
  border-inline-start-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-s-yellow-100\/35 {
  border-inline-start-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-s-yellow-100\/40 {
  border-inline-start-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-s-yellow-100\/45 {
  border-inline-start-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-s-yellow-100\/5 {
  border-inline-start-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-s-yellow-100\/50 {
  border-inline-start-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-s-yellow-100\/55 {
  border-inline-start-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-s-yellow-100\/60 {
  border-inline-start-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-s-yellow-100\/65 {
  border-inline-start-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-s-yellow-100\/70 {
  border-inline-start-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-s-yellow-100\/75 {
  border-inline-start-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-s-yellow-100\/80 {
  border-inline-start-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-s-yellow-100\/85 {
  border-inline-start-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-s-yellow-100\/90 {
  border-inline-start-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-s-yellow-100\/95 {
  border-inline-start-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-s-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-200\/0 {
  border-inline-start-color: rgb(254 240 138 / 0) !important;
}

.tw-border-s-yellow-200\/10 {
  border-inline-start-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-s-yellow-200\/100 {
  border-inline-start-color: rgb(254 240 138 / 1) !important;
}

.tw-border-s-yellow-200\/15 {
  border-inline-start-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-s-yellow-200\/20 {
  border-inline-start-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-s-yellow-200\/25 {
  border-inline-start-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-s-yellow-200\/30 {
  border-inline-start-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-s-yellow-200\/35 {
  border-inline-start-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-s-yellow-200\/40 {
  border-inline-start-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-s-yellow-200\/45 {
  border-inline-start-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-s-yellow-200\/5 {
  border-inline-start-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-s-yellow-200\/50 {
  border-inline-start-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-s-yellow-200\/55 {
  border-inline-start-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-s-yellow-200\/60 {
  border-inline-start-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-s-yellow-200\/65 {
  border-inline-start-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-s-yellow-200\/70 {
  border-inline-start-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-s-yellow-200\/75 {
  border-inline-start-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-s-yellow-200\/80 {
  border-inline-start-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-s-yellow-200\/85 {
  border-inline-start-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-s-yellow-200\/90 {
  border-inline-start-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-s-yellow-200\/95 {
  border-inline-start-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-s-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-300\/0 {
  border-inline-start-color: rgb(253 224 71 / 0) !important;
}

.tw-border-s-yellow-300\/10 {
  border-inline-start-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-s-yellow-300\/100 {
  border-inline-start-color: rgb(253 224 71 / 1) !important;
}

.tw-border-s-yellow-300\/15 {
  border-inline-start-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-s-yellow-300\/20 {
  border-inline-start-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-s-yellow-300\/25 {
  border-inline-start-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-s-yellow-300\/30 {
  border-inline-start-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-s-yellow-300\/35 {
  border-inline-start-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-s-yellow-300\/40 {
  border-inline-start-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-s-yellow-300\/45 {
  border-inline-start-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-s-yellow-300\/5 {
  border-inline-start-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-s-yellow-300\/50 {
  border-inline-start-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-s-yellow-300\/55 {
  border-inline-start-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-s-yellow-300\/60 {
  border-inline-start-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-s-yellow-300\/65 {
  border-inline-start-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-s-yellow-300\/70 {
  border-inline-start-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-s-yellow-300\/75 {
  border-inline-start-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-s-yellow-300\/80 {
  border-inline-start-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-s-yellow-300\/85 {
  border-inline-start-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-s-yellow-300\/90 {
  border-inline-start-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-s-yellow-300\/95 {
  border-inline-start-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-s-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-400\/0 {
  border-inline-start-color: rgb(250 204 21 / 0) !important;
}

.tw-border-s-yellow-400\/10 {
  border-inline-start-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-s-yellow-400\/100 {
  border-inline-start-color: rgb(250 204 21 / 1) !important;
}

.tw-border-s-yellow-400\/15 {
  border-inline-start-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-s-yellow-400\/20 {
  border-inline-start-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-s-yellow-400\/25 {
  border-inline-start-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-s-yellow-400\/30 {
  border-inline-start-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-s-yellow-400\/35 {
  border-inline-start-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-s-yellow-400\/40 {
  border-inline-start-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-s-yellow-400\/45 {
  border-inline-start-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-s-yellow-400\/5 {
  border-inline-start-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-s-yellow-400\/50 {
  border-inline-start-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-s-yellow-400\/55 {
  border-inline-start-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-s-yellow-400\/60 {
  border-inline-start-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-s-yellow-400\/65 {
  border-inline-start-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-s-yellow-400\/70 {
  border-inline-start-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-s-yellow-400\/75 {
  border-inline-start-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-s-yellow-400\/80 {
  border-inline-start-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-s-yellow-400\/85 {
  border-inline-start-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-s-yellow-400\/90 {
  border-inline-start-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-s-yellow-400\/95 {
  border-inline-start-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-s-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-50\/0 {
  border-inline-start-color: rgb(254 252 232 / 0) !important;
}

.tw-border-s-yellow-50\/10 {
  border-inline-start-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-s-yellow-50\/100 {
  border-inline-start-color: rgb(254 252 232 / 1) !important;
}

.tw-border-s-yellow-50\/15 {
  border-inline-start-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-s-yellow-50\/20 {
  border-inline-start-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-s-yellow-50\/25 {
  border-inline-start-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-s-yellow-50\/30 {
  border-inline-start-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-s-yellow-50\/35 {
  border-inline-start-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-s-yellow-50\/40 {
  border-inline-start-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-s-yellow-50\/45 {
  border-inline-start-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-s-yellow-50\/5 {
  border-inline-start-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-s-yellow-50\/50 {
  border-inline-start-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-s-yellow-50\/55 {
  border-inline-start-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-s-yellow-50\/60 {
  border-inline-start-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-s-yellow-50\/65 {
  border-inline-start-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-s-yellow-50\/70 {
  border-inline-start-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-s-yellow-50\/75 {
  border-inline-start-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-s-yellow-50\/80 {
  border-inline-start-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-s-yellow-50\/85 {
  border-inline-start-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-s-yellow-50\/90 {
  border-inline-start-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-s-yellow-50\/95 {
  border-inline-start-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-s-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-500\/0 {
  border-inline-start-color: rgb(234 179 8 / 0) !important;
}

.tw-border-s-yellow-500\/10 {
  border-inline-start-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-s-yellow-500\/100 {
  border-inline-start-color: rgb(234 179 8 / 1) !important;
}

.tw-border-s-yellow-500\/15 {
  border-inline-start-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-s-yellow-500\/20 {
  border-inline-start-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-s-yellow-500\/25 {
  border-inline-start-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-s-yellow-500\/30 {
  border-inline-start-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-s-yellow-500\/35 {
  border-inline-start-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-s-yellow-500\/40 {
  border-inline-start-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-s-yellow-500\/45 {
  border-inline-start-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-s-yellow-500\/5 {
  border-inline-start-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-s-yellow-500\/50 {
  border-inline-start-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-s-yellow-500\/55 {
  border-inline-start-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-s-yellow-500\/60 {
  border-inline-start-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-s-yellow-500\/65 {
  border-inline-start-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-s-yellow-500\/70 {
  border-inline-start-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-s-yellow-500\/75 {
  border-inline-start-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-s-yellow-500\/80 {
  border-inline-start-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-s-yellow-500\/85 {
  border-inline-start-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-s-yellow-500\/90 {
  border-inline-start-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-s-yellow-500\/95 {
  border-inline-start-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-s-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-600\/0 {
  border-inline-start-color: rgb(202 138 4 / 0) !important;
}

.tw-border-s-yellow-600\/10 {
  border-inline-start-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-s-yellow-600\/100 {
  border-inline-start-color: rgb(202 138 4 / 1) !important;
}

.tw-border-s-yellow-600\/15 {
  border-inline-start-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-s-yellow-600\/20 {
  border-inline-start-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-s-yellow-600\/25 {
  border-inline-start-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-s-yellow-600\/30 {
  border-inline-start-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-s-yellow-600\/35 {
  border-inline-start-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-s-yellow-600\/40 {
  border-inline-start-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-s-yellow-600\/45 {
  border-inline-start-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-s-yellow-600\/5 {
  border-inline-start-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-s-yellow-600\/50 {
  border-inline-start-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-s-yellow-600\/55 {
  border-inline-start-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-s-yellow-600\/60 {
  border-inline-start-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-s-yellow-600\/65 {
  border-inline-start-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-s-yellow-600\/70 {
  border-inline-start-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-s-yellow-600\/75 {
  border-inline-start-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-s-yellow-600\/80 {
  border-inline-start-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-s-yellow-600\/85 {
  border-inline-start-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-s-yellow-600\/90 {
  border-inline-start-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-s-yellow-600\/95 {
  border-inline-start-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-s-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-700\/0 {
  border-inline-start-color: rgb(161 98 7 / 0) !important;
}

.tw-border-s-yellow-700\/10 {
  border-inline-start-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-s-yellow-700\/100 {
  border-inline-start-color: rgb(161 98 7 / 1) !important;
}

.tw-border-s-yellow-700\/15 {
  border-inline-start-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-s-yellow-700\/20 {
  border-inline-start-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-s-yellow-700\/25 {
  border-inline-start-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-s-yellow-700\/30 {
  border-inline-start-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-s-yellow-700\/35 {
  border-inline-start-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-s-yellow-700\/40 {
  border-inline-start-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-s-yellow-700\/45 {
  border-inline-start-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-s-yellow-700\/5 {
  border-inline-start-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-s-yellow-700\/50 {
  border-inline-start-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-s-yellow-700\/55 {
  border-inline-start-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-s-yellow-700\/60 {
  border-inline-start-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-s-yellow-700\/65 {
  border-inline-start-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-s-yellow-700\/70 {
  border-inline-start-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-s-yellow-700\/75 {
  border-inline-start-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-s-yellow-700\/80 {
  border-inline-start-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-s-yellow-700\/85 {
  border-inline-start-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-s-yellow-700\/90 {
  border-inline-start-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-s-yellow-700\/95 {
  border-inline-start-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-s-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-800\/0 {
  border-inline-start-color: rgb(133 77 14 / 0) !important;
}

.tw-border-s-yellow-800\/10 {
  border-inline-start-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-s-yellow-800\/100 {
  border-inline-start-color: rgb(133 77 14 / 1) !important;
}

.tw-border-s-yellow-800\/15 {
  border-inline-start-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-s-yellow-800\/20 {
  border-inline-start-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-s-yellow-800\/25 {
  border-inline-start-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-s-yellow-800\/30 {
  border-inline-start-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-s-yellow-800\/35 {
  border-inline-start-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-s-yellow-800\/40 {
  border-inline-start-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-s-yellow-800\/45 {
  border-inline-start-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-s-yellow-800\/5 {
  border-inline-start-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-s-yellow-800\/50 {
  border-inline-start-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-s-yellow-800\/55 {
  border-inline-start-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-s-yellow-800\/60 {
  border-inline-start-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-s-yellow-800\/65 {
  border-inline-start-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-s-yellow-800\/70 {
  border-inline-start-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-s-yellow-800\/75 {
  border-inline-start-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-s-yellow-800\/80 {
  border-inline-start-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-s-yellow-800\/85 {
  border-inline-start-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-s-yellow-800\/90 {
  border-inline-start-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-s-yellow-800\/95 {
  border-inline-start-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-s-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-900\/0 {
  border-inline-start-color: rgb(113 63 18 / 0) !important;
}

.tw-border-s-yellow-900\/10 {
  border-inline-start-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-s-yellow-900\/100 {
  border-inline-start-color: rgb(113 63 18 / 1) !important;
}

.tw-border-s-yellow-900\/15 {
  border-inline-start-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-s-yellow-900\/20 {
  border-inline-start-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-s-yellow-900\/25 {
  border-inline-start-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-s-yellow-900\/30 {
  border-inline-start-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-s-yellow-900\/35 {
  border-inline-start-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-s-yellow-900\/40 {
  border-inline-start-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-s-yellow-900\/45 {
  border-inline-start-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-s-yellow-900\/5 {
  border-inline-start-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-s-yellow-900\/50 {
  border-inline-start-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-s-yellow-900\/55 {
  border-inline-start-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-s-yellow-900\/60 {
  border-inline-start-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-s-yellow-900\/65 {
  border-inline-start-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-s-yellow-900\/70 {
  border-inline-start-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-s-yellow-900\/75 {
  border-inline-start-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-s-yellow-900\/80 {
  border-inline-start-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-s-yellow-900\/85 {
  border-inline-start-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-s-yellow-900\/90 {
  border-inline-start-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-s-yellow-900\/95 {
  border-inline-start-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-s-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-s-yellow-950\/0 {
  border-inline-start-color: rgb(66 32 6 / 0) !important;
}

.tw-border-s-yellow-950\/10 {
  border-inline-start-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-s-yellow-950\/100 {
  border-inline-start-color: rgb(66 32 6 / 1) !important;
}

.tw-border-s-yellow-950\/15 {
  border-inline-start-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-s-yellow-950\/20 {
  border-inline-start-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-s-yellow-950\/25 {
  border-inline-start-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-s-yellow-950\/30 {
  border-inline-start-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-s-yellow-950\/35 {
  border-inline-start-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-s-yellow-950\/40 {
  border-inline-start-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-s-yellow-950\/45 {
  border-inline-start-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-s-yellow-950\/5 {
  border-inline-start-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-s-yellow-950\/50 {
  border-inline-start-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-s-yellow-950\/55 {
  border-inline-start-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-s-yellow-950\/60 {
  border-inline-start-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-s-yellow-950\/65 {
  border-inline-start-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-s-yellow-950\/70 {
  border-inline-start-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-s-yellow-950\/75 {
  border-inline-start-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-s-yellow-950\/80 {
  border-inline-start-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-s-yellow-950\/85 {
  border-inline-start-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-s-yellow-950\/90 {
  border-inline-start-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-s-yellow-950\/95 {
  border-inline-start-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-s-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-100\/0 {
  border-inline-start-color: rgb(244 244 245 / 0) !important;
}

.tw-border-s-zinc-100\/10 {
  border-inline-start-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-s-zinc-100\/100 {
  border-inline-start-color: rgb(244 244 245 / 1) !important;
}

.tw-border-s-zinc-100\/15 {
  border-inline-start-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-s-zinc-100\/20 {
  border-inline-start-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-s-zinc-100\/25 {
  border-inline-start-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-s-zinc-100\/30 {
  border-inline-start-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-s-zinc-100\/35 {
  border-inline-start-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-s-zinc-100\/40 {
  border-inline-start-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-s-zinc-100\/45 {
  border-inline-start-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-s-zinc-100\/5 {
  border-inline-start-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-s-zinc-100\/50 {
  border-inline-start-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-s-zinc-100\/55 {
  border-inline-start-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-s-zinc-100\/60 {
  border-inline-start-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-s-zinc-100\/65 {
  border-inline-start-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-s-zinc-100\/70 {
  border-inline-start-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-s-zinc-100\/75 {
  border-inline-start-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-s-zinc-100\/80 {
  border-inline-start-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-s-zinc-100\/85 {
  border-inline-start-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-s-zinc-100\/90 {
  border-inline-start-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-s-zinc-100\/95 {
  border-inline-start-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-s-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-200\/0 {
  border-inline-start-color: rgb(228 228 231 / 0) !important;
}

.tw-border-s-zinc-200\/10 {
  border-inline-start-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-s-zinc-200\/100 {
  border-inline-start-color: rgb(228 228 231 / 1) !important;
}

.tw-border-s-zinc-200\/15 {
  border-inline-start-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-s-zinc-200\/20 {
  border-inline-start-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-s-zinc-200\/25 {
  border-inline-start-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-s-zinc-200\/30 {
  border-inline-start-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-s-zinc-200\/35 {
  border-inline-start-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-s-zinc-200\/40 {
  border-inline-start-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-s-zinc-200\/45 {
  border-inline-start-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-s-zinc-200\/5 {
  border-inline-start-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-s-zinc-200\/50 {
  border-inline-start-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-s-zinc-200\/55 {
  border-inline-start-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-s-zinc-200\/60 {
  border-inline-start-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-s-zinc-200\/65 {
  border-inline-start-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-s-zinc-200\/70 {
  border-inline-start-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-s-zinc-200\/75 {
  border-inline-start-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-s-zinc-200\/80 {
  border-inline-start-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-s-zinc-200\/85 {
  border-inline-start-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-s-zinc-200\/90 {
  border-inline-start-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-s-zinc-200\/95 {
  border-inline-start-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-s-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-300\/0 {
  border-inline-start-color: rgb(212 212 216 / 0) !important;
}

.tw-border-s-zinc-300\/10 {
  border-inline-start-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-s-zinc-300\/100 {
  border-inline-start-color: rgb(212 212 216 / 1) !important;
}

.tw-border-s-zinc-300\/15 {
  border-inline-start-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-s-zinc-300\/20 {
  border-inline-start-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-s-zinc-300\/25 {
  border-inline-start-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-s-zinc-300\/30 {
  border-inline-start-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-s-zinc-300\/35 {
  border-inline-start-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-s-zinc-300\/40 {
  border-inline-start-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-s-zinc-300\/45 {
  border-inline-start-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-s-zinc-300\/5 {
  border-inline-start-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-s-zinc-300\/50 {
  border-inline-start-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-s-zinc-300\/55 {
  border-inline-start-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-s-zinc-300\/60 {
  border-inline-start-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-s-zinc-300\/65 {
  border-inline-start-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-s-zinc-300\/70 {
  border-inline-start-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-s-zinc-300\/75 {
  border-inline-start-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-s-zinc-300\/80 {
  border-inline-start-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-s-zinc-300\/85 {
  border-inline-start-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-s-zinc-300\/90 {
  border-inline-start-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-s-zinc-300\/95 {
  border-inline-start-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-s-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-400\/0 {
  border-inline-start-color: rgb(161 161 170 / 0) !important;
}

.tw-border-s-zinc-400\/10 {
  border-inline-start-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-s-zinc-400\/100 {
  border-inline-start-color: rgb(161 161 170 / 1) !important;
}

.tw-border-s-zinc-400\/15 {
  border-inline-start-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-s-zinc-400\/20 {
  border-inline-start-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-s-zinc-400\/25 {
  border-inline-start-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-s-zinc-400\/30 {
  border-inline-start-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-s-zinc-400\/35 {
  border-inline-start-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-s-zinc-400\/40 {
  border-inline-start-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-s-zinc-400\/45 {
  border-inline-start-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-s-zinc-400\/5 {
  border-inline-start-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-s-zinc-400\/50 {
  border-inline-start-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-s-zinc-400\/55 {
  border-inline-start-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-s-zinc-400\/60 {
  border-inline-start-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-s-zinc-400\/65 {
  border-inline-start-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-s-zinc-400\/70 {
  border-inline-start-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-s-zinc-400\/75 {
  border-inline-start-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-s-zinc-400\/80 {
  border-inline-start-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-s-zinc-400\/85 {
  border-inline-start-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-s-zinc-400\/90 {
  border-inline-start-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-s-zinc-400\/95 {
  border-inline-start-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-s-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-50\/0 {
  border-inline-start-color: rgb(250 250 250 / 0) !important;
}

.tw-border-s-zinc-50\/10 {
  border-inline-start-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-s-zinc-50\/100 {
  border-inline-start-color: rgb(250 250 250 / 1) !important;
}

.tw-border-s-zinc-50\/15 {
  border-inline-start-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-s-zinc-50\/20 {
  border-inline-start-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-s-zinc-50\/25 {
  border-inline-start-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-s-zinc-50\/30 {
  border-inline-start-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-s-zinc-50\/35 {
  border-inline-start-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-s-zinc-50\/40 {
  border-inline-start-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-s-zinc-50\/45 {
  border-inline-start-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-s-zinc-50\/5 {
  border-inline-start-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-s-zinc-50\/50 {
  border-inline-start-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-s-zinc-50\/55 {
  border-inline-start-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-s-zinc-50\/60 {
  border-inline-start-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-s-zinc-50\/65 {
  border-inline-start-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-s-zinc-50\/70 {
  border-inline-start-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-s-zinc-50\/75 {
  border-inline-start-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-s-zinc-50\/80 {
  border-inline-start-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-s-zinc-50\/85 {
  border-inline-start-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-s-zinc-50\/90 {
  border-inline-start-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-s-zinc-50\/95 {
  border-inline-start-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-s-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-500\/0 {
  border-inline-start-color: rgb(113 113 122 / 0) !important;
}

.tw-border-s-zinc-500\/10 {
  border-inline-start-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-s-zinc-500\/100 {
  border-inline-start-color: rgb(113 113 122 / 1) !important;
}

.tw-border-s-zinc-500\/15 {
  border-inline-start-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-s-zinc-500\/20 {
  border-inline-start-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-s-zinc-500\/25 {
  border-inline-start-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-s-zinc-500\/30 {
  border-inline-start-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-s-zinc-500\/35 {
  border-inline-start-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-s-zinc-500\/40 {
  border-inline-start-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-s-zinc-500\/45 {
  border-inline-start-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-s-zinc-500\/5 {
  border-inline-start-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-s-zinc-500\/50 {
  border-inline-start-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-s-zinc-500\/55 {
  border-inline-start-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-s-zinc-500\/60 {
  border-inline-start-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-s-zinc-500\/65 {
  border-inline-start-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-s-zinc-500\/70 {
  border-inline-start-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-s-zinc-500\/75 {
  border-inline-start-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-s-zinc-500\/80 {
  border-inline-start-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-s-zinc-500\/85 {
  border-inline-start-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-s-zinc-500\/90 {
  border-inline-start-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-s-zinc-500\/95 {
  border-inline-start-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-s-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-600\/0 {
  border-inline-start-color: rgb(82 82 91 / 0) !important;
}

.tw-border-s-zinc-600\/10 {
  border-inline-start-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-s-zinc-600\/100 {
  border-inline-start-color: rgb(82 82 91 / 1) !important;
}

.tw-border-s-zinc-600\/15 {
  border-inline-start-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-s-zinc-600\/20 {
  border-inline-start-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-s-zinc-600\/25 {
  border-inline-start-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-s-zinc-600\/30 {
  border-inline-start-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-s-zinc-600\/35 {
  border-inline-start-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-s-zinc-600\/40 {
  border-inline-start-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-s-zinc-600\/45 {
  border-inline-start-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-s-zinc-600\/5 {
  border-inline-start-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-s-zinc-600\/50 {
  border-inline-start-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-s-zinc-600\/55 {
  border-inline-start-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-s-zinc-600\/60 {
  border-inline-start-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-s-zinc-600\/65 {
  border-inline-start-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-s-zinc-600\/70 {
  border-inline-start-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-s-zinc-600\/75 {
  border-inline-start-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-s-zinc-600\/80 {
  border-inline-start-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-s-zinc-600\/85 {
  border-inline-start-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-s-zinc-600\/90 {
  border-inline-start-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-s-zinc-600\/95 {
  border-inline-start-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-s-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-700\/0 {
  border-inline-start-color: rgb(63 63 70 / 0) !important;
}

.tw-border-s-zinc-700\/10 {
  border-inline-start-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-s-zinc-700\/100 {
  border-inline-start-color: rgb(63 63 70 / 1) !important;
}

.tw-border-s-zinc-700\/15 {
  border-inline-start-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-s-zinc-700\/20 {
  border-inline-start-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-s-zinc-700\/25 {
  border-inline-start-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-s-zinc-700\/30 {
  border-inline-start-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-s-zinc-700\/35 {
  border-inline-start-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-s-zinc-700\/40 {
  border-inline-start-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-s-zinc-700\/45 {
  border-inline-start-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-s-zinc-700\/5 {
  border-inline-start-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-s-zinc-700\/50 {
  border-inline-start-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-s-zinc-700\/55 {
  border-inline-start-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-s-zinc-700\/60 {
  border-inline-start-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-s-zinc-700\/65 {
  border-inline-start-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-s-zinc-700\/70 {
  border-inline-start-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-s-zinc-700\/75 {
  border-inline-start-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-s-zinc-700\/80 {
  border-inline-start-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-s-zinc-700\/85 {
  border-inline-start-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-s-zinc-700\/90 {
  border-inline-start-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-s-zinc-700\/95 {
  border-inline-start-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-s-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-800\/0 {
  border-inline-start-color: rgb(39 39 42 / 0) !important;
}

.tw-border-s-zinc-800\/10 {
  border-inline-start-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-s-zinc-800\/100 {
  border-inline-start-color: rgb(39 39 42 / 1) !important;
}

.tw-border-s-zinc-800\/15 {
  border-inline-start-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-s-zinc-800\/20 {
  border-inline-start-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-s-zinc-800\/25 {
  border-inline-start-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-s-zinc-800\/30 {
  border-inline-start-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-s-zinc-800\/35 {
  border-inline-start-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-s-zinc-800\/40 {
  border-inline-start-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-s-zinc-800\/45 {
  border-inline-start-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-s-zinc-800\/5 {
  border-inline-start-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-s-zinc-800\/50 {
  border-inline-start-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-s-zinc-800\/55 {
  border-inline-start-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-s-zinc-800\/60 {
  border-inline-start-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-s-zinc-800\/65 {
  border-inline-start-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-s-zinc-800\/70 {
  border-inline-start-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-s-zinc-800\/75 {
  border-inline-start-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-s-zinc-800\/80 {
  border-inline-start-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-s-zinc-800\/85 {
  border-inline-start-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-s-zinc-800\/90 {
  border-inline-start-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-s-zinc-800\/95 {
  border-inline-start-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-s-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-900\/0 {
  border-inline-start-color: rgb(24 24 27 / 0) !important;
}

.tw-border-s-zinc-900\/10 {
  border-inline-start-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-s-zinc-900\/100 {
  border-inline-start-color: rgb(24 24 27 / 1) !important;
}

.tw-border-s-zinc-900\/15 {
  border-inline-start-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-s-zinc-900\/20 {
  border-inline-start-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-s-zinc-900\/25 {
  border-inline-start-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-s-zinc-900\/30 {
  border-inline-start-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-s-zinc-900\/35 {
  border-inline-start-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-s-zinc-900\/40 {
  border-inline-start-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-s-zinc-900\/45 {
  border-inline-start-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-s-zinc-900\/5 {
  border-inline-start-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-s-zinc-900\/50 {
  border-inline-start-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-s-zinc-900\/55 {
  border-inline-start-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-s-zinc-900\/60 {
  border-inline-start-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-s-zinc-900\/65 {
  border-inline-start-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-s-zinc-900\/70 {
  border-inline-start-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-s-zinc-900\/75 {
  border-inline-start-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-s-zinc-900\/80 {
  border-inline-start-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-s-zinc-900\/85 {
  border-inline-start-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-s-zinc-900\/90 {
  border-inline-start-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-s-zinc-900\/95 {
  border-inline-start-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-s-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-inline-start-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-s-zinc-950\/0 {
  border-inline-start-color: rgb(9 9 11 / 0) !important;
}

.tw-border-s-zinc-950\/10 {
  border-inline-start-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-s-zinc-950\/100 {
  border-inline-start-color: rgb(9 9 11 / 1) !important;
}

.tw-border-s-zinc-950\/15 {
  border-inline-start-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-s-zinc-950\/20 {
  border-inline-start-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-s-zinc-950\/25 {
  border-inline-start-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-s-zinc-950\/30 {
  border-inline-start-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-s-zinc-950\/35 {
  border-inline-start-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-s-zinc-950\/40 {
  border-inline-start-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-s-zinc-950\/45 {
  border-inline-start-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-s-zinc-950\/5 {
  border-inline-start-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-s-zinc-950\/50 {
  border-inline-start-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-s-zinc-950\/55 {
  border-inline-start-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-s-zinc-950\/60 {
  border-inline-start-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-s-zinc-950\/65 {
  border-inline-start-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-s-zinc-950\/70 {
  border-inline-start-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-s-zinc-950\/75 {
  border-inline-start-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-s-zinc-950\/80 {
  border-inline-start-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-s-zinc-950\/85 {
  border-inline-start-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-s-zinc-950\/90 {
  border-inline-start-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-s-zinc-950\/95 {
  border-inline-start-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-t-amber-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 243 199 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-100\/0 {
  border-top-color: rgb(254 243 199 / 0) !important;
}

.tw-border-t-amber-100\/10 {
  border-top-color: rgb(254 243 199 / 0.1) !important;
}

.tw-border-t-amber-100\/100 {
  border-top-color: rgb(254 243 199 / 1) !important;
}

.tw-border-t-amber-100\/15 {
  border-top-color: rgb(254 243 199 / 0.15) !important;
}

.tw-border-t-amber-100\/20 {
  border-top-color: rgb(254 243 199 / 0.2) !important;
}

.tw-border-t-amber-100\/25 {
  border-top-color: rgb(254 243 199 / 0.25) !important;
}

.tw-border-t-amber-100\/30 {
  border-top-color: rgb(254 243 199 / 0.3) !important;
}

.tw-border-t-amber-100\/35 {
  border-top-color: rgb(254 243 199 / 0.35) !important;
}

.tw-border-t-amber-100\/40 {
  border-top-color: rgb(254 243 199 / 0.4) !important;
}

.tw-border-t-amber-100\/45 {
  border-top-color: rgb(254 243 199 / 0.45) !important;
}

.tw-border-t-amber-100\/5 {
  border-top-color: rgb(254 243 199 / 0.05) !important;
}

.tw-border-t-amber-100\/50 {
  border-top-color: rgb(254 243 199 / 0.5) !important;
}

.tw-border-t-amber-100\/55 {
  border-top-color: rgb(254 243 199 / 0.55) !important;
}

.tw-border-t-amber-100\/60 {
  border-top-color: rgb(254 243 199 / 0.6) !important;
}

.tw-border-t-amber-100\/65 {
  border-top-color: rgb(254 243 199 / 0.65) !important;
}

.tw-border-t-amber-100\/70 {
  border-top-color: rgb(254 243 199 / 0.7) !important;
}

.tw-border-t-amber-100\/75 {
  border-top-color: rgb(254 243 199 / 0.75) !important;
}

.tw-border-t-amber-100\/80 {
  border-top-color: rgb(254 243 199 / 0.8) !important;
}

.tw-border-t-amber-100\/85 {
  border-top-color: rgb(254 243 199 / 0.85) !important;
}

.tw-border-t-amber-100\/90 {
  border-top-color: rgb(254 243 199 / 0.9) !important;
}

.tw-border-t-amber-100\/95 {
  border-top-color: rgb(254 243 199 / 0.95) !important;
}

.tw-border-t-amber-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 230 138 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-200\/0 {
  border-top-color: rgb(253 230 138 / 0) !important;
}

.tw-border-t-amber-200\/10 {
  border-top-color: rgb(253 230 138 / 0.1) !important;
}

.tw-border-t-amber-200\/100 {
  border-top-color: rgb(253 230 138 / 1) !important;
}

.tw-border-t-amber-200\/15 {
  border-top-color: rgb(253 230 138 / 0.15) !important;
}

.tw-border-t-amber-200\/20 {
  border-top-color: rgb(253 230 138 / 0.2) !important;
}

.tw-border-t-amber-200\/25 {
  border-top-color: rgb(253 230 138 / 0.25) !important;
}

.tw-border-t-amber-200\/30 {
  border-top-color: rgb(253 230 138 / 0.3) !important;
}

.tw-border-t-amber-200\/35 {
  border-top-color: rgb(253 230 138 / 0.35) !important;
}

.tw-border-t-amber-200\/40 {
  border-top-color: rgb(253 230 138 / 0.4) !important;
}

.tw-border-t-amber-200\/45 {
  border-top-color: rgb(253 230 138 / 0.45) !important;
}

.tw-border-t-amber-200\/5 {
  border-top-color: rgb(253 230 138 / 0.05) !important;
}

.tw-border-t-amber-200\/50 {
  border-top-color: rgb(253 230 138 / 0.5) !important;
}

.tw-border-t-amber-200\/55 {
  border-top-color: rgb(253 230 138 / 0.55) !important;
}

.tw-border-t-amber-200\/60 {
  border-top-color: rgb(253 230 138 / 0.6) !important;
}

.tw-border-t-amber-200\/65 {
  border-top-color: rgb(253 230 138 / 0.65) !important;
}

.tw-border-t-amber-200\/70 {
  border-top-color: rgb(253 230 138 / 0.7) !important;
}

.tw-border-t-amber-200\/75 {
  border-top-color: rgb(253 230 138 / 0.75) !important;
}

.tw-border-t-amber-200\/80 {
  border-top-color: rgb(253 230 138 / 0.8) !important;
}

.tw-border-t-amber-200\/85 {
  border-top-color: rgb(253 230 138 / 0.85) !important;
}

.tw-border-t-amber-200\/90 {
  border-top-color: rgb(253 230 138 / 0.9) !important;
}

.tw-border-t-amber-200\/95 {
  border-top-color: rgb(253 230 138 / 0.95) !important;
}

.tw-border-t-amber-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(252 211 77 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-300\/0 {
  border-top-color: rgb(252 211 77 / 0) !important;
}

.tw-border-t-amber-300\/10 {
  border-top-color: rgb(252 211 77 / 0.1) !important;
}

.tw-border-t-amber-300\/100 {
  border-top-color: rgb(252 211 77 / 1) !important;
}

.tw-border-t-amber-300\/15 {
  border-top-color: rgb(252 211 77 / 0.15) !important;
}

.tw-border-t-amber-300\/20 {
  border-top-color: rgb(252 211 77 / 0.2) !important;
}

.tw-border-t-amber-300\/25 {
  border-top-color: rgb(252 211 77 / 0.25) !important;
}

.tw-border-t-amber-300\/30 {
  border-top-color: rgb(252 211 77 / 0.3) !important;
}

.tw-border-t-amber-300\/35 {
  border-top-color: rgb(252 211 77 / 0.35) !important;
}

.tw-border-t-amber-300\/40 {
  border-top-color: rgb(252 211 77 / 0.4) !important;
}

.tw-border-t-amber-300\/45 {
  border-top-color: rgb(252 211 77 / 0.45) !important;
}

.tw-border-t-amber-300\/5 {
  border-top-color: rgb(252 211 77 / 0.05) !important;
}

.tw-border-t-amber-300\/50 {
  border-top-color: rgb(252 211 77 / 0.5) !important;
}

.tw-border-t-amber-300\/55 {
  border-top-color: rgb(252 211 77 / 0.55) !important;
}

.tw-border-t-amber-300\/60 {
  border-top-color: rgb(252 211 77 / 0.6) !important;
}

.tw-border-t-amber-300\/65 {
  border-top-color: rgb(252 211 77 / 0.65) !important;
}

.tw-border-t-amber-300\/70 {
  border-top-color: rgb(252 211 77 / 0.7) !important;
}

.tw-border-t-amber-300\/75 {
  border-top-color: rgb(252 211 77 / 0.75) !important;
}

.tw-border-t-amber-300\/80 {
  border-top-color: rgb(252 211 77 / 0.8) !important;
}

.tw-border-t-amber-300\/85 {
  border-top-color: rgb(252 211 77 / 0.85) !important;
}

.tw-border-t-amber-300\/90 {
  border-top-color: rgb(252 211 77 / 0.9) !important;
}

.tw-border-t-amber-300\/95 {
  border-top-color: rgb(252 211 77 / 0.95) !important;
}

.tw-border-t-amber-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 191 36 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-400\/0 {
  border-top-color: rgb(251 191 36 / 0) !important;
}

.tw-border-t-amber-400\/10 {
  border-top-color: rgb(251 191 36 / 0.1) !important;
}

.tw-border-t-amber-400\/100 {
  border-top-color: rgb(251 191 36 / 1) !important;
}

.tw-border-t-amber-400\/15 {
  border-top-color: rgb(251 191 36 / 0.15) !important;
}

.tw-border-t-amber-400\/20 {
  border-top-color: rgb(251 191 36 / 0.2) !important;
}

.tw-border-t-amber-400\/25 {
  border-top-color: rgb(251 191 36 / 0.25) !important;
}

.tw-border-t-amber-400\/30 {
  border-top-color: rgb(251 191 36 / 0.3) !important;
}

.tw-border-t-amber-400\/35 {
  border-top-color: rgb(251 191 36 / 0.35) !important;
}

.tw-border-t-amber-400\/40 {
  border-top-color: rgb(251 191 36 / 0.4) !important;
}

.tw-border-t-amber-400\/45 {
  border-top-color: rgb(251 191 36 / 0.45) !important;
}

.tw-border-t-amber-400\/5 {
  border-top-color: rgb(251 191 36 / 0.05) !important;
}

.tw-border-t-amber-400\/50 {
  border-top-color: rgb(251 191 36 / 0.5) !important;
}

.tw-border-t-amber-400\/55 {
  border-top-color: rgb(251 191 36 / 0.55) !important;
}

.tw-border-t-amber-400\/60 {
  border-top-color: rgb(251 191 36 / 0.6) !important;
}

.tw-border-t-amber-400\/65 {
  border-top-color: rgb(251 191 36 / 0.65) !important;
}

.tw-border-t-amber-400\/70 {
  border-top-color: rgb(251 191 36 / 0.7) !important;
}

.tw-border-t-amber-400\/75 {
  border-top-color: rgb(251 191 36 / 0.75) !important;
}

.tw-border-t-amber-400\/80 {
  border-top-color: rgb(251 191 36 / 0.8) !important;
}

.tw-border-t-amber-400\/85 {
  border-top-color: rgb(251 191 36 / 0.85) !important;
}

.tw-border-t-amber-400\/90 {
  border-top-color: rgb(251 191 36 / 0.9) !important;
}

.tw-border-t-amber-400\/95 {
  border-top-color: rgb(251 191 36 / 0.95) !important;
}

.tw-border-t-amber-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 251 235 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-50\/0 {
  border-top-color: rgb(255 251 235 / 0) !important;
}

.tw-border-t-amber-50\/10 {
  border-top-color: rgb(255 251 235 / 0.1) !important;
}

.tw-border-t-amber-50\/100 {
  border-top-color: rgb(255 251 235 / 1) !important;
}

.tw-border-t-amber-50\/15 {
  border-top-color: rgb(255 251 235 / 0.15) !important;
}

.tw-border-t-amber-50\/20 {
  border-top-color: rgb(255 251 235 / 0.2) !important;
}

.tw-border-t-amber-50\/25 {
  border-top-color: rgb(255 251 235 / 0.25) !important;
}

.tw-border-t-amber-50\/30 {
  border-top-color: rgb(255 251 235 / 0.3) !important;
}

.tw-border-t-amber-50\/35 {
  border-top-color: rgb(255 251 235 / 0.35) !important;
}

.tw-border-t-amber-50\/40 {
  border-top-color: rgb(255 251 235 / 0.4) !important;
}

.tw-border-t-amber-50\/45 {
  border-top-color: rgb(255 251 235 / 0.45) !important;
}

.tw-border-t-amber-50\/5 {
  border-top-color: rgb(255 251 235 / 0.05) !important;
}

.tw-border-t-amber-50\/50 {
  border-top-color: rgb(255 251 235 / 0.5) !important;
}

.tw-border-t-amber-50\/55 {
  border-top-color: rgb(255 251 235 / 0.55) !important;
}

.tw-border-t-amber-50\/60 {
  border-top-color: rgb(255 251 235 / 0.6) !important;
}

.tw-border-t-amber-50\/65 {
  border-top-color: rgb(255 251 235 / 0.65) !important;
}

.tw-border-t-amber-50\/70 {
  border-top-color: rgb(255 251 235 / 0.7) !important;
}

.tw-border-t-amber-50\/75 {
  border-top-color: rgb(255 251 235 / 0.75) !important;
}

.tw-border-t-amber-50\/80 {
  border-top-color: rgb(255 251 235 / 0.8) !important;
}

.tw-border-t-amber-50\/85 {
  border-top-color: rgb(255 251 235 / 0.85) !important;
}

.tw-border-t-amber-50\/90 {
  border-top-color: rgb(255 251 235 / 0.9) !important;
}

.tw-border-t-amber-50\/95 {
  border-top-color: rgb(255 251 235 / 0.95) !important;
}

.tw-border-t-amber-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 158 11 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-500\/0 {
  border-top-color: rgb(245 158 11 / 0) !important;
}

.tw-border-t-amber-500\/10 {
  border-top-color: rgb(245 158 11 / 0.1) !important;
}

.tw-border-t-amber-500\/100 {
  border-top-color: rgb(245 158 11 / 1) !important;
}

.tw-border-t-amber-500\/15 {
  border-top-color: rgb(245 158 11 / 0.15) !important;
}

.tw-border-t-amber-500\/20 {
  border-top-color: rgb(245 158 11 / 0.2) !important;
}

.tw-border-t-amber-500\/25 {
  border-top-color: rgb(245 158 11 / 0.25) !important;
}

.tw-border-t-amber-500\/30 {
  border-top-color: rgb(245 158 11 / 0.3) !important;
}

.tw-border-t-amber-500\/35 {
  border-top-color: rgb(245 158 11 / 0.35) !important;
}

.tw-border-t-amber-500\/40 {
  border-top-color: rgb(245 158 11 / 0.4) !important;
}

.tw-border-t-amber-500\/45 {
  border-top-color: rgb(245 158 11 / 0.45) !important;
}

.tw-border-t-amber-500\/5 {
  border-top-color: rgb(245 158 11 / 0.05) !important;
}

.tw-border-t-amber-500\/50 {
  border-top-color: rgb(245 158 11 / 0.5) !important;
}

.tw-border-t-amber-500\/55 {
  border-top-color: rgb(245 158 11 / 0.55) !important;
}

.tw-border-t-amber-500\/60 {
  border-top-color: rgb(245 158 11 / 0.6) !important;
}

.tw-border-t-amber-500\/65 {
  border-top-color: rgb(245 158 11 / 0.65) !important;
}

.tw-border-t-amber-500\/70 {
  border-top-color: rgb(245 158 11 / 0.7) !important;
}

.tw-border-t-amber-500\/75 {
  border-top-color: rgb(245 158 11 / 0.75) !important;
}

.tw-border-t-amber-500\/80 {
  border-top-color: rgb(245 158 11 / 0.8) !important;
}

.tw-border-t-amber-500\/85 {
  border-top-color: rgb(245 158 11 / 0.85) !important;
}

.tw-border-t-amber-500\/90 {
  border-top-color: rgb(245 158 11 / 0.9) !important;
}

.tw-border-t-amber-500\/95 {
  border-top-color: rgb(245 158 11 / 0.95) !important;
}

.tw-border-t-amber-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(217 119 6 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-600\/0 {
  border-top-color: rgb(217 119 6 / 0) !important;
}

.tw-border-t-amber-600\/10 {
  border-top-color: rgb(217 119 6 / 0.1) !important;
}

.tw-border-t-amber-600\/100 {
  border-top-color: rgb(217 119 6 / 1) !important;
}

.tw-border-t-amber-600\/15 {
  border-top-color: rgb(217 119 6 / 0.15) !important;
}

.tw-border-t-amber-600\/20 {
  border-top-color: rgb(217 119 6 / 0.2) !important;
}

.tw-border-t-amber-600\/25 {
  border-top-color: rgb(217 119 6 / 0.25) !important;
}

.tw-border-t-amber-600\/30 {
  border-top-color: rgb(217 119 6 / 0.3) !important;
}

.tw-border-t-amber-600\/35 {
  border-top-color: rgb(217 119 6 / 0.35) !important;
}

.tw-border-t-amber-600\/40 {
  border-top-color: rgb(217 119 6 / 0.4) !important;
}

.tw-border-t-amber-600\/45 {
  border-top-color: rgb(217 119 6 / 0.45) !important;
}

.tw-border-t-amber-600\/5 {
  border-top-color: rgb(217 119 6 / 0.05) !important;
}

.tw-border-t-amber-600\/50 {
  border-top-color: rgb(217 119 6 / 0.5) !important;
}

.tw-border-t-amber-600\/55 {
  border-top-color: rgb(217 119 6 / 0.55) !important;
}

.tw-border-t-amber-600\/60 {
  border-top-color: rgb(217 119 6 / 0.6) !important;
}

.tw-border-t-amber-600\/65 {
  border-top-color: rgb(217 119 6 / 0.65) !important;
}

.tw-border-t-amber-600\/70 {
  border-top-color: rgb(217 119 6 / 0.7) !important;
}

.tw-border-t-amber-600\/75 {
  border-top-color: rgb(217 119 6 / 0.75) !important;
}

.tw-border-t-amber-600\/80 {
  border-top-color: rgb(217 119 6 / 0.8) !important;
}

.tw-border-t-amber-600\/85 {
  border-top-color: rgb(217 119 6 / 0.85) !important;
}

.tw-border-t-amber-600\/90 {
  border-top-color: rgb(217 119 6 / 0.9) !important;
}

.tw-border-t-amber-600\/95 {
  border-top-color: rgb(217 119 6 / 0.95) !important;
}

.tw-border-t-amber-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(180 83 9 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-700\/0 {
  border-top-color: rgb(180 83 9 / 0) !important;
}

.tw-border-t-amber-700\/10 {
  border-top-color: rgb(180 83 9 / 0.1) !important;
}

.tw-border-t-amber-700\/100 {
  border-top-color: rgb(180 83 9 / 1) !important;
}

.tw-border-t-amber-700\/15 {
  border-top-color: rgb(180 83 9 / 0.15) !important;
}

.tw-border-t-amber-700\/20 {
  border-top-color: rgb(180 83 9 / 0.2) !important;
}

.tw-border-t-amber-700\/25 {
  border-top-color: rgb(180 83 9 / 0.25) !important;
}

.tw-border-t-amber-700\/30 {
  border-top-color: rgb(180 83 9 / 0.3) !important;
}

.tw-border-t-amber-700\/35 {
  border-top-color: rgb(180 83 9 / 0.35) !important;
}

.tw-border-t-amber-700\/40 {
  border-top-color: rgb(180 83 9 / 0.4) !important;
}

.tw-border-t-amber-700\/45 {
  border-top-color: rgb(180 83 9 / 0.45) !important;
}

.tw-border-t-amber-700\/5 {
  border-top-color: rgb(180 83 9 / 0.05) !important;
}

.tw-border-t-amber-700\/50 {
  border-top-color: rgb(180 83 9 / 0.5) !important;
}

.tw-border-t-amber-700\/55 {
  border-top-color: rgb(180 83 9 / 0.55) !important;
}

.tw-border-t-amber-700\/60 {
  border-top-color: rgb(180 83 9 / 0.6) !important;
}

.tw-border-t-amber-700\/65 {
  border-top-color: rgb(180 83 9 / 0.65) !important;
}

.tw-border-t-amber-700\/70 {
  border-top-color: rgb(180 83 9 / 0.7) !important;
}

.tw-border-t-amber-700\/75 {
  border-top-color: rgb(180 83 9 / 0.75) !important;
}

.tw-border-t-amber-700\/80 {
  border-top-color: rgb(180 83 9 / 0.8) !important;
}

.tw-border-t-amber-700\/85 {
  border-top-color: rgb(180 83 9 / 0.85) !important;
}

.tw-border-t-amber-700\/90 {
  border-top-color: rgb(180 83 9 / 0.9) !important;
}

.tw-border-t-amber-700\/95 {
  border-top-color: rgb(180 83 9 / 0.95) !important;
}

.tw-border-t-amber-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(146 64 14 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-800\/0 {
  border-top-color: rgb(146 64 14 / 0) !important;
}

.tw-border-t-amber-800\/10 {
  border-top-color: rgb(146 64 14 / 0.1) !important;
}

.tw-border-t-amber-800\/100 {
  border-top-color: rgb(146 64 14 / 1) !important;
}

.tw-border-t-amber-800\/15 {
  border-top-color: rgb(146 64 14 / 0.15) !important;
}

.tw-border-t-amber-800\/20 {
  border-top-color: rgb(146 64 14 / 0.2) !important;
}

.tw-border-t-amber-800\/25 {
  border-top-color: rgb(146 64 14 / 0.25) !important;
}

.tw-border-t-amber-800\/30 {
  border-top-color: rgb(146 64 14 / 0.3) !important;
}

.tw-border-t-amber-800\/35 {
  border-top-color: rgb(146 64 14 / 0.35) !important;
}

.tw-border-t-amber-800\/40 {
  border-top-color: rgb(146 64 14 / 0.4) !important;
}

.tw-border-t-amber-800\/45 {
  border-top-color: rgb(146 64 14 / 0.45) !important;
}

.tw-border-t-amber-800\/5 {
  border-top-color: rgb(146 64 14 / 0.05) !important;
}

.tw-border-t-amber-800\/50 {
  border-top-color: rgb(146 64 14 / 0.5) !important;
}

.tw-border-t-amber-800\/55 {
  border-top-color: rgb(146 64 14 / 0.55) !important;
}

.tw-border-t-amber-800\/60 {
  border-top-color: rgb(146 64 14 / 0.6) !important;
}

.tw-border-t-amber-800\/65 {
  border-top-color: rgb(146 64 14 / 0.65) !important;
}

.tw-border-t-amber-800\/70 {
  border-top-color: rgb(146 64 14 / 0.7) !important;
}

.tw-border-t-amber-800\/75 {
  border-top-color: rgb(146 64 14 / 0.75) !important;
}

.tw-border-t-amber-800\/80 {
  border-top-color: rgb(146 64 14 / 0.8) !important;
}

.tw-border-t-amber-800\/85 {
  border-top-color: rgb(146 64 14 / 0.85) !important;
}

.tw-border-t-amber-800\/90 {
  border-top-color: rgb(146 64 14 / 0.9) !important;
}

.tw-border-t-amber-800\/95 {
  border-top-color: rgb(146 64 14 / 0.95) !important;
}

.tw-border-t-amber-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(120 53 15 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-900\/0 {
  border-top-color: rgb(120 53 15 / 0) !important;
}

.tw-border-t-amber-900\/10 {
  border-top-color: rgb(120 53 15 / 0.1) !important;
}

.tw-border-t-amber-900\/100 {
  border-top-color: rgb(120 53 15 / 1) !important;
}

.tw-border-t-amber-900\/15 {
  border-top-color: rgb(120 53 15 / 0.15) !important;
}

.tw-border-t-amber-900\/20 {
  border-top-color: rgb(120 53 15 / 0.2) !important;
}

.tw-border-t-amber-900\/25 {
  border-top-color: rgb(120 53 15 / 0.25) !important;
}

.tw-border-t-amber-900\/30 {
  border-top-color: rgb(120 53 15 / 0.3) !important;
}

.tw-border-t-amber-900\/35 {
  border-top-color: rgb(120 53 15 / 0.35) !important;
}

.tw-border-t-amber-900\/40 {
  border-top-color: rgb(120 53 15 / 0.4) !important;
}

.tw-border-t-amber-900\/45 {
  border-top-color: rgb(120 53 15 / 0.45) !important;
}

.tw-border-t-amber-900\/5 {
  border-top-color: rgb(120 53 15 / 0.05) !important;
}

.tw-border-t-amber-900\/50 {
  border-top-color: rgb(120 53 15 / 0.5) !important;
}

.tw-border-t-amber-900\/55 {
  border-top-color: rgb(120 53 15 / 0.55) !important;
}

.tw-border-t-amber-900\/60 {
  border-top-color: rgb(120 53 15 / 0.6) !important;
}

.tw-border-t-amber-900\/65 {
  border-top-color: rgb(120 53 15 / 0.65) !important;
}

.tw-border-t-amber-900\/70 {
  border-top-color: rgb(120 53 15 / 0.7) !important;
}

.tw-border-t-amber-900\/75 {
  border-top-color: rgb(120 53 15 / 0.75) !important;
}

.tw-border-t-amber-900\/80 {
  border-top-color: rgb(120 53 15 / 0.8) !important;
}

.tw-border-t-amber-900\/85 {
  border-top-color: rgb(120 53 15 / 0.85) !important;
}

.tw-border-t-amber-900\/90 {
  border-top-color: rgb(120 53 15 / 0.9) !important;
}

.tw-border-t-amber-900\/95 {
  border-top-color: rgb(120 53 15 / 0.95) !important;
}

.tw-border-t-amber-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(69 26 3 / var(--tw-border-opacity)) !important;
}

.tw-border-t-amber-950\/0 {
  border-top-color: rgb(69 26 3 / 0) !important;
}

.tw-border-t-amber-950\/10 {
  border-top-color: rgb(69 26 3 / 0.1) !important;
}

.tw-border-t-amber-950\/100 {
  border-top-color: rgb(69 26 3 / 1) !important;
}

.tw-border-t-amber-950\/15 {
  border-top-color: rgb(69 26 3 / 0.15) !important;
}

.tw-border-t-amber-950\/20 {
  border-top-color: rgb(69 26 3 / 0.2) !important;
}

.tw-border-t-amber-950\/25 {
  border-top-color: rgb(69 26 3 / 0.25) !important;
}

.tw-border-t-amber-950\/30 {
  border-top-color: rgb(69 26 3 / 0.3) !important;
}

.tw-border-t-amber-950\/35 {
  border-top-color: rgb(69 26 3 / 0.35) !important;
}

.tw-border-t-amber-950\/40 {
  border-top-color: rgb(69 26 3 / 0.4) !important;
}

.tw-border-t-amber-950\/45 {
  border-top-color: rgb(69 26 3 / 0.45) !important;
}

.tw-border-t-amber-950\/5 {
  border-top-color: rgb(69 26 3 / 0.05) !important;
}

.tw-border-t-amber-950\/50 {
  border-top-color: rgb(69 26 3 / 0.5) !important;
}

.tw-border-t-amber-950\/55 {
  border-top-color: rgb(69 26 3 / 0.55) !important;
}

.tw-border-t-amber-950\/60 {
  border-top-color: rgb(69 26 3 / 0.6) !important;
}

.tw-border-t-amber-950\/65 {
  border-top-color: rgb(69 26 3 / 0.65) !important;
}

.tw-border-t-amber-950\/70 {
  border-top-color: rgb(69 26 3 / 0.7) !important;
}

.tw-border-t-amber-950\/75 {
  border-top-color: rgb(69 26 3 / 0.75) !important;
}

.tw-border-t-amber-950\/80 {
  border-top-color: rgb(69 26 3 / 0.8) !important;
}

.tw-border-t-amber-950\/85 {
  border-top-color: rgb(69 26 3 / 0.85) !important;
}

.tw-border-t-amber-950\/90 {
  border-top-color: rgb(69 26 3 / 0.9) !important;
}

.tw-border-t-amber-950\/95 {
  border-top-color: rgb(69 26 3 / 0.95) !important;
}

.tw-border-t-black {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
}

.tw-border-t-black\/0 {
  border-top-color: rgb(0 0 0 / 0) !important;
}

.tw-border-t-black\/10 {
  border-top-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-t-black\/100 {
  border-top-color: rgb(0 0 0 / 1) !important;
}

.tw-border-t-black\/15 {
  border-top-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-t-black\/20 {
  border-top-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-t-black\/25 {
  border-top-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-t-black\/30 {
  border-top-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-t-black\/35 {
  border-top-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-t-black\/40 {
  border-top-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-t-black\/45 {
  border-top-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-t-black\/5 {
  border-top-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-t-black\/50 {
  border-top-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-t-black\/55 {
  border-top-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-t-black\/60 {
  border-top-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-t-black\/65 {
  border-top-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-t-black\/70 {
  border-top-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-t-black\/75 {
  border-top-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-t-black\/80 {
  border-top-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-t-black\/85 {
  border-top-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-t-black\/90 {
  border-top-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-t-black\/95 {
  border-top-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-t-blue-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(219 234 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-100\/0 {
  border-top-color: rgb(219 234 254 / 0) !important;
}

.tw-border-t-blue-100\/10 {
  border-top-color: rgb(219 234 254 / 0.1) !important;
}

.tw-border-t-blue-100\/100 {
  border-top-color: rgb(219 234 254 / 1) !important;
}

.tw-border-t-blue-100\/15 {
  border-top-color: rgb(219 234 254 / 0.15) !important;
}

.tw-border-t-blue-100\/20 {
  border-top-color: rgb(219 234 254 / 0.2) !important;
}

.tw-border-t-blue-100\/25 {
  border-top-color: rgb(219 234 254 / 0.25) !important;
}

.tw-border-t-blue-100\/30 {
  border-top-color: rgb(219 234 254 / 0.3) !important;
}

.tw-border-t-blue-100\/35 {
  border-top-color: rgb(219 234 254 / 0.35) !important;
}

.tw-border-t-blue-100\/40 {
  border-top-color: rgb(219 234 254 / 0.4) !important;
}

.tw-border-t-blue-100\/45 {
  border-top-color: rgb(219 234 254 / 0.45) !important;
}

.tw-border-t-blue-100\/5 {
  border-top-color: rgb(219 234 254 / 0.05) !important;
}

.tw-border-t-blue-100\/50 {
  border-top-color: rgb(219 234 254 / 0.5) !important;
}

.tw-border-t-blue-100\/55 {
  border-top-color: rgb(219 234 254 / 0.55) !important;
}

.tw-border-t-blue-100\/60 {
  border-top-color: rgb(219 234 254 / 0.6) !important;
}

.tw-border-t-blue-100\/65 {
  border-top-color: rgb(219 234 254 / 0.65) !important;
}

.tw-border-t-blue-100\/70 {
  border-top-color: rgb(219 234 254 / 0.7) !important;
}

.tw-border-t-blue-100\/75 {
  border-top-color: rgb(219 234 254 / 0.75) !important;
}

.tw-border-t-blue-100\/80 {
  border-top-color: rgb(219 234 254 / 0.8) !important;
}

.tw-border-t-blue-100\/85 {
  border-top-color: rgb(219 234 254 / 0.85) !important;
}

.tw-border-t-blue-100\/90 {
  border-top-color: rgb(219 234 254 / 0.9) !important;
}

.tw-border-t-blue-100\/95 {
  border-top-color: rgb(219 234 254 / 0.95) !important;
}

.tw-border-t-blue-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(191 219 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-200\/0 {
  border-top-color: rgb(191 219 254 / 0) !important;
}

.tw-border-t-blue-200\/10 {
  border-top-color: rgb(191 219 254 / 0.1) !important;
}

.tw-border-t-blue-200\/100 {
  border-top-color: rgb(191 219 254 / 1) !important;
}

.tw-border-t-blue-200\/15 {
  border-top-color: rgb(191 219 254 / 0.15) !important;
}

.tw-border-t-blue-200\/20 {
  border-top-color: rgb(191 219 254 / 0.2) !important;
}

.tw-border-t-blue-200\/25 {
  border-top-color: rgb(191 219 254 / 0.25) !important;
}

.tw-border-t-blue-200\/30 {
  border-top-color: rgb(191 219 254 / 0.3) !important;
}

.tw-border-t-blue-200\/35 {
  border-top-color: rgb(191 219 254 / 0.35) !important;
}

.tw-border-t-blue-200\/40 {
  border-top-color: rgb(191 219 254 / 0.4) !important;
}

.tw-border-t-blue-200\/45 {
  border-top-color: rgb(191 219 254 / 0.45) !important;
}

.tw-border-t-blue-200\/5 {
  border-top-color: rgb(191 219 254 / 0.05) !important;
}

.tw-border-t-blue-200\/50 {
  border-top-color: rgb(191 219 254 / 0.5) !important;
}

.tw-border-t-blue-200\/55 {
  border-top-color: rgb(191 219 254 / 0.55) !important;
}

.tw-border-t-blue-200\/60 {
  border-top-color: rgb(191 219 254 / 0.6) !important;
}

.tw-border-t-blue-200\/65 {
  border-top-color: rgb(191 219 254 / 0.65) !important;
}

.tw-border-t-blue-200\/70 {
  border-top-color: rgb(191 219 254 / 0.7) !important;
}

.tw-border-t-blue-200\/75 {
  border-top-color: rgb(191 219 254 / 0.75) !important;
}

.tw-border-t-blue-200\/80 {
  border-top-color: rgb(191 219 254 / 0.8) !important;
}

.tw-border-t-blue-200\/85 {
  border-top-color: rgb(191 219 254 / 0.85) !important;
}

.tw-border-t-blue-200\/90 {
  border-top-color: rgb(191 219 254 / 0.9) !important;
}

.tw-border-t-blue-200\/95 {
  border-top-color: rgb(191 219 254 / 0.95) !important;
}

.tw-border-t-blue-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(147 197 253 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-300\/0 {
  border-top-color: rgb(147 197 253 / 0) !important;
}

.tw-border-t-blue-300\/10 {
  border-top-color: rgb(147 197 253 / 0.1) !important;
}

.tw-border-t-blue-300\/100 {
  border-top-color: rgb(147 197 253 / 1) !important;
}

.tw-border-t-blue-300\/15 {
  border-top-color: rgb(147 197 253 / 0.15) !important;
}

.tw-border-t-blue-300\/20 {
  border-top-color: rgb(147 197 253 / 0.2) !important;
}

.tw-border-t-blue-300\/25 {
  border-top-color: rgb(147 197 253 / 0.25) !important;
}

.tw-border-t-blue-300\/30 {
  border-top-color: rgb(147 197 253 / 0.3) !important;
}

.tw-border-t-blue-300\/35 {
  border-top-color: rgb(147 197 253 / 0.35) !important;
}

.tw-border-t-blue-300\/40 {
  border-top-color: rgb(147 197 253 / 0.4) !important;
}

.tw-border-t-blue-300\/45 {
  border-top-color: rgb(147 197 253 / 0.45) !important;
}

.tw-border-t-blue-300\/5 {
  border-top-color: rgb(147 197 253 / 0.05) !important;
}

.tw-border-t-blue-300\/50 {
  border-top-color: rgb(147 197 253 / 0.5) !important;
}

.tw-border-t-blue-300\/55 {
  border-top-color: rgb(147 197 253 / 0.55) !important;
}

.tw-border-t-blue-300\/60 {
  border-top-color: rgb(147 197 253 / 0.6) !important;
}

.tw-border-t-blue-300\/65 {
  border-top-color: rgb(147 197 253 / 0.65) !important;
}

.tw-border-t-blue-300\/70 {
  border-top-color: rgb(147 197 253 / 0.7) !important;
}

.tw-border-t-blue-300\/75 {
  border-top-color: rgb(147 197 253 / 0.75) !important;
}

.tw-border-t-blue-300\/80 {
  border-top-color: rgb(147 197 253 / 0.8) !important;
}

.tw-border-t-blue-300\/85 {
  border-top-color: rgb(147 197 253 / 0.85) !important;
}

.tw-border-t-blue-300\/90 {
  border-top-color: rgb(147 197 253 / 0.9) !important;
}

.tw-border-t-blue-300\/95 {
  border-top-color: rgb(147 197 253 / 0.95) !important;
}

.tw-border-t-blue-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(96 165 250 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-400\/0 {
  border-top-color: rgb(96 165 250 / 0) !important;
}

.tw-border-t-blue-400\/10 {
  border-top-color: rgb(96 165 250 / 0.1) !important;
}

.tw-border-t-blue-400\/100 {
  border-top-color: rgb(96 165 250 / 1) !important;
}

.tw-border-t-blue-400\/15 {
  border-top-color: rgb(96 165 250 / 0.15) !important;
}

.tw-border-t-blue-400\/20 {
  border-top-color: rgb(96 165 250 / 0.2) !important;
}

.tw-border-t-blue-400\/25 {
  border-top-color: rgb(96 165 250 / 0.25) !important;
}

.tw-border-t-blue-400\/30 {
  border-top-color: rgb(96 165 250 / 0.3) !important;
}

.tw-border-t-blue-400\/35 {
  border-top-color: rgb(96 165 250 / 0.35) !important;
}

.tw-border-t-blue-400\/40 {
  border-top-color: rgb(96 165 250 / 0.4) !important;
}

.tw-border-t-blue-400\/45 {
  border-top-color: rgb(96 165 250 / 0.45) !important;
}

.tw-border-t-blue-400\/5 {
  border-top-color: rgb(96 165 250 / 0.05) !important;
}

.tw-border-t-blue-400\/50 {
  border-top-color: rgb(96 165 250 / 0.5) !important;
}

.tw-border-t-blue-400\/55 {
  border-top-color: rgb(96 165 250 / 0.55) !important;
}

.tw-border-t-blue-400\/60 {
  border-top-color: rgb(96 165 250 / 0.6) !important;
}

.tw-border-t-blue-400\/65 {
  border-top-color: rgb(96 165 250 / 0.65) !important;
}

.tw-border-t-blue-400\/70 {
  border-top-color: rgb(96 165 250 / 0.7) !important;
}

.tw-border-t-blue-400\/75 {
  border-top-color: rgb(96 165 250 / 0.75) !important;
}

.tw-border-t-blue-400\/80 {
  border-top-color: rgb(96 165 250 / 0.8) !important;
}

.tw-border-t-blue-400\/85 {
  border-top-color: rgb(96 165 250 / 0.85) !important;
}

.tw-border-t-blue-400\/90 {
  border-top-color: rgb(96 165 250 / 0.9) !important;
}

.tw-border-t-blue-400\/95 {
  border-top-color: rgb(96 165 250 / 0.95) !important;
}

.tw-border-t-blue-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(239 246 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-50\/0 {
  border-top-color: rgb(239 246 255 / 0) !important;
}

.tw-border-t-blue-50\/10 {
  border-top-color: rgb(239 246 255 / 0.1) !important;
}

.tw-border-t-blue-50\/100 {
  border-top-color: rgb(239 246 255 / 1) !important;
}

.tw-border-t-blue-50\/15 {
  border-top-color: rgb(239 246 255 / 0.15) !important;
}

.tw-border-t-blue-50\/20 {
  border-top-color: rgb(239 246 255 / 0.2) !important;
}

.tw-border-t-blue-50\/25 {
  border-top-color: rgb(239 246 255 / 0.25) !important;
}

.tw-border-t-blue-50\/30 {
  border-top-color: rgb(239 246 255 / 0.3) !important;
}

.tw-border-t-blue-50\/35 {
  border-top-color: rgb(239 246 255 / 0.35) !important;
}

.tw-border-t-blue-50\/40 {
  border-top-color: rgb(239 246 255 / 0.4) !important;
}

.tw-border-t-blue-50\/45 {
  border-top-color: rgb(239 246 255 / 0.45) !important;
}

.tw-border-t-blue-50\/5 {
  border-top-color: rgb(239 246 255 / 0.05) !important;
}

.tw-border-t-blue-50\/50 {
  border-top-color: rgb(239 246 255 / 0.5) !important;
}

.tw-border-t-blue-50\/55 {
  border-top-color: rgb(239 246 255 / 0.55) !important;
}

.tw-border-t-blue-50\/60 {
  border-top-color: rgb(239 246 255 / 0.6) !important;
}

.tw-border-t-blue-50\/65 {
  border-top-color: rgb(239 246 255 / 0.65) !important;
}

.tw-border-t-blue-50\/70 {
  border-top-color: rgb(239 246 255 / 0.7) !important;
}

.tw-border-t-blue-50\/75 {
  border-top-color: rgb(239 246 255 / 0.75) !important;
}

.tw-border-t-blue-50\/80 {
  border-top-color: rgb(239 246 255 / 0.8) !important;
}

.tw-border-t-blue-50\/85 {
  border-top-color: rgb(239 246 255 / 0.85) !important;
}

.tw-border-t-blue-50\/90 {
  border-top-color: rgb(239 246 255 / 0.9) !important;
}

.tw-border-t-blue-50\/95 {
  border-top-color: rgb(239 246 255 / 0.95) !important;
}

.tw-border-t-blue-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(59 130 246 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-500\/0 {
  border-top-color: rgb(59 130 246 / 0) !important;
}

.tw-border-t-blue-500\/10 {
  border-top-color: rgb(59 130 246 / 0.1) !important;
}

.tw-border-t-blue-500\/100 {
  border-top-color: rgb(59 130 246 / 1) !important;
}

.tw-border-t-blue-500\/15 {
  border-top-color: rgb(59 130 246 / 0.15) !important;
}

.tw-border-t-blue-500\/20 {
  border-top-color: rgb(59 130 246 / 0.2) !important;
}

.tw-border-t-blue-500\/25 {
  border-top-color: rgb(59 130 246 / 0.25) !important;
}

.tw-border-t-blue-500\/30 {
  border-top-color: rgb(59 130 246 / 0.3) !important;
}

.tw-border-t-blue-500\/35 {
  border-top-color: rgb(59 130 246 / 0.35) !important;
}

.tw-border-t-blue-500\/40 {
  border-top-color: rgb(59 130 246 / 0.4) !important;
}

.tw-border-t-blue-500\/45 {
  border-top-color: rgb(59 130 246 / 0.45) !important;
}

.tw-border-t-blue-500\/5 {
  border-top-color: rgb(59 130 246 / 0.05) !important;
}

.tw-border-t-blue-500\/50 {
  border-top-color: rgb(59 130 246 / 0.5) !important;
}

.tw-border-t-blue-500\/55 {
  border-top-color: rgb(59 130 246 / 0.55) !important;
}

.tw-border-t-blue-500\/60 {
  border-top-color: rgb(59 130 246 / 0.6) !important;
}

.tw-border-t-blue-500\/65 {
  border-top-color: rgb(59 130 246 / 0.65) !important;
}

.tw-border-t-blue-500\/70 {
  border-top-color: rgb(59 130 246 / 0.7) !important;
}

.tw-border-t-blue-500\/75 {
  border-top-color: rgb(59 130 246 / 0.75) !important;
}

.tw-border-t-blue-500\/80 {
  border-top-color: rgb(59 130 246 / 0.8) !important;
}

.tw-border-t-blue-500\/85 {
  border-top-color: rgb(59 130 246 / 0.85) !important;
}

.tw-border-t-blue-500\/90 {
  border-top-color: rgb(59 130 246 / 0.9) !important;
}

.tw-border-t-blue-500\/95 {
  border-top-color: rgb(59 130 246 / 0.95) !important;
}

.tw-border-t-blue-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(37 99 235 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-600\/0 {
  border-top-color: rgb(37 99 235 / 0) !important;
}

.tw-border-t-blue-600\/10 {
  border-top-color: rgb(37 99 235 / 0.1) !important;
}

.tw-border-t-blue-600\/100 {
  border-top-color: rgb(37 99 235 / 1) !important;
}

.tw-border-t-blue-600\/15 {
  border-top-color: rgb(37 99 235 / 0.15) !important;
}

.tw-border-t-blue-600\/20 {
  border-top-color: rgb(37 99 235 / 0.2) !important;
}

.tw-border-t-blue-600\/25 {
  border-top-color: rgb(37 99 235 / 0.25) !important;
}

.tw-border-t-blue-600\/30 {
  border-top-color: rgb(37 99 235 / 0.3) !important;
}

.tw-border-t-blue-600\/35 {
  border-top-color: rgb(37 99 235 / 0.35) !important;
}

.tw-border-t-blue-600\/40 {
  border-top-color: rgb(37 99 235 / 0.4) !important;
}

.tw-border-t-blue-600\/45 {
  border-top-color: rgb(37 99 235 / 0.45) !important;
}

.tw-border-t-blue-600\/5 {
  border-top-color: rgb(37 99 235 / 0.05) !important;
}

.tw-border-t-blue-600\/50 {
  border-top-color: rgb(37 99 235 / 0.5) !important;
}

.tw-border-t-blue-600\/55 {
  border-top-color: rgb(37 99 235 / 0.55) !important;
}

.tw-border-t-blue-600\/60 {
  border-top-color: rgb(37 99 235 / 0.6) !important;
}

.tw-border-t-blue-600\/65 {
  border-top-color: rgb(37 99 235 / 0.65) !important;
}

.tw-border-t-blue-600\/70 {
  border-top-color: rgb(37 99 235 / 0.7) !important;
}

.tw-border-t-blue-600\/75 {
  border-top-color: rgb(37 99 235 / 0.75) !important;
}

.tw-border-t-blue-600\/80 {
  border-top-color: rgb(37 99 235 / 0.8) !important;
}

.tw-border-t-blue-600\/85 {
  border-top-color: rgb(37 99 235 / 0.85) !important;
}

.tw-border-t-blue-600\/90 {
  border-top-color: rgb(37 99 235 / 0.9) !important;
}

.tw-border-t-blue-600\/95 {
  border-top-color: rgb(37 99 235 / 0.95) !important;
}

.tw-border-t-blue-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(29 78 216 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-700\/0 {
  border-top-color: rgb(29 78 216 / 0) !important;
}

.tw-border-t-blue-700\/10 {
  border-top-color: rgb(29 78 216 / 0.1) !important;
}

.tw-border-t-blue-700\/100 {
  border-top-color: rgb(29 78 216 / 1) !important;
}

.tw-border-t-blue-700\/15 {
  border-top-color: rgb(29 78 216 / 0.15) !important;
}

.tw-border-t-blue-700\/20 {
  border-top-color: rgb(29 78 216 / 0.2) !important;
}

.tw-border-t-blue-700\/25 {
  border-top-color: rgb(29 78 216 / 0.25) !important;
}

.tw-border-t-blue-700\/30 {
  border-top-color: rgb(29 78 216 / 0.3) !important;
}

.tw-border-t-blue-700\/35 {
  border-top-color: rgb(29 78 216 / 0.35) !important;
}

.tw-border-t-blue-700\/40 {
  border-top-color: rgb(29 78 216 / 0.4) !important;
}

.tw-border-t-blue-700\/45 {
  border-top-color: rgb(29 78 216 / 0.45) !important;
}

.tw-border-t-blue-700\/5 {
  border-top-color: rgb(29 78 216 / 0.05) !important;
}

.tw-border-t-blue-700\/50 {
  border-top-color: rgb(29 78 216 / 0.5) !important;
}

.tw-border-t-blue-700\/55 {
  border-top-color: rgb(29 78 216 / 0.55) !important;
}

.tw-border-t-blue-700\/60 {
  border-top-color: rgb(29 78 216 / 0.6) !important;
}

.tw-border-t-blue-700\/65 {
  border-top-color: rgb(29 78 216 / 0.65) !important;
}

.tw-border-t-blue-700\/70 {
  border-top-color: rgb(29 78 216 / 0.7) !important;
}

.tw-border-t-blue-700\/75 {
  border-top-color: rgb(29 78 216 / 0.75) !important;
}

.tw-border-t-blue-700\/80 {
  border-top-color: rgb(29 78 216 / 0.8) !important;
}

.tw-border-t-blue-700\/85 {
  border-top-color: rgb(29 78 216 / 0.85) !important;
}

.tw-border-t-blue-700\/90 {
  border-top-color: rgb(29 78 216 / 0.9) !important;
}

.tw-border-t-blue-700\/95 {
  border-top-color: rgb(29 78 216 / 0.95) !important;
}

.tw-border-t-blue-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 64 175 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-800\/0 {
  border-top-color: rgb(30 64 175 / 0) !important;
}

.tw-border-t-blue-800\/10 {
  border-top-color: rgb(30 64 175 / 0.1) !important;
}

.tw-border-t-blue-800\/100 {
  border-top-color: rgb(30 64 175 / 1) !important;
}

.tw-border-t-blue-800\/15 {
  border-top-color: rgb(30 64 175 / 0.15) !important;
}

.tw-border-t-blue-800\/20 {
  border-top-color: rgb(30 64 175 / 0.2) !important;
}

.tw-border-t-blue-800\/25 {
  border-top-color: rgb(30 64 175 / 0.25) !important;
}

.tw-border-t-blue-800\/30 {
  border-top-color: rgb(30 64 175 / 0.3) !important;
}

.tw-border-t-blue-800\/35 {
  border-top-color: rgb(30 64 175 / 0.35) !important;
}

.tw-border-t-blue-800\/40 {
  border-top-color: rgb(30 64 175 / 0.4) !important;
}

.tw-border-t-blue-800\/45 {
  border-top-color: rgb(30 64 175 / 0.45) !important;
}

.tw-border-t-blue-800\/5 {
  border-top-color: rgb(30 64 175 / 0.05) !important;
}

.tw-border-t-blue-800\/50 {
  border-top-color: rgb(30 64 175 / 0.5) !important;
}

.tw-border-t-blue-800\/55 {
  border-top-color: rgb(30 64 175 / 0.55) !important;
}

.tw-border-t-blue-800\/60 {
  border-top-color: rgb(30 64 175 / 0.6) !important;
}

.tw-border-t-blue-800\/65 {
  border-top-color: rgb(30 64 175 / 0.65) !important;
}

.tw-border-t-blue-800\/70 {
  border-top-color: rgb(30 64 175 / 0.7) !important;
}

.tw-border-t-blue-800\/75 {
  border-top-color: rgb(30 64 175 / 0.75) !important;
}

.tw-border-t-blue-800\/80 {
  border-top-color: rgb(30 64 175 / 0.8) !important;
}

.tw-border-t-blue-800\/85 {
  border-top-color: rgb(30 64 175 / 0.85) !important;
}

.tw-border-t-blue-800\/90 {
  border-top-color: rgb(30 64 175 / 0.9) !important;
}

.tw-border-t-blue-800\/95 {
  border-top-color: rgb(30 64 175 / 0.95) !important;
}

.tw-border-t-blue-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 58 138 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-900\/0 {
  border-top-color: rgb(30 58 138 / 0) !important;
}

.tw-border-t-blue-900\/10 {
  border-top-color: rgb(30 58 138 / 0.1) !important;
}

.tw-border-t-blue-900\/100 {
  border-top-color: rgb(30 58 138 / 1) !important;
}

.tw-border-t-blue-900\/15 {
  border-top-color: rgb(30 58 138 / 0.15) !important;
}

.tw-border-t-blue-900\/20 {
  border-top-color: rgb(30 58 138 / 0.2) !important;
}

.tw-border-t-blue-900\/25 {
  border-top-color: rgb(30 58 138 / 0.25) !important;
}

.tw-border-t-blue-900\/30 {
  border-top-color: rgb(30 58 138 / 0.3) !important;
}

.tw-border-t-blue-900\/35 {
  border-top-color: rgb(30 58 138 / 0.35) !important;
}

.tw-border-t-blue-900\/40 {
  border-top-color: rgb(30 58 138 / 0.4) !important;
}

.tw-border-t-blue-900\/45 {
  border-top-color: rgb(30 58 138 / 0.45) !important;
}

.tw-border-t-blue-900\/5 {
  border-top-color: rgb(30 58 138 / 0.05) !important;
}

.tw-border-t-blue-900\/50 {
  border-top-color: rgb(30 58 138 / 0.5) !important;
}

.tw-border-t-blue-900\/55 {
  border-top-color: rgb(30 58 138 / 0.55) !important;
}

.tw-border-t-blue-900\/60 {
  border-top-color: rgb(30 58 138 / 0.6) !important;
}

.tw-border-t-blue-900\/65 {
  border-top-color: rgb(30 58 138 / 0.65) !important;
}

.tw-border-t-blue-900\/70 {
  border-top-color: rgb(30 58 138 / 0.7) !important;
}

.tw-border-t-blue-900\/75 {
  border-top-color: rgb(30 58 138 / 0.75) !important;
}

.tw-border-t-blue-900\/80 {
  border-top-color: rgb(30 58 138 / 0.8) !important;
}

.tw-border-t-blue-900\/85 {
  border-top-color: rgb(30 58 138 / 0.85) !important;
}

.tw-border-t-blue-900\/90 {
  border-top-color: rgb(30 58 138 / 0.9) !important;
}

.tw-border-t-blue-900\/95 {
  border-top-color: rgb(30 58 138 / 0.95) !important;
}

.tw-border-t-blue-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(23 37 84 / var(--tw-border-opacity)) !important;
}

.tw-border-t-blue-950\/0 {
  border-top-color: rgb(23 37 84 / 0) !important;
}

.tw-border-t-blue-950\/10 {
  border-top-color: rgb(23 37 84 / 0.1) !important;
}

.tw-border-t-blue-950\/100 {
  border-top-color: rgb(23 37 84 / 1) !important;
}

.tw-border-t-blue-950\/15 {
  border-top-color: rgb(23 37 84 / 0.15) !important;
}

.tw-border-t-blue-950\/20 {
  border-top-color: rgb(23 37 84 / 0.2) !important;
}

.tw-border-t-blue-950\/25 {
  border-top-color: rgb(23 37 84 / 0.25) !important;
}

.tw-border-t-blue-950\/30 {
  border-top-color: rgb(23 37 84 / 0.3) !important;
}

.tw-border-t-blue-950\/35 {
  border-top-color: rgb(23 37 84 / 0.35) !important;
}

.tw-border-t-blue-950\/40 {
  border-top-color: rgb(23 37 84 / 0.4) !important;
}

.tw-border-t-blue-950\/45 {
  border-top-color: rgb(23 37 84 / 0.45) !important;
}

.tw-border-t-blue-950\/5 {
  border-top-color: rgb(23 37 84 / 0.05) !important;
}

.tw-border-t-blue-950\/50 {
  border-top-color: rgb(23 37 84 / 0.5) !important;
}

.tw-border-t-blue-950\/55 {
  border-top-color: rgb(23 37 84 / 0.55) !important;
}

.tw-border-t-blue-950\/60 {
  border-top-color: rgb(23 37 84 / 0.6) !important;
}

.tw-border-t-blue-950\/65 {
  border-top-color: rgb(23 37 84 / 0.65) !important;
}

.tw-border-t-blue-950\/70 {
  border-top-color: rgb(23 37 84 / 0.7) !important;
}

.tw-border-t-blue-950\/75 {
  border-top-color: rgb(23 37 84 / 0.75) !important;
}

.tw-border-t-blue-950\/80 {
  border-top-color: rgb(23 37 84 / 0.8) !important;
}

.tw-border-t-blue-950\/85 {
  border-top-color: rgb(23 37 84 / 0.85) !important;
}

.tw-border-t-blue-950\/90 {
  border-top-color: rgb(23 37 84 / 0.9) !important;
}

.tw-border-t-blue-950\/95 {
  border-top-color: rgb(23 37 84 / 0.95) !important;
}

.tw-border-t-current {
  border-top-color: currentColor !important;
}

.tw-border-t-cyan-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(207 250 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-100\/0 {
  border-top-color: rgb(207 250 254 / 0) !important;
}

.tw-border-t-cyan-100\/10 {
  border-top-color: rgb(207 250 254 / 0.1) !important;
}

.tw-border-t-cyan-100\/100 {
  border-top-color: rgb(207 250 254 / 1) !important;
}

.tw-border-t-cyan-100\/15 {
  border-top-color: rgb(207 250 254 / 0.15) !important;
}

.tw-border-t-cyan-100\/20 {
  border-top-color: rgb(207 250 254 / 0.2) !important;
}

.tw-border-t-cyan-100\/25 {
  border-top-color: rgb(207 250 254 / 0.25) !important;
}

.tw-border-t-cyan-100\/30 {
  border-top-color: rgb(207 250 254 / 0.3) !important;
}

.tw-border-t-cyan-100\/35 {
  border-top-color: rgb(207 250 254 / 0.35) !important;
}

.tw-border-t-cyan-100\/40 {
  border-top-color: rgb(207 250 254 / 0.4) !important;
}

.tw-border-t-cyan-100\/45 {
  border-top-color: rgb(207 250 254 / 0.45) !important;
}

.tw-border-t-cyan-100\/5 {
  border-top-color: rgb(207 250 254 / 0.05) !important;
}

.tw-border-t-cyan-100\/50 {
  border-top-color: rgb(207 250 254 / 0.5) !important;
}

.tw-border-t-cyan-100\/55 {
  border-top-color: rgb(207 250 254 / 0.55) !important;
}

.tw-border-t-cyan-100\/60 {
  border-top-color: rgb(207 250 254 / 0.6) !important;
}

.tw-border-t-cyan-100\/65 {
  border-top-color: rgb(207 250 254 / 0.65) !important;
}

.tw-border-t-cyan-100\/70 {
  border-top-color: rgb(207 250 254 / 0.7) !important;
}

.tw-border-t-cyan-100\/75 {
  border-top-color: rgb(207 250 254 / 0.75) !important;
}

.tw-border-t-cyan-100\/80 {
  border-top-color: rgb(207 250 254 / 0.8) !important;
}

.tw-border-t-cyan-100\/85 {
  border-top-color: rgb(207 250 254 / 0.85) !important;
}

.tw-border-t-cyan-100\/90 {
  border-top-color: rgb(207 250 254 / 0.9) !important;
}

.tw-border-t-cyan-100\/95 {
  border-top-color: rgb(207 250 254 / 0.95) !important;
}

.tw-border-t-cyan-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(165 243 252 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-200\/0 {
  border-top-color: rgb(165 243 252 / 0) !important;
}

.tw-border-t-cyan-200\/10 {
  border-top-color: rgb(165 243 252 / 0.1) !important;
}

.tw-border-t-cyan-200\/100 {
  border-top-color: rgb(165 243 252 / 1) !important;
}

.tw-border-t-cyan-200\/15 {
  border-top-color: rgb(165 243 252 / 0.15) !important;
}

.tw-border-t-cyan-200\/20 {
  border-top-color: rgb(165 243 252 / 0.2) !important;
}

.tw-border-t-cyan-200\/25 {
  border-top-color: rgb(165 243 252 / 0.25) !important;
}

.tw-border-t-cyan-200\/30 {
  border-top-color: rgb(165 243 252 / 0.3) !important;
}

.tw-border-t-cyan-200\/35 {
  border-top-color: rgb(165 243 252 / 0.35) !important;
}

.tw-border-t-cyan-200\/40 {
  border-top-color: rgb(165 243 252 / 0.4) !important;
}

.tw-border-t-cyan-200\/45 {
  border-top-color: rgb(165 243 252 / 0.45) !important;
}

.tw-border-t-cyan-200\/5 {
  border-top-color: rgb(165 243 252 / 0.05) !important;
}

.tw-border-t-cyan-200\/50 {
  border-top-color: rgb(165 243 252 / 0.5) !important;
}

.tw-border-t-cyan-200\/55 {
  border-top-color: rgb(165 243 252 / 0.55) !important;
}

.tw-border-t-cyan-200\/60 {
  border-top-color: rgb(165 243 252 / 0.6) !important;
}

.tw-border-t-cyan-200\/65 {
  border-top-color: rgb(165 243 252 / 0.65) !important;
}

.tw-border-t-cyan-200\/70 {
  border-top-color: rgb(165 243 252 / 0.7) !important;
}

.tw-border-t-cyan-200\/75 {
  border-top-color: rgb(165 243 252 / 0.75) !important;
}

.tw-border-t-cyan-200\/80 {
  border-top-color: rgb(165 243 252 / 0.8) !important;
}

.tw-border-t-cyan-200\/85 {
  border-top-color: rgb(165 243 252 / 0.85) !important;
}

.tw-border-t-cyan-200\/90 {
  border-top-color: rgb(165 243 252 / 0.9) !important;
}

.tw-border-t-cyan-200\/95 {
  border-top-color: rgb(165 243 252 / 0.95) !important;
}

.tw-border-t-cyan-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(103 232 249 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-300\/0 {
  border-top-color: rgb(103 232 249 / 0) !important;
}

.tw-border-t-cyan-300\/10 {
  border-top-color: rgb(103 232 249 / 0.1) !important;
}

.tw-border-t-cyan-300\/100 {
  border-top-color: rgb(103 232 249 / 1) !important;
}

.tw-border-t-cyan-300\/15 {
  border-top-color: rgb(103 232 249 / 0.15) !important;
}

.tw-border-t-cyan-300\/20 {
  border-top-color: rgb(103 232 249 / 0.2) !important;
}

.tw-border-t-cyan-300\/25 {
  border-top-color: rgb(103 232 249 / 0.25) !important;
}

.tw-border-t-cyan-300\/30 {
  border-top-color: rgb(103 232 249 / 0.3) !important;
}

.tw-border-t-cyan-300\/35 {
  border-top-color: rgb(103 232 249 / 0.35) !important;
}

.tw-border-t-cyan-300\/40 {
  border-top-color: rgb(103 232 249 / 0.4) !important;
}

.tw-border-t-cyan-300\/45 {
  border-top-color: rgb(103 232 249 / 0.45) !important;
}

.tw-border-t-cyan-300\/5 {
  border-top-color: rgb(103 232 249 / 0.05) !important;
}

.tw-border-t-cyan-300\/50 {
  border-top-color: rgb(103 232 249 / 0.5) !important;
}

.tw-border-t-cyan-300\/55 {
  border-top-color: rgb(103 232 249 / 0.55) !important;
}

.tw-border-t-cyan-300\/60 {
  border-top-color: rgb(103 232 249 / 0.6) !important;
}

.tw-border-t-cyan-300\/65 {
  border-top-color: rgb(103 232 249 / 0.65) !important;
}

.tw-border-t-cyan-300\/70 {
  border-top-color: rgb(103 232 249 / 0.7) !important;
}

.tw-border-t-cyan-300\/75 {
  border-top-color: rgb(103 232 249 / 0.75) !important;
}

.tw-border-t-cyan-300\/80 {
  border-top-color: rgb(103 232 249 / 0.8) !important;
}

.tw-border-t-cyan-300\/85 {
  border-top-color: rgb(103 232 249 / 0.85) !important;
}

.tw-border-t-cyan-300\/90 {
  border-top-color: rgb(103 232 249 / 0.9) !important;
}

.tw-border-t-cyan-300\/95 {
  border-top-color: rgb(103 232 249 / 0.95) !important;
}

.tw-border-t-cyan-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(34 211 238 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-400\/0 {
  border-top-color: rgb(34 211 238 / 0) !important;
}

.tw-border-t-cyan-400\/10 {
  border-top-color: rgb(34 211 238 / 0.1) !important;
}

.tw-border-t-cyan-400\/100 {
  border-top-color: rgb(34 211 238 / 1) !important;
}

.tw-border-t-cyan-400\/15 {
  border-top-color: rgb(34 211 238 / 0.15) !important;
}

.tw-border-t-cyan-400\/20 {
  border-top-color: rgb(34 211 238 / 0.2) !important;
}

.tw-border-t-cyan-400\/25 {
  border-top-color: rgb(34 211 238 / 0.25) !important;
}

.tw-border-t-cyan-400\/30 {
  border-top-color: rgb(34 211 238 / 0.3) !important;
}

.tw-border-t-cyan-400\/35 {
  border-top-color: rgb(34 211 238 / 0.35) !important;
}

.tw-border-t-cyan-400\/40 {
  border-top-color: rgb(34 211 238 / 0.4) !important;
}

.tw-border-t-cyan-400\/45 {
  border-top-color: rgb(34 211 238 / 0.45) !important;
}

.tw-border-t-cyan-400\/5 {
  border-top-color: rgb(34 211 238 / 0.05) !important;
}

.tw-border-t-cyan-400\/50 {
  border-top-color: rgb(34 211 238 / 0.5) !important;
}

.tw-border-t-cyan-400\/55 {
  border-top-color: rgb(34 211 238 / 0.55) !important;
}

.tw-border-t-cyan-400\/60 {
  border-top-color: rgb(34 211 238 / 0.6) !important;
}

.tw-border-t-cyan-400\/65 {
  border-top-color: rgb(34 211 238 / 0.65) !important;
}

.tw-border-t-cyan-400\/70 {
  border-top-color: rgb(34 211 238 / 0.7) !important;
}

.tw-border-t-cyan-400\/75 {
  border-top-color: rgb(34 211 238 / 0.75) !important;
}

.tw-border-t-cyan-400\/80 {
  border-top-color: rgb(34 211 238 / 0.8) !important;
}

.tw-border-t-cyan-400\/85 {
  border-top-color: rgb(34 211 238 / 0.85) !important;
}

.tw-border-t-cyan-400\/90 {
  border-top-color: rgb(34 211 238 / 0.9) !important;
}

.tw-border-t-cyan-400\/95 {
  border-top-color: rgb(34 211 238 / 0.95) !important;
}

.tw-border-t-cyan-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 254 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-50\/0 {
  border-top-color: rgb(236 254 255 / 0) !important;
}

.tw-border-t-cyan-50\/10 {
  border-top-color: rgb(236 254 255 / 0.1) !important;
}

.tw-border-t-cyan-50\/100 {
  border-top-color: rgb(236 254 255 / 1) !important;
}

.tw-border-t-cyan-50\/15 {
  border-top-color: rgb(236 254 255 / 0.15) !important;
}

.tw-border-t-cyan-50\/20 {
  border-top-color: rgb(236 254 255 / 0.2) !important;
}

.tw-border-t-cyan-50\/25 {
  border-top-color: rgb(236 254 255 / 0.25) !important;
}

.tw-border-t-cyan-50\/30 {
  border-top-color: rgb(236 254 255 / 0.3) !important;
}

.tw-border-t-cyan-50\/35 {
  border-top-color: rgb(236 254 255 / 0.35) !important;
}

.tw-border-t-cyan-50\/40 {
  border-top-color: rgb(236 254 255 / 0.4) !important;
}

.tw-border-t-cyan-50\/45 {
  border-top-color: rgb(236 254 255 / 0.45) !important;
}

.tw-border-t-cyan-50\/5 {
  border-top-color: rgb(236 254 255 / 0.05) !important;
}

.tw-border-t-cyan-50\/50 {
  border-top-color: rgb(236 254 255 / 0.5) !important;
}

.tw-border-t-cyan-50\/55 {
  border-top-color: rgb(236 254 255 / 0.55) !important;
}

.tw-border-t-cyan-50\/60 {
  border-top-color: rgb(236 254 255 / 0.6) !important;
}

.tw-border-t-cyan-50\/65 {
  border-top-color: rgb(236 254 255 / 0.65) !important;
}

.tw-border-t-cyan-50\/70 {
  border-top-color: rgb(236 254 255 / 0.7) !important;
}

.tw-border-t-cyan-50\/75 {
  border-top-color: rgb(236 254 255 / 0.75) !important;
}

.tw-border-t-cyan-50\/80 {
  border-top-color: rgb(236 254 255 / 0.8) !important;
}

.tw-border-t-cyan-50\/85 {
  border-top-color: rgb(236 254 255 / 0.85) !important;
}

.tw-border-t-cyan-50\/90 {
  border-top-color: rgb(236 254 255 / 0.9) !important;
}

.tw-border-t-cyan-50\/95 {
  border-top-color: rgb(236 254 255 / 0.95) !important;
}

.tw-border-t-cyan-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(6 182 212 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-500\/0 {
  border-top-color: rgb(6 182 212 / 0) !important;
}

.tw-border-t-cyan-500\/10 {
  border-top-color: rgb(6 182 212 / 0.1) !important;
}

.tw-border-t-cyan-500\/100 {
  border-top-color: rgb(6 182 212 / 1) !important;
}

.tw-border-t-cyan-500\/15 {
  border-top-color: rgb(6 182 212 / 0.15) !important;
}

.tw-border-t-cyan-500\/20 {
  border-top-color: rgb(6 182 212 / 0.2) !important;
}

.tw-border-t-cyan-500\/25 {
  border-top-color: rgb(6 182 212 / 0.25) !important;
}

.tw-border-t-cyan-500\/30 {
  border-top-color: rgb(6 182 212 / 0.3) !important;
}

.tw-border-t-cyan-500\/35 {
  border-top-color: rgb(6 182 212 / 0.35) !important;
}

.tw-border-t-cyan-500\/40 {
  border-top-color: rgb(6 182 212 / 0.4) !important;
}

.tw-border-t-cyan-500\/45 {
  border-top-color: rgb(6 182 212 / 0.45) !important;
}

.tw-border-t-cyan-500\/5 {
  border-top-color: rgb(6 182 212 / 0.05) !important;
}

.tw-border-t-cyan-500\/50 {
  border-top-color: rgb(6 182 212 / 0.5) !important;
}

.tw-border-t-cyan-500\/55 {
  border-top-color: rgb(6 182 212 / 0.55) !important;
}

.tw-border-t-cyan-500\/60 {
  border-top-color: rgb(6 182 212 / 0.6) !important;
}

.tw-border-t-cyan-500\/65 {
  border-top-color: rgb(6 182 212 / 0.65) !important;
}

.tw-border-t-cyan-500\/70 {
  border-top-color: rgb(6 182 212 / 0.7) !important;
}

.tw-border-t-cyan-500\/75 {
  border-top-color: rgb(6 182 212 / 0.75) !important;
}

.tw-border-t-cyan-500\/80 {
  border-top-color: rgb(6 182 212 / 0.8) !important;
}

.tw-border-t-cyan-500\/85 {
  border-top-color: rgb(6 182 212 / 0.85) !important;
}

.tw-border-t-cyan-500\/90 {
  border-top-color: rgb(6 182 212 / 0.9) !important;
}

.tw-border-t-cyan-500\/95 {
  border-top-color: rgb(6 182 212 / 0.95) !important;
}

.tw-border-t-cyan-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 145 178 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-600\/0 {
  border-top-color: rgb(8 145 178 / 0) !important;
}

.tw-border-t-cyan-600\/10 {
  border-top-color: rgb(8 145 178 / 0.1) !important;
}

.tw-border-t-cyan-600\/100 {
  border-top-color: rgb(8 145 178 / 1) !important;
}

.tw-border-t-cyan-600\/15 {
  border-top-color: rgb(8 145 178 / 0.15) !important;
}

.tw-border-t-cyan-600\/20 {
  border-top-color: rgb(8 145 178 / 0.2) !important;
}

.tw-border-t-cyan-600\/25 {
  border-top-color: rgb(8 145 178 / 0.25) !important;
}

.tw-border-t-cyan-600\/30 {
  border-top-color: rgb(8 145 178 / 0.3) !important;
}

.tw-border-t-cyan-600\/35 {
  border-top-color: rgb(8 145 178 / 0.35) !important;
}

.tw-border-t-cyan-600\/40 {
  border-top-color: rgb(8 145 178 / 0.4) !important;
}

.tw-border-t-cyan-600\/45 {
  border-top-color: rgb(8 145 178 / 0.45) !important;
}

.tw-border-t-cyan-600\/5 {
  border-top-color: rgb(8 145 178 / 0.05) !important;
}

.tw-border-t-cyan-600\/50 {
  border-top-color: rgb(8 145 178 / 0.5) !important;
}

.tw-border-t-cyan-600\/55 {
  border-top-color: rgb(8 145 178 / 0.55) !important;
}

.tw-border-t-cyan-600\/60 {
  border-top-color: rgb(8 145 178 / 0.6) !important;
}

.tw-border-t-cyan-600\/65 {
  border-top-color: rgb(8 145 178 / 0.65) !important;
}

.tw-border-t-cyan-600\/70 {
  border-top-color: rgb(8 145 178 / 0.7) !important;
}

.tw-border-t-cyan-600\/75 {
  border-top-color: rgb(8 145 178 / 0.75) !important;
}

.tw-border-t-cyan-600\/80 {
  border-top-color: rgb(8 145 178 / 0.8) !important;
}

.tw-border-t-cyan-600\/85 {
  border-top-color: rgb(8 145 178 / 0.85) !important;
}

.tw-border-t-cyan-600\/90 {
  border-top-color: rgb(8 145 178 / 0.9) !important;
}

.tw-border-t-cyan-600\/95 {
  border-top-color: rgb(8 145 178 / 0.95) !important;
}

.tw-border-t-cyan-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(14 116 144 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-700\/0 {
  border-top-color: rgb(14 116 144 / 0) !important;
}

.tw-border-t-cyan-700\/10 {
  border-top-color: rgb(14 116 144 / 0.1) !important;
}

.tw-border-t-cyan-700\/100 {
  border-top-color: rgb(14 116 144 / 1) !important;
}

.tw-border-t-cyan-700\/15 {
  border-top-color: rgb(14 116 144 / 0.15) !important;
}

.tw-border-t-cyan-700\/20 {
  border-top-color: rgb(14 116 144 / 0.2) !important;
}

.tw-border-t-cyan-700\/25 {
  border-top-color: rgb(14 116 144 / 0.25) !important;
}

.tw-border-t-cyan-700\/30 {
  border-top-color: rgb(14 116 144 / 0.3) !important;
}

.tw-border-t-cyan-700\/35 {
  border-top-color: rgb(14 116 144 / 0.35) !important;
}

.tw-border-t-cyan-700\/40 {
  border-top-color: rgb(14 116 144 / 0.4) !important;
}

.tw-border-t-cyan-700\/45 {
  border-top-color: rgb(14 116 144 / 0.45) !important;
}

.tw-border-t-cyan-700\/5 {
  border-top-color: rgb(14 116 144 / 0.05) !important;
}

.tw-border-t-cyan-700\/50 {
  border-top-color: rgb(14 116 144 / 0.5) !important;
}

.tw-border-t-cyan-700\/55 {
  border-top-color: rgb(14 116 144 / 0.55) !important;
}

.tw-border-t-cyan-700\/60 {
  border-top-color: rgb(14 116 144 / 0.6) !important;
}

.tw-border-t-cyan-700\/65 {
  border-top-color: rgb(14 116 144 / 0.65) !important;
}

.tw-border-t-cyan-700\/70 {
  border-top-color: rgb(14 116 144 / 0.7) !important;
}

.tw-border-t-cyan-700\/75 {
  border-top-color: rgb(14 116 144 / 0.75) !important;
}

.tw-border-t-cyan-700\/80 {
  border-top-color: rgb(14 116 144 / 0.8) !important;
}

.tw-border-t-cyan-700\/85 {
  border-top-color: rgb(14 116 144 / 0.85) !important;
}

.tw-border-t-cyan-700\/90 {
  border-top-color: rgb(14 116 144 / 0.9) !important;
}

.tw-border-t-cyan-700\/95 {
  border-top-color: rgb(14 116 144 / 0.95) !important;
}

.tw-border-t-cyan-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(21 94 117 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-800\/0 {
  border-top-color: rgb(21 94 117 / 0) !important;
}

.tw-border-t-cyan-800\/10 {
  border-top-color: rgb(21 94 117 / 0.1) !important;
}

.tw-border-t-cyan-800\/100 {
  border-top-color: rgb(21 94 117 / 1) !important;
}

.tw-border-t-cyan-800\/15 {
  border-top-color: rgb(21 94 117 / 0.15) !important;
}

.tw-border-t-cyan-800\/20 {
  border-top-color: rgb(21 94 117 / 0.2) !important;
}

.tw-border-t-cyan-800\/25 {
  border-top-color: rgb(21 94 117 / 0.25) !important;
}

.tw-border-t-cyan-800\/30 {
  border-top-color: rgb(21 94 117 / 0.3) !important;
}

.tw-border-t-cyan-800\/35 {
  border-top-color: rgb(21 94 117 / 0.35) !important;
}

.tw-border-t-cyan-800\/40 {
  border-top-color: rgb(21 94 117 / 0.4) !important;
}

.tw-border-t-cyan-800\/45 {
  border-top-color: rgb(21 94 117 / 0.45) !important;
}

.tw-border-t-cyan-800\/5 {
  border-top-color: rgb(21 94 117 / 0.05) !important;
}

.tw-border-t-cyan-800\/50 {
  border-top-color: rgb(21 94 117 / 0.5) !important;
}

.tw-border-t-cyan-800\/55 {
  border-top-color: rgb(21 94 117 / 0.55) !important;
}

.tw-border-t-cyan-800\/60 {
  border-top-color: rgb(21 94 117 / 0.6) !important;
}

.tw-border-t-cyan-800\/65 {
  border-top-color: rgb(21 94 117 / 0.65) !important;
}

.tw-border-t-cyan-800\/70 {
  border-top-color: rgb(21 94 117 / 0.7) !important;
}

.tw-border-t-cyan-800\/75 {
  border-top-color: rgb(21 94 117 / 0.75) !important;
}

.tw-border-t-cyan-800\/80 {
  border-top-color: rgb(21 94 117 / 0.8) !important;
}

.tw-border-t-cyan-800\/85 {
  border-top-color: rgb(21 94 117 / 0.85) !important;
}

.tw-border-t-cyan-800\/90 {
  border-top-color: rgb(21 94 117 / 0.9) !important;
}

.tw-border-t-cyan-800\/95 {
  border-top-color: rgb(21 94 117 / 0.95) !important;
}

.tw-border-t-cyan-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(22 78 99 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-900\/0 {
  border-top-color: rgb(22 78 99 / 0) !important;
}

.tw-border-t-cyan-900\/10 {
  border-top-color: rgb(22 78 99 / 0.1) !important;
}

.tw-border-t-cyan-900\/100 {
  border-top-color: rgb(22 78 99 / 1) !important;
}

.tw-border-t-cyan-900\/15 {
  border-top-color: rgb(22 78 99 / 0.15) !important;
}

.tw-border-t-cyan-900\/20 {
  border-top-color: rgb(22 78 99 / 0.2) !important;
}

.tw-border-t-cyan-900\/25 {
  border-top-color: rgb(22 78 99 / 0.25) !important;
}

.tw-border-t-cyan-900\/30 {
  border-top-color: rgb(22 78 99 / 0.3) !important;
}

.tw-border-t-cyan-900\/35 {
  border-top-color: rgb(22 78 99 / 0.35) !important;
}

.tw-border-t-cyan-900\/40 {
  border-top-color: rgb(22 78 99 / 0.4) !important;
}

.tw-border-t-cyan-900\/45 {
  border-top-color: rgb(22 78 99 / 0.45) !important;
}

.tw-border-t-cyan-900\/5 {
  border-top-color: rgb(22 78 99 / 0.05) !important;
}

.tw-border-t-cyan-900\/50 {
  border-top-color: rgb(22 78 99 / 0.5) !important;
}

.tw-border-t-cyan-900\/55 {
  border-top-color: rgb(22 78 99 / 0.55) !important;
}

.tw-border-t-cyan-900\/60 {
  border-top-color: rgb(22 78 99 / 0.6) !important;
}

.tw-border-t-cyan-900\/65 {
  border-top-color: rgb(22 78 99 / 0.65) !important;
}

.tw-border-t-cyan-900\/70 {
  border-top-color: rgb(22 78 99 / 0.7) !important;
}

.tw-border-t-cyan-900\/75 {
  border-top-color: rgb(22 78 99 / 0.75) !important;
}

.tw-border-t-cyan-900\/80 {
  border-top-color: rgb(22 78 99 / 0.8) !important;
}

.tw-border-t-cyan-900\/85 {
  border-top-color: rgb(22 78 99 / 0.85) !important;
}

.tw-border-t-cyan-900\/90 {
  border-top-color: rgb(22 78 99 / 0.9) !important;
}

.tw-border-t-cyan-900\/95 {
  border-top-color: rgb(22 78 99 / 0.95) !important;
}

.tw-border-t-cyan-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 51 68 / var(--tw-border-opacity)) !important;
}

.tw-border-t-cyan-950\/0 {
  border-top-color: rgb(8 51 68 / 0) !important;
}

.tw-border-t-cyan-950\/10 {
  border-top-color: rgb(8 51 68 / 0.1) !important;
}

.tw-border-t-cyan-950\/100 {
  border-top-color: rgb(8 51 68 / 1) !important;
}

.tw-border-t-cyan-950\/15 {
  border-top-color: rgb(8 51 68 / 0.15) !important;
}

.tw-border-t-cyan-950\/20 {
  border-top-color: rgb(8 51 68 / 0.2) !important;
}

.tw-border-t-cyan-950\/25 {
  border-top-color: rgb(8 51 68 / 0.25) !important;
}

.tw-border-t-cyan-950\/30 {
  border-top-color: rgb(8 51 68 / 0.3) !important;
}

.tw-border-t-cyan-950\/35 {
  border-top-color: rgb(8 51 68 / 0.35) !important;
}

.tw-border-t-cyan-950\/40 {
  border-top-color: rgb(8 51 68 / 0.4) !important;
}

.tw-border-t-cyan-950\/45 {
  border-top-color: rgb(8 51 68 / 0.45) !important;
}

.tw-border-t-cyan-950\/5 {
  border-top-color: rgb(8 51 68 / 0.05) !important;
}

.tw-border-t-cyan-950\/50 {
  border-top-color: rgb(8 51 68 / 0.5) !important;
}

.tw-border-t-cyan-950\/55 {
  border-top-color: rgb(8 51 68 / 0.55) !important;
}

.tw-border-t-cyan-950\/60 {
  border-top-color: rgb(8 51 68 / 0.6) !important;
}

.tw-border-t-cyan-950\/65 {
  border-top-color: rgb(8 51 68 / 0.65) !important;
}

.tw-border-t-cyan-950\/70 {
  border-top-color: rgb(8 51 68 / 0.7) !important;
}

.tw-border-t-cyan-950\/75 {
  border-top-color: rgb(8 51 68 / 0.75) !important;
}

.tw-border-t-cyan-950\/80 {
  border-top-color: rgb(8 51 68 / 0.8) !important;
}

.tw-border-t-cyan-950\/85 {
  border-top-color: rgb(8 51 68 / 0.85) !important;
}

.tw-border-t-cyan-950\/90 {
  border-top-color: rgb(8 51 68 / 0.9) !important;
}

.tw-border-t-cyan-950\/95 {
  border-top-color: rgb(8 51 68 / 0.95) !important;
}

.tw-border-t-emerald-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(209 250 229 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-100\/0 {
  border-top-color: rgb(209 250 229 / 0) !important;
}

.tw-border-t-emerald-100\/10 {
  border-top-color: rgb(209 250 229 / 0.1) !important;
}

.tw-border-t-emerald-100\/100 {
  border-top-color: rgb(209 250 229 / 1) !important;
}

.tw-border-t-emerald-100\/15 {
  border-top-color: rgb(209 250 229 / 0.15) !important;
}

.tw-border-t-emerald-100\/20 {
  border-top-color: rgb(209 250 229 / 0.2) !important;
}

.tw-border-t-emerald-100\/25 {
  border-top-color: rgb(209 250 229 / 0.25) !important;
}

.tw-border-t-emerald-100\/30 {
  border-top-color: rgb(209 250 229 / 0.3) !important;
}

.tw-border-t-emerald-100\/35 {
  border-top-color: rgb(209 250 229 / 0.35) !important;
}

.tw-border-t-emerald-100\/40 {
  border-top-color: rgb(209 250 229 / 0.4) !important;
}

.tw-border-t-emerald-100\/45 {
  border-top-color: rgb(209 250 229 / 0.45) !important;
}

.tw-border-t-emerald-100\/5 {
  border-top-color: rgb(209 250 229 / 0.05) !important;
}

.tw-border-t-emerald-100\/50 {
  border-top-color: rgb(209 250 229 / 0.5) !important;
}

.tw-border-t-emerald-100\/55 {
  border-top-color: rgb(209 250 229 / 0.55) !important;
}

.tw-border-t-emerald-100\/60 {
  border-top-color: rgb(209 250 229 / 0.6) !important;
}

.tw-border-t-emerald-100\/65 {
  border-top-color: rgb(209 250 229 / 0.65) !important;
}

.tw-border-t-emerald-100\/70 {
  border-top-color: rgb(209 250 229 / 0.7) !important;
}

.tw-border-t-emerald-100\/75 {
  border-top-color: rgb(209 250 229 / 0.75) !important;
}

.tw-border-t-emerald-100\/80 {
  border-top-color: rgb(209 250 229 / 0.8) !important;
}

.tw-border-t-emerald-100\/85 {
  border-top-color: rgb(209 250 229 / 0.85) !important;
}

.tw-border-t-emerald-100\/90 {
  border-top-color: rgb(209 250 229 / 0.9) !important;
}

.tw-border-t-emerald-100\/95 {
  border-top-color: rgb(209 250 229 / 0.95) !important;
}

.tw-border-t-emerald-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(167 243 208 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-200\/0 {
  border-top-color: rgb(167 243 208 / 0) !important;
}

.tw-border-t-emerald-200\/10 {
  border-top-color: rgb(167 243 208 / 0.1) !important;
}

.tw-border-t-emerald-200\/100 {
  border-top-color: rgb(167 243 208 / 1) !important;
}

.tw-border-t-emerald-200\/15 {
  border-top-color: rgb(167 243 208 / 0.15) !important;
}

.tw-border-t-emerald-200\/20 {
  border-top-color: rgb(167 243 208 / 0.2) !important;
}

.tw-border-t-emerald-200\/25 {
  border-top-color: rgb(167 243 208 / 0.25) !important;
}

.tw-border-t-emerald-200\/30 {
  border-top-color: rgb(167 243 208 / 0.3) !important;
}

.tw-border-t-emerald-200\/35 {
  border-top-color: rgb(167 243 208 / 0.35) !important;
}

.tw-border-t-emerald-200\/40 {
  border-top-color: rgb(167 243 208 / 0.4) !important;
}

.tw-border-t-emerald-200\/45 {
  border-top-color: rgb(167 243 208 / 0.45) !important;
}

.tw-border-t-emerald-200\/5 {
  border-top-color: rgb(167 243 208 / 0.05) !important;
}

.tw-border-t-emerald-200\/50 {
  border-top-color: rgb(167 243 208 / 0.5) !important;
}

.tw-border-t-emerald-200\/55 {
  border-top-color: rgb(167 243 208 / 0.55) !important;
}

.tw-border-t-emerald-200\/60 {
  border-top-color: rgb(167 243 208 / 0.6) !important;
}

.tw-border-t-emerald-200\/65 {
  border-top-color: rgb(167 243 208 / 0.65) !important;
}

.tw-border-t-emerald-200\/70 {
  border-top-color: rgb(167 243 208 / 0.7) !important;
}

.tw-border-t-emerald-200\/75 {
  border-top-color: rgb(167 243 208 / 0.75) !important;
}

.tw-border-t-emerald-200\/80 {
  border-top-color: rgb(167 243 208 / 0.8) !important;
}

.tw-border-t-emerald-200\/85 {
  border-top-color: rgb(167 243 208 / 0.85) !important;
}

.tw-border-t-emerald-200\/90 {
  border-top-color: rgb(167 243 208 / 0.9) !important;
}

.tw-border-t-emerald-200\/95 {
  border-top-color: rgb(167 243 208 / 0.95) !important;
}

.tw-border-t-emerald-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(110 231 183 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-300\/0 {
  border-top-color: rgb(110 231 183 / 0) !important;
}

.tw-border-t-emerald-300\/10 {
  border-top-color: rgb(110 231 183 / 0.1) !important;
}

.tw-border-t-emerald-300\/100 {
  border-top-color: rgb(110 231 183 / 1) !important;
}

.tw-border-t-emerald-300\/15 {
  border-top-color: rgb(110 231 183 / 0.15) !important;
}

.tw-border-t-emerald-300\/20 {
  border-top-color: rgb(110 231 183 / 0.2) !important;
}

.tw-border-t-emerald-300\/25 {
  border-top-color: rgb(110 231 183 / 0.25) !important;
}

.tw-border-t-emerald-300\/30 {
  border-top-color: rgb(110 231 183 / 0.3) !important;
}

.tw-border-t-emerald-300\/35 {
  border-top-color: rgb(110 231 183 / 0.35) !important;
}

.tw-border-t-emerald-300\/40 {
  border-top-color: rgb(110 231 183 / 0.4) !important;
}

.tw-border-t-emerald-300\/45 {
  border-top-color: rgb(110 231 183 / 0.45) !important;
}

.tw-border-t-emerald-300\/5 {
  border-top-color: rgb(110 231 183 / 0.05) !important;
}

.tw-border-t-emerald-300\/50 {
  border-top-color: rgb(110 231 183 / 0.5) !important;
}

.tw-border-t-emerald-300\/55 {
  border-top-color: rgb(110 231 183 / 0.55) !important;
}

.tw-border-t-emerald-300\/60 {
  border-top-color: rgb(110 231 183 / 0.6) !important;
}

.tw-border-t-emerald-300\/65 {
  border-top-color: rgb(110 231 183 / 0.65) !important;
}

.tw-border-t-emerald-300\/70 {
  border-top-color: rgb(110 231 183 / 0.7) !important;
}

.tw-border-t-emerald-300\/75 {
  border-top-color: rgb(110 231 183 / 0.75) !important;
}

.tw-border-t-emerald-300\/80 {
  border-top-color: rgb(110 231 183 / 0.8) !important;
}

.tw-border-t-emerald-300\/85 {
  border-top-color: rgb(110 231 183 / 0.85) !important;
}

.tw-border-t-emerald-300\/90 {
  border-top-color: rgb(110 231 183 / 0.9) !important;
}

.tw-border-t-emerald-300\/95 {
  border-top-color: rgb(110 231 183 / 0.95) !important;
}

.tw-border-t-emerald-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(52 211 153 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-400\/0 {
  border-top-color: rgb(52 211 153 / 0) !important;
}

.tw-border-t-emerald-400\/10 {
  border-top-color: rgb(52 211 153 / 0.1) !important;
}

.tw-border-t-emerald-400\/100 {
  border-top-color: rgb(52 211 153 / 1) !important;
}

.tw-border-t-emerald-400\/15 {
  border-top-color: rgb(52 211 153 / 0.15) !important;
}

.tw-border-t-emerald-400\/20 {
  border-top-color: rgb(52 211 153 / 0.2) !important;
}

.tw-border-t-emerald-400\/25 {
  border-top-color: rgb(52 211 153 / 0.25) !important;
}

.tw-border-t-emerald-400\/30 {
  border-top-color: rgb(52 211 153 / 0.3) !important;
}

.tw-border-t-emerald-400\/35 {
  border-top-color: rgb(52 211 153 / 0.35) !important;
}

.tw-border-t-emerald-400\/40 {
  border-top-color: rgb(52 211 153 / 0.4) !important;
}

.tw-border-t-emerald-400\/45 {
  border-top-color: rgb(52 211 153 / 0.45) !important;
}

.tw-border-t-emerald-400\/5 {
  border-top-color: rgb(52 211 153 / 0.05) !important;
}

.tw-border-t-emerald-400\/50 {
  border-top-color: rgb(52 211 153 / 0.5) !important;
}

.tw-border-t-emerald-400\/55 {
  border-top-color: rgb(52 211 153 / 0.55) !important;
}

.tw-border-t-emerald-400\/60 {
  border-top-color: rgb(52 211 153 / 0.6) !important;
}

.tw-border-t-emerald-400\/65 {
  border-top-color: rgb(52 211 153 / 0.65) !important;
}

.tw-border-t-emerald-400\/70 {
  border-top-color: rgb(52 211 153 / 0.7) !important;
}

.tw-border-t-emerald-400\/75 {
  border-top-color: rgb(52 211 153 / 0.75) !important;
}

.tw-border-t-emerald-400\/80 {
  border-top-color: rgb(52 211 153 / 0.8) !important;
}

.tw-border-t-emerald-400\/85 {
  border-top-color: rgb(52 211 153 / 0.85) !important;
}

.tw-border-t-emerald-400\/90 {
  border-top-color: rgb(52 211 153 / 0.9) !important;
}

.tw-border-t-emerald-400\/95 {
  border-top-color: rgb(52 211 153 / 0.95) !important;
}

.tw-border-t-emerald-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 253 245 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-50\/0 {
  border-top-color: rgb(236 253 245 / 0) !important;
}

.tw-border-t-emerald-50\/10 {
  border-top-color: rgb(236 253 245 / 0.1) !important;
}

.tw-border-t-emerald-50\/100 {
  border-top-color: rgb(236 253 245 / 1) !important;
}

.tw-border-t-emerald-50\/15 {
  border-top-color: rgb(236 253 245 / 0.15) !important;
}

.tw-border-t-emerald-50\/20 {
  border-top-color: rgb(236 253 245 / 0.2) !important;
}

.tw-border-t-emerald-50\/25 {
  border-top-color: rgb(236 253 245 / 0.25) !important;
}

.tw-border-t-emerald-50\/30 {
  border-top-color: rgb(236 253 245 / 0.3) !important;
}

.tw-border-t-emerald-50\/35 {
  border-top-color: rgb(236 253 245 / 0.35) !important;
}

.tw-border-t-emerald-50\/40 {
  border-top-color: rgb(236 253 245 / 0.4) !important;
}

.tw-border-t-emerald-50\/45 {
  border-top-color: rgb(236 253 245 / 0.45) !important;
}

.tw-border-t-emerald-50\/5 {
  border-top-color: rgb(236 253 245 / 0.05) !important;
}

.tw-border-t-emerald-50\/50 {
  border-top-color: rgb(236 253 245 / 0.5) !important;
}

.tw-border-t-emerald-50\/55 {
  border-top-color: rgb(236 253 245 / 0.55) !important;
}

.tw-border-t-emerald-50\/60 {
  border-top-color: rgb(236 253 245 / 0.6) !important;
}

.tw-border-t-emerald-50\/65 {
  border-top-color: rgb(236 253 245 / 0.65) !important;
}

.tw-border-t-emerald-50\/70 {
  border-top-color: rgb(236 253 245 / 0.7) !important;
}

.tw-border-t-emerald-50\/75 {
  border-top-color: rgb(236 253 245 / 0.75) !important;
}

.tw-border-t-emerald-50\/80 {
  border-top-color: rgb(236 253 245 / 0.8) !important;
}

.tw-border-t-emerald-50\/85 {
  border-top-color: rgb(236 253 245 / 0.85) !important;
}

.tw-border-t-emerald-50\/90 {
  border-top-color: rgb(236 253 245 / 0.9) !important;
}

.tw-border-t-emerald-50\/95 {
  border-top-color: rgb(236 253 245 / 0.95) !important;
}

.tw-border-t-emerald-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(16 185 129 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-500\/0 {
  border-top-color: rgb(16 185 129 / 0) !important;
}

.tw-border-t-emerald-500\/10 {
  border-top-color: rgb(16 185 129 / 0.1) !important;
}

.tw-border-t-emerald-500\/100 {
  border-top-color: rgb(16 185 129 / 1) !important;
}

.tw-border-t-emerald-500\/15 {
  border-top-color: rgb(16 185 129 / 0.15) !important;
}

.tw-border-t-emerald-500\/20 {
  border-top-color: rgb(16 185 129 / 0.2) !important;
}

.tw-border-t-emerald-500\/25 {
  border-top-color: rgb(16 185 129 / 0.25) !important;
}

.tw-border-t-emerald-500\/30 {
  border-top-color: rgb(16 185 129 / 0.3) !important;
}

.tw-border-t-emerald-500\/35 {
  border-top-color: rgb(16 185 129 / 0.35) !important;
}

.tw-border-t-emerald-500\/40 {
  border-top-color: rgb(16 185 129 / 0.4) !important;
}

.tw-border-t-emerald-500\/45 {
  border-top-color: rgb(16 185 129 / 0.45) !important;
}

.tw-border-t-emerald-500\/5 {
  border-top-color: rgb(16 185 129 / 0.05) !important;
}

.tw-border-t-emerald-500\/50 {
  border-top-color: rgb(16 185 129 / 0.5) !important;
}

.tw-border-t-emerald-500\/55 {
  border-top-color: rgb(16 185 129 / 0.55) !important;
}

.tw-border-t-emerald-500\/60 {
  border-top-color: rgb(16 185 129 / 0.6) !important;
}

.tw-border-t-emerald-500\/65 {
  border-top-color: rgb(16 185 129 / 0.65) !important;
}

.tw-border-t-emerald-500\/70 {
  border-top-color: rgb(16 185 129 / 0.7) !important;
}

.tw-border-t-emerald-500\/75 {
  border-top-color: rgb(16 185 129 / 0.75) !important;
}

.tw-border-t-emerald-500\/80 {
  border-top-color: rgb(16 185 129 / 0.8) !important;
}

.tw-border-t-emerald-500\/85 {
  border-top-color: rgb(16 185 129 / 0.85) !important;
}

.tw-border-t-emerald-500\/90 {
  border-top-color: rgb(16 185 129 / 0.9) !important;
}

.tw-border-t-emerald-500\/95 {
  border-top-color: rgb(16 185 129 / 0.95) !important;
}

.tw-border-t-emerald-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(5 150 105 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-600\/0 {
  border-top-color: rgb(5 150 105 / 0) !important;
}

.tw-border-t-emerald-600\/10 {
  border-top-color: rgb(5 150 105 / 0.1) !important;
}

.tw-border-t-emerald-600\/100 {
  border-top-color: rgb(5 150 105 / 1) !important;
}

.tw-border-t-emerald-600\/15 {
  border-top-color: rgb(5 150 105 / 0.15) !important;
}

.tw-border-t-emerald-600\/20 {
  border-top-color: rgb(5 150 105 / 0.2) !important;
}

.tw-border-t-emerald-600\/25 {
  border-top-color: rgb(5 150 105 / 0.25) !important;
}

.tw-border-t-emerald-600\/30 {
  border-top-color: rgb(5 150 105 / 0.3) !important;
}

.tw-border-t-emerald-600\/35 {
  border-top-color: rgb(5 150 105 / 0.35) !important;
}

.tw-border-t-emerald-600\/40 {
  border-top-color: rgb(5 150 105 / 0.4) !important;
}

.tw-border-t-emerald-600\/45 {
  border-top-color: rgb(5 150 105 / 0.45) !important;
}

.tw-border-t-emerald-600\/5 {
  border-top-color: rgb(5 150 105 / 0.05) !important;
}

.tw-border-t-emerald-600\/50 {
  border-top-color: rgb(5 150 105 / 0.5) !important;
}

.tw-border-t-emerald-600\/55 {
  border-top-color: rgb(5 150 105 / 0.55) !important;
}

.tw-border-t-emerald-600\/60 {
  border-top-color: rgb(5 150 105 / 0.6) !important;
}

.tw-border-t-emerald-600\/65 {
  border-top-color: rgb(5 150 105 / 0.65) !important;
}

.tw-border-t-emerald-600\/70 {
  border-top-color: rgb(5 150 105 / 0.7) !important;
}

.tw-border-t-emerald-600\/75 {
  border-top-color: rgb(5 150 105 / 0.75) !important;
}

.tw-border-t-emerald-600\/80 {
  border-top-color: rgb(5 150 105 / 0.8) !important;
}

.tw-border-t-emerald-600\/85 {
  border-top-color: rgb(5 150 105 / 0.85) !important;
}

.tw-border-t-emerald-600\/90 {
  border-top-color: rgb(5 150 105 / 0.9) !important;
}

.tw-border-t-emerald-600\/95 {
  border-top-color: rgb(5 150 105 / 0.95) !important;
}

.tw-border-t-emerald-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(4 120 87 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-700\/0 {
  border-top-color: rgb(4 120 87 / 0) !important;
}

.tw-border-t-emerald-700\/10 {
  border-top-color: rgb(4 120 87 / 0.1) !important;
}

.tw-border-t-emerald-700\/100 {
  border-top-color: rgb(4 120 87 / 1) !important;
}

.tw-border-t-emerald-700\/15 {
  border-top-color: rgb(4 120 87 / 0.15) !important;
}

.tw-border-t-emerald-700\/20 {
  border-top-color: rgb(4 120 87 / 0.2) !important;
}

.tw-border-t-emerald-700\/25 {
  border-top-color: rgb(4 120 87 / 0.25) !important;
}

.tw-border-t-emerald-700\/30 {
  border-top-color: rgb(4 120 87 / 0.3) !important;
}

.tw-border-t-emerald-700\/35 {
  border-top-color: rgb(4 120 87 / 0.35) !important;
}

.tw-border-t-emerald-700\/40 {
  border-top-color: rgb(4 120 87 / 0.4) !important;
}

.tw-border-t-emerald-700\/45 {
  border-top-color: rgb(4 120 87 / 0.45) !important;
}

.tw-border-t-emerald-700\/5 {
  border-top-color: rgb(4 120 87 / 0.05) !important;
}

.tw-border-t-emerald-700\/50 {
  border-top-color: rgb(4 120 87 / 0.5) !important;
}

.tw-border-t-emerald-700\/55 {
  border-top-color: rgb(4 120 87 / 0.55) !important;
}

.tw-border-t-emerald-700\/60 {
  border-top-color: rgb(4 120 87 / 0.6) !important;
}

.tw-border-t-emerald-700\/65 {
  border-top-color: rgb(4 120 87 / 0.65) !important;
}

.tw-border-t-emerald-700\/70 {
  border-top-color: rgb(4 120 87 / 0.7) !important;
}

.tw-border-t-emerald-700\/75 {
  border-top-color: rgb(4 120 87 / 0.75) !important;
}

.tw-border-t-emerald-700\/80 {
  border-top-color: rgb(4 120 87 / 0.8) !important;
}

.tw-border-t-emerald-700\/85 {
  border-top-color: rgb(4 120 87 / 0.85) !important;
}

.tw-border-t-emerald-700\/90 {
  border-top-color: rgb(4 120 87 / 0.9) !important;
}

.tw-border-t-emerald-700\/95 {
  border-top-color: rgb(4 120 87 / 0.95) !important;
}

.tw-border-t-emerald-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(6 95 70 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-800\/0 {
  border-top-color: rgb(6 95 70 / 0) !important;
}

.tw-border-t-emerald-800\/10 {
  border-top-color: rgb(6 95 70 / 0.1) !important;
}

.tw-border-t-emerald-800\/100 {
  border-top-color: rgb(6 95 70 / 1) !important;
}

.tw-border-t-emerald-800\/15 {
  border-top-color: rgb(6 95 70 / 0.15) !important;
}

.tw-border-t-emerald-800\/20 {
  border-top-color: rgb(6 95 70 / 0.2) !important;
}

.tw-border-t-emerald-800\/25 {
  border-top-color: rgb(6 95 70 / 0.25) !important;
}

.tw-border-t-emerald-800\/30 {
  border-top-color: rgb(6 95 70 / 0.3) !important;
}

.tw-border-t-emerald-800\/35 {
  border-top-color: rgb(6 95 70 / 0.35) !important;
}

.tw-border-t-emerald-800\/40 {
  border-top-color: rgb(6 95 70 / 0.4) !important;
}

.tw-border-t-emerald-800\/45 {
  border-top-color: rgb(6 95 70 / 0.45) !important;
}

.tw-border-t-emerald-800\/5 {
  border-top-color: rgb(6 95 70 / 0.05) !important;
}

.tw-border-t-emerald-800\/50 {
  border-top-color: rgb(6 95 70 / 0.5) !important;
}

.tw-border-t-emerald-800\/55 {
  border-top-color: rgb(6 95 70 / 0.55) !important;
}

.tw-border-t-emerald-800\/60 {
  border-top-color: rgb(6 95 70 / 0.6) !important;
}

.tw-border-t-emerald-800\/65 {
  border-top-color: rgb(6 95 70 / 0.65) !important;
}

.tw-border-t-emerald-800\/70 {
  border-top-color: rgb(6 95 70 / 0.7) !important;
}

.tw-border-t-emerald-800\/75 {
  border-top-color: rgb(6 95 70 / 0.75) !important;
}

.tw-border-t-emerald-800\/80 {
  border-top-color: rgb(6 95 70 / 0.8) !important;
}

.tw-border-t-emerald-800\/85 {
  border-top-color: rgb(6 95 70 / 0.85) !important;
}

.tw-border-t-emerald-800\/90 {
  border-top-color: rgb(6 95 70 / 0.9) !important;
}

.tw-border-t-emerald-800\/95 {
  border-top-color: rgb(6 95 70 / 0.95) !important;
}

.tw-border-t-emerald-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(6 78 59 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-900\/0 {
  border-top-color: rgb(6 78 59 / 0) !important;
}

.tw-border-t-emerald-900\/10 {
  border-top-color: rgb(6 78 59 / 0.1) !important;
}

.tw-border-t-emerald-900\/100 {
  border-top-color: rgb(6 78 59 / 1) !important;
}

.tw-border-t-emerald-900\/15 {
  border-top-color: rgb(6 78 59 / 0.15) !important;
}

.tw-border-t-emerald-900\/20 {
  border-top-color: rgb(6 78 59 / 0.2) !important;
}

.tw-border-t-emerald-900\/25 {
  border-top-color: rgb(6 78 59 / 0.25) !important;
}

.tw-border-t-emerald-900\/30 {
  border-top-color: rgb(6 78 59 / 0.3) !important;
}

.tw-border-t-emerald-900\/35 {
  border-top-color: rgb(6 78 59 / 0.35) !important;
}

.tw-border-t-emerald-900\/40 {
  border-top-color: rgb(6 78 59 / 0.4) !important;
}

.tw-border-t-emerald-900\/45 {
  border-top-color: rgb(6 78 59 / 0.45) !important;
}

.tw-border-t-emerald-900\/5 {
  border-top-color: rgb(6 78 59 / 0.05) !important;
}

.tw-border-t-emerald-900\/50 {
  border-top-color: rgb(6 78 59 / 0.5) !important;
}

.tw-border-t-emerald-900\/55 {
  border-top-color: rgb(6 78 59 / 0.55) !important;
}

.tw-border-t-emerald-900\/60 {
  border-top-color: rgb(6 78 59 / 0.6) !important;
}

.tw-border-t-emerald-900\/65 {
  border-top-color: rgb(6 78 59 / 0.65) !important;
}

.tw-border-t-emerald-900\/70 {
  border-top-color: rgb(6 78 59 / 0.7) !important;
}

.tw-border-t-emerald-900\/75 {
  border-top-color: rgb(6 78 59 / 0.75) !important;
}

.tw-border-t-emerald-900\/80 {
  border-top-color: rgb(6 78 59 / 0.8) !important;
}

.tw-border-t-emerald-900\/85 {
  border-top-color: rgb(6 78 59 / 0.85) !important;
}

.tw-border-t-emerald-900\/90 {
  border-top-color: rgb(6 78 59 / 0.9) !important;
}

.tw-border-t-emerald-900\/95 {
  border-top-color: rgb(6 78 59 / 0.95) !important;
}

.tw-border-t-emerald-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(2 44 34 / var(--tw-border-opacity)) !important;
}

.tw-border-t-emerald-950\/0 {
  border-top-color: rgb(2 44 34 / 0) !important;
}

.tw-border-t-emerald-950\/10 {
  border-top-color: rgb(2 44 34 / 0.1) !important;
}

.tw-border-t-emerald-950\/100 {
  border-top-color: rgb(2 44 34 / 1) !important;
}

.tw-border-t-emerald-950\/15 {
  border-top-color: rgb(2 44 34 / 0.15) !important;
}

.tw-border-t-emerald-950\/20 {
  border-top-color: rgb(2 44 34 / 0.2) !important;
}

.tw-border-t-emerald-950\/25 {
  border-top-color: rgb(2 44 34 / 0.25) !important;
}

.tw-border-t-emerald-950\/30 {
  border-top-color: rgb(2 44 34 / 0.3) !important;
}

.tw-border-t-emerald-950\/35 {
  border-top-color: rgb(2 44 34 / 0.35) !important;
}

.tw-border-t-emerald-950\/40 {
  border-top-color: rgb(2 44 34 / 0.4) !important;
}

.tw-border-t-emerald-950\/45 {
  border-top-color: rgb(2 44 34 / 0.45) !important;
}

.tw-border-t-emerald-950\/5 {
  border-top-color: rgb(2 44 34 / 0.05) !important;
}

.tw-border-t-emerald-950\/50 {
  border-top-color: rgb(2 44 34 / 0.5) !important;
}

.tw-border-t-emerald-950\/55 {
  border-top-color: rgb(2 44 34 / 0.55) !important;
}

.tw-border-t-emerald-950\/60 {
  border-top-color: rgb(2 44 34 / 0.6) !important;
}

.tw-border-t-emerald-950\/65 {
  border-top-color: rgb(2 44 34 / 0.65) !important;
}

.tw-border-t-emerald-950\/70 {
  border-top-color: rgb(2 44 34 / 0.7) !important;
}

.tw-border-t-emerald-950\/75 {
  border-top-color: rgb(2 44 34 / 0.75) !important;
}

.tw-border-t-emerald-950\/80 {
  border-top-color: rgb(2 44 34 / 0.8) !important;
}

.tw-border-t-emerald-950\/85 {
  border-top-color: rgb(2 44 34 / 0.85) !important;
}

.tw-border-t-emerald-950\/90 {
  border-top-color: rgb(2 44 34 / 0.9) !important;
}

.tw-border-t-emerald-950\/95 {
  border-top-color: rgb(2 44 34 / 0.95) !important;
}

.tw-border-t-fuchsia-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-100\/0 {
  border-top-color: rgb(250 232 255 / 0) !important;
}

.tw-border-t-fuchsia-100\/10 {
  border-top-color: rgb(250 232 255 / 0.1) !important;
}

.tw-border-t-fuchsia-100\/100 {
  border-top-color: rgb(250 232 255 / 1) !important;
}

.tw-border-t-fuchsia-100\/15 {
  border-top-color: rgb(250 232 255 / 0.15) !important;
}

.tw-border-t-fuchsia-100\/20 {
  border-top-color: rgb(250 232 255 / 0.2) !important;
}

.tw-border-t-fuchsia-100\/25 {
  border-top-color: rgb(250 232 255 / 0.25) !important;
}

.tw-border-t-fuchsia-100\/30 {
  border-top-color: rgb(250 232 255 / 0.3) !important;
}

.tw-border-t-fuchsia-100\/35 {
  border-top-color: rgb(250 232 255 / 0.35) !important;
}

.tw-border-t-fuchsia-100\/40 {
  border-top-color: rgb(250 232 255 / 0.4) !important;
}

.tw-border-t-fuchsia-100\/45 {
  border-top-color: rgb(250 232 255 / 0.45) !important;
}

.tw-border-t-fuchsia-100\/5 {
  border-top-color: rgb(250 232 255 / 0.05) !important;
}

.tw-border-t-fuchsia-100\/50 {
  border-top-color: rgb(250 232 255 / 0.5) !important;
}

.tw-border-t-fuchsia-100\/55 {
  border-top-color: rgb(250 232 255 / 0.55) !important;
}

.tw-border-t-fuchsia-100\/60 {
  border-top-color: rgb(250 232 255 / 0.6) !important;
}

.tw-border-t-fuchsia-100\/65 {
  border-top-color: rgb(250 232 255 / 0.65) !important;
}

.tw-border-t-fuchsia-100\/70 {
  border-top-color: rgb(250 232 255 / 0.7) !important;
}

.tw-border-t-fuchsia-100\/75 {
  border-top-color: rgb(250 232 255 / 0.75) !important;
}

.tw-border-t-fuchsia-100\/80 {
  border-top-color: rgb(250 232 255 / 0.8) !important;
}

.tw-border-t-fuchsia-100\/85 {
  border-top-color: rgb(250 232 255 / 0.85) !important;
}

.tw-border-t-fuchsia-100\/90 {
  border-top-color: rgb(250 232 255 / 0.9) !important;
}

.tw-border-t-fuchsia-100\/95 {
  border-top-color: rgb(250 232 255 / 0.95) !important;
}

.tw-border-t-fuchsia-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 208 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-200\/0 {
  border-top-color: rgb(245 208 254 / 0) !important;
}

.tw-border-t-fuchsia-200\/10 {
  border-top-color: rgb(245 208 254 / 0.1) !important;
}

.tw-border-t-fuchsia-200\/100 {
  border-top-color: rgb(245 208 254 / 1) !important;
}

.tw-border-t-fuchsia-200\/15 {
  border-top-color: rgb(245 208 254 / 0.15) !important;
}

.tw-border-t-fuchsia-200\/20 {
  border-top-color: rgb(245 208 254 / 0.2) !important;
}

.tw-border-t-fuchsia-200\/25 {
  border-top-color: rgb(245 208 254 / 0.25) !important;
}

.tw-border-t-fuchsia-200\/30 {
  border-top-color: rgb(245 208 254 / 0.3) !important;
}

.tw-border-t-fuchsia-200\/35 {
  border-top-color: rgb(245 208 254 / 0.35) !important;
}

.tw-border-t-fuchsia-200\/40 {
  border-top-color: rgb(245 208 254 / 0.4) !important;
}

.tw-border-t-fuchsia-200\/45 {
  border-top-color: rgb(245 208 254 / 0.45) !important;
}

.tw-border-t-fuchsia-200\/5 {
  border-top-color: rgb(245 208 254 / 0.05) !important;
}

.tw-border-t-fuchsia-200\/50 {
  border-top-color: rgb(245 208 254 / 0.5) !important;
}

.tw-border-t-fuchsia-200\/55 {
  border-top-color: rgb(245 208 254 / 0.55) !important;
}

.tw-border-t-fuchsia-200\/60 {
  border-top-color: rgb(245 208 254 / 0.6) !important;
}

.tw-border-t-fuchsia-200\/65 {
  border-top-color: rgb(245 208 254 / 0.65) !important;
}

.tw-border-t-fuchsia-200\/70 {
  border-top-color: rgb(245 208 254 / 0.7) !important;
}

.tw-border-t-fuchsia-200\/75 {
  border-top-color: rgb(245 208 254 / 0.75) !important;
}

.tw-border-t-fuchsia-200\/80 {
  border-top-color: rgb(245 208 254 / 0.8) !important;
}

.tw-border-t-fuchsia-200\/85 {
  border-top-color: rgb(245 208 254 / 0.85) !important;
}

.tw-border-t-fuchsia-200\/90 {
  border-top-color: rgb(245 208 254 / 0.9) !important;
}

.tw-border-t-fuchsia-200\/95 {
  border-top-color: rgb(245 208 254 / 0.95) !important;
}

.tw-border-t-fuchsia-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 171 252 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-300\/0 {
  border-top-color: rgb(240 171 252 / 0) !important;
}

.tw-border-t-fuchsia-300\/10 {
  border-top-color: rgb(240 171 252 / 0.1) !important;
}

.tw-border-t-fuchsia-300\/100 {
  border-top-color: rgb(240 171 252 / 1) !important;
}

.tw-border-t-fuchsia-300\/15 {
  border-top-color: rgb(240 171 252 / 0.15) !important;
}

.tw-border-t-fuchsia-300\/20 {
  border-top-color: rgb(240 171 252 / 0.2) !important;
}

.tw-border-t-fuchsia-300\/25 {
  border-top-color: rgb(240 171 252 / 0.25) !important;
}

.tw-border-t-fuchsia-300\/30 {
  border-top-color: rgb(240 171 252 / 0.3) !important;
}

.tw-border-t-fuchsia-300\/35 {
  border-top-color: rgb(240 171 252 / 0.35) !important;
}

.tw-border-t-fuchsia-300\/40 {
  border-top-color: rgb(240 171 252 / 0.4) !important;
}

.tw-border-t-fuchsia-300\/45 {
  border-top-color: rgb(240 171 252 / 0.45) !important;
}

.tw-border-t-fuchsia-300\/5 {
  border-top-color: rgb(240 171 252 / 0.05) !important;
}

.tw-border-t-fuchsia-300\/50 {
  border-top-color: rgb(240 171 252 / 0.5) !important;
}

.tw-border-t-fuchsia-300\/55 {
  border-top-color: rgb(240 171 252 / 0.55) !important;
}

.tw-border-t-fuchsia-300\/60 {
  border-top-color: rgb(240 171 252 / 0.6) !important;
}

.tw-border-t-fuchsia-300\/65 {
  border-top-color: rgb(240 171 252 / 0.65) !important;
}

.tw-border-t-fuchsia-300\/70 {
  border-top-color: rgb(240 171 252 / 0.7) !important;
}

.tw-border-t-fuchsia-300\/75 {
  border-top-color: rgb(240 171 252 / 0.75) !important;
}

.tw-border-t-fuchsia-300\/80 {
  border-top-color: rgb(240 171 252 / 0.8) !important;
}

.tw-border-t-fuchsia-300\/85 {
  border-top-color: rgb(240 171 252 / 0.85) !important;
}

.tw-border-t-fuchsia-300\/90 {
  border-top-color: rgb(240 171 252 / 0.9) !important;
}

.tw-border-t-fuchsia-300\/95 {
  border-top-color: rgb(240 171 252 / 0.95) !important;
}

.tw-border-t-fuchsia-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(232 121 249 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-400\/0 {
  border-top-color: rgb(232 121 249 / 0) !important;
}

.tw-border-t-fuchsia-400\/10 {
  border-top-color: rgb(232 121 249 / 0.1) !important;
}

.tw-border-t-fuchsia-400\/100 {
  border-top-color: rgb(232 121 249 / 1) !important;
}

.tw-border-t-fuchsia-400\/15 {
  border-top-color: rgb(232 121 249 / 0.15) !important;
}

.tw-border-t-fuchsia-400\/20 {
  border-top-color: rgb(232 121 249 / 0.2) !important;
}

.tw-border-t-fuchsia-400\/25 {
  border-top-color: rgb(232 121 249 / 0.25) !important;
}

.tw-border-t-fuchsia-400\/30 {
  border-top-color: rgb(232 121 249 / 0.3) !important;
}

.tw-border-t-fuchsia-400\/35 {
  border-top-color: rgb(232 121 249 / 0.35) !important;
}

.tw-border-t-fuchsia-400\/40 {
  border-top-color: rgb(232 121 249 / 0.4) !important;
}

.tw-border-t-fuchsia-400\/45 {
  border-top-color: rgb(232 121 249 / 0.45) !important;
}

.tw-border-t-fuchsia-400\/5 {
  border-top-color: rgb(232 121 249 / 0.05) !important;
}

.tw-border-t-fuchsia-400\/50 {
  border-top-color: rgb(232 121 249 / 0.5) !important;
}

.tw-border-t-fuchsia-400\/55 {
  border-top-color: rgb(232 121 249 / 0.55) !important;
}

.tw-border-t-fuchsia-400\/60 {
  border-top-color: rgb(232 121 249 / 0.6) !important;
}

.tw-border-t-fuchsia-400\/65 {
  border-top-color: rgb(232 121 249 / 0.65) !important;
}

.tw-border-t-fuchsia-400\/70 {
  border-top-color: rgb(232 121 249 / 0.7) !important;
}

.tw-border-t-fuchsia-400\/75 {
  border-top-color: rgb(232 121 249 / 0.75) !important;
}

.tw-border-t-fuchsia-400\/80 {
  border-top-color: rgb(232 121 249 / 0.8) !important;
}

.tw-border-t-fuchsia-400\/85 {
  border-top-color: rgb(232 121 249 / 0.85) !important;
}

.tw-border-t-fuchsia-400\/90 {
  border-top-color: rgb(232 121 249 / 0.9) !important;
}

.tw-border-t-fuchsia-400\/95 {
  border-top-color: rgb(232 121 249 / 0.95) !important;
}

.tw-border-t-fuchsia-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 244 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-50\/0 {
  border-top-color: rgb(253 244 255 / 0) !important;
}

.tw-border-t-fuchsia-50\/10 {
  border-top-color: rgb(253 244 255 / 0.1) !important;
}

.tw-border-t-fuchsia-50\/100 {
  border-top-color: rgb(253 244 255 / 1) !important;
}

.tw-border-t-fuchsia-50\/15 {
  border-top-color: rgb(253 244 255 / 0.15) !important;
}

.tw-border-t-fuchsia-50\/20 {
  border-top-color: rgb(253 244 255 / 0.2) !important;
}

.tw-border-t-fuchsia-50\/25 {
  border-top-color: rgb(253 244 255 / 0.25) !important;
}

.tw-border-t-fuchsia-50\/30 {
  border-top-color: rgb(253 244 255 / 0.3) !important;
}

.tw-border-t-fuchsia-50\/35 {
  border-top-color: rgb(253 244 255 / 0.35) !important;
}

.tw-border-t-fuchsia-50\/40 {
  border-top-color: rgb(253 244 255 / 0.4) !important;
}

.tw-border-t-fuchsia-50\/45 {
  border-top-color: rgb(253 244 255 / 0.45) !important;
}

.tw-border-t-fuchsia-50\/5 {
  border-top-color: rgb(253 244 255 / 0.05) !important;
}

.tw-border-t-fuchsia-50\/50 {
  border-top-color: rgb(253 244 255 / 0.5) !important;
}

.tw-border-t-fuchsia-50\/55 {
  border-top-color: rgb(253 244 255 / 0.55) !important;
}

.tw-border-t-fuchsia-50\/60 {
  border-top-color: rgb(253 244 255 / 0.6) !important;
}

.tw-border-t-fuchsia-50\/65 {
  border-top-color: rgb(253 244 255 / 0.65) !important;
}

.tw-border-t-fuchsia-50\/70 {
  border-top-color: rgb(253 244 255 / 0.7) !important;
}

.tw-border-t-fuchsia-50\/75 {
  border-top-color: rgb(253 244 255 / 0.75) !important;
}

.tw-border-t-fuchsia-50\/80 {
  border-top-color: rgb(253 244 255 / 0.8) !important;
}

.tw-border-t-fuchsia-50\/85 {
  border-top-color: rgb(253 244 255 / 0.85) !important;
}

.tw-border-t-fuchsia-50\/90 {
  border-top-color: rgb(253 244 255 / 0.9) !important;
}

.tw-border-t-fuchsia-50\/95 {
  border-top-color: rgb(253 244 255 / 0.95) !important;
}

.tw-border-t-fuchsia-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(217 70 239 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-500\/0 {
  border-top-color: rgb(217 70 239 / 0) !important;
}

.tw-border-t-fuchsia-500\/10 {
  border-top-color: rgb(217 70 239 / 0.1) !important;
}

.tw-border-t-fuchsia-500\/100 {
  border-top-color: rgb(217 70 239 / 1) !important;
}

.tw-border-t-fuchsia-500\/15 {
  border-top-color: rgb(217 70 239 / 0.15) !important;
}

.tw-border-t-fuchsia-500\/20 {
  border-top-color: rgb(217 70 239 / 0.2) !important;
}

.tw-border-t-fuchsia-500\/25 {
  border-top-color: rgb(217 70 239 / 0.25) !important;
}

.tw-border-t-fuchsia-500\/30 {
  border-top-color: rgb(217 70 239 / 0.3) !important;
}

.tw-border-t-fuchsia-500\/35 {
  border-top-color: rgb(217 70 239 / 0.35) !important;
}

.tw-border-t-fuchsia-500\/40 {
  border-top-color: rgb(217 70 239 / 0.4) !important;
}

.tw-border-t-fuchsia-500\/45 {
  border-top-color: rgb(217 70 239 / 0.45) !important;
}

.tw-border-t-fuchsia-500\/5 {
  border-top-color: rgb(217 70 239 / 0.05) !important;
}

.tw-border-t-fuchsia-500\/50 {
  border-top-color: rgb(217 70 239 / 0.5) !important;
}

.tw-border-t-fuchsia-500\/55 {
  border-top-color: rgb(217 70 239 / 0.55) !important;
}

.tw-border-t-fuchsia-500\/60 {
  border-top-color: rgb(217 70 239 / 0.6) !important;
}

.tw-border-t-fuchsia-500\/65 {
  border-top-color: rgb(217 70 239 / 0.65) !important;
}

.tw-border-t-fuchsia-500\/70 {
  border-top-color: rgb(217 70 239 / 0.7) !important;
}

.tw-border-t-fuchsia-500\/75 {
  border-top-color: rgb(217 70 239 / 0.75) !important;
}

.tw-border-t-fuchsia-500\/80 {
  border-top-color: rgb(217 70 239 / 0.8) !important;
}

.tw-border-t-fuchsia-500\/85 {
  border-top-color: rgb(217 70 239 / 0.85) !important;
}

.tw-border-t-fuchsia-500\/90 {
  border-top-color: rgb(217 70 239 / 0.9) !important;
}

.tw-border-t-fuchsia-500\/95 {
  border-top-color: rgb(217 70 239 / 0.95) !important;
}

.tw-border-t-fuchsia-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(192 38 211 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-600\/0 {
  border-top-color: rgb(192 38 211 / 0) !important;
}

.tw-border-t-fuchsia-600\/10 {
  border-top-color: rgb(192 38 211 / 0.1) !important;
}

.tw-border-t-fuchsia-600\/100 {
  border-top-color: rgb(192 38 211 / 1) !important;
}

.tw-border-t-fuchsia-600\/15 {
  border-top-color: rgb(192 38 211 / 0.15) !important;
}

.tw-border-t-fuchsia-600\/20 {
  border-top-color: rgb(192 38 211 / 0.2) !important;
}

.tw-border-t-fuchsia-600\/25 {
  border-top-color: rgb(192 38 211 / 0.25) !important;
}

.tw-border-t-fuchsia-600\/30 {
  border-top-color: rgb(192 38 211 / 0.3) !important;
}

.tw-border-t-fuchsia-600\/35 {
  border-top-color: rgb(192 38 211 / 0.35) !important;
}

.tw-border-t-fuchsia-600\/40 {
  border-top-color: rgb(192 38 211 / 0.4) !important;
}

.tw-border-t-fuchsia-600\/45 {
  border-top-color: rgb(192 38 211 / 0.45) !important;
}

.tw-border-t-fuchsia-600\/5 {
  border-top-color: rgb(192 38 211 / 0.05) !important;
}

.tw-border-t-fuchsia-600\/50 {
  border-top-color: rgb(192 38 211 / 0.5) !important;
}

.tw-border-t-fuchsia-600\/55 {
  border-top-color: rgb(192 38 211 / 0.55) !important;
}

.tw-border-t-fuchsia-600\/60 {
  border-top-color: rgb(192 38 211 / 0.6) !important;
}

.tw-border-t-fuchsia-600\/65 {
  border-top-color: rgb(192 38 211 / 0.65) !important;
}

.tw-border-t-fuchsia-600\/70 {
  border-top-color: rgb(192 38 211 / 0.7) !important;
}

.tw-border-t-fuchsia-600\/75 {
  border-top-color: rgb(192 38 211 / 0.75) !important;
}

.tw-border-t-fuchsia-600\/80 {
  border-top-color: rgb(192 38 211 / 0.8) !important;
}

.tw-border-t-fuchsia-600\/85 {
  border-top-color: rgb(192 38 211 / 0.85) !important;
}

.tw-border-t-fuchsia-600\/90 {
  border-top-color: rgb(192 38 211 / 0.9) !important;
}

.tw-border-t-fuchsia-600\/95 {
  border-top-color: rgb(192 38 211 / 0.95) !important;
}

.tw-border-t-fuchsia-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(162 28 175 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-700\/0 {
  border-top-color: rgb(162 28 175 / 0) !important;
}

.tw-border-t-fuchsia-700\/10 {
  border-top-color: rgb(162 28 175 / 0.1) !important;
}

.tw-border-t-fuchsia-700\/100 {
  border-top-color: rgb(162 28 175 / 1) !important;
}

.tw-border-t-fuchsia-700\/15 {
  border-top-color: rgb(162 28 175 / 0.15) !important;
}

.tw-border-t-fuchsia-700\/20 {
  border-top-color: rgb(162 28 175 / 0.2) !important;
}

.tw-border-t-fuchsia-700\/25 {
  border-top-color: rgb(162 28 175 / 0.25) !important;
}

.tw-border-t-fuchsia-700\/30 {
  border-top-color: rgb(162 28 175 / 0.3) !important;
}

.tw-border-t-fuchsia-700\/35 {
  border-top-color: rgb(162 28 175 / 0.35) !important;
}

.tw-border-t-fuchsia-700\/40 {
  border-top-color: rgb(162 28 175 / 0.4) !important;
}

.tw-border-t-fuchsia-700\/45 {
  border-top-color: rgb(162 28 175 / 0.45) !important;
}

.tw-border-t-fuchsia-700\/5 {
  border-top-color: rgb(162 28 175 / 0.05) !important;
}

.tw-border-t-fuchsia-700\/50 {
  border-top-color: rgb(162 28 175 / 0.5) !important;
}

.tw-border-t-fuchsia-700\/55 {
  border-top-color: rgb(162 28 175 / 0.55) !important;
}

.tw-border-t-fuchsia-700\/60 {
  border-top-color: rgb(162 28 175 / 0.6) !important;
}

.tw-border-t-fuchsia-700\/65 {
  border-top-color: rgb(162 28 175 / 0.65) !important;
}

.tw-border-t-fuchsia-700\/70 {
  border-top-color: rgb(162 28 175 / 0.7) !important;
}

.tw-border-t-fuchsia-700\/75 {
  border-top-color: rgb(162 28 175 / 0.75) !important;
}

.tw-border-t-fuchsia-700\/80 {
  border-top-color: rgb(162 28 175 / 0.8) !important;
}

.tw-border-t-fuchsia-700\/85 {
  border-top-color: rgb(162 28 175 / 0.85) !important;
}

.tw-border-t-fuchsia-700\/90 {
  border-top-color: rgb(162 28 175 / 0.9) !important;
}

.tw-border-t-fuchsia-700\/95 {
  border-top-color: rgb(162 28 175 / 0.95) !important;
}

.tw-border-t-fuchsia-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(134 25 143 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-800\/0 {
  border-top-color: rgb(134 25 143 / 0) !important;
}

.tw-border-t-fuchsia-800\/10 {
  border-top-color: rgb(134 25 143 / 0.1) !important;
}

.tw-border-t-fuchsia-800\/100 {
  border-top-color: rgb(134 25 143 / 1) !important;
}

.tw-border-t-fuchsia-800\/15 {
  border-top-color: rgb(134 25 143 / 0.15) !important;
}

.tw-border-t-fuchsia-800\/20 {
  border-top-color: rgb(134 25 143 / 0.2) !important;
}

.tw-border-t-fuchsia-800\/25 {
  border-top-color: rgb(134 25 143 / 0.25) !important;
}

.tw-border-t-fuchsia-800\/30 {
  border-top-color: rgb(134 25 143 / 0.3) !important;
}

.tw-border-t-fuchsia-800\/35 {
  border-top-color: rgb(134 25 143 / 0.35) !important;
}

.tw-border-t-fuchsia-800\/40 {
  border-top-color: rgb(134 25 143 / 0.4) !important;
}

.tw-border-t-fuchsia-800\/45 {
  border-top-color: rgb(134 25 143 / 0.45) !important;
}

.tw-border-t-fuchsia-800\/5 {
  border-top-color: rgb(134 25 143 / 0.05) !important;
}

.tw-border-t-fuchsia-800\/50 {
  border-top-color: rgb(134 25 143 / 0.5) !important;
}

.tw-border-t-fuchsia-800\/55 {
  border-top-color: rgb(134 25 143 / 0.55) !important;
}

.tw-border-t-fuchsia-800\/60 {
  border-top-color: rgb(134 25 143 / 0.6) !important;
}

.tw-border-t-fuchsia-800\/65 {
  border-top-color: rgb(134 25 143 / 0.65) !important;
}

.tw-border-t-fuchsia-800\/70 {
  border-top-color: rgb(134 25 143 / 0.7) !important;
}

.tw-border-t-fuchsia-800\/75 {
  border-top-color: rgb(134 25 143 / 0.75) !important;
}

.tw-border-t-fuchsia-800\/80 {
  border-top-color: rgb(134 25 143 / 0.8) !important;
}

.tw-border-t-fuchsia-800\/85 {
  border-top-color: rgb(134 25 143 / 0.85) !important;
}

.tw-border-t-fuchsia-800\/90 {
  border-top-color: rgb(134 25 143 / 0.9) !important;
}

.tw-border-t-fuchsia-800\/95 {
  border-top-color: rgb(134 25 143 / 0.95) !important;
}

.tw-border-t-fuchsia-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(112 26 117 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-900\/0 {
  border-top-color: rgb(112 26 117 / 0) !important;
}

.tw-border-t-fuchsia-900\/10 {
  border-top-color: rgb(112 26 117 / 0.1) !important;
}

.tw-border-t-fuchsia-900\/100 {
  border-top-color: rgb(112 26 117 / 1) !important;
}

.tw-border-t-fuchsia-900\/15 {
  border-top-color: rgb(112 26 117 / 0.15) !important;
}

.tw-border-t-fuchsia-900\/20 {
  border-top-color: rgb(112 26 117 / 0.2) !important;
}

.tw-border-t-fuchsia-900\/25 {
  border-top-color: rgb(112 26 117 / 0.25) !important;
}

.tw-border-t-fuchsia-900\/30 {
  border-top-color: rgb(112 26 117 / 0.3) !important;
}

.tw-border-t-fuchsia-900\/35 {
  border-top-color: rgb(112 26 117 / 0.35) !important;
}

.tw-border-t-fuchsia-900\/40 {
  border-top-color: rgb(112 26 117 / 0.4) !important;
}

.tw-border-t-fuchsia-900\/45 {
  border-top-color: rgb(112 26 117 / 0.45) !important;
}

.tw-border-t-fuchsia-900\/5 {
  border-top-color: rgb(112 26 117 / 0.05) !important;
}

.tw-border-t-fuchsia-900\/50 {
  border-top-color: rgb(112 26 117 / 0.5) !important;
}

.tw-border-t-fuchsia-900\/55 {
  border-top-color: rgb(112 26 117 / 0.55) !important;
}

.tw-border-t-fuchsia-900\/60 {
  border-top-color: rgb(112 26 117 / 0.6) !important;
}

.tw-border-t-fuchsia-900\/65 {
  border-top-color: rgb(112 26 117 / 0.65) !important;
}

.tw-border-t-fuchsia-900\/70 {
  border-top-color: rgb(112 26 117 / 0.7) !important;
}

.tw-border-t-fuchsia-900\/75 {
  border-top-color: rgb(112 26 117 / 0.75) !important;
}

.tw-border-t-fuchsia-900\/80 {
  border-top-color: rgb(112 26 117 / 0.8) !important;
}

.tw-border-t-fuchsia-900\/85 {
  border-top-color: rgb(112 26 117 / 0.85) !important;
}

.tw-border-t-fuchsia-900\/90 {
  border-top-color: rgb(112 26 117 / 0.9) !important;
}

.tw-border-t-fuchsia-900\/95 {
  border-top-color: rgb(112 26 117 / 0.95) !important;
}

.tw-border-t-fuchsia-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(74 4 78 / var(--tw-border-opacity)) !important;
}

.tw-border-t-fuchsia-950\/0 {
  border-top-color: rgb(74 4 78 / 0) !important;
}

.tw-border-t-fuchsia-950\/10 {
  border-top-color: rgb(74 4 78 / 0.1) !important;
}

.tw-border-t-fuchsia-950\/100 {
  border-top-color: rgb(74 4 78 / 1) !important;
}

.tw-border-t-fuchsia-950\/15 {
  border-top-color: rgb(74 4 78 / 0.15) !important;
}

.tw-border-t-fuchsia-950\/20 {
  border-top-color: rgb(74 4 78 / 0.2) !important;
}

.tw-border-t-fuchsia-950\/25 {
  border-top-color: rgb(74 4 78 / 0.25) !important;
}

.tw-border-t-fuchsia-950\/30 {
  border-top-color: rgb(74 4 78 / 0.3) !important;
}

.tw-border-t-fuchsia-950\/35 {
  border-top-color: rgb(74 4 78 / 0.35) !important;
}

.tw-border-t-fuchsia-950\/40 {
  border-top-color: rgb(74 4 78 / 0.4) !important;
}

.tw-border-t-fuchsia-950\/45 {
  border-top-color: rgb(74 4 78 / 0.45) !important;
}

.tw-border-t-fuchsia-950\/5 {
  border-top-color: rgb(74 4 78 / 0.05) !important;
}

.tw-border-t-fuchsia-950\/50 {
  border-top-color: rgb(74 4 78 / 0.5) !important;
}

.tw-border-t-fuchsia-950\/55 {
  border-top-color: rgb(74 4 78 / 0.55) !important;
}

.tw-border-t-fuchsia-950\/60 {
  border-top-color: rgb(74 4 78 / 0.6) !important;
}

.tw-border-t-fuchsia-950\/65 {
  border-top-color: rgb(74 4 78 / 0.65) !important;
}

.tw-border-t-fuchsia-950\/70 {
  border-top-color: rgb(74 4 78 / 0.7) !important;
}

.tw-border-t-fuchsia-950\/75 {
  border-top-color: rgb(74 4 78 / 0.75) !important;
}

.tw-border-t-fuchsia-950\/80 {
  border-top-color: rgb(74 4 78 / 0.8) !important;
}

.tw-border-t-fuchsia-950\/85 {
  border-top-color: rgb(74 4 78 / 0.85) !important;
}

.tw-border-t-fuchsia-950\/90 {
  border-top-color: rgb(74 4 78 / 0.9) !important;
}

.tw-border-t-fuchsia-950\/95 {
  border-top-color: rgb(74 4 78 / 0.95) !important;
}

.tw-border-t-gray-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(243 244 246 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-100\/0 {
  border-top-color: rgb(243 244 246 / 0) !important;
}

.tw-border-t-gray-100\/10 {
  border-top-color: rgb(243 244 246 / 0.1) !important;
}

.tw-border-t-gray-100\/100 {
  border-top-color: rgb(243 244 246 / 1) !important;
}

.tw-border-t-gray-100\/15 {
  border-top-color: rgb(243 244 246 / 0.15) !important;
}

.tw-border-t-gray-100\/20 {
  border-top-color: rgb(243 244 246 / 0.2) !important;
}

.tw-border-t-gray-100\/25 {
  border-top-color: rgb(243 244 246 / 0.25) !important;
}

.tw-border-t-gray-100\/30 {
  border-top-color: rgb(243 244 246 / 0.3) !important;
}

.tw-border-t-gray-100\/35 {
  border-top-color: rgb(243 244 246 / 0.35) !important;
}

.tw-border-t-gray-100\/40 {
  border-top-color: rgb(243 244 246 / 0.4) !important;
}

.tw-border-t-gray-100\/45 {
  border-top-color: rgb(243 244 246 / 0.45) !important;
}

.tw-border-t-gray-100\/5 {
  border-top-color: rgb(243 244 246 / 0.05) !important;
}

.tw-border-t-gray-100\/50 {
  border-top-color: rgb(243 244 246 / 0.5) !important;
}

.tw-border-t-gray-100\/55 {
  border-top-color: rgb(243 244 246 / 0.55) !important;
}

.tw-border-t-gray-100\/60 {
  border-top-color: rgb(243 244 246 / 0.6) !important;
}

.tw-border-t-gray-100\/65 {
  border-top-color: rgb(243 244 246 / 0.65) !important;
}

.tw-border-t-gray-100\/70 {
  border-top-color: rgb(243 244 246 / 0.7) !important;
}

.tw-border-t-gray-100\/75 {
  border-top-color: rgb(243 244 246 / 0.75) !important;
}

.tw-border-t-gray-100\/80 {
  border-top-color: rgb(243 244 246 / 0.8) !important;
}

.tw-border-t-gray-100\/85 {
  border-top-color: rgb(243 244 246 / 0.85) !important;
}

.tw-border-t-gray-100\/90 {
  border-top-color: rgb(243 244 246 / 0.9) !important;
}

.tw-border-t-gray-100\/95 {
  border-top-color: rgb(243 244 246 / 0.95) !important;
}

.tw-border-t-gray-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(229 231 235 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-200\/0 {
  border-top-color: rgb(229 231 235 / 0) !important;
}

.tw-border-t-gray-200\/10 {
  border-top-color: rgb(229 231 235 / 0.1) !important;
}

.tw-border-t-gray-200\/100 {
  border-top-color: rgb(229 231 235 / 1) !important;
}

.tw-border-t-gray-200\/15 {
  border-top-color: rgb(229 231 235 / 0.15) !important;
}

.tw-border-t-gray-200\/20 {
  border-top-color: rgb(229 231 235 / 0.2) !important;
}

.tw-border-t-gray-200\/25 {
  border-top-color: rgb(229 231 235 / 0.25) !important;
}

.tw-border-t-gray-200\/30 {
  border-top-color: rgb(229 231 235 / 0.3) !important;
}

.tw-border-t-gray-200\/35 {
  border-top-color: rgb(229 231 235 / 0.35) !important;
}

.tw-border-t-gray-200\/40 {
  border-top-color: rgb(229 231 235 / 0.4) !important;
}

.tw-border-t-gray-200\/45 {
  border-top-color: rgb(229 231 235 / 0.45) !important;
}

.tw-border-t-gray-200\/5 {
  border-top-color: rgb(229 231 235 / 0.05) !important;
}

.tw-border-t-gray-200\/50 {
  border-top-color: rgb(229 231 235 / 0.5) !important;
}

.tw-border-t-gray-200\/55 {
  border-top-color: rgb(229 231 235 / 0.55) !important;
}

.tw-border-t-gray-200\/60 {
  border-top-color: rgb(229 231 235 / 0.6) !important;
}

.tw-border-t-gray-200\/65 {
  border-top-color: rgb(229 231 235 / 0.65) !important;
}

.tw-border-t-gray-200\/70 {
  border-top-color: rgb(229 231 235 / 0.7) !important;
}

.tw-border-t-gray-200\/75 {
  border-top-color: rgb(229 231 235 / 0.75) !important;
}

.tw-border-t-gray-200\/80 {
  border-top-color: rgb(229 231 235 / 0.8) !important;
}

.tw-border-t-gray-200\/85 {
  border-top-color: rgb(229 231 235 / 0.85) !important;
}

.tw-border-t-gray-200\/90 {
  border-top-color: rgb(229 231 235 / 0.9) !important;
}

.tw-border-t-gray-200\/95 {
  border-top-color: rgb(229 231 235 / 0.95) !important;
}

.tw-border-t-gray-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(209 213 219 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-300\/0 {
  border-top-color: rgb(209 213 219 / 0) !important;
}

.tw-border-t-gray-300\/10 {
  border-top-color: rgb(209 213 219 / 0.1) !important;
}

.tw-border-t-gray-300\/100 {
  border-top-color: rgb(209 213 219 / 1) !important;
}

.tw-border-t-gray-300\/15 {
  border-top-color: rgb(209 213 219 / 0.15) !important;
}

.tw-border-t-gray-300\/20 {
  border-top-color: rgb(209 213 219 / 0.2) !important;
}

.tw-border-t-gray-300\/25 {
  border-top-color: rgb(209 213 219 / 0.25) !important;
}

.tw-border-t-gray-300\/30 {
  border-top-color: rgb(209 213 219 / 0.3) !important;
}

.tw-border-t-gray-300\/35 {
  border-top-color: rgb(209 213 219 / 0.35) !important;
}

.tw-border-t-gray-300\/40 {
  border-top-color: rgb(209 213 219 / 0.4) !important;
}

.tw-border-t-gray-300\/45 {
  border-top-color: rgb(209 213 219 / 0.45) !important;
}

.tw-border-t-gray-300\/5 {
  border-top-color: rgb(209 213 219 / 0.05) !important;
}

.tw-border-t-gray-300\/50 {
  border-top-color: rgb(209 213 219 / 0.5) !important;
}

.tw-border-t-gray-300\/55 {
  border-top-color: rgb(209 213 219 / 0.55) !important;
}

.tw-border-t-gray-300\/60 {
  border-top-color: rgb(209 213 219 / 0.6) !important;
}

.tw-border-t-gray-300\/65 {
  border-top-color: rgb(209 213 219 / 0.65) !important;
}

.tw-border-t-gray-300\/70 {
  border-top-color: rgb(209 213 219 / 0.7) !important;
}

.tw-border-t-gray-300\/75 {
  border-top-color: rgb(209 213 219 / 0.75) !important;
}

.tw-border-t-gray-300\/80 {
  border-top-color: rgb(209 213 219 / 0.8) !important;
}

.tw-border-t-gray-300\/85 {
  border-top-color: rgb(209 213 219 / 0.85) !important;
}

.tw-border-t-gray-300\/90 {
  border-top-color: rgb(209 213 219 / 0.9) !important;
}

.tw-border-t-gray-300\/95 {
  border-top-color: rgb(209 213 219 / 0.95) !important;
}

.tw-border-t-gray-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(156 163 175 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-400\/0 {
  border-top-color: rgb(156 163 175 / 0) !important;
}

.tw-border-t-gray-400\/10 {
  border-top-color: rgb(156 163 175 / 0.1) !important;
}

.tw-border-t-gray-400\/100 {
  border-top-color: rgb(156 163 175 / 1) !important;
}

.tw-border-t-gray-400\/15 {
  border-top-color: rgb(156 163 175 / 0.15) !important;
}

.tw-border-t-gray-400\/20 {
  border-top-color: rgb(156 163 175 / 0.2) !important;
}

.tw-border-t-gray-400\/25 {
  border-top-color: rgb(156 163 175 / 0.25) !important;
}

.tw-border-t-gray-400\/30 {
  border-top-color: rgb(156 163 175 / 0.3) !important;
}

.tw-border-t-gray-400\/35 {
  border-top-color: rgb(156 163 175 / 0.35) !important;
}

.tw-border-t-gray-400\/40 {
  border-top-color: rgb(156 163 175 / 0.4) !important;
}

.tw-border-t-gray-400\/45 {
  border-top-color: rgb(156 163 175 / 0.45) !important;
}

.tw-border-t-gray-400\/5 {
  border-top-color: rgb(156 163 175 / 0.05) !important;
}

.tw-border-t-gray-400\/50 {
  border-top-color: rgb(156 163 175 / 0.5) !important;
}

.tw-border-t-gray-400\/55 {
  border-top-color: rgb(156 163 175 / 0.55) !important;
}

.tw-border-t-gray-400\/60 {
  border-top-color: rgb(156 163 175 / 0.6) !important;
}

.tw-border-t-gray-400\/65 {
  border-top-color: rgb(156 163 175 / 0.65) !important;
}

.tw-border-t-gray-400\/70 {
  border-top-color: rgb(156 163 175 / 0.7) !important;
}

.tw-border-t-gray-400\/75 {
  border-top-color: rgb(156 163 175 / 0.75) !important;
}

.tw-border-t-gray-400\/80 {
  border-top-color: rgb(156 163 175 / 0.8) !important;
}

.tw-border-t-gray-400\/85 {
  border-top-color: rgb(156 163 175 / 0.85) !important;
}

.tw-border-t-gray-400\/90 {
  border-top-color: rgb(156 163 175 / 0.9) !important;
}

.tw-border-t-gray-400\/95 {
  border-top-color: rgb(156 163 175 / 0.95) !important;
}

.tw-border-t-gray-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(249 250 251 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-50\/0 {
  border-top-color: rgb(249 250 251 / 0) !important;
}

.tw-border-t-gray-50\/10 {
  border-top-color: rgb(249 250 251 / 0.1) !important;
}

.tw-border-t-gray-50\/100 {
  border-top-color: rgb(249 250 251 / 1) !important;
}

.tw-border-t-gray-50\/15 {
  border-top-color: rgb(249 250 251 / 0.15) !important;
}

.tw-border-t-gray-50\/20 {
  border-top-color: rgb(249 250 251 / 0.2) !important;
}

.tw-border-t-gray-50\/25 {
  border-top-color: rgb(249 250 251 / 0.25) !important;
}

.tw-border-t-gray-50\/30 {
  border-top-color: rgb(249 250 251 / 0.3) !important;
}

.tw-border-t-gray-50\/35 {
  border-top-color: rgb(249 250 251 / 0.35) !important;
}

.tw-border-t-gray-50\/40 {
  border-top-color: rgb(249 250 251 / 0.4) !important;
}

.tw-border-t-gray-50\/45 {
  border-top-color: rgb(249 250 251 / 0.45) !important;
}

.tw-border-t-gray-50\/5 {
  border-top-color: rgb(249 250 251 / 0.05) !important;
}

.tw-border-t-gray-50\/50 {
  border-top-color: rgb(249 250 251 / 0.5) !important;
}

.tw-border-t-gray-50\/55 {
  border-top-color: rgb(249 250 251 / 0.55) !important;
}

.tw-border-t-gray-50\/60 {
  border-top-color: rgb(249 250 251 / 0.6) !important;
}

.tw-border-t-gray-50\/65 {
  border-top-color: rgb(249 250 251 / 0.65) !important;
}

.tw-border-t-gray-50\/70 {
  border-top-color: rgb(249 250 251 / 0.7) !important;
}

.tw-border-t-gray-50\/75 {
  border-top-color: rgb(249 250 251 / 0.75) !important;
}

.tw-border-t-gray-50\/80 {
  border-top-color: rgb(249 250 251 / 0.8) !important;
}

.tw-border-t-gray-50\/85 {
  border-top-color: rgb(249 250 251 / 0.85) !important;
}

.tw-border-t-gray-50\/90 {
  border-top-color: rgb(249 250 251 / 0.9) !important;
}

.tw-border-t-gray-50\/95 {
  border-top-color: rgb(249 250 251 / 0.95) !important;
}

.tw-border-t-gray-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(107 114 128 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-500\/0 {
  border-top-color: rgb(107 114 128 / 0) !important;
}

.tw-border-t-gray-500\/10 {
  border-top-color: rgb(107 114 128 / 0.1) !important;
}

.tw-border-t-gray-500\/100 {
  border-top-color: rgb(107 114 128 / 1) !important;
}

.tw-border-t-gray-500\/15 {
  border-top-color: rgb(107 114 128 / 0.15) !important;
}

.tw-border-t-gray-500\/20 {
  border-top-color: rgb(107 114 128 / 0.2) !important;
}

.tw-border-t-gray-500\/25 {
  border-top-color: rgb(107 114 128 / 0.25) !important;
}

.tw-border-t-gray-500\/30 {
  border-top-color: rgb(107 114 128 / 0.3) !important;
}

.tw-border-t-gray-500\/35 {
  border-top-color: rgb(107 114 128 / 0.35) !important;
}

.tw-border-t-gray-500\/40 {
  border-top-color: rgb(107 114 128 / 0.4) !important;
}

.tw-border-t-gray-500\/45 {
  border-top-color: rgb(107 114 128 / 0.45) !important;
}

.tw-border-t-gray-500\/5 {
  border-top-color: rgb(107 114 128 / 0.05) !important;
}

.tw-border-t-gray-500\/50 {
  border-top-color: rgb(107 114 128 / 0.5) !important;
}

.tw-border-t-gray-500\/55 {
  border-top-color: rgb(107 114 128 / 0.55) !important;
}

.tw-border-t-gray-500\/60 {
  border-top-color: rgb(107 114 128 / 0.6) !important;
}

.tw-border-t-gray-500\/65 {
  border-top-color: rgb(107 114 128 / 0.65) !important;
}

.tw-border-t-gray-500\/70 {
  border-top-color: rgb(107 114 128 / 0.7) !important;
}

.tw-border-t-gray-500\/75 {
  border-top-color: rgb(107 114 128 / 0.75) !important;
}

.tw-border-t-gray-500\/80 {
  border-top-color: rgb(107 114 128 / 0.8) !important;
}

.tw-border-t-gray-500\/85 {
  border-top-color: rgb(107 114 128 / 0.85) !important;
}

.tw-border-t-gray-500\/90 {
  border-top-color: rgb(107 114 128 / 0.9) !important;
}

.tw-border-t-gray-500\/95 {
  border-top-color: rgb(107 114 128 / 0.95) !important;
}

.tw-border-t-gray-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(75 85 99 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-600\/0 {
  border-top-color: rgb(75 85 99 / 0) !important;
}

.tw-border-t-gray-600\/10 {
  border-top-color: rgb(75 85 99 / 0.1) !important;
}

.tw-border-t-gray-600\/100 {
  border-top-color: rgb(75 85 99 / 1) !important;
}

.tw-border-t-gray-600\/15 {
  border-top-color: rgb(75 85 99 / 0.15) !important;
}

.tw-border-t-gray-600\/20 {
  border-top-color: rgb(75 85 99 / 0.2) !important;
}

.tw-border-t-gray-600\/25 {
  border-top-color: rgb(75 85 99 / 0.25) !important;
}

.tw-border-t-gray-600\/30 {
  border-top-color: rgb(75 85 99 / 0.3) !important;
}

.tw-border-t-gray-600\/35 {
  border-top-color: rgb(75 85 99 / 0.35) !important;
}

.tw-border-t-gray-600\/40 {
  border-top-color: rgb(75 85 99 / 0.4) !important;
}

.tw-border-t-gray-600\/45 {
  border-top-color: rgb(75 85 99 / 0.45) !important;
}

.tw-border-t-gray-600\/5 {
  border-top-color: rgb(75 85 99 / 0.05) !important;
}

.tw-border-t-gray-600\/50 {
  border-top-color: rgb(75 85 99 / 0.5) !important;
}

.tw-border-t-gray-600\/55 {
  border-top-color: rgb(75 85 99 / 0.55) !important;
}

.tw-border-t-gray-600\/60 {
  border-top-color: rgb(75 85 99 / 0.6) !important;
}

.tw-border-t-gray-600\/65 {
  border-top-color: rgb(75 85 99 / 0.65) !important;
}

.tw-border-t-gray-600\/70 {
  border-top-color: rgb(75 85 99 / 0.7) !important;
}

.tw-border-t-gray-600\/75 {
  border-top-color: rgb(75 85 99 / 0.75) !important;
}

.tw-border-t-gray-600\/80 {
  border-top-color: rgb(75 85 99 / 0.8) !important;
}

.tw-border-t-gray-600\/85 {
  border-top-color: rgb(75 85 99 / 0.85) !important;
}

.tw-border-t-gray-600\/90 {
  border-top-color: rgb(75 85 99 / 0.9) !important;
}

.tw-border-t-gray-600\/95 {
  border-top-color: rgb(75 85 99 / 0.95) !important;
}

.tw-border-t-gray-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(55 65 81 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-700\/0 {
  border-top-color: rgb(55 65 81 / 0) !important;
}

.tw-border-t-gray-700\/10 {
  border-top-color: rgb(55 65 81 / 0.1) !important;
}

.tw-border-t-gray-700\/100 {
  border-top-color: rgb(55 65 81 / 1) !important;
}

.tw-border-t-gray-700\/15 {
  border-top-color: rgb(55 65 81 / 0.15) !important;
}

.tw-border-t-gray-700\/20 {
  border-top-color: rgb(55 65 81 / 0.2) !important;
}

.tw-border-t-gray-700\/25 {
  border-top-color: rgb(55 65 81 / 0.25) !important;
}

.tw-border-t-gray-700\/30 {
  border-top-color: rgb(55 65 81 / 0.3) !important;
}

.tw-border-t-gray-700\/35 {
  border-top-color: rgb(55 65 81 / 0.35) !important;
}

.tw-border-t-gray-700\/40 {
  border-top-color: rgb(55 65 81 / 0.4) !important;
}

.tw-border-t-gray-700\/45 {
  border-top-color: rgb(55 65 81 / 0.45) !important;
}

.tw-border-t-gray-700\/5 {
  border-top-color: rgb(55 65 81 / 0.05) !important;
}

.tw-border-t-gray-700\/50 {
  border-top-color: rgb(55 65 81 / 0.5) !important;
}

.tw-border-t-gray-700\/55 {
  border-top-color: rgb(55 65 81 / 0.55) !important;
}

.tw-border-t-gray-700\/60 {
  border-top-color: rgb(55 65 81 / 0.6) !important;
}

.tw-border-t-gray-700\/65 {
  border-top-color: rgb(55 65 81 / 0.65) !important;
}

.tw-border-t-gray-700\/70 {
  border-top-color: rgb(55 65 81 / 0.7) !important;
}

.tw-border-t-gray-700\/75 {
  border-top-color: rgb(55 65 81 / 0.75) !important;
}

.tw-border-t-gray-700\/80 {
  border-top-color: rgb(55 65 81 / 0.8) !important;
}

.tw-border-t-gray-700\/85 {
  border-top-color: rgb(55 65 81 / 0.85) !important;
}

.tw-border-t-gray-700\/90 {
  border-top-color: rgb(55 65 81 / 0.9) !important;
}

.tw-border-t-gray-700\/95 {
  border-top-color: rgb(55 65 81 / 0.95) !important;
}

.tw-border-t-gray-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(31 41 55 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-800\/0 {
  border-top-color: rgb(31 41 55 / 0) !important;
}

.tw-border-t-gray-800\/10 {
  border-top-color: rgb(31 41 55 / 0.1) !important;
}

.tw-border-t-gray-800\/100 {
  border-top-color: rgb(31 41 55 / 1) !important;
}

.tw-border-t-gray-800\/15 {
  border-top-color: rgb(31 41 55 / 0.15) !important;
}

.tw-border-t-gray-800\/20 {
  border-top-color: rgb(31 41 55 / 0.2) !important;
}

.tw-border-t-gray-800\/25 {
  border-top-color: rgb(31 41 55 / 0.25) !important;
}

.tw-border-t-gray-800\/30 {
  border-top-color: rgb(31 41 55 / 0.3) !important;
}

.tw-border-t-gray-800\/35 {
  border-top-color: rgb(31 41 55 / 0.35) !important;
}

.tw-border-t-gray-800\/40 {
  border-top-color: rgb(31 41 55 / 0.4) !important;
}

.tw-border-t-gray-800\/45 {
  border-top-color: rgb(31 41 55 / 0.45) !important;
}

.tw-border-t-gray-800\/5 {
  border-top-color: rgb(31 41 55 / 0.05) !important;
}

.tw-border-t-gray-800\/50 {
  border-top-color: rgb(31 41 55 / 0.5) !important;
}

.tw-border-t-gray-800\/55 {
  border-top-color: rgb(31 41 55 / 0.55) !important;
}

.tw-border-t-gray-800\/60 {
  border-top-color: rgb(31 41 55 / 0.6) !important;
}

.tw-border-t-gray-800\/65 {
  border-top-color: rgb(31 41 55 / 0.65) !important;
}

.tw-border-t-gray-800\/70 {
  border-top-color: rgb(31 41 55 / 0.7) !important;
}

.tw-border-t-gray-800\/75 {
  border-top-color: rgb(31 41 55 / 0.75) !important;
}

.tw-border-t-gray-800\/80 {
  border-top-color: rgb(31 41 55 / 0.8) !important;
}

.tw-border-t-gray-800\/85 {
  border-top-color: rgb(31 41 55 / 0.85) !important;
}

.tw-border-t-gray-800\/90 {
  border-top-color: rgb(31 41 55 / 0.9) !important;
}

.tw-border-t-gray-800\/95 {
  border-top-color: rgb(31 41 55 / 0.95) !important;
}

.tw-border-t-gray-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(17 24 39 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-900\/0 {
  border-top-color: rgb(17 24 39 / 0) !important;
}

.tw-border-t-gray-900\/10 {
  border-top-color: rgb(17 24 39 / 0.1) !important;
}

.tw-border-t-gray-900\/100 {
  border-top-color: rgb(17 24 39 / 1) !important;
}

.tw-border-t-gray-900\/15 {
  border-top-color: rgb(17 24 39 / 0.15) !important;
}

.tw-border-t-gray-900\/20 {
  border-top-color: rgb(17 24 39 / 0.2) !important;
}

.tw-border-t-gray-900\/25 {
  border-top-color: rgb(17 24 39 / 0.25) !important;
}

.tw-border-t-gray-900\/30 {
  border-top-color: rgb(17 24 39 / 0.3) !important;
}

.tw-border-t-gray-900\/35 {
  border-top-color: rgb(17 24 39 / 0.35) !important;
}

.tw-border-t-gray-900\/40 {
  border-top-color: rgb(17 24 39 / 0.4) !important;
}

.tw-border-t-gray-900\/45 {
  border-top-color: rgb(17 24 39 / 0.45) !important;
}

.tw-border-t-gray-900\/5 {
  border-top-color: rgb(17 24 39 / 0.05) !important;
}

.tw-border-t-gray-900\/50 {
  border-top-color: rgb(17 24 39 / 0.5) !important;
}

.tw-border-t-gray-900\/55 {
  border-top-color: rgb(17 24 39 / 0.55) !important;
}

.tw-border-t-gray-900\/60 {
  border-top-color: rgb(17 24 39 / 0.6) !important;
}

.tw-border-t-gray-900\/65 {
  border-top-color: rgb(17 24 39 / 0.65) !important;
}

.tw-border-t-gray-900\/70 {
  border-top-color: rgb(17 24 39 / 0.7) !important;
}

.tw-border-t-gray-900\/75 {
  border-top-color: rgb(17 24 39 / 0.75) !important;
}

.tw-border-t-gray-900\/80 {
  border-top-color: rgb(17 24 39 / 0.8) !important;
}

.tw-border-t-gray-900\/85 {
  border-top-color: rgb(17 24 39 / 0.85) !important;
}

.tw-border-t-gray-900\/90 {
  border-top-color: rgb(17 24 39 / 0.9) !important;
}

.tw-border-t-gray-900\/95 {
  border-top-color: rgb(17 24 39 / 0.95) !important;
}

.tw-border-t-gray-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(3 7 18 / var(--tw-border-opacity)) !important;
}

.tw-border-t-gray-950\/0 {
  border-top-color: rgb(3 7 18 / 0) !important;
}

.tw-border-t-gray-950\/10 {
  border-top-color: rgb(3 7 18 / 0.1) !important;
}

.tw-border-t-gray-950\/100 {
  border-top-color: rgb(3 7 18 / 1) !important;
}

.tw-border-t-gray-950\/15 {
  border-top-color: rgb(3 7 18 / 0.15) !important;
}

.tw-border-t-gray-950\/20 {
  border-top-color: rgb(3 7 18 / 0.2) !important;
}

.tw-border-t-gray-950\/25 {
  border-top-color: rgb(3 7 18 / 0.25) !important;
}

.tw-border-t-gray-950\/30 {
  border-top-color: rgb(3 7 18 / 0.3) !important;
}

.tw-border-t-gray-950\/35 {
  border-top-color: rgb(3 7 18 / 0.35) !important;
}

.tw-border-t-gray-950\/40 {
  border-top-color: rgb(3 7 18 / 0.4) !important;
}

.tw-border-t-gray-950\/45 {
  border-top-color: rgb(3 7 18 / 0.45) !important;
}

.tw-border-t-gray-950\/5 {
  border-top-color: rgb(3 7 18 / 0.05) !important;
}

.tw-border-t-gray-950\/50 {
  border-top-color: rgb(3 7 18 / 0.5) !important;
}

.tw-border-t-gray-950\/55 {
  border-top-color: rgb(3 7 18 / 0.55) !important;
}

.tw-border-t-gray-950\/60 {
  border-top-color: rgb(3 7 18 / 0.6) !important;
}

.tw-border-t-gray-950\/65 {
  border-top-color: rgb(3 7 18 / 0.65) !important;
}

.tw-border-t-gray-950\/70 {
  border-top-color: rgb(3 7 18 / 0.7) !important;
}

.tw-border-t-gray-950\/75 {
  border-top-color: rgb(3 7 18 / 0.75) !important;
}

.tw-border-t-gray-950\/80 {
  border-top-color: rgb(3 7 18 / 0.8) !important;
}

.tw-border-t-gray-950\/85 {
  border-top-color: rgb(3 7 18 / 0.85) !important;
}

.tw-border-t-gray-950\/90 {
  border-top-color: rgb(3 7 18 / 0.9) !important;
}

.tw-border-t-gray-950\/95 {
  border-top-color: rgb(3 7 18 / 0.95) !important;
}

.tw-border-t-green-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(220 252 231 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-100\/0 {
  border-top-color: rgb(220 252 231 / 0) !important;
}

.tw-border-t-green-100\/10 {
  border-top-color: rgb(220 252 231 / 0.1) !important;
}

.tw-border-t-green-100\/100 {
  border-top-color: rgb(220 252 231 / 1) !important;
}

.tw-border-t-green-100\/15 {
  border-top-color: rgb(220 252 231 / 0.15) !important;
}

.tw-border-t-green-100\/20 {
  border-top-color: rgb(220 252 231 / 0.2) !important;
}

.tw-border-t-green-100\/25 {
  border-top-color: rgb(220 252 231 / 0.25) !important;
}

.tw-border-t-green-100\/30 {
  border-top-color: rgb(220 252 231 / 0.3) !important;
}

.tw-border-t-green-100\/35 {
  border-top-color: rgb(220 252 231 / 0.35) !important;
}

.tw-border-t-green-100\/40 {
  border-top-color: rgb(220 252 231 / 0.4) !important;
}

.tw-border-t-green-100\/45 {
  border-top-color: rgb(220 252 231 / 0.45) !important;
}

.tw-border-t-green-100\/5 {
  border-top-color: rgb(220 252 231 / 0.05) !important;
}

.tw-border-t-green-100\/50 {
  border-top-color: rgb(220 252 231 / 0.5) !important;
}

.tw-border-t-green-100\/55 {
  border-top-color: rgb(220 252 231 / 0.55) !important;
}

.tw-border-t-green-100\/60 {
  border-top-color: rgb(220 252 231 / 0.6) !important;
}

.tw-border-t-green-100\/65 {
  border-top-color: rgb(220 252 231 / 0.65) !important;
}

.tw-border-t-green-100\/70 {
  border-top-color: rgb(220 252 231 / 0.7) !important;
}

.tw-border-t-green-100\/75 {
  border-top-color: rgb(220 252 231 / 0.75) !important;
}

.tw-border-t-green-100\/80 {
  border-top-color: rgb(220 252 231 / 0.8) !important;
}

.tw-border-t-green-100\/85 {
  border-top-color: rgb(220 252 231 / 0.85) !important;
}

.tw-border-t-green-100\/90 {
  border-top-color: rgb(220 252 231 / 0.9) !important;
}

.tw-border-t-green-100\/95 {
  border-top-color: rgb(220 252 231 / 0.95) !important;
}

.tw-border-t-green-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(187 247 208 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-200\/0 {
  border-top-color: rgb(187 247 208 / 0) !important;
}

.tw-border-t-green-200\/10 {
  border-top-color: rgb(187 247 208 / 0.1) !important;
}

.tw-border-t-green-200\/100 {
  border-top-color: rgb(187 247 208 / 1) !important;
}

.tw-border-t-green-200\/15 {
  border-top-color: rgb(187 247 208 / 0.15) !important;
}

.tw-border-t-green-200\/20 {
  border-top-color: rgb(187 247 208 / 0.2) !important;
}

.tw-border-t-green-200\/25 {
  border-top-color: rgb(187 247 208 / 0.25) !important;
}

.tw-border-t-green-200\/30 {
  border-top-color: rgb(187 247 208 / 0.3) !important;
}

.tw-border-t-green-200\/35 {
  border-top-color: rgb(187 247 208 / 0.35) !important;
}

.tw-border-t-green-200\/40 {
  border-top-color: rgb(187 247 208 / 0.4) !important;
}

.tw-border-t-green-200\/45 {
  border-top-color: rgb(187 247 208 / 0.45) !important;
}

.tw-border-t-green-200\/5 {
  border-top-color: rgb(187 247 208 / 0.05) !important;
}

.tw-border-t-green-200\/50 {
  border-top-color: rgb(187 247 208 / 0.5) !important;
}

.tw-border-t-green-200\/55 {
  border-top-color: rgb(187 247 208 / 0.55) !important;
}

.tw-border-t-green-200\/60 {
  border-top-color: rgb(187 247 208 / 0.6) !important;
}

.tw-border-t-green-200\/65 {
  border-top-color: rgb(187 247 208 / 0.65) !important;
}

.tw-border-t-green-200\/70 {
  border-top-color: rgb(187 247 208 / 0.7) !important;
}

.tw-border-t-green-200\/75 {
  border-top-color: rgb(187 247 208 / 0.75) !important;
}

.tw-border-t-green-200\/80 {
  border-top-color: rgb(187 247 208 / 0.8) !important;
}

.tw-border-t-green-200\/85 {
  border-top-color: rgb(187 247 208 / 0.85) !important;
}

.tw-border-t-green-200\/90 {
  border-top-color: rgb(187 247 208 / 0.9) !important;
}

.tw-border-t-green-200\/95 {
  border-top-color: rgb(187 247 208 / 0.95) !important;
}

.tw-border-t-green-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(134 239 172 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-300\/0 {
  border-top-color: rgb(134 239 172 / 0) !important;
}

.tw-border-t-green-300\/10 {
  border-top-color: rgb(134 239 172 / 0.1) !important;
}

.tw-border-t-green-300\/100 {
  border-top-color: rgb(134 239 172 / 1) !important;
}

.tw-border-t-green-300\/15 {
  border-top-color: rgb(134 239 172 / 0.15) !important;
}

.tw-border-t-green-300\/20 {
  border-top-color: rgb(134 239 172 / 0.2) !important;
}

.tw-border-t-green-300\/25 {
  border-top-color: rgb(134 239 172 / 0.25) !important;
}

.tw-border-t-green-300\/30 {
  border-top-color: rgb(134 239 172 / 0.3) !important;
}

.tw-border-t-green-300\/35 {
  border-top-color: rgb(134 239 172 / 0.35) !important;
}

.tw-border-t-green-300\/40 {
  border-top-color: rgb(134 239 172 / 0.4) !important;
}

.tw-border-t-green-300\/45 {
  border-top-color: rgb(134 239 172 / 0.45) !important;
}

.tw-border-t-green-300\/5 {
  border-top-color: rgb(134 239 172 / 0.05) !important;
}

.tw-border-t-green-300\/50 {
  border-top-color: rgb(134 239 172 / 0.5) !important;
}

.tw-border-t-green-300\/55 {
  border-top-color: rgb(134 239 172 / 0.55) !important;
}

.tw-border-t-green-300\/60 {
  border-top-color: rgb(134 239 172 / 0.6) !important;
}

.tw-border-t-green-300\/65 {
  border-top-color: rgb(134 239 172 / 0.65) !important;
}

.tw-border-t-green-300\/70 {
  border-top-color: rgb(134 239 172 / 0.7) !important;
}

.tw-border-t-green-300\/75 {
  border-top-color: rgb(134 239 172 / 0.75) !important;
}

.tw-border-t-green-300\/80 {
  border-top-color: rgb(134 239 172 / 0.8) !important;
}

.tw-border-t-green-300\/85 {
  border-top-color: rgb(134 239 172 / 0.85) !important;
}

.tw-border-t-green-300\/90 {
  border-top-color: rgb(134 239 172 / 0.9) !important;
}

.tw-border-t-green-300\/95 {
  border-top-color: rgb(134 239 172 / 0.95) !important;
}

.tw-border-t-green-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(74 222 128 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-400\/0 {
  border-top-color: rgb(74 222 128 / 0) !important;
}

.tw-border-t-green-400\/10 {
  border-top-color: rgb(74 222 128 / 0.1) !important;
}

.tw-border-t-green-400\/100 {
  border-top-color: rgb(74 222 128 / 1) !important;
}

.tw-border-t-green-400\/15 {
  border-top-color: rgb(74 222 128 / 0.15) !important;
}

.tw-border-t-green-400\/20 {
  border-top-color: rgb(74 222 128 / 0.2) !important;
}

.tw-border-t-green-400\/25 {
  border-top-color: rgb(74 222 128 / 0.25) !important;
}

.tw-border-t-green-400\/30 {
  border-top-color: rgb(74 222 128 / 0.3) !important;
}

.tw-border-t-green-400\/35 {
  border-top-color: rgb(74 222 128 / 0.35) !important;
}

.tw-border-t-green-400\/40 {
  border-top-color: rgb(74 222 128 / 0.4) !important;
}

.tw-border-t-green-400\/45 {
  border-top-color: rgb(74 222 128 / 0.45) !important;
}

.tw-border-t-green-400\/5 {
  border-top-color: rgb(74 222 128 / 0.05) !important;
}

.tw-border-t-green-400\/50 {
  border-top-color: rgb(74 222 128 / 0.5) !important;
}

.tw-border-t-green-400\/55 {
  border-top-color: rgb(74 222 128 / 0.55) !important;
}

.tw-border-t-green-400\/60 {
  border-top-color: rgb(74 222 128 / 0.6) !important;
}

.tw-border-t-green-400\/65 {
  border-top-color: rgb(74 222 128 / 0.65) !important;
}

.tw-border-t-green-400\/70 {
  border-top-color: rgb(74 222 128 / 0.7) !important;
}

.tw-border-t-green-400\/75 {
  border-top-color: rgb(74 222 128 / 0.75) !important;
}

.tw-border-t-green-400\/80 {
  border-top-color: rgb(74 222 128 / 0.8) !important;
}

.tw-border-t-green-400\/85 {
  border-top-color: rgb(74 222 128 / 0.85) !important;
}

.tw-border-t-green-400\/90 {
  border-top-color: rgb(74 222 128 / 0.9) !important;
}

.tw-border-t-green-400\/95 {
  border-top-color: rgb(74 222 128 / 0.95) !important;
}

.tw-border-t-green-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 253 244 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-50\/0 {
  border-top-color: rgb(240 253 244 / 0) !important;
}

.tw-border-t-green-50\/10 {
  border-top-color: rgb(240 253 244 / 0.1) !important;
}

.tw-border-t-green-50\/100 {
  border-top-color: rgb(240 253 244 / 1) !important;
}

.tw-border-t-green-50\/15 {
  border-top-color: rgb(240 253 244 / 0.15) !important;
}

.tw-border-t-green-50\/20 {
  border-top-color: rgb(240 253 244 / 0.2) !important;
}

.tw-border-t-green-50\/25 {
  border-top-color: rgb(240 253 244 / 0.25) !important;
}

.tw-border-t-green-50\/30 {
  border-top-color: rgb(240 253 244 / 0.3) !important;
}

.tw-border-t-green-50\/35 {
  border-top-color: rgb(240 253 244 / 0.35) !important;
}

.tw-border-t-green-50\/40 {
  border-top-color: rgb(240 253 244 / 0.4) !important;
}

.tw-border-t-green-50\/45 {
  border-top-color: rgb(240 253 244 / 0.45) !important;
}

.tw-border-t-green-50\/5 {
  border-top-color: rgb(240 253 244 / 0.05) !important;
}

.tw-border-t-green-50\/50 {
  border-top-color: rgb(240 253 244 / 0.5) !important;
}

.tw-border-t-green-50\/55 {
  border-top-color: rgb(240 253 244 / 0.55) !important;
}

.tw-border-t-green-50\/60 {
  border-top-color: rgb(240 253 244 / 0.6) !important;
}

.tw-border-t-green-50\/65 {
  border-top-color: rgb(240 253 244 / 0.65) !important;
}

.tw-border-t-green-50\/70 {
  border-top-color: rgb(240 253 244 / 0.7) !important;
}

.tw-border-t-green-50\/75 {
  border-top-color: rgb(240 253 244 / 0.75) !important;
}

.tw-border-t-green-50\/80 {
  border-top-color: rgb(240 253 244 / 0.8) !important;
}

.tw-border-t-green-50\/85 {
  border-top-color: rgb(240 253 244 / 0.85) !important;
}

.tw-border-t-green-50\/90 {
  border-top-color: rgb(240 253 244 / 0.9) !important;
}

.tw-border-t-green-50\/95 {
  border-top-color: rgb(240 253 244 / 0.95) !important;
}

.tw-border-t-green-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(34 197 94 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-500\/0 {
  border-top-color: rgb(34 197 94 / 0) !important;
}

.tw-border-t-green-500\/10 {
  border-top-color: rgb(34 197 94 / 0.1) !important;
}

.tw-border-t-green-500\/100 {
  border-top-color: rgb(34 197 94 / 1) !important;
}

.tw-border-t-green-500\/15 {
  border-top-color: rgb(34 197 94 / 0.15) !important;
}

.tw-border-t-green-500\/20 {
  border-top-color: rgb(34 197 94 / 0.2) !important;
}

.tw-border-t-green-500\/25 {
  border-top-color: rgb(34 197 94 / 0.25) !important;
}

.tw-border-t-green-500\/30 {
  border-top-color: rgb(34 197 94 / 0.3) !important;
}

.tw-border-t-green-500\/35 {
  border-top-color: rgb(34 197 94 / 0.35) !important;
}

.tw-border-t-green-500\/40 {
  border-top-color: rgb(34 197 94 / 0.4) !important;
}

.tw-border-t-green-500\/45 {
  border-top-color: rgb(34 197 94 / 0.45) !important;
}

.tw-border-t-green-500\/5 {
  border-top-color: rgb(34 197 94 / 0.05) !important;
}

.tw-border-t-green-500\/50 {
  border-top-color: rgb(34 197 94 / 0.5) !important;
}

.tw-border-t-green-500\/55 {
  border-top-color: rgb(34 197 94 / 0.55) !important;
}

.tw-border-t-green-500\/60 {
  border-top-color: rgb(34 197 94 / 0.6) !important;
}

.tw-border-t-green-500\/65 {
  border-top-color: rgb(34 197 94 / 0.65) !important;
}

.tw-border-t-green-500\/70 {
  border-top-color: rgb(34 197 94 / 0.7) !important;
}

.tw-border-t-green-500\/75 {
  border-top-color: rgb(34 197 94 / 0.75) !important;
}

.tw-border-t-green-500\/80 {
  border-top-color: rgb(34 197 94 / 0.8) !important;
}

.tw-border-t-green-500\/85 {
  border-top-color: rgb(34 197 94 / 0.85) !important;
}

.tw-border-t-green-500\/90 {
  border-top-color: rgb(34 197 94 / 0.9) !important;
}

.tw-border-t-green-500\/95 {
  border-top-color: rgb(34 197 94 / 0.95) !important;
}

.tw-border-t-green-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(22 163 74 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-600\/0 {
  border-top-color: rgb(22 163 74 / 0) !important;
}

.tw-border-t-green-600\/10 {
  border-top-color: rgb(22 163 74 / 0.1) !important;
}

.tw-border-t-green-600\/100 {
  border-top-color: rgb(22 163 74 / 1) !important;
}

.tw-border-t-green-600\/15 {
  border-top-color: rgb(22 163 74 / 0.15) !important;
}

.tw-border-t-green-600\/20 {
  border-top-color: rgb(22 163 74 / 0.2) !important;
}

.tw-border-t-green-600\/25 {
  border-top-color: rgb(22 163 74 / 0.25) !important;
}

.tw-border-t-green-600\/30 {
  border-top-color: rgb(22 163 74 / 0.3) !important;
}

.tw-border-t-green-600\/35 {
  border-top-color: rgb(22 163 74 / 0.35) !important;
}

.tw-border-t-green-600\/40 {
  border-top-color: rgb(22 163 74 / 0.4) !important;
}

.tw-border-t-green-600\/45 {
  border-top-color: rgb(22 163 74 / 0.45) !important;
}

.tw-border-t-green-600\/5 {
  border-top-color: rgb(22 163 74 / 0.05) !important;
}

.tw-border-t-green-600\/50 {
  border-top-color: rgb(22 163 74 / 0.5) !important;
}

.tw-border-t-green-600\/55 {
  border-top-color: rgb(22 163 74 / 0.55) !important;
}

.tw-border-t-green-600\/60 {
  border-top-color: rgb(22 163 74 / 0.6) !important;
}

.tw-border-t-green-600\/65 {
  border-top-color: rgb(22 163 74 / 0.65) !important;
}

.tw-border-t-green-600\/70 {
  border-top-color: rgb(22 163 74 / 0.7) !important;
}

.tw-border-t-green-600\/75 {
  border-top-color: rgb(22 163 74 / 0.75) !important;
}

.tw-border-t-green-600\/80 {
  border-top-color: rgb(22 163 74 / 0.8) !important;
}

.tw-border-t-green-600\/85 {
  border-top-color: rgb(22 163 74 / 0.85) !important;
}

.tw-border-t-green-600\/90 {
  border-top-color: rgb(22 163 74 / 0.9) !important;
}

.tw-border-t-green-600\/95 {
  border-top-color: rgb(22 163 74 / 0.95) !important;
}

.tw-border-t-green-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(21 128 61 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-700\/0 {
  border-top-color: rgb(21 128 61 / 0) !important;
}

.tw-border-t-green-700\/10 {
  border-top-color: rgb(21 128 61 / 0.1) !important;
}

.tw-border-t-green-700\/100 {
  border-top-color: rgb(21 128 61 / 1) !important;
}

.tw-border-t-green-700\/15 {
  border-top-color: rgb(21 128 61 / 0.15) !important;
}

.tw-border-t-green-700\/20 {
  border-top-color: rgb(21 128 61 / 0.2) !important;
}

.tw-border-t-green-700\/25 {
  border-top-color: rgb(21 128 61 / 0.25) !important;
}

.tw-border-t-green-700\/30 {
  border-top-color: rgb(21 128 61 / 0.3) !important;
}

.tw-border-t-green-700\/35 {
  border-top-color: rgb(21 128 61 / 0.35) !important;
}

.tw-border-t-green-700\/40 {
  border-top-color: rgb(21 128 61 / 0.4) !important;
}

.tw-border-t-green-700\/45 {
  border-top-color: rgb(21 128 61 / 0.45) !important;
}

.tw-border-t-green-700\/5 {
  border-top-color: rgb(21 128 61 / 0.05) !important;
}

.tw-border-t-green-700\/50 {
  border-top-color: rgb(21 128 61 / 0.5) !important;
}

.tw-border-t-green-700\/55 {
  border-top-color: rgb(21 128 61 / 0.55) !important;
}

.tw-border-t-green-700\/60 {
  border-top-color: rgb(21 128 61 / 0.6) !important;
}

.tw-border-t-green-700\/65 {
  border-top-color: rgb(21 128 61 / 0.65) !important;
}

.tw-border-t-green-700\/70 {
  border-top-color: rgb(21 128 61 / 0.7) !important;
}

.tw-border-t-green-700\/75 {
  border-top-color: rgb(21 128 61 / 0.75) !important;
}

.tw-border-t-green-700\/80 {
  border-top-color: rgb(21 128 61 / 0.8) !important;
}

.tw-border-t-green-700\/85 {
  border-top-color: rgb(21 128 61 / 0.85) !important;
}

.tw-border-t-green-700\/90 {
  border-top-color: rgb(21 128 61 / 0.9) !important;
}

.tw-border-t-green-700\/95 {
  border-top-color: rgb(21 128 61 / 0.95) !important;
}

.tw-border-t-green-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(22 101 52 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-800\/0 {
  border-top-color: rgb(22 101 52 / 0) !important;
}

.tw-border-t-green-800\/10 {
  border-top-color: rgb(22 101 52 / 0.1) !important;
}

.tw-border-t-green-800\/100 {
  border-top-color: rgb(22 101 52 / 1) !important;
}

.tw-border-t-green-800\/15 {
  border-top-color: rgb(22 101 52 / 0.15) !important;
}

.tw-border-t-green-800\/20 {
  border-top-color: rgb(22 101 52 / 0.2) !important;
}

.tw-border-t-green-800\/25 {
  border-top-color: rgb(22 101 52 / 0.25) !important;
}

.tw-border-t-green-800\/30 {
  border-top-color: rgb(22 101 52 / 0.3) !important;
}

.tw-border-t-green-800\/35 {
  border-top-color: rgb(22 101 52 / 0.35) !important;
}

.tw-border-t-green-800\/40 {
  border-top-color: rgb(22 101 52 / 0.4) !important;
}

.tw-border-t-green-800\/45 {
  border-top-color: rgb(22 101 52 / 0.45) !important;
}

.tw-border-t-green-800\/5 {
  border-top-color: rgb(22 101 52 / 0.05) !important;
}

.tw-border-t-green-800\/50 {
  border-top-color: rgb(22 101 52 / 0.5) !important;
}

.tw-border-t-green-800\/55 {
  border-top-color: rgb(22 101 52 / 0.55) !important;
}

.tw-border-t-green-800\/60 {
  border-top-color: rgb(22 101 52 / 0.6) !important;
}

.tw-border-t-green-800\/65 {
  border-top-color: rgb(22 101 52 / 0.65) !important;
}

.tw-border-t-green-800\/70 {
  border-top-color: rgb(22 101 52 / 0.7) !important;
}

.tw-border-t-green-800\/75 {
  border-top-color: rgb(22 101 52 / 0.75) !important;
}

.tw-border-t-green-800\/80 {
  border-top-color: rgb(22 101 52 / 0.8) !important;
}

.tw-border-t-green-800\/85 {
  border-top-color: rgb(22 101 52 / 0.85) !important;
}

.tw-border-t-green-800\/90 {
  border-top-color: rgb(22 101 52 / 0.9) !important;
}

.tw-border-t-green-800\/95 {
  border-top-color: rgb(22 101 52 / 0.95) !important;
}

.tw-border-t-green-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(20 83 45 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-900\/0 {
  border-top-color: rgb(20 83 45 / 0) !important;
}

.tw-border-t-green-900\/10 {
  border-top-color: rgb(20 83 45 / 0.1) !important;
}

.tw-border-t-green-900\/100 {
  border-top-color: rgb(20 83 45 / 1) !important;
}

.tw-border-t-green-900\/15 {
  border-top-color: rgb(20 83 45 / 0.15) !important;
}

.tw-border-t-green-900\/20 {
  border-top-color: rgb(20 83 45 / 0.2) !important;
}

.tw-border-t-green-900\/25 {
  border-top-color: rgb(20 83 45 / 0.25) !important;
}

.tw-border-t-green-900\/30 {
  border-top-color: rgb(20 83 45 / 0.3) !important;
}

.tw-border-t-green-900\/35 {
  border-top-color: rgb(20 83 45 / 0.35) !important;
}

.tw-border-t-green-900\/40 {
  border-top-color: rgb(20 83 45 / 0.4) !important;
}

.tw-border-t-green-900\/45 {
  border-top-color: rgb(20 83 45 / 0.45) !important;
}

.tw-border-t-green-900\/5 {
  border-top-color: rgb(20 83 45 / 0.05) !important;
}

.tw-border-t-green-900\/50 {
  border-top-color: rgb(20 83 45 / 0.5) !important;
}

.tw-border-t-green-900\/55 {
  border-top-color: rgb(20 83 45 / 0.55) !important;
}

.tw-border-t-green-900\/60 {
  border-top-color: rgb(20 83 45 / 0.6) !important;
}

.tw-border-t-green-900\/65 {
  border-top-color: rgb(20 83 45 / 0.65) !important;
}

.tw-border-t-green-900\/70 {
  border-top-color: rgb(20 83 45 / 0.7) !important;
}

.tw-border-t-green-900\/75 {
  border-top-color: rgb(20 83 45 / 0.75) !important;
}

.tw-border-t-green-900\/80 {
  border-top-color: rgb(20 83 45 / 0.8) !important;
}

.tw-border-t-green-900\/85 {
  border-top-color: rgb(20 83 45 / 0.85) !important;
}

.tw-border-t-green-900\/90 {
  border-top-color: rgb(20 83 45 / 0.9) !important;
}

.tw-border-t-green-900\/95 {
  border-top-color: rgb(20 83 45 / 0.95) !important;
}

.tw-border-t-green-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(5 46 22 / var(--tw-border-opacity)) !important;
}

.tw-border-t-green-950\/0 {
  border-top-color: rgb(5 46 22 / 0) !important;
}

.tw-border-t-green-950\/10 {
  border-top-color: rgb(5 46 22 / 0.1) !important;
}

.tw-border-t-green-950\/100 {
  border-top-color: rgb(5 46 22 / 1) !important;
}

.tw-border-t-green-950\/15 {
  border-top-color: rgb(5 46 22 / 0.15) !important;
}

.tw-border-t-green-950\/20 {
  border-top-color: rgb(5 46 22 / 0.2) !important;
}

.tw-border-t-green-950\/25 {
  border-top-color: rgb(5 46 22 / 0.25) !important;
}

.tw-border-t-green-950\/30 {
  border-top-color: rgb(5 46 22 / 0.3) !important;
}

.tw-border-t-green-950\/35 {
  border-top-color: rgb(5 46 22 / 0.35) !important;
}

.tw-border-t-green-950\/40 {
  border-top-color: rgb(5 46 22 / 0.4) !important;
}

.tw-border-t-green-950\/45 {
  border-top-color: rgb(5 46 22 / 0.45) !important;
}

.tw-border-t-green-950\/5 {
  border-top-color: rgb(5 46 22 / 0.05) !important;
}

.tw-border-t-green-950\/50 {
  border-top-color: rgb(5 46 22 / 0.5) !important;
}

.tw-border-t-green-950\/55 {
  border-top-color: rgb(5 46 22 / 0.55) !important;
}

.tw-border-t-green-950\/60 {
  border-top-color: rgb(5 46 22 / 0.6) !important;
}

.tw-border-t-green-950\/65 {
  border-top-color: rgb(5 46 22 / 0.65) !important;
}

.tw-border-t-green-950\/70 {
  border-top-color: rgb(5 46 22 / 0.7) !important;
}

.tw-border-t-green-950\/75 {
  border-top-color: rgb(5 46 22 / 0.75) !important;
}

.tw-border-t-green-950\/80 {
  border-top-color: rgb(5 46 22 / 0.8) !important;
}

.tw-border-t-green-950\/85 {
  border-top-color: rgb(5 46 22 / 0.85) !important;
}

.tw-border-t-green-950\/90 {
  border-top-color: rgb(5 46 22 / 0.9) !important;
}

.tw-border-t-green-950\/95 {
  border-top-color: rgb(5 46 22 / 0.95) !important;
}

.tw-border-t-indigo-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(224 231 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-100\/0 {
  border-top-color: rgb(224 231 255 / 0) !important;
}

.tw-border-t-indigo-100\/10 {
  border-top-color: rgb(224 231 255 / 0.1) !important;
}

.tw-border-t-indigo-100\/100 {
  border-top-color: rgb(224 231 255 / 1) !important;
}

.tw-border-t-indigo-100\/15 {
  border-top-color: rgb(224 231 255 / 0.15) !important;
}

.tw-border-t-indigo-100\/20 {
  border-top-color: rgb(224 231 255 / 0.2) !important;
}

.tw-border-t-indigo-100\/25 {
  border-top-color: rgb(224 231 255 / 0.25) !important;
}

.tw-border-t-indigo-100\/30 {
  border-top-color: rgb(224 231 255 / 0.3) !important;
}

.tw-border-t-indigo-100\/35 {
  border-top-color: rgb(224 231 255 / 0.35) !important;
}

.tw-border-t-indigo-100\/40 {
  border-top-color: rgb(224 231 255 / 0.4) !important;
}

.tw-border-t-indigo-100\/45 {
  border-top-color: rgb(224 231 255 / 0.45) !important;
}

.tw-border-t-indigo-100\/5 {
  border-top-color: rgb(224 231 255 / 0.05) !important;
}

.tw-border-t-indigo-100\/50 {
  border-top-color: rgb(224 231 255 / 0.5) !important;
}

.tw-border-t-indigo-100\/55 {
  border-top-color: rgb(224 231 255 / 0.55) !important;
}

.tw-border-t-indigo-100\/60 {
  border-top-color: rgb(224 231 255 / 0.6) !important;
}

.tw-border-t-indigo-100\/65 {
  border-top-color: rgb(224 231 255 / 0.65) !important;
}

.tw-border-t-indigo-100\/70 {
  border-top-color: rgb(224 231 255 / 0.7) !important;
}

.tw-border-t-indigo-100\/75 {
  border-top-color: rgb(224 231 255 / 0.75) !important;
}

.tw-border-t-indigo-100\/80 {
  border-top-color: rgb(224 231 255 / 0.8) !important;
}

.tw-border-t-indigo-100\/85 {
  border-top-color: rgb(224 231 255 / 0.85) !important;
}

.tw-border-t-indigo-100\/90 {
  border-top-color: rgb(224 231 255 / 0.9) !important;
}

.tw-border-t-indigo-100\/95 {
  border-top-color: rgb(224 231 255 / 0.95) !important;
}

.tw-border-t-indigo-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(199 210 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-200\/0 {
  border-top-color: rgb(199 210 254 / 0) !important;
}

.tw-border-t-indigo-200\/10 {
  border-top-color: rgb(199 210 254 / 0.1) !important;
}

.tw-border-t-indigo-200\/100 {
  border-top-color: rgb(199 210 254 / 1) !important;
}

.tw-border-t-indigo-200\/15 {
  border-top-color: rgb(199 210 254 / 0.15) !important;
}

.tw-border-t-indigo-200\/20 {
  border-top-color: rgb(199 210 254 / 0.2) !important;
}

.tw-border-t-indigo-200\/25 {
  border-top-color: rgb(199 210 254 / 0.25) !important;
}

.tw-border-t-indigo-200\/30 {
  border-top-color: rgb(199 210 254 / 0.3) !important;
}

.tw-border-t-indigo-200\/35 {
  border-top-color: rgb(199 210 254 / 0.35) !important;
}

.tw-border-t-indigo-200\/40 {
  border-top-color: rgb(199 210 254 / 0.4) !important;
}

.tw-border-t-indigo-200\/45 {
  border-top-color: rgb(199 210 254 / 0.45) !important;
}

.tw-border-t-indigo-200\/5 {
  border-top-color: rgb(199 210 254 / 0.05) !important;
}

.tw-border-t-indigo-200\/50 {
  border-top-color: rgb(199 210 254 / 0.5) !important;
}

.tw-border-t-indigo-200\/55 {
  border-top-color: rgb(199 210 254 / 0.55) !important;
}

.tw-border-t-indigo-200\/60 {
  border-top-color: rgb(199 210 254 / 0.6) !important;
}

.tw-border-t-indigo-200\/65 {
  border-top-color: rgb(199 210 254 / 0.65) !important;
}

.tw-border-t-indigo-200\/70 {
  border-top-color: rgb(199 210 254 / 0.7) !important;
}

.tw-border-t-indigo-200\/75 {
  border-top-color: rgb(199 210 254 / 0.75) !important;
}

.tw-border-t-indigo-200\/80 {
  border-top-color: rgb(199 210 254 / 0.8) !important;
}

.tw-border-t-indigo-200\/85 {
  border-top-color: rgb(199 210 254 / 0.85) !important;
}

.tw-border-t-indigo-200\/90 {
  border-top-color: rgb(199 210 254 / 0.9) !important;
}

.tw-border-t-indigo-200\/95 {
  border-top-color: rgb(199 210 254 / 0.95) !important;
}

.tw-border-t-indigo-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(165 180 252 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-300\/0 {
  border-top-color: rgb(165 180 252 / 0) !important;
}

.tw-border-t-indigo-300\/10 {
  border-top-color: rgb(165 180 252 / 0.1) !important;
}

.tw-border-t-indigo-300\/100 {
  border-top-color: rgb(165 180 252 / 1) !important;
}

.tw-border-t-indigo-300\/15 {
  border-top-color: rgb(165 180 252 / 0.15) !important;
}

.tw-border-t-indigo-300\/20 {
  border-top-color: rgb(165 180 252 / 0.2) !important;
}

.tw-border-t-indigo-300\/25 {
  border-top-color: rgb(165 180 252 / 0.25) !important;
}

.tw-border-t-indigo-300\/30 {
  border-top-color: rgb(165 180 252 / 0.3) !important;
}

.tw-border-t-indigo-300\/35 {
  border-top-color: rgb(165 180 252 / 0.35) !important;
}

.tw-border-t-indigo-300\/40 {
  border-top-color: rgb(165 180 252 / 0.4) !important;
}

.tw-border-t-indigo-300\/45 {
  border-top-color: rgb(165 180 252 / 0.45) !important;
}

.tw-border-t-indigo-300\/5 {
  border-top-color: rgb(165 180 252 / 0.05) !important;
}

.tw-border-t-indigo-300\/50 {
  border-top-color: rgb(165 180 252 / 0.5) !important;
}

.tw-border-t-indigo-300\/55 {
  border-top-color: rgb(165 180 252 / 0.55) !important;
}

.tw-border-t-indigo-300\/60 {
  border-top-color: rgb(165 180 252 / 0.6) !important;
}

.tw-border-t-indigo-300\/65 {
  border-top-color: rgb(165 180 252 / 0.65) !important;
}

.tw-border-t-indigo-300\/70 {
  border-top-color: rgb(165 180 252 / 0.7) !important;
}

.tw-border-t-indigo-300\/75 {
  border-top-color: rgb(165 180 252 / 0.75) !important;
}

.tw-border-t-indigo-300\/80 {
  border-top-color: rgb(165 180 252 / 0.8) !important;
}

.tw-border-t-indigo-300\/85 {
  border-top-color: rgb(165 180 252 / 0.85) !important;
}

.tw-border-t-indigo-300\/90 {
  border-top-color: rgb(165 180 252 / 0.9) !important;
}

.tw-border-t-indigo-300\/95 {
  border-top-color: rgb(165 180 252 / 0.95) !important;
}

.tw-border-t-indigo-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(129 140 248 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-400\/0 {
  border-top-color: rgb(129 140 248 / 0) !important;
}

.tw-border-t-indigo-400\/10 {
  border-top-color: rgb(129 140 248 / 0.1) !important;
}

.tw-border-t-indigo-400\/100 {
  border-top-color: rgb(129 140 248 / 1) !important;
}

.tw-border-t-indigo-400\/15 {
  border-top-color: rgb(129 140 248 / 0.15) !important;
}

.tw-border-t-indigo-400\/20 {
  border-top-color: rgb(129 140 248 / 0.2) !important;
}

.tw-border-t-indigo-400\/25 {
  border-top-color: rgb(129 140 248 / 0.25) !important;
}

.tw-border-t-indigo-400\/30 {
  border-top-color: rgb(129 140 248 / 0.3) !important;
}

.tw-border-t-indigo-400\/35 {
  border-top-color: rgb(129 140 248 / 0.35) !important;
}

.tw-border-t-indigo-400\/40 {
  border-top-color: rgb(129 140 248 / 0.4) !important;
}

.tw-border-t-indigo-400\/45 {
  border-top-color: rgb(129 140 248 / 0.45) !important;
}

.tw-border-t-indigo-400\/5 {
  border-top-color: rgb(129 140 248 / 0.05) !important;
}

.tw-border-t-indigo-400\/50 {
  border-top-color: rgb(129 140 248 / 0.5) !important;
}

.tw-border-t-indigo-400\/55 {
  border-top-color: rgb(129 140 248 / 0.55) !important;
}

.tw-border-t-indigo-400\/60 {
  border-top-color: rgb(129 140 248 / 0.6) !important;
}

.tw-border-t-indigo-400\/65 {
  border-top-color: rgb(129 140 248 / 0.65) !important;
}

.tw-border-t-indigo-400\/70 {
  border-top-color: rgb(129 140 248 / 0.7) !important;
}

.tw-border-t-indigo-400\/75 {
  border-top-color: rgb(129 140 248 / 0.75) !important;
}

.tw-border-t-indigo-400\/80 {
  border-top-color: rgb(129 140 248 / 0.8) !important;
}

.tw-border-t-indigo-400\/85 {
  border-top-color: rgb(129 140 248 / 0.85) !important;
}

.tw-border-t-indigo-400\/90 {
  border-top-color: rgb(129 140 248 / 0.9) !important;
}

.tw-border-t-indigo-400\/95 {
  border-top-color: rgb(129 140 248 / 0.95) !important;
}

.tw-border-t-indigo-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(238 242 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-50\/0 {
  border-top-color: rgb(238 242 255 / 0) !important;
}

.tw-border-t-indigo-50\/10 {
  border-top-color: rgb(238 242 255 / 0.1) !important;
}

.tw-border-t-indigo-50\/100 {
  border-top-color: rgb(238 242 255 / 1) !important;
}

.tw-border-t-indigo-50\/15 {
  border-top-color: rgb(238 242 255 / 0.15) !important;
}

.tw-border-t-indigo-50\/20 {
  border-top-color: rgb(238 242 255 / 0.2) !important;
}

.tw-border-t-indigo-50\/25 {
  border-top-color: rgb(238 242 255 / 0.25) !important;
}

.tw-border-t-indigo-50\/30 {
  border-top-color: rgb(238 242 255 / 0.3) !important;
}

.tw-border-t-indigo-50\/35 {
  border-top-color: rgb(238 242 255 / 0.35) !important;
}

.tw-border-t-indigo-50\/40 {
  border-top-color: rgb(238 242 255 / 0.4) !important;
}

.tw-border-t-indigo-50\/45 {
  border-top-color: rgb(238 242 255 / 0.45) !important;
}

.tw-border-t-indigo-50\/5 {
  border-top-color: rgb(238 242 255 / 0.05) !important;
}

.tw-border-t-indigo-50\/50 {
  border-top-color: rgb(238 242 255 / 0.5) !important;
}

.tw-border-t-indigo-50\/55 {
  border-top-color: rgb(238 242 255 / 0.55) !important;
}

.tw-border-t-indigo-50\/60 {
  border-top-color: rgb(238 242 255 / 0.6) !important;
}

.tw-border-t-indigo-50\/65 {
  border-top-color: rgb(238 242 255 / 0.65) !important;
}

.tw-border-t-indigo-50\/70 {
  border-top-color: rgb(238 242 255 / 0.7) !important;
}

.tw-border-t-indigo-50\/75 {
  border-top-color: rgb(238 242 255 / 0.75) !important;
}

.tw-border-t-indigo-50\/80 {
  border-top-color: rgb(238 242 255 / 0.8) !important;
}

.tw-border-t-indigo-50\/85 {
  border-top-color: rgb(238 242 255 / 0.85) !important;
}

.tw-border-t-indigo-50\/90 {
  border-top-color: rgb(238 242 255 / 0.9) !important;
}

.tw-border-t-indigo-50\/95 {
  border-top-color: rgb(238 242 255 / 0.95) !important;
}

.tw-border-t-indigo-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(99 102 241 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-500\/0 {
  border-top-color: rgb(99 102 241 / 0) !important;
}

.tw-border-t-indigo-500\/10 {
  border-top-color: rgb(99 102 241 / 0.1) !important;
}

.tw-border-t-indigo-500\/100 {
  border-top-color: rgb(99 102 241 / 1) !important;
}

.tw-border-t-indigo-500\/15 {
  border-top-color: rgb(99 102 241 / 0.15) !important;
}

.tw-border-t-indigo-500\/20 {
  border-top-color: rgb(99 102 241 / 0.2) !important;
}

.tw-border-t-indigo-500\/25 {
  border-top-color: rgb(99 102 241 / 0.25) !important;
}

.tw-border-t-indigo-500\/30 {
  border-top-color: rgb(99 102 241 / 0.3) !important;
}

.tw-border-t-indigo-500\/35 {
  border-top-color: rgb(99 102 241 / 0.35) !important;
}

.tw-border-t-indigo-500\/40 {
  border-top-color: rgb(99 102 241 / 0.4) !important;
}

.tw-border-t-indigo-500\/45 {
  border-top-color: rgb(99 102 241 / 0.45) !important;
}

.tw-border-t-indigo-500\/5 {
  border-top-color: rgb(99 102 241 / 0.05) !important;
}

.tw-border-t-indigo-500\/50 {
  border-top-color: rgb(99 102 241 / 0.5) !important;
}

.tw-border-t-indigo-500\/55 {
  border-top-color: rgb(99 102 241 / 0.55) !important;
}

.tw-border-t-indigo-500\/60 {
  border-top-color: rgb(99 102 241 / 0.6) !important;
}

.tw-border-t-indigo-500\/65 {
  border-top-color: rgb(99 102 241 / 0.65) !important;
}

.tw-border-t-indigo-500\/70 {
  border-top-color: rgb(99 102 241 / 0.7) !important;
}

.tw-border-t-indigo-500\/75 {
  border-top-color: rgb(99 102 241 / 0.75) !important;
}

.tw-border-t-indigo-500\/80 {
  border-top-color: rgb(99 102 241 / 0.8) !important;
}

.tw-border-t-indigo-500\/85 {
  border-top-color: rgb(99 102 241 / 0.85) !important;
}

.tw-border-t-indigo-500\/90 {
  border-top-color: rgb(99 102 241 / 0.9) !important;
}

.tw-border-t-indigo-500\/95 {
  border-top-color: rgb(99 102 241 / 0.95) !important;
}

.tw-border-t-indigo-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(79 70 229 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-600\/0 {
  border-top-color: rgb(79 70 229 / 0) !important;
}

.tw-border-t-indigo-600\/10 {
  border-top-color: rgb(79 70 229 / 0.1) !important;
}

.tw-border-t-indigo-600\/100 {
  border-top-color: rgb(79 70 229 / 1) !important;
}

.tw-border-t-indigo-600\/15 {
  border-top-color: rgb(79 70 229 / 0.15) !important;
}

.tw-border-t-indigo-600\/20 {
  border-top-color: rgb(79 70 229 / 0.2) !important;
}

.tw-border-t-indigo-600\/25 {
  border-top-color: rgb(79 70 229 / 0.25) !important;
}

.tw-border-t-indigo-600\/30 {
  border-top-color: rgb(79 70 229 / 0.3) !important;
}

.tw-border-t-indigo-600\/35 {
  border-top-color: rgb(79 70 229 / 0.35) !important;
}

.tw-border-t-indigo-600\/40 {
  border-top-color: rgb(79 70 229 / 0.4) !important;
}

.tw-border-t-indigo-600\/45 {
  border-top-color: rgb(79 70 229 / 0.45) !important;
}

.tw-border-t-indigo-600\/5 {
  border-top-color: rgb(79 70 229 / 0.05) !important;
}

.tw-border-t-indigo-600\/50 {
  border-top-color: rgb(79 70 229 / 0.5) !important;
}

.tw-border-t-indigo-600\/55 {
  border-top-color: rgb(79 70 229 / 0.55) !important;
}

.tw-border-t-indigo-600\/60 {
  border-top-color: rgb(79 70 229 / 0.6) !important;
}

.tw-border-t-indigo-600\/65 {
  border-top-color: rgb(79 70 229 / 0.65) !important;
}

.tw-border-t-indigo-600\/70 {
  border-top-color: rgb(79 70 229 / 0.7) !important;
}

.tw-border-t-indigo-600\/75 {
  border-top-color: rgb(79 70 229 / 0.75) !important;
}

.tw-border-t-indigo-600\/80 {
  border-top-color: rgb(79 70 229 / 0.8) !important;
}

.tw-border-t-indigo-600\/85 {
  border-top-color: rgb(79 70 229 / 0.85) !important;
}

.tw-border-t-indigo-600\/90 {
  border-top-color: rgb(79 70 229 / 0.9) !important;
}

.tw-border-t-indigo-600\/95 {
  border-top-color: rgb(79 70 229 / 0.95) !important;
}

.tw-border-t-indigo-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(67 56 202 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-700\/0 {
  border-top-color: rgb(67 56 202 / 0) !important;
}

.tw-border-t-indigo-700\/10 {
  border-top-color: rgb(67 56 202 / 0.1) !important;
}

.tw-border-t-indigo-700\/100 {
  border-top-color: rgb(67 56 202 / 1) !important;
}

.tw-border-t-indigo-700\/15 {
  border-top-color: rgb(67 56 202 / 0.15) !important;
}

.tw-border-t-indigo-700\/20 {
  border-top-color: rgb(67 56 202 / 0.2) !important;
}

.tw-border-t-indigo-700\/25 {
  border-top-color: rgb(67 56 202 / 0.25) !important;
}

.tw-border-t-indigo-700\/30 {
  border-top-color: rgb(67 56 202 / 0.3) !important;
}

.tw-border-t-indigo-700\/35 {
  border-top-color: rgb(67 56 202 / 0.35) !important;
}

.tw-border-t-indigo-700\/40 {
  border-top-color: rgb(67 56 202 / 0.4) !important;
}

.tw-border-t-indigo-700\/45 {
  border-top-color: rgb(67 56 202 / 0.45) !important;
}

.tw-border-t-indigo-700\/5 {
  border-top-color: rgb(67 56 202 / 0.05) !important;
}

.tw-border-t-indigo-700\/50 {
  border-top-color: rgb(67 56 202 / 0.5) !important;
}

.tw-border-t-indigo-700\/55 {
  border-top-color: rgb(67 56 202 / 0.55) !important;
}

.tw-border-t-indigo-700\/60 {
  border-top-color: rgb(67 56 202 / 0.6) !important;
}

.tw-border-t-indigo-700\/65 {
  border-top-color: rgb(67 56 202 / 0.65) !important;
}

.tw-border-t-indigo-700\/70 {
  border-top-color: rgb(67 56 202 / 0.7) !important;
}

.tw-border-t-indigo-700\/75 {
  border-top-color: rgb(67 56 202 / 0.75) !important;
}

.tw-border-t-indigo-700\/80 {
  border-top-color: rgb(67 56 202 / 0.8) !important;
}

.tw-border-t-indigo-700\/85 {
  border-top-color: rgb(67 56 202 / 0.85) !important;
}

.tw-border-t-indigo-700\/90 {
  border-top-color: rgb(67 56 202 / 0.9) !important;
}

.tw-border-t-indigo-700\/95 {
  border-top-color: rgb(67 56 202 / 0.95) !important;
}

.tw-border-t-indigo-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(55 48 163 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-800\/0 {
  border-top-color: rgb(55 48 163 / 0) !important;
}

.tw-border-t-indigo-800\/10 {
  border-top-color: rgb(55 48 163 / 0.1) !important;
}

.tw-border-t-indigo-800\/100 {
  border-top-color: rgb(55 48 163 / 1) !important;
}

.tw-border-t-indigo-800\/15 {
  border-top-color: rgb(55 48 163 / 0.15) !important;
}

.tw-border-t-indigo-800\/20 {
  border-top-color: rgb(55 48 163 / 0.2) !important;
}

.tw-border-t-indigo-800\/25 {
  border-top-color: rgb(55 48 163 / 0.25) !important;
}

.tw-border-t-indigo-800\/30 {
  border-top-color: rgb(55 48 163 / 0.3) !important;
}

.tw-border-t-indigo-800\/35 {
  border-top-color: rgb(55 48 163 / 0.35) !important;
}

.tw-border-t-indigo-800\/40 {
  border-top-color: rgb(55 48 163 / 0.4) !important;
}

.tw-border-t-indigo-800\/45 {
  border-top-color: rgb(55 48 163 / 0.45) !important;
}

.tw-border-t-indigo-800\/5 {
  border-top-color: rgb(55 48 163 / 0.05) !important;
}

.tw-border-t-indigo-800\/50 {
  border-top-color: rgb(55 48 163 / 0.5) !important;
}

.tw-border-t-indigo-800\/55 {
  border-top-color: rgb(55 48 163 / 0.55) !important;
}

.tw-border-t-indigo-800\/60 {
  border-top-color: rgb(55 48 163 / 0.6) !important;
}

.tw-border-t-indigo-800\/65 {
  border-top-color: rgb(55 48 163 / 0.65) !important;
}

.tw-border-t-indigo-800\/70 {
  border-top-color: rgb(55 48 163 / 0.7) !important;
}

.tw-border-t-indigo-800\/75 {
  border-top-color: rgb(55 48 163 / 0.75) !important;
}

.tw-border-t-indigo-800\/80 {
  border-top-color: rgb(55 48 163 / 0.8) !important;
}

.tw-border-t-indigo-800\/85 {
  border-top-color: rgb(55 48 163 / 0.85) !important;
}

.tw-border-t-indigo-800\/90 {
  border-top-color: rgb(55 48 163 / 0.9) !important;
}

.tw-border-t-indigo-800\/95 {
  border-top-color: rgb(55 48 163 / 0.95) !important;
}

.tw-border-t-indigo-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(49 46 129 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-900\/0 {
  border-top-color: rgb(49 46 129 / 0) !important;
}

.tw-border-t-indigo-900\/10 {
  border-top-color: rgb(49 46 129 / 0.1) !important;
}

.tw-border-t-indigo-900\/100 {
  border-top-color: rgb(49 46 129 / 1) !important;
}

.tw-border-t-indigo-900\/15 {
  border-top-color: rgb(49 46 129 / 0.15) !important;
}

.tw-border-t-indigo-900\/20 {
  border-top-color: rgb(49 46 129 / 0.2) !important;
}

.tw-border-t-indigo-900\/25 {
  border-top-color: rgb(49 46 129 / 0.25) !important;
}

.tw-border-t-indigo-900\/30 {
  border-top-color: rgb(49 46 129 / 0.3) !important;
}

.tw-border-t-indigo-900\/35 {
  border-top-color: rgb(49 46 129 / 0.35) !important;
}

.tw-border-t-indigo-900\/40 {
  border-top-color: rgb(49 46 129 / 0.4) !important;
}

.tw-border-t-indigo-900\/45 {
  border-top-color: rgb(49 46 129 / 0.45) !important;
}

.tw-border-t-indigo-900\/5 {
  border-top-color: rgb(49 46 129 / 0.05) !important;
}

.tw-border-t-indigo-900\/50 {
  border-top-color: rgb(49 46 129 / 0.5) !important;
}

.tw-border-t-indigo-900\/55 {
  border-top-color: rgb(49 46 129 / 0.55) !important;
}

.tw-border-t-indigo-900\/60 {
  border-top-color: rgb(49 46 129 / 0.6) !important;
}

.tw-border-t-indigo-900\/65 {
  border-top-color: rgb(49 46 129 / 0.65) !important;
}

.tw-border-t-indigo-900\/70 {
  border-top-color: rgb(49 46 129 / 0.7) !important;
}

.tw-border-t-indigo-900\/75 {
  border-top-color: rgb(49 46 129 / 0.75) !important;
}

.tw-border-t-indigo-900\/80 {
  border-top-color: rgb(49 46 129 / 0.8) !important;
}

.tw-border-t-indigo-900\/85 {
  border-top-color: rgb(49 46 129 / 0.85) !important;
}

.tw-border-t-indigo-900\/90 {
  border-top-color: rgb(49 46 129 / 0.9) !important;
}

.tw-border-t-indigo-900\/95 {
  border-top-color: rgb(49 46 129 / 0.95) !important;
}

.tw-border-t-indigo-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 27 75 / var(--tw-border-opacity)) !important;
}

.tw-border-t-indigo-950\/0 {
  border-top-color: rgb(30 27 75 / 0) !important;
}

.tw-border-t-indigo-950\/10 {
  border-top-color: rgb(30 27 75 / 0.1) !important;
}

.tw-border-t-indigo-950\/100 {
  border-top-color: rgb(30 27 75 / 1) !important;
}

.tw-border-t-indigo-950\/15 {
  border-top-color: rgb(30 27 75 / 0.15) !important;
}

.tw-border-t-indigo-950\/20 {
  border-top-color: rgb(30 27 75 / 0.2) !important;
}

.tw-border-t-indigo-950\/25 {
  border-top-color: rgb(30 27 75 / 0.25) !important;
}

.tw-border-t-indigo-950\/30 {
  border-top-color: rgb(30 27 75 / 0.3) !important;
}

.tw-border-t-indigo-950\/35 {
  border-top-color: rgb(30 27 75 / 0.35) !important;
}

.tw-border-t-indigo-950\/40 {
  border-top-color: rgb(30 27 75 / 0.4) !important;
}

.tw-border-t-indigo-950\/45 {
  border-top-color: rgb(30 27 75 / 0.45) !important;
}

.tw-border-t-indigo-950\/5 {
  border-top-color: rgb(30 27 75 / 0.05) !important;
}

.tw-border-t-indigo-950\/50 {
  border-top-color: rgb(30 27 75 / 0.5) !important;
}

.tw-border-t-indigo-950\/55 {
  border-top-color: rgb(30 27 75 / 0.55) !important;
}

.tw-border-t-indigo-950\/60 {
  border-top-color: rgb(30 27 75 / 0.6) !important;
}

.tw-border-t-indigo-950\/65 {
  border-top-color: rgb(30 27 75 / 0.65) !important;
}

.tw-border-t-indigo-950\/70 {
  border-top-color: rgb(30 27 75 / 0.7) !important;
}

.tw-border-t-indigo-950\/75 {
  border-top-color: rgb(30 27 75 / 0.75) !important;
}

.tw-border-t-indigo-950\/80 {
  border-top-color: rgb(30 27 75 / 0.8) !important;
}

.tw-border-t-indigo-950\/85 {
  border-top-color: rgb(30 27 75 / 0.85) !important;
}

.tw-border-t-indigo-950\/90 {
  border-top-color: rgb(30 27 75 / 0.9) !important;
}

.tw-border-t-indigo-950\/95 {
  border-top-color: rgb(30 27 75 / 0.95) !important;
}

.tw-border-t-inherit {
  border-top-color: inherit !important;
}

.tw-border-t-lime-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 252 203 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-100\/0 {
  border-top-color: rgb(236 252 203 / 0) !important;
}

.tw-border-t-lime-100\/10 {
  border-top-color: rgb(236 252 203 / 0.1) !important;
}

.tw-border-t-lime-100\/100 {
  border-top-color: rgb(236 252 203 / 1) !important;
}

.tw-border-t-lime-100\/15 {
  border-top-color: rgb(236 252 203 / 0.15) !important;
}

.tw-border-t-lime-100\/20 {
  border-top-color: rgb(236 252 203 / 0.2) !important;
}

.tw-border-t-lime-100\/25 {
  border-top-color: rgb(236 252 203 / 0.25) !important;
}

.tw-border-t-lime-100\/30 {
  border-top-color: rgb(236 252 203 / 0.3) !important;
}

.tw-border-t-lime-100\/35 {
  border-top-color: rgb(236 252 203 / 0.35) !important;
}

.tw-border-t-lime-100\/40 {
  border-top-color: rgb(236 252 203 / 0.4) !important;
}

.tw-border-t-lime-100\/45 {
  border-top-color: rgb(236 252 203 / 0.45) !important;
}

.tw-border-t-lime-100\/5 {
  border-top-color: rgb(236 252 203 / 0.05) !important;
}

.tw-border-t-lime-100\/50 {
  border-top-color: rgb(236 252 203 / 0.5) !important;
}

.tw-border-t-lime-100\/55 {
  border-top-color: rgb(236 252 203 / 0.55) !important;
}

.tw-border-t-lime-100\/60 {
  border-top-color: rgb(236 252 203 / 0.6) !important;
}

.tw-border-t-lime-100\/65 {
  border-top-color: rgb(236 252 203 / 0.65) !important;
}

.tw-border-t-lime-100\/70 {
  border-top-color: rgb(236 252 203 / 0.7) !important;
}

.tw-border-t-lime-100\/75 {
  border-top-color: rgb(236 252 203 / 0.75) !important;
}

.tw-border-t-lime-100\/80 {
  border-top-color: rgb(236 252 203 / 0.8) !important;
}

.tw-border-t-lime-100\/85 {
  border-top-color: rgb(236 252 203 / 0.85) !important;
}

.tw-border-t-lime-100\/90 {
  border-top-color: rgb(236 252 203 / 0.9) !important;
}

.tw-border-t-lime-100\/95 {
  border-top-color: rgb(236 252 203 / 0.95) !important;
}

.tw-border-t-lime-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(217 249 157 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-200\/0 {
  border-top-color: rgb(217 249 157 / 0) !important;
}

.tw-border-t-lime-200\/10 {
  border-top-color: rgb(217 249 157 / 0.1) !important;
}

.tw-border-t-lime-200\/100 {
  border-top-color: rgb(217 249 157 / 1) !important;
}

.tw-border-t-lime-200\/15 {
  border-top-color: rgb(217 249 157 / 0.15) !important;
}

.tw-border-t-lime-200\/20 {
  border-top-color: rgb(217 249 157 / 0.2) !important;
}

.tw-border-t-lime-200\/25 {
  border-top-color: rgb(217 249 157 / 0.25) !important;
}

.tw-border-t-lime-200\/30 {
  border-top-color: rgb(217 249 157 / 0.3) !important;
}

.tw-border-t-lime-200\/35 {
  border-top-color: rgb(217 249 157 / 0.35) !important;
}

.tw-border-t-lime-200\/40 {
  border-top-color: rgb(217 249 157 / 0.4) !important;
}

.tw-border-t-lime-200\/45 {
  border-top-color: rgb(217 249 157 / 0.45) !important;
}

.tw-border-t-lime-200\/5 {
  border-top-color: rgb(217 249 157 / 0.05) !important;
}

.tw-border-t-lime-200\/50 {
  border-top-color: rgb(217 249 157 / 0.5) !important;
}

.tw-border-t-lime-200\/55 {
  border-top-color: rgb(217 249 157 / 0.55) !important;
}

.tw-border-t-lime-200\/60 {
  border-top-color: rgb(217 249 157 / 0.6) !important;
}

.tw-border-t-lime-200\/65 {
  border-top-color: rgb(217 249 157 / 0.65) !important;
}

.tw-border-t-lime-200\/70 {
  border-top-color: rgb(217 249 157 / 0.7) !important;
}

.tw-border-t-lime-200\/75 {
  border-top-color: rgb(217 249 157 / 0.75) !important;
}

.tw-border-t-lime-200\/80 {
  border-top-color: rgb(217 249 157 / 0.8) !important;
}

.tw-border-t-lime-200\/85 {
  border-top-color: rgb(217 249 157 / 0.85) !important;
}

.tw-border-t-lime-200\/90 {
  border-top-color: rgb(217 249 157 / 0.9) !important;
}

.tw-border-t-lime-200\/95 {
  border-top-color: rgb(217 249 157 / 0.95) !important;
}

.tw-border-t-lime-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(190 242 100 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-300\/0 {
  border-top-color: rgb(190 242 100 / 0) !important;
}

.tw-border-t-lime-300\/10 {
  border-top-color: rgb(190 242 100 / 0.1) !important;
}

.tw-border-t-lime-300\/100 {
  border-top-color: rgb(190 242 100 / 1) !important;
}

.tw-border-t-lime-300\/15 {
  border-top-color: rgb(190 242 100 / 0.15) !important;
}

.tw-border-t-lime-300\/20 {
  border-top-color: rgb(190 242 100 / 0.2) !important;
}

.tw-border-t-lime-300\/25 {
  border-top-color: rgb(190 242 100 / 0.25) !important;
}

.tw-border-t-lime-300\/30 {
  border-top-color: rgb(190 242 100 / 0.3) !important;
}

.tw-border-t-lime-300\/35 {
  border-top-color: rgb(190 242 100 / 0.35) !important;
}

.tw-border-t-lime-300\/40 {
  border-top-color: rgb(190 242 100 / 0.4) !important;
}

.tw-border-t-lime-300\/45 {
  border-top-color: rgb(190 242 100 / 0.45) !important;
}

.tw-border-t-lime-300\/5 {
  border-top-color: rgb(190 242 100 / 0.05) !important;
}

.tw-border-t-lime-300\/50 {
  border-top-color: rgb(190 242 100 / 0.5) !important;
}

.tw-border-t-lime-300\/55 {
  border-top-color: rgb(190 242 100 / 0.55) !important;
}

.tw-border-t-lime-300\/60 {
  border-top-color: rgb(190 242 100 / 0.6) !important;
}

.tw-border-t-lime-300\/65 {
  border-top-color: rgb(190 242 100 / 0.65) !important;
}

.tw-border-t-lime-300\/70 {
  border-top-color: rgb(190 242 100 / 0.7) !important;
}

.tw-border-t-lime-300\/75 {
  border-top-color: rgb(190 242 100 / 0.75) !important;
}

.tw-border-t-lime-300\/80 {
  border-top-color: rgb(190 242 100 / 0.8) !important;
}

.tw-border-t-lime-300\/85 {
  border-top-color: rgb(190 242 100 / 0.85) !important;
}

.tw-border-t-lime-300\/90 {
  border-top-color: rgb(190 242 100 / 0.9) !important;
}

.tw-border-t-lime-300\/95 {
  border-top-color: rgb(190 242 100 / 0.95) !important;
}

.tw-border-t-lime-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(163 230 53 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-400\/0 {
  border-top-color: rgb(163 230 53 / 0) !important;
}

.tw-border-t-lime-400\/10 {
  border-top-color: rgb(163 230 53 / 0.1) !important;
}

.tw-border-t-lime-400\/100 {
  border-top-color: rgb(163 230 53 / 1) !important;
}

.tw-border-t-lime-400\/15 {
  border-top-color: rgb(163 230 53 / 0.15) !important;
}

.tw-border-t-lime-400\/20 {
  border-top-color: rgb(163 230 53 / 0.2) !important;
}

.tw-border-t-lime-400\/25 {
  border-top-color: rgb(163 230 53 / 0.25) !important;
}

.tw-border-t-lime-400\/30 {
  border-top-color: rgb(163 230 53 / 0.3) !important;
}

.tw-border-t-lime-400\/35 {
  border-top-color: rgb(163 230 53 / 0.35) !important;
}

.tw-border-t-lime-400\/40 {
  border-top-color: rgb(163 230 53 / 0.4) !important;
}

.tw-border-t-lime-400\/45 {
  border-top-color: rgb(163 230 53 / 0.45) !important;
}

.tw-border-t-lime-400\/5 {
  border-top-color: rgb(163 230 53 / 0.05) !important;
}

.tw-border-t-lime-400\/50 {
  border-top-color: rgb(163 230 53 / 0.5) !important;
}

.tw-border-t-lime-400\/55 {
  border-top-color: rgb(163 230 53 / 0.55) !important;
}

.tw-border-t-lime-400\/60 {
  border-top-color: rgb(163 230 53 / 0.6) !important;
}

.tw-border-t-lime-400\/65 {
  border-top-color: rgb(163 230 53 / 0.65) !important;
}

.tw-border-t-lime-400\/70 {
  border-top-color: rgb(163 230 53 / 0.7) !important;
}

.tw-border-t-lime-400\/75 {
  border-top-color: rgb(163 230 53 / 0.75) !important;
}

.tw-border-t-lime-400\/80 {
  border-top-color: rgb(163 230 53 / 0.8) !important;
}

.tw-border-t-lime-400\/85 {
  border-top-color: rgb(163 230 53 / 0.85) !important;
}

.tw-border-t-lime-400\/90 {
  border-top-color: rgb(163 230 53 / 0.9) !important;
}

.tw-border-t-lime-400\/95 {
  border-top-color: rgb(163 230 53 / 0.95) !important;
}

.tw-border-t-lime-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(247 254 231 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-50\/0 {
  border-top-color: rgb(247 254 231 / 0) !important;
}

.tw-border-t-lime-50\/10 {
  border-top-color: rgb(247 254 231 / 0.1) !important;
}

.tw-border-t-lime-50\/100 {
  border-top-color: rgb(247 254 231 / 1) !important;
}

.tw-border-t-lime-50\/15 {
  border-top-color: rgb(247 254 231 / 0.15) !important;
}

.tw-border-t-lime-50\/20 {
  border-top-color: rgb(247 254 231 / 0.2) !important;
}

.tw-border-t-lime-50\/25 {
  border-top-color: rgb(247 254 231 / 0.25) !important;
}

.tw-border-t-lime-50\/30 {
  border-top-color: rgb(247 254 231 / 0.3) !important;
}

.tw-border-t-lime-50\/35 {
  border-top-color: rgb(247 254 231 / 0.35) !important;
}

.tw-border-t-lime-50\/40 {
  border-top-color: rgb(247 254 231 / 0.4) !important;
}

.tw-border-t-lime-50\/45 {
  border-top-color: rgb(247 254 231 / 0.45) !important;
}

.tw-border-t-lime-50\/5 {
  border-top-color: rgb(247 254 231 / 0.05) !important;
}

.tw-border-t-lime-50\/50 {
  border-top-color: rgb(247 254 231 / 0.5) !important;
}

.tw-border-t-lime-50\/55 {
  border-top-color: rgb(247 254 231 / 0.55) !important;
}

.tw-border-t-lime-50\/60 {
  border-top-color: rgb(247 254 231 / 0.6) !important;
}

.tw-border-t-lime-50\/65 {
  border-top-color: rgb(247 254 231 / 0.65) !important;
}

.tw-border-t-lime-50\/70 {
  border-top-color: rgb(247 254 231 / 0.7) !important;
}

.tw-border-t-lime-50\/75 {
  border-top-color: rgb(247 254 231 / 0.75) !important;
}

.tw-border-t-lime-50\/80 {
  border-top-color: rgb(247 254 231 / 0.8) !important;
}

.tw-border-t-lime-50\/85 {
  border-top-color: rgb(247 254 231 / 0.85) !important;
}

.tw-border-t-lime-50\/90 {
  border-top-color: rgb(247 254 231 / 0.9) !important;
}

.tw-border-t-lime-50\/95 {
  border-top-color: rgb(247 254 231 / 0.95) !important;
}

.tw-border-t-lime-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(132 204 22 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-500\/0 {
  border-top-color: rgb(132 204 22 / 0) !important;
}

.tw-border-t-lime-500\/10 {
  border-top-color: rgb(132 204 22 / 0.1) !important;
}

.tw-border-t-lime-500\/100 {
  border-top-color: rgb(132 204 22 / 1) !important;
}

.tw-border-t-lime-500\/15 {
  border-top-color: rgb(132 204 22 / 0.15) !important;
}

.tw-border-t-lime-500\/20 {
  border-top-color: rgb(132 204 22 / 0.2) !important;
}

.tw-border-t-lime-500\/25 {
  border-top-color: rgb(132 204 22 / 0.25) !important;
}

.tw-border-t-lime-500\/30 {
  border-top-color: rgb(132 204 22 / 0.3) !important;
}

.tw-border-t-lime-500\/35 {
  border-top-color: rgb(132 204 22 / 0.35) !important;
}

.tw-border-t-lime-500\/40 {
  border-top-color: rgb(132 204 22 / 0.4) !important;
}

.tw-border-t-lime-500\/45 {
  border-top-color: rgb(132 204 22 / 0.45) !important;
}

.tw-border-t-lime-500\/5 {
  border-top-color: rgb(132 204 22 / 0.05) !important;
}

.tw-border-t-lime-500\/50 {
  border-top-color: rgb(132 204 22 / 0.5) !important;
}

.tw-border-t-lime-500\/55 {
  border-top-color: rgb(132 204 22 / 0.55) !important;
}

.tw-border-t-lime-500\/60 {
  border-top-color: rgb(132 204 22 / 0.6) !important;
}

.tw-border-t-lime-500\/65 {
  border-top-color: rgb(132 204 22 / 0.65) !important;
}

.tw-border-t-lime-500\/70 {
  border-top-color: rgb(132 204 22 / 0.7) !important;
}

.tw-border-t-lime-500\/75 {
  border-top-color: rgb(132 204 22 / 0.75) !important;
}

.tw-border-t-lime-500\/80 {
  border-top-color: rgb(132 204 22 / 0.8) !important;
}

.tw-border-t-lime-500\/85 {
  border-top-color: rgb(132 204 22 / 0.85) !important;
}

.tw-border-t-lime-500\/90 {
  border-top-color: rgb(132 204 22 / 0.9) !important;
}

.tw-border-t-lime-500\/95 {
  border-top-color: rgb(132 204 22 / 0.95) !important;
}

.tw-border-t-lime-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(101 163 13 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-600\/0 {
  border-top-color: rgb(101 163 13 / 0) !important;
}

.tw-border-t-lime-600\/10 {
  border-top-color: rgb(101 163 13 / 0.1) !important;
}

.tw-border-t-lime-600\/100 {
  border-top-color: rgb(101 163 13 / 1) !important;
}

.tw-border-t-lime-600\/15 {
  border-top-color: rgb(101 163 13 / 0.15) !important;
}

.tw-border-t-lime-600\/20 {
  border-top-color: rgb(101 163 13 / 0.2) !important;
}

.tw-border-t-lime-600\/25 {
  border-top-color: rgb(101 163 13 / 0.25) !important;
}

.tw-border-t-lime-600\/30 {
  border-top-color: rgb(101 163 13 / 0.3) !important;
}

.tw-border-t-lime-600\/35 {
  border-top-color: rgb(101 163 13 / 0.35) !important;
}

.tw-border-t-lime-600\/40 {
  border-top-color: rgb(101 163 13 / 0.4) !important;
}

.tw-border-t-lime-600\/45 {
  border-top-color: rgb(101 163 13 / 0.45) !important;
}

.tw-border-t-lime-600\/5 {
  border-top-color: rgb(101 163 13 / 0.05) !important;
}

.tw-border-t-lime-600\/50 {
  border-top-color: rgb(101 163 13 / 0.5) !important;
}

.tw-border-t-lime-600\/55 {
  border-top-color: rgb(101 163 13 / 0.55) !important;
}

.tw-border-t-lime-600\/60 {
  border-top-color: rgb(101 163 13 / 0.6) !important;
}

.tw-border-t-lime-600\/65 {
  border-top-color: rgb(101 163 13 / 0.65) !important;
}

.tw-border-t-lime-600\/70 {
  border-top-color: rgb(101 163 13 / 0.7) !important;
}

.tw-border-t-lime-600\/75 {
  border-top-color: rgb(101 163 13 / 0.75) !important;
}

.tw-border-t-lime-600\/80 {
  border-top-color: rgb(101 163 13 / 0.8) !important;
}

.tw-border-t-lime-600\/85 {
  border-top-color: rgb(101 163 13 / 0.85) !important;
}

.tw-border-t-lime-600\/90 {
  border-top-color: rgb(101 163 13 / 0.9) !important;
}

.tw-border-t-lime-600\/95 {
  border-top-color: rgb(101 163 13 / 0.95) !important;
}

.tw-border-t-lime-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(77 124 15 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-700\/0 {
  border-top-color: rgb(77 124 15 / 0) !important;
}

.tw-border-t-lime-700\/10 {
  border-top-color: rgb(77 124 15 / 0.1) !important;
}

.tw-border-t-lime-700\/100 {
  border-top-color: rgb(77 124 15 / 1) !important;
}

.tw-border-t-lime-700\/15 {
  border-top-color: rgb(77 124 15 / 0.15) !important;
}

.tw-border-t-lime-700\/20 {
  border-top-color: rgb(77 124 15 / 0.2) !important;
}

.tw-border-t-lime-700\/25 {
  border-top-color: rgb(77 124 15 / 0.25) !important;
}

.tw-border-t-lime-700\/30 {
  border-top-color: rgb(77 124 15 / 0.3) !important;
}

.tw-border-t-lime-700\/35 {
  border-top-color: rgb(77 124 15 / 0.35) !important;
}

.tw-border-t-lime-700\/40 {
  border-top-color: rgb(77 124 15 / 0.4) !important;
}

.tw-border-t-lime-700\/45 {
  border-top-color: rgb(77 124 15 / 0.45) !important;
}

.tw-border-t-lime-700\/5 {
  border-top-color: rgb(77 124 15 / 0.05) !important;
}

.tw-border-t-lime-700\/50 {
  border-top-color: rgb(77 124 15 / 0.5) !important;
}

.tw-border-t-lime-700\/55 {
  border-top-color: rgb(77 124 15 / 0.55) !important;
}

.tw-border-t-lime-700\/60 {
  border-top-color: rgb(77 124 15 / 0.6) !important;
}

.tw-border-t-lime-700\/65 {
  border-top-color: rgb(77 124 15 / 0.65) !important;
}

.tw-border-t-lime-700\/70 {
  border-top-color: rgb(77 124 15 / 0.7) !important;
}

.tw-border-t-lime-700\/75 {
  border-top-color: rgb(77 124 15 / 0.75) !important;
}

.tw-border-t-lime-700\/80 {
  border-top-color: rgb(77 124 15 / 0.8) !important;
}

.tw-border-t-lime-700\/85 {
  border-top-color: rgb(77 124 15 / 0.85) !important;
}

.tw-border-t-lime-700\/90 {
  border-top-color: rgb(77 124 15 / 0.9) !important;
}

.tw-border-t-lime-700\/95 {
  border-top-color: rgb(77 124 15 / 0.95) !important;
}

.tw-border-t-lime-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(63 98 18 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-800\/0 {
  border-top-color: rgb(63 98 18 / 0) !important;
}

.tw-border-t-lime-800\/10 {
  border-top-color: rgb(63 98 18 / 0.1) !important;
}

.tw-border-t-lime-800\/100 {
  border-top-color: rgb(63 98 18 / 1) !important;
}

.tw-border-t-lime-800\/15 {
  border-top-color: rgb(63 98 18 / 0.15) !important;
}

.tw-border-t-lime-800\/20 {
  border-top-color: rgb(63 98 18 / 0.2) !important;
}

.tw-border-t-lime-800\/25 {
  border-top-color: rgb(63 98 18 / 0.25) !important;
}

.tw-border-t-lime-800\/30 {
  border-top-color: rgb(63 98 18 / 0.3) !important;
}

.tw-border-t-lime-800\/35 {
  border-top-color: rgb(63 98 18 / 0.35) !important;
}

.tw-border-t-lime-800\/40 {
  border-top-color: rgb(63 98 18 / 0.4) !important;
}

.tw-border-t-lime-800\/45 {
  border-top-color: rgb(63 98 18 / 0.45) !important;
}

.tw-border-t-lime-800\/5 {
  border-top-color: rgb(63 98 18 / 0.05) !important;
}

.tw-border-t-lime-800\/50 {
  border-top-color: rgb(63 98 18 / 0.5) !important;
}

.tw-border-t-lime-800\/55 {
  border-top-color: rgb(63 98 18 / 0.55) !important;
}

.tw-border-t-lime-800\/60 {
  border-top-color: rgb(63 98 18 / 0.6) !important;
}

.tw-border-t-lime-800\/65 {
  border-top-color: rgb(63 98 18 / 0.65) !important;
}

.tw-border-t-lime-800\/70 {
  border-top-color: rgb(63 98 18 / 0.7) !important;
}

.tw-border-t-lime-800\/75 {
  border-top-color: rgb(63 98 18 / 0.75) !important;
}

.tw-border-t-lime-800\/80 {
  border-top-color: rgb(63 98 18 / 0.8) !important;
}

.tw-border-t-lime-800\/85 {
  border-top-color: rgb(63 98 18 / 0.85) !important;
}

.tw-border-t-lime-800\/90 {
  border-top-color: rgb(63 98 18 / 0.9) !important;
}

.tw-border-t-lime-800\/95 {
  border-top-color: rgb(63 98 18 / 0.95) !important;
}

.tw-border-t-lime-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(54 83 20 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-900\/0 {
  border-top-color: rgb(54 83 20 / 0) !important;
}

.tw-border-t-lime-900\/10 {
  border-top-color: rgb(54 83 20 / 0.1) !important;
}

.tw-border-t-lime-900\/100 {
  border-top-color: rgb(54 83 20 / 1) !important;
}

.tw-border-t-lime-900\/15 {
  border-top-color: rgb(54 83 20 / 0.15) !important;
}

.tw-border-t-lime-900\/20 {
  border-top-color: rgb(54 83 20 / 0.2) !important;
}

.tw-border-t-lime-900\/25 {
  border-top-color: rgb(54 83 20 / 0.25) !important;
}

.tw-border-t-lime-900\/30 {
  border-top-color: rgb(54 83 20 / 0.3) !important;
}

.tw-border-t-lime-900\/35 {
  border-top-color: rgb(54 83 20 / 0.35) !important;
}

.tw-border-t-lime-900\/40 {
  border-top-color: rgb(54 83 20 / 0.4) !important;
}

.tw-border-t-lime-900\/45 {
  border-top-color: rgb(54 83 20 / 0.45) !important;
}

.tw-border-t-lime-900\/5 {
  border-top-color: rgb(54 83 20 / 0.05) !important;
}

.tw-border-t-lime-900\/50 {
  border-top-color: rgb(54 83 20 / 0.5) !important;
}

.tw-border-t-lime-900\/55 {
  border-top-color: rgb(54 83 20 / 0.55) !important;
}

.tw-border-t-lime-900\/60 {
  border-top-color: rgb(54 83 20 / 0.6) !important;
}

.tw-border-t-lime-900\/65 {
  border-top-color: rgb(54 83 20 / 0.65) !important;
}

.tw-border-t-lime-900\/70 {
  border-top-color: rgb(54 83 20 / 0.7) !important;
}

.tw-border-t-lime-900\/75 {
  border-top-color: rgb(54 83 20 / 0.75) !important;
}

.tw-border-t-lime-900\/80 {
  border-top-color: rgb(54 83 20 / 0.8) !important;
}

.tw-border-t-lime-900\/85 {
  border-top-color: rgb(54 83 20 / 0.85) !important;
}

.tw-border-t-lime-900\/90 {
  border-top-color: rgb(54 83 20 / 0.9) !important;
}

.tw-border-t-lime-900\/95 {
  border-top-color: rgb(54 83 20 / 0.95) !important;
}

.tw-border-t-lime-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(26 46 5 / var(--tw-border-opacity)) !important;
}

.tw-border-t-lime-950\/0 {
  border-top-color: rgb(26 46 5 / 0) !important;
}

.tw-border-t-lime-950\/10 {
  border-top-color: rgb(26 46 5 / 0.1) !important;
}

.tw-border-t-lime-950\/100 {
  border-top-color: rgb(26 46 5 / 1) !important;
}

.tw-border-t-lime-950\/15 {
  border-top-color: rgb(26 46 5 / 0.15) !important;
}

.tw-border-t-lime-950\/20 {
  border-top-color: rgb(26 46 5 / 0.2) !important;
}

.tw-border-t-lime-950\/25 {
  border-top-color: rgb(26 46 5 / 0.25) !important;
}

.tw-border-t-lime-950\/30 {
  border-top-color: rgb(26 46 5 / 0.3) !important;
}

.tw-border-t-lime-950\/35 {
  border-top-color: rgb(26 46 5 / 0.35) !important;
}

.tw-border-t-lime-950\/40 {
  border-top-color: rgb(26 46 5 / 0.4) !important;
}

.tw-border-t-lime-950\/45 {
  border-top-color: rgb(26 46 5 / 0.45) !important;
}

.tw-border-t-lime-950\/5 {
  border-top-color: rgb(26 46 5 / 0.05) !important;
}

.tw-border-t-lime-950\/50 {
  border-top-color: rgb(26 46 5 / 0.5) !important;
}

.tw-border-t-lime-950\/55 {
  border-top-color: rgb(26 46 5 / 0.55) !important;
}

.tw-border-t-lime-950\/60 {
  border-top-color: rgb(26 46 5 / 0.6) !important;
}

.tw-border-t-lime-950\/65 {
  border-top-color: rgb(26 46 5 / 0.65) !important;
}

.tw-border-t-lime-950\/70 {
  border-top-color: rgb(26 46 5 / 0.7) !important;
}

.tw-border-t-lime-950\/75 {
  border-top-color: rgb(26 46 5 / 0.75) !important;
}

.tw-border-t-lime-950\/80 {
  border-top-color: rgb(26 46 5 / 0.8) !important;
}

.tw-border-t-lime-950\/85 {
  border-top-color: rgb(26 46 5 / 0.85) !important;
}

.tw-border-t-lime-950\/90 {
  border-top-color: rgb(26 46 5 / 0.9) !important;
}

.tw-border-t-lime-950\/95 {
  border-top-color: rgb(26 46 5 / 0.95) !important;
}

.tw-border-t-neutral-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 245 245 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-100\/0 {
  border-top-color: rgb(245 245 245 / 0) !important;
}

.tw-border-t-neutral-100\/10 {
  border-top-color: rgb(245 245 245 / 0.1) !important;
}

.tw-border-t-neutral-100\/100 {
  border-top-color: rgb(245 245 245 / 1) !important;
}

.tw-border-t-neutral-100\/15 {
  border-top-color: rgb(245 245 245 / 0.15) !important;
}

.tw-border-t-neutral-100\/20 {
  border-top-color: rgb(245 245 245 / 0.2) !important;
}

.tw-border-t-neutral-100\/25 {
  border-top-color: rgb(245 245 245 / 0.25) !important;
}

.tw-border-t-neutral-100\/30 {
  border-top-color: rgb(245 245 245 / 0.3) !important;
}

.tw-border-t-neutral-100\/35 {
  border-top-color: rgb(245 245 245 / 0.35) !important;
}

.tw-border-t-neutral-100\/40 {
  border-top-color: rgb(245 245 245 / 0.4) !important;
}

.tw-border-t-neutral-100\/45 {
  border-top-color: rgb(245 245 245 / 0.45) !important;
}

.tw-border-t-neutral-100\/5 {
  border-top-color: rgb(245 245 245 / 0.05) !important;
}

.tw-border-t-neutral-100\/50 {
  border-top-color: rgb(245 245 245 / 0.5) !important;
}

.tw-border-t-neutral-100\/55 {
  border-top-color: rgb(245 245 245 / 0.55) !important;
}

.tw-border-t-neutral-100\/60 {
  border-top-color: rgb(245 245 245 / 0.6) !important;
}

.tw-border-t-neutral-100\/65 {
  border-top-color: rgb(245 245 245 / 0.65) !important;
}

.tw-border-t-neutral-100\/70 {
  border-top-color: rgb(245 245 245 / 0.7) !important;
}

.tw-border-t-neutral-100\/75 {
  border-top-color: rgb(245 245 245 / 0.75) !important;
}

.tw-border-t-neutral-100\/80 {
  border-top-color: rgb(245 245 245 / 0.8) !important;
}

.tw-border-t-neutral-100\/85 {
  border-top-color: rgb(245 245 245 / 0.85) !important;
}

.tw-border-t-neutral-100\/90 {
  border-top-color: rgb(245 245 245 / 0.9) !important;
}

.tw-border-t-neutral-100\/95 {
  border-top-color: rgb(245 245 245 / 0.95) !important;
}

.tw-border-t-neutral-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(229 229 229 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-200\/0 {
  border-top-color: rgb(229 229 229 / 0) !important;
}

.tw-border-t-neutral-200\/10 {
  border-top-color: rgb(229 229 229 / 0.1) !important;
}

.tw-border-t-neutral-200\/100 {
  border-top-color: rgb(229 229 229 / 1) !important;
}

.tw-border-t-neutral-200\/15 {
  border-top-color: rgb(229 229 229 / 0.15) !important;
}

.tw-border-t-neutral-200\/20 {
  border-top-color: rgb(229 229 229 / 0.2) !important;
}

.tw-border-t-neutral-200\/25 {
  border-top-color: rgb(229 229 229 / 0.25) !important;
}

.tw-border-t-neutral-200\/30 {
  border-top-color: rgb(229 229 229 / 0.3) !important;
}

.tw-border-t-neutral-200\/35 {
  border-top-color: rgb(229 229 229 / 0.35) !important;
}

.tw-border-t-neutral-200\/40 {
  border-top-color: rgb(229 229 229 / 0.4) !important;
}

.tw-border-t-neutral-200\/45 {
  border-top-color: rgb(229 229 229 / 0.45) !important;
}

.tw-border-t-neutral-200\/5 {
  border-top-color: rgb(229 229 229 / 0.05) !important;
}

.tw-border-t-neutral-200\/50 {
  border-top-color: rgb(229 229 229 / 0.5) !important;
}

.tw-border-t-neutral-200\/55 {
  border-top-color: rgb(229 229 229 / 0.55) !important;
}

.tw-border-t-neutral-200\/60 {
  border-top-color: rgb(229 229 229 / 0.6) !important;
}

.tw-border-t-neutral-200\/65 {
  border-top-color: rgb(229 229 229 / 0.65) !important;
}

.tw-border-t-neutral-200\/70 {
  border-top-color: rgb(229 229 229 / 0.7) !important;
}

.tw-border-t-neutral-200\/75 {
  border-top-color: rgb(229 229 229 / 0.75) !important;
}

.tw-border-t-neutral-200\/80 {
  border-top-color: rgb(229 229 229 / 0.8) !important;
}

.tw-border-t-neutral-200\/85 {
  border-top-color: rgb(229 229 229 / 0.85) !important;
}

.tw-border-t-neutral-200\/90 {
  border-top-color: rgb(229 229 229 / 0.9) !important;
}

.tw-border-t-neutral-200\/95 {
  border-top-color: rgb(229 229 229 / 0.95) !important;
}

.tw-border-t-neutral-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(212 212 212 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-300\/0 {
  border-top-color: rgb(212 212 212 / 0) !important;
}

.tw-border-t-neutral-300\/10 {
  border-top-color: rgb(212 212 212 / 0.1) !important;
}

.tw-border-t-neutral-300\/100 {
  border-top-color: rgb(212 212 212 / 1) !important;
}

.tw-border-t-neutral-300\/15 {
  border-top-color: rgb(212 212 212 / 0.15) !important;
}

.tw-border-t-neutral-300\/20 {
  border-top-color: rgb(212 212 212 / 0.2) !important;
}

.tw-border-t-neutral-300\/25 {
  border-top-color: rgb(212 212 212 / 0.25) !important;
}

.tw-border-t-neutral-300\/30 {
  border-top-color: rgb(212 212 212 / 0.3) !important;
}

.tw-border-t-neutral-300\/35 {
  border-top-color: rgb(212 212 212 / 0.35) !important;
}

.tw-border-t-neutral-300\/40 {
  border-top-color: rgb(212 212 212 / 0.4) !important;
}

.tw-border-t-neutral-300\/45 {
  border-top-color: rgb(212 212 212 / 0.45) !important;
}

.tw-border-t-neutral-300\/5 {
  border-top-color: rgb(212 212 212 / 0.05) !important;
}

.tw-border-t-neutral-300\/50 {
  border-top-color: rgb(212 212 212 / 0.5) !important;
}

.tw-border-t-neutral-300\/55 {
  border-top-color: rgb(212 212 212 / 0.55) !important;
}

.tw-border-t-neutral-300\/60 {
  border-top-color: rgb(212 212 212 / 0.6) !important;
}

.tw-border-t-neutral-300\/65 {
  border-top-color: rgb(212 212 212 / 0.65) !important;
}

.tw-border-t-neutral-300\/70 {
  border-top-color: rgb(212 212 212 / 0.7) !important;
}

.tw-border-t-neutral-300\/75 {
  border-top-color: rgb(212 212 212 / 0.75) !important;
}

.tw-border-t-neutral-300\/80 {
  border-top-color: rgb(212 212 212 / 0.8) !important;
}

.tw-border-t-neutral-300\/85 {
  border-top-color: rgb(212 212 212 / 0.85) !important;
}

.tw-border-t-neutral-300\/90 {
  border-top-color: rgb(212 212 212 / 0.9) !important;
}

.tw-border-t-neutral-300\/95 {
  border-top-color: rgb(212 212 212 / 0.95) !important;
}

.tw-border-t-neutral-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(163 163 163 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-400\/0 {
  border-top-color: rgb(163 163 163 / 0) !important;
}

.tw-border-t-neutral-400\/10 {
  border-top-color: rgb(163 163 163 / 0.1) !important;
}

.tw-border-t-neutral-400\/100 {
  border-top-color: rgb(163 163 163 / 1) !important;
}

.tw-border-t-neutral-400\/15 {
  border-top-color: rgb(163 163 163 / 0.15) !important;
}

.tw-border-t-neutral-400\/20 {
  border-top-color: rgb(163 163 163 / 0.2) !important;
}

.tw-border-t-neutral-400\/25 {
  border-top-color: rgb(163 163 163 / 0.25) !important;
}

.tw-border-t-neutral-400\/30 {
  border-top-color: rgb(163 163 163 / 0.3) !important;
}

.tw-border-t-neutral-400\/35 {
  border-top-color: rgb(163 163 163 / 0.35) !important;
}

.tw-border-t-neutral-400\/40 {
  border-top-color: rgb(163 163 163 / 0.4) !important;
}

.tw-border-t-neutral-400\/45 {
  border-top-color: rgb(163 163 163 / 0.45) !important;
}

.tw-border-t-neutral-400\/5 {
  border-top-color: rgb(163 163 163 / 0.05) !important;
}

.tw-border-t-neutral-400\/50 {
  border-top-color: rgb(163 163 163 / 0.5) !important;
}

.tw-border-t-neutral-400\/55 {
  border-top-color: rgb(163 163 163 / 0.55) !important;
}

.tw-border-t-neutral-400\/60 {
  border-top-color: rgb(163 163 163 / 0.6) !important;
}

.tw-border-t-neutral-400\/65 {
  border-top-color: rgb(163 163 163 / 0.65) !important;
}

.tw-border-t-neutral-400\/70 {
  border-top-color: rgb(163 163 163 / 0.7) !important;
}

.tw-border-t-neutral-400\/75 {
  border-top-color: rgb(163 163 163 / 0.75) !important;
}

.tw-border-t-neutral-400\/80 {
  border-top-color: rgb(163 163 163 / 0.8) !important;
}

.tw-border-t-neutral-400\/85 {
  border-top-color: rgb(163 163 163 / 0.85) !important;
}

.tw-border-t-neutral-400\/90 {
  border-top-color: rgb(163 163 163 / 0.9) !important;
}

.tw-border-t-neutral-400\/95 {
  border-top-color: rgb(163 163 163 / 0.95) !important;
}

.tw-border-t-neutral-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-50\/0 {
  border-top-color: rgb(250 250 250 / 0) !important;
}

.tw-border-t-neutral-50\/10 {
  border-top-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-t-neutral-50\/100 {
  border-top-color: rgb(250 250 250 / 1) !important;
}

.tw-border-t-neutral-50\/15 {
  border-top-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-t-neutral-50\/20 {
  border-top-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-t-neutral-50\/25 {
  border-top-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-t-neutral-50\/30 {
  border-top-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-t-neutral-50\/35 {
  border-top-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-t-neutral-50\/40 {
  border-top-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-t-neutral-50\/45 {
  border-top-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-t-neutral-50\/5 {
  border-top-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-t-neutral-50\/50 {
  border-top-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-t-neutral-50\/55 {
  border-top-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-t-neutral-50\/60 {
  border-top-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-t-neutral-50\/65 {
  border-top-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-t-neutral-50\/70 {
  border-top-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-t-neutral-50\/75 {
  border-top-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-t-neutral-50\/80 {
  border-top-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-t-neutral-50\/85 {
  border-top-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-t-neutral-50\/90 {
  border-top-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-t-neutral-50\/95 {
  border-top-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-t-neutral-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(115 115 115 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-500\/0 {
  border-top-color: rgb(115 115 115 / 0) !important;
}

.tw-border-t-neutral-500\/10 {
  border-top-color: rgb(115 115 115 / 0.1) !important;
}

.tw-border-t-neutral-500\/100 {
  border-top-color: rgb(115 115 115 / 1) !important;
}

.tw-border-t-neutral-500\/15 {
  border-top-color: rgb(115 115 115 / 0.15) !important;
}

.tw-border-t-neutral-500\/20 {
  border-top-color: rgb(115 115 115 / 0.2) !important;
}

.tw-border-t-neutral-500\/25 {
  border-top-color: rgb(115 115 115 / 0.25) !important;
}

.tw-border-t-neutral-500\/30 {
  border-top-color: rgb(115 115 115 / 0.3) !important;
}

.tw-border-t-neutral-500\/35 {
  border-top-color: rgb(115 115 115 / 0.35) !important;
}

.tw-border-t-neutral-500\/40 {
  border-top-color: rgb(115 115 115 / 0.4) !important;
}

.tw-border-t-neutral-500\/45 {
  border-top-color: rgb(115 115 115 / 0.45) !important;
}

.tw-border-t-neutral-500\/5 {
  border-top-color: rgb(115 115 115 / 0.05) !important;
}

.tw-border-t-neutral-500\/50 {
  border-top-color: rgb(115 115 115 / 0.5) !important;
}

.tw-border-t-neutral-500\/55 {
  border-top-color: rgb(115 115 115 / 0.55) !important;
}

.tw-border-t-neutral-500\/60 {
  border-top-color: rgb(115 115 115 / 0.6) !important;
}

.tw-border-t-neutral-500\/65 {
  border-top-color: rgb(115 115 115 / 0.65) !important;
}

.tw-border-t-neutral-500\/70 {
  border-top-color: rgb(115 115 115 / 0.7) !important;
}

.tw-border-t-neutral-500\/75 {
  border-top-color: rgb(115 115 115 / 0.75) !important;
}

.tw-border-t-neutral-500\/80 {
  border-top-color: rgb(115 115 115 / 0.8) !important;
}

.tw-border-t-neutral-500\/85 {
  border-top-color: rgb(115 115 115 / 0.85) !important;
}

.tw-border-t-neutral-500\/90 {
  border-top-color: rgb(115 115 115 / 0.9) !important;
}

.tw-border-t-neutral-500\/95 {
  border-top-color: rgb(115 115 115 / 0.95) !important;
}

.tw-border-t-neutral-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(82 82 82 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-600\/0 {
  border-top-color: rgb(82 82 82 / 0) !important;
}

.tw-border-t-neutral-600\/10 {
  border-top-color: rgb(82 82 82 / 0.1) !important;
}

.tw-border-t-neutral-600\/100 {
  border-top-color: rgb(82 82 82 / 1) !important;
}

.tw-border-t-neutral-600\/15 {
  border-top-color: rgb(82 82 82 / 0.15) !important;
}

.tw-border-t-neutral-600\/20 {
  border-top-color: rgb(82 82 82 / 0.2) !important;
}

.tw-border-t-neutral-600\/25 {
  border-top-color: rgb(82 82 82 / 0.25) !important;
}

.tw-border-t-neutral-600\/30 {
  border-top-color: rgb(82 82 82 / 0.3) !important;
}

.tw-border-t-neutral-600\/35 {
  border-top-color: rgb(82 82 82 / 0.35) !important;
}

.tw-border-t-neutral-600\/40 {
  border-top-color: rgb(82 82 82 / 0.4) !important;
}

.tw-border-t-neutral-600\/45 {
  border-top-color: rgb(82 82 82 / 0.45) !important;
}

.tw-border-t-neutral-600\/5 {
  border-top-color: rgb(82 82 82 / 0.05) !important;
}

.tw-border-t-neutral-600\/50 {
  border-top-color: rgb(82 82 82 / 0.5) !important;
}

.tw-border-t-neutral-600\/55 {
  border-top-color: rgb(82 82 82 / 0.55) !important;
}

.tw-border-t-neutral-600\/60 {
  border-top-color: rgb(82 82 82 / 0.6) !important;
}

.tw-border-t-neutral-600\/65 {
  border-top-color: rgb(82 82 82 / 0.65) !important;
}

.tw-border-t-neutral-600\/70 {
  border-top-color: rgb(82 82 82 / 0.7) !important;
}

.tw-border-t-neutral-600\/75 {
  border-top-color: rgb(82 82 82 / 0.75) !important;
}

.tw-border-t-neutral-600\/80 {
  border-top-color: rgb(82 82 82 / 0.8) !important;
}

.tw-border-t-neutral-600\/85 {
  border-top-color: rgb(82 82 82 / 0.85) !important;
}

.tw-border-t-neutral-600\/90 {
  border-top-color: rgb(82 82 82 / 0.9) !important;
}

.tw-border-t-neutral-600\/95 {
  border-top-color: rgb(82 82 82 / 0.95) !important;
}

.tw-border-t-neutral-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(64 64 64 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-700\/0 {
  border-top-color: rgb(64 64 64 / 0) !important;
}

.tw-border-t-neutral-700\/10 {
  border-top-color: rgb(64 64 64 / 0.1) !important;
}

.tw-border-t-neutral-700\/100 {
  border-top-color: rgb(64 64 64 / 1) !important;
}

.tw-border-t-neutral-700\/15 {
  border-top-color: rgb(64 64 64 / 0.15) !important;
}

.tw-border-t-neutral-700\/20 {
  border-top-color: rgb(64 64 64 / 0.2) !important;
}

.tw-border-t-neutral-700\/25 {
  border-top-color: rgb(64 64 64 / 0.25) !important;
}

.tw-border-t-neutral-700\/30 {
  border-top-color: rgb(64 64 64 / 0.3) !important;
}

.tw-border-t-neutral-700\/35 {
  border-top-color: rgb(64 64 64 / 0.35) !important;
}

.tw-border-t-neutral-700\/40 {
  border-top-color: rgb(64 64 64 / 0.4) !important;
}

.tw-border-t-neutral-700\/45 {
  border-top-color: rgb(64 64 64 / 0.45) !important;
}

.tw-border-t-neutral-700\/5 {
  border-top-color: rgb(64 64 64 / 0.05) !important;
}

.tw-border-t-neutral-700\/50 {
  border-top-color: rgb(64 64 64 / 0.5) !important;
}

.tw-border-t-neutral-700\/55 {
  border-top-color: rgb(64 64 64 / 0.55) !important;
}

.tw-border-t-neutral-700\/60 {
  border-top-color: rgb(64 64 64 / 0.6) !important;
}

.tw-border-t-neutral-700\/65 {
  border-top-color: rgb(64 64 64 / 0.65) !important;
}

.tw-border-t-neutral-700\/70 {
  border-top-color: rgb(64 64 64 / 0.7) !important;
}

.tw-border-t-neutral-700\/75 {
  border-top-color: rgb(64 64 64 / 0.75) !important;
}

.tw-border-t-neutral-700\/80 {
  border-top-color: rgb(64 64 64 / 0.8) !important;
}

.tw-border-t-neutral-700\/85 {
  border-top-color: rgb(64 64 64 / 0.85) !important;
}

.tw-border-t-neutral-700\/90 {
  border-top-color: rgb(64 64 64 / 0.9) !important;
}

.tw-border-t-neutral-700\/95 {
  border-top-color: rgb(64 64 64 / 0.95) !important;
}

.tw-border-t-neutral-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(38 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-800\/0 {
  border-top-color: rgb(38 38 38 / 0) !important;
}

.tw-border-t-neutral-800\/10 {
  border-top-color: rgb(38 38 38 / 0.1) !important;
}

.tw-border-t-neutral-800\/100 {
  border-top-color: rgb(38 38 38 / 1) !important;
}

.tw-border-t-neutral-800\/15 {
  border-top-color: rgb(38 38 38 / 0.15) !important;
}

.tw-border-t-neutral-800\/20 {
  border-top-color: rgb(38 38 38 / 0.2) !important;
}

.tw-border-t-neutral-800\/25 {
  border-top-color: rgb(38 38 38 / 0.25) !important;
}

.tw-border-t-neutral-800\/30 {
  border-top-color: rgb(38 38 38 / 0.3) !important;
}

.tw-border-t-neutral-800\/35 {
  border-top-color: rgb(38 38 38 / 0.35) !important;
}

.tw-border-t-neutral-800\/40 {
  border-top-color: rgb(38 38 38 / 0.4) !important;
}

.tw-border-t-neutral-800\/45 {
  border-top-color: rgb(38 38 38 / 0.45) !important;
}

.tw-border-t-neutral-800\/5 {
  border-top-color: rgb(38 38 38 / 0.05) !important;
}

.tw-border-t-neutral-800\/50 {
  border-top-color: rgb(38 38 38 / 0.5) !important;
}

.tw-border-t-neutral-800\/55 {
  border-top-color: rgb(38 38 38 / 0.55) !important;
}

.tw-border-t-neutral-800\/60 {
  border-top-color: rgb(38 38 38 / 0.6) !important;
}

.tw-border-t-neutral-800\/65 {
  border-top-color: rgb(38 38 38 / 0.65) !important;
}

.tw-border-t-neutral-800\/70 {
  border-top-color: rgb(38 38 38 / 0.7) !important;
}

.tw-border-t-neutral-800\/75 {
  border-top-color: rgb(38 38 38 / 0.75) !important;
}

.tw-border-t-neutral-800\/80 {
  border-top-color: rgb(38 38 38 / 0.8) !important;
}

.tw-border-t-neutral-800\/85 {
  border-top-color: rgb(38 38 38 / 0.85) !important;
}

.tw-border-t-neutral-800\/90 {
  border-top-color: rgb(38 38 38 / 0.9) !important;
}

.tw-border-t-neutral-800\/95 {
  border-top-color: rgb(38 38 38 / 0.95) !important;
}

.tw-border-t-neutral-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(23 23 23 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-900\/0 {
  border-top-color: rgb(23 23 23 / 0) !important;
}

.tw-border-t-neutral-900\/10 {
  border-top-color: rgb(23 23 23 / 0.1) !important;
}

.tw-border-t-neutral-900\/100 {
  border-top-color: rgb(23 23 23 / 1) !important;
}

.tw-border-t-neutral-900\/15 {
  border-top-color: rgb(23 23 23 / 0.15) !important;
}

.tw-border-t-neutral-900\/20 {
  border-top-color: rgb(23 23 23 / 0.2) !important;
}

.tw-border-t-neutral-900\/25 {
  border-top-color: rgb(23 23 23 / 0.25) !important;
}

.tw-border-t-neutral-900\/30 {
  border-top-color: rgb(23 23 23 / 0.3) !important;
}

.tw-border-t-neutral-900\/35 {
  border-top-color: rgb(23 23 23 / 0.35) !important;
}

.tw-border-t-neutral-900\/40 {
  border-top-color: rgb(23 23 23 / 0.4) !important;
}

.tw-border-t-neutral-900\/45 {
  border-top-color: rgb(23 23 23 / 0.45) !important;
}

.tw-border-t-neutral-900\/5 {
  border-top-color: rgb(23 23 23 / 0.05) !important;
}

.tw-border-t-neutral-900\/50 {
  border-top-color: rgb(23 23 23 / 0.5) !important;
}

.tw-border-t-neutral-900\/55 {
  border-top-color: rgb(23 23 23 / 0.55) !important;
}

.tw-border-t-neutral-900\/60 {
  border-top-color: rgb(23 23 23 / 0.6) !important;
}

.tw-border-t-neutral-900\/65 {
  border-top-color: rgb(23 23 23 / 0.65) !important;
}

.tw-border-t-neutral-900\/70 {
  border-top-color: rgb(23 23 23 / 0.7) !important;
}

.tw-border-t-neutral-900\/75 {
  border-top-color: rgb(23 23 23 / 0.75) !important;
}

.tw-border-t-neutral-900\/80 {
  border-top-color: rgb(23 23 23 / 0.8) !important;
}

.tw-border-t-neutral-900\/85 {
  border-top-color: rgb(23 23 23 / 0.85) !important;
}

.tw-border-t-neutral-900\/90 {
  border-top-color: rgb(23 23 23 / 0.9) !important;
}

.tw-border-t-neutral-900\/95 {
  border-top-color: rgb(23 23 23 / 0.95) !important;
}

.tw-border-t-neutral-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(10 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-t-neutral-950\/0 {
  border-top-color: rgb(10 10 10 / 0) !important;
}

.tw-border-t-neutral-950\/10 {
  border-top-color: rgb(10 10 10 / 0.1) !important;
}

.tw-border-t-neutral-950\/100 {
  border-top-color: rgb(10 10 10 / 1) !important;
}

.tw-border-t-neutral-950\/15 {
  border-top-color: rgb(10 10 10 / 0.15) !important;
}

.tw-border-t-neutral-950\/20 {
  border-top-color: rgb(10 10 10 / 0.2) !important;
}

.tw-border-t-neutral-950\/25 {
  border-top-color: rgb(10 10 10 / 0.25) !important;
}

.tw-border-t-neutral-950\/30 {
  border-top-color: rgb(10 10 10 / 0.3) !important;
}

.tw-border-t-neutral-950\/35 {
  border-top-color: rgb(10 10 10 / 0.35) !important;
}

.tw-border-t-neutral-950\/40 {
  border-top-color: rgb(10 10 10 / 0.4) !important;
}

.tw-border-t-neutral-950\/45 {
  border-top-color: rgb(10 10 10 / 0.45) !important;
}

.tw-border-t-neutral-950\/5 {
  border-top-color: rgb(10 10 10 / 0.05) !important;
}

.tw-border-t-neutral-950\/50 {
  border-top-color: rgb(10 10 10 / 0.5) !important;
}

.tw-border-t-neutral-950\/55 {
  border-top-color: rgb(10 10 10 / 0.55) !important;
}

.tw-border-t-neutral-950\/60 {
  border-top-color: rgb(10 10 10 / 0.6) !important;
}

.tw-border-t-neutral-950\/65 {
  border-top-color: rgb(10 10 10 / 0.65) !important;
}

.tw-border-t-neutral-950\/70 {
  border-top-color: rgb(10 10 10 / 0.7) !important;
}

.tw-border-t-neutral-950\/75 {
  border-top-color: rgb(10 10 10 / 0.75) !important;
}

.tw-border-t-neutral-950\/80 {
  border-top-color: rgb(10 10 10 / 0.8) !important;
}

.tw-border-t-neutral-950\/85 {
  border-top-color: rgb(10 10 10 / 0.85) !important;
}

.tw-border-t-neutral-950\/90 {
  border-top-color: rgb(10 10 10 / 0.9) !important;
}

.tw-border-t-neutral-950\/95 {
  border-top-color: rgb(10 10 10 / 0.95) !important;
}

.tw-border-t-orange-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 237 213 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-100\/0 {
  border-top-color: rgb(255 237 213 / 0) !important;
}

.tw-border-t-orange-100\/10 {
  border-top-color: rgb(255 237 213 / 0.1) !important;
}

.tw-border-t-orange-100\/100 {
  border-top-color: rgb(255 237 213 / 1) !important;
}

.tw-border-t-orange-100\/15 {
  border-top-color: rgb(255 237 213 / 0.15) !important;
}

.tw-border-t-orange-100\/20 {
  border-top-color: rgb(255 237 213 / 0.2) !important;
}

.tw-border-t-orange-100\/25 {
  border-top-color: rgb(255 237 213 / 0.25) !important;
}

.tw-border-t-orange-100\/30 {
  border-top-color: rgb(255 237 213 / 0.3) !important;
}

.tw-border-t-orange-100\/35 {
  border-top-color: rgb(255 237 213 / 0.35) !important;
}

.tw-border-t-orange-100\/40 {
  border-top-color: rgb(255 237 213 / 0.4) !important;
}

.tw-border-t-orange-100\/45 {
  border-top-color: rgb(255 237 213 / 0.45) !important;
}

.tw-border-t-orange-100\/5 {
  border-top-color: rgb(255 237 213 / 0.05) !important;
}

.tw-border-t-orange-100\/50 {
  border-top-color: rgb(255 237 213 / 0.5) !important;
}

.tw-border-t-orange-100\/55 {
  border-top-color: rgb(255 237 213 / 0.55) !important;
}

.tw-border-t-orange-100\/60 {
  border-top-color: rgb(255 237 213 / 0.6) !important;
}

.tw-border-t-orange-100\/65 {
  border-top-color: rgb(255 237 213 / 0.65) !important;
}

.tw-border-t-orange-100\/70 {
  border-top-color: rgb(255 237 213 / 0.7) !important;
}

.tw-border-t-orange-100\/75 {
  border-top-color: rgb(255 237 213 / 0.75) !important;
}

.tw-border-t-orange-100\/80 {
  border-top-color: rgb(255 237 213 / 0.8) !important;
}

.tw-border-t-orange-100\/85 {
  border-top-color: rgb(255 237 213 / 0.85) !important;
}

.tw-border-t-orange-100\/90 {
  border-top-color: rgb(255 237 213 / 0.9) !important;
}

.tw-border-t-orange-100\/95 {
  border-top-color: rgb(255 237 213 / 0.95) !important;
}

.tw-border-t-orange-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 215 170 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-200\/0 {
  border-top-color: rgb(254 215 170 / 0) !important;
}

.tw-border-t-orange-200\/10 {
  border-top-color: rgb(254 215 170 / 0.1) !important;
}

.tw-border-t-orange-200\/100 {
  border-top-color: rgb(254 215 170 / 1) !important;
}

.tw-border-t-orange-200\/15 {
  border-top-color: rgb(254 215 170 / 0.15) !important;
}

.tw-border-t-orange-200\/20 {
  border-top-color: rgb(254 215 170 / 0.2) !important;
}

.tw-border-t-orange-200\/25 {
  border-top-color: rgb(254 215 170 / 0.25) !important;
}

.tw-border-t-orange-200\/30 {
  border-top-color: rgb(254 215 170 / 0.3) !important;
}

.tw-border-t-orange-200\/35 {
  border-top-color: rgb(254 215 170 / 0.35) !important;
}

.tw-border-t-orange-200\/40 {
  border-top-color: rgb(254 215 170 / 0.4) !important;
}

.tw-border-t-orange-200\/45 {
  border-top-color: rgb(254 215 170 / 0.45) !important;
}

.tw-border-t-orange-200\/5 {
  border-top-color: rgb(254 215 170 / 0.05) !important;
}

.tw-border-t-orange-200\/50 {
  border-top-color: rgb(254 215 170 / 0.5) !important;
}

.tw-border-t-orange-200\/55 {
  border-top-color: rgb(254 215 170 / 0.55) !important;
}

.tw-border-t-orange-200\/60 {
  border-top-color: rgb(254 215 170 / 0.6) !important;
}

.tw-border-t-orange-200\/65 {
  border-top-color: rgb(254 215 170 / 0.65) !important;
}

.tw-border-t-orange-200\/70 {
  border-top-color: rgb(254 215 170 / 0.7) !important;
}

.tw-border-t-orange-200\/75 {
  border-top-color: rgb(254 215 170 / 0.75) !important;
}

.tw-border-t-orange-200\/80 {
  border-top-color: rgb(254 215 170 / 0.8) !important;
}

.tw-border-t-orange-200\/85 {
  border-top-color: rgb(254 215 170 / 0.85) !important;
}

.tw-border-t-orange-200\/90 {
  border-top-color: rgb(254 215 170 / 0.9) !important;
}

.tw-border-t-orange-200\/95 {
  border-top-color: rgb(254 215 170 / 0.95) !important;
}

.tw-border-t-orange-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 186 116 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-300\/0 {
  border-top-color: rgb(253 186 116 / 0) !important;
}

.tw-border-t-orange-300\/10 {
  border-top-color: rgb(253 186 116 / 0.1) !important;
}

.tw-border-t-orange-300\/100 {
  border-top-color: rgb(253 186 116 / 1) !important;
}

.tw-border-t-orange-300\/15 {
  border-top-color: rgb(253 186 116 / 0.15) !important;
}

.tw-border-t-orange-300\/20 {
  border-top-color: rgb(253 186 116 / 0.2) !important;
}

.tw-border-t-orange-300\/25 {
  border-top-color: rgb(253 186 116 / 0.25) !important;
}

.tw-border-t-orange-300\/30 {
  border-top-color: rgb(253 186 116 / 0.3) !important;
}

.tw-border-t-orange-300\/35 {
  border-top-color: rgb(253 186 116 / 0.35) !important;
}

.tw-border-t-orange-300\/40 {
  border-top-color: rgb(253 186 116 / 0.4) !important;
}

.tw-border-t-orange-300\/45 {
  border-top-color: rgb(253 186 116 / 0.45) !important;
}

.tw-border-t-orange-300\/5 {
  border-top-color: rgb(253 186 116 / 0.05) !important;
}

.tw-border-t-orange-300\/50 {
  border-top-color: rgb(253 186 116 / 0.5) !important;
}

.tw-border-t-orange-300\/55 {
  border-top-color: rgb(253 186 116 / 0.55) !important;
}

.tw-border-t-orange-300\/60 {
  border-top-color: rgb(253 186 116 / 0.6) !important;
}

.tw-border-t-orange-300\/65 {
  border-top-color: rgb(253 186 116 / 0.65) !important;
}

.tw-border-t-orange-300\/70 {
  border-top-color: rgb(253 186 116 / 0.7) !important;
}

.tw-border-t-orange-300\/75 {
  border-top-color: rgb(253 186 116 / 0.75) !important;
}

.tw-border-t-orange-300\/80 {
  border-top-color: rgb(253 186 116 / 0.8) !important;
}

.tw-border-t-orange-300\/85 {
  border-top-color: rgb(253 186 116 / 0.85) !important;
}

.tw-border-t-orange-300\/90 {
  border-top-color: rgb(253 186 116 / 0.9) !important;
}

.tw-border-t-orange-300\/95 {
  border-top-color: rgb(253 186 116 / 0.95) !important;
}

.tw-border-t-orange-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 146 60 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-400\/0 {
  border-top-color: rgb(251 146 60 / 0) !important;
}

.tw-border-t-orange-400\/10 {
  border-top-color: rgb(251 146 60 / 0.1) !important;
}

.tw-border-t-orange-400\/100 {
  border-top-color: rgb(251 146 60 / 1) !important;
}

.tw-border-t-orange-400\/15 {
  border-top-color: rgb(251 146 60 / 0.15) !important;
}

.tw-border-t-orange-400\/20 {
  border-top-color: rgb(251 146 60 / 0.2) !important;
}

.tw-border-t-orange-400\/25 {
  border-top-color: rgb(251 146 60 / 0.25) !important;
}

.tw-border-t-orange-400\/30 {
  border-top-color: rgb(251 146 60 / 0.3) !important;
}

.tw-border-t-orange-400\/35 {
  border-top-color: rgb(251 146 60 / 0.35) !important;
}

.tw-border-t-orange-400\/40 {
  border-top-color: rgb(251 146 60 / 0.4) !important;
}

.tw-border-t-orange-400\/45 {
  border-top-color: rgb(251 146 60 / 0.45) !important;
}

.tw-border-t-orange-400\/5 {
  border-top-color: rgb(251 146 60 / 0.05) !important;
}

.tw-border-t-orange-400\/50 {
  border-top-color: rgb(251 146 60 / 0.5) !important;
}

.tw-border-t-orange-400\/55 {
  border-top-color: rgb(251 146 60 / 0.55) !important;
}

.tw-border-t-orange-400\/60 {
  border-top-color: rgb(251 146 60 / 0.6) !important;
}

.tw-border-t-orange-400\/65 {
  border-top-color: rgb(251 146 60 / 0.65) !important;
}

.tw-border-t-orange-400\/70 {
  border-top-color: rgb(251 146 60 / 0.7) !important;
}

.tw-border-t-orange-400\/75 {
  border-top-color: rgb(251 146 60 / 0.75) !important;
}

.tw-border-t-orange-400\/80 {
  border-top-color: rgb(251 146 60 / 0.8) !important;
}

.tw-border-t-orange-400\/85 {
  border-top-color: rgb(251 146 60 / 0.85) !important;
}

.tw-border-t-orange-400\/90 {
  border-top-color: rgb(251 146 60 / 0.9) !important;
}

.tw-border-t-orange-400\/95 {
  border-top-color: rgb(251 146 60 / 0.95) !important;
}

.tw-border-t-orange-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 247 237 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-50\/0 {
  border-top-color: rgb(255 247 237 / 0) !important;
}

.tw-border-t-orange-50\/10 {
  border-top-color: rgb(255 247 237 / 0.1) !important;
}

.tw-border-t-orange-50\/100 {
  border-top-color: rgb(255 247 237 / 1) !important;
}

.tw-border-t-orange-50\/15 {
  border-top-color: rgb(255 247 237 / 0.15) !important;
}

.tw-border-t-orange-50\/20 {
  border-top-color: rgb(255 247 237 / 0.2) !important;
}

.tw-border-t-orange-50\/25 {
  border-top-color: rgb(255 247 237 / 0.25) !important;
}

.tw-border-t-orange-50\/30 {
  border-top-color: rgb(255 247 237 / 0.3) !important;
}

.tw-border-t-orange-50\/35 {
  border-top-color: rgb(255 247 237 / 0.35) !important;
}

.tw-border-t-orange-50\/40 {
  border-top-color: rgb(255 247 237 / 0.4) !important;
}

.tw-border-t-orange-50\/45 {
  border-top-color: rgb(255 247 237 / 0.45) !important;
}

.tw-border-t-orange-50\/5 {
  border-top-color: rgb(255 247 237 / 0.05) !important;
}

.tw-border-t-orange-50\/50 {
  border-top-color: rgb(255 247 237 / 0.5) !important;
}

.tw-border-t-orange-50\/55 {
  border-top-color: rgb(255 247 237 / 0.55) !important;
}

.tw-border-t-orange-50\/60 {
  border-top-color: rgb(255 247 237 / 0.6) !important;
}

.tw-border-t-orange-50\/65 {
  border-top-color: rgb(255 247 237 / 0.65) !important;
}

.tw-border-t-orange-50\/70 {
  border-top-color: rgb(255 247 237 / 0.7) !important;
}

.tw-border-t-orange-50\/75 {
  border-top-color: rgb(255 247 237 / 0.75) !important;
}

.tw-border-t-orange-50\/80 {
  border-top-color: rgb(255 247 237 / 0.8) !important;
}

.tw-border-t-orange-50\/85 {
  border-top-color: rgb(255 247 237 / 0.85) !important;
}

.tw-border-t-orange-50\/90 {
  border-top-color: rgb(255 247 237 / 0.9) !important;
}

.tw-border-t-orange-50\/95 {
  border-top-color: rgb(255 247 237 / 0.95) !important;
}

.tw-border-t-orange-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(249 115 22 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-500\/0 {
  border-top-color: rgb(249 115 22 / 0) !important;
}

.tw-border-t-orange-500\/10 {
  border-top-color: rgb(249 115 22 / 0.1) !important;
}

.tw-border-t-orange-500\/100 {
  border-top-color: rgb(249 115 22 / 1) !important;
}

.tw-border-t-orange-500\/15 {
  border-top-color: rgb(249 115 22 / 0.15) !important;
}

.tw-border-t-orange-500\/20 {
  border-top-color: rgb(249 115 22 / 0.2) !important;
}

.tw-border-t-orange-500\/25 {
  border-top-color: rgb(249 115 22 / 0.25) !important;
}

.tw-border-t-orange-500\/30 {
  border-top-color: rgb(249 115 22 / 0.3) !important;
}

.tw-border-t-orange-500\/35 {
  border-top-color: rgb(249 115 22 / 0.35) !important;
}

.tw-border-t-orange-500\/40 {
  border-top-color: rgb(249 115 22 / 0.4) !important;
}

.tw-border-t-orange-500\/45 {
  border-top-color: rgb(249 115 22 / 0.45) !important;
}

.tw-border-t-orange-500\/5 {
  border-top-color: rgb(249 115 22 / 0.05) !important;
}

.tw-border-t-orange-500\/50 {
  border-top-color: rgb(249 115 22 / 0.5) !important;
}

.tw-border-t-orange-500\/55 {
  border-top-color: rgb(249 115 22 / 0.55) !important;
}

.tw-border-t-orange-500\/60 {
  border-top-color: rgb(249 115 22 / 0.6) !important;
}

.tw-border-t-orange-500\/65 {
  border-top-color: rgb(249 115 22 / 0.65) !important;
}

.tw-border-t-orange-500\/70 {
  border-top-color: rgb(249 115 22 / 0.7) !important;
}

.tw-border-t-orange-500\/75 {
  border-top-color: rgb(249 115 22 / 0.75) !important;
}

.tw-border-t-orange-500\/80 {
  border-top-color: rgb(249 115 22 / 0.8) !important;
}

.tw-border-t-orange-500\/85 {
  border-top-color: rgb(249 115 22 / 0.85) !important;
}

.tw-border-t-orange-500\/90 {
  border-top-color: rgb(249 115 22 / 0.9) !important;
}

.tw-border-t-orange-500\/95 {
  border-top-color: rgb(249 115 22 / 0.95) !important;
}

.tw-border-t-orange-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(234 88 12 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-600\/0 {
  border-top-color: rgb(234 88 12 / 0) !important;
}

.tw-border-t-orange-600\/10 {
  border-top-color: rgb(234 88 12 / 0.1) !important;
}

.tw-border-t-orange-600\/100 {
  border-top-color: rgb(234 88 12 / 1) !important;
}

.tw-border-t-orange-600\/15 {
  border-top-color: rgb(234 88 12 / 0.15) !important;
}

.tw-border-t-orange-600\/20 {
  border-top-color: rgb(234 88 12 / 0.2) !important;
}

.tw-border-t-orange-600\/25 {
  border-top-color: rgb(234 88 12 / 0.25) !important;
}

.tw-border-t-orange-600\/30 {
  border-top-color: rgb(234 88 12 / 0.3) !important;
}

.tw-border-t-orange-600\/35 {
  border-top-color: rgb(234 88 12 / 0.35) !important;
}

.tw-border-t-orange-600\/40 {
  border-top-color: rgb(234 88 12 / 0.4) !important;
}

.tw-border-t-orange-600\/45 {
  border-top-color: rgb(234 88 12 / 0.45) !important;
}

.tw-border-t-orange-600\/5 {
  border-top-color: rgb(234 88 12 / 0.05) !important;
}

.tw-border-t-orange-600\/50 {
  border-top-color: rgb(234 88 12 / 0.5) !important;
}

.tw-border-t-orange-600\/55 {
  border-top-color: rgb(234 88 12 / 0.55) !important;
}

.tw-border-t-orange-600\/60 {
  border-top-color: rgb(234 88 12 / 0.6) !important;
}

.tw-border-t-orange-600\/65 {
  border-top-color: rgb(234 88 12 / 0.65) !important;
}

.tw-border-t-orange-600\/70 {
  border-top-color: rgb(234 88 12 / 0.7) !important;
}

.tw-border-t-orange-600\/75 {
  border-top-color: rgb(234 88 12 / 0.75) !important;
}

.tw-border-t-orange-600\/80 {
  border-top-color: rgb(234 88 12 / 0.8) !important;
}

.tw-border-t-orange-600\/85 {
  border-top-color: rgb(234 88 12 / 0.85) !important;
}

.tw-border-t-orange-600\/90 {
  border-top-color: rgb(234 88 12 / 0.9) !important;
}

.tw-border-t-orange-600\/95 {
  border-top-color: rgb(234 88 12 / 0.95) !important;
}

.tw-border-t-orange-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(194 65 12 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-700\/0 {
  border-top-color: rgb(194 65 12 / 0) !important;
}

.tw-border-t-orange-700\/10 {
  border-top-color: rgb(194 65 12 / 0.1) !important;
}

.tw-border-t-orange-700\/100 {
  border-top-color: rgb(194 65 12 / 1) !important;
}

.tw-border-t-orange-700\/15 {
  border-top-color: rgb(194 65 12 / 0.15) !important;
}

.tw-border-t-orange-700\/20 {
  border-top-color: rgb(194 65 12 / 0.2) !important;
}

.tw-border-t-orange-700\/25 {
  border-top-color: rgb(194 65 12 / 0.25) !important;
}

.tw-border-t-orange-700\/30 {
  border-top-color: rgb(194 65 12 / 0.3) !important;
}

.tw-border-t-orange-700\/35 {
  border-top-color: rgb(194 65 12 / 0.35) !important;
}

.tw-border-t-orange-700\/40 {
  border-top-color: rgb(194 65 12 / 0.4) !important;
}

.tw-border-t-orange-700\/45 {
  border-top-color: rgb(194 65 12 / 0.45) !important;
}

.tw-border-t-orange-700\/5 {
  border-top-color: rgb(194 65 12 / 0.05) !important;
}

.tw-border-t-orange-700\/50 {
  border-top-color: rgb(194 65 12 / 0.5) !important;
}

.tw-border-t-orange-700\/55 {
  border-top-color: rgb(194 65 12 / 0.55) !important;
}

.tw-border-t-orange-700\/60 {
  border-top-color: rgb(194 65 12 / 0.6) !important;
}

.tw-border-t-orange-700\/65 {
  border-top-color: rgb(194 65 12 / 0.65) !important;
}

.tw-border-t-orange-700\/70 {
  border-top-color: rgb(194 65 12 / 0.7) !important;
}

.tw-border-t-orange-700\/75 {
  border-top-color: rgb(194 65 12 / 0.75) !important;
}

.tw-border-t-orange-700\/80 {
  border-top-color: rgb(194 65 12 / 0.8) !important;
}

.tw-border-t-orange-700\/85 {
  border-top-color: rgb(194 65 12 / 0.85) !important;
}

.tw-border-t-orange-700\/90 {
  border-top-color: rgb(194 65 12 / 0.9) !important;
}

.tw-border-t-orange-700\/95 {
  border-top-color: rgb(194 65 12 / 0.95) !important;
}

.tw-border-t-orange-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(154 52 18 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-800\/0 {
  border-top-color: rgb(154 52 18 / 0) !important;
}

.tw-border-t-orange-800\/10 {
  border-top-color: rgb(154 52 18 / 0.1) !important;
}

.tw-border-t-orange-800\/100 {
  border-top-color: rgb(154 52 18 / 1) !important;
}

.tw-border-t-orange-800\/15 {
  border-top-color: rgb(154 52 18 / 0.15) !important;
}

.tw-border-t-orange-800\/20 {
  border-top-color: rgb(154 52 18 / 0.2) !important;
}

.tw-border-t-orange-800\/25 {
  border-top-color: rgb(154 52 18 / 0.25) !important;
}

.tw-border-t-orange-800\/30 {
  border-top-color: rgb(154 52 18 / 0.3) !important;
}

.tw-border-t-orange-800\/35 {
  border-top-color: rgb(154 52 18 / 0.35) !important;
}

.tw-border-t-orange-800\/40 {
  border-top-color: rgb(154 52 18 / 0.4) !important;
}

.tw-border-t-orange-800\/45 {
  border-top-color: rgb(154 52 18 / 0.45) !important;
}

.tw-border-t-orange-800\/5 {
  border-top-color: rgb(154 52 18 / 0.05) !important;
}

.tw-border-t-orange-800\/50 {
  border-top-color: rgb(154 52 18 / 0.5) !important;
}

.tw-border-t-orange-800\/55 {
  border-top-color: rgb(154 52 18 / 0.55) !important;
}

.tw-border-t-orange-800\/60 {
  border-top-color: rgb(154 52 18 / 0.6) !important;
}

.tw-border-t-orange-800\/65 {
  border-top-color: rgb(154 52 18 / 0.65) !important;
}

.tw-border-t-orange-800\/70 {
  border-top-color: rgb(154 52 18 / 0.7) !important;
}

.tw-border-t-orange-800\/75 {
  border-top-color: rgb(154 52 18 / 0.75) !important;
}

.tw-border-t-orange-800\/80 {
  border-top-color: rgb(154 52 18 / 0.8) !important;
}

.tw-border-t-orange-800\/85 {
  border-top-color: rgb(154 52 18 / 0.85) !important;
}

.tw-border-t-orange-800\/90 {
  border-top-color: rgb(154 52 18 / 0.9) !important;
}

.tw-border-t-orange-800\/95 {
  border-top-color: rgb(154 52 18 / 0.95) !important;
}

.tw-border-t-orange-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(124 45 18 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-900\/0 {
  border-top-color: rgb(124 45 18 / 0) !important;
}

.tw-border-t-orange-900\/10 {
  border-top-color: rgb(124 45 18 / 0.1) !important;
}

.tw-border-t-orange-900\/100 {
  border-top-color: rgb(124 45 18 / 1) !important;
}

.tw-border-t-orange-900\/15 {
  border-top-color: rgb(124 45 18 / 0.15) !important;
}

.tw-border-t-orange-900\/20 {
  border-top-color: rgb(124 45 18 / 0.2) !important;
}

.tw-border-t-orange-900\/25 {
  border-top-color: rgb(124 45 18 / 0.25) !important;
}

.tw-border-t-orange-900\/30 {
  border-top-color: rgb(124 45 18 / 0.3) !important;
}

.tw-border-t-orange-900\/35 {
  border-top-color: rgb(124 45 18 / 0.35) !important;
}

.tw-border-t-orange-900\/40 {
  border-top-color: rgb(124 45 18 / 0.4) !important;
}

.tw-border-t-orange-900\/45 {
  border-top-color: rgb(124 45 18 / 0.45) !important;
}

.tw-border-t-orange-900\/5 {
  border-top-color: rgb(124 45 18 / 0.05) !important;
}

.tw-border-t-orange-900\/50 {
  border-top-color: rgb(124 45 18 / 0.5) !important;
}

.tw-border-t-orange-900\/55 {
  border-top-color: rgb(124 45 18 / 0.55) !important;
}

.tw-border-t-orange-900\/60 {
  border-top-color: rgb(124 45 18 / 0.6) !important;
}

.tw-border-t-orange-900\/65 {
  border-top-color: rgb(124 45 18 / 0.65) !important;
}

.tw-border-t-orange-900\/70 {
  border-top-color: rgb(124 45 18 / 0.7) !important;
}

.tw-border-t-orange-900\/75 {
  border-top-color: rgb(124 45 18 / 0.75) !important;
}

.tw-border-t-orange-900\/80 {
  border-top-color: rgb(124 45 18 / 0.8) !important;
}

.tw-border-t-orange-900\/85 {
  border-top-color: rgb(124 45 18 / 0.85) !important;
}

.tw-border-t-orange-900\/90 {
  border-top-color: rgb(124 45 18 / 0.9) !important;
}

.tw-border-t-orange-900\/95 {
  border-top-color: rgb(124 45 18 / 0.95) !important;
}

.tw-border-t-orange-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(67 20 7 / var(--tw-border-opacity)) !important;
}

.tw-border-t-orange-950\/0 {
  border-top-color: rgb(67 20 7 / 0) !important;
}

.tw-border-t-orange-950\/10 {
  border-top-color: rgb(67 20 7 / 0.1) !important;
}

.tw-border-t-orange-950\/100 {
  border-top-color: rgb(67 20 7 / 1) !important;
}

.tw-border-t-orange-950\/15 {
  border-top-color: rgb(67 20 7 / 0.15) !important;
}

.tw-border-t-orange-950\/20 {
  border-top-color: rgb(67 20 7 / 0.2) !important;
}

.tw-border-t-orange-950\/25 {
  border-top-color: rgb(67 20 7 / 0.25) !important;
}

.tw-border-t-orange-950\/30 {
  border-top-color: rgb(67 20 7 / 0.3) !important;
}

.tw-border-t-orange-950\/35 {
  border-top-color: rgb(67 20 7 / 0.35) !important;
}

.tw-border-t-orange-950\/40 {
  border-top-color: rgb(67 20 7 / 0.4) !important;
}

.tw-border-t-orange-950\/45 {
  border-top-color: rgb(67 20 7 / 0.45) !important;
}

.tw-border-t-orange-950\/5 {
  border-top-color: rgb(67 20 7 / 0.05) !important;
}

.tw-border-t-orange-950\/50 {
  border-top-color: rgb(67 20 7 / 0.5) !important;
}

.tw-border-t-orange-950\/55 {
  border-top-color: rgb(67 20 7 / 0.55) !important;
}

.tw-border-t-orange-950\/60 {
  border-top-color: rgb(67 20 7 / 0.6) !important;
}

.tw-border-t-orange-950\/65 {
  border-top-color: rgb(67 20 7 / 0.65) !important;
}

.tw-border-t-orange-950\/70 {
  border-top-color: rgb(67 20 7 / 0.7) !important;
}

.tw-border-t-orange-950\/75 {
  border-top-color: rgb(67 20 7 / 0.75) !important;
}

.tw-border-t-orange-950\/80 {
  border-top-color: rgb(67 20 7 / 0.8) !important;
}

.tw-border-t-orange-950\/85 {
  border-top-color: rgb(67 20 7 / 0.85) !important;
}

.tw-border-t-orange-950\/90 {
  border-top-color: rgb(67 20 7 / 0.9) !important;
}

.tw-border-t-orange-950\/95 {
  border-top-color: rgb(67 20 7 / 0.95) !important;
}

.tw-border-t-pink-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(252 231 243 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-100\/0 {
  border-top-color: rgb(252 231 243 / 0) !important;
}

.tw-border-t-pink-100\/10 {
  border-top-color: rgb(252 231 243 / 0.1) !important;
}

.tw-border-t-pink-100\/100 {
  border-top-color: rgb(252 231 243 / 1) !important;
}

.tw-border-t-pink-100\/15 {
  border-top-color: rgb(252 231 243 / 0.15) !important;
}

.tw-border-t-pink-100\/20 {
  border-top-color: rgb(252 231 243 / 0.2) !important;
}

.tw-border-t-pink-100\/25 {
  border-top-color: rgb(252 231 243 / 0.25) !important;
}

.tw-border-t-pink-100\/30 {
  border-top-color: rgb(252 231 243 / 0.3) !important;
}

.tw-border-t-pink-100\/35 {
  border-top-color: rgb(252 231 243 / 0.35) !important;
}

.tw-border-t-pink-100\/40 {
  border-top-color: rgb(252 231 243 / 0.4) !important;
}

.tw-border-t-pink-100\/45 {
  border-top-color: rgb(252 231 243 / 0.45) !important;
}

.tw-border-t-pink-100\/5 {
  border-top-color: rgb(252 231 243 / 0.05) !important;
}

.tw-border-t-pink-100\/50 {
  border-top-color: rgb(252 231 243 / 0.5) !important;
}

.tw-border-t-pink-100\/55 {
  border-top-color: rgb(252 231 243 / 0.55) !important;
}

.tw-border-t-pink-100\/60 {
  border-top-color: rgb(252 231 243 / 0.6) !important;
}

.tw-border-t-pink-100\/65 {
  border-top-color: rgb(252 231 243 / 0.65) !important;
}

.tw-border-t-pink-100\/70 {
  border-top-color: rgb(252 231 243 / 0.7) !important;
}

.tw-border-t-pink-100\/75 {
  border-top-color: rgb(252 231 243 / 0.75) !important;
}

.tw-border-t-pink-100\/80 {
  border-top-color: rgb(252 231 243 / 0.8) !important;
}

.tw-border-t-pink-100\/85 {
  border-top-color: rgb(252 231 243 / 0.85) !important;
}

.tw-border-t-pink-100\/90 {
  border-top-color: rgb(252 231 243 / 0.9) !important;
}

.tw-border-t-pink-100\/95 {
  border-top-color: rgb(252 231 243 / 0.95) !important;
}

.tw-border-t-pink-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 207 232 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-200\/0 {
  border-top-color: rgb(251 207 232 / 0) !important;
}

.tw-border-t-pink-200\/10 {
  border-top-color: rgb(251 207 232 / 0.1) !important;
}

.tw-border-t-pink-200\/100 {
  border-top-color: rgb(251 207 232 / 1) !important;
}

.tw-border-t-pink-200\/15 {
  border-top-color: rgb(251 207 232 / 0.15) !important;
}

.tw-border-t-pink-200\/20 {
  border-top-color: rgb(251 207 232 / 0.2) !important;
}

.tw-border-t-pink-200\/25 {
  border-top-color: rgb(251 207 232 / 0.25) !important;
}

.tw-border-t-pink-200\/30 {
  border-top-color: rgb(251 207 232 / 0.3) !important;
}

.tw-border-t-pink-200\/35 {
  border-top-color: rgb(251 207 232 / 0.35) !important;
}

.tw-border-t-pink-200\/40 {
  border-top-color: rgb(251 207 232 / 0.4) !important;
}

.tw-border-t-pink-200\/45 {
  border-top-color: rgb(251 207 232 / 0.45) !important;
}

.tw-border-t-pink-200\/5 {
  border-top-color: rgb(251 207 232 / 0.05) !important;
}

.tw-border-t-pink-200\/50 {
  border-top-color: rgb(251 207 232 / 0.5) !important;
}

.tw-border-t-pink-200\/55 {
  border-top-color: rgb(251 207 232 / 0.55) !important;
}

.tw-border-t-pink-200\/60 {
  border-top-color: rgb(251 207 232 / 0.6) !important;
}

.tw-border-t-pink-200\/65 {
  border-top-color: rgb(251 207 232 / 0.65) !important;
}

.tw-border-t-pink-200\/70 {
  border-top-color: rgb(251 207 232 / 0.7) !important;
}

.tw-border-t-pink-200\/75 {
  border-top-color: rgb(251 207 232 / 0.75) !important;
}

.tw-border-t-pink-200\/80 {
  border-top-color: rgb(251 207 232 / 0.8) !important;
}

.tw-border-t-pink-200\/85 {
  border-top-color: rgb(251 207 232 / 0.85) !important;
}

.tw-border-t-pink-200\/90 {
  border-top-color: rgb(251 207 232 / 0.9) !important;
}

.tw-border-t-pink-200\/95 {
  border-top-color: rgb(251 207 232 / 0.95) !important;
}

.tw-border-t-pink-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(249 168 212 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-300\/0 {
  border-top-color: rgb(249 168 212 / 0) !important;
}

.tw-border-t-pink-300\/10 {
  border-top-color: rgb(249 168 212 / 0.1) !important;
}

.tw-border-t-pink-300\/100 {
  border-top-color: rgb(249 168 212 / 1) !important;
}

.tw-border-t-pink-300\/15 {
  border-top-color: rgb(249 168 212 / 0.15) !important;
}

.tw-border-t-pink-300\/20 {
  border-top-color: rgb(249 168 212 / 0.2) !important;
}

.tw-border-t-pink-300\/25 {
  border-top-color: rgb(249 168 212 / 0.25) !important;
}

.tw-border-t-pink-300\/30 {
  border-top-color: rgb(249 168 212 / 0.3) !important;
}

.tw-border-t-pink-300\/35 {
  border-top-color: rgb(249 168 212 / 0.35) !important;
}

.tw-border-t-pink-300\/40 {
  border-top-color: rgb(249 168 212 / 0.4) !important;
}

.tw-border-t-pink-300\/45 {
  border-top-color: rgb(249 168 212 / 0.45) !important;
}

.tw-border-t-pink-300\/5 {
  border-top-color: rgb(249 168 212 / 0.05) !important;
}

.tw-border-t-pink-300\/50 {
  border-top-color: rgb(249 168 212 / 0.5) !important;
}

.tw-border-t-pink-300\/55 {
  border-top-color: rgb(249 168 212 / 0.55) !important;
}

.tw-border-t-pink-300\/60 {
  border-top-color: rgb(249 168 212 / 0.6) !important;
}

.tw-border-t-pink-300\/65 {
  border-top-color: rgb(249 168 212 / 0.65) !important;
}

.tw-border-t-pink-300\/70 {
  border-top-color: rgb(249 168 212 / 0.7) !important;
}

.tw-border-t-pink-300\/75 {
  border-top-color: rgb(249 168 212 / 0.75) !important;
}

.tw-border-t-pink-300\/80 {
  border-top-color: rgb(249 168 212 / 0.8) !important;
}

.tw-border-t-pink-300\/85 {
  border-top-color: rgb(249 168 212 / 0.85) !important;
}

.tw-border-t-pink-300\/90 {
  border-top-color: rgb(249 168 212 / 0.9) !important;
}

.tw-border-t-pink-300\/95 {
  border-top-color: rgb(249 168 212 / 0.95) !important;
}

.tw-border-t-pink-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(244 114 182 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-400\/0 {
  border-top-color: rgb(244 114 182 / 0) !important;
}

.tw-border-t-pink-400\/10 {
  border-top-color: rgb(244 114 182 / 0.1) !important;
}

.tw-border-t-pink-400\/100 {
  border-top-color: rgb(244 114 182 / 1) !important;
}

.tw-border-t-pink-400\/15 {
  border-top-color: rgb(244 114 182 / 0.15) !important;
}

.tw-border-t-pink-400\/20 {
  border-top-color: rgb(244 114 182 / 0.2) !important;
}

.tw-border-t-pink-400\/25 {
  border-top-color: rgb(244 114 182 / 0.25) !important;
}

.tw-border-t-pink-400\/30 {
  border-top-color: rgb(244 114 182 / 0.3) !important;
}

.tw-border-t-pink-400\/35 {
  border-top-color: rgb(244 114 182 / 0.35) !important;
}

.tw-border-t-pink-400\/40 {
  border-top-color: rgb(244 114 182 / 0.4) !important;
}

.tw-border-t-pink-400\/45 {
  border-top-color: rgb(244 114 182 / 0.45) !important;
}

.tw-border-t-pink-400\/5 {
  border-top-color: rgb(244 114 182 / 0.05) !important;
}

.tw-border-t-pink-400\/50 {
  border-top-color: rgb(244 114 182 / 0.5) !important;
}

.tw-border-t-pink-400\/55 {
  border-top-color: rgb(244 114 182 / 0.55) !important;
}

.tw-border-t-pink-400\/60 {
  border-top-color: rgb(244 114 182 / 0.6) !important;
}

.tw-border-t-pink-400\/65 {
  border-top-color: rgb(244 114 182 / 0.65) !important;
}

.tw-border-t-pink-400\/70 {
  border-top-color: rgb(244 114 182 / 0.7) !important;
}

.tw-border-t-pink-400\/75 {
  border-top-color: rgb(244 114 182 / 0.75) !important;
}

.tw-border-t-pink-400\/80 {
  border-top-color: rgb(244 114 182 / 0.8) !important;
}

.tw-border-t-pink-400\/85 {
  border-top-color: rgb(244 114 182 / 0.85) !important;
}

.tw-border-t-pink-400\/90 {
  border-top-color: rgb(244 114 182 / 0.9) !important;
}

.tw-border-t-pink-400\/95 {
  border-top-color: rgb(244 114 182 / 0.95) !important;
}

.tw-border-t-pink-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 242 248 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-50\/0 {
  border-top-color: rgb(253 242 248 / 0) !important;
}

.tw-border-t-pink-50\/10 {
  border-top-color: rgb(253 242 248 / 0.1) !important;
}

.tw-border-t-pink-50\/100 {
  border-top-color: rgb(253 242 248 / 1) !important;
}

.tw-border-t-pink-50\/15 {
  border-top-color: rgb(253 242 248 / 0.15) !important;
}

.tw-border-t-pink-50\/20 {
  border-top-color: rgb(253 242 248 / 0.2) !important;
}

.tw-border-t-pink-50\/25 {
  border-top-color: rgb(253 242 248 / 0.25) !important;
}

.tw-border-t-pink-50\/30 {
  border-top-color: rgb(253 242 248 / 0.3) !important;
}

.tw-border-t-pink-50\/35 {
  border-top-color: rgb(253 242 248 / 0.35) !important;
}

.tw-border-t-pink-50\/40 {
  border-top-color: rgb(253 242 248 / 0.4) !important;
}

.tw-border-t-pink-50\/45 {
  border-top-color: rgb(253 242 248 / 0.45) !important;
}

.tw-border-t-pink-50\/5 {
  border-top-color: rgb(253 242 248 / 0.05) !important;
}

.tw-border-t-pink-50\/50 {
  border-top-color: rgb(253 242 248 / 0.5) !important;
}

.tw-border-t-pink-50\/55 {
  border-top-color: rgb(253 242 248 / 0.55) !important;
}

.tw-border-t-pink-50\/60 {
  border-top-color: rgb(253 242 248 / 0.6) !important;
}

.tw-border-t-pink-50\/65 {
  border-top-color: rgb(253 242 248 / 0.65) !important;
}

.tw-border-t-pink-50\/70 {
  border-top-color: rgb(253 242 248 / 0.7) !important;
}

.tw-border-t-pink-50\/75 {
  border-top-color: rgb(253 242 248 / 0.75) !important;
}

.tw-border-t-pink-50\/80 {
  border-top-color: rgb(253 242 248 / 0.8) !important;
}

.tw-border-t-pink-50\/85 {
  border-top-color: rgb(253 242 248 / 0.85) !important;
}

.tw-border-t-pink-50\/90 {
  border-top-color: rgb(253 242 248 / 0.9) !important;
}

.tw-border-t-pink-50\/95 {
  border-top-color: rgb(253 242 248 / 0.95) !important;
}

.tw-border-t-pink-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(236 72 153 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-500\/0 {
  border-top-color: rgb(236 72 153 / 0) !important;
}

.tw-border-t-pink-500\/10 {
  border-top-color: rgb(236 72 153 / 0.1) !important;
}

.tw-border-t-pink-500\/100 {
  border-top-color: rgb(236 72 153 / 1) !important;
}

.tw-border-t-pink-500\/15 {
  border-top-color: rgb(236 72 153 / 0.15) !important;
}

.tw-border-t-pink-500\/20 {
  border-top-color: rgb(236 72 153 / 0.2) !important;
}

.tw-border-t-pink-500\/25 {
  border-top-color: rgb(236 72 153 / 0.25) !important;
}

.tw-border-t-pink-500\/30 {
  border-top-color: rgb(236 72 153 / 0.3) !important;
}

.tw-border-t-pink-500\/35 {
  border-top-color: rgb(236 72 153 / 0.35) !important;
}

.tw-border-t-pink-500\/40 {
  border-top-color: rgb(236 72 153 / 0.4) !important;
}

.tw-border-t-pink-500\/45 {
  border-top-color: rgb(236 72 153 / 0.45) !important;
}

.tw-border-t-pink-500\/5 {
  border-top-color: rgb(236 72 153 / 0.05) !important;
}

.tw-border-t-pink-500\/50 {
  border-top-color: rgb(236 72 153 / 0.5) !important;
}

.tw-border-t-pink-500\/55 {
  border-top-color: rgb(236 72 153 / 0.55) !important;
}

.tw-border-t-pink-500\/60 {
  border-top-color: rgb(236 72 153 / 0.6) !important;
}

.tw-border-t-pink-500\/65 {
  border-top-color: rgb(236 72 153 / 0.65) !important;
}

.tw-border-t-pink-500\/70 {
  border-top-color: rgb(236 72 153 / 0.7) !important;
}

.tw-border-t-pink-500\/75 {
  border-top-color: rgb(236 72 153 / 0.75) !important;
}

.tw-border-t-pink-500\/80 {
  border-top-color: rgb(236 72 153 / 0.8) !important;
}

.tw-border-t-pink-500\/85 {
  border-top-color: rgb(236 72 153 / 0.85) !important;
}

.tw-border-t-pink-500\/90 {
  border-top-color: rgb(236 72 153 / 0.9) !important;
}

.tw-border-t-pink-500\/95 {
  border-top-color: rgb(236 72 153 / 0.95) !important;
}

.tw-border-t-pink-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(219 39 119 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-600\/0 {
  border-top-color: rgb(219 39 119 / 0) !important;
}

.tw-border-t-pink-600\/10 {
  border-top-color: rgb(219 39 119 / 0.1) !important;
}

.tw-border-t-pink-600\/100 {
  border-top-color: rgb(219 39 119 / 1) !important;
}

.tw-border-t-pink-600\/15 {
  border-top-color: rgb(219 39 119 / 0.15) !important;
}

.tw-border-t-pink-600\/20 {
  border-top-color: rgb(219 39 119 / 0.2) !important;
}

.tw-border-t-pink-600\/25 {
  border-top-color: rgb(219 39 119 / 0.25) !important;
}

.tw-border-t-pink-600\/30 {
  border-top-color: rgb(219 39 119 / 0.3) !important;
}

.tw-border-t-pink-600\/35 {
  border-top-color: rgb(219 39 119 / 0.35) !important;
}

.tw-border-t-pink-600\/40 {
  border-top-color: rgb(219 39 119 / 0.4) !important;
}

.tw-border-t-pink-600\/45 {
  border-top-color: rgb(219 39 119 / 0.45) !important;
}

.tw-border-t-pink-600\/5 {
  border-top-color: rgb(219 39 119 / 0.05) !important;
}

.tw-border-t-pink-600\/50 {
  border-top-color: rgb(219 39 119 / 0.5) !important;
}

.tw-border-t-pink-600\/55 {
  border-top-color: rgb(219 39 119 / 0.55) !important;
}

.tw-border-t-pink-600\/60 {
  border-top-color: rgb(219 39 119 / 0.6) !important;
}

.tw-border-t-pink-600\/65 {
  border-top-color: rgb(219 39 119 / 0.65) !important;
}

.tw-border-t-pink-600\/70 {
  border-top-color: rgb(219 39 119 / 0.7) !important;
}

.tw-border-t-pink-600\/75 {
  border-top-color: rgb(219 39 119 / 0.75) !important;
}

.tw-border-t-pink-600\/80 {
  border-top-color: rgb(219 39 119 / 0.8) !important;
}

.tw-border-t-pink-600\/85 {
  border-top-color: rgb(219 39 119 / 0.85) !important;
}

.tw-border-t-pink-600\/90 {
  border-top-color: rgb(219 39 119 / 0.9) !important;
}

.tw-border-t-pink-600\/95 {
  border-top-color: rgb(219 39 119 / 0.95) !important;
}

.tw-border-t-pink-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(190 24 93 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-700\/0 {
  border-top-color: rgb(190 24 93 / 0) !important;
}

.tw-border-t-pink-700\/10 {
  border-top-color: rgb(190 24 93 / 0.1) !important;
}

.tw-border-t-pink-700\/100 {
  border-top-color: rgb(190 24 93 / 1) !important;
}

.tw-border-t-pink-700\/15 {
  border-top-color: rgb(190 24 93 / 0.15) !important;
}

.tw-border-t-pink-700\/20 {
  border-top-color: rgb(190 24 93 / 0.2) !important;
}

.tw-border-t-pink-700\/25 {
  border-top-color: rgb(190 24 93 / 0.25) !important;
}

.tw-border-t-pink-700\/30 {
  border-top-color: rgb(190 24 93 / 0.3) !important;
}

.tw-border-t-pink-700\/35 {
  border-top-color: rgb(190 24 93 / 0.35) !important;
}

.tw-border-t-pink-700\/40 {
  border-top-color: rgb(190 24 93 / 0.4) !important;
}

.tw-border-t-pink-700\/45 {
  border-top-color: rgb(190 24 93 / 0.45) !important;
}

.tw-border-t-pink-700\/5 {
  border-top-color: rgb(190 24 93 / 0.05) !important;
}

.tw-border-t-pink-700\/50 {
  border-top-color: rgb(190 24 93 / 0.5) !important;
}

.tw-border-t-pink-700\/55 {
  border-top-color: rgb(190 24 93 / 0.55) !important;
}

.tw-border-t-pink-700\/60 {
  border-top-color: rgb(190 24 93 / 0.6) !important;
}

.tw-border-t-pink-700\/65 {
  border-top-color: rgb(190 24 93 / 0.65) !important;
}

.tw-border-t-pink-700\/70 {
  border-top-color: rgb(190 24 93 / 0.7) !important;
}

.tw-border-t-pink-700\/75 {
  border-top-color: rgb(190 24 93 / 0.75) !important;
}

.tw-border-t-pink-700\/80 {
  border-top-color: rgb(190 24 93 / 0.8) !important;
}

.tw-border-t-pink-700\/85 {
  border-top-color: rgb(190 24 93 / 0.85) !important;
}

.tw-border-t-pink-700\/90 {
  border-top-color: rgb(190 24 93 / 0.9) !important;
}

.tw-border-t-pink-700\/95 {
  border-top-color: rgb(190 24 93 / 0.95) !important;
}

.tw-border-t-pink-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(157 23 77 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-800\/0 {
  border-top-color: rgb(157 23 77 / 0) !important;
}

.tw-border-t-pink-800\/10 {
  border-top-color: rgb(157 23 77 / 0.1) !important;
}

.tw-border-t-pink-800\/100 {
  border-top-color: rgb(157 23 77 / 1) !important;
}

.tw-border-t-pink-800\/15 {
  border-top-color: rgb(157 23 77 / 0.15) !important;
}

.tw-border-t-pink-800\/20 {
  border-top-color: rgb(157 23 77 / 0.2) !important;
}

.tw-border-t-pink-800\/25 {
  border-top-color: rgb(157 23 77 / 0.25) !important;
}

.tw-border-t-pink-800\/30 {
  border-top-color: rgb(157 23 77 / 0.3) !important;
}

.tw-border-t-pink-800\/35 {
  border-top-color: rgb(157 23 77 / 0.35) !important;
}

.tw-border-t-pink-800\/40 {
  border-top-color: rgb(157 23 77 / 0.4) !important;
}

.tw-border-t-pink-800\/45 {
  border-top-color: rgb(157 23 77 / 0.45) !important;
}

.tw-border-t-pink-800\/5 {
  border-top-color: rgb(157 23 77 / 0.05) !important;
}

.tw-border-t-pink-800\/50 {
  border-top-color: rgb(157 23 77 / 0.5) !important;
}

.tw-border-t-pink-800\/55 {
  border-top-color: rgb(157 23 77 / 0.55) !important;
}

.tw-border-t-pink-800\/60 {
  border-top-color: rgb(157 23 77 / 0.6) !important;
}

.tw-border-t-pink-800\/65 {
  border-top-color: rgb(157 23 77 / 0.65) !important;
}

.tw-border-t-pink-800\/70 {
  border-top-color: rgb(157 23 77 / 0.7) !important;
}

.tw-border-t-pink-800\/75 {
  border-top-color: rgb(157 23 77 / 0.75) !important;
}

.tw-border-t-pink-800\/80 {
  border-top-color: rgb(157 23 77 / 0.8) !important;
}

.tw-border-t-pink-800\/85 {
  border-top-color: rgb(157 23 77 / 0.85) !important;
}

.tw-border-t-pink-800\/90 {
  border-top-color: rgb(157 23 77 / 0.9) !important;
}

.tw-border-t-pink-800\/95 {
  border-top-color: rgb(157 23 77 / 0.95) !important;
}

.tw-border-t-pink-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(131 24 67 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-900\/0 {
  border-top-color: rgb(131 24 67 / 0) !important;
}

.tw-border-t-pink-900\/10 {
  border-top-color: rgb(131 24 67 / 0.1) !important;
}

.tw-border-t-pink-900\/100 {
  border-top-color: rgb(131 24 67 / 1) !important;
}

.tw-border-t-pink-900\/15 {
  border-top-color: rgb(131 24 67 / 0.15) !important;
}

.tw-border-t-pink-900\/20 {
  border-top-color: rgb(131 24 67 / 0.2) !important;
}

.tw-border-t-pink-900\/25 {
  border-top-color: rgb(131 24 67 / 0.25) !important;
}

.tw-border-t-pink-900\/30 {
  border-top-color: rgb(131 24 67 / 0.3) !important;
}

.tw-border-t-pink-900\/35 {
  border-top-color: rgb(131 24 67 / 0.35) !important;
}

.tw-border-t-pink-900\/40 {
  border-top-color: rgb(131 24 67 / 0.4) !important;
}

.tw-border-t-pink-900\/45 {
  border-top-color: rgb(131 24 67 / 0.45) !important;
}

.tw-border-t-pink-900\/5 {
  border-top-color: rgb(131 24 67 / 0.05) !important;
}

.tw-border-t-pink-900\/50 {
  border-top-color: rgb(131 24 67 / 0.5) !important;
}

.tw-border-t-pink-900\/55 {
  border-top-color: rgb(131 24 67 / 0.55) !important;
}

.tw-border-t-pink-900\/60 {
  border-top-color: rgb(131 24 67 / 0.6) !important;
}

.tw-border-t-pink-900\/65 {
  border-top-color: rgb(131 24 67 / 0.65) !important;
}

.tw-border-t-pink-900\/70 {
  border-top-color: rgb(131 24 67 / 0.7) !important;
}

.tw-border-t-pink-900\/75 {
  border-top-color: rgb(131 24 67 / 0.75) !important;
}

.tw-border-t-pink-900\/80 {
  border-top-color: rgb(131 24 67 / 0.8) !important;
}

.tw-border-t-pink-900\/85 {
  border-top-color: rgb(131 24 67 / 0.85) !important;
}

.tw-border-t-pink-900\/90 {
  border-top-color: rgb(131 24 67 / 0.9) !important;
}

.tw-border-t-pink-900\/95 {
  border-top-color: rgb(131 24 67 / 0.95) !important;
}

.tw-border-t-pink-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(80 7 36 / var(--tw-border-opacity)) !important;
}

.tw-border-t-pink-950\/0 {
  border-top-color: rgb(80 7 36 / 0) !important;
}

.tw-border-t-pink-950\/10 {
  border-top-color: rgb(80 7 36 / 0.1) !important;
}

.tw-border-t-pink-950\/100 {
  border-top-color: rgb(80 7 36 / 1) !important;
}

.tw-border-t-pink-950\/15 {
  border-top-color: rgb(80 7 36 / 0.15) !important;
}

.tw-border-t-pink-950\/20 {
  border-top-color: rgb(80 7 36 / 0.2) !important;
}

.tw-border-t-pink-950\/25 {
  border-top-color: rgb(80 7 36 / 0.25) !important;
}

.tw-border-t-pink-950\/30 {
  border-top-color: rgb(80 7 36 / 0.3) !important;
}

.tw-border-t-pink-950\/35 {
  border-top-color: rgb(80 7 36 / 0.35) !important;
}

.tw-border-t-pink-950\/40 {
  border-top-color: rgb(80 7 36 / 0.4) !important;
}

.tw-border-t-pink-950\/45 {
  border-top-color: rgb(80 7 36 / 0.45) !important;
}

.tw-border-t-pink-950\/5 {
  border-top-color: rgb(80 7 36 / 0.05) !important;
}

.tw-border-t-pink-950\/50 {
  border-top-color: rgb(80 7 36 / 0.5) !important;
}

.tw-border-t-pink-950\/55 {
  border-top-color: rgb(80 7 36 / 0.55) !important;
}

.tw-border-t-pink-950\/60 {
  border-top-color: rgb(80 7 36 / 0.6) !important;
}

.tw-border-t-pink-950\/65 {
  border-top-color: rgb(80 7 36 / 0.65) !important;
}

.tw-border-t-pink-950\/70 {
  border-top-color: rgb(80 7 36 / 0.7) !important;
}

.tw-border-t-pink-950\/75 {
  border-top-color: rgb(80 7 36 / 0.75) !important;
}

.tw-border-t-pink-950\/80 {
  border-top-color: rgb(80 7 36 / 0.8) !important;
}

.tw-border-t-pink-950\/85 {
  border-top-color: rgb(80 7 36 / 0.85) !important;
}

.tw-border-t-pink-950\/90 {
  border-top-color: rgb(80 7 36 / 0.9) !important;
}

.tw-border-t-pink-950\/95 {
  border-top-color: rgb(80 7 36 / 0.95) !important;
}

.tw-border-t-purple-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(243 232 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-100\/0 {
  border-top-color: rgb(243 232 255 / 0) !important;
}

.tw-border-t-purple-100\/10 {
  border-top-color: rgb(243 232 255 / 0.1) !important;
}

.tw-border-t-purple-100\/100 {
  border-top-color: rgb(243 232 255 / 1) !important;
}

.tw-border-t-purple-100\/15 {
  border-top-color: rgb(243 232 255 / 0.15) !important;
}

.tw-border-t-purple-100\/20 {
  border-top-color: rgb(243 232 255 / 0.2) !important;
}

.tw-border-t-purple-100\/25 {
  border-top-color: rgb(243 232 255 / 0.25) !important;
}

.tw-border-t-purple-100\/30 {
  border-top-color: rgb(243 232 255 / 0.3) !important;
}

.tw-border-t-purple-100\/35 {
  border-top-color: rgb(243 232 255 / 0.35) !important;
}

.tw-border-t-purple-100\/40 {
  border-top-color: rgb(243 232 255 / 0.4) !important;
}

.tw-border-t-purple-100\/45 {
  border-top-color: rgb(243 232 255 / 0.45) !important;
}

.tw-border-t-purple-100\/5 {
  border-top-color: rgb(243 232 255 / 0.05) !important;
}

.tw-border-t-purple-100\/50 {
  border-top-color: rgb(243 232 255 / 0.5) !important;
}

.tw-border-t-purple-100\/55 {
  border-top-color: rgb(243 232 255 / 0.55) !important;
}

.tw-border-t-purple-100\/60 {
  border-top-color: rgb(243 232 255 / 0.6) !important;
}

.tw-border-t-purple-100\/65 {
  border-top-color: rgb(243 232 255 / 0.65) !important;
}

.tw-border-t-purple-100\/70 {
  border-top-color: rgb(243 232 255 / 0.7) !important;
}

.tw-border-t-purple-100\/75 {
  border-top-color: rgb(243 232 255 / 0.75) !important;
}

.tw-border-t-purple-100\/80 {
  border-top-color: rgb(243 232 255 / 0.8) !important;
}

.tw-border-t-purple-100\/85 {
  border-top-color: rgb(243 232 255 / 0.85) !important;
}

.tw-border-t-purple-100\/90 {
  border-top-color: rgb(243 232 255 / 0.9) !important;
}

.tw-border-t-purple-100\/95 {
  border-top-color: rgb(243 232 255 / 0.95) !important;
}

.tw-border-t-purple-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(233 213 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-200\/0 {
  border-top-color: rgb(233 213 255 / 0) !important;
}

.tw-border-t-purple-200\/10 {
  border-top-color: rgb(233 213 255 / 0.1) !important;
}

.tw-border-t-purple-200\/100 {
  border-top-color: rgb(233 213 255 / 1) !important;
}

.tw-border-t-purple-200\/15 {
  border-top-color: rgb(233 213 255 / 0.15) !important;
}

.tw-border-t-purple-200\/20 {
  border-top-color: rgb(233 213 255 / 0.2) !important;
}

.tw-border-t-purple-200\/25 {
  border-top-color: rgb(233 213 255 / 0.25) !important;
}

.tw-border-t-purple-200\/30 {
  border-top-color: rgb(233 213 255 / 0.3) !important;
}

.tw-border-t-purple-200\/35 {
  border-top-color: rgb(233 213 255 / 0.35) !important;
}

.tw-border-t-purple-200\/40 {
  border-top-color: rgb(233 213 255 / 0.4) !important;
}

.tw-border-t-purple-200\/45 {
  border-top-color: rgb(233 213 255 / 0.45) !important;
}

.tw-border-t-purple-200\/5 {
  border-top-color: rgb(233 213 255 / 0.05) !important;
}

.tw-border-t-purple-200\/50 {
  border-top-color: rgb(233 213 255 / 0.5) !important;
}

.tw-border-t-purple-200\/55 {
  border-top-color: rgb(233 213 255 / 0.55) !important;
}

.tw-border-t-purple-200\/60 {
  border-top-color: rgb(233 213 255 / 0.6) !important;
}

.tw-border-t-purple-200\/65 {
  border-top-color: rgb(233 213 255 / 0.65) !important;
}

.tw-border-t-purple-200\/70 {
  border-top-color: rgb(233 213 255 / 0.7) !important;
}

.tw-border-t-purple-200\/75 {
  border-top-color: rgb(233 213 255 / 0.75) !important;
}

.tw-border-t-purple-200\/80 {
  border-top-color: rgb(233 213 255 / 0.8) !important;
}

.tw-border-t-purple-200\/85 {
  border-top-color: rgb(233 213 255 / 0.85) !important;
}

.tw-border-t-purple-200\/90 {
  border-top-color: rgb(233 213 255 / 0.9) !important;
}

.tw-border-t-purple-200\/95 {
  border-top-color: rgb(233 213 255 / 0.95) !important;
}

.tw-border-t-purple-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(216 180 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-300\/0 {
  border-top-color: rgb(216 180 254 / 0) !important;
}

.tw-border-t-purple-300\/10 {
  border-top-color: rgb(216 180 254 / 0.1) !important;
}

.tw-border-t-purple-300\/100 {
  border-top-color: rgb(216 180 254 / 1) !important;
}

.tw-border-t-purple-300\/15 {
  border-top-color: rgb(216 180 254 / 0.15) !important;
}

.tw-border-t-purple-300\/20 {
  border-top-color: rgb(216 180 254 / 0.2) !important;
}

.tw-border-t-purple-300\/25 {
  border-top-color: rgb(216 180 254 / 0.25) !important;
}

.tw-border-t-purple-300\/30 {
  border-top-color: rgb(216 180 254 / 0.3) !important;
}

.tw-border-t-purple-300\/35 {
  border-top-color: rgb(216 180 254 / 0.35) !important;
}

.tw-border-t-purple-300\/40 {
  border-top-color: rgb(216 180 254 / 0.4) !important;
}

.tw-border-t-purple-300\/45 {
  border-top-color: rgb(216 180 254 / 0.45) !important;
}

.tw-border-t-purple-300\/5 {
  border-top-color: rgb(216 180 254 / 0.05) !important;
}

.tw-border-t-purple-300\/50 {
  border-top-color: rgb(216 180 254 / 0.5) !important;
}

.tw-border-t-purple-300\/55 {
  border-top-color: rgb(216 180 254 / 0.55) !important;
}

.tw-border-t-purple-300\/60 {
  border-top-color: rgb(216 180 254 / 0.6) !important;
}

.tw-border-t-purple-300\/65 {
  border-top-color: rgb(216 180 254 / 0.65) !important;
}

.tw-border-t-purple-300\/70 {
  border-top-color: rgb(216 180 254 / 0.7) !important;
}

.tw-border-t-purple-300\/75 {
  border-top-color: rgb(216 180 254 / 0.75) !important;
}

.tw-border-t-purple-300\/80 {
  border-top-color: rgb(216 180 254 / 0.8) !important;
}

.tw-border-t-purple-300\/85 {
  border-top-color: rgb(216 180 254 / 0.85) !important;
}

.tw-border-t-purple-300\/90 {
  border-top-color: rgb(216 180 254 / 0.9) !important;
}

.tw-border-t-purple-300\/95 {
  border-top-color: rgb(216 180 254 / 0.95) !important;
}

.tw-border-t-purple-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(192 132 252 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-400\/0 {
  border-top-color: rgb(192 132 252 / 0) !important;
}

.tw-border-t-purple-400\/10 {
  border-top-color: rgb(192 132 252 / 0.1) !important;
}

.tw-border-t-purple-400\/100 {
  border-top-color: rgb(192 132 252 / 1) !important;
}

.tw-border-t-purple-400\/15 {
  border-top-color: rgb(192 132 252 / 0.15) !important;
}

.tw-border-t-purple-400\/20 {
  border-top-color: rgb(192 132 252 / 0.2) !important;
}

.tw-border-t-purple-400\/25 {
  border-top-color: rgb(192 132 252 / 0.25) !important;
}

.tw-border-t-purple-400\/30 {
  border-top-color: rgb(192 132 252 / 0.3) !important;
}

.tw-border-t-purple-400\/35 {
  border-top-color: rgb(192 132 252 / 0.35) !important;
}

.tw-border-t-purple-400\/40 {
  border-top-color: rgb(192 132 252 / 0.4) !important;
}

.tw-border-t-purple-400\/45 {
  border-top-color: rgb(192 132 252 / 0.45) !important;
}

.tw-border-t-purple-400\/5 {
  border-top-color: rgb(192 132 252 / 0.05) !important;
}

.tw-border-t-purple-400\/50 {
  border-top-color: rgb(192 132 252 / 0.5) !important;
}

.tw-border-t-purple-400\/55 {
  border-top-color: rgb(192 132 252 / 0.55) !important;
}

.tw-border-t-purple-400\/60 {
  border-top-color: rgb(192 132 252 / 0.6) !important;
}

.tw-border-t-purple-400\/65 {
  border-top-color: rgb(192 132 252 / 0.65) !important;
}

.tw-border-t-purple-400\/70 {
  border-top-color: rgb(192 132 252 / 0.7) !important;
}

.tw-border-t-purple-400\/75 {
  border-top-color: rgb(192 132 252 / 0.75) !important;
}

.tw-border-t-purple-400\/80 {
  border-top-color: rgb(192 132 252 / 0.8) !important;
}

.tw-border-t-purple-400\/85 {
  border-top-color: rgb(192 132 252 / 0.85) !important;
}

.tw-border-t-purple-400\/90 {
  border-top-color: rgb(192 132 252 / 0.9) !important;
}

.tw-border-t-purple-400\/95 {
  border-top-color: rgb(192 132 252 / 0.95) !important;
}

.tw-border-t-purple-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 245 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-50\/0 {
  border-top-color: rgb(250 245 255 / 0) !important;
}

.tw-border-t-purple-50\/10 {
  border-top-color: rgb(250 245 255 / 0.1) !important;
}

.tw-border-t-purple-50\/100 {
  border-top-color: rgb(250 245 255 / 1) !important;
}

.tw-border-t-purple-50\/15 {
  border-top-color: rgb(250 245 255 / 0.15) !important;
}

.tw-border-t-purple-50\/20 {
  border-top-color: rgb(250 245 255 / 0.2) !important;
}

.tw-border-t-purple-50\/25 {
  border-top-color: rgb(250 245 255 / 0.25) !important;
}

.tw-border-t-purple-50\/30 {
  border-top-color: rgb(250 245 255 / 0.3) !important;
}

.tw-border-t-purple-50\/35 {
  border-top-color: rgb(250 245 255 / 0.35) !important;
}

.tw-border-t-purple-50\/40 {
  border-top-color: rgb(250 245 255 / 0.4) !important;
}

.tw-border-t-purple-50\/45 {
  border-top-color: rgb(250 245 255 / 0.45) !important;
}

.tw-border-t-purple-50\/5 {
  border-top-color: rgb(250 245 255 / 0.05) !important;
}

.tw-border-t-purple-50\/50 {
  border-top-color: rgb(250 245 255 / 0.5) !important;
}

.tw-border-t-purple-50\/55 {
  border-top-color: rgb(250 245 255 / 0.55) !important;
}

.tw-border-t-purple-50\/60 {
  border-top-color: rgb(250 245 255 / 0.6) !important;
}

.tw-border-t-purple-50\/65 {
  border-top-color: rgb(250 245 255 / 0.65) !important;
}

.tw-border-t-purple-50\/70 {
  border-top-color: rgb(250 245 255 / 0.7) !important;
}

.tw-border-t-purple-50\/75 {
  border-top-color: rgb(250 245 255 / 0.75) !important;
}

.tw-border-t-purple-50\/80 {
  border-top-color: rgb(250 245 255 / 0.8) !important;
}

.tw-border-t-purple-50\/85 {
  border-top-color: rgb(250 245 255 / 0.85) !important;
}

.tw-border-t-purple-50\/90 {
  border-top-color: rgb(250 245 255 / 0.9) !important;
}

.tw-border-t-purple-50\/95 {
  border-top-color: rgb(250 245 255 / 0.95) !important;
}

.tw-border-t-purple-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(168 85 247 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-500\/0 {
  border-top-color: rgb(168 85 247 / 0) !important;
}

.tw-border-t-purple-500\/10 {
  border-top-color: rgb(168 85 247 / 0.1) !important;
}

.tw-border-t-purple-500\/100 {
  border-top-color: rgb(168 85 247 / 1) !important;
}

.tw-border-t-purple-500\/15 {
  border-top-color: rgb(168 85 247 / 0.15) !important;
}

.tw-border-t-purple-500\/20 {
  border-top-color: rgb(168 85 247 / 0.2) !important;
}

.tw-border-t-purple-500\/25 {
  border-top-color: rgb(168 85 247 / 0.25) !important;
}

.tw-border-t-purple-500\/30 {
  border-top-color: rgb(168 85 247 / 0.3) !important;
}

.tw-border-t-purple-500\/35 {
  border-top-color: rgb(168 85 247 / 0.35) !important;
}

.tw-border-t-purple-500\/40 {
  border-top-color: rgb(168 85 247 / 0.4) !important;
}

.tw-border-t-purple-500\/45 {
  border-top-color: rgb(168 85 247 / 0.45) !important;
}

.tw-border-t-purple-500\/5 {
  border-top-color: rgb(168 85 247 / 0.05) !important;
}

.tw-border-t-purple-500\/50 {
  border-top-color: rgb(168 85 247 / 0.5) !important;
}

.tw-border-t-purple-500\/55 {
  border-top-color: rgb(168 85 247 / 0.55) !important;
}

.tw-border-t-purple-500\/60 {
  border-top-color: rgb(168 85 247 / 0.6) !important;
}

.tw-border-t-purple-500\/65 {
  border-top-color: rgb(168 85 247 / 0.65) !important;
}

.tw-border-t-purple-500\/70 {
  border-top-color: rgb(168 85 247 / 0.7) !important;
}

.tw-border-t-purple-500\/75 {
  border-top-color: rgb(168 85 247 / 0.75) !important;
}

.tw-border-t-purple-500\/80 {
  border-top-color: rgb(168 85 247 / 0.8) !important;
}

.tw-border-t-purple-500\/85 {
  border-top-color: rgb(168 85 247 / 0.85) !important;
}

.tw-border-t-purple-500\/90 {
  border-top-color: rgb(168 85 247 / 0.9) !important;
}

.tw-border-t-purple-500\/95 {
  border-top-color: rgb(168 85 247 / 0.95) !important;
}

.tw-border-t-purple-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(147 51 234 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-600\/0 {
  border-top-color: rgb(147 51 234 / 0) !important;
}

.tw-border-t-purple-600\/10 {
  border-top-color: rgb(147 51 234 / 0.1) !important;
}

.tw-border-t-purple-600\/100 {
  border-top-color: rgb(147 51 234 / 1) !important;
}

.tw-border-t-purple-600\/15 {
  border-top-color: rgb(147 51 234 / 0.15) !important;
}

.tw-border-t-purple-600\/20 {
  border-top-color: rgb(147 51 234 / 0.2) !important;
}

.tw-border-t-purple-600\/25 {
  border-top-color: rgb(147 51 234 / 0.25) !important;
}

.tw-border-t-purple-600\/30 {
  border-top-color: rgb(147 51 234 / 0.3) !important;
}

.tw-border-t-purple-600\/35 {
  border-top-color: rgb(147 51 234 / 0.35) !important;
}

.tw-border-t-purple-600\/40 {
  border-top-color: rgb(147 51 234 / 0.4) !important;
}

.tw-border-t-purple-600\/45 {
  border-top-color: rgb(147 51 234 / 0.45) !important;
}

.tw-border-t-purple-600\/5 {
  border-top-color: rgb(147 51 234 / 0.05) !important;
}

.tw-border-t-purple-600\/50 {
  border-top-color: rgb(147 51 234 / 0.5) !important;
}

.tw-border-t-purple-600\/55 {
  border-top-color: rgb(147 51 234 / 0.55) !important;
}

.tw-border-t-purple-600\/60 {
  border-top-color: rgb(147 51 234 / 0.6) !important;
}

.tw-border-t-purple-600\/65 {
  border-top-color: rgb(147 51 234 / 0.65) !important;
}

.tw-border-t-purple-600\/70 {
  border-top-color: rgb(147 51 234 / 0.7) !important;
}

.tw-border-t-purple-600\/75 {
  border-top-color: rgb(147 51 234 / 0.75) !important;
}

.tw-border-t-purple-600\/80 {
  border-top-color: rgb(147 51 234 / 0.8) !important;
}

.tw-border-t-purple-600\/85 {
  border-top-color: rgb(147 51 234 / 0.85) !important;
}

.tw-border-t-purple-600\/90 {
  border-top-color: rgb(147 51 234 / 0.9) !important;
}

.tw-border-t-purple-600\/95 {
  border-top-color: rgb(147 51 234 / 0.95) !important;
}

.tw-border-t-purple-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(126 34 206 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-700\/0 {
  border-top-color: rgb(126 34 206 / 0) !important;
}

.tw-border-t-purple-700\/10 {
  border-top-color: rgb(126 34 206 / 0.1) !important;
}

.tw-border-t-purple-700\/100 {
  border-top-color: rgb(126 34 206 / 1) !important;
}

.tw-border-t-purple-700\/15 {
  border-top-color: rgb(126 34 206 / 0.15) !important;
}

.tw-border-t-purple-700\/20 {
  border-top-color: rgb(126 34 206 / 0.2) !important;
}

.tw-border-t-purple-700\/25 {
  border-top-color: rgb(126 34 206 / 0.25) !important;
}

.tw-border-t-purple-700\/30 {
  border-top-color: rgb(126 34 206 / 0.3) !important;
}

.tw-border-t-purple-700\/35 {
  border-top-color: rgb(126 34 206 / 0.35) !important;
}

.tw-border-t-purple-700\/40 {
  border-top-color: rgb(126 34 206 / 0.4) !important;
}

.tw-border-t-purple-700\/45 {
  border-top-color: rgb(126 34 206 / 0.45) !important;
}

.tw-border-t-purple-700\/5 {
  border-top-color: rgb(126 34 206 / 0.05) !important;
}

.tw-border-t-purple-700\/50 {
  border-top-color: rgb(126 34 206 / 0.5) !important;
}

.tw-border-t-purple-700\/55 {
  border-top-color: rgb(126 34 206 / 0.55) !important;
}

.tw-border-t-purple-700\/60 {
  border-top-color: rgb(126 34 206 / 0.6) !important;
}

.tw-border-t-purple-700\/65 {
  border-top-color: rgb(126 34 206 / 0.65) !important;
}

.tw-border-t-purple-700\/70 {
  border-top-color: rgb(126 34 206 / 0.7) !important;
}

.tw-border-t-purple-700\/75 {
  border-top-color: rgb(126 34 206 / 0.75) !important;
}

.tw-border-t-purple-700\/80 {
  border-top-color: rgb(126 34 206 / 0.8) !important;
}

.tw-border-t-purple-700\/85 {
  border-top-color: rgb(126 34 206 / 0.85) !important;
}

.tw-border-t-purple-700\/90 {
  border-top-color: rgb(126 34 206 / 0.9) !important;
}

.tw-border-t-purple-700\/95 {
  border-top-color: rgb(126 34 206 / 0.95) !important;
}

.tw-border-t-purple-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(107 33 168 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-800\/0 {
  border-top-color: rgb(107 33 168 / 0) !important;
}

.tw-border-t-purple-800\/10 {
  border-top-color: rgb(107 33 168 / 0.1) !important;
}

.tw-border-t-purple-800\/100 {
  border-top-color: rgb(107 33 168 / 1) !important;
}

.tw-border-t-purple-800\/15 {
  border-top-color: rgb(107 33 168 / 0.15) !important;
}

.tw-border-t-purple-800\/20 {
  border-top-color: rgb(107 33 168 / 0.2) !important;
}

.tw-border-t-purple-800\/25 {
  border-top-color: rgb(107 33 168 / 0.25) !important;
}

.tw-border-t-purple-800\/30 {
  border-top-color: rgb(107 33 168 / 0.3) !important;
}

.tw-border-t-purple-800\/35 {
  border-top-color: rgb(107 33 168 / 0.35) !important;
}

.tw-border-t-purple-800\/40 {
  border-top-color: rgb(107 33 168 / 0.4) !important;
}

.tw-border-t-purple-800\/45 {
  border-top-color: rgb(107 33 168 / 0.45) !important;
}

.tw-border-t-purple-800\/5 {
  border-top-color: rgb(107 33 168 / 0.05) !important;
}

.tw-border-t-purple-800\/50 {
  border-top-color: rgb(107 33 168 / 0.5) !important;
}

.tw-border-t-purple-800\/55 {
  border-top-color: rgb(107 33 168 / 0.55) !important;
}

.tw-border-t-purple-800\/60 {
  border-top-color: rgb(107 33 168 / 0.6) !important;
}

.tw-border-t-purple-800\/65 {
  border-top-color: rgb(107 33 168 / 0.65) !important;
}

.tw-border-t-purple-800\/70 {
  border-top-color: rgb(107 33 168 / 0.7) !important;
}

.tw-border-t-purple-800\/75 {
  border-top-color: rgb(107 33 168 / 0.75) !important;
}

.tw-border-t-purple-800\/80 {
  border-top-color: rgb(107 33 168 / 0.8) !important;
}

.tw-border-t-purple-800\/85 {
  border-top-color: rgb(107 33 168 / 0.85) !important;
}

.tw-border-t-purple-800\/90 {
  border-top-color: rgb(107 33 168 / 0.9) !important;
}

.tw-border-t-purple-800\/95 {
  border-top-color: rgb(107 33 168 / 0.95) !important;
}

.tw-border-t-purple-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(88 28 135 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-900\/0 {
  border-top-color: rgb(88 28 135 / 0) !important;
}

.tw-border-t-purple-900\/10 {
  border-top-color: rgb(88 28 135 / 0.1) !important;
}

.tw-border-t-purple-900\/100 {
  border-top-color: rgb(88 28 135 / 1) !important;
}

.tw-border-t-purple-900\/15 {
  border-top-color: rgb(88 28 135 / 0.15) !important;
}

.tw-border-t-purple-900\/20 {
  border-top-color: rgb(88 28 135 / 0.2) !important;
}

.tw-border-t-purple-900\/25 {
  border-top-color: rgb(88 28 135 / 0.25) !important;
}

.tw-border-t-purple-900\/30 {
  border-top-color: rgb(88 28 135 / 0.3) !important;
}

.tw-border-t-purple-900\/35 {
  border-top-color: rgb(88 28 135 / 0.35) !important;
}

.tw-border-t-purple-900\/40 {
  border-top-color: rgb(88 28 135 / 0.4) !important;
}

.tw-border-t-purple-900\/45 {
  border-top-color: rgb(88 28 135 / 0.45) !important;
}

.tw-border-t-purple-900\/5 {
  border-top-color: rgb(88 28 135 / 0.05) !important;
}

.tw-border-t-purple-900\/50 {
  border-top-color: rgb(88 28 135 / 0.5) !important;
}

.tw-border-t-purple-900\/55 {
  border-top-color: rgb(88 28 135 / 0.55) !important;
}

.tw-border-t-purple-900\/60 {
  border-top-color: rgb(88 28 135 / 0.6) !important;
}

.tw-border-t-purple-900\/65 {
  border-top-color: rgb(88 28 135 / 0.65) !important;
}

.tw-border-t-purple-900\/70 {
  border-top-color: rgb(88 28 135 / 0.7) !important;
}

.tw-border-t-purple-900\/75 {
  border-top-color: rgb(88 28 135 / 0.75) !important;
}

.tw-border-t-purple-900\/80 {
  border-top-color: rgb(88 28 135 / 0.8) !important;
}

.tw-border-t-purple-900\/85 {
  border-top-color: rgb(88 28 135 / 0.85) !important;
}

.tw-border-t-purple-900\/90 {
  border-top-color: rgb(88 28 135 / 0.9) !important;
}

.tw-border-t-purple-900\/95 {
  border-top-color: rgb(88 28 135 / 0.95) !important;
}

.tw-border-t-purple-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(59 7 100 / var(--tw-border-opacity)) !important;
}

.tw-border-t-purple-950\/0 {
  border-top-color: rgb(59 7 100 / 0) !important;
}

.tw-border-t-purple-950\/10 {
  border-top-color: rgb(59 7 100 / 0.1) !important;
}

.tw-border-t-purple-950\/100 {
  border-top-color: rgb(59 7 100 / 1) !important;
}

.tw-border-t-purple-950\/15 {
  border-top-color: rgb(59 7 100 / 0.15) !important;
}

.tw-border-t-purple-950\/20 {
  border-top-color: rgb(59 7 100 / 0.2) !important;
}

.tw-border-t-purple-950\/25 {
  border-top-color: rgb(59 7 100 / 0.25) !important;
}

.tw-border-t-purple-950\/30 {
  border-top-color: rgb(59 7 100 / 0.3) !important;
}

.tw-border-t-purple-950\/35 {
  border-top-color: rgb(59 7 100 / 0.35) !important;
}

.tw-border-t-purple-950\/40 {
  border-top-color: rgb(59 7 100 / 0.4) !important;
}

.tw-border-t-purple-950\/45 {
  border-top-color: rgb(59 7 100 / 0.45) !important;
}

.tw-border-t-purple-950\/5 {
  border-top-color: rgb(59 7 100 / 0.05) !important;
}

.tw-border-t-purple-950\/50 {
  border-top-color: rgb(59 7 100 / 0.5) !important;
}

.tw-border-t-purple-950\/55 {
  border-top-color: rgb(59 7 100 / 0.55) !important;
}

.tw-border-t-purple-950\/60 {
  border-top-color: rgb(59 7 100 / 0.6) !important;
}

.tw-border-t-purple-950\/65 {
  border-top-color: rgb(59 7 100 / 0.65) !important;
}

.tw-border-t-purple-950\/70 {
  border-top-color: rgb(59 7 100 / 0.7) !important;
}

.tw-border-t-purple-950\/75 {
  border-top-color: rgb(59 7 100 / 0.75) !important;
}

.tw-border-t-purple-950\/80 {
  border-top-color: rgb(59 7 100 / 0.8) !important;
}

.tw-border-t-purple-950\/85 {
  border-top-color: rgb(59 7 100 / 0.85) !important;
}

.tw-border-t-purple-950\/90 {
  border-top-color: rgb(59 7 100 / 0.9) !important;
}

.tw-border-t-purple-950\/95 {
  border-top-color: rgb(59 7 100 / 0.95) !important;
}

.tw-border-t-red-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 226 226 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-100\/0 {
  border-top-color: rgb(254 226 226 / 0) !important;
}

.tw-border-t-red-100\/10 {
  border-top-color: rgb(254 226 226 / 0.1) !important;
}

.tw-border-t-red-100\/100 {
  border-top-color: rgb(254 226 226 / 1) !important;
}

.tw-border-t-red-100\/15 {
  border-top-color: rgb(254 226 226 / 0.15) !important;
}

.tw-border-t-red-100\/20 {
  border-top-color: rgb(254 226 226 / 0.2) !important;
}

.tw-border-t-red-100\/25 {
  border-top-color: rgb(254 226 226 / 0.25) !important;
}

.tw-border-t-red-100\/30 {
  border-top-color: rgb(254 226 226 / 0.3) !important;
}

.tw-border-t-red-100\/35 {
  border-top-color: rgb(254 226 226 / 0.35) !important;
}

.tw-border-t-red-100\/40 {
  border-top-color: rgb(254 226 226 / 0.4) !important;
}

.tw-border-t-red-100\/45 {
  border-top-color: rgb(254 226 226 / 0.45) !important;
}

.tw-border-t-red-100\/5 {
  border-top-color: rgb(254 226 226 / 0.05) !important;
}

.tw-border-t-red-100\/50 {
  border-top-color: rgb(254 226 226 / 0.5) !important;
}

.tw-border-t-red-100\/55 {
  border-top-color: rgb(254 226 226 / 0.55) !important;
}

.tw-border-t-red-100\/60 {
  border-top-color: rgb(254 226 226 / 0.6) !important;
}

.tw-border-t-red-100\/65 {
  border-top-color: rgb(254 226 226 / 0.65) !important;
}

.tw-border-t-red-100\/70 {
  border-top-color: rgb(254 226 226 / 0.7) !important;
}

.tw-border-t-red-100\/75 {
  border-top-color: rgb(254 226 226 / 0.75) !important;
}

.tw-border-t-red-100\/80 {
  border-top-color: rgb(254 226 226 / 0.8) !important;
}

.tw-border-t-red-100\/85 {
  border-top-color: rgb(254 226 226 / 0.85) !important;
}

.tw-border-t-red-100\/90 {
  border-top-color: rgb(254 226 226 / 0.9) !important;
}

.tw-border-t-red-100\/95 {
  border-top-color: rgb(254 226 226 / 0.95) !important;
}

.tw-border-t-red-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 202 202 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-200\/0 {
  border-top-color: rgb(254 202 202 / 0) !important;
}

.tw-border-t-red-200\/10 {
  border-top-color: rgb(254 202 202 / 0.1) !important;
}

.tw-border-t-red-200\/100 {
  border-top-color: rgb(254 202 202 / 1) !important;
}

.tw-border-t-red-200\/15 {
  border-top-color: rgb(254 202 202 / 0.15) !important;
}

.tw-border-t-red-200\/20 {
  border-top-color: rgb(254 202 202 / 0.2) !important;
}

.tw-border-t-red-200\/25 {
  border-top-color: rgb(254 202 202 / 0.25) !important;
}

.tw-border-t-red-200\/30 {
  border-top-color: rgb(254 202 202 / 0.3) !important;
}

.tw-border-t-red-200\/35 {
  border-top-color: rgb(254 202 202 / 0.35) !important;
}

.tw-border-t-red-200\/40 {
  border-top-color: rgb(254 202 202 / 0.4) !important;
}

.tw-border-t-red-200\/45 {
  border-top-color: rgb(254 202 202 / 0.45) !important;
}

.tw-border-t-red-200\/5 {
  border-top-color: rgb(254 202 202 / 0.05) !important;
}

.tw-border-t-red-200\/50 {
  border-top-color: rgb(254 202 202 / 0.5) !important;
}

.tw-border-t-red-200\/55 {
  border-top-color: rgb(254 202 202 / 0.55) !important;
}

.tw-border-t-red-200\/60 {
  border-top-color: rgb(254 202 202 / 0.6) !important;
}

.tw-border-t-red-200\/65 {
  border-top-color: rgb(254 202 202 / 0.65) !important;
}

.tw-border-t-red-200\/70 {
  border-top-color: rgb(254 202 202 / 0.7) !important;
}

.tw-border-t-red-200\/75 {
  border-top-color: rgb(254 202 202 / 0.75) !important;
}

.tw-border-t-red-200\/80 {
  border-top-color: rgb(254 202 202 / 0.8) !important;
}

.tw-border-t-red-200\/85 {
  border-top-color: rgb(254 202 202 / 0.85) !important;
}

.tw-border-t-red-200\/90 {
  border-top-color: rgb(254 202 202 / 0.9) !important;
}

.tw-border-t-red-200\/95 {
  border-top-color: rgb(254 202 202 / 0.95) !important;
}

.tw-border-t-red-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(252 165 165 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-300\/0 {
  border-top-color: rgb(252 165 165 / 0) !important;
}

.tw-border-t-red-300\/10 {
  border-top-color: rgb(252 165 165 / 0.1) !important;
}

.tw-border-t-red-300\/100 {
  border-top-color: rgb(252 165 165 / 1) !important;
}

.tw-border-t-red-300\/15 {
  border-top-color: rgb(252 165 165 / 0.15) !important;
}

.tw-border-t-red-300\/20 {
  border-top-color: rgb(252 165 165 / 0.2) !important;
}

.tw-border-t-red-300\/25 {
  border-top-color: rgb(252 165 165 / 0.25) !important;
}

.tw-border-t-red-300\/30 {
  border-top-color: rgb(252 165 165 / 0.3) !important;
}

.tw-border-t-red-300\/35 {
  border-top-color: rgb(252 165 165 / 0.35) !important;
}

.tw-border-t-red-300\/40 {
  border-top-color: rgb(252 165 165 / 0.4) !important;
}

.tw-border-t-red-300\/45 {
  border-top-color: rgb(252 165 165 / 0.45) !important;
}

.tw-border-t-red-300\/5 {
  border-top-color: rgb(252 165 165 / 0.05) !important;
}

.tw-border-t-red-300\/50 {
  border-top-color: rgb(252 165 165 / 0.5) !important;
}

.tw-border-t-red-300\/55 {
  border-top-color: rgb(252 165 165 / 0.55) !important;
}

.tw-border-t-red-300\/60 {
  border-top-color: rgb(252 165 165 / 0.6) !important;
}

.tw-border-t-red-300\/65 {
  border-top-color: rgb(252 165 165 / 0.65) !important;
}

.tw-border-t-red-300\/70 {
  border-top-color: rgb(252 165 165 / 0.7) !important;
}

.tw-border-t-red-300\/75 {
  border-top-color: rgb(252 165 165 / 0.75) !important;
}

.tw-border-t-red-300\/80 {
  border-top-color: rgb(252 165 165 / 0.8) !important;
}

.tw-border-t-red-300\/85 {
  border-top-color: rgb(252 165 165 / 0.85) !important;
}

.tw-border-t-red-300\/90 {
  border-top-color: rgb(252 165 165 / 0.9) !important;
}

.tw-border-t-red-300\/95 {
  border-top-color: rgb(252 165 165 / 0.95) !important;
}

.tw-border-t-red-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(248 113 113 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-400\/0 {
  border-top-color: rgb(248 113 113 / 0) !important;
}

.tw-border-t-red-400\/10 {
  border-top-color: rgb(248 113 113 / 0.1) !important;
}

.tw-border-t-red-400\/100 {
  border-top-color: rgb(248 113 113 / 1) !important;
}

.tw-border-t-red-400\/15 {
  border-top-color: rgb(248 113 113 / 0.15) !important;
}

.tw-border-t-red-400\/20 {
  border-top-color: rgb(248 113 113 / 0.2) !important;
}

.tw-border-t-red-400\/25 {
  border-top-color: rgb(248 113 113 / 0.25) !important;
}

.tw-border-t-red-400\/30 {
  border-top-color: rgb(248 113 113 / 0.3) !important;
}

.tw-border-t-red-400\/35 {
  border-top-color: rgb(248 113 113 / 0.35) !important;
}

.tw-border-t-red-400\/40 {
  border-top-color: rgb(248 113 113 / 0.4) !important;
}

.tw-border-t-red-400\/45 {
  border-top-color: rgb(248 113 113 / 0.45) !important;
}

.tw-border-t-red-400\/5 {
  border-top-color: rgb(248 113 113 / 0.05) !important;
}

.tw-border-t-red-400\/50 {
  border-top-color: rgb(248 113 113 / 0.5) !important;
}

.tw-border-t-red-400\/55 {
  border-top-color: rgb(248 113 113 / 0.55) !important;
}

.tw-border-t-red-400\/60 {
  border-top-color: rgb(248 113 113 / 0.6) !important;
}

.tw-border-t-red-400\/65 {
  border-top-color: rgb(248 113 113 / 0.65) !important;
}

.tw-border-t-red-400\/70 {
  border-top-color: rgb(248 113 113 / 0.7) !important;
}

.tw-border-t-red-400\/75 {
  border-top-color: rgb(248 113 113 / 0.75) !important;
}

.tw-border-t-red-400\/80 {
  border-top-color: rgb(248 113 113 / 0.8) !important;
}

.tw-border-t-red-400\/85 {
  border-top-color: rgb(248 113 113 / 0.85) !important;
}

.tw-border-t-red-400\/90 {
  border-top-color: rgb(248 113 113 / 0.9) !important;
}

.tw-border-t-red-400\/95 {
  border-top-color: rgb(248 113 113 / 0.95) !important;
}

.tw-border-t-red-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 242 242 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-50\/0 {
  border-top-color: rgb(254 242 242 / 0) !important;
}

.tw-border-t-red-50\/10 {
  border-top-color: rgb(254 242 242 / 0.1) !important;
}

.tw-border-t-red-50\/100 {
  border-top-color: rgb(254 242 242 / 1) !important;
}

.tw-border-t-red-50\/15 {
  border-top-color: rgb(254 242 242 / 0.15) !important;
}

.tw-border-t-red-50\/20 {
  border-top-color: rgb(254 242 242 / 0.2) !important;
}

.tw-border-t-red-50\/25 {
  border-top-color: rgb(254 242 242 / 0.25) !important;
}

.tw-border-t-red-50\/30 {
  border-top-color: rgb(254 242 242 / 0.3) !important;
}

.tw-border-t-red-50\/35 {
  border-top-color: rgb(254 242 242 / 0.35) !important;
}

.tw-border-t-red-50\/40 {
  border-top-color: rgb(254 242 242 / 0.4) !important;
}

.tw-border-t-red-50\/45 {
  border-top-color: rgb(254 242 242 / 0.45) !important;
}

.tw-border-t-red-50\/5 {
  border-top-color: rgb(254 242 242 / 0.05) !important;
}

.tw-border-t-red-50\/50 {
  border-top-color: rgb(254 242 242 / 0.5) !important;
}

.tw-border-t-red-50\/55 {
  border-top-color: rgb(254 242 242 / 0.55) !important;
}

.tw-border-t-red-50\/60 {
  border-top-color: rgb(254 242 242 / 0.6) !important;
}

.tw-border-t-red-50\/65 {
  border-top-color: rgb(254 242 242 / 0.65) !important;
}

.tw-border-t-red-50\/70 {
  border-top-color: rgb(254 242 242 / 0.7) !important;
}

.tw-border-t-red-50\/75 {
  border-top-color: rgb(254 242 242 / 0.75) !important;
}

.tw-border-t-red-50\/80 {
  border-top-color: rgb(254 242 242 / 0.8) !important;
}

.tw-border-t-red-50\/85 {
  border-top-color: rgb(254 242 242 / 0.85) !important;
}

.tw-border-t-red-50\/90 {
  border-top-color: rgb(254 242 242 / 0.9) !important;
}

.tw-border-t-red-50\/95 {
  border-top-color: rgb(254 242 242 / 0.95) !important;
}

.tw-border-t-red-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(239 68 68 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-500\/0 {
  border-top-color: rgb(239 68 68 / 0) !important;
}

.tw-border-t-red-500\/10 {
  border-top-color: rgb(239 68 68 / 0.1) !important;
}

.tw-border-t-red-500\/100 {
  border-top-color: rgb(239 68 68 / 1) !important;
}

.tw-border-t-red-500\/15 {
  border-top-color: rgb(239 68 68 / 0.15) !important;
}

.tw-border-t-red-500\/20 {
  border-top-color: rgb(239 68 68 / 0.2) !important;
}

.tw-border-t-red-500\/25 {
  border-top-color: rgb(239 68 68 / 0.25) !important;
}

.tw-border-t-red-500\/30 {
  border-top-color: rgb(239 68 68 / 0.3) !important;
}

.tw-border-t-red-500\/35 {
  border-top-color: rgb(239 68 68 / 0.35) !important;
}

.tw-border-t-red-500\/40 {
  border-top-color: rgb(239 68 68 / 0.4) !important;
}

.tw-border-t-red-500\/45 {
  border-top-color: rgb(239 68 68 / 0.45) !important;
}

.tw-border-t-red-500\/5 {
  border-top-color: rgb(239 68 68 / 0.05) !important;
}

.tw-border-t-red-500\/50 {
  border-top-color: rgb(239 68 68 / 0.5) !important;
}

.tw-border-t-red-500\/55 {
  border-top-color: rgb(239 68 68 / 0.55) !important;
}

.tw-border-t-red-500\/60 {
  border-top-color: rgb(239 68 68 / 0.6) !important;
}

.tw-border-t-red-500\/65 {
  border-top-color: rgb(239 68 68 / 0.65) !important;
}

.tw-border-t-red-500\/70 {
  border-top-color: rgb(239 68 68 / 0.7) !important;
}

.tw-border-t-red-500\/75 {
  border-top-color: rgb(239 68 68 / 0.75) !important;
}

.tw-border-t-red-500\/80 {
  border-top-color: rgb(239 68 68 / 0.8) !important;
}

.tw-border-t-red-500\/85 {
  border-top-color: rgb(239 68 68 / 0.85) !important;
}

.tw-border-t-red-500\/90 {
  border-top-color: rgb(239 68 68 / 0.9) !important;
}

.tw-border-t-red-500\/95 {
  border-top-color: rgb(239 68 68 / 0.95) !important;
}

.tw-border-t-red-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(220 38 38 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-600\/0 {
  border-top-color: rgb(220 38 38 / 0) !important;
}

.tw-border-t-red-600\/10 {
  border-top-color: rgb(220 38 38 / 0.1) !important;
}

.tw-border-t-red-600\/100 {
  border-top-color: rgb(220 38 38 / 1) !important;
}

.tw-border-t-red-600\/15 {
  border-top-color: rgb(220 38 38 / 0.15) !important;
}

.tw-border-t-red-600\/20 {
  border-top-color: rgb(220 38 38 / 0.2) !important;
}

.tw-border-t-red-600\/25 {
  border-top-color: rgb(220 38 38 / 0.25) !important;
}

.tw-border-t-red-600\/30 {
  border-top-color: rgb(220 38 38 / 0.3) !important;
}

.tw-border-t-red-600\/35 {
  border-top-color: rgb(220 38 38 / 0.35) !important;
}

.tw-border-t-red-600\/40 {
  border-top-color: rgb(220 38 38 / 0.4) !important;
}

.tw-border-t-red-600\/45 {
  border-top-color: rgb(220 38 38 / 0.45) !important;
}

.tw-border-t-red-600\/5 {
  border-top-color: rgb(220 38 38 / 0.05) !important;
}

.tw-border-t-red-600\/50 {
  border-top-color: rgb(220 38 38 / 0.5) !important;
}

.tw-border-t-red-600\/55 {
  border-top-color: rgb(220 38 38 / 0.55) !important;
}

.tw-border-t-red-600\/60 {
  border-top-color: rgb(220 38 38 / 0.6) !important;
}

.tw-border-t-red-600\/65 {
  border-top-color: rgb(220 38 38 / 0.65) !important;
}

.tw-border-t-red-600\/70 {
  border-top-color: rgb(220 38 38 / 0.7) !important;
}

.tw-border-t-red-600\/75 {
  border-top-color: rgb(220 38 38 / 0.75) !important;
}

.tw-border-t-red-600\/80 {
  border-top-color: rgb(220 38 38 / 0.8) !important;
}

.tw-border-t-red-600\/85 {
  border-top-color: rgb(220 38 38 / 0.85) !important;
}

.tw-border-t-red-600\/90 {
  border-top-color: rgb(220 38 38 / 0.9) !important;
}

.tw-border-t-red-600\/95 {
  border-top-color: rgb(220 38 38 / 0.95) !important;
}

.tw-border-t-red-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(185 28 28 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-700\/0 {
  border-top-color: rgb(185 28 28 / 0) !important;
}

.tw-border-t-red-700\/10 {
  border-top-color: rgb(185 28 28 / 0.1) !important;
}

.tw-border-t-red-700\/100 {
  border-top-color: rgb(185 28 28 / 1) !important;
}

.tw-border-t-red-700\/15 {
  border-top-color: rgb(185 28 28 / 0.15) !important;
}

.tw-border-t-red-700\/20 {
  border-top-color: rgb(185 28 28 / 0.2) !important;
}

.tw-border-t-red-700\/25 {
  border-top-color: rgb(185 28 28 / 0.25) !important;
}

.tw-border-t-red-700\/30 {
  border-top-color: rgb(185 28 28 / 0.3) !important;
}

.tw-border-t-red-700\/35 {
  border-top-color: rgb(185 28 28 / 0.35) !important;
}

.tw-border-t-red-700\/40 {
  border-top-color: rgb(185 28 28 / 0.4) !important;
}

.tw-border-t-red-700\/45 {
  border-top-color: rgb(185 28 28 / 0.45) !important;
}

.tw-border-t-red-700\/5 {
  border-top-color: rgb(185 28 28 / 0.05) !important;
}

.tw-border-t-red-700\/50 {
  border-top-color: rgb(185 28 28 / 0.5) !important;
}

.tw-border-t-red-700\/55 {
  border-top-color: rgb(185 28 28 / 0.55) !important;
}

.tw-border-t-red-700\/60 {
  border-top-color: rgb(185 28 28 / 0.6) !important;
}

.tw-border-t-red-700\/65 {
  border-top-color: rgb(185 28 28 / 0.65) !important;
}

.tw-border-t-red-700\/70 {
  border-top-color: rgb(185 28 28 / 0.7) !important;
}

.tw-border-t-red-700\/75 {
  border-top-color: rgb(185 28 28 / 0.75) !important;
}

.tw-border-t-red-700\/80 {
  border-top-color: rgb(185 28 28 / 0.8) !important;
}

.tw-border-t-red-700\/85 {
  border-top-color: rgb(185 28 28 / 0.85) !important;
}

.tw-border-t-red-700\/90 {
  border-top-color: rgb(185 28 28 / 0.9) !important;
}

.tw-border-t-red-700\/95 {
  border-top-color: rgb(185 28 28 / 0.95) !important;
}

.tw-border-t-red-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(153 27 27 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-800\/0 {
  border-top-color: rgb(153 27 27 / 0) !important;
}

.tw-border-t-red-800\/10 {
  border-top-color: rgb(153 27 27 / 0.1) !important;
}

.tw-border-t-red-800\/100 {
  border-top-color: rgb(153 27 27 / 1) !important;
}

.tw-border-t-red-800\/15 {
  border-top-color: rgb(153 27 27 / 0.15) !important;
}

.tw-border-t-red-800\/20 {
  border-top-color: rgb(153 27 27 / 0.2) !important;
}

.tw-border-t-red-800\/25 {
  border-top-color: rgb(153 27 27 / 0.25) !important;
}

.tw-border-t-red-800\/30 {
  border-top-color: rgb(153 27 27 / 0.3) !important;
}

.tw-border-t-red-800\/35 {
  border-top-color: rgb(153 27 27 / 0.35) !important;
}

.tw-border-t-red-800\/40 {
  border-top-color: rgb(153 27 27 / 0.4) !important;
}

.tw-border-t-red-800\/45 {
  border-top-color: rgb(153 27 27 / 0.45) !important;
}

.tw-border-t-red-800\/5 {
  border-top-color: rgb(153 27 27 / 0.05) !important;
}

.tw-border-t-red-800\/50 {
  border-top-color: rgb(153 27 27 / 0.5) !important;
}

.tw-border-t-red-800\/55 {
  border-top-color: rgb(153 27 27 / 0.55) !important;
}

.tw-border-t-red-800\/60 {
  border-top-color: rgb(153 27 27 / 0.6) !important;
}

.tw-border-t-red-800\/65 {
  border-top-color: rgb(153 27 27 / 0.65) !important;
}

.tw-border-t-red-800\/70 {
  border-top-color: rgb(153 27 27 / 0.7) !important;
}

.tw-border-t-red-800\/75 {
  border-top-color: rgb(153 27 27 / 0.75) !important;
}

.tw-border-t-red-800\/80 {
  border-top-color: rgb(153 27 27 / 0.8) !important;
}

.tw-border-t-red-800\/85 {
  border-top-color: rgb(153 27 27 / 0.85) !important;
}

.tw-border-t-red-800\/90 {
  border-top-color: rgb(153 27 27 / 0.9) !important;
}

.tw-border-t-red-800\/95 {
  border-top-color: rgb(153 27 27 / 0.95) !important;
}

.tw-border-t-red-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(127 29 29 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-900\/0 {
  border-top-color: rgb(127 29 29 / 0) !important;
}

.tw-border-t-red-900\/10 {
  border-top-color: rgb(127 29 29 / 0.1) !important;
}

.tw-border-t-red-900\/100 {
  border-top-color: rgb(127 29 29 / 1) !important;
}

.tw-border-t-red-900\/15 {
  border-top-color: rgb(127 29 29 / 0.15) !important;
}

.tw-border-t-red-900\/20 {
  border-top-color: rgb(127 29 29 / 0.2) !important;
}

.tw-border-t-red-900\/25 {
  border-top-color: rgb(127 29 29 / 0.25) !important;
}

.tw-border-t-red-900\/30 {
  border-top-color: rgb(127 29 29 / 0.3) !important;
}

.tw-border-t-red-900\/35 {
  border-top-color: rgb(127 29 29 / 0.35) !important;
}

.tw-border-t-red-900\/40 {
  border-top-color: rgb(127 29 29 / 0.4) !important;
}

.tw-border-t-red-900\/45 {
  border-top-color: rgb(127 29 29 / 0.45) !important;
}

.tw-border-t-red-900\/5 {
  border-top-color: rgb(127 29 29 / 0.05) !important;
}

.tw-border-t-red-900\/50 {
  border-top-color: rgb(127 29 29 / 0.5) !important;
}

.tw-border-t-red-900\/55 {
  border-top-color: rgb(127 29 29 / 0.55) !important;
}

.tw-border-t-red-900\/60 {
  border-top-color: rgb(127 29 29 / 0.6) !important;
}

.tw-border-t-red-900\/65 {
  border-top-color: rgb(127 29 29 / 0.65) !important;
}

.tw-border-t-red-900\/70 {
  border-top-color: rgb(127 29 29 / 0.7) !important;
}

.tw-border-t-red-900\/75 {
  border-top-color: rgb(127 29 29 / 0.75) !important;
}

.tw-border-t-red-900\/80 {
  border-top-color: rgb(127 29 29 / 0.8) !important;
}

.tw-border-t-red-900\/85 {
  border-top-color: rgb(127 29 29 / 0.85) !important;
}

.tw-border-t-red-900\/90 {
  border-top-color: rgb(127 29 29 / 0.9) !important;
}

.tw-border-t-red-900\/95 {
  border-top-color: rgb(127 29 29 / 0.95) !important;
}

.tw-border-t-red-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(69 10 10 / var(--tw-border-opacity)) !important;
}

.tw-border-t-red-950\/0 {
  border-top-color: rgb(69 10 10 / 0) !important;
}

.tw-border-t-red-950\/10 {
  border-top-color: rgb(69 10 10 / 0.1) !important;
}

.tw-border-t-red-950\/100 {
  border-top-color: rgb(69 10 10 / 1) !important;
}

.tw-border-t-red-950\/15 {
  border-top-color: rgb(69 10 10 / 0.15) !important;
}

.tw-border-t-red-950\/20 {
  border-top-color: rgb(69 10 10 / 0.2) !important;
}

.tw-border-t-red-950\/25 {
  border-top-color: rgb(69 10 10 / 0.25) !important;
}

.tw-border-t-red-950\/30 {
  border-top-color: rgb(69 10 10 / 0.3) !important;
}

.tw-border-t-red-950\/35 {
  border-top-color: rgb(69 10 10 / 0.35) !important;
}

.tw-border-t-red-950\/40 {
  border-top-color: rgb(69 10 10 / 0.4) !important;
}

.tw-border-t-red-950\/45 {
  border-top-color: rgb(69 10 10 / 0.45) !important;
}

.tw-border-t-red-950\/5 {
  border-top-color: rgb(69 10 10 / 0.05) !important;
}

.tw-border-t-red-950\/50 {
  border-top-color: rgb(69 10 10 / 0.5) !important;
}

.tw-border-t-red-950\/55 {
  border-top-color: rgb(69 10 10 / 0.55) !important;
}

.tw-border-t-red-950\/60 {
  border-top-color: rgb(69 10 10 / 0.6) !important;
}

.tw-border-t-red-950\/65 {
  border-top-color: rgb(69 10 10 / 0.65) !important;
}

.tw-border-t-red-950\/70 {
  border-top-color: rgb(69 10 10 / 0.7) !important;
}

.tw-border-t-red-950\/75 {
  border-top-color: rgb(69 10 10 / 0.75) !important;
}

.tw-border-t-red-950\/80 {
  border-top-color: rgb(69 10 10 / 0.8) !important;
}

.tw-border-t-red-950\/85 {
  border-top-color: rgb(69 10 10 / 0.85) !important;
}

.tw-border-t-red-950\/90 {
  border-top-color: rgb(69 10 10 / 0.9) !important;
}

.tw-border-t-red-950\/95 {
  border-top-color: rgb(69 10 10 / 0.95) !important;
}

.tw-border-t-rose-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 228 230 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-100\/0 {
  border-top-color: rgb(255 228 230 / 0) !important;
}

.tw-border-t-rose-100\/10 {
  border-top-color: rgb(255 228 230 / 0.1) !important;
}

.tw-border-t-rose-100\/100 {
  border-top-color: rgb(255 228 230 / 1) !important;
}

.tw-border-t-rose-100\/15 {
  border-top-color: rgb(255 228 230 / 0.15) !important;
}

.tw-border-t-rose-100\/20 {
  border-top-color: rgb(255 228 230 / 0.2) !important;
}

.tw-border-t-rose-100\/25 {
  border-top-color: rgb(255 228 230 / 0.25) !important;
}

.tw-border-t-rose-100\/30 {
  border-top-color: rgb(255 228 230 / 0.3) !important;
}

.tw-border-t-rose-100\/35 {
  border-top-color: rgb(255 228 230 / 0.35) !important;
}

.tw-border-t-rose-100\/40 {
  border-top-color: rgb(255 228 230 / 0.4) !important;
}

.tw-border-t-rose-100\/45 {
  border-top-color: rgb(255 228 230 / 0.45) !important;
}

.tw-border-t-rose-100\/5 {
  border-top-color: rgb(255 228 230 / 0.05) !important;
}

.tw-border-t-rose-100\/50 {
  border-top-color: rgb(255 228 230 / 0.5) !important;
}

.tw-border-t-rose-100\/55 {
  border-top-color: rgb(255 228 230 / 0.55) !important;
}

.tw-border-t-rose-100\/60 {
  border-top-color: rgb(255 228 230 / 0.6) !important;
}

.tw-border-t-rose-100\/65 {
  border-top-color: rgb(255 228 230 / 0.65) !important;
}

.tw-border-t-rose-100\/70 {
  border-top-color: rgb(255 228 230 / 0.7) !important;
}

.tw-border-t-rose-100\/75 {
  border-top-color: rgb(255 228 230 / 0.75) !important;
}

.tw-border-t-rose-100\/80 {
  border-top-color: rgb(255 228 230 / 0.8) !important;
}

.tw-border-t-rose-100\/85 {
  border-top-color: rgb(255 228 230 / 0.85) !important;
}

.tw-border-t-rose-100\/90 {
  border-top-color: rgb(255 228 230 / 0.9) !important;
}

.tw-border-t-rose-100\/95 {
  border-top-color: rgb(255 228 230 / 0.95) !important;
}

.tw-border-t-rose-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 205 211 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-200\/0 {
  border-top-color: rgb(254 205 211 / 0) !important;
}

.tw-border-t-rose-200\/10 {
  border-top-color: rgb(254 205 211 / 0.1) !important;
}

.tw-border-t-rose-200\/100 {
  border-top-color: rgb(254 205 211 / 1) !important;
}

.tw-border-t-rose-200\/15 {
  border-top-color: rgb(254 205 211 / 0.15) !important;
}

.tw-border-t-rose-200\/20 {
  border-top-color: rgb(254 205 211 / 0.2) !important;
}

.tw-border-t-rose-200\/25 {
  border-top-color: rgb(254 205 211 / 0.25) !important;
}

.tw-border-t-rose-200\/30 {
  border-top-color: rgb(254 205 211 / 0.3) !important;
}

.tw-border-t-rose-200\/35 {
  border-top-color: rgb(254 205 211 / 0.35) !important;
}

.tw-border-t-rose-200\/40 {
  border-top-color: rgb(254 205 211 / 0.4) !important;
}

.tw-border-t-rose-200\/45 {
  border-top-color: rgb(254 205 211 / 0.45) !important;
}

.tw-border-t-rose-200\/5 {
  border-top-color: rgb(254 205 211 / 0.05) !important;
}

.tw-border-t-rose-200\/50 {
  border-top-color: rgb(254 205 211 / 0.5) !important;
}

.tw-border-t-rose-200\/55 {
  border-top-color: rgb(254 205 211 / 0.55) !important;
}

.tw-border-t-rose-200\/60 {
  border-top-color: rgb(254 205 211 / 0.6) !important;
}

.tw-border-t-rose-200\/65 {
  border-top-color: rgb(254 205 211 / 0.65) !important;
}

.tw-border-t-rose-200\/70 {
  border-top-color: rgb(254 205 211 / 0.7) !important;
}

.tw-border-t-rose-200\/75 {
  border-top-color: rgb(254 205 211 / 0.75) !important;
}

.tw-border-t-rose-200\/80 {
  border-top-color: rgb(254 205 211 / 0.8) !important;
}

.tw-border-t-rose-200\/85 {
  border-top-color: rgb(254 205 211 / 0.85) !important;
}

.tw-border-t-rose-200\/90 {
  border-top-color: rgb(254 205 211 / 0.9) !important;
}

.tw-border-t-rose-200\/95 {
  border-top-color: rgb(254 205 211 / 0.95) !important;
}

.tw-border-t-rose-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 164 175 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-300\/0 {
  border-top-color: rgb(253 164 175 / 0) !important;
}

.tw-border-t-rose-300\/10 {
  border-top-color: rgb(253 164 175 / 0.1) !important;
}

.tw-border-t-rose-300\/100 {
  border-top-color: rgb(253 164 175 / 1) !important;
}

.tw-border-t-rose-300\/15 {
  border-top-color: rgb(253 164 175 / 0.15) !important;
}

.tw-border-t-rose-300\/20 {
  border-top-color: rgb(253 164 175 / 0.2) !important;
}

.tw-border-t-rose-300\/25 {
  border-top-color: rgb(253 164 175 / 0.25) !important;
}

.tw-border-t-rose-300\/30 {
  border-top-color: rgb(253 164 175 / 0.3) !important;
}

.tw-border-t-rose-300\/35 {
  border-top-color: rgb(253 164 175 / 0.35) !important;
}

.tw-border-t-rose-300\/40 {
  border-top-color: rgb(253 164 175 / 0.4) !important;
}

.tw-border-t-rose-300\/45 {
  border-top-color: rgb(253 164 175 / 0.45) !important;
}

.tw-border-t-rose-300\/5 {
  border-top-color: rgb(253 164 175 / 0.05) !important;
}

.tw-border-t-rose-300\/50 {
  border-top-color: rgb(253 164 175 / 0.5) !important;
}

.tw-border-t-rose-300\/55 {
  border-top-color: rgb(253 164 175 / 0.55) !important;
}

.tw-border-t-rose-300\/60 {
  border-top-color: rgb(253 164 175 / 0.6) !important;
}

.tw-border-t-rose-300\/65 {
  border-top-color: rgb(253 164 175 / 0.65) !important;
}

.tw-border-t-rose-300\/70 {
  border-top-color: rgb(253 164 175 / 0.7) !important;
}

.tw-border-t-rose-300\/75 {
  border-top-color: rgb(253 164 175 / 0.75) !important;
}

.tw-border-t-rose-300\/80 {
  border-top-color: rgb(253 164 175 / 0.8) !important;
}

.tw-border-t-rose-300\/85 {
  border-top-color: rgb(253 164 175 / 0.85) !important;
}

.tw-border-t-rose-300\/90 {
  border-top-color: rgb(253 164 175 / 0.9) !important;
}

.tw-border-t-rose-300\/95 {
  border-top-color: rgb(253 164 175 / 0.95) !important;
}

.tw-border-t-rose-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(251 113 133 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-400\/0 {
  border-top-color: rgb(251 113 133 / 0) !important;
}

.tw-border-t-rose-400\/10 {
  border-top-color: rgb(251 113 133 / 0.1) !important;
}

.tw-border-t-rose-400\/100 {
  border-top-color: rgb(251 113 133 / 1) !important;
}

.tw-border-t-rose-400\/15 {
  border-top-color: rgb(251 113 133 / 0.15) !important;
}

.tw-border-t-rose-400\/20 {
  border-top-color: rgb(251 113 133 / 0.2) !important;
}

.tw-border-t-rose-400\/25 {
  border-top-color: rgb(251 113 133 / 0.25) !important;
}

.tw-border-t-rose-400\/30 {
  border-top-color: rgb(251 113 133 / 0.3) !important;
}

.tw-border-t-rose-400\/35 {
  border-top-color: rgb(251 113 133 / 0.35) !important;
}

.tw-border-t-rose-400\/40 {
  border-top-color: rgb(251 113 133 / 0.4) !important;
}

.tw-border-t-rose-400\/45 {
  border-top-color: rgb(251 113 133 / 0.45) !important;
}

.tw-border-t-rose-400\/5 {
  border-top-color: rgb(251 113 133 / 0.05) !important;
}

.tw-border-t-rose-400\/50 {
  border-top-color: rgb(251 113 133 / 0.5) !important;
}

.tw-border-t-rose-400\/55 {
  border-top-color: rgb(251 113 133 / 0.55) !important;
}

.tw-border-t-rose-400\/60 {
  border-top-color: rgb(251 113 133 / 0.6) !important;
}

.tw-border-t-rose-400\/65 {
  border-top-color: rgb(251 113 133 / 0.65) !important;
}

.tw-border-t-rose-400\/70 {
  border-top-color: rgb(251 113 133 / 0.7) !important;
}

.tw-border-t-rose-400\/75 {
  border-top-color: rgb(251 113 133 / 0.75) !important;
}

.tw-border-t-rose-400\/80 {
  border-top-color: rgb(251 113 133 / 0.8) !important;
}

.tw-border-t-rose-400\/85 {
  border-top-color: rgb(251 113 133 / 0.85) !important;
}

.tw-border-t-rose-400\/90 {
  border-top-color: rgb(251 113 133 / 0.9) !important;
}

.tw-border-t-rose-400\/95 {
  border-top-color: rgb(251 113 133 / 0.95) !important;
}

.tw-border-t-rose-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 241 242 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-50\/0 {
  border-top-color: rgb(255 241 242 / 0) !important;
}

.tw-border-t-rose-50\/10 {
  border-top-color: rgb(255 241 242 / 0.1) !important;
}

.tw-border-t-rose-50\/100 {
  border-top-color: rgb(255 241 242 / 1) !important;
}

.tw-border-t-rose-50\/15 {
  border-top-color: rgb(255 241 242 / 0.15) !important;
}

.tw-border-t-rose-50\/20 {
  border-top-color: rgb(255 241 242 / 0.2) !important;
}

.tw-border-t-rose-50\/25 {
  border-top-color: rgb(255 241 242 / 0.25) !important;
}

.tw-border-t-rose-50\/30 {
  border-top-color: rgb(255 241 242 / 0.3) !important;
}

.tw-border-t-rose-50\/35 {
  border-top-color: rgb(255 241 242 / 0.35) !important;
}

.tw-border-t-rose-50\/40 {
  border-top-color: rgb(255 241 242 / 0.4) !important;
}

.tw-border-t-rose-50\/45 {
  border-top-color: rgb(255 241 242 / 0.45) !important;
}

.tw-border-t-rose-50\/5 {
  border-top-color: rgb(255 241 242 / 0.05) !important;
}

.tw-border-t-rose-50\/50 {
  border-top-color: rgb(255 241 242 / 0.5) !important;
}

.tw-border-t-rose-50\/55 {
  border-top-color: rgb(255 241 242 / 0.55) !important;
}

.tw-border-t-rose-50\/60 {
  border-top-color: rgb(255 241 242 / 0.6) !important;
}

.tw-border-t-rose-50\/65 {
  border-top-color: rgb(255 241 242 / 0.65) !important;
}

.tw-border-t-rose-50\/70 {
  border-top-color: rgb(255 241 242 / 0.7) !important;
}

.tw-border-t-rose-50\/75 {
  border-top-color: rgb(255 241 242 / 0.75) !important;
}

.tw-border-t-rose-50\/80 {
  border-top-color: rgb(255 241 242 / 0.8) !important;
}

.tw-border-t-rose-50\/85 {
  border-top-color: rgb(255 241 242 / 0.85) !important;
}

.tw-border-t-rose-50\/90 {
  border-top-color: rgb(255 241 242 / 0.9) !important;
}

.tw-border-t-rose-50\/95 {
  border-top-color: rgb(255 241 242 / 0.95) !important;
}

.tw-border-t-rose-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(244 63 94 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-500\/0 {
  border-top-color: rgb(244 63 94 / 0) !important;
}

.tw-border-t-rose-500\/10 {
  border-top-color: rgb(244 63 94 / 0.1) !important;
}

.tw-border-t-rose-500\/100 {
  border-top-color: rgb(244 63 94 / 1) !important;
}

.tw-border-t-rose-500\/15 {
  border-top-color: rgb(244 63 94 / 0.15) !important;
}

.tw-border-t-rose-500\/20 {
  border-top-color: rgb(244 63 94 / 0.2) !important;
}

.tw-border-t-rose-500\/25 {
  border-top-color: rgb(244 63 94 / 0.25) !important;
}

.tw-border-t-rose-500\/30 {
  border-top-color: rgb(244 63 94 / 0.3) !important;
}

.tw-border-t-rose-500\/35 {
  border-top-color: rgb(244 63 94 / 0.35) !important;
}

.tw-border-t-rose-500\/40 {
  border-top-color: rgb(244 63 94 / 0.4) !important;
}

.tw-border-t-rose-500\/45 {
  border-top-color: rgb(244 63 94 / 0.45) !important;
}

.tw-border-t-rose-500\/5 {
  border-top-color: rgb(244 63 94 / 0.05) !important;
}

.tw-border-t-rose-500\/50 {
  border-top-color: rgb(244 63 94 / 0.5) !important;
}

.tw-border-t-rose-500\/55 {
  border-top-color: rgb(244 63 94 / 0.55) !important;
}

.tw-border-t-rose-500\/60 {
  border-top-color: rgb(244 63 94 / 0.6) !important;
}

.tw-border-t-rose-500\/65 {
  border-top-color: rgb(244 63 94 / 0.65) !important;
}

.tw-border-t-rose-500\/70 {
  border-top-color: rgb(244 63 94 / 0.7) !important;
}

.tw-border-t-rose-500\/75 {
  border-top-color: rgb(244 63 94 / 0.75) !important;
}

.tw-border-t-rose-500\/80 {
  border-top-color: rgb(244 63 94 / 0.8) !important;
}

.tw-border-t-rose-500\/85 {
  border-top-color: rgb(244 63 94 / 0.85) !important;
}

.tw-border-t-rose-500\/90 {
  border-top-color: rgb(244 63 94 / 0.9) !important;
}

.tw-border-t-rose-500\/95 {
  border-top-color: rgb(244 63 94 / 0.95) !important;
}

.tw-border-t-rose-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(225 29 72 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-600\/0 {
  border-top-color: rgb(225 29 72 / 0) !important;
}

.tw-border-t-rose-600\/10 {
  border-top-color: rgb(225 29 72 / 0.1) !important;
}

.tw-border-t-rose-600\/100 {
  border-top-color: rgb(225 29 72 / 1) !important;
}

.tw-border-t-rose-600\/15 {
  border-top-color: rgb(225 29 72 / 0.15) !important;
}

.tw-border-t-rose-600\/20 {
  border-top-color: rgb(225 29 72 / 0.2) !important;
}

.tw-border-t-rose-600\/25 {
  border-top-color: rgb(225 29 72 / 0.25) !important;
}

.tw-border-t-rose-600\/30 {
  border-top-color: rgb(225 29 72 / 0.3) !important;
}

.tw-border-t-rose-600\/35 {
  border-top-color: rgb(225 29 72 / 0.35) !important;
}

.tw-border-t-rose-600\/40 {
  border-top-color: rgb(225 29 72 / 0.4) !important;
}

.tw-border-t-rose-600\/45 {
  border-top-color: rgb(225 29 72 / 0.45) !important;
}

.tw-border-t-rose-600\/5 {
  border-top-color: rgb(225 29 72 / 0.05) !important;
}

.tw-border-t-rose-600\/50 {
  border-top-color: rgb(225 29 72 / 0.5) !important;
}

.tw-border-t-rose-600\/55 {
  border-top-color: rgb(225 29 72 / 0.55) !important;
}

.tw-border-t-rose-600\/60 {
  border-top-color: rgb(225 29 72 / 0.6) !important;
}

.tw-border-t-rose-600\/65 {
  border-top-color: rgb(225 29 72 / 0.65) !important;
}

.tw-border-t-rose-600\/70 {
  border-top-color: rgb(225 29 72 / 0.7) !important;
}

.tw-border-t-rose-600\/75 {
  border-top-color: rgb(225 29 72 / 0.75) !important;
}

.tw-border-t-rose-600\/80 {
  border-top-color: rgb(225 29 72 / 0.8) !important;
}

.tw-border-t-rose-600\/85 {
  border-top-color: rgb(225 29 72 / 0.85) !important;
}

.tw-border-t-rose-600\/90 {
  border-top-color: rgb(225 29 72 / 0.9) !important;
}

.tw-border-t-rose-600\/95 {
  border-top-color: rgb(225 29 72 / 0.95) !important;
}

.tw-border-t-rose-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(190 18 60 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-700\/0 {
  border-top-color: rgb(190 18 60 / 0) !important;
}

.tw-border-t-rose-700\/10 {
  border-top-color: rgb(190 18 60 / 0.1) !important;
}

.tw-border-t-rose-700\/100 {
  border-top-color: rgb(190 18 60 / 1) !important;
}

.tw-border-t-rose-700\/15 {
  border-top-color: rgb(190 18 60 / 0.15) !important;
}

.tw-border-t-rose-700\/20 {
  border-top-color: rgb(190 18 60 / 0.2) !important;
}

.tw-border-t-rose-700\/25 {
  border-top-color: rgb(190 18 60 / 0.25) !important;
}

.tw-border-t-rose-700\/30 {
  border-top-color: rgb(190 18 60 / 0.3) !important;
}

.tw-border-t-rose-700\/35 {
  border-top-color: rgb(190 18 60 / 0.35) !important;
}

.tw-border-t-rose-700\/40 {
  border-top-color: rgb(190 18 60 / 0.4) !important;
}

.tw-border-t-rose-700\/45 {
  border-top-color: rgb(190 18 60 / 0.45) !important;
}

.tw-border-t-rose-700\/5 {
  border-top-color: rgb(190 18 60 / 0.05) !important;
}

.tw-border-t-rose-700\/50 {
  border-top-color: rgb(190 18 60 / 0.5) !important;
}

.tw-border-t-rose-700\/55 {
  border-top-color: rgb(190 18 60 / 0.55) !important;
}

.tw-border-t-rose-700\/60 {
  border-top-color: rgb(190 18 60 / 0.6) !important;
}

.tw-border-t-rose-700\/65 {
  border-top-color: rgb(190 18 60 / 0.65) !important;
}

.tw-border-t-rose-700\/70 {
  border-top-color: rgb(190 18 60 / 0.7) !important;
}

.tw-border-t-rose-700\/75 {
  border-top-color: rgb(190 18 60 / 0.75) !important;
}

.tw-border-t-rose-700\/80 {
  border-top-color: rgb(190 18 60 / 0.8) !important;
}

.tw-border-t-rose-700\/85 {
  border-top-color: rgb(190 18 60 / 0.85) !important;
}

.tw-border-t-rose-700\/90 {
  border-top-color: rgb(190 18 60 / 0.9) !important;
}

.tw-border-t-rose-700\/95 {
  border-top-color: rgb(190 18 60 / 0.95) !important;
}

.tw-border-t-rose-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(159 18 57 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-800\/0 {
  border-top-color: rgb(159 18 57 / 0) !important;
}

.tw-border-t-rose-800\/10 {
  border-top-color: rgb(159 18 57 / 0.1) !important;
}

.tw-border-t-rose-800\/100 {
  border-top-color: rgb(159 18 57 / 1) !important;
}

.tw-border-t-rose-800\/15 {
  border-top-color: rgb(159 18 57 / 0.15) !important;
}

.tw-border-t-rose-800\/20 {
  border-top-color: rgb(159 18 57 / 0.2) !important;
}

.tw-border-t-rose-800\/25 {
  border-top-color: rgb(159 18 57 / 0.25) !important;
}

.tw-border-t-rose-800\/30 {
  border-top-color: rgb(159 18 57 / 0.3) !important;
}

.tw-border-t-rose-800\/35 {
  border-top-color: rgb(159 18 57 / 0.35) !important;
}

.tw-border-t-rose-800\/40 {
  border-top-color: rgb(159 18 57 / 0.4) !important;
}

.tw-border-t-rose-800\/45 {
  border-top-color: rgb(159 18 57 / 0.45) !important;
}

.tw-border-t-rose-800\/5 {
  border-top-color: rgb(159 18 57 / 0.05) !important;
}

.tw-border-t-rose-800\/50 {
  border-top-color: rgb(159 18 57 / 0.5) !important;
}

.tw-border-t-rose-800\/55 {
  border-top-color: rgb(159 18 57 / 0.55) !important;
}

.tw-border-t-rose-800\/60 {
  border-top-color: rgb(159 18 57 / 0.6) !important;
}

.tw-border-t-rose-800\/65 {
  border-top-color: rgb(159 18 57 / 0.65) !important;
}

.tw-border-t-rose-800\/70 {
  border-top-color: rgb(159 18 57 / 0.7) !important;
}

.tw-border-t-rose-800\/75 {
  border-top-color: rgb(159 18 57 / 0.75) !important;
}

.tw-border-t-rose-800\/80 {
  border-top-color: rgb(159 18 57 / 0.8) !important;
}

.tw-border-t-rose-800\/85 {
  border-top-color: rgb(159 18 57 / 0.85) !important;
}

.tw-border-t-rose-800\/90 {
  border-top-color: rgb(159 18 57 / 0.9) !important;
}

.tw-border-t-rose-800\/95 {
  border-top-color: rgb(159 18 57 / 0.95) !important;
}

.tw-border-t-rose-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(136 19 55 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-900\/0 {
  border-top-color: rgb(136 19 55 / 0) !important;
}

.tw-border-t-rose-900\/10 {
  border-top-color: rgb(136 19 55 / 0.1) !important;
}

.tw-border-t-rose-900\/100 {
  border-top-color: rgb(136 19 55 / 1) !important;
}

.tw-border-t-rose-900\/15 {
  border-top-color: rgb(136 19 55 / 0.15) !important;
}

.tw-border-t-rose-900\/20 {
  border-top-color: rgb(136 19 55 / 0.2) !important;
}

.tw-border-t-rose-900\/25 {
  border-top-color: rgb(136 19 55 / 0.25) !important;
}

.tw-border-t-rose-900\/30 {
  border-top-color: rgb(136 19 55 / 0.3) !important;
}

.tw-border-t-rose-900\/35 {
  border-top-color: rgb(136 19 55 / 0.35) !important;
}

.tw-border-t-rose-900\/40 {
  border-top-color: rgb(136 19 55 / 0.4) !important;
}

.tw-border-t-rose-900\/45 {
  border-top-color: rgb(136 19 55 / 0.45) !important;
}

.tw-border-t-rose-900\/5 {
  border-top-color: rgb(136 19 55 / 0.05) !important;
}

.tw-border-t-rose-900\/50 {
  border-top-color: rgb(136 19 55 / 0.5) !important;
}

.tw-border-t-rose-900\/55 {
  border-top-color: rgb(136 19 55 / 0.55) !important;
}

.tw-border-t-rose-900\/60 {
  border-top-color: rgb(136 19 55 / 0.6) !important;
}

.tw-border-t-rose-900\/65 {
  border-top-color: rgb(136 19 55 / 0.65) !important;
}

.tw-border-t-rose-900\/70 {
  border-top-color: rgb(136 19 55 / 0.7) !important;
}

.tw-border-t-rose-900\/75 {
  border-top-color: rgb(136 19 55 / 0.75) !important;
}

.tw-border-t-rose-900\/80 {
  border-top-color: rgb(136 19 55 / 0.8) !important;
}

.tw-border-t-rose-900\/85 {
  border-top-color: rgb(136 19 55 / 0.85) !important;
}

.tw-border-t-rose-900\/90 {
  border-top-color: rgb(136 19 55 / 0.9) !important;
}

.tw-border-t-rose-900\/95 {
  border-top-color: rgb(136 19 55 / 0.95) !important;
}

.tw-border-t-rose-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(76 5 25 / var(--tw-border-opacity)) !important;
}

.tw-border-t-rose-950\/0 {
  border-top-color: rgb(76 5 25 / 0) !important;
}

.tw-border-t-rose-950\/10 {
  border-top-color: rgb(76 5 25 / 0.1) !important;
}

.tw-border-t-rose-950\/100 {
  border-top-color: rgb(76 5 25 / 1) !important;
}

.tw-border-t-rose-950\/15 {
  border-top-color: rgb(76 5 25 / 0.15) !important;
}

.tw-border-t-rose-950\/20 {
  border-top-color: rgb(76 5 25 / 0.2) !important;
}

.tw-border-t-rose-950\/25 {
  border-top-color: rgb(76 5 25 / 0.25) !important;
}

.tw-border-t-rose-950\/30 {
  border-top-color: rgb(76 5 25 / 0.3) !important;
}

.tw-border-t-rose-950\/35 {
  border-top-color: rgb(76 5 25 / 0.35) !important;
}

.tw-border-t-rose-950\/40 {
  border-top-color: rgb(76 5 25 / 0.4) !important;
}

.tw-border-t-rose-950\/45 {
  border-top-color: rgb(76 5 25 / 0.45) !important;
}

.tw-border-t-rose-950\/5 {
  border-top-color: rgb(76 5 25 / 0.05) !important;
}

.tw-border-t-rose-950\/50 {
  border-top-color: rgb(76 5 25 / 0.5) !important;
}

.tw-border-t-rose-950\/55 {
  border-top-color: rgb(76 5 25 / 0.55) !important;
}

.tw-border-t-rose-950\/60 {
  border-top-color: rgb(76 5 25 / 0.6) !important;
}

.tw-border-t-rose-950\/65 {
  border-top-color: rgb(76 5 25 / 0.65) !important;
}

.tw-border-t-rose-950\/70 {
  border-top-color: rgb(76 5 25 / 0.7) !important;
}

.tw-border-t-rose-950\/75 {
  border-top-color: rgb(76 5 25 / 0.75) !important;
}

.tw-border-t-rose-950\/80 {
  border-top-color: rgb(76 5 25 / 0.8) !important;
}

.tw-border-t-rose-950\/85 {
  border-top-color: rgb(76 5 25 / 0.85) !important;
}

.tw-border-t-rose-950\/90 {
  border-top-color: rgb(76 5 25 / 0.9) !important;
}

.tw-border-t-rose-950\/95 {
  border-top-color: rgb(76 5 25 / 0.95) !important;
}

.tw-border-t-sky-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(224 242 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-100\/0 {
  border-top-color: rgb(224 242 254 / 0) !important;
}

.tw-border-t-sky-100\/10 {
  border-top-color: rgb(224 242 254 / 0.1) !important;
}

.tw-border-t-sky-100\/100 {
  border-top-color: rgb(224 242 254 / 1) !important;
}

.tw-border-t-sky-100\/15 {
  border-top-color: rgb(224 242 254 / 0.15) !important;
}

.tw-border-t-sky-100\/20 {
  border-top-color: rgb(224 242 254 / 0.2) !important;
}

.tw-border-t-sky-100\/25 {
  border-top-color: rgb(224 242 254 / 0.25) !important;
}

.tw-border-t-sky-100\/30 {
  border-top-color: rgb(224 242 254 / 0.3) !important;
}

.tw-border-t-sky-100\/35 {
  border-top-color: rgb(224 242 254 / 0.35) !important;
}

.tw-border-t-sky-100\/40 {
  border-top-color: rgb(224 242 254 / 0.4) !important;
}

.tw-border-t-sky-100\/45 {
  border-top-color: rgb(224 242 254 / 0.45) !important;
}

.tw-border-t-sky-100\/5 {
  border-top-color: rgb(224 242 254 / 0.05) !important;
}

.tw-border-t-sky-100\/50 {
  border-top-color: rgb(224 242 254 / 0.5) !important;
}

.tw-border-t-sky-100\/55 {
  border-top-color: rgb(224 242 254 / 0.55) !important;
}

.tw-border-t-sky-100\/60 {
  border-top-color: rgb(224 242 254 / 0.6) !important;
}

.tw-border-t-sky-100\/65 {
  border-top-color: rgb(224 242 254 / 0.65) !important;
}

.tw-border-t-sky-100\/70 {
  border-top-color: rgb(224 242 254 / 0.7) !important;
}

.tw-border-t-sky-100\/75 {
  border-top-color: rgb(224 242 254 / 0.75) !important;
}

.tw-border-t-sky-100\/80 {
  border-top-color: rgb(224 242 254 / 0.8) !important;
}

.tw-border-t-sky-100\/85 {
  border-top-color: rgb(224 242 254 / 0.85) !important;
}

.tw-border-t-sky-100\/90 {
  border-top-color: rgb(224 242 254 / 0.9) !important;
}

.tw-border-t-sky-100\/95 {
  border-top-color: rgb(224 242 254 / 0.95) !important;
}

.tw-border-t-sky-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(186 230 253 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-200\/0 {
  border-top-color: rgb(186 230 253 / 0) !important;
}

.tw-border-t-sky-200\/10 {
  border-top-color: rgb(186 230 253 / 0.1) !important;
}

.tw-border-t-sky-200\/100 {
  border-top-color: rgb(186 230 253 / 1) !important;
}

.tw-border-t-sky-200\/15 {
  border-top-color: rgb(186 230 253 / 0.15) !important;
}

.tw-border-t-sky-200\/20 {
  border-top-color: rgb(186 230 253 / 0.2) !important;
}

.tw-border-t-sky-200\/25 {
  border-top-color: rgb(186 230 253 / 0.25) !important;
}

.tw-border-t-sky-200\/30 {
  border-top-color: rgb(186 230 253 / 0.3) !important;
}

.tw-border-t-sky-200\/35 {
  border-top-color: rgb(186 230 253 / 0.35) !important;
}

.tw-border-t-sky-200\/40 {
  border-top-color: rgb(186 230 253 / 0.4) !important;
}

.tw-border-t-sky-200\/45 {
  border-top-color: rgb(186 230 253 / 0.45) !important;
}

.tw-border-t-sky-200\/5 {
  border-top-color: rgb(186 230 253 / 0.05) !important;
}

.tw-border-t-sky-200\/50 {
  border-top-color: rgb(186 230 253 / 0.5) !important;
}

.tw-border-t-sky-200\/55 {
  border-top-color: rgb(186 230 253 / 0.55) !important;
}

.tw-border-t-sky-200\/60 {
  border-top-color: rgb(186 230 253 / 0.6) !important;
}

.tw-border-t-sky-200\/65 {
  border-top-color: rgb(186 230 253 / 0.65) !important;
}

.tw-border-t-sky-200\/70 {
  border-top-color: rgb(186 230 253 / 0.7) !important;
}

.tw-border-t-sky-200\/75 {
  border-top-color: rgb(186 230 253 / 0.75) !important;
}

.tw-border-t-sky-200\/80 {
  border-top-color: rgb(186 230 253 / 0.8) !important;
}

.tw-border-t-sky-200\/85 {
  border-top-color: rgb(186 230 253 / 0.85) !important;
}

.tw-border-t-sky-200\/90 {
  border-top-color: rgb(186 230 253 / 0.9) !important;
}

.tw-border-t-sky-200\/95 {
  border-top-color: rgb(186 230 253 / 0.95) !important;
}

.tw-border-t-sky-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(125 211 252 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-300\/0 {
  border-top-color: rgb(125 211 252 / 0) !important;
}

.tw-border-t-sky-300\/10 {
  border-top-color: rgb(125 211 252 / 0.1) !important;
}

.tw-border-t-sky-300\/100 {
  border-top-color: rgb(125 211 252 / 1) !important;
}

.tw-border-t-sky-300\/15 {
  border-top-color: rgb(125 211 252 / 0.15) !important;
}

.tw-border-t-sky-300\/20 {
  border-top-color: rgb(125 211 252 / 0.2) !important;
}

.tw-border-t-sky-300\/25 {
  border-top-color: rgb(125 211 252 / 0.25) !important;
}

.tw-border-t-sky-300\/30 {
  border-top-color: rgb(125 211 252 / 0.3) !important;
}

.tw-border-t-sky-300\/35 {
  border-top-color: rgb(125 211 252 / 0.35) !important;
}

.tw-border-t-sky-300\/40 {
  border-top-color: rgb(125 211 252 / 0.4) !important;
}

.tw-border-t-sky-300\/45 {
  border-top-color: rgb(125 211 252 / 0.45) !important;
}

.tw-border-t-sky-300\/5 {
  border-top-color: rgb(125 211 252 / 0.05) !important;
}

.tw-border-t-sky-300\/50 {
  border-top-color: rgb(125 211 252 / 0.5) !important;
}

.tw-border-t-sky-300\/55 {
  border-top-color: rgb(125 211 252 / 0.55) !important;
}

.tw-border-t-sky-300\/60 {
  border-top-color: rgb(125 211 252 / 0.6) !important;
}

.tw-border-t-sky-300\/65 {
  border-top-color: rgb(125 211 252 / 0.65) !important;
}

.tw-border-t-sky-300\/70 {
  border-top-color: rgb(125 211 252 / 0.7) !important;
}

.tw-border-t-sky-300\/75 {
  border-top-color: rgb(125 211 252 / 0.75) !important;
}

.tw-border-t-sky-300\/80 {
  border-top-color: rgb(125 211 252 / 0.8) !important;
}

.tw-border-t-sky-300\/85 {
  border-top-color: rgb(125 211 252 / 0.85) !important;
}

.tw-border-t-sky-300\/90 {
  border-top-color: rgb(125 211 252 / 0.9) !important;
}

.tw-border-t-sky-300\/95 {
  border-top-color: rgb(125 211 252 / 0.95) !important;
}

.tw-border-t-sky-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(56 189 248 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-400\/0 {
  border-top-color: rgb(56 189 248 / 0) !important;
}

.tw-border-t-sky-400\/10 {
  border-top-color: rgb(56 189 248 / 0.1) !important;
}

.tw-border-t-sky-400\/100 {
  border-top-color: rgb(56 189 248 / 1) !important;
}

.tw-border-t-sky-400\/15 {
  border-top-color: rgb(56 189 248 / 0.15) !important;
}

.tw-border-t-sky-400\/20 {
  border-top-color: rgb(56 189 248 / 0.2) !important;
}

.tw-border-t-sky-400\/25 {
  border-top-color: rgb(56 189 248 / 0.25) !important;
}

.tw-border-t-sky-400\/30 {
  border-top-color: rgb(56 189 248 / 0.3) !important;
}

.tw-border-t-sky-400\/35 {
  border-top-color: rgb(56 189 248 / 0.35) !important;
}

.tw-border-t-sky-400\/40 {
  border-top-color: rgb(56 189 248 / 0.4) !important;
}

.tw-border-t-sky-400\/45 {
  border-top-color: rgb(56 189 248 / 0.45) !important;
}

.tw-border-t-sky-400\/5 {
  border-top-color: rgb(56 189 248 / 0.05) !important;
}

.tw-border-t-sky-400\/50 {
  border-top-color: rgb(56 189 248 / 0.5) !important;
}

.tw-border-t-sky-400\/55 {
  border-top-color: rgb(56 189 248 / 0.55) !important;
}

.tw-border-t-sky-400\/60 {
  border-top-color: rgb(56 189 248 / 0.6) !important;
}

.tw-border-t-sky-400\/65 {
  border-top-color: rgb(56 189 248 / 0.65) !important;
}

.tw-border-t-sky-400\/70 {
  border-top-color: rgb(56 189 248 / 0.7) !important;
}

.tw-border-t-sky-400\/75 {
  border-top-color: rgb(56 189 248 / 0.75) !important;
}

.tw-border-t-sky-400\/80 {
  border-top-color: rgb(56 189 248 / 0.8) !important;
}

.tw-border-t-sky-400\/85 {
  border-top-color: rgb(56 189 248 / 0.85) !important;
}

.tw-border-t-sky-400\/90 {
  border-top-color: rgb(56 189 248 / 0.9) !important;
}

.tw-border-t-sky-400\/95 {
  border-top-color: rgb(56 189 248 / 0.95) !important;
}

.tw-border-t-sky-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 249 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-50\/0 {
  border-top-color: rgb(240 249 255 / 0) !important;
}

.tw-border-t-sky-50\/10 {
  border-top-color: rgb(240 249 255 / 0.1) !important;
}

.tw-border-t-sky-50\/100 {
  border-top-color: rgb(240 249 255 / 1) !important;
}

.tw-border-t-sky-50\/15 {
  border-top-color: rgb(240 249 255 / 0.15) !important;
}

.tw-border-t-sky-50\/20 {
  border-top-color: rgb(240 249 255 / 0.2) !important;
}

.tw-border-t-sky-50\/25 {
  border-top-color: rgb(240 249 255 / 0.25) !important;
}

.tw-border-t-sky-50\/30 {
  border-top-color: rgb(240 249 255 / 0.3) !important;
}

.tw-border-t-sky-50\/35 {
  border-top-color: rgb(240 249 255 / 0.35) !important;
}

.tw-border-t-sky-50\/40 {
  border-top-color: rgb(240 249 255 / 0.4) !important;
}

.tw-border-t-sky-50\/45 {
  border-top-color: rgb(240 249 255 / 0.45) !important;
}

.tw-border-t-sky-50\/5 {
  border-top-color: rgb(240 249 255 / 0.05) !important;
}

.tw-border-t-sky-50\/50 {
  border-top-color: rgb(240 249 255 / 0.5) !important;
}

.tw-border-t-sky-50\/55 {
  border-top-color: rgb(240 249 255 / 0.55) !important;
}

.tw-border-t-sky-50\/60 {
  border-top-color: rgb(240 249 255 / 0.6) !important;
}

.tw-border-t-sky-50\/65 {
  border-top-color: rgb(240 249 255 / 0.65) !important;
}

.tw-border-t-sky-50\/70 {
  border-top-color: rgb(240 249 255 / 0.7) !important;
}

.tw-border-t-sky-50\/75 {
  border-top-color: rgb(240 249 255 / 0.75) !important;
}

.tw-border-t-sky-50\/80 {
  border-top-color: rgb(240 249 255 / 0.8) !important;
}

.tw-border-t-sky-50\/85 {
  border-top-color: rgb(240 249 255 / 0.85) !important;
}

.tw-border-t-sky-50\/90 {
  border-top-color: rgb(240 249 255 / 0.9) !important;
}

.tw-border-t-sky-50\/95 {
  border-top-color: rgb(240 249 255 / 0.95) !important;
}

.tw-border-t-sky-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(14 165 233 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-500\/0 {
  border-top-color: rgb(14 165 233 / 0) !important;
}

.tw-border-t-sky-500\/10 {
  border-top-color: rgb(14 165 233 / 0.1) !important;
}

.tw-border-t-sky-500\/100 {
  border-top-color: rgb(14 165 233 / 1) !important;
}

.tw-border-t-sky-500\/15 {
  border-top-color: rgb(14 165 233 / 0.15) !important;
}

.tw-border-t-sky-500\/20 {
  border-top-color: rgb(14 165 233 / 0.2) !important;
}

.tw-border-t-sky-500\/25 {
  border-top-color: rgb(14 165 233 / 0.25) !important;
}

.tw-border-t-sky-500\/30 {
  border-top-color: rgb(14 165 233 / 0.3) !important;
}

.tw-border-t-sky-500\/35 {
  border-top-color: rgb(14 165 233 / 0.35) !important;
}

.tw-border-t-sky-500\/40 {
  border-top-color: rgb(14 165 233 / 0.4) !important;
}

.tw-border-t-sky-500\/45 {
  border-top-color: rgb(14 165 233 / 0.45) !important;
}

.tw-border-t-sky-500\/5 {
  border-top-color: rgb(14 165 233 / 0.05) !important;
}

.tw-border-t-sky-500\/50 {
  border-top-color: rgb(14 165 233 / 0.5) !important;
}

.tw-border-t-sky-500\/55 {
  border-top-color: rgb(14 165 233 / 0.55) !important;
}

.tw-border-t-sky-500\/60 {
  border-top-color: rgb(14 165 233 / 0.6) !important;
}

.tw-border-t-sky-500\/65 {
  border-top-color: rgb(14 165 233 / 0.65) !important;
}

.tw-border-t-sky-500\/70 {
  border-top-color: rgb(14 165 233 / 0.7) !important;
}

.tw-border-t-sky-500\/75 {
  border-top-color: rgb(14 165 233 / 0.75) !important;
}

.tw-border-t-sky-500\/80 {
  border-top-color: rgb(14 165 233 / 0.8) !important;
}

.tw-border-t-sky-500\/85 {
  border-top-color: rgb(14 165 233 / 0.85) !important;
}

.tw-border-t-sky-500\/90 {
  border-top-color: rgb(14 165 233 / 0.9) !important;
}

.tw-border-t-sky-500\/95 {
  border-top-color: rgb(14 165 233 / 0.95) !important;
}

.tw-border-t-sky-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(2 132 199 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-600\/0 {
  border-top-color: rgb(2 132 199 / 0) !important;
}

.tw-border-t-sky-600\/10 {
  border-top-color: rgb(2 132 199 / 0.1) !important;
}

.tw-border-t-sky-600\/100 {
  border-top-color: rgb(2 132 199 / 1) !important;
}

.tw-border-t-sky-600\/15 {
  border-top-color: rgb(2 132 199 / 0.15) !important;
}

.tw-border-t-sky-600\/20 {
  border-top-color: rgb(2 132 199 / 0.2) !important;
}

.tw-border-t-sky-600\/25 {
  border-top-color: rgb(2 132 199 / 0.25) !important;
}

.tw-border-t-sky-600\/30 {
  border-top-color: rgb(2 132 199 / 0.3) !important;
}

.tw-border-t-sky-600\/35 {
  border-top-color: rgb(2 132 199 / 0.35) !important;
}

.tw-border-t-sky-600\/40 {
  border-top-color: rgb(2 132 199 / 0.4) !important;
}

.tw-border-t-sky-600\/45 {
  border-top-color: rgb(2 132 199 / 0.45) !important;
}

.tw-border-t-sky-600\/5 {
  border-top-color: rgb(2 132 199 / 0.05) !important;
}

.tw-border-t-sky-600\/50 {
  border-top-color: rgb(2 132 199 / 0.5) !important;
}

.tw-border-t-sky-600\/55 {
  border-top-color: rgb(2 132 199 / 0.55) !important;
}

.tw-border-t-sky-600\/60 {
  border-top-color: rgb(2 132 199 / 0.6) !important;
}

.tw-border-t-sky-600\/65 {
  border-top-color: rgb(2 132 199 / 0.65) !important;
}

.tw-border-t-sky-600\/70 {
  border-top-color: rgb(2 132 199 / 0.7) !important;
}

.tw-border-t-sky-600\/75 {
  border-top-color: rgb(2 132 199 / 0.75) !important;
}

.tw-border-t-sky-600\/80 {
  border-top-color: rgb(2 132 199 / 0.8) !important;
}

.tw-border-t-sky-600\/85 {
  border-top-color: rgb(2 132 199 / 0.85) !important;
}

.tw-border-t-sky-600\/90 {
  border-top-color: rgb(2 132 199 / 0.9) !important;
}

.tw-border-t-sky-600\/95 {
  border-top-color: rgb(2 132 199 / 0.95) !important;
}

.tw-border-t-sky-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(3 105 161 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-700\/0 {
  border-top-color: rgb(3 105 161 / 0) !important;
}

.tw-border-t-sky-700\/10 {
  border-top-color: rgb(3 105 161 / 0.1) !important;
}

.tw-border-t-sky-700\/100 {
  border-top-color: rgb(3 105 161 / 1) !important;
}

.tw-border-t-sky-700\/15 {
  border-top-color: rgb(3 105 161 / 0.15) !important;
}

.tw-border-t-sky-700\/20 {
  border-top-color: rgb(3 105 161 / 0.2) !important;
}

.tw-border-t-sky-700\/25 {
  border-top-color: rgb(3 105 161 / 0.25) !important;
}

.tw-border-t-sky-700\/30 {
  border-top-color: rgb(3 105 161 / 0.3) !important;
}

.tw-border-t-sky-700\/35 {
  border-top-color: rgb(3 105 161 / 0.35) !important;
}

.tw-border-t-sky-700\/40 {
  border-top-color: rgb(3 105 161 / 0.4) !important;
}

.tw-border-t-sky-700\/45 {
  border-top-color: rgb(3 105 161 / 0.45) !important;
}

.tw-border-t-sky-700\/5 {
  border-top-color: rgb(3 105 161 / 0.05) !important;
}

.tw-border-t-sky-700\/50 {
  border-top-color: rgb(3 105 161 / 0.5) !important;
}

.tw-border-t-sky-700\/55 {
  border-top-color: rgb(3 105 161 / 0.55) !important;
}

.tw-border-t-sky-700\/60 {
  border-top-color: rgb(3 105 161 / 0.6) !important;
}

.tw-border-t-sky-700\/65 {
  border-top-color: rgb(3 105 161 / 0.65) !important;
}

.tw-border-t-sky-700\/70 {
  border-top-color: rgb(3 105 161 / 0.7) !important;
}

.tw-border-t-sky-700\/75 {
  border-top-color: rgb(3 105 161 / 0.75) !important;
}

.tw-border-t-sky-700\/80 {
  border-top-color: rgb(3 105 161 / 0.8) !important;
}

.tw-border-t-sky-700\/85 {
  border-top-color: rgb(3 105 161 / 0.85) !important;
}

.tw-border-t-sky-700\/90 {
  border-top-color: rgb(3 105 161 / 0.9) !important;
}

.tw-border-t-sky-700\/95 {
  border-top-color: rgb(3 105 161 / 0.95) !important;
}

.tw-border-t-sky-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(7 89 133 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-800\/0 {
  border-top-color: rgb(7 89 133 / 0) !important;
}

.tw-border-t-sky-800\/10 {
  border-top-color: rgb(7 89 133 / 0.1) !important;
}

.tw-border-t-sky-800\/100 {
  border-top-color: rgb(7 89 133 / 1) !important;
}

.tw-border-t-sky-800\/15 {
  border-top-color: rgb(7 89 133 / 0.15) !important;
}

.tw-border-t-sky-800\/20 {
  border-top-color: rgb(7 89 133 / 0.2) !important;
}

.tw-border-t-sky-800\/25 {
  border-top-color: rgb(7 89 133 / 0.25) !important;
}

.tw-border-t-sky-800\/30 {
  border-top-color: rgb(7 89 133 / 0.3) !important;
}

.tw-border-t-sky-800\/35 {
  border-top-color: rgb(7 89 133 / 0.35) !important;
}

.tw-border-t-sky-800\/40 {
  border-top-color: rgb(7 89 133 / 0.4) !important;
}

.tw-border-t-sky-800\/45 {
  border-top-color: rgb(7 89 133 / 0.45) !important;
}

.tw-border-t-sky-800\/5 {
  border-top-color: rgb(7 89 133 / 0.05) !important;
}

.tw-border-t-sky-800\/50 {
  border-top-color: rgb(7 89 133 / 0.5) !important;
}

.tw-border-t-sky-800\/55 {
  border-top-color: rgb(7 89 133 / 0.55) !important;
}

.tw-border-t-sky-800\/60 {
  border-top-color: rgb(7 89 133 / 0.6) !important;
}

.tw-border-t-sky-800\/65 {
  border-top-color: rgb(7 89 133 / 0.65) !important;
}

.tw-border-t-sky-800\/70 {
  border-top-color: rgb(7 89 133 / 0.7) !important;
}

.tw-border-t-sky-800\/75 {
  border-top-color: rgb(7 89 133 / 0.75) !important;
}

.tw-border-t-sky-800\/80 {
  border-top-color: rgb(7 89 133 / 0.8) !important;
}

.tw-border-t-sky-800\/85 {
  border-top-color: rgb(7 89 133 / 0.85) !important;
}

.tw-border-t-sky-800\/90 {
  border-top-color: rgb(7 89 133 / 0.9) !important;
}

.tw-border-t-sky-800\/95 {
  border-top-color: rgb(7 89 133 / 0.95) !important;
}

.tw-border-t-sky-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(12 74 110 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-900\/0 {
  border-top-color: rgb(12 74 110 / 0) !important;
}

.tw-border-t-sky-900\/10 {
  border-top-color: rgb(12 74 110 / 0.1) !important;
}

.tw-border-t-sky-900\/100 {
  border-top-color: rgb(12 74 110 / 1) !important;
}

.tw-border-t-sky-900\/15 {
  border-top-color: rgb(12 74 110 / 0.15) !important;
}

.tw-border-t-sky-900\/20 {
  border-top-color: rgb(12 74 110 / 0.2) !important;
}

.tw-border-t-sky-900\/25 {
  border-top-color: rgb(12 74 110 / 0.25) !important;
}

.tw-border-t-sky-900\/30 {
  border-top-color: rgb(12 74 110 / 0.3) !important;
}

.tw-border-t-sky-900\/35 {
  border-top-color: rgb(12 74 110 / 0.35) !important;
}

.tw-border-t-sky-900\/40 {
  border-top-color: rgb(12 74 110 / 0.4) !important;
}

.tw-border-t-sky-900\/45 {
  border-top-color: rgb(12 74 110 / 0.45) !important;
}

.tw-border-t-sky-900\/5 {
  border-top-color: rgb(12 74 110 / 0.05) !important;
}

.tw-border-t-sky-900\/50 {
  border-top-color: rgb(12 74 110 / 0.5) !important;
}

.tw-border-t-sky-900\/55 {
  border-top-color: rgb(12 74 110 / 0.55) !important;
}

.tw-border-t-sky-900\/60 {
  border-top-color: rgb(12 74 110 / 0.6) !important;
}

.tw-border-t-sky-900\/65 {
  border-top-color: rgb(12 74 110 / 0.65) !important;
}

.tw-border-t-sky-900\/70 {
  border-top-color: rgb(12 74 110 / 0.7) !important;
}

.tw-border-t-sky-900\/75 {
  border-top-color: rgb(12 74 110 / 0.75) !important;
}

.tw-border-t-sky-900\/80 {
  border-top-color: rgb(12 74 110 / 0.8) !important;
}

.tw-border-t-sky-900\/85 {
  border-top-color: rgb(12 74 110 / 0.85) !important;
}

.tw-border-t-sky-900\/90 {
  border-top-color: rgb(12 74 110 / 0.9) !important;
}

.tw-border-t-sky-900\/95 {
  border-top-color: rgb(12 74 110 / 0.95) !important;
}

.tw-border-t-sky-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(8 47 73 / var(--tw-border-opacity)) !important;
}

.tw-border-t-sky-950\/0 {
  border-top-color: rgb(8 47 73 / 0) !important;
}

.tw-border-t-sky-950\/10 {
  border-top-color: rgb(8 47 73 / 0.1) !important;
}

.tw-border-t-sky-950\/100 {
  border-top-color: rgb(8 47 73 / 1) !important;
}

.tw-border-t-sky-950\/15 {
  border-top-color: rgb(8 47 73 / 0.15) !important;
}

.tw-border-t-sky-950\/20 {
  border-top-color: rgb(8 47 73 / 0.2) !important;
}

.tw-border-t-sky-950\/25 {
  border-top-color: rgb(8 47 73 / 0.25) !important;
}

.tw-border-t-sky-950\/30 {
  border-top-color: rgb(8 47 73 / 0.3) !important;
}

.tw-border-t-sky-950\/35 {
  border-top-color: rgb(8 47 73 / 0.35) !important;
}

.tw-border-t-sky-950\/40 {
  border-top-color: rgb(8 47 73 / 0.4) !important;
}

.tw-border-t-sky-950\/45 {
  border-top-color: rgb(8 47 73 / 0.45) !important;
}

.tw-border-t-sky-950\/5 {
  border-top-color: rgb(8 47 73 / 0.05) !important;
}

.tw-border-t-sky-950\/50 {
  border-top-color: rgb(8 47 73 / 0.5) !important;
}

.tw-border-t-sky-950\/55 {
  border-top-color: rgb(8 47 73 / 0.55) !important;
}

.tw-border-t-sky-950\/60 {
  border-top-color: rgb(8 47 73 / 0.6) !important;
}

.tw-border-t-sky-950\/65 {
  border-top-color: rgb(8 47 73 / 0.65) !important;
}

.tw-border-t-sky-950\/70 {
  border-top-color: rgb(8 47 73 / 0.7) !important;
}

.tw-border-t-sky-950\/75 {
  border-top-color: rgb(8 47 73 / 0.75) !important;
}

.tw-border-t-sky-950\/80 {
  border-top-color: rgb(8 47 73 / 0.8) !important;
}

.tw-border-t-sky-950\/85 {
  border-top-color: rgb(8 47 73 / 0.85) !important;
}

.tw-border-t-sky-950\/90 {
  border-top-color: rgb(8 47 73 / 0.9) !important;
}

.tw-border-t-sky-950\/95 {
  border-top-color: rgb(8 47 73 / 0.95) !important;
}

.tw-border-t-slate-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(241 245 249 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-100\/0 {
  border-top-color: rgb(241 245 249 / 0) !important;
}

.tw-border-t-slate-100\/10 {
  border-top-color: rgb(241 245 249 / 0.1) !important;
}

.tw-border-t-slate-100\/100 {
  border-top-color: rgb(241 245 249 / 1) !important;
}

.tw-border-t-slate-100\/15 {
  border-top-color: rgb(241 245 249 / 0.15) !important;
}

.tw-border-t-slate-100\/20 {
  border-top-color: rgb(241 245 249 / 0.2) !important;
}

.tw-border-t-slate-100\/25 {
  border-top-color: rgb(241 245 249 / 0.25) !important;
}

.tw-border-t-slate-100\/30 {
  border-top-color: rgb(241 245 249 / 0.3) !important;
}

.tw-border-t-slate-100\/35 {
  border-top-color: rgb(241 245 249 / 0.35) !important;
}

.tw-border-t-slate-100\/40 {
  border-top-color: rgb(241 245 249 / 0.4) !important;
}

.tw-border-t-slate-100\/45 {
  border-top-color: rgb(241 245 249 / 0.45) !important;
}

.tw-border-t-slate-100\/5 {
  border-top-color: rgb(241 245 249 / 0.05) !important;
}

.tw-border-t-slate-100\/50 {
  border-top-color: rgb(241 245 249 / 0.5) !important;
}

.tw-border-t-slate-100\/55 {
  border-top-color: rgb(241 245 249 / 0.55) !important;
}

.tw-border-t-slate-100\/60 {
  border-top-color: rgb(241 245 249 / 0.6) !important;
}

.tw-border-t-slate-100\/65 {
  border-top-color: rgb(241 245 249 / 0.65) !important;
}

.tw-border-t-slate-100\/70 {
  border-top-color: rgb(241 245 249 / 0.7) !important;
}

.tw-border-t-slate-100\/75 {
  border-top-color: rgb(241 245 249 / 0.75) !important;
}

.tw-border-t-slate-100\/80 {
  border-top-color: rgb(241 245 249 / 0.8) !important;
}

.tw-border-t-slate-100\/85 {
  border-top-color: rgb(241 245 249 / 0.85) !important;
}

.tw-border-t-slate-100\/90 {
  border-top-color: rgb(241 245 249 / 0.9) !important;
}

.tw-border-t-slate-100\/95 {
  border-top-color: rgb(241 245 249 / 0.95) !important;
}

.tw-border-t-slate-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(226 232 240 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-200\/0 {
  border-top-color: rgb(226 232 240 / 0) !important;
}

.tw-border-t-slate-200\/10 {
  border-top-color: rgb(226 232 240 / 0.1) !important;
}

.tw-border-t-slate-200\/100 {
  border-top-color: rgb(226 232 240 / 1) !important;
}

.tw-border-t-slate-200\/15 {
  border-top-color: rgb(226 232 240 / 0.15) !important;
}

.tw-border-t-slate-200\/20 {
  border-top-color: rgb(226 232 240 / 0.2) !important;
}

.tw-border-t-slate-200\/25 {
  border-top-color: rgb(226 232 240 / 0.25) !important;
}

.tw-border-t-slate-200\/30 {
  border-top-color: rgb(226 232 240 / 0.3) !important;
}

.tw-border-t-slate-200\/35 {
  border-top-color: rgb(226 232 240 / 0.35) !important;
}

.tw-border-t-slate-200\/40 {
  border-top-color: rgb(226 232 240 / 0.4) !important;
}

.tw-border-t-slate-200\/45 {
  border-top-color: rgb(226 232 240 / 0.45) !important;
}

.tw-border-t-slate-200\/5 {
  border-top-color: rgb(226 232 240 / 0.05) !important;
}

.tw-border-t-slate-200\/50 {
  border-top-color: rgb(226 232 240 / 0.5) !important;
}

.tw-border-t-slate-200\/55 {
  border-top-color: rgb(226 232 240 / 0.55) !important;
}

.tw-border-t-slate-200\/60 {
  border-top-color: rgb(226 232 240 / 0.6) !important;
}

.tw-border-t-slate-200\/65 {
  border-top-color: rgb(226 232 240 / 0.65) !important;
}

.tw-border-t-slate-200\/70 {
  border-top-color: rgb(226 232 240 / 0.7) !important;
}

.tw-border-t-slate-200\/75 {
  border-top-color: rgb(226 232 240 / 0.75) !important;
}

.tw-border-t-slate-200\/80 {
  border-top-color: rgb(226 232 240 / 0.8) !important;
}

.tw-border-t-slate-200\/85 {
  border-top-color: rgb(226 232 240 / 0.85) !important;
}

.tw-border-t-slate-200\/90 {
  border-top-color: rgb(226 232 240 / 0.9) !important;
}

.tw-border-t-slate-200\/95 {
  border-top-color: rgb(226 232 240 / 0.95) !important;
}

.tw-border-t-slate-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(203 213 225 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-300\/0 {
  border-top-color: rgb(203 213 225 / 0) !important;
}

.tw-border-t-slate-300\/10 {
  border-top-color: rgb(203 213 225 / 0.1) !important;
}

.tw-border-t-slate-300\/100 {
  border-top-color: rgb(203 213 225 / 1) !important;
}

.tw-border-t-slate-300\/15 {
  border-top-color: rgb(203 213 225 / 0.15) !important;
}

.tw-border-t-slate-300\/20 {
  border-top-color: rgb(203 213 225 / 0.2) !important;
}

.tw-border-t-slate-300\/25 {
  border-top-color: rgb(203 213 225 / 0.25) !important;
}

.tw-border-t-slate-300\/30 {
  border-top-color: rgb(203 213 225 / 0.3) !important;
}

.tw-border-t-slate-300\/35 {
  border-top-color: rgb(203 213 225 / 0.35) !important;
}

.tw-border-t-slate-300\/40 {
  border-top-color: rgb(203 213 225 / 0.4) !important;
}

.tw-border-t-slate-300\/45 {
  border-top-color: rgb(203 213 225 / 0.45) !important;
}

.tw-border-t-slate-300\/5 {
  border-top-color: rgb(203 213 225 / 0.05) !important;
}

.tw-border-t-slate-300\/50 {
  border-top-color: rgb(203 213 225 / 0.5) !important;
}

.tw-border-t-slate-300\/55 {
  border-top-color: rgb(203 213 225 / 0.55) !important;
}

.tw-border-t-slate-300\/60 {
  border-top-color: rgb(203 213 225 / 0.6) !important;
}

.tw-border-t-slate-300\/65 {
  border-top-color: rgb(203 213 225 / 0.65) !important;
}

.tw-border-t-slate-300\/70 {
  border-top-color: rgb(203 213 225 / 0.7) !important;
}

.tw-border-t-slate-300\/75 {
  border-top-color: rgb(203 213 225 / 0.75) !important;
}

.tw-border-t-slate-300\/80 {
  border-top-color: rgb(203 213 225 / 0.8) !important;
}

.tw-border-t-slate-300\/85 {
  border-top-color: rgb(203 213 225 / 0.85) !important;
}

.tw-border-t-slate-300\/90 {
  border-top-color: rgb(203 213 225 / 0.9) !important;
}

.tw-border-t-slate-300\/95 {
  border-top-color: rgb(203 213 225 / 0.95) !important;
}

.tw-border-t-slate-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(148 163 184 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-400\/0 {
  border-top-color: rgb(148 163 184 / 0) !important;
}

.tw-border-t-slate-400\/10 {
  border-top-color: rgb(148 163 184 / 0.1) !important;
}

.tw-border-t-slate-400\/100 {
  border-top-color: rgb(148 163 184 / 1) !important;
}

.tw-border-t-slate-400\/15 {
  border-top-color: rgb(148 163 184 / 0.15) !important;
}

.tw-border-t-slate-400\/20 {
  border-top-color: rgb(148 163 184 / 0.2) !important;
}

.tw-border-t-slate-400\/25 {
  border-top-color: rgb(148 163 184 / 0.25) !important;
}

.tw-border-t-slate-400\/30 {
  border-top-color: rgb(148 163 184 / 0.3) !important;
}

.tw-border-t-slate-400\/35 {
  border-top-color: rgb(148 163 184 / 0.35) !important;
}

.tw-border-t-slate-400\/40 {
  border-top-color: rgb(148 163 184 / 0.4) !important;
}

.tw-border-t-slate-400\/45 {
  border-top-color: rgb(148 163 184 / 0.45) !important;
}

.tw-border-t-slate-400\/5 {
  border-top-color: rgb(148 163 184 / 0.05) !important;
}

.tw-border-t-slate-400\/50 {
  border-top-color: rgb(148 163 184 / 0.5) !important;
}

.tw-border-t-slate-400\/55 {
  border-top-color: rgb(148 163 184 / 0.55) !important;
}

.tw-border-t-slate-400\/60 {
  border-top-color: rgb(148 163 184 / 0.6) !important;
}

.tw-border-t-slate-400\/65 {
  border-top-color: rgb(148 163 184 / 0.65) !important;
}

.tw-border-t-slate-400\/70 {
  border-top-color: rgb(148 163 184 / 0.7) !important;
}

.tw-border-t-slate-400\/75 {
  border-top-color: rgb(148 163 184 / 0.75) !important;
}

.tw-border-t-slate-400\/80 {
  border-top-color: rgb(148 163 184 / 0.8) !important;
}

.tw-border-t-slate-400\/85 {
  border-top-color: rgb(148 163 184 / 0.85) !important;
}

.tw-border-t-slate-400\/90 {
  border-top-color: rgb(148 163 184 / 0.9) !important;
}

.tw-border-t-slate-400\/95 {
  border-top-color: rgb(148 163 184 / 0.95) !important;
}

.tw-border-t-slate-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(248 250 252 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-50\/0 {
  border-top-color: rgb(248 250 252 / 0) !important;
}

.tw-border-t-slate-50\/10 {
  border-top-color: rgb(248 250 252 / 0.1) !important;
}

.tw-border-t-slate-50\/100 {
  border-top-color: rgb(248 250 252 / 1) !important;
}

.tw-border-t-slate-50\/15 {
  border-top-color: rgb(248 250 252 / 0.15) !important;
}

.tw-border-t-slate-50\/20 {
  border-top-color: rgb(248 250 252 / 0.2) !important;
}

.tw-border-t-slate-50\/25 {
  border-top-color: rgb(248 250 252 / 0.25) !important;
}

.tw-border-t-slate-50\/30 {
  border-top-color: rgb(248 250 252 / 0.3) !important;
}

.tw-border-t-slate-50\/35 {
  border-top-color: rgb(248 250 252 / 0.35) !important;
}

.tw-border-t-slate-50\/40 {
  border-top-color: rgb(248 250 252 / 0.4) !important;
}

.tw-border-t-slate-50\/45 {
  border-top-color: rgb(248 250 252 / 0.45) !important;
}

.tw-border-t-slate-50\/5 {
  border-top-color: rgb(248 250 252 / 0.05) !important;
}

.tw-border-t-slate-50\/50 {
  border-top-color: rgb(248 250 252 / 0.5) !important;
}

.tw-border-t-slate-50\/55 {
  border-top-color: rgb(248 250 252 / 0.55) !important;
}

.tw-border-t-slate-50\/60 {
  border-top-color: rgb(248 250 252 / 0.6) !important;
}

.tw-border-t-slate-50\/65 {
  border-top-color: rgb(248 250 252 / 0.65) !important;
}

.tw-border-t-slate-50\/70 {
  border-top-color: rgb(248 250 252 / 0.7) !important;
}

.tw-border-t-slate-50\/75 {
  border-top-color: rgb(248 250 252 / 0.75) !important;
}

.tw-border-t-slate-50\/80 {
  border-top-color: rgb(248 250 252 / 0.8) !important;
}

.tw-border-t-slate-50\/85 {
  border-top-color: rgb(248 250 252 / 0.85) !important;
}

.tw-border-t-slate-50\/90 {
  border-top-color: rgb(248 250 252 / 0.9) !important;
}

.tw-border-t-slate-50\/95 {
  border-top-color: rgb(248 250 252 / 0.95) !important;
}

.tw-border-t-slate-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(100 116 139 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-500\/0 {
  border-top-color: rgb(100 116 139 / 0) !important;
}

.tw-border-t-slate-500\/10 {
  border-top-color: rgb(100 116 139 / 0.1) !important;
}

.tw-border-t-slate-500\/100 {
  border-top-color: rgb(100 116 139 / 1) !important;
}

.tw-border-t-slate-500\/15 {
  border-top-color: rgb(100 116 139 / 0.15) !important;
}

.tw-border-t-slate-500\/20 {
  border-top-color: rgb(100 116 139 / 0.2) !important;
}

.tw-border-t-slate-500\/25 {
  border-top-color: rgb(100 116 139 / 0.25) !important;
}

.tw-border-t-slate-500\/30 {
  border-top-color: rgb(100 116 139 / 0.3) !important;
}

.tw-border-t-slate-500\/35 {
  border-top-color: rgb(100 116 139 / 0.35) !important;
}

.tw-border-t-slate-500\/40 {
  border-top-color: rgb(100 116 139 / 0.4) !important;
}

.tw-border-t-slate-500\/45 {
  border-top-color: rgb(100 116 139 / 0.45) !important;
}

.tw-border-t-slate-500\/5 {
  border-top-color: rgb(100 116 139 / 0.05) !important;
}

.tw-border-t-slate-500\/50 {
  border-top-color: rgb(100 116 139 / 0.5) !important;
}

.tw-border-t-slate-500\/55 {
  border-top-color: rgb(100 116 139 / 0.55) !important;
}

.tw-border-t-slate-500\/60 {
  border-top-color: rgb(100 116 139 / 0.6) !important;
}

.tw-border-t-slate-500\/65 {
  border-top-color: rgb(100 116 139 / 0.65) !important;
}

.tw-border-t-slate-500\/70 {
  border-top-color: rgb(100 116 139 / 0.7) !important;
}

.tw-border-t-slate-500\/75 {
  border-top-color: rgb(100 116 139 / 0.75) !important;
}

.tw-border-t-slate-500\/80 {
  border-top-color: rgb(100 116 139 / 0.8) !important;
}

.tw-border-t-slate-500\/85 {
  border-top-color: rgb(100 116 139 / 0.85) !important;
}

.tw-border-t-slate-500\/90 {
  border-top-color: rgb(100 116 139 / 0.9) !important;
}

.tw-border-t-slate-500\/95 {
  border-top-color: rgb(100 116 139 / 0.95) !important;
}

.tw-border-t-slate-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(71 85 105 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-600\/0 {
  border-top-color: rgb(71 85 105 / 0) !important;
}

.tw-border-t-slate-600\/10 {
  border-top-color: rgb(71 85 105 / 0.1) !important;
}

.tw-border-t-slate-600\/100 {
  border-top-color: rgb(71 85 105 / 1) !important;
}

.tw-border-t-slate-600\/15 {
  border-top-color: rgb(71 85 105 / 0.15) !important;
}

.tw-border-t-slate-600\/20 {
  border-top-color: rgb(71 85 105 / 0.2) !important;
}

.tw-border-t-slate-600\/25 {
  border-top-color: rgb(71 85 105 / 0.25) !important;
}

.tw-border-t-slate-600\/30 {
  border-top-color: rgb(71 85 105 / 0.3) !important;
}

.tw-border-t-slate-600\/35 {
  border-top-color: rgb(71 85 105 / 0.35) !important;
}

.tw-border-t-slate-600\/40 {
  border-top-color: rgb(71 85 105 / 0.4) !important;
}

.tw-border-t-slate-600\/45 {
  border-top-color: rgb(71 85 105 / 0.45) !important;
}

.tw-border-t-slate-600\/5 {
  border-top-color: rgb(71 85 105 / 0.05) !important;
}

.tw-border-t-slate-600\/50 {
  border-top-color: rgb(71 85 105 / 0.5) !important;
}

.tw-border-t-slate-600\/55 {
  border-top-color: rgb(71 85 105 / 0.55) !important;
}

.tw-border-t-slate-600\/60 {
  border-top-color: rgb(71 85 105 / 0.6) !important;
}

.tw-border-t-slate-600\/65 {
  border-top-color: rgb(71 85 105 / 0.65) !important;
}

.tw-border-t-slate-600\/70 {
  border-top-color: rgb(71 85 105 / 0.7) !important;
}

.tw-border-t-slate-600\/75 {
  border-top-color: rgb(71 85 105 / 0.75) !important;
}

.tw-border-t-slate-600\/80 {
  border-top-color: rgb(71 85 105 / 0.8) !important;
}

.tw-border-t-slate-600\/85 {
  border-top-color: rgb(71 85 105 / 0.85) !important;
}

.tw-border-t-slate-600\/90 {
  border-top-color: rgb(71 85 105 / 0.9) !important;
}

.tw-border-t-slate-600\/95 {
  border-top-color: rgb(71 85 105 / 0.95) !important;
}

.tw-border-t-slate-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(51 65 85 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-700\/0 {
  border-top-color: rgb(51 65 85 / 0) !important;
}

.tw-border-t-slate-700\/10 {
  border-top-color: rgb(51 65 85 / 0.1) !important;
}

.tw-border-t-slate-700\/100 {
  border-top-color: rgb(51 65 85 / 1) !important;
}

.tw-border-t-slate-700\/15 {
  border-top-color: rgb(51 65 85 / 0.15) !important;
}

.tw-border-t-slate-700\/20 {
  border-top-color: rgb(51 65 85 / 0.2) !important;
}

.tw-border-t-slate-700\/25 {
  border-top-color: rgb(51 65 85 / 0.25) !important;
}

.tw-border-t-slate-700\/30 {
  border-top-color: rgb(51 65 85 / 0.3) !important;
}

.tw-border-t-slate-700\/35 {
  border-top-color: rgb(51 65 85 / 0.35) !important;
}

.tw-border-t-slate-700\/40 {
  border-top-color: rgb(51 65 85 / 0.4) !important;
}

.tw-border-t-slate-700\/45 {
  border-top-color: rgb(51 65 85 / 0.45) !important;
}

.tw-border-t-slate-700\/5 {
  border-top-color: rgb(51 65 85 / 0.05) !important;
}

.tw-border-t-slate-700\/50 {
  border-top-color: rgb(51 65 85 / 0.5) !important;
}

.tw-border-t-slate-700\/55 {
  border-top-color: rgb(51 65 85 / 0.55) !important;
}

.tw-border-t-slate-700\/60 {
  border-top-color: rgb(51 65 85 / 0.6) !important;
}

.tw-border-t-slate-700\/65 {
  border-top-color: rgb(51 65 85 / 0.65) !important;
}

.tw-border-t-slate-700\/70 {
  border-top-color: rgb(51 65 85 / 0.7) !important;
}

.tw-border-t-slate-700\/75 {
  border-top-color: rgb(51 65 85 / 0.75) !important;
}

.tw-border-t-slate-700\/80 {
  border-top-color: rgb(51 65 85 / 0.8) !important;
}

.tw-border-t-slate-700\/85 {
  border-top-color: rgb(51 65 85 / 0.85) !important;
}

.tw-border-t-slate-700\/90 {
  border-top-color: rgb(51 65 85 / 0.9) !important;
}

.tw-border-t-slate-700\/95 {
  border-top-color: rgb(51 65 85 / 0.95) !important;
}

.tw-border-t-slate-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(30 41 59 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-800\/0 {
  border-top-color: rgb(30 41 59 / 0) !important;
}

.tw-border-t-slate-800\/10 {
  border-top-color: rgb(30 41 59 / 0.1) !important;
}

.tw-border-t-slate-800\/100 {
  border-top-color: rgb(30 41 59 / 1) !important;
}

.tw-border-t-slate-800\/15 {
  border-top-color: rgb(30 41 59 / 0.15) !important;
}

.tw-border-t-slate-800\/20 {
  border-top-color: rgb(30 41 59 / 0.2) !important;
}

.tw-border-t-slate-800\/25 {
  border-top-color: rgb(30 41 59 / 0.25) !important;
}

.tw-border-t-slate-800\/30 {
  border-top-color: rgb(30 41 59 / 0.3) !important;
}

.tw-border-t-slate-800\/35 {
  border-top-color: rgb(30 41 59 / 0.35) !important;
}

.tw-border-t-slate-800\/40 {
  border-top-color: rgb(30 41 59 / 0.4) !important;
}

.tw-border-t-slate-800\/45 {
  border-top-color: rgb(30 41 59 / 0.45) !important;
}

.tw-border-t-slate-800\/5 {
  border-top-color: rgb(30 41 59 / 0.05) !important;
}

.tw-border-t-slate-800\/50 {
  border-top-color: rgb(30 41 59 / 0.5) !important;
}

.tw-border-t-slate-800\/55 {
  border-top-color: rgb(30 41 59 / 0.55) !important;
}

.tw-border-t-slate-800\/60 {
  border-top-color: rgb(30 41 59 / 0.6) !important;
}

.tw-border-t-slate-800\/65 {
  border-top-color: rgb(30 41 59 / 0.65) !important;
}

.tw-border-t-slate-800\/70 {
  border-top-color: rgb(30 41 59 / 0.7) !important;
}

.tw-border-t-slate-800\/75 {
  border-top-color: rgb(30 41 59 / 0.75) !important;
}

.tw-border-t-slate-800\/80 {
  border-top-color: rgb(30 41 59 / 0.8) !important;
}

.tw-border-t-slate-800\/85 {
  border-top-color: rgb(30 41 59 / 0.85) !important;
}

.tw-border-t-slate-800\/90 {
  border-top-color: rgb(30 41 59 / 0.9) !important;
}

.tw-border-t-slate-800\/95 {
  border-top-color: rgb(30 41 59 / 0.95) !important;
}

.tw-border-t-slate-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(15 23 42 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-900\/0 {
  border-top-color: rgb(15 23 42 / 0) !important;
}

.tw-border-t-slate-900\/10 {
  border-top-color: rgb(15 23 42 / 0.1) !important;
}

.tw-border-t-slate-900\/100 {
  border-top-color: rgb(15 23 42 / 1) !important;
}

.tw-border-t-slate-900\/15 {
  border-top-color: rgb(15 23 42 / 0.15) !important;
}

.tw-border-t-slate-900\/20 {
  border-top-color: rgb(15 23 42 / 0.2) !important;
}

.tw-border-t-slate-900\/25 {
  border-top-color: rgb(15 23 42 / 0.25) !important;
}

.tw-border-t-slate-900\/30 {
  border-top-color: rgb(15 23 42 / 0.3) !important;
}

.tw-border-t-slate-900\/35 {
  border-top-color: rgb(15 23 42 / 0.35) !important;
}

.tw-border-t-slate-900\/40 {
  border-top-color: rgb(15 23 42 / 0.4) !important;
}

.tw-border-t-slate-900\/45 {
  border-top-color: rgb(15 23 42 / 0.45) !important;
}

.tw-border-t-slate-900\/5 {
  border-top-color: rgb(15 23 42 / 0.05) !important;
}

.tw-border-t-slate-900\/50 {
  border-top-color: rgb(15 23 42 / 0.5) !important;
}

.tw-border-t-slate-900\/55 {
  border-top-color: rgb(15 23 42 / 0.55) !important;
}

.tw-border-t-slate-900\/60 {
  border-top-color: rgb(15 23 42 / 0.6) !important;
}

.tw-border-t-slate-900\/65 {
  border-top-color: rgb(15 23 42 / 0.65) !important;
}

.tw-border-t-slate-900\/70 {
  border-top-color: rgb(15 23 42 / 0.7) !important;
}

.tw-border-t-slate-900\/75 {
  border-top-color: rgb(15 23 42 / 0.75) !important;
}

.tw-border-t-slate-900\/80 {
  border-top-color: rgb(15 23 42 / 0.8) !important;
}

.tw-border-t-slate-900\/85 {
  border-top-color: rgb(15 23 42 / 0.85) !important;
}

.tw-border-t-slate-900\/90 {
  border-top-color: rgb(15 23 42 / 0.9) !important;
}

.tw-border-t-slate-900\/95 {
  border-top-color: rgb(15 23 42 / 0.95) !important;
}

.tw-border-t-slate-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(2 6 23 / var(--tw-border-opacity)) !important;
}

.tw-border-t-slate-950\/0 {
  border-top-color: rgb(2 6 23 / 0) !important;
}

.tw-border-t-slate-950\/10 {
  border-top-color: rgb(2 6 23 / 0.1) !important;
}

.tw-border-t-slate-950\/100 {
  border-top-color: rgb(2 6 23 / 1) !important;
}

.tw-border-t-slate-950\/15 {
  border-top-color: rgb(2 6 23 / 0.15) !important;
}

.tw-border-t-slate-950\/20 {
  border-top-color: rgb(2 6 23 / 0.2) !important;
}

.tw-border-t-slate-950\/25 {
  border-top-color: rgb(2 6 23 / 0.25) !important;
}

.tw-border-t-slate-950\/30 {
  border-top-color: rgb(2 6 23 / 0.3) !important;
}

.tw-border-t-slate-950\/35 {
  border-top-color: rgb(2 6 23 / 0.35) !important;
}

.tw-border-t-slate-950\/40 {
  border-top-color: rgb(2 6 23 / 0.4) !important;
}

.tw-border-t-slate-950\/45 {
  border-top-color: rgb(2 6 23 / 0.45) !important;
}

.tw-border-t-slate-950\/5 {
  border-top-color: rgb(2 6 23 / 0.05) !important;
}

.tw-border-t-slate-950\/50 {
  border-top-color: rgb(2 6 23 / 0.5) !important;
}

.tw-border-t-slate-950\/55 {
  border-top-color: rgb(2 6 23 / 0.55) !important;
}

.tw-border-t-slate-950\/60 {
  border-top-color: rgb(2 6 23 / 0.6) !important;
}

.tw-border-t-slate-950\/65 {
  border-top-color: rgb(2 6 23 / 0.65) !important;
}

.tw-border-t-slate-950\/70 {
  border-top-color: rgb(2 6 23 / 0.7) !important;
}

.tw-border-t-slate-950\/75 {
  border-top-color: rgb(2 6 23 / 0.75) !important;
}

.tw-border-t-slate-950\/80 {
  border-top-color: rgb(2 6 23 / 0.8) !important;
}

.tw-border-t-slate-950\/85 {
  border-top-color: rgb(2 6 23 / 0.85) !important;
}

.tw-border-t-slate-950\/90 {
  border-top-color: rgb(2 6 23 / 0.9) !important;
}

.tw-border-t-slate-950\/95 {
  border-top-color: rgb(2 6 23 / 0.95) !important;
}

.tw-border-t-stone-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 245 244 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-100\/0 {
  border-top-color: rgb(245 245 244 / 0) !important;
}

.tw-border-t-stone-100\/10 {
  border-top-color: rgb(245 245 244 / 0.1) !important;
}

.tw-border-t-stone-100\/100 {
  border-top-color: rgb(245 245 244 / 1) !important;
}

.tw-border-t-stone-100\/15 {
  border-top-color: rgb(245 245 244 / 0.15) !important;
}

.tw-border-t-stone-100\/20 {
  border-top-color: rgb(245 245 244 / 0.2) !important;
}

.tw-border-t-stone-100\/25 {
  border-top-color: rgb(245 245 244 / 0.25) !important;
}

.tw-border-t-stone-100\/30 {
  border-top-color: rgb(245 245 244 / 0.3) !important;
}

.tw-border-t-stone-100\/35 {
  border-top-color: rgb(245 245 244 / 0.35) !important;
}

.tw-border-t-stone-100\/40 {
  border-top-color: rgb(245 245 244 / 0.4) !important;
}

.tw-border-t-stone-100\/45 {
  border-top-color: rgb(245 245 244 / 0.45) !important;
}

.tw-border-t-stone-100\/5 {
  border-top-color: rgb(245 245 244 / 0.05) !important;
}

.tw-border-t-stone-100\/50 {
  border-top-color: rgb(245 245 244 / 0.5) !important;
}

.tw-border-t-stone-100\/55 {
  border-top-color: rgb(245 245 244 / 0.55) !important;
}

.tw-border-t-stone-100\/60 {
  border-top-color: rgb(245 245 244 / 0.6) !important;
}

.tw-border-t-stone-100\/65 {
  border-top-color: rgb(245 245 244 / 0.65) !important;
}

.tw-border-t-stone-100\/70 {
  border-top-color: rgb(245 245 244 / 0.7) !important;
}

.tw-border-t-stone-100\/75 {
  border-top-color: rgb(245 245 244 / 0.75) !important;
}

.tw-border-t-stone-100\/80 {
  border-top-color: rgb(245 245 244 / 0.8) !important;
}

.tw-border-t-stone-100\/85 {
  border-top-color: rgb(245 245 244 / 0.85) !important;
}

.tw-border-t-stone-100\/90 {
  border-top-color: rgb(245 245 244 / 0.9) !important;
}

.tw-border-t-stone-100\/95 {
  border-top-color: rgb(245 245 244 / 0.95) !important;
}

.tw-border-t-stone-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(231 229 228 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-200\/0 {
  border-top-color: rgb(231 229 228 / 0) !important;
}

.tw-border-t-stone-200\/10 {
  border-top-color: rgb(231 229 228 / 0.1) !important;
}

.tw-border-t-stone-200\/100 {
  border-top-color: rgb(231 229 228 / 1) !important;
}

.tw-border-t-stone-200\/15 {
  border-top-color: rgb(231 229 228 / 0.15) !important;
}

.tw-border-t-stone-200\/20 {
  border-top-color: rgb(231 229 228 / 0.2) !important;
}

.tw-border-t-stone-200\/25 {
  border-top-color: rgb(231 229 228 / 0.25) !important;
}

.tw-border-t-stone-200\/30 {
  border-top-color: rgb(231 229 228 / 0.3) !important;
}

.tw-border-t-stone-200\/35 {
  border-top-color: rgb(231 229 228 / 0.35) !important;
}

.tw-border-t-stone-200\/40 {
  border-top-color: rgb(231 229 228 / 0.4) !important;
}

.tw-border-t-stone-200\/45 {
  border-top-color: rgb(231 229 228 / 0.45) !important;
}

.tw-border-t-stone-200\/5 {
  border-top-color: rgb(231 229 228 / 0.05) !important;
}

.tw-border-t-stone-200\/50 {
  border-top-color: rgb(231 229 228 / 0.5) !important;
}

.tw-border-t-stone-200\/55 {
  border-top-color: rgb(231 229 228 / 0.55) !important;
}

.tw-border-t-stone-200\/60 {
  border-top-color: rgb(231 229 228 / 0.6) !important;
}

.tw-border-t-stone-200\/65 {
  border-top-color: rgb(231 229 228 / 0.65) !important;
}

.tw-border-t-stone-200\/70 {
  border-top-color: rgb(231 229 228 / 0.7) !important;
}

.tw-border-t-stone-200\/75 {
  border-top-color: rgb(231 229 228 / 0.75) !important;
}

.tw-border-t-stone-200\/80 {
  border-top-color: rgb(231 229 228 / 0.8) !important;
}

.tw-border-t-stone-200\/85 {
  border-top-color: rgb(231 229 228 / 0.85) !important;
}

.tw-border-t-stone-200\/90 {
  border-top-color: rgb(231 229 228 / 0.9) !important;
}

.tw-border-t-stone-200\/95 {
  border-top-color: rgb(231 229 228 / 0.95) !important;
}

.tw-border-t-stone-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(214 211 209 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-300\/0 {
  border-top-color: rgb(214 211 209 / 0) !important;
}

.tw-border-t-stone-300\/10 {
  border-top-color: rgb(214 211 209 / 0.1) !important;
}

.tw-border-t-stone-300\/100 {
  border-top-color: rgb(214 211 209 / 1) !important;
}

.tw-border-t-stone-300\/15 {
  border-top-color: rgb(214 211 209 / 0.15) !important;
}

.tw-border-t-stone-300\/20 {
  border-top-color: rgb(214 211 209 / 0.2) !important;
}

.tw-border-t-stone-300\/25 {
  border-top-color: rgb(214 211 209 / 0.25) !important;
}

.tw-border-t-stone-300\/30 {
  border-top-color: rgb(214 211 209 / 0.3) !important;
}

.tw-border-t-stone-300\/35 {
  border-top-color: rgb(214 211 209 / 0.35) !important;
}

.tw-border-t-stone-300\/40 {
  border-top-color: rgb(214 211 209 / 0.4) !important;
}

.tw-border-t-stone-300\/45 {
  border-top-color: rgb(214 211 209 / 0.45) !important;
}

.tw-border-t-stone-300\/5 {
  border-top-color: rgb(214 211 209 / 0.05) !important;
}

.tw-border-t-stone-300\/50 {
  border-top-color: rgb(214 211 209 / 0.5) !important;
}

.tw-border-t-stone-300\/55 {
  border-top-color: rgb(214 211 209 / 0.55) !important;
}

.tw-border-t-stone-300\/60 {
  border-top-color: rgb(214 211 209 / 0.6) !important;
}

.tw-border-t-stone-300\/65 {
  border-top-color: rgb(214 211 209 / 0.65) !important;
}

.tw-border-t-stone-300\/70 {
  border-top-color: rgb(214 211 209 / 0.7) !important;
}

.tw-border-t-stone-300\/75 {
  border-top-color: rgb(214 211 209 / 0.75) !important;
}

.tw-border-t-stone-300\/80 {
  border-top-color: rgb(214 211 209 / 0.8) !important;
}

.tw-border-t-stone-300\/85 {
  border-top-color: rgb(214 211 209 / 0.85) !important;
}

.tw-border-t-stone-300\/90 {
  border-top-color: rgb(214 211 209 / 0.9) !important;
}

.tw-border-t-stone-300\/95 {
  border-top-color: rgb(214 211 209 / 0.95) !important;
}

.tw-border-t-stone-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(168 162 158 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-400\/0 {
  border-top-color: rgb(168 162 158 / 0) !important;
}

.tw-border-t-stone-400\/10 {
  border-top-color: rgb(168 162 158 / 0.1) !important;
}

.tw-border-t-stone-400\/100 {
  border-top-color: rgb(168 162 158 / 1) !important;
}

.tw-border-t-stone-400\/15 {
  border-top-color: rgb(168 162 158 / 0.15) !important;
}

.tw-border-t-stone-400\/20 {
  border-top-color: rgb(168 162 158 / 0.2) !important;
}

.tw-border-t-stone-400\/25 {
  border-top-color: rgb(168 162 158 / 0.25) !important;
}

.tw-border-t-stone-400\/30 {
  border-top-color: rgb(168 162 158 / 0.3) !important;
}

.tw-border-t-stone-400\/35 {
  border-top-color: rgb(168 162 158 / 0.35) !important;
}

.tw-border-t-stone-400\/40 {
  border-top-color: rgb(168 162 158 / 0.4) !important;
}

.tw-border-t-stone-400\/45 {
  border-top-color: rgb(168 162 158 / 0.45) !important;
}

.tw-border-t-stone-400\/5 {
  border-top-color: rgb(168 162 158 / 0.05) !important;
}

.tw-border-t-stone-400\/50 {
  border-top-color: rgb(168 162 158 / 0.5) !important;
}

.tw-border-t-stone-400\/55 {
  border-top-color: rgb(168 162 158 / 0.55) !important;
}

.tw-border-t-stone-400\/60 {
  border-top-color: rgb(168 162 158 / 0.6) !important;
}

.tw-border-t-stone-400\/65 {
  border-top-color: rgb(168 162 158 / 0.65) !important;
}

.tw-border-t-stone-400\/70 {
  border-top-color: rgb(168 162 158 / 0.7) !important;
}

.tw-border-t-stone-400\/75 {
  border-top-color: rgb(168 162 158 / 0.75) !important;
}

.tw-border-t-stone-400\/80 {
  border-top-color: rgb(168 162 158 / 0.8) !important;
}

.tw-border-t-stone-400\/85 {
  border-top-color: rgb(168 162 158 / 0.85) !important;
}

.tw-border-t-stone-400\/90 {
  border-top-color: rgb(168 162 158 / 0.9) !important;
}

.tw-border-t-stone-400\/95 {
  border-top-color: rgb(168 162 158 / 0.95) !important;
}

.tw-border-t-stone-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 250 249 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-50\/0 {
  border-top-color: rgb(250 250 249 / 0) !important;
}

.tw-border-t-stone-50\/10 {
  border-top-color: rgb(250 250 249 / 0.1) !important;
}

.tw-border-t-stone-50\/100 {
  border-top-color: rgb(250 250 249 / 1) !important;
}

.tw-border-t-stone-50\/15 {
  border-top-color: rgb(250 250 249 / 0.15) !important;
}

.tw-border-t-stone-50\/20 {
  border-top-color: rgb(250 250 249 / 0.2) !important;
}

.tw-border-t-stone-50\/25 {
  border-top-color: rgb(250 250 249 / 0.25) !important;
}

.tw-border-t-stone-50\/30 {
  border-top-color: rgb(250 250 249 / 0.3) !important;
}

.tw-border-t-stone-50\/35 {
  border-top-color: rgb(250 250 249 / 0.35) !important;
}

.tw-border-t-stone-50\/40 {
  border-top-color: rgb(250 250 249 / 0.4) !important;
}

.tw-border-t-stone-50\/45 {
  border-top-color: rgb(250 250 249 / 0.45) !important;
}

.tw-border-t-stone-50\/5 {
  border-top-color: rgb(250 250 249 / 0.05) !important;
}

.tw-border-t-stone-50\/50 {
  border-top-color: rgb(250 250 249 / 0.5) !important;
}

.tw-border-t-stone-50\/55 {
  border-top-color: rgb(250 250 249 / 0.55) !important;
}

.tw-border-t-stone-50\/60 {
  border-top-color: rgb(250 250 249 / 0.6) !important;
}

.tw-border-t-stone-50\/65 {
  border-top-color: rgb(250 250 249 / 0.65) !important;
}

.tw-border-t-stone-50\/70 {
  border-top-color: rgb(250 250 249 / 0.7) !important;
}

.tw-border-t-stone-50\/75 {
  border-top-color: rgb(250 250 249 / 0.75) !important;
}

.tw-border-t-stone-50\/80 {
  border-top-color: rgb(250 250 249 / 0.8) !important;
}

.tw-border-t-stone-50\/85 {
  border-top-color: rgb(250 250 249 / 0.85) !important;
}

.tw-border-t-stone-50\/90 {
  border-top-color: rgb(250 250 249 / 0.9) !important;
}

.tw-border-t-stone-50\/95 {
  border-top-color: rgb(250 250 249 / 0.95) !important;
}

.tw-border-t-stone-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(120 113 108 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-500\/0 {
  border-top-color: rgb(120 113 108 / 0) !important;
}

.tw-border-t-stone-500\/10 {
  border-top-color: rgb(120 113 108 / 0.1) !important;
}

.tw-border-t-stone-500\/100 {
  border-top-color: rgb(120 113 108 / 1) !important;
}

.tw-border-t-stone-500\/15 {
  border-top-color: rgb(120 113 108 / 0.15) !important;
}

.tw-border-t-stone-500\/20 {
  border-top-color: rgb(120 113 108 / 0.2) !important;
}

.tw-border-t-stone-500\/25 {
  border-top-color: rgb(120 113 108 / 0.25) !important;
}

.tw-border-t-stone-500\/30 {
  border-top-color: rgb(120 113 108 / 0.3) !important;
}

.tw-border-t-stone-500\/35 {
  border-top-color: rgb(120 113 108 / 0.35) !important;
}

.tw-border-t-stone-500\/40 {
  border-top-color: rgb(120 113 108 / 0.4) !important;
}

.tw-border-t-stone-500\/45 {
  border-top-color: rgb(120 113 108 / 0.45) !important;
}

.tw-border-t-stone-500\/5 {
  border-top-color: rgb(120 113 108 / 0.05) !important;
}

.tw-border-t-stone-500\/50 {
  border-top-color: rgb(120 113 108 / 0.5) !important;
}

.tw-border-t-stone-500\/55 {
  border-top-color: rgb(120 113 108 / 0.55) !important;
}

.tw-border-t-stone-500\/60 {
  border-top-color: rgb(120 113 108 / 0.6) !important;
}

.tw-border-t-stone-500\/65 {
  border-top-color: rgb(120 113 108 / 0.65) !important;
}

.tw-border-t-stone-500\/70 {
  border-top-color: rgb(120 113 108 / 0.7) !important;
}

.tw-border-t-stone-500\/75 {
  border-top-color: rgb(120 113 108 / 0.75) !important;
}

.tw-border-t-stone-500\/80 {
  border-top-color: rgb(120 113 108 / 0.8) !important;
}

.tw-border-t-stone-500\/85 {
  border-top-color: rgb(120 113 108 / 0.85) !important;
}

.tw-border-t-stone-500\/90 {
  border-top-color: rgb(120 113 108 / 0.9) !important;
}

.tw-border-t-stone-500\/95 {
  border-top-color: rgb(120 113 108 / 0.95) !important;
}

.tw-border-t-stone-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(87 83 78 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-600\/0 {
  border-top-color: rgb(87 83 78 / 0) !important;
}

.tw-border-t-stone-600\/10 {
  border-top-color: rgb(87 83 78 / 0.1) !important;
}

.tw-border-t-stone-600\/100 {
  border-top-color: rgb(87 83 78 / 1) !important;
}

.tw-border-t-stone-600\/15 {
  border-top-color: rgb(87 83 78 / 0.15) !important;
}

.tw-border-t-stone-600\/20 {
  border-top-color: rgb(87 83 78 / 0.2) !important;
}

.tw-border-t-stone-600\/25 {
  border-top-color: rgb(87 83 78 / 0.25) !important;
}

.tw-border-t-stone-600\/30 {
  border-top-color: rgb(87 83 78 / 0.3) !important;
}

.tw-border-t-stone-600\/35 {
  border-top-color: rgb(87 83 78 / 0.35) !important;
}

.tw-border-t-stone-600\/40 {
  border-top-color: rgb(87 83 78 / 0.4) !important;
}

.tw-border-t-stone-600\/45 {
  border-top-color: rgb(87 83 78 / 0.45) !important;
}

.tw-border-t-stone-600\/5 {
  border-top-color: rgb(87 83 78 / 0.05) !important;
}

.tw-border-t-stone-600\/50 {
  border-top-color: rgb(87 83 78 / 0.5) !important;
}

.tw-border-t-stone-600\/55 {
  border-top-color: rgb(87 83 78 / 0.55) !important;
}

.tw-border-t-stone-600\/60 {
  border-top-color: rgb(87 83 78 / 0.6) !important;
}

.tw-border-t-stone-600\/65 {
  border-top-color: rgb(87 83 78 / 0.65) !important;
}

.tw-border-t-stone-600\/70 {
  border-top-color: rgb(87 83 78 / 0.7) !important;
}

.tw-border-t-stone-600\/75 {
  border-top-color: rgb(87 83 78 / 0.75) !important;
}

.tw-border-t-stone-600\/80 {
  border-top-color: rgb(87 83 78 / 0.8) !important;
}

.tw-border-t-stone-600\/85 {
  border-top-color: rgb(87 83 78 / 0.85) !important;
}

.tw-border-t-stone-600\/90 {
  border-top-color: rgb(87 83 78 / 0.9) !important;
}

.tw-border-t-stone-600\/95 {
  border-top-color: rgb(87 83 78 / 0.95) !important;
}

.tw-border-t-stone-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(68 64 60 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-700\/0 {
  border-top-color: rgb(68 64 60 / 0) !important;
}

.tw-border-t-stone-700\/10 {
  border-top-color: rgb(68 64 60 / 0.1) !important;
}

.tw-border-t-stone-700\/100 {
  border-top-color: rgb(68 64 60 / 1) !important;
}

.tw-border-t-stone-700\/15 {
  border-top-color: rgb(68 64 60 / 0.15) !important;
}

.tw-border-t-stone-700\/20 {
  border-top-color: rgb(68 64 60 / 0.2) !important;
}

.tw-border-t-stone-700\/25 {
  border-top-color: rgb(68 64 60 / 0.25) !important;
}

.tw-border-t-stone-700\/30 {
  border-top-color: rgb(68 64 60 / 0.3) !important;
}

.tw-border-t-stone-700\/35 {
  border-top-color: rgb(68 64 60 / 0.35) !important;
}

.tw-border-t-stone-700\/40 {
  border-top-color: rgb(68 64 60 / 0.4) !important;
}

.tw-border-t-stone-700\/45 {
  border-top-color: rgb(68 64 60 / 0.45) !important;
}

.tw-border-t-stone-700\/5 {
  border-top-color: rgb(68 64 60 / 0.05) !important;
}

.tw-border-t-stone-700\/50 {
  border-top-color: rgb(68 64 60 / 0.5) !important;
}

.tw-border-t-stone-700\/55 {
  border-top-color: rgb(68 64 60 / 0.55) !important;
}

.tw-border-t-stone-700\/60 {
  border-top-color: rgb(68 64 60 / 0.6) !important;
}

.tw-border-t-stone-700\/65 {
  border-top-color: rgb(68 64 60 / 0.65) !important;
}

.tw-border-t-stone-700\/70 {
  border-top-color: rgb(68 64 60 / 0.7) !important;
}

.tw-border-t-stone-700\/75 {
  border-top-color: rgb(68 64 60 / 0.75) !important;
}

.tw-border-t-stone-700\/80 {
  border-top-color: rgb(68 64 60 / 0.8) !important;
}

.tw-border-t-stone-700\/85 {
  border-top-color: rgb(68 64 60 / 0.85) !important;
}

.tw-border-t-stone-700\/90 {
  border-top-color: rgb(68 64 60 / 0.9) !important;
}

.tw-border-t-stone-700\/95 {
  border-top-color: rgb(68 64 60 / 0.95) !important;
}

.tw-border-t-stone-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(41 37 36 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-800\/0 {
  border-top-color: rgb(41 37 36 / 0) !important;
}

.tw-border-t-stone-800\/10 {
  border-top-color: rgb(41 37 36 / 0.1) !important;
}

.tw-border-t-stone-800\/100 {
  border-top-color: rgb(41 37 36 / 1) !important;
}

.tw-border-t-stone-800\/15 {
  border-top-color: rgb(41 37 36 / 0.15) !important;
}

.tw-border-t-stone-800\/20 {
  border-top-color: rgb(41 37 36 / 0.2) !important;
}

.tw-border-t-stone-800\/25 {
  border-top-color: rgb(41 37 36 / 0.25) !important;
}

.tw-border-t-stone-800\/30 {
  border-top-color: rgb(41 37 36 / 0.3) !important;
}

.tw-border-t-stone-800\/35 {
  border-top-color: rgb(41 37 36 / 0.35) !important;
}

.tw-border-t-stone-800\/40 {
  border-top-color: rgb(41 37 36 / 0.4) !important;
}

.tw-border-t-stone-800\/45 {
  border-top-color: rgb(41 37 36 / 0.45) !important;
}

.tw-border-t-stone-800\/5 {
  border-top-color: rgb(41 37 36 / 0.05) !important;
}

.tw-border-t-stone-800\/50 {
  border-top-color: rgb(41 37 36 / 0.5) !important;
}

.tw-border-t-stone-800\/55 {
  border-top-color: rgb(41 37 36 / 0.55) !important;
}

.tw-border-t-stone-800\/60 {
  border-top-color: rgb(41 37 36 / 0.6) !important;
}

.tw-border-t-stone-800\/65 {
  border-top-color: rgb(41 37 36 / 0.65) !important;
}

.tw-border-t-stone-800\/70 {
  border-top-color: rgb(41 37 36 / 0.7) !important;
}

.tw-border-t-stone-800\/75 {
  border-top-color: rgb(41 37 36 / 0.75) !important;
}

.tw-border-t-stone-800\/80 {
  border-top-color: rgb(41 37 36 / 0.8) !important;
}

.tw-border-t-stone-800\/85 {
  border-top-color: rgb(41 37 36 / 0.85) !important;
}

.tw-border-t-stone-800\/90 {
  border-top-color: rgb(41 37 36 / 0.9) !important;
}

.tw-border-t-stone-800\/95 {
  border-top-color: rgb(41 37 36 / 0.95) !important;
}

.tw-border-t-stone-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(28 25 23 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-900\/0 {
  border-top-color: rgb(28 25 23 / 0) !important;
}

.tw-border-t-stone-900\/10 {
  border-top-color: rgb(28 25 23 / 0.1) !important;
}

.tw-border-t-stone-900\/100 {
  border-top-color: rgb(28 25 23 / 1) !important;
}

.tw-border-t-stone-900\/15 {
  border-top-color: rgb(28 25 23 / 0.15) !important;
}

.tw-border-t-stone-900\/20 {
  border-top-color: rgb(28 25 23 / 0.2) !important;
}

.tw-border-t-stone-900\/25 {
  border-top-color: rgb(28 25 23 / 0.25) !important;
}

.tw-border-t-stone-900\/30 {
  border-top-color: rgb(28 25 23 / 0.3) !important;
}

.tw-border-t-stone-900\/35 {
  border-top-color: rgb(28 25 23 / 0.35) !important;
}

.tw-border-t-stone-900\/40 {
  border-top-color: rgb(28 25 23 / 0.4) !important;
}

.tw-border-t-stone-900\/45 {
  border-top-color: rgb(28 25 23 / 0.45) !important;
}

.tw-border-t-stone-900\/5 {
  border-top-color: rgb(28 25 23 / 0.05) !important;
}

.tw-border-t-stone-900\/50 {
  border-top-color: rgb(28 25 23 / 0.5) !important;
}

.tw-border-t-stone-900\/55 {
  border-top-color: rgb(28 25 23 / 0.55) !important;
}

.tw-border-t-stone-900\/60 {
  border-top-color: rgb(28 25 23 / 0.6) !important;
}

.tw-border-t-stone-900\/65 {
  border-top-color: rgb(28 25 23 / 0.65) !important;
}

.tw-border-t-stone-900\/70 {
  border-top-color: rgb(28 25 23 / 0.7) !important;
}

.tw-border-t-stone-900\/75 {
  border-top-color: rgb(28 25 23 / 0.75) !important;
}

.tw-border-t-stone-900\/80 {
  border-top-color: rgb(28 25 23 / 0.8) !important;
}

.tw-border-t-stone-900\/85 {
  border-top-color: rgb(28 25 23 / 0.85) !important;
}

.tw-border-t-stone-900\/90 {
  border-top-color: rgb(28 25 23 / 0.9) !important;
}

.tw-border-t-stone-900\/95 {
  border-top-color: rgb(28 25 23 / 0.95) !important;
}

.tw-border-t-stone-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(12 10 9 / var(--tw-border-opacity)) !important;
}

.tw-border-t-stone-950\/0 {
  border-top-color: rgb(12 10 9 / 0) !important;
}

.tw-border-t-stone-950\/10 {
  border-top-color: rgb(12 10 9 / 0.1) !important;
}

.tw-border-t-stone-950\/100 {
  border-top-color: rgb(12 10 9 / 1) !important;
}

.tw-border-t-stone-950\/15 {
  border-top-color: rgb(12 10 9 / 0.15) !important;
}

.tw-border-t-stone-950\/20 {
  border-top-color: rgb(12 10 9 / 0.2) !important;
}

.tw-border-t-stone-950\/25 {
  border-top-color: rgb(12 10 9 / 0.25) !important;
}

.tw-border-t-stone-950\/30 {
  border-top-color: rgb(12 10 9 / 0.3) !important;
}

.tw-border-t-stone-950\/35 {
  border-top-color: rgb(12 10 9 / 0.35) !important;
}

.tw-border-t-stone-950\/40 {
  border-top-color: rgb(12 10 9 / 0.4) !important;
}

.tw-border-t-stone-950\/45 {
  border-top-color: rgb(12 10 9 / 0.45) !important;
}

.tw-border-t-stone-950\/5 {
  border-top-color: rgb(12 10 9 / 0.05) !important;
}

.tw-border-t-stone-950\/50 {
  border-top-color: rgb(12 10 9 / 0.5) !important;
}

.tw-border-t-stone-950\/55 {
  border-top-color: rgb(12 10 9 / 0.55) !important;
}

.tw-border-t-stone-950\/60 {
  border-top-color: rgb(12 10 9 / 0.6) !important;
}

.tw-border-t-stone-950\/65 {
  border-top-color: rgb(12 10 9 / 0.65) !important;
}

.tw-border-t-stone-950\/70 {
  border-top-color: rgb(12 10 9 / 0.7) !important;
}

.tw-border-t-stone-950\/75 {
  border-top-color: rgb(12 10 9 / 0.75) !important;
}

.tw-border-t-stone-950\/80 {
  border-top-color: rgb(12 10 9 / 0.8) !important;
}

.tw-border-t-stone-950\/85 {
  border-top-color: rgb(12 10 9 / 0.85) !important;
}

.tw-border-t-stone-950\/90 {
  border-top-color: rgb(12 10 9 / 0.9) !important;
}

.tw-border-t-stone-950\/95 {
  border-top-color: rgb(12 10 9 / 0.95) !important;
}

.tw-border-t-teal-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(204 251 241 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-100\/0 {
  border-top-color: rgb(204 251 241 / 0) !important;
}

.tw-border-t-teal-100\/10 {
  border-top-color: rgb(204 251 241 / 0.1) !important;
}

.tw-border-t-teal-100\/100 {
  border-top-color: rgb(204 251 241 / 1) !important;
}

.tw-border-t-teal-100\/15 {
  border-top-color: rgb(204 251 241 / 0.15) !important;
}

.tw-border-t-teal-100\/20 {
  border-top-color: rgb(204 251 241 / 0.2) !important;
}

.tw-border-t-teal-100\/25 {
  border-top-color: rgb(204 251 241 / 0.25) !important;
}

.tw-border-t-teal-100\/30 {
  border-top-color: rgb(204 251 241 / 0.3) !important;
}

.tw-border-t-teal-100\/35 {
  border-top-color: rgb(204 251 241 / 0.35) !important;
}

.tw-border-t-teal-100\/40 {
  border-top-color: rgb(204 251 241 / 0.4) !important;
}

.tw-border-t-teal-100\/45 {
  border-top-color: rgb(204 251 241 / 0.45) !important;
}

.tw-border-t-teal-100\/5 {
  border-top-color: rgb(204 251 241 / 0.05) !important;
}

.tw-border-t-teal-100\/50 {
  border-top-color: rgb(204 251 241 / 0.5) !important;
}

.tw-border-t-teal-100\/55 {
  border-top-color: rgb(204 251 241 / 0.55) !important;
}

.tw-border-t-teal-100\/60 {
  border-top-color: rgb(204 251 241 / 0.6) !important;
}

.tw-border-t-teal-100\/65 {
  border-top-color: rgb(204 251 241 / 0.65) !important;
}

.tw-border-t-teal-100\/70 {
  border-top-color: rgb(204 251 241 / 0.7) !important;
}

.tw-border-t-teal-100\/75 {
  border-top-color: rgb(204 251 241 / 0.75) !important;
}

.tw-border-t-teal-100\/80 {
  border-top-color: rgb(204 251 241 / 0.8) !important;
}

.tw-border-t-teal-100\/85 {
  border-top-color: rgb(204 251 241 / 0.85) !important;
}

.tw-border-t-teal-100\/90 {
  border-top-color: rgb(204 251 241 / 0.9) !important;
}

.tw-border-t-teal-100\/95 {
  border-top-color: rgb(204 251 241 / 0.95) !important;
}

.tw-border-t-teal-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(153 246 228 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-200\/0 {
  border-top-color: rgb(153 246 228 / 0) !important;
}

.tw-border-t-teal-200\/10 {
  border-top-color: rgb(153 246 228 / 0.1) !important;
}

.tw-border-t-teal-200\/100 {
  border-top-color: rgb(153 246 228 / 1) !important;
}

.tw-border-t-teal-200\/15 {
  border-top-color: rgb(153 246 228 / 0.15) !important;
}

.tw-border-t-teal-200\/20 {
  border-top-color: rgb(153 246 228 / 0.2) !important;
}

.tw-border-t-teal-200\/25 {
  border-top-color: rgb(153 246 228 / 0.25) !important;
}

.tw-border-t-teal-200\/30 {
  border-top-color: rgb(153 246 228 / 0.3) !important;
}

.tw-border-t-teal-200\/35 {
  border-top-color: rgb(153 246 228 / 0.35) !important;
}

.tw-border-t-teal-200\/40 {
  border-top-color: rgb(153 246 228 / 0.4) !important;
}

.tw-border-t-teal-200\/45 {
  border-top-color: rgb(153 246 228 / 0.45) !important;
}

.tw-border-t-teal-200\/5 {
  border-top-color: rgb(153 246 228 / 0.05) !important;
}

.tw-border-t-teal-200\/50 {
  border-top-color: rgb(153 246 228 / 0.5) !important;
}

.tw-border-t-teal-200\/55 {
  border-top-color: rgb(153 246 228 / 0.55) !important;
}

.tw-border-t-teal-200\/60 {
  border-top-color: rgb(153 246 228 / 0.6) !important;
}

.tw-border-t-teal-200\/65 {
  border-top-color: rgb(153 246 228 / 0.65) !important;
}

.tw-border-t-teal-200\/70 {
  border-top-color: rgb(153 246 228 / 0.7) !important;
}

.tw-border-t-teal-200\/75 {
  border-top-color: rgb(153 246 228 / 0.75) !important;
}

.tw-border-t-teal-200\/80 {
  border-top-color: rgb(153 246 228 / 0.8) !important;
}

.tw-border-t-teal-200\/85 {
  border-top-color: rgb(153 246 228 / 0.85) !important;
}

.tw-border-t-teal-200\/90 {
  border-top-color: rgb(153 246 228 / 0.9) !important;
}

.tw-border-t-teal-200\/95 {
  border-top-color: rgb(153 246 228 / 0.95) !important;
}

.tw-border-t-teal-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(94 234 212 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-300\/0 {
  border-top-color: rgb(94 234 212 / 0) !important;
}

.tw-border-t-teal-300\/10 {
  border-top-color: rgb(94 234 212 / 0.1) !important;
}

.tw-border-t-teal-300\/100 {
  border-top-color: rgb(94 234 212 / 1) !important;
}

.tw-border-t-teal-300\/15 {
  border-top-color: rgb(94 234 212 / 0.15) !important;
}

.tw-border-t-teal-300\/20 {
  border-top-color: rgb(94 234 212 / 0.2) !important;
}

.tw-border-t-teal-300\/25 {
  border-top-color: rgb(94 234 212 / 0.25) !important;
}

.tw-border-t-teal-300\/30 {
  border-top-color: rgb(94 234 212 / 0.3) !important;
}

.tw-border-t-teal-300\/35 {
  border-top-color: rgb(94 234 212 / 0.35) !important;
}

.tw-border-t-teal-300\/40 {
  border-top-color: rgb(94 234 212 / 0.4) !important;
}

.tw-border-t-teal-300\/45 {
  border-top-color: rgb(94 234 212 / 0.45) !important;
}

.tw-border-t-teal-300\/5 {
  border-top-color: rgb(94 234 212 / 0.05) !important;
}

.tw-border-t-teal-300\/50 {
  border-top-color: rgb(94 234 212 / 0.5) !important;
}

.tw-border-t-teal-300\/55 {
  border-top-color: rgb(94 234 212 / 0.55) !important;
}

.tw-border-t-teal-300\/60 {
  border-top-color: rgb(94 234 212 / 0.6) !important;
}

.tw-border-t-teal-300\/65 {
  border-top-color: rgb(94 234 212 / 0.65) !important;
}

.tw-border-t-teal-300\/70 {
  border-top-color: rgb(94 234 212 / 0.7) !important;
}

.tw-border-t-teal-300\/75 {
  border-top-color: rgb(94 234 212 / 0.75) !important;
}

.tw-border-t-teal-300\/80 {
  border-top-color: rgb(94 234 212 / 0.8) !important;
}

.tw-border-t-teal-300\/85 {
  border-top-color: rgb(94 234 212 / 0.85) !important;
}

.tw-border-t-teal-300\/90 {
  border-top-color: rgb(94 234 212 / 0.9) !important;
}

.tw-border-t-teal-300\/95 {
  border-top-color: rgb(94 234 212 / 0.95) !important;
}

.tw-border-t-teal-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(45 212 191 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-400\/0 {
  border-top-color: rgb(45 212 191 / 0) !important;
}

.tw-border-t-teal-400\/10 {
  border-top-color: rgb(45 212 191 / 0.1) !important;
}

.tw-border-t-teal-400\/100 {
  border-top-color: rgb(45 212 191 / 1) !important;
}

.tw-border-t-teal-400\/15 {
  border-top-color: rgb(45 212 191 / 0.15) !important;
}

.tw-border-t-teal-400\/20 {
  border-top-color: rgb(45 212 191 / 0.2) !important;
}

.tw-border-t-teal-400\/25 {
  border-top-color: rgb(45 212 191 / 0.25) !important;
}

.tw-border-t-teal-400\/30 {
  border-top-color: rgb(45 212 191 / 0.3) !important;
}

.tw-border-t-teal-400\/35 {
  border-top-color: rgb(45 212 191 / 0.35) !important;
}

.tw-border-t-teal-400\/40 {
  border-top-color: rgb(45 212 191 / 0.4) !important;
}

.tw-border-t-teal-400\/45 {
  border-top-color: rgb(45 212 191 / 0.45) !important;
}

.tw-border-t-teal-400\/5 {
  border-top-color: rgb(45 212 191 / 0.05) !important;
}

.tw-border-t-teal-400\/50 {
  border-top-color: rgb(45 212 191 / 0.5) !important;
}

.tw-border-t-teal-400\/55 {
  border-top-color: rgb(45 212 191 / 0.55) !important;
}

.tw-border-t-teal-400\/60 {
  border-top-color: rgb(45 212 191 / 0.6) !important;
}

.tw-border-t-teal-400\/65 {
  border-top-color: rgb(45 212 191 / 0.65) !important;
}

.tw-border-t-teal-400\/70 {
  border-top-color: rgb(45 212 191 / 0.7) !important;
}

.tw-border-t-teal-400\/75 {
  border-top-color: rgb(45 212 191 / 0.75) !important;
}

.tw-border-t-teal-400\/80 {
  border-top-color: rgb(45 212 191 / 0.8) !important;
}

.tw-border-t-teal-400\/85 {
  border-top-color: rgb(45 212 191 / 0.85) !important;
}

.tw-border-t-teal-400\/90 {
  border-top-color: rgb(45 212 191 / 0.9) !important;
}

.tw-border-t-teal-400\/95 {
  border-top-color: rgb(45 212 191 / 0.95) !important;
}

.tw-border-t-teal-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(240 253 250 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-50\/0 {
  border-top-color: rgb(240 253 250 / 0) !important;
}

.tw-border-t-teal-50\/10 {
  border-top-color: rgb(240 253 250 / 0.1) !important;
}

.tw-border-t-teal-50\/100 {
  border-top-color: rgb(240 253 250 / 1) !important;
}

.tw-border-t-teal-50\/15 {
  border-top-color: rgb(240 253 250 / 0.15) !important;
}

.tw-border-t-teal-50\/20 {
  border-top-color: rgb(240 253 250 / 0.2) !important;
}

.tw-border-t-teal-50\/25 {
  border-top-color: rgb(240 253 250 / 0.25) !important;
}

.tw-border-t-teal-50\/30 {
  border-top-color: rgb(240 253 250 / 0.3) !important;
}

.tw-border-t-teal-50\/35 {
  border-top-color: rgb(240 253 250 / 0.35) !important;
}

.tw-border-t-teal-50\/40 {
  border-top-color: rgb(240 253 250 / 0.4) !important;
}

.tw-border-t-teal-50\/45 {
  border-top-color: rgb(240 253 250 / 0.45) !important;
}

.tw-border-t-teal-50\/5 {
  border-top-color: rgb(240 253 250 / 0.05) !important;
}

.tw-border-t-teal-50\/50 {
  border-top-color: rgb(240 253 250 / 0.5) !important;
}

.tw-border-t-teal-50\/55 {
  border-top-color: rgb(240 253 250 / 0.55) !important;
}

.tw-border-t-teal-50\/60 {
  border-top-color: rgb(240 253 250 / 0.6) !important;
}

.tw-border-t-teal-50\/65 {
  border-top-color: rgb(240 253 250 / 0.65) !important;
}

.tw-border-t-teal-50\/70 {
  border-top-color: rgb(240 253 250 / 0.7) !important;
}

.tw-border-t-teal-50\/75 {
  border-top-color: rgb(240 253 250 / 0.75) !important;
}

.tw-border-t-teal-50\/80 {
  border-top-color: rgb(240 253 250 / 0.8) !important;
}

.tw-border-t-teal-50\/85 {
  border-top-color: rgb(240 253 250 / 0.85) !important;
}

.tw-border-t-teal-50\/90 {
  border-top-color: rgb(240 253 250 / 0.9) !important;
}

.tw-border-t-teal-50\/95 {
  border-top-color: rgb(240 253 250 / 0.95) !important;
}

.tw-border-t-teal-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(20 184 166 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-500\/0 {
  border-top-color: rgb(20 184 166 / 0) !important;
}

.tw-border-t-teal-500\/10 {
  border-top-color: rgb(20 184 166 / 0.1) !important;
}

.tw-border-t-teal-500\/100 {
  border-top-color: rgb(20 184 166 / 1) !important;
}

.tw-border-t-teal-500\/15 {
  border-top-color: rgb(20 184 166 / 0.15) !important;
}

.tw-border-t-teal-500\/20 {
  border-top-color: rgb(20 184 166 / 0.2) !important;
}

.tw-border-t-teal-500\/25 {
  border-top-color: rgb(20 184 166 / 0.25) !important;
}

.tw-border-t-teal-500\/30 {
  border-top-color: rgb(20 184 166 / 0.3) !important;
}

.tw-border-t-teal-500\/35 {
  border-top-color: rgb(20 184 166 / 0.35) !important;
}

.tw-border-t-teal-500\/40 {
  border-top-color: rgb(20 184 166 / 0.4) !important;
}

.tw-border-t-teal-500\/45 {
  border-top-color: rgb(20 184 166 / 0.45) !important;
}

.tw-border-t-teal-500\/5 {
  border-top-color: rgb(20 184 166 / 0.05) !important;
}

.tw-border-t-teal-500\/50 {
  border-top-color: rgb(20 184 166 / 0.5) !important;
}

.tw-border-t-teal-500\/55 {
  border-top-color: rgb(20 184 166 / 0.55) !important;
}

.tw-border-t-teal-500\/60 {
  border-top-color: rgb(20 184 166 / 0.6) !important;
}

.tw-border-t-teal-500\/65 {
  border-top-color: rgb(20 184 166 / 0.65) !important;
}

.tw-border-t-teal-500\/70 {
  border-top-color: rgb(20 184 166 / 0.7) !important;
}

.tw-border-t-teal-500\/75 {
  border-top-color: rgb(20 184 166 / 0.75) !important;
}

.tw-border-t-teal-500\/80 {
  border-top-color: rgb(20 184 166 / 0.8) !important;
}

.tw-border-t-teal-500\/85 {
  border-top-color: rgb(20 184 166 / 0.85) !important;
}

.tw-border-t-teal-500\/90 {
  border-top-color: rgb(20 184 166 / 0.9) !important;
}

.tw-border-t-teal-500\/95 {
  border-top-color: rgb(20 184 166 / 0.95) !important;
}

.tw-border-t-teal-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(13 148 136 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-600\/0 {
  border-top-color: rgb(13 148 136 / 0) !important;
}

.tw-border-t-teal-600\/10 {
  border-top-color: rgb(13 148 136 / 0.1) !important;
}

.tw-border-t-teal-600\/100 {
  border-top-color: rgb(13 148 136 / 1) !important;
}

.tw-border-t-teal-600\/15 {
  border-top-color: rgb(13 148 136 / 0.15) !important;
}

.tw-border-t-teal-600\/20 {
  border-top-color: rgb(13 148 136 / 0.2) !important;
}

.tw-border-t-teal-600\/25 {
  border-top-color: rgb(13 148 136 / 0.25) !important;
}

.tw-border-t-teal-600\/30 {
  border-top-color: rgb(13 148 136 / 0.3) !important;
}

.tw-border-t-teal-600\/35 {
  border-top-color: rgb(13 148 136 / 0.35) !important;
}

.tw-border-t-teal-600\/40 {
  border-top-color: rgb(13 148 136 / 0.4) !important;
}

.tw-border-t-teal-600\/45 {
  border-top-color: rgb(13 148 136 / 0.45) !important;
}

.tw-border-t-teal-600\/5 {
  border-top-color: rgb(13 148 136 / 0.05) !important;
}

.tw-border-t-teal-600\/50 {
  border-top-color: rgb(13 148 136 / 0.5) !important;
}

.tw-border-t-teal-600\/55 {
  border-top-color: rgb(13 148 136 / 0.55) !important;
}

.tw-border-t-teal-600\/60 {
  border-top-color: rgb(13 148 136 / 0.6) !important;
}

.tw-border-t-teal-600\/65 {
  border-top-color: rgb(13 148 136 / 0.65) !important;
}

.tw-border-t-teal-600\/70 {
  border-top-color: rgb(13 148 136 / 0.7) !important;
}

.tw-border-t-teal-600\/75 {
  border-top-color: rgb(13 148 136 / 0.75) !important;
}

.tw-border-t-teal-600\/80 {
  border-top-color: rgb(13 148 136 / 0.8) !important;
}

.tw-border-t-teal-600\/85 {
  border-top-color: rgb(13 148 136 / 0.85) !important;
}

.tw-border-t-teal-600\/90 {
  border-top-color: rgb(13 148 136 / 0.9) !important;
}

.tw-border-t-teal-600\/95 {
  border-top-color: rgb(13 148 136 / 0.95) !important;
}

.tw-border-t-teal-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(15 118 110 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-700\/0 {
  border-top-color: rgb(15 118 110 / 0) !important;
}

.tw-border-t-teal-700\/10 {
  border-top-color: rgb(15 118 110 / 0.1) !important;
}

.tw-border-t-teal-700\/100 {
  border-top-color: rgb(15 118 110 / 1) !important;
}

.tw-border-t-teal-700\/15 {
  border-top-color: rgb(15 118 110 / 0.15) !important;
}

.tw-border-t-teal-700\/20 {
  border-top-color: rgb(15 118 110 / 0.2) !important;
}

.tw-border-t-teal-700\/25 {
  border-top-color: rgb(15 118 110 / 0.25) !important;
}

.tw-border-t-teal-700\/30 {
  border-top-color: rgb(15 118 110 / 0.3) !important;
}

.tw-border-t-teal-700\/35 {
  border-top-color: rgb(15 118 110 / 0.35) !important;
}

.tw-border-t-teal-700\/40 {
  border-top-color: rgb(15 118 110 / 0.4) !important;
}

.tw-border-t-teal-700\/45 {
  border-top-color: rgb(15 118 110 / 0.45) !important;
}

.tw-border-t-teal-700\/5 {
  border-top-color: rgb(15 118 110 / 0.05) !important;
}

.tw-border-t-teal-700\/50 {
  border-top-color: rgb(15 118 110 / 0.5) !important;
}

.tw-border-t-teal-700\/55 {
  border-top-color: rgb(15 118 110 / 0.55) !important;
}

.tw-border-t-teal-700\/60 {
  border-top-color: rgb(15 118 110 / 0.6) !important;
}

.tw-border-t-teal-700\/65 {
  border-top-color: rgb(15 118 110 / 0.65) !important;
}

.tw-border-t-teal-700\/70 {
  border-top-color: rgb(15 118 110 / 0.7) !important;
}

.tw-border-t-teal-700\/75 {
  border-top-color: rgb(15 118 110 / 0.75) !important;
}

.tw-border-t-teal-700\/80 {
  border-top-color: rgb(15 118 110 / 0.8) !important;
}

.tw-border-t-teal-700\/85 {
  border-top-color: rgb(15 118 110 / 0.85) !important;
}

.tw-border-t-teal-700\/90 {
  border-top-color: rgb(15 118 110 / 0.9) !important;
}

.tw-border-t-teal-700\/95 {
  border-top-color: rgb(15 118 110 / 0.95) !important;
}

.tw-border-t-teal-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(17 94 89 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-800\/0 {
  border-top-color: rgb(17 94 89 / 0) !important;
}

.tw-border-t-teal-800\/10 {
  border-top-color: rgb(17 94 89 / 0.1) !important;
}

.tw-border-t-teal-800\/100 {
  border-top-color: rgb(17 94 89 / 1) !important;
}

.tw-border-t-teal-800\/15 {
  border-top-color: rgb(17 94 89 / 0.15) !important;
}

.tw-border-t-teal-800\/20 {
  border-top-color: rgb(17 94 89 / 0.2) !important;
}

.tw-border-t-teal-800\/25 {
  border-top-color: rgb(17 94 89 / 0.25) !important;
}

.tw-border-t-teal-800\/30 {
  border-top-color: rgb(17 94 89 / 0.3) !important;
}

.tw-border-t-teal-800\/35 {
  border-top-color: rgb(17 94 89 / 0.35) !important;
}

.tw-border-t-teal-800\/40 {
  border-top-color: rgb(17 94 89 / 0.4) !important;
}

.tw-border-t-teal-800\/45 {
  border-top-color: rgb(17 94 89 / 0.45) !important;
}

.tw-border-t-teal-800\/5 {
  border-top-color: rgb(17 94 89 / 0.05) !important;
}

.tw-border-t-teal-800\/50 {
  border-top-color: rgb(17 94 89 / 0.5) !important;
}

.tw-border-t-teal-800\/55 {
  border-top-color: rgb(17 94 89 / 0.55) !important;
}

.tw-border-t-teal-800\/60 {
  border-top-color: rgb(17 94 89 / 0.6) !important;
}

.tw-border-t-teal-800\/65 {
  border-top-color: rgb(17 94 89 / 0.65) !important;
}

.tw-border-t-teal-800\/70 {
  border-top-color: rgb(17 94 89 / 0.7) !important;
}

.tw-border-t-teal-800\/75 {
  border-top-color: rgb(17 94 89 / 0.75) !important;
}

.tw-border-t-teal-800\/80 {
  border-top-color: rgb(17 94 89 / 0.8) !important;
}

.tw-border-t-teal-800\/85 {
  border-top-color: rgb(17 94 89 / 0.85) !important;
}

.tw-border-t-teal-800\/90 {
  border-top-color: rgb(17 94 89 / 0.9) !important;
}

.tw-border-t-teal-800\/95 {
  border-top-color: rgb(17 94 89 / 0.95) !important;
}

.tw-border-t-teal-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(19 78 74 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-900\/0 {
  border-top-color: rgb(19 78 74 / 0) !important;
}

.tw-border-t-teal-900\/10 {
  border-top-color: rgb(19 78 74 / 0.1) !important;
}

.tw-border-t-teal-900\/100 {
  border-top-color: rgb(19 78 74 / 1) !important;
}

.tw-border-t-teal-900\/15 {
  border-top-color: rgb(19 78 74 / 0.15) !important;
}

.tw-border-t-teal-900\/20 {
  border-top-color: rgb(19 78 74 / 0.2) !important;
}

.tw-border-t-teal-900\/25 {
  border-top-color: rgb(19 78 74 / 0.25) !important;
}

.tw-border-t-teal-900\/30 {
  border-top-color: rgb(19 78 74 / 0.3) !important;
}

.tw-border-t-teal-900\/35 {
  border-top-color: rgb(19 78 74 / 0.35) !important;
}

.tw-border-t-teal-900\/40 {
  border-top-color: rgb(19 78 74 / 0.4) !important;
}

.tw-border-t-teal-900\/45 {
  border-top-color: rgb(19 78 74 / 0.45) !important;
}

.tw-border-t-teal-900\/5 {
  border-top-color: rgb(19 78 74 / 0.05) !important;
}

.tw-border-t-teal-900\/50 {
  border-top-color: rgb(19 78 74 / 0.5) !important;
}

.tw-border-t-teal-900\/55 {
  border-top-color: rgb(19 78 74 / 0.55) !important;
}

.tw-border-t-teal-900\/60 {
  border-top-color: rgb(19 78 74 / 0.6) !important;
}

.tw-border-t-teal-900\/65 {
  border-top-color: rgb(19 78 74 / 0.65) !important;
}

.tw-border-t-teal-900\/70 {
  border-top-color: rgb(19 78 74 / 0.7) !important;
}

.tw-border-t-teal-900\/75 {
  border-top-color: rgb(19 78 74 / 0.75) !important;
}

.tw-border-t-teal-900\/80 {
  border-top-color: rgb(19 78 74 / 0.8) !important;
}

.tw-border-t-teal-900\/85 {
  border-top-color: rgb(19 78 74 / 0.85) !important;
}

.tw-border-t-teal-900\/90 {
  border-top-color: rgb(19 78 74 / 0.9) !important;
}

.tw-border-t-teal-900\/95 {
  border-top-color: rgb(19 78 74 / 0.95) !important;
}

.tw-border-t-teal-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(4 47 46 / var(--tw-border-opacity)) !important;
}

.tw-border-t-teal-950\/0 {
  border-top-color: rgb(4 47 46 / 0) !important;
}

.tw-border-t-teal-950\/10 {
  border-top-color: rgb(4 47 46 / 0.1) !important;
}

.tw-border-t-teal-950\/100 {
  border-top-color: rgb(4 47 46 / 1) !important;
}

.tw-border-t-teal-950\/15 {
  border-top-color: rgb(4 47 46 / 0.15) !important;
}

.tw-border-t-teal-950\/20 {
  border-top-color: rgb(4 47 46 / 0.2) !important;
}

.tw-border-t-teal-950\/25 {
  border-top-color: rgb(4 47 46 / 0.25) !important;
}

.tw-border-t-teal-950\/30 {
  border-top-color: rgb(4 47 46 / 0.3) !important;
}

.tw-border-t-teal-950\/35 {
  border-top-color: rgb(4 47 46 / 0.35) !important;
}

.tw-border-t-teal-950\/40 {
  border-top-color: rgb(4 47 46 / 0.4) !important;
}

.tw-border-t-teal-950\/45 {
  border-top-color: rgb(4 47 46 / 0.45) !important;
}

.tw-border-t-teal-950\/5 {
  border-top-color: rgb(4 47 46 / 0.05) !important;
}

.tw-border-t-teal-950\/50 {
  border-top-color: rgb(4 47 46 / 0.5) !important;
}

.tw-border-t-teal-950\/55 {
  border-top-color: rgb(4 47 46 / 0.55) !important;
}

.tw-border-t-teal-950\/60 {
  border-top-color: rgb(4 47 46 / 0.6) !important;
}

.tw-border-t-teal-950\/65 {
  border-top-color: rgb(4 47 46 / 0.65) !important;
}

.tw-border-t-teal-950\/70 {
  border-top-color: rgb(4 47 46 / 0.7) !important;
}

.tw-border-t-teal-950\/75 {
  border-top-color: rgb(4 47 46 / 0.75) !important;
}

.tw-border-t-teal-950\/80 {
  border-top-color: rgb(4 47 46 / 0.8) !important;
}

.tw-border-t-teal-950\/85 {
  border-top-color: rgb(4 47 46 / 0.85) !important;
}

.tw-border-t-teal-950\/90 {
  border-top-color: rgb(4 47 46 / 0.9) !important;
}

.tw-border-t-teal-950\/95 {
  border-top-color: rgb(4 47 46 / 0.95) !important;
}

.tw-border-t-transparent {
  border-top-color: transparent !important;
}

.tw-border-t-transparent\/0 {
  border-top-color: rgb(0 0 0 / 0) !important;
}

.tw-border-t-transparent\/10 {
  border-top-color: rgb(0 0 0 / 0.1) !important;
}

.tw-border-t-transparent\/100 {
  border-top-color: rgb(0 0 0 / 1) !important;
}

.tw-border-t-transparent\/15 {
  border-top-color: rgb(0 0 0 / 0.15) !important;
}

.tw-border-t-transparent\/20 {
  border-top-color: rgb(0 0 0 / 0.2) !important;
}

.tw-border-t-transparent\/25 {
  border-top-color: rgb(0 0 0 / 0.25) !important;
}

.tw-border-t-transparent\/30 {
  border-top-color: rgb(0 0 0 / 0.3) !important;
}

.tw-border-t-transparent\/35 {
  border-top-color: rgb(0 0 0 / 0.35) !important;
}

.tw-border-t-transparent\/40 {
  border-top-color: rgb(0 0 0 / 0.4) !important;
}

.tw-border-t-transparent\/45 {
  border-top-color: rgb(0 0 0 / 0.45) !important;
}

.tw-border-t-transparent\/5 {
  border-top-color: rgb(0 0 0 / 0.05) !important;
}

.tw-border-t-transparent\/50 {
  border-top-color: rgb(0 0 0 / 0.5) !important;
}

.tw-border-t-transparent\/55 {
  border-top-color: rgb(0 0 0 / 0.55) !important;
}

.tw-border-t-transparent\/60 {
  border-top-color: rgb(0 0 0 / 0.6) !important;
}

.tw-border-t-transparent\/65 {
  border-top-color: rgb(0 0 0 / 0.65) !important;
}

.tw-border-t-transparent\/70 {
  border-top-color: rgb(0 0 0 / 0.7) !important;
}

.tw-border-t-transparent\/75 {
  border-top-color: rgb(0 0 0 / 0.75) !important;
}

.tw-border-t-transparent\/80 {
  border-top-color: rgb(0 0 0 / 0.8) !important;
}

.tw-border-t-transparent\/85 {
  border-top-color: rgb(0 0 0 / 0.85) !important;
}

.tw-border-t-transparent\/90 {
  border-top-color: rgb(0 0 0 / 0.9) !important;
}

.tw-border-t-transparent\/95 {
  border-top-color: rgb(0 0 0 / 0.95) !important;
}

.tw-border-t-violet-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(237 233 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-100\/0 {
  border-top-color: rgb(237 233 254 / 0) !important;
}

.tw-border-t-violet-100\/10 {
  border-top-color: rgb(237 233 254 / 0.1) !important;
}

.tw-border-t-violet-100\/100 {
  border-top-color: rgb(237 233 254 / 1) !important;
}

.tw-border-t-violet-100\/15 {
  border-top-color: rgb(237 233 254 / 0.15) !important;
}

.tw-border-t-violet-100\/20 {
  border-top-color: rgb(237 233 254 / 0.2) !important;
}

.tw-border-t-violet-100\/25 {
  border-top-color: rgb(237 233 254 / 0.25) !important;
}

.tw-border-t-violet-100\/30 {
  border-top-color: rgb(237 233 254 / 0.3) !important;
}

.tw-border-t-violet-100\/35 {
  border-top-color: rgb(237 233 254 / 0.35) !important;
}

.tw-border-t-violet-100\/40 {
  border-top-color: rgb(237 233 254 / 0.4) !important;
}

.tw-border-t-violet-100\/45 {
  border-top-color: rgb(237 233 254 / 0.45) !important;
}

.tw-border-t-violet-100\/5 {
  border-top-color: rgb(237 233 254 / 0.05) !important;
}

.tw-border-t-violet-100\/50 {
  border-top-color: rgb(237 233 254 / 0.5) !important;
}

.tw-border-t-violet-100\/55 {
  border-top-color: rgb(237 233 254 / 0.55) !important;
}

.tw-border-t-violet-100\/60 {
  border-top-color: rgb(237 233 254 / 0.6) !important;
}

.tw-border-t-violet-100\/65 {
  border-top-color: rgb(237 233 254 / 0.65) !important;
}

.tw-border-t-violet-100\/70 {
  border-top-color: rgb(237 233 254 / 0.7) !important;
}

.tw-border-t-violet-100\/75 {
  border-top-color: rgb(237 233 254 / 0.75) !important;
}

.tw-border-t-violet-100\/80 {
  border-top-color: rgb(237 233 254 / 0.8) !important;
}

.tw-border-t-violet-100\/85 {
  border-top-color: rgb(237 233 254 / 0.85) !important;
}

.tw-border-t-violet-100\/90 {
  border-top-color: rgb(237 233 254 / 0.9) !important;
}

.tw-border-t-violet-100\/95 {
  border-top-color: rgb(237 233 254 / 0.95) !important;
}

.tw-border-t-violet-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(221 214 254 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-200\/0 {
  border-top-color: rgb(221 214 254 / 0) !important;
}

.tw-border-t-violet-200\/10 {
  border-top-color: rgb(221 214 254 / 0.1) !important;
}

.tw-border-t-violet-200\/100 {
  border-top-color: rgb(221 214 254 / 1) !important;
}

.tw-border-t-violet-200\/15 {
  border-top-color: rgb(221 214 254 / 0.15) !important;
}

.tw-border-t-violet-200\/20 {
  border-top-color: rgb(221 214 254 / 0.2) !important;
}

.tw-border-t-violet-200\/25 {
  border-top-color: rgb(221 214 254 / 0.25) !important;
}

.tw-border-t-violet-200\/30 {
  border-top-color: rgb(221 214 254 / 0.3) !important;
}

.tw-border-t-violet-200\/35 {
  border-top-color: rgb(221 214 254 / 0.35) !important;
}

.tw-border-t-violet-200\/40 {
  border-top-color: rgb(221 214 254 / 0.4) !important;
}

.tw-border-t-violet-200\/45 {
  border-top-color: rgb(221 214 254 / 0.45) !important;
}

.tw-border-t-violet-200\/5 {
  border-top-color: rgb(221 214 254 / 0.05) !important;
}

.tw-border-t-violet-200\/50 {
  border-top-color: rgb(221 214 254 / 0.5) !important;
}

.tw-border-t-violet-200\/55 {
  border-top-color: rgb(221 214 254 / 0.55) !important;
}

.tw-border-t-violet-200\/60 {
  border-top-color: rgb(221 214 254 / 0.6) !important;
}

.tw-border-t-violet-200\/65 {
  border-top-color: rgb(221 214 254 / 0.65) !important;
}

.tw-border-t-violet-200\/70 {
  border-top-color: rgb(221 214 254 / 0.7) !important;
}

.tw-border-t-violet-200\/75 {
  border-top-color: rgb(221 214 254 / 0.75) !important;
}

.tw-border-t-violet-200\/80 {
  border-top-color: rgb(221 214 254 / 0.8) !important;
}

.tw-border-t-violet-200\/85 {
  border-top-color: rgb(221 214 254 / 0.85) !important;
}

.tw-border-t-violet-200\/90 {
  border-top-color: rgb(221 214 254 / 0.9) !important;
}

.tw-border-t-violet-200\/95 {
  border-top-color: rgb(221 214 254 / 0.95) !important;
}

.tw-border-t-violet-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(196 181 253 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-300\/0 {
  border-top-color: rgb(196 181 253 / 0) !important;
}

.tw-border-t-violet-300\/10 {
  border-top-color: rgb(196 181 253 / 0.1) !important;
}

.tw-border-t-violet-300\/100 {
  border-top-color: rgb(196 181 253 / 1) !important;
}

.tw-border-t-violet-300\/15 {
  border-top-color: rgb(196 181 253 / 0.15) !important;
}

.tw-border-t-violet-300\/20 {
  border-top-color: rgb(196 181 253 / 0.2) !important;
}

.tw-border-t-violet-300\/25 {
  border-top-color: rgb(196 181 253 / 0.25) !important;
}

.tw-border-t-violet-300\/30 {
  border-top-color: rgb(196 181 253 / 0.3) !important;
}

.tw-border-t-violet-300\/35 {
  border-top-color: rgb(196 181 253 / 0.35) !important;
}

.tw-border-t-violet-300\/40 {
  border-top-color: rgb(196 181 253 / 0.4) !important;
}

.tw-border-t-violet-300\/45 {
  border-top-color: rgb(196 181 253 / 0.45) !important;
}

.tw-border-t-violet-300\/5 {
  border-top-color: rgb(196 181 253 / 0.05) !important;
}

.tw-border-t-violet-300\/50 {
  border-top-color: rgb(196 181 253 / 0.5) !important;
}

.tw-border-t-violet-300\/55 {
  border-top-color: rgb(196 181 253 / 0.55) !important;
}

.tw-border-t-violet-300\/60 {
  border-top-color: rgb(196 181 253 / 0.6) !important;
}

.tw-border-t-violet-300\/65 {
  border-top-color: rgb(196 181 253 / 0.65) !important;
}

.tw-border-t-violet-300\/70 {
  border-top-color: rgb(196 181 253 / 0.7) !important;
}

.tw-border-t-violet-300\/75 {
  border-top-color: rgb(196 181 253 / 0.75) !important;
}

.tw-border-t-violet-300\/80 {
  border-top-color: rgb(196 181 253 / 0.8) !important;
}

.tw-border-t-violet-300\/85 {
  border-top-color: rgb(196 181 253 / 0.85) !important;
}

.tw-border-t-violet-300\/90 {
  border-top-color: rgb(196 181 253 / 0.9) !important;
}

.tw-border-t-violet-300\/95 {
  border-top-color: rgb(196 181 253 / 0.95) !important;
}

.tw-border-t-violet-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(167 139 250 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-400\/0 {
  border-top-color: rgb(167 139 250 / 0) !important;
}

.tw-border-t-violet-400\/10 {
  border-top-color: rgb(167 139 250 / 0.1) !important;
}

.tw-border-t-violet-400\/100 {
  border-top-color: rgb(167 139 250 / 1) !important;
}

.tw-border-t-violet-400\/15 {
  border-top-color: rgb(167 139 250 / 0.15) !important;
}

.tw-border-t-violet-400\/20 {
  border-top-color: rgb(167 139 250 / 0.2) !important;
}

.tw-border-t-violet-400\/25 {
  border-top-color: rgb(167 139 250 / 0.25) !important;
}

.tw-border-t-violet-400\/30 {
  border-top-color: rgb(167 139 250 / 0.3) !important;
}

.tw-border-t-violet-400\/35 {
  border-top-color: rgb(167 139 250 / 0.35) !important;
}

.tw-border-t-violet-400\/40 {
  border-top-color: rgb(167 139 250 / 0.4) !important;
}

.tw-border-t-violet-400\/45 {
  border-top-color: rgb(167 139 250 / 0.45) !important;
}

.tw-border-t-violet-400\/5 {
  border-top-color: rgb(167 139 250 / 0.05) !important;
}

.tw-border-t-violet-400\/50 {
  border-top-color: rgb(167 139 250 / 0.5) !important;
}

.tw-border-t-violet-400\/55 {
  border-top-color: rgb(167 139 250 / 0.55) !important;
}

.tw-border-t-violet-400\/60 {
  border-top-color: rgb(167 139 250 / 0.6) !important;
}

.tw-border-t-violet-400\/65 {
  border-top-color: rgb(167 139 250 / 0.65) !important;
}

.tw-border-t-violet-400\/70 {
  border-top-color: rgb(167 139 250 / 0.7) !important;
}

.tw-border-t-violet-400\/75 {
  border-top-color: rgb(167 139 250 / 0.75) !important;
}

.tw-border-t-violet-400\/80 {
  border-top-color: rgb(167 139 250 / 0.8) !important;
}

.tw-border-t-violet-400\/85 {
  border-top-color: rgb(167 139 250 / 0.85) !important;
}

.tw-border-t-violet-400\/90 {
  border-top-color: rgb(167 139 250 / 0.9) !important;
}

.tw-border-t-violet-400\/95 {
  border-top-color: rgb(167 139 250 / 0.95) !important;
}

.tw-border-t-violet-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(245 243 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-50\/0 {
  border-top-color: rgb(245 243 255 / 0) !important;
}

.tw-border-t-violet-50\/10 {
  border-top-color: rgb(245 243 255 / 0.1) !important;
}

.tw-border-t-violet-50\/100 {
  border-top-color: rgb(245 243 255 / 1) !important;
}

.tw-border-t-violet-50\/15 {
  border-top-color: rgb(245 243 255 / 0.15) !important;
}

.tw-border-t-violet-50\/20 {
  border-top-color: rgb(245 243 255 / 0.2) !important;
}

.tw-border-t-violet-50\/25 {
  border-top-color: rgb(245 243 255 / 0.25) !important;
}

.tw-border-t-violet-50\/30 {
  border-top-color: rgb(245 243 255 / 0.3) !important;
}

.tw-border-t-violet-50\/35 {
  border-top-color: rgb(245 243 255 / 0.35) !important;
}

.tw-border-t-violet-50\/40 {
  border-top-color: rgb(245 243 255 / 0.4) !important;
}

.tw-border-t-violet-50\/45 {
  border-top-color: rgb(245 243 255 / 0.45) !important;
}

.tw-border-t-violet-50\/5 {
  border-top-color: rgb(245 243 255 / 0.05) !important;
}

.tw-border-t-violet-50\/50 {
  border-top-color: rgb(245 243 255 / 0.5) !important;
}

.tw-border-t-violet-50\/55 {
  border-top-color: rgb(245 243 255 / 0.55) !important;
}

.tw-border-t-violet-50\/60 {
  border-top-color: rgb(245 243 255 / 0.6) !important;
}

.tw-border-t-violet-50\/65 {
  border-top-color: rgb(245 243 255 / 0.65) !important;
}

.tw-border-t-violet-50\/70 {
  border-top-color: rgb(245 243 255 / 0.7) !important;
}

.tw-border-t-violet-50\/75 {
  border-top-color: rgb(245 243 255 / 0.75) !important;
}

.tw-border-t-violet-50\/80 {
  border-top-color: rgb(245 243 255 / 0.8) !important;
}

.tw-border-t-violet-50\/85 {
  border-top-color: rgb(245 243 255 / 0.85) !important;
}

.tw-border-t-violet-50\/90 {
  border-top-color: rgb(245 243 255 / 0.9) !important;
}

.tw-border-t-violet-50\/95 {
  border-top-color: rgb(245 243 255 / 0.95) !important;
}

.tw-border-t-violet-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(139 92 246 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-500\/0 {
  border-top-color: rgb(139 92 246 / 0) !important;
}

.tw-border-t-violet-500\/10 {
  border-top-color: rgb(139 92 246 / 0.1) !important;
}

.tw-border-t-violet-500\/100 {
  border-top-color: rgb(139 92 246 / 1) !important;
}

.tw-border-t-violet-500\/15 {
  border-top-color: rgb(139 92 246 / 0.15) !important;
}

.tw-border-t-violet-500\/20 {
  border-top-color: rgb(139 92 246 / 0.2) !important;
}

.tw-border-t-violet-500\/25 {
  border-top-color: rgb(139 92 246 / 0.25) !important;
}

.tw-border-t-violet-500\/30 {
  border-top-color: rgb(139 92 246 / 0.3) !important;
}

.tw-border-t-violet-500\/35 {
  border-top-color: rgb(139 92 246 / 0.35) !important;
}

.tw-border-t-violet-500\/40 {
  border-top-color: rgb(139 92 246 / 0.4) !important;
}

.tw-border-t-violet-500\/45 {
  border-top-color: rgb(139 92 246 / 0.45) !important;
}

.tw-border-t-violet-500\/5 {
  border-top-color: rgb(139 92 246 / 0.05) !important;
}

.tw-border-t-violet-500\/50 {
  border-top-color: rgb(139 92 246 / 0.5) !important;
}

.tw-border-t-violet-500\/55 {
  border-top-color: rgb(139 92 246 / 0.55) !important;
}

.tw-border-t-violet-500\/60 {
  border-top-color: rgb(139 92 246 / 0.6) !important;
}

.tw-border-t-violet-500\/65 {
  border-top-color: rgb(139 92 246 / 0.65) !important;
}

.tw-border-t-violet-500\/70 {
  border-top-color: rgb(139 92 246 / 0.7) !important;
}

.tw-border-t-violet-500\/75 {
  border-top-color: rgb(139 92 246 / 0.75) !important;
}

.tw-border-t-violet-500\/80 {
  border-top-color: rgb(139 92 246 / 0.8) !important;
}

.tw-border-t-violet-500\/85 {
  border-top-color: rgb(139 92 246 / 0.85) !important;
}

.tw-border-t-violet-500\/90 {
  border-top-color: rgb(139 92 246 / 0.9) !important;
}

.tw-border-t-violet-500\/95 {
  border-top-color: rgb(139 92 246 / 0.95) !important;
}

.tw-border-t-violet-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(124 58 237 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-600\/0 {
  border-top-color: rgb(124 58 237 / 0) !important;
}

.tw-border-t-violet-600\/10 {
  border-top-color: rgb(124 58 237 / 0.1) !important;
}

.tw-border-t-violet-600\/100 {
  border-top-color: rgb(124 58 237 / 1) !important;
}

.tw-border-t-violet-600\/15 {
  border-top-color: rgb(124 58 237 / 0.15) !important;
}

.tw-border-t-violet-600\/20 {
  border-top-color: rgb(124 58 237 / 0.2) !important;
}

.tw-border-t-violet-600\/25 {
  border-top-color: rgb(124 58 237 / 0.25) !important;
}

.tw-border-t-violet-600\/30 {
  border-top-color: rgb(124 58 237 / 0.3) !important;
}

.tw-border-t-violet-600\/35 {
  border-top-color: rgb(124 58 237 / 0.35) !important;
}

.tw-border-t-violet-600\/40 {
  border-top-color: rgb(124 58 237 / 0.4) !important;
}

.tw-border-t-violet-600\/45 {
  border-top-color: rgb(124 58 237 / 0.45) !important;
}

.tw-border-t-violet-600\/5 {
  border-top-color: rgb(124 58 237 / 0.05) !important;
}

.tw-border-t-violet-600\/50 {
  border-top-color: rgb(124 58 237 / 0.5) !important;
}

.tw-border-t-violet-600\/55 {
  border-top-color: rgb(124 58 237 / 0.55) !important;
}

.tw-border-t-violet-600\/60 {
  border-top-color: rgb(124 58 237 / 0.6) !important;
}

.tw-border-t-violet-600\/65 {
  border-top-color: rgb(124 58 237 / 0.65) !important;
}

.tw-border-t-violet-600\/70 {
  border-top-color: rgb(124 58 237 / 0.7) !important;
}

.tw-border-t-violet-600\/75 {
  border-top-color: rgb(124 58 237 / 0.75) !important;
}

.tw-border-t-violet-600\/80 {
  border-top-color: rgb(124 58 237 / 0.8) !important;
}

.tw-border-t-violet-600\/85 {
  border-top-color: rgb(124 58 237 / 0.85) !important;
}

.tw-border-t-violet-600\/90 {
  border-top-color: rgb(124 58 237 / 0.9) !important;
}

.tw-border-t-violet-600\/95 {
  border-top-color: rgb(124 58 237 / 0.95) !important;
}

.tw-border-t-violet-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(109 40 217 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-700\/0 {
  border-top-color: rgb(109 40 217 / 0) !important;
}

.tw-border-t-violet-700\/10 {
  border-top-color: rgb(109 40 217 / 0.1) !important;
}

.tw-border-t-violet-700\/100 {
  border-top-color: rgb(109 40 217 / 1) !important;
}

.tw-border-t-violet-700\/15 {
  border-top-color: rgb(109 40 217 / 0.15) !important;
}

.tw-border-t-violet-700\/20 {
  border-top-color: rgb(109 40 217 / 0.2) !important;
}

.tw-border-t-violet-700\/25 {
  border-top-color: rgb(109 40 217 / 0.25) !important;
}

.tw-border-t-violet-700\/30 {
  border-top-color: rgb(109 40 217 / 0.3) !important;
}

.tw-border-t-violet-700\/35 {
  border-top-color: rgb(109 40 217 / 0.35) !important;
}

.tw-border-t-violet-700\/40 {
  border-top-color: rgb(109 40 217 / 0.4) !important;
}

.tw-border-t-violet-700\/45 {
  border-top-color: rgb(109 40 217 / 0.45) !important;
}

.tw-border-t-violet-700\/5 {
  border-top-color: rgb(109 40 217 / 0.05) !important;
}

.tw-border-t-violet-700\/50 {
  border-top-color: rgb(109 40 217 / 0.5) !important;
}

.tw-border-t-violet-700\/55 {
  border-top-color: rgb(109 40 217 / 0.55) !important;
}

.tw-border-t-violet-700\/60 {
  border-top-color: rgb(109 40 217 / 0.6) !important;
}

.tw-border-t-violet-700\/65 {
  border-top-color: rgb(109 40 217 / 0.65) !important;
}

.tw-border-t-violet-700\/70 {
  border-top-color: rgb(109 40 217 / 0.7) !important;
}

.tw-border-t-violet-700\/75 {
  border-top-color: rgb(109 40 217 / 0.75) !important;
}

.tw-border-t-violet-700\/80 {
  border-top-color: rgb(109 40 217 / 0.8) !important;
}

.tw-border-t-violet-700\/85 {
  border-top-color: rgb(109 40 217 / 0.85) !important;
}

.tw-border-t-violet-700\/90 {
  border-top-color: rgb(109 40 217 / 0.9) !important;
}

.tw-border-t-violet-700\/95 {
  border-top-color: rgb(109 40 217 / 0.95) !important;
}

.tw-border-t-violet-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(91 33 182 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-800\/0 {
  border-top-color: rgb(91 33 182 / 0) !important;
}

.tw-border-t-violet-800\/10 {
  border-top-color: rgb(91 33 182 / 0.1) !important;
}

.tw-border-t-violet-800\/100 {
  border-top-color: rgb(91 33 182 / 1) !important;
}

.tw-border-t-violet-800\/15 {
  border-top-color: rgb(91 33 182 / 0.15) !important;
}

.tw-border-t-violet-800\/20 {
  border-top-color: rgb(91 33 182 / 0.2) !important;
}

.tw-border-t-violet-800\/25 {
  border-top-color: rgb(91 33 182 / 0.25) !important;
}

.tw-border-t-violet-800\/30 {
  border-top-color: rgb(91 33 182 / 0.3) !important;
}

.tw-border-t-violet-800\/35 {
  border-top-color: rgb(91 33 182 / 0.35) !important;
}

.tw-border-t-violet-800\/40 {
  border-top-color: rgb(91 33 182 / 0.4) !important;
}

.tw-border-t-violet-800\/45 {
  border-top-color: rgb(91 33 182 / 0.45) !important;
}

.tw-border-t-violet-800\/5 {
  border-top-color: rgb(91 33 182 / 0.05) !important;
}

.tw-border-t-violet-800\/50 {
  border-top-color: rgb(91 33 182 / 0.5) !important;
}

.tw-border-t-violet-800\/55 {
  border-top-color: rgb(91 33 182 / 0.55) !important;
}

.tw-border-t-violet-800\/60 {
  border-top-color: rgb(91 33 182 / 0.6) !important;
}

.tw-border-t-violet-800\/65 {
  border-top-color: rgb(91 33 182 / 0.65) !important;
}

.tw-border-t-violet-800\/70 {
  border-top-color: rgb(91 33 182 / 0.7) !important;
}

.tw-border-t-violet-800\/75 {
  border-top-color: rgb(91 33 182 / 0.75) !important;
}

.tw-border-t-violet-800\/80 {
  border-top-color: rgb(91 33 182 / 0.8) !important;
}

.tw-border-t-violet-800\/85 {
  border-top-color: rgb(91 33 182 / 0.85) !important;
}

.tw-border-t-violet-800\/90 {
  border-top-color: rgb(91 33 182 / 0.9) !important;
}

.tw-border-t-violet-800\/95 {
  border-top-color: rgb(91 33 182 / 0.95) !important;
}

.tw-border-t-violet-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(76 29 149 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-900\/0 {
  border-top-color: rgb(76 29 149 / 0) !important;
}

.tw-border-t-violet-900\/10 {
  border-top-color: rgb(76 29 149 / 0.1) !important;
}

.tw-border-t-violet-900\/100 {
  border-top-color: rgb(76 29 149 / 1) !important;
}

.tw-border-t-violet-900\/15 {
  border-top-color: rgb(76 29 149 / 0.15) !important;
}

.tw-border-t-violet-900\/20 {
  border-top-color: rgb(76 29 149 / 0.2) !important;
}

.tw-border-t-violet-900\/25 {
  border-top-color: rgb(76 29 149 / 0.25) !important;
}

.tw-border-t-violet-900\/30 {
  border-top-color: rgb(76 29 149 / 0.3) !important;
}

.tw-border-t-violet-900\/35 {
  border-top-color: rgb(76 29 149 / 0.35) !important;
}

.tw-border-t-violet-900\/40 {
  border-top-color: rgb(76 29 149 / 0.4) !important;
}

.tw-border-t-violet-900\/45 {
  border-top-color: rgb(76 29 149 / 0.45) !important;
}

.tw-border-t-violet-900\/5 {
  border-top-color: rgb(76 29 149 / 0.05) !important;
}

.tw-border-t-violet-900\/50 {
  border-top-color: rgb(76 29 149 / 0.5) !important;
}

.tw-border-t-violet-900\/55 {
  border-top-color: rgb(76 29 149 / 0.55) !important;
}

.tw-border-t-violet-900\/60 {
  border-top-color: rgb(76 29 149 / 0.6) !important;
}

.tw-border-t-violet-900\/65 {
  border-top-color: rgb(76 29 149 / 0.65) !important;
}

.tw-border-t-violet-900\/70 {
  border-top-color: rgb(76 29 149 / 0.7) !important;
}

.tw-border-t-violet-900\/75 {
  border-top-color: rgb(76 29 149 / 0.75) !important;
}

.tw-border-t-violet-900\/80 {
  border-top-color: rgb(76 29 149 / 0.8) !important;
}

.tw-border-t-violet-900\/85 {
  border-top-color: rgb(76 29 149 / 0.85) !important;
}

.tw-border-t-violet-900\/90 {
  border-top-color: rgb(76 29 149 / 0.9) !important;
}

.tw-border-t-violet-900\/95 {
  border-top-color: rgb(76 29 149 / 0.95) !important;
}

.tw-border-t-violet-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(46 16 101 / var(--tw-border-opacity)) !important;
}

.tw-border-t-violet-950\/0 {
  border-top-color: rgb(46 16 101 / 0) !important;
}

.tw-border-t-violet-950\/10 {
  border-top-color: rgb(46 16 101 / 0.1) !important;
}

.tw-border-t-violet-950\/100 {
  border-top-color: rgb(46 16 101 / 1) !important;
}

.tw-border-t-violet-950\/15 {
  border-top-color: rgb(46 16 101 / 0.15) !important;
}

.tw-border-t-violet-950\/20 {
  border-top-color: rgb(46 16 101 / 0.2) !important;
}

.tw-border-t-violet-950\/25 {
  border-top-color: rgb(46 16 101 / 0.25) !important;
}

.tw-border-t-violet-950\/30 {
  border-top-color: rgb(46 16 101 / 0.3) !important;
}

.tw-border-t-violet-950\/35 {
  border-top-color: rgb(46 16 101 / 0.35) !important;
}

.tw-border-t-violet-950\/40 {
  border-top-color: rgb(46 16 101 / 0.4) !important;
}

.tw-border-t-violet-950\/45 {
  border-top-color: rgb(46 16 101 / 0.45) !important;
}

.tw-border-t-violet-950\/5 {
  border-top-color: rgb(46 16 101 / 0.05) !important;
}

.tw-border-t-violet-950\/50 {
  border-top-color: rgb(46 16 101 / 0.5) !important;
}

.tw-border-t-violet-950\/55 {
  border-top-color: rgb(46 16 101 / 0.55) !important;
}

.tw-border-t-violet-950\/60 {
  border-top-color: rgb(46 16 101 / 0.6) !important;
}

.tw-border-t-violet-950\/65 {
  border-top-color: rgb(46 16 101 / 0.65) !important;
}

.tw-border-t-violet-950\/70 {
  border-top-color: rgb(46 16 101 / 0.7) !important;
}

.tw-border-t-violet-950\/75 {
  border-top-color: rgb(46 16 101 / 0.75) !important;
}

.tw-border-t-violet-950\/80 {
  border-top-color: rgb(46 16 101 / 0.8) !important;
}

.tw-border-t-violet-950\/85 {
  border-top-color: rgb(46 16 101 / 0.85) !important;
}

.tw-border-t-violet-950\/90 {
  border-top-color: rgb(46 16 101 / 0.9) !important;
}

.tw-border-t-violet-950\/95 {
  border-top-color: rgb(46 16 101 / 0.95) !important;
}

.tw-border-t-white {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(255 255 255 / var(--tw-border-opacity)) !important;
}

.tw-border-t-white\/0 {
  border-top-color: rgb(255 255 255 / 0) !important;
}

.tw-border-t-white\/10 {
  border-top-color: rgb(255 255 255 / 0.1) !important;
}

.tw-border-t-white\/100 {
  border-top-color: rgb(255 255 255 / 1) !important;
}

.tw-border-t-white\/15 {
  border-top-color: rgb(255 255 255 / 0.15) !important;
}

.tw-border-t-white\/20 {
  border-top-color: rgb(255 255 255 / 0.2) !important;
}

.tw-border-t-white\/25 {
  border-top-color: rgb(255 255 255 / 0.25) !important;
}

.tw-border-t-white\/30 {
  border-top-color: rgb(255 255 255 / 0.3) !important;
}

.tw-border-t-white\/35 {
  border-top-color: rgb(255 255 255 / 0.35) !important;
}

.tw-border-t-white\/40 {
  border-top-color: rgb(255 255 255 / 0.4) !important;
}

.tw-border-t-white\/45 {
  border-top-color: rgb(255 255 255 / 0.45) !important;
}

.tw-border-t-white\/5 {
  border-top-color: rgb(255 255 255 / 0.05) !important;
}

.tw-border-t-white\/50 {
  border-top-color: rgb(255 255 255 / 0.5) !important;
}

.tw-border-t-white\/55 {
  border-top-color: rgb(255 255 255 / 0.55) !important;
}

.tw-border-t-white\/60 {
  border-top-color: rgb(255 255 255 / 0.6) !important;
}

.tw-border-t-white\/65 {
  border-top-color: rgb(255 255 255 / 0.65) !important;
}

.tw-border-t-white\/70 {
  border-top-color: rgb(255 255 255 / 0.7) !important;
}

.tw-border-t-white\/75 {
  border-top-color: rgb(255 255 255 / 0.75) !important;
}

.tw-border-t-white\/80 {
  border-top-color: rgb(255 255 255 / 0.8) !important;
}

.tw-border-t-white\/85 {
  border-top-color: rgb(255 255 255 / 0.85) !important;
}

.tw-border-t-white\/90 {
  border-top-color: rgb(255 255 255 / 0.9) !important;
}

.tw-border-t-white\/95 {
  border-top-color: rgb(255 255 255 / 0.95) !important;
}

.tw-border-t-yellow-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 249 195 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-100\/0 {
  border-top-color: rgb(254 249 195 / 0) !important;
}

.tw-border-t-yellow-100\/10 {
  border-top-color: rgb(254 249 195 / 0.1) !important;
}

.tw-border-t-yellow-100\/100 {
  border-top-color: rgb(254 249 195 / 1) !important;
}

.tw-border-t-yellow-100\/15 {
  border-top-color: rgb(254 249 195 / 0.15) !important;
}

.tw-border-t-yellow-100\/20 {
  border-top-color: rgb(254 249 195 / 0.2) !important;
}

.tw-border-t-yellow-100\/25 {
  border-top-color: rgb(254 249 195 / 0.25) !important;
}

.tw-border-t-yellow-100\/30 {
  border-top-color: rgb(254 249 195 / 0.3) !important;
}

.tw-border-t-yellow-100\/35 {
  border-top-color: rgb(254 249 195 / 0.35) !important;
}

.tw-border-t-yellow-100\/40 {
  border-top-color: rgb(254 249 195 / 0.4) !important;
}

.tw-border-t-yellow-100\/45 {
  border-top-color: rgb(254 249 195 / 0.45) !important;
}

.tw-border-t-yellow-100\/5 {
  border-top-color: rgb(254 249 195 / 0.05) !important;
}

.tw-border-t-yellow-100\/50 {
  border-top-color: rgb(254 249 195 / 0.5) !important;
}

.tw-border-t-yellow-100\/55 {
  border-top-color: rgb(254 249 195 / 0.55) !important;
}

.tw-border-t-yellow-100\/60 {
  border-top-color: rgb(254 249 195 / 0.6) !important;
}

.tw-border-t-yellow-100\/65 {
  border-top-color: rgb(254 249 195 / 0.65) !important;
}

.tw-border-t-yellow-100\/70 {
  border-top-color: rgb(254 249 195 / 0.7) !important;
}

.tw-border-t-yellow-100\/75 {
  border-top-color: rgb(254 249 195 / 0.75) !important;
}

.tw-border-t-yellow-100\/80 {
  border-top-color: rgb(254 249 195 / 0.8) !important;
}

.tw-border-t-yellow-100\/85 {
  border-top-color: rgb(254 249 195 / 0.85) !important;
}

.tw-border-t-yellow-100\/90 {
  border-top-color: rgb(254 249 195 / 0.9) !important;
}

.tw-border-t-yellow-100\/95 {
  border-top-color: rgb(254 249 195 / 0.95) !important;
}

.tw-border-t-yellow-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 240 138 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-200\/0 {
  border-top-color: rgb(254 240 138 / 0) !important;
}

.tw-border-t-yellow-200\/10 {
  border-top-color: rgb(254 240 138 / 0.1) !important;
}

.tw-border-t-yellow-200\/100 {
  border-top-color: rgb(254 240 138 / 1) !important;
}

.tw-border-t-yellow-200\/15 {
  border-top-color: rgb(254 240 138 / 0.15) !important;
}

.tw-border-t-yellow-200\/20 {
  border-top-color: rgb(254 240 138 / 0.2) !important;
}

.tw-border-t-yellow-200\/25 {
  border-top-color: rgb(254 240 138 / 0.25) !important;
}

.tw-border-t-yellow-200\/30 {
  border-top-color: rgb(254 240 138 / 0.3) !important;
}

.tw-border-t-yellow-200\/35 {
  border-top-color: rgb(254 240 138 / 0.35) !important;
}

.tw-border-t-yellow-200\/40 {
  border-top-color: rgb(254 240 138 / 0.4) !important;
}

.tw-border-t-yellow-200\/45 {
  border-top-color: rgb(254 240 138 / 0.45) !important;
}

.tw-border-t-yellow-200\/5 {
  border-top-color: rgb(254 240 138 / 0.05) !important;
}

.tw-border-t-yellow-200\/50 {
  border-top-color: rgb(254 240 138 / 0.5) !important;
}

.tw-border-t-yellow-200\/55 {
  border-top-color: rgb(254 240 138 / 0.55) !important;
}

.tw-border-t-yellow-200\/60 {
  border-top-color: rgb(254 240 138 / 0.6) !important;
}

.tw-border-t-yellow-200\/65 {
  border-top-color: rgb(254 240 138 / 0.65) !important;
}

.tw-border-t-yellow-200\/70 {
  border-top-color: rgb(254 240 138 / 0.7) !important;
}

.tw-border-t-yellow-200\/75 {
  border-top-color: rgb(254 240 138 / 0.75) !important;
}

.tw-border-t-yellow-200\/80 {
  border-top-color: rgb(254 240 138 / 0.8) !important;
}

.tw-border-t-yellow-200\/85 {
  border-top-color: rgb(254 240 138 / 0.85) !important;
}

.tw-border-t-yellow-200\/90 {
  border-top-color: rgb(254 240 138 / 0.9) !important;
}

.tw-border-t-yellow-200\/95 {
  border-top-color: rgb(254 240 138 / 0.95) !important;
}

.tw-border-t-yellow-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(253 224 71 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-300\/0 {
  border-top-color: rgb(253 224 71 / 0) !important;
}

.tw-border-t-yellow-300\/10 {
  border-top-color: rgb(253 224 71 / 0.1) !important;
}

.tw-border-t-yellow-300\/100 {
  border-top-color: rgb(253 224 71 / 1) !important;
}

.tw-border-t-yellow-300\/15 {
  border-top-color: rgb(253 224 71 / 0.15) !important;
}

.tw-border-t-yellow-300\/20 {
  border-top-color: rgb(253 224 71 / 0.2) !important;
}

.tw-border-t-yellow-300\/25 {
  border-top-color: rgb(253 224 71 / 0.25) !important;
}

.tw-border-t-yellow-300\/30 {
  border-top-color: rgb(253 224 71 / 0.3) !important;
}

.tw-border-t-yellow-300\/35 {
  border-top-color: rgb(253 224 71 / 0.35) !important;
}

.tw-border-t-yellow-300\/40 {
  border-top-color: rgb(253 224 71 / 0.4) !important;
}

.tw-border-t-yellow-300\/45 {
  border-top-color: rgb(253 224 71 / 0.45) !important;
}

.tw-border-t-yellow-300\/5 {
  border-top-color: rgb(253 224 71 / 0.05) !important;
}

.tw-border-t-yellow-300\/50 {
  border-top-color: rgb(253 224 71 / 0.5) !important;
}

.tw-border-t-yellow-300\/55 {
  border-top-color: rgb(253 224 71 / 0.55) !important;
}

.tw-border-t-yellow-300\/60 {
  border-top-color: rgb(253 224 71 / 0.6) !important;
}

.tw-border-t-yellow-300\/65 {
  border-top-color: rgb(253 224 71 / 0.65) !important;
}

.tw-border-t-yellow-300\/70 {
  border-top-color: rgb(253 224 71 / 0.7) !important;
}

.tw-border-t-yellow-300\/75 {
  border-top-color: rgb(253 224 71 / 0.75) !important;
}

.tw-border-t-yellow-300\/80 {
  border-top-color: rgb(253 224 71 / 0.8) !important;
}

.tw-border-t-yellow-300\/85 {
  border-top-color: rgb(253 224 71 / 0.85) !important;
}

.tw-border-t-yellow-300\/90 {
  border-top-color: rgb(253 224 71 / 0.9) !important;
}

.tw-border-t-yellow-300\/95 {
  border-top-color: rgb(253 224 71 / 0.95) !important;
}

.tw-border-t-yellow-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 204 21 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-400\/0 {
  border-top-color: rgb(250 204 21 / 0) !important;
}

.tw-border-t-yellow-400\/10 {
  border-top-color: rgb(250 204 21 / 0.1) !important;
}

.tw-border-t-yellow-400\/100 {
  border-top-color: rgb(250 204 21 / 1) !important;
}

.tw-border-t-yellow-400\/15 {
  border-top-color: rgb(250 204 21 / 0.15) !important;
}

.tw-border-t-yellow-400\/20 {
  border-top-color: rgb(250 204 21 / 0.2) !important;
}

.tw-border-t-yellow-400\/25 {
  border-top-color: rgb(250 204 21 / 0.25) !important;
}

.tw-border-t-yellow-400\/30 {
  border-top-color: rgb(250 204 21 / 0.3) !important;
}

.tw-border-t-yellow-400\/35 {
  border-top-color: rgb(250 204 21 / 0.35) !important;
}

.tw-border-t-yellow-400\/40 {
  border-top-color: rgb(250 204 21 / 0.4) !important;
}

.tw-border-t-yellow-400\/45 {
  border-top-color: rgb(250 204 21 / 0.45) !important;
}

.tw-border-t-yellow-400\/5 {
  border-top-color: rgb(250 204 21 / 0.05) !important;
}

.tw-border-t-yellow-400\/50 {
  border-top-color: rgb(250 204 21 / 0.5) !important;
}

.tw-border-t-yellow-400\/55 {
  border-top-color: rgb(250 204 21 / 0.55) !important;
}

.tw-border-t-yellow-400\/60 {
  border-top-color: rgb(250 204 21 / 0.6) !important;
}

.tw-border-t-yellow-400\/65 {
  border-top-color: rgb(250 204 21 / 0.65) !important;
}

.tw-border-t-yellow-400\/70 {
  border-top-color: rgb(250 204 21 / 0.7) !important;
}

.tw-border-t-yellow-400\/75 {
  border-top-color: rgb(250 204 21 / 0.75) !important;
}

.tw-border-t-yellow-400\/80 {
  border-top-color: rgb(250 204 21 / 0.8) !important;
}

.tw-border-t-yellow-400\/85 {
  border-top-color: rgb(250 204 21 / 0.85) !important;
}

.tw-border-t-yellow-400\/90 {
  border-top-color: rgb(250 204 21 / 0.9) !important;
}

.tw-border-t-yellow-400\/95 {
  border-top-color: rgb(250 204 21 / 0.95) !important;
}

.tw-border-t-yellow-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(254 252 232 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-50\/0 {
  border-top-color: rgb(254 252 232 / 0) !important;
}

.tw-border-t-yellow-50\/10 {
  border-top-color: rgb(254 252 232 / 0.1) !important;
}

.tw-border-t-yellow-50\/100 {
  border-top-color: rgb(254 252 232 / 1) !important;
}

.tw-border-t-yellow-50\/15 {
  border-top-color: rgb(254 252 232 / 0.15) !important;
}

.tw-border-t-yellow-50\/20 {
  border-top-color: rgb(254 252 232 / 0.2) !important;
}

.tw-border-t-yellow-50\/25 {
  border-top-color: rgb(254 252 232 / 0.25) !important;
}

.tw-border-t-yellow-50\/30 {
  border-top-color: rgb(254 252 232 / 0.3) !important;
}

.tw-border-t-yellow-50\/35 {
  border-top-color: rgb(254 252 232 / 0.35) !important;
}

.tw-border-t-yellow-50\/40 {
  border-top-color: rgb(254 252 232 / 0.4) !important;
}

.tw-border-t-yellow-50\/45 {
  border-top-color: rgb(254 252 232 / 0.45) !important;
}

.tw-border-t-yellow-50\/5 {
  border-top-color: rgb(254 252 232 / 0.05) !important;
}

.tw-border-t-yellow-50\/50 {
  border-top-color: rgb(254 252 232 / 0.5) !important;
}

.tw-border-t-yellow-50\/55 {
  border-top-color: rgb(254 252 232 / 0.55) !important;
}

.tw-border-t-yellow-50\/60 {
  border-top-color: rgb(254 252 232 / 0.6) !important;
}

.tw-border-t-yellow-50\/65 {
  border-top-color: rgb(254 252 232 / 0.65) !important;
}

.tw-border-t-yellow-50\/70 {
  border-top-color: rgb(254 252 232 / 0.7) !important;
}

.tw-border-t-yellow-50\/75 {
  border-top-color: rgb(254 252 232 / 0.75) !important;
}

.tw-border-t-yellow-50\/80 {
  border-top-color: rgb(254 252 232 / 0.8) !important;
}

.tw-border-t-yellow-50\/85 {
  border-top-color: rgb(254 252 232 / 0.85) !important;
}

.tw-border-t-yellow-50\/90 {
  border-top-color: rgb(254 252 232 / 0.9) !important;
}

.tw-border-t-yellow-50\/95 {
  border-top-color: rgb(254 252 232 / 0.95) !important;
}

.tw-border-t-yellow-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(234 179 8 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-500\/0 {
  border-top-color: rgb(234 179 8 / 0) !important;
}

.tw-border-t-yellow-500\/10 {
  border-top-color: rgb(234 179 8 / 0.1) !important;
}

.tw-border-t-yellow-500\/100 {
  border-top-color: rgb(234 179 8 / 1) !important;
}

.tw-border-t-yellow-500\/15 {
  border-top-color: rgb(234 179 8 / 0.15) !important;
}

.tw-border-t-yellow-500\/20 {
  border-top-color: rgb(234 179 8 / 0.2) !important;
}

.tw-border-t-yellow-500\/25 {
  border-top-color: rgb(234 179 8 / 0.25) !important;
}

.tw-border-t-yellow-500\/30 {
  border-top-color: rgb(234 179 8 / 0.3) !important;
}

.tw-border-t-yellow-500\/35 {
  border-top-color: rgb(234 179 8 / 0.35) !important;
}

.tw-border-t-yellow-500\/40 {
  border-top-color: rgb(234 179 8 / 0.4) !important;
}

.tw-border-t-yellow-500\/45 {
  border-top-color: rgb(234 179 8 / 0.45) !important;
}

.tw-border-t-yellow-500\/5 {
  border-top-color: rgb(234 179 8 / 0.05) !important;
}

.tw-border-t-yellow-500\/50 {
  border-top-color: rgb(234 179 8 / 0.5) !important;
}

.tw-border-t-yellow-500\/55 {
  border-top-color: rgb(234 179 8 / 0.55) !important;
}

.tw-border-t-yellow-500\/60 {
  border-top-color: rgb(234 179 8 / 0.6) !important;
}

.tw-border-t-yellow-500\/65 {
  border-top-color: rgb(234 179 8 / 0.65) !important;
}

.tw-border-t-yellow-500\/70 {
  border-top-color: rgb(234 179 8 / 0.7) !important;
}

.tw-border-t-yellow-500\/75 {
  border-top-color: rgb(234 179 8 / 0.75) !important;
}

.tw-border-t-yellow-500\/80 {
  border-top-color: rgb(234 179 8 / 0.8) !important;
}

.tw-border-t-yellow-500\/85 {
  border-top-color: rgb(234 179 8 / 0.85) !important;
}

.tw-border-t-yellow-500\/90 {
  border-top-color: rgb(234 179 8 / 0.9) !important;
}

.tw-border-t-yellow-500\/95 {
  border-top-color: rgb(234 179 8 / 0.95) !important;
}

.tw-border-t-yellow-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(202 138 4 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-600\/0 {
  border-top-color: rgb(202 138 4 / 0) !important;
}

.tw-border-t-yellow-600\/10 {
  border-top-color: rgb(202 138 4 / 0.1) !important;
}

.tw-border-t-yellow-600\/100 {
  border-top-color: rgb(202 138 4 / 1) !important;
}

.tw-border-t-yellow-600\/15 {
  border-top-color: rgb(202 138 4 / 0.15) !important;
}

.tw-border-t-yellow-600\/20 {
  border-top-color: rgb(202 138 4 / 0.2) !important;
}

.tw-border-t-yellow-600\/25 {
  border-top-color: rgb(202 138 4 / 0.25) !important;
}

.tw-border-t-yellow-600\/30 {
  border-top-color: rgb(202 138 4 / 0.3) !important;
}

.tw-border-t-yellow-600\/35 {
  border-top-color: rgb(202 138 4 / 0.35) !important;
}

.tw-border-t-yellow-600\/40 {
  border-top-color: rgb(202 138 4 / 0.4) !important;
}

.tw-border-t-yellow-600\/45 {
  border-top-color: rgb(202 138 4 / 0.45) !important;
}

.tw-border-t-yellow-600\/5 {
  border-top-color: rgb(202 138 4 / 0.05) !important;
}

.tw-border-t-yellow-600\/50 {
  border-top-color: rgb(202 138 4 / 0.5) !important;
}

.tw-border-t-yellow-600\/55 {
  border-top-color: rgb(202 138 4 / 0.55) !important;
}

.tw-border-t-yellow-600\/60 {
  border-top-color: rgb(202 138 4 / 0.6) !important;
}

.tw-border-t-yellow-600\/65 {
  border-top-color: rgb(202 138 4 / 0.65) !important;
}

.tw-border-t-yellow-600\/70 {
  border-top-color: rgb(202 138 4 / 0.7) !important;
}

.tw-border-t-yellow-600\/75 {
  border-top-color: rgb(202 138 4 / 0.75) !important;
}

.tw-border-t-yellow-600\/80 {
  border-top-color: rgb(202 138 4 / 0.8) !important;
}

.tw-border-t-yellow-600\/85 {
  border-top-color: rgb(202 138 4 / 0.85) !important;
}

.tw-border-t-yellow-600\/90 {
  border-top-color: rgb(202 138 4 / 0.9) !important;
}

.tw-border-t-yellow-600\/95 {
  border-top-color: rgb(202 138 4 / 0.95) !important;
}

.tw-border-t-yellow-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(161 98 7 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-700\/0 {
  border-top-color: rgb(161 98 7 / 0) !important;
}

.tw-border-t-yellow-700\/10 {
  border-top-color: rgb(161 98 7 / 0.1) !important;
}

.tw-border-t-yellow-700\/100 {
  border-top-color: rgb(161 98 7 / 1) !important;
}

.tw-border-t-yellow-700\/15 {
  border-top-color: rgb(161 98 7 / 0.15) !important;
}

.tw-border-t-yellow-700\/20 {
  border-top-color: rgb(161 98 7 / 0.2) !important;
}

.tw-border-t-yellow-700\/25 {
  border-top-color: rgb(161 98 7 / 0.25) !important;
}

.tw-border-t-yellow-700\/30 {
  border-top-color: rgb(161 98 7 / 0.3) !important;
}

.tw-border-t-yellow-700\/35 {
  border-top-color: rgb(161 98 7 / 0.35) !important;
}

.tw-border-t-yellow-700\/40 {
  border-top-color: rgb(161 98 7 / 0.4) !important;
}

.tw-border-t-yellow-700\/45 {
  border-top-color: rgb(161 98 7 / 0.45) !important;
}

.tw-border-t-yellow-700\/5 {
  border-top-color: rgb(161 98 7 / 0.05) !important;
}

.tw-border-t-yellow-700\/50 {
  border-top-color: rgb(161 98 7 / 0.5) !important;
}

.tw-border-t-yellow-700\/55 {
  border-top-color: rgb(161 98 7 / 0.55) !important;
}

.tw-border-t-yellow-700\/60 {
  border-top-color: rgb(161 98 7 / 0.6) !important;
}

.tw-border-t-yellow-700\/65 {
  border-top-color: rgb(161 98 7 / 0.65) !important;
}

.tw-border-t-yellow-700\/70 {
  border-top-color: rgb(161 98 7 / 0.7) !important;
}

.tw-border-t-yellow-700\/75 {
  border-top-color: rgb(161 98 7 / 0.75) !important;
}

.tw-border-t-yellow-700\/80 {
  border-top-color: rgb(161 98 7 / 0.8) !important;
}

.tw-border-t-yellow-700\/85 {
  border-top-color: rgb(161 98 7 / 0.85) !important;
}

.tw-border-t-yellow-700\/90 {
  border-top-color: rgb(161 98 7 / 0.9) !important;
}

.tw-border-t-yellow-700\/95 {
  border-top-color: rgb(161 98 7 / 0.95) !important;
}

.tw-border-t-yellow-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(133 77 14 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-800\/0 {
  border-top-color: rgb(133 77 14 / 0) !important;
}

.tw-border-t-yellow-800\/10 {
  border-top-color: rgb(133 77 14 / 0.1) !important;
}

.tw-border-t-yellow-800\/100 {
  border-top-color: rgb(133 77 14 / 1) !important;
}

.tw-border-t-yellow-800\/15 {
  border-top-color: rgb(133 77 14 / 0.15) !important;
}

.tw-border-t-yellow-800\/20 {
  border-top-color: rgb(133 77 14 / 0.2) !important;
}

.tw-border-t-yellow-800\/25 {
  border-top-color: rgb(133 77 14 / 0.25) !important;
}

.tw-border-t-yellow-800\/30 {
  border-top-color: rgb(133 77 14 / 0.3) !important;
}

.tw-border-t-yellow-800\/35 {
  border-top-color: rgb(133 77 14 / 0.35) !important;
}

.tw-border-t-yellow-800\/40 {
  border-top-color: rgb(133 77 14 / 0.4) !important;
}

.tw-border-t-yellow-800\/45 {
  border-top-color: rgb(133 77 14 / 0.45) !important;
}

.tw-border-t-yellow-800\/5 {
  border-top-color: rgb(133 77 14 / 0.05) !important;
}

.tw-border-t-yellow-800\/50 {
  border-top-color: rgb(133 77 14 / 0.5) !important;
}

.tw-border-t-yellow-800\/55 {
  border-top-color: rgb(133 77 14 / 0.55) !important;
}

.tw-border-t-yellow-800\/60 {
  border-top-color: rgb(133 77 14 / 0.6) !important;
}

.tw-border-t-yellow-800\/65 {
  border-top-color: rgb(133 77 14 / 0.65) !important;
}

.tw-border-t-yellow-800\/70 {
  border-top-color: rgb(133 77 14 / 0.7) !important;
}

.tw-border-t-yellow-800\/75 {
  border-top-color: rgb(133 77 14 / 0.75) !important;
}

.tw-border-t-yellow-800\/80 {
  border-top-color: rgb(133 77 14 / 0.8) !important;
}

.tw-border-t-yellow-800\/85 {
  border-top-color: rgb(133 77 14 / 0.85) !important;
}

.tw-border-t-yellow-800\/90 {
  border-top-color: rgb(133 77 14 / 0.9) !important;
}

.tw-border-t-yellow-800\/95 {
  border-top-color: rgb(133 77 14 / 0.95) !important;
}

.tw-border-t-yellow-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(113 63 18 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-900\/0 {
  border-top-color: rgb(113 63 18 / 0) !important;
}

.tw-border-t-yellow-900\/10 {
  border-top-color: rgb(113 63 18 / 0.1) !important;
}

.tw-border-t-yellow-900\/100 {
  border-top-color: rgb(113 63 18 / 1) !important;
}

.tw-border-t-yellow-900\/15 {
  border-top-color: rgb(113 63 18 / 0.15) !important;
}

.tw-border-t-yellow-900\/20 {
  border-top-color: rgb(113 63 18 / 0.2) !important;
}

.tw-border-t-yellow-900\/25 {
  border-top-color: rgb(113 63 18 / 0.25) !important;
}

.tw-border-t-yellow-900\/30 {
  border-top-color: rgb(113 63 18 / 0.3) !important;
}

.tw-border-t-yellow-900\/35 {
  border-top-color: rgb(113 63 18 / 0.35) !important;
}

.tw-border-t-yellow-900\/40 {
  border-top-color: rgb(113 63 18 / 0.4) !important;
}

.tw-border-t-yellow-900\/45 {
  border-top-color: rgb(113 63 18 / 0.45) !important;
}

.tw-border-t-yellow-900\/5 {
  border-top-color: rgb(113 63 18 / 0.05) !important;
}

.tw-border-t-yellow-900\/50 {
  border-top-color: rgb(113 63 18 / 0.5) !important;
}

.tw-border-t-yellow-900\/55 {
  border-top-color: rgb(113 63 18 / 0.55) !important;
}

.tw-border-t-yellow-900\/60 {
  border-top-color: rgb(113 63 18 / 0.6) !important;
}

.tw-border-t-yellow-900\/65 {
  border-top-color: rgb(113 63 18 / 0.65) !important;
}

.tw-border-t-yellow-900\/70 {
  border-top-color: rgb(113 63 18 / 0.7) !important;
}

.tw-border-t-yellow-900\/75 {
  border-top-color: rgb(113 63 18 / 0.75) !important;
}

.tw-border-t-yellow-900\/80 {
  border-top-color: rgb(113 63 18 / 0.8) !important;
}

.tw-border-t-yellow-900\/85 {
  border-top-color: rgb(113 63 18 / 0.85) !important;
}

.tw-border-t-yellow-900\/90 {
  border-top-color: rgb(113 63 18 / 0.9) !important;
}

.tw-border-t-yellow-900\/95 {
  border-top-color: rgb(113 63 18 / 0.95) !important;
}

.tw-border-t-yellow-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(66 32 6 / var(--tw-border-opacity)) !important;
}

.tw-border-t-yellow-950\/0 {
  border-top-color: rgb(66 32 6 / 0) !important;
}

.tw-border-t-yellow-950\/10 {
  border-top-color: rgb(66 32 6 / 0.1) !important;
}

.tw-border-t-yellow-950\/100 {
  border-top-color: rgb(66 32 6 / 1) !important;
}

.tw-border-t-yellow-950\/15 {
  border-top-color: rgb(66 32 6 / 0.15) !important;
}

.tw-border-t-yellow-950\/20 {
  border-top-color: rgb(66 32 6 / 0.2) !important;
}

.tw-border-t-yellow-950\/25 {
  border-top-color: rgb(66 32 6 / 0.25) !important;
}

.tw-border-t-yellow-950\/30 {
  border-top-color: rgb(66 32 6 / 0.3) !important;
}

.tw-border-t-yellow-950\/35 {
  border-top-color: rgb(66 32 6 / 0.35) !important;
}

.tw-border-t-yellow-950\/40 {
  border-top-color: rgb(66 32 6 / 0.4) !important;
}

.tw-border-t-yellow-950\/45 {
  border-top-color: rgb(66 32 6 / 0.45) !important;
}

.tw-border-t-yellow-950\/5 {
  border-top-color: rgb(66 32 6 / 0.05) !important;
}

.tw-border-t-yellow-950\/50 {
  border-top-color: rgb(66 32 6 / 0.5) !important;
}

.tw-border-t-yellow-950\/55 {
  border-top-color: rgb(66 32 6 / 0.55) !important;
}

.tw-border-t-yellow-950\/60 {
  border-top-color: rgb(66 32 6 / 0.6) !important;
}

.tw-border-t-yellow-950\/65 {
  border-top-color: rgb(66 32 6 / 0.65) !important;
}

.tw-border-t-yellow-950\/70 {
  border-top-color: rgb(66 32 6 / 0.7) !important;
}

.tw-border-t-yellow-950\/75 {
  border-top-color: rgb(66 32 6 / 0.75) !important;
}

.tw-border-t-yellow-950\/80 {
  border-top-color: rgb(66 32 6 / 0.8) !important;
}

.tw-border-t-yellow-950\/85 {
  border-top-color: rgb(66 32 6 / 0.85) !important;
}

.tw-border-t-yellow-950\/90 {
  border-top-color: rgb(66 32 6 / 0.9) !important;
}

.tw-border-t-yellow-950\/95 {
  border-top-color: rgb(66 32 6 / 0.95) !important;
}

.tw-border-t-zinc-100 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(244 244 245 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-100\/0 {
  border-top-color: rgb(244 244 245 / 0) !important;
}

.tw-border-t-zinc-100\/10 {
  border-top-color: rgb(244 244 245 / 0.1) !important;
}

.tw-border-t-zinc-100\/100 {
  border-top-color: rgb(244 244 245 / 1) !important;
}

.tw-border-t-zinc-100\/15 {
  border-top-color: rgb(244 244 245 / 0.15) !important;
}

.tw-border-t-zinc-100\/20 {
  border-top-color: rgb(244 244 245 / 0.2) !important;
}

.tw-border-t-zinc-100\/25 {
  border-top-color: rgb(244 244 245 / 0.25) !important;
}

.tw-border-t-zinc-100\/30 {
  border-top-color: rgb(244 244 245 / 0.3) !important;
}

.tw-border-t-zinc-100\/35 {
  border-top-color: rgb(244 244 245 / 0.35) !important;
}

.tw-border-t-zinc-100\/40 {
  border-top-color: rgb(244 244 245 / 0.4) !important;
}

.tw-border-t-zinc-100\/45 {
  border-top-color: rgb(244 244 245 / 0.45) !important;
}

.tw-border-t-zinc-100\/5 {
  border-top-color: rgb(244 244 245 / 0.05) !important;
}

.tw-border-t-zinc-100\/50 {
  border-top-color: rgb(244 244 245 / 0.5) !important;
}

.tw-border-t-zinc-100\/55 {
  border-top-color: rgb(244 244 245 / 0.55) !important;
}

.tw-border-t-zinc-100\/60 {
  border-top-color: rgb(244 244 245 / 0.6) !important;
}

.tw-border-t-zinc-100\/65 {
  border-top-color: rgb(244 244 245 / 0.65) !important;
}

.tw-border-t-zinc-100\/70 {
  border-top-color: rgb(244 244 245 / 0.7) !important;
}

.tw-border-t-zinc-100\/75 {
  border-top-color: rgb(244 244 245 / 0.75) !important;
}

.tw-border-t-zinc-100\/80 {
  border-top-color: rgb(244 244 245 / 0.8) !important;
}

.tw-border-t-zinc-100\/85 {
  border-top-color: rgb(244 244 245 / 0.85) !important;
}

.tw-border-t-zinc-100\/90 {
  border-top-color: rgb(244 244 245 / 0.9) !important;
}

.tw-border-t-zinc-100\/95 {
  border-top-color: rgb(244 244 245 / 0.95) !important;
}

.tw-border-t-zinc-200 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(228 228 231 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-200\/0 {
  border-top-color: rgb(228 228 231 / 0) !important;
}

.tw-border-t-zinc-200\/10 {
  border-top-color: rgb(228 228 231 / 0.1) !important;
}

.tw-border-t-zinc-200\/100 {
  border-top-color: rgb(228 228 231 / 1) !important;
}

.tw-border-t-zinc-200\/15 {
  border-top-color: rgb(228 228 231 / 0.15) !important;
}

.tw-border-t-zinc-200\/20 {
  border-top-color: rgb(228 228 231 / 0.2) !important;
}

.tw-border-t-zinc-200\/25 {
  border-top-color: rgb(228 228 231 / 0.25) !important;
}

.tw-border-t-zinc-200\/30 {
  border-top-color: rgb(228 228 231 / 0.3) !important;
}

.tw-border-t-zinc-200\/35 {
  border-top-color: rgb(228 228 231 / 0.35) !important;
}

.tw-border-t-zinc-200\/40 {
  border-top-color: rgb(228 228 231 / 0.4) !important;
}

.tw-border-t-zinc-200\/45 {
  border-top-color: rgb(228 228 231 / 0.45) !important;
}

.tw-border-t-zinc-200\/5 {
  border-top-color: rgb(228 228 231 / 0.05) !important;
}

.tw-border-t-zinc-200\/50 {
  border-top-color: rgb(228 228 231 / 0.5) !important;
}

.tw-border-t-zinc-200\/55 {
  border-top-color: rgb(228 228 231 / 0.55) !important;
}

.tw-border-t-zinc-200\/60 {
  border-top-color: rgb(228 228 231 / 0.6) !important;
}

.tw-border-t-zinc-200\/65 {
  border-top-color: rgb(228 228 231 / 0.65) !important;
}

.tw-border-t-zinc-200\/70 {
  border-top-color: rgb(228 228 231 / 0.7) !important;
}

.tw-border-t-zinc-200\/75 {
  border-top-color: rgb(228 228 231 / 0.75) !important;
}

.tw-border-t-zinc-200\/80 {
  border-top-color: rgb(228 228 231 / 0.8) !important;
}

.tw-border-t-zinc-200\/85 {
  border-top-color: rgb(228 228 231 / 0.85) !important;
}

.tw-border-t-zinc-200\/90 {
  border-top-color: rgb(228 228 231 / 0.9) !important;
}

.tw-border-t-zinc-200\/95 {
  border-top-color: rgb(228 228 231 / 0.95) !important;
}

.tw-border-t-zinc-300 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(212 212 216 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-300\/0 {
  border-top-color: rgb(212 212 216 / 0) !important;
}

.tw-border-t-zinc-300\/10 {
  border-top-color: rgb(212 212 216 / 0.1) !important;
}

.tw-border-t-zinc-300\/100 {
  border-top-color: rgb(212 212 216 / 1) !important;
}

.tw-border-t-zinc-300\/15 {
  border-top-color: rgb(212 212 216 / 0.15) !important;
}

.tw-border-t-zinc-300\/20 {
  border-top-color: rgb(212 212 216 / 0.2) !important;
}

.tw-border-t-zinc-300\/25 {
  border-top-color: rgb(212 212 216 / 0.25) !important;
}

.tw-border-t-zinc-300\/30 {
  border-top-color: rgb(212 212 216 / 0.3) !important;
}

.tw-border-t-zinc-300\/35 {
  border-top-color: rgb(212 212 216 / 0.35) !important;
}

.tw-border-t-zinc-300\/40 {
  border-top-color: rgb(212 212 216 / 0.4) !important;
}

.tw-border-t-zinc-300\/45 {
  border-top-color: rgb(212 212 216 / 0.45) !important;
}

.tw-border-t-zinc-300\/5 {
  border-top-color: rgb(212 212 216 / 0.05) !important;
}

.tw-border-t-zinc-300\/50 {
  border-top-color: rgb(212 212 216 / 0.5) !important;
}

.tw-border-t-zinc-300\/55 {
  border-top-color: rgb(212 212 216 / 0.55) !important;
}

.tw-border-t-zinc-300\/60 {
  border-top-color: rgb(212 212 216 / 0.6) !important;
}

.tw-border-t-zinc-300\/65 {
  border-top-color: rgb(212 212 216 / 0.65) !important;
}

.tw-border-t-zinc-300\/70 {
  border-top-color: rgb(212 212 216 / 0.7) !important;
}

.tw-border-t-zinc-300\/75 {
  border-top-color: rgb(212 212 216 / 0.75) !important;
}

.tw-border-t-zinc-300\/80 {
  border-top-color: rgb(212 212 216 / 0.8) !important;
}

.tw-border-t-zinc-300\/85 {
  border-top-color: rgb(212 212 216 / 0.85) !important;
}

.tw-border-t-zinc-300\/90 {
  border-top-color: rgb(212 212 216 / 0.9) !important;
}

.tw-border-t-zinc-300\/95 {
  border-top-color: rgb(212 212 216 / 0.95) !important;
}

.tw-border-t-zinc-400 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(161 161 170 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-400\/0 {
  border-top-color: rgb(161 161 170 / 0) !important;
}

.tw-border-t-zinc-400\/10 {
  border-top-color: rgb(161 161 170 / 0.1) !important;
}

.tw-border-t-zinc-400\/100 {
  border-top-color: rgb(161 161 170 / 1) !important;
}

.tw-border-t-zinc-400\/15 {
  border-top-color: rgb(161 161 170 / 0.15) !important;
}

.tw-border-t-zinc-400\/20 {
  border-top-color: rgb(161 161 170 / 0.2) !important;
}

.tw-border-t-zinc-400\/25 {
  border-top-color: rgb(161 161 170 / 0.25) !important;
}

.tw-border-t-zinc-400\/30 {
  border-top-color: rgb(161 161 170 / 0.3) !important;
}

.tw-border-t-zinc-400\/35 {
  border-top-color: rgb(161 161 170 / 0.35) !important;
}

.tw-border-t-zinc-400\/40 {
  border-top-color: rgb(161 161 170 / 0.4) !important;
}

.tw-border-t-zinc-400\/45 {
  border-top-color: rgb(161 161 170 / 0.45) !important;
}

.tw-border-t-zinc-400\/5 {
  border-top-color: rgb(161 161 170 / 0.05) !important;
}

.tw-border-t-zinc-400\/50 {
  border-top-color: rgb(161 161 170 / 0.5) !important;
}

.tw-border-t-zinc-400\/55 {
  border-top-color: rgb(161 161 170 / 0.55) !important;
}

.tw-border-t-zinc-400\/60 {
  border-top-color: rgb(161 161 170 / 0.6) !important;
}

.tw-border-t-zinc-400\/65 {
  border-top-color: rgb(161 161 170 / 0.65) !important;
}

.tw-border-t-zinc-400\/70 {
  border-top-color: rgb(161 161 170 / 0.7) !important;
}

.tw-border-t-zinc-400\/75 {
  border-top-color: rgb(161 161 170 / 0.75) !important;
}

.tw-border-t-zinc-400\/80 {
  border-top-color: rgb(161 161 170 / 0.8) !important;
}

.tw-border-t-zinc-400\/85 {
  border-top-color: rgb(161 161 170 / 0.85) !important;
}

.tw-border-t-zinc-400\/90 {
  border-top-color: rgb(161 161 170 / 0.9) !important;
}

.tw-border-t-zinc-400\/95 {
  border-top-color: rgb(161 161 170 / 0.95) !important;
}

.tw-border-t-zinc-50 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(250 250 250 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-50\/0 {
  border-top-color: rgb(250 250 250 / 0) !important;
}

.tw-border-t-zinc-50\/10 {
  border-top-color: rgb(250 250 250 / 0.1) !important;
}

.tw-border-t-zinc-50\/100 {
  border-top-color: rgb(250 250 250 / 1) !important;
}

.tw-border-t-zinc-50\/15 {
  border-top-color: rgb(250 250 250 / 0.15) !important;
}

.tw-border-t-zinc-50\/20 {
  border-top-color: rgb(250 250 250 / 0.2) !important;
}

.tw-border-t-zinc-50\/25 {
  border-top-color: rgb(250 250 250 / 0.25) !important;
}

.tw-border-t-zinc-50\/30 {
  border-top-color: rgb(250 250 250 / 0.3) !important;
}

.tw-border-t-zinc-50\/35 {
  border-top-color: rgb(250 250 250 / 0.35) !important;
}

.tw-border-t-zinc-50\/40 {
  border-top-color: rgb(250 250 250 / 0.4) !important;
}

.tw-border-t-zinc-50\/45 {
  border-top-color: rgb(250 250 250 / 0.45) !important;
}

.tw-border-t-zinc-50\/5 {
  border-top-color: rgb(250 250 250 / 0.05) !important;
}

.tw-border-t-zinc-50\/50 {
  border-top-color: rgb(250 250 250 / 0.5) !important;
}

.tw-border-t-zinc-50\/55 {
  border-top-color: rgb(250 250 250 / 0.55) !important;
}

.tw-border-t-zinc-50\/60 {
  border-top-color: rgb(250 250 250 / 0.6) !important;
}

.tw-border-t-zinc-50\/65 {
  border-top-color: rgb(250 250 250 / 0.65) !important;
}

.tw-border-t-zinc-50\/70 {
  border-top-color: rgb(250 250 250 / 0.7) !important;
}

.tw-border-t-zinc-50\/75 {
  border-top-color: rgb(250 250 250 / 0.75) !important;
}

.tw-border-t-zinc-50\/80 {
  border-top-color: rgb(250 250 250 / 0.8) !important;
}

.tw-border-t-zinc-50\/85 {
  border-top-color: rgb(250 250 250 / 0.85) !important;
}

.tw-border-t-zinc-50\/90 {
  border-top-color: rgb(250 250 250 / 0.9) !important;
}

.tw-border-t-zinc-50\/95 {
  border-top-color: rgb(250 250 250 / 0.95) !important;
}

.tw-border-t-zinc-500 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(113 113 122 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-500\/0 {
  border-top-color: rgb(113 113 122 / 0) !important;
}

.tw-border-t-zinc-500\/10 {
  border-top-color: rgb(113 113 122 / 0.1) !important;
}

.tw-border-t-zinc-500\/100 {
  border-top-color: rgb(113 113 122 / 1) !important;
}

.tw-border-t-zinc-500\/15 {
  border-top-color: rgb(113 113 122 / 0.15) !important;
}

.tw-border-t-zinc-500\/20 {
  border-top-color: rgb(113 113 122 / 0.2) !important;
}

.tw-border-t-zinc-500\/25 {
  border-top-color: rgb(113 113 122 / 0.25) !important;
}

.tw-border-t-zinc-500\/30 {
  border-top-color: rgb(113 113 122 / 0.3) !important;
}

.tw-border-t-zinc-500\/35 {
  border-top-color: rgb(113 113 122 / 0.35) !important;
}

.tw-border-t-zinc-500\/40 {
  border-top-color: rgb(113 113 122 / 0.4) !important;
}

.tw-border-t-zinc-500\/45 {
  border-top-color: rgb(113 113 122 / 0.45) !important;
}

.tw-border-t-zinc-500\/5 {
  border-top-color: rgb(113 113 122 / 0.05) !important;
}

.tw-border-t-zinc-500\/50 {
  border-top-color: rgb(113 113 122 / 0.5) !important;
}

.tw-border-t-zinc-500\/55 {
  border-top-color: rgb(113 113 122 / 0.55) !important;
}

.tw-border-t-zinc-500\/60 {
  border-top-color: rgb(113 113 122 / 0.6) !important;
}

.tw-border-t-zinc-500\/65 {
  border-top-color: rgb(113 113 122 / 0.65) !important;
}

.tw-border-t-zinc-500\/70 {
  border-top-color: rgb(113 113 122 / 0.7) !important;
}

.tw-border-t-zinc-500\/75 {
  border-top-color: rgb(113 113 122 / 0.75) !important;
}

.tw-border-t-zinc-500\/80 {
  border-top-color: rgb(113 113 122 / 0.8) !important;
}

.tw-border-t-zinc-500\/85 {
  border-top-color: rgb(113 113 122 / 0.85) !important;
}

.tw-border-t-zinc-500\/90 {
  border-top-color: rgb(113 113 122 / 0.9) !important;
}

.tw-border-t-zinc-500\/95 {
  border-top-color: rgb(113 113 122 / 0.95) !important;
}

.tw-border-t-zinc-600 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(82 82 91 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-600\/0 {
  border-top-color: rgb(82 82 91 / 0) !important;
}

.tw-border-t-zinc-600\/10 {
  border-top-color: rgb(82 82 91 / 0.1) !important;
}

.tw-border-t-zinc-600\/100 {
  border-top-color: rgb(82 82 91 / 1) !important;
}

.tw-border-t-zinc-600\/15 {
  border-top-color: rgb(82 82 91 / 0.15) !important;
}

.tw-border-t-zinc-600\/20 {
  border-top-color: rgb(82 82 91 / 0.2) !important;
}

.tw-border-t-zinc-600\/25 {
  border-top-color: rgb(82 82 91 / 0.25) !important;
}

.tw-border-t-zinc-600\/30 {
  border-top-color: rgb(82 82 91 / 0.3) !important;
}

.tw-border-t-zinc-600\/35 {
  border-top-color: rgb(82 82 91 / 0.35) !important;
}

.tw-border-t-zinc-600\/40 {
  border-top-color: rgb(82 82 91 / 0.4) !important;
}

.tw-border-t-zinc-600\/45 {
  border-top-color: rgb(82 82 91 / 0.45) !important;
}

.tw-border-t-zinc-600\/5 {
  border-top-color: rgb(82 82 91 / 0.05) !important;
}

.tw-border-t-zinc-600\/50 {
  border-top-color: rgb(82 82 91 / 0.5) !important;
}

.tw-border-t-zinc-600\/55 {
  border-top-color: rgb(82 82 91 / 0.55) !important;
}

.tw-border-t-zinc-600\/60 {
  border-top-color: rgb(82 82 91 / 0.6) !important;
}

.tw-border-t-zinc-600\/65 {
  border-top-color: rgb(82 82 91 / 0.65) !important;
}

.tw-border-t-zinc-600\/70 {
  border-top-color: rgb(82 82 91 / 0.7) !important;
}

.tw-border-t-zinc-600\/75 {
  border-top-color: rgb(82 82 91 / 0.75) !important;
}

.tw-border-t-zinc-600\/80 {
  border-top-color: rgb(82 82 91 / 0.8) !important;
}

.tw-border-t-zinc-600\/85 {
  border-top-color: rgb(82 82 91 / 0.85) !important;
}

.tw-border-t-zinc-600\/90 {
  border-top-color: rgb(82 82 91 / 0.9) !important;
}

.tw-border-t-zinc-600\/95 {
  border-top-color: rgb(82 82 91 / 0.95) !important;
}

.tw-border-t-zinc-700 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(63 63 70 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-700\/0 {
  border-top-color: rgb(63 63 70 / 0) !important;
}

.tw-border-t-zinc-700\/10 {
  border-top-color: rgb(63 63 70 / 0.1) !important;
}

.tw-border-t-zinc-700\/100 {
  border-top-color: rgb(63 63 70 / 1) !important;
}

.tw-border-t-zinc-700\/15 {
  border-top-color: rgb(63 63 70 / 0.15) !important;
}

.tw-border-t-zinc-700\/20 {
  border-top-color: rgb(63 63 70 / 0.2) !important;
}

.tw-border-t-zinc-700\/25 {
  border-top-color: rgb(63 63 70 / 0.25) !important;
}

.tw-border-t-zinc-700\/30 {
  border-top-color: rgb(63 63 70 / 0.3) !important;
}

.tw-border-t-zinc-700\/35 {
  border-top-color: rgb(63 63 70 / 0.35) !important;
}

.tw-border-t-zinc-700\/40 {
  border-top-color: rgb(63 63 70 / 0.4) !important;
}

.tw-border-t-zinc-700\/45 {
  border-top-color: rgb(63 63 70 / 0.45) !important;
}

.tw-border-t-zinc-700\/5 {
  border-top-color: rgb(63 63 70 / 0.05) !important;
}

.tw-border-t-zinc-700\/50 {
  border-top-color: rgb(63 63 70 / 0.5) !important;
}

.tw-border-t-zinc-700\/55 {
  border-top-color: rgb(63 63 70 / 0.55) !important;
}

.tw-border-t-zinc-700\/60 {
  border-top-color: rgb(63 63 70 / 0.6) !important;
}

.tw-border-t-zinc-700\/65 {
  border-top-color: rgb(63 63 70 / 0.65) !important;
}

.tw-border-t-zinc-700\/70 {
  border-top-color: rgb(63 63 70 / 0.7) !important;
}

.tw-border-t-zinc-700\/75 {
  border-top-color: rgb(63 63 70 / 0.75) !important;
}

.tw-border-t-zinc-700\/80 {
  border-top-color: rgb(63 63 70 / 0.8) !important;
}

.tw-border-t-zinc-700\/85 {
  border-top-color: rgb(63 63 70 / 0.85) !important;
}

.tw-border-t-zinc-700\/90 {
  border-top-color: rgb(63 63 70 / 0.9) !important;
}

.tw-border-t-zinc-700\/95 {
  border-top-color: rgb(63 63 70 / 0.95) !important;
}

.tw-border-t-zinc-800 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(39 39 42 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-800\/0 {
  border-top-color: rgb(39 39 42 / 0) !important;
}

.tw-border-t-zinc-800\/10 {
  border-top-color: rgb(39 39 42 / 0.1) !important;
}

.tw-border-t-zinc-800\/100 {
  border-top-color: rgb(39 39 42 / 1) !important;
}

.tw-border-t-zinc-800\/15 {
  border-top-color: rgb(39 39 42 / 0.15) !important;
}

.tw-border-t-zinc-800\/20 {
  border-top-color: rgb(39 39 42 / 0.2) !important;
}

.tw-border-t-zinc-800\/25 {
  border-top-color: rgb(39 39 42 / 0.25) !important;
}

.tw-border-t-zinc-800\/30 {
  border-top-color: rgb(39 39 42 / 0.3) !important;
}

.tw-border-t-zinc-800\/35 {
  border-top-color: rgb(39 39 42 / 0.35) !important;
}

.tw-border-t-zinc-800\/40 {
  border-top-color: rgb(39 39 42 / 0.4) !important;
}

.tw-border-t-zinc-800\/45 {
  border-top-color: rgb(39 39 42 / 0.45) !important;
}

.tw-border-t-zinc-800\/5 {
  border-top-color: rgb(39 39 42 / 0.05) !important;
}

.tw-border-t-zinc-800\/50 {
  border-top-color: rgb(39 39 42 / 0.5) !important;
}

.tw-border-t-zinc-800\/55 {
  border-top-color: rgb(39 39 42 / 0.55) !important;
}

.tw-border-t-zinc-800\/60 {
  border-top-color: rgb(39 39 42 / 0.6) !important;
}

.tw-border-t-zinc-800\/65 {
  border-top-color: rgb(39 39 42 / 0.65) !important;
}

.tw-border-t-zinc-800\/70 {
  border-top-color: rgb(39 39 42 / 0.7) !important;
}

.tw-border-t-zinc-800\/75 {
  border-top-color: rgb(39 39 42 / 0.75) !important;
}

.tw-border-t-zinc-800\/80 {
  border-top-color: rgb(39 39 42 / 0.8) !important;
}

.tw-border-t-zinc-800\/85 {
  border-top-color: rgb(39 39 42 / 0.85) !important;
}

.tw-border-t-zinc-800\/90 {
  border-top-color: rgb(39 39 42 / 0.9) !important;
}

.tw-border-t-zinc-800\/95 {
  border-top-color: rgb(39 39 42 / 0.95) !important;
}

.tw-border-t-zinc-900 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(24 24 27 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-900\/0 {
  border-top-color: rgb(24 24 27 / 0) !important;
}

.tw-border-t-zinc-900\/10 {
  border-top-color: rgb(24 24 27 / 0.1) !important;
}

.tw-border-t-zinc-900\/100 {
  border-top-color: rgb(24 24 27 / 1) !important;
}

.tw-border-t-zinc-900\/15 {
  border-top-color: rgb(24 24 27 / 0.15) !important;
}

.tw-border-t-zinc-900\/20 {
  border-top-color: rgb(24 24 27 / 0.2) !important;
}

.tw-border-t-zinc-900\/25 {
  border-top-color: rgb(24 24 27 / 0.25) !important;
}

.tw-border-t-zinc-900\/30 {
  border-top-color: rgb(24 24 27 / 0.3) !important;
}

.tw-border-t-zinc-900\/35 {
  border-top-color: rgb(24 24 27 / 0.35) !important;
}

.tw-border-t-zinc-900\/40 {
  border-top-color: rgb(24 24 27 / 0.4) !important;
}

.tw-border-t-zinc-900\/45 {
  border-top-color: rgb(24 24 27 / 0.45) !important;
}

.tw-border-t-zinc-900\/5 {
  border-top-color: rgb(24 24 27 / 0.05) !important;
}

.tw-border-t-zinc-900\/50 {
  border-top-color: rgb(24 24 27 / 0.5) !important;
}

.tw-border-t-zinc-900\/55 {
  border-top-color: rgb(24 24 27 / 0.55) !important;
}

.tw-border-t-zinc-900\/60 {
  border-top-color: rgb(24 24 27 / 0.6) !important;
}

.tw-border-t-zinc-900\/65 {
  border-top-color: rgb(24 24 27 / 0.65) !important;
}

.tw-border-t-zinc-900\/70 {
  border-top-color: rgb(24 24 27 / 0.7) !important;
}

.tw-border-t-zinc-900\/75 {
  border-top-color: rgb(24 24 27 / 0.75) !important;
}

.tw-border-t-zinc-900\/80 {
  border-top-color: rgb(24 24 27 / 0.8) !important;
}

.tw-border-t-zinc-900\/85 {
  border-top-color: rgb(24 24 27 / 0.85) !important;
}

.tw-border-t-zinc-900\/90 {
  border-top-color: rgb(24 24 27 / 0.9) !important;
}

.tw-border-t-zinc-900\/95 {
  border-top-color: rgb(24 24 27 / 0.95) !important;
}

.tw-border-t-zinc-950 {
  --tw-border-opacity: 1 !important;
  border-top-color: rgb(9 9 11 / var(--tw-border-opacity)) !important;
}

.tw-border-t-zinc-950\/0 {
  border-top-color: rgb(9 9 11 / 0) !important;
}

.tw-border-t-zinc-950\/10 {
  border-top-color: rgb(9 9 11 / 0.1) !important;
}

.tw-border-t-zinc-950\/100 {
  border-top-color: rgb(9 9 11 / 1) !important;
}

.tw-border-t-zinc-950\/15 {
  border-top-color: rgb(9 9 11 / 0.15) !important;
}

.tw-border-t-zinc-950\/20 {
  border-top-color: rgb(9 9 11 / 0.2) !important;
}

.tw-border-t-zinc-950\/25 {
  border-top-color: rgb(9 9 11 / 0.25) !important;
}

.tw-border-t-zinc-950\/30 {
  border-top-color: rgb(9 9 11 / 0.3) !important;
}

.tw-border-t-zinc-950\/35 {
  border-top-color: rgb(9 9 11 / 0.35) !important;
}

.tw-border-t-zinc-950\/40 {
  border-top-color: rgb(9 9 11 / 0.4) !important;
}

.tw-border-t-zinc-950\/45 {
  border-top-color: rgb(9 9 11 / 0.45) !important;
}

.tw-border-t-zinc-950\/5 {
  border-top-color: rgb(9 9 11 / 0.05) !important;
}

.tw-border-t-zinc-950\/50 {
  border-top-color: rgb(9 9 11 / 0.5) !important;
}

.tw-border-t-zinc-950\/55 {
  border-top-color: rgb(9 9 11 / 0.55) !important;
}

.tw-border-t-zinc-950\/60 {
  border-top-color: rgb(9 9 11 / 0.6) !important;
}

.tw-border-t-zinc-950\/65 {
  border-top-color: rgb(9 9 11 / 0.65) !important;
}

.tw-border-t-zinc-950\/70 {
  border-top-color: rgb(9 9 11 / 0.7) !important;
}

.tw-border-t-zinc-950\/75 {
  border-top-color: rgb(9 9 11 / 0.75) !important;
}

.tw-border-t-zinc-950\/80 {
  border-top-color: rgb(9 9 11 / 0.8) !important;
}

.tw-border-t-zinc-950\/85 {
  border-top-color: rgb(9 9 11 / 0.85) !important;
}

.tw-border-t-zinc-950\/90 {
  border-top-color: rgb(9 9 11 / 0.9) !important;
}

.tw-border-t-zinc-950\/95 {
  border-top-color: rgb(9 9 11 / 0.95) !important;
}

.tw-border-opacity-0 {
  --tw-border-opacity: 0 !important;
}

.tw-border-opacity-10 {
  --tw-border-opacity: 0.1 !important;
}

.tw-border-opacity-100 {
  --tw-border-opacity: 1 !important;
}

.tw-border-opacity-15 {
  --tw-border-opacity: 0.15 !important;
}

.tw-border-opacity-20 {
  --tw-border-opacity: 0.2 !important;
}

.tw-border-opacity-25 {
  --tw-border-opacity: 0.25 !important;
}

.tw-border-opacity-30 {
  --tw-border-opacity: 0.3 !important;
}

.tw-border-opacity-35 {
  --tw-border-opacity: 0.35 !important;
}

.tw-border-opacity-40 {
  --tw-border-opacity: 0.4 !important;
}

.tw-border-opacity-45 {
  --tw-border-opacity: 0.45 !important;
}

.tw-border-opacity-5 {
  --tw-border-opacity: 0.05 !important;
}

.tw-border-opacity-50 {
  --tw-border-opacity: 0.5 !important;
}

.tw-border-opacity-55 {
  --tw-border-opacity: 0.55 !important;
}

.tw-border-opacity-60 {
  --tw-border-opacity: 0.6 !important;
}

.tw-border-opacity-65 {
  --tw-border-opacity: 0.65 !important;
}

.tw-border-opacity-70 {
  --tw-border-opacity: 0.7 !important;
}

.tw-border-opacity-75 {
  --tw-border-opacity: 0.75 !important;
}

.tw-border-opacity-80 {
  --tw-border-opacity: 0.8 !important;
}

.tw-border-opacity-85 {
  --tw-border-opacity: 0.85 !important;
}

.tw-border-opacity-90 {
  --tw-border-opacity: 0.9 !important;
}

.tw-border-opacity-95 {
  --tw-border-opacity: 0.95 !important;
}

.tw-bg-amber-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 243 199 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-100\/0 {
  background-color: rgb(254 243 199 / 0) !important;
}

.tw-bg-amber-100\/10 {
  background-color: rgb(254 243 199 / 0.1) !important;
}

.tw-bg-amber-100\/100 {
  background-color: rgb(254 243 199 / 1) !important;
}

.tw-bg-amber-100\/15 {
  background-color: rgb(254 243 199 / 0.15) !important;
}

.tw-bg-amber-100\/20 {
  background-color: rgb(254 243 199 / 0.2) !important;
}

.tw-bg-amber-100\/25 {
  background-color: rgb(254 243 199 / 0.25) !important;
}

.tw-bg-amber-100\/30 {
  background-color: rgb(254 243 199 / 0.3) !important;
}

.tw-bg-amber-100\/35 {
  background-color: rgb(254 243 199 / 0.35) !important;
}

.tw-bg-amber-100\/40 {
  background-color: rgb(254 243 199 / 0.4) !important;
}

.tw-bg-amber-100\/45 {
  background-color: rgb(254 243 199 / 0.45) !important;
}

.tw-bg-amber-100\/5 {
  background-color: rgb(254 243 199 / 0.05) !important;
}

.tw-bg-amber-100\/50 {
  background-color: rgb(254 243 199 / 0.5) !important;
}

.tw-bg-amber-100\/55 {
  background-color: rgb(254 243 199 / 0.55) !important;
}

.tw-bg-amber-100\/60 {
  background-color: rgb(254 243 199 / 0.6) !important;
}

.tw-bg-amber-100\/65 {
  background-color: rgb(254 243 199 / 0.65) !important;
}

.tw-bg-amber-100\/70 {
  background-color: rgb(254 243 199 / 0.7) !important;
}

.tw-bg-amber-100\/75 {
  background-color: rgb(254 243 199 / 0.75) !important;
}

.tw-bg-amber-100\/80 {
  background-color: rgb(254 243 199 / 0.8) !important;
}

.tw-bg-amber-100\/85 {
  background-color: rgb(254 243 199 / 0.85) !important;
}

.tw-bg-amber-100\/90 {
  background-color: rgb(254 243 199 / 0.9) !important;
}

.tw-bg-amber-100\/95 {
  background-color: rgb(254 243 199 / 0.95) !important;
}

.tw-bg-amber-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 230 138 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-200\/0 {
  background-color: rgb(253 230 138 / 0) !important;
}

.tw-bg-amber-200\/10 {
  background-color: rgb(253 230 138 / 0.1) !important;
}

.tw-bg-amber-200\/100 {
  background-color: rgb(253 230 138 / 1) !important;
}

.tw-bg-amber-200\/15 {
  background-color: rgb(253 230 138 / 0.15) !important;
}

.tw-bg-amber-200\/20 {
  background-color: rgb(253 230 138 / 0.2) !important;
}

.tw-bg-amber-200\/25 {
  background-color: rgb(253 230 138 / 0.25) !important;
}

.tw-bg-amber-200\/30 {
  background-color: rgb(253 230 138 / 0.3) !important;
}

.tw-bg-amber-200\/35 {
  background-color: rgb(253 230 138 / 0.35) !important;
}

.tw-bg-amber-200\/40 {
  background-color: rgb(253 230 138 / 0.4) !important;
}

.tw-bg-amber-200\/45 {
  background-color: rgb(253 230 138 / 0.45) !important;
}

.tw-bg-amber-200\/5 {
  background-color: rgb(253 230 138 / 0.05) !important;
}

.tw-bg-amber-200\/50 {
  background-color: rgb(253 230 138 / 0.5) !important;
}

.tw-bg-amber-200\/55 {
  background-color: rgb(253 230 138 / 0.55) !important;
}

.tw-bg-amber-200\/60 {
  background-color: rgb(253 230 138 / 0.6) !important;
}

.tw-bg-amber-200\/65 {
  background-color: rgb(253 230 138 / 0.65) !important;
}

.tw-bg-amber-200\/70 {
  background-color: rgb(253 230 138 / 0.7) !important;
}

.tw-bg-amber-200\/75 {
  background-color: rgb(253 230 138 / 0.75) !important;
}

.tw-bg-amber-200\/80 {
  background-color: rgb(253 230 138 / 0.8) !important;
}

.tw-bg-amber-200\/85 {
  background-color: rgb(253 230 138 / 0.85) !important;
}

.tw-bg-amber-200\/90 {
  background-color: rgb(253 230 138 / 0.9) !important;
}

.tw-bg-amber-200\/95 {
  background-color: rgb(253 230 138 / 0.95) !important;
}

.tw-bg-amber-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 211 77 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-300\/0 {
  background-color: rgb(252 211 77 / 0) !important;
}

.tw-bg-amber-300\/10 {
  background-color: rgb(252 211 77 / 0.1) !important;
}

.tw-bg-amber-300\/100 {
  background-color: rgb(252 211 77 / 1) !important;
}

.tw-bg-amber-300\/15 {
  background-color: rgb(252 211 77 / 0.15) !important;
}

.tw-bg-amber-300\/20 {
  background-color: rgb(252 211 77 / 0.2) !important;
}

.tw-bg-amber-300\/25 {
  background-color: rgb(252 211 77 / 0.25) !important;
}

.tw-bg-amber-300\/30 {
  background-color: rgb(252 211 77 / 0.3) !important;
}

.tw-bg-amber-300\/35 {
  background-color: rgb(252 211 77 / 0.35) !important;
}

.tw-bg-amber-300\/40 {
  background-color: rgb(252 211 77 / 0.4) !important;
}

.tw-bg-amber-300\/45 {
  background-color: rgb(252 211 77 / 0.45) !important;
}

.tw-bg-amber-300\/5 {
  background-color: rgb(252 211 77 / 0.05) !important;
}

.tw-bg-amber-300\/50 {
  background-color: rgb(252 211 77 / 0.5) !important;
}

.tw-bg-amber-300\/55 {
  background-color: rgb(252 211 77 / 0.55) !important;
}

.tw-bg-amber-300\/60 {
  background-color: rgb(252 211 77 / 0.6) !important;
}

.tw-bg-amber-300\/65 {
  background-color: rgb(252 211 77 / 0.65) !important;
}

.tw-bg-amber-300\/70 {
  background-color: rgb(252 211 77 / 0.7) !important;
}

.tw-bg-amber-300\/75 {
  background-color: rgb(252 211 77 / 0.75) !important;
}

.tw-bg-amber-300\/80 {
  background-color: rgb(252 211 77 / 0.8) !important;
}

.tw-bg-amber-300\/85 {
  background-color: rgb(252 211 77 / 0.85) !important;
}

.tw-bg-amber-300\/90 {
  background-color: rgb(252 211 77 / 0.9) !important;
}

.tw-bg-amber-300\/95 {
  background-color: rgb(252 211 77 / 0.95) !important;
}

.tw-bg-amber-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(251 191 36 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-400\/0 {
  background-color: rgb(251 191 36 / 0) !important;
}

.tw-bg-amber-400\/10 {
  background-color: rgb(251 191 36 / 0.1) !important;
}

.tw-bg-amber-400\/100 {
  background-color: rgb(251 191 36 / 1) !important;
}

.tw-bg-amber-400\/15 {
  background-color: rgb(251 191 36 / 0.15) !important;
}

.tw-bg-amber-400\/20 {
  background-color: rgb(251 191 36 / 0.2) !important;
}

.tw-bg-amber-400\/25 {
  background-color: rgb(251 191 36 / 0.25) !important;
}

.tw-bg-amber-400\/30 {
  background-color: rgb(251 191 36 / 0.3) !important;
}

.tw-bg-amber-400\/35 {
  background-color: rgb(251 191 36 / 0.35) !important;
}

.tw-bg-amber-400\/40 {
  background-color: rgb(251 191 36 / 0.4) !important;
}

.tw-bg-amber-400\/45 {
  background-color: rgb(251 191 36 / 0.45) !important;
}

.tw-bg-amber-400\/5 {
  background-color: rgb(251 191 36 / 0.05) !important;
}

.tw-bg-amber-400\/50 {
  background-color: rgb(251 191 36 / 0.5) !important;
}

.tw-bg-amber-400\/55 {
  background-color: rgb(251 191 36 / 0.55) !important;
}

.tw-bg-amber-400\/60 {
  background-color: rgb(251 191 36 / 0.6) !important;
}

.tw-bg-amber-400\/65 {
  background-color: rgb(251 191 36 / 0.65) !important;
}

.tw-bg-amber-400\/70 {
  background-color: rgb(251 191 36 / 0.7) !important;
}

.tw-bg-amber-400\/75 {
  background-color: rgb(251 191 36 / 0.75) !important;
}

.tw-bg-amber-400\/80 {
  background-color: rgb(251 191 36 / 0.8) !important;
}

.tw-bg-amber-400\/85 {
  background-color: rgb(251 191 36 / 0.85) !important;
}

.tw-bg-amber-400\/90 {
  background-color: rgb(251 191 36 / 0.9) !important;
}

.tw-bg-amber-400\/95 {
  background-color: rgb(251 191 36 / 0.95) !important;
}

.tw-bg-amber-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 251 235 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-50\/0 {
  background-color: rgb(255 251 235 / 0) !important;
}

.tw-bg-amber-50\/10 {
  background-color: rgb(255 251 235 / 0.1) !important;
}

.tw-bg-amber-50\/100 {
  background-color: rgb(255 251 235 / 1) !important;
}

.tw-bg-amber-50\/15 {
  background-color: rgb(255 251 235 / 0.15) !important;
}

.tw-bg-amber-50\/20 {
  background-color: rgb(255 251 235 / 0.2) !important;
}

.tw-bg-amber-50\/25 {
  background-color: rgb(255 251 235 / 0.25) !important;
}

.tw-bg-amber-50\/30 {
  background-color: rgb(255 251 235 / 0.3) !important;
}

.tw-bg-amber-50\/35 {
  background-color: rgb(255 251 235 / 0.35) !important;
}

.tw-bg-amber-50\/40 {
  background-color: rgb(255 251 235 / 0.4) !important;
}

.tw-bg-amber-50\/45 {
  background-color: rgb(255 251 235 / 0.45) !important;
}

.tw-bg-amber-50\/5 {
  background-color: rgb(255 251 235 / 0.05) !important;
}

.tw-bg-amber-50\/50 {
  background-color: rgb(255 251 235 / 0.5) !important;
}

.tw-bg-amber-50\/55 {
  background-color: rgb(255 251 235 / 0.55) !important;
}

.tw-bg-amber-50\/60 {
  background-color: rgb(255 251 235 / 0.6) !important;
}

.tw-bg-amber-50\/65 {
  background-color: rgb(255 251 235 / 0.65) !important;
}

.tw-bg-amber-50\/70 {
  background-color: rgb(255 251 235 / 0.7) !important;
}

.tw-bg-amber-50\/75 {
  background-color: rgb(255 251 235 / 0.75) !important;
}

.tw-bg-amber-50\/80 {
  background-color: rgb(255 251 235 / 0.8) !important;
}

.tw-bg-amber-50\/85 {
  background-color: rgb(255 251 235 / 0.85) !important;
}

.tw-bg-amber-50\/90 {
  background-color: rgb(255 251 235 / 0.9) !important;
}

.tw-bg-amber-50\/95 {
  background-color: rgb(255 251 235 / 0.95) !important;
}

.tw-bg-amber-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 158 11 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-500\/0 {
  background-color: rgb(245 158 11 / 0) !important;
}

.tw-bg-amber-500\/10 {
  background-color: rgb(245 158 11 / 0.1) !important;
}

.tw-bg-amber-500\/100 {
  background-color: rgb(245 158 11 / 1) !important;
}

.tw-bg-amber-500\/15 {
  background-color: rgb(245 158 11 / 0.15) !important;
}

.tw-bg-amber-500\/20 {
  background-color: rgb(245 158 11 / 0.2) !important;
}

.tw-bg-amber-500\/25 {
  background-color: rgb(245 158 11 / 0.25) !important;
}

.tw-bg-amber-500\/30 {
  background-color: rgb(245 158 11 / 0.3) !important;
}

.tw-bg-amber-500\/35 {
  background-color: rgb(245 158 11 / 0.35) !important;
}

.tw-bg-amber-500\/40 {
  background-color: rgb(245 158 11 / 0.4) !important;
}

.tw-bg-amber-500\/45 {
  background-color: rgb(245 158 11 / 0.45) !important;
}

.tw-bg-amber-500\/5 {
  background-color: rgb(245 158 11 / 0.05) !important;
}

.tw-bg-amber-500\/50 {
  background-color: rgb(245 158 11 / 0.5) !important;
}

.tw-bg-amber-500\/55 {
  background-color: rgb(245 158 11 / 0.55) !important;
}

.tw-bg-amber-500\/60 {
  background-color: rgb(245 158 11 / 0.6) !important;
}

.tw-bg-amber-500\/65 {
  background-color: rgb(245 158 11 / 0.65) !important;
}

.tw-bg-amber-500\/70 {
  background-color: rgb(245 158 11 / 0.7) !important;
}

.tw-bg-amber-500\/75 {
  background-color: rgb(245 158 11 / 0.75) !important;
}

.tw-bg-amber-500\/80 {
  background-color: rgb(245 158 11 / 0.8) !important;
}

.tw-bg-amber-500\/85 {
  background-color: rgb(245 158 11 / 0.85) !important;
}

.tw-bg-amber-500\/90 {
  background-color: rgb(245 158 11 / 0.9) !important;
}

.tw-bg-amber-500\/95 {
  background-color: rgb(245 158 11 / 0.95) !important;
}

.tw-bg-amber-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 119 6 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-600\/0 {
  background-color: rgb(217 119 6 / 0) !important;
}

.tw-bg-amber-600\/10 {
  background-color: rgb(217 119 6 / 0.1) !important;
}

.tw-bg-amber-600\/100 {
  background-color: rgb(217 119 6 / 1) !important;
}

.tw-bg-amber-600\/15 {
  background-color: rgb(217 119 6 / 0.15) !important;
}

.tw-bg-amber-600\/20 {
  background-color: rgb(217 119 6 / 0.2) !important;
}

.tw-bg-amber-600\/25 {
  background-color: rgb(217 119 6 / 0.25) !important;
}

.tw-bg-amber-600\/30 {
  background-color: rgb(217 119 6 / 0.3) !important;
}

.tw-bg-amber-600\/35 {
  background-color: rgb(217 119 6 / 0.35) !important;
}

.tw-bg-amber-600\/40 {
  background-color: rgb(217 119 6 / 0.4) !important;
}

.tw-bg-amber-600\/45 {
  background-color: rgb(217 119 6 / 0.45) !important;
}

.tw-bg-amber-600\/5 {
  background-color: rgb(217 119 6 / 0.05) !important;
}

.tw-bg-amber-600\/50 {
  background-color: rgb(217 119 6 / 0.5) !important;
}

.tw-bg-amber-600\/55 {
  background-color: rgb(217 119 6 / 0.55) !important;
}

.tw-bg-amber-600\/60 {
  background-color: rgb(217 119 6 / 0.6) !important;
}

.tw-bg-amber-600\/65 {
  background-color: rgb(217 119 6 / 0.65) !important;
}

.tw-bg-amber-600\/70 {
  background-color: rgb(217 119 6 / 0.7) !important;
}

.tw-bg-amber-600\/75 {
  background-color: rgb(217 119 6 / 0.75) !important;
}

.tw-bg-amber-600\/80 {
  background-color: rgb(217 119 6 / 0.8) !important;
}

.tw-bg-amber-600\/85 {
  background-color: rgb(217 119 6 / 0.85) !important;
}

.tw-bg-amber-600\/90 {
  background-color: rgb(217 119 6 / 0.9) !important;
}

.tw-bg-amber-600\/95 {
  background-color: rgb(217 119 6 / 0.95) !important;
}

.tw-bg-amber-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(180 83 9 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-700\/0 {
  background-color: rgb(180 83 9 / 0) !important;
}

.tw-bg-amber-700\/10 {
  background-color: rgb(180 83 9 / 0.1) !important;
}

.tw-bg-amber-700\/100 {
  background-color: rgb(180 83 9 / 1) !important;
}

.tw-bg-amber-700\/15 {
  background-color: rgb(180 83 9 / 0.15) !important;
}

.tw-bg-amber-700\/20 {
  background-color: rgb(180 83 9 / 0.2) !important;
}

.tw-bg-amber-700\/25 {
  background-color: rgb(180 83 9 / 0.25) !important;
}

.tw-bg-amber-700\/30 {
  background-color: rgb(180 83 9 / 0.3) !important;
}

.tw-bg-amber-700\/35 {
  background-color: rgb(180 83 9 / 0.35) !important;
}

.tw-bg-amber-700\/40 {
  background-color: rgb(180 83 9 / 0.4) !important;
}

.tw-bg-amber-700\/45 {
  background-color: rgb(180 83 9 / 0.45) !important;
}

.tw-bg-amber-700\/5 {
  background-color: rgb(180 83 9 / 0.05) !important;
}

.tw-bg-amber-700\/50 {
  background-color: rgb(180 83 9 / 0.5) !important;
}

.tw-bg-amber-700\/55 {
  background-color: rgb(180 83 9 / 0.55) !important;
}

.tw-bg-amber-700\/60 {
  background-color: rgb(180 83 9 / 0.6) !important;
}

.tw-bg-amber-700\/65 {
  background-color: rgb(180 83 9 / 0.65) !important;
}

.tw-bg-amber-700\/70 {
  background-color: rgb(180 83 9 / 0.7) !important;
}

.tw-bg-amber-700\/75 {
  background-color: rgb(180 83 9 / 0.75) !important;
}

.tw-bg-amber-700\/80 {
  background-color: rgb(180 83 9 / 0.8) !important;
}

.tw-bg-amber-700\/85 {
  background-color: rgb(180 83 9 / 0.85) !important;
}

.tw-bg-amber-700\/90 {
  background-color: rgb(180 83 9 / 0.9) !important;
}

.tw-bg-amber-700\/95 {
  background-color: rgb(180 83 9 / 0.95) !important;
}

.tw-bg-amber-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(146 64 14 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-800\/0 {
  background-color: rgb(146 64 14 / 0) !important;
}

.tw-bg-amber-800\/10 {
  background-color: rgb(146 64 14 / 0.1) !important;
}

.tw-bg-amber-800\/100 {
  background-color: rgb(146 64 14 / 1) !important;
}

.tw-bg-amber-800\/15 {
  background-color: rgb(146 64 14 / 0.15) !important;
}

.tw-bg-amber-800\/20 {
  background-color: rgb(146 64 14 / 0.2) !important;
}

.tw-bg-amber-800\/25 {
  background-color: rgb(146 64 14 / 0.25) !important;
}

.tw-bg-amber-800\/30 {
  background-color: rgb(146 64 14 / 0.3) !important;
}

.tw-bg-amber-800\/35 {
  background-color: rgb(146 64 14 / 0.35) !important;
}

.tw-bg-amber-800\/40 {
  background-color: rgb(146 64 14 / 0.4) !important;
}

.tw-bg-amber-800\/45 {
  background-color: rgb(146 64 14 / 0.45) !important;
}

.tw-bg-amber-800\/5 {
  background-color: rgb(146 64 14 / 0.05) !important;
}

.tw-bg-amber-800\/50 {
  background-color: rgb(146 64 14 / 0.5) !important;
}

.tw-bg-amber-800\/55 {
  background-color: rgb(146 64 14 / 0.55) !important;
}

.tw-bg-amber-800\/60 {
  background-color: rgb(146 64 14 / 0.6) !important;
}

.tw-bg-amber-800\/65 {
  background-color: rgb(146 64 14 / 0.65) !important;
}

.tw-bg-amber-800\/70 {
  background-color: rgb(146 64 14 / 0.7) !important;
}

.tw-bg-amber-800\/75 {
  background-color: rgb(146 64 14 / 0.75) !important;
}

.tw-bg-amber-800\/80 {
  background-color: rgb(146 64 14 / 0.8) !important;
}

.tw-bg-amber-800\/85 {
  background-color: rgb(146 64 14 / 0.85) !important;
}

.tw-bg-amber-800\/90 {
  background-color: rgb(146 64 14 / 0.9) !important;
}

.tw-bg-amber-800\/95 {
  background-color: rgb(146 64 14 / 0.95) !important;
}

.tw-bg-amber-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(120 53 15 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-900\/0 {
  background-color: rgb(120 53 15 / 0) !important;
}

.tw-bg-amber-900\/10 {
  background-color: rgb(120 53 15 / 0.1) !important;
}

.tw-bg-amber-900\/100 {
  background-color: rgb(120 53 15 / 1) !important;
}

.tw-bg-amber-900\/15 {
  background-color: rgb(120 53 15 / 0.15) !important;
}

.tw-bg-amber-900\/20 {
  background-color: rgb(120 53 15 / 0.2) !important;
}

.tw-bg-amber-900\/25 {
  background-color: rgb(120 53 15 / 0.25) !important;
}

.tw-bg-amber-900\/30 {
  background-color: rgb(120 53 15 / 0.3) !important;
}

.tw-bg-amber-900\/35 {
  background-color: rgb(120 53 15 / 0.35) !important;
}

.tw-bg-amber-900\/40 {
  background-color: rgb(120 53 15 / 0.4) !important;
}

.tw-bg-amber-900\/45 {
  background-color: rgb(120 53 15 / 0.45) !important;
}

.tw-bg-amber-900\/5 {
  background-color: rgb(120 53 15 / 0.05) !important;
}

.tw-bg-amber-900\/50 {
  background-color: rgb(120 53 15 / 0.5) !important;
}

.tw-bg-amber-900\/55 {
  background-color: rgb(120 53 15 / 0.55) !important;
}

.tw-bg-amber-900\/60 {
  background-color: rgb(120 53 15 / 0.6) !important;
}

.tw-bg-amber-900\/65 {
  background-color: rgb(120 53 15 / 0.65) !important;
}

.tw-bg-amber-900\/70 {
  background-color: rgb(120 53 15 / 0.7) !important;
}

.tw-bg-amber-900\/75 {
  background-color: rgb(120 53 15 / 0.75) !important;
}

.tw-bg-amber-900\/80 {
  background-color: rgb(120 53 15 / 0.8) !important;
}

.tw-bg-amber-900\/85 {
  background-color: rgb(120 53 15 / 0.85) !important;
}

.tw-bg-amber-900\/90 {
  background-color: rgb(120 53 15 / 0.9) !important;
}

.tw-bg-amber-900\/95 {
  background-color: rgb(120 53 15 / 0.95) !important;
}

.tw-bg-amber-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(69 26 3 / var(--tw-bg-opacity)) !important;
}

.tw-bg-amber-950\/0 {
  background-color: rgb(69 26 3 / 0) !important;
}

.tw-bg-amber-950\/10 {
  background-color: rgb(69 26 3 / 0.1) !important;
}

.tw-bg-amber-950\/100 {
  background-color: rgb(69 26 3 / 1) !important;
}

.tw-bg-amber-950\/15 {
  background-color: rgb(69 26 3 / 0.15) !important;
}

.tw-bg-amber-950\/20 {
  background-color: rgb(69 26 3 / 0.2) !important;
}

.tw-bg-amber-950\/25 {
  background-color: rgb(69 26 3 / 0.25) !important;
}

.tw-bg-amber-950\/30 {
  background-color: rgb(69 26 3 / 0.3) !important;
}

.tw-bg-amber-950\/35 {
  background-color: rgb(69 26 3 / 0.35) !important;
}

.tw-bg-amber-950\/40 {
  background-color: rgb(69 26 3 / 0.4) !important;
}

.tw-bg-amber-950\/45 {
  background-color: rgb(69 26 3 / 0.45) !important;
}

.tw-bg-amber-950\/5 {
  background-color: rgb(69 26 3 / 0.05) !important;
}

.tw-bg-amber-950\/50 {
  background-color: rgb(69 26 3 / 0.5) !important;
}

.tw-bg-amber-950\/55 {
  background-color: rgb(69 26 3 / 0.55) !important;
}

.tw-bg-amber-950\/60 {
  background-color: rgb(69 26 3 / 0.6) !important;
}

.tw-bg-amber-950\/65 {
  background-color: rgb(69 26 3 / 0.65) !important;
}

.tw-bg-amber-950\/70 {
  background-color: rgb(69 26 3 / 0.7) !important;
}

.tw-bg-amber-950\/75 {
  background-color: rgb(69 26 3 / 0.75) !important;
}

.tw-bg-amber-950\/80 {
  background-color: rgb(69 26 3 / 0.8) !important;
}

.tw-bg-amber-950\/85 {
  background-color: rgb(69 26 3 / 0.85) !important;
}

.tw-bg-amber-950\/90 {
  background-color: rgb(69 26 3 / 0.9) !important;
}

.tw-bg-amber-950\/95 {
  background-color: rgb(69 26 3 / 0.95) !important;
}

.tw-bg-black {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity)) !important;
}

.tw-bg-black\/0 {
  background-color: rgb(0 0 0 / 0) !important;
}

.tw-bg-black\/10 {
  background-color: rgb(0 0 0 / 0.1) !important;
}

.tw-bg-black\/100 {
  background-color: rgb(0 0 0 / 1) !important;
}

.tw-bg-black\/15 {
  background-color: rgb(0 0 0 / 0.15) !important;
}

.tw-bg-black\/20 {
  background-color: rgb(0 0 0 / 0.2) !important;
}

.tw-bg-black\/25 {
  background-color: rgb(0 0 0 / 0.25) !important;
}

.tw-bg-black\/30 {
  background-color: rgb(0 0 0 / 0.3) !important;
}

.tw-bg-black\/35 {
  background-color: rgb(0 0 0 / 0.35) !important;
}

.tw-bg-black\/40 {
  background-color: rgb(0 0 0 / 0.4) !important;
}

.tw-bg-black\/45 {
  background-color: rgb(0 0 0 / 0.45) !important;
}

.tw-bg-black\/5 {
  background-color: rgb(0 0 0 / 0.05) !important;
}

.tw-bg-black\/50 {
  background-color: rgb(0 0 0 / 0.5) !important;
}

.tw-bg-black\/55 {
  background-color: rgb(0 0 0 / 0.55) !important;
}

.tw-bg-black\/60 {
  background-color: rgb(0 0 0 / 0.6) !important;
}

.tw-bg-black\/65 {
  background-color: rgb(0 0 0 / 0.65) !important;
}

.tw-bg-black\/70 {
  background-color: rgb(0 0 0 / 0.7) !important;
}

.tw-bg-black\/75 {
  background-color: rgb(0 0 0 / 0.75) !important;
}

.tw-bg-black\/80 {
  background-color: rgb(0 0 0 / 0.8) !important;
}

.tw-bg-black\/85 {
  background-color: rgb(0 0 0 / 0.85) !important;
}

.tw-bg-black\/90 {
  background-color: rgb(0 0 0 / 0.9) !important;
}

.tw-bg-black\/95 {
  background-color: rgb(0 0 0 / 0.95) !important;
}

.tw-bg-blue-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-100\/0 {
  background-color: rgb(219 234 254 / 0) !important;
}

.tw-bg-blue-100\/10 {
  background-color: rgb(219 234 254 / 0.1) !important;
}

.tw-bg-blue-100\/100 {
  background-color: rgb(219 234 254 / 1) !important;
}

.tw-bg-blue-100\/15 {
  background-color: rgb(219 234 254 / 0.15) !important;
}

.tw-bg-blue-100\/20 {
  background-color: rgb(219 234 254 / 0.2) !important;
}

.tw-bg-blue-100\/25 {
  background-color: rgb(219 234 254 / 0.25) !important;
}

.tw-bg-blue-100\/30 {
  background-color: rgb(219 234 254 / 0.3) !important;
}

.tw-bg-blue-100\/35 {
  background-color: rgb(219 234 254 / 0.35) !important;
}

.tw-bg-blue-100\/40 {
  background-color: rgb(219 234 254 / 0.4) !important;
}

.tw-bg-blue-100\/45 {
  background-color: rgb(219 234 254 / 0.45) !important;
}

.tw-bg-blue-100\/5 {
  background-color: rgb(219 234 254 / 0.05) !important;
}

.tw-bg-blue-100\/50 {
  background-color: rgb(219 234 254 / 0.5) !important;
}

.tw-bg-blue-100\/55 {
  background-color: rgb(219 234 254 / 0.55) !important;
}

.tw-bg-blue-100\/60 {
  background-color: rgb(219 234 254 / 0.6) !important;
}

.tw-bg-blue-100\/65 {
  background-color: rgb(219 234 254 / 0.65) !important;
}

.tw-bg-blue-100\/70 {
  background-color: rgb(219 234 254 / 0.7) !important;
}

.tw-bg-blue-100\/75 {
  background-color: rgb(219 234 254 / 0.75) !important;
}

.tw-bg-blue-100\/80 {
  background-color: rgb(219 234 254 / 0.8) !important;
}

.tw-bg-blue-100\/85 {
  background-color: rgb(219 234 254 / 0.85) !important;
}

.tw-bg-blue-100\/90 {
  background-color: rgb(219 234 254 / 0.9) !important;
}

.tw-bg-blue-100\/95 {
  background-color: rgb(219 234 254 / 0.95) !important;
}

.tw-bg-blue-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(191 219 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-200\/0 {
  background-color: rgb(191 219 254 / 0) !important;
}

.tw-bg-blue-200\/10 {
  background-color: rgb(191 219 254 / 0.1) !important;
}

.tw-bg-blue-200\/100 {
  background-color: rgb(191 219 254 / 1) !important;
}

.tw-bg-blue-200\/15 {
  background-color: rgb(191 219 254 / 0.15) !important;
}

.tw-bg-blue-200\/20 {
  background-color: rgb(191 219 254 / 0.2) !important;
}

.tw-bg-blue-200\/25 {
  background-color: rgb(191 219 254 / 0.25) !important;
}

.tw-bg-blue-200\/30 {
  background-color: rgb(191 219 254 / 0.3) !important;
}

.tw-bg-blue-200\/35 {
  background-color: rgb(191 219 254 / 0.35) !important;
}

.tw-bg-blue-200\/40 {
  background-color: rgb(191 219 254 / 0.4) !important;
}

.tw-bg-blue-200\/45 {
  background-color: rgb(191 219 254 / 0.45) !important;
}

.tw-bg-blue-200\/5 {
  background-color: rgb(191 219 254 / 0.05) !important;
}

.tw-bg-blue-200\/50 {
  background-color: rgb(191 219 254 / 0.5) !important;
}

.tw-bg-blue-200\/55 {
  background-color: rgb(191 219 254 / 0.55) !important;
}

.tw-bg-blue-200\/60 {
  background-color: rgb(191 219 254 / 0.6) !important;
}

.tw-bg-blue-200\/65 {
  background-color: rgb(191 219 254 / 0.65) !important;
}

.tw-bg-blue-200\/70 {
  background-color: rgb(191 219 254 / 0.7) !important;
}

.tw-bg-blue-200\/75 {
  background-color: rgb(191 219 254 / 0.75) !important;
}

.tw-bg-blue-200\/80 {
  background-color: rgb(191 219 254 / 0.8) !important;
}

.tw-bg-blue-200\/85 {
  background-color: rgb(191 219 254 / 0.85) !important;
}

.tw-bg-blue-200\/90 {
  background-color: rgb(191 219 254 / 0.9) !important;
}

.tw-bg-blue-200\/95 {
  background-color: rgb(191 219 254 / 0.95) !important;
}

.tw-bg-blue-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-300\/0 {
  background-color: rgb(147 197 253 / 0) !important;
}

.tw-bg-blue-300\/10 {
  background-color: rgb(147 197 253 / 0.1) !important;
}

.tw-bg-blue-300\/100 {
  background-color: rgb(147 197 253 / 1) !important;
}

.tw-bg-blue-300\/15 {
  background-color: rgb(147 197 253 / 0.15) !important;
}

.tw-bg-blue-300\/20 {
  background-color: rgb(147 197 253 / 0.2) !important;
}

.tw-bg-blue-300\/25 {
  background-color: rgb(147 197 253 / 0.25) !important;
}

.tw-bg-blue-300\/30 {
  background-color: rgb(147 197 253 / 0.3) !important;
}

.tw-bg-blue-300\/35 {
  background-color: rgb(147 197 253 / 0.35) !important;
}

.tw-bg-blue-300\/40 {
  background-color: rgb(147 197 253 / 0.4) !important;
}

.tw-bg-blue-300\/45 {
  background-color: rgb(147 197 253 / 0.45) !important;
}

.tw-bg-blue-300\/5 {
  background-color: rgb(147 197 253 / 0.05) !important;
}

.tw-bg-blue-300\/50 {
  background-color: rgb(147 197 253 / 0.5) !important;
}

.tw-bg-blue-300\/55 {
  background-color: rgb(147 197 253 / 0.55) !important;
}

.tw-bg-blue-300\/60 {
  background-color: rgb(147 197 253 / 0.6) !important;
}

.tw-bg-blue-300\/65 {
  background-color: rgb(147 197 253 / 0.65) !important;
}

.tw-bg-blue-300\/70 {
  background-color: rgb(147 197 253 / 0.7) !important;
}

.tw-bg-blue-300\/75 {
  background-color: rgb(147 197 253 / 0.75) !important;
}

.tw-bg-blue-300\/80 {
  background-color: rgb(147 197 253 / 0.8) !important;
}

.tw-bg-blue-300\/85 {
  background-color: rgb(147 197 253 / 0.85) !important;
}

.tw-bg-blue-300\/90 {
  background-color: rgb(147 197 253 / 0.9) !important;
}

.tw-bg-blue-300\/95 {
  background-color: rgb(147 197 253 / 0.95) !important;
}

.tw-bg-blue-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(96 165 250 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-400\/0 {
  background-color: rgb(96 165 250 / 0) !important;
}

.tw-bg-blue-400\/10 {
  background-color: rgb(96 165 250 / 0.1) !important;
}

.tw-bg-blue-400\/100 {
  background-color: rgb(96 165 250 / 1) !important;
}

.tw-bg-blue-400\/15 {
  background-color: rgb(96 165 250 / 0.15) !important;
}

.tw-bg-blue-400\/20 {
  background-color: rgb(96 165 250 / 0.2) !important;
}

.tw-bg-blue-400\/25 {
  background-color: rgb(96 165 250 / 0.25) !important;
}

.tw-bg-blue-400\/30 {
  background-color: rgb(96 165 250 / 0.3) !important;
}

.tw-bg-blue-400\/35 {
  background-color: rgb(96 165 250 / 0.35) !important;
}

.tw-bg-blue-400\/40 {
  background-color: rgb(96 165 250 / 0.4) !important;
}

.tw-bg-blue-400\/45 {
  background-color: rgb(96 165 250 / 0.45) !important;
}

.tw-bg-blue-400\/5 {
  background-color: rgb(96 165 250 / 0.05) !important;
}

.tw-bg-blue-400\/50 {
  background-color: rgb(96 165 250 / 0.5) !important;
}

.tw-bg-blue-400\/55 {
  background-color: rgb(96 165 250 / 0.55) !important;
}

.tw-bg-blue-400\/60 {
  background-color: rgb(96 165 250 / 0.6) !important;
}

.tw-bg-blue-400\/65 {
  background-color: rgb(96 165 250 / 0.65) !important;
}

.tw-bg-blue-400\/70 {
  background-color: rgb(96 165 250 / 0.7) !important;
}

.tw-bg-blue-400\/75 {
  background-color: rgb(96 165 250 / 0.75) !important;
}

.tw-bg-blue-400\/80 {
  background-color: rgb(96 165 250 / 0.8) !important;
}

.tw-bg-blue-400\/85 {
  background-color: rgb(96 165 250 / 0.85) !important;
}

.tw-bg-blue-400\/90 {
  background-color: rgb(96 165 250 / 0.9) !important;
}

.tw-bg-blue-400\/95 {
  background-color: rgb(96 165 250 / 0.95) !important;
}

.tw-bg-blue-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 246 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-50\/0 {
  background-color: rgb(239 246 255 / 0) !important;
}

.tw-bg-blue-50\/10 {
  background-color: rgb(239 246 255 / 0.1) !important;
}

.tw-bg-blue-50\/100 {
  background-color: rgb(239 246 255 / 1) !important;
}

.tw-bg-blue-50\/15 {
  background-color: rgb(239 246 255 / 0.15) !important;
}

.tw-bg-blue-50\/20 {
  background-color: rgb(239 246 255 / 0.2) !important;
}

.tw-bg-blue-50\/25 {
  background-color: rgb(239 246 255 / 0.25) !important;
}

.tw-bg-blue-50\/30 {
  background-color: rgb(239 246 255 / 0.3) !important;
}

.tw-bg-blue-50\/35 {
  background-color: rgb(239 246 255 / 0.35) !important;
}

.tw-bg-blue-50\/40 {
  background-color: rgb(239 246 255 / 0.4) !important;
}

.tw-bg-blue-50\/45 {
  background-color: rgb(239 246 255 / 0.45) !important;
}

.tw-bg-blue-50\/5 {
  background-color: rgb(239 246 255 / 0.05) !important;
}

.tw-bg-blue-50\/50 {
  background-color: rgb(239 246 255 / 0.5) !important;
}

.tw-bg-blue-50\/55 {
  background-color: rgb(239 246 255 / 0.55) !important;
}

.tw-bg-blue-50\/60 {
  background-color: rgb(239 246 255 / 0.6) !important;
}

.tw-bg-blue-50\/65 {
  background-color: rgb(239 246 255 / 0.65) !important;
}

.tw-bg-blue-50\/70 {
  background-color: rgb(239 246 255 / 0.7) !important;
}

.tw-bg-blue-50\/75 {
  background-color: rgb(239 246 255 / 0.75) !important;
}

.tw-bg-blue-50\/80 {
  background-color: rgb(239 246 255 / 0.8) !important;
}

.tw-bg-blue-50\/85 {
  background-color: rgb(239 246 255 / 0.85) !important;
}

.tw-bg-blue-50\/90 {
  background-color: rgb(239 246 255 / 0.9) !important;
}

.tw-bg-blue-50\/95 {
  background-color: rgb(239 246 255 / 0.95) !important;
}

.tw-bg-blue-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-500\/0 {
  background-color: rgb(59 130 246 / 0) !important;
}

.tw-bg-blue-500\/10 {
  background-color: rgb(59 130 246 / 0.1) !important;
}

.tw-bg-blue-500\/100 {
  background-color: rgb(59 130 246 / 1) !important;
}

.tw-bg-blue-500\/15 {
  background-color: rgb(59 130 246 / 0.15) !important;
}

.tw-bg-blue-500\/20 {
  background-color: rgb(59 130 246 / 0.2) !important;
}

.tw-bg-blue-500\/25 {
  background-color: rgb(59 130 246 / 0.25) !important;
}

.tw-bg-blue-500\/30 {
  background-color: rgb(59 130 246 / 0.3) !important;
}

.tw-bg-blue-500\/35 {
  background-color: rgb(59 130 246 / 0.35) !important;
}

.tw-bg-blue-500\/40 {
  background-color: rgb(59 130 246 / 0.4) !important;
}

.tw-bg-blue-500\/45 {
  background-color: rgb(59 130 246 / 0.45) !important;
}

.tw-bg-blue-500\/5 {
  background-color: rgb(59 130 246 / 0.05) !important;
}

.tw-bg-blue-500\/50 {
  background-color: rgb(59 130 246 / 0.5) !important;
}

.tw-bg-blue-500\/55 {
  background-color: rgb(59 130 246 / 0.55) !important;
}

.tw-bg-blue-500\/60 {
  background-color: rgb(59 130 246 / 0.6) !important;
}

.tw-bg-blue-500\/65 {
  background-color: rgb(59 130 246 / 0.65) !important;
}

.tw-bg-blue-500\/70 {
  background-color: rgb(59 130 246 / 0.7) !important;
}

.tw-bg-blue-500\/75 {
  background-color: rgb(59 130 246 / 0.75) !important;
}

.tw-bg-blue-500\/80 {
  background-color: rgb(59 130 246 / 0.8) !important;
}

.tw-bg-blue-500\/85 {
  background-color: rgb(59 130 246 / 0.85) !important;
}

.tw-bg-blue-500\/90 {
  background-color: rgb(59 130 246 / 0.9) !important;
}

.tw-bg-blue-500\/95 {
  background-color: rgb(59 130 246 / 0.95) !important;
}

.tw-bg-blue-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(37 99 235 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-600\/0 {
  background-color: rgb(37 99 235 / 0) !important;
}

.tw-bg-blue-600\/10 {
  background-color: rgb(37 99 235 / 0.1) !important;
}

.tw-bg-blue-600\/100 {
  background-color: rgb(37 99 235 / 1) !important;
}

.tw-bg-blue-600\/15 {
  background-color: rgb(37 99 235 / 0.15) !important;
}

.tw-bg-blue-600\/20 {
  background-color: rgb(37 99 235 / 0.2) !important;
}

.tw-bg-blue-600\/25 {
  background-color: rgb(37 99 235 / 0.25) !important;
}

.tw-bg-blue-600\/30 {
  background-color: rgb(37 99 235 / 0.3) !important;
}

.tw-bg-blue-600\/35 {
  background-color: rgb(37 99 235 / 0.35) !important;
}

.tw-bg-blue-600\/40 {
  background-color: rgb(37 99 235 / 0.4) !important;
}

.tw-bg-blue-600\/45 {
  background-color: rgb(37 99 235 / 0.45) !important;
}

.tw-bg-blue-600\/5 {
  background-color: rgb(37 99 235 / 0.05) !important;
}

.tw-bg-blue-600\/50 {
  background-color: rgb(37 99 235 / 0.5) !important;
}

.tw-bg-blue-600\/55 {
  background-color: rgb(37 99 235 / 0.55) !important;
}

.tw-bg-blue-600\/60 {
  background-color: rgb(37 99 235 / 0.6) !important;
}

.tw-bg-blue-600\/65 {
  background-color: rgb(37 99 235 / 0.65) !important;
}

.tw-bg-blue-600\/70 {
  background-color: rgb(37 99 235 / 0.7) !important;
}

.tw-bg-blue-600\/75 {
  background-color: rgb(37 99 235 / 0.75) !important;
}

.tw-bg-blue-600\/80 {
  background-color: rgb(37 99 235 / 0.8) !important;
}

.tw-bg-blue-600\/85 {
  background-color: rgb(37 99 235 / 0.85) !important;
}

.tw-bg-blue-600\/90 {
  background-color: rgb(37 99 235 / 0.9) !important;
}

.tw-bg-blue-600\/95 {
  background-color: rgb(37 99 235 / 0.95) !important;
}

.tw-bg-blue-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(29 78 216 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-700\/0 {
  background-color: rgb(29 78 216 / 0) !important;
}

.tw-bg-blue-700\/10 {
  background-color: rgb(29 78 216 / 0.1) !important;
}

.tw-bg-blue-700\/100 {
  background-color: rgb(29 78 216 / 1) !important;
}

.tw-bg-blue-700\/15 {
  background-color: rgb(29 78 216 / 0.15) !important;
}

.tw-bg-blue-700\/20 {
  background-color: rgb(29 78 216 / 0.2) !important;
}

.tw-bg-blue-700\/25 {
  background-color: rgb(29 78 216 / 0.25) !important;
}

.tw-bg-blue-700\/30 {
  background-color: rgb(29 78 216 / 0.3) !important;
}

.tw-bg-blue-700\/35 {
  background-color: rgb(29 78 216 / 0.35) !important;
}

.tw-bg-blue-700\/40 {
  background-color: rgb(29 78 216 / 0.4) !important;
}

.tw-bg-blue-700\/45 {
  background-color: rgb(29 78 216 / 0.45) !important;
}

.tw-bg-blue-700\/5 {
  background-color: rgb(29 78 216 / 0.05) !important;
}

.tw-bg-blue-700\/50 {
  background-color: rgb(29 78 216 / 0.5) !important;
}

.tw-bg-blue-700\/55 {
  background-color: rgb(29 78 216 / 0.55) !important;
}

.tw-bg-blue-700\/60 {
  background-color: rgb(29 78 216 / 0.6) !important;
}

.tw-bg-blue-700\/65 {
  background-color: rgb(29 78 216 / 0.65) !important;
}

.tw-bg-blue-700\/70 {
  background-color: rgb(29 78 216 / 0.7) !important;
}

.tw-bg-blue-700\/75 {
  background-color: rgb(29 78 216 / 0.75) !important;
}

.tw-bg-blue-700\/80 {
  background-color: rgb(29 78 216 / 0.8) !important;
}

.tw-bg-blue-700\/85 {
  background-color: rgb(29 78 216 / 0.85) !important;
}

.tw-bg-blue-700\/90 {
  background-color: rgb(29 78 216 / 0.9) !important;
}

.tw-bg-blue-700\/95 {
  background-color: rgb(29 78 216 / 0.95) !important;
}

.tw-bg-blue-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 64 175 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-800\/0 {
  background-color: rgb(30 64 175 / 0) !important;
}

.tw-bg-blue-800\/10 {
  background-color: rgb(30 64 175 / 0.1) !important;
}

.tw-bg-blue-800\/100 {
  background-color: rgb(30 64 175 / 1) !important;
}

.tw-bg-blue-800\/15 {
  background-color: rgb(30 64 175 / 0.15) !important;
}

.tw-bg-blue-800\/20 {
  background-color: rgb(30 64 175 / 0.2) !important;
}

.tw-bg-blue-800\/25 {
  background-color: rgb(30 64 175 / 0.25) !important;
}

.tw-bg-blue-800\/30 {
  background-color: rgb(30 64 175 / 0.3) !important;
}

.tw-bg-blue-800\/35 {
  background-color: rgb(30 64 175 / 0.35) !important;
}

.tw-bg-blue-800\/40 {
  background-color: rgb(30 64 175 / 0.4) !important;
}

.tw-bg-blue-800\/45 {
  background-color: rgb(30 64 175 / 0.45) !important;
}

.tw-bg-blue-800\/5 {
  background-color: rgb(30 64 175 / 0.05) !important;
}

.tw-bg-blue-800\/50 {
  background-color: rgb(30 64 175 / 0.5) !important;
}

.tw-bg-blue-800\/55 {
  background-color: rgb(30 64 175 / 0.55) !important;
}

.tw-bg-blue-800\/60 {
  background-color: rgb(30 64 175 / 0.6) !important;
}

.tw-bg-blue-800\/65 {
  background-color: rgb(30 64 175 / 0.65) !important;
}

.tw-bg-blue-800\/70 {
  background-color: rgb(30 64 175 / 0.7) !important;
}

.tw-bg-blue-800\/75 {
  background-color: rgb(30 64 175 / 0.75) !important;
}

.tw-bg-blue-800\/80 {
  background-color: rgb(30 64 175 / 0.8) !important;
}

.tw-bg-blue-800\/85 {
  background-color: rgb(30 64 175 / 0.85) !important;
}

.tw-bg-blue-800\/90 {
  background-color: rgb(30 64 175 / 0.9) !important;
}

.tw-bg-blue-800\/95 {
  background-color: rgb(30 64 175 / 0.95) !important;
}

.tw-bg-blue-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 58 138 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-900\/0 {
  background-color: rgb(30 58 138 / 0) !important;
}

.tw-bg-blue-900\/10 {
  background-color: rgb(30 58 138 / 0.1) !important;
}

.tw-bg-blue-900\/100 {
  background-color: rgb(30 58 138 / 1) !important;
}

.tw-bg-blue-900\/15 {
  background-color: rgb(30 58 138 / 0.15) !important;
}

.tw-bg-blue-900\/20 {
  background-color: rgb(30 58 138 / 0.2) !important;
}

.tw-bg-blue-900\/25 {
  background-color: rgb(30 58 138 / 0.25) !important;
}

.tw-bg-blue-900\/30 {
  background-color: rgb(30 58 138 / 0.3) !important;
}

.tw-bg-blue-900\/35 {
  background-color: rgb(30 58 138 / 0.35) !important;
}

.tw-bg-blue-900\/40 {
  background-color: rgb(30 58 138 / 0.4) !important;
}

.tw-bg-blue-900\/45 {
  background-color: rgb(30 58 138 / 0.45) !important;
}

.tw-bg-blue-900\/5 {
  background-color: rgb(30 58 138 / 0.05) !important;
}

.tw-bg-blue-900\/50 {
  background-color: rgb(30 58 138 / 0.5) !important;
}

.tw-bg-blue-900\/55 {
  background-color: rgb(30 58 138 / 0.55) !important;
}

.tw-bg-blue-900\/60 {
  background-color: rgb(30 58 138 / 0.6) !important;
}

.tw-bg-blue-900\/65 {
  background-color: rgb(30 58 138 / 0.65) !important;
}

.tw-bg-blue-900\/70 {
  background-color: rgb(30 58 138 / 0.7) !important;
}

.tw-bg-blue-900\/75 {
  background-color: rgb(30 58 138 / 0.75) !important;
}

.tw-bg-blue-900\/80 {
  background-color: rgb(30 58 138 / 0.8) !important;
}

.tw-bg-blue-900\/85 {
  background-color: rgb(30 58 138 / 0.85) !important;
}

.tw-bg-blue-900\/90 {
  background-color: rgb(30 58 138 / 0.9) !important;
}

.tw-bg-blue-900\/95 {
  background-color: rgb(30 58 138 / 0.95) !important;
}

.tw-bg-blue-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(23 37 84 / var(--tw-bg-opacity)) !important;
}

.tw-bg-blue-950\/0 {
  background-color: rgb(23 37 84 / 0) !important;
}

.tw-bg-blue-950\/10 {
  background-color: rgb(23 37 84 / 0.1) !important;
}

.tw-bg-blue-950\/100 {
  background-color: rgb(23 37 84 / 1) !important;
}

.tw-bg-blue-950\/15 {
  background-color: rgb(23 37 84 / 0.15) !important;
}

.tw-bg-blue-950\/20 {
  background-color: rgb(23 37 84 / 0.2) !important;
}

.tw-bg-blue-950\/25 {
  background-color: rgb(23 37 84 / 0.25) !important;
}

.tw-bg-blue-950\/30 {
  background-color: rgb(23 37 84 / 0.3) !important;
}

.tw-bg-blue-950\/35 {
  background-color: rgb(23 37 84 / 0.35) !important;
}

.tw-bg-blue-950\/40 {
  background-color: rgb(23 37 84 / 0.4) !important;
}

.tw-bg-blue-950\/45 {
  background-color: rgb(23 37 84 / 0.45) !important;
}

.tw-bg-blue-950\/5 {
  background-color: rgb(23 37 84 / 0.05) !important;
}

.tw-bg-blue-950\/50 {
  background-color: rgb(23 37 84 / 0.5) !important;
}

.tw-bg-blue-950\/55 {
  background-color: rgb(23 37 84 / 0.55) !important;
}

.tw-bg-blue-950\/60 {
  background-color: rgb(23 37 84 / 0.6) !important;
}

.tw-bg-blue-950\/65 {
  background-color: rgb(23 37 84 / 0.65) !important;
}

.tw-bg-blue-950\/70 {
  background-color: rgb(23 37 84 / 0.7) !important;
}

.tw-bg-blue-950\/75 {
  background-color: rgb(23 37 84 / 0.75) !important;
}

.tw-bg-blue-950\/80 {
  background-color: rgb(23 37 84 / 0.8) !important;
}

.tw-bg-blue-950\/85 {
  background-color: rgb(23 37 84 / 0.85) !important;
}

.tw-bg-blue-950\/90 {
  background-color: rgb(23 37 84 / 0.9) !important;
}

.tw-bg-blue-950\/95 {
  background-color: rgb(23 37 84 / 0.95) !important;
}

.tw-bg-current {
  background-color: currentColor !important;
}

.tw-bg-cyan-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(207 250 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-100\/0 {
  background-color: rgb(207 250 254 / 0) !important;
}

.tw-bg-cyan-100\/10 {
  background-color: rgb(207 250 254 / 0.1) !important;
}

.tw-bg-cyan-100\/100 {
  background-color: rgb(207 250 254 / 1) !important;
}

.tw-bg-cyan-100\/15 {
  background-color: rgb(207 250 254 / 0.15) !important;
}

.tw-bg-cyan-100\/20 {
  background-color: rgb(207 250 254 / 0.2) !important;
}

.tw-bg-cyan-100\/25 {
  background-color: rgb(207 250 254 / 0.25) !important;
}

.tw-bg-cyan-100\/30 {
  background-color: rgb(207 250 254 / 0.3) !important;
}

.tw-bg-cyan-100\/35 {
  background-color: rgb(207 250 254 / 0.35) !important;
}

.tw-bg-cyan-100\/40 {
  background-color: rgb(207 250 254 / 0.4) !important;
}

.tw-bg-cyan-100\/45 {
  background-color: rgb(207 250 254 / 0.45) !important;
}

.tw-bg-cyan-100\/5 {
  background-color: rgb(207 250 254 / 0.05) !important;
}

.tw-bg-cyan-100\/50 {
  background-color: rgb(207 250 254 / 0.5) !important;
}

.tw-bg-cyan-100\/55 {
  background-color: rgb(207 250 254 / 0.55) !important;
}

.tw-bg-cyan-100\/60 {
  background-color: rgb(207 250 254 / 0.6) !important;
}

.tw-bg-cyan-100\/65 {
  background-color: rgb(207 250 254 / 0.65) !important;
}

.tw-bg-cyan-100\/70 {
  background-color: rgb(207 250 254 / 0.7) !important;
}

.tw-bg-cyan-100\/75 {
  background-color: rgb(207 250 254 / 0.75) !important;
}

.tw-bg-cyan-100\/80 {
  background-color: rgb(207 250 254 / 0.8) !important;
}

.tw-bg-cyan-100\/85 {
  background-color: rgb(207 250 254 / 0.85) !important;
}

.tw-bg-cyan-100\/90 {
  background-color: rgb(207 250 254 / 0.9) !important;
}

.tw-bg-cyan-100\/95 {
  background-color: rgb(207 250 254 / 0.95) !important;
}

.tw-bg-cyan-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(165 243 252 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-200\/0 {
  background-color: rgb(165 243 252 / 0) !important;
}

.tw-bg-cyan-200\/10 {
  background-color: rgb(165 243 252 / 0.1) !important;
}

.tw-bg-cyan-200\/100 {
  background-color: rgb(165 243 252 / 1) !important;
}

.tw-bg-cyan-200\/15 {
  background-color: rgb(165 243 252 / 0.15) !important;
}

.tw-bg-cyan-200\/20 {
  background-color: rgb(165 243 252 / 0.2) !important;
}

.tw-bg-cyan-200\/25 {
  background-color: rgb(165 243 252 / 0.25) !important;
}

.tw-bg-cyan-200\/30 {
  background-color: rgb(165 243 252 / 0.3) !important;
}

.tw-bg-cyan-200\/35 {
  background-color: rgb(165 243 252 / 0.35) !important;
}

.tw-bg-cyan-200\/40 {
  background-color: rgb(165 243 252 / 0.4) !important;
}

.tw-bg-cyan-200\/45 {
  background-color: rgb(165 243 252 / 0.45) !important;
}

.tw-bg-cyan-200\/5 {
  background-color: rgb(165 243 252 / 0.05) !important;
}

.tw-bg-cyan-200\/50 {
  background-color: rgb(165 243 252 / 0.5) !important;
}

.tw-bg-cyan-200\/55 {
  background-color: rgb(165 243 252 / 0.55) !important;
}

.tw-bg-cyan-200\/60 {
  background-color: rgb(165 243 252 / 0.6) !important;
}

.tw-bg-cyan-200\/65 {
  background-color: rgb(165 243 252 / 0.65) !important;
}

.tw-bg-cyan-200\/70 {
  background-color: rgb(165 243 252 / 0.7) !important;
}

.tw-bg-cyan-200\/75 {
  background-color: rgb(165 243 252 / 0.75) !important;
}

.tw-bg-cyan-200\/80 {
  background-color: rgb(165 243 252 / 0.8) !important;
}

.tw-bg-cyan-200\/85 {
  background-color: rgb(165 243 252 / 0.85) !important;
}

.tw-bg-cyan-200\/90 {
  background-color: rgb(165 243 252 / 0.9) !important;
}

.tw-bg-cyan-200\/95 {
  background-color: rgb(165 243 252 / 0.95) !important;
}

.tw-bg-cyan-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-300\/0 {
  background-color: rgb(103 232 249 / 0) !important;
}

.tw-bg-cyan-300\/10 {
  background-color: rgb(103 232 249 / 0.1) !important;
}

.tw-bg-cyan-300\/100 {
  background-color: rgb(103 232 249 / 1) !important;
}

.tw-bg-cyan-300\/15 {
  background-color: rgb(103 232 249 / 0.15) !important;
}

.tw-bg-cyan-300\/20 {
  background-color: rgb(103 232 249 / 0.2) !important;
}

.tw-bg-cyan-300\/25 {
  background-color: rgb(103 232 249 / 0.25) !important;
}

.tw-bg-cyan-300\/30 {
  background-color: rgb(103 232 249 / 0.3) !important;
}

.tw-bg-cyan-300\/35 {
  background-color: rgb(103 232 249 / 0.35) !important;
}

.tw-bg-cyan-300\/40 {
  background-color: rgb(103 232 249 / 0.4) !important;
}

.tw-bg-cyan-300\/45 {
  background-color: rgb(103 232 249 / 0.45) !important;
}

.tw-bg-cyan-300\/5 {
  background-color: rgb(103 232 249 / 0.05) !important;
}

.tw-bg-cyan-300\/50 {
  background-color: rgb(103 232 249 / 0.5) !important;
}

.tw-bg-cyan-300\/55 {
  background-color: rgb(103 232 249 / 0.55) !important;
}

.tw-bg-cyan-300\/60 {
  background-color: rgb(103 232 249 / 0.6) !important;
}

.tw-bg-cyan-300\/65 {
  background-color: rgb(103 232 249 / 0.65) !important;
}

.tw-bg-cyan-300\/70 {
  background-color: rgb(103 232 249 / 0.7) !important;
}

.tw-bg-cyan-300\/75 {
  background-color: rgb(103 232 249 / 0.75) !important;
}

.tw-bg-cyan-300\/80 {
  background-color: rgb(103 232 249 / 0.8) !important;
}

.tw-bg-cyan-300\/85 {
  background-color: rgb(103 232 249 / 0.85) !important;
}

.tw-bg-cyan-300\/90 {
  background-color: rgb(103 232 249 / 0.9) !important;
}

.tw-bg-cyan-300\/95 {
  background-color: rgb(103 232 249 / 0.95) !important;
}

.tw-bg-cyan-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 211 238 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-400\/0 {
  background-color: rgb(34 211 238 / 0) !important;
}

.tw-bg-cyan-400\/10 {
  background-color: rgb(34 211 238 / 0.1) !important;
}

.tw-bg-cyan-400\/100 {
  background-color: rgb(34 211 238 / 1) !important;
}

.tw-bg-cyan-400\/15 {
  background-color: rgb(34 211 238 / 0.15) !important;
}

.tw-bg-cyan-400\/20 {
  background-color: rgb(34 211 238 / 0.2) !important;
}

.tw-bg-cyan-400\/25 {
  background-color: rgb(34 211 238 / 0.25) !important;
}

.tw-bg-cyan-400\/30 {
  background-color: rgb(34 211 238 / 0.3) !important;
}

.tw-bg-cyan-400\/35 {
  background-color: rgb(34 211 238 / 0.35) !important;
}

.tw-bg-cyan-400\/40 {
  background-color: rgb(34 211 238 / 0.4) !important;
}

.tw-bg-cyan-400\/45 {
  background-color: rgb(34 211 238 / 0.45) !important;
}

.tw-bg-cyan-400\/5 {
  background-color: rgb(34 211 238 / 0.05) !important;
}

.tw-bg-cyan-400\/50 {
  background-color: rgb(34 211 238 / 0.5) !important;
}

.tw-bg-cyan-400\/55 {
  background-color: rgb(34 211 238 / 0.55) !important;
}

.tw-bg-cyan-400\/60 {
  background-color: rgb(34 211 238 / 0.6) !important;
}

.tw-bg-cyan-400\/65 {
  background-color: rgb(34 211 238 / 0.65) !important;
}

.tw-bg-cyan-400\/70 {
  background-color: rgb(34 211 238 / 0.7) !important;
}

.tw-bg-cyan-400\/75 {
  background-color: rgb(34 211 238 / 0.75) !important;
}

.tw-bg-cyan-400\/80 {
  background-color: rgb(34 211 238 / 0.8) !important;
}

.tw-bg-cyan-400\/85 {
  background-color: rgb(34 211 238 / 0.85) !important;
}

.tw-bg-cyan-400\/90 {
  background-color: rgb(34 211 238 / 0.9) !important;
}

.tw-bg-cyan-400\/95 {
  background-color: rgb(34 211 238 / 0.95) !important;
}

.tw-bg-cyan-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(236 254 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-50\/0 {
  background-color: rgb(236 254 255 / 0) !important;
}

.tw-bg-cyan-50\/10 {
  background-color: rgb(236 254 255 / 0.1) !important;
}

.tw-bg-cyan-50\/100 {
  background-color: rgb(236 254 255 / 1) !important;
}

.tw-bg-cyan-50\/15 {
  background-color: rgb(236 254 255 / 0.15) !important;
}

.tw-bg-cyan-50\/20 {
  background-color: rgb(236 254 255 / 0.2) !important;
}

.tw-bg-cyan-50\/25 {
  background-color: rgb(236 254 255 / 0.25) !important;
}

.tw-bg-cyan-50\/30 {
  background-color: rgb(236 254 255 / 0.3) !important;
}

.tw-bg-cyan-50\/35 {
  background-color: rgb(236 254 255 / 0.35) !important;
}

.tw-bg-cyan-50\/40 {
  background-color: rgb(236 254 255 / 0.4) !important;
}

.tw-bg-cyan-50\/45 {
  background-color: rgb(236 254 255 / 0.45) !important;
}

.tw-bg-cyan-50\/5 {
  background-color: rgb(236 254 255 / 0.05) !important;
}

.tw-bg-cyan-50\/50 {
  background-color: rgb(236 254 255 / 0.5) !important;
}

.tw-bg-cyan-50\/55 {
  background-color: rgb(236 254 255 / 0.55) !important;
}

.tw-bg-cyan-50\/60 {
  background-color: rgb(236 254 255 / 0.6) !important;
}

.tw-bg-cyan-50\/65 {
  background-color: rgb(236 254 255 / 0.65) !important;
}

.tw-bg-cyan-50\/70 {
  background-color: rgb(236 254 255 / 0.7) !important;
}

.tw-bg-cyan-50\/75 {
  background-color: rgb(236 254 255 / 0.75) !important;
}

.tw-bg-cyan-50\/80 {
  background-color: rgb(236 254 255 / 0.8) !important;
}

.tw-bg-cyan-50\/85 {
  background-color: rgb(236 254 255 / 0.85) !important;
}

.tw-bg-cyan-50\/90 {
  background-color: rgb(236 254 255 / 0.9) !important;
}

.tw-bg-cyan-50\/95 {
  background-color: rgb(236 254 255 / 0.95) !important;
}

.tw-bg-cyan-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(6 182 212 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-500\/0 {
  background-color: rgb(6 182 212 / 0) !important;
}

.tw-bg-cyan-500\/10 {
  background-color: rgb(6 182 212 / 0.1) !important;
}

.tw-bg-cyan-500\/100 {
  background-color: rgb(6 182 212 / 1) !important;
}

.tw-bg-cyan-500\/15 {
  background-color: rgb(6 182 212 / 0.15) !important;
}

.tw-bg-cyan-500\/20 {
  background-color: rgb(6 182 212 / 0.2) !important;
}

.tw-bg-cyan-500\/25 {
  background-color: rgb(6 182 212 / 0.25) !important;
}

.tw-bg-cyan-500\/30 {
  background-color: rgb(6 182 212 / 0.3) !important;
}

.tw-bg-cyan-500\/35 {
  background-color: rgb(6 182 212 / 0.35) !important;
}

.tw-bg-cyan-500\/40 {
  background-color: rgb(6 182 212 / 0.4) !important;
}

.tw-bg-cyan-500\/45 {
  background-color: rgb(6 182 212 / 0.45) !important;
}

.tw-bg-cyan-500\/5 {
  background-color: rgb(6 182 212 / 0.05) !important;
}

.tw-bg-cyan-500\/50 {
  background-color: rgb(6 182 212 / 0.5) !important;
}

.tw-bg-cyan-500\/55 {
  background-color: rgb(6 182 212 / 0.55) !important;
}

.tw-bg-cyan-500\/60 {
  background-color: rgb(6 182 212 / 0.6) !important;
}

.tw-bg-cyan-500\/65 {
  background-color: rgb(6 182 212 / 0.65) !important;
}

.tw-bg-cyan-500\/70 {
  background-color: rgb(6 182 212 / 0.7) !important;
}

.tw-bg-cyan-500\/75 {
  background-color: rgb(6 182 212 / 0.75) !important;
}

.tw-bg-cyan-500\/80 {
  background-color: rgb(6 182 212 / 0.8) !important;
}

.tw-bg-cyan-500\/85 {
  background-color: rgb(6 182 212 / 0.85) !important;
}

.tw-bg-cyan-500\/90 {
  background-color: rgb(6 182 212 / 0.9) !important;
}

.tw-bg-cyan-500\/95 {
  background-color: rgb(6 182 212 / 0.95) !important;
}

.tw-bg-cyan-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(8 145 178 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-600\/0 {
  background-color: rgb(8 145 178 / 0) !important;
}

.tw-bg-cyan-600\/10 {
  background-color: rgb(8 145 178 / 0.1) !important;
}

.tw-bg-cyan-600\/100 {
  background-color: rgb(8 145 178 / 1) !important;
}

.tw-bg-cyan-600\/15 {
  background-color: rgb(8 145 178 / 0.15) !important;
}

.tw-bg-cyan-600\/20 {
  background-color: rgb(8 145 178 / 0.2) !important;
}

.tw-bg-cyan-600\/25 {
  background-color: rgb(8 145 178 / 0.25) !important;
}

.tw-bg-cyan-600\/30 {
  background-color: rgb(8 145 178 / 0.3) !important;
}

.tw-bg-cyan-600\/35 {
  background-color: rgb(8 145 178 / 0.35) !important;
}

.tw-bg-cyan-600\/40 {
  background-color: rgb(8 145 178 / 0.4) !important;
}

.tw-bg-cyan-600\/45 {
  background-color: rgb(8 145 178 / 0.45) !important;
}

.tw-bg-cyan-600\/5 {
  background-color: rgb(8 145 178 / 0.05) !important;
}

.tw-bg-cyan-600\/50 {
  background-color: rgb(8 145 178 / 0.5) !important;
}

.tw-bg-cyan-600\/55 {
  background-color: rgb(8 145 178 / 0.55) !important;
}

.tw-bg-cyan-600\/60 {
  background-color: rgb(8 145 178 / 0.6) !important;
}

.tw-bg-cyan-600\/65 {
  background-color: rgb(8 145 178 / 0.65) !important;
}

.tw-bg-cyan-600\/70 {
  background-color: rgb(8 145 178 / 0.7) !important;
}

.tw-bg-cyan-600\/75 {
  background-color: rgb(8 145 178 / 0.75) !important;
}

.tw-bg-cyan-600\/80 {
  background-color: rgb(8 145 178 / 0.8) !important;
}

.tw-bg-cyan-600\/85 {
  background-color: rgb(8 145 178 / 0.85) !important;
}

.tw-bg-cyan-600\/90 {
  background-color: rgb(8 145 178 / 0.9) !important;
}

.tw-bg-cyan-600\/95 {
  background-color: rgb(8 145 178 / 0.95) !important;
}

.tw-bg-cyan-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-700\/0 {
  background-color: rgb(14 116 144 / 0) !important;
}

.tw-bg-cyan-700\/10 {
  background-color: rgb(14 116 144 / 0.1) !important;
}

.tw-bg-cyan-700\/100 {
  background-color: rgb(14 116 144 / 1) !important;
}

.tw-bg-cyan-700\/15 {
  background-color: rgb(14 116 144 / 0.15) !important;
}

.tw-bg-cyan-700\/20 {
  background-color: rgb(14 116 144 / 0.2) !important;
}

.tw-bg-cyan-700\/25 {
  background-color: rgb(14 116 144 / 0.25) !important;
}

.tw-bg-cyan-700\/30 {
  background-color: rgb(14 116 144 / 0.3) !important;
}

.tw-bg-cyan-700\/35 {
  background-color: rgb(14 116 144 / 0.35) !important;
}

.tw-bg-cyan-700\/40 {
  background-color: rgb(14 116 144 / 0.4) !important;
}

.tw-bg-cyan-700\/45 {
  background-color: rgb(14 116 144 / 0.45) !important;
}

.tw-bg-cyan-700\/5 {
  background-color: rgb(14 116 144 / 0.05) !important;
}

.tw-bg-cyan-700\/50 {
  background-color: rgb(14 116 144 / 0.5) !important;
}

.tw-bg-cyan-700\/55 {
  background-color: rgb(14 116 144 / 0.55) !important;
}

.tw-bg-cyan-700\/60 {
  background-color: rgb(14 116 144 / 0.6) !important;
}

.tw-bg-cyan-700\/65 {
  background-color: rgb(14 116 144 / 0.65) !important;
}

.tw-bg-cyan-700\/70 {
  background-color: rgb(14 116 144 / 0.7) !important;
}

.tw-bg-cyan-700\/75 {
  background-color: rgb(14 116 144 / 0.75) !important;
}

.tw-bg-cyan-700\/80 {
  background-color: rgb(14 116 144 / 0.8) !important;
}

.tw-bg-cyan-700\/85 {
  background-color: rgb(14 116 144 / 0.85) !important;
}

.tw-bg-cyan-700\/90 {
  background-color: rgb(14 116 144 / 0.9) !important;
}

.tw-bg-cyan-700\/95 {
  background-color: rgb(14 116 144 / 0.95) !important;
}

.tw-bg-cyan-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 94 117 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-800\/0 {
  background-color: rgb(21 94 117 / 0) !important;
}

.tw-bg-cyan-800\/10 {
  background-color: rgb(21 94 117 / 0.1) !important;
}

.tw-bg-cyan-800\/100 {
  background-color: rgb(21 94 117 / 1) !important;
}

.tw-bg-cyan-800\/15 {
  background-color: rgb(21 94 117 / 0.15) !important;
}

.tw-bg-cyan-800\/20 {
  background-color: rgb(21 94 117 / 0.2) !important;
}

.tw-bg-cyan-800\/25 {
  background-color: rgb(21 94 117 / 0.25) !important;
}

.tw-bg-cyan-800\/30 {
  background-color: rgb(21 94 117 / 0.3) !important;
}

.tw-bg-cyan-800\/35 {
  background-color: rgb(21 94 117 / 0.35) !important;
}

.tw-bg-cyan-800\/40 {
  background-color: rgb(21 94 117 / 0.4) !important;
}

.tw-bg-cyan-800\/45 {
  background-color: rgb(21 94 117 / 0.45) !important;
}

.tw-bg-cyan-800\/5 {
  background-color: rgb(21 94 117 / 0.05) !important;
}

.tw-bg-cyan-800\/50 {
  background-color: rgb(21 94 117 / 0.5) !important;
}

.tw-bg-cyan-800\/55 {
  background-color: rgb(21 94 117 / 0.55) !important;
}

.tw-bg-cyan-800\/60 {
  background-color: rgb(21 94 117 / 0.6) !important;
}

.tw-bg-cyan-800\/65 {
  background-color: rgb(21 94 117 / 0.65) !important;
}

.tw-bg-cyan-800\/70 {
  background-color: rgb(21 94 117 / 0.7) !important;
}

.tw-bg-cyan-800\/75 {
  background-color: rgb(21 94 117 / 0.75) !important;
}

.tw-bg-cyan-800\/80 {
  background-color: rgb(21 94 117 / 0.8) !important;
}

.tw-bg-cyan-800\/85 {
  background-color: rgb(21 94 117 / 0.85) !important;
}

.tw-bg-cyan-800\/90 {
  background-color: rgb(21 94 117 / 0.9) !important;
}

.tw-bg-cyan-800\/95 {
  background-color: rgb(21 94 117 / 0.95) !important;
}

.tw-bg-cyan-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 78 99 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-900\/0 {
  background-color: rgb(22 78 99 / 0) !important;
}

.tw-bg-cyan-900\/10 {
  background-color: rgb(22 78 99 / 0.1) !important;
}

.tw-bg-cyan-900\/100 {
  background-color: rgb(22 78 99 / 1) !important;
}

.tw-bg-cyan-900\/15 {
  background-color: rgb(22 78 99 / 0.15) !important;
}

.tw-bg-cyan-900\/20 {
  background-color: rgb(22 78 99 / 0.2) !important;
}

.tw-bg-cyan-900\/25 {
  background-color: rgb(22 78 99 / 0.25) !important;
}

.tw-bg-cyan-900\/30 {
  background-color: rgb(22 78 99 / 0.3) !important;
}

.tw-bg-cyan-900\/35 {
  background-color: rgb(22 78 99 / 0.35) !important;
}

.tw-bg-cyan-900\/40 {
  background-color: rgb(22 78 99 / 0.4) !important;
}

.tw-bg-cyan-900\/45 {
  background-color: rgb(22 78 99 / 0.45) !important;
}

.tw-bg-cyan-900\/5 {
  background-color: rgb(22 78 99 / 0.05) !important;
}

.tw-bg-cyan-900\/50 {
  background-color: rgb(22 78 99 / 0.5) !important;
}

.tw-bg-cyan-900\/55 {
  background-color: rgb(22 78 99 / 0.55) !important;
}

.tw-bg-cyan-900\/60 {
  background-color: rgb(22 78 99 / 0.6) !important;
}

.tw-bg-cyan-900\/65 {
  background-color: rgb(22 78 99 / 0.65) !important;
}

.tw-bg-cyan-900\/70 {
  background-color: rgb(22 78 99 / 0.7) !important;
}

.tw-bg-cyan-900\/75 {
  background-color: rgb(22 78 99 / 0.75) !important;
}

.tw-bg-cyan-900\/80 {
  background-color: rgb(22 78 99 / 0.8) !important;
}

.tw-bg-cyan-900\/85 {
  background-color: rgb(22 78 99 / 0.85) !important;
}

.tw-bg-cyan-900\/90 {
  background-color: rgb(22 78 99 / 0.9) !important;
}

.tw-bg-cyan-900\/95 {
  background-color: rgb(22 78 99 / 0.95) !important;
}

.tw-bg-cyan-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(8 51 68 / var(--tw-bg-opacity)) !important;
}

.tw-bg-cyan-950\/0 {
  background-color: rgb(8 51 68 / 0) !important;
}

.tw-bg-cyan-950\/10 {
  background-color: rgb(8 51 68 / 0.1) !important;
}

.tw-bg-cyan-950\/100 {
  background-color: rgb(8 51 68 / 1) !important;
}

.tw-bg-cyan-950\/15 {
  background-color: rgb(8 51 68 / 0.15) !important;
}

.tw-bg-cyan-950\/20 {
  background-color: rgb(8 51 68 / 0.2) !important;
}

.tw-bg-cyan-950\/25 {
  background-color: rgb(8 51 68 / 0.25) !important;
}

.tw-bg-cyan-950\/30 {
  background-color: rgb(8 51 68 / 0.3) !important;
}

.tw-bg-cyan-950\/35 {
  background-color: rgb(8 51 68 / 0.35) !important;
}

.tw-bg-cyan-950\/40 {
  background-color: rgb(8 51 68 / 0.4) !important;
}

.tw-bg-cyan-950\/45 {
  background-color: rgb(8 51 68 / 0.45) !important;
}

.tw-bg-cyan-950\/5 {
  background-color: rgb(8 51 68 / 0.05) !important;
}

.tw-bg-cyan-950\/50 {
  background-color: rgb(8 51 68 / 0.5) !important;
}

.tw-bg-cyan-950\/55 {
  background-color: rgb(8 51 68 / 0.55) !important;
}

.tw-bg-cyan-950\/60 {
  background-color: rgb(8 51 68 / 0.6) !important;
}

.tw-bg-cyan-950\/65 {
  background-color: rgb(8 51 68 / 0.65) !important;
}

.tw-bg-cyan-950\/70 {
  background-color: rgb(8 51 68 / 0.7) !important;
}

.tw-bg-cyan-950\/75 {
  background-color: rgb(8 51 68 / 0.75) !important;
}

.tw-bg-cyan-950\/80 {
  background-color: rgb(8 51 68 / 0.8) !important;
}

.tw-bg-cyan-950\/85 {
  background-color: rgb(8 51 68 / 0.85) !important;
}

.tw-bg-cyan-950\/90 {
  background-color: rgb(8 51 68 / 0.9) !important;
}

.tw-bg-cyan-950\/95 {
  background-color: rgb(8 51 68 / 0.95) !important;
}

.tw-bg-emerald-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 250 229 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-100\/0 {
  background-color: rgb(209 250 229 / 0) !important;
}

.tw-bg-emerald-100\/10 {
  background-color: rgb(209 250 229 / 0.1) !important;
}

.tw-bg-emerald-100\/100 {
  background-color: rgb(209 250 229 / 1) !important;
}

.tw-bg-emerald-100\/15 {
  background-color: rgb(209 250 229 / 0.15) !important;
}

.tw-bg-emerald-100\/20 {
  background-color: rgb(209 250 229 / 0.2) !important;
}

.tw-bg-emerald-100\/25 {
  background-color: rgb(209 250 229 / 0.25) !important;
}

.tw-bg-emerald-100\/30 {
  background-color: rgb(209 250 229 / 0.3) !important;
}

.tw-bg-emerald-100\/35 {
  background-color: rgb(209 250 229 / 0.35) !important;
}

.tw-bg-emerald-100\/40 {
  background-color: rgb(209 250 229 / 0.4) !important;
}

.tw-bg-emerald-100\/45 {
  background-color: rgb(209 250 229 / 0.45) !important;
}

.tw-bg-emerald-100\/5 {
  background-color: rgb(209 250 229 / 0.05) !important;
}

.tw-bg-emerald-100\/50 {
  background-color: rgb(209 250 229 / 0.5) !important;
}

.tw-bg-emerald-100\/55 {
  background-color: rgb(209 250 229 / 0.55) !important;
}

.tw-bg-emerald-100\/60 {
  background-color: rgb(209 250 229 / 0.6) !important;
}

.tw-bg-emerald-100\/65 {
  background-color: rgb(209 250 229 / 0.65) !important;
}

.tw-bg-emerald-100\/70 {
  background-color: rgb(209 250 229 / 0.7) !important;
}

.tw-bg-emerald-100\/75 {
  background-color: rgb(209 250 229 / 0.75) !important;
}

.tw-bg-emerald-100\/80 {
  background-color: rgb(209 250 229 / 0.8) !important;
}

.tw-bg-emerald-100\/85 {
  background-color: rgb(209 250 229 / 0.85) !important;
}

.tw-bg-emerald-100\/90 {
  background-color: rgb(209 250 229 / 0.9) !important;
}

.tw-bg-emerald-100\/95 {
  background-color: rgb(209 250 229 / 0.95) !important;
}

.tw-bg-emerald-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(167 243 208 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-200\/0 {
  background-color: rgb(167 243 208 / 0) !important;
}

.tw-bg-emerald-200\/10 {
  background-color: rgb(167 243 208 / 0.1) !important;
}

.tw-bg-emerald-200\/100 {
  background-color: rgb(167 243 208 / 1) !important;
}

.tw-bg-emerald-200\/15 {
  background-color: rgb(167 243 208 / 0.15) !important;
}

.tw-bg-emerald-200\/20 {
  background-color: rgb(167 243 208 / 0.2) !important;
}

.tw-bg-emerald-200\/25 {
  background-color: rgb(167 243 208 / 0.25) !important;
}

.tw-bg-emerald-200\/30 {
  background-color: rgb(167 243 208 / 0.3) !important;
}

.tw-bg-emerald-200\/35 {
  background-color: rgb(167 243 208 / 0.35) !important;
}

.tw-bg-emerald-200\/40 {
  background-color: rgb(167 243 208 / 0.4) !important;
}

.tw-bg-emerald-200\/45 {
  background-color: rgb(167 243 208 / 0.45) !important;
}

.tw-bg-emerald-200\/5 {
  background-color: rgb(167 243 208 / 0.05) !important;
}

.tw-bg-emerald-200\/50 {
  background-color: rgb(167 243 208 / 0.5) !important;
}

.tw-bg-emerald-200\/55 {
  background-color: rgb(167 243 208 / 0.55) !important;
}

.tw-bg-emerald-200\/60 {
  background-color: rgb(167 243 208 / 0.6) !important;
}

.tw-bg-emerald-200\/65 {
  background-color: rgb(167 243 208 / 0.65) !important;
}

.tw-bg-emerald-200\/70 {
  background-color: rgb(167 243 208 / 0.7) !important;
}

.tw-bg-emerald-200\/75 {
  background-color: rgb(167 243 208 / 0.75) !important;
}

.tw-bg-emerald-200\/80 {
  background-color: rgb(167 243 208 / 0.8) !important;
}

.tw-bg-emerald-200\/85 {
  background-color: rgb(167 243 208 / 0.85) !important;
}

.tw-bg-emerald-200\/90 {
  background-color: rgb(167 243 208 / 0.9) !important;
}

.tw-bg-emerald-200\/95 {
  background-color: rgb(167 243 208 / 0.95) !important;
}

.tw-bg-emerald-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(110 231 183 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-300\/0 {
  background-color: rgb(110 231 183 / 0) !important;
}

.tw-bg-emerald-300\/10 {
  background-color: rgb(110 231 183 / 0.1) !important;
}

.tw-bg-emerald-300\/100 {
  background-color: rgb(110 231 183 / 1) !important;
}

.tw-bg-emerald-300\/15 {
  background-color: rgb(110 231 183 / 0.15) !important;
}

.tw-bg-emerald-300\/20 {
  background-color: rgb(110 231 183 / 0.2) !important;
}

.tw-bg-emerald-300\/25 {
  background-color: rgb(110 231 183 / 0.25) !important;
}

.tw-bg-emerald-300\/30 {
  background-color: rgb(110 231 183 / 0.3) !important;
}

.tw-bg-emerald-300\/35 {
  background-color: rgb(110 231 183 / 0.35) !important;
}

.tw-bg-emerald-300\/40 {
  background-color: rgb(110 231 183 / 0.4) !important;
}

.tw-bg-emerald-300\/45 {
  background-color: rgb(110 231 183 / 0.45) !important;
}

.tw-bg-emerald-300\/5 {
  background-color: rgb(110 231 183 / 0.05) !important;
}

.tw-bg-emerald-300\/50 {
  background-color: rgb(110 231 183 / 0.5) !important;
}

.tw-bg-emerald-300\/55 {
  background-color: rgb(110 231 183 / 0.55) !important;
}

.tw-bg-emerald-300\/60 {
  background-color: rgb(110 231 183 / 0.6) !important;
}

.tw-bg-emerald-300\/65 {
  background-color: rgb(110 231 183 / 0.65) !important;
}

.tw-bg-emerald-300\/70 {
  background-color: rgb(110 231 183 / 0.7) !important;
}

.tw-bg-emerald-300\/75 {
  background-color: rgb(110 231 183 / 0.75) !important;
}

.tw-bg-emerald-300\/80 {
  background-color: rgb(110 231 183 / 0.8) !important;
}

.tw-bg-emerald-300\/85 {
  background-color: rgb(110 231 183 / 0.85) !important;
}

.tw-bg-emerald-300\/90 {
  background-color: rgb(110 231 183 / 0.9) !important;
}

.tw-bg-emerald-300\/95 {
  background-color: rgb(110 231 183 / 0.95) !important;
}

.tw-bg-emerald-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(52 211 153 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-400\/0 {
  background-color: rgb(52 211 153 / 0) !important;
}

.tw-bg-emerald-400\/10 {
  background-color: rgb(52 211 153 / 0.1) !important;
}

.tw-bg-emerald-400\/100 {
  background-color: rgb(52 211 153 / 1) !important;
}

.tw-bg-emerald-400\/15 {
  background-color: rgb(52 211 153 / 0.15) !important;
}

.tw-bg-emerald-400\/20 {
  background-color: rgb(52 211 153 / 0.2) !important;
}

.tw-bg-emerald-400\/25 {
  background-color: rgb(52 211 153 / 0.25) !important;
}

.tw-bg-emerald-400\/30 {
  background-color: rgb(52 211 153 / 0.3) !important;
}

.tw-bg-emerald-400\/35 {
  background-color: rgb(52 211 153 / 0.35) !important;
}

.tw-bg-emerald-400\/40 {
  background-color: rgb(52 211 153 / 0.4) !important;
}

.tw-bg-emerald-400\/45 {
  background-color: rgb(52 211 153 / 0.45) !important;
}

.tw-bg-emerald-400\/5 {
  background-color: rgb(52 211 153 / 0.05) !important;
}

.tw-bg-emerald-400\/50 {
  background-color: rgb(52 211 153 / 0.5) !important;
}

.tw-bg-emerald-400\/55 {
  background-color: rgb(52 211 153 / 0.55) !important;
}

.tw-bg-emerald-400\/60 {
  background-color: rgb(52 211 153 / 0.6) !important;
}

.tw-bg-emerald-400\/65 {
  background-color: rgb(52 211 153 / 0.65) !important;
}

.tw-bg-emerald-400\/70 {
  background-color: rgb(52 211 153 / 0.7) !important;
}

.tw-bg-emerald-400\/75 {
  background-color: rgb(52 211 153 / 0.75) !important;
}

.tw-bg-emerald-400\/80 {
  background-color: rgb(52 211 153 / 0.8) !important;
}

.tw-bg-emerald-400\/85 {
  background-color: rgb(52 211 153 / 0.85) !important;
}

.tw-bg-emerald-400\/90 {
  background-color: rgb(52 211 153 / 0.9) !important;
}

.tw-bg-emerald-400\/95 {
  background-color: rgb(52 211 153 / 0.95) !important;
}

.tw-bg-emerald-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(236 253 245 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-50\/0 {
  background-color: rgb(236 253 245 / 0) !important;
}

.tw-bg-emerald-50\/10 {
  background-color: rgb(236 253 245 / 0.1) !important;
}

.tw-bg-emerald-50\/100 {
  background-color: rgb(236 253 245 / 1) !important;
}

.tw-bg-emerald-50\/15 {
  background-color: rgb(236 253 245 / 0.15) !important;
}

.tw-bg-emerald-50\/20 {
  background-color: rgb(236 253 245 / 0.2) !important;
}

.tw-bg-emerald-50\/25 {
  background-color: rgb(236 253 245 / 0.25) !important;
}

.tw-bg-emerald-50\/30 {
  background-color: rgb(236 253 245 / 0.3) !important;
}

.tw-bg-emerald-50\/35 {
  background-color: rgb(236 253 245 / 0.35) !important;
}

.tw-bg-emerald-50\/40 {
  background-color: rgb(236 253 245 / 0.4) !important;
}

.tw-bg-emerald-50\/45 {
  background-color: rgb(236 253 245 / 0.45) !important;
}

.tw-bg-emerald-50\/5 {
  background-color: rgb(236 253 245 / 0.05) !important;
}

.tw-bg-emerald-50\/50 {
  background-color: rgb(236 253 245 / 0.5) !important;
}

.tw-bg-emerald-50\/55 {
  background-color: rgb(236 253 245 / 0.55) !important;
}

.tw-bg-emerald-50\/60 {
  background-color: rgb(236 253 245 / 0.6) !important;
}

.tw-bg-emerald-50\/65 {
  background-color: rgb(236 253 245 / 0.65) !important;
}

.tw-bg-emerald-50\/70 {
  background-color: rgb(236 253 245 / 0.7) !important;
}

.tw-bg-emerald-50\/75 {
  background-color: rgb(236 253 245 / 0.75) !important;
}

.tw-bg-emerald-50\/80 {
  background-color: rgb(236 253 245 / 0.8) !important;
}

.tw-bg-emerald-50\/85 {
  background-color: rgb(236 253 245 / 0.85) !important;
}

.tw-bg-emerald-50\/90 {
  background-color: rgb(236 253 245 / 0.9) !important;
}

.tw-bg-emerald-50\/95 {
  background-color: rgb(236 253 245 / 0.95) !important;
}

.tw-bg-emerald-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(16 185 129 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-500\/0 {
  background-color: rgb(16 185 129 / 0) !important;
}

.tw-bg-emerald-500\/10 {
  background-color: rgb(16 185 129 / 0.1) !important;
}

.tw-bg-emerald-500\/100 {
  background-color: rgb(16 185 129 / 1) !important;
}

.tw-bg-emerald-500\/15 {
  background-color: rgb(16 185 129 / 0.15) !important;
}

.tw-bg-emerald-500\/20 {
  background-color: rgb(16 185 129 / 0.2) !important;
}

.tw-bg-emerald-500\/25 {
  background-color: rgb(16 185 129 / 0.25) !important;
}

.tw-bg-emerald-500\/30 {
  background-color: rgb(16 185 129 / 0.3) !important;
}

.tw-bg-emerald-500\/35 {
  background-color: rgb(16 185 129 / 0.35) !important;
}

.tw-bg-emerald-500\/40 {
  background-color: rgb(16 185 129 / 0.4) !important;
}

.tw-bg-emerald-500\/45 {
  background-color: rgb(16 185 129 / 0.45) !important;
}

.tw-bg-emerald-500\/5 {
  background-color: rgb(16 185 129 / 0.05) !important;
}

.tw-bg-emerald-500\/50 {
  background-color: rgb(16 185 129 / 0.5) !important;
}

.tw-bg-emerald-500\/55 {
  background-color: rgb(16 185 129 / 0.55) !important;
}

.tw-bg-emerald-500\/60 {
  background-color: rgb(16 185 129 / 0.6) !important;
}

.tw-bg-emerald-500\/65 {
  background-color: rgb(16 185 129 / 0.65) !important;
}

.tw-bg-emerald-500\/70 {
  background-color: rgb(16 185 129 / 0.7) !important;
}

.tw-bg-emerald-500\/75 {
  background-color: rgb(16 185 129 / 0.75) !important;
}

.tw-bg-emerald-500\/80 {
  background-color: rgb(16 185 129 / 0.8) !important;
}

.tw-bg-emerald-500\/85 {
  background-color: rgb(16 185 129 / 0.85) !important;
}

.tw-bg-emerald-500\/90 {
  background-color: rgb(16 185 129 / 0.9) !important;
}

.tw-bg-emerald-500\/95 {
  background-color: rgb(16 185 129 / 0.95) !important;
}

.tw-bg-emerald-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 150 105 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-600\/0 {
  background-color: rgb(5 150 105 / 0) !important;
}

.tw-bg-emerald-600\/10 {
  background-color: rgb(5 150 105 / 0.1) !important;
}

.tw-bg-emerald-600\/100 {
  background-color: rgb(5 150 105 / 1) !important;
}

.tw-bg-emerald-600\/15 {
  background-color: rgb(5 150 105 / 0.15) !important;
}

.tw-bg-emerald-600\/20 {
  background-color: rgb(5 150 105 / 0.2) !important;
}

.tw-bg-emerald-600\/25 {
  background-color: rgb(5 150 105 / 0.25) !important;
}

.tw-bg-emerald-600\/30 {
  background-color: rgb(5 150 105 / 0.3) !important;
}

.tw-bg-emerald-600\/35 {
  background-color: rgb(5 150 105 / 0.35) !important;
}

.tw-bg-emerald-600\/40 {
  background-color: rgb(5 150 105 / 0.4) !important;
}

.tw-bg-emerald-600\/45 {
  background-color: rgb(5 150 105 / 0.45) !important;
}

.tw-bg-emerald-600\/5 {
  background-color: rgb(5 150 105 / 0.05) !important;
}

.tw-bg-emerald-600\/50 {
  background-color: rgb(5 150 105 / 0.5) !important;
}

.tw-bg-emerald-600\/55 {
  background-color: rgb(5 150 105 / 0.55) !important;
}

.tw-bg-emerald-600\/60 {
  background-color: rgb(5 150 105 / 0.6) !important;
}

.tw-bg-emerald-600\/65 {
  background-color: rgb(5 150 105 / 0.65) !important;
}

.tw-bg-emerald-600\/70 {
  background-color: rgb(5 150 105 / 0.7) !important;
}

.tw-bg-emerald-600\/75 {
  background-color: rgb(5 150 105 / 0.75) !important;
}

.tw-bg-emerald-600\/80 {
  background-color: rgb(5 150 105 / 0.8) !important;
}

.tw-bg-emerald-600\/85 {
  background-color: rgb(5 150 105 / 0.85) !important;
}

.tw-bg-emerald-600\/90 {
  background-color: rgb(5 150 105 / 0.9) !important;
}

.tw-bg-emerald-600\/95 {
  background-color: rgb(5 150 105 / 0.95) !important;
}

.tw-bg-emerald-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(4 120 87 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-700\/0 {
  background-color: rgb(4 120 87 / 0) !important;
}

.tw-bg-emerald-700\/10 {
  background-color: rgb(4 120 87 / 0.1) !important;
}

.tw-bg-emerald-700\/100 {
  background-color: rgb(4 120 87 / 1) !important;
}

.tw-bg-emerald-700\/15 {
  background-color: rgb(4 120 87 / 0.15) !important;
}

.tw-bg-emerald-700\/20 {
  background-color: rgb(4 120 87 / 0.2) !important;
}

.tw-bg-emerald-700\/25 {
  background-color: rgb(4 120 87 / 0.25) !important;
}

.tw-bg-emerald-700\/30 {
  background-color: rgb(4 120 87 / 0.3) !important;
}

.tw-bg-emerald-700\/35 {
  background-color: rgb(4 120 87 / 0.35) !important;
}

.tw-bg-emerald-700\/40 {
  background-color: rgb(4 120 87 / 0.4) !important;
}

.tw-bg-emerald-700\/45 {
  background-color: rgb(4 120 87 / 0.45) !important;
}

.tw-bg-emerald-700\/5 {
  background-color: rgb(4 120 87 / 0.05) !important;
}

.tw-bg-emerald-700\/50 {
  background-color: rgb(4 120 87 / 0.5) !important;
}

.tw-bg-emerald-700\/55 {
  background-color: rgb(4 120 87 / 0.55) !important;
}

.tw-bg-emerald-700\/60 {
  background-color: rgb(4 120 87 / 0.6) !important;
}

.tw-bg-emerald-700\/65 {
  background-color: rgb(4 120 87 / 0.65) !important;
}

.tw-bg-emerald-700\/70 {
  background-color: rgb(4 120 87 / 0.7) !important;
}

.tw-bg-emerald-700\/75 {
  background-color: rgb(4 120 87 / 0.75) !important;
}

.tw-bg-emerald-700\/80 {
  background-color: rgb(4 120 87 / 0.8) !important;
}

.tw-bg-emerald-700\/85 {
  background-color: rgb(4 120 87 / 0.85) !important;
}

.tw-bg-emerald-700\/90 {
  background-color: rgb(4 120 87 / 0.9) !important;
}

.tw-bg-emerald-700\/95 {
  background-color: rgb(4 120 87 / 0.95) !important;
}

.tw-bg-emerald-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(6 95 70 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-800\/0 {
  background-color: rgb(6 95 70 / 0) !important;
}

.tw-bg-emerald-800\/10 {
  background-color: rgb(6 95 70 / 0.1) !important;
}

.tw-bg-emerald-800\/100 {
  background-color: rgb(6 95 70 / 1) !important;
}

.tw-bg-emerald-800\/15 {
  background-color: rgb(6 95 70 / 0.15) !important;
}

.tw-bg-emerald-800\/20 {
  background-color: rgb(6 95 70 / 0.2) !important;
}

.tw-bg-emerald-800\/25 {
  background-color: rgb(6 95 70 / 0.25) !important;
}

.tw-bg-emerald-800\/30 {
  background-color: rgb(6 95 70 / 0.3) !important;
}

.tw-bg-emerald-800\/35 {
  background-color: rgb(6 95 70 / 0.35) !important;
}

.tw-bg-emerald-800\/40 {
  background-color: rgb(6 95 70 / 0.4) !important;
}

.tw-bg-emerald-800\/45 {
  background-color: rgb(6 95 70 / 0.45) !important;
}

.tw-bg-emerald-800\/5 {
  background-color: rgb(6 95 70 / 0.05) !important;
}

.tw-bg-emerald-800\/50 {
  background-color: rgb(6 95 70 / 0.5) !important;
}

.tw-bg-emerald-800\/55 {
  background-color: rgb(6 95 70 / 0.55) !important;
}

.tw-bg-emerald-800\/60 {
  background-color: rgb(6 95 70 / 0.6) !important;
}

.tw-bg-emerald-800\/65 {
  background-color: rgb(6 95 70 / 0.65) !important;
}

.tw-bg-emerald-800\/70 {
  background-color: rgb(6 95 70 / 0.7) !important;
}

.tw-bg-emerald-800\/75 {
  background-color: rgb(6 95 70 / 0.75) !important;
}

.tw-bg-emerald-800\/80 {
  background-color: rgb(6 95 70 / 0.8) !important;
}

.tw-bg-emerald-800\/85 {
  background-color: rgb(6 95 70 / 0.85) !important;
}

.tw-bg-emerald-800\/90 {
  background-color: rgb(6 95 70 / 0.9) !important;
}

.tw-bg-emerald-800\/95 {
  background-color: rgb(6 95 70 / 0.95) !important;
}

.tw-bg-emerald-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(6 78 59 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-900\/0 {
  background-color: rgb(6 78 59 / 0) !important;
}

.tw-bg-emerald-900\/10 {
  background-color: rgb(6 78 59 / 0.1) !important;
}

.tw-bg-emerald-900\/100 {
  background-color: rgb(6 78 59 / 1) !important;
}

.tw-bg-emerald-900\/15 {
  background-color: rgb(6 78 59 / 0.15) !important;
}

.tw-bg-emerald-900\/20 {
  background-color: rgb(6 78 59 / 0.2) !important;
}

.tw-bg-emerald-900\/25 {
  background-color: rgb(6 78 59 / 0.25) !important;
}

.tw-bg-emerald-900\/30 {
  background-color: rgb(6 78 59 / 0.3) !important;
}

.tw-bg-emerald-900\/35 {
  background-color: rgb(6 78 59 / 0.35) !important;
}

.tw-bg-emerald-900\/40 {
  background-color: rgb(6 78 59 / 0.4) !important;
}

.tw-bg-emerald-900\/45 {
  background-color: rgb(6 78 59 / 0.45) !important;
}

.tw-bg-emerald-900\/5 {
  background-color: rgb(6 78 59 / 0.05) !important;
}

.tw-bg-emerald-900\/50 {
  background-color: rgb(6 78 59 / 0.5) !important;
}

.tw-bg-emerald-900\/55 {
  background-color: rgb(6 78 59 / 0.55) !important;
}

.tw-bg-emerald-900\/60 {
  background-color: rgb(6 78 59 / 0.6) !important;
}

.tw-bg-emerald-900\/65 {
  background-color: rgb(6 78 59 / 0.65) !important;
}

.tw-bg-emerald-900\/70 {
  background-color: rgb(6 78 59 / 0.7) !important;
}

.tw-bg-emerald-900\/75 {
  background-color: rgb(6 78 59 / 0.75) !important;
}

.tw-bg-emerald-900\/80 {
  background-color: rgb(6 78 59 / 0.8) !important;
}

.tw-bg-emerald-900\/85 {
  background-color: rgb(6 78 59 / 0.85) !important;
}

.tw-bg-emerald-900\/90 {
  background-color: rgb(6 78 59 / 0.9) !important;
}

.tw-bg-emerald-900\/95 {
  background-color: rgb(6 78 59 / 0.95) !important;
}

.tw-bg-emerald-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 44 34 / var(--tw-bg-opacity)) !important;
}

.tw-bg-emerald-950\/0 {
  background-color: rgb(2 44 34 / 0) !important;
}

.tw-bg-emerald-950\/10 {
  background-color: rgb(2 44 34 / 0.1) !important;
}

.tw-bg-emerald-950\/100 {
  background-color: rgb(2 44 34 / 1) !important;
}

.tw-bg-emerald-950\/15 {
  background-color: rgb(2 44 34 / 0.15) !important;
}

.tw-bg-emerald-950\/20 {
  background-color: rgb(2 44 34 / 0.2) !important;
}

.tw-bg-emerald-950\/25 {
  background-color: rgb(2 44 34 / 0.25) !important;
}

.tw-bg-emerald-950\/30 {
  background-color: rgb(2 44 34 / 0.3) !important;
}

.tw-bg-emerald-950\/35 {
  background-color: rgb(2 44 34 / 0.35) !important;
}

.tw-bg-emerald-950\/40 {
  background-color: rgb(2 44 34 / 0.4) !important;
}

.tw-bg-emerald-950\/45 {
  background-color: rgb(2 44 34 / 0.45) !important;
}

.tw-bg-emerald-950\/5 {
  background-color: rgb(2 44 34 / 0.05) !important;
}

.tw-bg-emerald-950\/50 {
  background-color: rgb(2 44 34 / 0.5) !important;
}

.tw-bg-emerald-950\/55 {
  background-color: rgb(2 44 34 / 0.55) !important;
}

.tw-bg-emerald-950\/60 {
  background-color: rgb(2 44 34 / 0.6) !important;
}

.tw-bg-emerald-950\/65 {
  background-color: rgb(2 44 34 / 0.65) !important;
}

.tw-bg-emerald-950\/70 {
  background-color: rgb(2 44 34 / 0.7) !important;
}

.tw-bg-emerald-950\/75 {
  background-color: rgb(2 44 34 / 0.75) !important;
}

.tw-bg-emerald-950\/80 {
  background-color: rgb(2 44 34 / 0.8) !important;
}

.tw-bg-emerald-950\/85 {
  background-color: rgb(2 44 34 / 0.85) !important;
}

.tw-bg-emerald-950\/90 {
  background-color: rgb(2 44 34 / 0.9) !important;
}

.tw-bg-emerald-950\/95 {
  background-color: rgb(2 44 34 / 0.95) !important;
}

.tw-bg-fuchsia-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 232 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-100\/0 {
  background-color: rgb(250 232 255 / 0) !important;
}

.tw-bg-fuchsia-100\/10 {
  background-color: rgb(250 232 255 / 0.1) !important;
}

.tw-bg-fuchsia-100\/100 {
  background-color: rgb(250 232 255 / 1) !important;
}

.tw-bg-fuchsia-100\/15 {
  background-color: rgb(250 232 255 / 0.15) !important;
}

.tw-bg-fuchsia-100\/20 {
  background-color: rgb(250 232 255 / 0.2) !important;
}

.tw-bg-fuchsia-100\/25 {
  background-color: rgb(250 232 255 / 0.25) !important;
}

.tw-bg-fuchsia-100\/30 {
  background-color: rgb(250 232 255 / 0.3) !important;
}

.tw-bg-fuchsia-100\/35 {
  background-color: rgb(250 232 255 / 0.35) !important;
}

.tw-bg-fuchsia-100\/40 {
  background-color: rgb(250 232 255 / 0.4) !important;
}

.tw-bg-fuchsia-100\/45 {
  background-color: rgb(250 232 255 / 0.45) !important;
}

.tw-bg-fuchsia-100\/5 {
  background-color: rgb(250 232 255 / 0.05) !important;
}

.tw-bg-fuchsia-100\/50 {
  background-color: rgb(250 232 255 / 0.5) !important;
}

.tw-bg-fuchsia-100\/55 {
  background-color: rgb(250 232 255 / 0.55) !important;
}

.tw-bg-fuchsia-100\/60 {
  background-color: rgb(250 232 255 / 0.6) !important;
}

.tw-bg-fuchsia-100\/65 {
  background-color: rgb(250 232 255 / 0.65) !important;
}

.tw-bg-fuchsia-100\/70 {
  background-color: rgb(250 232 255 / 0.7) !important;
}

.tw-bg-fuchsia-100\/75 {
  background-color: rgb(250 232 255 / 0.75) !important;
}

.tw-bg-fuchsia-100\/80 {
  background-color: rgb(250 232 255 / 0.8) !important;
}

.tw-bg-fuchsia-100\/85 {
  background-color: rgb(250 232 255 / 0.85) !important;
}

.tw-bg-fuchsia-100\/90 {
  background-color: rgb(250 232 255 / 0.9) !important;
}

.tw-bg-fuchsia-100\/95 {
  background-color: rgb(250 232 255 / 0.95) !important;
}

.tw-bg-fuchsia-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-200\/0 {
  background-color: rgb(245 208 254 / 0) !important;
}

.tw-bg-fuchsia-200\/10 {
  background-color: rgb(245 208 254 / 0.1) !important;
}

.tw-bg-fuchsia-200\/100 {
  background-color: rgb(245 208 254 / 1) !important;
}

.tw-bg-fuchsia-200\/15 {
  background-color: rgb(245 208 254 / 0.15) !important;
}

.tw-bg-fuchsia-200\/20 {
  background-color: rgb(245 208 254 / 0.2) !important;
}

.tw-bg-fuchsia-200\/25 {
  background-color: rgb(245 208 254 / 0.25) !important;
}

.tw-bg-fuchsia-200\/30 {
  background-color: rgb(245 208 254 / 0.3) !important;
}

.tw-bg-fuchsia-200\/35 {
  background-color: rgb(245 208 254 / 0.35) !important;
}

.tw-bg-fuchsia-200\/40 {
  background-color: rgb(245 208 254 / 0.4) !important;
}

.tw-bg-fuchsia-200\/45 {
  background-color: rgb(245 208 254 / 0.45) !important;
}

.tw-bg-fuchsia-200\/5 {
  background-color: rgb(245 208 254 / 0.05) !important;
}

.tw-bg-fuchsia-200\/50 {
  background-color: rgb(245 208 254 / 0.5) !important;
}

.tw-bg-fuchsia-200\/55 {
  background-color: rgb(245 208 254 / 0.55) !important;
}

.tw-bg-fuchsia-200\/60 {
  background-color: rgb(245 208 254 / 0.6) !important;
}

.tw-bg-fuchsia-200\/65 {
  background-color: rgb(245 208 254 / 0.65) !important;
}

.tw-bg-fuchsia-200\/70 {
  background-color: rgb(245 208 254 / 0.7) !important;
}

.tw-bg-fuchsia-200\/75 {
  background-color: rgb(245 208 254 / 0.75) !important;
}

.tw-bg-fuchsia-200\/80 {
  background-color: rgb(245 208 254 / 0.8) !important;
}

.tw-bg-fuchsia-200\/85 {
  background-color: rgb(245 208 254 / 0.85) !important;
}

.tw-bg-fuchsia-200\/90 {
  background-color: rgb(245 208 254 / 0.9) !important;
}

.tw-bg-fuchsia-200\/95 {
  background-color: rgb(245 208 254 / 0.95) !important;
}

.tw-bg-fuchsia-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 171 252 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-300\/0 {
  background-color: rgb(240 171 252 / 0) !important;
}

.tw-bg-fuchsia-300\/10 {
  background-color: rgb(240 171 252 / 0.1) !important;
}

.tw-bg-fuchsia-300\/100 {
  background-color: rgb(240 171 252 / 1) !important;
}

.tw-bg-fuchsia-300\/15 {
  background-color: rgb(240 171 252 / 0.15) !important;
}

.tw-bg-fuchsia-300\/20 {
  background-color: rgb(240 171 252 / 0.2) !important;
}

.tw-bg-fuchsia-300\/25 {
  background-color: rgb(240 171 252 / 0.25) !important;
}

.tw-bg-fuchsia-300\/30 {
  background-color: rgb(240 171 252 / 0.3) !important;
}

.tw-bg-fuchsia-300\/35 {
  background-color: rgb(240 171 252 / 0.35) !important;
}

.tw-bg-fuchsia-300\/40 {
  background-color: rgb(240 171 252 / 0.4) !important;
}

.tw-bg-fuchsia-300\/45 {
  background-color: rgb(240 171 252 / 0.45) !important;
}

.tw-bg-fuchsia-300\/5 {
  background-color: rgb(240 171 252 / 0.05) !important;
}

.tw-bg-fuchsia-300\/50 {
  background-color: rgb(240 171 252 / 0.5) !important;
}

.tw-bg-fuchsia-300\/55 {
  background-color: rgb(240 171 252 / 0.55) !important;
}

.tw-bg-fuchsia-300\/60 {
  background-color: rgb(240 171 252 / 0.6) !important;
}

.tw-bg-fuchsia-300\/65 {
  background-color: rgb(240 171 252 / 0.65) !important;
}

.tw-bg-fuchsia-300\/70 {
  background-color: rgb(240 171 252 / 0.7) !important;
}

.tw-bg-fuchsia-300\/75 {
  background-color: rgb(240 171 252 / 0.75) !important;
}

.tw-bg-fuchsia-300\/80 {
  background-color: rgb(240 171 252 / 0.8) !important;
}

.tw-bg-fuchsia-300\/85 {
  background-color: rgb(240 171 252 / 0.85) !important;
}

.tw-bg-fuchsia-300\/90 {
  background-color: rgb(240 171 252 / 0.9) !important;
}

.tw-bg-fuchsia-300\/95 {
  background-color: rgb(240 171 252 / 0.95) !important;
}

.tw-bg-fuchsia-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(232 121 249 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-400\/0 {
  background-color: rgb(232 121 249 / 0) !important;
}

.tw-bg-fuchsia-400\/10 {
  background-color: rgb(232 121 249 / 0.1) !important;
}

.tw-bg-fuchsia-400\/100 {
  background-color: rgb(232 121 249 / 1) !important;
}

.tw-bg-fuchsia-400\/15 {
  background-color: rgb(232 121 249 / 0.15) !important;
}

.tw-bg-fuchsia-400\/20 {
  background-color: rgb(232 121 249 / 0.2) !important;
}

.tw-bg-fuchsia-400\/25 {
  background-color: rgb(232 121 249 / 0.25) !important;
}

.tw-bg-fuchsia-400\/30 {
  background-color: rgb(232 121 249 / 0.3) !important;
}

.tw-bg-fuchsia-400\/35 {
  background-color: rgb(232 121 249 / 0.35) !important;
}

.tw-bg-fuchsia-400\/40 {
  background-color: rgb(232 121 249 / 0.4) !important;
}

.tw-bg-fuchsia-400\/45 {
  background-color: rgb(232 121 249 / 0.45) !important;
}

.tw-bg-fuchsia-400\/5 {
  background-color: rgb(232 121 249 / 0.05) !important;
}

.tw-bg-fuchsia-400\/50 {
  background-color: rgb(232 121 249 / 0.5) !important;
}

.tw-bg-fuchsia-400\/55 {
  background-color: rgb(232 121 249 / 0.55) !important;
}

.tw-bg-fuchsia-400\/60 {
  background-color: rgb(232 121 249 / 0.6) !important;
}

.tw-bg-fuchsia-400\/65 {
  background-color: rgb(232 121 249 / 0.65) !important;
}

.tw-bg-fuchsia-400\/70 {
  background-color: rgb(232 121 249 / 0.7) !important;
}

.tw-bg-fuchsia-400\/75 {
  background-color: rgb(232 121 249 / 0.75) !important;
}

.tw-bg-fuchsia-400\/80 {
  background-color: rgb(232 121 249 / 0.8) !important;
}

.tw-bg-fuchsia-400\/85 {
  background-color: rgb(232 121 249 / 0.85) !important;
}

.tw-bg-fuchsia-400\/90 {
  background-color: rgb(232 121 249 / 0.9) !important;
}

.tw-bg-fuchsia-400\/95 {
  background-color: rgb(232 121 249 / 0.95) !important;
}

.tw-bg-fuchsia-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 244 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-50\/0 {
  background-color: rgb(253 244 255 / 0) !important;
}

.tw-bg-fuchsia-50\/10 {
  background-color: rgb(253 244 255 / 0.1) !important;
}

.tw-bg-fuchsia-50\/100 {
  background-color: rgb(253 244 255 / 1) !important;
}

.tw-bg-fuchsia-50\/15 {
  background-color: rgb(253 244 255 / 0.15) !important;
}

.tw-bg-fuchsia-50\/20 {
  background-color: rgb(253 244 255 / 0.2) !important;
}

.tw-bg-fuchsia-50\/25 {
  background-color: rgb(253 244 255 / 0.25) !important;
}

.tw-bg-fuchsia-50\/30 {
  background-color: rgb(253 244 255 / 0.3) !important;
}

.tw-bg-fuchsia-50\/35 {
  background-color: rgb(253 244 255 / 0.35) !important;
}

.tw-bg-fuchsia-50\/40 {
  background-color: rgb(253 244 255 / 0.4) !important;
}

.tw-bg-fuchsia-50\/45 {
  background-color: rgb(253 244 255 / 0.45) !important;
}

.tw-bg-fuchsia-50\/5 {
  background-color: rgb(253 244 255 / 0.05) !important;
}

.tw-bg-fuchsia-50\/50 {
  background-color: rgb(253 244 255 / 0.5) !important;
}

.tw-bg-fuchsia-50\/55 {
  background-color: rgb(253 244 255 / 0.55) !important;
}

.tw-bg-fuchsia-50\/60 {
  background-color: rgb(253 244 255 / 0.6) !important;
}

.tw-bg-fuchsia-50\/65 {
  background-color: rgb(253 244 255 / 0.65) !important;
}

.tw-bg-fuchsia-50\/70 {
  background-color: rgb(253 244 255 / 0.7) !important;
}

.tw-bg-fuchsia-50\/75 {
  background-color: rgb(253 244 255 / 0.75) !important;
}

.tw-bg-fuchsia-50\/80 {
  background-color: rgb(253 244 255 / 0.8) !important;
}

.tw-bg-fuchsia-50\/85 {
  background-color: rgb(253 244 255 / 0.85) !important;
}

.tw-bg-fuchsia-50\/90 {
  background-color: rgb(253 244 255 / 0.9) !important;
}

.tw-bg-fuchsia-50\/95 {
  background-color: rgb(253 244 255 / 0.95) !important;
}

.tw-bg-fuchsia-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 70 239 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-500\/0 {
  background-color: rgb(217 70 239 / 0) !important;
}

.tw-bg-fuchsia-500\/10 {
  background-color: rgb(217 70 239 / 0.1) !important;
}

.tw-bg-fuchsia-500\/100 {
  background-color: rgb(217 70 239 / 1) !important;
}

.tw-bg-fuchsia-500\/15 {
  background-color: rgb(217 70 239 / 0.15) !important;
}

.tw-bg-fuchsia-500\/20 {
  background-color: rgb(217 70 239 / 0.2) !important;
}

.tw-bg-fuchsia-500\/25 {
  background-color: rgb(217 70 239 / 0.25) !important;
}

.tw-bg-fuchsia-500\/30 {
  background-color: rgb(217 70 239 / 0.3) !important;
}

.tw-bg-fuchsia-500\/35 {
  background-color: rgb(217 70 239 / 0.35) !important;
}

.tw-bg-fuchsia-500\/40 {
  background-color: rgb(217 70 239 / 0.4) !important;
}

.tw-bg-fuchsia-500\/45 {
  background-color: rgb(217 70 239 / 0.45) !important;
}

.tw-bg-fuchsia-500\/5 {
  background-color: rgb(217 70 239 / 0.05) !important;
}

.tw-bg-fuchsia-500\/50 {
  background-color: rgb(217 70 239 / 0.5) !important;
}

.tw-bg-fuchsia-500\/55 {
  background-color: rgb(217 70 239 / 0.55) !important;
}

.tw-bg-fuchsia-500\/60 {
  background-color: rgb(217 70 239 / 0.6) !important;
}

.tw-bg-fuchsia-500\/65 {
  background-color: rgb(217 70 239 / 0.65) !important;
}

.tw-bg-fuchsia-500\/70 {
  background-color: rgb(217 70 239 / 0.7) !important;
}

.tw-bg-fuchsia-500\/75 {
  background-color: rgb(217 70 239 / 0.75) !important;
}

.tw-bg-fuchsia-500\/80 {
  background-color: rgb(217 70 239 / 0.8) !important;
}

.tw-bg-fuchsia-500\/85 {
  background-color: rgb(217 70 239 / 0.85) !important;
}

.tw-bg-fuchsia-500\/90 {
  background-color: rgb(217 70 239 / 0.9) !important;
}

.tw-bg-fuchsia-500\/95 {
  background-color: rgb(217 70 239 / 0.95) !important;
}

.tw-bg-fuchsia-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(192 38 211 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-600\/0 {
  background-color: rgb(192 38 211 / 0) !important;
}

.tw-bg-fuchsia-600\/10 {
  background-color: rgb(192 38 211 / 0.1) !important;
}

.tw-bg-fuchsia-600\/100 {
  background-color: rgb(192 38 211 / 1) !important;
}

.tw-bg-fuchsia-600\/15 {
  background-color: rgb(192 38 211 / 0.15) !important;
}

.tw-bg-fuchsia-600\/20 {
  background-color: rgb(192 38 211 / 0.2) !important;
}

.tw-bg-fuchsia-600\/25 {
  background-color: rgb(192 38 211 / 0.25) !important;
}

.tw-bg-fuchsia-600\/30 {
  background-color: rgb(192 38 211 / 0.3) !important;
}

.tw-bg-fuchsia-600\/35 {
  background-color: rgb(192 38 211 / 0.35) !important;
}

.tw-bg-fuchsia-600\/40 {
  background-color: rgb(192 38 211 / 0.4) !important;
}

.tw-bg-fuchsia-600\/45 {
  background-color: rgb(192 38 211 / 0.45) !important;
}

.tw-bg-fuchsia-600\/5 {
  background-color: rgb(192 38 211 / 0.05) !important;
}

.tw-bg-fuchsia-600\/50 {
  background-color: rgb(192 38 211 / 0.5) !important;
}

.tw-bg-fuchsia-600\/55 {
  background-color: rgb(192 38 211 / 0.55) !important;
}

.tw-bg-fuchsia-600\/60 {
  background-color: rgb(192 38 211 / 0.6) !important;
}

.tw-bg-fuchsia-600\/65 {
  background-color: rgb(192 38 211 / 0.65) !important;
}

.tw-bg-fuchsia-600\/70 {
  background-color: rgb(192 38 211 / 0.7) !important;
}

.tw-bg-fuchsia-600\/75 {
  background-color: rgb(192 38 211 / 0.75) !important;
}

.tw-bg-fuchsia-600\/80 {
  background-color: rgb(192 38 211 / 0.8) !important;
}

.tw-bg-fuchsia-600\/85 {
  background-color: rgb(192 38 211 / 0.85) !important;
}

.tw-bg-fuchsia-600\/90 {
  background-color: rgb(192 38 211 / 0.9) !important;
}

.tw-bg-fuchsia-600\/95 {
  background-color: rgb(192 38 211 / 0.95) !important;
}

.tw-bg-fuchsia-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-700\/0 {
  background-color: rgb(162 28 175 / 0) !important;
}

.tw-bg-fuchsia-700\/10 {
  background-color: rgb(162 28 175 / 0.1) !important;
}

.tw-bg-fuchsia-700\/100 {
  background-color: rgb(162 28 175 / 1) !important;
}

.tw-bg-fuchsia-700\/15 {
  background-color: rgb(162 28 175 / 0.15) !important;
}

.tw-bg-fuchsia-700\/20 {
  background-color: rgb(162 28 175 / 0.2) !important;
}

.tw-bg-fuchsia-700\/25 {
  background-color: rgb(162 28 175 / 0.25) !important;
}

.tw-bg-fuchsia-700\/30 {
  background-color: rgb(162 28 175 / 0.3) !important;
}

.tw-bg-fuchsia-700\/35 {
  background-color: rgb(162 28 175 / 0.35) !important;
}

.tw-bg-fuchsia-700\/40 {
  background-color: rgb(162 28 175 / 0.4) !important;
}

.tw-bg-fuchsia-700\/45 {
  background-color: rgb(162 28 175 / 0.45) !important;
}

.tw-bg-fuchsia-700\/5 {
  background-color: rgb(162 28 175 / 0.05) !important;
}

.tw-bg-fuchsia-700\/50 {
  background-color: rgb(162 28 175 / 0.5) !important;
}

.tw-bg-fuchsia-700\/55 {
  background-color: rgb(162 28 175 / 0.55) !important;
}

.tw-bg-fuchsia-700\/60 {
  background-color: rgb(162 28 175 / 0.6) !important;
}

.tw-bg-fuchsia-700\/65 {
  background-color: rgb(162 28 175 / 0.65) !important;
}

.tw-bg-fuchsia-700\/70 {
  background-color: rgb(162 28 175 / 0.7) !important;
}

.tw-bg-fuchsia-700\/75 {
  background-color: rgb(162 28 175 / 0.75) !important;
}

.tw-bg-fuchsia-700\/80 {
  background-color: rgb(162 28 175 / 0.8) !important;
}

.tw-bg-fuchsia-700\/85 {
  background-color: rgb(162 28 175 / 0.85) !important;
}

.tw-bg-fuchsia-700\/90 {
  background-color: rgb(162 28 175 / 0.9) !important;
}

.tw-bg-fuchsia-700\/95 {
  background-color: rgb(162 28 175 / 0.95) !important;
}

.tw-bg-fuchsia-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(134 25 143 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-800\/0 {
  background-color: rgb(134 25 143 / 0) !important;
}

.tw-bg-fuchsia-800\/10 {
  background-color: rgb(134 25 143 / 0.1) !important;
}

.tw-bg-fuchsia-800\/100 {
  background-color: rgb(134 25 143 / 1) !important;
}

.tw-bg-fuchsia-800\/15 {
  background-color: rgb(134 25 143 / 0.15) !important;
}

.tw-bg-fuchsia-800\/20 {
  background-color: rgb(134 25 143 / 0.2) !important;
}

.tw-bg-fuchsia-800\/25 {
  background-color: rgb(134 25 143 / 0.25) !important;
}

.tw-bg-fuchsia-800\/30 {
  background-color: rgb(134 25 143 / 0.3) !important;
}

.tw-bg-fuchsia-800\/35 {
  background-color: rgb(134 25 143 / 0.35) !important;
}

.tw-bg-fuchsia-800\/40 {
  background-color: rgb(134 25 143 / 0.4) !important;
}

.tw-bg-fuchsia-800\/45 {
  background-color: rgb(134 25 143 / 0.45) !important;
}

.tw-bg-fuchsia-800\/5 {
  background-color: rgb(134 25 143 / 0.05) !important;
}

.tw-bg-fuchsia-800\/50 {
  background-color: rgb(134 25 143 / 0.5) !important;
}

.tw-bg-fuchsia-800\/55 {
  background-color: rgb(134 25 143 / 0.55) !important;
}

.tw-bg-fuchsia-800\/60 {
  background-color: rgb(134 25 143 / 0.6) !important;
}

.tw-bg-fuchsia-800\/65 {
  background-color: rgb(134 25 143 / 0.65) !important;
}

.tw-bg-fuchsia-800\/70 {
  background-color: rgb(134 25 143 / 0.7) !important;
}

.tw-bg-fuchsia-800\/75 {
  background-color: rgb(134 25 143 / 0.75) !important;
}

.tw-bg-fuchsia-800\/80 {
  background-color: rgb(134 25 143 / 0.8) !important;
}

.tw-bg-fuchsia-800\/85 {
  background-color: rgb(134 25 143 / 0.85) !important;
}

.tw-bg-fuchsia-800\/90 {
  background-color: rgb(134 25 143 / 0.9) !important;
}

.tw-bg-fuchsia-800\/95 {
  background-color: rgb(134 25 143 / 0.95) !important;
}

.tw-bg-fuchsia-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(112 26 117 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-900\/0 {
  background-color: rgb(112 26 117 / 0) !important;
}

.tw-bg-fuchsia-900\/10 {
  background-color: rgb(112 26 117 / 0.1) !important;
}

.tw-bg-fuchsia-900\/100 {
  background-color: rgb(112 26 117 / 1) !important;
}

.tw-bg-fuchsia-900\/15 {
  background-color: rgb(112 26 117 / 0.15) !important;
}

.tw-bg-fuchsia-900\/20 {
  background-color: rgb(112 26 117 / 0.2) !important;
}

.tw-bg-fuchsia-900\/25 {
  background-color: rgb(112 26 117 / 0.25) !important;
}

.tw-bg-fuchsia-900\/30 {
  background-color: rgb(112 26 117 / 0.3) !important;
}

.tw-bg-fuchsia-900\/35 {
  background-color: rgb(112 26 117 / 0.35) !important;
}

.tw-bg-fuchsia-900\/40 {
  background-color: rgb(112 26 117 / 0.4) !important;
}

.tw-bg-fuchsia-900\/45 {
  background-color: rgb(112 26 117 / 0.45) !important;
}

.tw-bg-fuchsia-900\/5 {
  background-color: rgb(112 26 117 / 0.05) !important;
}

.tw-bg-fuchsia-900\/50 {
  background-color: rgb(112 26 117 / 0.5) !important;
}

.tw-bg-fuchsia-900\/55 {
  background-color: rgb(112 26 117 / 0.55) !important;
}

.tw-bg-fuchsia-900\/60 {
  background-color: rgb(112 26 117 / 0.6) !important;
}

.tw-bg-fuchsia-900\/65 {
  background-color: rgb(112 26 117 / 0.65) !important;
}

.tw-bg-fuchsia-900\/70 {
  background-color: rgb(112 26 117 / 0.7) !important;
}

.tw-bg-fuchsia-900\/75 {
  background-color: rgb(112 26 117 / 0.75) !important;
}

.tw-bg-fuchsia-900\/80 {
  background-color: rgb(112 26 117 / 0.8) !important;
}

.tw-bg-fuchsia-900\/85 {
  background-color: rgb(112 26 117 / 0.85) !important;
}

.tw-bg-fuchsia-900\/90 {
  background-color: rgb(112 26 117 / 0.9) !important;
}

.tw-bg-fuchsia-900\/95 {
  background-color: rgb(112 26 117 / 0.95) !important;
}

.tw-bg-fuchsia-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(74 4 78 / var(--tw-bg-opacity)) !important;
}

.tw-bg-fuchsia-950\/0 {
  background-color: rgb(74 4 78 / 0) !important;
}

.tw-bg-fuchsia-950\/10 {
  background-color: rgb(74 4 78 / 0.1) !important;
}

.tw-bg-fuchsia-950\/100 {
  background-color: rgb(74 4 78 / 1) !important;
}

.tw-bg-fuchsia-950\/15 {
  background-color: rgb(74 4 78 / 0.15) !important;
}

.tw-bg-fuchsia-950\/20 {
  background-color: rgb(74 4 78 / 0.2) !important;
}

.tw-bg-fuchsia-950\/25 {
  background-color: rgb(74 4 78 / 0.25) !important;
}

.tw-bg-fuchsia-950\/30 {
  background-color: rgb(74 4 78 / 0.3) !important;
}

.tw-bg-fuchsia-950\/35 {
  background-color: rgb(74 4 78 / 0.35) !important;
}

.tw-bg-fuchsia-950\/40 {
  background-color: rgb(74 4 78 / 0.4) !important;
}

.tw-bg-fuchsia-950\/45 {
  background-color: rgb(74 4 78 / 0.45) !important;
}

.tw-bg-fuchsia-950\/5 {
  background-color: rgb(74 4 78 / 0.05) !important;
}

.tw-bg-fuchsia-950\/50 {
  background-color: rgb(74 4 78 / 0.5) !important;
}

.tw-bg-fuchsia-950\/55 {
  background-color: rgb(74 4 78 / 0.55) !important;
}

.tw-bg-fuchsia-950\/60 {
  background-color: rgb(74 4 78 / 0.6) !important;
}

.tw-bg-fuchsia-950\/65 {
  background-color: rgb(74 4 78 / 0.65) !important;
}

.tw-bg-fuchsia-950\/70 {
  background-color: rgb(74 4 78 / 0.7) !important;
}

.tw-bg-fuchsia-950\/75 {
  background-color: rgb(74 4 78 / 0.75) !important;
}

.tw-bg-fuchsia-950\/80 {
  background-color: rgb(74 4 78 / 0.8) !important;
}

.tw-bg-fuchsia-950\/85 {
  background-color: rgb(74 4 78 / 0.85) !important;
}

.tw-bg-fuchsia-950\/90 {
  background-color: rgb(74 4 78 / 0.9) !important;
}

.tw-bg-fuchsia-950\/95 {
  background-color: rgb(74 4 78 / 0.95) !important;
}

.tw-bg-gray-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 244 246 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-100\/0 {
  background-color: rgb(243 244 246 / 0) !important;
}

.tw-bg-gray-100\/10 {
  background-color: rgb(243 244 246 / 0.1) !important;
}

.tw-bg-gray-100\/100 {
  background-color: rgb(243 244 246 / 1) !important;
}

.tw-bg-gray-100\/15 {
  background-color: rgb(243 244 246 / 0.15) !important;
}

.tw-bg-gray-100\/20 {
  background-color: rgb(243 244 246 / 0.2) !important;
}

.tw-bg-gray-100\/25 {
  background-color: rgb(243 244 246 / 0.25) !important;
}

.tw-bg-gray-100\/30 {
  background-color: rgb(243 244 246 / 0.3) !important;
}

.tw-bg-gray-100\/35 {
  background-color: rgb(243 244 246 / 0.35) !important;
}

.tw-bg-gray-100\/40 {
  background-color: rgb(243 244 246 / 0.4) !important;
}

.tw-bg-gray-100\/45 {
  background-color: rgb(243 244 246 / 0.45) !important;
}

.tw-bg-gray-100\/5 {
  background-color: rgb(243 244 246 / 0.05) !important;
}

.tw-bg-gray-100\/50 {
  background-color: rgb(243 244 246 / 0.5) !important;
}

.tw-bg-gray-100\/55 {
  background-color: rgb(243 244 246 / 0.55) !important;
}

.tw-bg-gray-100\/60 {
  background-color: rgb(243 244 246 / 0.6) !important;
}

.tw-bg-gray-100\/65 {
  background-color: rgb(243 244 246 / 0.65) !important;
}

.tw-bg-gray-100\/70 {
  background-color: rgb(243 244 246 / 0.7) !important;
}

.tw-bg-gray-100\/75 {
  background-color: rgb(243 244 246 / 0.75) !important;
}

.tw-bg-gray-100\/80 {
  background-color: rgb(243 244 246 / 0.8) !important;
}

.tw-bg-gray-100\/85 {
  background-color: rgb(243 244 246 / 0.85) !important;
}

.tw-bg-gray-100\/90 {
  background-color: rgb(243 244 246 / 0.9) !important;
}

.tw-bg-gray-100\/95 {
  background-color: rgb(243 244 246 / 0.95) !important;
}

.tw-bg-gray-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-200\/0 {
  background-color: rgb(229 231 235 / 0) !important;
}

.tw-bg-gray-200\/10 {
  background-color: rgb(229 231 235 / 0.1) !important;
}

.tw-bg-gray-200\/100 {
  background-color: rgb(229 231 235 / 1) !important;
}

.tw-bg-gray-200\/15 {
  background-color: rgb(229 231 235 / 0.15) !important;
}

.tw-bg-gray-200\/20 {
  background-color: rgb(229 231 235 / 0.2) !important;
}

.tw-bg-gray-200\/25 {
  background-color: rgb(229 231 235 / 0.25) !important;
}

.tw-bg-gray-200\/30 {
  background-color: rgb(229 231 235 / 0.3) !important;
}

.tw-bg-gray-200\/35 {
  background-color: rgb(229 231 235 / 0.35) !important;
}

.tw-bg-gray-200\/40 {
  background-color: rgb(229 231 235 / 0.4) !important;
}

.tw-bg-gray-200\/45 {
  background-color: rgb(229 231 235 / 0.45) !important;
}

.tw-bg-gray-200\/5 {
  background-color: rgb(229 231 235 / 0.05) !important;
}

.tw-bg-gray-200\/50 {
  background-color: rgb(229 231 235 / 0.5) !important;
}

.tw-bg-gray-200\/55 {
  background-color: rgb(229 231 235 / 0.55) !important;
}

.tw-bg-gray-200\/60 {
  background-color: rgb(229 231 235 / 0.6) !important;
}

.tw-bg-gray-200\/65 {
  background-color: rgb(229 231 235 / 0.65) !important;
}

.tw-bg-gray-200\/70 {
  background-color: rgb(229 231 235 / 0.7) !important;
}

.tw-bg-gray-200\/75 {
  background-color: rgb(229 231 235 / 0.75) !important;
}

.tw-bg-gray-200\/80 {
  background-color: rgb(229 231 235 / 0.8) !important;
}

.tw-bg-gray-200\/85 {
  background-color: rgb(229 231 235 / 0.85) !important;
}

.tw-bg-gray-200\/90 {
  background-color: rgb(229 231 235 / 0.9) !important;
}

.tw-bg-gray-200\/95 {
  background-color: rgb(229 231 235 / 0.95) !important;
}

.tw-bg-gray-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(209 213 219 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-300\/0 {
  background-color: rgb(209 213 219 / 0) !important;
}

.tw-bg-gray-300\/10 {
  background-color: rgb(209 213 219 / 0.1) !important;
}

.tw-bg-gray-300\/100 {
  background-color: rgb(209 213 219 / 1) !important;
}

.tw-bg-gray-300\/15 {
  background-color: rgb(209 213 219 / 0.15) !important;
}

.tw-bg-gray-300\/20 {
  background-color: rgb(209 213 219 / 0.2) !important;
}

.tw-bg-gray-300\/25 {
  background-color: rgb(209 213 219 / 0.25) !important;
}

.tw-bg-gray-300\/30 {
  background-color: rgb(209 213 219 / 0.3) !important;
}

.tw-bg-gray-300\/35 {
  background-color: rgb(209 213 219 / 0.35) !important;
}

.tw-bg-gray-300\/40 {
  background-color: rgb(209 213 219 / 0.4) !important;
}

.tw-bg-gray-300\/45 {
  background-color: rgb(209 213 219 / 0.45) !important;
}

.tw-bg-gray-300\/5 {
  background-color: rgb(209 213 219 / 0.05) !important;
}

.tw-bg-gray-300\/50 {
  background-color: rgb(209 213 219 / 0.5) !important;
}

.tw-bg-gray-300\/55 {
  background-color: rgb(209 213 219 / 0.55) !important;
}

.tw-bg-gray-300\/60 {
  background-color: rgb(209 213 219 / 0.6) !important;
}

.tw-bg-gray-300\/65 {
  background-color: rgb(209 213 219 / 0.65) !important;
}

.tw-bg-gray-300\/70 {
  background-color: rgb(209 213 219 / 0.7) !important;
}

.tw-bg-gray-300\/75 {
  background-color: rgb(209 213 219 / 0.75) !important;
}

.tw-bg-gray-300\/80 {
  background-color: rgb(209 213 219 / 0.8) !important;
}

.tw-bg-gray-300\/85 {
  background-color: rgb(209 213 219 / 0.85) !important;
}

.tw-bg-gray-300\/90 {
  background-color: rgb(209 213 219 / 0.9) !important;
}

.tw-bg-gray-300\/95 {
  background-color: rgb(209 213 219 / 0.95) !important;
}

.tw-bg-gray-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(156 163 175 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-400\/0 {
  background-color: rgb(156 163 175 / 0) !important;
}

.tw-bg-gray-400\/10 {
  background-color: rgb(156 163 175 / 0.1) !important;
}

.tw-bg-gray-400\/100 {
  background-color: rgb(156 163 175 / 1) !important;
}

.tw-bg-gray-400\/15 {
  background-color: rgb(156 163 175 / 0.15) !important;
}

.tw-bg-gray-400\/20 {
  background-color: rgb(156 163 175 / 0.2) !important;
}

.tw-bg-gray-400\/25 {
  background-color: rgb(156 163 175 / 0.25) !important;
}

.tw-bg-gray-400\/30 {
  background-color: rgb(156 163 175 / 0.3) !important;
}

.tw-bg-gray-400\/35 {
  background-color: rgb(156 163 175 / 0.35) !important;
}

.tw-bg-gray-400\/40 {
  background-color: rgb(156 163 175 / 0.4) !important;
}

.tw-bg-gray-400\/45 {
  background-color: rgb(156 163 175 / 0.45) !important;
}

.tw-bg-gray-400\/5 {
  background-color: rgb(156 163 175 / 0.05) !important;
}

.tw-bg-gray-400\/50 {
  background-color: rgb(156 163 175 / 0.5) !important;
}

.tw-bg-gray-400\/55 {
  background-color: rgb(156 163 175 / 0.55) !important;
}

.tw-bg-gray-400\/60 {
  background-color: rgb(156 163 175 / 0.6) !important;
}

.tw-bg-gray-400\/65 {
  background-color: rgb(156 163 175 / 0.65) !important;
}

.tw-bg-gray-400\/70 {
  background-color: rgb(156 163 175 / 0.7) !important;
}

.tw-bg-gray-400\/75 {
  background-color: rgb(156 163 175 / 0.75) !important;
}

.tw-bg-gray-400\/80 {
  background-color: rgb(156 163 175 / 0.8) !important;
}

.tw-bg-gray-400\/85 {
  background-color: rgb(156 163 175 / 0.85) !important;
}

.tw-bg-gray-400\/90 {
  background-color: rgb(156 163 175 / 0.9) !important;
}

.tw-bg-gray-400\/95 {
  background-color: rgb(156 163 175 / 0.95) !important;
}

.tw-bg-gray-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 250 251 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-50\/0 {
  background-color: rgb(249 250 251 / 0) !important;
}

.tw-bg-gray-50\/10 {
  background-color: rgb(249 250 251 / 0.1) !important;
}

.tw-bg-gray-50\/100 {
  background-color: rgb(249 250 251 / 1) !important;
}

.tw-bg-gray-50\/15 {
  background-color: rgb(249 250 251 / 0.15) !important;
}

.tw-bg-gray-50\/20 {
  background-color: rgb(249 250 251 / 0.2) !important;
}

.tw-bg-gray-50\/25 {
  background-color: rgb(249 250 251 / 0.25) !important;
}

.tw-bg-gray-50\/30 {
  background-color: rgb(249 250 251 / 0.3) !important;
}

.tw-bg-gray-50\/35 {
  background-color: rgb(249 250 251 / 0.35) !important;
}

.tw-bg-gray-50\/40 {
  background-color: rgb(249 250 251 / 0.4) !important;
}

.tw-bg-gray-50\/45 {
  background-color: rgb(249 250 251 / 0.45) !important;
}

.tw-bg-gray-50\/5 {
  background-color: rgb(249 250 251 / 0.05) !important;
}

.tw-bg-gray-50\/50 {
  background-color: rgb(249 250 251 / 0.5) !important;
}

.tw-bg-gray-50\/55 {
  background-color: rgb(249 250 251 / 0.55) !important;
}

.tw-bg-gray-50\/60 {
  background-color: rgb(249 250 251 / 0.6) !important;
}

.tw-bg-gray-50\/65 {
  background-color: rgb(249 250 251 / 0.65) !important;
}

.tw-bg-gray-50\/70 {
  background-color: rgb(249 250 251 / 0.7) !important;
}

.tw-bg-gray-50\/75 {
  background-color: rgb(249 250 251 / 0.75) !important;
}

.tw-bg-gray-50\/80 {
  background-color: rgb(249 250 251 / 0.8) !important;
}

.tw-bg-gray-50\/85 {
  background-color: rgb(249 250 251 / 0.85) !important;
}

.tw-bg-gray-50\/90 {
  background-color: rgb(249 250 251 / 0.9) !important;
}

.tw-bg-gray-50\/95 {
  background-color: rgb(249 250 251 / 0.95) !important;
}

.tw-bg-gray-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(107 114 128 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-500\/0 {
  background-color: rgb(107 114 128 / 0) !important;
}

.tw-bg-gray-500\/10 {
  background-color: rgb(107 114 128 / 0.1) !important;
}

.tw-bg-gray-500\/100 {
  background-color: rgb(107 114 128 / 1) !important;
}

.tw-bg-gray-500\/15 {
  background-color: rgb(107 114 128 / 0.15) !important;
}

.tw-bg-gray-500\/20 {
  background-color: rgb(107 114 128 / 0.2) !important;
}

.tw-bg-gray-500\/25 {
  background-color: rgb(107 114 128 / 0.25) !important;
}

.tw-bg-gray-500\/30 {
  background-color: rgb(107 114 128 / 0.3) !important;
}

.tw-bg-gray-500\/35 {
  background-color: rgb(107 114 128 / 0.35) !important;
}

.tw-bg-gray-500\/40 {
  background-color: rgb(107 114 128 / 0.4) !important;
}

.tw-bg-gray-500\/45 {
  background-color: rgb(107 114 128 / 0.45) !important;
}

.tw-bg-gray-500\/5 {
  background-color: rgb(107 114 128 / 0.05) !important;
}

.tw-bg-gray-500\/50 {
  background-color: rgb(107 114 128 / 0.5) !important;
}

.tw-bg-gray-500\/55 {
  background-color: rgb(107 114 128 / 0.55) !important;
}

.tw-bg-gray-500\/60 {
  background-color: rgb(107 114 128 / 0.6) !important;
}

.tw-bg-gray-500\/65 {
  background-color: rgb(107 114 128 / 0.65) !important;
}

.tw-bg-gray-500\/70 {
  background-color: rgb(107 114 128 / 0.7) !important;
}

.tw-bg-gray-500\/75 {
  background-color: rgb(107 114 128 / 0.75) !important;
}

.tw-bg-gray-500\/80 {
  background-color: rgb(107 114 128 / 0.8) !important;
}

.tw-bg-gray-500\/85 {
  background-color: rgb(107 114 128 / 0.85) !important;
}

.tw-bg-gray-500\/90 {
  background-color: rgb(107 114 128 / 0.9) !important;
}

.tw-bg-gray-500\/95 {
  background-color: rgb(107 114 128 / 0.95) !important;
}

.tw-bg-gray-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(75 85 99 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-600\/0 {
  background-color: rgb(75 85 99 / 0) !important;
}

.tw-bg-gray-600\/10 {
  background-color: rgb(75 85 99 / 0.1) !important;
}

.tw-bg-gray-600\/100 {
  background-color: rgb(75 85 99 / 1) !important;
}

.tw-bg-gray-600\/15 {
  background-color: rgb(75 85 99 / 0.15) !important;
}

.tw-bg-gray-600\/20 {
  background-color: rgb(75 85 99 / 0.2) !important;
}

.tw-bg-gray-600\/25 {
  background-color: rgb(75 85 99 / 0.25) !important;
}

.tw-bg-gray-600\/30 {
  background-color: rgb(75 85 99 / 0.3) !important;
}

.tw-bg-gray-600\/35 {
  background-color: rgb(75 85 99 / 0.35) !important;
}

.tw-bg-gray-600\/40 {
  background-color: rgb(75 85 99 / 0.4) !important;
}

.tw-bg-gray-600\/45 {
  background-color: rgb(75 85 99 / 0.45) !important;
}

.tw-bg-gray-600\/5 {
  background-color: rgb(75 85 99 / 0.05) !important;
}

.tw-bg-gray-600\/50 {
  background-color: rgb(75 85 99 / 0.5) !important;
}

.tw-bg-gray-600\/55 {
  background-color: rgb(75 85 99 / 0.55) !important;
}

.tw-bg-gray-600\/60 {
  background-color: rgb(75 85 99 / 0.6) !important;
}

.tw-bg-gray-600\/65 {
  background-color: rgb(75 85 99 / 0.65) !important;
}

.tw-bg-gray-600\/70 {
  background-color: rgb(75 85 99 / 0.7) !important;
}

.tw-bg-gray-600\/75 {
  background-color: rgb(75 85 99 / 0.75) !important;
}

.tw-bg-gray-600\/80 {
  background-color: rgb(75 85 99 / 0.8) !important;
}

.tw-bg-gray-600\/85 {
  background-color: rgb(75 85 99 / 0.85) !important;
}

.tw-bg-gray-600\/90 {
  background-color: rgb(75 85 99 / 0.9) !important;
}

.tw-bg-gray-600\/95 {
  background-color: rgb(75 85 99 / 0.95) !important;
}

.tw-bg-gray-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-700\/0 {
  background-color: rgb(55 65 81 / 0) !important;
}

.tw-bg-gray-700\/10 {
  background-color: rgb(55 65 81 / 0.1) !important;
}

.tw-bg-gray-700\/100 {
  background-color: rgb(55 65 81 / 1) !important;
}

.tw-bg-gray-700\/15 {
  background-color: rgb(55 65 81 / 0.15) !important;
}

.tw-bg-gray-700\/20 {
  background-color: rgb(55 65 81 / 0.2) !important;
}

.tw-bg-gray-700\/25 {
  background-color: rgb(55 65 81 / 0.25) !important;
}

.tw-bg-gray-700\/30 {
  background-color: rgb(55 65 81 / 0.3) !important;
}

.tw-bg-gray-700\/35 {
  background-color: rgb(55 65 81 / 0.35) !important;
}

.tw-bg-gray-700\/40 {
  background-color: rgb(55 65 81 / 0.4) !important;
}

.tw-bg-gray-700\/45 {
  background-color: rgb(55 65 81 / 0.45) !important;
}

.tw-bg-gray-700\/5 {
  background-color: rgb(55 65 81 / 0.05) !important;
}

.tw-bg-gray-700\/50 {
  background-color: rgb(55 65 81 / 0.5) !important;
}

.tw-bg-gray-700\/55 {
  background-color: rgb(55 65 81 / 0.55) !important;
}

.tw-bg-gray-700\/60 {
  background-color: rgb(55 65 81 / 0.6) !important;
}

.tw-bg-gray-700\/65 {
  background-color: rgb(55 65 81 / 0.65) !important;
}

.tw-bg-gray-700\/70 {
  background-color: rgb(55 65 81 / 0.7) !important;
}

.tw-bg-gray-700\/75 {
  background-color: rgb(55 65 81 / 0.75) !important;
}

.tw-bg-gray-700\/80 {
  background-color: rgb(55 65 81 / 0.8) !important;
}

.tw-bg-gray-700\/85 {
  background-color: rgb(55 65 81 / 0.85) !important;
}

.tw-bg-gray-700\/90 {
  background-color: rgb(55 65 81 / 0.9) !important;
}

.tw-bg-gray-700\/95 {
  background-color: rgb(55 65 81 / 0.95) !important;
}

.tw-bg-gray-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-800\/0 {
  background-color: rgb(31 41 55 / 0) !important;
}

.tw-bg-gray-800\/10 {
  background-color: rgb(31 41 55 / 0.1) !important;
}

.tw-bg-gray-800\/100 {
  background-color: rgb(31 41 55 / 1) !important;
}

.tw-bg-gray-800\/15 {
  background-color: rgb(31 41 55 / 0.15) !important;
}

.tw-bg-gray-800\/20 {
  background-color: rgb(31 41 55 / 0.2) !important;
}

.tw-bg-gray-800\/25 {
  background-color: rgb(31 41 55 / 0.25) !important;
}

.tw-bg-gray-800\/30 {
  background-color: rgb(31 41 55 / 0.3) !important;
}

.tw-bg-gray-800\/35 {
  background-color: rgb(31 41 55 / 0.35) !important;
}

.tw-bg-gray-800\/40 {
  background-color: rgb(31 41 55 / 0.4) !important;
}

.tw-bg-gray-800\/45 {
  background-color: rgb(31 41 55 / 0.45) !important;
}

.tw-bg-gray-800\/5 {
  background-color: rgb(31 41 55 / 0.05) !important;
}

.tw-bg-gray-800\/50 {
  background-color: rgb(31 41 55 / 0.5) !important;
}

.tw-bg-gray-800\/55 {
  background-color: rgb(31 41 55 / 0.55) !important;
}

.tw-bg-gray-800\/60 {
  background-color: rgb(31 41 55 / 0.6) !important;
}

.tw-bg-gray-800\/65 {
  background-color: rgb(31 41 55 / 0.65) !important;
}

.tw-bg-gray-800\/70 {
  background-color: rgb(31 41 55 / 0.7) !important;
}

.tw-bg-gray-800\/75 {
  background-color: rgb(31 41 55 / 0.75) !important;
}

.tw-bg-gray-800\/80 {
  background-color: rgb(31 41 55 / 0.8) !important;
}

.tw-bg-gray-800\/85 {
  background-color: rgb(31 41 55 / 0.85) !important;
}

.tw-bg-gray-800\/90 {
  background-color: rgb(31 41 55 / 0.9) !important;
}

.tw-bg-gray-800\/95 {
  background-color: rgb(31 41 55 / 0.95) !important;
}

.tw-bg-gray-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-900\/0 {
  background-color: rgb(17 24 39 / 0) !important;
}

.tw-bg-gray-900\/10 {
  background-color: rgb(17 24 39 / 0.1) !important;
}

.tw-bg-gray-900\/100 {
  background-color: rgb(17 24 39 / 1) !important;
}

.tw-bg-gray-900\/15 {
  background-color: rgb(17 24 39 / 0.15) !important;
}

.tw-bg-gray-900\/20 {
  background-color: rgb(17 24 39 / 0.2) !important;
}

.tw-bg-gray-900\/25 {
  background-color: rgb(17 24 39 / 0.25) !important;
}

.tw-bg-gray-900\/30 {
  background-color: rgb(17 24 39 / 0.3) !important;
}

.tw-bg-gray-900\/35 {
  background-color: rgb(17 24 39 / 0.35) !important;
}

.tw-bg-gray-900\/40 {
  background-color: rgb(17 24 39 / 0.4) !important;
}

.tw-bg-gray-900\/45 {
  background-color: rgb(17 24 39 / 0.45) !important;
}

.tw-bg-gray-900\/5 {
  background-color: rgb(17 24 39 / 0.05) !important;
}

.tw-bg-gray-900\/50 {
  background-color: rgb(17 24 39 / 0.5) !important;
}

.tw-bg-gray-900\/55 {
  background-color: rgb(17 24 39 / 0.55) !important;
}

.tw-bg-gray-900\/60 {
  background-color: rgb(17 24 39 / 0.6) !important;
}

.tw-bg-gray-900\/65 {
  background-color: rgb(17 24 39 / 0.65) !important;
}

.tw-bg-gray-900\/70 {
  background-color: rgb(17 24 39 / 0.7) !important;
}

.tw-bg-gray-900\/75 {
  background-color: rgb(17 24 39 / 0.75) !important;
}

.tw-bg-gray-900\/80 {
  background-color: rgb(17 24 39 / 0.8) !important;
}

.tw-bg-gray-900\/85 {
  background-color: rgb(17 24 39 / 0.85) !important;
}

.tw-bg-gray-900\/90 {
  background-color: rgb(17 24 39 / 0.9) !important;
}

.tw-bg-gray-900\/95 {
  background-color: rgb(17 24 39 / 0.95) !important;
}

.tw-bg-gray-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(3 7 18 / var(--tw-bg-opacity)) !important;
}

.tw-bg-gray-950\/0 {
  background-color: rgb(3 7 18 / 0) !important;
}

.tw-bg-gray-950\/10 {
  background-color: rgb(3 7 18 / 0.1) !important;
}

.tw-bg-gray-950\/100 {
  background-color: rgb(3 7 18 / 1) !important;
}

.tw-bg-gray-950\/15 {
  background-color: rgb(3 7 18 / 0.15) !important;
}

.tw-bg-gray-950\/20 {
  background-color: rgb(3 7 18 / 0.2) !important;
}

.tw-bg-gray-950\/25 {
  background-color: rgb(3 7 18 / 0.25) !important;
}

.tw-bg-gray-950\/30 {
  background-color: rgb(3 7 18 / 0.3) !important;
}

.tw-bg-gray-950\/35 {
  background-color: rgb(3 7 18 / 0.35) !important;
}

.tw-bg-gray-950\/40 {
  background-color: rgb(3 7 18 / 0.4) !important;
}

.tw-bg-gray-950\/45 {
  background-color: rgb(3 7 18 / 0.45) !important;
}

.tw-bg-gray-950\/5 {
  background-color: rgb(3 7 18 / 0.05) !important;
}

.tw-bg-gray-950\/50 {
  background-color: rgb(3 7 18 / 0.5) !important;
}

.tw-bg-gray-950\/55 {
  background-color: rgb(3 7 18 / 0.55) !important;
}

.tw-bg-gray-950\/60 {
  background-color: rgb(3 7 18 / 0.6) !important;
}

.tw-bg-gray-950\/65 {
  background-color: rgb(3 7 18 / 0.65) !important;
}

.tw-bg-gray-950\/70 {
  background-color: rgb(3 7 18 / 0.7) !important;
}

.tw-bg-gray-950\/75 {
  background-color: rgb(3 7 18 / 0.75) !important;
}

.tw-bg-gray-950\/80 {
  background-color: rgb(3 7 18 / 0.8) !important;
}

.tw-bg-gray-950\/85 {
  background-color: rgb(3 7 18 / 0.85) !important;
}

.tw-bg-gray-950\/90 {
  background-color: rgb(3 7 18 / 0.9) !important;
}

.tw-bg-gray-950\/95 {
  background-color: rgb(3 7 18 / 0.95) !important;
}

.tw-bg-green-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 252 231 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-100\/0 {
  background-color: rgb(220 252 231 / 0) !important;
}

.tw-bg-green-100\/10 {
  background-color: rgb(220 252 231 / 0.1) !important;
}

.tw-bg-green-100\/100 {
  background-color: rgb(220 252 231 / 1) !important;
}

.tw-bg-green-100\/15 {
  background-color: rgb(220 252 231 / 0.15) !important;
}

.tw-bg-green-100\/20 {
  background-color: rgb(220 252 231 / 0.2) !important;
}

.tw-bg-green-100\/25 {
  background-color: rgb(220 252 231 / 0.25) !important;
}

.tw-bg-green-100\/30 {
  background-color: rgb(220 252 231 / 0.3) !important;
}

.tw-bg-green-100\/35 {
  background-color: rgb(220 252 231 / 0.35) !important;
}

.tw-bg-green-100\/40 {
  background-color: rgb(220 252 231 / 0.4) !important;
}

.tw-bg-green-100\/45 {
  background-color: rgb(220 252 231 / 0.45) !important;
}

.tw-bg-green-100\/5 {
  background-color: rgb(220 252 231 / 0.05) !important;
}

.tw-bg-green-100\/50 {
  background-color: rgb(220 252 231 / 0.5) !important;
}

.tw-bg-green-100\/55 {
  background-color: rgb(220 252 231 / 0.55) !important;
}

.tw-bg-green-100\/60 {
  background-color: rgb(220 252 231 / 0.6) !important;
}

.tw-bg-green-100\/65 {
  background-color: rgb(220 252 231 / 0.65) !important;
}

.tw-bg-green-100\/70 {
  background-color: rgb(220 252 231 / 0.7) !important;
}

.tw-bg-green-100\/75 {
  background-color: rgb(220 252 231 / 0.75) !important;
}

.tw-bg-green-100\/80 {
  background-color: rgb(220 252 231 / 0.8) !important;
}

.tw-bg-green-100\/85 {
  background-color: rgb(220 252 231 / 0.85) !important;
}

.tw-bg-green-100\/90 {
  background-color: rgb(220 252 231 / 0.9) !important;
}

.tw-bg-green-100\/95 {
  background-color: rgb(220 252 231 / 0.95) !important;
}

.tw-bg-green-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-200\/0 {
  background-color: rgb(187 247 208 / 0) !important;
}

.tw-bg-green-200\/10 {
  background-color: rgb(187 247 208 / 0.1) !important;
}

.tw-bg-green-200\/100 {
  background-color: rgb(187 247 208 / 1) !important;
}

.tw-bg-green-200\/15 {
  background-color: rgb(187 247 208 / 0.15) !important;
}

.tw-bg-green-200\/20 {
  background-color: rgb(187 247 208 / 0.2) !important;
}

.tw-bg-green-200\/25 {
  background-color: rgb(187 247 208 / 0.25) !important;
}

.tw-bg-green-200\/30 {
  background-color: rgb(187 247 208 / 0.3) !important;
}

.tw-bg-green-200\/35 {
  background-color: rgb(187 247 208 / 0.35) !important;
}

.tw-bg-green-200\/40 {
  background-color: rgb(187 247 208 / 0.4) !important;
}

.tw-bg-green-200\/45 {
  background-color: rgb(187 247 208 / 0.45) !important;
}

.tw-bg-green-200\/5 {
  background-color: rgb(187 247 208 / 0.05) !important;
}

.tw-bg-green-200\/50 {
  background-color: rgb(187 247 208 / 0.5) !important;
}

.tw-bg-green-200\/55 {
  background-color: rgb(187 247 208 / 0.55) !important;
}

.tw-bg-green-200\/60 {
  background-color: rgb(187 247 208 / 0.6) !important;
}

.tw-bg-green-200\/65 {
  background-color: rgb(187 247 208 / 0.65) !important;
}

.tw-bg-green-200\/70 {
  background-color: rgb(187 247 208 / 0.7) !important;
}

.tw-bg-green-200\/75 {
  background-color: rgb(187 247 208 / 0.75) !important;
}

.tw-bg-green-200\/80 {
  background-color: rgb(187 247 208 / 0.8) !important;
}

.tw-bg-green-200\/85 {
  background-color: rgb(187 247 208 / 0.85) !important;
}

.tw-bg-green-200\/90 {
  background-color: rgb(187 247 208 / 0.9) !important;
}

.tw-bg-green-200\/95 {
  background-color: rgb(187 247 208 / 0.95) !important;
}

.tw-bg-green-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(134 239 172 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-300\/0 {
  background-color: rgb(134 239 172 / 0) !important;
}

.tw-bg-green-300\/10 {
  background-color: rgb(134 239 172 / 0.1) !important;
}

.tw-bg-green-300\/100 {
  background-color: rgb(134 239 172 / 1) !important;
}

.tw-bg-green-300\/15 {
  background-color: rgb(134 239 172 / 0.15) !important;
}

.tw-bg-green-300\/20 {
  background-color: rgb(134 239 172 / 0.2) !important;
}

.tw-bg-green-300\/25 {
  background-color: rgb(134 239 172 / 0.25) !important;
}

.tw-bg-green-300\/30 {
  background-color: rgb(134 239 172 / 0.3) !important;
}

.tw-bg-green-300\/35 {
  background-color: rgb(134 239 172 / 0.35) !important;
}

.tw-bg-green-300\/40 {
  background-color: rgb(134 239 172 / 0.4) !important;
}

.tw-bg-green-300\/45 {
  background-color: rgb(134 239 172 / 0.45) !important;
}

.tw-bg-green-300\/5 {
  background-color: rgb(134 239 172 / 0.05) !important;
}

.tw-bg-green-300\/50 {
  background-color: rgb(134 239 172 / 0.5) !important;
}

.tw-bg-green-300\/55 {
  background-color: rgb(134 239 172 / 0.55) !important;
}

.tw-bg-green-300\/60 {
  background-color: rgb(134 239 172 / 0.6) !important;
}

.tw-bg-green-300\/65 {
  background-color: rgb(134 239 172 / 0.65) !important;
}

.tw-bg-green-300\/70 {
  background-color: rgb(134 239 172 / 0.7) !important;
}

.tw-bg-green-300\/75 {
  background-color: rgb(134 239 172 / 0.75) !important;
}

.tw-bg-green-300\/80 {
  background-color: rgb(134 239 172 / 0.8) !important;
}

.tw-bg-green-300\/85 {
  background-color: rgb(134 239 172 / 0.85) !important;
}

.tw-bg-green-300\/90 {
  background-color: rgb(134 239 172 / 0.9) !important;
}

.tw-bg-green-300\/95 {
  background-color: rgb(134 239 172 / 0.95) !important;
}

.tw-bg-green-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-400\/0 {
  background-color: rgb(74 222 128 / 0) !important;
}

.tw-bg-green-400\/10 {
  background-color: rgb(74 222 128 / 0.1) !important;
}

.tw-bg-green-400\/100 {
  background-color: rgb(74 222 128 / 1) !important;
}

.tw-bg-green-400\/15 {
  background-color: rgb(74 222 128 / 0.15) !important;
}

.tw-bg-green-400\/20 {
  background-color: rgb(74 222 128 / 0.2) !important;
}

.tw-bg-green-400\/25 {
  background-color: rgb(74 222 128 / 0.25) !important;
}

.tw-bg-green-400\/30 {
  background-color: rgb(74 222 128 / 0.3) !important;
}

.tw-bg-green-400\/35 {
  background-color: rgb(74 222 128 / 0.35) !important;
}

.tw-bg-green-400\/40 {
  background-color: rgb(74 222 128 / 0.4) !important;
}

.tw-bg-green-400\/45 {
  background-color: rgb(74 222 128 / 0.45) !important;
}

.tw-bg-green-400\/5 {
  background-color: rgb(74 222 128 / 0.05) !important;
}

.tw-bg-green-400\/50 {
  background-color: rgb(74 222 128 / 0.5) !important;
}

.tw-bg-green-400\/55 {
  background-color: rgb(74 222 128 / 0.55) !important;
}

.tw-bg-green-400\/60 {
  background-color: rgb(74 222 128 / 0.6) !important;
}

.tw-bg-green-400\/65 {
  background-color: rgb(74 222 128 / 0.65) !important;
}

.tw-bg-green-400\/70 {
  background-color: rgb(74 222 128 / 0.7) !important;
}

.tw-bg-green-400\/75 {
  background-color: rgb(74 222 128 / 0.75) !important;
}

.tw-bg-green-400\/80 {
  background-color: rgb(74 222 128 / 0.8) !important;
}

.tw-bg-green-400\/85 {
  background-color: rgb(74 222 128 / 0.85) !important;
}

.tw-bg-green-400\/90 {
  background-color: rgb(74 222 128 / 0.9) !important;
}

.tw-bg-green-400\/95 {
  background-color: rgb(74 222 128 / 0.95) !important;
}

.tw-bg-green-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 253 244 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-50\/0 {
  background-color: rgb(240 253 244 / 0) !important;
}

.tw-bg-green-50\/10 {
  background-color: rgb(240 253 244 / 0.1) !important;
}

.tw-bg-green-50\/100 {
  background-color: rgb(240 253 244 / 1) !important;
}

.tw-bg-green-50\/15 {
  background-color: rgb(240 253 244 / 0.15) !important;
}

.tw-bg-green-50\/20 {
  background-color: rgb(240 253 244 / 0.2) !important;
}

.tw-bg-green-50\/25 {
  background-color: rgb(240 253 244 / 0.25) !important;
}

.tw-bg-green-50\/30 {
  background-color: rgb(240 253 244 / 0.3) !important;
}

.tw-bg-green-50\/35 {
  background-color: rgb(240 253 244 / 0.35) !important;
}

.tw-bg-green-50\/40 {
  background-color: rgb(240 253 244 / 0.4) !important;
}

.tw-bg-green-50\/45 {
  background-color: rgb(240 253 244 / 0.45) !important;
}

.tw-bg-green-50\/5 {
  background-color: rgb(240 253 244 / 0.05) !important;
}

.tw-bg-green-50\/50 {
  background-color: rgb(240 253 244 / 0.5) !important;
}

.tw-bg-green-50\/55 {
  background-color: rgb(240 253 244 / 0.55) !important;
}

.tw-bg-green-50\/60 {
  background-color: rgb(240 253 244 / 0.6) !important;
}

.tw-bg-green-50\/65 {
  background-color: rgb(240 253 244 / 0.65) !important;
}

.tw-bg-green-50\/70 {
  background-color: rgb(240 253 244 / 0.7) !important;
}

.tw-bg-green-50\/75 {
  background-color: rgb(240 253 244 / 0.75) !important;
}

.tw-bg-green-50\/80 {
  background-color: rgb(240 253 244 / 0.8) !important;
}

.tw-bg-green-50\/85 {
  background-color: rgb(240 253 244 / 0.85) !important;
}

.tw-bg-green-50\/90 {
  background-color: rgb(240 253 244 / 0.9) !important;
}

.tw-bg-green-50\/95 {
  background-color: rgb(240 253 244 / 0.95) !important;
}

.tw-bg-green-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-500\/0 {
  background-color: rgb(34 197 94 / 0) !important;
}

.tw-bg-green-500\/10 {
  background-color: rgb(34 197 94 / 0.1) !important;
}

.tw-bg-green-500\/100 {
  background-color: rgb(34 197 94 / 1) !important;
}

.tw-bg-green-500\/15 {
  background-color: rgb(34 197 94 / 0.15) !important;
}

.tw-bg-green-500\/20 {
  background-color: rgb(34 197 94 / 0.2) !important;
}

.tw-bg-green-500\/25 {
  background-color: rgb(34 197 94 / 0.25) !important;
}

.tw-bg-green-500\/30 {
  background-color: rgb(34 197 94 / 0.3) !important;
}

.tw-bg-green-500\/35 {
  background-color: rgb(34 197 94 / 0.35) !important;
}

.tw-bg-green-500\/40 {
  background-color: rgb(34 197 94 / 0.4) !important;
}

.tw-bg-green-500\/45 {
  background-color: rgb(34 197 94 / 0.45) !important;
}

.tw-bg-green-500\/5 {
  background-color: rgb(34 197 94 / 0.05) !important;
}

.tw-bg-green-500\/50 {
  background-color: rgb(34 197 94 / 0.5) !important;
}

.tw-bg-green-500\/55 {
  background-color: rgb(34 197 94 / 0.55) !important;
}

.tw-bg-green-500\/60 {
  background-color: rgb(34 197 94 / 0.6) !important;
}

.tw-bg-green-500\/65 {
  background-color: rgb(34 197 94 / 0.65) !important;
}

.tw-bg-green-500\/70 {
  background-color: rgb(34 197 94 / 0.7) !important;
}

.tw-bg-green-500\/75 {
  background-color: rgb(34 197 94 / 0.75) !important;
}

.tw-bg-green-500\/80 {
  background-color: rgb(34 197 94 / 0.8) !important;
}

.tw-bg-green-500\/85 {
  background-color: rgb(34 197 94 / 0.85) !important;
}

.tw-bg-green-500\/90 {
  background-color: rgb(34 197 94 / 0.9) !important;
}

.tw-bg-green-500\/95 {
  background-color: rgb(34 197 94 / 0.95) !important;
}

.tw-bg-green-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 163 74 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-600\/0 {
  background-color: rgb(22 163 74 / 0) !important;
}

.tw-bg-green-600\/10 {
  background-color: rgb(22 163 74 / 0.1) !important;
}

.tw-bg-green-600\/100 {
  background-color: rgb(22 163 74 / 1) !important;
}

.tw-bg-green-600\/15 {
  background-color: rgb(22 163 74 / 0.15) !important;
}

.tw-bg-green-600\/20 {
  background-color: rgb(22 163 74 / 0.2) !important;
}

.tw-bg-green-600\/25 {
  background-color: rgb(22 163 74 / 0.25) !important;
}

.tw-bg-green-600\/30 {
  background-color: rgb(22 163 74 / 0.3) !important;
}

.tw-bg-green-600\/35 {
  background-color: rgb(22 163 74 / 0.35) !important;
}

.tw-bg-green-600\/40 {
  background-color: rgb(22 163 74 / 0.4) !important;
}

.tw-bg-green-600\/45 {
  background-color: rgb(22 163 74 / 0.45) !important;
}

.tw-bg-green-600\/5 {
  background-color: rgb(22 163 74 / 0.05) !important;
}

.tw-bg-green-600\/50 {
  background-color: rgb(22 163 74 / 0.5) !important;
}

.tw-bg-green-600\/55 {
  background-color: rgb(22 163 74 / 0.55) !important;
}

.tw-bg-green-600\/60 {
  background-color: rgb(22 163 74 / 0.6) !important;
}

.tw-bg-green-600\/65 {
  background-color: rgb(22 163 74 / 0.65) !important;
}

.tw-bg-green-600\/70 {
  background-color: rgb(22 163 74 / 0.7) !important;
}

.tw-bg-green-600\/75 {
  background-color: rgb(22 163 74 / 0.75) !important;
}

.tw-bg-green-600\/80 {
  background-color: rgb(22 163 74 / 0.8) !important;
}

.tw-bg-green-600\/85 {
  background-color: rgb(22 163 74 / 0.85) !important;
}

.tw-bg-green-600\/90 {
  background-color: rgb(22 163 74 / 0.9) !important;
}

.tw-bg-green-600\/95 {
  background-color: rgb(22 163 74 / 0.95) !important;
}

.tw-bg-green-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(21 128 61 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-700\/0 {
  background-color: rgb(21 128 61 / 0) !important;
}

.tw-bg-green-700\/10 {
  background-color: rgb(21 128 61 / 0.1) !important;
}

.tw-bg-green-700\/100 {
  background-color: rgb(21 128 61 / 1) !important;
}

.tw-bg-green-700\/15 {
  background-color: rgb(21 128 61 / 0.15) !important;
}

.tw-bg-green-700\/20 {
  background-color: rgb(21 128 61 / 0.2) !important;
}

.tw-bg-green-700\/25 {
  background-color: rgb(21 128 61 / 0.25) !important;
}

.tw-bg-green-700\/30 {
  background-color: rgb(21 128 61 / 0.3) !important;
}

.tw-bg-green-700\/35 {
  background-color: rgb(21 128 61 / 0.35) !important;
}

.tw-bg-green-700\/40 {
  background-color: rgb(21 128 61 / 0.4) !important;
}

.tw-bg-green-700\/45 {
  background-color: rgb(21 128 61 / 0.45) !important;
}

.tw-bg-green-700\/5 {
  background-color: rgb(21 128 61 / 0.05) !important;
}

.tw-bg-green-700\/50 {
  background-color: rgb(21 128 61 / 0.5) !important;
}

.tw-bg-green-700\/55 {
  background-color: rgb(21 128 61 / 0.55) !important;
}

.tw-bg-green-700\/60 {
  background-color: rgb(21 128 61 / 0.6) !important;
}

.tw-bg-green-700\/65 {
  background-color: rgb(21 128 61 / 0.65) !important;
}

.tw-bg-green-700\/70 {
  background-color: rgb(21 128 61 / 0.7) !important;
}

.tw-bg-green-700\/75 {
  background-color: rgb(21 128 61 / 0.75) !important;
}

.tw-bg-green-700\/80 {
  background-color: rgb(21 128 61 / 0.8) !important;
}

.tw-bg-green-700\/85 {
  background-color: rgb(21 128 61 / 0.85) !important;
}

.tw-bg-green-700\/90 {
  background-color: rgb(21 128 61 / 0.9) !important;
}

.tw-bg-green-700\/95 {
  background-color: rgb(21 128 61 / 0.95) !important;
}

.tw-bg-green-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(22 101 52 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-800\/0 {
  background-color: rgb(22 101 52 / 0) !important;
}

.tw-bg-green-800\/10 {
  background-color: rgb(22 101 52 / 0.1) !important;
}

.tw-bg-green-800\/100 {
  background-color: rgb(22 101 52 / 1) !important;
}

.tw-bg-green-800\/15 {
  background-color: rgb(22 101 52 / 0.15) !important;
}

.tw-bg-green-800\/20 {
  background-color: rgb(22 101 52 / 0.2) !important;
}

.tw-bg-green-800\/25 {
  background-color: rgb(22 101 52 / 0.25) !important;
}

.tw-bg-green-800\/30 {
  background-color: rgb(22 101 52 / 0.3) !important;
}

.tw-bg-green-800\/35 {
  background-color: rgb(22 101 52 / 0.35) !important;
}

.tw-bg-green-800\/40 {
  background-color: rgb(22 101 52 / 0.4) !important;
}

.tw-bg-green-800\/45 {
  background-color: rgb(22 101 52 / 0.45) !important;
}

.tw-bg-green-800\/5 {
  background-color: rgb(22 101 52 / 0.05) !important;
}

.tw-bg-green-800\/50 {
  background-color: rgb(22 101 52 / 0.5) !important;
}

.tw-bg-green-800\/55 {
  background-color: rgb(22 101 52 / 0.55) !important;
}

.tw-bg-green-800\/60 {
  background-color: rgb(22 101 52 / 0.6) !important;
}

.tw-bg-green-800\/65 {
  background-color: rgb(22 101 52 / 0.65) !important;
}

.tw-bg-green-800\/70 {
  background-color: rgb(22 101 52 / 0.7) !important;
}

.tw-bg-green-800\/75 {
  background-color: rgb(22 101 52 / 0.75) !important;
}

.tw-bg-green-800\/80 {
  background-color: rgb(22 101 52 / 0.8) !important;
}

.tw-bg-green-800\/85 {
  background-color: rgb(22 101 52 / 0.85) !important;
}

.tw-bg-green-800\/90 {
  background-color: rgb(22 101 52 / 0.9) !important;
}

.tw-bg-green-800\/95 {
  background-color: rgb(22 101 52 / 0.95) !important;
}

.tw-bg-green-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 83 45 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-900\/0 {
  background-color: rgb(20 83 45 / 0) !important;
}

.tw-bg-green-900\/10 {
  background-color: rgb(20 83 45 / 0.1) !important;
}

.tw-bg-green-900\/100 {
  background-color: rgb(20 83 45 / 1) !important;
}

.tw-bg-green-900\/15 {
  background-color: rgb(20 83 45 / 0.15) !important;
}

.tw-bg-green-900\/20 {
  background-color: rgb(20 83 45 / 0.2) !important;
}

.tw-bg-green-900\/25 {
  background-color: rgb(20 83 45 / 0.25) !important;
}

.tw-bg-green-900\/30 {
  background-color: rgb(20 83 45 / 0.3) !important;
}

.tw-bg-green-900\/35 {
  background-color: rgb(20 83 45 / 0.35) !important;
}

.tw-bg-green-900\/40 {
  background-color: rgb(20 83 45 / 0.4) !important;
}

.tw-bg-green-900\/45 {
  background-color: rgb(20 83 45 / 0.45) !important;
}

.tw-bg-green-900\/5 {
  background-color: rgb(20 83 45 / 0.05) !important;
}

.tw-bg-green-900\/50 {
  background-color: rgb(20 83 45 / 0.5) !important;
}

.tw-bg-green-900\/55 {
  background-color: rgb(20 83 45 / 0.55) !important;
}

.tw-bg-green-900\/60 {
  background-color: rgb(20 83 45 / 0.6) !important;
}

.tw-bg-green-900\/65 {
  background-color: rgb(20 83 45 / 0.65) !important;
}

.tw-bg-green-900\/70 {
  background-color: rgb(20 83 45 / 0.7) !important;
}

.tw-bg-green-900\/75 {
  background-color: rgb(20 83 45 / 0.75) !important;
}

.tw-bg-green-900\/80 {
  background-color: rgb(20 83 45 / 0.8) !important;
}

.tw-bg-green-900\/85 {
  background-color: rgb(20 83 45 / 0.85) !important;
}

.tw-bg-green-900\/90 {
  background-color: rgb(20 83 45 / 0.9) !important;
}

.tw-bg-green-900\/95 {
  background-color: rgb(20 83 45 / 0.95) !important;
}

.tw-bg-green-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(5 46 22 / var(--tw-bg-opacity)) !important;
}

.tw-bg-green-950\/0 {
  background-color: rgb(5 46 22 / 0) !important;
}

.tw-bg-green-950\/10 {
  background-color: rgb(5 46 22 / 0.1) !important;
}

.tw-bg-green-950\/100 {
  background-color: rgb(5 46 22 / 1) !important;
}

.tw-bg-green-950\/15 {
  background-color: rgb(5 46 22 / 0.15) !important;
}

.tw-bg-green-950\/20 {
  background-color: rgb(5 46 22 / 0.2) !important;
}

.tw-bg-green-950\/25 {
  background-color: rgb(5 46 22 / 0.25) !important;
}

.tw-bg-green-950\/30 {
  background-color: rgb(5 46 22 / 0.3) !important;
}

.tw-bg-green-950\/35 {
  background-color: rgb(5 46 22 / 0.35) !important;
}

.tw-bg-green-950\/40 {
  background-color: rgb(5 46 22 / 0.4) !important;
}

.tw-bg-green-950\/45 {
  background-color: rgb(5 46 22 / 0.45) !important;
}

.tw-bg-green-950\/5 {
  background-color: rgb(5 46 22 / 0.05) !important;
}

.tw-bg-green-950\/50 {
  background-color: rgb(5 46 22 / 0.5) !important;
}

.tw-bg-green-950\/55 {
  background-color: rgb(5 46 22 / 0.55) !important;
}

.tw-bg-green-950\/60 {
  background-color: rgb(5 46 22 / 0.6) !important;
}

.tw-bg-green-950\/65 {
  background-color: rgb(5 46 22 / 0.65) !important;
}

.tw-bg-green-950\/70 {
  background-color: rgb(5 46 22 / 0.7) !important;
}

.tw-bg-green-950\/75 {
  background-color: rgb(5 46 22 / 0.75) !important;
}

.tw-bg-green-950\/80 {
  background-color: rgb(5 46 22 / 0.8) !important;
}

.tw-bg-green-950\/85 {
  background-color: rgb(5 46 22 / 0.85) !important;
}

.tw-bg-green-950\/90 {
  background-color: rgb(5 46 22 / 0.9) !important;
}

.tw-bg-green-950\/95 {
  background-color: rgb(5 46 22 / 0.95) !important;
}

.tw-bg-indigo-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 231 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-100\/0 {
  background-color: rgb(224 231 255 / 0) !important;
}

.tw-bg-indigo-100\/10 {
  background-color: rgb(224 231 255 / 0.1) !important;
}

.tw-bg-indigo-100\/100 {
  background-color: rgb(224 231 255 / 1) !important;
}

.tw-bg-indigo-100\/15 {
  background-color: rgb(224 231 255 / 0.15) !important;
}

.tw-bg-indigo-100\/20 {
  background-color: rgb(224 231 255 / 0.2) !important;
}

.tw-bg-indigo-100\/25 {
  background-color: rgb(224 231 255 / 0.25) !important;
}

.tw-bg-indigo-100\/30 {
  background-color: rgb(224 231 255 / 0.3) !important;
}

.tw-bg-indigo-100\/35 {
  background-color: rgb(224 231 255 / 0.35) !important;
}

.tw-bg-indigo-100\/40 {
  background-color: rgb(224 231 255 / 0.4) !important;
}

.tw-bg-indigo-100\/45 {
  background-color: rgb(224 231 255 / 0.45) !important;
}

.tw-bg-indigo-100\/5 {
  background-color: rgb(224 231 255 / 0.05) !important;
}

.tw-bg-indigo-100\/50 {
  background-color: rgb(224 231 255 / 0.5) !important;
}

.tw-bg-indigo-100\/55 {
  background-color: rgb(224 231 255 / 0.55) !important;
}

.tw-bg-indigo-100\/60 {
  background-color: rgb(224 231 255 / 0.6) !important;
}

.tw-bg-indigo-100\/65 {
  background-color: rgb(224 231 255 / 0.65) !important;
}

.tw-bg-indigo-100\/70 {
  background-color: rgb(224 231 255 / 0.7) !important;
}

.tw-bg-indigo-100\/75 {
  background-color: rgb(224 231 255 / 0.75) !important;
}

.tw-bg-indigo-100\/80 {
  background-color: rgb(224 231 255 / 0.8) !important;
}

.tw-bg-indigo-100\/85 {
  background-color: rgb(224 231 255 / 0.85) !important;
}

.tw-bg-indigo-100\/90 {
  background-color: rgb(224 231 255 / 0.9) !important;
}

.tw-bg-indigo-100\/95 {
  background-color: rgb(224 231 255 / 0.95) !important;
}

.tw-bg-indigo-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(199 210 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-200\/0 {
  background-color: rgb(199 210 254 / 0) !important;
}

.tw-bg-indigo-200\/10 {
  background-color: rgb(199 210 254 / 0.1) !important;
}

.tw-bg-indigo-200\/100 {
  background-color: rgb(199 210 254 / 1) !important;
}

.tw-bg-indigo-200\/15 {
  background-color: rgb(199 210 254 / 0.15) !important;
}

.tw-bg-indigo-200\/20 {
  background-color: rgb(199 210 254 / 0.2) !important;
}

.tw-bg-indigo-200\/25 {
  background-color: rgb(199 210 254 / 0.25) !important;
}

.tw-bg-indigo-200\/30 {
  background-color: rgb(199 210 254 / 0.3) !important;
}

.tw-bg-indigo-200\/35 {
  background-color: rgb(199 210 254 / 0.35) !important;
}

.tw-bg-indigo-200\/40 {
  background-color: rgb(199 210 254 / 0.4) !important;
}

.tw-bg-indigo-200\/45 {
  background-color: rgb(199 210 254 / 0.45) !important;
}

.tw-bg-indigo-200\/5 {
  background-color: rgb(199 210 254 / 0.05) !important;
}

.tw-bg-indigo-200\/50 {
  background-color: rgb(199 210 254 / 0.5) !important;
}

.tw-bg-indigo-200\/55 {
  background-color: rgb(199 210 254 / 0.55) !important;
}

.tw-bg-indigo-200\/60 {
  background-color: rgb(199 210 254 / 0.6) !important;
}

.tw-bg-indigo-200\/65 {
  background-color: rgb(199 210 254 / 0.65) !important;
}

.tw-bg-indigo-200\/70 {
  background-color: rgb(199 210 254 / 0.7) !important;
}

.tw-bg-indigo-200\/75 {
  background-color: rgb(199 210 254 / 0.75) !important;
}

.tw-bg-indigo-200\/80 {
  background-color: rgb(199 210 254 / 0.8) !important;
}

.tw-bg-indigo-200\/85 {
  background-color: rgb(199 210 254 / 0.85) !important;
}

.tw-bg-indigo-200\/90 {
  background-color: rgb(199 210 254 / 0.9) !important;
}

.tw-bg-indigo-200\/95 {
  background-color: rgb(199 210 254 / 0.95) !important;
}

.tw-bg-indigo-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(165 180 252 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-300\/0 {
  background-color: rgb(165 180 252 / 0) !important;
}

.tw-bg-indigo-300\/10 {
  background-color: rgb(165 180 252 / 0.1) !important;
}

.tw-bg-indigo-300\/100 {
  background-color: rgb(165 180 252 / 1) !important;
}

.tw-bg-indigo-300\/15 {
  background-color: rgb(165 180 252 / 0.15) !important;
}

.tw-bg-indigo-300\/20 {
  background-color: rgb(165 180 252 / 0.2) !important;
}

.tw-bg-indigo-300\/25 {
  background-color: rgb(165 180 252 / 0.25) !important;
}

.tw-bg-indigo-300\/30 {
  background-color: rgb(165 180 252 / 0.3) !important;
}

.tw-bg-indigo-300\/35 {
  background-color: rgb(165 180 252 / 0.35) !important;
}

.tw-bg-indigo-300\/40 {
  background-color: rgb(165 180 252 / 0.4) !important;
}

.tw-bg-indigo-300\/45 {
  background-color: rgb(165 180 252 / 0.45) !important;
}

.tw-bg-indigo-300\/5 {
  background-color: rgb(165 180 252 / 0.05) !important;
}

.tw-bg-indigo-300\/50 {
  background-color: rgb(165 180 252 / 0.5) !important;
}

.tw-bg-indigo-300\/55 {
  background-color: rgb(165 180 252 / 0.55) !important;
}

.tw-bg-indigo-300\/60 {
  background-color: rgb(165 180 252 / 0.6) !important;
}

.tw-bg-indigo-300\/65 {
  background-color: rgb(165 180 252 / 0.65) !important;
}

.tw-bg-indigo-300\/70 {
  background-color: rgb(165 180 252 / 0.7) !important;
}

.tw-bg-indigo-300\/75 {
  background-color: rgb(165 180 252 / 0.75) !important;
}

.tw-bg-indigo-300\/80 {
  background-color: rgb(165 180 252 / 0.8) !important;
}

.tw-bg-indigo-300\/85 {
  background-color: rgb(165 180 252 / 0.85) !important;
}

.tw-bg-indigo-300\/90 {
  background-color: rgb(165 180 252 / 0.9) !important;
}

.tw-bg-indigo-300\/95 {
  background-color: rgb(165 180 252 / 0.95) !important;
}

.tw-bg-indigo-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(129 140 248 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-400\/0 {
  background-color: rgb(129 140 248 / 0) !important;
}

.tw-bg-indigo-400\/10 {
  background-color: rgb(129 140 248 / 0.1) !important;
}

.tw-bg-indigo-400\/100 {
  background-color: rgb(129 140 248 / 1) !important;
}

.tw-bg-indigo-400\/15 {
  background-color: rgb(129 140 248 / 0.15) !important;
}

.tw-bg-indigo-400\/20 {
  background-color: rgb(129 140 248 / 0.2) !important;
}

.tw-bg-indigo-400\/25 {
  background-color: rgb(129 140 248 / 0.25) !important;
}

.tw-bg-indigo-400\/30 {
  background-color: rgb(129 140 248 / 0.3) !important;
}

.tw-bg-indigo-400\/35 {
  background-color: rgb(129 140 248 / 0.35) !important;
}

.tw-bg-indigo-400\/40 {
  background-color: rgb(129 140 248 / 0.4) !important;
}

.tw-bg-indigo-400\/45 {
  background-color: rgb(129 140 248 / 0.45) !important;
}

.tw-bg-indigo-400\/5 {
  background-color: rgb(129 140 248 / 0.05) !important;
}

.tw-bg-indigo-400\/50 {
  background-color: rgb(129 140 248 / 0.5) !important;
}

.tw-bg-indigo-400\/55 {
  background-color: rgb(129 140 248 / 0.55) !important;
}

.tw-bg-indigo-400\/60 {
  background-color: rgb(129 140 248 / 0.6) !important;
}

.tw-bg-indigo-400\/65 {
  background-color: rgb(129 140 248 / 0.65) !important;
}

.tw-bg-indigo-400\/70 {
  background-color: rgb(129 140 248 / 0.7) !important;
}

.tw-bg-indigo-400\/75 {
  background-color: rgb(129 140 248 / 0.75) !important;
}

.tw-bg-indigo-400\/80 {
  background-color: rgb(129 140 248 / 0.8) !important;
}

.tw-bg-indigo-400\/85 {
  background-color: rgb(129 140 248 / 0.85) !important;
}

.tw-bg-indigo-400\/90 {
  background-color: rgb(129 140 248 / 0.9) !important;
}

.tw-bg-indigo-400\/95 {
  background-color: rgb(129 140 248 / 0.95) !important;
}

.tw-bg-indigo-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(238 242 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-50\/0 {
  background-color: rgb(238 242 255 / 0) !important;
}

.tw-bg-indigo-50\/10 {
  background-color: rgb(238 242 255 / 0.1) !important;
}

.tw-bg-indigo-50\/100 {
  background-color: rgb(238 242 255 / 1) !important;
}

.tw-bg-indigo-50\/15 {
  background-color: rgb(238 242 255 / 0.15) !important;
}

.tw-bg-indigo-50\/20 {
  background-color: rgb(238 242 255 / 0.2) !important;
}

.tw-bg-indigo-50\/25 {
  background-color: rgb(238 242 255 / 0.25) !important;
}

.tw-bg-indigo-50\/30 {
  background-color: rgb(238 242 255 / 0.3) !important;
}

.tw-bg-indigo-50\/35 {
  background-color: rgb(238 242 255 / 0.35) !important;
}

.tw-bg-indigo-50\/40 {
  background-color: rgb(238 242 255 / 0.4) !important;
}

.tw-bg-indigo-50\/45 {
  background-color: rgb(238 242 255 / 0.45) !important;
}

.tw-bg-indigo-50\/5 {
  background-color: rgb(238 242 255 / 0.05) !important;
}

.tw-bg-indigo-50\/50 {
  background-color: rgb(238 242 255 / 0.5) !important;
}

.tw-bg-indigo-50\/55 {
  background-color: rgb(238 242 255 / 0.55) !important;
}

.tw-bg-indigo-50\/60 {
  background-color: rgb(238 242 255 / 0.6) !important;
}

.tw-bg-indigo-50\/65 {
  background-color: rgb(238 242 255 / 0.65) !important;
}

.tw-bg-indigo-50\/70 {
  background-color: rgb(238 242 255 / 0.7) !important;
}

.tw-bg-indigo-50\/75 {
  background-color: rgb(238 242 255 / 0.75) !important;
}

.tw-bg-indigo-50\/80 {
  background-color: rgb(238 242 255 / 0.8) !important;
}

.tw-bg-indigo-50\/85 {
  background-color: rgb(238 242 255 / 0.85) !important;
}

.tw-bg-indigo-50\/90 {
  background-color: rgb(238 242 255 / 0.9) !important;
}

.tw-bg-indigo-50\/95 {
  background-color: rgb(238 242 255 / 0.95) !important;
}

.tw-bg-indigo-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-500\/0 {
  background-color: rgb(99 102 241 / 0) !important;
}

.tw-bg-indigo-500\/10 {
  background-color: rgb(99 102 241 / 0.1) !important;
}

.tw-bg-indigo-500\/100 {
  background-color: rgb(99 102 241 / 1) !important;
}

.tw-bg-indigo-500\/15 {
  background-color: rgb(99 102 241 / 0.15) !important;
}

.tw-bg-indigo-500\/20 {
  background-color: rgb(99 102 241 / 0.2) !important;
}

.tw-bg-indigo-500\/25 {
  background-color: rgb(99 102 241 / 0.25) !important;
}

.tw-bg-indigo-500\/30 {
  background-color: rgb(99 102 241 / 0.3) !important;
}

.tw-bg-indigo-500\/35 {
  background-color: rgb(99 102 241 / 0.35) !important;
}

.tw-bg-indigo-500\/40 {
  background-color: rgb(99 102 241 / 0.4) !important;
}

.tw-bg-indigo-500\/45 {
  background-color: rgb(99 102 241 / 0.45) !important;
}

.tw-bg-indigo-500\/5 {
  background-color: rgb(99 102 241 / 0.05) !important;
}

.tw-bg-indigo-500\/50 {
  background-color: rgb(99 102 241 / 0.5) !important;
}

.tw-bg-indigo-500\/55 {
  background-color: rgb(99 102 241 / 0.55) !important;
}

.tw-bg-indigo-500\/60 {
  background-color: rgb(99 102 241 / 0.6) !important;
}

.tw-bg-indigo-500\/65 {
  background-color: rgb(99 102 241 / 0.65) !important;
}

.tw-bg-indigo-500\/70 {
  background-color: rgb(99 102 241 / 0.7) !important;
}

.tw-bg-indigo-500\/75 {
  background-color: rgb(99 102 241 / 0.75) !important;
}

.tw-bg-indigo-500\/80 {
  background-color: rgb(99 102 241 / 0.8) !important;
}

.tw-bg-indigo-500\/85 {
  background-color: rgb(99 102 241 / 0.85) !important;
}

.tw-bg-indigo-500\/90 {
  background-color: rgb(99 102 241 / 0.9) !important;
}

.tw-bg-indigo-500\/95 {
  background-color: rgb(99 102 241 / 0.95) !important;
}

.tw-bg-indigo-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-600\/0 {
  background-color: rgb(79 70 229 / 0) !important;
}

.tw-bg-indigo-600\/10 {
  background-color: rgb(79 70 229 / 0.1) !important;
}

.tw-bg-indigo-600\/100 {
  background-color: rgb(79 70 229 / 1) !important;
}

.tw-bg-indigo-600\/15 {
  background-color: rgb(79 70 229 / 0.15) !important;
}

.tw-bg-indigo-600\/20 {
  background-color: rgb(79 70 229 / 0.2) !important;
}

.tw-bg-indigo-600\/25 {
  background-color: rgb(79 70 229 / 0.25) !important;
}

.tw-bg-indigo-600\/30 {
  background-color: rgb(79 70 229 / 0.3) !important;
}

.tw-bg-indigo-600\/35 {
  background-color: rgb(79 70 229 / 0.35) !important;
}

.tw-bg-indigo-600\/40 {
  background-color: rgb(79 70 229 / 0.4) !important;
}

.tw-bg-indigo-600\/45 {
  background-color: rgb(79 70 229 / 0.45) !important;
}

.tw-bg-indigo-600\/5 {
  background-color: rgb(79 70 229 / 0.05) !important;
}

.tw-bg-indigo-600\/50 {
  background-color: rgb(79 70 229 / 0.5) !important;
}

.tw-bg-indigo-600\/55 {
  background-color: rgb(79 70 229 / 0.55) !important;
}

.tw-bg-indigo-600\/60 {
  background-color: rgb(79 70 229 / 0.6) !important;
}

.tw-bg-indigo-600\/65 {
  background-color: rgb(79 70 229 / 0.65) !important;
}

.tw-bg-indigo-600\/70 {
  background-color: rgb(79 70 229 / 0.7) !important;
}

.tw-bg-indigo-600\/75 {
  background-color: rgb(79 70 229 / 0.75) !important;
}

.tw-bg-indigo-600\/80 {
  background-color: rgb(79 70 229 / 0.8) !important;
}

.tw-bg-indigo-600\/85 {
  background-color: rgb(79 70 229 / 0.85) !important;
}

.tw-bg-indigo-600\/90 {
  background-color: rgb(79 70 229 / 0.9) !important;
}

.tw-bg-indigo-600\/95 {
  background-color: rgb(79 70 229 / 0.95) !important;
}

.tw-bg-indigo-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-700\/0 {
  background-color: rgb(67 56 202 / 0) !important;
}

.tw-bg-indigo-700\/10 {
  background-color: rgb(67 56 202 / 0.1) !important;
}

.tw-bg-indigo-700\/100 {
  background-color: rgb(67 56 202 / 1) !important;
}

.tw-bg-indigo-700\/15 {
  background-color: rgb(67 56 202 / 0.15) !important;
}

.tw-bg-indigo-700\/20 {
  background-color: rgb(67 56 202 / 0.2) !important;
}

.tw-bg-indigo-700\/25 {
  background-color: rgb(67 56 202 / 0.25) !important;
}

.tw-bg-indigo-700\/30 {
  background-color: rgb(67 56 202 / 0.3) !important;
}

.tw-bg-indigo-700\/35 {
  background-color: rgb(67 56 202 / 0.35) !important;
}

.tw-bg-indigo-700\/40 {
  background-color: rgb(67 56 202 / 0.4) !important;
}

.tw-bg-indigo-700\/45 {
  background-color: rgb(67 56 202 / 0.45) !important;
}

.tw-bg-indigo-700\/5 {
  background-color: rgb(67 56 202 / 0.05) !important;
}

.tw-bg-indigo-700\/50 {
  background-color: rgb(67 56 202 / 0.5) !important;
}

.tw-bg-indigo-700\/55 {
  background-color: rgb(67 56 202 / 0.55) !important;
}

.tw-bg-indigo-700\/60 {
  background-color: rgb(67 56 202 / 0.6) !important;
}

.tw-bg-indigo-700\/65 {
  background-color: rgb(67 56 202 / 0.65) !important;
}

.tw-bg-indigo-700\/70 {
  background-color: rgb(67 56 202 / 0.7) !important;
}

.tw-bg-indigo-700\/75 {
  background-color: rgb(67 56 202 / 0.75) !important;
}

.tw-bg-indigo-700\/80 {
  background-color: rgb(67 56 202 / 0.8) !important;
}

.tw-bg-indigo-700\/85 {
  background-color: rgb(67 56 202 / 0.85) !important;
}

.tw-bg-indigo-700\/90 {
  background-color: rgb(67 56 202 / 0.9) !important;
}

.tw-bg-indigo-700\/95 {
  background-color: rgb(67 56 202 / 0.95) !important;
}

.tw-bg-indigo-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(55 48 163 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-800\/0 {
  background-color: rgb(55 48 163 / 0) !important;
}

.tw-bg-indigo-800\/10 {
  background-color: rgb(55 48 163 / 0.1) !important;
}

.tw-bg-indigo-800\/100 {
  background-color: rgb(55 48 163 / 1) !important;
}

.tw-bg-indigo-800\/15 {
  background-color: rgb(55 48 163 / 0.15) !important;
}

.tw-bg-indigo-800\/20 {
  background-color: rgb(55 48 163 / 0.2) !important;
}

.tw-bg-indigo-800\/25 {
  background-color: rgb(55 48 163 / 0.25) !important;
}

.tw-bg-indigo-800\/30 {
  background-color: rgb(55 48 163 / 0.3) !important;
}

.tw-bg-indigo-800\/35 {
  background-color: rgb(55 48 163 / 0.35) !important;
}

.tw-bg-indigo-800\/40 {
  background-color: rgb(55 48 163 / 0.4) !important;
}

.tw-bg-indigo-800\/45 {
  background-color: rgb(55 48 163 / 0.45) !important;
}

.tw-bg-indigo-800\/5 {
  background-color: rgb(55 48 163 / 0.05) !important;
}

.tw-bg-indigo-800\/50 {
  background-color: rgb(55 48 163 / 0.5) !important;
}

.tw-bg-indigo-800\/55 {
  background-color: rgb(55 48 163 / 0.55) !important;
}

.tw-bg-indigo-800\/60 {
  background-color: rgb(55 48 163 / 0.6) !important;
}

.tw-bg-indigo-800\/65 {
  background-color: rgb(55 48 163 / 0.65) !important;
}

.tw-bg-indigo-800\/70 {
  background-color: rgb(55 48 163 / 0.7) !important;
}

.tw-bg-indigo-800\/75 {
  background-color: rgb(55 48 163 / 0.75) !important;
}

.tw-bg-indigo-800\/80 {
  background-color: rgb(55 48 163 / 0.8) !important;
}

.tw-bg-indigo-800\/85 {
  background-color: rgb(55 48 163 / 0.85) !important;
}

.tw-bg-indigo-800\/90 {
  background-color: rgb(55 48 163 / 0.9) !important;
}

.tw-bg-indigo-800\/95 {
  background-color: rgb(55 48 163 / 0.95) !important;
}

.tw-bg-indigo-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-900\/0 {
  background-color: rgb(49 46 129 / 0) !important;
}

.tw-bg-indigo-900\/10 {
  background-color: rgb(49 46 129 / 0.1) !important;
}

.tw-bg-indigo-900\/100 {
  background-color: rgb(49 46 129 / 1) !important;
}

.tw-bg-indigo-900\/15 {
  background-color: rgb(49 46 129 / 0.15) !important;
}

.tw-bg-indigo-900\/20 {
  background-color: rgb(49 46 129 / 0.2) !important;
}

.tw-bg-indigo-900\/25 {
  background-color: rgb(49 46 129 / 0.25) !important;
}

.tw-bg-indigo-900\/30 {
  background-color: rgb(49 46 129 / 0.3) !important;
}

.tw-bg-indigo-900\/35 {
  background-color: rgb(49 46 129 / 0.35) !important;
}

.tw-bg-indigo-900\/40 {
  background-color: rgb(49 46 129 / 0.4) !important;
}

.tw-bg-indigo-900\/45 {
  background-color: rgb(49 46 129 / 0.45) !important;
}

.tw-bg-indigo-900\/5 {
  background-color: rgb(49 46 129 / 0.05) !important;
}

.tw-bg-indigo-900\/50 {
  background-color: rgb(49 46 129 / 0.5) !important;
}

.tw-bg-indigo-900\/55 {
  background-color: rgb(49 46 129 / 0.55) !important;
}

.tw-bg-indigo-900\/60 {
  background-color: rgb(49 46 129 / 0.6) !important;
}

.tw-bg-indigo-900\/65 {
  background-color: rgb(49 46 129 / 0.65) !important;
}

.tw-bg-indigo-900\/70 {
  background-color: rgb(49 46 129 / 0.7) !important;
}

.tw-bg-indigo-900\/75 {
  background-color: rgb(49 46 129 / 0.75) !important;
}

.tw-bg-indigo-900\/80 {
  background-color: rgb(49 46 129 / 0.8) !important;
}

.tw-bg-indigo-900\/85 {
  background-color: rgb(49 46 129 / 0.85) !important;
}

.tw-bg-indigo-900\/90 {
  background-color: rgb(49 46 129 / 0.9) !important;
}

.tw-bg-indigo-900\/95 {
  background-color: rgb(49 46 129 / 0.95) !important;
}

.tw-bg-indigo-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 27 75 / var(--tw-bg-opacity)) !important;
}

.tw-bg-indigo-950\/0 {
  background-color: rgb(30 27 75 / 0) !important;
}

.tw-bg-indigo-950\/10 {
  background-color: rgb(30 27 75 / 0.1) !important;
}

.tw-bg-indigo-950\/100 {
  background-color: rgb(30 27 75 / 1) !important;
}

.tw-bg-indigo-950\/15 {
  background-color: rgb(30 27 75 / 0.15) !important;
}

.tw-bg-indigo-950\/20 {
  background-color: rgb(30 27 75 / 0.2) !important;
}

.tw-bg-indigo-950\/25 {
  background-color: rgb(30 27 75 / 0.25) !important;
}

.tw-bg-indigo-950\/30 {
  background-color: rgb(30 27 75 / 0.3) !important;
}

.tw-bg-indigo-950\/35 {
  background-color: rgb(30 27 75 / 0.35) !important;
}

.tw-bg-indigo-950\/40 {
  background-color: rgb(30 27 75 / 0.4) !important;
}

.tw-bg-indigo-950\/45 {
  background-color: rgb(30 27 75 / 0.45) !important;
}

.tw-bg-indigo-950\/5 {
  background-color: rgb(30 27 75 / 0.05) !important;
}

.tw-bg-indigo-950\/50 {
  background-color: rgb(30 27 75 / 0.5) !important;
}

.tw-bg-indigo-950\/55 {
  background-color: rgb(30 27 75 / 0.55) !important;
}

.tw-bg-indigo-950\/60 {
  background-color: rgb(30 27 75 / 0.6) !important;
}

.tw-bg-indigo-950\/65 {
  background-color: rgb(30 27 75 / 0.65) !important;
}

.tw-bg-indigo-950\/70 {
  background-color: rgb(30 27 75 / 0.7) !important;
}

.tw-bg-indigo-950\/75 {
  background-color: rgb(30 27 75 / 0.75) !important;
}

.tw-bg-indigo-950\/80 {
  background-color: rgb(30 27 75 / 0.8) !important;
}

.tw-bg-indigo-950\/85 {
  background-color: rgb(30 27 75 / 0.85) !important;
}

.tw-bg-indigo-950\/90 {
  background-color: rgb(30 27 75 / 0.9) !important;
}

.tw-bg-indigo-950\/95 {
  background-color: rgb(30 27 75 / 0.95) !important;
}

.tw-bg-inherit {
  background-color: inherit !important;
}

.tw-bg-lime-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(236 252 203 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-100\/0 {
  background-color: rgb(236 252 203 / 0) !important;
}

.tw-bg-lime-100\/10 {
  background-color: rgb(236 252 203 / 0.1) !important;
}

.tw-bg-lime-100\/100 {
  background-color: rgb(236 252 203 / 1) !important;
}

.tw-bg-lime-100\/15 {
  background-color: rgb(236 252 203 / 0.15) !important;
}

.tw-bg-lime-100\/20 {
  background-color: rgb(236 252 203 / 0.2) !important;
}

.tw-bg-lime-100\/25 {
  background-color: rgb(236 252 203 / 0.25) !important;
}

.tw-bg-lime-100\/30 {
  background-color: rgb(236 252 203 / 0.3) !important;
}

.tw-bg-lime-100\/35 {
  background-color: rgb(236 252 203 / 0.35) !important;
}

.tw-bg-lime-100\/40 {
  background-color: rgb(236 252 203 / 0.4) !important;
}

.tw-bg-lime-100\/45 {
  background-color: rgb(236 252 203 / 0.45) !important;
}

.tw-bg-lime-100\/5 {
  background-color: rgb(236 252 203 / 0.05) !important;
}

.tw-bg-lime-100\/50 {
  background-color: rgb(236 252 203 / 0.5) !important;
}

.tw-bg-lime-100\/55 {
  background-color: rgb(236 252 203 / 0.55) !important;
}

.tw-bg-lime-100\/60 {
  background-color: rgb(236 252 203 / 0.6) !important;
}

.tw-bg-lime-100\/65 {
  background-color: rgb(236 252 203 / 0.65) !important;
}

.tw-bg-lime-100\/70 {
  background-color: rgb(236 252 203 / 0.7) !important;
}

.tw-bg-lime-100\/75 {
  background-color: rgb(236 252 203 / 0.75) !important;
}

.tw-bg-lime-100\/80 {
  background-color: rgb(236 252 203 / 0.8) !important;
}

.tw-bg-lime-100\/85 {
  background-color: rgb(236 252 203 / 0.85) !important;
}

.tw-bg-lime-100\/90 {
  background-color: rgb(236 252 203 / 0.9) !important;
}

.tw-bg-lime-100\/95 {
  background-color: rgb(236 252 203 / 0.95) !important;
}

.tw-bg-lime-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(217 249 157 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-200\/0 {
  background-color: rgb(217 249 157 / 0) !important;
}

.tw-bg-lime-200\/10 {
  background-color: rgb(217 249 157 / 0.1) !important;
}

.tw-bg-lime-200\/100 {
  background-color: rgb(217 249 157 / 1) !important;
}

.tw-bg-lime-200\/15 {
  background-color: rgb(217 249 157 / 0.15) !important;
}

.tw-bg-lime-200\/20 {
  background-color: rgb(217 249 157 / 0.2) !important;
}

.tw-bg-lime-200\/25 {
  background-color: rgb(217 249 157 / 0.25) !important;
}

.tw-bg-lime-200\/30 {
  background-color: rgb(217 249 157 / 0.3) !important;
}

.tw-bg-lime-200\/35 {
  background-color: rgb(217 249 157 / 0.35) !important;
}

.tw-bg-lime-200\/40 {
  background-color: rgb(217 249 157 / 0.4) !important;
}

.tw-bg-lime-200\/45 {
  background-color: rgb(217 249 157 / 0.45) !important;
}

.tw-bg-lime-200\/5 {
  background-color: rgb(217 249 157 / 0.05) !important;
}

.tw-bg-lime-200\/50 {
  background-color: rgb(217 249 157 / 0.5) !important;
}

.tw-bg-lime-200\/55 {
  background-color: rgb(217 249 157 / 0.55) !important;
}

.tw-bg-lime-200\/60 {
  background-color: rgb(217 249 157 / 0.6) !important;
}

.tw-bg-lime-200\/65 {
  background-color: rgb(217 249 157 / 0.65) !important;
}

.tw-bg-lime-200\/70 {
  background-color: rgb(217 249 157 / 0.7) !important;
}

.tw-bg-lime-200\/75 {
  background-color: rgb(217 249 157 / 0.75) !important;
}

.tw-bg-lime-200\/80 {
  background-color: rgb(217 249 157 / 0.8) !important;
}

.tw-bg-lime-200\/85 {
  background-color: rgb(217 249 157 / 0.85) !important;
}

.tw-bg-lime-200\/90 {
  background-color: rgb(217 249 157 / 0.9) !important;
}

.tw-bg-lime-200\/95 {
  background-color: rgb(217 249 157 / 0.95) !important;
}

.tw-bg-lime-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(190 242 100 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-300\/0 {
  background-color: rgb(190 242 100 / 0) !important;
}

.tw-bg-lime-300\/10 {
  background-color: rgb(190 242 100 / 0.1) !important;
}

.tw-bg-lime-300\/100 {
  background-color: rgb(190 242 100 / 1) !important;
}

.tw-bg-lime-300\/15 {
  background-color: rgb(190 242 100 / 0.15) !important;
}

.tw-bg-lime-300\/20 {
  background-color: rgb(190 242 100 / 0.2) !important;
}

.tw-bg-lime-300\/25 {
  background-color: rgb(190 242 100 / 0.25) !important;
}

.tw-bg-lime-300\/30 {
  background-color: rgb(190 242 100 / 0.3) !important;
}

.tw-bg-lime-300\/35 {
  background-color: rgb(190 242 100 / 0.35) !important;
}

.tw-bg-lime-300\/40 {
  background-color: rgb(190 242 100 / 0.4) !important;
}

.tw-bg-lime-300\/45 {
  background-color: rgb(190 242 100 / 0.45) !important;
}

.tw-bg-lime-300\/5 {
  background-color: rgb(190 242 100 / 0.05) !important;
}

.tw-bg-lime-300\/50 {
  background-color: rgb(190 242 100 / 0.5) !important;
}

.tw-bg-lime-300\/55 {
  background-color: rgb(190 242 100 / 0.55) !important;
}

.tw-bg-lime-300\/60 {
  background-color: rgb(190 242 100 / 0.6) !important;
}

.tw-bg-lime-300\/65 {
  background-color: rgb(190 242 100 / 0.65) !important;
}

.tw-bg-lime-300\/70 {
  background-color: rgb(190 242 100 / 0.7) !important;
}

.tw-bg-lime-300\/75 {
  background-color: rgb(190 242 100 / 0.75) !important;
}

.tw-bg-lime-300\/80 {
  background-color: rgb(190 242 100 / 0.8) !important;
}

.tw-bg-lime-300\/85 {
  background-color: rgb(190 242 100 / 0.85) !important;
}

.tw-bg-lime-300\/90 {
  background-color: rgb(190 242 100 / 0.9) !important;
}

.tw-bg-lime-300\/95 {
  background-color: rgb(190 242 100 / 0.95) !important;
}

.tw-bg-lime-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(163 230 53 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-400\/0 {
  background-color: rgb(163 230 53 / 0) !important;
}

.tw-bg-lime-400\/10 {
  background-color: rgb(163 230 53 / 0.1) !important;
}

.tw-bg-lime-400\/100 {
  background-color: rgb(163 230 53 / 1) !important;
}

.tw-bg-lime-400\/15 {
  background-color: rgb(163 230 53 / 0.15) !important;
}

.tw-bg-lime-400\/20 {
  background-color: rgb(163 230 53 / 0.2) !important;
}

.tw-bg-lime-400\/25 {
  background-color: rgb(163 230 53 / 0.25) !important;
}

.tw-bg-lime-400\/30 {
  background-color: rgb(163 230 53 / 0.3) !important;
}

.tw-bg-lime-400\/35 {
  background-color: rgb(163 230 53 / 0.35) !important;
}

.tw-bg-lime-400\/40 {
  background-color: rgb(163 230 53 / 0.4) !important;
}

.tw-bg-lime-400\/45 {
  background-color: rgb(163 230 53 / 0.45) !important;
}

.tw-bg-lime-400\/5 {
  background-color: rgb(163 230 53 / 0.05) !important;
}

.tw-bg-lime-400\/50 {
  background-color: rgb(163 230 53 / 0.5) !important;
}

.tw-bg-lime-400\/55 {
  background-color: rgb(163 230 53 / 0.55) !important;
}

.tw-bg-lime-400\/60 {
  background-color: rgb(163 230 53 / 0.6) !important;
}

.tw-bg-lime-400\/65 {
  background-color: rgb(163 230 53 / 0.65) !important;
}

.tw-bg-lime-400\/70 {
  background-color: rgb(163 230 53 / 0.7) !important;
}

.tw-bg-lime-400\/75 {
  background-color: rgb(163 230 53 / 0.75) !important;
}

.tw-bg-lime-400\/80 {
  background-color: rgb(163 230 53 / 0.8) !important;
}

.tw-bg-lime-400\/85 {
  background-color: rgb(163 230 53 / 0.85) !important;
}

.tw-bg-lime-400\/90 {
  background-color: rgb(163 230 53 / 0.9) !important;
}

.tw-bg-lime-400\/95 {
  background-color: rgb(163 230 53 / 0.95) !important;
}

.tw-bg-lime-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(247 254 231 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-50\/0 {
  background-color: rgb(247 254 231 / 0) !important;
}

.tw-bg-lime-50\/10 {
  background-color: rgb(247 254 231 / 0.1) !important;
}

.tw-bg-lime-50\/100 {
  background-color: rgb(247 254 231 / 1) !important;
}

.tw-bg-lime-50\/15 {
  background-color: rgb(247 254 231 / 0.15) !important;
}

.tw-bg-lime-50\/20 {
  background-color: rgb(247 254 231 / 0.2) !important;
}

.tw-bg-lime-50\/25 {
  background-color: rgb(247 254 231 / 0.25) !important;
}

.tw-bg-lime-50\/30 {
  background-color: rgb(247 254 231 / 0.3) !important;
}

.tw-bg-lime-50\/35 {
  background-color: rgb(247 254 231 / 0.35) !important;
}

.tw-bg-lime-50\/40 {
  background-color: rgb(247 254 231 / 0.4) !important;
}

.tw-bg-lime-50\/45 {
  background-color: rgb(247 254 231 / 0.45) !important;
}

.tw-bg-lime-50\/5 {
  background-color: rgb(247 254 231 / 0.05) !important;
}

.tw-bg-lime-50\/50 {
  background-color: rgb(247 254 231 / 0.5) !important;
}

.tw-bg-lime-50\/55 {
  background-color: rgb(247 254 231 / 0.55) !important;
}

.tw-bg-lime-50\/60 {
  background-color: rgb(247 254 231 / 0.6) !important;
}

.tw-bg-lime-50\/65 {
  background-color: rgb(247 254 231 / 0.65) !important;
}

.tw-bg-lime-50\/70 {
  background-color: rgb(247 254 231 / 0.7) !important;
}

.tw-bg-lime-50\/75 {
  background-color: rgb(247 254 231 / 0.75) !important;
}

.tw-bg-lime-50\/80 {
  background-color: rgb(247 254 231 / 0.8) !important;
}

.tw-bg-lime-50\/85 {
  background-color: rgb(247 254 231 / 0.85) !important;
}

.tw-bg-lime-50\/90 {
  background-color: rgb(247 254 231 / 0.9) !important;
}

.tw-bg-lime-50\/95 {
  background-color: rgb(247 254 231 / 0.95) !important;
}

.tw-bg-lime-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(132 204 22 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-500\/0 {
  background-color: rgb(132 204 22 / 0) !important;
}

.tw-bg-lime-500\/10 {
  background-color: rgb(132 204 22 / 0.1) !important;
}

.tw-bg-lime-500\/100 {
  background-color: rgb(132 204 22 / 1) !important;
}

.tw-bg-lime-500\/15 {
  background-color: rgb(132 204 22 / 0.15) !important;
}

.tw-bg-lime-500\/20 {
  background-color: rgb(132 204 22 / 0.2) !important;
}

.tw-bg-lime-500\/25 {
  background-color: rgb(132 204 22 / 0.25) !important;
}

.tw-bg-lime-500\/30 {
  background-color: rgb(132 204 22 / 0.3) !important;
}

.tw-bg-lime-500\/35 {
  background-color: rgb(132 204 22 / 0.35) !important;
}

.tw-bg-lime-500\/40 {
  background-color: rgb(132 204 22 / 0.4) !important;
}

.tw-bg-lime-500\/45 {
  background-color: rgb(132 204 22 / 0.45) !important;
}

.tw-bg-lime-500\/5 {
  background-color: rgb(132 204 22 / 0.05) !important;
}

.tw-bg-lime-500\/50 {
  background-color: rgb(132 204 22 / 0.5) !important;
}

.tw-bg-lime-500\/55 {
  background-color: rgb(132 204 22 / 0.55) !important;
}

.tw-bg-lime-500\/60 {
  background-color: rgb(132 204 22 / 0.6) !important;
}

.tw-bg-lime-500\/65 {
  background-color: rgb(132 204 22 / 0.65) !important;
}

.tw-bg-lime-500\/70 {
  background-color: rgb(132 204 22 / 0.7) !important;
}

.tw-bg-lime-500\/75 {
  background-color: rgb(132 204 22 / 0.75) !important;
}

.tw-bg-lime-500\/80 {
  background-color: rgb(132 204 22 / 0.8) !important;
}

.tw-bg-lime-500\/85 {
  background-color: rgb(132 204 22 / 0.85) !important;
}

.tw-bg-lime-500\/90 {
  background-color: rgb(132 204 22 / 0.9) !important;
}

.tw-bg-lime-500\/95 {
  background-color: rgb(132 204 22 / 0.95) !important;
}

.tw-bg-lime-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(101 163 13 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-600\/0 {
  background-color: rgb(101 163 13 / 0) !important;
}

.tw-bg-lime-600\/10 {
  background-color: rgb(101 163 13 / 0.1) !important;
}

.tw-bg-lime-600\/100 {
  background-color: rgb(101 163 13 / 1) !important;
}

.tw-bg-lime-600\/15 {
  background-color: rgb(101 163 13 / 0.15) !important;
}

.tw-bg-lime-600\/20 {
  background-color: rgb(101 163 13 / 0.2) !important;
}

.tw-bg-lime-600\/25 {
  background-color: rgb(101 163 13 / 0.25) !important;
}

.tw-bg-lime-600\/30 {
  background-color: rgb(101 163 13 / 0.3) !important;
}

.tw-bg-lime-600\/35 {
  background-color: rgb(101 163 13 / 0.35) !important;
}

.tw-bg-lime-600\/40 {
  background-color: rgb(101 163 13 / 0.4) !important;
}

.tw-bg-lime-600\/45 {
  background-color: rgb(101 163 13 / 0.45) !important;
}

.tw-bg-lime-600\/5 {
  background-color: rgb(101 163 13 / 0.05) !important;
}

.tw-bg-lime-600\/50 {
  background-color: rgb(101 163 13 / 0.5) !important;
}

.tw-bg-lime-600\/55 {
  background-color: rgb(101 163 13 / 0.55) !important;
}

.tw-bg-lime-600\/60 {
  background-color: rgb(101 163 13 / 0.6) !important;
}

.tw-bg-lime-600\/65 {
  background-color: rgb(101 163 13 / 0.65) !important;
}

.tw-bg-lime-600\/70 {
  background-color: rgb(101 163 13 / 0.7) !important;
}

.tw-bg-lime-600\/75 {
  background-color: rgb(101 163 13 / 0.75) !important;
}

.tw-bg-lime-600\/80 {
  background-color: rgb(101 163 13 / 0.8) !important;
}

.tw-bg-lime-600\/85 {
  background-color: rgb(101 163 13 / 0.85) !important;
}

.tw-bg-lime-600\/90 {
  background-color: rgb(101 163 13 / 0.9) !important;
}

.tw-bg-lime-600\/95 {
  background-color: rgb(101 163 13 / 0.95) !important;
}

.tw-bg-lime-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(77 124 15 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-700\/0 {
  background-color: rgb(77 124 15 / 0) !important;
}

.tw-bg-lime-700\/10 {
  background-color: rgb(77 124 15 / 0.1) !important;
}

.tw-bg-lime-700\/100 {
  background-color: rgb(77 124 15 / 1) !important;
}

.tw-bg-lime-700\/15 {
  background-color: rgb(77 124 15 / 0.15) !important;
}

.tw-bg-lime-700\/20 {
  background-color: rgb(77 124 15 / 0.2) !important;
}

.tw-bg-lime-700\/25 {
  background-color: rgb(77 124 15 / 0.25) !important;
}

.tw-bg-lime-700\/30 {
  background-color: rgb(77 124 15 / 0.3) !important;
}

.tw-bg-lime-700\/35 {
  background-color: rgb(77 124 15 / 0.35) !important;
}

.tw-bg-lime-700\/40 {
  background-color: rgb(77 124 15 / 0.4) !important;
}

.tw-bg-lime-700\/45 {
  background-color: rgb(77 124 15 / 0.45) !important;
}

.tw-bg-lime-700\/5 {
  background-color: rgb(77 124 15 / 0.05) !important;
}

.tw-bg-lime-700\/50 {
  background-color: rgb(77 124 15 / 0.5) !important;
}

.tw-bg-lime-700\/55 {
  background-color: rgb(77 124 15 / 0.55) !important;
}

.tw-bg-lime-700\/60 {
  background-color: rgb(77 124 15 / 0.6) !important;
}

.tw-bg-lime-700\/65 {
  background-color: rgb(77 124 15 / 0.65) !important;
}

.tw-bg-lime-700\/70 {
  background-color: rgb(77 124 15 / 0.7) !important;
}

.tw-bg-lime-700\/75 {
  background-color: rgb(77 124 15 / 0.75) !important;
}

.tw-bg-lime-700\/80 {
  background-color: rgb(77 124 15 / 0.8) !important;
}

.tw-bg-lime-700\/85 {
  background-color: rgb(77 124 15 / 0.85) !important;
}

.tw-bg-lime-700\/90 {
  background-color: rgb(77 124 15 / 0.9) !important;
}

.tw-bg-lime-700\/95 {
  background-color: rgb(77 124 15 / 0.95) !important;
}

.tw-bg-lime-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(63 98 18 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-800\/0 {
  background-color: rgb(63 98 18 / 0) !important;
}

.tw-bg-lime-800\/10 {
  background-color: rgb(63 98 18 / 0.1) !important;
}

.tw-bg-lime-800\/100 {
  background-color: rgb(63 98 18 / 1) !important;
}

.tw-bg-lime-800\/15 {
  background-color: rgb(63 98 18 / 0.15) !important;
}

.tw-bg-lime-800\/20 {
  background-color: rgb(63 98 18 / 0.2) !important;
}

.tw-bg-lime-800\/25 {
  background-color: rgb(63 98 18 / 0.25) !important;
}

.tw-bg-lime-800\/30 {
  background-color: rgb(63 98 18 / 0.3) !important;
}

.tw-bg-lime-800\/35 {
  background-color: rgb(63 98 18 / 0.35) !important;
}

.tw-bg-lime-800\/40 {
  background-color: rgb(63 98 18 / 0.4) !important;
}

.tw-bg-lime-800\/45 {
  background-color: rgb(63 98 18 / 0.45) !important;
}

.tw-bg-lime-800\/5 {
  background-color: rgb(63 98 18 / 0.05) !important;
}

.tw-bg-lime-800\/50 {
  background-color: rgb(63 98 18 / 0.5) !important;
}

.tw-bg-lime-800\/55 {
  background-color: rgb(63 98 18 / 0.55) !important;
}

.tw-bg-lime-800\/60 {
  background-color: rgb(63 98 18 / 0.6) !important;
}

.tw-bg-lime-800\/65 {
  background-color: rgb(63 98 18 / 0.65) !important;
}

.tw-bg-lime-800\/70 {
  background-color: rgb(63 98 18 / 0.7) !important;
}

.tw-bg-lime-800\/75 {
  background-color: rgb(63 98 18 / 0.75) !important;
}

.tw-bg-lime-800\/80 {
  background-color: rgb(63 98 18 / 0.8) !important;
}

.tw-bg-lime-800\/85 {
  background-color: rgb(63 98 18 / 0.85) !important;
}

.tw-bg-lime-800\/90 {
  background-color: rgb(63 98 18 / 0.9) !important;
}

.tw-bg-lime-800\/95 {
  background-color: rgb(63 98 18 / 0.95) !important;
}

.tw-bg-lime-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(54 83 20 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-900\/0 {
  background-color: rgb(54 83 20 / 0) !important;
}

.tw-bg-lime-900\/10 {
  background-color: rgb(54 83 20 / 0.1) !important;
}

.tw-bg-lime-900\/100 {
  background-color: rgb(54 83 20 / 1) !important;
}

.tw-bg-lime-900\/15 {
  background-color: rgb(54 83 20 / 0.15) !important;
}

.tw-bg-lime-900\/20 {
  background-color: rgb(54 83 20 / 0.2) !important;
}

.tw-bg-lime-900\/25 {
  background-color: rgb(54 83 20 / 0.25) !important;
}

.tw-bg-lime-900\/30 {
  background-color: rgb(54 83 20 / 0.3) !important;
}

.tw-bg-lime-900\/35 {
  background-color: rgb(54 83 20 / 0.35) !important;
}

.tw-bg-lime-900\/40 {
  background-color: rgb(54 83 20 / 0.4) !important;
}

.tw-bg-lime-900\/45 {
  background-color: rgb(54 83 20 / 0.45) !important;
}

.tw-bg-lime-900\/5 {
  background-color: rgb(54 83 20 / 0.05) !important;
}

.tw-bg-lime-900\/50 {
  background-color: rgb(54 83 20 / 0.5) !important;
}

.tw-bg-lime-900\/55 {
  background-color: rgb(54 83 20 / 0.55) !important;
}

.tw-bg-lime-900\/60 {
  background-color: rgb(54 83 20 / 0.6) !important;
}

.tw-bg-lime-900\/65 {
  background-color: rgb(54 83 20 / 0.65) !important;
}

.tw-bg-lime-900\/70 {
  background-color: rgb(54 83 20 / 0.7) !important;
}

.tw-bg-lime-900\/75 {
  background-color: rgb(54 83 20 / 0.75) !important;
}

.tw-bg-lime-900\/80 {
  background-color: rgb(54 83 20 / 0.8) !important;
}

.tw-bg-lime-900\/85 {
  background-color: rgb(54 83 20 / 0.85) !important;
}

.tw-bg-lime-900\/90 {
  background-color: rgb(54 83 20 / 0.9) !important;
}

.tw-bg-lime-900\/95 {
  background-color: rgb(54 83 20 / 0.95) !important;
}

.tw-bg-lime-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(26 46 5 / var(--tw-bg-opacity)) !important;
}

.tw-bg-lime-950\/0 {
  background-color: rgb(26 46 5 / 0) !important;
}

.tw-bg-lime-950\/10 {
  background-color: rgb(26 46 5 / 0.1) !important;
}

.tw-bg-lime-950\/100 {
  background-color: rgb(26 46 5 / 1) !important;
}

.tw-bg-lime-950\/15 {
  background-color: rgb(26 46 5 / 0.15) !important;
}

.tw-bg-lime-950\/20 {
  background-color: rgb(26 46 5 / 0.2) !important;
}

.tw-bg-lime-950\/25 {
  background-color: rgb(26 46 5 / 0.25) !important;
}

.tw-bg-lime-950\/30 {
  background-color: rgb(26 46 5 / 0.3) !important;
}

.tw-bg-lime-950\/35 {
  background-color: rgb(26 46 5 / 0.35) !important;
}

.tw-bg-lime-950\/40 {
  background-color: rgb(26 46 5 / 0.4) !important;
}

.tw-bg-lime-950\/45 {
  background-color: rgb(26 46 5 / 0.45) !important;
}

.tw-bg-lime-950\/5 {
  background-color: rgb(26 46 5 / 0.05) !important;
}

.tw-bg-lime-950\/50 {
  background-color: rgb(26 46 5 / 0.5) !important;
}

.tw-bg-lime-950\/55 {
  background-color: rgb(26 46 5 / 0.55) !important;
}

.tw-bg-lime-950\/60 {
  background-color: rgb(26 46 5 / 0.6) !important;
}

.tw-bg-lime-950\/65 {
  background-color: rgb(26 46 5 / 0.65) !important;
}

.tw-bg-lime-950\/70 {
  background-color: rgb(26 46 5 / 0.7) !important;
}

.tw-bg-lime-950\/75 {
  background-color: rgb(26 46 5 / 0.75) !important;
}

.tw-bg-lime-950\/80 {
  background-color: rgb(26 46 5 / 0.8) !important;
}

.tw-bg-lime-950\/85 {
  background-color: rgb(26 46 5 / 0.85) !important;
}

.tw-bg-lime-950\/90 {
  background-color: rgb(26 46 5 / 0.9) !important;
}

.tw-bg-lime-950\/95 {
  background-color: rgb(26 46 5 / 0.95) !important;
}

.tw-bg-neutral-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 245 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-100\/0 {
  background-color: rgb(245 245 245 / 0) !important;
}

.tw-bg-neutral-100\/10 {
  background-color: rgb(245 245 245 / 0.1) !important;
}

.tw-bg-neutral-100\/100 {
  background-color: rgb(245 245 245 / 1) !important;
}

.tw-bg-neutral-100\/15 {
  background-color: rgb(245 245 245 / 0.15) !important;
}

.tw-bg-neutral-100\/20 {
  background-color: rgb(245 245 245 / 0.2) !important;
}

.tw-bg-neutral-100\/25 {
  background-color: rgb(245 245 245 / 0.25) !important;
}

.tw-bg-neutral-100\/30 {
  background-color: rgb(245 245 245 / 0.3) !important;
}

.tw-bg-neutral-100\/35 {
  background-color: rgb(245 245 245 / 0.35) !important;
}

.tw-bg-neutral-100\/40 {
  background-color: rgb(245 245 245 / 0.4) !important;
}

.tw-bg-neutral-100\/45 {
  background-color: rgb(245 245 245 / 0.45) !important;
}

.tw-bg-neutral-100\/5 {
  background-color: rgb(245 245 245 / 0.05) !important;
}

.tw-bg-neutral-100\/50 {
  background-color: rgb(245 245 245 / 0.5) !important;
}

.tw-bg-neutral-100\/55 {
  background-color: rgb(245 245 245 / 0.55) !important;
}

.tw-bg-neutral-100\/60 {
  background-color: rgb(245 245 245 / 0.6) !important;
}

.tw-bg-neutral-100\/65 {
  background-color: rgb(245 245 245 / 0.65) !important;
}

.tw-bg-neutral-100\/70 {
  background-color: rgb(245 245 245 / 0.7) !important;
}

.tw-bg-neutral-100\/75 {
  background-color: rgb(245 245 245 / 0.75) !important;
}

.tw-bg-neutral-100\/80 {
  background-color: rgb(245 245 245 / 0.8) !important;
}

.tw-bg-neutral-100\/85 {
  background-color: rgb(245 245 245 / 0.85) !important;
}

.tw-bg-neutral-100\/90 {
  background-color: rgb(245 245 245 / 0.9) !important;
}

.tw-bg-neutral-100\/95 {
  background-color: rgb(245 245 245 / 0.95) !important;
}

.tw-bg-neutral-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(229 229 229 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-200\/0 {
  background-color: rgb(229 229 229 / 0) !important;
}

.tw-bg-neutral-200\/10 {
  background-color: rgb(229 229 229 / 0.1) !important;
}

.tw-bg-neutral-200\/100 {
  background-color: rgb(229 229 229 / 1) !important;
}

.tw-bg-neutral-200\/15 {
  background-color: rgb(229 229 229 / 0.15) !important;
}

.tw-bg-neutral-200\/20 {
  background-color: rgb(229 229 229 / 0.2) !important;
}

.tw-bg-neutral-200\/25 {
  background-color: rgb(229 229 229 / 0.25) !important;
}

.tw-bg-neutral-200\/30 {
  background-color: rgb(229 229 229 / 0.3) !important;
}

.tw-bg-neutral-200\/35 {
  background-color: rgb(229 229 229 / 0.35) !important;
}

.tw-bg-neutral-200\/40 {
  background-color: rgb(229 229 229 / 0.4) !important;
}

.tw-bg-neutral-200\/45 {
  background-color: rgb(229 229 229 / 0.45) !important;
}

.tw-bg-neutral-200\/5 {
  background-color: rgb(229 229 229 / 0.05) !important;
}

.tw-bg-neutral-200\/50 {
  background-color: rgb(229 229 229 / 0.5) !important;
}

.tw-bg-neutral-200\/55 {
  background-color: rgb(229 229 229 / 0.55) !important;
}

.tw-bg-neutral-200\/60 {
  background-color: rgb(229 229 229 / 0.6) !important;
}

.tw-bg-neutral-200\/65 {
  background-color: rgb(229 229 229 / 0.65) !important;
}

.tw-bg-neutral-200\/70 {
  background-color: rgb(229 229 229 / 0.7) !important;
}

.tw-bg-neutral-200\/75 {
  background-color: rgb(229 229 229 / 0.75) !important;
}

.tw-bg-neutral-200\/80 {
  background-color: rgb(229 229 229 / 0.8) !important;
}

.tw-bg-neutral-200\/85 {
  background-color: rgb(229 229 229 / 0.85) !important;
}

.tw-bg-neutral-200\/90 {
  background-color: rgb(229 229 229 / 0.9) !important;
}

.tw-bg-neutral-200\/95 {
  background-color: rgb(229 229 229 / 0.95) !important;
}

.tw-bg-neutral-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 212 212 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-300\/0 {
  background-color: rgb(212 212 212 / 0) !important;
}

.tw-bg-neutral-300\/10 {
  background-color: rgb(212 212 212 / 0.1) !important;
}

.tw-bg-neutral-300\/100 {
  background-color: rgb(212 212 212 / 1) !important;
}

.tw-bg-neutral-300\/15 {
  background-color: rgb(212 212 212 / 0.15) !important;
}

.tw-bg-neutral-300\/20 {
  background-color: rgb(212 212 212 / 0.2) !important;
}

.tw-bg-neutral-300\/25 {
  background-color: rgb(212 212 212 / 0.25) !important;
}

.tw-bg-neutral-300\/30 {
  background-color: rgb(212 212 212 / 0.3) !important;
}

.tw-bg-neutral-300\/35 {
  background-color: rgb(212 212 212 / 0.35) !important;
}

.tw-bg-neutral-300\/40 {
  background-color: rgb(212 212 212 / 0.4) !important;
}

.tw-bg-neutral-300\/45 {
  background-color: rgb(212 212 212 / 0.45) !important;
}

.tw-bg-neutral-300\/5 {
  background-color: rgb(212 212 212 / 0.05) !important;
}

.tw-bg-neutral-300\/50 {
  background-color: rgb(212 212 212 / 0.5) !important;
}

.tw-bg-neutral-300\/55 {
  background-color: rgb(212 212 212 / 0.55) !important;
}

.tw-bg-neutral-300\/60 {
  background-color: rgb(212 212 212 / 0.6) !important;
}

.tw-bg-neutral-300\/65 {
  background-color: rgb(212 212 212 / 0.65) !important;
}

.tw-bg-neutral-300\/70 {
  background-color: rgb(212 212 212 / 0.7) !important;
}

.tw-bg-neutral-300\/75 {
  background-color: rgb(212 212 212 / 0.75) !important;
}

.tw-bg-neutral-300\/80 {
  background-color: rgb(212 212 212 / 0.8) !important;
}

.tw-bg-neutral-300\/85 {
  background-color: rgb(212 212 212 / 0.85) !important;
}

.tw-bg-neutral-300\/90 {
  background-color: rgb(212 212 212 / 0.9) !important;
}

.tw-bg-neutral-300\/95 {
  background-color: rgb(212 212 212 / 0.95) !important;
}

.tw-bg-neutral-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(163 163 163 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-400\/0 {
  background-color: rgb(163 163 163 / 0) !important;
}

.tw-bg-neutral-400\/10 {
  background-color: rgb(163 163 163 / 0.1) !important;
}

.tw-bg-neutral-400\/100 {
  background-color: rgb(163 163 163 / 1) !important;
}

.tw-bg-neutral-400\/15 {
  background-color: rgb(163 163 163 / 0.15) !important;
}

.tw-bg-neutral-400\/20 {
  background-color: rgb(163 163 163 / 0.2) !important;
}

.tw-bg-neutral-400\/25 {
  background-color: rgb(163 163 163 / 0.25) !important;
}

.tw-bg-neutral-400\/30 {
  background-color: rgb(163 163 163 / 0.3) !important;
}

.tw-bg-neutral-400\/35 {
  background-color: rgb(163 163 163 / 0.35) !important;
}

.tw-bg-neutral-400\/40 {
  background-color: rgb(163 163 163 / 0.4) !important;
}

.tw-bg-neutral-400\/45 {
  background-color: rgb(163 163 163 / 0.45) !important;
}

.tw-bg-neutral-400\/5 {
  background-color: rgb(163 163 163 / 0.05) !important;
}

.tw-bg-neutral-400\/50 {
  background-color: rgb(163 163 163 / 0.5) !important;
}

.tw-bg-neutral-400\/55 {
  background-color: rgb(163 163 163 / 0.55) !important;
}

.tw-bg-neutral-400\/60 {
  background-color: rgb(163 163 163 / 0.6) !important;
}

.tw-bg-neutral-400\/65 {
  background-color: rgb(163 163 163 / 0.65) !important;
}

.tw-bg-neutral-400\/70 {
  background-color: rgb(163 163 163 / 0.7) !important;
}

.tw-bg-neutral-400\/75 {
  background-color: rgb(163 163 163 / 0.75) !important;
}

.tw-bg-neutral-400\/80 {
  background-color: rgb(163 163 163 / 0.8) !important;
}

.tw-bg-neutral-400\/85 {
  background-color: rgb(163 163 163 / 0.85) !important;
}

.tw-bg-neutral-400\/90 {
  background-color: rgb(163 163 163 / 0.9) !important;
}

.tw-bg-neutral-400\/95 {
  background-color: rgb(163 163 163 / 0.95) !important;
}

.tw-bg-neutral-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-50\/0 {
  background-color: rgb(250 250 250 / 0) !important;
}

.tw-bg-neutral-50\/10 {
  background-color: rgb(250 250 250 / 0.1) !important;
}

.tw-bg-neutral-50\/100 {
  background-color: rgb(250 250 250 / 1) !important;
}

.tw-bg-neutral-50\/15 {
  background-color: rgb(250 250 250 / 0.15) !important;
}

.tw-bg-neutral-50\/20 {
  background-color: rgb(250 250 250 / 0.2) !important;
}

.tw-bg-neutral-50\/25 {
  background-color: rgb(250 250 250 / 0.25) !important;
}

.tw-bg-neutral-50\/30 {
  background-color: rgb(250 250 250 / 0.3) !important;
}

.tw-bg-neutral-50\/35 {
  background-color: rgb(250 250 250 / 0.35) !important;
}

.tw-bg-neutral-50\/40 {
  background-color: rgb(250 250 250 / 0.4) !important;
}

.tw-bg-neutral-50\/45 {
  background-color: rgb(250 250 250 / 0.45) !important;
}

.tw-bg-neutral-50\/5 {
  background-color: rgb(250 250 250 / 0.05) !important;
}

.tw-bg-neutral-50\/50 {
  background-color: rgb(250 250 250 / 0.5) !important;
}

.tw-bg-neutral-50\/55 {
  background-color: rgb(250 250 250 / 0.55) !important;
}

.tw-bg-neutral-50\/60 {
  background-color: rgb(250 250 250 / 0.6) !important;
}

.tw-bg-neutral-50\/65 {
  background-color: rgb(250 250 250 / 0.65) !important;
}

.tw-bg-neutral-50\/70 {
  background-color: rgb(250 250 250 / 0.7) !important;
}

.tw-bg-neutral-50\/75 {
  background-color: rgb(250 250 250 / 0.75) !important;
}

.tw-bg-neutral-50\/80 {
  background-color: rgb(250 250 250 / 0.8) !important;
}

.tw-bg-neutral-50\/85 {
  background-color: rgb(250 250 250 / 0.85) !important;
}

.tw-bg-neutral-50\/90 {
  background-color: rgb(250 250 250 / 0.9) !important;
}

.tw-bg-neutral-50\/95 {
  background-color: rgb(250 250 250 / 0.95) !important;
}

.tw-bg-neutral-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(115 115 115 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-500\/0 {
  background-color: rgb(115 115 115 / 0) !important;
}

.tw-bg-neutral-500\/10 {
  background-color: rgb(115 115 115 / 0.1) !important;
}

.tw-bg-neutral-500\/100 {
  background-color: rgb(115 115 115 / 1) !important;
}

.tw-bg-neutral-500\/15 {
  background-color: rgb(115 115 115 / 0.15) !important;
}

.tw-bg-neutral-500\/20 {
  background-color: rgb(115 115 115 / 0.2) !important;
}

.tw-bg-neutral-500\/25 {
  background-color: rgb(115 115 115 / 0.25) !important;
}

.tw-bg-neutral-500\/30 {
  background-color: rgb(115 115 115 / 0.3) !important;
}

.tw-bg-neutral-500\/35 {
  background-color: rgb(115 115 115 / 0.35) !important;
}

.tw-bg-neutral-500\/40 {
  background-color: rgb(115 115 115 / 0.4) !important;
}

.tw-bg-neutral-500\/45 {
  background-color: rgb(115 115 115 / 0.45) !important;
}

.tw-bg-neutral-500\/5 {
  background-color: rgb(115 115 115 / 0.05) !important;
}

.tw-bg-neutral-500\/50 {
  background-color: rgb(115 115 115 / 0.5) !important;
}

.tw-bg-neutral-500\/55 {
  background-color: rgb(115 115 115 / 0.55) !important;
}

.tw-bg-neutral-500\/60 {
  background-color: rgb(115 115 115 / 0.6) !important;
}

.tw-bg-neutral-500\/65 {
  background-color: rgb(115 115 115 / 0.65) !important;
}

.tw-bg-neutral-500\/70 {
  background-color: rgb(115 115 115 / 0.7) !important;
}

.tw-bg-neutral-500\/75 {
  background-color: rgb(115 115 115 / 0.75) !important;
}

.tw-bg-neutral-500\/80 {
  background-color: rgb(115 115 115 / 0.8) !important;
}

.tw-bg-neutral-500\/85 {
  background-color: rgb(115 115 115 / 0.85) !important;
}

.tw-bg-neutral-500\/90 {
  background-color: rgb(115 115 115 / 0.9) !important;
}

.tw-bg-neutral-500\/95 {
  background-color: rgb(115 115 115 / 0.95) !important;
}

.tw-bg-neutral-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(82 82 82 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-600\/0 {
  background-color: rgb(82 82 82 / 0) !important;
}

.tw-bg-neutral-600\/10 {
  background-color: rgb(82 82 82 / 0.1) !important;
}

.tw-bg-neutral-600\/100 {
  background-color: rgb(82 82 82 / 1) !important;
}

.tw-bg-neutral-600\/15 {
  background-color: rgb(82 82 82 / 0.15) !important;
}

.tw-bg-neutral-600\/20 {
  background-color: rgb(82 82 82 / 0.2) !important;
}

.tw-bg-neutral-600\/25 {
  background-color: rgb(82 82 82 / 0.25) !important;
}

.tw-bg-neutral-600\/30 {
  background-color: rgb(82 82 82 / 0.3) !important;
}

.tw-bg-neutral-600\/35 {
  background-color: rgb(82 82 82 / 0.35) !important;
}

.tw-bg-neutral-600\/40 {
  background-color: rgb(82 82 82 / 0.4) !important;
}

.tw-bg-neutral-600\/45 {
  background-color: rgb(82 82 82 / 0.45) !important;
}

.tw-bg-neutral-600\/5 {
  background-color: rgb(82 82 82 / 0.05) !important;
}

.tw-bg-neutral-600\/50 {
  background-color: rgb(82 82 82 / 0.5) !important;
}

.tw-bg-neutral-600\/55 {
  background-color: rgb(82 82 82 / 0.55) !important;
}

.tw-bg-neutral-600\/60 {
  background-color: rgb(82 82 82 / 0.6) !important;
}

.tw-bg-neutral-600\/65 {
  background-color: rgb(82 82 82 / 0.65) !important;
}

.tw-bg-neutral-600\/70 {
  background-color: rgb(82 82 82 / 0.7) !important;
}

.tw-bg-neutral-600\/75 {
  background-color: rgb(82 82 82 / 0.75) !important;
}

.tw-bg-neutral-600\/80 {
  background-color: rgb(82 82 82 / 0.8) !important;
}

.tw-bg-neutral-600\/85 {
  background-color: rgb(82 82 82 / 0.85) !important;
}

.tw-bg-neutral-600\/90 {
  background-color: rgb(82 82 82 / 0.9) !important;
}

.tw-bg-neutral-600\/95 {
  background-color: rgb(82 82 82 / 0.95) !important;
}

.tw-bg-neutral-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(64 64 64 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-700\/0 {
  background-color: rgb(64 64 64 / 0) !important;
}

.tw-bg-neutral-700\/10 {
  background-color: rgb(64 64 64 / 0.1) !important;
}

.tw-bg-neutral-700\/100 {
  background-color: rgb(64 64 64 / 1) !important;
}

.tw-bg-neutral-700\/15 {
  background-color: rgb(64 64 64 / 0.15) !important;
}

.tw-bg-neutral-700\/20 {
  background-color: rgb(64 64 64 / 0.2) !important;
}

.tw-bg-neutral-700\/25 {
  background-color: rgb(64 64 64 / 0.25) !important;
}

.tw-bg-neutral-700\/30 {
  background-color: rgb(64 64 64 / 0.3) !important;
}

.tw-bg-neutral-700\/35 {
  background-color: rgb(64 64 64 / 0.35) !important;
}

.tw-bg-neutral-700\/40 {
  background-color: rgb(64 64 64 / 0.4) !important;
}

.tw-bg-neutral-700\/45 {
  background-color: rgb(64 64 64 / 0.45) !important;
}

.tw-bg-neutral-700\/5 {
  background-color: rgb(64 64 64 / 0.05) !important;
}

.tw-bg-neutral-700\/50 {
  background-color: rgb(64 64 64 / 0.5) !important;
}

.tw-bg-neutral-700\/55 {
  background-color: rgb(64 64 64 / 0.55) !important;
}

.tw-bg-neutral-700\/60 {
  background-color: rgb(64 64 64 / 0.6) !important;
}

.tw-bg-neutral-700\/65 {
  background-color: rgb(64 64 64 / 0.65) !important;
}

.tw-bg-neutral-700\/70 {
  background-color: rgb(64 64 64 / 0.7) !important;
}

.tw-bg-neutral-700\/75 {
  background-color: rgb(64 64 64 / 0.75) !important;
}

.tw-bg-neutral-700\/80 {
  background-color: rgb(64 64 64 / 0.8) !important;
}

.tw-bg-neutral-700\/85 {
  background-color: rgb(64 64 64 / 0.85) !important;
}

.tw-bg-neutral-700\/90 {
  background-color: rgb(64 64 64 / 0.9) !important;
}

.tw-bg-neutral-700\/95 {
  background-color: rgb(64 64 64 / 0.95) !important;
}

.tw-bg-neutral-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(38 38 38 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-800\/0 {
  background-color: rgb(38 38 38 / 0) !important;
}

.tw-bg-neutral-800\/10 {
  background-color: rgb(38 38 38 / 0.1) !important;
}

.tw-bg-neutral-800\/100 {
  background-color: rgb(38 38 38 / 1) !important;
}

.tw-bg-neutral-800\/15 {
  background-color: rgb(38 38 38 / 0.15) !important;
}

.tw-bg-neutral-800\/20 {
  background-color: rgb(38 38 38 / 0.2) !important;
}

.tw-bg-neutral-800\/25 {
  background-color: rgb(38 38 38 / 0.25) !important;
}

.tw-bg-neutral-800\/30 {
  background-color: rgb(38 38 38 / 0.3) !important;
}

.tw-bg-neutral-800\/35 {
  background-color: rgb(38 38 38 / 0.35) !important;
}

.tw-bg-neutral-800\/40 {
  background-color: rgb(38 38 38 / 0.4) !important;
}

.tw-bg-neutral-800\/45 {
  background-color: rgb(38 38 38 / 0.45) !important;
}

.tw-bg-neutral-800\/5 {
  background-color: rgb(38 38 38 / 0.05) !important;
}

.tw-bg-neutral-800\/50 {
  background-color: rgb(38 38 38 / 0.5) !important;
}

.tw-bg-neutral-800\/55 {
  background-color: rgb(38 38 38 / 0.55) !important;
}

.tw-bg-neutral-800\/60 {
  background-color: rgb(38 38 38 / 0.6) !important;
}

.tw-bg-neutral-800\/65 {
  background-color: rgb(38 38 38 / 0.65) !important;
}

.tw-bg-neutral-800\/70 {
  background-color: rgb(38 38 38 / 0.7) !important;
}

.tw-bg-neutral-800\/75 {
  background-color: rgb(38 38 38 / 0.75) !important;
}

.tw-bg-neutral-800\/80 {
  background-color: rgb(38 38 38 / 0.8) !important;
}

.tw-bg-neutral-800\/85 {
  background-color: rgb(38 38 38 / 0.85) !important;
}

.tw-bg-neutral-800\/90 {
  background-color: rgb(38 38 38 / 0.9) !important;
}

.tw-bg-neutral-800\/95 {
  background-color: rgb(38 38 38 / 0.95) !important;
}

.tw-bg-neutral-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(23 23 23 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-900\/0 {
  background-color: rgb(23 23 23 / 0) !important;
}

.tw-bg-neutral-900\/10 {
  background-color: rgb(23 23 23 / 0.1) !important;
}

.tw-bg-neutral-900\/100 {
  background-color: rgb(23 23 23 / 1) !important;
}

.tw-bg-neutral-900\/15 {
  background-color: rgb(23 23 23 / 0.15) !important;
}

.tw-bg-neutral-900\/20 {
  background-color: rgb(23 23 23 / 0.2) !important;
}

.tw-bg-neutral-900\/25 {
  background-color: rgb(23 23 23 / 0.25) !important;
}

.tw-bg-neutral-900\/30 {
  background-color: rgb(23 23 23 / 0.3) !important;
}

.tw-bg-neutral-900\/35 {
  background-color: rgb(23 23 23 / 0.35) !important;
}

.tw-bg-neutral-900\/40 {
  background-color: rgb(23 23 23 / 0.4) !important;
}

.tw-bg-neutral-900\/45 {
  background-color: rgb(23 23 23 / 0.45) !important;
}

.tw-bg-neutral-900\/5 {
  background-color: rgb(23 23 23 / 0.05) !important;
}

.tw-bg-neutral-900\/50 {
  background-color: rgb(23 23 23 / 0.5) !important;
}

.tw-bg-neutral-900\/55 {
  background-color: rgb(23 23 23 / 0.55) !important;
}

.tw-bg-neutral-900\/60 {
  background-color: rgb(23 23 23 / 0.6) !important;
}

.tw-bg-neutral-900\/65 {
  background-color: rgb(23 23 23 / 0.65) !important;
}

.tw-bg-neutral-900\/70 {
  background-color: rgb(23 23 23 / 0.7) !important;
}

.tw-bg-neutral-900\/75 {
  background-color: rgb(23 23 23 / 0.75) !important;
}

.tw-bg-neutral-900\/80 {
  background-color: rgb(23 23 23 / 0.8) !important;
}

.tw-bg-neutral-900\/85 {
  background-color: rgb(23 23 23 / 0.85) !important;
}

.tw-bg-neutral-900\/90 {
  background-color: rgb(23 23 23 / 0.9) !important;
}

.tw-bg-neutral-900\/95 {
  background-color: rgb(23 23 23 / 0.95) !important;
}

.tw-bg-neutral-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(10 10 10 / var(--tw-bg-opacity)) !important;
}

.tw-bg-neutral-950\/0 {
  background-color: rgb(10 10 10 / 0) !important;
}

.tw-bg-neutral-950\/10 {
  background-color: rgb(10 10 10 / 0.1) !important;
}

.tw-bg-neutral-950\/100 {
  background-color: rgb(10 10 10 / 1) !important;
}

.tw-bg-neutral-950\/15 {
  background-color: rgb(10 10 10 / 0.15) !important;
}

.tw-bg-neutral-950\/20 {
  background-color: rgb(10 10 10 / 0.2) !important;
}

.tw-bg-neutral-950\/25 {
  background-color: rgb(10 10 10 / 0.25) !important;
}

.tw-bg-neutral-950\/30 {
  background-color: rgb(10 10 10 / 0.3) !important;
}

.tw-bg-neutral-950\/35 {
  background-color: rgb(10 10 10 / 0.35) !important;
}

.tw-bg-neutral-950\/40 {
  background-color: rgb(10 10 10 / 0.4) !important;
}

.tw-bg-neutral-950\/45 {
  background-color: rgb(10 10 10 / 0.45) !important;
}

.tw-bg-neutral-950\/5 {
  background-color: rgb(10 10 10 / 0.05) !important;
}

.tw-bg-neutral-950\/50 {
  background-color: rgb(10 10 10 / 0.5) !important;
}

.tw-bg-neutral-950\/55 {
  background-color: rgb(10 10 10 / 0.55) !important;
}

.tw-bg-neutral-950\/60 {
  background-color: rgb(10 10 10 / 0.6) !important;
}

.tw-bg-neutral-950\/65 {
  background-color: rgb(10 10 10 / 0.65) !important;
}

.tw-bg-neutral-950\/70 {
  background-color: rgb(10 10 10 / 0.7) !important;
}

.tw-bg-neutral-950\/75 {
  background-color: rgb(10 10 10 / 0.75) !important;
}

.tw-bg-neutral-950\/80 {
  background-color: rgb(10 10 10 / 0.8) !important;
}

.tw-bg-neutral-950\/85 {
  background-color: rgb(10 10 10 / 0.85) !important;
}

.tw-bg-neutral-950\/90 {
  background-color: rgb(10 10 10 / 0.9) !important;
}

.tw-bg-neutral-950\/95 {
  background-color: rgb(10 10 10 / 0.95) !important;
}

.tw-bg-orange-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 237 213 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-100\/0 {
  background-color: rgb(255 237 213 / 0) !important;
}

.tw-bg-orange-100\/10 {
  background-color: rgb(255 237 213 / 0.1) !important;
}

.tw-bg-orange-100\/100 {
  background-color: rgb(255 237 213 / 1) !important;
}

.tw-bg-orange-100\/15 {
  background-color: rgb(255 237 213 / 0.15) !important;
}

.tw-bg-orange-100\/20 {
  background-color: rgb(255 237 213 / 0.2) !important;
}

.tw-bg-orange-100\/25 {
  background-color: rgb(255 237 213 / 0.25) !important;
}

.tw-bg-orange-100\/30 {
  background-color: rgb(255 237 213 / 0.3) !important;
}

.tw-bg-orange-100\/35 {
  background-color: rgb(255 237 213 / 0.35) !important;
}

.tw-bg-orange-100\/40 {
  background-color: rgb(255 237 213 / 0.4) !important;
}

.tw-bg-orange-100\/45 {
  background-color: rgb(255 237 213 / 0.45) !important;
}

.tw-bg-orange-100\/5 {
  background-color: rgb(255 237 213 / 0.05) !important;
}

.tw-bg-orange-100\/50 {
  background-color: rgb(255 237 213 / 0.5) !important;
}

.tw-bg-orange-100\/55 {
  background-color: rgb(255 237 213 / 0.55) !important;
}

.tw-bg-orange-100\/60 {
  background-color: rgb(255 237 213 / 0.6) !important;
}

.tw-bg-orange-100\/65 {
  background-color: rgb(255 237 213 / 0.65) !important;
}

.tw-bg-orange-100\/70 {
  background-color: rgb(255 237 213 / 0.7) !important;
}

.tw-bg-orange-100\/75 {
  background-color: rgb(255 237 213 / 0.75) !important;
}

.tw-bg-orange-100\/80 {
  background-color: rgb(255 237 213 / 0.8) !important;
}

.tw-bg-orange-100\/85 {
  background-color: rgb(255 237 213 / 0.85) !important;
}

.tw-bg-orange-100\/90 {
  background-color: rgb(255 237 213 / 0.9) !important;
}

.tw-bg-orange-100\/95 {
  background-color: rgb(255 237 213 / 0.95) !important;
}

.tw-bg-orange-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 215 170 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-200\/0 {
  background-color: rgb(254 215 170 / 0) !important;
}

.tw-bg-orange-200\/10 {
  background-color: rgb(254 215 170 / 0.1) !important;
}

.tw-bg-orange-200\/100 {
  background-color: rgb(254 215 170 / 1) !important;
}

.tw-bg-orange-200\/15 {
  background-color: rgb(254 215 170 / 0.15) !important;
}

.tw-bg-orange-200\/20 {
  background-color: rgb(254 215 170 / 0.2) !important;
}

.tw-bg-orange-200\/25 {
  background-color: rgb(254 215 170 / 0.25) !important;
}

.tw-bg-orange-200\/30 {
  background-color: rgb(254 215 170 / 0.3) !important;
}

.tw-bg-orange-200\/35 {
  background-color: rgb(254 215 170 / 0.35) !important;
}

.tw-bg-orange-200\/40 {
  background-color: rgb(254 215 170 / 0.4) !important;
}

.tw-bg-orange-200\/45 {
  background-color: rgb(254 215 170 / 0.45) !important;
}

.tw-bg-orange-200\/5 {
  background-color: rgb(254 215 170 / 0.05) !important;
}

.tw-bg-orange-200\/50 {
  background-color: rgb(254 215 170 / 0.5) !important;
}

.tw-bg-orange-200\/55 {
  background-color: rgb(254 215 170 / 0.55) !important;
}

.tw-bg-orange-200\/60 {
  background-color: rgb(254 215 170 / 0.6) !important;
}

.tw-bg-orange-200\/65 {
  background-color: rgb(254 215 170 / 0.65) !important;
}

.tw-bg-orange-200\/70 {
  background-color: rgb(254 215 170 / 0.7) !important;
}

.tw-bg-orange-200\/75 {
  background-color: rgb(254 215 170 / 0.75) !important;
}

.tw-bg-orange-200\/80 {
  background-color: rgb(254 215 170 / 0.8) !important;
}

.tw-bg-orange-200\/85 {
  background-color: rgb(254 215 170 / 0.85) !important;
}

.tw-bg-orange-200\/90 {
  background-color: rgb(254 215 170 / 0.9) !important;
}

.tw-bg-orange-200\/95 {
  background-color: rgb(254 215 170 / 0.95) !important;
}

.tw-bg-orange-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 186 116 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-300\/0 {
  background-color: rgb(253 186 116 / 0) !important;
}

.tw-bg-orange-300\/10 {
  background-color: rgb(253 186 116 / 0.1) !important;
}

.tw-bg-orange-300\/100 {
  background-color: rgb(253 186 116 / 1) !important;
}

.tw-bg-orange-300\/15 {
  background-color: rgb(253 186 116 / 0.15) !important;
}

.tw-bg-orange-300\/20 {
  background-color: rgb(253 186 116 / 0.2) !important;
}

.tw-bg-orange-300\/25 {
  background-color: rgb(253 186 116 / 0.25) !important;
}

.tw-bg-orange-300\/30 {
  background-color: rgb(253 186 116 / 0.3) !important;
}

.tw-bg-orange-300\/35 {
  background-color: rgb(253 186 116 / 0.35) !important;
}

.tw-bg-orange-300\/40 {
  background-color: rgb(253 186 116 / 0.4) !important;
}

.tw-bg-orange-300\/45 {
  background-color: rgb(253 186 116 / 0.45) !important;
}

.tw-bg-orange-300\/5 {
  background-color: rgb(253 186 116 / 0.05) !important;
}

.tw-bg-orange-300\/50 {
  background-color: rgb(253 186 116 / 0.5) !important;
}

.tw-bg-orange-300\/55 {
  background-color: rgb(253 186 116 / 0.55) !important;
}

.tw-bg-orange-300\/60 {
  background-color: rgb(253 186 116 / 0.6) !important;
}

.tw-bg-orange-300\/65 {
  background-color: rgb(253 186 116 / 0.65) !important;
}

.tw-bg-orange-300\/70 {
  background-color: rgb(253 186 116 / 0.7) !important;
}

.tw-bg-orange-300\/75 {
  background-color: rgb(253 186 116 / 0.75) !important;
}

.tw-bg-orange-300\/80 {
  background-color: rgb(253 186 116 / 0.8) !important;
}

.tw-bg-orange-300\/85 {
  background-color: rgb(253 186 116 / 0.85) !important;
}

.tw-bg-orange-300\/90 {
  background-color: rgb(253 186 116 / 0.9) !important;
}

.tw-bg-orange-300\/95 {
  background-color: rgb(253 186 116 / 0.95) !important;
}

.tw-bg-orange-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(251 146 60 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-400\/0 {
  background-color: rgb(251 146 60 / 0) !important;
}

.tw-bg-orange-400\/10 {
  background-color: rgb(251 146 60 / 0.1) !important;
}

.tw-bg-orange-400\/100 {
  background-color: rgb(251 146 60 / 1) !important;
}

.tw-bg-orange-400\/15 {
  background-color: rgb(251 146 60 / 0.15) !important;
}

.tw-bg-orange-400\/20 {
  background-color: rgb(251 146 60 / 0.2) !important;
}

.tw-bg-orange-400\/25 {
  background-color: rgb(251 146 60 / 0.25) !important;
}

.tw-bg-orange-400\/30 {
  background-color: rgb(251 146 60 / 0.3) !important;
}

.tw-bg-orange-400\/35 {
  background-color: rgb(251 146 60 / 0.35) !important;
}

.tw-bg-orange-400\/40 {
  background-color: rgb(251 146 60 / 0.4) !important;
}

.tw-bg-orange-400\/45 {
  background-color: rgb(251 146 60 / 0.45) !important;
}

.tw-bg-orange-400\/5 {
  background-color: rgb(251 146 60 / 0.05) !important;
}

.tw-bg-orange-400\/50 {
  background-color: rgb(251 146 60 / 0.5) !important;
}

.tw-bg-orange-400\/55 {
  background-color: rgb(251 146 60 / 0.55) !important;
}

.tw-bg-orange-400\/60 {
  background-color: rgb(251 146 60 / 0.6) !important;
}

.tw-bg-orange-400\/65 {
  background-color: rgb(251 146 60 / 0.65) !important;
}

.tw-bg-orange-400\/70 {
  background-color: rgb(251 146 60 / 0.7) !important;
}

.tw-bg-orange-400\/75 {
  background-color: rgb(251 146 60 / 0.75) !important;
}

.tw-bg-orange-400\/80 {
  background-color: rgb(251 146 60 / 0.8) !important;
}

.tw-bg-orange-400\/85 {
  background-color: rgb(251 146 60 / 0.85) !important;
}

.tw-bg-orange-400\/90 {
  background-color: rgb(251 146 60 / 0.9) !important;
}

.tw-bg-orange-400\/95 {
  background-color: rgb(251 146 60 / 0.95) !important;
}

.tw-bg-orange-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 247 237 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-50\/0 {
  background-color: rgb(255 247 237 / 0) !important;
}

.tw-bg-orange-50\/10 {
  background-color: rgb(255 247 237 / 0.1) !important;
}

.tw-bg-orange-50\/100 {
  background-color: rgb(255 247 237 / 1) !important;
}

.tw-bg-orange-50\/15 {
  background-color: rgb(255 247 237 / 0.15) !important;
}

.tw-bg-orange-50\/20 {
  background-color: rgb(255 247 237 / 0.2) !important;
}

.tw-bg-orange-50\/25 {
  background-color: rgb(255 247 237 / 0.25) !important;
}

.tw-bg-orange-50\/30 {
  background-color: rgb(255 247 237 / 0.3) !important;
}

.tw-bg-orange-50\/35 {
  background-color: rgb(255 247 237 / 0.35) !important;
}

.tw-bg-orange-50\/40 {
  background-color: rgb(255 247 237 / 0.4) !important;
}

.tw-bg-orange-50\/45 {
  background-color: rgb(255 247 237 / 0.45) !important;
}

.tw-bg-orange-50\/5 {
  background-color: rgb(255 247 237 / 0.05) !important;
}

.tw-bg-orange-50\/50 {
  background-color: rgb(255 247 237 / 0.5) !important;
}

.tw-bg-orange-50\/55 {
  background-color: rgb(255 247 237 / 0.55) !important;
}

.tw-bg-orange-50\/60 {
  background-color: rgb(255 247 237 / 0.6) !important;
}

.tw-bg-orange-50\/65 {
  background-color: rgb(255 247 237 / 0.65) !important;
}

.tw-bg-orange-50\/70 {
  background-color: rgb(255 247 237 / 0.7) !important;
}

.tw-bg-orange-50\/75 {
  background-color: rgb(255 247 237 / 0.75) !important;
}

.tw-bg-orange-50\/80 {
  background-color: rgb(255 247 237 / 0.8) !important;
}

.tw-bg-orange-50\/85 {
  background-color: rgb(255 247 237 / 0.85) !important;
}

.tw-bg-orange-50\/90 {
  background-color: rgb(255 247 237 / 0.9) !important;
}

.tw-bg-orange-50\/95 {
  background-color: rgb(255 247 237 / 0.95) !important;
}

.tw-bg-orange-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 115 22 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-500\/0 {
  background-color: rgb(249 115 22 / 0) !important;
}

.tw-bg-orange-500\/10 {
  background-color: rgb(249 115 22 / 0.1) !important;
}

.tw-bg-orange-500\/100 {
  background-color: rgb(249 115 22 / 1) !important;
}

.tw-bg-orange-500\/15 {
  background-color: rgb(249 115 22 / 0.15) !important;
}

.tw-bg-orange-500\/20 {
  background-color: rgb(249 115 22 / 0.2) !important;
}

.tw-bg-orange-500\/25 {
  background-color: rgb(249 115 22 / 0.25) !important;
}

.tw-bg-orange-500\/30 {
  background-color: rgb(249 115 22 / 0.3) !important;
}

.tw-bg-orange-500\/35 {
  background-color: rgb(249 115 22 / 0.35) !important;
}

.tw-bg-orange-500\/40 {
  background-color: rgb(249 115 22 / 0.4) !important;
}

.tw-bg-orange-500\/45 {
  background-color: rgb(249 115 22 / 0.45) !important;
}

.tw-bg-orange-500\/5 {
  background-color: rgb(249 115 22 / 0.05) !important;
}

.tw-bg-orange-500\/50 {
  background-color: rgb(249 115 22 / 0.5) !important;
}

.tw-bg-orange-500\/55 {
  background-color: rgb(249 115 22 / 0.55) !important;
}

.tw-bg-orange-500\/60 {
  background-color: rgb(249 115 22 / 0.6) !important;
}

.tw-bg-orange-500\/65 {
  background-color: rgb(249 115 22 / 0.65) !important;
}

.tw-bg-orange-500\/70 {
  background-color: rgb(249 115 22 / 0.7) !important;
}

.tw-bg-orange-500\/75 {
  background-color: rgb(249 115 22 / 0.75) !important;
}

.tw-bg-orange-500\/80 {
  background-color: rgb(249 115 22 / 0.8) !important;
}

.tw-bg-orange-500\/85 {
  background-color: rgb(249 115 22 / 0.85) !important;
}

.tw-bg-orange-500\/90 {
  background-color: rgb(249 115 22 / 0.9) !important;
}

.tw-bg-orange-500\/95 {
  background-color: rgb(249 115 22 / 0.95) !important;
}

.tw-bg-orange-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 88 12 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-600\/0 {
  background-color: rgb(234 88 12 / 0) !important;
}

.tw-bg-orange-600\/10 {
  background-color: rgb(234 88 12 / 0.1) !important;
}

.tw-bg-orange-600\/100 {
  background-color: rgb(234 88 12 / 1) !important;
}

.tw-bg-orange-600\/15 {
  background-color: rgb(234 88 12 / 0.15) !important;
}

.tw-bg-orange-600\/20 {
  background-color: rgb(234 88 12 / 0.2) !important;
}

.tw-bg-orange-600\/25 {
  background-color: rgb(234 88 12 / 0.25) !important;
}

.tw-bg-orange-600\/30 {
  background-color: rgb(234 88 12 / 0.3) !important;
}

.tw-bg-orange-600\/35 {
  background-color: rgb(234 88 12 / 0.35) !important;
}

.tw-bg-orange-600\/40 {
  background-color: rgb(234 88 12 / 0.4) !important;
}

.tw-bg-orange-600\/45 {
  background-color: rgb(234 88 12 / 0.45) !important;
}

.tw-bg-orange-600\/5 {
  background-color: rgb(234 88 12 / 0.05) !important;
}

.tw-bg-orange-600\/50 {
  background-color: rgb(234 88 12 / 0.5) !important;
}

.tw-bg-orange-600\/55 {
  background-color: rgb(234 88 12 / 0.55) !important;
}

.tw-bg-orange-600\/60 {
  background-color: rgb(234 88 12 / 0.6) !important;
}

.tw-bg-orange-600\/65 {
  background-color: rgb(234 88 12 / 0.65) !important;
}

.tw-bg-orange-600\/70 {
  background-color: rgb(234 88 12 / 0.7) !important;
}

.tw-bg-orange-600\/75 {
  background-color: rgb(234 88 12 / 0.75) !important;
}

.tw-bg-orange-600\/80 {
  background-color: rgb(234 88 12 / 0.8) !important;
}

.tw-bg-orange-600\/85 {
  background-color: rgb(234 88 12 / 0.85) !important;
}

.tw-bg-orange-600\/90 {
  background-color: rgb(234 88 12 / 0.9) !important;
}

.tw-bg-orange-600\/95 {
  background-color: rgb(234 88 12 / 0.95) !important;
}

.tw-bg-orange-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(194 65 12 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-700\/0 {
  background-color: rgb(194 65 12 / 0) !important;
}

.tw-bg-orange-700\/10 {
  background-color: rgb(194 65 12 / 0.1) !important;
}

.tw-bg-orange-700\/100 {
  background-color: rgb(194 65 12 / 1) !important;
}

.tw-bg-orange-700\/15 {
  background-color: rgb(194 65 12 / 0.15) !important;
}

.tw-bg-orange-700\/20 {
  background-color: rgb(194 65 12 / 0.2) !important;
}

.tw-bg-orange-700\/25 {
  background-color: rgb(194 65 12 / 0.25) !important;
}

.tw-bg-orange-700\/30 {
  background-color: rgb(194 65 12 / 0.3) !important;
}

.tw-bg-orange-700\/35 {
  background-color: rgb(194 65 12 / 0.35) !important;
}

.tw-bg-orange-700\/40 {
  background-color: rgb(194 65 12 / 0.4) !important;
}

.tw-bg-orange-700\/45 {
  background-color: rgb(194 65 12 / 0.45) !important;
}

.tw-bg-orange-700\/5 {
  background-color: rgb(194 65 12 / 0.05) !important;
}

.tw-bg-orange-700\/50 {
  background-color: rgb(194 65 12 / 0.5) !important;
}

.tw-bg-orange-700\/55 {
  background-color: rgb(194 65 12 / 0.55) !important;
}

.tw-bg-orange-700\/60 {
  background-color: rgb(194 65 12 / 0.6) !important;
}

.tw-bg-orange-700\/65 {
  background-color: rgb(194 65 12 / 0.65) !important;
}

.tw-bg-orange-700\/70 {
  background-color: rgb(194 65 12 / 0.7) !important;
}

.tw-bg-orange-700\/75 {
  background-color: rgb(194 65 12 / 0.75) !important;
}

.tw-bg-orange-700\/80 {
  background-color: rgb(194 65 12 / 0.8) !important;
}

.tw-bg-orange-700\/85 {
  background-color: rgb(194 65 12 / 0.85) !important;
}

.tw-bg-orange-700\/90 {
  background-color: rgb(194 65 12 / 0.9) !important;
}

.tw-bg-orange-700\/95 {
  background-color: rgb(194 65 12 / 0.95) !important;
}

.tw-bg-orange-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(154 52 18 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-800\/0 {
  background-color: rgb(154 52 18 / 0) !important;
}

.tw-bg-orange-800\/10 {
  background-color: rgb(154 52 18 / 0.1) !important;
}

.tw-bg-orange-800\/100 {
  background-color: rgb(154 52 18 / 1) !important;
}

.tw-bg-orange-800\/15 {
  background-color: rgb(154 52 18 / 0.15) !important;
}

.tw-bg-orange-800\/20 {
  background-color: rgb(154 52 18 / 0.2) !important;
}

.tw-bg-orange-800\/25 {
  background-color: rgb(154 52 18 / 0.25) !important;
}

.tw-bg-orange-800\/30 {
  background-color: rgb(154 52 18 / 0.3) !important;
}

.tw-bg-orange-800\/35 {
  background-color: rgb(154 52 18 / 0.35) !important;
}

.tw-bg-orange-800\/40 {
  background-color: rgb(154 52 18 / 0.4) !important;
}

.tw-bg-orange-800\/45 {
  background-color: rgb(154 52 18 / 0.45) !important;
}

.tw-bg-orange-800\/5 {
  background-color: rgb(154 52 18 / 0.05) !important;
}

.tw-bg-orange-800\/50 {
  background-color: rgb(154 52 18 / 0.5) !important;
}

.tw-bg-orange-800\/55 {
  background-color: rgb(154 52 18 / 0.55) !important;
}

.tw-bg-orange-800\/60 {
  background-color: rgb(154 52 18 / 0.6) !important;
}

.tw-bg-orange-800\/65 {
  background-color: rgb(154 52 18 / 0.65) !important;
}

.tw-bg-orange-800\/70 {
  background-color: rgb(154 52 18 / 0.7) !important;
}

.tw-bg-orange-800\/75 {
  background-color: rgb(154 52 18 / 0.75) !important;
}

.tw-bg-orange-800\/80 {
  background-color: rgb(154 52 18 / 0.8) !important;
}

.tw-bg-orange-800\/85 {
  background-color: rgb(154 52 18 / 0.85) !important;
}

.tw-bg-orange-800\/90 {
  background-color: rgb(154 52 18 / 0.9) !important;
}

.tw-bg-orange-800\/95 {
  background-color: rgb(154 52 18 / 0.95) !important;
}

.tw-bg-orange-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(124 45 18 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-900\/0 {
  background-color: rgb(124 45 18 / 0) !important;
}

.tw-bg-orange-900\/10 {
  background-color: rgb(124 45 18 / 0.1) !important;
}

.tw-bg-orange-900\/100 {
  background-color: rgb(124 45 18 / 1) !important;
}

.tw-bg-orange-900\/15 {
  background-color: rgb(124 45 18 / 0.15) !important;
}

.tw-bg-orange-900\/20 {
  background-color: rgb(124 45 18 / 0.2) !important;
}

.tw-bg-orange-900\/25 {
  background-color: rgb(124 45 18 / 0.25) !important;
}

.tw-bg-orange-900\/30 {
  background-color: rgb(124 45 18 / 0.3) !important;
}

.tw-bg-orange-900\/35 {
  background-color: rgb(124 45 18 / 0.35) !important;
}

.tw-bg-orange-900\/40 {
  background-color: rgb(124 45 18 / 0.4) !important;
}

.tw-bg-orange-900\/45 {
  background-color: rgb(124 45 18 / 0.45) !important;
}

.tw-bg-orange-900\/5 {
  background-color: rgb(124 45 18 / 0.05) !important;
}

.tw-bg-orange-900\/50 {
  background-color: rgb(124 45 18 / 0.5) !important;
}

.tw-bg-orange-900\/55 {
  background-color: rgb(124 45 18 / 0.55) !important;
}

.tw-bg-orange-900\/60 {
  background-color: rgb(124 45 18 / 0.6) !important;
}

.tw-bg-orange-900\/65 {
  background-color: rgb(124 45 18 / 0.65) !important;
}

.tw-bg-orange-900\/70 {
  background-color: rgb(124 45 18 / 0.7) !important;
}

.tw-bg-orange-900\/75 {
  background-color: rgb(124 45 18 / 0.75) !important;
}

.tw-bg-orange-900\/80 {
  background-color: rgb(124 45 18 / 0.8) !important;
}

.tw-bg-orange-900\/85 {
  background-color: rgb(124 45 18 / 0.85) !important;
}

.tw-bg-orange-900\/90 {
  background-color: rgb(124 45 18 / 0.9) !important;
}

.tw-bg-orange-900\/95 {
  background-color: rgb(124 45 18 / 0.95) !important;
}

.tw-bg-orange-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(67 20 7 / var(--tw-bg-opacity)) !important;
}

.tw-bg-orange-950\/0 {
  background-color: rgb(67 20 7 / 0) !important;
}

.tw-bg-orange-950\/10 {
  background-color: rgb(67 20 7 / 0.1) !important;
}

.tw-bg-orange-950\/100 {
  background-color: rgb(67 20 7 / 1) !important;
}

.tw-bg-orange-950\/15 {
  background-color: rgb(67 20 7 / 0.15) !important;
}

.tw-bg-orange-950\/20 {
  background-color: rgb(67 20 7 / 0.2) !important;
}

.tw-bg-orange-950\/25 {
  background-color: rgb(67 20 7 / 0.25) !important;
}

.tw-bg-orange-950\/30 {
  background-color: rgb(67 20 7 / 0.3) !important;
}

.tw-bg-orange-950\/35 {
  background-color: rgb(67 20 7 / 0.35) !important;
}

.tw-bg-orange-950\/40 {
  background-color: rgb(67 20 7 / 0.4) !important;
}

.tw-bg-orange-950\/45 {
  background-color: rgb(67 20 7 / 0.45) !important;
}

.tw-bg-orange-950\/5 {
  background-color: rgb(67 20 7 / 0.05) !important;
}

.tw-bg-orange-950\/50 {
  background-color: rgb(67 20 7 / 0.5) !important;
}

.tw-bg-orange-950\/55 {
  background-color: rgb(67 20 7 / 0.55) !important;
}

.tw-bg-orange-950\/60 {
  background-color: rgb(67 20 7 / 0.6) !important;
}

.tw-bg-orange-950\/65 {
  background-color: rgb(67 20 7 / 0.65) !important;
}

.tw-bg-orange-950\/70 {
  background-color: rgb(67 20 7 / 0.7) !important;
}

.tw-bg-orange-950\/75 {
  background-color: rgb(67 20 7 / 0.75) !important;
}

.tw-bg-orange-950\/80 {
  background-color: rgb(67 20 7 / 0.8) !important;
}

.tw-bg-orange-950\/85 {
  background-color: rgb(67 20 7 / 0.85) !important;
}

.tw-bg-orange-950\/90 {
  background-color: rgb(67 20 7 / 0.9) !important;
}

.tw-bg-orange-950\/95 {
  background-color: rgb(67 20 7 / 0.95) !important;
}

.tw-bg-pink-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 231 243 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-100\/0 {
  background-color: rgb(252 231 243 / 0) !important;
}

.tw-bg-pink-100\/10 {
  background-color: rgb(252 231 243 / 0.1) !important;
}

.tw-bg-pink-100\/100 {
  background-color: rgb(252 231 243 / 1) !important;
}

.tw-bg-pink-100\/15 {
  background-color: rgb(252 231 243 / 0.15) !important;
}

.tw-bg-pink-100\/20 {
  background-color: rgb(252 231 243 / 0.2) !important;
}

.tw-bg-pink-100\/25 {
  background-color: rgb(252 231 243 / 0.25) !important;
}

.tw-bg-pink-100\/30 {
  background-color: rgb(252 231 243 / 0.3) !important;
}

.tw-bg-pink-100\/35 {
  background-color: rgb(252 231 243 / 0.35) !important;
}

.tw-bg-pink-100\/40 {
  background-color: rgb(252 231 243 / 0.4) !important;
}

.tw-bg-pink-100\/45 {
  background-color: rgb(252 231 243 / 0.45) !important;
}

.tw-bg-pink-100\/5 {
  background-color: rgb(252 231 243 / 0.05) !important;
}

.tw-bg-pink-100\/50 {
  background-color: rgb(252 231 243 / 0.5) !important;
}

.tw-bg-pink-100\/55 {
  background-color: rgb(252 231 243 / 0.55) !important;
}

.tw-bg-pink-100\/60 {
  background-color: rgb(252 231 243 / 0.6) !important;
}

.tw-bg-pink-100\/65 {
  background-color: rgb(252 231 243 / 0.65) !important;
}

.tw-bg-pink-100\/70 {
  background-color: rgb(252 231 243 / 0.7) !important;
}

.tw-bg-pink-100\/75 {
  background-color: rgb(252 231 243 / 0.75) !important;
}

.tw-bg-pink-100\/80 {
  background-color: rgb(252 231 243 / 0.8) !important;
}

.tw-bg-pink-100\/85 {
  background-color: rgb(252 231 243 / 0.85) !important;
}

.tw-bg-pink-100\/90 {
  background-color: rgb(252 231 243 / 0.9) !important;
}

.tw-bg-pink-100\/95 {
  background-color: rgb(252 231 243 / 0.95) !important;
}

.tw-bg-pink-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(251 207 232 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-200\/0 {
  background-color: rgb(251 207 232 / 0) !important;
}

.tw-bg-pink-200\/10 {
  background-color: rgb(251 207 232 / 0.1) !important;
}

.tw-bg-pink-200\/100 {
  background-color: rgb(251 207 232 / 1) !important;
}

.tw-bg-pink-200\/15 {
  background-color: rgb(251 207 232 / 0.15) !important;
}

.tw-bg-pink-200\/20 {
  background-color: rgb(251 207 232 / 0.2) !important;
}

.tw-bg-pink-200\/25 {
  background-color: rgb(251 207 232 / 0.25) !important;
}

.tw-bg-pink-200\/30 {
  background-color: rgb(251 207 232 / 0.3) !important;
}

.tw-bg-pink-200\/35 {
  background-color: rgb(251 207 232 / 0.35) !important;
}

.tw-bg-pink-200\/40 {
  background-color: rgb(251 207 232 / 0.4) !important;
}

.tw-bg-pink-200\/45 {
  background-color: rgb(251 207 232 / 0.45) !important;
}

.tw-bg-pink-200\/5 {
  background-color: rgb(251 207 232 / 0.05) !important;
}

.tw-bg-pink-200\/50 {
  background-color: rgb(251 207 232 / 0.5) !important;
}

.tw-bg-pink-200\/55 {
  background-color: rgb(251 207 232 / 0.55) !important;
}

.tw-bg-pink-200\/60 {
  background-color: rgb(251 207 232 / 0.6) !important;
}

.tw-bg-pink-200\/65 {
  background-color: rgb(251 207 232 / 0.65) !important;
}

.tw-bg-pink-200\/70 {
  background-color: rgb(251 207 232 / 0.7) !important;
}

.tw-bg-pink-200\/75 {
  background-color: rgb(251 207 232 / 0.75) !important;
}

.tw-bg-pink-200\/80 {
  background-color: rgb(251 207 232 / 0.8) !important;
}

.tw-bg-pink-200\/85 {
  background-color: rgb(251 207 232 / 0.85) !important;
}

.tw-bg-pink-200\/90 {
  background-color: rgb(251 207 232 / 0.9) !important;
}

.tw-bg-pink-200\/95 {
  background-color: rgb(251 207 232 / 0.95) !important;
}

.tw-bg-pink-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(249 168 212 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-300\/0 {
  background-color: rgb(249 168 212 / 0) !important;
}

.tw-bg-pink-300\/10 {
  background-color: rgb(249 168 212 / 0.1) !important;
}

.tw-bg-pink-300\/100 {
  background-color: rgb(249 168 212 / 1) !important;
}

.tw-bg-pink-300\/15 {
  background-color: rgb(249 168 212 / 0.15) !important;
}

.tw-bg-pink-300\/20 {
  background-color: rgb(249 168 212 / 0.2) !important;
}

.tw-bg-pink-300\/25 {
  background-color: rgb(249 168 212 / 0.25) !important;
}

.tw-bg-pink-300\/30 {
  background-color: rgb(249 168 212 / 0.3) !important;
}

.tw-bg-pink-300\/35 {
  background-color: rgb(249 168 212 / 0.35) !important;
}

.tw-bg-pink-300\/40 {
  background-color: rgb(249 168 212 / 0.4) !important;
}

.tw-bg-pink-300\/45 {
  background-color: rgb(249 168 212 / 0.45) !important;
}

.tw-bg-pink-300\/5 {
  background-color: rgb(249 168 212 / 0.05) !important;
}

.tw-bg-pink-300\/50 {
  background-color: rgb(249 168 212 / 0.5) !important;
}

.tw-bg-pink-300\/55 {
  background-color: rgb(249 168 212 / 0.55) !important;
}

.tw-bg-pink-300\/60 {
  background-color: rgb(249 168 212 / 0.6) !important;
}

.tw-bg-pink-300\/65 {
  background-color: rgb(249 168 212 / 0.65) !important;
}

.tw-bg-pink-300\/70 {
  background-color: rgb(249 168 212 / 0.7) !important;
}

.tw-bg-pink-300\/75 {
  background-color: rgb(249 168 212 / 0.75) !important;
}

.tw-bg-pink-300\/80 {
  background-color: rgb(249 168 212 / 0.8) !important;
}

.tw-bg-pink-300\/85 {
  background-color: rgb(249 168 212 / 0.85) !important;
}

.tw-bg-pink-300\/90 {
  background-color: rgb(249 168 212 / 0.9) !important;
}

.tw-bg-pink-300\/95 {
  background-color: rgb(249 168 212 / 0.95) !important;
}

.tw-bg-pink-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 114 182 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-400\/0 {
  background-color: rgb(244 114 182 / 0) !important;
}

.tw-bg-pink-400\/10 {
  background-color: rgb(244 114 182 / 0.1) !important;
}

.tw-bg-pink-400\/100 {
  background-color: rgb(244 114 182 / 1) !important;
}

.tw-bg-pink-400\/15 {
  background-color: rgb(244 114 182 / 0.15) !important;
}

.tw-bg-pink-400\/20 {
  background-color: rgb(244 114 182 / 0.2) !important;
}

.tw-bg-pink-400\/25 {
  background-color: rgb(244 114 182 / 0.25) !important;
}

.tw-bg-pink-400\/30 {
  background-color: rgb(244 114 182 / 0.3) !important;
}

.tw-bg-pink-400\/35 {
  background-color: rgb(244 114 182 / 0.35) !important;
}

.tw-bg-pink-400\/40 {
  background-color: rgb(244 114 182 / 0.4) !important;
}

.tw-bg-pink-400\/45 {
  background-color: rgb(244 114 182 / 0.45) !important;
}

.tw-bg-pink-400\/5 {
  background-color: rgb(244 114 182 / 0.05) !important;
}

.tw-bg-pink-400\/50 {
  background-color: rgb(244 114 182 / 0.5) !important;
}

.tw-bg-pink-400\/55 {
  background-color: rgb(244 114 182 / 0.55) !important;
}

.tw-bg-pink-400\/60 {
  background-color: rgb(244 114 182 / 0.6) !important;
}

.tw-bg-pink-400\/65 {
  background-color: rgb(244 114 182 / 0.65) !important;
}

.tw-bg-pink-400\/70 {
  background-color: rgb(244 114 182 / 0.7) !important;
}

.tw-bg-pink-400\/75 {
  background-color: rgb(244 114 182 / 0.75) !important;
}

.tw-bg-pink-400\/80 {
  background-color: rgb(244 114 182 / 0.8) !important;
}

.tw-bg-pink-400\/85 {
  background-color: rgb(244 114 182 / 0.85) !important;
}

.tw-bg-pink-400\/90 {
  background-color: rgb(244 114 182 / 0.9) !important;
}

.tw-bg-pink-400\/95 {
  background-color: rgb(244 114 182 / 0.95) !important;
}

.tw-bg-pink-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 242 248 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-50\/0 {
  background-color: rgb(253 242 248 / 0) !important;
}

.tw-bg-pink-50\/10 {
  background-color: rgb(253 242 248 / 0.1) !important;
}

.tw-bg-pink-50\/100 {
  background-color: rgb(253 242 248 / 1) !important;
}

.tw-bg-pink-50\/15 {
  background-color: rgb(253 242 248 / 0.15) !important;
}

.tw-bg-pink-50\/20 {
  background-color: rgb(253 242 248 / 0.2) !important;
}

.tw-bg-pink-50\/25 {
  background-color: rgb(253 242 248 / 0.25) !important;
}

.tw-bg-pink-50\/30 {
  background-color: rgb(253 242 248 / 0.3) !important;
}

.tw-bg-pink-50\/35 {
  background-color: rgb(253 242 248 / 0.35) !important;
}

.tw-bg-pink-50\/40 {
  background-color: rgb(253 242 248 / 0.4) !important;
}

.tw-bg-pink-50\/45 {
  background-color: rgb(253 242 248 / 0.45) !important;
}

.tw-bg-pink-50\/5 {
  background-color: rgb(253 242 248 / 0.05) !important;
}

.tw-bg-pink-50\/50 {
  background-color: rgb(253 242 248 / 0.5) !important;
}

.tw-bg-pink-50\/55 {
  background-color: rgb(253 242 248 / 0.55) !important;
}

.tw-bg-pink-50\/60 {
  background-color: rgb(253 242 248 / 0.6) !important;
}

.tw-bg-pink-50\/65 {
  background-color: rgb(253 242 248 / 0.65) !important;
}

.tw-bg-pink-50\/70 {
  background-color: rgb(253 242 248 / 0.7) !important;
}

.tw-bg-pink-50\/75 {
  background-color: rgb(253 242 248 / 0.75) !important;
}

.tw-bg-pink-50\/80 {
  background-color: rgb(253 242 248 / 0.8) !important;
}

.tw-bg-pink-50\/85 {
  background-color: rgb(253 242 248 / 0.85) !important;
}

.tw-bg-pink-50\/90 {
  background-color: rgb(253 242 248 / 0.9) !important;
}

.tw-bg-pink-50\/95 {
  background-color: rgb(253 242 248 / 0.95) !important;
}

.tw-bg-pink-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(236 72 153 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-500\/0 {
  background-color: rgb(236 72 153 / 0) !important;
}

.tw-bg-pink-500\/10 {
  background-color: rgb(236 72 153 / 0.1) !important;
}

.tw-bg-pink-500\/100 {
  background-color: rgb(236 72 153 / 1) !important;
}

.tw-bg-pink-500\/15 {
  background-color: rgb(236 72 153 / 0.15) !important;
}

.tw-bg-pink-500\/20 {
  background-color: rgb(236 72 153 / 0.2) !important;
}

.tw-bg-pink-500\/25 {
  background-color: rgb(236 72 153 / 0.25) !important;
}

.tw-bg-pink-500\/30 {
  background-color: rgb(236 72 153 / 0.3) !important;
}

.tw-bg-pink-500\/35 {
  background-color: rgb(236 72 153 / 0.35) !important;
}

.tw-bg-pink-500\/40 {
  background-color: rgb(236 72 153 / 0.4) !important;
}

.tw-bg-pink-500\/45 {
  background-color: rgb(236 72 153 / 0.45) !important;
}

.tw-bg-pink-500\/5 {
  background-color: rgb(236 72 153 / 0.05) !important;
}

.tw-bg-pink-500\/50 {
  background-color: rgb(236 72 153 / 0.5) !important;
}

.tw-bg-pink-500\/55 {
  background-color: rgb(236 72 153 / 0.55) !important;
}

.tw-bg-pink-500\/60 {
  background-color: rgb(236 72 153 / 0.6) !important;
}

.tw-bg-pink-500\/65 {
  background-color: rgb(236 72 153 / 0.65) !important;
}

.tw-bg-pink-500\/70 {
  background-color: rgb(236 72 153 / 0.7) !important;
}

.tw-bg-pink-500\/75 {
  background-color: rgb(236 72 153 / 0.75) !important;
}

.tw-bg-pink-500\/80 {
  background-color: rgb(236 72 153 / 0.8) !important;
}

.tw-bg-pink-500\/85 {
  background-color: rgb(236 72 153 / 0.85) !important;
}

.tw-bg-pink-500\/90 {
  background-color: rgb(236 72 153 / 0.9) !important;
}

.tw-bg-pink-500\/95 {
  background-color: rgb(236 72 153 / 0.95) !important;
}

.tw-bg-pink-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(219 39 119 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-600\/0 {
  background-color: rgb(219 39 119 / 0) !important;
}

.tw-bg-pink-600\/10 {
  background-color: rgb(219 39 119 / 0.1) !important;
}

.tw-bg-pink-600\/100 {
  background-color: rgb(219 39 119 / 1) !important;
}

.tw-bg-pink-600\/15 {
  background-color: rgb(219 39 119 / 0.15) !important;
}

.tw-bg-pink-600\/20 {
  background-color: rgb(219 39 119 / 0.2) !important;
}

.tw-bg-pink-600\/25 {
  background-color: rgb(219 39 119 / 0.25) !important;
}

.tw-bg-pink-600\/30 {
  background-color: rgb(219 39 119 / 0.3) !important;
}

.tw-bg-pink-600\/35 {
  background-color: rgb(219 39 119 / 0.35) !important;
}

.tw-bg-pink-600\/40 {
  background-color: rgb(219 39 119 / 0.4) !important;
}

.tw-bg-pink-600\/45 {
  background-color: rgb(219 39 119 / 0.45) !important;
}

.tw-bg-pink-600\/5 {
  background-color: rgb(219 39 119 / 0.05) !important;
}

.tw-bg-pink-600\/50 {
  background-color: rgb(219 39 119 / 0.5) !important;
}

.tw-bg-pink-600\/55 {
  background-color: rgb(219 39 119 / 0.55) !important;
}

.tw-bg-pink-600\/60 {
  background-color: rgb(219 39 119 / 0.6) !important;
}

.tw-bg-pink-600\/65 {
  background-color: rgb(219 39 119 / 0.65) !important;
}

.tw-bg-pink-600\/70 {
  background-color: rgb(219 39 119 / 0.7) !important;
}

.tw-bg-pink-600\/75 {
  background-color: rgb(219 39 119 / 0.75) !important;
}

.tw-bg-pink-600\/80 {
  background-color: rgb(219 39 119 / 0.8) !important;
}

.tw-bg-pink-600\/85 {
  background-color: rgb(219 39 119 / 0.85) !important;
}

.tw-bg-pink-600\/90 {
  background-color: rgb(219 39 119 / 0.9) !important;
}

.tw-bg-pink-600\/95 {
  background-color: rgb(219 39 119 / 0.95) !important;
}

.tw-bg-pink-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(190 24 93 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-700\/0 {
  background-color: rgb(190 24 93 / 0) !important;
}

.tw-bg-pink-700\/10 {
  background-color: rgb(190 24 93 / 0.1) !important;
}

.tw-bg-pink-700\/100 {
  background-color: rgb(190 24 93 / 1) !important;
}

.tw-bg-pink-700\/15 {
  background-color: rgb(190 24 93 / 0.15) !important;
}

.tw-bg-pink-700\/20 {
  background-color: rgb(190 24 93 / 0.2) !important;
}

.tw-bg-pink-700\/25 {
  background-color: rgb(190 24 93 / 0.25) !important;
}

.tw-bg-pink-700\/30 {
  background-color: rgb(190 24 93 / 0.3) !important;
}

.tw-bg-pink-700\/35 {
  background-color: rgb(190 24 93 / 0.35) !important;
}

.tw-bg-pink-700\/40 {
  background-color: rgb(190 24 93 / 0.4) !important;
}

.tw-bg-pink-700\/45 {
  background-color: rgb(190 24 93 / 0.45) !important;
}

.tw-bg-pink-700\/5 {
  background-color: rgb(190 24 93 / 0.05) !important;
}

.tw-bg-pink-700\/50 {
  background-color: rgb(190 24 93 / 0.5) !important;
}

.tw-bg-pink-700\/55 {
  background-color: rgb(190 24 93 / 0.55) !important;
}

.tw-bg-pink-700\/60 {
  background-color: rgb(190 24 93 / 0.6) !important;
}

.tw-bg-pink-700\/65 {
  background-color: rgb(190 24 93 / 0.65) !important;
}

.tw-bg-pink-700\/70 {
  background-color: rgb(190 24 93 / 0.7) !important;
}

.tw-bg-pink-700\/75 {
  background-color: rgb(190 24 93 / 0.75) !important;
}

.tw-bg-pink-700\/80 {
  background-color: rgb(190 24 93 / 0.8) !important;
}

.tw-bg-pink-700\/85 {
  background-color: rgb(190 24 93 / 0.85) !important;
}

.tw-bg-pink-700\/90 {
  background-color: rgb(190 24 93 / 0.9) !important;
}

.tw-bg-pink-700\/95 {
  background-color: rgb(190 24 93 / 0.95) !important;
}

.tw-bg-pink-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(157 23 77 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-800\/0 {
  background-color: rgb(157 23 77 / 0) !important;
}

.tw-bg-pink-800\/10 {
  background-color: rgb(157 23 77 / 0.1) !important;
}

.tw-bg-pink-800\/100 {
  background-color: rgb(157 23 77 / 1) !important;
}

.tw-bg-pink-800\/15 {
  background-color: rgb(157 23 77 / 0.15) !important;
}

.tw-bg-pink-800\/20 {
  background-color: rgb(157 23 77 / 0.2) !important;
}

.tw-bg-pink-800\/25 {
  background-color: rgb(157 23 77 / 0.25) !important;
}

.tw-bg-pink-800\/30 {
  background-color: rgb(157 23 77 / 0.3) !important;
}

.tw-bg-pink-800\/35 {
  background-color: rgb(157 23 77 / 0.35) !important;
}

.tw-bg-pink-800\/40 {
  background-color: rgb(157 23 77 / 0.4) !important;
}

.tw-bg-pink-800\/45 {
  background-color: rgb(157 23 77 / 0.45) !important;
}

.tw-bg-pink-800\/5 {
  background-color: rgb(157 23 77 / 0.05) !important;
}

.tw-bg-pink-800\/50 {
  background-color: rgb(157 23 77 / 0.5) !important;
}

.tw-bg-pink-800\/55 {
  background-color: rgb(157 23 77 / 0.55) !important;
}

.tw-bg-pink-800\/60 {
  background-color: rgb(157 23 77 / 0.6) !important;
}

.tw-bg-pink-800\/65 {
  background-color: rgb(157 23 77 / 0.65) !important;
}

.tw-bg-pink-800\/70 {
  background-color: rgb(157 23 77 / 0.7) !important;
}

.tw-bg-pink-800\/75 {
  background-color: rgb(157 23 77 / 0.75) !important;
}

.tw-bg-pink-800\/80 {
  background-color: rgb(157 23 77 / 0.8) !important;
}

.tw-bg-pink-800\/85 {
  background-color: rgb(157 23 77 / 0.85) !important;
}

.tw-bg-pink-800\/90 {
  background-color: rgb(157 23 77 / 0.9) !important;
}

.tw-bg-pink-800\/95 {
  background-color: rgb(157 23 77 / 0.95) !important;
}

.tw-bg-pink-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(131 24 67 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-900\/0 {
  background-color: rgb(131 24 67 / 0) !important;
}

.tw-bg-pink-900\/10 {
  background-color: rgb(131 24 67 / 0.1) !important;
}

.tw-bg-pink-900\/100 {
  background-color: rgb(131 24 67 / 1) !important;
}

.tw-bg-pink-900\/15 {
  background-color: rgb(131 24 67 / 0.15) !important;
}

.tw-bg-pink-900\/20 {
  background-color: rgb(131 24 67 / 0.2) !important;
}

.tw-bg-pink-900\/25 {
  background-color: rgb(131 24 67 / 0.25) !important;
}

.tw-bg-pink-900\/30 {
  background-color: rgb(131 24 67 / 0.3) !important;
}

.tw-bg-pink-900\/35 {
  background-color: rgb(131 24 67 / 0.35) !important;
}

.tw-bg-pink-900\/40 {
  background-color: rgb(131 24 67 / 0.4) !important;
}

.tw-bg-pink-900\/45 {
  background-color: rgb(131 24 67 / 0.45) !important;
}

.tw-bg-pink-900\/5 {
  background-color: rgb(131 24 67 / 0.05) !important;
}

.tw-bg-pink-900\/50 {
  background-color: rgb(131 24 67 / 0.5) !important;
}

.tw-bg-pink-900\/55 {
  background-color: rgb(131 24 67 / 0.55) !important;
}

.tw-bg-pink-900\/60 {
  background-color: rgb(131 24 67 / 0.6) !important;
}

.tw-bg-pink-900\/65 {
  background-color: rgb(131 24 67 / 0.65) !important;
}

.tw-bg-pink-900\/70 {
  background-color: rgb(131 24 67 / 0.7) !important;
}

.tw-bg-pink-900\/75 {
  background-color: rgb(131 24 67 / 0.75) !important;
}

.tw-bg-pink-900\/80 {
  background-color: rgb(131 24 67 / 0.8) !important;
}

.tw-bg-pink-900\/85 {
  background-color: rgb(131 24 67 / 0.85) !important;
}

.tw-bg-pink-900\/90 {
  background-color: rgb(131 24 67 / 0.9) !important;
}

.tw-bg-pink-900\/95 {
  background-color: rgb(131 24 67 / 0.95) !important;
}

.tw-bg-pink-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(80 7 36 / var(--tw-bg-opacity)) !important;
}

.tw-bg-pink-950\/0 {
  background-color: rgb(80 7 36 / 0) !important;
}

.tw-bg-pink-950\/10 {
  background-color: rgb(80 7 36 / 0.1) !important;
}

.tw-bg-pink-950\/100 {
  background-color: rgb(80 7 36 / 1) !important;
}

.tw-bg-pink-950\/15 {
  background-color: rgb(80 7 36 / 0.15) !important;
}

.tw-bg-pink-950\/20 {
  background-color: rgb(80 7 36 / 0.2) !important;
}

.tw-bg-pink-950\/25 {
  background-color: rgb(80 7 36 / 0.25) !important;
}

.tw-bg-pink-950\/30 {
  background-color: rgb(80 7 36 / 0.3) !important;
}

.tw-bg-pink-950\/35 {
  background-color: rgb(80 7 36 / 0.35) !important;
}

.tw-bg-pink-950\/40 {
  background-color: rgb(80 7 36 / 0.4) !important;
}

.tw-bg-pink-950\/45 {
  background-color: rgb(80 7 36 / 0.45) !important;
}

.tw-bg-pink-950\/5 {
  background-color: rgb(80 7 36 / 0.05) !important;
}

.tw-bg-pink-950\/50 {
  background-color: rgb(80 7 36 / 0.5) !important;
}

.tw-bg-pink-950\/55 {
  background-color: rgb(80 7 36 / 0.55) !important;
}

.tw-bg-pink-950\/60 {
  background-color: rgb(80 7 36 / 0.6) !important;
}

.tw-bg-pink-950\/65 {
  background-color: rgb(80 7 36 / 0.65) !important;
}

.tw-bg-pink-950\/70 {
  background-color: rgb(80 7 36 / 0.7) !important;
}

.tw-bg-pink-950\/75 {
  background-color: rgb(80 7 36 / 0.75) !important;
}

.tw-bg-pink-950\/80 {
  background-color: rgb(80 7 36 / 0.8) !important;
}

.tw-bg-pink-950\/85 {
  background-color: rgb(80 7 36 / 0.85) !important;
}

.tw-bg-pink-950\/90 {
  background-color: rgb(80 7 36 / 0.9) !important;
}

.tw-bg-pink-950\/95 {
  background-color: rgb(80 7 36 / 0.95) !important;
}

.tw-bg-purple-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(243 232 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-100\/0 {
  background-color: rgb(243 232 255 / 0) !important;
}

.tw-bg-purple-100\/10 {
  background-color: rgb(243 232 255 / 0.1) !important;
}

.tw-bg-purple-100\/100 {
  background-color: rgb(243 232 255 / 1) !important;
}

.tw-bg-purple-100\/15 {
  background-color: rgb(243 232 255 / 0.15) !important;
}

.tw-bg-purple-100\/20 {
  background-color: rgb(243 232 255 / 0.2) !important;
}

.tw-bg-purple-100\/25 {
  background-color: rgb(243 232 255 / 0.25) !important;
}

.tw-bg-purple-100\/30 {
  background-color: rgb(243 232 255 / 0.3) !important;
}

.tw-bg-purple-100\/35 {
  background-color: rgb(243 232 255 / 0.35) !important;
}

.tw-bg-purple-100\/40 {
  background-color: rgb(243 232 255 / 0.4) !important;
}

.tw-bg-purple-100\/45 {
  background-color: rgb(243 232 255 / 0.45) !important;
}

.tw-bg-purple-100\/5 {
  background-color: rgb(243 232 255 / 0.05) !important;
}

.tw-bg-purple-100\/50 {
  background-color: rgb(243 232 255 / 0.5) !important;
}

.tw-bg-purple-100\/55 {
  background-color: rgb(243 232 255 / 0.55) !important;
}

.tw-bg-purple-100\/60 {
  background-color: rgb(243 232 255 / 0.6) !important;
}

.tw-bg-purple-100\/65 {
  background-color: rgb(243 232 255 / 0.65) !important;
}

.tw-bg-purple-100\/70 {
  background-color: rgb(243 232 255 / 0.7) !important;
}

.tw-bg-purple-100\/75 {
  background-color: rgb(243 232 255 / 0.75) !important;
}

.tw-bg-purple-100\/80 {
  background-color: rgb(243 232 255 / 0.8) !important;
}

.tw-bg-purple-100\/85 {
  background-color: rgb(243 232 255 / 0.85) !important;
}

.tw-bg-purple-100\/90 {
  background-color: rgb(243 232 255 / 0.9) !important;
}

.tw-bg-purple-100\/95 {
  background-color: rgb(243 232 255 / 0.95) !important;
}

.tw-bg-purple-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(233 213 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-200\/0 {
  background-color: rgb(233 213 255 / 0) !important;
}

.tw-bg-purple-200\/10 {
  background-color: rgb(233 213 255 / 0.1) !important;
}

.tw-bg-purple-200\/100 {
  background-color: rgb(233 213 255 / 1) !important;
}

.tw-bg-purple-200\/15 {
  background-color: rgb(233 213 255 / 0.15) !important;
}

.tw-bg-purple-200\/20 {
  background-color: rgb(233 213 255 / 0.2) !important;
}

.tw-bg-purple-200\/25 {
  background-color: rgb(233 213 255 / 0.25) !important;
}

.tw-bg-purple-200\/30 {
  background-color: rgb(233 213 255 / 0.3) !important;
}

.tw-bg-purple-200\/35 {
  background-color: rgb(233 213 255 / 0.35) !important;
}

.tw-bg-purple-200\/40 {
  background-color: rgb(233 213 255 / 0.4) !important;
}

.tw-bg-purple-200\/45 {
  background-color: rgb(233 213 255 / 0.45) !important;
}

.tw-bg-purple-200\/5 {
  background-color: rgb(233 213 255 / 0.05) !important;
}

.tw-bg-purple-200\/50 {
  background-color: rgb(233 213 255 / 0.5) !important;
}

.tw-bg-purple-200\/55 {
  background-color: rgb(233 213 255 / 0.55) !important;
}

.tw-bg-purple-200\/60 {
  background-color: rgb(233 213 255 / 0.6) !important;
}

.tw-bg-purple-200\/65 {
  background-color: rgb(233 213 255 / 0.65) !important;
}

.tw-bg-purple-200\/70 {
  background-color: rgb(233 213 255 / 0.7) !important;
}

.tw-bg-purple-200\/75 {
  background-color: rgb(233 213 255 / 0.75) !important;
}

.tw-bg-purple-200\/80 {
  background-color: rgb(233 213 255 / 0.8) !important;
}

.tw-bg-purple-200\/85 {
  background-color: rgb(233 213 255 / 0.85) !important;
}

.tw-bg-purple-200\/90 {
  background-color: rgb(233 213 255 / 0.9) !important;
}

.tw-bg-purple-200\/95 {
  background-color: rgb(233 213 255 / 0.95) !important;
}

.tw-bg-purple-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(216 180 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-300\/0 {
  background-color: rgb(216 180 254 / 0) !important;
}

.tw-bg-purple-300\/10 {
  background-color: rgb(216 180 254 / 0.1) !important;
}

.tw-bg-purple-300\/100 {
  background-color: rgb(216 180 254 / 1) !important;
}

.tw-bg-purple-300\/15 {
  background-color: rgb(216 180 254 / 0.15) !important;
}

.tw-bg-purple-300\/20 {
  background-color: rgb(216 180 254 / 0.2) !important;
}

.tw-bg-purple-300\/25 {
  background-color: rgb(216 180 254 / 0.25) !important;
}

.tw-bg-purple-300\/30 {
  background-color: rgb(216 180 254 / 0.3) !important;
}

.tw-bg-purple-300\/35 {
  background-color: rgb(216 180 254 / 0.35) !important;
}

.tw-bg-purple-300\/40 {
  background-color: rgb(216 180 254 / 0.4) !important;
}

.tw-bg-purple-300\/45 {
  background-color: rgb(216 180 254 / 0.45) !important;
}

.tw-bg-purple-300\/5 {
  background-color: rgb(216 180 254 / 0.05) !important;
}

.tw-bg-purple-300\/50 {
  background-color: rgb(216 180 254 / 0.5) !important;
}

.tw-bg-purple-300\/55 {
  background-color: rgb(216 180 254 / 0.55) !important;
}

.tw-bg-purple-300\/60 {
  background-color: rgb(216 180 254 / 0.6) !important;
}

.tw-bg-purple-300\/65 {
  background-color: rgb(216 180 254 / 0.65) !important;
}

.tw-bg-purple-300\/70 {
  background-color: rgb(216 180 254 / 0.7) !important;
}

.tw-bg-purple-300\/75 {
  background-color: rgb(216 180 254 / 0.75) !important;
}

.tw-bg-purple-300\/80 {
  background-color: rgb(216 180 254 / 0.8) !important;
}

.tw-bg-purple-300\/85 {
  background-color: rgb(216 180 254 / 0.85) !important;
}

.tw-bg-purple-300\/90 {
  background-color: rgb(216 180 254 / 0.9) !important;
}

.tw-bg-purple-300\/95 {
  background-color: rgb(216 180 254 / 0.95) !important;
}

.tw-bg-purple-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(192 132 252 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-400\/0 {
  background-color: rgb(192 132 252 / 0) !important;
}

.tw-bg-purple-400\/10 {
  background-color: rgb(192 132 252 / 0.1) !important;
}

.tw-bg-purple-400\/100 {
  background-color: rgb(192 132 252 / 1) !important;
}

.tw-bg-purple-400\/15 {
  background-color: rgb(192 132 252 / 0.15) !important;
}

.tw-bg-purple-400\/20 {
  background-color: rgb(192 132 252 / 0.2) !important;
}

.tw-bg-purple-400\/25 {
  background-color: rgb(192 132 252 / 0.25) !important;
}

.tw-bg-purple-400\/30 {
  background-color: rgb(192 132 252 / 0.3) !important;
}

.tw-bg-purple-400\/35 {
  background-color: rgb(192 132 252 / 0.35) !important;
}

.tw-bg-purple-400\/40 {
  background-color: rgb(192 132 252 / 0.4) !important;
}

.tw-bg-purple-400\/45 {
  background-color: rgb(192 132 252 / 0.45) !important;
}

.tw-bg-purple-400\/5 {
  background-color: rgb(192 132 252 / 0.05) !important;
}

.tw-bg-purple-400\/50 {
  background-color: rgb(192 132 252 / 0.5) !important;
}

.tw-bg-purple-400\/55 {
  background-color: rgb(192 132 252 / 0.55) !important;
}

.tw-bg-purple-400\/60 {
  background-color: rgb(192 132 252 / 0.6) !important;
}

.tw-bg-purple-400\/65 {
  background-color: rgb(192 132 252 / 0.65) !important;
}

.tw-bg-purple-400\/70 {
  background-color: rgb(192 132 252 / 0.7) !important;
}

.tw-bg-purple-400\/75 {
  background-color: rgb(192 132 252 / 0.75) !important;
}

.tw-bg-purple-400\/80 {
  background-color: rgb(192 132 252 / 0.8) !important;
}

.tw-bg-purple-400\/85 {
  background-color: rgb(192 132 252 / 0.85) !important;
}

.tw-bg-purple-400\/90 {
  background-color: rgb(192 132 252 / 0.9) !important;
}

.tw-bg-purple-400\/95 {
  background-color: rgb(192 132 252 / 0.95) !important;
}

.tw-bg-purple-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 245 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-50\/0 {
  background-color: rgb(250 245 255 / 0) !important;
}

.tw-bg-purple-50\/10 {
  background-color: rgb(250 245 255 / 0.1) !important;
}

.tw-bg-purple-50\/100 {
  background-color: rgb(250 245 255 / 1) !important;
}

.tw-bg-purple-50\/15 {
  background-color: rgb(250 245 255 / 0.15) !important;
}

.tw-bg-purple-50\/20 {
  background-color: rgb(250 245 255 / 0.2) !important;
}

.tw-bg-purple-50\/25 {
  background-color: rgb(250 245 255 / 0.25) !important;
}

.tw-bg-purple-50\/30 {
  background-color: rgb(250 245 255 / 0.3) !important;
}

.tw-bg-purple-50\/35 {
  background-color: rgb(250 245 255 / 0.35) !important;
}

.tw-bg-purple-50\/40 {
  background-color: rgb(250 245 255 / 0.4) !important;
}

.tw-bg-purple-50\/45 {
  background-color: rgb(250 245 255 / 0.45) !important;
}

.tw-bg-purple-50\/5 {
  background-color: rgb(250 245 255 / 0.05) !important;
}

.tw-bg-purple-50\/50 {
  background-color: rgb(250 245 255 / 0.5) !important;
}

.tw-bg-purple-50\/55 {
  background-color: rgb(250 245 255 / 0.55) !important;
}

.tw-bg-purple-50\/60 {
  background-color: rgb(250 245 255 / 0.6) !important;
}

.tw-bg-purple-50\/65 {
  background-color: rgb(250 245 255 / 0.65) !important;
}

.tw-bg-purple-50\/70 {
  background-color: rgb(250 245 255 / 0.7) !important;
}

.tw-bg-purple-50\/75 {
  background-color: rgb(250 245 255 / 0.75) !important;
}

.tw-bg-purple-50\/80 {
  background-color: rgb(250 245 255 / 0.8) !important;
}

.tw-bg-purple-50\/85 {
  background-color: rgb(250 245 255 / 0.85) !important;
}

.tw-bg-purple-50\/90 {
  background-color: rgb(250 245 255 / 0.9) !important;
}

.tw-bg-purple-50\/95 {
  background-color: rgb(250 245 255 / 0.95) !important;
}

.tw-bg-purple-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(168 85 247 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-500\/0 {
  background-color: rgb(168 85 247 / 0) !important;
}

.tw-bg-purple-500\/10 {
  background-color: rgb(168 85 247 / 0.1) !important;
}

.tw-bg-purple-500\/100 {
  background-color: rgb(168 85 247 / 1) !important;
}

.tw-bg-purple-500\/15 {
  background-color: rgb(168 85 247 / 0.15) !important;
}

.tw-bg-purple-500\/20 {
  background-color: rgb(168 85 247 / 0.2) !important;
}

.tw-bg-purple-500\/25 {
  background-color: rgb(168 85 247 / 0.25) !important;
}

.tw-bg-purple-500\/30 {
  background-color: rgb(168 85 247 / 0.3) !important;
}

.tw-bg-purple-500\/35 {
  background-color: rgb(168 85 247 / 0.35) !important;
}

.tw-bg-purple-500\/40 {
  background-color: rgb(168 85 247 / 0.4) !important;
}

.tw-bg-purple-500\/45 {
  background-color: rgb(168 85 247 / 0.45) !important;
}

.tw-bg-purple-500\/5 {
  background-color: rgb(168 85 247 / 0.05) !important;
}

.tw-bg-purple-500\/50 {
  background-color: rgb(168 85 247 / 0.5) !important;
}

.tw-bg-purple-500\/55 {
  background-color: rgb(168 85 247 / 0.55) !important;
}

.tw-bg-purple-500\/60 {
  background-color: rgb(168 85 247 / 0.6) !important;
}

.tw-bg-purple-500\/65 {
  background-color: rgb(168 85 247 / 0.65) !important;
}

.tw-bg-purple-500\/70 {
  background-color: rgb(168 85 247 / 0.7) !important;
}

.tw-bg-purple-500\/75 {
  background-color: rgb(168 85 247 / 0.75) !important;
}

.tw-bg-purple-500\/80 {
  background-color: rgb(168 85 247 / 0.8) !important;
}

.tw-bg-purple-500\/85 {
  background-color: rgb(168 85 247 / 0.85) !important;
}

.tw-bg-purple-500\/90 {
  background-color: rgb(168 85 247 / 0.9) !important;
}

.tw-bg-purple-500\/95 {
  background-color: rgb(168 85 247 / 0.95) !important;
}

.tw-bg-purple-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(147 51 234 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-600\/0 {
  background-color: rgb(147 51 234 / 0) !important;
}

.tw-bg-purple-600\/10 {
  background-color: rgb(147 51 234 / 0.1) !important;
}

.tw-bg-purple-600\/100 {
  background-color: rgb(147 51 234 / 1) !important;
}

.tw-bg-purple-600\/15 {
  background-color: rgb(147 51 234 / 0.15) !important;
}

.tw-bg-purple-600\/20 {
  background-color: rgb(147 51 234 / 0.2) !important;
}

.tw-bg-purple-600\/25 {
  background-color: rgb(147 51 234 / 0.25) !important;
}

.tw-bg-purple-600\/30 {
  background-color: rgb(147 51 234 / 0.3) !important;
}

.tw-bg-purple-600\/35 {
  background-color: rgb(147 51 234 / 0.35) !important;
}

.tw-bg-purple-600\/40 {
  background-color: rgb(147 51 234 / 0.4) !important;
}

.tw-bg-purple-600\/45 {
  background-color: rgb(147 51 234 / 0.45) !important;
}

.tw-bg-purple-600\/5 {
  background-color: rgb(147 51 234 / 0.05) !important;
}

.tw-bg-purple-600\/50 {
  background-color: rgb(147 51 234 / 0.5) !important;
}

.tw-bg-purple-600\/55 {
  background-color: rgb(147 51 234 / 0.55) !important;
}

.tw-bg-purple-600\/60 {
  background-color: rgb(147 51 234 / 0.6) !important;
}

.tw-bg-purple-600\/65 {
  background-color: rgb(147 51 234 / 0.65) !important;
}

.tw-bg-purple-600\/70 {
  background-color: rgb(147 51 234 / 0.7) !important;
}

.tw-bg-purple-600\/75 {
  background-color: rgb(147 51 234 / 0.75) !important;
}

.tw-bg-purple-600\/80 {
  background-color: rgb(147 51 234 / 0.8) !important;
}

.tw-bg-purple-600\/85 {
  background-color: rgb(147 51 234 / 0.85) !important;
}

.tw-bg-purple-600\/90 {
  background-color: rgb(147 51 234 / 0.9) !important;
}

.tw-bg-purple-600\/95 {
  background-color: rgb(147 51 234 / 0.95) !important;
}

.tw-bg-purple-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-700\/0 {
  background-color: rgb(126 34 206 / 0) !important;
}

.tw-bg-purple-700\/10 {
  background-color: rgb(126 34 206 / 0.1) !important;
}

.tw-bg-purple-700\/100 {
  background-color: rgb(126 34 206 / 1) !important;
}

.tw-bg-purple-700\/15 {
  background-color: rgb(126 34 206 / 0.15) !important;
}

.tw-bg-purple-700\/20 {
  background-color: rgb(126 34 206 / 0.2) !important;
}

.tw-bg-purple-700\/25 {
  background-color: rgb(126 34 206 / 0.25) !important;
}

.tw-bg-purple-700\/30 {
  background-color: rgb(126 34 206 / 0.3) !important;
}

.tw-bg-purple-700\/35 {
  background-color: rgb(126 34 206 / 0.35) !important;
}

.tw-bg-purple-700\/40 {
  background-color: rgb(126 34 206 / 0.4) !important;
}

.tw-bg-purple-700\/45 {
  background-color: rgb(126 34 206 / 0.45) !important;
}

.tw-bg-purple-700\/5 {
  background-color: rgb(126 34 206 / 0.05) !important;
}

.tw-bg-purple-700\/50 {
  background-color: rgb(126 34 206 / 0.5) !important;
}

.tw-bg-purple-700\/55 {
  background-color: rgb(126 34 206 / 0.55) !important;
}

.tw-bg-purple-700\/60 {
  background-color: rgb(126 34 206 / 0.6) !important;
}

.tw-bg-purple-700\/65 {
  background-color: rgb(126 34 206 / 0.65) !important;
}

.tw-bg-purple-700\/70 {
  background-color: rgb(126 34 206 / 0.7) !important;
}

.tw-bg-purple-700\/75 {
  background-color: rgb(126 34 206 / 0.75) !important;
}

.tw-bg-purple-700\/80 {
  background-color: rgb(126 34 206 / 0.8) !important;
}

.tw-bg-purple-700\/85 {
  background-color: rgb(126 34 206 / 0.85) !important;
}

.tw-bg-purple-700\/90 {
  background-color: rgb(126 34 206 / 0.9) !important;
}

.tw-bg-purple-700\/95 {
  background-color: rgb(126 34 206 / 0.95) !important;
}

.tw-bg-purple-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(107 33 168 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-800\/0 {
  background-color: rgb(107 33 168 / 0) !important;
}

.tw-bg-purple-800\/10 {
  background-color: rgb(107 33 168 / 0.1) !important;
}

.tw-bg-purple-800\/100 {
  background-color: rgb(107 33 168 / 1) !important;
}

.tw-bg-purple-800\/15 {
  background-color: rgb(107 33 168 / 0.15) !important;
}

.tw-bg-purple-800\/20 {
  background-color: rgb(107 33 168 / 0.2) !important;
}

.tw-bg-purple-800\/25 {
  background-color: rgb(107 33 168 / 0.25) !important;
}

.tw-bg-purple-800\/30 {
  background-color: rgb(107 33 168 / 0.3) !important;
}

.tw-bg-purple-800\/35 {
  background-color: rgb(107 33 168 / 0.35) !important;
}

.tw-bg-purple-800\/40 {
  background-color: rgb(107 33 168 / 0.4) !important;
}

.tw-bg-purple-800\/45 {
  background-color: rgb(107 33 168 / 0.45) !important;
}

.tw-bg-purple-800\/5 {
  background-color: rgb(107 33 168 / 0.05) !important;
}

.tw-bg-purple-800\/50 {
  background-color: rgb(107 33 168 / 0.5) !important;
}

.tw-bg-purple-800\/55 {
  background-color: rgb(107 33 168 / 0.55) !important;
}

.tw-bg-purple-800\/60 {
  background-color: rgb(107 33 168 / 0.6) !important;
}

.tw-bg-purple-800\/65 {
  background-color: rgb(107 33 168 / 0.65) !important;
}

.tw-bg-purple-800\/70 {
  background-color: rgb(107 33 168 / 0.7) !important;
}

.tw-bg-purple-800\/75 {
  background-color: rgb(107 33 168 / 0.75) !important;
}

.tw-bg-purple-800\/80 {
  background-color: rgb(107 33 168 / 0.8) !important;
}

.tw-bg-purple-800\/85 {
  background-color: rgb(107 33 168 / 0.85) !important;
}

.tw-bg-purple-800\/90 {
  background-color: rgb(107 33 168 / 0.9) !important;
}

.tw-bg-purple-800\/95 {
  background-color: rgb(107 33 168 / 0.95) !important;
}

.tw-bg-purple-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(88 28 135 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-900\/0 {
  background-color: rgb(88 28 135 / 0) !important;
}

.tw-bg-purple-900\/10 {
  background-color: rgb(88 28 135 / 0.1) !important;
}

.tw-bg-purple-900\/100 {
  background-color: rgb(88 28 135 / 1) !important;
}

.tw-bg-purple-900\/15 {
  background-color: rgb(88 28 135 / 0.15) !important;
}

.tw-bg-purple-900\/20 {
  background-color: rgb(88 28 135 / 0.2) !important;
}

.tw-bg-purple-900\/25 {
  background-color: rgb(88 28 135 / 0.25) !important;
}

.tw-bg-purple-900\/30 {
  background-color: rgb(88 28 135 / 0.3) !important;
}

.tw-bg-purple-900\/35 {
  background-color: rgb(88 28 135 / 0.35) !important;
}

.tw-bg-purple-900\/40 {
  background-color: rgb(88 28 135 / 0.4) !important;
}

.tw-bg-purple-900\/45 {
  background-color: rgb(88 28 135 / 0.45) !important;
}

.tw-bg-purple-900\/5 {
  background-color: rgb(88 28 135 / 0.05) !important;
}

.tw-bg-purple-900\/50 {
  background-color: rgb(88 28 135 / 0.5) !important;
}

.tw-bg-purple-900\/55 {
  background-color: rgb(88 28 135 / 0.55) !important;
}

.tw-bg-purple-900\/60 {
  background-color: rgb(88 28 135 / 0.6) !important;
}

.tw-bg-purple-900\/65 {
  background-color: rgb(88 28 135 / 0.65) !important;
}

.tw-bg-purple-900\/70 {
  background-color: rgb(88 28 135 / 0.7) !important;
}

.tw-bg-purple-900\/75 {
  background-color: rgb(88 28 135 / 0.75) !important;
}

.tw-bg-purple-900\/80 {
  background-color: rgb(88 28 135 / 0.8) !important;
}

.tw-bg-purple-900\/85 {
  background-color: rgb(88 28 135 / 0.85) !important;
}

.tw-bg-purple-900\/90 {
  background-color: rgb(88 28 135 / 0.9) !important;
}

.tw-bg-purple-900\/95 {
  background-color: rgb(88 28 135 / 0.95) !important;
}

.tw-bg-purple-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(59 7 100 / var(--tw-bg-opacity)) !important;
}

.tw-bg-purple-950\/0 {
  background-color: rgb(59 7 100 / 0) !important;
}

.tw-bg-purple-950\/10 {
  background-color: rgb(59 7 100 / 0.1) !important;
}

.tw-bg-purple-950\/100 {
  background-color: rgb(59 7 100 / 1) !important;
}

.tw-bg-purple-950\/15 {
  background-color: rgb(59 7 100 / 0.15) !important;
}

.tw-bg-purple-950\/20 {
  background-color: rgb(59 7 100 / 0.2) !important;
}

.tw-bg-purple-950\/25 {
  background-color: rgb(59 7 100 / 0.25) !important;
}

.tw-bg-purple-950\/30 {
  background-color: rgb(59 7 100 / 0.3) !important;
}

.tw-bg-purple-950\/35 {
  background-color: rgb(59 7 100 / 0.35) !important;
}

.tw-bg-purple-950\/40 {
  background-color: rgb(59 7 100 / 0.4) !important;
}

.tw-bg-purple-950\/45 {
  background-color: rgb(59 7 100 / 0.45) !important;
}

.tw-bg-purple-950\/5 {
  background-color: rgb(59 7 100 / 0.05) !important;
}

.tw-bg-purple-950\/50 {
  background-color: rgb(59 7 100 / 0.5) !important;
}

.tw-bg-purple-950\/55 {
  background-color: rgb(59 7 100 / 0.55) !important;
}

.tw-bg-purple-950\/60 {
  background-color: rgb(59 7 100 / 0.6) !important;
}

.tw-bg-purple-950\/65 {
  background-color: rgb(59 7 100 / 0.65) !important;
}

.tw-bg-purple-950\/70 {
  background-color: rgb(59 7 100 / 0.7) !important;
}

.tw-bg-purple-950\/75 {
  background-color: rgb(59 7 100 / 0.75) !important;
}

.tw-bg-purple-950\/80 {
  background-color: rgb(59 7 100 / 0.8) !important;
}

.tw-bg-purple-950\/85 {
  background-color: rgb(59 7 100 / 0.85) !important;
}

.tw-bg-purple-950\/90 {
  background-color: rgb(59 7 100 / 0.9) !important;
}

.tw-bg-purple-950\/95 {
  background-color: rgb(59 7 100 / 0.95) !important;
}

.tw-bg-red-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 226 226 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-100\/0 {
  background-color: rgb(254 226 226 / 0) !important;
}

.tw-bg-red-100\/10 {
  background-color: rgb(254 226 226 / 0.1) !important;
}

.tw-bg-red-100\/100 {
  background-color: rgb(254 226 226 / 1) !important;
}

.tw-bg-red-100\/15 {
  background-color: rgb(254 226 226 / 0.15) !important;
}

.tw-bg-red-100\/20 {
  background-color: rgb(254 226 226 / 0.2) !important;
}

.tw-bg-red-100\/25 {
  background-color: rgb(254 226 226 / 0.25) !important;
}

.tw-bg-red-100\/30 {
  background-color: rgb(254 226 226 / 0.3) !important;
}

.tw-bg-red-100\/35 {
  background-color: rgb(254 226 226 / 0.35) !important;
}

.tw-bg-red-100\/40 {
  background-color: rgb(254 226 226 / 0.4) !important;
}

.tw-bg-red-100\/45 {
  background-color: rgb(254 226 226 / 0.45) !important;
}

.tw-bg-red-100\/5 {
  background-color: rgb(254 226 226 / 0.05) !important;
}

.tw-bg-red-100\/50 {
  background-color: rgb(254 226 226 / 0.5) !important;
}

.tw-bg-red-100\/55 {
  background-color: rgb(254 226 226 / 0.55) !important;
}

.tw-bg-red-100\/60 {
  background-color: rgb(254 226 226 / 0.6) !important;
}

.tw-bg-red-100\/65 {
  background-color: rgb(254 226 226 / 0.65) !important;
}

.tw-bg-red-100\/70 {
  background-color: rgb(254 226 226 / 0.7) !important;
}

.tw-bg-red-100\/75 {
  background-color: rgb(254 226 226 / 0.75) !important;
}

.tw-bg-red-100\/80 {
  background-color: rgb(254 226 226 / 0.8) !important;
}

.tw-bg-red-100\/85 {
  background-color: rgb(254 226 226 / 0.85) !important;
}

.tw-bg-red-100\/90 {
  background-color: rgb(254 226 226 / 0.9) !important;
}

.tw-bg-red-100\/95 {
  background-color: rgb(254 226 226 / 0.95) !important;
}

.tw-bg-red-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 202 202 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-200\/0 {
  background-color: rgb(254 202 202 / 0) !important;
}

.tw-bg-red-200\/10 {
  background-color: rgb(254 202 202 / 0.1) !important;
}

.tw-bg-red-200\/100 {
  background-color: rgb(254 202 202 / 1) !important;
}

.tw-bg-red-200\/15 {
  background-color: rgb(254 202 202 / 0.15) !important;
}

.tw-bg-red-200\/20 {
  background-color: rgb(254 202 202 / 0.2) !important;
}

.tw-bg-red-200\/25 {
  background-color: rgb(254 202 202 / 0.25) !important;
}

.tw-bg-red-200\/30 {
  background-color: rgb(254 202 202 / 0.3) !important;
}

.tw-bg-red-200\/35 {
  background-color: rgb(254 202 202 / 0.35) !important;
}

.tw-bg-red-200\/40 {
  background-color: rgb(254 202 202 / 0.4) !important;
}

.tw-bg-red-200\/45 {
  background-color: rgb(254 202 202 / 0.45) !important;
}

.tw-bg-red-200\/5 {
  background-color: rgb(254 202 202 / 0.05) !important;
}

.tw-bg-red-200\/50 {
  background-color: rgb(254 202 202 / 0.5) !important;
}

.tw-bg-red-200\/55 {
  background-color: rgb(254 202 202 / 0.55) !important;
}

.tw-bg-red-200\/60 {
  background-color: rgb(254 202 202 / 0.6) !important;
}

.tw-bg-red-200\/65 {
  background-color: rgb(254 202 202 / 0.65) !important;
}

.tw-bg-red-200\/70 {
  background-color: rgb(254 202 202 / 0.7) !important;
}

.tw-bg-red-200\/75 {
  background-color: rgb(254 202 202 / 0.75) !important;
}

.tw-bg-red-200\/80 {
  background-color: rgb(254 202 202 / 0.8) !important;
}

.tw-bg-red-200\/85 {
  background-color: rgb(254 202 202 / 0.85) !important;
}

.tw-bg-red-200\/90 {
  background-color: rgb(254 202 202 / 0.9) !important;
}

.tw-bg-red-200\/95 {
  background-color: rgb(254 202 202 / 0.95) !important;
}

.tw-bg-red-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(252 165 165 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-300\/0 {
  background-color: rgb(252 165 165 / 0) !important;
}

.tw-bg-red-300\/10 {
  background-color: rgb(252 165 165 / 0.1) !important;
}

.tw-bg-red-300\/100 {
  background-color: rgb(252 165 165 / 1) !important;
}

.tw-bg-red-300\/15 {
  background-color: rgb(252 165 165 / 0.15) !important;
}

.tw-bg-red-300\/20 {
  background-color: rgb(252 165 165 / 0.2) !important;
}

.tw-bg-red-300\/25 {
  background-color: rgb(252 165 165 / 0.25) !important;
}

.tw-bg-red-300\/30 {
  background-color: rgb(252 165 165 / 0.3) !important;
}

.tw-bg-red-300\/35 {
  background-color: rgb(252 165 165 / 0.35) !important;
}

.tw-bg-red-300\/40 {
  background-color: rgb(252 165 165 / 0.4) !important;
}

.tw-bg-red-300\/45 {
  background-color: rgb(252 165 165 / 0.45) !important;
}

.tw-bg-red-300\/5 {
  background-color: rgb(252 165 165 / 0.05) !important;
}

.tw-bg-red-300\/50 {
  background-color: rgb(252 165 165 / 0.5) !important;
}

.tw-bg-red-300\/55 {
  background-color: rgb(252 165 165 / 0.55) !important;
}

.tw-bg-red-300\/60 {
  background-color: rgb(252 165 165 / 0.6) !important;
}

.tw-bg-red-300\/65 {
  background-color: rgb(252 165 165 / 0.65) !important;
}

.tw-bg-red-300\/70 {
  background-color: rgb(252 165 165 / 0.7) !important;
}

.tw-bg-red-300\/75 {
  background-color: rgb(252 165 165 / 0.75) !important;
}

.tw-bg-red-300\/80 {
  background-color: rgb(252 165 165 / 0.8) !important;
}

.tw-bg-red-300\/85 {
  background-color: rgb(252 165 165 / 0.85) !important;
}

.tw-bg-red-300\/90 {
  background-color: rgb(252 165 165 / 0.9) !important;
}

.tw-bg-red-300\/95 {
  background-color: rgb(252 165 165 / 0.95) !important;
}

.tw-bg-red-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 113 113 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-400\/0 {
  background-color: rgb(248 113 113 / 0) !important;
}

.tw-bg-red-400\/10 {
  background-color: rgb(248 113 113 / 0.1) !important;
}

.tw-bg-red-400\/100 {
  background-color: rgb(248 113 113 / 1) !important;
}

.tw-bg-red-400\/15 {
  background-color: rgb(248 113 113 / 0.15) !important;
}

.tw-bg-red-400\/20 {
  background-color: rgb(248 113 113 / 0.2) !important;
}

.tw-bg-red-400\/25 {
  background-color: rgb(248 113 113 / 0.25) !important;
}

.tw-bg-red-400\/30 {
  background-color: rgb(248 113 113 / 0.3) !important;
}

.tw-bg-red-400\/35 {
  background-color: rgb(248 113 113 / 0.35) !important;
}

.tw-bg-red-400\/40 {
  background-color: rgb(248 113 113 / 0.4) !important;
}

.tw-bg-red-400\/45 {
  background-color: rgb(248 113 113 / 0.45) !important;
}

.tw-bg-red-400\/5 {
  background-color: rgb(248 113 113 / 0.05) !important;
}

.tw-bg-red-400\/50 {
  background-color: rgb(248 113 113 / 0.5) !important;
}

.tw-bg-red-400\/55 {
  background-color: rgb(248 113 113 / 0.55) !important;
}

.tw-bg-red-400\/60 {
  background-color: rgb(248 113 113 / 0.6) !important;
}

.tw-bg-red-400\/65 {
  background-color: rgb(248 113 113 / 0.65) !important;
}

.tw-bg-red-400\/70 {
  background-color: rgb(248 113 113 / 0.7) !important;
}

.tw-bg-red-400\/75 {
  background-color: rgb(248 113 113 / 0.75) !important;
}

.tw-bg-red-400\/80 {
  background-color: rgb(248 113 113 / 0.8) !important;
}

.tw-bg-red-400\/85 {
  background-color: rgb(248 113 113 / 0.85) !important;
}

.tw-bg-red-400\/90 {
  background-color: rgb(248 113 113 / 0.9) !important;
}

.tw-bg-red-400\/95 {
  background-color: rgb(248 113 113 / 0.95) !important;
}

.tw-bg-red-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 242 242 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-50\/0 {
  background-color: rgb(254 242 242 / 0) !important;
}

.tw-bg-red-50\/10 {
  background-color: rgb(254 242 242 / 0.1) !important;
}

.tw-bg-red-50\/100 {
  background-color: rgb(254 242 242 / 1) !important;
}

.tw-bg-red-50\/15 {
  background-color: rgb(254 242 242 / 0.15) !important;
}

.tw-bg-red-50\/20 {
  background-color: rgb(254 242 242 / 0.2) !important;
}

.tw-bg-red-50\/25 {
  background-color: rgb(254 242 242 / 0.25) !important;
}

.tw-bg-red-50\/30 {
  background-color: rgb(254 242 242 / 0.3) !important;
}

.tw-bg-red-50\/35 {
  background-color: rgb(254 242 242 / 0.35) !important;
}

.tw-bg-red-50\/40 {
  background-color: rgb(254 242 242 / 0.4) !important;
}

.tw-bg-red-50\/45 {
  background-color: rgb(254 242 242 / 0.45) !important;
}

.tw-bg-red-50\/5 {
  background-color: rgb(254 242 242 / 0.05) !important;
}

.tw-bg-red-50\/50 {
  background-color: rgb(254 242 242 / 0.5) !important;
}

.tw-bg-red-50\/55 {
  background-color: rgb(254 242 242 / 0.55) !important;
}

.tw-bg-red-50\/60 {
  background-color: rgb(254 242 242 / 0.6) !important;
}

.tw-bg-red-50\/65 {
  background-color: rgb(254 242 242 / 0.65) !important;
}

.tw-bg-red-50\/70 {
  background-color: rgb(254 242 242 / 0.7) !important;
}

.tw-bg-red-50\/75 {
  background-color: rgb(254 242 242 / 0.75) !important;
}

.tw-bg-red-50\/80 {
  background-color: rgb(254 242 242 / 0.8) !important;
}

.tw-bg-red-50\/85 {
  background-color: rgb(254 242 242 / 0.85) !important;
}

.tw-bg-red-50\/90 {
  background-color: rgb(254 242 242 / 0.9) !important;
}

.tw-bg-red-50\/95 {
  background-color: rgb(254 242 242 / 0.95) !important;
}

.tw-bg-red-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-500\/0 {
  background-color: rgb(239 68 68 / 0) !important;
}

.tw-bg-red-500\/10 {
  background-color: rgb(239 68 68 / 0.1) !important;
}

.tw-bg-red-500\/100 {
  background-color: rgb(239 68 68 / 1) !important;
}

.tw-bg-red-500\/15 {
  background-color: rgb(239 68 68 / 0.15) !important;
}

.tw-bg-red-500\/20 {
  background-color: rgb(239 68 68 / 0.2) !important;
}

.tw-bg-red-500\/25 {
  background-color: rgb(239 68 68 / 0.25) !important;
}

.tw-bg-red-500\/30 {
  background-color: rgb(239 68 68 / 0.3) !important;
}

.tw-bg-red-500\/35 {
  background-color: rgb(239 68 68 / 0.35) !important;
}

.tw-bg-red-500\/40 {
  background-color: rgb(239 68 68 / 0.4) !important;
}

.tw-bg-red-500\/45 {
  background-color: rgb(239 68 68 / 0.45) !important;
}

.tw-bg-red-500\/5 {
  background-color: rgb(239 68 68 / 0.05) !important;
}

.tw-bg-red-500\/50 {
  background-color: rgb(239 68 68 / 0.5) !important;
}

.tw-bg-red-500\/55 {
  background-color: rgb(239 68 68 / 0.55) !important;
}

.tw-bg-red-500\/60 {
  background-color: rgb(239 68 68 / 0.6) !important;
}

.tw-bg-red-500\/65 {
  background-color: rgb(239 68 68 / 0.65) !important;
}

.tw-bg-red-500\/70 {
  background-color: rgb(239 68 68 / 0.7) !important;
}

.tw-bg-red-500\/75 {
  background-color: rgb(239 68 68 / 0.75) !important;
}

.tw-bg-red-500\/80 {
  background-color: rgb(239 68 68 / 0.8) !important;
}

.tw-bg-red-500\/85 {
  background-color: rgb(239 68 68 / 0.85) !important;
}

.tw-bg-red-500\/90 {
  background-color: rgb(239 68 68 / 0.9) !important;
}

.tw-bg-red-500\/95 {
  background-color: rgb(239 68 68 / 0.95) !important;
}

.tw-bg-red-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(220 38 38 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-600\/0 {
  background-color: rgb(220 38 38 / 0) !important;
}

.tw-bg-red-600\/10 {
  background-color: rgb(220 38 38 / 0.1) !important;
}

.tw-bg-red-600\/100 {
  background-color: rgb(220 38 38 / 1) !important;
}

.tw-bg-red-600\/15 {
  background-color: rgb(220 38 38 / 0.15) !important;
}

.tw-bg-red-600\/20 {
  background-color: rgb(220 38 38 / 0.2) !important;
}

.tw-bg-red-600\/25 {
  background-color: rgb(220 38 38 / 0.25) !important;
}

.tw-bg-red-600\/30 {
  background-color: rgb(220 38 38 / 0.3) !important;
}

.tw-bg-red-600\/35 {
  background-color: rgb(220 38 38 / 0.35) !important;
}

.tw-bg-red-600\/40 {
  background-color: rgb(220 38 38 / 0.4) !important;
}

.tw-bg-red-600\/45 {
  background-color: rgb(220 38 38 / 0.45) !important;
}

.tw-bg-red-600\/5 {
  background-color: rgb(220 38 38 / 0.05) !important;
}

.tw-bg-red-600\/50 {
  background-color: rgb(220 38 38 / 0.5) !important;
}

.tw-bg-red-600\/55 {
  background-color: rgb(220 38 38 / 0.55) !important;
}

.tw-bg-red-600\/60 {
  background-color: rgb(220 38 38 / 0.6) !important;
}

.tw-bg-red-600\/65 {
  background-color: rgb(220 38 38 / 0.65) !important;
}

.tw-bg-red-600\/70 {
  background-color: rgb(220 38 38 / 0.7) !important;
}

.tw-bg-red-600\/75 {
  background-color: rgb(220 38 38 / 0.75) !important;
}

.tw-bg-red-600\/80 {
  background-color: rgb(220 38 38 / 0.8) !important;
}

.tw-bg-red-600\/85 {
  background-color: rgb(220 38 38 / 0.85) !important;
}

.tw-bg-red-600\/90 {
  background-color: rgb(220 38 38 / 0.9) !important;
}

.tw-bg-red-600\/95 {
  background-color: rgb(220 38 38 / 0.95) !important;
}

.tw-bg-red-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(185 28 28 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-700\/0 {
  background-color: rgb(185 28 28 / 0) !important;
}

.tw-bg-red-700\/10 {
  background-color: rgb(185 28 28 / 0.1) !important;
}

.tw-bg-red-700\/100 {
  background-color: rgb(185 28 28 / 1) !important;
}

.tw-bg-red-700\/15 {
  background-color: rgb(185 28 28 / 0.15) !important;
}

.tw-bg-red-700\/20 {
  background-color: rgb(185 28 28 / 0.2) !important;
}

.tw-bg-red-700\/25 {
  background-color: rgb(185 28 28 / 0.25) !important;
}

.tw-bg-red-700\/30 {
  background-color: rgb(185 28 28 / 0.3) !important;
}

.tw-bg-red-700\/35 {
  background-color: rgb(185 28 28 / 0.35) !important;
}

.tw-bg-red-700\/40 {
  background-color: rgb(185 28 28 / 0.4) !important;
}

.tw-bg-red-700\/45 {
  background-color: rgb(185 28 28 / 0.45) !important;
}

.tw-bg-red-700\/5 {
  background-color: rgb(185 28 28 / 0.05) !important;
}

.tw-bg-red-700\/50 {
  background-color: rgb(185 28 28 / 0.5) !important;
}

.tw-bg-red-700\/55 {
  background-color: rgb(185 28 28 / 0.55) !important;
}

.tw-bg-red-700\/60 {
  background-color: rgb(185 28 28 / 0.6) !important;
}

.tw-bg-red-700\/65 {
  background-color: rgb(185 28 28 / 0.65) !important;
}

.tw-bg-red-700\/70 {
  background-color: rgb(185 28 28 / 0.7) !important;
}

.tw-bg-red-700\/75 {
  background-color: rgb(185 28 28 / 0.75) !important;
}

.tw-bg-red-700\/80 {
  background-color: rgb(185 28 28 / 0.8) !important;
}

.tw-bg-red-700\/85 {
  background-color: rgb(185 28 28 / 0.85) !important;
}

.tw-bg-red-700\/90 {
  background-color: rgb(185 28 28 / 0.9) !important;
}

.tw-bg-red-700\/95 {
  background-color: rgb(185 28 28 / 0.95) !important;
}

.tw-bg-red-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(153 27 27 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-800\/0 {
  background-color: rgb(153 27 27 / 0) !important;
}

.tw-bg-red-800\/10 {
  background-color: rgb(153 27 27 / 0.1) !important;
}

.tw-bg-red-800\/100 {
  background-color: rgb(153 27 27 / 1) !important;
}

.tw-bg-red-800\/15 {
  background-color: rgb(153 27 27 / 0.15) !important;
}

.tw-bg-red-800\/20 {
  background-color: rgb(153 27 27 / 0.2) !important;
}

.tw-bg-red-800\/25 {
  background-color: rgb(153 27 27 / 0.25) !important;
}

.tw-bg-red-800\/30 {
  background-color: rgb(153 27 27 / 0.3) !important;
}

.tw-bg-red-800\/35 {
  background-color: rgb(153 27 27 / 0.35) !important;
}

.tw-bg-red-800\/40 {
  background-color: rgb(153 27 27 / 0.4) !important;
}

.tw-bg-red-800\/45 {
  background-color: rgb(153 27 27 / 0.45) !important;
}

.tw-bg-red-800\/5 {
  background-color: rgb(153 27 27 / 0.05) !important;
}

.tw-bg-red-800\/50 {
  background-color: rgb(153 27 27 / 0.5) !important;
}

.tw-bg-red-800\/55 {
  background-color: rgb(153 27 27 / 0.55) !important;
}

.tw-bg-red-800\/60 {
  background-color: rgb(153 27 27 / 0.6) !important;
}

.tw-bg-red-800\/65 {
  background-color: rgb(153 27 27 / 0.65) !important;
}

.tw-bg-red-800\/70 {
  background-color: rgb(153 27 27 / 0.7) !important;
}

.tw-bg-red-800\/75 {
  background-color: rgb(153 27 27 / 0.75) !important;
}

.tw-bg-red-800\/80 {
  background-color: rgb(153 27 27 / 0.8) !important;
}

.tw-bg-red-800\/85 {
  background-color: rgb(153 27 27 / 0.85) !important;
}

.tw-bg-red-800\/90 {
  background-color: rgb(153 27 27 / 0.9) !important;
}

.tw-bg-red-800\/95 {
  background-color: rgb(153 27 27 / 0.95) !important;
}

.tw-bg-red-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(127 29 29 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-900\/0 {
  background-color: rgb(127 29 29 / 0) !important;
}

.tw-bg-red-900\/10 {
  background-color: rgb(127 29 29 / 0.1) !important;
}

.tw-bg-red-900\/100 {
  background-color: rgb(127 29 29 / 1) !important;
}

.tw-bg-red-900\/15 {
  background-color: rgb(127 29 29 / 0.15) !important;
}

.tw-bg-red-900\/20 {
  background-color: rgb(127 29 29 / 0.2) !important;
}

.tw-bg-red-900\/25 {
  background-color: rgb(127 29 29 / 0.25) !important;
}

.tw-bg-red-900\/30 {
  background-color: rgb(127 29 29 / 0.3) !important;
}

.tw-bg-red-900\/35 {
  background-color: rgb(127 29 29 / 0.35) !important;
}

.tw-bg-red-900\/40 {
  background-color: rgb(127 29 29 / 0.4) !important;
}

.tw-bg-red-900\/45 {
  background-color: rgb(127 29 29 / 0.45) !important;
}

.tw-bg-red-900\/5 {
  background-color: rgb(127 29 29 / 0.05) !important;
}

.tw-bg-red-900\/50 {
  background-color: rgb(127 29 29 / 0.5) !important;
}

.tw-bg-red-900\/55 {
  background-color: rgb(127 29 29 / 0.55) !important;
}

.tw-bg-red-900\/60 {
  background-color: rgb(127 29 29 / 0.6) !important;
}

.tw-bg-red-900\/65 {
  background-color: rgb(127 29 29 / 0.65) !important;
}

.tw-bg-red-900\/70 {
  background-color: rgb(127 29 29 / 0.7) !important;
}

.tw-bg-red-900\/75 {
  background-color: rgb(127 29 29 / 0.75) !important;
}

.tw-bg-red-900\/80 {
  background-color: rgb(127 29 29 / 0.8) !important;
}

.tw-bg-red-900\/85 {
  background-color: rgb(127 29 29 / 0.85) !important;
}

.tw-bg-red-900\/90 {
  background-color: rgb(127 29 29 / 0.9) !important;
}

.tw-bg-red-900\/95 {
  background-color: rgb(127 29 29 / 0.95) !important;
}

.tw-bg-red-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(69 10 10 / var(--tw-bg-opacity)) !important;
}

.tw-bg-red-950\/0 {
  background-color: rgb(69 10 10 / 0) !important;
}

.tw-bg-red-950\/10 {
  background-color: rgb(69 10 10 / 0.1) !important;
}

.tw-bg-red-950\/100 {
  background-color: rgb(69 10 10 / 1) !important;
}

.tw-bg-red-950\/15 {
  background-color: rgb(69 10 10 / 0.15) !important;
}

.tw-bg-red-950\/20 {
  background-color: rgb(69 10 10 / 0.2) !important;
}

.tw-bg-red-950\/25 {
  background-color: rgb(69 10 10 / 0.25) !important;
}

.tw-bg-red-950\/30 {
  background-color: rgb(69 10 10 / 0.3) !important;
}

.tw-bg-red-950\/35 {
  background-color: rgb(69 10 10 / 0.35) !important;
}

.tw-bg-red-950\/40 {
  background-color: rgb(69 10 10 / 0.4) !important;
}

.tw-bg-red-950\/45 {
  background-color: rgb(69 10 10 / 0.45) !important;
}

.tw-bg-red-950\/5 {
  background-color: rgb(69 10 10 / 0.05) !important;
}

.tw-bg-red-950\/50 {
  background-color: rgb(69 10 10 / 0.5) !important;
}

.tw-bg-red-950\/55 {
  background-color: rgb(69 10 10 / 0.55) !important;
}

.tw-bg-red-950\/60 {
  background-color: rgb(69 10 10 / 0.6) !important;
}

.tw-bg-red-950\/65 {
  background-color: rgb(69 10 10 / 0.65) !important;
}

.tw-bg-red-950\/70 {
  background-color: rgb(69 10 10 / 0.7) !important;
}

.tw-bg-red-950\/75 {
  background-color: rgb(69 10 10 / 0.75) !important;
}

.tw-bg-red-950\/80 {
  background-color: rgb(69 10 10 / 0.8) !important;
}

.tw-bg-red-950\/85 {
  background-color: rgb(69 10 10 / 0.85) !important;
}

.tw-bg-red-950\/90 {
  background-color: rgb(69 10 10 / 0.9) !important;
}

.tw-bg-red-950\/95 {
  background-color: rgb(69 10 10 / 0.95) !important;
}

.tw-bg-rose-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 228 230 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-100\/0 {
  background-color: rgb(255 228 230 / 0) !important;
}

.tw-bg-rose-100\/10 {
  background-color: rgb(255 228 230 / 0.1) !important;
}

.tw-bg-rose-100\/100 {
  background-color: rgb(255 228 230 / 1) !important;
}

.tw-bg-rose-100\/15 {
  background-color: rgb(255 228 230 / 0.15) !important;
}

.tw-bg-rose-100\/20 {
  background-color: rgb(255 228 230 / 0.2) !important;
}

.tw-bg-rose-100\/25 {
  background-color: rgb(255 228 230 / 0.25) !important;
}

.tw-bg-rose-100\/30 {
  background-color: rgb(255 228 230 / 0.3) !important;
}

.tw-bg-rose-100\/35 {
  background-color: rgb(255 228 230 / 0.35) !important;
}

.tw-bg-rose-100\/40 {
  background-color: rgb(255 228 230 / 0.4) !important;
}

.tw-bg-rose-100\/45 {
  background-color: rgb(255 228 230 / 0.45) !important;
}

.tw-bg-rose-100\/5 {
  background-color: rgb(255 228 230 / 0.05) !important;
}

.tw-bg-rose-100\/50 {
  background-color: rgb(255 228 230 / 0.5) !important;
}

.tw-bg-rose-100\/55 {
  background-color: rgb(255 228 230 / 0.55) !important;
}

.tw-bg-rose-100\/60 {
  background-color: rgb(255 228 230 / 0.6) !important;
}

.tw-bg-rose-100\/65 {
  background-color: rgb(255 228 230 / 0.65) !important;
}

.tw-bg-rose-100\/70 {
  background-color: rgb(255 228 230 / 0.7) !important;
}

.tw-bg-rose-100\/75 {
  background-color: rgb(255 228 230 / 0.75) !important;
}

.tw-bg-rose-100\/80 {
  background-color: rgb(255 228 230 / 0.8) !important;
}

.tw-bg-rose-100\/85 {
  background-color: rgb(255 228 230 / 0.85) !important;
}

.tw-bg-rose-100\/90 {
  background-color: rgb(255 228 230 / 0.9) !important;
}

.tw-bg-rose-100\/95 {
  background-color: rgb(255 228 230 / 0.95) !important;
}

.tw-bg-rose-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 205 211 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-200\/0 {
  background-color: rgb(254 205 211 / 0) !important;
}

.tw-bg-rose-200\/10 {
  background-color: rgb(254 205 211 / 0.1) !important;
}

.tw-bg-rose-200\/100 {
  background-color: rgb(254 205 211 / 1) !important;
}

.tw-bg-rose-200\/15 {
  background-color: rgb(254 205 211 / 0.15) !important;
}

.tw-bg-rose-200\/20 {
  background-color: rgb(254 205 211 / 0.2) !important;
}

.tw-bg-rose-200\/25 {
  background-color: rgb(254 205 211 / 0.25) !important;
}

.tw-bg-rose-200\/30 {
  background-color: rgb(254 205 211 / 0.3) !important;
}

.tw-bg-rose-200\/35 {
  background-color: rgb(254 205 211 / 0.35) !important;
}

.tw-bg-rose-200\/40 {
  background-color: rgb(254 205 211 / 0.4) !important;
}

.tw-bg-rose-200\/45 {
  background-color: rgb(254 205 211 / 0.45) !important;
}

.tw-bg-rose-200\/5 {
  background-color: rgb(254 205 211 / 0.05) !important;
}

.tw-bg-rose-200\/50 {
  background-color: rgb(254 205 211 / 0.5) !important;
}

.tw-bg-rose-200\/55 {
  background-color: rgb(254 205 211 / 0.55) !important;
}

.tw-bg-rose-200\/60 {
  background-color: rgb(254 205 211 / 0.6) !important;
}

.tw-bg-rose-200\/65 {
  background-color: rgb(254 205 211 / 0.65) !important;
}

.tw-bg-rose-200\/70 {
  background-color: rgb(254 205 211 / 0.7) !important;
}

.tw-bg-rose-200\/75 {
  background-color: rgb(254 205 211 / 0.75) !important;
}

.tw-bg-rose-200\/80 {
  background-color: rgb(254 205 211 / 0.8) !important;
}

.tw-bg-rose-200\/85 {
  background-color: rgb(254 205 211 / 0.85) !important;
}

.tw-bg-rose-200\/90 {
  background-color: rgb(254 205 211 / 0.9) !important;
}

.tw-bg-rose-200\/95 {
  background-color: rgb(254 205 211 / 0.95) !important;
}

.tw-bg-rose-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 164 175 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-300\/0 {
  background-color: rgb(253 164 175 / 0) !important;
}

.tw-bg-rose-300\/10 {
  background-color: rgb(253 164 175 / 0.1) !important;
}

.tw-bg-rose-300\/100 {
  background-color: rgb(253 164 175 / 1) !important;
}

.tw-bg-rose-300\/15 {
  background-color: rgb(253 164 175 / 0.15) !important;
}

.tw-bg-rose-300\/20 {
  background-color: rgb(253 164 175 / 0.2) !important;
}

.tw-bg-rose-300\/25 {
  background-color: rgb(253 164 175 / 0.25) !important;
}

.tw-bg-rose-300\/30 {
  background-color: rgb(253 164 175 / 0.3) !important;
}

.tw-bg-rose-300\/35 {
  background-color: rgb(253 164 175 / 0.35) !important;
}

.tw-bg-rose-300\/40 {
  background-color: rgb(253 164 175 / 0.4) !important;
}

.tw-bg-rose-300\/45 {
  background-color: rgb(253 164 175 / 0.45) !important;
}

.tw-bg-rose-300\/5 {
  background-color: rgb(253 164 175 / 0.05) !important;
}

.tw-bg-rose-300\/50 {
  background-color: rgb(253 164 175 / 0.5) !important;
}

.tw-bg-rose-300\/55 {
  background-color: rgb(253 164 175 / 0.55) !important;
}

.tw-bg-rose-300\/60 {
  background-color: rgb(253 164 175 / 0.6) !important;
}

.tw-bg-rose-300\/65 {
  background-color: rgb(253 164 175 / 0.65) !important;
}

.tw-bg-rose-300\/70 {
  background-color: rgb(253 164 175 / 0.7) !important;
}

.tw-bg-rose-300\/75 {
  background-color: rgb(253 164 175 / 0.75) !important;
}

.tw-bg-rose-300\/80 {
  background-color: rgb(253 164 175 / 0.8) !important;
}

.tw-bg-rose-300\/85 {
  background-color: rgb(253 164 175 / 0.85) !important;
}

.tw-bg-rose-300\/90 {
  background-color: rgb(253 164 175 / 0.9) !important;
}

.tw-bg-rose-300\/95 {
  background-color: rgb(253 164 175 / 0.95) !important;
}

.tw-bg-rose-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(251 113 133 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-400\/0 {
  background-color: rgb(251 113 133 / 0) !important;
}

.tw-bg-rose-400\/10 {
  background-color: rgb(251 113 133 / 0.1) !important;
}

.tw-bg-rose-400\/100 {
  background-color: rgb(251 113 133 / 1) !important;
}

.tw-bg-rose-400\/15 {
  background-color: rgb(251 113 133 / 0.15) !important;
}

.tw-bg-rose-400\/20 {
  background-color: rgb(251 113 133 / 0.2) !important;
}

.tw-bg-rose-400\/25 {
  background-color: rgb(251 113 133 / 0.25) !important;
}

.tw-bg-rose-400\/30 {
  background-color: rgb(251 113 133 / 0.3) !important;
}

.tw-bg-rose-400\/35 {
  background-color: rgb(251 113 133 / 0.35) !important;
}

.tw-bg-rose-400\/40 {
  background-color: rgb(251 113 133 / 0.4) !important;
}

.tw-bg-rose-400\/45 {
  background-color: rgb(251 113 133 / 0.45) !important;
}

.tw-bg-rose-400\/5 {
  background-color: rgb(251 113 133 / 0.05) !important;
}

.tw-bg-rose-400\/50 {
  background-color: rgb(251 113 133 / 0.5) !important;
}

.tw-bg-rose-400\/55 {
  background-color: rgb(251 113 133 / 0.55) !important;
}

.tw-bg-rose-400\/60 {
  background-color: rgb(251 113 133 / 0.6) !important;
}

.tw-bg-rose-400\/65 {
  background-color: rgb(251 113 133 / 0.65) !important;
}

.tw-bg-rose-400\/70 {
  background-color: rgb(251 113 133 / 0.7) !important;
}

.tw-bg-rose-400\/75 {
  background-color: rgb(251 113 133 / 0.75) !important;
}

.tw-bg-rose-400\/80 {
  background-color: rgb(251 113 133 / 0.8) !important;
}

.tw-bg-rose-400\/85 {
  background-color: rgb(251 113 133 / 0.85) !important;
}

.tw-bg-rose-400\/90 {
  background-color: rgb(251 113 133 / 0.9) !important;
}

.tw-bg-rose-400\/95 {
  background-color: rgb(251 113 133 / 0.95) !important;
}

.tw-bg-rose-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 241 242 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-50\/0 {
  background-color: rgb(255 241 242 / 0) !important;
}

.tw-bg-rose-50\/10 {
  background-color: rgb(255 241 242 / 0.1) !important;
}

.tw-bg-rose-50\/100 {
  background-color: rgb(255 241 242 / 1) !important;
}

.tw-bg-rose-50\/15 {
  background-color: rgb(255 241 242 / 0.15) !important;
}

.tw-bg-rose-50\/20 {
  background-color: rgb(255 241 242 / 0.2) !important;
}

.tw-bg-rose-50\/25 {
  background-color: rgb(255 241 242 / 0.25) !important;
}

.tw-bg-rose-50\/30 {
  background-color: rgb(255 241 242 / 0.3) !important;
}

.tw-bg-rose-50\/35 {
  background-color: rgb(255 241 242 / 0.35) !important;
}

.tw-bg-rose-50\/40 {
  background-color: rgb(255 241 242 / 0.4) !important;
}

.tw-bg-rose-50\/45 {
  background-color: rgb(255 241 242 / 0.45) !important;
}

.tw-bg-rose-50\/5 {
  background-color: rgb(255 241 242 / 0.05) !important;
}

.tw-bg-rose-50\/50 {
  background-color: rgb(255 241 242 / 0.5) !important;
}

.tw-bg-rose-50\/55 {
  background-color: rgb(255 241 242 / 0.55) !important;
}

.tw-bg-rose-50\/60 {
  background-color: rgb(255 241 242 / 0.6) !important;
}

.tw-bg-rose-50\/65 {
  background-color: rgb(255 241 242 / 0.65) !important;
}

.tw-bg-rose-50\/70 {
  background-color: rgb(255 241 242 / 0.7) !important;
}

.tw-bg-rose-50\/75 {
  background-color: rgb(255 241 242 / 0.75) !important;
}

.tw-bg-rose-50\/80 {
  background-color: rgb(255 241 242 / 0.8) !important;
}

.tw-bg-rose-50\/85 {
  background-color: rgb(255 241 242 / 0.85) !important;
}

.tw-bg-rose-50\/90 {
  background-color: rgb(255 241 242 / 0.9) !important;
}

.tw-bg-rose-50\/95 {
  background-color: rgb(255 241 242 / 0.95) !important;
}

.tw-bg-rose-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 63 94 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-500\/0 {
  background-color: rgb(244 63 94 / 0) !important;
}

.tw-bg-rose-500\/10 {
  background-color: rgb(244 63 94 / 0.1) !important;
}

.tw-bg-rose-500\/100 {
  background-color: rgb(244 63 94 / 1) !important;
}

.tw-bg-rose-500\/15 {
  background-color: rgb(244 63 94 / 0.15) !important;
}

.tw-bg-rose-500\/20 {
  background-color: rgb(244 63 94 / 0.2) !important;
}

.tw-bg-rose-500\/25 {
  background-color: rgb(244 63 94 / 0.25) !important;
}

.tw-bg-rose-500\/30 {
  background-color: rgb(244 63 94 / 0.3) !important;
}

.tw-bg-rose-500\/35 {
  background-color: rgb(244 63 94 / 0.35) !important;
}

.tw-bg-rose-500\/40 {
  background-color: rgb(244 63 94 / 0.4) !important;
}

.tw-bg-rose-500\/45 {
  background-color: rgb(244 63 94 / 0.45) !important;
}

.tw-bg-rose-500\/5 {
  background-color: rgb(244 63 94 / 0.05) !important;
}

.tw-bg-rose-500\/50 {
  background-color: rgb(244 63 94 / 0.5) !important;
}

.tw-bg-rose-500\/55 {
  background-color: rgb(244 63 94 / 0.55) !important;
}

.tw-bg-rose-500\/60 {
  background-color: rgb(244 63 94 / 0.6) !important;
}

.tw-bg-rose-500\/65 {
  background-color: rgb(244 63 94 / 0.65) !important;
}

.tw-bg-rose-500\/70 {
  background-color: rgb(244 63 94 / 0.7) !important;
}

.tw-bg-rose-500\/75 {
  background-color: rgb(244 63 94 / 0.75) !important;
}

.tw-bg-rose-500\/80 {
  background-color: rgb(244 63 94 / 0.8) !important;
}

.tw-bg-rose-500\/85 {
  background-color: rgb(244 63 94 / 0.85) !important;
}

.tw-bg-rose-500\/90 {
  background-color: rgb(244 63 94 / 0.9) !important;
}

.tw-bg-rose-500\/95 {
  background-color: rgb(244 63 94 / 0.95) !important;
}

.tw-bg-rose-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(225 29 72 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-600\/0 {
  background-color: rgb(225 29 72 / 0) !important;
}

.tw-bg-rose-600\/10 {
  background-color: rgb(225 29 72 / 0.1) !important;
}

.tw-bg-rose-600\/100 {
  background-color: rgb(225 29 72 / 1) !important;
}

.tw-bg-rose-600\/15 {
  background-color: rgb(225 29 72 / 0.15) !important;
}

.tw-bg-rose-600\/20 {
  background-color: rgb(225 29 72 / 0.2) !important;
}

.tw-bg-rose-600\/25 {
  background-color: rgb(225 29 72 / 0.25) !important;
}

.tw-bg-rose-600\/30 {
  background-color: rgb(225 29 72 / 0.3) !important;
}

.tw-bg-rose-600\/35 {
  background-color: rgb(225 29 72 / 0.35) !important;
}

.tw-bg-rose-600\/40 {
  background-color: rgb(225 29 72 / 0.4) !important;
}

.tw-bg-rose-600\/45 {
  background-color: rgb(225 29 72 / 0.45) !important;
}

.tw-bg-rose-600\/5 {
  background-color: rgb(225 29 72 / 0.05) !important;
}

.tw-bg-rose-600\/50 {
  background-color: rgb(225 29 72 / 0.5) !important;
}

.tw-bg-rose-600\/55 {
  background-color: rgb(225 29 72 / 0.55) !important;
}

.tw-bg-rose-600\/60 {
  background-color: rgb(225 29 72 / 0.6) !important;
}

.tw-bg-rose-600\/65 {
  background-color: rgb(225 29 72 / 0.65) !important;
}

.tw-bg-rose-600\/70 {
  background-color: rgb(225 29 72 / 0.7) !important;
}

.tw-bg-rose-600\/75 {
  background-color: rgb(225 29 72 / 0.75) !important;
}

.tw-bg-rose-600\/80 {
  background-color: rgb(225 29 72 / 0.8) !important;
}

.tw-bg-rose-600\/85 {
  background-color: rgb(225 29 72 / 0.85) !important;
}

.tw-bg-rose-600\/90 {
  background-color: rgb(225 29 72 / 0.9) !important;
}

.tw-bg-rose-600\/95 {
  background-color: rgb(225 29 72 / 0.95) !important;
}

.tw-bg-rose-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(190 18 60 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-700\/0 {
  background-color: rgb(190 18 60 / 0) !important;
}

.tw-bg-rose-700\/10 {
  background-color: rgb(190 18 60 / 0.1) !important;
}

.tw-bg-rose-700\/100 {
  background-color: rgb(190 18 60 / 1) !important;
}

.tw-bg-rose-700\/15 {
  background-color: rgb(190 18 60 / 0.15) !important;
}

.tw-bg-rose-700\/20 {
  background-color: rgb(190 18 60 / 0.2) !important;
}

.tw-bg-rose-700\/25 {
  background-color: rgb(190 18 60 / 0.25) !important;
}

.tw-bg-rose-700\/30 {
  background-color: rgb(190 18 60 / 0.3) !important;
}

.tw-bg-rose-700\/35 {
  background-color: rgb(190 18 60 / 0.35) !important;
}

.tw-bg-rose-700\/40 {
  background-color: rgb(190 18 60 / 0.4) !important;
}

.tw-bg-rose-700\/45 {
  background-color: rgb(190 18 60 / 0.45) !important;
}

.tw-bg-rose-700\/5 {
  background-color: rgb(190 18 60 / 0.05) !important;
}

.tw-bg-rose-700\/50 {
  background-color: rgb(190 18 60 / 0.5) !important;
}

.tw-bg-rose-700\/55 {
  background-color: rgb(190 18 60 / 0.55) !important;
}

.tw-bg-rose-700\/60 {
  background-color: rgb(190 18 60 / 0.6) !important;
}

.tw-bg-rose-700\/65 {
  background-color: rgb(190 18 60 / 0.65) !important;
}

.tw-bg-rose-700\/70 {
  background-color: rgb(190 18 60 / 0.7) !important;
}

.tw-bg-rose-700\/75 {
  background-color: rgb(190 18 60 / 0.75) !important;
}

.tw-bg-rose-700\/80 {
  background-color: rgb(190 18 60 / 0.8) !important;
}

.tw-bg-rose-700\/85 {
  background-color: rgb(190 18 60 / 0.85) !important;
}

.tw-bg-rose-700\/90 {
  background-color: rgb(190 18 60 / 0.9) !important;
}

.tw-bg-rose-700\/95 {
  background-color: rgb(190 18 60 / 0.95) !important;
}

.tw-bg-rose-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(159 18 57 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-800\/0 {
  background-color: rgb(159 18 57 / 0) !important;
}

.tw-bg-rose-800\/10 {
  background-color: rgb(159 18 57 / 0.1) !important;
}

.tw-bg-rose-800\/100 {
  background-color: rgb(159 18 57 / 1) !important;
}

.tw-bg-rose-800\/15 {
  background-color: rgb(159 18 57 / 0.15) !important;
}

.tw-bg-rose-800\/20 {
  background-color: rgb(159 18 57 / 0.2) !important;
}

.tw-bg-rose-800\/25 {
  background-color: rgb(159 18 57 / 0.25) !important;
}

.tw-bg-rose-800\/30 {
  background-color: rgb(159 18 57 / 0.3) !important;
}

.tw-bg-rose-800\/35 {
  background-color: rgb(159 18 57 / 0.35) !important;
}

.tw-bg-rose-800\/40 {
  background-color: rgb(159 18 57 / 0.4) !important;
}

.tw-bg-rose-800\/45 {
  background-color: rgb(159 18 57 / 0.45) !important;
}

.tw-bg-rose-800\/5 {
  background-color: rgb(159 18 57 / 0.05) !important;
}

.tw-bg-rose-800\/50 {
  background-color: rgb(159 18 57 / 0.5) !important;
}

.tw-bg-rose-800\/55 {
  background-color: rgb(159 18 57 / 0.55) !important;
}

.tw-bg-rose-800\/60 {
  background-color: rgb(159 18 57 / 0.6) !important;
}

.tw-bg-rose-800\/65 {
  background-color: rgb(159 18 57 / 0.65) !important;
}

.tw-bg-rose-800\/70 {
  background-color: rgb(159 18 57 / 0.7) !important;
}

.tw-bg-rose-800\/75 {
  background-color: rgb(159 18 57 / 0.75) !important;
}

.tw-bg-rose-800\/80 {
  background-color: rgb(159 18 57 / 0.8) !important;
}

.tw-bg-rose-800\/85 {
  background-color: rgb(159 18 57 / 0.85) !important;
}

.tw-bg-rose-800\/90 {
  background-color: rgb(159 18 57 / 0.9) !important;
}

.tw-bg-rose-800\/95 {
  background-color: rgb(159 18 57 / 0.95) !important;
}

.tw-bg-rose-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(136 19 55 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-900\/0 {
  background-color: rgb(136 19 55 / 0) !important;
}

.tw-bg-rose-900\/10 {
  background-color: rgb(136 19 55 / 0.1) !important;
}

.tw-bg-rose-900\/100 {
  background-color: rgb(136 19 55 / 1) !important;
}

.tw-bg-rose-900\/15 {
  background-color: rgb(136 19 55 / 0.15) !important;
}

.tw-bg-rose-900\/20 {
  background-color: rgb(136 19 55 / 0.2) !important;
}

.tw-bg-rose-900\/25 {
  background-color: rgb(136 19 55 / 0.25) !important;
}

.tw-bg-rose-900\/30 {
  background-color: rgb(136 19 55 / 0.3) !important;
}

.tw-bg-rose-900\/35 {
  background-color: rgb(136 19 55 / 0.35) !important;
}

.tw-bg-rose-900\/40 {
  background-color: rgb(136 19 55 / 0.4) !important;
}

.tw-bg-rose-900\/45 {
  background-color: rgb(136 19 55 / 0.45) !important;
}

.tw-bg-rose-900\/5 {
  background-color: rgb(136 19 55 / 0.05) !important;
}

.tw-bg-rose-900\/50 {
  background-color: rgb(136 19 55 / 0.5) !important;
}

.tw-bg-rose-900\/55 {
  background-color: rgb(136 19 55 / 0.55) !important;
}

.tw-bg-rose-900\/60 {
  background-color: rgb(136 19 55 / 0.6) !important;
}

.tw-bg-rose-900\/65 {
  background-color: rgb(136 19 55 / 0.65) !important;
}

.tw-bg-rose-900\/70 {
  background-color: rgb(136 19 55 / 0.7) !important;
}

.tw-bg-rose-900\/75 {
  background-color: rgb(136 19 55 / 0.75) !important;
}

.tw-bg-rose-900\/80 {
  background-color: rgb(136 19 55 / 0.8) !important;
}

.tw-bg-rose-900\/85 {
  background-color: rgb(136 19 55 / 0.85) !important;
}

.tw-bg-rose-900\/90 {
  background-color: rgb(136 19 55 / 0.9) !important;
}

.tw-bg-rose-900\/95 {
  background-color: rgb(136 19 55 / 0.95) !important;
}

.tw-bg-rose-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(76 5 25 / var(--tw-bg-opacity)) !important;
}

.tw-bg-rose-950\/0 {
  background-color: rgb(76 5 25 / 0) !important;
}

.tw-bg-rose-950\/10 {
  background-color: rgb(76 5 25 / 0.1) !important;
}

.tw-bg-rose-950\/100 {
  background-color: rgb(76 5 25 / 1) !important;
}

.tw-bg-rose-950\/15 {
  background-color: rgb(76 5 25 / 0.15) !important;
}

.tw-bg-rose-950\/20 {
  background-color: rgb(76 5 25 / 0.2) !important;
}

.tw-bg-rose-950\/25 {
  background-color: rgb(76 5 25 / 0.25) !important;
}

.tw-bg-rose-950\/30 {
  background-color: rgb(76 5 25 / 0.3) !important;
}

.tw-bg-rose-950\/35 {
  background-color: rgb(76 5 25 / 0.35) !important;
}

.tw-bg-rose-950\/40 {
  background-color: rgb(76 5 25 / 0.4) !important;
}

.tw-bg-rose-950\/45 {
  background-color: rgb(76 5 25 / 0.45) !important;
}

.tw-bg-rose-950\/5 {
  background-color: rgb(76 5 25 / 0.05) !important;
}

.tw-bg-rose-950\/50 {
  background-color: rgb(76 5 25 / 0.5) !important;
}

.tw-bg-rose-950\/55 {
  background-color: rgb(76 5 25 / 0.55) !important;
}

.tw-bg-rose-950\/60 {
  background-color: rgb(76 5 25 / 0.6) !important;
}

.tw-bg-rose-950\/65 {
  background-color: rgb(76 5 25 / 0.65) !important;
}

.tw-bg-rose-950\/70 {
  background-color: rgb(76 5 25 / 0.7) !important;
}

.tw-bg-rose-950\/75 {
  background-color: rgb(76 5 25 / 0.75) !important;
}

.tw-bg-rose-950\/80 {
  background-color: rgb(76 5 25 / 0.8) !important;
}

.tw-bg-rose-950\/85 {
  background-color: rgb(76 5 25 / 0.85) !important;
}

.tw-bg-rose-950\/90 {
  background-color: rgb(76 5 25 / 0.9) !important;
}

.tw-bg-rose-950\/95 {
  background-color: rgb(76 5 25 / 0.95) !important;
}

.tw-bg-sky-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(224 242 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-100\/0 {
  background-color: rgb(224 242 254 / 0) !important;
}

.tw-bg-sky-100\/10 {
  background-color: rgb(224 242 254 / 0.1) !important;
}

.tw-bg-sky-100\/100 {
  background-color: rgb(224 242 254 / 1) !important;
}

.tw-bg-sky-100\/15 {
  background-color: rgb(224 242 254 / 0.15) !important;
}

.tw-bg-sky-100\/20 {
  background-color: rgb(224 242 254 / 0.2) !important;
}

.tw-bg-sky-100\/25 {
  background-color: rgb(224 242 254 / 0.25) !important;
}

.tw-bg-sky-100\/30 {
  background-color: rgb(224 242 254 / 0.3) !important;
}

.tw-bg-sky-100\/35 {
  background-color: rgb(224 242 254 / 0.35) !important;
}

.tw-bg-sky-100\/40 {
  background-color: rgb(224 242 254 / 0.4) !important;
}

.tw-bg-sky-100\/45 {
  background-color: rgb(224 242 254 / 0.45) !important;
}

.tw-bg-sky-100\/5 {
  background-color: rgb(224 242 254 / 0.05) !important;
}

.tw-bg-sky-100\/50 {
  background-color: rgb(224 242 254 / 0.5) !important;
}

.tw-bg-sky-100\/55 {
  background-color: rgb(224 242 254 / 0.55) !important;
}

.tw-bg-sky-100\/60 {
  background-color: rgb(224 242 254 / 0.6) !important;
}

.tw-bg-sky-100\/65 {
  background-color: rgb(224 242 254 / 0.65) !important;
}

.tw-bg-sky-100\/70 {
  background-color: rgb(224 242 254 / 0.7) !important;
}

.tw-bg-sky-100\/75 {
  background-color: rgb(224 242 254 / 0.75) !important;
}

.tw-bg-sky-100\/80 {
  background-color: rgb(224 242 254 / 0.8) !important;
}

.tw-bg-sky-100\/85 {
  background-color: rgb(224 242 254 / 0.85) !important;
}

.tw-bg-sky-100\/90 {
  background-color: rgb(224 242 254 / 0.9) !important;
}

.tw-bg-sky-100\/95 {
  background-color: rgb(224 242 254 / 0.95) !important;
}

.tw-bg-sky-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-200\/0 {
  background-color: rgb(186 230 253 / 0) !important;
}

.tw-bg-sky-200\/10 {
  background-color: rgb(186 230 253 / 0.1) !important;
}

.tw-bg-sky-200\/100 {
  background-color: rgb(186 230 253 / 1) !important;
}

.tw-bg-sky-200\/15 {
  background-color: rgb(186 230 253 / 0.15) !important;
}

.tw-bg-sky-200\/20 {
  background-color: rgb(186 230 253 / 0.2) !important;
}

.tw-bg-sky-200\/25 {
  background-color: rgb(186 230 253 / 0.25) !important;
}

.tw-bg-sky-200\/30 {
  background-color: rgb(186 230 253 / 0.3) !important;
}

.tw-bg-sky-200\/35 {
  background-color: rgb(186 230 253 / 0.35) !important;
}

.tw-bg-sky-200\/40 {
  background-color: rgb(186 230 253 / 0.4) !important;
}

.tw-bg-sky-200\/45 {
  background-color: rgb(186 230 253 / 0.45) !important;
}

.tw-bg-sky-200\/5 {
  background-color: rgb(186 230 253 / 0.05) !important;
}

.tw-bg-sky-200\/50 {
  background-color: rgb(186 230 253 / 0.5) !important;
}

.tw-bg-sky-200\/55 {
  background-color: rgb(186 230 253 / 0.55) !important;
}

.tw-bg-sky-200\/60 {
  background-color: rgb(186 230 253 / 0.6) !important;
}

.tw-bg-sky-200\/65 {
  background-color: rgb(186 230 253 / 0.65) !important;
}

.tw-bg-sky-200\/70 {
  background-color: rgb(186 230 253 / 0.7) !important;
}

.tw-bg-sky-200\/75 {
  background-color: rgb(186 230 253 / 0.75) !important;
}

.tw-bg-sky-200\/80 {
  background-color: rgb(186 230 253 / 0.8) !important;
}

.tw-bg-sky-200\/85 {
  background-color: rgb(186 230 253 / 0.85) !important;
}

.tw-bg-sky-200\/90 {
  background-color: rgb(186 230 253 / 0.9) !important;
}

.tw-bg-sky-200\/95 {
  background-color: rgb(186 230 253 / 0.95) !important;
}

.tw-bg-sky-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(125 211 252 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-300\/0 {
  background-color: rgb(125 211 252 / 0) !important;
}

.tw-bg-sky-300\/10 {
  background-color: rgb(125 211 252 / 0.1) !important;
}

.tw-bg-sky-300\/100 {
  background-color: rgb(125 211 252 / 1) !important;
}

.tw-bg-sky-300\/15 {
  background-color: rgb(125 211 252 / 0.15) !important;
}

.tw-bg-sky-300\/20 {
  background-color: rgb(125 211 252 / 0.2) !important;
}

.tw-bg-sky-300\/25 {
  background-color: rgb(125 211 252 / 0.25) !important;
}

.tw-bg-sky-300\/30 {
  background-color: rgb(125 211 252 / 0.3) !important;
}

.tw-bg-sky-300\/35 {
  background-color: rgb(125 211 252 / 0.35) !important;
}

.tw-bg-sky-300\/40 {
  background-color: rgb(125 211 252 / 0.4) !important;
}

.tw-bg-sky-300\/45 {
  background-color: rgb(125 211 252 / 0.45) !important;
}

.tw-bg-sky-300\/5 {
  background-color: rgb(125 211 252 / 0.05) !important;
}

.tw-bg-sky-300\/50 {
  background-color: rgb(125 211 252 / 0.5) !important;
}

.tw-bg-sky-300\/55 {
  background-color: rgb(125 211 252 / 0.55) !important;
}

.tw-bg-sky-300\/60 {
  background-color: rgb(125 211 252 / 0.6) !important;
}

.tw-bg-sky-300\/65 {
  background-color: rgb(125 211 252 / 0.65) !important;
}

.tw-bg-sky-300\/70 {
  background-color: rgb(125 211 252 / 0.7) !important;
}

.tw-bg-sky-300\/75 {
  background-color: rgb(125 211 252 / 0.75) !important;
}

.tw-bg-sky-300\/80 {
  background-color: rgb(125 211 252 / 0.8) !important;
}

.tw-bg-sky-300\/85 {
  background-color: rgb(125 211 252 / 0.85) !important;
}

.tw-bg-sky-300\/90 {
  background-color: rgb(125 211 252 / 0.9) !important;
}

.tw-bg-sky-300\/95 {
  background-color: rgb(125 211 252 / 0.95) !important;
}

.tw-bg-sky-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(56 189 248 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-400\/0 {
  background-color: rgb(56 189 248 / 0) !important;
}

.tw-bg-sky-400\/10 {
  background-color: rgb(56 189 248 / 0.1) !important;
}

.tw-bg-sky-400\/100 {
  background-color: rgb(56 189 248 / 1) !important;
}

.tw-bg-sky-400\/15 {
  background-color: rgb(56 189 248 / 0.15) !important;
}

.tw-bg-sky-400\/20 {
  background-color: rgb(56 189 248 / 0.2) !important;
}

.tw-bg-sky-400\/25 {
  background-color: rgb(56 189 248 / 0.25) !important;
}

.tw-bg-sky-400\/30 {
  background-color: rgb(56 189 248 / 0.3) !important;
}

.tw-bg-sky-400\/35 {
  background-color: rgb(56 189 248 / 0.35) !important;
}

.tw-bg-sky-400\/40 {
  background-color: rgb(56 189 248 / 0.4) !important;
}

.tw-bg-sky-400\/45 {
  background-color: rgb(56 189 248 / 0.45) !important;
}

.tw-bg-sky-400\/5 {
  background-color: rgb(56 189 248 / 0.05) !important;
}

.tw-bg-sky-400\/50 {
  background-color: rgb(56 189 248 / 0.5) !important;
}

.tw-bg-sky-400\/55 {
  background-color: rgb(56 189 248 / 0.55) !important;
}

.tw-bg-sky-400\/60 {
  background-color: rgb(56 189 248 / 0.6) !important;
}

.tw-bg-sky-400\/65 {
  background-color: rgb(56 189 248 / 0.65) !important;
}

.tw-bg-sky-400\/70 {
  background-color: rgb(56 189 248 / 0.7) !important;
}

.tw-bg-sky-400\/75 {
  background-color: rgb(56 189 248 / 0.75) !important;
}

.tw-bg-sky-400\/80 {
  background-color: rgb(56 189 248 / 0.8) !important;
}

.tw-bg-sky-400\/85 {
  background-color: rgb(56 189 248 / 0.85) !important;
}

.tw-bg-sky-400\/90 {
  background-color: rgb(56 189 248 / 0.9) !important;
}

.tw-bg-sky-400\/95 {
  background-color: rgb(56 189 248 / 0.95) !important;
}

.tw-bg-sky-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 249 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-50\/0 {
  background-color: rgb(240 249 255 / 0) !important;
}

.tw-bg-sky-50\/10 {
  background-color: rgb(240 249 255 / 0.1) !important;
}

.tw-bg-sky-50\/100 {
  background-color: rgb(240 249 255 / 1) !important;
}

.tw-bg-sky-50\/15 {
  background-color: rgb(240 249 255 / 0.15) !important;
}

.tw-bg-sky-50\/20 {
  background-color: rgb(240 249 255 / 0.2) !important;
}

.tw-bg-sky-50\/25 {
  background-color: rgb(240 249 255 / 0.25) !important;
}

.tw-bg-sky-50\/30 {
  background-color: rgb(240 249 255 / 0.3) !important;
}

.tw-bg-sky-50\/35 {
  background-color: rgb(240 249 255 / 0.35) !important;
}

.tw-bg-sky-50\/40 {
  background-color: rgb(240 249 255 / 0.4) !important;
}

.tw-bg-sky-50\/45 {
  background-color: rgb(240 249 255 / 0.45) !important;
}

.tw-bg-sky-50\/5 {
  background-color: rgb(240 249 255 / 0.05) !important;
}

.tw-bg-sky-50\/50 {
  background-color: rgb(240 249 255 / 0.5) !important;
}

.tw-bg-sky-50\/55 {
  background-color: rgb(240 249 255 / 0.55) !important;
}

.tw-bg-sky-50\/60 {
  background-color: rgb(240 249 255 / 0.6) !important;
}

.tw-bg-sky-50\/65 {
  background-color: rgb(240 249 255 / 0.65) !important;
}

.tw-bg-sky-50\/70 {
  background-color: rgb(240 249 255 / 0.7) !important;
}

.tw-bg-sky-50\/75 {
  background-color: rgb(240 249 255 / 0.75) !important;
}

.tw-bg-sky-50\/80 {
  background-color: rgb(240 249 255 / 0.8) !important;
}

.tw-bg-sky-50\/85 {
  background-color: rgb(240 249 255 / 0.85) !important;
}

.tw-bg-sky-50\/90 {
  background-color: rgb(240 249 255 / 0.9) !important;
}

.tw-bg-sky-50\/95 {
  background-color: rgb(240 249 255 / 0.95) !important;
}

.tw-bg-sky-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(14 165 233 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-500\/0 {
  background-color: rgb(14 165 233 / 0) !important;
}

.tw-bg-sky-500\/10 {
  background-color: rgb(14 165 233 / 0.1) !important;
}

.tw-bg-sky-500\/100 {
  background-color: rgb(14 165 233 / 1) !important;
}

.tw-bg-sky-500\/15 {
  background-color: rgb(14 165 233 / 0.15) !important;
}

.tw-bg-sky-500\/20 {
  background-color: rgb(14 165 233 / 0.2) !important;
}

.tw-bg-sky-500\/25 {
  background-color: rgb(14 165 233 / 0.25) !important;
}

.tw-bg-sky-500\/30 {
  background-color: rgb(14 165 233 / 0.3) !important;
}

.tw-bg-sky-500\/35 {
  background-color: rgb(14 165 233 / 0.35) !important;
}

.tw-bg-sky-500\/40 {
  background-color: rgb(14 165 233 / 0.4) !important;
}

.tw-bg-sky-500\/45 {
  background-color: rgb(14 165 233 / 0.45) !important;
}

.tw-bg-sky-500\/5 {
  background-color: rgb(14 165 233 / 0.05) !important;
}

.tw-bg-sky-500\/50 {
  background-color: rgb(14 165 233 / 0.5) !important;
}

.tw-bg-sky-500\/55 {
  background-color: rgb(14 165 233 / 0.55) !important;
}

.tw-bg-sky-500\/60 {
  background-color: rgb(14 165 233 / 0.6) !important;
}

.tw-bg-sky-500\/65 {
  background-color: rgb(14 165 233 / 0.65) !important;
}

.tw-bg-sky-500\/70 {
  background-color: rgb(14 165 233 / 0.7) !important;
}

.tw-bg-sky-500\/75 {
  background-color: rgb(14 165 233 / 0.75) !important;
}

.tw-bg-sky-500\/80 {
  background-color: rgb(14 165 233 / 0.8) !important;
}

.tw-bg-sky-500\/85 {
  background-color: rgb(14 165 233 / 0.85) !important;
}

.tw-bg-sky-500\/90 {
  background-color: rgb(14 165 233 / 0.9) !important;
}

.tw-bg-sky-500\/95 {
  background-color: rgb(14 165 233 / 0.95) !important;
}

.tw-bg-sky-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 132 199 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-600\/0 {
  background-color: rgb(2 132 199 / 0) !important;
}

.tw-bg-sky-600\/10 {
  background-color: rgb(2 132 199 / 0.1) !important;
}

.tw-bg-sky-600\/100 {
  background-color: rgb(2 132 199 / 1) !important;
}

.tw-bg-sky-600\/15 {
  background-color: rgb(2 132 199 / 0.15) !important;
}

.tw-bg-sky-600\/20 {
  background-color: rgb(2 132 199 / 0.2) !important;
}

.tw-bg-sky-600\/25 {
  background-color: rgb(2 132 199 / 0.25) !important;
}

.tw-bg-sky-600\/30 {
  background-color: rgb(2 132 199 / 0.3) !important;
}

.tw-bg-sky-600\/35 {
  background-color: rgb(2 132 199 / 0.35) !important;
}

.tw-bg-sky-600\/40 {
  background-color: rgb(2 132 199 / 0.4) !important;
}

.tw-bg-sky-600\/45 {
  background-color: rgb(2 132 199 / 0.45) !important;
}

.tw-bg-sky-600\/5 {
  background-color: rgb(2 132 199 / 0.05) !important;
}

.tw-bg-sky-600\/50 {
  background-color: rgb(2 132 199 / 0.5) !important;
}

.tw-bg-sky-600\/55 {
  background-color: rgb(2 132 199 / 0.55) !important;
}

.tw-bg-sky-600\/60 {
  background-color: rgb(2 132 199 / 0.6) !important;
}

.tw-bg-sky-600\/65 {
  background-color: rgb(2 132 199 / 0.65) !important;
}

.tw-bg-sky-600\/70 {
  background-color: rgb(2 132 199 / 0.7) !important;
}

.tw-bg-sky-600\/75 {
  background-color: rgb(2 132 199 / 0.75) !important;
}

.tw-bg-sky-600\/80 {
  background-color: rgb(2 132 199 / 0.8) !important;
}

.tw-bg-sky-600\/85 {
  background-color: rgb(2 132 199 / 0.85) !important;
}

.tw-bg-sky-600\/90 {
  background-color: rgb(2 132 199 / 0.9) !important;
}

.tw-bg-sky-600\/95 {
  background-color: rgb(2 132 199 / 0.95) !important;
}

.tw-bg-sky-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(3 105 161 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-700\/0 {
  background-color: rgb(3 105 161 / 0) !important;
}

.tw-bg-sky-700\/10 {
  background-color: rgb(3 105 161 / 0.1) !important;
}

.tw-bg-sky-700\/100 {
  background-color: rgb(3 105 161 / 1) !important;
}

.tw-bg-sky-700\/15 {
  background-color: rgb(3 105 161 / 0.15) !important;
}

.tw-bg-sky-700\/20 {
  background-color: rgb(3 105 161 / 0.2) !important;
}

.tw-bg-sky-700\/25 {
  background-color: rgb(3 105 161 / 0.25) !important;
}

.tw-bg-sky-700\/30 {
  background-color: rgb(3 105 161 / 0.3) !important;
}

.tw-bg-sky-700\/35 {
  background-color: rgb(3 105 161 / 0.35) !important;
}

.tw-bg-sky-700\/40 {
  background-color: rgb(3 105 161 / 0.4) !important;
}

.tw-bg-sky-700\/45 {
  background-color: rgb(3 105 161 / 0.45) !important;
}

.tw-bg-sky-700\/5 {
  background-color: rgb(3 105 161 / 0.05) !important;
}

.tw-bg-sky-700\/50 {
  background-color: rgb(3 105 161 / 0.5) !important;
}

.tw-bg-sky-700\/55 {
  background-color: rgb(3 105 161 / 0.55) !important;
}

.tw-bg-sky-700\/60 {
  background-color: rgb(3 105 161 / 0.6) !important;
}

.tw-bg-sky-700\/65 {
  background-color: rgb(3 105 161 / 0.65) !important;
}

.tw-bg-sky-700\/70 {
  background-color: rgb(3 105 161 / 0.7) !important;
}

.tw-bg-sky-700\/75 {
  background-color: rgb(3 105 161 / 0.75) !important;
}

.tw-bg-sky-700\/80 {
  background-color: rgb(3 105 161 / 0.8) !important;
}

.tw-bg-sky-700\/85 {
  background-color: rgb(3 105 161 / 0.85) !important;
}

.tw-bg-sky-700\/90 {
  background-color: rgb(3 105 161 / 0.9) !important;
}

.tw-bg-sky-700\/95 {
  background-color: rgb(3 105 161 / 0.95) !important;
}

.tw-bg-sky-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(7 89 133 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-800\/0 {
  background-color: rgb(7 89 133 / 0) !important;
}

.tw-bg-sky-800\/10 {
  background-color: rgb(7 89 133 / 0.1) !important;
}

.tw-bg-sky-800\/100 {
  background-color: rgb(7 89 133 / 1) !important;
}

.tw-bg-sky-800\/15 {
  background-color: rgb(7 89 133 / 0.15) !important;
}

.tw-bg-sky-800\/20 {
  background-color: rgb(7 89 133 / 0.2) !important;
}

.tw-bg-sky-800\/25 {
  background-color: rgb(7 89 133 / 0.25) !important;
}

.tw-bg-sky-800\/30 {
  background-color: rgb(7 89 133 / 0.3) !important;
}

.tw-bg-sky-800\/35 {
  background-color: rgb(7 89 133 / 0.35) !important;
}

.tw-bg-sky-800\/40 {
  background-color: rgb(7 89 133 / 0.4) !important;
}

.tw-bg-sky-800\/45 {
  background-color: rgb(7 89 133 / 0.45) !important;
}

.tw-bg-sky-800\/5 {
  background-color: rgb(7 89 133 / 0.05) !important;
}

.tw-bg-sky-800\/50 {
  background-color: rgb(7 89 133 / 0.5) !important;
}

.tw-bg-sky-800\/55 {
  background-color: rgb(7 89 133 / 0.55) !important;
}

.tw-bg-sky-800\/60 {
  background-color: rgb(7 89 133 / 0.6) !important;
}

.tw-bg-sky-800\/65 {
  background-color: rgb(7 89 133 / 0.65) !important;
}

.tw-bg-sky-800\/70 {
  background-color: rgb(7 89 133 / 0.7) !important;
}

.tw-bg-sky-800\/75 {
  background-color: rgb(7 89 133 / 0.75) !important;
}

.tw-bg-sky-800\/80 {
  background-color: rgb(7 89 133 / 0.8) !important;
}

.tw-bg-sky-800\/85 {
  background-color: rgb(7 89 133 / 0.85) !important;
}

.tw-bg-sky-800\/90 {
  background-color: rgb(7 89 133 / 0.9) !important;
}

.tw-bg-sky-800\/95 {
  background-color: rgb(7 89 133 / 0.95) !important;
}

.tw-bg-sky-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(12 74 110 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-900\/0 {
  background-color: rgb(12 74 110 / 0) !important;
}

.tw-bg-sky-900\/10 {
  background-color: rgb(12 74 110 / 0.1) !important;
}

.tw-bg-sky-900\/100 {
  background-color: rgb(12 74 110 / 1) !important;
}

.tw-bg-sky-900\/15 {
  background-color: rgb(12 74 110 / 0.15) !important;
}

.tw-bg-sky-900\/20 {
  background-color: rgb(12 74 110 / 0.2) !important;
}

.tw-bg-sky-900\/25 {
  background-color: rgb(12 74 110 / 0.25) !important;
}

.tw-bg-sky-900\/30 {
  background-color: rgb(12 74 110 / 0.3) !important;
}

.tw-bg-sky-900\/35 {
  background-color: rgb(12 74 110 / 0.35) !important;
}

.tw-bg-sky-900\/40 {
  background-color: rgb(12 74 110 / 0.4) !important;
}

.tw-bg-sky-900\/45 {
  background-color: rgb(12 74 110 / 0.45) !important;
}

.tw-bg-sky-900\/5 {
  background-color: rgb(12 74 110 / 0.05) !important;
}

.tw-bg-sky-900\/50 {
  background-color: rgb(12 74 110 / 0.5) !important;
}

.tw-bg-sky-900\/55 {
  background-color: rgb(12 74 110 / 0.55) !important;
}

.tw-bg-sky-900\/60 {
  background-color: rgb(12 74 110 / 0.6) !important;
}

.tw-bg-sky-900\/65 {
  background-color: rgb(12 74 110 / 0.65) !important;
}

.tw-bg-sky-900\/70 {
  background-color: rgb(12 74 110 / 0.7) !important;
}

.tw-bg-sky-900\/75 {
  background-color: rgb(12 74 110 / 0.75) !important;
}

.tw-bg-sky-900\/80 {
  background-color: rgb(12 74 110 / 0.8) !important;
}

.tw-bg-sky-900\/85 {
  background-color: rgb(12 74 110 / 0.85) !important;
}

.tw-bg-sky-900\/90 {
  background-color: rgb(12 74 110 / 0.9) !important;
}

.tw-bg-sky-900\/95 {
  background-color: rgb(12 74 110 / 0.95) !important;
}

.tw-bg-sky-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(8 47 73 / var(--tw-bg-opacity)) !important;
}

.tw-bg-sky-950\/0 {
  background-color: rgb(8 47 73 / 0) !important;
}

.tw-bg-sky-950\/10 {
  background-color: rgb(8 47 73 / 0.1) !important;
}

.tw-bg-sky-950\/100 {
  background-color: rgb(8 47 73 / 1) !important;
}

.tw-bg-sky-950\/15 {
  background-color: rgb(8 47 73 / 0.15) !important;
}

.tw-bg-sky-950\/20 {
  background-color: rgb(8 47 73 / 0.2) !important;
}

.tw-bg-sky-950\/25 {
  background-color: rgb(8 47 73 / 0.25) !important;
}

.tw-bg-sky-950\/30 {
  background-color: rgb(8 47 73 / 0.3) !important;
}

.tw-bg-sky-950\/35 {
  background-color: rgb(8 47 73 / 0.35) !important;
}

.tw-bg-sky-950\/40 {
  background-color: rgb(8 47 73 / 0.4) !important;
}

.tw-bg-sky-950\/45 {
  background-color: rgb(8 47 73 / 0.45) !important;
}

.tw-bg-sky-950\/5 {
  background-color: rgb(8 47 73 / 0.05) !important;
}

.tw-bg-sky-950\/50 {
  background-color: rgb(8 47 73 / 0.5) !important;
}

.tw-bg-sky-950\/55 {
  background-color: rgb(8 47 73 / 0.55) !important;
}

.tw-bg-sky-950\/60 {
  background-color: rgb(8 47 73 / 0.6) !important;
}

.tw-bg-sky-950\/65 {
  background-color: rgb(8 47 73 / 0.65) !important;
}

.tw-bg-sky-950\/70 {
  background-color: rgb(8 47 73 / 0.7) !important;
}

.tw-bg-sky-950\/75 {
  background-color: rgb(8 47 73 / 0.75) !important;
}

.tw-bg-sky-950\/80 {
  background-color: rgb(8 47 73 / 0.8) !important;
}

.tw-bg-sky-950\/85 {
  background-color: rgb(8 47 73 / 0.85) !important;
}

.tw-bg-sky-950\/90 {
  background-color: rgb(8 47 73 / 0.9) !important;
}

.tw-bg-sky-950\/95 {
  background-color: rgb(8 47 73 / 0.95) !important;
}

.tw-bg-slate-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(241 245 249 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-100\/0 {
  background-color: rgb(241 245 249 / 0) !important;
}

.tw-bg-slate-100\/10 {
  background-color: rgb(241 245 249 / 0.1) !important;
}

.tw-bg-slate-100\/100 {
  background-color: rgb(241 245 249 / 1) !important;
}

.tw-bg-slate-100\/15 {
  background-color: rgb(241 245 249 / 0.15) !important;
}

.tw-bg-slate-100\/20 {
  background-color: rgb(241 245 249 / 0.2) !important;
}

.tw-bg-slate-100\/25 {
  background-color: rgb(241 245 249 / 0.25) !important;
}

.tw-bg-slate-100\/30 {
  background-color: rgb(241 245 249 / 0.3) !important;
}

.tw-bg-slate-100\/35 {
  background-color: rgb(241 245 249 / 0.35) !important;
}

.tw-bg-slate-100\/40 {
  background-color: rgb(241 245 249 / 0.4) !important;
}

.tw-bg-slate-100\/45 {
  background-color: rgb(241 245 249 / 0.45) !important;
}

.tw-bg-slate-100\/5 {
  background-color: rgb(241 245 249 / 0.05) !important;
}

.tw-bg-slate-100\/50 {
  background-color: rgb(241 245 249 / 0.5) !important;
}

.tw-bg-slate-100\/55 {
  background-color: rgb(241 245 249 / 0.55) !important;
}

.tw-bg-slate-100\/60 {
  background-color: rgb(241 245 249 / 0.6) !important;
}

.tw-bg-slate-100\/65 {
  background-color: rgb(241 245 249 / 0.65) !important;
}

.tw-bg-slate-100\/70 {
  background-color: rgb(241 245 249 / 0.7) !important;
}

.tw-bg-slate-100\/75 {
  background-color: rgb(241 245 249 / 0.75) !important;
}

.tw-bg-slate-100\/80 {
  background-color: rgb(241 245 249 / 0.8) !important;
}

.tw-bg-slate-100\/85 {
  background-color: rgb(241 245 249 / 0.85) !important;
}

.tw-bg-slate-100\/90 {
  background-color: rgb(241 245 249 / 0.9) !important;
}

.tw-bg-slate-100\/95 {
  background-color: rgb(241 245 249 / 0.95) !important;
}

.tw-bg-slate-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(226 232 240 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-200\/0 {
  background-color: rgb(226 232 240 / 0) !important;
}

.tw-bg-slate-200\/10 {
  background-color: rgb(226 232 240 / 0.1) !important;
}

.tw-bg-slate-200\/100 {
  background-color: rgb(226 232 240 / 1) !important;
}

.tw-bg-slate-200\/15 {
  background-color: rgb(226 232 240 / 0.15) !important;
}

.tw-bg-slate-200\/20 {
  background-color: rgb(226 232 240 / 0.2) !important;
}

.tw-bg-slate-200\/25 {
  background-color: rgb(226 232 240 / 0.25) !important;
}

.tw-bg-slate-200\/30 {
  background-color: rgb(226 232 240 / 0.3) !important;
}

.tw-bg-slate-200\/35 {
  background-color: rgb(226 232 240 / 0.35) !important;
}

.tw-bg-slate-200\/40 {
  background-color: rgb(226 232 240 / 0.4) !important;
}

.tw-bg-slate-200\/45 {
  background-color: rgb(226 232 240 / 0.45) !important;
}

.tw-bg-slate-200\/5 {
  background-color: rgb(226 232 240 / 0.05) !important;
}

.tw-bg-slate-200\/50 {
  background-color: rgb(226 232 240 / 0.5) !important;
}

.tw-bg-slate-200\/55 {
  background-color: rgb(226 232 240 / 0.55) !important;
}

.tw-bg-slate-200\/60 {
  background-color: rgb(226 232 240 / 0.6) !important;
}

.tw-bg-slate-200\/65 {
  background-color: rgb(226 232 240 / 0.65) !important;
}

.tw-bg-slate-200\/70 {
  background-color: rgb(226 232 240 / 0.7) !important;
}

.tw-bg-slate-200\/75 {
  background-color: rgb(226 232 240 / 0.75) !important;
}

.tw-bg-slate-200\/80 {
  background-color: rgb(226 232 240 / 0.8) !important;
}

.tw-bg-slate-200\/85 {
  background-color: rgb(226 232 240 / 0.85) !important;
}

.tw-bg-slate-200\/90 {
  background-color: rgb(226 232 240 / 0.9) !important;
}

.tw-bg-slate-200\/95 {
  background-color: rgb(226 232 240 / 0.95) !important;
}

.tw-bg-slate-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(203 213 225 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-300\/0 {
  background-color: rgb(203 213 225 / 0) !important;
}

.tw-bg-slate-300\/10 {
  background-color: rgb(203 213 225 / 0.1) !important;
}

.tw-bg-slate-300\/100 {
  background-color: rgb(203 213 225 / 1) !important;
}

.tw-bg-slate-300\/15 {
  background-color: rgb(203 213 225 / 0.15) !important;
}

.tw-bg-slate-300\/20 {
  background-color: rgb(203 213 225 / 0.2) !important;
}

.tw-bg-slate-300\/25 {
  background-color: rgb(203 213 225 / 0.25) !important;
}

.tw-bg-slate-300\/30 {
  background-color: rgb(203 213 225 / 0.3) !important;
}

.tw-bg-slate-300\/35 {
  background-color: rgb(203 213 225 / 0.35) !important;
}

.tw-bg-slate-300\/40 {
  background-color: rgb(203 213 225 / 0.4) !important;
}

.tw-bg-slate-300\/45 {
  background-color: rgb(203 213 225 / 0.45) !important;
}

.tw-bg-slate-300\/5 {
  background-color: rgb(203 213 225 / 0.05) !important;
}

.tw-bg-slate-300\/50 {
  background-color: rgb(203 213 225 / 0.5) !important;
}

.tw-bg-slate-300\/55 {
  background-color: rgb(203 213 225 / 0.55) !important;
}

.tw-bg-slate-300\/60 {
  background-color: rgb(203 213 225 / 0.6) !important;
}

.tw-bg-slate-300\/65 {
  background-color: rgb(203 213 225 / 0.65) !important;
}

.tw-bg-slate-300\/70 {
  background-color: rgb(203 213 225 / 0.7) !important;
}

.tw-bg-slate-300\/75 {
  background-color: rgb(203 213 225 / 0.75) !important;
}

.tw-bg-slate-300\/80 {
  background-color: rgb(203 213 225 / 0.8) !important;
}

.tw-bg-slate-300\/85 {
  background-color: rgb(203 213 225 / 0.85) !important;
}

.tw-bg-slate-300\/90 {
  background-color: rgb(203 213 225 / 0.9) !important;
}

.tw-bg-slate-300\/95 {
  background-color: rgb(203 213 225 / 0.95) !important;
}

.tw-bg-slate-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(148 163 184 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-400\/0 {
  background-color: rgb(148 163 184 / 0) !important;
}

.tw-bg-slate-400\/10 {
  background-color: rgb(148 163 184 / 0.1) !important;
}

.tw-bg-slate-400\/100 {
  background-color: rgb(148 163 184 / 1) !important;
}

.tw-bg-slate-400\/15 {
  background-color: rgb(148 163 184 / 0.15) !important;
}

.tw-bg-slate-400\/20 {
  background-color: rgb(148 163 184 / 0.2) !important;
}

.tw-bg-slate-400\/25 {
  background-color: rgb(148 163 184 / 0.25) !important;
}

.tw-bg-slate-400\/30 {
  background-color: rgb(148 163 184 / 0.3) !important;
}

.tw-bg-slate-400\/35 {
  background-color: rgb(148 163 184 / 0.35) !important;
}

.tw-bg-slate-400\/40 {
  background-color: rgb(148 163 184 / 0.4) !important;
}

.tw-bg-slate-400\/45 {
  background-color: rgb(148 163 184 / 0.45) !important;
}

.tw-bg-slate-400\/5 {
  background-color: rgb(148 163 184 / 0.05) !important;
}

.tw-bg-slate-400\/50 {
  background-color: rgb(148 163 184 / 0.5) !important;
}

.tw-bg-slate-400\/55 {
  background-color: rgb(148 163 184 / 0.55) !important;
}

.tw-bg-slate-400\/60 {
  background-color: rgb(148 163 184 / 0.6) !important;
}

.tw-bg-slate-400\/65 {
  background-color: rgb(148 163 184 / 0.65) !important;
}

.tw-bg-slate-400\/70 {
  background-color: rgb(148 163 184 / 0.7) !important;
}

.tw-bg-slate-400\/75 {
  background-color: rgb(148 163 184 / 0.75) !important;
}

.tw-bg-slate-400\/80 {
  background-color: rgb(148 163 184 / 0.8) !important;
}

.tw-bg-slate-400\/85 {
  background-color: rgb(148 163 184 / 0.85) !important;
}

.tw-bg-slate-400\/90 {
  background-color: rgb(148 163 184 / 0.9) !important;
}

.tw-bg-slate-400\/95 {
  background-color: rgb(148 163 184 / 0.95) !important;
}

.tw-bg-slate-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(248 250 252 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-50\/0 {
  background-color: rgb(248 250 252 / 0) !important;
}

.tw-bg-slate-50\/10 {
  background-color: rgb(248 250 252 / 0.1) !important;
}

.tw-bg-slate-50\/100 {
  background-color: rgb(248 250 252 / 1) !important;
}

.tw-bg-slate-50\/15 {
  background-color: rgb(248 250 252 / 0.15) !important;
}

.tw-bg-slate-50\/20 {
  background-color: rgb(248 250 252 / 0.2) !important;
}

.tw-bg-slate-50\/25 {
  background-color: rgb(248 250 252 / 0.25) !important;
}

.tw-bg-slate-50\/30 {
  background-color: rgb(248 250 252 / 0.3) !important;
}

.tw-bg-slate-50\/35 {
  background-color: rgb(248 250 252 / 0.35) !important;
}

.tw-bg-slate-50\/40 {
  background-color: rgb(248 250 252 / 0.4) !important;
}

.tw-bg-slate-50\/45 {
  background-color: rgb(248 250 252 / 0.45) !important;
}

.tw-bg-slate-50\/5 {
  background-color: rgb(248 250 252 / 0.05) !important;
}

.tw-bg-slate-50\/50 {
  background-color: rgb(248 250 252 / 0.5) !important;
}

.tw-bg-slate-50\/55 {
  background-color: rgb(248 250 252 / 0.55) !important;
}

.tw-bg-slate-50\/60 {
  background-color: rgb(248 250 252 / 0.6) !important;
}

.tw-bg-slate-50\/65 {
  background-color: rgb(248 250 252 / 0.65) !important;
}

.tw-bg-slate-50\/70 {
  background-color: rgb(248 250 252 / 0.7) !important;
}

.tw-bg-slate-50\/75 {
  background-color: rgb(248 250 252 / 0.75) !important;
}

.tw-bg-slate-50\/80 {
  background-color: rgb(248 250 252 / 0.8) !important;
}

.tw-bg-slate-50\/85 {
  background-color: rgb(248 250 252 / 0.85) !important;
}

.tw-bg-slate-50\/90 {
  background-color: rgb(248 250 252 / 0.9) !important;
}

.tw-bg-slate-50\/95 {
  background-color: rgb(248 250 252 / 0.95) !important;
}

.tw-bg-slate-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(100 116 139 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-500\/0 {
  background-color: rgb(100 116 139 / 0) !important;
}

.tw-bg-slate-500\/10 {
  background-color: rgb(100 116 139 / 0.1) !important;
}

.tw-bg-slate-500\/100 {
  background-color: rgb(100 116 139 / 1) !important;
}

.tw-bg-slate-500\/15 {
  background-color: rgb(100 116 139 / 0.15) !important;
}

.tw-bg-slate-500\/20 {
  background-color: rgb(100 116 139 / 0.2) !important;
}

.tw-bg-slate-500\/25 {
  background-color: rgb(100 116 139 / 0.25) !important;
}

.tw-bg-slate-500\/30 {
  background-color: rgb(100 116 139 / 0.3) !important;
}

.tw-bg-slate-500\/35 {
  background-color: rgb(100 116 139 / 0.35) !important;
}

.tw-bg-slate-500\/40 {
  background-color: rgb(100 116 139 / 0.4) !important;
}

.tw-bg-slate-500\/45 {
  background-color: rgb(100 116 139 / 0.45) !important;
}

.tw-bg-slate-500\/5 {
  background-color: rgb(100 116 139 / 0.05) !important;
}

.tw-bg-slate-500\/50 {
  background-color: rgb(100 116 139 / 0.5) !important;
}

.tw-bg-slate-500\/55 {
  background-color: rgb(100 116 139 / 0.55) !important;
}

.tw-bg-slate-500\/60 {
  background-color: rgb(100 116 139 / 0.6) !important;
}

.tw-bg-slate-500\/65 {
  background-color: rgb(100 116 139 / 0.65) !important;
}

.tw-bg-slate-500\/70 {
  background-color: rgb(100 116 139 / 0.7) !important;
}

.tw-bg-slate-500\/75 {
  background-color: rgb(100 116 139 / 0.75) !important;
}

.tw-bg-slate-500\/80 {
  background-color: rgb(100 116 139 / 0.8) !important;
}

.tw-bg-slate-500\/85 {
  background-color: rgb(100 116 139 / 0.85) !important;
}

.tw-bg-slate-500\/90 {
  background-color: rgb(100 116 139 / 0.9) !important;
}

.tw-bg-slate-500\/95 {
  background-color: rgb(100 116 139 / 0.95) !important;
}

.tw-bg-slate-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(71 85 105 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-600\/0 {
  background-color: rgb(71 85 105 / 0) !important;
}

.tw-bg-slate-600\/10 {
  background-color: rgb(71 85 105 / 0.1) !important;
}

.tw-bg-slate-600\/100 {
  background-color: rgb(71 85 105 / 1) !important;
}

.tw-bg-slate-600\/15 {
  background-color: rgb(71 85 105 / 0.15) !important;
}

.tw-bg-slate-600\/20 {
  background-color: rgb(71 85 105 / 0.2) !important;
}

.tw-bg-slate-600\/25 {
  background-color: rgb(71 85 105 / 0.25) !important;
}

.tw-bg-slate-600\/30 {
  background-color: rgb(71 85 105 / 0.3) !important;
}

.tw-bg-slate-600\/35 {
  background-color: rgb(71 85 105 / 0.35) !important;
}

.tw-bg-slate-600\/40 {
  background-color: rgb(71 85 105 / 0.4) !important;
}

.tw-bg-slate-600\/45 {
  background-color: rgb(71 85 105 / 0.45) !important;
}

.tw-bg-slate-600\/5 {
  background-color: rgb(71 85 105 / 0.05) !important;
}

.tw-bg-slate-600\/50 {
  background-color: rgb(71 85 105 / 0.5) !important;
}

.tw-bg-slate-600\/55 {
  background-color: rgb(71 85 105 / 0.55) !important;
}

.tw-bg-slate-600\/60 {
  background-color: rgb(71 85 105 / 0.6) !important;
}

.tw-bg-slate-600\/65 {
  background-color: rgb(71 85 105 / 0.65) !important;
}

.tw-bg-slate-600\/70 {
  background-color: rgb(71 85 105 / 0.7) !important;
}

.tw-bg-slate-600\/75 {
  background-color: rgb(71 85 105 / 0.75) !important;
}

.tw-bg-slate-600\/80 {
  background-color: rgb(71 85 105 / 0.8) !important;
}

.tw-bg-slate-600\/85 {
  background-color: rgb(71 85 105 / 0.85) !important;
}

.tw-bg-slate-600\/90 {
  background-color: rgb(71 85 105 / 0.9) !important;
}

.tw-bg-slate-600\/95 {
  background-color: rgb(71 85 105 / 0.95) !important;
}

.tw-bg-slate-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(51 65 85 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-700\/0 {
  background-color: rgb(51 65 85 / 0) !important;
}

.tw-bg-slate-700\/10 {
  background-color: rgb(51 65 85 / 0.1) !important;
}

.tw-bg-slate-700\/100 {
  background-color: rgb(51 65 85 / 1) !important;
}

.tw-bg-slate-700\/15 {
  background-color: rgb(51 65 85 / 0.15) !important;
}

.tw-bg-slate-700\/20 {
  background-color: rgb(51 65 85 / 0.2) !important;
}

.tw-bg-slate-700\/25 {
  background-color: rgb(51 65 85 / 0.25) !important;
}

.tw-bg-slate-700\/30 {
  background-color: rgb(51 65 85 / 0.3) !important;
}

.tw-bg-slate-700\/35 {
  background-color: rgb(51 65 85 / 0.35) !important;
}

.tw-bg-slate-700\/40 {
  background-color: rgb(51 65 85 / 0.4) !important;
}

.tw-bg-slate-700\/45 {
  background-color: rgb(51 65 85 / 0.45) !important;
}

.tw-bg-slate-700\/5 {
  background-color: rgb(51 65 85 / 0.05) !important;
}

.tw-bg-slate-700\/50 {
  background-color: rgb(51 65 85 / 0.5) !important;
}

.tw-bg-slate-700\/55 {
  background-color: rgb(51 65 85 / 0.55) !important;
}

.tw-bg-slate-700\/60 {
  background-color: rgb(51 65 85 / 0.6) !important;
}

.tw-bg-slate-700\/65 {
  background-color: rgb(51 65 85 / 0.65) !important;
}

.tw-bg-slate-700\/70 {
  background-color: rgb(51 65 85 / 0.7) !important;
}

.tw-bg-slate-700\/75 {
  background-color: rgb(51 65 85 / 0.75) !important;
}

.tw-bg-slate-700\/80 {
  background-color: rgb(51 65 85 / 0.8) !important;
}

.tw-bg-slate-700\/85 {
  background-color: rgb(51 65 85 / 0.85) !important;
}

.tw-bg-slate-700\/90 {
  background-color: rgb(51 65 85 / 0.9) !important;
}

.tw-bg-slate-700\/95 {
  background-color: rgb(51 65 85 / 0.95) !important;
}

.tw-bg-slate-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(30 41 59 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-800\/0 {
  background-color: rgb(30 41 59 / 0) !important;
}

.tw-bg-slate-800\/10 {
  background-color: rgb(30 41 59 / 0.1) !important;
}

.tw-bg-slate-800\/100 {
  background-color: rgb(30 41 59 / 1) !important;
}

.tw-bg-slate-800\/15 {
  background-color: rgb(30 41 59 / 0.15) !important;
}

.tw-bg-slate-800\/20 {
  background-color: rgb(30 41 59 / 0.2) !important;
}

.tw-bg-slate-800\/25 {
  background-color: rgb(30 41 59 / 0.25) !important;
}

.tw-bg-slate-800\/30 {
  background-color: rgb(30 41 59 / 0.3) !important;
}

.tw-bg-slate-800\/35 {
  background-color: rgb(30 41 59 / 0.35) !important;
}

.tw-bg-slate-800\/40 {
  background-color: rgb(30 41 59 / 0.4) !important;
}

.tw-bg-slate-800\/45 {
  background-color: rgb(30 41 59 / 0.45) !important;
}

.tw-bg-slate-800\/5 {
  background-color: rgb(30 41 59 / 0.05) !important;
}

.tw-bg-slate-800\/50 {
  background-color: rgb(30 41 59 / 0.5) !important;
}

.tw-bg-slate-800\/55 {
  background-color: rgb(30 41 59 / 0.55) !important;
}

.tw-bg-slate-800\/60 {
  background-color: rgb(30 41 59 / 0.6) !important;
}

.tw-bg-slate-800\/65 {
  background-color: rgb(30 41 59 / 0.65) !important;
}

.tw-bg-slate-800\/70 {
  background-color: rgb(30 41 59 / 0.7) !important;
}

.tw-bg-slate-800\/75 {
  background-color: rgb(30 41 59 / 0.75) !important;
}

.tw-bg-slate-800\/80 {
  background-color: rgb(30 41 59 / 0.8) !important;
}

.tw-bg-slate-800\/85 {
  background-color: rgb(30 41 59 / 0.85) !important;
}

.tw-bg-slate-800\/90 {
  background-color: rgb(30 41 59 / 0.9) !important;
}

.tw-bg-slate-800\/95 {
  background-color: rgb(30 41 59 / 0.95) !important;
}

.tw-bg-slate-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-900\/0 {
  background-color: rgb(15 23 42 / 0) !important;
}

.tw-bg-slate-900\/10 {
  background-color: rgb(15 23 42 / 0.1) !important;
}

.tw-bg-slate-900\/100 {
  background-color: rgb(15 23 42 / 1) !important;
}

.tw-bg-slate-900\/15 {
  background-color: rgb(15 23 42 / 0.15) !important;
}

.tw-bg-slate-900\/20 {
  background-color: rgb(15 23 42 / 0.2) !important;
}

.tw-bg-slate-900\/25 {
  background-color: rgb(15 23 42 / 0.25) !important;
}

.tw-bg-slate-900\/30 {
  background-color: rgb(15 23 42 / 0.3) !important;
}

.tw-bg-slate-900\/35 {
  background-color: rgb(15 23 42 / 0.35) !important;
}

.tw-bg-slate-900\/40 {
  background-color: rgb(15 23 42 / 0.4) !important;
}

.tw-bg-slate-900\/45 {
  background-color: rgb(15 23 42 / 0.45) !important;
}

.tw-bg-slate-900\/5 {
  background-color: rgb(15 23 42 / 0.05) !important;
}

.tw-bg-slate-900\/50 {
  background-color: rgb(15 23 42 / 0.5) !important;
}

.tw-bg-slate-900\/55 {
  background-color: rgb(15 23 42 / 0.55) !important;
}

.tw-bg-slate-900\/60 {
  background-color: rgb(15 23 42 / 0.6) !important;
}

.tw-bg-slate-900\/65 {
  background-color: rgb(15 23 42 / 0.65) !important;
}

.tw-bg-slate-900\/70 {
  background-color: rgb(15 23 42 / 0.7) !important;
}

.tw-bg-slate-900\/75 {
  background-color: rgb(15 23 42 / 0.75) !important;
}

.tw-bg-slate-900\/80 {
  background-color: rgb(15 23 42 / 0.8) !important;
}

.tw-bg-slate-900\/85 {
  background-color: rgb(15 23 42 / 0.85) !important;
}

.tw-bg-slate-900\/90 {
  background-color: rgb(15 23 42 / 0.9) !important;
}

.tw-bg-slate-900\/95 {
  background-color: rgb(15 23 42 / 0.95) !important;
}

.tw-bg-slate-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(2 6 23 / var(--tw-bg-opacity)) !important;
}

.tw-bg-slate-950\/0 {
  background-color: rgb(2 6 23 / 0) !important;
}

.tw-bg-slate-950\/10 {
  background-color: rgb(2 6 23 / 0.1) !important;
}

.tw-bg-slate-950\/100 {
  background-color: rgb(2 6 23 / 1) !important;
}

.tw-bg-slate-950\/15 {
  background-color: rgb(2 6 23 / 0.15) !important;
}

.tw-bg-slate-950\/20 {
  background-color: rgb(2 6 23 / 0.2) !important;
}

.tw-bg-slate-950\/25 {
  background-color: rgb(2 6 23 / 0.25) !important;
}

.tw-bg-slate-950\/30 {
  background-color: rgb(2 6 23 / 0.3) !important;
}

.tw-bg-slate-950\/35 {
  background-color: rgb(2 6 23 / 0.35) !important;
}

.tw-bg-slate-950\/40 {
  background-color: rgb(2 6 23 / 0.4) !important;
}

.tw-bg-slate-950\/45 {
  background-color: rgb(2 6 23 / 0.45) !important;
}

.tw-bg-slate-950\/5 {
  background-color: rgb(2 6 23 / 0.05) !important;
}

.tw-bg-slate-950\/50 {
  background-color: rgb(2 6 23 / 0.5) !important;
}

.tw-bg-slate-950\/55 {
  background-color: rgb(2 6 23 / 0.55) !important;
}

.tw-bg-slate-950\/60 {
  background-color: rgb(2 6 23 / 0.6) !important;
}

.tw-bg-slate-950\/65 {
  background-color: rgb(2 6 23 / 0.65) !important;
}

.tw-bg-slate-950\/70 {
  background-color: rgb(2 6 23 / 0.7) !important;
}

.tw-bg-slate-950\/75 {
  background-color: rgb(2 6 23 / 0.75) !important;
}

.tw-bg-slate-950\/80 {
  background-color: rgb(2 6 23 / 0.8) !important;
}

.tw-bg-slate-950\/85 {
  background-color: rgb(2 6 23 / 0.85) !important;
}

.tw-bg-slate-950\/90 {
  background-color: rgb(2 6 23 / 0.9) !important;
}

.tw-bg-slate-950\/95 {
  background-color: rgb(2 6 23 / 0.95) !important;
}

.tw-bg-stone-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 245 244 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-100\/0 {
  background-color: rgb(245 245 244 / 0) !important;
}

.tw-bg-stone-100\/10 {
  background-color: rgb(245 245 244 / 0.1) !important;
}

.tw-bg-stone-100\/100 {
  background-color: rgb(245 245 244 / 1) !important;
}

.tw-bg-stone-100\/15 {
  background-color: rgb(245 245 244 / 0.15) !important;
}

.tw-bg-stone-100\/20 {
  background-color: rgb(245 245 244 / 0.2) !important;
}

.tw-bg-stone-100\/25 {
  background-color: rgb(245 245 244 / 0.25) !important;
}

.tw-bg-stone-100\/30 {
  background-color: rgb(245 245 244 / 0.3) !important;
}

.tw-bg-stone-100\/35 {
  background-color: rgb(245 245 244 / 0.35) !important;
}

.tw-bg-stone-100\/40 {
  background-color: rgb(245 245 244 / 0.4) !important;
}

.tw-bg-stone-100\/45 {
  background-color: rgb(245 245 244 / 0.45) !important;
}

.tw-bg-stone-100\/5 {
  background-color: rgb(245 245 244 / 0.05) !important;
}

.tw-bg-stone-100\/50 {
  background-color: rgb(245 245 244 / 0.5) !important;
}

.tw-bg-stone-100\/55 {
  background-color: rgb(245 245 244 / 0.55) !important;
}

.tw-bg-stone-100\/60 {
  background-color: rgb(245 245 244 / 0.6) !important;
}

.tw-bg-stone-100\/65 {
  background-color: rgb(245 245 244 / 0.65) !important;
}

.tw-bg-stone-100\/70 {
  background-color: rgb(245 245 244 / 0.7) !important;
}

.tw-bg-stone-100\/75 {
  background-color: rgb(245 245 244 / 0.75) !important;
}

.tw-bg-stone-100\/80 {
  background-color: rgb(245 245 244 / 0.8) !important;
}

.tw-bg-stone-100\/85 {
  background-color: rgb(245 245 244 / 0.85) !important;
}

.tw-bg-stone-100\/90 {
  background-color: rgb(245 245 244 / 0.9) !important;
}

.tw-bg-stone-100\/95 {
  background-color: rgb(245 245 244 / 0.95) !important;
}

.tw-bg-stone-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(231 229 228 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-200\/0 {
  background-color: rgb(231 229 228 / 0) !important;
}

.tw-bg-stone-200\/10 {
  background-color: rgb(231 229 228 / 0.1) !important;
}

.tw-bg-stone-200\/100 {
  background-color: rgb(231 229 228 / 1) !important;
}

.tw-bg-stone-200\/15 {
  background-color: rgb(231 229 228 / 0.15) !important;
}

.tw-bg-stone-200\/20 {
  background-color: rgb(231 229 228 / 0.2) !important;
}

.tw-bg-stone-200\/25 {
  background-color: rgb(231 229 228 / 0.25) !important;
}

.tw-bg-stone-200\/30 {
  background-color: rgb(231 229 228 / 0.3) !important;
}

.tw-bg-stone-200\/35 {
  background-color: rgb(231 229 228 / 0.35) !important;
}

.tw-bg-stone-200\/40 {
  background-color: rgb(231 229 228 / 0.4) !important;
}

.tw-bg-stone-200\/45 {
  background-color: rgb(231 229 228 / 0.45) !important;
}

.tw-bg-stone-200\/5 {
  background-color: rgb(231 229 228 / 0.05) !important;
}

.tw-bg-stone-200\/50 {
  background-color: rgb(231 229 228 / 0.5) !important;
}

.tw-bg-stone-200\/55 {
  background-color: rgb(231 229 228 / 0.55) !important;
}

.tw-bg-stone-200\/60 {
  background-color: rgb(231 229 228 / 0.6) !important;
}

.tw-bg-stone-200\/65 {
  background-color: rgb(231 229 228 / 0.65) !important;
}

.tw-bg-stone-200\/70 {
  background-color: rgb(231 229 228 / 0.7) !important;
}

.tw-bg-stone-200\/75 {
  background-color: rgb(231 229 228 / 0.75) !important;
}

.tw-bg-stone-200\/80 {
  background-color: rgb(231 229 228 / 0.8) !important;
}

.tw-bg-stone-200\/85 {
  background-color: rgb(231 229 228 / 0.85) !important;
}

.tw-bg-stone-200\/90 {
  background-color: rgb(231 229 228 / 0.9) !important;
}

.tw-bg-stone-200\/95 {
  background-color: rgb(231 229 228 / 0.95) !important;
}

.tw-bg-stone-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(214 211 209 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-300\/0 {
  background-color: rgb(214 211 209 / 0) !important;
}

.tw-bg-stone-300\/10 {
  background-color: rgb(214 211 209 / 0.1) !important;
}

.tw-bg-stone-300\/100 {
  background-color: rgb(214 211 209 / 1) !important;
}

.tw-bg-stone-300\/15 {
  background-color: rgb(214 211 209 / 0.15) !important;
}

.tw-bg-stone-300\/20 {
  background-color: rgb(214 211 209 / 0.2) !important;
}

.tw-bg-stone-300\/25 {
  background-color: rgb(214 211 209 / 0.25) !important;
}

.tw-bg-stone-300\/30 {
  background-color: rgb(214 211 209 / 0.3) !important;
}

.tw-bg-stone-300\/35 {
  background-color: rgb(214 211 209 / 0.35) !important;
}

.tw-bg-stone-300\/40 {
  background-color: rgb(214 211 209 / 0.4) !important;
}

.tw-bg-stone-300\/45 {
  background-color: rgb(214 211 209 / 0.45) !important;
}

.tw-bg-stone-300\/5 {
  background-color: rgb(214 211 209 / 0.05) !important;
}

.tw-bg-stone-300\/50 {
  background-color: rgb(214 211 209 / 0.5) !important;
}

.tw-bg-stone-300\/55 {
  background-color: rgb(214 211 209 / 0.55) !important;
}

.tw-bg-stone-300\/60 {
  background-color: rgb(214 211 209 / 0.6) !important;
}

.tw-bg-stone-300\/65 {
  background-color: rgb(214 211 209 / 0.65) !important;
}

.tw-bg-stone-300\/70 {
  background-color: rgb(214 211 209 / 0.7) !important;
}

.tw-bg-stone-300\/75 {
  background-color: rgb(214 211 209 / 0.75) !important;
}

.tw-bg-stone-300\/80 {
  background-color: rgb(214 211 209 / 0.8) !important;
}

.tw-bg-stone-300\/85 {
  background-color: rgb(214 211 209 / 0.85) !important;
}

.tw-bg-stone-300\/90 {
  background-color: rgb(214 211 209 / 0.9) !important;
}

.tw-bg-stone-300\/95 {
  background-color: rgb(214 211 209 / 0.95) !important;
}

.tw-bg-stone-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(168 162 158 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-400\/0 {
  background-color: rgb(168 162 158 / 0) !important;
}

.tw-bg-stone-400\/10 {
  background-color: rgb(168 162 158 / 0.1) !important;
}

.tw-bg-stone-400\/100 {
  background-color: rgb(168 162 158 / 1) !important;
}

.tw-bg-stone-400\/15 {
  background-color: rgb(168 162 158 / 0.15) !important;
}

.tw-bg-stone-400\/20 {
  background-color: rgb(168 162 158 / 0.2) !important;
}

.tw-bg-stone-400\/25 {
  background-color: rgb(168 162 158 / 0.25) !important;
}

.tw-bg-stone-400\/30 {
  background-color: rgb(168 162 158 / 0.3) !important;
}

.tw-bg-stone-400\/35 {
  background-color: rgb(168 162 158 / 0.35) !important;
}

.tw-bg-stone-400\/40 {
  background-color: rgb(168 162 158 / 0.4) !important;
}

.tw-bg-stone-400\/45 {
  background-color: rgb(168 162 158 / 0.45) !important;
}

.tw-bg-stone-400\/5 {
  background-color: rgb(168 162 158 / 0.05) !important;
}

.tw-bg-stone-400\/50 {
  background-color: rgb(168 162 158 / 0.5) !important;
}

.tw-bg-stone-400\/55 {
  background-color: rgb(168 162 158 / 0.55) !important;
}

.tw-bg-stone-400\/60 {
  background-color: rgb(168 162 158 / 0.6) !important;
}

.tw-bg-stone-400\/65 {
  background-color: rgb(168 162 158 / 0.65) !important;
}

.tw-bg-stone-400\/70 {
  background-color: rgb(168 162 158 / 0.7) !important;
}

.tw-bg-stone-400\/75 {
  background-color: rgb(168 162 158 / 0.75) !important;
}

.tw-bg-stone-400\/80 {
  background-color: rgb(168 162 158 / 0.8) !important;
}

.tw-bg-stone-400\/85 {
  background-color: rgb(168 162 158 / 0.85) !important;
}

.tw-bg-stone-400\/90 {
  background-color: rgb(168 162 158 / 0.9) !important;
}

.tw-bg-stone-400\/95 {
  background-color: rgb(168 162 158 / 0.95) !important;
}

.tw-bg-stone-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 249 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-50\/0 {
  background-color: rgb(250 250 249 / 0) !important;
}

.tw-bg-stone-50\/10 {
  background-color: rgb(250 250 249 / 0.1) !important;
}

.tw-bg-stone-50\/100 {
  background-color: rgb(250 250 249 / 1) !important;
}

.tw-bg-stone-50\/15 {
  background-color: rgb(250 250 249 / 0.15) !important;
}

.tw-bg-stone-50\/20 {
  background-color: rgb(250 250 249 / 0.2) !important;
}

.tw-bg-stone-50\/25 {
  background-color: rgb(250 250 249 / 0.25) !important;
}

.tw-bg-stone-50\/30 {
  background-color: rgb(250 250 249 / 0.3) !important;
}

.tw-bg-stone-50\/35 {
  background-color: rgb(250 250 249 / 0.35) !important;
}

.tw-bg-stone-50\/40 {
  background-color: rgb(250 250 249 / 0.4) !important;
}

.tw-bg-stone-50\/45 {
  background-color: rgb(250 250 249 / 0.45) !important;
}

.tw-bg-stone-50\/5 {
  background-color: rgb(250 250 249 / 0.05) !important;
}

.tw-bg-stone-50\/50 {
  background-color: rgb(250 250 249 / 0.5) !important;
}

.tw-bg-stone-50\/55 {
  background-color: rgb(250 250 249 / 0.55) !important;
}

.tw-bg-stone-50\/60 {
  background-color: rgb(250 250 249 / 0.6) !important;
}

.tw-bg-stone-50\/65 {
  background-color: rgb(250 250 249 / 0.65) !important;
}

.tw-bg-stone-50\/70 {
  background-color: rgb(250 250 249 / 0.7) !important;
}

.tw-bg-stone-50\/75 {
  background-color: rgb(250 250 249 / 0.75) !important;
}

.tw-bg-stone-50\/80 {
  background-color: rgb(250 250 249 / 0.8) !important;
}

.tw-bg-stone-50\/85 {
  background-color: rgb(250 250 249 / 0.85) !important;
}

.tw-bg-stone-50\/90 {
  background-color: rgb(250 250 249 / 0.9) !important;
}

.tw-bg-stone-50\/95 {
  background-color: rgb(250 250 249 / 0.95) !important;
}

.tw-bg-stone-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(120 113 108 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-500\/0 {
  background-color: rgb(120 113 108 / 0) !important;
}

.tw-bg-stone-500\/10 {
  background-color: rgb(120 113 108 / 0.1) !important;
}

.tw-bg-stone-500\/100 {
  background-color: rgb(120 113 108 / 1) !important;
}

.tw-bg-stone-500\/15 {
  background-color: rgb(120 113 108 / 0.15) !important;
}

.tw-bg-stone-500\/20 {
  background-color: rgb(120 113 108 / 0.2) !important;
}

.tw-bg-stone-500\/25 {
  background-color: rgb(120 113 108 / 0.25) !important;
}

.tw-bg-stone-500\/30 {
  background-color: rgb(120 113 108 / 0.3) !important;
}

.tw-bg-stone-500\/35 {
  background-color: rgb(120 113 108 / 0.35) !important;
}

.tw-bg-stone-500\/40 {
  background-color: rgb(120 113 108 / 0.4) !important;
}

.tw-bg-stone-500\/45 {
  background-color: rgb(120 113 108 / 0.45) !important;
}

.tw-bg-stone-500\/5 {
  background-color: rgb(120 113 108 / 0.05) !important;
}

.tw-bg-stone-500\/50 {
  background-color: rgb(120 113 108 / 0.5) !important;
}

.tw-bg-stone-500\/55 {
  background-color: rgb(120 113 108 / 0.55) !important;
}

.tw-bg-stone-500\/60 {
  background-color: rgb(120 113 108 / 0.6) !important;
}

.tw-bg-stone-500\/65 {
  background-color: rgb(120 113 108 / 0.65) !important;
}

.tw-bg-stone-500\/70 {
  background-color: rgb(120 113 108 / 0.7) !important;
}

.tw-bg-stone-500\/75 {
  background-color: rgb(120 113 108 / 0.75) !important;
}

.tw-bg-stone-500\/80 {
  background-color: rgb(120 113 108 / 0.8) !important;
}

.tw-bg-stone-500\/85 {
  background-color: rgb(120 113 108 / 0.85) !important;
}

.tw-bg-stone-500\/90 {
  background-color: rgb(120 113 108 / 0.9) !important;
}

.tw-bg-stone-500\/95 {
  background-color: rgb(120 113 108 / 0.95) !important;
}

.tw-bg-stone-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(87 83 78 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-600\/0 {
  background-color: rgb(87 83 78 / 0) !important;
}

.tw-bg-stone-600\/10 {
  background-color: rgb(87 83 78 / 0.1) !important;
}

.tw-bg-stone-600\/100 {
  background-color: rgb(87 83 78 / 1) !important;
}

.tw-bg-stone-600\/15 {
  background-color: rgb(87 83 78 / 0.15) !important;
}

.tw-bg-stone-600\/20 {
  background-color: rgb(87 83 78 / 0.2) !important;
}

.tw-bg-stone-600\/25 {
  background-color: rgb(87 83 78 / 0.25) !important;
}

.tw-bg-stone-600\/30 {
  background-color: rgb(87 83 78 / 0.3) !important;
}

.tw-bg-stone-600\/35 {
  background-color: rgb(87 83 78 / 0.35) !important;
}

.tw-bg-stone-600\/40 {
  background-color: rgb(87 83 78 / 0.4) !important;
}

.tw-bg-stone-600\/45 {
  background-color: rgb(87 83 78 / 0.45) !important;
}

.tw-bg-stone-600\/5 {
  background-color: rgb(87 83 78 / 0.05) !important;
}

.tw-bg-stone-600\/50 {
  background-color: rgb(87 83 78 / 0.5) !important;
}

.tw-bg-stone-600\/55 {
  background-color: rgb(87 83 78 / 0.55) !important;
}

.tw-bg-stone-600\/60 {
  background-color: rgb(87 83 78 / 0.6) !important;
}

.tw-bg-stone-600\/65 {
  background-color: rgb(87 83 78 / 0.65) !important;
}

.tw-bg-stone-600\/70 {
  background-color: rgb(87 83 78 / 0.7) !important;
}

.tw-bg-stone-600\/75 {
  background-color: rgb(87 83 78 / 0.75) !important;
}

.tw-bg-stone-600\/80 {
  background-color: rgb(87 83 78 / 0.8) !important;
}

.tw-bg-stone-600\/85 {
  background-color: rgb(87 83 78 / 0.85) !important;
}

.tw-bg-stone-600\/90 {
  background-color: rgb(87 83 78 / 0.9) !important;
}

.tw-bg-stone-600\/95 {
  background-color: rgb(87 83 78 / 0.95) !important;
}

.tw-bg-stone-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(68 64 60 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-700\/0 {
  background-color: rgb(68 64 60 / 0) !important;
}

.tw-bg-stone-700\/10 {
  background-color: rgb(68 64 60 / 0.1) !important;
}

.tw-bg-stone-700\/100 {
  background-color: rgb(68 64 60 / 1) !important;
}

.tw-bg-stone-700\/15 {
  background-color: rgb(68 64 60 / 0.15) !important;
}

.tw-bg-stone-700\/20 {
  background-color: rgb(68 64 60 / 0.2) !important;
}

.tw-bg-stone-700\/25 {
  background-color: rgb(68 64 60 / 0.25) !important;
}

.tw-bg-stone-700\/30 {
  background-color: rgb(68 64 60 / 0.3) !important;
}

.tw-bg-stone-700\/35 {
  background-color: rgb(68 64 60 / 0.35) !important;
}

.tw-bg-stone-700\/40 {
  background-color: rgb(68 64 60 / 0.4) !important;
}

.tw-bg-stone-700\/45 {
  background-color: rgb(68 64 60 / 0.45) !important;
}

.tw-bg-stone-700\/5 {
  background-color: rgb(68 64 60 / 0.05) !important;
}

.tw-bg-stone-700\/50 {
  background-color: rgb(68 64 60 / 0.5) !important;
}

.tw-bg-stone-700\/55 {
  background-color: rgb(68 64 60 / 0.55) !important;
}

.tw-bg-stone-700\/60 {
  background-color: rgb(68 64 60 / 0.6) !important;
}

.tw-bg-stone-700\/65 {
  background-color: rgb(68 64 60 / 0.65) !important;
}

.tw-bg-stone-700\/70 {
  background-color: rgb(68 64 60 / 0.7) !important;
}

.tw-bg-stone-700\/75 {
  background-color: rgb(68 64 60 / 0.75) !important;
}

.tw-bg-stone-700\/80 {
  background-color: rgb(68 64 60 / 0.8) !important;
}

.tw-bg-stone-700\/85 {
  background-color: rgb(68 64 60 / 0.85) !important;
}

.tw-bg-stone-700\/90 {
  background-color: rgb(68 64 60 / 0.9) !important;
}

.tw-bg-stone-700\/95 {
  background-color: rgb(68 64 60 / 0.95) !important;
}

.tw-bg-stone-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(41 37 36 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-800\/0 {
  background-color: rgb(41 37 36 / 0) !important;
}

.tw-bg-stone-800\/10 {
  background-color: rgb(41 37 36 / 0.1) !important;
}

.tw-bg-stone-800\/100 {
  background-color: rgb(41 37 36 / 1) !important;
}

.tw-bg-stone-800\/15 {
  background-color: rgb(41 37 36 / 0.15) !important;
}

.tw-bg-stone-800\/20 {
  background-color: rgb(41 37 36 / 0.2) !important;
}

.tw-bg-stone-800\/25 {
  background-color: rgb(41 37 36 / 0.25) !important;
}

.tw-bg-stone-800\/30 {
  background-color: rgb(41 37 36 / 0.3) !important;
}

.tw-bg-stone-800\/35 {
  background-color: rgb(41 37 36 / 0.35) !important;
}

.tw-bg-stone-800\/40 {
  background-color: rgb(41 37 36 / 0.4) !important;
}

.tw-bg-stone-800\/45 {
  background-color: rgb(41 37 36 / 0.45) !important;
}

.tw-bg-stone-800\/5 {
  background-color: rgb(41 37 36 / 0.05) !important;
}

.tw-bg-stone-800\/50 {
  background-color: rgb(41 37 36 / 0.5) !important;
}

.tw-bg-stone-800\/55 {
  background-color: rgb(41 37 36 / 0.55) !important;
}

.tw-bg-stone-800\/60 {
  background-color: rgb(41 37 36 / 0.6) !important;
}

.tw-bg-stone-800\/65 {
  background-color: rgb(41 37 36 / 0.65) !important;
}

.tw-bg-stone-800\/70 {
  background-color: rgb(41 37 36 / 0.7) !important;
}

.tw-bg-stone-800\/75 {
  background-color: rgb(41 37 36 / 0.75) !important;
}

.tw-bg-stone-800\/80 {
  background-color: rgb(41 37 36 / 0.8) !important;
}

.tw-bg-stone-800\/85 {
  background-color: rgb(41 37 36 / 0.85) !important;
}

.tw-bg-stone-800\/90 {
  background-color: rgb(41 37 36 / 0.9) !important;
}

.tw-bg-stone-800\/95 {
  background-color: rgb(41 37 36 / 0.95) !important;
}

.tw-bg-stone-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(28 25 23 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-900\/0 {
  background-color: rgb(28 25 23 / 0) !important;
}

.tw-bg-stone-900\/10 {
  background-color: rgb(28 25 23 / 0.1) !important;
}

.tw-bg-stone-900\/100 {
  background-color: rgb(28 25 23 / 1) !important;
}

.tw-bg-stone-900\/15 {
  background-color: rgb(28 25 23 / 0.15) !important;
}

.tw-bg-stone-900\/20 {
  background-color: rgb(28 25 23 / 0.2) !important;
}

.tw-bg-stone-900\/25 {
  background-color: rgb(28 25 23 / 0.25) !important;
}

.tw-bg-stone-900\/30 {
  background-color: rgb(28 25 23 / 0.3) !important;
}

.tw-bg-stone-900\/35 {
  background-color: rgb(28 25 23 / 0.35) !important;
}

.tw-bg-stone-900\/40 {
  background-color: rgb(28 25 23 / 0.4) !important;
}

.tw-bg-stone-900\/45 {
  background-color: rgb(28 25 23 / 0.45) !important;
}

.tw-bg-stone-900\/5 {
  background-color: rgb(28 25 23 / 0.05) !important;
}

.tw-bg-stone-900\/50 {
  background-color: rgb(28 25 23 / 0.5) !important;
}

.tw-bg-stone-900\/55 {
  background-color: rgb(28 25 23 / 0.55) !important;
}

.tw-bg-stone-900\/60 {
  background-color: rgb(28 25 23 / 0.6) !important;
}

.tw-bg-stone-900\/65 {
  background-color: rgb(28 25 23 / 0.65) !important;
}

.tw-bg-stone-900\/70 {
  background-color: rgb(28 25 23 / 0.7) !important;
}

.tw-bg-stone-900\/75 {
  background-color: rgb(28 25 23 / 0.75) !important;
}

.tw-bg-stone-900\/80 {
  background-color: rgb(28 25 23 / 0.8) !important;
}

.tw-bg-stone-900\/85 {
  background-color: rgb(28 25 23 / 0.85) !important;
}

.tw-bg-stone-900\/90 {
  background-color: rgb(28 25 23 / 0.9) !important;
}

.tw-bg-stone-900\/95 {
  background-color: rgb(28 25 23 / 0.95) !important;
}

.tw-bg-stone-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(12 10 9 / var(--tw-bg-opacity)) !important;
}

.tw-bg-stone-950\/0 {
  background-color: rgb(12 10 9 / 0) !important;
}

.tw-bg-stone-950\/10 {
  background-color: rgb(12 10 9 / 0.1) !important;
}

.tw-bg-stone-950\/100 {
  background-color: rgb(12 10 9 / 1) !important;
}

.tw-bg-stone-950\/15 {
  background-color: rgb(12 10 9 / 0.15) !important;
}

.tw-bg-stone-950\/20 {
  background-color: rgb(12 10 9 / 0.2) !important;
}

.tw-bg-stone-950\/25 {
  background-color: rgb(12 10 9 / 0.25) !important;
}

.tw-bg-stone-950\/30 {
  background-color: rgb(12 10 9 / 0.3) !important;
}

.tw-bg-stone-950\/35 {
  background-color: rgb(12 10 9 / 0.35) !important;
}

.tw-bg-stone-950\/40 {
  background-color: rgb(12 10 9 / 0.4) !important;
}

.tw-bg-stone-950\/45 {
  background-color: rgb(12 10 9 / 0.45) !important;
}

.tw-bg-stone-950\/5 {
  background-color: rgb(12 10 9 / 0.05) !important;
}

.tw-bg-stone-950\/50 {
  background-color: rgb(12 10 9 / 0.5) !important;
}

.tw-bg-stone-950\/55 {
  background-color: rgb(12 10 9 / 0.55) !important;
}

.tw-bg-stone-950\/60 {
  background-color: rgb(12 10 9 / 0.6) !important;
}

.tw-bg-stone-950\/65 {
  background-color: rgb(12 10 9 / 0.65) !important;
}

.tw-bg-stone-950\/70 {
  background-color: rgb(12 10 9 / 0.7) !important;
}

.tw-bg-stone-950\/75 {
  background-color: rgb(12 10 9 / 0.75) !important;
}

.tw-bg-stone-950\/80 {
  background-color: rgb(12 10 9 / 0.8) !important;
}

.tw-bg-stone-950\/85 {
  background-color: rgb(12 10 9 / 0.85) !important;
}

.tw-bg-stone-950\/90 {
  background-color: rgb(12 10 9 / 0.9) !important;
}

.tw-bg-stone-950\/95 {
  background-color: rgb(12 10 9 / 0.95) !important;
}

.tw-bg-teal-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(204 251 241 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-100\/0 {
  background-color: rgb(204 251 241 / 0) !important;
}

.tw-bg-teal-100\/10 {
  background-color: rgb(204 251 241 / 0.1) !important;
}

.tw-bg-teal-100\/100 {
  background-color: rgb(204 251 241 / 1) !important;
}

.tw-bg-teal-100\/15 {
  background-color: rgb(204 251 241 / 0.15) !important;
}

.tw-bg-teal-100\/20 {
  background-color: rgb(204 251 241 / 0.2) !important;
}

.tw-bg-teal-100\/25 {
  background-color: rgb(204 251 241 / 0.25) !important;
}

.tw-bg-teal-100\/30 {
  background-color: rgb(204 251 241 / 0.3) !important;
}

.tw-bg-teal-100\/35 {
  background-color: rgb(204 251 241 / 0.35) !important;
}

.tw-bg-teal-100\/40 {
  background-color: rgb(204 251 241 / 0.4) !important;
}

.tw-bg-teal-100\/45 {
  background-color: rgb(204 251 241 / 0.45) !important;
}

.tw-bg-teal-100\/5 {
  background-color: rgb(204 251 241 / 0.05) !important;
}

.tw-bg-teal-100\/50 {
  background-color: rgb(204 251 241 / 0.5) !important;
}

.tw-bg-teal-100\/55 {
  background-color: rgb(204 251 241 / 0.55) !important;
}

.tw-bg-teal-100\/60 {
  background-color: rgb(204 251 241 / 0.6) !important;
}

.tw-bg-teal-100\/65 {
  background-color: rgb(204 251 241 / 0.65) !important;
}

.tw-bg-teal-100\/70 {
  background-color: rgb(204 251 241 / 0.7) !important;
}

.tw-bg-teal-100\/75 {
  background-color: rgb(204 251 241 / 0.75) !important;
}

.tw-bg-teal-100\/80 {
  background-color: rgb(204 251 241 / 0.8) !important;
}

.tw-bg-teal-100\/85 {
  background-color: rgb(204 251 241 / 0.85) !important;
}

.tw-bg-teal-100\/90 {
  background-color: rgb(204 251 241 / 0.9) !important;
}

.tw-bg-teal-100\/95 {
  background-color: rgb(204 251 241 / 0.95) !important;
}

.tw-bg-teal-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(153 246 228 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-200\/0 {
  background-color: rgb(153 246 228 / 0) !important;
}

.tw-bg-teal-200\/10 {
  background-color: rgb(153 246 228 / 0.1) !important;
}

.tw-bg-teal-200\/100 {
  background-color: rgb(153 246 228 / 1) !important;
}

.tw-bg-teal-200\/15 {
  background-color: rgb(153 246 228 / 0.15) !important;
}

.tw-bg-teal-200\/20 {
  background-color: rgb(153 246 228 / 0.2) !important;
}

.tw-bg-teal-200\/25 {
  background-color: rgb(153 246 228 / 0.25) !important;
}

.tw-bg-teal-200\/30 {
  background-color: rgb(153 246 228 / 0.3) !important;
}

.tw-bg-teal-200\/35 {
  background-color: rgb(153 246 228 / 0.35) !important;
}

.tw-bg-teal-200\/40 {
  background-color: rgb(153 246 228 / 0.4) !important;
}

.tw-bg-teal-200\/45 {
  background-color: rgb(153 246 228 / 0.45) !important;
}

.tw-bg-teal-200\/5 {
  background-color: rgb(153 246 228 / 0.05) !important;
}

.tw-bg-teal-200\/50 {
  background-color: rgb(153 246 228 / 0.5) !important;
}

.tw-bg-teal-200\/55 {
  background-color: rgb(153 246 228 / 0.55) !important;
}

.tw-bg-teal-200\/60 {
  background-color: rgb(153 246 228 / 0.6) !important;
}

.tw-bg-teal-200\/65 {
  background-color: rgb(153 246 228 / 0.65) !important;
}

.tw-bg-teal-200\/70 {
  background-color: rgb(153 246 228 / 0.7) !important;
}

.tw-bg-teal-200\/75 {
  background-color: rgb(153 246 228 / 0.75) !important;
}

.tw-bg-teal-200\/80 {
  background-color: rgb(153 246 228 / 0.8) !important;
}

.tw-bg-teal-200\/85 {
  background-color: rgb(153 246 228 / 0.85) !important;
}

.tw-bg-teal-200\/90 {
  background-color: rgb(153 246 228 / 0.9) !important;
}

.tw-bg-teal-200\/95 {
  background-color: rgb(153 246 228 / 0.95) !important;
}

.tw-bg-teal-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(94 234 212 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-300\/0 {
  background-color: rgb(94 234 212 / 0) !important;
}

.tw-bg-teal-300\/10 {
  background-color: rgb(94 234 212 / 0.1) !important;
}

.tw-bg-teal-300\/100 {
  background-color: rgb(94 234 212 / 1) !important;
}

.tw-bg-teal-300\/15 {
  background-color: rgb(94 234 212 / 0.15) !important;
}

.tw-bg-teal-300\/20 {
  background-color: rgb(94 234 212 / 0.2) !important;
}

.tw-bg-teal-300\/25 {
  background-color: rgb(94 234 212 / 0.25) !important;
}

.tw-bg-teal-300\/30 {
  background-color: rgb(94 234 212 / 0.3) !important;
}

.tw-bg-teal-300\/35 {
  background-color: rgb(94 234 212 / 0.35) !important;
}

.tw-bg-teal-300\/40 {
  background-color: rgb(94 234 212 / 0.4) !important;
}

.tw-bg-teal-300\/45 {
  background-color: rgb(94 234 212 / 0.45) !important;
}

.tw-bg-teal-300\/5 {
  background-color: rgb(94 234 212 / 0.05) !important;
}

.tw-bg-teal-300\/50 {
  background-color: rgb(94 234 212 / 0.5) !important;
}

.tw-bg-teal-300\/55 {
  background-color: rgb(94 234 212 / 0.55) !important;
}

.tw-bg-teal-300\/60 {
  background-color: rgb(94 234 212 / 0.6) !important;
}

.tw-bg-teal-300\/65 {
  background-color: rgb(94 234 212 / 0.65) !important;
}

.tw-bg-teal-300\/70 {
  background-color: rgb(94 234 212 / 0.7) !important;
}

.tw-bg-teal-300\/75 {
  background-color: rgb(94 234 212 / 0.75) !important;
}

.tw-bg-teal-300\/80 {
  background-color: rgb(94 234 212 / 0.8) !important;
}

.tw-bg-teal-300\/85 {
  background-color: rgb(94 234 212 / 0.85) !important;
}

.tw-bg-teal-300\/90 {
  background-color: rgb(94 234 212 / 0.9) !important;
}

.tw-bg-teal-300\/95 {
  background-color: rgb(94 234 212 / 0.95) !important;
}

.tw-bg-teal-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(45 212 191 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-400\/0 {
  background-color: rgb(45 212 191 / 0) !important;
}

.tw-bg-teal-400\/10 {
  background-color: rgb(45 212 191 / 0.1) !important;
}

.tw-bg-teal-400\/100 {
  background-color: rgb(45 212 191 / 1) !important;
}

.tw-bg-teal-400\/15 {
  background-color: rgb(45 212 191 / 0.15) !important;
}

.tw-bg-teal-400\/20 {
  background-color: rgb(45 212 191 / 0.2) !important;
}

.tw-bg-teal-400\/25 {
  background-color: rgb(45 212 191 / 0.25) !important;
}

.tw-bg-teal-400\/30 {
  background-color: rgb(45 212 191 / 0.3) !important;
}

.tw-bg-teal-400\/35 {
  background-color: rgb(45 212 191 / 0.35) !important;
}

.tw-bg-teal-400\/40 {
  background-color: rgb(45 212 191 / 0.4) !important;
}

.tw-bg-teal-400\/45 {
  background-color: rgb(45 212 191 / 0.45) !important;
}

.tw-bg-teal-400\/5 {
  background-color: rgb(45 212 191 / 0.05) !important;
}

.tw-bg-teal-400\/50 {
  background-color: rgb(45 212 191 / 0.5) !important;
}

.tw-bg-teal-400\/55 {
  background-color: rgb(45 212 191 / 0.55) !important;
}

.tw-bg-teal-400\/60 {
  background-color: rgb(45 212 191 / 0.6) !important;
}

.tw-bg-teal-400\/65 {
  background-color: rgb(45 212 191 / 0.65) !important;
}

.tw-bg-teal-400\/70 {
  background-color: rgb(45 212 191 / 0.7) !important;
}

.tw-bg-teal-400\/75 {
  background-color: rgb(45 212 191 / 0.75) !important;
}

.tw-bg-teal-400\/80 {
  background-color: rgb(45 212 191 / 0.8) !important;
}

.tw-bg-teal-400\/85 {
  background-color: rgb(45 212 191 / 0.85) !important;
}

.tw-bg-teal-400\/90 {
  background-color: rgb(45 212 191 / 0.9) !important;
}

.tw-bg-teal-400\/95 {
  background-color: rgb(45 212 191 / 0.95) !important;
}

.tw-bg-teal-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(240 253 250 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-50\/0 {
  background-color: rgb(240 253 250 / 0) !important;
}

.tw-bg-teal-50\/10 {
  background-color: rgb(240 253 250 / 0.1) !important;
}

.tw-bg-teal-50\/100 {
  background-color: rgb(240 253 250 / 1) !important;
}

.tw-bg-teal-50\/15 {
  background-color: rgb(240 253 250 / 0.15) !important;
}

.tw-bg-teal-50\/20 {
  background-color: rgb(240 253 250 / 0.2) !important;
}

.tw-bg-teal-50\/25 {
  background-color: rgb(240 253 250 / 0.25) !important;
}

.tw-bg-teal-50\/30 {
  background-color: rgb(240 253 250 / 0.3) !important;
}

.tw-bg-teal-50\/35 {
  background-color: rgb(240 253 250 / 0.35) !important;
}

.tw-bg-teal-50\/40 {
  background-color: rgb(240 253 250 / 0.4) !important;
}

.tw-bg-teal-50\/45 {
  background-color: rgb(240 253 250 / 0.45) !important;
}

.tw-bg-teal-50\/5 {
  background-color: rgb(240 253 250 / 0.05) !important;
}

.tw-bg-teal-50\/50 {
  background-color: rgb(240 253 250 / 0.5) !important;
}

.tw-bg-teal-50\/55 {
  background-color: rgb(240 253 250 / 0.55) !important;
}

.tw-bg-teal-50\/60 {
  background-color: rgb(240 253 250 / 0.6) !important;
}

.tw-bg-teal-50\/65 {
  background-color: rgb(240 253 250 / 0.65) !important;
}

.tw-bg-teal-50\/70 {
  background-color: rgb(240 253 250 / 0.7) !important;
}

.tw-bg-teal-50\/75 {
  background-color: rgb(240 253 250 / 0.75) !important;
}

.tw-bg-teal-50\/80 {
  background-color: rgb(240 253 250 / 0.8) !important;
}

.tw-bg-teal-50\/85 {
  background-color: rgb(240 253 250 / 0.85) !important;
}

.tw-bg-teal-50\/90 {
  background-color: rgb(240 253 250 / 0.9) !important;
}

.tw-bg-teal-50\/95 {
  background-color: rgb(240 253 250 / 0.95) !important;
}

.tw-bg-teal-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(20 184 166 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-500\/0 {
  background-color: rgb(20 184 166 / 0) !important;
}

.tw-bg-teal-500\/10 {
  background-color: rgb(20 184 166 / 0.1) !important;
}

.tw-bg-teal-500\/100 {
  background-color: rgb(20 184 166 / 1) !important;
}

.tw-bg-teal-500\/15 {
  background-color: rgb(20 184 166 / 0.15) !important;
}

.tw-bg-teal-500\/20 {
  background-color: rgb(20 184 166 / 0.2) !important;
}

.tw-bg-teal-500\/25 {
  background-color: rgb(20 184 166 / 0.25) !important;
}

.tw-bg-teal-500\/30 {
  background-color: rgb(20 184 166 / 0.3) !important;
}

.tw-bg-teal-500\/35 {
  background-color: rgb(20 184 166 / 0.35) !important;
}

.tw-bg-teal-500\/40 {
  background-color: rgb(20 184 166 / 0.4) !important;
}

.tw-bg-teal-500\/45 {
  background-color: rgb(20 184 166 / 0.45) !important;
}

.tw-bg-teal-500\/5 {
  background-color: rgb(20 184 166 / 0.05) !important;
}

.tw-bg-teal-500\/50 {
  background-color: rgb(20 184 166 / 0.5) !important;
}

.tw-bg-teal-500\/55 {
  background-color: rgb(20 184 166 / 0.55) !important;
}

.tw-bg-teal-500\/60 {
  background-color: rgb(20 184 166 / 0.6) !important;
}

.tw-bg-teal-500\/65 {
  background-color: rgb(20 184 166 / 0.65) !important;
}

.tw-bg-teal-500\/70 {
  background-color: rgb(20 184 166 / 0.7) !important;
}

.tw-bg-teal-500\/75 {
  background-color: rgb(20 184 166 / 0.75) !important;
}

.tw-bg-teal-500\/80 {
  background-color: rgb(20 184 166 / 0.8) !important;
}

.tw-bg-teal-500\/85 {
  background-color: rgb(20 184 166 / 0.85) !important;
}

.tw-bg-teal-500\/90 {
  background-color: rgb(20 184 166 / 0.9) !important;
}

.tw-bg-teal-500\/95 {
  background-color: rgb(20 184 166 / 0.95) !important;
}

.tw-bg-teal-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(13 148 136 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-600\/0 {
  background-color: rgb(13 148 136 / 0) !important;
}

.tw-bg-teal-600\/10 {
  background-color: rgb(13 148 136 / 0.1) !important;
}

.tw-bg-teal-600\/100 {
  background-color: rgb(13 148 136 / 1) !important;
}

.tw-bg-teal-600\/15 {
  background-color: rgb(13 148 136 / 0.15) !important;
}

.tw-bg-teal-600\/20 {
  background-color: rgb(13 148 136 / 0.2) !important;
}

.tw-bg-teal-600\/25 {
  background-color: rgb(13 148 136 / 0.25) !important;
}

.tw-bg-teal-600\/30 {
  background-color: rgb(13 148 136 / 0.3) !important;
}

.tw-bg-teal-600\/35 {
  background-color: rgb(13 148 136 / 0.35) !important;
}

.tw-bg-teal-600\/40 {
  background-color: rgb(13 148 136 / 0.4) !important;
}

.tw-bg-teal-600\/45 {
  background-color: rgb(13 148 136 / 0.45) !important;
}

.tw-bg-teal-600\/5 {
  background-color: rgb(13 148 136 / 0.05) !important;
}

.tw-bg-teal-600\/50 {
  background-color: rgb(13 148 136 / 0.5) !important;
}

.tw-bg-teal-600\/55 {
  background-color: rgb(13 148 136 / 0.55) !important;
}

.tw-bg-teal-600\/60 {
  background-color: rgb(13 148 136 / 0.6) !important;
}

.tw-bg-teal-600\/65 {
  background-color: rgb(13 148 136 / 0.65) !important;
}

.tw-bg-teal-600\/70 {
  background-color: rgb(13 148 136 / 0.7) !important;
}

.tw-bg-teal-600\/75 {
  background-color: rgb(13 148 136 / 0.75) !important;
}

.tw-bg-teal-600\/80 {
  background-color: rgb(13 148 136 / 0.8) !important;
}

.tw-bg-teal-600\/85 {
  background-color: rgb(13 148 136 / 0.85) !important;
}

.tw-bg-teal-600\/90 {
  background-color: rgb(13 148 136 / 0.9) !important;
}

.tw-bg-teal-600\/95 {
  background-color: rgb(13 148 136 / 0.95) !important;
}

.tw-bg-teal-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(15 118 110 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-700\/0 {
  background-color: rgb(15 118 110 / 0) !important;
}

.tw-bg-teal-700\/10 {
  background-color: rgb(15 118 110 / 0.1) !important;
}

.tw-bg-teal-700\/100 {
  background-color: rgb(15 118 110 / 1) !important;
}

.tw-bg-teal-700\/15 {
  background-color: rgb(15 118 110 / 0.15) !important;
}

.tw-bg-teal-700\/20 {
  background-color: rgb(15 118 110 / 0.2) !important;
}

.tw-bg-teal-700\/25 {
  background-color: rgb(15 118 110 / 0.25) !important;
}

.tw-bg-teal-700\/30 {
  background-color: rgb(15 118 110 / 0.3) !important;
}

.tw-bg-teal-700\/35 {
  background-color: rgb(15 118 110 / 0.35) !important;
}

.tw-bg-teal-700\/40 {
  background-color: rgb(15 118 110 / 0.4) !important;
}

.tw-bg-teal-700\/45 {
  background-color: rgb(15 118 110 / 0.45) !important;
}

.tw-bg-teal-700\/5 {
  background-color: rgb(15 118 110 / 0.05) !important;
}

.tw-bg-teal-700\/50 {
  background-color: rgb(15 118 110 / 0.5) !important;
}

.tw-bg-teal-700\/55 {
  background-color: rgb(15 118 110 / 0.55) !important;
}

.tw-bg-teal-700\/60 {
  background-color: rgb(15 118 110 / 0.6) !important;
}

.tw-bg-teal-700\/65 {
  background-color: rgb(15 118 110 / 0.65) !important;
}

.tw-bg-teal-700\/70 {
  background-color: rgb(15 118 110 / 0.7) !important;
}

.tw-bg-teal-700\/75 {
  background-color: rgb(15 118 110 / 0.75) !important;
}

.tw-bg-teal-700\/80 {
  background-color: rgb(15 118 110 / 0.8) !important;
}

.tw-bg-teal-700\/85 {
  background-color: rgb(15 118 110 / 0.85) !important;
}

.tw-bg-teal-700\/90 {
  background-color: rgb(15 118 110 / 0.9) !important;
}

.tw-bg-teal-700\/95 {
  background-color: rgb(15 118 110 / 0.95) !important;
}

.tw-bg-teal-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(17 94 89 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-800\/0 {
  background-color: rgb(17 94 89 / 0) !important;
}

.tw-bg-teal-800\/10 {
  background-color: rgb(17 94 89 / 0.1) !important;
}

.tw-bg-teal-800\/100 {
  background-color: rgb(17 94 89 / 1) !important;
}

.tw-bg-teal-800\/15 {
  background-color: rgb(17 94 89 / 0.15) !important;
}

.tw-bg-teal-800\/20 {
  background-color: rgb(17 94 89 / 0.2) !important;
}

.tw-bg-teal-800\/25 {
  background-color: rgb(17 94 89 / 0.25) !important;
}

.tw-bg-teal-800\/30 {
  background-color: rgb(17 94 89 / 0.3) !important;
}

.tw-bg-teal-800\/35 {
  background-color: rgb(17 94 89 / 0.35) !important;
}

.tw-bg-teal-800\/40 {
  background-color: rgb(17 94 89 / 0.4) !important;
}

.tw-bg-teal-800\/45 {
  background-color: rgb(17 94 89 / 0.45) !important;
}

.tw-bg-teal-800\/5 {
  background-color: rgb(17 94 89 / 0.05) !important;
}

.tw-bg-teal-800\/50 {
  background-color: rgb(17 94 89 / 0.5) !important;
}

.tw-bg-teal-800\/55 {
  background-color: rgb(17 94 89 / 0.55) !important;
}

.tw-bg-teal-800\/60 {
  background-color: rgb(17 94 89 / 0.6) !important;
}

.tw-bg-teal-800\/65 {
  background-color: rgb(17 94 89 / 0.65) !important;
}

.tw-bg-teal-800\/70 {
  background-color: rgb(17 94 89 / 0.7) !important;
}

.tw-bg-teal-800\/75 {
  background-color: rgb(17 94 89 / 0.75) !important;
}

.tw-bg-teal-800\/80 {
  background-color: rgb(17 94 89 / 0.8) !important;
}

.tw-bg-teal-800\/85 {
  background-color: rgb(17 94 89 / 0.85) !important;
}

.tw-bg-teal-800\/90 {
  background-color: rgb(17 94 89 / 0.9) !important;
}

.tw-bg-teal-800\/95 {
  background-color: rgb(17 94 89 / 0.95) !important;
}

.tw-bg-teal-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(19 78 74 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-900\/0 {
  background-color: rgb(19 78 74 / 0) !important;
}

.tw-bg-teal-900\/10 {
  background-color: rgb(19 78 74 / 0.1) !important;
}

.tw-bg-teal-900\/100 {
  background-color: rgb(19 78 74 / 1) !important;
}

.tw-bg-teal-900\/15 {
  background-color: rgb(19 78 74 / 0.15) !important;
}

.tw-bg-teal-900\/20 {
  background-color: rgb(19 78 74 / 0.2) !important;
}

.tw-bg-teal-900\/25 {
  background-color: rgb(19 78 74 / 0.25) !important;
}

.tw-bg-teal-900\/30 {
  background-color: rgb(19 78 74 / 0.3) !important;
}

.tw-bg-teal-900\/35 {
  background-color: rgb(19 78 74 / 0.35) !important;
}

.tw-bg-teal-900\/40 {
  background-color: rgb(19 78 74 / 0.4) !important;
}

.tw-bg-teal-900\/45 {
  background-color: rgb(19 78 74 / 0.45) !important;
}

.tw-bg-teal-900\/5 {
  background-color: rgb(19 78 74 / 0.05) !important;
}

.tw-bg-teal-900\/50 {
  background-color: rgb(19 78 74 / 0.5) !important;
}

.tw-bg-teal-900\/55 {
  background-color: rgb(19 78 74 / 0.55) !important;
}

.tw-bg-teal-900\/60 {
  background-color: rgb(19 78 74 / 0.6) !important;
}

.tw-bg-teal-900\/65 {
  background-color: rgb(19 78 74 / 0.65) !important;
}

.tw-bg-teal-900\/70 {
  background-color: rgb(19 78 74 / 0.7) !important;
}

.tw-bg-teal-900\/75 {
  background-color: rgb(19 78 74 / 0.75) !important;
}

.tw-bg-teal-900\/80 {
  background-color: rgb(19 78 74 / 0.8) !important;
}

.tw-bg-teal-900\/85 {
  background-color: rgb(19 78 74 / 0.85) !important;
}

.tw-bg-teal-900\/90 {
  background-color: rgb(19 78 74 / 0.9) !important;
}

.tw-bg-teal-900\/95 {
  background-color: rgb(19 78 74 / 0.95) !important;
}

.tw-bg-teal-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(4 47 46 / var(--tw-bg-opacity)) !important;
}

.tw-bg-teal-950\/0 {
  background-color: rgb(4 47 46 / 0) !important;
}

.tw-bg-teal-950\/10 {
  background-color: rgb(4 47 46 / 0.1) !important;
}

.tw-bg-teal-950\/100 {
  background-color: rgb(4 47 46 / 1) !important;
}

.tw-bg-teal-950\/15 {
  background-color: rgb(4 47 46 / 0.15) !important;
}

.tw-bg-teal-950\/20 {
  background-color: rgb(4 47 46 / 0.2) !important;
}

.tw-bg-teal-950\/25 {
  background-color: rgb(4 47 46 / 0.25) !important;
}

.tw-bg-teal-950\/30 {
  background-color: rgb(4 47 46 / 0.3) !important;
}

.tw-bg-teal-950\/35 {
  background-color: rgb(4 47 46 / 0.35) !important;
}

.tw-bg-teal-950\/40 {
  background-color: rgb(4 47 46 / 0.4) !important;
}

.tw-bg-teal-950\/45 {
  background-color: rgb(4 47 46 / 0.45) !important;
}

.tw-bg-teal-950\/5 {
  background-color: rgb(4 47 46 / 0.05) !important;
}

.tw-bg-teal-950\/50 {
  background-color: rgb(4 47 46 / 0.5) !important;
}

.tw-bg-teal-950\/55 {
  background-color: rgb(4 47 46 / 0.55) !important;
}

.tw-bg-teal-950\/60 {
  background-color: rgb(4 47 46 / 0.6) !important;
}

.tw-bg-teal-950\/65 {
  background-color: rgb(4 47 46 / 0.65) !important;
}

.tw-bg-teal-950\/70 {
  background-color: rgb(4 47 46 / 0.7) !important;
}

.tw-bg-teal-950\/75 {
  background-color: rgb(4 47 46 / 0.75) !important;
}

.tw-bg-teal-950\/80 {
  background-color: rgb(4 47 46 / 0.8) !important;
}

.tw-bg-teal-950\/85 {
  background-color: rgb(4 47 46 / 0.85) !important;
}

.tw-bg-teal-950\/90 {
  background-color: rgb(4 47 46 / 0.9) !important;
}

.tw-bg-teal-950\/95 {
  background-color: rgb(4 47 46 / 0.95) !important;
}

.tw-bg-transparent {
  background-color: transparent !important;
}

.tw-bg-transparent\/0 {
  background-color: rgb(0 0 0 / 0) !important;
}

.tw-bg-transparent\/10 {
  background-color: rgb(0 0 0 / 0.1) !important;
}

.tw-bg-transparent\/100 {
  background-color: rgb(0 0 0 / 1) !important;
}

.tw-bg-transparent\/15 {
  background-color: rgb(0 0 0 / 0.15) !important;
}

.tw-bg-transparent\/20 {
  background-color: rgb(0 0 0 / 0.2) !important;
}

.tw-bg-transparent\/25 {
  background-color: rgb(0 0 0 / 0.25) !important;
}

.tw-bg-transparent\/30 {
  background-color: rgb(0 0 0 / 0.3) !important;
}

.tw-bg-transparent\/35 {
  background-color: rgb(0 0 0 / 0.35) !important;
}

.tw-bg-transparent\/40 {
  background-color: rgb(0 0 0 / 0.4) !important;
}

.tw-bg-transparent\/45 {
  background-color: rgb(0 0 0 / 0.45) !important;
}

.tw-bg-transparent\/5 {
  background-color: rgb(0 0 0 / 0.05) !important;
}

.tw-bg-transparent\/50 {
  background-color: rgb(0 0 0 / 0.5) !important;
}

.tw-bg-transparent\/55 {
  background-color: rgb(0 0 0 / 0.55) !important;
}

.tw-bg-transparent\/60 {
  background-color: rgb(0 0 0 / 0.6) !important;
}

.tw-bg-transparent\/65 {
  background-color: rgb(0 0 0 / 0.65) !important;
}

.tw-bg-transparent\/70 {
  background-color: rgb(0 0 0 / 0.7) !important;
}

.tw-bg-transparent\/75 {
  background-color: rgb(0 0 0 / 0.75) !important;
}

.tw-bg-transparent\/80 {
  background-color: rgb(0 0 0 / 0.8) !important;
}

.tw-bg-transparent\/85 {
  background-color: rgb(0 0 0 / 0.85) !important;
}

.tw-bg-transparent\/90 {
  background-color: rgb(0 0 0 / 0.9) !important;
}

.tw-bg-transparent\/95 {
  background-color: rgb(0 0 0 / 0.95) !important;
}

.tw-bg-violet-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(237 233 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-100\/0 {
  background-color: rgb(237 233 254 / 0) !important;
}

.tw-bg-violet-100\/10 {
  background-color: rgb(237 233 254 / 0.1) !important;
}

.tw-bg-violet-100\/100 {
  background-color: rgb(237 233 254 / 1) !important;
}

.tw-bg-violet-100\/15 {
  background-color: rgb(237 233 254 / 0.15) !important;
}

.tw-bg-violet-100\/20 {
  background-color: rgb(237 233 254 / 0.2) !important;
}

.tw-bg-violet-100\/25 {
  background-color: rgb(237 233 254 / 0.25) !important;
}

.tw-bg-violet-100\/30 {
  background-color: rgb(237 233 254 / 0.3) !important;
}

.tw-bg-violet-100\/35 {
  background-color: rgb(237 233 254 / 0.35) !important;
}

.tw-bg-violet-100\/40 {
  background-color: rgb(237 233 254 / 0.4) !important;
}

.tw-bg-violet-100\/45 {
  background-color: rgb(237 233 254 / 0.45) !important;
}

.tw-bg-violet-100\/5 {
  background-color: rgb(237 233 254 / 0.05) !important;
}

.tw-bg-violet-100\/50 {
  background-color: rgb(237 233 254 / 0.5) !important;
}

.tw-bg-violet-100\/55 {
  background-color: rgb(237 233 254 / 0.55) !important;
}

.tw-bg-violet-100\/60 {
  background-color: rgb(237 233 254 / 0.6) !important;
}

.tw-bg-violet-100\/65 {
  background-color: rgb(237 233 254 / 0.65) !important;
}

.tw-bg-violet-100\/70 {
  background-color: rgb(237 233 254 / 0.7) !important;
}

.tw-bg-violet-100\/75 {
  background-color: rgb(237 233 254 / 0.75) !important;
}

.tw-bg-violet-100\/80 {
  background-color: rgb(237 233 254 / 0.8) !important;
}

.tw-bg-violet-100\/85 {
  background-color: rgb(237 233 254 / 0.85) !important;
}

.tw-bg-violet-100\/90 {
  background-color: rgb(237 233 254 / 0.9) !important;
}

.tw-bg-violet-100\/95 {
  background-color: rgb(237 233 254 / 0.95) !important;
}

.tw-bg-violet-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(221 214 254 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-200\/0 {
  background-color: rgb(221 214 254 / 0) !important;
}

.tw-bg-violet-200\/10 {
  background-color: rgb(221 214 254 / 0.1) !important;
}

.tw-bg-violet-200\/100 {
  background-color: rgb(221 214 254 / 1) !important;
}

.tw-bg-violet-200\/15 {
  background-color: rgb(221 214 254 / 0.15) !important;
}

.tw-bg-violet-200\/20 {
  background-color: rgb(221 214 254 / 0.2) !important;
}

.tw-bg-violet-200\/25 {
  background-color: rgb(221 214 254 / 0.25) !important;
}

.tw-bg-violet-200\/30 {
  background-color: rgb(221 214 254 / 0.3) !important;
}

.tw-bg-violet-200\/35 {
  background-color: rgb(221 214 254 / 0.35) !important;
}

.tw-bg-violet-200\/40 {
  background-color: rgb(221 214 254 / 0.4) !important;
}

.tw-bg-violet-200\/45 {
  background-color: rgb(221 214 254 / 0.45) !important;
}

.tw-bg-violet-200\/5 {
  background-color: rgb(221 214 254 / 0.05) !important;
}

.tw-bg-violet-200\/50 {
  background-color: rgb(221 214 254 / 0.5) !important;
}

.tw-bg-violet-200\/55 {
  background-color: rgb(221 214 254 / 0.55) !important;
}

.tw-bg-violet-200\/60 {
  background-color: rgb(221 214 254 / 0.6) !important;
}

.tw-bg-violet-200\/65 {
  background-color: rgb(221 214 254 / 0.65) !important;
}

.tw-bg-violet-200\/70 {
  background-color: rgb(221 214 254 / 0.7) !important;
}

.tw-bg-violet-200\/75 {
  background-color: rgb(221 214 254 / 0.75) !important;
}

.tw-bg-violet-200\/80 {
  background-color: rgb(221 214 254 / 0.8) !important;
}

.tw-bg-violet-200\/85 {
  background-color: rgb(221 214 254 / 0.85) !important;
}

.tw-bg-violet-200\/90 {
  background-color: rgb(221 214 254 / 0.9) !important;
}

.tw-bg-violet-200\/95 {
  background-color: rgb(221 214 254 / 0.95) !important;
}

.tw-bg-violet-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(196 181 253 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-300\/0 {
  background-color: rgb(196 181 253 / 0) !important;
}

.tw-bg-violet-300\/10 {
  background-color: rgb(196 181 253 / 0.1) !important;
}

.tw-bg-violet-300\/100 {
  background-color: rgb(196 181 253 / 1) !important;
}

.tw-bg-violet-300\/15 {
  background-color: rgb(196 181 253 / 0.15) !important;
}

.tw-bg-violet-300\/20 {
  background-color: rgb(196 181 253 / 0.2) !important;
}

.tw-bg-violet-300\/25 {
  background-color: rgb(196 181 253 / 0.25) !important;
}

.tw-bg-violet-300\/30 {
  background-color: rgb(196 181 253 / 0.3) !important;
}

.tw-bg-violet-300\/35 {
  background-color: rgb(196 181 253 / 0.35) !important;
}

.tw-bg-violet-300\/40 {
  background-color: rgb(196 181 253 / 0.4) !important;
}

.tw-bg-violet-300\/45 {
  background-color: rgb(196 181 253 / 0.45) !important;
}

.tw-bg-violet-300\/5 {
  background-color: rgb(196 181 253 / 0.05) !important;
}

.tw-bg-violet-300\/50 {
  background-color: rgb(196 181 253 / 0.5) !important;
}

.tw-bg-violet-300\/55 {
  background-color: rgb(196 181 253 / 0.55) !important;
}

.tw-bg-violet-300\/60 {
  background-color: rgb(196 181 253 / 0.6) !important;
}

.tw-bg-violet-300\/65 {
  background-color: rgb(196 181 253 / 0.65) !important;
}

.tw-bg-violet-300\/70 {
  background-color: rgb(196 181 253 / 0.7) !important;
}

.tw-bg-violet-300\/75 {
  background-color: rgb(196 181 253 / 0.75) !important;
}

.tw-bg-violet-300\/80 {
  background-color: rgb(196 181 253 / 0.8) !important;
}

.tw-bg-violet-300\/85 {
  background-color: rgb(196 181 253 / 0.85) !important;
}

.tw-bg-violet-300\/90 {
  background-color: rgb(196 181 253 / 0.9) !important;
}

.tw-bg-violet-300\/95 {
  background-color: rgb(196 181 253 / 0.95) !important;
}

.tw-bg-violet-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(167 139 250 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-400\/0 {
  background-color: rgb(167 139 250 / 0) !important;
}

.tw-bg-violet-400\/10 {
  background-color: rgb(167 139 250 / 0.1) !important;
}

.tw-bg-violet-400\/100 {
  background-color: rgb(167 139 250 / 1) !important;
}

.tw-bg-violet-400\/15 {
  background-color: rgb(167 139 250 / 0.15) !important;
}

.tw-bg-violet-400\/20 {
  background-color: rgb(167 139 250 / 0.2) !important;
}

.tw-bg-violet-400\/25 {
  background-color: rgb(167 139 250 / 0.25) !important;
}

.tw-bg-violet-400\/30 {
  background-color: rgb(167 139 250 / 0.3) !important;
}

.tw-bg-violet-400\/35 {
  background-color: rgb(167 139 250 / 0.35) !important;
}

.tw-bg-violet-400\/40 {
  background-color: rgb(167 139 250 / 0.4) !important;
}

.tw-bg-violet-400\/45 {
  background-color: rgb(167 139 250 / 0.45) !important;
}

.tw-bg-violet-400\/5 {
  background-color: rgb(167 139 250 / 0.05) !important;
}

.tw-bg-violet-400\/50 {
  background-color: rgb(167 139 250 / 0.5) !important;
}

.tw-bg-violet-400\/55 {
  background-color: rgb(167 139 250 / 0.55) !important;
}

.tw-bg-violet-400\/60 {
  background-color: rgb(167 139 250 / 0.6) !important;
}

.tw-bg-violet-400\/65 {
  background-color: rgb(167 139 250 / 0.65) !important;
}

.tw-bg-violet-400\/70 {
  background-color: rgb(167 139 250 / 0.7) !important;
}

.tw-bg-violet-400\/75 {
  background-color: rgb(167 139 250 / 0.75) !important;
}

.tw-bg-violet-400\/80 {
  background-color: rgb(167 139 250 / 0.8) !important;
}

.tw-bg-violet-400\/85 {
  background-color: rgb(167 139 250 / 0.85) !important;
}

.tw-bg-violet-400\/90 {
  background-color: rgb(167 139 250 / 0.9) !important;
}

.tw-bg-violet-400\/95 {
  background-color: rgb(167 139 250 / 0.95) !important;
}

.tw-bg-violet-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(245 243 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-50\/0 {
  background-color: rgb(245 243 255 / 0) !important;
}

.tw-bg-violet-50\/10 {
  background-color: rgb(245 243 255 / 0.1) !important;
}

.tw-bg-violet-50\/100 {
  background-color: rgb(245 243 255 / 1) !important;
}

.tw-bg-violet-50\/15 {
  background-color: rgb(245 243 255 / 0.15) !important;
}

.tw-bg-violet-50\/20 {
  background-color: rgb(245 243 255 / 0.2) !important;
}

.tw-bg-violet-50\/25 {
  background-color: rgb(245 243 255 / 0.25) !important;
}

.tw-bg-violet-50\/30 {
  background-color: rgb(245 243 255 / 0.3) !important;
}

.tw-bg-violet-50\/35 {
  background-color: rgb(245 243 255 / 0.35) !important;
}

.tw-bg-violet-50\/40 {
  background-color: rgb(245 243 255 / 0.4) !important;
}

.tw-bg-violet-50\/45 {
  background-color: rgb(245 243 255 / 0.45) !important;
}

.tw-bg-violet-50\/5 {
  background-color: rgb(245 243 255 / 0.05) !important;
}

.tw-bg-violet-50\/50 {
  background-color: rgb(245 243 255 / 0.5) !important;
}

.tw-bg-violet-50\/55 {
  background-color: rgb(245 243 255 / 0.55) !important;
}

.tw-bg-violet-50\/60 {
  background-color: rgb(245 243 255 / 0.6) !important;
}

.tw-bg-violet-50\/65 {
  background-color: rgb(245 243 255 / 0.65) !important;
}

.tw-bg-violet-50\/70 {
  background-color: rgb(245 243 255 / 0.7) !important;
}

.tw-bg-violet-50\/75 {
  background-color: rgb(245 243 255 / 0.75) !important;
}

.tw-bg-violet-50\/80 {
  background-color: rgb(245 243 255 / 0.8) !important;
}

.tw-bg-violet-50\/85 {
  background-color: rgb(245 243 255 / 0.85) !important;
}

.tw-bg-violet-50\/90 {
  background-color: rgb(245 243 255 / 0.9) !important;
}

.tw-bg-violet-50\/95 {
  background-color: rgb(245 243 255 / 0.95) !important;
}

.tw-bg-violet-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(139 92 246 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-500\/0 {
  background-color: rgb(139 92 246 / 0) !important;
}

.tw-bg-violet-500\/10 {
  background-color: rgb(139 92 246 / 0.1) !important;
}

.tw-bg-violet-500\/100 {
  background-color: rgb(139 92 246 / 1) !important;
}

.tw-bg-violet-500\/15 {
  background-color: rgb(139 92 246 / 0.15) !important;
}

.tw-bg-violet-500\/20 {
  background-color: rgb(139 92 246 / 0.2) !important;
}

.tw-bg-violet-500\/25 {
  background-color: rgb(139 92 246 / 0.25) !important;
}

.tw-bg-violet-500\/30 {
  background-color: rgb(139 92 246 / 0.3) !important;
}

.tw-bg-violet-500\/35 {
  background-color: rgb(139 92 246 / 0.35) !important;
}

.tw-bg-violet-500\/40 {
  background-color: rgb(139 92 246 / 0.4) !important;
}

.tw-bg-violet-500\/45 {
  background-color: rgb(139 92 246 / 0.45) !important;
}

.tw-bg-violet-500\/5 {
  background-color: rgb(139 92 246 / 0.05) !important;
}

.tw-bg-violet-500\/50 {
  background-color: rgb(139 92 246 / 0.5) !important;
}

.tw-bg-violet-500\/55 {
  background-color: rgb(139 92 246 / 0.55) !important;
}

.tw-bg-violet-500\/60 {
  background-color: rgb(139 92 246 / 0.6) !important;
}

.tw-bg-violet-500\/65 {
  background-color: rgb(139 92 246 / 0.65) !important;
}

.tw-bg-violet-500\/70 {
  background-color: rgb(139 92 246 / 0.7) !important;
}

.tw-bg-violet-500\/75 {
  background-color: rgb(139 92 246 / 0.75) !important;
}

.tw-bg-violet-500\/80 {
  background-color: rgb(139 92 246 / 0.8) !important;
}

.tw-bg-violet-500\/85 {
  background-color: rgb(139 92 246 / 0.85) !important;
}

.tw-bg-violet-500\/90 {
  background-color: rgb(139 92 246 / 0.9) !important;
}

.tw-bg-violet-500\/95 {
  background-color: rgb(139 92 246 / 0.95) !important;
}

.tw-bg-violet-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(124 58 237 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-600\/0 {
  background-color: rgb(124 58 237 / 0) !important;
}

.tw-bg-violet-600\/10 {
  background-color: rgb(124 58 237 / 0.1) !important;
}

.tw-bg-violet-600\/100 {
  background-color: rgb(124 58 237 / 1) !important;
}

.tw-bg-violet-600\/15 {
  background-color: rgb(124 58 237 / 0.15) !important;
}

.tw-bg-violet-600\/20 {
  background-color: rgb(124 58 237 / 0.2) !important;
}

.tw-bg-violet-600\/25 {
  background-color: rgb(124 58 237 / 0.25) !important;
}

.tw-bg-violet-600\/30 {
  background-color: rgb(124 58 237 / 0.3) !important;
}

.tw-bg-violet-600\/35 {
  background-color: rgb(124 58 237 / 0.35) !important;
}

.tw-bg-violet-600\/40 {
  background-color: rgb(124 58 237 / 0.4) !important;
}

.tw-bg-violet-600\/45 {
  background-color: rgb(124 58 237 / 0.45) !important;
}

.tw-bg-violet-600\/5 {
  background-color: rgb(124 58 237 / 0.05) !important;
}

.tw-bg-violet-600\/50 {
  background-color: rgb(124 58 237 / 0.5) !important;
}

.tw-bg-violet-600\/55 {
  background-color: rgb(124 58 237 / 0.55) !important;
}

.tw-bg-violet-600\/60 {
  background-color: rgb(124 58 237 / 0.6) !important;
}

.tw-bg-violet-600\/65 {
  background-color: rgb(124 58 237 / 0.65) !important;
}

.tw-bg-violet-600\/70 {
  background-color: rgb(124 58 237 / 0.7) !important;
}

.tw-bg-violet-600\/75 {
  background-color: rgb(124 58 237 / 0.75) !important;
}

.tw-bg-violet-600\/80 {
  background-color: rgb(124 58 237 / 0.8) !important;
}

.tw-bg-violet-600\/85 {
  background-color: rgb(124 58 237 / 0.85) !important;
}

.tw-bg-violet-600\/90 {
  background-color: rgb(124 58 237 / 0.9) !important;
}

.tw-bg-violet-600\/95 {
  background-color: rgb(124 58 237 / 0.95) !important;
}

.tw-bg-violet-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-700\/0 {
  background-color: rgb(109 40 217 / 0) !important;
}

.tw-bg-violet-700\/10 {
  background-color: rgb(109 40 217 / 0.1) !important;
}

.tw-bg-violet-700\/100 {
  background-color: rgb(109 40 217 / 1) !important;
}

.tw-bg-violet-700\/15 {
  background-color: rgb(109 40 217 / 0.15) !important;
}

.tw-bg-violet-700\/20 {
  background-color: rgb(109 40 217 / 0.2) !important;
}

.tw-bg-violet-700\/25 {
  background-color: rgb(109 40 217 / 0.25) !important;
}

.tw-bg-violet-700\/30 {
  background-color: rgb(109 40 217 / 0.3) !important;
}

.tw-bg-violet-700\/35 {
  background-color: rgb(109 40 217 / 0.35) !important;
}

.tw-bg-violet-700\/40 {
  background-color: rgb(109 40 217 / 0.4) !important;
}

.tw-bg-violet-700\/45 {
  background-color: rgb(109 40 217 / 0.45) !important;
}

.tw-bg-violet-700\/5 {
  background-color: rgb(109 40 217 / 0.05) !important;
}

.tw-bg-violet-700\/50 {
  background-color: rgb(109 40 217 / 0.5) !important;
}

.tw-bg-violet-700\/55 {
  background-color: rgb(109 40 217 / 0.55) !important;
}

.tw-bg-violet-700\/60 {
  background-color: rgb(109 40 217 / 0.6) !important;
}

.tw-bg-violet-700\/65 {
  background-color: rgb(109 40 217 / 0.65) !important;
}

.tw-bg-violet-700\/70 {
  background-color: rgb(109 40 217 / 0.7) !important;
}

.tw-bg-violet-700\/75 {
  background-color: rgb(109 40 217 / 0.75) !important;
}

.tw-bg-violet-700\/80 {
  background-color: rgb(109 40 217 / 0.8) !important;
}

.tw-bg-violet-700\/85 {
  background-color: rgb(109 40 217 / 0.85) !important;
}

.tw-bg-violet-700\/90 {
  background-color: rgb(109 40 217 / 0.9) !important;
}

.tw-bg-violet-700\/95 {
  background-color: rgb(109 40 217 / 0.95) !important;
}

.tw-bg-violet-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(91 33 182 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-800\/0 {
  background-color: rgb(91 33 182 / 0) !important;
}

.tw-bg-violet-800\/10 {
  background-color: rgb(91 33 182 / 0.1) !important;
}

.tw-bg-violet-800\/100 {
  background-color: rgb(91 33 182 / 1) !important;
}

.tw-bg-violet-800\/15 {
  background-color: rgb(91 33 182 / 0.15) !important;
}

.tw-bg-violet-800\/20 {
  background-color: rgb(91 33 182 / 0.2) !important;
}

.tw-bg-violet-800\/25 {
  background-color: rgb(91 33 182 / 0.25) !important;
}

.tw-bg-violet-800\/30 {
  background-color: rgb(91 33 182 / 0.3) !important;
}

.tw-bg-violet-800\/35 {
  background-color: rgb(91 33 182 / 0.35) !important;
}

.tw-bg-violet-800\/40 {
  background-color: rgb(91 33 182 / 0.4) !important;
}

.tw-bg-violet-800\/45 {
  background-color: rgb(91 33 182 / 0.45) !important;
}

.tw-bg-violet-800\/5 {
  background-color: rgb(91 33 182 / 0.05) !important;
}

.tw-bg-violet-800\/50 {
  background-color: rgb(91 33 182 / 0.5) !important;
}

.tw-bg-violet-800\/55 {
  background-color: rgb(91 33 182 / 0.55) !important;
}

.tw-bg-violet-800\/60 {
  background-color: rgb(91 33 182 / 0.6) !important;
}

.tw-bg-violet-800\/65 {
  background-color: rgb(91 33 182 / 0.65) !important;
}

.tw-bg-violet-800\/70 {
  background-color: rgb(91 33 182 / 0.7) !important;
}

.tw-bg-violet-800\/75 {
  background-color: rgb(91 33 182 / 0.75) !important;
}

.tw-bg-violet-800\/80 {
  background-color: rgb(91 33 182 / 0.8) !important;
}

.tw-bg-violet-800\/85 {
  background-color: rgb(91 33 182 / 0.85) !important;
}

.tw-bg-violet-800\/90 {
  background-color: rgb(91 33 182 / 0.9) !important;
}

.tw-bg-violet-800\/95 {
  background-color: rgb(91 33 182 / 0.95) !important;
}

.tw-bg-violet-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-900\/0 {
  background-color: rgb(76 29 149 / 0) !important;
}

.tw-bg-violet-900\/10 {
  background-color: rgb(76 29 149 / 0.1) !important;
}

.tw-bg-violet-900\/100 {
  background-color: rgb(76 29 149 / 1) !important;
}

.tw-bg-violet-900\/15 {
  background-color: rgb(76 29 149 / 0.15) !important;
}

.tw-bg-violet-900\/20 {
  background-color: rgb(76 29 149 / 0.2) !important;
}

.tw-bg-violet-900\/25 {
  background-color: rgb(76 29 149 / 0.25) !important;
}

.tw-bg-violet-900\/30 {
  background-color: rgb(76 29 149 / 0.3) !important;
}

.tw-bg-violet-900\/35 {
  background-color: rgb(76 29 149 / 0.35) !important;
}

.tw-bg-violet-900\/40 {
  background-color: rgb(76 29 149 / 0.4) !important;
}

.tw-bg-violet-900\/45 {
  background-color: rgb(76 29 149 / 0.45) !important;
}

.tw-bg-violet-900\/5 {
  background-color: rgb(76 29 149 / 0.05) !important;
}

.tw-bg-violet-900\/50 {
  background-color: rgb(76 29 149 / 0.5) !important;
}

.tw-bg-violet-900\/55 {
  background-color: rgb(76 29 149 / 0.55) !important;
}

.tw-bg-violet-900\/60 {
  background-color: rgb(76 29 149 / 0.6) !important;
}

.tw-bg-violet-900\/65 {
  background-color: rgb(76 29 149 / 0.65) !important;
}

.tw-bg-violet-900\/70 {
  background-color: rgb(76 29 149 / 0.7) !important;
}

.tw-bg-violet-900\/75 {
  background-color: rgb(76 29 149 / 0.75) !important;
}

.tw-bg-violet-900\/80 {
  background-color: rgb(76 29 149 / 0.8) !important;
}

.tw-bg-violet-900\/85 {
  background-color: rgb(76 29 149 / 0.85) !important;
}

.tw-bg-violet-900\/90 {
  background-color: rgb(76 29 149 / 0.9) !important;
}

.tw-bg-violet-900\/95 {
  background-color: rgb(76 29 149 / 0.95) !important;
}

.tw-bg-violet-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(46 16 101 / var(--tw-bg-opacity)) !important;
}

.tw-bg-violet-950\/0 {
  background-color: rgb(46 16 101 / 0) !important;
}

.tw-bg-violet-950\/10 {
  background-color: rgb(46 16 101 / 0.1) !important;
}

.tw-bg-violet-950\/100 {
  background-color: rgb(46 16 101 / 1) !important;
}

.tw-bg-violet-950\/15 {
  background-color: rgb(46 16 101 / 0.15) !important;
}

.tw-bg-violet-950\/20 {
  background-color: rgb(46 16 101 / 0.2) !important;
}

.tw-bg-violet-950\/25 {
  background-color: rgb(46 16 101 / 0.25) !important;
}

.tw-bg-violet-950\/30 {
  background-color: rgb(46 16 101 / 0.3) !important;
}

.tw-bg-violet-950\/35 {
  background-color: rgb(46 16 101 / 0.35) !important;
}

.tw-bg-violet-950\/40 {
  background-color: rgb(46 16 101 / 0.4) !important;
}

.tw-bg-violet-950\/45 {
  background-color: rgb(46 16 101 / 0.45) !important;
}

.tw-bg-violet-950\/5 {
  background-color: rgb(46 16 101 / 0.05) !important;
}

.tw-bg-violet-950\/50 {
  background-color: rgb(46 16 101 / 0.5) !important;
}

.tw-bg-violet-950\/55 {
  background-color: rgb(46 16 101 / 0.55) !important;
}

.tw-bg-violet-950\/60 {
  background-color: rgb(46 16 101 / 0.6) !important;
}

.tw-bg-violet-950\/65 {
  background-color: rgb(46 16 101 / 0.65) !important;
}

.tw-bg-violet-950\/70 {
  background-color: rgb(46 16 101 / 0.7) !important;
}

.tw-bg-violet-950\/75 {
  background-color: rgb(46 16 101 / 0.75) !important;
}

.tw-bg-violet-950\/80 {
  background-color: rgb(46 16 101 / 0.8) !important;
}

.tw-bg-violet-950\/85 {
  background-color: rgb(46 16 101 / 0.85) !important;
}

.tw-bg-violet-950\/90 {
  background-color: rgb(46 16 101 / 0.9) !important;
}

.tw-bg-violet-950\/95 {
  background-color: rgb(46 16 101 / 0.95) !important;
}

.tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity)) !important;
}

.tw-bg-white\/0 {
  background-color: rgb(255 255 255 / 0) !important;
}

.tw-bg-white\/10 {
  background-color: rgb(255 255 255 / 0.1) !important;
}

.tw-bg-white\/100 {
  background-color: rgb(255 255 255 / 1) !important;
}

.tw-bg-white\/15 {
  background-color: rgb(255 255 255 / 0.15) !important;
}

.tw-bg-white\/20 {
  background-color: rgb(255 255 255 / 0.2) !important;
}

.tw-bg-white\/25 {
  background-color: rgb(255 255 255 / 0.25) !important;
}

.tw-bg-white\/30 {
  background-color: rgb(255 255 255 / 0.3) !important;
}

.tw-bg-white\/35 {
  background-color: rgb(255 255 255 / 0.35) !important;
}

.tw-bg-white\/40 {
  background-color: rgb(255 255 255 / 0.4) !important;
}

.tw-bg-white\/45 {
  background-color: rgb(255 255 255 / 0.45) !important;
}

.tw-bg-white\/5 {
  background-color: rgb(255 255 255 / 0.05) !important;
}

.tw-bg-white\/50 {
  background-color: rgb(255 255 255 / 0.5) !important;
}

.tw-bg-white\/55 {
  background-color: rgb(255 255 255 / 0.55) !important;
}

.tw-bg-white\/60 {
  background-color: rgb(255 255 255 / 0.6) !important;
}

.tw-bg-white\/65 {
  background-color: rgb(255 255 255 / 0.65) !important;
}

.tw-bg-white\/70 {
  background-color: rgb(255 255 255 / 0.7) !important;
}

.tw-bg-white\/75 {
  background-color: rgb(255 255 255 / 0.75) !important;
}

.tw-bg-white\/80 {
  background-color: rgb(255 255 255 / 0.8) !important;
}

.tw-bg-white\/85 {
  background-color: rgb(255 255 255 / 0.85) !important;
}

.tw-bg-white\/90 {
  background-color: rgb(255 255 255 / 0.9) !important;
}

.tw-bg-white\/95 {
  background-color: rgb(255 255 255 / 0.95) !important;
}

.tw-bg-yellow-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 249 195 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-100\/0 {
  background-color: rgb(254 249 195 / 0) !important;
}

.tw-bg-yellow-100\/10 {
  background-color: rgb(254 249 195 / 0.1) !important;
}

.tw-bg-yellow-100\/100 {
  background-color: rgb(254 249 195 / 1) !important;
}

.tw-bg-yellow-100\/15 {
  background-color: rgb(254 249 195 / 0.15) !important;
}

.tw-bg-yellow-100\/20 {
  background-color: rgb(254 249 195 / 0.2) !important;
}

.tw-bg-yellow-100\/25 {
  background-color: rgb(254 249 195 / 0.25) !important;
}

.tw-bg-yellow-100\/30 {
  background-color: rgb(254 249 195 / 0.3) !important;
}

.tw-bg-yellow-100\/35 {
  background-color: rgb(254 249 195 / 0.35) !important;
}

.tw-bg-yellow-100\/40 {
  background-color: rgb(254 249 195 / 0.4) !important;
}

.tw-bg-yellow-100\/45 {
  background-color: rgb(254 249 195 / 0.45) !important;
}

.tw-bg-yellow-100\/5 {
  background-color: rgb(254 249 195 / 0.05) !important;
}

.tw-bg-yellow-100\/50 {
  background-color: rgb(254 249 195 / 0.5) !important;
}

.tw-bg-yellow-100\/55 {
  background-color: rgb(254 249 195 / 0.55) !important;
}

.tw-bg-yellow-100\/60 {
  background-color: rgb(254 249 195 / 0.6) !important;
}

.tw-bg-yellow-100\/65 {
  background-color: rgb(254 249 195 / 0.65) !important;
}

.tw-bg-yellow-100\/70 {
  background-color: rgb(254 249 195 / 0.7) !important;
}

.tw-bg-yellow-100\/75 {
  background-color: rgb(254 249 195 / 0.75) !important;
}

.tw-bg-yellow-100\/80 {
  background-color: rgb(254 249 195 / 0.8) !important;
}

.tw-bg-yellow-100\/85 {
  background-color: rgb(254 249 195 / 0.85) !important;
}

.tw-bg-yellow-100\/90 {
  background-color: rgb(254 249 195 / 0.9) !important;
}

.tw-bg-yellow-100\/95 {
  background-color: rgb(254 249 195 / 0.95) !important;
}

.tw-bg-yellow-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 240 138 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-200\/0 {
  background-color: rgb(254 240 138 / 0) !important;
}

.tw-bg-yellow-200\/10 {
  background-color: rgb(254 240 138 / 0.1) !important;
}

.tw-bg-yellow-200\/100 {
  background-color: rgb(254 240 138 / 1) !important;
}

.tw-bg-yellow-200\/15 {
  background-color: rgb(254 240 138 / 0.15) !important;
}

.tw-bg-yellow-200\/20 {
  background-color: rgb(254 240 138 / 0.2) !important;
}

.tw-bg-yellow-200\/25 {
  background-color: rgb(254 240 138 / 0.25) !important;
}

.tw-bg-yellow-200\/30 {
  background-color: rgb(254 240 138 / 0.3) !important;
}

.tw-bg-yellow-200\/35 {
  background-color: rgb(254 240 138 / 0.35) !important;
}

.tw-bg-yellow-200\/40 {
  background-color: rgb(254 240 138 / 0.4) !important;
}

.tw-bg-yellow-200\/45 {
  background-color: rgb(254 240 138 / 0.45) !important;
}

.tw-bg-yellow-200\/5 {
  background-color: rgb(254 240 138 / 0.05) !important;
}

.tw-bg-yellow-200\/50 {
  background-color: rgb(254 240 138 / 0.5) !important;
}

.tw-bg-yellow-200\/55 {
  background-color: rgb(254 240 138 / 0.55) !important;
}

.tw-bg-yellow-200\/60 {
  background-color: rgb(254 240 138 / 0.6) !important;
}

.tw-bg-yellow-200\/65 {
  background-color: rgb(254 240 138 / 0.65) !important;
}

.tw-bg-yellow-200\/70 {
  background-color: rgb(254 240 138 / 0.7) !important;
}

.tw-bg-yellow-200\/75 {
  background-color: rgb(254 240 138 / 0.75) !important;
}

.tw-bg-yellow-200\/80 {
  background-color: rgb(254 240 138 / 0.8) !important;
}

.tw-bg-yellow-200\/85 {
  background-color: rgb(254 240 138 / 0.85) !important;
}

.tw-bg-yellow-200\/90 {
  background-color: rgb(254 240 138 / 0.9) !important;
}

.tw-bg-yellow-200\/95 {
  background-color: rgb(254 240 138 / 0.95) !important;
}

.tw-bg-yellow-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(253 224 71 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-300\/0 {
  background-color: rgb(253 224 71 / 0) !important;
}

.tw-bg-yellow-300\/10 {
  background-color: rgb(253 224 71 / 0.1) !important;
}

.tw-bg-yellow-300\/100 {
  background-color: rgb(253 224 71 / 1) !important;
}

.tw-bg-yellow-300\/15 {
  background-color: rgb(253 224 71 / 0.15) !important;
}

.tw-bg-yellow-300\/20 {
  background-color: rgb(253 224 71 / 0.2) !important;
}

.tw-bg-yellow-300\/25 {
  background-color: rgb(253 224 71 / 0.25) !important;
}

.tw-bg-yellow-300\/30 {
  background-color: rgb(253 224 71 / 0.3) !important;
}

.tw-bg-yellow-300\/35 {
  background-color: rgb(253 224 71 / 0.35) !important;
}

.tw-bg-yellow-300\/40 {
  background-color: rgb(253 224 71 / 0.4) !important;
}

.tw-bg-yellow-300\/45 {
  background-color: rgb(253 224 71 / 0.45) !important;
}

.tw-bg-yellow-300\/5 {
  background-color: rgb(253 224 71 / 0.05) !important;
}

.tw-bg-yellow-300\/50 {
  background-color: rgb(253 224 71 / 0.5) !important;
}

.tw-bg-yellow-300\/55 {
  background-color: rgb(253 224 71 / 0.55) !important;
}

.tw-bg-yellow-300\/60 {
  background-color: rgb(253 224 71 / 0.6) !important;
}

.tw-bg-yellow-300\/65 {
  background-color: rgb(253 224 71 / 0.65) !important;
}

.tw-bg-yellow-300\/70 {
  background-color: rgb(253 224 71 / 0.7) !important;
}

.tw-bg-yellow-300\/75 {
  background-color: rgb(253 224 71 / 0.75) !important;
}

.tw-bg-yellow-300\/80 {
  background-color: rgb(253 224 71 / 0.8) !important;
}

.tw-bg-yellow-300\/85 {
  background-color: rgb(253 224 71 / 0.85) !important;
}

.tw-bg-yellow-300\/90 {
  background-color: rgb(253 224 71 / 0.9) !important;
}

.tw-bg-yellow-300\/95 {
  background-color: rgb(253 224 71 / 0.95) !important;
}

.tw-bg-yellow-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 204 21 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-400\/0 {
  background-color: rgb(250 204 21 / 0) !important;
}

.tw-bg-yellow-400\/10 {
  background-color: rgb(250 204 21 / 0.1) !important;
}

.tw-bg-yellow-400\/100 {
  background-color: rgb(250 204 21 / 1) !important;
}

.tw-bg-yellow-400\/15 {
  background-color: rgb(250 204 21 / 0.15) !important;
}

.tw-bg-yellow-400\/20 {
  background-color: rgb(250 204 21 / 0.2) !important;
}

.tw-bg-yellow-400\/25 {
  background-color: rgb(250 204 21 / 0.25) !important;
}

.tw-bg-yellow-400\/30 {
  background-color: rgb(250 204 21 / 0.3) !important;
}

.tw-bg-yellow-400\/35 {
  background-color: rgb(250 204 21 / 0.35) !important;
}

.tw-bg-yellow-400\/40 {
  background-color: rgb(250 204 21 / 0.4) !important;
}

.tw-bg-yellow-400\/45 {
  background-color: rgb(250 204 21 / 0.45) !important;
}

.tw-bg-yellow-400\/5 {
  background-color: rgb(250 204 21 / 0.05) !important;
}

.tw-bg-yellow-400\/50 {
  background-color: rgb(250 204 21 / 0.5) !important;
}

.tw-bg-yellow-400\/55 {
  background-color: rgb(250 204 21 / 0.55) !important;
}

.tw-bg-yellow-400\/60 {
  background-color: rgb(250 204 21 / 0.6) !important;
}

.tw-bg-yellow-400\/65 {
  background-color: rgb(250 204 21 / 0.65) !important;
}

.tw-bg-yellow-400\/70 {
  background-color: rgb(250 204 21 / 0.7) !important;
}

.tw-bg-yellow-400\/75 {
  background-color: rgb(250 204 21 / 0.75) !important;
}

.tw-bg-yellow-400\/80 {
  background-color: rgb(250 204 21 / 0.8) !important;
}

.tw-bg-yellow-400\/85 {
  background-color: rgb(250 204 21 / 0.85) !important;
}

.tw-bg-yellow-400\/90 {
  background-color: rgb(250 204 21 / 0.9) !important;
}

.tw-bg-yellow-400\/95 {
  background-color: rgb(250 204 21 / 0.95) !important;
}

.tw-bg-yellow-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(254 252 232 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-50\/0 {
  background-color: rgb(254 252 232 / 0) !important;
}

.tw-bg-yellow-50\/10 {
  background-color: rgb(254 252 232 / 0.1) !important;
}

.tw-bg-yellow-50\/100 {
  background-color: rgb(254 252 232 / 1) !important;
}

.tw-bg-yellow-50\/15 {
  background-color: rgb(254 252 232 / 0.15) !important;
}

.tw-bg-yellow-50\/20 {
  background-color: rgb(254 252 232 / 0.2) !important;
}

.tw-bg-yellow-50\/25 {
  background-color: rgb(254 252 232 / 0.25) !important;
}

.tw-bg-yellow-50\/30 {
  background-color: rgb(254 252 232 / 0.3) !important;
}

.tw-bg-yellow-50\/35 {
  background-color: rgb(254 252 232 / 0.35) !important;
}

.tw-bg-yellow-50\/40 {
  background-color: rgb(254 252 232 / 0.4) !important;
}

.tw-bg-yellow-50\/45 {
  background-color: rgb(254 252 232 / 0.45) !important;
}

.tw-bg-yellow-50\/5 {
  background-color: rgb(254 252 232 / 0.05) !important;
}

.tw-bg-yellow-50\/50 {
  background-color: rgb(254 252 232 / 0.5) !important;
}

.tw-bg-yellow-50\/55 {
  background-color: rgb(254 252 232 / 0.55) !important;
}

.tw-bg-yellow-50\/60 {
  background-color: rgb(254 252 232 / 0.6) !important;
}

.tw-bg-yellow-50\/65 {
  background-color: rgb(254 252 232 / 0.65) !important;
}

.tw-bg-yellow-50\/70 {
  background-color: rgb(254 252 232 / 0.7) !important;
}

.tw-bg-yellow-50\/75 {
  background-color: rgb(254 252 232 / 0.75) !important;
}

.tw-bg-yellow-50\/80 {
  background-color: rgb(254 252 232 / 0.8) !important;
}

.tw-bg-yellow-50\/85 {
  background-color: rgb(254 252 232 / 0.85) !important;
}

.tw-bg-yellow-50\/90 {
  background-color: rgb(254 252 232 / 0.9) !important;
}

.tw-bg-yellow-50\/95 {
  background-color: rgb(254 252 232 / 0.95) !important;
}

.tw-bg-yellow-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(234 179 8 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-500\/0 {
  background-color: rgb(234 179 8 / 0) !important;
}

.tw-bg-yellow-500\/10 {
  background-color: rgb(234 179 8 / 0.1) !important;
}

.tw-bg-yellow-500\/100 {
  background-color: rgb(234 179 8 / 1) !important;
}

.tw-bg-yellow-500\/15 {
  background-color: rgb(234 179 8 / 0.15) !important;
}

.tw-bg-yellow-500\/20 {
  background-color: rgb(234 179 8 / 0.2) !important;
}

.tw-bg-yellow-500\/25 {
  background-color: rgb(234 179 8 / 0.25) !important;
}

.tw-bg-yellow-500\/30 {
  background-color: rgb(234 179 8 / 0.3) !important;
}

.tw-bg-yellow-500\/35 {
  background-color: rgb(234 179 8 / 0.35) !important;
}

.tw-bg-yellow-500\/40 {
  background-color: rgb(234 179 8 / 0.4) !important;
}

.tw-bg-yellow-500\/45 {
  background-color: rgb(234 179 8 / 0.45) !important;
}

.tw-bg-yellow-500\/5 {
  background-color: rgb(234 179 8 / 0.05) !important;
}

.tw-bg-yellow-500\/50 {
  background-color: rgb(234 179 8 / 0.5) !important;
}

.tw-bg-yellow-500\/55 {
  background-color: rgb(234 179 8 / 0.55) !important;
}

.tw-bg-yellow-500\/60 {
  background-color: rgb(234 179 8 / 0.6) !important;
}

.tw-bg-yellow-500\/65 {
  background-color: rgb(234 179 8 / 0.65) !important;
}

.tw-bg-yellow-500\/70 {
  background-color: rgb(234 179 8 / 0.7) !important;
}

.tw-bg-yellow-500\/75 {
  background-color: rgb(234 179 8 / 0.75) !important;
}

.tw-bg-yellow-500\/80 {
  background-color: rgb(234 179 8 / 0.8) !important;
}

.tw-bg-yellow-500\/85 {
  background-color: rgb(234 179 8 / 0.85) !important;
}

.tw-bg-yellow-500\/90 {
  background-color: rgb(234 179 8 / 0.9) !important;
}

.tw-bg-yellow-500\/95 {
  background-color: rgb(234 179 8 / 0.95) !important;
}

.tw-bg-yellow-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(202 138 4 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-600\/0 {
  background-color: rgb(202 138 4 / 0) !important;
}

.tw-bg-yellow-600\/10 {
  background-color: rgb(202 138 4 / 0.1) !important;
}

.tw-bg-yellow-600\/100 {
  background-color: rgb(202 138 4 / 1) !important;
}

.tw-bg-yellow-600\/15 {
  background-color: rgb(202 138 4 / 0.15) !important;
}

.tw-bg-yellow-600\/20 {
  background-color: rgb(202 138 4 / 0.2) !important;
}

.tw-bg-yellow-600\/25 {
  background-color: rgb(202 138 4 / 0.25) !important;
}

.tw-bg-yellow-600\/30 {
  background-color: rgb(202 138 4 / 0.3) !important;
}

.tw-bg-yellow-600\/35 {
  background-color: rgb(202 138 4 / 0.35) !important;
}

.tw-bg-yellow-600\/40 {
  background-color: rgb(202 138 4 / 0.4) !important;
}

.tw-bg-yellow-600\/45 {
  background-color: rgb(202 138 4 / 0.45) !important;
}

.tw-bg-yellow-600\/5 {
  background-color: rgb(202 138 4 / 0.05) !important;
}

.tw-bg-yellow-600\/50 {
  background-color: rgb(202 138 4 / 0.5) !important;
}

.tw-bg-yellow-600\/55 {
  background-color: rgb(202 138 4 / 0.55) !important;
}

.tw-bg-yellow-600\/60 {
  background-color: rgb(202 138 4 / 0.6) !important;
}

.tw-bg-yellow-600\/65 {
  background-color: rgb(202 138 4 / 0.65) !important;
}

.tw-bg-yellow-600\/70 {
  background-color: rgb(202 138 4 / 0.7) !important;
}

.tw-bg-yellow-600\/75 {
  background-color: rgb(202 138 4 / 0.75) !important;
}

.tw-bg-yellow-600\/80 {
  background-color: rgb(202 138 4 / 0.8) !important;
}

.tw-bg-yellow-600\/85 {
  background-color: rgb(202 138 4 / 0.85) !important;
}

.tw-bg-yellow-600\/90 {
  background-color: rgb(202 138 4 / 0.9) !important;
}

.tw-bg-yellow-600\/95 {
  background-color: rgb(202 138 4 / 0.95) !important;
}

.tw-bg-yellow-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(161 98 7 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-700\/0 {
  background-color: rgb(161 98 7 / 0) !important;
}

.tw-bg-yellow-700\/10 {
  background-color: rgb(161 98 7 / 0.1) !important;
}

.tw-bg-yellow-700\/100 {
  background-color: rgb(161 98 7 / 1) !important;
}

.tw-bg-yellow-700\/15 {
  background-color: rgb(161 98 7 / 0.15) !important;
}

.tw-bg-yellow-700\/20 {
  background-color: rgb(161 98 7 / 0.2) !important;
}

.tw-bg-yellow-700\/25 {
  background-color: rgb(161 98 7 / 0.25) !important;
}

.tw-bg-yellow-700\/30 {
  background-color: rgb(161 98 7 / 0.3) !important;
}

.tw-bg-yellow-700\/35 {
  background-color: rgb(161 98 7 / 0.35) !important;
}

.tw-bg-yellow-700\/40 {
  background-color: rgb(161 98 7 / 0.4) !important;
}

.tw-bg-yellow-700\/45 {
  background-color: rgb(161 98 7 / 0.45) !important;
}

.tw-bg-yellow-700\/5 {
  background-color: rgb(161 98 7 / 0.05) !important;
}

.tw-bg-yellow-700\/50 {
  background-color: rgb(161 98 7 / 0.5) !important;
}

.tw-bg-yellow-700\/55 {
  background-color: rgb(161 98 7 / 0.55) !important;
}

.tw-bg-yellow-700\/60 {
  background-color: rgb(161 98 7 / 0.6) !important;
}

.tw-bg-yellow-700\/65 {
  background-color: rgb(161 98 7 / 0.65) !important;
}

.tw-bg-yellow-700\/70 {
  background-color: rgb(161 98 7 / 0.7) !important;
}

.tw-bg-yellow-700\/75 {
  background-color: rgb(161 98 7 / 0.75) !important;
}

.tw-bg-yellow-700\/80 {
  background-color: rgb(161 98 7 / 0.8) !important;
}

.tw-bg-yellow-700\/85 {
  background-color: rgb(161 98 7 / 0.85) !important;
}

.tw-bg-yellow-700\/90 {
  background-color: rgb(161 98 7 / 0.9) !important;
}

.tw-bg-yellow-700\/95 {
  background-color: rgb(161 98 7 / 0.95) !important;
}

.tw-bg-yellow-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(133 77 14 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-800\/0 {
  background-color: rgb(133 77 14 / 0) !important;
}

.tw-bg-yellow-800\/10 {
  background-color: rgb(133 77 14 / 0.1) !important;
}

.tw-bg-yellow-800\/100 {
  background-color: rgb(133 77 14 / 1) !important;
}

.tw-bg-yellow-800\/15 {
  background-color: rgb(133 77 14 / 0.15) !important;
}

.tw-bg-yellow-800\/20 {
  background-color: rgb(133 77 14 / 0.2) !important;
}

.tw-bg-yellow-800\/25 {
  background-color: rgb(133 77 14 / 0.25) !important;
}

.tw-bg-yellow-800\/30 {
  background-color: rgb(133 77 14 / 0.3) !important;
}

.tw-bg-yellow-800\/35 {
  background-color: rgb(133 77 14 / 0.35) !important;
}

.tw-bg-yellow-800\/40 {
  background-color: rgb(133 77 14 / 0.4) !important;
}

.tw-bg-yellow-800\/45 {
  background-color: rgb(133 77 14 / 0.45) !important;
}

.tw-bg-yellow-800\/5 {
  background-color: rgb(133 77 14 / 0.05) !important;
}

.tw-bg-yellow-800\/50 {
  background-color: rgb(133 77 14 / 0.5) !important;
}

.tw-bg-yellow-800\/55 {
  background-color: rgb(133 77 14 / 0.55) !important;
}

.tw-bg-yellow-800\/60 {
  background-color: rgb(133 77 14 / 0.6) !important;
}

.tw-bg-yellow-800\/65 {
  background-color: rgb(133 77 14 / 0.65) !important;
}

.tw-bg-yellow-800\/70 {
  background-color: rgb(133 77 14 / 0.7) !important;
}

.tw-bg-yellow-800\/75 {
  background-color: rgb(133 77 14 / 0.75) !important;
}

.tw-bg-yellow-800\/80 {
  background-color: rgb(133 77 14 / 0.8) !important;
}

.tw-bg-yellow-800\/85 {
  background-color: rgb(133 77 14 / 0.85) !important;
}

.tw-bg-yellow-800\/90 {
  background-color: rgb(133 77 14 / 0.9) !important;
}

.tw-bg-yellow-800\/95 {
  background-color: rgb(133 77 14 / 0.95) !important;
}

.tw-bg-yellow-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(113 63 18 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-900\/0 {
  background-color: rgb(113 63 18 / 0) !important;
}

.tw-bg-yellow-900\/10 {
  background-color: rgb(113 63 18 / 0.1) !important;
}

.tw-bg-yellow-900\/100 {
  background-color: rgb(113 63 18 / 1) !important;
}

.tw-bg-yellow-900\/15 {
  background-color: rgb(113 63 18 / 0.15) !important;
}

.tw-bg-yellow-900\/20 {
  background-color: rgb(113 63 18 / 0.2) !important;
}

.tw-bg-yellow-900\/25 {
  background-color: rgb(113 63 18 / 0.25) !important;
}

.tw-bg-yellow-900\/30 {
  background-color: rgb(113 63 18 / 0.3) !important;
}

.tw-bg-yellow-900\/35 {
  background-color: rgb(113 63 18 / 0.35) !important;
}

.tw-bg-yellow-900\/40 {
  background-color: rgb(113 63 18 / 0.4) !important;
}

.tw-bg-yellow-900\/45 {
  background-color: rgb(113 63 18 / 0.45) !important;
}

.tw-bg-yellow-900\/5 {
  background-color: rgb(113 63 18 / 0.05) !important;
}

.tw-bg-yellow-900\/50 {
  background-color: rgb(113 63 18 / 0.5) !important;
}

.tw-bg-yellow-900\/55 {
  background-color: rgb(113 63 18 / 0.55) !important;
}

.tw-bg-yellow-900\/60 {
  background-color: rgb(113 63 18 / 0.6) !important;
}

.tw-bg-yellow-900\/65 {
  background-color: rgb(113 63 18 / 0.65) !important;
}

.tw-bg-yellow-900\/70 {
  background-color: rgb(113 63 18 / 0.7) !important;
}

.tw-bg-yellow-900\/75 {
  background-color: rgb(113 63 18 / 0.75) !important;
}

.tw-bg-yellow-900\/80 {
  background-color: rgb(113 63 18 / 0.8) !important;
}

.tw-bg-yellow-900\/85 {
  background-color: rgb(113 63 18 / 0.85) !important;
}

.tw-bg-yellow-900\/90 {
  background-color: rgb(113 63 18 / 0.9) !important;
}

.tw-bg-yellow-900\/95 {
  background-color: rgb(113 63 18 / 0.95) !important;
}

.tw-bg-yellow-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(66 32 6 / var(--tw-bg-opacity)) !important;
}

.tw-bg-yellow-950\/0 {
  background-color: rgb(66 32 6 / 0) !important;
}

.tw-bg-yellow-950\/10 {
  background-color: rgb(66 32 6 / 0.1) !important;
}

.tw-bg-yellow-950\/100 {
  background-color: rgb(66 32 6 / 1) !important;
}

.tw-bg-yellow-950\/15 {
  background-color: rgb(66 32 6 / 0.15) !important;
}

.tw-bg-yellow-950\/20 {
  background-color: rgb(66 32 6 / 0.2) !important;
}

.tw-bg-yellow-950\/25 {
  background-color: rgb(66 32 6 / 0.25) !important;
}

.tw-bg-yellow-950\/30 {
  background-color: rgb(66 32 6 / 0.3) !important;
}

.tw-bg-yellow-950\/35 {
  background-color: rgb(66 32 6 / 0.35) !important;
}

.tw-bg-yellow-950\/40 {
  background-color: rgb(66 32 6 / 0.4) !important;
}

.tw-bg-yellow-950\/45 {
  background-color: rgb(66 32 6 / 0.45) !important;
}

.tw-bg-yellow-950\/5 {
  background-color: rgb(66 32 6 / 0.05) !important;
}

.tw-bg-yellow-950\/50 {
  background-color: rgb(66 32 6 / 0.5) !important;
}

.tw-bg-yellow-950\/55 {
  background-color: rgb(66 32 6 / 0.55) !important;
}

.tw-bg-yellow-950\/60 {
  background-color: rgb(66 32 6 / 0.6) !important;
}

.tw-bg-yellow-950\/65 {
  background-color: rgb(66 32 6 / 0.65) !important;
}

.tw-bg-yellow-950\/70 {
  background-color: rgb(66 32 6 / 0.7) !important;
}

.tw-bg-yellow-950\/75 {
  background-color: rgb(66 32 6 / 0.75) !important;
}

.tw-bg-yellow-950\/80 {
  background-color: rgb(66 32 6 / 0.8) !important;
}

.tw-bg-yellow-950\/85 {
  background-color: rgb(66 32 6 / 0.85) !important;
}

.tw-bg-yellow-950\/90 {
  background-color: rgb(66 32 6 / 0.9) !important;
}

.tw-bg-yellow-950\/95 {
  background-color: rgb(66 32 6 / 0.95) !important;
}

.tw-bg-zinc-100 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(244 244 245 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-100\/0 {
  background-color: rgb(244 244 245 / 0) !important;
}

.tw-bg-zinc-100\/10 {
  background-color: rgb(244 244 245 / 0.1) !important;
}

.tw-bg-zinc-100\/100 {
  background-color: rgb(244 244 245 / 1) !important;
}

.tw-bg-zinc-100\/15 {
  background-color: rgb(244 244 245 / 0.15) !important;
}

.tw-bg-zinc-100\/20 {
  background-color: rgb(244 244 245 / 0.2) !important;
}

.tw-bg-zinc-100\/25 {
  background-color: rgb(244 244 245 / 0.25) !important;
}

.tw-bg-zinc-100\/30 {
  background-color: rgb(244 244 245 / 0.3) !important;
}

.tw-bg-zinc-100\/35 {
  background-color: rgb(244 244 245 / 0.35) !important;
}

.tw-bg-zinc-100\/40 {
  background-color: rgb(244 244 245 / 0.4) !important;
}

.tw-bg-zinc-100\/45 {
  background-color: rgb(244 244 245 / 0.45) !important;
}

.tw-bg-zinc-100\/5 {
  background-color: rgb(244 244 245 / 0.05) !important;
}

.tw-bg-zinc-100\/50 {
  background-color: rgb(244 244 245 / 0.5) !important;
}

.tw-bg-zinc-100\/55 {
  background-color: rgb(244 244 245 / 0.55) !important;
}

.tw-bg-zinc-100\/60 {
  background-color: rgb(244 244 245 / 0.6) !important;
}

.tw-bg-zinc-100\/65 {
  background-color: rgb(244 244 245 / 0.65) !important;
}

.tw-bg-zinc-100\/70 {
  background-color: rgb(244 244 245 / 0.7) !important;
}

.tw-bg-zinc-100\/75 {
  background-color: rgb(244 244 245 / 0.75) !important;
}

.tw-bg-zinc-100\/80 {
  background-color: rgb(244 244 245 / 0.8) !important;
}

.tw-bg-zinc-100\/85 {
  background-color: rgb(244 244 245 / 0.85) !important;
}

.tw-bg-zinc-100\/90 {
  background-color: rgb(244 244 245 / 0.9) !important;
}

.tw-bg-zinc-100\/95 {
  background-color: rgb(244 244 245 / 0.95) !important;
}

.tw-bg-zinc-200 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(228 228 231 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-200\/0 {
  background-color: rgb(228 228 231 / 0) !important;
}

.tw-bg-zinc-200\/10 {
  background-color: rgb(228 228 231 / 0.1) !important;
}

.tw-bg-zinc-200\/100 {
  background-color: rgb(228 228 231 / 1) !important;
}

.tw-bg-zinc-200\/15 {
  background-color: rgb(228 228 231 / 0.15) !important;
}

.tw-bg-zinc-200\/20 {
  background-color: rgb(228 228 231 / 0.2) !important;
}

.tw-bg-zinc-200\/25 {
  background-color: rgb(228 228 231 / 0.25) !important;
}

.tw-bg-zinc-200\/30 {
  background-color: rgb(228 228 231 / 0.3) !important;
}

.tw-bg-zinc-200\/35 {
  background-color: rgb(228 228 231 / 0.35) !important;
}

.tw-bg-zinc-200\/40 {
  background-color: rgb(228 228 231 / 0.4) !important;
}

.tw-bg-zinc-200\/45 {
  background-color: rgb(228 228 231 / 0.45) !important;
}

.tw-bg-zinc-200\/5 {
  background-color: rgb(228 228 231 / 0.05) !important;
}

.tw-bg-zinc-200\/50 {
  background-color: rgb(228 228 231 / 0.5) !important;
}

.tw-bg-zinc-200\/55 {
  background-color: rgb(228 228 231 / 0.55) !important;
}

.tw-bg-zinc-200\/60 {
  background-color: rgb(228 228 231 / 0.6) !important;
}

.tw-bg-zinc-200\/65 {
  background-color: rgb(228 228 231 / 0.65) !important;
}

.tw-bg-zinc-200\/70 {
  background-color: rgb(228 228 231 / 0.7) !important;
}

.tw-bg-zinc-200\/75 {
  background-color: rgb(228 228 231 / 0.75) !important;
}

.tw-bg-zinc-200\/80 {
  background-color: rgb(228 228 231 / 0.8) !important;
}

.tw-bg-zinc-200\/85 {
  background-color: rgb(228 228 231 / 0.85) !important;
}

.tw-bg-zinc-200\/90 {
  background-color: rgb(228 228 231 / 0.9) !important;
}

.tw-bg-zinc-200\/95 {
  background-color: rgb(228 228 231 / 0.95) !important;
}

.tw-bg-zinc-300 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(212 212 216 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-300\/0 {
  background-color: rgb(212 212 216 / 0) !important;
}

.tw-bg-zinc-300\/10 {
  background-color: rgb(212 212 216 / 0.1) !important;
}

.tw-bg-zinc-300\/100 {
  background-color: rgb(212 212 216 / 1) !important;
}

.tw-bg-zinc-300\/15 {
  background-color: rgb(212 212 216 / 0.15) !important;
}

.tw-bg-zinc-300\/20 {
  background-color: rgb(212 212 216 / 0.2) !important;
}

.tw-bg-zinc-300\/25 {
  background-color: rgb(212 212 216 / 0.25) !important;
}

.tw-bg-zinc-300\/30 {
  background-color: rgb(212 212 216 / 0.3) !important;
}

.tw-bg-zinc-300\/35 {
  background-color: rgb(212 212 216 / 0.35) !important;
}

.tw-bg-zinc-300\/40 {
  background-color: rgb(212 212 216 / 0.4) !important;
}

.tw-bg-zinc-300\/45 {
  background-color: rgb(212 212 216 / 0.45) !important;
}

.tw-bg-zinc-300\/5 {
  background-color: rgb(212 212 216 / 0.05) !important;
}

.tw-bg-zinc-300\/50 {
  background-color: rgb(212 212 216 / 0.5) !important;
}

.tw-bg-zinc-300\/55 {
  background-color: rgb(212 212 216 / 0.55) !important;
}

.tw-bg-zinc-300\/60 {
  background-color: rgb(212 212 216 / 0.6) !important;
}

.tw-bg-zinc-300\/65 {
  background-color: rgb(212 212 216 / 0.65) !important;
}

.tw-bg-zinc-300\/70 {
  background-color: rgb(212 212 216 / 0.7) !important;
}

.tw-bg-zinc-300\/75 {
  background-color: rgb(212 212 216 / 0.75) !important;
}

.tw-bg-zinc-300\/80 {
  background-color: rgb(212 212 216 / 0.8) !important;
}

.tw-bg-zinc-300\/85 {
  background-color: rgb(212 212 216 / 0.85) !important;
}

.tw-bg-zinc-300\/90 {
  background-color: rgb(212 212 216 / 0.9) !important;
}

.tw-bg-zinc-300\/95 {
  background-color: rgb(212 212 216 / 0.95) !important;
}

.tw-bg-zinc-400 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(161 161 170 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-400\/0 {
  background-color: rgb(161 161 170 / 0) !important;
}

.tw-bg-zinc-400\/10 {
  background-color: rgb(161 161 170 / 0.1) !important;
}

.tw-bg-zinc-400\/100 {
  background-color: rgb(161 161 170 / 1) !important;
}

.tw-bg-zinc-400\/15 {
  background-color: rgb(161 161 170 / 0.15) !important;
}

.tw-bg-zinc-400\/20 {
  background-color: rgb(161 161 170 / 0.2) !important;
}

.tw-bg-zinc-400\/25 {
  background-color: rgb(161 161 170 / 0.25) !important;
}

.tw-bg-zinc-400\/30 {
  background-color: rgb(161 161 170 / 0.3) !important;
}

.tw-bg-zinc-400\/35 {
  background-color: rgb(161 161 170 / 0.35) !important;
}

.tw-bg-zinc-400\/40 {
  background-color: rgb(161 161 170 / 0.4) !important;
}

.tw-bg-zinc-400\/45 {
  background-color: rgb(161 161 170 / 0.45) !important;
}

.tw-bg-zinc-400\/5 {
  background-color: rgb(161 161 170 / 0.05) !important;
}

.tw-bg-zinc-400\/50 {
  background-color: rgb(161 161 170 / 0.5) !important;
}

.tw-bg-zinc-400\/55 {
  background-color: rgb(161 161 170 / 0.55) !important;
}

.tw-bg-zinc-400\/60 {
  background-color: rgb(161 161 170 / 0.6) !important;
}

.tw-bg-zinc-400\/65 {
  background-color: rgb(161 161 170 / 0.65) !important;
}

.tw-bg-zinc-400\/70 {
  background-color: rgb(161 161 170 / 0.7) !important;
}

.tw-bg-zinc-400\/75 {
  background-color: rgb(161 161 170 / 0.75) !important;
}

.tw-bg-zinc-400\/80 {
  background-color: rgb(161 161 170 / 0.8) !important;
}

.tw-bg-zinc-400\/85 {
  background-color: rgb(161 161 170 / 0.85) !important;
}

.tw-bg-zinc-400\/90 {
  background-color: rgb(161 161 170 / 0.9) !important;
}

.tw-bg-zinc-400\/95 {
  background-color: rgb(161 161 170 / 0.95) !important;
}

.tw-bg-zinc-50 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(250 250 250 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-50\/0 {
  background-color: rgb(250 250 250 / 0) !important;
}

.tw-bg-zinc-50\/10 {
  background-color: rgb(250 250 250 / 0.1) !important;
}

.tw-bg-zinc-50\/100 {
  background-color: rgb(250 250 250 / 1) !important;
}

.tw-bg-zinc-50\/15 {
  background-color: rgb(250 250 250 / 0.15) !important;
}

.tw-bg-zinc-50\/20 {
  background-color: rgb(250 250 250 / 0.2) !important;
}

.tw-bg-zinc-50\/25 {
  background-color: rgb(250 250 250 / 0.25) !important;
}

.tw-bg-zinc-50\/30 {
  background-color: rgb(250 250 250 / 0.3) !important;
}

.tw-bg-zinc-50\/35 {
  background-color: rgb(250 250 250 / 0.35) !important;
}

.tw-bg-zinc-50\/40 {
  background-color: rgb(250 250 250 / 0.4) !important;
}

.tw-bg-zinc-50\/45 {
  background-color: rgb(250 250 250 / 0.45) !important;
}

.tw-bg-zinc-50\/5 {
  background-color: rgb(250 250 250 / 0.05) !important;
}

.tw-bg-zinc-50\/50 {
  background-color: rgb(250 250 250 / 0.5) !important;
}

.tw-bg-zinc-50\/55 {
  background-color: rgb(250 250 250 / 0.55) !important;
}

.tw-bg-zinc-50\/60 {
  background-color: rgb(250 250 250 / 0.6) !important;
}

.tw-bg-zinc-50\/65 {
  background-color: rgb(250 250 250 / 0.65) !important;
}

.tw-bg-zinc-50\/70 {
  background-color: rgb(250 250 250 / 0.7) !important;
}

.tw-bg-zinc-50\/75 {
  background-color: rgb(250 250 250 / 0.75) !important;
}

.tw-bg-zinc-50\/80 {
  background-color: rgb(250 250 250 / 0.8) !important;
}

.tw-bg-zinc-50\/85 {
  background-color: rgb(250 250 250 / 0.85) !important;
}

.tw-bg-zinc-50\/90 {
  background-color: rgb(250 250 250 / 0.9) !important;
}

.tw-bg-zinc-50\/95 {
  background-color: rgb(250 250 250 / 0.95) !important;
}

.tw-bg-zinc-500 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(113 113 122 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-500\/0 {
  background-color: rgb(113 113 122 / 0) !important;
}

.tw-bg-zinc-500\/10 {
  background-color: rgb(113 113 122 / 0.1) !important;
}

.tw-bg-zinc-500\/100 {
  background-color: rgb(113 113 122 / 1) !important;
}

.tw-bg-zinc-500\/15 {
  background-color: rgb(113 113 122 / 0.15) !important;
}

.tw-bg-zinc-500\/20 {
  background-color: rgb(113 113 122 / 0.2) !important;
}

.tw-bg-zinc-500\/25 {
  background-color: rgb(113 113 122 / 0.25) !important;
}

.tw-bg-zinc-500\/30 {
  background-color: rgb(113 113 122 / 0.3) !important;
}

.tw-bg-zinc-500\/35 {
  background-color: rgb(113 113 122 / 0.35) !important;
}

.tw-bg-zinc-500\/40 {
  background-color: rgb(113 113 122 / 0.4) !important;
}

.tw-bg-zinc-500\/45 {
  background-color: rgb(113 113 122 / 0.45) !important;
}

.tw-bg-zinc-500\/5 {
  background-color: rgb(113 113 122 / 0.05) !important;
}

.tw-bg-zinc-500\/50 {
  background-color: rgb(113 113 122 / 0.5) !important;
}

.tw-bg-zinc-500\/55 {
  background-color: rgb(113 113 122 / 0.55) !important;
}

.tw-bg-zinc-500\/60 {
  background-color: rgb(113 113 122 / 0.6) !important;
}

.tw-bg-zinc-500\/65 {
  background-color: rgb(113 113 122 / 0.65) !important;
}

.tw-bg-zinc-500\/70 {
  background-color: rgb(113 113 122 / 0.7) !important;
}

.tw-bg-zinc-500\/75 {
  background-color: rgb(113 113 122 / 0.75) !important;
}

.tw-bg-zinc-500\/80 {
  background-color: rgb(113 113 122 / 0.8) !important;
}

.tw-bg-zinc-500\/85 {
  background-color: rgb(113 113 122 / 0.85) !important;
}

.tw-bg-zinc-500\/90 {
  background-color: rgb(113 113 122 / 0.9) !important;
}

.tw-bg-zinc-500\/95 {
  background-color: rgb(113 113 122 / 0.95) !important;
}

.tw-bg-zinc-600 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(82 82 91 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-600\/0 {
  background-color: rgb(82 82 91 / 0) !important;
}

.tw-bg-zinc-600\/10 {
  background-color: rgb(82 82 91 / 0.1) !important;
}

.tw-bg-zinc-600\/100 {
  background-color: rgb(82 82 91 / 1) !important;
}

.tw-bg-zinc-600\/15 {
  background-color: rgb(82 82 91 / 0.15) !important;
}

.tw-bg-zinc-600\/20 {
  background-color: rgb(82 82 91 / 0.2) !important;
}

.tw-bg-zinc-600\/25 {
  background-color: rgb(82 82 91 / 0.25) !important;
}

.tw-bg-zinc-600\/30 {
  background-color: rgb(82 82 91 / 0.3) !important;
}

.tw-bg-zinc-600\/35 {
  background-color: rgb(82 82 91 / 0.35) !important;
}

.tw-bg-zinc-600\/40 {
  background-color: rgb(82 82 91 / 0.4) !important;
}

.tw-bg-zinc-600\/45 {
  background-color: rgb(82 82 91 / 0.45) !important;
}

.tw-bg-zinc-600\/5 {
  background-color: rgb(82 82 91 / 0.05) !important;
}

.tw-bg-zinc-600\/50 {
  background-color: rgb(82 82 91 / 0.5) !important;
}

.tw-bg-zinc-600\/55 {
  background-color: rgb(82 82 91 / 0.55) !important;
}

.tw-bg-zinc-600\/60 {
  background-color: rgb(82 82 91 / 0.6) !important;
}

.tw-bg-zinc-600\/65 {
  background-color: rgb(82 82 91 / 0.65) !important;
}

.tw-bg-zinc-600\/70 {
  background-color: rgb(82 82 91 / 0.7) !important;
}

.tw-bg-zinc-600\/75 {
  background-color: rgb(82 82 91 / 0.75) !important;
}

.tw-bg-zinc-600\/80 {
  background-color: rgb(82 82 91 / 0.8) !important;
}

.tw-bg-zinc-600\/85 {
  background-color: rgb(82 82 91 / 0.85) !important;
}

.tw-bg-zinc-600\/90 {
  background-color: rgb(82 82 91 / 0.9) !important;
}

.tw-bg-zinc-600\/95 {
  background-color: rgb(82 82 91 / 0.95) !important;
}

.tw-bg-zinc-700 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(63 63 70 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-700\/0 {
  background-color: rgb(63 63 70 / 0) !important;
}

.tw-bg-zinc-700\/10 {
  background-color: rgb(63 63 70 / 0.1) !important;
}

.tw-bg-zinc-700\/100 {
  background-color: rgb(63 63 70 / 1) !important;
}

.tw-bg-zinc-700\/15 {
  background-color: rgb(63 63 70 / 0.15) !important;
}

.tw-bg-zinc-700\/20 {
  background-color: rgb(63 63 70 / 0.2) !important;
}

.tw-bg-zinc-700\/25 {
  background-color: rgb(63 63 70 / 0.25) !important;
}

.tw-bg-zinc-700\/30 {
  background-color: rgb(63 63 70 / 0.3) !important;
}

.tw-bg-zinc-700\/35 {
  background-color: rgb(63 63 70 / 0.35) !important;
}

.tw-bg-zinc-700\/40 {
  background-color: rgb(63 63 70 / 0.4) !important;
}

.tw-bg-zinc-700\/45 {
  background-color: rgb(63 63 70 / 0.45) !important;
}

.tw-bg-zinc-700\/5 {
  background-color: rgb(63 63 70 / 0.05) !important;
}

.tw-bg-zinc-700\/50 {
  background-color: rgb(63 63 70 / 0.5) !important;
}

.tw-bg-zinc-700\/55 {
  background-color: rgb(63 63 70 / 0.55) !important;
}

.tw-bg-zinc-700\/60 {
  background-color: rgb(63 63 70 / 0.6) !important;
}

.tw-bg-zinc-700\/65 {
  background-color: rgb(63 63 70 / 0.65) !important;
}

.tw-bg-zinc-700\/70 {
  background-color: rgb(63 63 70 / 0.7) !important;
}

.tw-bg-zinc-700\/75 {
  background-color: rgb(63 63 70 / 0.75) !important;
}

.tw-bg-zinc-700\/80 {
  background-color: rgb(63 63 70 / 0.8) !important;
}

.tw-bg-zinc-700\/85 {
  background-color: rgb(63 63 70 / 0.85) !important;
}

.tw-bg-zinc-700\/90 {
  background-color: rgb(63 63 70 / 0.9) !important;
}

.tw-bg-zinc-700\/95 {
  background-color: rgb(63 63 70 / 0.95) !important;
}

.tw-bg-zinc-800 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(39 39 42 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-800\/0 {
  background-color: rgb(39 39 42 / 0) !important;
}

.tw-bg-zinc-800\/10 {
  background-color: rgb(39 39 42 / 0.1) !important;
}

.tw-bg-zinc-800\/100 {
  background-color: rgb(39 39 42 / 1) !important;
}

.tw-bg-zinc-800\/15 {
  background-color: rgb(39 39 42 / 0.15) !important;
}

.tw-bg-zinc-800\/20 {
  background-color: rgb(39 39 42 / 0.2) !important;
}

.tw-bg-zinc-800\/25 {
  background-color: rgb(39 39 42 / 0.25) !important;
}

.tw-bg-zinc-800\/30 {
  background-color: rgb(39 39 42 / 0.3) !important;
}

.tw-bg-zinc-800\/35 {
  background-color: rgb(39 39 42 / 0.35) !important;
}

.tw-bg-zinc-800\/40 {
  background-color: rgb(39 39 42 / 0.4) !important;
}

.tw-bg-zinc-800\/45 {
  background-color: rgb(39 39 42 / 0.45) !important;
}

.tw-bg-zinc-800\/5 {
  background-color: rgb(39 39 42 / 0.05) !important;
}

.tw-bg-zinc-800\/50 {
  background-color: rgb(39 39 42 / 0.5) !important;
}

.tw-bg-zinc-800\/55 {
  background-color: rgb(39 39 42 / 0.55) !important;
}

.tw-bg-zinc-800\/60 {
  background-color: rgb(39 39 42 / 0.6) !important;
}

.tw-bg-zinc-800\/65 {
  background-color: rgb(39 39 42 / 0.65) !important;
}

.tw-bg-zinc-800\/70 {
  background-color: rgb(39 39 42 / 0.7) !important;
}

.tw-bg-zinc-800\/75 {
  background-color: rgb(39 39 42 / 0.75) !important;
}

.tw-bg-zinc-800\/80 {
  background-color: rgb(39 39 42 / 0.8) !important;
}

.tw-bg-zinc-800\/85 {
  background-color: rgb(39 39 42 / 0.85) !important;
}

.tw-bg-zinc-800\/90 {
  background-color: rgb(39 39 42 / 0.9) !important;
}

.tw-bg-zinc-800\/95 {
  background-color: rgb(39 39 42 / 0.95) !important;
}

.tw-bg-zinc-900 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(24 24 27 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-900\/0 {
  background-color: rgb(24 24 27 / 0) !important;
}

.tw-bg-zinc-900\/10 {
  background-color: rgb(24 24 27 / 0.1) !important;
}

.tw-bg-zinc-900\/100 {
  background-color: rgb(24 24 27 / 1) !important;
}

.tw-bg-zinc-900\/15 {
  background-color: rgb(24 24 27 / 0.15) !important;
}

.tw-bg-zinc-900\/20 {
  background-color: rgb(24 24 27 / 0.2) !important;
}

.tw-bg-zinc-900\/25 {
  background-color: rgb(24 24 27 / 0.25) !important;
}

.tw-bg-zinc-900\/30 {
  background-color: rgb(24 24 27 / 0.3) !important;
}

.tw-bg-zinc-900\/35 {
  background-color: rgb(24 24 27 / 0.35) !important;
}

.tw-bg-zinc-900\/40 {
  background-color: rgb(24 24 27 / 0.4) !important;
}

.tw-bg-zinc-900\/45 {
  background-color: rgb(24 24 27 / 0.45) !important;
}

.tw-bg-zinc-900\/5 {
  background-color: rgb(24 24 27 / 0.05) !important;
}

.tw-bg-zinc-900\/50 {
  background-color: rgb(24 24 27 / 0.5) !important;
}

.tw-bg-zinc-900\/55 {
  background-color: rgb(24 24 27 / 0.55) !important;
}

.tw-bg-zinc-900\/60 {
  background-color: rgb(24 24 27 / 0.6) !important;
}

.tw-bg-zinc-900\/65 {
  background-color: rgb(24 24 27 / 0.65) !important;
}

.tw-bg-zinc-900\/70 {
  background-color: rgb(24 24 27 / 0.7) !important;
}

.tw-bg-zinc-900\/75 {
  background-color: rgb(24 24 27 / 0.75) !important;
}

.tw-bg-zinc-900\/80 {
  background-color: rgb(24 24 27 / 0.8) !important;
}

.tw-bg-zinc-900\/85 {
  background-color: rgb(24 24 27 / 0.85) !important;
}

.tw-bg-zinc-900\/90 {
  background-color: rgb(24 24 27 / 0.9) !important;
}

.tw-bg-zinc-900\/95 {
  background-color: rgb(24 24 27 / 0.95) !important;
}

.tw-bg-zinc-950 {
  --tw-bg-opacity: 1 !important;
  background-color: rgb(9 9 11 / var(--tw-bg-opacity)) !important;
}

.tw-bg-zinc-950\/0 {
  background-color: rgb(9 9 11 / 0) !important;
}

.tw-bg-zinc-950\/10 {
  background-color: rgb(9 9 11 / 0.1) !important;
}

.tw-bg-zinc-950\/100 {
  background-color: rgb(9 9 11 / 1) !important;
}

.tw-bg-zinc-950\/15 {
  background-color: rgb(9 9 11 / 0.15) !important;
}

.tw-bg-zinc-950\/20 {
  background-color: rgb(9 9 11 / 0.2) !important;
}

.tw-bg-zinc-950\/25 {
  background-color: rgb(9 9 11 / 0.25) !important;
}

.tw-bg-zinc-950\/30 {
  background-color: rgb(9 9 11 / 0.3) !important;
}

.tw-bg-zinc-950\/35 {
  background-color: rgb(9 9 11 / 0.35) !important;
}

.tw-bg-zinc-950\/40 {
  background-color: rgb(9 9 11 / 0.4) !important;
}

.tw-bg-zinc-950\/45 {
  background-color: rgb(9 9 11 / 0.45) !important;
}

.tw-bg-zinc-950\/5 {
  background-color: rgb(9 9 11 / 0.05) !important;
}

.tw-bg-zinc-950\/50 {
  background-color: rgb(9 9 11 / 0.5) !important;
}

.tw-bg-zinc-950\/55 {
  background-color: rgb(9 9 11 / 0.55) !important;
}

.tw-bg-zinc-950\/60 {
  background-color: rgb(9 9 11 / 0.6) !important;
}

.tw-bg-zinc-950\/65 {
  background-color: rgb(9 9 11 / 0.65) !important;
}

.tw-bg-zinc-950\/70 {
  background-color: rgb(9 9 11 / 0.7) !important;
}

.tw-bg-zinc-950\/75 {
  background-color: rgb(9 9 11 / 0.75) !important;
}

.tw-bg-zinc-950\/80 {
  background-color: rgb(9 9 11 / 0.8) !important;
}

.tw-bg-zinc-950\/85 {
  background-color: rgb(9 9 11 / 0.85) !important;
}

.tw-bg-zinc-950\/90 {
  background-color: rgb(9 9 11 / 0.9) !important;
}

.tw-bg-zinc-950\/95 {
  background-color: rgb(9 9 11 / 0.95) !important;
}

.tw-bg-opacity-0 {
  --tw-bg-opacity: 0 !important;
}

.tw-bg-opacity-10 {
  --tw-bg-opacity: 0.1 !important;
}

.tw-bg-opacity-100 {
  --tw-bg-opacity: 1 !important;
}

.tw-bg-opacity-15 {
  --tw-bg-opacity: 0.15 !important;
}

.tw-bg-opacity-20 {
  --tw-bg-opacity: 0.2 !important;
}

.tw-bg-opacity-25 {
  --tw-bg-opacity: 0.25 !important;
}

.tw-bg-opacity-30 {
  --tw-bg-opacity: 0.3 !important;
}

.tw-bg-opacity-35 {
  --tw-bg-opacity: 0.35 !important;
}

.tw-bg-opacity-40 {
  --tw-bg-opacity: 0.4 !important;
}

.tw-bg-opacity-45 {
  --tw-bg-opacity: 0.45 !important;
}

.tw-bg-opacity-5 {
  --tw-bg-opacity: 0.05 !important;
}

.tw-bg-opacity-50 {
  --tw-bg-opacity: 0.5 !important;
}

.tw-bg-opacity-55 {
  --tw-bg-opacity: 0.55 !important;
}

.tw-bg-opacity-60 {
  --tw-bg-opacity: 0.6 !important;
}

.tw-bg-opacity-65 {
  --tw-bg-opacity: 0.65 !important;
}

.tw-bg-opacity-70 {
  --tw-bg-opacity: 0.7 !important;
}

.tw-bg-opacity-75 {
  --tw-bg-opacity: 0.75 !important;
}

.tw-bg-opacity-80 {
  --tw-bg-opacity: 0.8 !important;
}

.tw-bg-opacity-85 {
  --tw-bg-opacity: 0.85 !important;
}

.tw-bg-opacity-90 {
  --tw-bg-opacity: 0.9 !important;
}

.tw-bg-opacity-95 {
  --tw-bg-opacity: 0.95 !important;
}

.tw-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-bl {
  background-image: linear-gradient(to bottom left, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-br {
  background-image: linear-gradient(to bottom right, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-l {
  background-image: linear-gradient(to left, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-r {
  background-image: linear-gradient(to right, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-t {
  background-image: linear-gradient(to top, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-tl {
  background-image: linear-gradient(to top left, var(--tw-gradient-stops)) !important;
}

.tw-bg-gradient-to-tr {
  background-image: linear-gradient(to top right, var(--tw-gradient-stops)) !important;
}

.tw-bg-none {
  background-image: none !important;
}

.tw-from-amber-100 {
  --tw-gradient-from: #fef3c7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/0 {
  --tw-gradient-from: rgb(254 243 199 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/10 {
  --tw-gradient-from: rgb(254 243 199 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/100 {
  --tw-gradient-from: rgb(254 243 199 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/15 {
  --tw-gradient-from: rgb(254 243 199 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/20 {
  --tw-gradient-from: rgb(254 243 199 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/25 {
  --tw-gradient-from: rgb(254 243 199 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/30 {
  --tw-gradient-from: rgb(254 243 199 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/35 {
  --tw-gradient-from: rgb(254 243 199 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/40 {
  --tw-gradient-from: rgb(254 243 199 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/45 {
  --tw-gradient-from: rgb(254 243 199 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/5 {
  --tw-gradient-from: rgb(254 243 199 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/50 {
  --tw-gradient-from: rgb(254 243 199 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/55 {
  --tw-gradient-from: rgb(254 243 199 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/60 {
  --tw-gradient-from: rgb(254 243 199 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/65 {
  --tw-gradient-from: rgb(254 243 199 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/70 {
  --tw-gradient-from: rgb(254 243 199 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/75 {
  --tw-gradient-from: rgb(254 243 199 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/80 {
  --tw-gradient-from: rgb(254 243 199 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/85 {
  --tw-gradient-from: rgb(254 243 199 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/90 {
  --tw-gradient-from: rgb(254 243 199 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-100\/95 {
  --tw-gradient-from: rgb(254 243 199 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200 {
  --tw-gradient-from: #fde68a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/0 {
  --tw-gradient-from: rgb(253 230 138 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/10 {
  --tw-gradient-from: rgb(253 230 138 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/100 {
  --tw-gradient-from: rgb(253 230 138 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/15 {
  --tw-gradient-from: rgb(253 230 138 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/20 {
  --tw-gradient-from: rgb(253 230 138 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/25 {
  --tw-gradient-from: rgb(253 230 138 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/30 {
  --tw-gradient-from: rgb(253 230 138 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/35 {
  --tw-gradient-from: rgb(253 230 138 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/40 {
  --tw-gradient-from: rgb(253 230 138 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/45 {
  --tw-gradient-from: rgb(253 230 138 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/5 {
  --tw-gradient-from: rgb(253 230 138 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/50 {
  --tw-gradient-from: rgb(253 230 138 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/55 {
  --tw-gradient-from: rgb(253 230 138 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/60 {
  --tw-gradient-from: rgb(253 230 138 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/65 {
  --tw-gradient-from: rgb(253 230 138 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/70 {
  --tw-gradient-from: rgb(253 230 138 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/75 {
  --tw-gradient-from: rgb(253 230 138 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/80 {
  --tw-gradient-from: rgb(253 230 138 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/85 {
  --tw-gradient-from: rgb(253 230 138 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/90 {
  --tw-gradient-from: rgb(253 230 138 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-200\/95 {
  --tw-gradient-from: rgb(253 230 138 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300 {
  --tw-gradient-from: #fcd34d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/0 {
  --tw-gradient-from: rgb(252 211 77 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/10 {
  --tw-gradient-from: rgb(252 211 77 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/100 {
  --tw-gradient-from: rgb(252 211 77 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/15 {
  --tw-gradient-from: rgb(252 211 77 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/20 {
  --tw-gradient-from: rgb(252 211 77 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/25 {
  --tw-gradient-from: rgb(252 211 77 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/30 {
  --tw-gradient-from: rgb(252 211 77 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/35 {
  --tw-gradient-from: rgb(252 211 77 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/40 {
  --tw-gradient-from: rgb(252 211 77 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/45 {
  --tw-gradient-from: rgb(252 211 77 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/5 {
  --tw-gradient-from: rgb(252 211 77 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/50 {
  --tw-gradient-from: rgb(252 211 77 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/55 {
  --tw-gradient-from: rgb(252 211 77 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/60 {
  --tw-gradient-from: rgb(252 211 77 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/65 {
  --tw-gradient-from: rgb(252 211 77 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/70 {
  --tw-gradient-from: rgb(252 211 77 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/75 {
  --tw-gradient-from: rgb(252 211 77 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/80 {
  --tw-gradient-from: rgb(252 211 77 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/85 {
  --tw-gradient-from: rgb(252 211 77 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/90 {
  --tw-gradient-from: rgb(252 211 77 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-300\/95 {
  --tw-gradient-from: rgb(252 211 77 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400 {
  --tw-gradient-from: #fbbf24 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/0 {
  --tw-gradient-from: rgb(251 191 36 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/10 {
  --tw-gradient-from: rgb(251 191 36 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/100 {
  --tw-gradient-from: rgb(251 191 36 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/15 {
  --tw-gradient-from: rgb(251 191 36 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/20 {
  --tw-gradient-from: rgb(251 191 36 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/25 {
  --tw-gradient-from: rgb(251 191 36 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/30 {
  --tw-gradient-from: rgb(251 191 36 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/35 {
  --tw-gradient-from: rgb(251 191 36 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/40 {
  --tw-gradient-from: rgb(251 191 36 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/45 {
  --tw-gradient-from: rgb(251 191 36 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/5 {
  --tw-gradient-from: rgb(251 191 36 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/50 {
  --tw-gradient-from: rgb(251 191 36 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/55 {
  --tw-gradient-from: rgb(251 191 36 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/60 {
  --tw-gradient-from: rgb(251 191 36 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/65 {
  --tw-gradient-from: rgb(251 191 36 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/70 {
  --tw-gradient-from: rgb(251 191 36 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/75 {
  --tw-gradient-from: rgb(251 191 36 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/80 {
  --tw-gradient-from: rgb(251 191 36 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/85 {
  --tw-gradient-from: rgb(251 191 36 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/90 {
  --tw-gradient-from: rgb(251 191 36 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-400\/95 {
  --tw-gradient-from: rgb(251 191 36 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50 {
  --tw-gradient-from: #fffbeb var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/0 {
  --tw-gradient-from: rgb(255 251 235 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/10 {
  --tw-gradient-from: rgb(255 251 235 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/100 {
  --tw-gradient-from: rgb(255 251 235 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/15 {
  --tw-gradient-from: rgb(255 251 235 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/20 {
  --tw-gradient-from: rgb(255 251 235 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/25 {
  --tw-gradient-from: rgb(255 251 235 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/30 {
  --tw-gradient-from: rgb(255 251 235 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/35 {
  --tw-gradient-from: rgb(255 251 235 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/40 {
  --tw-gradient-from: rgb(255 251 235 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/45 {
  --tw-gradient-from: rgb(255 251 235 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/5 {
  --tw-gradient-from: rgb(255 251 235 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/50 {
  --tw-gradient-from: rgb(255 251 235 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/55 {
  --tw-gradient-from: rgb(255 251 235 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/60 {
  --tw-gradient-from: rgb(255 251 235 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/65 {
  --tw-gradient-from: rgb(255 251 235 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/70 {
  --tw-gradient-from: rgb(255 251 235 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/75 {
  --tw-gradient-from: rgb(255 251 235 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/80 {
  --tw-gradient-from: rgb(255 251 235 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/85 {
  --tw-gradient-from: rgb(255 251 235 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/90 {
  --tw-gradient-from: rgb(255 251 235 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-50\/95 {
  --tw-gradient-from: rgb(255 251 235 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500 {
  --tw-gradient-from: #f59e0b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/0 {
  --tw-gradient-from: rgb(245 158 11 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/10 {
  --tw-gradient-from: rgb(245 158 11 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/100 {
  --tw-gradient-from: rgb(245 158 11 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/15 {
  --tw-gradient-from: rgb(245 158 11 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/20 {
  --tw-gradient-from: rgb(245 158 11 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/25 {
  --tw-gradient-from: rgb(245 158 11 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/30 {
  --tw-gradient-from: rgb(245 158 11 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/35 {
  --tw-gradient-from: rgb(245 158 11 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/40 {
  --tw-gradient-from: rgb(245 158 11 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/45 {
  --tw-gradient-from: rgb(245 158 11 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/5 {
  --tw-gradient-from: rgb(245 158 11 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/50 {
  --tw-gradient-from: rgb(245 158 11 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/55 {
  --tw-gradient-from: rgb(245 158 11 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/60 {
  --tw-gradient-from: rgb(245 158 11 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/65 {
  --tw-gradient-from: rgb(245 158 11 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/70 {
  --tw-gradient-from: rgb(245 158 11 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/75 {
  --tw-gradient-from: rgb(245 158 11 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/80 {
  --tw-gradient-from: rgb(245 158 11 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/85 {
  --tw-gradient-from: rgb(245 158 11 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/90 {
  --tw-gradient-from: rgb(245 158 11 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-500\/95 {
  --tw-gradient-from: rgb(245 158 11 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600 {
  --tw-gradient-from: #d97706 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/0 {
  --tw-gradient-from: rgb(217 119 6 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/10 {
  --tw-gradient-from: rgb(217 119 6 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/100 {
  --tw-gradient-from: rgb(217 119 6 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/15 {
  --tw-gradient-from: rgb(217 119 6 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/20 {
  --tw-gradient-from: rgb(217 119 6 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/25 {
  --tw-gradient-from: rgb(217 119 6 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/30 {
  --tw-gradient-from: rgb(217 119 6 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/35 {
  --tw-gradient-from: rgb(217 119 6 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/40 {
  --tw-gradient-from: rgb(217 119 6 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/45 {
  --tw-gradient-from: rgb(217 119 6 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/5 {
  --tw-gradient-from: rgb(217 119 6 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/50 {
  --tw-gradient-from: rgb(217 119 6 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/55 {
  --tw-gradient-from: rgb(217 119 6 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/60 {
  --tw-gradient-from: rgb(217 119 6 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/65 {
  --tw-gradient-from: rgb(217 119 6 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/70 {
  --tw-gradient-from: rgb(217 119 6 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/75 {
  --tw-gradient-from: rgb(217 119 6 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/80 {
  --tw-gradient-from: rgb(217 119 6 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/85 {
  --tw-gradient-from: rgb(217 119 6 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/90 {
  --tw-gradient-from: rgb(217 119 6 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-600\/95 {
  --tw-gradient-from: rgb(217 119 6 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700 {
  --tw-gradient-from: #b45309 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/0 {
  --tw-gradient-from: rgb(180 83 9 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/10 {
  --tw-gradient-from: rgb(180 83 9 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/100 {
  --tw-gradient-from: rgb(180 83 9 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/15 {
  --tw-gradient-from: rgb(180 83 9 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/20 {
  --tw-gradient-from: rgb(180 83 9 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/25 {
  --tw-gradient-from: rgb(180 83 9 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/30 {
  --tw-gradient-from: rgb(180 83 9 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/35 {
  --tw-gradient-from: rgb(180 83 9 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/40 {
  --tw-gradient-from: rgb(180 83 9 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/45 {
  --tw-gradient-from: rgb(180 83 9 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/5 {
  --tw-gradient-from: rgb(180 83 9 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/50 {
  --tw-gradient-from: rgb(180 83 9 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/55 {
  --tw-gradient-from: rgb(180 83 9 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/60 {
  --tw-gradient-from: rgb(180 83 9 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/65 {
  --tw-gradient-from: rgb(180 83 9 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/70 {
  --tw-gradient-from: rgb(180 83 9 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/75 {
  --tw-gradient-from: rgb(180 83 9 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/80 {
  --tw-gradient-from: rgb(180 83 9 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/85 {
  --tw-gradient-from: rgb(180 83 9 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/90 {
  --tw-gradient-from: rgb(180 83 9 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-700\/95 {
  --tw-gradient-from: rgb(180 83 9 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800 {
  --tw-gradient-from: #92400e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/0 {
  --tw-gradient-from: rgb(146 64 14 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/10 {
  --tw-gradient-from: rgb(146 64 14 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/100 {
  --tw-gradient-from: rgb(146 64 14 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/15 {
  --tw-gradient-from: rgb(146 64 14 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/20 {
  --tw-gradient-from: rgb(146 64 14 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/25 {
  --tw-gradient-from: rgb(146 64 14 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/30 {
  --tw-gradient-from: rgb(146 64 14 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/35 {
  --tw-gradient-from: rgb(146 64 14 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/40 {
  --tw-gradient-from: rgb(146 64 14 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/45 {
  --tw-gradient-from: rgb(146 64 14 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/5 {
  --tw-gradient-from: rgb(146 64 14 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/50 {
  --tw-gradient-from: rgb(146 64 14 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/55 {
  --tw-gradient-from: rgb(146 64 14 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/60 {
  --tw-gradient-from: rgb(146 64 14 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/65 {
  --tw-gradient-from: rgb(146 64 14 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/70 {
  --tw-gradient-from: rgb(146 64 14 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/75 {
  --tw-gradient-from: rgb(146 64 14 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/80 {
  --tw-gradient-from: rgb(146 64 14 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/85 {
  --tw-gradient-from: rgb(146 64 14 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/90 {
  --tw-gradient-from: rgb(146 64 14 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-800\/95 {
  --tw-gradient-from: rgb(146 64 14 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900 {
  --tw-gradient-from: #78350f var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/0 {
  --tw-gradient-from: rgb(120 53 15 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/10 {
  --tw-gradient-from: rgb(120 53 15 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/100 {
  --tw-gradient-from: rgb(120 53 15 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/15 {
  --tw-gradient-from: rgb(120 53 15 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/20 {
  --tw-gradient-from: rgb(120 53 15 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/25 {
  --tw-gradient-from: rgb(120 53 15 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/30 {
  --tw-gradient-from: rgb(120 53 15 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/35 {
  --tw-gradient-from: rgb(120 53 15 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/40 {
  --tw-gradient-from: rgb(120 53 15 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/45 {
  --tw-gradient-from: rgb(120 53 15 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/5 {
  --tw-gradient-from: rgb(120 53 15 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/50 {
  --tw-gradient-from: rgb(120 53 15 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/55 {
  --tw-gradient-from: rgb(120 53 15 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/60 {
  --tw-gradient-from: rgb(120 53 15 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/65 {
  --tw-gradient-from: rgb(120 53 15 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/70 {
  --tw-gradient-from: rgb(120 53 15 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/75 {
  --tw-gradient-from: rgb(120 53 15 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/80 {
  --tw-gradient-from: rgb(120 53 15 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/85 {
  --tw-gradient-from: rgb(120 53 15 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/90 {
  --tw-gradient-from: rgb(120 53 15 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-900\/95 {
  --tw-gradient-from: rgb(120 53 15 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950 {
  --tw-gradient-from: #451a03 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/0 {
  --tw-gradient-from: rgb(69 26 3 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/10 {
  --tw-gradient-from: rgb(69 26 3 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/100 {
  --tw-gradient-from: rgb(69 26 3 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/15 {
  --tw-gradient-from: rgb(69 26 3 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/20 {
  --tw-gradient-from: rgb(69 26 3 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/25 {
  --tw-gradient-from: rgb(69 26 3 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/30 {
  --tw-gradient-from: rgb(69 26 3 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/35 {
  --tw-gradient-from: rgb(69 26 3 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/40 {
  --tw-gradient-from: rgb(69 26 3 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/45 {
  --tw-gradient-from: rgb(69 26 3 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/5 {
  --tw-gradient-from: rgb(69 26 3 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/50 {
  --tw-gradient-from: rgb(69 26 3 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/55 {
  --tw-gradient-from: rgb(69 26 3 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/60 {
  --tw-gradient-from: rgb(69 26 3 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/65 {
  --tw-gradient-from: rgb(69 26 3 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/70 {
  --tw-gradient-from: rgb(69 26 3 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/75 {
  --tw-gradient-from: rgb(69 26 3 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/80 {
  --tw-gradient-from: rgb(69 26 3 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/85 {
  --tw-gradient-from: rgb(69 26 3 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/90 {
  --tw-gradient-from: rgb(69 26 3 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-amber-950\/95 {
  --tw-gradient-from: rgb(69 26 3 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black {
  --tw-gradient-from: #000 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/0 {
  --tw-gradient-from: rgb(0 0 0 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/10 {
  --tw-gradient-from: rgb(0 0 0 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/100 {
  --tw-gradient-from: rgb(0 0 0 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/15 {
  --tw-gradient-from: rgb(0 0 0 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/20 {
  --tw-gradient-from: rgb(0 0 0 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/25 {
  --tw-gradient-from: rgb(0 0 0 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/30 {
  --tw-gradient-from: rgb(0 0 0 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/35 {
  --tw-gradient-from: rgb(0 0 0 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/40 {
  --tw-gradient-from: rgb(0 0 0 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/45 {
  --tw-gradient-from: rgb(0 0 0 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/5 {
  --tw-gradient-from: rgb(0 0 0 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/50 {
  --tw-gradient-from: rgb(0 0 0 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/55 {
  --tw-gradient-from: rgb(0 0 0 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/60 {
  --tw-gradient-from: rgb(0 0 0 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/65 {
  --tw-gradient-from: rgb(0 0 0 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/70 {
  --tw-gradient-from: rgb(0 0 0 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/75 {
  --tw-gradient-from: rgb(0 0 0 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/80 {
  --tw-gradient-from: rgb(0 0 0 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/85 {
  --tw-gradient-from: rgb(0 0 0 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/90 {
  --tw-gradient-from: rgb(0 0 0 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-black\/95 {
  --tw-gradient-from: rgb(0 0 0 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100 {
  --tw-gradient-from: #dbeafe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/0 {
  --tw-gradient-from: rgb(219 234 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/10 {
  --tw-gradient-from: rgb(219 234 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/100 {
  --tw-gradient-from: rgb(219 234 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/15 {
  --tw-gradient-from: rgb(219 234 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/20 {
  --tw-gradient-from: rgb(219 234 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/25 {
  --tw-gradient-from: rgb(219 234 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/30 {
  --tw-gradient-from: rgb(219 234 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/35 {
  --tw-gradient-from: rgb(219 234 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/40 {
  --tw-gradient-from: rgb(219 234 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/45 {
  --tw-gradient-from: rgb(219 234 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/5 {
  --tw-gradient-from: rgb(219 234 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/50 {
  --tw-gradient-from: rgb(219 234 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/55 {
  --tw-gradient-from: rgb(219 234 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/60 {
  --tw-gradient-from: rgb(219 234 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/65 {
  --tw-gradient-from: rgb(219 234 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/70 {
  --tw-gradient-from: rgb(219 234 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/75 {
  --tw-gradient-from: rgb(219 234 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/80 {
  --tw-gradient-from: rgb(219 234 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/85 {
  --tw-gradient-from: rgb(219 234 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/90 {
  --tw-gradient-from: rgb(219 234 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-100\/95 {
  --tw-gradient-from: rgb(219 234 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200 {
  --tw-gradient-from: #bfdbfe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/0 {
  --tw-gradient-from: rgb(191 219 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/10 {
  --tw-gradient-from: rgb(191 219 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/100 {
  --tw-gradient-from: rgb(191 219 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/15 {
  --tw-gradient-from: rgb(191 219 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/20 {
  --tw-gradient-from: rgb(191 219 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/25 {
  --tw-gradient-from: rgb(191 219 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/30 {
  --tw-gradient-from: rgb(191 219 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/35 {
  --tw-gradient-from: rgb(191 219 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/40 {
  --tw-gradient-from: rgb(191 219 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/45 {
  --tw-gradient-from: rgb(191 219 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/5 {
  --tw-gradient-from: rgb(191 219 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/50 {
  --tw-gradient-from: rgb(191 219 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/55 {
  --tw-gradient-from: rgb(191 219 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/60 {
  --tw-gradient-from: rgb(191 219 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/65 {
  --tw-gradient-from: rgb(191 219 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/70 {
  --tw-gradient-from: rgb(191 219 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/75 {
  --tw-gradient-from: rgb(191 219 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/80 {
  --tw-gradient-from: rgb(191 219 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/85 {
  --tw-gradient-from: rgb(191 219 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/90 {
  --tw-gradient-from: rgb(191 219 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-200\/95 {
  --tw-gradient-from: rgb(191 219 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300 {
  --tw-gradient-from: #93c5fd var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/0 {
  --tw-gradient-from: rgb(147 197 253 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/10 {
  --tw-gradient-from: rgb(147 197 253 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/100 {
  --tw-gradient-from: rgb(147 197 253 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/15 {
  --tw-gradient-from: rgb(147 197 253 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/20 {
  --tw-gradient-from: rgb(147 197 253 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/25 {
  --tw-gradient-from: rgb(147 197 253 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/30 {
  --tw-gradient-from: rgb(147 197 253 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/35 {
  --tw-gradient-from: rgb(147 197 253 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/40 {
  --tw-gradient-from: rgb(147 197 253 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/45 {
  --tw-gradient-from: rgb(147 197 253 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/5 {
  --tw-gradient-from: rgb(147 197 253 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/50 {
  --tw-gradient-from: rgb(147 197 253 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/55 {
  --tw-gradient-from: rgb(147 197 253 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/60 {
  --tw-gradient-from: rgb(147 197 253 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/65 {
  --tw-gradient-from: rgb(147 197 253 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/70 {
  --tw-gradient-from: rgb(147 197 253 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/75 {
  --tw-gradient-from: rgb(147 197 253 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/80 {
  --tw-gradient-from: rgb(147 197 253 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/85 {
  --tw-gradient-from: rgb(147 197 253 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/90 {
  --tw-gradient-from: rgb(147 197 253 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-300\/95 {
  --tw-gradient-from: rgb(147 197 253 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400 {
  --tw-gradient-from: #60a5fa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/0 {
  --tw-gradient-from: rgb(96 165 250 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/10 {
  --tw-gradient-from: rgb(96 165 250 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/100 {
  --tw-gradient-from: rgb(96 165 250 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/15 {
  --tw-gradient-from: rgb(96 165 250 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/20 {
  --tw-gradient-from: rgb(96 165 250 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/25 {
  --tw-gradient-from: rgb(96 165 250 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/30 {
  --tw-gradient-from: rgb(96 165 250 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/35 {
  --tw-gradient-from: rgb(96 165 250 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/40 {
  --tw-gradient-from: rgb(96 165 250 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/45 {
  --tw-gradient-from: rgb(96 165 250 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/5 {
  --tw-gradient-from: rgb(96 165 250 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/50 {
  --tw-gradient-from: rgb(96 165 250 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/55 {
  --tw-gradient-from: rgb(96 165 250 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/60 {
  --tw-gradient-from: rgb(96 165 250 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/65 {
  --tw-gradient-from: rgb(96 165 250 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/70 {
  --tw-gradient-from: rgb(96 165 250 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/75 {
  --tw-gradient-from: rgb(96 165 250 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/80 {
  --tw-gradient-from: rgb(96 165 250 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/85 {
  --tw-gradient-from: rgb(96 165 250 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/90 {
  --tw-gradient-from: rgb(96 165 250 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-400\/95 {
  --tw-gradient-from: rgb(96 165 250 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50 {
  --tw-gradient-from: #eff6ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/0 {
  --tw-gradient-from: rgb(239 246 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/10 {
  --tw-gradient-from: rgb(239 246 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/100 {
  --tw-gradient-from: rgb(239 246 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/15 {
  --tw-gradient-from: rgb(239 246 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/20 {
  --tw-gradient-from: rgb(239 246 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/25 {
  --tw-gradient-from: rgb(239 246 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/30 {
  --tw-gradient-from: rgb(239 246 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/35 {
  --tw-gradient-from: rgb(239 246 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/40 {
  --tw-gradient-from: rgb(239 246 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/45 {
  --tw-gradient-from: rgb(239 246 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/5 {
  --tw-gradient-from: rgb(239 246 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/50 {
  --tw-gradient-from: rgb(239 246 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/55 {
  --tw-gradient-from: rgb(239 246 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/60 {
  --tw-gradient-from: rgb(239 246 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/65 {
  --tw-gradient-from: rgb(239 246 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/70 {
  --tw-gradient-from: rgb(239 246 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/75 {
  --tw-gradient-from: rgb(239 246 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/80 {
  --tw-gradient-from: rgb(239 246 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/85 {
  --tw-gradient-from: rgb(239 246 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/90 {
  --tw-gradient-from: rgb(239 246 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-50\/95 {
  --tw-gradient-from: rgb(239 246 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500 {
  --tw-gradient-from: #3b82f6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/0 {
  --tw-gradient-from: rgb(59 130 246 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/10 {
  --tw-gradient-from: rgb(59 130 246 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/100 {
  --tw-gradient-from: rgb(59 130 246 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/15 {
  --tw-gradient-from: rgb(59 130 246 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/20 {
  --tw-gradient-from: rgb(59 130 246 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/25 {
  --tw-gradient-from: rgb(59 130 246 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/30 {
  --tw-gradient-from: rgb(59 130 246 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/35 {
  --tw-gradient-from: rgb(59 130 246 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/40 {
  --tw-gradient-from: rgb(59 130 246 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/45 {
  --tw-gradient-from: rgb(59 130 246 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/5 {
  --tw-gradient-from: rgb(59 130 246 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/50 {
  --tw-gradient-from: rgb(59 130 246 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/55 {
  --tw-gradient-from: rgb(59 130 246 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/60 {
  --tw-gradient-from: rgb(59 130 246 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/65 {
  --tw-gradient-from: rgb(59 130 246 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/70 {
  --tw-gradient-from: rgb(59 130 246 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/75 {
  --tw-gradient-from: rgb(59 130 246 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/80 {
  --tw-gradient-from: rgb(59 130 246 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/85 {
  --tw-gradient-from: rgb(59 130 246 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/90 {
  --tw-gradient-from: rgb(59 130 246 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-500\/95 {
  --tw-gradient-from: rgb(59 130 246 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600 {
  --tw-gradient-from: #2563eb var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/0 {
  --tw-gradient-from: rgb(37 99 235 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/10 {
  --tw-gradient-from: rgb(37 99 235 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/100 {
  --tw-gradient-from: rgb(37 99 235 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/15 {
  --tw-gradient-from: rgb(37 99 235 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/20 {
  --tw-gradient-from: rgb(37 99 235 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/25 {
  --tw-gradient-from: rgb(37 99 235 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/30 {
  --tw-gradient-from: rgb(37 99 235 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/35 {
  --tw-gradient-from: rgb(37 99 235 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/40 {
  --tw-gradient-from: rgb(37 99 235 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/45 {
  --tw-gradient-from: rgb(37 99 235 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/5 {
  --tw-gradient-from: rgb(37 99 235 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/50 {
  --tw-gradient-from: rgb(37 99 235 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/55 {
  --tw-gradient-from: rgb(37 99 235 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/60 {
  --tw-gradient-from: rgb(37 99 235 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/65 {
  --tw-gradient-from: rgb(37 99 235 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/70 {
  --tw-gradient-from: rgb(37 99 235 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/75 {
  --tw-gradient-from: rgb(37 99 235 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/80 {
  --tw-gradient-from: rgb(37 99 235 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/85 {
  --tw-gradient-from: rgb(37 99 235 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/90 {
  --tw-gradient-from: rgb(37 99 235 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-600\/95 {
  --tw-gradient-from: rgb(37 99 235 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700 {
  --tw-gradient-from: #1d4ed8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/0 {
  --tw-gradient-from: rgb(29 78 216 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/10 {
  --tw-gradient-from: rgb(29 78 216 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/100 {
  --tw-gradient-from: rgb(29 78 216 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/15 {
  --tw-gradient-from: rgb(29 78 216 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/20 {
  --tw-gradient-from: rgb(29 78 216 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/25 {
  --tw-gradient-from: rgb(29 78 216 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/30 {
  --tw-gradient-from: rgb(29 78 216 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/35 {
  --tw-gradient-from: rgb(29 78 216 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/40 {
  --tw-gradient-from: rgb(29 78 216 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/45 {
  --tw-gradient-from: rgb(29 78 216 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/5 {
  --tw-gradient-from: rgb(29 78 216 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/50 {
  --tw-gradient-from: rgb(29 78 216 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/55 {
  --tw-gradient-from: rgb(29 78 216 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/60 {
  --tw-gradient-from: rgb(29 78 216 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/65 {
  --tw-gradient-from: rgb(29 78 216 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/70 {
  --tw-gradient-from: rgb(29 78 216 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/75 {
  --tw-gradient-from: rgb(29 78 216 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/80 {
  --tw-gradient-from: rgb(29 78 216 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/85 {
  --tw-gradient-from: rgb(29 78 216 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/90 {
  --tw-gradient-from: rgb(29 78 216 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-700\/95 {
  --tw-gradient-from: rgb(29 78 216 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800 {
  --tw-gradient-from: #1e40af var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/0 {
  --tw-gradient-from: rgb(30 64 175 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/10 {
  --tw-gradient-from: rgb(30 64 175 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/100 {
  --tw-gradient-from: rgb(30 64 175 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/15 {
  --tw-gradient-from: rgb(30 64 175 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/20 {
  --tw-gradient-from: rgb(30 64 175 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/25 {
  --tw-gradient-from: rgb(30 64 175 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/30 {
  --tw-gradient-from: rgb(30 64 175 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/35 {
  --tw-gradient-from: rgb(30 64 175 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/40 {
  --tw-gradient-from: rgb(30 64 175 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/45 {
  --tw-gradient-from: rgb(30 64 175 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/5 {
  --tw-gradient-from: rgb(30 64 175 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/50 {
  --tw-gradient-from: rgb(30 64 175 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/55 {
  --tw-gradient-from: rgb(30 64 175 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/60 {
  --tw-gradient-from: rgb(30 64 175 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/65 {
  --tw-gradient-from: rgb(30 64 175 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/70 {
  --tw-gradient-from: rgb(30 64 175 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/75 {
  --tw-gradient-from: rgb(30 64 175 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/80 {
  --tw-gradient-from: rgb(30 64 175 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/85 {
  --tw-gradient-from: rgb(30 64 175 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/90 {
  --tw-gradient-from: rgb(30 64 175 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-800\/95 {
  --tw-gradient-from: rgb(30 64 175 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900 {
  --tw-gradient-from: #1e3a8a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/0 {
  --tw-gradient-from: rgb(30 58 138 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/10 {
  --tw-gradient-from: rgb(30 58 138 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/100 {
  --tw-gradient-from: rgb(30 58 138 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/15 {
  --tw-gradient-from: rgb(30 58 138 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/20 {
  --tw-gradient-from: rgb(30 58 138 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/25 {
  --tw-gradient-from: rgb(30 58 138 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/30 {
  --tw-gradient-from: rgb(30 58 138 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/35 {
  --tw-gradient-from: rgb(30 58 138 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/40 {
  --tw-gradient-from: rgb(30 58 138 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/45 {
  --tw-gradient-from: rgb(30 58 138 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/5 {
  --tw-gradient-from: rgb(30 58 138 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/50 {
  --tw-gradient-from: rgb(30 58 138 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/55 {
  --tw-gradient-from: rgb(30 58 138 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/60 {
  --tw-gradient-from: rgb(30 58 138 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/65 {
  --tw-gradient-from: rgb(30 58 138 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/70 {
  --tw-gradient-from: rgb(30 58 138 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/75 {
  --tw-gradient-from: rgb(30 58 138 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/80 {
  --tw-gradient-from: rgb(30 58 138 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/85 {
  --tw-gradient-from: rgb(30 58 138 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/90 {
  --tw-gradient-from: rgb(30 58 138 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-900\/95 {
  --tw-gradient-from: rgb(30 58 138 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950 {
  --tw-gradient-from: #172554 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/0 {
  --tw-gradient-from: rgb(23 37 84 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/10 {
  --tw-gradient-from: rgb(23 37 84 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/100 {
  --tw-gradient-from: rgb(23 37 84 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/15 {
  --tw-gradient-from: rgb(23 37 84 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/20 {
  --tw-gradient-from: rgb(23 37 84 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/25 {
  --tw-gradient-from: rgb(23 37 84 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/30 {
  --tw-gradient-from: rgb(23 37 84 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/35 {
  --tw-gradient-from: rgb(23 37 84 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/40 {
  --tw-gradient-from: rgb(23 37 84 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/45 {
  --tw-gradient-from: rgb(23 37 84 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/5 {
  --tw-gradient-from: rgb(23 37 84 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/50 {
  --tw-gradient-from: rgb(23 37 84 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/55 {
  --tw-gradient-from: rgb(23 37 84 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/60 {
  --tw-gradient-from: rgb(23 37 84 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/65 {
  --tw-gradient-from: rgb(23 37 84 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/70 {
  --tw-gradient-from: rgb(23 37 84 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/75 {
  --tw-gradient-from: rgb(23 37 84 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/80 {
  --tw-gradient-from: rgb(23 37 84 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/85 {
  --tw-gradient-from: rgb(23 37 84 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/90 {
  --tw-gradient-from: rgb(23 37 84 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-blue-950\/95 {
  --tw-gradient-from: rgb(23 37 84 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-current {
  --tw-gradient-from: currentColor var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100 {
  --tw-gradient-from: #cffafe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/0 {
  --tw-gradient-from: rgb(207 250 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/10 {
  --tw-gradient-from: rgb(207 250 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/100 {
  --tw-gradient-from: rgb(207 250 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/15 {
  --tw-gradient-from: rgb(207 250 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/20 {
  --tw-gradient-from: rgb(207 250 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/25 {
  --tw-gradient-from: rgb(207 250 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/30 {
  --tw-gradient-from: rgb(207 250 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/35 {
  --tw-gradient-from: rgb(207 250 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/40 {
  --tw-gradient-from: rgb(207 250 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/45 {
  --tw-gradient-from: rgb(207 250 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/5 {
  --tw-gradient-from: rgb(207 250 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/50 {
  --tw-gradient-from: rgb(207 250 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/55 {
  --tw-gradient-from: rgb(207 250 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/60 {
  --tw-gradient-from: rgb(207 250 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/65 {
  --tw-gradient-from: rgb(207 250 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/70 {
  --tw-gradient-from: rgb(207 250 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/75 {
  --tw-gradient-from: rgb(207 250 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/80 {
  --tw-gradient-from: rgb(207 250 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/85 {
  --tw-gradient-from: rgb(207 250 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/90 {
  --tw-gradient-from: rgb(207 250 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-100\/95 {
  --tw-gradient-from: rgb(207 250 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200 {
  --tw-gradient-from: #a5f3fc var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/0 {
  --tw-gradient-from: rgb(165 243 252 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/10 {
  --tw-gradient-from: rgb(165 243 252 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/100 {
  --tw-gradient-from: rgb(165 243 252 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/15 {
  --tw-gradient-from: rgb(165 243 252 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/20 {
  --tw-gradient-from: rgb(165 243 252 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/25 {
  --tw-gradient-from: rgb(165 243 252 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/30 {
  --tw-gradient-from: rgb(165 243 252 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/35 {
  --tw-gradient-from: rgb(165 243 252 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/40 {
  --tw-gradient-from: rgb(165 243 252 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/45 {
  --tw-gradient-from: rgb(165 243 252 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/5 {
  --tw-gradient-from: rgb(165 243 252 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/50 {
  --tw-gradient-from: rgb(165 243 252 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/55 {
  --tw-gradient-from: rgb(165 243 252 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/60 {
  --tw-gradient-from: rgb(165 243 252 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/65 {
  --tw-gradient-from: rgb(165 243 252 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/70 {
  --tw-gradient-from: rgb(165 243 252 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/75 {
  --tw-gradient-from: rgb(165 243 252 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/80 {
  --tw-gradient-from: rgb(165 243 252 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/85 {
  --tw-gradient-from: rgb(165 243 252 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/90 {
  --tw-gradient-from: rgb(165 243 252 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-200\/95 {
  --tw-gradient-from: rgb(165 243 252 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300 {
  --tw-gradient-from: #67e8f9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/0 {
  --tw-gradient-from: rgb(103 232 249 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/10 {
  --tw-gradient-from: rgb(103 232 249 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/100 {
  --tw-gradient-from: rgb(103 232 249 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/15 {
  --tw-gradient-from: rgb(103 232 249 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/20 {
  --tw-gradient-from: rgb(103 232 249 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/25 {
  --tw-gradient-from: rgb(103 232 249 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/30 {
  --tw-gradient-from: rgb(103 232 249 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/35 {
  --tw-gradient-from: rgb(103 232 249 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/40 {
  --tw-gradient-from: rgb(103 232 249 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/45 {
  --tw-gradient-from: rgb(103 232 249 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/5 {
  --tw-gradient-from: rgb(103 232 249 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/50 {
  --tw-gradient-from: rgb(103 232 249 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/55 {
  --tw-gradient-from: rgb(103 232 249 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/60 {
  --tw-gradient-from: rgb(103 232 249 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/65 {
  --tw-gradient-from: rgb(103 232 249 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/70 {
  --tw-gradient-from: rgb(103 232 249 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/75 {
  --tw-gradient-from: rgb(103 232 249 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/80 {
  --tw-gradient-from: rgb(103 232 249 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/85 {
  --tw-gradient-from: rgb(103 232 249 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/90 {
  --tw-gradient-from: rgb(103 232 249 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-300\/95 {
  --tw-gradient-from: rgb(103 232 249 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400 {
  --tw-gradient-from: #22d3ee var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/0 {
  --tw-gradient-from: rgb(34 211 238 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/10 {
  --tw-gradient-from: rgb(34 211 238 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/100 {
  --tw-gradient-from: rgb(34 211 238 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/15 {
  --tw-gradient-from: rgb(34 211 238 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/20 {
  --tw-gradient-from: rgb(34 211 238 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/25 {
  --tw-gradient-from: rgb(34 211 238 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/30 {
  --tw-gradient-from: rgb(34 211 238 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/35 {
  --tw-gradient-from: rgb(34 211 238 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/40 {
  --tw-gradient-from: rgb(34 211 238 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/45 {
  --tw-gradient-from: rgb(34 211 238 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/5 {
  --tw-gradient-from: rgb(34 211 238 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/50 {
  --tw-gradient-from: rgb(34 211 238 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/55 {
  --tw-gradient-from: rgb(34 211 238 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/60 {
  --tw-gradient-from: rgb(34 211 238 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/65 {
  --tw-gradient-from: rgb(34 211 238 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/70 {
  --tw-gradient-from: rgb(34 211 238 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/75 {
  --tw-gradient-from: rgb(34 211 238 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/80 {
  --tw-gradient-from: rgb(34 211 238 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/85 {
  --tw-gradient-from: rgb(34 211 238 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/90 {
  --tw-gradient-from: rgb(34 211 238 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-400\/95 {
  --tw-gradient-from: rgb(34 211 238 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50 {
  --tw-gradient-from: #ecfeff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/0 {
  --tw-gradient-from: rgb(236 254 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/10 {
  --tw-gradient-from: rgb(236 254 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/100 {
  --tw-gradient-from: rgb(236 254 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/15 {
  --tw-gradient-from: rgb(236 254 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/20 {
  --tw-gradient-from: rgb(236 254 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/25 {
  --tw-gradient-from: rgb(236 254 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/30 {
  --tw-gradient-from: rgb(236 254 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/35 {
  --tw-gradient-from: rgb(236 254 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/40 {
  --tw-gradient-from: rgb(236 254 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/45 {
  --tw-gradient-from: rgb(236 254 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/5 {
  --tw-gradient-from: rgb(236 254 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/50 {
  --tw-gradient-from: rgb(236 254 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/55 {
  --tw-gradient-from: rgb(236 254 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/60 {
  --tw-gradient-from: rgb(236 254 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/65 {
  --tw-gradient-from: rgb(236 254 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/70 {
  --tw-gradient-from: rgb(236 254 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/75 {
  --tw-gradient-from: rgb(236 254 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/80 {
  --tw-gradient-from: rgb(236 254 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/85 {
  --tw-gradient-from: rgb(236 254 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/90 {
  --tw-gradient-from: rgb(236 254 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-50\/95 {
  --tw-gradient-from: rgb(236 254 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500 {
  --tw-gradient-from: #06b6d4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/0 {
  --tw-gradient-from: rgb(6 182 212 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/10 {
  --tw-gradient-from: rgb(6 182 212 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/100 {
  --tw-gradient-from: rgb(6 182 212 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/15 {
  --tw-gradient-from: rgb(6 182 212 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/20 {
  --tw-gradient-from: rgb(6 182 212 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/25 {
  --tw-gradient-from: rgb(6 182 212 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/30 {
  --tw-gradient-from: rgb(6 182 212 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/35 {
  --tw-gradient-from: rgb(6 182 212 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/40 {
  --tw-gradient-from: rgb(6 182 212 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/45 {
  --tw-gradient-from: rgb(6 182 212 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/5 {
  --tw-gradient-from: rgb(6 182 212 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/50 {
  --tw-gradient-from: rgb(6 182 212 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/55 {
  --tw-gradient-from: rgb(6 182 212 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/60 {
  --tw-gradient-from: rgb(6 182 212 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/65 {
  --tw-gradient-from: rgb(6 182 212 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/70 {
  --tw-gradient-from: rgb(6 182 212 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/75 {
  --tw-gradient-from: rgb(6 182 212 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/80 {
  --tw-gradient-from: rgb(6 182 212 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/85 {
  --tw-gradient-from: rgb(6 182 212 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/90 {
  --tw-gradient-from: rgb(6 182 212 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-500\/95 {
  --tw-gradient-from: rgb(6 182 212 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600 {
  --tw-gradient-from: #0891b2 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/0 {
  --tw-gradient-from: rgb(8 145 178 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/10 {
  --tw-gradient-from: rgb(8 145 178 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/100 {
  --tw-gradient-from: rgb(8 145 178 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/15 {
  --tw-gradient-from: rgb(8 145 178 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/20 {
  --tw-gradient-from: rgb(8 145 178 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/25 {
  --tw-gradient-from: rgb(8 145 178 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/30 {
  --tw-gradient-from: rgb(8 145 178 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/35 {
  --tw-gradient-from: rgb(8 145 178 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/40 {
  --tw-gradient-from: rgb(8 145 178 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/45 {
  --tw-gradient-from: rgb(8 145 178 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/5 {
  --tw-gradient-from: rgb(8 145 178 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/50 {
  --tw-gradient-from: rgb(8 145 178 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/55 {
  --tw-gradient-from: rgb(8 145 178 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/60 {
  --tw-gradient-from: rgb(8 145 178 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/65 {
  --tw-gradient-from: rgb(8 145 178 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/70 {
  --tw-gradient-from: rgb(8 145 178 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/75 {
  --tw-gradient-from: rgb(8 145 178 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/80 {
  --tw-gradient-from: rgb(8 145 178 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/85 {
  --tw-gradient-from: rgb(8 145 178 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/90 {
  --tw-gradient-from: rgb(8 145 178 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-600\/95 {
  --tw-gradient-from: rgb(8 145 178 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700 {
  --tw-gradient-from: #0e7490 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/0 {
  --tw-gradient-from: rgb(14 116 144 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/10 {
  --tw-gradient-from: rgb(14 116 144 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/100 {
  --tw-gradient-from: rgb(14 116 144 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/15 {
  --tw-gradient-from: rgb(14 116 144 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/20 {
  --tw-gradient-from: rgb(14 116 144 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/25 {
  --tw-gradient-from: rgb(14 116 144 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/30 {
  --tw-gradient-from: rgb(14 116 144 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/35 {
  --tw-gradient-from: rgb(14 116 144 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/40 {
  --tw-gradient-from: rgb(14 116 144 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/45 {
  --tw-gradient-from: rgb(14 116 144 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/5 {
  --tw-gradient-from: rgb(14 116 144 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/50 {
  --tw-gradient-from: rgb(14 116 144 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/55 {
  --tw-gradient-from: rgb(14 116 144 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/60 {
  --tw-gradient-from: rgb(14 116 144 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/65 {
  --tw-gradient-from: rgb(14 116 144 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/70 {
  --tw-gradient-from: rgb(14 116 144 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/75 {
  --tw-gradient-from: rgb(14 116 144 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/80 {
  --tw-gradient-from: rgb(14 116 144 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/85 {
  --tw-gradient-from: rgb(14 116 144 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/90 {
  --tw-gradient-from: rgb(14 116 144 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-700\/95 {
  --tw-gradient-from: rgb(14 116 144 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800 {
  --tw-gradient-from: #155e75 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/0 {
  --tw-gradient-from: rgb(21 94 117 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/10 {
  --tw-gradient-from: rgb(21 94 117 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/100 {
  --tw-gradient-from: rgb(21 94 117 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/15 {
  --tw-gradient-from: rgb(21 94 117 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/20 {
  --tw-gradient-from: rgb(21 94 117 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/25 {
  --tw-gradient-from: rgb(21 94 117 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/30 {
  --tw-gradient-from: rgb(21 94 117 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/35 {
  --tw-gradient-from: rgb(21 94 117 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/40 {
  --tw-gradient-from: rgb(21 94 117 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/45 {
  --tw-gradient-from: rgb(21 94 117 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/5 {
  --tw-gradient-from: rgb(21 94 117 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/50 {
  --tw-gradient-from: rgb(21 94 117 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/55 {
  --tw-gradient-from: rgb(21 94 117 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/60 {
  --tw-gradient-from: rgb(21 94 117 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/65 {
  --tw-gradient-from: rgb(21 94 117 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/70 {
  --tw-gradient-from: rgb(21 94 117 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/75 {
  --tw-gradient-from: rgb(21 94 117 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/80 {
  --tw-gradient-from: rgb(21 94 117 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/85 {
  --tw-gradient-from: rgb(21 94 117 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/90 {
  --tw-gradient-from: rgb(21 94 117 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-800\/95 {
  --tw-gradient-from: rgb(21 94 117 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900 {
  --tw-gradient-from: #164e63 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/0 {
  --tw-gradient-from: rgb(22 78 99 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/10 {
  --tw-gradient-from: rgb(22 78 99 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/100 {
  --tw-gradient-from: rgb(22 78 99 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/15 {
  --tw-gradient-from: rgb(22 78 99 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/20 {
  --tw-gradient-from: rgb(22 78 99 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/25 {
  --tw-gradient-from: rgb(22 78 99 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/30 {
  --tw-gradient-from: rgb(22 78 99 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/35 {
  --tw-gradient-from: rgb(22 78 99 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/40 {
  --tw-gradient-from: rgb(22 78 99 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/45 {
  --tw-gradient-from: rgb(22 78 99 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/5 {
  --tw-gradient-from: rgb(22 78 99 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/50 {
  --tw-gradient-from: rgb(22 78 99 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/55 {
  --tw-gradient-from: rgb(22 78 99 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/60 {
  --tw-gradient-from: rgb(22 78 99 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/65 {
  --tw-gradient-from: rgb(22 78 99 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/70 {
  --tw-gradient-from: rgb(22 78 99 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/75 {
  --tw-gradient-from: rgb(22 78 99 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/80 {
  --tw-gradient-from: rgb(22 78 99 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/85 {
  --tw-gradient-from: rgb(22 78 99 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/90 {
  --tw-gradient-from: rgb(22 78 99 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-900\/95 {
  --tw-gradient-from: rgb(22 78 99 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950 {
  --tw-gradient-from: #083344 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/0 {
  --tw-gradient-from: rgb(8 51 68 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/10 {
  --tw-gradient-from: rgb(8 51 68 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/100 {
  --tw-gradient-from: rgb(8 51 68 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/15 {
  --tw-gradient-from: rgb(8 51 68 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/20 {
  --tw-gradient-from: rgb(8 51 68 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/25 {
  --tw-gradient-from: rgb(8 51 68 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/30 {
  --tw-gradient-from: rgb(8 51 68 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/35 {
  --tw-gradient-from: rgb(8 51 68 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/40 {
  --tw-gradient-from: rgb(8 51 68 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/45 {
  --tw-gradient-from: rgb(8 51 68 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/5 {
  --tw-gradient-from: rgb(8 51 68 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/50 {
  --tw-gradient-from: rgb(8 51 68 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/55 {
  --tw-gradient-from: rgb(8 51 68 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/60 {
  --tw-gradient-from: rgb(8 51 68 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/65 {
  --tw-gradient-from: rgb(8 51 68 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/70 {
  --tw-gradient-from: rgb(8 51 68 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/75 {
  --tw-gradient-from: rgb(8 51 68 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/80 {
  --tw-gradient-from: rgb(8 51 68 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/85 {
  --tw-gradient-from: rgb(8 51 68 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/90 {
  --tw-gradient-from: rgb(8 51 68 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-cyan-950\/95 {
  --tw-gradient-from: rgb(8 51 68 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100 {
  --tw-gradient-from: #d1fae5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/0 {
  --tw-gradient-from: rgb(209 250 229 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/10 {
  --tw-gradient-from: rgb(209 250 229 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/100 {
  --tw-gradient-from: rgb(209 250 229 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/15 {
  --tw-gradient-from: rgb(209 250 229 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/20 {
  --tw-gradient-from: rgb(209 250 229 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/25 {
  --tw-gradient-from: rgb(209 250 229 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/30 {
  --tw-gradient-from: rgb(209 250 229 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/35 {
  --tw-gradient-from: rgb(209 250 229 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/40 {
  --tw-gradient-from: rgb(209 250 229 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/45 {
  --tw-gradient-from: rgb(209 250 229 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/5 {
  --tw-gradient-from: rgb(209 250 229 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/50 {
  --tw-gradient-from: rgb(209 250 229 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/55 {
  --tw-gradient-from: rgb(209 250 229 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/60 {
  --tw-gradient-from: rgb(209 250 229 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/65 {
  --tw-gradient-from: rgb(209 250 229 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/70 {
  --tw-gradient-from: rgb(209 250 229 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/75 {
  --tw-gradient-from: rgb(209 250 229 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/80 {
  --tw-gradient-from: rgb(209 250 229 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/85 {
  --tw-gradient-from: rgb(209 250 229 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/90 {
  --tw-gradient-from: rgb(209 250 229 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-100\/95 {
  --tw-gradient-from: rgb(209 250 229 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200 {
  --tw-gradient-from: #a7f3d0 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/0 {
  --tw-gradient-from: rgb(167 243 208 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/10 {
  --tw-gradient-from: rgb(167 243 208 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/100 {
  --tw-gradient-from: rgb(167 243 208 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/15 {
  --tw-gradient-from: rgb(167 243 208 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/20 {
  --tw-gradient-from: rgb(167 243 208 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/25 {
  --tw-gradient-from: rgb(167 243 208 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/30 {
  --tw-gradient-from: rgb(167 243 208 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/35 {
  --tw-gradient-from: rgb(167 243 208 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/40 {
  --tw-gradient-from: rgb(167 243 208 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/45 {
  --tw-gradient-from: rgb(167 243 208 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/5 {
  --tw-gradient-from: rgb(167 243 208 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/50 {
  --tw-gradient-from: rgb(167 243 208 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/55 {
  --tw-gradient-from: rgb(167 243 208 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/60 {
  --tw-gradient-from: rgb(167 243 208 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/65 {
  --tw-gradient-from: rgb(167 243 208 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/70 {
  --tw-gradient-from: rgb(167 243 208 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/75 {
  --tw-gradient-from: rgb(167 243 208 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/80 {
  --tw-gradient-from: rgb(167 243 208 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/85 {
  --tw-gradient-from: rgb(167 243 208 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/90 {
  --tw-gradient-from: rgb(167 243 208 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-200\/95 {
  --tw-gradient-from: rgb(167 243 208 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300 {
  --tw-gradient-from: #6ee7b7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/0 {
  --tw-gradient-from: rgb(110 231 183 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/10 {
  --tw-gradient-from: rgb(110 231 183 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/100 {
  --tw-gradient-from: rgb(110 231 183 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/15 {
  --tw-gradient-from: rgb(110 231 183 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/20 {
  --tw-gradient-from: rgb(110 231 183 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/25 {
  --tw-gradient-from: rgb(110 231 183 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/30 {
  --tw-gradient-from: rgb(110 231 183 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/35 {
  --tw-gradient-from: rgb(110 231 183 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/40 {
  --tw-gradient-from: rgb(110 231 183 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/45 {
  --tw-gradient-from: rgb(110 231 183 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/5 {
  --tw-gradient-from: rgb(110 231 183 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/50 {
  --tw-gradient-from: rgb(110 231 183 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/55 {
  --tw-gradient-from: rgb(110 231 183 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/60 {
  --tw-gradient-from: rgb(110 231 183 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/65 {
  --tw-gradient-from: rgb(110 231 183 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/70 {
  --tw-gradient-from: rgb(110 231 183 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/75 {
  --tw-gradient-from: rgb(110 231 183 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/80 {
  --tw-gradient-from: rgb(110 231 183 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/85 {
  --tw-gradient-from: rgb(110 231 183 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/90 {
  --tw-gradient-from: rgb(110 231 183 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-300\/95 {
  --tw-gradient-from: rgb(110 231 183 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400 {
  --tw-gradient-from: #34d399 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/0 {
  --tw-gradient-from: rgb(52 211 153 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/10 {
  --tw-gradient-from: rgb(52 211 153 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/100 {
  --tw-gradient-from: rgb(52 211 153 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/15 {
  --tw-gradient-from: rgb(52 211 153 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/20 {
  --tw-gradient-from: rgb(52 211 153 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/25 {
  --tw-gradient-from: rgb(52 211 153 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/30 {
  --tw-gradient-from: rgb(52 211 153 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/35 {
  --tw-gradient-from: rgb(52 211 153 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/40 {
  --tw-gradient-from: rgb(52 211 153 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/45 {
  --tw-gradient-from: rgb(52 211 153 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/5 {
  --tw-gradient-from: rgb(52 211 153 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/50 {
  --tw-gradient-from: rgb(52 211 153 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/55 {
  --tw-gradient-from: rgb(52 211 153 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/60 {
  --tw-gradient-from: rgb(52 211 153 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/65 {
  --tw-gradient-from: rgb(52 211 153 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/70 {
  --tw-gradient-from: rgb(52 211 153 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/75 {
  --tw-gradient-from: rgb(52 211 153 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/80 {
  --tw-gradient-from: rgb(52 211 153 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/85 {
  --tw-gradient-from: rgb(52 211 153 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/90 {
  --tw-gradient-from: rgb(52 211 153 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-400\/95 {
  --tw-gradient-from: rgb(52 211 153 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50 {
  --tw-gradient-from: #ecfdf5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/0 {
  --tw-gradient-from: rgb(236 253 245 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/10 {
  --tw-gradient-from: rgb(236 253 245 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/100 {
  --tw-gradient-from: rgb(236 253 245 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/15 {
  --tw-gradient-from: rgb(236 253 245 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/20 {
  --tw-gradient-from: rgb(236 253 245 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/25 {
  --tw-gradient-from: rgb(236 253 245 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/30 {
  --tw-gradient-from: rgb(236 253 245 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/35 {
  --tw-gradient-from: rgb(236 253 245 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/40 {
  --tw-gradient-from: rgb(236 253 245 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/45 {
  --tw-gradient-from: rgb(236 253 245 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/5 {
  --tw-gradient-from: rgb(236 253 245 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/50 {
  --tw-gradient-from: rgb(236 253 245 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/55 {
  --tw-gradient-from: rgb(236 253 245 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/60 {
  --tw-gradient-from: rgb(236 253 245 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/65 {
  --tw-gradient-from: rgb(236 253 245 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/70 {
  --tw-gradient-from: rgb(236 253 245 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/75 {
  --tw-gradient-from: rgb(236 253 245 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/80 {
  --tw-gradient-from: rgb(236 253 245 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/85 {
  --tw-gradient-from: rgb(236 253 245 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/90 {
  --tw-gradient-from: rgb(236 253 245 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-50\/95 {
  --tw-gradient-from: rgb(236 253 245 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500 {
  --tw-gradient-from: #10b981 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/0 {
  --tw-gradient-from: rgb(16 185 129 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/10 {
  --tw-gradient-from: rgb(16 185 129 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/100 {
  --tw-gradient-from: rgb(16 185 129 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/15 {
  --tw-gradient-from: rgb(16 185 129 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/20 {
  --tw-gradient-from: rgb(16 185 129 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/25 {
  --tw-gradient-from: rgb(16 185 129 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/30 {
  --tw-gradient-from: rgb(16 185 129 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/35 {
  --tw-gradient-from: rgb(16 185 129 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/40 {
  --tw-gradient-from: rgb(16 185 129 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/45 {
  --tw-gradient-from: rgb(16 185 129 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/5 {
  --tw-gradient-from: rgb(16 185 129 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/50 {
  --tw-gradient-from: rgb(16 185 129 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/55 {
  --tw-gradient-from: rgb(16 185 129 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/60 {
  --tw-gradient-from: rgb(16 185 129 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/65 {
  --tw-gradient-from: rgb(16 185 129 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/70 {
  --tw-gradient-from: rgb(16 185 129 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/75 {
  --tw-gradient-from: rgb(16 185 129 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/80 {
  --tw-gradient-from: rgb(16 185 129 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/85 {
  --tw-gradient-from: rgb(16 185 129 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/90 {
  --tw-gradient-from: rgb(16 185 129 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-500\/95 {
  --tw-gradient-from: rgb(16 185 129 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600 {
  --tw-gradient-from: #059669 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/0 {
  --tw-gradient-from: rgb(5 150 105 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/10 {
  --tw-gradient-from: rgb(5 150 105 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/100 {
  --tw-gradient-from: rgb(5 150 105 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/15 {
  --tw-gradient-from: rgb(5 150 105 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/20 {
  --tw-gradient-from: rgb(5 150 105 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/25 {
  --tw-gradient-from: rgb(5 150 105 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/30 {
  --tw-gradient-from: rgb(5 150 105 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/35 {
  --tw-gradient-from: rgb(5 150 105 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/40 {
  --tw-gradient-from: rgb(5 150 105 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/45 {
  --tw-gradient-from: rgb(5 150 105 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/5 {
  --tw-gradient-from: rgb(5 150 105 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/50 {
  --tw-gradient-from: rgb(5 150 105 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/55 {
  --tw-gradient-from: rgb(5 150 105 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/60 {
  --tw-gradient-from: rgb(5 150 105 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/65 {
  --tw-gradient-from: rgb(5 150 105 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/70 {
  --tw-gradient-from: rgb(5 150 105 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/75 {
  --tw-gradient-from: rgb(5 150 105 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/80 {
  --tw-gradient-from: rgb(5 150 105 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/85 {
  --tw-gradient-from: rgb(5 150 105 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/90 {
  --tw-gradient-from: rgb(5 150 105 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-600\/95 {
  --tw-gradient-from: rgb(5 150 105 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700 {
  --tw-gradient-from: #047857 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/0 {
  --tw-gradient-from: rgb(4 120 87 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/10 {
  --tw-gradient-from: rgb(4 120 87 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/100 {
  --tw-gradient-from: rgb(4 120 87 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/15 {
  --tw-gradient-from: rgb(4 120 87 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/20 {
  --tw-gradient-from: rgb(4 120 87 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/25 {
  --tw-gradient-from: rgb(4 120 87 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/30 {
  --tw-gradient-from: rgb(4 120 87 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/35 {
  --tw-gradient-from: rgb(4 120 87 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/40 {
  --tw-gradient-from: rgb(4 120 87 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/45 {
  --tw-gradient-from: rgb(4 120 87 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/5 {
  --tw-gradient-from: rgb(4 120 87 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/50 {
  --tw-gradient-from: rgb(4 120 87 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/55 {
  --tw-gradient-from: rgb(4 120 87 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/60 {
  --tw-gradient-from: rgb(4 120 87 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/65 {
  --tw-gradient-from: rgb(4 120 87 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/70 {
  --tw-gradient-from: rgb(4 120 87 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/75 {
  --tw-gradient-from: rgb(4 120 87 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/80 {
  --tw-gradient-from: rgb(4 120 87 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/85 {
  --tw-gradient-from: rgb(4 120 87 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/90 {
  --tw-gradient-from: rgb(4 120 87 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-700\/95 {
  --tw-gradient-from: rgb(4 120 87 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800 {
  --tw-gradient-from: #065f46 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/0 {
  --tw-gradient-from: rgb(6 95 70 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/10 {
  --tw-gradient-from: rgb(6 95 70 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/100 {
  --tw-gradient-from: rgb(6 95 70 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/15 {
  --tw-gradient-from: rgb(6 95 70 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/20 {
  --tw-gradient-from: rgb(6 95 70 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/25 {
  --tw-gradient-from: rgb(6 95 70 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/30 {
  --tw-gradient-from: rgb(6 95 70 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/35 {
  --tw-gradient-from: rgb(6 95 70 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/40 {
  --tw-gradient-from: rgb(6 95 70 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/45 {
  --tw-gradient-from: rgb(6 95 70 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/5 {
  --tw-gradient-from: rgb(6 95 70 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/50 {
  --tw-gradient-from: rgb(6 95 70 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/55 {
  --tw-gradient-from: rgb(6 95 70 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/60 {
  --tw-gradient-from: rgb(6 95 70 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/65 {
  --tw-gradient-from: rgb(6 95 70 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/70 {
  --tw-gradient-from: rgb(6 95 70 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/75 {
  --tw-gradient-from: rgb(6 95 70 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/80 {
  --tw-gradient-from: rgb(6 95 70 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/85 {
  --tw-gradient-from: rgb(6 95 70 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/90 {
  --tw-gradient-from: rgb(6 95 70 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-800\/95 {
  --tw-gradient-from: rgb(6 95 70 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900 {
  --tw-gradient-from: #064e3b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/0 {
  --tw-gradient-from: rgb(6 78 59 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/10 {
  --tw-gradient-from: rgb(6 78 59 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/100 {
  --tw-gradient-from: rgb(6 78 59 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/15 {
  --tw-gradient-from: rgb(6 78 59 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/20 {
  --tw-gradient-from: rgb(6 78 59 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/25 {
  --tw-gradient-from: rgb(6 78 59 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/30 {
  --tw-gradient-from: rgb(6 78 59 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/35 {
  --tw-gradient-from: rgb(6 78 59 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/40 {
  --tw-gradient-from: rgb(6 78 59 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/45 {
  --tw-gradient-from: rgb(6 78 59 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/5 {
  --tw-gradient-from: rgb(6 78 59 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/50 {
  --tw-gradient-from: rgb(6 78 59 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/55 {
  --tw-gradient-from: rgb(6 78 59 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/60 {
  --tw-gradient-from: rgb(6 78 59 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/65 {
  --tw-gradient-from: rgb(6 78 59 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/70 {
  --tw-gradient-from: rgb(6 78 59 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/75 {
  --tw-gradient-from: rgb(6 78 59 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/80 {
  --tw-gradient-from: rgb(6 78 59 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/85 {
  --tw-gradient-from: rgb(6 78 59 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/90 {
  --tw-gradient-from: rgb(6 78 59 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-900\/95 {
  --tw-gradient-from: rgb(6 78 59 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950 {
  --tw-gradient-from: #022c22 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/0 {
  --tw-gradient-from: rgb(2 44 34 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/10 {
  --tw-gradient-from: rgb(2 44 34 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/100 {
  --tw-gradient-from: rgb(2 44 34 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/15 {
  --tw-gradient-from: rgb(2 44 34 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/20 {
  --tw-gradient-from: rgb(2 44 34 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/25 {
  --tw-gradient-from: rgb(2 44 34 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/30 {
  --tw-gradient-from: rgb(2 44 34 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/35 {
  --tw-gradient-from: rgb(2 44 34 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/40 {
  --tw-gradient-from: rgb(2 44 34 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/45 {
  --tw-gradient-from: rgb(2 44 34 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/5 {
  --tw-gradient-from: rgb(2 44 34 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/50 {
  --tw-gradient-from: rgb(2 44 34 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/55 {
  --tw-gradient-from: rgb(2 44 34 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/60 {
  --tw-gradient-from: rgb(2 44 34 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/65 {
  --tw-gradient-from: rgb(2 44 34 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/70 {
  --tw-gradient-from: rgb(2 44 34 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/75 {
  --tw-gradient-from: rgb(2 44 34 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/80 {
  --tw-gradient-from: rgb(2 44 34 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/85 {
  --tw-gradient-from: rgb(2 44 34 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/90 {
  --tw-gradient-from: rgb(2 44 34 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-emerald-950\/95 {
  --tw-gradient-from: rgb(2 44 34 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100 {
  --tw-gradient-from: #fae8ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/0 {
  --tw-gradient-from: rgb(250 232 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/10 {
  --tw-gradient-from: rgb(250 232 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/100 {
  --tw-gradient-from: rgb(250 232 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/15 {
  --tw-gradient-from: rgb(250 232 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/20 {
  --tw-gradient-from: rgb(250 232 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/25 {
  --tw-gradient-from: rgb(250 232 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/30 {
  --tw-gradient-from: rgb(250 232 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/35 {
  --tw-gradient-from: rgb(250 232 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/40 {
  --tw-gradient-from: rgb(250 232 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/45 {
  --tw-gradient-from: rgb(250 232 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/5 {
  --tw-gradient-from: rgb(250 232 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/50 {
  --tw-gradient-from: rgb(250 232 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/55 {
  --tw-gradient-from: rgb(250 232 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/60 {
  --tw-gradient-from: rgb(250 232 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/65 {
  --tw-gradient-from: rgb(250 232 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/70 {
  --tw-gradient-from: rgb(250 232 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/75 {
  --tw-gradient-from: rgb(250 232 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/80 {
  --tw-gradient-from: rgb(250 232 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/85 {
  --tw-gradient-from: rgb(250 232 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/90 {
  --tw-gradient-from: rgb(250 232 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-100\/95 {
  --tw-gradient-from: rgb(250 232 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200 {
  --tw-gradient-from: #f5d0fe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/0 {
  --tw-gradient-from: rgb(245 208 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/10 {
  --tw-gradient-from: rgb(245 208 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/100 {
  --tw-gradient-from: rgb(245 208 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/15 {
  --tw-gradient-from: rgb(245 208 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/20 {
  --tw-gradient-from: rgb(245 208 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/25 {
  --tw-gradient-from: rgb(245 208 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/30 {
  --tw-gradient-from: rgb(245 208 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/35 {
  --tw-gradient-from: rgb(245 208 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/40 {
  --tw-gradient-from: rgb(245 208 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/45 {
  --tw-gradient-from: rgb(245 208 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/5 {
  --tw-gradient-from: rgb(245 208 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/50 {
  --tw-gradient-from: rgb(245 208 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/55 {
  --tw-gradient-from: rgb(245 208 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/60 {
  --tw-gradient-from: rgb(245 208 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/65 {
  --tw-gradient-from: rgb(245 208 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/70 {
  --tw-gradient-from: rgb(245 208 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/75 {
  --tw-gradient-from: rgb(245 208 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/80 {
  --tw-gradient-from: rgb(245 208 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/85 {
  --tw-gradient-from: rgb(245 208 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/90 {
  --tw-gradient-from: rgb(245 208 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-200\/95 {
  --tw-gradient-from: rgb(245 208 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300 {
  --tw-gradient-from: #f0abfc var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/0 {
  --tw-gradient-from: rgb(240 171 252 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/10 {
  --tw-gradient-from: rgb(240 171 252 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/100 {
  --tw-gradient-from: rgb(240 171 252 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/15 {
  --tw-gradient-from: rgb(240 171 252 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/20 {
  --tw-gradient-from: rgb(240 171 252 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/25 {
  --tw-gradient-from: rgb(240 171 252 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/30 {
  --tw-gradient-from: rgb(240 171 252 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/35 {
  --tw-gradient-from: rgb(240 171 252 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/40 {
  --tw-gradient-from: rgb(240 171 252 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/45 {
  --tw-gradient-from: rgb(240 171 252 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/5 {
  --tw-gradient-from: rgb(240 171 252 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/50 {
  --tw-gradient-from: rgb(240 171 252 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/55 {
  --tw-gradient-from: rgb(240 171 252 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/60 {
  --tw-gradient-from: rgb(240 171 252 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/65 {
  --tw-gradient-from: rgb(240 171 252 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/70 {
  --tw-gradient-from: rgb(240 171 252 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/75 {
  --tw-gradient-from: rgb(240 171 252 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/80 {
  --tw-gradient-from: rgb(240 171 252 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/85 {
  --tw-gradient-from: rgb(240 171 252 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/90 {
  --tw-gradient-from: rgb(240 171 252 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-300\/95 {
  --tw-gradient-from: rgb(240 171 252 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400 {
  --tw-gradient-from: #e879f9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/0 {
  --tw-gradient-from: rgb(232 121 249 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/10 {
  --tw-gradient-from: rgb(232 121 249 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/100 {
  --tw-gradient-from: rgb(232 121 249 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/15 {
  --tw-gradient-from: rgb(232 121 249 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/20 {
  --tw-gradient-from: rgb(232 121 249 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/25 {
  --tw-gradient-from: rgb(232 121 249 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/30 {
  --tw-gradient-from: rgb(232 121 249 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/35 {
  --tw-gradient-from: rgb(232 121 249 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/40 {
  --tw-gradient-from: rgb(232 121 249 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/45 {
  --tw-gradient-from: rgb(232 121 249 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/5 {
  --tw-gradient-from: rgb(232 121 249 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/50 {
  --tw-gradient-from: rgb(232 121 249 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/55 {
  --tw-gradient-from: rgb(232 121 249 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/60 {
  --tw-gradient-from: rgb(232 121 249 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/65 {
  --tw-gradient-from: rgb(232 121 249 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/70 {
  --tw-gradient-from: rgb(232 121 249 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/75 {
  --tw-gradient-from: rgb(232 121 249 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/80 {
  --tw-gradient-from: rgb(232 121 249 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/85 {
  --tw-gradient-from: rgb(232 121 249 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/90 {
  --tw-gradient-from: rgb(232 121 249 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-400\/95 {
  --tw-gradient-from: rgb(232 121 249 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50 {
  --tw-gradient-from: #fdf4ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/0 {
  --tw-gradient-from: rgb(253 244 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/10 {
  --tw-gradient-from: rgb(253 244 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/100 {
  --tw-gradient-from: rgb(253 244 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/15 {
  --tw-gradient-from: rgb(253 244 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/20 {
  --tw-gradient-from: rgb(253 244 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/25 {
  --tw-gradient-from: rgb(253 244 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/30 {
  --tw-gradient-from: rgb(253 244 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/35 {
  --tw-gradient-from: rgb(253 244 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/40 {
  --tw-gradient-from: rgb(253 244 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/45 {
  --tw-gradient-from: rgb(253 244 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/5 {
  --tw-gradient-from: rgb(253 244 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/50 {
  --tw-gradient-from: rgb(253 244 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/55 {
  --tw-gradient-from: rgb(253 244 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/60 {
  --tw-gradient-from: rgb(253 244 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/65 {
  --tw-gradient-from: rgb(253 244 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/70 {
  --tw-gradient-from: rgb(253 244 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/75 {
  --tw-gradient-from: rgb(253 244 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/80 {
  --tw-gradient-from: rgb(253 244 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/85 {
  --tw-gradient-from: rgb(253 244 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/90 {
  --tw-gradient-from: rgb(253 244 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-50\/95 {
  --tw-gradient-from: rgb(253 244 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500 {
  --tw-gradient-from: #d946ef var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/0 {
  --tw-gradient-from: rgb(217 70 239 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/10 {
  --tw-gradient-from: rgb(217 70 239 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/100 {
  --tw-gradient-from: rgb(217 70 239 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/15 {
  --tw-gradient-from: rgb(217 70 239 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/20 {
  --tw-gradient-from: rgb(217 70 239 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/25 {
  --tw-gradient-from: rgb(217 70 239 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/30 {
  --tw-gradient-from: rgb(217 70 239 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/35 {
  --tw-gradient-from: rgb(217 70 239 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/40 {
  --tw-gradient-from: rgb(217 70 239 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/45 {
  --tw-gradient-from: rgb(217 70 239 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/5 {
  --tw-gradient-from: rgb(217 70 239 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/50 {
  --tw-gradient-from: rgb(217 70 239 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/55 {
  --tw-gradient-from: rgb(217 70 239 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/60 {
  --tw-gradient-from: rgb(217 70 239 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/65 {
  --tw-gradient-from: rgb(217 70 239 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/70 {
  --tw-gradient-from: rgb(217 70 239 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/75 {
  --tw-gradient-from: rgb(217 70 239 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/80 {
  --tw-gradient-from: rgb(217 70 239 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/85 {
  --tw-gradient-from: rgb(217 70 239 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/90 {
  --tw-gradient-from: rgb(217 70 239 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-500\/95 {
  --tw-gradient-from: rgb(217 70 239 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600 {
  --tw-gradient-from: #c026d3 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/0 {
  --tw-gradient-from: rgb(192 38 211 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/10 {
  --tw-gradient-from: rgb(192 38 211 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/100 {
  --tw-gradient-from: rgb(192 38 211 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/15 {
  --tw-gradient-from: rgb(192 38 211 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/20 {
  --tw-gradient-from: rgb(192 38 211 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/25 {
  --tw-gradient-from: rgb(192 38 211 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/30 {
  --tw-gradient-from: rgb(192 38 211 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/35 {
  --tw-gradient-from: rgb(192 38 211 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/40 {
  --tw-gradient-from: rgb(192 38 211 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/45 {
  --tw-gradient-from: rgb(192 38 211 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/5 {
  --tw-gradient-from: rgb(192 38 211 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/50 {
  --tw-gradient-from: rgb(192 38 211 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/55 {
  --tw-gradient-from: rgb(192 38 211 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/60 {
  --tw-gradient-from: rgb(192 38 211 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/65 {
  --tw-gradient-from: rgb(192 38 211 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/70 {
  --tw-gradient-from: rgb(192 38 211 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/75 {
  --tw-gradient-from: rgb(192 38 211 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/80 {
  --tw-gradient-from: rgb(192 38 211 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/85 {
  --tw-gradient-from: rgb(192 38 211 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/90 {
  --tw-gradient-from: rgb(192 38 211 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-600\/95 {
  --tw-gradient-from: rgb(192 38 211 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700 {
  --tw-gradient-from: #a21caf var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/0 {
  --tw-gradient-from: rgb(162 28 175 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/10 {
  --tw-gradient-from: rgb(162 28 175 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/100 {
  --tw-gradient-from: rgb(162 28 175 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/15 {
  --tw-gradient-from: rgb(162 28 175 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/20 {
  --tw-gradient-from: rgb(162 28 175 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/25 {
  --tw-gradient-from: rgb(162 28 175 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/30 {
  --tw-gradient-from: rgb(162 28 175 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/35 {
  --tw-gradient-from: rgb(162 28 175 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/40 {
  --tw-gradient-from: rgb(162 28 175 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/45 {
  --tw-gradient-from: rgb(162 28 175 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/5 {
  --tw-gradient-from: rgb(162 28 175 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/50 {
  --tw-gradient-from: rgb(162 28 175 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/55 {
  --tw-gradient-from: rgb(162 28 175 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/60 {
  --tw-gradient-from: rgb(162 28 175 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/65 {
  --tw-gradient-from: rgb(162 28 175 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/70 {
  --tw-gradient-from: rgb(162 28 175 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/75 {
  --tw-gradient-from: rgb(162 28 175 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/80 {
  --tw-gradient-from: rgb(162 28 175 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/85 {
  --tw-gradient-from: rgb(162 28 175 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/90 {
  --tw-gradient-from: rgb(162 28 175 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-700\/95 {
  --tw-gradient-from: rgb(162 28 175 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800 {
  --tw-gradient-from: #86198f var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/0 {
  --tw-gradient-from: rgb(134 25 143 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/10 {
  --tw-gradient-from: rgb(134 25 143 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/100 {
  --tw-gradient-from: rgb(134 25 143 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/15 {
  --tw-gradient-from: rgb(134 25 143 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/20 {
  --tw-gradient-from: rgb(134 25 143 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/25 {
  --tw-gradient-from: rgb(134 25 143 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/30 {
  --tw-gradient-from: rgb(134 25 143 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/35 {
  --tw-gradient-from: rgb(134 25 143 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/40 {
  --tw-gradient-from: rgb(134 25 143 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/45 {
  --tw-gradient-from: rgb(134 25 143 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/5 {
  --tw-gradient-from: rgb(134 25 143 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/50 {
  --tw-gradient-from: rgb(134 25 143 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/55 {
  --tw-gradient-from: rgb(134 25 143 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/60 {
  --tw-gradient-from: rgb(134 25 143 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/65 {
  --tw-gradient-from: rgb(134 25 143 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/70 {
  --tw-gradient-from: rgb(134 25 143 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/75 {
  --tw-gradient-from: rgb(134 25 143 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/80 {
  --tw-gradient-from: rgb(134 25 143 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/85 {
  --tw-gradient-from: rgb(134 25 143 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/90 {
  --tw-gradient-from: rgb(134 25 143 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-800\/95 {
  --tw-gradient-from: rgb(134 25 143 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900 {
  --tw-gradient-from: #701a75 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/0 {
  --tw-gradient-from: rgb(112 26 117 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/10 {
  --tw-gradient-from: rgb(112 26 117 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/100 {
  --tw-gradient-from: rgb(112 26 117 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/15 {
  --tw-gradient-from: rgb(112 26 117 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/20 {
  --tw-gradient-from: rgb(112 26 117 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/25 {
  --tw-gradient-from: rgb(112 26 117 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/30 {
  --tw-gradient-from: rgb(112 26 117 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/35 {
  --tw-gradient-from: rgb(112 26 117 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/40 {
  --tw-gradient-from: rgb(112 26 117 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/45 {
  --tw-gradient-from: rgb(112 26 117 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/5 {
  --tw-gradient-from: rgb(112 26 117 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/50 {
  --tw-gradient-from: rgb(112 26 117 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/55 {
  --tw-gradient-from: rgb(112 26 117 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/60 {
  --tw-gradient-from: rgb(112 26 117 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/65 {
  --tw-gradient-from: rgb(112 26 117 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/70 {
  --tw-gradient-from: rgb(112 26 117 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/75 {
  --tw-gradient-from: rgb(112 26 117 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/80 {
  --tw-gradient-from: rgb(112 26 117 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/85 {
  --tw-gradient-from: rgb(112 26 117 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/90 {
  --tw-gradient-from: rgb(112 26 117 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-900\/95 {
  --tw-gradient-from: rgb(112 26 117 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950 {
  --tw-gradient-from: #4a044e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/0 {
  --tw-gradient-from: rgb(74 4 78 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/10 {
  --tw-gradient-from: rgb(74 4 78 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/100 {
  --tw-gradient-from: rgb(74 4 78 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/15 {
  --tw-gradient-from: rgb(74 4 78 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/20 {
  --tw-gradient-from: rgb(74 4 78 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/25 {
  --tw-gradient-from: rgb(74 4 78 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/30 {
  --tw-gradient-from: rgb(74 4 78 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/35 {
  --tw-gradient-from: rgb(74 4 78 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/40 {
  --tw-gradient-from: rgb(74 4 78 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/45 {
  --tw-gradient-from: rgb(74 4 78 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/5 {
  --tw-gradient-from: rgb(74 4 78 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/50 {
  --tw-gradient-from: rgb(74 4 78 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/55 {
  --tw-gradient-from: rgb(74 4 78 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/60 {
  --tw-gradient-from: rgb(74 4 78 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/65 {
  --tw-gradient-from: rgb(74 4 78 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/70 {
  --tw-gradient-from: rgb(74 4 78 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/75 {
  --tw-gradient-from: rgb(74 4 78 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/80 {
  --tw-gradient-from: rgb(74 4 78 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/85 {
  --tw-gradient-from: rgb(74 4 78 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/90 {
  --tw-gradient-from: rgb(74 4 78 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-fuchsia-950\/95 {
  --tw-gradient-from: rgb(74 4 78 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100 {
  --tw-gradient-from: #f3f4f6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/0 {
  --tw-gradient-from: rgb(243 244 246 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/10 {
  --tw-gradient-from: rgb(243 244 246 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/100 {
  --tw-gradient-from: rgb(243 244 246 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/15 {
  --tw-gradient-from: rgb(243 244 246 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/20 {
  --tw-gradient-from: rgb(243 244 246 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/25 {
  --tw-gradient-from: rgb(243 244 246 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/30 {
  --tw-gradient-from: rgb(243 244 246 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/35 {
  --tw-gradient-from: rgb(243 244 246 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/40 {
  --tw-gradient-from: rgb(243 244 246 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/45 {
  --tw-gradient-from: rgb(243 244 246 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/5 {
  --tw-gradient-from: rgb(243 244 246 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/50 {
  --tw-gradient-from: rgb(243 244 246 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/55 {
  --tw-gradient-from: rgb(243 244 246 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/60 {
  --tw-gradient-from: rgb(243 244 246 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/65 {
  --tw-gradient-from: rgb(243 244 246 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/70 {
  --tw-gradient-from: rgb(243 244 246 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/75 {
  --tw-gradient-from: rgb(243 244 246 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/80 {
  --tw-gradient-from: rgb(243 244 246 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/85 {
  --tw-gradient-from: rgb(243 244 246 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/90 {
  --tw-gradient-from: rgb(243 244 246 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-100\/95 {
  --tw-gradient-from: rgb(243 244 246 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200 {
  --tw-gradient-from: #e5e7eb var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/0 {
  --tw-gradient-from: rgb(229 231 235 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/10 {
  --tw-gradient-from: rgb(229 231 235 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/100 {
  --tw-gradient-from: rgb(229 231 235 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/15 {
  --tw-gradient-from: rgb(229 231 235 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/20 {
  --tw-gradient-from: rgb(229 231 235 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/25 {
  --tw-gradient-from: rgb(229 231 235 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/30 {
  --tw-gradient-from: rgb(229 231 235 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/35 {
  --tw-gradient-from: rgb(229 231 235 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/40 {
  --tw-gradient-from: rgb(229 231 235 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/45 {
  --tw-gradient-from: rgb(229 231 235 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/5 {
  --tw-gradient-from: rgb(229 231 235 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/50 {
  --tw-gradient-from: rgb(229 231 235 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/55 {
  --tw-gradient-from: rgb(229 231 235 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/60 {
  --tw-gradient-from: rgb(229 231 235 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/65 {
  --tw-gradient-from: rgb(229 231 235 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/70 {
  --tw-gradient-from: rgb(229 231 235 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/75 {
  --tw-gradient-from: rgb(229 231 235 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/80 {
  --tw-gradient-from: rgb(229 231 235 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/85 {
  --tw-gradient-from: rgb(229 231 235 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/90 {
  --tw-gradient-from: rgb(229 231 235 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-200\/95 {
  --tw-gradient-from: rgb(229 231 235 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300 {
  --tw-gradient-from: #d1d5db var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/0 {
  --tw-gradient-from: rgb(209 213 219 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/10 {
  --tw-gradient-from: rgb(209 213 219 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/100 {
  --tw-gradient-from: rgb(209 213 219 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/15 {
  --tw-gradient-from: rgb(209 213 219 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/20 {
  --tw-gradient-from: rgb(209 213 219 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/25 {
  --tw-gradient-from: rgb(209 213 219 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/30 {
  --tw-gradient-from: rgb(209 213 219 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/35 {
  --tw-gradient-from: rgb(209 213 219 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/40 {
  --tw-gradient-from: rgb(209 213 219 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/45 {
  --tw-gradient-from: rgb(209 213 219 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/5 {
  --tw-gradient-from: rgb(209 213 219 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/50 {
  --tw-gradient-from: rgb(209 213 219 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/55 {
  --tw-gradient-from: rgb(209 213 219 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/60 {
  --tw-gradient-from: rgb(209 213 219 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/65 {
  --tw-gradient-from: rgb(209 213 219 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/70 {
  --tw-gradient-from: rgb(209 213 219 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/75 {
  --tw-gradient-from: rgb(209 213 219 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/80 {
  --tw-gradient-from: rgb(209 213 219 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/85 {
  --tw-gradient-from: rgb(209 213 219 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/90 {
  --tw-gradient-from: rgb(209 213 219 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-300\/95 {
  --tw-gradient-from: rgb(209 213 219 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400 {
  --tw-gradient-from: #9ca3af var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/0 {
  --tw-gradient-from: rgb(156 163 175 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/10 {
  --tw-gradient-from: rgb(156 163 175 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/100 {
  --tw-gradient-from: rgb(156 163 175 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/15 {
  --tw-gradient-from: rgb(156 163 175 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/20 {
  --tw-gradient-from: rgb(156 163 175 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/25 {
  --tw-gradient-from: rgb(156 163 175 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/30 {
  --tw-gradient-from: rgb(156 163 175 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/35 {
  --tw-gradient-from: rgb(156 163 175 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/40 {
  --tw-gradient-from: rgb(156 163 175 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/45 {
  --tw-gradient-from: rgb(156 163 175 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/5 {
  --tw-gradient-from: rgb(156 163 175 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/50 {
  --tw-gradient-from: rgb(156 163 175 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/55 {
  --tw-gradient-from: rgb(156 163 175 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/60 {
  --tw-gradient-from: rgb(156 163 175 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/65 {
  --tw-gradient-from: rgb(156 163 175 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/70 {
  --tw-gradient-from: rgb(156 163 175 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/75 {
  --tw-gradient-from: rgb(156 163 175 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/80 {
  --tw-gradient-from: rgb(156 163 175 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/85 {
  --tw-gradient-from: rgb(156 163 175 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/90 {
  --tw-gradient-from: rgb(156 163 175 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-400\/95 {
  --tw-gradient-from: rgb(156 163 175 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50 {
  --tw-gradient-from: #f9fafb var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/0 {
  --tw-gradient-from: rgb(249 250 251 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/10 {
  --tw-gradient-from: rgb(249 250 251 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/100 {
  --tw-gradient-from: rgb(249 250 251 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/15 {
  --tw-gradient-from: rgb(249 250 251 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/20 {
  --tw-gradient-from: rgb(249 250 251 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/25 {
  --tw-gradient-from: rgb(249 250 251 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/30 {
  --tw-gradient-from: rgb(249 250 251 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/35 {
  --tw-gradient-from: rgb(249 250 251 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/40 {
  --tw-gradient-from: rgb(249 250 251 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/45 {
  --tw-gradient-from: rgb(249 250 251 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/5 {
  --tw-gradient-from: rgb(249 250 251 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/50 {
  --tw-gradient-from: rgb(249 250 251 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/55 {
  --tw-gradient-from: rgb(249 250 251 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/60 {
  --tw-gradient-from: rgb(249 250 251 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/65 {
  --tw-gradient-from: rgb(249 250 251 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/70 {
  --tw-gradient-from: rgb(249 250 251 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/75 {
  --tw-gradient-from: rgb(249 250 251 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/80 {
  --tw-gradient-from: rgb(249 250 251 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/85 {
  --tw-gradient-from: rgb(249 250 251 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/90 {
  --tw-gradient-from: rgb(249 250 251 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-50\/95 {
  --tw-gradient-from: rgb(249 250 251 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500 {
  --tw-gradient-from: #6b7280 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/0 {
  --tw-gradient-from: rgb(107 114 128 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/10 {
  --tw-gradient-from: rgb(107 114 128 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/100 {
  --tw-gradient-from: rgb(107 114 128 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/15 {
  --tw-gradient-from: rgb(107 114 128 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/20 {
  --tw-gradient-from: rgb(107 114 128 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/25 {
  --tw-gradient-from: rgb(107 114 128 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/30 {
  --tw-gradient-from: rgb(107 114 128 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/35 {
  --tw-gradient-from: rgb(107 114 128 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/40 {
  --tw-gradient-from: rgb(107 114 128 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/45 {
  --tw-gradient-from: rgb(107 114 128 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/5 {
  --tw-gradient-from: rgb(107 114 128 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/50 {
  --tw-gradient-from: rgb(107 114 128 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/55 {
  --tw-gradient-from: rgb(107 114 128 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/60 {
  --tw-gradient-from: rgb(107 114 128 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/65 {
  --tw-gradient-from: rgb(107 114 128 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/70 {
  --tw-gradient-from: rgb(107 114 128 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/75 {
  --tw-gradient-from: rgb(107 114 128 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/80 {
  --tw-gradient-from: rgb(107 114 128 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/85 {
  --tw-gradient-from: rgb(107 114 128 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/90 {
  --tw-gradient-from: rgb(107 114 128 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-500\/95 {
  --tw-gradient-from: rgb(107 114 128 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600 {
  --tw-gradient-from: #4b5563 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/0 {
  --tw-gradient-from: rgb(75 85 99 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/10 {
  --tw-gradient-from: rgb(75 85 99 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/100 {
  --tw-gradient-from: rgb(75 85 99 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/15 {
  --tw-gradient-from: rgb(75 85 99 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/20 {
  --tw-gradient-from: rgb(75 85 99 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/25 {
  --tw-gradient-from: rgb(75 85 99 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/30 {
  --tw-gradient-from: rgb(75 85 99 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/35 {
  --tw-gradient-from: rgb(75 85 99 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/40 {
  --tw-gradient-from: rgb(75 85 99 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/45 {
  --tw-gradient-from: rgb(75 85 99 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/5 {
  --tw-gradient-from: rgb(75 85 99 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/50 {
  --tw-gradient-from: rgb(75 85 99 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/55 {
  --tw-gradient-from: rgb(75 85 99 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/60 {
  --tw-gradient-from: rgb(75 85 99 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/65 {
  --tw-gradient-from: rgb(75 85 99 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/70 {
  --tw-gradient-from: rgb(75 85 99 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/75 {
  --tw-gradient-from: rgb(75 85 99 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/80 {
  --tw-gradient-from: rgb(75 85 99 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/85 {
  --tw-gradient-from: rgb(75 85 99 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/90 {
  --tw-gradient-from: rgb(75 85 99 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-600\/95 {
  --tw-gradient-from: rgb(75 85 99 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700 {
  --tw-gradient-from: #374151 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/0 {
  --tw-gradient-from: rgb(55 65 81 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/10 {
  --tw-gradient-from: rgb(55 65 81 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/100 {
  --tw-gradient-from: rgb(55 65 81 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/15 {
  --tw-gradient-from: rgb(55 65 81 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/20 {
  --tw-gradient-from: rgb(55 65 81 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/25 {
  --tw-gradient-from: rgb(55 65 81 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/30 {
  --tw-gradient-from: rgb(55 65 81 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/35 {
  --tw-gradient-from: rgb(55 65 81 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/40 {
  --tw-gradient-from: rgb(55 65 81 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/45 {
  --tw-gradient-from: rgb(55 65 81 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/5 {
  --tw-gradient-from: rgb(55 65 81 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/50 {
  --tw-gradient-from: rgb(55 65 81 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/55 {
  --tw-gradient-from: rgb(55 65 81 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/60 {
  --tw-gradient-from: rgb(55 65 81 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/65 {
  --tw-gradient-from: rgb(55 65 81 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/70 {
  --tw-gradient-from: rgb(55 65 81 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/75 {
  --tw-gradient-from: rgb(55 65 81 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/80 {
  --tw-gradient-from: rgb(55 65 81 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/85 {
  --tw-gradient-from: rgb(55 65 81 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/90 {
  --tw-gradient-from: rgb(55 65 81 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-700\/95 {
  --tw-gradient-from: rgb(55 65 81 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800 {
  --tw-gradient-from: #1f2937 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/0 {
  --tw-gradient-from: rgb(31 41 55 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/10 {
  --tw-gradient-from: rgb(31 41 55 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/100 {
  --tw-gradient-from: rgb(31 41 55 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/15 {
  --tw-gradient-from: rgb(31 41 55 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/20 {
  --tw-gradient-from: rgb(31 41 55 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/25 {
  --tw-gradient-from: rgb(31 41 55 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/30 {
  --tw-gradient-from: rgb(31 41 55 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/35 {
  --tw-gradient-from: rgb(31 41 55 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/40 {
  --tw-gradient-from: rgb(31 41 55 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/45 {
  --tw-gradient-from: rgb(31 41 55 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/5 {
  --tw-gradient-from: rgb(31 41 55 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/50 {
  --tw-gradient-from: rgb(31 41 55 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/55 {
  --tw-gradient-from: rgb(31 41 55 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/60 {
  --tw-gradient-from: rgb(31 41 55 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/65 {
  --tw-gradient-from: rgb(31 41 55 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/70 {
  --tw-gradient-from: rgb(31 41 55 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/75 {
  --tw-gradient-from: rgb(31 41 55 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/80 {
  --tw-gradient-from: rgb(31 41 55 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/85 {
  --tw-gradient-from: rgb(31 41 55 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/90 {
  --tw-gradient-from: rgb(31 41 55 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-800\/95 {
  --tw-gradient-from: rgb(31 41 55 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900 {
  --tw-gradient-from: #111827 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/0 {
  --tw-gradient-from: rgb(17 24 39 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/10 {
  --tw-gradient-from: rgb(17 24 39 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/100 {
  --tw-gradient-from: rgb(17 24 39 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/15 {
  --tw-gradient-from: rgb(17 24 39 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/20 {
  --tw-gradient-from: rgb(17 24 39 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/25 {
  --tw-gradient-from: rgb(17 24 39 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/30 {
  --tw-gradient-from: rgb(17 24 39 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/35 {
  --tw-gradient-from: rgb(17 24 39 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/40 {
  --tw-gradient-from: rgb(17 24 39 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/45 {
  --tw-gradient-from: rgb(17 24 39 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/5 {
  --tw-gradient-from: rgb(17 24 39 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/50 {
  --tw-gradient-from: rgb(17 24 39 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/55 {
  --tw-gradient-from: rgb(17 24 39 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/60 {
  --tw-gradient-from: rgb(17 24 39 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/65 {
  --tw-gradient-from: rgb(17 24 39 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/70 {
  --tw-gradient-from: rgb(17 24 39 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/75 {
  --tw-gradient-from: rgb(17 24 39 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/80 {
  --tw-gradient-from: rgb(17 24 39 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/85 {
  --tw-gradient-from: rgb(17 24 39 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/90 {
  --tw-gradient-from: rgb(17 24 39 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-900\/95 {
  --tw-gradient-from: rgb(17 24 39 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950 {
  --tw-gradient-from: #030712 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/0 {
  --tw-gradient-from: rgb(3 7 18 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/10 {
  --tw-gradient-from: rgb(3 7 18 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/100 {
  --tw-gradient-from: rgb(3 7 18 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/15 {
  --tw-gradient-from: rgb(3 7 18 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/20 {
  --tw-gradient-from: rgb(3 7 18 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/25 {
  --tw-gradient-from: rgb(3 7 18 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/30 {
  --tw-gradient-from: rgb(3 7 18 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/35 {
  --tw-gradient-from: rgb(3 7 18 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/40 {
  --tw-gradient-from: rgb(3 7 18 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/45 {
  --tw-gradient-from: rgb(3 7 18 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/5 {
  --tw-gradient-from: rgb(3 7 18 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/50 {
  --tw-gradient-from: rgb(3 7 18 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/55 {
  --tw-gradient-from: rgb(3 7 18 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/60 {
  --tw-gradient-from: rgb(3 7 18 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/65 {
  --tw-gradient-from: rgb(3 7 18 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/70 {
  --tw-gradient-from: rgb(3 7 18 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/75 {
  --tw-gradient-from: rgb(3 7 18 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/80 {
  --tw-gradient-from: rgb(3 7 18 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/85 {
  --tw-gradient-from: rgb(3 7 18 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/90 {
  --tw-gradient-from: rgb(3 7 18 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-gray-950\/95 {
  --tw-gradient-from: rgb(3 7 18 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100 {
  --tw-gradient-from: #dcfce7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/0 {
  --tw-gradient-from: rgb(220 252 231 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/10 {
  --tw-gradient-from: rgb(220 252 231 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/100 {
  --tw-gradient-from: rgb(220 252 231 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/15 {
  --tw-gradient-from: rgb(220 252 231 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/20 {
  --tw-gradient-from: rgb(220 252 231 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/25 {
  --tw-gradient-from: rgb(220 252 231 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/30 {
  --tw-gradient-from: rgb(220 252 231 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/35 {
  --tw-gradient-from: rgb(220 252 231 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/40 {
  --tw-gradient-from: rgb(220 252 231 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/45 {
  --tw-gradient-from: rgb(220 252 231 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/5 {
  --tw-gradient-from: rgb(220 252 231 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/50 {
  --tw-gradient-from: rgb(220 252 231 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/55 {
  --tw-gradient-from: rgb(220 252 231 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/60 {
  --tw-gradient-from: rgb(220 252 231 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/65 {
  --tw-gradient-from: rgb(220 252 231 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/70 {
  --tw-gradient-from: rgb(220 252 231 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/75 {
  --tw-gradient-from: rgb(220 252 231 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/80 {
  --tw-gradient-from: rgb(220 252 231 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/85 {
  --tw-gradient-from: rgb(220 252 231 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/90 {
  --tw-gradient-from: rgb(220 252 231 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-100\/95 {
  --tw-gradient-from: rgb(220 252 231 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200 {
  --tw-gradient-from: #bbf7d0 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/0 {
  --tw-gradient-from: rgb(187 247 208 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/10 {
  --tw-gradient-from: rgb(187 247 208 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/100 {
  --tw-gradient-from: rgb(187 247 208 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/15 {
  --tw-gradient-from: rgb(187 247 208 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/20 {
  --tw-gradient-from: rgb(187 247 208 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/25 {
  --tw-gradient-from: rgb(187 247 208 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/30 {
  --tw-gradient-from: rgb(187 247 208 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/35 {
  --tw-gradient-from: rgb(187 247 208 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/40 {
  --tw-gradient-from: rgb(187 247 208 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/45 {
  --tw-gradient-from: rgb(187 247 208 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/5 {
  --tw-gradient-from: rgb(187 247 208 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/50 {
  --tw-gradient-from: rgb(187 247 208 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/55 {
  --tw-gradient-from: rgb(187 247 208 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/60 {
  --tw-gradient-from: rgb(187 247 208 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/65 {
  --tw-gradient-from: rgb(187 247 208 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/70 {
  --tw-gradient-from: rgb(187 247 208 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/75 {
  --tw-gradient-from: rgb(187 247 208 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/80 {
  --tw-gradient-from: rgb(187 247 208 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/85 {
  --tw-gradient-from: rgb(187 247 208 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/90 {
  --tw-gradient-from: rgb(187 247 208 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-200\/95 {
  --tw-gradient-from: rgb(187 247 208 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300 {
  --tw-gradient-from: #86efac var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/0 {
  --tw-gradient-from: rgb(134 239 172 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/10 {
  --tw-gradient-from: rgb(134 239 172 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/100 {
  --tw-gradient-from: rgb(134 239 172 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/15 {
  --tw-gradient-from: rgb(134 239 172 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/20 {
  --tw-gradient-from: rgb(134 239 172 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/25 {
  --tw-gradient-from: rgb(134 239 172 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/30 {
  --tw-gradient-from: rgb(134 239 172 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/35 {
  --tw-gradient-from: rgb(134 239 172 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/40 {
  --tw-gradient-from: rgb(134 239 172 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/45 {
  --tw-gradient-from: rgb(134 239 172 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/5 {
  --tw-gradient-from: rgb(134 239 172 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/50 {
  --tw-gradient-from: rgb(134 239 172 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/55 {
  --tw-gradient-from: rgb(134 239 172 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/60 {
  --tw-gradient-from: rgb(134 239 172 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/65 {
  --tw-gradient-from: rgb(134 239 172 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/70 {
  --tw-gradient-from: rgb(134 239 172 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/75 {
  --tw-gradient-from: rgb(134 239 172 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/80 {
  --tw-gradient-from: rgb(134 239 172 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/85 {
  --tw-gradient-from: rgb(134 239 172 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/90 {
  --tw-gradient-from: rgb(134 239 172 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-300\/95 {
  --tw-gradient-from: rgb(134 239 172 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400 {
  --tw-gradient-from: #4ade80 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/0 {
  --tw-gradient-from: rgb(74 222 128 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/10 {
  --tw-gradient-from: rgb(74 222 128 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/100 {
  --tw-gradient-from: rgb(74 222 128 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/15 {
  --tw-gradient-from: rgb(74 222 128 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/20 {
  --tw-gradient-from: rgb(74 222 128 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/25 {
  --tw-gradient-from: rgb(74 222 128 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/30 {
  --tw-gradient-from: rgb(74 222 128 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/35 {
  --tw-gradient-from: rgb(74 222 128 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/40 {
  --tw-gradient-from: rgb(74 222 128 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/45 {
  --tw-gradient-from: rgb(74 222 128 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/5 {
  --tw-gradient-from: rgb(74 222 128 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/50 {
  --tw-gradient-from: rgb(74 222 128 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/55 {
  --tw-gradient-from: rgb(74 222 128 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/60 {
  --tw-gradient-from: rgb(74 222 128 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/65 {
  --tw-gradient-from: rgb(74 222 128 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/70 {
  --tw-gradient-from: rgb(74 222 128 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/75 {
  --tw-gradient-from: rgb(74 222 128 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/80 {
  --tw-gradient-from: rgb(74 222 128 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/85 {
  --tw-gradient-from: rgb(74 222 128 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/90 {
  --tw-gradient-from: rgb(74 222 128 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-400\/95 {
  --tw-gradient-from: rgb(74 222 128 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50 {
  --tw-gradient-from: #f0fdf4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/0 {
  --tw-gradient-from: rgb(240 253 244 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/10 {
  --tw-gradient-from: rgb(240 253 244 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/100 {
  --tw-gradient-from: rgb(240 253 244 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/15 {
  --tw-gradient-from: rgb(240 253 244 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/20 {
  --tw-gradient-from: rgb(240 253 244 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/25 {
  --tw-gradient-from: rgb(240 253 244 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/30 {
  --tw-gradient-from: rgb(240 253 244 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/35 {
  --tw-gradient-from: rgb(240 253 244 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/40 {
  --tw-gradient-from: rgb(240 253 244 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/45 {
  --tw-gradient-from: rgb(240 253 244 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/5 {
  --tw-gradient-from: rgb(240 253 244 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/50 {
  --tw-gradient-from: rgb(240 253 244 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/55 {
  --tw-gradient-from: rgb(240 253 244 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/60 {
  --tw-gradient-from: rgb(240 253 244 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/65 {
  --tw-gradient-from: rgb(240 253 244 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/70 {
  --tw-gradient-from: rgb(240 253 244 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/75 {
  --tw-gradient-from: rgb(240 253 244 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/80 {
  --tw-gradient-from: rgb(240 253 244 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/85 {
  --tw-gradient-from: rgb(240 253 244 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/90 {
  --tw-gradient-from: rgb(240 253 244 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-50\/95 {
  --tw-gradient-from: rgb(240 253 244 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500 {
  --tw-gradient-from: #22c55e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/0 {
  --tw-gradient-from: rgb(34 197 94 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/10 {
  --tw-gradient-from: rgb(34 197 94 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/100 {
  --tw-gradient-from: rgb(34 197 94 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/15 {
  --tw-gradient-from: rgb(34 197 94 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/20 {
  --tw-gradient-from: rgb(34 197 94 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/25 {
  --tw-gradient-from: rgb(34 197 94 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/30 {
  --tw-gradient-from: rgb(34 197 94 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/35 {
  --tw-gradient-from: rgb(34 197 94 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/40 {
  --tw-gradient-from: rgb(34 197 94 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/45 {
  --tw-gradient-from: rgb(34 197 94 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/5 {
  --tw-gradient-from: rgb(34 197 94 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/50 {
  --tw-gradient-from: rgb(34 197 94 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/55 {
  --tw-gradient-from: rgb(34 197 94 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/60 {
  --tw-gradient-from: rgb(34 197 94 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/65 {
  --tw-gradient-from: rgb(34 197 94 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/70 {
  --tw-gradient-from: rgb(34 197 94 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/75 {
  --tw-gradient-from: rgb(34 197 94 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/80 {
  --tw-gradient-from: rgb(34 197 94 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/85 {
  --tw-gradient-from: rgb(34 197 94 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/90 {
  --tw-gradient-from: rgb(34 197 94 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-500\/95 {
  --tw-gradient-from: rgb(34 197 94 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600 {
  --tw-gradient-from: #16a34a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/0 {
  --tw-gradient-from: rgb(22 163 74 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/10 {
  --tw-gradient-from: rgb(22 163 74 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/100 {
  --tw-gradient-from: rgb(22 163 74 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/15 {
  --tw-gradient-from: rgb(22 163 74 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/20 {
  --tw-gradient-from: rgb(22 163 74 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/25 {
  --tw-gradient-from: rgb(22 163 74 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/30 {
  --tw-gradient-from: rgb(22 163 74 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/35 {
  --tw-gradient-from: rgb(22 163 74 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/40 {
  --tw-gradient-from: rgb(22 163 74 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/45 {
  --tw-gradient-from: rgb(22 163 74 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/5 {
  --tw-gradient-from: rgb(22 163 74 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/50 {
  --tw-gradient-from: rgb(22 163 74 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/55 {
  --tw-gradient-from: rgb(22 163 74 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/60 {
  --tw-gradient-from: rgb(22 163 74 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/65 {
  --tw-gradient-from: rgb(22 163 74 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/70 {
  --tw-gradient-from: rgb(22 163 74 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/75 {
  --tw-gradient-from: rgb(22 163 74 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/80 {
  --tw-gradient-from: rgb(22 163 74 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/85 {
  --tw-gradient-from: rgb(22 163 74 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/90 {
  --tw-gradient-from: rgb(22 163 74 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-600\/95 {
  --tw-gradient-from: rgb(22 163 74 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700 {
  --tw-gradient-from: #15803d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/0 {
  --tw-gradient-from: rgb(21 128 61 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/10 {
  --tw-gradient-from: rgb(21 128 61 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/100 {
  --tw-gradient-from: rgb(21 128 61 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/15 {
  --tw-gradient-from: rgb(21 128 61 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/20 {
  --tw-gradient-from: rgb(21 128 61 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/25 {
  --tw-gradient-from: rgb(21 128 61 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/30 {
  --tw-gradient-from: rgb(21 128 61 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/35 {
  --tw-gradient-from: rgb(21 128 61 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/40 {
  --tw-gradient-from: rgb(21 128 61 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/45 {
  --tw-gradient-from: rgb(21 128 61 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/5 {
  --tw-gradient-from: rgb(21 128 61 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/50 {
  --tw-gradient-from: rgb(21 128 61 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/55 {
  --tw-gradient-from: rgb(21 128 61 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/60 {
  --tw-gradient-from: rgb(21 128 61 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/65 {
  --tw-gradient-from: rgb(21 128 61 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/70 {
  --tw-gradient-from: rgb(21 128 61 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/75 {
  --tw-gradient-from: rgb(21 128 61 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/80 {
  --tw-gradient-from: rgb(21 128 61 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/85 {
  --tw-gradient-from: rgb(21 128 61 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/90 {
  --tw-gradient-from: rgb(21 128 61 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-700\/95 {
  --tw-gradient-from: rgb(21 128 61 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800 {
  --tw-gradient-from: #166534 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/0 {
  --tw-gradient-from: rgb(22 101 52 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/10 {
  --tw-gradient-from: rgb(22 101 52 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/100 {
  --tw-gradient-from: rgb(22 101 52 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/15 {
  --tw-gradient-from: rgb(22 101 52 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/20 {
  --tw-gradient-from: rgb(22 101 52 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/25 {
  --tw-gradient-from: rgb(22 101 52 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/30 {
  --tw-gradient-from: rgb(22 101 52 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/35 {
  --tw-gradient-from: rgb(22 101 52 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/40 {
  --tw-gradient-from: rgb(22 101 52 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/45 {
  --tw-gradient-from: rgb(22 101 52 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/5 {
  --tw-gradient-from: rgb(22 101 52 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/50 {
  --tw-gradient-from: rgb(22 101 52 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/55 {
  --tw-gradient-from: rgb(22 101 52 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/60 {
  --tw-gradient-from: rgb(22 101 52 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/65 {
  --tw-gradient-from: rgb(22 101 52 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/70 {
  --tw-gradient-from: rgb(22 101 52 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/75 {
  --tw-gradient-from: rgb(22 101 52 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/80 {
  --tw-gradient-from: rgb(22 101 52 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/85 {
  --tw-gradient-from: rgb(22 101 52 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/90 {
  --tw-gradient-from: rgb(22 101 52 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-800\/95 {
  --tw-gradient-from: rgb(22 101 52 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900 {
  --tw-gradient-from: #14532d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/0 {
  --tw-gradient-from: rgb(20 83 45 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/10 {
  --tw-gradient-from: rgb(20 83 45 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/100 {
  --tw-gradient-from: rgb(20 83 45 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/15 {
  --tw-gradient-from: rgb(20 83 45 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/20 {
  --tw-gradient-from: rgb(20 83 45 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/25 {
  --tw-gradient-from: rgb(20 83 45 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/30 {
  --tw-gradient-from: rgb(20 83 45 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/35 {
  --tw-gradient-from: rgb(20 83 45 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/40 {
  --tw-gradient-from: rgb(20 83 45 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/45 {
  --tw-gradient-from: rgb(20 83 45 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/5 {
  --tw-gradient-from: rgb(20 83 45 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/50 {
  --tw-gradient-from: rgb(20 83 45 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/55 {
  --tw-gradient-from: rgb(20 83 45 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/60 {
  --tw-gradient-from: rgb(20 83 45 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/65 {
  --tw-gradient-from: rgb(20 83 45 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/70 {
  --tw-gradient-from: rgb(20 83 45 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/75 {
  --tw-gradient-from: rgb(20 83 45 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/80 {
  --tw-gradient-from: rgb(20 83 45 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/85 {
  --tw-gradient-from: rgb(20 83 45 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/90 {
  --tw-gradient-from: rgb(20 83 45 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-900\/95 {
  --tw-gradient-from: rgb(20 83 45 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950 {
  --tw-gradient-from: #052e16 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/0 {
  --tw-gradient-from: rgb(5 46 22 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/10 {
  --tw-gradient-from: rgb(5 46 22 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/100 {
  --tw-gradient-from: rgb(5 46 22 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/15 {
  --tw-gradient-from: rgb(5 46 22 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/20 {
  --tw-gradient-from: rgb(5 46 22 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/25 {
  --tw-gradient-from: rgb(5 46 22 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/30 {
  --tw-gradient-from: rgb(5 46 22 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/35 {
  --tw-gradient-from: rgb(5 46 22 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/40 {
  --tw-gradient-from: rgb(5 46 22 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/45 {
  --tw-gradient-from: rgb(5 46 22 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/5 {
  --tw-gradient-from: rgb(5 46 22 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/50 {
  --tw-gradient-from: rgb(5 46 22 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/55 {
  --tw-gradient-from: rgb(5 46 22 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/60 {
  --tw-gradient-from: rgb(5 46 22 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/65 {
  --tw-gradient-from: rgb(5 46 22 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/70 {
  --tw-gradient-from: rgb(5 46 22 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/75 {
  --tw-gradient-from: rgb(5 46 22 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/80 {
  --tw-gradient-from: rgb(5 46 22 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/85 {
  --tw-gradient-from: rgb(5 46 22 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/90 {
  --tw-gradient-from: rgb(5 46 22 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-green-950\/95 {
  --tw-gradient-from: rgb(5 46 22 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100 {
  --tw-gradient-from: #e0e7ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/0 {
  --tw-gradient-from: rgb(224 231 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/10 {
  --tw-gradient-from: rgb(224 231 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/100 {
  --tw-gradient-from: rgb(224 231 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/15 {
  --tw-gradient-from: rgb(224 231 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/20 {
  --tw-gradient-from: rgb(224 231 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/25 {
  --tw-gradient-from: rgb(224 231 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/30 {
  --tw-gradient-from: rgb(224 231 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/35 {
  --tw-gradient-from: rgb(224 231 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/40 {
  --tw-gradient-from: rgb(224 231 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/45 {
  --tw-gradient-from: rgb(224 231 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/5 {
  --tw-gradient-from: rgb(224 231 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/50 {
  --tw-gradient-from: rgb(224 231 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/55 {
  --tw-gradient-from: rgb(224 231 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/60 {
  --tw-gradient-from: rgb(224 231 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/65 {
  --tw-gradient-from: rgb(224 231 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/70 {
  --tw-gradient-from: rgb(224 231 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/75 {
  --tw-gradient-from: rgb(224 231 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/80 {
  --tw-gradient-from: rgb(224 231 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/85 {
  --tw-gradient-from: rgb(224 231 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/90 {
  --tw-gradient-from: rgb(224 231 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-100\/95 {
  --tw-gradient-from: rgb(224 231 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200 {
  --tw-gradient-from: #c7d2fe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/0 {
  --tw-gradient-from: rgb(199 210 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/10 {
  --tw-gradient-from: rgb(199 210 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/100 {
  --tw-gradient-from: rgb(199 210 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/15 {
  --tw-gradient-from: rgb(199 210 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/20 {
  --tw-gradient-from: rgb(199 210 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/25 {
  --tw-gradient-from: rgb(199 210 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/30 {
  --tw-gradient-from: rgb(199 210 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/35 {
  --tw-gradient-from: rgb(199 210 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/40 {
  --tw-gradient-from: rgb(199 210 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/45 {
  --tw-gradient-from: rgb(199 210 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/5 {
  --tw-gradient-from: rgb(199 210 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/50 {
  --tw-gradient-from: rgb(199 210 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/55 {
  --tw-gradient-from: rgb(199 210 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/60 {
  --tw-gradient-from: rgb(199 210 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/65 {
  --tw-gradient-from: rgb(199 210 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/70 {
  --tw-gradient-from: rgb(199 210 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/75 {
  --tw-gradient-from: rgb(199 210 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/80 {
  --tw-gradient-from: rgb(199 210 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/85 {
  --tw-gradient-from: rgb(199 210 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/90 {
  --tw-gradient-from: rgb(199 210 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-200\/95 {
  --tw-gradient-from: rgb(199 210 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300 {
  --tw-gradient-from: #a5b4fc var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/0 {
  --tw-gradient-from: rgb(165 180 252 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/10 {
  --tw-gradient-from: rgb(165 180 252 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/100 {
  --tw-gradient-from: rgb(165 180 252 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/15 {
  --tw-gradient-from: rgb(165 180 252 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/20 {
  --tw-gradient-from: rgb(165 180 252 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/25 {
  --tw-gradient-from: rgb(165 180 252 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/30 {
  --tw-gradient-from: rgb(165 180 252 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/35 {
  --tw-gradient-from: rgb(165 180 252 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/40 {
  --tw-gradient-from: rgb(165 180 252 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/45 {
  --tw-gradient-from: rgb(165 180 252 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/5 {
  --tw-gradient-from: rgb(165 180 252 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/50 {
  --tw-gradient-from: rgb(165 180 252 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/55 {
  --tw-gradient-from: rgb(165 180 252 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/60 {
  --tw-gradient-from: rgb(165 180 252 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/65 {
  --tw-gradient-from: rgb(165 180 252 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/70 {
  --tw-gradient-from: rgb(165 180 252 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/75 {
  --tw-gradient-from: rgb(165 180 252 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/80 {
  --tw-gradient-from: rgb(165 180 252 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/85 {
  --tw-gradient-from: rgb(165 180 252 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/90 {
  --tw-gradient-from: rgb(165 180 252 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-300\/95 {
  --tw-gradient-from: rgb(165 180 252 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400 {
  --tw-gradient-from: #818cf8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/0 {
  --tw-gradient-from: rgb(129 140 248 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/10 {
  --tw-gradient-from: rgb(129 140 248 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/100 {
  --tw-gradient-from: rgb(129 140 248 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/15 {
  --tw-gradient-from: rgb(129 140 248 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/20 {
  --tw-gradient-from: rgb(129 140 248 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/25 {
  --tw-gradient-from: rgb(129 140 248 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/30 {
  --tw-gradient-from: rgb(129 140 248 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/35 {
  --tw-gradient-from: rgb(129 140 248 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/40 {
  --tw-gradient-from: rgb(129 140 248 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/45 {
  --tw-gradient-from: rgb(129 140 248 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/5 {
  --tw-gradient-from: rgb(129 140 248 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/50 {
  --tw-gradient-from: rgb(129 140 248 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/55 {
  --tw-gradient-from: rgb(129 140 248 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/60 {
  --tw-gradient-from: rgb(129 140 248 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/65 {
  --tw-gradient-from: rgb(129 140 248 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/70 {
  --tw-gradient-from: rgb(129 140 248 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/75 {
  --tw-gradient-from: rgb(129 140 248 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/80 {
  --tw-gradient-from: rgb(129 140 248 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/85 {
  --tw-gradient-from: rgb(129 140 248 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/90 {
  --tw-gradient-from: rgb(129 140 248 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-400\/95 {
  --tw-gradient-from: rgb(129 140 248 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50 {
  --tw-gradient-from: #eef2ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/0 {
  --tw-gradient-from: rgb(238 242 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/10 {
  --tw-gradient-from: rgb(238 242 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/100 {
  --tw-gradient-from: rgb(238 242 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/15 {
  --tw-gradient-from: rgb(238 242 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/20 {
  --tw-gradient-from: rgb(238 242 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/25 {
  --tw-gradient-from: rgb(238 242 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/30 {
  --tw-gradient-from: rgb(238 242 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/35 {
  --tw-gradient-from: rgb(238 242 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/40 {
  --tw-gradient-from: rgb(238 242 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/45 {
  --tw-gradient-from: rgb(238 242 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/5 {
  --tw-gradient-from: rgb(238 242 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/50 {
  --tw-gradient-from: rgb(238 242 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/55 {
  --tw-gradient-from: rgb(238 242 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/60 {
  --tw-gradient-from: rgb(238 242 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/65 {
  --tw-gradient-from: rgb(238 242 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/70 {
  --tw-gradient-from: rgb(238 242 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/75 {
  --tw-gradient-from: rgb(238 242 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/80 {
  --tw-gradient-from: rgb(238 242 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/85 {
  --tw-gradient-from: rgb(238 242 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/90 {
  --tw-gradient-from: rgb(238 242 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-50\/95 {
  --tw-gradient-from: rgb(238 242 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500 {
  --tw-gradient-from: #6366f1 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/0 {
  --tw-gradient-from: rgb(99 102 241 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/10 {
  --tw-gradient-from: rgb(99 102 241 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/100 {
  --tw-gradient-from: rgb(99 102 241 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/15 {
  --tw-gradient-from: rgb(99 102 241 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/20 {
  --tw-gradient-from: rgb(99 102 241 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/25 {
  --tw-gradient-from: rgb(99 102 241 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/30 {
  --tw-gradient-from: rgb(99 102 241 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/35 {
  --tw-gradient-from: rgb(99 102 241 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/40 {
  --tw-gradient-from: rgb(99 102 241 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/45 {
  --tw-gradient-from: rgb(99 102 241 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/5 {
  --tw-gradient-from: rgb(99 102 241 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/50 {
  --tw-gradient-from: rgb(99 102 241 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/55 {
  --tw-gradient-from: rgb(99 102 241 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/60 {
  --tw-gradient-from: rgb(99 102 241 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/65 {
  --tw-gradient-from: rgb(99 102 241 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/70 {
  --tw-gradient-from: rgb(99 102 241 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/75 {
  --tw-gradient-from: rgb(99 102 241 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/80 {
  --tw-gradient-from: rgb(99 102 241 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/85 {
  --tw-gradient-from: rgb(99 102 241 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/90 {
  --tw-gradient-from: rgb(99 102 241 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-500\/95 {
  --tw-gradient-from: rgb(99 102 241 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600 {
  --tw-gradient-from: #4f46e5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/0 {
  --tw-gradient-from: rgb(79 70 229 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/10 {
  --tw-gradient-from: rgb(79 70 229 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/100 {
  --tw-gradient-from: rgb(79 70 229 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/15 {
  --tw-gradient-from: rgb(79 70 229 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/20 {
  --tw-gradient-from: rgb(79 70 229 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/25 {
  --tw-gradient-from: rgb(79 70 229 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/30 {
  --tw-gradient-from: rgb(79 70 229 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/35 {
  --tw-gradient-from: rgb(79 70 229 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/40 {
  --tw-gradient-from: rgb(79 70 229 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/45 {
  --tw-gradient-from: rgb(79 70 229 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/5 {
  --tw-gradient-from: rgb(79 70 229 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/50 {
  --tw-gradient-from: rgb(79 70 229 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/55 {
  --tw-gradient-from: rgb(79 70 229 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/60 {
  --tw-gradient-from: rgb(79 70 229 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/65 {
  --tw-gradient-from: rgb(79 70 229 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/70 {
  --tw-gradient-from: rgb(79 70 229 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/75 {
  --tw-gradient-from: rgb(79 70 229 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/80 {
  --tw-gradient-from: rgb(79 70 229 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/85 {
  --tw-gradient-from: rgb(79 70 229 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/90 {
  --tw-gradient-from: rgb(79 70 229 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-600\/95 {
  --tw-gradient-from: rgb(79 70 229 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700 {
  --tw-gradient-from: #4338ca var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/0 {
  --tw-gradient-from: rgb(67 56 202 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/10 {
  --tw-gradient-from: rgb(67 56 202 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/100 {
  --tw-gradient-from: rgb(67 56 202 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/15 {
  --tw-gradient-from: rgb(67 56 202 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/20 {
  --tw-gradient-from: rgb(67 56 202 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/25 {
  --tw-gradient-from: rgb(67 56 202 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/30 {
  --tw-gradient-from: rgb(67 56 202 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/35 {
  --tw-gradient-from: rgb(67 56 202 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/40 {
  --tw-gradient-from: rgb(67 56 202 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/45 {
  --tw-gradient-from: rgb(67 56 202 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/5 {
  --tw-gradient-from: rgb(67 56 202 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/50 {
  --tw-gradient-from: rgb(67 56 202 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/55 {
  --tw-gradient-from: rgb(67 56 202 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/60 {
  --tw-gradient-from: rgb(67 56 202 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/65 {
  --tw-gradient-from: rgb(67 56 202 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/70 {
  --tw-gradient-from: rgb(67 56 202 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/75 {
  --tw-gradient-from: rgb(67 56 202 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/80 {
  --tw-gradient-from: rgb(67 56 202 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/85 {
  --tw-gradient-from: rgb(67 56 202 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/90 {
  --tw-gradient-from: rgb(67 56 202 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-700\/95 {
  --tw-gradient-from: rgb(67 56 202 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800 {
  --tw-gradient-from: #3730a3 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/0 {
  --tw-gradient-from: rgb(55 48 163 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/10 {
  --tw-gradient-from: rgb(55 48 163 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/100 {
  --tw-gradient-from: rgb(55 48 163 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/15 {
  --tw-gradient-from: rgb(55 48 163 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/20 {
  --tw-gradient-from: rgb(55 48 163 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/25 {
  --tw-gradient-from: rgb(55 48 163 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/30 {
  --tw-gradient-from: rgb(55 48 163 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/35 {
  --tw-gradient-from: rgb(55 48 163 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/40 {
  --tw-gradient-from: rgb(55 48 163 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/45 {
  --tw-gradient-from: rgb(55 48 163 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/5 {
  --tw-gradient-from: rgb(55 48 163 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/50 {
  --tw-gradient-from: rgb(55 48 163 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/55 {
  --tw-gradient-from: rgb(55 48 163 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/60 {
  --tw-gradient-from: rgb(55 48 163 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/65 {
  --tw-gradient-from: rgb(55 48 163 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/70 {
  --tw-gradient-from: rgb(55 48 163 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/75 {
  --tw-gradient-from: rgb(55 48 163 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/80 {
  --tw-gradient-from: rgb(55 48 163 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/85 {
  --tw-gradient-from: rgb(55 48 163 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/90 {
  --tw-gradient-from: rgb(55 48 163 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-800\/95 {
  --tw-gradient-from: rgb(55 48 163 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900 {
  --tw-gradient-from: #312e81 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/0 {
  --tw-gradient-from: rgb(49 46 129 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/10 {
  --tw-gradient-from: rgb(49 46 129 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/100 {
  --tw-gradient-from: rgb(49 46 129 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/15 {
  --tw-gradient-from: rgb(49 46 129 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/20 {
  --tw-gradient-from: rgb(49 46 129 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/25 {
  --tw-gradient-from: rgb(49 46 129 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/30 {
  --tw-gradient-from: rgb(49 46 129 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/35 {
  --tw-gradient-from: rgb(49 46 129 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/40 {
  --tw-gradient-from: rgb(49 46 129 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/45 {
  --tw-gradient-from: rgb(49 46 129 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/5 {
  --tw-gradient-from: rgb(49 46 129 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/50 {
  --tw-gradient-from: rgb(49 46 129 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/55 {
  --tw-gradient-from: rgb(49 46 129 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/60 {
  --tw-gradient-from: rgb(49 46 129 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/65 {
  --tw-gradient-from: rgb(49 46 129 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/70 {
  --tw-gradient-from: rgb(49 46 129 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/75 {
  --tw-gradient-from: rgb(49 46 129 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/80 {
  --tw-gradient-from: rgb(49 46 129 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/85 {
  --tw-gradient-from: rgb(49 46 129 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/90 {
  --tw-gradient-from: rgb(49 46 129 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-900\/95 {
  --tw-gradient-from: rgb(49 46 129 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950 {
  --tw-gradient-from: #1e1b4b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/0 {
  --tw-gradient-from: rgb(30 27 75 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/10 {
  --tw-gradient-from: rgb(30 27 75 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/100 {
  --tw-gradient-from: rgb(30 27 75 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/15 {
  --tw-gradient-from: rgb(30 27 75 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/20 {
  --tw-gradient-from: rgb(30 27 75 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/25 {
  --tw-gradient-from: rgb(30 27 75 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/30 {
  --tw-gradient-from: rgb(30 27 75 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/35 {
  --tw-gradient-from: rgb(30 27 75 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/40 {
  --tw-gradient-from: rgb(30 27 75 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/45 {
  --tw-gradient-from: rgb(30 27 75 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/5 {
  --tw-gradient-from: rgb(30 27 75 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/50 {
  --tw-gradient-from: rgb(30 27 75 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/55 {
  --tw-gradient-from: rgb(30 27 75 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/60 {
  --tw-gradient-from: rgb(30 27 75 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/65 {
  --tw-gradient-from: rgb(30 27 75 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/70 {
  --tw-gradient-from: rgb(30 27 75 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/75 {
  --tw-gradient-from: rgb(30 27 75 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/80 {
  --tw-gradient-from: rgb(30 27 75 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/85 {
  --tw-gradient-from: rgb(30 27 75 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/90 {
  --tw-gradient-from: rgb(30 27 75 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-indigo-950\/95 {
  --tw-gradient-from: rgb(30 27 75 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-inherit {
  --tw-gradient-from: inherit var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100 {
  --tw-gradient-from: #ecfccb var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/0 {
  --tw-gradient-from: rgb(236 252 203 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/10 {
  --tw-gradient-from: rgb(236 252 203 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/100 {
  --tw-gradient-from: rgb(236 252 203 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/15 {
  --tw-gradient-from: rgb(236 252 203 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/20 {
  --tw-gradient-from: rgb(236 252 203 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/25 {
  --tw-gradient-from: rgb(236 252 203 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/30 {
  --tw-gradient-from: rgb(236 252 203 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/35 {
  --tw-gradient-from: rgb(236 252 203 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/40 {
  --tw-gradient-from: rgb(236 252 203 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/45 {
  --tw-gradient-from: rgb(236 252 203 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/5 {
  --tw-gradient-from: rgb(236 252 203 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/50 {
  --tw-gradient-from: rgb(236 252 203 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/55 {
  --tw-gradient-from: rgb(236 252 203 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/60 {
  --tw-gradient-from: rgb(236 252 203 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/65 {
  --tw-gradient-from: rgb(236 252 203 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/70 {
  --tw-gradient-from: rgb(236 252 203 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/75 {
  --tw-gradient-from: rgb(236 252 203 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/80 {
  --tw-gradient-from: rgb(236 252 203 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/85 {
  --tw-gradient-from: rgb(236 252 203 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/90 {
  --tw-gradient-from: rgb(236 252 203 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-100\/95 {
  --tw-gradient-from: rgb(236 252 203 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200 {
  --tw-gradient-from: #d9f99d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/0 {
  --tw-gradient-from: rgb(217 249 157 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/10 {
  --tw-gradient-from: rgb(217 249 157 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/100 {
  --tw-gradient-from: rgb(217 249 157 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/15 {
  --tw-gradient-from: rgb(217 249 157 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/20 {
  --tw-gradient-from: rgb(217 249 157 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/25 {
  --tw-gradient-from: rgb(217 249 157 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/30 {
  --tw-gradient-from: rgb(217 249 157 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/35 {
  --tw-gradient-from: rgb(217 249 157 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/40 {
  --tw-gradient-from: rgb(217 249 157 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/45 {
  --tw-gradient-from: rgb(217 249 157 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/5 {
  --tw-gradient-from: rgb(217 249 157 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/50 {
  --tw-gradient-from: rgb(217 249 157 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/55 {
  --tw-gradient-from: rgb(217 249 157 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/60 {
  --tw-gradient-from: rgb(217 249 157 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/65 {
  --tw-gradient-from: rgb(217 249 157 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/70 {
  --tw-gradient-from: rgb(217 249 157 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/75 {
  --tw-gradient-from: rgb(217 249 157 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/80 {
  --tw-gradient-from: rgb(217 249 157 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/85 {
  --tw-gradient-from: rgb(217 249 157 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/90 {
  --tw-gradient-from: rgb(217 249 157 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-200\/95 {
  --tw-gradient-from: rgb(217 249 157 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300 {
  --tw-gradient-from: #bef264 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/0 {
  --tw-gradient-from: rgb(190 242 100 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/10 {
  --tw-gradient-from: rgb(190 242 100 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/100 {
  --tw-gradient-from: rgb(190 242 100 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/15 {
  --tw-gradient-from: rgb(190 242 100 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/20 {
  --tw-gradient-from: rgb(190 242 100 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/25 {
  --tw-gradient-from: rgb(190 242 100 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/30 {
  --tw-gradient-from: rgb(190 242 100 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/35 {
  --tw-gradient-from: rgb(190 242 100 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/40 {
  --tw-gradient-from: rgb(190 242 100 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/45 {
  --tw-gradient-from: rgb(190 242 100 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/5 {
  --tw-gradient-from: rgb(190 242 100 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/50 {
  --tw-gradient-from: rgb(190 242 100 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/55 {
  --tw-gradient-from: rgb(190 242 100 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/60 {
  --tw-gradient-from: rgb(190 242 100 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/65 {
  --tw-gradient-from: rgb(190 242 100 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/70 {
  --tw-gradient-from: rgb(190 242 100 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/75 {
  --tw-gradient-from: rgb(190 242 100 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/80 {
  --tw-gradient-from: rgb(190 242 100 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/85 {
  --tw-gradient-from: rgb(190 242 100 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/90 {
  --tw-gradient-from: rgb(190 242 100 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-300\/95 {
  --tw-gradient-from: rgb(190 242 100 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400 {
  --tw-gradient-from: #a3e635 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/0 {
  --tw-gradient-from: rgb(163 230 53 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/10 {
  --tw-gradient-from: rgb(163 230 53 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/100 {
  --tw-gradient-from: rgb(163 230 53 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/15 {
  --tw-gradient-from: rgb(163 230 53 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/20 {
  --tw-gradient-from: rgb(163 230 53 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/25 {
  --tw-gradient-from: rgb(163 230 53 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/30 {
  --tw-gradient-from: rgb(163 230 53 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/35 {
  --tw-gradient-from: rgb(163 230 53 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/40 {
  --tw-gradient-from: rgb(163 230 53 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/45 {
  --tw-gradient-from: rgb(163 230 53 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/5 {
  --tw-gradient-from: rgb(163 230 53 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/50 {
  --tw-gradient-from: rgb(163 230 53 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/55 {
  --tw-gradient-from: rgb(163 230 53 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/60 {
  --tw-gradient-from: rgb(163 230 53 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/65 {
  --tw-gradient-from: rgb(163 230 53 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/70 {
  --tw-gradient-from: rgb(163 230 53 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/75 {
  --tw-gradient-from: rgb(163 230 53 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/80 {
  --tw-gradient-from: rgb(163 230 53 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/85 {
  --tw-gradient-from: rgb(163 230 53 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/90 {
  --tw-gradient-from: rgb(163 230 53 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-400\/95 {
  --tw-gradient-from: rgb(163 230 53 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50 {
  --tw-gradient-from: #f7fee7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/0 {
  --tw-gradient-from: rgb(247 254 231 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/10 {
  --tw-gradient-from: rgb(247 254 231 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/100 {
  --tw-gradient-from: rgb(247 254 231 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/15 {
  --tw-gradient-from: rgb(247 254 231 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/20 {
  --tw-gradient-from: rgb(247 254 231 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/25 {
  --tw-gradient-from: rgb(247 254 231 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/30 {
  --tw-gradient-from: rgb(247 254 231 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/35 {
  --tw-gradient-from: rgb(247 254 231 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/40 {
  --tw-gradient-from: rgb(247 254 231 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/45 {
  --tw-gradient-from: rgb(247 254 231 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/5 {
  --tw-gradient-from: rgb(247 254 231 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/50 {
  --tw-gradient-from: rgb(247 254 231 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/55 {
  --tw-gradient-from: rgb(247 254 231 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/60 {
  --tw-gradient-from: rgb(247 254 231 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/65 {
  --tw-gradient-from: rgb(247 254 231 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/70 {
  --tw-gradient-from: rgb(247 254 231 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/75 {
  --tw-gradient-from: rgb(247 254 231 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/80 {
  --tw-gradient-from: rgb(247 254 231 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/85 {
  --tw-gradient-from: rgb(247 254 231 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/90 {
  --tw-gradient-from: rgb(247 254 231 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-50\/95 {
  --tw-gradient-from: rgb(247 254 231 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500 {
  --tw-gradient-from: #84cc16 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/0 {
  --tw-gradient-from: rgb(132 204 22 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/10 {
  --tw-gradient-from: rgb(132 204 22 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/100 {
  --tw-gradient-from: rgb(132 204 22 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/15 {
  --tw-gradient-from: rgb(132 204 22 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/20 {
  --tw-gradient-from: rgb(132 204 22 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/25 {
  --tw-gradient-from: rgb(132 204 22 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/30 {
  --tw-gradient-from: rgb(132 204 22 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/35 {
  --tw-gradient-from: rgb(132 204 22 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/40 {
  --tw-gradient-from: rgb(132 204 22 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/45 {
  --tw-gradient-from: rgb(132 204 22 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/5 {
  --tw-gradient-from: rgb(132 204 22 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/50 {
  --tw-gradient-from: rgb(132 204 22 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/55 {
  --tw-gradient-from: rgb(132 204 22 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/60 {
  --tw-gradient-from: rgb(132 204 22 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/65 {
  --tw-gradient-from: rgb(132 204 22 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/70 {
  --tw-gradient-from: rgb(132 204 22 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/75 {
  --tw-gradient-from: rgb(132 204 22 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/80 {
  --tw-gradient-from: rgb(132 204 22 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/85 {
  --tw-gradient-from: rgb(132 204 22 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/90 {
  --tw-gradient-from: rgb(132 204 22 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-500\/95 {
  --tw-gradient-from: rgb(132 204 22 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600 {
  --tw-gradient-from: #65a30d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/0 {
  --tw-gradient-from: rgb(101 163 13 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/10 {
  --tw-gradient-from: rgb(101 163 13 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/100 {
  --tw-gradient-from: rgb(101 163 13 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/15 {
  --tw-gradient-from: rgb(101 163 13 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/20 {
  --tw-gradient-from: rgb(101 163 13 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/25 {
  --tw-gradient-from: rgb(101 163 13 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/30 {
  --tw-gradient-from: rgb(101 163 13 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/35 {
  --tw-gradient-from: rgb(101 163 13 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/40 {
  --tw-gradient-from: rgb(101 163 13 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/45 {
  --tw-gradient-from: rgb(101 163 13 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/5 {
  --tw-gradient-from: rgb(101 163 13 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/50 {
  --tw-gradient-from: rgb(101 163 13 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/55 {
  --tw-gradient-from: rgb(101 163 13 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/60 {
  --tw-gradient-from: rgb(101 163 13 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/65 {
  --tw-gradient-from: rgb(101 163 13 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/70 {
  --tw-gradient-from: rgb(101 163 13 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/75 {
  --tw-gradient-from: rgb(101 163 13 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/80 {
  --tw-gradient-from: rgb(101 163 13 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/85 {
  --tw-gradient-from: rgb(101 163 13 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/90 {
  --tw-gradient-from: rgb(101 163 13 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-600\/95 {
  --tw-gradient-from: rgb(101 163 13 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700 {
  --tw-gradient-from: #4d7c0f var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/0 {
  --tw-gradient-from: rgb(77 124 15 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/10 {
  --tw-gradient-from: rgb(77 124 15 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/100 {
  --tw-gradient-from: rgb(77 124 15 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/15 {
  --tw-gradient-from: rgb(77 124 15 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/20 {
  --tw-gradient-from: rgb(77 124 15 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/25 {
  --tw-gradient-from: rgb(77 124 15 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/30 {
  --tw-gradient-from: rgb(77 124 15 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/35 {
  --tw-gradient-from: rgb(77 124 15 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/40 {
  --tw-gradient-from: rgb(77 124 15 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/45 {
  --tw-gradient-from: rgb(77 124 15 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/5 {
  --tw-gradient-from: rgb(77 124 15 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/50 {
  --tw-gradient-from: rgb(77 124 15 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/55 {
  --tw-gradient-from: rgb(77 124 15 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/60 {
  --tw-gradient-from: rgb(77 124 15 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/65 {
  --tw-gradient-from: rgb(77 124 15 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/70 {
  --tw-gradient-from: rgb(77 124 15 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/75 {
  --tw-gradient-from: rgb(77 124 15 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/80 {
  --tw-gradient-from: rgb(77 124 15 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/85 {
  --tw-gradient-from: rgb(77 124 15 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/90 {
  --tw-gradient-from: rgb(77 124 15 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-700\/95 {
  --tw-gradient-from: rgb(77 124 15 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800 {
  --tw-gradient-from: #3f6212 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/0 {
  --tw-gradient-from: rgb(63 98 18 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/10 {
  --tw-gradient-from: rgb(63 98 18 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/100 {
  --tw-gradient-from: rgb(63 98 18 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/15 {
  --tw-gradient-from: rgb(63 98 18 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/20 {
  --tw-gradient-from: rgb(63 98 18 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/25 {
  --tw-gradient-from: rgb(63 98 18 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/30 {
  --tw-gradient-from: rgb(63 98 18 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/35 {
  --tw-gradient-from: rgb(63 98 18 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/40 {
  --tw-gradient-from: rgb(63 98 18 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/45 {
  --tw-gradient-from: rgb(63 98 18 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/5 {
  --tw-gradient-from: rgb(63 98 18 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/50 {
  --tw-gradient-from: rgb(63 98 18 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/55 {
  --tw-gradient-from: rgb(63 98 18 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/60 {
  --tw-gradient-from: rgb(63 98 18 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/65 {
  --tw-gradient-from: rgb(63 98 18 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/70 {
  --tw-gradient-from: rgb(63 98 18 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/75 {
  --tw-gradient-from: rgb(63 98 18 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/80 {
  --tw-gradient-from: rgb(63 98 18 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/85 {
  --tw-gradient-from: rgb(63 98 18 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/90 {
  --tw-gradient-from: rgb(63 98 18 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-800\/95 {
  --tw-gradient-from: rgb(63 98 18 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900 {
  --tw-gradient-from: #365314 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/0 {
  --tw-gradient-from: rgb(54 83 20 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/10 {
  --tw-gradient-from: rgb(54 83 20 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/100 {
  --tw-gradient-from: rgb(54 83 20 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/15 {
  --tw-gradient-from: rgb(54 83 20 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/20 {
  --tw-gradient-from: rgb(54 83 20 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/25 {
  --tw-gradient-from: rgb(54 83 20 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/30 {
  --tw-gradient-from: rgb(54 83 20 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/35 {
  --tw-gradient-from: rgb(54 83 20 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/40 {
  --tw-gradient-from: rgb(54 83 20 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/45 {
  --tw-gradient-from: rgb(54 83 20 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/5 {
  --tw-gradient-from: rgb(54 83 20 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/50 {
  --tw-gradient-from: rgb(54 83 20 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/55 {
  --tw-gradient-from: rgb(54 83 20 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/60 {
  --tw-gradient-from: rgb(54 83 20 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/65 {
  --tw-gradient-from: rgb(54 83 20 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/70 {
  --tw-gradient-from: rgb(54 83 20 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/75 {
  --tw-gradient-from: rgb(54 83 20 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/80 {
  --tw-gradient-from: rgb(54 83 20 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/85 {
  --tw-gradient-from: rgb(54 83 20 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/90 {
  --tw-gradient-from: rgb(54 83 20 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-900\/95 {
  --tw-gradient-from: rgb(54 83 20 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950 {
  --tw-gradient-from: #1a2e05 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/0 {
  --tw-gradient-from: rgb(26 46 5 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/10 {
  --tw-gradient-from: rgb(26 46 5 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/100 {
  --tw-gradient-from: rgb(26 46 5 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/15 {
  --tw-gradient-from: rgb(26 46 5 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/20 {
  --tw-gradient-from: rgb(26 46 5 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/25 {
  --tw-gradient-from: rgb(26 46 5 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/30 {
  --tw-gradient-from: rgb(26 46 5 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/35 {
  --tw-gradient-from: rgb(26 46 5 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/40 {
  --tw-gradient-from: rgb(26 46 5 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/45 {
  --tw-gradient-from: rgb(26 46 5 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/5 {
  --tw-gradient-from: rgb(26 46 5 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/50 {
  --tw-gradient-from: rgb(26 46 5 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/55 {
  --tw-gradient-from: rgb(26 46 5 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/60 {
  --tw-gradient-from: rgb(26 46 5 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/65 {
  --tw-gradient-from: rgb(26 46 5 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/70 {
  --tw-gradient-from: rgb(26 46 5 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/75 {
  --tw-gradient-from: rgb(26 46 5 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/80 {
  --tw-gradient-from: rgb(26 46 5 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/85 {
  --tw-gradient-from: rgb(26 46 5 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/90 {
  --tw-gradient-from: rgb(26 46 5 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-lime-950\/95 {
  --tw-gradient-from: rgb(26 46 5 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100 {
  --tw-gradient-from: #f5f5f5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/0 {
  --tw-gradient-from: rgb(245 245 245 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/10 {
  --tw-gradient-from: rgb(245 245 245 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/100 {
  --tw-gradient-from: rgb(245 245 245 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/15 {
  --tw-gradient-from: rgb(245 245 245 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/20 {
  --tw-gradient-from: rgb(245 245 245 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/25 {
  --tw-gradient-from: rgb(245 245 245 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/30 {
  --tw-gradient-from: rgb(245 245 245 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/35 {
  --tw-gradient-from: rgb(245 245 245 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/40 {
  --tw-gradient-from: rgb(245 245 245 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/45 {
  --tw-gradient-from: rgb(245 245 245 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/5 {
  --tw-gradient-from: rgb(245 245 245 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/50 {
  --tw-gradient-from: rgb(245 245 245 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/55 {
  --tw-gradient-from: rgb(245 245 245 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/60 {
  --tw-gradient-from: rgb(245 245 245 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/65 {
  --tw-gradient-from: rgb(245 245 245 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/70 {
  --tw-gradient-from: rgb(245 245 245 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/75 {
  --tw-gradient-from: rgb(245 245 245 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/80 {
  --tw-gradient-from: rgb(245 245 245 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/85 {
  --tw-gradient-from: rgb(245 245 245 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/90 {
  --tw-gradient-from: rgb(245 245 245 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-100\/95 {
  --tw-gradient-from: rgb(245 245 245 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200 {
  --tw-gradient-from: #e5e5e5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/0 {
  --tw-gradient-from: rgb(229 229 229 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/10 {
  --tw-gradient-from: rgb(229 229 229 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/100 {
  --tw-gradient-from: rgb(229 229 229 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/15 {
  --tw-gradient-from: rgb(229 229 229 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/20 {
  --tw-gradient-from: rgb(229 229 229 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/25 {
  --tw-gradient-from: rgb(229 229 229 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/30 {
  --tw-gradient-from: rgb(229 229 229 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/35 {
  --tw-gradient-from: rgb(229 229 229 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/40 {
  --tw-gradient-from: rgb(229 229 229 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/45 {
  --tw-gradient-from: rgb(229 229 229 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/5 {
  --tw-gradient-from: rgb(229 229 229 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/50 {
  --tw-gradient-from: rgb(229 229 229 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/55 {
  --tw-gradient-from: rgb(229 229 229 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/60 {
  --tw-gradient-from: rgb(229 229 229 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/65 {
  --tw-gradient-from: rgb(229 229 229 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/70 {
  --tw-gradient-from: rgb(229 229 229 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/75 {
  --tw-gradient-from: rgb(229 229 229 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/80 {
  --tw-gradient-from: rgb(229 229 229 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/85 {
  --tw-gradient-from: rgb(229 229 229 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/90 {
  --tw-gradient-from: rgb(229 229 229 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-200\/95 {
  --tw-gradient-from: rgb(229 229 229 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300 {
  --tw-gradient-from: #d4d4d4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/0 {
  --tw-gradient-from: rgb(212 212 212 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/10 {
  --tw-gradient-from: rgb(212 212 212 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/100 {
  --tw-gradient-from: rgb(212 212 212 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/15 {
  --tw-gradient-from: rgb(212 212 212 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/20 {
  --tw-gradient-from: rgb(212 212 212 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/25 {
  --tw-gradient-from: rgb(212 212 212 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/30 {
  --tw-gradient-from: rgb(212 212 212 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/35 {
  --tw-gradient-from: rgb(212 212 212 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/40 {
  --tw-gradient-from: rgb(212 212 212 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/45 {
  --tw-gradient-from: rgb(212 212 212 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/5 {
  --tw-gradient-from: rgb(212 212 212 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/50 {
  --tw-gradient-from: rgb(212 212 212 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/55 {
  --tw-gradient-from: rgb(212 212 212 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/60 {
  --tw-gradient-from: rgb(212 212 212 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/65 {
  --tw-gradient-from: rgb(212 212 212 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/70 {
  --tw-gradient-from: rgb(212 212 212 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/75 {
  --tw-gradient-from: rgb(212 212 212 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/80 {
  --tw-gradient-from: rgb(212 212 212 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/85 {
  --tw-gradient-from: rgb(212 212 212 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/90 {
  --tw-gradient-from: rgb(212 212 212 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-300\/95 {
  --tw-gradient-from: rgb(212 212 212 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400 {
  --tw-gradient-from: #a3a3a3 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/0 {
  --tw-gradient-from: rgb(163 163 163 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/10 {
  --tw-gradient-from: rgb(163 163 163 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/100 {
  --tw-gradient-from: rgb(163 163 163 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/15 {
  --tw-gradient-from: rgb(163 163 163 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/20 {
  --tw-gradient-from: rgb(163 163 163 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/25 {
  --tw-gradient-from: rgb(163 163 163 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/30 {
  --tw-gradient-from: rgb(163 163 163 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/35 {
  --tw-gradient-from: rgb(163 163 163 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/40 {
  --tw-gradient-from: rgb(163 163 163 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/45 {
  --tw-gradient-from: rgb(163 163 163 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/5 {
  --tw-gradient-from: rgb(163 163 163 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/50 {
  --tw-gradient-from: rgb(163 163 163 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/55 {
  --tw-gradient-from: rgb(163 163 163 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/60 {
  --tw-gradient-from: rgb(163 163 163 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/65 {
  --tw-gradient-from: rgb(163 163 163 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/70 {
  --tw-gradient-from: rgb(163 163 163 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/75 {
  --tw-gradient-from: rgb(163 163 163 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/80 {
  --tw-gradient-from: rgb(163 163 163 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/85 {
  --tw-gradient-from: rgb(163 163 163 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/90 {
  --tw-gradient-from: rgb(163 163 163 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-400\/95 {
  --tw-gradient-from: rgb(163 163 163 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50 {
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/0 {
  --tw-gradient-from: rgb(250 250 250 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/10 {
  --tw-gradient-from: rgb(250 250 250 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/100 {
  --tw-gradient-from: rgb(250 250 250 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/15 {
  --tw-gradient-from: rgb(250 250 250 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/20 {
  --tw-gradient-from: rgb(250 250 250 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/25 {
  --tw-gradient-from: rgb(250 250 250 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/30 {
  --tw-gradient-from: rgb(250 250 250 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/35 {
  --tw-gradient-from: rgb(250 250 250 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/40 {
  --tw-gradient-from: rgb(250 250 250 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/45 {
  --tw-gradient-from: rgb(250 250 250 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/5 {
  --tw-gradient-from: rgb(250 250 250 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/50 {
  --tw-gradient-from: rgb(250 250 250 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/55 {
  --tw-gradient-from: rgb(250 250 250 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/60 {
  --tw-gradient-from: rgb(250 250 250 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/65 {
  --tw-gradient-from: rgb(250 250 250 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/70 {
  --tw-gradient-from: rgb(250 250 250 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/75 {
  --tw-gradient-from: rgb(250 250 250 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/80 {
  --tw-gradient-from: rgb(250 250 250 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/85 {
  --tw-gradient-from: rgb(250 250 250 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/90 {
  --tw-gradient-from: rgb(250 250 250 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-50\/95 {
  --tw-gradient-from: rgb(250 250 250 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500 {
  --tw-gradient-from: #737373 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/0 {
  --tw-gradient-from: rgb(115 115 115 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/10 {
  --tw-gradient-from: rgb(115 115 115 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/100 {
  --tw-gradient-from: rgb(115 115 115 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/15 {
  --tw-gradient-from: rgb(115 115 115 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/20 {
  --tw-gradient-from: rgb(115 115 115 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/25 {
  --tw-gradient-from: rgb(115 115 115 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/30 {
  --tw-gradient-from: rgb(115 115 115 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/35 {
  --tw-gradient-from: rgb(115 115 115 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/40 {
  --tw-gradient-from: rgb(115 115 115 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/45 {
  --tw-gradient-from: rgb(115 115 115 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/5 {
  --tw-gradient-from: rgb(115 115 115 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/50 {
  --tw-gradient-from: rgb(115 115 115 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/55 {
  --tw-gradient-from: rgb(115 115 115 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/60 {
  --tw-gradient-from: rgb(115 115 115 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/65 {
  --tw-gradient-from: rgb(115 115 115 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/70 {
  --tw-gradient-from: rgb(115 115 115 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/75 {
  --tw-gradient-from: rgb(115 115 115 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/80 {
  --tw-gradient-from: rgb(115 115 115 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/85 {
  --tw-gradient-from: rgb(115 115 115 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/90 {
  --tw-gradient-from: rgb(115 115 115 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-500\/95 {
  --tw-gradient-from: rgb(115 115 115 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600 {
  --tw-gradient-from: #525252 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/0 {
  --tw-gradient-from: rgb(82 82 82 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/10 {
  --tw-gradient-from: rgb(82 82 82 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/100 {
  --tw-gradient-from: rgb(82 82 82 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/15 {
  --tw-gradient-from: rgb(82 82 82 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/20 {
  --tw-gradient-from: rgb(82 82 82 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/25 {
  --tw-gradient-from: rgb(82 82 82 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/30 {
  --tw-gradient-from: rgb(82 82 82 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/35 {
  --tw-gradient-from: rgb(82 82 82 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/40 {
  --tw-gradient-from: rgb(82 82 82 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/45 {
  --tw-gradient-from: rgb(82 82 82 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/5 {
  --tw-gradient-from: rgb(82 82 82 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/50 {
  --tw-gradient-from: rgb(82 82 82 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/55 {
  --tw-gradient-from: rgb(82 82 82 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/60 {
  --tw-gradient-from: rgb(82 82 82 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/65 {
  --tw-gradient-from: rgb(82 82 82 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/70 {
  --tw-gradient-from: rgb(82 82 82 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/75 {
  --tw-gradient-from: rgb(82 82 82 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/80 {
  --tw-gradient-from: rgb(82 82 82 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/85 {
  --tw-gradient-from: rgb(82 82 82 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/90 {
  --tw-gradient-from: rgb(82 82 82 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-600\/95 {
  --tw-gradient-from: rgb(82 82 82 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700 {
  --tw-gradient-from: #404040 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/0 {
  --tw-gradient-from: rgb(64 64 64 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/10 {
  --tw-gradient-from: rgb(64 64 64 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/100 {
  --tw-gradient-from: rgb(64 64 64 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/15 {
  --tw-gradient-from: rgb(64 64 64 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/20 {
  --tw-gradient-from: rgb(64 64 64 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/25 {
  --tw-gradient-from: rgb(64 64 64 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/30 {
  --tw-gradient-from: rgb(64 64 64 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/35 {
  --tw-gradient-from: rgb(64 64 64 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/40 {
  --tw-gradient-from: rgb(64 64 64 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/45 {
  --tw-gradient-from: rgb(64 64 64 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/5 {
  --tw-gradient-from: rgb(64 64 64 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/50 {
  --tw-gradient-from: rgb(64 64 64 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/55 {
  --tw-gradient-from: rgb(64 64 64 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/60 {
  --tw-gradient-from: rgb(64 64 64 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/65 {
  --tw-gradient-from: rgb(64 64 64 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/70 {
  --tw-gradient-from: rgb(64 64 64 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/75 {
  --tw-gradient-from: rgb(64 64 64 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/80 {
  --tw-gradient-from: rgb(64 64 64 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/85 {
  --tw-gradient-from: rgb(64 64 64 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/90 {
  --tw-gradient-from: rgb(64 64 64 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-700\/95 {
  --tw-gradient-from: rgb(64 64 64 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800 {
  --tw-gradient-from: #262626 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/0 {
  --tw-gradient-from: rgb(38 38 38 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/10 {
  --tw-gradient-from: rgb(38 38 38 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/100 {
  --tw-gradient-from: rgb(38 38 38 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/15 {
  --tw-gradient-from: rgb(38 38 38 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/20 {
  --tw-gradient-from: rgb(38 38 38 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/25 {
  --tw-gradient-from: rgb(38 38 38 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/30 {
  --tw-gradient-from: rgb(38 38 38 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/35 {
  --tw-gradient-from: rgb(38 38 38 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/40 {
  --tw-gradient-from: rgb(38 38 38 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/45 {
  --tw-gradient-from: rgb(38 38 38 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/5 {
  --tw-gradient-from: rgb(38 38 38 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/50 {
  --tw-gradient-from: rgb(38 38 38 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/55 {
  --tw-gradient-from: rgb(38 38 38 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/60 {
  --tw-gradient-from: rgb(38 38 38 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/65 {
  --tw-gradient-from: rgb(38 38 38 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/70 {
  --tw-gradient-from: rgb(38 38 38 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/75 {
  --tw-gradient-from: rgb(38 38 38 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/80 {
  --tw-gradient-from: rgb(38 38 38 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/85 {
  --tw-gradient-from: rgb(38 38 38 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/90 {
  --tw-gradient-from: rgb(38 38 38 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-800\/95 {
  --tw-gradient-from: rgb(38 38 38 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900 {
  --tw-gradient-from: #171717 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/0 {
  --tw-gradient-from: rgb(23 23 23 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/10 {
  --tw-gradient-from: rgb(23 23 23 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/100 {
  --tw-gradient-from: rgb(23 23 23 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/15 {
  --tw-gradient-from: rgb(23 23 23 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/20 {
  --tw-gradient-from: rgb(23 23 23 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/25 {
  --tw-gradient-from: rgb(23 23 23 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/30 {
  --tw-gradient-from: rgb(23 23 23 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/35 {
  --tw-gradient-from: rgb(23 23 23 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/40 {
  --tw-gradient-from: rgb(23 23 23 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/45 {
  --tw-gradient-from: rgb(23 23 23 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/5 {
  --tw-gradient-from: rgb(23 23 23 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/50 {
  --tw-gradient-from: rgb(23 23 23 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/55 {
  --tw-gradient-from: rgb(23 23 23 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/60 {
  --tw-gradient-from: rgb(23 23 23 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/65 {
  --tw-gradient-from: rgb(23 23 23 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/70 {
  --tw-gradient-from: rgb(23 23 23 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/75 {
  --tw-gradient-from: rgb(23 23 23 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/80 {
  --tw-gradient-from: rgb(23 23 23 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/85 {
  --tw-gradient-from: rgb(23 23 23 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/90 {
  --tw-gradient-from: rgb(23 23 23 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-900\/95 {
  --tw-gradient-from: rgb(23 23 23 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950 {
  --tw-gradient-from: #0a0a0a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/0 {
  --tw-gradient-from: rgb(10 10 10 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/10 {
  --tw-gradient-from: rgb(10 10 10 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/100 {
  --tw-gradient-from: rgb(10 10 10 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/15 {
  --tw-gradient-from: rgb(10 10 10 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/20 {
  --tw-gradient-from: rgb(10 10 10 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/25 {
  --tw-gradient-from: rgb(10 10 10 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/30 {
  --tw-gradient-from: rgb(10 10 10 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/35 {
  --tw-gradient-from: rgb(10 10 10 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/40 {
  --tw-gradient-from: rgb(10 10 10 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/45 {
  --tw-gradient-from: rgb(10 10 10 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/5 {
  --tw-gradient-from: rgb(10 10 10 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/50 {
  --tw-gradient-from: rgb(10 10 10 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/55 {
  --tw-gradient-from: rgb(10 10 10 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/60 {
  --tw-gradient-from: rgb(10 10 10 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/65 {
  --tw-gradient-from: rgb(10 10 10 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/70 {
  --tw-gradient-from: rgb(10 10 10 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/75 {
  --tw-gradient-from: rgb(10 10 10 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/80 {
  --tw-gradient-from: rgb(10 10 10 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/85 {
  --tw-gradient-from: rgb(10 10 10 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/90 {
  --tw-gradient-from: rgb(10 10 10 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-neutral-950\/95 {
  --tw-gradient-from: rgb(10 10 10 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100 {
  --tw-gradient-from: #ffedd5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/0 {
  --tw-gradient-from: rgb(255 237 213 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/10 {
  --tw-gradient-from: rgb(255 237 213 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/100 {
  --tw-gradient-from: rgb(255 237 213 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/15 {
  --tw-gradient-from: rgb(255 237 213 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/20 {
  --tw-gradient-from: rgb(255 237 213 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/25 {
  --tw-gradient-from: rgb(255 237 213 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/30 {
  --tw-gradient-from: rgb(255 237 213 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/35 {
  --tw-gradient-from: rgb(255 237 213 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/40 {
  --tw-gradient-from: rgb(255 237 213 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/45 {
  --tw-gradient-from: rgb(255 237 213 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/5 {
  --tw-gradient-from: rgb(255 237 213 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/50 {
  --tw-gradient-from: rgb(255 237 213 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/55 {
  --tw-gradient-from: rgb(255 237 213 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/60 {
  --tw-gradient-from: rgb(255 237 213 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/65 {
  --tw-gradient-from: rgb(255 237 213 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/70 {
  --tw-gradient-from: rgb(255 237 213 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/75 {
  --tw-gradient-from: rgb(255 237 213 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/80 {
  --tw-gradient-from: rgb(255 237 213 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/85 {
  --tw-gradient-from: rgb(255 237 213 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/90 {
  --tw-gradient-from: rgb(255 237 213 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-100\/95 {
  --tw-gradient-from: rgb(255 237 213 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200 {
  --tw-gradient-from: #fed7aa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/0 {
  --tw-gradient-from: rgb(254 215 170 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/10 {
  --tw-gradient-from: rgb(254 215 170 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/100 {
  --tw-gradient-from: rgb(254 215 170 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/15 {
  --tw-gradient-from: rgb(254 215 170 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/20 {
  --tw-gradient-from: rgb(254 215 170 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/25 {
  --tw-gradient-from: rgb(254 215 170 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/30 {
  --tw-gradient-from: rgb(254 215 170 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/35 {
  --tw-gradient-from: rgb(254 215 170 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/40 {
  --tw-gradient-from: rgb(254 215 170 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/45 {
  --tw-gradient-from: rgb(254 215 170 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/5 {
  --tw-gradient-from: rgb(254 215 170 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/50 {
  --tw-gradient-from: rgb(254 215 170 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/55 {
  --tw-gradient-from: rgb(254 215 170 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/60 {
  --tw-gradient-from: rgb(254 215 170 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/65 {
  --tw-gradient-from: rgb(254 215 170 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/70 {
  --tw-gradient-from: rgb(254 215 170 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/75 {
  --tw-gradient-from: rgb(254 215 170 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/80 {
  --tw-gradient-from: rgb(254 215 170 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/85 {
  --tw-gradient-from: rgb(254 215 170 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/90 {
  --tw-gradient-from: rgb(254 215 170 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-200\/95 {
  --tw-gradient-from: rgb(254 215 170 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300 {
  --tw-gradient-from: #fdba74 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/0 {
  --tw-gradient-from: rgb(253 186 116 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/10 {
  --tw-gradient-from: rgb(253 186 116 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/100 {
  --tw-gradient-from: rgb(253 186 116 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/15 {
  --tw-gradient-from: rgb(253 186 116 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/20 {
  --tw-gradient-from: rgb(253 186 116 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/25 {
  --tw-gradient-from: rgb(253 186 116 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/30 {
  --tw-gradient-from: rgb(253 186 116 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/35 {
  --tw-gradient-from: rgb(253 186 116 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/40 {
  --tw-gradient-from: rgb(253 186 116 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/45 {
  --tw-gradient-from: rgb(253 186 116 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/5 {
  --tw-gradient-from: rgb(253 186 116 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/50 {
  --tw-gradient-from: rgb(253 186 116 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/55 {
  --tw-gradient-from: rgb(253 186 116 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/60 {
  --tw-gradient-from: rgb(253 186 116 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/65 {
  --tw-gradient-from: rgb(253 186 116 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/70 {
  --tw-gradient-from: rgb(253 186 116 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/75 {
  --tw-gradient-from: rgb(253 186 116 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/80 {
  --tw-gradient-from: rgb(253 186 116 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/85 {
  --tw-gradient-from: rgb(253 186 116 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/90 {
  --tw-gradient-from: rgb(253 186 116 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-300\/95 {
  --tw-gradient-from: rgb(253 186 116 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400 {
  --tw-gradient-from: #fb923c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/0 {
  --tw-gradient-from: rgb(251 146 60 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/10 {
  --tw-gradient-from: rgb(251 146 60 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/100 {
  --tw-gradient-from: rgb(251 146 60 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/15 {
  --tw-gradient-from: rgb(251 146 60 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/20 {
  --tw-gradient-from: rgb(251 146 60 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/25 {
  --tw-gradient-from: rgb(251 146 60 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/30 {
  --tw-gradient-from: rgb(251 146 60 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/35 {
  --tw-gradient-from: rgb(251 146 60 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/40 {
  --tw-gradient-from: rgb(251 146 60 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/45 {
  --tw-gradient-from: rgb(251 146 60 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/5 {
  --tw-gradient-from: rgb(251 146 60 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/50 {
  --tw-gradient-from: rgb(251 146 60 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/55 {
  --tw-gradient-from: rgb(251 146 60 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/60 {
  --tw-gradient-from: rgb(251 146 60 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/65 {
  --tw-gradient-from: rgb(251 146 60 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/70 {
  --tw-gradient-from: rgb(251 146 60 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/75 {
  --tw-gradient-from: rgb(251 146 60 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/80 {
  --tw-gradient-from: rgb(251 146 60 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/85 {
  --tw-gradient-from: rgb(251 146 60 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/90 {
  --tw-gradient-from: rgb(251 146 60 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-400\/95 {
  --tw-gradient-from: rgb(251 146 60 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50 {
  --tw-gradient-from: #fff7ed var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/0 {
  --tw-gradient-from: rgb(255 247 237 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/10 {
  --tw-gradient-from: rgb(255 247 237 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/100 {
  --tw-gradient-from: rgb(255 247 237 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/15 {
  --tw-gradient-from: rgb(255 247 237 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/20 {
  --tw-gradient-from: rgb(255 247 237 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/25 {
  --tw-gradient-from: rgb(255 247 237 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/30 {
  --tw-gradient-from: rgb(255 247 237 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/35 {
  --tw-gradient-from: rgb(255 247 237 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/40 {
  --tw-gradient-from: rgb(255 247 237 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/45 {
  --tw-gradient-from: rgb(255 247 237 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/5 {
  --tw-gradient-from: rgb(255 247 237 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/50 {
  --tw-gradient-from: rgb(255 247 237 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/55 {
  --tw-gradient-from: rgb(255 247 237 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/60 {
  --tw-gradient-from: rgb(255 247 237 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/65 {
  --tw-gradient-from: rgb(255 247 237 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/70 {
  --tw-gradient-from: rgb(255 247 237 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/75 {
  --tw-gradient-from: rgb(255 247 237 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/80 {
  --tw-gradient-from: rgb(255 247 237 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/85 {
  --tw-gradient-from: rgb(255 247 237 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/90 {
  --tw-gradient-from: rgb(255 247 237 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-50\/95 {
  --tw-gradient-from: rgb(255 247 237 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500 {
  --tw-gradient-from: #f97316 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/0 {
  --tw-gradient-from: rgb(249 115 22 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/10 {
  --tw-gradient-from: rgb(249 115 22 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/100 {
  --tw-gradient-from: rgb(249 115 22 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/15 {
  --tw-gradient-from: rgb(249 115 22 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/20 {
  --tw-gradient-from: rgb(249 115 22 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/25 {
  --tw-gradient-from: rgb(249 115 22 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/30 {
  --tw-gradient-from: rgb(249 115 22 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/35 {
  --tw-gradient-from: rgb(249 115 22 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/40 {
  --tw-gradient-from: rgb(249 115 22 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/45 {
  --tw-gradient-from: rgb(249 115 22 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/5 {
  --tw-gradient-from: rgb(249 115 22 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/50 {
  --tw-gradient-from: rgb(249 115 22 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/55 {
  --tw-gradient-from: rgb(249 115 22 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/60 {
  --tw-gradient-from: rgb(249 115 22 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/65 {
  --tw-gradient-from: rgb(249 115 22 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/70 {
  --tw-gradient-from: rgb(249 115 22 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/75 {
  --tw-gradient-from: rgb(249 115 22 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/80 {
  --tw-gradient-from: rgb(249 115 22 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/85 {
  --tw-gradient-from: rgb(249 115 22 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/90 {
  --tw-gradient-from: rgb(249 115 22 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-500\/95 {
  --tw-gradient-from: rgb(249 115 22 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600 {
  --tw-gradient-from: #ea580c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/0 {
  --tw-gradient-from: rgb(234 88 12 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/10 {
  --tw-gradient-from: rgb(234 88 12 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/100 {
  --tw-gradient-from: rgb(234 88 12 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/15 {
  --tw-gradient-from: rgb(234 88 12 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/20 {
  --tw-gradient-from: rgb(234 88 12 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/25 {
  --tw-gradient-from: rgb(234 88 12 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/30 {
  --tw-gradient-from: rgb(234 88 12 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/35 {
  --tw-gradient-from: rgb(234 88 12 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/40 {
  --tw-gradient-from: rgb(234 88 12 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/45 {
  --tw-gradient-from: rgb(234 88 12 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/5 {
  --tw-gradient-from: rgb(234 88 12 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/50 {
  --tw-gradient-from: rgb(234 88 12 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/55 {
  --tw-gradient-from: rgb(234 88 12 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/60 {
  --tw-gradient-from: rgb(234 88 12 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/65 {
  --tw-gradient-from: rgb(234 88 12 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/70 {
  --tw-gradient-from: rgb(234 88 12 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/75 {
  --tw-gradient-from: rgb(234 88 12 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/80 {
  --tw-gradient-from: rgb(234 88 12 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/85 {
  --tw-gradient-from: rgb(234 88 12 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/90 {
  --tw-gradient-from: rgb(234 88 12 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-600\/95 {
  --tw-gradient-from: rgb(234 88 12 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700 {
  --tw-gradient-from: #c2410c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/0 {
  --tw-gradient-from: rgb(194 65 12 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/10 {
  --tw-gradient-from: rgb(194 65 12 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/100 {
  --tw-gradient-from: rgb(194 65 12 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/15 {
  --tw-gradient-from: rgb(194 65 12 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/20 {
  --tw-gradient-from: rgb(194 65 12 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/25 {
  --tw-gradient-from: rgb(194 65 12 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/30 {
  --tw-gradient-from: rgb(194 65 12 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/35 {
  --tw-gradient-from: rgb(194 65 12 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/40 {
  --tw-gradient-from: rgb(194 65 12 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/45 {
  --tw-gradient-from: rgb(194 65 12 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/5 {
  --tw-gradient-from: rgb(194 65 12 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/50 {
  --tw-gradient-from: rgb(194 65 12 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/55 {
  --tw-gradient-from: rgb(194 65 12 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/60 {
  --tw-gradient-from: rgb(194 65 12 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/65 {
  --tw-gradient-from: rgb(194 65 12 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/70 {
  --tw-gradient-from: rgb(194 65 12 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/75 {
  --tw-gradient-from: rgb(194 65 12 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/80 {
  --tw-gradient-from: rgb(194 65 12 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/85 {
  --tw-gradient-from: rgb(194 65 12 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/90 {
  --tw-gradient-from: rgb(194 65 12 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-700\/95 {
  --tw-gradient-from: rgb(194 65 12 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800 {
  --tw-gradient-from: #9a3412 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/0 {
  --tw-gradient-from: rgb(154 52 18 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/10 {
  --tw-gradient-from: rgb(154 52 18 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/100 {
  --tw-gradient-from: rgb(154 52 18 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/15 {
  --tw-gradient-from: rgb(154 52 18 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/20 {
  --tw-gradient-from: rgb(154 52 18 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/25 {
  --tw-gradient-from: rgb(154 52 18 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/30 {
  --tw-gradient-from: rgb(154 52 18 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/35 {
  --tw-gradient-from: rgb(154 52 18 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/40 {
  --tw-gradient-from: rgb(154 52 18 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/45 {
  --tw-gradient-from: rgb(154 52 18 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/5 {
  --tw-gradient-from: rgb(154 52 18 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/50 {
  --tw-gradient-from: rgb(154 52 18 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/55 {
  --tw-gradient-from: rgb(154 52 18 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/60 {
  --tw-gradient-from: rgb(154 52 18 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/65 {
  --tw-gradient-from: rgb(154 52 18 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/70 {
  --tw-gradient-from: rgb(154 52 18 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/75 {
  --tw-gradient-from: rgb(154 52 18 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/80 {
  --tw-gradient-from: rgb(154 52 18 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/85 {
  --tw-gradient-from: rgb(154 52 18 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/90 {
  --tw-gradient-from: rgb(154 52 18 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-800\/95 {
  --tw-gradient-from: rgb(154 52 18 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900 {
  --tw-gradient-from: #7c2d12 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/0 {
  --tw-gradient-from: rgb(124 45 18 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/10 {
  --tw-gradient-from: rgb(124 45 18 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/100 {
  --tw-gradient-from: rgb(124 45 18 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/15 {
  --tw-gradient-from: rgb(124 45 18 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/20 {
  --tw-gradient-from: rgb(124 45 18 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/25 {
  --tw-gradient-from: rgb(124 45 18 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/30 {
  --tw-gradient-from: rgb(124 45 18 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/35 {
  --tw-gradient-from: rgb(124 45 18 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/40 {
  --tw-gradient-from: rgb(124 45 18 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/45 {
  --tw-gradient-from: rgb(124 45 18 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/5 {
  --tw-gradient-from: rgb(124 45 18 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/50 {
  --tw-gradient-from: rgb(124 45 18 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/55 {
  --tw-gradient-from: rgb(124 45 18 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/60 {
  --tw-gradient-from: rgb(124 45 18 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/65 {
  --tw-gradient-from: rgb(124 45 18 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/70 {
  --tw-gradient-from: rgb(124 45 18 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/75 {
  --tw-gradient-from: rgb(124 45 18 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/80 {
  --tw-gradient-from: rgb(124 45 18 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/85 {
  --tw-gradient-from: rgb(124 45 18 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/90 {
  --tw-gradient-from: rgb(124 45 18 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-900\/95 {
  --tw-gradient-from: rgb(124 45 18 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950 {
  --tw-gradient-from: #431407 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/0 {
  --tw-gradient-from: rgb(67 20 7 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/10 {
  --tw-gradient-from: rgb(67 20 7 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/100 {
  --tw-gradient-from: rgb(67 20 7 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/15 {
  --tw-gradient-from: rgb(67 20 7 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/20 {
  --tw-gradient-from: rgb(67 20 7 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/25 {
  --tw-gradient-from: rgb(67 20 7 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/30 {
  --tw-gradient-from: rgb(67 20 7 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/35 {
  --tw-gradient-from: rgb(67 20 7 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/40 {
  --tw-gradient-from: rgb(67 20 7 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/45 {
  --tw-gradient-from: rgb(67 20 7 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/5 {
  --tw-gradient-from: rgb(67 20 7 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/50 {
  --tw-gradient-from: rgb(67 20 7 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/55 {
  --tw-gradient-from: rgb(67 20 7 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/60 {
  --tw-gradient-from: rgb(67 20 7 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/65 {
  --tw-gradient-from: rgb(67 20 7 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/70 {
  --tw-gradient-from: rgb(67 20 7 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/75 {
  --tw-gradient-from: rgb(67 20 7 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/80 {
  --tw-gradient-from: rgb(67 20 7 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/85 {
  --tw-gradient-from: rgb(67 20 7 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/90 {
  --tw-gradient-from: rgb(67 20 7 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-orange-950\/95 {
  --tw-gradient-from: rgb(67 20 7 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100 {
  --tw-gradient-from: #fce7f3 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/0 {
  --tw-gradient-from: rgb(252 231 243 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/10 {
  --tw-gradient-from: rgb(252 231 243 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/100 {
  --tw-gradient-from: rgb(252 231 243 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/15 {
  --tw-gradient-from: rgb(252 231 243 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/20 {
  --tw-gradient-from: rgb(252 231 243 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/25 {
  --tw-gradient-from: rgb(252 231 243 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/30 {
  --tw-gradient-from: rgb(252 231 243 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/35 {
  --tw-gradient-from: rgb(252 231 243 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/40 {
  --tw-gradient-from: rgb(252 231 243 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/45 {
  --tw-gradient-from: rgb(252 231 243 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/5 {
  --tw-gradient-from: rgb(252 231 243 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/50 {
  --tw-gradient-from: rgb(252 231 243 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/55 {
  --tw-gradient-from: rgb(252 231 243 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/60 {
  --tw-gradient-from: rgb(252 231 243 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/65 {
  --tw-gradient-from: rgb(252 231 243 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/70 {
  --tw-gradient-from: rgb(252 231 243 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/75 {
  --tw-gradient-from: rgb(252 231 243 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/80 {
  --tw-gradient-from: rgb(252 231 243 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/85 {
  --tw-gradient-from: rgb(252 231 243 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/90 {
  --tw-gradient-from: rgb(252 231 243 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-100\/95 {
  --tw-gradient-from: rgb(252 231 243 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200 {
  --tw-gradient-from: #fbcfe8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/0 {
  --tw-gradient-from: rgb(251 207 232 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/10 {
  --tw-gradient-from: rgb(251 207 232 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/100 {
  --tw-gradient-from: rgb(251 207 232 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/15 {
  --tw-gradient-from: rgb(251 207 232 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/20 {
  --tw-gradient-from: rgb(251 207 232 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/25 {
  --tw-gradient-from: rgb(251 207 232 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/30 {
  --tw-gradient-from: rgb(251 207 232 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/35 {
  --tw-gradient-from: rgb(251 207 232 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/40 {
  --tw-gradient-from: rgb(251 207 232 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/45 {
  --tw-gradient-from: rgb(251 207 232 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/5 {
  --tw-gradient-from: rgb(251 207 232 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/50 {
  --tw-gradient-from: rgb(251 207 232 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/55 {
  --tw-gradient-from: rgb(251 207 232 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/60 {
  --tw-gradient-from: rgb(251 207 232 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/65 {
  --tw-gradient-from: rgb(251 207 232 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/70 {
  --tw-gradient-from: rgb(251 207 232 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/75 {
  --tw-gradient-from: rgb(251 207 232 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/80 {
  --tw-gradient-from: rgb(251 207 232 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/85 {
  --tw-gradient-from: rgb(251 207 232 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/90 {
  --tw-gradient-from: rgb(251 207 232 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-200\/95 {
  --tw-gradient-from: rgb(251 207 232 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300 {
  --tw-gradient-from: #f9a8d4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/0 {
  --tw-gradient-from: rgb(249 168 212 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/10 {
  --tw-gradient-from: rgb(249 168 212 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/100 {
  --tw-gradient-from: rgb(249 168 212 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/15 {
  --tw-gradient-from: rgb(249 168 212 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/20 {
  --tw-gradient-from: rgb(249 168 212 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/25 {
  --tw-gradient-from: rgb(249 168 212 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/30 {
  --tw-gradient-from: rgb(249 168 212 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/35 {
  --tw-gradient-from: rgb(249 168 212 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/40 {
  --tw-gradient-from: rgb(249 168 212 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/45 {
  --tw-gradient-from: rgb(249 168 212 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/5 {
  --tw-gradient-from: rgb(249 168 212 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/50 {
  --tw-gradient-from: rgb(249 168 212 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/55 {
  --tw-gradient-from: rgb(249 168 212 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/60 {
  --tw-gradient-from: rgb(249 168 212 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/65 {
  --tw-gradient-from: rgb(249 168 212 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/70 {
  --tw-gradient-from: rgb(249 168 212 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/75 {
  --tw-gradient-from: rgb(249 168 212 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/80 {
  --tw-gradient-from: rgb(249 168 212 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/85 {
  --tw-gradient-from: rgb(249 168 212 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/90 {
  --tw-gradient-from: rgb(249 168 212 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-300\/95 {
  --tw-gradient-from: rgb(249 168 212 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400 {
  --tw-gradient-from: #f472b6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/0 {
  --tw-gradient-from: rgb(244 114 182 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/10 {
  --tw-gradient-from: rgb(244 114 182 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/100 {
  --tw-gradient-from: rgb(244 114 182 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/15 {
  --tw-gradient-from: rgb(244 114 182 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/20 {
  --tw-gradient-from: rgb(244 114 182 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/25 {
  --tw-gradient-from: rgb(244 114 182 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/30 {
  --tw-gradient-from: rgb(244 114 182 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/35 {
  --tw-gradient-from: rgb(244 114 182 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/40 {
  --tw-gradient-from: rgb(244 114 182 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/45 {
  --tw-gradient-from: rgb(244 114 182 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/5 {
  --tw-gradient-from: rgb(244 114 182 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/50 {
  --tw-gradient-from: rgb(244 114 182 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/55 {
  --tw-gradient-from: rgb(244 114 182 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/60 {
  --tw-gradient-from: rgb(244 114 182 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/65 {
  --tw-gradient-from: rgb(244 114 182 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/70 {
  --tw-gradient-from: rgb(244 114 182 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/75 {
  --tw-gradient-from: rgb(244 114 182 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/80 {
  --tw-gradient-from: rgb(244 114 182 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/85 {
  --tw-gradient-from: rgb(244 114 182 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/90 {
  --tw-gradient-from: rgb(244 114 182 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-400\/95 {
  --tw-gradient-from: rgb(244 114 182 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50 {
  --tw-gradient-from: #fdf2f8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/0 {
  --tw-gradient-from: rgb(253 242 248 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/10 {
  --tw-gradient-from: rgb(253 242 248 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/100 {
  --tw-gradient-from: rgb(253 242 248 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/15 {
  --tw-gradient-from: rgb(253 242 248 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/20 {
  --tw-gradient-from: rgb(253 242 248 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/25 {
  --tw-gradient-from: rgb(253 242 248 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/30 {
  --tw-gradient-from: rgb(253 242 248 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/35 {
  --tw-gradient-from: rgb(253 242 248 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/40 {
  --tw-gradient-from: rgb(253 242 248 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/45 {
  --tw-gradient-from: rgb(253 242 248 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/5 {
  --tw-gradient-from: rgb(253 242 248 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/50 {
  --tw-gradient-from: rgb(253 242 248 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/55 {
  --tw-gradient-from: rgb(253 242 248 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/60 {
  --tw-gradient-from: rgb(253 242 248 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/65 {
  --tw-gradient-from: rgb(253 242 248 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/70 {
  --tw-gradient-from: rgb(253 242 248 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/75 {
  --tw-gradient-from: rgb(253 242 248 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/80 {
  --tw-gradient-from: rgb(253 242 248 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/85 {
  --tw-gradient-from: rgb(253 242 248 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/90 {
  --tw-gradient-from: rgb(253 242 248 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-50\/95 {
  --tw-gradient-from: rgb(253 242 248 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500 {
  --tw-gradient-from: #ec4899 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/0 {
  --tw-gradient-from: rgb(236 72 153 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/10 {
  --tw-gradient-from: rgb(236 72 153 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/100 {
  --tw-gradient-from: rgb(236 72 153 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/15 {
  --tw-gradient-from: rgb(236 72 153 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/20 {
  --tw-gradient-from: rgb(236 72 153 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/25 {
  --tw-gradient-from: rgb(236 72 153 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/30 {
  --tw-gradient-from: rgb(236 72 153 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/35 {
  --tw-gradient-from: rgb(236 72 153 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/40 {
  --tw-gradient-from: rgb(236 72 153 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/45 {
  --tw-gradient-from: rgb(236 72 153 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/5 {
  --tw-gradient-from: rgb(236 72 153 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/50 {
  --tw-gradient-from: rgb(236 72 153 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/55 {
  --tw-gradient-from: rgb(236 72 153 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/60 {
  --tw-gradient-from: rgb(236 72 153 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/65 {
  --tw-gradient-from: rgb(236 72 153 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/70 {
  --tw-gradient-from: rgb(236 72 153 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/75 {
  --tw-gradient-from: rgb(236 72 153 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/80 {
  --tw-gradient-from: rgb(236 72 153 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/85 {
  --tw-gradient-from: rgb(236 72 153 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/90 {
  --tw-gradient-from: rgb(236 72 153 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-500\/95 {
  --tw-gradient-from: rgb(236 72 153 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600 {
  --tw-gradient-from: #db2777 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/0 {
  --tw-gradient-from: rgb(219 39 119 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/10 {
  --tw-gradient-from: rgb(219 39 119 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/100 {
  --tw-gradient-from: rgb(219 39 119 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/15 {
  --tw-gradient-from: rgb(219 39 119 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/20 {
  --tw-gradient-from: rgb(219 39 119 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/25 {
  --tw-gradient-from: rgb(219 39 119 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/30 {
  --tw-gradient-from: rgb(219 39 119 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/35 {
  --tw-gradient-from: rgb(219 39 119 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/40 {
  --tw-gradient-from: rgb(219 39 119 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/45 {
  --tw-gradient-from: rgb(219 39 119 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/5 {
  --tw-gradient-from: rgb(219 39 119 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/50 {
  --tw-gradient-from: rgb(219 39 119 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/55 {
  --tw-gradient-from: rgb(219 39 119 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/60 {
  --tw-gradient-from: rgb(219 39 119 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/65 {
  --tw-gradient-from: rgb(219 39 119 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/70 {
  --tw-gradient-from: rgb(219 39 119 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/75 {
  --tw-gradient-from: rgb(219 39 119 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/80 {
  --tw-gradient-from: rgb(219 39 119 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/85 {
  --tw-gradient-from: rgb(219 39 119 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/90 {
  --tw-gradient-from: rgb(219 39 119 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-600\/95 {
  --tw-gradient-from: rgb(219 39 119 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700 {
  --tw-gradient-from: #be185d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/0 {
  --tw-gradient-from: rgb(190 24 93 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/10 {
  --tw-gradient-from: rgb(190 24 93 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/100 {
  --tw-gradient-from: rgb(190 24 93 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/15 {
  --tw-gradient-from: rgb(190 24 93 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/20 {
  --tw-gradient-from: rgb(190 24 93 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/25 {
  --tw-gradient-from: rgb(190 24 93 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/30 {
  --tw-gradient-from: rgb(190 24 93 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/35 {
  --tw-gradient-from: rgb(190 24 93 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/40 {
  --tw-gradient-from: rgb(190 24 93 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/45 {
  --tw-gradient-from: rgb(190 24 93 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/5 {
  --tw-gradient-from: rgb(190 24 93 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/50 {
  --tw-gradient-from: rgb(190 24 93 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/55 {
  --tw-gradient-from: rgb(190 24 93 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/60 {
  --tw-gradient-from: rgb(190 24 93 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/65 {
  --tw-gradient-from: rgb(190 24 93 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/70 {
  --tw-gradient-from: rgb(190 24 93 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/75 {
  --tw-gradient-from: rgb(190 24 93 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/80 {
  --tw-gradient-from: rgb(190 24 93 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/85 {
  --tw-gradient-from: rgb(190 24 93 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/90 {
  --tw-gradient-from: rgb(190 24 93 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-700\/95 {
  --tw-gradient-from: rgb(190 24 93 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800 {
  --tw-gradient-from: #9d174d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/0 {
  --tw-gradient-from: rgb(157 23 77 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/10 {
  --tw-gradient-from: rgb(157 23 77 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/100 {
  --tw-gradient-from: rgb(157 23 77 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/15 {
  --tw-gradient-from: rgb(157 23 77 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/20 {
  --tw-gradient-from: rgb(157 23 77 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/25 {
  --tw-gradient-from: rgb(157 23 77 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/30 {
  --tw-gradient-from: rgb(157 23 77 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/35 {
  --tw-gradient-from: rgb(157 23 77 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/40 {
  --tw-gradient-from: rgb(157 23 77 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/45 {
  --tw-gradient-from: rgb(157 23 77 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/5 {
  --tw-gradient-from: rgb(157 23 77 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/50 {
  --tw-gradient-from: rgb(157 23 77 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/55 {
  --tw-gradient-from: rgb(157 23 77 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/60 {
  --tw-gradient-from: rgb(157 23 77 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/65 {
  --tw-gradient-from: rgb(157 23 77 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/70 {
  --tw-gradient-from: rgb(157 23 77 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/75 {
  --tw-gradient-from: rgb(157 23 77 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/80 {
  --tw-gradient-from: rgb(157 23 77 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/85 {
  --tw-gradient-from: rgb(157 23 77 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/90 {
  --tw-gradient-from: rgb(157 23 77 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-800\/95 {
  --tw-gradient-from: rgb(157 23 77 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900 {
  --tw-gradient-from: #831843 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/0 {
  --tw-gradient-from: rgb(131 24 67 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/10 {
  --tw-gradient-from: rgb(131 24 67 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/100 {
  --tw-gradient-from: rgb(131 24 67 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/15 {
  --tw-gradient-from: rgb(131 24 67 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/20 {
  --tw-gradient-from: rgb(131 24 67 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/25 {
  --tw-gradient-from: rgb(131 24 67 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/30 {
  --tw-gradient-from: rgb(131 24 67 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/35 {
  --tw-gradient-from: rgb(131 24 67 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/40 {
  --tw-gradient-from: rgb(131 24 67 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/45 {
  --tw-gradient-from: rgb(131 24 67 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/5 {
  --tw-gradient-from: rgb(131 24 67 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/50 {
  --tw-gradient-from: rgb(131 24 67 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/55 {
  --tw-gradient-from: rgb(131 24 67 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/60 {
  --tw-gradient-from: rgb(131 24 67 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/65 {
  --tw-gradient-from: rgb(131 24 67 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/70 {
  --tw-gradient-from: rgb(131 24 67 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/75 {
  --tw-gradient-from: rgb(131 24 67 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/80 {
  --tw-gradient-from: rgb(131 24 67 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/85 {
  --tw-gradient-from: rgb(131 24 67 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/90 {
  --tw-gradient-from: rgb(131 24 67 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-900\/95 {
  --tw-gradient-from: rgb(131 24 67 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950 {
  --tw-gradient-from: #500724 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/0 {
  --tw-gradient-from: rgb(80 7 36 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/10 {
  --tw-gradient-from: rgb(80 7 36 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/100 {
  --tw-gradient-from: rgb(80 7 36 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/15 {
  --tw-gradient-from: rgb(80 7 36 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/20 {
  --tw-gradient-from: rgb(80 7 36 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/25 {
  --tw-gradient-from: rgb(80 7 36 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/30 {
  --tw-gradient-from: rgb(80 7 36 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/35 {
  --tw-gradient-from: rgb(80 7 36 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/40 {
  --tw-gradient-from: rgb(80 7 36 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/45 {
  --tw-gradient-from: rgb(80 7 36 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/5 {
  --tw-gradient-from: rgb(80 7 36 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/50 {
  --tw-gradient-from: rgb(80 7 36 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/55 {
  --tw-gradient-from: rgb(80 7 36 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/60 {
  --tw-gradient-from: rgb(80 7 36 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/65 {
  --tw-gradient-from: rgb(80 7 36 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/70 {
  --tw-gradient-from: rgb(80 7 36 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/75 {
  --tw-gradient-from: rgb(80 7 36 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/80 {
  --tw-gradient-from: rgb(80 7 36 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/85 {
  --tw-gradient-from: rgb(80 7 36 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/90 {
  --tw-gradient-from: rgb(80 7 36 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-pink-950\/95 {
  --tw-gradient-from: rgb(80 7 36 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100 {
  --tw-gradient-from: #f3e8ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/0 {
  --tw-gradient-from: rgb(243 232 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/10 {
  --tw-gradient-from: rgb(243 232 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/100 {
  --tw-gradient-from: rgb(243 232 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/15 {
  --tw-gradient-from: rgb(243 232 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/20 {
  --tw-gradient-from: rgb(243 232 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/25 {
  --tw-gradient-from: rgb(243 232 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/30 {
  --tw-gradient-from: rgb(243 232 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/35 {
  --tw-gradient-from: rgb(243 232 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/40 {
  --tw-gradient-from: rgb(243 232 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/45 {
  --tw-gradient-from: rgb(243 232 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/5 {
  --tw-gradient-from: rgb(243 232 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/50 {
  --tw-gradient-from: rgb(243 232 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/55 {
  --tw-gradient-from: rgb(243 232 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/60 {
  --tw-gradient-from: rgb(243 232 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/65 {
  --tw-gradient-from: rgb(243 232 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/70 {
  --tw-gradient-from: rgb(243 232 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/75 {
  --tw-gradient-from: rgb(243 232 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/80 {
  --tw-gradient-from: rgb(243 232 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/85 {
  --tw-gradient-from: rgb(243 232 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/90 {
  --tw-gradient-from: rgb(243 232 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-100\/95 {
  --tw-gradient-from: rgb(243 232 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200 {
  --tw-gradient-from: #e9d5ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/0 {
  --tw-gradient-from: rgb(233 213 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/10 {
  --tw-gradient-from: rgb(233 213 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/100 {
  --tw-gradient-from: rgb(233 213 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/15 {
  --tw-gradient-from: rgb(233 213 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/20 {
  --tw-gradient-from: rgb(233 213 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/25 {
  --tw-gradient-from: rgb(233 213 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/30 {
  --tw-gradient-from: rgb(233 213 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/35 {
  --tw-gradient-from: rgb(233 213 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/40 {
  --tw-gradient-from: rgb(233 213 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/45 {
  --tw-gradient-from: rgb(233 213 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/5 {
  --tw-gradient-from: rgb(233 213 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/50 {
  --tw-gradient-from: rgb(233 213 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/55 {
  --tw-gradient-from: rgb(233 213 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/60 {
  --tw-gradient-from: rgb(233 213 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/65 {
  --tw-gradient-from: rgb(233 213 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/70 {
  --tw-gradient-from: rgb(233 213 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/75 {
  --tw-gradient-from: rgb(233 213 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/80 {
  --tw-gradient-from: rgb(233 213 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/85 {
  --tw-gradient-from: rgb(233 213 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/90 {
  --tw-gradient-from: rgb(233 213 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-200\/95 {
  --tw-gradient-from: rgb(233 213 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300 {
  --tw-gradient-from: #d8b4fe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/0 {
  --tw-gradient-from: rgb(216 180 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/10 {
  --tw-gradient-from: rgb(216 180 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/100 {
  --tw-gradient-from: rgb(216 180 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/15 {
  --tw-gradient-from: rgb(216 180 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/20 {
  --tw-gradient-from: rgb(216 180 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/25 {
  --tw-gradient-from: rgb(216 180 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/30 {
  --tw-gradient-from: rgb(216 180 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/35 {
  --tw-gradient-from: rgb(216 180 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/40 {
  --tw-gradient-from: rgb(216 180 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/45 {
  --tw-gradient-from: rgb(216 180 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/5 {
  --tw-gradient-from: rgb(216 180 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/50 {
  --tw-gradient-from: rgb(216 180 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/55 {
  --tw-gradient-from: rgb(216 180 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/60 {
  --tw-gradient-from: rgb(216 180 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/65 {
  --tw-gradient-from: rgb(216 180 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/70 {
  --tw-gradient-from: rgb(216 180 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/75 {
  --tw-gradient-from: rgb(216 180 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/80 {
  --tw-gradient-from: rgb(216 180 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/85 {
  --tw-gradient-from: rgb(216 180 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/90 {
  --tw-gradient-from: rgb(216 180 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-300\/95 {
  --tw-gradient-from: rgb(216 180 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400 {
  --tw-gradient-from: #c084fc var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/0 {
  --tw-gradient-from: rgb(192 132 252 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/10 {
  --tw-gradient-from: rgb(192 132 252 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/100 {
  --tw-gradient-from: rgb(192 132 252 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/15 {
  --tw-gradient-from: rgb(192 132 252 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/20 {
  --tw-gradient-from: rgb(192 132 252 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/25 {
  --tw-gradient-from: rgb(192 132 252 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/30 {
  --tw-gradient-from: rgb(192 132 252 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/35 {
  --tw-gradient-from: rgb(192 132 252 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/40 {
  --tw-gradient-from: rgb(192 132 252 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/45 {
  --tw-gradient-from: rgb(192 132 252 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/5 {
  --tw-gradient-from: rgb(192 132 252 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/50 {
  --tw-gradient-from: rgb(192 132 252 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/55 {
  --tw-gradient-from: rgb(192 132 252 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/60 {
  --tw-gradient-from: rgb(192 132 252 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/65 {
  --tw-gradient-from: rgb(192 132 252 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/70 {
  --tw-gradient-from: rgb(192 132 252 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/75 {
  --tw-gradient-from: rgb(192 132 252 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/80 {
  --tw-gradient-from: rgb(192 132 252 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/85 {
  --tw-gradient-from: rgb(192 132 252 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/90 {
  --tw-gradient-from: rgb(192 132 252 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-400\/95 {
  --tw-gradient-from: rgb(192 132 252 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50 {
  --tw-gradient-from: #faf5ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/0 {
  --tw-gradient-from: rgb(250 245 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/10 {
  --tw-gradient-from: rgb(250 245 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/100 {
  --tw-gradient-from: rgb(250 245 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/15 {
  --tw-gradient-from: rgb(250 245 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/20 {
  --tw-gradient-from: rgb(250 245 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/25 {
  --tw-gradient-from: rgb(250 245 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/30 {
  --tw-gradient-from: rgb(250 245 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/35 {
  --tw-gradient-from: rgb(250 245 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/40 {
  --tw-gradient-from: rgb(250 245 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/45 {
  --tw-gradient-from: rgb(250 245 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/5 {
  --tw-gradient-from: rgb(250 245 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/50 {
  --tw-gradient-from: rgb(250 245 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/55 {
  --tw-gradient-from: rgb(250 245 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/60 {
  --tw-gradient-from: rgb(250 245 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/65 {
  --tw-gradient-from: rgb(250 245 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/70 {
  --tw-gradient-from: rgb(250 245 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/75 {
  --tw-gradient-from: rgb(250 245 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/80 {
  --tw-gradient-from: rgb(250 245 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/85 {
  --tw-gradient-from: rgb(250 245 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/90 {
  --tw-gradient-from: rgb(250 245 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-50\/95 {
  --tw-gradient-from: rgb(250 245 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500 {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/0 {
  --tw-gradient-from: rgb(168 85 247 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/10 {
  --tw-gradient-from: rgb(168 85 247 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/100 {
  --tw-gradient-from: rgb(168 85 247 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/15 {
  --tw-gradient-from: rgb(168 85 247 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/20 {
  --tw-gradient-from: rgb(168 85 247 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/25 {
  --tw-gradient-from: rgb(168 85 247 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/30 {
  --tw-gradient-from: rgb(168 85 247 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/35 {
  --tw-gradient-from: rgb(168 85 247 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/40 {
  --tw-gradient-from: rgb(168 85 247 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/45 {
  --tw-gradient-from: rgb(168 85 247 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/5 {
  --tw-gradient-from: rgb(168 85 247 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/50 {
  --tw-gradient-from: rgb(168 85 247 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/55 {
  --tw-gradient-from: rgb(168 85 247 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/60 {
  --tw-gradient-from: rgb(168 85 247 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/65 {
  --tw-gradient-from: rgb(168 85 247 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/70 {
  --tw-gradient-from: rgb(168 85 247 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/75 {
  --tw-gradient-from: rgb(168 85 247 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/80 {
  --tw-gradient-from: rgb(168 85 247 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/85 {
  --tw-gradient-from: rgb(168 85 247 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/90 {
  --tw-gradient-from: rgb(168 85 247 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-500\/95 {
  --tw-gradient-from: rgb(168 85 247 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600 {
  --tw-gradient-from: #9333ea var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/0 {
  --tw-gradient-from: rgb(147 51 234 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/10 {
  --tw-gradient-from: rgb(147 51 234 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/100 {
  --tw-gradient-from: rgb(147 51 234 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/15 {
  --tw-gradient-from: rgb(147 51 234 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/20 {
  --tw-gradient-from: rgb(147 51 234 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/25 {
  --tw-gradient-from: rgb(147 51 234 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/30 {
  --tw-gradient-from: rgb(147 51 234 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/35 {
  --tw-gradient-from: rgb(147 51 234 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/40 {
  --tw-gradient-from: rgb(147 51 234 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/45 {
  --tw-gradient-from: rgb(147 51 234 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/5 {
  --tw-gradient-from: rgb(147 51 234 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/50 {
  --tw-gradient-from: rgb(147 51 234 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/55 {
  --tw-gradient-from: rgb(147 51 234 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/60 {
  --tw-gradient-from: rgb(147 51 234 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/65 {
  --tw-gradient-from: rgb(147 51 234 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/70 {
  --tw-gradient-from: rgb(147 51 234 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/75 {
  --tw-gradient-from: rgb(147 51 234 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/80 {
  --tw-gradient-from: rgb(147 51 234 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/85 {
  --tw-gradient-from: rgb(147 51 234 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/90 {
  --tw-gradient-from: rgb(147 51 234 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-600\/95 {
  --tw-gradient-from: rgb(147 51 234 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700 {
  --tw-gradient-from: #7e22ce var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/0 {
  --tw-gradient-from: rgb(126 34 206 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/10 {
  --tw-gradient-from: rgb(126 34 206 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/100 {
  --tw-gradient-from: rgb(126 34 206 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/15 {
  --tw-gradient-from: rgb(126 34 206 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/20 {
  --tw-gradient-from: rgb(126 34 206 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/25 {
  --tw-gradient-from: rgb(126 34 206 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/30 {
  --tw-gradient-from: rgb(126 34 206 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/35 {
  --tw-gradient-from: rgb(126 34 206 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/40 {
  --tw-gradient-from: rgb(126 34 206 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/45 {
  --tw-gradient-from: rgb(126 34 206 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/5 {
  --tw-gradient-from: rgb(126 34 206 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/50 {
  --tw-gradient-from: rgb(126 34 206 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/55 {
  --tw-gradient-from: rgb(126 34 206 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/60 {
  --tw-gradient-from: rgb(126 34 206 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/65 {
  --tw-gradient-from: rgb(126 34 206 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/70 {
  --tw-gradient-from: rgb(126 34 206 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/75 {
  --tw-gradient-from: rgb(126 34 206 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/80 {
  --tw-gradient-from: rgb(126 34 206 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/85 {
  --tw-gradient-from: rgb(126 34 206 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/90 {
  --tw-gradient-from: rgb(126 34 206 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-700\/95 {
  --tw-gradient-from: rgb(126 34 206 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800 {
  --tw-gradient-from: #6b21a8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/0 {
  --tw-gradient-from: rgb(107 33 168 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/10 {
  --tw-gradient-from: rgb(107 33 168 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/100 {
  --tw-gradient-from: rgb(107 33 168 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/15 {
  --tw-gradient-from: rgb(107 33 168 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/20 {
  --tw-gradient-from: rgb(107 33 168 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/25 {
  --tw-gradient-from: rgb(107 33 168 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/30 {
  --tw-gradient-from: rgb(107 33 168 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/35 {
  --tw-gradient-from: rgb(107 33 168 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/40 {
  --tw-gradient-from: rgb(107 33 168 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/45 {
  --tw-gradient-from: rgb(107 33 168 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/5 {
  --tw-gradient-from: rgb(107 33 168 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/50 {
  --tw-gradient-from: rgb(107 33 168 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/55 {
  --tw-gradient-from: rgb(107 33 168 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/60 {
  --tw-gradient-from: rgb(107 33 168 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/65 {
  --tw-gradient-from: rgb(107 33 168 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/70 {
  --tw-gradient-from: rgb(107 33 168 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/75 {
  --tw-gradient-from: rgb(107 33 168 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/80 {
  --tw-gradient-from: rgb(107 33 168 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/85 {
  --tw-gradient-from: rgb(107 33 168 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/90 {
  --tw-gradient-from: rgb(107 33 168 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-800\/95 {
  --tw-gradient-from: rgb(107 33 168 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900 {
  --tw-gradient-from: #581c87 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/0 {
  --tw-gradient-from: rgb(88 28 135 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/10 {
  --tw-gradient-from: rgb(88 28 135 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/100 {
  --tw-gradient-from: rgb(88 28 135 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/15 {
  --tw-gradient-from: rgb(88 28 135 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/20 {
  --tw-gradient-from: rgb(88 28 135 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/25 {
  --tw-gradient-from: rgb(88 28 135 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/30 {
  --tw-gradient-from: rgb(88 28 135 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/35 {
  --tw-gradient-from: rgb(88 28 135 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/40 {
  --tw-gradient-from: rgb(88 28 135 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/45 {
  --tw-gradient-from: rgb(88 28 135 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/5 {
  --tw-gradient-from: rgb(88 28 135 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/50 {
  --tw-gradient-from: rgb(88 28 135 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/55 {
  --tw-gradient-from: rgb(88 28 135 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/60 {
  --tw-gradient-from: rgb(88 28 135 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/65 {
  --tw-gradient-from: rgb(88 28 135 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/70 {
  --tw-gradient-from: rgb(88 28 135 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/75 {
  --tw-gradient-from: rgb(88 28 135 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/80 {
  --tw-gradient-from: rgb(88 28 135 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/85 {
  --tw-gradient-from: rgb(88 28 135 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/90 {
  --tw-gradient-from: rgb(88 28 135 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-900\/95 {
  --tw-gradient-from: rgb(88 28 135 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950 {
  --tw-gradient-from: #3b0764 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/0 {
  --tw-gradient-from: rgb(59 7 100 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/10 {
  --tw-gradient-from: rgb(59 7 100 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/100 {
  --tw-gradient-from: rgb(59 7 100 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/15 {
  --tw-gradient-from: rgb(59 7 100 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/20 {
  --tw-gradient-from: rgb(59 7 100 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/25 {
  --tw-gradient-from: rgb(59 7 100 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/30 {
  --tw-gradient-from: rgb(59 7 100 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/35 {
  --tw-gradient-from: rgb(59 7 100 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/40 {
  --tw-gradient-from: rgb(59 7 100 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/45 {
  --tw-gradient-from: rgb(59 7 100 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/5 {
  --tw-gradient-from: rgb(59 7 100 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/50 {
  --tw-gradient-from: rgb(59 7 100 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/55 {
  --tw-gradient-from: rgb(59 7 100 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/60 {
  --tw-gradient-from: rgb(59 7 100 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/65 {
  --tw-gradient-from: rgb(59 7 100 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/70 {
  --tw-gradient-from: rgb(59 7 100 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/75 {
  --tw-gradient-from: rgb(59 7 100 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/80 {
  --tw-gradient-from: rgb(59 7 100 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/85 {
  --tw-gradient-from: rgb(59 7 100 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/90 {
  --tw-gradient-from: rgb(59 7 100 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-purple-950\/95 {
  --tw-gradient-from: rgb(59 7 100 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100 {
  --tw-gradient-from: #fee2e2 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/0 {
  --tw-gradient-from: rgb(254 226 226 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/10 {
  --tw-gradient-from: rgb(254 226 226 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/100 {
  --tw-gradient-from: rgb(254 226 226 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/15 {
  --tw-gradient-from: rgb(254 226 226 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/20 {
  --tw-gradient-from: rgb(254 226 226 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/25 {
  --tw-gradient-from: rgb(254 226 226 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/30 {
  --tw-gradient-from: rgb(254 226 226 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/35 {
  --tw-gradient-from: rgb(254 226 226 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/40 {
  --tw-gradient-from: rgb(254 226 226 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/45 {
  --tw-gradient-from: rgb(254 226 226 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/5 {
  --tw-gradient-from: rgb(254 226 226 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/50 {
  --tw-gradient-from: rgb(254 226 226 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/55 {
  --tw-gradient-from: rgb(254 226 226 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/60 {
  --tw-gradient-from: rgb(254 226 226 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/65 {
  --tw-gradient-from: rgb(254 226 226 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/70 {
  --tw-gradient-from: rgb(254 226 226 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/75 {
  --tw-gradient-from: rgb(254 226 226 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/80 {
  --tw-gradient-from: rgb(254 226 226 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/85 {
  --tw-gradient-from: rgb(254 226 226 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/90 {
  --tw-gradient-from: rgb(254 226 226 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-100\/95 {
  --tw-gradient-from: rgb(254 226 226 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200 {
  --tw-gradient-from: #fecaca var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/0 {
  --tw-gradient-from: rgb(254 202 202 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/10 {
  --tw-gradient-from: rgb(254 202 202 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/100 {
  --tw-gradient-from: rgb(254 202 202 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/15 {
  --tw-gradient-from: rgb(254 202 202 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/20 {
  --tw-gradient-from: rgb(254 202 202 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/25 {
  --tw-gradient-from: rgb(254 202 202 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/30 {
  --tw-gradient-from: rgb(254 202 202 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/35 {
  --tw-gradient-from: rgb(254 202 202 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/40 {
  --tw-gradient-from: rgb(254 202 202 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/45 {
  --tw-gradient-from: rgb(254 202 202 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/5 {
  --tw-gradient-from: rgb(254 202 202 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/50 {
  --tw-gradient-from: rgb(254 202 202 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/55 {
  --tw-gradient-from: rgb(254 202 202 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/60 {
  --tw-gradient-from: rgb(254 202 202 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/65 {
  --tw-gradient-from: rgb(254 202 202 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/70 {
  --tw-gradient-from: rgb(254 202 202 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/75 {
  --tw-gradient-from: rgb(254 202 202 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/80 {
  --tw-gradient-from: rgb(254 202 202 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/85 {
  --tw-gradient-from: rgb(254 202 202 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/90 {
  --tw-gradient-from: rgb(254 202 202 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-200\/95 {
  --tw-gradient-from: rgb(254 202 202 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300 {
  --tw-gradient-from: #fca5a5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/0 {
  --tw-gradient-from: rgb(252 165 165 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/10 {
  --tw-gradient-from: rgb(252 165 165 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/100 {
  --tw-gradient-from: rgb(252 165 165 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/15 {
  --tw-gradient-from: rgb(252 165 165 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/20 {
  --tw-gradient-from: rgb(252 165 165 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/25 {
  --tw-gradient-from: rgb(252 165 165 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/30 {
  --tw-gradient-from: rgb(252 165 165 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/35 {
  --tw-gradient-from: rgb(252 165 165 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/40 {
  --tw-gradient-from: rgb(252 165 165 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/45 {
  --tw-gradient-from: rgb(252 165 165 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/5 {
  --tw-gradient-from: rgb(252 165 165 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/50 {
  --tw-gradient-from: rgb(252 165 165 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/55 {
  --tw-gradient-from: rgb(252 165 165 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/60 {
  --tw-gradient-from: rgb(252 165 165 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/65 {
  --tw-gradient-from: rgb(252 165 165 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/70 {
  --tw-gradient-from: rgb(252 165 165 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/75 {
  --tw-gradient-from: rgb(252 165 165 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/80 {
  --tw-gradient-from: rgb(252 165 165 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/85 {
  --tw-gradient-from: rgb(252 165 165 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/90 {
  --tw-gradient-from: rgb(252 165 165 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-300\/95 {
  --tw-gradient-from: rgb(252 165 165 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400 {
  --tw-gradient-from: #f87171 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/0 {
  --tw-gradient-from: rgb(248 113 113 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/10 {
  --tw-gradient-from: rgb(248 113 113 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/100 {
  --tw-gradient-from: rgb(248 113 113 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/15 {
  --tw-gradient-from: rgb(248 113 113 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/20 {
  --tw-gradient-from: rgb(248 113 113 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/25 {
  --tw-gradient-from: rgb(248 113 113 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/30 {
  --tw-gradient-from: rgb(248 113 113 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/35 {
  --tw-gradient-from: rgb(248 113 113 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/40 {
  --tw-gradient-from: rgb(248 113 113 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/45 {
  --tw-gradient-from: rgb(248 113 113 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/5 {
  --tw-gradient-from: rgb(248 113 113 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/50 {
  --tw-gradient-from: rgb(248 113 113 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/55 {
  --tw-gradient-from: rgb(248 113 113 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/60 {
  --tw-gradient-from: rgb(248 113 113 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/65 {
  --tw-gradient-from: rgb(248 113 113 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/70 {
  --tw-gradient-from: rgb(248 113 113 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/75 {
  --tw-gradient-from: rgb(248 113 113 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/80 {
  --tw-gradient-from: rgb(248 113 113 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/85 {
  --tw-gradient-from: rgb(248 113 113 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/90 {
  --tw-gradient-from: rgb(248 113 113 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-400\/95 {
  --tw-gradient-from: rgb(248 113 113 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50 {
  --tw-gradient-from: #fef2f2 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/0 {
  --tw-gradient-from: rgb(254 242 242 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/10 {
  --tw-gradient-from: rgb(254 242 242 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/100 {
  --tw-gradient-from: rgb(254 242 242 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/15 {
  --tw-gradient-from: rgb(254 242 242 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/20 {
  --tw-gradient-from: rgb(254 242 242 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/25 {
  --tw-gradient-from: rgb(254 242 242 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/30 {
  --tw-gradient-from: rgb(254 242 242 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/35 {
  --tw-gradient-from: rgb(254 242 242 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/40 {
  --tw-gradient-from: rgb(254 242 242 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/45 {
  --tw-gradient-from: rgb(254 242 242 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/5 {
  --tw-gradient-from: rgb(254 242 242 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/50 {
  --tw-gradient-from: rgb(254 242 242 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/55 {
  --tw-gradient-from: rgb(254 242 242 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/60 {
  --tw-gradient-from: rgb(254 242 242 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/65 {
  --tw-gradient-from: rgb(254 242 242 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/70 {
  --tw-gradient-from: rgb(254 242 242 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/75 {
  --tw-gradient-from: rgb(254 242 242 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/80 {
  --tw-gradient-from: rgb(254 242 242 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/85 {
  --tw-gradient-from: rgb(254 242 242 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/90 {
  --tw-gradient-from: rgb(254 242 242 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-50\/95 {
  --tw-gradient-from: rgb(254 242 242 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500 {
  --tw-gradient-from: #ef4444 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/0 {
  --tw-gradient-from: rgb(239 68 68 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/10 {
  --tw-gradient-from: rgb(239 68 68 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/100 {
  --tw-gradient-from: rgb(239 68 68 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/15 {
  --tw-gradient-from: rgb(239 68 68 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/20 {
  --tw-gradient-from: rgb(239 68 68 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/25 {
  --tw-gradient-from: rgb(239 68 68 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/30 {
  --tw-gradient-from: rgb(239 68 68 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/35 {
  --tw-gradient-from: rgb(239 68 68 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/40 {
  --tw-gradient-from: rgb(239 68 68 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/45 {
  --tw-gradient-from: rgb(239 68 68 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/5 {
  --tw-gradient-from: rgb(239 68 68 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/50 {
  --tw-gradient-from: rgb(239 68 68 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/55 {
  --tw-gradient-from: rgb(239 68 68 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/60 {
  --tw-gradient-from: rgb(239 68 68 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/65 {
  --tw-gradient-from: rgb(239 68 68 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/70 {
  --tw-gradient-from: rgb(239 68 68 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/75 {
  --tw-gradient-from: rgb(239 68 68 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/80 {
  --tw-gradient-from: rgb(239 68 68 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/85 {
  --tw-gradient-from: rgb(239 68 68 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/90 {
  --tw-gradient-from: rgb(239 68 68 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-500\/95 {
  --tw-gradient-from: rgb(239 68 68 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600 {
  --tw-gradient-from: #dc2626 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/0 {
  --tw-gradient-from: rgb(220 38 38 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/10 {
  --tw-gradient-from: rgb(220 38 38 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/100 {
  --tw-gradient-from: rgb(220 38 38 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/15 {
  --tw-gradient-from: rgb(220 38 38 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/20 {
  --tw-gradient-from: rgb(220 38 38 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/25 {
  --tw-gradient-from: rgb(220 38 38 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/30 {
  --tw-gradient-from: rgb(220 38 38 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/35 {
  --tw-gradient-from: rgb(220 38 38 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/40 {
  --tw-gradient-from: rgb(220 38 38 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/45 {
  --tw-gradient-from: rgb(220 38 38 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/5 {
  --tw-gradient-from: rgb(220 38 38 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/50 {
  --tw-gradient-from: rgb(220 38 38 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/55 {
  --tw-gradient-from: rgb(220 38 38 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/60 {
  --tw-gradient-from: rgb(220 38 38 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/65 {
  --tw-gradient-from: rgb(220 38 38 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/70 {
  --tw-gradient-from: rgb(220 38 38 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/75 {
  --tw-gradient-from: rgb(220 38 38 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/80 {
  --tw-gradient-from: rgb(220 38 38 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/85 {
  --tw-gradient-from: rgb(220 38 38 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/90 {
  --tw-gradient-from: rgb(220 38 38 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-600\/95 {
  --tw-gradient-from: rgb(220 38 38 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700 {
  --tw-gradient-from: #b91c1c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/0 {
  --tw-gradient-from: rgb(185 28 28 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/10 {
  --tw-gradient-from: rgb(185 28 28 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/100 {
  --tw-gradient-from: rgb(185 28 28 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/15 {
  --tw-gradient-from: rgb(185 28 28 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/20 {
  --tw-gradient-from: rgb(185 28 28 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/25 {
  --tw-gradient-from: rgb(185 28 28 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/30 {
  --tw-gradient-from: rgb(185 28 28 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/35 {
  --tw-gradient-from: rgb(185 28 28 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/40 {
  --tw-gradient-from: rgb(185 28 28 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/45 {
  --tw-gradient-from: rgb(185 28 28 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/5 {
  --tw-gradient-from: rgb(185 28 28 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/50 {
  --tw-gradient-from: rgb(185 28 28 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/55 {
  --tw-gradient-from: rgb(185 28 28 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/60 {
  --tw-gradient-from: rgb(185 28 28 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/65 {
  --tw-gradient-from: rgb(185 28 28 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/70 {
  --tw-gradient-from: rgb(185 28 28 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/75 {
  --tw-gradient-from: rgb(185 28 28 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/80 {
  --tw-gradient-from: rgb(185 28 28 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/85 {
  --tw-gradient-from: rgb(185 28 28 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/90 {
  --tw-gradient-from: rgb(185 28 28 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-700\/95 {
  --tw-gradient-from: rgb(185 28 28 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800 {
  --tw-gradient-from: #991b1b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/0 {
  --tw-gradient-from: rgb(153 27 27 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/10 {
  --tw-gradient-from: rgb(153 27 27 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/100 {
  --tw-gradient-from: rgb(153 27 27 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/15 {
  --tw-gradient-from: rgb(153 27 27 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/20 {
  --tw-gradient-from: rgb(153 27 27 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/25 {
  --tw-gradient-from: rgb(153 27 27 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/30 {
  --tw-gradient-from: rgb(153 27 27 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/35 {
  --tw-gradient-from: rgb(153 27 27 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/40 {
  --tw-gradient-from: rgb(153 27 27 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/45 {
  --tw-gradient-from: rgb(153 27 27 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/5 {
  --tw-gradient-from: rgb(153 27 27 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/50 {
  --tw-gradient-from: rgb(153 27 27 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/55 {
  --tw-gradient-from: rgb(153 27 27 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/60 {
  --tw-gradient-from: rgb(153 27 27 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/65 {
  --tw-gradient-from: rgb(153 27 27 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/70 {
  --tw-gradient-from: rgb(153 27 27 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/75 {
  --tw-gradient-from: rgb(153 27 27 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/80 {
  --tw-gradient-from: rgb(153 27 27 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/85 {
  --tw-gradient-from: rgb(153 27 27 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/90 {
  --tw-gradient-from: rgb(153 27 27 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-800\/95 {
  --tw-gradient-from: rgb(153 27 27 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900 {
  --tw-gradient-from: #7f1d1d var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/0 {
  --tw-gradient-from: rgb(127 29 29 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/10 {
  --tw-gradient-from: rgb(127 29 29 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/100 {
  --tw-gradient-from: rgb(127 29 29 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/15 {
  --tw-gradient-from: rgb(127 29 29 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/20 {
  --tw-gradient-from: rgb(127 29 29 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/25 {
  --tw-gradient-from: rgb(127 29 29 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/30 {
  --tw-gradient-from: rgb(127 29 29 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/35 {
  --tw-gradient-from: rgb(127 29 29 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/40 {
  --tw-gradient-from: rgb(127 29 29 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/45 {
  --tw-gradient-from: rgb(127 29 29 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/5 {
  --tw-gradient-from: rgb(127 29 29 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/50 {
  --tw-gradient-from: rgb(127 29 29 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/55 {
  --tw-gradient-from: rgb(127 29 29 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/60 {
  --tw-gradient-from: rgb(127 29 29 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/65 {
  --tw-gradient-from: rgb(127 29 29 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/70 {
  --tw-gradient-from: rgb(127 29 29 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/75 {
  --tw-gradient-from: rgb(127 29 29 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/80 {
  --tw-gradient-from: rgb(127 29 29 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/85 {
  --tw-gradient-from: rgb(127 29 29 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/90 {
  --tw-gradient-from: rgb(127 29 29 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-900\/95 {
  --tw-gradient-from: rgb(127 29 29 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950 {
  --tw-gradient-from: #450a0a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/0 {
  --tw-gradient-from: rgb(69 10 10 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/10 {
  --tw-gradient-from: rgb(69 10 10 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/100 {
  --tw-gradient-from: rgb(69 10 10 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/15 {
  --tw-gradient-from: rgb(69 10 10 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/20 {
  --tw-gradient-from: rgb(69 10 10 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/25 {
  --tw-gradient-from: rgb(69 10 10 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/30 {
  --tw-gradient-from: rgb(69 10 10 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/35 {
  --tw-gradient-from: rgb(69 10 10 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/40 {
  --tw-gradient-from: rgb(69 10 10 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/45 {
  --tw-gradient-from: rgb(69 10 10 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/5 {
  --tw-gradient-from: rgb(69 10 10 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/50 {
  --tw-gradient-from: rgb(69 10 10 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/55 {
  --tw-gradient-from: rgb(69 10 10 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/60 {
  --tw-gradient-from: rgb(69 10 10 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/65 {
  --tw-gradient-from: rgb(69 10 10 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/70 {
  --tw-gradient-from: rgb(69 10 10 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/75 {
  --tw-gradient-from: rgb(69 10 10 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/80 {
  --tw-gradient-from: rgb(69 10 10 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/85 {
  --tw-gradient-from: rgb(69 10 10 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/90 {
  --tw-gradient-from: rgb(69 10 10 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-red-950\/95 {
  --tw-gradient-from: rgb(69 10 10 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100 {
  --tw-gradient-from: #ffe4e6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/0 {
  --tw-gradient-from: rgb(255 228 230 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/10 {
  --tw-gradient-from: rgb(255 228 230 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/100 {
  --tw-gradient-from: rgb(255 228 230 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/15 {
  --tw-gradient-from: rgb(255 228 230 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/20 {
  --tw-gradient-from: rgb(255 228 230 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/25 {
  --tw-gradient-from: rgb(255 228 230 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/30 {
  --tw-gradient-from: rgb(255 228 230 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/35 {
  --tw-gradient-from: rgb(255 228 230 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/40 {
  --tw-gradient-from: rgb(255 228 230 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/45 {
  --tw-gradient-from: rgb(255 228 230 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/5 {
  --tw-gradient-from: rgb(255 228 230 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/50 {
  --tw-gradient-from: rgb(255 228 230 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/55 {
  --tw-gradient-from: rgb(255 228 230 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/60 {
  --tw-gradient-from: rgb(255 228 230 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/65 {
  --tw-gradient-from: rgb(255 228 230 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/70 {
  --tw-gradient-from: rgb(255 228 230 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/75 {
  --tw-gradient-from: rgb(255 228 230 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/80 {
  --tw-gradient-from: rgb(255 228 230 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/85 {
  --tw-gradient-from: rgb(255 228 230 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/90 {
  --tw-gradient-from: rgb(255 228 230 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-100\/95 {
  --tw-gradient-from: rgb(255 228 230 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200 {
  --tw-gradient-from: #fecdd3 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/0 {
  --tw-gradient-from: rgb(254 205 211 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/10 {
  --tw-gradient-from: rgb(254 205 211 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/100 {
  --tw-gradient-from: rgb(254 205 211 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/15 {
  --tw-gradient-from: rgb(254 205 211 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/20 {
  --tw-gradient-from: rgb(254 205 211 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/25 {
  --tw-gradient-from: rgb(254 205 211 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/30 {
  --tw-gradient-from: rgb(254 205 211 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/35 {
  --tw-gradient-from: rgb(254 205 211 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/40 {
  --tw-gradient-from: rgb(254 205 211 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/45 {
  --tw-gradient-from: rgb(254 205 211 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/5 {
  --tw-gradient-from: rgb(254 205 211 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/50 {
  --tw-gradient-from: rgb(254 205 211 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/55 {
  --tw-gradient-from: rgb(254 205 211 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/60 {
  --tw-gradient-from: rgb(254 205 211 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/65 {
  --tw-gradient-from: rgb(254 205 211 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/70 {
  --tw-gradient-from: rgb(254 205 211 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/75 {
  --tw-gradient-from: rgb(254 205 211 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/80 {
  --tw-gradient-from: rgb(254 205 211 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/85 {
  --tw-gradient-from: rgb(254 205 211 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/90 {
  --tw-gradient-from: rgb(254 205 211 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-200\/95 {
  --tw-gradient-from: rgb(254 205 211 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300 {
  --tw-gradient-from: #fda4af var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/0 {
  --tw-gradient-from: rgb(253 164 175 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/10 {
  --tw-gradient-from: rgb(253 164 175 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/100 {
  --tw-gradient-from: rgb(253 164 175 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/15 {
  --tw-gradient-from: rgb(253 164 175 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/20 {
  --tw-gradient-from: rgb(253 164 175 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/25 {
  --tw-gradient-from: rgb(253 164 175 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/30 {
  --tw-gradient-from: rgb(253 164 175 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/35 {
  --tw-gradient-from: rgb(253 164 175 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/40 {
  --tw-gradient-from: rgb(253 164 175 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/45 {
  --tw-gradient-from: rgb(253 164 175 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/5 {
  --tw-gradient-from: rgb(253 164 175 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/50 {
  --tw-gradient-from: rgb(253 164 175 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/55 {
  --tw-gradient-from: rgb(253 164 175 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/60 {
  --tw-gradient-from: rgb(253 164 175 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/65 {
  --tw-gradient-from: rgb(253 164 175 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/70 {
  --tw-gradient-from: rgb(253 164 175 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/75 {
  --tw-gradient-from: rgb(253 164 175 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/80 {
  --tw-gradient-from: rgb(253 164 175 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/85 {
  --tw-gradient-from: rgb(253 164 175 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/90 {
  --tw-gradient-from: rgb(253 164 175 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-300\/95 {
  --tw-gradient-from: rgb(253 164 175 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400 {
  --tw-gradient-from: #fb7185 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/0 {
  --tw-gradient-from: rgb(251 113 133 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/10 {
  --tw-gradient-from: rgb(251 113 133 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/100 {
  --tw-gradient-from: rgb(251 113 133 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/15 {
  --tw-gradient-from: rgb(251 113 133 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/20 {
  --tw-gradient-from: rgb(251 113 133 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/25 {
  --tw-gradient-from: rgb(251 113 133 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/30 {
  --tw-gradient-from: rgb(251 113 133 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/35 {
  --tw-gradient-from: rgb(251 113 133 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/40 {
  --tw-gradient-from: rgb(251 113 133 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/45 {
  --tw-gradient-from: rgb(251 113 133 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/5 {
  --tw-gradient-from: rgb(251 113 133 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/50 {
  --tw-gradient-from: rgb(251 113 133 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/55 {
  --tw-gradient-from: rgb(251 113 133 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/60 {
  --tw-gradient-from: rgb(251 113 133 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/65 {
  --tw-gradient-from: rgb(251 113 133 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/70 {
  --tw-gradient-from: rgb(251 113 133 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/75 {
  --tw-gradient-from: rgb(251 113 133 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/80 {
  --tw-gradient-from: rgb(251 113 133 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/85 {
  --tw-gradient-from: rgb(251 113 133 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/90 {
  --tw-gradient-from: rgb(251 113 133 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-400\/95 {
  --tw-gradient-from: rgb(251 113 133 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50 {
  --tw-gradient-from: #fff1f2 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/0 {
  --tw-gradient-from: rgb(255 241 242 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/10 {
  --tw-gradient-from: rgb(255 241 242 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/100 {
  --tw-gradient-from: rgb(255 241 242 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/15 {
  --tw-gradient-from: rgb(255 241 242 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/20 {
  --tw-gradient-from: rgb(255 241 242 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/25 {
  --tw-gradient-from: rgb(255 241 242 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/30 {
  --tw-gradient-from: rgb(255 241 242 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/35 {
  --tw-gradient-from: rgb(255 241 242 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/40 {
  --tw-gradient-from: rgb(255 241 242 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/45 {
  --tw-gradient-from: rgb(255 241 242 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/5 {
  --tw-gradient-from: rgb(255 241 242 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/50 {
  --tw-gradient-from: rgb(255 241 242 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/55 {
  --tw-gradient-from: rgb(255 241 242 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/60 {
  --tw-gradient-from: rgb(255 241 242 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/65 {
  --tw-gradient-from: rgb(255 241 242 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/70 {
  --tw-gradient-from: rgb(255 241 242 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/75 {
  --tw-gradient-from: rgb(255 241 242 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/80 {
  --tw-gradient-from: rgb(255 241 242 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/85 {
  --tw-gradient-from: rgb(255 241 242 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/90 {
  --tw-gradient-from: rgb(255 241 242 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-50\/95 {
  --tw-gradient-from: rgb(255 241 242 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500 {
  --tw-gradient-from: #f43f5e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/0 {
  --tw-gradient-from: rgb(244 63 94 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/10 {
  --tw-gradient-from: rgb(244 63 94 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/100 {
  --tw-gradient-from: rgb(244 63 94 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/15 {
  --tw-gradient-from: rgb(244 63 94 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/20 {
  --tw-gradient-from: rgb(244 63 94 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/25 {
  --tw-gradient-from: rgb(244 63 94 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/30 {
  --tw-gradient-from: rgb(244 63 94 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/35 {
  --tw-gradient-from: rgb(244 63 94 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/40 {
  --tw-gradient-from: rgb(244 63 94 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/45 {
  --tw-gradient-from: rgb(244 63 94 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/5 {
  --tw-gradient-from: rgb(244 63 94 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/50 {
  --tw-gradient-from: rgb(244 63 94 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/55 {
  --tw-gradient-from: rgb(244 63 94 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/60 {
  --tw-gradient-from: rgb(244 63 94 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/65 {
  --tw-gradient-from: rgb(244 63 94 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/70 {
  --tw-gradient-from: rgb(244 63 94 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/75 {
  --tw-gradient-from: rgb(244 63 94 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/80 {
  --tw-gradient-from: rgb(244 63 94 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/85 {
  --tw-gradient-from: rgb(244 63 94 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/90 {
  --tw-gradient-from: rgb(244 63 94 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-500\/95 {
  --tw-gradient-from: rgb(244 63 94 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600 {
  --tw-gradient-from: #e11d48 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/0 {
  --tw-gradient-from: rgb(225 29 72 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/10 {
  --tw-gradient-from: rgb(225 29 72 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/100 {
  --tw-gradient-from: rgb(225 29 72 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/15 {
  --tw-gradient-from: rgb(225 29 72 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/20 {
  --tw-gradient-from: rgb(225 29 72 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/25 {
  --tw-gradient-from: rgb(225 29 72 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/30 {
  --tw-gradient-from: rgb(225 29 72 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/35 {
  --tw-gradient-from: rgb(225 29 72 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/40 {
  --tw-gradient-from: rgb(225 29 72 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/45 {
  --tw-gradient-from: rgb(225 29 72 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/5 {
  --tw-gradient-from: rgb(225 29 72 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/50 {
  --tw-gradient-from: rgb(225 29 72 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/55 {
  --tw-gradient-from: rgb(225 29 72 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/60 {
  --tw-gradient-from: rgb(225 29 72 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/65 {
  --tw-gradient-from: rgb(225 29 72 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/70 {
  --tw-gradient-from: rgb(225 29 72 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/75 {
  --tw-gradient-from: rgb(225 29 72 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/80 {
  --tw-gradient-from: rgb(225 29 72 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/85 {
  --tw-gradient-from: rgb(225 29 72 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/90 {
  --tw-gradient-from: rgb(225 29 72 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-600\/95 {
  --tw-gradient-from: rgb(225 29 72 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700 {
  --tw-gradient-from: #be123c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/0 {
  --tw-gradient-from: rgb(190 18 60 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/10 {
  --tw-gradient-from: rgb(190 18 60 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/100 {
  --tw-gradient-from: rgb(190 18 60 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/15 {
  --tw-gradient-from: rgb(190 18 60 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/20 {
  --tw-gradient-from: rgb(190 18 60 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/25 {
  --tw-gradient-from: rgb(190 18 60 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/30 {
  --tw-gradient-from: rgb(190 18 60 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/35 {
  --tw-gradient-from: rgb(190 18 60 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/40 {
  --tw-gradient-from: rgb(190 18 60 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/45 {
  --tw-gradient-from: rgb(190 18 60 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/5 {
  --tw-gradient-from: rgb(190 18 60 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/50 {
  --tw-gradient-from: rgb(190 18 60 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/55 {
  --tw-gradient-from: rgb(190 18 60 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/60 {
  --tw-gradient-from: rgb(190 18 60 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/65 {
  --tw-gradient-from: rgb(190 18 60 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/70 {
  --tw-gradient-from: rgb(190 18 60 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/75 {
  --tw-gradient-from: rgb(190 18 60 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/80 {
  --tw-gradient-from: rgb(190 18 60 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/85 {
  --tw-gradient-from: rgb(190 18 60 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/90 {
  --tw-gradient-from: rgb(190 18 60 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-700\/95 {
  --tw-gradient-from: rgb(190 18 60 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800 {
  --tw-gradient-from: #9f1239 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/0 {
  --tw-gradient-from: rgb(159 18 57 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/10 {
  --tw-gradient-from: rgb(159 18 57 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/100 {
  --tw-gradient-from: rgb(159 18 57 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/15 {
  --tw-gradient-from: rgb(159 18 57 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/20 {
  --tw-gradient-from: rgb(159 18 57 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/25 {
  --tw-gradient-from: rgb(159 18 57 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/30 {
  --tw-gradient-from: rgb(159 18 57 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/35 {
  --tw-gradient-from: rgb(159 18 57 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/40 {
  --tw-gradient-from: rgb(159 18 57 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/45 {
  --tw-gradient-from: rgb(159 18 57 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/5 {
  --tw-gradient-from: rgb(159 18 57 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/50 {
  --tw-gradient-from: rgb(159 18 57 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/55 {
  --tw-gradient-from: rgb(159 18 57 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/60 {
  --tw-gradient-from: rgb(159 18 57 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/65 {
  --tw-gradient-from: rgb(159 18 57 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/70 {
  --tw-gradient-from: rgb(159 18 57 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/75 {
  --tw-gradient-from: rgb(159 18 57 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/80 {
  --tw-gradient-from: rgb(159 18 57 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/85 {
  --tw-gradient-from: rgb(159 18 57 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/90 {
  --tw-gradient-from: rgb(159 18 57 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-800\/95 {
  --tw-gradient-from: rgb(159 18 57 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900 {
  --tw-gradient-from: #881337 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/0 {
  --tw-gradient-from: rgb(136 19 55 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/10 {
  --tw-gradient-from: rgb(136 19 55 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/100 {
  --tw-gradient-from: rgb(136 19 55 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/15 {
  --tw-gradient-from: rgb(136 19 55 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/20 {
  --tw-gradient-from: rgb(136 19 55 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/25 {
  --tw-gradient-from: rgb(136 19 55 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/30 {
  --tw-gradient-from: rgb(136 19 55 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/35 {
  --tw-gradient-from: rgb(136 19 55 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/40 {
  --tw-gradient-from: rgb(136 19 55 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/45 {
  --tw-gradient-from: rgb(136 19 55 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/5 {
  --tw-gradient-from: rgb(136 19 55 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/50 {
  --tw-gradient-from: rgb(136 19 55 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/55 {
  --tw-gradient-from: rgb(136 19 55 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/60 {
  --tw-gradient-from: rgb(136 19 55 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/65 {
  --tw-gradient-from: rgb(136 19 55 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/70 {
  --tw-gradient-from: rgb(136 19 55 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/75 {
  --tw-gradient-from: rgb(136 19 55 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/80 {
  --tw-gradient-from: rgb(136 19 55 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/85 {
  --tw-gradient-from: rgb(136 19 55 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/90 {
  --tw-gradient-from: rgb(136 19 55 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-900\/95 {
  --tw-gradient-from: rgb(136 19 55 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950 {
  --tw-gradient-from: #4c0519 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/0 {
  --tw-gradient-from: rgb(76 5 25 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/10 {
  --tw-gradient-from: rgb(76 5 25 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/100 {
  --tw-gradient-from: rgb(76 5 25 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/15 {
  --tw-gradient-from: rgb(76 5 25 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/20 {
  --tw-gradient-from: rgb(76 5 25 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/25 {
  --tw-gradient-from: rgb(76 5 25 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/30 {
  --tw-gradient-from: rgb(76 5 25 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/35 {
  --tw-gradient-from: rgb(76 5 25 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/40 {
  --tw-gradient-from: rgb(76 5 25 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/45 {
  --tw-gradient-from: rgb(76 5 25 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/5 {
  --tw-gradient-from: rgb(76 5 25 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/50 {
  --tw-gradient-from: rgb(76 5 25 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/55 {
  --tw-gradient-from: rgb(76 5 25 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/60 {
  --tw-gradient-from: rgb(76 5 25 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/65 {
  --tw-gradient-from: rgb(76 5 25 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/70 {
  --tw-gradient-from: rgb(76 5 25 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/75 {
  --tw-gradient-from: rgb(76 5 25 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/80 {
  --tw-gradient-from: rgb(76 5 25 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/85 {
  --tw-gradient-from: rgb(76 5 25 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/90 {
  --tw-gradient-from: rgb(76 5 25 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-rose-950\/95 {
  --tw-gradient-from: rgb(76 5 25 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100 {
  --tw-gradient-from: #e0f2fe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/0 {
  --tw-gradient-from: rgb(224 242 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/10 {
  --tw-gradient-from: rgb(224 242 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/100 {
  --tw-gradient-from: rgb(224 242 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/15 {
  --tw-gradient-from: rgb(224 242 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/20 {
  --tw-gradient-from: rgb(224 242 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/25 {
  --tw-gradient-from: rgb(224 242 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/30 {
  --tw-gradient-from: rgb(224 242 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/35 {
  --tw-gradient-from: rgb(224 242 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/40 {
  --tw-gradient-from: rgb(224 242 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/45 {
  --tw-gradient-from: rgb(224 242 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/5 {
  --tw-gradient-from: rgb(224 242 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/50 {
  --tw-gradient-from: rgb(224 242 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/55 {
  --tw-gradient-from: rgb(224 242 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/60 {
  --tw-gradient-from: rgb(224 242 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/65 {
  --tw-gradient-from: rgb(224 242 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/70 {
  --tw-gradient-from: rgb(224 242 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/75 {
  --tw-gradient-from: rgb(224 242 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/80 {
  --tw-gradient-from: rgb(224 242 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/85 {
  --tw-gradient-from: rgb(224 242 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/90 {
  --tw-gradient-from: rgb(224 242 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-100\/95 {
  --tw-gradient-from: rgb(224 242 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200 {
  --tw-gradient-from: #bae6fd var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/0 {
  --tw-gradient-from: rgb(186 230 253 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/10 {
  --tw-gradient-from: rgb(186 230 253 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/100 {
  --tw-gradient-from: rgb(186 230 253 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/15 {
  --tw-gradient-from: rgb(186 230 253 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/20 {
  --tw-gradient-from: rgb(186 230 253 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/25 {
  --tw-gradient-from: rgb(186 230 253 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/30 {
  --tw-gradient-from: rgb(186 230 253 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/35 {
  --tw-gradient-from: rgb(186 230 253 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/40 {
  --tw-gradient-from: rgb(186 230 253 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/45 {
  --tw-gradient-from: rgb(186 230 253 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/5 {
  --tw-gradient-from: rgb(186 230 253 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/50 {
  --tw-gradient-from: rgb(186 230 253 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/55 {
  --tw-gradient-from: rgb(186 230 253 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/60 {
  --tw-gradient-from: rgb(186 230 253 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/65 {
  --tw-gradient-from: rgb(186 230 253 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/70 {
  --tw-gradient-from: rgb(186 230 253 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/75 {
  --tw-gradient-from: rgb(186 230 253 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/80 {
  --tw-gradient-from: rgb(186 230 253 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/85 {
  --tw-gradient-from: rgb(186 230 253 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/90 {
  --tw-gradient-from: rgb(186 230 253 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-200\/95 {
  --tw-gradient-from: rgb(186 230 253 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300 {
  --tw-gradient-from: #7dd3fc var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/0 {
  --tw-gradient-from: rgb(125 211 252 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/10 {
  --tw-gradient-from: rgb(125 211 252 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/100 {
  --tw-gradient-from: rgb(125 211 252 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/15 {
  --tw-gradient-from: rgb(125 211 252 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/20 {
  --tw-gradient-from: rgb(125 211 252 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/25 {
  --tw-gradient-from: rgb(125 211 252 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/30 {
  --tw-gradient-from: rgb(125 211 252 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/35 {
  --tw-gradient-from: rgb(125 211 252 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/40 {
  --tw-gradient-from: rgb(125 211 252 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/45 {
  --tw-gradient-from: rgb(125 211 252 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/5 {
  --tw-gradient-from: rgb(125 211 252 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/50 {
  --tw-gradient-from: rgb(125 211 252 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/55 {
  --tw-gradient-from: rgb(125 211 252 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/60 {
  --tw-gradient-from: rgb(125 211 252 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/65 {
  --tw-gradient-from: rgb(125 211 252 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/70 {
  --tw-gradient-from: rgb(125 211 252 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/75 {
  --tw-gradient-from: rgb(125 211 252 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/80 {
  --tw-gradient-from: rgb(125 211 252 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/85 {
  --tw-gradient-from: rgb(125 211 252 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/90 {
  --tw-gradient-from: rgb(125 211 252 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-300\/95 {
  --tw-gradient-from: rgb(125 211 252 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400 {
  --tw-gradient-from: #38bdf8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/0 {
  --tw-gradient-from: rgb(56 189 248 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/10 {
  --tw-gradient-from: rgb(56 189 248 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/100 {
  --tw-gradient-from: rgb(56 189 248 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/15 {
  --tw-gradient-from: rgb(56 189 248 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/20 {
  --tw-gradient-from: rgb(56 189 248 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/25 {
  --tw-gradient-from: rgb(56 189 248 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/30 {
  --tw-gradient-from: rgb(56 189 248 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/35 {
  --tw-gradient-from: rgb(56 189 248 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/40 {
  --tw-gradient-from: rgb(56 189 248 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/45 {
  --tw-gradient-from: rgb(56 189 248 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/5 {
  --tw-gradient-from: rgb(56 189 248 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/50 {
  --tw-gradient-from: rgb(56 189 248 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/55 {
  --tw-gradient-from: rgb(56 189 248 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/60 {
  --tw-gradient-from: rgb(56 189 248 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/65 {
  --tw-gradient-from: rgb(56 189 248 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/70 {
  --tw-gradient-from: rgb(56 189 248 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/75 {
  --tw-gradient-from: rgb(56 189 248 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/80 {
  --tw-gradient-from: rgb(56 189 248 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/85 {
  --tw-gradient-from: rgb(56 189 248 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/90 {
  --tw-gradient-from: rgb(56 189 248 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-400\/95 {
  --tw-gradient-from: rgb(56 189 248 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50 {
  --tw-gradient-from: #f0f9ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/0 {
  --tw-gradient-from: rgb(240 249 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/10 {
  --tw-gradient-from: rgb(240 249 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/100 {
  --tw-gradient-from: rgb(240 249 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/15 {
  --tw-gradient-from: rgb(240 249 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/20 {
  --tw-gradient-from: rgb(240 249 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/25 {
  --tw-gradient-from: rgb(240 249 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/30 {
  --tw-gradient-from: rgb(240 249 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/35 {
  --tw-gradient-from: rgb(240 249 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/40 {
  --tw-gradient-from: rgb(240 249 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/45 {
  --tw-gradient-from: rgb(240 249 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/5 {
  --tw-gradient-from: rgb(240 249 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/50 {
  --tw-gradient-from: rgb(240 249 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/55 {
  --tw-gradient-from: rgb(240 249 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/60 {
  --tw-gradient-from: rgb(240 249 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/65 {
  --tw-gradient-from: rgb(240 249 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/70 {
  --tw-gradient-from: rgb(240 249 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/75 {
  --tw-gradient-from: rgb(240 249 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/80 {
  --tw-gradient-from: rgb(240 249 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/85 {
  --tw-gradient-from: rgb(240 249 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/90 {
  --tw-gradient-from: rgb(240 249 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-50\/95 {
  --tw-gradient-from: rgb(240 249 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500 {
  --tw-gradient-from: #0ea5e9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/0 {
  --tw-gradient-from: rgb(14 165 233 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/10 {
  --tw-gradient-from: rgb(14 165 233 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/100 {
  --tw-gradient-from: rgb(14 165 233 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/15 {
  --tw-gradient-from: rgb(14 165 233 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/20 {
  --tw-gradient-from: rgb(14 165 233 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/25 {
  --tw-gradient-from: rgb(14 165 233 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/30 {
  --tw-gradient-from: rgb(14 165 233 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/35 {
  --tw-gradient-from: rgb(14 165 233 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/40 {
  --tw-gradient-from: rgb(14 165 233 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/45 {
  --tw-gradient-from: rgb(14 165 233 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/5 {
  --tw-gradient-from: rgb(14 165 233 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/50 {
  --tw-gradient-from: rgb(14 165 233 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/55 {
  --tw-gradient-from: rgb(14 165 233 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/60 {
  --tw-gradient-from: rgb(14 165 233 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/65 {
  --tw-gradient-from: rgb(14 165 233 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/70 {
  --tw-gradient-from: rgb(14 165 233 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/75 {
  --tw-gradient-from: rgb(14 165 233 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/80 {
  --tw-gradient-from: rgb(14 165 233 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/85 {
  --tw-gradient-from: rgb(14 165 233 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/90 {
  --tw-gradient-from: rgb(14 165 233 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-500\/95 {
  --tw-gradient-from: rgb(14 165 233 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600 {
  --tw-gradient-from: #0284c7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/0 {
  --tw-gradient-from: rgb(2 132 199 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/10 {
  --tw-gradient-from: rgb(2 132 199 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/100 {
  --tw-gradient-from: rgb(2 132 199 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/15 {
  --tw-gradient-from: rgb(2 132 199 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/20 {
  --tw-gradient-from: rgb(2 132 199 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/25 {
  --tw-gradient-from: rgb(2 132 199 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/30 {
  --tw-gradient-from: rgb(2 132 199 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/35 {
  --tw-gradient-from: rgb(2 132 199 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/40 {
  --tw-gradient-from: rgb(2 132 199 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/45 {
  --tw-gradient-from: rgb(2 132 199 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/5 {
  --tw-gradient-from: rgb(2 132 199 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/50 {
  --tw-gradient-from: rgb(2 132 199 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/55 {
  --tw-gradient-from: rgb(2 132 199 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/60 {
  --tw-gradient-from: rgb(2 132 199 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/65 {
  --tw-gradient-from: rgb(2 132 199 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/70 {
  --tw-gradient-from: rgb(2 132 199 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/75 {
  --tw-gradient-from: rgb(2 132 199 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/80 {
  --tw-gradient-from: rgb(2 132 199 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/85 {
  --tw-gradient-from: rgb(2 132 199 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/90 {
  --tw-gradient-from: rgb(2 132 199 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-600\/95 {
  --tw-gradient-from: rgb(2 132 199 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700 {
  --tw-gradient-from: #0369a1 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/0 {
  --tw-gradient-from: rgb(3 105 161 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/10 {
  --tw-gradient-from: rgb(3 105 161 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/100 {
  --tw-gradient-from: rgb(3 105 161 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/15 {
  --tw-gradient-from: rgb(3 105 161 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/20 {
  --tw-gradient-from: rgb(3 105 161 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/25 {
  --tw-gradient-from: rgb(3 105 161 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/30 {
  --tw-gradient-from: rgb(3 105 161 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/35 {
  --tw-gradient-from: rgb(3 105 161 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/40 {
  --tw-gradient-from: rgb(3 105 161 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/45 {
  --tw-gradient-from: rgb(3 105 161 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/5 {
  --tw-gradient-from: rgb(3 105 161 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/50 {
  --tw-gradient-from: rgb(3 105 161 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/55 {
  --tw-gradient-from: rgb(3 105 161 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/60 {
  --tw-gradient-from: rgb(3 105 161 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/65 {
  --tw-gradient-from: rgb(3 105 161 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/70 {
  --tw-gradient-from: rgb(3 105 161 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/75 {
  --tw-gradient-from: rgb(3 105 161 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/80 {
  --tw-gradient-from: rgb(3 105 161 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/85 {
  --tw-gradient-from: rgb(3 105 161 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/90 {
  --tw-gradient-from: rgb(3 105 161 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-700\/95 {
  --tw-gradient-from: rgb(3 105 161 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800 {
  --tw-gradient-from: #075985 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/0 {
  --tw-gradient-from: rgb(7 89 133 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/10 {
  --tw-gradient-from: rgb(7 89 133 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/100 {
  --tw-gradient-from: rgb(7 89 133 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/15 {
  --tw-gradient-from: rgb(7 89 133 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/20 {
  --tw-gradient-from: rgb(7 89 133 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/25 {
  --tw-gradient-from: rgb(7 89 133 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/30 {
  --tw-gradient-from: rgb(7 89 133 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/35 {
  --tw-gradient-from: rgb(7 89 133 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/40 {
  --tw-gradient-from: rgb(7 89 133 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/45 {
  --tw-gradient-from: rgb(7 89 133 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/5 {
  --tw-gradient-from: rgb(7 89 133 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/50 {
  --tw-gradient-from: rgb(7 89 133 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/55 {
  --tw-gradient-from: rgb(7 89 133 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/60 {
  --tw-gradient-from: rgb(7 89 133 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/65 {
  --tw-gradient-from: rgb(7 89 133 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/70 {
  --tw-gradient-from: rgb(7 89 133 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/75 {
  --tw-gradient-from: rgb(7 89 133 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/80 {
  --tw-gradient-from: rgb(7 89 133 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/85 {
  --tw-gradient-from: rgb(7 89 133 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/90 {
  --tw-gradient-from: rgb(7 89 133 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-800\/95 {
  --tw-gradient-from: rgb(7 89 133 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900 {
  --tw-gradient-from: #0c4a6e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/0 {
  --tw-gradient-from: rgb(12 74 110 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/10 {
  --tw-gradient-from: rgb(12 74 110 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/100 {
  --tw-gradient-from: rgb(12 74 110 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/15 {
  --tw-gradient-from: rgb(12 74 110 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/20 {
  --tw-gradient-from: rgb(12 74 110 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/25 {
  --tw-gradient-from: rgb(12 74 110 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/30 {
  --tw-gradient-from: rgb(12 74 110 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/35 {
  --tw-gradient-from: rgb(12 74 110 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/40 {
  --tw-gradient-from: rgb(12 74 110 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/45 {
  --tw-gradient-from: rgb(12 74 110 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/5 {
  --tw-gradient-from: rgb(12 74 110 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/50 {
  --tw-gradient-from: rgb(12 74 110 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/55 {
  --tw-gradient-from: rgb(12 74 110 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/60 {
  --tw-gradient-from: rgb(12 74 110 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/65 {
  --tw-gradient-from: rgb(12 74 110 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/70 {
  --tw-gradient-from: rgb(12 74 110 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/75 {
  --tw-gradient-from: rgb(12 74 110 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/80 {
  --tw-gradient-from: rgb(12 74 110 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/85 {
  --tw-gradient-from: rgb(12 74 110 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/90 {
  --tw-gradient-from: rgb(12 74 110 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-900\/95 {
  --tw-gradient-from: rgb(12 74 110 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950 {
  --tw-gradient-from: #082f49 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/0 {
  --tw-gradient-from: rgb(8 47 73 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/10 {
  --tw-gradient-from: rgb(8 47 73 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/100 {
  --tw-gradient-from: rgb(8 47 73 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/15 {
  --tw-gradient-from: rgb(8 47 73 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/20 {
  --tw-gradient-from: rgb(8 47 73 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/25 {
  --tw-gradient-from: rgb(8 47 73 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/30 {
  --tw-gradient-from: rgb(8 47 73 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/35 {
  --tw-gradient-from: rgb(8 47 73 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/40 {
  --tw-gradient-from: rgb(8 47 73 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/45 {
  --tw-gradient-from: rgb(8 47 73 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/5 {
  --tw-gradient-from: rgb(8 47 73 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/50 {
  --tw-gradient-from: rgb(8 47 73 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/55 {
  --tw-gradient-from: rgb(8 47 73 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/60 {
  --tw-gradient-from: rgb(8 47 73 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/65 {
  --tw-gradient-from: rgb(8 47 73 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/70 {
  --tw-gradient-from: rgb(8 47 73 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/75 {
  --tw-gradient-from: rgb(8 47 73 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/80 {
  --tw-gradient-from: rgb(8 47 73 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/85 {
  --tw-gradient-from: rgb(8 47 73 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/90 {
  --tw-gradient-from: rgb(8 47 73 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-sky-950\/95 {
  --tw-gradient-from: rgb(8 47 73 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100 {
  --tw-gradient-from: #f1f5f9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/0 {
  --tw-gradient-from: rgb(241 245 249 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/10 {
  --tw-gradient-from: rgb(241 245 249 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/100 {
  --tw-gradient-from: rgb(241 245 249 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/15 {
  --tw-gradient-from: rgb(241 245 249 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/20 {
  --tw-gradient-from: rgb(241 245 249 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/25 {
  --tw-gradient-from: rgb(241 245 249 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/30 {
  --tw-gradient-from: rgb(241 245 249 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/35 {
  --tw-gradient-from: rgb(241 245 249 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/40 {
  --tw-gradient-from: rgb(241 245 249 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/45 {
  --tw-gradient-from: rgb(241 245 249 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/5 {
  --tw-gradient-from: rgb(241 245 249 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/50 {
  --tw-gradient-from: rgb(241 245 249 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/55 {
  --tw-gradient-from: rgb(241 245 249 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/60 {
  --tw-gradient-from: rgb(241 245 249 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/65 {
  --tw-gradient-from: rgb(241 245 249 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/70 {
  --tw-gradient-from: rgb(241 245 249 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/75 {
  --tw-gradient-from: rgb(241 245 249 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/80 {
  --tw-gradient-from: rgb(241 245 249 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/85 {
  --tw-gradient-from: rgb(241 245 249 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/90 {
  --tw-gradient-from: rgb(241 245 249 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-100\/95 {
  --tw-gradient-from: rgb(241 245 249 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200 {
  --tw-gradient-from: #e2e8f0 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/0 {
  --tw-gradient-from: rgb(226 232 240 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/10 {
  --tw-gradient-from: rgb(226 232 240 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/100 {
  --tw-gradient-from: rgb(226 232 240 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/15 {
  --tw-gradient-from: rgb(226 232 240 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/20 {
  --tw-gradient-from: rgb(226 232 240 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/25 {
  --tw-gradient-from: rgb(226 232 240 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/30 {
  --tw-gradient-from: rgb(226 232 240 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/35 {
  --tw-gradient-from: rgb(226 232 240 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/40 {
  --tw-gradient-from: rgb(226 232 240 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/45 {
  --tw-gradient-from: rgb(226 232 240 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/5 {
  --tw-gradient-from: rgb(226 232 240 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/50 {
  --tw-gradient-from: rgb(226 232 240 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/55 {
  --tw-gradient-from: rgb(226 232 240 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/60 {
  --tw-gradient-from: rgb(226 232 240 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/65 {
  --tw-gradient-from: rgb(226 232 240 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/70 {
  --tw-gradient-from: rgb(226 232 240 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/75 {
  --tw-gradient-from: rgb(226 232 240 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/80 {
  --tw-gradient-from: rgb(226 232 240 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/85 {
  --tw-gradient-from: rgb(226 232 240 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/90 {
  --tw-gradient-from: rgb(226 232 240 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-200\/95 {
  --tw-gradient-from: rgb(226 232 240 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300 {
  --tw-gradient-from: #cbd5e1 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/0 {
  --tw-gradient-from: rgb(203 213 225 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/10 {
  --tw-gradient-from: rgb(203 213 225 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/100 {
  --tw-gradient-from: rgb(203 213 225 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/15 {
  --tw-gradient-from: rgb(203 213 225 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/20 {
  --tw-gradient-from: rgb(203 213 225 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/25 {
  --tw-gradient-from: rgb(203 213 225 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/30 {
  --tw-gradient-from: rgb(203 213 225 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/35 {
  --tw-gradient-from: rgb(203 213 225 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/40 {
  --tw-gradient-from: rgb(203 213 225 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/45 {
  --tw-gradient-from: rgb(203 213 225 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/5 {
  --tw-gradient-from: rgb(203 213 225 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/50 {
  --tw-gradient-from: rgb(203 213 225 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/55 {
  --tw-gradient-from: rgb(203 213 225 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/60 {
  --tw-gradient-from: rgb(203 213 225 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/65 {
  --tw-gradient-from: rgb(203 213 225 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/70 {
  --tw-gradient-from: rgb(203 213 225 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/75 {
  --tw-gradient-from: rgb(203 213 225 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/80 {
  --tw-gradient-from: rgb(203 213 225 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/85 {
  --tw-gradient-from: rgb(203 213 225 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/90 {
  --tw-gradient-from: rgb(203 213 225 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-300\/95 {
  --tw-gradient-from: rgb(203 213 225 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400 {
  --tw-gradient-from: #94a3b8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/0 {
  --tw-gradient-from: rgb(148 163 184 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/10 {
  --tw-gradient-from: rgb(148 163 184 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/100 {
  --tw-gradient-from: rgb(148 163 184 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/15 {
  --tw-gradient-from: rgb(148 163 184 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/20 {
  --tw-gradient-from: rgb(148 163 184 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/25 {
  --tw-gradient-from: rgb(148 163 184 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/30 {
  --tw-gradient-from: rgb(148 163 184 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/35 {
  --tw-gradient-from: rgb(148 163 184 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/40 {
  --tw-gradient-from: rgb(148 163 184 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/45 {
  --tw-gradient-from: rgb(148 163 184 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/5 {
  --tw-gradient-from: rgb(148 163 184 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/50 {
  --tw-gradient-from: rgb(148 163 184 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/55 {
  --tw-gradient-from: rgb(148 163 184 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/60 {
  --tw-gradient-from: rgb(148 163 184 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/65 {
  --tw-gradient-from: rgb(148 163 184 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/70 {
  --tw-gradient-from: rgb(148 163 184 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/75 {
  --tw-gradient-from: rgb(148 163 184 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/80 {
  --tw-gradient-from: rgb(148 163 184 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/85 {
  --tw-gradient-from: rgb(148 163 184 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/90 {
  --tw-gradient-from: rgb(148 163 184 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-400\/95 {
  --tw-gradient-from: rgb(148 163 184 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50 {
  --tw-gradient-from: #f8fafc var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/0 {
  --tw-gradient-from: rgb(248 250 252 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/10 {
  --tw-gradient-from: rgb(248 250 252 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/100 {
  --tw-gradient-from: rgb(248 250 252 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/15 {
  --tw-gradient-from: rgb(248 250 252 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/20 {
  --tw-gradient-from: rgb(248 250 252 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/25 {
  --tw-gradient-from: rgb(248 250 252 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/30 {
  --tw-gradient-from: rgb(248 250 252 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/35 {
  --tw-gradient-from: rgb(248 250 252 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/40 {
  --tw-gradient-from: rgb(248 250 252 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/45 {
  --tw-gradient-from: rgb(248 250 252 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/5 {
  --tw-gradient-from: rgb(248 250 252 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/50 {
  --tw-gradient-from: rgb(248 250 252 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/55 {
  --tw-gradient-from: rgb(248 250 252 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/60 {
  --tw-gradient-from: rgb(248 250 252 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/65 {
  --tw-gradient-from: rgb(248 250 252 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/70 {
  --tw-gradient-from: rgb(248 250 252 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/75 {
  --tw-gradient-from: rgb(248 250 252 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/80 {
  --tw-gradient-from: rgb(248 250 252 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/85 {
  --tw-gradient-from: rgb(248 250 252 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/90 {
  --tw-gradient-from: rgb(248 250 252 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-50\/95 {
  --tw-gradient-from: rgb(248 250 252 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500 {
  --tw-gradient-from: #64748b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/0 {
  --tw-gradient-from: rgb(100 116 139 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/10 {
  --tw-gradient-from: rgb(100 116 139 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/100 {
  --tw-gradient-from: rgb(100 116 139 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/15 {
  --tw-gradient-from: rgb(100 116 139 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/20 {
  --tw-gradient-from: rgb(100 116 139 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/25 {
  --tw-gradient-from: rgb(100 116 139 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/30 {
  --tw-gradient-from: rgb(100 116 139 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/35 {
  --tw-gradient-from: rgb(100 116 139 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/40 {
  --tw-gradient-from: rgb(100 116 139 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/45 {
  --tw-gradient-from: rgb(100 116 139 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/5 {
  --tw-gradient-from: rgb(100 116 139 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/50 {
  --tw-gradient-from: rgb(100 116 139 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/55 {
  --tw-gradient-from: rgb(100 116 139 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/60 {
  --tw-gradient-from: rgb(100 116 139 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/65 {
  --tw-gradient-from: rgb(100 116 139 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/70 {
  --tw-gradient-from: rgb(100 116 139 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/75 {
  --tw-gradient-from: rgb(100 116 139 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/80 {
  --tw-gradient-from: rgb(100 116 139 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/85 {
  --tw-gradient-from: rgb(100 116 139 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/90 {
  --tw-gradient-from: rgb(100 116 139 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-500\/95 {
  --tw-gradient-from: rgb(100 116 139 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600 {
  --tw-gradient-from: #475569 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/0 {
  --tw-gradient-from: rgb(71 85 105 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/10 {
  --tw-gradient-from: rgb(71 85 105 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/100 {
  --tw-gradient-from: rgb(71 85 105 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/15 {
  --tw-gradient-from: rgb(71 85 105 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/20 {
  --tw-gradient-from: rgb(71 85 105 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/25 {
  --tw-gradient-from: rgb(71 85 105 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/30 {
  --tw-gradient-from: rgb(71 85 105 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/35 {
  --tw-gradient-from: rgb(71 85 105 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/40 {
  --tw-gradient-from: rgb(71 85 105 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/45 {
  --tw-gradient-from: rgb(71 85 105 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/5 {
  --tw-gradient-from: rgb(71 85 105 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/50 {
  --tw-gradient-from: rgb(71 85 105 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/55 {
  --tw-gradient-from: rgb(71 85 105 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/60 {
  --tw-gradient-from: rgb(71 85 105 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/65 {
  --tw-gradient-from: rgb(71 85 105 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/70 {
  --tw-gradient-from: rgb(71 85 105 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/75 {
  --tw-gradient-from: rgb(71 85 105 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/80 {
  --tw-gradient-from: rgb(71 85 105 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/85 {
  --tw-gradient-from: rgb(71 85 105 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/90 {
  --tw-gradient-from: rgb(71 85 105 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-600\/95 {
  --tw-gradient-from: rgb(71 85 105 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700 {
  --tw-gradient-from: #334155 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/0 {
  --tw-gradient-from: rgb(51 65 85 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/10 {
  --tw-gradient-from: rgb(51 65 85 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/100 {
  --tw-gradient-from: rgb(51 65 85 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/15 {
  --tw-gradient-from: rgb(51 65 85 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/20 {
  --tw-gradient-from: rgb(51 65 85 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/25 {
  --tw-gradient-from: rgb(51 65 85 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/30 {
  --tw-gradient-from: rgb(51 65 85 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/35 {
  --tw-gradient-from: rgb(51 65 85 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/40 {
  --tw-gradient-from: rgb(51 65 85 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/45 {
  --tw-gradient-from: rgb(51 65 85 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/5 {
  --tw-gradient-from: rgb(51 65 85 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/50 {
  --tw-gradient-from: rgb(51 65 85 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/55 {
  --tw-gradient-from: rgb(51 65 85 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/60 {
  --tw-gradient-from: rgb(51 65 85 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/65 {
  --tw-gradient-from: rgb(51 65 85 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/70 {
  --tw-gradient-from: rgb(51 65 85 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/75 {
  --tw-gradient-from: rgb(51 65 85 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/80 {
  --tw-gradient-from: rgb(51 65 85 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/85 {
  --tw-gradient-from: rgb(51 65 85 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/90 {
  --tw-gradient-from: rgb(51 65 85 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-700\/95 {
  --tw-gradient-from: rgb(51 65 85 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800 {
  --tw-gradient-from: #1e293b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/0 {
  --tw-gradient-from: rgb(30 41 59 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/10 {
  --tw-gradient-from: rgb(30 41 59 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/100 {
  --tw-gradient-from: rgb(30 41 59 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/15 {
  --tw-gradient-from: rgb(30 41 59 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/20 {
  --tw-gradient-from: rgb(30 41 59 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/25 {
  --tw-gradient-from: rgb(30 41 59 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/30 {
  --tw-gradient-from: rgb(30 41 59 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/35 {
  --tw-gradient-from: rgb(30 41 59 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/40 {
  --tw-gradient-from: rgb(30 41 59 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/45 {
  --tw-gradient-from: rgb(30 41 59 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/5 {
  --tw-gradient-from: rgb(30 41 59 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/50 {
  --tw-gradient-from: rgb(30 41 59 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/55 {
  --tw-gradient-from: rgb(30 41 59 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/60 {
  --tw-gradient-from: rgb(30 41 59 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/65 {
  --tw-gradient-from: rgb(30 41 59 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/70 {
  --tw-gradient-from: rgb(30 41 59 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/75 {
  --tw-gradient-from: rgb(30 41 59 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/80 {
  --tw-gradient-from: rgb(30 41 59 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/85 {
  --tw-gradient-from: rgb(30 41 59 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/90 {
  --tw-gradient-from: rgb(30 41 59 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-800\/95 {
  --tw-gradient-from: rgb(30 41 59 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900 {
  --tw-gradient-from: #0f172a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/0 {
  --tw-gradient-from: rgb(15 23 42 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/10 {
  --tw-gradient-from: rgb(15 23 42 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/100 {
  --tw-gradient-from: rgb(15 23 42 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/15 {
  --tw-gradient-from: rgb(15 23 42 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/20 {
  --tw-gradient-from: rgb(15 23 42 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/25 {
  --tw-gradient-from: rgb(15 23 42 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/30 {
  --tw-gradient-from: rgb(15 23 42 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/35 {
  --tw-gradient-from: rgb(15 23 42 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/40 {
  --tw-gradient-from: rgb(15 23 42 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/45 {
  --tw-gradient-from: rgb(15 23 42 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/5 {
  --tw-gradient-from: rgb(15 23 42 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/50 {
  --tw-gradient-from: rgb(15 23 42 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/55 {
  --tw-gradient-from: rgb(15 23 42 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/60 {
  --tw-gradient-from: rgb(15 23 42 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/65 {
  --tw-gradient-from: rgb(15 23 42 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/70 {
  --tw-gradient-from: rgb(15 23 42 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/75 {
  --tw-gradient-from: rgb(15 23 42 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/80 {
  --tw-gradient-from: rgb(15 23 42 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/85 {
  --tw-gradient-from: rgb(15 23 42 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/90 {
  --tw-gradient-from: rgb(15 23 42 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-900\/95 {
  --tw-gradient-from: rgb(15 23 42 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950 {
  --tw-gradient-from: #020617 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/0 {
  --tw-gradient-from: rgb(2 6 23 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/10 {
  --tw-gradient-from: rgb(2 6 23 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/100 {
  --tw-gradient-from: rgb(2 6 23 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/15 {
  --tw-gradient-from: rgb(2 6 23 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/20 {
  --tw-gradient-from: rgb(2 6 23 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/25 {
  --tw-gradient-from: rgb(2 6 23 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/30 {
  --tw-gradient-from: rgb(2 6 23 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/35 {
  --tw-gradient-from: rgb(2 6 23 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/40 {
  --tw-gradient-from: rgb(2 6 23 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/45 {
  --tw-gradient-from: rgb(2 6 23 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/5 {
  --tw-gradient-from: rgb(2 6 23 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/50 {
  --tw-gradient-from: rgb(2 6 23 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/55 {
  --tw-gradient-from: rgb(2 6 23 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/60 {
  --tw-gradient-from: rgb(2 6 23 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/65 {
  --tw-gradient-from: rgb(2 6 23 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/70 {
  --tw-gradient-from: rgb(2 6 23 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/75 {
  --tw-gradient-from: rgb(2 6 23 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/80 {
  --tw-gradient-from: rgb(2 6 23 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/85 {
  --tw-gradient-from: rgb(2 6 23 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/90 {
  --tw-gradient-from: rgb(2 6 23 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-slate-950\/95 {
  --tw-gradient-from: rgb(2 6 23 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100 {
  --tw-gradient-from: #f5f5f4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/0 {
  --tw-gradient-from: rgb(245 245 244 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/10 {
  --tw-gradient-from: rgb(245 245 244 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/100 {
  --tw-gradient-from: rgb(245 245 244 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/15 {
  --tw-gradient-from: rgb(245 245 244 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/20 {
  --tw-gradient-from: rgb(245 245 244 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/25 {
  --tw-gradient-from: rgb(245 245 244 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/30 {
  --tw-gradient-from: rgb(245 245 244 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/35 {
  --tw-gradient-from: rgb(245 245 244 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/40 {
  --tw-gradient-from: rgb(245 245 244 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/45 {
  --tw-gradient-from: rgb(245 245 244 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/5 {
  --tw-gradient-from: rgb(245 245 244 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/50 {
  --tw-gradient-from: rgb(245 245 244 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/55 {
  --tw-gradient-from: rgb(245 245 244 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/60 {
  --tw-gradient-from: rgb(245 245 244 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/65 {
  --tw-gradient-from: rgb(245 245 244 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/70 {
  --tw-gradient-from: rgb(245 245 244 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/75 {
  --tw-gradient-from: rgb(245 245 244 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/80 {
  --tw-gradient-from: rgb(245 245 244 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/85 {
  --tw-gradient-from: rgb(245 245 244 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/90 {
  --tw-gradient-from: rgb(245 245 244 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-100\/95 {
  --tw-gradient-from: rgb(245 245 244 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200 {
  --tw-gradient-from: #e7e5e4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/0 {
  --tw-gradient-from: rgb(231 229 228 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/10 {
  --tw-gradient-from: rgb(231 229 228 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/100 {
  --tw-gradient-from: rgb(231 229 228 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/15 {
  --tw-gradient-from: rgb(231 229 228 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/20 {
  --tw-gradient-from: rgb(231 229 228 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/25 {
  --tw-gradient-from: rgb(231 229 228 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/30 {
  --tw-gradient-from: rgb(231 229 228 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/35 {
  --tw-gradient-from: rgb(231 229 228 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/40 {
  --tw-gradient-from: rgb(231 229 228 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/45 {
  --tw-gradient-from: rgb(231 229 228 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/5 {
  --tw-gradient-from: rgb(231 229 228 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/50 {
  --tw-gradient-from: rgb(231 229 228 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/55 {
  --tw-gradient-from: rgb(231 229 228 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/60 {
  --tw-gradient-from: rgb(231 229 228 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/65 {
  --tw-gradient-from: rgb(231 229 228 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/70 {
  --tw-gradient-from: rgb(231 229 228 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/75 {
  --tw-gradient-from: rgb(231 229 228 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/80 {
  --tw-gradient-from: rgb(231 229 228 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/85 {
  --tw-gradient-from: rgb(231 229 228 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/90 {
  --tw-gradient-from: rgb(231 229 228 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-200\/95 {
  --tw-gradient-from: rgb(231 229 228 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300 {
  --tw-gradient-from: #d6d3d1 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/0 {
  --tw-gradient-from: rgb(214 211 209 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/10 {
  --tw-gradient-from: rgb(214 211 209 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/100 {
  --tw-gradient-from: rgb(214 211 209 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/15 {
  --tw-gradient-from: rgb(214 211 209 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/20 {
  --tw-gradient-from: rgb(214 211 209 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/25 {
  --tw-gradient-from: rgb(214 211 209 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/30 {
  --tw-gradient-from: rgb(214 211 209 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/35 {
  --tw-gradient-from: rgb(214 211 209 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/40 {
  --tw-gradient-from: rgb(214 211 209 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/45 {
  --tw-gradient-from: rgb(214 211 209 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/5 {
  --tw-gradient-from: rgb(214 211 209 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/50 {
  --tw-gradient-from: rgb(214 211 209 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/55 {
  --tw-gradient-from: rgb(214 211 209 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/60 {
  --tw-gradient-from: rgb(214 211 209 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/65 {
  --tw-gradient-from: rgb(214 211 209 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/70 {
  --tw-gradient-from: rgb(214 211 209 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/75 {
  --tw-gradient-from: rgb(214 211 209 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/80 {
  --tw-gradient-from: rgb(214 211 209 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/85 {
  --tw-gradient-from: rgb(214 211 209 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/90 {
  --tw-gradient-from: rgb(214 211 209 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-300\/95 {
  --tw-gradient-from: rgb(214 211 209 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400 {
  --tw-gradient-from: #a8a29e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/0 {
  --tw-gradient-from: rgb(168 162 158 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/10 {
  --tw-gradient-from: rgb(168 162 158 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/100 {
  --tw-gradient-from: rgb(168 162 158 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/15 {
  --tw-gradient-from: rgb(168 162 158 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/20 {
  --tw-gradient-from: rgb(168 162 158 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/25 {
  --tw-gradient-from: rgb(168 162 158 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/30 {
  --tw-gradient-from: rgb(168 162 158 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/35 {
  --tw-gradient-from: rgb(168 162 158 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/40 {
  --tw-gradient-from: rgb(168 162 158 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/45 {
  --tw-gradient-from: rgb(168 162 158 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/5 {
  --tw-gradient-from: rgb(168 162 158 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/50 {
  --tw-gradient-from: rgb(168 162 158 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/55 {
  --tw-gradient-from: rgb(168 162 158 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/60 {
  --tw-gradient-from: rgb(168 162 158 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/65 {
  --tw-gradient-from: rgb(168 162 158 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/70 {
  --tw-gradient-from: rgb(168 162 158 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/75 {
  --tw-gradient-from: rgb(168 162 158 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/80 {
  --tw-gradient-from: rgb(168 162 158 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/85 {
  --tw-gradient-from: rgb(168 162 158 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/90 {
  --tw-gradient-from: rgb(168 162 158 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-400\/95 {
  --tw-gradient-from: rgb(168 162 158 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50 {
  --tw-gradient-from: #fafaf9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/0 {
  --tw-gradient-from: rgb(250 250 249 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/10 {
  --tw-gradient-from: rgb(250 250 249 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/100 {
  --tw-gradient-from: rgb(250 250 249 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/15 {
  --tw-gradient-from: rgb(250 250 249 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/20 {
  --tw-gradient-from: rgb(250 250 249 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/25 {
  --tw-gradient-from: rgb(250 250 249 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/30 {
  --tw-gradient-from: rgb(250 250 249 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/35 {
  --tw-gradient-from: rgb(250 250 249 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/40 {
  --tw-gradient-from: rgb(250 250 249 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/45 {
  --tw-gradient-from: rgb(250 250 249 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/5 {
  --tw-gradient-from: rgb(250 250 249 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/50 {
  --tw-gradient-from: rgb(250 250 249 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/55 {
  --tw-gradient-from: rgb(250 250 249 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/60 {
  --tw-gradient-from: rgb(250 250 249 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/65 {
  --tw-gradient-from: rgb(250 250 249 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/70 {
  --tw-gradient-from: rgb(250 250 249 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/75 {
  --tw-gradient-from: rgb(250 250 249 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/80 {
  --tw-gradient-from: rgb(250 250 249 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/85 {
  --tw-gradient-from: rgb(250 250 249 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/90 {
  --tw-gradient-from: rgb(250 250 249 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-50\/95 {
  --tw-gradient-from: rgb(250 250 249 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500 {
  --tw-gradient-from: #78716c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/0 {
  --tw-gradient-from: rgb(120 113 108 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/10 {
  --tw-gradient-from: rgb(120 113 108 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/100 {
  --tw-gradient-from: rgb(120 113 108 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/15 {
  --tw-gradient-from: rgb(120 113 108 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/20 {
  --tw-gradient-from: rgb(120 113 108 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/25 {
  --tw-gradient-from: rgb(120 113 108 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/30 {
  --tw-gradient-from: rgb(120 113 108 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/35 {
  --tw-gradient-from: rgb(120 113 108 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/40 {
  --tw-gradient-from: rgb(120 113 108 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/45 {
  --tw-gradient-from: rgb(120 113 108 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/5 {
  --tw-gradient-from: rgb(120 113 108 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/50 {
  --tw-gradient-from: rgb(120 113 108 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/55 {
  --tw-gradient-from: rgb(120 113 108 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/60 {
  --tw-gradient-from: rgb(120 113 108 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/65 {
  --tw-gradient-from: rgb(120 113 108 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/70 {
  --tw-gradient-from: rgb(120 113 108 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/75 {
  --tw-gradient-from: rgb(120 113 108 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/80 {
  --tw-gradient-from: rgb(120 113 108 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/85 {
  --tw-gradient-from: rgb(120 113 108 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/90 {
  --tw-gradient-from: rgb(120 113 108 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-500\/95 {
  --tw-gradient-from: rgb(120 113 108 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600 {
  --tw-gradient-from: #57534e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/0 {
  --tw-gradient-from: rgb(87 83 78 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/10 {
  --tw-gradient-from: rgb(87 83 78 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/100 {
  --tw-gradient-from: rgb(87 83 78 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/15 {
  --tw-gradient-from: rgb(87 83 78 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/20 {
  --tw-gradient-from: rgb(87 83 78 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/25 {
  --tw-gradient-from: rgb(87 83 78 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/30 {
  --tw-gradient-from: rgb(87 83 78 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/35 {
  --tw-gradient-from: rgb(87 83 78 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/40 {
  --tw-gradient-from: rgb(87 83 78 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/45 {
  --tw-gradient-from: rgb(87 83 78 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/5 {
  --tw-gradient-from: rgb(87 83 78 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/50 {
  --tw-gradient-from: rgb(87 83 78 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/55 {
  --tw-gradient-from: rgb(87 83 78 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/60 {
  --tw-gradient-from: rgb(87 83 78 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/65 {
  --tw-gradient-from: rgb(87 83 78 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/70 {
  --tw-gradient-from: rgb(87 83 78 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/75 {
  --tw-gradient-from: rgb(87 83 78 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/80 {
  --tw-gradient-from: rgb(87 83 78 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/85 {
  --tw-gradient-from: rgb(87 83 78 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/90 {
  --tw-gradient-from: rgb(87 83 78 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-600\/95 {
  --tw-gradient-from: rgb(87 83 78 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700 {
  --tw-gradient-from: #44403c var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/0 {
  --tw-gradient-from: rgb(68 64 60 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/10 {
  --tw-gradient-from: rgb(68 64 60 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/100 {
  --tw-gradient-from: rgb(68 64 60 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/15 {
  --tw-gradient-from: rgb(68 64 60 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/20 {
  --tw-gradient-from: rgb(68 64 60 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/25 {
  --tw-gradient-from: rgb(68 64 60 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/30 {
  --tw-gradient-from: rgb(68 64 60 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/35 {
  --tw-gradient-from: rgb(68 64 60 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/40 {
  --tw-gradient-from: rgb(68 64 60 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/45 {
  --tw-gradient-from: rgb(68 64 60 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/5 {
  --tw-gradient-from: rgb(68 64 60 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/50 {
  --tw-gradient-from: rgb(68 64 60 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/55 {
  --tw-gradient-from: rgb(68 64 60 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/60 {
  --tw-gradient-from: rgb(68 64 60 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/65 {
  --tw-gradient-from: rgb(68 64 60 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/70 {
  --tw-gradient-from: rgb(68 64 60 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/75 {
  --tw-gradient-from: rgb(68 64 60 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/80 {
  --tw-gradient-from: rgb(68 64 60 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/85 {
  --tw-gradient-from: rgb(68 64 60 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/90 {
  --tw-gradient-from: rgb(68 64 60 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-700\/95 {
  --tw-gradient-from: rgb(68 64 60 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800 {
  --tw-gradient-from: #292524 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/0 {
  --tw-gradient-from: rgb(41 37 36 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/10 {
  --tw-gradient-from: rgb(41 37 36 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/100 {
  --tw-gradient-from: rgb(41 37 36 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/15 {
  --tw-gradient-from: rgb(41 37 36 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/20 {
  --tw-gradient-from: rgb(41 37 36 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/25 {
  --tw-gradient-from: rgb(41 37 36 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/30 {
  --tw-gradient-from: rgb(41 37 36 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/35 {
  --tw-gradient-from: rgb(41 37 36 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/40 {
  --tw-gradient-from: rgb(41 37 36 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/45 {
  --tw-gradient-from: rgb(41 37 36 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/5 {
  --tw-gradient-from: rgb(41 37 36 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/50 {
  --tw-gradient-from: rgb(41 37 36 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/55 {
  --tw-gradient-from: rgb(41 37 36 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/60 {
  --tw-gradient-from: rgb(41 37 36 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/65 {
  --tw-gradient-from: rgb(41 37 36 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/70 {
  --tw-gradient-from: rgb(41 37 36 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/75 {
  --tw-gradient-from: rgb(41 37 36 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/80 {
  --tw-gradient-from: rgb(41 37 36 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/85 {
  --tw-gradient-from: rgb(41 37 36 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/90 {
  --tw-gradient-from: rgb(41 37 36 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-800\/95 {
  --tw-gradient-from: rgb(41 37 36 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900 {
  --tw-gradient-from: #1c1917 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/0 {
  --tw-gradient-from: rgb(28 25 23 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/10 {
  --tw-gradient-from: rgb(28 25 23 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/100 {
  --tw-gradient-from: rgb(28 25 23 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/15 {
  --tw-gradient-from: rgb(28 25 23 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/20 {
  --tw-gradient-from: rgb(28 25 23 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/25 {
  --tw-gradient-from: rgb(28 25 23 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/30 {
  --tw-gradient-from: rgb(28 25 23 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/35 {
  --tw-gradient-from: rgb(28 25 23 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/40 {
  --tw-gradient-from: rgb(28 25 23 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/45 {
  --tw-gradient-from: rgb(28 25 23 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/5 {
  --tw-gradient-from: rgb(28 25 23 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/50 {
  --tw-gradient-from: rgb(28 25 23 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/55 {
  --tw-gradient-from: rgb(28 25 23 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/60 {
  --tw-gradient-from: rgb(28 25 23 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/65 {
  --tw-gradient-from: rgb(28 25 23 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/70 {
  --tw-gradient-from: rgb(28 25 23 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/75 {
  --tw-gradient-from: rgb(28 25 23 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/80 {
  --tw-gradient-from: rgb(28 25 23 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/85 {
  --tw-gradient-from: rgb(28 25 23 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/90 {
  --tw-gradient-from: rgb(28 25 23 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-900\/95 {
  --tw-gradient-from: rgb(28 25 23 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950 {
  --tw-gradient-from: #0c0a09 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/0 {
  --tw-gradient-from: rgb(12 10 9 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/10 {
  --tw-gradient-from: rgb(12 10 9 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/100 {
  --tw-gradient-from: rgb(12 10 9 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/15 {
  --tw-gradient-from: rgb(12 10 9 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/20 {
  --tw-gradient-from: rgb(12 10 9 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/25 {
  --tw-gradient-from: rgb(12 10 9 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/30 {
  --tw-gradient-from: rgb(12 10 9 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/35 {
  --tw-gradient-from: rgb(12 10 9 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/40 {
  --tw-gradient-from: rgb(12 10 9 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/45 {
  --tw-gradient-from: rgb(12 10 9 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/5 {
  --tw-gradient-from: rgb(12 10 9 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/50 {
  --tw-gradient-from: rgb(12 10 9 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/55 {
  --tw-gradient-from: rgb(12 10 9 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/60 {
  --tw-gradient-from: rgb(12 10 9 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/65 {
  --tw-gradient-from: rgb(12 10 9 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/70 {
  --tw-gradient-from: rgb(12 10 9 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/75 {
  --tw-gradient-from: rgb(12 10 9 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/80 {
  --tw-gradient-from: rgb(12 10 9 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/85 {
  --tw-gradient-from: rgb(12 10 9 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/90 {
  --tw-gradient-from: rgb(12 10 9 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-stone-950\/95 {
  --tw-gradient-from: rgb(12 10 9 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100 {
  --tw-gradient-from: #ccfbf1 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/0 {
  --tw-gradient-from: rgb(204 251 241 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/10 {
  --tw-gradient-from: rgb(204 251 241 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/100 {
  --tw-gradient-from: rgb(204 251 241 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/15 {
  --tw-gradient-from: rgb(204 251 241 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/20 {
  --tw-gradient-from: rgb(204 251 241 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/25 {
  --tw-gradient-from: rgb(204 251 241 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/30 {
  --tw-gradient-from: rgb(204 251 241 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/35 {
  --tw-gradient-from: rgb(204 251 241 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/40 {
  --tw-gradient-from: rgb(204 251 241 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/45 {
  --tw-gradient-from: rgb(204 251 241 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/5 {
  --tw-gradient-from: rgb(204 251 241 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/50 {
  --tw-gradient-from: rgb(204 251 241 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/55 {
  --tw-gradient-from: rgb(204 251 241 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/60 {
  --tw-gradient-from: rgb(204 251 241 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/65 {
  --tw-gradient-from: rgb(204 251 241 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/70 {
  --tw-gradient-from: rgb(204 251 241 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/75 {
  --tw-gradient-from: rgb(204 251 241 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/80 {
  --tw-gradient-from: rgb(204 251 241 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/85 {
  --tw-gradient-from: rgb(204 251 241 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/90 {
  --tw-gradient-from: rgb(204 251 241 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-100\/95 {
  --tw-gradient-from: rgb(204 251 241 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200 {
  --tw-gradient-from: #99f6e4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/0 {
  --tw-gradient-from: rgb(153 246 228 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/10 {
  --tw-gradient-from: rgb(153 246 228 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/100 {
  --tw-gradient-from: rgb(153 246 228 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/15 {
  --tw-gradient-from: rgb(153 246 228 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/20 {
  --tw-gradient-from: rgb(153 246 228 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/25 {
  --tw-gradient-from: rgb(153 246 228 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/30 {
  --tw-gradient-from: rgb(153 246 228 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/35 {
  --tw-gradient-from: rgb(153 246 228 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/40 {
  --tw-gradient-from: rgb(153 246 228 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/45 {
  --tw-gradient-from: rgb(153 246 228 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/5 {
  --tw-gradient-from: rgb(153 246 228 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/50 {
  --tw-gradient-from: rgb(153 246 228 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/55 {
  --tw-gradient-from: rgb(153 246 228 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/60 {
  --tw-gradient-from: rgb(153 246 228 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/65 {
  --tw-gradient-from: rgb(153 246 228 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/70 {
  --tw-gradient-from: rgb(153 246 228 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/75 {
  --tw-gradient-from: rgb(153 246 228 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/80 {
  --tw-gradient-from: rgb(153 246 228 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/85 {
  --tw-gradient-from: rgb(153 246 228 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/90 {
  --tw-gradient-from: rgb(153 246 228 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-200\/95 {
  --tw-gradient-from: rgb(153 246 228 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300 {
  --tw-gradient-from: #5eead4 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/0 {
  --tw-gradient-from: rgb(94 234 212 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/10 {
  --tw-gradient-from: rgb(94 234 212 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/100 {
  --tw-gradient-from: rgb(94 234 212 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/15 {
  --tw-gradient-from: rgb(94 234 212 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/20 {
  --tw-gradient-from: rgb(94 234 212 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/25 {
  --tw-gradient-from: rgb(94 234 212 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/30 {
  --tw-gradient-from: rgb(94 234 212 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/35 {
  --tw-gradient-from: rgb(94 234 212 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/40 {
  --tw-gradient-from: rgb(94 234 212 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/45 {
  --tw-gradient-from: rgb(94 234 212 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/5 {
  --tw-gradient-from: rgb(94 234 212 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/50 {
  --tw-gradient-from: rgb(94 234 212 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/55 {
  --tw-gradient-from: rgb(94 234 212 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/60 {
  --tw-gradient-from: rgb(94 234 212 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/65 {
  --tw-gradient-from: rgb(94 234 212 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/70 {
  --tw-gradient-from: rgb(94 234 212 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/75 {
  --tw-gradient-from: rgb(94 234 212 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/80 {
  --tw-gradient-from: rgb(94 234 212 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/85 {
  --tw-gradient-from: rgb(94 234 212 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/90 {
  --tw-gradient-from: rgb(94 234 212 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-300\/95 {
  --tw-gradient-from: rgb(94 234 212 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400 {
  --tw-gradient-from: #2dd4bf var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/0 {
  --tw-gradient-from: rgb(45 212 191 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/10 {
  --tw-gradient-from: rgb(45 212 191 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/100 {
  --tw-gradient-from: rgb(45 212 191 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/15 {
  --tw-gradient-from: rgb(45 212 191 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/20 {
  --tw-gradient-from: rgb(45 212 191 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/25 {
  --tw-gradient-from: rgb(45 212 191 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/30 {
  --tw-gradient-from: rgb(45 212 191 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/35 {
  --tw-gradient-from: rgb(45 212 191 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/40 {
  --tw-gradient-from: rgb(45 212 191 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/45 {
  --tw-gradient-from: rgb(45 212 191 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/5 {
  --tw-gradient-from: rgb(45 212 191 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/50 {
  --tw-gradient-from: rgb(45 212 191 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/55 {
  --tw-gradient-from: rgb(45 212 191 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/60 {
  --tw-gradient-from: rgb(45 212 191 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/65 {
  --tw-gradient-from: rgb(45 212 191 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/70 {
  --tw-gradient-from: rgb(45 212 191 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/75 {
  --tw-gradient-from: rgb(45 212 191 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/80 {
  --tw-gradient-from: rgb(45 212 191 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/85 {
  --tw-gradient-from: rgb(45 212 191 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/90 {
  --tw-gradient-from: rgb(45 212 191 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-400\/95 {
  --tw-gradient-from: rgb(45 212 191 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50 {
  --tw-gradient-from: #f0fdfa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/0 {
  --tw-gradient-from: rgb(240 253 250 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/10 {
  --tw-gradient-from: rgb(240 253 250 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/100 {
  --tw-gradient-from: rgb(240 253 250 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/15 {
  --tw-gradient-from: rgb(240 253 250 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/20 {
  --tw-gradient-from: rgb(240 253 250 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/25 {
  --tw-gradient-from: rgb(240 253 250 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/30 {
  --tw-gradient-from: rgb(240 253 250 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/35 {
  --tw-gradient-from: rgb(240 253 250 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/40 {
  --tw-gradient-from: rgb(240 253 250 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/45 {
  --tw-gradient-from: rgb(240 253 250 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/5 {
  --tw-gradient-from: rgb(240 253 250 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/50 {
  --tw-gradient-from: rgb(240 253 250 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/55 {
  --tw-gradient-from: rgb(240 253 250 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/60 {
  --tw-gradient-from: rgb(240 253 250 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/65 {
  --tw-gradient-from: rgb(240 253 250 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/70 {
  --tw-gradient-from: rgb(240 253 250 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/75 {
  --tw-gradient-from: rgb(240 253 250 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/80 {
  --tw-gradient-from: rgb(240 253 250 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/85 {
  --tw-gradient-from: rgb(240 253 250 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/90 {
  --tw-gradient-from: rgb(240 253 250 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-50\/95 {
  --tw-gradient-from: rgb(240 253 250 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500 {
  --tw-gradient-from: #14b8a6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/0 {
  --tw-gradient-from: rgb(20 184 166 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/10 {
  --tw-gradient-from: rgb(20 184 166 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/100 {
  --tw-gradient-from: rgb(20 184 166 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/15 {
  --tw-gradient-from: rgb(20 184 166 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/20 {
  --tw-gradient-from: rgb(20 184 166 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/25 {
  --tw-gradient-from: rgb(20 184 166 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/30 {
  --tw-gradient-from: rgb(20 184 166 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/35 {
  --tw-gradient-from: rgb(20 184 166 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/40 {
  --tw-gradient-from: rgb(20 184 166 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/45 {
  --tw-gradient-from: rgb(20 184 166 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/5 {
  --tw-gradient-from: rgb(20 184 166 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/50 {
  --tw-gradient-from: rgb(20 184 166 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/55 {
  --tw-gradient-from: rgb(20 184 166 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/60 {
  --tw-gradient-from: rgb(20 184 166 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/65 {
  --tw-gradient-from: rgb(20 184 166 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/70 {
  --tw-gradient-from: rgb(20 184 166 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/75 {
  --tw-gradient-from: rgb(20 184 166 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/80 {
  --tw-gradient-from: rgb(20 184 166 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/85 {
  --tw-gradient-from: rgb(20 184 166 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/90 {
  --tw-gradient-from: rgb(20 184 166 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-500\/95 {
  --tw-gradient-from: rgb(20 184 166 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600 {
  --tw-gradient-from: #0d9488 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/0 {
  --tw-gradient-from: rgb(13 148 136 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/10 {
  --tw-gradient-from: rgb(13 148 136 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/100 {
  --tw-gradient-from: rgb(13 148 136 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/15 {
  --tw-gradient-from: rgb(13 148 136 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/20 {
  --tw-gradient-from: rgb(13 148 136 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/25 {
  --tw-gradient-from: rgb(13 148 136 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/30 {
  --tw-gradient-from: rgb(13 148 136 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/35 {
  --tw-gradient-from: rgb(13 148 136 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/40 {
  --tw-gradient-from: rgb(13 148 136 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/45 {
  --tw-gradient-from: rgb(13 148 136 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/5 {
  --tw-gradient-from: rgb(13 148 136 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/50 {
  --tw-gradient-from: rgb(13 148 136 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/55 {
  --tw-gradient-from: rgb(13 148 136 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/60 {
  --tw-gradient-from: rgb(13 148 136 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/65 {
  --tw-gradient-from: rgb(13 148 136 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/70 {
  --tw-gradient-from: rgb(13 148 136 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/75 {
  --tw-gradient-from: rgb(13 148 136 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/80 {
  --tw-gradient-from: rgb(13 148 136 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/85 {
  --tw-gradient-from: rgb(13 148 136 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/90 {
  --tw-gradient-from: rgb(13 148 136 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-600\/95 {
  --tw-gradient-from: rgb(13 148 136 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700 {
  --tw-gradient-from: #0f766e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/0 {
  --tw-gradient-from: rgb(15 118 110 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/10 {
  --tw-gradient-from: rgb(15 118 110 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/100 {
  --tw-gradient-from: rgb(15 118 110 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/15 {
  --tw-gradient-from: rgb(15 118 110 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/20 {
  --tw-gradient-from: rgb(15 118 110 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/25 {
  --tw-gradient-from: rgb(15 118 110 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/30 {
  --tw-gradient-from: rgb(15 118 110 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/35 {
  --tw-gradient-from: rgb(15 118 110 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/40 {
  --tw-gradient-from: rgb(15 118 110 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/45 {
  --tw-gradient-from: rgb(15 118 110 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/5 {
  --tw-gradient-from: rgb(15 118 110 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/50 {
  --tw-gradient-from: rgb(15 118 110 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/55 {
  --tw-gradient-from: rgb(15 118 110 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/60 {
  --tw-gradient-from: rgb(15 118 110 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/65 {
  --tw-gradient-from: rgb(15 118 110 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/70 {
  --tw-gradient-from: rgb(15 118 110 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/75 {
  --tw-gradient-from: rgb(15 118 110 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/80 {
  --tw-gradient-from: rgb(15 118 110 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/85 {
  --tw-gradient-from: rgb(15 118 110 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/90 {
  --tw-gradient-from: rgb(15 118 110 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-700\/95 {
  --tw-gradient-from: rgb(15 118 110 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800 {
  --tw-gradient-from: #115e59 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/0 {
  --tw-gradient-from: rgb(17 94 89 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/10 {
  --tw-gradient-from: rgb(17 94 89 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/100 {
  --tw-gradient-from: rgb(17 94 89 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/15 {
  --tw-gradient-from: rgb(17 94 89 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/20 {
  --tw-gradient-from: rgb(17 94 89 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/25 {
  --tw-gradient-from: rgb(17 94 89 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/30 {
  --tw-gradient-from: rgb(17 94 89 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/35 {
  --tw-gradient-from: rgb(17 94 89 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/40 {
  --tw-gradient-from: rgb(17 94 89 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/45 {
  --tw-gradient-from: rgb(17 94 89 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/5 {
  --tw-gradient-from: rgb(17 94 89 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/50 {
  --tw-gradient-from: rgb(17 94 89 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/55 {
  --tw-gradient-from: rgb(17 94 89 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/60 {
  --tw-gradient-from: rgb(17 94 89 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/65 {
  --tw-gradient-from: rgb(17 94 89 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/70 {
  --tw-gradient-from: rgb(17 94 89 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/75 {
  --tw-gradient-from: rgb(17 94 89 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/80 {
  --tw-gradient-from: rgb(17 94 89 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/85 {
  --tw-gradient-from: rgb(17 94 89 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/90 {
  --tw-gradient-from: rgb(17 94 89 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-800\/95 {
  --tw-gradient-from: rgb(17 94 89 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900 {
  --tw-gradient-from: #134e4a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/0 {
  --tw-gradient-from: rgb(19 78 74 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/10 {
  --tw-gradient-from: rgb(19 78 74 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/100 {
  --tw-gradient-from: rgb(19 78 74 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/15 {
  --tw-gradient-from: rgb(19 78 74 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/20 {
  --tw-gradient-from: rgb(19 78 74 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/25 {
  --tw-gradient-from: rgb(19 78 74 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/30 {
  --tw-gradient-from: rgb(19 78 74 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/35 {
  --tw-gradient-from: rgb(19 78 74 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/40 {
  --tw-gradient-from: rgb(19 78 74 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/45 {
  --tw-gradient-from: rgb(19 78 74 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/5 {
  --tw-gradient-from: rgb(19 78 74 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/50 {
  --tw-gradient-from: rgb(19 78 74 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/55 {
  --tw-gradient-from: rgb(19 78 74 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/60 {
  --tw-gradient-from: rgb(19 78 74 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/65 {
  --tw-gradient-from: rgb(19 78 74 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/70 {
  --tw-gradient-from: rgb(19 78 74 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/75 {
  --tw-gradient-from: rgb(19 78 74 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/80 {
  --tw-gradient-from: rgb(19 78 74 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/85 {
  --tw-gradient-from: rgb(19 78 74 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/90 {
  --tw-gradient-from: rgb(19 78 74 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-900\/95 {
  --tw-gradient-from: rgb(19 78 74 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950 {
  --tw-gradient-from: #042f2e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/0 {
  --tw-gradient-from: rgb(4 47 46 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/10 {
  --tw-gradient-from: rgb(4 47 46 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/100 {
  --tw-gradient-from: rgb(4 47 46 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/15 {
  --tw-gradient-from: rgb(4 47 46 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/20 {
  --tw-gradient-from: rgb(4 47 46 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/25 {
  --tw-gradient-from: rgb(4 47 46 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/30 {
  --tw-gradient-from: rgb(4 47 46 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/35 {
  --tw-gradient-from: rgb(4 47 46 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/40 {
  --tw-gradient-from: rgb(4 47 46 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/45 {
  --tw-gradient-from: rgb(4 47 46 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/5 {
  --tw-gradient-from: rgb(4 47 46 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/50 {
  --tw-gradient-from: rgb(4 47 46 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/55 {
  --tw-gradient-from: rgb(4 47 46 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/60 {
  --tw-gradient-from: rgb(4 47 46 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/65 {
  --tw-gradient-from: rgb(4 47 46 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/70 {
  --tw-gradient-from: rgb(4 47 46 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/75 {
  --tw-gradient-from: rgb(4 47 46 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/80 {
  --tw-gradient-from: rgb(4 47 46 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/85 {
  --tw-gradient-from: rgb(4 47 46 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/90 {
  --tw-gradient-from: rgb(4 47 46 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-teal-950\/95 {
  --tw-gradient-from: rgb(4 47 46 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent {
  --tw-gradient-from: transparent var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/0 {
  --tw-gradient-from: rgb(0 0 0 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/10 {
  --tw-gradient-from: rgb(0 0 0 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/100 {
  --tw-gradient-from: rgb(0 0 0 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/15 {
  --tw-gradient-from: rgb(0 0 0 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/20 {
  --tw-gradient-from: rgb(0 0 0 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/25 {
  --tw-gradient-from: rgb(0 0 0 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/30 {
  --tw-gradient-from: rgb(0 0 0 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/35 {
  --tw-gradient-from: rgb(0 0 0 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/40 {
  --tw-gradient-from: rgb(0 0 0 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/45 {
  --tw-gradient-from: rgb(0 0 0 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/5 {
  --tw-gradient-from: rgb(0 0 0 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/50 {
  --tw-gradient-from: rgb(0 0 0 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/55 {
  --tw-gradient-from: rgb(0 0 0 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/60 {
  --tw-gradient-from: rgb(0 0 0 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/65 {
  --tw-gradient-from: rgb(0 0 0 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/70 {
  --tw-gradient-from: rgb(0 0 0 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/75 {
  --tw-gradient-from: rgb(0 0 0 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/80 {
  --tw-gradient-from: rgb(0 0 0 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/85 {
  --tw-gradient-from: rgb(0 0 0 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/90 {
  --tw-gradient-from: rgb(0 0 0 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-transparent\/95 {
  --tw-gradient-from: rgb(0 0 0 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100 {
  --tw-gradient-from: #ede9fe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/0 {
  --tw-gradient-from: rgb(237 233 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/10 {
  --tw-gradient-from: rgb(237 233 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/100 {
  --tw-gradient-from: rgb(237 233 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/15 {
  --tw-gradient-from: rgb(237 233 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/20 {
  --tw-gradient-from: rgb(237 233 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/25 {
  --tw-gradient-from: rgb(237 233 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/30 {
  --tw-gradient-from: rgb(237 233 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/35 {
  --tw-gradient-from: rgb(237 233 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/40 {
  --tw-gradient-from: rgb(237 233 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/45 {
  --tw-gradient-from: rgb(237 233 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/5 {
  --tw-gradient-from: rgb(237 233 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/50 {
  --tw-gradient-from: rgb(237 233 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/55 {
  --tw-gradient-from: rgb(237 233 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/60 {
  --tw-gradient-from: rgb(237 233 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/65 {
  --tw-gradient-from: rgb(237 233 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/70 {
  --tw-gradient-from: rgb(237 233 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/75 {
  --tw-gradient-from: rgb(237 233 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/80 {
  --tw-gradient-from: rgb(237 233 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/85 {
  --tw-gradient-from: rgb(237 233 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/90 {
  --tw-gradient-from: rgb(237 233 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-100\/95 {
  --tw-gradient-from: rgb(237 233 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200 {
  --tw-gradient-from: #ddd6fe var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/0 {
  --tw-gradient-from: rgb(221 214 254 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/10 {
  --tw-gradient-from: rgb(221 214 254 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/100 {
  --tw-gradient-from: rgb(221 214 254 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/15 {
  --tw-gradient-from: rgb(221 214 254 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/20 {
  --tw-gradient-from: rgb(221 214 254 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/25 {
  --tw-gradient-from: rgb(221 214 254 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/30 {
  --tw-gradient-from: rgb(221 214 254 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/35 {
  --tw-gradient-from: rgb(221 214 254 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/40 {
  --tw-gradient-from: rgb(221 214 254 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/45 {
  --tw-gradient-from: rgb(221 214 254 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/5 {
  --tw-gradient-from: rgb(221 214 254 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/50 {
  --tw-gradient-from: rgb(221 214 254 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/55 {
  --tw-gradient-from: rgb(221 214 254 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/60 {
  --tw-gradient-from: rgb(221 214 254 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/65 {
  --tw-gradient-from: rgb(221 214 254 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/70 {
  --tw-gradient-from: rgb(221 214 254 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/75 {
  --tw-gradient-from: rgb(221 214 254 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/80 {
  --tw-gradient-from: rgb(221 214 254 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/85 {
  --tw-gradient-from: rgb(221 214 254 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/90 {
  --tw-gradient-from: rgb(221 214 254 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-200\/95 {
  --tw-gradient-from: rgb(221 214 254 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300 {
  --tw-gradient-from: #c4b5fd var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/0 {
  --tw-gradient-from: rgb(196 181 253 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/10 {
  --tw-gradient-from: rgb(196 181 253 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/100 {
  --tw-gradient-from: rgb(196 181 253 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/15 {
  --tw-gradient-from: rgb(196 181 253 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/20 {
  --tw-gradient-from: rgb(196 181 253 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/25 {
  --tw-gradient-from: rgb(196 181 253 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/30 {
  --tw-gradient-from: rgb(196 181 253 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/35 {
  --tw-gradient-from: rgb(196 181 253 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/40 {
  --tw-gradient-from: rgb(196 181 253 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/45 {
  --tw-gradient-from: rgb(196 181 253 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/5 {
  --tw-gradient-from: rgb(196 181 253 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/50 {
  --tw-gradient-from: rgb(196 181 253 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/55 {
  --tw-gradient-from: rgb(196 181 253 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/60 {
  --tw-gradient-from: rgb(196 181 253 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/65 {
  --tw-gradient-from: rgb(196 181 253 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/70 {
  --tw-gradient-from: rgb(196 181 253 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/75 {
  --tw-gradient-from: rgb(196 181 253 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/80 {
  --tw-gradient-from: rgb(196 181 253 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/85 {
  --tw-gradient-from: rgb(196 181 253 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/90 {
  --tw-gradient-from: rgb(196 181 253 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-300\/95 {
  --tw-gradient-from: rgb(196 181 253 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400 {
  --tw-gradient-from: #a78bfa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/0 {
  --tw-gradient-from: rgb(167 139 250 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/10 {
  --tw-gradient-from: rgb(167 139 250 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/100 {
  --tw-gradient-from: rgb(167 139 250 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/15 {
  --tw-gradient-from: rgb(167 139 250 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/20 {
  --tw-gradient-from: rgb(167 139 250 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/25 {
  --tw-gradient-from: rgb(167 139 250 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/30 {
  --tw-gradient-from: rgb(167 139 250 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/35 {
  --tw-gradient-from: rgb(167 139 250 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/40 {
  --tw-gradient-from: rgb(167 139 250 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/45 {
  --tw-gradient-from: rgb(167 139 250 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/5 {
  --tw-gradient-from: rgb(167 139 250 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/50 {
  --tw-gradient-from: rgb(167 139 250 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/55 {
  --tw-gradient-from: rgb(167 139 250 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/60 {
  --tw-gradient-from: rgb(167 139 250 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/65 {
  --tw-gradient-from: rgb(167 139 250 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/70 {
  --tw-gradient-from: rgb(167 139 250 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/75 {
  --tw-gradient-from: rgb(167 139 250 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/80 {
  --tw-gradient-from: rgb(167 139 250 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/85 {
  --tw-gradient-from: rgb(167 139 250 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/90 {
  --tw-gradient-from: rgb(167 139 250 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-400\/95 {
  --tw-gradient-from: rgb(167 139 250 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50 {
  --tw-gradient-from: #f5f3ff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/0 {
  --tw-gradient-from: rgb(245 243 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/10 {
  --tw-gradient-from: rgb(245 243 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/100 {
  --tw-gradient-from: rgb(245 243 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/15 {
  --tw-gradient-from: rgb(245 243 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/20 {
  --tw-gradient-from: rgb(245 243 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/25 {
  --tw-gradient-from: rgb(245 243 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/30 {
  --tw-gradient-from: rgb(245 243 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/35 {
  --tw-gradient-from: rgb(245 243 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/40 {
  --tw-gradient-from: rgb(245 243 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/45 {
  --tw-gradient-from: rgb(245 243 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/5 {
  --tw-gradient-from: rgb(245 243 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/50 {
  --tw-gradient-from: rgb(245 243 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/55 {
  --tw-gradient-from: rgb(245 243 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/60 {
  --tw-gradient-from: rgb(245 243 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/65 {
  --tw-gradient-from: rgb(245 243 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/70 {
  --tw-gradient-from: rgb(245 243 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/75 {
  --tw-gradient-from: rgb(245 243 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/80 {
  --tw-gradient-from: rgb(245 243 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/85 {
  --tw-gradient-from: rgb(245 243 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/90 {
  --tw-gradient-from: rgb(245 243 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-50\/95 {
  --tw-gradient-from: rgb(245 243 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500 {
  --tw-gradient-from: #8b5cf6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/0 {
  --tw-gradient-from: rgb(139 92 246 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/10 {
  --tw-gradient-from: rgb(139 92 246 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/100 {
  --tw-gradient-from: rgb(139 92 246 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/15 {
  --tw-gradient-from: rgb(139 92 246 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/20 {
  --tw-gradient-from: rgb(139 92 246 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/25 {
  --tw-gradient-from: rgb(139 92 246 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/30 {
  --tw-gradient-from: rgb(139 92 246 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/35 {
  --tw-gradient-from: rgb(139 92 246 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/40 {
  --tw-gradient-from: rgb(139 92 246 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/45 {
  --tw-gradient-from: rgb(139 92 246 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/5 {
  --tw-gradient-from: rgb(139 92 246 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/50 {
  --tw-gradient-from: rgb(139 92 246 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/55 {
  --tw-gradient-from: rgb(139 92 246 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/60 {
  --tw-gradient-from: rgb(139 92 246 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/65 {
  --tw-gradient-from: rgb(139 92 246 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/70 {
  --tw-gradient-from: rgb(139 92 246 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/75 {
  --tw-gradient-from: rgb(139 92 246 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/80 {
  --tw-gradient-from: rgb(139 92 246 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/85 {
  --tw-gradient-from: rgb(139 92 246 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/90 {
  --tw-gradient-from: rgb(139 92 246 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-500\/95 {
  --tw-gradient-from: rgb(139 92 246 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600 {
  --tw-gradient-from: #7c3aed var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/0 {
  --tw-gradient-from: rgb(124 58 237 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/10 {
  --tw-gradient-from: rgb(124 58 237 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/100 {
  --tw-gradient-from: rgb(124 58 237 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/15 {
  --tw-gradient-from: rgb(124 58 237 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/20 {
  --tw-gradient-from: rgb(124 58 237 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/25 {
  --tw-gradient-from: rgb(124 58 237 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/30 {
  --tw-gradient-from: rgb(124 58 237 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/35 {
  --tw-gradient-from: rgb(124 58 237 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/40 {
  --tw-gradient-from: rgb(124 58 237 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/45 {
  --tw-gradient-from: rgb(124 58 237 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/5 {
  --tw-gradient-from: rgb(124 58 237 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/50 {
  --tw-gradient-from: rgb(124 58 237 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/55 {
  --tw-gradient-from: rgb(124 58 237 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/60 {
  --tw-gradient-from: rgb(124 58 237 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/65 {
  --tw-gradient-from: rgb(124 58 237 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/70 {
  --tw-gradient-from: rgb(124 58 237 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/75 {
  --tw-gradient-from: rgb(124 58 237 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/80 {
  --tw-gradient-from: rgb(124 58 237 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/85 {
  --tw-gradient-from: rgb(124 58 237 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/90 {
  --tw-gradient-from: rgb(124 58 237 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-600\/95 {
  --tw-gradient-from: rgb(124 58 237 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700 {
  --tw-gradient-from: #6d28d9 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/0 {
  --tw-gradient-from: rgb(109 40 217 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/10 {
  --tw-gradient-from: rgb(109 40 217 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/100 {
  --tw-gradient-from: rgb(109 40 217 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/15 {
  --tw-gradient-from: rgb(109 40 217 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/20 {
  --tw-gradient-from: rgb(109 40 217 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/25 {
  --tw-gradient-from: rgb(109 40 217 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/30 {
  --tw-gradient-from: rgb(109 40 217 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/35 {
  --tw-gradient-from: rgb(109 40 217 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/40 {
  --tw-gradient-from: rgb(109 40 217 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/45 {
  --tw-gradient-from: rgb(109 40 217 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/5 {
  --tw-gradient-from: rgb(109 40 217 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/50 {
  --tw-gradient-from: rgb(109 40 217 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/55 {
  --tw-gradient-from: rgb(109 40 217 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/60 {
  --tw-gradient-from: rgb(109 40 217 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/65 {
  --tw-gradient-from: rgb(109 40 217 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/70 {
  --tw-gradient-from: rgb(109 40 217 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/75 {
  --tw-gradient-from: rgb(109 40 217 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/80 {
  --tw-gradient-from: rgb(109 40 217 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/85 {
  --tw-gradient-from: rgb(109 40 217 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/90 {
  --tw-gradient-from: rgb(109 40 217 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-700\/95 {
  --tw-gradient-from: rgb(109 40 217 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800 {
  --tw-gradient-from: #5b21b6 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/0 {
  --tw-gradient-from: rgb(91 33 182 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/10 {
  --tw-gradient-from: rgb(91 33 182 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/100 {
  --tw-gradient-from: rgb(91 33 182 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/15 {
  --tw-gradient-from: rgb(91 33 182 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/20 {
  --tw-gradient-from: rgb(91 33 182 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/25 {
  --tw-gradient-from: rgb(91 33 182 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/30 {
  --tw-gradient-from: rgb(91 33 182 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/35 {
  --tw-gradient-from: rgb(91 33 182 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/40 {
  --tw-gradient-from: rgb(91 33 182 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/45 {
  --tw-gradient-from: rgb(91 33 182 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/5 {
  --tw-gradient-from: rgb(91 33 182 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/50 {
  --tw-gradient-from: rgb(91 33 182 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/55 {
  --tw-gradient-from: rgb(91 33 182 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/60 {
  --tw-gradient-from: rgb(91 33 182 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/65 {
  --tw-gradient-from: rgb(91 33 182 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/70 {
  --tw-gradient-from: rgb(91 33 182 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/75 {
  --tw-gradient-from: rgb(91 33 182 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/80 {
  --tw-gradient-from: rgb(91 33 182 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/85 {
  --tw-gradient-from: rgb(91 33 182 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/90 {
  --tw-gradient-from: rgb(91 33 182 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-800\/95 {
  --tw-gradient-from: rgb(91 33 182 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900 {
  --tw-gradient-from: #4c1d95 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/0 {
  --tw-gradient-from: rgb(76 29 149 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/10 {
  --tw-gradient-from: rgb(76 29 149 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/100 {
  --tw-gradient-from: rgb(76 29 149 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/15 {
  --tw-gradient-from: rgb(76 29 149 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/20 {
  --tw-gradient-from: rgb(76 29 149 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/25 {
  --tw-gradient-from: rgb(76 29 149 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/30 {
  --tw-gradient-from: rgb(76 29 149 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/35 {
  --tw-gradient-from: rgb(76 29 149 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/40 {
  --tw-gradient-from: rgb(76 29 149 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/45 {
  --tw-gradient-from: rgb(76 29 149 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/5 {
  --tw-gradient-from: rgb(76 29 149 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/50 {
  --tw-gradient-from: rgb(76 29 149 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/55 {
  --tw-gradient-from: rgb(76 29 149 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/60 {
  --tw-gradient-from: rgb(76 29 149 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/65 {
  --tw-gradient-from: rgb(76 29 149 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/70 {
  --tw-gradient-from: rgb(76 29 149 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/75 {
  --tw-gradient-from: rgb(76 29 149 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/80 {
  --tw-gradient-from: rgb(76 29 149 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/85 {
  --tw-gradient-from: rgb(76 29 149 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/90 {
  --tw-gradient-from: rgb(76 29 149 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-900\/95 {
  --tw-gradient-from: rgb(76 29 149 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950 {
  --tw-gradient-from: #2e1065 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/0 {
  --tw-gradient-from: rgb(46 16 101 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/10 {
  --tw-gradient-from: rgb(46 16 101 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/100 {
  --tw-gradient-from: rgb(46 16 101 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/15 {
  --tw-gradient-from: rgb(46 16 101 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/20 {
  --tw-gradient-from: rgb(46 16 101 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/25 {
  --tw-gradient-from: rgb(46 16 101 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/30 {
  --tw-gradient-from: rgb(46 16 101 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/35 {
  --tw-gradient-from: rgb(46 16 101 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/40 {
  --tw-gradient-from: rgb(46 16 101 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/45 {
  --tw-gradient-from: rgb(46 16 101 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/5 {
  --tw-gradient-from: rgb(46 16 101 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/50 {
  --tw-gradient-from: rgb(46 16 101 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/55 {
  --tw-gradient-from: rgb(46 16 101 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/60 {
  --tw-gradient-from: rgb(46 16 101 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/65 {
  --tw-gradient-from: rgb(46 16 101 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/70 {
  --tw-gradient-from: rgb(46 16 101 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/75 {
  --tw-gradient-from: rgb(46 16 101 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/80 {
  --tw-gradient-from: rgb(46 16 101 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/85 {
  --tw-gradient-from: rgb(46 16 101 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/90 {
  --tw-gradient-from: rgb(46 16 101 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-violet-950\/95 {
  --tw-gradient-from: rgb(46 16 101 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white {
  --tw-gradient-from: #fff var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/0 {
  --tw-gradient-from: rgb(255 255 255 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/10 {
  --tw-gradient-from: rgb(255 255 255 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/100 {
  --tw-gradient-from: rgb(255 255 255 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/15 {
  --tw-gradient-from: rgb(255 255 255 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/20 {
  --tw-gradient-from: rgb(255 255 255 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/25 {
  --tw-gradient-from: rgb(255 255 255 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/30 {
  --tw-gradient-from: rgb(255 255 255 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/35 {
  --tw-gradient-from: rgb(255 255 255 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/40 {
  --tw-gradient-from: rgb(255 255 255 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/45 {
  --tw-gradient-from: rgb(255 255 255 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/5 {
  --tw-gradient-from: rgb(255 255 255 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/50 {
  --tw-gradient-from: rgb(255 255 255 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/55 {
  --tw-gradient-from: rgb(255 255 255 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/60 {
  --tw-gradient-from: rgb(255 255 255 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/65 {
  --tw-gradient-from: rgb(255 255 255 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/70 {
  --tw-gradient-from: rgb(255 255 255 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/75 {
  --tw-gradient-from: rgb(255 255 255 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/80 {
  --tw-gradient-from: rgb(255 255 255 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/85 {
  --tw-gradient-from: rgb(255 255 255 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/90 {
  --tw-gradient-from: rgb(255 255 255 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-white\/95 {
  --tw-gradient-from: rgb(255 255 255 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100 {
  --tw-gradient-from: #fef9c3 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/0 {
  --tw-gradient-from: rgb(254 249 195 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/10 {
  --tw-gradient-from: rgb(254 249 195 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/100 {
  --tw-gradient-from: rgb(254 249 195 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/15 {
  --tw-gradient-from: rgb(254 249 195 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/20 {
  --tw-gradient-from: rgb(254 249 195 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/25 {
  --tw-gradient-from: rgb(254 249 195 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/30 {
  --tw-gradient-from: rgb(254 249 195 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/35 {
  --tw-gradient-from: rgb(254 249 195 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/40 {
  --tw-gradient-from: rgb(254 249 195 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/45 {
  --tw-gradient-from: rgb(254 249 195 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/5 {
  --tw-gradient-from: rgb(254 249 195 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/50 {
  --tw-gradient-from: rgb(254 249 195 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/55 {
  --tw-gradient-from: rgb(254 249 195 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/60 {
  --tw-gradient-from: rgb(254 249 195 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/65 {
  --tw-gradient-from: rgb(254 249 195 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/70 {
  --tw-gradient-from: rgb(254 249 195 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/75 {
  --tw-gradient-from: rgb(254 249 195 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/80 {
  --tw-gradient-from: rgb(254 249 195 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/85 {
  --tw-gradient-from: rgb(254 249 195 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/90 {
  --tw-gradient-from: rgb(254 249 195 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-100\/95 {
  --tw-gradient-from: rgb(254 249 195 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200 {
  --tw-gradient-from: #fef08a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/0 {
  --tw-gradient-from: rgb(254 240 138 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/10 {
  --tw-gradient-from: rgb(254 240 138 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/100 {
  --tw-gradient-from: rgb(254 240 138 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/15 {
  --tw-gradient-from: rgb(254 240 138 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/20 {
  --tw-gradient-from: rgb(254 240 138 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/25 {
  --tw-gradient-from: rgb(254 240 138 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/30 {
  --tw-gradient-from: rgb(254 240 138 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/35 {
  --tw-gradient-from: rgb(254 240 138 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/40 {
  --tw-gradient-from: rgb(254 240 138 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/45 {
  --tw-gradient-from: rgb(254 240 138 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/5 {
  --tw-gradient-from: rgb(254 240 138 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/50 {
  --tw-gradient-from: rgb(254 240 138 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/55 {
  --tw-gradient-from: rgb(254 240 138 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/60 {
  --tw-gradient-from: rgb(254 240 138 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/65 {
  --tw-gradient-from: rgb(254 240 138 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/70 {
  --tw-gradient-from: rgb(254 240 138 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/75 {
  --tw-gradient-from: rgb(254 240 138 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/80 {
  --tw-gradient-from: rgb(254 240 138 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/85 {
  --tw-gradient-from: rgb(254 240 138 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/90 {
  --tw-gradient-from: rgb(254 240 138 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-200\/95 {
  --tw-gradient-from: rgb(254 240 138 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300 {
  --tw-gradient-from: #fde047 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/0 {
  --tw-gradient-from: rgb(253 224 71 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/10 {
  --tw-gradient-from: rgb(253 224 71 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/100 {
  --tw-gradient-from: rgb(253 224 71 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/15 {
  --tw-gradient-from: rgb(253 224 71 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/20 {
  --tw-gradient-from: rgb(253 224 71 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/25 {
  --tw-gradient-from: rgb(253 224 71 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/30 {
  --tw-gradient-from: rgb(253 224 71 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/35 {
  --tw-gradient-from: rgb(253 224 71 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/40 {
  --tw-gradient-from: rgb(253 224 71 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/45 {
  --tw-gradient-from: rgb(253 224 71 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/5 {
  --tw-gradient-from: rgb(253 224 71 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/50 {
  --tw-gradient-from: rgb(253 224 71 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/55 {
  --tw-gradient-from: rgb(253 224 71 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/60 {
  --tw-gradient-from: rgb(253 224 71 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/65 {
  --tw-gradient-from: rgb(253 224 71 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/70 {
  --tw-gradient-from: rgb(253 224 71 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/75 {
  --tw-gradient-from: rgb(253 224 71 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/80 {
  --tw-gradient-from: rgb(253 224 71 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/85 {
  --tw-gradient-from: rgb(253 224 71 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/90 {
  --tw-gradient-from: rgb(253 224 71 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-300\/95 {
  --tw-gradient-from: rgb(253 224 71 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400 {
  --tw-gradient-from: #facc15 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/0 {
  --tw-gradient-from: rgb(250 204 21 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/10 {
  --tw-gradient-from: rgb(250 204 21 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/100 {
  --tw-gradient-from: rgb(250 204 21 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/15 {
  --tw-gradient-from: rgb(250 204 21 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/20 {
  --tw-gradient-from: rgb(250 204 21 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/25 {
  --tw-gradient-from: rgb(250 204 21 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/30 {
  --tw-gradient-from: rgb(250 204 21 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/35 {
  --tw-gradient-from: rgb(250 204 21 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/40 {
  --tw-gradient-from: rgb(250 204 21 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/45 {
  --tw-gradient-from: rgb(250 204 21 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/5 {
  --tw-gradient-from: rgb(250 204 21 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/50 {
  --tw-gradient-from: rgb(250 204 21 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/55 {
  --tw-gradient-from: rgb(250 204 21 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/60 {
  --tw-gradient-from: rgb(250 204 21 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/65 {
  --tw-gradient-from: rgb(250 204 21 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/70 {
  --tw-gradient-from: rgb(250 204 21 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/75 {
  --tw-gradient-from: rgb(250 204 21 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/80 {
  --tw-gradient-from: rgb(250 204 21 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/85 {
  --tw-gradient-from: rgb(250 204 21 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/90 {
  --tw-gradient-from: rgb(250 204 21 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-400\/95 {
  --tw-gradient-from: rgb(250 204 21 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50 {
  --tw-gradient-from: #fefce8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/0 {
  --tw-gradient-from: rgb(254 252 232 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/10 {
  --tw-gradient-from: rgb(254 252 232 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/100 {
  --tw-gradient-from: rgb(254 252 232 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/15 {
  --tw-gradient-from: rgb(254 252 232 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/20 {
  --tw-gradient-from: rgb(254 252 232 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/25 {
  --tw-gradient-from: rgb(254 252 232 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/30 {
  --tw-gradient-from: rgb(254 252 232 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/35 {
  --tw-gradient-from: rgb(254 252 232 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/40 {
  --tw-gradient-from: rgb(254 252 232 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/45 {
  --tw-gradient-from: rgb(254 252 232 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/5 {
  --tw-gradient-from: rgb(254 252 232 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/50 {
  --tw-gradient-from: rgb(254 252 232 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/55 {
  --tw-gradient-from: rgb(254 252 232 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/60 {
  --tw-gradient-from: rgb(254 252 232 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/65 {
  --tw-gradient-from: rgb(254 252 232 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/70 {
  --tw-gradient-from: rgb(254 252 232 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/75 {
  --tw-gradient-from: rgb(254 252 232 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/80 {
  --tw-gradient-from: rgb(254 252 232 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/85 {
  --tw-gradient-from: rgb(254 252 232 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/90 {
  --tw-gradient-from: rgb(254 252 232 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-50\/95 {
  --tw-gradient-from: rgb(254 252 232 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500 {
  --tw-gradient-from: #eab308 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/0 {
  --tw-gradient-from: rgb(234 179 8 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/10 {
  --tw-gradient-from: rgb(234 179 8 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/100 {
  --tw-gradient-from: rgb(234 179 8 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/15 {
  --tw-gradient-from: rgb(234 179 8 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/20 {
  --tw-gradient-from: rgb(234 179 8 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/25 {
  --tw-gradient-from: rgb(234 179 8 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/30 {
  --tw-gradient-from: rgb(234 179 8 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/35 {
  --tw-gradient-from: rgb(234 179 8 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/40 {
  --tw-gradient-from: rgb(234 179 8 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/45 {
  --tw-gradient-from: rgb(234 179 8 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/5 {
  --tw-gradient-from: rgb(234 179 8 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/50 {
  --tw-gradient-from: rgb(234 179 8 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/55 {
  --tw-gradient-from: rgb(234 179 8 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/60 {
  --tw-gradient-from: rgb(234 179 8 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/65 {
  --tw-gradient-from: rgb(234 179 8 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/70 {
  --tw-gradient-from: rgb(234 179 8 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/75 {
  --tw-gradient-from: rgb(234 179 8 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/80 {
  --tw-gradient-from: rgb(234 179 8 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/85 {
  --tw-gradient-from: rgb(234 179 8 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/90 {
  --tw-gradient-from: rgb(234 179 8 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-500\/95 {
  --tw-gradient-from: rgb(234 179 8 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600 {
  --tw-gradient-from: #ca8a04 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/0 {
  --tw-gradient-from: rgb(202 138 4 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/10 {
  --tw-gradient-from: rgb(202 138 4 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/100 {
  --tw-gradient-from: rgb(202 138 4 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/15 {
  --tw-gradient-from: rgb(202 138 4 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/20 {
  --tw-gradient-from: rgb(202 138 4 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/25 {
  --tw-gradient-from: rgb(202 138 4 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/30 {
  --tw-gradient-from: rgb(202 138 4 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/35 {
  --tw-gradient-from: rgb(202 138 4 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/40 {
  --tw-gradient-from: rgb(202 138 4 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/45 {
  --tw-gradient-from: rgb(202 138 4 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/5 {
  --tw-gradient-from: rgb(202 138 4 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/50 {
  --tw-gradient-from: rgb(202 138 4 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/55 {
  --tw-gradient-from: rgb(202 138 4 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/60 {
  --tw-gradient-from: rgb(202 138 4 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/65 {
  --tw-gradient-from: rgb(202 138 4 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/70 {
  --tw-gradient-from: rgb(202 138 4 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/75 {
  --tw-gradient-from: rgb(202 138 4 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/80 {
  --tw-gradient-from: rgb(202 138 4 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/85 {
  --tw-gradient-from: rgb(202 138 4 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/90 {
  --tw-gradient-from: rgb(202 138 4 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-600\/95 {
  --tw-gradient-from: rgb(202 138 4 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700 {
  --tw-gradient-from: #a16207 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/0 {
  --tw-gradient-from: rgb(161 98 7 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/10 {
  --tw-gradient-from: rgb(161 98 7 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/100 {
  --tw-gradient-from: rgb(161 98 7 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/15 {
  --tw-gradient-from: rgb(161 98 7 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/20 {
  --tw-gradient-from: rgb(161 98 7 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/25 {
  --tw-gradient-from: rgb(161 98 7 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/30 {
  --tw-gradient-from: rgb(161 98 7 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/35 {
  --tw-gradient-from: rgb(161 98 7 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/40 {
  --tw-gradient-from: rgb(161 98 7 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/45 {
  --tw-gradient-from: rgb(161 98 7 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/5 {
  --tw-gradient-from: rgb(161 98 7 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/50 {
  --tw-gradient-from: rgb(161 98 7 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/55 {
  --tw-gradient-from: rgb(161 98 7 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/60 {
  --tw-gradient-from: rgb(161 98 7 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/65 {
  --tw-gradient-from: rgb(161 98 7 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/70 {
  --tw-gradient-from: rgb(161 98 7 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/75 {
  --tw-gradient-from: rgb(161 98 7 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/80 {
  --tw-gradient-from: rgb(161 98 7 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/85 {
  --tw-gradient-from: rgb(161 98 7 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/90 {
  --tw-gradient-from: rgb(161 98 7 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-700\/95 {
  --tw-gradient-from: rgb(161 98 7 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800 {
  --tw-gradient-from: #854d0e var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/0 {
  --tw-gradient-from: rgb(133 77 14 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/10 {
  --tw-gradient-from: rgb(133 77 14 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/100 {
  --tw-gradient-from: rgb(133 77 14 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/15 {
  --tw-gradient-from: rgb(133 77 14 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/20 {
  --tw-gradient-from: rgb(133 77 14 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/25 {
  --tw-gradient-from: rgb(133 77 14 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/30 {
  --tw-gradient-from: rgb(133 77 14 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/35 {
  --tw-gradient-from: rgb(133 77 14 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/40 {
  --tw-gradient-from: rgb(133 77 14 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/45 {
  --tw-gradient-from: rgb(133 77 14 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/5 {
  --tw-gradient-from: rgb(133 77 14 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/50 {
  --tw-gradient-from: rgb(133 77 14 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/55 {
  --tw-gradient-from: rgb(133 77 14 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/60 {
  --tw-gradient-from: rgb(133 77 14 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/65 {
  --tw-gradient-from: rgb(133 77 14 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/70 {
  --tw-gradient-from: rgb(133 77 14 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/75 {
  --tw-gradient-from: rgb(133 77 14 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/80 {
  --tw-gradient-from: rgb(133 77 14 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/85 {
  --tw-gradient-from: rgb(133 77 14 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/90 {
  --tw-gradient-from: rgb(133 77 14 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-800\/95 {
  --tw-gradient-from: rgb(133 77 14 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900 {
  --tw-gradient-from: #713f12 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/0 {
  --tw-gradient-from: rgb(113 63 18 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/10 {
  --tw-gradient-from: rgb(113 63 18 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/100 {
  --tw-gradient-from: rgb(113 63 18 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/15 {
  --tw-gradient-from: rgb(113 63 18 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/20 {
  --tw-gradient-from: rgb(113 63 18 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/25 {
  --tw-gradient-from: rgb(113 63 18 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/30 {
  --tw-gradient-from: rgb(113 63 18 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/35 {
  --tw-gradient-from: rgb(113 63 18 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/40 {
  --tw-gradient-from: rgb(113 63 18 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/45 {
  --tw-gradient-from: rgb(113 63 18 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/5 {
  --tw-gradient-from: rgb(113 63 18 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/50 {
  --tw-gradient-from: rgb(113 63 18 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/55 {
  --tw-gradient-from: rgb(113 63 18 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/60 {
  --tw-gradient-from: rgb(113 63 18 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/65 {
  --tw-gradient-from: rgb(113 63 18 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/70 {
  --tw-gradient-from: rgb(113 63 18 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/75 {
  --tw-gradient-from: rgb(113 63 18 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/80 {
  --tw-gradient-from: rgb(113 63 18 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/85 {
  --tw-gradient-from: rgb(113 63 18 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/90 {
  --tw-gradient-from: rgb(113 63 18 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-900\/95 {
  --tw-gradient-from: rgb(113 63 18 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950 {
  --tw-gradient-from: #422006 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/0 {
  --tw-gradient-from: rgb(66 32 6 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/10 {
  --tw-gradient-from: rgb(66 32 6 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/100 {
  --tw-gradient-from: rgb(66 32 6 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/15 {
  --tw-gradient-from: rgb(66 32 6 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/20 {
  --tw-gradient-from: rgb(66 32 6 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/25 {
  --tw-gradient-from: rgb(66 32 6 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/30 {
  --tw-gradient-from: rgb(66 32 6 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/35 {
  --tw-gradient-from: rgb(66 32 6 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/40 {
  --tw-gradient-from: rgb(66 32 6 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/45 {
  --tw-gradient-from: rgb(66 32 6 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/5 {
  --tw-gradient-from: rgb(66 32 6 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/50 {
  --tw-gradient-from: rgb(66 32 6 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/55 {
  --tw-gradient-from: rgb(66 32 6 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/60 {
  --tw-gradient-from: rgb(66 32 6 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/65 {
  --tw-gradient-from: rgb(66 32 6 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/70 {
  --tw-gradient-from: rgb(66 32 6 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/75 {
  --tw-gradient-from: rgb(66 32 6 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/80 {
  --tw-gradient-from: rgb(66 32 6 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/85 {
  --tw-gradient-from: rgb(66 32 6 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/90 {
  --tw-gradient-from: rgb(66 32 6 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-yellow-950\/95 {
  --tw-gradient-from: rgb(66 32 6 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100 {
  --tw-gradient-from: #f4f4f5 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/0 {
  --tw-gradient-from: rgb(244 244 245 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/10 {
  --tw-gradient-from: rgb(244 244 245 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/100 {
  --tw-gradient-from: rgb(244 244 245 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/15 {
  --tw-gradient-from: rgb(244 244 245 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/20 {
  --tw-gradient-from: rgb(244 244 245 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/25 {
  --tw-gradient-from: rgb(244 244 245 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/30 {
  --tw-gradient-from: rgb(244 244 245 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/35 {
  --tw-gradient-from: rgb(244 244 245 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/40 {
  --tw-gradient-from: rgb(244 244 245 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/45 {
  --tw-gradient-from: rgb(244 244 245 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/5 {
  --tw-gradient-from: rgb(244 244 245 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/50 {
  --tw-gradient-from: rgb(244 244 245 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/55 {
  --tw-gradient-from: rgb(244 244 245 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/60 {
  --tw-gradient-from: rgb(244 244 245 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/65 {
  --tw-gradient-from: rgb(244 244 245 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/70 {
  --tw-gradient-from: rgb(244 244 245 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/75 {
  --tw-gradient-from: rgb(244 244 245 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/80 {
  --tw-gradient-from: rgb(244 244 245 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/85 {
  --tw-gradient-from: rgb(244 244 245 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/90 {
  --tw-gradient-from: rgb(244 244 245 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-100\/95 {
  --tw-gradient-from: rgb(244 244 245 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200 {
  --tw-gradient-from: #e4e4e7 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/0 {
  --tw-gradient-from: rgb(228 228 231 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/10 {
  --tw-gradient-from: rgb(228 228 231 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/100 {
  --tw-gradient-from: rgb(228 228 231 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/15 {
  --tw-gradient-from: rgb(228 228 231 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/20 {
  --tw-gradient-from: rgb(228 228 231 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/25 {
  --tw-gradient-from: rgb(228 228 231 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/30 {
  --tw-gradient-from: rgb(228 228 231 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/35 {
  --tw-gradient-from: rgb(228 228 231 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/40 {
  --tw-gradient-from: rgb(228 228 231 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/45 {
  --tw-gradient-from: rgb(228 228 231 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/5 {
  --tw-gradient-from: rgb(228 228 231 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/50 {
  --tw-gradient-from: rgb(228 228 231 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/55 {
  --tw-gradient-from: rgb(228 228 231 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/60 {
  --tw-gradient-from: rgb(228 228 231 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/65 {
  --tw-gradient-from: rgb(228 228 231 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/70 {
  --tw-gradient-from: rgb(228 228 231 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/75 {
  --tw-gradient-from: rgb(228 228 231 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/80 {
  --tw-gradient-from: rgb(228 228 231 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/85 {
  --tw-gradient-from: rgb(228 228 231 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/90 {
  --tw-gradient-from: rgb(228 228 231 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-200\/95 {
  --tw-gradient-from: rgb(228 228 231 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300 {
  --tw-gradient-from: #d4d4d8 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/0 {
  --tw-gradient-from: rgb(212 212 216 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/10 {
  --tw-gradient-from: rgb(212 212 216 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/100 {
  --tw-gradient-from: rgb(212 212 216 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/15 {
  --tw-gradient-from: rgb(212 212 216 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/20 {
  --tw-gradient-from: rgb(212 212 216 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/25 {
  --tw-gradient-from: rgb(212 212 216 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/30 {
  --tw-gradient-from: rgb(212 212 216 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/35 {
  --tw-gradient-from: rgb(212 212 216 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/40 {
  --tw-gradient-from: rgb(212 212 216 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/45 {
  --tw-gradient-from: rgb(212 212 216 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/5 {
  --tw-gradient-from: rgb(212 212 216 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/50 {
  --tw-gradient-from: rgb(212 212 216 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/55 {
  --tw-gradient-from: rgb(212 212 216 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/60 {
  --tw-gradient-from: rgb(212 212 216 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/65 {
  --tw-gradient-from: rgb(212 212 216 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/70 {
  --tw-gradient-from: rgb(212 212 216 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/75 {
  --tw-gradient-from: rgb(212 212 216 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/80 {
  --tw-gradient-from: rgb(212 212 216 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/85 {
  --tw-gradient-from: rgb(212 212 216 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/90 {
  --tw-gradient-from: rgb(212 212 216 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-300\/95 {
  --tw-gradient-from: rgb(212 212 216 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400 {
  --tw-gradient-from: #a1a1aa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/0 {
  --tw-gradient-from: rgb(161 161 170 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/10 {
  --tw-gradient-from: rgb(161 161 170 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/100 {
  --tw-gradient-from: rgb(161 161 170 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/15 {
  --tw-gradient-from: rgb(161 161 170 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/20 {
  --tw-gradient-from: rgb(161 161 170 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/25 {
  --tw-gradient-from: rgb(161 161 170 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/30 {
  --tw-gradient-from: rgb(161 161 170 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/35 {
  --tw-gradient-from: rgb(161 161 170 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/40 {
  --tw-gradient-from: rgb(161 161 170 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/45 {
  --tw-gradient-from: rgb(161 161 170 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/5 {
  --tw-gradient-from: rgb(161 161 170 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/50 {
  --tw-gradient-from: rgb(161 161 170 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/55 {
  --tw-gradient-from: rgb(161 161 170 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/60 {
  --tw-gradient-from: rgb(161 161 170 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/65 {
  --tw-gradient-from: rgb(161 161 170 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/70 {
  --tw-gradient-from: rgb(161 161 170 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/75 {
  --tw-gradient-from: rgb(161 161 170 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/80 {
  --tw-gradient-from: rgb(161 161 170 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/85 {
  --tw-gradient-from: rgb(161 161 170 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/90 {
  --tw-gradient-from: rgb(161 161 170 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-400\/95 {
  --tw-gradient-from: rgb(161 161 170 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50 {
  --tw-gradient-from: #fafafa var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/0 {
  --tw-gradient-from: rgb(250 250 250 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/10 {
  --tw-gradient-from: rgb(250 250 250 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/100 {
  --tw-gradient-from: rgb(250 250 250 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/15 {
  --tw-gradient-from: rgb(250 250 250 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/20 {
  --tw-gradient-from: rgb(250 250 250 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/25 {
  --tw-gradient-from: rgb(250 250 250 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/30 {
  --tw-gradient-from: rgb(250 250 250 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/35 {
  --tw-gradient-from: rgb(250 250 250 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/40 {
  --tw-gradient-from: rgb(250 250 250 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/45 {
  --tw-gradient-from: rgb(250 250 250 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/5 {
  --tw-gradient-from: rgb(250 250 250 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/50 {
  --tw-gradient-from: rgb(250 250 250 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/55 {
  --tw-gradient-from: rgb(250 250 250 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/60 {
  --tw-gradient-from: rgb(250 250 250 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/65 {
  --tw-gradient-from: rgb(250 250 250 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/70 {
  --tw-gradient-from: rgb(250 250 250 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/75 {
  --tw-gradient-from: rgb(250 250 250 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/80 {
  --tw-gradient-from: rgb(250 250 250 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/85 {
  --tw-gradient-from: rgb(250 250 250 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/90 {
  --tw-gradient-from: rgb(250 250 250 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-50\/95 {
  --tw-gradient-from: rgb(250 250 250 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500 {
  --tw-gradient-from: #71717a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/0 {
  --tw-gradient-from: rgb(113 113 122 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/10 {
  --tw-gradient-from: rgb(113 113 122 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/100 {
  --tw-gradient-from: rgb(113 113 122 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/15 {
  --tw-gradient-from: rgb(113 113 122 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/20 {
  --tw-gradient-from: rgb(113 113 122 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/25 {
  --tw-gradient-from: rgb(113 113 122 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/30 {
  --tw-gradient-from: rgb(113 113 122 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/35 {
  --tw-gradient-from: rgb(113 113 122 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/40 {
  --tw-gradient-from: rgb(113 113 122 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/45 {
  --tw-gradient-from: rgb(113 113 122 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/5 {
  --tw-gradient-from: rgb(113 113 122 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/50 {
  --tw-gradient-from: rgb(113 113 122 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/55 {
  --tw-gradient-from: rgb(113 113 122 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/60 {
  --tw-gradient-from: rgb(113 113 122 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/65 {
  --tw-gradient-from: rgb(113 113 122 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/70 {
  --tw-gradient-from: rgb(113 113 122 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/75 {
  --tw-gradient-from: rgb(113 113 122 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/80 {
  --tw-gradient-from: rgb(113 113 122 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/85 {
  --tw-gradient-from: rgb(113 113 122 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/90 {
  --tw-gradient-from: rgb(113 113 122 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-500\/95 {
  --tw-gradient-from: rgb(113 113 122 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600 {
  --tw-gradient-from: #52525b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/0 {
  --tw-gradient-from: rgb(82 82 91 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/10 {
  --tw-gradient-from: rgb(82 82 91 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/100 {
  --tw-gradient-from: rgb(82 82 91 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/15 {
  --tw-gradient-from: rgb(82 82 91 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/20 {
  --tw-gradient-from: rgb(82 82 91 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/25 {
  --tw-gradient-from: rgb(82 82 91 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/30 {
  --tw-gradient-from: rgb(82 82 91 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/35 {
  --tw-gradient-from: rgb(82 82 91 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/40 {
  --tw-gradient-from: rgb(82 82 91 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/45 {
  --tw-gradient-from: rgb(82 82 91 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/5 {
  --tw-gradient-from: rgb(82 82 91 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/50 {
  --tw-gradient-from: rgb(82 82 91 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/55 {
  --tw-gradient-from: rgb(82 82 91 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/60 {
  --tw-gradient-from: rgb(82 82 91 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/65 {
  --tw-gradient-from: rgb(82 82 91 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/70 {
  --tw-gradient-from: rgb(82 82 91 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/75 {
  --tw-gradient-from: rgb(82 82 91 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/80 {
  --tw-gradient-from: rgb(82 82 91 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/85 {
  --tw-gradient-from: rgb(82 82 91 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/90 {
  --tw-gradient-from: rgb(82 82 91 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-600\/95 {
  --tw-gradient-from: rgb(82 82 91 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700 {
  --tw-gradient-from: #3f3f46 var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/0 {
  --tw-gradient-from: rgb(63 63 70 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/10 {
  --tw-gradient-from: rgb(63 63 70 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/100 {
  --tw-gradient-from: rgb(63 63 70 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/15 {
  --tw-gradient-from: rgb(63 63 70 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/20 {
  --tw-gradient-from: rgb(63 63 70 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/25 {
  --tw-gradient-from: rgb(63 63 70 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/30 {
  --tw-gradient-from: rgb(63 63 70 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/35 {
  --tw-gradient-from: rgb(63 63 70 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/40 {
  --tw-gradient-from: rgb(63 63 70 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/45 {
  --tw-gradient-from: rgb(63 63 70 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/5 {
  --tw-gradient-from: rgb(63 63 70 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/50 {
  --tw-gradient-from: rgb(63 63 70 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/55 {
  --tw-gradient-from: rgb(63 63 70 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/60 {
  --tw-gradient-from: rgb(63 63 70 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/65 {
  --tw-gradient-from: rgb(63 63 70 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/70 {
  --tw-gradient-from: rgb(63 63 70 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/75 {
  --tw-gradient-from: rgb(63 63 70 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/80 {
  --tw-gradient-from: rgb(63 63 70 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/85 {
  --tw-gradient-from: rgb(63 63 70 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/90 {
  --tw-gradient-from: rgb(63 63 70 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-700\/95 {
  --tw-gradient-from: rgb(63 63 70 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800 {
  --tw-gradient-from: #27272a var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/0 {
  --tw-gradient-from: rgb(39 39 42 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/10 {
  --tw-gradient-from: rgb(39 39 42 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/100 {
  --tw-gradient-from: rgb(39 39 42 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/15 {
  --tw-gradient-from: rgb(39 39 42 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/20 {
  --tw-gradient-from: rgb(39 39 42 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/25 {
  --tw-gradient-from: rgb(39 39 42 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/30 {
  --tw-gradient-from: rgb(39 39 42 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/35 {
  --tw-gradient-from: rgb(39 39 42 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/40 {
  --tw-gradient-from: rgb(39 39 42 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/45 {
  --tw-gradient-from: rgb(39 39 42 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/5 {
  --tw-gradient-from: rgb(39 39 42 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/50 {
  --tw-gradient-from: rgb(39 39 42 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/55 {
  --tw-gradient-from: rgb(39 39 42 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/60 {
  --tw-gradient-from: rgb(39 39 42 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/65 {
  --tw-gradient-from: rgb(39 39 42 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/70 {
  --tw-gradient-from: rgb(39 39 42 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/75 {
  --tw-gradient-from: rgb(39 39 42 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/80 {
  --tw-gradient-from: rgb(39 39 42 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/85 {
  --tw-gradient-from: rgb(39 39 42 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/90 {
  --tw-gradient-from: rgb(39 39 42 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-800\/95 {
  --tw-gradient-from: rgb(39 39 42 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900 {
  --tw-gradient-from: #18181b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/0 {
  --tw-gradient-from: rgb(24 24 27 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/10 {
  --tw-gradient-from: rgb(24 24 27 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/100 {
  --tw-gradient-from: rgb(24 24 27 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/15 {
  --tw-gradient-from: rgb(24 24 27 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/20 {
  --tw-gradient-from: rgb(24 24 27 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/25 {
  --tw-gradient-from: rgb(24 24 27 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/30 {
  --tw-gradient-from: rgb(24 24 27 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/35 {
  --tw-gradient-from: rgb(24 24 27 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/40 {
  --tw-gradient-from: rgb(24 24 27 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/45 {
  --tw-gradient-from: rgb(24 24 27 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/5 {
  --tw-gradient-from: rgb(24 24 27 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/50 {
  --tw-gradient-from: rgb(24 24 27 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/55 {
  --tw-gradient-from: rgb(24 24 27 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/60 {
  --tw-gradient-from: rgb(24 24 27 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/65 {
  --tw-gradient-from: rgb(24 24 27 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/70 {
  --tw-gradient-from: rgb(24 24 27 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/75 {
  --tw-gradient-from: rgb(24 24 27 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/80 {
  --tw-gradient-from: rgb(24 24 27 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/85 {
  --tw-gradient-from: rgb(24 24 27 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/90 {
  --tw-gradient-from: rgb(24 24 27 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-900\/95 {
  --tw-gradient-from: rgb(24 24 27 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950 {
  --tw-gradient-from: #09090b var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/0 {
  --tw-gradient-from: rgb(9 9 11 / 0) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/10 {
  --tw-gradient-from: rgb(9 9 11 / 0.1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/100 {
  --tw-gradient-from: rgb(9 9 11 / 1) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/15 {
  --tw-gradient-from: rgb(9 9 11 / 0.15) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/20 {
  --tw-gradient-from: rgb(9 9 11 / 0.2) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/25 {
  --tw-gradient-from: rgb(9 9 11 / 0.25) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/30 {
  --tw-gradient-from: rgb(9 9 11 / 0.3) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/35 {
  --tw-gradient-from: rgb(9 9 11 / 0.35) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/40 {
  --tw-gradient-from: rgb(9 9 11 / 0.4) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/45 {
  --tw-gradient-from: rgb(9 9 11 / 0.45) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/5 {
  --tw-gradient-from: rgb(9 9 11 / 0.05) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/50 {
  --tw-gradient-from: rgb(9 9 11 / 0.5) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/55 {
  --tw-gradient-from: rgb(9 9 11 / 0.55) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/60 {
  --tw-gradient-from: rgb(9 9 11 / 0.6) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/65 {
  --tw-gradient-from: rgb(9 9 11 / 0.65) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/70 {
  --tw-gradient-from: rgb(9 9 11 / 0.7) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/75 {
  --tw-gradient-from: rgb(9 9 11 / 0.75) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/80 {
  --tw-gradient-from: rgb(9 9 11 / 0.8) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/85 {
  --tw-gradient-from: rgb(9 9 11 / 0.85) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/90 {
  --tw-gradient-from: rgb(9 9 11 / 0.9) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-zinc-950\/95 {
  --tw-gradient-from: rgb(9 9 11 / 0.95) var(--tw-gradient-from-position) !important;
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to) !important;
}

.tw-from-0\% {
  --tw-gradient-from-position: 0% !important;
}

.tw-from-10\% {
  --tw-gradient-from-position: 10% !important;
}

.tw-from-100\% {
  --tw-gradient-from-position: 100% !important;
}

.tw-from-15\% {
  --tw-gradient-from-position: 15% !important;
}

.tw-from-20\% {
  --tw-gradient-from-position: 20% !important;
}

.tw-from-25\% {
  --tw-gradient-from-position: 25% !important;
}

.tw-from-30\% {
  --tw-gradient-from-position: 30% !important;
}

.tw-from-35\% {
  --tw-gradient-from-position: 35% !important;
}

.tw-from-40\% {
  --tw-gradient-from-position: 40% !important;
}

.tw-from-45\% {
  --tw-gradient-from-position: 45% !important;
}

.tw-from-5\% {
  --tw-gradient-from-position: 5% !important;
}

.tw-from-50\% {
  --tw-gradient-from-position: 50% !important;
}

.tw-from-55\% {
  --tw-gradient-from-position: 55% !important;
}

.tw-from-60\% {
  --tw-gradient-from-position: 60% !important;
}

.tw-from-65\% {
  --tw-gradient-from-position: 65% !important;
}

.tw-from-70\% {
  --tw-gradient-from-position: 70% !important;
}

.tw-from-75\% {
  --tw-gradient-from-position: 75% !important;
}

.tw-from-80\% {
  --tw-gradient-from-position: 80% !important;
}

.tw-from-85\% {
  --tw-gradient-from-position: 85% !important;
}

.tw-from-90\% {
  --tw-gradient-from-position: 90% !important;
}

.tw-from-95\% {
  --tw-gradient-from-position: 95% !important;
}

.tw-via-amber-100 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fef3c7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/0 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/10 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/100 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/15 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/20 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/25 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/30 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/35 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/40 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/45 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/5 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/50 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/55 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/60 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/65 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/70 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/75 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/80 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/85 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/90 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-100\/95 {
  --tw-gradient-to: rgb(254 243 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 243 199 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fde68a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/0 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/10 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/100 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/15 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/20 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/25 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/30 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/35 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/40 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/45 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/5 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/50 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/55 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/60 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/65 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/70 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/75 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/80 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/85 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/90 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-200\/95 {
  --tw-gradient-to: rgb(253 230 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 230 138 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fcd34d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/0 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/10 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/100 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/15 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/20 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/25 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/30 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/35 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/40 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/45 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/5 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/50 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/55 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/60 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/65 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/70 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/75 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/80 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/85 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/90 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-300\/95 {
  --tw-gradient-to: rgb(252 211 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 211 77 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fbbf24 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/0 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/10 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/100 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/15 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/20 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/25 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/30 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/35 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/40 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/45 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/5 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/50 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/55 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/60 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/65 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/70 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/75 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/80 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/85 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/90 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-400\/95 {
  --tw-gradient-to: rgb(251 191 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 191 36 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fffbeb var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/0 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/10 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/100 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/15 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/20 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/25 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/30 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/35 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/40 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/45 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/5 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/50 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/55 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/60 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/65 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/70 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/75 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/80 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/85 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/90 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-50\/95 {
  --tw-gradient-to: rgb(255 251 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 251 235 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f59e0b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/0 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/10 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/100 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/15 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/20 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/25 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/30 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/35 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/40 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/45 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/5 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/50 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/55 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/60 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/65 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/70 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/75 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/80 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/85 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/90 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-500\/95 {
  --tw-gradient-to: rgb(245 158 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 158 11 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d97706 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/0 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/10 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/100 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/15 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/20 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/25 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/30 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/35 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/40 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/45 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/5 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/50 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/55 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/60 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/65 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/70 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/75 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/80 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/85 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/90 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-600\/95 {
  --tw-gradient-to: rgb(217 119 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 119 6 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #b45309 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/0 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/10 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/100 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/15 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/20 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/25 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/30 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/35 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/40 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/45 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/5 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/50 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/55 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/60 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/65 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/70 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/75 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/80 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/85 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/90 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-700\/95 {
  --tw-gradient-to: rgb(180 83 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(180 83 9 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #92400e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/0 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/10 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/100 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/15 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/20 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/25 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/30 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/35 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/40 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/45 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/5 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/50 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/55 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/60 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/65 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/70 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/75 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/80 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/85 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/90 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-800\/95 {
  --tw-gradient-to: rgb(146 64 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(146 64 14 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #78350f var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/0 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/10 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/100 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/15 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/20 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/25 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/30 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/35 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/40 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/45 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/5 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/50 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/55 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/60 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/65 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/70 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/75 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/80 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/85 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/90 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-900\/95 {
  --tw-gradient-to: rgb(120 53 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 53 15 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #451a03 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/0 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/10 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/100 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/15 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/20 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/25 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/30 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/35 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/40 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/45 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/5 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/50 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/55 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/60 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/65 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/70 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/75 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/80 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/85 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/90 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-amber-950\/95 {
  --tw-gradient-to: rgb(69 26 3 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 26 3 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #000 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/0 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/10 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/100 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/15 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/20 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/25 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/30 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/35 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/40 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/45 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/5 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/50 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/55 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/60 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/65 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/70 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/75 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/80 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/85 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/90 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-black\/95 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #dbeafe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/0 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/10 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/100 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/15 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/20 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/25 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/30 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/35 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/40 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/45 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/5 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/50 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/55 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/60 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/65 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/70 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/75 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/80 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/85 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/90 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-100\/95 {
  --tw-gradient-to: rgb(219 234 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 234 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #bfdbfe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/0 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/10 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/100 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/15 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/20 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/25 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/30 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/35 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/40 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/45 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/5 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/50 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/55 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/60 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/65 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/70 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/75 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/80 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/85 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/90 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-200\/95 {
  --tw-gradient-to: rgb(191 219 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(191 219 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #93c5fd var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/0 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/10 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/100 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/15 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/20 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/25 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/30 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/35 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/40 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/45 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/5 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/50 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/55 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/60 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/65 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/70 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/75 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/80 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/85 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/90 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-300\/95 {
  --tw-gradient-to: rgb(147 197 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 197 253 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #60a5fa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/0 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/10 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/100 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/15 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/20 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/25 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/30 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/35 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/40 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/45 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/5 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/50 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/55 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/60 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/65 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/70 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/75 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/80 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/85 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/90 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-400\/95 {
  --tw-gradient-to: rgb(96 165 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(96 165 250 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #eff6ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/0 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/10 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/100 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/15 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/20 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/25 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/30 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/35 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/40 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/45 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/5 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/50 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/55 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/60 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/65 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/70 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/75 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/80 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/85 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/90 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-50\/95 {
  --tw-gradient-to: rgb(239 246 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 246 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #3b82f6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/0 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/10 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/100 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/15 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/20 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/25 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/30 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/35 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/40 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/45 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/5 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/50 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/55 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/60 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/65 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/70 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/75 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/80 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/85 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/90 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-500\/95 {
  --tw-gradient-to: rgb(59 130 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 130 246 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #2563eb var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/0 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/10 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/100 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/15 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/20 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/25 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/30 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/35 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/40 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/45 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/5 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/50 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/55 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/60 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/65 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/70 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/75 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/80 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/85 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/90 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-600\/95 {
  --tw-gradient-to: rgb(37 99 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(37 99 235 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1d4ed8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/0 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/10 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/100 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/15 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/20 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/25 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/30 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/35 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/40 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/45 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/5 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/50 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/55 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/60 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/65 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/70 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/75 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/80 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/85 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/90 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-700\/95 {
  --tw-gradient-to: rgb(29 78 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(29 78 216 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1e40af var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/0 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/10 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/100 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/15 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/20 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/25 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/30 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/35 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/40 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/45 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/5 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/50 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/55 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/60 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/65 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/70 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/75 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/80 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/85 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/90 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-800\/95 {
  --tw-gradient-to: rgb(30 64 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 64 175 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1e3a8a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/0 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/10 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/100 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/15 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/20 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/25 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/30 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/35 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/40 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/45 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/5 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/50 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/55 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/60 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/65 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/70 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/75 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/80 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/85 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/90 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-900\/95 {
  --tw-gradient-to: rgb(30 58 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 58 138 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #172554 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/0 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/10 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/100 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/15 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/20 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/25 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/30 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/35 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/40 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/45 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/5 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/50 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/55 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/60 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/65 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/70 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/75 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/80 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/85 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/90 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-blue-950\/95 {
  --tw-gradient-to: rgb(23 37 84 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 37 84 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-current {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), currentColor var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #cffafe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/0 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/10 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/100 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/15 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/20 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/25 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/30 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/35 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/40 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/45 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/5 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/50 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/55 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/60 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/65 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/70 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/75 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/80 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/85 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/90 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-100\/95 {
  --tw-gradient-to: rgb(207 250 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(207 250 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a5f3fc var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/0 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/10 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/100 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/15 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/20 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/25 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/30 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/35 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/40 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/45 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/5 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/50 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/55 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/60 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/65 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/70 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/75 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/80 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/85 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/90 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-200\/95 {
  --tw-gradient-to: rgb(165 243 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 243 252 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #67e8f9 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/0 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/10 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/100 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/15 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/20 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/25 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/30 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/35 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/40 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/45 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/5 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/50 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/55 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/60 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/65 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/70 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/75 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/80 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/85 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/90 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-300\/95 {
  --tw-gradient-to: rgb(103 232 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(103 232 249 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #22d3ee var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/0 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/10 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/100 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/15 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/20 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/25 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/30 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/35 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/40 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/45 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/5 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/50 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/55 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/60 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/65 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/70 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/75 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/80 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/85 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/90 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-400\/95 {
  --tw-gradient-to: rgb(34 211 238 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 211 238 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ecfeff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/0 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/10 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/100 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/15 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/20 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/25 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/30 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/35 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/40 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/45 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/5 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/50 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/55 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/60 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/65 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/70 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/75 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/80 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/85 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/90 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-50\/95 {
  --tw-gradient-to: rgb(236 254 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 254 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #06b6d4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/0 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/10 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/100 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/15 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/20 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/25 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/30 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/35 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/40 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/45 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/5 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/50 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/55 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/60 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/65 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/70 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/75 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/80 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/85 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/90 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-500\/95 {
  --tw-gradient-to: rgb(6 182 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 182 212 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0891b2 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/0 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/10 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/100 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/15 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/20 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/25 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/30 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/35 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/40 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/45 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/5 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/50 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/55 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/60 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/65 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/70 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/75 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/80 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/85 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/90 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-600\/95 {
  --tw-gradient-to: rgb(8 145 178 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 145 178 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0e7490 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/0 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/10 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/100 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/15 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/20 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/25 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/30 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/35 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/40 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/45 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/5 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/50 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/55 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/60 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/65 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/70 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/75 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/80 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/85 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/90 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-700\/95 {
  --tw-gradient-to: rgb(14 116 144 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 116 144 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #155e75 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/0 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/10 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/100 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/15 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/20 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/25 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/30 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/35 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/40 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/45 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/5 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/50 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/55 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/60 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/65 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/70 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/75 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/80 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/85 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/90 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-800\/95 {
  --tw-gradient-to: rgb(21 94 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 94 117 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #164e63 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/0 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/10 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/100 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/15 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/20 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/25 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/30 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/35 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/40 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/45 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/5 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/50 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/55 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/60 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/65 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/70 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/75 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/80 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/85 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/90 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-900\/95 {
  --tw-gradient-to: rgb(22 78 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 78 99 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #083344 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/0 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/10 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/100 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/15 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/20 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/25 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/30 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/35 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/40 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/45 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/5 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/50 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/55 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/60 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/65 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/70 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/75 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/80 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/85 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/90 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-cyan-950\/95 {
  --tw-gradient-to: rgb(8 51 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 51 68 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d1fae5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/0 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/10 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/100 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/15 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/20 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/25 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/30 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/35 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/40 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/45 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/5 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/50 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/55 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/60 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/65 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/70 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/75 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/80 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/85 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/90 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-100\/95 {
  --tw-gradient-to: rgb(209 250 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 250 229 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a7f3d0 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/0 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/10 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/100 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/15 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/20 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/25 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/30 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/35 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/40 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/45 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/5 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/50 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/55 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/60 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/65 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/70 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/75 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/80 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/85 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/90 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-200\/95 {
  --tw-gradient-to: rgb(167 243 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 243 208 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #6ee7b7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/0 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/10 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/100 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/15 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/20 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/25 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/30 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/35 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/40 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/45 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/5 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/50 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/55 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/60 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/65 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/70 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/75 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/80 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/85 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/90 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-300\/95 {
  --tw-gradient-to: rgb(110 231 183 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(110 231 183 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #34d399 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/0 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/10 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/100 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/15 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/20 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/25 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/30 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/35 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/40 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/45 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/5 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/50 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/55 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/60 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/65 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/70 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/75 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/80 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/85 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/90 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-400\/95 {
  --tw-gradient-to: rgb(52 211 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(52 211 153 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ecfdf5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/0 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/10 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/100 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/15 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/20 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/25 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/30 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/35 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/40 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/45 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/5 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/50 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/55 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/60 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/65 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/70 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/75 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/80 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/85 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/90 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-50\/95 {
  --tw-gradient-to: rgb(236 253 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 253 245 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #10b981 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/0 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/10 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/100 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/15 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/20 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/25 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/30 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/35 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/40 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/45 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/5 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/50 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/55 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/60 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/65 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/70 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/75 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/80 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/85 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/90 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-500\/95 {
  --tw-gradient-to: rgb(16 185 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(16 185 129 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #059669 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/0 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/10 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/100 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/15 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/20 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/25 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/30 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/35 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/40 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/45 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/5 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/50 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/55 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/60 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/65 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/70 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/75 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/80 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/85 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/90 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-600\/95 {
  --tw-gradient-to: rgb(5 150 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 150 105 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #047857 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/0 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/10 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/100 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/15 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/20 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/25 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/30 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/35 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/40 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/45 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/5 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/50 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/55 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/60 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/65 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/70 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/75 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/80 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/85 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/90 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-700\/95 {
  --tw-gradient-to: rgb(4 120 87 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 120 87 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #065f46 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/0 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/10 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/100 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/15 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/20 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/25 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/30 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/35 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/40 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/45 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/5 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/50 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/55 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/60 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/65 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/70 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/75 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/80 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/85 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/90 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-800\/95 {
  --tw-gradient-to: rgb(6 95 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 95 70 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #064e3b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/0 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/10 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/100 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/15 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/20 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/25 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/30 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/35 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/40 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/45 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/5 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/50 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/55 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/60 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/65 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/70 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/75 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/80 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/85 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/90 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-900\/95 {
  --tw-gradient-to: rgb(6 78 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(6 78 59 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #022c22 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/0 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/10 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/100 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/15 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/20 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/25 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/30 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/35 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/40 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/45 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/5 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/50 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/55 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/60 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/65 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/70 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/75 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/80 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/85 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/90 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-emerald-950\/95 {
  --tw-gradient-to: rgb(2 44 34 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 44 34 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fae8ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/0 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/10 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/100 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/15 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/20 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/25 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/30 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/35 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/40 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/45 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/5 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/50 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/55 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/60 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/65 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/70 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/75 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/80 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/85 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/90 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-100\/95 {
  --tw-gradient-to: rgb(250 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 232 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f5d0fe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/0 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/10 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/100 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/15 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/20 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/25 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/30 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/35 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/40 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/45 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/5 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/50 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/55 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/60 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/65 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/70 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/75 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/80 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/85 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/90 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-200\/95 {
  --tw-gradient-to: rgb(245 208 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 208 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f0abfc var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/0 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/10 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/100 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/15 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/20 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/25 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/30 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/35 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/40 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/45 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/5 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/50 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/55 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/60 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/65 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/70 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/75 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/80 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/85 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/90 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-300\/95 {
  --tw-gradient-to: rgb(240 171 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 171 252 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e879f9 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/0 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/10 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/100 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/15 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/20 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/25 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/30 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/35 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/40 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/45 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/5 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/50 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/55 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/60 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/65 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/70 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/75 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/80 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/85 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/90 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-400\/95 {
  --tw-gradient-to: rgb(232 121 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(232 121 249 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fdf4ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/0 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/10 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/100 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/15 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/20 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/25 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/30 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/35 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/40 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/45 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/5 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/50 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/55 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/60 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/65 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/70 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/75 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/80 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/85 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/90 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-50\/95 {
  --tw-gradient-to: rgb(253 244 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 244 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d946ef var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/0 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/10 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/100 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/15 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/20 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/25 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/30 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/35 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/40 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/45 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/5 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/50 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/55 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/60 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/65 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/70 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/75 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/80 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/85 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/90 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-500\/95 {
  --tw-gradient-to: rgb(217 70 239 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 70 239 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #c026d3 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/0 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/10 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/100 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/15 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/20 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/25 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/30 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/35 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/40 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/45 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/5 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/50 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/55 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/60 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/65 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/70 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/75 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/80 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/85 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/90 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-600\/95 {
  --tw-gradient-to: rgb(192 38 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 38 211 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a21caf var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/0 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/10 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/100 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/15 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/20 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/25 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/30 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/35 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/40 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/45 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/5 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/50 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/55 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/60 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/65 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/70 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/75 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/80 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/85 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/90 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-700\/95 {
  --tw-gradient-to: rgb(162 28 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(162 28 175 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #86198f var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/0 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/10 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/100 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/15 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/20 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/25 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/30 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/35 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/40 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/45 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/5 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/50 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/55 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/60 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/65 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/70 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/75 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/80 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/85 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/90 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-800\/95 {
  --tw-gradient-to: rgb(134 25 143 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 25 143 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #701a75 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/0 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/10 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/100 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/15 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/20 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/25 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/30 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/35 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/40 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/45 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/5 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/50 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/55 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/60 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/65 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/70 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/75 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/80 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/85 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/90 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-900\/95 {
  --tw-gradient-to: rgb(112 26 117 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(112 26 117 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4a044e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/0 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/10 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/100 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/15 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/20 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/25 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/30 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/35 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/40 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/45 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/5 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/50 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/55 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/60 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/65 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/70 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/75 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/80 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/85 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/90 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-fuchsia-950\/95 {
  --tw-gradient-to: rgb(74 4 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 4 78 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f3f4f6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/0 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/10 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/100 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/15 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/20 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/25 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/30 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/35 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/40 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/45 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/5 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/50 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/55 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/60 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/65 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/70 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/75 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/80 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/85 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/90 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-100\/95 {
  --tw-gradient-to: rgb(243 244 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 244 246 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e5e7eb var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/0 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/10 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/100 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/15 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/20 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/25 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/30 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/35 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/40 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/45 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/5 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/50 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/55 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/60 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/65 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/70 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/75 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/80 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/85 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/90 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-200\/95 {
  --tw-gradient-to: rgb(229 231 235 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 231 235 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d1d5db var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/0 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/10 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/100 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/15 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/20 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/25 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/30 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/35 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/40 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/45 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/5 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/50 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/55 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/60 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/65 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/70 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/75 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/80 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/85 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/90 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-300\/95 {
  --tw-gradient-to: rgb(209 213 219 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(209 213 219 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #9ca3af var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/0 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/10 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/100 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/15 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/20 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/25 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/30 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/35 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/40 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/45 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/5 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/50 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/55 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/60 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/65 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/70 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/75 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/80 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/85 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/90 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-400\/95 {
  --tw-gradient-to: rgb(156 163 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(156 163 175 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f9fafb var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/0 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/10 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/100 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/15 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/20 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/25 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/30 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/35 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/40 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/45 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/5 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/50 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/55 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/60 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/65 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/70 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/75 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/80 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/85 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/90 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-50\/95 {
  --tw-gradient-to: rgb(249 250 251 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 250 251 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #6b7280 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/0 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/10 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/100 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/15 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/20 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/25 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/30 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/35 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/40 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/45 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/5 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/50 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/55 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/60 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/65 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/70 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/75 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/80 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/85 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/90 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-500\/95 {
  --tw-gradient-to: rgb(107 114 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 114 128 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4b5563 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/0 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/10 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/100 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/15 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/20 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/25 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/30 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/35 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/40 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/45 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/5 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/50 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/55 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/60 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/65 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/70 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/75 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/80 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/85 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/90 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-600\/95 {
  --tw-gradient-to: rgb(75 85 99 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(75 85 99 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #374151 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/0 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/10 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/100 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/15 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/20 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/25 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/30 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/35 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/40 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/45 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/5 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/50 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/55 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/60 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/65 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/70 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/75 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/80 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/85 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/90 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-700\/95 {
  --tw-gradient-to: rgb(55 65 81 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 65 81 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1f2937 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/0 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/10 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/100 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/15 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/20 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/25 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/30 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/35 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/40 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/45 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/5 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/50 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/55 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/60 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/65 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/70 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/75 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/80 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/85 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/90 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-800\/95 {
  --tw-gradient-to: rgb(31 41 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(31 41 55 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #111827 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/0 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/10 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/100 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/15 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/20 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/25 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/30 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/35 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/40 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/45 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/5 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/50 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/55 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/60 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/65 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/70 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/75 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/80 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/85 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/90 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-900\/95 {
  --tw-gradient-to: rgb(17 24 39 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 24 39 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #030712 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/0 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/10 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/100 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/15 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/20 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/25 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/30 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/35 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/40 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/45 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/5 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/50 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/55 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/60 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/65 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/70 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/75 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/80 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/85 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/90 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-gray-950\/95 {
  --tw-gradient-to: rgb(3 7 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 7 18 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #dcfce7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/0 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/10 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/100 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/15 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/20 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/25 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/30 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/35 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/40 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/45 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/5 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/50 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/55 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/60 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/65 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/70 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/75 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/80 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/85 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/90 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-100\/95 {
  --tw-gradient-to: rgb(220 252 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 252 231 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #bbf7d0 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/0 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/10 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/100 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/15 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/20 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/25 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/30 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/35 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/40 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/45 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/5 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/50 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/55 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/60 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/65 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/70 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/75 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/80 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/85 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/90 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-200\/95 {
  --tw-gradient-to: rgb(187 247 208 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(187 247 208 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #86efac var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/0 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/10 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/100 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/15 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/20 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/25 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/30 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/35 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/40 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/45 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/5 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/50 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/55 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/60 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/65 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/70 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/75 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/80 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/85 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/90 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-300\/95 {
  --tw-gradient-to: rgb(134 239 172 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(134 239 172 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4ade80 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/0 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/10 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/100 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/15 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/20 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/25 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/30 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/35 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/40 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/45 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/5 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/50 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/55 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/60 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/65 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/70 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/75 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/80 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/85 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/90 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-400\/95 {
  --tw-gradient-to: rgb(74 222 128 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(74 222 128 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f0fdf4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/0 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/10 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/100 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/15 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/20 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/25 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/30 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/35 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/40 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/45 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/5 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/50 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/55 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/60 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/65 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/70 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/75 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/80 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/85 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/90 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-50\/95 {
  --tw-gradient-to: rgb(240 253 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 244 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #22c55e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/0 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/10 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/100 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/15 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/20 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/25 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/30 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/35 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/40 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/45 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/5 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/50 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/55 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/60 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/65 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/70 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/75 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/80 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/85 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/90 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-500\/95 {
  --tw-gradient-to: rgb(34 197 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(34 197 94 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #16a34a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/0 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/10 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/100 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/15 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/20 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/25 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/30 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/35 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/40 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/45 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/5 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/50 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/55 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/60 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/65 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/70 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/75 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/80 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/85 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/90 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-600\/95 {
  --tw-gradient-to: rgb(22 163 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 163 74 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #15803d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/0 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/10 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/100 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/15 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/20 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/25 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/30 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/35 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/40 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/45 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/5 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/50 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/55 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/60 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/65 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/70 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/75 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/80 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/85 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/90 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-700\/95 {
  --tw-gradient-to: rgb(21 128 61 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(21 128 61 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #166534 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/0 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/10 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/100 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/15 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/20 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/25 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/30 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/35 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/40 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/45 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/5 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/50 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/55 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/60 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/65 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/70 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/75 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/80 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/85 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/90 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-800\/95 {
  --tw-gradient-to: rgb(22 101 52 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(22 101 52 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #14532d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/0 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/10 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/100 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/15 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/20 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/25 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/30 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/35 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/40 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/45 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/5 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/50 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/55 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/60 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/65 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/70 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/75 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/80 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/85 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/90 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-900\/95 {
  --tw-gradient-to: rgb(20 83 45 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 83 45 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #052e16 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/0 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/10 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/100 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/15 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/20 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/25 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/30 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/35 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/40 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/45 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/5 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/50 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/55 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/60 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/65 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/70 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/75 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/80 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/85 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/90 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-green-950\/95 {
  --tw-gradient-to: rgb(5 46 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(5 46 22 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e0e7ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/0 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/10 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/100 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/15 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/20 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/25 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/30 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/35 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/40 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/45 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/5 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/50 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/55 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/60 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/65 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/70 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/75 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/80 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/85 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/90 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-100\/95 {
  --tw-gradient-to: rgb(224 231 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 231 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #c7d2fe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/0 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/10 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/100 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/15 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/20 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/25 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/30 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/35 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/40 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/45 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/5 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/50 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/55 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/60 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/65 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/70 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/75 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/80 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/85 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/90 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-200\/95 {
  --tw-gradient-to: rgb(199 210 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(199 210 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a5b4fc var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/0 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/10 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/100 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/15 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/20 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/25 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/30 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/35 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/40 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/45 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/5 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/50 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/55 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/60 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/65 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/70 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/75 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/80 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/85 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/90 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-300\/95 {
  --tw-gradient-to: rgb(165 180 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(165 180 252 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #818cf8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/0 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/10 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/100 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/15 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/20 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/25 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/30 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/35 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/40 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/45 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/5 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/50 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/55 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/60 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/65 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/70 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/75 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/80 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/85 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/90 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-400\/95 {
  --tw-gradient-to: rgb(129 140 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(129 140 248 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #eef2ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/0 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/10 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/100 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/15 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/20 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/25 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/30 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/35 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/40 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/45 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/5 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/50 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/55 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/60 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/65 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/70 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/75 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/80 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/85 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/90 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-50\/95 {
  --tw-gradient-to: rgb(238 242 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(238 242 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #6366f1 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/0 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/10 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/100 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/15 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/20 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/25 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/30 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/35 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/40 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/45 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/5 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/50 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/55 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/60 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/65 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/70 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/75 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/80 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/85 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/90 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-500\/95 {
  --tw-gradient-to: rgb(99 102 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(99 102 241 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4f46e5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/0 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/10 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/100 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/15 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/20 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/25 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/30 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/35 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/40 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/45 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/5 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/50 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/55 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/60 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/65 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/70 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/75 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/80 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/85 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/90 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-600\/95 {
  --tw-gradient-to: rgb(79 70 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(79 70 229 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4338ca var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/0 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/10 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/100 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/15 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/20 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/25 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/30 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/35 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/40 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/45 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/5 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/50 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/55 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/60 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/65 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/70 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/75 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/80 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/85 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/90 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-700\/95 {
  --tw-gradient-to: rgb(67 56 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 56 202 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #3730a3 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/0 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/10 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/100 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/15 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/20 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/25 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/30 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/35 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/40 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/45 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/5 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/50 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/55 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/60 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/65 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/70 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/75 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/80 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/85 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/90 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-800\/95 {
  --tw-gradient-to: rgb(55 48 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(55 48 163 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #312e81 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/0 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/10 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/100 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/15 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/20 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/25 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/30 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/35 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/40 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/45 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/5 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/50 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/55 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/60 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/65 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/70 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/75 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/80 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/85 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/90 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-900\/95 {
  --tw-gradient-to: rgb(49 46 129 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(49 46 129 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1e1b4b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/0 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/10 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/100 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/15 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/20 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/25 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/30 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/35 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/40 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/45 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/5 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/50 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/55 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/60 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/65 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/70 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/75 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/80 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/85 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/90 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-indigo-950\/95 {
  --tw-gradient-to: rgb(30 27 75 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 27 75 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-inherit {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), inherit var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ecfccb var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/0 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/10 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/100 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/15 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/20 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/25 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/30 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/35 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/40 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/45 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/5 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/50 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/55 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/60 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/65 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/70 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/75 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/80 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/85 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/90 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-100\/95 {
  --tw-gradient-to: rgb(236 252 203 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 252 203 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d9f99d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/0 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/10 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/100 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/15 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/20 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/25 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/30 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/35 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/40 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/45 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/5 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/50 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/55 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/60 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/65 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/70 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/75 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/80 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/85 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/90 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-200\/95 {
  --tw-gradient-to: rgb(217 249 157 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(217 249 157 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #bef264 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/0 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/10 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/100 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/15 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/20 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/25 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/30 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/35 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/40 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/45 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/5 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/50 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/55 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/60 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/65 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/70 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/75 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/80 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/85 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/90 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-300\/95 {
  --tw-gradient-to: rgb(190 242 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 242 100 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a3e635 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/0 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/10 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/100 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/15 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/20 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/25 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/30 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/35 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/40 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/45 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/5 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/50 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/55 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/60 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/65 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/70 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/75 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/80 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/85 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/90 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-400\/95 {
  --tw-gradient-to: rgb(163 230 53 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 230 53 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f7fee7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/0 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/10 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/100 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/15 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/20 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/25 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/30 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/35 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/40 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/45 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/5 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/50 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/55 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/60 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/65 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/70 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/75 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/80 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/85 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/90 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-50\/95 {
  --tw-gradient-to: rgb(247 254 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(247 254 231 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #84cc16 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/0 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/10 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/100 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/15 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/20 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/25 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/30 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/35 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/40 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/45 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/5 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/50 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/55 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/60 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/65 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/70 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/75 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/80 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/85 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/90 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-500\/95 {
  --tw-gradient-to: rgb(132 204 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(132 204 22 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #65a30d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/0 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/10 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/100 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/15 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/20 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/25 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/30 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/35 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/40 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/45 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/5 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/50 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/55 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/60 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/65 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/70 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/75 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/80 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/85 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/90 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-600\/95 {
  --tw-gradient-to: rgb(101 163 13 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(101 163 13 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4d7c0f var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/0 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/10 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/100 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/15 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/20 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/25 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/30 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/35 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/40 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/45 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/5 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/50 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/55 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/60 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/65 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/70 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/75 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/80 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/85 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/90 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-700\/95 {
  --tw-gradient-to: rgb(77 124 15 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(77 124 15 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #3f6212 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/0 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/10 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/100 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/15 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/20 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/25 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/30 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/35 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/40 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/45 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/5 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/50 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/55 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/60 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/65 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/70 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/75 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/80 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/85 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/90 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-800\/95 {
  --tw-gradient-to: rgb(63 98 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 98 18 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #365314 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/0 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/10 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/100 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/15 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/20 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/25 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/30 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/35 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/40 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/45 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/5 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/50 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/55 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/60 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/65 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/70 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/75 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/80 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/85 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/90 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-900\/95 {
  --tw-gradient-to: rgb(54 83 20 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(54 83 20 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1a2e05 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/0 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/10 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/100 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/15 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/20 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/25 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/30 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/35 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/40 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/45 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/5 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/50 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/55 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/60 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/65 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/70 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/75 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/80 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/85 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/90 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-lime-950\/95 {
  --tw-gradient-to: rgb(26 46 5 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(26 46 5 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f5f5f5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/0 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/10 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/100 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/15 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/20 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/25 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/30 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/35 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/40 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/45 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/5 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/50 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/55 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/60 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/65 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/70 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/75 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/80 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/85 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/90 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-100\/95 {
  --tw-gradient-to: rgb(245 245 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 245 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e5e5e5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/0 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/10 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/100 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/15 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/20 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/25 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/30 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/35 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/40 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/45 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/5 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/50 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/55 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/60 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/65 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/70 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/75 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/80 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/85 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/90 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-200\/95 {
  --tw-gradient-to: rgb(229 229 229 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(229 229 229 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d4d4d4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/0 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/10 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/100 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/15 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/20 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/25 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/30 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/35 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/40 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/45 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/5 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/50 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/55 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/60 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/65 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/70 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/75 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/80 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/85 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/90 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-300\/95 {
  --tw-gradient-to: rgb(212 212 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 212 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a3a3a3 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/0 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/10 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/100 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/15 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/20 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/25 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/30 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/35 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/40 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/45 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/5 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/50 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/55 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/60 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/65 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/70 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/75 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/80 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/85 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/90 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-400\/95 {
  --tw-gradient-to: rgb(163 163 163 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(163 163 163 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fafafa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/0 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/10 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/100 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/15 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/20 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/25 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/30 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/35 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/40 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/45 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/5 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/50 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/55 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/60 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/65 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/70 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/75 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/80 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/85 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/90 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-50\/95 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #737373 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/0 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/10 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/100 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/15 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/20 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/25 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/30 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/35 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/40 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/45 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/5 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/50 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/55 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/60 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/65 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/70 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/75 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/80 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/85 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/90 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-500\/95 {
  --tw-gradient-to: rgb(115 115 115 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(115 115 115 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #525252 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/0 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/10 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/100 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/15 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/20 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/25 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/30 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/35 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/40 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/45 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/5 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/50 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/55 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/60 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/65 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/70 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/75 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/80 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/85 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/90 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-600\/95 {
  --tw-gradient-to: rgb(82 82 82 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 82 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #404040 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/0 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/10 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/100 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/15 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/20 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/25 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/30 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/35 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/40 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/45 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/5 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/50 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/55 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/60 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/65 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/70 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/75 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/80 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/85 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/90 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-700\/95 {
  --tw-gradient-to: rgb(64 64 64 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(64 64 64 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #262626 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/0 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/10 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/100 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/15 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/20 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/25 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/30 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/35 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/40 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/45 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/5 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/50 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/55 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/60 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/65 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/70 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/75 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/80 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/85 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/90 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-800\/95 {
  --tw-gradient-to: rgb(38 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(38 38 38 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #171717 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/0 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/10 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/100 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/15 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/20 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/25 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/30 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/35 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/40 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/45 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/5 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/50 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/55 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/60 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/65 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/70 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/75 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/80 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/85 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/90 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-900\/95 {
  --tw-gradient-to: rgb(23 23 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(23 23 23 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0a0a0a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/0 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/10 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/100 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/15 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/20 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/25 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/30 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/35 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/40 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/45 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/5 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/50 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/55 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/60 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/65 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/70 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/75 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/80 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/85 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/90 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-neutral-950\/95 {
  --tw-gradient-to: rgb(10 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(10 10 10 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ffedd5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/0 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/10 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/100 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/15 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/20 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/25 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/30 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/35 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/40 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/45 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/5 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/50 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/55 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/60 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/65 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/70 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/75 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/80 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/85 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/90 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-100\/95 {
  --tw-gradient-to: rgb(255 237 213 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 237 213 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fed7aa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/0 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/10 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/100 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/15 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/20 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/25 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/30 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/35 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/40 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/45 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/5 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/50 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/55 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/60 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/65 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/70 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/75 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/80 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/85 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/90 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-200\/95 {
  --tw-gradient-to: rgb(254 215 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 215 170 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fdba74 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/0 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/10 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/100 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/15 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/20 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/25 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/30 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/35 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/40 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/45 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/5 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/50 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/55 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/60 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/65 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/70 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/75 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/80 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/85 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/90 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-300\/95 {
  --tw-gradient-to: rgb(253 186 116 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 186 116 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fb923c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/0 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/10 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/100 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/15 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/20 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/25 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/30 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/35 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/40 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/45 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/5 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/50 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/55 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/60 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/65 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/70 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/75 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/80 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/85 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/90 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-400\/95 {
  --tw-gradient-to: rgb(251 146 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 146 60 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fff7ed var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/0 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/10 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/100 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/15 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/20 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/25 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/30 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/35 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/40 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/45 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/5 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/50 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/55 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/60 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/65 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/70 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/75 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/80 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/85 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/90 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-50\/95 {
  --tw-gradient-to: rgb(255 247 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 247 237 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f97316 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/0 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/10 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/100 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/15 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/20 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/25 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/30 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/35 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/40 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/45 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/5 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/50 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/55 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/60 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/65 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/70 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/75 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/80 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/85 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/90 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-500\/95 {
  --tw-gradient-to: rgb(249 115 22 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 115 22 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ea580c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/0 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/10 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/100 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/15 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/20 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/25 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/30 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/35 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/40 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/45 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/5 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/50 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/55 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/60 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/65 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/70 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/75 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/80 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/85 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/90 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-600\/95 {
  --tw-gradient-to: rgb(234 88 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 88 12 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #c2410c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/0 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/10 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/100 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/15 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/20 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/25 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/30 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/35 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/40 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/45 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/5 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/50 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/55 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/60 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/65 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/70 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/75 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/80 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/85 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/90 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-700\/95 {
  --tw-gradient-to: rgb(194 65 12 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(194 65 12 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #9a3412 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/0 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/10 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/100 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/15 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/20 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/25 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/30 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/35 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/40 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/45 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/5 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/50 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/55 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/60 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/65 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/70 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/75 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/80 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/85 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/90 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-800\/95 {
  --tw-gradient-to: rgb(154 52 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(154 52 18 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #7c2d12 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/0 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/10 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/100 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/15 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/20 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/25 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/30 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/35 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/40 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/45 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/5 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/50 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/55 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/60 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/65 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/70 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/75 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/80 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/85 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/90 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-900\/95 {
  --tw-gradient-to: rgb(124 45 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 45 18 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #431407 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/0 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/10 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/100 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/15 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/20 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/25 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/30 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/35 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/40 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/45 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/5 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/50 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/55 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/60 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/65 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/70 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/75 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/80 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/85 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/90 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-orange-950\/95 {
  --tw-gradient-to: rgb(67 20 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(67 20 7 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fce7f3 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/0 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/10 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/100 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/15 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/20 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/25 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/30 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/35 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/40 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/45 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/5 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/50 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/55 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/60 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/65 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/70 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/75 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/80 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/85 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/90 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-100\/95 {
  --tw-gradient-to: rgb(252 231 243 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 231 243 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fbcfe8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/0 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/10 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/100 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/15 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/20 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/25 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/30 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/35 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/40 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/45 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/5 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/50 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/55 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/60 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/65 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/70 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/75 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/80 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/85 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/90 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-200\/95 {
  --tw-gradient-to: rgb(251 207 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 207 232 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f9a8d4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/0 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/10 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/100 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/15 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/20 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/25 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/30 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/35 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/40 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/45 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/5 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/50 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/55 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/60 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/65 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/70 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/75 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/80 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/85 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/90 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-300\/95 {
  --tw-gradient-to: rgb(249 168 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(249 168 212 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f472b6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/0 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/10 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/100 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/15 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/20 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/25 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/30 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/35 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/40 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/45 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/5 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/50 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/55 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/60 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/65 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/70 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/75 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/80 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/85 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/90 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-400\/95 {
  --tw-gradient-to: rgb(244 114 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 114 182 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fdf2f8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/0 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/10 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/100 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/15 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/20 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/25 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/30 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/35 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/40 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/45 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/5 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/50 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/55 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/60 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/65 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/70 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/75 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/80 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/85 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/90 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-50\/95 {
  --tw-gradient-to: rgb(253 242 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 242 248 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ec4899 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/0 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/10 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/100 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/15 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/20 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/25 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/30 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/35 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/40 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/45 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/5 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/50 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/55 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/60 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/65 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/70 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/75 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/80 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/85 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/90 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-500\/95 {
  --tw-gradient-to: rgb(236 72 153 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(236 72 153 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #db2777 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/0 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/10 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/100 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/15 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/20 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/25 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/30 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/35 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/40 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/45 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/5 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/50 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/55 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/60 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/65 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/70 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/75 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/80 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/85 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/90 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-600\/95 {
  --tw-gradient-to: rgb(219 39 119 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(219 39 119 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #be185d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/0 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/10 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/100 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/15 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/20 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/25 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/30 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/35 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/40 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/45 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/5 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/50 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/55 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/60 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/65 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/70 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/75 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/80 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/85 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/90 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-700\/95 {
  --tw-gradient-to: rgb(190 24 93 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 24 93 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #9d174d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/0 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/10 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/100 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/15 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/20 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/25 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/30 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/35 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/40 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/45 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/5 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/50 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/55 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/60 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/65 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/70 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/75 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/80 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/85 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/90 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-800\/95 {
  --tw-gradient-to: rgb(157 23 77 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(157 23 77 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #831843 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/0 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/10 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/100 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/15 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/20 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/25 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/30 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/35 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/40 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/45 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/5 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/50 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/55 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/60 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/65 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/70 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/75 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/80 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/85 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/90 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-900\/95 {
  --tw-gradient-to: rgb(131 24 67 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(131 24 67 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #500724 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/0 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/10 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/100 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/15 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/20 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/25 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/30 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/35 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/40 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/45 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/5 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/50 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/55 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/60 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/65 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/70 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/75 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/80 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/85 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/90 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-pink-950\/95 {
  --tw-gradient-to: rgb(80 7 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(80 7 36 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f3e8ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/0 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/10 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/100 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/15 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/20 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/25 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/30 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/35 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/40 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/45 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/5 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/50 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/55 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/60 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/65 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/70 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/75 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/80 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/85 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/90 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-100\/95 {
  --tw-gradient-to: rgb(243 232 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(243 232 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e9d5ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/0 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/10 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/100 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/15 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/20 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/25 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/30 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/35 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/40 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/45 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/5 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/50 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/55 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/60 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/65 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/70 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/75 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/80 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/85 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/90 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-200\/95 {
  --tw-gradient-to: rgb(233 213 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(233 213 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d8b4fe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/0 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/10 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/100 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/15 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/20 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/25 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/30 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/35 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/40 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/45 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/5 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/50 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/55 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/60 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/65 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/70 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/75 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/80 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/85 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/90 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-300\/95 {
  --tw-gradient-to: rgb(216 180 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(216 180 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #c084fc var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/0 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/10 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/100 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/15 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/20 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/25 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/30 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/35 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/40 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/45 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/5 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/50 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/55 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/60 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/65 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/70 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/75 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/80 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/85 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/90 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-400\/95 {
  --tw-gradient-to: rgb(192 132 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(192 132 252 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #faf5ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/0 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/10 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/100 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/15 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/20 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/25 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/30 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/35 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/40 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/45 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/5 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/50 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/55 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/60 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/65 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/70 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/75 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/80 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/85 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/90 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-50\/95 {
  --tw-gradient-to: rgb(250 245 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 245 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a855f7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/0 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/10 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/100 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/15 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/20 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/25 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/30 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/35 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/40 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/45 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/5 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/50 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/55 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/60 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/65 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/70 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/75 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/80 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/85 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/90 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-500\/95 {
  --tw-gradient-to: rgb(168 85 247 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 85 247 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #9333ea var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/0 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/10 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/100 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/15 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/20 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/25 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/30 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/35 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/40 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/45 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/5 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/50 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/55 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/60 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/65 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/70 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/75 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/80 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/85 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/90 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-600\/95 {
  --tw-gradient-to: rgb(147 51 234 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(147 51 234 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #7e22ce var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/0 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/10 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/100 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/15 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/20 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/25 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/30 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/35 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/40 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/45 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/5 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/50 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/55 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/60 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/65 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/70 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/75 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/80 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/85 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/90 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-700\/95 {
  --tw-gradient-to: rgb(126 34 206 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(126 34 206 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #6b21a8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/0 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/10 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/100 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/15 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/20 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/25 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/30 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/35 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/40 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/45 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/5 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/50 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/55 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/60 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/65 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/70 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/75 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/80 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/85 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/90 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-800\/95 {
  --tw-gradient-to: rgb(107 33 168 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(107 33 168 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #581c87 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/0 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/10 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/100 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/15 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/20 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/25 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/30 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/35 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/40 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/45 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/5 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/50 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/55 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/60 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/65 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/70 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/75 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/80 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/85 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/90 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-900\/95 {
  --tw-gradient-to: rgb(88 28 135 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(88 28 135 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #3b0764 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/0 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/10 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/100 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/15 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/20 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/25 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/30 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/35 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/40 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/45 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/5 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/50 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/55 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/60 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/65 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/70 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/75 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/80 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/85 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/90 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-purple-950\/95 {
  --tw-gradient-to: rgb(59 7 100 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(59 7 100 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fee2e2 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/0 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/10 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/100 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/15 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/20 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/25 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/30 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/35 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/40 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/45 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/5 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/50 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/55 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/60 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/65 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/70 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/75 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/80 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/85 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/90 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-100\/95 {
  --tw-gradient-to: rgb(254 226 226 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 226 226 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fecaca var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/0 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/10 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/100 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/15 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/20 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/25 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/30 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/35 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/40 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/45 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/5 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/50 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/55 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/60 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/65 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/70 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/75 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/80 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/85 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/90 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-200\/95 {
  --tw-gradient-to: rgb(254 202 202 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 202 202 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fca5a5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/0 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/10 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/100 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/15 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/20 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/25 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/30 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/35 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/40 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/45 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/5 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/50 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/55 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/60 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/65 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/70 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/75 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/80 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/85 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/90 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-300\/95 {
  --tw-gradient-to: rgb(252 165 165 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(252 165 165 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f87171 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/0 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/10 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/100 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/15 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/20 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/25 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/30 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/35 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/40 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/45 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/5 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/50 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/55 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/60 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/65 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/70 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/75 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/80 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/85 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/90 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-400\/95 {
  --tw-gradient-to: rgb(248 113 113 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 113 113 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fef2f2 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/0 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/10 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/100 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/15 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/20 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/25 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/30 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/35 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/40 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/45 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/5 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/50 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/55 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/60 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/65 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/70 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/75 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/80 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/85 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/90 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-50\/95 {
  --tw-gradient-to: rgb(254 242 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 242 242 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ef4444 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/0 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/10 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/100 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/15 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/20 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/25 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/30 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/35 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/40 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/45 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/5 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/50 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/55 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/60 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/65 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/70 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/75 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/80 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/85 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/90 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-500\/95 {
  --tw-gradient-to: rgb(239 68 68 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(239 68 68 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #dc2626 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/0 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/10 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/100 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/15 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/20 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/25 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/30 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/35 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/40 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/45 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/5 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/50 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/55 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/60 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/65 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/70 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/75 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/80 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/85 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/90 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-600\/95 {
  --tw-gradient-to: rgb(220 38 38 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(220 38 38 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #b91c1c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/0 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/10 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/100 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/15 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/20 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/25 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/30 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/35 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/40 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/45 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/5 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/50 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/55 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/60 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/65 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/70 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/75 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/80 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/85 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/90 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-700\/95 {
  --tw-gradient-to: rgb(185 28 28 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(185 28 28 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #991b1b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/0 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/10 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/100 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/15 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/20 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/25 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/30 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/35 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/40 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/45 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/5 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/50 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/55 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/60 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/65 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/70 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/75 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/80 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/85 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/90 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-800\/95 {
  --tw-gradient-to: rgb(153 27 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 27 27 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #7f1d1d var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/0 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/10 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/100 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/15 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/20 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/25 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/30 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/35 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/40 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/45 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/5 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/50 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/55 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/60 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/65 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/70 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/75 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/80 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/85 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/90 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-900\/95 {
  --tw-gradient-to: rgb(127 29 29 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(127 29 29 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #450a0a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/0 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/10 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/100 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/15 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/20 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/25 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/30 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/35 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/40 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/45 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/5 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/50 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/55 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/60 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/65 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/70 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/75 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/80 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/85 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/90 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-red-950\/95 {
  --tw-gradient-to: rgb(69 10 10 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(69 10 10 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ffe4e6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/0 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/10 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/100 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/15 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/20 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/25 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/30 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/35 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/40 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/45 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/5 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/50 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/55 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/60 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/65 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/70 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/75 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/80 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/85 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/90 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-100\/95 {
  --tw-gradient-to: rgb(255 228 230 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 228 230 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fecdd3 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/0 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/10 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/100 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/15 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/20 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/25 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/30 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/35 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/40 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/45 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/5 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/50 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/55 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/60 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/65 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/70 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/75 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/80 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/85 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/90 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-200\/95 {
  --tw-gradient-to: rgb(254 205 211 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 205 211 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fda4af var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/0 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/10 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/100 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/15 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/20 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/25 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/30 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/35 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/40 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/45 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/5 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/50 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/55 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/60 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/65 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/70 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/75 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/80 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/85 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/90 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-300\/95 {
  --tw-gradient-to: rgb(253 164 175 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 164 175 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fb7185 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/0 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/10 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/100 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/15 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/20 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/25 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/30 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/35 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/40 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/45 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/5 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/50 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/55 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/60 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/65 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/70 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/75 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/80 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/85 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/90 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-400\/95 {
  --tw-gradient-to: rgb(251 113 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(251 113 133 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fff1f2 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/0 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/10 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/100 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/15 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/20 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/25 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/30 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/35 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/40 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/45 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/5 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/50 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/55 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/60 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/65 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/70 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/75 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/80 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/85 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/90 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-50\/95 {
  --tw-gradient-to: rgb(255 241 242 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 241 242 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f43f5e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/0 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/10 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/100 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/15 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/20 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/25 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/30 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/35 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/40 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/45 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/5 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/50 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/55 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/60 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/65 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/70 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/75 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/80 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/85 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/90 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-500\/95 {
  --tw-gradient-to: rgb(244 63 94 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 63 94 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e11d48 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/0 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/10 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/100 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/15 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/20 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/25 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/30 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/35 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/40 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/45 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/5 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/50 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/55 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/60 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/65 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/70 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/75 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/80 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/85 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/90 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-600\/95 {
  --tw-gradient-to: rgb(225 29 72 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(225 29 72 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #be123c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/0 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/10 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/100 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/15 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/20 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/25 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/30 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/35 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/40 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/45 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/5 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/50 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/55 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/60 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/65 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/70 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/75 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/80 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/85 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/90 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-700\/95 {
  --tw-gradient-to: rgb(190 18 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(190 18 60 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #9f1239 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/0 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/10 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/100 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/15 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/20 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/25 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/30 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/35 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/40 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/45 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/5 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/50 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/55 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/60 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/65 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/70 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/75 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/80 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/85 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/90 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-800\/95 {
  --tw-gradient-to: rgb(159 18 57 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(159 18 57 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #881337 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/0 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/10 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/100 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/15 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/20 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/25 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/30 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/35 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/40 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/45 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/5 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/50 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/55 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/60 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/65 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/70 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/75 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/80 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/85 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/90 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-900\/95 {
  --tw-gradient-to: rgb(136 19 55 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(136 19 55 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4c0519 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/0 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/10 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/100 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/15 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/20 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/25 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/30 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/35 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/40 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/45 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/5 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/50 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/55 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/60 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/65 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/70 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/75 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/80 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/85 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/90 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-rose-950\/95 {
  --tw-gradient-to: rgb(76 5 25 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 5 25 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e0f2fe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/0 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/10 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/100 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/15 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/20 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/25 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/30 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/35 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/40 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/45 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/5 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/50 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/55 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/60 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/65 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/70 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/75 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/80 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/85 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/90 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-100\/95 {
  --tw-gradient-to: rgb(224 242 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(224 242 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #bae6fd var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/0 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/10 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/100 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/15 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/20 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/25 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/30 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/35 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/40 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/45 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/5 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/50 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/55 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/60 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/65 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/70 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/75 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/80 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/85 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/90 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-200\/95 {
  --tw-gradient-to: rgb(186 230 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(186 230 253 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #7dd3fc var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/0 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/10 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/100 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/15 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/20 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/25 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/30 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/35 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/40 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/45 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/5 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/50 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/55 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/60 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/65 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/70 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/75 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/80 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/85 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/90 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-300\/95 {
  --tw-gradient-to: rgb(125 211 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(125 211 252 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #38bdf8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/0 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/10 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/100 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/15 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/20 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/25 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/30 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/35 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/40 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/45 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/5 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/50 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/55 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/60 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/65 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/70 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/75 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/80 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/85 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/90 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-400\/95 {
  --tw-gradient-to: rgb(56 189 248 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(56 189 248 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f0f9ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/0 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/10 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/100 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/15 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/20 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/25 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/30 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/35 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/40 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/45 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/5 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/50 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/55 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/60 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/65 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/70 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/75 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/80 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/85 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/90 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-50\/95 {
  --tw-gradient-to: rgb(240 249 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 249 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0ea5e9 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/0 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/10 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/100 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/15 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/20 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/25 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/30 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/35 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/40 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/45 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/5 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/50 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/55 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/60 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/65 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/70 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/75 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/80 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/85 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/90 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-500\/95 {
  --tw-gradient-to: rgb(14 165 233 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(14 165 233 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0284c7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/0 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/10 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/100 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/15 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/20 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/25 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/30 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/35 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/40 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/45 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/5 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/50 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/55 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/60 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/65 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/70 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/75 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/80 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/85 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/90 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-600\/95 {
  --tw-gradient-to: rgb(2 132 199 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 132 199 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0369a1 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/0 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/10 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/100 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/15 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/20 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/25 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/30 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/35 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/40 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/45 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/5 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/50 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/55 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/60 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/65 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/70 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/75 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/80 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/85 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/90 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-700\/95 {
  --tw-gradient-to: rgb(3 105 161 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(3 105 161 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #075985 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/0 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/10 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/100 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/15 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/20 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/25 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/30 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/35 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/40 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/45 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/5 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/50 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/55 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/60 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/65 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/70 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/75 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/80 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/85 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/90 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-800\/95 {
  --tw-gradient-to: rgb(7 89 133 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(7 89 133 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0c4a6e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/0 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/10 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/100 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/15 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/20 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/25 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/30 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/35 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/40 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/45 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/5 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/50 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/55 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/60 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/65 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/70 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/75 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/80 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/85 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/90 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-900\/95 {
  --tw-gradient-to: rgb(12 74 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 74 110 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #082f49 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/0 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/10 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/100 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/15 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/20 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/25 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/30 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/35 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/40 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/45 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/5 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/50 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/55 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/60 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/65 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/70 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/75 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/80 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/85 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/90 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-sky-950\/95 {
  --tw-gradient-to: rgb(8 47 73 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(8 47 73 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f1f5f9 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/0 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/10 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/100 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/15 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/20 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/25 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/30 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/35 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/40 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/45 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/5 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/50 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/55 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/60 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/65 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/70 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/75 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/80 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/85 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/90 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-100\/95 {
  --tw-gradient-to: rgb(241 245 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(241 245 249 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e2e8f0 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/0 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/10 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/100 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/15 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/20 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/25 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/30 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/35 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/40 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/45 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/5 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/50 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/55 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/60 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/65 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/70 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/75 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/80 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/85 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/90 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-200\/95 {
  --tw-gradient-to: rgb(226 232 240 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(226 232 240 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #cbd5e1 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/0 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/10 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/100 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/15 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/20 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/25 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/30 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/35 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/40 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/45 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/5 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/50 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/55 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/60 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/65 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/70 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/75 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/80 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/85 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/90 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-300\/95 {
  --tw-gradient-to: rgb(203 213 225 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(203 213 225 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #94a3b8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/0 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/10 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/100 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/15 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/20 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/25 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/30 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/35 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/40 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/45 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/5 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/50 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/55 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/60 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/65 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/70 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/75 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/80 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/85 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/90 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-400\/95 {
  --tw-gradient-to: rgb(148 163 184 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(148 163 184 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f8fafc var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/0 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/10 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/100 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/15 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/20 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/25 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/30 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/35 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/40 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/45 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/5 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/50 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/55 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/60 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/65 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/70 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/75 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/80 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/85 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/90 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-50\/95 {
  --tw-gradient-to: rgb(248 250 252 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(248 250 252 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #64748b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/0 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/10 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/100 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/15 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/20 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/25 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/30 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/35 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/40 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/45 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/5 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/50 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/55 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/60 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/65 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/70 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/75 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/80 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/85 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/90 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-500\/95 {
  --tw-gradient-to: rgb(100 116 139 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(100 116 139 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #475569 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/0 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/10 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/100 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/15 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/20 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/25 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/30 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/35 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/40 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/45 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/5 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/50 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/55 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/60 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/65 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/70 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/75 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/80 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/85 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/90 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-600\/95 {
  --tw-gradient-to: rgb(71 85 105 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(71 85 105 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #334155 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/0 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/10 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/100 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/15 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/20 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/25 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/30 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/35 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/40 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/45 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/5 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/50 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/55 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/60 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/65 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/70 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/75 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/80 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/85 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/90 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-700\/95 {
  --tw-gradient-to: rgb(51 65 85 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(51 65 85 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1e293b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/0 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/10 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/100 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/15 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/20 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/25 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/30 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/35 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/40 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/45 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/5 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/50 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/55 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/60 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/65 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/70 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/75 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/80 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/85 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/90 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-800\/95 {
  --tw-gradient-to: rgb(30 41 59 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(30 41 59 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0f172a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/0 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/10 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/100 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/15 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/20 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/25 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/30 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/35 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/40 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/45 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/5 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/50 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/55 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/60 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/65 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/70 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/75 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/80 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/85 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/90 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-900\/95 {
  --tw-gradient-to: rgb(15 23 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 23 42 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #020617 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/0 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/10 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/100 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/15 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/20 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/25 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/30 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/35 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/40 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/45 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/5 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/50 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/55 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/60 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/65 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/70 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/75 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/80 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/85 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/90 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-slate-950\/95 {
  --tw-gradient-to: rgb(2 6 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(2 6 23 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f5f5f4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/0 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/10 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/100 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/15 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/20 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/25 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/30 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/35 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/40 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/45 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/5 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/50 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/55 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/60 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/65 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/70 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/75 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/80 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/85 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/90 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-100\/95 {
  --tw-gradient-to: rgb(245 245 244 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 245 244 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e7e5e4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/0 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/10 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/100 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/15 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/20 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/25 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/30 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/35 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/40 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/45 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/5 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/50 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/55 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/60 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/65 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/70 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/75 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/80 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/85 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/90 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-200\/95 {
  --tw-gradient-to: rgb(231 229 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(231 229 228 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d6d3d1 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/0 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/10 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/100 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/15 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/20 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/25 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/30 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/35 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/40 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/45 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/5 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/50 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/55 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/60 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/65 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/70 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/75 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/80 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/85 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/90 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-300\/95 {
  --tw-gradient-to: rgb(214 211 209 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(214 211 209 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a8a29e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/0 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/10 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/100 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/15 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/20 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/25 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/30 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/35 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/40 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/45 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/5 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/50 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/55 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/60 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/65 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/70 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/75 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/80 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/85 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/90 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-400\/95 {
  --tw-gradient-to: rgb(168 162 158 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(168 162 158 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fafaf9 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/0 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/10 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/100 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/15 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/20 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/25 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/30 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/35 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/40 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/45 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/5 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/50 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/55 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/60 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/65 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/70 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/75 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/80 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/85 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/90 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-50\/95 {
  --tw-gradient-to: rgb(250 250 249 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 249 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #78716c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/0 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/10 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/100 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/15 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/20 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/25 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/30 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/35 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/40 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/45 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/5 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/50 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/55 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/60 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/65 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/70 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/75 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/80 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/85 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/90 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-500\/95 {
  --tw-gradient-to: rgb(120 113 108 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(120 113 108 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #57534e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/0 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/10 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/100 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/15 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/20 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/25 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/30 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/35 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/40 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/45 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/5 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/50 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/55 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/60 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/65 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/70 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/75 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/80 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/85 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/90 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-600\/95 {
  --tw-gradient-to: rgb(87 83 78 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(87 83 78 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #44403c var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/0 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/10 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/100 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/15 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/20 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/25 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/30 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/35 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/40 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/45 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/5 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/50 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/55 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/60 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/65 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/70 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/75 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/80 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/85 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/90 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-700\/95 {
  --tw-gradient-to: rgb(68 64 60 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(68 64 60 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #292524 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/0 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/10 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/100 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/15 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/20 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/25 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/30 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/35 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/40 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/45 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/5 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/50 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/55 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/60 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/65 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/70 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/75 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/80 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/85 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/90 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-800\/95 {
  --tw-gradient-to: rgb(41 37 36 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(41 37 36 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #1c1917 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/0 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/10 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/100 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/15 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/20 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/25 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/30 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/35 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/40 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/45 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/5 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/50 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/55 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/60 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/65 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/70 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/75 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/80 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/85 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/90 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-900\/95 {
  --tw-gradient-to: rgb(28 25 23 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(28 25 23 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0c0a09 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/0 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/10 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/100 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/15 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/20 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/25 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/30 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/35 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/40 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/45 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/5 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/50 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/55 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/60 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/65 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/70 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/75 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/80 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/85 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/90 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-stone-950\/95 {
  --tw-gradient-to: rgb(12 10 9 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(12 10 9 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ccfbf1 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/0 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/10 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/100 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/15 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/20 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/25 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/30 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/35 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/40 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/45 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/5 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/50 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/55 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/60 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/65 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/70 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/75 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/80 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/85 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/90 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-100\/95 {
  --tw-gradient-to: rgb(204 251 241 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(204 251 241 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #99f6e4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/0 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/10 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/100 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/15 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/20 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/25 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/30 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/35 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/40 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/45 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/5 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/50 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/55 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/60 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/65 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/70 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/75 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/80 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/85 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/90 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-200\/95 {
  --tw-gradient-to: rgb(153 246 228 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(153 246 228 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #5eead4 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/0 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/10 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/100 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/15 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/20 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/25 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/30 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/35 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/40 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/45 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/5 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/50 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/55 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/60 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/65 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/70 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/75 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/80 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/85 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/90 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-300\/95 {
  --tw-gradient-to: rgb(94 234 212 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(94 234 212 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #2dd4bf var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/0 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/10 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/100 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/15 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/20 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/25 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/30 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/35 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/40 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/45 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/5 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/50 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/55 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/60 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/65 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/70 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/75 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/80 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/85 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/90 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-400\/95 {
  --tw-gradient-to: rgb(45 212 191 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(45 212 191 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f0fdfa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/0 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/10 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/100 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/15 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/20 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/25 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/30 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/35 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/40 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/45 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/5 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/50 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/55 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/60 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/65 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/70 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/75 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/80 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/85 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/90 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-50\/95 {
  --tw-gradient-to: rgb(240 253 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(240 253 250 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #14b8a6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/0 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/10 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/100 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/15 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/20 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/25 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/30 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/35 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/40 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/45 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/5 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/50 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/55 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/60 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/65 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/70 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/75 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/80 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/85 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/90 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-500\/95 {
  --tw-gradient-to: rgb(20 184 166 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(20 184 166 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0d9488 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/0 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/10 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/100 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/15 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/20 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/25 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/30 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/35 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/40 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/45 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/5 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/50 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/55 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/60 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/65 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/70 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/75 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/80 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/85 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/90 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-600\/95 {
  --tw-gradient-to: rgb(13 148 136 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(13 148 136 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #0f766e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/0 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/10 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/100 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/15 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/20 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/25 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/30 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/35 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/40 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/45 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/5 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/50 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/55 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/60 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/65 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/70 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/75 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/80 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/85 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/90 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-700\/95 {
  --tw-gradient-to: rgb(15 118 110 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(15 118 110 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #115e59 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/0 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/10 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/100 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/15 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/20 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/25 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/30 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/35 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/40 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/45 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/5 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/50 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/55 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/60 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/65 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/70 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/75 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/80 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/85 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/90 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-800\/95 {
  --tw-gradient-to: rgb(17 94 89 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(17 94 89 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #134e4a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/0 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/10 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/100 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/15 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/20 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/25 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/30 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/35 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/40 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/45 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/5 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/50 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/55 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/60 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/65 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/70 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/75 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/80 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/85 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/90 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-900\/95 {
  --tw-gradient-to: rgb(19 78 74 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(19 78 74 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #042f2e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/0 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/10 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/100 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/15 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/20 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/25 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/30 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/35 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/40 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/45 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/5 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/50 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/55 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/60 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/65 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/70 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/75 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/80 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/85 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/90 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-teal-950\/95 {
  --tw-gradient-to: rgb(4 47 46 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(4 47 46 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/0 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/10 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/100 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/15 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/20 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/25 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/30 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/35 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/40 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/45 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/5 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/50 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/55 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/60 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/65 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/70 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/75 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/80 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/85 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/90 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-transparent\/95 {
  --tw-gradient-to: rgb(0 0 0 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(0 0 0 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ede9fe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/0 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/10 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/100 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/15 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/20 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/25 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/30 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/35 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/40 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/45 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/5 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/50 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/55 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/60 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/65 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/70 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/75 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/80 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/85 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/90 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-100\/95 {
  --tw-gradient-to: rgb(237 233 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(237 233 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ddd6fe var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/0 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/10 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/100 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/15 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/20 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/25 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/30 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/35 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/40 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/45 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/5 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/50 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/55 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/60 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/65 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/70 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/75 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/80 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/85 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/90 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-200\/95 {
  --tw-gradient-to: rgb(221 214 254 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(221 214 254 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #c4b5fd var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/0 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/10 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/100 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/15 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/20 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/25 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/30 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/35 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/40 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/45 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/5 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/50 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/55 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/60 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/65 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/70 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/75 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/80 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/85 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/90 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-300\/95 {
  --tw-gradient-to: rgb(196 181 253 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(196 181 253 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a78bfa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/0 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/10 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/100 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/15 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/20 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/25 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/30 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/35 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/40 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/45 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/5 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/50 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/55 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/60 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/65 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/70 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/75 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/80 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/85 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/90 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-400\/95 {
  --tw-gradient-to: rgb(167 139 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(167 139 250 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f5f3ff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/0 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/10 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/100 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/15 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/20 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/25 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/30 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/35 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/40 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/45 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/5 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/50 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/55 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/60 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/65 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/70 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/75 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/80 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/85 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/90 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-50\/95 {
  --tw-gradient-to: rgb(245 243 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(245 243 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #8b5cf6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/0 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/10 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/100 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/15 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/20 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/25 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/30 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/35 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/40 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/45 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/5 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/50 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/55 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/60 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/65 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/70 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/75 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/80 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/85 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/90 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-500\/95 {
  --tw-gradient-to: rgb(139 92 246 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(139 92 246 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #7c3aed var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/0 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/10 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/100 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/15 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/20 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/25 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/30 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/35 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/40 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/45 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/5 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/50 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/55 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/60 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/65 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/70 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/75 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/80 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/85 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/90 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-600\/95 {
  --tw-gradient-to: rgb(124 58 237 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(124 58 237 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #6d28d9 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/0 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/10 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/100 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/15 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/20 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/25 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/30 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/35 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/40 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/45 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/5 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/50 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/55 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/60 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/65 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/70 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/75 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/80 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/85 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/90 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-700\/95 {
  --tw-gradient-to: rgb(109 40 217 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(109 40 217 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #5b21b6 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/0 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/10 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/100 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/15 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/20 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/25 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/30 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/35 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/40 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/45 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/5 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/50 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/55 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/60 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/65 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/70 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/75 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/80 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/85 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/90 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-800\/95 {
  --tw-gradient-to: rgb(91 33 182 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(91 33 182 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #4c1d95 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/0 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/10 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/100 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/15 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/20 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/25 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/30 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/35 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/40 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/45 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/5 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/50 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/55 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/60 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/65 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/70 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/75 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/80 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/85 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/90 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-900\/95 {
  --tw-gradient-to: rgb(76 29 149 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(76 29 149 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #2e1065 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/0 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/10 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/100 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/15 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/20 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/25 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/30 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/35 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/40 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/45 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/5 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/50 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/55 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/60 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/65 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/70 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/75 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/80 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/85 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/90 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-violet-950\/95 {
  --tw-gradient-to: rgb(46 16 101 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(46 16 101 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fff var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/0 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/10 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/100 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/15 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/20 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/25 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/30 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/35 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/40 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/45 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/5 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/50 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/55 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/60 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/65 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/70 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/75 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/80 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/85 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/90 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-white\/95 {
  --tw-gradient-to: rgb(255 255 255 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(255 255 255 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fef9c3 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/0 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/10 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/100 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/15 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/20 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/25 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/30 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/35 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/40 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/45 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/5 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/50 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/55 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/60 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/65 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/70 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/75 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/80 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/85 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/90 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-100\/95 {
  --tw-gradient-to: rgb(254 249 195 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 249 195 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fef08a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/0 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/10 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/100 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/15 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/20 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/25 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/30 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/35 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/40 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/45 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/5 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/50 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/55 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/60 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/65 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/70 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/75 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/80 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/85 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/90 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-200\/95 {
  --tw-gradient-to: rgb(254 240 138 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 240 138 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fde047 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/0 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/10 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/100 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/15 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/20 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/25 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/30 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/35 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/40 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/45 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/5 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/50 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/55 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/60 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/65 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/70 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/75 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/80 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/85 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/90 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-300\/95 {
  --tw-gradient-to: rgb(253 224 71 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(253 224 71 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #facc15 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/0 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/10 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/100 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/15 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/20 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/25 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/30 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/35 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/40 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/45 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/5 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/50 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/55 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/60 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/65 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/70 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/75 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/80 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/85 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/90 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-400\/95 {
  --tw-gradient-to: rgb(250 204 21 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 204 21 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fefce8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/0 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/10 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/100 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/15 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/20 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/25 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/30 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/35 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/40 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/45 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/5 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/50 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/55 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/60 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/65 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/70 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/75 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/80 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/85 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/90 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-50\/95 {
  --tw-gradient-to: rgb(254 252 232 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(254 252 232 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #eab308 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/0 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/10 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/100 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/15 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/20 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/25 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/30 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/35 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/40 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/45 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/5 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/50 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/55 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/60 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/65 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/70 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/75 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/80 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/85 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/90 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-500\/95 {
  --tw-gradient-to: rgb(234 179 8 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(234 179 8 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #ca8a04 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/0 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/10 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/100 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/15 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/20 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/25 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/30 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/35 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/40 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/45 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/5 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/50 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/55 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/60 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/65 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/70 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/75 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/80 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/85 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/90 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-600\/95 {
  --tw-gradient-to: rgb(202 138 4 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(202 138 4 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a16207 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/0 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/10 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/100 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/15 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/20 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/25 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/30 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/35 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/40 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/45 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/5 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/50 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/55 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/60 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/65 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/70 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/75 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/80 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/85 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/90 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-700\/95 {
  --tw-gradient-to: rgb(161 98 7 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 98 7 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #854d0e var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/0 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/10 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/100 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/15 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/20 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/25 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/30 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/35 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/40 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/45 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/5 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/50 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/55 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/60 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/65 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/70 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/75 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/80 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/85 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/90 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-800\/95 {
  --tw-gradient-to: rgb(133 77 14 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(133 77 14 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #713f12 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/0 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/10 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/100 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/15 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/20 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/25 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/30 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/35 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/40 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/45 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/5 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/50 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/55 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/60 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/65 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/70 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/75 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/80 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/85 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/90 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-900\/95 {
  --tw-gradient-to: rgb(113 63 18 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 63 18 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #422006 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/0 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/10 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/100 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/15 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/20 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/25 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/30 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/35 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/40 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/45 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/5 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/50 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/55 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/60 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/65 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/70 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/75 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/80 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/85 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/90 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-yellow-950\/95 {
  --tw-gradient-to: rgb(66 32 6 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(66 32 6 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #f4f4f5 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/0 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/10 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/100 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/15 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/20 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/25 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/30 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/35 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/40 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/45 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/5 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/50 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/55 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/60 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/65 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/70 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/75 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/80 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/85 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/90 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-100\/95 {
  --tw-gradient-to: rgb(244 244 245 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(244 244 245 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #e4e4e7 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/0 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/10 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/100 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/15 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/20 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/25 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/30 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/35 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/40 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/45 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/5 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/50 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/55 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/60 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/65 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/70 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/75 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/80 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/85 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/90 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-200\/95 {
  --tw-gradient-to: rgb(228 228 231 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(228 228 231 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #d4d4d8 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/0 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/10 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/100 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/15 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/20 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/25 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/30 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/35 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/40 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/45 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/5 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/50 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/55 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/60 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/65 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/70 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/75 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/80 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/85 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/90 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-300\/95 {
  --tw-gradient-to: rgb(212 212 216 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(212 212 216 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #a1a1aa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/0 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/10 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/100 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/15 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/20 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/25 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/30 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/35 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/40 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/45 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/5 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/50 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/55 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/60 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/65 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/70 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/75 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/80 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/85 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/90 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-400\/95 {
  --tw-gradient-to: rgb(161 161 170 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(161 161 170 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #fafafa var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/0 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/10 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/100 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/15 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/20 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/25 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/30 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/35 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/40 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/45 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/5 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/50 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/55 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/60 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/65 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/70 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/75 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/80 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/85 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/90 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-50\/95 {
  --tw-gradient-to: rgb(250 250 250 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(250 250 250 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #71717a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/0 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/10 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/100 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/15 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/20 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/25 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/30 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/35 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/40 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/45 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/5 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/50 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/55 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/60 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/65 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/70 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/75 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/80 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/85 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/90 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-500\/95 {
  --tw-gradient-to: rgb(113 113 122 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(113 113 122 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #52525b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/0 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/10 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/100 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/15 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/20 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/25 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/30 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/35 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/40 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/45 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/5 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/50 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/55 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/60 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/65 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/70 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/75 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/80 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/85 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/90 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-600\/95 {
  --tw-gradient-to: rgb(82 82 91 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(82 82 91 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #3f3f46 var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/0 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/10 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/100 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/15 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/20 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/25 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/30 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/35 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/40 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/45 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/5 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/50 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/55 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/60 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/65 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/70 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/75 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/80 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/85 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/90 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-700\/95 {
  --tw-gradient-to: rgb(63 63 70 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(63 63 70 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #27272a var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/0 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/10 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/100 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/15 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/20 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/25 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/30 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/35 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/40 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/45 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/5 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/50 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/55 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/60 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/65 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/70 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/75 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/80 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/85 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/90 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-800\/95 {
  --tw-gradient-to: rgb(39 39 42 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(39 39 42 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #18181b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/0 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/10 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/100 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/15 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/20 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/25 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/30 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/35 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/40 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/45 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/5 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/50 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/55 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/60 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/65 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/70 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/75 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/80 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/85 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/90 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-900\/95 {
  --tw-gradient-to: rgb(24 24 27 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(24 24 27 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), #09090b var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/0 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/10 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/100 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 1) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/15 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.15) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/20 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.2) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/25 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.25) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/30 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.3) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/35 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.35) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/40 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.4) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/45 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.45) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/5 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.05) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/50 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.5) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/55 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.55) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/60 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.6) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/65 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.65) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/70 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.7) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/75 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.75) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/80 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.8) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/85 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.85) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/90 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.9) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-zinc-950\/95 {
  --tw-gradient-to: rgb(9 9 11 / 0)  var(--tw-gradient-to-position) !important;
  --tw-gradient-stops: var(--tw-gradient-from), rgb(9 9 11 / 0.95) var(--tw-gradient-via-position), var(--tw-gradient-to) !important;
}

.tw-via-0\% {
  --tw-gradient-via-position: 0% !important;
}

.tw-via-10\% {
  --tw-gradient-via-position: 10% !important;
}

.tw-via-100\% {
  --tw-gradient-via-position: 100% !important;
}

.tw-via-15\% {
  --tw-gradient-via-position: 15% !important;
}

.tw-via-20\% {
  --tw-gradient-via-position: 20% !important;
}

.tw-via-25\% {
  --tw-gradient-via-position: 25% !important;
}

.tw-via-30\% {
  --tw-gradient-via-position: 30% !important;
}

.tw-via-35\% {
  --tw-gradient-via-position: 35% !important;
}

.tw-via-40\% {
  --tw-gradient-via-position: 40% !important;
}

.tw-via-45\% {
  --tw-gradient-via-position: 45% !important;
}

.tw-via-5\% {
  --tw-gradient-via-position: 5% !important;
}

.tw-via-50\% {
  --tw-gradient-via-position: 50% !important;
}

.tw-via-55\% {
  --tw-gradient-via-position: 55% !important;
}

.tw-via-60\% {
  --tw-gradient-via-position: 60% !important;
}

.tw-via-65\% {
  --tw-gradient-via-position: 65% !important;
}

.tw-via-70\% {
  --tw-gradient-via-position: 70% !important;
}

.tw-via-75\% {
  --tw-gradient-via-position: 75% !important;
}

.tw-via-80\% {
  --tw-gradient-via-position: 80% !important;
}

.tw-via-85\% {
  --tw-gradient-via-position: 85% !important;
}

.tw-via-90\% {
  --tw-gradient-via-position: 90% !important;
}

.tw-via-95\% {
  --tw-gradient-via-position: 95% !important;
}

.tw-to-amber-100 {
  --tw-gradient-to: #fef3c7 var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/0 {
  --tw-gradient-to: rgb(254 243 199 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/10 {
  --tw-gradient-to: rgb(254 243 199 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/100 {
  --tw-gradient-to: rgb(254 243 199 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/15 {
  --tw-gradient-to: rgb(254 243 199 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/20 {
  --tw-gradient-to: rgb(254 243 199 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/25 {
  --tw-gradient-to: rgb(254 243 199 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/30 {
  --tw-gradient-to: rgb(254 243 199 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/35 {
  --tw-gradient-to: rgb(254 243 199 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/40 {
  --tw-gradient-to: rgb(254 243 199 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/45 {
  --tw-gradient-to: rgb(254 243 199 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/5 {
  --tw-gradient-to: rgb(254 243 199 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/50 {
  --tw-gradient-to: rgb(254 243 199 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/55 {
  --tw-gradient-to: rgb(254 243 199 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/60 {
  --tw-gradient-to: rgb(254 243 199 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/65 {
  --tw-gradient-to: rgb(254 243 199 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/70 {
  --tw-gradient-to: rgb(254 243 199 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/75 {
  --tw-gradient-to: rgb(254 243 199 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/80 {
  --tw-gradient-to: rgb(254 243 199 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/85 {
  --tw-gradient-to: rgb(254 243 199 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/90 {
  --tw-gradient-to: rgb(254 243 199 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-100\/95 {
  --tw-gradient-to: rgb(254 243 199 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200 {
  --tw-gradient-to: #fde68a var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/0 {
  --tw-gradient-to: rgb(253 230 138 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/10 {
  --tw-gradient-to: rgb(253 230 138 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/100 {
  --tw-gradient-to: rgb(253 230 138 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/15 {
  --tw-gradient-to: rgb(253 230 138 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/20 {
  --tw-gradient-to: rgb(253 230 138 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/25 {
  --tw-gradient-to: rgb(253 230 138 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/30 {
  --tw-gradient-to: rgb(253 230 138 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/35 {
  --tw-gradient-to: rgb(253 230 138 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/40 {
  --tw-gradient-to: rgb(253 230 138 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/45 {
  --tw-gradient-to: rgb(253 230 138 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/5 {
  --tw-gradient-to: rgb(253 230 138 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/50 {
  --tw-gradient-to: rgb(253 230 138 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/55 {
  --tw-gradient-to: rgb(253 230 138 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/60 {
  --tw-gradient-to: rgb(253 230 138 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/65 {
  --tw-gradient-to: rgb(253 230 138 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/70 {
  --tw-gradient-to: rgb(253 230 138 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/75 {
  --tw-gradient-to: rgb(253 230 138 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/80 {
  --tw-gradient-to: rgb(253 230 138 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/85 {
  --tw-gradient-to: rgb(253 230 138 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/90 {
  --tw-gradient-to: rgb(253 230 138 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-200\/95 {
  --tw-gradient-to: rgb(253 230 138 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300 {
  --tw-gradient-to: #fcd34d var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/0 {
  --tw-gradient-to: rgb(252 211 77 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/10 {
  --tw-gradient-to: rgb(252 211 77 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/100 {
  --tw-gradient-to: rgb(252 211 77 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/15 {
  --tw-gradient-to: rgb(252 211 77 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/20 {
  --tw-gradient-to: rgb(252 211 77 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/25 {
  --tw-gradient-to: rgb(252 211 77 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/30 {
  --tw-gradient-to: rgb(252 211 77 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/35 {
  --tw-gradient-to: rgb(252 211 77 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/40 {
  --tw-gradient-to: rgb(252 211 77 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/45 {
  --tw-gradient-to: rgb(252 211 77 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/5 {
  --tw-gradient-to: rgb(252 211 77 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/50 {
  --tw-gradient-to: rgb(252 211 77 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/55 {
  --tw-gradient-to: rgb(252 211 77 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/60 {
  --tw-gradient-to: rgb(252 211 77 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/65 {
  --tw-gradient-to: rgb(252 211 77 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/70 {
  --tw-gradient-to: rgb(252 211 77 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/75 {
  --tw-gradient-to: rgb(252 211 77 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/80 {
  --tw-gradient-to: rgb(252 211 77 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/85 {
  --tw-gradient-to: rgb(252 211 77 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/90 {
  --tw-gradient-to: rgb(252 211 77 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-300\/95 {
  --tw-gradient-to: rgb(252 211 77 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400 {
  --tw-gradient-to: #fbbf24 var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/0 {
  --tw-gradient-to: rgb(251 191 36 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/10 {
  --tw-gradient-to: rgb(251 191 36 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/100 {
  --tw-gradient-to: rgb(251 191 36 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/15 {
  --tw-gradient-to: rgb(251 191 36 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/20 {
  --tw-gradient-to: rgb(251 191 36 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/25 {
  --tw-gradient-to: rgb(251 191 36 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/30 {
  --tw-gradient-to: rgb(251 191 36 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/35 {
  --tw-gradient-to: rgb(251 191 36 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/40 {
  --tw-gradient-to: rgb(251 191 36 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/45 {
  --tw-gradient-to: rgb(251 191 36 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/5 {
  --tw-gradient-to: rgb(251 191 36 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/50 {
  --tw-gradient-to: rgb(251 191 36 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/55 {
  --tw-gradient-to: rgb(251 191 36 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/60 {
  --tw-gradient-to: rgb(251 191 36 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/65 {
  --tw-gradient-to: rgb(251 191 36 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/70 {
  --tw-gradient-to: rgb(251 191 36 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/75 {
  --tw-gradient-to: rgb(251 191 36 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/80 {
  --tw-gradient-to: rgb(251 191 36 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/85 {
  --tw-gradient-to: rgb(251 191 36 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/90 {
  --tw-gradient-to: rgb(251 191 36 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-400\/95 {
  --tw-gradient-to: rgb(251 191 36 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50 {
  --tw-gradient-to: #fffbeb var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/0 {
  --tw-gradient-to: rgb(255 251 235 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/10 {
  --tw-gradient-to: rgb(255 251 235 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/100 {
  --tw-gradient-to: rgb(255 251 235 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/15 {
  --tw-gradient-to: rgb(255 251 235 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/20 {
  --tw-gradient-to: rgb(255 251 235 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/25 {
  --tw-gradient-to: rgb(255 251 235 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/30 {
  --tw-gradient-to: rgb(255 251 235 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/35 {
  --tw-gradient-to: rgb(255 251 235 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/40 {
  --tw-gradient-to: rgb(255 251 235 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/45 {
  --tw-gradient-to: rgb(255 251 235 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/5 {
  --tw-gradient-to: rgb(255 251 235 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/50 {
  --tw-gradient-to: rgb(255 251 235 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/55 {
  --tw-gradient-to: rgb(255 251 235 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/60 {
  --tw-gradient-to: rgb(255 251 235 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/65 {
  --tw-gradient-to: rgb(255 251 235 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/70 {
  --tw-gradient-to: rgb(255 251 235 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/75 {
  --tw-gradient-to: rgb(255 251 235 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/80 {
  --tw-gradient-to: rgb(255 251 235 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/85 {
  --tw-gradient-to: rgb(255 251 235 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/90 {
  --tw-gradient-to: rgb(255 251 235 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-50\/95 {
  --tw-gradient-to: rgb(255 251 235 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500 {
  --tw-gradient-to: #f59e0b var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/0 {
  --tw-gradient-to: rgb(245 158 11 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/10 {
  --tw-gradient-to: rgb(245 158 11 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/100 {
  --tw-gradient-to: rgb(245 158 11 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/15 {
  --tw-gradient-to: rgb(245 158 11 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/20 {
  --tw-gradient-to: rgb(245 158 11 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/25 {
  --tw-gradient-to: rgb(245 158 11 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/30 {
  --tw-gradient-to: rgb(245 158 11 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/35 {
  --tw-gradient-to: rgb(245 158 11 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/40 {
  --tw-gradient-to: rgb(245 158 11 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/45 {
  --tw-gradient-to: rgb(245 158 11 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/5 {
  --tw-gradient-to: rgb(245 158 11 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/50 {
  --tw-gradient-to: rgb(245 158 11 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/55 {
  --tw-gradient-to: rgb(245 158 11 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/60 {
  --tw-gradient-to: rgb(245 158 11 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/65 {
  --tw-gradient-to: rgb(245 158 11 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/70 {
  --tw-gradient-to: rgb(245 158 11 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/75 {
  --tw-gradient-to: rgb(245 158 11 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/80 {
  --tw-gradient-to: rgb(245 158 11 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/85 {
  --tw-gradient-to: rgb(245 158 11 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/90 {
  --tw-gradient-to: rgb(245 158 11 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-500\/95 {
  --tw-gradient-to: rgb(245 158 11 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600 {
  --tw-gradient-to: #d97706 var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/0 {
  --tw-gradient-to: rgb(217 119 6 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/10 {
  --tw-gradient-to: rgb(217 119 6 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/100 {
  --tw-gradient-to: rgb(217 119 6 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/15 {
  --tw-gradient-to: rgb(217 119 6 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/20 {
  --tw-gradient-to: rgb(217 119 6 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/25 {
  --tw-gradient-to: rgb(217 119 6 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/30 {
  --tw-gradient-to: rgb(217 119 6 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/35 {
  --tw-gradient-to: rgb(217 119 6 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/40 {
  --tw-gradient-to: rgb(217 119 6 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/45 {
  --tw-gradient-to: rgb(217 119 6 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/5 {
  --tw-gradient-to: rgb(217 119 6 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/50 {
  --tw-gradient-to: rgb(217 119 6 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/55 {
  --tw-gradient-to: rgb(217 119 6 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/60 {
  --tw-gradient-to: rgb(217 119 6 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/65 {
  --tw-gradient-to: rgb(217 119 6 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/70 {
  --tw-gradient-to: rgb(217 119 6 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/75 {
  --tw-gradient-to: rgb(217 119 6 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/80 {
  --tw-gradient-to: rgb(217 119 6 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/85 {
  --tw-gradient-to: rgb(217 119 6 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/90 {
  --tw-gradient-to: rgb(217 119 6 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-600\/95 {
  --tw-gradient-to: rgb(217 119 6 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700 {
  --tw-gradient-to: #b45309 var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/0 {
  --tw-gradient-to: rgb(180 83 9 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/10 {
  --tw-gradient-to: rgb(180 83 9 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/100 {
  --tw-gradient-to: rgb(180 83 9 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/15 {
  --tw-gradient-to: rgb(180 83 9 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/20 {
  --tw-gradient-to: rgb(180 83 9 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/25 {
  --tw-gradient-to: rgb(180 83 9 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/30 {
  --tw-gradient-to: rgb(180 83 9 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/35 {
  --tw-gradient-to: rgb(180 83 9 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/40 {
  --tw-gradient-to: rgb(180 83 9 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/45 {
  --tw-gradient-to: rgb(180 83 9 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/5 {
  --tw-gradient-to: rgb(180 83 9 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/50 {
  --tw-gradient-to: rgb(180 83 9 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/55 {
  --tw-gradient-to: rgb(180 83 9 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/60 {
  --tw-gradient-to: rgb(180 83 9 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/65 {
  --tw-gradient-to: rgb(180 83 9 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/70 {
  --tw-gradient-to: rgb(180 83 9 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/75 {
  --tw-gradient-to: rgb(180 83 9 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/80 {
  --tw-gradient-to: rgb(180 83 9 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/85 {
  --tw-gradient-to: rgb(180 83 9 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/90 {
  --tw-gradient-to: rgb(180 83 9 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-700\/95 {
  --tw-gradient-to: rgb(180 83 9 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800 {
  --tw-gradient-to: #92400e var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/0 {
  --tw-gradient-to: rgb(146 64 14 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/10 {
  --tw-gradient-to: rgb(146 64 14 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/100 {
  --tw-gradient-to: rgb(146 64 14 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/15 {
  --tw-gradient-to: rgb(146 64 14 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/20 {
  --tw-gradient-to: rgb(146 64 14 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/25 {
  --tw-gradient-to: rgb(146 64 14 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/30 {
  --tw-gradient-to: rgb(146 64 14 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/35 {
  --tw-gradient-to: rgb(146 64 14 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/40 {
  --tw-gradient-to: rgb(146 64 14 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/45 {
  --tw-gradient-to: rgb(146 64 14 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/5 {
  --tw-gradient-to: rgb(146 64 14 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/50 {
  --tw-gradient-to: rgb(146 64 14 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/55 {
  --tw-gradient-to: rgb(146 64 14 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/60 {
  --tw-gradient-to: rgb(146 64 14 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/65 {
  --tw-gradient-to: rgb(146 64 14 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/70 {
  --tw-gradient-to: rgb(146 64 14 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/75 {
  --tw-gradient-to: rgb(146 64 14 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/80 {
  --tw-gradient-to: rgb(146 64 14 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/85 {
  --tw-gradient-to: rgb(146 64 14 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/90 {
  --tw-gradient-to: rgb(146 64 14 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-800\/95 {
  --tw-gradient-to: rgb(146 64 14 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900 {
  --tw-gradient-to: #78350f var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/0 {
  --tw-gradient-to: rgb(120 53 15 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/10 {
  --tw-gradient-to: rgb(120 53 15 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/100 {
  --tw-gradient-to: rgb(120 53 15 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/15 {
  --tw-gradient-to: rgb(120 53 15 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/20 {
  --tw-gradient-to: rgb(120 53 15 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/25 {
  --tw-gradient-to: rgb(120 53 15 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/30 {
  --tw-gradient-to: rgb(120 53 15 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/35 {
  --tw-gradient-to: rgb(120 53 15 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/40 {
  --tw-gradient-to: rgb(120 53 15 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/45 {
  --tw-gradient-to: rgb(120 53 15 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/5 {
  --tw-gradient-to: rgb(120 53 15 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/50 {
  --tw-gradient-to: rgb(120 53 15 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/55 {
  --tw-gradient-to: rgb(120 53 15 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/60 {
  --tw-gradient-to: rgb(120 53 15 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/65 {
  --tw-gradient-to: rgb(120 53 15 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/70 {
  --tw-gradient-to: rgb(120 53 15 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/75 {
  --tw-gradient-to: rgb(120 53 15 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/80 {
  --tw-gradient-to: rgb(120 53 15 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/85 {
  --tw-gradient-to: rgb(120 53 15 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/90 {
  --tw-gradient-to: rgb(120 53 15 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-900\/95 {
  --tw-gradient-to: rgb(120 53 15 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950 {
  --tw-gradient-to: #451a03 var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/0 {
  --tw-gradient-to: rgb(69 26 3 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/10 {
  --tw-gradient-to: rgb(69 26 3 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/100 {
  --tw-gradient-to: rgb(69 26 3 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/15 {
  --tw-gradient-to: rgb(69 26 3 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/20 {
  --tw-gradient-to: rgb(69 26 3 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/25 {
  --tw-gradient-to: rgb(69 26 3 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/30 {
  --tw-gradient-to: rgb(69 26 3 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/35 {
  --tw-gradient-to: rgb(69 26 3 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/40 {
  --tw-gradient-to: rgb(69 26 3 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/45 {
  --tw-gradient-to: rgb(69 26 3 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/5 {
  --tw-gradient-to: rgb(69 26 3 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/50 {
  --tw-gradient-to: rgb(69 26 3 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/55 {
  --tw-gradient-to: rgb(69 26 3 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/60 {
  --tw-gradient-to: rgb(69 26 3 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/65 {
  --tw-gradient-to: rgb(69 26 3 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/70 {
  --tw-gradient-to: rgb(69 26 3 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/75 {
  --tw-gradient-to: rgb(69 26 3 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/80 {
  --tw-gradient-to: rgb(69 26 3 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/85 {
  --tw-gradient-to: rgb(69 26 3 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/90 {
  --tw-gradient-to: rgb(69 26 3 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-amber-950\/95 {
  --tw-gradient-to: rgb(69 26 3 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-black {
  --tw-gradient-to: #000 var(--tw-gradient-to-position) !important;
}

.tw-to-black\/0 {
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/10 {
  --tw-gradient-to: rgb(0 0 0 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/100 {
  --tw-gradient-to: rgb(0 0 0 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/15 {
  --tw-gradient-to: rgb(0 0 0 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/20 {
  --tw-gradient-to: rgb(0 0 0 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/25 {
  --tw-gradient-to: rgb(0 0 0 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/30 {
  --tw-gradient-to: rgb(0 0 0 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/35 {
  --tw-gradient-to: rgb(0 0 0 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/40 {
  --tw-gradient-to: rgb(0 0 0 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/45 {
  --tw-gradient-to: rgb(0 0 0 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/5 {
  --tw-gradient-to: rgb(0 0 0 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/50 {
  --tw-gradient-to: rgb(0 0 0 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/55 {
  --tw-gradient-to: rgb(0 0 0 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/60 {
  --tw-gradient-to: rgb(0 0 0 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/65 {
  --tw-gradient-to: rgb(0 0 0 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/70 {
  --tw-gradient-to: rgb(0 0 0 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/75 {
  --tw-gradient-to: rgb(0 0 0 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/80 {
  --tw-gradient-to: rgb(0 0 0 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/85 {
  --tw-gradient-to: rgb(0 0 0 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/90 {
  --tw-gradient-to: rgb(0 0 0 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-black\/95 {
  --tw-gradient-to: rgb(0 0 0 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100 {
  --tw-gradient-to: #dbeafe var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/0 {
  --tw-gradient-to: rgb(219 234 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/10 {
  --tw-gradient-to: rgb(219 234 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/100 {
  --tw-gradient-to: rgb(219 234 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/15 {
  --tw-gradient-to: rgb(219 234 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/20 {
  --tw-gradient-to: rgb(219 234 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/25 {
  --tw-gradient-to: rgb(219 234 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/30 {
  --tw-gradient-to: rgb(219 234 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/35 {
  --tw-gradient-to: rgb(219 234 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/40 {
  --tw-gradient-to: rgb(219 234 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/45 {
  --tw-gradient-to: rgb(219 234 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/5 {
  --tw-gradient-to: rgb(219 234 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/50 {
  --tw-gradient-to: rgb(219 234 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/55 {
  --tw-gradient-to: rgb(219 234 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/60 {
  --tw-gradient-to: rgb(219 234 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/65 {
  --tw-gradient-to: rgb(219 234 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/70 {
  --tw-gradient-to: rgb(219 234 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/75 {
  --tw-gradient-to: rgb(219 234 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/80 {
  --tw-gradient-to: rgb(219 234 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/85 {
  --tw-gradient-to: rgb(219 234 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/90 {
  --tw-gradient-to: rgb(219 234 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-100\/95 {
  --tw-gradient-to: rgb(219 234 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200 {
  --tw-gradient-to: #bfdbfe var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/0 {
  --tw-gradient-to: rgb(191 219 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/10 {
  --tw-gradient-to: rgb(191 219 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/100 {
  --tw-gradient-to: rgb(191 219 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/15 {
  --tw-gradient-to: rgb(191 219 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/20 {
  --tw-gradient-to: rgb(191 219 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/25 {
  --tw-gradient-to: rgb(191 219 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/30 {
  --tw-gradient-to: rgb(191 219 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/35 {
  --tw-gradient-to: rgb(191 219 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/40 {
  --tw-gradient-to: rgb(191 219 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/45 {
  --tw-gradient-to: rgb(191 219 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/5 {
  --tw-gradient-to: rgb(191 219 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/50 {
  --tw-gradient-to: rgb(191 219 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/55 {
  --tw-gradient-to: rgb(191 219 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/60 {
  --tw-gradient-to: rgb(191 219 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/65 {
  --tw-gradient-to: rgb(191 219 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/70 {
  --tw-gradient-to: rgb(191 219 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/75 {
  --tw-gradient-to: rgb(191 219 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/80 {
  --tw-gradient-to: rgb(191 219 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/85 {
  --tw-gradient-to: rgb(191 219 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/90 {
  --tw-gradient-to: rgb(191 219 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-200\/95 {
  --tw-gradient-to: rgb(191 219 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300 {
  --tw-gradient-to: #93c5fd var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/0 {
  --tw-gradient-to: rgb(147 197 253 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/10 {
  --tw-gradient-to: rgb(147 197 253 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/100 {
  --tw-gradient-to: rgb(147 197 253 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/15 {
  --tw-gradient-to: rgb(147 197 253 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/20 {
  --tw-gradient-to: rgb(147 197 253 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/25 {
  --tw-gradient-to: rgb(147 197 253 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/30 {
  --tw-gradient-to: rgb(147 197 253 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/35 {
  --tw-gradient-to: rgb(147 197 253 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/40 {
  --tw-gradient-to: rgb(147 197 253 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/45 {
  --tw-gradient-to: rgb(147 197 253 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/5 {
  --tw-gradient-to: rgb(147 197 253 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/50 {
  --tw-gradient-to: rgb(147 197 253 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/55 {
  --tw-gradient-to: rgb(147 197 253 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/60 {
  --tw-gradient-to: rgb(147 197 253 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/65 {
  --tw-gradient-to: rgb(147 197 253 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/70 {
  --tw-gradient-to: rgb(147 197 253 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/75 {
  --tw-gradient-to: rgb(147 197 253 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/80 {
  --tw-gradient-to: rgb(147 197 253 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/85 {
  --tw-gradient-to: rgb(147 197 253 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/90 {
  --tw-gradient-to: rgb(147 197 253 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-300\/95 {
  --tw-gradient-to: rgb(147 197 253 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400 {
  --tw-gradient-to: #60a5fa var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/0 {
  --tw-gradient-to: rgb(96 165 250 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/10 {
  --tw-gradient-to: rgb(96 165 250 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/100 {
  --tw-gradient-to: rgb(96 165 250 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/15 {
  --tw-gradient-to: rgb(96 165 250 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/20 {
  --tw-gradient-to: rgb(96 165 250 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/25 {
  --tw-gradient-to: rgb(96 165 250 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/30 {
  --tw-gradient-to: rgb(96 165 250 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/35 {
  --tw-gradient-to: rgb(96 165 250 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/40 {
  --tw-gradient-to: rgb(96 165 250 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/45 {
  --tw-gradient-to: rgb(96 165 250 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/5 {
  --tw-gradient-to: rgb(96 165 250 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/50 {
  --tw-gradient-to: rgb(96 165 250 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/55 {
  --tw-gradient-to: rgb(96 165 250 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/60 {
  --tw-gradient-to: rgb(96 165 250 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/65 {
  --tw-gradient-to: rgb(96 165 250 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/70 {
  --tw-gradient-to: rgb(96 165 250 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/75 {
  --tw-gradient-to: rgb(96 165 250 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/80 {
  --tw-gradient-to: rgb(96 165 250 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/85 {
  --tw-gradient-to: rgb(96 165 250 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/90 {
  --tw-gradient-to: rgb(96 165 250 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-400\/95 {
  --tw-gradient-to: rgb(96 165 250 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50 {
  --tw-gradient-to: #eff6ff var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/0 {
  --tw-gradient-to: rgb(239 246 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/10 {
  --tw-gradient-to: rgb(239 246 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/100 {
  --tw-gradient-to: rgb(239 246 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/15 {
  --tw-gradient-to: rgb(239 246 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/20 {
  --tw-gradient-to: rgb(239 246 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/25 {
  --tw-gradient-to: rgb(239 246 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/30 {
  --tw-gradient-to: rgb(239 246 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/35 {
  --tw-gradient-to: rgb(239 246 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/40 {
  --tw-gradient-to: rgb(239 246 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/45 {
  --tw-gradient-to: rgb(239 246 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/5 {
  --tw-gradient-to: rgb(239 246 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/50 {
  --tw-gradient-to: rgb(239 246 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/55 {
  --tw-gradient-to: rgb(239 246 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/60 {
  --tw-gradient-to: rgb(239 246 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/65 {
  --tw-gradient-to: rgb(239 246 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/70 {
  --tw-gradient-to: rgb(239 246 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/75 {
  --tw-gradient-to: rgb(239 246 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/80 {
  --tw-gradient-to: rgb(239 246 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/85 {
  --tw-gradient-to: rgb(239 246 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/90 {
  --tw-gradient-to: rgb(239 246 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-50\/95 {
  --tw-gradient-to: rgb(239 246 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500 {
  --tw-gradient-to: #3b82f6 var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/0 {
  --tw-gradient-to: rgb(59 130 246 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/10 {
  --tw-gradient-to: rgb(59 130 246 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/100 {
  --tw-gradient-to: rgb(59 130 246 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/15 {
  --tw-gradient-to: rgb(59 130 246 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/20 {
  --tw-gradient-to: rgb(59 130 246 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/25 {
  --tw-gradient-to: rgb(59 130 246 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/30 {
  --tw-gradient-to: rgb(59 130 246 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/35 {
  --tw-gradient-to: rgb(59 130 246 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/40 {
  --tw-gradient-to: rgb(59 130 246 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/45 {
  --tw-gradient-to: rgb(59 130 246 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/5 {
  --tw-gradient-to: rgb(59 130 246 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/50 {
  --tw-gradient-to: rgb(59 130 246 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/55 {
  --tw-gradient-to: rgb(59 130 246 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/60 {
  --tw-gradient-to: rgb(59 130 246 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/65 {
  --tw-gradient-to: rgb(59 130 246 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/70 {
  --tw-gradient-to: rgb(59 130 246 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/75 {
  --tw-gradient-to: rgb(59 130 246 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/80 {
  --tw-gradient-to: rgb(59 130 246 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/85 {
  --tw-gradient-to: rgb(59 130 246 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/90 {
  --tw-gradient-to: rgb(59 130 246 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-500\/95 {
  --tw-gradient-to: rgb(59 130 246 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600 {
  --tw-gradient-to: #2563eb var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/0 {
  --tw-gradient-to: rgb(37 99 235 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/10 {
  --tw-gradient-to: rgb(37 99 235 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/100 {
  --tw-gradient-to: rgb(37 99 235 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/15 {
  --tw-gradient-to: rgb(37 99 235 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/20 {
  --tw-gradient-to: rgb(37 99 235 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/25 {
  --tw-gradient-to: rgb(37 99 235 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/30 {
  --tw-gradient-to: rgb(37 99 235 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/35 {
  --tw-gradient-to: rgb(37 99 235 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/40 {
  --tw-gradient-to: rgb(37 99 235 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/45 {
  --tw-gradient-to: rgb(37 99 235 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/5 {
  --tw-gradient-to: rgb(37 99 235 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/50 {
  --tw-gradient-to: rgb(37 99 235 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/55 {
  --tw-gradient-to: rgb(37 99 235 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/60 {
  --tw-gradient-to: rgb(37 99 235 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/65 {
  --tw-gradient-to: rgb(37 99 235 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/70 {
  --tw-gradient-to: rgb(37 99 235 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/75 {
  --tw-gradient-to: rgb(37 99 235 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/80 {
  --tw-gradient-to: rgb(37 99 235 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/85 {
  --tw-gradient-to: rgb(37 99 235 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/90 {
  --tw-gradient-to: rgb(37 99 235 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-600\/95 {
  --tw-gradient-to: rgb(37 99 235 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700 {
  --tw-gradient-to: #1d4ed8 var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/0 {
  --tw-gradient-to: rgb(29 78 216 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/10 {
  --tw-gradient-to: rgb(29 78 216 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/100 {
  --tw-gradient-to: rgb(29 78 216 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/15 {
  --tw-gradient-to: rgb(29 78 216 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/20 {
  --tw-gradient-to: rgb(29 78 216 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/25 {
  --tw-gradient-to: rgb(29 78 216 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/30 {
  --tw-gradient-to: rgb(29 78 216 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/35 {
  --tw-gradient-to: rgb(29 78 216 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/40 {
  --tw-gradient-to: rgb(29 78 216 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/45 {
  --tw-gradient-to: rgb(29 78 216 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/5 {
  --tw-gradient-to: rgb(29 78 216 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/50 {
  --tw-gradient-to: rgb(29 78 216 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/55 {
  --tw-gradient-to: rgb(29 78 216 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/60 {
  --tw-gradient-to: rgb(29 78 216 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/65 {
  --tw-gradient-to: rgb(29 78 216 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/70 {
  --tw-gradient-to: rgb(29 78 216 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/75 {
  --tw-gradient-to: rgb(29 78 216 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/80 {
  --tw-gradient-to: rgb(29 78 216 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/85 {
  --tw-gradient-to: rgb(29 78 216 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/90 {
  --tw-gradient-to: rgb(29 78 216 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-700\/95 {
  --tw-gradient-to: rgb(29 78 216 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800 {
  --tw-gradient-to: #1e40af var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/0 {
  --tw-gradient-to: rgb(30 64 175 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/10 {
  --tw-gradient-to: rgb(30 64 175 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/100 {
  --tw-gradient-to: rgb(30 64 175 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/15 {
  --tw-gradient-to: rgb(30 64 175 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/20 {
  --tw-gradient-to: rgb(30 64 175 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/25 {
  --tw-gradient-to: rgb(30 64 175 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/30 {
  --tw-gradient-to: rgb(30 64 175 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/35 {
  --tw-gradient-to: rgb(30 64 175 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/40 {
  --tw-gradient-to: rgb(30 64 175 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/45 {
  --tw-gradient-to: rgb(30 64 175 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/5 {
  --tw-gradient-to: rgb(30 64 175 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/50 {
  --tw-gradient-to: rgb(30 64 175 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/55 {
  --tw-gradient-to: rgb(30 64 175 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/60 {
  --tw-gradient-to: rgb(30 64 175 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/65 {
  --tw-gradient-to: rgb(30 64 175 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/70 {
  --tw-gradient-to: rgb(30 64 175 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/75 {
  --tw-gradient-to: rgb(30 64 175 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/80 {
  --tw-gradient-to: rgb(30 64 175 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/85 {
  --tw-gradient-to: rgb(30 64 175 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/90 {
  --tw-gradient-to: rgb(30 64 175 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-800\/95 {
  --tw-gradient-to: rgb(30 64 175 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900 {
  --tw-gradient-to: #1e3a8a var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/0 {
  --tw-gradient-to: rgb(30 58 138 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/10 {
  --tw-gradient-to: rgb(30 58 138 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/100 {
  --tw-gradient-to: rgb(30 58 138 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/15 {
  --tw-gradient-to: rgb(30 58 138 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/20 {
  --tw-gradient-to: rgb(30 58 138 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/25 {
  --tw-gradient-to: rgb(30 58 138 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/30 {
  --tw-gradient-to: rgb(30 58 138 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/35 {
  --tw-gradient-to: rgb(30 58 138 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/40 {
  --tw-gradient-to: rgb(30 58 138 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/45 {
  --tw-gradient-to: rgb(30 58 138 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/5 {
  --tw-gradient-to: rgb(30 58 138 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/50 {
  --tw-gradient-to: rgb(30 58 138 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/55 {
  --tw-gradient-to: rgb(30 58 138 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/60 {
  --tw-gradient-to: rgb(30 58 138 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/65 {
  --tw-gradient-to: rgb(30 58 138 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/70 {
  --tw-gradient-to: rgb(30 58 138 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/75 {
  --tw-gradient-to: rgb(30 58 138 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/80 {
  --tw-gradient-to: rgb(30 58 138 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/85 {
  --tw-gradient-to: rgb(30 58 138 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/90 {
  --tw-gradient-to: rgb(30 58 138 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-900\/95 {
  --tw-gradient-to: rgb(30 58 138 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950 {
  --tw-gradient-to: #172554 var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/0 {
  --tw-gradient-to: rgb(23 37 84 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/10 {
  --tw-gradient-to: rgb(23 37 84 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/100 {
  --tw-gradient-to: rgb(23 37 84 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/15 {
  --tw-gradient-to: rgb(23 37 84 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/20 {
  --tw-gradient-to: rgb(23 37 84 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/25 {
  --tw-gradient-to: rgb(23 37 84 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/30 {
  --tw-gradient-to: rgb(23 37 84 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/35 {
  --tw-gradient-to: rgb(23 37 84 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/40 {
  --tw-gradient-to: rgb(23 37 84 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/45 {
  --tw-gradient-to: rgb(23 37 84 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/5 {
  --tw-gradient-to: rgb(23 37 84 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/50 {
  --tw-gradient-to: rgb(23 37 84 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/55 {
  --tw-gradient-to: rgb(23 37 84 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/60 {
  --tw-gradient-to: rgb(23 37 84 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/65 {
  --tw-gradient-to: rgb(23 37 84 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/70 {
  --tw-gradient-to: rgb(23 37 84 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/75 {
  --tw-gradient-to: rgb(23 37 84 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/80 {
  --tw-gradient-to: rgb(23 37 84 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/85 {
  --tw-gradient-to: rgb(23 37 84 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/90 {
  --tw-gradient-to: rgb(23 37 84 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-blue-950\/95 {
  --tw-gradient-to: rgb(23 37 84 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-current {
  --tw-gradient-to: currentColor var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100 {
  --tw-gradient-to: #cffafe var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/0 {
  --tw-gradient-to: rgb(207 250 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/10 {
  --tw-gradient-to: rgb(207 250 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/100 {
  --tw-gradient-to: rgb(207 250 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/15 {
  --tw-gradient-to: rgb(207 250 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/20 {
  --tw-gradient-to: rgb(207 250 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/25 {
  --tw-gradient-to: rgb(207 250 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/30 {
  --tw-gradient-to: rgb(207 250 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/35 {
  --tw-gradient-to: rgb(207 250 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/40 {
  --tw-gradient-to: rgb(207 250 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/45 {
  --tw-gradient-to: rgb(207 250 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/5 {
  --tw-gradient-to: rgb(207 250 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/50 {
  --tw-gradient-to: rgb(207 250 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/55 {
  --tw-gradient-to: rgb(207 250 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/60 {
  --tw-gradient-to: rgb(207 250 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/65 {
  --tw-gradient-to: rgb(207 250 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/70 {
  --tw-gradient-to: rgb(207 250 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/75 {
  --tw-gradient-to: rgb(207 250 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/80 {
  --tw-gradient-to: rgb(207 250 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/85 {
  --tw-gradient-to: rgb(207 250 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/90 {
  --tw-gradient-to: rgb(207 250 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-100\/95 {
  --tw-gradient-to: rgb(207 250 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200 {
  --tw-gradient-to: #a5f3fc var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/0 {
  --tw-gradient-to: rgb(165 243 252 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/10 {
  --tw-gradient-to: rgb(165 243 252 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/100 {
  --tw-gradient-to: rgb(165 243 252 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/15 {
  --tw-gradient-to: rgb(165 243 252 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/20 {
  --tw-gradient-to: rgb(165 243 252 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/25 {
  --tw-gradient-to: rgb(165 243 252 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/30 {
  --tw-gradient-to: rgb(165 243 252 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/35 {
  --tw-gradient-to: rgb(165 243 252 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/40 {
  --tw-gradient-to: rgb(165 243 252 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/45 {
  --tw-gradient-to: rgb(165 243 252 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/5 {
  --tw-gradient-to: rgb(165 243 252 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/50 {
  --tw-gradient-to: rgb(165 243 252 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/55 {
  --tw-gradient-to: rgb(165 243 252 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/60 {
  --tw-gradient-to: rgb(165 243 252 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/65 {
  --tw-gradient-to: rgb(165 243 252 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/70 {
  --tw-gradient-to: rgb(165 243 252 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/75 {
  --tw-gradient-to: rgb(165 243 252 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/80 {
  --tw-gradient-to: rgb(165 243 252 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/85 {
  --tw-gradient-to: rgb(165 243 252 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/90 {
  --tw-gradient-to: rgb(165 243 252 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-200\/95 {
  --tw-gradient-to: rgb(165 243 252 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300 {
  --tw-gradient-to: #67e8f9 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/0 {
  --tw-gradient-to: rgb(103 232 249 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/10 {
  --tw-gradient-to: rgb(103 232 249 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/100 {
  --tw-gradient-to: rgb(103 232 249 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/15 {
  --tw-gradient-to: rgb(103 232 249 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/20 {
  --tw-gradient-to: rgb(103 232 249 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/25 {
  --tw-gradient-to: rgb(103 232 249 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/30 {
  --tw-gradient-to: rgb(103 232 249 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/35 {
  --tw-gradient-to: rgb(103 232 249 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/40 {
  --tw-gradient-to: rgb(103 232 249 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/45 {
  --tw-gradient-to: rgb(103 232 249 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/5 {
  --tw-gradient-to: rgb(103 232 249 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/50 {
  --tw-gradient-to: rgb(103 232 249 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/55 {
  --tw-gradient-to: rgb(103 232 249 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/60 {
  --tw-gradient-to: rgb(103 232 249 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/65 {
  --tw-gradient-to: rgb(103 232 249 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/70 {
  --tw-gradient-to: rgb(103 232 249 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/75 {
  --tw-gradient-to: rgb(103 232 249 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/80 {
  --tw-gradient-to: rgb(103 232 249 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/85 {
  --tw-gradient-to: rgb(103 232 249 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/90 {
  --tw-gradient-to: rgb(103 232 249 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-300\/95 {
  --tw-gradient-to: rgb(103 232 249 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400 {
  --tw-gradient-to: #22d3ee var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/0 {
  --tw-gradient-to: rgb(34 211 238 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/10 {
  --tw-gradient-to: rgb(34 211 238 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/100 {
  --tw-gradient-to: rgb(34 211 238 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/15 {
  --tw-gradient-to: rgb(34 211 238 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/20 {
  --tw-gradient-to: rgb(34 211 238 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/25 {
  --tw-gradient-to: rgb(34 211 238 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/30 {
  --tw-gradient-to: rgb(34 211 238 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/35 {
  --tw-gradient-to: rgb(34 211 238 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/40 {
  --tw-gradient-to: rgb(34 211 238 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/45 {
  --tw-gradient-to: rgb(34 211 238 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/5 {
  --tw-gradient-to: rgb(34 211 238 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/50 {
  --tw-gradient-to: rgb(34 211 238 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/55 {
  --tw-gradient-to: rgb(34 211 238 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/60 {
  --tw-gradient-to: rgb(34 211 238 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/65 {
  --tw-gradient-to: rgb(34 211 238 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/70 {
  --tw-gradient-to: rgb(34 211 238 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/75 {
  --tw-gradient-to: rgb(34 211 238 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/80 {
  --tw-gradient-to: rgb(34 211 238 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/85 {
  --tw-gradient-to: rgb(34 211 238 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/90 {
  --tw-gradient-to: rgb(34 211 238 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-400\/95 {
  --tw-gradient-to: rgb(34 211 238 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50 {
  --tw-gradient-to: #ecfeff var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/0 {
  --tw-gradient-to: rgb(236 254 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/10 {
  --tw-gradient-to: rgb(236 254 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/100 {
  --tw-gradient-to: rgb(236 254 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/15 {
  --tw-gradient-to: rgb(236 254 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/20 {
  --tw-gradient-to: rgb(236 254 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/25 {
  --tw-gradient-to: rgb(236 254 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/30 {
  --tw-gradient-to: rgb(236 254 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/35 {
  --tw-gradient-to: rgb(236 254 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/40 {
  --tw-gradient-to: rgb(236 254 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/45 {
  --tw-gradient-to: rgb(236 254 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/5 {
  --tw-gradient-to: rgb(236 254 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/50 {
  --tw-gradient-to: rgb(236 254 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/55 {
  --tw-gradient-to: rgb(236 254 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/60 {
  --tw-gradient-to: rgb(236 254 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/65 {
  --tw-gradient-to: rgb(236 254 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/70 {
  --tw-gradient-to: rgb(236 254 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/75 {
  --tw-gradient-to: rgb(236 254 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/80 {
  --tw-gradient-to: rgb(236 254 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/85 {
  --tw-gradient-to: rgb(236 254 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/90 {
  --tw-gradient-to: rgb(236 254 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-50\/95 {
  --tw-gradient-to: rgb(236 254 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500 {
  --tw-gradient-to: #06b6d4 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/0 {
  --tw-gradient-to: rgb(6 182 212 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/10 {
  --tw-gradient-to: rgb(6 182 212 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/100 {
  --tw-gradient-to: rgb(6 182 212 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/15 {
  --tw-gradient-to: rgb(6 182 212 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/20 {
  --tw-gradient-to: rgb(6 182 212 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/25 {
  --tw-gradient-to: rgb(6 182 212 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/30 {
  --tw-gradient-to: rgb(6 182 212 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/35 {
  --tw-gradient-to: rgb(6 182 212 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/40 {
  --tw-gradient-to: rgb(6 182 212 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/45 {
  --tw-gradient-to: rgb(6 182 212 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/5 {
  --tw-gradient-to: rgb(6 182 212 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/50 {
  --tw-gradient-to: rgb(6 182 212 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/55 {
  --tw-gradient-to: rgb(6 182 212 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/60 {
  --tw-gradient-to: rgb(6 182 212 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/65 {
  --tw-gradient-to: rgb(6 182 212 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/70 {
  --tw-gradient-to: rgb(6 182 212 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/75 {
  --tw-gradient-to: rgb(6 182 212 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/80 {
  --tw-gradient-to: rgb(6 182 212 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/85 {
  --tw-gradient-to: rgb(6 182 212 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/90 {
  --tw-gradient-to: rgb(6 182 212 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-500\/95 {
  --tw-gradient-to: rgb(6 182 212 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600 {
  --tw-gradient-to: #0891b2 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/0 {
  --tw-gradient-to: rgb(8 145 178 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/10 {
  --tw-gradient-to: rgb(8 145 178 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/100 {
  --tw-gradient-to: rgb(8 145 178 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/15 {
  --tw-gradient-to: rgb(8 145 178 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/20 {
  --tw-gradient-to: rgb(8 145 178 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/25 {
  --tw-gradient-to: rgb(8 145 178 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/30 {
  --tw-gradient-to: rgb(8 145 178 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/35 {
  --tw-gradient-to: rgb(8 145 178 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/40 {
  --tw-gradient-to: rgb(8 145 178 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/45 {
  --tw-gradient-to: rgb(8 145 178 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/5 {
  --tw-gradient-to: rgb(8 145 178 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/50 {
  --tw-gradient-to: rgb(8 145 178 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/55 {
  --tw-gradient-to: rgb(8 145 178 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/60 {
  --tw-gradient-to: rgb(8 145 178 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/65 {
  --tw-gradient-to: rgb(8 145 178 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/70 {
  --tw-gradient-to: rgb(8 145 178 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/75 {
  --tw-gradient-to: rgb(8 145 178 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/80 {
  --tw-gradient-to: rgb(8 145 178 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/85 {
  --tw-gradient-to: rgb(8 145 178 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/90 {
  --tw-gradient-to: rgb(8 145 178 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-600\/95 {
  --tw-gradient-to: rgb(8 145 178 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700 {
  --tw-gradient-to: #0e7490 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/0 {
  --tw-gradient-to: rgb(14 116 144 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/10 {
  --tw-gradient-to: rgb(14 116 144 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/100 {
  --tw-gradient-to: rgb(14 116 144 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/15 {
  --tw-gradient-to: rgb(14 116 144 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/20 {
  --tw-gradient-to: rgb(14 116 144 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/25 {
  --tw-gradient-to: rgb(14 116 144 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/30 {
  --tw-gradient-to: rgb(14 116 144 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/35 {
  --tw-gradient-to: rgb(14 116 144 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/40 {
  --tw-gradient-to: rgb(14 116 144 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/45 {
  --tw-gradient-to: rgb(14 116 144 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/5 {
  --tw-gradient-to: rgb(14 116 144 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/50 {
  --tw-gradient-to: rgb(14 116 144 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/55 {
  --tw-gradient-to: rgb(14 116 144 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/60 {
  --tw-gradient-to: rgb(14 116 144 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/65 {
  --tw-gradient-to: rgb(14 116 144 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/70 {
  --tw-gradient-to: rgb(14 116 144 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/75 {
  --tw-gradient-to: rgb(14 116 144 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/80 {
  --tw-gradient-to: rgb(14 116 144 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/85 {
  --tw-gradient-to: rgb(14 116 144 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/90 {
  --tw-gradient-to: rgb(14 116 144 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-700\/95 {
  --tw-gradient-to: rgb(14 116 144 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800 {
  --tw-gradient-to: #155e75 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/0 {
  --tw-gradient-to: rgb(21 94 117 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/10 {
  --tw-gradient-to: rgb(21 94 117 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/100 {
  --tw-gradient-to: rgb(21 94 117 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/15 {
  --tw-gradient-to: rgb(21 94 117 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/20 {
  --tw-gradient-to: rgb(21 94 117 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/25 {
  --tw-gradient-to: rgb(21 94 117 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/30 {
  --tw-gradient-to: rgb(21 94 117 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/35 {
  --tw-gradient-to: rgb(21 94 117 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/40 {
  --tw-gradient-to: rgb(21 94 117 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/45 {
  --tw-gradient-to: rgb(21 94 117 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/5 {
  --tw-gradient-to: rgb(21 94 117 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/50 {
  --tw-gradient-to: rgb(21 94 117 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/55 {
  --tw-gradient-to: rgb(21 94 117 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/60 {
  --tw-gradient-to: rgb(21 94 117 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/65 {
  --tw-gradient-to: rgb(21 94 117 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/70 {
  --tw-gradient-to: rgb(21 94 117 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/75 {
  --tw-gradient-to: rgb(21 94 117 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/80 {
  --tw-gradient-to: rgb(21 94 117 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/85 {
  --tw-gradient-to: rgb(21 94 117 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/90 {
  --tw-gradient-to: rgb(21 94 117 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-800\/95 {
  --tw-gradient-to: rgb(21 94 117 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900 {
  --tw-gradient-to: #164e63 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/0 {
  --tw-gradient-to: rgb(22 78 99 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/10 {
  --tw-gradient-to: rgb(22 78 99 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/100 {
  --tw-gradient-to: rgb(22 78 99 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/15 {
  --tw-gradient-to: rgb(22 78 99 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/20 {
  --tw-gradient-to: rgb(22 78 99 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/25 {
  --tw-gradient-to: rgb(22 78 99 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/30 {
  --tw-gradient-to: rgb(22 78 99 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/35 {
  --tw-gradient-to: rgb(22 78 99 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/40 {
  --tw-gradient-to: rgb(22 78 99 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/45 {
  --tw-gradient-to: rgb(22 78 99 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/5 {
  --tw-gradient-to: rgb(22 78 99 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/50 {
  --tw-gradient-to: rgb(22 78 99 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/55 {
  --tw-gradient-to: rgb(22 78 99 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/60 {
  --tw-gradient-to: rgb(22 78 99 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/65 {
  --tw-gradient-to: rgb(22 78 99 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/70 {
  --tw-gradient-to: rgb(22 78 99 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/75 {
  --tw-gradient-to: rgb(22 78 99 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/80 {
  --tw-gradient-to: rgb(22 78 99 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/85 {
  --tw-gradient-to: rgb(22 78 99 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/90 {
  --tw-gradient-to: rgb(22 78 99 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-900\/95 {
  --tw-gradient-to: rgb(22 78 99 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950 {
  --tw-gradient-to: #083344 var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/0 {
  --tw-gradient-to: rgb(8 51 68 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/10 {
  --tw-gradient-to: rgb(8 51 68 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/100 {
  --tw-gradient-to: rgb(8 51 68 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/15 {
  --tw-gradient-to: rgb(8 51 68 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/20 {
  --tw-gradient-to: rgb(8 51 68 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/25 {
  --tw-gradient-to: rgb(8 51 68 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/30 {
  --tw-gradient-to: rgb(8 51 68 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/35 {
  --tw-gradient-to: rgb(8 51 68 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/40 {
  --tw-gradient-to: rgb(8 51 68 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/45 {
  --tw-gradient-to: rgb(8 51 68 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/5 {
  --tw-gradient-to: rgb(8 51 68 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/50 {
  --tw-gradient-to: rgb(8 51 68 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/55 {
  --tw-gradient-to: rgb(8 51 68 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/60 {
  --tw-gradient-to: rgb(8 51 68 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/65 {
  --tw-gradient-to: rgb(8 51 68 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/70 {
  --tw-gradient-to: rgb(8 51 68 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/75 {
  --tw-gradient-to: rgb(8 51 68 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/80 {
  --tw-gradient-to: rgb(8 51 68 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/85 {
  --tw-gradient-to: rgb(8 51 68 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/90 {
  --tw-gradient-to: rgb(8 51 68 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-cyan-950\/95 {
  --tw-gradient-to: rgb(8 51 68 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100 {
  --tw-gradient-to: #d1fae5 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/0 {
  --tw-gradient-to: rgb(209 250 229 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/10 {
  --tw-gradient-to: rgb(209 250 229 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/100 {
  --tw-gradient-to: rgb(209 250 229 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/15 {
  --tw-gradient-to: rgb(209 250 229 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/20 {
  --tw-gradient-to: rgb(209 250 229 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/25 {
  --tw-gradient-to: rgb(209 250 229 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/30 {
  --tw-gradient-to: rgb(209 250 229 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/35 {
  --tw-gradient-to: rgb(209 250 229 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/40 {
  --tw-gradient-to: rgb(209 250 229 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/45 {
  --tw-gradient-to: rgb(209 250 229 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/5 {
  --tw-gradient-to: rgb(209 250 229 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/50 {
  --tw-gradient-to: rgb(209 250 229 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/55 {
  --tw-gradient-to: rgb(209 250 229 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/60 {
  --tw-gradient-to: rgb(209 250 229 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/65 {
  --tw-gradient-to: rgb(209 250 229 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/70 {
  --tw-gradient-to: rgb(209 250 229 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/75 {
  --tw-gradient-to: rgb(209 250 229 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/80 {
  --tw-gradient-to: rgb(209 250 229 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/85 {
  --tw-gradient-to: rgb(209 250 229 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/90 {
  --tw-gradient-to: rgb(209 250 229 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-100\/95 {
  --tw-gradient-to: rgb(209 250 229 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200 {
  --tw-gradient-to: #a7f3d0 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/0 {
  --tw-gradient-to: rgb(167 243 208 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/10 {
  --tw-gradient-to: rgb(167 243 208 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/100 {
  --tw-gradient-to: rgb(167 243 208 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/15 {
  --tw-gradient-to: rgb(167 243 208 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/20 {
  --tw-gradient-to: rgb(167 243 208 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/25 {
  --tw-gradient-to: rgb(167 243 208 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/30 {
  --tw-gradient-to: rgb(167 243 208 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/35 {
  --tw-gradient-to: rgb(167 243 208 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/40 {
  --tw-gradient-to: rgb(167 243 208 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/45 {
  --tw-gradient-to: rgb(167 243 208 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/5 {
  --tw-gradient-to: rgb(167 243 208 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/50 {
  --tw-gradient-to: rgb(167 243 208 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/55 {
  --tw-gradient-to: rgb(167 243 208 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/60 {
  --tw-gradient-to: rgb(167 243 208 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/65 {
  --tw-gradient-to: rgb(167 243 208 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/70 {
  --tw-gradient-to: rgb(167 243 208 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/75 {
  --tw-gradient-to: rgb(167 243 208 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/80 {
  --tw-gradient-to: rgb(167 243 208 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/85 {
  --tw-gradient-to: rgb(167 243 208 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/90 {
  --tw-gradient-to: rgb(167 243 208 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-200\/95 {
  --tw-gradient-to: rgb(167 243 208 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300 {
  --tw-gradient-to: #6ee7b7 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/0 {
  --tw-gradient-to: rgb(110 231 183 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/10 {
  --tw-gradient-to: rgb(110 231 183 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/100 {
  --tw-gradient-to: rgb(110 231 183 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/15 {
  --tw-gradient-to: rgb(110 231 183 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/20 {
  --tw-gradient-to: rgb(110 231 183 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/25 {
  --tw-gradient-to: rgb(110 231 183 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/30 {
  --tw-gradient-to: rgb(110 231 183 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/35 {
  --tw-gradient-to: rgb(110 231 183 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/40 {
  --tw-gradient-to: rgb(110 231 183 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/45 {
  --tw-gradient-to: rgb(110 231 183 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/5 {
  --tw-gradient-to: rgb(110 231 183 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/50 {
  --tw-gradient-to: rgb(110 231 183 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/55 {
  --tw-gradient-to: rgb(110 231 183 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/60 {
  --tw-gradient-to: rgb(110 231 183 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/65 {
  --tw-gradient-to: rgb(110 231 183 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/70 {
  --tw-gradient-to: rgb(110 231 183 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/75 {
  --tw-gradient-to: rgb(110 231 183 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/80 {
  --tw-gradient-to: rgb(110 231 183 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/85 {
  --tw-gradient-to: rgb(110 231 183 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/90 {
  --tw-gradient-to: rgb(110 231 183 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-300\/95 {
  --tw-gradient-to: rgb(110 231 183 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400 {
  --tw-gradient-to: #34d399 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/0 {
  --tw-gradient-to: rgb(52 211 153 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/10 {
  --tw-gradient-to: rgb(52 211 153 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/100 {
  --tw-gradient-to: rgb(52 211 153 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/15 {
  --tw-gradient-to: rgb(52 211 153 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/20 {
  --tw-gradient-to: rgb(52 211 153 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/25 {
  --tw-gradient-to: rgb(52 211 153 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/30 {
  --tw-gradient-to: rgb(52 211 153 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/35 {
  --tw-gradient-to: rgb(52 211 153 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/40 {
  --tw-gradient-to: rgb(52 211 153 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/45 {
  --tw-gradient-to: rgb(52 211 153 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/5 {
  --tw-gradient-to: rgb(52 211 153 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/50 {
  --tw-gradient-to: rgb(52 211 153 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/55 {
  --tw-gradient-to: rgb(52 211 153 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/60 {
  --tw-gradient-to: rgb(52 211 153 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/65 {
  --tw-gradient-to: rgb(52 211 153 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/70 {
  --tw-gradient-to: rgb(52 211 153 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/75 {
  --tw-gradient-to: rgb(52 211 153 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/80 {
  --tw-gradient-to: rgb(52 211 153 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/85 {
  --tw-gradient-to: rgb(52 211 153 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/90 {
  --tw-gradient-to: rgb(52 211 153 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-400\/95 {
  --tw-gradient-to: rgb(52 211 153 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50 {
  --tw-gradient-to: #ecfdf5 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/0 {
  --tw-gradient-to: rgb(236 253 245 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/10 {
  --tw-gradient-to: rgb(236 253 245 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/100 {
  --tw-gradient-to: rgb(236 253 245 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/15 {
  --tw-gradient-to: rgb(236 253 245 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/20 {
  --tw-gradient-to: rgb(236 253 245 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/25 {
  --tw-gradient-to: rgb(236 253 245 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/30 {
  --tw-gradient-to: rgb(236 253 245 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/35 {
  --tw-gradient-to: rgb(236 253 245 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/40 {
  --tw-gradient-to: rgb(236 253 245 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/45 {
  --tw-gradient-to: rgb(236 253 245 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/5 {
  --tw-gradient-to: rgb(236 253 245 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/50 {
  --tw-gradient-to: rgb(236 253 245 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/55 {
  --tw-gradient-to: rgb(236 253 245 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/60 {
  --tw-gradient-to: rgb(236 253 245 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/65 {
  --tw-gradient-to: rgb(236 253 245 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/70 {
  --tw-gradient-to: rgb(236 253 245 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/75 {
  --tw-gradient-to: rgb(236 253 245 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/80 {
  --tw-gradient-to: rgb(236 253 245 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/85 {
  --tw-gradient-to: rgb(236 253 245 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/90 {
  --tw-gradient-to: rgb(236 253 245 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-50\/95 {
  --tw-gradient-to: rgb(236 253 245 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500 {
  --tw-gradient-to: #10b981 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/0 {
  --tw-gradient-to: rgb(16 185 129 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/10 {
  --tw-gradient-to: rgb(16 185 129 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/100 {
  --tw-gradient-to: rgb(16 185 129 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/15 {
  --tw-gradient-to: rgb(16 185 129 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/20 {
  --tw-gradient-to: rgb(16 185 129 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/25 {
  --tw-gradient-to: rgb(16 185 129 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/30 {
  --tw-gradient-to: rgb(16 185 129 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/35 {
  --tw-gradient-to: rgb(16 185 129 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/40 {
  --tw-gradient-to: rgb(16 185 129 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/45 {
  --tw-gradient-to: rgb(16 185 129 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/5 {
  --tw-gradient-to: rgb(16 185 129 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/50 {
  --tw-gradient-to: rgb(16 185 129 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/55 {
  --tw-gradient-to: rgb(16 185 129 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/60 {
  --tw-gradient-to: rgb(16 185 129 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/65 {
  --tw-gradient-to: rgb(16 185 129 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/70 {
  --tw-gradient-to: rgb(16 185 129 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/75 {
  --tw-gradient-to: rgb(16 185 129 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/80 {
  --tw-gradient-to: rgb(16 185 129 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/85 {
  --tw-gradient-to: rgb(16 185 129 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/90 {
  --tw-gradient-to: rgb(16 185 129 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-500\/95 {
  --tw-gradient-to: rgb(16 185 129 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600 {
  --tw-gradient-to: #059669 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/0 {
  --tw-gradient-to: rgb(5 150 105 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/10 {
  --tw-gradient-to: rgb(5 150 105 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/100 {
  --tw-gradient-to: rgb(5 150 105 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/15 {
  --tw-gradient-to: rgb(5 150 105 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/20 {
  --tw-gradient-to: rgb(5 150 105 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/25 {
  --tw-gradient-to: rgb(5 150 105 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/30 {
  --tw-gradient-to: rgb(5 150 105 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/35 {
  --tw-gradient-to: rgb(5 150 105 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/40 {
  --tw-gradient-to: rgb(5 150 105 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/45 {
  --tw-gradient-to: rgb(5 150 105 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/5 {
  --tw-gradient-to: rgb(5 150 105 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/50 {
  --tw-gradient-to: rgb(5 150 105 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/55 {
  --tw-gradient-to: rgb(5 150 105 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/60 {
  --tw-gradient-to: rgb(5 150 105 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/65 {
  --tw-gradient-to: rgb(5 150 105 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/70 {
  --tw-gradient-to: rgb(5 150 105 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/75 {
  --tw-gradient-to: rgb(5 150 105 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/80 {
  --tw-gradient-to: rgb(5 150 105 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/85 {
  --tw-gradient-to: rgb(5 150 105 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/90 {
  --tw-gradient-to: rgb(5 150 105 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-600\/95 {
  --tw-gradient-to: rgb(5 150 105 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700 {
  --tw-gradient-to: #047857 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/0 {
  --tw-gradient-to: rgb(4 120 87 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/10 {
  --tw-gradient-to: rgb(4 120 87 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/100 {
  --tw-gradient-to: rgb(4 120 87 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/15 {
  --tw-gradient-to: rgb(4 120 87 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/20 {
  --tw-gradient-to: rgb(4 120 87 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/25 {
  --tw-gradient-to: rgb(4 120 87 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/30 {
  --tw-gradient-to: rgb(4 120 87 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/35 {
  --tw-gradient-to: rgb(4 120 87 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/40 {
  --tw-gradient-to: rgb(4 120 87 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/45 {
  --tw-gradient-to: rgb(4 120 87 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/5 {
  --tw-gradient-to: rgb(4 120 87 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/50 {
  --tw-gradient-to: rgb(4 120 87 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/55 {
  --tw-gradient-to: rgb(4 120 87 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/60 {
  --tw-gradient-to: rgb(4 120 87 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/65 {
  --tw-gradient-to: rgb(4 120 87 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/70 {
  --tw-gradient-to: rgb(4 120 87 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/75 {
  --tw-gradient-to: rgb(4 120 87 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/80 {
  --tw-gradient-to: rgb(4 120 87 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/85 {
  --tw-gradient-to: rgb(4 120 87 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/90 {
  --tw-gradient-to: rgb(4 120 87 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-700\/95 {
  --tw-gradient-to: rgb(4 120 87 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800 {
  --tw-gradient-to: #065f46 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/0 {
  --tw-gradient-to: rgb(6 95 70 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/10 {
  --tw-gradient-to: rgb(6 95 70 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/100 {
  --tw-gradient-to: rgb(6 95 70 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/15 {
  --tw-gradient-to: rgb(6 95 70 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/20 {
  --tw-gradient-to: rgb(6 95 70 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/25 {
  --tw-gradient-to: rgb(6 95 70 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/30 {
  --tw-gradient-to: rgb(6 95 70 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/35 {
  --tw-gradient-to: rgb(6 95 70 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/40 {
  --tw-gradient-to: rgb(6 95 70 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/45 {
  --tw-gradient-to: rgb(6 95 70 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/5 {
  --tw-gradient-to: rgb(6 95 70 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/50 {
  --tw-gradient-to: rgb(6 95 70 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/55 {
  --tw-gradient-to: rgb(6 95 70 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/60 {
  --tw-gradient-to: rgb(6 95 70 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/65 {
  --tw-gradient-to: rgb(6 95 70 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/70 {
  --tw-gradient-to: rgb(6 95 70 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/75 {
  --tw-gradient-to: rgb(6 95 70 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/80 {
  --tw-gradient-to: rgb(6 95 70 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/85 {
  --tw-gradient-to: rgb(6 95 70 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/90 {
  --tw-gradient-to: rgb(6 95 70 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-800\/95 {
  --tw-gradient-to: rgb(6 95 70 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900 {
  --tw-gradient-to: #064e3b var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/0 {
  --tw-gradient-to: rgb(6 78 59 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/10 {
  --tw-gradient-to: rgb(6 78 59 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/100 {
  --tw-gradient-to: rgb(6 78 59 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/15 {
  --tw-gradient-to: rgb(6 78 59 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/20 {
  --tw-gradient-to: rgb(6 78 59 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/25 {
  --tw-gradient-to: rgb(6 78 59 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/30 {
  --tw-gradient-to: rgb(6 78 59 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/35 {
  --tw-gradient-to: rgb(6 78 59 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/40 {
  --tw-gradient-to: rgb(6 78 59 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/45 {
  --tw-gradient-to: rgb(6 78 59 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/5 {
  --tw-gradient-to: rgb(6 78 59 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/50 {
  --tw-gradient-to: rgb(6 78 59 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/55 {
  --tw-gradient-to: rgb(6 78 59 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/60 {
  --tw-gradient-to: rgb(6 78 59 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/65 {
  --tw-gradient-to: rgb(6 78 59 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/70 {
  --tw-gradient-to: rgb(6 78 59 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/75 {
  --tw-gradient-to: rgb(6 78 59 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/80 {
  --tw-gradient-to: rgb(6 78 59 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/85 {
  --tw-gradient-to: rgb(6 78 59 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/90 {
  --tw-gradient-to: rgb(6 78 59 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-900\/95 {
  --tw-gradient-to: rgb(6 78 59 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950 {
  --tw-gradient-to: #022c22 var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/0 {
  --tw-gradient-to: rgb(2 44 34 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/10 {
  --tw-gradient-to: rgb(2 44 34 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/100 {
  --tw-gradient-to: rgb(2 44 34 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/15 {
  --tw-gradient-to: rgb(2 44 34 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/20 {
  --tw-gradient-to: rgb(2 44 34 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/25 {
  --tw-gradient-to: rgb(2 44 34 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/30 {
  --tw-gradient-to: rgb(2 44 34 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/35 {
  --tw-gradient-to: rgb(2 44 34 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/40 {
  --tw-gradient-to: rgb(2 44 34 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/45 {
  --tw-gradient-to: rgb(2 44 34 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/5 {
  --tw-gradient-to: rgb(2 44 34 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/50 {
  --tw-gradient-to: rgb(2 44 34 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/55 {
  --tw-gradient-to: rgb(2 44 34 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/60 {
  --tw-gradient-to: rgb(2 44 34 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/65 {
  --tw-gradient-to: rgb(2 44 34 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/70 {
  --tw-gradient-to: rgb(2 44 34 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/75 {
  --tw-gradient-to: rgb(2 44 34 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/80 {
  --tw-gradient-to: rgb(2 44 34 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/85 {
  --tw-gradient-to: rgb(2 44 34 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/90 {
  --tw-gradient-to: rgb(2 44 34 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-emerald-950\/95 {
  --tw-gradient-to: rgb(2 44 34 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100 {
  --tw-gradient-to: #fae8ff var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/0 {
  --tw-gradient-to: rgb(250 232 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/10 {
  --tw-gradient-to: rgb(250 232 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/100 {
  --tw-gradient-to: rgb(250 232 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/15 {
  --tw-gradient-to: rgb(250 232 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/20 {
  --tw-gradient-to: rgb(250 232 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/25 {
  --tw-gradient-to: rgb(250 232 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/30 {
  --tw-gradient-to: rgb(250 232 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/35 {
  --tw-gradient-to: rgb(250 232 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/40 {
  --tw-gradient-to: rgb(250 232 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/45 {
  --tw-gradient-to: rgb(250 232 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/5 {
  --tw-gradient-to: rgb(250 232 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/50 {
  --tw-gradient-to: rgb(250 232 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/55 {
  --tw-gradient-to: rgb(250 232 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/60 {
  --tw-gradient-to: rgb(250 232 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/65 {
  --tw-gradient-to: rgb(250 232 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/70 {
  --tw-gradient-to: rgb(250 232 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/75 {
  --tw-gradient-to: rgb(250 232 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/80 {
  --tw-gradient-to: rgb(250 232 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/85 {
  --tw-gradient-to: rgb(250 232 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/90 {
  --tw-gradient-to: rgb(250 232 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-100\/95 {
  --tw-gradient-to: rgb(250 232 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200 {
  --tw-gradient-to: #f5d0fe var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/0 {
  --tw-gradient-to: rgb(245 208 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/10 {
  --tw-gradient-to: rgb(245 208 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/100 {
  --tw-gradient-to: rgb(245 208 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/15 {
  --tw-gradient-to: rgb(245 208 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/20 {
  --tw-gradient-to: rgb(245 208 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/25 {
  --tw-gradient-to: rgb(245 208 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/30 {
  --tw-gradient-to: rgb(245 208 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/35 {
  --tw-gradient-to: rgb(245 208 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/40 {
  --tw-gradient-to: rgb(245 208 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/45 {
  --tw-gradient-to: rgb(245 208 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/5 {
  --tw-gradient-to: rgb(245 208 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/50 {
  --tw-gradient-to: rgb(245 208 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/55 {
  --tw-gradient-to: rgb(245 208 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/60 {
  --tw-gradient-to: rgb(245 208 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/65 {
  --tw-gradient-to: rgb(245 208 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/70 {
  --tw-gradient-to: rgb(245 208 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/75 {
  --tw-gradient-to: rgb(245 208 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/80 {
  --tw-gradient-to: rgb(245 208 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/85 {
  --tw-gradient-to: rgb(245 208 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/90 {
  --tw-gradient-to: rgb(245 208 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-200\/95 {
  --tw-gradient-to: rgb(245 208 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300 {
  --tw-gradient-to: #f0abfc var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/0 {
  --tw-gradient-to: rgb(240 171 252 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/10 {
  --tw-gradient-to: rgb(240 171 252 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/100 {
  --tw-gradient-to: rgb(240 171 252 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/15 {
  --tw-gradient-to: rgb(240 171 252 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/20 {
  --tw-gradient-to: rgb(240 171 252 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/25 {
  --tw-gradient-to: rgb(240 171 252 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/30 {
  --tw-gradient-to: rgb(240 171 252 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/35 {
  --tw-gradient-to: rgb(240 171 252 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/40 {
  --tw-gradient-to: rgb(240 171 252 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/45 {
  --tw-gradient-to: rgb(240 171 252 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/5 {
  --tw-gradient-to: rgb(240 171 252 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/50 {
  --tw-gradient-to: rgb(240 171 252 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/55 {
  --tw-gradient-to: rgb(240 171 252 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/60 {
  --tw-gradient-to: rgb(240 171 252 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/65 {
  --tw-gradient-to: rgb(240 171 252 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/70 {
  --tw-gradient-to: rgb(240 171 252 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/75 {
  --tw-gradient-to: rgb(240 171 252 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/80 {
  --tw-gradient-to: rgb(240 171 252 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/85 {
  --tw-gradient-to: rgb(240 171 252 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/90 {
  --tw-gradient-to: rgb(240 171 252 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-300\/95 {
  --tw-gradient-to: rgb(240 171 252 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400 {
  --tw-gradient-to: #e879f9 var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/0 {
  --tw-gradient-to: rgb(232 121 249 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/10 {
  --tw-gradient-to: rgb(232 121 249 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/100 {
  --tw-gradient-to: rgb(232 121 249 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/15 {
  --tw-gradient-to: rgb(232 121 249 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/20 {
  --tw-gradient-to: rgb(232 121 249 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/25 {
  --tw-gradient-to: rgb(232 121 249 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/30 {
  --tw-gradient-to: rgb(232 121 249 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/35 {
  --tw-gradient-to: rgb(232 121 249 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/40 {
  --tw-gradient-to: rgb(232 121 249 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/45 {
  --tw-gradient-to: rgb(232 121 249 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/5 {
  --tw-gradient-to: rgb(232 121 249 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/50 {
  --tw-gradient-to: rgb(232 121 249 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/55 {
  --tw-gradient-to: rgb(232 121 249 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/60 {
  --tw-gradient-to: rgb(232 121 249 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/65 {
  --tw-gradient-to: rgb(232 121 249 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/70 {
  --tw-gradient-to: rgb(232 121 249 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/75 {
  --tw-gradient-to: rgb(232 121 249 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/80 {
  --tw-gradient-to: rgb(232 121 249 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/85 {
  --tw-gradient-to: rgb(232 121 249 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/90 {
  --tw-gradient-to: rgb(232 121 249 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-400\/95 {
  --tw-gradient-to: rgb(232 121 249 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50 {
  --tw-gradient-to: #fdf4ff var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/0 {
  --tw-gradient-to: rgb(253 244 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/10 {
  --tw-gradient-to: rgb(253 244 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/100 {
  --tw-gradient-to: rgb(253 244 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/15 {
  --tw-gradient-to: rgb(253 244 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/20 {
  --tw-gradient-to: rgb(253 244 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/25 {
  --tw-gradient-to: rgb(253 244 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/30 {
  --tw-gradient-to: rgb(253 244 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/35 {
  --tw-gradient-to: rgb(253 244 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/40 {
  --tw-gradient-to: rgb(253 244 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/45 {
  --tw-gradient-to: rgb(253 244 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/5 {
  --tw-gradient-to: rgb(253 244 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/50 {
  --tw-gradient-to: rgb(253 244 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/55 {
  --tw-gradient-to: rgb(253 244 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/60 {
  --tw-gradient-to: rgb(253 244 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/65 {
  --tw-gradient-to: rgb(253 244 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/70 {
  --tw-gradient-to: rgb(253 244 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/75 {
  --tw-gradient-to: rgb(253 244 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/80 {
  --tw-gradient-to: rgb(253 244 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/85 {
  --tw-gradient-to: rgb(253 244 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/90 {
  --tw-gradient-to: rgb(253 244 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-50\/95 {
  --tw-gradient-to: rgb(253 244 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500 {
  --tw-gradient-to: #d946ef var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/0 {
  --tw-gradient-to: rgb(217 70 239 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/10 {
  --tw-gradient-to: rgb(217 70 239 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/100 {
  --tw-gradient-to: rgb(217 70 239 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/15 {
  --tw-gradient-to: rgb(217 70 239 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/20 {
  --tw-gradient-to: rgb(217 70 239 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/25 {
  --tw-gradient-to: rgb(217 70 239 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/30 {
  --tw-gradient-to: rgb(217 70 239 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/35 {
  --tw-gradient-to: rgb(217 70 239 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/40 {
  --tw-gradient-to: rgb(217 70 239 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/45 {
  --tw-gradient-to: rgb(217 70 239 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/5 {
  --tw-gradient-to: rgb(217 70 239 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/50 {
  --tw-gradient-to: rgb(217 70 239 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/55 {
  --tw-gradient-to: rgb(217 70 239 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/60 {
  --tw-gradient-to: rgb(217 70 239 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/65 {
  --tw-gradient-to: rgb(217 70 239 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/70 {
  --tw-gradient-to: rgb(217 70 239 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/75 {
  --tw-gradient-to: rgb(217 70 239 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/80 {
  --tw-gradient-to: rgb(217 70 239 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/85 {
  --tw-gradient-to: rgb(217 70 239 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/90 {
  --tw-gradient-to: rgb(217 70 239 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-500\/95 {
  --tw-gradient-to: rgb(217 70 239 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600 {
  --tw-gradient-to: #c026d3 var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/0 {
  --tw-gradient-to: rgb(192 38 211 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/10 {
  --tw-gradient-to: rgb(192 38 211 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/100 {
  --tw-gradient-to: rgb(192 38 211 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/15 {
  --tw-gradient-to: rgb(192 38 211 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/20 {
  --tw-gradient-to: rgb(192 38 211 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/25 {
  --tw-gradient-to: rgb(192 38 211 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/30 {
  --tw-gradient-to: rgb(192 38 211 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/35 {
  --tw-gradient-to: rgb(192 38 211 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/40 {
  --tw-gradient-to: rgb(192 38 211 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/45 {
  --tw-gradient-to: rgb(192 38 211 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/5 {
  --tw-gradient-to: rgb(192 38 211 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/50 {
  --tw-gradient-to: rgb(192 38 211 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/55 {
  --tw-gradient-to: rgb(192 38 211 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/60 {
  --tw-gradient-to: rgb(192 38 211 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/65 {
  --tw-gradient-to: rgb(192 38 211 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/70 {
  --tw-gradient-to: rgb(192 38 211 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/75 {
  --tw-gradient-to: rgb(192 38 211 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/80 {
  --tw-gradient-to: rgb(192 38 211 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/85 {
  --tw-gradient-to: rgb(192 38 211 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/90 {
  --tw-gradient-to: rgb(192 38 211 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-600\/95 {
  --tw-gradient-to: rgb(192 38 211 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700 {
  --tw-gradient-to: #a21caf var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/0 {
  --tw-gradient-to: rgb(162 28 175 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/10 {
  --tw-gradient-to: rgb(162 28 175 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/100 {
  --tw-gradient-to: rgb(162 28 175 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/15 {
  --tw-gradient-to: rgb(162 28 175 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/20 {
  --tw-gradient-to: rgb(162 28 175 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/25 {
  --tw-gradient-to: rgb(162 28 175 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/30 {
  --tw-gradient-to: rgb(162 28 175 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/35 {
  --tw-gradient-to: rgb(162 28 175 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/40 {
  --tw-gradient-to: rgb(162 28 175 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/45 {
  --tw-gradient-to: rgb(162 28 175 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/5 {
  --tw-gradient-to: rgb(162 28 175 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/50 {
  --tw-gradient-to: rgb(162 28 175 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/55 {
  --tw-gradient-to: rgb(162 28 175 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/60 {
  --tw-gradient-to: rgb(162 28 175 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/65 {
  --tw-gradient-to: rgb(162 28 175 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/70 {
  --tw-gradient-to: rgb(162 28 175 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/75 {
  --tw-gradient-to: rgb(162 28 175 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/80 {
  --tw-gradient-to: rgb(162 28 175 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/85 {
  --tw-gradient-to: rgb(162 28 175 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/90 {
  --tw-gradient-to: rgb(162 28 175 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-700\/95 {
  --tw-gradient-to: rgb(162 28 175 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800 {
  --tw-gradient-to: #86198f var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/0 {
  --tw-gradient-to: rgb(134 25 143 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/10 {
  --tw-gradient-to: rgb(134 25 143 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/100 {
  --tw-gradient-to: rgb(134 25 143 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/15 {
  --tw-gradient-to: rgb(134 25 143 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/20 {
  --tw-gradient-to: rgb(134 25 143 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/25 {
  --tw-gradient-to: rgb(134 25 143 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/30 {
  --tw-gradient-to: rgb(134 25 143 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/35 {
  --tw-gradient-to: rgb(134 25 143 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/40 {
  --tw-gradient-to: rgb(134 25 143 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/45 {
  --tw-gradient-to: rgb(134 25 143 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/5 {
  --tw-gradient-to: rgb(134 25 143 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/50 {
  --tw-gradient-to: rgb(134 25 143 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/55 {
  --tw-gradient-to: rgb(134 25 143 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/60 {
  --tw-gradient-to: rgb(134 25 143 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/65 {
  --tw-gradient-to: rgb(134 25 143 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/70 {
  --tw-gradient-to: rgb(134 25 143 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/75 {
  --tw-gradient-to: rgb(134 25 143 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/80 {
  --tw-gradient-to: rgb(134 25 143 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/85 {
  --tw-gradient-to: rgb(134 25 143 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/90 {
  --tw-gradient-to: rgb(134 25 143 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-800\/95 {
  --tw-gradient-to: rgb(134 25 143 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900 {
  --tw-gradient-to: #701a75 var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/0 {
  --tw-gradient-to: rgb(112 26 117 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/10 {
  --tw-gradient-to: rgb(112 26 117 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/100 {
  --tw-gradient-to: rgb(112 26 117 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/15 {
  --tw-gradient-to: rgb(112 26 117 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/20 {
  --tw-gradient-to: rgb(112 26 117 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/25 {
  --tw-gradient-to: rgb(112 26 117 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/30 {
  --tw-gradient-to: rgb(112 26 117 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/35 {
  --tw-gradient-to: rgb(112 26 117 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/40 {
  --tw-gradient-to: rgb(112 26 117 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/45 {
  --tw-gradient-to: rgb(112 26 117 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/5 {
  --tw-gradient-to: rgb(112 26 117 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/50 {
  --tw-gradient-to: rgb(112 26 117 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/55 {
  --tw-gradient-to: rgb(112 26 117 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/60 {
  --tw-gradient-to: rgb(112 26 117 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/65 {
  --tw-gradient-to: rgb(112 26 117 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/70 {
  --tw-gradient-to: rgb(112 26 117 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/75 {
  --tw-gradient-to: rgb(112 26 117 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/80 {
  --tw-gradient-to: rgb(112 26 117 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/85 {
  --tw-gradient-to: rgb(112 26 117 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/90 {
  --tw-gradient-to: rgb(112 26 117 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-900\/95 {
  --tw-gradient-to: rgb(112 26 117 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950 {
  --tw-gradient-to: #4a044e var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/0 {
  --tw-gradient-to: rgb(74 4 78 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/10 {
  --tw-gradient-to: rgb(74 4 78 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/100 {
  --tw-gradient-to: rgb(74 4 78 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/15 {
  --tw-gradient-to: rgb(74 4 78 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/20 {
  --tw-gradient-to: rgb(74 4 78 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/25 {
  --tw-gradient-to: rgb(74 4 78 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/30 {
  --tw-gradient-to: rgb(74 4 78 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/35 {
  --tw-gradient-to: rgb(74 4 78 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/40 {
  --tw-gradient-to: rgb(74 4 78 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/45 {
  --tw-gradient-to: rgb(74 4 78 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/5 {
  --tw-gradient-to: rgb(74 4 78 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/50 {
  --tw-gradient-to: rgb(74 4 78 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/55 {
  --tw-gradient-to: rgb(74 4 78 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/60 {
  --tw-gradient-to: rgb(74 4 78 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/65 {
  --tw-gradient-to: rgb(74 4 78 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/70 {
  --tw-gradient-to: rgb(74 4 78 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/75 {
  --tw-gradient-to: rgb(74 4 78 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/80 {
  --tw-gradient-to: rgb(74 4 78 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/85 {
  --tw-gradient-to: rgb(74 4 78 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/90 {
  --tw-gradient-to: rgb(74 4 78 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-fuchsia-950\/95 {
  --tw-gradient-to: rgb(74 4 78 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100 {
  --tw-gradient-to: #f3f4f6 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/0 {
  --tw-gradient-to: rgb(243 244 246 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/10 {
  --tw-gradient-to: rgb(243 244 246 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/100 {
  --tw-gradient-to: rgb(243 244 246 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/15 {
  --tw-gradient-to: rgb(243 244 246 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/20 {
  --tw-gradient-to: rgb(243 244 246 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/25 {
  --tw-gradient-to: rgb(243 244 246 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/30 {
  --tw-gradient-to: rgb(243 244 246 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/35 {
  --tw-gradient-to: rgb(243 244 246 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/40 {
  --tw-gradient-to: rgb(243 244 246 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/45 {
  --tw-gradient-to: rgb(243 244 246 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/5 {
  --tw-gradient-to: rgb(243 244 246 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/50 {
  --tw-gradient-to: rgb(243 244 246 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/55 {
  --tw-gradient-to: rgb(243 244 246 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/60 {
  --tw-gradient-to: rgb(243 244 246 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/65 {
  --tw-gradient-to: rgb(243 244 246 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/70 {
  --tw-gradient-to: rgb(243 244 246 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/75 {
  --tw-gradient-to: rgb(243 244 246 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/80 {
  --tw-gradient-to: rgb(243 244 246 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/85 {
  --tw-gradient-to: rgb(243 244 246 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/90 {
  --tw-gradient-to: rgb(243 244 246 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-100\/95 {
  --tw-gradient-to: rgb(243 244 246 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200 {
  --tw-gradient-to: #e5e7eb var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/0 {
  --tw-gradient-to: rgb(229 231 235 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/10 {
  --tw-gradient-to: rgb(229 231 235 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/100 {
  --tw-gradient-to: rgb(229 231 235 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/15 {
  --tw-gradient-to: rgb(229 231 235 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/20 {
  --tw-gradient-to: rgb(229 231 235 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/25 {
  --tw-gradient-to: rgb(229 231 235 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/30 {
  --tw-gradient-to: rgb(229 231 235 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/35 {
  --tw-gradient-to: rgb(229 231 235 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/40 {
  --tw-gradient-to: rgb(229 231 235 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/45 {
  --tw-gradient-to: rgb(229 231 235 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/5 {
  --tw-gradient-to: rgb(229 231 235 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/50 {
  --tw-gradient-to: rgb(229 231 235 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/55 {
  --tw-gradient-to: rgb(229 231 235 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/60 {
  --tw-gradient-to: rgb(229 231 235 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/65 {
  --tw-gradient-to: rgb(229 231 235 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/70 {
  --tw-gradient-to: rgb(229 231 235 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/75 {
  --tw-gradient-to: rgb(229 231 235 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/80 {
  --tw-gradient-to: rgb(229 231 235 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/85 {
  --tw-gradient-to: rgb(229 231 235 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/90 {
  --tw-gradient-to: rgb(229 231 235 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-200\/95 {
  --tw-gradient-to: rgb(229 231 235 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300 {
  --tw-gradient-to: #d1d5db var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/0 {
  --tw-gradient-to: rgb(209 213 219 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/10 {
  --tw-gradient-to: rgb(209 213 219 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/100 {
  --tw-gradient-to: rgb(209 213 219 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/15 {
  --tw-gradient-to: rgb(209 213 219 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/20 {
  --tw-gradient-to: rgb(209 213 219 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/25 {
  --tw-gradient-to: rgb(209 213 219 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/30 {
  --tw-gradient-to: rgb(209 213 219 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/35 {
  --tw-gradient-to: rgb(209 213 219 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/40 {
  --tw-gradient-to: rgb(209 213 219 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/45 {
  --tw-gradient-to: rgb(209 213 219 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/5 {
  --tw-gradient-to: rgb(209 213 219 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/50 {
  --tw-gradient-to: rgb(209 213 219 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/55 {
  --tw-gradient-to: rgb(209 213 219 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/60 {
  --tw-gradient-to: rgb(209 213 219 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/65 {
  --tw-gradient-to: rgb(209 213 219 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/70 {
  --tw-gradient-to: rgb(209 213 219 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/75 {
  --tw-gradient-to: rgb(209 213 219 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/80 {
  --tw-gradient-to: rgb(209 213 219 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/85 {
  --tw-gradient-to: rgb(209 213 219 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/90 {
  --tw-gradient-to: rgb(209 213 219 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-300\/95 {
  --tw-gradient-to: rgb(209 213 219 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400 {
  --tw-gradient-to: #9ca3af var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/0 {
  --tw-gradient-to: rgb(156 163 175 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/10 {
  --tw-gradient-to: rgb(156 163 175 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/100 {
  --tw-gradient-to: rgb(156 163 175 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/15 {
  --tw-gradient-to: rgb(156 163 175 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/20 {
  --tw-gradient-to: rgb(156 163 175 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/25 {
  --tw-gradient-to: rgb(156 163 175 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/30 {
  --tw-gradient-to: rgb(156 163 175 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/35 {
  --tw-gradient-to: rgb(156 163 175 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/40 {
  --tw-gradient-to: rgb(156 163 175 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/45 {
  --tw-gradient-to: rgb(156 163 175 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/5 {
  --tw-gradient-to: rgb(156 163 175 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/50 {
  --tw-gradient-to: rgb(156 163 175 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/55 {
  --tw-gradient-to: rgb(156 163 175 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/60 {
  --tw-gradient-to: rgb(156 163 175 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/65 {
  --tw-gradient-to: rgb(156 163 175 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/70 {
  --tw-gradient-to: rgb(156 163 175 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/75 {
  --tw-gradient-to: rgb(156 163 175 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/80 {
  --tw-gradient-to: rgb(156 163 175 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/85 {
  --tw-gradient-to: rgb(156 163 175 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/90 {
  --tw-gradient-to: rgb(156 163 175 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-400\/95 {
  --tw-gradient-to: rgb(156 163 175 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50 {
  --tw-gradient-to: #f9fafb var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/0 {
  --tw-gradient-to: rgb(249 250 251 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/10 {
  --tw-gradient-to: rgb(249 250 251 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/100 {
  --tw-gradient-to: rgb(249 250 251 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/15 {
  --tw-gradient-to: rgb(249 250 251 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/20 {
  --tw-gradient-to: rgb(249 250 251 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/25 {
  --tw-gradient-to: rgb(249 250 251 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/30 {
  --tw-gradient-to: rgb(249 250 251 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/35 {
  --tw-gradient-to: rgb(249 250 251 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/40 {
  --tw-gradient-to: rgb(249 250 251 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/45 {
  --tw-gradient-to: rgb(249 250 251 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/5 {
  --tw-gradient-to: rgb(249 250 251 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/50 {
  --tw-gradient-to: rgb(249 250 251 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/55 {
  --tw-gradient-to: rgb(249 250 251 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/60 {
  --tw-gradient-to: rgb(249 250 251 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/65 {
  --tw-gradient-to: rgb(249 250 251 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/70 {
  --tw-gradient-to: rgb(249 250 251 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/75 {
  --tw-gradient-to: rgb(249 250 251 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/80 {
  --tw-gradient-to: rgb(249 250 251 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/85 {
  --tw-gradient-to: rgb(249 250 251 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/90 {
  --tw-gradient-to: rgb(249 250 251 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-50\/95 {
  --tw-gradient-to: rgb(249 250 251 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500 {
  --tw-gradient-to: #6b7280 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/0 {
  --tw-gradient-to: rgb(107 114 128 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/10 {
  --tw-gradient-to: rgb(107 114 128 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/100 {
  --tw-gradient-to: rgb(107 114 128 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/15 {
  --tw-gradient-to: rgb(107 114 128 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/20 {
  --tw-gradient-to: rgb(107 114 128 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/25 {
  --tw-gradient-to: rgb(107 114 128 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/30 {
  --tw-gradient-to: rgb(107 114 128 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/35 {
  --tw-gradient-to: rgb(107 114 128 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/40 {
  --tw-gradient-to: rgb(107 114 128 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/45 {
  --tw-gradient-to: rgb(107 114 128 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/5 {
  --tw-gradient-to: rgb(107 114 128 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/50 {
  --tw-gradient-to: rgb(107 114 128 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/55 {
  --tw-gradient-to: rgb(107 114 128 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/60 {
  --tw-gradient-to: rgb(107 114 128 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/65 {
  --tw-gradient-to: rgb(107 114 128 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/70 {
  --tw-gradient-to: rgb(107 114 128 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/75 {
  --tw-gradient-to: rgb(107 114 128 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/80 {
  --tw-gradient-to: rgb(107 114 128 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/85 {
  --tw-gradient-to: rgb(107 114 128 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/90 {
  --tw-gradient-to: rgb(107 114 128 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-500\/95 {
  --tw-gradient-to: rgb(107 114 128 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600 {
  --tw-gradient-to: #4b5563 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/0 {
  --tw-gradient-to: rgb(75 85 99 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/10 {
  --tw-gradient-to: rgb(75 85 99 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/100 {
  --tw-gradient-to: rgb(75 85 99 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/15 {
  --tw-gradient-to: rgb(75 85 99 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/20 {
  --tw-gradient-to: rgb(75 85 99 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/25 {
  --tw-gradient-to: rgb(75 85 99 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/30 {
  --tw-gradient-to: rgb(75 85 99 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/35 {
  --tw-gradient-to: rgb(75 85 99 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/40 {
  --tw-gradient-to: rgb(75 85 99 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/45 {
  --tw-gradient-to: rgb(75 85 99 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/5 {
  --tw-gradient-to: rgb(75 85 99 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/50 {
  --tw-gradient-to: rgb(75 85 99 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/55 {
  --tw-gradient-to: rgb(75 85 99 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/60 {
  --tw-gradient-to: rgb(75 85 99 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/65 {
  --tw-gradient-to: rgb(75 85 99 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/70 {
  --tw-gradient-to: rgb(75 85 99 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/75 {
  --tw-gradient-to: rgb(75 85 99 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/80 {
  --tw-gradient-to: rgb(75 85 99 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/85 {
  --tw-gradient-to: rgb(75 85 99 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/90 {
  --tw-gradient-to: rgb(75 85 99 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-600\/95 {
  --tw-gradient-to: rgb(75 85 99 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700 {
  --tw-gradient-to: #374151 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/0 {
  --tw-gradient-to: rgb(55 65 81 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/10 {
  --tw-gradient-to: rgb(55 65 81 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/100 {
  --tw-gradient-to: rgb(55 65 81 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/15 {
  --tw-gradient-to: rgb(55 65 81 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/20 {
  --tw-gradient-to: rgb(55 65 81 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/25 {
  --tw-gradient-to: rgb(55 65 81 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/30 {
  --tw-gradient-to: rgb(55 65 81 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/35 {
  --tw-gradient-to: rgb(55 65 81 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/40 {
  --tw-gradient-to: rgb(55 65 81 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/45 {
  --tw-gradient-to: rgb(55 65 81 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/5 {
  --tw-gradient-to: rgb(55 65 81 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/50 {
  --tw-gradient-to: rgb(55 65 81 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/55 {
  --tw-gradient-to: rgb(55 65 81 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/60 {
  --tw-gradient-to: rgb(55 65 81 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/65 {
  --tw-gradient-to: rgb(55 65 81 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/70 {
  --tw-gradient-to: rgb(55 65 81 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/75 {
  --tw-gradient-to: rgb(55 65 81 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/80 {
  --tw-gradient-to: rgb(55 65 81 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/85 {
  --tw-gradient-to: rgb(55 65 81 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/90 {
  --tw-gradient-to: rgb(55 65 81 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-700\/95 {
  --tw-gradient-to: rgb(55 65 81 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800 {
  --tw-gradient-to: #1f2937 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/0 {
  --tw-gradient-to: rgb(31 41 55 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/10 {
  --tw-gradient-to: rgb(31 41 55 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/100 {
  --tw-gradient-to: rgb(31 41 55 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/15 {
  --tw-gradient-to: rgb(31 41 55 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/20 {
  --tw-gradient-to: rgb(31 41 55 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/25 {
  --tw-gradient-to: rgb(31 41 55 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/30 {
  --tw-gradient-to: rgb(31 41 55 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/35 {
  --tw-gradient-to: rgb(31 41 55 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/40 {
  --tw-gradient-to: rgb(31 41 55 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/45 {
  --tw-gradient-to: rgb(31 41 55 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/5 {
  --tw-gradient-to: rgb(31 41 55 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/50 {
  --tw-gradient-to: rgb(31 41 55 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/55 {
  --tw-gradient-to: rgb(31 41 55 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/60 {
  --tw-gradient-to: rgb(31 41 55 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/65 {
  --tw-gradient-to: rgb(31 41 55 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/70 {
  --tw-gradient-to: rgb(31 41 55 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/75 {
  --tw-gradient-to: rgb(31 41 55 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/80 {
  --tw-gradient-to: rgb(31 41 55 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/85 {
  --tw-gradient-to: rgb(31 41 55 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/90 {
  --tw-gradient-to: rgb(31 41 55 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-800\/95 {
  --tw-gradient-to: rgb(31 41 55 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900 {
  --tw-gradient-to: #111827 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/0 {
  --tw-gradient-to: rgb(17 24 39 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/10 {
  --tw-gradient-to: rgb(17 24 39 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/100 {
  --tw-gradient-to: rgb(17 24 39 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/15 {
  --tw-gradient-to: rgb(17 24 39 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/20 {
  --tw-gradient-to: rgb(17 24 39 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/25 {
  --tw-gradient-to: rgb(17 24 39 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/30 {
  --tw-gradient-to: rgb(17 24 39 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/35 {
  --tw-gradient-to: rgb(17 24 39 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/40 {
  --tw-gradient-to: rgb(17 24 39 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/45 {
  --tw-gradient-to: rgb(17 24 39 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/5 {
  --tw-gradient-to: rgb(17 24 39 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/50 {
  --tw-gradient-to: rgb(17 24 39 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/55 {
  --tw-gradient-to: rgb(17 24 39 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/60 {
  --tw-gradient-to: rgb(17 24 39 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/65 {
  --tw-gradient-to: rgb(17 24 39 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/70 {
  --tw-gradient-to: rgb(17 24 39 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/75 {
  --tw-gradient-to: rgb(17 24 39 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/80 {
  --tw-gradient-to: rgb(17 24 39 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/85 {
  --tw-gradient-to: rgb(17 24 39 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/90 {
  --tw-gradient-to: rgb(17 24 39 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-900\/95 {
  --tw-gradient-to: rgb(17 24 39 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950 {
  --tw-gradient-to: #030712 var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/0 {
  --tw-gradient-to: rgb(3 7 18 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/10 {
  --tw-gradient-to: rgb(3 7 18 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/100 {
  --tw-gradient-to: rgb(3 7 18 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/15 {
  --tw-gradient-to: rgb(3 7 18 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/20 {
  --tw-gradient-to: rgb(3 7 18 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/25 {
  --tw-gradient-to: rgb(3 7 18 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/30 {
  --tw-gradient-to: rgb(3 7 18 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/35 {
  --tw-gradient-to: rgb(3 7 18 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/40 {
  --tw-gradient-to: rgb(3 7 18 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/45 {
  --tw-gradient-to: rgb(3 7 18 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/5 {
  --tw-gradient-to: rgb(3 7 18 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/50 {
  --tw-gradient-to: rgb(3 7 18 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/55 {
  --tw-gradient-to: rgb(3 7 18 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/60 {
  --tw-gradient-to: rgb(3 7 18 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/65 {
  --tw-gradient-to: rgb(3 7 18 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/70 {
  --tw-gradient-to: rgb(3 7 18 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/75 {
  --tw-gradient-to: rgb(3 7 18 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/80 {
  --tw-gradient-to: rgb(3 7 18 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/85 {
  --tw-gradient-to: rgb(3 7 18 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/90 {
  --tw-gradient-to: rgb(3 7 18 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-gray-950\/95 {
  --tw-gradient-to: rgb(3 7 18 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100 {
  --tw-gradient-to: #dcfce7 var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/0 {
  --tw-gradient-to: rgb(220 252 231 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/10 {
  --tw-gradient-to: rgb(220 252 231 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/100 {
  --tw-gradient-to: rgb(220 252 231 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/15 {
  --tw-gradient-to: rgb(220 252 231 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/20 {
  --tw-gradient-to: rgb(220 252 231 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/25 {
  --tw-gradient-to: rgb(220 252 231 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/30 {
  --tw-gradient-to: rgb(220 252 231 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/35 {
  --tw-gradient-to: rgb(220 252 231 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/40 {
  --tw-gradient-to: rgb(220 252 231 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/45 {
  --tw-gradient-to: rgb(220 252 231 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/5 {
  --tw-gradient-to: rgb(220 252 231 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/50 {
  --tw-gradient-to: rgb(220 252 231 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/55 {
  --tw-gradient-to: rgb(220 252 231 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/60 {
  --tw-gradient-to: rgb(220 252 231 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/65 {
  --tw-gradient-to: rgb(220 252 231 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/70 {
  --tw-gradient-to: rgb(220 252 231 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/75 {
  --tw-gradient-to: rgb(220 252 231 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/80 {
  --tw-gradient-to: rgb(220 252 231 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/85 {
  --tw-gradient-to: rgb(220 252 231 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/90 {
  --tw-gradient-to: rgb(220 252 231 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-100\/95 {
  --tw-gradient-to: rgb(220 252 231 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200 {
  --tw-gradient-to: #bbf7d0 var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/0 {
  --tw-gradient-to: rgb(187 247 208 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/10 {
  --tw-gradient-to: rgb(187 247 208 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/100 {
  --tw-gradient-to: rgb(187 247 208 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/15 {
  --tw-gradient-to: rgb(187 247 208 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/20 {
  --tw-gradient-to: rgb(187 247 208 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/25 {
  --tw-gradient-to: rgb(187 247 208 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/30 {
  --tw-gradient-to: rgb(187 247 208 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/35 {
  --tw-gradient-to: rgb(187 247 208 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/40 {
  --tw-gradient-to: rgb(187 247 208 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/45 {
  --tw-gradient-to: rgb(187 247 208 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/5 {
  --tw-gradient-to: rgb(187 247 208 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/50 {
  --tw-gradient-to: rgb(187 247 208 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/55 {
  --tw-gradient-to: rgb(187 247 208 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/60 {
  --tw-gradient-to: rgb(187 247 208 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/65 {
  --tw-gradient-to: rgb(187 247 208 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/70 {
  --tw-gradient-to: rgb(187 247 208 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/75 {
  --tw-gradient-to: rgb(187 247 208 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/80 {
  --tw-gradient-to: rgb(187 247 208 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/85 {
  --tw-gradient-to: rgb(187 247 208 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/90 {
  --tw-gradient-to: rgb(187 247 208 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-200\/95 {
  --tw-gradient-to: rgb(187 247 208 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300 {
  --tw-gradient-to: #86efac var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/0 {
  --tw-gradient-to: rgb(134 239 172 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/10 {
  --tw-gradient-to: rgb(134 239 172 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/100 {
  --tw-gradient-to: rgb(134 239 172 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/15 {
  --tw-gradient-to: rgb(134 239 172 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/20 {
  --tw-gradient-to: rgb(134 239 172 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/25 {
  --tw-gradient-to: rgb(134 239 172 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/30 {
  --tw-gradient-to: rgb(134 239 172 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/35 {
  --tw-gradient-to: rgb(134 239 172 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/40 {
  --tw-gradient-to: rgb(134 239 172 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/45 {
  --tw-gradient-to: rgb(134 239 172 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/5 {
  --tw-gradient-to: rgb(134 239 172 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/50 {
  --tw-gradient-to: rgb(134 239 172 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/55 {
  --tw-gradient-to: rgb(134 239 172 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/60 {
  --tw-gradient-to: rgb(134 239 172 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/65 {
  --tw-gradient-to: rgb(134 239 172 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/70 {
  --tw-gradient-to: rgb(134 239 172 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/75 {
  --tw-gradient-to: rgb(134 239 172 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/80 {
  --tw-gradient-to: rgb(134 239 172 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/85 {
  --tw-gradient-to: rgb(134 239 172 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/90 {
  --tw-gradient-to: rgb(134 239 172 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-300\/95 {
  --tw-gradient-to: rgb(134 239 172 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400 {
  --tw-gradient-to: #4ade80 var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/0 {
  --tw-gradient-to: rgb(74 222 128 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/10 {
  --tw-gradient-to: rgb(74 222 128 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/100 {
  --tw-gradient-to: rgb(74 222 128 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/15 {
  --tw-gradient-to: rgb(74 222 128 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/20 {
  --tw-gradient-to: rgb(74 222 128 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/25 {
  --tw-gradient-to: rgb(74 222 128 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/30 {
  --tw-gradient-to: rgb(74 222 128 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/35 {
  --tw-gradient-to: rgb(74 222 128 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/40 {
  --tw-gradient-to: rgb(74 222 128 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/45 {
  --tw-gradient-to: rgb(74 222 128 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/5 {
  --tw-gradient-to: rgb(74 222 128 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/50 {
  --tw-gradient-to: rgb(74 222 128 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/55 {
  --tw-gradient-to: rgb(74 222 128 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/60 {
  --tw-gradient-to: rgb(74 222 128 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/65 {
  --tw-gradient-to: rgb(74 222 128 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/70 {
  --tw-gradient-to: rgb(74 222 128 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/75 {
  --tw-gradient-to: rgb(74 222 128 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/80 {
  --tw-gradient-to: rgb(74 222 128 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/85 {
  --tw-gradient-to: rgb(74 222 128 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/90 {
  --tw-gradient-to: rgb(74 222 128 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-400\/95 {
  --tw-gradient-to: rgb(74 222 128 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50 {
  --tw-gradient-to: #f0fdf4 var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/0 {
  --tw-gradient-to: rgb(240 253 244 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/10 {
  --tw-gradient-to: rgb(240 253 244 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/100 {
  --tw-gradient-to: rgb(240 253 244 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/15 {
  --tw-gradient-to: rgb(240 253 244 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/20 {
  --tw-gradient-to: rgb(240 253 244 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/25 {
  --tw-gradient-to: rgb(240 253 244 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/30 {
  --tw-gradient-to: rgb(240 253 244 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/35 {
  --tw-gradient-to: rgb(240 253 244 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/40 {
  --tw-gradient-to: rgb(240 253 244 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/45 {
  --tw-gradient-to: rgb(240 253 244 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/5 {
  --tw-gradient-to: rgb(240 253 244 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/50 {
  --tw-gradient-to: rgb(240 253 244 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/55 {
  --tw-gradient-to: rgb(240 253 244 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/60 {
  --tw-gradient-to: rgb(240 253 244 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/65 {
  --tw-gradient-to: rgb(240 253 244 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/70 {
  --tw-gradient-to: rgb(240 253 244 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/75 {
  --tw-gradient-to: rgb(240 253 244 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/80 {
  --tw-gradient-to: rgb(240 253 244 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/85 {
  --tw-gradient-to: rgb(240 253 244 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/90 {
  --tw-gradient-to: rgb(240 253 244 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-50\/95 {
  --tw-gradient-to: rgb(240 253 244 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500 {
  --tw-gradient-to: #22c55e var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/0 {
  --tw-gradient-to: rgb(34 197 94 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/10 {
  --tw-gradient-to: rgb(34 197 94 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/100 {
  --tw-gradient-to: rgb(34 197 94 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/15 {
  --tw-gradient-to: rgb(34 197 94 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/20 {
  --tw-gradient-to: rgb(34 197 94 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/25 {
  --tw-gradient-to: rgb(34 197 94 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/30 {
  --tw-gradient-to: rgb(34 197 94 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/35 {
  --tw-gradient-to: rgb(34 197 94 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/40 {
  --tw-gradient-to: rgb(34 197 94 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/45 {
  --tw-gradient-to: rgb(34 197 94 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/5 {
  --tw-gradient-to: rgb(34 197 94 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/50 {
  --tw-gradient-to: rgb(34 197 94 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/55 {
  --tw-gradient-to: rgb(34 197 94 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/60 {
  --tw-gradient-to: rgb(34 197 94 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/65 {
  --tw-gradient-to: rgb(34 197 94 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/70 {
  --tw-gradient-to: rgb(34 197 94 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/75 {
  --tw-gradient-to: rgb(34 197 94 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/80 {
  --tw-gradient-to: rgb(34 197 94 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/85 {
  --tw-gradient-to: rgb(34 197 94 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/90 {
  --tw-gradient-to: rgb(34 197 94 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-500\/95 {
  --tw-gradient-to: rgb(34 197 94 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600 {
  --tw-gradient-to: #16a34a var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/0 {
  --tw-gradient-to: rgb(22 163 74 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/10 {
  --tw-gradient-to: rgb(22 163 74 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/100 {
  --tw-gradient-to: rgb(22 163 74 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/15 {
  --tw-gradient-to: rgb(22 163 74 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/20 {
  --tw-gradient-to: rgb(22 163 74 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/25 {
  --tw-gradient-to: rgb(22 163 74 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/30 {
  --tw-gradient-to: rgb(22 163 74 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/35 {
  --tw-gradient-to: rgb(22 163 74 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/40 {
  --tw-gradient-to: rgb(22 163 74 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/45 {
  --tw-gradient-to: rgb(22 163 74 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/5 {
  --tw-gradient-to: rgb(22 163 74 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/50 {
  --tw-gradient-to: rgb(22 163 74 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/55 {
  --tw-gradient-to: rgb(22 163 74 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/60 {
  --tw-gradient-to: rgb(22 163 74 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/65 {
  --tw-gradient-to: rgb(22 163 74 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/70 {
  --tw-gradient-to: rgb(22 163 74 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/75 {
  --tw-gradient-to: rgb(22 163 74 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/80 {
  --tw-gradient-to: rgb(22 163 74 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/85 {
  --tw-gradient-to: rgb(22 163 74 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/90 {
  --tw-gradient-to: rgb(22 163 74 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-600\/95 {
  --tw-gradient-to: rgb(22 163 74 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700 {
  --tw-gradient-to: #15803d var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/0 {
  --tw-gradient-to: rgb(21 128 61 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/10 {
  --tw-gradient-to: rgb(21 128 61 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/100 {
  --tw-gradient-to: rgb(21 128 61 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/15 {
  --tw-gradient-to: rgb(21 128 61 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/20 {
  --tw-gradient-to: rgb(21 128 61 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/25 {
  --tw-gradient-to: rgb(21 128 61 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/30 {
  --tw-gradient-to: rgb(21 128 61 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/35 {
  --tw-gradient-to: rgb(21 128 61 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/40 {
  --tw-gradient-to: rgb(21 128 61 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/45 {
  --tw-gradient-to: rgb(21 128 61 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/5 {
  --tw-gradient-to: rgb(21 128 61 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/50 {
  --tw-gradient-to: rgb(21 128 61 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/55 {
  --tw-gradient-to: rgb(21 128 61 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/60 {
  --tw-gradient-to: rgb(21 128 61 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/65 {
  --tw-gradient-to: rgb(21 128 61 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/70 {
  --tw-gradient-to: rgb(21 128 61 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/75 {
  --tw-gradient-to: rgb(21 128 61 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/80 {
  --tw-gradient-to: rgb(21 128 61 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/85 {
  --tw-gradient-to: rgb(21 128 61 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/90 {
  --tw-gradient-to: rgb(21 128 61 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-700\/95 {
  --tw-gradient-to: rgb(21 128 61 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800 {
  --tw-gradient-to: #166534 var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/0 {
  --tw-gradient-to: rgb(22 101 52 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/10 {
  --tw-gradient-to: rgb(22 101 52 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/100 {
  --tw-gradient-to: rgb(22 101 52 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/15 {
  --tw-gradient-to: rgb(22 101 52 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/20 {
  --tw-gradient-to: rgb(22 101 52 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/25 {
  --tw-gradient-to: rgb(22 101 52 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/30 {
  --tw-gradient-to: rgb(22 101 52 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/35 {
  --tw-gradient-to: rgb(22 101 52 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/40 {
  --tw-gradient-to: rgb(22 101 52 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/45 {
  --tw-gradient-to: rgb(22 101 52 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/5 {
  --tw-gradient-to: rgb(22 101 52 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/50 {
  --tw-gradient-to: rgb(22 101 52 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/55 {
  --tw-gradient-to: rgb(22 101 52 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/60 {
  --tw-gradient-to: rgb(22 101 52 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/65 {
  --tw-gradient-to: rgb(22 101 52 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/70 {
  --tw-gradient-to: rgb(22 101 52 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/75 {
  --tw-gradient-to: rgb(22 101 52 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/80 {
  --tw-gradient-to: rgb(22 101 52 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/85 {
  --tw-gradient-to: rgb(22 101 52 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/90 {
  --tw-gradient-to: rgb(22 101 52 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-800\/95 {
  --tw-gradient-to: rgb(22 101 52 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900 {
  --tw-gradient-to: #14532d var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/0 {
  --tw-gradient-to: rgb(20 83 45 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/10 {
  --tw-gradient-to: rgb(20 83 45 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/100 {
  --tw-gradient-to: rgb(20 83 45 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/15 {
  --tw-gradient-to: rgb(20 83 45 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/20 {
  --tw-gradient-to: rgb(20 83 45 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/25 {
  --tw-gradient-to: rgb(20 83 45 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/30 {
  --tw-gradient-to: rgb(20 83 45 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/35 {
  --tw-gradient-to: rgb(20 83 45 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/40 {
  --tw-gradient-to: rgb(20 83 45 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/45 {
  --tw-gradient-to: rgb(20 83 45 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/5 {
  --tw-gradient-to: rgb(20 83 45 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/50 {
  --tw-gradient-to: rgb(20 83 45 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/55 {
  --tw-gradient-to: rgb(20 83 45 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/60 {
  --tw-gradient-to: rgb(20 83 45 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/65 {
  --tw-gradient-to: rgb(20 83 45 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/70 {
  --tw-gradient-to: rgb(20 83 45 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/75 {
  --tw-gradient-to: rgb(20 83 45 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/80 {
  --tw-gradient-to: rgb(20 83 45 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/85 {
  --tw-gradient-to: rgb(20 83 45 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/90 {
  --tw-gradient-to: rgb(20 83 45 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-900\/95 {
  --tw-gradient-to: rgb(20 83 45 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950 {
  --tw-gradient-to: #052e16 var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/0 {
  --tw-gradient-to: rgb(5 46 22 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/10 {
  --tw-gradient-to: rgb(5 46 22 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/100 {
  --tw-gradient-to: rgb(5 46 22 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/15 {
  --tw-gradient-to: rgb(5 46 22 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/20 {
  --tw-gradient-to: rgb(5 46 22 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/25 {
  --tw-gradient-to: rgb(5 46 22 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/30 {
  --tw-gradient-to: rgb(5 46 22 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/35 {
  --tw-gradient-to: rgb(5 46 22 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/40 {
  --tw-gradient-to: rgb(5 46 22 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/45 {
  --tw-gradient-to: rgb(5 46 22 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/5 {
  --tw-gradient-to: rgb(5 46 22 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/50 {
  --tw-gradient-to: rgb(5 46 22 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/55 {
  --tw-gradient-to: rgb(5 46 22 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/60 {
  --tw-gradient-to: rgb(5 46 22 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/65 {
  --tw-gradient-to: rgb(5 46 22 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/70 {
  --tw-gradient-to: rgb(5 46 22 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/75 {
  --tw-gradient-to: rgb(5 46 22 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/80 {
  --tw-gradient-to: rgb(5 46 22 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/85 {
  --tw-gradient-to: rgb(5 46 22 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/90 {
  --tw-gradient-to: rgb(5 46 22 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-green-950\/95 {
  --tw-gradient-to: rgb(5 46 22 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100 {
  --tw-gradient-to: #e0e7ff var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/0 {
  --tw-gradient-to: rgb(224 231 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/10 {
  --tw-gradient-to: rgb(224 231 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/100 {
  --tw-gradient-to: rgb(224 231 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/15 {
  --tw-gradient-to: rgb(224 231 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/20 {
  --tw-gradient-to: rgb(224 231 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/25 {
  --tw-gradient-to: rgb(224 231 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/30 {
  --tw-gradient-to: rgb(224 231 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/35 {
  --tw-gradient-to: rgb(224 231 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/40 {
  --tw-gradient-to: rgb(224 231 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/45 {
  --tw-gradient-to: rgb(224 231 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/5 {
  --tw-gradient-to: rgb(224 231 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/50 {
  --tw-gradient-to: rgb(224 231 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/55 {
  --tw-gradient-to: rgb(224 231 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/60 {
  --tw-gradient-to: rgb(224 231 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/65 {
  --tw-gradient-to: rgb(224 231 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/70 {
  --tw-gradient-to: rgb(224 231 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/75 {
  --tw-gradient-to: rgb(224 231 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/80 {
  --tw-gradient-to: rgb(224 231 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/85 {
  --tw-gradient-to: rgb(224 231 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/90 {
  --tw-gradient-to: rgb(224 231 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-100\/95 {
  --tw-gradient-to: rgb(224 231 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200 {
  --tw-gradient-to: #c7d2fe var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/0 {
  --tw-gradient-to: rgb(199 210 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/10 {
  --tw-gradient-to: rgb(199 210 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/100 {
  --tw-gradient-to: rgb(199 210 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/15 {
  --tw-gradient-to: rgb(199 210 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/20 {
  --tw-gradient-to: rgb(199 210 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/25 {
  --tw-gradient-to: rgb(199 210 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/30 {
  --tw-gradient-to: rgb(199 210 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/35 {
  --tw-gradient-to: rgb(199 210 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/40 {
  --tw-gradient-to: rgb(199 210 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/45 {
  --tw-gradient-to: rgb(199 210 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/5 {
  --tw-gradient-to: rgb(199 210 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/50 {
  --tw-gradient-to: rgb(199 210 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/55 {
  --tw-gradient-to: rgb(199 210 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/60 {
  --tw-gradient-to: rgb(199 210 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/65 {
  --tw-gradient-to: rgb(199 210 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/70 {
  --tw-gradient-to: rgb(199 210 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/75 {
  --tw-gradient-to: rgb(199 210 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/80 {
  --tw-gradient-to: rgb(199 210 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/85 {
  --tw-gradient-to: rgb(199 210 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/90 {
  --tw-gradient-to: rgb(199 210 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-200\/95 {
  --tw-gradient-to: rgb(199 210 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300 {
  --tw-gradient-to: #a5b4fc var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/0 {
  --tw-gradient-to: rgb(165 180 252 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/10 {
  --tw-gradient-to: rgb(165 180 252 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/100 {
  --tw-gradient-to: rgb(165 180 252 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/15 {
  --tw-gradient-to: rgb(165 180 252 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/20 {
  --tw-gradient-to: rgb(165 180 252 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/25 {
  --tw-gradient-to: rgb(165 180 252 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/30 {
  --tw-gradient-to: rgb(165 180 252 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/35 {
  --tw-gradient-to: rgb(165 180 252 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/40 {
  --tw-gradient-to: rgb(165 180 252 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/45 {
  --tw-gradient-to: rgb(165 180 252 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/5 {
  --tw-gradient-to: rgb(165 180 252 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/50 {
  --tw-gradient-to: rgb(165 180 252 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/55 {
  --tw-gradient-to: rgb(165 180 252 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/60 {
  --tw-gradient-to: rgb(165 180 252 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/65 {
  --tw-gradient-to: rgb(165 180 252 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/70 {
  --tw-gradient-to: rgb(165 180 252 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/75 {
  --tw-gradient-to: rgb(165 180 252 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/80 {
  --tw-gradient-to: rgb(165 180 252 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/85 {
  --tw-gradient-to: rgb(165 180 252 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/90 {
  --tw-gradient-to: rgb(165 180 252 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-300\/95 {
  --tw-gradient-to: rgb(165 180 252 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400 {
  --tw-gradient-to: #818cf8 var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/0 {
  --tw-gradient-to: rgb(129 140 248 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/10 {
  --tw-gradient-to: rgb(129 140 248 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/100 {
  --tw-gradient-to: rgb(129 140 248 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/15 {
  --tw-gradient-to: rgb(129 140 248 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/20 {
  --tw-gradient-to: rgb(129 140 248 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/25 {
  --tw-gradient-to: rgb(129 140 248 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/30 {
  --tw-gradient-to: rgb(129 140 248 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/35 {
  --tw-gradient-to: rgb(129 140 248 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/40 {
  --tw-gradient-to: rgb(129 140 248 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/45 {
  --tw-gradient-to: rgb(129 140 248 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/5 {
  --tw-gradient-to: rgb(129 140 248 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/50 {
  --tw-gradient-to: rgb(129 140 248 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/55 {
  --tw-gradient-to: rgb(129 140 248 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/60 {
  --tw-gradient-to: rgb(129 140 248 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/65 {
  --tw-gradient-to: rgb(129 140 248 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/70 {
  --tw-gradient-to: rgb(129 140 248 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/75 {
  --tw-gradient-to: rgb(129 140 248 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/80 {
  --tw-gradient-to: rgb(129 140 248 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/85 {
  --tw-gradient-to: rgb(129 140 248 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/90 {
  --tw-gradient-to: rgb(129 140 248 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-400\/95 {
  --tw-gradient-to: rgb(129 140 248 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50 {
  --tw-gradient-to: #eef2ff var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/0 {
  --tw-gradient-to: rgb(238 242 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/10 {
  --tw-gradient-to: rgb(238 242 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/100 {
  --tw-gradient-to: rgb(238 242 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/15 {
  --tw-gradient-to: rgb(238 242 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/20 {
  --tw-gradient-to: rgb(238 242 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/25 {
  --tw-gradient-to: rgb(238 242 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/30 {
  --tw-gradient-to: rgb(238 242 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/35 {
  --tw-gradient-to: rgb(238 242 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/40 {
  --tw-gradient-to: rgb(238 242 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/45 {
  --tw-gradient-to: rgb(238 242 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/5 {
  --tw-gradient-to: rgb(238 242 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/50 {
  --tw-gradient-to: rgb(238 242 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/55 {
  --tw-gradient-to: rgb(238 242 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/60 {
  --tw-gradient-to: rgb(238 242 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/65 {
  --tw-gradient-to: rgb(238 242 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/70 {
  --tw-gradient-to: rgb(238 242 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/75 {
  --tw-gradient-to: rgb(238 242 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/80 {
  --tw-gradient-to: rgb(238 242 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/85 {
  --tw-gradient-to: rgb(238 242 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/90 {
  --tw-gradient-to: rgb(238 242 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-50\/95 {
  --tw-gradient-to: rgb(238 242 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500 {
  --tw-gradient-to: #6366f1 var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/0 {
  --tw-gradient-to: rgb(99 102 241 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/10 {
  --tw-gradient-to: rgb(99 102 241 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/100 {
  --tw-gradient-to: rgb(99 102 241 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/15 {
  --tw-gradient-to: rgb(99 102 241 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/20 {
  --tw-gradient-to: rgb(99 102 241 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/25 {
  --tw-gradient-to: rgb(99 102 241 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/30 {
  --tw-gradient-to: rgb(99 102 241 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/35 {
  --tw-gradient-to: rgb(99 102 241 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/40 {
  --tw-gradient-to: rgb(99 102 241 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/45 {
  --tw-gradient-to: rgb(99 102 241 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/5 {
  --tw-gradient-to: rgb(99 102 241 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/50 {
  --tw-gradient-to: rgb(99 102 241 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/55 {
  --tw-gradient-to: rgb(99 102 241 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/60 {
  --tw-gradient-to: rgb(99 102 241 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/65 {
  --tw-gradient-to: rgb(99 102 241 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/70 {
  --tw-gradient-to: rgb(99 102 241 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/75 {
  --tw-gradient-to: rgb(99 102 241 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/80 {
  --tw-gradient-to: rgb(99 102 241 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/85 {
  --tw-gradient-to: rgb(99 102 241 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/90 {
  --tw-gradient-to: rgb(99 102 241 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-500\/95 {
  --tw-gradient-to: rgb(99 102 241 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600 {
  --tw-gradient-to: #4f46e5 var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/0 {
  --tw-gradient-to: rgb(79 70 229 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/10 {
  --tw-gradient-to: rgb(79 70 229 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/100 {
  --tw-gradient-to: rgb(79 70 229 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/15 {
  --tw-gradient-to: rgb(79 70 229 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/20 {
  --tw-gradient-to: rgb(79 70 229 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/25 {
  --tw-gradient-to: rgb(79 70 229 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/30 {
  --tw-gradient-to: rgb(79 70 229 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/35 {
  --tw-gradient-to: rgb(79 70 229 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/40 {
  --tw-gradient-to: rgb(79 70 229 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/45 {
  --tw-gradient-to: rgb(79 70 229 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/5 {
  --tw-gradient-to: rgb(79 70 229 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/50 {
  --tw-gradient-to: rgb(79 70 229 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/55 {
  --tw-gradient-to: rgb(79 70 229 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/60 {
  --tw-gradient-to: rgb(79 70 229 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/65 {
  --tw-gradient-to: rgb(79 70 229 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/70 {
  --tw-gradient-to: rgb(79 70 229 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/75 {
  --tw-gradient-to: rgb(79 70 229 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/80 {
  --tw-gradient-to: rgb(79 70 229 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/85 {
  --tw-gradient-to: rgb(79 70 229 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/90 {
  --tw-gradient-to: rgb(79 70 229 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-600\/95 {
  --tw-gradient-to: rgb(79 70 229 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700 {
  --tw-gradient-to: #4338ca var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/0 {
  --tw-gradient-to: rgb(67 56 202 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/10 {
  --tw-gradient-to: rgb(67 56 202 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/100 {
  --tw-gradient-to: rgb(67 56 202 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/15 {
  --tw-gradient-to: rgb(67 56 202 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/20 {
  --tw-gradient-to: rgb(67 56 202 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/25 {
  --tw-gradient-to: rgb(67 56 202 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/30 {
  --tw-gradient-to: rgb(67 56 202 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/35 {
  --tw-gradient-to: rgb(67 56 202 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/40 {
  --tw-gradient-to: rgb(67 56 202 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/45 {
  --tw-gradient-to: rgb(67 56 202 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/5 {
  --tw-gradient-to: rgb(67 56 202 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/50 {
  --tw-gradient-to: rgb(67 56 202 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/55 {
  --tw-gradient-to: rgb(67 56 202 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/60 {
  --tw-gradient-to: rgb(67 56 202 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/65 {
  --tw-gradient-to: rgb(67 56 202 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/70 {
  --tw-gradient-to: rgb(67 56 202 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/75 {
  --tw-gradient-to: rgb(67 56 202 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/80 {
  --tw-gradient-to: rgb(67 56 202 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/85 {
  --tw-gradient-to: rgb(67 56 202 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/90 {
  --tw-gradient-to: rgb(67 56 202 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-700\/95 {
  --tw-gradient-to: rgb(67 56 202 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800 {
  --tw-gradient-to: #3730a3 var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/0 {
  --tw-gradient-to: rgb(55 48 163 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/10 {
  --tw-gradient-to: rgb(55 48 163 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/100 {
  --tw-gradient-to: rgb(55 48 163 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/15 {
  --tw-gradient-to: rgb(55 48 163 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/20 {
  --tw-gradient-to: rgb(55 48 163 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/25 {
  --tw-gradient-to: rgb(55 48 163 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/30 {
  --tw-gradient-to: rgb(55 48 163 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/35 {
  --tw-gradient-to: rgb(55 48 163 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/40 {
  --tw-gradient-to: rgb(55 48 163 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/45 {
  --tw-gradient-to: rgb(55 48 163 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/5 {
  --tw-gradient-to: rgb(55 48 163 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/50 {
  --tw-gradient-to: rgb(55 48 163 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/55 {
  --tw-gradient-to: rgb(55 48 163 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/60 {
  --tw-gradient-to: rgb(55 48 163 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/65 {
  --tw-gradient-to: rgb(55 48 163 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/70 {
  --tw-gradient-to: rgb(55 48 163 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/75 {
  --tw-gradient-to: rgb(55 48 163 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/80 {
  --tw-gradient-to: rgb(55 48 163 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/85 {
  --tw-gradient-to: rgb(55 48 163 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/90 {
  --tw-gradient-to: rgb(55 48 163 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-800\/95 {
  --tw-gradient-to: rgb(55 48 163 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900 {
  --tw-gradient-to: #312e81 var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/0 {
  --tw-gradient-to: rgb(49 46 129 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/10 {
  --tw-gradient-to: rgb(49 46 129 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/100 {
  --tw-gradient-to: rgb(49 46 129 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/15 {
  --tw-gradient-to: rgb(49 46 129 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/20 {
  --tw-gradient-to: rgb(49 46 129 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/25 {
  --tw-gradient-to: rgb(49 46 129 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/30 {
  --tw-gradient-to: rgb(49 46 129 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/35 {
  --tw-gradient-to: rgb(49 46 129 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/40 {
  --tw-gradient-to: rgb(49 46 129 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/45 {
  --tw-gradient-to: rgb(49 46 129 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/5 {
  --tw-gradient-to: rgb(49 46 129 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/50 {
  --tw-gradient-to: rgb(49 46 129 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/55 {
  --tw-gradient-to: rgb(49 46 129 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/60 {
  --tw-gradient-to: rgb(49 46 129 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/65 {
  --tw-gradient-to: rgb(49 46 129 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/70 {
  --tw-gradient-to: rgb(49 46 129 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/75 {
  --tw-gradient-to: rgb(49 46 129 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/80 {
  --tw-gradient-to: rgb(49 46 129 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/85 {
  --tw-gradient-to: rgb(49 46 129 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/90 {
  --tw-gradient-to: rgb(49 46 129 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-900\/95 {
  --tw-gradient-to: rgb(49 46 129 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950 {
  --tw-gradient-to: #1e1b4b var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/0 {
  --tw-gradient-to: rgb(30 27 75 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/10 {
  --tw-gradient-to: rgb(30 27 75 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/100 {
  --tw-gradient-to: rgb(30 27 75 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/15 {
  --tw-gradient-to: rgb(30 27 75 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/20 {
  --tw-gradient-to: rgb(30 27 75 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/25 {
  --tw-gradient-to: rgb(30 27 75 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/30 {
  --tw-gradient-to: rgb(30 27 75 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/35 {
  --tw-gradient-to: rgb(30 27 75 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/40 {
  --tw-gradient-to: rgb(30 27 75 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/45 {
  --tw-gradient-to: rgb(30 27 75 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/5 {
  --tw-gradient-to: rgb(30 27 75 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/50 {
  --tw-gradient-to: rgb(30 27 75 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/55 {
  --tw-gradient-to: rgb(30 27 75 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/60 {
  --tw-gradient-to: rgb(30 27 75 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/65 {
  --tw-gradient-to: rgb(30 27 75 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/70 {
  --tw-gradient-to: rgb(30 27 75 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/75 {
  --tw-gradient-to: rgb(30 27 75 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/80 {
  --tw-gradient-to: rgb(30 27 75 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/85 {
  --tw-gradient-to: rgb(30 27 75 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/90 {
  --tw-gradient-to: rgb(30 27 75 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-indigo-950\/95 {
  --tw-gradient-to: rgb(30 27 75 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-inherit {
  --tw-gradient-to: inherit var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100 {
  --tw-gradient-to: #ecfccb var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/0 {
  --tw-gradient-to: rgb(236 252 203 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/10 {
  --tw-gradient-to: rgb(236 252 203 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/100 {
  --tw-gradient-to: rgb(236 252 203 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/15 {
  --tw-gradient-to: rgb(236 252 203 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/20 {
  --tw-gradient-to: rgb(236 252 203 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/25 {
  --tw-gradient-to: rgb(236 252 203 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/30 {
  --tw-gradient-to: rgb(236 252 203 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/35 {
  --tw-gradient-to: rgb(236 252 203 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/40 {
  --tw-gradient-to: rgb(236 252 203 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/45 {
  --tw-gradient-to: rgb(236 252 203 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/5 {
  --tw-gradient-to: rgb(236 252 203 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/50 {
  --tw-gradient-to: rgb(236 252 203 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/55 {
  --tw-gradient-to: rgb(236 252 203 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/60 {
  --tw-gradient-to: rgb(236 252 203 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/65 {
  --tw-gradient-to: rgb(236 252 203 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/70 {
  --tw-gradient-to: rgb(236 252 203 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/75 {
  --tw-gradient-to: rgb(236 252 203 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/80 {
  --tw-gradient-to: rgb(236 252 203 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/85 {
  --tw-gradient-to: rgb(236 252 203 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/90 {
  --tw-gradient-to: rgb(236 252 203 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-100\/95 {
  --tw-gradient-to: rgb(236 252 203 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200 {
  --tw-gradient-to: #d9f99d var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/0 {
  --tw-gradient-to: rgb(217 249 157 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/10 {
  --tw-gradient-to: rgb(217 249 157 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/100 {
  --tw-gradient-to: rgb(217 249 157 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/15 {
  --tw-gradient-to: rgb(217 249 157 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/20 {
  --tw-gradient-to: rgb(217 249 157 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/25 {
  --tw-gradient-to: rgb(217 249 157 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/30 {
  --tw-gradient-to: rgb(217 249 157 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/35 {
  --tw-gradient-to: rgb(217 249 157 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/40 {
  --tw-gradient-to: rgb(217 249 157 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/45 {
  --tw-gradient-to: rgb(217 249 157 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/5 {
  --tw-gradient-to: rgb(217 249 157 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/50 {
  --tw-gradient-to: rgb(217 249 157 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/55 {
  --tw-gradient-to: rgb(217 249 157 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/60 {
  --tw-gradient-to: rgb(217 249 157 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/65 {
  --tw-gradient-to: rgb(217 249 157 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/70 {
  --tw-gradient-to: rgb(217 249 157 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/75 {
  --tw-gradient-to: rgb(217 249 157 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/80 {
  --tw-gradient-to: rgb(217 249 157 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/85 {
  --tw-gradient-to: rgb(217 249 157 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/90 {
  --tw-gradient-to: rgb(217 249 157 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-200\/95 {
  --tw-gradient-to: rgb(217 249 157 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300 {
  --tw-gradient-to: #bef264 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/0 {
  --tw-gradient-to: rgb(190 242 100 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/10 {
  --tw-gradient-to: rgb(190 242 100 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/100 {
  --tw-gradient-to: rgb(190 242 100 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/15 {
  --tw-gradient-to: rgb(190 242 100 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/20 {
  --tw-gradient-to: rgb(190 242 100 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/25 {
  --tw-gradient-to: rgb(190 242 100 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/30 {
  --tw-gradient-to: rgb(190 242 100 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/35 {
  --tw-gradient-to: rgb(190 242 100 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/40 {
  --tw-gradient-to: rgb(190 242 100 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/45 {
  --tw-gradient-to: rgb(190 242 100 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/5 {
  --tw-gradient-to: rgb(190 242 100 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/50 {
  --tw-gradient-to: rgb(190 242 100 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/55 {
  --tw-gradient-to: rgb(190 242 100 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/60 {
  --tw-gradient-to: rgb(190 242 100 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/65 {
  --tw-gradient-to: rgb(190 242 100 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/70 {
  --tw-gradient-to: rgb(190 242 100 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/75 {
  --tw-gradient-to: rgb(190 242 100 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/80 {
  --tw-gradient-to: rgb(190 242 100 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/85 {
  --tw-gradient-to: rgb(190 242 100 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/90 {
  --tw-gradient-to: rgb(190 242 100 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-300\/95 {
  --tw-gradient-to: rgb(190 242 100 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400 {
  --tw-gradient-to: #a3e635 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/0 {
  --tw-gradient-to: rgb(163 230 53 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/10 {
  --tw-gradient-to: rgb(163 230 53 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/100 {
  --tw-gradient-to: rgb(163 230 53 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/15 {
  --tw-gradient-to: rgb(163 230 53 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/20 {
  --tw-gradient-to: rgb(163 230 53 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/25 {
  --tw-gradient-to: rgb(163 230 53 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/30 {
  --tw-gradient-to: rgb(163 230 53 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/35 {
  --tw-gradient-to: rgb(163 230 53 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/40 {
  --tw-gradient-to: rgb(163 230 53 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/45 {
  --tw-gradient-to: rgb(163 230 53 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/5 {
  --tw-gradient-to: rgb(163 230 53 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/50 {
  --tw-gradient-to: rgb(163 230 53 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/55 {
  --tw-gradient-to: rgb(163 230 53 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/60 {
  --tw-gradient-to: rgb(163 230 53 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/65 {
  --tw-gradient-to: rgb(163 230 53 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/70 {
  --tw-gradient-to: rgb(163 230 53 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/75 {
  --tw-gradient-to: rgb(163 230 53 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/80 {
  --tw-gradient-to: rgb(163 230 53 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/85 {
  --tw-gradient-to: rgb(163 230 53 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/90 {
  --tw-gradient-to: rgb(163 230 53 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-400\/95 {
  --tw-gradient-to: rgb(163 230 53 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50 {
  --tw-gradient-to: #f7fee7 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/0 {
  --tw-gradient-to: rgb(247 254 231 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/10 {
  --tw-gradient-to: rgb(247 254 231 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/100 {
  --tw-gradient-to: rgb(247 254 231 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/15 {
  --tw-gradient-to: rgb(247 254 231 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/20 {
  --tw-gradient-to: rgb(247 254 231 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/25 {
  --tw-gradient-to: rgb(247 254 231 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/30 {
  --tw-gradient-to: rgb(247 254 231 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/35 {
  --tw-gradient-to: rgb(247 254 231 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/40 {
  --tw-gradient-to: rgb(247 254 231 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/45 {
  --tw-gradient-to: rgb(247 254 231 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/5 {
  --tw-gradient-to: rgb(247 254 231 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/50 {
  --tw-gradient-to: rgb(247 254 231 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/55 {
  --tw-gradient-to: rgb(247 254 231 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/60 {
  --tw-gradient-to: rgb(247 254 231 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/65 {
  --tw-gradient-to: rgb(247 254 231 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/70 {
  --tw-gradient-to: rgb(247 254 231 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/75 {
  --tw-gradient-to: rgb(247 254 231 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/80 {
  --tw-gradient-to: rgb(247 254 231 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/85 {
  --tw-gradient-to: rgb(247 254 231 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/90 {
  --tw-gradient-to: rgb(247 254 231 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-50\/95 {
  --tw-gradient-to: rgb(247 254 231 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500 {
  --tw-gradient-to: #84cc16 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/0 {
  --tw-gradient-to: rgb(132 204 22 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/10 {
  --tw-gradient-to: rgb(132 204 22 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/100 {
  --tw-gradient-to: rgb(132 204 22 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/15 {
  --tw-gradient-to: rgb(132 204 22 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/20 {
  --tw-gradient-to: rgb(132 204 22 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/25 {
  --tw-gradient-to: rgb(132 204 22 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/30 {
  --tw-gradient-to: rgb(132 204 22 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/35 {
  --tw-gradient-to: rgb(132 204 22 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/40 {
  --tw-gradient-to: rgb(132 204 22 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/45 {
  --tw-gradient-to: rgb(132 204 22 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/5 {
  --tw-gradient-to: rgb(132 204 22 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/50 {
  --tw-gradient-to: rgb(132 204 22 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/55 {
  --tw-gradient-to: rgb(132 204 22 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/60 {
  --tw-gradient-to: rgb(132 204 22 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/65 {
  --tw-gradient-to: rgb(132 204 22 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/70 {
  --tw-gradient-to: rgb(132 204 22 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/75 {
  --tw-gradient-to: rgb(132 204 22 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/80 {
  --tw-gradient-to: rgb(132 204 22 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/85 {
  --tw-gradient-to: rgb(132 204 22 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/90 {
  --tw-gradient-to: rgb(132 204 22 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-500\/95 {
  --tw-gradient-to: rgb(132 204 22 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600 {
  --tw-gradient-to: #65a30d var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/0 {
  --tw-gradient-to: rgb(101 163 13 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/10 {
  --tw-gradient-to: rgb(101 163 13 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/100 {
  --tw-gradient-to: rgb(101 163 13 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/15 {
  --tw-gradient-to: rgb(101 163 13 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/20 {
  --tw-gradient-to: rgb(101 163 13 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/25 {
  --tw-gradient-to: rgb(101 163 13 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/30 {
  --tw-gradient-to: rgb(101 163 13 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/35 {
  --tw-gradient-to: rgb(101 163 13 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/40 {
  --tw-gradient-to: rgb(101 163 13 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/45 {
  --tw-gradient-to: rgb(101 163 13 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/5 {
  --tw-gradient-to: rgb(101 163 13 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/50 {
  --tw-gradient-to: rgb(101 163 13 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/55 {
  --tw-gradient-to: rgb(101 163 13 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/60 {
  --tw-gradient-to: rgb(101 163 13 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/65 {
  --tw-gradient-to: rgb(101 163 13 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/70 {
  --tw-gradient-to: rgb(101 163 13 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/75 {
  --tw-gradient-to: rgb(101 163 13 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/80 {
  --tw-gradient-to: rgb(101 163 13 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/85 {
  --tw-gradient-to: rgb(101 163 13 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/90 {
  --tw-gradient-to: rgb(101 163 13 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-600\/95 {
  --tw-gradient-to: rgb(101 163 13 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700 {
  --tw-gradient-to: #4d7c0f var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/0 {
  --tw-gradient-to: rgb(77 124 15 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/10 {
  --tw-gradient-to: rgb(77 124 15 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/100 {
  --tw-gradient-to: rgb(77 124 15 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/15 {
  --tw-gradient-to: rgb(77 124 15 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/20 {
  --tw-gradient-to: rgb(77 124 15 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/25 {
  --tw-gradient-to: rgb(77 124 15 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/30 {
  --tw-gradient-to: rgb(77 124 15 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/35 {
  --tw-gradient-to: rgb(77 124 15 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/40 {
  --tw-gradient-to: rgb(77 124 15 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/45 {
  --tw-gradient-to: rgb(77 124 15 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/5 {
  --tw-gradient-to: rgb(77 124 15 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/50 {
  --tw-gradient-to: rgb(77 124 15 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/55 {
  --tw-gradient-to: rgb(77 124 15 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/60 {
  --tw-gradient-to: rgb(77 124 15 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/65 {
  --tw-gradient-to: rgb(77 124 15 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/70 {
  --tw-gradient-to: rgb(77 124 15 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/75 {
  --tw-gradient-to: rgb(77 124 15 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/80 {
  --tw-gradient-to: rgb(77 124 15 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/85 {
  --tw-gradient-to: rgb(77 124 15 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/90 {
  --tw-gradient-to: rgb(77 124 15 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-700\/95 {
  --tw-gradient-to: rgb(77 124 15 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800 {
  --tw-gradient-to: #3f6212 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/0 {
  --tw-gradient-to: rgb(63 98 18 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/10 {
  --tw-gradient-to: rgb(63 98 18 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/100 {
  --tw-gradient-to: rgb(63 98 18 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/15 {
  --tw-gradient-to: rgb(63 98 18 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/20 {
  --tw-gradient-to: rgb(63 98 18 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/25 {
  --tw-gradient-to: rgb(63 98 18 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/30 {
  --tw-gradient-to: rgb(63 98 18 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/35 {
  --tw-gradient-to: rgb(63 98 18 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/40 {
  --tw-gradient-to: rgb(63 98 18 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/45 {
  --tw-gradient-to: rgb(63 98 18 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/5 {
  --tw-gradient-to: rgb(63 98 18 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/50 {
  --tw-gradient-to: rgb(63 98 18 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/55 {
  --tw-gradient-to: rgb(63 98 18 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/60 {
  --tw-gradient-to: rgb(63 98 18 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/65 {
  --tw-gradient-to: rgb(63 98 18 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/70 {
  --tw-gradient-to: rgb(63 98 18 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/75 {
  --tw-gradient-to: rgb(63 98 18 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/80 {
  --tw-gradient-to: rgb(63 98 18 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/85 {
  --tw-gradient-to: rgb(63 98 18 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/90 {
  --tw-gradient-to: rgb(63 98 18 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-800\/95 {
  --tw-gradient-to: rgb(63 98 18 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900 {
  --tw-gradient-to: #365314 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/0 {
  --tw-gradient-to: rgb(54 83 20 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/10 {
  --tw-gradient-to: rgb(54 83 20 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/100 {
  --tw-gradient-to: rgb(54 83 20 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/15 {
  --tw-gradient-to: rgb(54 83 20 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/20 {
  --tw-gradient-to: rgb(54 83 20 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/25 {
  --tw-gradient-to: rgb(54 83 20 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/30 {
  --tw-gradient-to: rgb(54 83 20 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/35 {
  --tw-gradient-to: rgb(54 83 20 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/40 {
  --tw-gradient-to: rgb(54 83 20 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/45 {
  --tw-gradient-to: rgb(54 83 20 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/5 {
  --tw-gradient-to: rgb(54 83 20 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/50 {
  --tw-gradient-to: rgb(54 83 20 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/55 {
  --tw-gradient-to: rgb(54 83 20 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/60 {
  --tw-gradient-to: rgb(54 83 20 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/65 {
  --tw-gradient-to: rgb(54 83 20 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/70 {
  --tw-gradient-to: rgb(54 83 20 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/75 {
  --tw-gradient-to: rgb(54 83 20 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/80 {
  --tw-gradient-to: rgb(54 83 20 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/85 {
  --tw-gradient-to: rgb(54 83 20 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/90 {
  --tw-gradient-to: rgb(54 83 20 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-900\/95 {
  --tw-gradient-to: rgb(54 83 20 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950 {
  --tw-gradient-to: #1a2e05 var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/0 {
  --tw-gradient-to: rgb(26 46 5 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/10 {
  --tw-gradient-to: rgb(26 46 5 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/100 {
  --tw-gradient-to: rgb(26 46 5 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/15 {
  --tw-gradient-to: rgb(26 46 5 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/20 {
  --tw-gradient-to: rgb(26 46 5 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/25 {
  --tw-gradient-to: rgb(26 46 5 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/30 {
  --tw-gradient-to: rgb(26 46 5 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/35 {
  --tw-gradient-to: rgb(26 46 5 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/40 {
  --tw-gradient-to: rgb(26 46 5 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/45 {
  --tw-gradient-to: rgb(26 46 5 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/5 {
  --tw-gradient-to: rgb(26 46 5 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/50 {
  --tw-gradient-to: rgb(26 46 5 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/55 {
  --tw-gradient-to: rgb(26 46 5 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/60 {
  --tw-gradient-to: rgb(26 46 5 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/65 {
  --tw-gradient-to: rgb(26 46 5 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/70 {
  --tw-gradient-to: rgb(26 46 5 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/75 {
  --tw-gradient-to: rgb(26 46 5 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/80 {
  --tw-gradient-to: rgb(26 46 5 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/85 {
  --tw-gradient-to: rgb(26 46 5 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/90 {
  --tw-gradient-to: rgb(26 46 5 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-lime-950\/95 {
  --tw-gradient-to: rgb(26 46 5 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100 {
  --tw-gradient-to: #f5f5f5 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/0 {
  --tw-gradient-to: rgb(245 245 245 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/10 {
  --tw-gradient-to: rgb(245 245 245 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/100 {
  --tw-gradient-to: rgb(245 245 245 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/15 {
  --tw-gradient-to: rgb(245 245 245 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/20 {
  --tw-gradient-to: rgb(245 245 245 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/25 {
  --tw-gradient-to: rgb(245 245 245 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/30 {
  --tw-gradient-to: rgb(245 245 245 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/35 {
  --tw-gradient-to: rgb(245 245 245 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/40 {
  --tw-gradient-to: rgb(245 245 245 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/45 {
  --tw-gradient-to: rgb(245 245 245 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/5 {
  --tw-gradient-to: rgb(245 245 245 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/50 {
  --tw-gradient-to: rgb(245 245 245 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/55 {
  --tw-gradient-to: rgb(245 245 245 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/60 {
  --tw-gradient-to: rgb(245 245 245 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/65 {
  --tw-gradient-to: rgb(245 245 245 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/70 {
  --tw-gradient-to: rgb(245 245 245 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/75 {
  --tw-gradient-to: rgb(245 245 245 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/80 {
  --tw-gradient-to: rgb(245 245 245 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/85 {
  --tw-gradient-to: rgb(245 245 245 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/90 {
  --tw-gradient-to: rgb(245 245 245 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-100\/95 {
  --tw-gradient-to: rgb(245 245 245 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200 {
  --tw-gradient-to: #e5e5e5 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/0 {
  --tw-gradient-to: rgb(229 229 229 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/10 {
  --tw-gradient-to: rgb(229 229 229 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/100 {
  --tw-gradient-to: rgb(229 229 229 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/15 {
  --tw-gradient-to: rgb(229 229 229 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/20 {
  --tw-gradient-to: rgb(229 229 229 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/25 {
  --tw-gradient-to: rgb(229 229 229 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/30 {
  --tw-gradient-to: rgb(229 229 229 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/35 {
  --tw-gradient-to: rgb(229 229 229 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/40 {
  --tw-gradient-to: rgb(229 229 229 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/45 {
  --tw-gradient-to: rgb(229 229 229 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/5 {
  --tw-gradient-to: rgb(229 229 229 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/50 {
  --tw-gradient-to: rgb(229 229 229 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/55 {
  --tw-gradient-to: rgb(229 229 229 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/60 {
  --tw-gradient-to: rgb(229 229 229 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/65 {
  --tw-gradient-to: rgb(229 229 229 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/70 {
  --tw-gradient-to: rgb(229 229 229 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/75 {
  --tw-gradient-to: rgb(229 229 229 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/80 {
  --tw-gradient-to: rgb(229 229 229 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/85 {
  --tw-gradient-to: rgb(229 229 229 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/90 {
  --tw-gradient-to: rgb(229 229 229 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-200\/95 {
  --tw-gradient-to: rgb(229 229 229 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300 {
  --tw-gradient-to: #d4d4d4 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/0 {
  --tw-gradient-to: rgb(212 212 212 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/10 {
  --tw-gradient-to: rgb(212 212 212 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/100 {
  --tw-gradient-to: rgb(212 212 212 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/15 {
  --tw-gradient-to: rgb(212 212 212 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/20 {
  --tw-gradient-to: rgb(212 212 212 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/25 {
  --tw-gradient-to: rgb(212 212 212 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/30 {
  --tw-gradient-to: rgb(212 212 212 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/35 {
  --tw-gradient-to: rgb(212 212 212 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/40 {
  --tw-gradient-to: rgb(212 212 212 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/45 {
  --tw-gradient-to: rgb(212 212 212 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/5 {
  --tw-gradient-to: rgb(212 212 212 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/50 {
  --tw-gradient-to: rgb(212 212 212 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/55 {
  --tw-gradient-to: rgb(212 212 212 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/60 {
  --tw-gradient-to: rgb(212 212 212 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/65 {
  --tw-gradient-to: rgb(212 212 212 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/70 {
  --tw-gradient-to: rgb(212 212 212 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/75 {
  --tw-gradient-to: rgb(212 212 212 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/80 {
  --tw-gradient-to: rgb(212 212 212 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/85 {
  --tw-gradient-to: rgb(212 212 212 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/90 {
  --tw-gradient-to: rgb(212 212 212 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-300\/95 {
  --tw-gradient-to: rgb(212 212 212 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400 {
  --tw-gradient-to: #a3a3a3 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/0 {
  --tw-gradient-to: rgb(163 163 163 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/10 {
  --tw-gradient-to: rgb(163 163 163 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/100 {
  --tw-gradient-to: rgb(163 163 163 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/15 {
  --tw-gradient-to: rgb(163 163 163 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/20 {
  --tw-gradient-to: rgb(163 163 163 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/25 {
  --tw-gradient-to: rgb(163 163 163 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/30 {
  --tw-gradient-to: rgb(163 163 163 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/35 {
  --tw-gradient-to: rgb(163 163 163 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/40 {
  --tw-gradient-to: rgb(163 163 163 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/45 {
  --tw-gradient-to: rgb(163 163 163 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/5 {
  --tw-gradient-to: rgb(163 163 163 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/50 {
  --tw-gradient-to: rgb(163 163 163 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/55 {
  --tw-gradient-to: rgb(163 163 163 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/60 {
  --tw-gradient-to: rgb(163 163 163 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/65 {
  --tw-gradient-to: rgb(163 163 163 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/70 {
  --tw-gradient-to: rgb(163 163 163 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/75 {
  --tw-gradient-to: rgb(163 163 163 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/80 {
  --tw-gradient-to: rgb(163 163 163 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/85 {
  --tw-gradient-to: rgb(163 163 163 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/90 {
  --tw-gradient-to: rgb(163 163 163 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-400\/95 {
  --tw-gradient-to: rgb(163 163 163 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50 {
  --tw-gradient-to: #fafafa var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/0 {
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/10 {
  --tw-gradient-to: rgb(250 250 250 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/100 {
  --tw-gradient-to: rgb(250 250 250 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/15 {
  --tw-gradient-to: rgb(250 250 250 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/20 {
  --tw-gradient-to: rgb(250 250 250 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/25 {
  --tw-gradient-to: rgb(250 250 250 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/30 {
  --tw-gradient-to: rgb(250 250 250 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/35 {
  --tw-gradient-to: rgb(250 250 250 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/40 {
  --tw-gradient-to: rgb(250 250 250 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/45 {
  --tw-gradient-to: rgb(250 250 250 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/5 {
  --tw-gradient-to: rgb(250 250 250 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/50 {
  --tw-gradient-to: rgb(250 250 250 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/55 {
  --tw-gradient-to: rgb(250 250 250 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/60 {
  --tw-gradient-to: rgb(250 250 250 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/65 {
  --tw-gradient-to: rgb(250 250 250 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/70 {
  --tw-gradient-to: rgb(250 250 250 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/75 {
  --tw-gradient-to: rgb(250 250 250 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/80 {
  --tw-gradient-to: rgb(250 250 250 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/85 {
  --tw-gradient-to: rgb(250 250 250 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/90 {
  --tw-gradient-to: rgb(250 250 250 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-50\/95 {
  --tw-gradient-to: rgb(250 250 250 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500 {
  --tw-gradient-to: #737373 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/0 {
  --tw-gradient-to: rgb(115 115 115 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/10 {
  --tw-gradient-to: rgb(115 115 115 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/100 {
  --tw-gradient-to: rgb(115 115 115 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/15 {
  --tw-gradient-to: rgb(115 115 115 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/20 {
  --tw-gradient-to: rgb(115 115 115 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/25 {
  --tw-gradient-to: rgb(115 115 115 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/30 {
  --tw-gradient-to: rgb(115 115 115 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/35 {
  --tw-gradient-to: rgb(115 115 115 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/40 {
  --tw-gradient-to: rgb(115 115 115 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/45 {
  --tw-gradient-to: rgb(115 115 115 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/5 {
  --tw-gradient-to: rgb(115 115 115 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/50 {
  --tw-gradient-to: rgb(115 115 115 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/55 {
  --tw-gradient-to: rgb(115 115 115 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/60 {
  --tw-gradient-to: rgb(115 115 115 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/65 {
  --tw-gradient-to: rgb(115 115 115 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/70 {
  --tw-gradient-to: rgb(115 115 115 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/75 {
  --tw-gradient-to: rgb(115 115 115 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/80 {
  --tw-gradient-to: rgb(115 115 115 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/85 {
  --tw-gradient-to: rgb(115 115 115 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/90 {
  --tw-gradient-to: rgb(115 115 115 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-500\/95 {
  --tw-gradient-to: rgb(115 115 115 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600 {
  --tw-gradient-to: #525252 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/0 {
  --tw-gradient-to: rgb(82 82 82 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/10 {
  --tw-gradient-to: rgb(82 82 82 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/100 {
  --tw-gradient-to: rgb(82 82 82 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/15 {
  --tw-gradient-to: rgb(82 82 82 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/20 {
  --tw-gradient-to: rgb(82 82 82 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/25 {
  --tw-gradient-to: rgb(82 82 82 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/30 {
  --tw-gradient-to: rgb(82 82 82 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/35 {
  --tw-gradient-to: rgb(82 82 82 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/40 {
  --tw-gradient-to: rgb(82 82 82 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/45 {
  --tw-gradient-to: rgb(82 82 82 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/5 {
  --tw-gradient-to: rgb(82 82 82 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/50 {
  --tw-gradient-to: rgb(82 82 82 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/55 {
  --tw-gradient-to: rgb(82 82 82 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/60 {
  --tw-gradient-to: rgb(82 82 82 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/65 {
  --tw-gradient-to: rgb(82 82 82 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/70 {
  --tw-gradient-to: rgb(82 82 82 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/75 {
  --tw-gradient-to: rgb(82 82 82 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/80 {
  --tw-gradient-to: rgb(82 82 82 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/85 {
  --tw-gradient-to: rgb(82 82 82 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/90 {
  --tw-gradient-to: rgb(82 82 82 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-600\/95 {
  --tw-gradient-to: rgb(82 82 82 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700 {
  --tw-gradient-to: #404040 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/0 {
  --tw-gradient-to: rgb(64 64 64 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/10 {
  --tw-gradient-to: rgb(64 64 64 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/100 {
  --tw-gradient-to: rgb(64 64 64 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/15 {
  --tw-gradient-to: rgb(64 64 64 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/20 {
  --tw-gradient-to: rgb(64 64 64 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/25 {
  --tw-gradient-to: rgb(64 64 64 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/30 {
  --tw-gradient-to: rgb(64 64 64 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/35 {
  --tw-gradient-to: rgb(64 64 64 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/40 {
  --tw-gradient-to: rgb(64 64 64 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/45 {
  --tw-gradient-to: rgb(64 64 64 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/5 {
  --tw-gradient-to: rgb(64 64 64 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/50 {
  --tw-gradient-to: rgb(64 64 64 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/55 {
  --tw-gradient-to: rgb(64 64 64 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/60 {
  --tw-gradient-to: rgb(64 64 64 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/65 {
  --tw-gradient-to: rgb(64 64 64 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/70 {
  --tw-gradient-to: rgb(64 64 64 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/75 {
  --tw-gradient-to: rgb(64 64 64 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/80 {
  --tw-gradient-to: rgb(64 64 64 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/85 {
  --tw-gradient-to: rgb(64 64 64 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/90 {
  --tw-gradient-to: rgb(64 64 64 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-700\/95 {
  --tw-gradient-to: rgb(64 64 64 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800 {
  --tw-gradient-to: #262626 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/0 {
  --tw-gradient-to: rgb(38 38 38 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/10 {
  --tw-gradient-to: rgb(38 38 38 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/100 {
  --tw-gradient-to: rgb(38 38 38 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/15 {
  --tw-gradient-to: rgb(38 38 38 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/20 {
  --tw-gradient-to: rgb(38 38 38 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/25 {
  --tw-gradient-to: rgb(38 38 38 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/30 {
  --tw-gradient-to: rgb(38 38 38 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/35 {
  --tw-gradient-to: rgb(38 38 38 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/40 {
  --tw-gradient-to: rgb(38 38 38 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/45 {
  --tw-gradient-to: rgb(38 38 38 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/5 {
  --tw-gradient-to: rgb(38 38 38 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/50 {
  --tw-gradient-to: rgb(38 38 38 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/55 {
  --tw-gradient-to: rgb(38 38 38 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/60 {
  --tw-gradient-to: rgb(38 38 38 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/65 {
  --tw-gradient-to: rgb(38 38 38 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/70 {
  --tw-gradient-to: rgb(38 38 38 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/75 {
  --tw-gradient-to: rgb(38 38 38 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/80 {
  --tw-gradient-to: rgb(38 38 38 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/85 {
  --tw-gradient-to: rgb(38 38 38 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/90 {
  --tw-gradient-to: rgb(38 38 38 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-800\/95 {
  --tw-gradient-to: rgb(38 38 38 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900 {
  --tw-gradient-to: #171717 var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/0 {
  --tw-gradient-to: rgb(23 23 23 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/10 {
  --tw-gradient-to: rgb(23 23 23 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/100 {
  --tw-gradient-to: rgb(23 23 23 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/15 {
  --tw-gradient-to: rgb(23 23 23 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/20 {
  --tw-gradient-to: rgb(23 23 23 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/25 {
  --tw-gradient-to: rgb(23 23 23 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/30 {
  --tw-gradient-to: rgb(23 23 23 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/35 {
  --tw-gradient-to: rgb(23 23 23 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/40 {
  --tw-gradient-to: rgb(23 23 23 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/45 {
  --tw-gradient-to: rgb(23 23 23 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/5 {
  --tw-gradient-to: rgb(23 23 23 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/50 {
  --tw-gradient-to: rgb(23 23 23 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/55 {
  --tw-gradient-to: rgb(23 23 23 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/60 {
  --tw-gradient-to: rgb(23 23 23 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/65 {
  --tw-gradient-to: rgb(23 23 23 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/70 {
  --tw-gradient-to: rgb(23 23 23 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/75 {
  --tw-gradient-to: rgb(23 23 23 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/80 {
  --tw-gradient-to: rgb(23 23 23 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/85 {
  --tw-gradient-to: rgb(23 23 23 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/90 {
  --tw-gradient-to: rgb(23 23 23 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-900\/95 {
  --tw-gradient-to: rgb(23 23 23 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950 {
  --tw-gradient-to: #0a0a0a var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/0 {
  --tw-gradient-to: rgb(10 10 10 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/10 {
  --tw-gradient-to: rgb(10 10 10 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/100 {
  --tw-gradient-to: rgb(10 10 10 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/15 {
  --tw-gradient-to: rgb(10 10 10 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/20 {
  --tw-gradient-to: rgb(10 10 10 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/25 {
  --tw-gradient-to: rgb(10 10 10 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/30 {
  --tw-gradient-to: rgb(10 10 10 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/35 {
  --tw-gradient-to: rgb(10 10 10 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/40 {
  --tw-gradient-to: rgb(10 10 10 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/45 {
  --tw-gradient-to: rgb(10 10 10 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/5 {
  --tw-gradient-to: rgb(10 10 10 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/50 {
  --tw-gradient-to: rgb(10 10 10 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/55 {
  --tw-gradient-to: rgb(10 10 10 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/60 {
  --tw-gradient-to: rgb(10 10 10 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/65 {
  --tw-gradient-to: rgb(10 10 10 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/70 {
  --tw-gradient-to: rgb(10 10 10 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/75 {
  --tw-gradient-to: rgb(10 10 10 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/80 {
  --tw-gradient-to: rgb(10 10 10 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/85 {
  --tw-gradient-to: rgb(10 10 10 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/90 {
  --tw-gradient-to: rgb(10 10 10 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-neutral-950\/95 {
  --tw-gradient-to: rgb(10 10 10 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100 {
  --tw-gradient-to: #ffedd5 var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/0 {
  --tw-gradient-to: rgb(255 237 213 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/10 {
  --tw-gradient-to: rgb(255 237 213 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/100 {
  --tw-gradient-to: rgb(255 237 213 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/15 {
  --tw-gradient-to: rgb(255 237 213 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/20 {
  --tw-gradient-to: rgb(255 237 213 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/25 {
  --tw-gradient-to: rgb(255 237 213 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/30 {
  --tw-gradient-to: rgb(255 237 213 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/35 {
  --tw-gradient-to: rgb(255 237 213 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/40 {
  --tw-gradient-to: rgb(255 237 213 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/45 {
  --tw-gradient-to: rgb(255 237 213 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/5 {
  --tw-gradient-to: rgb(255 237 213 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/50 {
  --tw-gradient-to: rgb(255 237 213 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/55 {
  --tw-gradient-to: rgb(255 237 213 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/60 {
  --tw-gradient-to: rgb(255 237 213 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/65 {
  --tw-gradient-to: rgb(255 237 213 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/70 {
  --tw-gradient-to: rgb(255 237 213 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/75 {
  --tw-gradient-to: rgb(255 237 213 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/80 {
  --tw-gradient-to: rgb(255 237 213 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/85 {
  --tw-gradient-to: rgb(255 237 213 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/90 {
  --tw-gradient-to: rgb(255 237 213 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-100\/95 {
  --tw-gradient-to: rgb(255 237 213 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200 {
  --tw-gradient-to: #fed7aa var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/0 {
  --tw-gradient-to: rgb(254 215 170 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/10 {
  --tw-gradient-to: rgb(254 215 170 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/100 {
  --tw-gradient-to: rgb(254 215 170 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/15 {
  --tw-gradient-to: rgb(254 215 170 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/20 {
  --tw-gradient-to: rgb(254 215 170 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/25 {
  --tw-gradient-to: rgb(254 215 170 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/30 {
  --tw-gradient-to: rgb(254 215 170 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/35 {
  --tw-gradient-to: rgb(254 215 170 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/40 {
  --tw-gradient-to: rgb(254 215 170 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/45 {
  --tw-gradient-to: rgb(254 215 170 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/5 {
  --tw-gradient-to: rgb(254 215 170 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/50 {
  --tw-gradient-to: rgb(254 215 170 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/55 {
  --tw-gradient-to: rgb(254 215 170 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/60 {
  --tw-gradient-to: rgb(254 215 170 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/65 {
  --tw-gradient-to: rgb(254 215 170 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/70 {
  --tw-gradient-to: rgb(254 215 170 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/75 {
  --tw-gradient-to: rgb(254 215 170 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/80 {
  --tw-gradient-to: rgb(254 215 170 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/85 {
  --tw-gradient-to: rgb(254 215 170 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/90 {
  --tw-gradient-to: rgb(254 215 170 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-200\/95 {
  --tw-gradient-to: rgb(254 215 170 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300 {
  --tw-gradient-to: #fdba74 var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/0 {
  --tw-gradient-to: rgb(253 186 116 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/10 {
  --tw-gradient-to: rgb(253 186 116 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/100 {
  --tw-gradient-to: rgb(253 186 116 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/15 {
  --tw-gradient-to: rgb(253 186 116 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/20 {
  --tw-gradient-to: rgb(253 186 116 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/25 {
  --tw-gradient-to: rgb(253 186 116 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/30 {
  --tw-gradient-to: rgb(253 186 116 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/35 {
  --tw-gradient-to: rgb(253 186 116 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/40 {
  --tw-gradient-to: rgb(253 186 116 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/45 {
  --tw-gradient-to: rgb(253 186 116 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/5 {
  --tw-gradient-to: rgb(253 186 116 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/50 {
  --tw-gradient-to: rgb(253 186 116 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/55 {
  --tw-gradient-to: rgb(253 186 116 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/60 {
  --tw-gradient-to: rgb(253 186 116 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/65 {
  --tw-gradient-to: rgb(253 186 116 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/70 {
  --tw-gradient-to: rgb(253 186 116 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/75 {
  --tw-gradient-to: rgb(253 186 116 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/80 {
  --tw-gradient-to: rgb(253 186 116 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/85 {
  --tw-gradient-to: rgb(253 186 116 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/90 {
  --tw-gradient-to: rgb(253 186 116 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-300\/95 {
  --tw-gradient-to: rgb(253 186 116 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400 {
  --tw-gradient-to: #fb923c var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/0 {
  --tw-gradient-to: rgb(251 146 60 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/10 {
  --tw-gradient-to: rgb(251 146 60 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/100 {
  --tw-gradient-to: rgb(251 146 60 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/15 {
  --tw-gradient-to: rgb(251 146 60 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/20 {
  --tw-gradient-to: rgb(251 146 60 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/25 {
  --tw-gradient-to: rgb(251 146 60 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/30 {
  --tw-gradient-to: rgb(251 146 60 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/35 {
  --tw-gradient-to: rgb(251 146 60 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/40 {
  --tw-gradient-to: rgb(251 146 60 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/45 {
  --tw-gradient-to: rgb(251 146 60 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/5 {
  --tw-gradient-to: rgb(251 146 60 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/50 {
  --tw-gradient-to: rgb(251 146 60 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/55 {
  --tw-gradient-to: rgb(251 146 60 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/60 {
  --tw-gradient-to: rgb(251 146 60 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/65 {
  --tw-gradient-to: rgb(251 146 60 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/70 {
  --tw-gradient-to: rgb(251 146 60 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/75 {
  --tw-gradient-to: rgb(251 146 60 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/80 {
  --tw-gradient-to: rgb(251 146 60 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/85 {
  --tw-gradient-to: rgb(251 146 60 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/90 {
  --tw-gradient-to: rgb(251 146 60 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-400\/95 {
  --tw-gradient-to: rgb(251 146 60 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50 {
  --tw-gradient-to: #fff7ed var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/0 {
  --tw-gradient-to: rgb(255 247 237 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/10 {
  --tw-gradient-to: rgb(255 247 237 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/100 {
  --tw-gradient-to: rgb(255 247 237 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/15 {
  --tw-gradient-to: rgb(255 247 237 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/20 {
  --tw-gradient-to: rgb(255 247 237 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/25 {
  --tw-gradient-to: rgb(255 247 237 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/30 {
  --tw-gradient-to: rgb(255 247 237 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/35 {
  --tw-gradient-to: rgb(255 247 237 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/40 {
  --tw-gradient-to: rgb(255 247 237 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/45 {
  --tw-gradient-to: rgb(255 247 237 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/5 {
  --tw-gradient-to: rgb(255 247 237 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/50 {
  --tw-gradient-to: rgb(255 247 237 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/55 {
  --tw-gradient-to: rgb(255 247 237 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/60 {
  --tw-gradient-to: rgb(255 247 237 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/65 {
  --tw-gradient-to: rgb(255 247 237 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/70 {
  --tw-gradient-to: rgb(255 247 237 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/75 {
  --tw-gradient-to: rgb(255 247 237 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/80 {
  --tw-gradient-to: rgb(255 247 237 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/85 {
  --tw-gradient-to: rgb(255 247 237 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/90 {
  --tw-gradient-to: rgb(255 247 237 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-50\/95 {
  --tw-gradient-to: rgb(255 247 237 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500 {
  --tw-gradient-to: #f97316 var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/0 {
  --tw-gradient-to: rgb(249 115 22 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/10 {
  --tw-gradient-to: rgb(249 115 22 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/100 {
  --tw-gradient-to: rgb(249 115 22 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/15 {
  --tw-gradient-to: rgb(249 115 22 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/20 {
  --tw-gradient-to: rgb(249 115 22 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/25 {
  --tw-gradient-to: rgb(249 115 22 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/30 {
  --tw-gradient-to: rgb(249 115 22 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/35 {
  --tw-gradient-to: rgb(249 115 22 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/40 {
  --tw-gradient-to: rgb(249 115 22 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/45 {
  --tw-gradient-to: rgb(249 115 22 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/5 {
  --tw-gradient-to: rgb(249 115 22 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/50 {
  --tw-gradient-to: rgb(249 115 22 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/55 {
  --tw-gradient-to: rgb(249 115 22 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/60 {
  --tw-gradient-to: rgb(249 115 22 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/65 {
  --tw-gradient-to: rgb(249 115 22 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/70 {
  --tw-gradient-to: rgb(249 115 22 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/75 {
  --tw-gradient-to: rgb(249 115 22 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/80 {
  --tw-gradient-to: rgb(249 115 22 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/85 {
  --tw-gradient-to: rgb(249 115 22 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/90 {
  --tw-gradient-to: rgb(249 115 22 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-500\/95 {
  --tw-gradient-to: rgb(249 115 22 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600 {
  --tw-gradient-to: #ea580c var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/0 {
  --tw-gradient-to: rgb(234 88 12 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/10 {
  --tw-gradient-to: rgb(234 88 12 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/100 {
  --tw-gradient-to: rgb(234 88 12 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/15 {
  --tw-gradient-to: rgb(234 88 12 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/20 {
  --tw-gradient-to: rgb(234 88 12 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/25 {
  --tw-gradient-to: rgb(234 88 12 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/30 {
  --tw-gradient-to: rgb(234 88 12 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/35 {
  --tw-gradient-to: rgb(234 88 12 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/40 {
  --tw-gradient-to: rgb(234 88 12 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/45 {
  --tw-gradient-to: rgb(234 88 12 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/5 {
  --tw-gradient-to: rgb(234 88 12 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/50 {
  --tw-gradient-to: rgb(234 88 12 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/55 {
  --tw-gradient-to: rgb(234 88 12 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/60 {
  --tw-gradient-to: rgb(234 88 12 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/65 {
  --tw-gradient-to: rgb(234 88 12 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/70 {
  --tw-gradient-to: rgb(234 88 12 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/75 {
  --tw-gradient-to: rgb(234 88 12 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/80 {
  --tw-gradient-to: rgb(234 88 12 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/85 {
  --tw-gradient-to: rgb(234 88 12 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/90 {
  --tw-gradient-to: rgb(234 88 12 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-600\/95 {
  --tw-gradient-to: rgb(234 88 12 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700 {
  --tw-gradient-to: #c2410c var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/0 {
  --tw-gradient-to: rgb(194 65 12 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/10 {
  --tw-gradient-to: rgb(194 65 12 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/100 {
  --tw-gradient-to: rgb(194 65 12 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/15 {
  --tw-gradient-to: rgb(194 65 12 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/20 {
  --tw-gradient-to: rgb(194 65 12 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/25 {
  --tw-gradient-to: rgb(194 65 12 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/30 {
  --tw-gradient-to: rgb(194 65 12 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/35 {
  --tw-gradient-to: rgb(194 65 12 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/40 {
  --tw-gradient-to: rgb(194 65 12 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/45 {
  --tw-gradient-to: rgb(194 65 12 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/5 {
  --tw-gradient-to: rgb(194 65 12 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/50 {
  --tw-gradient-to: rgb(194 65 12 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/55 {
  --tw-gradient-to: rgb(194 65 12 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/60 {
  --tw-gradient-to: rgb(194 65 12 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/65 {
  --tw-gradient-to: rgb(194 65 12 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/70 {
  --tw-gradient-to: rgb(194 65 12 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/75 {
  --tw-gradient-to: rgb(194 65 12 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/80 {
  --tw-gradient-to: rgb(194 65 12 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/85 {
  --tw-gradient-to: rgb(194 65 12 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/90 {
  --tw-gradient-to: rgb(194 65 12 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-700\/95 {
  --tw-gradient-to: rgb(194 65 12 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800 {
  --tw-gradient-to: #9a3412 var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/0 {
  --tw-gradient-to: rgb(154 52 18 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/10 {
  --tw-gradient-to: rgb(154 52 18 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/100 {
  --tw-gradient-to: rgb(154 52 18 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/15 {
  --tw-gradient-to: rgb(154 52 18 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/20 {
  --tw-gradient-to: rgb(154 52 18 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/25 {
  --tw-gradient-to: rgb(154 52 18 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/30 {
  --tw-gradient-to: rgb(154 52 18 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/35 {
  --tw-gradient-to: rgb(154 52 18 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/40 {
  --tw-gradient-to: rgb(154 52 18 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/45 {
  --tw-gradient-to: rgb(154 52 18 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/5 {
  --tw-gradient-to: rgb(154 52 18 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/50 {
  --tw-gradient-to: rgb(154 52 18 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/55 {
  --tw-gradient-to: rgb(154 52 18 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/60 {
  --tw-gradient-to: rgb(154 52 18 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/65 {
  --tw-gradient-to: rgb(154 52 18 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/70 {
  --tw-gradient-to: rgb(154 52 18 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/75 {
  --tw-gradient-to: rgb(154 52 18 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/80 {
  --tw-gradient-to: rgb(154 52 18 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/85 {
  --tw-gradient-to: rgb(154 52 18 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/90 {
  --tw-gradient-to: rgb(154 52 18 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-800\/95 {
  --tw-gradient-to: rgb(154 52 18 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900 {
  --tw-gradient-to: #7c2d12 var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/0 {
  --tw-gradient-to: rgb(124 45 18 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/10 {
  --tw-gradient-to: rgb(124 45 18 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/100 {
  --tw-gradient-to: rgb(124 45 18 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/15 {
  --tw-gradient-to: rgb(124 45 18 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/20 {
  --tw-gradient-to: rgb(124 45 18 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/25 {
  --tw-gradient-to: rgb(124 45 18 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/30 {
  --tw-gradient-to: rgb(124 45 18 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/35 {
  --tw-gradient-to: rgb(124 45 18 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/40 {
  --tw-gradient-to: rgb(124 45 18 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/45 {
  --tw-gradient-to: rgb(124 45 18 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/5 {
  --tw-gradient-to: rgb(124 45 18 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/50 {
  --tw-gradient-to: rgb(124 45 18 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/55 {
  --tw-gradient-to: rgb(124 45 18 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/60 {
  --tw-gradient-to: rgb(124 45 18 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/65 {
  --tw-gradient-to: rgb(124 45 18 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/70 {
  --tw-gradient-to: rgb(124 45 18 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/75 {
  --tw-gradient-to: rgb(124 45 18 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/80 {
  --tw-gradient-to: rgb(124 45 18 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/85 {
  --tw-gradient-to: rgb(124 45 18 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/90 {
  --tw-gradient-to: rgb(124 45 18 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-900\/95 {
  --tw-gradient-to: rgb(124 45 18 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950 {
  --tw-gradient-to: #431407 var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/0 {
  --tw-gradient-to: rgb(67 20 7 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/10 {
  --tw-gradient-to: rgb(67 20 7 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/100 {
  --tw-gradient-to: rgb(67 20 7 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/15 {
  --tw-gradient-to: rgb(67 20 7 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/20 {
  --tw-gradient-to: rgb(67 20 7 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/25 {
  --tw-gradient-to: rgb(67 20 7 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/30 {
  --tw-gradient-to: rgb(67 20 7 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/35 {
  --tw-gradient-to: rgb(67 20 7 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/40 {
  --tw-gradient-to: rgb(67 20 7 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/45 {
  --tw-gradient-to: rgb(67 20 7 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/5 {
  --tw-gradient-to: rgb(67 20 7 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/50 {
  --tw-gradient-to: rgb(67 20 7 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/55 {
  --tw-gradient-to: rgb(67 20 7 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/60 {
  --tw-gradient-to: rgb(67 20 7 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/65 {
  --tw-gradient-to: rgb(67 20 7 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/70 {
  --tw-gradient-to: rgb(67 20 7 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/75 {
  --tw-gradient-to: rgb(67 20 7 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/80 {
  --tw-gradient-to: rgb(67 20 7 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/85 {
  --tw-gradient-to: rgb(67 20 7 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/90 {
  --tw-gradient-to: rgb(67 20 7 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-orange-950\/95 {
  --tw-gradient-to: rgb(67 20 7 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100 {
  --tw-gradient-to: #fce7f3 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/0 {
  --tw-gradient-to: rgb(252 231 243 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/10 {
  --tw-gradient-to: rgb(252 231 243 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/100 {
  --tw-gradient-to: rgb(252 231 243 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/15 {
  --tw-gradient-to: rgb(252 231 243 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/20 {
  --tw-gradient-to: rgb(252 231 243 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/25 {
  --tw-gradient-to: rgb(252 231 243 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/30 {
  --tw-gradient-to: rgb(252 231 243 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/35 {
  --tw-gradient-to: rgb(252 231 243 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/40 {
  --tw-gradient-to: rgb(252 231 243 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/45 {
  --tw-gradient-to: rgb(252 231 243 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/5 {
  --tw-gradient-to: rgb(252 231 243 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/50 {
  --tw-gradient-to: rgb(252 231 243 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/55 {
  --tw-gradient-to: rgb(252 231 243 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/60 {
  --tw-gradient-to: rgb(252 231 243 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/65 {
  --tw-gradient-to: rgb(252 231 243 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/70 {
  --tw-gradient-to: rgb(252 231 243 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/75 {
  --tw-gradient-to: rgb(252 231 243 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/80 {
  --tw-gradient-to: rgb(252 231 243 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/85 {
  --tw-gradient-to: rgb(252 231 243 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/90 {
  --tw-gradient-to: rgb(252 231 243 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-100\/95 {
  --tw-gradient-to: rgb(252 231 243 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200 {
  --tw-gradient-to: #fbcfe8 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/0 {
  --tw-gradient-to: rgb(251 207 232 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/10 {
  --tw-gradient-to: rgb(251 207 232 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/100 {
  --tw-gradient-to: rgb(251 207 232 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/15 {
  --tw-gradient-to: rgb(251 207 232 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/20 {
  --tw-gradient-to: rgb(251 207 232 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/25 {
  --tw-gradient-to: rgb(251 207 232 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/30 {
  --tw-gradient-to: rgb(251 207 232 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/35 {
  --tw-gradient-to: rgb(251 207 232 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/40 {
  --tw-gradient-to: rgb(251 207 232 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/45 {
  --tw-gradient-to: rgb(251 207 232 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/5 {
  --tw-gradient-to: rgb(251 207 232 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/50 {
  --tw-gradient-to: rgb(251 207 232 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/55 {
  --tw-gradient-to: rgb(251 207 232 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/60 {
  --tw-gradient-to: rgb(251 207 232 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/65 {
  --tw-gradient-to: rgb(251 207 232 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/70 {
  --tw-gradient-to: rgb(251 207 232 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/75 {
  --tw-gradient-to: rgb(251 207 232 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/80 {
  --tw-gradient-to: rgb(251 207 232 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/85 {
  --tw-gradient-to: rgb(251 207 232 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/90 {
  --tw-gradient-to: rgb(251 207 232 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-200\/95 {
  --tw-gradient-to: rgb(251 207 232 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300 {
  --tw-gradient-to: #f9a8d4 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/0 {
  --tw-gradient-to: rgb(249 168 212 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/10 {
  --tw-gradient-to: rgb(249 168 212 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/100 {
  --tw-gradient-to: rgb(249 168 212 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/15 {
  --tw-gradient-to: rgb(249 168 212 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/20 {
  --tw-gradient-to: rgb(249 168 212 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/25 {
  --tw-gradient-to: rgb(249 168 212 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/30 {
  --tw-gradient-to: rgb(249 168 212 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/35 {
  --tw-gradient-to: rgb(249 168 212 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/40 {
  --tw-gradient-to: rgb(249 168 212 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/45 {
  --tw-gradient-to: rgb(249 168 212 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/5 {
  --tw-gradient-to: rgb(249 168 212 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/50 {
  --tw-gradient-to: rgb(249 168 212 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/55 {
  --tw-gradient-to: rgb(249 168 212 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/60 {
  --tw-gradient-to: rgb(249 168 212 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/65 {
  --tw-gradient-to: rgb(249 168 212 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/70 {
  --tw-gradient-to: rgb(249 168 212 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/75 {
  --tw-gradient-to: rgb(249 168 212 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/80 {
  --tw-gradient-to: rgb(249 168 212 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/85 {
  --tw-gradient-to: rgb(249 168 212 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/90 {
  --tw-gradient-to: rgb(249 168 212 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-300\/95 {
  --tw-gradient-to: rgb(249 168 212 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400 {
  --tw-gradient-to: #f472b6 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/0 {
  --tw-gradient-to: rgb(244 114 182 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/10 {
  --tw-gradient-to: rgb(244 114 182 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/100 {
  --tw-gradient-to: rgb(244 114 182 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/15 {
  --tw-gradient-to: rgb(244 114 182 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/20 {
  --tw-gradient-to: rgb(244 114 182 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/25 {
  --tw-gradient-to: rgb(244 114 182 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/30 {
  --tw-gradient-to: rgb(244 114 182 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/35 {
  --tw-gradient-to: rgb(244 114 182 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/40 {
  --tw-gradient-to: rgb(244 114 182 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/45 {
  --tw-gradient-to: rgb(244 114 182 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/5 {
  --tw-gradient-to: rgb(244 114 182 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/50 {
  --tw-gradient-to: rgb(244 114 182 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/55 {
  --tw-gradient-to: rgb(244 114 182 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/60 {
  --tw-gradient-to: rgb(244 114 182 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/65 {
  --tw-gradient-to: rgb(244 114 182 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/70 {
  --tw-gradient-to: rgb(244 114 182 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/75 {
  --tw-gradient-to: rgb(244 114 182 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/80 {
  --tw-gradient-to: rgb(244 114 182 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/85 {
  --tw-gradient-to: rgb(244 114 182 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/90 {
  --tw-gradient-to: rgb(244 114 182 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-400\/95 {
  --tw-gradient-to: rgb(244 114 182 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50 {
  --tw-gradient-to: #fdf2f8 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/0 {
  --tw-gradient-to: rgb(253 242 248 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/10 {
  --tw-gradient-to: rgb(253 242 248 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/100 {
  --tw-gradient-to: rgb(253 242 248 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/15 {
  --tw-gradient-to: rgb(253 242 248 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/20 {
  --tw-gradient-to: rgb(253 242 248 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/25 {
  --tw-gradient-to: rgb(253 242 248 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/30 {
  --tw-gradient-to: rgb(253 242 248 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/35 {
  --tw-gradient-to: rgb(253 242 248 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/40 {
  --tw-gradient-to: rgb(253 242 248 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/45 {
  --tw-gradient-to: rgb(253 242 248 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/5 {
  --tw-gradient-to: rgb(253 242 248 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/50 {
  --tw-gradient-to: rgb(253 242 248 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/55 {
  --tw-gradient-to: rgb(253 242 248 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/60 {
  --tw-gradient-to: rgb(253 242 248 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/65 {
  --tw-gradient-to: rgb(253 242 248 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/70 {
  --tw-gradient-to: rgb(253 242 248 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/75 {
  --tw-gradient-to: rgb(253 242 248 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/80 {
  --tw-gradient-to: rgb(253 242 248 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/85 {
  --tw-gradient-to: rgb(253 242 248 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/90 {
  --tw-gradient-to: rgb(253 242 248 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-50\/95 {
  --tw-gradient-to: rgb(253 242 248 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500 {
  --tw-gradient-to: #ec4899 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/0 {
  --tw-gradient-to: rgb(236 72 153 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/10 {
  --tw-gradient-to: rgb(236 72 153 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/100 {
  --tw-gradient-to: rgb(236 72 153 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/15 {
  --tw-gradient-to: rgb(236 72 153 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/20 {
  --tw-gradient-to: rgb(236 72 153 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/25 {
  --tw-gradient-to: rgb(236 72 153 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/30 {
  --tw-gradient-to: rgb(236 72 153 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/35 {
  --tw-gradient-to: rgb(236 72 153 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/40 {
  --tw-gradient-to: rgb(236 72 153 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/45 {
  --tw-gradient-to: rgb(236 72 153 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/5 {
  --tw-gradient-to: rgb(236 72 153 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/50 {
  --tw-gradient-to: rgb(236 72 153 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/55 {
  --tw-gradient-to: rgb(236 72 153 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/60 {
  --tw-gradient-to: rgb(236 72 153 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/65 {
  --tw-gradient-to: rgb(236 72 153 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/70 {
  --tw-gradient-to: rgb(236 72 153 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/75 {
  --tw-gradient-to: rgb(236 72 153 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/80 {
  --tw-gradient-to: rgb(236 72 153 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/85 {
  --tw-gradient-to: rgb(236 72 153 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/90 {
  --tw-gradient-to: rgb(236 72 153 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-500\/95 {
  --tw-gradient-to: rgb(236 72 153 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600 {
  --tw-gradient-to: #db2777 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/0 {
  --tw-gradient-to: rgb(219 39 119 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/10 {
  --tw-gradient-to: rgb(219 39 119 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/100 {
  --tw-gradient-to: rgb(219 39 119 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/15 {
  --tw-gradient-to: rgb(219 39 119 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/20 {
  --tw-gradient-to: rgb(219 39 119 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/25 {
  --tw-gradient-to: rgb(219 39 119 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/30 {
  --tw-gradient-to: rgb(219 39 119 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/35 {
  --tw-gradient-to: rgb(219 39 119 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/40 {
  --tw-gradient-to: rgb(219 39 119 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/45 {
  --tw-gradient-to: rgb(219 39 119 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/5 {
  --tw-gradient-to: rgb(219 39 119 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/50 {
  --tw-gradient-to: rgb(219 39 119 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/55 {
  --tw-gradient-to: rgb(219 39 119 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/60 {
  --tw-gradient-to: rgb(219 39 119 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/65 {
  --tw-gradient-to: rgb(219 39 119 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/70 {
  --tw-gradient-to: rgb(219 39 119 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/75 {
  --tw-gradient-to: rgb(219 39 119 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/80 {
  --tw-gradient-to: rgb(219 39 119 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/85 {
  --tw-gradient-to: rgb(219 39 119 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/90 {
  --tw-gradient-to: rgb(219 39 119 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-600\/95 {
  --tw-gradient-to: rgb(219 39 119 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700 {
  --tw-gradient-to: #be185d var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/0 {
  --tw-gradient-to: rgb(190 24 93 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/10 {
  --tw-gradient-to: rgb(190 24 93 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/100 {
  --tw-gradient-to: rgb(190 24 93 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/15 {
  --tw-gradient-to: rgb(190 24 93 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/20 {
  --tw-gradient-to: rgb(190 24 93 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/25 {
  --tw-gradient-to: rgb(190 24 93 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/30 {
  --tw-gradient-to: rgb(190 24 93 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/35 {
  --tw-gradient-to: rgb(190 24 93 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/40 {
  --tw-gradient-to: rgb(190 24 93 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/45 {
  --tw-gradient-to: rgb(190 24 93 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/5 {
  --tw-gradient-to: rgb(190 24 93 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/50 {
  --tw-gradient-to: rgb(190 24 93 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/55 {
  --tw-gradient-to: rgb(190 24 93 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/60 {
  --tw-gradient-to: rgb(190 24 93 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/65 {
  --tw-gradient-to: rgb(190 24 93 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/70 {
  --tw-gradient-to: rgb(190 24 93 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/75 {
  --tw-gradient-to: rgb(190 24 93 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/80 {
  --tw-gradient-to: rgb(190 24 93 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/85 {
  --tw-gradient-to: rgb(190 24 93 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/90 {
  --tw-gradient-to: rgb(190 24 93 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-700\/95 {
  --tw-gradient-to: rgb(190 24 93 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800 {
  --tw-gradient-to: #9d174d var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/0 {
  --tw-gradient-to: rgb(157 23 77 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/10 {
  --tw-gradient-to: rgb(157 23 77 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/100 {
  --tw-gradient-to: rgb(157 23 77 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/15 {
  --tw-gradient-to: rgb(157 23 77 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/20 {
  --tw-gradient-to: rgb(157 23 77 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/25 {
  --tw-gradient-to: rgb(157 23 77 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/30 {
  --tw-gradient-to: rgb(157 23 77 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/35 {
  --tw-gradient-to: rgb(157 23 77 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/40 {
  --tw-gradient-to: rgb(157 23 77 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/45 {
  --tw-gradient-to: rgb(157 23 77 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/5 {
  --tw-gradient-to: rgb(157 23 77 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/50 {
  --tw-gradient-to: rgb(157 23 77 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/55 {
  --tw-gradient-to: rgb(157 23 77 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/60 {
  --tw-gradient-to: rgb(157 23 77 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/65 {
  --tw-gradient-to: rgb(157 23 77 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/70 {
  --tw-gradient-to: rgb(157 23 77 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/75 {
  --tw-gradient-to: rgb(157 23 77 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/80 {
  --tw-gradient-to: rgb(157 23 77 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/85 {
  --tw-gradient-to: rgb(157 23 77 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/90 {
  --tw-gradient-to: rgb(157 23 77 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-800\/95 {
  --tw-gradient-to: rgb(157 23 77 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900 {
  --tw-gradient-to: #831843 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/0 {
  --tw-gradient-to: rgb(131 24 67 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/10 {
  --tw-gradient-to: rgb(131 24 67 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/100 {
  --tw-gradient-to: rgb(131 24 67 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/15 {
  --tw-gradient-to: rgb(131 24 67 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/20 {
  --tw-gradient-to: rgb(131 24 67 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/25 {
  --tw-gradient-to: rgb(131 24 67 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/30 {
  --tw-gradient-to: rgb(131 24 67 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/35 {
  --tw-gradient-to: rgb(131 24 67 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/40 {
  --tw-gradient-to: rgb(131 24 67 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/45 {
  --tw-gradient-to: rgb(131 24 67 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/5 {
  --tw-gradient-to: rgb(131 24 67 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/50 {
  --tw-gradient-to: rgb(131 24 67 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/55 {
  --tw-gradient-to: rgb(131 24 67 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/60 {
  --tw-gradient-to: rgb(131 24 67 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/65 {
  --tw-gradient-to: rgb(131 24 67 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/70 {
  --tw-gradient-to: rgb(131 24 67 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/75 {
  --tw-gradient-to: rgb(131 24 67 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/80 {
  --tw-gradient-to: rgb(131 24 67 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/85 {
  --tw-gradient-to: rgb(131 24 67 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/90 {
  --tw-gradient-to: rgb(131 24 67 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-900\/95 {
  --tw-gradient-to: rgb(131 24 67 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950 {
  --tw-gradient-to: #500724 var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/0 {
  --tw-gradient-to: rgb(80 7 36 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/10 {
  --tw-gradient-to: rgb(80 7 36 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/100 {
  --tw-gradient-to: rgb(80 7 36 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/15 {
  --tw-gradient-to: rgb(80 7 36 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/20 {
  --tw-gradient-to: rgb(80 7 36 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/25 {
  --tw-gradient-to: rgb(80 7 36 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/30 {
  --tw-gradient-to: rgb(80 7 36 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/35 {
  --tw-gradient-to: rgb(80 7 36 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/40 {
  --tw-gradient-to: rgb(80 7 36 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/45 {
  --tw-gradient-to: rgb(80 7 36 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/5 {
  --tw-gradient-to: rgb(80 7 36 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/50 {
  --tw-gradient-to: rgb(80 7 36 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/55 {
  --tw-gradient-to: rgb(80 7 36 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/60 {
  --tw-gradient-to: rgb(80 7 36 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/65 {
  --tw-gradient-to: rgb(80 7 36 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/70 {
  --tw-gradient-to: rgb(80 7 36 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/75 {
  --tw-gradient-to: rgb(80 7 36 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/80 {
  --tw-gradient-to: rgb(80 7 36 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/85 {
  --tw-gradient-to: rgb(80 7 36 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/90 {
  --tw-gradient-to: rgb(80 7 36 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-pink-950\/95 {
  --tw-gradient-to: rgb(80 7 36 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100 {
  --tw-gradient-to: #f3e8ff var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/0 {
  --tw-gradient-to: rgb(243 232 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/10 {
  --tw-gradient-to: rgb(243 232 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/100 {
  --tw-gradient-to: rgb(243 232 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/15 {
  --tw-gradient-to: rgb(243 232 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/20 {
  --tw-gradient-to: rgb(243 232 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/25 {
  --tw-gradient-to: rgb(243 232 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/30 {
  --tw-gradient-to: rgb(243 232 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/35 {
  --tw-gradient-to: rgb(243 232 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/40 {
  --tw-gradient-to: rgb(243 232 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/45 {
  --tw-gradient-to: rgb(243 232 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/5 {
  --tw-gradient-to: rgb(243 232 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/50 {
  --tw-gradient-to: rgb(243 232 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/55 {
  --tw-gradient-to: rgb(243 232 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/60 {
  --tw-gradient-to: rgb(243 232 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/65 {
  --tw-gradient-to: rgb(243 232 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/70 {
  --tw-gradient-to: rgb(243 232 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/75 {
  --tw-gradient-to: rgb(243 232 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/80 {
  --tw-gradient-to: rgb(243 232 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/85 {
  --tw-gradient-to: rgb(243 232 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/90 {
  --tw-gradient-to: rgb(243 232 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-100\/95 {
  --tw-gradient-to: rgb(243 232 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200 {
  --tw-gradient-to: #e9d5ff var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/0 {
  --tw-gradient-to: rgb(233 213 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/10 {
  --tw-gradient-to: rgb(233 213 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/100 {
  --tw-gradient-to: rgb(233 213 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/15 {
  --tw-gradient-to: rgb(233 213 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/20 {
  --tw-gradient-to: rgb(233 213 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/25 {
  --tw-gradient-to: rgb(233 213 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/30 {
  --tw-gradient-to: rgb(233 213 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/35 {
  --tw-gradient-to: rgb(233 213 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/40 {
  --tw-gradient-to: rgb(233 213 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/45 {
  --tw-gradient-to: rgb(233 213 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/5 {
  --tw-gradient-to: rgb(233 213 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/50 {
  --tw-gradient-to: rgb(233 213 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/55 {
  --tw-gradient-to: rgb(233 213 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/60 {
  --tw-gradient-to: rgb(233 213 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/65 {
  --tw-gradient-to: rgb(233 213 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/70 {
  --tw-gradient-to: rgb(233 213 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/75 {
  --tw-gradient-to: rgb(233 213 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/80 {
  --tw-gradient-to: rgb(233 213 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/85 {
  --tw-gradient-to: rgb(233 213 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/90 {
  --tw-gradient-to: rgb(233 213 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-200\/95 {
  --tw-gradient-to: rgb(233 213 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300 {
  --tw-gradient-to: #d8b4fe var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/0 {
  --tw-gradient-to: rgb(216 180 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/10 {
  --tw-gradient-to: rgb(216 180 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/100 {
  --tw-gradient-to: rgb(216 180 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/15 {
  --tw-gradient-to: rgb(216 180 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/20 {
  --tw-gradient-to: rgb(216 180 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/25 {
  --tw-gradient-to: rgb(216 180 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/30 {
  --tw-gradient-to: rgb(216 180 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/35 {
  --tw-gradient-to: rgb(216 180 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/40 {
  --tw-gradient-to: rgb(216 180 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/45 {
  --tw-gradient-to: rgb(216 180 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/5 {
  --tw-gradient-to: rgb(216 180 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/50 {
  --tw-gradient-to: rgb(216 180 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/55 {
  --tw-gradient-to: rgb(216 180 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/60 {
  --tw-gradient-to: rgb(216 180 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/65 {
  --tw-gradient-to: rgb(216 180 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/70 {
  --tw-gradient-to: rgb(216 180 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/75 {
  --tw-gradient-to: rgb(216 180 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/80 {
  --tw-gradient-to: rgb(216 180 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/85 {
  --tw-gradient-to: rgb(216 180 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/90 {
  --tw-gradient-to: rgb(216 180 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-300\/95 {
  --tw-gradient-to: rgb(216 180 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400 {
  --tw-gradient-to: #c084fc var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/0 {
  --tw-gradient-to: rgb(192 132 252 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/10 {
  --tw-gradient-to: rgb(192 132 252 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/100 {
  --tw-gradient-to: rgb(192 132 252 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/15 {
  --tw-gradient-to: rgb(192 132 252 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/20 {
  --tw-gradient-to: rgb(192 132 252 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/25 {
  --tw-gradient-to: rgb(192 132 252 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/30 {
  --tw-gradient-to: rgb(192 132 252 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/35 {
  --tw-gradient-to: rgb(192 132 252 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/40 {
  --tw-gradient-to: rgb(192 132 252 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/45 {
  --tw-gradient-to: rgb(192 132 252 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/5 {
  --tw-gradient-to: rgb(192 132 252 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/50 {
  --tw-gradient-to: rgb(192 132 252 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/55 {
  --tw-gradient-to: rgb(192 132 252 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/60 {
  --tw-gradient-to: rgb(192 132 252 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/65 {
  --tw-gradient-to: rgb(192 132 252 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/70 {
  --tw-gradient-to: rgb(192 132 252 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/75 {
  --tw-gradient-to: rgb(192 132 252 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/80 {
  --tw-gradient-to: rgb(192 132 252 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/85 {
  --tw-gradient-to: rgb(192 132 252 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/90 {
  --tw-gradient-to: rgb(192 132 252 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-400\/95 {
  --tw-gradient-to: rgb(192 132 252 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50 {
  --tw-gradient-to: #faf5ff var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/0 {
  --tw-gradient-to: rgb(250 245 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/10 {
  --tw-gradient-to: rgb(250 245 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/100 {
  --tw-gradient-to: rgb(250 245 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/15 {
  --tw-gradient-to: rgb(250 245 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/20 {
  --tw-gradient-to: rgb(250 245 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/25 {
  --tw-gradient-to: rgb(250 245 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/30 {
  --tw-gradient-to: rgb(250 245 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/35 {
  --tw-gradient-to: rgb(250 245 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/40 {
  --tw-gradient-to: rgb(250 245 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/45 {
  --tw-gradient-to: rgb(250 245 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/5 {
  --tw-gradient-to: rgb(250 245 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/50 {
  --tw-gradient-to: rgb(250 245 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/55 {
  --tw-gradient-to: rgb(250 245 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/60 {
  --tw-gradient-to: rgb(250 245 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/65 {
  --tw-gradient-to: rgb(250 245 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/70 {
  --tw-gradient-to: rgb(250 245 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/75 {
  --tw-gradient-to: rgb(250 245 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/80 {
  --tw-gradient-to: rgb(250 245 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/85 {
  --tw-gradient-to: rgb(250 245 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/90 {
  --tw-gradient-to: rgb(250 245 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-50\/95 {
  --tw-gradient-to: rgb(250 245 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500 {
  --tw-gradient-to: #a855f7 var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/0 {
  --tw-gradient-to: rgb(168 85 247 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/10 {
  --tw-gradient-to: rgb(168 85 247 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/100 {
  --tw-gradient-to: rgb(168 85 247 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/15 {
  --tw-gradient-to: rgb(168 85 247 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/20 {
  --tw-gradient-to: rgb(168 85 247 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/25 {
  --tw-gradient-to: rgb(168 85 247 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/30 {
  --tw-gradient-to: rgb(168 85 247 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/35 {
  --tw-gradient-to: rgb(168 85 247 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/40 {
  --tw-gradient-to: rgb(168 85 247 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/45 {
  --tw-gradient-to: rgb(168 85 247 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/5 {
  --tw-gradient-to: rgb(168 85 247 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/50 {
  --tw-gradient-to: rgb(168 85 247 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/55 {
  --tw-gradient-to: rgb(168 85 247 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/60 {
  --tw-gradient-to: rgb(168 85 247 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/65 {
  --tw-gradient-to: rgb(168 85 247 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/70 {
  --tw-gradient-to: rgb(168 85 247 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/75 {
  --tw-gradient-to: rgb(168 85 247 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/80 {
  --tw-gradient-to: rgb(168 85 247 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/85 {
  --tw-gradient-to: rgb(168 85 247 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/90 {
  --tw-gradient-to: rgb(168 85 247 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-500\/95 {
  --tw-gradient-to: rgb(168 85 247 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600 {
  --tw-gradient-to: #9333ea var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/0 {
  --tw-gradient-to: rgb(147 51 234 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/10 {
  --tw-gradient-to: rgb(147 51 234 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/100 {
  --tw-gradient-to: rgb(147 51 234 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/15 {
  --tw-gradient-to: rgb(147 51 234 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/20 {
  --tw-gradient-to: rgb(147 51 234 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/25 {
  --tw-gradient-to: rgb(147 51 234 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/30 {
  --tw-gradient-to: rgb(147 51 234 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/35 {
  --tw-gradient-to: rgb(147 51 234 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/40 {
  --tw-gradient-to: rgb(147 51 234 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/45 {
  --tw-gradient-to: rgb(147 51 234 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/5 {
  --tw-gradient-to: rgb(147 51 234 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/50 {
  --tw-gradient-to: rgb(147 51 234 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/55 {
  --tw-gradient-to: rgb(147 51 234 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/60 {
  --tw-gradient-to: rgb(147 51 234 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/65 {
  --tw-gradient-to: rgb(147 51 234 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/70 {
  --tw-gradient-to: rgb(147 51 234 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/75 {
  --tw-gradient-to: rgb(147 51 234 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/80 {
  --tw-gradient-to: rgb(147 51 234 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/85 {
  --tw-gradient-to: rgb(147 51 234 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/90 {
  --tw-gradient-to: rgb(147 51 234 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-600\/95 {
  --tw-gradient-to: rgb(147 51 234 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700 {
  --tw-gradient-to: #7e22ce var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/0 {
  --tw-gradient-to: rgb(126 34 206 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/10 {
  --tw-gradient-to: rgb(126 34 206 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/100 {
  --tw-gradient-to: rgb(126 34 206 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/15 {
  --tw-gradient-to: rgb(126 34 206 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/20 {
  --tw-gradient-to: rgb(126 34 206 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/25 {
  --tw-gradient-to: rgb(126 34 206 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/30 {
  --tw-gradient-to: rgb(126 34 206 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/35 {
  --tw-gradient-to: rgb(126 34 206 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/40 {
  --tw-gradient-to: rgb(126 34 206 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/45 {
  --tw-gradient-to: rgb(126 34 206 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/5 {
  --tw-gradient-to: rgb(126 34 206 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/50 {
  --tw-gradient-to: rgb(126 34 206 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/55 {
  --tw-gradient-to: rgb(126 34 206 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/60 {
  --tw-gradient-to: rgb(126 34 206 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/65 {
  --tw-gradient-to: rgb(126 34 206 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/70 {
  --tw-gradient-to: rgb(126 34 206 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/75 {
  --tw-gradient-to: rgb(126 34 206 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/80 {
  --tw-gradient-to: rgb(126 34 206 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/85 {
  --tw-gradient-to: rgb(126 34 206 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/90 {
  --tw-gradient-to: rgb(126 34 206 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-700\/95 {
  --tw-gradient-to: rgb(126 34 206 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800 {
  --tw-gradient-to: #6b21a8 var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/0 {
  --tw-gradient-to: rgb(107 33 168 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/10 {
  --tw-gradient-to: rgb(107 33 168 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/100 {
  --tw-gradient-to: rgb(107 33 168 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/15 {
  --tw-gradient-to: rgb(107 33 168 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/20 {
  --tw-gradient-to: rgb(107 33 168 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/25 {
  --tw-gradient-to: rgb(107 33 168 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/30 {
  --tw-gradient-to: rgb(107 33 168 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/35 {
  --tw-gradient-to: rgb(107 33 168 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/40 {
  --tw-gradient-to: rgb(107 33 168 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/45 {
  --tw-gradient-to: rgb(107 33 168 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/5 {
  --tw-gradient-to: rgb(107 33 168 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/50 {
  --tw-gradient-to: rgb(107 33 168 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/55 {
  --tw-gradient-to: rgb(107 33 168 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/60 {
  --tw-gradient-to: rgb(107 33 168 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/65 {
  --tw-gradient-to: rgb(107 33 168 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/70 {
  --tw-gradient-to: rgb(107 33 168 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/75 {
  --tw-gradient-to: rgb(107 33 168 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/80 {
  --tw-gradient-to: rgb(107 33 168 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/85 {
  --tw-gradient-to: rgb(107 33 168 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/90 {
  --tw-gradient-to: rgb(107 33 168 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-800\/95 {
  --tw-gradient-to: rgb(107 33 168 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900 {
  --tw-gradient-to: #581c87 var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/0 {
  --tw-gradient-to: rgb(88 28 135 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/10 {
  --tw-gradient-to: rgb(88 28 135 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/100 {
  --tw-gradient-to: rgb(88 28 135 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/15 {
  --tw-gradient-to: rgb(88 28 135 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/20 {
  --tw-gradient-to: rgb(88 28 135 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/25 {
  --tw-gradient-to: rgb(88 28 135 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/30 {
  --tw-gradient-to: rgb(88 28 135 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/35 {
  --tw-gradient-to: rgb(88 28 135 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/40 {
  --tw-gradient-to: rgb(88 28 135 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/45 {
  --tw-gradient-to: rgb(88 28 135 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/5 {
  --tw-gradient-to: rgb(88 28 135 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/50 {
  --tw-gradient-to: rgb(88 28 135 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/55 {
  --tw-gradient-to: rgb(88 28 135 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/60 {
  --tw-gradient-to: rgb(88 28 135 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/65 {
  --tw-gradient-to: rgb(88 28 135 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/70 {
  --tw-gradient-to: rgb(88 28 135 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/75 {
  --tw-gradient-to: rgb(88 28 135 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/80 {
  --tw-gradient-to: rgb(88 28 135 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/85 {
  --tw-gradient-to: rgb(88 28 135 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/90 {
  --tw-gradient-to: rgb(88 28 135 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-900\/95 {
  --tw-gradient-to: rgb(88 28 135 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950 {
  --tw-gradient-to: #3b0764 var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/0 {
  --tw-gradient-to: rgb(59 7 100 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/10 {
  --tw-gradient-to: rgb(59 7 100 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/100 {
  --tw-gradient-to: rgb(59 7 100 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/15 {
  --tw-gradient-to: rgb(59 7 100 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/20 {
  --tw-gradient-to: rgb(59 7 100 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/25 {
  --tw-gradient-to: rgb(59 7 100 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/30 {
  --tw-gradient-to: rgb(59 7 100 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/35 {
  --tw-gradient-to: rgb(59 7 100 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/40 {
  --tw-gradient-to: rgb(59 7 100 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/45 {
  --tw-gradient-to: rgb(59 7 100 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/5 {
  --tw-gradient-to: rgb(59 7 100 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/50 {
  --tw-gradient-to: rgb(59 7 100 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/55 {
  --tw-gradient-to: rgb(59 7 100 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/60 {
  --tw-gradient-to: rgb(59 7 100 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/65 {
  --tw-gradient-to: rgb(59 7 100 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/70 {
  --tw-gradient-to: rgb(59 7 100 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/75 {
  --tw-gradient-to: rgb(59 7 100 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/80 {
  --tw-gradient-to: rgb(59 7 100 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/85 {
  --tw-gradient-to: rgb(59 7 100 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/90 {
  --tw-gradient-to: rgb(59 7 100 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-purple-950\/95 {
  --tw-gradient-to: rgb(59 7 100 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100 {
  --tw-gradient-to: #fee2e2 var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/0 {
  --tw-gradient-to: rgb(254 226 226 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/10 {
  --tw-gradient-to: rgb(254 226 226 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/100 {
  --tw-gradient-to: rgb(254 226 226 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/15 {
  --tw-gradient-to: rgb(254 226 226 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/20 {
  --tw-gradient-to: rgb(254 226 226 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/25 {
  --tw-gradient-to: rgb(254 226 226 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/30 {
  --tw-gradient-to: rgb(254 226 226 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/35 {
  --tw-gradient-to: rgb(254 226 226 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/40 {
  --tw-gradient-to: rgb(254 226 226 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/45 {
  --tw-gradient-to: rgb(254 226 226 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/5 {
  --tw-gradient-to: rgb(254 226 226 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/50 {
  --tw-gradient-to: rgb(254 226 226 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/55 {
  --tw-gradient-to: rgb(254 226 226 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/60 {
  --tw-gradient-to: rgb(254 226 226 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/65 {
  --tw-gradient-to: rgb(254 226 226 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/70 {
  --tw-gradient-to: rgb(254 226 226 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/75 {
  --tw-gradient-to: rgb(254 226 226 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/80 {
  --tw-gradient-to: rgb(254 226 226 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/85 {
  --tw-gradient-to: rgb(254 226 226 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/90 {
  --tw-gradient-to: rgb(254 226 226 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-100\/95 {
  --tw-gradient-to: rgb(254 226 226 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200 {
  --tw-gradient-to: #fecaca var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/0 {
  --tw-gradient-to: rgb(254 202 202 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/10 {
  --tw-gradient-to: rgb(254 202 202 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/100 {
  --tw-gradient-to: rgb(254 202 202 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/15 {
  --tw-gradient-to: rgb(254 202 202 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/20 {
  --tw-gradient-to: rgb(254 202 202 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/25 {
  --tw-gradient-to: rgb(254 202 202 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/30 {
  --tw-gradient-to: rgb(254 202 202 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/35 {
  --tw-gradient-to: rgb(254 202 202 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/40 {
  --tw-gradient-to: rgb(254 202 202 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/45 {
  --tw-gradient-to: rgb(254 202 202 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/5 {
  --tw-gradient-to: rgb(254 202 202 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/50 {
  --tw-gradient-to: rgb(254 202 202 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/55 {
  --tw-gradient-to: rgb(254 202 202 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/60 {
  --tw-gradient-to: rgb(254 202 202 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/65 {
  --tw-gradient-to: rgb(254 202 202 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/70 {
  --tw-gradient-to: rgb(254 202 202 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/75 {
  --tw-gradient-to: rgb(254 202 202 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/80 {
  --tw-gradient-to: rgb(254 202 202 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/85 {
  --tw-gradient-to: rgb(254 202 202 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/90 {
  --tw-gradient-to: rgb(254 202 202 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-200\/95 {
  --tw-gradient-to: rgb(254 202 202 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300 {
  --tw-gradient-to: #fca5a5 var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/0 {
  --tw-gradient-to: rgb(252 165 165 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/10 {
  --tw-gradient-to: rgb(252 165 165 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/100 {
  --tw-gradient-to: rgb(252 165 165 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/15 {
  --tw-gradient-to: rgb(252 165 165 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/20 {
  --tw-gradient-to: rgb(252 165 165 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/25 {
  --tw-gradient-to: rgb(252 165 165 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/30 {
  --tw-gradient-to: rgb(252 165 165 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/35 {
  --tw-gradient-to: rgb(252 165 165 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/40 {
  --tw-gradient-to: rgb(252 165 165 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/45 {
  --tw-gradient-to: rgb(252 165 165 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/5 {
  --tw-gradient-to: rgb(252 165 165 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/50 {
  --tw-gradient-to: rgb(252 165 165 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/55 {
  --tw-gradient-to: rgb(252 165 165 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/60 {
  --tw-gradient-to: rgb(252 165 165 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/65 {
  --tw-gradient-to: rgb(252 165 165 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/70 {
  --tw-gradient-to: rgb(252 165 165 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/75 {
  --tw-gradient-to: rgb(252 165 165 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/80 {
  --tw-gradient-to: rgb(252 165 165 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/85 {
  --tw-gradient-to: rgb(252 165 165 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/90 {
  --tw-gradient-to: rgb(252 165 165 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-300\/95 {
  --tw-gradient-to: rgb(252 165 165 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400 {
  --tw-gradient-to: #f87171 var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/0 {
  --tw-gradient-to: rgb(248 113 113 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/10 {
  --tw-gradient-to: rgb(248 113 113 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/100 {
  --tw-gradient-to: rgb(248 113 113 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/15 {
  --tw-gradient-to: rgb(248 113 113 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/20 {
  --tw-gradient-to: rgb(248 113 113 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/25 {
  --tw-gradient-to: rgb(248 113 113 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/30 {
  --tw-gradient-to: rgb(248 113 113 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/35 {
  --tw-gradient-to: rgb(248 113 113 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/40 {
  --tw-gradient-to: rgb(248 113 113 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/45 {
  --tw-gradient-to: rgb(248 113 113 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/5 {
  --tw-gradient-to: rgb(248 113 113 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/50 {
  --tw-gradient-to: rgb(248 113 113 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/55 {
  --tw-gradient-to: rgb(248 113 113 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/60 {
  --tw-gradient-to: rgb(248 113 113 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/65 {
  --tw-gradient-to: rgb(248 113 113 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/70 {
  --tw-gradient-to: rgb(248 113 113 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/75 {
  --tw-gradient-to: rgb(248 113 113 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/80 {
  --tw-gradient-to: rgb(248 113 113 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/85 {
  --tw-gradient-to: rgb(248 113 113 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/90 {
  --tw-gradient-to: rgb(248 113 113 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-400\/95 {
  --tw-gradient-to: rgb(248 113 113 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50 {
  --tw-gradient-to: #fef2f2 var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/0 {
  --tw-gradient-to: rgb(254 242 242 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/10 {
  --tw-gradient-to: rgb(254 242 242 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/100 {
  --tw-gradient-to: rgb(254 242 242 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/15 {
  --tw-gradient-to: rgb(254 242 242 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/20 {
  --tw-gradient-to: rgb(254 242 242 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/25 {
  --tw-gradient-to: rgb(254 242 242 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/30 {
  --tw-gradient-to: rgb(254 242 242 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/35 {
  --tw-gradient-to: rgb(254 242 242 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/40 {
  --tw-gradient-to: rgb(254 242 242 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/45 {
  --tw-gradient-to: rgb(254 242 242 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/5 {
  --tw-gradient-to: rgb(254 242 242 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/50 {
  --tw-gradient-to: rgb(254 242 242 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/55 {
  --tw-gradient-to: rgb(254 242 242 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/60 {
  --tw-gradient-to: rgb(254 242 242 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/65 {
  --tw-gradient-to: rgb(254 242 242 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/70 {
  --tw-gradient-to: rgb(254 242 242 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/75 {
  --tw-gradient-to: rgb(254 242 242 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/80 {
  --tw-gradient-to: rgb(254 242 242 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/85 {
  --tw-gradient-to: rgb(254 242 242 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/90 {
  --tw-gradient-to: rgb(254 242 242 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-50\/95 {
  --tw-gradient-to: rgb(254 242 242 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500 {
  --tw-gradient-to: #ef4444 var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/0 {
  --tw-gradient-to: rgb(239 68 68 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/10 {
  --tw-gradient-to: rgb(239 68 68 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/100 {
  --tw-gradient-to: rgb(239 68 68 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/15 {
  --tw-gradient-to: rgb(239 68 68 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/20 {
  --tw-gradient-to: rgb(239 68 68 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/25 {
  --tw-gradient-to: rgb(239 68 68 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/30 {
  --tw-gradient-to: rgb(239 68 68 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/35 {
  --tw-gradient-to: rgb(239 68 68 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/40 {
  --tw-gradient-to: rgb(239 68 68 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/45 {
  --tw-gradient-to: rgb(239 68 68 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/5 {
  --tw-gradient-to: rgb(239 68 68 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/50 {
  --tw-gradient-to: rgb(239 68 68 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/55 {
  --tw-gradient-to: rgb(239 68 68 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/60 {
  --tw-gradient-to: rgb(239 68 68 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/65 {
  --tw-gradient-to: rgb(239 68 68 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/70 {
  --tw-gradient-to: rgb(239 68 68 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/75 {
  --tw-gradient-to: rgb(239 68 68 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/80 {
  --tw-gradient-to: rgb(239 68 68 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/85 {
  --tw-gradient-to: rgb(239 68 68 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/90 {
  --tw-gradient-to: rgb(239 68 68 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-500\/95 {
  --tw-gradient-to: rgb(239 68 68 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600 {
  --tw-gradient-to: #dc2626 var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/0 {
  --tw-gradient-to: rgb(220 38 38 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/10 {
  --tw-gradient-to: rgb(220 38 38 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/100 {
  --tw-gradient-to: rgb(220 38 38 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/15 {
  --tw-gradient-to: rgb(220 38 38 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/20 {
  --tw-gradient-to: rgb(220 38 38 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/25 {
  --tw-gradient-to: rgb(220 38 38 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/30 {
  --tw-gradient-to: rgb(220 38 38 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/35 {
  --tw-gradient-to: rgb(220 38 38 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/40 {
  --tw-gradient-to: rgb(220 38 38 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/45 {
  --tw-gradient-to: rgb(220 38 38 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/5 {
  --tw-gradient-to: rgb(220 38 38 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/50 {
  --tw-gradient-to: rgb(220 38 38 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/55 {
  --tw-gradient-to: rgb(220 38 38 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/60 {
  --tw-gradient-to: rgb(220 38 38 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/65 {
  --tw-gradient-to: rgb(220 38 38 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/70 {
  --tw-gradient-to: rgb(220 38 38 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/75 {
  --tw-gradient-to: rgb(220 38 38 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/80 {
  --tw-gradient-to: rgb(220 38 38 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/85 {
  --tw-gradient-to: rgb(220 38 38 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/90 {
  --tw-gradient-to: rgb(220 38 38 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-600\/95 {
  --tw-gradient-to: rgb(220 38 38 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700 {
  --tw-gradient-to: #b91c1c var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/0 {
  --tw-gradient-to: rgb(185 28 28 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/10 {
  --tw-gradient-to: rgb(185 28 28 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/100 {
  --tw-gradient-to: rgb(185 28 28 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/15 {
  --tw-gradient-to: rgb(185 28 28 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/20 {
  --tw-gradient-to: rgb(185 28 28 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/25 {
  --tw-gradient-to: rgb(185 28 28 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/30 {
  --tw-gradient-to: rgb(185 28 28 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/35 {
  --tw-gradient-to: rgb(185 28 28 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/40 {
  --tw-gradient-to: rgb(185 28 28 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/45 {
  --tw-gradient-to: rgb(185 28 28 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/5 {
  --tw-gradient-to: rgb(185 28 28 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/50 {
  --tw-gradient-to: rgb(185 28 28 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/55 {
  --tw-gradient-to: rgb(185 28 28 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/60 {
  --tw-gradient-to: rgb(185 28 28 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/65 {
  --tw-gradient-to: rgb(185 28 28 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/70 {
  --tw-gradient-to: rgb(185 28 28 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/75 {
  --tw-gradient-to: rgb(185 28 28 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/80 {
  --tw-gradient-to: rgb(185 28 28 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/85 {
  --tw-gradient-to: rgb(185 28 28 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/90 {
  --tw-gradient-to: rgb(185 28 28 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-700\/95 {
  --tw-gradient-to: rgb(185 28 28 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800 {
  --tw-gradient-to: #991b1b var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/0 {
  --tw-gradient-to: rgb(153 27 27 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/10 {
  --tw-gradient-to: rgb(153 27 27 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/100 {
  --tw-gradient-to: rgb(153 27 27 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/15 {
  --tw-gradient-to: rgb(153 27 27 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/20 {
  --tw-gradient-to: rgb(153 27 27 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/25 {
  --tw-gradient-to: rgb(153 27 27 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/30 {
  --tw-gradient-to: rgb(153 27 27 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/35 {
  --tw-gradient-to: rgb(153 27 27 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/40 {
  --tw-gradient-to: rgb(153 27 27 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/45 {
  --tw-gradient-to: rgb(153 27 27 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/5 {
  --tw-gradient-to: rgb(153 27 27 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/50 {
  --tw-gradient-to: rgb(153 27 27 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/55 {
  --tw-gradient-to: rgb(153 27 27 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/60 {
  --tw-gradient-to: rgb(153 27 27 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/65 {
  --tw-gradient-to: rgb(153 27 27 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/70 {
  --tw-gradient-to: rgb(153 27 27 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/75 {
  --tw-gradient-to: rgb(153 27 27 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/80 {
  --tw-gradient-to: rgb(153 27 27 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/85 {
  --tw-gradient-to: rgb(153 27 27 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/90 {
  --tw-gradient-to: rgb(153 27 27 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-800\/95 {
  --tw-gradient-to: rgb(153 27 27 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900 {
  --tw-gradient-to: #7f1d1d var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/0 {
  --tw-gradient-to: rgb(127 29 29 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/10 {
  --tw-gradient-to: rgb(127 29 29 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/100 {
  --tw-gradient-to: rgb(127 29 29 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/15 {
  --tw-gradient-to: rgb(127 29 29 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/20 {
  --tw-gradient-to: rgb(127 29 29 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/25 {
  --tw-gradient-to: rgb(127 29 29 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/30 {
  --tw-gradient-to: rgb(127 29 29 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/35 {
  --tw-gradient-to: rgb(127 29 29 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/40 {
  --tw-gradient-to: rgb(127 29 29 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/45 {
  --tw-gradient-to: rgb(127 29 29 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/5 {
  --tw-gradient-to: rgb(127 29 29 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/50 {
  --tw-gradient-to: rgb(127 29 29 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/55 {
  --tw-gradient-to: rgb(127 29 29 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/60 {
  --tw-gradient-to: rgb(127 29 29 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/65 {
  --tw-gradient-to: rgb(127 29 29 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/70 {
  --tw-gradient-to: rgb(127 29 29 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/75 {
  --tw-gradient-to: rgb(127 29 29 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/80 {
  --tw-gradient-to: rgb(127 29 29 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/85 {
  --tw-gradient-to: rgb(127 29 29 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/90 {
  --tw-gradient-to: rgb(127 29 29 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-900\/95 {
  --tw-gradient-to: rgb(127 29 29 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950 {
  --tw-gradient-to: #450a0a var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/0 {
  --tw-gradient-to: rgb(69 10 10 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/10 {
  --tw-gradient-to: rgb(69 10 10 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/100 {
  --tw-gradient-to: rgb(69 10 10 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/15 {
  --tw-gradient-to: rgb(69 10 10 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/20 {
  --tw-gradient-to: rgb(69 10 10 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/25 {
  --tw-gradient-to: rgb(69 10 10 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/30 {
  --tw-gradient-to: rgb(69 10 10 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/35 {
  --tw-gradient-to: rgb(69 10 10 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/40 {
  --tw-gradient-to: rgb(69 10 10 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/45 {
  --tw-gradient-to: rgb(69 10 10 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/5 {
  --tw-gradient-to: rgb(69 10 10 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/50 {
  --tw-gradient-to: rgb(69 10 10 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/55 {
  --tw-gradient-to: rgb(69 10 10 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/60 {
  --tw-gradient-to: rgb(69 10 10 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/65 {
  --tw-gradient-to: rgb(69 10 10 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/70 {
  --tw-gradient-to: rgb(69 10 10 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/75 {
  --tw-gradient-to: rgb(69 10 10 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/80 {
  --tw-gradient-to: rgb(69 10 10 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/85 {
  --tw-gradient-to: rgb(69 10 10 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/90 {
  --tw-gradient-to: rgb(69 10 10 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-red-950\/95 {
  --tw-gradient-to: rgb(69 10 10 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100 {
  --tw-gradient-to: #ffe4e6 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/0 {
  --tw-gradient-to: rgb(255 228 230 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/10 {
  --tw-gradient-to: rgb(255 228 230 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/100 {
  --tw-gradient-to: rgb(255 228 230 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/15 {
  --tw-gradient-to: rgb(255 228 230 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/20 {
  --tw-gradient-to: rgb(255 228 230 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/25 {
  --tw-gradient-to: rgb(255 228 230 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/30 {
  --tw-gradient-to: rgb(255 228 230 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/35 {
  --tw-gradient-to: rgb(255 228 230 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/40 {
  --tw-gradient-to: rgb(255 228 230 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/45 {
  --tw-gradient-to: rgb(255 228 230 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/5 {
  --tw-gradient-to: rgb(255 228 230 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/50 {
  --tw-gradient-to: rgb(255 228 230 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/55 {
  --tw-gradient-to: rgb(255 228 230 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/60 {
  --tw-gradient-to: rgb(255 228 230 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/65 {
  --tw-gradient-to: rgb(255 228 230 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/70 {
  --tw-gradient-to: rgb(255 228 230 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/75 {
  --tw-gradient-to: rgb(255 228 230 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/80 {
  --tw-gradient-to: rgb(255 228 230 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/85 {
  --tw-gradient-to: rgb(255 228 230 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/90 {
  --tw-gradient-to: rgb(255 228 230 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-100\/95 {
  --tw-gradient-to: rgb(255 228 230 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200 {
  --tw-gradient-to: #fecdd3 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/0 {
  --tw-gradient-to: rgb(254 205 211 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/10 {
  --tw-gradient-to: rgb(254 205 211 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/100 {
  --tw-gradient-to: rgb(254 205 211 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/15 {
  --tw-gradient-to: rgb(254 205 211 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/20 {
  --tw-gradient-to: rgb(254 205 211 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/25 {
  --tw-gradient-to: rgb(254 205 211 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/30 {
  --tw-gradient-to: rgb(254 205 211 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/35 {
  --tw-gradient-to: rgb(254 205 211 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/40 {
  --tw-gradient-to: rgb(254 205 211 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/45 {
  --tw-gradient-to: rgb(254 205 211 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/5 {
  --tw-gradient-to: rgb(254 205 211 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/50 {
  --tw-gradient-to: rgb(254 205 211 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/55 {
  --tw-gradient-to: rgb(254 205 211 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/60 {
  --tw-gradient-to: rgb(254 205 211 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/65 {
  --tw-gradient-to: rgb(254 205 211 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/70 {
  --tw-gradient-to: rgb(254 205 211 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/75 {
  --tw-gradient-to: rgb(254 205 211 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/80 {
  --tw-gradient-to: rgb(254 205 211 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/85 {
  --tw-gradient-to: rgb(254 205 211 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/90 {
  --tw-gradient-to: rgb(254 205 211 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-200\/95 {
  --tw-gradient-to: rgb(254 205 211 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300 {
  --tw-gradient-to: #fda4af var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/0 {
  --tw-gradient-to: rgb(253 164 175 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/10 {
  --tw-gradient-to: rgb(253 164 175 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/100 {
  --tw-gradient-to: rgb(253 164 175 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/15 {
  --tw-gradient-to: rgb(253 164 175 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/20 {
  --tw-gradient-to: rgb(253 164 175 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/25 {
  --tw-gradient-to: rgb(253 164 175 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/30 {
  --tw-gradient-to: rgb(253 164 175 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/35 {
  --tw-gradient-to: rgb(253 164 175 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/40 {
  --tw-gradient-to: rgb(253 164 175 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/45 {
  --tw-gradient-to: rgb(253 164 175 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/5 {
  --tw-gradient-to: rgb(253 164 175 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/50 {
  --tw-gradient-to: rgb(253 164 175 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/55 {
  --tw-gradient-to: rgb(253 164 175 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/60 {
  --tw-gradient-to: rgb(253 164 175 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/65 {
  --tw-gradient-to: rgb(253 164 175 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/70 {
  --tw-gradient-to: rgb(253 164 175 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/75 {
  --tw-gradient-to: rgb(253 164 175 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/80 {
  --tw-gradient-to: rgb(253 164 175 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/85 {
  --tw-gradient-to: rgb(253 164 175 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/90 {
  --tw-gradient-to: rgb(253 164 175 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-300\/95 {
  --tw-gradient-to: rgb(253 164 175 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400 {
  --tw-gradient-to: #fb7185 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/0 {
  --tw-gradient-to: rgb(251 113 133 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/10 {
  --tw-gradient-to: rgb(251 113 133 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/100 {
  --tw-gradient-to: rgb(251 113 133 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/15 {
  --tw-gradient-to: rgb(251 113 133 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/20 {
  --tw-gradient-to: rgb(251 113 133 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/25 {
  --tw-gradient-to: rgb(251 113 133 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/30 {
  --tw-gradient-to: rgb(251 113 133 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/35 {
  --tw-gradient-to: rgb(251 113 133 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/40 {
  --tw-gradient-to: rgb(251 113 133 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/45 {
  --tw-gradient-to: rgb(251 113 133 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/5 {
  --tw-gradient-to: rgb(251 113 133 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/50 {
  --tw-gradient-to: rgb(251 113 133 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/55 {
  --tw-gradient-to: rgb(251 113 133 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/60 {
  --tw-gradient-to: rgb(251 113 133 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/65 {
  --tw-gradient-to: rgb(251 113 133 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/70 {
  --tw-gradient-to: rgb(251 113 133 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/75 {
  --tw-gradient-to: rgb(251 113 133 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/80 {
  --tw-gradient-to: rgb(251 113 133 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/85 {
  --tw-gradient-to: rgb(251 113 133 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/90 {
  --tw-gradient-to: rgb(251 113 133 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-400\/95 {
  --tw-gradient-to: rgb(251 113 133 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50 {
  --tw-gradient-to: #fff1f2 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/0 {
  --tw-gradient-to: rgb(255 241 242 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/10 {
  --tw-gradient-to: rgb(255 241 242 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/100 {
  --tw-gradient-to: rgb(255 241 242 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/15 {
  --tw-gradient-to: rgb(255 241 242 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/20 {
  --tw-gradient-to: rgb(255 241 242 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/25 {
  --tw-gradient-to: rgb(255 241 242 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/30 {
  --tw-gradient-to: rgb(255 241 242 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/35 {
  --tw-gradient-to: rgb(255 241 242 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/40 {
  --tw-gradient-to: rgb(255 241 242 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/45 {
  --tw-gradient-to: rgb(255 241 242 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/5 {
  --tw-gradient-to: rgb(255 241 242 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/50 {
  --tw-gradient-to: rgb(255 241 242 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/55 {
  --tw-gradient-to: rgb(255 241 242 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/60 {
  --tw-gradient-to: rgb(255 241 242 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/65 {
  --tw-gradient-to: rgb(255 241 242 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/70 {
  --tw-gradient-to: rgb(255 241 242 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/75 {
  --tw-gradient-to: rgb(255 241 242 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/80 {
  --tw-gradient-to: rgb(255 241 242 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/85 {
  --tw-gradient-to: rgb(255 241 242 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/90 {
  --tw-gradient-to: rgb(255 241 242 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-50\/95 {
  --tw-gradient-to: rgb(255 241 242 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500 {
  --tw-gradient-to: #f43f5e var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/0 {
  --tw-gradient-to: rgb(244 63 94 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/10 {
  --tw-gradient-to: rgb(244 63 94 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/100 {
  --tw-gradient-to: rgb(244 63 94 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/15 {
  --tw-gradient-to: rgb(244 63 94 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/20 {
  --tw-gradient-to: rgb(244 63 94 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/25 {
  --tw-gradient-to: rgb(244 63 94 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/30 {
  --tw-gradient-to: rgb(244 63 94 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/35 {
  --tw-gradient-to: rgb(244 63 94 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/40 {
  --tw-gradient-to: rgb(244 63 94 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/45 {
  --tw-gradient-to: rgb(244 63 94 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/5 {
  --tw-gradient-to: rgb(244 63 94 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/50 {
  --tw-gradient-to: rgb(244 63 94 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/55 {
  --tw-gradient-to: rgb(244 63 94 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/60 {
  --tw-gradient-to: rgb(244 63 94 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/65 {
  --tw-gradient-to: rgb(244 63 94 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/70 {
  --tw-gradient-to: rgb(244 63 94 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/75 {
  --tw-gradient-to: rgb(244 63 94 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/80 {
  --tw-gradient-to: rgb(244 63 94 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/85 {
  --tw-gradient-to: rgb(244 63 94 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/90 {
  --tw-gradient-to: rgb(244 63 94 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-500\/95 {
  --tw-gradient-to: rgb(244 63 94 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600 {
  --tw-gradient-to: #e11d48 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/0 {
  --tw-gradient-to: rgb(225 29 72 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/10 {
  --tw-gradient-to: rgb(225 29 72 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/100 {
  --tw-gradient-to: rgb(225 29 72 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/15 {
  --tw-gradient-to: rgb(225 29 72 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/20 {
  --tw-gradient-to: rgb(225 29 72 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/25 {
  --tw-gradient-to: rgb(225 29 72 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/30 {
  --tw-gradient-to: rgb(225 29 72 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/35 {
  --tw-gradient-to: rgb(225 29 72 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/40 {
  --tw-gradient-to: rgb(225 29 72 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/45 {
  --tw-gradient-to: rgb(225 29 72 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/5 {
  --tw-gradient-to: rgb(225 29 72 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/50 {
  --tw-gradient-to: rgb(225 29 72 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/55 {
  --tw-gradient-to: rgb(225 29 72 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/60 {
  --tw-gradient-to: rgb(225 29 72 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/65 {
  --tw-gradient-to: rgb(225 29 72 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/70 {
  --tw-gradient-to: rgb(225 29 72 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/75 {
  --tw-gradient-to: rgb(225 29 72 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/80 {
  --tw-gradient-to: rgb(225 29 72 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/85 {
  --tw-gradient-to: rgb(225 29 72 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/90 {
  --tw-gradient-to: rgb(225 29 72 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-600\/95 {
  --tw-gradient-to: rgb(225 29 72 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700 {
  --tw-gradient-to: #be123c var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/0 {
  --tw-gradient-to: rgb(190 18 60 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/10 {
  --tw-gradient-to: rgb(190 18 60 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/100 {
  --tw-gradient-to: rgb(190 18 60 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/15 {
  --tw-gradient-to: rgb(190 18 60 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/20 {
  --tw-gradient-to: rgb(190 18 60 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/25 {
  --tw-gradient-to: rgb(190 18 60 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/30 {
  --tw-gradient-to: rgb(190 18 60 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/35 {
  --tw-gradient-to: rgb(190 18 60 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/40 {
  --tw-gradient-to: rgb(190 18 60 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/45 {
  --tw-gradient-to: rgb(190 18 60 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/5 {
  --tw-gradient-to: rgb(190 18 60 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/50 {
  --tw-gradient-to: rgb(190 18 60 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/55 {
  --tw-gradient-to: rgb(190 18 60 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/60 {
  --tw-gradient-to: rgb(190 18 60 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/65 {
  --tw-gradient-to: rgb(190 18 60 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/70 {
  --tw-gradient-to: rgb(190 18 60 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/75 {
  --tw-gradient-to: rgb(190 18 60 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/80 {
  --tw-gradient-to: rgb(190 18 60 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/85 {
  --tw-gradient-to: rgb(190 18 60 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/90 {
  --tw-gradient-to: rgb(190 18 60 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-700\/95 {
  --tw-gradient-to: rgb(190 18 60 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800 {
  --tw-gradient-to: #9f1239 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/0 {
  --tw-gradient-to: rgb(159 18 57 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/10 {
  --tw-gradient-to: rgb(159 18 57 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/100 {
  --tw-gradient-to: rgb(159 18 57 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/15 {
  --tw-gradient-to: rgb(159 18 57 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/20 {
  --tw-gradient-to: rgb(159 18 57 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/25 {
  --tw-gradient-to: rgb(159 18 57 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/30 {
  --tw-gradient-to: rgb(159 18 57 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/35 {
  --tw-gradient-to: rgb(159 18 57 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/40 {
  --tw-gradient-to: rgb(159 18 57 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/45 {
  --tw-gradient-to: rgb(159 18 57 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/5 {
  --tw-gradient-to: rgb(159 18 57 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/50 {
  --tw-gradient-to: rgb(159 18 57 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/55 {
  --tw-gradient-to: rgb(159 18 57 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/60 {
  --tw-gradient-to: rgb(159 18 57 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/65 {
  --tw-gradient-to: rgb(159 18 57 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/70 {
  --tw-gradient-to: rgb(159 18 57 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/75 {
  --tw-gradient-to: rgb(159 18 57 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/80 {
  --tw-gradient-to: rgb(159 18 57 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/85 {
  --tw-gradient-to: rgb(159 18 57 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/90 {
  --tw-gradient-to: rgb(159 18 57 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-800\/95 {
  --tw-gradient-to: rgb(159 18 57 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900 {
  --tw-gradient-to: #881337 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/0 {
  --tw-gradient-to: rgb(136 19 55 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/10 {
  --tw-gradient-to: rgb(136 19 55 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/100 {
  --tw-gradient-to: rgb(136 19 55 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/15 {
  --tw-gradient-to: rgb(136 19 55 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/20 {
  --tw-gradient-to: rgb(136 19 55 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/25 {
  --tw-gradient-to: rgb(136 19 55 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/30 {
  --tw-gradient-to: rgb(136 19 55 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/35 {
  --tw-gradient-to: rgb(136 19 55 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/40 {
  --tw-gradient-to: rgb(136 19 55 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/45 {
  --tw-gradient-to: rgb(136 19 55 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/5 {
  --tw-gradient-to: rgb(136 19 55 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/50 {
  --tw-gradient-to: rgb(136 19 55 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/55 {
  --tw-gradient-to: rgb(136 19 55 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/60 {
  --tw-gradient-to: rgb(136 19 55 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/65 {
  --tw-gradient-to: rgb(136 19 55 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/70 {
  --tw-gradient-to: rgb(136 19 55 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/75 {
  --tw-gradient-to: rgb(136 19 55 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/80 {
  --tw-gradient-to: rgb(136 19 55 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/85 {
  --tw-gradient-to: rgb(136 19 55 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/90 {
  --tw-gradient-to: rgb(136 19 55 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-900\/95 {
  --tw-gradient-to: rgb(136 19 55 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950 {
  --tw-gradient-to: #4c0519 var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/0 {
  --tw-gradient-to: rgb(76 5 25 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/10 {
  --tw-gradient-to: rgb(76 5 25 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/100 {
  --tw-gradient-to: rgb(76 5 25 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/15 {
  --tw-gradient-to: rgb(76 5 25 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/20 {
  --tw-gradient-to: rgb(76 5 25 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/25 {
  --tw-gradient-to: rgb(76 5 25 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/30 {
  --tw-gradient-to: rgb(76 5 25 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/35 {
  --tw-gradient-to: rgb(76 5 25 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/40 {
  --tw-gradient-to: rgb(76 5 25 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/45 {
  --tw-gradient-to: rgb(76 5 25 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/5 {
  --tw-gradient-to: rgb(76 5 25 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/50 {
  --tw-gradient-to: rgb(76 5 25 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/55 {
  --tw-gradient-to: rgb(76 5 25 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/60 {
  --tw-gradient-to: rgb(76 5 25 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/65 {
  --tw-gradient-to: rgb(76 5 25 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/70 {
  --tw-gradient-to: rgb(76 5 25 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/75 {
  --tw-gradient-to: rgb(76 5 25 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/80 {
  --tw-gradient-to: rgb(76 5 25 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/85 {
  --tw-gradient-to: rgb(76 5 25 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/90 {
  --tw-gradient-to: rgb(76 5 25 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-rose-950\/95 {
  --tw-gradient-to: rgb(76 5 25 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100 {
  --tw-gradient-to: #e0f2fe var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/0 {
  --tw-gradient-to: rgb(224 242 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/10 {
  --tw-gradient-to: rgb(224 242 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/100 {
  --tw-gradient-to: rgb(224 242 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/15 {
  --tw-gradient-to: rgb(224 242 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/20 {
  --tw-gradient-to: rgb(224 242 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/25 {
  --tw-gradient-to: rgb(224 242 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/30 {
  --tw-gradient-to: rgb(224 242 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/35 {
  --tw-gradient-to: rgb(224 242 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/40 {
  --tw-gradient-to: rgb(224 242 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/45 {
  --tw-gradient-to: rgb(224 242 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/5 {
  --tw-gradient-to: rgb(224 242 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/50 {
  --tw-gradient-to: rgb(224 242 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/55 {
  --tw-gradient-to: rgb(224 242 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/60 {
  --tw-gradient-to: rgb(224 242 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/65 {
  --tw-gradient-to: rgb(224 242 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/70 {
  --tw-gradient-to: rgb(224 242 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/75 {
  --tw-gradient-to: rgb(224 242 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/80 {
  --tw-gradient-to: rgb(224 242 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/85 {
  --tw-gradient-to: rgb(224 242 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/90 {
  --tw-gradient-to: rgb(224 242 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-100\/95 {
  --tw-gradient-to: rgb(224 242 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200 {
  --tw-gradient-to: #bae6fd var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/0 {
  --tw-gradient-to: rgb(186 230 253 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/10 {
  --tw-gradient-to: rgb(186 230 253 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/100 {
  --tw-gradient-to: rgb(186 230 253 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/15 {
  --tw-gradient-to: rgb(186 230 253 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/20 {
  --tw-gradient-to: rgb(186 230 253 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/25 {
  --tw-gradient-to: rgb(186 230 253 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/30 {
  --tw-gradient-to: rgb(186 230 253 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/35 {
  --tw-gradient-to: rgb(186 230 253 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/40 {
  --tw-gradient-to: rgb(186 230 253 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/45 {
  --tw-gradient-to: rgb(186 230 253 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/5 {
  --tw-gradient-to: rgb(186 230 253 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/50 {
  --tw-gradient-to: rgb(186 230 253 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/55 {
  --tw-gradient-to: rgb(186 230 253 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/60 {
  --tw-gradient-to: rgb(186 230 253 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/65 {
  --tw-gradient-to: rgb(186 230 253 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/70 {
  --tw-gradient-to: rgb(186 230 253 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/75 {
  --tw-gradient-to: rgb(186 230 253 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/80 {
  --tw-gradient-to: rgb(186 230 253 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/85 {
  --tw-gradient-to: rgb(186 230 253 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/90 {
  --tw-gradient-to: rgb(186 230 253 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-200\/95 {
  --tw-gradient-to: rgb(186 230 253 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300 {
  --tw-gradient-to: #7dd3fc var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/0 {
  --tw-gradient-to: rgb(125 211 252 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/10 {
  --tw-gradient-to: rgb(125 211 252 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/100 {
  --tw-gradient-to: rgb(125 211 252 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/15 {
  --tw-gradient-to: rgb(125 211 252 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/20 {
  --tw-gradient-to: rgb(125 211 252 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/25 {
  --tw-gradient-to: rgb(125 211 252 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/30 {
  --tw-gradient-to: rgb(125 211 252 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/35 {
  --tw-gradient-to: rgb(125 211 252 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/40 {
  --tw-gradient-to: rgb(125 211 252 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/45 {
  --tw-gradient-to: rgb(125 211 252 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/5 {
  --tw-gradient-to: rgb(125 211 252 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/50 {
  --tw-gradient-to: rgb(125 211 252 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/55 {
  --tw-gradient-to: rgb(125 211 252 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/60 {
  --tw-gradient-to: rgb(125 211 252 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/65 {
  --tw-gradient-to: rgb(125 211 252 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/70 {
  --tw-gradient-to: rgb(125 211 252 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/75 {
  --tw-gradient-to: rgb(125 211 252 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/80 {
  --tw-gradient-to: rgb(125 211 252 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/85 {
  --tw-gradient-to: rgb(125 211 252 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/90 {
  --tw-gradient-to: rgb(125 211 252 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-300\/95 {
  --tw-gradient-to: rgb(125 211 252 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400 {
  --tw-gradient-to: #38bdf8 var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/0 {
  --tw-gradient-to: rgb(56 189 248 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/10 {
  --tw-gradient-to: rgb(56 189 248 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/100 {
  --tw-gradient-to: rgb(56 189 248 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/15 {
  --tw-gradient-to: rgb(56 189 248 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/20 {
  --tw-gradient-to: rgb(56 189 248 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/25 {
  --tw-gradient-to: rgb(56 189 248 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/30 {
  --tw-gradient-to: rgb(56 189 248 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/35 {
  --tw-gradient-to: rgb(56 189 248 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/40 {
  --tw-gradient-to: rgb(56 189 248 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/45 {
  --tw-gradient-to: rgb(56 189 248 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/5 {
  --tw-gradient-to: rgb(56 189 248 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/50 {
  --tw-gradient-to: rgb(56 189 248 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/55 {
  --tw-gradient-to: rgb(56 189 248 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/60 {
  --tw-gradient-to: rgb(56 189 248 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/65 {
  --tw-gradient-to: rgb(56 189 248 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/70 {
  --tw-gradient-to: rgb(56 189 248 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/75 {
  --tw-gradient-to: rgb(56 189 248 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/80 {
  --tw-gradient-to: rgb(56 189 248 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/85 {
  --tw-gradient-to: rgb(56 189 248 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/90 {
  --tw-gradient-to: rgb(56 189 248 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-400\/95 {
  --tw-gradient-to: rgb(56 189 248 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50 {
  --tw-gradient-to: #f0f9ff var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/0 {
  --tw-gradient-to: rgb(240 249 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/10 {
  --tw-gradient-to: rgb(240 249 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/100 {
  --tw-gradient-to: rgb(240 249 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/15 {
  --tw-gradient-to: rgb(240 249 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/20 {
  --tw-gradient-to: rgb(240 249 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/25 {
  --tw-gradient-to: rgb(240 249 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/30 {
  --tw-gradient-to: rgb(240 249 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/35 {
  --tw-gradient-to: rgb(240 249 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/40 {
  --tw-gradient-to: rgb(240 249 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/45 {
  --tw-gradient-to: rgb(240 249 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/5 {
  --tw-gradient-to: rgb(240 249 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/50 {
  --tw-gradient-to: rgb(240 249 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/55 {
  --tw-gradient-to: rgb(240 249 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/60 {
  --tw-gradient-to: rgb(240 249 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/65 {
  --tw-gradient-to: rgb(240 249 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/70 {
  --tw-gradient-to: rgb(240 249 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/75 {
  --tw-gradient-to: rgb(240 249 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/80 {
  --tw-gradient-to: rgb(240 249 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/85 {
  --tw-gradient-to: rgb(240 249 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/90 {
  --tw-gradient-to: rgb(240 249 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-50\/95 {
  --tw-gradient-to: rgb(240 249 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500 {
  --tw-gradient-to: #0ea5e9 var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/0 {
  --tw-gradient-to: rgb(14 165 233 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/10 {
  --tw-gradient-to: rgb(14 165 233 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/100 {
  --tw-gradient-to: rgb(14 165 233 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/15 {
  --tw-gradient-to: rgb(14 165 233 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/20 {
  --tw-gradient-to: rgb(14 165 233 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/25 {
  --tw-gradient-to: rgb(14 165 233 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/30 {
  --tw-gradient-to: rgb(14 165 233 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/35 {
  --tw-gradient-to: rgb(14 165 233 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/40 {
  --tw-gradient-to: rgb(14 165 233 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/45 {
  --tw-gradient-to: rgb(14 165 233 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/5 {
  --tw-gradient-to: rgb(14 165 233 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/50 {
  --tw-gradient-to: rgb(14 165 233 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/55 {
  --tw-gradient-to: rgb(14 165 233 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/60 {
  --tw-gradient-to: rgb(14 165 233 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/65 {
  --tw-gradient-to: rgb(14 165 233 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/70 {
  --tw-gradient-to: rgb(14 165 233 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/75 {
  --tw-gradient-to: rgb(14 165 233 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/80 {
  --tw-gradient-to: rgb(14 165 233 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/85 {
  --tw-gradient-to: rgb(14 165 233 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/90 {
  --tw-gradient-to: rgb(14 165 233 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-500\/95 {
  --tw-gradient-to: rgb(14 165 233 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600 {
  --tw-gradient-to: #0284c7 var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/0 {
  --tw-gradient-to: rgb(2 132 199 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/10 {
  --tw-gradient-to: rgb(2 132 199 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/100 {
  --tw-gradient-to: rgb(2 132 199 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/15 {
  --tw-gradient-to: rgb(2 132 199 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/20 {
  --tw-gradient-to: rgb(2 132 199 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/25 {
  --tw-gradient-to: rgb(2 132 199 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/30 {
  --tw-gradient-to: rgb(2 132 199 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/35 {
  --tw-gradient-to: rgb(2 132 199 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/40 {
  --tw-gradient-to: rgb(2 132 199 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/45 {
  --tw-gradient-to: rgb(2 132 199 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/5 {
  --tw-gradient-to: rgb(2 132 199 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/50 {
  --tw-gradient-to: rgb(2 132 199 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/55 {
  --tw-gradient-to: rgb(2 132 199 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/60 {
  --tw-gradient-to: rgb(2 132 199 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/65 {
  --tw-gradient-to: rgb(2 132 199 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/70 {
  --tw-gradient-to: rgb(2 132 199 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/75 {
  --tw-gradient-to: rgb(2 132 199 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/80 {
  --tw-gradient-to: rgb(2 132 199 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/85 {
  --tw-gradient-to: rgb(2 132 199 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/90 {
  --tw-gradient-to: rgb(2 132 199 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-600\/95 {
  --tw-gradient-to: rgb(2 132 199 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700 {
  --tw-gradient-to: #0369a1 var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/0 {
  --tw-gradient-to: rgb(3 105 161 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/10 {
  --tw-gradient-to: rgb(3 105 161 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/100 {
  --tw-gradient-to: rgb(3 105 161 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/15 {
  --tw-gradient-to: rgb(3 105 161 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/20 {
  --tw-gradient-to: rgb(3 105 161 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/25 {
  --tw-gradient-to: rgb(3 105 161 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/30 {
  --tw-gradient-to: rgb(3 105 161 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/35 {
  --tw-gradient-to: rgb(3 105 161 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/40 {
  --tw-gradient-to: rgb(3 105 161 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/45 {
  --tw-gradient-to: rgb(3 105 161 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/5 {
  --tw-gradient-to: rgb(3 105 161 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/50 {
  --tw-gradient-to: rgb(3 105 161 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/55 {
  --tw-gradient-to: rgb(3 105 161 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/60 {
  --tw-gradient-to: rgb(3 105 161 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/65 {
  --tw-gradient-to: rgb(3 105 161 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/70 {
  --tw-gradient-to: rgb(3 105 161 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/75 {
  --tw-gradient-to: rgb(3 105 161 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/80 {
  --tw-gradient-to: rgb(3 105 161 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/85 {
  --tw-gradient-to: rgb(3 105 161 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/90 {
  --tw-gradient-to: rgb(3 105 161 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-700\/95 {
  --tw-gradient-to: rgb(3 105 161 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800 {
  --tw-gradient-to: #075985 var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/0 {
  --tw-gradient-to: rgb(7 89 133 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/10 {
  --tw-gradient-to: rgb(7 89 133 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/100 {
  --tw-gradient-to: rgb(7 89 133 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/15 {
  --tw-gradient-to: rgb(7 89 133 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/20 {
  --tw-gradient-to: rgb(7 89 133 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/25 {
  --tw-gradient-to: rgb(7 89 133 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/30 {
  --tw-gradient-to: rgb(7 89 133 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/35 {
  --tw-gradient-to: rgb(7 89 133 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/40 {
  --tw-gradient-to: rgb(7 89 133 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/45 {
  --tw-gradient-to: rgb(7 89 133 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/5 {
  --tw-gradient-to: rgb(7 89 133 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/50 {
  --tw-gradient-to: rgb(7 89 133 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/55 {
  --tw-gradient-to: rgb(7 89 133 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/60 {
  --tw-gradient-to: rgb(7 89 133 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/65 {
  --tw-gradient-to: rgb(7 89 133 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/70 {
  --tw-gradient-to: rgb(7 89 133 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/75 {
  --tw-gradient-to: rgb(7 89 133 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/80 {
  --tw-gradient-to: rgb(7 89 133 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/85 {
  --tw-gradient-to: rgb(7 89 133 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/90 {
  --tw-gradient-to: rgb(7 89 133 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-800\/95 {
  --tw-gradient-to: rgb(7 89 133 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900 {
  --tw-gradient-to: #0c4a6e var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/0 {
  --tw-gradient-to: rgb(12 74 110 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/10 {
  --tw-gradient-to: rgb(12 74 110 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/100 {
  --tw-gradient-to: rgb(12 74 110 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/15 {
  --tw-gradient-to: rgb(12 74 110 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/20 {
  --tw-gradient-to: rgb(12 74 110 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/25 {
  --tw-gradient-to: rgb(12 74 110 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/30 {
  --tw-gradient-to: rgb(12 74 110 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/35 {
  --tw-gradient-to: rgb(12 74 110 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/40 {
  --tw-gradient-to: rgb(12 74 110 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/45 {
  --tw-gradient-to: rgb(12 74 110 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/5 {
  --tw-gradient-to: rgb(12 74 110 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/50 {
  --tw-gradient-to: rgb(12 74 110 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/55 {
  --tw-gradient-to: rgb(12 74 110 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/60 {
  --tw-gradient-to: rgb(12 74 110 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/65 {
  --tw-gradient-to: rgb(12 74 110 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/70 {
  --tw-gradient-to: rgb(12 74 110 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/75 {
  --tw-gradient-to: rgb(12 74 110 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/80 {
  --tw-gradient-to: rgb(12 74 110 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/85 {
  --tw-gradient-to: rgb(12 74 110 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/90 {
  --tw-gradient-to: rgb(12 74 110 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-900\/95 {
  --tw-gradient-to: rgb(12 74 110 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950 {
  --tw-gradient-to: #082f49 var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/0 {
  --tw-gradient-to: rgb(8 47 73 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/10 {
  --tw-gradient-to: rgb(8 47 73 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/100 {
  --tw-gradient-to: rgb(8 47 73 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/15 {
  --tw-gradient-to: rgb(8 47 73 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/20 {
  --tw-gradient-to: rgb(8 47 73 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/25 {
  --tw-gradient-to: rgb(8 47 73 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/30 {
  --tw-gradient-to: rgb(8 47 73 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/35 {
  --tw-gradient-to: rgb(8 47 73 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/40 {
  --tw-gradient-to: rgb(8 47 73 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/45 {
  --tw-gradient-to: rgb(8 47 73 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/5 {
  --tw-gradient-to: rgb(8 47 73 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/50 {
  --tw-gradient-to: rgb(8 47 73 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/55 {
  --tw-gradient-to: rgb(8 47 73 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/60 {
  --tw-gradient-to: rgb(8 47 73 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/65 {
  --tw-gradient-to: rgb(8 47 73 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/70 {
  --tw-gradient-to: rgb(8 47 73 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/75 {
  --tw-gradient-to: rgb(8 47 73 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/80 {
  --tw-gradient-to: rgb(8 47 73 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/85 {
  --tw-gradient-to: rgb(8 47 73 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/90 {
  --tw-gradient-to: rgb(8 47 73 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-sky-950\/95 {
  --tw-gradient-to: rgb(8 47 73 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100 {
  --tw-gradient-to: #f1f5f9 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/0 {
  --tw-gradient-to: rgb(241 245 249 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/10 {
  --tw-gradient-to: rgb(241 245 249 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/100 {
  --tw-gradient-to: rgb(241 245 249 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/15 {
  --tw-gradient-to: rgb(241 245 249 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/20 {
  --tw-gradient-to: rgb(241 245 249 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/25 {
  --tw-gradient-to: rgb(241 245 249 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/30 {
  --tw-gradient-to: rgb(241 245 249 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/35 {
  --tw-gradient-to: rgb(241 245 249 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/40 {
  --tw-gradient-to: rgb(241 245 249 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/45 {
  --tw-gradient-to: rgb(241 245 249 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/5 {
  --tw-gradient-to: rgb(241 245 249 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/50 {
  --tw-gradient-to: rgb(241 245 249 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/55 {
  --tw-gradient-to: rgb(241 245 249 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/60 {
  --tw-gradient-to: rgb(241 245 249 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/65 {
  --tw-gradient-to: rgb(241 245 249 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/70 {
  --tw-gradient-to: rgb(241 245 249 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/75 {
  --tw-gradient-to: rgb(241 245 249 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/80 {
  --tw-gradient-to: rgb(241 245 249 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/85 {
  --tw-gradient-to: rgb(241 245 249 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/90 {
  --tw-gradient-to: rgb(241 245 249 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-100\/95 {
  --tw-gradient-to: rgb(241 245 249 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200 {
  --tw-gradient-to: #e2e8f0 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/0 {
  --tw-gradient-to: rgb(226 232 240 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/10 {
  --tw-gradient-to: rgb(226 232 240 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/100 {
  --tw-gradient-to: rgb(226 232 240 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/15 {
  --tw-gradient-to: rgb(226 232 240 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/20 {
  --tw-gradient-to: rgb(226 232 240 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/25 {
  --tw-gradient-to: rgb(226 232 240 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/30 {
  --tw-gradient-to: rgb(226 232 240 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/35 {
  --tw-gradient-to: rgb(226 232 240 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/40 {
  --tw-gradient-to: rgb(226 232 240 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/45 {
  --tw-gradient-to: rgb(226 232 240 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/5 {
  --tw-gradient-to: rgb(226 232 240 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/50 {
  --tw-gradient-to: rgb(226 232 240 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/55 {
  --tw-gradient-to: rgb(226 232 240 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/60 {
  --tw-gradient-to: rgb(226 232 240 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/65 {
  --tw-gradient-to: rgb(226 232 240 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/70 {
  --tw-gradient-to: rgb(226 232 240 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/75 {
  --tw-gradient-to: rgb(226 232 240 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/80 {
  --tw-gradient-to: rgb(226 232 240 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/85 {
  --tw-gradient-to: rgb(226 232 240 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/90 {
  --tw-gradient-to: rgb(226 232 240 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-200\/95 {
  --tw-gradient-to: rgb(226 232 240 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300 {
  --tw-gradient-to: #cbd5e1 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/0 {
  --tw-gradient-to: rgb(203 213 225 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/10 {
  --tw-gradient-to: rgb(203 213 225 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/100 {
  --tw-gradient-to: rgb(203 213 225 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/15 {
  --tw-gradient-to: rgb(203 213 225 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/20 {
  --tw-gradient-to: rgb(203 213 225 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/25 {
  --tw-gradient-to: rgb(203 213 225 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/30 {
  --tw-gradient-to: rgb(203 213 225 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/35 {
  --tw-gradient-to: rgb(203 213 225 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/40 {
  --tw-gradient-to: rgb(203 213 225 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/45 {
  --tw-gradient-to: rgb(203 213 225 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/5 {
  --tw-gradient-to: rgb(203 213 225 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/50 {
  --tw-gradient-to: rgb(203 213 225 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/55 {
  --tw-gradient-to: rgb(203 213 225 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/60 {
  --tw-gradient-to: rgb(203 213 225 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/65 {
  --tw-gradient-to: rgb(203 213 225 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/70 {
  --tw-gradient-to: rgb(203 213 225 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/75 {
  --tw-gradient-to: rgb(203 213 225 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/80 {
  --tw-gradient-to: rgb(203 213 225 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/85 {
  --tw-gradient-to: rgb(203 213 225 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/90 {
  --tw-gradient-to: rgb(203 213 225 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-300\/95 {
  --tw-gradient-to: rgb(203 213 225 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400 {
  --tw-gradient-to: #94a3b8 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/0 {
  --tw-gradient-to: rgb(148 163 184 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/10 {
  --tw-gradient-to: rgb(148 163 184 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/100 {
  --tw-gradient-to: rgb(148 163 184 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/15 {
  --tw-gradient-to: rgb(148 163 184 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/20 {
  --tw-gradient-to: rgb(148 163 184 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/25 {
  --tw-gradient-to: rgb(148 163 184 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/30 {
  --tw-gradient-to: rgb(148 163 184 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/35 {
  --tw-gradient-to: rgb(148 163 184 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/40 {
  --tw-gradient-to: rgb(148 163 184 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/45 {
  --tw-gradient-to: rgb(148 163 184 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/5 {
  --tw-gradient-to: rgb(148 163 184 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/50 {
  --tw-gradient-to: rgb(148 163 184 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/55 {
  --tw-gradient-to: rgb(148 163 184 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/60 {
  --tw-gradient-to: rgb(148 163 184 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/65 {
  --tw-gradient-to: rgb(148 163 184 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/70 {
  --tw-gradient-to: rgb(148 163 184 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/75 {
  --tw-gradient-to: rgb(148 163 184 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/80 {
  --tw-gradient-to: rgb(148 163 184 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/85 {
  --tw-gradient-to: rgb(148 163 184 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/90 {
  --tw-gradient-to: rgb(148 163 184 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-400\/95 {
  --tw-gradient-to: rgb(148 163 184 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50 {
  --tw-gradient-to: #f8fafc var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/0 {
  --tw-gradient-to: rgb(248 250 252 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/10 {
  --tw-gradient-to: rgb(248 250 252 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/100 {
  --tw-gradient-to: rgb(248 250 252 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/15 {
  --tw-gradient-to: rgb(248 250 252 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/20 {
  --tw-gradient-to: rgb(248 250 252 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/25 {
  --tw-gradient-to: rgb(248 250 252 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/30 {
  --tw-gradient-to: rgb(248 250 252 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/35 {
  --tw-gradient-to: rgb(248 250 252 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/40 {
  --tw-gradient-to: rgb(248 250 252 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/45 {
  --tw-gradient-to: rgb(248 250 252 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/5 {
  --tw-gradient-to: rgb(248 250 252 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/50 {
  --tw-gradient-to: rgb(248 250 252 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/55 {
  --tw-gradient-to: rgb(248 250 252 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/60 {
  --tw-gradient-to: rgb(248 250 252 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/65 {
  --tw-gradient-to: rgb(248 250 252 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/70 {
  --tw-gradient-to: rgb(248 250 252 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/75 {
  --tw-gradient-to: rgb(248 250 252 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/80 {
  --tw-gradient-to: rgb(248 250 252 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/85 {
  --tw-gradient-to: rgb(248 250 252 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/90 {
  --tw-gradient-to: rgb(248 250 252 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-50\/95 {
  --tw-gradient-to: rgb(248 250 252 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500 {
  --tw-gradient-to: #64748b var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/0 {
  --tw-gradient-to: rgb(100 116 139 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/10 {
  --tw-gradient-to: rgb(100 116 139 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/100 {
  --tw-gradient-to: rgb(100 116 139 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/15 {
  --tw-gradient-to: rgb(100 116 139 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/20 {
  --tw-gradient-to: rgb(100 116 139 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/25 {
  --tw-gradient-to: rgb(100 116 139 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/30 {
  --tw-gradient-to: rgb(100 116 139 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/35 {
  --tw-gradient-to: rgb(100 116 139 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/40 {
  --tw-gradient-to: rgb(100 116 139 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/45 {
  --tw-gradient-to: rgb(100 116 139 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/5 {
  --tw-gradient-to: rgb(100 116 139 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/50 {
  --tw-gradient-to: rgb(100 116 139 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/55 {
  --tw-gradient-to: rgb(100 116 139 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/60 {
  --tw-gradient-to: rgb(100 116 139 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/65 {
  --tw-gradient-to: rgb(100 116 139 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/70 {
  --tw-gradient-to: rgb(100 116 139 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/75 {
  --tw-gradient-to: rgb(100 116 139 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/80 {
  --tw-gradient-to: rgb(100 116 139 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/85 {
  --tw-gradient-to: rgb(100 116 139 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/90 {
  --tw-gradient-to: rgb(100 116 139 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-500\/95 {
  --tw-gradient-to: rgb(100 116 139 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600 {
  --tw-gradient-to: #475569 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/0 {
  --tw-gradient-to: rgb(71 85 105 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/10 {
  --tw-gradient-to: rgb(71 85 105 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/100 {
  --tw-gradient-to: rgb(71 85 105 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/15 {
  --tw-gradient-to: rgb(71 85 105 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/20 {
  --tw-gradient-to: rgb(71 85 105 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/25 {
  --tw-gradient-to: rgb(71 85 105 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/30 {
  --tw-gradient-to: rgb(71 85 105 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/35 {
  --tw-gradient-to: rgb(71 85 105 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/40 {
  --tw-gradient-to: rgb(71 85 105 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/45 {
  --tw-gradient-to: rgb(71 85 105 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/5 {
  --tw-gradient-to: rgb(71 85 105 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/50 {
  --tw-gradient-to: rgb(71 85 105 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/55 {
  --tw-gradient-to: rgb(71 85 105 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/60 {
  --tw-gradient-to: rgb(71 85 105 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/65 {
  --tw-gradient-to: rgb(71 85 105 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/70 {
  --tw-gradient-to: rgb(71 85 105 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/75 {
  --tw-gradient-to: rgb(71 85 105 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/80 {
  --tw-gradient-to: rgb(71 85 105 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/85 {
  --tw-gradient-to: rgb(71 85 105 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/90 {
  --tw-gradient-to: rgb(71 85 105 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-600\/95 {
  --tw-gradient-to: rgb(71 85 105 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700 {
  --tw-gradient-to: #334155 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/0 {
  --tw-gradient-to: rgb(51 65 85 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/10 {
  --tw-gradient-to: rgb(51 65 85 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/100 {
  --tw-gradient-to: rgb(51 65 85 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/15 {
  --tw-gradient-to: rgb(51 65 85 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/20 {
  --tw-gradient-to: rgb(51 65 85 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/25 {
  --tw-gradient-to: rgb(51 65 85 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/30 {
  --tw-gradient-to: rgb(51 65 85 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/35 {
  --tw-gradient-to: rgb(51 65 85 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/40 {
  --tw-gradient-to: rgb(51 65 85 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/45 {
  --tw-gradient-to: rgb(51 65 85 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/5 {
  --tw-gradient-to: rgb(51 65 85 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/50 {
  --tw-gradient-to: rgb(51 65 85 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/55 {
  --tw-gradient-to: rgb(51 65 85 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/60 {
  --tw-gradient-to: rgb(51 65 85 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/65 {
  --tw-gradient-to: rgb(51 65 85 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/70 {
  --tw-gradient-to: rgb(51 65 85 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/75 {
  --tw-gradient-to: rgb(51 65 85 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/80 {
  --tw-gradient-to: rgb(51 65 85 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/85 {
  --tw-gradient-to: rgb(51 65 85 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/90 {
  --tw-gradient-to: rgb(51 65 85 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-700\/95 {
  --tw-gradient-to: rgb(51 65 85 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800 {
  --tw-gradient-to: #1e293b var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/0 {
  --tw-gradient-to: rgb(30 41 59 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/10 {
  --tw-gradient-to: rgb(30 41 59 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/100 {
  --tw-gradient-to: rgb(30 41 59 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/15 {
  --tw-gradient-to: rgb(30 41 59 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/20 {
  --tw-gradient-to: rgb(30 41 59 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/25 {
  --tw-gradient-to: rgb(30 41 59 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/30 {
  --tw-gradient-to: rgb(30 41 59 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/35 {
  --tw-gradient-to: rgb(30 41 59 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/40 {
  --tw-gradient-to: rgb(30 41 59 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/45 {
  --tw-gradient-to: rgb(30 41 59 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/5 {
  --tw-gradient-to: rgb(30 41 59 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/50 {
  --tw-gradient-to: rgb(30 41 59 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/55 {
  --tw-gradient-to: rgb(30 41 59 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/60 {
  --tw-gradient-to: rgb(30 41 59 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/65 {
  --tw-gradient-to: rgb(30 41 59 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/70 {
  --tw-gradient-to: rgb(30 41 59 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/75 {
  --tw-gradient-to: rgb(30 41 59 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/80 {
  --tw-gradient-to: rgb(30 41 59 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/85 {
  --tw-gradient-to: rgb(30 41 59 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/90 {
  --tw-gradient-to: rgb(30 41 59 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-800\/95 {
  --tw-gradient-to: rgb(30 41 59 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900 {
  --tw-gradient-to: #0f172a var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/0 {
  --tw-gradient-to: rgb(15 23 42 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/10 {
  --tw-gradient-to: rgb(15 23 42 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/100 {
  --tw-gradient-to: rgb(15 23 42 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/15 {
  --tw-gradient-to: rgb(15 23 42 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/20 {
  --tw-gradient-to: rgb(15 23 42 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/25 {
  --tw-gradient-to: rgb(15 23 42 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/30 {
  --tw-gradient-to: rgb(15 23 42 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/35 {
  --tw-gradient-to: rgb(15 23 42 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/40 {
  --tw-gradient-to: rgb(15 23 42 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/45 {
  --tw-gradient-to: rgb(15 23 42 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/5 {
  --tw-gradient-to: rgb(15 23 42 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/50 {
  --tw-gradient-to: rgb(15 23 42 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/55 {
  --tw-gradient-to: rgb(15 23 42 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/60 {
  --tw-gradient-to: rgb(15 23 42 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/65 {
  --tw-gradient-to: rgb(15 23 42 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/70 {
  --tw-gradient-to: rgb(15 23 42 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/75 {
  --tw-gradient-to: rgb(15 23 42 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/80 {
  --tw-gradient-to: rgb(15 23 42 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/85 {
  --tw-gradient-to: rgb(15 23 42 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/90 {
  --tw-gradient-to: rgb(15 23 42 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-900\/95 {
  --tw-gradient-to: rgb(15 23 42 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950 {
  --tw-gradient-to: #020617 var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/0 {
  --tw-gradient-to: rgb(2 6 23 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/10 {
  --tw-gradient-to: rgb(2 6 23 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/100 {
  --tw-gradient-to: rgb(2 6 23 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/15 {
  --tw-gradient-to: rgb(2 6 23 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/20 {
  --tw-gradient-to: rgb(2 6 23 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/25 {
  --tw-gradient-to: rgb(2 6 23 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/30 {
  --tw-gradient-to: rgb(2 6 23 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/35 {
  --tw-gradient-to: rgb(2 6 23 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/40 {
  --tw-gradient-to: rgb(2 6 23 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/45 {
  --tw-gradient-to: rgb(2 6 23 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/5 {
  --tw-gradient-to: rgb(2 6 23 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/50 {
  --tw-gradient-to: rgb(2 6 23 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/55 {
  --tw-gradient-to: rgb(2 6 23 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/60 {
  --tw-gradient-to: rgb(2 6 23 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/65 {
  --tw-gradient-to: rgb(2 6 23 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/70 {
  --tw-gradient-to: rgb(2 6 23 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/75 {
  --tw-gradient-to: rgb(2 6 23 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/80 {
  --tw-gradient-to: rgb(2 6 23 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/85 {
  --tw-gradient-to: rgb(2 6 23 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/90 {
  --tw-gradient-to: rgb(2 6 23 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-slate-950\/95 {
  --tw-gradient-to: rgb(2 6 23 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100 {
  --tw-gradient-to: #f5f5f4 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/0 {
  --tw-gradient-to: rgb(245 245 244 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/10 {
  --tw-gradient-to: rgb(245 245 244 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/100 {
  --tw-gradient-to: rgb(245 245 244 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/15 {
  --tw-gradient-to: rgb(245 245 244 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/20 {
  --tw-gradient-to: rgb(245 245 244 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/25 {
  --tw-gradient-to: rgb(245 245 244 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/30 {
  --tw-gradient-to: rgb(245 245 244 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/35 {
  --tw-gradient-to: rgb(245 245 244 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/40 {
  --tw-gradient-to: rgb(245 245 244 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/45 {
  --tw-gradient-to: rgb(245 245 244 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/5 {
  --tw-gradient-to: rgb(245 245 244 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/50 {
  --tw-gradient-to: rgb(245 245 244 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/55 {
  --tw-gradient-to: rgb(245 245 244 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/60 {
  --tw-gradient-to: rgb(245 245 244 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/65 {
  --tw-gradient-to: rgb(245 245 244 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/70 {
  --tw-gradient-to: rgb(245 245 244 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/75 {
  --tw-gradient-to: rgb(245 245 244 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/80 {
  --tw-gradient-to: rgb(245 245 244 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/85 {
  --tw-gradient-to: rgb(245 245 244 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/90 {
  --tw-gradient-to: rgb(245 245 244 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-100\/95 {
  --tw-gradient-to: rgb(245 245 244 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200 {
  --tw-gradient-to: #e7e5e4 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/0 {
  --tw-gradient-to: rgb(231 229 228 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/10 {
  --tw-gradient-to: rgb(231 229 228 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/100 {
  --tw-gradient-to: rgb(231 229 228 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/15 {
  --tw-gradient-to: rgb(231 229 228 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/20 {
  --tw-gradient-to: rgb(231 229 228 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/25 {
  --tw-gradient-to: rgb(231 229 228 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/30 {
  --tw-gradient-to: rgb(231 229 228 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/35 {
  --tw-gradient-to: rgb(231 229 228 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/40 {
  --tw-gradient-to: rgb(231 229 228 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/45 {
  --tw-gradient-to: rgb(231 229 228 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/5 {
  --tw-gradient-to: rgb(231 229 228 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/50 {
  --tw-gradient-to: rgb(231 229 228 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/55 {
  --tw-gradient-to: rgb(231 229 228 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/60 {
  --tw-gradient-to: rgb(231 229 228 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/65 {
  --tw-gradient-to: rgb(231 229 228 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/70 {
  --tw-gradient-to: rgb(231 229 228 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/75 {
  --tw-gradient-to: rgb(231 229 228 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/80 {
  --tw-gradient-to: rgb(231 229 228 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/85 {
  --tw-gradient-to: rgb(231 229 228 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/90 {
  --tw-gradient-to: rgb(231 229 228 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-200\/95 {
  --tw-gradient-to: rgb(231 229 228 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300 {
  --tw-gradient-to: #d6d3d1 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/0 {
  --tw-gradient-to: rgb(214 211 209 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/10 {
  --tw-gradient-to: rgb(214 211 209 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/100 {
  --tw-gradient-to: rgb(214 211 209 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/15 {
  --tw-gradient-to: rgb(214 211 209 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/20 {
  --tw-gradient-to: rgb(214 211 209 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/25 {
  --tw-gradient-to: rgb(214 211 209 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/30 {
  --tw-gradient-to: rgb(214 211 209 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/35 {
  --tw-gradient-to: rgb(214 211 209 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/40 {
  --tw-gradient-to: rgb(214 211 209 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/45 {
  --tw-gradient-to: rgb(214 211 209 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/5 {
  --tw-gradient-to: rgb(214 211 209 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/50 {
  --tw-gradient-to: rgb(214 211 209 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/55 {
  --tw-gradient-to: rgb(214 211 209 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/60 {
  --tw-gradient-to: rgb(214 211 209 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/65 {
  --tw-gradient-to: rgb(214 211 209 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/70 {
  --tw-gradient-to: rgb(214 211 209 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/75 {
  --tw-gradient-to: rgb(214 211 209 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/80 {
  --tw-gradient-to: rgb(214 211 209 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/85 {
  --tw-gradient-to: rgb(214 211 209 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/90 {
  --tw-gradient-to: rgb(214 211 209 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-300\/95 {
  --tw-gradient-to: rgb(214 211 209 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400 {
  --tw-gradient-to: #a8a29e var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/0 {
  --tw-gradient-to: rgb(168 162 158 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/10 {
  --tw-gradient-to: rgb(168 162 158 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/100 {
  --tw-gradient-to: rgb(168 162 158 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/15 {
  --tw-gradient-to: rgb(168 162 158 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/20 {
  --tw-gradient-to: rgb(168 162 158 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/25 {
  --tw-gradient-to: rgb(168 162 158 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/30 {
  --tw-gradient-to: rgb(168 162 158 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/35 {
  --tw-gradient-to: rgb(168 162 158 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/40 {
  --tw-gradient-to: rgb(168 162 158 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/45 {
  --tw-gradient-to: rgb(168 162 158 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/5 {
  --tw-gradient-to: rgb(168 162 158 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/50 {
  --tw-gradient-to: rgb(168 162 158 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/55 {
  --tw-gradient-to: rgb(168 162 158 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/60 {
  --tw-gradient-to: rgb(168 162 158 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/65 {
  --tw-gradient-to: rgb(168 162 158 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/70 {
  --tw-gradient-to: rgb(168 162 158 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/75 {
  --tw-gradient-to: rgb(168 162 158 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/80 {
  --tw-gradient-to: rgb(168 162 158 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/85 {
  --tw-gradient-to: rgb(168 162 158 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/90 {
  --tw-gradient-to: rgb(168 162 158 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-400\/95 {
  --tw-gradient-to: rgb(168 162 158 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50 {
  --tw-gradient-to: #fafaf9 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/0 {
  --tw-gradient-to: rgb(250 250 249 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/10 {
  --tw-gradient-to: rgb(250 250 249 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/100 {
  --tw-gradient-to: rgb(250 250 249 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/15 {
  --tw-gradient-to: rgb(250 250 249 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/20 {
  --tw-gradient-to: rgb(250 250 249 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/25 {
  --tw-gradient-to: rgb(250 250 249 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/30 {
  --tw-gradient-to: rgb(250 250 249 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/35 {
  --tw-gradient-to: rgb(250 250 249 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/40 {
  --tw-gradient-to: rgb(250 250 249 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/45 {
  --tw-gradient-to: rgb(250 250 249 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/5 {
  --tw-gradient-to: rgb(250 250 249 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/50 {
  --tw-gradient-to: rgb(250 250 249 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/55 {
  --tw-gradient-to: rgb(250 250 249 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/60 {
  --tw-gradient-to: rgb(250 250 249 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/65 {
  --tw-gradient-to: rgb(250 250 249 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/70 {
  --tw-gradient-to: rgb(250 250 249 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/75 {
  --tw-gradient-to: rgb(250 250 249 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/80 {
  --tw-gradient-to: rgb(250 250 249 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/85 {
  --tw-gradient-to: rgb(250 250 249 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/90 {
  --tw-gradient-to: rgb(250 250 249 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-50\/95 {
  --tw-gradient-to: rgb(250 250 249 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500 {
  --tw-gradient-to: #78716c var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/0 {
  --tw-gradient-to: rgb(120 113 108 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/10 {
  --tw-gradient-to: rgb(120 113 108 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/100 {
  --tw-gradient-to: rgb(120 113 108 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/15 {
  --tw-gradient-to: rgb(120 113 108 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/20 {
  --tw-gradient-to: rgb(120 113 108 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/25 {
  --tw-gradient-to: rgb(120 113 108 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/30 {
  --tw-gradient-to: rgb(120 113 108 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/35 {
  --tw-gradient-to: rgb(120 113 108 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/40 {
  --tw-gradient-to: rgb(120 113 108 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/45 {
  --tw-gradient-to: rgb(120 113 108 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/5 {
  --tw-gradient-to: rgb(120 113 108 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/50 {
  --tw-gradient-to: rgb(120 113 108 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/55 {
  --tw-gradient-to: rgb(120 113 108 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/60 {
  --tw-gradient-to: rgb(120 113 108 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/65 {
  --tw-gradient-to: rgb(120 113 108 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/70 {
  --tw-gradient-to: rgb(120 113 108 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/75 {
  --tw-gradient-to: rgb(120 113 108 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/80 {
  --tw-gradient-to: rgb(120 113 108 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/85 {
  --tw-gradient-to: rgb(120 113 108 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/90 {
  --tw-gradient-to: rgb(120 113 108 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-500\/95 {
  --tw-gradient-to: rgb(120 113 108 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600 {
  --tw-gradient-to: #57534e var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/0 {
  --tw-gradient-to: rgb(87 83 78 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/10 {
  --tw-gradient-to: rgb(87 83 78 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/100 {
  --tw-gradient-to: rgb(87 83 78 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/15 {
  --tw-gradient-to: rgb(87 83 78 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/20 {
  --tw-gradient-to: rgb(87 83 78 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/25 {
  --tw-gradient-to: rgb(87 83 78 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/30 {
  --tw-gradient-to: rgb(87 83 78 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/35 {
  --tw-gradient-to: rgb(87 83 78 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/40 {
  --tw-gradient-to: rgb(87 83 78 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/45 {
  --tw-gradient-to: rgb(87 83 78 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/5 {
  --tw-gradient-to: rgb(87 83 78 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/50 {
  --tw-gradient-to: rgb(87 83 78 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/55 {
  --tw-gradient-to: rgb(87 83 78 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/60 {
  --tw-gradient-to: rgb(87 83 78 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/65 {
  --tw-gradient-to: rgb(87 83 78 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/70 {
  --tw-gradient-to: rgb(87 83 78 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/75 {
  --tw-gradient-to: rgb(87 83 78 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/80 {
  --tw-gradient-to: rgb(87 83 78 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/85 {
  --tw-gradient-to: rgb(87 83 78 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/90 {
  --tw-gradient-to: rgb(87 83 78 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-600\/95 {
  --tw-gradient-to: rgb(87 83 78 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700 {
  --tw-gradient-to: #44403c var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/0 {
  --tw-gradient-to: rgb(68 64 60 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/10 {
  --tw-gradient-to: rgb(68 64 60 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/100 {
  --tw-gradient-to: rgb(68 64 60 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/15 {
  --tw-gradient-to: rgb(68 64 60 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/20 {
  --tw-gradient-to: rgb(68 64 60 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/25 {
  --tw-gradient-to: rgb(68 64 60 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/30 {
  --tw-gradient-to: rgb(68 64 60 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/35 {
  --tw-gradient-to: rgb(68 64 60 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/40 {
  --tw-gradient-to: rgb(68 64 60 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/45 {
  --tw-gradient-to: rgb(68 64 60 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/5 {
  --tw-gradient-to: rgb(68 64 60 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/50 {
  --tw-gradient-to: rgb(68 64 60 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/55 {
  --tw-gradient-to: rgb(68 64 60 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/60 {
  --tw-gradient-to: rgb(68 64 60 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/65 {
  --tw-gradient-to: rgb(68 64 60 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/70 {
  --tw-gradient-to: rgb(68 64 60 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/75 {
  --tw-gradient-to: rgb(68 64 60 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/80 {
  --tw-gradient-to: rgb(68 64 60 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/85 {
  --tw-gradient-to: rgb(68 64 60 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/90 {
  --tw-gradient-to: rgb(68 64 60 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-700\/95 {
  --tw-gradient-to: rgb(68 64 60 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800 {
  --tw-gradient-to: #292524 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/0 {
  --tw-gradient-to: rgb(41 37 36 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/10 {
  --tw-gradient-to: rgb(41 37 36 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/100 {
  --tw-gradient-to: rgb(41 37 36 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/15 {
  --tw-gradient-to: rgb(41 37 36 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/20 {
  --tw-gradient-to: rgb(41 37 36 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/25 {
  --tw-gradient-to: rgb(41 37 36 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/30 {
  --tw-gradient-to: rgb(41 37 36 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/35 {
  --tw-gradient-to: rgb(41 37 36 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/40 {
  --tw-gradient-to: rgb(41 37 36 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/45 {
  --tw-gradient-to: rgb(41 37 36 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/5 {
  --tw-gradient-to: rgb(41 37 36 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/50 {
  --tw-gradient-to: rgb(41 37 36 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/55 {
  --tw-gradient-to: rgb(41 37 36 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/60 {
  --tw-gradient-to: rgb(41 37 36 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/65 {
  --tw-gradient-to: rgb(41 37 36 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/70 {
  --tw-gradient-to: rgb(41 37 36 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/75 {
  --tw-gradient-to: rgb(41 37 36 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/80 {
  --tw-gradient-to: rgb(41 37 36 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/85 {
  --tw-gradient-to: rgb(41 37 36 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/90 {
  --tw-gradient-to: rgb(41 37 36 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-800\/95 {
  --tw-gradient-to: rgb(41 37 36 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900 {
  --tw-gradient-to: #1c1917 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/0 {
  --tw-gradient-to: rgb(28 25 23 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/10 {
  --tw-gradient-to: rgb(28 25 23 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/100 {
  --tw-gradient-to: rgb(28 25 23 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/15 {
  --tw-gradient-to: rgb(28 25 23 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/20 {
  --tw-gradient-to: rgb(28 25 23 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/25 {
  --tw-gradient-to: rgb(28 25 23 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/30 {
  --tw-gradient-to: rgb(28 25 23 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/35 {
  --tw-gradient-to: rgb(28 25 23 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/40 {
  --tw-gradient-to: rgb(28 25 23 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/45 {
  --tw-gradient-to: rgb(28 25 23 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/5 {
  --tw-gradient-to: rgb(28 25 23 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/50 {
  --tw-gradient-to: rgb(28 25 23 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/55 {
  --tw-gradient-to: rgb(28 25 23 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/60 {
  --tw-gradient-to: rgb(28 25 23 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/65 {
  --tw-gradient-to: rgb(28 25 23 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/70 {
  --tw-gradient-to: rgb(28 25 23 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/75 {
  --tw-gradient-to: rgb(28 25 23 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/80 {
  --tw-gradient-to: rgb(28 25 23 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/85 {
  --tw-gradient-to: rgb(28 25 23 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/90 {
  --tw-gradient-to: rgb(28 25 23 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-900\/95 {
  --tw-gradient-to: rgb(28 25 23 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950 {
  --tw-gradient-to: #0c0a09 var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/0 {
  --tw-gradient-to: rgb(12 10 9 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/10 {
  --tw-gradient-to: rgb(12 10 9 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/100 {
  --tw-gradient-to: rgb(12 10 9 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/15 {
  --tw-gradient-to: rgb(12 10 9 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/20 {
  --tw-gradient-to: rgb(12 10 9 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/25 {
  --tw-gradient-to: rgb(12 10 9 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/30 {
  --tw-gradient-to: rgb(12 10 9 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/35 {
  --tw-gradient-to: rgb(12 10 9 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/40 {
  --tw-gradient-to: rgb(12 10 9 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/45 {
  --tw-gradient-to: rgb(12 10 9 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/5 {
  --tw-gradient-to: rgb(12 10 9 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/50 {
  --tw-gradient-to: rgb(12 10 9 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/55 {
  --tw-gradient-to: rgb(12 10 9 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/60 {
  --tw-gradient-to: rgb(12 10 9 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/65 {
  --tw-gradient-to: rgb(12 10 9 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/70 {
  --tw-gradient-to: rgb(12 10 9 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/75 {
  --tw-gradient-to: rgb(12 10 9 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/80 {
  --tw-gradient-to: rgb(12 10 9 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/85 {
  --tw-gradient-to: rgb(12 10 9 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/90 {
  --tw-gradient-to: rgb(12 10 9 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-stone-950\/95 {
  --tw-gradient-to: rgb(12 10 9 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100 {
  --tw-gradient-to: #ccfbf1 var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/0 {
  --tw-gradient-to: rgb(204 251 241 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/10 {
  --tw-gradient-to: rgb(204 251 241 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/100 {
  --tw-gradient-to: rgb(204 251 241 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/15 {
  --tw-gradient-to: rgb(204 251 241 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/20 {
  --tw-gradient-to: rgb(204 251 241 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/25 {
  --tw-gradient-to: rgb(204 251 241 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/30 {
  --tw-gradient-to: rgb(204 251 241 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/35 {
  --tw-gradient-to: rgb(204 251 241 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/40 {
  --tw-gradient-to: rgb(204 251 241 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/45 {
  --tw-gradient-to: rgb(204 251 241 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/5 {
  --tw-gradient-to: rgb(204 251 241 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/50 {
  --tw-gradient-to: rgb(204 251 241 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/55 {
  --tw-gradient-to: rgb(204 251 241 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/60 {
  --tw-gradient-to: rgb(204 251 241 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/65 {
  --tw-gradient-to: rgb(204 251 241 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/70 {
  --tw-gradient-to: rgb(204 251 241 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/75 {
  --tw-gradient-to: rgb(204 251 241 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/80 {
  --tw-gradient-to: rgb(204 251 241 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/85 {
  --tw-gradient-to: rgb(204 251 241 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/90 {
  --tw-gradient-to: rgb(204 251 241 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-100\/95 {
  --tw-gradient-to: rgb(204 251 241 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200 {
  --tw-gradient-to: #99f6e4 var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/0 {
  --tw-gradient-to: rgb(153 246 228 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/10 {
  --tw-gradient-to: rgb(153 246 228 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/100 {
  --tw-gradient-to: rgb(153 246 228 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/15 {
  --tw-gradient-to: rgb(153 246 228 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/20 {
  --tw-gradient-to: rgb(153 246 228 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/25 {
  --tw-gradient-to: rgb(153 246 228 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/30 {
  --tw-gradient-to: rgb(153 246 228 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/35 {
  --tw-gradient-to: rgb(153 246 228 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/40 {
  --tw-gradient-to: rgb(153 246 228 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/45 {
  --tw-gradient-to: rgb(153 246 228 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/5 {
  --tw-gradient-to: rgb(153 246 228 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/50 {
  --tw-gradient-to: rgb(153 246 228 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/55 {
  --tw-gradient-to: rgb(153 246 228 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/60 {
  --tw-gradient-to: rgb(153 246 228 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/65 {
  --tw-gradient-to: rgb(153 246 228 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/70 {
  --tw-gradient-to: rgb(153 246 228 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/75 {
  --tw-gradient-to: rgb(153 246 228 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/80 {
  --tw-gradient-to: rgb(153 246 228 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/85 {
  --tw-gradient-to: rgb(153 246 228 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/90 {
  --tw-gradient-to: rgb(153 246 228 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-200\/95 {
  --tw-gradient-to: rgb(153 246 228 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300 {
  --tw-gradient-to: #5eead4 var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/0 {
  --tw-gradient-to: rgb(94 234 212 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/10 {
  --tw-gradient-to: rgb(94 234 212 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/100 {
  --tw-gradient-to: rgb(94 234 212 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/15 {
  --tw-gradient-to: rgb(94 234 212 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/20 {
  --tw-gradient-to: rgb(94 234 212 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/25 {
  --tw-gradient-to: rgb(94 234 212 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/30 {
  --tw-gradient-to: rgb(94 234 212 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/35 {
  --tw-gradient-to: rgb(94 234 212 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/40 {
  --tw-gradient-to: rgb(94 234 212 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/45 {
  --tw-gradient-to: rgb(94 234 212 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/5 {
  --tw-gradient-to: rgb(94 234 212 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/50 {
  --tw-gradient-to: rgb(94 234 212 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/55 {
  --tw-gradient-to: rgb(94 234 212 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/60 {
  --tw-gradient-to: rgb(94 234 212 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/65 {
  --tw-gradient-to: rgb(94 234 212 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/70 {
  --tw-gradient-to: rgb(94 234 212 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/75 {
  --tw-gradient-to: rgb(94 234 212 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/80 {
  --tw-gradient-to: rgb(94 234 212 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/85 {
  --tw-gradient-to: rgb(94 234 212 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/90 {
  --tw-gradient-to: rgb(94 234 212 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-300\/95 {
  --tw-gradient-to: rgb(94 234 212 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400 {
  --tw-gradient-to: #2dd4bf var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/0 {
  --tw-gradient-to: rgb(45 212 191 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/10 {
  --tw-gradient-to: rgb(45 212 191 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/100 {
  --tw-gradient-to: rgb(45 212 191 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/15 {
  --tw-gradient-to: rgb(45 212 191 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/20 {
  --tw-gradient-to: rgb(45 212 191 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/25 {
  --tw-gradient-to: rgb(45 212 191 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/30 {
  --tw-gradient-to: rgb(45 212 191 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/35 {
  --tw-gradient-to: rgb(45 212 191 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/40 {
  --tw-gradient-to: rgb(45 212 191 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/45 {
  --tw-gradient-to: rgb(45 212 191 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/5 {
  --tw-gradient-to: rgb(45 212 191 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/50 {
  --tw-gradient-to: rgb(45 212 191 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/55 {
  --tw-gradient-to: rgb(45 212 191 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/60 {
  --tw-gradient-to: rgb(45 212 191 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/65 {
  --tw-gradient-to: rgb(45 212 191 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/70 {
  --tw-gradient-to: rgb(45 212 191 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/75 {
  --tw-gradient-to: rgb(45 212 191 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/80 {
  --tw-gradient-to: rgb(45 212 191 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/85 {
  --tw-gradient-to: rgb(45 212 191 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/90 {
  --tw-gradient-to: rgb(45 212 191 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-400\/95 {
  --tw-gradient-to: rgb(45 212 191 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50 {
  --tw-gradient-to: #f0fdfa var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/0 {
  --tw-gradient-to: rgb(240 253 250 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/10 {
  --tw-gradient-to: rgb(240 253 250 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/100 {
  --tw-gradient-to: rgb(240 253 250 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/15 {
  --tw-gradient-to: rgb(240 253 250 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/20 {
  --tw-gradient-to: rgb(240 253 250 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/25 {
  --tw-gradient-to: rgb(240 253 250 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/30 {
  --tw-gradient-to: rgb(240 253 250 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/35 {
  --tw-gradient-to: rgb(240 253 250 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/40 {
  --tw-gradient-to: rgb(240 253 250 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/45 {
  --tw-gradient-to: rgb(240 253 250 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/5 {
  --tw-gradient-to: rgb(240 253 250 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/50 {
  --tw-gradient-to: rgb(240 253 250 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/55 {
  --tw-gradient-to: rgb(240 253 250 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/60 {
  --tw-gradient-to: rgb(240 253 250 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/65 {
  --tw-gradient-to: rgb(240 253 250 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/70 {
  --tw-gradient-to: rgb(240 253 250 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/75 {
  --tw-gradient-to: rgb(240 253 250 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/80 {
  --tw-gradient-to: rgb(240 253 250 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/85 {
  --tw-gradient-to: rgb(240 253 250 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/90 {
  --tw-gradient-to: rgb(240 253 250 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-50\/95 {
  --tw-gradient-to: rgb(240 253 250 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500 {
  --tw-gradient-to: #14b8a6 var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/0 {
  --tw-gradient-to: rgb(20 184 166 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/10 {
  --tw-gradient-to: rgb(20 184 166 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/100 {
  --tw-gradient-to: rgb(20 184 166 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/15 {
  --tw-gradient-to: rgb(20 184 166 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/20 {
  --tw-gradient-to: rgb(20 184 166 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/25 {
  --tw-gradient-to: rgb(20 184 166 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/30 {
  --tw-gradient-to: rgb(20 184 166 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/35 {
  --tw-gradient-to: rgb(20 184 166 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/40 {
  --tw-gradient-to: rgb(20 184 166 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/45 {
  --tw-gradient-to: rgb(20 184 166 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/5 {
  --tw-gradient-to: rgb(20 184 166 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/50 {
  --tw-gradient-to: rgb(20 184 166 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/55 {
  --tw-gradient-to: rgb(20 184 166 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/60 {
  --tw-gradient-to: rgb(20 184 166 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/65 {
  --tw-gradient-to: rgb(20 184 166 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/70 {
  --tw-gradient-to: rgb(20 184 166 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/75 {
  --tw-gradient-to: rgb(20 184 166 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/80 {
  --tw-gradient-to: rgb(20 184 166 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/85 {
  --tw-gradient-to: rgb(20 184 166 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/90 {
  --tw-gradient-to: rgb(20 184 166 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-500\/95 {
  --tw-gradient-to: rgb(20 184 166 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600 {
  --tw-gradient-to: #0d9488 var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/0 {
  --tw-gradient-to: rgb(13 148 136 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/10 {
  --tw-gradient-to: rgb(13 148 136 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/100 {
  --tw-gradient-to: rgb(13 148 136 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/15 {
  --tw-gradient-to: rgb(13 148 136 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/20 {
  --tw-gradient-to: rgb(13 148 136 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/25 {
  --tw-gradient-to: rgb(13 148 136 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/30 {
  --tw-gradient-to: rgb(13 148 136 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/35 {
  --tw-gradient-to: rgb(13 148 136 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/40 {
  --tw-gradient-to: rgb(13 148 136 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/45 {
  --tw-gradient-to: rgb(13 148 136 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/5 {
  --tw-gradient-to: rgb(13 148 136 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/50 {
  --tw-gradient-to: rgb(13 148 136 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/55 {
  --tw-gradient-to: rgb(13 148 136 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/60 {
  --tw-gradient-to: rgb(13 148 136 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/65 {
  --tw-gradient-to: rgb(13 148 136 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/70 {
  --tw-gradient-to: rgb(13 148 136 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/75 {
  --tw-gradient-to: rgb(13 148 136 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/80 {
  --tw-gradient-to: rgb(13 148 136 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/85 {
  --tw-gradient-to: rgb(13 148 136 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/90 {
  --tw-gradient-to: rgb(13 148 136 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-600\/95 {
  --tw-gradient-to: rgb(13 148 136 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700 {
  --tw-gradient-to: #0f766e var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/0 {
  --tw-gradient-to: rgb(15 118 110 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/10 {
  --tw-gradient-to: rgb(15 118 110 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/100 {
  --tw-gradient-to: rgb(15 118 110 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/15 {
  --tw-gradient-to: rgb(15 118 110 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/20 {
  --tw-gradient-to: rgb(15 118 110 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/25 {
  --tw-gradient-to: rgb(15 118 110 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/30 {
  --tw-gradient-to: rgb(15 118 110 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/35 {
  --tw-gradient-to: rgb(15 118 110 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/40 {
  --tw-gradient-to: rgb(15 118 110 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/45 {
  --tw-gradient-to: rgb(15 118 110 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/5 {
  --tw-gradient-to: rgb(15 118 110 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/50 {
  --tw-gradient-to: rgb(15 118 110 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/55 {
  --tw-gradient-to: rgb(15 118 110 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/60 {
  --tw-gradient-to: rgb(15 118 110 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/65 {
  --tw-gradient-to: rgb(15 118 110 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/70 {
  --tw-gradient-to: rgb(15 118 110 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/75 {
  --tw-gradient-to: rgb(15 118 110 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/80 {
  --tw-gradient-to: rgb(15 118 110 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/85 {
  --tw-gradient-to: rgb(15 118 110 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/90 {
  --tw-gradient-to: rgb(15 118 110 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-700\/95 {
  --tw-gradient-to: rgb(15 118 110 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800 {
  --tw-gradient-to: #115e59 var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/0 {
  --tw-gradient-to: rgb(17 94 89 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/10 {
  --tw-gradient-to: rgb(17 94 89 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/100 {
  --tw-gradient-to: rgb(17 94 89 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/15 {
  --tw-gradient-to: rgb(17 94 89 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/20 {
  --tw-gradient-to: rgb(17 94 89 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/25 {
  --tw-gradient-to: rgb(17 94 89 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/30 {
  --tw-gradient-to: rgb(17 94 89 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/35 {
  --tw-gradient-to: rgb(17 94 89 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/40 {
  --tw-gradient-to: rgb(17 94 89 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/45 {
  --tw-gradient-to: rgb(17 94 89 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/5 {
  --tw-gradient-to: rgb(17 94 89 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/50 {
  --tw-gradient-to: rgb(17 94 89 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/55 {
  --tw-gradient-to: rgb(17 94 89 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/60 {
  --tw-gradient-to: rgb(17 94 89 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/65 {
  --tw-gradient-to: rgb(17 94 89 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/70 {
  --tw-gradient-to: rgb(17 94 89 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/75 {
  --tw-gradient-to: rgb(17 94 89 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/80 {
  --tw-gradient-to: rgb(17 94 89 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/85 {
  --tw-gradient-to: rgb(17 94 89 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/90 {
  --tw-gradient-to: rgb(17 94 89 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-800\/95 {
  --tw-gradient-to: rgb(17 94 89 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900 {
  --tw-gradient-to: #134e4a var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/0 {
  --tw-gradient-to: rgb(19 78 74 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/10 {
  --tw-gradient-to: rgb(19 78 74 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/100 {
  --tw-gradient-to: rgb(19 78 74 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/15 {
  --tw-gradient-to: rgb(19 78 74 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/20 {
  --tw-gradient-to: rgb(19 78 74 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/25 {
  --tw-gradient-to: rgb(19 78 74 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/30 {
  --tw-gradient-to: rgb(19 78 74 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/35 {
  --tw-gradient-to: rgb(19 78 74 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/40 {
  --tw-gradient-to: rgb(19 78 74 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/45 {
  --tw-gradient-to: rgb(19 78 74 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/5 {
  --tw-gradient-to: rgb(19 78 74 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/50 {
  --tw-gradient-to: rgb(19 78 74 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/55 {
  --tw-gradient-to: rgb(19 78 74 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/60 {
  --tw-gradient-to: rgb(19 78 74 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/65 {
  --tw-gradient-to: rgb(19 78 74 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/70 {
  --tw-gradient-to: rgb(19 78 74 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/75 {
  --tw-gradient-to: rgb(19 78 74 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/80 {
  --tw-gradient-to: rgb(19 78 74 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/85 {
  --tw-gradient-to: rgb(19 78 74 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/90 {
  --tw-gradient-to: rgb(19 78 74 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-900\/95 {
  --tw-gradient-to: rgb(19 78 74 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950 {
  --tw-gradient-to: #042f2e var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/0 {
  --tw-gradient-to: rgb(4 47 46 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/10 {
  --tw-gradient-to: rgb(4 47 46 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/100 {
  --tw-gradient-to: rgb(4 47 46 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/15 {
  --tw-gradient-to: rgb(4 47 46 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/20 {
  --tw-gradient-to: rgb(4 47 46 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/25 {
  --tw-gradient-to: rgb(4 47 46 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/30 {
  --tw-gradient-to: rgb(4 47 46 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/35 {
  --tw-gradient-to: rgb(4 47 46 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/40 {
  --tw-gradient-to: rgb(4 47 46 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/45 {
  --tw-gradient-to: rgb(4 47 46 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/5 {
  --tw-gradient-to: rgb(4 47 46 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/50 {
  --tw-gradient-to: rgb(4 47 46 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/55 {
  --tw-gradient-to: rgb(4 47 46 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/60 {
  --tw-gradient-to: rgb(4 47 46 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/65 {
  --tw-gradient-to: rgb(4 47 46 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/70 {
  --tw-gradient-to: rgb(4 47 46 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/75 {
  --tw-gradient-to: rgb(4 47 46 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/80 {
  --tw-gradient-to: rgb(4 47 46 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/85 {
  --tw-gradient-to: rgb(4 47 46 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/90 {
  --tw-gradient-to: rgb(4 47 46 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-teal-950\/95 {
  --tw-gradient-to: rgb(4 47 46 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent {
  --tw-gradient-to: transparent var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/0 {
  --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/10 {
  --tw-gradient-to: rgb(0 0 0 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/100 {
  --tw-gradient-to: rgb(0 0 0 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/15 {
  --tw-gradient-to: rgb(0 0 0 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/20 {
  --tw-gradient-to: rgb(0 0 0 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/25 {
  --tw-gradient-to: rgb(0 0 0 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/30 {
  --tw-gradient-to: rgb(0 0 0 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/35 {
  --tw-gradient-to: rgb(0 0 0 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/40 {
  --tw-gradient-to: rgb(0 0 0 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/45 {
  --tw-gradient-to: rgb(0 0 0 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/5 {
  --tw-gradient-to: rgb(0 0 0 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/50 {
  --tw-gradient-to: rgb(0 0 0 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/55 {
  --tw-gradient-to: rgb(0 0 0 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/60 {
  --tw-gradient-to: rgb(0 0 0 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/65 {
  --tw-gradient-to: rgb(0 0 0 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/70 {
  --tw-gradient-to: rgb(0 0 0 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/75 {
  --tw-gradient-to: rgb(0 0 0 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/80 {
  --tw-gradient-to: rgb(0 0 0 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/85 {
  --tw-gradient-to: rgb(0 0 0 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/90 {
  --tw-gradient-to: rgb(0 0 0 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-transparent\/95 {
  --tw-gradient-to: rgb(0 0 0 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100 {
  --tw-gradient-to: #ede9fe var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/0 {
  --tw-gradient-to: rgb(237 233 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/10 {
  --tw-gradient-to: rgb(237 233 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/100 {
  --tw-gradient-to: rgb(237 233 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/15 {
  --tw-gradient-to: rgb(237 233 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/20 {
  --tw-gradient-to: rgb(237 233 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/25 {
  --tw-gradient-to: rgb(237 233 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/30 {
  --tw-gradient-to: rgb(237 233 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/35 {
  --tw-gradient-to: rgb(237 233 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/40 {
  --tw-gradient-to: rgb(237 233 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/45 {
  --tw-gradient-to: rgb(237 233 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/5 {
  --tw-gradient-to: rgb(237 233 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/50 {
  --tw-gradient-to: rgb(237 233 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/55 {
  --tw-gradient-to: rgb(237 233 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/60 {
  --tw-gradient-to: rgb(237 233 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/65 {
  --tw-gradient-to: rgb(237 233 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/70 {
  --tw-gradient-to: rgb(237 233 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/75 {
  --tw-gradient-to: rgb(237 233 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/80 {
  --tw-gradient-to: rgb(237 233 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/85 {
  --tw-gradient-to: rgb(237 233 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/90 {
  --tw-gradient-to: rgb(237 233 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-100\/95 {
  --tw-gradient-to: rgb(237 233 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200 {
  --tw-gradient-to: #ddd6fe var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/0 {
  --tw-gradient-to: rgb(221 214 254 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/10 {
  --tw-gradient-to: rgb(221 214 254 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/100 {
  --tw-gradient-to: rgb(221 214 254 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/15 {
  --tw-gradient-to: rgb(221 214 254 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/20 {
  --tw-gradient-to: rgb(221 214 254 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/25 {
  --tw-gradient-to: rgb(221 214 254 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/30 {
  --tw-gradient-to: rgb(221 214 254 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/35 {
  --tw-gradient-to: rgb(221 214 254 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/40 {
  --tw-gradient-to: rgb(221 214 254 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/45 {
  --tw-gradient-to: rgb(221 214 254 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/5 {
  --tw-gradient-to: rgb(221 214 254 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/50 {
  --tw-gradient-to: rgb(221 214 254 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/55 {
  --tw-gradient-to: rgb(221 214 254 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/60 {
  --tw-gradient-to: rgb(221 214 254 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/65 {
  --tw-gradient-to: rgb(221 214 254 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/70 {
  --tw-gradient-to: rgb(221 214 254 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/75 {
  --tw-gradient-to: rgb(221 214 254 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/80 {
  --tw-gradient-to: rgb(221 214 254 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/85 {
  --tw-gradient-to: rgb(221 214 254 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/90 {
  --tw-gradient-to: rgb(221 214 254 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-200\/95 {
  --tw-gradient-to: rgb(221 214 254 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300 {
  --tw-gradient-to: #c4b5fd var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/0 {
  --tw-gradient-to: rgb(196 181 253 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/10 {
  --tw-gradient-to: rgb(196 181 253 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/100 {
  --tw-gradient-to: rgb(196 181 253 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/15 {
  --tw-gradient-to: rgb(196 181 253 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/20 {
  --tw-gradient-to: rgb(196 181 253 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/25 {
  --tw-gradient-to: rgb(196 181 253 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/30 {
  --tw-gradient-to: rgb(196 181 253 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/35 {
  --tw-gradient-to: rgb(196 181 253 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/40 {
  --tw-gradient-to: rgb(196 181 253 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/45 {
  --tw-gradient-to: rgb(196 181 253 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/5 {
  --tw-gradient-to: rgb(196 181 253 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/50 {
  --tw-gradient-to: rgb(196 181 253 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/55 {
  --tw-gradient-to: rgb(196 181 253 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/60 {
  --tw-gradient-to: rgb(196 181 253 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/65 {
  --tw-gradient-to: rgb(196 181 253 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/70 {
  --tw-gradient-to: rgb(196 181 253 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/75 {
  --tw-gradient-to: rgb(196 181 253 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/80 {
  --tw-gradient-to: rgb(196 181 253 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/85 {
  --tw-gradient-to: rgb(196 181 253 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/90 {
  --tw-gradient-to: rgb(196 181 253 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-300\/95 {
  --tw-gradient-to: rgb(196 181 253 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400 {
  --tw-gradient-to: #a78bfa var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/0 {
  --tw-gradient-to: rgb(167 139 250 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/10 {
  --tw-gradient-to: rgb(167 139 250 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/100 {
  --tw-gradient-to: rgb(167 139 250 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/15 {
  --tw-gradient-to: rgb(167 139 250 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/20 {
  --tw-gradient-to: rgb(167 139 250 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/25 {
  --tw-gradient-to: rgb(167 139 250 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/30 {
  --tw-gradient-to: rgb(167 139 250 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/35 {
  --tw-gradient-to: rgb(167 139 250 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/40 {
  --tw-gradient-to: rgb(167 139 250 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/45 {
  --tw-gradient-to: rgb(167 139 250 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/5 {
  --tw-gradient-to: rgb(167 139 250 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/50 {
  --tw-gradient-to: rgb(167 139 250 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/55 {
  --tw-gradient-to: rgb(167 139 250 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/60 {
  --tw-gradient-to: rgb(167 139 250 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/65 {
  --tw-gradient-to: rgb(167 139 250 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/70 {
  --tw-gradient-to: rgb(167 139 250 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/75 {
  --tw-gradient-to: rgb(167 139 250 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/80 {
  --tw-gradient-to: rgb(167 139 250 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/85 {
  --tw-gradient-to: rgb(167 139 250 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/90 {
  --tw-gradient-to: rgb(167 139 250 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-400\/95 {
  --tw-gradient-to: rgb(167 139 250 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50 {
  --tw-gradient-to: #f5f3ff var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/0 {
  --tw-gradient-to: rgb(245 243 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/10 {
  --tw-gradient-to: rgb(245 243 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/100 {
  --tw-gradient-to: rgb(245 243 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/15 {
  --tw-gradient-to: rgb(245 243 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/20 {
  --tw-gradient-to: rgb(245 243 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/25 {
  --tw-gradient-to: rgb(245 243 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/30 {
  --tw-gradient-to: rgb(245 243 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/35 {
  --tw-gradient-to: rgb(245 243 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/40 {
  --tw-gradient-to: rgb(245 243 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/45 {
  --tw-gradient-to: rgb(245 243 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/5 {
  --tw-gradient-to: rgb(245 243 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/50 {
  --tw-gradient-to: rgb(245 243 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/55 {
  --tw-gradient-to: rgb(245 243 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/60 {
  --tw-gradient-to: rgb(245 243 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/65 {
  --tw-gradient-to: rgb(245 243 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/70 {
  --tw-gradient-to: rgb(245 243 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/75 {
  --tw-gradient-to: rgb(245 243 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/80 {
  --tw-gradient-to: rgb(245 243 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/85 {
  --tw-gradient-to: rgb(245 243 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/90 {
  --tw-gradient-to: rgb(245 243 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-50\/95 {
  --tw-gradient-to: rgb(245 243 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500 {
  --tw-gradient-to: #8b5cf6 var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/0 {
  --tw-gradient-to: rgb(139 92 246 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/10 {
  --tw-gradient-to: rgb(139 92 246 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/100 {
  --tw-gradient-to: rgb(139 92 246 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/15 {
  --tw-gradient-to: rgb(139 92 246 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/20 {
  --tw-gradient-to: rgb(139 92 246 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/25 {
  --tw-gradient-to: rgb(139 92 246 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/30 {
  --tw-gradient-to: rgb(139 92 246 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/35 {
  --tw-gradient-to: rgb(139 92 246 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/40 {
  --tw-gradient-to: rgb(139 92 246 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/45 {
  --tw-gradient-to: rgb(139 92 246 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/5 {
  --tw-gradient-to: rgb(139 92 246 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/50 {
  --tw-gradient-to: rgb(139 92 246 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/55 {
  --tw-gradient-to: rgb(139 92 246 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/60 {
  --tw-gradient-to: rgb(139 92 246 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/65 {
  --tw-gradient-to: rgb(139 92 246 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/70 {
  --tw-gradient-to: rgb(139 92 246 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/75 {
  --tw-gradient-to: rgb(139 92 246 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/80 {
  --tw-gradient-to: rgb(139 92 246 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/85 {
  --tw-gradient-to: rgb(139 92 246 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/90 {
  --tw-gradient-to: rgb(139 92 246 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-500\/95 {
  --tw-gradient-to: rgb(139 92 246 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600 {
  --tw-gradient-to: #7c3aed var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/0 {
  --tw-gradient-to: rgb(124 58 237 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/10 {
  --tw-gradient-to: rgb(124 58 237 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/100 {
  --tw-gradient-to: rgb(124 58 237 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/15 {
  --tw-gradient-to: rgb(124 58 237 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/20 {
  --tw-gradient-to: rgb(124 58 237 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/25 {
  --tw-gradient-to: rgb(124 58 237 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/30 {
  --tw-gradient-to: rgb(124 58 237 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/35 {
  --tw-gradient-to: rgb(124 58 237 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/40 {
  --tw-gradient-to: rgb(124 58 237 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/45 {
  --tw-gradient-to: rgb(124 58 237 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/5 {
  --tw-gradient-to: rgb(124 58 237 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/50 {
  --tw-gradient-to: rgb(124 58 237 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/55 {
  --tw-gradient-to: rgb(124 58 237 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/60 {
  --tw-gradient-to: rgb(124 58 237 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/65 {
  --tw-gradient-to: rgb(124 58 237 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/70 {
  --tw-gradient-to: rgb(124 58 237 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/75 {
  --tw-gradient-to: rgb(124 58 237 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/80 {
  --tw-gradient-to: rgb(124 58 237 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/85 {
  --tw-gradient-to: rgb(124 58 237 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/90 {
  --tw-gradient-to: rgb(124 58 237 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-600\/95 {
  --tw-gradient-to: rgb(124 58 237 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700 {
  --tw-gradient-to: #6d28d9 var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/0 {
  --tw-gradient-to: rgb(109 40 217 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/10 {
  --tw-gradient-to: rgb(109 40 217 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/100 {
  --tw-gradient-to: rgb(109 40 217 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/15 {
  --tw-gradient-to: rgb(109 40 217 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/20 {
  --tw-gradient-to: rgb(109 40 217 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/25 {
  --tw-gradient-to: rgb(109 40 217 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/30 {
  --tw-gradient-to: rgb(109 40 217 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/35 {
  --tw-gradient-to: rgb(109 40 217 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/40 {
  --tw-gradient-to: rgb(109 40 217 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/45 {
  --tw-gradient-to: rgb(109 40 217 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/5 {
  --tw-gradient-to: rgb(109 40 217 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/50 {
  --tw-gradient-to: rgb(109 40 217 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/55 {
  --tw-gradient-to: rgb(109 40 217 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/60 {
  --tw-gradient-to: rgb(109 40 217 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/65 {
  --tw-gradient-to: rgb(109 40 217 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/70 {
  --tw-gradient-to: rgb(109 40 217 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/75 {
  --tw-gradient-to: rgb(109 40 217 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/80 {
  --tw-gradient-to: rgb(109 40 217 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/85 {
  --tw-gradient-to: rgb(109 40 217 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/90 {
  --tw-gradient-to: rgb(109 40 217 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-700\/95 {
  --tw-gradient-to: rgb(109 40 217 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800 {
  --tw-gradient-to: #5b21b6 var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/0 {
  --tw-gradient-to: rgb(91 33 182 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/10 {
  --tw-gradient-to: rgb(91 33 182 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/100 {
  --tw-gradient-to: rgb(91 33 182 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/15 {
  --tw-gradient-to: rgb(91 33 182 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/20 {
  --tw-gradient-to: rgb(91 33 182 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/25 {
  --tw-gradient-to: rgb(91 33 182 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/30 {
  --tw-gradient-to: rgb(91 33 182 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/35 {
  --tw-gradient-to: rgb(91 33 182 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/40 {
  --tw-gradient-to: rgb(91 33 182 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/45 {
  --tw-gradient-to: rgb(91 33 182 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/5 {
  --tw-gradient-to: rgb(91 33 182 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/50 {
  --tw-gradient-to: rgb(91 33 182 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/55 {
  --tw-gradient-to: rgb(91 33 182 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/60 {
  --tw-gradient-to: rgb(91 33 182 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/65 {
  --tw-gradient-to: rgb(91 33 182 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/70 {
  --tw-gradient-to: rgb(91 33 182 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/75 {
  --tw-gradient-to: rgb(91 33 182 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/80 {
  --tw-gradient-to: rgb(91 33 182 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/85 {
  --tw-gradient-to: rgb(91 33 182 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/90 {
  --tw-gradient-to: rgb(91 33 182 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-800\/95 {
  --tw-gradient-to: rgb(91 33 182 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900 {
  --tw-gradient-to: #4c1d95 var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/0 {
  --tw-gradient-to: rgb(76 29 149 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/10 {
  --tw-gradient-to: rgb(76 29 149 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/100 {
  --tw-gradient-to: rgb(76 29 149 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/15 {
  --tw-gradient-to: rgb(76 29 149 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/20 {
  --tw-gradient-to: rgb(76 29 149 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/25 {
  --tw-gradient-to: rgb(76 29 149 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/30 {
  --tw-gradient-to: rgb(76 29 149 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/35 {
  --tw-gradient-to: rgb(76 29 149 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/40 {
  --tw-gradient-to: rgb(76 29 149 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/45 {
  --tw-gradient-to: rgb(76 29 149 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/5 {
  --tw-gradient-to: rgb(76 29 149 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/50 {
  --tw-gradient-to: rgb(76 29 149 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/55 {
  --tw-gradient-to: rgb(76 29 149 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/60 {
  --tw-gradient-to: rgb(76 29 149 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/65 {
  --tw-gradient-to: rgb(76 29 149 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/70 {
  --tw-gradient-to: rgb(76 29 149 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/75 {
  --tw-gradient-to: rgb(76 29 149 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/80 {
  --tw-gradient-to: rgb(76 29 149 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/85 {
  --tw-gradient-to: rgb(76 29 149 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/90 {
  --tw-gradient-to: rgb(76 29 149 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-900\/95 {
  --tw-gradient-to: rgb(76 29 149 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950 {
  --tw-gradient-to: #2e1065 var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/0 {
  --tw-gradient-to: rgb(46 16 101 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/10 {
  --tw-gradient-to: rgb(46 16 101 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/100 {
  --tw-gradient-to: rgb(46 16 101 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/15 {
  --tw-gradient-to: rgb(46 16 101 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/20 {
  --tw-gradient-to: rgb(46 16 101 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/25 {
  --tw-gradient-to: rgb(46 16 101 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/30 {
  --tw-gradient-to: rgb(46 16 101 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/35 {
  --tw-gradient-to: rgb(46 16 101 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/40 {
  --tw-gradient-to: rgb(46 16 101 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/45 {
  --tw-gradient-to: rgb(46 16 101 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/5 {
  --tw-gradient-to: rgb(46 16 101 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/50 {
  --tw-gradient-to: rgb(46 16 101 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/55 {
  --tw-gradient-to: rgb(46 16 101 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/60 {
  --tw-gradient-to: rgb(46 16 101 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/65 {
  --tw-gradient-to: rgb(46 16 101 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/70 {
  --tw-gradient-to: rgb(46 16 101 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/75 {
  --tw-gradient-to: rgb(46 16 101 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/80 {
  --tw-gradient-to: rgb(46 16 101 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/85 {
  --tw-gradient-to: rgb(46 16 101 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/90 {
  --tw-gradient-to: rgb(46 16 101 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-violet-950\/95 {
  --tw-gradient-to: rgb(46 16 101 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-white {
  --tw-gradient-to: #fff var(--tw-gradient-to-position) !important;
}

.tw-to-white\/0 {
  --tw-gradient-to: rgb(255 255 255 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/10 {
  --tw-gradient-to: rgb(255 255 255 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/100 {
  --tw-gradient-to: rgb(255 255 255 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/15 {
  --tw-gradient-to: rgb(255 255 255 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/20 {
  --tw-gradient-to: rgb(255 255 255 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/25 {
  --tw-gradient-to: rgb(255 255 255 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/30 {
  --tw-gradient-to: rgb(255 255 255 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/35 {
  --tw-gradient-to: rgb(255 255 255 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/40 {
  --tw-gradient-to: rgb(255 255 255 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/45 {
  --tw-gradient-to: rgb(255 255 255 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/5 {
  --tw-gradient-to: rgb(255 255 255 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/50 {
  --tw-gradient-to: rgb(255 255 255 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/55 {
  --tw-gradient-to: rgb(255 255 255 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/60 {
  --tw-gradient-to: rgb(255 255 255 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/65 {
  --tw-gradient-to: rgb(255 255 255 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/70 {
  --tw-gradient-to: rgb(255 255 255 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/75 {
  --tw-gradient-to: rgb(255 255 255 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/80 {
  --tw-gradient-to: rgb(255 255 255 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/85 {
  --tw-gradient-to: rgb(255 255 255 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/90 {
  --tw-gradient-to: rgb(255 255 255 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-white\/95 {
  --tw-gradient-to: rgb(255 255 255 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100 {
  --tw-gradient-to: #fef9c3 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/0 {
  --tw-gradient-to: rgb(254 249 195 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/10 {
  --tw-gradient-to: rgb(254 249 195 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/100 {
  --tw-gradient-to: rgb(254 249 195 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/15 {
  --tw-gradient-to: rgb(254 249 195 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/20 {
  --tw-gradient-to: rgb(254 249 195 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/25 {
  --tw-gradient-to: rgb(254 249 195 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/30 {
  --tw-gradient-to: rgb(254 249 195 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/35 {
  --tw-gradient-to: rgb(254 249 195 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/40 {
  --tw-gradient-to: rgb(254 249 195 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/45 {
  --tw-gradient-to: rgb(254 249 195 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/5 {
  --tw-gradient-to: rgb(254 249 195 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/50 {
  --tw-gradient-to: rgb(254 249 195 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/55 {
  --tw-gradient-to: rgb(254 249 195 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/60 {
  --tw-gradient-to: rgb(254 249 195 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/65 {
  --tw-gradient-to: rgb(254 249 195 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/70 {
  --tw-gradient-to: rgb(254 249 195 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/75 {
  --tw-gradient-to: rgb(254 249 195 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/80 {
  --tw-gradient-to: rgb(254 249 195 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/85 {
  --tw-gradient-to: rgb(254 249 195 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/90 {
  --tw-gradient-to: rgb(254 249 195 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-100\/95 {
  --tw-gradient-to: rgb(254 249 195 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200 {
  --tw-gradient-to: #fef08a var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/0 {
  --tw-gradient-to: rgb(254 240 138 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/10 {
  --tw-gradient-to: rgb(254 240 138 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/100 {
  --tw-gradient-to: rgb(254 240 138 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/15 {
  --tw-gradient-to: rgb(254 240 138 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/20 {
  --tw-gradient-to: rgb(254 240 138 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/25 {
  --tw-gradient-to: rgb(254 240 138 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/30 {
  --tw-gradient-to: rgb(254 240 138 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/35 {
  --tw-gradient-to: rgb(254 240 138 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/40 {
  --tw-gradient-to: rgb(254 240 138 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/45 {
  --tw-gradient-to: rgb(254 240 138 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/5 {
  --tw-gradient-to: rgb(254 240 138 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/50 {
  --tw-gradient-to: rgb(254 240 138 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/55 {
  --tw-gradient-to: rgb(254 240 138 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/60 {
  --tw-gradient-to: rgb(254 240 138 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/65 {
  --tw-gradient-to: rgb(254 240 138 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/70 {
  --tw-gradient-to: rgb(254 240 138 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/75 {
  --tw-gradient-to: rgb(254 240 138 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/80 {
  --tw-gradient-to: rgb(254 240 138 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/85 {
  --tw-gradient-to: rgb(254 240 138 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/90 {
  --tw-gradient-to: rgb(254 240 138 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-200\/95 {
  --tw-gradient-to: rgb(254 240 138 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300 {
  --tw-gradient-to: #fde047 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/0 {
  --tw-gradient-to: rgb(253 224 71 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/10 {
  --tw-gradient-to: rgb(253 224 71 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/100 {
  --tw-gradient-to: rgb(253 224 71 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/15 {
  --tw-gradient-to: rgb(253 224 71 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/20 {
  --tw-gradient-to: rgb(253 224 71 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/25 {
  --tw-gradient-to: rgb(253 224 71 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/30 {
  --tw-gradient-to: rgb(253 224 71 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/35 {
  --tw-gradient-to: rgb(253 224 71 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/40 {
  --tw-gradient-to: rgb(253 224 71 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/45 {
  --tw-gradient-to: rgb(253 224 71 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/5 {
  --tw-gradient-to: rgb(253 224 71 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/50 {
  --tw-gradient-to: rgb(253 224 71 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/55 {
  --tw-gradient-to: rgb(253 224 71 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/60 {
  --tw-gradient-to: rgb(253 224 71 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/65 {
  --tw-gradient-to: rgb(253 224 71 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/70 {
  --tw-gradient-to: rgb(253 224 71 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/75 {
  --tw-gradient-to: rgb(253 224 71 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/80 {
  --tw-gradient-to: rgb(253 224 71 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/85 {
  --tw-gradient-to: rgb(253 224 71 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/90 {
  --tw-gradient-to: rgb(253 224 71 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-300\/95 {
  --tw-gradient-to: rgb(253 224 71 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400 {
  --tw-gradient-to: #facc15 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/0 {
  --tw-gradient-to: rgb(250 204 21 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/10 {
  --tw-gradient-to: rgb(250 204 21 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/100 {
  --tw-gradient-to: rgb(250 204 21 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/15 {
  --tw-gradient-to: rgb(250 204 21 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/20 {
  --tw-gradient-to: rgb(250 204 21 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/25 {
  --tw-gradient-to: rgb(250 204 21 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/30 {
  --tw-gradient-to: rgb(250 204 21 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/35 {
  --tw-gradient-to: rgb(250 204 21 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/40 {
  --tw-gradient-to: rgb(250 204 21 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/45 {
  --tw-gradient-to: rgb(250 204 21 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/5 {
  --tw-gradient-to: rgb(250 204 21 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/50 {
  --tw-gradient-to: rgb(250 204 21 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/55 {
  --tw-gradient-to: rgb(250 204 21 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/60 {
  --tw-gradient-to: rgb(250 204 21 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/65 {
  --tw-gradient-to: rgb(250 204 21 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/70 {
  --tw-gradient-to: rgb(250 204 21 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/75 {
  --tw-gradient-to: rgb(250 204 21 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/80 {
  --tw-gradient-to: rgb(250 204 21 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/85 {
  --tw-gradient-to: rgb(250 204 21 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/90 {
  --tw-gradient-to: rgb(250 204 21 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-400\/95 {
  --tw-gradient-to: rgb(250 204 21 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50 {
  --tw-gradient-to: #fefce8 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/0 {
  --tw-gradient-to: rgb(254 252 232 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/10 {
  --tw-gradient-to: rgb(254 252 232 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/100 {
  --tw-gradient-to: rgb(254 252 232 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/15 {
  --tw-gradient-to: rgb(254 252 232 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/20 {
  --tw-gradient-to: rgb(254 252 232 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/25 {
  --tw-gradient-to: rgb(254 252 232 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/30 {
  --tw-gradient-to: rgb(254 252 232 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/35 {
  --tw-gradient-to: rgb(254 252 232 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/40 {
  --tw-gradient-to: rgb(254 252 232 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/45 {
  --tw-gradient-to: rgb(254 252 232 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/5 {
  --tw-gradient-to: rgb(254 252 232 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/50 {
  --tw-gradient-to: rgb(254 252 232 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/55 {
  --tw-gradient-to: rgb(254 252 232 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/60 {
  --tw-gradient-to: rgb(254 252 232 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/65 {
  --tw-gradient-to: rgb(254 252 232 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/70 {
  --tw-gradient-to: rgb(254 252 232 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/75 {
  --tw-gradient-to: rgb(254 252 232 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/80 {
  --tw-gradient-to: rgb(254 252 232 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/85 {
  --tw-gradient-to: rgb(254 252 232 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/90 {
  --tw-gradient-to: rgb(254 252 232 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-50\/95 {
  --tw-gradient-to: rgb(254 252 232 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500 {
  --tw-gradient-to: #eab308 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/0 {
  --tw-gradient-to: rgb(234 179 8 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/10 {
  --tw-gradient-to: rgb(234 179 8 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/100 {
  --tw-gradient-to: rgb(234 179 8 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/15 {
  --tw-gradient-to: rgb(234 179 8 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/20 {
  --tw-gradient-to: rgb(234 179 8 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/25 {
  --tw-gradient-to: rgb(234 179 8 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/30 {
  --tw-gradient-to: rgb(234 179 8 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/35 {
  --tw-gradient-to: rgb(234 179 8 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/40 {
  --tw-gradient-to: rgb(234 179 8 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/45 {
  --tw-gradient-to: rgb(234 179 8 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/5 {
  --tw-gradient-to: rgb(234 179 8 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/50 {
  --tw-gradient-to: rgb(234 179 8 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/55 {
  --tw-gradient-to: rgb(234 179 8 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/60 {
  --tw-gradient-to: rgb(234 179 8 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/65 {
  --tw-gradient-to: rgb(234 179 8 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/70 {
  --tw-gradient-to: rgb(234 179 8 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/75 {
  --tw-gradient-to: rgb(234 179 8 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/80 {
  --tw-gradient-to: rgb(234 179 8 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/85 {
  --tw-gradient-to: rgb(234 179 8 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/90 {
  --tw-gradient-to: rgb(234 179 8 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-500\/95 {
  --tw-gradient-to: rgb(234 179 8 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600 {
  --tw-gradient-to: #ca8a04 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/0 {
  --tw-gradient-to: rgb(202 138 4 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/10 {
  --tw-gradient-to: rgb(202 138 4 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/100 {
  --tw-gradient-to: rgb(202 138 4 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/15 {
  --tw-gradient-to: rgb(202 138 4 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/20 {
  --tw-gradient-to: rgb(202 138 4 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/25 {
  --tw-gradient-to: rgb(202 138 4 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/30 {
  --tw-gradient-to: rgb(202 138 4 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/35 {
  --tw-gradient-to: rgb(202 138 4 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/40 {
  --tw-gradient-to: rgb(202 138 4 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/45 {
  --tw-gradient-to: rgb(202 138 4 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/5 {
  --tw-gradient-to: rgb(202 138 4 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/50 {
  --tw-gradient-to: rgb(202 138 4 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/55 {
  --tw-gradient-to: rgb(202 138 4 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/60 {
  --tw-gradient-to: rgb(202 138 4 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/65 {
  --tw-gradient-to: rgb(202 138 4 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/70 {
  --tw-gradient-to: rgb(202 138 4 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/75 {
  --tw-gradient-to: rgb(202 138 4 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/80 {
  --tw-gradient-to: rgb(202 138 4 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/85 {
  --tw-gradient-to: rgb(202 138 4 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/90 {
  --tw-gradient-to: rgb(202 138 4 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-600\/95 {
  --tw-gradient-to: rgb(202 138 4 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700 {
  --tw-gradient-to: #a16207 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/0 {
  --tw-gradient-to: rgb(161 98 7 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/10 {
  --tw-gradient-to: rgb(161 98 7 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/100 {
  --tw-gradient-to: rgb(161 98 7 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/15 {
  --tw-gradient-to: rgb(161 98 7 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/20 {
  --tw-gradient-to: rgb(161 98 7 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/25 {
  --tw-gradient-to: rgb(161 98 7 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/30 {
  --tw-gradient-to: rgb(161 98 7 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/35 {
  --tw-gradient-to: rgb(161 98 7 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/40 {
  --tw-gradient-to: rgb(161 98 7 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/45 {
  --tw-gradient-to: rgb(161 98 7 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/5 {
  --tw-gradient-to: rgb(161 98 7 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/50 {
  --tw-gradient-to: rgb(161 98 7 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/55 {
  --tw-gradient-to: rgb(161 98 7 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/60 {
  --tw-gradient-to: rgb(161 98 7 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/65 {
  --tw-gradient-to: rgb(161 98 7 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/70 {
  --tw-gradient-to: rgb(161 98 7 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/75 {
  --tw-gradient-to: rgb(161 98 7 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/80 {
  --tw-gradient-to: rgb(161 98 7 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/85 {
  --tw-gradient-to: rgb(161 98 7 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/90 {
  --tw-gradient-to: rgb(161 98 7 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-700\/95 {
  --tw-gradient-to: rgb(161 98 7 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800 {
  --tw-gradient-to: #854d0e var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/0 {
  --tw-gradient-to: rgb(133 77 14 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/10 {
  --tw-gradient-to: rgb(133 77 14 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/100 {
  --tw-gradient-to: rgb(133 77 14 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/15 {
  --tw-gradient-to: rgb(133 77 14 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/20 {
  --tw-gradient-to: rgb(133 77 14 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/25 {
  --tw-gradient-to: rgb(133 77 14 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/30 {
  --tw-gradient-to: rgb(133 77 14 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/35 {
  --tw-gradient-to: rgb(133 77 14 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/40 {
  --tw-gradient-to: rgb(133 77 14 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/45 {
  --tw-gradient-to: rgb(133 77 14 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/5 {
  --tw-gradient-to: rgb(133 77 14 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/50 {
  --tw-gradient-to: rgb(133 77 14 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/55 {
  --tw-gradient-to: rgb(133 77 14 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/60 {
  --tw-gradient-to: rgb(133 77 14 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/65 {
  --tw-gradient-to: rgb(133 77 14 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/70 {
  --tw-gradient-to: rgb(133 77 14 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/75 {
  --tw-gradient-to: rgb(133 77 14 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/80 {
  --tw-gradient-to: rgb(133 77 14 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/85 {
  --tw-gradient-to: rgb(133 77 14 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/90 {
  --tw-gradient-to: rgb(133 77 14 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-800\/95 {
  --tw-gradient-to: rgb(133 77 14 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900 {
  --tw-gradient-to: #713f12 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/0 {
  --tw-gradient-to: rgb(113 63 18 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/10 {
  --tw-gradient-to: rgb(113 63 18 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/100 {
  --tw-gradient-to: rgb(113 63 18 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/15 {
  --tw-gradient-to: rgb(113 63 18 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/20 {
  --tw-gradient-to: rgb(113 63 18 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/25 {
  --tw-gradient-to: rgb(113 63 18 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/30 {
  --tw-gradient-to: rgb(113 63 18 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/35 {
  --tw-gradient-to: rgb(113 63 18 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/40 {
  --tw-gradient-to: rgb(113 63 18 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/45 {
  --tw-gradient-to: rgb(113 63 18 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/5 {
  --tw-gradient-to: rgb(113 63 18 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/50 {
  --tw-gradient-to: rgb(113 63 18 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/55 {
  --tw-gradient-to: rgb(113 63 18 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/60 {
  --tw-gradient-to: rgb(113 63 18 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/65 {
  --tw-gradient-to: rgb(113 63 18 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/70 {
  --tw-gradient-to: rgb(113 63 18 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/75 {
  --tw-gradient-to: rgb(113 63 18 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/80 {
  --tw-gradient-to: rgb(113 63 18 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/85 {
  --tw-gradient-to: rgb(113 63 18 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/90 {
  --tw-gradient-to: rgb(113 63 18 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-900\/95 {
  --tw-gradient-to: rgb(113 63 18 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950 {
  --tw-gradient-to: #422006 var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/0 {
  --tw-gradient-to: rgb(66 32 6 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/10 {
  --tw-gradient-to: rgb(66 32 6 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/100 {
  --tw-gradient-to: rgb(66 32 6 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/15 {
  --tw-gradient-to: rgb(66 32 6 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/20 {
  --tw-gradient-to: rgb(66 32 6 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/25 {
  --tw-gradient-to: rgb(66 32 6 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/30 {
  --tw-gradient-to: rgb(66 32 6 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/35 {
  --tw-gradient-to: rgb(66 32 6 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/40 {
  --tw-gradient-to: rgb(66 32 6 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/45 {
  --tw-gradient-to: rgb(66 32 6 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/5 {
  --tw-gradient-to: rgb(66 32 6 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/50 {
  --tw-gradient-to: rgb(66 32 6 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/55 {
  --tw-gradient-to: rgb(66 32 6 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/60 {
  --tw-gradient-to: rgb(66 32 6 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/65 {
  --tw-gradient-to: rgb(66 32 6 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/70 {
  --tw-gradient-to: rgb(66 32 6 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/75 {
  --tw-gradient-to: rgb(66 32 6 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/80 {
  --tw-gradient-to: rgb(66 32 6 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/85 {
  --tw-gradient-to: rgb(66 32 6 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/90 {
  --tw-gradient-to: rgb(66 32 6 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-yellow-950\/95 {
  --tw-gradient-to: rgb(66 32 6 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100 {
  --tw-gradient-to: #f4f4f5 var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/0 {
  --tw-gradient-to: rgb(244 244 245 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/10 {
  --tw-gradient-to: rgb(244 244 245 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/100 {
  --tw-gradient-to: rgb(244 244 245 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/15 {
  --tw-gradient-to: rgb(244 244 245 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/20 {
  --tw-gradient-to: rgb(244 244 245 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/25 {
  --tw-gradient-to: rgb(244 244 245 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/30 {
  --tw-gradient-to: rgb(244 244 245 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/35 {
  --tw-gradient-to: rgb(244 244 245 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/40 {
  --tw-gradient-to: rgb(244 244 245 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/45 {
  --tw-gradient-to: rgb(244 244 245 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/5 {
  --tw-gradient-to: rgb(244 244 245 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/50 {
  --tw-gradient-to: rgb(244 244 245 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/55 {
  --tw-gradient-to: rgb(244 244 245 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/60 {
  --tw-gradient-to: rgb(244 244 245 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/65 {
  --tw-gradient-to: rgb(244 244 245 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/70 {
  --tw-gradient-to: rgb(244 244 245 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/75 {
  --tw-gradient-to: rgb(244 244 245 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/80 {
  --tw-gradient-to: rgb(244 244 245 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/85 {
  --tw-gradient-to: rgb(244 244 245 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/90 {
  --tw-gradient-to: rgb(244 244 245 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-100\/95 {
  --tw-gradient-to: rgb(244 244 245 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200 {
  --tw-gradient-to: #e4e4e7 var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/0 {
  --tw-gradient-to: rgb(228 228 231 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/10 {
  --tw-gradient-to: rgb(228 228 231 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/100 {
  --tw-gradient-to: rgb(228 228 231 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/15 {
  --tw-gradient-to: rgb(228 228 231 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/20 {
  --tw-gradient-to: rgb(228 228 231 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/25 {
  --tw-gradient-to: rgb(228 228 231 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/30 {
  --tw-gradient-to: rgb(228 228 231 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/35 {
  --tw-gradient-to: rgb(228 228 231 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/40 {
  --tw-gradient-to: rgb(228 228 231 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/45 {
  --tw-gradient-to: rgb(228 228 231 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/5 {
  --tw-gradient-to: rgb(228 228 231 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/50 {
  --tw-gradient-to: rgb(228 228 231 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/55 {
  --tw-gradient-to: rgb(228 228 231 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/60 {
  --tw-gradient-to: rgb(228 228 231 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/65 {
  --tw-gradient-to: rgb(228 228 231 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/70 {
  --tw-gradient-to: rgb(228 228 231 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/75 {
  --tw-gradient-to: rgb(228 228 231 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/80 {
  --tw-gradient-to: rgb(228 228 231 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/85 {
  --tw-gradient-to: rgb(228 228 231 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/90 {
  --tw-gradient-to: rgb(228 228 231 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-200\/95 {
  --tw-gradient-to: rgb(228 228 231 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300 {
  --tw-gradient-to: #d4d4d8 var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/0 {
  --tw-gradient-to: rgb(212 212 216 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/10 {
  --tw-gradient-to: rgb(212 212 216 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/100 {
  --tw-gradient-to: rgb(212 212 216 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/15 {
  --tw-gradient-to: rgb(212 212 216 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/20 {
  --tw-gradient-to: rgb(212 212 216 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/25 {
  --tw-gradient-to: rgb(212 212 216 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/30 {
  --tw-gradient-to: rgb(212 212 216 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/35 {
  --tw-gradient-to: rgb(212 212 216 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/40 {
  --tw-gradient-to: rgb(212 212 216 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/45 {
  --tw-gradient-to: rgb(212 212 216 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/5 {
  --tw-gradient-to: rgb(212 212 216 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/50 {
  --tw-gradient-to: rgb(212 212 216 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/55 {
  --tw-gradient-to: rgb(212 212 216 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/60 {
  --tw-gradient-to: rgb(212 212 216 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/65 {
  --tw-gradient-to: rgb(212 212 216 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/70 {
  --tw-gradient-to: rgb(212 212 216 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/75 {
  --tw-gradient-to: rgb(212 212 216 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/80 {
  --tw-gradient-to: rgb(212 212 216 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/85 {
  --tw-gradient-to: rgb(212 212 216 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/90 {
  --tw-gradient-to: rgb(212 212 216 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-300\/95 {
  --tw-gradient-to: rgb(212 212 216 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400 {
  --tw-gradient-to: #a1a1aa var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/0 {
  --tw-gradient-to: rgb(161 161 170 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/10 {
  --tw-gradient-to: rgb(161 161 170 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/100 {
  --tw-gradient-to: rgb(161 161 170 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/15 {
  --tw-gradient-to: rgb(161 161 170 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/20 {
  --tw-gradient-to: rgb(161 161 170 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/25 {
  --tw-gradient-to: rgb(161 161 170 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/30 {
  --tw-gradient-to: rgb(161 161 170 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/35 {
  --tw-gradient-to: rgb(161 161 170 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/40 {
  --tw-gradient-to: rgb(161 161 170 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/45 {
  --tw-gradient-to: rgb(161 161 170 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/5 {
  --tw-gradient-to: rgb(161 161 170 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/50 {
  --tw-gradient-to: rgb(161 161 170 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/55 {
  --tw-gradient-to: rgb(161 161 170 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/60 {
  --tw-gradient-to: rgb(161 161 170 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/65 {
  --tw-gradient-to: rgb(161 161 170 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/70 {
  --tw-gradient-to: rgb(161 161 170 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/75 {
  --tw-gradient-to: rgb(161 161 170 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/80 {
  --tw-gradient-to: rgb(161 161 170 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/85 {
  --tw-gradient-to: rgb(161 161 170 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/90 {
  --tw-gradient-to: rgb(161 161 170 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-400\/95 {
  --tw-gradient-to: rgb(161 161 170 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50 {
  --tw-gradient-to: #fafafa var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/0 {
  --tw-gradient-to: rgb(250 250 250 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/10 {
  --tw-gradient-to: rgb(250 250 250 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/100 {
  --tw-gradient-to: rgb(250 250 250 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/15 {
  --tw-gradient-to: rgb(250 250 250 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/20 {
  --tw-gradient-to: rgb(250 250 250 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/25 {
  --tw-gradient-to: rgb(250 250 250 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/30 {
  --tw-gradient-to: rgb(250 250 250 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/35 {
  --tw-gradient-to: rgb(250 250 250 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/40 {
  --tw-gradient-to: rgb(250 250 250 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/45 {
  --tw-gradient-to: rgb(250 250 250 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/5 {
  --tw-gradient-to: rgb(250 250 250 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/50 {
  --tw-gradient-to: rgb(250 250 250 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/55 {
  --tw-gradient-to: rgb(250 250 250 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/60 {
  --tw-gradient-to: rgb(250 250 250 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/65 {
  --tw-gradient-to: rgb(250 250 250 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/70 {
  --tw-gradient-to: rgb(250 250 250 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/75 {
  --tw-gradient-to: rgb(250 250 250 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/80 {
  --tw-gradient-to: rgb(250 250 250 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/85 {
  --tw-gradient-to: rgb(250 250 250 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/90 {
  --tw-gradient-to: rgb(250 250 250 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-50\/95 {
  --tw-gradient-to: rgb(250 250 250 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500 {
  --tw-gradient-to: #71717a var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/0 {
  --tw-gradient-to: rgb(113 113 122 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/10 {
  --tw-gradient-to: rgb(113 113 122 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/100 {
  --tw-gradient-to: rgb(113 113 122 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/15 {
  --tw-gradient-to: rgb(113 113 122 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/20 {
  --tw-gradient-to: rgb(113 113 122 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/25 {
  --tw-gradient-to: rgb(113 113 122 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/30 {
  --tw-gradient-to: rgb(113 113 122 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/35 {
  --tw-gradient-to: rgb(113 113 122 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/40 {
  --tw-gradient-to: rgb(113 113 122 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/45 {
  --tw-gradient-to: rgb(113 113 122 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/5 {
  --tw-gradient-to: rgb(113 113 122 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/50 {
  --tw-gradient-to: rgb(113 113 122 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/55 {
  --tw-gradient-to: rgb(113 113 122 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/60 {
  --tw-gradient-to: rgb(113 113 122 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/65 {
  --tw-gradient-to: rgb(113 113 122 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/70 {
  --tw-gradient-to: rgb(113 113 122 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/75 {
  --tw-gradient-to: rgb(113 113 122 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/80 {
  --tw-gradient-to: rgb(113 113 122 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/85 {
  --tw-gradient-to: rgb(113 113 122 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/90 {
  --tw-gradient-to: rgb(113 113 122 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-500\/95 {
  --tw-gradient-to: rgb(113 113 122 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600 {
  --tw-gradient-to: #52525b var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/0 {
  --tw-gradient-to: rgb(82 82 91 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/10 {
  --tw-gradient-to: rgb(82 82 91 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/100 {
  --tw-gradient-to: rgb(82 82 91 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/15 {
  --tw-gradient-to: rgb(82 82 91 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/20 {
  --tw-gradient-to: rgb(82 82 91 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/25 {
  --tw-gradient-to: rgb(82 82 91 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/30 {
  --tw-gradient-to: rgb(82 82 91 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/35 {
  --tw-gradient-to: rgb(82 82 91 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/40 {
  --tw-gradient-to: rgb(82 82 91 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/45 {
  --tw-gradient-to: rgb(82 82 91 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/5 {
  --tw-gradient-to: rgb(82 82 91 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/50 {
  --tw-gradient-to: rgb(82 82 91 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/55 {
  --tw-gradient-to: rgb(82 82 91 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/60 {
  --tw-gradient-to: rgb(82 82 91 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/65 {
  --tw-gradient-to: rgb(82 82 91 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/70 {
  --tw-gradient-to: rgb(82 82 91 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/75 {
  --tw-gradient-to: rgb(82 82 91 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/80 {
  --tw-gradient-to: rgb(82 82 91 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/85 {
  --tw-gradient-to: rgb(82 82 91 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/90 {
  --tw-gradient-to: rgb(82 82 91 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-600\/95 {
  --tw-gradient-to: rgb(82 82 91 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700 {
  --tw-gradient-to: #3f3f46 var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/0 {
  --tw-gradient-to: rgb(63 63 70 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/10 {
  --tw-gradient-to: rgb(63 63 70 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/100 {
  --tw-gradient-to: rgb(63 63 70 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/15 {
  --tw-gradient-to: rgb(63 63 70 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/20 {
  --tw-gradient-to: rgb(63 63 70 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/25 {
  --tw-gradient-to: rgb(63 63 70 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/30 {
  --tw-gradient-to: rgb(63 63 70 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/35 {
  --tw-gradient-to: rgb(63 63 70 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/40 {
  --tw-gradient-to: rgb(63 63 70 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/45 {
  --tw-gradient-to: rgb(63 63 70 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/5 {
  --tw-gradient-to: rgb(63 63 70 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/50 {
  --tw-gradient-to: rgb(63 63 70 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/55 {
  --tw-gradient-to: rgb(63 63 70 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/60 {
  --tw-gradient-to: rgb(63 63 70 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/65 {
  --tw-gradient-to: rgb(63 63 70 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/70 {
  --tw-gradient-to: rgb(63 63 70 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/75 {
  --tw-gradient-to: rgb(63 63 70 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/80 {
  --tw-gradient-to: rgb(63 63 70 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/85 {
  --tw-gradient-to: rgb(63 63 70 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/90 {
  --tw-gradient-to: rgb(63 63 70 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-700\/95 {
  --tw-gradient-to: rgb(63 63 70 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800 {
  --tw-gradient-to: #27272a var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/0 {
  --tw-gradient-to: rgb(39 39 42 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/10 {
  --tw-gradient-to: rgb(39 39 42 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/100 {
  --tw-gradient-to: rgb(39 39 42 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/15 {
  --tw-gradient-to: rgb(39 39 42 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/20 {
  --tw-gradient-to: rgb(39 39 42 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/25 {
  --tw-gradient-to: rgb(39 39 42 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/30 {
  --tw-gradient-to: rgb(39 39 42 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/35 {
  --tw-gradient-to: rgb(39 39 42 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/40 {
  --tw-gradient-to: rgb(39 39 42 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/45 {
  --tw-gradient-to: rgb(39 39 42 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/5 {
  --tw-gradient-to: rgb(39 39 42 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/50 {
  --tw-gradient-to: rgb(39 39 42 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/55 {
  --tw-gradient-to: rgb(39 39 42 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/60 {
  --tw-gradient-to: rgb(39 39 42 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/65 {
  --tw-gradient-to: rgb(39 39 42 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/70 {
  --tw-gradient-to: rgb(39 39 42 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/75 {
  --tw-gradient-to: rgb(39 39 42 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/80 {
  --tw-gradient-to: rgb(39 39 42 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/85 {
  --tw-gradient-to: rgb(39 39 42 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/90 {
  --tw-gradient-to: rgb(39 39 42 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-800\/95 {
  --tw-gradient-to: rgb(39 39 42 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900 {
  --tw-gradient-to: #18181b var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/0 {
  --tw-gradient-to: rgb(24 24 27 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/10 {
  --tw-gradient-to: rgb(24 24 27 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/100 {
  --tw-gradient-to: rgb(24 24 27 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/15 {
  --tw-gradient-to: rgb(24 24 27 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/20 {
  --tw-gradient-to: rgb(24 24 27 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/25 {
  --tw-gradient-to: rgb(24 24 27 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/30 {
  --tw-gradient-to: rgb(24 24 27 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/35 {
  --tw-gradient-to: rgb(24 24 27 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/40 {
  --tw-gradient-to: rgb(24 24 27 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/45 {
  --tw-gradient-to: rgb(24 24 27 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/5 {
  --tw-gradient-to: rgb(24 24 27 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/50 {
  --tw-gradient-to: rgb(24 24 27 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/55 {
  --tw-gradient-to: rgb(24 24 27 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/60 {
  --tw-gradient-to: rgb(24 24 27 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/65 {
  --tw-gradient-to: rgb(24 24 27 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/70 {
  --tw-gradient-to: rgb(24 24 27 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/75 {
  --tw-gradient-to: rgb(24 24 27 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/80 {
  --tw-gradient-to: rgb(24 24 27 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/85 {
  --tw-gradient-to: rgb(24 24 27 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/90 {
  --tw-gradient-to: rgb(24 24 27 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-900\/95 {
  --tw-gradient-to: rgb(24 24 27 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950 {
  --tw-gradient-to: #09090b var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/0 {
  --tw-gradient-to: rgb(9 9 11 / 0) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/10 {
  --tw-gradient-to: rgb(9 9 11 / 0.1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/100 {
  --tw-gradient-to: rgb(9 9 11 / 1) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/15 {
  --tw-gradient-to: rgb(9 9 11 / 0.15) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/20 {
  --tw-gradient-to: rgb(9 9 11 / 0.2) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/25 {
  --tw-gradient-to: rgb(9 9 11 / 0.25) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/30 {
  --tw-gradient-to: rgb(9 9 11 / 0.3) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/35 {
  --tw-gradient-to: rgb(9 9 11 / 0.35) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/40 {
  --tw-gradient-to: rgb(9 9 11 / 0.4) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/45 {
  --tw-gradient-to: rgb(9 9 11 / 0.45) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/5 {
  --tw-gradient-to: rgb(9 9 11 / 0.05) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/50 {
  --tw-gradient-to: rgb(9 9 11 / 0.5) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/55 {
  --tw-gradient-to: rgb(9 9 11 / 0.55) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/60 {
  --tw-gradient-to: rgb(9 9 11 / 0.6) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/65 {
  --tw-gradient-to: rgb(9 9 11 / 0.65) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/70 {
  --tw-gradient-to: rgb(9 9 11 / 0.7) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/75 {
  --tw-gradient-to: rgb(9 9 11 / 0.75) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/80 {
  --tw-gradient-to: rgb(9 9 11 / 0.8) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/85 {
  --tw-gradient-to: rgb(9 9 11 / 0.85) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/90 {
  --tw-gradient-to: rgb(9 9 11 / 0.9) var(--tw-gradient-to-position) !important;
}

.tw-to-zinc-950\/95 {
  --tw-gradient-to: rgb(9 9 11 / 0.95) var(--tw-gradient-to-position) !important;
}

.tw-to-0\% {
  --tw-gradient-to-position: 0% !important;
}

.tw-to-10\% {
  --tw-gradient-to-position: 10% !important;
}

.tw-to-100\% {
  --tw-gradient-to-position: 100% !important;
}

.tw-to-15\% {
  --tw-gradient-to-position: 15% !important;
}

.tw-to-20\% {
  --tw-gradient-to-position: 20% !important;
}

.tw-to-25\% {
  --tw-gradient-to-position: 25% !important;
}

.tw-to-30\% {
  --tw-gradient-to-position: 30% !important;
}

.tw-to-35\% {
  --tw-gradient-to-position: 35% !important;
}

.tw-to-40\% {
  --tw-gradient-to-position: 40% !important;
}

.tw-to-45\% {
  --tw-gradient-to-position: 45% !important;
}

.tw-to-5\% {
  --tw-gradient-to-position: 5% !important;
}

.tw-to-50\% {
  --tw-gradient-to-position: 50% !important;
}

.tw-to-55\% {
  --tw-gradient-to-position: 55% !important;
}

.tw-to-60\% {
  --tw-gradient-to-position: 60% !important;
}

.tw-to-65\% {
  --tw-gradient-to-position: 65% !important;
}

.tw-to-70\% {
  --tw-gradient-to-position: 70% !important;
}

.tw-to-75\% {
  --tw-gradient-to-position: 75% !important;
}

.tw-to-80\% {
  --tw-gradient-to-position: 80% !important;
}

.tw-to-85\% {
  --tw-gradient-to-position: 85% !important;
}

.tw-to-90\% {
  --tw-gradient-to-position: 90% !important;
}

.tw-to-95\% {
  --tw-gradient-to-position: 95% !important;
}

.tw-decoration-slice {
  -webkit-box-decoration-break: slice !important;
          box-decoration-break: slice !important;
}

.tw-decoration-clone {
  -webkit-box-decoration-break: clone !important;
          box-decoration-break: clone !important;
}

.tw-box-decoration-slice {
  -webkit-box-decoration-break: slice !important;
          box-decoration-break: slice !important;
}

.tw-box-decoration-clone {
  -webkit-box-decoration-break: clone !important;
          box-decoration-break: clone !important;
}

.tw-bg-auto {
  background-size: auto !important;
}

.tw-bg-contain {
  background-size: contain !important;
}

.tw-bg-cover {
  background-size: cover !important;
}

.tw-bg-fixed {
  background-attachment: fixed !important;
}

.tw-bg-local {
  background-attachment: local !important;
}

.tw-bg-scroll {
  background-attachment: scroll !important;
}

.tw-bg-clip-border {
  background-clip: border-box !important;
}

.tw-bg-clip-padding {
  background-clip: padding-box !important;
}

.tw-bg-clip-content {
  background-clip: content-box !important;
}

.tw-bg-clip-text {
  -webkit-background-clip: text !important;
          background-clip: text !important;
}

.tw-bg-bottom {
  background-position: bottom !important;
}

.tw-bg-center {
  background-position: center !important;
}

.tw-bg-left {
  background-position: left !important;
}

.tw-bg-left-bottom {
  background-position: left bottom !important;
}

.tw-bg-left-top {
  background-position: left top !important;
}

.tw-bg-right {
  background-position: right !important;
}

.tw-bg-right-bottom {
  background-position: right bottom !important;
}

.tw-bg-right-top {
  background-position: right top !important;
}

.tw-bg-top {
  background-position: top !important;
}

.tw-bg-repeat {
  background-repeat: repeat !important;
}

.tw-bg-no-repeat {
  background-repeat: no-repeat !important;
}

.tw-bg-repeat-x {
  background-repeat: repeat-x !important;
}

.tw-bg-repeat-y {
  background-repeat: repeat-y !important;
}

.tw-bg-repeat-round {
  background-repeat: round !important;
}

.tw-bg-repeat-space {
  background-repeat: space !important;
}

.tw-bg-origin-border {
  background-origin: border-box !important;
}

.tw-bg-origin-padding {
  background-origin: padding-box !important;
}

.tw-bg-origin-content {
  background-origin: content-box !important;
}

.tw-fill-amber-100 {
  fill: #fef3c7 !important;
}

.tw-fill-amber-100\/0 {
  fill: rgb(254 243 199 / 0) !important;
}

.tw-fill-amber-100\/10 {
  fill: rgb(254 243 199 / 0.1) !important;
}

.tw-fill-amber-100\/100 {
  fill: rgb(254 243 199 / 1) !important;
}

.tw-fill-amber-100\/15 {
  fill: rgb(254 243 199 / 0.15) !important;
}

.tw-fill-amber-100\/20 {
  fill: rgb(254 243 199 / 0.2) !important;
}

.tw-fill-amber-100\/25 {
  fill: rgb(254 243 199 / 0.25) !important;
}

.tw-fill-amber-100\/30 {
  fill: rgb(254 243 199 / 0.3) !important;
}

.tw-fill-amber-100\/35 {
  fill: rgb(254 243 199 / 0.35) !important;
}

.tw-fill-amber-100\/40 {
  fill: rgb(254 243 199 / 0.4) !important;
}

.tw-fill-amber-100\/45 {
  fill: rgb(254 243 199 / 0.45) !important;
}

.tw-fill-amber-100\/5 {
  fill: rgb(254 243 199 / 0.05) !important;
}

.tw-fill-amber-100\/50 {
  fill: rgb(254 243 199 / 0.5) !important;
}

.tw-fill-amber-100\/55 {
  fill: rgb(254 243 199 / 0.55) !important;
}

.tw-fill-amber-100\/60 {
  fill: rgb(254 243 199 / 0.6) !important;
}

.tw-fill-amber-100\/65 {
  fill: rgb(254 243 199 / 0.65) !important;
}

.tw-fill-amber-100\/70 {
  fill: rgb(254 243 199 / 0.7) !important;
}

.tw-fill-amber-100\/75 {
  fill: rgb(254 243 199 / 0.75) !important;
}

.tw-fill-amber-100\/80 {
  fill: rgb(254 243 199 / 0.8) !important;
}

.tw-fill-amber-100\/85 {
  fill: rgb(254 243 199 / 0.85) !important;
}

.tw-fill-amber-100\/90 {
  fill: rgb(254 243 199 / 0.9) !important;
}

.tw-fill-amber-100\/95 {
  fill: rgb(254 243 199 / 0.95) !important;
}

.tw-fill-amber-200 {
  fill: #fde68a !important;
}

.tw-fill-amber-200\/0 {
  fill: rgb(253 230 138 / 0) !important;
}

.tw-fill-amber-200\/10 {
  fill: rgb(253 230 138 / 0.1) !important;
}

.tw-fill-amber-200\/100 {
  fill: rgb(253 230 138 / 1) !important;
}

.tw-fill-amber-200\/15 {
  fill: rgb(253 230 138 / 0.15) !important;
}

.tw-fill-amber-200\/20 {
  fill: rgb(253 230 138 / 0.2) !important;
}

.tw-fill-amber-200\/25 {
  fill: rgb(253 230 138 / 0.25) !important;
}

.tw-fill-amber-200\/30 {
  fill: rgb(253 230 138 / 0.3) !important;
}

.tw-fill-amber-200\/35 {
  fill: rgb(253 230 138 / 0.35) !important;
}

.tw-fill-amber-200\/40 {
  fill: rgb(253 230 138 / 0.4) !important;
}

.tw-fill-amber-200\/45 {
  fill: rgb(253 230 138 / 0.45) !important;
}

.tw-fill-amber-200\/5 {
  fill: rgb(253 230 138 / 0.05) !important;
}

.tw-fill-amber-200\/50 {
  fill: rgb(253 230 138 / 0.5) !important;
}

.tw-fill-amber-200\/55 {
  fill: rgb(253 230 138 / 0.55) !important;
}

.tw-fill-amber-200\/60 {
  fill: rgb(253 230 138 / 0.6) !important;
}

.tw-fill-amber-200\/65 {
  fill: rgb(253 230 138 / 0.65) !important;
}

.tw-fill-amber-200\/70 {
  fill: rgb(253 230 138 / 0.7) !important;
}

.tw-fill-amber-200\/75 {
  fill: rgb(253 230 138 / 0.75) !important;
}

.tw-fill-amber-200\/80 {
  fill: rgb(253 230 138 / 0.8) !important;
}

.tw-fill-amber-200\/85 {
  fill: rgb(253 230 138 / 0.85) !important;
}

.tw-fill-amber-200\/90 {
  fill: rgb(253 230 138 / 0.9) !important;
}

.tw-fill-amber-200\/95 {
  fill: rgb(253 230 138 / 0.95) !important;
}

.tw-fill-amber-300 {
  fill: #fcd34d !important;
}

.tw-fill-amber-300\/0 {
  fill: rgb(252 211 77 / 0) !important;
}

.tw-fill-amber-300\/10 {
  fill: rgb(252 211 77 / 0.1) !important;
}

.tw-fill-amber-300\/100 {
  fill: rgb(252 211 77 / 1) !important;
}

.tw-fill-amber-300\/15 {
  fill: rgb(252 211 77 / 0.15) !important;
}

.tw-fill-amber-300\/20 {
  fill: rgb(252 211 77 / 0.2) !important;
}

.tw-fill-amber-300\/25 {
  fill: rgb(252 211 77 / 0.25) !important;
}

.tw-fill-amber-300\/30 {
  fill: rgb(252 211 77 / 0.3) !important;
}

.tw-fill-amber-300\/35 {
  fill: rgb(252 211 77 / 0.35) !important;
}

.tw-fill-amber-300\/40 {
  fill: rgb(252 211 77 / 0.4) !important;
}

.tw-fill-amber-300\/45 {
  fill: rgb(252 211 77 / 0.45) !important;
}

.tw-fill-amber-300\/5 {
  fill: rgb(252 211 77 / 0.05) !important;
}

.tw-fill-amber-300\/50 {
  fill: rgb(252 211 77 / 0.5) !important;
}

.tw-fill-amber-300\/55 {
  fill: rgb(252 211 77 / 0.55) !important;
}

.tw-fill-amber-300\/60 {
  fill: rgb(252 211 77 / 0.6) !important;
}

.tw-fill-amber-300\/65 {
  fill: rgb(252 211 77 / 0.65) !important;
}

.tw-fill-amber-300\/70 {
  fill: rgb(252 211 77 / 0.7) !important;
}

.tw-fill-amber-300\/75 {
  fill: rgb(252 211 77 / 0.75) !important;
}

.tw-fill-amber-300\/80 {
  fill: rgb(252 211 77 / 0.8) !important;
}

.tw-fill-amber-300\/85 {
  fill: rgb(252 211 77 / 0.85) !important;
}

.tw-fill-amber-300\/90 {
  fill: rgb(252 211 77 / 0.9) !important;
}

.tw-fill-amber-300\/95 {
  fill: rgb(252 211 77 / 0.95) !important;
}

.tw-fill-amber-400 {
  fill: #fbbf24 !important;
}

.tw-fill-amber-400\/0 {
  fill: rgb(251 191 36 / 0) !important;
}

.tw-fill-amber-400\/10 {
  fill: rgb(251 191 36 / 0.1) !important;
}

.tw-fill-amber-400\/100 {
  fill: rgb(251 191 36 / 1) !important;
}

.tw-fill-amber-400\/15 {
  fill: rgb(251 191 36 / 0.15) !important;
}

.tw-fill-amber-400\/20 {
  fill: rgb(251 191 36 / 0.2) !important;
}

.tw-fill-amber-400\/25 {
  fill: rgb(251 191 36 / 0.25) !important;
}

.tw-fill-amber-400\/30 {
  fill: rgb(251 191 36 / 0.3) !important;
}

.tw-fill-amber-400\/35 {
  fill: rgb(251 191 36 / 0.35) !important;
}

.tw-fill-amber-400\/40 {
  fill: rgb(251 191 36 / 0.4) !important;
}

.tw-fill-amber-400\/45 {
  fill: rgb(251 191 36 / 0.45) !important;
}

.tw-fill-amber-400\/5 {
  fill: rgb(251 191 36 / 0.05) !important;
}

.tw-fill-amber-400\/50 {
  fill: rgb(251 191 36 / 0.5) !important;
}

.tw-fill-amber-400\/55 {
  fill: rgb(251 191 36 / 0.55) !important;
}

.tw-fill-amber-400\/60 {
  fill: rgb(251 191 36 / 0.6) !important;
}

.tw-fill-amber-400\/65 {
  fill: rgb(251 191 36 / 0.65) !important;
}

.tw-fill-amber-400\/70 {
  fill: rgb(251 191 36 / 0.7) !important;
}

.tw-fill-amber-400\/75 {
  fill: rgb(251 191 36 / 0.75) !important;
}

.tw-fill-amber-400\/80 {
  fill: rgb(251 191 36 / 0.8) !important;
}

.tw-fill-amber-400\/85 {
  fill: rgb(251 191 36 / 0.85) !important;
}

.tw-fill-amber-400\/90 {
  fill: rgb(251 191 36 / 0.9) !important;
}

.tw-fill-amber-400\/95 {
  fill: rgb(251 191 36 / 0.95) !important;
}

.tw-fill-amber-50 {
  fill: #fffbeb !important;
}

.tw-fill-amber-50\/0 {
  fill: rgb(255 251 235 / 0) !important;
}

.tw-fill-amber-50\/10 {
  fill: rgb(255 251 235 / 0.1) !important;
}

.tw-fill-amber-50\/100 {
  fill: rgb(255 251 235 / 1) !important;
}

.tw-fill-amber-50\/15 {
  fill: rgb(255 251 235 / 0.15) !important;
}

.tw-fill-amber-50\/20 {
  fill: rgb(255 251 235 / 0.2) !important;
}

.tw-fill-amber-50\/25 {
  fill: rgb(255 251 235 / 0.25) !important;
}

.tw-fill-amber-50\/30 {
  fill: rgb(255 251 235 / 0.3) !important;
}

.tw-fill-amber-50\/35 {
  fill: rgb(255 251 235 / 0.35) !important;
}

.tw-fill-amber-50\/40 {
  fill: rgb(255 251 235 / 0.4) !important;
}

.tw-fill-amber-50\/45 {
  fill: rgb(255 251 235 / 0.45) !important;
}

.tw-fill-amber-50\/5 {
  fill: rgb(255 251 235 / 0.05) !important;
}

.tw-fill-amber-50\/50 {
  fill: rgb(255 251 235 / 0.5) !important;
}

.tw-fill-amber-50\/55 {
  fill: rgb(255 251 235 / 0.55) !important;
}

.tw-fill-amber-50\/60 {
  fill: rgb(255 251 235 / 0.6) !important;
}

.tw-fill-amber-50\/65 {
  fill: rgb(255 251 235 / 0.65) !important;
}

.tw-fill-amber-50\/70 {
  fill: rgb(255 251 235 / 0.7) !important;
}

.tw-fill-amber-50\/75 {
  fill: rgb(255 251 235 / 0.75) !important;
}

.tw-fill-amber-50\/80 {
  fill: rgb(255 251 235 / 0.8) !important;
}

.tw-fill-amber-50\/85 {
  fill: rgb(255 251 235 / 0.85) !important;
}

.tw-fill-amber-50\/90 {
  fill: rgb(255 251 235 / 0.9) !important;
}

.tw-fill-amber-50\/95 {
  fill: rgb(255 251 235 / 0.95) !important;
}

.tw-fill-amber-500 {
  fill: #f59e0b !important;
}

.tw-fill-amber-500\/0 {
  fill: rgb(245 158 11 / 0) !important;
}

.tw-fill-amber-500\/10 {
  fill: rgb(245 158 11 / 0.1) !important;
}

.tw-fill-amber-500\/100 {
  fill: rgb(245 158 11 / 1) !important;
}

.tw-fill-amber-500\/15 {
  fill: rgb(245 158 11 / 0.15) !important;
}

.tw-fill-amber-500\/20 {
  fill: rgb(245 158 11 / 0.2) !important;
}

.tw-fill-amber-500\/25 {
  fill: rgb(245 158 11 / 0.25) !important;
}

.tw-fill-amber-500\/30 {
  fill: rgb(245 158 11 / 0.3) !important;
}

.tw-fill-amber-500\/35 {
  fill: rgb(245 158 11 / 0.35) !important;
}

.tw-fill-amber-500\/40 {
  fill: rgb(245 158 11 / 0.4) !important;
}

.tw-fill-amber-500\/45 {
  fill: rgb(245 158 11 / 0.45) !important;
}

.tw-fill-amber-500\/5 {
  fill: rgb(245 158 11 / 0.05) !important;
}

.tw-fill-amber-500\/50 {
  fill: rgb(245 158 11 / 0.5) !important;
}

.tw-fill-amber-500\/55 {
  fill: rgb(245 158 11 / 0.55) !important;
}

.tw-fill-amber-500\/60 {
  fill: rgb(245 158 11 / 0.6) !important;
}

.tw-fill-amber-500\/65 {
  fill: rgb(245 158 11 / 0.65) !important;
}

.tw-fill-amber-500\/70 {
  fill: rgb(245 158 11 / 0.7) !important;
}

.tw-fill-amber-500\/75 {
  fill: rgb(245 158 11 / 0.75) !important;
}

.tw-fill-amber-500\/80 {
  fill: rgb(245 158 11 / 0.8) !important;
}

.tw-fill-amber-500\/85 {
  fill: rgb(245 158 11 / 0.85) !important;
}

.tw-fill-amber-500\/90 {
  fill: rgb(245 158 11 / 0.9) !important;
}

.tw-fill-amber-500\/95 {
  fill: rgb(245 158 11 / 0.95) !important;
}

.tw-fill-amber-600 {
  fill: #d97706 !important;
}

.tw-fill-amber-600\/0 {
  fill: rgb(217 119 6 / 0) !important;
}

.tw-fill-amber-600\/10 {
  fill: rgb(217 119 6 / 0.1) !important;
}

.tw-fill-amber-600\/100 {
  fill: rgb(217 119 6 / 1) !important;
}

.tw-fill-amber-600\/15 {
  fill: rgb(217 119 6 / 0.15) !important;
}

.tw-fill-amber-600\/20 {
  fill: rgb(217 119 6 / 0.2) !important;
}

.tw-fill-amber-600\/25 {
  fill: rgb(217 119 6 / 0.25) !important;
}

.tw-fill-amber-600\/30 {
  fill: rgb(217 119 6 / 0.3) !important;
}

.tw-fill-amber-600\/35 {
  fill: rgb(217 119 6 / 0.35) !important;
}

.tw-fill-amber-600\/40 {
  fill: rgb(217 119 6 / 0.4) !important;
}

.tw-fill-amber-600\/45 {
  fill: rgb(217 119 6 / 0.45) !important;
}

.tw-fill-amber-600\/5 {
  fill: rgb(217 119 6 / 0.05) !important;
}

.tw-fill-amber-600\/50 {
  fill: rgb(217 119 6 / 0.5) !important;
}

.tw-fill-amber-600\/55 {
  fill: rgb(217 119 6 / 0.55) !important;
}

.tw-fill-amber-600\/60 {
  fill: rgb(217 119 6 / 0.6) !important;
}

.tw-fill-amber-600\/65 {
  fill: rgb(217 119 6 / 0.65) !important;
}

.tw-fill-amber-600\/70 {
  fill: rgb(217 119 6 / 0.7) !important;
}

.tw-fill-amber-600\/75 {
  fill: rgb(217 119 6 / 0.75) !important;
}

.tw-fill-amber-600\/80 {
  fill: rgb(217 119 6 / 0.8) !important;
}

.tw-fill-amber-600\/85 {
  fill: rgb(217 119 6 / 0.85) !important;
}

.tw-fill-amber-600\/90 {
  fill: rgb(217 119 6 / 0.9) !important;
}

.tw-fill-amber-600\/95 {
  fill: rgb(217 119 6 / 0.95) !important;
}

.tw-fill-amber-700 {
  fill: #b45309 !important;
}

.tw-fill-amber-700\/0 {
  fill: rgb(180 83 9 / 0) !important;
}

.tw-fill-amber-700\/10 {
  fill: rgb(180 83 9 / 0.1) !important;
}

.tw-fill-amber-700\/100 {
  fill: rgb(180 83 9 / 1) !important;
}

.tw-fill-amber-700\/15 {
  fill: rgb(180 83 9 / 0.15) !important;
}

.tw-fill-amber-700\/20 {
  fill: rgb(180 83 9 / 0.2) !important;
}

.tw-fill-amber-700\/25 {
  fill: rgb(180 83 9 / 0.25) !important;
}

.tw-fill-amber-700\/30 {
  fill: rgb(180 83 9 / 0.3) !important;
}

.tw-fill-amber-700\/35 {
  fill: rgb(180 83 9 / 0.35) !important;
}

.tw-fill-amber-700\/40 {
  fill: rgb(180 83 9 / 0.4) !important;
}

.tw-fill-amber-700\/45 {
  fill: rgb(180 83 9 / 0.45) !important;
}

.tw-fill-amber-700\/5 {
  fill: rgb(180 83 9 / 0.05) !important;
}

.tw-fill-amber-700\/50 {
  fill: rgb(180 83 9 / 0.5) !important;
}

.tw-fill-amber-700\/55 {
  fill: rgb(180 83 9 / 0.55) !important;
}

.tw-fill-amber-700\/60 {
  fill: rgb(180 83 9 / 0.6) !important;
}

.tw-fill-amber-700\/65 {
  fill: rgb(180 83 9 / 0.65) !important;
}

.tw-fill-amber-700\/70 {
  fill: rgb(180 83 9 / 0.7) !important;
}

.tw-fill-amber-700\/75 {
  fill: rgb(180 83 9 / 0.75) !important;
}

.tw-fill-amber-700\/80 {
  fill: rgb(180 83 9 / 0.8) !important;
}

.tw-fill-amber-700\/85 {
  fill: rgb(180 83 9 / 0.85) !important;
}

.tw-fill-amber-700\/90 {
  fill: rgb(180 83 9 / 0.9) !important;
}

.tw-fill-amber-700\/95 {
  fill: rgb(180 83 9 / 0.95) !important;
}

.tw-fill-amber-800 {
  fill: #92400e !important;
}

.tw-fill-amber-800\/0 {
  fill: rgb(146 64 14 / 0) !important;
}

.tw-fill-amber-800\/10 {
  fill: rgb(146 64 14 / 0.1) !important;
}

.tw-fill-amber-800\/100 {
  fill: rgb(146 64 14 / 1) !important;
}

.tw-fill-amber-800\/15 {
  fill: rgb(146 64 14 / 0.15) !important;
}

.tw-fill-amber-800\/20 {
  fill: rgb(146 64 14 / 0.2) !important;
}

.tw-fill-amber-800\/25 {
  fill: rgb(146 64 14 / 0.25) !important;
}

.tw-fill-amber-800\/30 {
  fill: rgb(146 64 14 / 0.3) !important;
}

.tw-fill-amber-800\/35 {
  fill: rgb(146 64 14 / 0.35) !important;
}

.tw-fill-amber-800\/40 {
  fill: rgb(146 64 14 / 0.4) !important;
}

.tw-fill-amber-800\/45 {
  fill: rgb(146 64 14 / 0.45) !important;
}

.tw-fill-amber-800\/5 {
  fill: rgb(146 64 14 / 0.05) !important;
}

.tw-fill-amber-800\/50 {
  fill: rgb(146 64 14 / 0.5) !important;
}

.tw-fill-amber-800\/55 {
  fill: rgb(146 64 14 / 0.55) !important;
}

.tw-fill-amber-800\/60 {
  fill: rgb(146 64 14 / 0.6) !important;
}

.tw-fill-amber-800\/65 {
  fill: rgb(146 64 14 / 0.65) !important;
}

.tw-fill-amber-800\/70 {
  fill: rgb(146 64 14 / 0.7) !important;
}

.tw-fill-amber-800\/75 {
  fill: rgb(146 64 14 / 0.75) !important;
}

.tw-fill-amber-800\/80 {
  fill: rgb(146 64 14 / 0.8) !important;
}

.tw-fill-amber-800\/85 {
  fill: rgb(146 64 14 / 0.85) !important;
}

.tw-fill-amber-800\/90 {
  fill: rgb(146 64 14 / 0.9) !important;
}

.tw-fill-amber-800\/95 {
  fill: rgb(146 64 14 / 0.95) !important;
}

.tw-fill-amber-900 {
  fill: #78350f !important;
}

.tw-fill-amber-900\/0 {
  fill: rgb(120 53 15 / 0) !important;
}

.tw-fill-amber-900\/10 {
  fill: rgb(120 53 15 / 0.1) !important;
}

.tw-fill-amber-900\/100 {
  fill: rgb(120 53 15 / 1) !important;
}

.tw-fill-amber-900\/15 {
  fill: rgb(120 53 15 / 0.15) !important;
}

.tw-fill-amber-900\/20 {
  fill: rgb(120 53 15 / 0.2) !important;
}

.tw-fill-amber-900\/25 {
  fill: rgb(120 53 15 / 0.25) !important;
}

.tw-fill-amber-900\/30 {
  fill: rgb(120 53 15 / 0.3) !important;
}

.tw-fill-amber-900\/35 {
  fill: rgb(120 53 15 / 0.35) !important;
}

.tw-fill-amber-900\/40 {
  fill: rgb(120 53 15 / 0.4) !important;
}

.tw-fill-amber-900\/45 {
  fill: rgb(120 53 15 / 0.45) !important;
}

.tw-fill-amber-900\/5 {
  fill: rgb(120 53 15 / 0.05) !important;
}

.tw-fill-amber-900\/50 {
  fill: rgb(120 53 15 / 0.5) !important;
}

.tw-fill-amber-900\/55 {
  fill: rgb(120 53 15 / 0.55) !important;
}

.tw-fill-amber-900\/60 {
  fill: rgb(120 53 15 / 0.6) !important;
}

.tw-fill-amber-900\/65 {
  fill: rgb(120 53 15 / 0.65) !important;
}

.tw-fill-amber-900\/70 {
  fill: rgb(120 53 15 / 0.7) !important;
}

.tw-fill-amber-900\/75 {
  fill: rgb(120 53 15 / 0.75) !important;
}

.tw-fill-amber-900\/80 {
  fill: rgb(120 53 15 / 0.8) !important;
}

.tw-fill-amber-900\/85 {
  fill: rgb(120 53 15 / 0.85) !important;
}

.tw-fill-amber-900\/90 {
  fill: rgb(120 53 15 / 0.9) !important;
}

.tw-fill-amber-900\/95 {
  fill: rgb(120 53 15 / 0.95) !important;
}

.tw-fill-amber-950 {
  fill: #451a03 !important;
}

.tw-fill-amber-950\/0 {
  fill: rgb(69 26 3 / 0) !important;
}

.tw-fill-amber-950\/10 {
  fill: rgb(69 26 3 / 0.1) !important;
}

.tw-fill-amber-950\/100 {
  fill: rgb(69 26 3 / 1) !important;
}

.tw-fill-amber-950\/15 {
  fill: rgb(69 26 3 / 0.15) !important;
}

.tw-fill-amber-950\/20 {
  fill: rgb(69 26 3 / 0.2) !important;
}

.tw-fill-amber-950\/25 {
  fill: rgb(69 26 3 / 0.25) !important;
}

.tw-fill-amber-950\/30 {
  fill: rgb(69 26 3 / 0.3) !important;
}

.tw-fill-amber-950\/35 {
  fill: rgb(69 26 3 / 0.35) !important;
}

.tw-fill-amber-950\/40 {
  fill: rgb(69 26 3 / 0.4) !important;
}

.tw-fill-amber-950\/45 {
  fill: rgb(69 26 3 / 0.45) !important;
}

.tw-fill-amber-950\/5 {
  fill: rgb(69 26 3 / 0.05) !important;
}

.tw-fill-amber-950\/50 {
  fill: rgb(69 26 3 / 0.5) !important;
}

.tw-fill-amber-950\/55 {
  fill: rgb(69 26 3 / 0.55) !important;
}

.tw-fill-amber-950\/60 {
  fill: rgb(69 26 3 / 0.6) !important;
}

.tw-fill-amber-950\/65 {
  fill: rgb(69 26 3 / 0.65) !important;
}

.tw-fill-amber-950\/70 {
  fill: rgb(69 26 3 / 0.7) !important;
}

.tw-fill-amber-950\/75 {
  fill: rgb(69 26 3 / 0.75) !important;
}

.tw-fill-amber-950\/80 {
  fill: rgb(69 26 3 / 0.8) !important;
}

.tw-fill-amber-950\/85 {
  fill: rgb(69 26 3 / 0.85) !important;
}

.tw-fill-amber-950\/90 {
  fill: rgb(69 26 3 / 0.9) !important;
}

.tw-fill-amber-950\/95 {
  fill: rgb(69 26 3 / 0.95) !important;
}

.tw-fill-black {
  fill: #000 !important;
}

.tw-fill-black\/0 {
  fill: rgb(0 0 0 / 0) !important;
}

.tw-fill-black\/10 {
  fill: rgb(0 0 0 / 0.1) !important;
}

.tw-fill-black\/100 {
  fill: rgb(0 0 0 / 1) !important;
}

.tw-fill-black\/15 {
  fill: rgb(0 0 0 / 0.15) !important;
}

.tw-fill-black\/20 {
  fill: rgb(0 0 0 / 0.2) !important;
}

.tw-fill-black\/25 {
  fill: rgb(0 0 0 / 0.25) !important;
}

.tw-fill-black\/30 {
  fill: rgb(0 0 0 / 0.3) !important;
}

.tw-fill-black\/35 {
  fill: rgb(0 0 0 / 0.35) !important;
}

.tw-fill-black\/40 {
  fill: rgb(0 0 0 / 0.4) !important;
}

.tw-fill-black\/45 {
  fill: rgb(0 0 0 / 0.45) !important;
}

.tw-fill-black\/5 {
  fill: rgb(0 0 0 / 0.05) !important;
}

.tw-fill-black\/50 {
  fill: rgb(0 0 0 / 0.5) !important;
}

.tw-fill-black\/55 {
  fill: rgb(0 0 0 / 0.55) !important;
}

.tw-fill-black\/60 {
  fill: rgb(0 0 0 / 0.6) !important;
}

.tw-fill-black\/65 {
  fill: rgb(0 0 0 / 0.65) !important;
}

.tw-fill-black\/70 {
  fill: rgb(0 0 0 / 0.7) !important;
}

.tw-fill-black\/75 {
  fill: rgb(0 0 0 / 0.75) !important;
}

.tw-fill-black\/80 {
  fill: rgb(0 0 0 / 0.8) !important;
}

.tw-fill-black\/85 {
  fill: rgb(0 0 0 / 0.85) !important;
}

.tw-fill-black\/90 {
  fill: rgb(0 0 0 / 0.9) !important;
}

.tw-fill-black\/95 {
  fill: rgb(0 0 0 / 0.95) !important;
}

.tw-fill-blue-100 {
  fill: #dbeafe !important;
}

.tw-fill-blue-100\/0 {
  fill: rgb(219 234 254 / 0) !important;
}

.tw-fill-blue-100\/10 {
  fill: rgb(219 234 254 / 0.1) !important;
}

.tw-fill-blue-100\/100 {
  fill: rgb(219 234 254 / 1) !important;
}

.tw-fill-blue-100\/15 {
  fill: rgb(219 234 254 / 0.15) !important;
}

.tw-fill-blue-100\/20 {
  fill: rgb(219 234 254 / 0.2) !important;
}

.tw-fill-blue-100\/25 {
  fill: rgb(219 234 254 / 0.25) !important;
}

.tw-fill-blue-100\/30 {
  fill: rgb(219 234 254 / 0.3) !important;
}

.tw-fill-blue-100\/35 {
  fill: rgb(219 234 254 / 0.35) !important;
}

.tw-fill-blue-100\/40 {
  fill: rgb(219 234 254 / 0.4) !important;
}

.tw-fill-blue-100\/45 {
  fill: rgb(219 234 254 / 0.45) !important;
}

.tw-fill-blue-100\/5 {
  fill: rgb(219 234 254 / 0.05) !important;
}

.tw-fill-blue-100\/50 {
  fill: rgb(219 234 254 / 0.5) !important;
}

.tw-fill-blue-100\/55 {
  fill: rgb(219 234 254 / 0.55) !important;
}

.tw-fill-blue-100\/60 {
  fill: rgb(219 234 254 / 0.6) !important;
}

.tw-fill-blue-100\/65 {
  fill: rgb(219 234 254 / 0.65) !important;
}

.tw-fill-blue-100\/70 {
  fill: rgb(219 234 254 / 0.7) !important;
}

.tw-fill-blue-100\/75 {
  fill: rgb(219 234 254 / 0.75) !important;
}

.tw-fill-blue-100\/80 {
  fill: rgb(219 234 254 / 0.8) !important;
}

.tw-fill-blue-100\/85 {
  fill: rgb(219 234 254 / 0.85) !important;
}

.tw-fill-blue-100\/90 {
  fill: rgb(219 234 254 / 0.9) !important;
}

.tw-fill-blue-100\/95 {
  fill: rgb(219 234 254 / 0.95) !important;
}

.tw-fill-blue-200 {
  fill: #bfdbfe !important;
}

.tw-fill-blue-200\/0 {
  fill: rgb(191 219 254 / 0) !important;
}

.tw-fill-blue-200\/10 {
  fill: rgb(191 219 254 / 0.1) !important;
}

.tw-fill-blue-200\/100 {
  fill: rgb(191 219 254 / 1) !important;
}

.tw-fill-blue-200\/15 {
  fill: rgb(191 219 254 / 0.15) !important;
}

.tw-fill-blue-200\/20 {
  fill: rgb(191 219 254 / 0.2) !important;
}

.tw-fill-blue-200\/25 {
  fill: rgb(191 219 254 / 0.25) !important;
}

.tw-fill-blue-200\/30 {
  fill: rgb(191 219 254 / 0.3) !important;
}

.tw-fill-blue-200\/35 {
  fill: rgb(191 219 254 / 0.35) !important;
}

.tw-fill-blue-200\/40 {
  fill: rgb(191 219 254 / 0.4) !important;
}

.tw-fill-blue-200\/45 {
  fill: rgb(191 219 254 / 0.45) !important;
}

.tw-fill-blue-200\/5 {
  fill: rgb(191 219 254 / 0.05) !important;
}

.tw-fill-blue-200\/50 {
  fill: rgb(191 219 254 / 0.5) !important;
}

.tw-fill-blue-200\/55 {
  fill: rgb(191 219 254 / 0.55) !important;
}

.tw-fill-blue-200\/60 {
  fill: rgb(191 219 254 / 0.6) !important;
}

.tw-fill-blue-200\/65 {
  fill: rgb(191 219 254 / 0.65) !important;
}

.tw-fill-blue-200\/70 {
  fill: rgb(191 219 254 / 0.7) !important;
}

.tw-fill-blue-200\/75 {
  fill: rgb(191 219 254 / 0.75) !important;
}

.tw-fill-blue-200\/80 {
  fill: rgb(191 219 254 / 0.8) !important;
}

.tw-fill-blue-200\/85 {
  fill: rgb(191 219 254 / 0.85) !important;
}

.tw-fill-blue-200\/90 {
  fill: rgb(191 219 254 / 0.9) !important;
}

.tw-fill-blue-200\/95 {
  fill: rgb(191 219 254 / 0.95) !important;
}

.tw-fill-blue-300 {
  fill: #93c5fd !important;
}

.tw-fill-blue-300\/0 {
  fill: rgb(147 197 253 / 0) !important;
}

.tw-fill-blue-300\/10 {
  fill: rgb(147 197 253 / 0.1) !important;
}

.tw-fill-blue-300\/100 {
  fill: rgb(147 197 253 / 1) !important;
}

.tw-fill-blue-300\/15 {
  fill: rgb(147 197 253 / 0.15) !important;
}

.tw-fill-blue-300\/20 {
  fill: rgb(147 197 253 / 0.2) !important;
}

.tw-fill-blue-300\/25 {
  fill: rgb(147 197 253 / 0.25) !important;
}

.tw-fill-blue-300\/30 {
  fill: rgb(147 197 253 / 0.3) !important;
}

.tw-fill-blue-300\/35 {
  fill: rgb(147 197 253 / 0.35) !important;
}

.tw-fill-blue-300\/40 {
  fill: rgb(147 197 253 / 0.4) !important;
}

.tw-fill-blue-300\/45 {
  fill: rgb(147 197 253 / 0.45) !important;
}

.tw-fill-blue-300\/5 {
  fill: rgb(147 197 253 / 0.05) !important;
}

.tw-fill-blue-300\/50 {
  fill: rgb(147 197 253 / 0.5) !important;
}

.tw-fill-blue-300\/55 {
  fill: rgb(147 197 253 / 0.55) !important;
}

.tw-fill-blue-300\/60 {
  fill: rgb(147 197 253 / 0.6) !important;
}

.tw-fill-blue-300\/65 {
  fill: rgb(147 197 253 / 0.65) !important;
}

.tw-fill-blue-300\/70 {
  fill: rgb(147 197 253 / 0.7) !important;
}

.tw-fill-blue-300\/75 {
  fill: rgb(147 197 253 / 0.75) !important;
}

.tw-fill-blue-300\/80 {
  fill: rgb(147 197 253 / 0.8) !important;
}

.tw-fill-blue-300\/85 {
  fill: rgb(147 197 253 / 0.85) !important;
}

.tw-fill-blue-300\/90 {
  fill: rgb(147 197 253 / 0.9) !important;
}

.tw-fill-blue-300\/95 {
  fill: rgb(147 197 253 / 0.95) !important;
}

.tw-fill-blue-400 {
  fill: #60a5fa !important;
}

.tw-fill-blue-400\/0 {
  fill: rgb(96 165 250 / 0) !important;
}

.tw-fill-blue-400\/10 {
  fill: rgb(96 165 250 / 0.1) !important;
}

.tw-fill-blue-400\/100 {
  fill: rgb(96 165 250 / 1) !important;
}

.tw-fill-blue-400\/15 {
  fill: rgb(96 165 250 / 0.15) !important;
}

.tw-fill-blue-400\/20 {
  fill: rgb(96 165 250 / 0.2) !important;
}

.tw-fill-blue-400\/25 {
  fill: rgb(96 165 250 / 0.25) !important;
}

.tw-fill-blue-400\/30 {
  fill: rgb(96 165 250 / 0.3) !important;
}

.tw-fill-blue-400\/35 {
  fill: rgb(96 165 250 / 0.35) !important;
}

.tw-fill-blue-400\/40 {
  fill: rgb(96 165 250 / 0.4) !important;
}

.tw-fill-blue-400\/45 {
  fill: rgb(96 165 250 / 0.45) !important;
}

.tw-fill-blue-400\/5 {
  fill: rgb(96 165 250 / 0.05) !important;
}

.tw-fill-blue-400\/50 {
  fill: rgb(96 165 250 / 0.5) !important;
}

.tw-fill-blue-400\/55 {
  fill: rgb(96 165 250 / 0.55) !important;
}

.tw-fill-blue-400\/60 {
  fill: rgb(96 165 250 / 0.6) !important;
}

.tw-fill-blue-400\/65 {
  fill: rgb(96 165 250 / 0.65) !important;
}

.tw-fill-blue-400\/70 {
  fill: rgb(96 165 250 / 0.7) !important;
}

.tw-fill-blue-400\/75 {
  fill: rgb(96 165 250 / 0.75) !important;
}

.tw-fill-blue-400\/80 {
  fill: rgb(96 165 250 / 0.8) !important;
}

.tw-fill-blue-400\/85 {
  fill: rgb(96 165 250 / 0.85) !important;
}

.tw-fill-blue-400\/90 {
  fill: rgb(96 165 250 / 0.9) !important;
}

.tw-fill-blue-400\/95 {
  fill: rgb(96 165 250 / 0.95) !important;
}

.tw-fill-blue-50 {
  fill: #eff6ff !important;
}

.tw-fill-blue-50\/0 {
  fill: rgb(239 246 255 / 0) !important;
}

.tw-fill-blue-50\/10 {
  fill: rgb(239 246 255 / 0.1) !important;
}

.tw-fill-blue-50\/100 {
  fill: rgb(239 246 255 / 1) !important;
}

.tw-fill-blue-50\/15 {
  fill: rgb(239 246 255 / 0.15) !important;
}

.tw-fill-blue-50\/20 {
  fill: rgb(239 246 255 / 0.2) !important;
}

.tw-fill-blue-50\/25 {
  fill: rgb(239 246 255 / 0.25) !important;
}

.tw-fill-blue-50\/30 {
  fill: rgb(239 246 255 / 0.3) !important;
}

.tw-fill-blue-50\/35 {
  fill: rgb(239 246 255 / 0.35) !important;
}

.tw-fill-blue-50\/40 {
  fill: rgb(239 246 255 / 0.4) !important;
}

.tw-fill-blue-50\/45 {
  fill: rgb(239 246 255 / 0.45) !important;
}

.tw-fill-blue-50\/5 {
  fill: rgb(239 246 255 / 0.05) !important;
}

.tw-fill-blue-50\/50 {
  fill: rgb(239 246 255 / 0.5) !important;
}

.tw-fill-blue-50\/55 {
  fill: rgb(239 246 255 / 0.55) !important;
}

.tw-fill-blue-50\/60 {
  fill: rgb(239 246 255 / 0.6) !important;
}

.tw-fill-blue-50\/65 {
  fill: rgb(239 246 255 / 0.65) !important;
}

.tw-fill-blue-50\/70 {
  fill: rgb(239 246 255 / 0.7) !important;
}

.tw-fill-blue-50\/75 {
  fill: rgb(239 246 255 / 0.75) !important;
}

.tw-fill-blue-50\/80 {
  fill: rgb(239 246 255 / 0.8) !important;
}

.tw-fill-blue-50\/85 {
  fill: rgb(239 246 255 / 0.85) !important;
}

.tw-fill-blue-50\/90 {
  fill: rgb(239 246 255 / 0.9) !important;
}

.tw-fill-blue-50\/95 {
  fill: rgb(239 246 255 / 0.95) !important;
}

.tw-fill-blue-500 {
  fill: #3b82f6 !important;
}

.tw-fill-blue-500\/0 {
  fill: rgb(59 130 246 / 0) !important;
}

.tw-fill-blue-500\/10 {
  fill: rgb(59 130 246 / 0.1) !important;
}

.tw-fill-blue-500\/100 {
  fill: rgb(59 130 246 / 1) !important;
}

.tw-fill-blue-500\/15 {
  fill: rgb(59 130 246 / 0.15) !important;
}

.tw-fill-blue-500\/20 {
  fill: rgb(59 130 246 / 0.2) !important;
}

.tw-fill-blue-500\/25 {
  fill: rgb(59 130 246 / 0.25) !important;
}

.tw-fill-blue-500\/30 {
  fill: rgb(59 130 246 / 0.3) !important;
}

.tw-fill-blue-500\/35 {
  fill: rgb(59 130 246 / 0.35) !important;
}

.tw-fill-blue-500\/40 {
  fill: rgb(59 130 246 / 0.4) !important;
}

.tw-fill-blue-500\/45 {
  fill: rgb(59 130 246 / 0.45) !important;
}

.tw-fill-blue-500\/5 {
  fill: rgb(59 130 246 / 0.05) !important;
}

.tw-fill-blue-500\/50 {
  fill: rgb(59 130 246 / 0.5) !important;
}

.tw-fill-blue-500\/55 {
  fill: rgb(59 130 246 / 0.55) !important;
}

.tw-fill-blue-500\/60 {
  fill: rgb(59 130 246 / 0.6) !important;
}

.tw-fill-blue-500\/65 {
  fill: rgb(59 130 246 / 0.65) !important;
}

.tw-fill-blue-500\/70 {
  fill: rgb(59 130 246 / 0.7) !important;
}

.tw-fill-blue-500\/75 {
  fill: rgb(59 130 246 / 0.75) !important;
}

.tw-fill-blue-500\/80 {
  fill: rgb(59 130 246 / 0.8) !important;
}

.tw-fill-blue-500\/85 {
  fill: rgb(59 130 246 / 0.85) !important;
}

.tw-fill-blue-500\/90 {
  fill: rgb(59 130 246 / 0.9) !important;
}

.tw-fill-blue-500\/95 {
  fill: rgb(59 130 246 / 0.95) !important;
}

.tw-fill-blue-600 {
  fill: #2563eb !important;
}

.tw-fill-blue-600\/0 {
  fill: rgb(37 99 235 / 0) !important;
}

.tw-fill-blue-600\/10 {
  fill: rgb(37 99 235 / 0.1) !important;
}

.tw-fill-blue-600\/100 {
  fill: rgb(37 99 235 / 1) !important;
}

.tw-fill-blue-600\/15 {
  fill: rgb(37 99 235 / 0.15) !important;
}

.tw-fill-blue-600\/20 {
  fill: rgb(37 99 235 / 0.2) !important;
}

.tw-fill-blue-600\/25 {
  fill: rgb(37 99 235 / 0.25) !important;
}

.tw-fill-blue-600\/30 {
  fill: rgb(37 99 235 / 0.3) !important;
}

.tw-fill-blue-600\/35 {
  fill: rgb(37 99 235 / 0.35) !important;
}

.tw-fill-blue-600\/40 {
  fill: rgb(37 99 235 / 0.4) !important;
}

.tw-fill-blue-600\/45 {
  fill: rgb(37 99 235 / 0.45) !important;
}

.tw-fill-blue-600\/5 {
  fill: rgb(37 99 235 / 0.05) !important;
}

.tw-fill-blue-600\/50 {
  fill: rgb(37 99 235 / 0.5) !important;
}

.tw-fill-blue-600\/55 {
  fill: rgb(37 99 235 / 0.55) !important;
}

.tw-fill-blue-600\/60 {
  fill: rgb(37 99 235 / 0.6) !important;
}

.tw-fill-blue-600\/65 {
  fill: rgb(37 99 235 / 0.65) !important;
}

.tw-fill-blue-600\/70 {
  fill: rgb(37 99 235 / 0.7) !important;
}

.tw-fill-blue-600\/75 {
  fill: rgb(37 99 235 / 0.75) !important;
}

.tw-fill-blue-600\/80 {
  fill: rgb(37 99 235 / 0.8) !important;
}

.tw-fill-blue-600\/85 {
  fill: rgb(37 99 235 / 0.85) !important;
}

.tw-fill-blue-600\/90 {
  fill: rgb(37 99 235 / 0.9) !important;
}

.tw-fill-blue-600\/95 {
  fill: rgb(37 99 235 / 0.95) !important;
}

.tw-fill-blue-700 {
  fill: #1d4ed8 !important;
}

.tw-fill-blue-700\/0 {
  fill: rgb(29 78 216 / 0) !important;
}

.tw-fill-blue-700\/10 {
  fill: rgb(29 78 216 / 0.1) !important;
}

.tw-fill-blue-700\/100 {
  fill: rgb(29 78 216 / 1) !important;
}

.tw-fill-blue-700\/15 {
  fill: rgb(29 78 216 / 0.15) !important;
}

.tw-fill-blue-700\/20 {
  fill: rgb(29 78 216 / 0.2) !important;
}

.tw-fill-blue-700\/25 {
  fill: rgb(29 78 216 / 0.25) !important;
}

.tw-fill-blue-700\/30 {
  fill: rgb(29 78 216 / 0.3) !important;
}

.tw-fill-blue-700\/35 {
  fill: rgb(29 78 216 / 0.35) !important;
}

.tw-fill-blue-700\/40 {
  fill: rgb(29 78 216 / 0.4) !important;
}

.tw-fill-blue-700\/45 {
  fill: rgb(29 78 216 / 0.45) !important;
}

.tw-fill-blue-700\/5 {
  fill: rgb(29 78 216 / 0.05) !important;
}

.tw-fill-blue-700\/50 {
  fill: rgb(29 78 216 / 0.5) !important;
}

.tw-fill-blue-700\/55 {
  fill: rgb(29 78 216 / 0.55) !important;
}

.tw-fill-blue-700\/60 {
  fill: rgb(29 78 216 / 0.6) !important;
}

.tw-fill-blue-700\/65 {
  fill: rgb(29 78 216 / 0.65) !important;
}

.tw-fill-blue-700\/70 {
  fill: rgb(29 78 216 / 0.7) !important;
}

.tw-fill-blue-700\/75 {
  fill: rgb(29 78 216 / 0.75) !important;
}

.tw-fill-blue-700\/80 {
  fill: rgb(29 78 216 / 0.8) !important;
}

.tw-fill-blue-700\/85 {
  fill: rgb(29 78 216 / 0.85) !important;
}

.tw-fill-blue-700\/90 {
  fill: rgb(29 78 216 / 0.9) !important;
}

.tw-fill-blue-700\/95 {
  fill: rgb(29 78 216 / 0.95) !important;
}

.tw-fill-blue-800 {
  fill: #1e40af !important;
}

.tw-fill-blue-800\/0 {
  fill: rgb(30 64 175 / 0) !important;
}

.tw-fill-blue-800\/10 {
  fill: rgb(30 64 175 / 0.1) !important;
}

.tw-fill-blue-800\/100 {
  fill: rgb(30 64 175 / 1) !important;
}

.tw-fill-blue-800\/15 {
  fill: rgb(30 64 175 / 0.15) !important;
}

.tw-fill-blue-800\/20 {
  fill: rgb(30 64 175 / 0.2) !important;
}

.tw-fill-blue-800\/25 {
  fill: rgb(30 64 175 / 0.25) !important;
}

.tw-fill-blue-800\/30 {
  fill: rgb(30 64 175 / 0.3) !important;
}

.tw-fill-blue-800\/35 {
  fill: rgb(30 64 175 / 0.35) !important;
}

.tw-fill-blue-800\/40 {
  fill: rgb(30 64 175 / 0.4) !important;
}

.tw-fill-blue-800\/45 {
  fill: rgb(30 64 175 / 0.45) !important;
}

.tw-fill-blue-800\/5 {
  fill: rgb(30 64 175 / 0.05) !important;
}

.tw-fill-blue-800\/50 {
  fill: rgb(30 64 175 / 0.5) !important;
}

.tw-fill-blue-800\/55 {
  fill: rgb(30 64 175 / 0.55) !important;
}

.tw-fill-blue-800\/60 {
  fill: rgb(30 64 175 / 0.6) !important;
}

.tw-fill-blue-800\/65 {
  fill: rgb(30 64 175 / 0.65) !important;
}

.tw-fill-blue-800\/70 {
  fill: rgb(30 64 175 / 0.7) !important;
}

.tw-fill-blue-800\/75 {
  fill: rgb(30 64 175 / 0.75) !important;
}

.tw-fill-blue-800\/80 {
  fill: rgb(30 64 175 / 0.8) !important;
}

.tw-fill-blue-800\/85 {
  fill: rgb(30 64 175 / 0.85) !important;
}

.tw-fill-blue-800\/90 {
  fill: rgb(30 64 175 / 0.9) !important;
}

.tw-fill-blue-800\/95 {
  fill: rgb(30 64 175 / 0.95) !important;
}

.tw-fill-blue-900 {
  fill: #1e3a8a !important;
}

.tw-fill-blue-900\/0 {
  fill: rgb(30 58 138 / 0) !important;
}

.tw-fill-blue-900\/10 {
  fill: rgb(30 58 138 / 0.1) !important;
}

.tw-fill-blue-900\/100 {
  fill: rgb(30 58 138 / 1) !important;
}

.tw-fill-blue-900\/15 {
  fill: rgb(30 58 138 / 0.15) !important;
}

.tw-fill-blue-900\/20 {
  fill: rgb(30 58 138 / 0.2) !important;
}

.tw-fill-blue-900\/25 {
  fill: rgb(30 58 138 / 0.25) !important;
}

.tw-fill-blue-900\/30 {
  fill: rgb(30 58 138 / 0.3) !important;
}

.tw-fill-blue-900\/35 {
  fill: rgb(30 58 138 / 0.35) !important;
}

.tw-fill-blue-900\/40 {
  fill: rgb(30 58 138 / 0.4) !important;
}

.tw-fill-blue-900\/45 {
  fill: rgb(30 58 138 / 0.45) !important;
}

.tw-fill-blue-900\/5 {
  fill: rgb(30 58 138 / 0.05) !important;
}

.tw-fill-blue-900\/50 {
  fill: rgb(30 58 138 / 0.5) !important;
}

.tw-fill-blue-900\/55 {
  fill: rgb(30 58 138 / 0.55) !important;
}

.tw-fill-blue-900\/60 {
  fill: rgb(30 58 138 / 0.6) !important;
}

.tw-fill-blue-900\/65 {
  fill: rgb(30 58 138 / 0.65) !important;
}

.tw-fill-blue-900\/70 {
  fill: rgb(30 58 138 / 0.7) !important;
}

.tw-fill-blue-900\/75 {
  fill: rgb(30 58 138 / 0.75) !important;
}

.tw-fill-blue-900\/80 {
  fill: rgb(30 58 138 / 0.8) !important;
}

.tw-fill-blue-900\/85 {
  fill: rgb(30 58 138 / 0.85) !important;
}

.tw-fill-blue-900\/90 {
  fill: rgb(30 58 138 / 0.9) !important;
}

.tw-fill-blue-900\/95 {
  fill: rgb(30 58 138 / 0.95) !important;
}

.tw-fill-blue-950 {
  fill: #172554 !important;
}

.tw-fill-blue-950\/0 {
  fill: rgb(23 37 84 / 0) !important;
}

.tw-fill-blue-950\/10 {
  fill: rgb(23 37 84 / 0.1) !important;
}

.tw-fill-blue-950\/100 {
  fill: rgb(23 37 84 / 1) !important;
}

.tw-fill-blue-950\/15 {
  fill: rgb(23 37 84 / 0.15) !important;
}

.tw-fill-blue-950\/20 {
  fill: rgb(23 37 84 / 0.2) !important;
}

.tw-fill-blue-950\/25 {
  fill: rgb(23 37 84 / 0.25) !important;
}

.tw-fill-blue-950\/30 {
  fill: rgb(23 37 84 / 0.3) !important;
}

.tw-fill-blue-950\/35 {
  fill: rgb(23 37 84 / 0.35) !important;
}

.tw-fill-blue-950\/40 {
  fill: rgb(23 37 84 / 0.4) !important;
}

.tw-fill-blue-950\/45 {
  fill: rgb(23 37 84 / 0.45) !important;
}

.tw-fill-blue-950\/5 {
  fill: rgb(23 37 84 / 0.05) !important;
}

.tw-fill-blue-950\/50 {
  fill: rgb(23 37 84 / 0.5) !important;
}

.tw-fill-blue-950\/55 {
  fill: rgb(23 37 84 / 0.55) !important;
}

.tw-fill-blue-950\/60 {
  fill: rgb(23 37 84 / 0.6) !important;
}

.tw-fill-blue-950\/65 {
  fill: rgb(23 37 84 / 0.65) !important;
}

.tw-fill-blue-950\/70 {
  fill: rgb(23 37 84 / 0.7) !important;
}

.tw-fill-blue-950\/75 {
  fill: rgb(23 37 84 / 0.75) !important;
}

.tw-fill-blue-950\/80 {
  fill: rgb(23 37 84 / 0.8) !important;
}

.tw-fill-blue-950\/85 {
  fill: rgb(23 37 84 / 0.85) !important;
}

.tw-fill-blue-950\/90 {
  fill: rgb(23 37 84 / 0.9) !important;
}

.tw-fill-blue-950\/95 {
  fill: rgb(23 37 84 / 0.95) !important;
}

.tw-fill-current {
  fill: currentColor !important;
}

.tw-fill-cyan-100 {
  fill: #cffafe !important;
}

.tw-fill-cyan-100\/0 {
  fill: rgb(207 250 254 / 0) !important;
}

.tw-fill-cyan-100\/10 {
  fill: rgb(207 250 254 / 0.1) !important;
}

.tw-fill-cyan-100\/100 {
  fill: rgb(207 250 254 / 1) !important;
}

.tw-fill-cyan-100\/15 {
  fill: rgb(207 250 254 / 0.15) !important;
}

.tw-fill-cyan-100\/20 {
  fill: rgb(207 250 254 / 0.2) !important;
}

.tw-fill-cyan-100\/25 {
  fill: rgb(207 250 254 / 0.25) !important;
}

.tw-fill-cyan-100\/30 {
  fill: rgb(207 250 254 / 0.3) !important;
}

.tw-fill-cyan-100\/35 {
  fill: rgb(207 250 254 / 0.35) !important;
}

.tw-fill-cyan-100\/40 {
  fill: rgb(207 250 254 / 0.4) !important;
}

.tw-fill-cyan-100\/45 {
  fill: rgb(207 250 254 / 0.45) !important;
}

.tw-fill-cyan-100\/5 {
  fill: rgb(207 250 254 / 0.05) !important;
}

.tw-fill-cyan-100\/50 {
  fill: rgb(207 250 254 / 0.5) !important;
}

.tw-fill-cyan-100\/55 {
  fill: rgb(207 250 254 / 0.55) !important;
}

.tw-fill-cyan-100\/60 {
  fill: rgb(207 250 254 / 0.6) !important;
}

.tw-fill-cyan-100\/65 {
  fill: rgb(207 250 254 / 0.65) !important;
}

.tw-fill-cyan-100\/70 {
  fill: rgb(207 250 254 / 0.7) !important;
}

.tw-fill-cyan-100\/75 {
  fill: rgb(207 250 254 / 0.75) !important;
}

.tw-fill-cyan-100\/80 {
  fill: rgb(207 250 254 / 0.8) !important;
}

.tw-fill-cyan-100\/85 {
  fill: rgb(207 250 254 / 0.85) !important;
}

.tw-fill-cyan-100\/90 {
  fill: rgb(207 250 254 / 0.9) !important;
}

.tw-fill-cyan-100\/95 {
  fill: rgb(207 250 254 / 0.95) !important;
}

.tw-fill-cyan-200 {
  fill: #a5f3fc !important;
}

.tw-fill-cyan-200\/0 {
  fill: rgb(165 243 252 / 0) !important;
}

.tw-fill-cyan-200\/10 {
  fill: rgb(165 243 252 / 0.1) !important;
}

.tw-fill-cyan-200\/100 {
  fill: rgb(165 243 252 / 1) !important;
}

.tw-fill-cyan-200\/15 {
  fill: rgb(165 243 252 / 0.15) !important;
}

.tw-fill-cyan-200\/20 {
  fill: rgb(165 243 252 / 0.2) !important;
}

.tw-fill-cyan-200\/25 {
  fill: rgb(165 243 252 / 0.25) !important;
}

.tw-fill-cyan-200\/30 {
  fill: rgb(165 243 252 / 0.3) !important;
}

.tw-fill-cyan-200\/35 {
  fill: rgb(165 243 252 / 0.35) !important;
}

.tw-fill-cyan-200\/40 {
  fill: rgb(165 243 252 / 0.4) !important;
}

.tw-fill-cyan-200\/45 {
  fill: rgb(165 243 252 / 0.45) !important;
}

.tw-fill-cyan-200\/5 {
  fill: rgb(165 243 252 / 0.05) !important;
}

.tw-fill-cyan-200\/50 {
  fill: rgb(165 243 252 / 0.5) !important;
}

.tw-fill-cyan-200\/55 {
  fill: rgb(165 243 252 / 0.55) !important;
}

.tw-fill-cyan-200\/60 {
  fill: rgb(165 243 252 / 0.6) !important;
}

.tw-fill-cyan-200\/65 {
  fill: rgb(165 243 252 / 0.65) !important;
}

.tw-fill-cyan-200\/70 {
  fill: rgb(165 243 252 / 0.7) !important;
}

.tw-fill-cyan-200\/75 {
  fill: rgb(165 243 252 / 0.75) !important;
}

.tw-fill-cyan-200\/80 {
  fill: rgb(165 243 252 / 0.8) !important;
}

.tw-fill-cyan-200\/85 {
  fill: rgb(165 243 252 / 0.85) !important;
}

.tw-fill-cyan-200\/90 {
  fill: rgb(165 243 252 / 0.9) !important;
}

.tw-fill-cyan-200\/95 {
  fill: rgb(165 243 252 / 0.95) !important;
}

.tw-fill-cyan-300 {
  fill: #67e8f9 !important;
}

.tw-fill-cyan-300\/0 {
  fill: rgb(103 232 249 / 0) !important;
}

.tw-fill-cyan-300\/10 {
  fill: rgb(103 232 249 / 0.1) !important;
}

.tw-fill-cyan-300\/100 {
  fill: rgb(103 232 249 / 1) !important;
}

.tw-fill-cyan-300\/15 {
  fill: rgb(103 232 249 / 0.15) !important;
}

.tw-fill-cyan-300\/20 {
  fill: rgb(103 232 249 / 0.2) !important;
}

.tw-fill-cyan-300\/25 {
  fill: rgb(103 232 249 / 0.25) !important;
}

.tw-fill-cyan-300\/30 {
  fill: rgb(103 232 249 / 0.3) !important;
}

.tw-fill-cyan-300\/35 {
  fill: rgb(103 232 249 / 0.35) !important;
}

.tw-fill-cyan-300\/40 {
  fill: rgb(103 232 249 / 0.4) !important;
}

.tw-fill-cyan-300\/45 {
  fill: rgb(103 232 249 / 0.45) !important;
}

.tw-fill-cyan-300\/5 {
  fill: rgb(103 232 249 / 0.05) !important;
}

.tw-fill-cyan-300\/50 {
  fill: rgb(103 232 249 / 0.5) !important;
}

.tw-fill-cyan-300\/55 {
  fill: rgb(103 232 249 / 0.55) !important;
}

.tw-fill-cyan-300\/60 {
  fill: rgb(103 232 249 / 0.6) !important;
}

.tw-fill-cyan-300\/65 {
  fill: rgb(103 232 249 / 0.65) !important;
}

.tw-fill-cyan-300\/70 {
  fill: rgb(103 232 249 / 0.7) !important;
}

.tw-fill-cyan-300\/75 {
  fill: rgb(103 232 249 / 0.75) !important;
}

.tw-fill-cyan-300\/80 {
  fill: rgb(103 232 249 / 0.8) !important;
}

.tw-fill-cyan-300\/85 {
  fill: rgb(103 232 249 / 0.85) !important;
}

.tw-fill-cyan-300\/90 {
  fill: rgb(103 232 249 / 0.9) !important;
}

.tw-fill-cyan-300\/95 {
  fill: rgb(103 232 249 / 0.95) !important;
}

.tw-fill-cyan-400 {
  fill: #22d3ee !important;
}

.tw-fill-cyan-400\/0 {
  fill: rgb(34 211 238 / 0) !important;
}

.tw-fill-cyan-400\/10 {
  fill: rgb(34 211 238 / 0.1) !important;
}

.tw-fill-cyan-400\/100 {
  fill: rgb(34 211 238 / 1) !important;
}

.tw-fill-cyan-400\/15 {
  fill: rgb(34 211 238 / 0.15) !important;
}

.tw-fill-cyan-400\/20 {
  fill: rgb(34 211 238 / 0.2) !important;
}

.tw-fill-cyan-400\/25 {
  fill: rgb(34 211 238 / 0.25) !important;
}

.tw-fill-cyan-400\/30 {
  fill: rgb(34 211 238 / 0.3) !important;
}

.tw-fill-cyan-400\/35 {
  fill: rgb(34 211 238 / 0.35) !important;
}

.tw-fill-cyan-400\/40 {
  fill: rgb(34 211 238 / 0.4) !important;
}

.tw-fill-cyan-400\/45 {
  fill: rgb(34 211 238 / 0.45) !important;
}

.tw-fill-cyan-400\/5 {
  fill: rgb(34 211 238 / 0.05) !important;
}

.tw-fill-cyan-400\/50 {
  fill: rgb(34 211 238 / 0.5) !important;
}

.tw-fill-cyan-400\/55 {
  fill: rgb(34 211 238 / 0.55) !important;
}

.tw-fill-cyan-400\/60 {
  fill: rgb(34 211 238 / 0.6) !important;
}

.tw-fill-cyan-400\/65 {
  fill: rgb(34 211 238 / 0.65) !important;
}

.tw-fill-cyan-400\/70 {
  fill: rgb(34 211 238 / 0.7) !important;
}

.tw-fill-cyan-400\/75 {
  fill: rgb(34 211 238 / 0.75) !important;
}

.tw-fill-cyan-400\/80 {
  fill: rgb(34 211 238 / 0.8) !important;
}

.tw-fill-cyan-400\/85 {
  fill: rgb(34 211 238 / 0.85) !important;
}

.tw-fill-cyan-400\/90 {
  fill: rgb(34 211 238 / 0.9) !important;
}

.tw-fill-cyan-400\/95 {
  fill: rgb(34 211 238 / 0.95) !important;
}

.tw-fill-cyan-50 {
  fill: #ecfeff !important;
}

.tw-fill-cyan-50\/0 {
  fill: rgb(236 254 255 / 0) !important;
}

.tw-fill-cyan-50\/10 {
  fill: rgb(236 254 255 / 0.1) !important;
}

.tw-fill-cyan-50\/100 {
  fill: rgb(236 254 255 / 1) !important;
}

.tw-fill-cyan-50\/15 {
  fill: rgb(236 254 255 / 0.15) !important;
}

.tw-fill-cyan-50\/20 {
  fill: rgb(236 254 255 / 0.2) !important;
}

.tw-fill-cyan-50\/25 {
  fill: rgb(236 254 255 / 0.25) !important;
}

.tw-fill-cyan-50\/30 {
  fill: rgb(236 254 255 / 0.3) !important;
}

.tw-fill-cyan-50\/35 {
  fill: rgb(236 254 255 / 0.35) !important;
}

.tw-fill-cyan-50\/40 {
  fill: rgb(236 254 255 / 0.4) !important;
}

.tw-fill-cyan-50\/45 {
  fill: rgb(236 254 255 / 0.45) !important;
}

.tw-fill-cyan-50\/5 {
  fill: rgb(236 254 255 / 0.05) !important;
}

.tw-fill-cyan-50\/50 {
  fill: rgb(236 254 255 / 0.5) !important;
}

.tw-fill-cyan-50\/55 {
  fill: rgb(236 254 255 / 0.55) !important;
}

.tw-fill-cyan-50\/60 {
  fill: rgb(236 254 255 / 0.6) !important;
}

.tw-fill-cyan-50\/65 {
  fill: rgb(236 254 255 / 0.65) !important;
}

.tw-fill-cyan-50\/70 {
  fill: rgb(236 254 255 / 0.7) !important;
}

.tw-fill-cyan-50\/75 {
  fill: rgb(236 254 255 / 0.75) !important;
}

.tw-fill-cyan-50\/80 {
  fill: rgb(236 254 255 / 0.8) !important;
}

.tw-fill-cyan-50\/85 {
  fill: rgb(236 254 255 / 0.85) !important;
}

.tw-fill-cyan-50\/90 {
  fill: rgb(236 254 255 / 0.9) !important;
}

.tw-fill-cyan-50\/95 {
  fill: rgb(236 254 255 / 0.95) !important;
}

.tw-fill-cyan-500 {
  fill: #06b6d4 !important;
}

.tw-fill-cyan-500\/0 {
  fill: rgb(6 182 212 / 0) !important;
}

.tw-fill-cyan-500\/10 {
  fill: rgb(6 182 212 / 0.1) !important;
}

.tw-fill-cyan-500\/100 {
  fill: rgb(6 182 212 / 1) !important;
}

.tw-fill-cyan-500\/15 {
  fill: rgb(6 182 212 / 0.15) !important;
}

.tw-fill-cyan-500\/20 {
  fill: rgb(6 182 212 / 0.2) !important;
}

.tw-fill-cyan-500\/25 {
  fill: rgb(6 182 212 / 0.25) !important;
}

.tw-fill-cyan-500\/30 {
  fill: rgb(6 182 212 / 0.3) !important;
}

.tw-fill-cyan-500\/35 {
  fill: rgb(6 182 212 / 0.35) !important;
}

.tw-fill-cyan-500\/40 {
  fill: rgb(6 182 212 / 0.4) !important;
}

.tw-fill-cyan-500\/45 {
  fill: rgb(6 182 212 / 0.45) !important;
}

.tw-fill-cyan-500\/5 {
  fill: rgb(6 182 212 / 0.05) !important;
}

.tw-fill-cyan-500\/50 {
  fill: rgb(6 182 212 / 0.5) !important;
}

.tw-fill-cyan-500\/55 {
  fill: rgb(6 182 212 / 0.55) !important;
}

.tw-fill-cyan-500\/60 {
  fill: rgb(6 182 212 / 0.6) !important;
}

.tw-fill-cyan-500\/65 {
  fill: rgb(6 182 212 / 0.65) !important;
}

.tw-fill-cyan-500\/70 {
  fill: rgb(6 182 212 / 0.7) !important;
}

.tw-fill-cyan-500\/75 {
  fill: rgb(6 182 212 / 0.75) !important;
}

.tw-fill-cyan-500\/80 {
  fill: rgb(6 182 212 / 0.8) !important;
}

.tw-fill-cyan-500\/85 {
  fill: rgb(6 182 212 / 0.85) !important;
}

.tw-fill-cyan-500\/90 {
  fill: rgb(6 182 212 / 0.9) !important;
}

.tw-fill-cyan-500\/95 {
  fill: rgb(6 182 212 / 0.95) !important;
}

.tw-fill-cyan-600 {
  fill: #0891b2 !important;
}

.tw-fill-cyan-600\/0 {
  fill: rgb(8 145 178 / 0) !important;
}

.tw-fill-cyan-600\/10 {
  fill: rgb(8 145 178 / 0.1) !important;
}

.tw-fill-cyan-600\/100 {
  fill: rgb(8 145 178 / 1) !important;
}

.tw-fill-cyan-600\/15 {
  fill: rgb(8 145 178 / 0.15) !important;
}

.tw-fill-cyan-600\/20 {
  fill: rgb(8 145 178 / 0.2) !important;
}

.tw-fill-cyan-600\/25 {
  fill: rgb(8 145 178 / 0.25) !important;
}

.tw-fill-cyan-600\/30 {
  fill: rgb(8 145 178 / 0.3) !important;
}

.tw-fill-cyan-600\/35 {
  fill: rgb(8 145 178 / 0.35) !important;
}

.tw-fill-cyan-600\/40 {
  fill: rgb(8 145 178 / 0.4) !important;
}

.tw-fill-cyan-600\/45 {
  fill: rgb(8 145 178 / 0.45) !important;
}

.tw-fill-cyan-600\/5 {
  fill: rgb(8 145 178 / 0.05) !important;
}

.tw-fill-cyan-600\/50 {
  fill: rgb(8 145 178 / 0.5) !important;
}

.tw-fill-cyan-600\/55 {
  fill: rgb(8 145 178 / 0.55) !important;
}

.tw-fill-cyan-600\/60 {
  fill: rgb(8 145 178 / 0.6) !important;
}

.tw-fill-cyan-600\/65 {
  fill: rgb(8 145 178 / 0.65) !important;
}

.tw-fill-cyan-600\/70 {
  fill: rgb(8 145 178 / 0.7) !important;
}

.tw-fill-cyan-600\/75 {
  fill: rgb(8 145 178 / 0.75) !important;
}

.tw-fill-cyan-600\/80 {
  fill: rgb(8 145 178 / 0.8) !important;
}

.tw-fill-cyan-600\/85 {
  fill: rgb(8 145 178 / 0.85) !important;
}

.tw-fill-cyan-600\/90 {
  fill: rgb(8 145 178 / 0.9) !important;
}

.tw-fill-cyan-600\/95 {
  fill: rgb(8 145 178 / 0.95) !important;
}

.tw-fill-cyan-700 {
  fill: #0e7490 !important;
}

.tw-fill-cyan-700\/0 {
  fill: rgb(14 116 144 / 0) !important;
}

.tw-fill-cyan-700\/10 {
  fill: rgb(14 116 144 / 0.1) !important;
}

.tw-fill-cyan-700\/100 {
  fill: rgb(14 116 144 / 1) !important;
}

.tw-fill-cyan-700\/15 {
  fill: rgb(14 116 144 / 0.15) !important;
}

.tw-fill-cyan-700\/20 {
  fill: rgb(14 116 144 / 0.2) !important;
}

.tw-fill-cyan-700\/25 {
  fill: rgb(14 116 144 / 0.25) !important;
}

.tw-fill-cyan-700\/30 {
  fill: rgb(14 116 144 / 0.3) !important;
}

.tw-fill-cyan-700\/35 {
  fill: rgb(14 116 144 / 0.35) !important;
}

.tw-fill-cyan-700\/40 {
  fill: rgb(14 116 144 / 0.4) !important;
}

.tw-fill-cyan-700\/45 {
  fill: rgb(14 116 144 / 0.45) !important;
}

.tw-fill-cyan-700\/5 {
  fill: rgb(14 116 144 / 0.05) !important;
}

.tw-fill-cyan-700\/50 {
  fill: rgb(14 116 144 / 0.5) !important;
}

.tw-fill-cyan-700\/55 {
  fill: rgb(14 116 144 / 0.55) !important;
}

.tw-fill-cyan-700\/60 {
  fill: rgb(14 116 144 / 0.6) !important;
}

.tw-fill-cyan-700\/65 {
  fill: rgb(14 116 144 / 0.65) !important;
}

.tw-fill-cyan-700\/70 {
  fill: rgb(14 116 144 / 0.7) !important;
}

.tw-fill-cyan-700\/75 {
  fill: rgb(14 116 144 / 0.75) !important;
}

.tw-fill-cyan-700\/80 {
  fill: rgb(14 116 144 / 0.8) !important;
}

.tw-fill-cyan-700\/85 {
  fill: rgb(14 116 144 / 0.85) !important;
}

.tw-fill-cyan-700\/90 {
  fill: rgb(14 116 144 / 0.9) !important;
}

.tw-fill-cyan-700\/95 {
  fill: rgb(14 116 144 / 0.95) !important;
}

.tw-fill-cyan-800 {
  fill: #155e75 !important;
}

.tw-fill-cyan-800\/0 {
  fill: rgb(21 94 117 / 0) !important;
}

.tw-fill-cyan-800\/10 {
  fill: rgb(21 94 117 / 0.1) !important;
}

.tw-fill-cyan-800\/100 {
  fill: rgb(21 94 117 / 1) !important;
}

.tw-fill-cyan-800\/15 {
  fill: rgb(21 94 117 / 0.15) !important;
}

.tw-fill-cyan-800\/20 {
  fill: rgb(21 94 117 / 0.2) !important;
}

.tw-fill-cyan-800\/25 {
  fill: rgb(21 94 117 / 0.25) !important;
}

.tw-fill-cyan-800\/30 {
  fill: rgb(21 94 117 / 0.3) !important;
}

.tw-fill-cyan-800\/35 {
  fill: rgb(21 94 117 / 0.35) !important;
}

.tw-fill-cyan-800\/40 {
  fill: rgb(21 94 117 / 0.4) !important;
}

.tw-fill-cyan-800\/45 {
  fill: rgb(21 94 117 / 0.45) !important;
}

.tw-fill-cyan-800\/5 {
  fill: rgb(21 94 117 / 0.05) !important;
}

.tw-fill-cyan-800\/50 {
  fill: rgb(21 94 117 / 0.5) !important;
}

.tw-fill-cyan-800\/55 {
  fill: rgb(21 94 117 / 0.55) !important;
}

.tw-fill-cyan-800\/60 {
  fill: rgb(21 94 117 / 0.6) !important;
}

.tw-fill-cyan-800\/65 {
  fill: rgb(21 94 117 / 0.65) !important;
}

.tw-fill-cyan-800\/70 {
  fill: rgb(21 94 117 / 0.7) !important;
}

.tw-fill-cyan-800\/75 {
  fill: rgb(21 94 117 / 0.75) !important;
}

.tw-fill-cyan-800\/80 {
  fill: rgb(21 94 117 / 0.8) !important;
}

.tw-fill-cyan-800\/85 {
  fill: rgb(21 94 117 / 0.85) !important;
}

.tw-fill-cyan-800\/90 {
  fill: rgb(21 94 117 / 0.9) !important;
}

.tw-fill-cyan-800\/95 {
  fill: rgb(21 94 117 / 0.95) !important;
}

.tw-fill-cyan-900 {
  fill: #164e63 !important;
}

.tw-fill-cyan-900\/0 {
  fill: rgb(22 78 99 / 0) !important;
}

.tw-fill-cyan-900\/10 {
  fill: rgb(22 78 99 / 0.1) !important;
}

.tw-fill-cyan-900\/100 {
  fill: rgb(22 78 99 / 1) !important;
}

.tw-fill-cyan-900\/15 {
  fill: rgb(22 78 99 / 0.15) !important;
}

.tw-fill-cyan-900\/20 {
  fill: rgb(22 78 99 / 0.2) !important;
}

.tw-fill-cyan-900\/25 {
  fill: rgb(22 78 99 / 0.25) !important;
}

.tw-fill-cyan-900\/30 {
  fill: rgb(22 78 99 / 0.3) !important;
}

.tw-fill-cyan-900\/35 {
  fill: rgb(22 78 99 / 0.35) !important;
}

.tw-fill-cyan-900\/40 {
  fill: rgb(22 78 99 / 0.4) !important;
}

.tw-fill-cyan-900\/45 {
  fill: rgb(22 78 99 / 0.45) !important;
}

.tw-fill-cyan-900\/5 {
  fill: rgb(22 78 99 / 0.05) !important;
}

.tw-fill-cyan-900\/50 {
  fill: rgb(22 78 99 / 0.5) !important;
}

.tw-fill-cyan-900\/55 {
  fill: rgb(22 78 99 / 0.55) !important;
}

.tw-fill-cyan-900\/60 {
  fill: rgb(22 78 99 / 0.6) !important;
}

.tw-fill-cyan-900\/65 {
  fill: rgb(22 78 99 / 0.65) !important;
}

.tw-fill-cyan-900\/70 {
  fill: rgb(22 78 99 / 0.7) !important;
}

.tw-fill-cyan-900\/75 {
  fill: rgb(22 78 99 / 0.75) !important;
}

.tw-fill-cyan-900\/80 {
  fill: rgb(22 78 99 / 0.8) !important;
}

.tw-fill-cyan-900\/85 {
  fill: rgb(22 78 99 / 0.85) !important;
}

.tw-fill-cyan-900\/90 {
  fill: rgb(22 78 99 / 0.9) !important;
}

.tw-fill-cyan-900\/95 {
  fill: rgb(22 78 99 / 0.95) !important;
}

.tw-fill-cyan-950 {
  fill: #083344 !important;
}

.tw-fill-cyan-950\/0 {
  fill: rgb(8 51 68 / 0) !important;
}

.tw-fill-cyan-950\/10 {
  fill: rgb(8 51 68 / 0.1) !important;
}

.tw-fill-cyan-950\/100 {
  fill: rgb(8 51 68 / 1) !important;
}

.tw-fill-cyan-950\/15 {
  fill: rgb(8 51 68 / 0.15) !important;
}

.tw-fill-cyan-950\/20 {
  fill: rgb(8 51 68 / 0.2) !important;
}

.tw-fill-cyan-950\/25 {
  fill: rgb(8 51 68 / 0.25) !important;
}

.tw-fill-cyan-950\/30 {
  fill: rgb(8 51 68 / 0.3) !important;
}

.tw-fill-cyan-950\/35 {
  fill: rgb(8 51 68 / 0.35) !important;
}

.tw-fill-cyan-950\/40 {
  fill: rgb(8 51 68 / 0.4) !important;
}

.tw-fill-cyan-950\/45 {
  fill: rgb(8 51 68 / 0.45) !important;
}

.tw-fill-cyan-950\/5 {
  fill: rgb(8 51 68 / 0.05) !important;
}

.tw-fill-cyan-950\/50 {
  fill: rgb(8 51 68 / 0.5) !important;
}

.tw-fill-cyan-950\/55 {
  fill: rgb(8 51 68 / 0.55) !important;
}

.tw-fill-cyan-950\/60 {
  fill: rgb(8 51 68 / 0.6) !important;
}

.tw-fill-cyan-950\/65 {
  fill: rgb(8 51 68 / 0.65) !important;
}

.tw-fill-cyan-950\/70 {
  fill: rgb(8 51 68 / 0.7) !important;
}

.tw-fill-cyan-950\/75 {
  fill: rgb(8 51 68 / 0.75) !important;
}

.tw-fill-cyan-950\/80 {
  fill: rgb(8 51 68 / 0.8) !important;
}

.tw-fill-cyan-950\/85 {
  fill: rgb(8 51 68 / 0.85) !important;
}

.tw-fill-cyan-950\/90 {
  fill: rgb(8 51 68 / 0.9) !important;
}

.tw-fill-cyan-950\/95 {
  fill: rgb(8 51 68 / 0.95) !important;
}

.tw-fill-emerald-100 {
  fill: #d1fae5 !important;
}

.tw-fill-emerald-100\/0 {
  fill: rgb(209 250 229 / 0) !important;
}

.tw-fill-emerald-100\/10 {
  fill: rgb(209 250 229 / 0.1) !important;
}

.tw-fill-emerald-100\/100 {
  fill: rgb(209 250 229 / 1) !important;
}

.tw-fill-emerald-100\/15 {
  fill: rgb(209 250 229 / 0.15) !important;
}

.tw-fill-emerald-100\/20 {
  fill: rgb(209 250 229 / 0.2) !important;
}

.tw-fill-emerald-100\/25 {
  fill: rgb(209 250 229 / 0.25) !important;
}

.tw-fill-emerald-100\/30 {
  fill: rgb(209 250 229 / 0.3) !important;
}

.tw-fill-emerald-100\/35 {
  fill: rgb(209 250 229 / 0.35) !important;
}

.tw-fill-emerald-100\/40 {
  fill: rgb(209 250 229 / 0.4) !important;
}

.tw-fill-emerald-100\/45 {
  fill: rgb(209 250 229 / 0.45) !important;
}

.tw-fill-emerald-100\/5 {
  fill: rgb(209 250 229 / 0.05) !important;
}

.tw-fill-emerald-100\/50 {
  fill: rgb(209 250 229 / 0.5) !important;
}

.tw-fill-emerald-100\/55 {
  fill: rgb(209 250 229 / 0.55) !important;
}

.tw-fill-emerald-100\/60 {
  fill: rgb(209 250 229 / 0.6) !important;
}

.tw-fill-emerald-100\/65 {
  fill: rgb(209 250 229 / 0.65) !important;
}

.tw-fill-emerald-100\/70 {
  fill: rgb(209 250 229 / 0.7) !important;
}

.tw-fill-emerald-100\/75 {
  fill: rgb(209 250 229 / 0.75) !important;
}

.tw-fill-emerald-100\/80 {
  fill: rgb(209 250 229 / 0.8) !important;
}

.tw-fill-emerald-100\/85 {
  fill: rgb(209 250 229 / 0.85) !important;
}

.tw-fill-emerald-100\/90 {
  fill: rgb(209 250 229 / 0.9) !important;
}

.tw-fill-emerald-100\/95 {
  fill: rgb(209 250 229 / 0.95) !important;
}

.tw-fill-emerald-200 {
  fill: #a7f3d0 !important;
}

.tw-fill-emerald-200\/0 {
  fill: rgb(167 243 208 / 0) !important;
}

.tw-fill-emerald-200\/10 {
  fill: rgb(167 243 208 / 0.1) !important;
}

.tw-fill-emerald-200\/100 {
  fill: rgb(167 243 208 / 1) !important;
}

.tw-fill-emerald-200\/15 {
  fill: rgb(167 243 208 / 0.15) !important;
}

.tw-fill-emerald-200\/20 {
  fill: rgb(167 243 208 / 0.2) !important;
}

.tw-fill-emerald-200\/25 {
  fill: rgb(167 243 208 / 0.25) !important;
}

.tw-fill-emerald-200\/30 {
  fill: rgb(167 243 208 / 0.3) !important;
}

.tw-fill-emerald-200\/35 {
  fill: rgb(167 243 208 / 0.35) !important;
}

.tw-fill-emerald-200\/40 {
  fill: rgb(167 243 208 / 0.4) !important;
}

.tw-fill-emerald-200\/45 {
  fill: rgb(167 243 208 / 0.45) !important;
}

.tw-fill-emerald-200\/5 {
  fill: rgb(167 243 208 / 0.05) !important;
}

.tw-fill-emerald-200\/50 {
  fill: rgb(167 243 208 / 0.5) !important;
}

.tw-fill-emerald-200\/55 {
  fill: rgb(167 243 208 / 0.55) !important;
}

.tw-fill-emerald-200\/60 {
  fill: rgb(167 243 208 / 0.6) !important;
}

.tw-fill-emerald-200\/65 {
  fill: rgb(167 243 208 / 0.65) !important;
}

.tw-fill-emerald-200\/70 {
  fill: rgb(167 243 208 / 0.7) !important;
}

.tw-fill-emerald-200\/75 {
  fill: rgb(167 243 208 / 0.75) !important;
}

.tw-fill-emerald-200\/80 {
  fill: rgb(167 243 208 / 0.8) !important;
}

.tw-fill-emerald-200\/85 {
  fill: rgb(167 243 208 / 0.85) !important;
}

.tw-fill-emerald-200\/90 {
  fill: rgb(167 243 208 / 0.9) !important;
}

.tw-fill-emerald-200\/95 {
  fill: rgb(167 243 208 / 0.95) !important;
}

.tw-fill-emerald-300 {
  fill: #6ee7b7 !important;
}

.tw-fill-emerald-300\/0 {
  fill: rgb(110 231 183 / 0) !important;
}

.tw-fill-emerald-300\/10 {
  fill: rgb(110 231 183 / 0.1) !important;
}

.tw-fill-emerald-300\/100 {
  fill: rgb(110 231 183 / 1) !important;
}

.tw-fill-emerald-300\/15 {
  fill: rgb(110 231 183 / 0.15) !important;
}

.tw-fill-emerald-300\/20 {
  fill: rgb(110 231 183 / 0.2) !important;
}

.tw-fill-emerald-300\/25 {
  fill: rgb(110 231 183 / 0.25) !important;
}

.tw-fill-emerald-300\/30 {
  fill: rgb(110 231 183 / 0.3) !important;
}

.tw-fill-emerald-300\/35 {
  fill: rgb(110 231 183 / 0.35) !important;
}

.tw-fill-emerald-300\/40 {
  fill: rgb(110 231 183 / 0.4) !important;
}

.tw-fill-emerald-300\/45 {
  fill: rgb(110 231 183 / 0.45) !important;
}

.tw-fill-emerald-300\/5 {
  fill: rgb(110 231 183 / 0.05) !important;
}

.tw-fill-emerald-300\/50 {
  fill: rgb(110 231 183 / 0.5) !important;
}

.tw-fill-emerald-300\/55 {
  fill: rgb(110 231 183 / 0.55) !important;
}

.tw-fill-emerald-300\/60 {
  fill: rgb(110 231 183 / 0.6) !important;
}

.tw-fill-emerald-300\/65 {
  fill: rgb(110 231 183 / 0.65) !important;
}

.tw-fill-emerald-300\/70 {
  fill: rgb(110 231 183 / 0.7) !important;
}

.tw-fill-emerald-300\/75 {
  fill: rgb(110 231 183 / 0.75) !important;
}

.tw-fill-emerald-300\/80 {
  fill: rgb(110 231 183 / 0.8) !important;
}

.tw-fill-emerald-300\/85 {
  fill: rgb(110 231 183 / 0.85) !important;
}

.tw-fill-emerald-300\/90 {
  fill: rgb(110 231 183 / 0.9) !important;
}

.tw-fill-emerald-300\/95 {
  fill: rgb(110 231 183 / 0.95) !important;
}

.tw-fill-emerald-400 {
  fill: #34d399 !important;
}

.tw-fill-emerald-400\/0 {
  fill: rgb(52 211 153 / 0) !important;
}

.tw-fill-emerald-400\/10 {
  fill: rgb(52 211 153 / 0.1) !important;
}

.tw-fill-emerald-400\/100 {
  fill: rgb(52 211 153 / 1) !important;
}

.tw-fill-emerald-400\/15 {
  fill: rgb(52 211 153 / 0.15) !important;
}

.tw-fill-emerald-400\/20 {
  fill: rgb(52 211 153 / 0.2) !important;
}

.tw-fill-emerald-400\/25 {
  fill: rgb(52 211 153 / 0.25) !important;
}

.tw-fill-emerald-400\/30 {
  fill: rgb(52 211 153 / 0.3) !important;
}

.tw-fill-emerald-400\/35 {
  fill: rgb(52 211 153 / 0.35) !important;
}

.tw-fill-emerald-400\/40 {
  fill: rgb(52 211 153 / 0.4) !important;
}

.tw-fill-emerald-400\/45 {
  fill: rgb(52 211 153 / 0.45) !important;
}

.tw-fill-emerald-400\/5 {
  fill: rgb(52 211 153 / 0.05) !important;
}

.tw-fill-emerald-400\/50 {
  fill: rgb(52 211 153 / 0.5) !important;
}

.tw-fill-emerald-400\/55 {
  fill: rgb(52 211 153 / 0.55) !important;
}

.tw-fill-emerald-400\/60 {
  fill: rgb(52 211 153 / 0.6) !important;
}

.tw-fill-emerald-400\/65 {
  fill: rgb(52 211 153 / 0.65) !important;
}

.tw-fill-emerald-400\/70 {
  fill: rgb(52 211 153 / 0.7) !important;
}

.tw-fill-emerald-400\/75 {
  fill: rgb(52 211 153 / 0.75) !important;
}

.tw-fill-emerald-400\/80 {
  fill: rgb(52 211 153 / 0.8) !important;
}

.tw-fill-emerald-400\/85 {
  fill: rgb(52 211 153 / 0.85) !important;
}

.tw-fill-emerald-400\/90 {
  fill: rgb(52 211 153 / 0.9) !important;
}

.tw-fill-emerald-400\/95 {
  fill: rgb(52 211 153 / 0.95) !important;
}

.tw-fill-emerald-50 {
  fill: #ecfdf5 !important;
}

.tw-fill-emerald-50\/0 {
  fill: rgb(236 253 245 / 0) !important;
}

.tw-fill-emerald-50\/10 {
  fill: rgb(236 253 245 / 0.1) !important;
}

.tw-fill-emerald-50\/100 {
  fill: rgb(236 253 245 / 1) !important;
}

.tw-fill-emerald-50\/15 {
  fill: rgb(236 253 245 / 0.15) !important;
}

.tw-fill-emerald-50\/20 {
  fill: rgb(236 253 245 / 0.2) !important;
}

.tw-fill-emerald-50\/25 {
  fill: rgb(236 253 245 / 0.25) !important;
}

.tw-fill-emerald-50\/30 {
  fill: rgb(236 253 245 / 0.3) !important;
}

.tw-fill-emerald-50\/35 {
  fill: rgb(236 253 245 / 0.35) !important;
}

.tw-fill-emerald-50\/40 {
  fill: rgb(236 253 245 / 0.4) !important;
}

.tw-fill-emerald-50\/45 {
  fill: rgb(236 253 245 / 0.45) !important;
}

.tw-fill-emerald-50\/5 {
  fill: rgb(236 253 245 / 0.05) !important;
}

.tw-fill-emerald-50\/50 {
  fill: rgb(236 253 245 / 0.5) !important;
}

.tw-fill-emerald-50\/55 {
  fill: rgb(236 253 245 / 0.55) !important;
}

.tw-fill-emerald-50\/60 {
  fill: rgb(236 253 245 / 0.6) !important;
}

.tw-fill-emerald-50\/65 {
  fill: rgb(236 253 245 / 0.65) !important;
}

.tw-fill-emerald-50\/70 {
  fill: rgb(236 253 245 / 0.7) !important;
}

.tw-fill-emerald-50\/75 {
  fill: rgb(236 253 245 / 0.75) !important;
}

.tw-fill-emerald-50\/80 {
  fill: rgb(236 253 245 / 0.8) !important;
}

.tw-fill-emerald-50\/85 {
  fill: rgb(236 253 245 / 0.85) !important;
}

.tw-fill-emerald-50\/90 {
  fill: rgb(236 253 245 / 0.9) !important;
}

.tw-fill-emerald-50\/95 {
  fill: rgb(236 253 245 / 0.95) !important;
}

.tw-fill-emerald-500 {
  fill: #10b981 !important;
}

.tw-fill-emerald-500\/0 {
  fill: rgb(16 185 129 / 0) !important;
}

.tw-fill-emerald-500\/10 {
  fill: rgb(16 185 129 / 0.1) !important;
}

.tw-fill-emerald-500\/100 {
  fill: rgb(16 185 129 / 1) !important;
}

.tw-fill-emerald-500\/15 {
  fill: rgb(16 185 129 / 0.15) !important;
}

.tw-fill-emerald-500\/20 {
  fill: rgb(16 185 129 / 0.2) !important;
}

.tw-fill-emerald-500\/25 {
  fill: rgb(16 185 129 / 0.25) !important;
}

.tw-fill-emerald-500\/30 {
  fill: rgb(16 185 129 / 0.3) !important;
}

.tw-fill-emerald-500\/35 {
  fill: rgb(16 185 129 / 0.35) !important;
}

.tw-fill-emerald-500\/40 {
  fill: rgb(16 185 129 / 0.4) !important;
}

.tw-fill-emerald-500\/45 {
  fill: rgb(16 185 129 / 0.45) !important;
}

.tw-fill-emerald-500\/5 {
  fill: rgb(16 185 129 / 0.05) !important;
}

.tw-fill-emerald-500\/50 {
  fill: rgb(16 185 129 / 0.5) !important;
}

.tw-fill-emerald-500\/55 {
  fill: rgb(16 185 129 / 0.55) !important;
}

.tw-fill-emerald-500\/60 {
  fill: rgb(16 185 129 / 0.6) !important;
}

.tw-fill-emerald-500\/65 {
  fill: rgb(16 185 129 / 0.65) !important;
}

.tw-fill-emerald-500\/70 {
  fill: rgb(16 185 129 / 0.7) !important;
}

.tw-fill-emerald-500\/75 {
  fill: rgb(16 185 129 / 0.75) !important;
}

.tw-fill-emerald-500\/80 {
  fill: rgb(16 185 129 / 0.8) !important;
}

.tw-fill-emerald-500\/85 {
  fill: rgb(16 185 129 / 0.85) !important;
}

.tw-fill-emerald-500\/90 {
  fill: rgb(16 185 129 / 0.9) !important;
}

.tw-fill-emerald-500\/95 {
  fill: rgb(16 185 129 / 0.95) !important;
}

.tw-fill-emerald-600 {
  fill: #059669 !important;
}

.tw-fill-emerald-600\/0 {
  fill: rgb(5 150 105 / 0) !important;
}

.tw-fill-emerald-600\/10 {
  fill: rgb(5 150 105 / 0.1) !important;
}

.tw-fill-emerald-600\/100 {
  fill: rgb(5 150 105 / 1) !important;
}

.tw-fill-emerald-600\/15 {
  fill: rgb(5 150 105 / 0.15) !important;
}

.tw-fill-emerald-600\/20 {
  fill: rgb(5 150 105 / 0.2) !important;
}

.tw-fill-emerald-600\/25 {
  fill: rgb(5 150 105 / 0.25) !important;
}

.tw-fill-emerald-600\/30 {
  fill: rgb(5 150 105 / 0.3) !important;
}

.tw-fill-emerald-600\/35 {
  fill: rgb(5 150 105 / 0.35) !important;
}

.tw-fill-emerald-600\/40 {
  fill: rgb(5 150 105 / 0.4) !important;
}

.tw-fill-emerald-600\/45 {
  fill: rgb(5 150 105 / 0.45) !important;
}

.tw-fill-emerald-600\/5 {
  fill: rgb(5 150 105 / 0.05) !important;
}

.tw-fill-emerald-600\/50 {
  fill: rgb(5 150 105 / 0.5) !important;
}

.tw-fill-emerald-600\/55 {
  fill: rgb(5 150 105 / 0.55) !important;
}

.tw-fill-emerald-600\/60 {
  fill: rgb(5 150 105 / 0.6) !important;
}

.tw-fill-emerald-600\/65 {
  fill: rgb(5 150 105 / 0.65) !important;
}

.tw-fill-emerald-600\/70 {
  fill: rgb(5 150 105 / 0.7) !important;
}

.tw-fill-emerald-600\/75 {
  fill: rgb(5 150 105 / 0.75) !important;
}

.tw-fill-emerald-600\/80 {
  fill: rgb(5 150 105 / 0.8) !important;
}

.tw-fill-emerald-600\/85 {
  fill: rgb(5 150 105 / 0.85) !important;
}

.tw-fill-emerald-600\/90 {
  fill: rgb(5 150 105 / 0.9) !important;
}

.tw-fill-emerald-600\/95 {
  fill: rgb(5 150 105 / 0.95) !important;
}

.tw-fill-emerald-700 {
  fill: #047857 !important;
}

.tw-fill-emerald-700\/0 {
  fill: rgb(4 120 87 / 0) !important;
}

.tw-fill-emerald-700\/10 {
  fill: rgb(4 120 87 / 0.1) !important;
}

.tw-fill-emerald-700\/100 {
  fill: rgb(4 120 87 / 1) !important;
}

.tw-fill-emerald-700\/15 {
  fill: rgb(4 120 87 / 0.15) !important;
}

.tw-fill-emerald-700\/20 {
  fill: rgb(4 120 87 / 0.2) !important;
}

.tw-fill-emerald-700\/25 {
  fill: rgb(4 120 87 / 0.25) !important;
}

.tw-fill-emerald-700\/30 {
  fill: rgb(4 120 87 / 0.3) !important;
}

.tw-fill-emerald-700\/35 {
  fill: rgb(4 120 87 / 0.35) !important;
}

.tw-fill-emerald-700\/40 {
  fill: rgb(4 120 87 / 0.4) !important;
}

.tw-fill-emerald-700\/45 {
  fill: rgb(4 120 87 / 0.45) !important;
}

.tw-fill-emerald-700\/5 {
  fill: rgb(4 120 87 / 0.05) !important;
}

.tw-fill-emerald-700\/50 {
  fill: rgb(4 120 87 / 0.5) !important;
}

.tw-fill-emerald-700\/55 {
  fill: rgb(4 120 87 / 0.55) !important;
}

.tw-fill-emerald-700\/60 {
  fill: rgb(4 120 87 / 0.6) !important;
}

.tw-fill-emerald-700\/65 {
  fill: rgb(4 120 87 / 0.65) !important;
}

.tw-fill-emerald-700\/70 {
  fill: rgb(4 120 87 / 0.7) !important;
}

.tw-fill-emerald-700\/75 {
  fill: rgb(4 120 87 / 0.75) !important;
}

.tw-fill-emerald-700\/80 {
  fill: rgb(4 120 87 / 0.8) !important;
}

.tw-fill-emerald-700\/85 {
  fill: rgb(4 120 87 / 0.85) !important;
}

.tw-fill-emerald-700\/90 {
  fill: rgb(4 120 87 / 0.9) !important;
}

.tw-fill-emerald-700\/95 {
  fill: rgb(4 120 87 / 0.95) !important;
}

.tw-fill-emerald-800 {
  fill: #065f46 !important;
}

.tw-fill-emerald-800\/0 {
  fill: rgb(6 95 70 / 0) !important;
}

.tw-fill-emerald-800\/10 {
  fill: rgb(6 95 70 / 0.1) !important;
}

.tw-fill-emerald-800\/100 {
  fill: rgb(6 95 70 / 1) !important;
}

.tw-fill-emerald-800\/15 {
  fill: rgb(6 95 70 / 0.15) !important;
}

.tw-fill-emerald-800\/20 {
  fill: rgb(6 95 70 / 0.2) !important;
}

.tw-fill-emerald-800\/25 {
  fill: rgb(6 95 70 / 0.25) !important;
}

.tw-fill-emerald-800\/30 {
  fill: rgb(6 95 70 / 0.3) !important;
}

.tw-fill-emerald-800\/35 {
  fill: rgb(6 95 70 / 0.35) !important;
}

.tw-fill-emerald-800\/40 {
  fill: rgb(6 95 70 / 0.4) !important;
}

.tw-fill-emerald-800\/45 {
  fill: rgb(6 95 70 / 0.45) !important;
}

.tw-fill-emerald-800\/5 {
  fill: rgb(6 95 70 / 0.05) !important;
}

.tw-fill-emerald-800\/50 {
  fill: rgb(6 95 70 / 0.5) !important;
}

.tw-fill-emerald-800\/55 {
  fill: rgb(6 95 70 / 0.55) !important;
}

.tw-fill-emerald-800\/60 {
  fill: rgb(6 95 70 / 0.6) !important;
}

.tw-fill-emerald-800\/65 {
  fill: rgb(6 95 70 / 0.65) !important;
}

.tw-fill-emerald-800\/70 {
  fill: rgb(6 95 70 / 0.7) !important;
}

.tw-fill-emerald-800\/75 {
  fill: rgb(6 95 70 / 0.75) !important;
}

.tw-fill-emerald-800\/80 {
  fill: rgb(6 95 70 / 0.8) !important;
}

.tw-fill-emerald-800\/85 {
  fill: rgb(6 95 70 / 0.85) !important;
}

.tw-fill-emerald-800\/90 {
  fill: rgb(6 95 70 / 0.9) !important;
}

.tw-fill-emerald-800\/95 {
  fill: rgb(6 95 70 / 0.95) !important;
}

.tw-fill-emerald-900 {
  fill: #064e3b !important;
}

.tw-fill-emerald-900\/0 {
  fill: rgb(6 78 59 / 0) !important;
}

.tw-fill-emerald-900\/10 {
  fill: rgb(6 78 59 / 0.1) !important;
}

.tw-fill-emerald-900\/100 {
  fill: rgb(6 78 59 / 1) !important;
}

.tw-fill-emerald-900\/15 {
  fill: rgb(6 78 59 / 0.15) !important;
}

.tw-fill-emerald-900\/20 {
  fill: rgb(6 78 59 / 0.2) !important;
}

.tw-fill-emerald-900\/25 {
  fill: rgb(6 78 59 / 0.25) !important;
}

.tw-fill-emerald-900\/30 {
  fill: rgb(6 78 59 / 0.3) !important;
}

.tw-fill-emerald-900\/35 {
  fill: rgb(6 78 59 / 0.35) !important;
}

.tw-fill-emerald-900\/40 {
  fill: rgb(6 78 59 / 0.4) !important;
}

.tw-fill-emerald-900\/45 {
  fill: rgb(6 78 59 / 0.45) !important;
}

.tw-fill-emerald-900\/5 {
  fill: rgb(6 78 59 / 0.05) !important;
}

.tw-fill-emerald-900\/50 {
  fill: rgb(6 78 59 / 0.5) !important;
}

.tw-fill-emerald-900\/55 {
  fill: rgb(6 78 59 / 0.55) !important;
}

.tw-fill-emerald-900\/60 {
  fill: rgb(6 78 59 / 0.6) !important;
}

.tw-fill-emerald-900\/65 {
  fill: rgb(6 78 59 / 0.65) !important;
}

.tw-fill-emerald-900\/70 {
  fill: rgb(6 78 59 / 0.7) !important;
}

.tw-fill-emerald-900\/75 {
  fill: rgb(6 78 59 / 0.75) !important;
}

.tw-fill-emerald-900\/80 {
  fill: rgb(6 78 59 / 0.8) !important;
}

.tw-fill-emerald-900\/85 {
  fill: rgb(6 78 59 / 0.85) !important;
}

.tw-fill-emerald-900\/90 {
  fill: rgb(6 78 59 / 0.9) !important;
}

.tw-fill-emerald-900\/95 {
  fill: rgb(6 78 59 / 0.95) !important;
}

.tw-fill-emerald-950 {
  fill: #022c22 !important;
}

.tw-fill-emerald-950\/0 {
  fill: rgb(2 44 34 / 0) !important;
}

.tw-fill-emerald-950\/10 {
  fill: rgb(2 44 34 / 0.1) !important;
}

.tw-fill-emerald-950\/100 {
  fill: rgb(2 44 34 / 1) !important;
}

.tw-fill-emerald-950\/15 {
  fill: rgb(2 44 34 / 0.15) !important;
}

.tw-fill-emerald-950\/20 {
  fill: rgb(2 44 34 / 0.2) !important;
}

.tw-fill-emerald-950\/25 {
  fill: rgb(2 44 34 / 0.25) !important;
}

.tw-fill-emerald-950\/30 {
  fill: rgb(2 44 34 / 0.3) !important;
}

.tw-fill-emerald-950\/35 {
  fill: rgb(2 44 34 / 0.35) !important;
}

.tw-fill-emerald-950\/40 {
  fill: rgb(2 44 34 / 0.4) !important;
}

.tw-fill-emerald-950\/45 {
  fill: rgb(2 44 34 / 0.45) !important;
}

.tw-fill-emerald-950\/5 {
  fill: rgb(2 44 34 / 0.05) !important;
}

.tw-fill-emerald-950\/50 {
  fill: rgb(2 44 34 / 0.5) !important;
}

.tw-fill-emerald-950\/55 {
  fill: rgb(2 44 34 / 0.55) !important;
}

.tw-fill-emerald-950\/60 {
  fill: rgb(2 44 34 / 0.6) !important;
}

.tw-fill-emerald-950\/65 {
  fill: rgb(2 44 34 / 0.65) !important;
}

.tw-fill-emerald-950\/70 {
  fill: rgb(2 44 34 / 0.7) !important;
}

.tw-fill-emerald-950\/75 {
  fill: rgb(2 44 34 / 0.75) !important;
}

.tw-fill-emerald-950\/80 {
  fill: rgb(2 44 34 / 0.8) !important;
}

.tw-fill-emerald-950\/85 {
  fill: rgb(2 44 34 / 0.85) !important;
}

.tw-fill-emerald-950\/90 {
  fill: rgb(2 44 34 / 0.9) !important;
}

.tw-fill-emerald-950\/95 {
  fill: rgb(2 44 34 / 0.95) !important;
}

.tw-fill-fuchsia-100 {
  fill: #fae8ff !important;
}

.tw-fill-fuchsia-100\/0 {
  fill: rgb(250 232 255 / 0) !important;
}

.tw-fill-fuchsia-100\/10 {
  fill: rgb(250 232 255 / 0.1) !important;
}

.tw-fill-fuchsia-100\/100 {
  fill: rgb(250 232 255 / 1) !important;
}

.tw-fill-fuchsia-100\/15 {
  fill: rgb(250 232 255 / 0.15) !important;
}

.tw-fill-fuchsia-100\/20 {
  fill: rgb(250 232 255 / 0.2) !important;
}

.tw-fill-fuchsia-100\/25 {
  fill: rgb(250 232 255 / 0.25) !important;
}

.tw-fill-fuchsia-100\/30 {
  fill: rgb(250 232 255 / 0.3) !important;
}

.tw-fill-fuchsia-100\/35 {
  fill: rgb(250 232 255 / 0.35) !important;
}

.tw-fill-fuchsia-100\/40 {
  fill: rgb(250 232 255 / 0.4) !important;
}

.tw-fill-fuchsia-100\/45 {
  fill: rgb(250 232 255 / 0.45) !important;
}

.tw-fill-fuchsia-100\/5 {
  fill: rgb(250 232 255 / 0.05) !important;
}

.tw-fill-fuchsia-100\/50 {
  fill: rgb(250 232 255 / 0.5) !important;
}

.tw-fill-fuchsia-100\/55 {
  fill: rgb(250 232 255 / 0.55) !important;
}

.tw-fill-fuchsia-100\/60 {
  fill: rgb(250 232 255 / 0.6) !important;
}

.tw-fill-fuchsia-100\/65 {
  fill: rgb(250 232 255 / 0.65) !important;
}

.tw-fill-fuchsia-100\/70 {
  fill: rgb(250 232 255 / 0.7) !important;
}

.tw-fill-fuchsia-100\/75 {
  fill: rgb(250 232 255 / 0.75) !important;
}

.tw-fill-fuchsia-100\/80 {
  fill: rgb(250 232 255 / 0.8) !important;
}

.tw-fill-fuchsia-100\/85 {
  fill: rgb(250 232 255 / 0.85) !important;
}

.tw-fill-fuchsia-100\/90 {
  fill: rgb(250 232 255 / 0.9) !important;
}

.tw-fill-fuchsia-100\/95 {
  fill: rgb(250 232 255 / 0.95) !important;
}

.tw-fill-fuchsia-200 {
  fill: #f5d0fe !important;
}

.tw-fill-fuchsia-200\/0 {
  fill: rgb(245 208 254 / 0) !important;
}

.tw-fill-fuchsia-200\/10 {
  fill: rgb(245 208 254 / 0.1) !important;
}

.tw-fill-fuchsia-200\/100 {
  fill: rgb(245 208 254 / 1) !important;
}

.tw-fill-fuchsia-200\/15 {
  fill: rgb(245 208 254 / 0.15) !important;
}

.tw-fill-fuchsia-200\/20 {
  fill: rgb(245 208 254 / 0.2) !important;
}

.tw-fill-fuchsia-200\/25 {
  fill: rgb(245 208 254 / 0.25) !important;
}

.tw-fill-fuchsia-200\/30 {
  fill: rgb(245 208 254 / 0.3) !important;
}

.tw-fill-fuchsia-200\/35 {
  fill: rgb(245 208 254 / 0.35) !important;
}

.tw-fill-fuchsia-200\/40 {
  fill: rgb(245 208 254 / 0.4) !important;
}

.tw-fill-fuchsia-200\/45 {
  fill: rgb(245 208 254 / 0.45) !important;
}

.tw-fill-fuchsia-200\/5 {
  fill: rgb(245 208 254 / 0.05) !important;
}

.tw-fill-fuchsia-200\/50 {
  fill: rgb(245 208 254 / 0.5) !important;
}

.tw-fill-fuchsia-200\/55 {
  fill: rgb(245 208 254 / 0.55) !important;
}

.tw-fill-fuchsia-200\/60 {
  fill: rgb(245 208 254 / 0.6) !important;
}

.tw-fill-fuchsia-200\/65 {
  fill: rgb(245 208 254 / 0.65) !important;
}

.tw-fill-fuchsia-200\/70 {
  fill: rgb(245 208 254 / 0.7) !important;
}

.tw-fill-fuchsia-200\/75 {
  fill: rgb(245 208 254 / 0.75) !important;
}

.tw-fill-fuchsia-200\/80 {
  fill: rgb(245 208 254 / 0.8) !important;
}

.tw-fill-fuchsia-200\/85 {
  fill: rgb(245 208 254 / 0.85) !important;
}

.tw-fill-fuchsia-200\/90 {
  fill: rgb(245 208 254 / 0.9) !important;
}

.tw-fill-fuchsia-200\/95 {
  fill: rgb(245 208 254 / 0.95) !important;
}

.tw-fill-fuchsia-300 {
  fill: #f0abfc !important;
}

.tw-fill-fuchsia-300\/0 {
  fill: rgb(240 171 252 / 0) !important;
}

.tw-fill-fuchsia-300\/10 {
  fill: rgb(240 171 252 / 0.1) !important;
}

.tw-fill-fuchsia-300\/100 {
  fill: rgb(240 171 252 / 1) !important;
}

.tw-fill-fuchsia-300\/15 {
  fill: rgb(240 171 252 / 0.15) !important;
}

.tw-fill-fuchsia-300\/20 {
  fill: rgb(240 171 252 / 0.2) !important;
}

.tw-fill-fuchsia-300\/25 {
  fill: rgb(240 171 252 / 0.25) !important;
}

.tw-fill-fuchsia-300\/30 {
  fill: rgb(240 171 252 / 0.3) !important;
}

.tw-fill-fuchsia-300\/35 {
  fill: rgb(240 171 252 / 0.35) !important;
}

.tw-fill-fuchsia-300\/40 {
  fill: rgb(240 171 252 / 0.4) !important;
}

.tw-fill-fuchsia-300\/45 {
  fill: rgb(240 171 252 / 0.45) !important;
}

.tw-fill-fuchsia-300\/5 {
  fill: rgb(240 171 252 / 0.05) !important;
}

.tw-fill-fuchsia-300\/50 {
  fill: rgb(240 171 252 / 0.5) !important;
}

.tw-fill-fuchsia-300\/55 {
  fill: rgb(240 171 252 / 0.55) !important;
}

.tw-fill-fuchsia-300\/60 {
  fill: rgb(240 171 252 / 0.6) !important;
}

.tw-fill-fuchsia-300\/65 {
  fill: rgb(240 171 252 / 0.65) !important;
}

.tw-fill-fuchsia-300\/70 {
  fill: rgb(240 171 252 / 0.7) !important;
}

.tw-fill-fuchsia-300\/75 {
  fill: rgb(240 171 252 / 0.75) !important;
}

.tw-fill-fuchsia-300\/80 {
  fill: rgb(240 171 252 / 0.8) !important;
}

.tw-fill-fuchsia-300\/85 {
  fill: rgb(240 171 252 / 0.85) !important;
}

.tw-fill-fuchsia-300\/90 {
  fill: rgb(240 171 252 / 0.9) !important;
}

.tw-fill-fuchsia-300\/95 {
  fill: rgb(240 171 252 / 0.95) !important;
}

.tw-fill-fuchsia-400 {
  fill: #e879f9 !important;
}

.tw-fill-fuchsia-400\/0 {
  fill: rgb(232 121 249 / 0) !important;
}

.tw-fill-fuchsia-400\/10 {
  fill: rgb(232 121 249 / 0.1) !important;
}

.tw-fill-fuchsia-400\/100 {
  fill: rgb(232 121 249 / 1) !important;
}

.tw-fill-fuchsia-400\/15 {
  fill: rgb(232 121 249 / 0.15) !important;
}

.tw-fill-fuchsia-400\/20 {
  fill: rgb(232 121 249 / 0.2) !important;
}

.tw-fill-fuchsia-400\/25 {
  fill: rgb(232 121 249 / 0.25) !important;
}

.tw-fill-fuchsia-400\/30 {
  fill: rgb(232 121 249 / 0.3) !important;
}

.tw-fill-fuchsia-400\/35 {
  fill: rgb(232 121 249 / 0.35) !important;
}

.tw-fill-fuchsia-400\/40 {
  fill: rgb(232 121 249 / 0.4) !important;
}

.tw-fill-fuchsia-400\/45 {
  fill: rgb(232 121 249 / 0.45) !important;
}

.tw-fill-fuchsia-400\/5 {
  fill: rgb(232 121 249 / 0.05) !important;
}

.tw-fill-fuchsia-400\/50 {
  fill: rgb(232 121 249 / 0.5) !important;
}

.tw-fill-fuchsia-400\/55 {
  fill: rgb(232 121 249 / 0.55) !important;
}

.tw-fill-fuchsia-400\/60 {
  fill: rgb(232 121 249 / 0.6) !important;
}

.tw-fill-fuchsia-400\/65 {
  fill: rgb(232 121 249 / 0.65) !important;
}

.tw-fill-fuchsia-400\/70 {
  fill: rgb(232 121 249 / 0.7) !important;
}

.tw-fill-fuchsia-400\/75 {
  fill: rgb(232 121 249 / 0.75) !important;
}

.tw-fill-fuchsia-400\/80 {
  fill: rgb(232 121 249 / 0.8) !important;
}

.tw-fill-fuchsia-400\/85 {
  fill: rgb(232 121 249 / 0.85) !important;
}

.tw-fill-fuchsia-400\/90 {
  fill: rgb(232 121 249 / 0.9) !important;
}

.tw-fill-fuchsia-400\/95 {
  fill: rgb(232 121 249 / 0.95) !important;
}

.tw-fill-fuchsia-50 {
  fill: #fdf4ff !important;
}

.tw-fill-fuchsia-50\/0 {
  fill: rgb(253 244 255 / 0) !important;
}

.tw-fill-fuchsia-50\/10 {
  fill: rgb(253 244 255 / 0.1) !important;
}

.tw-fill-fuchsia-50\/100 {
  fill: rgb(253 244 255 / 1) !important;
}

.tw-fill-fuchsia-50\/15 {
  fill: rgb(253 244 255 / 0.15) !important;
}

.tw-fill-fuchsia-50\/20 {
  fill: rgb(253 244 255 / 0.2) !important;
}

.tw-fill-fuchsia-50\/25 {
  fill: rgb(253 244 255 / 0.25) !important;
}

.tw-fill-fuchsia-50\/30 {
  fill: rgb(253 244 255 / 0.3) !important;
}

.tw-fill-fuchsia-50\/35 {
  fill: rgb(253 244 255 / 0.35) !important;
}

.tw-fill-fuchsia-50\/40 {
  fill: rgb(253 244 255 / 0.4) !important;
}

.tw-fill-fuchsia-50\/45 {
  fill: rgb(253 244 255 / 0.45) !important;
}

.tw-fill-fuchsia-50\/5 {
  fill: rgb(253 244 255 / 0.05) !important;
}

.tw-fill-fuchsia-50\/50 {
  fill: rgb(253 244 255 / 0.5) !important;
}

.tw-fill-fuchsia-50\/55 {
  fill: rgb(253 244 255 / 0.55) !important;
}

.tw-fill-fuchsia-50\/60 {
  fill: rgb(253 244 255 / 0.6) !important;
}

.tw-fill-fuchsia-50\/65 {
  fill: rgb(253 244 255 / 0.65) !important;
}

.tw-fill-fuchsia-50\/70 {
  fill: rgb(253 244 255 / 0.7) !important;
}

.tw-fill-fuchsia-50\/75 {
  fill: rgb(253 244 255 / 0.75) !important;
}

.tw-fill-fuchsia-50\/80 {
  fill: rgb(253 244 255 / 0.8) !important;
}

.tw-fill-fuchsia-50\/85 {
  fill: rgb(253 244 255 / 0.85) !important;
}

.tw-fill-fuchsia-50\/90 {
  fill: rgb(253 244 255 / 0.9) !important;
}

.tw-fill-fuchsia-50\/95 {
  fill: rgb(253 244 255 / 0.95) !important;
}

.tw-fill-fuchsia-500 {
  fill: #d946ef !important;
}

.tw-fill-fuchsia-500\/0 {
  fill: rgb(217 70 239 / 0) !important;
}

.tw-fill-fuchsia-500\/10 {
  fill: rgb(217 70 239 / 0.1) !important;
}

.tw-fill-fuchsia-500\/100 {
  fill: rgb(217 70 239 / 1) !important;
}

.tw-fill-fuchsia-500\/15 {
  fill: rgb(217 70 239 / 0.15) !important;
}

.tw-fill-fuchsia-500\/20 {
  fill: rgb(217 70 239 / 0.2) !important;
}

.tw-fill-fuchsia-500\/25 {
  fill: rgb(217 70 239 / 0.25) !important;
}

.tw-fill-fuchsia-500\/30 {
  fill: rgb(217 70 239 / 0.3) !important;
}

.tw-fill-fuchsia-500\/35 {
  fill: rgb(217 70 239 / 0.35) !important;
}

.tw-fill-fuchsia-500\/40 {
  fill: rgb(217 70 239 / 0.4) !important;
}

.tw-fill-fuchsia-500\/45 {
  fill: rgb(217 70 239 / 0.45) !important;
}

.tw-fill-fuchsia-500\/5 {
  fill: rgb(217 70 239 / 0.05) !important;
}

.tw-fill-fuchsia-500\/50 {
  fill: rgb(217 70 239 / 0.5) !important;
}

.tw-fill-fuchsia-500\/55 {
  fill: rgb(217 70 239 / 0.55) !important;
}

.tw-fill-fuchsia-500\/60 {
  fill: rgb(217 70 239 / 0.6) !important;
}

.tw-fill-fuchsia-500\/65 {
  fill: rgb(217 70 239 / 0.65) !important;
}

.tw-fill-fuchsia-500\/70 {
  fill: rgb(217 70 239 / 0.7) !important;
}

.tw-fill-fuchsia-500\/75 {
  fill: rgb(217 70 239 / 0.75) !important;
}

.tw-fill-fuchsia-500\/80 {
  fill: rgb(217 70 239 / 0.8) !important;
}

.tw-fill-fuchsia-500\/85 {
  fill: rgb(217 70 239 / 0.85) !important;
}

.tw-fill-fuchsia-500\/90 {
  fill: rgb(217 70 239 / 0.9) !important;
}

.tw-fill-fuchsia-500\/95 {
  fill: rgb(217 70 239 / 0.95) !important;
}

.tw-fill-fuchsia-600 {
  fill: #c026d3 !important;
}

.tw-fill-fuchsia-600\/0 {
  fill: rgb(192 38 211 / 0) !important;
}

.tw-fill-fuchsia-600\/10 {
  fill: rgb(192 38 211 / 0.1) !important;
}

.tw-fill-fuchsia-600\/100 {
  fill: rgb(192 38 211 / 1) !important;
}

.tw-fill-fuchsia-600\/15 {
  fill: rgb(192 38 211 / 0.15) !important;
}

.tw-fill-fuchsia-600\/20 {
  fill: rgb(192 38 211 / 0.2) !important;
}

.tw-fill-fuchsia-600\/25 {
  fill: rgb(192 38 211 / 0.25) !important;
}

.tw-fill-fuchsia-600\/30 {
  fill: rgb(192 38 211 / 0.3) !important;
}

.tw-fill-fuchsia-600\/35 {
  fill: rgb(192 38 211 / 0.35) !important;
}

.tw-fill-fuchsia-600\/40 {
  fill: rgb(192 38 211 / 0.4) !important;
}

.tw-fill-fuchsia-600\/45 {
  fill: rgb(192 38 211 / 0.45) !important;
}

.tw-fill-fuchsia-600\/5 {
  fill: rgb(192 38 211 / 0.05) !important;
}

.tw-fill-fuchsia-600\/50 {
  fill: rgb(192 38 211 / 0.5) !important;
}

.tw-fill-fuchsia-600\/55 {
  fill: rgb(192 38 211 / 0.55) !important;
}

.tw-fill-fuchsia-600\/60 {
  fill: rgb(192 38 211 / 0.6) !important;
}

.tw-fill-fuchsia-600\/65 {
  fill: rgb(192 38 211 / 0.65) !important;
}

.tw-fill-fuchsia-600\/70 {
  fill: rgb(192 38 211 / 0.7) !important;
}

.tw-fill-fuchsia-600\/75 {
  fill: rgb(192 38 211 / 0.75) !important;
}

.tw-fill-fuchsia-600\/80 {
  fill: rgb(192 38 211 / 0.8) !important;
}

.tw-fill-fuchsia-600\/85 {
  fill: rgb(192 38 211 / 0.85) !important;
}

.tw-fill-fuchsia-600\/90 {
  fill: rgb(192 38 211 / 0.9) !important;
}

.tw-fill-fuchsia-600\/95 {
  fill: rgb(192 38 211 / 0.95) !important;
}

.tw-fill-fuchsia-700 {
  fill: #a21caf !important;
}

.tw-fill-fuchsia-700\/0 {
  fill: rgb(162 28 175 / 0) !important;
}

.tw-fill-fuchsia-700\/10 {
  fill: rgb(162 28 175 / 0.1) !important;
}

.tw-fill-fuchsia-700\/100 {
  fill: rgb(162 28 175 / 1) !important;
}

.tw-fill-fuchsia-700\/15 {
  fill: rgb(162 28 175 / 0.15) !important;
}

.tw-fill-fuchsia-700\/20 {
  fill: rgb(162 28 175 / 0.2) !important;
}

.tw-fill-fuchsia-700\/25 {
  fill: rgb(162 28 175 / 0.25) !important;
}

.tw-fill-fuchsia-700\/30 {
  fill: rgb(162 28 175 / 0.3) !important;
}

.tw-fill-fuchsia-700\/35 {
  fill: rgb(162 28 175 / 0.35) !important;
}

.tw-fill-fuchsia-700\/40 {
  fill: rgb(162 28 175 / 0.4) !important;
}

.tw-fill-fuchsia-700\/45 {
  fill: rgb(162 28 175 / 0.45) !important;
}

.tw-fill-fuchsia-700\/5 {
  fill: rgb(162 28 175 / 0.05) !important;
}

.tw-fill-fuchsia-700\/50 {
  fill: rgb(162 28 175 / 0.5) !important;
}

.tw-fill-fuchsia-700\/55 {
  fill: rgb(162 28 175 / 0.55) !important;
}

.tw-fill-fuchsia-700\/60 {
  fill: rgb(162 28 175 / 0.6) !important;
}

.tw-fill-fuchsia-700\/65 {
  fill: rgb(162 28 175 / 0.65) !important;
}

.tw-fill-fuchsia-700\/70 {
  fill: rgb(162 28 175 / 0.7) !important;
}

.tw-fill-fuchsia-700\/75 {
  fill: rgb(162 28 175 / 0.75) !important;
}

.tw-fill-fuchsia-700\/80 {
  fill: rgb(162 28 175 / 0.8) !important;
}

.tw-fill-fuchsia-700\/85 {
  fill: rgb(162 28 175 / 0.85) !important;
}

.tw-fill-fuchsia-700\/90 {
  fill: rgb(162 28 175 / 0.9) !important;
}

.tw-fill-fuchsia-700\/95 {
  fill: rgb(162 28 175 / 0.95) !important;
}

.tw-fill-fuchsia-800 {
  fill: #86198f !important;
}

.tw-fill-fuchsia-800\/0 {
  fill: rgb(134 25 143 / 0) !important;
}

.tw-fill-fuchsia-800\/10 {
  fill: rgb(134 25 143 / 0.1) !important;
}

.tw-fill-fuchsia-800\/100 {
  fill: rgb(134 25 143 / 1) !important;
}

.tw-fill-fuchsia-800\/15 {
  fill: rgb(134 25 143 / 0.15) !important;
}

.tw-fill-fuchsia-800\/20 {
  fill: rgb(134 25 143 / 0.2) !important;
}

.tw-fill-fuchsia-800\/25 {
  fill: rgb(134 25 143 / 0.25) !important;
}

.tw-fill-fuchsia-800\/30 {
  fill: rgb(134 25 143 / 0.3) !important;
}

.tw-fill-fuchsia-800\/35 {
  fill: rgb(134 25 143 / 0.35) !important;
}

.tw-fill-fuchsia-800\/40 {
  fill: rgb(134 25 143 / 0.4) !important;
}

.tw-fill-fuchsia-800\/45 {
  fill: rgb(134 25 143 / 0.45) !important;
}

.tw-fill-fuchsia-800\/5 {
  fill: rgb(134 25 143 / 0.05) !important;
}

.tw-fill-fuchsia-800\/50 {
  fill: rgb(134 25 143 / 0.5) !important;
}

.tw-fill-fuchsia-800\/55 {
  fill: rgb(134 25 143 / 0.55) !important;
}

.tw-fill-fuchsia-800\/60 {
  fill: rgb(134 25 143 / 0.6) !important;
}

.tw-fill-fuchsia-800\/65 {
  fill: rgb(134 25 143 / 0.65) !important;
}

.tw-fill-fuchsia-800\/70 {
  fill: rgb(134 25 143 / 0.7) !important;
}

.tw-fill-fuchsia-800\/75 {
  fill: rgb(134 25 143 / 0.75) !important;
}

.tw-fill-fuchsia-800\/80 {
  fill: rgb(134 25 143 / 0.8) !important;
}

.tw-fill-fuchsia-800\/85 {
  fill: rgb(134 25 143 / 0.85) !important;
}

.tw-fill-fuchsia-800\/90 {
  fill: rgb(134 25 143 / 0.9) !important;
}

.tw-fill-fuchsia-800\/95 {
  fill: rgb(134 25 143 / 0.95) !important;
}

.tw-fill-fuchsia-900 {
  fill: #701a75 !important;
}

.tw-fill-fuchsia-900\/0 {
  fill: rgb(112 26 117 / 0) !important;
}

.tw-fill-fuchsia-900\/10 {
  fill: rgb(112 26 117 / 0.1) !important;
}

.tw-fill-fuchsia-900\/100 {
  fill: rgb(112 26 117 / 1) !important;
}

.tw-fill-fuchsia-900\/15 {
  fill: rgb(112 26 117 / 0.15) !important;
}

.tw-fill-fuchsia-900\/20 {
  fill: rgb(112 26 117 / 0.2) !important;
}

.tw-fill-fuchsia-900\/25 {
  fill: rgb(112 26 117 / 0.25) !important;
}

.tw-fill-fuchsia-900\/30 {
  fill: rgb(112 26 117 / 0.3) !important;
}

.tw-fill-fuchsia-900\/35 {
  fill: rgb(112 26 117 / 0.35) !important;
}

.tw-fill-fuchsia-900\/40 {
  fill: rgb(112 26 117 / 0.4) !important;
}

.tw-fill-fuchsia-900\/45 {
  fill: rgb(112 26 117 / 0.45) !important;
}

.tw-fill-fuchsia-900\/5 {
  fill: rgb(112 26 117 / 0.05) !important;
}

.tw-fill-fuchsia-900\/50 {
  fill: rgb(112 26 117 / 0.5) !important;
}

.tw-fill-fuchsia-900\/55 {
  fill: rgb(112 26 117 / 0.55) !important;
}

.tw-fill-fuchsia-900\/60 {
  fill: rgb(112 26 117 / 0.6) !important;
}

.tw-fill-fuchsia-900\/65 {
  fill: rgb(112 26 117 / 0.65) !important;
}

.tw-fill-fuchsia-900\/70 {
  fill: rgb(112 26 117 / 0.7) !important;
}

.tw-fill-fuchsia-900\/75 {
  fill: rgb(112 26 117 / 0.75) !important;
}

.tw-fill-fuchsia-900\/80 {
  fill: rgb(112 26 117 / 0.8) !important;
}

.tw-fill-fuchsia-900\/85 {
  fill: rgb(112 26 117 / 0.85) !important;
}

.tw-fill-fuchsia-900\/90 {
  fill: rgb(112 26 117 / 0.9) !important;
}

.tw-fill-fuchsia-900\/95 {
  fill: rgb(112 26 117 / 0.95) !important;
}

.tw-fill-fuchsia-950 {
  fill: #4a044e !important;
}

.tw-fill-fuchsia-950\/0 {
  fill: rgb(74 4 78 / 0) !important;
}

.tw-fill-fuchsia-950\/10 {
  fill: rgb(74 4 78 / 0.1) !important;
}

.tw-fill-fuchsia-950\/100 {
  fill: rgb(74 4 78 / 1) !important;
}

.tw-fill-fuchsia-950\/15 {
  fill: rgb(74 4 78 / 0.15) !important;
}

.tw-fill-fuchsia-950\/20 {
  fill: rgb(74 4 78 / 0.2) !important;
}

.tw-fill-fuchsia-950\/25 {
  fill: rgb(74 4 78 / 0.25) !important;
}

.tw-fill-fuchsia-950\/30 {
  fill: rgb(74 4 78 / 0.3) !important;
}

.tw-fill-fuchsia-950\/35 {
  fill: rgb(74 4 78 / 0.35) !important;
}

.tw-fill-fuchsia-950\/40 {
  fill: rgb(74 4 78 / 0.4) !important;
}

.tw-fill-fuchsia-950\/45 {
  fill: rgb(74 4 78 / 0.45) !important;
}

.tw-fill-fuchsia-950\/5 {
  fill: rgb(74 4 78 / 0.05) !important;
}

.tw-fill-fuchsia-950\/50 {
  fill: rgb(74 4 78 / 0.5) !important;
}

.tw-fill-fuchsia-950\/55 {
  fill: rgb(74 4 78 / 0.55) !important;
}

.tw-fill-fuchsia-950\/60 {
  fill: rgb(74 4 78 / 0.6) !important;
}

.tw-fill-fuchsia-950\/65 {
  fill: rgb(74 4 78 / 0.65) !important;
}

.tw-fill-fuchsia-950\/70 {
  fill: rgb(74 4 78 / 0.7) !important;
}

.tw-fill-fuchsia-950\/75 {
  fill: rgb(74 4 78 / 0.75) !important;
}

.tw-fill-fuchsia-950\/80 {
  fill: rgb(74 4 78 / 0.8) !important;
}

.tw-fill-fuchsia-950\/85 {
  fill: rgb(74 4 78 / 0.85) !important;
}

.tw-fill-fuchsia-950\/90 {
  fill: rgb(74 4 78 / 0.9) !important;
}

.tw-fill-fuchsia-950\/95 {
  fill: rgb(74 4 78 / 0.95) !important;
}

.tw-fill-gray-100 {
  fill: #f3f4f6 !important;
}

.tw-fill-gray-100\/0 {
  fill: rgb(243 244 246 / 0) !important;
}

.tw-fill-gray-100\/10 {
  fill: rgb(243 244 246 / 0.1) !important;
}

.tw-fill-gray-100\/100 {
  fill: rgb(243 244 246 / 1) !important;
}

.tw-fill-gray-100\/15 {
  fill: rgb(243 244 246 / 0.15) !important;
}

.tw-fill-gray-100\/20 {
  fill: rgb(243 244 246 / 0.2) !important;
}

.tw-fill-gray-100\/25 {
  fill: rgb(243 244 246 / 0.25) !important;
}

.tw-fill-gray-100\/30 {
  fill: rgb(243 244 246 / 0.3) !important;
}

.tw-fill-gray-100\/35 {
  fill: rgb(243 244 246 / 0.35) !important;
}

.tw-fill-gray-100\/40 {
  fill: rgb(243 244 246 / 0.4) !important;
}

.tw-fill-gray-100\/45 {
  fill: rgb(243 244 246 / 0.45) !important;
}

.tw-fill-gray-100\/5 {
  fill: rgb(243 244 246 / 0.05) !important;
}

.tw-fill-gray-100\/50 {
  fill: rgb(243 244 246 / 0.5) !important;
}

.tw-fill-gray-100\/55 {
  fill: rgb(243 244 246 / 0.55) !important;
}

.tw-fill-gray-100\/60 {
  fill: rgb(243 244 246 / 0.6) !important;
}

.tw-fill-gray-100\/65 {
  fill: rgb(243 244 246 / 0.65) !important;
}

.tw-fill-gray-100\/70 {
  fill: rgb(243 244 246 / 0.7) !important;
}

.tw-fill-gray-100\/75 {
  fill: rgb(243 244 246 / 0.75) !important;
}

.tw-fill-gray-100\/80 {
  fill: rgb(243 244 246 / 0.8) !important;
}

.tw-fill-gray-100\/85 {
  fill: rgb(243 244 246 / 0.85) !important;
}

.tw-fill-gray-100\/90 {
  fill: rgb(243 244 246 / 0.9) !important;
}

.tw-fill-gray-100\/95 {
  fill: rgb(243 244 246 / 0.95) !important;
}

.tw-fill-gray-200 {
  fill: #e5e7eb !important;
}

.tw-fill-gray-200\/0 {
  fill: rgb(229 231 235 / 0) !important;
}

.tw-fill-gray-200\/10 {
  fill: rgb(229 231 235 / 0.1) !important;
}

.tw-fill-gray-200\/100 {
  fill: rgb(229 231 235 / 1) !important;
}

.tw-fill-gray-200\/15 {
  fill: rgb(229 231 235 / 0.15) !important;
}

.tw-fill-gray-200\/20 {
  fill: rgb(229 231 235 / 0.2) !important;
}

.tw-fill-gray-200\/25 {
  fill: rgb(229 231 235 / 0.25) !important;
}

.tw-fill-gray-200\/30 {
  fill: rgb(229 231 235 / 0.3) !important;
}

.tw-fill-gray-200\/35 {
  fill: rgb(229 231 235 / 0.35) !important;
}

.tw-fill-gray-200\/40 {
  fill: rgb(229 231 235 / 0.4) !important;
}

.tw-fill-gray-200\/45 {
  fill: rgb(229 231 235 / 0.45) !important;
}

.tw-fill-gray-200\/5 {
  fill: rgb(229 231 235 / 0.05) !important;
}

.tw-fill-gray-200\/50 {
  fill: rgb(229 231 235 / 0.5) !important;
}

.tw-fill-gray-200\/55 {
  fill: rgb(229 231 235 / 0.55) !important;
}

.tw-fill-gray-200\/60 {
  fill: rgb(229 231 235 / 0.6) !important;
}

.tw-fill-gray-200\/65 {
  fill: rgb(229 231 235 / 0.65) !important;
}

.tw-fill-gray-200\/70 {
  fill: rgb(229 231 235 / 0.7) !important;
}

.tw-fill-gray-200\/75 {
  fill: rgb(229 231 235 / 0.75) !important;
}

.tw-fill-gray-200\/80 {
  fill: rgb(229 231 235 / 0.8) !important;
}

.tw-fill-gray-200\/85 {
  fill: rgb(229 231 235 / 0.85) !important;
}

.tw-fill-gray-200\/90 {
  fill: rgb(229 231 235 / 0.9) !important;
}

.tw-fill-gray-200\/95 {
  fill: rgb(229 231 235 / 0.95) !important;
}

.tw-fill-gray-300 {
  fill: #d1d5db !important;
}

.tw-fill-gray-300\/0 {
  fill: rgb(209 213 219 / 0) !important;
}

.tw-fill-gray-300\/10 {
  fill: rgb(209 213 219 / 0.1) !important;
}

.tw-fill-gray-300\/100 {
  fill: rgb(209 213 219 / 1) !important;
}

.tw-fill-gray-300\/15 {
  fill: rgb(209 213 219 / 0.15) !important;
}

.tw-fill-gray-300\/20 {
  fill: rgb(209 213 219 / 0.2) !important;
}

.tw-fill-gray-300\/25 {
  fill: rgb(209 213 219 / 0.25) !important;
}

.tw-fill-gray-300\/30 {
  fill: rgb(209 213 219 / 0.3) !important;
}

.tw-fill-gray-300\/35 {
  fill: rgb(209 213 219 / 0.35) !important;
}

.tw-fill-gray-300\/40 {
  fill: rgb(209 213 219 / 0.4) !important;
}

.tw-fill-gray-300\/45 {
  fill: rgb(209 213 219 / 0.45) !important;
}

.tw-fill-gray-300\/5 {
  fill: rgb(209 213 219 / 0.05) !important;
}

.tw-fill-gray-300\/50 {
  fill: rgb(209 213 219 / 0.5) !important;
}

.tw-fill-gray-300\/55 {
  fill: rgb(209 213 219 / 0.55) !important;
}

.tw-fill-gray-300\/60 {
  fill: rgb(209 213 219 / 0.6) !important;
}

.tw-fill-gray-300\/65 {
  fill: rgb(209 213 219 / 0.65) !important;
}

.tw-fill-gray-300\/70 {
  fill: rgb(209 213 219 / 0.7) !important;
}

.tw-fill-gray-300\/75 {
  fill: rgb(209 213 219 / 0.75) !important;
}

.tw-fill-gray-300\/80 {
  fill: rgb(209 213 219 / 0.8) !important;
}

.tw-fill-gray-300\/85 {
  fill: rgb(209 213 219 / 0.85) !important;
}

.tw-fill-gray-300\/90 {
  fill: rgb(209 213 219 / 0.9) !important;
}

.tw-fill-gray-300\/95 {
  fill: rgb(209 213 219 / 0.95) !important;
}

.tw-fill-gray-400 {
  fill: #9ca3af !important;
}

.tw-fill-gray-400\/0 {
  fill: rgb(156 163 175 / 0) !important;
}

.tw-fill-gray-400\/10 {
  fill: rgb(156 163 175 / 0.1) !important;
}

.tw-fill-gray-400\/100 {
  fill: rgb(156 163 175 / 1) !important;
}

.tw-fill-gray-400\/15 {
  fill: rgb(156 163 175 / 0.15) !important;
}

.tw-fill-gray-400\/20 {
  fill: rgb(156 163 175 / 0.2) !important;
}

.tw-fill-gray-400\/25 {
  fill: rgb(156 163 175 / 0.25) !important;
}

.tw-fill-gray-400\/30 {
  fill: rgb(156 163 175 / 0.3) !important;
}

.tw-fill-gray-400\/35 {
  fill: rgb(156 163 175 / 0.35) !important;
}

.tw-fill-gray-400\/40 {
  fill: rgb(156 163 175 / 0.4) !important;
}

.tw-fill-gray-400\/45 {
  fill: rgb(156 163 175 / 0.45) !important;
}

.tw-fill-gray-400\/5 {
  fill: rgb(156 163 175 / 0.05) !important;
}

.tw-fill-gray-400\/50 {
  fill: rgb(156 163 175 / 0.5) !important;
}

.tw-fill-gray-400\/55 {
  fill: rgb(156 163 175 / 0.55) !important;
}

.tw-fill-gray-400\/60 {
  fill: rgb(156 163 175 / 0.6) !important;
}

.tw-fill-gray-400\/65 {
  fill: rgb(156 163 175 / 0.65) !important;
}

.tw-fill-gray-400\/70 {
  fill: rgb(156 163 175 / 0.7) !important;
}

.tw-fill-gray-400\/75 {
  fill: rgb(156 163 175 / 0.75) !important;
}

.tw-fill-gray-400\/80 {
  fill: rgb(156 163 175 / 0.8) !important;
}

.tw-fill-gray-400\/85 {
  fill: rgb(156 163 175 / 0.85) !important;
}

.tw-fill-gray-400\/90 {
  fill: rgb(156 163 175 / 0.9) !important;
}

.tw-fill-gray-400\/95 {
  fill: rgb(156 163 175 / 0.95) !important;
}

.tw-fill-gray-50 {
  fill: #f9fafb !important;
}

.tw-fill-gray-50\/0 {
  fill: rgb(249 250 251 / 0) !important;
}

.tw-fill-gray-50\/10 {
  fill: rgb(249 250 251 / 0.1) !important;
}

.tw-fill-gray-50\/100 {
  fill: rgb(249 250 251 / 1) !important;
}

.tw-fill-gray-50\/15 {
  fill: rgb(249 250 251 / 0.15) !important;
}

.tw-fill-gray-50\/20 {
  fill: rgb(249 250 251 / 0.2) !important;
}

.tw-fill-gray-50\/25 {
  fill: rgb(249 250 251 / 0.25) !important;
}

.tw-fill-gray-50\/30 {
  fill: rgb(249 250 251 / 0.3) !important;
}

.tw-fill-gray-50\/35 {
  fill: rgb(249 250 251 / 0.35) !important;
}

.tw-fill-gray-50\/40 {
  fill: rgb(249 250 251 / 0.4) !important;
}

.tw-fill-gray-50\/45 {
  fill: rgb(249 250 251 / 0.45) !important;
}

.tw-fill-gray-50\/5 {
  fill: rgb(249 250 251 / 0.05) !important;
}

.tw-fill-gray-50\/50 {
  fill: rgb(249 250 251 / 0.5) !important;
}

.tw-fill-gray-50\/55 {
  fill: rgb(249 250 251 / 0.55) !important;
}

.tw-fill-gray-50\/60 {
  fill: rgb(249 250 251 / 0.6) !important;
}

.tw-fill-gray-50\/65 {
  fill: rgb(249 250 251 / 0.65) !important;
}

.tw-fill-gray-50\/70 {
  fill: rgb(249 250 251 / 0.7) !important;
}

.tw-fill-gray-50\/75 {
  fill: rgb(249 250 251 / 0.75) !important;
}

.tw-fill-gray-50\/80 {
  fill: rgb(249 250 251 / 0.8) !important;
}

.tw-fill-gray-50\/85 {
  fill: rgb(249 250 251 / 0.85) !important;
}

.tw-fill-gray-50\/90 {
  fill: rgb(249 250 251 / 0.9) !important;
}

.tw-fill-gray-50\/95 {
  fill: rgb(249 250 251 / 0.95) !important;
}

.tw-fill-gray-500 {
  fill: #6b7280 !important;
}

.tw-fill-gray-500\/0 {
  fill: rgb(107 114 128 / 0) !important;
}

.tw-fill-gray-500\/10 {
  fill: rgb(107 114 128 / 0.1) !important;
}

.tw-fill-gray-500\/100 {
  fill: rgb(107 114 128 / 1) !important;
}

.tw-fill-gray-500\/15 {
  fill: rgb(107 114 128 / 0.15) !important;
}

.tw-fill-gray-500\/20 {
  fill: rgb(107 114 128 / 0.2) !important;
}

.tw-fill-gray-500\/25 {
  fill: rgb(107 114 128 / 0.25) !important;
}

.tw-fill-gray-500\/30 {
  fill: rgb(107 114 128 / 0.3) !important;
}

.tw-fill-gray-500\/35 {
  fill: rgb(107 114 128 / 0.35) !important;
}

.tw-fill-gray-500\/40 {
  fill: rgb(107 114 128 / 0.4) !important;
}

.tw-fill-gray-500\/45 {
  fill: rgb(107 114 128 / 0.45) !important;
}

.tw-fill-gray-500\/5 {
  fill: rgb(107 114 128 / 0.05) !important;
}

.tw-fill-gray-500\/50 {
  fill: rgb(107 114 128 / 0.5) !important;
}

.tw-fill-gray-500\/55 {
  fill: rgb(107 114 128 / 0.55) !important;
}

.tw-fill-gray-500\/60 {
  fill: rgb(107 114 128 / 0.6) !important;
}

.tw-fill-gray-500\/65 {
  fill: rgb(107 114 128 / 0.65) !important;
}

.tw-fill-gray-500\/70 {
  fill: rgb(107 114 128 / 0.7) !important;
}

.tw-fill-gray-500\/75 {
  fill: rgb(107 114 128 / 0.75) !important;
}

.tw-fill-gray-500\/80 {
  fill: rgb(107 114 128 / 0.8) !important;
}

.tw-fill-gray-500\/85 {
  fill: rgb(107 114 128 / 0.85) !important;
}

.tw-fill-gray-500\/90 {
  fill: rgb(107 114 128 / 0.9) !important;
}

.tw-fill-gray-500\/95 {
  fill: rgb(107 114 128 / 0.95) !important;
}

.tw-fill-gray-600 {
  fill: #4b5563 !important;
}

.tw-fill-gray-600\/0 {
  fill: rgb(75 85 99 / 0) !important;
}

.tw-fill-gray-600\/10 {
  fill: rgb(75 85 99 / 0.1) !important;
}

.tw-fill-gray-600\/100 {
  fill: rgb(75 85 99 / 1) !important;
}

.tw-fill-gray-600\/15 {
  fill: rgb(75 85 99 / 0.15) !important;
}

.tw-fill-gray-600\/20 {
  fill: rgb(75 85 99 / 0.2) !important;
}

.tw-fill-gray-600\/25 {
  fill: rgb(75 85 99 / 0.25) !important;
}

.tw-fill-gray-600\/30 {
  fill: rgb(75 85 99 / 0.3) !important;
}

.tw-fill-gray-600\/35 {
  fill: rgb(75 85 99 / 0.35) !important;
}

.tw-fill-gray-600\/40 {
  fill: rgb(75 85 99 / 0.4) !important;
}

.tw-fill-gray-600\/45 {
  fill: rgb(75 85 99 / 0.45) !important;
}

.tw-fill-gray-600\/5 {
  fill: rgb(75 85 99 / 0.05) !important;
}

.tw-fill-gray-600\/50 {
  fill: rgb(75 85 99 / 0.5) !important;
}

.tw-fill-gray-600\/55 {
  fill: rgb(75 85 99 / 0.55) !important;
}

.tw-fill-gray-600\/60 {
  fill: rgb(75 85 99 / 0.6) !important;
}

.tw-fill-gray-600\/65 {
  fill: rgb(75 85 99 / 0.65) !important;
}

.tw-fill-gray-600\/70 {
  fill: rgb(75 85 99 / 0.7) !important;
}

.tw-fill-gray-600\/75 {
  fill: rgb(75 85 99 / 0.75) !important;
}

.tw-fill-gray-600\/80 {
  fill: rgb(75 85 99 / 0.8) !important;
}

.tw-fill-gray-600\/85 {
  fill: rgb(75 85 99 / 0.85) !important;
}

.tw-fill-gray-600\/90 {
  fill: rgb(75 85 99 / 0.9) !important;
}

.tw-fill-gray-600\/95 {
  fill: rgb(75 85 99 / 0.95) !important;
}

.tw-fill-gray-700 {
  fill: #374151 !important;
}

.tw-fill-gray-700\/0 {
  fill: rgb(55 65 81 / 0) !important;
}

.tw-fill-gray-700\/10 {
  fill: rgb(55 65 81 / 0.1) !important;
}

.tw-fill-gray-700\/100 {
  fill: rgb(55 65 81 / 1) !important;
}

.tw-fill-gray-700\/15 {
  fill: rgb(55 65 81 / 0.15) !important;
}

.tw-fill-gray-700\/20 {
  fill: rgb(55 65 81 / 0.2) !important;
}

.tw-fill-gray-700\/25 {
  fill: rgb(55 65 81 / 0.25) !important;
}

.tw-fill-gray-700\/30 {
  fill: rgb(55 65 81 / 0.3) !important;
}

.tw-fill-gray-700\/35 {
  fill: rgb(55 65 81 / 0.35) !important;
}

.tw-fill-gray-700\/40 {
  fill: rgb(55 65 81 / 0.4) !important;
}

.tw-fill-gray-700\/45 {
  fill: rgb(55 65 81 / 0.45) !important;
}

.tw-fill-gray-700\/5 {
  fill: rgb(55 65 81 / 0.05) !important;
}

.tw-fill-gray-700\/50 {
  fill: rgb(55 65 81 / 0.5) !important;
}

.tw-fill-gray-700\/55 {
  fill: rgb(55 65 81 / 0.55) !important;
}

.tw-fill-gray-700\/60 {
  fill: rgb(55 65 81 / 0.6) !important;
}

.tw-fill-gray-700\/65 {
  fill: rgb(55 65 81 / 0.65) !important;
}

.tw-fill-gray-700\/70 {
  fill: rgb(55 65 81 / 0.7) !important;
}

.tw-fill-gray-700\/75 {
  fill: rgb(55 65 81 / 0.75) !important;
}

.tw-fill-gray-700\/80 {
  fill: rgb(55 65 81 / 0.8) !important;
}

.tw-fill-gray-700\/85 {
  fill: rgb(55 65 81 / 0.85) !important;
}

.tw-fill-gray-700\/90 {
  fill: rgb(55 65 81 / 0.9) !important;
}

.tw-fill-gray-700\/95 {
  fill: rgb(55 65 81 / 0.95) !important;
}

.tw-fill-gray-800 {
  fill: #1f2937 !important;
}

.tw-fill-gray-800\/0 {
  fill: rgb(31 41 55 / 0) !important;
}

.tw-fill-gray-800\/10 {
  fill: rgb(31 41 55 / 0.1) !important;
}

.tw-fill-gray-800\/100 {
  fill: rgb(31 41 55 / 1) !important;
}

.tw-fill-gray-800\/15 {
  fill: rgb(31 41 55 / 0.15) !important;
}

.tw-fill-gray-800\/20 {
  fill: rgb(31 41 55 / 0.2) !important;
}

.tw-fill-gray-800\/25 {
  fill: rgb(31 41 55 / 0.25) !important;
}

.tw-fill-gray-800\/30 {
  fill: rgb(31 41 55 / 0.3) !important;
}

.tw-fill-gray-800\/35 {
  fill: rgb(31 41 55 / 0.35) !important;
}

.tw-fill-gray-800\/40 {
  fill: rgb(31 41 55 / 0.4) !important;
}

.tw-fill-gray-800\/45 {
  fill: rgb(31 41 55 / 0.45) !important;
}

.tw-fill-gray-800\/5 {
  fill: rgb(31 41 55 / 0.05) !important;
}

.tw-fill-gray-800\/50 {
  fill: rgb(31 41 55 / 0.5) !important;
}

.tw-fill-gray-800\/55 {
  fill: rgb(31 41 55 / 0.55) !important;
}

.tw-fill-gray-800\/60 {
  fill: rgb(31 41 55 / 0.6) !important;
}

.tw-fill-gray-800\/65 {
  fill: rgb(31 41 55 / 0.65) !important;
}

.tw-fill-gray-800\/70 {
  fill: rgb(31 41 55 / 0.7) !important;
}

.tw-fill-gray-800\/75 {
  fill: rgb(31 41 55 / 0.75) !important;
}

.tw-fill-gray-800\/80 {
  fill: rgb(31 41 55 / 0.8) !important;
}

.tw-fill-gray-800\/85 {
  fill: rgb(31 41 55 / 0.85) !important;
}

.tw-fill-gray-800\/90 {
  fill: rgb(31 41 55 / 0.9) !important;
}

.tw-fill-gray-800\/95 {
  fill: rgb(31 41 55 / 0.95) !important;
}

.tw-fill-gray-900 {
  fill: #111827 !important;
}

.tw-fill-gray-900\/0 {
  fill: rgb(17 24 39 / 0) !important;
}

.tw-fill-gray-900\/10 {
  fill: rgb(17 24 39 / 0.1) !important;
}

.tw-fill-gray-900\/100 {
  fill: rgb(17 24 39 / 1) !important;
}

.tw-fill-gray-900\/15 {
  fill: rgb(17 24 39 / 0.15) !important;
}

.tw-fill-gray-900\/20 {
  fill: rgb(17 24 39 / 0.2) !important;
}

.tw-fill-gray-900\/25 {
  fill: rgb(17 24 39 / 0.25) !important;
}

.tw-fill-gray-900\/30 {
  fill: rgb(17 24 39 / 0.3) !important;
}

.tw-fill-gray-900\/35 {
  fill: rgb(17 24 39 / 0.35) !important;
}

.tw-fill-gray-900\/40 {
  fill: rgb(17 24 39 / 0.4) !important;
}

.tw-fill-gray-900\/45 {
  fill: rgb(17 24 39 / 0.45) !important;
}

.tw-fill-gray-900\/5 {
  fill: rgb(17 24 39 / 0.05) !important;
}

.tw-fill-gray-900\/50 {
  fill: rgb(17 24 39 / 0.5) !important;
}

.tw-fill-gray-900\/55 {
  fill: rgb(17 24 39 / 0.55) !important;
}

.tw-fill-gray-900\/60 {
  fill: rgb(17 24 39 / 0.6) !important;
}

.tw-fill-gray-900\/65 {
  fill: rgb(17 24 39 / 0.65) !important;
}

.tw-fill-gray-900\/70 {
  fill: rgb(17 24 39 / 0.7) !important;
}

.tw-fill-gray-900\/75 {
  fill: rgb(17 24 39 / 0.75) !important;
}

.tw-fill-gray-900\/80 {
  fill: rgb(17 24 39 / 0.8) !important;
}

.tw-fill-gray-900\/85 {
  fill: rgb(17 24 39 / 0.85) !important;
}

.tw-fill-gray-900\/90 {
  fill: rgb(17 24 39 / 0.9) !important;
}

.tw-fill-gray-900\/95 {
  fill: rgb(17 24 39 / 0.95) !important;
}

.tw-fill-gray-950 {
  fill: #030712 !important;
}

.tw-fill-gray-950\/0 {
  fill: rgb(3 7 18 / 0) !important;
}

.tw-fill-gray-950\/10 {
  fill: rgb(3 7 18 / 0.1) !important;
}

.tw-fill-gray-950\/100 {
  fill: rgb(3 7 18 / 1) !important;
}

.tw-fill-gray-950\/15 {
  fill: rgb(3 7 18 / 0.15) !important;
}

.tw-fill-gray-950\/20 {
  fill: rgb(3 7 18 / 0.2) !important;
}

.tw-fill-gray-950\/25 {
  fill: rgb(3 7 18 / 0.25) !important;
}

.tw-fill-gray-950\/30 {
  fill: rgb(3 7 18 / 0.3) !important;
}

.tw-fill-gray-950\/35 {
  fill: rgb(3 7 18 / 0.35) !important;
}

.tw-fill-gray-950\/40 {
  fill: rgb(3 7 18 / 0.4) !important;
}

.tw-fill-gray-950\/45 {
  fill: rgb(3 7 18 / 0.45) !important;
}

.tw-fill-gray-950\/5 {
  fill: rgb(3 7 18 / 0.05) !important;
}

.tw-fill-gray-950\/50 {
  fill: rgb(3 7 18 / 0.5) !important;
}

.tw-fill-gray-950\/55 {
  fill: rgb(3 7 18 / 0.55) !important;
}

.tw-fill-gray-950\/60 {
  fill: rgb(3 7 18 / 0.6) !important;
}

.tw-fill-gray-950\/65 {
  fill: rgb(3 7 18 / 0.65) !important;
}

.tw-fill-gray-950\/70 {
  fill: rgb(3 7 18 / 0.7) !important;
}

.tw-fill-gray-950\/75 {
  fill: rgb(3 7 18 / 0.75) !important;
}

.tw-fill-gray-950\/80 {
  fill: rgb(3 7 18 / 0.8) !important;
}

.tw-fill-gray-950\/85 {
  fill: rgb(3 7 18 / 0.85) !important;
}

.tw-fill-gray-950\/90 {
  fill: rgb(3 7 18 / 0.9) !important;
}

.tw-fill-gray-950\/95 {
  fill: rgb(3 7 18 / 0.95) !important;
}

.tw-fill-green-100 {
  fill: #dcfce7 !important;
}

.tw-fill-green-100\/0 {
  fill: rgb(220 252 231 / 0) !important;
}

.tw-fill-green-100\/10 {
  fill: rgb(220 252 231 / 0.1) !important;
}

.tw-fill-green-100\/100 {
  fill: rgb(220 252 231 / 1) !important;
}

.tw-fill-green-100\/15 {
  fill: rgb(220 252 231 / 0.15) !important;
}

.tw-fill-green-100\/20 {
  fill: rgb(220 252 231 / 0.2) !important;
}

.tw-fill-green-100\/25 {
  fill: rgb(220 252 231 / 0.25) !important;
}

.tw-fill-green-100\/30 {
  fill: rgb(220 252 231 / 0.3) !important;
}

.tw-fill-green-100\/35 {
  fill: rgb(220 252 231 / 0.35) !important;
}

.tw-fill-green-100\/40 {
  fill: rgb(220 252 231 / 0.4) !important;
}

.tw-fill-green-100\/45 {
  fill: rgb(220 252 231 / 0.45) !important;
}

.tw-fill-green-100\/5 {
  fill: rgb(220 252 231 / 0.05) !important;
}

.tw-fill-green-100\/50 {
  fill: rgb(220 252 231 / 0.5) !important;
}

.tw-fill-green-100\/55 {
  fill: rgb(220 252 231 / 0.55) !important;
}

.tw-fill-green-100\/60 {
  fill: rgb(220 252 231 / 0.6) !important;
}

.tw-fill-green-100\/65 {
  fill: rgb(220 252 231 / 0.65) !important;
}

.tw-fill-green-100\/70 {
  fill: rgb(220 252 231 / 0.7) !important;
}

.tw-fill-green-100\/75 {
  fill: rgb(220 252 231 / 0.75) !important;
}

.tw-fill-green-100\/80 {
  fill: rgb(220 252 231 / 0.8) !important;
}

.tw-fill-green-100\/85 {
  fill: rgb(220 252 231 / 0.85) !important;
}

.tw-fill-green-100\/90 {
  fill: rgb(220 252 231 / 0.9) !important;
}

.tw-fill-green-100\/95 {
  fill: rgb(220 252 231 / 0.95) !important;
}

.tw-fill-green-200 {
  fill: #bbf7d0 !important;
}

.tw-fill-green-200\/0 {
  fill: rgb(187 247 208 / 0) !important;
}

.tw-fill-green-200\/10 {
  fill: rgb(187 247 208 / 0.1) !important;
}

.tw-fill-green-200\/100 {
  fill: rgb(187 247 208 / 1) !important;
}

.tw-fill-green-200\/15 {
  fill: rgb(187 247 208 / 0.15) !important;
}

.tw-fill-green-200\/20 {
  fill: rgb(187 247 208 / 0.2) !important;
}

.tw-fill-green-200\/25 {
  fill: rgb(187 247 208 / 0.25) !important;
}

.tw-fill-green-200\/30 {
  fill: rgb(187 247 208 / 0.3) !important;
}

.tw-fill-green-200\/35 {
  fill: rgb(187 247 208 / 0.35) !important;
}

.tw-fill-green-200\/40 {
  fill: rgb(187 247 208 / 0.4) !important;
}

.tw-fill-green-200\/45 {
  fill: rgb(187 247 208 / 0.45) !important;
}

.tw-fill-green-200\/5 {
  fill: rgb(187 247 208 / 0.05) !important;
}

.tw-fill-green-200\/50 {
  fill: rgb(187 247 208 / 0.5) !important;
}

.tw-fill-green-200\/55 {
  fill: rgb(187 247 208 / 0.55) !important;
}

.tw-fill-green-200\/60 {
  fill: rgb(187 247 208 / 0.6) !important;
}

.tw-fill-green-200\/65 {
  fill: rgb(187 247 208 / 0.65) !important;
}

.tw-fill-green-200\/70 {
  fill: rgb(187 247 208 / 0.7) !important;
}

.tw-fill-green-200\/75 {
  fill: rgb(187 247 208 / 0.75) !important;
}

.tw-fill-green-200\/80 {
  fill: rgb(187 247 208 / 0.8) !important;
}

.tw-fill-green-200\/85 {
  fill: rgb(187 247 208 / 0.85) !important;
}

.tw-fill-green-200\/90 {
  fill: rgb(187 247 208 / 0.9) !important;
}

.tw-fill-green-200\/95 {
  fill: rgb(187 247 208 / 0.95) !important;
}

.tw-fill-green-300 {
  fill: #86efac !important;
}

.tw-fill-green-300\/0 {
  fill: rgb(134 239 172 / 0) !important;
}

.tw-fill-green-300\/10 {
  fill: rgb(134 239 172 / 0.1) !important;
}

.tw-fill-green-300\/100 {
  fill: rgb(134 239 172 / 1) !important;
}

.tw-fill-green-300\/15 {
  fill: rgb(134 239 172 / 0.15) !important;
}

.tw-fill-green-300\/20 {
  fill: rgb(134 239 172 / 0.2) !important;
}

.tw-fill-green-300\/25 {
  fill: rgb(134 239 172 / 0.25) !important;
}

.tw-fill-green-300\/30 {
  fill: rgb(134 239 172 / 0.3) !important;
}

.tw-fill-green-300\/35 {
  fill: rgb(134 239 172 / 0.35) !important;
}

.tw-fill-green-300\/40 {
  fill: rgb(134 239 172 / 0.4) !important;
}

.tw-fill-green-300\/45 {
  fill: rgb(134 239 172 / 0.45) !important;
}

.tw-fill-green-300\/5 {
  fill: rgb(134 239 172 / 0.05) !important;
}

.tw-fill-green-300\/50 {
  fill: rgb(134 239 172 / 0.5) !important;
}

.tw-fill-green-300\/55 {
  fill: rgb(134 239 172 / 0.55) !important;
}

.tw-fill-green-300\/60 {
  fill: rgb(134 239 172 / 0.6) !important;
}

.tw-fill-green-300\/65 {
  fill: rgb(134 239 172 / 0.65) !important;
}

.tw-fill-green-300\/70 {
  fill: rgb(134 239 172 / 0.7) !important;
}

.tw-fill-green-300\/75 {
  fill: rgb(134 239 172 / 0.75) !important;
}

.tw-fill-green-300\/80 {
  fill: rgb(134 239 172 / 0.8) !important;
}

.tw-fill-green-300\/85 {
  fill: rgb(134 239 172 / 0.85) !important;
}

.tw-fill-green-300\/90 {
  fill: rgb(134 239 172 / 0.9) !important;
}

.tw-fill-green-300\/95 {
  fill: rgb(134 239 172 / 0.95) !important;
}

.tw-fill-green-400 {
  fill: #4ade80 !important;
}

.tw-fill-green-400\/0 {
  fill: rgb(74 222 128 / 0) !important;
}

.tw-fill-green-400\/10 {
  fill: rgb(74 222 128 / 0.1) !important;
}

.tw-fill-green-400\/100 {
  fill: rgb(74 222 128 / 1) !important;
}

.tw-fill-green-400\/15 {
  fill: rgb(74 222 128 / 0.15) !important;
}

.tw-fill-green-400\/20 {
  fill: rgb(74 222 128 / 0.2) !important;
}

.tw-fill-green-400\/25 {
  fill: rgb(74 222 128 / 0.25) !important;
}

.tw-fill-green-400\/30 {
  fill: rgb(74 222 128 / 0.3) !important;
}

.tw-fill-green-400\/35 {
  fill: rgb(74 222 128 / 0.35) !important;
}

.tw-fill-green-400\/40 {
  fill: rgb(74 222 128 / 0.4) !important;
}

.tw-fill-green-400\/45 {
  fill: rgb(74 222 128 / 0.45) !important;
}

.tw-fill-green-400\/5 {
  fill: rgb(74 222 128 / 0.05) !important;
}

.tw-fill-green-400\/50 {
  fill: rgb(74 222 128 / 0.5) !important;
}

.tw-fill-green-400\/55 {
  fill: rgb(74 222 128 / 0.55) !important;
}

.tw-fill-green-400\/60 {
  fill: rgb(74 222 128 / 0.6) !important;
}

.tw-fill-green-400\/65 {
  fill: rgb(74 222 128 / 0.65) !important;
}

.tw-fill-green-400\/70 {
  fill: rgb(74 222 128 / 0.7) !important;
}

.tw-fill-green-400\/75 {
  fill: rgb(74 222 128 / 0.75) !important;
}

.tw-fill-green-400\/80 {
  fill: rgb(74 222 128 / 0.8) !important;
}

.tw-fill-green-400\/85 {
  fill: rgb(74 222 128 / 0.85) !important;
}

.tw-fill-green-400\/90 {
  fill: rgb(74 222 128 / 0.9) !important;
}

.tw-fill-green-400\/95 {
  fill: rgb(74 222 128 / 0.95) !important;
}

.tw-fill-green-50 {
  fill: #f0fdf4 !important;
}

.tw-fill-green-50\/0 {
  fill: rgb(240 253 244 / 0) !important;
}

.tw-fill-green-50\/10 {
  fill: rgb(240 253 244 / 0.1) !important;
}

.tw-fill-green-50\/100 {
  fill: rgb(240 253 244 / 1) !important;
}

.tw-fill-green-50\/15 {
  fill: rgb(240 253 244 / 0.15) !important;
}

.tw-fill-green-50\/20 {
  fill: rgb(240 253 244 / 0.2) !important;
}

.tw-fill-green-50\/25 {
  fill: rgb(240 253 244 / 0.25) !important;
}

.tw-fill-green-50\/30 {
  fill: rgb(240 253 244 / 0.3) !important;
}

.tw-fill-green-50\/35 {
  fill: rgb(240 253 244 / 0.35) !important;
}

.tw-fill-green-50\/40 {
  fill: rgb(240 253 244 / 0.4) !important;
}

.tw-fill-green-50\/45 {
  fill: rgb(240 253 244 / 0.45) !important;
}

.tw-fill-green-50\/5 {
  fill: rgb(240 253 244 / 0.05) !important;
}

.tw-fill-green-50\/50 {
  fill: rgb(240 253 244 / 0.5) !important;
}

.tw-fill-green-50\/55 {
  fill: rgb(240 253 244 / 0.55) !important;
}

.tw-fill-green-50\/60 {
  fill: rgb(240 253 244 / 0.6) !important;
}

.tw-fill-green-50\/65 {
  fill: rgb(240 253 244 / 0.65) !important;
}

.tw-fill-green-50\/70 {
  fill: rgb(240 253 244 / 0.7) !important;
}

.tw-fill-green-50\/75 {
  fill: rgb(240 253 244 / 0.75) !important;
}

.tw-fill-green-50\/80 {
  fill: rgb(240 253 244 / 0.8) !important;
}

.tw-fill-green-50\/85 {
  fill: rgb(240 253 244 / 0.85) !important;
}

.tw-fill-green-50\/90 {
  fill: rgb(240 253 244 / 0.9) !important;
}

.tw-fill-green-50\/95 {
  fill: rgb(240 253 244 / 0.95) !important;
}

.tw-fill-green-500 {
  fill: #22c55e !important;
}

.tw-fill-green-500\/0 {
  fill: rgb(34 197 94 / 0) !important;
}

.tw-fill-green-500\/10 {
  fill: rgb(34 197 94 / 0.1) !important;
}

.tw-fill-green-500\/100 {
  fill: rgb(34 197 94 / 1) !important;
}

.tw-fill-green-500\/15 {
  fill: rgb(34 197 94 / 0.15) !important;
}

.tw-fill-green-500\/20 {
  fill: rgb(34 197 94 / 0.2) !important;
}

.tw-fill-green-500\/25 {
  fill: rgb(34 197 94 / 0.25) !important;
}

.tw-fill-green-500\/30 {
  fill: rgb(34 197 94 / 0.3) !important;
}

.tw-fill-green-500\/35 {
  fill: rgb(34 197 94 / 0.35) !important;
}

.tw-fill-green-500\/40 {
  fill: rgb(34 197 94 / 0.4) !important;
}

.tw-fill-green-500\/45 {
  fill: rgb(34 197 94 / 0.45) !important;
}

.tw-fill-green-500\/5 {
  fill: rgb(34 197 94 / 0.05) !important;
}

.tw-fill-green-500\/50 {
  fill: rgb(34 197 94 / 0.5) !important;
}

.tw-fill-green-500\/55 {
  fill: rgb(34 197 94 / 0.55) !important;
}

.tw-fill-green-500\/60 {
  fill: rgb(34 197 94 / 0.6) !important;
}

.tw-fill-green-500\/65 {
  fill: rgb(34 197 94 / 0.65) !important;
}

.tw-fill-green-500\/70 {
  fill: rgb(34 197 94 / 0.7) !important;
}

.tw-fill-green-500\/75 {
  fill: rgb(34 197 94 / 0.75) !important;
}

.tw-fill-green-500\/80 {
  fill: rgb(34 197 94 / 0.8) !important;
}

.tw-fill-green-500\/85 {
  fill: rgb(34 197 94 / 0.85) !important;
}

.tw-fill-green-500\/90 {
  fill: rgb(34 197 94 / 0.9) !important;
}

.tw-fill-green-500\/95 {
  fill: rgb(34 197 94 / 0.95) !important;
}

.tw-fill-green-600 {
  fill: #16a34a !important;
}

.tw-fill-green-600\/0 {
  fill: rgb(22 163 74 / 0) !important;
}

.tw-fill-green-600\/10 {
  fill: rgb(22 163 74 / 0.1) !important;
}

.tw-fill-green-600\/100 {
  fill: rgb(22 163 74 / 1) !important;
}

.tw-fill-green-600\/15 {
  fill: rgb(22 163 74 / 0.15) !important;
}

.tw-fill-green-600\/20 {
  fill: rgb(22 163 74 / 0.2) !important;
}

.tw-fill-green-600\/25 {
  fill: rgb(22 163 74 / 0.25) !important;
}

.tw-fill-green-600\/30 {
  fill: rgb(22 163 74 / 0.3) !important;
}

.tw-fill-green-600\/35 {
  fill: rgb(22 163 74 / 0.35) !important;
}

.tw-fill-green-600\/40 {
  fill: rgb(22 163 74 / 0.4) !important;
}

.tw-fill-green-600\/45 {
  fill: rgb(22 163 74 / 0.45) !important;
}

.tw-fill-green-600\/5 {
  fill: rgb(22 163 74 / 0.05) !important;
}

.tw-fill-green-600\/50 {
  fill: rgb(22 163 74 / 0.5) !important;
}

.tw-fill-green-600\/55 {
  fill: rgb(22 163 74 / 0.55) !important;
}

.tw-fill-green-600\/60 {
  fill: rgb(22 163 74 / 0.6) !important;
}

.tw-fill-green-600\/65 {
  fill: rgb(22 163 74 / 0.65) !important;
}

.tw-fill-green-600\/70 {
  fill: rgb(22 163 74 / 0.7) !important;
}

.tw-fill-green-600\/75 {
  fill: rgb(22 163 74 / 0.75) !important;
}

.tw-fill-green-600\/80 {
  fill: rgb(22 163 74 / 0.8) !important;
}

.tw-fill-green-600\/85 {
  fill: rgb(22 163 74 / 0.85) !important;
}

.tw-fill-green-600\/90 {
  fill: rgb(22 163 74 / 0.9) !important;
}

.tw-fill-green-600\/95 {
  fill: rgb(22 163 74 / 0.95) !important;
}

.tw-fill-green-700 {
  fill: #15803d !important;
}

.tw-fill-green-700\/0 {
  fill: rgb(21 128 61 / 0) !important;
}

.tw-fill-green-700\/10 {
  fill: rgb(21 128 61 / 0.1) !important;
}

.tw-fill-green-700\/100 {
  fill: rgb(21 128 61 / 1) !important;
}

.tw-fill-green-700\/15 {
  fill: rgb(21 128 61 / 0.15) !important;
}

.tw-fill-green-700\/20 {
  fill: rgb(21 128 61 / 0.2) !important;
}

.tw-fill-green-700\/25 {
  fill: rgb(21 128 61 / 0.25) !important;
}

.tw-fill-green-700\/30 {
  fill: rgb(21 128 61 / 0.3) !important;
}

.tw-fill-green-700\/35 {
  fill: rgb(21 128 61 / 0.35) !important;
}

.tw-fill-green-700\/40 {
  fill: rgb(21 128 61 / 0.4) !important;
}

.tw-fill-green-700\/45 {
  fill: rgb(21 128 61 / 0.45) !important;
}

.tw-fill-green-700\/5 {
  fill: rgb(21 128 61 / 0.05) !important;
}

.tw-fill-green-700\/50 {
  fill: rgb(21 128 61 / 0.5) !important;
}

.tw-fill-green-700\/55 {
  fill: rgb(21 128 61 / 0.55) !important;
}

.tw-fill-green-700\/60 {
  fill: rgb(21 128 61 / 0.6) !important;
}

.tw-fill-green-700\/65 {
  fill: rgb(21 128 61 / 0.65) !important;
}

.tw-fill-green-700\/70 {
  fill: rgb(21 128 61 / 0.7) !important;
}

.tw-fill-green-700\/75 {
  fill: rgb(21 128 61 / 0.75) !important;
}

.tw-fill-green-700\/80 {
  fill: rgb(21 128 61 / 0.8) !important;
}

.tw-fill-green-700\/85 {
  fill: rgb(21 128 61 / 0.85) !important;
}

.tw-fill-green-700\/90 {
  fill: rgb(21 128 61 / 0.9) !important;
}

.tw-fill-green-700\/95 {
  fill: rgb(21 128 61 / 0.95) !important;
}

.tw-fill-green-800 {
  fill: #166534 !important;
}

.tw-fill-green-800\/0 {
  fill: rgb(22 101 52 / 0) !important;
}

.tw-fill-green-800\/10 {
  fill: rgb(22 101 52 / 0.1) !important;
}

.tw-fill-green-800\/100 {
  fill: rgb(22 101 52 / 1) !important;
}

.tw-fill-green-800\/15 {
  fill: rgb(22 101 52 / 0.15) !important;
}

.tw-fill-green-800\/20 {
  fill: rgb(22 101 52 / 0.2) !important;
}

.tw-fill-green-800\/25 {
  fill: rgb(22 101 52 / 0.25) !important;
}

.tw-fill-green-800\/30 {
  fill: rgb(22 101 52 / 0.3) !important;
}

.tw-fill-green-800\/35 {
  fill: rgb(22 101 52 / 0.35) !important;
}

.tw-fill-green-800\/40 {
  fill: rgb(22 101 52 / 0.4) !important;
}

.tw-fill-green-800\/45 {
  fill: rgb(22 101 52 / 0.45) !important;
}

.tw-fill-green-800\/5 {
  fill: rgb(22 101 52 / 0.05) !important;
}

.tw-fill-green-800\/50 {
  fill: rgb(22 101 52 / 0.5) !important;
}

.tw-fill-green-800\/55 {
  fill: rgb(22 101 52 / 0.55) !important;
}

.tw-fill-green-800\/60 {
  fill: rgb(22 101 52 / 0.6) !important;
}

.tw-fill-green-800\/65 {
  fill: rgb(22 101 52 / 0.65) !important;
}

.tw-fill-green-800\/70 {
  fill: rgb(22 101 52 / 0.7) !important;
}

.tw-fill-green-800\/75 {
  fill: rgb(22 101 52 / 0.75) !important;
}

.tw-fill-green-800\/80 {
  fill: rgb(22 101 52 / 0.8) !important;
}

.tw-fill-green-800\/85 {
  fill: rgb(22 101 52 / 0.85) !important;
}

.tw-fill-green-800\/90 {
  fill: rgb(22 101 52 / 0.9) !important;
}

.tw-fill-green-800\/95 {
  fill: rgb(22 101 52 / 0.95) !important;
}

.tw-fill-green-900 {
  fill: #14532d !important;
}

.tw-fill-green-900\/0 {
  fill: rgb(20 83 45 / 0) !important;
}

.tw-fill-green-900\/10 {
  fill: rgb(20 83 45 / 0.1) !important;
}

.tw-fill-green-900\/100 {
  fill: rgb(20 83 45 / 1) !important;
}

.tw-fill-green-900\/15 {
  fill: rgb(20 83 45 / 0.15) !important;
}

.tw-fill-green-900\/20 {
  fill: rgb(20 83 45 / 0.2) !important;
}

.tw-fill-green-900\/25 {
  fill: rgb(20 83 45 / 0.25) !important;
}

.tw-fill-green-900\/30 {
  fill: rgb(20 83 45 / 0.3) !important;
}

.tw-fill-green-900\/35 {
  fill: rgb(20 83 45 / 0.35) !important;
}

.tw-fill-green-900\/40 {
  fill: rgb(20 83 45 / 0.4) !important;
}

.tw-fill-green-900\/45 {
  fill: rgb(20 83 45 / 0.45) !important;
}

.tw-fill-green-900\/5 {
  fill: rgb(20 83 45 / 0.05) !important;
}

.tw-fill-green-900\/50 {
  fill: rgb(20 83 45 / 0.5) !important;
}

.tw-fill-green-900\/55 {
  fill: rgb(20 83 45 / 0.55) !important;
}

.tw-fill-green-900\/60 {
  fill: rgb(20 83 45 / 0.6) !important;
}

.tw-fill-green-900\/65 {
  fill: rgb(20 83 45 / 0.65) !important;
}

.tw-fill-green-900\/70 {
  fill: rgb(20 83 45 / 0.7) !important;
}

.tw-fill-green-900\/75 {
  fill: rgb(20 83 45 / 0.75) !important;
}

.tw-fill-green-900\/80 {
  fill: rgb(20 83 45 / 0.8) !important;
}

.tw-fill-green-900\/85 {
  fill: rgb(20 83 45 / 0.85) !important;
}

.tw-fill-green-900\/90 {
  fill: rgb(20 83 45 / 0.9) !important;
}

.tw-fill-green-900\/95 {
  fill: rgb(20 83 45 / 0.95) !important;
}

.tw-fill-green-950 {
  fill: #052e16 !important;
}

.tw-fill-green-950\/0 {
  fill: rgb(5 46 22 / 0) !important;
}

.tw-fill-green-950\/10 {
  fill: rgb(5 46 22 / 0.1) !important;
}

.tw-fill-green-950\/100 {
  fill: rgb(5 46 22 / 1) !important;
}

.tw-fill-green-950\/15 {
  fill: rgb(5 46 22 / 0.15) !important;
}

.tw-fill-green-950\/20 {
  fill: rgb(5 46 22 / 0.2) !important;
}

.tw-fill-green-950\/25 {
  fill: rgb(5 46 22 / 0.25) !important;
}

.tw-fill-green-950\/30 {
  fill: rgb(5 46 22 / 0.3) !important;
}

.tw-fill-green-950\/35 {
  fill: rgb(5 46 22 / 0.35) !important;
}

.tw-fill-green-950\/40 {
  fill: rgb(5 46 22 / 0.4) !important;
}

.tw-fill-green-950\/45 {
  fill: rgb(5 46 22 / 0.45) !important;
}

.tw-fill-green-950\/5 {
  fill: rgb(5 46 22 / 0.05) !important;
}

.tw-fill-green-950\/50 {
  fill: rgb(5 46 22 / 0.5) !important;
}

.tw-fill-green-950\/55 {
  fill: rgb(5 46 22 / 0.55) !important;
}

.tw-fill-green-950\/60 {
  fill: rgb(5 46 22 / 0.6) !important;
}

.tw-fill-green-950\/65 {
  fill: rgb(5 46 22 / 0.65) !important;
}

.tw-fill-green-950\/70 {
  fill: rgb(5 46 22 / 0.7) !important;
}

.tw-fill-green-950\/75 {
  fill: rgb(5 46 22 / 0.75) !important;
}

.tw-fill-green-950\/80 {
  fill: rgb(5 46 22 / 0.8) !important;
}

.tw-fill-green-950\/85 {
  fill: rgb(5 46 22 / 0.85) !important;
}

.tw-fill-green-950\/90 {
  fill: rgb(5 46 22 / 0.9) !important;
}

.tw-fill-green-950\/95 {
  fill: rgb(5 46 22 / 0.95) !important;
}

.tw-fill-indigo-100 {
  fill: #e0e7ff !important;
}

.tw-fill-indigo-100\/0 {
  fill: rgb(224 231 255 / 0) !important;
}

.tw-fill-indigo-100\/10 {
  fill: rgb(224 231 255 / 0.1) !important;
}

.tw-fill-indigo-100\/100 {
  fill: rgb(224 231 255 / 1) !important;
}

.tw-fill-indigo-100\/15 {
  fill: rgb(224 231 255 / 0.15) !important;
}

.tw-fill-indigo-100\/20 {
  fill: rgb(224 231 255 / 0.2) !important;
}

.tw-fill-indigo-100\/25 {
  fill: rgb(224 231 255 / 0.25) !important;
}

.tw-fill-indigo-100\/30 {
  fill: rgb(224 231 255 / 0.3) !important;
}

.tw-fill-indigo-100\/35 {
  fill: rgb(224 231 255 / 0.35) !important;
}

.tw-fill-indigo-100\/40 {
  fill: rgb(224 231 255 / 0.4) !important;
}

.tw-fill-indigo-100\/45 {
  fill: rgb(224 231 255 / 0.45) !important;
}

.tw-fill-indigo-100\/5 {
  fill: rgb(224 231 255 / 0.05) !important;
}

.tw-fill-indigo-100\/50 {
  fill: rgb(224 231 255 / 0.5) !important;
}

.tw-fill-indigo-100\/55 {
  fill: rgb(224 231 255 / 0.55) !important;
}

.tw-fill-indigo-100\/60 {
  fill: rgb(224 231 255 / 0.6) !important;
}

.tw-fill-indigo-100\/65 {
  fill: rgb(224 231 255 / 0.65) !important;
}

.tw-fill-indigo-100\/70 {
  fill: rgb(224 231 255 / 0.7) !important;
}

.tw-fill-indigo-100\/75 {
  fill: rgb(224 231 255 / 0.75) !important;
}

.tw-fill-indigo-100\/80 {
  fill: rgb(224 231 255 / 0.8) !important;
}

.tw-fill-indigo-100\/85 {
  fill: rgb(224 231 255 / 0.85) !important;
}

.tw-fill-indigo-100\/90 {
  fill: rgb(224 231 255 / 0.9) !important;
}

.tw-fill-indigo-100\/95 {
  fill: rgb(224 231 255 / 0.95) !important;
}

.tw-fill-indigo-200 {
  fill: #c7d2fe !important;
}

.tw-fill-indigo-200\/0 {
  fill: rgb(199 210 254 / 0) !important;
}

.tw-fill-indigo-200\/10 {
  fill: rgb(199 210 254 / 0.1) !important;
}

.tw-fill-indigo-200\/100 {
  fill: rgb(199 210 254 / 1) !important;
}

.tw-fill-indigo-200\/15 {
  fill: rgb(199 210 254 / 0.15) !important;
}

.tw-fill-indigo-200\/20 {
  fill: rgb(199 210 254 / 0.2) !important;
}

.tw-fill-indigo-200\/25 {
  fill: rgb(199 210 254 / 0.25) !important;
}

.tw-fill-indigo-200\/30 {
  fill: rgb(199 210 254 / 0.3) !important;
}

.tw-fill-indigo-200\/35 {
  fill: rgb(199 210 254 / 0.35) !important;
}

.tw-fill-indigo-200\/40 {
  fill: rgb(199 210 254 / 0.4) !important;
}

.tw-fill-indigo-200\/45 {
  fill: rgb(199 210 254 / 0.45) !important;
}

.tw-fill-indigo-200\/5 {
  fill: rgb(199 210 254 / 0.05) !important;
}

.tw-fill-indigo-200\/50 {
  fill: rgb(199 210 254 / 0.5) !important;
}

.tw-fill-indigo-200\/55 {
  fill: rgb(199 210 254 / 0.55) !important;
}

.tw-fill-indigo-200\/60 {
  fill: rgb(199 210 254 / 0.6) !important;
}

.tw-fill-indigo-200\/65 {
  fill: rgb(199 210 254 / 0.65) !important;
}

.tw-fill-indigo-200\/70 {
  fill: rgb(199 210 254 / 0.7) !important;
}

.tw-fill-indigo-200\/75 {
  fill: rgb(199 210 254 / 0.75) !important;
}

.tw-fill-indigo-200\/80 {
  fill: rgb(199 210 254 / 0.8) !important;
}

.tw-fill-indigo-200\/85 {
  fill: rgb(199 210 254 / 0.85) !important;
}

.tw-fill-indigo-200\/90 {
  fill: rgb(199 210 254 / 0.9) !important;
}

.tw-fill-indigo-200\/95 {
  fill: rgb(199 210 254 / 0.95) !important;
}

.tw-fill-indigo-300 {
  fill: #a5b4fc !important;
}

.tw-fill-indigo-300\/0 {
  fill: rgb(165 180 252 / 0) !important;
}

.tw-fill-indigo-300\/10 {
  fill: rgb(165 180 252 / 0.1) !important;
}

.tw-fill-indigo-300\/100 {
  fill: rgb(165 180 252 / 1) !important;
}

.tw-fill-indigo-300\/15 {
  fill: rgb(165 180 252 / 0.15) !important;
}

.tw-fill-indigo-300\/20 {
  fill: rgb(165 180 252 / 0.2) !important;
}

.tw-fill-indigo-300\/25 {
  fill: rgb(165 180 252 / 0.25) !important;
}

.tw-fill-indigo-300\/30 {
  fill: rgb(165 180 252 / 0.3) !important;
}

.tw-fill-indigo-300\/35 {
  fill: rgb(165 180 252 / 0.35) !important;
}

.tw-fill-indigo-300\/40 {
  fill: rgb(165 180 252 / 0.4) !important;
}

.tw-fill-indigo-300\/45 {
  fill: rgb(165 180 252 / 0.45) !important;
}

.tw-fill-indigo-300\/5 {
  fill: rgb(165 180 252 / 0.05) !important;
}

.tw-fill-indigo-300\/50 {
  fill: rgb(165 180 252 / 0.5) !important;
}

.tw-fill-indigo-300\/55 {
  fill: rgb(165 180 252 / 0.55) !important;
}

.tw-fill-indigo-300\/60 {
  fill: rgb(165 180 252 / 0.6) !important;
}

.tw-fill-indigo-300\/65 {
  fill: rgb(165 180 252 / 0.65) !important;
}

.tw-fill-indigo-300\/70 {
  fill: rgb(165 180 252 / 0.7) !important;
}

.tw-fill-indigo-300\/75 {
  fill: rgb(165 180 252 / 0.75) !important;
}

.tw-fill-indigo-300\/80 {
  fill: rgb(165 180 252 / 0.8) !important;
}

.tw-fill-indigo-300\/85 {
  fill: rgb(165 180 252 / 0.85) !important;
}

.tw-fill-indigo-300\/90 {
  fill: rgb(165 180 252 / 0.9) !important;
}

.tw-fill-indigo-300\/95 {
  fill: rgb(165 180 252 / 0.95) !important;
}

.tw-fill-indigo-400 {
  fill: #818cf8 !important;
}

.tw-fill-indigo-400\/0 {
  fill: rgb(129 140 248 / 0) !important;
}

.tw-fill-indigo-400\/10 {
  fill: rgb(129 140 248 / 0.1) !important;
}

.tw-fill-indigo-400\/100 {
  fill: rgb(129 140 248 / 1) !important;
}

.tw-fill-indigo-400\/15 {
  fill: rgb(129 140 248 / 0.15) !important;
}

.tw-fill-indigo-400\/20 {
  fill: rgb(129 140 248 / 0.2) !important;
}

.tw-fill-indigo-400\/25 {
  fill: rgb(129 140 248 / 0.25) !important;
}

.tw-fill-indigo-400\/30 {
  fill: rgb(129 140 248 / 0.3) !important;
}

.tw-fill-indigo-400\/35 {
  fill: rgb(129 140 248 / 0.35) !important;
}

.tw-fill-indigo-400\/40 {
  fill: rgb(129 140 248 / 0.4) !important;
}

.tw-fill-indigo-400\/45 {
  fill: rgb(129 140 248 / 0.45) !important;
}

.tw-fill-indigo-400\/5 {
  fill: rgb(129 140 248 / 0.05) !important;
}

.tw-fill-indigo-400\/50 {
  fill: rgb(129 140 248 / 0.5) !important;
}

.tw-fill-indigo-400\/55 {
  fill: rgb(129 140 248 / 0.55) !important;
}

.tw-fill-indigo-400\/60 {
  fill: rgb(129 140 248 / 0.6) !important;
}

.tw-fill-indigo-400\/65 {
  fill: rgb(129 140 248 / 0.65) !important;
}

.tw-fill-indigo-400\/70 {
  fill: rgb(129 140 248 / 0.7) !important;
}

.tw-fill-indigo-400\/75 {
  fill: rgb(129 140 248 / 0.75) !important;
}

.tw-fill-indigo-400\/80 {
  fill: rgb(129 140 248 / 0.8) !important;
}

.tw-fill-indigo-400\/85 {
  fill: rgb(129 140 248 / 0.85) !important;
}

.tw-fill-indigo-400\/90 {
  fill: rgb(129 140 248 / 0.9) !important;
}

.tw-fill-indigo-400\/95 {
  fill: rgb(129 140 248 / 0.95) !important;
}

.tw-fill-indigo-50 {
  fill: #eef2ff !important;
}

.tw-fill-indigo-50\/0 {
  fill: rgb(238 242 255 / 0) !important;
}

.tw-fill-indigo-50\/10 {
  fill: rgb(238 242 255 / 0.1) !important;
}

.tw-fill-indigo-50\/100 {
  fill: rgb(238 242 255 / 1) !important;
}

.tw-fill-indigo-50\/15 {
  fill: rgb(238 242 255 / 0.15) !important;
}

.tw-fill-indigo-50\/20 {
  fill: rgb(238 242 255 / 0.2) !important;
}

.tw-fill-indigo-50\/25 {
  fill: rgb(238 242 255 / 0.25) !important;
}

.tw-fill-indigo-50\/30 {
  fill: rgb(238 242 255 / 0.3) !important;
}

.tw-fill-indigo-50\/35 {
  fill: rgb(238 242 255 / 0.35) !important;
}

.tw-fill-indigo-50\/40 {
  fill: rgb(238 242 255 / 0.4) !important;
}

.tw-fill-indigo-50\/45 {
  fill: rgb(238 242 255 / 0.45) !important;
}

.tw-fill-indigo-50\/5 {
  fill: rgb(238 242 255 / 0.05) !important;
}

.tw-fill-indigo-50\/50 {
  fill: rgb(238 242 255 / 0.5) !important;
}

.tw-fill-indigo-50\/55 {
  fill: rgb(238 242 255 / 0.55) !important;
}

.tw-fill-indigo-50\/60 {
  fill: rgb(238 242 255 / 0.6) !important;
}

.tw-fill-indigo-50\/65 {
  fill: rgb(238 242 255 / 0.65) !important;
}

.tw-fill-indigo-50\/70 {
  fill: rgb(238 242 255 / 0.7) !important;
}

.tw-fill-indigo-50\/75 {
  fill: rgb(238 242 255 / 0.75) !important;
}

.tw-fill-indigo-50\/80 {
  fill: rgb(238 242 255 / 0.8) !important;
}

.tw-fill-indigo-50\/85 {
  fill: rgb(238 242 255 / 0.85) !important;
}

.tw-fill-indigo-50\/90 {
  fill: rgb(238 242 255 / 0.9) !important;
}

.tw-fill-indigo-50\/95 {
  fill: rgb(238 242 255 / 0.95) !important;
}

.tw-fill-indigo-500 {
  fill: #6366f1 !important;
}

.tw-fill-indigo-500\/0 {
  fill: rgb(99 102 241 / 0) !important;
}

.tw-fill-indigo-500\/10 {
  fill: rgb(99 102 241 / 0.1) !important;
}

.tw-fill-indigo-500\/100 {
  fill: rgb(99 102 241 / 1) !important;
}

.tw-fill-indigo-500\/15 {
  fill: rgb(99 102 241 / 0.15) !important;
}

.tw-fill-indigo-500\/20 {
  fill: rgb(99 102 241 / 0.2) !important;
}

.tw-fill-indigo-500\/25 {
  fill: rgb(99 102 241 / 0.25) !important;
}

.tw-fill-indigo-500\/30 {
  fill: rgb(99 102 241 / 0.3) !important;
}

.tw-fill-indigo-500\/35 {
  fill: rgb(99 102 241 / 0.35) !important;
}

.tw-fill-indigo-500\/40 {
  fill: rgb(99 102 241 / 0.4) !important;
}

.tw-fill-indigo-500\/45 {
  fill: rgb(99 102 241 / 0.45) !important;
}

.tw-fill-indigo-500\/5 {
  fill: rgb(99 102 241 / 0.05) !important;
}

.tw-fill-indigo-500\/50 {
  fill: rgb(99 102 241 / 0.5) !important;
}

.tw-fill-indigo-500\/55 {
  fill: rgb(99 102 241 / 0.55) !important;
}

.tw-fill-indigo-500\/60 {
  fill: rgb(99 102 241 / 0.6) !important;
}

.tw-fill-indigo-500\/65 {
  fill: rgb(99 102 241 / 0.65) !important;
}

.tw-fill-indigo-500\/70 {
  fill: rgb(99 102 241 / 0.7) !important;
}

.tw-fill-indigo-500\/75 {
  fill: rgb(99 102 241 / 0.75) !important;
}

.tw-fill-indigo-500\/80 {
  fill: rgb(99 102 241 / 0.8) !important;
}

.tw-fill-indigo-500\/85 {
  fill: rgb(99 102 241 / 0.85) !important;
}

.tw-fill-indigo-500\/90 {
  fill: rgb(99 102 241 / 0.9) !important;
}

.tw-fill-indigo-500\/95 {
  fill: rgb(99 102 241 / 0.95) !important;
}

.tw-fill-indigo-600 {
  fill: #4f46e5 !important;
}

.tw-fill-indigo-600\/0 {
  fill: rgb(79 70 229 / 0) !important;
}

.tw-fill-indigo-600\/10 {
  fill: rgb(79 70 229 / 0.1) !important;
}

.tw-fill-indigo-600\/100 {
  fill: rgb(79 70 229 / 1) !important;
}

.tw-fill-indigo-600\/15 {
  fill: rgb(79 70 229 / 0.15) !important;
}

.tw-fill-indigo-600\/20 {
  fill: rgb(79 70 229 / 0.2) !important;
}

.tw-fill-indigo-600\/25 {
  fill: rgb(79 70 229 / 0.25) !important;
}

.tw-fill-indigo-600\/30 {
  fill: rgb(79 70 229 / 0.3) !important;
}

.tw-fill-indigo-600\/35 {
  fill: rgb(79 70 229 / 0.35) !important;
}

.tw-fill-indigo-600\/40 {
  fill: rgb(79 70 229 / 0.4) !important;
}

.tw-fill-indigo-600\/45 {
  fill: rgb(79 70 229 / 0.45) !important;
}

.tw-fill-indigo-600\/5 {
  fill: rgb(79 70 229 / 0.05) !important;
}

.tw-fill-indigo-600\/50 {
  fill: rgb(79 70 229 / 0.5) !important;
}

.tw-fill-indigo-600\/55 {
  fill: rgb(79 70 229 / 0.55) !important;
}

.tw-fill-indigo-600\/60 {
  fill: rgb(79 70 229 / 0.6) !important;
}

.tw-fill-indigo-600\/65 {
  fill: rgb(79 70 229 / 0.65) !important;
}

.tw-fill-indigo-600\/70 {
  fill: rgb(79 70 229 / 0.7) !important;
}

.tw-fill-indigo-600\/75 {
  fill: rgb(79 70 229 / 0.75) !important;
}

.tw-fill-indigo-600\/80 {
  fill: rgb(79 70 229 / 0.8) !important;
}

.tw-fill-indigo-600\/85 {
  fill: rgb(79 70 229 / 0.85) !important;
}

.tw-fill-indigo-600\/90 {
  fill: rgb(79 70 229 / 0.9) !important;
}

.tw-fill-indigo-600\/95 {
  fill: rgb(79 70 229 / 0.95) !important;
}

.tw-fill-indigo-700 {
  fill: #4338ca !important;
}

.tw-fill-indigo-700\/0 {
  fill: rgb(67 56 202 / 0) !important;
}

.tw-fill-indigo-700\/10 {
  fill: rgb(67 56 202 / 0.1) !important;
}

.tw-fill-indigo-700\/100 {
  fill: rgb(67 56 202 / 1) !important;
}

.tw-fill-indigo-700\/15 {
  fill: rgb(67 56 202 / 0.15) !important;
}

.tw-fill-indigo-700\/20 {
  fill: rgb(67 56 202 / 0.2) !important;
}

.tw-fill-indigo-700\/25 {
  fill: rgb(67 56 202 / 0.25) !important;
}

.tw-fill-indigo-700\/30 {
  fill: rgb(67 56 202 / 0.3) !important;
}

.tw-fill-indigo-700\/35 {
  fill: rgb(67 56 202 / 0.35) !important;
}

.tw-fill-indigo-700\/40 {
  fill: rgb(67 56 202 / 0.4) !important;
}

.tw-fill-indigo-700\/45 {
  fill: rgb(67 56 202 / 0.45) !important;
}

.tw-fill-indigo-700\/5 {
  fill: rgb(67 56 202 / 0.05) !important;
}

.tw-fill-indigo-700\/50 {
  fill: rgb(67 56 202 / 0.5) !important;
}

.tw-fill-indigo-700\/55 {
  fill: rgb(67 56 202 / 0.55) !important;
}

.tw-fill-indigo-700\/60 {
  fill: rgb(67 56 202 / 0.6) !important;
}

.tw-fill-indigo-700\/65 {
  fill: rgb(67 56 202 / 0.65) !important;
}

.tw-fill-indigo-700\/70 {
  fill: rgb(67 56 202 / 0.7) !important;
}

.tw-fill-indigo-700\/75 {
  fill: rgb(67 56 202 / 0.75) !important;
}

.tw-fill-indigo-700\/80 {
  fill: rgb(67 56 202 / 0.8) !important;
}

.tw-fill-indigo-700\/85 {
  fill: rgb(67 56 202 / 0.85) !important;
}

.tw-fill-indigo-700\/90 {
  fill: rgb(67 56 202 / 0.9) !important;
}

.tw-fill-indigo-700\/95 {
  fill: rgb(67 56 202 / 0.95) !important;
}

.tw-fill-indigo-800 {
  fill: #3730a3 !important;
}

.tw-fill-indigo-800\/0 {
  fill: rgb(55 48 163 / 0) !important;
}

.tw-fill-indigo-800\/10 {
  fill: rgb(55 48 163 / 0.1) !important;
}

.tw-fill-indigo-800\/100 {
  fill: rgb(55 48 163 / 1) !important;
}

.tw-fill-indigo-800\/15 {
  fill: rgb(55 48 163 / 0.15) !important;
}

.tw-fill-indigo-800\/20 {
  fill: rgb(55 48 163 / 0.2) !important;
}

.tw-fill-indigo-800\/25 {
  fill: rgb(55 48 163 / 0.25) !important;
}

.tw-fill-indigo-800\/30 {
  fill: rgb(55 48 163 / 0.3) !important;
}

.tw-fill-indigo-800\/35 {
  fill: rgb(55 48 163 / 0.35) !important;
}

.tw-fill-indigo-800\/40 {
  fill: rgb(55 48 163 / 0.4) !important;
}

.tw-fill-indigo-800\/45 {
  fill: rgb(55 48 163 / 0.45) !important;
}

.tw-fill-indigo-800\/5 {
  fill: rgb(55 48 163 / 0.05) !important;
}

.tw-fill-indigo-800\/50 {
  fill: rgb(55 48 163 / 0.5) !important;
}

.tw-fill-indigo-800\/55 {
  fill: rgb(55 48 163 / 0.55) !important;
}

.tw-fill-indigo-800\/60 {
  fill: rgb(55 48 163 / 0.6) !important;
}

.tw-fill-indigo-800\/65 {
  fill: rgb(55 48 163 / 0.65) !important;
}

.tw-fill-indigo-800\/70 {
  fill: rgb(55 48 163 / 0.7) !important;
}

.tw-fill-indigo-800\/75 {
  fill: rgb(55 48 163 / 0.75) !important;
}

.tw-fill-indigo-800\/80 {
  fill: rgb(55 48 163 / 0.8) !important;
}

.tw-fill-indigo-800\/85 {
  fill: rgb(55 48 163 / 0.85) !important;
}

.tw-fill-indigo-800\/90 {
  fill: rgb(55 48 163 / 0.9) !important;
}

.tw-fill-indigo-800\/95 {
  fill: rgb(55 48 163 / 0.95) !important;
}

.tw-fill-indigo-900 {
  fill: #312e81 !important;
}

.tw-fill-indigo-900\/0 {
  fill: rgb(49 46 129 / 0) !important;
}

.tw-fill-indigo-900\/10 {
  fill: rgb(49 46 129 / 0.1) !important;
}

.tw-fill-indigo-900\/100 {
  fill: rgb(49 46 129 / 1) !important;
}

.tw-fill-indigo-900\/15 {
  fill: rgb(49 46 129 / 0.15) !important;
}

.tw-fill-indigo-900\/20 {
  fill: rgb(49 46 129 / 0.2) !important;
}

.tw-fill-indigo-900\/25 {
  fill: rgb(49 46 129 / 0.25) !important;
}

.tw-fill-indigo-900\/30 {
  fill: rgb(49 46 129 / 0.3) !important;
}

.tw-fill-indigo-900\/35 {
  fill: rgb(49 46 129 / 0.35) !important;
}

.tw-fill-indigo-900\/40 {
  fill: rgb(49 46 129 / 0.4) !important;
}

.tw-fill-indigo-900\/45 {
  fill: rgb(49 46 129 / 0.45) !important;
}

.tw-fill-indigo-900\/5 {
  fill: rgb(49 46 129 / 0.05) !important;
}

.tw-fill-indigo-900\/50 {
  fill: rgb(49 46 129 / 0.5) !important;
}

.tw-fill-indigo-900\/55 {
  fill: rgb(49 46 129 / 0.55) !important;
}

.tw-fill-indigo-900\/60 {
  fill: rgb(49 46 129 / 0.6) !important;
}

.tw-fill-indigo-900\/65 {
  fill: rgb(49 46 129 / 0.65) !important;
}

.tw-fill-indigo-900\/70 {
  fill: rgb(49 46 129 / 0.7) !important;
}

.tw-fill-indigo-900\/75 {
  fill: rgb(49 46 129 / 0.75) !important;
}

.tw-fill-indigo-900\/80 {
  fill: rgb(49 46 129 / 0.8) !important;
}

.tw-fill-indigo-900\/85 {
  fill: rgb(49 46 129 / 0.85) !important;
}

.tw-fill-indigo-900\/90 {
  fill: rgb(49 46 129 / 0.9) !important;
}

.tw-fill-indigo-900\/95 {
  fill: rgb(49 46 129 / 0.95) !important;
}

.tw-fill-indigo-950 {
  fill: #1e1b4b !important;
}

.tw-fill-indigo-950\/0 {
  fill: rgb(30 27 75 / 0) !important;
}

.tw-fill-indigo-950\/10 {
  fill: rgb(30 27 75 / 0.1) !important;
}

.tw-fill-indigo-950\/100 {
  fill: rgb(30 27 75 / 1) !important;
}

.tw-fill-indigo-950\/15 {
  fill: rgb(30 27 75 / 0.15) !important;
}

.tw-fill-indigo-950\/20 {
  fill: rgb(30 27 75 / 0.2) !important;
}

.tw-fill-indigo-950\/25 {
  fill: rgb(30 27 75 / 0.25) !important;
}

.tw-fill-indigo-950\/30 {
  fill: rgb(30 27 75 / 0.3) !important;
}

.tw-fill-indigo-950\/35 {
  fill: rgb(30 27 75 / 0.35) !important;
}

.tw-fill-indigo-950\/40 {
  fill: rgb(30 27 75 / 0.4) !important;
}

.tw-fill-indigo-950\/45 {
  fill: rgb(30 27 75 / 0.45) !important;
}

.tw-fill-indigo-950\/5 {
  fill: rgb(30 27 75 / 0.05) !important;
}

.tw-fill-indigo-950\/50 {
  fill: rgb(30 27 75 / 0.5) !important;
}

.tw-fill-indigo-950\/55 {
  fill: rgb(30 27 75 / 0.55) !important;
}

.tw-fill-indigo-950\/60 {
  fill: rgb(30 27 75 / 0.6) !important;
}

.tw-fill-indigo-950\/65 {
  fill: rgb(30 27 75 / 0.65) !important;
}

.tw-fill-indigo-950\/70 {
  fill: rgb(30 27 75 / 0.7) !important;
}

.tw-fill-indigo-950\/75 {
  fill: rgb(30 27 75 / 0.75) !important;
}

.tw-fill-indigo-950\/80 {
  fill: rgb(30 27 75 / 0.8) !important;
}

.tw-fill-indigo-950\/85 {
  fill: rgb(30 27 75 / 0.85) !important;
}

.tw-fill-indigo-950\/90 {
  fill: rgb(30 27 75 / 0.9) !important;
}

.tw-fill-indigo-950\/95 {
  fill: rgb(30 27 75 / 0.95) !important;
}

.tw-fill-inherit {
  fill: inherit !important;
}

.tw-fill-lime-100 {
  fill: #ecfccb !important;
}

.tw-fill-lime-100\/0 {
  fill: rgb(236 252 203 / 0) !important;
}

.tw-fill-lime-100\/10 {
  fill: rgb(236 252 203 / 0.1) !important;
}

.tw-fill-lime-100\/100 {
  fill: rgb(236 252 203 / 1) !important;
}

.tw-fill-lime-100\/15 {
  fill: rgb(236 252 203 / 0.15) !important;
}

.tw-fill-lime-100\/20 {
  fill: rgb(236 252 203 / 0.2) !important;
}

.tw-fill-lime-100\/25 {
  fill: rgb(236 252 203 / 0.25) !important;
}

.tw-fill-lime-100\/30 {
  fill: rgb(236 252 203 / 0.3) !important;
}

.tw-fill-lime-100\/35 {
  fill: rgb(236 252 203 / 0.35) !important;
}

.tw-fill-lime-100\/40 {
  fill: rgb(236 252 203 / 0.4) !important;
}

.tw-fill-lime-100\/45 {
  fill: rgb(236 252 203 / 0.45) !important;
}

.tw-fill-lime-100\/5 {
  fill: rgb(236 252 203 / 0.05) !important;
}

.tw-fill-lime-100\/50 {
  fill: rgb(236 252 203 / 0.5) !important;
}

.tw-fill-lime-100\/55 {
  fill: rgb(236 252 203 / 0.55) !important;
}

.tw-fill-lime-100\/60 {
  fill: rgb(236 252 203 / 0.6) !important;
}

.tw-fill-lime-100\/65 {
  fill: rgb(236 252 203 / 0.65) !important;
}

.tw-fill-lime-100\/70 {
  fill: rgb(236 252 203 / 0.7) !important;
}

.tw-fill-lime-100\/75 {
  fill: rgb(236 252 203 / 0.75) !important;
}

.tw-fill-lime-100\/80 {
  fill: rgb(236 252 203 / 0.8) !important;
}

.tw-fill-lime-100\/85 {
  fill: rgb(236 252 203 / 0.85) !important;
}

.tw-fill-lime-100\/90 {
  fill: rgb(236 252 203 / 0.9) !important;
}

.tw-fill-lime-100\/95 {
  fill: rgb(236 252 203 / 0.95) !important;
}

.tw-fill-lime-200 {
  fill: #d9f99d !important;
}

.tw-fill-lime-200\/0 {
  fill: rgb(217 249 157 / 0) !important;
}

.tw-fill-lime-200\/10 {
  fill: rgb(217 249 157 / 0.1) !important;
}

.tw-fill-lime-200\/100 {
  fill: rgb(217 249 157 / 1) !important;
}

.tw-fill-lime-200\/15 {
  fill: rgb(217 249 157 / 0.15) !important;
}

.tw-fill-lime-200\/20 {
  fill: rgb(217 249 157 / 0.2) !important;
}

.tw-fill-lime-200\/25 {
  fill: rgb(217 249 157 / 0.25) !important;
}

.tw-fill-lime-200\/30 {
  fill: rgb(217 249 157 / 0.3) !important;
}

.tw-fill-lime-200\/35 {
  fill: rgb(217 249 157 / 0.35) !important;
}

.tw-fill-lime-200\/40 {
  fill: rgb(217 249 157 / 0.4) !important;
}

.tw-fill-lime-200\/45 {
  fill: rgb(217 249 157 / 0.45) !important;
}

.tw-fill-lime-200\/5 {
  fill: rgb(217 249 157 / 0.05) !important;
}

.tw-fill-lime-200\/50 {
  fill: rgb(217 249 157 / 0.5) !important;
}

.tw-fill-lime-200\/55 {
  fill: rgb(217 249 157 / 0.55) !important;
}

.tw-fill-lime-200\/60 {
  fill: rgb(217 249 157 / 0.6) !important;
}

.tw-fill-lime-200\/65 {
  fill: rgb(217 249 157 / 0.65) !important;
}

.tw-fill-lime-200\/70 {
  fill: rgb(217 249 157 / 0.7) !important;
}

.tw-fill-lime-200\/75 {
  fill: rgb(217 249 157 / 0.75) !important;
}

.tw-fill-lime-200\/80 {
  fill: rgb(217 249 157 / 0.8) !important;
}

.tw-fill-lime-200\/85 {
  fill: rgb(217 249 157 / 0.85) !important;
}

.tw-fill-lime-200\/90 {
  fill: rgb(217 249 157 / 0.9) !important;
}

.tw-fill-lime-200\/95 {
  fill: rgb(217 249 157 / 0.95) !important;
}

.tw-fill-lime-300 {
  fill: #bef264 !important;
}

.tw-fill-lime-300\/0 {
  fill: rgb(190 242 100 / 0) !important;
}

.tw-fill-lime-300\/10 {
  fill: rgb(190 242 100 / 0.1) !important;
}

.tw-fill-lime-300\/100 {
  fill: rgb(190 242 100 / 1) !important;
}

.tw-fill-lime-300\/15 {
  fill: rgb(190 242 100 / 0.15) !important;
}

.tw-fill-lime-300\/20 {
  fill: rgb(190 242 100 / 0.2) !important;
}

.tw-fill-lime-300\/25 {
  fill: rgb(190 242 100 / 0.25) !important;
}

.tw-fill-lime-300\/30 {
  fill: rgb(190 242 100 / 0.3) !important;
}

.tw-fill-lime-300\/35 {
  fill: rgb(190 242 100 / 0.35) !important;
}

.tw-fill-lime-300\/40 {
  fill: rgb(190 242 100 / 0.4) !important;
}

.tw-fill-lime-300\/45 {
  fill: rgb(190 242 100 / 0.45) !important;
}

.tw-fill-lime-300\/5 {
  fill: rgb(190 242 100 / 0.05) !important;
}

.tw-fill-lime-300\/50 {
  fill: rgb(190 242 100 / 0.5) !important;
}

.tw-fill-lime-300\/55 {
  fill: rgb(190 242 100 / 0.55) !important;
}

.tw-fill-lime-300\/60 {
  fill: rgb(190 242 100 / 0.6) !important;
}

.tw-fill-lime-300\/65 {
  fill: rgb(190 242 100 / 0.65) !important;
}

.tw-fill-lime-300\/70 {
  fill: rgb(190 242 100 / 0.7) !important;
}

.tw-fill-lime-300\/75 {
  fill: rgb(190 242 100 / 0.75) !important;
}

.tw-fill-lime-300\/80 {
  fill: rgb(190 242 100 / 0.8) !important;
}

.tw-fill-lime-300\/85 {
  fill: rgb(190 242 100 / 0.85) !important;
}

.tw-fill-lime-300\/90 {
  fill: rgb(190 242 100 / 0.9) !important;
}

.tw-fill-lime-300\/95 {
  fill: rgb(190 242 100 / 0.95) !important;
}

.tw-fill-lime-400 {
  fill: #a3e635 !important;
}

.tw-fill-lime-400\/0 {
  fill: rgb(163 230 53 / 0) !important;
}

.tw-fill-lime-400\/10 {
  fill: rgb(163 230 53 / 0.1) !important;
}

.tw-fill-lime-400\/100 {
  fill: rgb(163 230 53 / 1) !important;
}

.tw-fill-lime-400\/15 {
  fill: rgb(163 230 53 / 0.15) !important;
}

.tw-fill-lime-400\/20 {
  fill: rgb(163 230 53 / 0.2) !important;
}

.tw-fill-lime-400\/25 {
  fill: rgb(163 230 53 / 0.25) !important;
}

.tw-fill-lime-400\/30 {
  fill: rgb(163 230 53 / 0.3) !important;
}

.tw-fill-lime-400\/35 {
  fill: rgb(163 230 53 / 0.35) !important;
}

.tw-fill-lime-400\/40 {
  fill: rgb(163 230 53 / 0.4) !important;
}

.tw-fill-lime-400\/45 {
  fill: rgb(163 230 53 / 0.45) !important;
}

.tw-fill-lime-400\/5 {
  fill: rgb(163 230 53 / 0.05) !important;
}

.tw-fill-lime-400\/50 {
  fill: rgb(163 230 53 / 0.5) !important;
}

.tw-fill-lime-400\/55 {
  fill: rgb(163 230 53 / 0.55) !important;
}

.tw-fill-lime-400\/60 {
  fill: rgb(163 230 53 / 0.6) !important;
}

.tw-fill-lime-400\/65 {
  fill: rgb(163 230 53 / 0.65) !important;
}

.tw-fill-lime-400\/70 {
  fill: rgb(163 230 53 / 0.7) !important;
}

.tw-fill-lime-400\/75 {
  fill: rgb(163 230 53 / 0.75) !important;
}

.tw-fill-lime-400\/80 {
  fill: rgb(163 230 53 / 0.8) !important;
}

.tw-fill-lime-400\/85 {
  fill: rgb(163 230 53 / 0.85) !important;
}

.tw-fill-lime-400\/90 {
  fill: rgb(163 230 53 / 0.9) !important;
}

.tw-fill-lime-400\/95 {
  fill: rgb(163 230 53 / 0.95) !important;
}

.tw-fill-lime-50 {
  fill: #f7fee7 !important;
}

.tw-fill-lime-50\/0 {
  fill: rgb(247 254 231 / 0) !important;
}

.tw-fill-lime-50\/10 {
  fill: rgb(247 254 231 / 0.1) !important;
}

.tw-fill-lime-50\/100 {
  fill: rgb(247 254 231 / 1) !important;
}

.tw-fill-lime-50\/15 {
  fill: rgb(247 254 231 / 0.15) !important;
}

.tw-fill-lime-50\/20 {
  fill: rgb(247 254 231 / 0.2) !important;
}

.tw-fill-lime-50\/25 {
  fill: rgb(247 254 231 / 0.25) !important;
}

.tw-fill-lime-50\/30 {
  fill: rgb(247 254 231 / 0.3) !important;
}

.tw-fill-lime-50\/35 {
  fill: rgb(247 254 231 / 0.35) !important;
}

.tw-fill-lime-50\/40 {
  fill: rgb(247 254 231 / 0.4) !important;
}

.tw-fill-lime-50\/45 {
  fill: rgb(247 254 231 / 0.45) !important;
}

.tw-fill-lime-50\/5 {
  fill: rgb(247 254 231 / 0.05) !important;
}

.tw-fill-lime-50\/50 {
  fill: rgb(247 254 231 / 0.5) !important;
}

.tw-fill-lime-50\/55 {
  fill: rgb(247 254 231 / 0.55) !important;
}

.tw-fill-lime-50\/60 {
  fill: rgb(247 254 231 / 0.6) !important;
}

.tw-fill-lime-50\/65 {
  fill: rgb(247 254 231 / 0.65) !important;
}

.tw-fill-lime-50\/70 {
  fill: rgb(247 254 231 / 0.7) !important;
}

.tw-fill-lime-50\/75 {
  fill: rgb(247 254 231 / 0.75) !important;
}

.tw-fill-lime-50\/80 {
  fill: rgb(247 254 231 / 0.8) !important;
}

.tw-fill-lime-50\/85 {
  fill: rgb(247 254 231 / 0.85) !important;
}

.tw-fill-lime-50\/90 {
  fill: rgb(247 254 231 / 0.9) !important;
}

.tw-fill-lime-50\/95 {
  fill: rgb(247 254 231 / 0.95) !important;
}

.tw-fill-lime-500 {
  fill: #84cc16 !important;
}

.tw-fill-lime-500\/0 {
  fill: rgb(132 204 22 / 0) !important;
}

.tw-fill-lime-500\/10 {
  fill: rgb(132 204 22 / 0.1) !important;
}

.tw-fill-lime-500\/100 {
  fill: rgb(132 204 22 / 1) !important;
}

.tw-fill-lime-500\/15 {
  fill: rgb(132 204 22 / 0.15) !important;
}

.tw-fill-lime-500\/20 {
  fill: rgb(132 204 22 / 0.2) !important;
}

.tw-fill-lime-500\/25 {
  fill: rgb(132 204 22 / 0.25) !important;
}

.tw-fill-lime-500\/30 {
  fill: rgb(132 204 22 / 0.3) !important;
}

.tw-fill-lime-500\/35 {
  fill: rgb(132 204 22 / 0.35) !important;
}

.tw-fill-lime-500\/40 {
  fill: rgb(132 204 22 / 0.4) !important;
}

.tw-fill-lime-500\/45 {
  fill: rgb(132 204 22 / 0.45) !important;
}

.tw-fill-lime-500\/5 {
  fill: rgb(132 204 22 / 0.05) !important;
}

.tw-fill-lime-500\/50 {
  fill: rgb(132 204 22 / 0.5) !important;
}

.tw-fill-lime-500\/55 {
  fill: rgb(132 204 22 / 0.55) !important;
}

.tw-fill-lime-500\/60 {
  fill: rgb(132 204 22 / 0.6) !important;
}

.tw-fill-lime-500\/65 {
  fill: rgb(132 204 22 / 0.65) !important;
}

.tw-fill-lime-500\/70 {
  fill: rgb(132 204 22 / 0.7) !important;
}

.tw-fill-lime-500\/75 {
  fill: rgb(132 204 22 / 0.75) !important;
}

.tw-fill-lime-500\/80 {
  fill: rgb(132 204 22 / 0.8) !important;
}

.tw-fill-lime-500\/85 {
  fill: rgb(132 204 22 / 0.85) !important;
}

.tw-fill-lime-500\/90 {
  fill: rgb(132 204 22 / 0.9) !important;
}

.tw-fill-lime-500\/95 {
  fill: rgb(132 204 22 / 0.95) !important;
}

.tw-fill-lime-600 {
  fill: #65a30d !important;
}

.tw-fill-lime-600\/0 {
  fill: rgb(101 163 13 / 0) !important;
}

.tw-fill-lime-600\/10 {
  fill: rgb(101 163 13 / 0.1) !important;
}

.tw-fill-lime-600\/100 {
  fill: rgb(101 163 13 / 1) !important;
}

.tw-fill-lime-600\/15 {
  fill: rgb(101 163 13 / 0.15) !important;
}

.tw-fill-lime-600\/20 {
  fill: rgb(101 163 13 / 0.2) !important;
}

.tw-fill-lime-600\/25 {
  fill: rgb(101 163 13 / 0.25) !important;
}

.tw-fill-lime-600\/30 {
  fill: rgb(101 163 13 / 0.3) !important;
}

.tw-fill-lime-600\/35 {
  fill: rgb(101 163 13 / 0.35) !important;
}

.tw-fill-lime-600\/40 {
  fill: rgb(101 163 13 / 0.4) !important;
}

.tw-fill-lime-600\/45 {
  fill: rgb(101 163 13 / 0.45) !important;
}

.tw-fill-lime-600\/5 {
  fill: rgb(101 163 13 / 0.05) !important;
}

.tw-fill-lime-600\/50 {
  fill: rgb(101 163 13 / 0.5) !important;
}

.tw-fill-lime-600\/55 {
  fill: rgb(101 163 13 / 0.55) !important;
}

.tw-fill-lime-600\/60 {
  fill: rgb(101 163 13 / 0.6) !important;
}

.tw-fill-lime-600\/65 {
  fill: rgb(101 163 13 / 0.65) !important;
}

.tw-fill-lime-600\/70 {
  fill: rgb(101 163 13 / 0.7) !important;
}

.tw-fill-lime-600\/75 {
  fill: rgb(101 163 13 / 0.75) !important;
}

.tw-fill-lime-600\/80 {
  fill: rgb(101 163 13 / 0.8) !important;
}

.tw-fill-lime-600\/85 {
  fill: rgb(101 163 13 / 0.85) !important;
}

.tw-fill-lime-600\/90 {
  fill: rgb(101 163 13 / 0.9) !important;
}

.tw-fill-lime-600\/95 {
  fill: rgb(101 163 13 / 0.95) !important;
}

.tw-fill-lime-700 {
  fill: #4d7c0f !important;
}

.tw-fill-lime-700\/0 {
  fill: rgb(77 124 15 / 0) !important;
}

.tw-fill-lime-700\/10 {
  fill: rgb(77 124 15 / 0.1) !important;
}

.tw-fill-lime-700\/100 {
  fill: rgb(77 124 15 / 1) !important;
}

.tw-fill-lime-700\/15 {
  fill: rgb(77 124 15 / 0.15) !important;
}

.tw-fill-lime-700\/20 {
  fill: rgb(77 124 15 / 0.2) !important;
}

.tw-fill-lime-700\/25 {
  fill: rgb(77 124 15 / 0.25) !important;
}

.tw-fill-lime-700\/30 {
  fill: rgb(77 124 15 / 0.3) !important;
}

.tw-fill-lime-700\/35 {
  fill: rgb(77 124 15 / 0.35) !important;
}

.tw-fill-lime-700\/40 {
  fill: rgb(77 124 15 / 0.4) !important;
}

.tw-fill-lime-700\/45 {
  fill: rgb(77 124 15 / 0.45) !important;
}

.tw-fill-lime-700\/5 {
  fill: rgb(77 124 15 / 0.05) !important;
}

.tw-fill-lime-700\/50 {
  fill: rgb(77 124 15 / 0.5) !important;
}

.tw-fill-lime-700\/55 {
  fill: rgb(77 124 15 / 0.55) !important;
}

.tw-fill-lime-700\/60 {
  fill: rgb(77 124 15 / 0.6) !important;
}

.tw-fill-lime-700\/65 {
  fill: rgb(77 124 15 / 0.65) !important;
}

.tw-fill-lime-700\/70 {
  fill: rgb(77 124 15 / 0.7) !important;
}

.tw-fill-lime-700\/75 {
  fill: rgb(77 124 15 / 0.75) !important;
}

.tw-fill-lime-700\/80 {
  fill: rgb(77 124 15 / 0.8) !important;
}

.tw-fill-lime-700\/85 {
  fill: rgb(77 124 15 / 0.85) !important;
}

.tw-fill-lime-700\/90 {
  fill: rgb(77 124 15 / 0.9) !important;
}

.tw-fill-lime-700\/95 {
  fill: rgb(77 124 15 / 0.95) !important;
}

.tw-fill-lime-800 {
  fill: #3f6212 !important;
}

.tw-fill-lime-800\/0 {
  fill: rgb(63 98 18 / 0) !important;
}

.tw-fill-lime-800\/10 {
  fill: rgb(63 98 18 / 0.1) !important;
}

.tw-fill-lime-800\/100 {
  fill: rgb(63 98 18 / 1) !important;
}

.tw-fill-lime-800\/15 {
  fill: rgb(63 98 18 / 0.15) !important;
}

.tw-fill-lime-800\/20 {
  fill: rgb(63 98 18 / 0.2) !important;
}

.tw-fill-lime-800\/25 {
  fill: rgb(63 98 18 / 0.25) !important;
}

.tw-fill-lime-800\/30 {
  fill: rgb(63 98 18 / 0.3) !important;
}

.tw-fill-lime-800\/35 {
  fill: rgb(63 98 18 / 0.35) !important;
}

.tw-fill-lime-800\/40 {
  fill: rgb(63 98 18 / 0.4) !important;
}

.tw-fill-lime-800\/45 {
  fill: rgb(63 98 18 / 0.45) !important;
}

.tw-fill-lime-800\/5 {
  fill: rgb(63 98 18 / 0.05) !important;
}

.tw-fill-lime-800\/50 {
  fill: rgb(63 98 18 / 0.5) !important;
}

.tw-fill-lime-800\/55 {
  fill: rgb(63 98 18 / 0.55) !important;
}

.tw-fill-lime-800\/60 {
  fill: rgb(63 98 18 / 0.6) !important;
}

.tw-fill-lime-800\/65 {
  fill: rgb(63 98 18 / 0.65) !important;
}

.tw-fill-lime-800\/70 {
  fill: rgb(63 98 18 / 0.7) !important;
}

.tw-fill-lime-800\/75 {
  fill: rgb(63 98 18 / 0.75) !important;
}

.tw-fill-lime-800\/80 {
  fill: rgb(63 98 18 / 0.8) !important;
}

.tw-fill-lime-800\/85 {
  fill: rgb(63 98 18 / 0.85) !important;
}

.tw-fill-lime-800\/90 {
  fill: rgb(63 98 18 / 0.9) !important;
}

.tw-fill-lime-800\/95 {
  fill: rgb(63 98 18 / 0.95) !important;
}

.tw-fill-lime-900 {
  fill: #365314 !important;
}

.tw-fill-lime-900\/0 {
  fill: rgb(54 83 20 / 0) !important;
}

.tw-fill-lime-900\/10 {
  fill: rgb(54 83 20 / 0.1) !important;
}

.tw-fill-lime-900\/100 {
  fill: rgb(54 83 20 / 1) !important;
}

.tw-fill-lime-900\/15 {
  fill: rgb(54 83 20 / 0.15) !important;
}

.tw-fill-lime-900\/20 {
  fill: rgb(54 83 20 / 0.2) !important;
}

.tw-fill-lime-900\/25 {
  fill: rgb(54 83 20 / 0.25) !important;
}

.tw-fill-lime-900\/30 {
  fill: rgb(54 83 20 / 0.3) !important;
}

.tw-fill-lime-900\/35 {
  fill: rgb(54 83 20 / 0.35) !important;
}

.tw-fill-lime-900\/40 {
  fill: rgb(54 83 20 / 0.4) !important;
}

.tw-fill-lime-900\/45 {
  fill: rgb(54 83 20 / 0.45) !important;
}

.tw-fill-lime-900\/5 {
  fill: rgb(54 83 20 / 0.05) !important;
}

.tw-fill-lime-900\/50 {
  fill: rgb(54 83 20 / 0.5) !important;
}

.tw-fill-lime-900\/55 {
  fill: rgb(54 83 20 / 0.55) !important;
}

.tw-fill-lime-900\/60 {
  fill: rgb(54 83 20 / 0.6) !important;
}

.tw-fill-lime-900\/65 {
  fill: rgb(54 83 20 / 0.65) !important;
}

.tw-fill-lime-900\/70 {
  fill: rgb(54 83 20 / 0.7) !important;
}

.tw-fill-lime-900\/75 {
  fill: rgb(54 83 20 / 0.75) !important;
}

.tw-fill-lime-900\/80 {
  fill: rgb(54 83 20 / 0.8) !important;
}

.tw-fill-lime-900\/85 {
  fill: rgb(54 83 20 / 0.85) !important;
}

.tw-fill-lime-900\/90 {
  fill: rgb(54 83 20 / 0.9) !important;
}

.tw-fill-lime-900\/95 {
  fill: rgb(54 83 20 / 0.95) !important;
}

.tw-fill-lime-950 {
  fill: #1a2e05 !important;
}

.tw-fill-lime-950\/0 {
  fill: rgb(26 46 5 / 0) !important;
}

.tw-fill-lime-950\/10 {
  fill: rgb(26 46 5 / 0.1) !important;
}

.tw-fill-lime-950\/100 {
  fill: rgb(26 46 5 / 1) !important;
}

.tw-fill-lime-950\/15 {
  fill: rgb(26 46 5 / 0.15) !important;
}

.tw-fill-lime-950\/20 {
  fill: rgb(26 46 5 / 0.2) !important;
}

.tw-fill-lime-950\/25 {
  fill: rgb(26 46 5 / 0.25) !important;
}

.tw-fill-lime-950\/30 {
  fill: rgb(26 46 5 / 0.3) !important;
}

.tw-fill-lime-950\/35 {
  fill: rgb(26 46 5 / 0.35) !important;
}

.tw-fill-lime-950\/40 {
  fill: rgb(26 46 5 / 0.4) !important;
}

.tw-fill-lime-950\/45 {
  fill: rgb(26 46 5 / 0.45) !important;
}

.tw-fill-lime-950\/5 {
  fill: rgb(26 46 5 / 0.05) !important;
}

.tw-fill-lime-950\/50 {
  fill: rgb(26 46 5 / 0.5) !important;
}

.tw-fill-lime-950\/55 {
  fill: rgb(26 46 5 / 0.55) !important;
}

.tw-fill-lime-950\/60 {
  fill: rgb(26 46 5 / 0.6) !important;
}

.tw-fill-lime-950\/65 {
  fill: rgb(26 46 5 / 0.65) !important;
}

.tw-fill-lime-950\/70 {
  fill: rgb(26 46 5 / 0.7) !important;
}

.tw-fill-lime-950\/75 {
  fill: rgb(26 46 5 / 0.75) !important;
}

.tw-fill-lime-950\/80 {
  fill: rgb(26 46 5 / 0.8) !important;
}

.tw-fill-lime-950\/85 {
  fill: rgb(26 46 5 / 0.85) !important;
}

.tw-fill-lime-950\/90 {
  fill: rgb(26 46 5 / 0.9) !important;
}

.tw-fill-lime-950\/95 {
  fill: rgb(26 46 5 / 0.95) !important;
}

.tw-fill-neutral-100 {
  fill: #f5f5f5 !important;
}

.tw-fill-neutral-100\/0 {
  fill: rgb(245 245 245 / 0) !important;
}

.tw-fill-neutral-100\/10 {
  fill: rgb(245 245 245 / 0.1) !important;
}

.tw-fill-neutral-100\/100 {
  fill: rgb(245 245 245 / 1) !important;
}

.tw-fill-neutral-100\/15 {
  fill: rgb(245 245 245 / 0.15) !important;
}

.tw-fill-neutral-100\/20 {
  fill: rgb(245 245 245 / 0.2) !important;
}

.tw-fill-neutral-100\/25 {
  fill: rgb(245 245 245 / 0.25) !important;
}

.tw-fill-neutral-100\/30 {
  fill: rgb(245 245 245 / 0.3) !important;
}

.tw-fill-neutral-100\/35 {
  fill: rgb(245 245 245 / 0.35) !important;
}

.tw-fill-neutral-100\/40 {
  fill: rgb(245 245 245 / 0.4) !important;
}

.tw-fill-neutral-100\/45 {
  fill: rgb(245 245 245 / 0.45) !important;
}

.tw-fill-neutral-100\/5 {
  fill: rgb(245 245 245 / 0.05) !important;
}

.tw-fill-neutral-100\/50 {
  fill: rgb(245 245 245 / 0.5) !important;
}

.tw-fill-neutral-100\/55 {
  fill: rgb(245 245 245 / 0.55) !important;
}

.tw-fill-neutral-100\/60 {
  fill: rgb(245 245 245 / 0.6) !important;
}

.tw-fill-neutral-100\/65 {
  fill: rgb(245 245 245 / 0.65) !important;
}

.tw-fill-neutral-100\/70 {
  fill: rgb(245 245 245 / 0.7) !important;
}

.tw-fill-neutral-100\/75 {
  fill: rgb(245 245 245 / 0.75) !important;
}

.tw-fill-neutral-100\/80 {
  fill: rgb(245 245 245 / 0.8) !important;
}

.tw-fill-neutral-100\/85 {
  fill: rgb(245 245 245 / 0.85) !important;
}

.tw-fill-neutral-100\/90 {
  fill: rgb(245 245 245 / 0.9) !important;
}

.tw-fill-neutral-100\/95 {
  fill: rgb(245 245 245 / 0.95) !important;
}

.tw-fill-neutral-200 {
  fill: #e5e5e5 !important;
}

.tw-fill-neutral-200\/0 {
  fill: rgb(229 229 229 / 0) !important;
}

.tw-fill-neutral-200\/10 {
  fill: rgb(229 229 229 / 0.1) !important;
}

.tw-fill-neutral-200\/100 {
  fill: rgb(229 229 229 / 1) !important;
}

.tw-fill-neutral-200\/15 {
  fill: rgb(229 229 229 / 0.15) !important;
}

.tw-fill-neutral-200\/20 {
  fill: rgb(229 229 229 / 0.2) !important;
}

.tw-fill-neutral-200\/25 {
  fill: rgb(229 229 229 / 0.25) !important;
}

.tw-fill-neutral-200\/30 {
  fill: rgb(229 229 229 / 0.3) !important;
}

.tw-fill-neutral-200\/35 {
  fill: rgb(229 229 229 / 0.35) !important;
}

.tw-fill-neutral-200\/40 {
  fill: rgb(229 229 229 / 0.4) !important;
}

.tw-fill-neutral-200\/45 {
  fill: rgb(229 229 229 / 0.45) !important;
}

.tw-fill-neutral-200\/5 {
  fill: rgb(229 229 229 / 0.05) !important;
}

.tw-fill-neutral-200\/50 {
  fill: rgb(229 229 229 / 0.5) !important;
}

.tw-fill-neutral-200\/55 {
  fill: rgb(229 229 229 / 0.55) !important;
}

.tw-fill-neutral-200\/60 {
  fill: rgb(229 229 229 / 0.6) !important;
}

.tw-fill-neutral-200\/65 {
  fill: rgb(229 229 229 / 0.65) !important;
}

.tw-fill-neutral-200\/70 {
  fill: rgb(229 229 229 / 0.7) !important;
}

.tw-fill-neutral-200\/75 {
  fill: rgb(229 229 229 / 0.75) !important;
}

.tw-fill-neutral-200\/80 {
  fill: rgb(229 229 229 / 0.8) !important;
}

.tw-fill-neutral-200\/85 {
  fill: rgb(229 229 229 / 0.85) !important;
}

.tw-fill-neutral-200\/90 {
  fill: rgb(229 229 229 / 0.9) !important;
}

.tw-fill-neutral-200\/95 {
  fill: rgb(229 229 229 / 0.95) !important;
}

.tw-fill-neutral-300 {
  fill: #d4d4d4 !important;
}

.tw-fill-neutral-300\/0 {
  fill: rgb(212 212 212 / 0) !important;
}

.tw-fill-neutral-300\/10 {
  fill: rgb(212 212 212 / 0.1) !important;
}

.tw-fill-neutral-300\/100 {
  fill: rgb(212 212 212 / 1) !important;
}

.tw-fill-neutral-300\/15 {
  fill: rgb(212 212 212 / 0.15) !important;
}

.tw-fill-neutral-300\/20 {
  fill: rgb(212 212 212 / 0.2) !important;
}

.tw-fill-neutral-300\/25 {
  fill: rgb(212 212 212 / 0.25) !important;
}

.tw-fill-neutral-300\/30 {
  fill: rgb(212 212 212 / 0.3) !important;
}

.tw-fill-neutral-300\/35 {
  fill: rgb(212 212 212 / 0.35) !important;
}

.tw-fill-neutral-300\/40 {
  fill: rgb(212 212 212 / 0.4) !important;
}

.tw-fill-neutral-300\/45 {
  fill: rgb(212 212 212 / 0.45) !important;
}

.tw-fill-neutral-300\/5 {
  fill: rgb(212 212 212 / 0.05) !important;
}

.tw-fill-neutral-300\/50 {
  fill: rgb(212 212 212 / 0.5) !important;
}

.tw-fill-neutral-300\/55 {
  fill: rgb(212 212 212 / 0.55) !important;
}

.tw-fill-neutral-300\/60 {
  fill: rgb(212 212 212 / 0.6) !important;
}

.tw-fill-neutral-300\/65 {
  fill: rgb(212 212 212 / 0.65) !important;
}

.tw-fill-neutral-300\/70 {
  fill: rgb(212 212 212 / 0.7) !important;
}

.tw-fill-neutral-300\/75 {
  fill: rgb(212 212 212 / 0.75) !important;
}

.tw-fill-neutral-300\/80 {
  fill: rgb(212 212 212 / 0.8) !important;
}

.tw-fill-neutral-300\/85 {
  fill: rgb(212 212 212 / 0.85) !important;
}

.tw-fill-neutral-300\/90 {
  fill: rgb(212 212 212 / 0.9) !important;
}

.tw-fill-neutral-300\/95 {
  fill: rgb(212 212 212 / 0.95) !important;
}

.tw-fill-neutral-400 {
  fill: #a3a3a3 !important;
}

.tw-fill-neutral-400\/0 {
  fill: rgb(163 163 163 / 0) !important;
}

.tw-fill-neutral-400\/10 {
  fill: rgb(163 163 163 / 0.1) !important;
}

.tw-fill-neutral-400\/100 {
  fill: rgb(163 163 163 / 1) !important;
}

.tw-fill-neutral-400\/15 {
  fill: rgb(163 163 163 / 0.15) !important;
}

.tw-fill-neutral-400\/20 {
  fill: rgb(163 163 163 / 0.2) !important;
}

.tw-fill-neutral-400\/25 {
  fill: rgb(163 163 163 / 0.25) !important;
}

.tw-fill-neutral-400\/30 {
  fill: rgb(163 163 163 / 0.3) !important;
}

.tw-fill-neutral-400\/35 {
  fill: rgb(163 163 163 / 0.35) !important;
}

.tw-fill-neutral-400\/40 {
  fill: rgb(163 163 163 / 0.4) !important;
}

.tw-fill-neutral-400\/45 {
  fill: rgb(163 163 163 / 0.45) !important;
}

.tw-fill-neutral-400\/5 {
  fill: rgb(163 163 163 / 0.05) !important;
}

.tw-fill-neutral-400\/50 {
  fill: rgb(163 163 163 / 0.5) !important;
}

.tw-fill-neutral-400\/55 {
  fill: rgb(163 163 163 / 0.55) !important;
}

.tw-fill-neutral-400\/60 {
  fill: rgb(163 163 163 / 0.6) !important;
}

.tw-fill-neutral-400\/65 {
  fill: rgb(163 163 163 / 0.65) !important;
}

.tw-fill-neutral-400\/70 {
  fill: rgb(163 163 163 / 0.7) !important;
}

.tw-fill-neutral-400\/75 {
  fill: rgb(163 163 163 / 0.75) !important;
}

.tw-fill-neutral-400\/80 {
  fill: rgb(163 163 163 / 0.8) !important;
}

.tw-fill-neutral-400\/85 {
  fill: rgb(163 163 163 / 0.85) !important;
}

.tw-fill-neutral-400\/90 {
  fill: rgb(163 163 163 / 0.9) !important;
}

.tw-fill-neutral-400\/95 {
  fill: rgb(163 163 163 / 0.95) !important;
}

.tw-fill-neutral-50 {
  fill: #fafafa !important;
}

.tw-fill-neutral-50\/0 {
  fill: rgb(250 250 250 / 0) !important;
}

.tw-fill-neutral-50\/10 {
  fill: rgb(250 250 250 / 0.1) !important;
}

.tw-fill-neutral-50\/100 {
  fill: rgb(250 250 250 / 1) !important;
}

.tw-fill-neutral-50\/15 {
  fill: rgb(250 250 250 / 0.15) !important;
}

.tw-fill-neutral-50\/20 {
  fill: rgb(250 250 250 / 0.2) !important;
}

.tw-fill-neutral-50\/25 {
  fill: rgb(250 250 250 / 0.25) !important;
}

.tw-fill-neutral-50\/30 {
  fill: rgb(250 250 250 / 0.3) !important;
}

.tw-fill-neutral-50\/35 {
  fill: rgb(250 250 250 / 0.35) !important;
}

.tw-fill-neutral-50\/40 {
  fill: rgb(250 250 250 / 0.4) !important;
}

.tw-fill-neutral-50\/45 {
  fill: rgb(250 250 250 / 0.45) !important;
}

.tw-fill-neutral-50\/5 {
  fill: rgb(250 250 250 / 0.05) !important;
}

.tw-fill-neutral-50\/50 {
  fill: rgb(250 250 250 / 0.5) !important;
}

.tw-fill-neutral-50\/55 {
  fill: rgb(250 250 250 / 0.55) !important;
}

.tw-fill-neutral-50\/60 {
  fill: rgb(250 250 250 / 0.6) !important;
}

.tw-fill-neutral-50\/65 {
  fill: rgb(250 250 250 / 0.65) !important;
}

.tw-fill-neutral-50\/70 {
  fill: rgb(250 250 250 / 0.7) !important;
}

.tw-fill-neutral-50\/75 {
  fill: rgb(250 250 250 / 0.75) !important;
}

.tw-fill-neutral-50\/80 {
  fill: rgb(250 250 250 / 0.8) !important;
}

.tw-fill-neutral-50\/85 {
  fill: rgb(250 250 250 / 0.85) !important;
}

.tw-fill-neutral-50\/90 {
  fill: rgb(250 250 250 / 0.9) !important;
}

.tw-fill-neutral-50\/95 {
  fill: rgb(250 250 250 / 0.95) !important;
}

.tw-fill-neutral-500 {
  fill: #737373 !important;
}

.tw-fill-neutral-500\/0 {
  fill: rgb(115 115 115 / 0) !important;
}

.tw-fill-neutral-500\/10 {
  fill: rgb(115 115 115 / 0.1) !important;
}

.tw-fill-neutral-500\/100 {
  fill: rgb(115 115 115 / 1) !important;
}

.tw-fill-neutral-500\/15 {
  fill: rgb(115 115 115 / 0.15) !important;
}

.tw-fill-neutral-500\/20 {
  fill: rgb(115 115 115 / 0.2) !important;
}

.tw-fill-neutral-500\/25 {
  fill: rgb(115 115 115 / 0.25) !important;
}

.tw-fill-neutral-500\/30 {
  fill: rgb(115 115 115 / 0.3) !important;
}

.tw-fill-neutral-500\/35 {
  fill: rgb(115 115 115 / 0.35) !important;
}

.tw-fill-neutral-500\/40 {
  fill: rgb(115 115 115 / 0.4) !important;
}

.tw-fill-neutral-500\/45 {
  fill: rgb(115 115 115 / 0.45) !important;
}

.tw-fill-neutral-500\/5 {
  fill: rgb(115 115 115 / 0.05) !important;
}

.tw-fill-neutral-500\/50 {
  fill: rgb(115 115 115 / 0.5) !important;
}

.tw-fill-neutral-500\/55 {
  fill: rgb(115 115 115 / 0.55) !important;
}

.tw-fill-neutral-500\/60 {
  fill: rgb(115 115 115 / 0.6) !important;
}

.tw-fill-neutral-500\/65 {
  fill: rgb(115 115 115 / 0.65) !important;
}

.tw-fill-neutral-500\/70 {
  fill: rgb(115 115 115 / 0.7) !important;
}

.tw-fill-neutral-500\/75 {
  fill: rgb(115 115 115 / 0.75) !important;
}

.tw-fill-neutral-500\/80 {
  fill: rgb(115 115 115 / 0.8) !important;
}

.tw-fill-neutral-500\/85 {
  fill: rgb(115 115 115 / 0.85) !important;
}

.tw-fill-neutral-500\/90 {
  fill: rgb(115 115 115 / 0.9) !important;
}

.tw-fill-neutral-500\/95 {
  fill: rgb(115 115 115 / 0.95) !important;
}

.tw-fill-neutral-600 {
  fill: #525252 !important;
}

.tw-fill-neutral-600\/0 {
  fill: rgb(82 82 82 / 0) !important;
}

.tw-fill-neutral-600\/10 {
  fill: rgb(82 82 82 / 0.1) !important;
}

.tw-fill-neutral-600\/100 {
  fill: rgb(82 82 82 / 1) !important;
}

.tw-fill-neutral-600\/15 {
  fill: rgb(82 82 82 / 0.15) !important;
}

.tw-fill-neutral-600\/20 {
  fill: rgb(82 82 82 / 0.2) !important;
}

.tw-fill-neutral-600\/25 {
  fill: rgb(82 82 82 / 0.25) !important;
}

.tw-fill-neutral-600\/30 {
  fill: rgb(82 82 82 / 0.3) !important;
}

.tw-fill-neutral-600\/35 {
  fill: rgb(82 82 82 / 0.35) !important;
}

.tw-fill-neutral-600\/40 {
  fill: rgb(82 82 82 / 0.4) !important;
}

.tw-fill-neutral-600\/45 {
  fill: rgb(82 82 82 / 0.45) !important;
}

.tw-fill-neutral-600\/5 {
  fill: rgb(82 82 82 / 0.05) !important;
}

.tw-fill-neutral-600\/50 {
  fill: rgb(82 82 82 / 0.5) !important;
}

.tw-fill-neutral-600\/55 {
  fill: rgb(82 82 82 / 0.55) !important;
}

.tw-fill-neutral-600\/60 {
  fill: rgb(82 82 82 / 0.6) !important;
}

.tw-fill-neutral-600\/65 {
  fill: rgb(82 82 82 / 0.65) !important;
}

.tw-fill-neutral-600\/70 {
  fill: rgb(82 82 82 / 0.7) !important;
}

.tw-fill-neutral-600\/75 {
  fill: rgb(82 82 82 / 0.75) !important;
}

.tw-fill-neutral-600\/80 {
  fill: rgb(82 82 82 / 0.8) !important;
}

.tw-fill-neutral-600\/85 {
  fill: rgb(82 82 82 / 0.85) !important;
}

.tw-fill-neutral-600\/90 {
  fill: rgb(82 82 82 / 0.9) !important;
}

.tw-fill-neutral-600\/95 {
  fill: rgb(82 82 82 / 0.95) !important;
}

.tw-fill-neutral-700 {
  fill: #404040 !important;
}

.tw-fill-neutral-700\/0 {
  fill: rgb(64 64 64 / 0) !important;
}

.tw-fill-neutral-700\/10 {
  fill: rgb(64 64 64 / 0.1) !important;
}

.tw-fill-neutral-700\/100 {
  fill: rgb(64 64 64 / 1) !important;
}

.tw-fill-neutral-700\/15 {
  fill: rgb(64 64 64 / 0.15) !important;
}

.tw-fill-neutral-700\/20 {
  fill: rgb(64 64 64 / 0.2) !important;
}

.tw-fill-neutral-700\/25 {
  fill: rgb(64 64 64 / 0.25) !important;
}

.tw-fill-neutral-700\/30 {
  fill: rgb(64 64 64 / 0.3) !important;
}

.tw-fill-neutral-700\/35 {
  fill: rgb(64 64 64 / 0.35) !important;
}

.tw-fill-neutral-700\/40 {
  fill: rgb(64 64 64 / 0.4) !important;
}

.tw-fill-neutral-700\/45 {
  fill: rgb(64 64 64 / 0.45) !important;
}

.tw-fill-neutral-700\/5 {
  fill: rgb(64 64 64 / 0.05) !important;
}

.tw-fill-neutral-700\/50 {
  fill: rgb(64 64 64 / 0.5) !important;
}

.tw-fill-neutral-700\/55 {
  fill: rgb(64 64 64 / 0.55) !important;
}

.tw-fill-neutral-700\/60 {
  fill: rgb(64 64 64 / 0.6) !important;
}

.tw-fill-neutral-700\/65 {
  fill: rgb(64 64 64 / 0.65) !important;
}

.tw-fill-neutral-700\/70 {
  fill: rgb(64 64 64 / 0.7) !important;
}

.tw-fill-neutral-700\/75 {
  fill: rgb(64 64 64 / 0.75) !important;
}

.tw-fill-neutral-700\/80 {
  fill: rgb(64 64 64 / 0.8) !important;
}

.tw-fill-neutral-700\/85 {
  fill: rgb(64 64 64 / 0.85) !important;
}

.tw-fill-neutral-700\/90 {
  fill: rgb(64 64 64 / 0.9) !important;
}

.tw-fill-neutral-700\/95 {
  fill: rgb(64 64 64 / 0.95) !important;
}

.tw-fill-neutral-800 {
  fill: #262626 !important;
}

.tw-fill-neutral-800\/0 {
  fill: rgb(38 38 38 / 0) !important;
}

.tw-fill-neutral-800\/10 {
  fill: rgb(38 38 38 / 0.1) !important;
}

.tw-fill-neutral-800\/100 {
  fill: rgb(38 38 38 / 1) !important;
}

.tw-fill-neutral-800\/15 {
  fill: rgb(38 38 38 / 0.15) !important;
}

.tw-fill-neutral-800\/20 {
  fill: rgb(38 38 38 / 0.2) !important;
}

.tw-fill-neutral-800\/25 {
  fill: rgb(38 38 38 / 0.25) !important;
}

.tw-fill-neutral-800\/30 {
  fill: rgb(38 38 38 / 0.3) !important;
}

.tw-fill-neutral-800\/35 {
  fill: rgb(38 38 38 / 0.35) !important;
}

.tw-fill-neutral-800\/40 {
  fill: rgb(38 38 38 / 0.4) !important;
}

.tw-fill-neutral-800\/45 {
  fill: rgb(38 38 38 / 0.45) !important;
}

.tw-fill-neutral-800\/5 {
  fill: rgb(38 38 38 / 0.05) !important;
}

.tw-fill-neutral-800\/50 {
  fill: rgb(38 38 38 / 0.5) !important;
}

.tw-fill-neutral-800\/55 {
  fill: rgb(38 38 38 / 0.55) !important;
}

.tw-fill-neutral-800\/60 {
  fill: rgb(38 38 38 / 0.6) !important;
}

.tw-fill-neutral-800\/65 {
  fill: rgb(38 38 38 / 0.65) !important;
}

.tw-fill-neutral-800\/70 {
  fill: rgb(38 38 38 / 0.7) !important;
}

.tw-fill-neutral-800\/75 {
  fill: rgb(38 38 38 / 0.75) !important;
}

.tw-fill-neutral-800\/80 {
  fill: rgb(38 38 38 / 0.8) !important;
}

.tw-fill-neutral-800\/85 {
  fill: rgb(38 38 38 / 0.85) !important;
}

.tw-fill-neutral-800\/90 {
  fill: rgb(38 38 38 / 0.9) !important;
}

.tw-fill-neutral-800\/95 {
  fill: rgb(38 38 38 / 0.95) !important;
}

.tw-fill-neutral-900 {
  fill: #171717 !important;
}

.tw-fill-neutral-900\/0 {
  fill: rgb(23 23 23 / 0) !important;
}

.tw-fill-neutral-900\/10 {
  fill: rgb(23 23 23 / 0.1) !important;
}

.tw-fill-neutral-900\/100 {
  fill: rgb(23 23 23 / 1) !important;
}

.tw-fill-neutral-900\/15 {
  fill: rgb(23 23 23 / 0.15) !important;
}

.tw-fill-neutral-900\/20 {
  fill: rgb(23 23 23 / 0.2) !important;
}

.tw-fill-neutral-900\/25 {
  fill: rgb(23 23 23 / 0.25) !important;
}

.tw-fill-neutral-900\/30 {
  fill: rgb(23 23 23 / 0.3) !important;
}

.tw-fill-neutral-900\/35 {
  fill: rgb(23 23 23 / 0.35) !important;
}

.tw-fill-neutral-900\/40 {
  fill: rgb(23 23 23 / 0.4) !important;
}

.tw-fill-neutral-900\/45 {
  fill: rgb(23 23 23 / 0.45) !important;
}

.tw-fill-neutral-900\/5 {
  fill: rgb(23 23 23 / 0.05) !important;
}

.tw-fill-neutral-900\/50 {
  fill: rgb(23 23 23 / 0.5) !important;
}

.tw-fill-neutral-900\/55 {
  fill: rgb(23 23 23 / 0.55) !important;
}

.tw-fill-neutral-900\/60 {
  fill: rgb(23 23 23 / 0.6) !important;
}

.tw-fill-neutral-900\/65 {
  fill: rgb(23 23 23 / 0.65) !important;
}

.tw-fill-neutral-900\/70 {
  fill: rgb(23 23 23 / 0.7) !important;
}

.tw-fill-neutral-900\/75 {
  fill: rgb(23 23 23 / 0.75) !important;
}

.tw-fill-neutral-900\/80 {
  fill: rgb(23 23 23 / 0.8) !important;
}

.tw-fill-neutral-900\/85 {
  fill: rgb(23 23 23 / 0.85) !important;
}

.tw-fill-neutral-900\/90 {
  fill: rgb(23 23 23 / 0.9) !important;
}

.tw-fill-neutral-900\/95 {
  fill: rgb(23 23 23 / 0.95) !important;
}

.tw-fill-neutral-950 {
  fill: #0a0a0a !important;
}

.tw-fill-neutral-950\/0 {
  fill: rgb(10 10 10 / 0) !important;
}

.tw-fill-neutral-950\/10 {
  fill: rgb(10 10 10 / 0.1) !important;
}

.tw-fill-neutral-950\/100 {
  fill: rgb(10 10 10 / 1) !important;
}

.tw-fill-neutral-950\/15 {
  fill: rgb(10 10 10 / 0.15) !important;
}

.tw-fill-neutral-950\/20 {
  fill: rgb(10 10 10 / 0.2) !important;
}

.tw-fill-neutral-950\/25 {
  fill: rgb(10 10 10 / 0.25) !important;
}

.tw-fill-neutral-950\/30 {
  fill: rgb(10 10 10 / 0.3) !important;
}

.tw-fill-neutral-950\/35 {
  fill: rgb(10 10 10 / 0.35) !important;
}

.tw-fill-neutral-950\/40 {
  fill: rgb(10 10 10 / 0.4) !important;
}

.tw-fill-neutral-950\/45 {
  fill: rgb(10 10 10 / 0.45) !important;
}

.tw-fill-neutral-950\/5 {
  fill: rgb(10 10 10 / 0.05) !important;
}

.tw-fill-neutral-950\/50 {
  fill: rgb(10 10 10 / 0.5) !important;
}

.tw-fill-neutral-950\/55 {
  fill: rgb(10 10 10 / 0.55) !important;
}

.tw-fill-neutral-950\/60 {
  fill: rgb(10 10 10 / 0.6) !important;
}

.tw-fill-neutral-950\/65 {
  fill: rgb(10 10 10 / 0.65) !important;
}

.tw-fill-neutral-950\/70 {
  fill: rgb(10 10 10 / 0.7) !important;
}

.tw-fill-neutral-950\/75 {
  fill: rgb(10 10 10 / 0.75) !important;
}

.tw-fill-neutral-950\/80 {
  fill: rgb(10 10 10 / 0.8) !important;
}

.tw-fill-neutral-950\/85 {
  fill: rgb(10 10 10 / 0.85) !important;
}

.tw-fill-neutral-950\/90 {
  fill: rgb(10 10 10 / 0.9) !important;
}

.tw-fill-neutral-950\/95 {
  fill: rgb(10 10 10 / 0.95) !important;
}

.tw-fill-none {
  fill: none !important;
}

.tw-fill-orange-100 {
  fill: #ffedd5 !important;
}

.tw-fill-orange-100\/0 {
  fill: rgb(255 237 213 / 0) !important;
}

.tw-fill-orange-100\/10 {
  fill: rgb(255 237 213 / 0.1) !important;
}

.tw-fill-orange-100\/100 {
  fill: rgb(255 237 213 / 1) !important;
}

.tw-fill-orange-100\/15 {
  fill: rgb(255 237 213 / 0.15) !important;
}

.tw-fill-orange-100\/20 {
  fill: rgb(255 237 213 / 0.2) !important;
}

.tw-fill-orange-100\/25 {
  fill: rgb(255 237 213 / 0.25) !important;
}

.tw-fill-orange-100\/30 {
  fill: rgb(255 237 213 / 0.3) !important;
}

.tw-fill-orange-100\/35 {
  fill: rgb(255 237 213 / 0.35) !important;
}

.tw-fill-orange-100\/40 {
  fill: rgb(255 237 213 / 0.4) !important;
}

.tw-fill-orange-100\/45 {
  fill: rgb(255 237 213 / 0.45) !important;
}

.tw-fill-orange-100\/5 {
  fill: rgb(255 237 213 / 0.05) !important;
}

.tw-fill-orange-100\/50 {
  fill: rgb(255 237 213 / 0.5) !important;
}

.tw-fill-orange-100\/55 {
  fill: rgb(255 237 213 / 0.55) !important;
}

.tw-fill-orange-100\/60 {
  fill: rgb(255 237 213 / 0.6) !important;
}

.tw-fill-orange-100\/65 {
  fill: rgb(255 237 213 / 0.65) !important;
}

.tw-fill-orange-100\/70 {
  fill: rgb(255 237 213 / 0.7) !important;
}

.tw-fill-orange-100\/75 {
  fill: rgb(255 237 213 / 0.75) !important;
}

.tw-fill-orange-100\/80 {
  fill: rgb(255 237 213 / 0.8) !important;
}

.tw-fill-orange-100\/85 {
  fill: rgb(255 237 213 / 0.85) !important;
}

.tw-fill-orange-100\/90 {
  fill: rgb(255 237 213 / 0.9) !important;
}

.tw-fill-orange-100\/95 {
  fill: rgb(255 237 213 / 0.95) !important;
}

.tw-fill-orange-200 {
  fill: #fed7aa !important;
}

.tw-fill-orange-200\/0 {
  fill: rgb(254 215 170 / 0) !important;
}

.tw-fill-orange-200\/10 {
  fill: rgb(254 215 170 / 0.1) !important;
}

.tw-fill-orange-200\/100 {
  fill: rgb(254 215 170 / 1) !important;
}

.tw-fill-orange-200\/15 {
  fill: rgb(254 215 170 / 0.15) !important;
}

.tw-fill-orange-200\/20 {
  fill: rgb(254 215 170 / 0.2) !important;
}

.tw-fill-orange-200\/25 {
  fill: rgb(254 215 170 / 0.25) !important;
}

.tw-fill-orange-200\/30 {
  fill: rgb(254 215 170 / 0.3) !important;
}

.tw-fill-orange-200\/35 {
  fill: rgb(254 215 170 / 0.35) !important;
}

.tw-fill-orange-200\/40 {
  fill: rgb(254 215 170 / 0.4) !important;
}

.tw-fill-orange-200\/45 {
  fill: rgb(254 215 170 / 0.45) !important;
}

.tw-fill-orange-200\/5 {
  fill: rgb(254 215 170 / 0.05) !important;
}

.tw-fill-orange-200\/50 {
  fill: rgb(254 215 170 / 0.5) !important;
}

.tw-fill-orange-200\/55 {
  fill: rgb(254 215 170 / 0.55) !important;
}

.tw-fill-orange-200\/60 {
  fill: rgb(254 215 170 / 0.6) !important;
}

.tw-fill-orange-200\/65 {
  fill: rgb(254 215 170 / 0.65) !important;
}

.tw-fill-orange-200\/70 {
  fill: rgb(254 215 170 / 0.7) !important;
}

.tw-fill-orange-200\/75 {
  fill: rgb(254 215 170 / 0.75) !important;
}

.tw-fill-orange-200\/80 {
  fill: rgb(254 215 170 / 0.8) !important;
}

.tw-fill-orange-200\/85 {
  fill: rgb(254 215 170 / 0.85) !important;
}

.tw-fill-orange-200\/90 {
  fill: rgb(254 215 170 / 0.9) !important;
}

.tw-fill-orange-200\/95 {
  fill: rgb(254 215 170 / 0.95) !important;
}

.tw-fill-orange-300 {
  fill: #fdba74 !important;
}

.tw-fill-orange-300\/0 {
  fill: rgb(253 186 116 / 0) !important;
}

.tw-fill-orange-300\/10 {
  fill: rgb(253 186 116 / 0.1) !important;
}

.tw-fill-orange-300\/100 {
  fill: rgb(253 186 116 / 1) !important;
}

.tw-fill-orange-300\/15 {
  fill: rgb(253 186 116 / 0.15) !important;
}

.tw-fill-orange-300\/20 {
  fill: rgb(253 186 116 / 0.2) !important;
}

.tw-fill-orange-300\/25 {
  fill: rgb(253 186 116 / 0.25) !important;
}

.tw-fill-orange-300\/30 {
  fill: rgb(253 186 116 / 0.3) !important;
}

.tw-fill-orange-300\/35 {
  fill: rgb(253 186 116 / 0.35) !important;
}

.tw-fill-orange-300\/40 {
  fill: rgb(253 186 116 / 0.4) !important;
}

.tw-fill-orange-300\/45 {
  fill: rgb(253 186 116 / 0.45) !important;
}

.tw-fill-orange-300\/5 {
  fill: rgb(253 186 116 / 0.05) !important;
}

.tw-fill-orange-300\/50 {
  fill: rgb(253 186 116 / 0.5) !important;
}

.tw-fill-orange-300\/55 {
  fill: rgb(253 186 116 / 0.55) !important;
}

.tw-fill-orange-300\/60 {
  fill: rgb(253 186 116 / 0.6) !important;
}

.tw-fill-orange-300\/65 {
  fill: rgb(253 186 116 / 0.65) !important;
}

.tw-fill-orange-300\/70 {
  fill: rgb(253 186 116 / 0.7) !important;
}

.tw-fill-orange-300\/75 {
  fill: rgb(253 186 116 / 0.75) !important;
}

.tw-fill-orange-300\/80 {
  fill: rgb(253 186 116 / 0.8) !important;
}

.tw-fill-orange-300\/85 {
  fill: rgb(253 186 116 / 0.85) !important;
}

.tw-fill-orange-300\/90 {
  fill: rgb(253 186 116 / 0.9) !important;
}

.tw-fill-orange-300\/95 {
  fill: rgb(253 186 116 / 0.95) !important;
}

.tw-fill-orange-400 {
  fill: #fb923c !important;
}

.tw-fill-orange-400\/0 {
  fill: rgb(251 146 60 / 0) !important;
}

.tw-fill-orange-400\/10 {
  fill: rgb(251 146 60 / 0.1) !important;
}

.tw-fill-orange-400\/100 {
  fill: rgb(251 146 60 / 1) !important;
}

.tw-fill-orange-400\/15 {
  fill: rgb(251 146 60 / 0.15) !important;
}

.tw-fill-orange-400\/20 {
  fill: rgb(251 146 60 / 0.2) !important;
}

.tw-fill-orange-400\/25 {
  fill: rgb(251 146 60 / 0.25) !important;
}

.tw-fill-orange-400\/30 {
  fill: rgb(251 146 60 / 0.3) !important;
}

.tw-fill-orange-400\/35 {
  fill: rgb(251 146 60 / 0.35) !important;
}

.tw-fill-orange-400\/40 {
  fill: rgb(251 146 60 / 0.4) !important;
}

.tw-fill-orange-400\/45 {
  fill: rgb(251 146 60 / 0.45) !important;
}

.tw-fill-orange-400\/5 {
  fill: rgb(251 146 60 / 0.05) !important;
}

.tw-fill-orange-400\/50 {
  fill: rgb(251 146 60 / 0.5) !important;
}

.tw-fill-orange-400\/55 {
  fill: rgb(251 146 60 / 0.55) !important;
}

.tw-fill-orange-400\/60 {
  fill: rgb(251 146 60 / 0.6) !important;
}

.tw-fill-orange-400\/65 {
  fill: rgb(251 146 60 / 0.65) !important;
}

.tw-fill-orange-400\/70 {
  fill: rgb(251 146 60 / 0.7) !important;
}

.tw-fill-orange-400\/75 {
  fill: rgb(251 146 60 / 0.75) !important;
}

.tw-fill-orange-400\/80 {
  fill: rgb(251 146 60 / 0.8) !important;
}

.tw-fill-orange-400\/85 {
  fill: rgb(251 146 60 / 0.85) !important;
}

.tw-fill-orange-400\/90 {
  fill: rgb(251 146 60 / 0.9) !important;
}

.tw-fill-orange-400\/95 {
  fill: rgb(251 146 60 / 0.95) !important;
}

.tw-fill-orange-50 {
  fill: #fff7ed !important;
}

.tw-fill-orange-50\/0 {
  fill: rgb(255 247 237 / 0) !important;
}

.tw-fill-orange-50\/10 {
  fill: rgb(255 247 237 / 0.1) !important;
}

.tw-fill-orange-50\/100 {
  fill: rgb(255 247 237 / 1) !important;
}

.tw-fill-orange-50\/15 {
  fill: rgb(255 247 237 / 0.15) !important;
}

.tw-fill-orange-50\/20 {
  fill: rgb(255 247 237 / 0.2) !important;
}

.tw-fill-orange-50\/25 {
  fill: rgb(255 247 237 / 0.25) !important;
}

.tw-fill-orange-50\/30 {
  fill: rgb(255 247 237 / 0.3) !important;
}

.tw-fill-orange-50\/35 {
  fill: rgb(255 247 237 / 0.35) !important;
}

.tw-fill-orange-50\/40 {
  fill: rgb(255 247 237 / 0.4) !important;
}

.tw-fill-orange-50\/45 {
  fill: rgb(255 247 237 / 0.45) !important;
}

.tw-fill-orange-50\/5 {
  fill: rgb(255 247 237 / 0.05) !important;
}

.tw-fill-orange-50\/50 {
  fill: rgb(255 247 237 / 0.5) !important;
}

.tw-fill-orange-50\/55 {
  fill: rgb(255 247 237 / 0.55) !important;
}

.tw-fill-orange-50\/60 {
  fill: rgb(255 247 237 / 0.6) !important;
}

.tw-fill-orange-50\/65 {
  fill: rgb(255 247 237 / 0.65) !important;
}

.tw-fill-orange-50\/70 {
  fill: rgb(255 247 237 / 0.7) !important;
}

.tw-fill-orange-50\/75 {
  fill: rgb(255 247 237 / 0.75) !important;
}

.tw-fill-orange-50\/80 {
  fill: rgb(255 247 237 / 0.8) !important;
}

.tw-fill-orange-50\/85 {
  fill: rgb(255 247 237 / 0.85) !important;
}

.tw-fill-orange-50\/90 {
  fill: rgb(255 247 237 / 0.9) !important;
}

.tw-fill-orange-50\/95 {
  fill: rgb(255 247 237 / 0.95) !important;
}

.tw-fill-orange-500 {
  fill: #f97316 !important;
}

.tw-fill-orange-500\/0 {
  fill: rgb(249 115 22 / 0) !important;
}

.tw-fill-orange-500\/10 {
  fill: rgb(249 115 22 / 0.1) !important;
}

.tw-fill-orange-500\/100 {
  fill: rgb(249 115 22 / 1) !important;
}

.tw-fill-orange-500\/15 {
  fill: rgb(249 115 22 / 0.15) !important;
}

.tw-fill-orange-500\/20 {
  fill: rgb(249 115 22 / 0.2) !important;
}

.tw-fill-orange-500\/25 {
  fill: rgb(249 115 22 / 0.25) !important;
}

.tw-fill-orange-500\/30 {
  fill: rgb(249 115 22 / 0.3) !important;
}

.tw-fill-orange-500\/35 {
  fill: rgb(249 115 22 / 0.35) !important;
}

.tw-fill-orange-500\/40 {
  fill: rgb(249 115 22 / 0.4) !important;
}

.tw-fill-orange-500\/45 {
  fill: rgb(249 115 22 / 0.45) !important;
}

.tw-fill-orange-500\/5 {
  fill: rgb(249 115 22 / 0.05) !important;
}

.tw-fill-orange-500\/50 {
  fill: rgb(249 115 22 / 0.5) !important;
}

.tw-fill-orange-500\/55 {
  fill: rgb(249 115 22 / 0.55) !important;
}

.tw-fill-orange-500\/60 {
  fill: rgb(249 115 22 / 0.6) !important;
}

.tw-fill-orange-500\/65 {
  fill: rgb(249 115 22 / 0.65) !important;
}

.tw-fill-orange-500\/70 {
  fill: rgb(249 115 22 / 0.7) !important;
}

.tw-fill-orange-500\/75 {
  fill: rgb(249 115 22 / 0.75) !important;
}

.tw-fill-orange-500\/80 {
  fill: rgb(249 115 22 / 0.8) !important;
}

.tw-fill-orange-500\/85 {
  fill: rgb(249 115 22 / 0.85) !important;
}

.tw-fill-orange-500\/90 {
  fill: rgb(249 115 22 / 0.9) !important;
}

.tw-fill-orange-500\/95 {
  fill: rgb(249 115 22 / 0.95) !important;
}

.tw-fill-orange-600 {
  fill: #ea580c !important;
}

.tw-fill-orange-600\/0 {
  fill: rgb(234 88 12 / 0) !important;
}

.tw-fill-orange-600\/10 {
  fill: rgb(234 88 12 / 0.1) !important;
}

.tw-fill-orange-600\/100 {
  fill: rgb(234 88 12 / 1) !important;
}

.tw-fill-orange-600\/15 {
  fill: rgb(234 88 12 / 0.15) !important;
}

.tw-fill-orange-600\/20 {
  fill: rgb(234 88 12 / 0.2) !important;
}

.tw-fill-orange-600\/25 {
  fill: rgb(234 88 12 / 0.25) !important;
}

.tw-fill-orange-600\/30 {
  fill: rgb(234 88 12 / 0.3) !important;
}

.tw-fill-orange-600\/35 {
  fill: rgb(234 88 12 / 0.35) !important;
}

.tw-fill-orange-600\/40 {
  fill: rgb(234 88 12 / 0.4) !important;
}

.tw-fill-orange-600\/45 {
  fill: rgb(234 88 12 / 0.45) !important;
}

.tw-fill-orange-600\/5 {
  fill: rgb(234 88 12 / 0.05) !important;
}

.tw-fill-orange-600\/50 {
  fill: rgb(234 88 12 / 0.5) !important;
}

.tw-fill-orange-600\/55 {
  fill: rgb(234 88 12 / 0.55) !important;
}

.tw-fill-orange-600\/60 {
  fill: rgb(234 88 12 / 0.6) !important;
}

.tw-fill-orange-600\/65 {
  fill: rgb(234 88 12 / 0.65) !important;
}

.tw-fill-orange-600\/70 {
  fill: rgb(234 88 12 / 0.7) !important;
}

.tw-fill-orange-600\/75 {
  fill: rgb(234 88 12 / 0.75) !important;
}

.tw-fill-orange-600\/80 {
  fill: rgb(234 88 12 / 0.8) !important;
}

.tw-fill-orange-600\/85 {
  fill: rgb(234 88 12 / 0.85) !important;
}

.tw-fill-orange-600\/90 {
  fill: rgb(234 88 12 / 0.9) !important;
}

.tw-fill-orange-600\/95 {
  fill: rgb(234 88 12 / 0.95) !important;
}

.tw-fill-orange-700 {
  fill: #c2410c !important;
}

.tw-fill-orange-700\/0 {
  fill: rgb(194 65 12 / 0) !important;
}

.tw-fill-orange-700\/10 {
  fill: rgb(194 65 12 / 0.1) !important;
}

.tw-fill-orange-700\/100 {
  fill: rgb(194 65 12 / 1) !important;
}

.tw-fill-orange-700\/15 {
  fill: rgb(194 65 12 / 0.15) !important;
}

.tw-fill-orange-700\/20 {
  fill: rgb(194 65 12 / 0.2) !important;
}

.tw-fill-orange-700\/25 {
  fill: rgb(194 65 12 / 0.25) !important;
}

.tw-fill-orange-700\/30 {
  fill: rgb(194 65 12 / 0.3) !important;
}

.tw-fill-orange-700\/35 {
  fill: rgb(194 65 12 / 0.35) !important;
}

.tw-fill-orange-700\/40 {
  fill: rgb(194 65 12 / 0.4) !important;
}

.tw-fill-orange-700\/45 {
  fill: rgb(194 65 12 / 0.45) !important;
}

.tw-fill-orange-700\/5 {
  fill: rgb(194 65 12 / 0.05) !important;
}

.tw-fill-orange-700\/50 {
  fill: rgb(194 65 12 / 0.5) !important;
}

.tw-fill-orange-700\/55 {
  fill: rgb(194 65 12 / 0.55) !important;
}

.tw-fill-orange-700\/60 {
  fill: rgb(194 65 12 / 0.6) !important;
}

.tw-fill-orange-700\/65 {
  fill: rgb(194 65 12 / 0.65) !important;
}

.tw-fill-orange-700\/70 {
  fill: rgb(194 65 12 / 0.7) !important;
}

.tw-fill-orange-700\/75 {
  fill: rgb(194 65 12 / 0.75) !important;
}

.tw-fill-orange-700\/80 {
  fill: rgb(194 65 12 / 0.8) !important;
}

.tw-fill-orange-700\/85 {
  fill: rgb(194 65 12 / 0.85) !important;
}

.tw-fill-orange-700\/90 {
  fill: rgb(194 65 12 / 0.9) !important;
}

.tw-fill-orange-700\/95 {
  fill: rgb(194 65 12 / 0.95) !important;
}

.tw-fill-orange-800 {
  fill: #9a3412 !important;
}

.tw-fill-orange-800\/0 {
  fill: rgb(154 52 18 / 0) !important;
}

.tw-fill-orange-800\/10 {
  fill: rgb(154 52 18 / 0.1) !important;
}

.tw-fill-orange-800\/100 {
  fill: rgb(154 52 18 / 1) !important;
}

.tw-fill-orange-800\/15 {
  fill: rgb(154 52 18 / 0.15) !important;
}

.tw-fill-orange-800\/20 {
  fill: rgb(154 52 18 / 0.2) !important;
}

.tw-fill-orange-800\/25 {
  fill: rgb(154 52 18 / 0.25) !important;
}

.tw-fill-orange-800\/30 {
  fill: rgb(154 52 18 / 0.3) !important;
}

.tw-fill-orange-800\/35 {
  fill: rgb(154 52 18 / 0.35) !important;
}

.tw-fill-orange-800\/40 {
  fill: rgb(154 52 18 / 0.4) !important;
}

.tw-fill-orange-800\/45 {
  fill: rgb(154 52 18 / 0.45) !important;
}

.tw-fill-orange-800\/5 {
  fill: rgb(154 52 18 / 0.05) !important;
}

.tw-fill-orange-800\/50 {
  fill: rgb(154 52 18 / 0.5) !important;
}

.tw-fill-orange-800\/55 {
  fill: rgb(154 52 18 / 0.55) !important;
}

.tw-fill-orange-800\/60 {
  fill: rgb(154 52 18 / 0.6) !important;
}

.tw-fill-orange-800\/65 {
  fill: rgb(154 52 18 / 0.65) !important;
}

.tw-fill-orange-800\/70 {
  fill: rgb(154 52 18 / 0.7) !important;
}

.tw-fill-orange-800\/75 {
  fill: rgb(154 52 18 / 0.75) !important;
}

.tw-fill-orange-800\/80 {
  fill: rgb(154 52 18 / 0.8) !important;
}

.tw-fill-orange-800\/85 {
  fill: rgb(154 52 18 / 0.85) !important;
}

.tw-fill-orange-800\/90 {
  fill: rgb(154 52 18 / 0.9) !important;
}

.tw-fill-orange-800\/95 {
  fill: rgb(154 52 18 / 0.95) !important;
}

.tw-fill-orange-900 {
  fill: #7c2d12 !important;
}

.tw-fill-orange-900\/0 {
  fill: rgb(124 45 18 / 0) !important;
}

.tw-fill-orange-900\/10 {
  fill: rgb(124 45 18 / 0.1) !important;
}

.tw-fill-orange-900\/100 {
  fill: rgb(124 45 18 / 1) !important;
}

.tw-fill-orange-900\/15 {
  fill: rgb(124 45 18 / 0.15) !important;
}

.tw-fill-orange-900\/20 {
  fill: rgb(124 45 18 / 0.2) !important;
}

.tw-fill-orange-900\/25 {
  fill: rgb(124 45 18 / 0.25) !important;
}

.tw-fill-orange-900\/30 {
  fill: rgb(124 45 18 / 0.3) !important;
}

.tw-fill-orange-900\/35 {
  fill: rgb(124 45 18 / 0.35) !important;
}

.tw-fill-orange-900\/40 {
  fill: rgb(124 45 18 / 0.4) !important;
}

.tw-fill-orange-900\/45 {
  fill: rgb(124 45 18 / 0.45) !important;
}

.tw-fill-orange-900\/5 {
  fill: rgb(124 45 18 / 0.05) !important;
}

.tw-fill-orange-900\/50 {
  fill: rgb(124 45 18 / 0.5) !important;
}

.tw-fill-orange-900\/55 {
  fill: rgb(124 45 18 / 0.55) !important;
}

.tw-fill-orange-900\/60 {
  fill: rgb(124 45 18 / 0.6) !important;
}

.tw-fill-orange-900\/65 {
  fill: rgb(124 45 18 / 0.65) !important;
}

.tw-fill-orange-900\/70 {
  fill: rgb(124 45 18 / 0.7) !important;
}

.tw-fill-orange-900\/75 {
  fill: rgb(124 45 18 / 0.75) !important;
}

.tw-fill-orange-900\/80 {
  fill: rgb(124 45 18 / 0.8) !important;
}

.tw-fill-orange-900\/85 {
  fill: rgb(124 45 18 / 0.85) !important;
}

.tw-fill-orange-900\/90 {
  fill: rgb(124 45 18 / 0.9) !important;
}

.tw-fill-orange-900\/95 {
  fill: rgb(124 45 18 / 0.95) !important;
}

.tw-fill-orange-950 {
  fill: #431407 !important;
}

.tw-fill-orange-950\/0 {
  fill: rgb(67 20 7 / 0) !important;
}

.tw-fill-orange-950\/10 {
  fill: rgb(67 20 7 / 0.1) !important;
}

.tw-fill-orange-950\/100 {
  fill: rgb(67 20 7 / 1) !important;
}

.tw-fill-orange-950\/15 {
  fill: rgb(67 20 7 / 0.15) !important;
}

.tw-fill-orange-950\/20 {
  fill: rgb(67 20 7 / 0.2) !important;
}

.tw-fill-orange-950\/25 {
  fill: rgb(67 20 7 / 0.25) !important;
}

.tw-fill-orange-950\/30 {
  fill: rgb(67 20 7 / 0.3) !important;
}

.tw-fill-orange-950\/35 {
  fill: rgb(67 20 7 / 0.35) !important;
}

.tw-fill-orange-950\/40 {
  fill: rgb(67 20 7 / 0.4) !important;
}

.tw-fill-orange-950\/45 {
  fill: rgb(67 20 7 / 0.45) !important;
}

.tw-fill-orange-950\/5 {
  fill: rgb(67 20 7 / 0.05) !important;
}

.tw-fill-orange-950\/50 {
  fill: rgb(67 20 7 / 0.5) !important;
}

.tw-fill-orange-950\/55 {
  fill: rgb(67 20 7 / 0.55) !important;
}

.tw-fill-orange-950\/60 {
  fill: rgb(67 20 7 / 0.6) !important;
}

.tw-fill-orange-950\/65 {
  fill: rgb(67 20 7 / 0.65) !important;
}

.tw-fill-orange-950\/70 {
  fill: rgb(67 20 7 / 0.7) !important;
}

.tw-fill-orange-950\/75 {
  fill: rgb(67 20 7 / 0.75) !important;
}

.tw-fill-orange-950\/80 {
  fill: rgb(67 20 7 / 0.8) !important;
}

.tw-fill-orange-950\/85 {
  fill: rgb(67 20 7 / 0.85) !important;
}

.tw-fill-orange-950\/90 {
  fill: rgb(67 20 7 / 0.9) !important;
}

.tw-fill-orange-950\/95 {
  fill: rgb(67 20 7 / 0.95) !important;
}

.tw-fill-pink-100 {
  fill: #fce7f3 !important;
}

.tw-fill-pink-100\/0 {
  fill: rgb(252 231 243 / 0) !important;
}

.tw-fill-pink-100\/10 {
  fill: rgb(252 231 243 / 0.1) !important;
}

.tw-fill-pink-100\/100 {
  fill: rgb(252 231 243 / 1) !important;
}

.tw-fill-pink-100\/15 {
  fill: rgb(252 231 243 / 0.15) !important;
}

.tw-fill-pink-100\/20 {
  fill: rgb(252 231 243 / 0.2) !important;
}

.tw-fill-pink-100\/25 {
  fill: rgb(252 231 243 / 0.25) !important;
}

.tw-fill-pink-100\/30 {
  fill: rgb(252 231 243 / 0.3) !important;
}

.tw-fill-pink-100\/35 {
  fill: rgb(252 231 243 / 0.35) !important;
}

.tw-fill-pink-100\/40 {
  fill: rgb(252 231 243 / 0.4) !important;
}

.tw-fill-pink-100\/45 {
  fill: rgb(252 231 243 / 0.45) !important;
}

.tw-fill-pink-100\/5 {
  fill: rgb(252 231 243 / 0.05) !important;
}

.tw-fill-pink-100\/50 {
  fill: rgb(252 231 243 / 0.5) !important;
}

.tw-fill-pink-100\/55 {
  fill: rgb(252 231 243 / 0.55) !important;
}

.tw-fill-pink-100\/60 {
  fill: rgb(252 231 243 / 0.6) !important;
}

.tw-fill-pink-100\/65 {
  fill: rgb(252 231 243 / 0.65) !important;
}

.tw-fill-pink-100\/70 {
  fill: rgb(252 231 243 / 0.7) !important;
}

.tw-fill-pink-100\/75 {
  fill: rgb(252 231 243 / 0.75) !important;
}

.tw-fill-pink-100\/80 {
  fill: rgb(252 231 243 / 0.8) !important;
}

.tw-fill-pink-100\/85 {
  fill: rgb(252 231 243 / 0.85) !important;
}

.tw-fill-pink-100\/90 {
  fill: rgb(252 231 243 / 0.9) !important;
}

.tw-fill-pink-100\/95 {
  fill: rgb(252 231 243 / 0.95) !important;
}

.tw-fill-pink-200 {
  fill: #fbcfe8 !important;
}

.tw-fill-pink-200\/0 {
  fill: rgb(251 207 232 / 0) !important;
}

.tw-fill-pink-200\/10 {
  fill: rgb(251 207 232 / 0.1) !important;
}

.tw-fill-pink-200\/100 {
  fill: rgb(251 207 232 / 1) !important;
}

.tw-fill-pink-200\/15 {
  fill: rgb(251 207 232 / 0.15) !important;
}

.tw-fill-pink-200\/20 {
  fill: rgb(251 207 232 / 0.2) !important;
}

.tw-fill-pink-200\/25 {
  fill: rgb(251 207 232 / 0.25) !important;
}

.tw-fill-pink-200\/30 {
  fill: rgb(251 207 232 / 0.3) !important;
}

.tw-fill-pink-200\/35 {
  fill: rgb(251 207 232 / 0.35) !important;
}

.tw-fill-pink-200\/40 {
  fill: rgb(251 207 232 / 0.4) !important;
}

.tw-fill-pink-200\/45 {
  fill: rgb(251 207 232 / 0.45) !important;
}

.tw-fill-pink-200\/5 {
  fill: rgb(251 207 232 / 0.05) !important;
}

.tw-fill-pink-200\/50 {
  fill: rgb(251 207 232 / 0.5) !important;
}

.tw-fill-pink-200\/55 {
  fill: rgb(251 207 232 / 0.55) !important;
}

.tw-fill-pink-200\/60 {
  fill: rgb(251 207 232 / 0.6) !important;
}

.tw-fill-pink-200\/65 {
  fill: rgb(251 207 232 / 0.65) !important;
}

.tw-fill-pink-200\/70 {
  fill: rgb(251 207 232 / 0.7) !important;
}

.tw-fill-pink-200\/75 {
  fill: rgb(251 207 232 / 0.75) !important;
}

.tw-fill-pink-200\/80 {
  fill: rgb(251 207 232 / 0.8) !important;
}

.tw-fill-pink-200\/85 {
  fill: rgb(251 207 232 / 0.85) !important;
}

.tw-fill-pink-200\/90 {
  fill: rgb(251 207 232 / 0.9) !important;
}

.tw-fill-pink-200\/95 {
  fill: rgb(251 207 232 / 0.95) !important;
}

.tw-fill-pink-300 {
  fill: #f9a8d4 !important;
}

.tw-fill-pink-300\/0 {
  fill: rgb(249 168 212 / 0) !important;
}

.tw-fill-pink-300\/10 {
  fill: rgb(249 168 212 / 0.1) !important;
}

.tw-fill-pink-300\/100 {
  fill: rgb(249 168 212 / 1) !important;
}

.tw-fill-pink-300\/15 {
  fill: rgb(249 168 212 / 0.15) !important;
}

.tw-fill-pink-300\/20 {
  fill: rgb(249 168 212 / 0.2) !important;
}

.tw-fill-pink-300\/25 {
  fill: rgb(249 168 212 / 0.25) !important;
}

.tw-fill-pink-300\/30 {
  fill: rgb(249 168 212 / 0.3) !important;
}

.tw-fill-pink-300\/35 {
  fill: rgb(249 168 212 / 0.35) !important;
}

.tw-fill-pink-300\/40 {
  fill: rgb(249 168 212 / 0.4) !important;
}

.tw-fill-pink-300\/45 {
  fill: rgb(249 168 212 / 0.45) !important;
}

.tw-fill-pink-300\/5 {
  fill: rgb(249 168 212 / 0.05) !important;
}

.tw-fill-pink-300\/50 {
  fill: rgb(249 168 212 / 0.5) !important;
}

.tw-fill-pink-300\/55 {
  fill: rgb(249 168 212 / 0.55) !important;
}

.tw-fill-pink-300\/60 {
  fill: rgb(249 168 212 / 0.6) !important;
}

.tw-fill-pink-300\/65 {
  fill: rgb(249 168 212 / 0.65) !important;
}

.tw-fill-pink-300\/70 {
  fill: rgb(249 168 212 / 0.7) !important;
}

.tw-fill-pink-300\/75 {
  fill: rgb(249 168 212 / 0.75) !important;
}

.tw-fill-pink-300\/80 {
  fill: rgb(249 168 212 / 0.8) !important;
}

.tw-fill-pink-300\/85 {
  fill: rgb(249 168 212 / 0.85) !important;
}

.tw-fill-pink-300\/90 {
  fill: rgb(249 168 212 / 0.9) !important;
}

.tw-fill-pink-300\/95 {
  fill: rgb(249 168 212 / 0.95) !important;
}

.tw-fill-pink-400 {
  fill: #f472b6 !important;
}

.tw-fill-pink-400\/0 {
  fill: rgb(244 114 182 / 0) !important;
}

.tw-fill-pink-400\/10 {
  fill: rgb(244 114 182 / 0.1) !important;
}

.tw-fill-pink-400\/100 {
  fill: rgb(244 114 182 / 1) !important;
}

.tw-fill-pink-400\/15 {
  fill: rgb(244 114 182 / 0.15) !important;
}

.tw-fill-pink-400\/20 {
  fill: rgb(244 114 182 / 0.2) !important;
}

.tw-fill-pink-400\/25 {
  fill: rgb(244 114 182 / 0.25) !important;
}

.tw-fill-pink-400\/30 {
  fill: rgb(244 114 182 / 0.3) !important;
}

.tw-fill-pink-400\/35 {
  fill: rgb(244 114 182 / 0.35) !important;
}

.tw-fill-pink-400\/40 {
  fill: rgb(244 114 182 / 0.4) !important;
}

.tw-fill-pink-400\/45 {
  fill: rgb(244 114 182 / 0.45) !important;
}

.tw-fill-pink-400\/5 {
  fill: rgb(244 114 182 / 0.05) !important;
}

.tw-fill-pink-400\/50 {
  fill: rgb(244 114 182 / 0.5) !important;
}

.tw-fill-pink-400\/55 {
  fill: rgb(244 114 182 / 0.55) !important;
}

.tw-fill-pink-400\/60 {
  fill: rgb(244 114 182 / 0.6) !important;
}

.tw-fill-pink-400\/65 {
  fill: rgb(244 114 182 / 0.65) !important;
}

.tw-fill-pink-400\/70 {
  fill: rgb(244 114 182 / 0.7) !important;
}

.tw-fill-pink-400\/75 {
  fill: rgb(244 114 182 / 0.75) !important;
}

.tw-fill-pink-400\/80 {
  fill: rgb(244 114 182 / 0.8) !important;
}

.tw-fill-pink-400\/85 {
  fill: rgb(244 114 182 / 0.85) !important;
}

.tw-fill-pink-400\/90 {
  fill: rgb(244 114 182 / 0.9) !important;
}

.tw-fill-pink-400\/95 {
  fill: rgb(244 114 182 / 0.95) !important;
}

.tw-fill-pink-50 {
  fill: #fdf2f8 !important;
}

.tw-fill-pink-50\/0 {
  fill: rgb(253 242 248 / 0) !important;
}

.tw-fill-pink-50\/10 {
  fill: rgb(253 242 248 / 0.1) !important;
}

.tw-fill-pink-50\/100 {
  fill: rgb(253 242 248 / 1) !important;
}

.tw-fill-pink-50\/15 {
  fill: rgb(253 242 248 / 0.15) !important;
}

.tw-fill-pink-50\/20 {
  fill: rgb(253 242 248 / 0.2) !important;
}

.tw-fill-pink-50\/25 {
  fill: rgb(253 242 248 / 0.25) !important;
}

.tw-fill-pink-50\/30 {
  fill: rgb(253 242 248 / 0.3) !important;
}

.tw-fill-pink-50\/35 {
  fill: rgb(253 242 248 / 0.35) !important;
}

.tw-fill-pink-50\/40 {
  fill: rgb(253 242 248 / 0.4) !important;
}

.tw-fill-pink-50\/45 {
  fill: rgb(253 242 248 / 0.45) !important;
}

.tw-fill-pink-50\/5 {
  fill: rgb(253 242 248 / 0.05) !important;
}

.tw-fill-pink-50\/50 {
  fill: rgb(253 242 248 / 0.5) !important;
}

.tw-fill-pink-50\/55 {
  fill: rgb(253 242 248 / 0.55) !important;
}

.tw-fill-pink-50\/60 {
  fill: rgb(253 242 248 / 0.6) !important;
}

.tw-fill-pink-50\/65 {
  fill: rgb(253 242 248 / 0.65) !important;
}

.tw-fill-pink-50\/70 {
  fill: rgb(253 242 248 / 0.7) !important;
}

.tw-fill-pink-50\/75 {
  fill: rgb(253 242 248 / 0.75) !important;
}

.tw-fill-pink-50\/80 {
  fill: rgb(253 242 248 / 0.8) !important;
}

.tw-fill-pink-50\/85 {
  fill: rgb(253 242 248 / 0.85) !important;
}

.tw-fill-pink-50\/90 {
  fill: rgb(253 242 248 / 0.9) !important;
}

.tw-fill-pink-50\/95 {
  fill: rgb(253 242 248 / 0.95) !important;
}

.tw-fill-pink-500 {
  fill: #ec4899 !important;
}

.tw-fill-pink-500\/0 {
  fill: rgb(236 72 153 / 0) !important;
}

.tw-fill-pink-500\/10 {
  fill: rgb(236 72 153 / 0.1) !important;
}

.tw-fill-pink-500\/100 {
  fill: rgb(236 72 153 / 1) !important;
}

.tw-fill-pink-500\/15 {
  fill: rgb(236 72 153 / 0.15) !important;
}

.tw-fill-pink-500\/20 {
  fill: rgb(236 72 153 / 0.2) !important;
}

.tw-fill-pink-500\/25 {
  fill: rgb(236 72 153 / 0.25) !important;
}

.tw-fill-pink-500\/30 {
  fill: rgb(236 72 153 / 0.3) !important;
}

.tw-fill-pink-500\/35 {
  fill: rgb(236 72 153 / 0.35) !important;
}

.tw-fill-pink-500\/40 {
  fill: rgb(236 72 153 / 0.4) !important;
}

.tw-fill-pink-500\/45 {
  fill: rgb(236 72 153 / 0.45) !important;
}

.tw-fill-pink-500\/5 {
  fill: rgb(236 72 153 / 0.05) !important;
}

.tw-fill-pink-500\/50 {
  fill: rgb(236 72 153 / 0.5) !important;
}

.tw-fill-pink-500\/55 {
  fill: rgb(236 72 153 / 0.55) !important;
}

.tw-fill-pink-500\/60 {
  fill: rgb(236 72 153 / 0.6) !important;
}

.tw-fill-pink-500\/65 {
  fill: rgb(236 72 153 / 0.65) !important;
}

.tw-fill-pink-500\/70 {
  fill: rgb(236 72 153 / 0.7) !important;
}

.tw-fill-pink-500\/75 {
  fill: rgb(236 72 153 / 0.75) !important;
}

.tw-fill-pink-500\/80 {
  fill: rgb(236 72 153 / 0.8) !important;
}

.tw-fill-pink-500\/85 {
  fill: rgb(236 72 153 / 0.85) !important;
}

.tw-fill-pink-500\/90 {
  fill: rgb(236 72 153 / 0.9) !important;
}

.tw-fill-pink-500\/95 {
  fill: rgb(236 72 153 / 0.95) !important;
}

.tw-fill-pink-600 {
  fill: #db2777 !important;
}

.tw-fill-pink-600\/0 {
  fill: rgb(219 39 119 / 0) !important;
}

.tw-fill-pink-600\/10 {
  fill: rgb(219 39 119 / 0.1) !important;
}

.tw-fill-pink-600\/100 {
  fill: rgb(219 39 119 / 1) !important;
}

.tw-fill-pink-600\/15 {
  fill: rgb(219 39 119 / 0.15) !important;
}

.tw-fill-pink-600\/20 {
  fill: rgb(219 39 119 / 0.2) !important;
}

.tw-fill-pink-600\/25 {
  fill: rgb(219 39 119 / 0.25) !important;
}

.tw-fill-pink-600\/30 {
  fill: rgb(219 39 119 / 0.3) !important;
}

.tw-fill-pink-600\/35 {
  fill: rgb(219 39 119 / 0.35) !important;
}

.tw-fill-pink-600\/40 {
  fill: rgb(219 39 119 / 0.4) !important;
}

.tw-fill-pink-600\/45 {
  fill: rgb(219 39 119 / 0.45) !important;
}

.tw-fill-pink-600\/5 {
  fill: rgb(219 39 119 / 0.05) !important;
}

.tw-fill-pink-600\/50 {
  fill: rgb(219 39 119 / 0.5) !important;
}

.tw-fill-pink-600\/55 {
  fill: rgb(219 39 119 / 0.55) !important;
}

.tw-fill-pink-600\/60 {
  fill: rgb(219 39 119 / 0.6) !important;
}

.tw-fill-pink-600\/65 {
  fill: rgb(219 39 119 / 0.65) !important;
}

.tw-fill-pink-600\/70 {
  fill: rgb(219 39 119 / 0.7) !important;
}

.tw-fill-pink-600\/75 {
  fill: rgb(219 39 119 / 0.75) !important;
}

.tw-fill-pink-600\/80 {
  fill: rgb(219 39 119 / 0.8) !important;
}

.tw-fill-pink-600\/85 {
  fill: rgb(219 39 119 / 0.85) !important;
}

.tw-fill-pink-600\/90 {
  fill: rgb(219 39 119 / 0.9) !important;
}

.tw-fill-pink-600\/95 {
  fill: rgb(219 39 119 / 0.95) !important;
}

.tw-fill-pink-700 {
  fill: #be185d !important;
}

.tw-fill-pink-700\/0 {
  fill: rgb(190 24 93 / 0) !important;
}

.tw-fill-pink-700\/10 {
  fill: rgb(190 24 93 / 0.1) !important;
}

.tw-fill-pink-700\/100 {
  fill: rgb(190 24 93 / 1) !important;
}

.tw-fill-pink-700\/15 {
  fill: rgb(190 24 93 / 0.15) !important;
}

.tw-fill-pink-700\/20 {
  fill: rgb(190 24 93 / 0.2) !important;
}

.tw-fill-pink-700\/25 {
  fill: rgb(190 24 93 / 0.25) !important;
}

.tw-fill-pink-700\/30 {
  fill: rgb(190 24 93 / 0.3) !important;
}

.tw-fill-pink-700\/35 {
  fill: rgb(190 24 93 / 0.35) !important;
}

.tw-fill-pink-700\/40 {
  fill: rgb(190 24 93 / 0.4) !important;
}

.tw-fill-pink-700\/45 {
  fill: rgb(190 24 93 / 0.45) !important;
}

.tw-fill-pink-700\/5 {
  fill: rgb(190 24 93 / 0.05) !important;
}

.tw-fill-pink-700\/50 {
  fill: rgb(190 24 93 / 0.5) !important;
}

.tw-fill-pink-700\/55 {
  fill: rgb(190 24 93 / 0.55) !important;
}

.tw-fill-pink-700\/60 {
  fill: rgb(190 24 93 / 0.6) !important;
}

.tw-fill-pink-700\/65 {
  fill: rgb(190 24 93 / 0.65) !important;
}

.tw-fill-pink-700\/70 {
  fill: rgb(190 24 93 / 0.7) !important;
}

.tw-fill-pink-700\/75 {
  fill: rgb(190 24 93 / 0.75) !important;
}

.tw-fill-pink-700\/80 {
  fill: rgb(190 24 93 / 0.8) !important;
}

.tw-fill-pink-700\/85 {
  fill: rgb(190 24 93 / 0.85) !important;
}

.tw-fill-pink-700\/90 {
  fill: rgb(190 24 93 / 0.9) !important;
}

.tw-fill-pink-700\/95 {
  fill: rgb(190 24 93 / 0.95) !important;
}

.tw-fill-pink-800 {
  fill: #9d174d !important;
}

.tw-fill-pink-800\/0 {
  fill: rgb(157 23 77 / 0) !important;
}

.tw-fill-pink-800\/10 {
  fill: rgb(157 23 77 / 0.1) !important;
}

.tw-fill-pink-800\/100 {
  fill: rgb(157 23 77 / 1) !important;
}

.tw-fill-pink-800\/15 {
  fill: rgb(157 23 77 / 0.15) !important;
}

.tw-fill-pink-800\/20 {
  fill: rgb(157 23 77 / 0.2) !important;
}

.tw-fill-pink-800\/25 {
  fill: rgb(157 23 77 / 0.25) !important;
}

.tw-fill-pink-800\/30 {
  fill: rgb(157 23 77 / 0.3) !important;
}

.tw-fill-pink-800\/35 {
  fill: rgb(157 23 77 / 0.35) !important;
}

.tw-fill-pink-800\/40 {
  fill: rgb(157 23 77 / 0.4) !important;
}

.tw-fill-pink-800\/45 {
  fill: rgb(157 23 77 / 0.45) !important;
}

.tw-fill-pink-800\/5 {
  fill: rgb(157 23 77 / 0.05) !important;
}

.tw-fill-pink-800\/50 {
  fill: rgb(157 23 77 / 0.5) !important;
}

.tw-fill-pink-800\/55 {
  fill: rgb(157 23 77 / 0.55) !important;
}

.tw-fill-pink-800\/60 {
  fill: rgb(157 23 77 / 0.6) !important;
}

.tw-fill-pink-800\/65 {
  fill: rgb(157 23 77 / 0.65) !important;
}

.tw-fill-pink-800\/70 {
  fill: rgb(157 23 77 / 0.7) !important;
}

.tw-fill-pink-800\/75 {
  fill: rgb(157 23 77 / 0.75) !important;
}

.tw-fill-pink-800\/80 {
  fill: rgb(157 23 77 / 0.8) !important;
}

.tw-fill-pink-800\/85 {
  fill: rgb(157 23 77 / 0.85) !important;
}

.tw-fill-pink-800\/90 {
  fill: rgb(157 23 77 / 0.9) !important;
}

.tw-fill-pink-800\/95 {
  fill: rgb(157 23 77 / 0.95) !important;
}

.tw-fill-pink-900 {
  fill: #831843 !important;
}

.tw-fill-pink-900\/0 {
  fill: rgb(131 24 67 / 0) !important;
}

.tw-fill-pink-900\/10 {
  fill: rgb(131 24 67 / 0.1) !important;
}

.tw-fill-pink-900\/100 {
  fill: rgb(131 24 67 / 1) !important;
}

.tw-fill-pink-900\/15 {
  fill: rgb(131 24 67 / 0.15) !important;
}

.tw-fill-pink-900\/20 {
  fill: rgb(131 24 67 / 0.2) !important;
}

.tw-fill-pink-900\/25 {
  fill: rgb(131 24 67 / 0.25) !important;
}

.tw-fill-pink-900\/30 {
  fill: rgb(131 24 67 / 0.3) !important;
}

.tw-fill-pink-900\/35 {
  fill: rgb(131 24 67 / 0.35) !important;
}

.tw-fill-pink-900\/40 {
  fill: rgb(131 24 67 / 0.4) !important;
}

.tw-fill-pink-900\/45 {
  fill: rgb(131 24 67 / 0.45) !important;
}

.tw-fill-pink-900\/5 {
  fill: rgb(131 24 67 / 0.05) !important;
}

.tw-fill-pink-900\/50 {
  fill: rgb(131 24 67 / 0.5) !important;
}

.tw-fill-pink-900\/55 {
  fill: rgb(131 24 67 / 0.55) !important;
}

.tw-fill-pink-900\/60 {
  fill: rgb(131 24 67 / 0.6) !important;
}

.tw-fill-pink-900\/65 {
  fill: rgb(131 24 67 / 0.65) !important;
}

.tw-fill-pink-900\/70 {
  fill: rgb(131 24 67 / 0.7) !important;
}

.tw-fill-pink-900\/75 {
  fill: rgb(131 24 67 / 0.75) !important;
}

.tw-fill-pink-900\/80 {
  fill: rgb(131 24 67 / 0.8) !important;
}

.tw-fill-pink-900\/85 {
  fill: rgb(131 24 67 / 0.85) !important;
}

.tw-fill-pink-900\/90 {
  fill: rgb(131 24 67 / 0.9) !important;
}

.tw-fill-pink-900\/95 {
  fill: rgb(131 24 67 / 0.95) !important;
}

.tw-fill-pink-950 {
  fill: #500724 !important;
}

.tw-fill-pink-950\/0 {
  fill: rgb(80 7 36 / 0) !important;
}

.tw-fill-pink-950\/10 {
  fill: rgb(80 7 36 / 0.1) !important;
}

.tw-fill-pink-950\/100 {
  fill: rgb(80 7 36 / 1) !important;
}

.tw-fill-pink-950\/15 {
  fill: rgb(80 7 36 / 0.15) !important;
}

.tw-fill-pink-950\/20 {
  fill: rgb(80 7 36 / 0.2) !important;
}

.tw-fill-pink-950\/25 {
  fill: rgb(80 7 36 / 0.25) !important;
}

.tw-fill-pink-950\/30 {
  fill: rgb(80 7 36 / 0.3) !important;
}

.tw-fill-pink-950\/35 {
  fill: rgb(80 7 36 / 0.35) !important;
}

.tw-fill-pink-950\/40 {
  fill: rgb(80 7 36 / 0.4) !important;
}

.tw-fill-pink-950\/45 {
  fill: rgb(80 7 36 / 0.45) !important;
}

.tw-fill-pink-950\/5 {
  fill: rgb(80 7 36 / 0.05) !important;
}

.tw-fill-pink-950\/50 {
  fill: rgb(80 7 36 / 0.5) !important;
}

.tw-fill-pink-950\/55 {
  fill: rgb(80 7 36 / 0.55) !important;
}

.tw-fill-pink-950\/60 {
  fill: rgb(80 7 36 / 0.6) !important;
}

.tw-fill-pink-950\/65 {
  fill: rgb(80 7 36 / 0.65) !important;
}

.tw-fill-pink-950\/70 {
  fill: rgb(80 7 36 / 0.7) !important;
}

.tw-fill-pink-950\/75 {
  fill: rgb(80 7 36 / 0.75) !important;
}

.tw-fill-pink-950\/80 {
  fill: rgb(80 7 36 / 0.8) !important;
}

.tw-fill-pink-950\/85 {
  fill: rgb(80 7 36 / 0.85) !important;
}

.tw-fill-pink-950\/90 {
  fill: rgb(80 7 36 / 0.9) !important;
}

.tw-fill-pink-950\/95 {
  fill: rgb(80 7 36 / 0.95) !important;
}

.tw-fill-purple-100 {
  fill: #f3e8ff !important;
}

.tw-fill-purple-100\/0 {
  fill: rgb(243 232 255 / 0) !important;
}

.tw-fill-purple-100\/10 {
  fill: rgb(243 232 255 / 0.1) !important;
}

.tw-fill-purple-100\/100 {
  fill: rgb(243 232 255 / 1) !important;
}

.tw-fill-purple-100\/15 {
  fill: rgb(243 232 255 / 0.15) !important;
}

.tw-fill-purple-100\/20 {
  fill: rgb(243 232 255 / 0.2) !important;
}

.tw-fill-purple-100\/25 {
  fill: rgb(243 232 255 / 0.25) !important;
}

.tw-fill-purple-100\/30 {
  fill: rgb(243 232 255 / 0.3) !important;
}

.tw-fill-purple-100\/35 {
  fill: rgb(243 232 255 / 0.35) !important;
}

.tw-fill-purple-100\/40 {
  fill: rgb(243 232 255 / 0.4) !important;
}

.tw-fill-purple-100\/45 {
  fill: rgb(243 232 255 / 0.45) !important;
}

.tw-fill-purple-100\/5 {
  fill: rgb(243 232 255 / 0.05) !important;
}

.tw-fill-purple-100\/50 {
  fill: rgb(243 232 255 / 0.5) !important;
}

.tw-fill-purple-100\/55 {
  fill: rgb(243 232 255 / 0.55) !important;
}

.tw-fill-purple-100\/60 {
  fill: rgb(243 232 255 / 0.6) !important;
}

.tw-fill-purple-100\/65 {
  fill: rgb(243 232 255 / 0.65) !important;
}

.tw-fill-purple-100\/70 {
  fill: rgb(243 232 255 / 0.7) !important;
}

.tw-fill-purple-100\/75 {
  fill: rgb(243 232 255 / 0.75) !important;
}

.tw-fill-purple-100\/80 {
  fill: rgb(243 232 255 / 0.8) !important;
}

.tw-fill-purple-100\/85 {
  fill: rgb(243 232 255 / 0.85) !important;
}

.tw-fill-purple-100\/90 {
  fill: rgb(243 232 255 / 0.9) !important;
}

.tw-fill-purple-100\/95 {
  fill: rgb(243 232 255 / 0.95) !important;
}

.tw-fill-purple-200 {
  fill: #e9d5ff !important;
}

.tw-fill-purple-200\/0 {
  fill: rgb(233 213 255 / 0) !important;
}

.tw-fill-purple-200\/10 {
  fill: rgb(233 213 255 / 0.1) !important;
}

.tw-fill-purple-200\/100 {
  fill: rgb(233 213 255 / 1) !important;
}

.tw-fill-purple-200\/15 {
  fill: rgb(233 213 255 / 0.15) !important;
}

.tw-fill-purple-200\/20 {
  fill: rgb(233 213 255 / 0.2) !important;
}

.tw-fill-purple-200\/25 {
  fill: rgb(233 213 255 / 0.25) !important;
}

.tw-fill-purple-200\/30 {
  fill: rgb(233 213 255 / 0.3) !important;
}

.tw-fill-purple-200\/35 {
  fill: rgb(233 213 255 / 0.35) !important;
}

.tw-fill-purple-200\/40 {
  fill: rgb(233 213 255 / 0.4) !important;
}

.tw-fill-purple-200\/45 {
  fill: rgb(233 213 255 / 0.45) !important;
}

.tw-fill-purple-200\/5 {
  fill: rgb(233 213 255 / 0.05) !important;
}

.tw-fill-purple-200\/50 {
  fill: rgb(233 213 255 / 0.5) !important;
}

.tw-fill-purple-200\/55 {
  fill: rgb(233 213 255 / 0.55) !important;
}

.tw-fill-purple-200\/60 {
  fill: rgb(233 213 255 / 0.6) !important;
}

.tw-fill-purple-200\/65 {
  fill: rgb(233 213 255 / 0.65) !important;
}

.tw-fill-purple-200\/70 {
  fill: rgb(233 213 255 / 0.7) !important;
}

.tw-fill-purple-200\/75 {
  fill: rgb(233 213 255 / 0.75) !important;
}

.tw-fill-purple-200\/80 {
  fill: rgb(233 213 255 / 0.8) !important;
}

.tw-fill-purple-200\/85 {
  fill: rgb(233 213 255 / 0.85) !important;
}

.tw-fill-purple-200\/90 {
  fill: rgb(233 213 255 / 0.9) !important;
}

.tw-fill-purple-200\/95 {
  fill: rgb(233 213 255 / 0.95) !important;
}

.tw-fill-purple-300 {
  fill: #d8b4fe !important;
}

.tw-fill-purple-300\/0 {
  fill: rgb(216 180 254 / 0) !important;
}

.tw-fill-purple-300\/10 {
  fill: rgb(216 180 254 / 0.1) !important;
}

.tw-fill-purple-300\/100 {
  fill: rgb(216 180 254 / 1) !important;
}

.tw-fill-purple-300\/15 {
  fill: rgb(216 180 254 / 0.15) !important;
}

.tw-fill-purple-300\/20 {
  fill: rgb(216 180 254 / 0.2) !important;
}

.tw-fill-purple-300\/25 {
  fill: rgb(216 180 254 / 0.25) !important;
}

.tw-fill-purple-300\/30 {
  fill: rgb(216 180 254 / 0.3) !important;
}

.tw-fill-purple-300\/35 {
  fill: rgb(216 180 254 / 0.35) !important;
}

.tw-fill-purple-300\/40 {
  fill: rgb(216 180 254 / 0.4) !important;
}

.tw-fill-purple-300\/45 {
  fill: rgb(216 180 254 / 0.45) !important;
}

.tw-fill-purple-300\/5 {
  fill: rgb(216 180 254 / 0.05) !important;
}

.tw-fill-purple-300\/50 {
  fill: rgb(216 180 254 / 0.5) !important;
}

.tw-fill-purple-300\/55 {
  fill: rgb(216 180 254 / 0.55) !important;
}

.tw-fill-purple-300\/60 {
  fill: rgb(216 180 254 / 0.6) !important;
}

.tw-fill-purple-300\/65 {
  fill: rgb(216 180 254 / 0.65) !important;
}

.tw-fill-purple-300\/70 {
  fill: rgb(216 180 254 / 0.7) !important;
}

.tw-fill-purple-300\/75 {
  fill: rgb(216 180 254 / 0.75) !important;
}

.tw-fill-purple-300\/80 {
  fill: rgb(216 180 254 / 0.8) !important;
}

.tw-fill-purple-300\/85 {
  fill: rgb(216 180 254 / 0.85) !important;
}

.tw-fill-purple-300\/90 {
  fill: rgb(216 180 254 / 0.9) !important;
}

.tw-fill-purple-300\/95 {
  fill: rgb(216 180 254 / 0.95) !important;
}

.tw-fill-purple-400 {
  fill: #c084fc !important;
}

.tw-fill-purple-400\/0 {
  fill: rgb(192 132 252 / 0) !important;
}

.tw-fill-purple-400\/10 {
  fill: rgb(192 132 252 / 0.1) !important;
}

.tw-fill-purple-400\/100 {
  fill: rgb(192 132 252 / 1) !important;
}

.tw-fill-purple-400\/15 {
  fill: rgb(192 132 252 / 0.15) !important;
}

.tw-fill-purple-400\/20 {
  fill: rgb(192 132 252 / 0.2) !important;
}

.tw-fill-purple-400\/25 {
  fill: rgb(192 132 252 / 0.25) !important;
}

.tw-fill-purple-400\/30 {
  fill: rgb(192 132 252 / 0.3) !important;
}

.tw-fill-purple-400\/35 {
  fill: rgb(192 132 252 / 0.35) !important;
}

.tw-fill-purple-400\/40 {
  fill: rgb(192 132 252 / 0.4) !important;
}

.tw-fill-purple-400\/45 {
  fill: rgb(192 132 252 / 0.45) !important;
}

.tw-fill-purple-400\/5 {
  fill: rgb(192 132 252 / 0.05) !important;
}

.tw-fill-purple-400\/50 {
  fill: rgb(192 132 252 / 0.5) !important;
}

.tw-fill-purple-400\/55 {
  fill: rgb(192 132 252 / 0.55) !important;
}

.tw-fill-purple-400\/60 {
  fill: rgb(192 132 252 / 0.6) !important;
}

.tw-fill-purple-400\/65 {
  fill: rgb(192 132 252 / 0.65) !important;
}

.tw-fill-purple-400\/70 {
  fill: rgb(192 132 252 / 0.7) !important;
}

.tw-fill-purple-400\/75 {
  fill: rgb(192 132 252 / 0.75) !important;
}

.tw-fill-purple-400\/80 {
  fill: rgb(192 132 252 / 0.8) !important;
}

.tw-fill-purple-400\/85 {
  fill: rgb(192 132 252 / 0.85) !important;
}

.tw-fill-purple-400\/90 {
  fill: rgb(192 132 252 / 0.9) !important;
}

.tw-fill-purple-400\/95 {
  fill: rgb(192 132 252 / 0.95) !important;
}

.tw-fill-purple-50 {
  fill: #faf5ff !important;
}

.tw-fill-purple-50\/0 {
  fill: rgb(250 245 255 / 0) !important;
}

.tw-fill-purple-50\/10 {
  fill: rgb(250 245 255 / 0.1) !important;
}

.tw-fill-purple-50\/100 {
  fill: rgb(250 245 255 / 1) !important;
}

.tw-fill-purple-50\/15 {
  fill: rgb(250 245 255 / 0.15) !important;
}

.tw-fill-purple-50\/20 {
  fill: rgb(250 245 255 / 0.2) !important;
}

.tw-fill-purple-50\/25 {
  fill: rgb(250 245 255 / 0.25) !important;
}

.tw-fill-purple-50\/30 {
  fill: rgb(250 245 255 / 0.3) !important;
}

.tw-fill-purple-50\/35 {
  fill: rgb(250 245 255 / 0.35) !important;
}

.tw-fill-purple-50\/40 {
  fill: rgb(250 245 255 / 0.4) !important;
}

.tw-fill-purple-50\/45 {
  fill: rgb(250 245 255 / 0.45) !important;
}

.tw-fill-purple-50\/5 {
  fill: rgb(250 245 255 / 0.05) !important;
}

.tw-fill-purple-50\/50 {
  fill: rgb(250 245 255 / 0.5) !important;
}

.tw-fill-purple-50\/55 {
  fill: rgb(250 245 255 / 0.55) !important;
}

.tw-fill-purple-50\/60 {
  fill: rgb(250 245 255 / 0.6) !important;
}

.tw-fill-purple-50\/65 {
  fill: rgb(250 245 255 / 0.65) !important;
}

.tw-fill-purple-50\/70 {
  fill: rgb(250 245 255 / 0.7) !important;
}

.tw-fill-purple-50\/75 {
  fill: rgb(250 245 255 / 0.75) !important;
}

.tw-fill-purple-50\/80 {
  fill: rgb(250 245 255 / 0.8) !important;
}

.tw-fill-purple-50\/85 {
  fill: rgb(250 245 255 / 0.85) !important;
}

.tw-fill-purple-50\/90 {
  fill: rgb(250 245 255 / 0.9) !important;
}

.tw-fill-purple-50\/95 {
  fill: rgb(250 245 255 / 0.95) !important;
}

.tw-fill-purple-500 {
  fill: #a855f7 !important;
}

.tw-fill-purple-500\/0 {
  fill: rgb(168 85 247 / 0) !important;
}

.tw-fill-purple-500\/10 {
  fill: rgb(168 85 247 / 0.1) !important;
}

.tw-fill-purple-500\/100 {
  fill: rgb(168 85 247 / 1) !important;
}

.tw-fill-purple-500\/15 {
  fill: rgb(168 85 247 / 0.15) !important;
}

.tw-fill-purple-500\/20 {
  fill: rgb(168 85 247 / 0.2) !important;
}

.tw-fill-purple-500\/25 {
  fill: rgb(168 85 247 / 0.25) !important;
}

.tw-fill-purple-500\/30 {
  fill: rgb(168 85 247 / 0.3) !important;
}

.tw-fill-purple-500\/35 {
  fill: rgb(168 85 247 / 0.35) !important;
}

.tw-fill-purple-500\/40 {
  fill: rgb(168 85 247 / 0.4) !important;
}

.tw-fill-purple-500\/45 {
  fill: rgb(168 85 247 / 0.45) !important;
}

.tw-fill-purple-500\/5 {
  fill: rgb(168 85 247 / 0.05) !important;
}

.tw-fill-purple-500\/50 {
  fill: rgb(168 85 247 / 0.5) !important;
}

.tw-fill-purple-500\/55 {
  fill: rgb(168 85 247 / 0.55) !important;
}

.tw-fill-purple-500\/60 {
  fill: rgb(168 85 247 / 0.6) !important;
}

.tw-fill-purple-500\/65 {
  fill: rgb(168 85 247 / 0.65) !important;
}

.tw-fill-purple-500\/70 {
  fill: rgb(168 85 247 / 0.7) !important;
}

.tw-fill-purple-500\/75 {
  fill: rgb(168 85 247 / 0.75) !important;
}

.tw-fill-purple-500\/80 {
  fill: rgb(168 85 247 / 0.8) !important;
}

.tw-fill-purple-500\/85 {
  fill: rgb(168 85 247 / 0.85) !important;
}

.tw-fill-purple-500\/90 {
  fill: rgb(168 85 247 / 0.9) !important;
}

.tw-fill-purple-500\/95 {
  fill: rgb(168 85 247 / 0.95) !important;
}

.tw-fill-purple-600 {
  fill: #9333ea !important;
}

.tw-fill-purple-600\/0 {
  fill: rgb(147 51 234 / 0) !important;
}

.tw-fill-purple-600\/10 {
  fill: rgb(147 51 234 / 0.1) !important;
}

.tw-fill-purple-600\/100 {
  fill: rgb(147 51 234 / 1) !important;
}

.tw-fill-purple-600\/15 {
  fill: rgb(147 51 234 / 0.15) !important;
}

.tw-fill-purple-600\/20 {
  fill: rgb(147 51 234 / 0.2) !important;
}

.tw-fill-purple-600\/25 {
  fill: rgb(147 51 234 / 0.25) !important;
}

.tw-fill-purple-600\/30 {
  fill: rgb(147 51 234 / 0.3) !important;
}

.tw-fill-purple-600\/35 {
  fill: rgb(147 51 234 / 0.35) !important;
}

.tw-fill-purple-600\/40 {
  fill: rgb(147 51 234 / 0.4) !important;
}

.tw-fill-purple-600\/45 {
  fill: rgb(147 51 234 / 0.45) !important;
}

.tw-fill-purple-600\/5 {
  fill: rgb(147 51 234 / 0.05) !important;
}

.tw-fill-purple-600\/50 {
  fill: rgb(147 51 234 / 0.5) !important;
}

.tw-fill-purple-600\/55 {
  fill: rgb(147 51 234 / 0.55) !important;
}

.tw-fill-purple-600\/60 {
  fill: rgb(147 51 234 / 0.6) !important;
}

.tw-fill-purple-600\/65 {
  fill: rgb(147 51 234 / 0.65) !important;
}

.tw-fill-purple-600\/70 {
  fill: rgb(147 51 234 / 0.7) !important;
}

.tw-fill-purple-600\/75 {
  fill: rgb(147 51 234 / 0.75) !important;
}

.tw-fill-purple-600\/80 {
  fill: rgb(147 51 234 / 0.8) !important;
}

.tw-fill-purple-600\/85 {
  fill: rgb(147 51 234 / 0.85) !important;
}

.tw-fill-purple-600\/90 {
  fill: rgb(147 51 234 / 0.9) !important;
}

.tw-fill-purple-600\/95 {
  fill: rgb(147 51 234 / 0.95) !important;
}

.tw-fill-purple-700 {
  fill: #7e22ce !important;
}

.tw-fill-purple-700\/0 {
  fill: rgb(126 34 206 / 0) !important;
}

.tw-fill-purple-700\/10 {
  fill: rgb(126 34 206 / 0.1) !important;
}

.tw-fill-purple-700\/100 {
  fill: rgb(126 34 206 / 1) !important;
}

.tw-fill-purple-700\/15 {
  fill: rgb(126 34 206 / 0.15) !important;
}

.tw-fill-purple-700\/20 {
  fill: rgb(126 34 206 / 0.2) !important;
}

.tw-fill-purple-700\/25 {
  fill: rgb(126 34 206 / 0.25) !important;
}

.tw-fill-purple-700\/30 {
  fill: rgb(126 34 206 / 0.3) !important;
}

.tw-fill-purple-700\/35 {
  fill: rgb(126 34 206 / 0.35) !important;
}

.tw-fill-purple-700\/40 {
  fill: rgb(126 34 206 / 0.4) !important;
}

.tw-fill-purple-700\/45 {
  fill: rgb(126 34 206 / 0.45) !important;
}

.tw-fill-purple-700\/5 {
  fill: rgb(126 34 206 / 0.05) !important;
}

.tw-fill-purple-700\/50 {
  fill: rgb(126 34 206 / 0.5) !important;
}

.tw-fill-purple-700\/55 {
  fill: rgb(126 34 206 / 0.55) !important;
}

.tw-fill-purple-700\/60 {
  fill: rgb(126 34 206 / 0.6) !important;
}

.tw-fill-purple-700\/65 {
  fill: rgb(126 34 206 / 0.65) !important;
}

.tw-fill-purple-700\/70 {
  fill: rgb(126 34 206 / 0.7) !important;
}

.tw-fill-purple-700\/75 {
  fill: rgb(126 34 206 / 0.75) !important;
}

.tw-fill-purple-700\/80 {
  fill: rgb(126 34 206 / 0.8) !important;
}

.tw-fill-purple-700\/85 {
  fill: rgb(126 34 206 / 0.85) !important;
}

.tw-fill-purple-700\/90 {
  fill: rgb(126 34 206 / 0.9) !important;
}

.tw-fill-purple-700\/95 {
  fill: rgb(126 34 206 / 0.95) !important;
}

.tw-fill-purple-800 {
  fill: #6b21a8 !important;
}

.tw-fill-purple-800\/0 {
  fill: rgb(107 33 168 / 0) !important;
}

.tw-fill-purple-800\/10 {
  fill: rgb(107 33 168 / 0.1) !important;
}

.tw-fill-purple-800\/100 {
  fill: rgb(107 33 168 / 1) !important;
}

.tw-fill-purple-800\/15 {
  fill: rgb(107 33 168 / 0.15) !important;
}

.tw-fill-purple-800\/20 {
  fill: rgb(107 33 168 / 0.2) !important;
}

.tw-fill-purple-800\/25 {
  fill: rgb(107 33 168 / 0.25) !important;
}

.tw-fill-purple-800\/30 {
  fill: rgb(107 33 168 / 0.3) !important;
}

.tw-fill-purple-800\/35 {
  fill: rgb(107 33 168 / 0.35) !important;
}

.tw-fill-purple-800\/40 {
  fill: rgb(107 33 168 / 0.4) !important;
}

.tw-fill-purple-800\/45 {
  fill: rgb(107 33 168 / 0.45) !important;
}

.tw-fill-purple-800\/5 {
  fill: rgb(107 33 168 / 0.05) !important;
}

.tw-fill-purple-800\/50 {
  fill: rgb(107 33 168 / 0.5) !important;
}

.tw-fill-purple-800\/55 {
  fill: rgb(107 33 168 / 0.55) !important;
}

.tw-fill-purple-800\/60 {
  fill: rgb(107 33 168 / 0.6) !important;
}

.tw-fill-purple-800\/65 {
  fill: rgb(107 33 168 / 0.65) !important;
}

.tw-fill-purple-800\/70 {
  fill: rgb(107 33 168 / 0.7) !important;
}

.tw-fill-purple-800\/75 {
  fill: rgb(107 33 168 / 0.75) !important;
}

.tw-fill-purple-800\/80 {
  fill: rgb(107 33 168 / 0.8) !important;
}

.tw-fill-purple-800\/85 {
  fill: rgb(107 33 168 / 0.85) !important;
}

.tw-fill-purple-800\/90 {
  fill: rgb(107 33 168 / 0.9) !important;
}

.tw-fill-purple-800\/95 {
  fill: rgb(107 33 168 / 0.95) !important;
}

.tw-fill-purple-900 {
  fill: #581c87 !important;
}

.tw-fill-purple-900\/0 {
  fill: rgb(88 28 135 / 0) !important;
}

.tw-fill-purple-900\/10 {
  fill: rgb(88 28 135 / 0.1) !important;
}

.tw-fill-purple-900\/100 {
  fill: rgb(88 28 135 / 1) !important;
}

.tw-fill-purple-900\/15 {
  fill: rgb(88 28 135 / 0.15) !important;
}

.tw-fill-purple-900\/20 {
  fill: rgb(88 28 135 / 0.2) !important;
}

.tw-fill-purple-900\/25 {
  fill: rgb(88 28 135 / 0.25) !important;
}

.tw-fill-purple-900\/30 {
  fill: rgb(88 28 135 / 0.3) !important;
}

.tw-fill-purple-900\/35 {
  fill: rgb(88 28 135 / 0.35) !important;
}

.tw-fill-purple-900\/40 {
  fill: rgb(88 28 135 / 0.4) !important;
}

.tw-fill-purple-900\/45 {
  fill: rgb(88 28 135 / 0.45) !important;
}

.tw-fill-purple-900\/5 {
  fill: rgb(88 28 135 / 0.05) !important;
}

.tw-fill-purple-900\/50 {
  fill: rgb(88 28 135 / 0.5) !important;
}

.tw-fill-purple-900\/55 {
  fill: rgb(88 28 135 / 0.55) !important;
}

.tw-fill-purple-900\/60 {
  fill: rgb(88 28 135 / 0.6) !important;
}

.tw-fill-purple-900\/65 {
  fill: rgb(88 28 135 / 0.65) !important;
}

.tw-fill-purple-900\/70 {
  fill: rgb(88 28 135 / 0.7) !important;
}

.tw-fill-purple-900\/75 {
  fill: rgb(88 28 135 / 0.75) !important;
}

.tw-fill-purple-900\/80 {
  fill: rgb(88 28 135 / 0.8) !important;
}

.tw-fill-purple-900\/85 {
  fill: rgb(88 28 135 / 0.85) !important;
}

.tw-fill-purple-900\/90 {
  fill: rgb(88 28 135 / 0.9) !important;
}

.tw-fill-purple-900\/95 {
  fill: rgb(88 28 135 / 0.95) !important;
}

.tw-fill-purple-950 {
  fill: #3b0764 !important;
}

.tw-fill-purple-950\/0 {
  fill: rgb(59 7 100 / 0) !important;
}

.tw-fill-purple-950\/10 {
  fill: rgb(59 7 100 / 0.1) !important;
}

.tw-fill-purple-950\/100 {
  fill: rgb(59 7 100 / 1) !important;
}

.tw-fill-purple-950\/15 {
  fill: rgb(59 7 100 / 0.15) !important;
}

.tw-fill-purple-950\/20 {
  fill: rgb(59 7 100 / 0.2) !important;
}

.tw-fill-purple-950\/25 {
  fill: rgb(59 7 100 / 0.25) !important;
}

.tw-fill-purple-950\/30 {
  fill: rgb(59 7 100 / 0.3) !important;
}

.tw-fill-purple-950\/35 {
  fill: rgb(59 7 100 / 0.35) !important;
}

.tw-fill-purple-950\/40 {
  fill: rgb(59 7 100 / 0.4) !important;
}

.tw-fill-purple-950\/45 {
  fill: rgb(59 7 100 / 0.45) !important;
}

.tw-fill-purple-950\/5 {
  fill: rgb(59 7 100 / 0.05) !important;
}

.tw-fill-purple-950\/50 {
  fill: rgb(59 7 100 / 0.5) !important;
}

.tw-fill-purple-950\/55 {
  fill: rgb(59 7 100 / 0.55) !important;
}

.tw-fill-purple-950\/60 {
  fill: rgb(59 7 100 / 0.6) !important;
}

.tw-fill-purple-950\/65 {
  fill: rgb(59 7 100 / 0.65) !important;
}

.tw-fill-purple-950\/70 {
  fill: rgb(59 7 100 / 0.7) !important;
}

.tw-fill-purple-950\/75 {
  fill: rgb(59 7 100 / 0.75) !important;
}

.tw-fill-purple-950\/80 {
  fill: rgb(59 7 100 / 0.8) !important;
}

.tw-fill-purple-950\/85 {
  fill: rgb(59 7 100 / 0.85) !important;
}

.tw-fill-purple-950\/90 {
  fill: rgb(59 7 100 / 0.9) !important;
}

.tw-fill-purple-950\/95 {
  fill: rgb(59 7 100 / 0.95) !important;
}

.tw-fill-red-100 {
  fill: #fee2e2 !important;
}

.tw-fill-red-100\/0 {
  fill: rgb(254 226 226 / 0) !important;
}

.tw-fill-red-100\/10 {
  fill: rgb(254 226 226 / 0.1) !important;
}

.tw-fill-red-100\/100 {
  fill: rgb(254 226 226 / 1) !important;
}

.tw-fill-red-100\/15 {
  fill: rgb(254 226 226 / 0.15) !important;
}

.tw-fill-red-100\/20 {
  fill: rgb(254 226 226 / 0.2) !important;
}

.tw-fill-red-100\/25 {
  fill: rgb(254 226 226 / 0.25) !important;
}

.tw-fill-red-100\/30 {
  fill: rgb(254 226 226 / 0.3) !important;
}

.tw-fill-red-100\/35 {
  fill: rgb(254 226 226 / 0.35) !important;
}

.tw-fill-red-100\/40 {
  fill: rgb(254 226 226 / 0.4) !important;
}

.tw-fill-red-100\/45 {
  fill: rgb(254 226 226 / 0.45) !important;
}

.tw-fill-red-100\/5 {
  fill: rgb(254 226 226 / 0.05) !important;
}

.tw-fill-red-100\/50 {
  fill: rgb(254 226 226 / 0.5) !important;
}

.tw-fill-red-100\/55 {
  fill: rgb(254 226 226 / 0.55) !important;
}

.tw-fill-red-100\/60 {
  fill: rgb(254 226 226 / 0.6) !important;
}

.tw-fill-red-100\/65 {
  fill: rgb(254 226 226 / 0.65) !important;
}

.tw-fill-red-100\/70 {
  fill: rgb(254 226 226 / 0.7) !important;
}

.tw-fill-red-100\/75 {
  fill: rgb(254 226 226 / 0.75) !important;
}

.tw-fill-red-100\/80 {
  fill: rgb(254 226 226 / 0.8) !important;
}

.tw-fill-red-100\/85 {
  fill: rgb(254 226 226 / 0.85) !important;
}

.tw-fill-red-100\/90 {
  fill: rgb(254 226 226 / 0.9) !important;
}

.tw-fill-red-100\/95 {
  fill: rgb(254 226 226 / 0.95) !important;
}

.tw-fill-red-200 {
  fill: #fecaca !important;
}

.tw-fill-red-200\/0 {
  fill: rgb(254 202 202 / 0) !important;
}

.tw-fill-red-200\/10 {
  fill: rgb(254 202 202 / 0.1) !important;
}

.tw-fill-red-200\/100 {
  fill: rgb(254 202 202 / 1) !important;
}

.tw-fill-red-200\/15 {
  fill: rgb(254 202 202 / 0.15) !important;
}

.tw-fill-red-200\/20 {
  fill: rgb(254 202 202 / 0.2) !important;
}

.tw-fill-red-200\/25 {
  fill: rgb(254 202 202 / 0.25) !important;
}

.tw-fill-red-200\/30 {
  fill: rgb(254 202 202 / 0.3) !important;
}

.tw-fill-red-200\/35 {
  fill: rgb(254 202 202 / 0.35) !important;
}

.tw-fill-red-200\/40 {
  fill: rgb(254 202 202 / 0.4) !important;
}

.tw-fill-red-200\/45 {
  fill: rgb(254 202 202 / 0.45) !important;
}

.tw-fill-red-200\/5 {
  fill: rgb(254 202 202 / 0.05) !important;
}

.tw-fill-red-200\/50 {
  fill: rgb(254 202 202 / 0.5) !important;
}

.tw-fill-red-200\/55 {
  fill: rgb(254 202 202 / 0.55) !important;
}

.tw-fill-red-200\/60 {
  fill: rgb(254 202 202 / 0.6) !important;
}

.tw-fill-red-200\/65 {
  fill: rgb(254 202 202 / 0.65) !important;
}

.tw-fill-red-200\/70 {
  fill: rgb(254 202 202 / 0.7) !important;
}

.tw-fill-red-200\/75 {
  fill: rgb(254 202 202 / 0.75) !important;
}

.tw-fill-red-200\/80 {
  fill: rgb(254 202 202 / 0.8) !important;
}

.tw-fill-red-200\/85 {
  fill: rgb(254 202 202 / 0.85) !important;
}

.tw-fill-red-200\/90 {
  fill: rgb(254 202 202 / 0.9) !important;
}

.tw-fill-red-200\/95 {
  fill: rgb(254 202 202 / 0.95) !important;
}

.tw-fill-red-300 {
  fill: #fca5a5 !important;
}

.tw-fill-red-300\/0 {
  fill: rgb(252 165 165 / 0) !important;
}

.tw-fill-red-300\/10 {
  fill: rgb(252 165 165 / 0.1) !important;
}

.tw-fill-red-300\/100 {
  fill: rgb(252 165 165 / 1) !important;
}

.tw-fill-red-300\/15 {
  fill: rgb(252 165 165 / 0.15) !important;
}

.tw-fill-red-300\/20 {
  fill: rgb(252 165 165 / 0.2) !important;
}

.tw-fill-red-300\/25 {
  fill: rgb(252 165 165 / 0.25) !important;
}

.tw-fill-red-300\/30 {
  fill: rgb(252 165 165 / 0.3) !important;
}

.tw-fill-red-300\/35 {
  fill: rgb(252 165 165 / 0.35) !important;
}

.tw-fill-red-300\/40 {
  fill: rgb(252 165 165 / 0.4) !important;
}

.tw-fill-red-300\/45 {
  fill: rgb(252 165 165 / 0.45) !important;
}

.tw-fill-red-300\/5 {
  fill: rgb(252 165 165 / 0.05) !important;
}

.tw-fill-red-300\/50 {
  fill: rgb(252 165 165 / 0.5) !important;
}

.tw-fill-red-300\/55 {
  fill: rgb(252 165 165 / 0.55) !important;
}

.tw-fill-red-300\/60 {
  fill: rgb(252 165 165 / 0.6) !important;
}

.tw-fill-red-300\/65 {
  fill: rgb(252 165 165 / 0.65) !important;
}

.tw-fill-red-300\/70 {
  fill: rgb(252 165 165 / 0.7) !important;
}

.tw-fill-red-300\/75 {
  fill: rgb(252 165 165 / 0.75) !important;
}

.tw-fill-red-300\/80 {
  fill: rgb(252 165 165 / 0.8) !important;
}

.tw-fill-red-300\/85 {
  fill: rgb(252 165 165 / 0.85) !important;
}

.tw-fill-red-300\/90 {
  fill: rgb(252 165 165 / 0.9) !important;
}

.tw-fill-red-300\/95 {
  fill: rgb(252 165 165 / 0.95) !important;
}

.tw-fill-red-400 {
  fill: #f87171 !important;
}

.tw-fill-red-400\/0 {
  fill: rgb(248 113 113 / 0) !important;
}

.tw-fill-red-400\/10 {
  fill: rgb(248 113 113 / 0.1) !important;
}

.tw-fill-red-400\/100 {
  fill: rgb(248 113 113 / 1) !important;
}

.tw-fill-red-400\/15 {
  fill: rgb(248 113 113 / 0.15) !important;
}

.tw-fill-red-400\/20 {
  fill: rgb(248 113 113 / 0.2) !important;
}

.tw-fill-red-400\/25 {
  fill: rgb(248 113 113 / 0.25) !important;
}

.tw-fill-red-400\/30 {
  fill: rgb(248 113 113 / 0.3) !important;
}

.tw-fill-red-400\/35 {
  fill: rgb(248 113 113 / 0.35) !important;
}

.tw-fill-red-400\/40 {
  fill: rgb(248 113 113 / 0.4) !important;
}

.tw-fill-red-400\/45 {
  fill: rgb(248 113 113 / 0.45) !important;
}

.tw-fill-red-400\/5 {
  fill: rgb(248 113 113 / 0.05) !important;
}

.tw-fill-red-400\/50 {
  fill: rgb(248 113 113 / 0.5) !important;
}

.tw-fill-red-400\/55 {
  fill: rgb(248 113 113 / 0.55) !important;
}

.tw-fill-red-400\/60 {
  fill: rgb(248 113 113 / 0.6) !important;
}

.tw-fill-red-400\/65 {
  fill: rgb(248 113 113 / 0.65) !important;
}

.tw-fill-red-400\/70 {
  fill: rgb(248 113 113 / 0.7) !important;
}

.tw-fill-red-400\/75 {
  fill: rgb(248 113 113 / 0.75) !important;
}

.tw-fill-red-400\/80 {
  fill: rgb(248 113 113 / 0.8) !important;
}

.tw-fill-red-400\/85 {
  fill: rgb(248 113 113 / 0.85) !important;
}

.tw-fill-red-400\/90 {
  fill: rgb(248 113 113 / 0.9) !important;
}

.tw-fill-red-400\/95 {
  fill: rgb(248 113 113 / 0.95) !important;
}

.tw-fill-red-50 {
  fill: #fef2f2 !important;
}

.tw-fill-red-50\/0 {
  fill: rgb(254 242 242 / 0) !important;
}

.tw-fill-red-50\/10 {
  fill: rgb(254 242 242 / 0.1) !important;
}

.tw-fill-red-50\/100 {
  fill: rgb(254 242 242 / 1) !important;
}

.tw-fill-red-50\/15 {
  fill: rgb(254 242 242 / 0.15) !important;
}

.tw-fill-red-50\/20 {
  fill: rgb(254 242 242 / 0.2) !important;
}

.tw-fill-red-50\/25 {
  fill: rgb(254 242 242 / 0.25) !important;
}

.tw-fill-red-50\/30 {
  fill: rgb(254 242 242 / 0.3) !important;
}

.tw-fill-red-50\/35 {
  fill: rgb(254 242 242 / 0.35) !important;
}

.tw-fill-red-50\/40 {
  fill: rgb(254 242 242 / 0.4) !important;
}

.tw-fill-red-50\/45 {
  fill: rgb(254 242 242 / 0.45) !important;
}

.tw-fill-red-50\/5 {
  fill: rgb(254 242 242 / 0.05) !important;
}

.tw-fill-red-50\/50 {
  fill: rgb(254 242 242 / 0.5) !important;
}

.tw-fill-red-50\/55 {
  fill: rgb(254 242 242 / 0.55) !important;
}

.tw-fill-red-50\/60 {
  fill: rgb(254 242 242 / 0.6) !important;
}

.tw-fill-red-50\/65 {
  fill: rgb(254 242 242 / 0.65) !important;
}

.tw-fill-red-50\/70 {
  fill: rgb(254 242 242 / 0.7) !important;
}

.tw-fill-red-50\/75 {
  fill: rgb(254 242 242 / 0.75) !important;
}

.tw-fill-red-50\/80 {
  fill: rgb(254 242 242 / 0.8) !important;
}

.tw-fill-red-50\/85 {
  fill: rgb(254 242 242 / 0.85) !important;
}

.tw-fill-red-50\/90 {
  fill: rgb(254 242 242 / 0.9) !important;
}

.tw-fill-red-50\/95 {
  fill: rgb(254 242 242 / 0.95) !important;
}

.tw-fill-red-500 {
  fill: #ef4444 !important;
}

.tw-fill-red-500\/0 {
  fill: rgb(239 68 68 / 0) !important;
}

.tw-fill-red-500\/10 {
  fill: rgb(239 68 68 / 0.1) !important;
}

.tw-fill-red-500\/100 {
  fill: rgb(239 68 68 / 1) !important;
}

.tw-fill-red-500\/15 {
  fill: rgb(239 68 68 / 0.15) !important;
}

.tw-fill-red-500\/20 {
  fill: rgb(239 68 68 / 0.2) !important;
}

.tw-fill-red-500\/25 {
  fill: rgb(239 68 68 / 0.25) !important;
}

.tw-fill-red-500\/30 {
  fill: rgb(239 68 68 / 0.3) !important;
}

.tw-fill-red-500\/35 {
  fill: rgb(239 68 68 / 0.35) !important;
}

.tw-fill-red-500\/40 {
  fill: rgb(239 68 68 / 0.4) !important;
}

.tw-fill-red-500\/45 {
  fill: rgb(239 68 68 / 0.45) !important;
}

.tw-fill-red-500\/5 {
  fill: rgb(239 68 68 / 0.05) !important;
}

.tw-fill-red-500\/50 {
  fill: rgb(239 68 68 / 0.5) !important;
}

.tw-fill-red-500\/55 {
  fill: rgb(239 68 68 / 0.55) !important;
}

.tw-fill-red-500\/60 {
  fill: rgb(239 68 68 / 0.6) !important;
}

.tw-fill-red-500\/65 {
  fill: rgb(239 68 68 / 0.65) !important;
}

.tw-fill-red-500\/70 {
  fill: rgb(239 68 68 / 0.7) !important;
}

.tw-fill-red-500\/75 {
  fill: rgb(239 68 68 / 0.75) !important;
}

.tw-fill-red-500\/80 {
  fill: rgb(239 68 68 / 0.8) !important;
}

.tw-fill-red-500\/85 {
  fill: rgb(239 68 68 / 0.85) !important;
}

.tw-fill-red-500\/90 {
  fill: rgb(239 68 68 / 0.9) !important;
}

.tw-fill-red-500\/95 {
  fill: rgb(239 68 68 / 0.95) !important;
}

.tw-fill-red-600 {
  fill: #dc2626 !important;
}

.tw-fill-red-600\/0 {
  fill: rgb(220 38 38 / 0) !important;
}

.tw-fill-red-600\/10 {
  fill: rgb(220 38 38 / 0.1) !important;
}

.tw-fill-red-600\/100 {
  fill: rgb(220 38 38 / 1) !important;
}

.tw-fill-red-600\/15 {
  fill: rgb(220 38 38 / 0.15) !important;
}

.tw-fill-red-600\/20 {
  fill: rgb(220 38 38 / 0.2) !important;
}

.tw-fill-red-600\/25 {
  fill: rgb(220 38 38 / 0.25) !important;
}

.tw-fill-red-600\/30 {
  fill: rgb(220 38 38 / 0.3) !important;
}

.tw-fill-red-600\/35 {
  fill: rgb(220 38 38 / 0.35) !important;
}

.tw-fill-red-600\/40 {
  fill: rgb(220 38 38 / 0.4) !important;
}

.tw-fill-red-600\/45 {
  fill: rgb(220 38 38 / 0.45) !important;
}

.tw-fill-red-600\/5 {
  fill: rgb(220 38 38 / 0.05) !important;
}

.tw-fill-red-600\/50 {
  fill: rgb(220 38 38 / 0.5) !important;
}

.tw-fill-red-600\/55 {
  fill: rgb(220 38 38 / 0.55) !important;
}

.tw-fill-red-600\/60 {
  fill: rgb(220 38 38 / 0.6) !important;
}

.tw-fill-red-600\/65 {
  fill: rgb(220 38 38 / 0.65) !important;
}

.tw-fill-red-600\/70 {
  fill: rgb(220 38 38 / 0.7) !important;
}

.tw-fill-red-600\/75 {
  fill: rgb(220 38 38 / 0.75) !important;
}

.tw-fill-red-600\/80 {
  fill: rgb(220 38 38 / 0.8) !important;
}

.tw-fill-red-600\/85 {
  fill: rgb(220 38 38 / 0.85) !important;
}

.tw-fill-red-600\/90 {
  fill: rgb(220 38 38 / 0.9) !important;
}

.tw-fill-red-600\/95 {
  fill: rgb(220 38 38 / 0.95) !important;
}

.tw-fill-red-700 {
  fill: #b91c1c !important;
}

.tw-fill-red-700\/0 {
  fill: rgb(185 28 28 / 0) !important;
}

.tw-fill-red-700\/10 {
  fill: rgb(185 28 28 / 0.1) !important;
}

.tw-fill-red-700\/100 {
  fill: rgb(185 28 28 / 1) !important;
}

.tw-fill-red-700\/15 {
  fill: rgb(185 28 28 / 0.15) !important;
}

.tw-fill-red-700\/20 {
  fill: rgb(185 28 28 / 0.2) !important;
}

.tw-fill-red-700\/25 {
  fill: rgb(185 28 28 / 0.25) !important;
}

.tw-fill-red-700\/30 {
  fill: rgb(185 28 28 / 0.3) !important;
}

.tw-fill-red-700\/35 {
  fill: rgb(185 28 28 / 0.35) !important;
}

.tw-fill-red-700\/40 {
  fill: rgb(185 28 28 / 0.4) !important;
}

.tw-fill-red-700\/45 {
  fill: rgb(185 28 28 / 0.45) !important;
}

.tw-fill-red-700\/5 {
  fill: rgb(185 28 28 / 0.05) !important;
}

.tw-fill-red-700\/50 {
  fill: rgb(185 28 28 / 0.5) !important;
}

.tw-fill-red-700\/55 {
  fill: rgb(185 28 28 / 0.55) !important;
}

.tw-fill-red-700\/60 {
  fill: rgb(185 28 28 / 0.6) !important;
}

.tw-fill-red-700\/65 {
  fill: rgb(185 28 28 / 0.65) !important;
}

.tw-fill-red-700\/70 {
  fill: rgb(185 28 28 / 0.7) !important;
}

.tw-fill-red-700\/75 {
  fill: rgb(185 28 28 / 0.75) !important;
}

.tw-fill-red-700\/80 {
  fill: rgb(185 28 28 / 0.8) !important;
}

.tw-fill-red-700\/85 {
  fill: rgb(185 28 28 / 0.85) !important;
}

.tw-fill-red-700\/90 {
  fill: rgb(185 28 28 / 0.9) !important;
}

.tw-fill-red-700\/95 {
  fill: rgb(185 28 28 / 0.95) !important;
}

.tw-fill-red-800 {
  fill: #991b1b !important;
}

.tw-fill-red-800\/0 {
  fill: rgb(153 27 27 / 0) !important;
}

.tw-fill-red-800\/10 {
  fill: rgb(153 27 27 / 0.1) !important;
}

.tw-fill-red-800\/100 {
  fill: rgb(153 27 27 / 1) !important;
}

.tw-fill-red-800\/15 {
  fill: rgb(153 27 27 / 0.15) !important;
}

.tw-fill-red-800\/20 {
  fill: rgb(153 27 27 / 0.2) !important;
}

.tw-fill-red-800\/25 {
  fill: rgb(153 27 27 / 0.25) !important;
}

.tw-fill-red-800\/30 {
  fill: rgb(153 27 27 / 0.3) !important;
}

.tw-fill-red-800\/35 {
  fill: rgb(153 27 27 / 0.35) !important;
}

.tw-fill-red-800\/40 {
  fill: rgb(153 27 27 / 0.4) !important;
}

.tw-fill-red-800\/45 {
  fill: rgb(153 27 27 / 0.45) !important;
}

.tw-fill-red-800\/5 {
  fill: rgb(153 27 27 / 0.05) !important;
}

.tw-fill-red-800\/50 {
  fill: rgb(153 27 27 / 0.5) !important;
}

.tw-fill-red-800\/55 {
  fill: rgb(153 27 27 / 0.55) !important;
}

.tw-fill-red-800\/60 {
  fill: rgb(153 27 27 / 0.6) !important;
}

.tw-fill-red-800\/65 {
  fill: rgb(153 27 27 / 0.65) !important;
}

.tw-fill-red-800\/70 {
  fill: rgb(153 27 27 / 0.7) !important;
}

.tw-fill-red-800\/75 {
  fill: rgb(153 27 27 / 0.75) !important;
}

.tw-fill-red-800\/80 {
  fill: rgb(153 27 27 / 0.8) !important;
}

.tw-fill-red-800\/85 {
  fill: rgb(153 27 27 / 0.85) !important;
}

.tw-fill-red-800\/90 {
  fill: rgb(153 27 27 / 0.9) !important;
}

.tw-fill-red-800\/95 {
  fill: rgb(153 27 27 / 0.95) !important;
}

.tw-fill-red-900 {
  fill: #7f1d1d !important;
}

.tw-fill-red-900\/0 {
  fill: rgb(127 29 29 / 0) !important;
}

.tw-fill-red-900\/10 {
  fill: rgb(127 29 29 / 0.1) !important;
}

.tw-fill-red-900\/100 {
  fill: rgb(127 29 29 / 1) !important;
}

.tw-fill-red-900\/15 {
  fill: rgb(127 29 29 / 0.15) !important;
}

.tw-fill-red-900\/20 {
  fill: rgb(127 29 29 / 0.2) !important;
}

.tw-fill-red-900\/25 {
  fill: rgb(127 29 29 / 0.25) !important;
}

.tw-fill-red-900\/30 {
  fill: rgb(127 29 29 / 0.3) !important;
}

.tw-fill-red-900\/35 {
  fill: rgb(127 29 29 / 0.35) !important;
}

.tw-fill-red-900\/40 {
  fill: rgb(127 29 29 / 0.4) !important;
}

.tw-fill-red-900\/45 {
  fill: rgb(127 29 29 / 0.45) !important;
}

.tw-fill-red-900\/5 {
  fill: rgb(127 29 29 / 0.05) !important;
}

.tw-fill-red-900\/50 {
  fill: rgb(127 29 29 / 0.5) !important;
}

.tw-fill-red-900\/55 {
  fill: rgb(127 29 29 / 0.55) !important;
}

.tw-fill-red-900\/60 {
  fill: rgb(127 29 29 / 0.6) !important;
}

.tw-fill-red-900\/65 {
  fill: rgb(127 29 29 / 0.65) !important;
}

.tw-fill-red-900\/70 {
  fill: rgb(127 29 29 / 0.7) !important;
}

.tw-fill-red-900\/75 {
  fill: rgb(127 29 29 / 0.75) !important;
}

.tw-fill-red-900\/80 {
  fill: rgb(127 29 29 / 0.8) !important;
}

.tw-fill-red-900\/85 {
  fill: rgb(127 29 29 / 0.85) !important;
}

.tw-fill-red-900\/90 {
  fill: rgb(127 29 29 / 0.9) !important;
}

.tw-fill-red-900\/95 {
  fill: rgb(127 29 29 / 0.95) !important;
}

.tw-fill-red-950 {
  fill: #450a0a !important;
}

.tw-fill-red-950\/0 {
  fill: rgb(69 10 10 / 0) !important;
}

.tw-fill-red-950\/10 {
  fill: rgb(69 10 10 / 0.1) !important;
}

.tw-fill-red-950\/100 {
  fill: rgb(69 10 10 / 1) !important;
}

.tw-fill-red-950\/15 {
  fill: rgb(69 10 10 / 0.15) !important;
}

.tw-fill-red-950\/20 {
  fill: rgb(69 10 10 / 0.2) !important;
}

.tw-fill-red-950\/25 {
  fill: rgb(69 10 10 / 0.25) !important;
}

.tw-fill-red-950\/30 {
  fill: rgb(69 10 10 / 0.3) !important;
}

.tw-fill-red-950\/35 {
  fill: rgb(69 10 10 / 0.35) !important;
}

.tw-fill-red-950\/40 {
  fill: rgb(69 10 10 / 0.4) !important;
}

.tw-fill-red-950\/45 {
  fill: rgb(69 10 10 / 0.45) !important;
}

.tw-fill-red-950\/5 {
  fill: rgb(69 10 10 / 0.05) !important;
}

.tw-fill-red-950\/50 {
  fill: rgb(69 10 10 / 0.5) !important;
}

.tw-fill-red-950\/55 {
  fill: rgb(69 10 10 / 0.55) !important;
}

.tw-fill-red-950\/60 {
  fill: rgb(69 10 10 / 0.6) !important;
}

.tw-fill-red-950\/65 {
  fill: rgb(69 10 10 / 0.65) !important;
}

.tw-fill-red-950\/70 {
  fill: rgb(69 10 10 / 0.7) !important;
}

.tw-fill-red-950\/75 {
  fill: rgb(69 10 10 / 0.75) !important;
}

.tw-fill-red-950\/80 {
  fill: rgb(69 10 10 / 0.8) !important;
}

.tw-fill-red-950\/85 {
  fill: rgb(69 10 10 / 0.85) !important;
}

.tw-fill-red-950\/90 {
  fill: rgb(69 10 10 / 0.9) !important;
}

.tw-fill-red-950\/95 {
  fill: rgb(69 10 10 / 0.95) !important;
}

.tw-fill-rose-100 {
  fill: #ffe4e6 !important;
}

.tw-fill-rose-100\/0 {
  fill: rgb(255 228 230 / 0) !important;
}

.tw-fill-rose-100\/10 {
  fill: rgb(255 228 230 / 0.1) !important;
}

.tw-fill-rose-100\/100 {
  fill: rgb(255 228 230 / 1) !important;
}

.tw-fill-rose-100\/15 {
  fill: rgb(255 228 230 / 0.15) !important;
}

.tw-fill-rose-100\/20 {
  fill: rgb(255 228 230 / 0.2) !important;
}

.tw-fill-rose-100\/25 {
  fill: rgb(255 228 230 / 0.25) !important;
}

.tw-fill-rose-100\/30 {
  fill: rgb(255 228 230 / 0.3) !important;
}

.tw-fill-rose-100\/35 {
  fill: rgb(255 228 230 / 0.35) !important;
}

.tw-fill-rose-100\/40 {
  fill: rgb(255 228 230 / 0.4) !important;
}

.tw-fill-rose-100\/45 {
  fill: rgb(255 228 230 / 0.45) !important;
}

.tw-fill-rose-100\/5 {
  fill: rgb(255 228 230 / 0.05) !important;
}

.tw-fill-rose-100\/50 {
  fill: rgb(255 228 230 / 0.5) !important;
}

.tw-fill-rose-100\/55 {
  fill: rgb(255 228 230 / 0.55) !important;
}

.tw-fill-rose-100\/60 {
  fill: rgb(255 228 230 / 0.6) !important;
}

.tw-fill-rose-100\/65 {
  fill: rgb(255 228 230 / 0.65) !important;
}

.tw-fill-rose-100\/70 {
  fill: rgb(255 228 230 / 0.7) !important;
}

.tw-fill-rose-100\/75 {
  fill: rgb(255 228 230 / 0.75) !important;
}

.tw-fill-rose-100\/80 {
  fill: rgb(255 228 230 / 0.8) !important;
}

.tw-fill-rose-100\/85 {
  fill: rgb(255 228 230 / 0.85) !important;
}

.tw-fill-rose-100\/90 {
  fill: rgb(255 228 230 / 0.9) !important;
}

.tw-fill-rose-100\/95 {
  fill: rgb(255 228 230 / 0.95) !important;
}

.tw-fill-rose-200 {
  fill: #fecdd3 !important;
}

.tw-fill-rose-200\/0 {
  fill: rgb(254 205 211 / 0) !important;
}

.tw-fill-rose-200\/10 {
  fill: rgb(254 205 211 / 0.1) !important;
}

.tw-fill-rose-200\/100 {
  fill: rgb(254 205 211 / 1) !important;
}

.tw-fill-rose-200\/15 {
  fill: rgb(254 205 211 / 0.15) !important;
}

.tw-fill-rose-200\/20 {
  fill: rgb(254 205 211 / 0.2) !important;
}

.tw-fill-rose-200\/25 {
  fill: rgb(254 205 211 / 0.25) !important;
}

.tw-fill-rose-200\/30 {
  fill: rgb(254 205 211 / 0.3) !important;
}

.tw-fill-rose-200\/35 {
  fill: rgb(254 205 211 / 0.35) !important;
}

.tw-fill-rose-200\/40 {
  fill: rgb(254 205 211 / 0.4) !important;
}

.tw-fill-rose-200\/45 {
  fill: rgb(254 205 211 / 0.45) !important;
}

.tw-fill-rose-200\/5 {
  fill: rgb(254 205 211 / 0.05) !important;
}

.tw-fill-rose-200\/50 {
  fill: rgb(254 205 211 / 0.5) !important;
}

.tw-fill-rose-200\/55 {
  fill: rgb(254 205 211 / 0.55) !important;
}

.tw-fill-rose-200\/60 {
  fill: rgb(254 205 211 / 0.6) !important;
}

.tw-fill-rose-200\/65 {
  fill: rgb(254 205 211 / 0.65) !important;
}

.tw-fill-rose-200\/70 {
  fill: rgb(254 205 211 / 0.7) !important;
}

.tw-fill-rose-200\/75 {
  fill: rgb(254 205 211 / 0.75) !important;
}

.tw-fill-rose-200\/80 {
  fill: rgb(254 205 211 / 0.8) !important;
}

.tw-fill-rose-200\/85 {
  fill: rgb(254 205 211 / 0.85) !important;
}

.tw-fill-rose-200\/90 {
  fill: rgb(254 205 211 / 0.9) !important;
}

.tw-fill-rose-200\/95 {
  fill: rgb(254 205 211 / 0.95) !important;
}

.tw-fill-rose-300 {
  fill: #fda4af !important;
}

.tw-fill-rose-300\/0 {
  fill: rgb(253 164 175 / 0) !important;
}

.tw-fill-rose-300\/10 {
  fill: rgb(253 164 175 / 0.1) !important;
}

.tw-fill-rose-300\/100 {
  fill: rgb(253 164 175 / 1) !important;
}

.tw-fill-rose-300\/15 {
  fill: rgb(253 164 175 / 0.15) !important;
}

.tw-fill-rose-300\/20 {
  fill: rgb(253 164 175 / 0.2) !important;
}

.tw-fill-rose-300\/25 {
  fill: rgb(253 164 175 / 0.25) !important;
}

.tw-fill-rose-300\/30 {
  fill: rgb(253 164 175 / 0.3) !important;
}

.tw-fill-rose-300\/35 {
  fill: rgb(253 164 175 / 0.35) !important;
}

.tw-fill-rose-300\/40 {
  fill: rgb(253 164 175 / 0.4) !important;
}

.tw-fill-rose-300\/45 {
  fill: rgb(253 164 175 / 0.45) !important;
}

.tw-fill-rose-300\/5 {
  fill: rgb(253 164 175 / 0.05) !important;
}

.tw-fill-rose-300\/50 {
  fill: rgb(253 164 175 / 0.5) !important;
}

.tw-fill-rose-300\/55 {
  fill: rgb(253 164 175 / 0.55) !important;
}

.tw-fill-rose-300\/60 {
  fill: rgb(253 164 175 / 0.6) !important;
}

.tw-fill-rose-300\/65 {
  fill: rgb(253 164 175 / 0.65) !important;
}

.tw-fill-rose-300\/70 {
  fill: rgb(253 164 175 / 0.7) !important;
}

.tw-fill-rose-300\/75 {
  fill: rgb(253 164 175 / 0.75) !important;
}

.tw-fill-rose-300\/80 {
  fill: rgb(253 164 175 / 0.8) !important;
}

.tw-fill-rose-300\/85 {
  fill: rgb(253 164 175 / 0.85) !important;
}

.tw-fill-rose-300\/90 {
  fill: rgb(253 164 175 / 0.9) !important;
}

.tw-fill-rose-300\/95 {
  fill: rgb(253 164 175 / 0.95) !important;
}

.tw-fill-rose-400 {
  fill: #fb7185 !important;
}

.tw-fill-rose-400\/0 {
  fill: rgb(251 113 133 / 0) !important;
}

.tw-fill-rose-400\/10 {
  fill: rgb(251 113 133 / 0.1) !important;
}

.tw-fill-rose-400\/100 {
  fill: rgb(251 113 133 / 1) !important;
}

.tw-fill-rose-400\/15 {
  fill: rgb(251 113 133 / 0.15) !important;
}

.tw-fill-rose-400\/20 {
  fill: rgb(251 113 133 / 0.2) !important;
}

.tw-fill-rose-400\/25 {
  fill: rgb(251 113 133 / 0.25) !important;
}

.tw-fill-rose-400\/30 {
  fill: rgb(251 113 133 / 0.3) !important;
}

.tw-fill-rose-400\/35 {
  fill: rgb(251 113 133 / 0.35) !important;
}

.tw-fill-rose-400\/40 {
  fill: rgb(251 113 133 / 0.4) !important;
}

.tw-fill-rose-400\/45 {
  fill: rgb(251 113 133 / 0.45) !important;
}

.tw-fill-rose-400\/5 {
  fill: rgb(251 113 133 / 0.05) !important;
}

.tw-fill-rose-400\/50 {
  fill: rgb(251 113 133 / 0.5) !important;
}

.tw-fill-rose-400\/55 {
  fill: rgb(251 113 133 / 0.55) !important;
}

.tw-fill-rose-400\/60 {
  fill: rgb(251 113 133 / 0.6) !important;
}

.tw-fill-rose-400\/65 {
  fill: rgb(251 113 133 / 0.65) !important;
}

.tw-fill-rose-400\/70 {
  fill: rgb(251 113 133 / 0.7) !important;
}

.tw-fill-rose-400\/75 {
  fill: rgb(251 113 133 / 0.75) !important;
}

.tw-fill-rose-400\/80 {
  fill: rgb(251 113 133 / 0.8) !important;
}

.tw-fill-rose-400\/85 {
  fill: rgb(251 113 133 / 0.85) !important;
}

.tw-fill-rose-400\/90 {
  fill: rgb(251 113 133 / 0.9) !important;
}

.tw-fill-rose-400\/95 {
  fill: rgb(251 113 133 / 0.95) !important;
}

.tw-fill-rose-50 {
  fill: #fff1f2 !important;
}

.tw-fill-rose-50\/0 {
  fill: rgb(255 241 242 / 0) !important;
}

.tw-fill-rose-50\/10 {
  fill: rgb(255 241 242 / 0.1) !important;
}

.tw-fill-rose-50\/100 {
  fill: rgb(255 241 242 / 1) !important;
}

.tw-fill-rose-50\/15 {
  fill: rgb(255 241 242 / 0.15) !important;
}

.tw-fill-rose-50\/20 {
  fill: rgb(255 241 242 / 0.2) !important;
}

.tw-fill-rose-50\/25 {
  fill: rgb(255 241 242 / 0.25) !important;
}

.tw-fill-rose-50\/30 {
  fill: rgb(255 241 242 / 0.3) !important;
}

.tw-fill-rose-50\/35 {
  fill: rgb(255 241 242 / 0.35) !important;
}

.tw-fill-rose-50\/40 {
  fill: rgb(255 241 242 / 0.4) !important;
}

.tw-fill-rose-50\/45 {
  fill: rgb(255 241 242 / 0.45) !important;
}

.tw-fill-rose-50\/5 {
  fill: rgb(255 241 242 / 0.05) !important;
}

.tw-fill-rose-50\/50 {
  fill: rgb(255 241 242 / 0.5) !important;
}

.tw-fill-rose-50\/55 {
  fill: rgb(255 241 242 / 0.55) !important;
}

.tw-fill-rose-50\/60 {
  fill: rgb(255 241 242 / 0.6) !important;
}

.tw-fill-rose-50\/65 {
  fill: rgb(255 241 242 / 0.65) !important;
}

.tw-fill-rose-50\/70 {
  fill: rgb(255 241 242 / 0.7) !important;
}

.tw-fill-rose-50\/75 {
  fill: rgb(255 241 242 / 0.75) !important;
}

.tw-fill-rose-50\/80 {
  fill: rgb(255 241 242 / 0.8) !important;
}

.tw-fill-rose-50\/85 {
  fill: rgb(255 241 242 / 0.85) !important;
}

.tw-fill-rose-50\/90 {
  fill: rgb(255 241 242 / 0.9) !important;
}

.tw-fill-rose-50\/95 {
  fill: rgb(255 241 242 / 0.95) !important;
}

.tw-fill-rose-500 {
  fill: #f43f5e !important;
}

.tw-fill-rose-500\/0 {
  fill: rgb(244 63 94 / 0) !important;
}

.tw-fill-rose-500\/10 {
  fill: rgb(244 63 94 / 0.1) !important;
}

.tw-fill-rose-500\/100 {
  fill: rgb(244 63 94 / 1) !important;
}

.tw-fill-rose-500\/15 {
  fill: rgb(244 63 94 / 0.15) !important;
}

.tw-fill-rose-500\/20 {
  fill: rgb(244 63 94 / 0.2) !important;
}

.tw-fill-rose-500\/25 {
  fill: rgb(244 63 94 / 0.25) !important;
}

.tw-fill-rose-500\/30 {
  fill: rgb(244 63 94 / 0.3) !important;
}

.tw-fill-rose-500\/35 {
  fill: rgb(244 63 94 / 0.35) !important;
}

.tw-fill-rose-500\/40 {
  fill: rgb(244 63 94 / 0.4) !important;
}

.tw-fill-rose-500\/45 {
  fill: rgb(244 63 94 / 0.45) !important;
}

.tw-fill-rose-500\/5 {
  fill: rgb(244 63 94 / 0.05) !important;
}

.tw-fill-rose-500\/50 {
  fill: rgb(244 63 94 / 0.5) !important;
}

.tw-fill-rose-500\/55 {
  fill: rgb(244 63 94 / 0.55) !important;
}

.tw-fill-rose-500\/60 {
  fill: rgb(244 63 94 / 0.6) !important;
}

.tw-fill-rose-500\/65 {
  fill: rgb(244 63 94 / 0.65) !important;
}

.tw-fill-rose-500\/70 {
  fill: rgb(244 63 94 / 0.7) !important;
}

.tw-fill-rose-500\/75 {
  fill: rgb(244 63 94 / 0.75) !important;
}

.tw-fill-rose-500\/80 {
  fill: rgb(244 63 94 / 0.8) !important;
}

.tw-fill-rose-500\/85 {
  fill: rgb(244 63 94 / 0.85) !important;
}

.tw-fill-rose-500\/90 {
  fill: rgb(244 63 94 / 0.9) !important;
}

.tw-fill-rose-500\/95 {
  fill: rgb(244 63 94 / 0.95) !important;
}

.tw-fill-rose-600 {
  fill: #e11d48 !important;
}

.tw-fill-rose-600\/0 {
  fill: rgb(225 29 72 / 0) !important;
}

.tw-fill-rose-600\/10 {
  fill: rgb(225 29 72 / 0.1) !important;
}

.tw-fill-rose-600\/100 {
  fill: rgb(225 29 72 / 1) !important;
}

.tw-fill-rose-600\/15 {
  fill: rgb(225 29 72 / 0.15) !important;
}

.tw-fill-rose-600\/20 {
  fill: rgb(225 29 72 / 0.2) !important;
}

.tw-fill-rose-600\/25 {
  fill: rgb(225 29 72 / 0.25) !important;
}

.tw-fill-rose-600\/30 {
  fill: rgb(225 29 72 / 0.3) !important;
}

.tw-fill-rose-600\/35 {
  fill: rgb(225 29 72 / 0.35) !important;
}

.tw-fill-rose-600\/40 {
  fill: rgb(225 29 72 / 0.4) !important;
}

.tw-fill-rose-600\/45 {
  fill: rgb(225 29 72 / 0.45) !important;
}

.tw-fill-rose-600\/5 {
  fill: rgb(225 29 72 / 0.05) !important;
}

.tw-fill-rose-600\/50 {
  fill: rgb(225 29 72 / 0.5) !important;
}

.tw-fill-rose-600\/55 {
  fill: rgb(225 29 72 / 0.55) !important;
}

.tw-fill-rose-600\/60 {
  fill: rgb(225 29 72 / 0.6) !important;
}

.tw-fill-rose-600\/65 {
  fill: rgb(225 29 72 / 0.65) !important;
}

.tw-fill-rose-600\/70 {
  fill: rgb(225 29 72 / 0.7) !important;
}

.tw-fill-rose-600\/75 {
  fill: rgb(225 29 72 / 0.75) !important;
}

.tw-fill-rose-600\/80 {
  fill: rgb(225 29 72 / 0.8) !important;
}

.tw-fill-rose-600\/85 {
  fill: rgb(225 29 72 / 0.85) !important;
}

.tw-fill-rose-600\/90 {
  fill: rgb(225 29 72 / 0.9) !important;
}

.tw-fill-rose-600\/95 {
  fill: rgb(225 29 72 / 0.95) !important;
}

.tw-fill-rose-700 {
  fill: #be123c !important;
}

.tw-fill-rose-700\/0 {
  fill: rgb(190 18 60 / 0) !important;
}

.tw-fill-rose-700\/10 {
  fill: rgb(190 18 60 / 0.1) !important;
}

.tw-fill-rose-700\/100 {
  fill: rgb(190 18 60 / 1) !important;
}

.tw-fill-rose-700\/15 {
  fill: rgb(190 18 60 / 0.15) !important;
}

.tw-fill-rose-700\/20 {
  fill: rgb(190 18 60 / 0.2) !important;
}

.tw-fill-rose-700\/25 {
  fill: rgb(190 18 60 / 0.25) !important;
}

.tw-fill-rose-700\/30 {
  fill: rgb(190 18 60 / 0.3) !important;
}

.tw-fill-rose-700\/35 {
  fill: rgb(190 18 60 / 0.35) !important;
}

.tw-fill-rose-700\/40 {
  fill: rgb(190 18 60 / 0.4) !important;
}

.tw-fill-rose-700\/45 {
  fill: rgb(190 18 60 / 0.45) !important;
}

.tw-fill-rose-700\/5 {
  fill: rgb(190 18 60 / 0.05) !important;
}

.tw-fill-rose-700\/50 {
  fill: rgb(190 18 60 / 0.5) !important;
}

.tw-fill-rose-700\/55 {
  fill: rgb(190 18 60 / 0.55) !important;
}

.tw-fill-rose-700\/60 {
  fill: rgb(190 18 60 / 0.6) !important;
}

.tw-fill-rose-700\/65 {
  fill: rgb(190 18 60 / 0.65) !important;
}

.tw-fill-rose-700\/70 {
  fill: rgb(190 18 60 / 0.7) !important;
}

.tw-fill-rose-700\/75 {
  fill: rgb(190 18 60 / 0.75) !important;
}

.tw-fill-rose-700\/80 {
  fill: rgb(190 18 60 / 0.8) !important;
}

.tw-fill-rose-700\/85 {
  fill: rgb(190 18 60 / 0.85) !important;
}

.tw-fill-rose-700\/90 {
  fill: rgb(190 18 60 / 0.9) !important;
}

.tw-fill-rose-700\/95 {
  fill: rgb(190 18 60 / 0.95) !important;
}

.tw-fill-rose-800 {
  fill: #9f1239 !important;
}

.tw-fill-rose-800\/0 {
  fill: rgb(159 18 57 / 0) !important;
}

.tw-fill-rose-800\/10 {
  fill: rgb(159 18 57 / 0.1) !important;
}

.tw-fill-rose-800\/100 {
  fill: rgb(159 18 57 / 1) !important;
}

.tw-fill-rose-800\/15 {
  fill: rgb(159 18 57 / 0.15) !important;
}

.tw-fill-rose-800\/20 {
  fill: rgb(159 18 57 / 0.2) !important;
}

.tw-fill-rose-800\/25 {
  fill: rgb(159 18 57 / 0.25) !important;
}

.tw-fill-rose-800\/30 {
  fill: rgb(159 18 57 / 0.3) !important;
}

.tw-fill-rose-800\/35 {
  fill: rgb(159 18 57 / 0.35) !important;
}

.tw-fill-rose-800\/40 {
  fill: rgb(159 18 57 / 0.4) !important;
}

.tw-fill-rose-800\/45 {
  fill: rgb(159 18 57 / 0.45) !important;
}

.tw-fill-rose-800\/5 {
  fill: rgb(159 18 57 / 0.05) !important;
}

.tw-fill-rose-800\/50 {
  fill: rgb(159 18 57 / 0.5) !important;
}

.tw-fill-rose-800\/55 {
  fill: rgb(159 18 57 / 0.55) !important;
}

.tw-fill-rose-800\/60 {
  fill: rgb(159 18 57 / 0.6) !important;
}

.tw-fill-rose-800\/65 {
  fill: rgb(159 18 57 / 0.65) !important;
}

.tw-fill-rose-800\/70 {
  fill: rgb(159 18 57 / 0.7) !important;
}

.tw-fill-rose-800\/75 {
  fill: rgb(159 18 57 / 0.75) !important;
}

.tw-fill-rose-800\/80 {
  fill: rgb(159 18 57 / 0.8) !important;
}

.tw-fill-rose-800\/85 {
  fill: rgb(159 18 57 / 0.85) !important;
}

.tw-fill-rose-800\/90 {
  fill: rgb(159 18 57 / 0.9) !important;
}

.tw-fill-rose-800\/95 {
  fill: rgb(159 18 57 / 0.95) !important;
}

.tw-fill-rose-900 {
  fill: #881337 !important;
}

.tw-fill-rose-900\/0 {
  fill: rgb(136 19 55 / 0) !important;
}

.tw-fill-rose-900\/10 {
  fill: rgb(136 19 55 / 0.1) !important;
}

.tw-fill-rose-900\/100 {
  fill: rgb(136 19 55 / 1) !important;
}

.tw-fill-rose-900\/15 {
  fill: rgb(136 19 55 / 0.15) !important;
}

.tw-fill-rose-900\/20 {
  fill: rgb(136 19 55 / 0.2) !important;
}

.tw-fill-rose-900\/25 {
  fill: rgb(136 19 55 / 0.25) !important;
}

.tw-fill-rose-900\/30 {
  fill: rgb(136 19 55 / 0.3) !important;
}

.tw-fill-rose-900\/35 {
  fill: rgb(136 19 55 / 0.35) !important;
}

.tw-fill-rose-900\/40 {
  fill: rgb(136 19 55 / 0.4) !important;
}

.tw-fill-rose-900\/45 {
  fill: rgb(136 19 55 / 0.45) !important;
}

.tw-fill-rose-900\/5 {
  fill: rgb(136 19 55 / 0.05) !important;
}

.tw-fill-rose-900\/50 {
  fill: rgb(136 19 55 / 0.5) !important;
}

.tw-fill-rose-900\/55 {
  fill: rgb(136 19 55 / 0.55) !important;
}

.tw-fill-rose-900\/60 {
  fill: rgb(136 19 55 / 0.6) !important;
}

.tw-fill-rose-900\/65 {
  fill: rgb(136 19 55 / 0.65) !important;
}

.tw-fill-rose-900\/70 {
  fill: rgb(136 19 55 / 0.7) !important;
}

.tw-fill-rose-900\/75 {
  fill: rgb(136 19 55 / 0.75) !important;
}

.tw-fill-rose-900\/80 {
  fill: rgb(136 19 55 / 0.8) !important;
}

.tw-fill-rose-900\/85 {
  fill: rgb(136 19 55 / 0.85) !important;
}

.tw-fill-rose-900\/90 {
  fill: rgb(136 19 55 / 0.9) !important;
}

.tw-fill-rose-900\/95 {
  fill: rgb(136 19 55 / 0.95) !important;
}

.tw-fill-rose-950 {
  fill: #4c0519 !important;
}

.tw-fill-rose-950\/0 {
  fill: rgb(76 5 25 / 0) !important;
}

.tw-fill-rose-950\/10 {
  fill: rgb(76 5 25 / 0.1) !important;
}

.tw-fill-rose-950\/100 {
  fill: rgb(76 5 25 / 1) !important;
}

.tw-fill-rose-950\/15 {
  fill: rgb(76 5 25 / 0.15) !important;
}

.tw-fill-rose-950\/20 {
  fill: rgb(76 5 25 / 0.2) !important;
}

.tw-fill-rose-950\/25 {
  fill: rgb(76 5 25 / 0.25) !important;
}

.tw-fill-rose-950\/30 {
  fill: rgb(76 5 25 / 0.3) !important;
}

.tw-fill-rose-950\/35 {
  fill: rgb(76 5 25 / 0.35) !important;
}

.tw-fill-rose-950\/40 {
  fill: rgb(76 5 25 / 0.4) !important;
}

.tw-fill-rose-950\/45 {
  fill: rgb(76 5 25 / 0.45) !important;
}

.tw-fill-rose-950\/5 {
  fill: rgb(76 5 25 / 0.05) !important;
}

.tw-fill-rose-950\/50 {
  fill: rgb(76 5 25 / 0.5) !important;
}

.tw-fill-rose-950\/55 {
  fill: rgb(76 5 25 / 0.55) !important;
}

.tw-fill-rose-950\/60 {
  fill: rgb(76 5 25 / 0.6) !important;
}

.tw-fill-rose-950\/65 {
  fill: rgb(76 5 25 / 0.65) !important;
}

.tw-fill-rose-950\/70 {
  fill: rgb(76 5 25 / 0.7) !important;
}

.tw-fill-rose-950\/75 {
  fill: rgb(76 5 25 / 0.75) !important;
}

.tw-fill-rose-950\/80 {
  fill: rgb(76 5 25 / 0.8) !important;
}

.tw-fill-rose-950\/85 {
  fill: rgb(76 5 25 / 0.85) !important;
}

.tw-fill-rose-950\/90 {
  fill: rgb(76 5 25 / 0.9) !important;
}

.tw-fill-rose-950\/95 {
  fill: rgb(76 5 25 / 0.95) !important;
}

.tw-fill-sky-100 {
  fill: #e0f2fe !important;
}

.tw-fill-sky-100\/0 {
  fill: rgb(224 242 254 / 0) !important;
}

.tw-fill-sky-100\/10 {
  fill: rgb(224 242 254 / 0.1) !important;
}

.tw-fill-sky-100\/100 {
  fill: rgb(224 242 254 / 1) !important;
}

.tw-fill-sky-100\/15 {
  fill: rgb(224 242 254 / 0.15) !important;
}

.tw-fill-sky-100\/20 {
  fill: rgb(224 242 254 / 0.2) !important;
}

.tw-fill-sky-100\/25 {
  fill: rgb(224 242 254 / 0.25) !important;
}

.tw-fill-sky-100\/30 {
  fill: rgb(224 242 254 / 0.3) !important;
}

.tw-fill-sky-100\/35 {
  fill: rgb(224 242 254 / 0.35) !important;
}

.tw-fill-sky-100\/40 {
  fill: rgb(224 242 254 / 0.4) !important;
}

.tw-fill-sky-100\/45 {
  fill: rgb(224 242 254 / 0.45) !important;
}

.tw-fill-sky-100\/5 {
  fill: rgb(224 242 254 / 0.05) !important;
}

.tw-fill-sky-100\/50 {
  fill: rgb(224 242 254 / 0.5) !important;
}

.tw-fill-sky-100\/55 {
  fill: rgb(224 242 254 / 0.55) !important;
}

.tw-fill-sky-100\/60 {
  fill: rgb(224 242 254 / 0.6) !important;
}

.tw-fill-sky-100\/65 {
  fill: rgb(224 242 254 / 0.65) !important;
}

.tw-fill-sky-100\/70 {
  fill: rgb(224 242 254 / 0.7) !important;
}

.tw-fill-sky-100\/75 {
  fill: rgb(224 242 254 / 0.75) !important;
}

.tw-fill-sky-100\/80 {
  fill: rgb(224 242 254 / 0.8) !important;
}

.tw-fill-sky-100\/85 {
  fill: rgb(224 242 254 / 0.85) !important;
}

.tw-fill-sky-100\/90 {
  fill: rgb(224 242 254 / 0.9) !important;
}

.tw-fill-sky-100\/95 {
  fill: rgb(224 242 254 / 0.95) !important;
}

.tw-fill-sky-200 {
  fill: #bae6fd !important;
}

.tw-fill-sky-200\/0 {
  fill: rgb(186 230 253 / 0) !important;
}

.tw-fill-sky-200\/10 {
  fill: rgb(186 230 253 / 0.1) !important;
}

.tw-fill-sky-200\/100 {
  fill: rgb(186 230 253 / 1) !important;
}

.tw-fill-sky-200\/15 {
  fill: rgb(186 230 253 / 0.15) !important;
}

.tw-fill-sky-200\/20 {
  fill: rgb(186 230 253 / 0.2) !important;
}

.tw-fill-sky-200\/25 {
  fill: rgb(186 230 253 / 0.25) !important;
}

.tw-fill-sky-200\/30 {
  fill: rgb(186 230 253 / 0.3) !important;
}

.tw-fill-sky-200\/35 {
  fill: rgb(186 230 253 / 0.35) !important;
}

.tw-fill-sky-200\/40 {
  fill: rgb(186 230 253 / 0.4) !important;
}

.tw-fill-sky-200\/45 {
  fill: rgb(186 230 253 / 0.45) !important;
}

.tw-fill-sky-200\/5 {
  fill: rgb(186 230 253 / 0.05) !important;
}

.tw-fill-sky-200\/50 {
  fill: rgb(186 230 253 / 0.5) !important;
}

.tw-fill-sky-200\/55 {
  fill: rgb(186 230 253 / 0.55) !important;
}

.tw-fill-sky-200\/60 {
  fill: rgb(186 230 253 / 0.6) !important;
}

.tw-fill-sky-200\/65 {
  fill: rgb(186 230 253 / 0.65) !important;
}

.tw-fill-sky-200\/70 {
  fill: rgb(186 230 253 / 0.7) !important;
}

.tw-fill-sky-200\/75 {
  fill: rgb(186 230 253 / 0.75) !important;
}

.tw-fill-sky-200\/80 {
  fill: rgb(186 230 253 / 0.8) !important;
}

.tw-fill-sky-200\/85 {
  fill: rgb(186 230 253 / 0.85) !important;
}

.tw-fill-sky-200\/90 {
  fill: rgb(186 230 253 / 0.9) !important;
}

.tw-fill-sky-200\/95 {
  fill: rgb(186 230 253 / 0.95) !important;
}

.tw-fill-sky-300 {
  fill: #7dd3fc !important;
}

.tw-fill-sky-300\/0 {
  fill: rgb(125 211 252 / 0) !important;
}

.tw-fill-sky-300\/10 {
  fill: rgb(125 211 252 / 0.1) !important;
}

.tw-fill-sky-300\/100 {
  fill: rgb(125 211 252 / 1) !important;
}

.tw-fill-sky-300\/15 {
  fill: rgb(125 211 252 / 0.15) !important;
}

.tw-fill-sky-300\/20 {
  fill: rgb(125 211 252 / 0.2) !important;
}

.tw-fill-sky-300\/25 {
  fill: rgb(125 211 252 / 0.25) !important;
}

.tw-fill-sky-300\/30 {
  fill: rgb(125 211 252 / 0.3) !important;
}

.tw-fill-sky-300\/35 {
  fill: rgb(125 211 252 / 0.35) !important;
}

.tw-fill-sky-300\/40 {
  fill: rgb(125 211 252 / 0.4) !important;
}

.tw-fill-sky-300\/45 {
  fill: rgb(125 211 252 / 0.45) !important;
}

.tw-fill-sky-300\/5 {
  fill: rgb(125 211 252 / 0.05) !important;
}

.tw-fill-sky-300\/50 {
  fill: rgb(125 211 252 / 0.5) !important;
}

.tw-fill-sky-300\/55 {
  fill: rgb(125 211 252 / 0.55) !important;
}

.tw-fill-sky-300\/60 {
  fill: rgb(125 211 252 / 0.6) !important;
}

.tw-fill-sky-300\/65 {
  fill: rgb(125 211 252 / 0.65) !important;
}

.tw-fill-sky-300\/70 {
  fill: rgb(125 211 252 / 0.7) !important;
}

.tw-fill-sky-300\/75 {
  fill: rgb(125 211 252 / 0.75) !important;
}

.tw-fill-sky-300\/80 {
  fill: rgb(125 211 252 / 0.8) !important;
}

.tw-fill-sky-300\/85 {
  fill: rgb(125 211 252 / 0.85) !important;
}

.tw-fill-sky-300\/90 {
  fill: rgb(125 211 252 / 0.9) !important;
}

.tw-fill-sky-300\/95 {
  fill: rgb(125 211 252 / 0.95) !important;
}

.tw-fill-sky-400 {
  fill: #38bdf8 !important;
}

.tw-fill-sky-400\/0 {
  fill: rgb(56 189 248 / 0) !important;
}

.tw-fill-sky-400\/10 {
  fill: rgb(56 189 248 / 0.1) !important;
}

.tw-fill-sky-400\/100 {
  fill: rgb(56 189 248 / 1) !important;
}

.tw-fill-sky-400\/15 {
  fill: rgb(56 189 248 / 0.15) !important;
}

.tw-fill-sky-400\/20 {
  fill: rgb(56 189 248 / 0.2) !important;
}

.tw-fill-sky-400\/25 {
  fill: rgb(56 189 248 / 0.25) !important;
}

.tw-fill-sky-400\/30 {
  fill: rgb(56 189 248 / 0.3) !important;
}

.tw-fill-sky-400\/35 {
  fill: rgb(56 189 248 / 0.35) !important;
}

.tw-fill-sky-400\/40 {
  fill: rgb(56 189 248 / 0.4) !important;
}

.tw-fill-sky-400\/45 {
  fill: rgb(56 189 248 / 0.45) !important;
}

.tw-fill-sky-400\/5 {
  fill: rgb(56 189 248 / 0.05) !important;
}

.tw-fill-sky-400\/50 {
  fill: rgb(56 189 248 / 0.5) !important;
}

.tw-fill-sky-400\/55 {
  fill: rgb(56 189 248 / 0.55) !important;
}

.tw-fill-sky-400\/60 {
  fill: rgb(56 189 248 / 0.6) !important;
}

.tw-fill-sky-400\/65 {
  fill: rgb(56 189 248 / 0.65) !important;
}

.tw-fill-sky-400\/70 {
  fill: rgb(56 189 248 / 0.7) !important;
}

.tw-fill-sky-400\/75 {
  fill: rgb(56 189 248 / 0.75) !important;
}

.tw-fill-sky-400\/80 {
  fill: rgb(56 189 248 / 0.8) !important;
}

.tw-fill-sky-400\/85 {
  fill: rgb(56 189 248 / 0.85) !important;
}

.tw-fill-sky-400\/90 {
  fill: rgb(56 189 248 / 0.9) !important;
}

.tw-fill-sky-400\/95 {
  fill: rgb(56 189 248 / 0.95) !important;
}

.tw-fill-sky-50 {
  fill: #f0f9ff !important;
}

.tw-fill-sky-50\/0 {
  fill: rgb(240 249 255 / 0) !important;
}

.tw-fill-sky-50\/10 {
  fill: rgb(240 249 255 / 0.1) !important;
}

.tw-fill-sky-50\/100 {
  fill: rgb(240 249 255 / 1) !important;
}

.tw-fill-sky-50\/15 {
  fill: rgb(240 249 255 / 0.15) !important;
}

.tw-fill-sky-50\/20 {
  fill: rgb(240 249 255 / 0.2) !important;
}

.tw-fill-sky-50\/25 {
  fill: rgb(240 249 255 / 0.25) !important;
}

.tw-fill-sky-50\/30 {
  fill: rgb(240 249 255 / 0.3) !important;
}

.tw-fill-sky-50\/35 {
  fill: rgb(240 249 255 / 0.35) !important;
}

.tw-fill-sky-50\/40 {
  fill: rgb(240 249 255 / 0.4) !important;
}

.tw-fill-sky-50\/45 {
  fill: rgb(240 249 255 / 0.45) !important;
}

.tw-fill-sky-50\/5 {
  fill: rgb(240 249 255 / 0.05) !important;
}

.tw-fill-sky-50\/50 {
  fill: rgb(240 249 255 / 0.5) !important;
}

.tw-fill-sky-50\/55 {
  fill: rgb(240 249 255 / 0.55) !important;
}

.tw-fill-sky-50\/60 {
  fill: rgb(240 249 255 / 0.6) !important;
}

.tw-fill-sky-50\/65 {
  fill: rgb(240 249 255 / 0.65) !important;
}

.tw-fill-sky-50\/70 {
  fill: rgb(240 249 255 / 0.7) !important;
}

.tw-fill-sky-50\/75 {
  fill: rgb(240 249 255 / 0.75) !important;
}

.tw-fill-sky-50\/80 {
  fill: rgb(240 249 255 / 0.8) !important;
}

.tw-fill-sky-50\/85 {
  fill: rgb(240 249 255 / 0.85) !important;
}

.tw-fill-sky-50\/90 {
  fill: rgb(240 249 255 / 0.9) !important;
}

.tw-fill-sky-50\/95 {
  fill: rgb(240 249 255 / 0.95) !important;
}

.tw-fill-sky-500 {
  fill: #0ea5e9 !important;
}

.tw-fill-sky-500\/0 {
  fill: rgb(14 165 233 / 0) !important;
}

.tw-fill-sky-500\/10 {
  fill: rgb(14 165 233 / 0.1) !important;
}

.tw-fill-sky-500\/100 {
  fill: rgb(14 165 233 / 1) !important;
}

.tw-fill-sky-500\/15 {
  fill: rgb(14 165 233 / 0.15) !important;
}

.tw-fill-sky-500\/20 {
  fill: rgb(14 165 233 / 0.2) !important;
}

.tw-fill-sky-500\/25 {
  fill: rgb(14 165 233 / 0.25) !important;
}

.tw-fill-sky-500\/30 {
  fill: rgb(14 165 233 / 0.3) !important;
}

.tw-fill-sky-500\/35 {
  fill: rgb(14 165 233 / 0.35) !important;
}

.tw-fill-sky-500\/40 {
  fill: rgb(14 165 233 / 0.4) !important;
}

.tw-fill-sky-500\/45 {
  fill: rgb(14 165 233 / 0.45) !important;
}

.tw-fill-sky-500\/5 {
  fill: rgb(14 165 233 / 0.05) !important;
}

.tw-fill-sky-500\/50 {
  fill: rgb(14 165 233 / 0.5) !important;
}

.tw-fill-sky-500\/55 {
  fill: rgb(14 165 233 / 0.55) !important;
}

.tw-fill-sky-500\/60 {
  fill: rgb(14 165 233 / 0.6) !important;
}

.tw-fill-sky-500\/65 {
  fill: rgb(14 165 233 / 0.65) !important;
}

.tw-fill-sky-500\/70 {
  fill: rgb(14 165 233 / 0.7) !important;
}

.tw-fill-sky-500\/75 {
  fill: rgb(14 165 233 / 0.75) !important;
}

.tw-fill-sky-500\/80 {
  fill: rgb(14 165 233 / 0.8) !important;
}

.tw-fill-sky-500\/85 {
  fill: rgb(14 165 233 / 0.85) !important;
}

.tw-fill-sky-500\/90 {
  fill: rgb(14 165 233 / 0.9) !important;
}

.tw-fill-sky-500\/95 {
  fill: rgb(14 165 233 / 0.95) !important;
}

.tw-fill-sky-600 {
  fill: #0284c7 !important;
}

.tw-fill-sky-600\/0 {
  fill: rgb(2 132 199 / 0) !important;
}

.tw-fill-sky-600\/10 {
  fill: rgb(2 132 199 / 0.1) !important;
}

.tw-fill-sky-600\/100 {
  fill: rgb(2 132 199 / 1) !important;
}

.tw-fill-sky-600\/15 {
  fill: rgb(2 132 199 / 0.15) !important;
}

.tw-fill-sky-600\/20 {
  fill: rgb(2 132 199 / 0.2) !important;
}

.tw-fill-sky-600\/25 {
  fill: rgb(2 132 199 / 0.25) !important;
}

.tw-fill-sky-600\/30 {
  fill: rgb(2 132 199 / 0.3) !important;
}

.tw-fill-sky-600\/35 {
  fill: rgb(2 132 199 / 0.35) !important;
}

.tw-fill-sky-600\/40 {
  fill: rgb(2 132 199 / 0.4) !important;
}

.tw-fill-sky-600\/45 {
  fill: rgb(2 132 199 / 0.45) !important;
}

.tw-fill-sky-600\/5 {
  fill: rgb(2 132 199 / 0.05) !important;
}

.tw-fill-sky-600\/50 {
  fill: rgb(2 132 199 / 0.5) !important;
}

.tw-fill-sky-600\/55 {
  fill: rgb(2 132 199 / 0.55) !important;
}

.tw-fill-sky-600\/60 {
  fill: rgb(2 132 199 / 0.6) !important;
}

.tw-fill-sky-600\/65 {
  fill: rgb(2 132 199 / 0.65) !important;
}

.tw-fill-sky-600\/70 {
  fill: rgb(2 132 199 / 0.7) !important;
}

.tw-fill-sky-600\/75 {
  fill: rgb(2 132 199 / 0.75) !important;
}

.tw-fill-sky-600\/80 {
  fill: rgb(2 132 199 / 0.8) !important;
}

.tw-fill-sky-600\/85 {
  fill: rgb(2 132 199 / 0.85) !important;
}

.tw-fill-sky-600\/90 {
  fill: rgb(2 132 199 / 0.9) !important;
}

.tw-fill-sky-600\/95 {
  fill: rgb(2 132 199 / 0.95) !important;
}

.tw-fill-sky-700 {
  fill: #0369a1 !important;
}

.tw-fill-sky-700\/0 {
  fill: rgb(3 105 161 / 0) !important;
}

.tw-fill-sky-700\/10 {
  fill: rgb(3 105 161 / 0.1) !important;
}

.tw-fill-sky-700\/100 {
  fill: rgb(3 105 161 / 1) !important;
}

.tw-fill-sky-700\/15 {
  fill: rgb(3 105 161 / 0.15) !important;
}

.tw-fill-sky-700\/20 {
  fill: rgb(3 105 161 / 0.2) !important;
}

.tw-fill-sky-700\/25 {
  fill: rgb(3 105 161 / 0.25) !important;
}

.tw-fill-sky-700\/30 {
  fill: rgb(3 105 161 / 0.3) !important;
}

.tw-fill-sky-700\/35 {
  fill: rgb(3 105 161 / 0.35) !important;
}

.tw-fill-sky-700\/40 {
  fill: rgb(3 105 161 / 0.4) !important;
}

.tw-fill-sky-700\/45 {
  fill: rgb(3 105 161 / 0.45) !important;
}

.tw-fill-sky-700\/5 {
  fill: rgb(3 105 161 / 0.05) !important;
}

.tw-fill-sky-700\/50 {
  fill: rgb(3 105 161 / 0.5) !important;
}

.tw-fill-sky-700\/55 {
  fill: rgb(3 105 161 / 0.55) !important;
}

.tw-fill-sky-700\/60 {
  fill: rgb(3 105 161 / 0.6) !important;
}

.tw-fill-sky-700\/65 {
  fill: rgb(3 105 161 / 0.65) !important;
}

.tw-fill-sky-700\/70 {
  fill: rgb(3 105 161 / 0.7) !important;
}

.tw-fill-sky-700\/75 {
  fill: rgb(3 105 161 / 0.75) !important;
}

.tw-fill-sky-700\/80 {
  fill: rgb(3 105 161 / 0.8) !important;
}

.tw-fill-sky-700\/85 {
  fill: rgb(3 105 161 / 0.85) !important;
}

.tw-fill-sky-700\/90 {
  fill: rgb(3 105 161 / 0.9) !important;
}

.tw-fill-sky-700\/95 {
  fill: rgb(3 105 161 / 0.95) !important;
}

.tw-fill-sky-800 {
  fill: #075985 !important;
}

.tw-fill-sky-800\/0 {
  fill: rgb(7 89 133 / 0) !important;
}

.tw-fill-sky-800\/10 {
  fill: rgb(7 89 133 / 0.1) !important;
}

.tw-fill-sky-800\/100 {
  fill: rgb(7 89 133 / 1) !important;
}

.tw-fill-sky-800\/15 {
  fill: rgb(7 89 133 / 0.15) !important;
}

.tw-fill-sky-800\/20 {
  fill: rgb(7 89 133 / 0.2) !important;
}

.tw-fill-sky-800\/25 {
  fill: rgb(7 89 133 / 0.25) !important;
}

.tw-fill-sky-800\/30 {
  fill: rgb(7 89 133 / 0.3) !important;
}

.tw-fill-sky-800\/35 {
  fill: rgb(7 89 133 / 0.35) !important;
}

.tw-fill-sky-800\/40 {
  fill: rgb(7 89 133 / 0.4) !important;
}

.tw-fill-sky-800\/45 {
  fill: rgb(7 89 133 / 0.45) !important;
}

.tw-fill-sky-800\/5 {
  fill: rgb(7 89 133 / 0.05) !important;
}

.tw-fill-sky-800\/50 {
  fill: rgb(7 89 133 / 0.5) !important;
}

.tw-fill-sky-800\/55 {
  fill: rgb(7 89 133 / 0.55) !important;
}

.tw-fill-sky-800\/60 {
  fill: rgb(7 89 133 / 0.6) !important;
}

.tw-fill-sky-800\/65 {
  fill: rgb(7 89 133 / 0.65) !important;
}

.tw-fill-sky-800\/70 {
  fill: rgb(7 89 133 / 0.7) !important;
}

.tw-fill-sky-800\/75 {
  fill: rgb(7 89 133 / 0.75) !important;
}

.tw-fill-sky-800\/80 {
  fill: rgb(7 89 133 / 0.8) !important;
}

.tw-fill-sky-800\/85 {
  fill: rgb(7 89 133 / 0.85) !important;
}

.tw-fill-sky-800\/90 {
  fill: rgb(7 89 133 / 0.9) !important;
}

.tw-fill-sky-800\/95 {
  fill: rgb(7 89 133 / 0.95) !important;
}

.tw-fill-sky-900 {
  fill: #0c4a6e !important;
}

.tw-fill-sky-900\/0 {
  fill: rgb(12 74 110 / 0) !important;
}

.tw-fill-sky-900\/10 {
  fill: rgb(12 74 110 / 0.1) !important;
}

.tw-fill-sky-900\/100 {
  fill: rgb(12 74 110 / 1) !important;
}

.tw-fill-sky-900\/15 {
  fill: rgb(12 74 110 / 0.15) !important;
}

.tw-fill-sky-900\/20 {
  fill: rgb(12 74 110 / 0.2) !important;
}

.tw-fill-sky-900\/25 {
  fill: rgb(12 74 110 / 0.25) !important;
}

.tw-fill-sky-900\/30 {
  fill: rgb(12 74 110 / 0.3) !important;
}

.tw-fill-sky-900\/35 {
  fill: rgb(12 74 110 / 0.35) !important;
}

.tw-fill-sky-900\/40 {
  fill: rgb(12 74 110 / 0.4) !important;
}

.tw-fill-sky-900\/45 {
  fill: rgb(12 74 110 / 0.45) !important;
}

.tw-fill-sky-900\/5 {
  fill: rgb(12 74 110 / 0.05) !important;
}

.tw-fill-sky-900\/50 {
  fill: rgb(12 74 110 / 0.5) !important;
}

.tw-fill-sky-900\/55 {
  fill: rgb(12 74 110 / 0.55) !important;
}

.tw-fill-sky-900\/60 {
  fill: rgb(12 74 110 / 0.6) !important;
}

.tw-fill-sky-900\/65 {
  fill: rgb(12 74 110 / 0.65) !important;
}

.tw-fill-sky-900\/70 {
  fill: rgb(12 74 110 / 0.7) !important;
}

.tw-fill-sky-900\/75 {
  fill: rgb(12 74 110 / 0.75) !important;
}

.tw-fill-sky-900\/80 {
  fill: rgb(12 74 110 / 0.8) !important;
}

.tw-fill-sky-900\/85 {
  fill: rgb(12 74 110 / 0.85) !important;
}

.tw-fill-sky-900\/90 {
  fill: rgb(12 74 110 / 0.9) !important;
}

.tw-fill-sky-900\/95 {
  fill: rgb(12 74 110 / 0.95) !important;
}

.tw-fill-sky-950 {
  fill: #082f49 !important;
}

.tw-fill-sky-950\/0 {
  fill: rgb(8 47 73 / 0) !important;
}

.tw-fill-sky-950\/10 {
  fill: rgb(8 47 73 / 0.1) !important;
}

.tw-fill-sky-950\/100 {
  fill: rgb(8 47 73 / 1) !important;
}

.tw-fill-sky-950\/15 {
  fill: rgb(8 47 73 / 0.15) !important;
}

.tw-fill-sky-950\/20 {
  fill: rgb(8 47 73 / 0.2) !important;
}

.tw-fill-sky-950\/25 {
  fill: rgb(8 47 73 / 0.25) !important;
}

.tw-fill-sky-950\/30 {
  fill: rgb(8 47 73 / 0.3) !important;
}

.tw-fill-sky-950\/35 {
  fill: rgb(8 47 73 / 0.35) !important;
}

.tw-fill-sky-950\/40 {
  fill: rgb(8 47 73 / 0.4) !important;
}

.tw-fill-sky-950\/45 {
  fill: rgb(8 47 73 / 0.45) !important;
}

.tw-fill-sky-950\/5 {
  fill: rgb(8 47 73 / 0.05) !important;
}

.tw-fill-sky-950\/50 {
  fill: rgb(8 47 73 / 0.5) !important;
}

.tw-fill-sky-950\/55 {
  fill: rgb(8 47 73 / 0.55) !important;
}

.tw-fill-sky-950\/60 {
  fill: rgb(8 47 73 / 0.6) !important;
}

.tw-fill-sky-950\/65 {
  fill: rgb(8 47 73 / 0.65) !important;
}

.tw-fill-sky-950\/70 {
  fill: rgb(8 47 73 / 0.7) !important;
}

.tw-fill-sky-950\/75 {
  fill: rgb(8 47 73 / 0.75) !important;
}

.tw-fill-sky-950\/80 {
  fill: rgb(8 47 73 / 0.8) !important;
}

.tw-fill-sky-950\/85 {
  fill: rgb(8 47 73 / 0.85) !important;
}

.tw-fill-sky-950\/90 {
  fill: rgb(8 47 73 / 0.9) !important;
}

.tw-fill-sky-950\/95 {
  fill: rgb(8 47 73 / 0.95) !important;
}

.tw-fill-slate-100 {
  fill: #f1f5f9 !important;
}

.tw-fill-slate-100\/0 {
  fill: rgb(241 245 249 / 0) !important;
}

.tw-fill-slate-100\/10 {
  fill: rgb(241 245 249 / 0.1) !important;
}

.tw-fill-slate-100\/100 {
  fill: rgb(241 245 249 / 1) !important;
}

.tw-fill-slate-100\/15 {
  fill: rgb(241 245 249 / 0.15) !important;
}

.tw-fill-slate-100\/20 {
  fill: rgb(241 245 249 / 0.2) !important;
}

.tw-fill-slate-100\/25 {
  fill: rgb(241 245 249 / 0.25) !important;
}

.tw-fill-slate-100\/30 {
  fill: rgb(241 245 249 / 0.3) !important;
}

.tw-fill-slate-100\/35 {
  fill: rgb(241 245 249 / 0.35) !important;
}

.tw-fill-slate-100\/40 {
  fill: rgb(241 245 249 / 0.4) !important;
}

.tw-fill-slate-100\/45 {
  fill: rgb(241 245 249 / 0.45) !important;
}

.tw-fill-slate-100\/5 {
  fill: rgb(241 245 249 / 0.05) !important;
}

.tw-fill-slate-100\/50 {
  fill: rgb(241 245 249 / 0.5) !important;
}

.tw-fill-slate-100\/55 {
  fill: rgb(241 245 249 / 0.55) !important;
}

.tw-fill-slate-100\/60 {
  fill: rgb(241 245 249 / 0.6) !important;
}

.tw-fill-slate-100\/65 {
  fill: rgb(241 245 249 / 0.65) !important;
}

.tw-fill-slate-100\/70 {
  fill: rgb(241 245 249 / 0.7) !important;
}

.tw-fill-slate-100\/75 {
  fill: rgb(241 245 249 / 0.75) !important;
}

.tw-fill-slate-100\/80 {
  fill: rgb(241 245 249 / 0.8) !important;
}

.tw-fill-slate-100\/85 {
  fill: rgb(241 245 249 / 0.85) !important;
}

.tw-fill-slate-100\/90 {
  fill: rgb(241 245 249 / 0.9) !important;
}

.tw-fill-slate-100\/95 {
  fill: rgb(241 245 249 / 0.95) !important;
}

.tw-fill-slate-200 {
  fill: #e2e8f0 !important;
}

.tw-fill-slate-200\/0 {
  fill: rgb(226 232 240 / 0) !important;
}

.tw-fill-slate-200\/10 {
  fill: rgb(226 232 240 / 0.1) !important;
}

.tw-fill-slate-200\/100 {
  fill: rgb(226 232 240 / 1) !important;
}

.tw-fill-slate-200\/15 {
  fill: rgb(226 232 240 / 0.15) !important;
}

.tw-fill-slate-200\/20 {
  fill: rgb(226 232 240 / 0.2) !important;
}

.tw-fill-slate-200\/25 {
  fill: rgb(226 232 240 / 0.25) !important;
}

.tw-fill-slate-200\/30 {
  fill: rgb(226 232 240 / 0.3) !important;
}

.tw-fill-slate-200\/35 {
  fill: rgb(226 232 240 / 0.35) !important;
}

.tw-fill-slate-200\/40 {
  fill: rgb(226 232 240 / 0.4) !important;
}

.tw-fill-slate-200\/45 {
  fill: rgb(226 232 240 / 0.45) !important;
}

.tw-fill-slate-200\/5 {
  fill: rgb(226 232 240 / 0.05) !important;
}

.tw-fill-slate-200\/50 {
  fill: rgb(226 232 240 / 0.5) !important;
}

.tw-fill-slate-200\/55 {
  fill: rgb(226 232 240 / 0.55) !important;
}

.tw-fill-slate-200\/60 {
  fill: rgb(226 232 240 / 0.6) !important;
}

.tw-fill-slate-200\/65 {
  fill: rgb(226 232 240 / 0.65) !important;
}

.tw-fill-slate-200\/70 {
  fill: rgb(226 232 240 / 0.7) !important;
}

.tw-fill-slate-200\/75 {
  fill: rgb(226 232 240 / 0.75) !important;
}

.tw-fill-slate-200\/80 {
  fill: rgb(226 232 240 / 0.8) !important;
}

.tw-fill-slate-200\/85 {
  fill: rgb(226 232 240 / 0.85) !important;
}

.tw-fill-slate-200\/90 {
  fill: rgb(226 232 240 / 0.9) !important;
}

.tw-fill-slate-200\/95 {
  fill: rgb(226 232 240 / 0.95) !important;
}

.tw-fill-slate-300 {
  fill: #cbd5e1 !important;
}

.tw-fill-slate-300\/0 {
  fill: rgb(203 213 225 / 0) !important;
}

.tw-fill-slate-300\/10 {
  fill: rgb(203 213 225 / 0.1) !important;
}

.tw-fill-slate-300\/100 {
  fill: rgb(203 213 225 / 1) !important;
}

.tw-fill-slate-300\/15 {
  fill: rgb(203 213 225 / 0.15) !important;
}

.tw-fill-slate-300\/20 {
  fill: rgb(203 213 225 / 0.2) !important;
}

.tw-fill-slate-300\/25 {
  fill: rgb(203 213 225 / 0.25) !important;
}

.tw-fill-slate-300\/30 {
  fill: rgb(203 213 225 / 0.3) !important;
}

.tw-fill-slate-300\/35 {
  fill: rgb(203 213 225 / 0.35) !important;
}

.tw-fill-slate-300\/40 {
  fill: rgb(203 213 225 / 0.4) !important;
}

.tw-fill-slate-300\/45 {
  fill: rgb(203 213 225 / 0.45) !important;
}

.tw-fill-slate-300\/5 {
  fill: rgb(203 213 225 / 0.05) !important;
}

.tw-fill-slate-300\/50 {
  fill: rgb(203 213 225 / 0.5) !important;
}

.tw-fill-slate-300\/55 {
  fill: rgb(203 213 225 / 0.55) !important;
}

.tw-fill-slate-300\/60 {
  fill: rgb(203 213 225 / 0.6) !important;
}

.tw-fill-slate-300\/65 {
  fill: rgb(203 213 225 / 0.65) !important;
}

.tw-fill-slate-300\/70 {
  fill: rgb(203 213 225 / 0.7) !important;
}

.tw-fill-slate-300\/75 {
  fill: rgb(203 213 225 / 0.75) !important;
}

.tw-fill-slate-300\/80 {
  fill: rgb(203 213 225 / 0.8) !important;
}

.tw-fill-slate-300\/85 {
  fill: rgb(203 213 225 / 0.85) !important;
}

.tw-fill-slate-300\/90 {
  fill: rgb(203 213 225 / 0.9) !important;
}

.tw-fill-slate-300\/95 {
  fill: rgb(203 213 225 / 0.95) !important;
}

.tw-fill-slate-400 {
  fill: #94a3b8 !important;
}

.tw-fill-slate-400\/0 {
  fill: rgb(148 163 184 / 0) !important;
}

.tw-fill-slate-400\/10 {
  fill: rgb(148 163 184 / 0.1) !important;
}

.tw-fill-slate-400\/100 {
  fill: rgb(148 163 184 / 1) !important;
}

.tw-fill-slate-400\/15 {
  fill: rgb(148 163 184 / 0.15) !important;
}

.tw-fill-slate-400\/20 {
  fill: rgb(148 163 184 / 0.2) !important;
}

.tw-fill-slate-400\/25 {
  fill: rgb(148 163 184 / 0.25) !important;
}

.tw-fill-slate-400\/30 {
  fill: rgb(148 163 184 / 0.3) !important;
}

.tw-fill-slate-400\/35 {
  fill: rgb(148 163 184 / 0.35) !important;
}

.tw-fill-slate-400\/40 {
  fill: rgb(148 163 184 / 0.4) !important;
}

.tw-fill-slate-400\/45 {
  fill: rgb(148 163 184 / 0.45) !important;
}

.tw-fill-slate-400\/5 {
  fill: rgb(148 163 184 / 0.05) !important;
}

.tw-fill-slate-400\/50 {
  fill: rgb(148 163 184 / 0.5) !important;
}

.tw-fill-slate-400\/55 {
  fill: rgb(148 163 184 / 0.55) !important;
}

.tw-fill-slate-400\/60 {
  fill: rgb(148 163 184 / 0.6) !important;
}

.tw-fill-slate-400\/65 {
  fill: rgb(148 163 184 / 0.65) !important;
}

.tw-fill-slate-400\/70 {
  fill: rgb(148 163 184 / 0.7) !important;
}

.tw-fill-slate-400\/75 {
  fill: rgb(148 163 184 / 0.75) !important;
}

.tw-fill-slate-400\/80 {
  fill: rgb(148 163 184 / 0.8) !important;
}

.tw-fill-slate-400\/85 {
  fill: rgb(148 163 184 / 0.85) !important;
}

.tw-fill-slate-400\/90 {
  fill: rgb(148 163 184 / 0.9) !important;
}

.tw-fill-slate-400\/95 {
  fill: rgb(148 163 184 / 0.95) !important;
}

.tw-fill-slate-50 {
  fill: #f8fafc !important;
}

.tw-fill-slate-50\/0 {
  fill: rgb(248 250 252 / 0) !important;
}

.tw-fill-slate-50\/10 {
  fill: rgb(248 250 252 / 0.1) !important;
}

.tw-fill-slate-50\/100 {
  fill: rgb(248 250 252 / 1) !important;
}

.tw-fill-slate-50\/15 {
  fill: rgb(248 250 252 / 0.15) !important;
}

.tw-fill-slate-50\/20 {
  fill: rgb(248 250 252 / 0.2) !important;
}

.tw-fill-slate-50\/25 {
  fill: rgb(248 250 252 / 0.25) !important;
}

.tw-fill-slate-50\/30 {
  fill: rgb(248 250 252 / 0.3) !important;
}

.tw-fill-slate-50\/35 {
  fill: rgb(248 250 252 / 0.35) !important;
}

.tw-fill-slate-50\/40 {
  fill: rgb(248 250 252 / 0.4) !important;
}

.tw-fill-slate-50\/45 {
  fill: rgb(248 250 252 / 0.45) !important;
}

.tw-fill-slate-50\/5 {
  fill: rgb(248 250 252 / 0.05) !important;
}

.tw-fill-slate-50\/50 {
  fill: rgb(248 250 252 / 0.5) !important;
}

.tw-fill-slate-50\/55 {
  fill: rgb(248 250 252 / 0.55) !important;
}

.tw-fill-slate-50\/60 {
  fill: rgb(248 250 252 / 0.6) !important;
}

.tw-fill-slate-50\/65 {
  fill: rgb(248 250 252 / 0.65) !important;
}

.tw-fill-slate-50\/70 {
  fill: rgb(248 250 252 / 0.7) !important;
}

.tw-fill-slate-50\/75 {
  fill: rgb(248 250 252 / 0.75) !important;
}

.tw-fill-slate-50\/80 {
  fill: rgb(248 250 252 / 0.8) !important;
}

.tw-fill-slate-50\/85 {
  fill: rgb(248 250 252 / 0.85) !important;
}

.tw-fill-slate-50\/90 {
  fill: rgb(248 250 252 / 0.9) !important;
}

.tw-fill-slate-50\/95 {
  fill: rgb(248 250 252 / 0.95) !important;
}

.tw-fill-slate-500 {
  fill: #64748b !important;
}

.tw-fill-slate-500\/0 {
  fill: rgb(100 116 139 / 0) !important;
}

.tw-fill-slate-500\/10 {
  fill: rgb(100 116 139 / 0.1) !important;
}

.tw-fill-slate-500\/100 {
  fill: rgb(100 116 139 / 1) !important;
}

.tw-fill-slate-500\/15 {
  fill: rgb(100 116 139 / 0.15) !important;
}

.tw-fill-slate-500\/20 {
  fill: rgb(100 116 139 / 0.2) !important;
}

.tw-fill-slate-500\/25 {
  fill: rgb(100 116 139 / 0.25) !important;
}

.tw-fill-slate-500\/30 {
  fill: rgb(100 116 139 / 0.3) !important;
}

.tw-fill-slate-500\/35 {
  fill: rgb(100 116 139 / 0.35) !important;
}

.tw-fill-slate-500\/40 {
  fill: rgb(100 116 139 / 0.4) !important;
}

.tw-fill-slate-500\/45 {
  fill: rgb(100 116 139 / 0.45) !important;
}

.tw-fill-slate-500\/5 {
  fill: rgb(100 116 139 / 0.05) !important;
}

.tw-fill-slate-500\/50 {
  fill: rgb(100 116 139 / 0.5) !important;
}

.tw-fill-slate-500\/55 {
  fill: rgb(100 116 139 / 0.55) !important;
}

.tw-fill-slate-500\/60 {
  fill: rgb(100 116 139 / 0.6) !important;
}

.tw-fill-slate-500\/65 {
  fill: rgb(100 116 139 / 0.65) !important;
}

.tw-fill-slate-500\/70 {
  fill: rgb(100 116 139 / 0.7) !important;
}

.tw-fill-slate-500\/75 {
  fill: rgb(100 116 139 / 0.75) !important;
}

.tw-fill-slate-500\/80 {
  fill: rgb(100 116 139 / 0.8) !important;
}

.tw-fill-slate-500\/85 {
  fill: rgb(100 116 139 / 0.85) !important;
}

.tw-fill-slate-500\/90 {
  fill: rgb(100 116 139 / 0.9) !important;
}

.tw-fill-slate-500\/95 {
  fill: rgb(100 116 139 / 0.95) !important;
}

.tw-fill-slate-600 {
  fill: #475569 !important;
}

.tw-fill-slate-600\/0 {
  fill: rgb(71 85 105 / 0) !important;
}

.tw-fill-slate-600\/10 {
  fill: rgb(71 85 105 / 0.1) !important;
}

.tw-fill-slate-600\/100 {
  fill: rgb(71 85 105 / 1) !important;
}

.tw-fill-slate-600\/15 {
  fill: rgb(71 85 105 / 0.15) !important;
}

.tw-fill-slate-600\/20 {
  fill: rgb(71 85 105 / 0.2) !important;
}

.tw-fill-slate-600\/25 {
  fill: rgb(71 85 105 / 0.25) !important;
}

.tw-fill-slate-600\/30 {
  fill: rgb(71 85 105 / 0.3) !important;
}

.tw-fill-slate-600\/35 {
  fill: rgb(71 85 105 / 0.35) !important;
}

.tw-fill-slate-600\/40 {
  fill: rgb(71 85 105 / 0.4) !important;
}

.tw-fill-slate-600\/45 {
  fill: rgb(71 85 105 / 0.45) !important;
}

.tw-fill-slate-600\/5 {
  fill: rgb(71 85 105 / 0.05) !important;
}

.tw-fill-slate-600\/50 {
  fill: rgb(71 85 105 / 0.5) !important;
}

.tw-fill-slate-600\/55 {
  fill: rgb(71 85 105 / 0.55) !important;
}

.tw-fill-slate-600\/60 {
  fill: rgb(71 85 105 / 0.6) !important;
}

.tw-fill-slate-600\/65 {
  fill: rgb(71 85 105 / 0.65) !important;
}

.tw-fill-slate-600\/70 {
  fill: rgb(71 85 105 / 0.7) !important;
}

.tw-fill-slate-600\/75 {
  fill: rgb(71 85 105 / 0.75) !important;
}

.tw-fill-slate-600\/80 {
  fill: rgb(71 85 105 / 0.8) !important;
}

.tw-fill-slate-600\/85 {
  fill: rgb(71 85 105 / 0.85) !important;
}

.tw-fill-slate-600\/90 {
  fill: rgb(71 85 105 / 0.9) !important;
}

.tw-fill-slate-600\/95 {
  fill: rgb(71 85 105 / 0.95) !important;
}

.tw-fill-slate-700 {
  fill: #334155 !important;
}

.tw-fill-slate-700\/0 {
  fill: rgb(51 65 85 / 0) !important;
}

.tw-fill-slate-700\/10 {
  fill: rgb(51 65 85 / 0.1) !important;
}

.tw-fill-slate-700\/100 {
  fill: rgb(51 65 85 / 1) !important;
}

.tw-fill-slate-700\/15 {
  fill: rgb(51 65 85 / 0.15) !important;
}

.tw-fill-slate-700\/20 {
  fill: rgb(51 65 85 / 0.2) !important;
}

.tw-fill-slate-700\/25 {
  fill: rgb(51 65 85 / 0.25) !important;
}

.tw-fill-slate-700\/30 {
  fill: rgb(51 65 85 / 0.3) !important;
}

.tw-fill-slate-700\/35 {
  fill: rgb(51 65 85 / 0.35) !important;
}

.tw-fill-slate-700\/40 {
  fill: rgb(51 65 85 / 0.4) !important;
}

.tw-fill-slate-700\/45 {
  fill: rgb(51 65 85 / 0.45) !important;
}

.tw-fill-slate-700\/5 {
  fill: rgb(51 65 85 / 0.05) !important;
}

.tw-fill-slate-700\/50 {
  fill: rgb(51 65 85 / 0.5) !important;
}

.tw-fill-slate-700\/55 {
  fill: rgb(51 65 85 / 0.55) !important;
}

.tw-fill-slate-700\/60 {
  fill: rgb(51 65 85 / 0.6) !important;
}

.tw-fill-slate-700\/65 {
  fill: rgb(51 65 85 / 0.65) !important;
}

.tw-fill-slate-700\/70 {
  fill: rgb(51 65 85 / 0.7) !important;
}

.tw-fill-slate-700\/75 {
  fill: rgb(51 65 85 / 0.75) !important;
}

.tw-fill-slate-700\/80 {
  fill: rgb(51 65 85 / 0.8) !important;
}

.tw-fill-slate-700\/85 {
  fill: rgb(51 65 85 / 0.85) !important;
}

.tw-fill-slate-700\/90 {
  fill: rgb(51 65 85 / 0.9) !important;
}

.tw-fill-slate-700\/95 {
  fill: rgb(51 65 85 / 0.95) !important;
}

.tw-fill-slate-800 {
  fill: #1e293b !important;
}

.tw-fill-slate-800\/0 {
  fill: rgb(30 41 59 / 0) !important;
}

.tw-fill-slate-800\/10 {
  fill: rgb(30 41 59 / 0.1) !important;
}

.tw-fill-slate-800\/100 {
  fill: rgb(30 41 59 / 1) !important;
}

.tw-fill-slate-800\/15 {
  fill: rgb(30 41 59 / 0.15) !important;
}

.tw-fill-slate-800\/20 {
  fill: rgb(30 41 59 / 0.2) !important;
}

.tw-fill-slate-800\/25 {
  fill: rgb(30 41 59 / 0.25) !important;
}

.tw-fill-slate-800\/30 {
  fill: rgb(30 41 59 / 0.3) !important;
}

.tw-fill-slate-800\/35 {
  fill: rgb(30 41 59 / 0.35) !important;
}

.tw-fill-slate-800\/40 {
  fill: rgb(30 41 59 / 0.4) !important;
}

.tw-fill-slate-800\/45 {
  fill: rgb(30 41 59 / 0.45) !important;
}

.tw-fill-slate-800\/5 {
  fill: rgb(30 41 59 / 0.05) !important;
}

.tw-fill-slate-800\/50 {
  fill: rgb(30 41 59 / 0.5) !important;
}

.tw-fill-slate-800\/55 {
  fill: rgb(30 41 59 / 0.55) !important;
}

.tw-fill-slate-800\/60 {
  fill: rgb(30 41 59 / 0.6) !important;
}

.tw-fill-slate-800\/65 {
  fill: rgb(30 41 59 / 0.65) !important;
}

.tw-fill-slate-800\/70 {
  fill: rgb(30 41 59 / 0.7) !important;
}

.tw-fill-slate-800\/75 {
  fill: rgb(30 41 59 / 0.75) !important;
}

.tw-fill-slate-800\/80 {
  fill: rgb(30 41 59 / 0.8) !important;
}

.tw-fill-slate-800\/85 {
  fill: rgb(30 41 59 / 0.85) !important;
}

.tw-fill-slate-800\/90 {
  fill: rgb(30 41 59 / 0.9) !important;
}

.tw-fill-slate-800\/95 {
  fill: rgb(30 41 59 / 0.95) !important;
}

.tw-fill-slate-900 {
  fill: #0f172a !important;
}

.tw-fill-slate-900\/0 {
  fill: rgb(15 23 42 / 0) !important;
}

.tw-fill-slate-900\/10 {
  fill: rgb(15 23 42 / 0.1) !important;
}

.tw-fill-slate-900\/100 {
  fill: rgb(15 23 42 / 1) !important;
}

.tw-fill-slate-900\/15 {
  fill: rgb(15 23 42 / 0.15) !important;
}

.tw-fill-slate-900\/20 {
  fill: rgb(15 23 42 / 0.2) !important;
}

.tw-fill-slate-900\/25 {
  fill: rgb(15 23 42 / 0.25) !important;
}

.tw-fill-slate-900\/30 {
  fill: rgb(15 23 42 / 0.3) !important;
}

.tw-fill-slate-900\/35 {
  fill: rgb(15 23 42 / 0.35) !important;
}

.tw-fill-slate-900\/40 {
  fill: rgb(15 23 42 / 0.4) !important;
}

.tw-fill-slate-900\/45 {
  fill: rgb(15 23 42 / 0.45) !important;
}

.tw-fill-slate-900\/5 {
  fill: rgb(15 23 42 / 0.05) !important;
}

.tw-fill-slate-900\/50 {
  fill: rgb(15 23 42 / 0.5) !important;
}

.tw-fill-slate-900\/55 {
  fill: rgb(15 23 42 / 0.55) !important;
}

.tw-fill-slate-900\/60 {
  fill: rgb(15 23 42 / 0.6) !important;
}

.tw-fill-slate-900\/65 {
  fill: rgb(15 23 42 / 0.65) !important;
}

.tw-fill-slate-900\/70 {
  fill: rgb(15 23 42 / 0.7) !important;
}

.tw-fill-slate-900\/75 {
  fill: rgb(15 23 42 / 0.75) !important;
}

.tw-fill-slate-900\/80 {
  fill: rgb(15 23 42 / 0.8) !important;
}

.tw-fill-slate-900\/85 {
  fill: rgb(15 23 42 / 0.85) !important;
}

.tw-fill-slate-900\/90 {
  fill: rgb(15 23 42 / 0.9) !important;
}

.tw-fill-slate-900\/95 {
  fill: rgb(15 23 42 / 0.95) !important;
}

.tw-fill-slate-950 {
  fill: #020617 !important;
}

.tw-fill-slate-950\/0 {
  fill: rgb(2 6 23 / 0) !important;
}

.tw-fill-slate-950\/10 {
  fill: rgb(2 6 23 / 0.1) !important;
}

.tw-fill-slate-950\/100 {
  fill: rgb(2 6 23 / 1) !important;
}

.tw-fill-slate-950\/15 {
  fill: rgb(2 6 23 / 0.15) !important;
}

.tw-fill-slate-950\/20 {
  fill: rgb(2 6 23 / 0.2) !important;
}

.tw-fill-slate-950\/25 {
  fill: rgb(2 6 23 / 0.25) !important;
}

.tw-fill-slate-950\/30 {
  fill: rgb(2 6 23 / 0.3) !important;
}

.tw-fill-slate-950\/35 {
  fill: rgb(2 6 23 / 0.35) !important;
}

.tw-fill-slate-950\/40 {
  fill: rgb(2 6 23 / 0.4) !important;
}

.tw-fill-slate-950\/45 {
  fill: rgb(2 6 23 / 0.45) !important;
}

.tw-fill-slate-950\/5 {
  fill: rgb(2 6 23 / 0.05) !important;
}

.tw-fill-slate-950\/50 {
  fill: rgb(2 6 23 / 0.5) !important;
}

.tw-fill-slate-950\/55 {
  fill: rgb(2 6 23 / 0.55) !important;
}

.tw-fill-slate-950\/60 {
  fill: rgb(2 6 23 / 0.6) !important;
}

.tw-fill-slate-950\/65 {
  fill: rgb(2 6 23 / 0.65) !important;
}

.tw-fill-slate-950\/70 {
  fill: rgb(2 6 23 / 0.7) !important;
}

.tw-fill-slate-950\/75 {
  fill: rgb(2 6 23 / 0.75) !important;
}

.tw-fill-slate-950\/80 {
  fill: rgb(2 6 23 / 0.8) !important;
}

.tw-fill-slate-950\/85 {
  fill: rgb(2 6 23 / 0.85) !important;
}

.tw-fill-slate-950\/90 {
  fill: rgb(2 6 23 / 0.9) !important;
}

.tw-fill-slate-950\/95 {
  fill: rgb(2 6 23 / 0.95) !important;
}

.tw-fill-stone-100 {
  fill: #f5f5f4 !important;
}

.tw-fill-stone-100\/0 {
  fill: rgb(245 245 244 / 0) !important;
}

.tw-fill-stone-100\/10 {
  fill: rgb(245 245 244 / 0.1) !important;
}

.tw-fill-stone-100\/100 {
  fill: rgb(245 245 244 / 1) !important;
}

.tw-fill-stone-100\/15 {
  fill: rgb(245 245 244 / 0.15) !important;
}

.tw-fill-stone-100\/20 {
  fill: rgb(245 245 244 / 0.2) !important;
}

.tw-fill-stone-100\/25 {
  fill: rgb(245 245 244 / 0.25) !important;
}

.tw-fill-stone-100\/30 {
  fill: rgb(245 245 244 / 0.3) !important;
}

.tw-fill-stone-100\/35 {
  fill: rgb(245 245 244 / 0.35) !important;
}

.tw-fill-stone-100\/40 {
  fill: rgb(245 245 244 / 0.4) !important;
}

.tw-fill-stone-100\/45 {
  fill: rgb(245 245 244 / 0.45) !important;
}

.tw-fill-stone-100\/5 {
  fill: rgb(245 245 244 / 0.05) !important;
}

.tw-fill-stone-100\/50 {
  fill: rgb(245 245 244 / 0.5) !important;
}

.tw-fill-stone-100\/55 {
  fill: rgb(245 245 244 / 0.55) !important;
}

.tw-fill-stone-100\/60 {
  fill: rgb(245 245 244 / 0.6) !important;
}

.tw-fill-stone-100\/65 {
  fill: rgb(245 245 244 / 0.65) !important;
}

.tw-fill-stone-100\/70 {
  fill: rgb(245 245 244 / 0.7) !important;
}

.tw-fill-stone-100\/75 {
  fill: rgb(245 245 244 / 0.75) !important;
}

.tw-fill-stone-100\/80 {
  fill: rgb(245 245 244 / 0.8) !important;
}

.tw-fill-stone-100\/85 {
  fill: rgb(245 245 244 / 0.85) !important;
}

.tw-fill-stone-100\/90 {
  fill: rgb(245 245 244 / 0.9) !important;
}

.tw-fill-stone-100\/95 {
  fill: rgb(245 245 244 / 0.95) !important;
}

.tw-fill-stone-200 {
  fill: #e7e5e4 !important;
}

.tw-fill-stone-200\/0 {
  fill: rgb(231 229 228 / 0) !important;
}

.tw-fill-stone-200\/10 {
  fill: rgb(231 229 228 / 0.1) !important;
}

.tw-fill-stone-200\/100 {
  fill: rgb(231 229 228 / 1) !important;
}

.tw-fill-stone-200\/15 {
  fill: rgb(231 229 228 / 0.15) !important;
}

.tw-fill-stone-200\/20 {
  fill: rgb(231 229 228 / 0.2) !important;
}

.tw-fill-stone-200\/25 {
  fill: rgb(231 229 228 / 0.25) !important;
}

.tw-fill-stone-200\/30 {
  fill: rgb(231 229 228 / 0.3) !important;
}

.tw-fill-stone-200\/35 {
  fill: rgb(231 229 228 / 0.35) !important;
}

.tw-fill-stone-200\/40 {
  fill: rgb(231 229 228 / 0.4) !important;
}

.tw-fill-stone-200\/45 {
  fill: rgb(231 229 228 / 0.45) !important;
}

.tw-fill-stone-200\/5 {
  fill: rgb(231 229 228 / 0.05) !important;
}

.tw-fill-stone-200\/50 {
  fill: rgb(231 229 228 / 0.5) !important;
}

.tw-fill-stone-200\/55 {
  fill: rgb(231 229 228 / 0.55) !important;
}

.tw-fill-stone-200\/60 {
  fill: rgb(231 229 228 / 0.6) !important;
}

.tw-fill-stone-200\/65 {
  fill: rgb(231 229 228 / 0.65) !important;
}

.tw-fill-stone-200\/70 {
  fill: rgb(231 229 228 / 0.7) !important;
}

.tw-fill-stone-200\/75 {
  fill: rgb(231 229 228 / 0.75) !important;
}

.tw-fill-stone-200\/80 {
  fill: rgb(231 229 228 / 0.8) !important;
}

.tw-fill-stone-200\/85 {
  fill: rgb(231 229 228 / 0.85) !important;
}

.tw-fill-stone-200\/90 {
  fill: rgb(231 229 228 / 0.9) !important;
}

.tw-fill-stone-200\/95 {
  fill: rgb(231 229 228 / 0.95) !important;
}

.tw-fill-stone-300 {
  fill: #d6d3d1 !important;
}

.tw-fill-stone-300\/0 {
  fill: rgb(214 211 209 / 0) !important;
}

.tw-fill-stone-300\/10 {
  fill: rgb(214 211 209 / 0.1) !important;
}

.tw-fill-stone-300\/100 {
  fill: rgb(214 211 209 / 1) !important;
}

.tw-fill-stone-300\/15 {
  fill: rgb(214 211 209 / 0.15) !important;
}

.tw-fill-stone-300\/20 {
  fill: rgb(214 211 209 / 0.2) !important;
}

.tw-fill-stone-300\/25 {
  fill: rgb(214 211 209 / 0.25) !important;
}

.tw-fill-stone-300\/30 {
  fill: rgb(214 211 209 / 0.3) !important;
}

.tw-fill-stone-300\/35 {
  fill: rgb(214 211 209 / 0.35) !important;
}

.tw-fill-stone-300\/40 {
  fill: rgb(214 211 209 / 0.4) !important;
}

.tw-fill-stone-300\/45 {
  fill: rgb(214 211 209 / 0.45) !important;
}

.tw-fill-stone-300\/5 {
  fill: rgb(214 211 209 / 0.05) !important;
}

.tw-fill-stone-300\/50 {
  fill: rgb(214 211 209 / 0.5) !important;
}

.tw-fill-stone-300\/55 {
  fill: rgb(214 211 209 / 0.55) !important;
}

.tw-fill-stone-300\/60 {
  fill: rgb(214 211 209 / 0.6) !important;
}

.tw-fill-stone-300\/65 {
  fill: rgb(214 211 209 / 0.65) !important;
}

.tw-fill-stone-300\/70 {
  fill: rgb(214 211 209 / 0.7) !important;
}

.tw-fill-stone-300\/75 {
  fill: rgb(214 211 209 / 0.75) !important;
}

.tw-fill-stone-300\/80 {
  fill: rgb(214 211 209 / 0.8) !important;
}

.tw-fill-stone-300\/85 {
  fill: rgb(214 211 209 / 0.85) !important;
}

.tw-fill-stone-300\/90 {
  fill: rgb(214 211 209 / 0.9) !important;
}

.tw-fill-stone-300\/95 {
  fill: rgb(214 211 209 / 0.95) !important;
}

.tw-fill-stone-400 {
  fill: #a8a29e !important;
}

.tw-fill-stone-400\/0 {
  fill: rgb(168 162 158 / 0) !important;
}

.tw-fill-stone-400\/10 {
  fill: rgb(168 162 158 / 0.1) !important;
}

.tw-fill-stone-400\/100 {
  fill: rgb(168 162 158 / 1) !important;
}

.tw-fill-stone-400\/15 {
  fill: rgb(168 162 158 / 0.15) !important;
}

.tw-fill-stone-400\/20 {
  fill: rgb(168 162 158 / 0.2) !important;
}

.tw-fill-stone-400\/25 {
  fill: rgb(168 162 158 / 0.25) !important;
}

.tw-fill-stone-400\/30 {
  fill: rgb(168 162 158 / 0.3) !important;
}

.tw-fill-stone-400\/35 {
  fill: rgb(168 162 158 / 0.35) !important;
}

.tw-fill-stone-400\/40 {
  fill: rgb(168 162 158 / 0.4) !important;
}

.tw-fill-stone-400\/45 {
  fill: rgb(168 162 158 / 0.45) !important;
}

.tw-fill-stone-400\/5 {
  fill: rgb(168 162 158 / 0.05) !important;
}

.tw-fill-stone-400\/50 {
  fill: rgb(168 162 158 / 0.5) !important;
}

.tw-fill-stone-400\/55 {
  fill: rgb(168 162 158 / 0.55) !important;
}

.tw-fill-stone-400\/60 {
  fill: rgb(168 162 158 / 0.6) !important;
}

.tw-fill-stone-400\/65 {
  fill: rgb(168 162 158 / 0.65) !important;
}

.tw-fill-stone-400\/70 {
  fill: rgb(168 162 158 / 0.7) !important;
}

.tw-fill-stone-400\/75 {
  fill: rgb(168 162 158 / 0.75) !important;
}

.tw-fill-stone-400\/80 {
  fill: rgb(168 162 158 / 0.8) !important;
}

.tw-fill-stone-400\/85 {
  fill: rgb(168 162 158 / 0.85) !important;
}

.tw-fill-stone-400\/90 {
  fill: rgb(168 162 158 / 0.9) !important;
}

.tw-fill-stone-400\/95 {
  fill: rgb(168 162 158 / 0.95) !important;
}

.tw-fill-stone-50 {
  fill: #fafaf9 !important;
}

.tw-fill-stone-50\/0 {
  fill: rgb(250 250 249 / 0) !important;
}

.tw-fill-stone-50\/10 {
  fill: rgb(250 250 249 / 0.1) !important;
}

.tw-fill-stone-50\/100 {
  fill: rgb(250 250 249 / 1) !important;
}

.tw-fill-stone-50\/15 {
  fill: rgb(250 250 249 / 0.15) !important;
}

.tw-fill-stone-50\/20 {
  fill: rgb(250 250 249 / 0.2) !important;
}

.tw-fill-stone-50\/25 {
  fill: rgb(250 250 249 / 0.25) !important;
}

.tw-fill-stone-50\/30 {
  fill: rgb(250 250 249 / 0.3) !important;
}

.tw-fill-stone-50\/35 {
  fill: rgb(250 250 249 / 0.35) !important;
}

.tw-fill-stone-50\/40 {
  fill: rgb(250 250 249 / 0.4) !important;
}

.tw-fill-stone-50\/45 {
  fill: rgb(250 250 249 / 0.45) !important;
}

.tw-fill-stone-50\/5 {
  fill: rgb(250 250 249 / 0.05) !important;
}

.tw-fill-stone-50\/50 {
  fill: rgb(250 250 249 / 0.5) !important;
}

.tw-fill-stone-50\/55 {
  fill: rgb(250 250 249 / 0.55) !important;
}

.tw-fill-stone-50\/60 {
  fill: rgb(250 250 249 / 0.6) !important;
}

.tw-fill-stone-50\/65 {
  fill: rgb(250 250 249 / 0.65) !important;
}

.tw-fill-stone-50\/70 {
  fill: rgb(250 250 249 / 0.7) !important;
}

.tw-fill-stone-50\/75 {
  fill: rgb(250 250 249 / 0.75) !important;
}

.tw-fill-stone-50\/80 {
  fill: rgb(250 250 249 / 0.8) !important;
}

.tw-fill-stone-50\/85 {
  fill: rgb(250 250 249 / 0.85) !important;
}

.tw-fill-stone-50\/90 {
  fill: rgb(250 250 249 / 0.9) !important;
}

.tw-fill-stone-50\/95 {
  fill: rgb(250 250 249 / 0.95) !important;
}

.tw-fill-stone-500 {
  fill: #78716c !important;
}

.tw-fill-stone-500\/0 {
  fill: rgb(120 113 108 / 0) !important;
}

.tw-fill-stone-500\/10 {
  fill: rgb(120 113 108 / 0.1) !important;
}

.tw-fill-stone-500\/100 {
  fill: rgb(120 113 108 / 1) !important;
}

.tw-fill-stone-500\/15 {
  fill: rgb(120 113 108 / 0.15) !important;
}

.tw-fill-stone-500\/20 {
  fill: rgb(120 113 108 / 0.2) !important;
}

.tw-fill-stone-500\/25 {
  fill: rgb(120 113 108 / 0.25) !important;
}

.tw-fill-stone-500\/30 {
  fill: rgb(120 113 108 / 0.3) !important;
}

.tw-fill-stone-500\/35 {
  fill: rgb(120 113 108 / 0.35) !important;
}

.tw-fill-stone-500\/40 {
  fill: rgb(120 113 108 / 0.4) !important;
}

.tw-fill-stone-500\/45 {
  fill: rgb(120 113 108 / 0.45) !important;
}

.tw-fill-stone-500\/5 {
  fill: rgb(120 113 108 / 0.05) !important;
}

.tw-fill-stone-500\/50 {
  fill: rgb(120 113 108 / 0.5) !important;
}

.tw-fill-stone-500\/55 {
  fill: rgb(120 113 108 / 0.55) !important;
}

.tw-fill-stone-500\/60 {
  fill: rgb(120 113 108 / 0.6) !important;
}

.tw-fill-stone-500\/65 {
  fill: rgb(120 113 108 / 0.65) !important;
}

.tw-fill-stone-500\/70 {
  fill: rgb(120 113 108 / 0.7) !important;
}

.tw-fill-stone-500\/75 {
  fill: rgb(120 113 108 / 0.75) !important;
}

.tw-fill-stone-500\/80 {
  fill: rgb(120 113 108 / 0.8) !important;
}

.tw-fill-stone-500\/85 {
  fill: rgb(120 113 108 / 0.85) !important;
}

.tw-fill-stone-500\/90 {
  fill: rgb(120 113 108 / 0.9) !important;
}

.tw-fill-stone-500\/95 {
  fill: rgb(120 113 108 / 0.95) !important;
}

.tw-fill-stone-600 {
  fill: #57534e !important;
}

.tw-fill-stone-600\/0 {
  fill: rgb(87 83 78 / 0) !important;
}

.tw-fill-stone-600\/10 {
  fill: rgb(87 83 78 / 0.1) !important;
}

.tw-fill-stone-600\/100 {
  fill: rgb(87 83 78 / 1) !important;
}

.tw-fill-stone-600\/15 {
  fill: rgb(87 83 78 / 0.15) !important;
}

.tw-fill-stone-600\/20 {
  fill: rgb(87 83 78 / 0.2) !important;
}

.tw-fill-stone-600\/25 {
  fill: rgb(87 83 78 / 0.25) !important;
}

.tw-fill-stone-600\/30 {
  fill: rgb(87 83 78 / 0.3) !important;
}

.tw-fill-stone-600\/35 {
  fill: rgb(87 83 78 / 0.35) !important;
}

.tw-fill-stone-600\/40 {
  fill: rgb(87 83 78 / 0.4) !important;
}

.tw-fill-stone-600\/45 {
  fill: rgb(87 83 78 / 0.45) !important;
}

.tw-fill-stone-600\/5 {
  fill: rgb(87 83 78 / 0.05) !important;
}

.tw-fill-stone-600\/50 {
  fill: rgb(87 83 78 / 0.5) !important;
}

.tw-fill-stone-600\/55 {
  fill: rgb(87 83 78 / 0.55) !important;
}

.tw-fill-stone-600\/60 {
  fill: rgb(87 83 78 / 0.6) !important;
}

.tw-fill-stone-600\/65 {
  fill: rgb(87 83 78 / 0.65) !important;
}

.tw-fill-stone-600\/70 {
  fill: rgb(87 83 78 / 0.7) !important;
}

.tw-fill-stone-600\/75 {
  fill: rgb(87 83 78 / 0.75) !important;
}

.tw-fill-stone-600\/80 {
  fill: rgb(87 83 78 / 0.8) !important;
}

.tw-fill-stone-600\/85 {
  fill: rgb(87 83 78 / 0.85) !important;
}

.tw-fill-stone-600\/90 {
  fill: rgb(87 83 78 / 0.9) !important;
}

.tw-fill-stone-600\/95 {
  fill: rgb(87 83 78 / 0.95) !important;
}

.tw-fill-stone-700 {
  fill: #44403c !important;
}

.tw-fill-stone-700\/0 {
  fill: rgb(68 64 60 / 0) !important;
}

.tw-fill-stone-700\/10 {
  fill: rgb(68 64 60 / 0.1) !important;
}

.tw-fill-stone-700\/100 {
  fill: rgb(68 64 60 / 1) !important;
}

.tw-fill-stone-700\/15 {
  fill: rgb(68 64 60 / 0.15) !important;
}

.tw-fill-stone-700\/20 {
  fill: rgb(68 64 60 / 0.2) !important;
}

.tw-fill-stone-700\/25 {
  fill: rgb(68 64 60 / 0.25) !important;
}

.tw-fill-stone-700\/30 {
  fill: rgb(68 64 60 / 0.3) !important;
}

.tw-fill-stone-700\/35 {
  fill: rgb(68 64 60 / 0.35) !important;
}

.tw-fill-stone-700\/40 {
  fill: rgb(68 64 60 / 0.4) !important;
}

.tw-fill-stone-700\/45 {
  fill: rgb(68 64 60 / 0.45) !important;
}

.tw-fill-stone-700\/5 {
  fill: rgb(68 64 60 / 0.05) !important;
}

.tw-fill-stone-700\/50 {
  fill: rgb(68 64 60 / 0.5) !important;
}

.tw-fill-stone-700\/55 {
  fill: rgb(68 64 60 / 0.55) !important;
}

.tw-fill-stone-700\/60 {
  fill: rgb(68 64 60 / 0.6) !important;
}

.tw-fill-stone-700\/65 {
  fill: rgb(68 64 60 / 0.65) !important;
}

.tw-fill-stone-700\/70 {
  fill: rgb(68 64 60 / 0.7) !important;
}

.tw-fill-stone-700\/75 {
  fill: rgb(68 64 60 / 0.75) !important;
}

.tw-fill-stone-700\/80 {
  fill: rgb(68 64 60 / 0.8) !important;
}

.tw-fill-stone-700\/85 {
  fill: rgb(68 64 60 / 0.85) !important;
}

.tw-fill-stone-700\/90 {
  fill: rgb(68 64 60 / 0.9) !important;
}

.tw-fill-stone-700\/95 {
  fill: rgb(68 64 60 / 0.95) !important;
}

.tw-fill-stone-800 {
  fill: #292524 !important;
}

.tw-fill-stone-800\/0 {
  fill: rgb(41 37 36 / 0) !important;
}

.tw-fill-stone-800\/10 {
  fill: rgb(41 37 36 / 0.1) !important;
}

.tw-fill-stone-800\/100 {
  fill: rgb(41 37 36 / 1) !important;
}

.tw-fill-stone-800\/15 {
  fill: rgb(41 37 36 / 0.15) !important;
}

.tw-fill-stone-800\/20 {
  fill: rgb(41 37 36 / 0.2) !important;
}

.tw-fill-stone-800\/25 {
  fill: rgb(41 37 36 / 0.25) !important;
}

.tw-fill-stone-800\/30 {
  fill: rgb(41 37 36 / 0.3) !important;
}

.tw-fill-stone-800\/35 {
  fill: rgb(41 37 36 / 0.35) !important;
}

.tw-fill-stone-800\/40 {
  fill: rgb(41 37 36 / 0.4) !important;
}

.tw-fill-stone-800\/45 {
  fill: rgb(41 37 36 / 0.45) !important;
}

.tw-fill-stone-800\/5 {
  fill: rgb(41 37 36 / 0.05) !important;
}

.tw-fill-stone-800\/50 {
  fill: rgb(41 37 36 / 0.5) !important;
}

.tw-fill-stone-800\/55 {
  fill: rgb(41 37 36 / 0.55) !important;
}

.tw-fill-stone-800\/60 {
  fill: rgb(41 37 36 / 0.6) !important;
}

.tw-fill-stone-800\/65 {
  fill: rgb(41 37 36 / 0.65) !important;
}

.tw-fill-stone-800\/70 {
  fill: rgb(41 37 36 / 0.7) !important;
}

.tw-fill-stone-800\/75 {
  fill: rgb(41 37 36 / 0.75) !important;
}

.tw-fill-stone-800\/80 {
  fill: rgb(41 37 36 / 0.8) !important;
}

.tw-fill-stone-800\/85 {
  fill: rgb(41 37 36 / 0.85) !important;
}

.tw-fill-stone-800\/90 {
  fill: rgb(41 37 36 / 0.9) !important;
}

.tw-fill-stone-800\/95 {
  fill: rgb(41 37 36 / 0.95) !important;
}

.tw-fill-stone-900 {
  fill: #1c1917 !important;
}

.tw-fill-stone-900\/0 {
  fill: rgb(28 25 23 / 0) !important;
}

.tw-fill-stone-900\/10 {
  fill: rgb(28 25 23 / 0.1) !important;
}

.tw-fill-stone-900\/100 {
  fill: rgb(28 25 23 / 1) !important;
}

.tw-fill-stone-900\/15 {
  fill: rgb(28 25 23 / 0.15) !important;
}

.tw-fill-stone-900\/20 {
  fill: rgb(28 25 23 / 0.2) !important;
}

.tw-fill-stone-900\/25 {
  fill: rgb(28 25 23 / 0.25) !important;
}

.tw-fill-stone-900\/30 {
  fill: rgb(28 25 23 / 0.3) !important;
}

.tw-fill-stone-900\/35 {
  fill: rgb(28 25 23 / 0.35) !important;
}

.tw-fill-stone-900\/40 {
  fill: rgb(28 25 23 / 0.4) !important;
}

.tw-fill-stone-900\/45 {
  fill: rgb(28 25 23 / 0.45) !important;
}

.tw-fill-stone-900\/5 {
  fill: rgb(28 25 23 / 0.05) !important;
}

.tw-fill-stone-900\/50 {
  fill: rgb(28 25 23 / 0.5) !important;
}

.tw-fill-stone-900\/55 {
  fill: rgb(28 25 23 / 0.55) !important;
}

.tw-fill-stone-900\/60 {
  fill: rgb(28 25 23 / 0.6) !important;
}

.tw-fill-stone-900\/65 {
  fill: rgb(28 25 23 / 0.65) !important;
}

.tw-fill-stone-900\/70 {
  fill: rgb(28 25 23 / 0.7) !important;
}

.tw-fill-stone-900\/75 {
  fill: rgb(28 25 23 / 0.75) !important;
}

.tw-fill-stone-900\/80 {
  fill: rgb(28 25 23 / 0.8) !important;
}

.tw-fill-stone-900\/85 {
  fill: rgb(28 25 23 / 0.85) !important;
}

.tw-fill-stone-900\/90 {
  fill: rgb(28 25 23 / 0.9) !important;
}

.tw-fill-stone-900\/95 {
  fill: rgb(28 25 23 / 0.95) !important;
}

.tw-fill-stone-950 {
  fill: #0c0a09 !important;
}

.tw-fill-stone-950\/0 {
  fill: rgb(12 10 9 / 0) !important;
}

.tw-fill-stone-950\/10 {
  fill: rgb(12 10 9 / 0.1) !important;
}

.tw-fill-stone-950\/100 {
  fill: rgb(12 10 9 / 1) !important;
}

.tw-fill-stone-950\/15 {
  fill: rgb(12 10 9 / 0.15) !important;
}

.tw-fill-stone-950\/20 {
  fill: rgb(12 10 9 / 0.2) !important;
}

.tw-fill-stone-950\/25 {
  fill: rgb(12 10 9 / 0.25) !important;
}

.tw-fill-stone-950\/30 {
  fill: rgb(12 10 9 / 0.3) !important;
}

.tw-fill-stone-950\/35 {
  fill: rgb(12 10 9 / 0.35) !important;
}

.tw-fill-stone-950\/40 {
  fill: rgb(12 10 9 / 0.4) !important;
}

.tw-fill-stone-950\/45 {
  fill: rgb(12 10 9 / 0.45) !important;
}

.tw-fill-stone-950\/5 {
  fill: rgb(12 10 9 / 0.05) !important;
}

.tw-fill-stone-950\/50 {
  fill: rgb(12 10 9 / 0.5) !important;
}

.tw-fill-stone-950\/55 {
  fill: rgb(12 10 9 / 0.55) !important;
}

.tw-fill-stone-950\/60 {
  fill: rgb(12 10 9 / 0.6) !important;
}

.tw-fill-stone-950\/65 {
  fill: rgb(12 10 9 / 0.65) !important;
}

.tw-fill-stone-950\/70 {
  fill: rgb(12 10 9 / 0.7) !important;
}

.tw-fill-stone-950\/75 {
  fill: rgb(12 10 9 / 0.75) !important;
}

.tw-fill-stone-950\/80 {
  fill: rgb(12 10 9 / 0.8) !important;
}

.tw-fill-stone-950\/85 {
  fill: rgb(12 10 9 / 0.85) !important;
}

.tw-fill-stone-950\/90 {
  fill: rgb(12 10 9 / 0.9) !important;
}

.tw-fill-stone-950\/95 {
  fill: rgb(12 10 9 / 0.95) !important;
}

.tw-fill-teal-100 {
  fill: #ccfbf1 !important;
}

.tw-fill-teal-100\/0 {
  fill: rgb(204 251 241 / 0) !important;
}

.tw-fill-teal-100\/10 {
  fill: rgb(204 251 241 / 0.1) !important;
}

.tw-fill-teal-100\/100 {
  fill: rgb(204 251 241 / 1) !important;
}

.tw-fill-teal-100\/15 {
  fill: rgb(204 251 241 / 0.15) !important;
}

.tw-fill-teal-100\/20 {
  fill: rgb(204 251 241 / 0.2) !important;
}

.tw-fill-teal-100\/25 {
  fill: rgb(204 251 241 / 0.25) !important;
}

.tw-fill-teal-100\/30 {
  fill: rgb(204 251 241 / 0.3) !important;
}

.tw-fill-teal-100\/35 {
  fill: rgb(204 251 241 / 0.35) !important;
}

.tw-fill-teal-100\/40 {
  fill: rgb(204 251 241 / 0.4) !important;
}

.tw-fill-teal-100\/45 {
  fill: rgb(204 251 241 / 0.45) !important;
}

.tw-fill-teal-100\/5 {
  fill: rgb(204 251 241 / 0.05) !important;
}

.tw-fill-teal-100\/50 {
  fill: rgb(204 251 241 / 0.5) !important;
}

.tw-fill-teal-100\/55 {
  fill: rgb(204 251 241 / 0.55) !important;
}

.tw-fill-teal-100\/60 {
  fill: rgb(204 251 241 / 0.6) !important;
}

.tw-fill-teal-100\/65 {
  fill: rgb(204 251 241 / 0.65) !important;
}

.tw-fill-teal-100\/70 {
  fill: rgb(204 251 241 / 0.7) !important;
}

.tw-fill-teal-100\/75 {
  fill: rgb(204 251 241 / 0.75) !important;
}

.tw-fill-teal-100\/80 {
  fill: rgb(204 251 241 / 0.8) !important;
}

.tw-fill-teal-100\/85 {
  fill: rgb(204 251 241 / 0.85) !important;
}

.tw-fill-teal-100\/90 {
  fill: rgb(204 251 241 / 0.9) !important;
}

.tw-fill-teal-100\/95 {
  fill: rgb(204 251 241 / 0.95) !important;
}

.tw-fill-teal-200 {
  fill: #99f6e4 !important;
}

.tw-fill-teal-200\/0 {
  fill: rgb(153 246 228 / 0) !important;
}

.tw-fill-teal-200\/10 {
  fill: rgb(153 246 228 / 0.1) !important;
}

.tw-fill-teal-200\/100 {
  fill: rgb(153 246 228 / 1) !important;
}

.tw-fill-teal-200\/15 {
  fill: rgb(153 246 228 / 0.15) !important;
}

.tw-fill-teal-200\/20 {
  fill: rgb(153 246 228 / 0.2) !important;
}

.tw-fill-teal-200\/25 {
  fill: rgb(153 246 228 / 0.25) !important;
}

.tw-fill-teal-200\/30 {
  fill: rgb(153 246 228 / 0.3) !important;
}

.tw-fill-teal-200\/35 {
  fill: rgb(153 246 228 / 0.35) !important;
}

.tw-fill-teal-200\/40 {
  fill: rgb(153 246 228 / 0.4) !important;
}

.tw-fill-teal-200\/45 {
  fill: rgb(153 246 228 / 0.45) !important;
}

.tw-fill-teal-200\/5 {
  fill: rgb(153 246 228 / 0.05) !important;
}

.tw-fill-teal-200\/50 {
  fill: rgb(153 246 228 / 0.5) !important;
}

.tw-fill-teal-200\/55 {
  fill: rgb(153 246 228 / 0.55) !important;
}

.tw-fill-teal-200\/60 {
  fill: rgb(153 246 228 / 0.6) !important;
}

.tw-fill-teal-200\/65 {
  fill: rgb(153 246 228 / 0.65) !important;
}

.tw-fill-teal-200\/70 {
  fill: rgb(153 246 228 / 0.7) !important;
}

.tw-fill-teal-200\/75 {
  fill: rgb(153 246 228 / 0.75) !important;
}

.tw-fill-teal-200\/80 {
  fill: rgb(153 246 228 / 0.8) !important;
}

.tw-fill-teal-200\/85 {
  fill: rgb(153 246 228 / 0.85) !important;
}

.tw-fill-teal-200\/90 {
  fill: rgb(153 246 228 / 0.9) !important;
}

.tw-fill-teal-200\/95 {
  fill: rgb(153 246 228 / 0.95) !important;
}

.tw-fill-teal-300 {
  fill: #5eead4 !important;
}

.tw-fill-teal-300\/0 {
  fill: rgb(94 234 212 / 0) !important;
}

.tw-fill-teal-300\/10 {
  fill: rgb(94 234 212 / 0.1) !important;
}

.tw-fill-teal-300\/100 {
  fill: rgb(94 234 212 / 1) !important;
}

.tw-fill-teal-300\/15 {
  fill: rgb(94 234 212 / 0.15) !important;
}

.tw-fill-teal-300\/20 {
  fill: rgb(94 234 212 / 0.2) !important;
}

.tw-fill-teal-300\/25 {
  fill: rgb(94 234 212 / 0.25) !important;
}

.tw-fill-teal-300\/30 {
  fill: rgb(94 234 212 / 0.3) !important;
}

.tw-fill-teal-300\/35 {
  fill: rgb(94 234 212 / 0.35) !important;
}

.tw-fill-teal-300\/40 {
  fill: rgb(94 234 212 / 0.4) !important;
}

.tw-fill-teal-300\/45 {
  fill: rgb(94 234 212 / 0.45) !important;
}

.tw-fill-teal-300\/5 {
  fill: rgb(94 234 212 / 0.05) !important;
}

.tw-fill-teal-300\/50 {
  fill: rgb(94 234 212 / 0.5) !important;
}

.tw-fill-teal-300\/55 {
  fill: rgb(94 234 212 / 0.55) !important;
}

.tw-fill-teal-300\/60 {
  fill: rgb(94 234 212 / 0.6) !important;
}

.tw-fill-teal-300\/65 {
  fill: rgb(94 234 212 / 0.65) !important;
}

.tw-fill-teal-300\/70 {
  fill: rgb(94 234 212 / 0.7) !important;
}

.tw-fill-teal-300\/75 {
  fill: rgb(94 234 212 / 0.75) !important;
}

.tw-fill-teal-300\/80 {
  fill: rgb(94 234 212 / 0.8) !important;
}

.tw-fill-teal-300\/85 {
  fill: rgb(94 234 212 / 0.85) !important;
}

.tw-fill-teal-300\/90 {
  fill: rgb(94 234 212 / 0.9) !important;
}

.tw-fill-teal-300\/95 {
  fill: rgb(94 234 212 / 0.95) !important;
}

.tw-fill-teal-400 {
  fill: #2dd4bf !important;
}

.tw-fill-teal-400\/0 {
  fill: rgb(45 212 191 / 0) !important;
}

.tw-fill-teal-400\/10 {
  fill: rgb(45 212 191 / 0.1) !important;
}

.tw-fill-teal-400\/100 {
  fill: rgb(45 212 191 / 1) !important;
}

.tw-fill-teal-400\/15 {
  fill: rgb(45 212 191 / 0.15) !important;
}

.tw-fill-teal-400\/20 {
  fill: rgb(45 212 191 / 0.2) !important;
}

.tw-fill-teal-400\/25 {
  fill: rgb(45 212 191 / 0.25) !important;
}

.tw-fill-teal-400\/30 {
  fill: rgb(45 212 191 / 0.3) !important;
}

.tw-fill-teal-400\/35 {
  fill: rgb(45 212 191 / 0.35) !important;
}

.tw-fill-teal-400\/40 {
  fill: rgb(45 212 191 / 0.4) !important;
}

.tw-fill-teal-400\/45 {
  fill: rgb(45 212 191 / 0.45) !important;
}

.tw-fill-teal-400\/5 {
  fill: rgb(45 212 191 / 0.05) !important;
}

.tw-fill-teal-400\/50 {
  fill: rgb(45 212 191 / 0.5) !important;
}

.tw-fill-teal-400\/55 {
  fill: rgb(45 212 191 / 0.55) !important;
}

.tw-fill-teal-400\/60 {
  fill: rgb(45 212 191 / 0.6) !important;
}

.tw-fill-teal-400\/65 {
  fill: rgb(45 212 191 / 0.65) !important;
}

.tw-fill-teal-400\/70 {
  fill: rgb(45 212 191 / 0.7) !important;
}

.tw-fill-teal-400\/75 {
  fill: rgb(45 212 191 / 0.75) !important;
}

.tw-fill-teal-400\/80 {
  fill: rgb(45 212 191 / 0.8) !important;
}

.tw-fill-teal-400\/85 {
  fill: rgb(45 212 191 / 0.85) !important;
}

.tw-fill-teal-400\/90 {
  fill: rgb(45 212 191 / 0.9) !important;
}

.tw-fill-teal-400\/95 {
  fill: rgb(45 212 191 / 0.95) !important;
}

.tw-fill-teal-50 {
  fill: #f0fdfa !important;
}

.tw-fill-teal-50\/0 {
  fill: rgb(240 253 250 / 0) !important;
}

.tw-fill-teal-50\/10 {
  fill: rgb(240 253 250 / 0.1) !important;
}

.tw-fill-teal-50\/100 {
  fill: rgb(240 253 250 / 1) !important;
}

.tw-fill-teal-50\/15 {
  fill: rgb(240 253 250 / 0.15) !important;
}

.tw-fill-teal-50\/20 {
  fill: rgb(240 253 250 / 0.2) !important;
}

.tw-fill-teal-50\/25 {
  fill: rgb(240 253 250 / 0.25) !important;
}

.tw-fill-teal-50\/30 {
  fill: rgb(240 253 250 / 0.3) !important;
}

.tw-fill-teal-50\/35 {
  fill: rgb(240 253 250 / 0.35) !important;
}

.tw-fill-teal-50\/40 {
  fill: rgb(240 253 250 / 0.4) !important;
}

.tw-fill-teal-50\/45 {
  fill: rgb(240 253 250 / 0.45) !important;
}

.tw-fill-teal-50\/5 {
  fill: rgb(240 253 250 / 0.05) !important;
}

.tw-fill-teal-50\/50 {
  fill: rgb(240 253 250 / 0.5) !important;
}

.tw-fill-teal-50\/55 {
  fill: rgb(240 253 250 / 0.55) !important;
}

.tw-fill-teal-50\/60 {
  fill: rgb(240 253 250 / 0.6) !important;
}

.tw-fill-teal-50\/65 {
  fill: rgb(240 253 250 / 0.65) !important;
}

.tw-fill-teal-50\/70 {
  fill: rgb(240 253 250 / 0.7) !important;
}

.tw-fill-teal-50\/75 {
  fill: rgb(240 253 250 / 0.75) !important;
}

.tw-fill-teal-50\/80 {
  fill: rgb(240 253 250 / 0.8) !important;
}

.tw-fill-teal-50\/85 {
  fill: rgb(240 253 250 / 0.85) !important;
}

.tw-fill-teal-50\/90 {
  fill: rgb(240 253 250 / 0.9) !important;
}

.tw-fill-teal-50\/95 {
  fill: rgb(240 253 250 / 0.95) !important;
}

.tw-fill-teal-500 {
  fill: #14b8a6 !important;
}

.tw-fill-teal-500\/0 {
  fill: rgb(20 184 166 / 0) !important;
}

.tw-fill-teal-500\/10 {
  fill: rgb(20 184 166 / 0.1) !important;
}

.tw-fill-teal-500\/100 {
  fill: rgb(20 184 166 / 1) !important;
}

.tw-fill-teal-500\/15 {
  fill: rgb(20 184 166 / 0.15) !important;
}

.tw-fill-teal-500\/20 {
  fill: rgb(20 184 166 / 0.2) !important;
}

.tw-fill-teal-500\/25 {
  fill: rgb(20 184 166 / 0.25) !important;
}

.tw-fill-teal-500\/30 {
  fill: rgb(20 184 166 / 0.3) !important;
}

.tw-fill-teal-500\/35 {
  fill: rgb(20 184 166 / 0.35) !important;
}

.tw-fill-teal-500\/40 {
  fill: rgb(20 184 166 / 0.4) !important;
}

.tw-fill-teal-500\/45 {
  fill: rgb(20 184 166 / 0.45) !important;
}

.tw-fill-teal-500\/5 {
  fill: rgb(20 184 166 / 0.05) !important;
}

.tw-fill-teal-500\/50 {
  fill: rgb(20 184 166 / 0.5) !important;
}

.tw-fill-teal-500\/55 {
  fill: rgb(20 184 166 / 0.55) !important;
}

.tw-fill-teal-500\/60 {
  fill: rgb(20 184 166 / 0.6) !important;
}

.tw-fill-teal-500\/65 {
  fill: rgb(20 184 166 / 0.65) !important;
}

.tw-fill-teal-500\/70 {
  fill: rgb(20 184 166 / 0.7) !important;
}

.tw-fill-teal-500\/75 {
  fill: rgb(20 184 166 / 0.75) !important;
}

.tw-fill-teal-500\/80 {
  fill: rgb(20 184 166 / 0.8) !important;
}

.tw-fill-teal-500\/85 {
  fill: rgb(20 184 166 / 0.85) !important;
}

.tw-fill-teal-500\/90 {
  fill: rgb(20 184 166 / 0.9) !important;
}

.tw-fill-teal-500\/95 {
  fill: rgb(20 184 166 / 0.95) !important;
}

.tw-fill-teal-600 {
  fill: #0d9488 !important;
}

.tw-fill-teal-600\/0 {
  fill: rgb(13 148 136 / 0) !important;
}

.tw-fill-teal-600\/10 {
  fill: rgb(13 148 136 / 0.1) !important;
}

.tw-fill-teal-600\/100 {
  fill: rgb(13 148 136 / 1) !important;
}

.tw-fill-teal-600\/15 {
  fill: rgb(13 148 136 / 0.15) !important;
}

.tw-fill-teal-600\/20 {
  fill: rgb(13 148 136 / 0.2) !important;
}

.tw-fill-teal-600\/25 {
  fill: rgb(13 148 136 / 0.25) !important;
}

.tw-fill-teal-600\/30 {
  fill: rgb(13 148 136 / 0.3) !important;
}

.tw-fill-teal-600\/35 {
  fill: rgb(13 148 136 / 0.35) !important;
}

.tw-fill-teal-600\/40 {
  fill: rgb(13 148 136 / 0.4) !important;
}

.tw-fill-teal-600\/45 {
  fill: rgb(13 148 136 / 0.45) !important;
}

.tw-fill-teal-600\/5 {
  fill: rgb(13 148 136 / 0.05) !important;
}

.tw-fill-teal-600\/50 {
  fill: rgb(13 148 136 / 0.5) !important;
}

.tw-fill-teal-600\/55 {
  fill: rgb(13 148 136 / 0.55) !important;
}

.tw-fill-teal-600\/60 {
  fill: rgb(13 148 136 / 0.6) !important;
}

.tw-fill-teal-600\/65 {
  fill: rgb(13 148 136 / 0.65) !important;
}

.tw-fill-teal-600\/70 {
  fill: rgb(13 148 136 / 0.7) !important;
}

.tw-fill-teal-600\/75 {
  fill: rgb(13 148 136 / 0.75) !important;
}

.tw-fill-teal-600\/80 {
  fill: rgb(13 148 136 / 0.8) !important;
}

.tw-fill-teal-600\/85 {
  fill: rgb(13 148 136 / 0.85) !important;
}

.tw-fill-teal-600\/90 {
  fill: rgb(13 148 136 / 0.9) !important;
}

.tw-fill-teal-600\/95 {
  fill: rgb(13 148 136 / 0.95) !important;
}

.tw-fill-teal-700 {
  fill: #0f766e !important;
}

.tw-fill-teal-700\/0 {
  fill: rgb(15 118 110 / 0) !important;
}

.tw-fill-teal-700\/10 {
  fill: rgb(15 118 110 / 0.1) !important;
}

.tw-fill-teal-700\/100 {
  fill: rgb(15 118 110 / 1) !important;
}

.tw-fill-teal-700\/15 {
  fill: rgb(15 118 110 / 0.15) !important;
}

.tw-fill-teal-700\/20 {
  fill: rgb(15 118 110 / 0.2) !important;
}

.tw-fill-teal-700\/25 {
  fill: rgb(15 118 110 / 0.25) !important;
}

.tw-fill-teal-700\/30 {
  fill: rgb(15 118 110 / 0.3) !important;
}

.tw-fill-teal-700\/35 {
  fill: rgb(15 118 110 / 0.35) !important;
}

.tw-fill-teal-700\/40 {
  fill: rgb(15 118 110 / 0.4) !important;
}

.tw-fill-teal-700\/45 {
  fill: rgb(15 118 110 / 0.45) !important;
}

.tw-fill-teal-700\/5 {
  fill: rgb(15 118 110 / 0.05) !important;
}

.tw-fill-teal-700\/50 {
  fill: rgb(15 118 110 / 0.5) !important;
}

.tw-fill-teal-700\/55 {
  fill: rgb(15 118 110 / 0.55) !important;
}

.tw-fill-teal-700\/60 {
  fill: rgb(15 118 110 / 0.6) !important;
}

.tw-fill-teal-700\/65 {
  fill: rgb(15 118 110 / 0.65) !important;
}

.tw-fill-teal-700\/70 {
  fill: rgb(15 118 110 / 0.7) !important;
}

.tw-fill-teal-700\/75 {
  fill: rgb(15 118 110 / 0.75) !important;
}

.tw-fill-teal-700\/80 {
  fill: rgb(15 118 110 / 0.8) !important;
}

.tw-fill-teal-700\/85 {
  fill: rgb(15 118 110 / 0.85) !important;
}

.tw-fill-teal-700\/90 {
  fill: rgb(15 118 110 / 0.9) !important;
}

.tw-fill-teal-700\/95 {
  fill: rgb(15 118 110 / 0.95) !important;
}

.tw-fill-teal-800 {
  fill: #115e59 !important;
}

.tw-fill-teal-800\/0 {
  fill: rgb(17 94 89 / 0) !important;
}

.tw-fill-teal-800\/10 {
  fill: rgb(17 94 89 / 0.1) !important;
}

.tw-fill-teal-800\/100 {
  fill: rgb(17 94 89 / 1) !important;
}

.tw-fill-teal-800\/15 {
  fill: rgb(17 94 89 / 0.15) !important;
}

.tw-fill-teal-800\/20 {
  fill: rgb(17 94 89 / 0.2) !important;
}

.tw-fill-teal-800\/25 {
  fill: rgb(17 94 89 / 0.25) !important;
}

.tw-fill-teal-800\/30 {
  fill: rgb(17 94 89 / 0.3) !important;
}

.tw-fill-teal-800\/35 {
  fill: rgb(17 94 89 / 0.35) !important;
}

.tw-fill-teal-800\/40 {
  fill: rgb(17 94 89 / 0.4) !important;
}

.tw-fill-teal-800\/45 {
  fill: rgb(17 94 89 / 0.45) !important;
}

.tw-fill-teal-800\/5 {
  fill: rgb(17 94 89 / 0.05) !important;
}

.tw-fill-teal-800\/50 {
  fill: rgb(17 94 89 / 0.5) !important;
}

.tw-fill-teal-800\/55 {
  fill: rgb(17 94 89 / 0.55) !important;
}

.tw-fill-teal-800\/60 {
  fill: rgb(17 94 89 / 0.6) !important;
}

.tw-fill-teal-800\/65 {
  fill: rgb(17 94 89 / 0.65) !important;
}

.tw-fill-teal-800\/70 {
  fill: rgb(17 94 89 / 0.7) !important;
}

.tw-fill-teal-800\/75 {
  fill: rgb(17 94 89 / 0.75) !important;
}

.tw-fill-teal-800\/80 {
  fill: rgb(17 94 89 / 0.8) !important;
}

.tw-fill-teal-800\/85 {
  fill: rgb(17 94 89 / 0.85) !important;
}

.tw-fill-teal-800\/90 {
  fill: rgb(17 94 89 / 0.9) !important;
}

.tw-fill-teal-800\/95 {
  fill: rgb(17 94 89 / 0.95) !important;
}

.tw-fill-teal-900 {
  fill: #134e4a !important;
}

.tw-fill-teal-900\/0 {
  fill: rgb(19 78 74 / 0) !important;
}

.tw-fill-teal-900\/10 {
  fill: rgb(19 78 74 / 0.1) !important;
}

.tw-fill-teal-900\/100 {
  fill: rgb(19 78 74 / 1) !important;
}

.tw-fill-teal-900\/15 {
  fill: rgb(19 78 74 / 0.15) !important;
}

.tw-fill-teal-900\/20 {
  fill: rgb(19 78 74 / 0.2) !important;
}

.tw-fill-teal-900\/25 {
  fill: rgb(19 78 74 / 0.25) !important;
}

.tw-fill-teal-900\/30 {
  fill: rgb(19 78 74 / 0.3) !important;
}

.tw-fill-teal-900\/35 {
  fill: rgb(19 78 74 / 0.35) !important;
}

.tw-fill-teal-900\/40 {
  fill: rgb(19 78 74 / 0.4) !important;
}

.tw-fill-teal-900\/45 {
  fill: rgb(19 78 74 / 0.45) !important;
}

.tw-fill-teal-900\/5 {
  fill: rgb(19 78 74 / 0.05) !important;
}

.tw-fill-teal-900\/50 {
  fill: rgb(19 78 74 / 0.5) !important;
}

.tw-fill-teal-900\/55 {
  fill: rgb(19 78 74 / 0.55) !important;
}

.tw-fill-teal-900\/60 {
  fill: rgb(19 78 74 / 0.6) !important;
}

.tw-fill-teal-900\/65 {
  fill: rgb(19 78 74 / 0.65) !important;
}

.tw-fill-teal-900\/70 {
  fill: rgb(19 78 74 / 0.7) !important;
}

.tw-fill-teal-900\/75 {
  fill: rgb(19 78 74 / 0.75) !important;
}

.tw-fill-teal-900\/80 {
  fill: rgb(19 78 74 / 0.8) !important;
}

.tw-fill-teal-900\/85 {
  fill: rgb(19 78 74 / 0.85) !important;
}

.tw-fill-teal-900\/90 {
  fill: rgb(19 78 74 / 0.9) !important;
}

.tw-fill-teal-900\/95 {
  fill: rgb(19 78 74 / 0.95) !important;
}

.tw-fill-teal-950 {
  fill: #042f2e !important;
}

.tw-fill-teal-950\/0 {
  fill: rgb(4 47 46 / 0) !important;
}

.tw-fill-teal-950\/10 {
  fill: rgb(4 47 46 / 0.1) !important;
}

.tw-fill-teal-950\/100 {
  fill: rgb(4 47 46 / 1) !important;
}

.tw-fill-teal-950\/15 {
  fill: rgb(4 47 46 / 0.15) !important;
}

.tw-fill-teal-950\/20 {
  fill: rgb(4 47 46 / 0.2) !important;
}

.tw-fill-teal-950\/25 {
  fill: rgb(4 47 46 / 0.25) !important;
}

.tw-fill-teal-950\/30 {
  fill: rgb(4 47 46 / 0.3) !important;
}

.tw-fill-teal-950\/35 {
  fill: rgb(4 47 46 / 0.35) !important;
}

.tw-fill-teal-950\/40 {
  fill: rgb(4 47 46 / 0.4) !important;
}

.tw-fill-teal-950\/45 {
  fill: rgb(4 47 46 / 0.45) !important;
}

.tw-fill-teal-950\/5 {
  fill: rgb(4 47 46 / 0.05) !important;
}

.tw-fill-teal-950\/50 {
  fill: rgb(4 47 46 / 0.5) !important;
}

.tw-fill-teal-950\/55 {
  fill: rgb(4 47 46 / 0.55) !important;
}

.tw-fill-teal-950\/60 {
  fill: rgb(4 47 46 / 0.6) !important;
}

.tw-fill-teal-950\/65 {
  fill: rgb(4 47 46 / 0.65) !important;
}

.tw-fill-teal-950\/70 {
  fill: rgb(4 47 46 / 0.7) !important;
}

.tw-fill-teal-950\/75 {
  fill: rgb(4 47 46 / 0.75) !important;
}

.tw-fill-teal-950\/80 {
  fill: rgb(4 47 46 / 0.8) !important;
}

.tw-fill-teal-950\/85 {
  fill: rgb(4 47 46 / 0.85) !important;
}

.tw-fill-teal-950\/90 {
  fill: rgb(4 47 46 / 0.9) !important;
}

.tw-fill-teal-950\/95 {
  fill: rgb(4 47 46 / 0.95) !important;
}

.tw-fill-transparent {
  fill: transparent !important;
}

.tw-fill-transparent\/0 {
  fill: rgb(0 0 0 / 0) !important;
}

.tw-fill-transparent\/10 {
  fill: rgb(0 0 0 / 0.1) !important;
}

.tw-fill-transparent\/100 {
  fill: rgb(0 0 0 / 1) !important;
}

.tw-fill-transparent\/15 {
  fill: rgb(0 0 0 / 0.15) !important;
}

.tw-fill-transparent\/20 {
  fill: rgb(0 0 0 / 0.2) !important;
}

.tw-fill-transparent\/25 {
  fill: rgb(0 0 0 / 0.25) !important;
}

.tw-fill-transparent\/30 {
  fill: rgb(0 0 0 / 0.3) !important;
}

.tw-fill-transparent\/35 {
  fill: rgb(0 0 0 / 0.35) !important;
}

.tw-fill-transparent\/40 {
  fill: rgb(0 0 0 / 0.4) !important;
}

.tw-fill-transparent\/45 {
  fill: rgb(0 0 0 / 0.45) !important;
}

.tw-fill-transparent\/5 {
  fill: rgb(0 0 0 / 0.05) !important;
}

.tw-fill-transparent\/50 {
  fill: rgb(0 0 0 / 0.5) !important;
}

.tw-fill-transparent\/55 {
  fill: rgb(0 0 0 / 0.55) !important;
}

.tw-fill-transparent\/60 {
  fill: rgb(0 0 0 / 0.6) !important;
}

.tw-fill-transparent\/65 {
  fill: rgb(0 0 0 / 0.65) !important;
}

.tw-fill-transparent\/70 {
  fill: rgb(0 0 0 / 0.7) !important;
}

.tw-fill-transparent\/75 {
  fill: rgb(0 0 0 / 0.75) !important;
}

.tw-fill-transparent\/80 {
  fill: rgb(0 0 0 / 0.8) !important;
}

.tw-fill-transparent\/85 {
  fill: rgb(0 0 0 / 0.85) !important;
}

.tw-fill-transparent\/90 {
  fill: rgb(0 0 0 / 0.9) !important;
}

.tw-fill-transparent\/95 {
  fill: rgb(0 0 0 / 0.95) !important;
}

.tw-fill-violet-100 {
  fill: #ede9fe !important;
}

.tw-fill-violet-100\/0 {
  fill: rgb(237 233 254 / 0) !important;
}

.tw-fill-violet-100\/10 {
  fill: rgb(237 233 254 / 0.1) !important;
}

.tw-fill-violet-100\/100 {
  fill: rgb(237 233 254 / 1) !important;
}

.tw-fill-violet-100\/15 {
  fill: rgb(237 233 254 / 0.15) !important;
}

.tw-fill-violet-100\/20 {
  fill: rgb(237 233 254 / 0.2) !important;
}

.tw-fill-violet-100\/25 {
  fill: rgb(237 233 254 / 0.25) !important;
}

.tw-fill-violet-100\/30 {
  fill: rgb(237 233 254 / 0.3) !important;
}

.tw-fill-violet-100\/35 {
  fill: rgb(237 233 254 / 0.35) !important;
}

.tw-fill-violet-100\/40 {
  fill: rgb(237 233 254 / 0.4) !important;
}

.tw-fill-violet-100\/45 {
  fill: rgb(237 233 254 / 0.45) !important;
}

.tw-fill-violet-100\/5 {
  fill: rgb(237 233 254 / 0.05) !important;
}

.tw-fill-violet-100\/50 {
  fill: rgb(237 233 254 / 0.5) !important;
}

.tw-fill-violet-100\/55 {
  fill: rgb(237 233 254 / 0.55) !important;
}

.tw-fill-violet-100\/60 {
  fill: rgb(237 233 254 / 0.6) !important;
}

.tw-fill-violet-100\/65 {
  fill: rgb(237 233 254 / 0.65) !important;
}

.tw-fill-violet-100\/70 {
  fill: rgb(237 233 254 / 0.7) !important;
}

.tw-fill-violet-100\/75 {
  fill: rgb(237 233 254 / 0.75) !important;
}

.tw-fill-violet-100\/80 {
  fill: rgb(237 233 254 / 0.8) !important;
}

.tw-fill-violet-100\/85 {
  fill: rgb(237 233 254 / 0.85) !important;
}

.tw-fill-violet-100\/90 {
  fill: rgb(237 233 254 / 0.9) !important;
}

.tw-fill-violet-100\/95 {
  fill: rgb(237 233 254 / 0.95) !important;
}

.tw-fill-violet-200 {
  fill: #ddd6fe !important;
}

.tw-fill-violet-200\/0 {
  fill: rgb(221 214 254 / 0) !important;
}

.tw-fill-violet-200\/10 {
  fill: rgb(221 214 254 / 0.1) !important;
}

.tw-fill-violet-200\/100 {
  fill: rgb(221 214 254 / 1) !important;
}

.tw-fill-violet-200\/15 {
  fill: rgb(221 214 254 / 0.15) !important;
}

.tw-fill-violet-200\/20 {
  fill: rgb(221 214 254 / 0.2) !important;
}

.tw-fill-violet-200\/25 {
  fill: rgb(221 214 254 / 0.25) !important;
}

.tw-fill-violet-200\/30 {
  fill: rgb(221 214 254 / 0.3) !important;
}

.tw-fill-violet-200\/35 {
  fill: rgb(221 214 254 / 0.35) !important;
}

.tw-fill-violet-200\/40 {
  fill: rgb(221 214 254 / 0.4) !important;
}

.tw-fill-violet-200\/45 {
  fill: rgb(221 214 254 / 0.45) !important;
}

.tw-fill-violet-200\/5 {
  fill: rgb(221 214 254 / 0.05) !important;
}

.tw-fill-violet-200\/50 {
  fill: rgb(221 214 254 / 0.5) !important;
}

.tw-fill-violet-200\/55 {
  fill: rgb(221 214 254 / 0.55) !important;
}

.tw-fill-violet-200\/60 {
  fill: rgb(221 214 254 / 0.6) !important;
}

.tw-fill-violet-200\/65 {
  fill: rgb(221 214 254 / 0.65) !important;
}

.tw-fill-violet-200\/70 {
  fill: rgb(221 214 254 / 0.7) !important;
}

.tw-fill-violet-200\/75 {
  fill: rgb(221 214 254 / 0.75) !important;
}

.tw-fill-violet-200\/80 {
  fill: rgb(221 214 254 / 0.8) !important;
}

.tw-fill-violet-200\/85 {
  fill: rgb(221 214 254 / 0.85) !important;
}

.tw-fill-violet-200\/90 {
  fill: rgb(221 214 254 / 0.9) !important;
}

.tw-fill-violet-200\/95 {
  fill: rgb(221 214 254 / 0.95) !important;
}

.tw-fill-violet-300 {
  fill: #c4b5fd !important;
}

.tw-fill-violet-300\/0 {
  fill: rgb(196 181 253 / 0) !important;
}

.tw-fill-violet-300\/10 {
  fill: rgb(196 181 253 / 0.1) !important;
}

.tw-fill-violet-300\/100 {
  fill: rgb(196 181 253 / 1) !important;
}

.tw-fill-violet-300\/15 {
  fill: rgb(196 181 253 / 0.15) !important;
}

.tw-fill-violet-300\/20 {
  fill: rgb(196 181 253 / 0.2) !important;
}

.tw-fill-violet-300\/25 {
  fill: rgb(196 181 253 / 0.25) !important;
}

.tw-fill-violet-300\/30 {
  fill: rgb(196 181 253 / 0.3) !important;
}

.tw-fill-violet-300\/35 {
  fill: rgb(196 181 253 / 0.35) !important;
}

.tw-fill-violet-300\/40 {
  fill: rgb(196 181 253 / 0.4) !important;
}

.tw-fill-violet-300\/45 {
  fill: rgb(196 181 253 / 0.45) !important;
}

.tw-fill-violet-300\/5 {
  fill: rgb(196 181 253 / 0.05) !important;
}

.tw-fill-violet-300\/50 {
  fill: rgb(196 181 253 / 0.5) !important;
}

.tw-fill-violet-300\/55 {
  fill: rgb(196 181 253 / 0.55) !important;
}

.tw-fill-violet-300\/60 {
  fill: rgb(196 181 253 / 0.6) !important;
}

.tw-fill-violet-300\/65 {
  fill: rgb(196 181 253 / 0.65) !important;
}

.tw-fill-violet-300\/70 {
  fill: rgb(196 181 253 / 0.7) !important;
}

.tw-fill-violet-300\/75 {
  fill: rgb(196 181 253 / 0.75) !important;
}

.tw-fill-violet-300\/80 {
  fill: rgb(196 181 253 / 0.8) !important;
}

.tw-fill-violet-300\/85 {
  fill: rgb(196 181 253 / 0.85) !important;
}

.tw-fill-violet-300\/90 {
  fill: rgb(196 181 253 / 0.9) !important;
}

.tw-fill-violet-300\/95 {
  fill: rgb(196 181 253 / 0.95) !important;
}

.tw-fill-violet-400 {
  fill: #a78bfa !important;
}

.tw-fill-violet-400\/0 {
  fill: rgb(167 139 250 / 0) !important;
}

.tw-fill-violet-400\/10 {
  fill: rgb(167 139 250 / 0.1) !important;
}

.tw-fill-violet-400\/100 {
  fill: rgb(167 139 250 / 1) !important;
}

.tw-fill-violet-400\/15 {
  fill: rgb(167 139 250 / 0.15) !important;
}

.tw-fill-violet-400\/20 {
  fill: rgb(167 139 250 / 0.2) !important;
}

.tw-fill-violet-400\/25 {
  fill: rgb(167 139 250 / 0.25) !important;
}

.tw-fill-violet-400\/30 {
  fill: rgb(167 139 250 / 0.3) !important;
}

.tw-fill-violet-400\/35 {
  fill: rgb(167 139 250 / 0.35) !important;
}

.tw-fill-violet-400\/40 {
  fill: rgb(167 139 250 / 0.4) !important;
}

.tw-fill-violet-400\/45 {
  fill: rgb(167 139 250 / 0.45) !important;
}

.tw-fill-violet-400\/5 {
  fill: rgb(167 139 250 / 0.05) !important;
}

.tw-fill-violet-400\/50 {
  fill: rgb(167 139 250 / 0.5) !important;
}

.tw-fill-violet-400\/55 {
  fill: rgb(167 139 250 / 0.55) !important;
}

.tw-fill-violet-400\/60 {
  fill: rgb(167 139 250 / 0.6) !important;
}

.tw-fill-violet-400\/65 {
  fill: rgb(167 139 250 / 0.65) !important;
}

.tw-fill-violet-400\/70 {
  fill: rgb(167 139 250 / 0.7) !important;
}

.tw-fill-violet-400\/75 {
  fill: rgb(167 139 250 / 0.75) !important;
}

.tw-fill-violet-400\/80 {
  fill: rgb(167 139 250 / 0.8) !important;
}

.tw-fill-violet-400\/85 {
  fill: rgb(167 139 250 / 0.85) !important;
}

.tw-fill-violet-400\/90 {
  fill: rgb(167 139 250 / 0.9) !important;
}

.tw-fill-violet-400\/95 {
  fill: rgb(167 139 250 / 0.95) !important;
}

.tw-fill-violet-50 {
  fill: #f5f3ff !important;
}

.tw-fill-violet-50\/0 {
  fill: rgb(245 243 255 / 0) !important;
}

.tw-fill-violet-50\/10 {
  fill: rgb(245 243 255 / 0.1) !important;
}

.tw-fill-violet-50\/100 {
  fill: rgb(245 243 255 / 1) !important;
}

.tw-fill-violet-50\/15 {
  fill: rgb(245 243 255 / 0.15) !important;
}

.tw-fill-violet-50\/20 {
  fill: rgb(245 243 255 / 0.2) !important;
}

.tw-fill-violet-50\/25 {
  fill: rgb(245 243 255 / 0.25) !important;
}

.tw-fill-violet-50\/30 {
  fill: rgb(245 243 255 / 0.3) !important;
}

.tw-fill-violet-50\/35 {
  fill: rgb(245 243 255 / 0.35) !important;
}

.tw-fill-violet-50\/40 {
  fill: rgb(245 243 255 / 0.4) !important;
}

.tw-fill-violet-50\/45 {
  fill: rgb(245 243 255 / 0.45) !important;
}

.tw-fill-violet-50\/5 {
  fill: rgb(245 243 255 / 0.05) !important;
}

.tw-fill-violet-50\/50 {
  fill: rgb(245 243 255 / 0.5) !important;
}

.tw-fill-violet-50\/55 {
  fill: rgb(245 243 255 / 0.55) !important;
}

.tw-fill-violet-50\/60 {
  fill: rgb(245 243 255 / 0.6) !important;
}

.tw-fill-violet-50\/65 {
  fill: rgb(245 243 255 / 0.65) !important;
}

.tw-fill-violet-50\/70 {
  fill: rgb(245 243 255 / 0.7) !important;
}

.tw-fill-violet-50\/75 {
  fill: rgb(245 243 255 / 0.75) !important;
}

.tw-fill-violet-50\/80 {
  fill: rgb(245 243 255 / 0.8) !important;
}

.tw-fill-violet-50\/85 {
  fill: rgb(245 243 255 / 0.85) !important;
}

.tw-fill-violet-50\/90 {
  fill: rgb(245 243 255 / 0.9) !important;
}

.tw-fill-violet-50\/95 {
  fill: rgb(245 243 255 / 0.95) !important;
}

.tw-fill-violet-500 {
  fill: #8b5cf6 !important;
}

.tw-fill-violet-500\/0 {
  fill: rgb(139 92 246 / 0) !important;
}

.tw-fill-violet-500\/10 {
  fill: rgb(139 92 246 / 0.1) !important;
}

.tw-fill-violet-500\/100 {
  fill: rgb(139 92 246 / 1) !important;
}

.tw-fill-violet-500\/15 {
  fill: rgb(139 92 246 / 0.15) !important;
}

.tw-fill-violet-500\/20 {
  fill: rgb(139 92 246 / 0.2) !important;
}

.tw-fill-violet-500\/25 {
  fill: rgb(139 92 246 / 0.25) !important;
}

.tw-fill-violet-500\/30 {
  fill: rgb(139 92 246 / 0.3) !important;
}

.tw-fill-violet-500\/35 {
  fill: rgb(139 92 246 / 0.35) !important;
}

.tw-fill-violet-500\/40 {
  fill: rgb(139 92 246 / 0.4) !important;
}

.tw-fill-violet-500\/45 {
  fill: rgb(139 92 246 / 0.45) !important;
}

.tw-fill-violet-500\/5 {
  fill: rgb(139 92 246 / 0.05) !important;
}

.tw-fill-violet-500\/50 {
  fill: rgb(139 92 246 / 0.5) !important;
}

.tw-fill-violet-500\/55 {
  fill: rgb(139 92 246 / 0.55) !important;
}

.tw-fill-violet-500\/60 {
  fill: rgb(139 92 246 / 0.6) !important;
}

.tw-fill-violet-500\/65 {
  fill: rgb(139 92 246 / 0.65) !important;
}

.tw-fill-violet-500\/70 {
  fill: rgb(139 92 246 / 0.7) !important;
}

.tw-fill-violet-500\/75 {
  fill: rgb(139 92 246 / 0.75) !important;
}

.tw-fill-violet-500\/80 {
  fill: rgb(139 92 246 / 0.8) !important;
}

.tw-fill-violet-500\/85 {
  fill: rgb(139 92 246 / 0.85) !important;
}

.tw-fill-violet-500\/90 {
  fill: rgb(139 92 246 / 0.9) !important;
}

.tw-fill-violet-500\/95 {
  fill: rgb(139 92 246 / 0.95) !important;
}

.tw-fill-violet-600 {
  fill: #7c3aed !important;
}

.tw-fill-violet-600\/0 {
  fill: rgb(124 58 237 / 0) !important;
}

.tw-fill-violet-600\/10 {
  fill: rgb(124 58 237 / 0.1) !important;
}

.tw-fill-violet-600\/100 {
  fill: rgb(124 58 237 / 1) !important;
}

.tw-fill-violet-600\/15 {
  fill: rgb(124 58 237 / 0.15) !important;
}

.tw-fill-violet-600\/20 {
  fill: rgb(124 58 237 / 0.2) !important;
}

.tw-fill-violet-600\/25 {
  fill: rgb(124 58 237 / 0.25) !important;
}

.tw-fill-violet-600\/30 {
  fill: rgb(124 58 237 / 0.3) !important;
}

.tw-fill-violet-600\/35 {
  fill: rgb(124 58 237 / 0.35) !important;
}

.tw-fill-violet-600\/40 {
  fill: rgb(124 58 237 / 0.4) !important;
}

.tw-fill-violet-600\/45 {
  fill: rgb(124 58 237 / 0.45) !important;
}

.tw-fill-violet-600\/5 {
  fill: rgb(124 58 237 / 0.05) !important;
}

.tw-fill-violet-600\/50 {
  fill: rgb(124 58 237 / 0.5) !important;
}

.tw-fill-violet-600\/55 {
  fill: rgb(124 58 237 / 0.55) !important;
}

.tw-fill-violet-600\/60 {
  fill: rgb(124 58 237 / 0.6) !important;
}

.tw-fill-violet-600\/65 {
  fill: rgb(124 58 237 / 0.65) !important;
}

.tw-fill-violet-600\/70 {
  fill: rgb(124 58 237 / 0.7) !important;
}

.tw-fill-violet-600\/75 {
  fill: rgb(124 58 237 / 0.75) !important;
}

.tw-fill-violet-600\/80 {
  fill: rgb(124 58 237 / 0.8) !important;
}

.tw-fill-violet-600\/85 {
  fill: rgb(124 58 237 / 0.85) !important;
}

.tw-fill-violet-600\/90 {
  fill: rgb(124 58 237 / 0.9) !important;
}

.tw-fill-violet-600\/95 {
  fill: rgb(124 58 237 / 0.95) !important;
}

.tw-fill-violet-700 {
  fill: #6d28d9 !important;
}

.tw-fill-violet-700\/0 {
  fill: rgb(109 40 217 / 0) !important;
}

.tw-fill-violet-700\/10 {
  fill: rgb(109 40 217 / 0.1) !important;
}

.tw-fill-violet-700\/100 {
  fill: rgb(109 40 217 / 1) !important;
}

.tw-fill-violet-700\/15 {
  fill: rgb(109 40 217 / 0.15) !important;
}

.tw-fill-violet-700\/20 {
  fill: rgb(109 40 217 / 0.2) !important;
}

.tw-fill-violet-700\/25 {
  fill: rgb(109 40 217 / 0.25) !important;
}

.tw-fill-violet-700\/30 {
  fill: rgb(109 40 217 / 0.3) !important;
}

.tw-fill-violet-700\/35 {
  fill: rgb(109 40 217 / 0.35) !important;
}

.tw-fill-violet-700\/40 {
  fill: rgb(109 40 217 / 0.4) !important;
}

.tw-fill-violet-700\/45 {
  fill: rgb(109 40 217 / 0.45) !important;
}

.tw-fill-violet-700\/5 {
  fill: rgb(109 40 217 / 0.05) !important;
}

.tw-fill-violet-700\/50 {
  fill: rgb(109 40 217 / 0.5) !important;
}

.tw-fill-violet-700\/55 {
  fill: rgb(109 40 217 / 0.55) !important;
}

.tw-fill-violet-700\/60 {
  fill: rgb(109 40 217 / 0.6) !important;
}

.tw-fill-violet-700\/65 {
  fill: rgb(109 40 217 / 0.65) !important;
}

.tw-fill-violet-700\/70 {
  fill: rgb(109 40 217 / 0.7) !important;
}

.tw-fill-violet-700\/75 {
  fill: rgb(109 40 217 / 0.75) !important;
}

.tw-fill-violet-700\/80 {
  fill: rgb(109 40 217 / 0.8) !important;
}

.tw-fill-violet-700\/85 {
  fill: rgb(109 40 217 / 0.85) !important;
}

.tw-fill-violet-700\/90 {
  fill: rgb(109 40 217 / 0.9) !important;
}

.tw-fill-violet-700\/95 {
  fill: rgb(109 40 217 / 0.95) !important;
}

.tw-fill-violet-800 {
  fill: #5b21b6 !important;
}

.tw-fill-violet-800\/0 {
  fill: rgb(91 33 182 / 0) !important;
}

.tw-fill-violet-800\/10 {
  fill: rgb(91 33 182 / 0.1) !important;
}

.tw-fill-violet-800\/100 {
  fill: rgb(91 33 182 / 1) !important;
}

.tw-fill-violet-800\/15 {
  fill: rgb(91 33 182 / 0.15) !important;
}

.tw-fill-violet-800\/20 {
  fill: rgb(91 33 182 / 0.2) !important;
}

.tw-fill-violet-800\/25 {
  fill: rgb(91 33 182 / 0.25) !important;
}

.tw-fill-violet-800\/30 {
  fill: rgb(91 33 182 / 0.3) !important;
}

.tw-fill-violet-800\/35 {
  fill: rgb(91 33 182 / 0.35) !important;
}

.tw-fill-violet-800\/40 {
  fill: rgb(91 33 182 / 0.4) !important;
}

.tw-fill-violet-800\/45 {
  fill: rgb(91 33 182 / 0.45) !important;
}

.tw-fill-violet-800\/5 {
  fill: rgb(91 33 182 / 0.05) !important;
}

.tw-fill-violet-800\/50 {
  fill: rgb(91 33 182 / 0.5) !important;
}

.tw-fill-violet-800\/55 {
  fill: rgb(91 33 182 / 0.55) !important;
}

.tw-fill-violet-800\/60 {
  fill: rgb(91 33 182 / 0.6) !important;
}

.tw-fill-violet-800\/65 {
  fill: rgb(91 33 182 / 0.65) !important;
}

.tw-fill-violet-800\/70 {
  fill: rgb(91 33 182 / 0.7) !important;
}

.tw-fill-violet-800\/75 {
  fill: rgb(91 33 182 / 0.75) !important;
}

.tw-fill-violet-800\/80 {
  fill: rgb(91 33 182 / 0.8) !important;
}

.tw-fill-violet-800\/85 {
  fill: rgb(91 33 182 / 0.85) !important;
}

.tw-fill-violet-800\/90 {
  fill: rgb(91 33 182 / 0.9) !important;
}

.tw-fill-violet-800\/95 {
  fill: rgb(91 33 182 / 0.95) !important;
}

.tw-fill-violet-900 {
  fill: #4c1d95 !important;
}

.tw-fill-violet-900\/0 {
  fill: rgb(76 29 149 / 0) !important;
}

.tw-fill-violet-900\/10 {
  fill: rgb(76 29 149 / 0.1) !important;
}

.tw-fill-violet-900\/100 {
  fill: rgb(76 29 149 / 1) !important;
}

.tw-fill-violet-900\/15 {
  fill: rgb(76 29 149 / 0.15) !important;
}

.tw-fill-violet-900\/20 {
  fill: rgb(76 29 149 / 0.2) !important;
}

.tw-fill-violet-900\/25 {
  fill: rgb(76 29 149 / 0.25) !important;
}

.tw-fill-violet-900\/30 {
  fill: rgb(76 29 149 / 0.3) !important;
}

.tw-fill-violet-900\/35 {
  fill: rgb(76 29 149 / 0.35) !important;
}

.tw-fill-violet-900\/40 {
  fill: rgb(76 29 149 / 0.4) !important;
}

.tw-fill-violet-900\/45 {
  fill: rgb(76 29 149 / 0.45) !important;
}

.tw-fill-violet-900\/5 {
  fill: rgb(76 29 149 / 0.05) !important;
}

.tw-fill-violet-900\/50 {
  fill: rgb(76 29 149 / 0.5) !important;
}

.tw-fill-violet-900\/55 {
  fill: rgb(76 29 149 / 0.55) !important;
}

.tw-fill-violet-900\/60 {
  fill: rgb(76 29 149 / 0.6) !important;
}

.tw-fill-violet-900\/65 {
  fill: rgb(76 29 149 / 0.65) !important;
}

.tw-fill-violet-900\/70 {
  fill: rgb(76 29 149 / 0.7) !important;
}

.tw-fill-violet-900\/75 {
  fill: rgb(76 29 149 / 0.75) !important;
}

.tw-fill-violet-900\/80 {
  fill: rgb(76 29 149 / 0.8) !important;
}

.tw-fill-violet-900\/85 {
  fill: rgb(76 29 149 / 0.85) !important;
}

.tw-fill-violet-900\/90 {
  fill: rgb(76 29 149 / 0.9) !important;
}

.tw-fill-violet-900\/95 {
  fill: rgb(76 29 149 / 0.95) !important;
}

.tw-fill-violet-950 {
  fill: #2e1065 !important;
}

.tw-fill-violet-950\/0 {
  fill: rgb(46 16 101 / 0) !important;
}

.tw-fill-violet-950\/10 {
  fill: rgb(46 16 101 / 0.1) !important;
}

.tw-fill-violet-950\/100 {
  fill: rgb(46 16 101 / 1) !important;
}

.tw-fill-violet-950\/15 {
  fill: rgb(46 16 101 / 0.15) !important;
}

.tw-fill-violet-950\/20 {
  fill: rgb(46 16 101 / 0.2) !important;
}

.tw-fill-violet-950\/25 {
  fill: rgb(46 16 101 / 0.25) !important;
}

.tw-fill-violet-950\/30 {
  fill: rgb(46 16 101 / 0.3) !important;
}

.tw-fill-violet-950\/35 {
  fill: rgb(46 16 101 / 0.35) !important;
}

.tw-fill-violet-950\/40 {
  fill: rgb(46 16 101 / 0.4) !important;
}

.tw-fill-violet-950\/45 {
  fill: rgb(46 16 101 / 0.45) !important;
}

.tw-fill-violet-950\/5 {
  fill: rgb(46 16 101 / 0.05) !important;
}

.tw-fill-violet-950\/50 {
  fill: rgb(46 16 101 / 0.5) !important;
}

.tw-fill-violet-950\/55 {
  fill: rgb(46 16 101 / 0.55) !important;
}

.tw-fill-violet-950\/60 {
  fill: rgb(46 16 101 / 0.6) !important;
}

.tw-fill-violet-950\/65 {
  fill: rgb(46 16 101 / 0.65) !important;
}

.tw-fill-violet-950\/70 {
  fill: rgb(46 16 101 / 0.7) !important;
}

.tw-fill-violet-950\/75 {
  fill: rgb(46 16 101 / 0.75) !important;
}

.tw-fill-violet-950\/80 {
  fill: rgb(46 16 101 / 0.8) !important;
}

.tw-fill-violet-950\/85 {
  fill: rgb(46 16 101 / 0.85) !important;
}

.tw-fill-violet-950\/90 {
  fill: rgb(46 16 101 / 0.9) !important;
}

.tw-fill-violet-950\/95 {
  fill: rgb(46 16 101 / 0.95) !important;
}

.tw-fill-white {
  fill: #fff !important;
}

.tw-fill-white\/0 {
  fill: rgb(255 255 255 / 0) !important;
}

.tw-fill-white\/10 {
  fill: rgb(255 255 255 / 0.1) !important;
}

.tw-fill-white\/100 {
  fill: rgb(255 255 255 / 1) !important;
}

.tw-fill-white\/15 {
  fill: rgb(255 255 255 / 0.15) !important;
}

.tw-fill-white\/20 {
  fill: rgb(255 255 255 / 0.2) !important;
}

.tw-fill-white\/25 {
  fill: rgb(255 255 255 / 0.25) !important;
}

.tw-fill-white\/30 {
  fill: rgb(255 255 255 / 0.3) !important;
}

.tw-fill-white\/35 {
  fill: rgb(255 255 255 / 0.35) !important;
}

.tw-fill-white\/40 {
  fill: rgb(255 255 255 / 0.4) !important;
}

.tw-fill-white\/45 {
  fill: rgb(255 255 255 / 0.45) !important;
}

.tw-fill-white\/5 {
  fill: rgb(255 255 255 / 0.05) !important;
}

.tw-fill-white\/50 {
  fill: rgb(255 255 255 / 0.5) !important;
}

.tw-fill-white\/55 {
  fill: rgb(255 255 255 / 0.55) !important;
}

.tw-fill-white\/60 {
  fill: rgb(255 255 255 / 0.6) !important;
}

.tw-fill-white\/65 {
  fill: rgb(255 255 255 / 0.65) !important;
}

.tw-fill-white\/70 {
  fill: rgb(255 255 255 / 0.7) !important;
}

.tw-fill-white\/75 {
  fill: rgb(255 255 255 / 0.75) !important;
}

.tw-fill-white\/80 {
  fill: rgb(255 255 255 / 0.8) !important;
}

.tw-fill-white\/85 {
  fill: rgb(255 255 255 / 0.85) !important;
}

.tw-fill-white\/90 {
  fill: rgb(255 255 255 / 0.9) !important;
}

.tw-fill-white\/95 {
  fill: rgb(255 255 255 / 0.95) !important;
}

.tw-fill-yellow-100 {
  fill: #fef9c3 !important;
}

.tw-fill-yellow-100\/0 {
  fill: rgb(254 249 195 / 0) !important;
}

.tw-fill-yellow-100\/10 {
  fill: rgb(254 249 195 / 0.1) !important;
}

.tw-fill-yellow-100\/100 {
  fill: rgb(254 249 195 / 1) !important;
}

.tw-fill-yellow-100\/15 {
  fill: rgb(254 249 195 / 0.15) !important;
}

.tw-fill-yellow-100\/20 {
  fill: rgb(254 249 195 / 0.2) !important;
}

.tw-fill-yellow-100\/25 {
  fill: rgb(254 249 195 / 0.25) !important;
}

.tw-fill-yellow-100\/30 {
  fill: rgb(254 249 195 / 0.3) !important;
}

.tw-fill-yellow-100\/35 {
  fill: rgb(254 249 195 / 0.35) !important;
}

.tw-fill-yellow-100\/40 {
  fill: rgb(254 249 195 / 0.4) !important;
}

.tw-fill-yellow-100\/45 {
  fill: rgb(254 249 195 / 0.45) !important;
}

.tw-fill-yellow-100\/5 {
  fill: rgb(254 249 195 / 0.05) !important;
}

.tw-fill-yellow-100\/50 {
  fill: rgb(254 249 195 / 0.5) !important;
}

.tw-fill-yellow-100\/55 {
  fill: rgb(254 249 195 / 0.55) !important;
}

.tw-fill-yellow-100\/60 {
  fill: rgb(254 249 195 / 0.6) !important;
}

.tw-fill-yellow-100\/65 {
  fill: rgb(254 249 195 / 0.65) !important;
}

.tw-fill-yellow-100\/70 {
  fill: rgb(254 249 195 / 0.7) !important;
}

.tw-fill-yellow-100\/75 {
  fill: rgb(254 249 195 / 0.75) !important;
}

.tw-fill-yellow-100\/80 {
  fill: rgb(254 249 195 / 0.8) !important;
}

.tw-fill-yellow-100\/85 {
  fill: rgb(254 249 195 / 0.85) !important;
}

.tw-fill-yellow-100\/90 {
  fill: rgb(254 249 195 / 0.9) !important;
}

.tw-fill-yellow-100\/95 {
  fill: rgb(254 249 195 / 0.95) !important;
}

.tw-fill-yellow-200 {
  fill: #fef08a !important;
}

.tw-fill-yellow-200\/0 {
  fill: rgb(254 240 138 / 0) !important;
}

.tw-fill-yellow-200\/10 {
  fill: rgb(254 240 138 / 0.1) !important;
}

.tw-fill-yellow-200\/100 {
  fill: rgb(254 240 138 / 1) !important;
}

.tw-fill-yellow-200\/15 {
  fill: rgb(254 240 138 / 0.15) !important;
}

.tw-fill-yellow-200\/20 {
  fill: rgb(254 240 138 / 0.2) !important;
}

.tw-fill-yellow-200\/25 {
  fill: rgb(254 240 138 / 0.25) !important;
}

.tw-fill-yellow-200\/30 {
  fill: rgb(254 240 138 / 0.3) !important;
}

.tw-fill-yellow-200\/35 {
  fill: rgb(254 240 138 / 0.35) !important;
}

.tw-fill-yellow-200\/40 {
  fill: rgb(254 240 138 / 0.4) !important;
}

.tw-fill-yellow-200\/45 {
  fill: rgb(254 240 138 / 0.45) !important;
}

.tw-fill-yellow-200\/5 {
  fill: rgb(254 240 138 / 0.05) !important;
}

.tw-fill-yellow-200\/50 {
  fill: rgb(254 240 138 / 0.5) !important;
}

.tw-fill-yellow-200\/55 {
  fill: rgb(254 240 138 / 0.55) !important;
}

.tw-fill-yellow-200\/60 {
  fill: rgb(254 240 138 / 0.6) !important;
}

.tw-fill-yellow-200\/65 {
  fill: rgb(254 240 138 / 0.65) !important;
}

.tw-fill-yellow-200\/70 {
  fill: rgb(254 240 138 / 0.7) !important;
}

.tw-fill-yellow-200\/75 {
  fill: rgb(254 240 138 / 0.75) !important;
}

.tw-fill-yellow-200\/80 {
  fill: rgb(254 240 138 / 0.8) !important;
}

.tw-fill-yellow-200\/85 {
  fill: rgb(254 240 138 / 0.85) !important;
}

.tw-fill-yellow-200\/90 {
  fill: rgb(254 240 138 / 0.9) !important;
}

.tw-fill-yellow-200\/95 {
  fill: rgb(254 240 138 / 0.95) !important;
}

.tw-fill-yellow-300 {
  fill: #fde047 !important;
}

.tw-fill-yellow-300\/0 {
  fill: rgb(253 224 71 / 0) !important;
}

.tw-fill-yellow-300\/10 {
  fill: rgb(253 224 71 / 0.1) !important;
}

.tw-fill-yellow-300\/100 {
  fill: rgb(253 224 71 / 1) !important;
}

.tw-fill-yellow-300\/15 {
  fill: rgb(253 224 71 / 0.15) !important;
}

.tw-fill-yellow-300\/20 {
  fill: rgb(253 224 71 / 0.2) !important;
}

.tw-fill-yellow-300\/25 {
  fill: rgb(253 224 71 / 0.25) !important;
}

.tw-fill-yellow-300\/30 {
  fill: rgb(253 224 71 / 0.3) !important;
}

.tw-fill-yellow-300\/35 {
  fill: rgb(253 224 71 / 0.35) !important;
}

.tw-fill-yellow-300\/40 {
  fill: rgb(253 224 71 / 0.4) !important;
}

.tw-fill-yellow-300\/45 {
  fill: rgb(253 224 71 / 0.45) !important;
}

.tw-fill-yellow-300\/5 {
  fill: rgb(253 224 71 / 0.05) !important;
}

.tw-fill-yellow-300\/50 {
  fill: rgb(253 224 71 / 0.5) !important;
}

.tw-fill-yellow-300\/55 {
  fill: rgb(253 224 71 / 0.55) !important;
}

.tw-fill-yellow-300\/60 {
  fill: rgb(253 224 71 / 0.6) !important;
}

.tw-fill-yellow-300\/65 {
  fill: rgb(253 224 71 / 0.65) !important;
}

.tw-fill-yellow-300\/70 {
  fill: rgb(253 224 71 / 0.7) !important;
}

.tw-fill-yellow-300\/75 {
  fill: rgb(253 224 71 / 0.75) !important;
}

.tw-fill-yellow-300\/80 {
  fill: rgb(253 224 71 / 0.8) !important;
}

.tw-fill-yellow-300\/85 {
  fill: rgb(253 224 71 / 0.85) !important;
}

.tw-fill-yellow-300\/90 {
  fill: rgb(253 224 71 / 0.9) !important;
}

.tw-fill-yellow-300\/95 {
  fill: rgb(253 224 71 / 0.95) !important;
}

.tw-fill-yellow-400 {
  fill: #facc15 !important;
}

.tw-fill-yellow-400\/0 {
  fill: rgb(250 204 21 / 0) !important;
}

.tw-fill-yellow-400\/10 {
  fill: rgb(250 204 21 / 0.1) !important;
}

.tw-fill-yellow-400\/100 {
  fill: rgb(250 204 21 / 1) !important;
}

.tw-fill-yellow-400\/15 {
  fill: rgb(250 204 21 / 0.15) !important;
}

.tw-fill-yellow-400\/20 {
  fill: rgb(250 204 21 / 0.2) !important;
}

.tw-fill-yellow-400\/25 {
  fill: rgb(250 204 21 / 0.25) !important;
}

.tw-fill-yellow-400\/30 {
  fill: rgb(250 204 21 / 0.3) !important;
}

.tw-fill-yellow-400\/35 {
  fill: rgb(250 204 21 / 0.35) !important;
}

.tw-fill-yellow-400\/40 {
  fill: rgb(250 204 21 / 0.4) !important;
}

.tw-fill-yellow-400\/45 {
  fill: rgb(250 204 21 / 0.45) !important;
}

.tw-fill-yellow-400\/5 {
  fill: rgb(250 204 21 / 0.05) !important;
}

.tw-fill-yellow-400\/50 {
  fill: rgb(250 204 21 / 0.5) !important;
}

.tw-fill-yellow-400\/55 {
  fill: rgb(250 204 21 / 0.55) !important;
}

.tw-fill-yellow-400\/60 {
  fill: rgb(250 204 21 / 0.6) !important;
}

.tw-fill-yellow-400\/65 {
  fill: rgb(250 204 21 / 0.65) !important;
}

.tw-fill-yellow-400\/70 {
  fill: rgb(250 204 21 / 0.7) !important;
}

.tw-fill-yellow-400\/75 {
  fill: rgb(250 204 21 / 0.75) !important;
}

.tw-fill-yellow-400\/80 {
  fill: rgb(250 204 21 / 0.8) !important;
}

.tw-fill-yellow-400\/85 {
  fill: rgb(250 204 21 / 0.85) !important;
}

.tw-fill-yellow-400\/90 {
  fill: rgb(250 204 21 / 0.9) !important;
}

.tw-fill-yellow-400\/95 {
  fill: rgb(250 204 21 / 0.95) !important;
}

.tw-fill-yellow-50 {
  fill: #fefce8 !important;
}

.tw-fill-yellow-50\/0 {
  fill: rgb(254 252 232 / 0) !important;
}

.tw-fill-yellow-50\/10 {
  fill: rgb(254 252 232 / 0.1) !important;
}

.tw-fill-yellow-50\/100 {
  fill: rgb(254 252 232 / 1) !important;
}

.tw-fill-yellow-50\/15 {
  fill: rgb(254 252 232 / 0.15) !important;
}

.tw-fill-yellow-50\/20 {
  fill: rgb(254 252 232 / 0.2) !important;
}

.tw-fill-yellow-50\/25 {
  fill: rgb(254 252 232 / 0.25) !important;
}

.tw-fill-yellow-50\/30 {
  fill: rgb(254 252 232 / 0.3) !important;
}

.tw-fill-yellow-50\/35 {
  fill: rgb(254 252 232 / 0.35) !important;
}

.tw-fill-yellow-50\/40 {
  fill: rgb(254 252 232 / 0.4) !important;
}

.tw-fill-yellow-50\/45 {
  fill: rgb(254 252 232 / 0.45) !important;
}

.tw-fill-yellow-50\/5 {
  fill: rgb(254 252 232 / 0.05) !important;
}

.tw-fill-yellow-50\/50 {
  fill: rgb(254 252 232 / 0.5) !important;
}

.tw-fill-yellow-50\/55 {
  fill: rgb(254 252 232 / 0.55) !important;
}

.tw-fill-yellow-50\/60 {
  fill: rgb(254 252 232 / 0.6) !important;
}

.tw-fill-yellow-50\/65 {
  fill: rgb(254 252 232 / 0.65) !important;
}

.tw-fill-yellow-50\/70 {
  fill: rgb(254 252 232 / 0.7) !important;
}

.tw-fill-yellow-50\/75 {
  fill: rgb(254 252 232 / 0.75) !important;
}

.tw-fill-yellow-50\/80 {
  fill: rgb(254 252 232 / 0.8) !important;
}

.tw-fill-yellow-50\/85 {
  fill: rgb(254 252 232 / 0.85) !important;
}

.tw-fill-yellow-50\/90 {
  fill: rgb(254 252 232 / 0.9) !important;
}

.tw-fill-yellow-50\/95 {
  fill: rgb(254 252 232 / 0.95) !important;
}

.tw-fill-yellow-500 {
  fill: #eab308 !important;
}

.tw-fill-yellow-500\/0 {
  fill: rgb(234 179 8 / 0) !important;
}

.tw-fill-yellow-500\/10 {
  fill: rgb(234 179 8 / 0.1) !important;
}

.tw-fill-yellow-500\/100 {
  fill: rgb(234 179 8 / 1) !important;
}

.tw-fill-yellow-500\/15 {
  fill: rgb(234 179 8 / 0.15) !important;
}

.tw-fill-yellow-500\/20 {
  fill: rgb(234 179 8 / 0.2) !important;
}

.tw-fill-yellow-500\/25 {
  fill: rgb(234 179 8 / 0.25) !important;
}

.tw-fill-yellow-500\/30 {
  fill: rgb(234 179 8 / 0.3) !important;
}

.tw-fill-yellow-500\/35 {
  fill: rgb(234 179 8 / 0.35) !important;
}

.tw-fill-yellow-500\/40 {
  fill: rgb(234 179 8 / 0.4) !important;
}

.tw-fill-yellow-500\/45 {
  fill: rgb(234 179 8 / 0.45) !important;
}

.tw-fill-yellow-500\/5 {
  fill: rgb(234 179 8 / 0.05) !important;
}

.tw-fill-yellow-500\/50 {
  fill: rgb(234 179 8 / 0.5) !important;
}

.tw-fill-yellow-500\/55 {
  fill: rgb(234 179 8 / 0.55) !important;
}

.tw-fill-yellow-500\/60 {
  fill: rgb(234 179 8 / 0.6) !important;
}

.tw-fill-yellow-500\/65 {
  fill: rgb(234 179 8 / 0.65) !important;
}

.tw-fill-yellow-500\/70 {
  fill: rgb(234 179 8 / 0.7) !important;
}

.tw-fill-yellow-500\/75 {
  fill: rgb(234 179 8 / 0.75) !important;
}

.tw-fill-yellow-500\/80 {
  fill: rgb(234 179 8 / 0.8) !important;
}

.tw-fill-yellow-500\/85 {
  fill: rgb(234 179 8 / 0.85) !important;
}

.tw-fill-yellow-500\/90 {
  fill: rgb(234 179 8 / 0.9) !important;
}

.tw-fill-yellow-500\/95 {
  fill: rgb(234 179 8 / 0.95) !important;
}

.tw-fill-yellow-600 {
  fill: #ca8a04 !important;
}

.tw-fill-yellow-600\/0 {
  fill: rgb(202 138 4 / 0) !important;
}

.tw-fill-yellow-600\/10 {
  fill: rgb(202 138 4 / 0.1) !important;
}

.tw-fill-yellow-600\/100 {
  fill: rgb(202 138 4 / 1) !important;
}

.tw-fill-yellow-600\/15 {
  fill: rgb(202 138 4 / 0.15) !important;
}

.tw-fill-yellow-600\/20 {
  fill: rgb(202 138 4 / 0.2) !important;
}

.tw-fill-yellow-600\/25 {
  fill: rgb(202 138 4 / 0.25) !important;
}

.tw-fill-yellow-600\/30 {
  fill: rgb(202 138 4 / 0.3) !important;
}

.tw-fill-yellow-600\/35 {
  fill: rgb(202 138 4 / 0.35) !important;
}

.tw-fill-yellow-600\/40 {
  fill: rgb(202 138 4 / 0.4) !important;
}

.tw-fill-yellow-600\/45 {
  fill: rgb(202 138 4 / 0.45) !important;
}

.tw-fill-yellow-600\/5 {
  fill: rgb(202 138 4 / 0.05) !important;
}

.tw-fill-yellow-600\/50 {
  fill: rgb(202 138 4 / 0.5) !important;
}

.tw-fill-yellow-600\/55 {
  fill: rgb(202 138 4 / 0.55) !important;
}

.tw-fill-yellow-600\/60 {
  fill: rgb(202 138 4 / 0.6) !important;
}

.tw-fill-yellow-600\/65 {
  fill: rgb(202 138 4 / 0.65) !important;
}

.tw-fill-yellow-600\/70 {
  fill: rgb(202 138 4 / 0.7) !important;
}

.tw-fill-yellow-600\/75 {
  fill: rgb(202 138 4 / 0.75) !important;
}

.tw-fill-yellow-600\/80 {
  fill: rgb(202 138 4 / 0.8) !important;
}

.tw-fill-yellow-600\/85 {
  fill: rgb(202 138 4 / 0.85) !important;
}

.tw-fill-yellow-600\/90 {
  fill: rgb(202 138 4 / 0.9) !important;
}

.tw-fill-yellow-600\/95 {
  fill: rgb(202 138 4 / 0.95) !important;
}

.tw-fill-yellow-700 {
  fill: #a16207 !important;
}

.tw-fill-yellow-700\/0 {
  fill: rgb(161 98 7 / 0) !important;
}

.tw-fill-yellow-700\/10 {
  fill: rgb(161 98 7 / 0.1) !important;
}

.tw-fill-yellow-700\/100 {
  fill: rgb(161 98 7 / 1) !important;
}

.tw-fill-yellow-700\/15 {
  fill: rgb(161 98 7 / 0.15) !important;
}

.tw-fill-yellow-700\/20 {
  fill: rgb(161 98 7 / 0.2) !important;
}

.tw-fill-yellow-700\/25 {
  fill: rgb(161 98 7 / 0.25) !important;
}

.tw-fill-yellow-700\/30 {
  fill: rgb(161 98 7 / 0.3) !important;
}

.tw-fill-yellow-700\/35 {
  fill: rgb(161 98 7 / 0.35) !important;
}

.tw-fill-yellow-700\/40 {
  fill: rgb(161 98 7 / 0.4) !important;
}

.tw-fill-yellow-700\/45 {
  fill: rgb(161 98 7 / 0.45) !important;
}

.tw-fill-yellow-700\/5 {
  fill: rgb(161 98 7 / 0.05) !important;
}

.tw-fill-yellow-700\/50 {
  fill: rgb(161 98 7 / 0.5) !important;
}

.tw-fill-yellow-700\/55 {
  fill: rgb(161 98 7 / 0.55) !important;
}

.tw-fill-yellow-700\/60 {
  fill: rgb(161 98 7 / 0.6) !important;
}

.tw-fill-yellow-700\/65 {
  fill: rgb(161 98 7 / 0.65) !important;
}

.tw-fill-yellow-700\/70 {
  fill: rgb(161 98 7 / 0.7) !important;
}

.tw-fill-yellow-700\/75 {
  fill: rgb(161 98 7 / 0.75) !important;
}

.tw-fill-yellow-700\/80 {
  fill: rgb(161 98 7 / 0.8) !important;
}

.tw-fill-yellow-700\/85 {
  fill: rgb(161 98 7 / 0.85) !important;
}

.tw-fill-yellow-700\/90 {
  fill: rgb(161 98 7 / 0.9) !important;
}

.tw-fill-yellow-700\/95 {
  fill: rgb(161 98 7 / 0.95) !important;
}

.tw-fill-yellow-800 {
  fill: #854d0e !important;
}

.tw-fill-yellow-800\/0 {
  fill: rgb(133 77 14 / 0) !important;
}

.tw-fill-yellow-800\/10 {
  fill: rgb(133 77 14 / 0.1) !important;
}

.tw-fill-yellow-800\/100 {
  fill: rgb(133 77 14 / 1) !important;
}

.tw-fill-yellow-800\/15 {
  fill: rgb(133 77 14 / 0.15) !important;
}

.tw-fill-yellow-800\/20 {
  fill: rgb(133 77 14 / 0.2) !important;
}

.tw-fill-yellow-800\/25 {
  fill: rgb(133 77 14 / 0.25) !important;
}

.tw-fill-yellow-800\/30 {
  fill: rgb(133 77 14 / 0.3) !important;
}

.tw-fill-yellow-800\/35 {
  fill: rgb(133 77 14 / 0.35) !important;
}

.tw-fill-yellow-800\/40 {
  fill: rgb(133 77 14 / 0.4) !important;
}

.tw-fill-yellow-800\/45 {
  fill: rgb(133 77 14 / 0.45) !important;
}

.tw-fill-yellow-800\/5 {
  fill: rgb(133 77 14 / 0.05) !important;
}

.tw-fill-yellow-800\/50 {
  fill: rgb(133 77 14 / 0.5) !important;
}

.tw-fill-yellow-800\/55 {
  fill: rgb(133 77 14 / 0.55) !important;
}

.tw-fill-yellow-800\/60 {
  fill: rgb(133 77 14 / 0.6) !important;
}

.tw-fill-yellow-800\/65 {
  fill: rgb(133 77 14 / 0.65) !important;
}

.tw-fill-yellow-800\/70 {
  fill: rgb(133 77 14 / 0.7) !important;
}

.tw-fill-yellow-800\/75 {
  fill: rgb(133 77 14 / 0.75) !important;
}

.tw-fill-yellow-800\/80 {
  fill: rgb(133 77 14 / 0.8) !important;
}

.tw-fill-yellow-800\/85 {
  fill: rgb(133 77 14 / 0.85) !important;
}

.tw-fill-yellow-800\/90 {
  fill: rgb(133 77 14 / 0.9) !important;
}

.tw-fill-yellow-800\/95 {
  fill: rgb(133 77 14 / 0.95) !important;
}

.tw-fill-yellow-900 {
  fill: #713f12 !important;
}

.tw-fill-yellow-900\/0 {
  fill: rgb(113 63 18 / 0) !important;
}

.tw-fill-yellow-900\/10 {
  fill: rgb(113 63 18 / 0.1) !important;
}

.tw-fill-yellow-900\/100 {
  fill: rgb(113 63 18 / 1) !important;
}

.tw-fill-yellow-900\/15 {
  fill: rgb(113 63 18 / 0.15) !important;
}

.tw-fill-yellow-900\/20 {
  fill: rgb(113 63 18 / 0.2) !important;
}

.tw-fill-yellow-900\/25 {
  fill: rgb(113 63 18 / 0.25) !important;
}

.tw-fill-yellow-900\/30 {
  fill: rgb(113 63 18 / 0.3) !important;
}

.tw-fill-yellow-900\/35 {
  fill: rgb(113 63 18 / 0.35) !important;
}

.tw-fill-yellow-900\/40 {
  fill: rgb(113 63 18 / 0.4) !important;
}

.tw-fill-yellow-900\/45 {
  fill: rgb(113 63 18 / 0.45) !important;
}

.tw-fill-yellow-900\/5 {
  fill: rgb(113 63 18 / 0.05) !important;
}

.tw-fill-yellow-900\/50 {
  fill: rgb(113 63 18 / 0.5) !important;
}

.tw-fill-yellow-900\/55 {
  fill: rgb(113 63 18 / 0.55) !important;
}

.tw-fill-yellow-900\/60 {
  fill: rgb(113 63 18 / 0.6) !important;
}

.tw-fill-yellow-900\/65 {
  fill: rgb(113 63 18 / 0.65) !important;
}

.tw-fill-yellow-900\/70 {
  fill: rgb(113 63 18 / 0.7) !important;
}

.tw-fill-yellow-900\/75 {
  fill: rgb(113 63 18 / 0.75) !important;
}

.tw-fill-yellow-900\/80 {
  fill: rgb(113 63 18 / 0.8) !important;
}

.tw-fill-yellow-900\/85 {
  fill: rgb(113 63 18 / 0.85) !important;
}

.tw-fill-yellow-900\/90 {
  fill: rgb(113 63 18 / 0.9) !important;
}

.tw-fill-yellow-900\/95 {
  fill: rgb(113 63 18 / 0.95) !important;
}

.tw-fill-yellow-950 {
  fill: #422006 !important;
}

.tw-fill-yellow-950\/0 {
  fill: rgb(66 32 6 / 0) !important;
}

.tw-fill-yellow-950\/10 {
  fill: rgb(66 32 6 / 0.1) !important;
}

.tw-fill-yellow-950\/100 {
  fill: rgb(66 32 6 / 1) !important;
}

.tw-fill-yellow-950\/15 {
  fill: rgb(66 32 6 / 0.15) !important;
}

.tw-fill-yellow-950\/20 {
  fill: rgb(66 32 6 / 0.2) !important;
}

.tw-fill-yellow-950\/25 {
  fill: rgb(66 32 6 / 0.25) !important;
}

.tw-fill-yellow-950\/30 {
  fill: rgb(66 32 6 / 0.3) !important;
}

.tw-fill-yellow-950\/35 {
  fill: rgb(66 32 6 / 0.35) !important;
}

.tw-fill-yellow-950\/40 {
  fill: rgb(66 32 6 / 0.4) !important;
}

.tw-fill-yellow-950\/45 {
  fill: rgb(66 32 6 / 0.45) !important;
}

.tw-fill-yellow-950\/5 {
  fill: rgb(66 32 6 / 0.05) !important;
}

.tw-fill-yellow-950\/50 {
  fill: rgb(66 32 6 / 0.5) !important;
}

.tw-fill-yellow-950\/55 {
  fill: rgb(66 32 6 / 0.55) !important;
}

.tw-fill-yellow-950\/60 {
  fill: rgb(66 32 6 / 0.6) !important;
}

.tw-fill-yellow-950\/65 {
  fill: rgb(66 32 6 / 0.65) !important;
}

.tw-fill-yellow-950\/70 {
  fill: rgb(66 32 6 / 0.7) !important;
}

.tw-fill-yellow-950\/75 {
  fill: rgb(66 32 6 / 0.75) !important;
}

.tw-fill-yellow-950\/80 {
  fill: rgb(66 32 6 / 0.8) !important;
}

.tw-fill-yellow-950\/85 {
  fill: rgb(66 32 6 / 0.85) !important;
}

.tw-fill-yellow-950\/90 {
  fill: rgb(66 32 6 / 0.9) !important;
}

.tw-fill-yellow-950\/95 {
  fill: rgb(66 32 6 / 0.95) !important;
}

.tw-fill-zinc-100 {
  fill: #f4f4f5 !important;
}

.tw-fill-zinc-100\/0 {
  fill: rgb(244 244 245 / 0) !important;
}

.tw-fill-zinc-100\/10 {
  fill: rgb(244 244 245 / 0.1) !important;
}

.tw-fill-zinc-100\/100 {
  fill: rgb(244 244 245 / 1) !important;
}

.tw-fill-zinc-100\/15 {
  fill: rgb(244 244 245 / 0.15) !important;
}

.tw-fill-zinc-100\/20 {
  fill: rgb(244 244 245 / 0.2) !important;
}

.tw-fill-zinc-100\/25 {
  fill: rgb(244 244 245 / 0.25) !important;
}

.tw-fill-zinc-100\/30 {
  fill: rgb(244 244 245 / 0.3) !important;
}

.tw-fill-zinc-100\/35 {
  fill: rgb(244 244 245 / 0.35) !important;
}

.tw-fill-zinc-100\/40 {
  fill: rgb(244 244 245 / 0.4) !important;
}

.tw-fill-zinc-100\/45 {
  fill: rgb(244 244 245 / 0.45) !important;
}

.tw-fill-zinc-100\/5 {
  fill: rgb(244 244 245 / 0.05) !important;
}

.tw-fill-zinc-100\/50 {
  fill: rgb(244 244 245 / 0.5) !important;
}

.tw-fill-zinc-100\/55 {
  fill: rgb(244 244 245 / 0.55) !important;
}

.tw-fill-zinc-100\/60 {
  fill: rgb(244 244 245 / 0.6) !important;
}

.tw-fill-zinc-100\/65 {
  fill: rgb(244 244 245 / 0.65) !important;
}

.tw-fill-zinc-100\/70 {
  fill: rgb(244 244 245 / 0.7) !important;
}

.tw-fill-zinc-100\/75 {
  fill: rgb(244 244 245 / 0.75) !important;
}

.tw-fill-zinc-100\/80 {
  fill: rgb(244 244 245 / 0.8) !important;
}

.tw-fill-zinc-100\/85 {
  fill: rgb(244 244 245 / 0.85) !important;
}

.tw-fill-zinc-100\/90 {
  fill: rgb(244 244 245 / 0.9) !important;
}

.tw-fill-zinc-100\/95 {
  fill: rgb(244 244 245 / 0.95) !important;
}

.tw-fill-zinc-200 {
  fill: #e4e4e7 !important;
}

.tw-fill-zinc-200\/0 {
  fill: rgb(228 228 231 / 0) !important;
}

.tw-fill-zinc-200\/10 {
  fill: rgb(228 228 231 / 0.1) !important;
}

.tw-fill-zinc-200\/100 {
  fill: rgb(228 228 231 / 1) !important;
}

.tw-fill-zinc-200\/15 {
  fill: rgb(228 228 231 / 0.15) !important;
}

.tw-fill-zinc-200\/20 {
  fill: rgb(228 228 231 / 0.2) !important;
}

.tw-fill-zinc-200\/25 {
  fill: rgb(228 228 231 / 0.25) !important;
}

.tw-fill-zinc-200\/30 {
  fill: rgb(228 228 231 / 0.3) !important;
}

.tw-fill-zinc-200\/35 {
  fill: rgb(228 228 231 / 0.35) !important;
}

.tw-fill-zinc-200\/40 {
  fill: rgb(228 228 231 / 0.4) !important;
}

.tw-fill-zinc-200\/45 {
  fill: rgb(228 228 231 / 0.45) !important;
}

.tw-fill-zinc-200\/5 {
  fill: rgb(228 228 231 / 0.05) !important;
}

.tw-fill-zinc-200\/50 {
  fill: rgb(228 228 231 / 0.5) !important;
}

.tw-fill-zinc-200\/55 {
  fill: rgb(228 228 231 / 0.55) !important;
}

.tw-fill-zinc-200\/60 {
  fill: rgb(228 228 231 / 0.6) !important;
}

.tw-fill-zinc-200\/65 {
  fill: rgb(228 228 231 / 0.65) !important;
}

.tw-fill-zinc-200\/70 {
  fill: rgb(228 228 231 / 0.7) !important;
}

.tw-fill-zinc-200\/75 {
  fill: rgb(228 228 231 / 0.75) !important;
}

.tw-fill-zinc-200\/80 {
  fill: rgb(228 228 231 / 0.8) !important;
}

.tw-fill-zinc-200\/85 {
  fill: rgb(228 228 231 / 0.85) !important;
}

.tw-fill-zinc-200\/90 {
  fill: rgb(228 228 231 / 0.9) !important;
}

.tw-fill-zinc-200\/95 {
  fill: rgb(228 228 231 / 0.95) !important;
}

.tw-fill-zinc-300 {
  fill: #d4d4d8 !important;
}

.tw-fill-zinc-300\/0 {
  fill: rgb(212 212 216 / 0) !important;
}

.tw-fill-zinc-300\/10 {
  fill: rgb(212 212 216 / 0.1) !important;
}

.tw-fill-zinc-300\/100 {
  fill: rgb(212 212 216 / 1) !important;
}

.tw-fill-zinc-300\/15 {
  fill: rgb(212 212 216 / 0.15) !important;
}

.tw-fill-zinc-300\/20 {
  fill: rgb(212 212 216 / 0.2) !important;
}

.tw-fill-zinc-300\/25 {
  fill: rgb(212 212 216 / 0.25) !important;
}

.tw-fill-zinc-300\/30 {
  fill: rgb(212 212 216 / 0.3) !important;
}

.tw-fill-zinc-300\/35 {
  fill: rgb(212 212 216 / 0.35) !important;
}

.tw-fill-zinc-300\/40 {
  fill: rgb(212 212 216 / 0.4) !important;
}

.tw-fill-zinc-300\/45 {
  fill: rgb(212 212 216 / 0.45) !important;
}

.tw-fill-zinc-300\/5 {
  fill: rgb(212 212 216 / 0.05) !important;
}

.tw-fill-zinc-300\/50 {
  fill: rgb(212 212 216 / 0.5) !important;
}

.tw-fill-zinc-300\/55 {
  fill: rgb(212 212 216 / 0.55) !important;
}

.tw-fill-zinc-300\/60 {
  fill: rgb(212 212 216 / 0.6) !important;
}

.tw-fill-zinc-300\/65 {
  fill: rgb(212 212 216 / 0.65) !important;
}

.tw-fill-zinc-300\/70 {
  fill: rgb(212 212 216 / 0.7) !important;
}

.tw-fill-zinc-300\/75 {
  fill: rgb(212 212 216 / 0.75) !important;
}

.tw-fill-zinc-300\/80 {
  fill: rgb(212 212 216 / 0.8) !important;
}

.tw-fill-zinc-300\/85 {
  fill: rgb(212 212 216 / 0.85) !important;
}

.tw-fill-zinc-300\/90 {
  fill: rgb(212 212 216 / 0.9) !important;
}

.tw-fill-zinc-300\/95 {
  fill: rgb(212 212 216 / 0.95) !important;
}

.tw-fill-zinc-400 {
  fill: #a1a1aa !important;
}

.tw-fill-zinc-400\/0 {
  fill: rgb(161 161 170 / 0) !important;
}

.tw-fill-zinc-400\/10 {
  fill: rgb(161 161 170 / 0.1) !important;
}

.tw-fill-zinc-400\/100 {
  fill: rgb(161 161 170 / 1) !important;
}

.tw-fill-zinc-400\/15 {
  fill: rgb(161 161 170 / 0.15) !important;
}

.tw-fill-zinc-400\/20 {
  fill: rgb(161 161 170 / 0.2) !important;
}

.tw-fill-zinc-400\/25 {
  fill: rgb(161 161 170 / 0.25) !important;
}

.tw-fill-zinc-400\/30 {
  fill: rgb(161 161 170 / 0.3) !important;
}

.tw-fill-zinc-400\/35 {
  fill: rgb(161 161 170 / 0.35) !important;
}

.tw-fill-zinc-400\/40 {
  fill: rgb(161 161 170 / 0.4) !important;
}

.tw-fill-zinc-400\/45 {
  fill: rgb(161 161 170 / 0.45) !important;
}

.tw-fill-zinc-400\/5 {
  fill: rgb(161 161 170 / 0.05) !important;
}

.tw-fill-zinc-400\/50 {
  fill: rgb(161 161 170 / 0.5) !important;
}

.tw-fill-zinc-400\/55 {
  fill: rgb(161 161 170 / 0.55) !important;
}

.tw-fill-zinc-400\/60 {
  fill: rgb(161 161 170 / 0.6) !important;
}

.tw-fill-zinc-400\/65 {
  fill: rgb(161 161 170 / 0.65) !important;
}

.tw-fill-zinc-400\/70 {
  fill: rgb(161 161 170 / 0.7) !important;
}

.tw-fill-zinc-400\/75 {
  fill: rgb(161 161 170 / 0.75) !important;
}

.tw-fill-zinc-400\/80 {
  fill: rgb(161 161 170 / 0.8) !important;
}

.tw-fill-zinc-400\/85 {
  fill: rgb(161 161 170 / 0.85) !important;
}

.tw-fill-zinc-400\/90 {
  fill: rgb(161 161 170 / 0.9) !important;
}

.tw-fill-zinc-400\/95 {
  fill: rgb(161 161 170 / 0.95) !important;
}

.tw-fill-zinc-50 {
  fill: #fafafa !important;
}

.tw-fill-zinc-50\/0 {
  fill: rgb(250 250 250 / 0) !important;
}

.tw-fill-zinc-50\/10 {
  fill: rgb(250 250 250 / 0.1) !important;
}

.tw-fill-zinc-50\/100 {
  fill: rgb(250 250 250 / 1) !important;
}

.tw-fill-zinc-50\/15 {
  fill: rgb(250 250 250 / 0.15) !important;
}

.tw-fill-zinc-50\/20 {
  fill: rgb(250 250 250 / 0.2) !important;
}

.tw-fill-zinc-50\/25 {
  fill: rgb(250 250 250 / 0.25) !important;
}

.tw-fill-zinc-50\/30 {
  fill: rgb(250 250 250 / 0.3) !important;
}

.tw-fill-zinc-50\/35 {
  fill: rgb(250 250 250 / 0.35) !important;
}

.tw-fill-zinc-50\/40 {
  fill: rgb(250 250 250 / 0.4) !important;
}

.tw-fill-zinc-50\/45 {
  fill: rgb(250 250 250 / 0.45) !important;
}

.tw-fill-zinc-50\/5 {
  fill: rgb(250 250 250 / 0.05) !important;
}

.tw-fill-zinc-50\/50 {
  fill: rgb(250 250 250 / 0.5) !important;
}

.tw-fill-zinc-50\/55 {
  fill: rgb(250 250 250 / 0.55) !important;
}

.tw-fill-zinc-50\/60 {
  fill: rgb(250 250 250 / 0.6) !important;
}

.tw-fill-zinc-50\/65 {
  fill: rgb(250 250 250 / 0.65) !important;
}

.tw-fill-zinc-50\/70 {
  fill: rgb(250 250 250 / 0.7) !important;
}

.tw-fill-zinc-50\/75 {
  fill: rgb(250 250 250 / 0.75) !important;
}

.tw-fill-zinc-50\/80 {
  fill: rgb(250 250 250 / 0.8) !important;
}

.tw-fill-zinc-50\/85 {
  fill: rgb(250 250 250 / 0.85) !important;
}

.tw-fill-zinc-50\/90 {
  fill: rgb(250 250 250 / 0.9) !important;
}

.tw-fill-zinc-50\/95 {
  fill: rgb(250 250 250 / 0.95) !important;
}

.tw-fill-zinc-500 {
  fill: #71717a !important;
}

.tw-fill-zinc-500\/0 {
  fill: rgb(113 113 122 / 0) !important;
}

.tw-fill-zinc-500\/10 {
  fill: rgb(113 113 122 / 0.1) !important;
}

.tw-fill-zinc-500\/100 {
  fill: rgb(113 113 122 / 1) !important;
}

.tw-fill-zinc-500\/15 {
  fill: rgb(113 113 122 / 0.15) !important;
}

.tw-fill-zinc-500\/20 {
  fill: rgb(113 113 122 / 0.2) !important;
}

.tw-fill-zinc-500\/25 {
  fill: rgb(113 113 122 / 0.25) !important;
}

.tw-fill-zinc-500\/30 {
  fill: rgb(113 113 122 / 0.3) !important;
}

.tw-fill-zinc-500\/35 {
  fill: rgb(113 113 122 / 0.35) !important;
}

.tw-fill-zinc-500\/40 {
  fill: rgb(113 113 122 / 0.4) !important;
}

.tw-fill-zinc-500\/45 {
  fill: rgb(113 113 122 / 0.45) !important;
}

.tw-fill-zinc-500\/5 {
  fill: rgb(113 113 122 / 0.05) !important;
}

.tw-fill-zinc-500\/50 {
  fill: rgb(113 113 122 / 0.5) !important;
}

.tw-fill-zinc-500\/55 {
  fill: rgb(113 113 122 / 0.55) !important;
}

.tw-fill-zinc-500\/60 {
  fill: rgb(113 113 122 / 0.6) !important;
}

.tw-fill-zinc-500\/65 {
  fill: rgb(113 113 122 / 0.65) !important;
}

.tw-fill-zinc-500\/70 {
  fill: rgb(113 113 122 / 0.7) !important;
}

.tw-fill-zinc-500\/75 {
  fill: rgb(113 113 122 / 0.75) !important;
}

.tw-fill-zinc-500\/80 {
  fill: rgb(113 113 122 / 0.8) !important;
}

.tw-fill-zinc-500\/85 {
  fill: rgb(113 113 122 / 0.85) !important;
}

.tw-fill-zinc-500\/90 {
  fill: rgb(113 113 122 / 0.9) !important;
}

.tw-fill-zinc-500\/95 {
  fill: rgb(113 113 122 / 0.95) !important;
}

.tw-fill-zinc-600 {
  fill: #52525b !important;
}

.tw-fill-zinc-600\/0 {
  fill: rgb(82 82 91 / 0) !important;
}

.tw-fill-zinc-600\/10 {
  fill: rgb(82 82 91 / 0.1) !important;
}

.tw-fill-zinc-600\/100 {
  fill: rgb(82 82 91 / 1) !important;
}

.tw-fill-zinc-600\/15 {
  fill: rgb(82 82 91 / 0.15) !important;
}

.tw-fill-zinc-600\/20 {
  fill: rgb(82 82 91 / 0.2) !important;
}

.tw-fill-zinc-600\/25 {
  fill: rgb(82 82 91 / 0.25) !important;
}

.tw-fill-zinc-600\/30 {
  fill: rgb(82 82 91 / 0.3) !important;
}

.tw-fill-zinc-600\/35 {
  fill: rgb(82 82 91 / 0.35) !important;
}

.tw-fill-zinc-600\/40 {
  fill: rgb(82 82 91 / 0.4) !important;
}

.tw-fill-zinc-600\/45 {
  fill: rgb(82 82 91 / 0.45) !important;
}

.tw-fill-zinc-600\/5 {
  fill: rgb(82 82 91 / 0.05) !important;
}

.tw-fill-zinc-600\/50 {
  fill: rgb(82 82 91 / 0.5) !important;
}

.tw-fill-zinc-600\/55 {
  fill: rgb(82 82 91 / 0.55) !important;
}

.tw-fill-zinc-600\/60 {
  fill: rgb(82 82 91 / 0.6) !important;
}

.tw-fill-zinc-600\/65 {
  fill: rgb(82 82 91 / 0.65) !important;
}

.tw-fill-zinc-600\/70 {
  fill: rgb(82 82 91 / 0.7) !important;
}

.tw-fill-zinc-600\/75 {
  fill: rgb(82 82 91 / 0.75) !important;
}

.tw-fill-zinc-600\/80 {
  fill: rgb(82 82 91 / 0.8) !important;
}

.tw-fill-zinc-600\/85 {
  fill: rgb(82 82 91 / 0.85) !important;
}

.tw-fill-zinc-600\/90 {
  fill: rgb(82 82 91 / 0.9) !important;
}

.tw-fill-zinc-600\/95 {
  fill: rgb(82 82 91 / 0.95) !important;
}

.tw-fill-zinc-700 {
  fill: #3f3f46 !important;
}

.tw-fill-zinc-700\/0 {
  fill: rgb(63 63 70 / 0) !important;
}

.tw-fill-zinc-700\/10 {
  fill: rgb(63 63 70 / 0.1) !important;
}

.tw-fill-zinc-700\/100 {
  fill: rgb(63 63 70 / 1) !important;
}

.tw-fill-zinc-700\/15 {
  fill: rgb(63 63 70 / 0.15) !important;
}

.tw-fill-zinc-700\/20 {
  fill: rgb(63 63 70 / 0.2) !important;
}

.tw-fill-zinc-700\/25 {
  fill: rgb(63 63 70 / 0.25) !important;
}

.tw-fill-zinc-700\/30 {
  fill: rgb(63 63 70 / 0.3) !important;
}

.tw-fill-zinc-700\/35 {
  fill: rgb(63 63 70 / 0.35) !important;
}

.tw-fill-zinc-700\/40 {
  fill: rgb(63 63 70 / 0.4) !important;
}

.tw-fill-zinc-700\/45 {
  fill: rgb(63 63 70 / 0.45) !important;
}

.tw-fill-zinc-700\/5 {
  fill: rgb(63 63 70 / 0.05) !important;
}

.tw-fill-zinc-700\/50 {
  fill: rgb(63 63 70 / 0.5) !important;
}

.tw-fill-zinc-700\/55 {
  fill: rgb(63 63 70 / 0.55) !important;
}

.tw-fill-zinc-700\/60 {
  fill: rgb(63 63 70 / 0.6) !important;
}

.tw-fill-zinc-700\/65 {
  fill: rgb(63 63 70 / 0.65) !important;
}

.tw-fill-zinc-700\/70 {
  fill: rgb(63 63 70 / 0.7) !important;
}

.tw-fill-zinc-700\/75 {
  fill: rgb(63 63 70 / 0.75) !important;
}

.tw-fill-zinc-700\/80 {
  fill: rgb(63 63 70 / 0.8) !important;
}

.tw-fill-zinc-700\/85 {
  fill: rgb(63 63 70 / 0.85) !important;
}

.tw-fill-zinc-700\/90 {
  fill: rgb(63 63 70 / 0.9) !important;
}

.tw-fill-zinc-700\/95 {
  fill: rgb(63 63 70 / 0.95) !important;
}

.tw-fill-zinc-800 {
  fill: #27272a !important;
}

.tw-fill-zinc-800\/0 {
  fill: rgb(39 39 42 / 0) !important;
}

.tw-fill-zinc-800\/10 {
  fill: rgb(39 39 42 / 0.1) !important;
}

.tw-fill-zinc-800\/100 {
  fill: rgb(39 39 42 / 1) !important;
}

.tw-fill-zinc-800\/15 {
  fill: rgb(39 39 42 / 0.15) !important;
}

.tw-fill-zinc-800\/20 {
  fill: rgb(39 39 42 / 0.2) !important;
}

.tw-fill-zinc-800\/25 {
  fill: rgb(39 39 42 / 0.25) !important;
}

.tw-fill-zinc-800\/30 {
  fill: rgb(39 39 42 / 0.3) !important;
}

.tw-fill-zinc-800\/35 {
  fill: rgb(39 39 42 / 0.35) !important;
}

.tw-fill-zinc-800\/40 {
  fill: rgb(39 39 42 / 0.4) !important;
}

.tw-fill-zinc-800\/45 {
  fill: rgb(39 39 42 / 0.45) !important;
}

.tw-fill-zinc-800\/5 {
  fill: rgb(39 39 42 / 0.05) !important;
}

.tw-fill-zinc-800\/50 {
  fill: rgb(39 39 42 / 0.5) !important;
}

.tw-fill-zinc-800\/55 {
  fill: rgb(39 39 42 / 0.55) !important;
}

.tw-fill-zinc-800\/60 {
  fill: rgb(39 39 42 / 0.6) !important;
}

.tw-fill-zinc-800\/65 {
  fill: rgb(39 39 42 / 0.65) !important;
}

.tw-fill-zinc-800\/70 {
  fill: rgb(39 39 42 / 0.7) !important;
}

.tw-fill-zinc-800\/75 {
  fill: rgb(39 39 42 / 0.75) !important;
}

.tw-fill-zinc-800\/80 {
  fill: rgb(39 39 42 / 0.8) !important;
}

.tw-fill-zinc-800\/85 {
  fill: rgb(39 39 42 / 0.85) !important;
}

.tw-fill-zinc-800\/90 {
  fill: rgb(39 39 42 / 0.9) !important;
}

.tw-fill-zinc-800\/95 {
  fill: rgb(39 39 42 / 0.95) !important;
}

.tw-fill-zinc-900 {
  fill: #18181b !important;
}

.tw-fill-zinc-900\/0 {
  fill: rgb(24 24 27 / 0) !important;
}

.tw-fill-zinc-900\/10 {
  fill: rgb(24 24 27 / 0.1) !important;
}

.tw-fill-zinc-900\/100 {
  fill: rgb(24 24 27 / 1) !important;
}

.tw-fill-zinc-900\/15 {
  fill: rgb(24 24 27 / 0.15) !important;
}

.tw-fill-zinc-900\/20 {
  fill: rgb(24 24 27 / 0.2) !important;
}

.tw-fill-zinc-900\/25 {
  fill: rgb(24 24 27 / 0.25) !important;
}

.tw-fill-zinc-900\/30 {
  fill: rgb(24 24 27 / 0.3) !important;
}

.tw-fill-zinc-900\/35 {
  fill: rgb(24 24 27 / 0.35) !important;
}

.tw-fill-zinc-900\/40 {
  fill: rgb(24 24 27 / 0.4) !important;
}

.tw-fill-zinc-900\/45 {
  fill: rgb(24 24 27 / 0.45) !important;
}

.tw-fill-zinc-900\/5 {
  fill: rgb(24 24 27 / 0.05) !important;
}

.tw-fill-zinc-900\/50 {
  fill: rgb(24 24 27 / 0.5) !important;
}

.tw-fill-zinc-900\/55 {
  fill: rgb(24 24 27 / 0.55) !important;
}

.tw-fill-zinc-900\/60 {
  fill: rgb(24 24 27 / 0.6) !important;
}

.tw-fill-zinc-900\/65 {
  fill: rgb(24 24 27 / 0.65) !important;
}

.tw-fill-zinc-900\/70 {
  fill: rgb(24 24 27 / 0.7) !important;
}

.tw-fill-zinc-900\/75 {
  fill: rgb(24 24 27 / 0.75) !important;
}

.tw-fill-zinc-900\/80 {
  fill: rgb(24 24 27 / 0.8) !important;
}

.tw-fill-zinc-900\/85 {
  fill: rgb(24 24 27 / 0.85) !important;
}

.tw-fill-zinc-900\/90 {
  fill: rgb(24 24 27 / 0.9) !important;
}

.tw-fill-zinc-900\/95 {
  fill: rgb(24 24 27 / 0.95) !important;
}

.tw-fill-zinc-950 {
  fill: #09090b !important;
}

.tw-fill-zinc-950\/0 {
  fill: rgb(9 9 11 / 0) !important;
}

.tw-fill-zinc-950\/10 {
  fill: rgb(9 9 11 / 0.1) !important;
}

.tw-fill-zinc-950\/100 {
  fill: rgb(9 9 11 / 1) !important;
}

.tw-fill-zinc-950\/15 {
  fill: rgb(9 9 11 / 0.15) !important;
}

.tw-fill-zinc-950\/20 {
  fill: rgb(9 9 11 / 0.2) !important;
}

.tw-fill-zinc-950\/25 {
  fill: rgb(9 9 11 / 0.25) !important;
}

.tw-fill-zinc-950\/30 {
  fill: rgb(9 9 11 / 0.3) !important;
}

.tw-fill-zinc-950\/35 {
  fill: rgb(9 9 11 / 0.35) !important;
}

.tw-fill-zinc-950\/40 {
  fill: rgb(9 9 11 / 0.4) !important;
}

.tw-fill-zinc-950\/45 {
  fill: rgb(9 9 11 / 0.45) !important;
}

.tw-fill-zinc-950\/5 {
  fill: rgb(9 9 11 / 0.05) !important;
}

.tw-fill-zinc-950\/50 {
  fill: rgb(9 9 11 / 0.5) !important;
}

.tw-fill-zinc-950\/55 {
  fill: rgb(9 9 11 / 0.55) !important;
}

.tw-fill-zinc-950\/60 {
  fill: rgb(9 9 11 / 0.6) !important;
}

.tw-fill-zinc-950\/65 {
  fill: rgb(9 9 11 / 0.65) !important;
}

.tw-fill-zinc-950\/70 {
  fill: rgb(9 9 11 / 0.7) !important;
}

.tw-fill-zinc-950\/75 {
  fill: rgb(9 9 11 / 0.75) !important;
}

.tw-fill-zinc-950\/80 {
  fill: rgb(9 9 11 / 0.8) !important;
}

.tw-fill-zinc-950\/85 {
  fill: rgb(9 9 11 / 0.85) !important;
}

.tw-fill-zinc-950\/90 {
  fill: rgb(9 9 11 / 0.9) !important;
}

.tw-fill-zinc-950\/95 {
  fill: rgb(9 9 11 / 0.95) !important;
}

.tw-stroke-amber-100 {
  stroke: #fef3c7 !important;
}

.tw-stroke-amber-100\/0 {
  stroke: rgb(254 243 199 / 0) !important;
}

.tw-stroke-amber-100\/10 {
  stroke: rgb(254 243 199 / 0.1) !important;
}

.tw-stroke-amber-100\/100 {
  stroke: rgb(254 243 199 / 1) !important;
}

.tw-stroke-amber-100\/15 {
  stroke: rgb(254 243 199 / 0.15) !important;
}

.tw-stroke-amber-100\/20 {
  stroke: rgb(254 243 199 / 0.2) !important;
}

.tw-stroke-amber-100\/25 {
  stroke: rgb(254 243 199 / 0.25) !important;
}

.tw-stroke-amber-100\/30 {
  stroke: rgb(254 243 199 / 0.3) !important;
}

.tw-stroke-amber-100\/35 {
  stroke: rgb(254 243 199 / 0.35) !important;
}

.tw-stroke-amber-100\/40 {
  stroke: rgb(254 243 199 / 0.4) !important;
}

.tw-stroke-amber-100\/45 {
  stroke: rgb(254 243 199 / 0.45) !important;
}

.tw-stroke-amber-100\/5 {
  stroke: rgb(254 243 199 / 0.05) !important;
}

.tw-stroke-amber-100\/50 {
  stroke: rgb(254 243 199 / 0.5) !important;
}

.tw-stroke-amber-100\/55 {
  stroke: rgb(254 243 199 / 0.55) !important;
}

.tw-stroke-amber-100\/60 {
  stroke: rgb(254 243 199 / 0.6) !important;
}

.tw-stroke-amber-100\/65 {
  stroke: rgb(254 243 199 / 0.65) !important;
}

.tw-stroke-amber-100\/70 {
  stroke: rgb(254 243 199 / 0.7) !important;
}

.tw-stroke-amber-100\/75 {
  stroke: rgb(254 243 199 / 0.75) !important;
}

.tw-stroke-amber-100\/80 {
  stroke: rgb(254 243 199 / 0.8) !important;
}

.tw-stroke-amber-100\/85 {
  stroke: rgb(254 243 199 / 0.85) !important;
}

.tw-stroke-amber-100\/90 {
  stroke: rgb(254 243 199 / 0.9) !important;
}

.tw-stroke-amber-100\/95 {
  stroke: rgb(254 243 199 / 0.95) !important;
}

.tw-stroke-amber-200 {
  stroke: #fde68a !important;
}

.tw-stroke-amber-200\/0 {
  stroke: rgb(253 230 138 / 0) !important;
}

.tw-stroke-amber-200\/10 {
  stroke: rgb(253 230 138 / 0.1) !important;
}

.tw-stroke-amber-200\/100 {
  stroke: rgb(253 230 138 / 1) !important;
}

.tw-stroke-amber-200\/15 {
  stroke: rgb(253 230 138 / 0.15) !important;
}

.tw-stroke-amber-200\/20 {
  stroke: rgb(253 230 138 / 0.2) !important;
}

.tw-stroke-amber-200\/25 {
  stroke: rgb(253 230 138 / 0.25) !important;
}

.tw-stroke-amber-200\/30 {
  stroke: rgb(253 230 138 / 0.3) !important;
}

.tw-stroke-amber-200\/35 {
  stroke: rgb(253 230 138 / 0.35) !important;
}

.tw-stroke-amber-200\/40 {
  stroke: rgb(253 230 138 / 0.4) !important;
}

.tw-stroke-amber-200\/45 {
  stroke: rgb(253 230 138 / 0.45) !important;
}

.tw-stroke-amber-200\/5 {
  stroke: rgb(253 230 138 / 0.05) !important;
}

.tw-stroke-amber-200\/50 {
  stroke: rgb(253 230 138 / 0.5) !important;
}

.tw-stroke-amber-200\/55 {
  stroke: rgb(253 230 138 / 0.55) !important;
}

.tw-stroke-amber-200\/60 {
  stroke: rgb(253 230 138 / 0.6) !important;
}

.tw-stroke-amber-200\/65 {
  stroke: rgb(253 230 138 / 0.65) !important;
}

.tw-stroke-amber-200\/70 {
  stroke: rgb(253 230 138 / 0.7) !important;
}

.tw-stroke-amber-200\/75 {
  stroke: rgb(253 230 138 / 0.75) !important;
}

.tw-stroke-amber-200\/80 {
  stroke: rgb(253 230 138 / 0.8) !important;
}

.tw-stroke-amber-200\/85 {
  stroke: rgb(253 230 138 / 0.85) !important;
}

.tw-stroke-amber-200\/90 {
  stroke: rgb(253 230 138 / 0.9) !important;
}

.tw-stroke-amber-200\/95 {
  stroke: rgb(253 230 138 / 0.95) !important;
}

.tw-stroke-amber-300 {
  stroke: #fcd34d !important;
}

.tw-stroke-amber-300\/0 {
  stroke: rgb(252 211 77 / 0) !important;
}

.tw-stroke-amber-300\/10 {
  stroke: rgb(252 211 77 / 0.1) !important;
}

.tw-stroke-amber-300\/100 {
  stroke: rgb(252 211 77 / 1) !important;
}

.tw-stroke-amber-300\/15 {
  stroke: rgb(252 211 77 / 0.15) !important;
}

.tw-stroke-amber-300\/20 {
  stroke: rgb(252 211 77 / 0.2) !important;
}

.tw-stroke-amber-300\/25 {
  stroke: rgb(252 211 77 / 0.25) !important;
}

.tw-stroke-amber-300\/30 {
  stroke: rgb(252 211 77 / 0.3) !important;
}

.tw-stroke-amber-300\/35 {
  stroke: rgb(252 211 77 / 0.35) !important;
}

.tw-stroke-amber-300\/40 {
  stroke: rgb(252 211 77 / 0.4) !important;
}

.tw-stroke-amber-300\/45 {
  stroke: rgb(252 211 77 / 0.45) !important;
}

.tw-stroke-amber-300\/5 {
  stroke: rgb(252 211 77 / 0.05) !important;
}

.tw-stroke-amber-300\/50 {
  stroke: rgb(252 211 77 / 0.5) !important;
}

.tw-stroke-amber-300\/55 {
  stroke: rgb(252 211 77 / 0.55) !important;
}

.tw-stroke-amber-300\/60 {
  stroke: rgb(252 211 77 / 0.6) !important;
}

.tw-stroke-amber-300\/65 {
  stroke: rgb(252 211 77 / 0.65) !important;
}

.tw-stroke-amber-300\/70 {
  stroke: rgb(252 211 77 / 0.7) !important;
}

.tw-stroke-amber-300\/75 {
  stroke: rgb(252 211 77 / 0.75) !important;
}

.tw-stroke-amber-300\/80 {
  stroke: rgb(252 211 77 / 0.8) !important;
}

.tw-stroke-amber-300\/85 {
  stroke: rgb(252 211 77 / 0.85) !important;
}

.tw-stroke-amber-300\/90 {
  stroke: rgb(252 211 77 / 0.9) !important;
}

.tw-stroke-amber-300\/95 {
  stroke: rgb(252 211 77 / 0.95) !important;
}

.tw-stroke-amber-400 {
  stroke: #fbbf24 !important;
}

.tw-stroke-amber-400\/0 {
  stroke: rgb(251 191 36 / 0) !important;
}

.tw-stroke-amber-400\/10 {
  stroke: rgb(251 191 36 / 0.1) !important;
}

.tw-stroke-amber-400\/100 {
  stroke: rgb(251 191 36 / 1) !important;
}

.tw-stroke-amber-400\/15 {
  stroke: rgb(251 191 36 / 0.15) !important;
}

.tw-stroke-amber-400\/20 {
  stroke: rgb(251 191 36 / 0.2) !important;
}

.tw-stroke-amber-400\/25 {
  stroke: rgb(251 191 36 / 0.25) !important;
}

.tw-stroke-amber-400\/30 {
  stroke: rgb(251 191 36 / 0.3) !important;
}

.tw-stroke-amber-400\/35 {
  stroke: rgb(251 191 36 / 0.35) !important;
}

.tw-stroke-amber-400\/40 {
  stroke: rgb(251 191 36 / 0.4) !important;
}

.tw-stroke-amber-400\/45 {
  stroke: rgb(251 191 36 / 0.45) !important;
}

.tw-stroke-amber-400\/5 {
  stroke: rgb(251 191 36 / 0.05) !important;
}

.tw-stroke-amber-400\/50 {
  stroke: rgb(251 191 36 / 0.5) !important;
}

.tw-stroke-amber-400\/55 {
  stroke: rgb(251 191 36 / 0.55) !important;
}

.tw-stroke-amber-400\/60 {
  stroke: rgb(251 191 36 / 0.6) !important;
}

.tw-stroke-amber-400\/65 {
  stroke: rgb(251 191 36 / 0.65) !important;
}

.tw-stroke-amber-400\/70 {
  stroke: rgb(251 191 36 / 0.7) !important;
}

.tw-stroke-amber-400\/75 {
  stroke: rgb(251 191 36 / 0.75) !important;
}

.tw-stroke-amber-400\/80 {
  stroke: rgb(251 191 36 / 0.8) !important;
}

.tw-stroke-amber-400\/85 {
  stroke: rgb(251 191 36 / 0.85) !important;
}

.tw-stroke-amber-400\/90 {
  stroke: rgb(251 191 36 / 0.9) !important;
}

.tw-stroke-amber-400\/95 {
  stroke: rgb(251 191 36 / 0.95) !important;
}

.tw-stroke-amber-50 {
  stroke: #fffbeb !important;
}

.tw-stroke-amber-50\/0 {
  stroke: rgb(255 251 235 / 0) !important;
}

.tw-stroke-amber-50\/10 {
  stroke: rgb(255 251 235 / 0.1) !important;
}

.tw-stroke-amber-50\/100 {
  stroke: rgb(255 251 235 / 1) !important;
}

.tw-stroke-amber-50\/15 {
  stroke: rgb(255 251 235 / 0.15) !important;
}

.tw-stroke-amber-50\/20 {
  stroke: rgb(255 251 235 / 0.2) !important;
}

.tw-stroke-amber-50\/25 {
  stroke: rgb(255 251 235 / 0.25) !important;
}

.tw-stroke-amber-50\/30 {
  stroke: rgb(255 251 235 / 0.3) !important;
}

.tw-stroke-amber-50\/35 {
  stroke: rgb(255 251 235 / 0.35) !important;
}

.tw-stroke-amber-50\/40 {
  stroke: rgb(255 251 235 / 0.4) !important;
}

.tw-stroke-amber-50\/45 {
  stroke: rgb(255 251 235 / 0.45) !important;
}

.tw-stroke-amber-50\/5 {
  stroke: rgb(255 251 235 / 0.05) !important;
}

.tw-stroke-amber-50\/50 {
  stroke: rgb(255 251 235 / 0.5) !important;
}

.tw-stroke-amber-50\/55 {
  stroke: rgb(255 251 235 / 0.55) !important;
}

.tw-stroke-amber-50\/60 {
  stroke: rgb(255 251 235 / 0.6) !important;
}

.tw-stroke-amber-50\/65 {
  stroke: rgb(255 251 235 / 0.65) !important;
}

.tw-stroke-amber-50\/70 {
  stroke: rgb(255 251 235 / 0.7) !important;
}

.tw-stroke-amber-50\/75 {
  stroke: rgb(255 251 235 / 0.75) !important;
}

.tw-stroke-amber-50\/80 {
  stroke: rgb(255 251 235 / 0.8) !important;
}

.tw-stroke-amber-50\/85 {
  stroke: rgb(255 251 235 / 0.85) !important;
}

.tw-stroke-amber-50\/90 {
  stroke: rgb(255 251 235 / 0.9) !important;
}

.tw-stroke-amber-50\/95 {
  stroke: rgb(255 251 235 / 0.95) !important;
}

.tw-stroke-amber-500 {
  stroke: #f59e0b !important;
}

.tw-stroke-amber-500\/0 {
  stroke: rgb(245 158 11 / 0) !important;
}

.tw-stroke-amber-500\/10 {
  stroke: rgb(245 158 11 / 0.1) !important;
}

.tw-stroke-amber-500\/100 {
  stroke: rgb(245 158 11 / 1) !important;
}

.tw-stroke-amber-500\/15 {
  stroke: rgb(245 158 11 / 0.15) !important;
}

.tw-stroke-amber-500\/20 {
  stroke: rgb(245 158 11 / 0.2) !important;
}

.tw-stroke-amber-500\/25 {
  stroke: rgb(245 158 11 / 0.25) !important;
}

.tw-stroke-amber-500\/30 {
  stroke: rgb(245 158 11 / 0.3) !important;
}

.tw-stroke-amber-500\/35 {
  stroke: rgb(245 158 11 / 0.35) !important;
}

.tw-stroke-amber-500\/40 {
  stroke: rgb(245 158 11 / 0.4) !important;
}

.tw-stroke-amber-500\/45 {
  stroke: rgb(245 158 11 / 0.45) !important;
}

.tw-stroke-amber-500\/5 {
  stroke: rgb(245 158 11 / 0.05) !important;
}

.tw-stroke-amber-500\/50 {
  stroke: rgb(245 158 11 / 0.5) !important;
}

.tw-stroke-amber-500\/55 {
  stroke: rgb(245 158 11 / 0.55) !important;
}

.tw-stroke-amber-500\/60 {
  stroke: rgb(245 158 11 / 0.6) !important;
}

.tw-stroke-amber-500\/65 {
  stroke: rgb(245 158 11 / 0.65) !important;
}

.tw-stroke-amber-500\/70 {
  stroke: rgb(245 158 11 / 0.7) !important;
}

.tw-stroke-amber-500\/75 {
  stroke: rgb(245 158 11 / 0.75) !important;
}

.tw-stroke-amber-500\/80 {
  stroke: rgb(245 158 11 / 0.8) !important;
}

.tw-stroke-amber-500\/85 {
  stroke: rgb(245 158 11 / 0.85) !important;
}

.tw-stroke-amber-500\/90 {
  stroke: rgb(245 158 11 / 0.9) !important;
}

.tw-stroke-amber-500\/95 {
  stroke: rgb(245 158 11 / 0.95) !important;
}

.tw-stroke-amber-600 {
  stroke: #d97706 !important;
}

.tw-stroke-amber-600\/0 {
  stroke: rgb(217 119 6 / 0) !important;
}

.tw-stroke-amber-600\/10 {
  stroke: rgb(217 119 6 / 0.1) !important;
}

.tw-stroke-amber-600\/100 {
  stroke: rgb(217 119 6 / 1) !important;
}

.tw-stroke-amber-600\/15 {
  stroke: rgb(217 119 6 / 0.15) !important;
}

.tw-stroke-amber-600\/20 {
  stroke: rgb(217 119 6 / 0.2) !important;
}

.tw-stroke-amber-600\/25 {
  stroke: rgb(217 119 6 / 0.25) !important;
}

.tw-stroke-amber-600\/30 {
  stroke: rgb(217 119 6 / 0.3) !important;
}

.tw-stroke-amber-600\/35 {
  stroke: rgb(217 119 6 / 0.35) !important;
}

.tw-stroke-amber-600\/40 {
  stroke: rgb(217 119 6 / 0.4) !important;
}

.tw-stroke-amber-600\/45 {
  stroke: rgb(217 119 6 / 0.45) !important;
}

.tw-stroke-amber-600\/5 {
  stroke: rgb(217 119 6 / 0.05) !important;
}

.tw-stroke-amber-600\/50 {
  stroke: rgb(217 119 6 / 0.5) !important;
}

.tw-stroke-amber-600\/55 {
  stroke: rgb(217 119 6 / 0.55) !important;
}

.tw-stroke-amber-600\/60 {
  stroke: rgb(217 119 6 / 0.6) !important;
}

.tw-stroke-amber-600\/65 {
  stroke: rgb(217 119 6 / 0.65) !important;
}

.tw-stroke-amber-600\/70 {
  stroke: rgb(217 119 6 / 0.7) !important;
}

.tw-stroke-amber-600\/75 {
  stroke: rgb(217 119 6 / 0.75) !important;
}

.tw-stroke-amber-600\/80 {
  stroke: rgb(217 119 6 / 0.8) !important;
}

.tw-stroke-amber-600\/85 {
  stroke: rgb(217 119 6 / 0.85) !important;
}

.tw-stroke-amber-600\/90 {
  stroke: rgb(217 119 6 / 0.9) !important;
}

.tw-stroke-amber-600\/95 {
  stroke: rgb(217 119 6 / 0.95) !important;
}

.tw-stroke-amber-700 {
  stroke: #b45309 !important;
}

.tw-stroke-amber-700\/0 {
  stroke: rgb(180 83 9 / 0) !important;
}

.tw-stroke-amber-700\/10 {
  stroke: rgb(180 83 9 / 0.1) !important;
}

.tw-stroke-amber-700\/100 {
  stroke: rgb(180 83 9 / 1) !important;
}

.tw-stroke-amber-700\/15 {
  stroke: rgb(180 83 9 / 0.15) !important;
}

.tw-stroke-amber-700\/20 {
  stroke: rgb(180 83 9 / 0.2) !important;
}

.tw-stroke-amber-700\/25 {
  stroke: rgb(180 83 9 / 0.25) !important;
}

.tw-stroke-amber-700\/30 {
  stroke: rgb(180 83 9 / 0.3) !important;
}

.tw-stroke-amber-700\/35 {
  stroke: rgb(180 83 9 / 0.35) !important;
}

.tw-stroke-amber-700\/40 {
  stroke: rgb(180 83 9 / 0.4) !important;
}

.tw-stroke-amber-700\/45 {
  stroke: rgb(180 83 9 / 0.45) !important;
}

.tw-stroke-amber-700\/5 {
  stroke: rgb(180 83 9 / 0.05) !important;
}

.tw-stroke-amber-700\/50 {
  stroke: rgb(180 83 9 / 0.5) !important;
}

.tw-stroke-amber-700\/55 {
  stroke: rgb(180 83 9 / 0.55) !important;
}

.tw-stroke-amber-700\/60 {
  stroke: rgb(180 83 9 / 0.6) !important;
}

.tw-stroke-amber-700\/65 {
  stroke: rgb(180 83 9 / 0.65) !important;
}

.tw-stroke-amber-700\/70 {
  stroke: rgb(180 83 9 / 0.7) !important;
}

.tw-stroke-amber-700\/75 {
  stroke: rgb(180 83 9 / 0.75) !important;
}

.tw-stroke-amber-700\/80 {
  stroke: rgb(180 83 9 / 0.8) !important;
}

.tw-stroke-amber-700\/85 {
  stroke: rgb(180 83 9 / 0.85) !important;
}

.tw-stroke-amber-700\/90 {
  stroke: rgb(180 83 9 / 0.9) !important;
}

.tw-stroke-amber-700\/95 {
  stroke: rgb(180 83 9 / 0.95) !important;
}

.tw-stroke-amber-800 {
  stroke: #92400e !important;
}

.tw-stroke-amber-800\/0 {
  stroke: rgb(146 64 14 / 0) !important;
}

.tw-stroke-amber-800\/10 {
  stroke: rgb(146 64 14 / 0.1) !important;
}

.tw-stroke-amber-800\/100 {
  stroke: rgb(146 64 14 / 1) !important;
}

.tw-stroke-amber-800\/15 {
  stroke: rgb(146 64 14 / 0.15) !important;
}

.tw-stroke-amber-800\/20 {
  stroke: rgb(146 64 14 / 0.2) !important;
}

.tw-stroke-amber-800\/25 {
  stroke: rgb(146 64 14 / 0.25) !important;
}

.tw-stroke-amber-800\/30 {
  stroke: rgb(146 64 14 / 0.3) !important;
}

.tw-stroke-amber-800\/35 {
  stroke: rgb(146 64 14 / 0.35) !important;
}

.tw-stroke-amber-800\/40 {
  stroke: rgb(146 64 14 / 0.4) !important;
}

.tw-stroke-amber-800\/45 {
  stroke: rgb(146 64 14 / 0.45) !important;
}

.tw-stroke-amber-800\/5 {
  stroke: rgb(146 64 14 / 0.05) !important;
}

.tw-stroke-amber-800\/50 {
  stroke: rgb(146 64 14 / 0.5) !important;
}

.tw-stroke-amber-800\/55 {
  stroke: rgb(146 64 14 / 0.55) !important;
}

.tw-stroke-amber-800\/60 {
  stroke: rgb(146 64 14 / 0.6) !important;
}

.tw-stroke-amber-800\/65 {
  stroke: rgb(146 64 14 / 0.65) !important;
}

.tw-stroke-amber-800\/70 {
  stroke: rgb(146 64 14 / 0.7) !important;
}

.tw-stroke-amber-800\/75 {
  stroke: rgb(146 64 14 / 0.75) !important;
}

.tw-stroke-amber-800\/80 {
  stroke: rgb(146 64 14 / 0.8) !important;
}

.tw-stroke-amber-800\/85 {
  stroke: rgb(146 64 14 / 0.85) !important;
}

.tw-stroke-amber-800\/90 {
  stroke: rgb(146 64 14 / 0.9) !important;
}

.tw-stroke-amber-800\/95 {
  stroke: rgb(146 64 14 / 0.95) !important;
}

.tw-stroke-amber-900 {
  stroke: #78350f !important;
}

.tw-stroke-amber-900\/0 {
  stroke: rgb(120 53 15 / 0) !important;
}

.tw-stroke-amber-900\/10 {
  stroke: rgb(120 53 15 / 0.1) !important;
}

.tw-stroke-amber-900\/100 {
  stroke: rgb(120 53 15 / 1) !important;
}

.tw-stroke-amber-900\/15 {
  stroke: rgb(120 53 15 / 0.15) !important;
}

.tw-stroke-amber-900\/20 {
  stroke: rgb(120 53 15 / 0.2) !important;
}

.tw-stroke-amber-900\/25 {
  stroke: rgb(120 53 15 / 0.25) !important;
}

.tw-stroke-amber-900\/30 {
  stroke: rgb(120 53 15 / 0.3) !important;
}

.tw-stroke-amber-900\/35 {
  stroke: rgb(120 53 15 / 0.35) !important;
}

.tw-stroke-amber-900\/40 {
  stroke: rgb(120 53 15 / 0.4) !important;
}

.tw-stroke-amber-900\/45 {
  stroke: rgb(120 53 15 / 0.45) !important;
}

.tw-stroke-amber-900\/5 {
  stroke: rgb(120 53 15 / 0.05) !important;
}

.tw-stroke-amber-900\/50 {
  stroke: rgb(120 53 15 / 0.5) !important;
}

.tw-stroke-amber-900\/55 {
  stroke: rgb(120 53 15 / 0.55) !important;
}

.tw-stroke-amber-900\/60 {
  stroke: rgb(120 53 15 / 0.6) !important;
}

.tw-stroke-amber-900\/65 {
  stroke: rgb(120 53 15 / 0.65) !important;
}

.tw-stroke-amber-900\/70 {
  stroke: rgb(120 53 15 / 0.7) !important;
}

.tw-stroke-amber-900\/75 {
  stroke: rgb(120 53 15 / 0.75) !important;
}

.tw-stroke-amber-900\/80 {
  stroke: rgb(120 53 15 / 0.8) !important;
}

.tw-stroke-amber-900\/85 {
  stroke: rgb(120 53 15 / 0.85) !important;
}

.tw-stroke-amber-900\/90 {
  stroke: rgb(120 53 15 / 0.9) !important;
}

.tw-stroke-amber-900\/95 {
  stroke: rgb(120 53 15 / 0.95) !important;
}

.tw-stroke-amber-950 {
  stroke: #451a03 !important;
}

.tw-stroke-amber-950\/0 {
  stroke: rgb(69 26 3 / 0) !important;
}

.tw-stroke-amber-950\/10 {
  stroke: rgb(69 26 3 / 0.1) !important;
}

.tw-stroke-amber-950\/100 {
  stroke: rgb(69 26 3 / 1) !important;
}

.tw-stroke-amber-950\/15 {
  stroke: rgb(69 26 3 / 0.15) !important;
}

.tw-stroke-amber-950\/20 {
  stroke: rgb(69 26 3 / 0.2) !important;
}

.tw-stroke-amber-950\/25 {
  stroke: rgb(69 26 3 / 0.25) !important;
}

.tw-stroke-amber-950\/30 {
  stroke: rgb(69 26 3 / 0.3) !important;
}

.tw-stroke-amber-950\/35 {
  stroke: rgb(69 26 3 / 0.35) !important;
}

.tw-stroke-amber-950\/40 {
  stroke: rgb(69 26 3 / 0.4) !important;
}

.tw-stroke-amber-950\/45 {
  stroke: rgb(69 26 3 / 0.45) !important;
}

.tw-stroke-amber-950\/5 {
  stroke: rgb(69 26 3 / 0.05) !important;
}

.tw-stroke-amber-950\/50 {
  stroke: rgb(69 26 3 / 0.5) !important;
}

.tw-stroke-amber-950\/55 {
  stroke: rgb(69 26 3 / 0.55) !important;
}

.tw-stroke-amber-950\/60 {
  stroke: rgb(69 26 3 / 0.6) !important;
}

.tw-stroke-amber-950\/65 {
  stroke: rgb(69 26 3 / 0.65) !important;
}

.tw-stroke-amber-950\/70 {
  stroke: rgb(69 26 3 / 0.7) !important;
}

.tw-stroke-amber-950\/75 {
  stroke: rgb(69 26 3 / 0.75) !important;
}

.tw-stroke-amber-950\/80 {
  stroke: rgb(69 26 3 / 0.8) !important;
}

.tw-stroke-amber-950\/85 {
  stroke: rgb(69 26 3 / 0.85) !important;
}

.tw-stroke-amber-950\/90 {
  stroke: rgb(69 26 3 / 0.9) !important;
}

.tw-stroke-amber-950\/95 {
  stroke: rgb(69 26 3 / 0.95) !important;
}

.tw-stroke-black {
  stroke: #000 !important;
}

.tw-stroke-black\/0 {
  stroke: rgb(0 0 0 / 0) !important;
}

.tw-stroke-black\/10 {
  stroke: rgb(0 0 0 / 0.1) !important;
}

.tw-stroke-black\/100 {
  stroke: rgb(0 0 0 / 1) !important;
}

.tw-stroke-black\/15 {
  stroke: rgb(0 0 0 / 0.15) !important;
}

.tw-stroke-black\/20 {
  stroke: rgb(0 0 0 / 0.2) !important;
}

.tw-stroke-black\/25 {
  stroke: rgb(0 0 0 / 0.25) !important;
}

.tw-stroke-black\/30 {
  stroke: rgb(0 0 0 / 0.3) !important;
}

.tw-stroke-black\/35 {
  stroke: rgb(0 0 0 / 0.35) !important;
}

.tw-stroke-black\/40 {
  stroke: rgb(0 0 0 / 0.4) !important;
}

.tw-stroke-black\/45 {
  stroke: rgb(0 0 0 / 0.45) !important;
}

.tw-stroke-black\/5 {
  stroke: rgb(0 0 0 / 0.05) !important;
}

.tw-stroke-black\/50 {
  stroke: rgb(0 0 0 / 0.5) !important;
}

.tw-stroke-black\/55 {
  stroke: rgb(0 0 0 / 0.55) !important;
}

.tw-stroke-black\/60 {
  stroke: rgb(0 0 0 / 0.6) !important;
}

.tw-stroke-black\/65 {
  stroke: rgb(0 0 0 / 0.65) !important;
}

.tw-stroke-black\/70 {
  stroke: rgb(0 0 0 / 0.7) !important;
}

.tw-stroke-black\/75 {
  stroke: rgb(0 0 0 / 0.75) !important;
}

.tw-stroke-black\/80 {
  stroke: rgb(0 0 0 / 0.8) !important;
}

.tw-stroke-black\/85 {
  stroke: rgb(0 0 0 / 0.85) !important;
}

.tw-stroke-black\/90 {
  stroke: rgb(0 0 0 / 0.9) !important;
}

.tw-stroke-black\/95 {
  stroke: rgb(0 0 0 / 0.95) !important;
}

.tw-stroke-blue-100 {
  stroke: #dbeafe !important;
}

.tw-stroke-blue-100\/0 {
  stroke: rgb(219 234 254 / 0) !important;
}

.tw-stroke-blue-100\/10 {
  stroke: rgb(219 234 254 / 0.1) !important;
}

.tw-stroke-blue-100\/100 {
  stroke: rgb(219 234 254 / 1) !important;
}

.tw-stroke-blue-100\/15 {
  stroke: rgb(219 234 254 / 0.15) !important;
}

.tw-stroke-blue-100\/20 {
  stroke: rgb(219 234 254 / 0.2) !important;
}

.tw-stroke-blue-100\/25 {
  stroke: rgb(219 234 254 / 0.25) !important;
}

.tw-stroke-blue-100\/30 {
  stroke: rgb(219 234 254 / 0.3) !important;
}

.tw-stroke-blue-100\/35 {
  stroke: rgb(219 234 254 / 0.35) !important;
}

.tw-stroke-blue-100\/40 {
  stroke: rgb(219 234 254 / 0.4) !important;
}

.tw-stroke-blue-100\/45 {
  stroke: rgb(219 234 254 / 0.45) !important;
}

.tw-stroke-blue-100\/5 {
  stroke: rgb(219 234 254 / 0.05) !important;
}

.tw-stroke-blue-100\/50 {
  stroke: rgb(219 234 254 / 0.5) !important;
}

.tw-stroke-blue-100\/55 {
  stroke: rgb(219 234 254 / 0.55) !important;
}

.tw-stroke-blue-100\/60 {
  stroke: rgb(219 234 254 / 0.6) !important;
}

.tw-stroke-blue-100\/65 {
  stroke: rgb(219 234 254 / 0.65) !important;
}

.tw-stroke-blue-100\/70 {
  stroke: rgb(219 234 254 / 0.7) !important;
}

.tw-stroke-blue-100\/75 {
  stroke: rgb(219 234 254 / 0.75) !important;
}

.tw-stroke-blue-100\/80 {
  stroke: rgb(219 234 254 / 0.8) !important;
}

.tw-stroke-blue-100\/85 {
  stroke: rgb(219 234 254 / 0.85) !important;
}

.tw-stroke-blue-100\/90 {
  stroke: rgb(219 234 254 / 0.9) !important;
}

.tw-stroke-blue-100\/95 {
  stroke: rgb(219 234 254 / 0.95) !important;
}

.tw-stroke-blue-200 {
  stroke: #bfdbfe !important;
}

.tw-stroke-blue-200\/0 {
  stroke: rgb(191 219 254 / 0) !important;
}

.tw-stroke-blue-200\/10 {
  stroke: rgb(191 219 254 / 0.1) !important;
}

.tw-stroke-blue-200\/100 {
  stroke: rgb(191 219 254 / 1) !important;
}

.tw-stroke-blue-200\/15 {
  stroke: rgb(191 219 254 / 0.15) !important;
}

.tw-stroke-blue-200\/20 {
  stroke: rgb(191 219 254 / 0.2) !important;
}

.tw-stroke-blue-200\/25 {
  stroke: rgb(191 219 254 / 0.25) !important;
}

.tw-stroke-blue-200\/30 {
  stroke: rgb(191 219 254 / 0.3) !important;
}

.tw-stroke-blue-200\/35 {
  stroke: rgb(191 219 254 / 0.35) !important;
}

.tw-stroke-blue-200\/40 {
  stroke: rgb(191 219 254 / 0.4) !important;
}

.tw-stroke-blue-200\/45 {
  stroke: rgb(191 219 254 / 0.45) !important;
}

.tw-stroke-blue-200\/5 {
  stroke: rgb(191 219 254 / 0.05) !important;
}

.tw-stroke-blue-200\/50 {
  stroke: rgb(191 219 254 / 0.5) !important;
}

.tw-stroke-blue-200\/55 {
  stroke: rgb(191 219 254 / 0.55) !important;
}

.tw-stroke-blue-200\/60 {
  stroke: rgb(191 219 254 / 0.6) !important;
}

.tw-stroke-blue-200\/65 {
  stroke: rgb(191 219 254 / 0.65) !important;
}

.tw-stroke-blue-200\/70 {
  stroke: rgb(191 219 254 / 0.7) !important;
}

.tw-stroke-blue-200\/75 {
  stroke: rgb(191 219 254 / 0.75) !important;
}

.tw-stroke-blue-200\/80 {
  stroke: rgb(191 219 254 / 0.8) !important;
}

.tw-stroke-blue-200\/85 {
  stroke: rgb(191 219 254 / 0.85) !important;
}

.tw-stroke-blue-200\/90 {
  stroke: rgb(191 219 254 / 0.9) !important;
}

.tw-stroke-blue-200\/95 {
  stroke: rgb(191 219 254 / 0.95) !important;
}

.tw-stroke-blue-300 {
  stroke: #93c5fd !important;
}

.tw-stroke-blue-300\/0 {
  stroke: rgb(147 197 253 / 0) !important;
}

.tw-stroke-blue-300\/10 {
  stroke: rgb(147 197 253 / 0.1) !important;
}

.tw-stroke-blue-300\/100 {
  stroke: rgb(147 197 253 / 1) !important;
}

.tw-stroke-blue-300\/15 {
  stroke: rgb(147 197 253 / 0.15) !important;
}

.tw-stroke-blue-300\/20 {
  stroke: rgb(147 197 253 / 0.2) !important;
}

.tw-stroke-blue-300\/25 {
  stroke: rgb(147 197 253 / 0.25) !important;
}

.tw-stroke-blue-300\/30 {
  stroke: rgb(147 197 253 / 0.3) !important;
}

.tw-stroke-blue-300\/35 {
  stroke: rgb(147 197 253 / 0.35) !important;
}

.tw-stroke-blue-300\/40 {
  stroke: rgb(147 197 253 / 0.4) !important;
}

.tw-stroke-blue-300\/45 {
  stroke: rgb(147 197 253 / 0.45) !important;
}

.tw-stroke-blue-300\/5 {
  stroke: rgb(147 197 253 / 0.05) !important;
}

.tw-stroke-blue-300\/50 {
  stroke: rgb(147 197 253 / 0.5) !important;
}

.tw-stroke-blue-300\/55 {
  stroke: rgb(147 197 253 / 0.55) !important;
}

.tw-stroke-blue-300\/60 {
  stroke: rgb(147 197 253 / 0.6) !important;
}

.tw-stroke-blue-300\/65 {
  stroke: rgb(147 197 253 / 0.65) !important;
}

.tw-stroke-blue-300\/70 {
  stroke: rgb(147 197 253 / 0.7) !important;
}

.tw-stroke-blue-300\/75 {
  stroke: rgb(147 197 253 / 0.75) !important;
}

.tw-stroke-blue-300\/80 {
  stroke: rgb(147 197 253 / 0.8) !important;
}

.tw-stroke-blue-300\/85 {
  stroke: rgb(147 197 253 / 0.85) !important;
}

.tw-stroke-blue-300\/90 {
  stroke: rgb(147 197 253 / 0.9) !important;
}

.tw-stroke-blue-300\/95 {
  stroke: rgb(147 197 253 / 0.95) !important;
}

.tw-stroke-blue-400 {
  stroke: #60a5fa !important;
}

.tw-stroke-blue-400\/0 {
  stroke: rgb(96 165 250 / 0) !important;
}

.tw-stroke-blue-400\/10 {
  stroke: rgb(96 165 250 / 0.1) !important;
}

.tw-stroke-blue-400\/100 {
  stroke: rgb(96 165 250 / 1) !important;
}

.tw-stroke-blue-400\/15 {
  stroke: rgb(96 165 250 / 0.15) !important;
}

.tw-stroke-blue-400\/20 {
  stroke: rgb(96 165 250 / 0.2) !important;
}

.tw-stroke-blue-400\/25 {
  stroke: rgb(96 165 250 / 0.25) !important;
}

.tw-stroke-blue-400\/30 {
  stroke: rgb(96 165 250 / 0.3) !important;
}

.tw-stroke-blue-400\/35 {
  stroke: rgb(96 165 250 / 0.35) !important;
}

.tw-stroke-blue-400\/40 {
  stroke: rgb(96 165 250 / 0.4) !important;
}

.tw-stroke-blue-400\/45 {
  stroke: rgb(96 165 250 / 0.45) !important;
}

.tw-stroke-blue-400\/5 {
  stroke: rgb(96 165 250 / 0.05) !important;
}

.tw-stroke-blue-400\/50 {
  stroke: rgb(96 165 250 / 0.5) !important;
}

.tw-stroke-blue-400\/55 {
  stroke: rgb(96 165 250 / 0.55) !important;
}

.tw-stroke-blue-400\/60 {
  stroke: rgb(96 165 250 / 0.6) !important;
}

.tw-stroke-blue-400\/65 {
  stroke: rgb(96 165 250 / 0.65) !important;
}

.tw-stroke-blue-400\/70 {
  stroke: rgb(96 165 250 / 0.7) !important;
}

.tw-stroke-blue-400\/75 {
  stroke: rgb(96 165 250 / 0.75) !important;
}

.tw-stroke-blue-400\/80 {
  stroke: rgb(96 165 250 / 0.8) !important;
}

.tw-stroke-blue-400\/85 {
  stroke: rgb(96 165 250 / 0.85) !important;
}

.tw-stroke-blue-400\/90 {
  stroke: rgb(96 165 250 / 0.9) !important;
}

.tw-stroke-blue-400\/95 {
  stroke: rgb(96 165 250 / 0.95) !important;
}

.tw-stroke-blue-50 {
  stroke: #eff6ff !important;
}

.tw-stroke-blue-50\/0 {
  stroke: rgb(239 246 255 / 0) !important;
}

.tw-stroke-blue-50\/10 {
  stroke: rgb(239 246 255 / 0.1) !important;
}

.tw-stroke-blue-50\/100 {
  stroke: rgb(239 246 255 / 1) !important;
}

.tw-stroke-blue-50\/15 {
  stroke: rgb(239 246 255 / 0.15) !important;
}

.tw-stroke-blue-50\/20 {
  stroke: rgb(239 246 255 / 0.2) !important;
}

.tw-stroke-blue-50\/25 {
  stroke: rgb(239 246 255 / 0.25) !important;
}

.tw-stroke-blue-50\/30 {
  stroke: rgb(239 246 255 / 0.3) !important;
}

.tw-stroke-blue-50\/35 {
  stroke: rgb(239 246 255 / 0.35) !important;
}

.tw-stroke-blue-50\/40 {
  stroke: rgb(239 246 255 / 0.4) !important;
}

.tw-stroke-blue-50\/45 {
  stroke: rgb(239 246 255 / 0.45) !important;
}

.tw-stroke-blue-50\/5 {
  stroke: rgb(239 246 255 / 0.05) !important;
}

.tw-stroke-blue-50\/50 {
  stroke: rgb(239 246 255 / 0.5) !important;
}

.tw-stroke-blue-50\/55 {
  stroke: rgb(239 246 255 / 0.55) !important;
}

.tw-stroke-blue-50\/60 {
  stroke: rgb(239 246 255 / 0.6) !important;
}

.tw-stroke-blue-50\/65 {
  stroke: rgb(239 246 255 / 0.65) !important;
}

.tw-stroke-blue-50\/70 {
  stroke: rgb(239 246 255 / 0.7) !important;
}

.tw-stroke-blue-50\/75 {
  stroke: rgb(239 246 255 / 0.75) !important;
}

.tw-stroke-blue-50\/80 {
  stroke: rgb(239 246 255 / 0.8) !important;
}

.tw-stroke-blue-50\/85 {
  stroke: rgb(239 246 255 / 0.85) !important;
}

.tw-stroke-blue-50\/90 {
  stroke: rgb(239 246 255 / 0.9) !important;
}

.tw-stroke-blue-50\/95 {
  stroke: rgb(239 246 255 / 0.95) !important;
}

.tw-stroke-blue-500 {
  stroke: #3b82f6 !important;
}

.tw-stroke-blue-500\/0 {
  stroke: rgb(59 130 246 / 0) !important;
}

.tw-stroke-blue-500\/10 {
  stroke: rgb(59 130 246 / 0.1) !important;
}

.tw-stroke-blue-500\/100 {
  stroke: rgb(59 130 246 / 1) !important;
}

.tw-stroke-blue-500\/15 {
  stroke: rgb(59 130 246 / 0.15) !important;
}

.tw-stroke-blue-500\/20 {
  stroke: rgb(59 130 246 / 0.2) !important;
}

.tw-stroke-blue-500\/25 {
  stroke: rgb(59 130 246 / 0.25) !important;
}

.tw-stroke-blue-500\/30 {
  stroke: rgb(59 130 246 / 0.3) !important;
}

.tw-stroke-blue-500\/35 {
  stroke: rgb(59 130 246 / 0.35) !important;
}

.tw-stroke-blue-500\/40 {
  stroke: rgb(59 130 246 / 0.4) !important;
}

.tw-stroke-blue-500\/45 {
  stroke: rgb(59 130 246 / 0.45) !important;
}

.tw-stroke-blue-500\/5 {
  stroke: rgb(59 130 246 / 0.05) !important;
}

.tw-stroke-blue-500\/50 {
  stroke: rgb(59 130 246 / 0.5) !important;
}

.tw-stroke-blue-500\/55 {
  stroke: rgb(59 130 246 / 0.55) !important;
}

.tw-stroke-blue-500\/60 {
  stroke: rgb(59 130 246 / 0.6) !important;
}

.tw-stroke-blue-500\/65 {
  stroke: rgb(59 130 246 / 0.65) !important;
}

.tw-stroke-blue-500\/70 {
  stroke: rgb(59 130 246 / 0.7) !important;
}

.tw-stroke-blue-500\/75 {
  stroke: rgb(59 130 246 / 0.75) !important;
}

.tw-stroke-blue-500\/80 {
  stroke: rgb(59 130 246 / 0.8) !important;
}

.tw-stroke-blue-500\/85 {
  stroke: rgb(59 130 246 / 0.85) !important;
}

.tw-stroke-blue-500\/90 {
  stroke: rgb(59 130 246 / 0.9) !important;
}

.tw-stroke-blue-500\/95 {
  stroke: rgb(59 130 246 / 0.95) !important;
}

.tw-stroke-blue-600 {
  stroke: #2563eb !important;
}

.tw-stroke-blue-600\/0 {
  stroke: rgb(37 99 235 / 0) !important;
}

.tw-stroke-blue-600\/10 {
  stroke: rgb(37 99 235 / 0.1) !important;
}

.tw-stroke-blue-600\/100 {
  stroke: rgb(37 99 235 / 1) !important;
}

.tw-stroke-blue-600\/15 {
  stroke: rgb(37 99 235 / 0.15) !important;
}

.tw-stroke-blue-600\/20 {
  stroke: rgb(37 99 235 / 0.2) !important;
}

.tw-stroke-blue-600\/25 {
  stroke: rgb(37 99 235 / 0.25) !important;
}

.tw-stroke-blue-600\/30 {
  stroke: rgb(37 99 235 / 0.3) !important;
}

.tw-stroke-blue-600\/35 {
  stroke: rgb(37 99 235 / 0.35) !important;
}

.tw-stroke-blue-600\/40 {
  stroke: rgb(37 99 235 / 0.4) !important;
}

.tw-stroke-blue-600\/45 {
  stroke: rgb(37 99 235 / 0.45) !important;
}

.tw-stroke-blue-600\/5 {
  stroke: rgb(37 99 235 / 0.05) !important;
}

.tw-stroke-blue-600\/50 {
  stroke: rgb(37 99 235 / 0.5) !important;
}

.tw-stroke-blue-600\/55 {
  stroke: rgb(37 99 235 / 0.55) !important;
}

.tw-stroke-blue-600\/60 {
  stroke: rgb(37 99 235 / 0.6) !important;
}

.tw-stroke-blue-600\/65 {
  stroke: rgb(37 99 235 / 0.65) !important;
}

.tw-stroke-blue-600\/70 {
  stroke: rgb(37 99 235 / 0.7) !important;
}

.tw-stroke-blue-600\/75 {
  stroke: rgb(37 99 235 / 0.75) !important;
}

.tw-stroke-blue-600\/80 {
  stroke: rgb(37 99 235 / 0.8) !important;
}

.tw-stroke-blue-600\/85 {
  stroke: rgb(37 99 235 / 0.85) !important;
}

.tw-stroke-blue-600\/90 {
  stroke: rgb(37 99 235 / 0.9) !important;
}

.tw-stroke-blue-600\/95 {
  stroke: rgb(37 99 235 / 0.95) !important;
}

.tw-stroke-blue-700 {
  stroke: #1d4ed8 !important;
}

.tw-stroke-blue-700\/0 {
  stroke: rgb(29 78 216 / 0) !important;
}

.tw-stroke-blue-700\/10 {
  stroke: rgb(29 78 216 / 0.1) !important;
}

.tw-stroke-blue-700\/100 {
  stroke: rgb(29 78 216 / 1) !important;
}

.tw-stroke-blue-700\/15 {
  stroke: rgb(29 78 216 / 0.15) !important;
}

.tw-stroke-blue-700\/20 {
  stroke: rgb(29 78 216 / 0.2) !important;
}

.tw-stroke-blue-700\/25 {
  stroke: rgb(29 78 216 / 0.25) !important;
}

.tw-stroke-blue-700\/30 {
  stroke: rgb(29 78 216 / 0.3) !important;
}

.tw-stroke-blue-700\/35 {
  stroke: rgb(29 78 216 / 0.35) !important;
}

.tw-stroke-blue-700\/40 {
  stroke: rgb(29 78 216 / 0.4) !important;
}

.tw-stroke-blue-700\/45 {
  stroke: rgb(29 78 216 / 0.45) !important;
}

.tw-stroke-blue-700\/5 {
  stroke: rgb(29 78 216 / 0.05) !important;
}

.tw-stroke-blue-700\/50 {
  stroke: rgb(29 78 216 / 0.5) !important;
}

.tw-stroke-blue-700\/55 {
  stroke: rgb(29 78 216 / 0.55) !important;
}

.tw-stroke-blue-700\/60 {
  stroke: rgb(29 78 216 / 0.6) !important;
}

.tw-stroke-blue-700\/65 {
  stroke: rgb(29 78 216 / 0.65) !important;
}

.tw-stroke-blue-700\/70 {
  stroke: rgb(29 78 216 / 0.7) !important;
}

.tw-stroke-blue-700\/75 {
  stroke: rgb(29 78 216 / 0.75) !important;
}

.tw-stroke-blue-700\/80 {
  stroke: rgb(29 78 216 / 0.8) !important;
}

.tw-stroke-blue-700\/85 {
  stroke: rgb(29 78 216 / 0.85) !important;
}

.tw-stroke-blue-700\/90 {
  stroke: rgb(29 78 216 / 0.9) !important;
}

.tw-stroke-blue-700\/95 {
  stroke: rgb(29 78 216 / 0.95) !important;
}

.tw-stroke-blue-800 {
  stroke: #1e40af !important;
}

.tw-stroke-blue-800\/0 {
  stroke: rgb(30 64 175 / 0) !important;
}

.tw-stroke-blue-800\/10 {
  stroke: rgb(30 64 175 / 0.1) !important;
}

.tw-stroke-blue-800\/100 {
  stroke: rgb(30 64 175 / 1) !important;
}

.tw-stroke-blue-800\/15 {
  stroke: rgb(30 64 175 / 0.15) !important;
}

.tw-stroke-blue-800\/20 {
  stroke: rgb(30 64 175 / 0.2) !important;
}

.tw-stroke-blue-800\/25 {
  stroke: rgb(30 64 175 / 0.25) !important;
}

.tw-stroke-blue-800\/30 {
  stroke: rgb(30 64 175 / 0.3) !important;
}

.tw-stroke-blue-800\/35 {
  stroke: rgb(30 64 175 / 0.35) !important;
}

.tw-stroke-blue-800\/40 {
  stroke: rgb(30 64 175 / 0.4) !important;
}

.tw-stroke-blue-800\/45 {
  stroke: rgb(30 64 175 / 0.45) !important;
}

.tw-stroke-blue-800\/5 {
  stroke: rgb(30 64 175 / 0.05) !important;
}

.tw-stroke-blue-800\/50 {
  stroke: rgb(30 64 175 / 0.5) !important;
}

.tw-stroke-blue-800\/55 {
  stroke: rgb(30 64 175 / 0.55) !important;
}

.tw-stroke-blue-800\/60 {
  stroke: rgb(30 64 175 / 0.6) !important;
}

.tw-stroke-blue-800\/65 {
  stroke: rgb(30 64 175 / 0.65) !important;
}

.tw-stroke-blue-800\/70 {
  stroke: rgb(30 64 175 / 0.7) !important;
}

.tw-stroke-blue-800\/75 {
  stroke: rgb(30 64 175 / 0.75) !important;
}

.tw-stroke-blue-800\/80 {
  stroke: rgb(30 64 175 / 0.8) !important;
}

.tw-stroke-blue-800\/85 {
  stroke: rgb(30 64 175 / 0.85) !important;
}

.tw-stroke-blue-800\/90 {
  stroke: rgb(30 64 175 / 0.9) !important;
}

.tw-stroke-blue-800\/95 {
  stroke: rgb(30 64 175 / 0.95) !important;
}

.tw-stroke-blue-900 {
  stroke: #1e3a8a !important;
}

.tw-stroke-blue-900\/0 {
  stroke: rgb(30 58 138 / 0) !important;
}

.tw-stroke-blue-900\/10 {
  stroke: rgb(30 58 138 / 0.1) !important;
}

.tw-stroke-blue-900\/100 {
  stroke: rgb(30 58 138 / 1) !important;
}

.tw-stroke-blue-900\/15 {
  stroke: rgb(30 58 138 / 0.15) !important;
}

.tw-stroke-blue-900\/20 {
  stroke: rgb(30 58 138 / 0.2) !important;
}

.tw-stroke-blue-900\/25 {
  stroke: rgb(30 58 138 / 0.25) !important;
}

.tw-stroke-blue-900\/30 {
  stroke: rgb(30 58 138 / 0.3) !important;
}

.tw-stroke-blue-900\/35 {
  stroke: rgb(30 58 138 / 0.35) !important;
}

.tw-stroke-blue-900\/40 {
  stroke: rgb(30 58 138 / 0.4) !important;
}

.tw-stroke-blue-900\/45 {
  stroke: rgb(30 58 138 / 0.45) !important;
}

.tw-stroke-blue-900\/5 {
  stroke: rgb(30 58 138 / 0.05) !important;
}

.tw-stroke-blue-900\/50 {
  stroke: rgb(30 58 138 / 0.5) !important;
}

.tw-stroke-blue-900\/55 {
  stroke: rgb(30 58 138 / 0.55) !important;
}

.tw-stroke-blue-900\/60 {
  stroke: rgb(30 58 138 / 0.6) !important;
}

.tw-stroke-blue-900\/65 {
  stroke: rgb(30 58 138 / 0.65) !important;
}

.tw-stroke-blue-900\/70 {
  stroke: rgb(30 58 138 / 0.7) !important;
}

.tw-stroke-blue-900\/75 {
  stroke: rgb(30 58 138 / 0.75) !important;
}

.tw-stroke-blue-900\/80 {
  stroke: rgb(30 58 138 / 0.8) !important;
}

.tw-stroke-blue-900\/85 {
  stroke: rgb(30 58 138 / 0.85) !important;
}

.tw-stroke-blue-900\/90 {
  stroke: rgb(30 58 138 / 0.9) !important;
}

.tw-stroke-blue-900\/95 {
  stroke: rgb(30 58 138 / 0.95) !important;
}

.tw-stroke-blue-950 {
  stroke: #172554 !important;
}

.tw-stroke-blue-950\/0 {
  stroke: rgb(23 37 84 / 0) !important;
}

.tw-stroke-blue-950\/10 {
  stroke: rgb(23 37 84 / 0.1) !important;
}

.tw-stroke-blue-950\/100 {
  stroke: rgb(23 37 84 / 1) !important;
}

.tw-stroke-blue-950\/15 {
  stroke: rgb(23 37 84 / 0.15) !important;
}

.tw-stroke-blue-950\/20 {
  stroke: rgb(23 37 84 / 0.2) !important;
}

.tw-stroke-blue-950\/25 {
  stroke: rgb(23 37 84 / 0.25) !important;
}

.tw-stroke-blue-950\/30 {
  stroke: rgb(23 37 84 / 0.3) !important;
}

.tw-stroke-blue-950\/35 {
  stroke: rgb(23 37 84 / 0.35) !important;
}

.tw-stroke-blue-950\/40 {
  stroke: rgb(23 37 84 / 0.4) !important;
}

.tw-stroke-blue-950\/45 {
  stroke: rgb(23 37 84 / 0.45) !important;
}

.tw-stroke-blue-950\/5 {
  stroke: rgb(23 37 84 / 0.05) !important;
}

.tw-stroke-blue-950\/50 {
  stroke: rgb(23 37 84 / 0.5) !important;
}

.tw-stroke-blue-950\/55 {
  stroke: rgb(23 37 84 / 0.55) !important;
}

.tw-stroke-blue-950\/60 {
  stroke: rgb(23 37 84 / 0.6) !important;
}

.tw-stroke-blue-950\/65 {
  stroke: rgb(23 37 84 / 0.65) !important;
}

.tw-stroke-blue-950\/70 {
  stroke: rgb(23 37 84 / 0.7) !important;
}

.tw-stroke-blue-950\/75 {
  stroke: rgb(23 37 84 / 0.75) !important;
}

.tw-stroke-blue-950\/80 {
  stroke: rgb(23 37 84 / 0.8) !important;
}

.tw-stroke-blue-950\/85 {
  stroke: rgb(23 37 84 / 0.85) !important;
}

.tw-stroke-blue-950\/90 {
  stroke: rgb(23 37 84 / 0.9) !important;
}

.tw-stroke-blue-950\/95 {
  stroke: rgb(23 37 84 / 0.95) !important;
}

.tw-stroke-current {
  stroke: currentColor !important;
}

.tw-stroke-cyan-100 {
  stroke: #cffafe !important;
}

.tw-stroke-cyan-100\/0 {
  stroke: rgb(207 250 254 / 0) !important;
}

.tw-stroke-cyan-100\/10 {
  stroke: rgb(207 250 254 / 0.1) !important;
}

.tw-stroke-cyan-100\/100 {
  stroke: rgb(207 250 254 / 1) !important;
}

.tw-stroke-cyan-100\/15 {
  stroke: rgb(207 250 254 / 0.15) !important;
}

.tw-stroke-cyan-100\/20 {
  stroke: rgb(207 250 254 / 0.2) !important;
}

.tw-stroke-cyan-100\/25 {
  stroke: rgb(207 250 254 / 0.25) !important;
}

.tw-stroke-cyan-100\/30 {
  stroke: rgb(207 250 254 / 0.3) !important;
}

.tw-stroke-cyan-100\/35 {
  stroke: rgb(207 250 254 / 0.35) !important;
}

.tw-stroke-cyan-100\/40 {
  stroke: rgb(207 250 254 / 0.4) !important;
}

.tw-stroke-cyan-100\/45 {
  stroke: rgb(207 250 254 / 0.45) !important;
}

.tw-stroke-cyan-100\/5 {
  stroke: rgb(207 250 254 / 0.05) !important;
}

.tw-stroke-cyan-100\/50 {
  stroke: rgb(207 250 254 / 0.5) !important;
}

.tw-stroke-cyan-100\/55 {
  stroke: rgb(207 250 254 / 0.55) !important;
}

.tw-stroke-cyan-100\/60 {
  stroke: rgb(207 250 254 / 0.6) !important;
}

.tw-stroke-cyan-100\/65 {
  stroke: rgb(207 250 254 / 0.65) !important;
}

.tw-stroke-cyan-100\/70 {
  stroke: rgb(207 250 254 / 0.7) !important;
}

.tw-stroke-cyan-100\/75 {
  stroke: rgb(207 250 254 / 0.75) !important;
}

.tw-stroke-cyan-100\/80 {
  stroke: rgb(207 250 254 / 0.8) !important;
}

.tw-stroke-cyan-100\/85 {
  stroke: rgb(207 250 254 / 0.85) !important;
}

.tw-stroke-cyan-100\/90 {
  stroke: rgb(207 250 254 / 0.9) !important;
}

.tw-stroke-cyan-100\/95 {
  stroke: rgb(207 250 254 / 0.95) !important;
}

.tw-stroke-cyan-200 {
  stroke: #a5f3fc !important;
}

.tw-stroke-cyan-200\/0 {
  stroke: rgb(165 243 252 / 0) !important;
}

.tw-stroke-cyan-200\/10 {
  stroke: rgb(165 243 252 / 0.1) !important;
}

.tw-stroke-cyan-200\/100 {
  stroke: rgb(165 243 252 / 1) !important;
}

.tw-stroke-cyan-200\/15 {
  stroke: rgb(165 243 252 / 0.15) !important;
}

.tw-stroke-cyan-200\/20 {
  stroke: rgb(165 243 252 / 0.2) !important;
}

.tw-stroke-cyan-200\/25 {
  stroke: rgb(165 243 252 / 0.25) !important;
}

.tw-stroke-cyan-200\/30 {
  stroke: rgb(165 243 252 / 0.3) !important;
}

.tw-stroke-cyan-200\/35 {
  stroke: rgb(165 243 252 / 0.35) !important;
}

.tw-stroke-cyan-200\/40 {
  stroke: rgb(165 243 252 / 0.4) !important;
}

.tw-stroke-cyan-200\/45 {
  stroke: rgb(165 243 252 / 0.45) !important;
}

.tw-stroke-cyan-200\/5 {
  stroke: rgb(165 243 252 / 0.05) !important;
}

.tw-stroke-cyan-200\/50 {
  stroke: rgb(165 243 252 / 0.5) !important;
}

.tw-stroke-cyan-200\/55 {
  stroke: rgb(165 243 252 / 0.55) !important;
}

.tw-stroke-cyan-200\/60 {
  stroke: rgb(165 243 252 / 0.6) !important;
}

.tw-stroke-cyan-200\/65 {
  stroke: rgb(165 243 252 / 0.65) !important;
}

.tw-stroke-cyan-200\/70 {
  stroke: rgb(165 243 252 / 0.7) !important;
}

.tw-stroke-cyan-200\/75 {
  stroke: rgb(165 243 252 / 0.75) !important;
}

.tw-stroke-cyan-200\/80 {
  stroke: rgb(165 243 252 / 0.8) !important;
}

.tw-stroke-cyan-200\/85 {
  stroke: rgb(165 243 252 / 0.85) !important;
}

.tw-stroke-cyan-200\/90 {
  stroke: rgb(165 243 252 / 0.9) !important;
}

.tw-stroke-cyan-200\/95 {
  stroke: rgb(165 243 252 / 0.95) !important;
}

.tw-stroke-cyan-300 {
  stroke: #67e8f9 !important;
}

.tw-stroke-cyan-300\/0 {
  stroke: rgb(103 232 249 / 0) !important;
}

.tw-stroke-cyan-300\/10 {
  stroke: rgb(103 232 249 / 0.1) !important;
}

.tw-stroke-cyan-300\/100 {
  stroke: rgb(103 232 249 / 1) !important;
}

.tw-stroke-cyan-300\/15 {
  stroke: rgb(103 232 249 / 0.15) !important;
}

.tw-stroke-cyan-300\/20 {
  stroke: rgb(103 232 249 / 0.2) !important;
}

.tw-stroke-cyan-300\/25 {
  stroke: rgb(103 232 249 / 0.25) !important;
}

.tw-stroke-cyan-300\/30 {
  stroke: rgb(103 232 249 / 0.3) !important;
}

.tw-stroke-cyan-300\/35 {
  stroke: rgb(103 232 249 / 0.35) !important;
}

.tw-stroke-cyan-300\/40 {
  stroke: rgb(103 232 249 / 0.4) !important;
}

.tw-stroke-cyan-300\/45 {
  stroke: rgb(103 232 249 / 0.45) !important;
}

.tw-stroke-cyan-300\/5 {
  stroke: rgb(103 232 249 / 0.05) !important;
}

.tw-stroke-cyan-300\/50 {
  stroke: rgb(103 232 249 / 0.5) !important;
}

.tw-stroke-cyan-300\/55 {
  stroke: rgb(103 232 249 / 0.55) !important;
}

.tw-stroke-cyan-300\/60 {
  stroke: rgb(103 232 249 / 0.6) !important;
}

.tw-stroke-cyan-300\/65 {
  stroke: rgb(103 232 249 / 0.65) !important;
}

.tw-stroke-cyan-300\/70 {
  stroke: rgb(103 232 249 / 0.7) !important;
}

.tw-stroke-cyan-300\/75 {
  stroke: rgb(103 232 249 / 0.75) !important;
}

.tw-stroke-cyan-300\/80 {
  stroke: rgb(103 232 249 / 0.8) !important;
}

.tw-stroke-cyan-300\/85 {
  stroke: rgb(103 232 249 / 0.85) !important;
}

.tw-stroke-cyan-300\/90 {
  stroke: rgb(103 232 249 / 0.9) !important;
}

.tw-stroke-cyan-300\/95 {
  stroke: rgb(103 232 249 / 0.95) !important;
}

.tw-stroke-cyan-400 {
  stroke: #22d3ee !important;
}

.tw-stroke-cyan-400\/0 {
  stroke: rgb(34 211 238 / 0) !important;
}

.tw-stroke-cyan-400\/10 {
  stroke: rgb(34 211 238 / 0.1) !important;
}

.tw-stroke-cyan-400\/100 {
  stroke: rgb(34 211 238 / 1) !important;
}

.tw-stroke-cyan-400\/15 {
  stroke: rgb(34 211 238 / 0.15) !important;
}

.tw-stroke-cyan-400\/20 {
  stroke: rgb(34 211 238 / 0.2) !important;
}

.tw-stroke-cyan-400\/25 {
  stroke: rgb(34 211 238 / 0.25) !important;
}

.tw-stroke-cyan-400\/30 {
  stroke: rgb(34 211 238 / 0.3) !important;
}

.tw-stroke-cyan-400\/35 {
  stroke: rgb(34 211 238 / 0.35) !important;
}

.tw-stroke-cyan-400\/40 {
  stroke: rgb(34 211 238 / 0.4) !important;
}

.tw-stroke-cyan-400\/45 {
  stroke: rgb(34 211 238 / 0.45) !important;
}

.tw-stroke-cyan-400\/5 {
  stroke: rgb(34 211 238 / 0.05) !important;
}

.tw-stroke-cyan-400\/50 {
  stroke: rgb(34 211 238 / 0.5) !important;
}

.tw-stroke-cyan-400\/55 {
  stroke: rgb(34 211 238 / 0.55) !important;
}

.tw-stroke-cyan-400\/60 {
  stroke: rgb(34 211 238 / 0.6) !important;
}

.tw-stroke-cyan-400\/65 {
  stroke: rgb(34 211 238 / 0.65) !important;
}

.tw-stroke-cyan-400\/70 {
  stroke: rgb(34 211 238 / 0.7) !important;
}

.tw-stroke-cyan-400\/75 {
  stroke: rgb(34 211 238 / 0.75) !important;
}

.tw-stroke-cyan-400\/80 {
  stroke: rgb(34 211 238 / 0.8) !important;
}

.tw-stroke-cyan-400\/85 {
  stroke: rgb(34 211 238 / 0.85) !important;
}

.tw-stroke-cyan-400\/90 {
  stroke: rgb(34 211 238 / 0.9) !important;
}

.tw-stroke-cyan-400\/95 {
  stroke: rgb(34 211 238 / 0.95) !important;
}

.tw-stroke-cyan-50 {
  stroke: #ecfeff !important;
}

.tw-stroke-cyan-50\/0 {
  stroke: rgb(236 254 255 / 0) !important;
}

.tw-stroke-cyan-50\/10 {
  stroke: rgb(236 254 255 / 0.1) !important;
}

.tw-stroke-cyan-50\/100 {
  stroke: rgb(236 254 255 / 1) !important;
}

.tw-stroke-cyan-50\/15 {
  stroke: rgb(236 254 255 / 0.15) !important;
}

.tw-stroke-cyan-50\/20 {
  stroke: rgb(236 254 255 / 0.2) !important;
}

.tw-stroke-cyan-50\/25 {
  stroke: rgb(236 254 255 / 0.25) !important;
}

.tw-stroke-cyan-50\/30 {
  stroke: rgb(236 254 255 / 0.3) !important;
}

.tw-stroke-cyan-50\/35 {
  stroke: rgb(236 254 255 / 0.35) !important;
}

.tw-stroke-cyan-50\/40 {
  stroke: rgb(236 254 255 / 0.4) !important;
}

.tw-stroke-cyan-50\/45 {
  stroke: rgb(236 254 255 / 0.45) !important;
}

.tw-stroke-cyan-50\/5 {
  stroke: rgb(236 254 255 / 0.05) !important;
}

.tw-stroke-cyan-50\/50 {
  stroke: rgb(236 254 255 / 0.5) !important;
}

.tw-stroke-cyan-50\/55 {
  stroke: rgb(236 254 255 / 0.55) !important;
}

.tw-stroke-cyan-50\/60 {
  stroke: rgb(236 254 255 / 0.6) !important;
}

.tw-stroke-cyan-50\/65 {
  stroke: rgb(236 254 255 / 0.65) !important;
}

.tw-stroke-cyan-50\/70 {
  stroke: rgb(236 254 255 / 0.7) !important;
}

.tw-stroke-cyan-50\/75 {
  stroke: rgb(236 254 255 / 0.75) !important;
}

.tw-stroke-cyan-50\/80 {
  stroke: rgb(236 254 255 / 0.8) !important;
}

.tw-stroke-cyan-50\/85 {
  stroke: rgb(236 254 255 / 0.85) !important;
}

.tw-stroke-cyan-50\/90 {
  stroke: rgb(236 254 255 / 0.9) !important;
}

.tw-stroke-cyan-50\/95 {
  stroke: rgb(236 254 255 / 0.95) !important;
}

.tw-stroke-cyan-500 {
  stroke: #06b6d4 !important;
}

.tw-stroke-cyan-500\/0 {
  stroke: rgb(6 182 212 / 0) !important;
}

.tw-stroke-cyan-500\/10 {
  stroke: rgb(6 182 212 / 0.1) !important;
}

.tw-stroke-cyan-500\/100 {
  stroke: rgb(6 182 212 / 1) !important;
}

.tw-stroke-cyan-500\/15 {
  stroke: rgb(6 182 212 / 0.15) !important;
}

.tw-stroke-cyan-500\/20 {
  stroke: rgb(6 182 212 / 0.2) !important;
}

.tw-stroke-cyan-500\/25 {
  stroke: rgb(6 182 212 / 0.25) !important;
}

.tw-stroke-cyan-500\/30 {
  stroke: rgb(6 182 212 / 0.3) !important;
}

.tw-stroke-cyan-500\/35 {
  stroke: rgb(6 182 212 / 0.35) !important;
}

.tw-stroke-cyan-500\/40 {
  stroke: rgb(6 182 212 / 0.4) !important;
}

.tw-stroke-cyan-500\/45 {
  stroke: rgb(6 182 212 / 0.45) !important;
}

.tw-stroke-cyan-500\/5 {
  stroke: rgb(6 182 212 / 0.05) !important;
}

.tw-stroke-cyan-500\/50 {
  stroke: rgb(6 182 212 / 0.5) !important;
}

.tw-stroke-cyan-500\/55 {
  stroke: rgb(6 182 212 / 0.55) !important;
}

.tw-stroke-cyan-500\/60 {
  stroke: rgb(6 182 212 / 0.6) !important;
}

.tw-stroke-cyan-500\/65 {
  stroke: rgb(6 182 212 / 0.65) !important;
}

.tw-stroke-cyan-500\/70 {
  stroke: rgb(6 182 212 / 0.7) !important;
}

.tw-stroke-cyan-500\/75 {
  stroke: rgb(6 182 212 / 0.75) !important;
}

.tw-stroke-cyan-500\/80 {
  stroke: rgb(6 182 212 / 0.8) !important;
}

.tw-stroke-cyan-500\/85 {
  stroke: rgb(6 182 212 / 0.85) !important;
}

.tw-stroke-cyan-500\/90 {
  stroke: rgb(6 182 212 / 0.9) !important;
}

.tw-stroke-cyan-500\/95 {
  stroke: rgb(6 182 212 / 0.95) !important;
}

.tw-stroke-cyan-600 {
  stroke: #0891b2 !important;
}

.tw-stroke-cyan-600\/0 {
  stroke: rgb(8 145 178 / 0) !important;
}

.tw-stroke-cyan-600\/10 {
  stroke: rgb(8 145 178 / 0.1) !important;
}

.tw-stroke-cyan-600\/100 {
  stroke: rgb(8 145 178 / 1) !important;
}

.tw-stroke-cyan-600\/15 {
  stroke: rgb(8 145 178 / 0.15) !important;
}

.tw-stroke-cyan-600\/20 {
  stroke: rgb(8 145 178 / 0.2) !important;
}

.tw-stroke-cyan-600\/25 {
  stroke: rgb(8 145 178 / 0.25) !important;
}

.tw-stroke-cyan-600\/30 {
  stroke: rgb(8 145 178 / 0.3) !important;
}

.tw-stroke-cyan-600\/35 {
  stroke: rgb(8 145 178 / 0.35) !important;
}

.tw-stroke-cyan-600\/40 {
  stroke: rgb(8 145 178 / 0.4) !important;
}

.tw-stroke-cyan-600\/45 {
  stroke: rgb(8 145 178 / 0.45) !important;
}

.tw-stroke-cyan-600\/5 {
  stroke: rgb(8 145 178 / 0.05) !important;
}

.tw-stroke-cyan-600\/50 {
  stroke: rgb(8 145 178 / 0.5) !important;
}

.tw-stroke-cyan-600\/55 {
  stroke: rgb(8 145 178 / 0.55) !important;
}

.tw-stroke-cyan-600\/60 {
  stroke: rgb(8 145 178 / 0.6) !important;
}

.tw-stroke-cyan-600\/65 {
  stroke: rgb(8 145 178 / 0.65) !important;
}

.tw-stroke-cyan-600\/70 {
  stroke: rgb(8 145 178 / 0.7) !important;
}

.tw-stroke-cyan-600\/75 {
  stroke: rgb(8 145 178 / 0.75) !important;
}

.tw-stroke-cyan-600\/80 {
  stroke: rgb(8 145 178 / 0.8) !important;
}

.tw-stroke-cyan-600\/85 {
  stroke: rgb(8 145 178 / 0.85) !important;
}

.tw-stroke-cyan-600\/90 {
  stroke: rgb(8 145 178 / 0.9) !important;
}

.tw-stroke-cyan-600\/95 {
  stroke: rgb(8 145 178 / 0.95) !important;
}

.tw-stroke-cyan-700 {
  stroke: #0e7490 !important;
}

.tw-stroke-cyan-700\/0 {
  stroke: rgb(14 116 144 / 0) !important;
}

.tw-stroke-cyan-700\/10 {
  stroke: rgb(14 116 144 / 0.1) !important;
}

.tw-stroke-cyan-700\/100 {
  stroke: rgb(14 116 144 / 1) !important;
}

.tw-stroke-cyan-700\/15 {
  stroke: rgb(14 116 144 / 0.15) !important;
}

.tw-stroke-cyan-700\/20 {
  stroke: rgb(14 116 144 / 0.2) !important;
}

.tw-stroke-cyan-700\/25 {
  stroke: rgb(14 116 144 / 0.25) !important;
}

.tw-stroke-cyan-700\/30 {
  stroke: rgb(14 116 144 / 0.3) !important;
}

.tw-stroke-cyan-700\/35 {
  stroke: rgb(14 116 144 / 0.35) !important;
}

.tw-stroke-cyan-700\/40 {
  stroke: rgb(14 116 144 / 0.4) !important;
}

.tw-stroke-cyan-700\/45 {
  stroke: rgb(14 116 144 / 0.45) !important;
}

.tw-stroke-cyan-700\/5 {
  stroke: rgb(14 116 144 / 0.05) !important;
}

.tw-stroke-cyan-700\/50 {
  stroke: rgb(14 116 144 / 0.5) !important;
}

.tw-stroke-cyan-700\/55 {
  stroke: rgb(14 116 144 / 0.55) !important;
}

.tw-stroke-cyan-700\/60 {
  stroke: rgb(14 116 144 / 0.6) !important;
}

.tw-stroke-cyan-700\/65 {
  stroke: rgb(14 116 144 / 0.65) !important;
}

.tw-stroke-cyan-700\/70 {
  stroke: rgb(14 116 144 / 0.7) !important;
}

.tw-stroke-cyan-700\/75 {
  stroke: rgb(14 116 144 / 0.75) !important;
}

.tw-stroke-cyan-700\/80 {
  stroke: rgb(14 116 144 / 0.8) !important;
}

.tw-stroke-cyan-700\/85 {
  stroke: rgb(14 116 144 / 0.85) !important;
}

.tw-stroke-cyan-700\/90 {
  stroke: rgb(14 116 144 / 0.9) !important;
}

.tw-stroke-cyan-700\/95 {
  stroke: rgb(14 116 144 / 0.95) !important;
}

.tw-stroke-cyan-800 {
  stroke: #155e75 !important;
}

.tw-stroke-cyan-800\/0 {
  stroke: rgb(21 94 117 / 0) !important;
}

.tw-stroke-cyan-800\/10 {
  stroke: rgb(21 94 117 / 0.1) !important;
}

.tw-stroke-cyan-800\/100 {
  stroke: rgb(21 94 117 / 1) !important;
}

.tw-stroke-cyan-800\/15 {
  stroke: rgb(21 94 117 / 0.15) !important;
}

.tw-stroke-cyan-800\/20 {
  stroke: rgb(21 94 117 / 0.2) !important;
}

.tw-stroke-cyan-800\/25 {
  stroke: rgb(21 94 117 / 0.25) !important;
}

.tw-stroke-cyan-800\/30 {
  stroke: rgb(21 94 117 / 0.3) !important;
}

.tw-stroke-cyan-800\/35 {
  stroke: rgb(21 94 117 / 0.35) !important;
}

.tw-stroke-cyan-800\/40 {
  stroke: rgb(21 94 117 / 0.4) !important;
}

.tw-stroke-cyan-800\/45 {
  stroke: rgb(21 94 117 / 0.45) !important;
}

.tw-stroke-cyan-800\/5 {
  stroke: rgb(21 94 117 / 0.05) !important;
}

.tw-stroke-cyan-800\/50 {
  stroke: rgb(21 94 117 / 0.5) !important;
}

.tw-stroke-cyan-800\/55 {
  stroke: rgb(21 94 117 / 0.55) !important;
}

.tw-stroke-cyan-800\/60 {
  stroke: rgb(21 94 117 / 0.6) !important;
}

.tw-stroke-cyan-800\/65 {
  stroke: rgb(21 94 117 / 0.65) !important;
}

.tw-stroke-cyan-800\/70 {
  stroke: rgb(21 94 117 / 0.7) !important;
}

.tw-stroke-cyan-800\/75 {
  stroke: rgb(21 94 117 / 0.75) !important;
}

.tw-stroke-cyan-800\/80 {
  stroke: rgb(21 94 117 / 0.8) !important;
}

.tw-stroke-cyan-800\/85 {
  stroke: rgb(21 94 117 / 0.85) !important;
}

.tw-stroke-cyan-800\/90 {
  stroke: rgb(21 94 117 / 0.9) !important;
}

.tw-stroke-cyan-800\/95 {
  stroke: rgb(21 94 117 / 0.95) !important;
}

.tw-stroke-cyan-900 {
  stroke: #164e63 !important;
}

.tw-stroke-cyan-900\/0 {
  stroke: rgb(22 78 99 / 0) !important;
}

.tw-stroke-cyan-900\/10 {
  stroke: rgb(22 78 99 / 0.1) !important;
}

.tw-stroke-cyan-900\/100 {
  stroke: rgb(22 78 99 / 1) !important;
}

.tw-stroke-cyan-900\/15 {
  stroke: rgb(22 78 99 / 0.15) !important;
}

.tw-stroke-cyan-900\/20 {
  stroke: rgb(22 78 99 / 0.2) !important;
}

.tw-stroke-cyan-900\/25 {
  stroke: rgb(22 78 99 / 0.25) !important;
}

.tw-stroke-cyan-900\/30 {
  stroke: rgb(22 78 99 / 0.3) !important;
}

.tw-stroke-cyan-900\/35 {
  stroke: rgb(22 78 99 / 0.35) !important;
}

.tw-stroke-cyan-900\/40 {
  stroke: rgb(22 78 99 / 0.4) !important;
}

.tw-stroke-cyan-900\/45 {
  stroke: rgb(22 78 99 / 0.45) !important;
}

.tw-stroke-cyan-900\/5 {
  stroke: rgb(22 78 99 / 0.05) !important;
}

.tw-stroke-cyan-900\/50 {
  stroke: rgb(22 78 99 / 0.5) !important;
}

.tw-stroke-cyan-900\/55 {
  stroke: rgb(22 78 99 / 0.55) !important;
}

.tw-stroke-cyan-900\/60 {
  stroke: rgb(22 78 99 / 0.6) !important;
}

.tw-stroke-cyan-900\/65 {
  stroke: rgb(22 78 99 / 0.65) !important;
}

.tw-stroke-cyan-900\/70 {
  stroke: rgb(22 78 99 / 0.7) !important;
}

.tw-stroke-cyan-900\/75 {
  stroke: rgb(22 78 99 / 0.75) !important;
}

.tw-stroke-cyan-900\/80 {
  stroke: rgb(22 78 99 / 0.8) !important;
}

.tw-stroke-cyan-900\/85 {
  stroke: rgb(22 78 99 / 0.85) !important;
}

.tw-stroke-cyan-900\/90 {
  stroke: rgb(22 78 99 / 0.9) !important;
}

.tw-stroke-cyan-900\/95 {
  stroke: rgb(22 78 99 / 0.95) !important;
}

.tw-stroke-cyan-950 {
  stroke: #083344 !important;
}

.tw-stroke-cyan-950\/0 {
  stroke: rgb(8 51 68 / 0) !important;
}

.tw-stroke-cyan-950\/10 {
  stroke: rgb(8 51 68 / 0.1) !important;
}

.tw-stroke-cyan-950\/100 {
  stroke: rgb(8 51 68 / 1) !important;
}

.tw-stroke-cyan-950\/15 {
  stroke: rgb(8 51 68 / 0.15) !important;
}

.tw-stroke-cyan-950\/20 {
  stroke: rgb(8 51 68 / 0.2) !important;
}

.tw-stroke-cyan-950\/25 {
  stroke: rgb(8 51 68 / 0.25) !important;
}

.tw-stroke-cyan-950\/30 {
  stroke: rgb(8 51 68 / 0.3) !important;
}

.tw-stroke-cyan-950\/35 {
  stroke: rgb(8 51 68 / 0.35) !important;
}

.tw-stroke-cyan-950\/40 {
  stroke: rgb(8 51 68 / 0.4) !important;
}

.tw-stroke-cyan-950\/45 {
  stroke: rgb(8 51 68 / 0.45) !important;
}

.tw-stroke-cyan-950\/5 {
  stroke: rgb(8 51 68 / 0.05) !important;
}

.tw-stroke-cyan-950\/50 {
  stroke: rgb(8 51 68 / 0.5) !important;
}

.tw-stroke-cyan-950\/55 {
  stroke: rgb(8 51 68 / 0.55) !important;
}

.tw-stroke-cyan-950\/60 {
  stroke: rgb(8 51 68 / 0.6) !important;
}

.tw-stroke-cyan-950\/65 {
  stroke: rgb(8 51 68 / 0.65) !important;
}

.tw-stroke-cyan-950\/70 {
  stroke: rgb(8 51 68 / 0.7) !important;
}

.tw-stroke-cyan-950\/75 {
  stroke: rgb(8 51 68 / 0.75) !important;
}

.tw-stroke-cyan-950\/80 {
  stroke: rgb(8 51 68 / 0.8) !important;
}

.tw-stroke-cyan-950\/85 {
  stroke: rgb(8 51 68 / 0.85) !important;
}

.tw-stroke-cyan-950\/90 {
  stroke: rgb(8 51 68 / 0.9) !important;
}

.tw-stroke-cyan-950\/95 {
  stroke: rgb(8 51 68 / 0.95) !important;
}

.tw-stroke-emerald-100 {
  stroke: #d1fae5 !important;
}

.tw-stroke-emerald-100\/0 {
  stroke: rgb(209 250 229 / 0) !important;
}

.tw-stroke-emerald-100\/10 {
  stroke: rgb(209 250 229 / 0.1) !important;
}

.tw-stroke-emerald-100\/100 {
  stroke: rgb(209 250 229 / 1) !important;
}

.tw-stroke-emerald-100\/15 {
  stroke: rgb(209 250 229 / 0.15) !important;
}

.tw-stroke-emerald-100\/20 {
  stroke: rgb(209 250 229 / 0.2) !important;
}

.tw-stroke-emerald-100\/25 {
  stroke: rgb(209 250 229 / 0.25) !important;
}

.tw-stroke-emerald-100\/30 {
  stroke: rgb(209 250 229 / 0.3) !important;
}

.tw-stroke-emerald-100\/35 {
  stroke: rgb(209 250 229 / 0.35) !important;
}

.tw-stroke-emerald-100\/40 {
  stroke: rgb(209 250 229 / 0.4) !important;
}

.tw-stroke-emerald-100\/45 {
  stroke: rgb(209 250 229 / 0.45) !important;
}

.tw-stroke-emerald-100\/5 {
  stroke: rgb(209 250 229 / 0.05) !important;
}

.tw-stroke-emerald-100\/50 {
  stroke: rgb(209 250 229 / 0.5) !important;
}

.tw-stroke-emerald-100\/55 {
  stroke: rgb(209 250 229 / 0.55) !important;
}

.tw-stroke-emerald-100\/60 {
  stroke: rgb(209 250 229 / 0.6) !important;
}

.tw-stroke-emerald-100\/65 {
  stroke: rgb(209 250 229 / 0.65) !important;
}

.tw-stroke-emerald-100\/70 {
  stroke: rgb(209 250 229 / 0.7) !important;
}

.tw-stroke-emerald-100\/75 {
  stroke: rgb(209 250 229 / 0.75) !important;
}

.tw-stroke-emerald-100\/80 {
  stroke: rgb(209 250 229 / 0.8) !important;
}

.tw-stroke-emerald-100\/85 {
  stroke: rgb(209 250 229 / 0.85) !important;
}

.tw-stroke-emerald-100\/90 {
  stroke: rgb(209 250 229 / 0.9) !important;
}

.tw-stroke-emerald-100\/95 {
  stroke: rgb(209 250 229 / 0.95) !important;
}

.tw-stroke-emerald-200 {
  stroke: #a7f3d0 !important;
}

.tw-stroke-emerald-200\/0 {
  stroke: rgb(167 243 208 / 0) !important;
}

.tw-stroke-emerald-200\/10 {
  stroke: rgb(167 243 208 / 0.1) !important;
}

.tw-stroke-emerald-200\/100 {
  stroke: rgb(167 243 208 / 1) !important;
}

.tw-stroke-emerald-200\/15 {
  stroke: rgb(167 243 208 / 0.15) !important;
}

.tw-stroke-emerald-200\/20 {
  stroke: rgb(167 243 208 / 0.2) !important;
}

.tw-stroke-emerald-200\/25 {
  stroke: rgb(167 243 208 / 0.25) !important;
}

.tw-stroke-emerald-200\/30 {
  stroke: rgb(167 243 208 / 0.3) !important;
}

.tw-stroke-emerald-200\/35 {
  stroke: rgb(167 243 208 / 0.35) !important;
}

.tw-stroke-emerald-200\/40 {
  stroke: rgb(167 243 208 / 0.4) !important;
}

.tw-stroke-emerald-200\/45 {
  stroke: rgb(167 243 208 / 0.45) !important;
}

.tw-stroke-emerald-200\/5 {
  stroke: rgb(167 243 208 / 0.05) !important;
}

.tw-stroke-emerald-200\/50 {
  stroke: rgb(167 243 208 / 0.5) !important;
}

.tw-stroke-emerald-200\/55 {
  stroke: rgb(167 243 208 / 0.55) !important;
}

.tw-stroke-emerald-200\/60 {
  stroke: rgb(167 243 208 / 0.6) !important;
}

.tw-stroke-emerald-200\/65 {
  stroke: rgb(167 243 208 / 0.65) !important;
}

.tw-stroke-emerald-200\/70 {
  stroke: rgb(167 243 208 / 0.7) !important;
}

.tw-stroke-emerald-200\/75 {
  stroke: rgb(167 243 208 / 0.75) !important;
}

.tw-stroke-emerald-200\/80 {
  stroke: rgb(167 243 208 / 0.8) !important;
}

.tw-stroke-emerald-200\/85 {
  stroke: rgb(167 243 208 / 0.85) !important;
}

.tw-stroke-emerald-200\/90 {
  stroke: rgb(167 243 208 / 0.9) !important;
}

.tw-stroke-emerald-200\/95 {
  stroke: rgb(167 243 208 / 0.95) !important;
}

.tw-stroke-emerald-300 {
  stroke: #6ee7b7 !important;
}

.tw-stroke-emerald-300\/0 {
  stroke: rgb(110 231 183 / 0) !important;
}

.tw-stroke-emerald-300\/10 {
  stroke: rgb(110 231 183 / 0.1) !important;
}

.tw-stroke-emerald-300\/100 {
  stroke: rgb(110 231 183 / 1) !important;
}

.tw-stroke-emerald-300\/15 {
  stroke: rgb(110 231 183 / 0.15) !important;
}

.tw-stroke-emerald-300\/20 {
  stroke: rgb(110 231 183 / 0.2) !important;
}

.tw-stroke-emerald-300\/25 {
  stroke: rgb(110 231 183 / 0.25) !important;
}

.tw-stroke-emerald-300\/30 {
  stroke: rgb(110 231 183 / 0.3) !important;
}

.tw-stroke-emerald-300\/35 {
  stroke: rgb(110 231 183 / 0.35) !important;
}

.tw-stroke-emerald-300\/40 {
  stroke: rgb(110 231 183 / 0.4) !important;
}

.tw-stroke-emerald-300\/45 {
  stroke: rgb(110 231 183 / 0.45) !important;
}

.tw-stroke-emerald-300\/5 {
  stroke: rgb(110 231 183 / 0.05) !important;
}

.tw-stroke-emerald-300\/50 {
  stroke: rgb(110 231 183 / 0.5) !important;
}

.tw-stroke-emerald-300\/55 {
  stroke: rgb(110 231 183 / 0.55) !important;
}

.tw-stroke-emerald-300\/60 {
  stroke: rgb(110 231 183 / 0.6) !important;
}

.tw-stroke-emerald-300\/65 {
  stroke: rgb(110 231 183 / 0.65) !important;
}

.tw-stroke-emerald-300\/70 {
  stroke: rgb(110 231 183 / 0.7) !important;
}

.tw-stroke-emerald-300\/75 {
  stroke: rgb(110 231 183 / 0.75) !important;
}

.tw-stroke-emerald-300\/80 {
  stroke: rgb(110 231 183 / 0.8) !important;
}

.tw-stroke-emerald-300\/85 {
  stroke: rgb(110 231 183 / 0.85) !important;
}

.tw-stroke-emerald-300\/90 {
  stroke: rgb(110 231 183 / 0.9) !important;
}

.tw-stroke-emerald-300\/95 {
  stroke: rgb(110 231 183 / 0.95) !important;
}

.tw-stroke-emerald-400 {
  stroke: #34d399 !important;
}

.tw-stroke-emerald-400\/0 {
  stroke: rgb(52 211 153 / 0) !important;
}

.tw-stroke-emerald-400\/10 {
  stroke: rgb(52 211 153 / 0.1) !important;
}

.tw-stroke-emerald-400\/100 {
  stroke: rgb(52 211 153 / 1) !important;
}

.tw-stroke-emerald-400\/15 {
  stroke: rgb(52 211 153 / 0.15) !important;
}

.tw-stroke-emerald-400\/20 {
  stroke: rgb(52 211 153 / 0.2) !important;
}

.tw-stroke-emerald-400\/25 {
  stroke: rgb(52 211 153 / 0.25) !important;
}

.tw-stroke-emerald-400\/30 {
  stroke: rgb(52 211 153 / 0.3) !important;
}

.tw-stroke-emerald-400\/35 {
  stroke: rgb(52 211 153 / 0.35) !important;
}

.tw-stroke-emerald-400\/40 {
  stroke: rgb(52 211 153 / 0.4) !important;
}

.tw-stroke-emerald-400\/45 {
  stroke: rgb(52 211 153 / 0.45) !important;
}

.tw-stroke-emerald-400\/5 {
  stroke: rgb(52 211 153 / 0.05) !important;
}

.tw-stroke-emerald-400\/50 {
  stroke: rgb(52 211 153 / 0.5) !important;
}

.tw-stroke-emerald-400\/55 {
  stroke: rgb(52 211 153 / 0.55) !important;
}

.tw-stroke-emerald-400\/60 {
  stroke: rgb(52 211 153 / 0.6) !important;
}

.tw-stroke-emerald-400\/65 {
  stroke: rgb(52 211 153 / 0.65) !important;
}

.tw-stroke-emerald-400\/70 {
  stroke: rgb(52 211 153 / 0.7) !important;
}

.tw-stroke-emerald-400\/75 {
  stroke: rgb(52 211 153 / 0.75) !important;
}

.tw-stroke-emerald-400\/80 {
  stroke: rgb(52 211 153 / 0.8) !important;
}

.tw-stroke-emerald-400\/85 {
  stroke: rgb(52 211 153 / 0.85) !important;
}

.tw-stroke-emerald-400\/90 {
  stroke: rgb(52 211 153 / 0.9) !important;
}

.tw-stroke-emerald-400\/95 {
  stroke: rgb(52 211 153 / 0.95) !important;
}

.tw-stroke-emerald-50 {
  stroke: #ecfdf5 !important;
}

.tw-stroke-emerald-50\/0 {
  stroke: rgb(236 253 245 / 0) !important;
}

.tw-stroke-emerald-50\/10 {
  stroke: rgb(236 253 245 / 0.1) !important;
}

.tw-stroke-emerald-50\/100 {
  stroke: rgb(236 253 245 / 1) !important;
}

.tw-stroke-emerald-50\/15 {
  stroke: rgb(236 253 245 / 0.15) !important;
}

.tw-stroke-emerald-50\/20 {
  stroke: rgb(236 253 245 / 0.2) !important;
}

.tw-stroke-emerald-50\/25 {
  stroke: rgb(236 253 245 / 0.25) !important;
}

.tw-stroke-emerald-50\/30 {
  stroke: rgb(236 253 245 / 0.3) !important;
}

.tw-stroke-emerald-50\/35 {
  stroke: rgb(236 253 245 / 0.35) !important;
}

.tw-stroke-emerald-50\/40 {
  stroke: rgb(236 253 245 / 0.4) !important;
}

.tw-stroke-emerald-50\/45 {
  stroke: rgb(236 253 245 / 0.45) !important;
}

.tw-stroke-emerald-50\/5 {
  stroke: rgb(236 253 245 / 0.05) !important;
}

.tw-stroke-emerald-50\/50 {
  stroke: rgb(236 253 245 / 0.5) !important;
}

.tw-stroke-emerald-50\/55 {
  stroke: rgb(236 253 245 / 0.55) !important;
}

.tw-stroke-emerald-50\/60 {
  stroke: rgb(236 253 245 / 0.6) !important;
}

.tw-stroke-emerald-50\/65 {
  stroke: rgb(236 253 245 / 0.65) !important;
}

.tw-stroke-emerald-50\/70 {
  stroke: rgb(236 253 245 / 0.7) !important;
}

.tw-stroke-emerald-50\/75 {
  stroke: rgb(236 253 245 / 0.75) !important;
}

.tw-stroke-emerald-50\/80 {
  stroke: rgb(236 253 245 / 0.8) !important;
}

.tw-stroke-emerald-50\/85 {
  stroke: rgb(236 253 245 / 0.85) !important;
}

.tw-stroke-emerald-50\/90 {
  stroke: rgb(236 253 245 / 0.9) !important;
}

.tw-stroke-emerald-50\/95 {
  stroke: rgb(236 253 245 / 0.95) !important;
}

.tw-stroke-emerald-500 {
  stroke: #10b981 !important;
}

.tw-stroke-emerald-500\/0 {
  stroke: rgb(16 185 129 / 0) !important;
}

.tw-stroke-emerald-500\/10 {
  stroke: rgb(16 185 129 / 0.1) !important;
}

.tw-stroke-emerald-500\/100 {
  stroke: rgb(16 185 129 / 1) !important;
}

.tw-stroke-emerald-500\/15 {
  stroke: rgb(16 185 129 / 0.15) !important;
}

.tw-stroke-emerald-500\/20 {
  stroke: rgb(16 185 129 / 0.2) !important;
}

.tw-stroke-emerald-500\/25 {
  stroke: rgb(16 185 129 / 0.25) !important;
}

.tw-stroke-emerald-500\/30 {
  stroke: rgb(16 185 129 / 0.3) !important;
}

.tw-stroke-emerald-500\/35 {
  stroke: rgb(16 185 129 / 0.35) !important;
}

.tw-stroke-emerald-500\/40 {
  stroke: rgb(16 185 129 / 0.4) !important;
}

.tw-stroke-emerald-500\/45 {
  stroke: rgb(16 185 129 / 0.45) !important;
}

.tw-stroke-emerald-500\/5 {
  stroke: rgb(16 185 129 / 0.05) !important;
}

.tw-stroke-emerald-500\/50 {
  stroke: rgb(16 185 129 / 0.5) !important;
}

.tw-stroke-emerald-500\/55 {
  stroke: rgb(16 185 129 / 0.55) !important;
}

.tw-stroke-emerald-500\/60 {
  stroke: rgb(16 185 129 / 0.6) !important;
}

.tw-stroke-emerald-500\/65 {
  stroke: rgb(16 185 129 / 0.65) !important;
}

.tw-stroke-emerald-500\/70 {
  stroke: rgb(16 185 129 / 0.7) !important;
}

.tw-stroke-emerald-500\/75 {
  stroke: rgb(16 185 129 / 0.75) !important;
}

.tw-stroke-emerald-500\/80 {
  stroke: rgb(16 185 129 / 0.8) !important;
}

.tw-stroke-emerald-500\/85 {
  stroke: rgb(16 185 129 / 0.85) !important;
}

.tw-stroke-emerald-500\/90 {
  stroke: rgb(16 185 129 / 0.9) !important;
}

.tw-stroke-emerald-500\/95 {
  stroke: rgb(16 185 129 / 0.95) !important;
}

.tw-stroke-emerald-600 {
  stroke: #059669 !important;
}

.tw-stroke-emerald-600\/0 {
  stroke: rgb(5 150 105 / 0) !important;
}

.tw-stroke-emerald-600\/10 {
  stroke: rgb(5 150 105 / 0.1) !important;
}

.tw-stroke-emerald-600\/100 {
  stroke: rgb(5 150 105 / 1) !important;
}

.tw-stroke-emerald-600\/15 {
  stroke: rgb(5 150 105 / 0.15) !important;
}

.tw-stroke-emerald-600\/20 {
  stroke: rgb(5 150 105 / 0.2) !important;
}

.tw-stroke-emerald-600\/25 {
  stroke: rgb(5 150 105 / 0.25) !important;
}

.tw-stroke-emerald-600\/30 {
  stroke: rgb(5 150 105 / 0.3) !important;
}

.tw-stroke-emerald-600\/35 {
  stroke: rgb(5 150 105 / 0.35) !important;
}

.tw-stroke-emerald-600\/40 {
  stroke: rgb(5 150 105 / 0.4) !important;
}

.tw-stroke-emerald-600\/45 {
  stroke: rgb(5 150 105 / 0.45) !important;
}

.tw-stroke-emerald-600\/5 {
  stroke: rgb(5 150 105 / 0.05) !important;
}

.tw-stroke-emerald-600\/50 {
  stroke: rgb(5 150 105 / 0.5) !important;
}

.tw-stroke-emerald-600\/55 {
  stroke: rgb(5 150 105 / 0.55) !important;
}

.tw-stroke-emerald-600\/60 {
  stroke: rgb(5 150 105 / 0.6) !important;
}

.tw-stroke-emerald-600\/65 {
  stroke: rgb(5 150 105 / 0.65) !important;
}

.tw-stroke-emerald-600\/70 {
  stroke: rgb(5 150 105 / 0.7) !important;
}

.tw-stroke-emerald-600\/75 {
  stroke: rgb(5 150 105 / 0.75) !important;
}

.tw-stroke-emerald-600\/80 {
  stroke: rgb(5 150 105 / 0.8) !important;
}

.tw-stroke-emerald-600\/85 {
  stroke: rgb(5 150 105 / 0.85) !important;
}

.tw-stroke-emerald-600\/90 {
  stroke: rgb(5 150 105 / 0.9) !important;
}

.tw-stroke-emerald-600\/95 {
  stroke: rgb(5 150 105 / 0.95) !important;
}

.tw-stroke-emerald-700 {
  stroke: #047857 !important;
}

.tw-stroke-emerald-700\/0 {
  stroke: rgb(4 120 87 / 0) !important;
}

.tw-stroke-emerald-700\/10 {
  stroke: rgb(4 120 87 / 0.1) !important;
}

.tw-stroke-emerald-700\/100 {
  stroke: rgb(4 120 87 / 1) !important;
}

.tw-stroke-emerald-700\/15 {
  stroke: rgb(4 120 87 / 0.15) !important;
}

.tw-stroke-emerald-700\/20 {
  stroke: rgb(4 120 87 / 0.2) !important;
}

.tw-stroke-emerald-700\/25 {
  stroke: rgb(4 120 87 / 0.25) !important;
}

.tw-stroke-emerald-700\/30 {
  stroke: rgb(4 120 87 / 0.3) !important;
}

.tw-stroke-emerald-700\/35 {
  stroke: rgb(4 120 87 / 0.35) !important;
}

.tw-stroke-emerald-700\/40 {
  stroke: rgb(4 120 87 / 0.4) !important;
}

.tw-stroke-emerald-700\/45 {
  stroke: rgb(4 120 87 / 0.45) !important;
}

.tw-stroke-emerald-700\/5 {
  stroke: rgb(4 120 87 / 0.05) !important;
}

.tw-stroke-emerald-700\/50 {
  stroke: rgb(4 120 87 / 0.5) !important;
}

.tw-stroke-emerald-700\/55 {
  stroke: rgb(4 120 87 / 0.55) !important;
}

.tw-stroke-emerald-700\/60 {
  stroke: rgb(4 120 87 / 0.6) !important;
}

.tw-stroke-emerald-700\/65 {
  stroke: rgb(4 120 87 / 0.65) !important;
}

.tw-stroke-emerald-700\/70 {
  stroke: rgb(4 120 87 / 0.7) !important;
}

.tw-stroke-emerald-700\/75 {
  stroke: rgb(4 120 87 / 0.75) !important;
}

.tw-stroke-emerald-700\/80 {
  stroke: rgb(4 120 87 / 0.8) !important;
}

.tw-stroke-emerald-700\/85 {
  stroke: rgb(4 120 87 / 0.85) !important;
}

.tw-stroke-emerald-700\/90 {
  stroke: rgb(4 120 87 / 0.9) !important;
}

.tw-stroke-emerald-700\/95 {
  stroke: rgb(4 120 87 / 0.95) !important;
}

.tw-stroke-emerald-800 {
  stroke: #065f46 !important;
}

.tw-stroke-emerald-800\/0 {
  stroke: rgb(6 95 70 / 0) !important;
}

.tw-stroke-emerald-800\/10 {
  stroke: rgb(6 95 70 / 0.1) !important;
}

.tw-stroke-emerald-800\/100 {
  stroke: rgb(6 95 70 / 1) !important;
}

.tw-stroke-emerald-800\/15 {
  stroke: rgb(6 95 70 / 0.15) !important;
}

.tw-stroke-emerald-800\/20 {
  stroke: rgb(6 95 70 / 0.2) !important;
}

.tw-stroke-emerald-800\/25 {
  stroke: rgb(6 95 70 / 0.25) !important;
}

.tw-stroke-emerald-800\/30 {
  stroke: rgb(6 95 70 / 0.3) !important;
}

.tw-stroke-emerald-800\/35 {
  stroke: rgb(6 95 70 / 0.35) !important;
}

.tw-stroke-emerald-800\/40 {
  stroke: rgb(6 95 70 / 0.4) !important;
}

.tw-stroke-emerald-800\/45 {
  stroke: rgb(6 95 70 / 0.45) !important;
}

.tw-stroke-emerald-800\/5 {
  stroke: rgb(6 95 70 / 0.05) !important;
}

.tw-stroke-emerald-800\/50 {
  stroke: rgb(6 95 70 / 0.5) !important;
}

.tw-stroke-emerald-800\/55 {
  stroke: rgb(6 95 70 / 0.55) !important;
}

.tw-stroke-emerald-800\/60 {
  stroke: rgb(6 95 70 / 0.6) !important;
}

.tw-stroke-emerald-800\/65 {
  stroke: rgb(6 95 70 / 0.65) !important;
}

.tw-stroke-emerald-800\/70 {
  stroke: rgb(6 95 70 / 0.7) !important;
}

.tw-stroke-emerald-800\/75 {
  stroke: rgb(6 95 70 / 0.75) !important;
}

.tw-stroke-emerald-800\/80 {
  stroke: rgb(6 95 70 / 0.8) !important;
}

.tw-stroke-emerald-800\/85 {
  stroke: rgb(6 95 70 / 0.85) !important;
}

.tw-stroke-emerald-800\/90 {
  stroke: rgb(6 95 70 / 0.9) !important;
}

.tw-stroke-emerald-800\/95 {
  stroke: rgb(6 95 70 / 0.95) !important;
}

.tw-stroke-emerald-900 {
  stroke: #064e3b !important;
}

.tw-stroke-emerald-900\/0 {
  stroke: rgb(6 78 59 / 0) !important;
}

.tw-stroke-emerald-900\/10 {
  stroke: rgb(6 78 59 / 0.1) !important;
}

.tw-stroke-emerald-900\/100 {
  stroke: rgb(6 78 59 / 1) !important;
}

.tw-stroke-emerald-900\/15 {
  stroke: rgb(6 78 59 / 0.15) !important;
}

.tw-stroke-emerald-900\/20 {
  stroke: rgb(6 78 59 / 0.2) !important;
}

.tw-stroke-emerald-900\/25 {
  stroke: rgb(6 78 59 / 0.25) !important;
}

.tw-stroke-emerald-900\/30 {
  stroke: rgb(6 78 59 / 0.3) !important;
}

.tw-stroke-emerald-900\/35 {
  stroke: rgb(6 78 59 / 0.35) !important;
}

.tw-stroke-emerald-900\/40 {
  stroke: rgb(6 78 59 / 0.4) !important;
}

.tw-stroke-emerald-900\/45 {
  stroke: rgb(6 78 59 / 0.45) !important;
}

.tw-stroke-emerald-900\/5 {
  stroke: rgb(6 78 59 / 0.05) !important;
}

.tw-stroke-emerald-900\/50 {
  stroke: rgb(6 78 59 / 0.5) !important;
}

.tw-stroke-emerald-900\/55 {
  stroke: rgb(6 78 59 / 0.55) !important;
}

.tw-stroke-emerald-900\/60 {
  stroke: rgb(6 78 59 / 0.6) !important;
}

.tw-stroke-emerald-900\/65 {
  stroke: rgb(6 78 59 / 0.65) !important;
}

.tw-stroke-emerald-900\/70 {
  stroke: rgb(6 78 59 / 0.7) !important;
}

.tw-stroke-emerald-900\/75 {
  stroke: rgb(6 78 59 / 0.75) !important;
}

.tw-stroke-emerald-900\/80 {
  stroke: rgb(6 78 59 / 0.8) !important;
}

.tw-stroke-emerald-900\/85 {
  stroke: rgb(6 78 59 / 0.85) !important;
}

.tw-stroke-emerald-900\/90 {
  stroke: rgb(6 78 59 / 0.9) !important;
}

.tw-stroke-emerald-900\/95 {
  stroke: rgb(6 78 59 / 0.95) !important;
}

.tw-stroke-emerald-950 {
  stroke: #022c22 !important;
}

.tw-stroke-emerald-950\/0 {
  stroke: rgb(2 44 34 / 0) !important;
}

.tw-stroke-emerald-950\/10 {
  stroke: rgb(2 44 34 / 0.1) !important;
}

.tw-stroke-emerald-950\/100 {
  stroke: rgb(2 44 34 / 1) !important;
}

.tw-stroke-emerald-950\/15 {
  stroke: rgb(2 44 34 / 0.15) !important;
}

.tw-stroke-emerald-950\/20 {
  stroke: rgb(2 44 34 / 0.2) !important;
}

.tw-stroke-emerald-950\/25 {
  stroke: rgb(2 44 34 / 0.25) !important;
}

.tw-stroke-emerald-950\/30 {
  stroke: rgb(2 44 34 / 0.3) !important;
}

.tw-stroke-emerald-950\/35 {
  stroke: rgb(2 44 34 / 0.35) !important;
}

.tw-stroke-emerald-950\/40 {
  stroke: rgb(2 44 34 / 0.4) !important;
}

.tw-stroke-emerald-950\/45 {
  stroke: rgb(2 44 34 / 0.45) !important;
}

.tw-stroke-emerald-950\/5 {
  stroke: rgb(2 44 34 / 0.05) !important;
}

.tw-stroke-emerald-950\/50 {
  stroke: rgb(2 44 34 / 0.5) !important;
}

.tw-stroke-emerald-950\/55 {
  stroke: rgb(2 44 34 / 0.55) !important;
}

.tw-stroke-emerald-950\/60 {
  stroke: rgb(2 44 34 / 0.6) !important;
}

.tw-stroke-emerald-950\/65 {
  stroke: rgb(2 44 34 / 0.65) !important;
}

.tw-stroke-emerald-950\/70 {
  stroke: rgb(2 44 34 / 0.7) !important;
}

.tw-stroke-emerald-950\/75 {
  stroke: rgb(2 44 34 / 0.75) !important;
}

.tw-stroke-emerald-950\/80 {
  stroke: rgb(2 44 34 / 0.8) !important;
}

.tw-stroke-emerald-950\/85 {
  stroke: rgb(2 44 34 / 0.85) !important;
}

.tw-stroke-emerald-950\/90 {
  stroke: rgb(2 44 34 / 0.9) !important;
}

.tw-stroke-emerald-950\/95 {
  stroke: rgb(2 44 34 / 0.95) !important;
}

.tw-stroke-fuchsia-100 {
  stroke: #fae8ff !important;
}

.tw-stroke-fuchsia-100\/0 {
  stroke: rgb(250 232 255 / 0) !important;
}

.tw-stroke-fuchsia-100\/10 {
  stroke: rgb(250 232 255 / 0.1) !important;
}

.tw-stroke-fuchsia-100\/100 {
  stroke: rgb(250 232 255 / 1) !important;
}

.tw-stroke-fuchsia-100\/15 {
  stroke: rgb(250 232 255 / 0.15) !important;
}

.tw-stroke-fuchsia-100\/20 {
  stroke: rgb(250 232 255 / 0.2) !important;
}

.tw-stroke-fuchsia-100\/25 {
  stroke: rgb(250 232 255 / 0.25) !important;
}

.tw-stroke-fuchsia-100\/30 {
  stroke: rgb(250 232 255 / 0.3) !important;
}

.tw-stroke-fuchsia-100\/35 {
  stroke: rgb(250 232 255 / 0.35) !important;
}

.tw-stroke-fuchsia-100\/40 {
  stroke: rgb(250 232 255 / 0.4) !important;
}

.tw-stroke-fuchsia-100\/45 {
  stroke: rgb(250 232 255 / 0.45) !important;
}

.tw-stroke-fuchsia-100\/5 {
  stroke: rgb(250 232 255 / 0.05) !important;
}

.tw-stroke-fuchsia-100\/50 {
  stroke: rgb(250 232 255 / 0.5) !important;
}

.tw-stroke-fuchsia-100\/55 {
  stroke: rgb(250 232 255 / 0.55) !important;
}

.tw-stroke-fuchsia-100\/60 {
  stroke: rgb(250 232 255 / 0.6) !important;
}

.tw-stroke-fuchsia-100\/65 {
  stroke: rgb(250 232 255 / 0.65) !important;
}

.tw-stroke-fuchsia-100\/70 {
  stroke: rgb(250 232 255 / 0.7) !important;
}

.tw-stroke-fuchsia-100\/75 {
  stroke: rgb(250 232 255 / 0.75) !important;
}

.tw-stroke-fuchsia-100\/80 {
  stroke: rgb(250 232 255 / 0.8) !important;
}

.tw-stroke-fuchsia-100\/85 {
  stroke: rgb(250 232 255 / 0.85) !important;
}

.tw-stroke-fuchsia-100\/90 {
  stroke: rgb(250 232 255 / 0.9) !important;
}

.tw-stroke-fuchsia-100\/95 {
  stroke: rgb(250 232 255 / 0.95) !important;
}

.tw-stroke-fuchsia-200 {
  stroke: #f5d0fe !important;
}

.tw-stroke-fuchsia-200\/0 {
  stroke: rgb(245 208 254 / 0) !important;
}

.tw-stroke-fuchsia-200\/10 {
  stroke: rgb(245 208 254 / 0.1) !important;
}

.tw-stroke-fuchsia-200\/100 {
  stroke: rgb(245 208 254 / 1) !important;
}

.tw-stroke-fuchsia-200\/15 {
  stroke: rgb(245 208 254 / 0.15) !important;
}

.tw-stroke-fuchsia-200\/20 {
  stroke: rgb(245 208 254 / 0.2) !important;
}

.tw-stroke-fuchsia-200\/25 {
  stroke: rgb(245 208 254 / 0.25) !important;
}

.tw-stroke-fuchsia-200\/30 {
  stroke: rgb(245 208 254 / 0.3) !important;
}

.tw-stroke-fuchsia-200\/35 {
  stroke: rgb(245 208 254 / 0.35) !important;
}

.tw-stroke-fuchsia-200\/40 {
  stroke: rgb(245 208 254 / 0.4) !important;
}

.tw-stroke-fuchsia-200\/45 {
  stroke: rgb(245 208 254 / 0.45) !important;
}

.tw-stroke-fuchsia-200\/5 {
  stroke: rgb(245 208 254 / 0.05) !important;
}

.tw-stroke-fuchsia-200\/50 {
  stroke: rgb(245 208 254 / 0.5) !important;
}

.tw-stroke-fuchsia-200\/55 {
  stroke: rgb(245 208 254 / 0.55) !important;
}

.tw-stroke-fuchsia-200\/60 {
  stroke: rgb(245 208 254 / 0.6) !important;
}

.tw-stroke-fuchsia-200\/65 {
  stroke: rgb(245 208 254 / 0.65) !important;
}

.tw-stroke-fuchsia-200\/70 {
  stroke: rgb(245 208 254 / 0.7) !important;
}

.tw-stroke-fuchsia-200\/75 {
  stroke: rgb(245 208 254 / 0.75) !important;
}

.tw-stroke-fuchsia-200\/80 {
  stroke: rgb(245 208 254 / 0.8) !important;
}

.tw-stroke-fuchsia-200\/85 {
  stroke: rgb(245 208 254 / 0.85) !important;
}

.tw-stroke-fuchsia-200\/90 {
  stroke: rgb(245 208 254 / 0.9) !important;
}

.tw-stroke-fuchsia-200\/95 {
  stroke: rgb(245 208 254 / 0.95) !important;
}

.tw-stroke-fuchsia-300 {
  stroke: #f0abfc !important;
}

.tw-stroke-fuchsia-300\/0 {
  stroke: rgb(240 171 252 / 0) !important;
}

.tw-stroke-fuchsia-300\/10 {
  stroke: rgb(240 171 252 / 0.1) !important;
}

.tw-stroke-fuchsia-300\/100 {
  stroke: rgb(240 171 252 / 1) !important;
}

.tw-stroke-fuchsia-300\/15 {
  stroke: rgb(240 171 252 / 0.15) !important;
}

.tw-stroke-fuchsia-300\/20 {
  stroke: rgb(240 171 252 / 0.2) !important;
}

.tw-stroke-fuchsia-300\/25 {
  stroke: rgb(240 171 252 / 0.25) !important;
}

.tw-stroke-fuchsia-300\/30 {
  stroke: rgb(240 171 252 / 0.3) !important;
}

.tw-stroke-fuchsia-300\/35 {
  stroke: rgb(240 171 252 / 0.35) !important;
}

.tw-stroke-fuchsia-300\/40 {
  stroke: rgb(240 171 252 / 0.4) !important;
}

.tw-stroke-fuchsia-300\/45 {
  stroke: rgb(240 171 252 / 0.45) !important;
}

.tw-stroke-fuchsia-300\/5 {
  stroke: rgb(240 171 252 / 0.05) !important;
}

.tw-stroke-fuchsia-300\/50 {
  stroke: rgb(240 171 252 / 0.5) !important;
}

.tw-stroke-fuchsia-300\/55 {
  stroke: rgb(240 171 252 / 0.55) !important;
}

.tw-stroke-fuchsia-300\/60 {
  stroke: rgb(240 171 252 / 0.6) !important;
}

.tw-stroke-fuchsia-300\/65 {
  stroke: rgb(240 171 252 / 0.65) !important;
}

.tw-stroke-fuchsia-300\/70 {
  stroke: rgb(240 171 252 / 0.7) !important;
}

.tw-stroke-fuchsia-300\/75 {
  stroke: rgb(240 171 252 / 0.75) !important;
}

.tw-stroke-fuchsia-300\/80 {
  stroke: rgb(240 171 252 / 0.8) !important;
}

.tw-stroke-fuchsia-300\/85 {
  stroke: rgb(240 171 252 / 0.85) !important;
}

.tw-stroke-fuchsia-300\/90 {
  stroke: rgb(240 171 252 / 0.9) !important;
}

.tw-stroke-fuchsia-300\/95 {
  stroke: rgb(240 171 252 / 0.95) !important;
}

.tw-stroke-fuchsia-400 {
  stroke: #e879f9 !important;
}

.tw-stroke-fuchsia-400\/0 {
  stroke: rgb(232 121 249 / 0) !important;
}

.tw-stroke-fuchsia-400\/10 {
  stroke: rgb(232 121 249 / 0.1) !important;
}

.tw-stroke-fuchsia-400\/100 {
  stroke: rgb(232 121 249 / 1) !important;
}

.tw-stroke-fuchsia-400\/15 {
  stroke: rgb(232 121 249 / 0.15) !important;
}

.tw-stroke-fuchsia-400\/20 {
  stroke: rgb(232 121 249 / 0.2) !important;
}

.tw-stroke-fuchsia-400\/25 {
  stroke: rgb(232 121 249 / 0.25) !important;
}

.tw-stroke-fuchsia-400\/30 {
  stroke: rgb(232 121 249 / 0.3) !important;
}

.tw-stroke-fuchsia-400\/35 {
  stroke: rgb(232 121 249 / 0.35) !important;
}

.tw-stroke-fuchsia-400\/40 {
  stroke: rgb(232 121 249 / 0.4) !important;
}

.tw-stroke-fuchsia-400\/45 {
  stroke: rgb(232 121 249 / 0.45) !important;
}

.tw-stroke-fuchsia-400\/5 {
  stroke: rgb(232 121 249 / 0.05) !important;
}

.tw-stroke-fuchsia-400\/50 {
  stroke: rgb(232 121 249 / 0.5) !important;
}

.tw-stroke-fuchsia-400\/55 {
  stroke: rgb(232 121 249 / 0.55) !important;
}

.tw-stroke-fuchsia-400\/60 {
  stroke: rgb(232 121 249 / 0.6) !important;
}

.tw-stroke-fuchsia-400\/65 {
  stroke: rgb(232 121 249 / 0.65) !important;
}

.tw-stroke-fuchsia-400\/70 {
  stroke: rgb(232 121 249 / 0.7) !important;
}

.tw-stroke-fuchsia-400\/75 {
  stroke: rgb(232 121 249 / 0.75) !important;
}

.tw-stroke-fuchsia-400\/80 {
  stroke: rgb(232 121 249 / 0.8) !important;
}

.tw-stroke-fuchsia-400\/85 {
  stroke: rgb(232 121 249 / 0.85) !important;
}

.tw-stroke-fuchsia-400\/90 {
  stroke: rgb(232 121 249 / 0.9) !important;
}

.tw-stroke-fuchsia-400\/95 {
  stroke: rgb(232 121 249 / 0.95) !important;
}

.tw-stroke-fuchsia-50 {
  stroke: #fdf4ff !important;
}

.tw-stroke-fuchsia-50\/0 {
  stroke: rgb(253 244 255 / 0) !important;
}

.tw-stroke-fuchsia-50\/10 {
  stroke: rgb(253 244 255 / 0.1) !important;
}

.tw-stroke-fuchsia-50\/100 {
  stroke: rgb(253 244 255 / 1) !important;
}

.tw-stroke-fuchsia-50\/15 {
  stroke: rgb(253 244 255 / 0.15) !important;
}

.tw-stroke-fuchsia-50\/20 {
  stroke: rgb(253 244 255 / 0.2) !important;
}

.tw-stroke-fuchsia-50\/25 {
  stroke: rgb(253 244 255 / 0.25) !important;
}

.tw-stroke-fuchsia-50\/30 {
  stroke: rgb(253 244 255 / 0.3) !important;
}

.tw-stroke-fuchsia-50\/35 {
  stroke: rgb(253 244 255 / 0.35) !important;
}

.tw-stroke-fuchsia-50\/40 {
  stroke: rgb(253 244 255 / 0.4) !important;
}

.tw-stroke-fuchsia-50\/45 {
  stroke: rgb(253 244 255 / 0.45) !important;
}

.tw-stroke-fuchsia-50\/5 {
  stroke: rgb(253 244 255 / 0.05) !important;
}

.tw-stroke-fuchsia-50\/50 {
  stroke: rgb(253 244 255 / 0.5) !important;
}

.tw-stroke-fuchsia-50\/55 {
  stroke: rgb(253 244 255 / 0.55) !important;
}

.tw-stroke-fuchsia-50\/60 {
  stroke: rgb(253 244 255 / 0.6) !important;
}

.tw-stroke-fuchsia-50\/65 {
  stroke: rgb(253 244 255 / 0.65) !important;
}

.tw-stroke-fuchsia-50\/70 {
  stroke: rgb(253 244 255 / 0.7) !important;
}

.tw-stroke-fuchsia-50\/75 {
  stroke: rgb(253 244 255 / 0.75) !important;
}

.tw-stroke-fuchsia-50\/80 {
  stroke: rgb(253 244 255 / 0.8) !important;
}

.tw-stroke-fuchsia-50\/85 {
  stroke: rgb(253 244 255 / 0.85) !important;
}

.tw-stroke-fuchsia-50\/90 {
  stroke: rgb(253 244 255 / 0.9) !important;
}

.tw-stroke-fuchsia-50\/95 {
  stroke: rgb(253 244 255 / 0.95) !important;
}

.tw-stroke-fuchsia-500 {
  stroke: #d946ef !important;
}

.tw-stroke-fuchsia-500\/0 {
  stroke: rgb(217 70 239 / 0) !important;
}

.tw-stroke-fuchsia-500\/10 {
  stroke: rgb(217 70 239 / 0.1) !important;
}

.tw-stroke-fuchsia-500\/100 {
  stroke: rgb(217 70 239 / 1) !important;
}

.tw-stroke-fuchsia-500\/15 {
  stroke: rgb(217 70 239 / 0.15) !important;
}

.tw-stroke-fuchsia-500\/20 {
  stroke: rgb(217 70 239 / 0.2) !important;
}

.tw-stroke-fuchsia-500\/25 {
  stroke: rgb(217 70 239 / 0.25) !important;
}

.tw-stroke-fuchsia-500\/30 {
  stroke: rgb(217 70 239 / 0.3) !important;
}

.tw-stroke-fuchsia-500\/35 {
  stroke: rgb(217 70 239 / 0.35) !important;
}

.tw-stroke-fuchsia-500\/40 {
  stroke: rgb(217 70 239 / 0.4) !important;
}

.tw-stroke-fuchsia-500\/45 {
  stroke: rgb(217 70 239 / 0.45) !important;
}

.tw-stroke-fuchsia-500\/5 {
  stroke: rgb(217 70 239 / 0.05) !important;
}

.tw-stroke-fuchsia-500\/50 {
  stroke: rgb(217 70 239 / 0.5) !important;
}

.tw-stroke-fuchsia-500\/55 {
  stroke: rgb(217 70 239 / 0.55) !important;
}

.tw-stroke-fuchsia-500\/60 {
  stroke: rgb(217 70 239 / 0.6) !important;
}

.tw-stroke-fuchsia-500\/65 {
  stroke: rgb(217 70 239 / 0.65) !important;
}

.tw-stroke-fuchsia-500\/70 {
  stroke: rgb(217 70 239 / 0.7) !important;
}

.tw-stroke-fuchsia-500\/75 {
  stroke: rgb(217 70 239 / 0.75) !important;
}

.tw-stroke-fuchsia-500\/80 {
  stroke: rgb(217 70 239 / 0.8) !important;
}

.tw-stroke-fuchsia-500\/85 {
  stroke: rgb(217 70 239 / 0.85) !important;
}

.tw-stroke-fuchsia-500\/90 {
  stroke: rgb(217 70 239 / 0.9) !important;
}

.tw-stroke-fuchsia-500\/95 {
  stroke: rgb(217 70 239 / 0.95) !important;
}

.tw-stroke-fuchsia-600 {
  stroke: #c026d3 !important;
}

.tw-stroke-fuchsia-600\/0 {
  stroke: rgb(192 38 211 / 0) !important;
}

.tw-stroke-fuchsia-600\/10 {
  stroke: rgb(192 38 211 / 0.1) !important;
}

.tw-stroke-fuchsia-600\/100 {
  stroke: rgb(192 38 211 / 1) !important;
}

.tw-stroke-fuchsia-600\/15 {
  stroke: rgb(192 38 211 / 0.15) !important;
}

.tw-stroke-fuchsia-600\/20 {
  stroke: rgb(192 38 211 / 0.2) !important;
}

.tw-stroke-fuchsia-600\/25 {
  stroke: rgb(192 38 211 / 0.25) !important;
}

.tw-stroke-fuchsia-600\/30 {
  stroke: rgb(192 38 211 / 0.3) !important;
}

.tw-stroke-fuchsia-600\/35 {
  stroke: rgb(192 38 211 / 0.35) !important;
}

.tw-stroke-fuchsia-600\/40 {
  stroke: rgb(192 38 211 / 0.4) !important;
}

.tw-stroke-fuchsia-600\/45 {
  stroke: rgb(192 38 211 / 0.45) !important;
}

.tw-stroke-fuchsia-600\/5 {
  stroke: rgb(192 38 211 / 0.05) !important;
}

.tw-stroke-fuchsia-600\/50 {
  stroke: rgb(192 38 211 / 0.5) !important;
}

.tw-stroke-fuchsia-600\/55 {
  stroke: rgb(192 38 211 / 0.55) !important;
}

.tw-stroke-fuchsia-600\/60 {
  stroke: rgb(192 38 211 / 0.6) !important;
}

.tw-stroke-fuchsia-600\/65 {
  stroke: rgb(192 38 211 / 0.65) !important;
}

.tw-stroke-fuchsia-600\/70 {
  stroke: rgb(192 38 211 / 0.7) !important;
}

.tw-stroke-fuchsia-600\/75 {
  stroke: rgb(192 38 211 / 0.75) !important;
}

.tw-stroke-fuchsia-600\/80 {
  stroke: rgb(192 38 211 / 0.8) !important;
}

.tw-stroke-fuchsia-600\/85 {
  stroke: rgb(192 38 211 / 0.85) !important;
}

.tw-stroke-fuchsia-600\/90 {
  stroke: rgb(192 38 211 / 0.9) !important;
}

.tw-stroke-fuchsia-600\/95 {
  stroke: rgb(192 38 211 / 0.95) !important;
}

.tw-stroke-fuchsia-700 {
  stroke: #a21caf !important;
}

.tw-stroke-fuchsia-700\/0 {
  stroke: rgb(162 28 175 / 0) !important;
}

.tw-stroke-fuchsia-700\/10 {
  stroke: rgb(162 28 175 / 0.1) !important;
}

.tw-stroke-fuchsia-700\/100 {
  stroke: rgb(162 28 175 / 1) !important;
}

.tw-stroke-fuchsia-700\/15 {
  stroke: rgb(162 28 175 / 0.15) !important;
}

.tw-stroke-fuchsia-700\/20 {
  stroke: rgb(162 28 175 / 0.2) !important;
}

.tw-stroke-fuchsia-700\/25 {
  stroke: rgb(162 28 175 / 0.25) !important;
}

.tw-stroke-fuchsia-700\/30 {
  stroke: rgb(162 28 175 / 0.3) !important;
}

.tw-stroke-fuchsia-700\/35 {
  stroke: rgb(162 28 175 / 0.35) !important;
}

.tw-stroke-fuchsia-700\/40 {
  stroke: rgb(162 28 175 / 0.4) !important;
}

.tw-stroke-fuchsia-700\/45 {
  stroke: rgb(162 28 175 / 0.45) !important;
}

.tw-stroke-fuchsia-700\/5 {
  stroke: rgb(162 28 175 / 0.05) !important;
}

.tw-stroke-fuchsia-700\/50 {
  stroke: rgb(162 28 175 / 0.5) !important;
}

.tw-stroke-fuchsia-700\/55 {
  stroke: rgb(162 28 175 / 0.55) !important;
}

.tw-stroke-fuchsia-700\/60 {
  stroke: rgb(162 28 175 / 0.6) !important;
}

.tw-stroke-fuchsia-700\/65 {
  stroke: rgb(162 28 175 / 0.65) !important;
}

.tw-stroke-fuchsia-700\/70 {
  stroke: rgb(162 28 175 / 0.7) !important;
}

.tw-stroke-fuchsia-700\/75 {
  stroke: rgb(162 28 175 / 0.75) !important;
}

.tw-stroke-fuchsia-700\/80 {
  stroke: rgb(162 28 175 / 0.8) !important;
}

.tw-stroke-fuchsia-700\/85 {
  stroke: rgb(162 28 175 / 0.85) !important;
}

.tw-stroke-fuchsia-700\/90 {
  stroke: rgb(162 28 175 / 0.9) !important;
}

.tw-stroke-fuchsia-700\/95 {
  stroke: rgb(162 28 175 / 0.95) !important;
}

.tw-stroke-fuchsia-800 {
  stroke: #86198f !important;
}

.tw-stroke-fuchsia-800\/0 {
  stroke: rgb(134 25 143 / 0) !important;
}

.tw-stroke-fuchsia-800\/10 {
  stroke: rgb(134 25 143 / 0.1) !important;
}

.tw-stroke-fuchsia-800\/100 {
  stroke: rgb(134 25 143 / 1) !important;
}

.tw-stroke-fuchsia-800\/15 {
  stroke: rgb(134 25 143 / 0.15) !important;
}

.tw-stroke-fuchsia-800\/20 {
  stroke: rgb(134 25 143 / 0.2) !important;
}

.tw-stroke-fuchsia-800\/25 {
  stroke: rgb(134 25 143 / 0.25) !important;
}

.tw-stroke-fuchsia-800\/30 {
  stroke: rgb(134 25 143 / 0.3) !important;
}

.tw-stroke-fuchsia-800\/35 {
  stroke: rgb(134 25 143 / 0.35) !important;
}

.tw-stroke-fuchsia-800\/40 {
  stroke: rgb(134 25 143 / 0.4) !important;
}

.tw-stroke-fuchsia-800\/45 {
  stroke: rgb(134 25 143 / 0.45) !important;
}

.tw-stroke-fuchsia-800\/5 {
  stroke: rgb(134 25 143 / 0.05) !important;
}

.tw-stroke-fuchsia-800\/50 {
  stroke: rgb(134 25 143 / 0.5) !important;
}

.tw-stroke-fuchsia-800\/55 {
  stroke: rgb(134 25 143 / 0.55) !important;
}

.tw-stroke-fuchsia-800\/60 {
  stroke: rgb(134 25 143 / 0.6) !important;
}

.tw-stroke-fuchsia-800\/65 {
  stroke: rgb(134 25 143 / 0.65) !important;
}

.tw-stroke-fuchsia-800\/70 {
  stroke: rgb(134 25 143 / 0.7) !important;
}

.tw-stroke-fuchsia-800\/75 {
  stroke: rgb(134 25 143 / 0.75) !important;
}

.tw-stroke-fuchsia-800\/80 {
  stroke: rgb(134 25 143 / 0.8) !important;
}

.tw-stroke-fuchsia-800\/85 {
  stroke: rgb(134 25 143 / 0.85) !important;
}

.tw-stroke-fuchsia-800\/90 {
  stroke: rgb(134 25 143 / 0.9) !important;
}

.tw-stroke-fuchsia-800\/95 {
  stroke: rgb(134 25 143 / 0.95) !important;
}

.tw-stroke-fuchsia-900 {
  stroke: #701a75 !important;
}

.tw-stroke-fuchsia-900\/0 {
  stroke: rgb(112 26 117 / 0) !important;
}

.tw-stroke-fuchsia-900\/10 {
  stroke: rgb(112 26 117 / 0.1) !important;
}

.tw-stroke-fuchsia-900\/100 {
  stroke: rgb(112 26 117 / 1) !important;
}

.tw-stroke-fuchsia-900\/15 {
  stroke: rgb(112 26 117 / 0.15) !important;
}

.tw-stroke-fuchsia-900\/20 {
  stroke: rgb(112 26 117 / 0.2) !important;
}

.tw-stroke-fuchsia-900\/25 {
  stroke: rgb(112 26 117 / 0.25) !important;
}

.tw-stroke-fuchsia-900\/30 {
  stroke: rgb(112 26 117 / 0.3) !important;
}

.tw-stroke-fuchsia-900\/35 {
  stroke: rgb(112 26 117 / 0.35) !important;
}

.tw-stroke-fuchsia-900\/40 {
  stroke: rgb(112 26 117 / 0.4) !important;
}

.tw-stroke-fuchsia-900\/45 {
  stroke: rgb(112 26 117 / 0.45) !important;
}

.tw-stroke-fuchsia-900\/5 {
  stroke: rgb(112 26 117 / 0.05) !important;
}

.tw-stroke-fuchsia-900\/50 {
  stroke: rgb(112 26 117 / 0.5) !important;
}

.tw-stroke-fuchsia-900\/55 {
  stroke: rgb(112 26 117 / 0.55) !important;
}

.tw-stroke-fuchsia-900\/60 {
  stroke: rgb(112 26 117 / 0.6) !important;
}

.tw-stroke-fuchsia-900\/65 {
  stroke: rgb(112 26 117 / 0.65) !important;
}

.tw-stroke-fuchsia-900\/70 {
  stroke: rgb(112 26 117 / 0.7) !important;
}

.tw-stroke-fuchsia-900\/75 {
  stroke: rgb(112 26 117 / 0.75) !important;
}

.tw-stroke-fuchsia-900\/80 {
  stroke: rgb(112 26 117 / 0.8) !important;
}

.tw-stroke-fuchsia-900\/85 {
  stroke: rgb(112 26 117 / 0.85) !important;
}

.tw-stroke-fuchsia-900\/90 {
  stroke: rgb(112 26 117 / 0.9) !important;
}

.tw-stroke-fuchsia-900\/95 {
  stroke: rgb(112 26 117 / 0.95) !important;
}

.tw-stroke-fuchsia-950 {
  stroke: #4a044e !important;
}

.tw-stroke-fuchsia-950\/0 {
  stroke: rgb(74 4 78 / 0) !important;
}

.tw-stroke-fuchsia-950\/10 {
  stroke: rgb(74 4 78 / 0.1) !important;
}

.tw-stroke-fuchsia-950\/100 {
  stroke: rgb(74 4 78 / 1) !important;
}

.tw-stroke-fuchsia-950\/15 {
  stroke: rgb(74 4 78 / 0.15) !important;
}

.tw-stroke-fuchsia-950\/20 {
  stroke: rgb(74 4 78 / 0.2) !important;
}

.tw-stroke-fuchsia-950\/25 {
  stroke: rgb(74 4 78 / 0.25) !important;
}

.tw-stroke-fuchsia-950\/30 {
  stroke: rgb(74 4 78 / 0.3) !important;
}

.tw-stroke-fuchsia-950\/35 {
  stroke: rgb(74 4 78 / 0.35) !important;
}

.tw-stroke-fuchsia-950\/40 {
  stroke: rgb(74 4 78 / 0.4) !important;
}

.tw-stroke-fuchsia-950\/45 {
  stroke: rgb(74 4 78 / 0.45) !important;
}

.tw-stroke-fuchsia-950\/5 {
  stroke: rgb(74 4 78 / 0.05) !important;
}

.tw-stroke-fuchsia-950\/50 {
  stroke: rgb(74 4 78 / 0.5) !important;
}

.tw-stroke-fuchsia-950\/55 {
  stroke: rgb(74 4 78 / 0.55) !important;
}

.tw-stroke-fuchsia-950\/60 {
  stroke: rgb(74 4 78 / 0.6) !important;
}

.tw-stroke-fuchsia-950\/65 {
  stroke: rgb(74 4 78 / 0.65) !important;
}

.tw-stroke-fuchsia-950\/70 {
  stroke: rgb(74 4 78 / 0.7) !important;
}

.tw-stroke-fuchsia-950\/75 {
  stroke: rgb(74 4 78 / 0.75) !important;
}

.tw-stroke-fuchsia-950\/80 {
  stroke: rgb(74 4 78 / 0.8) !important;
}

.tw-stroke-fuchsia-950\/85 {
  stroke: rgb(74 4 78 / 0.85) !important;
}

.tw-stroke-fuchsia-950\/90 {
  stroke: rgb(74 4 78 / 0.9) !important;
}

.tw-stroke-fuchsia-950\/95 {
  stroke: rgb(74 4 78 / 0.95) !important;
}

.tw-stroke-gray-100 {
  stroke: #f3f4f6 !important;
}

.tw-stroke-gray-100\/0 {
  stroke: rgb(243 244 246 / 0) !important;
}

.tw-stroke-gray-100\/10 {
  stroke: rgb(243 244 246 / 0.1) !important;
}

.tw-stroke-gray-100\/100 {
  stroke: rgb(243 244 246 / 1) !important;
}

.tw-stroke-gray-100\/15 {
  stroke: rgb(243 244 246 / 0.15) !important;
}

.tw-stroke-gray-100\/20 {
  stroke: rgb(243 244 246 / 0.2) !important;
}

.tw-stroke-gray-100\/25 {
  stroke: rgb(243 244 246 / 0.25) !important;
}

.tw-stroke-gray-100\/30 {
  stroke: rgb(243 244 246 / 0.3) !important;
}

.tw-stroke-gray-100\/35 {
  stroke: rgb(243 244 246 / 0.35) !important;
}

.tw-stroke-gray-100\/40 {
  stroke: rgb(243 244 246 / 0.4) !important;
}

.tw-stroke-gray-100\/45 {
  stroke: rgb(243 244 246 / 0.45) !important;
}

.tw-stroke-gray-100\/5 {
  stroke: rgb(243 244 246 / 0.05) !important;
}

.tw-stroke-gray-100\/50 {
  stroke: rgb(243 244 246 / 0.5) !important;
}

.tw-stroke-gray-100\/55 {
  stroke: rgb(243 244 246 / 0.55) !important;
}

.tw-stroke-gray-100\/60 {
  stroke: rgb(243 244 246 / 0.6) !important;
}

.tw-stroke-gray-100\/65 {
  stroke: rgb(243 244 246 / 0.65) !important;
}

.tw-stroke-gray-100\/70 {
  stroke: rgb(243 244 246 / 0.7) !important;
}

.tw-stroke-gray-100\/75 {
  stroke: rgb(243 244 246 / 0.75) !important;
}

.tw-stroke-gray-100\/80 {
  stroke: rgb(243 244 246 / 0.8) !important;
}

.tw-stroke-gray-100\/85 {
  stroke: rgb(243 244 246 / 0.85) !important;
}

.tw-stroke-gray-100\/90 {
  stroke: rgb(243 244 246 / 0.9) !important;
}

.tw-stroke-gray-100\/95 {
  stroke: rgb(243 244 246 / 0.95) !important;
}

.tw-stroke-gray-200 {
  stroke: #e5e7eb !important;
}

.tw-stroke-gray-200\/0 {
  stroke: rgb(229 231 235 / 0) !important;
}

.tw-stroke-gray-200\/10 {
  stroke: rgb(229 231 235 / 0.1) !important;
}

.tw-stroke-gray-200\/100 {
  stroke: rgb(229 231 235 / 1) !important;
}

.tw-stroke-gray-200\/15 {
  stroke: rgb(229 231 235 / 0.15) !important;
}

.tw-stroke-gray-200\/20 {
  stroke: rgb(229 231 235 / 0.2) !important;
}

.tw-stroke-gray-200\/25 {
  stroke: rgb(229 231 235 / 0.25) !important;
}

.tw-stroke-gray-200\/30 {
  stroke: rgb(229 231 235 / 0.3) !important;
}

.tw-stroke-gray-200\/35 {
  stroke: rgb(229 231 235 / 0.35) !important;
}

.tw-stroke-gray-200\/40 {
  stroke: rgb(229 231 235 / 0.4) !important;
}

.tw-stroke-gray-200\/45 {
  stroke: rgb(229 231 235 / 0.45) !important;
}

.tw-stroke-gray-200\/5 {
  stroke: rgb(229 231 235 / 0.05) !important;
}

.tw-stroke-gray-200\/50 {
  stroke: rgb(229 231 235 / 0.5) !important;
}

.tw-stroke-gray-200\/55 {
  stroke: rgb(229 231 235 / 0.55) !important;
}

.tw-stroke-gray-200\/60 {
  stroke: rgb(229 231 235 / 0.6) !important;
}

.tw-stroke-gray-200\/65 {
  stroke: rgb(229 231 235 / 0.65) !important;
}

.tw-stroke-gray-200\/70 {
  stroke: rgb(229 231 235 / 0.7) !important;
}

.tw-stroke-gray-200\/75 {
  stroke: rgb(229 231 235 / 0.75) !important;
}

.tw-stroke-gray-200\/80 {
  stroke: rgb(229 231 235 / 0.8) !important;
}

.tw-stroke-gray-200\/85 {
  stroke: rgb(229 231 235 / 0.85) !important;
}

.tw-stroke-gray-200\/90 {
  stroke: rgb(229 231 235 / 0.9) !important;
}

.tw-stroke-gray-200\/95 {
  stroke: rgb(229 231 235 / 0.95) !important;
}

.tw-stroke-gray-300 {
  stroke: #d1d5db !important;
}

.tw-stroke-gray-300\/0 {
  stroke: rgb(209 213 219 / 0) !important;
}

.tw-stroke-gray-300\/10 {
  stroke: rgb(209 213 219 / 0.1) !important;
}

.tw-stroke-gray-300\/100 {
  stroke: rgb(209 213 219 / 1) !important;
}

.tw-stroke-gray-300\/15 {
  stroke: rgb(209 213 219 / 0.15) !important;
}

.tw-stroke-gray-300\/20 {
  stroke: rgb(209 213 219 / 0.2) !important;
}

.tw-stroke-gray-300\/25 {
  stroke: rgb(209 213 219 / 0.25) !important;
}

.tw-stroke-gray-300\/30 {
  stroke: rgb(209 213 219 / 0.3) !important;
}

.tw-stroke-gray-300\/35 {
  stroke: rgb(209 213 219 / 0.35) !important;
}

.tw-stroke-gray-300\/40 {
  stroke: rgb(209 213 219 / 0.4) !important;
}

.tw-stroke-gray-300\/45 {
  stroke: rgb(209 213 219 / 0.45) !important;
}

.tw-stroke-gray-300\/5 {
  stroke: rgb(209 213 219 / 0.05) !important;
}

.tw-stroke-gray-300\/50 {
  stroke: rgb(209 213 219 / 0.5) !important;
}

.tw-stroke-gray-300\/55 {
  stroke: rgb(209 213 219 / 0.55) !important;
}

.tw-stroke-gray-300\/60 {
  stroke: rgb(209 213 219 / 0.6) !important;
}

.tw-stroke-gray-300\/65 {
  stroke: rgb(209 213 219 / 0.65) !important;
}

.tw-stroke-gray-300\/70 {
  stroke: rgb(209 213 219 / 0.7) !important;
}

.tw-stroke-gray-300\/75 {
  stroke: rgb(209 213 219 / 0.75) !important;
}

.tw-stroke-gray-300\/80 {
  stroke: rgb(209 213 219 / 0.8) !important;
}

.tw-stroke-gray-300\/85 {
  stroke: rgb(209 213 219 / 0.85) !important;
}

.tw-stroke-gray-300\/90 {
  stroke: rgb(209 213 219 / 0.9) !important;
}

.tw-stroke-gray-300\/95 {
  stroke: rgb(209 213 219 / 0.95) !important;
}

.tw-stroke-gray-400 {
  stroke: #9ca3af !important;
}

.tw-stroke-gray-400\/0 {
  stroke: rgb(156 163 175 / 0) !important;
}

.tw-stroke-gray-400\/10 {
  stroke: rgb(156 163 175 / 0.1) !important;
}

.tw-stroke-gray-400\/100 {
  stroke: rgb(156 163 175 / 1) !important;
}

.tw-stroke-gray-400\/15 {
  stroke: rgb(156 163 175 / 0.15) !important;
}

.tw-stroke-gray-400\/20 {
  stroke: rgb(156 163 175 / 0.2) !important;
}

.tw-stroke-gray-400\/25 {
  stroke: rgb(156 163 175 / 0.25) !important;
}

.tw-stroke-gray-400\/30 {
  stroke: rgb(156 163 175 / 0.3) !important;
}

.tw-stroke-gray-400\/35 {
  stroke: rgb(156 163 175 / 0.35) !important;
}

.tw-stroke-gray-400\/40 {
  stroke: rgb(156 163 175 / 0.4) !important;
}

.tw-stroke-gray-400\/45 {
  stroke: rgb(156 163 175 / 0.45) !important;
}

.tw-stroke-gray-400\/5 {
  stroke: rgb(156 163 175 / 0.05) !important;
}

.tw-stroke-gray-400\/50 {
  stroke: rgb(156 163 175 / 0.5) !important;
}

.tw-stroke-gray-400\/55 {
  stroke: rgb(156 163 175 / 0.55) !important;
}

.tw-stroke-gray-400\/60 {
  stroke: rgb(156 163 175 / 0.6) !important;
}

.tw-stroke-gray-400\/65 {
  stroke: rgb(156 163 175 / 0.65) !important;
}

.tw-stroke-gray-400\/70 {
  stroke: rgb(156 163 175 / 0.7) !important;
}

.tw-stroke-gray-400\/75 {
  stroke: rgb(156 163 175 / 0.75) !important;
}

.tw-stroke-gray-400\/80 {
  stroke: rgb(156 163 175 / 0.8) !important;
}

.tw-stroke-gray-400\/85 {
  stroke: rgb(156 163 175 / 0.85) !important;
}

.tw-stroke-gray-400\/90 {
  stroke: rgb(156 163 175 / 0.9) !important;
}

.tw-stroke-gray-400\/95 {
  stroke: rgb(156 163 175 / 0.95) !important;
}

.tw-stroke-gray-50 {
  stroke: #f9fafb !important;
}

.tw-stroke-gray-50\/0 {
  stroke: rgb(249 250 251 / 0) !important;
}

.tw-stroke-gray-50\/10 {
  stroke: rgb(249 250 251 / 0.1) !important;
}

.tw-stroke-gray-50\/100 {
  stroke: rgb(249 250 251 / 1) !important;
}

.tw-stroke-gray-50\/15 {
  stroke: rgb(249 250 251 / 0.15) !important;
}

.tw-stroke-gray-50\/20 {
  stroke: rgb(249 250 251 / 0.2) !important;
}

.tw-stroke-gray-50\/25 {
  stroke: rgb(249 250 251 / 0.25) !important;
}

.tw-stroke-gray-50\/30 {
  stroke: rgb(249 250 251 / 0.3) !important;
}

.tw-stroke-gray-50\/35 {
  stroke: rgb(249 250 251 / 0.35) !important;
}

.tw-stroke-gray-50\/40 {
  stroke: rgb(249 250 251 / 0.4) !important;
}

.tw-stroke-gray-50\/45 {
  stroke: rgb(249 250 251 / 0.45) !important;
}

.tw-stroke-gray-50\/5 {
  stroke: rgb(249 250 251 / 0.05) !important;
}

.tw-stroke-gray-50\/50 {
  stroke: rgb(249 250 251 / 0.5) !important;
}

.tw-stroke-gray-50\/55 {
  stroke: rgb(249 250 251 / 0.55) !important;
}

.tw-stroke-gray-50\/60 {
  stroke: rgb(249 250 251 / 0.6) !important;
}

.tw-stroke-gray-50\/65 {
  stroke: rgb(249 250 251 / 0.65) !important;
}

.tw-stroke-gray-50\/70 {
  stroke: rgb(249 250 251 / 0.7) !important;
}

.tw-stroke-gray-50\/75 {
  stroke: rgb(249 250 251 / 0.75) !important;
}

.tw-stroke-gray-50\/80 {
  stroke: rgb(249 250 251 / 0.8) !important;
}

.tw-stroke-gray-50\/85 {
  stroke: rgb(249 250 251 / 0.85) !important;
}

.tw-stroke-gray-50\/90 {
  stroke: rgb(249 250 251 / 0.9) !important;
}

.tw-stroke-gray-50\/95 {
  stroke: rgb(249 250 251 / 0.95) !important;
}

.tw-stroke-gray-500 {
  stroke: #6b7280 !important;
}

.tw-stroke-gray-500\/0 {
  stroke: rgb(107 114 128 / 0) !important;
}

.tw-stroke-gray-500\/10 {
  stroke: rgb(107 114 128 / 0.1) !important;
}

.tw-stroke-gray-500\/100 {
  stroke: rgb(107 114 128 / 1) !important;
}

.tw-stroke-gray-500\/15 {
  stroke: rgb(107 114 128 / 0.15) !important;
}

.tw-stroke-gray-500\/20 {
  stroke: rgb(107 114 128 / 0.2) !important;
}

.tw-stroke-gray-500\/25 {
  stroke: rgb(107 114 128 / 0.25) !important;
}

.tw-stroke-gray-500\/30 {
  stroke: rgb(107 114 128 / 0.3) !important;
}

.tw-stroke-gray-500\/35 {
  stroke: rgb(107 114 128 / 0.35) !important;
}

.tw-stroke-gray-500\/40 {
  stroke: rgb(107 114 128 / 0.4) !important;
}

.tw-stroke-gray-500\/45 {
  stroke: rgb(107 114 128 / 0.45) !important;
}

.tw-stroke-gray-500\/5 {
  stroke: rgb(107 114 128 / 0.05) !important;
}

.tw-stroke-gray-500\/50 {
  stroke: rgb(107 114 128 / 0.5) !important;
}

.tw-stroke-gray-500\/55 {
  stroke: rgb(107 114 128 / 0.55) !important;
}

.tw-stroke-gray-500\/60 {
  stroke: rgb(107 114 128 / 0.6) !important;
}

.tw-stroke-gray-500\/65 {
  stroke: rgb(107 114 128 / 0.65) !important;
}

.tw-stroke-gray-500\/70 {
  stroke: rgb(107 114 128 / 0.7) !important;
}

.tw-stroke-gray-500\/75 {
  stroke: rgb(107 114 128 / 0.75) !important;
}

.tw-stroke-gray-500\/80 {
  stroke: rgb(107 114 128 / 0.8) !important;
}

.tw-stroke-gray-500\/85 {
  stroke: rgb(107 114 128 / 0.85) !important;
}

.tw-stroke-gray-500\/90 {
  stroke: rgb(107 114 128 / 0.9) !important;
}

.tw-stroke-gray-500\/95 {
  stroke: rgb(107 114 128 / 0.95) !important;
}

.tw-stroke-gray-600 {
  stroke: #4b5563 !important;
}

.tw-stroke-gray-600\/0 {
  stroke: rgb(75 85 99 / 0) !important;
}

.tw-stroke-gray-600\/10 {
  stroke: rgb(75 85 99 / 0.1) !important;
}

.tw-stroke-gray-600\/100 {
  stroke: rgb(75 85 99 / 1) !important;
}

.tw-stroke-gray-600\/15 {
  stroke: rgb(75 85 99 / 0.15) !important;
}

.tw-stroke-gray-600\/20 {
  stroke: rgb(75 85 99 / 0.2) !important;
}

.tw-stroke-gray-600\/25 {
  stroke: rgb(75 85 99 / 0.25) !important;
}

.tw-stroke-gray-600\/30 {
  stroke: rgb(75 85 99 / 0.3) !important;
}

.tw-stroke-gray-600\/35 {
  stroke: rgb(75 85 99 / 0.35) !important;
}

.tw-stroke-gray-600\/40 {
  stroke: rgb(75 85 99 / 0.4) !important;
}

.tw-stroke-gray-600\/45 {
  stroke: rgb(75 85 99 / 0.45) !important;
}

.tw-stroke-gray-600\/5 {
  stroke: rgb(75 85 99 / 0.05) !important;
}

.tw-stroke-gray-600\/50 {
  stroke: rgb(75 85 99 / 0.5) !important;
}

.tw-stroke-gray-600\/55 {
  stroke: rgb(75 85 99 / 0.55) !important;
}

.tw-stroke-gray-600\/60 {
  stroke: rgb(75 85 99 / 0.6) !important;
}

.tw-stroke-gray-600\/65 {
  stroke: rgb(75 85 99 / 0.65) !important;
}

.tw-stroke-gray-600\/70 {
  stroke: rgb(75 85 99 / 0.7) !important;
}

.tw-stroke-gray-600\/75 {
  stroke: rgb(75 85 99 / 0.75) !important;
}

.tw-stroke-gray-600\/80 {
  stroke: rgb(75 85 99 / 0.8) !important;
}

.tw-stroke-gray-600\/85 {
  stroke: rgb(75 85 99 / 0.85) !important;
}

.tw-stroke-gray-600\/90 {
  stroke: rgb(75 85 99 / 0.9) !important;
}

.tw-stroke-gray-600\/95 {
  stroke: rgb(75 85 99 / 0.95) !important;
}

.tw-stroke-gray-700 {
  stroke: #374151 !important;
}

.tw-stroke-gray-700\/0 {
  stroke: rgb(55 65 81 / 0) !important;
}

.tw-stroke-gray-700\/10 {
  stroke: rgb(55 65 81 / 0.1) !important;
}

.tw-stroke-gray-700\/100 {
  stroke: rgb(55 65 81 / 1) !important;
}

.tw-stroke-gray-700\/15 {
  stroke: rgb(55 65 81 / 0.15) !important;
}

.tw-stroke-gray-700\/20 {
  stroke: rgb(55 65 81 / 0.2) !important;
}

.tw-stroke-gray-700\/25 {
  stroke: rgb(55 65 81 / 0.25) !important;
}

.tw-stroke-gray-700\/30 {
  stroke: rgb(55 65 81 / 0.3) !important;
}

.tw-stroke-gray-700\/35 {
  stroke: rgb(55 65 81 / 0.35) !important;
}

.tw-stroke-gray-700\/40 {
  stroke: rgb(55 65 81 / 0.4) !important;
}

.tw-stroke-gray-700\/45 {
  stroke: rgb(55 65 81 / 0.45) !important;
}

.tw-stroke-gray-700\/5 {
  stroke: rgb(55 65 81 / 0.05) !important;
}

.tw-stroke-gray-700\/50 {
  stroke: rgb(55 65 81 / 0.5) !important;
}

.tw-stroke-gray-700\/55 {
  stroke: rgb(55 65 81 / 0.55) !important;
}

.tw-stroke-gray-700\/60 {
  stroke: rgb(55 65 81 / 0.6) !important;
}

.tw-stroke-gray-700\/65 {
  stroke: rgb(55 65 81 / 0.65) !important;
}

.tw-stroke-gray-700\/70 {
  stroke: rgb(55 65 81 / 0.7) !important;
}

.tw-stroke-gray-700\/75 {
  stroke: rgb(55 65 81 / 0.75) !important;
}

.tw-stroke-gray-700\/80 {
  stroke: rgb(55 65 81 / 0.8) !important;
}

.tw-stroke-gray-700\/85 {
  stroke: rgb(55 65 81 / 0.85) !important;
}

.tw-stroke-gray-700\/90 {
  stroke: rgb(55 65 81 / 0.9) !important;
}

.tw-stroke-gray-700\/95 {
  stroke: rgb(55 65 81 / 0.95) !important;
}

.tw-stroke-gray-800 {
  stroke: #1f2937 !important;
}

.tw-stroke-gray-800\/0 {
  stroke: rgb(31 41 55 / 0) !important;
}

.tw-stroke-gray-800\/10 {
  stroke: rgb(31 41 55 / 0.1) !important;
}

.tw-stroke-gray-800\/100 {
  stroke: rgb(31 41 55 / 1) !important;
}

.tw-stroke-gray-800\/15 {
  stroke: rgb(31 41 55 / 0.15) !important;
}

.tw-stroke-gray-800\/20 {
  stroke: rgb(31 41 55 / 0.2) !important;
}

.tw-stroke-gray-800\/25 {
  stroke: rgb(31 41 55 / 0.25) !important;
}

.tw-stroke-gray-800\/30 {
  stroke: rgb(31 41 55 / 0.3) !important;
}

.tw-stroke-gray-800\/35 {
  stroke: rgb(31 41 55 / 0.35) !important;
}

.tw-stroke-gray-800\/40 {
  stroke: rgb(31 41 55 / 0.4) !important;
}

.tw-stroke-gray-800\/45 {
  stroke: rgb(31 41 55 / 0.45) !important;
}

.tw-stroke-gray-800\/5 {
  stroke: rgb(31 41 55 / 0.05) !important;
}

.tw-stroke-gray-800\/50 {
  stroke: rgb(31 41 55 / 0.5) !important;
}

.tw-stroke-gray-800\/55 {
  stroke: rgb(31 41 55 / 0.55) !important;
}

.tw-stroke-gray-800\/60 {
  stroke: rgb(31 41 55 / 0.6) !important;
}

.tw-stroke-gray-800\/65 {
  stroke: rgb(31 41 55 / 0.65) !important;
}

.tw-stroke-gray-800\/70 {
  stroke: rgb(31 41 55 / 0.7) !important;
}

.tw-stroke-gray-800\/75 {
  stroke: rgb(31 41 55 / 0.75) !important;
}

.tw-stroke-gray-800\/80 {
  stroke: rgb(31 41 55 / 0.8) !important;
}

.tw-stroke-gray-800\/85 {
  stroke: rgb(31 41 55 / 0.85) !important;
}

.tw-stroke-gray-800\/90 {
  stroke: rgb(31 41 55 / 0.9) !important;
}

.tw-stroke-gray-800\/95 {
  stroke: rgb(31 41 55 / 0.95) !important;
}

.tw-stroke-gray-900 {
  stroke: #111827 !important;
}

.tw-stroke-gray-900\/0 {
  stroke: rgb(17 24 39 / 0) !important;
}

.tw-stroke-gray-900\/10 {
  stroke: rgb(17 24 39 / 0.1) !important;
}

.tw-stroke-gray-900\/100 {
  stroke: rgb(17 24 39 / 1) !important;
}

.tw-stroke-gray-900\/15 {
  stroke: rgb(17 24 39 / 0.15) !important;
}

.tw-stroke-gray-900\/20 {
  stroke: rgb(17 24 39 / 0.2) !important;
}

.tw-stroke-gray-900\/25 {
  stroke: rgb(17 24 39 / 0.25) !important;
}

.tw-stroke-gray-900\/30 {
  stroke: rgb(17 24 39 / 0.3) !important;
}

.tw-stroke-gray-900\/35 {
  stroke: rgb(17 24 39 / 0.35) !important;
}

.tw-stroke-gray-900\/40 {
  stroke: rgb(17 24 39 / 0.4) !important;
}

.tw-stroke-gray-900\/45 {
  stroke: rgb(17 24 39 / 0.45) !important;
}

.tw-stroke-gray-900\/5 {
  stroke: rgb(17 24 39 / 0.05) !important;
}

.tw-stroke-gray-900\/50 {
  stroke: rgb(17 24 39 / 0.5) !important;
}

.tw-stroke-gray-900\/55 {
  stroke: rgb(17 24 39 / 0.55) !important;
}

.tw-stroke-gray-900\/60 {
  stroke: rgb(17 24 39 / 0.6) !important;
}

.tw-stroke-gray-900\/65 {
  stroke: rgb(17 24 39 / 0.65) !important;
}

.tw-stroke-gray-900\/70 {
  stroke: rgb(17 24 39 / 0.7) !important;
}

.tw-stroke-gray-900\/75 {
  stroke: rgb(17 24 39 / 0.75) !important;
}

.tw-stroke-gray-900\/80 {
  stroke: rgb(17 24 39 / 0.8) !important;
}

.tw-stroke-gray-900\/85 {
  stroke: rgb(17 24 39 / 0.85) !important;
}

.tw-stroke-gray-900\/90 {
  stroke: rgb(17 24 39 / 0.9) !important;
}

.tw-stroke-gray-900\/95 {
  stroke: rgb(17 24 39 / 0.95) !important;
}

.tw-stroke-gray-950 {
  stroke: #030712 !important;
}

.tw-stroke-gray-950\/0 {
  stroke: rgb(3 7 18 / 0) !important;
}

.tw-stroke-gray-950\/10 {
  stroke: rgb(3 7 18 / 0.1) !important;
}

.tw-stroke-gray-950\/100 {
  stroke: rgb(3 7 18 / 1) !important;
}

.tw-stroke-gray-950\/15 {
  stroke: rgb(3 7 18 / 0.15) !important;
}

.tw-stroke-gray-950\/20 {
  stroke: rgb(3 7 18 / 0.2) !important;
}

.tw-stroke-gray-950\/25 {
  stroke: rgb(3 7 18 / 0.25) !important;
}

.tw-stroke-gray-950\/30 {
  stroke: rgb(3 7 18 / 0.3) !important;
}

.tw-stroke-gray-950\/35 {
  stroke: rgb(3 7 18 / 0.35) !important;
}

.tw-stroke-gray-950\/40 {
  stroke: rgb(3 7 18 / 0.4) !important;
}

.tw-stroke-gray-950\/45 {
  stroke: rgb(3 7 18 / 0.45) !important;
}

.tw-stroke-gray-950\/5 {
  stroke: rgb(3 7 18 / 0.05) !important;
}

.tw-stroke-gray-950\/50 {
  stroke: rgb(3 7 18 / 0.5) !important;
}

.tw-stroke-gray-950\/55 {
  stroke: rgb(3 7 18 / 0.55) !important;
}

.tw-stroke-gray-950\/60 {
  stroke: rgb(3 7 18 / 0.6) !important;
}

.tw-stroke-gray-950\/65 {
  stroke: rgb(3 7 18 / 0.65) !important;
}

.tw-stroke-gray-950\/70 {
  stroke: rgb(3 7 18 / 0.7) !important;
}

.tw-stroke-gray-950\/75 {
  stroke: rgb(3 7 18 / 0.75) !important;
}

.tw-stroke-gray-950\/80 {
  stroke: rgb(3 7 18 / 0.8) !important;
}

.tw-stroke-gray-950\/85 {
  stroke: rgb(3 7 18 / 0.85) !important;
}

.tw-stroke-gray-950\/90 {
  stroke: rgb(3 7 18 / 0.9) !important;
}

.tw-stroke-gray-950\/95 {
  stroke: rgb(3 7 18 / 0.95) !important;
}

.tw-stroke-green-100 {
  stroke: #dcfce7 !important;
}

.tw-stroke-green-100\/0 {
  stroke: rgb(220 252 231 / 0) !important;
}

.tw-stroke-green-100\/10 {
  stroke: rgb(220 252 231 / 0.1) !important;
}

.tw-stroke-green-100\/100 {
  stroke: rgb(220 252 231 / 1) !important;
}

.tw-stroke-green-100\/15 {
  stroke: rgb(220 252 231 / 0.15) !important;
}

.tw-stroke-green-100\/20 {
  stroke: rgb(220 252 231 / 0.2) !important;
}

.tw-stroke-green-100\/25 {
  stroke: rgb(220 252 231 / 0.25) !important;
}

.tw-stroke-green-100\/30 {
  stroke: rgb(220 252 231 / 0.3) !important;
}

.tw-stroke-green-100\/35 {
  stroke: rgb(220 252 231 / 0.35) !important;
}

.tw-stroke-green-100\/40 {
  stroke: rgb(220 252 231 / 0.4) !important;
}

.tw-stroke-green-100\/45 {
  stroke: rgb(220 252 231 / 0.45) !important;
}

.tw-stroke-green-100\/5 {
  stroke: rgb(220 252 231 / 0.05) !important;
}

.tw-stroke-green-100\/50 {
  stroke: rgb(220 252 231 / 0.5) !important;
}

.tw-stroke-green-100\/55 {
  stroke: rgb(220 252 231 / 0.55) !important;
}

.tw-stroke-green-100\/60 {
  stroke: rgb(220 252 231 / 0.6) !important;
}

.tw-stroke-green-100\/65 {
  stroke: rgb(220 252 231 / 0.65) !important;
}

.tw-stroke-green-100\/70 {
  stroke: rgb(220 252 231 / 0.7) !important;
}

.tw-stroke-green-100\/75 {
  stroke: rgb(220 252 231 / 0.75) !important;
}

.tw-stroke-green-100\/80 {
  stroke: rgb(220 252 231 / 0.8) !important;
}

.tw-stroke-green-100\/85 {
  stroke: rgb(220 252 231 / 0.85) !important;
}

.tw-stroke-green-100\/90 {
  stroke: rgb(220 252 231 / 0.9) !important;
}

.tw-stroke-green-100\/95 {
  stroke: rgb(220 252 231 / 0.95) !important;
}

.tw-stroke-green-200 {
  stroke: #bbf7d0 !important;
}

.tw-stroke-green-200\/0 {
  stroke: rgb(187 247 208 / 0) !important;
}

.tw-stroke-green-200\/10 {
  stroke: rgb(187 247 208 / 0.1) !important;
}

.tw-stroke-green-200\/100 {
  stroke: rgb(187 247 208 / 1) !important;
}

.tw-stroke-green-200\/15 {
  stroke: rgb(187 247 208 / 0.15) !important;
}

.tw-stroke-green-200\/20 {
  stroke: rgb(187 247 208 / 0.2) !important;
}

.tw-stroke-green-200\/25 {
  stroke: rgb(187 247 208 / 0.25) !important;
}

.tw-stroke-green-200\/30 {
  stroke: rgb(187 247 208 / 0.3) !important;
}

.tw-stroke-green-200\/35 {
  stroke: rgb(187 247 208 / 0.35) !important;
}

.tw-stroke-green-200\/40 {
  stroke: rgb(187 247 208 / 0.4) !important;
}

.tw-stroke-green-200\/45 {
  stroke: rgb(187 247 208 / 0.45) !important;
}

.tw-stroke-green-200\/5 {
  stroke: rgb(187 247 208 / 0.05) !important;
}

.tw-stroke-green-200\/50 {
  stroke: rgb(187 247 208 / 0.5) !important;
}

.tw-stroke-green-200\/55 {
  stroke: rgb(187 247 208 / 0.55) !important;
}

.tw-stroke-green-200\/60 {
  stroke: rgb(187 247 208 / 0.6) !important;
}

.tw-stroke-green-200\/65 {
  stroke: rgb(187 247 208 / 0.65) !important;
}

.tw-stroke-green-200\/70 {
  stroke: rgb(187 247 208 / 0.7) !important;
}

.tw-stroke-green-200\/75 {
  stroke: rgb(187 247 208 / 0.75) !important;
}

.tw-stroke-green-200\/80 {
  stroke: rgb(187 247 208 / 0.8) !important;
}

.tw-stroke-green-200\/85 {
  stroke: rgb(187 247 208 / 0.85) !important;
}

.tw-stroke-green-200\/90 {
  stroke: rgb(187 247 208 / 0.9) !important;
}

.tw-stroke-green-200\/95 {
  stroke: rgb(187 247 208 / 0.95) !important;
}

.tw-stroke-green-300 {
  stroke: #86efac !important;
}

.tw-stroke-green-300\/0 {
  stroke: rgb(134 239 172 / 0) !important;
}

.tw-stroke-green-300\/10 {
  stroke: rgb(134 239 172 / 0.1) !important;
}

.tw-stroke-green-300\/100 {
  stroke: rgb(134 239 172 / 1) !important;
}

.tw-stroke-green-300\/15 {
  stroke: rgb(134 239 172 / 0.15) !important;
}

.tw-stroke-green-300\/20 {
  stroke: rgb(134 239 172 / 0.2) !important;
}

.tw-stroke-green-300\/25 {
  stroke: rgb(134 239 172 / 0.25) !important;
}

.tw-stroke-green-300\/30 {
  stroke: rgb(134 239 172 / 0.3) !important;
}

.tw-stroke-green-300\/35 {
  stroke: rgb(134 239 172 / 0.35) !important;
}

.tw-stroke-green-300\/40 {
  stroke: rgb(134 239 172 / 0.4) !important;
}

.tw-stroke-green-300\/45 {
  stroke: rgb(134 239 172 / 0.45) !important;
}

.tw-stroke-green-300\/5 {
  stroke: rgb(134 239 172 / 0.05) !important;
}

.tw-stroke-green-300\/50 {
  stroke: rgb(134 239 172 / 0.5) !important;
}

.tw-stroke-green-300\/55 {
  stroke: rgb(134 239 172 / 0.55) !important;
}

.tw-stroke-green-300\/60 {
  stroke: rgb(134 239 172 / 0.6) !important;
}

.tw-stroke-green-300\/65 {
  stroke: rgb(134 239 172 / 0.65) !important;
}

.tw-stroke-green-300\/70 {
  stroke: rgb(134 239 172 / 0.7) !important;
}

.tw-stroke-green-300\/75 {
  stroke: rgb(134 239 172 / 0.75) !important;
}

.tw-stroke-green-300\/80 {
  stroke: rgb(134 239 172 / 0.8) !important;
}

.tw-stroke-green-300\/85 {
  stroke: rgb(134 239 172 / 0.85) !important;
}

.tw-stroke-green-300\/90 {
  stroke: rgb(134 239 172 / 0.9) !important;
}

.tw-stroke-green-300\/95 {
  stroke: rgb(134 239 172 / 0.95) !important;
}

.tw-stroke-green-400 {
  stroke: #4ade80 !important;
}

.tw-stroke-green-400\/0 {
  stroke: rgb(74 222 128 / 0) !important;
}

.tw-stroke-green-400\/10 {
  stroke: rgb(74 222 128 / 0.1) !important;
}

.tw-stroke-green-400\/100 {
  stroke: rgb(74 222 128 / 1) !important;
}

.tw-stroke-green-400\/15 {
  stroke: rgb(74 222 128 / 0.15) !important;
}

.tw-stroke-green-400\/20 {
  stroke: rgb(74 222 128 / 0.2) !important;
}

.tw-stroke-green-400\/25 {
  stroke: rgb(74 222 128 / 0.25) !important;
}

.tw-stroke-green-400\/30 {
  stroke: rgb(74 222 128 / 0.3) !important;
}

.tw-stroke-green-400\/35 {
  stroke: rgb(74 222 128 / 0.35) !important;
}

.tw-stroke-green-400\/40 {
  stroke: rgb(74 222 128 / 0.4) !important;
}

.tw-stroke-green-400\/45 {
  stroke: rgb(74 222 128 / 0.45) !important;
}

.tw-stroke-green-400\/5 {
  stroke: rgb(74 222 128 / 0.05) !important;
}

.tw-stroke-green-400\/50 {
  stroke: rgb(74 222 128 / 0.5) !important;
}

.tw-stroke-green-400\/55 {
  stroke: rgb(74 222 128 / 0.55) !important;
}

.tw-stroke-green-400\/60 {
  stroke: rgb(74 222 128 / 0.6) !important;
}

.tw-stroke-green-400\/65 {
  stroke: rgb(74 222 128 / 0.65) !important;
}

.tw-stroke-green-400\/70 {
  stroke: rgb(74 222 128 / 0.7) !important;
}

.tw-stroke-green-400\/75 {
  stroke: rgb(74 222 128 / 0.75) !important;
}

.tw-stroke-green-400\/80 {
  stroke: rgb(74 222 128 / 0.8) !important;
}

.tw-stroke-green-400\/85 {
  stroke: rgb(74 222 128 / 0.85) !important;
}

.tw-stroke-green-400\/90 {
  stroke: rgb(74 222 128 / 0.9) !important;
}

.tw-stroke-green-400\/95 {
  stroke: rgb(74 222 128 / 0.95) !important;
}

.tw-stroke-green-50 {
  stroke: #f0fdf4 !important;
}

.tw-stroke-green-50\/0 {
  stroke: rgb(240 253 244 / 0) !important;
}

.tw-stroke-green-50\/10 {
  stroke: rgb(240 253 244 / 0.1) !important;
}

.tw-stroke-green-50\/100 {
  stroke: rgb(240 253 244 / 1) !important;
}

.tw-stroke-green-50\/15 {
  stroke: rgb(240 253 244 / 0.15) !important;
}

.tw-stroke-green-50\/20 {
  stroke: rgb(240 253 244 / 0.2) !important;
}

.tw-stroke-green-50\/25 {
  stroke: rgb(240 253 244 / 0.25) !important;
}

.tw-stroke-green-50\/30 {
  stroke: rgb(240 253 244 / 0.3) !important;
}

.tw-stroke-green-50\/35 {
  stroke: rgb(240 253 244 / 0.35) !important;
}

.tw-stroke-green-50\/40 {
  stroke: rgb(240 253 244 / 0.4) !important;
}

.tw-stroke-green-50\/45 {
  stroke: rgb(240 253 244 / 0.45) !important;
}

.tw-stroke-green-50\/5 {
  stroke: rgb(240 253 244 / 0.05) !important;
}

.tw-stroke-green-50\/50 {
  stroke: rgb(240 253 244 / 0.5) !important;
}

.tw-stroke-green-50\/55 {
  stroke: rgb(240 253 244 / 0.55) !important;
}

.tw-stroke-green-50\/60 {
  stroke: rgb(240 253 244 / 0.6) !important;
}

.tw-stroke-green-50\/65 {
  stroke: rgb(240 253 244 / 0.65) !important;
}

.tw-stroke-green-50\/70 {
  stroke: rgb(240 253 244 / 0.7) !important;
}

.tw-stroke-green-50\/75 {
  stroke: rgb(240 253 244 / 0.75) !important;
}

.tw-stroke-green-50\/80 {
  stroke: rgb(240 253 244 / 0.8) !important;
}

.tw-stroke-green-50\/85 {
  stroke: rgb(240 253 244 / 0.85) !important;
}

.tw-stroke-green-50\/90 {
  stroke: rgb(240 253 244 / 0.9) !important;
}

.tw-stroke-green-50\/95 {
  stroke: rgb(240 253 244 / 0.95) !important;
}

.tw-stroke-green-500 {
  stroke: #22c55e !important;
}

.tw-stroke-green-500\/0 {
  stroke: rgb(34 197 94 / 0) !important;
}

.tw-stroke-green-500\/10 {
  stroke: rgb(34 197 94 / 0.1) !important;
}

.tw-stroke-green-500\/100 {
  stroke: rgb(34 197 94 / 1) !important;
}

.tw-stroke-green-500\/15 {
  stroke: rgb(34 197 94 / 0.15) !important;
}

.tw-stroke-green-500\/20 {
  stroke: rgb(34 197 94 / 0.2) !important;
}

.tw-stroke-green-500\/25 {
  stroke: rgb(34 197 94 / 0.25) !important;
}

.tw-stroke-green-500\/30 {
  stroke: rgb(34 197 94 / 0.3) !important;
}

.tw-stroke-green-500\/35 {
  stroke: rgb(34 197 94 / 0.35) !important;
}

.tw-stroke-green-500\/40 {
  stroke: rgb(34 197 94 / 0.4) !important;
}

.tw-stroke-green-500\/45 {
  stroke: rgb(34 197 94 / 0.45) !important;
}

.tw-stroke-green-500\/5 {
  stroke: rgb(34 197 94 / 0.05) !important;
}

.tw-stroke-green-500\/50 {
  stroke: rgb(34 197 94 / 0.5) !important;
}

.tw-stroke-green-500\/55 {
  stroke: rgb(34 197 94 / 0.55) !important;
}

.tw-stroke-green-500\/60 {
  stroke: rgb(34 197 94 / 0.6) !important;
}

.tw-stroke-green-500\/65 {
  stroke: rgb(34 197 94 / 0.65) !important;
}

.tw-stroke-green-500\/70 {
  stroke: rgb(34 197 94 / 0.7) !important;
}

.tw-stroke-green-500\/75 {
  stroke: rgb(34 197 94 / 0.75) !important;
}

.tw-stroke-green-500\/80 {
  stroke: rgb(34 197 94 / 0.8) !important;
}

.tw-stroke-green-500\/85 {
  stroke: rgb(34 197 94 / 0.85) !important;
}

.tw-stroke-green-500\/90 {
  stroke: rgb(34 197 94 / 0.9) !important;
}

.tw-stroke-green-500\/95 {
  stroke: rgb(34 197 94 / 0.95) !important;
}

.tw-stroke-green-600 {
  stroke: #16a34a !important;
}

.tw-stroke-green-600\/0 {
  stroke: rgb(22 163 74 / 0) !important;
}

.tw-stroke-green-600\/10 {
  stroke: rgb(22 163 74 / 0.1) !important;
}

.tw-stroke-green-600\/100 {
  stroke: rgb(22 163 74 / 1) !important;
}

.tw-stroke-green-600\/15 {
  stroke: rgb(22 163 74 / 0.15) !important;
}

.tw-stroke-green-600\/20 {
  stroke: rgb(22 163 74 / 0.2) !important;
}

.tw-stroke-green-600\/25 {
  stroke: rgb(22 163 74 / 0.25) !important;
}

.tw-stroke-green-600\/30 {
  stroke: rgb(22 163 74 / 0.3) !important;
}

.tw-stroke-green-600\/35 {
  stroke: rgb(22 163 74 / 0.35) !important;
}

.tw-stroke-green-600\/40 {
  stroke: rgb(22 163 74 / 0.4) !important;
}

.tw-stroke-green-600\/45 {
  stroke: rgb(22 163 74 / 0.45) !important;
}

.tw-stroke-green-600\/5 {
  stroke: rgb(22 163 74 / 0.05) !important;
}

.tw-stroke-green-600\/50 {
  stroke: rgb(22 163 74 / 0.5) !important;
}

.tw-stroke-green-600\/55 {
  stroke: rgb(22 163 74 / 0.55) !important;
}

.tw-stroke-green-600\/60 {
  stroke: rgb(22 163 74 / 0.6) !important;
}

.tw-stroke-green-600\/65 {
  stroke: rgb(22 163 74 / 0.65) !important;
}

.tw-stroke-green-600\/70 {
  stroke: rgb(22 163 74 / 0.7) !important;
}

.tw-stroke-green-600\/75 {
  stroke: rgb(22 163 74 / 0.75) !important;
}

.tw-stroke-green-600\/80 {
  stroke: rgb(22 163 74 / 0.8) !important;
}

.tw-stroke-green-600\/85 {
  stroke: rgb(22 163 74 / 0.85) !important;
}

.tw-stroke-green-600\/90 {
  stroke: rgb(22 163 74 / 0.9) !important;
}

.tw-stroke-green-600\/95 {
  stroke: rgb(22 163 74 / 0.95) !important;
}

.tw-stroke-green-700 {
  stroke: #15803d !important;
}

.tw-stroke-green-700\/0 {
  stroke: rgb(21 128 61 / 0) !important;
}

.tw-stroke-green-700\/10 {
  stroke: rgb(21 128 61 / 0.1) !important;
}

.tw-stroke-green-700\/100 {
  stroke: rgb(21 128 61 / 1) !important;
}

.tw-stroke-green-700\/15 {
  stroke: rgb(21 128 61 / 0.15) !important;
}

.tw-stroke-green-700\/20 {
  stroke: rgb(21 128 61 / 0.2) !important;
}

.tw-stroke-green-700\/25 {
  stroke: rgb(21 128 61 / 0.25) !important;
}

.tw-stroke-green-700\/30 {
  stroke: rgb(21 128 61 / 0.3) !important;
}

.tw-stroke-green-700\/35 {
  stroke: rgb(21 128 61 / 0.35) !important;
}

.tw-stroke-green-700\/40 {
  stroke: rgb(21 128 61 / 0.4) !important;
}

.tw-stroke-green-700\/45 {
  stroke: rgb(21 128 61 / 0.45) !important;
}

.tw-stroke-green-700\/5 {
  stroke: rgb(21 128 61 / 0.05) !important;
}

.tw-stroke-green-700\/50 {
  stroke: rgb(21 128 61 / 0.5) !important;
}

.tw-stroke-green-700\/55 {
  stroke: rgb(21 128 61 / 0.55) !important;
}

.tw-stroke-green-700\/60 {
  stroke: rgb(21 128 61 / 0.6) !important;
}

.tw-stroke-green-700\/65 {
  stroke: rgb(21 128 61 / 0.65) !important;
}

.tw-stroke-green-700\/70 {
  stroke: rgb(21 128 61 / 0.7) !important;
}

.tw-stroke-green-700\/75 {
  stroke: rgb(21 128 61 / 0.75) !important;
}

.tw-stroke-green-700\/80 {
  stroke: rgb(21 128 61 / 0.8) !important;
}

.tw-stroke-green-700\/85 {
  stroke: rgb(21 128 61 / 0.85) !important;
}

.tw-stroke-green-700\/90 {
  stroke: rgb(21 128 61 / 0.9) !important;
}

.tw-stroke-green-700\/95 {
  stroke: rgb(21 128 61 / 0.95) !important;
}

.tw-stroke-green-800 {
  stroke: #166534 !important;
}

.tw-stroke-green-800\/0 {
  stroke: rgb(22 101 52 / 0) !important;
}

.tw-stroke-green-800\/10 {
  stroke: rgb(22 101 52 / 0.1) !important;
}

.tw-stroke-green-800\/100 {
  stroke: rgb(22 101 52 / 1) !important;
}

.tw-stroke-green-800\/15 {
  stroke: rgb(22 101 52 / 0.15) !important;
}

.tw-stroke-green-800\/20 {
  stroke: rgb(22 101 52 / 0.2) !important;
}

.tw-stroke-green-800\/25 {
  stroke: rgb(22 101 52 / 0.25) !important;
}

.tw-stroke-green-800\/30 {
  stroke: rgb(22 101 52 / 0.3) !important;
}

.tw-stroke-green-800\/35 {
  stroke: rgb(22 101 52 / 0.35) !important;
}

.tw-stroke-green-800\/40 {
  stroke: rgb(22 101 52 / 0.4) !important;
}

.tw-stroke-green-800\/45 {
  stroke: rgb(22 101 52 / 0.45) !important;
}

.tw-stroke-green-800\/5 {
  stroke: rgb(22 101 52 / 0.05) !important;
}

.tw-stroke-green-800\/50 {
  stroke: rgb(22 101 52 / 0.5) !important;
}

.tw-stroke-green-800\/55 {
  stroke: rgb(22 101 52 / 0.55) !important;
}

.tw-stroke-green-800\/60 {
  stroke: rgb(22 101 52 / 0.6) !important;
}

.tw-stroke-green-800\/65 {
  stroke: rgb(22 101 52 / 0.65) !important;
}

.tw-stroke-green-800\/70 {
  stroke: rgb(22 101 52 / 0.7) !important;
}

.tw-stroke-green-800\/75 {
  stroke: rgb(22 101 52 / 0.75) !important;
}

.tw-stroke-green-800\/80 {
  stroke: rgb(22 101 52 / 0.8) !important;
}

.tw-stroke-green-800\/85 {
  stroke: rgb(22 101 52 / 0.85) !important;
}

.tw-stroke-green-800\/90 {
  stroke: rgb(22 101 52 / 0.9) !important;
}

.tw-stroke-green-800\/95 {
  stroke: rgb(22 101 52 / 0.95) !important;
}

.tw-stroke-green-900 {
  stroke: #14532d !important;
}

.tw-stroke-green-900\/0 {
  stroke: rgb(20 83 45 / 0) !important;
}

.tw-stroke-green-900\/10 {
  stroke: rgb(20 83 45 / 0.1) !important;
}

.tw-stroke-green-900\/100 {
  stroke: rgb(20 83 45 / 1) !important;
}

.tw-stroke-green-900\/15 {
  stroke: rgb(20 83 45 / 0.15) !important;
}

.tw-stroke-green-900\/20 {
  stroke: rgb(20 83 45 / 0.2) !important;
}

.tw-stroke-green-900\/25 {
  stroke: rgb(20 83 45 / 0.25) !important;
}

.tw-stroke-green-900\/30 {
  stroke: rgb(20 83 45 / 0.3) !important;
}

.tw-stroke-green-900\/35 {
  stroke: rgb(20 83 45 / 0.35) !important;
}

.tw-stroke-green-900\/40 {
  stroke: rgb(20 83 45 / 0.4) !important;
}

.tw-stroke-green-900\/45 {
  stroke: rgb(20 83 45 / 0.45) !important;
}

.tw-stroke-green-900\/5 {
  stroke: rgb(20 83 45 / 0.05) !important;
}

.tw-stroke-green-900\/50 {
  stroke: rgb(20 83 45 / 0.5) !important;
}

.tw-stroke-green-900\/55 {
  stroke: rgb(20 83 45 / 0.55) !important;
}

.tw-stroke-green-900\/60 {
  stroke: rgb(20 83 45 / 0.6) !important;
}

.tw-stroke-green-900\/65 {
  stroke: rgb(20 83 45 / 0.65) !important;
}

.tw-stroke-green-900\/70 {
  stroke: rgb(20 83 45 / 0.7) !important;
}

.tw-stroke-green-900\/75 {
  stroke: rgb(20 83 45 / 0.75) !important;
}

.tw-stroke-green-900\/80 {
  stroke: rgb(20 83 45 / 0.8) !important;
}

.tw-stroke-green-900\/85 {
  stroke: rgb(20 83 45 / 0.85) !important;
}

.tw-stroke-green-900\/90 {
  stroke: rgb(20 83 45 / 0.9) !important;
}

.tw-stroke-green-900\/95 {
  stroke: rgb(20 83 45 / 0.95) !important;
}

.tw-stroke-green-950 {
  stroke: #052e16 !important;
}

.tw-stroke-green-950\/0 {
  stroke: rgb(5 46 22 / 0) !important;
}

.tw-stroke-green-950\/10 {
  stroke: rgb(5 46 22 / 0.1) !important;
}

.tw-stroke-green-950\/100 {
  stroke: rgb(5 46 22 / 1) !important;
}

.tw-stroke-green-950\/15 {
  stroke: rgb(5 46 22 / 0.15) !important;
}

.tw-stroke-green-950\/20 {
  stroke: rgb(5 46 22 / 0.2) !important;
}

.tw-stroke-green-950\/25 {
  stroke: rgb(5 46 22 / 0.25) !important;
}

.tw-stroke-green-950\/30 {
  stroke: rgb(5 46 22 / 0.3) !important;
}

.tw-stroke-green-950\/35 {
  stroke: rgb(5 46 22 / 0.35) !important;
}

.tw-stroke-green-950\/40 {
  stroke: rgb(5 46 22 / 0.4) !important;
}

.tw-stroke-green-950\/45 {
  stroke: rgb(5 46 22 / 0.45) !important;
}

.tw-stroke-green-950\/5 {
  stroke: rgb(5 46 22 / 0.05) !important;
}

.tw-stroke-green-950\/50 {
  stroke: rgb(5 46 22 / 0.5) !important;
}

.tw-stroke-green-950\/55 {
  stroke: rgb(5 46 22 / 0.55) !important;
}

.tw-stroke-green-950\/60 {
  stroke: rgb(5 46 22 / 0.6) !important;
}

.tw-stroke-green-950\/65 {
  stroke: rgb(5 46 22 / 0.65) !important;
}

.tw-stroke-green-950\/70 {
  stroke: rgb(5 46 22 / 0.7) !important;
}

.tw-stroke-green-950\/75 {
  stroke: rgb(5 46 22 / 0.75) !important;
}

.tw-stroke-green-950\/80 {
  stroke: rgb(5 46 22 / 0.8) !important;
}

.tw-stroke-green-950\/85 {
  stroke: rgb(5 46 22 / 0.85) !important;
}

.tw-stroke-green-950\/90 {
  stroke: rgb(5 46 22 / 0.9) !important;
}

.tw-stroke-green-950\/95 {
  stroke: rgb(5 46 22 / 0.95) !important;
}

.tw-stroke-indigo-100 {
  stroke: #e0e7ff !important;
}

.tw-stroke-indigo-100\/0 {
  stroke: rgb(224 231 255 / 0) !important;
}

.tw-stroke-indigo-100\/10 {
  stroke: rgb(224 231 255 / 0.1) !important;
}

.tw-stroke-indigo-100\/100 {
  stroke: rgb(224 231 255 / 1) !important;
}

.tw-stroke-indigo-100\/15 {
  stroke: rgb(224 231 255 / 0.15) !important;
}

.tw-stroke-indigo-100\/20 {
  stroke: rgb(224 231 255 / 0.2) !important;
}

.tw-stroke-indigo-100\/25 {
  stroke: rgb(224 231 255 / 0.25) !important;
}

.tw-stroke-indigo-100\/30 {
  stroke: rgb(224 231 255 / 0.3) !important;
}

.tw-stroke-indigo-100\/35 {
  stroke: rgb(224 231 255 / 0.35) !important;
}

.tw-stroke-indigo-100\/40 {
  stroke: rgb(224 231 255 / 0.4) !important;
}

.tw-stroke-indigo-100\/45 {
  stroke: rgb(224 231 255 / 0.45) !important;
}

.tw-stroke-indigo-100\/5 {
  stroke: rgb(224 231 255 / 0.05) !important;
}

.tw-stroke-indigo-100\/50 {
  stroke: rgb(224 231 255 / 0.5) !important;
}

.tw-stroke-indigo-100\/55 {
  stroke: rgb(224 231 255 / 0.55) !important;
}

.tw-stroke-indigo-100\/60 {
  stroke: rgb(224 231 255 / 0.6) !important;
}

.tw-stroke-indigo-100\/65 {
  stroke: rgb(224 231 255 / 0.65) !important;
}

.tw-stroke-indigo-100\/70 {
  stroke: rgb(224 231 255 / 0.7) !important;
}

.tw-stroke-indigo-100\/75 {
  stroke: rgb(224 231 255 / 0.75) !important;
}

.tw-stroke-indigo-100\/80 {
  stroke: rgb(224 231 255 / 0.8) !important;
}

.tw-stroke-indigo-100\/85 {
  stroke: rgb(224 231 255 / 0.85) !important;
}

.tw-stroke-indigo-100\/90 {
  stroke: rgb(224 231 255 / 0.9) !important;
}

.tw-stroke-indigo-100\/95 {
  stroke: rgb(224 231 255 / 0.95) !important;
}

.tw-stroke-indigo-200 {
  stroke: #c7d2fe !important;
}

.tw-stroke-indigo-200\/0 {
  stroke: rgb(199 210 254 / 0) !important;
}

.tw-stroke-indigo-200\/10 {
  stroke: rgb(199 210 254 / 0.1) !important;
}

.tw-stroke-indigo-200\/100 {
  stroke: rgb(199 210 254 / 1) !important;
}

.tw-stroke-indigo-200\/15 {
  stroke: rgb(199 210 254 / 0.15) !important;
}

.tw-stroke-indigo-200\/20 {
  stroke: rgb(199 210 254 / 0.2) !important;
}

.tw-stroke-indigo-200\/25 {
  stroke: rgb(199 210 254 / 0.25) !important;
}

.tw-stroke-indigo-200\/30 {
  stroke: rgb(199 210 254 / 0.3) !important;
}

.tw-stroke-indigo-200\/35 {
  stroke: rgb(199 210 254 / 0.35) !important;
}

.tw-stroke-indigo-200\/40 {
  stroke: rgb(199 210 254 / 0.4) !important;
}

.tw-stroke-indigo-200\/45 {
  stroke: rgb(199 210 254 / 0.45) !important;
}

.tw-stroke-indigo-200\/5 {
  stroke: rgb(199 210 254 / 0.05) !important;
}

.tw-stroke-indigo-200\/50 {
  stroke: rgb(199 210 254 / 0.5) !important;
}

.tw-stroke-indigo-200\/55 {
  stroke: rgb(199 210 254 / 0.55) !important;
}

.tw-stroke-indigo-200\/60 {
  stroke: rgb(199 210 254 / 0.6) !important;
}

.tw-stroke-indigo-200\/65 {
  stroke: rgb(199 210 254 / 0.65) !important;
}

.tw-stroke-indigo-200\/70 {
  stroke: rgb(199 210 254 / 0.7) !important;
}

.tw-stroke-indigo-200\/75 {
  stroke: rgb(199 210 254 / 0.75) !important;
}

.tw-stroke-indigo-200\/80 {
  stroke: rgb(199 210 254 / 0.8) !important;
}

.tw-stroke-indigo-200\/85 {
  stroke: rgb(199 210 254 / 0.85) !important;
}

.tw-stroke-indigo-200\/90 {
  stroke: rgb(199 210 254 / 0.9) !important;
}

.tw-stroke-indigo-200\/95 {
  stroke: rgb(199 210 254 / 0.95) !important;
}

.tw-stroke-indigo-300 {
  stroke: #a5b4fc !important;
}

.tw-stroke-indigo-300\/0 {
  stroke: rgb(165 180 252 / 0) !important;
}

.tw-stroke-indigo-300\/10 {
  stroke: rgb(165 180 252 / 0.1) !important;
}

.tw-stroke-indigo-300\/100 {
  stroke: rgb(165 180 252 / 1) !important;
}

.tw-stroke-indigo-300\/15 {
  stroke: rgb(165 180 252 / 0.15) !important;
}

.tw-stroke-indigo-300\/20 {
  stroke: rgb(165 180 252 / 0.2) !important;
}

.tw-stroke-indigo-300\/25 {
  stroke: rgb(165 180 252 / 0.25) !important;
}

.tw-stroke-indigo-300\/30 {
  stroke: rgb(165 180 252 / 0.3) !important;
}

.tw-stroke-indigo-300\/35 {
  stroke: rgb(165 180 252 / 0.35) !important;
}

.tw-stroke-indigo-300\/40 {
  stroke: rgb(165 180 252 / 0.4) !important;
}

.tw-stroke-indigo-300\/45 {
  stroke: rgb(165 180 252 / 0.45) !important;
}

.tw-stroke-indigo-300\/5 {
  stroke: rgb(165 180 252 / 0.05) !important;
}

.tw-stroke-indigo-300\/50 {
  stroke: rgb(165 180 252 / 0.5) !important;
}

.tw-stroke-indigo-300\/55 {
  stroke: rgb(165 180 252 / 0.55) !important;
}

.tw-stroke-indigo-300\/60 {
  stroke: rgb(165 180 252 / 0.6) !important;
}

.tw-stroke-indigo-300\/65 {
  stroke: rgb(165 180 252 / 0.65) !important;
}

.tw-stroke-indigo-300\/70 {
  stroke: rgb(165 180 252 / 0.7) !important;
}

.tw-stroke-indigo-300\/75 {
  stroke: rgb(165 180 252 / 0.75) !important;
}

.tw-stroke-indigo-300\/80 {
  stroke: rgb(165 180 252 / 0.8) !important;
}

.tw-stroke-indigo-300\/85 {
  stroke: rgb(165 180 252 / 0.85) !important;
}

.tw-stroke-indigo-300\/90 {
  stroke: rgb(165 180 252 / 0.9) !important;
}

.tw-stroke-indigo-300\/95 {
  stroke: rgb(165 180 252 / 0.95) !important;
}

.tw-stroke-indigo-400 {
  stroke: #818cf8 !important;
}

.tw-stroke-indigo-400\/0 {
  stroke: rgb(129 140 248 / 0) !important;
}

.tw-stroke-indigo-400\/10 {
  stroke: rgb(129 140 248 / 0.1) !important;
}

.tw-stroke-indigo-400\/100 {
  stroke: rgb(129 140 248 / 1) !important;
}

.tw-stroke-indigo-400\/15 {
  stroke: rgb(129 140 248 / 0.15) !important;
}

.tw-stroke-indigo-400\/20 {
  stroke: rgb(129 140 248 / 0.2) !important;
}

.tw-stroke-indigo-400\/25 {
  stroke: rgb(129 140 248 / 0.25) !important;
}

.tw-stroke-indigo-400\/30 {
  stroke: rgb(129 140 248 / 0.3) !important;
}

.tw-stroke-indigo-400\/35 {
  stroke: rgb(129 140 248 / 0.35) !important;
}

.tw-stroke-indigo-400\/40 {
  stroke: rgb(129 140 248 / 0.4) !important;
}

.tw-stroke-indigo-400\/45 {
  stroke: rgb(129 140 248 / 0.45) !important;
}

.tw-stroke-indigo-400\/5 {
  stroke: rgb(129 140 248 / 0.05) !important;
}

.tw-stroke-indigo-400\/50 {
  stroke: rgb(129 140 248 / 0.5) !important;
}

.tw-stroke-indigo-400\/55 {
  stroke: rgb(129 140 248 / 0.55) !important;
}

.tw-stroke-indigo-400\/60 {
  stroke: rgb(129 140 248 / 0.6) !important;
}

.tw-stroke-indigo-400\/65 {
  stroke: rgb(129 140 248 / 0.65) !important;
}

.tw-stroke-indigo-400\/70 {
  stroke: rgb(129 140 248 / 0.7) !important;
}

.tw-stroke-indigo-400\/75 {
  stroke: rgb(129 140 248 / 0.75) !important;
}

.tw-stroke-indigo-400\/80 {
  stroke: rgb(129 140 248 / 0.8) !important;
}

.tw-stroke-indigo-400\/85 {
  stroke: rgb(129 140 248 / 0.85) !important;
}

.tw-stroke-indigo-400\/90 {
  stroke: rgb(129 140 248 / 0.9) !important;
}

.tw-stroke-indigo-400\/95 {
  stroke: rgb(129 140 248 / 0.95) !important;
}

.tw-stroke-indigo-50 {
  stroke: #eef2ff !important;
}

.tw-stroke-indigo-50\/0 {
  stroke: rgb(238 242 255 / 0) !important;
}

.tw-stroke-indigo-50\/10 {
  stroke: rgb(238 242 255 / 0.1) !important;
}

.tw-stroke-indigo-50\/100 {
  stroke: rgb(238 242 255 / 1) !important;
}

.tw-stroke-indigo-50\/15 {
  stroke: rgb(238 242 255 / 0.15) !important;
}

.tw-stroke-indigo-50\/20 {
  stroke: rgb(238 242 255 / 0.2) !important;
}

.tw-stroke-indigo-50\/25 {
  stroke: rgb(238 242 255 / 0.25) !important;
}

.tw-stroke-indigo-50\/30 {
  stroke: rgb(238 242 255 / 0.3) !important;
}

.tw-stroke-indigo-50\/35 {
  stroke: rgb(238 242 255 / 0.35) !important;
}

.tw-stroke-indigo-50\/40 {
  stroke: rgb(238 242 255 / 0.4) !important;
}

.tw-stroke-indigo-50\/45 {
  stroke: rgb(238 242 255 / 0.45) !important;
}

.tw-stroke-indigo-50\/5 {
  stroke: rgb(238 242 255 / 0.05) !important;
}

.tw-stroke-indigo-50\/50 {
  stroke: rgb(238 242 255 / 0.5) !important;
}

.tw-stroke-indigo-50\/55 {
  stroke: rgb(238 242 255 / 0.55) !important;
}

.tw-stroke-indigo-50\/60 {
  stroke: rgb(238 242 255 / 0.6) !important;
}

.tw-stroke-indigo-50\/65 {
  stroke: rgb(238 242 255 / 0.65) !important;
}

.tw-stroke-indigo-50\/70 {
  stroke: rgb(238 242 255 / 0.7) !important;
}

.tw-stroke-indigo-50\/75 {
  stroke: rgb(238 242 255 / 0.75) !important;
}

.tw-stroke-indigo-50\/80 {
  stroke: rgb(238 242 255 / 0.8) !important;
}

.tw-stroke-indigo-50\/85 {
  stroke: rgb(238 242 255 / 0.85) !important;
}

.tw-stroke-indigo-50\/90 {
  stroke: rgb(238 242 255 / 0.9) !important;
}

.tw-stroke-indigo-50\/95 {
  stroke: rgb(238 242 255 / 0.95) !important;
}

.tw-stroke-indigo-500 {
  stroke: #6366f1 !important;
}

.tw-stroke-indigo-500\/0 {
  stroke: rgb(99 102 241 / 0) !important;
}

.tw-stroke-indigo-500\/10 {
  stroke: rgb(99 102 241 / 0.1) !important;
}

.tw-stroke-indigo-500\/100 {
  stroke: rgb(99 102 241 / 1) !important;
}

.tw-stroke-indigo-500\/15 {
  stroke: rgb(99 102 241 / 0.15) !important;
}

.tw-stroke-indigo-500\/20 {
  stroke: rgb(99 102 241 / 0.2) !important;
}

.tw-stroke-indigo-500\/25 {
  stroke: rgb(99 102 241 / 0.25) !important;
}

.tw-stroke-indigo-500\/30 {
  stroke: rgb(99 102 241 / 0.3) !important;
}

.tw-stroke-indigo-500\/35 {
  stroke: rgb(99 102 241 / 0.35) !important;
}

.tw-stroke-indigo-500\/40 {
  stroke: rgb(99 102 241 / 0.4) !important;
}

.tw-stroke-indigo-500\/45 {
  stroke: rgb(99 102 241 / 0.45) !important;
}

.tw-stroke-indigo-500\/5 {
  stroke: rgb(99 102 241 / 0.05) !important;
}

.tw-stroke-indigo-500\/50 {
  stroke: rgb(99 102 241 / 0.5) !important;
}

.tw-stroke-indigo-500\/55 {
  stroke: rgb(99 102 241 / 0.55) !important;
}

.tw-stroke-indigo-500\/60 {
  stroke: rgb(99 102 241 / 0.6) !important;
}

.tw-stroke-indigo-500\/65 {
  stroke: rgb(99 102 241 / 0.65) !important;
}

.tw-stroke-indigo-500\/70 {
  stroke: rgb(99 102 241 / 0.7) !important;
}

.tw-stroke-indigo-500\/75 {
  stroke: rgb(99 102 241 / 0.75) !important;
}

.tw-stroke-indigo-500\/80 {
  stroke: rgb(99 102 241 / 0.8) !important;
}

.tw-stroke-indigo-500\/85 {
  stroke: rgb(99 102 241 / 0.85) !important;
}

.tw-stroke-indigo-500\/90 {
  stroke: rgb(99 102 241 / 0.9) !important;
}

.tw-stroke-indigo-500\/95 {
  stroke: rgb(99 102 241 / 0.95) !important;
}

.tw-stroke-indigo-600 {
  stroke: #4f46e5 !important;
}

.tw-stroke-indigo-600\/0 {
  stroke: rgb(79 70 229 / 0) !important;
}

.tw-stroke-indigo-600\/10 {
  stroke: rgb(79 70 229 / 0.1) !important;
}

.tw-stroke-indigo-600\/100 {
  stroke: rgb(79 70 229 / 1) !important;
}

.tw-stroke-indigo-600\/15 {
  stroke: rgb(79 70 229 / 0.15) !important;
}

.tw-stroke-indigo-600\/20 {
  stroke: rgb(79 70 229 / 0.2) !important;
}

.tw-stroke-indigo-600\/25 {
  stroke: rgb(79 70 229 / 0.25) !important;
}

.tw-stroke-indigo-600\/30 {
  stroke: rgb(79 70 229 / 0.3) !important;
}

.tw-stroke-indigo-600\/35 {
  stroke: rgb(79 70 229 / 0.35) !important;
}

.tw-stroke-indigo-600\/40 {
  stroke: rgb(79 70 229 / 0.4) !important;
}

.tw-stroke-indigo-600\/45 {
  stroke: rgb(79 70 229 / 0.45) !important;
}

.tw-stroke-indigo-600\/5 {
  stroke: rgb(79 70 229 / 0.05) !important;
}

.tw-stroke-indigo-600\/50 {
  stroke: rgb(79 70 229 / 0.5) !important;
}

.tw-stroke-indigo-600\/55 {
  stroke: rgb(79 70 229 / 0.55) !important;
}

.tw-stroke-indigo-600\/60 {
  stroke: rgb(79 70 229 / 0.6) !important;
}

.tw-stroke-indigo-600\/65 {
  stroke: rgb(79 70 229 / 0.65) !important;
}

.tw-stroke-indigo-600\/70 {
  stroke: rgb(79 70 229 / 0.7) !important;
}

.tw-stroke-indigo-600\/75 {
  stroke: rgb(79 70 229 / 0.75) !important;
}

.tw-stroke-indigo-600\/80 {
  stroke: rgb(79 70 229 / 0.8) !important;
}

.tw-stroke-indigo-600\/85 {
  stroke: rgb(79 70 229 / 0.85) !important;
}

.tw-stroke-indigo-600\/90 {
  stroke: rgb(79 70 229 / 0.9) !important;
}

.tw-stroke-indigo-600\/95 {
  stroke: rgb(79 70 229 / 0.95) !important;
}

.tw-stroke-indigo-700 {
  stroke: #4338ca !important;
}

.tw-stroke-indigo-700\/0 {
  stroke: rgb(67 56 202 / 0) !important;
}

.tw-stroke-indigo-700\/10 {
  stroke: rgb(67 56 202 / 0.1) !important;
}

.tw-stroke-indigo-700\/100 {
  stroke: rgb(67 56 202 / 1) !important;
}

.tw-stroke-indigo-700\/15 {
  stroke: rgb(67 56 202 / 0.15) !important;
}

.tw-stroke-indigo-700\/20 {
  stroke: rgb(67 56 202 / 0.2) !important;
}

.tw-stroke-indigo-700\/25 {
  stroke: rgb(67 56 202 / 0.25) !important;
}

.tw-stroke-indigo-700\/30 {
  stroke: rgb(67 56 202 / 0.3) !important;
}

.tw-stroke-indigo-700\/35 {
  stroke: rgb(67 56 202 / 0.35) !important;
}

.tw-stroke-indigo-700\/40 {
  stroke: rgb(67 56 202 / 0.4) !important;
}

.tw-stroke-indigo-700\/45 {
  stroke: rgb(67 56 202 / 0.45) !important;
}

.tw-stroke-indigo-700\/5 {
  stroke: rgb(67 56 202 / 0.05) !important;
}

.tw-stroke-indigo-700\/50 {
  stroke: rgb(67 56 202 / 0.5) !important;
}

.tw-stroke-indigo-700\/55 {
  stroke: rgb(67 56 202 / 0.55) !important;
}

.tw-stroke-indigo-700\/60 {
  stroke: rgb(67 56 202 / 0.6) !important;
}

.tw-stroke-indigo-700\/65 {
  stroke: rgb(67 56 202 / 0.65) !important;
}

.tw-stroke-indigo-700\/70 {
  stroke: rgb(67 56 202 / 0.7) !important;
}

.tw-stroke-indigo-700\/75 {
  stroke: rgb(67 56 202 / 0.75) !important;
}

.tw-stroke-indigo-700\/80 {
  stroke: rgb(67 56 202 / 0.8) !important;
}

.tw-stroke-indigo-700\/85 {
  stroke: rgb(67 56 202 / 0.85) !important;
}

.tw-stroke-indigo-700\/90 {
  stroke: rgb(67 56 202 / 0.9) !important;
}

.tw-stroke-indigo-700\/95 {
  stroke: rgb(67 56 202 / 0.95) !important;
}

.tw-stroke-indigo-800 {
  stroke: #3730a3 !important;
}

.tw-stroke-indigo-800\/0 {
  stroke: rgb(55 48 163 / 0) !important;
}

.tw-stroke-indigo-800\/10 {
  stroke: rgb(55 48 163 / 0.1) !important;
}

.tw-stroke-indigo-800\/100 {
  stroke: rgb(55 48 163 / 1) !important;
}

.tw-stroke-indigo-800\/15 {
  stroke: rgb(55 48 163 / 0.15) !important;
}

.tw-stroke-indigo-800\/20 {
  stroke: rgb(55 48 163 / 0.2) !important;
}

.tw-stroke-indigo-800\/25 {
  stroke: rgb(55 48 163 / 0.25) !important;
}

.tw-stroke-indigo-800\/30 {
  stroke: rgb(55 48 163 / 0.3) !important;
}

.tw-stroke-indigo-800\/35 {
  stroke: rgb(55 48 163 / 0.35) !important;
}

.tw-stroke-indigo-800\/40 {
  stroke: rgb(55 48 163 / 0.4) !important;
}

.tw-stroke-indigo-800\/45 {
  stroke: rgb(55 48 163 / 0.45) !important;
}

.tw-stroke-indigo-800\/5 {
  stroke: rgb(55 48 163 / 0.05) !important;
}

.tw-stroke-indigo-800\/50 {
  stroke: rgb(55 48 163 / 0.5) !important;
}

.tw-stroke-indigo-800\/55 {
  stroke: rgb(55 48 163 / 0.55) !important;
}

.tw-stroke-indigo-800\/60 {
  stroke: rgb(55 48 163 / 0.6) !important;
}

.tw-stroke-indigo-800\/65 {
  stroke: rgb(55 48 163 / 0.65) !important;
}

.tw-stroke-indigo-800\/70 {
  stroke: rgb(55 48 163 / 0.7) !important;
}

.tw-stroke-indigo-800\/75 {
  stroke: rgb(55 48 163 / 0.75) !important;
}

.tw-stroke-indigo-800\/80 {
  stroke: rgb(55 48 163 / 0.8) !important;
}

.tw-stroke-indigo-800\/85 {
  stroke: rgb(55 48 163 / 0.85) !important;
}

.tw-stroke-indigo-800\/90 {
  stroke: rgb(55 48 163 / 0.9) !important;
}

.tw-stroke-indigo-800\/95 {
  stroke: rgb(55 48 163 / 0.95) !important;
}

.tw-stroke-indigo-900 {
  stroke: #312e81 !important;
}

.tw-stroke-indigo-900\/0 {
  stroke: rgb(49 46 129 / 0) !important;
}

.tw-stroke-indigo-900\/10 {
  stroke: rgb(49 46 129 / 0.1) !important;
}

.tw-stroke-indigo-900\/100 {
  stroke: rgb(49 46 129 / 1) !important;
}

.tw-stroke-indigo-900\/15 {
  stroke: rgb(49 46 129 / 0.15) !important;
}

.tw-stroke-indigo-900\/20 {
  stroke: rgb(49 46 129 / 0.2) !important;
}

.tw-stroke-indigo-900\/25 {
  stroke: rgb(49 46 129 / 0.25) !important;
}

.tw-stroke-indigo-900\/30 {
  stroke: rgb(49 46 129 / 0.3) !important;
}

.tw-stroke-indigo-900\/35 {
  stroke: rgb(49 46 129 / 0.35) !important;
}

.tw-stroke-indigo-900\/40 {
  stroke: rgb(49 46 129 / 0.4) !important;
}

.tw-stroke-indigo-900\/45 {
  stroke: rgb(49 46 129 / 0.45) !important;
}

.tw-stroke-indigo-900\/5 {
  stroke: rgb(49 46 129 / 0.05) !important;
}

.tw-stroke-indigo-900\/50 {
  stroke: rgb(49 46 129 / 0.5) !important;
}

.tw-stroke-indigo-900\/55 {
  stroke: rgb(49 46 129 / 0.55) !important;
}

.tw-stroke-indigo-900\/60 {
  stroke: rgb(49 46 129 / 0.6) !important;
}

.tw-stroke-indigo-900\/65 {
  stroke: rgb(49 46 129 / 0.65) !important;
}

.tw-stroke-indigo-900\/70 {
  stroke: rgb(49 46 129 / 0.7) !important;
}

.tw-stroke-indigo-900\/75 {
  stroke: rgb(49 46 129 / 0.75) !important;
}

.tw-stroke-indigo-900\/80 {
  stroke: rgb(49 46 129 / 0.8) !important;
}

.tw-stroke-indigo-900\/85 {
  stroke: rgb(49 46 129 / 0.85) !important;
}

.tw-stroke-indigo-900\/90 {
  stroke: rgb(49 46 129 / 0.9) !important;
}

.tw-stroke-indigo-900\/95 {
  stroke: rgb(49 46 129 / 0.95) !important;
}

.tw-stroke-indigo-950 {
  stroke: #1e1b4b !important;
}

.tw-stroke-indigo-950\/0 {
  stroke: rgb(30 27 75 / 0) !important;
}

.tw-stroke-indigo-950\/10 {
  stroke: rgb(30 27 75 / 0.1) !important;
}

.tw-stroke-indigo-950\/100 {
  stroke: rgb(30 27 75 / 1) !important;
}

.tw-stroke-indigo-950\/15 {
  stroke: rgb(30 27 75 / 0.15) !important;
}

.tw-stroke-indigo-950\/20 {
  stroke: rgb(30 27 75 / 0.2) !important;
}

.tw-stroke-indigo-950\/25 {
  stroke: rgb(30 27 75 / 0.25) !important;
}

.tw-stroke-indigo-950\/30 {
  stroke: rgb(30 27 75 / 0.3) !important;
}

.tw-stroke-indigo-950\/35 {
  stroke: rgb(30 27 75 / 0.35) !important;
}

.tw-stroke-indigo-950\/40 {
  stroke: rgb(30 27 75 / 0.4) !important;
}

.tw-stroke-indigo-950\/45 {
  stroke: rgb(30 27 75 / 0.45) !important;
}

.tw-stroke-indigo-950\/5 {
  stroke: rgb(30 27 75 / 0.05) !important;
}

.tw-stroke-indigo-950\/50 {
  stroke: rgb(30 27 75 / 0.5) !important;
}

.tw-stroke-indigo-950\/55 {
  stroke: rgb(30 27 75 / 0.55) !important;
}

.tw-stroke-indigo-950\/60 {
  stroke: rgb(30 27 75 / 0.6) !important;
}

.tw-stroke-indigo-950\/65 {
  stroke: rgb(30 27 75 / 0.65) !important;
}

.tw-stroke-indigo-950\/70 {
  stroke: rgb(30 27 75 / 0.7) !important;
}

.tw-stroke-indigo-950\/75 {
  stroke: rgb(30 27 75 / 0.75) !important;
}

.tw-stroke-indigo-950\/80 {
  stroke: rgb(30 27 75 / 0.8) !important;
}

.tw-stroke-indigo-950\/85 {
  stroke: rgb(30 27 75 / 0.85) !important;
}

.tw-stroke-indigo-950\/90 {
  stroke: rgb(30 27 75 / 0.9) !important;
}

.tw-stroke-indigo-950\/95 {
  stroke: rgb(30 27 75 / 0.95) !important;
}

.tw-stroke-inherit {
  stroke: inherit !important;
}

.tw-stroke-lime-100 {
  stroke: #ecfccb !important;
}

.tw-stroke-lime-100\/0 {
  stroke: rgb(236 252 203 / 0) !important;
}

.tw-stroke-lime-100\/10 {
  stroke: rgb(236 252 203 / 0.1) !important;
}

.tw-stroke-lime-100\/100 {
  stroke: rgb(236 252 203 / 1) !important;
}

.tw-stroke-lime-100\/15 {
  stroke: rgb(236 252 203 / 0.15) !important;
}

.tw-stroke-lime-100\/20 {
  stroke: rgb(236 252 203 / 0.2) !important;
}

.tw-stroke-lime-100\/25 {
  stroke: rgb(236 252 203 / 0.25) !important;
}

.tw-stroke-lime-100\/30 {
  stroke: rgb(236 252 203 / 0.3) !important;
}

.tw-stroke-lime-100\/35 {
  stroke: rgb(236 252 203 / 0.35) !important;
}

.tw-stroke-lime-100\/40 {
  stroke: rgb(236 252 203 / 0.4) !important;
}

.tw-stroke-lime-100\/45 {
  stroke: rgb(236 252 203 / 0.45) !important;
}

.tw-stroke-lime-100\/5 {
  stroke: rgb(236 252 203 / 0.05) !important;
}

.tw-stroke-lime-100\/50 {
  stroke: rgb(236 252 203 / 0.5) !important;
}

.tw-stroke-lime-100\/55 {
  stroke: rgb(236 252 203 / 0.55) !important;
}

.tw-stroke-lime-100\/60 {
  stroke: rgb(236 252 203 / 0.6) !important;
}

.tw-stroke-lime-100\/65 {
  stroke: rgb(236 252 203 / 0.65) !important;
}

.tw-stroke-lime-100\/70 {
  stroke: rgb(236 252 203 / 0.7) !important;
}

.tw-stroke-lime-100\/75 {
  stroke: rgb(236 252 203 / 0.75) !important;
}

.tw-stroke-lime-100\/80 {
  stroke: rgb(236 252 203 / 0.8) !important;
}

.tw-stroke-lime-100\/85 {
  stroke: rgb(236 252 203 / 0.85) !important;
}

.tw-stroke-lime-100\/90 {
  stroke: rgb(236 252 203 / 0.9) !important;
}

.tw-stroke-lime-100\/95 {
  stroke: rgb(236 252 203 / 0.95) !important;
}

.tw-stroke-lime-200 {
  stroke: #d9f99d !important;
}

.tw-stroke-lime-200\/0 {
  stroke: rgb(217 249 157 / 0) !important;
}

.tw-stroke-lime-200\/10 {
  stroke: rgb(217 249 157 / 0.1) !important;
}

.tw-stroke-lime-200\/100 {
  stroke: rgb(217 249 157 / 1) !important;
}

.tw-stroke-lime-200\/15 {
  stroke: rgb(217 249 157 / 0.15) !important;
}

.tw-stroke-lime-200\/20 {
  stroke: rgb(217 249 157 / 0.2) !important;
}

.tw-stroke-lime-200\/25 {
  stroke: rgb(217 249 157 / 0.25) !important;
}

.tw-stroke-lime-200\/30 {
  stroke: rgb(217 249 157 / 0.3) !important;
}

.tw-stroke-lime-200\/35 {
  stroke: rgb(217 249 157 / 0.35) !important;
}

.tw-stroke-lime-200\/40 {
  stroke: rgb(217 249 157 / 0.4) !important;
}

.tw-stroke-lime-200\/45 {
  stroke: rgb(217 249 157 / 0.45) !important;
}

.tw-stroke-lime-200\/5 {
  stroke: rgb(217 249 157 / 0.05) !important;
}

.tw-stroke-lime-200\/50 {
  stroke: rgb(217 249 157 / 0.5) !important;
}

.tw-stroke-lime-200\/55 {
  stroke: rgb(217 249 157 / 0.55) !important;
}

.tw-stroke-lime-200\/60 {
  stroke: rgb(217 249 157 / 0.6) !important;
}

.tw-stroke-lime-200\/65 {
  stroke: rgb(217 249 157 / 0.65) !important;
}

.tw-stroke-lime-200\/70 {
  stroke: rgb(217 249 157 / 0.7) !important;
}

.tw-stroke-lime-200\/75 {
  stroke: rgb(217 249 157 / 0.75) !important;
}

.tw-stroke-lime-200\/80 {
  stroke: rgb(217 249 157 / 0.8) !important;
}

.tw-stroke-lime-200\/85 {
  stroke: rgb(217 249 157 / 0.85) !important;
}

.tw-stroke-lime-200\/90 {
  stroke: rgb(217 249 157 / 0.9) !important;
}

.tw-stroke-lime-200\/95 {
  stroke: rgb(217 249 157 / 0.95) !important;
}

.tw-stroke-lime-300 {
  stroke: #bef264 !important;
}

.tw-stroke-lime-300\/0 {
  stroke: rgb(190 242 100 / 0) !important;
}

.tw-stroke-lime-300\/10 {
  stroke: rgb(190 242 100 / 0.1) !important;
}

.tw-stroke-lime-300\/100 {
  stroke: rgb(190 242 100 / 1) !important;
}

.tw-stroke-lime-300\/15 {
  stroke: rgb(190 242 100 / 0.15) !important;
}

.tw-stroke-lime-300\/20 {
  stroke: rgb(190 242 100 / 0.2) !important;
}

.tw-stroke-lime-300\/25 {
  stroke: rgb(190 242 100 / 0.25) !important;
}

.tw-stroke-lime-300\/30 {
  stroke: rgb(190 242 100 / 0.3) !important;
}

.tw-stroke-lime-300\/35 {
  stroke: rgb(190 242 100 / 0.35) !important;
}

.tw-stroke-lime-300\/40 {
  stroke: rgb(190 242 100 / 0.4) !important;
}

.tw-stroke-lime-300\/45 {
  stroke: rgb(190 242 100 / 0.45) !important;
}

.tw-stroke-lime-300\/5 {
  stroke: rgb(190 242 100 / 0.05) !important;
}

.tw-stroke-lime-300\/50 {
  stroke: rgb(190 242 100 / 0.5) !important;
}

.tw-stroke-lime-300\/55 {
  stroke: rgb(190 242 100 / 0.55) !important;
}

.tw-stroke-lime-300\/60 {
  stroke: rgb(190 242 100 / 0.6) !important;
}

.tw-stroke-lime-300\/65 {
  stroke: rgb(190 242 100 / 0.65) !important;
}

.tw-stroke-lime-300\/70 {
  stroke: rgb(190 242 100 / 0.7) !important;
}

.tw-stroke-lime-300\/75 {
  stroke: rgb(190 242 100 / 0.75) !important;
}

.tw-stroke-lime-300\/80 {
  stroke: rgb(190 242 100 / 0.8) !important;
}

.tw-stroke-lime-300\/85 {
  stroke: rgb(190 242 100 / 0.85) !important;
}

.tw-stroke-lime-300\/90 {
  stroke: rgb(190 242 100 / 0.9) !important;
}

.tw-stroke-lime-300\/95 {
  stroke: rgb(190 242 100 / 0.95) !important;
}

.tw-stroke-lime-400 {
  stroke: #a3e635 !important;
}

.tw-stroke-lime-400\/0 {
  stroke: rgb(163 230 53 / 0) !important;
}

.tw-stroke-lime-400\/10 {
  stroke: rgb(163 230 53 / 0.1) !important;
}

.tw-stroke-lime-400\/100 {
  stroke: rgb(163 230 53 / 1) !important;
}

.tw-stroke-lime-400\/15 {
  stroke: rgb(163 230 53 / 0.15) !important;
}

.tw-stroke-lime-400\/20 {
  stroke: rgb(163 230 53 / 0.2) !important;
}

.tw-stroke-lime-400\/25 {
  stroke: rgb(163 230 53 / 0.25) !important;
}

.tw-stroke-lime-400\/30 {
  stroke: rgb(163 230 53 / 0.3) !important;
}

.tw-stroke-lime-400\/35 {
  stroke: rgb(163 230 53 / 0.35) !important;
}

.tw-stroke-lime-400\/40 {
  stroke: rgb(163 230 53 / 0.4) !important;
}

.tw-stroke-lime-400\/45 {
  stroke: rgb(163 230 53 / 0.45) !important;
}

.tw-stroke-lime-400\/5 {
  stroke: rgb(163 230 53 / 0.05) !important;
}

.tw-stroke-lime-400\/50 {
  stroke: rgb(163 230 53 / 0.5) !important;
}

.tw-stroke-lime-400\/55 {
  stroke: rgb(163 230 53 / 0.55) !important;
}

.tw-stroke-lime-400\/60 {
  stroke: rgb(163 230 53 / 0.6) !important;
}

.tw-stroke-lime-400\/65 {
  stroke: rgb(163 230 53 / 0.65) !important;
}

.tw-stroke-lime-400\/70 {
  stroke: rgb(163 230 53 / 0.7) !important;
}

.tw-stroke-lime-400\/75 {
  stroke: rgb(163 230 53 / 0.75) !important;
}

.tw-stroke-lime-400\/80 {
  stroke: rgb(163 230 53 / 0.8) !important;
}

.tw-stroke-lime-400\/85 {
  stroke: rgb(163 230 53 / 0.85) !important;
}

.tw-stroke-lime-400\/90 {
  stroke: rgb(163 230 53 / 0.9) !important;
}

.tw-stroke-lime-400\/95 {
  stroke: rgb(163 230 53 / 0.95) !important;
}

.tw-stroke-lime-50 {
  stroke: #f7fee7 !important;
}

.tw-stroke-lime-50\/0 {
  stroke: rgb(247 254 231 / 0) !important;
}

.tw-stroke-lime-50\/10 {
  stroke: rgb(247 254 231 / 0.1) !important;
}

.tw-stroke-lime-50\/100 {
  stroke: rgb(247 254 231 / 1) !important;
}

.tw-stroke-lime-50\/15 {
  stroke: rgb(247 254 231 / 0.15) !important;
}

.tw-stroke-lime-50\/20 {
  stroke: rgb(247 254 231 / 0.2) !important;
}

.tw-stroke-lime-50\/25 {
  stroke: rgb(247 254 231 / 0.25) !important;
}

.tw-stroke-lime-50\/30 {
  stroke: rgb(247 254 231 / 0.3) !important;
}

.tw-stroke-lime-50\/35 {
  stroke: rgb(247 254 231 / 0.35) !important;
}

.tw-stroke-lime-50\/40 {
  stroke: rgb(247 254 231 / 0.4) !important;
}

.tw-stroke-lime-50\/45 {
  stroke: rgb(247 254 231 / 0.45) !important;
}

.tw-stroke-lime-50\/5 {
  stroke: rgb(247 254 231 / 0.05) !important;
}

.tw-stroke-lime-50\/50 {
  stroke: rgb(247 254 231 / 0.5) !important;
}

.tw-stroke-lime-50\/55 {
  stroke: rgb(247 254 231 / 0.55) !important;
}

.tw-stroke-lime-50\/60 {
  stroke: rgb(247 254 231 / 0.6) !important;
}

.tw-stroke-lime-50\/65 {
  stroke: rgb(247 254 231 / 0.65) !important;
}

.tw-stroke-lime-50\/70 {
  stroke: rgb(247 254 231 / 0.7) !important;
}

.tw-stroke-lime-50\/75 {
  stroke: rgb(247 254 231 / 0.75) !important;
}

.tw-stroke-lime-50\/80 {
  stroke: rgb(247 254 231 / 0.8) !important;
}

.tw-stroke-lime-50\/85 {
  stroke: rgb(247 254 231 / 0.85) !important;
}

.tw-stroke-lime-50\/90 {
  stroke: rgb(247 254 231 / 0.9) !important;
}

.tw-stroke-lime-50\/95 {
  stroke: rgb(247 254 231 / 0.95) !important;
}

.tw-stroke-lime-500 {
  stroke: #84cc16 !important;
}

.tw-stroke-lime-500\/0 {
  stroke: rgb(132 204 22 / 0) !important;
}

.tw-stroke-lime-500\/10 {
  stroke: rgb(132 204 22 / 0.1) !important;
}

.tw-stroke-lime-500\/100 {
  stroke: rgb(132 204 22 / 1) !important;
}

.tw-stroke-lime-500\/15 {
  stroke: rgb(132 204 22 / 0.15) !important;
}

.tw-stroke-lime-500\/20 {
  stroke: rgb(132 204 22 / 0.2) !important;
}

.tw-stroke-lime-500\/25 {
  stroke: rgb(132 204 22 / 0.25) !important;
}

.tw-stroke-lime-500\/30 {
  stroke: rgb(132 204 22 / 0.3) !important;
}

.tw-stroke-lime-500\/35 {
  stroke: rgb(132 204 22 / 0.35) !important;
}

.tw-stroke-lime-500\/40 {
  stroke: rgb(132 204 22 / 0.4) !important;
}

.tw-stroke-lime-500\/45 {
  stroke: rgb(132 204 22 / 0.45) !important;
}

.tw-stroke-lime-500\/5 {
  stroke: rgb(132 204 22 / 0.05) !important;
}

.tw-stroke-lime-500\/50 {
  stroke: rgb(132 204 22 / 0.5) !important;
}

.tw-stroke-lime-500\/55 {
  stroke: rgb(132 204 22 / 0.55) !important;
}

.tw-stroke-lime-500\/60 {
  stroke: rgb(132 204 22 / 0.6) !important;
}

.tw-stroke-lime-500\/65 {
  stroke: rgb(132 204 22 / 0.65) !important;
}

.tw-stroke-lime-500\/70 {
  stroke: rgb(132 204 22 / 0.7) !important;
}

.tw-stroke-lime-500\/75 {
  stroke: rgb(132 204 22 / 0.75) !important;
}

.tw-stroke-lime-500\/80 {
  stroke: rgb(132 204 22 / 0.8) !important;
}

.tw-stroke-lime-500\/85 {
  stroke: rgb(132 204 22 / 0.85) !important;
}

.tw-stroke-lime-500\/90 {
  stroke: rgb(132 204 22 / 0.9) !important;
}

.tw-stroke-lime-500\/95 {
  stroke: rgb(132 204 22 / 0.95) !important;
}

.tw-stroke-lime-600 {
  stroke: #65a30d !important;
}

.tw-stroke-lime-600\/0 {
  stroke: rgb(101 163 13 / 0) !important;
}

.tw-stroke-lime-600\/10 {
  stroke: rgb(101 163 13 / 0.1) !important;
}

.tw-stroke-lime-600\/100 {
  stroke: rgb(101 163 13 / 1) !important;
}

.tw-stroke-lime-600\/15 {
  stroke: rgb(101 163 13 / 0.15) !important;
}

.tw-stroke-lime-600\/20 {
  stroke: rgb(101 163 13 / 0.2) !important;
}

.tw-stroke-lime-600\/25 {
  stroke: rgb(101 163 13 / 0.25) !important;
}

.tw-stroke-lime-600\/30 {
  stroke: rgb(101 163 13 / 0.3) !important;
}

.tw-stroke-lime-600\/35 {
  stroke: rgb(101 163 13 / 0.35) !important;
}

.tw-stroke-lime-600\/40 {
  stroke: rgb(101 163 13 / 0.4) !important;
}

.tw-stroke-lime-600\/45 {
  stroke: rgb(101 163 13 / 0.45) !important;
}

.tw-stroke-lime-600\/5 {
  stroke: rgb(101 163 13 / 0.05) !important;
}

.tw-stroke-lime-600\/50 {
  stroke: rgb(101 163 13 / 0.5) !important;
}

.tw-stroke-lime-600\/55 {
  stroke: rgb(101 163 13 / 0.55) !important;
}

.tw-stroke-lime-600\/60 {
  stroke: rgb(101 163 13 / 0.6) !important;
}

.tw-stroke-lime-600\/65 {
  stroke: rgb(101 163 13 / 0.65) !important;
}

.tw-stroke-lime-600\/70 {
  stroke: rgb(101 163 13 / 0.7) !important;
}

.tw-stroke-lime-600\/75 {
  stroke: rgb(101 163 13 / 0.75) !important;
}

.tw-stroke-lime-600\/80 {
  stroke: rgb(101 163 13 / 0.8) !important;
}

.tw-stroke-lime-600\/85 {
  stroke: rgb(101 163 13 / 0.85) !important;
}

.tw-stroke-lime-600\/90 {
  stroke: rgb(101 163 13 / 0.9) !important;
}

.tw-stroke-lime-600\/95 {
  stroke: rgb(101 163 13 / 0.95) !important;
}

.tw-stroke-lime-700 {
  stroke: #4d7c0f !important;
}

.tw-stroke-lime-700\/0 {
  stroke: rgb(77 124 15 / 0) !important;
}

.tw-stroke-lime-700\/10 {
  stroke: rgb(77 124 15 / 0.1) !important;
}

.tw-stroke-lime-700\/100 {
  stroke: rgb(77 124 15 / 1) !important;
}

.tw-stroke-lime-700\/15 {
  stroke: rgb(77 124 15 / 0.15) !important;
}

.tw-stroke-lime-700\/20 {
  stroke: rgb(77 124 15 / 0.2) !important;
}

.tw-stroke-lime-700\/25 {
  stroke: rgb(77 124 15 / 0.25) !important;
}

.tw-stroke-lime-700\/30 {
  stroke: rgb(77 124 15 / 0.3) !important;
}

.tw-stroke-lime-700\/35 {
  stroke: rgb(77 124 15 / 0.35) !important;
}

.tw-stroke-lime-700\/40 {
  stroke: rgb(77 124 15 / 0.4) !important;
}

.tw-stroke-lime-700\/45 {
  stroke: rgb(77 124 15 / 0.45) !important;
}

.tw-stroke-lime-700\/5 {
  stroke: rgb(77 124 15 / 0.05) !important;
}

.tw-stroke-lime-700\/50 {
  stroke: rgb(77 124 15 / 0.5) !important;
}

.tw-stroke-lime-700\/55 {
  stroke: rgb(77 124 15 / 0.55) !important;
}

.tw-stroke-lime-700\/60 {
  stroke: rgb(77 124 15 / 0.6) !important;
}

.tw-stroke-lime-700\/65 {
  stroke: rgb(77 124 15 / 0.65) !important;
}

.tw-stroke-lime-700\/70 {
  stroke: rgb(77 124 15 / 0.7) !important;
}

.tw-stroke-lime-700\/75 {
  stroke: rgb(77 124 15 / 0.75) !important;
}

.tw-stroke-lime-700\/80 {
  stroke: rgb(77 124 15 / 0.8) !important;
}

.tw-stroke-lime-700\/85 {
  stroke: rgb(77 124 15 / 0.85) !important;
}

.tw-stroke-lime-700\/90 {
  stroke: rgb(77 124 15 / 0.9) !important;
}

.tw-stroke-lime-700\/95 {
  stroke: rgb(77 124 15 / 0.95) !important;
}

.tw-stroke-lime-800 {
  stroke: #3f6212 !important;
}

.tw-stroke-lime-800\/0 {
  stroke: rgb(63 98 18 / 0) !important;
}

.tw-stroke-lime-800\/10 {
  stroke: rgb(63 98 18 / 0.1) !important;
}

.tw-stroke-lime-800\/100 {
  stroke: rgb(63 98 18 / 1) !important;
}

.tw-stroke-lime-800\/15 {
  stroke: rgb(63 98 18 / 0.15) !important;
}

.tw-stroke-lime-800\/20 {
  stroke: rgb(63 98 18 / 0.2) !important;
}

.tw-stroke-lime-800\/25 {
  stroke: rgb(63 98 18 / 0.25) !important;
}

.tw-stroke-lime-800\/30 {
  stroke: rgb(63 98 18 / 0.3) !important;
}

.tw-stroke-lime-800\/35 {
  stroke: rgb(63 98 18 / 0.35) !important;
}

.tw-stroke-lime-800\/40 {
  stroke: rgb(63 98 18 / 0.4) !important;
}

.tw-stroke-lime-800\/45 {
  stroke: rgb(63 98 18 / 0.45) !important;
}

.tw-stroke-lime-800\/5 {
  stroke: rgb(63 98 18 / 0.05) !important;
}

.tw-stroke-lime-800\/50 {
  stroke: rgb(63 98 18 / 0.5) !important;
}

.tw-stroke-lime-800\/55 {
  stroke: rgb(63 98 18 / 0.55) !important;
}

.tw-stroke-lime-800\/60 {
  stroke: rgb(63 98 18 / 0.6) !important;
}

.tw-stroke-lime-800\/65 {
  stroke: rgb(63 98 18 / 0.65) !important;
}

.tw-stroke-lime-800\/70 {
  stroke: rgb(63 98 18 / 0.7) !important;
}

.tw-stroke-lime-800\/75 {
  stroke: rgb(63 98 18 / 0.75) !important;
}

.tw-stroke-lime-800\/80 {
  stroke: rgb(63 98 18 / 0.8) !important;
}

.tw-stroke-lime-800\/85 {
  stroke: rgb(63 98 18 / 0.85) !important;
}

.tw-stroke-lime-800\/90 {
  stroke: rgb(63 98 18 / 0.9) !important;
}

.tw-stroke-lime-800\/95 {
  stroke: rgb(63 98 18 / 0.95) !important;
}

.tw-stroke-lime-900 {
  stroke: #365314 !important;
}

.tw-stroke-lime-900\/0 {
  stroke: rgb(54 83 20 / 0) !important;
}

.tw-stroke-lime-900\/10 {
  stroke: rgb(54 83 20 / 0.1) !important;
}

.tw-stroke-lime-900\/100 {
  stroke: rgb(54 83 20 / 1) !important;
}

.tw-stroke-lime-900\/15 {
  stroke: rgb(54 83 20 / 0.15) !important;
}

.tw-stroke-lime-900\/20 {
  stroke: rgb(54 83 20 / 0.2) !important;
}

.tw-stroke-lime-900\/25 {
  stroke: rgb(54 83 20 / 0.25) !important;
}

.tw-stroke-lime-900\/30 {
  stroke: rgb(54 83 20 / 0.3) !important;
}

.tw-stroke-lime-900\/35 {
  stroke: rgb(54 83 20 / 0.35) !important;
}

.tw-stroke-lime-900\/40 {
  stroke: rgb(54 83 20 / 0.4) !important;
}

.tw-stroke-lime-900\/45 {
  stroke: rgb(54 83 20 / 0.45) !important;
}

.tw-stroke-lime-900\/5 {
  stroke: rgb(54 83 20 / 0.05) !important;
}

.tw-stroke-lime-900\/50 {
  stroke: rgb(54 83 20 / 0.5) !important;
}

.tw-stroke-lime-900\/55 {
  stroke: rgb(54 83 20 / 0.55) !important;
}

.tw-stroke-lime-900\/60 {
  stroke: rgb(54 83 20 / 0.6) !important;
}

.tw-stroke-lime-900\/65 {
  stroke: rgb(54 83 20 / 0.65) !important;
}

.tw-stroke-lime-900\/70 {
  stroke: rgb(54 83 20 / 0.7) !important;
}

.tw-stroke-lime-900\/75 {
  stroke: rgb(54 83 20 / 0.75) !important;
}

.tw-stroke-lime-900\/80 {
  stroke: rgb(54 83 20 / 0.8) !important;
}

.tw-stroke-lime-900\/85 {
  stroke: rgb(54 83 20 / 0.85) !important;
}

.tw-stroke-lime-900\/90 {
  stroke: rgb(54 83 20 / 0.9) !important;
}

.tw-stroke-lime-900\/95 {
  stroke: rgb(54 83 20 / 0.95) !important;
}

.tw-stroke-lime-950 {
  stroke: #1a2e05 !important;
}

.tw-stroke-lime-950\/0 {
  stroke: rgb(26 46 5 / 0) !important;
}

.tw-stroke-lime-950\/10 {
  stroke: rgb(26 46 5 / 0.1) !important;
}

.tw-stroke-lime-950\/100 {
  stroke: rgb(26 46 5 / 1) !important;
}

.tw-stroke-lime-950\/15 {
  stroke: rgb(26 46 5 / 0.15) !important;
}

.tw-stroke-lime-950\/20 {
  stroke: rgb(26 46 5 / 0.2) !important;
}

.tw-stroke-lime-950\/25 {
  stroke: rgb(26 46 5 / 0.25) !important;
}

.tw-stroke-lime-950\/30 {
  stroke: rgb(26 46 5 / 0.3) !important;
}

.tw-stroke-lime-950\/35 {
  stroke: rgb(26 46 5 / 0.35) !important;
}

.tw-stroke-lime-950\/40 {
  stroke: rgb(26 46 5 / 0.4) !important;
}

.tw-stroke-lime-950\/45 {
  stroke: rgb(26 46 5 / 0.45) !important;
}

.tw-stroke-lime-950\/5 {
  stroke: rgb(26 46 5 / 0.05) !important;
}

.tw-stroke-lime-950\/50 {
  stroke: rgb(26 46 5 / 0.5) !important;
}

.tw-stroke-lime-950\/55 {
  stroke: rgb(26 46 5 / 0.55) !important;
}

.tw-stroke-lime-950\/60 {
  stroke: rgb(26 46 5 / 0.6) !important;
}

.tw-stroke-lime-950\/65 {
  stroke: rgb(26 46 5 / 0.65) !important;
}

.tw-stroke-lime-950\/70 {
  stroke: rgb(26 46 5 / 0.7) !important;
}

.tw-stroke-lime-950\/75 {
  stroke: rgb(26 46 5 / 0.75) !important;
}

.tw-stroke-lime-950\/80 {
  stroke: rgb(26 46 5 / 0.8) !important;
}

.tw-stroke-lime-950\/85 {
  stroke: rgb(26 46 5 / 0.85) !important;
}

.tw-stroke-lime-950\/90 {
  stroke: rgb(26 46 5 / 0.9) !important;
}

.tw-stroke-lime-950\/95 {
  stroke: rgb(26 46 5 / 0.95) !important;
}

.tw-stroke-neutral-100 {
  stroke: #f5f5f5 !important;
}

.tw-stroke-neutral-100\/0 {
  stroke: rgb(245 245 245 / 0) !important;
}

.tw-stroke-neutral-100\/10 {
  stroke: rgb(245 245 245 / 0.1) !important;
}

.tw-stroke-neutral-100\/100 {
  stroke: rgb(245 245 245 / 1) !important;
}

.tw-stroke-neutral-100\/15 {
  stroke: rgb(245 245 245 / 0.15) !important;
}

.tw-stroke-neutral-100\/20 {
  stroke: rgb(245 245 245 / 0.2) !important;
}

.tw-stroke-neutral-100\/25 {
  stroke: rgb(245 245 245 / 0.25) !important;
}

.tw-stroke-neutral-100\/30 {
  stroke: rgb(245 245 245 / 0.3) !important;
}

.tw-stroke-neutral-100\/35 {
  stroke: rgb(245 245 245 / 0.35) !important;
}

.tw-stroke-neutral-100\/40 {
  stroke: rgb(245 245 245 / 0.4) !important;
}

.tw-stroke-neutral-100\/45 {
  stroke: rgb(245 245 245 / 0.45) !important;
}

.tw-stroke-neutral-100\/5 {
  stroke: rgb(245 245 245 / 0.05) !important;
}

.tw-stroke-neutral-100\/50 {
  stroke: rgb(245 245 245 / 0.5) !important;
}

.tw-stroke-neutral-100\/55 {
  stroke: rgb(245 245 245 / 0.55) !important;
}

.tw-stroke-neutral-100\/60 {
  stroke: rgb(245 245 245 / 0.6) !important;
}

.tw-stroke-neutral-100\/65 {
  stroke: rgb(245 245 245 / 0.65) !important;
}

.tw-stroke-neutral-100\/70 {
  stroke: rgb(245 245 245 / 0.7) !important;
}

.tw-stroke-neutral-100\/75 {
  stroke: rgb(245 245 245 / 0.75) !important;
}

.tw-stroke-neutral-100\/80 {
  stroke: rgb(245 245 245 / 0.8) !important;
}

.tw-stroke-neutral-100\/85 {
  stroke: rgb(245 245 245 / 0.85) !important;
}

.tw-stroke-neutral-100\/90 {
  stroke: rgb(245 245 245 / 0.9) !important;
}

.tw-stroke-neutral-100\/95 {
  stroke: rgb(245 245 245 / 0.95) !important;
}

.tw-stroke-neutral-200 {
  stroke: #e5e5e5 !important;
}

.tw-stroke-neutral-200\/0 {
  stroke: rgb(229 229 229 / 0) !important;
}

.tw-stroke-neutral-200\/10 {
  stroke: rgb(229 229 229 / 0.1) !important;
}

.tw-stroke-neutral-200\/100 {
  stroke: rgb(229 229 229 / 1) !important;
}

.tw-stroke-neutral-200\/15 {
  stroke: rgb(229 229 229 / 0.15) !important;
}

.tw-stroke-neutral-200\/20 {
  stroke: rgb(229 229 229 / 0.2) !important;
}

.tw-stroke-neutral-200\/25 {
  stroke: rgb(229 229 229 / 0.25) !important;
}

.tw-stroke-neutral-200\/30 {
  stroke: rgb(229 229 229 / 0.3) !important;
}

.tw-stroke-neutral-200\/35 {
  stroke: rgb(229 229 229 / 0.35) !important;
}

.tw-stroke-neutral-200\/40 {
  stroke: rgb(229 229 229 / 0.4) !important;
}

.tw-stroke-neutral-200\/45 {
  stroke: rgb(229 229 229 / 0.45) !important;
}

.tw-stroke-neutral-200\/5 {
  stroke: rgb(229 229 229 / 0.05) !important;
}

.tw-stroke-neutral-200\/50 {
  stroke: rgb(229 229 229 / 0.5) !important;
}

.tw-stroke-neutral-200\/55 {
  stroke: rgb(229 229 229 / 0.55) !important;
}

.tw-stroke-neutral-200\/60 {
  stroke: rgb(229 229 229 / 0.6) !important;
}

.tw-stroke-neutral-200\/65 {
  stroke: rgb(229 229 229 / 0.65) !important;
}

.tw-stroke-neutral-200\/70 {
  stroke: rgb(229 229 229 / 0.7) !important;
}

.tw-stroke-neutral-200\/75 {
  stroke: rgb(229 229 229 / 0.75) !important;
}

.tw-stroke-neutral-200\/80 {
  stroke: rgb(229 229 229 / 0.8) !important;
}

.tw-stroke-neutral-200\/85 {
  stroke: rgb(229 229 229 / 0.85) !important;
}

.tw-stroke-neutral-200\/90 {
  stroke: rgb(229 229 229 / 0.9) !important;
}

.tw-stroke-neutral-200\/95 {
  stroke: rgb(229 229 229 / 0.95) !important;
}

.tw-stroke-neutral-300 {
  stroke: #d4d4d4 !important;
}

.tw-stroke-neutral-300\/0 {
  stroke: rgb(212 212 212 / 0) !important;
}

.tw-stroke-neutral-300\/10 {
  stroke: rgb(212 212 212 / 0.1) !important;
}

.tw-stroke-neutral-300\/100 {
  stroke: rgb(212 212 212 / 1) !important;
}

.tw-stroke-neutral-300\/15 {
  stroke: rgb(212 212 212 / 0.15) !important;
}

.tw-stroke-neutral-300\/20 {
  stroke: rgb(212 212 212 / 0.2) !important;
}

.tw-stroke-neutral-300\/25 {
  stroke: rgb(212 212 212 / 0.25) !important;
}

.tw-stroke-neutral-300\/30 {
  stroke: rgb(212 212 212 / 0.3) !important;
}

.tw-stroke-neutral-300\/35 {
  stroke: rgb(212 212 212 / 0.35) !important;
}

.tw-stroke-neutral-300\/40 {
  stroke: rgb(212 212 212 / 0.4) !important;
}

.tw-stroke-neutral-300\/45 {
  stroke: rgb(212 212 212 / 0.45) !important;
}

.tw-stroke-neutral-300\/5 {
  stroke: rgb(212 212 212 / 0.05) !important;
}

.tw-stroke-neutral-300\/50 {
  stroke: rgb(212 212 212 / 0.5) !important;
}

.tw-stroke-neutral-300\/55 {
  stroke: rgb(212 212 212 / 0.55) !important;
}

.tw-stroke-neutral-300\/60 {
  stroke: rgb(212 212 212 / 0.6) !important;
}

.tw-stroke-neutral-300\/65 {
  stroke: rgb(212 212 212 / 0.65) !important;
}

.tw-stroke-neutral-300\/70 {
  stroke: rgb(212 212 212 / 0.7) !important;
}

.tw-stroke-neutral-300\/75 {
  stroke: rgb(212 212 212 / 0.75) !important;
}

.tw-stroke-neutral-300\/80 {
  stroke: rgb(212 212 212 / 0.8) !important;
}

.tw-stroke-neutral-300\/85 {
  stroke: rgb(212 212 212 / 0.85) !important;
}

.tw-stroke-neutral-300\/90 {
  stroke: rgb(212 212 212 / 0.9) !important;
}

.tw-stroke-neutral-300\/95 {
  stroke: rgb(212 212 212 / 0.95) !important;
}

.tw-stroke-neutral-400 {
  stroke: #a3a3a3 !important;
}

.tw-stroke-neutral-400\/0 {
  stroke: rgb(163 163 163 / 0) !important;
}

.tw-stroke-neutral-400\/10 {
  stroke: rgb(163 163 163 / 0.1) !important;
}

.tw-stroke-neutral-400\/100 {
  stroke: rgb(163 163 163 / 1) !important;
}

.tw-stroke-neutral-400\/15 {
  stroke: rgb(163 163 163 / 0.15) !important;
}

.tw-stroke-neutral-400\/20 {
  stroke: rgb(163 163 163 / 0.2) !important;
}

.tw-stroke-neutral-400\/25 {
  stroke: rgb(163 163 163 / 0.25) !important;
}

.tw-stroke-neutral-400\/30 {
  stroke: rgb(163 163 163 / 0.3) !important;
}

.tw-stroke-neutral-400\/35 {
  stroke: rgb(163 163 163 / 0.35) !important;
}

.tw-stroke-neutral-400\/40 {
  stroke: rgb(163 163 163 / 0.4) !important;
}

.tw-stroke-neutral-400\/45 {
  stroke: rgb(163 163 163 / 0.45) !important;
}

.tw-stroke-neutral-400\/5 {
  stroke: rgb(163 163 163 / 0.05) !important;
}

.tw-stroke-neutral-400\/50 {
  stroke: rgb(163 163 163 / 0.5) !important;
}

.tw-stroke-neutral-400\/55 {
  stroke: rgb(163 163 163 / 0.55) !important;
}

.tw-stroke-neutral-400\/60 {
  stroke: rgb(163 163 163 / 0.6) !important;
}

.tw-stroke-neutral-400\/65 {
  stroke: rgb(163 163 163 / 0.65) !important;
}

.tw-stroke-neutral-400\/70 {
  stroke: rgb(163 163 163 / 0.7) !important;
}

.tw-stroke-neutral-400\/75 {
  stroke: rgb(163 163 163 / 0.75) !important;
}

.tw-stroke-neutral-400\/80 {
  stroke: rgb(163 163 163 / 0.8) !important;
}

.tw-stroke-neutral-400\/85 {
  stroke: rgb(163 163 163 / 0.85) !important;
}

.tw-stroke-neutral-400\/90 {
  stroke: rgb(163 163 163 / 0.9) !important;
}

.tw-stroke-neutral-400\/95 {
  stroke: rgb(163 163 163 / 0.95) !important;
}

.tw-stroke-neutral-50 {
  stroke: #fafafa !important;
}

.tw-stroke-neutral-50\/0 {
  stroke: rgb(250 250 250 / 0) !important;
}

.tw-stroke-neutral-50\/10 {
  stroke: rgb(250 250 250 / 0.1) !important;
}

.tw-stroke-neutral-50\/100 {
  stroke: rgb(250 250 250 / 1) !important;
}

.tw-stroke-neutral-50\/15 {
  stroke: rgb(250 250 250 / 0.15) !important;
}

.tw-stroke-neutral-50\/20 {
  stroke: rgb(250 250 250 / 0.2) !important;
}

.tw-stroke-neutral-50\/25 {
  stroke: rgb(250 250 250 / 0.25) !important;
}

.tw-stroke-neutral-50\/30 {
  stroke: rgb(250 250 250 / 0.3) !important;
}

.tw-stroke-neutral-50\/35 {
  stroke: rgb(250 250 250 / 0.35) !important;
}

.tw-stroke-neutral-50\/40 {
  stroke: rgb(250 250 250 / 0.4) !important;
}

.tw-stroke-neutral-50\/45 {
  stroke: rgb(250 250 250 / 0.45) !important;
}

.tw-stroke-neutral-50\/5 {
  stroke: rgb(250 250 250 / 0.05) !important;
}

.tw-stroke-neutral-50\/50 {
  stroke: rgb(250 250 250 / 0.5) !important;
}

.tw-stroke-neutral-50\/55 {
  stroke: rgb(250 250 250 / 0.55) !important;
}

.tw-stroke-neutral-50\/60 {
  stroke: rgb(250 250 250 / 0.6) !important;
}

.tw-stroke-neutral-50\/65 {
  stroke: rgb(250 250 250 / 0.65) !important;
}

.tw-stroke-neutral-50\/70 {
  stroke: rgb(250 250 250 / 0.7) !important;
}

.tw-stroke-neutral-50\/75 {
  stroke: rgb(250 250 250 / 0.75) !important;
}

.tw-stroke-neutral-50\/80 {
  stroke: rgb(250 250 250 / 0.8) !important;
}

.tw-stroke-neutral-50\/85 {
  stroke: rgb(250 250 250 / 0.85) !important;
}

.tw-stroke-neutral-50\/90 {
  stroke: rgb(250 250 250 / 0.9) !important;
}

.tw-stroke-neutral-50\/95 {
  stroke: rgb(250 250 250 / 0.95) !important;
}

.tw-stroke-neutral-500 {
  stroke: #737373 !important;
}

.tw-stroke-neutral-500\/0 {
  stroke: rgb(115 115 115 / 0) !important;
}

.tw-stroke-neutral-500\/10 {
  stroke: rgb(115 115 115 / 0.1) !important;
}

.tw-stroke-neutral-500\/100 {
  stroke: rgb(115 115 115 / 1) !important;
}

.tw-stroke-neutral-500\/15 {
  stroke: rgb(115 115 115 / 0.15) !important;
}

.tw-stroke-neutral-500\/20 {
  stroke: rgb(115 115 115 / 0.2) !important;
}

.tw-stroke-neutral-500\/25 {
  stroke: rgb(115 115 115 / 0.25) !important;
}

.tw-stroke-neutral-500\/30 {
  stroke: rgb(115 115 115 / 0.3) !important;
}

.tw-stroke-neutral-500\/35 {
  stroke: rgb(115 115 115 / 0.35) !important;
}

.tw-stroke-neutral-500\/40 {
  stroke: rgb(115 115 115 / 0.4) !important;
}

.tw-stroke-neutral-500\/45 {
  stroke: rgb(115 115 115 / 0.45) !important;
}

.tw-stroke-neutral-500\/5 {
  stroke: rgb(115 115 115 / 0.05) !important;
}

.tw-stroke-neutral-500\/50 {
  stroke: rgb(115 115 115 / 0.5) !important;
}

.tw-stroke-neutral-500\/55 {
  stroke: rgb(115 115 115 / 0.55) !important;
}

.tw-stroke-neutral-500\/60 {
  stroke: rgb(115 115 115 / 0.6) !important;
}

.tw-stroke-neutral-500\/65 {
  stroke: rgb(115 115 115 / 0.65) !important;
}

.tw-stroke-neutral-500\/70 {
  stroke: rgb(115 115 115 / 0.7) !important;
}

.tw-stroke-neutral-500\/75 {
  stroke: rgb(115 115 115 / 0.75) !important;
}

.tw-stroke-neutral-500\/80 {
  stroke: rgb(115 115 115 / 0.8) !important;
}

.tw-stroke-neutral-500\/85 {
  stroke: rgb(115 115 115 / 0.85) !important;
}

.tw-stroke-neutral-500\/90 {
  stroke: rgb(115 115 115 / 0.9) !important;
}

.tw-stroke-neutral-500\/95 {
  stroke: rgb(115 115 115 / 0.95) !important;
}

.tw-stroke-neutral-600 {
  stroke: #525252 !important;
}

.tw-stroke-neutral-600\/0 {
  stroke: rgb(82 82 82 / 0) !important;
}

.tw-stroke-neutral-600\/10 {
  stroke: rgb(82 82 82 / 0.1) !important;
}

.tw-stroke-neutral-600\/100 {
  stroke: rgb(82 82 82 / 1) !important;
}

.tw-stroke-neutral-600\/15 {
  stroke: rgb(82 82 82 / 0.15) !important;
}

.tw-stroke-neutral-600\/20 {
  stroke: rgb(82 82 82 / 0.2) !important;
}

.tw-stroke-neutral-600\/25 {
  stroke: rgb(82 82 82 / 0.25) !important;
}

.tw-stroke-neutral-600\/30 {
  stroke: rgb(82 82 82 / 0.3) !important;
}

.tw-stroke-neutral-600\/35 {
  stroke: rgb(82 82 82 / 0.35) !important;
}

.tw-stroke-neutral-600\/40 {
  stroke: rgb(82 82 82 / 0.4) !important;
}

.tw-stroke-neutral-600\/45 {
  stroke: rgb(82 82 82 / 0.45) !important;
}

.tw-stroke-neutral-600\/5 {
  stroke: rgb(82 82 82 / 0.05) !important;
}

.tw-stroke-neutral-600\/50 {
  stroke: rgb(82 82 82 / 0.5) !important;
}

.tw-stroke-neutral-600\/55 {
  stroke: rgb(82 82 82 / 0.55) !important;
}

.tw-stroke-neutral-600\/60 {
  stroke: rgb(82 82 82 / 0.6) !important;
}

.tw-stroke-neutral-600\/65 {
  stroke: rgb(82 82 82 / 0.65) !important;
}

.tw-stroke-neutral-600\/70 {
  stroke: rgb(82 82 82 / 0.7) !important;
}

.tw-stroke-neutral-600\/75 {
  stroke: rgb(82 82 82 / 0.75) !important;
}

.tw-stroke-neutral-600\/80 {
  stroke: rgb(82 82 82 / 0.8) !important;
}

.tw-stroke-neutral-600\/85 {
  stroke: rgb(82 82 82 / 0.85) !important;
}

.tw-stroke-neutral-600\/90 {
  stroke: rgb(82 82 82 / 0.9) !important;
}

.tw-stroke-neutral-600\/95 {
  stroke: rgb(82 82 82 / 0.95) !important;
}

.tw-stroke-neutral-700 {
  stroke: #404040 !important;
}

.tw-stroke-neutral-700\/0 {
  stroke: rgb(64 64 64 / 0) !important;
}

.tw-stroke-neutral-700\/10 {
  stroke: rgb(64 64 64 / 0.1) !important;
}

.tw-stroke-neutral-700\/100 {
  stroke: rgb(64 64 64 / 1) !important;
}

.tw-stroke-neutral-700\/15 {
  stroke: rgb(64 64 64 / 0.15) !important;
}

.tw-stroke-neutral-700\/20 {
  stroke: rgb(64 64 64 / 0.2) !important;
}

.tw-stroke-neutral-700\/25 {
  stroke: rgb(64 64 64 / 0.25) !important;
}

.tw-stroke-neutral-700\/30 {
  stroke: rgb(64 64 64 / 0.3) !important;
}

.tw-stroke-neutral-700\/35 {
  stroke: rgb(64 64 64 / 0.35) !important;
}

.tw-stroke-neutral-700\/40 {
  stroke: rgb(64 64 64 / 0.4) !important;
}

.tw-stroke-neutral-700\/45 {
  stroke: rgb(64 64 64 / 0.45) !important;
}

.tw-stroke-neutral-700\/5 {
  stroke: rgb(64 64 64 / 0.05) !important;
}

.tw-stroke-neutral-700\/50 {
  stroke: rgb(64 64 64 / 0.5) !important;
}

.tw-stroke-neutral-700\/55 {
  stroke: rgb(64 64 64 / 0.55) !important;
}

.tw-stroke-neutral-700\/60 {
  stroke: rgb(64 64 64 / 0.6) !important;
}

.tw-stroke-neutral-700\/65 {
  stroke: rgb(64 64 64 / 0.65) !important;
}

.tw-stroke-neutral-700\/70 {
  stroke: rgb(64 64 64 / 0.7) !important;
}

.tw-stroke-neutral-700\/75 {
  stroke: rgb(64 64 64 / 0.75) !important;
}

.tw-stroke-neutral-700\/80 {
  stroke: rgb(64 64 64 / 0.8) !important;
}

.tw-stroke-neutral-700\/85 {
  stroke: rgb(64 64 64 / 0.85) !important;
}

.tw-stroke-neutral-700\/90 {
  stroke: rgb(64 64 64 / 0.9) !important;
}

.tw-stroke-neutral-700\/95 {
  stroke: rgb(64 64 64 / 0.95) !important;
}

.tw-stroke-neutral-800 {
  stroke: #262626 !important;
}

.tw-stroke-neutral-800\/0 {
  stroke: rgb(38 38 38 / 0) !important;
}

.tw-stroke-neutral-800\/10 {
  stroke: rgb(38 38 38 / 0.1) !important;
}

.tw-stroke-neutral-800\/100 {
  stroke: rgb(38 38 38 / 1) !important;
}

.tw-stroke-neutral-800\/15 {
  stroke: rgb(38 38 38 / 0.15) !important;
}

.tw-stroke-neutral-800\/20 {
  stroke: rgb(38 38 38 / 0.2) !important;
}

.tw-stroke-neutral-800\/25 {
  stroke: rgb(38 38 38 / 0.25) !important;
}

.tw-stroke-neutral-800\/30 {
  stroke: rgb(38 38 38 / 0.3) !important;
}

.tw-stroke-neutral-800\/35 {
  stroke: rgb(38 38 38 / 0.35) !important;
}

.tw-stroke-neutral-800\/40 {
  stroke: rgb(38 38 38 / 0.4) !important;
}

.tw-stroke-neutral-800\/45 {
  stroke: rgb(38 38 38 / 0.45) !important;
}

.tw-stroke-neutral-800\/5 {
  stroke: rgb(38 38 38 / 0.05) !important;
}

.tw-stroke-neutral-800\/50 {
  stroke: rgb(38 38 38 / 0.5) !important;
}

.tw-stroke-neutral-800\/55 {
  stroke: rgb(38 38 38 / 0.55) !important;
}

.tw-stroke-neutral-800\/60 {
  stroke: rgb(38 38 38 / 0.6) !important;
}

.tw-stroke-neutral-800\/65 {
  stroke: rgb(38 38 38 / 0.65) !important;
}

.tw-stroke-neutral-800\/70 {
  stroke: rgb(38 38 38 / 0.7) !important;
}

.tw-stroke-neutral-800\/75 {
  stroke: rgb(38 38 38 / 0.75) !important;
}

.tw-stroke-neutral-800\/80 {
  stroke: rgb(38 38 38 / 0.8) !important;
}

.tw-stroke-neutral-800\/85 {
  stroke: rgb(38 38 38 / 0.85) !important;
}

.tw-stroke-neutral-800\/90 {
  stroke: rgb(38 38 38 / 0.9) !important;
}

.tw-stroke-neutral-800\/95 {
  stroke: rgb(38 38 38 / 0.95) !important;
}

.tw-stroke-neutral-900 {
  stroke: #171717 !important;
}

.tw-stroke-neutral-900\/0 {
  stroke: rgb(23 23 23 / 0) !important;
}

.tw-stroke-neutral-900\/10 {
  stroke: rgb(23 23 23 / 0.1) !important;
}

.tw-stroke-neutral-900\/100 {
  stroke: rgb(23 23 23 / 1) !important;
}

.tw-stroke-neutral-900\/15 {
  stroke: rgb(23 23 23 / 0.15) !important;
}

.tw-stroke-neutral-900\/20 {
  stroke: rgb(23 23 23 / 0.2) !important;
}

.tw-stroke-neutral-900\/25 {
  stroke: rgb(23 23 23 / 0.25) !important;
}

.tw-stroke-neutral-900\/30 {
  stroke: rgb(23 23 23 / 0.3) !important;
}

.tw-stroke-neutral-900\/35 {
  stroke: rgb(23 23 23 / 0.35) !important;
}

.tw-stroke-neutral-900\/40 {
  stroke: rgb(23 23 23 / 0.4) !important;
}

.tw-stroke-neutral-900\/45 {
  stroke: rgb(23 23 23 / 0.45) !important;
}

.tw-stroke-neutral-900\/5 {
  stroke: rgb(23 23 23 / 0.05) !important;
}

.tw-stroke-neutral-900\/50 {
  stroke: rgb(23 23 23 / 0.5) !important;
}

.tw-stroke-neutral-900\/55 {
  stroke: rgb(23 23 23 / 0.55) !important;
}

.tw-stroke-neutral-900\/60 {
  stroke: rgb(23 23 23 / 0.6) !important;
}

.tw-stroke-neutral-900\/65 {
  stroke: rgb(23 23 23 / 0.65) !important;
}

.tw-stroke-neutral-900\/70 {
  stroke: rgb(23 23 23 / 0.7) !important;
}

.tw-stroke-neutral-900\/75 {
  stroke: rgb(23 23 23 / 0.75) !important;
}

.tw-stroke-neutral-900\/80 {
  stroke: rgb(23 23 23 / 0.8) !important;
}

.tw-stroke-neutral-900\/85 {
  stroke: rgb(23 23 23 / 0.85) !important;
}

.tw-stroke-neutral-900\/90 {
  stroke: rgb(23 23 23 / 0.9) !important;
}

.tw-stroke-neutral-900\/95 {
  stroke: rgb(23 23 23 / 0.95) !important;
}

.tw-stroke-neutral-950 {
  stroke: #0a0a0a !important;
}

.tw-stroke-neutral-950\/0 {
  stroke: rgb(10 10 10 / 0) !important;
}

.tw-stroke-neutral-950\/10 {
  stroke: rgb(10 10 10 / 0.1) !important;
}

.tw-stroke-neutral-950\/100 {
  stroke: rgb(10 10 10 / 1) !important;
}

.tw-stroke-neutral-950\/15 {
  stroke: rgb(10 10 10 / 0.15) !important;
}

.tw-stroke-neutral-950\/20 {
  stroke: rgb(10 10 10 / 0.2) !important;
}

.tw-stroke-neutral-950\/25 {
  stroke: rgb(10 10 10 / 0.25) !important;
}

.tw-stroke-neutral-950\/30 {
  stroke: rgb(10 10 10 / 0.3) !important;
}

.tw-stroke-neutral-950\/35 {
  stroke: rgb(10 10 10 / 0.35) !important;
}

.tw-stroke-neutral-950\/40 {
  stroke: rgb(10 10 10 / 0.4) !important;
}

.tw-stroke-neutral-950\/45 {
  stroke: rgb(10 10 10 / 0.45) !important;
}

.tw-stroke-neutral-950\/5 {
  stroke: rgb(10 10 10 / 0.05) !important;
}

.tw-stroke-neutral-950\/50 {
  stroke: rgb(10 10 10 / 0.5) !important;
}

.tw-stroke-neutral-950\/55 {
  stroke: rgb(10 10 10 / 0.55) !important;
}

.tw-stroke-neutral-950\/60 {
  stroke: rgb(10 10 10 / 0.6) !important;
}

.tw-stroke-neutral-950\/65 {
  stroke: rgb(10 10 10 / 0.65) !important;
}

.tw-stroke-neutral-950\/70 {
  stroke: rgb(10 10 10 / 0.7) !important;
}

.tw-stroke-neutral-950\/75 {
  stroke: rgb(10 10 10 / 0.75) !important;
}

.tw-stroke-neutral-950\/80 {
  stroke: rgb(10 10 10 / 0.8) !important;
}

.tw-stroke-neutral-950\/85 {
  stroke: rgb(10 10 10 / 0.85) !important;
}

.tw-stroke-neutral-950\/90 {
  stroke: rgb(10 10 10 / 0.9) !important;
}

.tw-stroke-neutral-950\/95 {
  stroke: rgb(10 10 10 / 0.95) !important;
}

.tw-stroke-none {
  stroke: none !important;
}

.tw-stroke-orange-100 {
  stroke: #ffedd5 !important;
}

.tw-stroke-orange-100\/0 {
  stroke: rgb(255 237 213 / 0) !important;
}

.tw-stroke-orange-100\/10 {
  stroke: rgb(255 237 213 / 0.1) !important;
}

.tw-stroke-orange-100\/100 {
  stroke: rgb(255 237 213 / 1) !important;
}

.tw-stroke-orange-100\/15 {
  stroke: rgb(255 237 213 / 0.15) !important;
}

.tw-stroke-orange-100\/20 {
  stroke: rgb(255 237 213 / 0.2) !important;
}

.tw-stroke-orange-100\/25 {
  stroke: rgb(255 237 213 / 0.25) !important;
}

.tw-stroke-orange-100\/30 {
  stroke: rgb(255 237 213 / 0.3) !important;
}

.tw-stroke-orange-100\/35 {
  stroke: rgb(255 237 213 / 0.35) !important;
}

.tw-stroke-orange-100\/40 {
  stroke: rgb(255 237 213 / 0.4) !important;
}

.tw-stroke-orange-100\/45 {
  stroke: rgb(255 237 213 / 0.45) !important;
}

.tw-stroke-orange-100\/5 {
  stroke: rgb(255 237 213 / 0.05) !important;
}

.tw-stroke-orange-100\/50 {
  stroke: rgb(255 237 213 / 0.5) !important;
}

.tw-stroke-orange-100\/55 {
  stroke: rgb(255 237 213 / 0.55) !important;
}

.tw-stroke-orange-100\/60 {
  stroke: rgb(255 237 213 / 0.6) !important;
}

.tw-stroke-orange-100\/65 {
  stroke: rgb(255 237 213 / 0.65) !important;
}

.tw-stroke-orange-100\/70 {
  stroke: rgb(255 237 213 / 0.7) !important;
}

.tw-stroke-orange-100\/75 {
  stroke: rgb(255 237 213 / 0.75) !important;
}

.tw-stroke-orange-100\/80 {
  stroke: rgb(255 237 213 / 0.8) !important;
}

.tw-stroke-orange-100\/85 {
  stroke: rgb(255 237 213 / 0.85) !important;
}

.tw-stroke-orange-100\/90 {
  stroke: rgb(255 237 213 / 0.9) !important;
}

.tw-stroke-orange-100\/95 {
  stroke: rgb(255 237 213 / 0.95) !important;
}

.tw-stroke-orange-200 {
  stroke: #fed7aa !important;
}

.tw-stroke-orange-200\/0 {
  stroke: rgb(254 215 170 / 0) !important;
}

.tw-stroke-orange-200\/10 {
  stroke: rgb(254 215 170 / 0.1) !important;
}

.tw-stroke-orange-200\/100 {
  stroke: rgb(254 215 170 / 1) !important;
}

.tw-stroke-orange-200\/15 {
  stroke: rgb(254 215 170 / 0.15) !important;
}

.tw-stroke-orange-200\/20 {
  stroke: rgb(254 215 170 / 0.2) !important;
}

.tw-stroke-orange-200\/25 {
  stroke: rgb(254 215 170 / 0.25) !important;
}

.tw-stroke-orange-200\/30 {
  stroke: rgb(254 215 170 / 0.3) !important;
}

.tw-stroke-orange-200\/35 {
  stroke: rgb(254 215 170 / 0.35) !important;
}

.tw-stroke-orange-200\/40 {
  stroke: rgb(254 215 170 / 0.4) !important;
}

.tw-stroke-orange-200\/45 {
  stroke: rgb(254 215 170 / 0.45) !important;
}

.tw-stroke-orange-200\/5 {
  stroke: rgb(254 215 170 / 0.05) !important;
}

.tw-stroke-orange-200\/50 {
  stroke: rgb(254 215 170 / 0.5) !important;
}

.tw-stroke-orange-200\/55 {
  stroke: rgb(254 215 170 / 0.55) !important;
}

.tw-stroke-orange-200\/60 {
  stroke: rgb(254 215 170 / 0.6) !important;
}

.tw-stroke-orange-200\/65 {
  stroke: rgb(254 215 170 / 0.65) !important;
}

.tw-stroke-orange-200\/70 {
  stroke: rgb(254 215 170 / 0.7) !important;
}

.tw-stroke-orange-200\/75 {
  stroke: rgb(254 215 170 / 0.75) !important;
}

.tw-stroke-orange-200\/80 {
  stroke: rgb(254 215 170 / 0.8) !important;
}

.tw-stroke-orange-200\/85 {
  stroke: rgb(254 215 170 / 0.85) !important;
}

.tw-stroke-orange-200\/90 {
  stroke: rgb(254 215 170 / 0.9) !important;
}

.tw-stroke-orange-200\/95 {
  stroke: rgb(254 215 170 / 0.95) !important;
}

.tw-stroke-orange-300 {
  stroke: #fdba74 !important;
}

.tw-stroke-orange-300\/0 {
  stroke: rgb(253 186 116 / 0) !important;
}

.tw-stroke-orange-300\/10 {
  stroke: rgb(253 186 116 / 0.1) !important;
}

.tw-stroke-orange-300\/100 {
  stroke: rgb(253 186 116 / 1) !important;
}

.tw-stroke-orange-300\/15 {
  stroke: rgb(253 186 116 / 0.15) !important;
}

.tw-stroke-orange-300\/20 {
  stroke: rgb(253 186 116 / 0.2) !important;
}

.tw-stroke-orange-300\/25 {
  stroke: rgb(253 186 116 / 0.25) !important;
}

.tw-stroke-orange-300\/30 {
  stroke: rgb(253 186 116 / 0.3) !important;
}

.tw-stroke-orange-300\/35 {
  stroke: rgb(253 186 116 / 0.35) !important;
}

.tw-stroke-orange-300\/40 {
  stroke: rgb(253 186 116 / 0.4) !important;
}

.tw-stroke-orange-300\/45 {
  stroke: rgb(253 186 116 / 0.45) !important;
}

.tw-stroke-orange-300\/5 {
  stroke: rgb(253 186 116 / 0.05) !important;
}

.tw-stroke-orange-300\/50 {
  stroke: rgb(253 186 116 / 0.5) !important;
}

.tw-stroke-orange-300\/55 {
  stroke: rgb(253 186 116 / 0.55) !important;
}

.tw-stroke-orange-300\/60 {
  stroke: rgb(253 186 116 / 0.6) !important;
}

.tw-stroke-orange-300\/65 {
  stroke: rgb(253 186 116 / 0.65) !important;
}

.tw-stroke-orange-300\/70 {
  stroke: rgb(253 186 116 / 0.7) !important;
}

.tw-stroke-orange-300\/75 {
  stroke: rgb(253 186 116 / 0.75) !important;
}

.tw-stroke-orange-300\/80 {
  stroke: rgb(253 186 116 / 0.8) !important;
}

.tw-stroke-orange-300\/85 {
  stroke: rgb(253 186 116 / 0.85) !important;
}

.tw-stroke-orange-300\/90 {
  stroke: rgb(253 186 116 / 0.9) !important;
}

.tw-stroke-orange-300\/95 {
  stroke: rgb(253 186 116 / 0.95) !important;
}

.tw-stroke-orange-400 {
  stroke: #fb923c !important;
}

.tw-stroke-orange-400\/0 {
  stroke: rgb(251 146 60 / 0) !important;
}

.tw-stroke-orange-400\/10 {
  stroke: rgb(251 146 60 / 0.1) !important;
}

.tw-stroke-orange-400\/100 {
  stroke: rgb(251 146 60 / 1) !important;
}

.tw-stroke-orange-400\/15 {
  stroke: rgb(251 146 60 / 0.15) !important;
}

.tw-stroke-orange-400\/20 {
  stroke: rgb(251 146 60 / 0.2) !important;
}

.tw-stroke-orange-400\/25 {
  stroke: rgb(251 146 60 / 0.25) !important;
}

.tw-stroke-orange-400\/30 {
  stroke: rgb(251 146 60 / 0.3) !important;
}

.tw-stroke-orange-400\/35 {
  stroke: rgb(251 146 60 / 0.35) !important;
}

.tw-stroke-orange-400\/40 {
  stroke: rgb(251 146 60 / 0.4) !important;
}

.tw-stroke-orange-400\/45 {
  stroke: rgb(251 146 60 / 0.45) !important;
}

.tw-stroke-orange-400\/5 {
  stroke: rgb(251 146 60 / 0.05) !important;
}

.tw-stroke-orange-400\/50 {
  stroke: rgb(251 146 60 / 0.5) !important;
}

.tw-stroke-orange-400\/55 {
  stroke: rgb(251 146 60 / 0.55) !important;
}

.tw-stroke-orange-400\/60 {
  stroke: rgb(251 146 60 / 0.6) !important;
}

.tw-stroke-orange-400\/65 {
  stroke: rgb(251 146 60 / 0.65) !important;
}

.tw-stroke-orange-400\/70 {
  stroke: rgb(251 146 60 / 0.7) !important;
}

.tw-stroke-orange-400\/75 {
  stroke: rgb(251 146 60 / 0.75) !important;
}

.tw-stroke-orange-400\/80 {
  stroke: rgb(251 146 60 / 0.8) !important;
}

.tw-stroke-orange-400\/85 {
  stroke: rgb(251 146 60 / 0.85) !important;
}

.tw-stroke-orange-400\/90 {
  stroke: rgb(251 146 60 / 0.9) !important;
}

.tw-stroke-orange-400\/95 {
  stroke: rgb(251 146 60 / 0.95) !important;
}

.tw-stroke-orange-50 {
  stroke: #fff7ed !important;
}

.tw-stroke-orange-50\/0 {
  stroke: rgb(255 247 237 / 0) !important;
}

.tw-stroke-orange-50\/10 {
  stroke: rgb(255 247 237 / 0.1) !important;
}

.tw-stroke-orange-50\/100 {
  stroke: rgb(255 247 237 / 1) !important;
}

.tw-stroke-orange-50\/15 {
  stroke: rgb(255 247 237 / 0.15) !important;
}

.tw-stroke-orange-50\/20 {
  stroke: rgb(255 247 237 / 0.2) !important;
}

.tw-stroke-orange-50\/25 {
  stroke: rgb(255 247 237 / 0.25) !important;
}

.tw-stroke-orange-50\/30 {
  stroke: rgb(255 247 237 / 0.3) !important;
}

.tw-stroke-orange-50\/35 {
  stroke: rgb(255 247 237 / 0.35) !important;
}

.tw-stroke-orange-50\/40 {
  stroke: rgb(255 247 237 / 0.4) !important;
}

.tw-stroke-orange-50\/45 {
  stroke: rgb(255 247 237 / 0.45) !important;
}

.tw-stroke-orange-50\/5 {
  stroke: rgb(255 247 237 / 0.05) !important;
}

.tw-stroke-orange-50\/50 {
  stroke: rgb(255 247 237 / 0.5) !important;
}

.tw-stroke-orange-50\/55 {
  stroke: rgb(255 247 237 / 0.55) !important;
}

.tw-stroke-orange-50\/60 {
  stroke: rgb(255 247 237 / 0.6) !important;
}

.tw-stroke-orange-50\/65 {
  stroke: rgb(255 247 237 / 0.65) !important;
}

.tw-stroke-orange-50\/70 {
  stroke: rgb(255 247 237 / 0.7) !important;
}

.tw-stroke-orange-50\/75 {
  stroke: rgb(255 247 237 / 0.75) !important;
}

.tw-stroke-orange-50\/80 {
  stroke: rgb(255 247 237 / 0.8) !important;
}

.tw-stroke-orange-50\/85 {
  stroke: rgb(255 247 237 / 0.85) !important;
}

.tw-stroke-orange-50\/90 {
  stroke: rgb(255 247 237 / 0.9) !important;
}

.tw-stroke-orange-50\/95 {
  stroke: rgb(255 247 237 / 0.95) !important;
}

.tw-stroke-orange-500 {
  stroke: #f97316 !important;
}

.tw-stroke-orange-500\/0 {
  stroke: rgb(249 115 22 / 0) !important;
}

.tw-stroke-orange-500\/10 {
  stroke: rgb(249 115 22 / 0.1) !important;
}

.tw-stroke-orange-500\/100 {
  stroke: rgb(249 115 22 / 1) !important;
}

.tw-stroke-orange-500\/15 {
  stroke: rgb(249 115 22 / 0.15) !important;
}

.tw-stroke-orange-500\/20 {
  stroke: rgb(249 115 22 / 0.2) !important;
}

.tw-stroke-orange-500\/25 {
  stroke: rgb(249 115 22 / 0.25) !important;
}

.tw-stroke-orange-500\/30 {
  stroke: rgb(249 115 22 / 0.3) !important;
}

.tw-stroke-orange-500\/35 {
  stroke: rgb(249 115 22 / 0.35) !important;
}

.tw-stroke-orange-500\/40 {
  stroke: rgb(249 115 22 / 0.4) !important;
}

.tw-stroke-orange-500\/45 {
  stroke: rgb(249 115 22 / 0.45) !important;
}

.tw-stroke-orange-500\/5 {
  stroke: rgb(249 115 22 / 0.05) !important;
}

.tw-stroke-orange-500\/50 {
  stroke: rgb(249 115 22 / 0.5) !important;
}

.tw-stroke-orange-500\/55 {
  stroke: rgb(249 115 22 / 0.55) !important;
}

.tw-stroke-orange-500\/60 {
  stroke: rgb(249 115 22 / 0.6) !important;
}

.tw-stroke-orange-500\/65 {
  stroke: rgb(249 115 22 / 0.65) !important;
}

.tw-stroke-orange-500\/70 {
  stroke: rgb(249 115 22 / 0.7) !important;
}

.tw-stroke-orange-500\/75 {
  stroke: rgb(249 115 22 / 0.75) !important;
}

.tw-stroke-orange-500\/80 {
  stroke: rgb(249 115 22 / 0.8) !important;
}

.tw-stroke-orange-500\/85 {
  stroke: rgb(249 115 22 / 0.85) !important;
}

.tw-stroke-orange-500\/90 {
  stroke: rgb(249 115 22 / 0.9) !important;
}

.tw-stroke-orange-500\/95 {
  stroke: rgb(249 115 22 / 0.95) !important;
}

.tw-stroke-orange-600 {
  stroke: #ea580c !important;
}

.tw-stroke-orange-600\/0 {
  stroke: rgb(234 88 12 / 0) !important;
}

.tw-stroke-orange-600\/10 {
  stroke: rgb(234 88 12 / 0.1) !important;
}

.tw-stroke-orange-600\/100 {
  stroke: rgb(234 88 12 / 1) !important;
}

.tw-stroke-orange-600\/15 {
  stroke: rgb(234 88 12 / 0.15) !important;
}

.tw-stroke-orange-600\/20 {
  stroke: rgb(234 88 12 / 0.2) !important;
}

.tw-stroke-orange-600\/25 {
  stroke: rgb(234 88 12 / 0.25) !important;
}

.tw-stroke-orange-600\/30 {
  stroke: rgb(234 88 12 / 0.3) !important;
}

.tw-stroke-orange-600\/35 {
  stroke: rgb(234 88 12 / 0.35) !important;
}

.tw-stroke-orange-600\/40 {
  stroke: rgb(234 88 12 / 0.4) !important;
}

.tw-stroke-orange-600\/45 {
  stroke: rgb(234 88 12 / 0.45) !important;
}

.tw-stroke-orange-600\/5 {
  stroke: rgb(234 88 12 / 0.05) !important;
}

.tw-stroke-orange-600\/50 {
  stroke: rgb(234 88 12 / 0.5) !important;
}

.tw-stroke-orange-600\/55 {
  stroke: rgb(234 88 12 / 0.55) !important;
}

.tw-stroke-orange-600\/60 {
  stroke: rgb(234 88 12 / 0.6) !important;
}

.tw-stroke-orange-600\/65 {
  stroke: rgb(234 88 12 / 0.65) !important;
}

.tw-stroke-orange-600\/70 {
  stroke: rgb(234 88 12 / 0.7) !important;
}

.tw-stroke-orange-600\/75 {
  stroke: rgb(234 88 12 / 0.75) !important;
}

.tw-stroke-orange-600\/80 {
  stroke: rgb(234 88 12 / 0.8) !important;
}

.tw-stroke-orange-600\/85 {
  stroke: rgb(234 88 12 / 0.85) !important;
}

.tw-stroke-orange-600\/90 {
  stroke: rgb(234 88 12 / 0.9) !important;
}

.tw-stroke-orange-600\/95 {
  stroke: rgb(234 88 12 / 0.95) !important;
}

.tw-stroke-orange-700 {
  stroke: #c2410c !important;
}

.tw-stroke-orange-700\/0 {
  stroke: rgb(194 65 12 / 0) !important;
}

.tw-stroke-orange-700\/10 {
  stroke: rgb(194 65 12 / 0.1) !important;
}

.tw-stroke-orange-700\/100 {
  stroke: rgb(194 65 12 / 1) !important;
}

.tw-stroke-orange-700\/15 {
  stroke: rgb(194 65 12 / 0.15) !important;
}

.tw-stroke-orange-700\/20 {
  stroke: rgb(194 65 12 / 0.2) !important;
}

.tw-stroke-orange-700\/25 {
  stroke: rgb(194 65 12 / 0.25) !important;
}

.tw-stroke-orange-700\/30 {
  stroke: rgb(194 65 12 / 0.3) !important;
}

.tw-stroke-orange-700\/35 {
  stroke: rgb(194 65 12 / 0.35) !important;
}

.tw-stroke-orange-700\/40 {
  stroke: rgb(194 65 12 / 0.4) !important;
}

.tw-stroke-orange-700\/45 {
  stroke: rgb(194 65 12 / 0.45) !important;
}

.tw-stroke-orange-700\/5 {
  stroke: rgb(194 65 12 / 0.05) !important;
}

.tw-stroke-orange-700\/50 {
  stroke: rgb(194 65 12 / 0.5) !important;
}

.tw-stroke-orange-700\/55 {
  stroke: rgb(194 65 12 / 0.55) !important;
}

.tw-stroke-orange-700\/60 {
  stroke: rgb(194 65 12 / 0.6) !important;
}

.tw-stroke-orange-700\/65 {
  stroke: rgb(194 65 12 / 0.65) !important;
}

.tw-stroke-orange-700\/70 {
  stroke: rgb(194 65 12 / 0.7) !important;
}

.tw-stroke-orange-700\/75 {
  stroke: rgb(194 65 12 / 0.75) !important;
}

.tw-stroke-orange-700\/80 {
  stroke: rgb(194 65 12 / 0.8) !important;
}

.tw-stroke-orange-700\/85 {
  stroke: rgb(194 65 12 / 0.85) !important;
}

.tw-stroke-orange-700\/90 {
  stroke: rgb(194 65 12 / 0.9) !important;
}

.tw-stroke-orange-700\/95 {
  stroke: rgb(194 65 12 / 0.95) !important;
}

.tw-stroke-orange-800 {
  stroke: #9a3412 !important;
}

.tw-stroke-orange-800\/0 {
  stroke: rgb(154 52 18 / 0) !important;
}

.tw-stroke-orange-800\/10 {
  stroke: rgb(154 52 18 / 0.1) !important;
}

.tw-stroke-orange-800\/100 {
  stroke: rgb(154 52 18 / 1) !important;
}

.tw-stroke-orange-800\/15 {
  stroke: rgb(154 52 18 / 0.15) !important;
}

.tw-stroke-orange-800\/20 {
  stroke: rgb(154 52 18 / 0.2) !important;
}

.tw-stroke-orange-800\/25 {
  stroke: rgb(154 52 18 / 0.25) !important;
}

.tw-stroke-orange-800\/30 {
  stroke: rgb(154 52 18 / 0.3) !important;
}

.tw-stroke-orange-800\/35 {
  stroke: rgb(154 52 18 / 0.35) !important;
}

.tw-stroke-orange-800\/40 {
  stroke: rgb(154 52 18 / 0.4) !important;
}

.tw-stroke-orange-800\/45 {
  stroke: rgb(154 52 18 / 0.45) !important;
}

.tw-stroke-orange-800\/5 {
  stroke: rgb(154 52 18 / 0.05) !important;
}

.tw-stroke-orange-800\/50 {
  stroke: rgb(154 52 18 / 0.5) !important;
}

.tw-stroke-orange-800\/55 {
  stroke: rgb(154 52 18 / 0.55) !important;
}

.tw-stroke-orange-800\/60 {
  stroke: rgb(154 52 18 / 0.6) !important;
}

.tw-stroke-orange-800\/65 {
  stroke: rgb(154 52 18 / 0.65) !important;
}

.tw-stroke-orange-800\/70 {
  stroke: rgb(154 52 18 / 0.7) !important;
}

.tw-stroke-orange-800\/75 {
  stroke: rgb(154 52 18 / 0.75) !important;
}

.tw-stroke-orange-800\/80 {
  stroke: rgb(154 52 18 / 0.8) !important;
}

.tw-stroke-orange-800\/85 {
  stroke: rgb(154 52 18 / 0.85) !important;
}

.tw-stroke-orange-800\/90 {
  stroke: rgb(154 52 18 / 0.9) !important;
}

.tw-stroke-orange-800\/95 {
  stroke: rgb(154 52 18 / 0.95) !important;
}

.tw-stroke-orange-900 {
  stroke: #7c2d12 !important;
}

.tw-stroke-orange-900\/0 {
  stroke: rgb(124 45 18 / 0) !important;
}

.tw-stroke-orange-900\/10 {
  stroke: rgb(124 45 18 / 0.1) !important;
}

.tw-stroke-orange-900\/100 {
  stroke: rgb(124 45 18 / 1) !important;
}

.tw-stroke-orange-900\/15 {
  stroke: rgb(124 45 18 / 0.15) !important;
}

.tw-stroke-orange-900\/20 {
  stroke: rgb(124 45 18 / 0.2) !important;
}

.tw-stroke-orange-900\/25 {
  stroke: rgb(124 45 18 / 0.25) !important;
}

.tw-stroke-orange-900\/30 {
  stroke: rgb(124 45 18 / 0.3) !important;
}

.tw-stroke-orange-900\/35 {
  stroke: rgb(124 45 18 / 0.35) !important;
}

.tw-stroke-orange-900\/40 {
  stroke: rgb(124 45 18 / 0.4) !important;
}

.tw-stroke-orange-900\/45 {
  stroke: rgb(124 45 18 / 0.45) !important;
}

.tw-stroke-orange-900\/5 {
  stroke: rgb(124 45 18 / 0.05) !important;
}

.tw-stroke-orange-900\/50 {
  stroke: rgb(124 45 18 / 0.5) !important;
}

.tw-stroke-orange-900\/55 {
  stroke: rgb(124 45 18 / 0.55) !important;
}

.tw-stroke-orange-900\/60 {
  stroke: rgb(124 45 18 / 0.6) !important;
}

.tw-stroke-orange-900\/65 {
  stroke: rgb(124 45 18 / 0.65) !important;
}

.tw-stroke-orange-900\/70 {
  stroke: rgb(124 45 18 / 0.7) !important;
}

.tw-stroke-orange-900\/75 {
  stroke: rgb(124 45 18 / 0.75) !important;
}

.tw-stroke-orange-900\/80 {
  stroke: rgb(124 45 18 / 0.8) !important;
}

.tw-stroke-orange-900\/85 {
  stroke: rgb(124 45 18 / 0.85) !important;
}

.tw-stroke-orange-900\/90 {
  stroke: rgb(124 45 18 / 0.9) !important;
}

.tw-stroke-orange-900\/95 {
  stroke: rgb(124 45 18 / 0.95) !important;
}

.tw-stroke-orange-950 {
  stroke: #431407 !important;
}

.tw-stroke-orange-950\/0 {
  stroke: rgb(67 20 7 / 0) !important;
}

.tw-stroke-orange-950\/10 {
  stroke: rgb(67 20 7 / 0.1) !important;
}

.tw-stroke-orange-950\/100 {
  stroke: rgb(67 20 7 / 1) !important;
}

.tw-stroke-orange-950\/15 {
  stroke: rgb(67 20 7 / 0.15) !important;
}

.tw-stroke-orange-950\/20 {
  stroke: rgb(67 20 7 / 0.2) !important;
}

.tw-stroke-orange-950\/25 {
  stroke: rgb(67 20 7 / 0.25) !important;
}

.tw-stroke-orange-950\/30 {
  stroke: rgb(67 20 7 / 0.3) !important;
}

.tw-stroke-orange-950\/35 {
  stroke: rgb(67 20 7 / 0.35) !important;
}

.tw-stroke-orange-950\/40 {
  stroke: rgb(67 20 7 / 0.4) !important;
}

.tw-stroke-orange-950\/45 {
  stroke: rgb(67 20 7 / 0.45) !important;
}

.tw-stroke-orange-950\/5 {
  stroke: rgb(67 20 7 / 0.05) !important;
}

.tw-stroke-orange-950\/50 {
  stroke: rgb(67 20 7 / 0.5) !important;
}

.tw-stroke-orange-950\/55 {
  stroke: rgb(67 20 7 / 0.55) !important;
}

.tw-stroke-orange-950\/60 {
  stroke: rgb(67 20 7 / 0.6) !important;
}

.tw-stroke-orange-950\/65 {
  stroke: rgb(67 20 7 / 0.65) !important;
}

.tw-stroke-orange-950\/70 {
  stroke: rgb(67 20 7 / 0.7) !important;
}

.tw-stroke-orange-950\/75 {
  stroke: rgb(67 20 7 / 0.75) !important;
}

.tw-stroke-orange-950\/80 {
  stroke: rgb(67 20 7 / 0.8) !important;
}

.tw-stroke-orange-950\/85 {
  stroke: rgb(67 20 7 / 0.85) !important;
}

.tw-stroke-orange-950\/90 {
  stroke: rgb(67 20 7 / 0.9) !important;
}

.tw-stroke-orange-950\/95 {
  stroke: rgb(67 20 7 / 0.95) !important;
}

.tw-stroke-pink-100 {
  stroke: #fce7f3 !important;
}

.tw-stroke-pink-100\/0 {
  stroke: rgb(252 231 243 / 0) !important;
}

.tw-stroke-pink-100\/10 {
  stroke: rgb(252 231 243 / 0.1) !important;
}

.tw-stroke-pink-100\/100 {
  stroke: rgb(252 231 243 / 1) !important;
}

.tw-stroke-pink-100\/15 {
  stroke: rgb(252 231 243 / 0.15) !important;
}

.tw-stroke-pink-100\/20 {
  stroke: rgb(252 231 243 / 0.2) !important;
}

.tw-stroke-pink-100\/25 {
  stroke: rgb(252 231 243 / 0.25) !important;
}

.tw-stroke-pink-100\/30 {
  stroke: rgb(252 231 243 / 0.3) !important;
}

.tw-stroke-pink-100\/35 {
  stroke: rgb(252 231 243 / 0.35) !important;
}

.tw-stroke-pink-100\/40 {
  stroke: rgb(252 231 243 / 0.4) !important;
}

.tw-stroke-pink-100\/45 {
  stroke: rgb(252 231 243 / 0.45) !important;
}

.tw-stroke-pink-100\/5 {
  stroke: rgb(252 231 243 / 0.05) !important;
}

.tw-stroke-pink-100\/50 {
  stroke: rgb(252 231 243 / 0.5) !important;
}

.tw-stroke-pink-100\/55 {
  stroke: rgb(252 231 243 / 0.55) !important;
}

.tw-stroke-pink-100\/60 {
  stroke: rgb(252 231 243 / 0.6) !important;
}

.tw-stroke-pink-100\/65 {
  stroke: rgb(252 231 243 / 0.65) !important;
}

.tw-stroke-pink-100\/70 {
  stroke: rgb(252 231 243 / 0.7) !important;
}

.tw-stroke-pink-100\/75 {
  stroke: rgb(252 231 243 / 0.75) !important;
}

.tw-stroke-pink-100\/80 {
  stroke: rgb(252 231 243 / 0.8) !important;
}

.tw-stroke-pink-100\/85 {
  stroke: rgb(252 231 243 / 0.85) !important;
}

.tw-stroke-pink-100\/90 {
  stroke: rgb(252 231 243 / 0.9) !important;
}

.tw-stroke-pink-100\/95 {
  stroke: rgb(252 231 243 / 0.95) !important;
}

.tw-stroke-pink-200 {
  stroke: #fbcfe8 !important;
}

.tw-stroke-pink-200\/0 {
  stroke: rgb(251 207 232 / 0) !important;
}

.tw-stroke-pink-200\/10 {
  stroke: rgb(251 207 232 / 0.1) !important;
}

.tw-stroke-pink-200\/100 {
  stroke: rgb(251 207 232 / 1) !important;
}

.tw-stroke-pink-200\/15 {
  stroke: rgb(251 207 232 / 0.15) !important;
}

.tw-stroke-pink-200\/20 {
  stroke: rgb(251 207 232 / 0.2) !important;
}

.tw-stroke-pink-200\/25 {
  stroke: rgb(251 207 232 / 0.25) !important;
}

.tw-stroke-pink-200\/30 {
  stroke: rgb(251 207 232 / 0.3) !important;
}

.tw-stroke-pink-200\/35 {
  stroke: rgb(251 207 232 / 0.35) !important;
}

.tw-stroke-pink-200\/40 {
  stroke: rgb(251 207 232 / 0.4) !important;
}

.tw-stroke-pink-200\/45 {
  stroke: rgb(251 207 232 / 0.45) !important;
}

.tw-stroke-pink-200\/5 {
  stroke: rgb(251 207 232 / 0.05) !important;
}

.tw-stroke-pink-200\/50 {
  stroke: rgb(251 207 232 / 0.5) !important;
}

.tw-stroke-pink-200\/55 {
  stroke: rgb(251 207 232 / 0.55) !important;
}

.tw-stroke-pink-200\/60 {
  stroke: rgb(251 207 232 / 0.6) !important;
}

.tw-stroke-pink-200\/65 {
  stroke: rgb(251 207 232 / 0.65) !important;
}

.tw-stroke-pink-200\/70 {
  stroke: rgb(251 207 232 / 0.7) !important;
}

.tw-stroke-pink-200\/75 {
  stroke: rgb(251 207 232 / 0.75) !important;
}

.tw-stroke-pink-200\/80 {
  stroke: rgb(251 207 232 / 0.8) !important;
}

.tw-stroke-pink-200\/85 {
  stroke: rgb(251 207 232 / 0.85) !important;
}

.tw-stroke-pink-200\/90 {
  stroke: rgb(251 207 232 / 0.9) !important;
}

.tw-stroke-pink-200\/95 {
  stroke: rgb(251 207 232 / 0.95) !important;
}

.tw-stroke-pink-300 {
  stroke: #f9a8d4 !important;
}

.tw-stroke-pink-300\/0 {
  stroke: rgb(249 168 212 / 0) !important;
}

.tw-stroke-pink-300\/10 {
  stroke: rgb(249 168 212 / 0.1) !important;
}

.tw-stroke-pink-300\/100 {
  stroke: rgb(249 168 212 / 1) !important;
}

.tw-stroke-pink-300\/15 {
  stroke: rgb(249 168 212 / 0.15) !important;
}

.tw-stroke-pink-300\/20 {
  stroke: rgb(249 168 212 / 0.2) !important;
}

.tw-stroke-pink-300\/25 {
  stroke: rgb(249 168 212 / 0.25) !important;
}

.tw-stroke-pink-300\/30 {
  stroke: rgb(249 168 212 / 0.3) !important;
}

.tw-stroke-pink-300\/35 {
  stroke: rgb(249 168 212 / 0.35) !important;
}

.tw-stroke-pink-300\/40 {
  stroke: rgb(249 168 212 / 0.4) !important;
}

.tw-stroke-pink-300\/45 {
  stroke: rgb(249 168 212 / 0.45) !important;
}

.tw-stroke-pink-300\/5 {
  stroke: rgb(249 168 212 / 0.05) !important;
}

.tw-stroke-pink-300\/50 {
  stroke: rgb(249 168 212 / 0.5) !important;
}

.tw-stroke-pink-300\/55 {
  stroke: rgb(249 168 212 / 0.55) !important;
}

.tw-stroke-pink-300\/60 {
  stroke: rgb(249 168 212 / 0.6) !important;
}

.tw-stroke-pink-300\/65 {
  stroke: rgb(249 168 212 / 0.65) !important;
}

.tw-stroke-pink-300\/70 {
  stroke: rgb(249 168 212 / 0.7) !important;
}

.tw-stroke-pink-300\/75 {
  stroke: rgb(249 168 212 / 0.75) !important;
}

.tw-stroke-pink-300\/80 {
  stroke: rgb(249 168 212 / 0.8) !important;
}

.tw-stroke-pink-300\/85 {
  stroke: rgb(249 168 212 / 0.85) !important;
}

.tw-stroke-pink-300\/90 {
  stroke: rgb(249 168 212 / 0.9) !important;
}

.tw-stroke-pink-300\/95 {
  stroke: rgb(249 168 212 / 0.95) !important;
}

.tw-stroke-pink-400 {
  stroke: #f472b6 !important;
}

.tw-stroke-pink-400\/0 {
  stroke: rgb(244 114 182 / 0) !important;
}

.tw-stroke-pink-400\/10 {
  stroke: rgb(244 114 182 / 0.1) !important;
}

.tw-stroke-pink-400\/100 {
  stroke: rgb(244 114 182 / 1) !important;
}

.tw-stroke-pink-400\/15 {
  stroke: rgb(244 114 182 / 0.15) !important;
}

.tw-stroke-pink-400\/20 {
  stroke: rgb(244 114 182 / 0.2) !important;
}

.tw-stroke-pink-400\/25 {
  stroke: rgb(244 114 182 / 0.25) !important;
}

.tw-stroke-pink-400\/30 {
  stroke: rgb(244 114 182 / 0.3) !important;
}

.tw-stroke-pink-400\/35 {
  stroke: rgb(244 114 182 / 0.35) !important;
}

.tw-stroke-pink-400\/40 {
  stroke: rgb(244 114 182 / 0.4) !important;
}

.tw-stroke-pink-400\/45 {
  stroke: rgb(244 114 182 / 0.45) !important;
}

.tw-stroke-pink-400\/5 {
  stroke: rgb(244 114 182 / 0.05) !important;
}

.tw-stroke-pink-400\/50 {
  stroke: rgb(244 114 182 / 0.5) !important;
}

.tw-stroke-pink-400\/55 {
  stroke: rgb(244 114 182 / 0.55) !important;
}

.tw-stroke-pink-400\/60 {
  stroke: rgb(244 114 182 / 0.6) !important;
}

.tw-stroke-pink-400\/65 {
  stroke: rgb(244 114 182 / 0.65) !important;
}

.tw-stroke-pink-400\/70 {
  stroke: rgb(244 114 182 / 0.7) !important;
}

.tw-stroke-pink-400\/75 {
  stroke: rgb(244 114 182 / 0.75) !important;
}

.tw-stroke-pink-400\/80 {
  stroke: rgb(244 114 182 / 0.8) !important;
}

.tw-stroke-pink-400\/85 {
  stroke: rgb(244 114 182 / 0.85) !important;
}

.tw-stroke-pink-400\/90 {
  stroke: rgb(244 114 182 / 0.9) !important;
}

.tw-stroke-pink-400\/95 {
  stroke: rgb(244 114 182 / 0.95) !important;
}

.tw-stroke-pink-50 {
  stroke: #fdf2f8 !important;
}

.tw-stroke-pink-50\/0 {
  stroke: rgb(253 242 248 / 0) !important;
}

.tw-stroke-pink-50\/10 {
  stroke: rgb(253 242 248 / 0.1) !important;
}

.tw-stroke-pink-50\/100 {
  stroke: rgb(253 242 248 / 1) !important;
}

.tw-stroke-pink-50\/15 {
  stroke: rgb(253 242 248 / 0.15) !important;
}

.tw-stroke-pink-50\/20 {
  stroke: rgb(253 242 248 / 0.2) !important;
}

.tw-stroke-pink-50\/25 {
  stroke: rgb(253 242 248 / 0.25) !important;
}

.tw-stroke-pink-50\/30 {
  stroke: rgb(253 242 248 / 0.3) !important;
}

.tw-stroke-pink-50\/35 {
  stroke: rgb(253 242 248 / 0.35) !important;
}

.tw-stroke-pink-50\/40 {
  stroke: rgb(253 242 248 / 0.4) !important;
}

.tw-stroke-pink-50\/45 {
  stroke: rgb(253 242 248 / 0.45) !important;
}

.tw-stroke-pink-50\/5 {
  stroke: rgb(253 242 248 / 0.05) !important;
}

.tw-stroke-pink-50\/50 {
  stroke: rgb(253 242 248 / 0.5) !important;
}

.tw-stroke-pink-50\/55 {
  stroke: rgb(253 242 248 / 0.55) !important;
}

.tw-stroke-pink-50\/60 {
  stroke: rgb(253 242 248 / 0.6) !important;
}

.tw-stroke-pink-50\/65 {
  stroke: rgb(253 242 248 / 0.65) !important;
}

.tw-stroke-pink-50\/70 {
  stroke: rgb(253 242 248 / 0.7) !important;
}

.tw-stroke-pink-50\/75 {
  stroke: rgb(253 242 248 / 0.75) !important;
}

.tw-stroke-pink-50\/80 {
  stroke: rgb(253 242 248 / 0.8) !important;
}

.tw-stroke-pink-50\/85 {
  stroke: rgb(253 242 248 / 0.85) !important;
}

.tw-stroke-pink-50\/90 {
  stroke: rgb(253 242 248 / 0.9) !important;
}

.tw-stroke-pink-50\/95 {
  stroke: rgb(253 242 248 / 0.95) !important;
}

.tw-stroke-pink-500 {
  stroke: #ec4899 !important;
}

.tw-stroke-pink-500\/0 {
  stroke: rgb(236 72 153 / 0) !important;
}

.tw-stroke-pink-500\/10 {
  stroke: rgb(236 72 153 / 0.1) !important;
}

.tw-stroke-pink-500\/100 {
  stroke: rgb(236 72 153 / 1) !important;
}

.tw-stroke-pink-500\/15 {
  stroke: rgb(236 72 153 / 0.15) !important;
}

.tw-stroke-pink-500\/20 {
  stroke: rgb(236 72 153 / 0.2) !important;
}

.tw-stroke-pink-500\/25 {
  stroke: rgb(236 72 153 / 0.25) !important;
}

.tw-stroke-pink-500\/30 {
  stroke: rgb(236 72 153 / 0.3) !important;
}

.tw-stroke-pink-500\/35 {
  stroke: rgb(236 72 153 / 0.35) !important;
}

.tw-stroke-pink-500\/40 {
  stroke: rgb(236 72 153 / 0.4) !important;
}

.tw-stroke-pink-500\/45 {
  stroke: rgb(236 72 153 / 0.45) !important;
}

.tw-stroke-pink-500\/5 {
  stroke: rgb(236 72 153 / 0.05) !important;
}

.tw-stroke-pink-500\/50 {
  stroke: rgb(236 72 153 / 0.5) !important;
}

.tw-stroke-pink-500\/55 {
  stroke: rgb(236 72 153 / 0.55) !important;
}

.tw-stroke-pink-500\/60 {
  stroke: rgb(236 72 153 / 0.6) !important;
}

.tw-stroke-pink-500\/65 {
  stroke: rgb(236 72 153 / 0.65) !important;
}

.tw-stroke-pink-500\/70 {
  stroke: rgb(236 72 153 / 0.7) !important;
}

.tw-stroke-pink-500\/75 {
  stroke: rgb(236 72 153 / 0.75) !important;
}

.tw-stroke-pink-500\/80 {
  stroke: rgb(236 72 153 / 0.8) !important;
}

.tw-stroke-pink-500\/85 {
  stroke: rgb(236 72 153 / 0.85) !important;
}

.tw-stroke-pink-500\/90 {
  stroke: rgb(236 72 153 / 0.9) !important;
}

.tw-stroke-pink-500\/95 {
  stroke: rgb(236 72 153 / 0.95) !important;
}

.tw-stroke-pink-600 {
  stroke: #db2777 !important;
}

.tw-stroke-pink-600\/0 {
  stroke: rgb(219 39 119 / 0) !important;
}

.tw-stroke-pink-600\/10 {
  stroke: rgb(219 39 119 / 0.1) !important;
}

.tw-stroke-pink-600\/100 {
  stroke: rgb(219 39 119 / 1) !important;
}

.tw-stroke-pink-600\/15 {
  stroke: rgb(219 39 119 / 0.15) !important;
}

.tw-stroke-pink-600\/20 {
  stroke: rgb(219 39 119 / 0.2) !important;
}

.tw-stroke-pink-600\/25 {
  stroke: rgb(219 39 119 / 0.25) !important;
}

.tw-stroke-pink-600\/30 {
  stroke: rgb(219 39 119 / 0.3) !important;
}

.tw-stroke-pink-600\/35 {
  stroke: rgb(219 39 119 / 0.35) !important;
}

.tw-stroke-pink-600\/40 {
  stroke: rgb(219 39 119 / 0.4) !important;
}

.tw-stroke-pink-600\/45 {
  stroke: rgb(219 39 119 / 0.45) !important;
}

.tw-stroke-pink-600\/5 {
  stroke: rgb(219 39 119 / 0.05) !important;
}

.tw-stroke-pink-600\/50 {
  stroke: rgb(219 39 119 / 0.5) !important;
}

.tw-stroke-pink-600\/55 {
  stroke: rgb(219 39 119 / 0.55) !important;
}

.tw-stroke-pink-600\/60 {
  stroke: rgb(219 39 119 / 0.6) !important;
}

.tw-stroke-pink-600\/65 {
  stroke: rgb(219 39 119 / 0.65) !important;
}

.tw-stroke-pink-600\/70 {
  stroke: rgb(219 39 119 / 0.7) !important;
}

.tw-stroke-pink-600\/75 {
  stroke: rgb(219 39 119 / 0.75) !important;
}

.tw-stroke-pink-600\/80 {
  stroke: rgb(219 39 119 / 0.8) !important;
}

.tw-stroke-pink-600\/85 {
  stroke: rgb(219 39 119 / 0.85) !important;
}

.tw-stroke-pink-600\/90 {
  stroke: rgb(219 39 119 / 0.9) !important;
}

.tw-stroke-pink-600\/95 {
  stroke: rgb(219 39 119 / 0.95) !important;
}

.tw-stroke-pink-700 {
  stroke: #be185d !important;
}

.tw-stroke-pink-700\/0 {
  stroke: rgb(190 24 93 / 0) !important;
}

.tw-stroke-pink-700\/10 {
  stroke: rgb(190 24 93 / 0.1) !important;
}

.tw-stroke-pink-700\/100 {
  stroke: rgb(190 24 93 / 1) !important;
}

.tw-stroke-pink-700\/15 {
  stroke: rgb(190 24 93 / 0.15) !important;
}

.tw-stroke-pink-700\/20 {
  stroke: rgb(190 24 93 / 0.2) !important;
}

.tw-stroke-pink-700\/25 {
  stroke: rgb(190 24 93 / 0.25) !important;
}

.tw-stroke-pink-700\/30 {
  stroke: rgb(190 24 93 / 0.3) !important;
}

.tw-stroke-pink-700\/35 {
  stroke: rgb(190 24 93 / 0.35) !important;
}

.tw-stroke-pink-700\/40 {
  stroke: rgb(190 24 93 / 0.4) !important;
}

.tw-stroke-pink-700\/45 {
  stroke: rgb(190 24 93 / 0.45) !important;
}

.tw-stroke-pink-700\/5 {
  stroke: rgb(190 24 93 / 0.05) !important;
}

.tw-stroke-pink-700\/50 {
  stroke: rgb(190 24 93 / 0.5) !important;
}

.tw-stroke-pink-700\/55 {
  stroke: rgb(190 24 93 / 0.55) !important;
}

.tw-stroke-pink-700\/60 {
  stroke: rgb(190 24 93 / 0.6) !important;
}

.tw-stroke-pink-700\/65 {
  stroke: rgb(190 24 93 / 0.65) !important;
}

.tw-stroke-pink-700\/70 {
  stroke: rgb(190 24 93 / 0.7) !important;
}

.tw-stroke-pink-700\/75 {
  stroke: rgb(190 24 93 / 0.75) !important;
}

.tw-stroke-pink-700\/80 {
  stroke: rgb(190 24 93 / 0.8) !important;
}

.tw-stroke-pink-700\/85 {
  stroke: rgb(190 24 93 / 0.85) !important;
}

.tw-stroke-pink-700\/90 {
  stroke: rgb(190 24 93 / 0.9) !important;
}

.tw-stroke-pink-700\/95 {
  stroke: rgb(190 24 93 / 0.95) !important;
}

.tw-stroke-pink-800 {
  stroke: #9d174d !important;
}

.tw-stroke-pink-800\/0 {
  stroke: rgb(157 23 77 / 0) !important;
}

.tw-stroke-pink-800\/10 {
  stroke: rgb(157 23 77 / 0.1) !important;
}

.tw-stroke-pink-800\/100 {
  stroke: rgb(157 23 77 / 1) !important;
}

.tw-stroke-pink-800\/15 {
  stroke: rgb(157 23 77 / 0.15) !important;
}

.tw-stroke-pink-800\/20 {
  stroke: rgb(157 23 77 / 0.2) !important;
}

.tw-stroke-pink-800\/25 {
  stroke: rgb(157 23 77 / 0.25) !important;
}

.tw-stroke-pink-800\/30 {
  stroke: rgb(157 23 77 / 0.3) !important;
}

.tw-stroke-pink-800\/35 {
  stroke: rgb(157 23 77 / 0.35) !important;
}

.tw-stroke-pink-800\/40 {
  stroke: rgb(157 23 77 / 0.4) !important;
}

.tw-stroke-pink-800\/45 {
  stroke: rgb(157 23 77 / 0.45) !important;
}

.tw-stroke-pink-800\/5 {
  stroke: rgb(157 23 77 / 0.05) !important;
}

.tw-stroke-pink-800\/50 {
  stroke: rgb(157 23 77 / 0.5) !important;
}

.tw-stroke-pink-800\/55 {
  stroke: rgb(157 23 77 / 0.55) !important;
}

.tw-stroke-pink-800\/60 {
  stroke: rgb(157 23 77 / 0.6) !important;
}

.tw-stroke-pink-800\/65 {
  stroke: rgb(157 23 77 / 0.65) !important;
}

.tw-stroke-pink-800\/70 {
  stroke: rgb(157 23 77 / 0.7) !important;
}

.tw-stroke-pink-800\/75 {
  stroke: rgb(157 23 77 / 0.75) !important;
}

.tw-stroke-pink-800\/80 {
  stroke: rgb(157 23 77 / 0.8) !important;
}

.tw-stroke-pink-800\/85 {
  stroke: rgb(157 23 77 / 0.85) !important;
}

.tw-stroke-pink-800\/90 {
  stroke: rgb(157 23 77 / 0.9) !important;
}

.tw-stroke-pink-800\/95 {
  stroke: rgb(157 23 77 / 0.95) !important;
}

.tw-stroke-pink-900 {
  stroke: #831843 !important;
}

.tw-stroke-pink-900\/0 {
  stroke: rgb(131 24 67 / 0) !important;
}

.tw-stroke-pink-900\/10 {
  stroke: rgb(131 24 67 / 0.1) !important;
}

.tw-stroke-pink-900\/100 {
  stroke: rgb(131 24 67 / 1) !important;
}

.tw-stroke-pink-900\/15 {
  stroke: rgb(131 24 67 / 0.15) !important;
}

.tw-stroke-pink-900\/20 {
  stroke: rgb(131 24 67 / 0.2) !important;
}

.tw-stroke-pink-900\/25 {
  stroke: rgb(131 24 67 / 0.25) !important;
}

.tw-stroke-pink-900\/30 {
  stroke: rgb(131 24 67 / 0.3) !important;
}

.tw-stroke-pink-900\/35 {
  stroke: rgb(131 24 67 / 0.35) !important;
}

.tw-stroke-pink-900\/40 {
  stroke: rgb(131 24 67 / 0.4) !important;
}

.tw-stroke-pink-900\/45 {
  stroke: rgb(131 24 67 / 0.45) !important;
}

.tw-stroke-pink-900\/5 {
  stroke: rgb(131 24 67 / 0.05) !important;
}

.tw-stroke-pink-900\/50 {
  stroke: rgb(131 24 67 / 0.5) !important;
}

.tw-stroke-pink-900\/55 {
  stroke: rgb(131 24 67 / 0.55) !important;
}

.tw-stroke-pink-900\/60 {
  stroke: rgb(131 24 67 / 0.6) !important;
}

.tw-stroke-pink-900\/65 {
  stroke: rgb(131 24 67 / 0.65) !important;
}

.tw-stroke-pink-900\/70 {
  stroke: rgb(131 24 67 / 0.7) !important;
}

.tw-stroke-pink-900\/75 {
  stroke: rgb(131 24 67 / 0.75) !important;
}

.tw-stroke-pink-900\/80 {
  stroke: rgb(131 24 67 / 0.8) !important;
}

.tw-stroke-pink-900\/85 {
  stroke: rgb(131 24 67 / 0.85) !important;
}

.tw-stroke-pink-900\/90 {
  stroke: rgb(131 24 67 / 0.9) !important;
}

.tw-stroke-pink-900\/95 {
  stroke: rgb(131 24 67 / 0.95) !important;
}

.tw-stroke-pink-950 {
  stroke: #500724 !important;
}

.tw-stroke-pink-950\/0 {
  stroke: rgb(80 7 36 / 0) !important;
}

.tw-stroke-pink-950\/10 {
  stroke: rgb(80 7 36 / 0.1) !important;
}

.tw-stroke-pink-950\/100 {
  stroke: rgb(80 7 36 / 1) !important;
}

.tw-stroke-pink-950\/15 {
  stroke: rgb(80 7 36 / 0.15) !important;
}

.tw-stroke-pink-950\/20 {
  stroke: rgb(80 7 36 / 0.2) !important;
}

.tw-stroke-pink-950\/25 {
  stroke: rgb(80 7 36 / 0.25) !important;
}

.tw-stroke-pink-950\/30 {
  stroke: rgb(80 7 36 / 0.3) !important;
}

.tw-stroke-pink-950\/35 {
  stroke: rgb(80 7 36 / 0.35) !important;
}

.tw-stroke-pink-950\/40 {
  stroke: rgb(80 7 36 / 0.4) !important;
}

.tw-stroke-pink-950\/45 {
  stroke: rgb(80 7 36 / 0.45) !important;
}

.tw-stroke-pink-950\/5 {
  stroke: rgb(80 7 36 / 0.05) !important;
}

.tw-stroke-pink-950\/50 {
  stroke: rgb(80 7 36 / 0.5) !important;
}

.tw-stroke-pink-950\/55 {
  stroke: rgb(80 7 36 / 0.55) !important;
}

.tw-stroke-pink-950\/60 {
  stroke: rgb(80 7 36 / 0.6) !important;
}

.tw-stroke-pink-950\/65 {
  stroke: rgb(80 7 36 / 0.65) !important;
}

.tw-stroke-pink-950\/70 {
  stroke: rgb(80 7 36 / 0.7) !important;
}

.tw-stroke-pink-950\/75 {
  stroke: rgb(80 7 36 / 0.75) !important;
}

.tw-stroke-pink-950\/80 {
  stroke: rgb(80 7 36 / 0.8) !important;
}

.tw-stroke-pink-950\/85 {
  stroke: rgb(80 7 36 / 0.85) !important;
}

.tw-stroke-pink-950\/90 {
  stroke: rgb(80 7 36 / 0.9) !important;
}

.tw-stroke-pink-950\/95 {
  stroke: rgb(80 7 36 / 0.95) !important;
}

.tw-stroke-purple-100 {
  stroke: #f3e8ff !important;
}

.tw-stroke-purple-100\/0 {
  stroke: rgb(243 232 255 / 0) !important;
}

.tw-stroke-purple-100\/10 {
  stroke: rgb(243 232 255 / 0.1) !important;
}

.tw-stroke-purple-100\/100 {
  stroke: rgb(243 232 255 / 1) !important;
}

.tw-stroke-purple-100\/15 {
  stroke: rgb(243 232 255 / 0.15) !important;
}

.tw-stroke-purple-100\/20 {
  stroke: rgb(243 232 255 / 0.2) !important;
}

.tw-stroke-purple-100\/25 {
  stroke: rgb(243 232 255 / 0.25) !important;
}

.tw-stroke-purple-100\/30 {
  stroke: rgb(243 232 255 / 0.3) !important;
}

.tw-stroke-purple-100\/35 {
  stroke: rgb(243 232 255 / 0.35) !important;
}

.tw-stroke-purple-100\/40 {
  stroke: rgb(243 232 255 / 0.4) !important;
}

.tw-stroke-purple-100\/45 {
  stroke: rgb(243 232 255 / 0.45) !important;
}

.tw-stroke-purple-100\/5 {
  stroke: rgb(243 232 255 / 0.05) !important;
}

.tw-stroke-purple-100\/50 {
  stroke: rgb(243 232 255 / 0.5) !important;
}

.tw-stroke-purple-100\/55 {
  stroke: rgb(243 232 255 / 0.55) !important;
}

.tw-stroke-purple-100\/60 {
  stroke: rgb(243 232 255 / 0.6) !important;
}

.tw-stroke-purple-100\/65 {
  stroke: rgb(243 232 255 / 0.65) !important;
}

.tw-stroke-purple-100\/70 {
  stroke: rgb(243 232 255 / 0.7) !important;
}

.tw-stroke-purple-100\/75 {
  stroke: rgb(243 232 255 / 0.75) !important;
}

.tw-stroke-purple-100\/80 {
  stroke: rgb(243 232 255 / 0.8) !important;
}

.tw-stroke-purple-100\/85 {
  stroke: rgb(243 232 255 / 0.85) !important;
}

.tw-stroke-purple-100\/90 {
  stroke: rgb(243 232 255 / 0.9) !important;
}

.tw-stroke-purple-100\/95 {
  stroke: rgb(243 232 255 / 0.95) !important;
}

.tw-stroke-purple-200 {
  stroke: #e9d5ff !important;
}

.tw-stroke-purple-200\/0 {
  stroke: rgb(233 213 255 / 0) !important;
}

.tw-stroke-purple-200\/10 {
  stroke: rgb(233 213 255 / 0.1) !important;
}

.tw-stroke-purple-200\/100 {
  stroke: rgb(233 213 255 / 1) !important;
}

.tw-stroke-purple-200\/15 {
  stroke: rgb(233 213 255 / 0.15) !important;
}

.tw-stroke-purple-200\/20 {
  stroke: rgb(233 213 255 / 0.2) !important;
}

.tw-stroke-purple-200\/25 {
  stroke: rgb(233 213 255 / 0.25) !important;
}

.tw-stroke-purple-200\/30 {
  stroke: rgb(233 213 255 / 0.3) !important;
}

.tw-stroke-purple-200\/35 {
  stroke: rgb(233 213 255 / 0.35) !important;
}

.tw-stroke-purple-200\/40 {
  stroke: rgb(233 213 255 / 0.4) !important;
}

.tw-stroke-purple-200\/45 {
  stroke: rgb(233 213 255 / 0.45) !important;
}

.tw-stroke-purple-200\/5 {
  stroke: rgb(233 213 255 / 0.05) !important;
}

.tw-stroke-purple-200\/50 {
  stroke: rgb(233 213 255 / 0.5) !important;
}

.tw-stroke-purple-200\/55 {
  stroke: rgb(233 213 255 / 0.55) !important;
}

.tw-stroke-purple-200\/60 {
  stroke: rgb(233 213 255 / 0.6) !important;
}

.tw-stroke-purple-200\/65 {
  stroke: rgb(233 213 255 / 0.65) !important;
}

.tw-stroke-purple-200\/70 {
  stroke: rgb(233 213 255 / 0.7) !important;
}

.tw-stroke-purple-200\/75 {
  stroke: rgb(233 213 255 / 0.75) !important;
}

.tw-stroke-purple-200\/80 {
  stroke: rgb(233 213 255 / 0.8) !important;
}

.tw-stroke-purple-200\/85 {
  stroke: rgb(233 213 255 / 0.85) !important;
}

.tw-stroke-purple-200\/90 {
  stroke: rgb(233 213 255 / 0.9) !important;
}

.tw-stroke-purple-200\/95 {
  stroke: rgb(233 213 255 / 0.95) !important;
}

.tw-stroke-purple-300 {
  stroke: #d8b4fe !important;
}

.tw-stroke-purple-300\/0 {
  stroke: rgb(216 180 254 / 0) !important;
}

.tw-stroke-purple-300\/10 {
  stroke: rgb(216 180 254 / 0.1) !important;
}

.tw-stroke-purple-300\/100 {
  stroke: rgb(216 180 254 / 1) !important;
}

.tw-stroke-purple-300\/15 {
  stroke: rgb(216 180 254 / 0.15) !important;
}

.tw-stroke-purple-300\/20 {
  stroke: rgb(216 180 254 / 0.2) !important;
}

.tw-stroke-purple-300\/25 {
  stroke: rgb(216 180 254 / 0.25) !important;
}

.tw-stroke-purple-300\/30 {
  stroke: rgb(216 180 254 / 0.3) !important;
}

.tw-stroke-purple-300\/35 {
  stroke: rgb(216 180 254 / 0.35) !important;
}

.tw-stroke-purple-300\/40 {
  stroke: rgb(216 180 254 / 0.4) !important;
}

.tw-stroke-purple-300\/45 {
  stroke: rgb(216 180 254 / 0.45) !important;
}

.tw-stroke-purple-300\/5 {
  stroke: rgb(216 180 254 / 0.05) !important;
}

.tw-stroke-purple-300\/50 {
  stroke: rgb(216 180 254 / 0.5) !important;
}

.tw-stroke-purple-300\/55 {
  stroke: rgb(216 180 254 / 0.55) !important;
}

.tw-stroke-purple-300\/60 {
  stroke: rgb(216 180 254 / 0.6) !important;
}

.tw-stroke-purple-300\/65 {
  stroke: rgb(216 180 254 / 0.65) !important;
}

.tw-stroke-purple-300\/70 {
  stroke: rgb(216 180 254 / 0.7) !important;
}

.tw-stroke-purple-300\/75 {
  stroke: rgb(216 180 254 / 0.75) !important;
}

.tw-stroke-purple-300\/80 {
  stroke: rgb(216 180 254 / 0.8) !important;
}

.tw-stroke-purple-300\/85 {
  stroke: rgb(216 180 254 / 0.85) !important;
}

.tw-stroke-purple-300\/90 {
  stroke: rgb(216 180 254 / 0.9) !important;
}

.tw-stroke-purple-300\/95 {
  stroke: rgb(216 180 254 / 0.95) !important;
}

.tw-stroke-purple-400 {
  stroke: #c084fc !important;
}

.tw-stroke-purple-400\/0 {
  stroke: rgb(192 132 252 / 0) !important;
}

.tw-stroke-purple-400\/10 {
  stroke: rgb(192 132 252 / 0.1) !important;
}

.tw-stroke-purple-400\/100 {
  stroke: rgb(192 132 252 / 1) !important;
}

.tw-stroke-purple-400\/15 {
  stroke: rgb(192 132 252 / 0.15) !important;
}

.tw-stroke-purple-400\/20 {
  stroke: rgb(192 132 252 / 0.2) !important;
}

.tw-stroke-purple-400\/25 {
  stroke: rgb(192 132 252 / 0.25) !important;
}

.tw-stroke-purple-400\/30 {
  stroke: rgb(192 132 252 / 0.3) !important;
}

.tw-stroke-purple-400\/35 {
  stroke: rgb(192 132 252 / 0.35) !important;
}

.tw-stroke-purple-400\/40 {
  stroke: rgb(192 132 252 / 0.4) !important;
}

.tw-stroke-purple-400\/45 {
  stroke: rgb(192 132 252 / 0.45) !important;
}

.tw-stroke-purple-400\/5 {
  stroke: rgb(192 132 252 / 0.05) !important;
}

.tw-stroke-purple-400\/50 {
  stroke: rgb(192 132 252 / 0.5) !important;
}

.tw-stroke-purple-400\/55 {
  stroke: rgb(192 132 252 / 0.55) !important;
}

.tw-stroke-purple-400\/60 {
  stroke: rgb(192 132 252 / 0.6) !important;
}

.tw-stroke-purple-400\/65 {
  stroke: rgb(192 132 252 / 0.65) !important;
}

.tw-stroke-purple-400\/70 {
  stroke: rgb(192 132 252 / 0.7) !important;
}

.tw-stroke-purple-400\/75 {
  stroke: rgb(192 132 252 / 0.75) !important;
}

.tw-stroke-purple-400\/80 {
  stroke: rgb(192 132 252 / 0.8) !important;
}

.tw-stroke-purple-400\/85 {
  stroke: rgb(192 132 252 / 0.85) !important;
}

.tw-stroke-purple-400\/90 {
  stroke: rgb(192 132 252 / 0.9) !important;
}

.tw-stroke-purple-400\/95 {
  stroke: rgb(192 132 252 / 0.95) !important;
}

.tw-stroke-purple-50 {
  stroke: #faf5ff !important;
}

.tw-stroke-purple-50\/0 {
  stroke: rgb(250 245 255 / 0) !important;
}

.tw-stroke-purple-50\/10 {
  stroke: rgb(250 245 255 / 0.1) !important;
}

.tw-stroke-purple-50\/100 {
  stroke: rgb(250 245 255 / 1) !important;
}

.tw-stroke-purple-50\/15 {
  stroke: rgb(250 245 255 / 0.15) !important;
}

.tw-stroke-purple-50\/20 {
  stroke: rgb(250 245 255 / 0.2) !important;
}

.tw-stroke-purple-50\/25 {
  stroke: rgb(250 245 255 / 0.25) !important;
}

.tw-stroke-purple-50\/30 {
  stroke: rgb(250 245 255 / 0.3) !important;
}

.tw-stroke-purple-50\/35 {
  stroke: rgb(250 245 255 / 0.35) !important;
}

.tw-stroke-purple-50\/40 {
  stroke: rgb(250 245 255 / 0.4) !important;
}

.tw-stroke-purple-50\/45 {
  stroke: rgb(250 245 255 / 0.45) !important;
}

.tw-stroke-purple-50\/5 {
  stroke: rgb(250 245 255 / 0.05) !important;
}

.tw-stroke-purple-50\/50 {
  stroke: rgb(250 245 255 / 0.5) !important;
}

.tw-stroke-purple-50\/55 {
  stroke: rgb(250 245 255 / 0.55) !important;
}

.tw-stroke-purple-50\/60 {
  stroke: rgb(250 245 255 / 0.6) !important;
}

.tw-stroke-purple-50\/65 {
  stroke: rgb(250 245 255 / 0.65) !important;
}

.tw-stroke-purple-50\/70 {
  stroke: rgb(250 245 255 / 0.7) !important;
}

.tw-stroke-purple-50\/75 {
  stroke: rgb(250 245 255 / 0.75) !important;
}

.tw-stroke-purple-50\/80 {
  stroke: rgb(250 245 255 / 0.8) !important;
}

.tw-stroke-purple-50\/85 {
  stroke: rgb(250 245 255 / 0.85) !important;
}

.tw-stroke-purple-50\/90 {
  stroke: rgb(250 245 255 / 0.9) !important;
}

.tw-stroke-purple-50\/95 {
  stroke: rgb(250 245 255 / 0.95) !important;
}

.tw-stroke-purple-500 {
  stroke: #a855f7 !important;
}

.tw-stroke-purple-500\/0 {
  stroke: rgb(168 85 247 / 0) !important;
}

.tw-stroke-purple-500\/10 {
  stroke: rgb(168 85 247 / 0.1) !important;
}

.tw-stroke-purple-500\/100 {
  stroke: rgb(168 85 247 / 1) !important;
}

.tw-stroke-purple-500\/15 {
  stroke: rgb(168 85 247 / 0.15) !important;
}

.tw-stroke-purple-500\/20 {
  stroke: rgb(168 85 247 / 0.2) !important;
}

.tw-stroke-purple-500\/25 {
  stroke: rgb(168 85 247 / 0.25) !important;
}

.tw-stroke-purple-500\/30 {
  stroke: rgb(168 85 247 / 0.3) !important;
}

.tw-stroke-purple-500\/35 {
  stroke: rgb(168 85 247 / 0.35) !important;
}

.tw-stroke-purple-500\/40 {
  stroke: rgb(168 85 247 / 0.4) !important;
}

.tw-stroke-purple-500\/45 {
  stroke: rgb(168 85 247 / 0.45) !important;
}

.tw-stroke-purple-500\/5 {
  stroke: rgb(168 85 247 / 0.05) !important;
}

.tw-stroke-purple-500\/50 {
  stroke: rgb(168 85 247 / 0.5) !important;
}

.tw-stroke-purple-500\/55 {
  stroke: rgb(168 85 247 / 0.55) !important;
}

.tw-stroke-purple-500\/60 {
  stroke: rgb(168 85 247 / 0.6) !important;
}

.tw-stroke-purple-500\/65 {
  stroke: rgb(168 85 247 / 0.65) !important;
}

.tw-stroke-purple-500\/70 {
  stroke: rgb(168 85 247 / 0.7) !important;
}

.tw-stroke-purple-500\/75 {
  stroke: rgb(168 85 247 / 0.75) !important;
}

.tw-stroke-purple-500\/80 {
  stroke: rgb(168 85 247 / 0.8) !important;
}

.tw-stroke-purple-500\/85 {
  stroke: rgb(168 85 247 / 0.85) !important;
}

.tw-stroke-purple-500\/90 {
  stroke: rgb(168 85 247 / 0.9) !important;
}

.tw-stroke-purple-500\/95 {
  stroke: rgb(168 85 247 / 0.95) !important;
}

.tw-stroke-purple-600 {
  stroke: #9333ea !important;
}

.tw-stroke-purple-600\/0 {
  stroke: rgb(147 51 234 / 0) !important;
}

.tw-stroke-purple-600\/10 {
  stroke: rgb(147 51 234 / 0.1) !important;
}

.tw-stroke-purple-600\/100 {
  stroke: rgb(147 51 234 / 1) !important;
}

.tw-stroke-purple-600\/15 {
  stroke: rgb(147 51 234 / 0.15) !important;
}

.tw-stroke-purple-600\/20 {
  stroke: rgb(147 51 234 / 0.2) !important;
}

.tw-stroke-purple-600\/25 {
  stroke: rgb(147 51 234 / 0.25) !important;
}

.tw-stroke-purple-600\/30 {
  stroke: rgb(147 51 234 / 0.3) !important;
}

.tw-stroke-purple-600\/35 {
  stroke: rgb(147 51 234 / 0.35) !important;
}

.tw-stroke-purple-600\/40 {
  stroke: rgb(147 51 234 / 0.4) !important;
}

.tw-stroke-purple-600\/45 {
  stroke: rgb(147 51 234 / 0.45) !important;
}

.tw-stroke-purple-600\/5 {
  stroke: rgb(147 51 234 / 0.05) !important;
}

.tw-stroke-purple-600\/50 {
  stroke: rgb(147 51 234 / 0.5) !important;
}

.tw-stroke-purple-600\/55 {
  stroke: rgb(147 51 234 / 0.55) !important;
}

.tw-stroke-purple-600\/60 {
  stroke: rgb(147 51 234 / 0.6) !important;
}

.tw-stroke-purple-600\/65 {
  stroke: rgb(147 51 234 / 0.65) !important;
}

.tw-stroke-purple-600\/70 {
  stroke: rgb(147 51 234 / 0.7) !important;
}

.tw-stroke-purple-600\/75 {
  stroke: rgb(147 51 234 / 0.75) !important;
}

.tw-stroke-purple-600\/80 {
  stroke: rgb(147 51 234 / 0.8) !important;
}

.tw-stroke-purple-600\/85 {
  stroke: rgb(147 51 234 / 0.85) !important;
}

.tw-stroke-purple-600\/90 {
  stroke: rgb(147 51 234 / 0.9) !important;
}

.tw-stroke-purple-600\/95 {
  stroke: rgb(147 51 234 / 0.95) !important;
}

.tw-stroke-purple-700 {
  stroke: #7e22ce !important;
}

.tw-stroke-purple-700\/0 {
  stroke: rgb(126 34 206 / 0) !important;
}

.tw-stroke-purple-700\/10 {
  stroke: rgb(126 34 206 / 0.1) !important;
}

.tw-stroke-purple-700\/100 {
  stroke: rgb(126 34 206 / 1) !important;
}

.tw-stroke-purple-700\/15 {
  stroke: rgb(126 34 206 / 0.15) !important;
}

.tw-stroke-purple-700\/20 {
  stroke: rgb(126 34 206 / 0.2) !important;
}

.tw-stroke-purple-700\/25 {
  stroke: rgb(126 34 206 / 0.25) !important;
}

.tw-stroke-purple-700\/30 {
  stroke: rgb(126 34 206 / 0.3) !important;
}

.tw-stroke-purple-700\/35 {
  stroke: rgb(126 34 206 / 0.35) !important;
}

.tw-stroke-purple-700\/40 {
  stroke: rgb(126 34 206 / 0.4) !important;
}

.tw-stroke-purple-700\/45 {
  stroke: rgb(126 34 206 / 0.45) !important;
}

.tw-stroke-purple-700\/5 {
  stroke: rgb(126 34 206 / 0.05) !important;
}

.tw-stroke-purple-700\/50 {
  stroke: rgb(126 34 206 / 0.5) !important;
}

.tw-stroke-purple-700\/55 {
  stroke: rgb(126 34 206 / 0.55) !important;
}

.tw-stroke-purple-700\/60 {
  stroke: rgb(126 34 206 / 0.6) !important;
}

.tw-stroke-purple-700\/65 {
  stroke: rgb(126 34 206 / 0.65) !important;
}

.tw-stroke-purple-700\/70 {
  stroke: rgb(126 34 206 / 0.7) !important;
}

.tw-stroke-purple-700\/75 {
  stroke: rgb(126 34 206 / 0.75) !important;
}

.tw-stroke-purple-700\/80 {
  stroke: rgb(126 34 206 / 0.8) !important;
}

.tw-stroke-purple-700\/85 {
  stroke: rgb(126 34 206 / 0.85) !important;
}

.tw-stroke-purple-700\/90 {
  stroke: rgb(126 34 206 / 0.9) !important;
}

.tw-stroke-purple-700\/95 {
  stroke: rgb(126 34 206 / 0.95) !important;
}

.tw-stroke-purple-800 {
  stroke: #6b21a8 !important;
}

.tw-stroke-purple-800\/0 {
  stroke: rgb(107 33 168 / 0) !important;
}

.tw-stroke-purple-800\/10 {
  stroke: rgb(107 33 168 / 0.1) !important;
}

.tw-stroke-purple-800\/100 {
  stroke: rgb(107 33 168 / 1) !important;
}

.tw-stroke-purple-800\/15 {
  stroke: rgb(107 33 168 / 0.15) !important;
}

.tw-stroke-purple-800\/20 {
  stroke: rgb(107 33 168 / 0.2) !important;
}

.tw-stroke-purple-800\/25 {
  stroke: rgb(107 33 168 / 0.25) !important;
}

.tw-stroke-purple-800\/30 {
  stroke: rgb(107 33 168 / 0.3) !important;
}

.tw-stroke-purple-800\/35 {
  stroke: rgb(107 33 168 / 0.35) !important;
}

.tw-stroke-purple-800\/40 {
  stroke: rgb(107 33 168 / 0.4) !important;
}

.tw-stroke-purple-800\/45 {
  stroke: rgb(107 33 168 / 0.45) !important;
}

.tw-stroke-purple-800\/5 {
  stroke: rgb(107 33 168 / 0.05) !important;
}

.tw-stroke-purple-800\/50 {
  stroke: rgb(107 33 168 / 0.5) !important;
}

.tw-stroke-purple-800\/55 {
  stroke: rgb(107 33 168 / 0.55) !important;
}

.tw-stroke-purple-800\/60 {
  stroke: rgb(107 33 168 / 0.6) !important;
}

.tw-stroke-purple-800\/65 {
  stroke: rgb(107 33 168 / 0.65) !important;
}

.tw-stroke-purple-800\/70 {
  stroke: rgb(107 33 168 / 0.7) !important;
}

.tw-stroke-purple-800\/75 {
  stroke: rgb(107 33 168 / 0.75) !important;
}

.tw-stroke-purple-800\/80 {
  stroke: rgb(107 33 168 / 0.8) !important;
}

.tw-stroke-purple-800\/85 {
  stroke: rgb(107 33 168 / 0.85) !important;
}

.tw-stroke-purple-800\/90 {
  stroke: rgb(107 33 168 / 0.9) !important;
}

.tw-stroke-purple-800\/95 {
  stroke: rgb(107 33 168 / 0.95) !important;
}

.tw-stroke-purple-900 {
  stroke: #581c87 !important;
}

.tw-stroke-purple-900\/0 {
  stroke: rgb(88 28 135 / 0) !important;
}

.tw-stroke-purple-900\/10 {
  stroke: rgb(88 28 135 / 0.1) !important;
}

.tw-stroke-purple-900\/100 {
  stroke: rgb(88 28 135 / 1) !important;
}

.tw-stroke-purple-900\/15 {
  stroke: rgb(88 28 135 / 0.15) !important;
}

.tw-stroke-purple-900\/20 {
  stroke: rgb(88 28 135 / 0.2) !important;
}

.tw-stroke-purple-900\/25 {
  stroke: rgb(88 28 135 / 0.25) !important;
}

.tw-stroke-purple-900\/30 {
  stroke: rgb(88 28 135 / 0.3) !important;
}

.tw-stroke-purple-900\/35 {
  stroke: rgb(88 28 135 / 0.35) !important;
}

.tw-stroke-purple-900\/40 {
  stroke: rgb(88 28 135 / 0.4) !important;
}

.tw-stroke-purple-900\/45 {
  stroke: rgb(88 28 135 / 0.45) !important;
}

.tw-stroke-purple-900\/5 {
  stroke: rgb(88 28 135 / 0.05) !important;
}

.tw-stroke-purple-900\/50 {
  stroke: rgb(88 28 135 / 0.5) !important;
}

.tw-stroke-purple-900\/55 {
  stroke: rgb(88 28 135 / 0.55) !important;
}

.tw-stroke-purple-900\/60 {
  stroke: rgb(88 28 135 / 0.6) !important;
}

.tw-stroke-purple-900\/65 {
  stroke: rgb(88 28 135 / 0.65) !important;
}

.tw-stroke-purple-900\/70 {
  stroke: rgb(88 28 135 / 0.7) !important;
}

.tw-stroke-purple-900\/75 {
  stroke: rgb(88 28 135 / 0.75) !important;
}

.tw-stroke-purple-900\/80 {
  stroke: rgb(88 28 135 / 0.8) !important;
}

.tw-stroke-purple-900\/85 {
  stroke: rgb(88 28 135 / 0.85) !important;
}

.tw-stroke-purple-900\/90 {
  stroke: rgb(88 28 135 / 0.9) !important;
}

.tw-stroke-purple-900\/95 {
  stroke: rgb(88 28 135 / 0.95) !important;
}

.tw-stroke-purple-950 {
  stroke: #3b0764 !important;
}

.tw-stroke-purple-950\/0 {
  stroke: rgb(59 7 100 / 0) !important;
}

.tw-stroke-purple-950\/10 {
  stroke: rgb(59 7 100 / 0.1) !important;
}

.tw-stroke-purple-950\/100 {
  stroke: rgb(59 7 100 / 1) !important;
}

.tw-stroke-purple-950\/15 {
  stroke: rgb(59 7 100 / 0.15) !important;
}

.tw-stroke-purple-950\/20 {
  stroke: rgb(59 7 100 / 0.2) !important;
}

.tw-stroke-purple-950\/25 {
  stroke: rgb(59 7 100 / 0.25) !important;
}

.tw-stroke-purple-950\/30 {
  stroke: rgb(59 7 100 / 0.3) !important;
}

.tw-stroke-purple-950\/35 {
  stroke: rgb(59 7 100 / 0.35) !important;
}

.tw-stroke-purple-950\/40 {
  stroke: rgb(59 7 100 / 0.4) !important;
}

.tw-stroke-purple-950\/45 {
  stroke: rgb(59 7 100 / 0.45) !important;
}

.tw-stroke-purple-950\/5 {
  stroke: rgb(59 7 100 / 0.05) !important;
}

.tw-stroke-purple-950\/50 {
  stroke: rgb(59 7 100 / 0.5) !important;
}

.tw-stroke-purple-950\/55 {
  stroke: rgb(59 7 100 / 0.55) !important;
}

.tw-stroke-purple-950\/60 {
  stroke: rgb(59 7 100 / 0.6) !important;
}

.tw-stroke-purple-950\/65 {
  stroke: rgb(59 7 100 / 0.65) !important;
}

.tw-stroke-purple-950\/70 {
  stroke: rgb(59 7 100 / 0.7) !important;
}

.tw-stroke-purple-950\/75 {
  stroke: rgb(59 7 100 / 0.75) !important;
}

.tw-stroke-purple-950\/80 {
  stroke: rgb(59 7 100 / 0.8) !important;
}

.tw-stroke-purple-950\/85 {
  stroke: rgb(59 7 100 / 0.85) !important;
}

.tw-stroke-purple-950\/90 {
  stroke: rgb(59 7 100 / 0.9) !important;
}

.tw-stroke-purple-950\/95 {
  stroke: rgb(59 7 100 / 0.95) !important;
}

.tw-stroke-red-100 {
  stroke: #fee2e2 !important;
}

.tw-stroke-red-100\/0 {
  stroke: rgb(254 226 226 / 0) !important;
}

.tw-stroke-red-100\/10 {
  stroke: rgb(254 226 226 / 0.1) !important;
}

.tw-stroke-red-100\/100 {
  stroke: rgb(254 226 226 / 1) !important;
}

.tw-stroke-red-100\/15 {
  stroke: rgb(254 226 226 / 0.15) !important;
}

.tw-stroke-red-100\/20 {
  stroke: rgb(254 226 226 / 0.2) !important;
}

.tw-stroke-red-100\/25 {
  stroke: rgb(254 226 226 / 0.25) !important;
}

.tw-stroke-red-100\/30 {
  stroke: rgb(254 226 226 / 0.3) !important;
}

.tw-stroke-red-100\/35 {
  stroke: rgb(254 226 226 / 0.35) !important;
}

.tw-stroke-red-100\/40 {
  stroke: rgb(254 226 226 / 0.4) !important;
}

.tw-stroke-red-100\/45 {
  stroke: rgb(254 226 226 / 0.45) !important;
}

.tw-stroke-red-100\/5 {
  stroke: rgb(254 226 226 / 0.05) !important;
}

.tw-stroke-red-100\/50 {
  stroke: rgb(254 226 226 / 0.5) !important;
}

.tw-stroke-red-100\/55 {
  stroke: rgb(254 226 226 / 0.55) !important;
}

.tw-stroke-red-100\/60 {
  stroke: rgb(254 226 226 / 0.6) !important;
}

.tw-stroke-red-100\/65 {
  stroke: rgb(254 226 226 / 0.65) !important;
}

.tw-stroke-red-100\/70 {
  stroke: rgb(254 226 226 / 0.7) !important;
}

.tw-stroke-red-100\/75 {
  stroke: rgb(254 226 226 / 0.75) !important;
}

.tw-stroke-red-100\/80 {
  stroke: rgb(254 226 226 / 0.8) !important;
}

.tw-stroke-red-100\/85 {
  stroke: rgb(254 226 226 / 0.85) !important;
}

.tw-stroke-red-100\/90 {
  stroke: rgb(254 226 226 / 0.9) !important;
}

.tw-stroke-red-100\/95 {
  stroke: rgb(254 226 226 / 0.95) !important;
}

.tw-stroke-red-200 {
  stroke: #fecaca !important;
}

.tw-stroke-red-200\/0 {
  stroke: rgb(254 202 202 / 0) !important;
}

.tw-stroke-red-200\/10 {
  stroke: rgb(254 202 202 / 0.1) !important;
}

.tw-stroke-red-200\/100 {
  stroke: rgb(254 202 202 / 1) !important;
}

.tw-stroke-red-200\/15 {
  stroke: rgb(254 202 202 / 0.15) !important;
}

.tw-stroke-red-200\/20 {
  stroke: rgb(254 202 202 / 0.2) !important;
}

.tw-stroke-red-200\/25 {
  stroke: rgb(254 202 202 / 0.25) !important;
}

.tw-stroke-red-200\/30 {
  stroke: rgb(254 202 202 / 0.3) !important;
}

.tw-stroke-red-200\/35 {
  stroke: rgb(254 202 202 / 0.35) !important;
}

.tw-stroke-red-200\/40 {
  stroke: rgb(254 202 202 / 0.4) !important;
}

.tw-stroke-red-200\/45 {
  stroke: rgb(254 202 202 / 0.45) !important;
}

.tw-stroke-red-200\/5 {
  stroke: rgb(254 202 202 / 0.05) !important;
}

.tw-stroke-red-200\/50 {
  stroke: rgb(254 202 202 / 0.5) !important;
}

.tw-stroke-red-200\/55 {
  stroke: rgb(254 202 202 / 0.55) !important;
}

.tw-stroke-red-200\/60 {
  stroke: rgb(254 202 202 / 0.6) !important;
}

.tw-stroke-red-200\/65 {
  stroke: rgb(254 202 202 / 0.65) !important;
}

.tw-stroke-red-200\/70 {
  stroke: rgb(254 202 202 / 0.7) !important;
}

.tw-stroke-red-200\/75 {
  stroke: rgb(254 202 202 / 0.75) !important;
}

.tw-stroke-red-200\/80 {
  stroke: rgb(254 202 202 / 0.8) !important;
}

.tw-stroke-red-200\/85 {
  stroke: rgb(254 202 202 / 0.85) !important;
}

.tw-stroke-red-200\/90 {
  stroke: rgb(254 202 202 / 0.9) !important;
}

.tw-stroke-red-200\/95 {
  stroke: rgb(254 202 202 / 0.95) !important;
}

.tw-stroke-red-300 {
  stroke: #fca5a5 !important;
}

.tw-stroke-red-300\/0 {
  stroke: rgb(252 165 165 / 0) !important;
}

.tw-stroke-red-300\/10 {
  stroke: rgb(252 165 165 / 0.1) !important;
}

.tw-stroke-red-300\/100 {
  stroke: rgb(252 165 165 / 1) !important;
}

.tw-stroke-red-300\/15 {
  stroke: rgb(252 165 165 / 0.15) !important;
}

.tw-stroke-red-300\/20 {
  stroke: rgb(252 165 165 / 0.2) !important;
}

.tw-stroke-red-300\/25 {
  stroke: rgb(252 165 165 / 0.25) !important;
}

.tw-stroke-red-300\/30 {
  stroke: rgb(252 165 165 / 0.3) !important;
}

.tw-stroke-red-300\/35 {
  stroke: rgb(252 165 165 / 0.35) !important;
}

.tw-stroke-red-300\/40 {
  stroke: rgb(252 165 165 / 0.4) !important;
}

.tw-stroke-red-300\/45 {
  stroke: rgb(252 165 165 / 0.45) !important;
}

.tw-stroke-red-300\/5 {
  stroke: rgb(252 165 165 / 0.05) !important;
}

.tw-stroke-red-300\/50 {
  stroke: rgb(252 165 165 / 0.5) !important;
}

.tw-stroke-red-300\/55 {
  stroke: rgb(252 165 165 / 0.55) !important;
}

.tw-stroke-red-300\/60 {
  stroke: rgb(252 165 165 / 0.6) !important;
}

.tw-stroke-red-300\/65 {
  stroke: rgb(252 165 165 / 0.65) !important;
}

.tw-stroke-red-300\/70 {
  stroke: rgb(252 165 165 / 0.7) !important;
}

.tw-stroke-red-300\/75 {
  stroke: rgb(252 165 165 / 0.75) !important;
}

.tw-stroke-red-300\/80 {
  stroke: rgb(252 165 165 / 0.8) !important;
}

.tw-stroke-red-300\/85 {
  stroke: rgb(252 165 165 / 0.85) !important;
}

.tw-stroke-red-300\/90 {
  stroke: rgb(252 165 165 / 0.9) !important;
}

.tw-stroke-red-300\/95 {
  stroke: rgb(252 165 165 / 0.95) !important;
}

.tw-stroke-red-400 {
  stroke: #f87171 !important;
}

.tw-stroke-red-400\/0 {
  stroke: rgb(248 113 113 / 0) !important;
}

.tw-stroke-red-400\/10 {
  stroke: rgb(248 113 113 / 0.1) !important;
}

.tw-stroke-red-400\/100 {
  stroke: rgb(248 113 113 / 1) !important;
}

.tw-stroke-red-400\/15 {
  stroke: rgb(248 113 113 / 0.15) !important;
}

.tw-stroke-red-400\/20 {
  stroke: rgb(248 113 113 / 0.2) !important;
}

.tw-stroke-red-400\/25 {
  stroke: rgb(248 113 113 / 0.25) !important;
}

.tw-stroke-red-400\/30 {
  stroke: rgb(248 113 113 / 0.3) !important;
}

.tw-stroke-red-400\/35 {
  stroke: rgb(248 113 113 / 0.35) !important;
}

.tw-stroke-red-400\/40 {
  stroke: rgb(248 113 113 / 0.4) !important;
}

.tw-stroke-red-400\/45 {
  stroke: rgb(248 113 113 / 0.45) !important;
}

.tw-stroke-red-400\/5 {
  stroke: rgb(248 113 113 / 0.05) !important;
}

.tw-stroke-red-400\/50 {
  stroke: rgb(248 113 113 / 0.5) !important;
}

.tw-stroke-red-400\/55 {
  stroke: rgb(248 113 113 / 0.55) !important;
}

.tw-stroke-red-400\/60 {
  stroke: rgb(248 113 113 / 0.6) !important;
}

.tw-stroke-red-400\/65 {
  stroke: rgb(248 113 113 / 0.65) !important;
}

.tw-stroke-red-400\/70 {
  stroke: rgb(248 113 113 / 0.7) !important;
}

.tw-stroke-red-400\/75 {
  stroke: rgb(248 113 113 / 0.75) !important;
}

.tw-stroke-red-400\/80 {
  stroke: rgb(248 113 113 / 0.8) !important;
}

.tw-stroke-red-400\/85 {
  stroke: rgb(248 113 113 / 0.85) !important;
}

.tw-stroke-red-400\/90 {
  stroke: rgb(248 113 113 / 0.9) !important;
}

.tw-stroke-red-400\/95 {
  stroke: rgb(248 113 113 / 0.95) !important;
}

.tw-stroke-red-50 {
  stroke: #fef2f2 !important;
}

.tw-stroke-red-50\/0 {
  stroke: rgb(254 242 242 / 0) !important;
}

.tw-stroke-red-50\/10 {
  stroke: rgb(254 242 242 / 0.1) !important;
}

.tw-stroke-red-50\/100 {
  stroke: rgb(254 242 242 / 1) !important;
}

.tw-stroke-red-50\/15 {
  stroke: rgb(254 242 242 / 0.15) !important;
}

.tw-stroke-red-50\/20 {
  stroke: rgb(254 242 242 / 0.2) !important;
}

.tw-stroke-red-50\/25 {
  stroke: rgb(254 242 242 / 0.25) !important;
}

.tw-stroke-red-50\/30 {
  stroke: rgb(254 242 242 / 0.3) !important;
}

.tw-stroke-red-50\/35 {
  stroke: rgb(254 242 242 / 0.35) !important;
}

.tw-stroke-red-50\/40 {
  stroke: rgb(254 242 242 / 0.4) !important;
}

.tw-stroke-red-50\/45 {
  stroke: rgb(254 242 242 / 0.45) !important;
}

.tw-stroke-red-50\/5 {
  stroke: rgb(254 242 242 / 0.05) !important;
}

.tw-stroke-red-50\/50 {
  stroke: rgb(254 242 242 / 0.5) !important;
}

.tw-stroke-red-50\/55 {
  stroke: rgb(254 242 242 / 0.55) !important;
}

.tw-stroke-red-50\/60 {
  stroke: rgb(254 242 242 / 0.6) !important;
}

.tw-stroke-red-50\/65 {
  stroke: rgb(254 242 242 / 0.65) !important;
}

.tw-stroke-red-50\/70 {
  stroke: rgb(254 242 242 / 0.7) !important;
}

.tw-stroke-red-50\/75 {
  stroke: rgb(254 242 242 / 0.75) !important;
}

.tw-stroke-red-50\/80 {
  stroke: rgb(254 242 242 / 0.8) !important;
}

.tw-stroke-red-50\/85 {
  stroke: rgb(254 242 242 / 0.85) !important;
}

.tw-stroke-red-50\/90 {
  stroke: rgb(254 242 242 / 0.9) !important;
}

.tw-stroke-red-50\/95 {
  stroke: rgb(254 242 242 / 0.95) !important;
}

.tw-stroke-red-500 {
  stroke: #ef4444 !important;
}

.tw-stroke-red-500\/0 {
  stroke: rgb(239 68 68 / 0) !important;
}

.tw-stroke-red-500\/10 {
  stroke: rgb(239 68 68 / 0.1) !important;
}

.tw-stroke-red-500\/100 {
  stroke: rgb(239 68 68 / 1) !important;
}

.tw-stroke-red-500\/15 {
  stroke: rgb(239 68 68 / 0.15) !important;
}

.tw-stroke-red-500\/20 {
  stroke: rgb(239 68 68 / 0.2) !important;
}

.tw-stroke-red-500\/25 {
  stroke: rgb(239 68 68 / 0.25) !important;
}

.tw-stroke-red-500\/30 {
  stroke: rgb(239 68 68 / 0.3) !important;
}

.tw-stroke-red-500\/35 {
  stroke: rgb(239 68 68 / 0.35) !important;
}

.tw-stroke-red-500\/40 {
  stroke: rgb(239 68 68 / 0.4) !important;
}

.tw-stroke-red-500\/45 {
  stroke: rgb(239 68 68 / 0.45) !important;
}

.tw-stroke-red-500\/5 {
  stroke: rgb(239 68 68 / 0.05) !important;
}

.tw-stroke-red-500\/50 {
  stroke: rgb(239 68 68 / 0.5) !important;
}

.tw-stroke-red-500\/55 {
  stroke: rgb(239 68 68 / 0.55) !important;
}

.tw-stroke-red-500\/60 {
  stroke: rgb(239 68 68 / 0.6) !important;
}

.tw-stroke-red-500\/65 {
  stroke: rgb(239 68 68 / 0.65) !important;
}

.tw-stroke-red-500\/70 {
  stroke: rgb(239 68 68 / 0.7) !important;
}

.tw-stroke-red-500\/75 {
  stroke: rgb(239 68 68 / 0.75) !important;
}

.tw-stroke-red-500\/80 {
  stroke: rgb(239 68 68 / 0.8) !important;
}

.tw-stroke-red-500\/85 {
  stroke: rgb(239 68 68 / 0.85) !important;
}

.tw-stroke-red-500\/90 {
  stroke: rgb(239 68 68 / 0.9) !important;
}

.tw-stroke-red-500\/95 {
  stroke: rgb(239 68 68 / 0.95) !important;
}

.tw-stroke-red-600 {
  stroke: #dc2626 !important;
}

.tw-stroke-red-600\/0 {
  stroke: rgb(220 38 38 / 0) !important;
}

.tw-stroke-red-600\/10 {
  stroke: rgb(220 38 38 / 0.1) !important;
}

.tw-stroke-red-600\/100 {
  stroke: rgb(220 38 38 / 1) !important;
}

.tw-stroke-red-600\/15 {
  stroke: rgb(220 38 38 / 0.15) !important;
}

.tw-stroke-red-600\/20 {
  stroke: rgb(220 38 38 / 0.2) !important;
}

.tw-stroke-red-600\/25 {
  stroke: rgb(220 38 38 / 0.25) !important;
}

.tw-stroke-red-600\/30 {
  stroke: rgb(220 38 38 / 0.3) !important;
}

.tw-stroke-red-600\/35 {
  stroke: rgb(220 38 38 / 0.35) !important;
}

.tw-stroke-red-600\/40 {
  stroke: rgb(220 38 38 / 0.4) !important;
}

.tw-stroke-red-600\/45 {
  stroke: rgb(220 38 38 / 0.45) !important;
}

.tw-stroke-red-600\/5 {
  stroke: rgb(220 38 38 / 0.05) !important;
}

.tw-stroke-red-600\/50 {
  stroke: rgb(220 38 38 / 0.5) !important;
}

.tw-stroke-red-600\/55 {
  stroke: rgb(220 38 38 / 0.55) !important;
}

.tw-stroke-red-600\/60 {
  stroke: rgb(220 38 38 / 0.6) !important;
}

.tw-stroke-red-600\/65 {
  stroke: rgb(220 38 38 / 0.65) !important;
}

.tw-stroke-red-600\/70 {
  stroke: rgb(220 38 38 / 0.7) !important;
}

.tw-stroke-red-600\/75 {
  stroke: rgb(220 38 38 / 0.75) !important;
}

.tw-stroke-red-600\/80 {
  stroke: rgb(220 38 38 / 0.8) !important;
}

.tw-stroke-red-600\/85 {
  stroke: rgb(220 38 38 / 0.85) !important;
}

.tw-stroke-red-600\/90 {
  stroke: rgb(220 38 38 / 0.9) !important;
}

.tw-stroke-red-600\/95 {
  stroke: rgb(220 38 38 / 0.95) !important;
}

.tw-stroke-red-700 {
  stroke: #b91c1c !important;
}

.tw-stroke-red-700\/0 {
  stroke: rgb(185 28 28 / 0) !important;
}

.tw-stroke-red-700\/10 {
  stroke: rgb(185 28 28 / 0.1) !important;
}

.tw-stroke-red-700\/100 {
  stroke: rgb(185 28 28 / 1) !important;
}

.tw-stroke-red-700\/15 {
  stroke: rgb(185 28 28 / 0.15) !important;
}

.tw-stroke-red-700\/20 {
  stroke: rgb(185 28 28 / 0.2) !important;
}

.tw-stroke-red-700\/25 {
  stroke: rgb(185 28 28 / 0.25) !important;
}

.tw-stroke-red-700\/30 {
  stroke: rgb(185 28 28 / 0.3) !important;
}

.tw-stroke-red-700\/35 {
  stroke: rgb(185 28 28 / 0.35) !important;
}

.tw-stroke-red-700\/40 {
  stroke: rgb(185 28 28 / 0.4) !important;
}

.tw-stroke-red-700\/45 {
  stroke: rgb(185 28 28 / 0.45) !important;
}

.tw-stroke-red-700\/5 {
  stroke: rgb(185 28 28 / 0.05) !important;
}

.tw-stroke-red-700\/50 {
  stroke: rgb(185 28 28 / 0.5) !important;
}

.tw-stroke-red-700\/55 {
  stroke: rgb(185 28 28 / 0.55) !important;
}

.tw-stroke-red-700\/60 {
  stroke: rgb(185 28 28 / 0.6) !important;
}

.tw-stroke-red-700\/65 {
  stroke: rgb(185 28 28 / 0.65) !important;
}

.tw-stroke-red-700\/70 {
  stroke: rgb(185 28 28 / 0.7) !important;
}

.tw-stroke-red-700\/75 {
  stroke: rgb(185 28 28 / 0.75) !important;
}

.tw-stroke-red-700\/80 {
  stroke: rgb(185 28 28 / 0.8) !important;
}

.tw-stroke-red-700\/85 {
  stroke: rgb(185 28 28 / 0.85) !important;
}

.tw-stroke-red-700\/90 {
  stroke: rgb(185 28 28 / 0.9) !important;
}

.tw-stroke-red-700\/95 {
  stroke: rgb(185 28 28 / 0.95) !important;
}

.tw-stroke-red-800 {
  stroke: #991b1b !important;
}

.tw-stroke-red-800\/0 {
  stroke: rgb(153 27 27 / 0) !important;
}

.tw-stroke-red-800\/10 {
  stroke: rgb(153 27 27 / 0.1) !important;
}

.tw-stroke-red-800\/100 {
  stroke: rgb(153 27 27 / 1) !important;
}

.tw-stroke-red-800\/15 {
  stroke: rgb(153 27 27 / 0.15) !important;
}

.tw-stroke-red-800\/20 {
  stroke: rgb(153 27 27 / 0.2) !important;
}

.tw-stroke-red-800\/25 {
  stroke: rgb(153 27 27 / 0.25) !important;
}

.tw-stroke-red-800\/30 {
  stroke: rgb(153 27 27 / 0.3) !important;
}

.tw-stroke-red-800\/35 {
  stroke: rgb(153 27 27 / 0.35) !important;
}

.tw-stroke-red-800\/40 {
  stroke: rgb(153 27 27 / 0.4) !important;
}

.tw-stroke-red-800\/45 {
  stroke: rgb(153 27 27 / 0.45) !important;
}

.tw-stroke-red-800\/5 {
  stroke: rgb(153 27 27 / 0.05) !important;
}

.tw-stroke-red-800\/50 {
  stroke: rgb(153 27 27 / 0.5) !important;
}

.tw-stroke-red-800\/55 {
  stroke: rgb(153 27 27 / 0.55) !important;
}

.tw-stroke-red-800\/60 {
  stroke: rgb(153 27 27 / 0.6) !important;
}

.tw-stroke-red-800\/65 {
  stroke: rgb(153 27 27 / 0.65) !important;
}

.tw-stroke-red-800\/70 {
  stroke: rgb(153 27 27 / 0.7) !important;
}

.tw-stroke-red-800\/75 {
  stroke: rgb(153 27 27 / 0.75) !important;
}

.tw-stroke-red-800\/80 {
  stroke: rgb(153 27 27 / 0.8) !important;
}

.tw-stroke-red-800\/85 {
  stroke: rgb(153 27 27 / 0.85) !important;
}

.tw-stroke-red-800\/90 {
  stroke: rgb(153 27 27 / 0.9) !important;
}

.tw-stroke-red-800\/95 {
  stroke: rgb(153 27 27 / 0.95) !important;
}

.tw-stroke-red-900 {
  stroke: #7f1d1d !important;
}

.tw-stroke-red-900\/0 {
  stroke: rgb(127 29 29 / 0) !important;
}

.tw-stroke-red-900\/10 {
  stroke: rgb(127 29 29 / 0.1) !important;
}

.tw-stroke-red-900\/100 {
  stroke: rgb(127 29 29 / 1) !important;
}

.tw-stroke-red-900\/15 {
  stroke: rgb(127 29 29 / 0.15) !important;
}

.tw-stroke-red-900\/20 {
  stroke: rgb(127 29 29 / 0.2) !important;
}

.tw-stroke-red-900\/25 {
  stroke: rgb(127 29 29 / 0.25) !important;
}

.tw-stroke-red-900\/30 {
  stroke: rgb(127 29 29 / 0.3) !important;
}

.tw-stroke-red-900\/35 {
  stroke: rgb(127 29 29 / 0.35) !important;
}

.tw-stroke-red-900\/40 {
  stroke: rgb(127 29 29 / 0.4) !important;
}

.tw-stroke-red-900\/45 {
  stroke: rgb(127 29 29 / 0.45) !important;
}

.tw-stroke-red-900\/5 {
  stroke: rgb(127 29 29 / 0.05) !important;
}

.tw-stroke-red-900\/50 {
  stroke: rgb(127 29 29 / 0.5) !important;
}

.tw-stroke-red-900\/55 {
  stroke: rgb(127 29 29 / 0.55) !important;
}

.tw-stroke-red-900\/60 {
  stroke: rgb(127 29 29 / 0.6) !important;
}

.tw-stroke-red-900\/65 {
  stroke: rgb(127 29 29 / 0.65) !important;
}

.tw-stroke-red-900\/70 {
  stroke: rgb(127 29 29 / 0.7) !important;
}

.tw-stroke-red-900\/75 {
  stroke: rgb(127 29 29 / 0.75) !important;
}

.tw-stroke-red-900\/80 {
  stroke: rgb(127 29 29 / 0.8) !important;
}

.tw-stroke-red-900\/85 {
  stroke: rgb(127 29 29 / 0.85) !important;
}

.tw-stroke-red-900\/90 {
  stroke: rgb(127 29 29 / 0.9) !important;
}

.tw-stroke-red-900\/95 {
  stroke: rgb(127 29 29 / 0.95) !important;
}

.tw-stroke-red-950 {
  stroke: #450a0a !important;
}

.tw-stroke-red-950\/0 {
  stroke: rgb(69 10 10 / 0) !important;
}

.tw-stroke-red-950\/10 {
  stroke: rgb(69 10 10 / 0.1) !important;
}

.tw-stroke-red-950\/100 {
  stroke: rgb(69 10 10 / 1) !important;
}

.tw-stroke-red-950\/15 {
  stroke: rgb(69 10 10 / 0.15) !important;
}

.tw-stroke-red-950\/20 {
  stroke: rgb(69 10 10 / 0.2) !important;
}

.tw-stroke-red-950\/25 {
  stroke: rgb(69 10 10 / 0.25) !important;
}

.tw-stroke-red-950\/30 {
  stroke: rgb(69 10 10 / 0.3) !important;
}

.tw-stroke-red-950\/35 {
  stroke: rgb(69 10 10 / 0.35) !important;
}

.tw-stroke-red-950\/40 {
  stroke: rgb(69 10 10 / 0.4) !important;
}

.tw-stroke-red-950\/45 {
  stroke: rgb(69 10 10 / 0.45) !important;
}

.tw-stroke-red-950\/5 {
  stroke: rgb(69 10 10 / 0.05) !important;
}

.tw-stroke-red-950\/50 {
  stroke: rgb(69 10 10 / 0.5) !important;
}

.tw-stroke-red-950\/55 {
  stroke: rgb(69 10 10 / 0.55) !important;
}

.tw-stroke-red-950\/60 {
  stroke: rgb(69 10 10 / 0.6) !important;
}

.tw-stroke-red-950\/65 {
  stroke: rgb(69 10 10 / 0.65) !important;
}

.tw-stroke-red-950\/70 {
  stroke: rgb(69 10 10 / 0.7) !important;
}

.tw-stroke-red-950\/75 {
  stroke: rgb(69 10 10 / 0.75) !important;
}

.tw-stroke-red-950\/80 {
  stroke: rgb(69 10 10 / 0.8) !important;
}

.tw-stroke-red-950\/85 {
  stroke: rgb(69 10 10 / 0.85) !important;
}

.tw-stroke-red-950\/90 {
  stroke: rgb(69 10 10 / 0.9) !important;
}

.tw-stroke-red-950\/95 {
  stroke: rgb(69 10 10 / 0.95) !important;
}

.tw-stroke-rose-100 {
  stroke: #ffe4e6 !important;
}

.tw-stroke-rose-100\/0 {
  stroke: rgb(255 228 230 / 0) !important;
}

.tw-stroke-rose-100\/10 {
  stroke: rgb(255 228 230 / 0.1) !important;
}

.tw-stroke-rose-100\/100 {
  stroke: rgb(255 228 230 / 1) !important;
}

.tw-stroke-rose-100\/15 {
  stroke: rgb(255 228 230 / 0.15) !important;
}

.tw-stroke-rose-100\/20 {
  stroke: rgb(255 228 230 / 0.2) !important;
}

.tw-stroke-rose-100\/25 {
  stroke: rgb(255 228 230 / 0.25) !important;
}

.tw-stroke-rose-100\/30 {
  stroke: rgb(255 228 230 / 0.3) !important;
}

.tw-stroke-rose-100\/35 {
  stroke: rgb(255 228 230 / 0.35) !important;
}

.tw-stroke-rose-100\/40 {
  stroke: rgb(255 228 230 / 0.4) !important;
}

.tw-stroke-rose-100\/45 {
  stroke: rgb(255 228 230 / 0.45) !important;
}

.tw-stroke-rose-100\/5 {
  stroke: rgb(255 228 230 / 0.05) !important;
}

.tw-stroke-rose-100\/50 {
  stroke: rgb(255 228 230 / 0.5) !important;
}

.tw-stroke-rose-100\/55 {
  stroke: rgb(255 228 230 / 0.55) !important;
}

.tw-stroke-rose-100\/60 {
  stroke: rgb(255 228 230 / 0.6) !important;
}

.tw-stroke-rose-100\/65 {
  stroke: rgb(255 228 230 / 0.65) !important;
}

.tw-stroke-rose-100\/70 {
  stroke: rgb(255 228 230 / 0.7) !important;
}

.tw-stroke-rose-100\/75 {
  stroke: rgb(255 228 230 / 0.75) !important;
}

.tw-stroke-rose-100\/80 {
  stroke: rgb(255 228 230 / 0.8) !important;
}

.tw-stroke-rose-100\/85 {
  stroke: rgb(255 228 230 / 0.85) !important;
}

.tw-stroke-rose-100\/90 {
  stroke: rgb(255 228 230 / 0.9) !important;
}

.tw-stroke-rose-100\/95 {
  stroke: rgb(255 228 230 / 0.95) !important;
}

.tw-stroke-rose-200 {
  stroke: #fecdd3 !important;
}

.tw-stroke-rose-200\/0 {
  stroke: rgb(254 205 211 / 0) !important;
}

.tw-stroke-rose-200\/10 {
  stroke: rgb(254 205 211 / 0.1) !important;
}

.tw-stroke-rose-200\/100 {
  stroke: rgb(254 205 211 / 1) !important;
}

.tw-stroke-rose-200\/15 {
  stroke: rgb(254 205 211 / 0.15) !important;
}

.tw-stroke-rose-200\/20 {
  stroke: rgb(254 205 211 / 0.2) !important;
}

.tw-stroke-rose-200\/25 {
  stroke: rgb(254 205 211 / 0.25) !important;
}

.tw-stroke-rose-200\/30 {
  stroke: rgb(254 205 211 / 0.3) !important;
}

.tw-stroke-rose-200\/35 {
  stroke: rgb(254 205 211 / 0.35) !important;
}

.tw-stroke-rose-200\/40 {
  stroke: rgb(254 205 211 / 0.4) !important;
}

.tw-stroke-rose-200\/45 {
  stroke: rgb(254 205 211 / 0.45) !important;
}

.tw-stroke-rose-200\/5 {
  stroke: rgb(254 205 211 / 0.05) !important;
}

.tw-stroke-rose-200\/50 {
  stroke: rgb(254 205 211 / 0.5) !important;
}

.tw-stroke-rose-200\/55 {
  stroke: rgb(254 205 211 / 0.55) !important;
}

.tw-stroke-rose-200\/60 {
  stroke: rgb(254 205 211 / 0.6) !important;
}

.tw-stroke-rose-200\/65 {
  stroke: rgb(254 205 211 / 0.65) !important;
}

.tw-stroke-rose-200\/70 {
  stroke: rgb(254 205 211 / 0.7) !important;
}

.tw-stroke-rose-200\/75 {
  stroke: rgb(254 205 211 / 0.75) !important;
}

.tw-stroke-rose-200\/80 {
  stroke: rgb(254 205 211 / 0.8) !important;
}

.tw-stroke-rose-200\/85 {
  stroke: rgb(254 205 211 / 0.85) !important;
}

.tw-stroke-rose-200\/90 {
  stroke: rgb(254 205 211 / 0.9) !important;
}

.tw-stroke-rose-200\/95 {
  stroke: rgb(254 205 211 / 0.95) !important;
}

.tw-stroke-rose-300 {
  stroke: #fda4af !important;
}

.tw-stroke-rose-300\/0 {
  stroke: rgb(253 164 175 / 0) !important;
}

.tw-stroke-rose-300\/10 {
  stroke: rgb(253 164 175 / 0.1) !important;
}

.tw-stroke-rose-300\/100 {
  stroke: rgb(253 164 175 / 1) !important;
}

.tw-stroke-rose-300\/15 {
  stroke: rgb(253 164 175 / 0.15) !important;
}

.tw-stroke-rose-300\/20 {
  stroke: rgb(253 164 175 / 0.2) !important;
}

.tw-stroke-rose-300\/25 {
  stroke: rgb(253 164 175 / 0.25) !important;
}

.tw-stroke-rose-300\/30 {
  stroke: rgb(253 164 175 / 0.3) !important;
}

.tw-stroke-rose-300\/35 {
  stroke: rgb(253 164 175 / 0.35) !important;
}

.tw-stroke-rose-300\/40 {
  stroke: rgb(253 164 175 / 0.4) !important;
}

.tw-stroke-rose-300\/45 {
  stroke: rgb(253 164 175 / 0.45) !important;
}

.tw-stroke-rose-300\/5 {
  stroke: rgb(253 164 175 / 0.05) !important;
}

.tw-stroke-rose-300\/50 {
  stroke: rgb(253 164 175 / 0.5) !important;
}

.tw-stroke-rose-300\/55 {
  stroke: rgb(253 164 175 / 0.55) !important;
}

.tw-stroke-rose-300\/60 {
  stroke: rgb(253 164 175 / 0.6) !important;
}

.tw-stroke-rose-300\/65 {
  stroke: rgb(253 164 175 / 0.65) !important;
}

.tw-stroke-rose-300\/70 {
  stroke: rgb(253 164 175 / 0.7) !important;
}

.tw-stroke-rose-300\/75 {
  stroke: rgb(253 164 175 / 0.75) !important;
}

.tw-stroke-rose-300\/80 {
  stroke: rgb(253 164 175 / 0.8) !important;
}

.tw-stroke-rose-300\/85 {
  stroke: rgb(253 164 175 / 0.85) !important;
}

.tw-stroke-rose-300\/90 {
  stroke: rgb(253 164 175 / 0.9) !important;
}

.tw-stroke-rose-300\/95 {
  stroke: rgb(253 164 175 / 0.95) !important;
}

.tw-stroke-rose-400 {
  stroke: #fb7185 !important;
}

.tw-stroke-rose-400\/0 {
  stroke: rgb(251 113 133 / 0) !important;
}

.tw-stroke-rose-400\/10 {
  stroke: rgb(251 113 133 / 0.1) !important;
}

.tw-stroke-rose-400\/100 {
  stroke: rgb(251 113 133 / 1) !important;
}

.tw-stroke-rose-400\/15 {
  stroke: rgb(251 113 133 / 0.15) !important;
}

.tw-stroke-rose-400\/20 {
  stroke: rgb(251 113 133 / 0.2) !important;
}

.tw-stroke-rose-400\/25 {
  stroke: rgb(251 113 133 / 0.25) !important;
}

.tw-stroke-rose-400\/30 {
  stroke: rgb(251 113 133 / 0.3) !important;
}

.tw-stroke-rose-400\/35 {
  stroke: rgb(251 113 133 / 0.35) !important;
}

.tw-stroke-rose-400\/40 {
  stroke: rgb(251 113 133 / 0.4) !important;
}

.tw-stroke-rose-400\/45 {
  stroke: rgb(251 113 133 / 0.45) !important;
}

.tw-stroke-rose-400\/5 {
  stroke: rgb(251 113 133 / 0.05) !important;
}

.tw-stroke-rose-400\/50 {
  stroke: rgb(251 113 133 / 0.5) !important;
}

.tw-stroke-rose-400\/55 {
  stroke: rgb(251 113 133 / 0.55) !important;
}

.tw-stroke-rose-400\/60 {
  stroke: rgb(251 113 133 / 0.6) !important;
}

.tw-stroke-rose-400\/65 {
  stroke: rgb(251 113 133 / 0.65) !important;
}

.tw-stroke-rose-400\/70 {
  stroke: rgb(251 113 133 / 0.7) !important;
}

.tw-stroke-rose-400\/75 {
  stroke: rgb(251 113 133 / 0.75) !important;
}

.tw-stroke-rose-400\/80 {
  stroke: rgb(251 113 133 / 0.8) !important;
}

.tw-stroke-rose-400\/85 {
  stroke: rgb(251 113 133 / 0.85) !important;
}

.tw-stroke-rose-400\/90 {
  stroke: rgb(251 113 133 / 0.9) !important;
}

.tw-stroke-rose-400\/95 {
  stroke: rgb(251 113 133 / 0.95) !important;
}

.tw-stroke-rose-50 {
  stroke: #fff1f2 !important;
}

.tw-stroke-rose-50\/0 {
  stroke: rgb(255 241 242 / 0) !important;
}

.tw-stroke-rose-50\/10 {
  stroke: rgb(255 241 242 / 0.1) !important;
}

.tw-stroke-rose-50\/100 {
  stroke: rgb(255 241 242 / 1) !important;
}

.tw-stroke-rose-50\/15 {
  stroke: rgb(255 241 242 / 0.15) !important;
}

.tw-stroke-rose-50\/20 {
  stroke: rgb(255 241 242 / 0.2) !important;
}

.tw-stroke-rose-50\/25 {
  stroke: rgb(255 241 242 / 0.25) !important;
}

.tw-stroke-rose-50\/30 {
  stroke: rgb(255 241 242 / 0.3) !important;
}

.tw-stroke-rose-50\/35 {
  stroke: rgb(255 241 242 / 0.35) !important;
}

.tw-stroke-rose-50\/40 {
  stroke: rgb(255 241 242 / 0.4) !important;
}

.tw-stroke-rose-50\/45 {
  stroke: rgb(255 241 242 / 0.45) !important;
}

.tw-stroke-rose-50\/5 {
  stroke: rgb(255 241 242 / 0.05) !important;
}

.tw-stroke-rose-50\/50 {
  stroke: rgb(255 241 242 / 0.5) !important;
}

.tw-stroke-rose-50\/55 {
  stroke: rgb(255 241 242 / 0.55) !important;
}

.tw-stroke-rose-50\/60 {
  stroke: rgb(255 241 242 / 0.6) !important;
}

.tw-stroke-rose-50\/65 {
  stroke: rgb(255 241 242 / 0.65) !important;
}

.tw-stroke-rose-50\/70 {
  stroke: rgb(255 241 242 / 0.7) !important;
}

.tw-stroke-rose-50\/75 {
  stroke: rgb(255 241 242 / 0.75) !important;
}

.tw-stroke-rose-50\/80 {
  stroke: rgb(255 241 242 / 0.8) !important;
}

.tw-stroke-rose-50\/85 {
  stroke: rgb(255 241 242 / 0.85) !important;
}

.tw-stroke-rose-50\/90 {
  stroke: rgb(255 241 242 / 0.9) !important;
}

.tw-stroke-rose-50\/95 {
  stroke: rgb(255 241 242 / 0.95) !important;
}

.tw-stroke-rose-500 {
  stroke: #f43f5e !important;
}

.tw-stroke-rose-500\/0 {
  stroke: rgb(244 63 94 / 0) !important;
}

.tw-stroke-rose-500\/10 {
  stroke: rgb(244 63 94 / 0.1) !important;
}

.tw-stroke-rose-500\/100 {
  stroke: rgb(244 63 94 / 1) !important;
}

.tw-stroke-rose-500\/15 {
  stroke: rgb(244 63 94 / 0.15) !important;
}

.tw-stroke-rose-500\/20 {
  stroke: rgb(244 63 94 / 0.2) !important;
}

.tw-stroke-rose-500\/25 {
  stroke: rgb(244 63 94 / 0.25) !important;
}

.tw-stroke-rose-500\/30 {
  stroke: rgb(244 63 94 / 0.3) !important;
}

.tw-stroke-rose-500\/35 {
  stroke: rgb(244 63 94 / 0.35) !important;
}

.tw-stroke-rose-500\/40 {
  stroke: rgb(244 63 94 / 0.4) !important;
}

.tw-stroke-rose-500\/45 {
  stroke: rgb(244 63 94 / 0.45) !important;
}

.tw-stroke-rose-500\/5 {
  stroke: rgb(244 63 94 / 0.05) !important;
}

.tw-stroke-rose-500\/50 {
  stroke: rgb(244 63 94 / 0.5) !important;
}

.tw-stroke-rose-500\/55 {
  stroke: rgb(244 63 94 / 0.55) !important;
}

.tw-stroke-rose-500\/60 {
  stroke: rgb(244 63 94 / 0.6) !important;
}

.tw-stroke-rose-500\/65 {
  stroke: rgb(244 63 94 / 0.65) !important;
}

.tw-stroke-rose-500\/70 {
  stroke: rgb(244 63 94 / 0.7) !important;
}

.tw-stroke-rose-500\/75 {
  stroke: rgb(244 63 94 / 0.75) !important;
}

.tw-stroke-rose-500\/80 {
  stroke: rgb(244 63 94 / 0.8) !important;
}

.tw-stroke-rose-500\/85 {
  stroke: rgb(244 63 94 / 0.85) !important;
}

.tw-stroke-rose-500\/90 {
  stroke: rgb(244 63 94 / 0.9) !important;
}

.tw-stroke-rose-500\/95 {
  stroke: rgb(244 63 94 / 0.95) !important;
}

.tw-stroke-rose-600 {
  stroke: #e11d48 !important;
}

.tw-stroke-rose-600\/0 {
  stroke: rgb(225 29 72 / 0) !important;
}

.tw-stroke-rose-600\/10 {
  stroke: rgb(225 29 72 / 0.1) !important;
}

.tw-stroke-rose-600\/100 {
  stroke: rgb(225 29 72 / 1) !important;
}

.tw-stroke-rose-600\/15 {
  stroke: rgb(225 29 72 / 0.15) !important;
}

.tw-stroke-rose-600\/20 {
  stroke: rgb(225 29 72 / 0.2) !important;
}

.tw-stroke-rose-600\/25 {
  stroke: rgb(225 29 72 / 0.25) !important;
}

.tw-stroke-rose-600\/30 {
  stroke: rgb(225 29 72 / 0.3) !important;
}

.tw-stroke-rose-600\/35 {
  stroke: rgb(225 29 72 / 0.35) !important;
}

.tw-stroke-rose-600\/40 {
  stroke: rgb(225 29 72 / 0.4) !important;
}

.tw-stroke-rose-600\/45 {
  stroke: rgb(225 29 72 / 0.45) !important;
}

.tw-stroke-rose-600\/5 {
  stroke: rgb(225 29 72 / 0.05) !important;
}

.tw-stroke-rose-600\/50 {
  stroke: rgb(225 29 72 / 0.5) !important;
}

.tw-stroke-rose-600\/55 {
  stroke: rgb(225 29 72 / 0.55) !important;
}

.tw-stroke-rose-600\/60 {
  stroke: rgb(225 29 72 / 0.6) !important;
}

.tw-stroke-rose-600\/65 {
  stroke: rgb(225 29 72 / 0.65) !important;
}

.tw-stroke-rose-600\/70 {
  stroke: rgb(225 29 72 / 0.7) !important;
}

.tw-stroke-rose-600\/75 {
  stroke: rgb(225 29 72 / 0.75) !important;
}

.tw-stroke-rose-600\/80 {
  stroke: rgb(225 29 72 / 0.8) !important;
}

.tw-stroke-rose-600\/85 {
  stroke: rgb(225 29 72 / 0.85) !important;
}

.tw-stroke-rose-600\/90 {
  stroke: rgb(225 29 72 / 0.9) !important;
}

.tw-stroke-rose-600\/95 {
  stroke: rgb(225 29 72 / 0.95) !important;
}

.tw-stroke-rose-700 {
  stroke: #be123c !important;
}

.tw-stroke-rose-700\/0 {
  stroke: rgb(190 18 60 / 0) !important;
}

.tw-stroke-rose-700\/10 {
  stroke: rgb(190 18 60 / 0.1) !important;
}

.tw-stroke-rose-700\/100 {
  stroke: rgb(190 18 60 / 1) !important;
}

.tw-stroke-rose-700\/15 {
  stroke: rgb(190 18 60 / 0.15) !important;
}

.tw-stroke-rose-700\/20 {
  stroke: rgb(190 18 60 / 0.2) !important;
}

.tw-stroke-rose-700\/25 {
  stroke: rgb(190 18 60 / 0.25) !important;
}

.tw-stroke-rose-700\/30 {
  stroke: rgb(190 18 60 / 0.3) !important;
}

.tw-stroke-rose-700\/35 {
  stroke: rgb(190 18 60 / 0.35) !important;
}

.tw-stroke-rose-700\/40 {
  stroke: rgb(190 18 60 / 0.4) !important;
}

.tw-stroke-rose-700\/45 {
  stroke: rgb(190 18 60 / 0.45) !important;
}

.tw-stroke-rose-700\/5 {
  stroke: rgb(190 18 60 / 0.05) !important;
}

.tw-stroke-rose-700\/50 {
  stroke: rgb(190 18 60 / 0.5) !important;
}

.tw-stroke-rose-700\/55 {
  stroke: rgb(190 18 60 / 0.55) !important;
}

.tw-stroke-rose-700\/60 {
  stroke: rgb(190 18 60 / 0.6) !important;
}

.tw-stroke-rose-700\/65 {
  stroke: rgb(190 18 60 / 0.65) !important;
}

.tw-stroke-rose-700\/70 {
  stroke: rgb(190 18 60 / 0.7) !important;
}

.tw-stroke-rose-700\/75 {
  stroke: rgb(190 18 60 / 0.75) !important;
}

.tw-stroke-rose-700\/80 {
  stroke: rgb(190 18 60 / 0.8) !important;
}

.tw-stroke-rose-700\/85 {
  stroke: rgb(190 18 60 / 0.85) !important;
}

.tw-stroke-rose-700\/90 {
  stroke: rgb(190 18 60 / 0.9) !important;
}

.tw-stroke-rose-700\/95 {
  stroke: rgb(190 18 60 / 0.95) !important;
}

.tw-stroke-rose-800 {
  stroke: #9f1239 !important;
}

.tw-stroke-rose-800\/0 {
  stroke: rgb(159 18 57 / 0) !important;
}

.tw-stroke-rose-800\/10 {
  stroke: rgb(159 18 57 / 0.1) !important;
}

.tw-stroke-rose-800\/100 {
  stroke: rgb(159 18 57 / 1) !important;
}

.tw-stroke-rose-800\/15 {
  stroke: rgb(159 18 57 / 0.15) !important;
}

.tw-stroke-rose-800\/20 {
  stroke: rgb(159 18 57 / 0.2) !important;
}

.tw-stroke-rose-800\/25 {
  stroke: rgb(159 18 57 / 0.25) !important;
}

.tw-stroke-rose-800\/30 {
  stroke: rgb(159 18 57 / 0.3) !important;
}

.tw-stroke-rose-800\/35 {
  stroke: rgb(159 18 57 / 0.35) !important;
}

.tw-stroke-rose-800\/40 {
  stroke: rgb(159 18 57 / 0.4) !important;
}

.tw-stroke-rose-800\/45 {
  stroke: rgb(159 18 57 / 0.45) !important;
}

.tw-stroke-rose-800\/5 {
  stroke: rgb(159 18 57 / 0.05) !important;
}

.tw-stroke-rose-800\/50 {
  stroke: rgb(159 18 57 / 0.5) !important;
}

.tw-stroke-rose-800\/55 {
  stroke: rgb(159 18 57 / 0.55) !important;
}

.tw-stroke-rose-800\/60 {
  stroke: rgb(159 18 57 / 0.6) !important;
}

.tw-stroke-rose-800\/65 {
  stroke: rgb(159 18 57 / 0.65) !important;
}

.tw-stroke-rose-800\/70 {
  stroke: rgb(159 18 57 / 0.7) !important;
}

.tw-stroke-rose-800\/75 {
  stroke: rgb(159 18 57 / 0.75) !important;
}

.tw-stroke-rose-800\/80 {
  stroke: rgb(159 18 57 / 0.8) !important;
}

.tw-stroke-rose-800\/85 {
  stroke: rgb(159 18 57 / 0.85) !important;
}

.tw-stroke-rose-800\/90 {
  stroke: rgb(159 18 57 / 0.9) !important;
}

.tw-stroke-rose-800\/95 {
  stroke: rgb(159 18 57 / 0.95) !important;
}

.tw-stroke-rose-900 {
  stroke: #881337 !important;
}

.tw-stroke-rose-900\/0 {
  stroke: rgb(136 19 55 / 0) !important;
}

.tw-stroke-rose-900\/10 {
  stroke: rgb(136 19 55 / 0.1) !important;
}

.tw-stroke-rose-900\/100 {
  stroke: rgb(136 19 55 / 1) !important;
}

.tw-stroke-rose-900\/15 {
  stroke: rgb(136 19 55 / 0.15) !important;
}

.tw-stroke-rose-900\/20 {
  stroke: rgb(136 19 55 / 0.2) !important;
}

.tw-stroke-rose-900\/25 {
  stroke: rgb(136 19 55 / 0.25) !important;
}

.tw-stroke-rose-900\/30 {
  stroke: rgb(136 19 55 / 0.3) !important;
}

.tw-stroke-rose-900\/35 {
  stroke: rgb(136 19 55 / 0.35) !important;
}

.tw-stroke-rose-900\/40 {
  stroke: rgb(136 19 55 / 0.4) !important;
}

.tw-stroke-rose-900\/45 {
  stroke: rgb(136 19 55 / 0.45) !important;
}

.tw-stroke-rose-900\/5 {
  stroke: rgb(136 19 55 / 0.05) !important;
}

.tw-stroke-rose-900\/50 {
  stroke: rgb(136 19 55 / 0.5) !important;
}

.tw-stroke-rose-900\/55 {
  stroke: rgb(136 19 55 / 0.55) !important;
}

.tw-stroke-rose-900\/60 {
  stroke: rgb(136 19 55 / 0.6) !important;
}

.tw-stroke-rose-900\/65 {
  stroke: rgb(136 19 55 / 0.65) !important;
}

.tw-stroke-rose-900\/70 {
  stroke: rgb(136 19 55 / 0.7) !important;
}

.tw-stroke-rose-900\/75 {
  stroke: rgb(136 19 55 / 0.75) !important;
}

.tw-stroke-rose-900\/80 {
  stroke: rgb(136 19 55 / 0.8) !important;
}

.tw-stroke-rose-900\/85 {
  stroke: rgb(136 19 55 / 0.85) !important;
}

.tw-stroke-rose-900\/90 {
  stroke: rgb(136 19 55 / 0.9) !important;
}

.tw-stroke-rose-900\/95 {
  stroke: rgb(136 19 55 / 0.95) !important;
}

.tw-stroke-rose-950 {
  stroke: #4c0519 !important;
}

.tw-stroke-rose-950\/0 {
  stroke: rgb(76 5 25 / 0) !important;
}

.tw-stroke-rose-950\/10 {
  stroke: rgb(76 5 25 / 0.1) !important;
}

.tw-stroke-rose-950\/100 {
  stroke: rgb(76 5 25 / 1) !important;
}

.tw-stroke-rose-950\/15 {
  stroke: rgb(76 5 25 / 0.15) !important;
}

.tw-stroke-rose-950\/20 {
  stroke: rgb(76 5 25 / 0.2) !important;
}

.tw-stroke-rose-950\/25 {
  stroke: rgb(76 5 25 / 0.25) !important;
}

.tw-stroke-rose-950\/30 {
  stroke: rgb(76 5 25 / 0.3) !important;
}

.tw-stroke-rose-950\/35 {
  stroke: rgb(76 5 25 / 0.35) !important;
}

.tw-stroke-rose-950\/40 {
  stroke: rgb(76 5 25 / 0.4) !important;
}

.tw-stroke-rose-950\/45 {
  stroke: rgb(76 5 25 / 0.45) !important;
}

.tw-stroke-rose-950\/5 {
  stroke: rgb(76 5 25 / 0.05) !important;
}

.tw-stroke-rose-950\/50 {
  stroke: rgb(76 5 25 / 0.5) !important;
}

.tw-stroke-rose-950\/55 {
  stroke: rgb(76 5 25 / 0.55) !important;
}

.tw-stroke-rose-950\/60 {
  stroke: rgb(76 5 25 / 0.6) !important;
}

.tw-stroke-rose-950\/65 {
  stroke: rgb(76 5 25 / 0.65) !important;
}

.tw-stroke-rose-950\/70 {
  stroke: rgb(76 5 25 / 0.7) !important;
}

.tw-stroke-rose-950\/75 {
  stroke: rgb(76 5 25 / 0.75) !important;
}

.tw-stroke-rose-950\/80 {
  stroke: rgb(76 5 25 / 0.8) !important;
}

.tw-stroke-rose-950\/85 {
  stroke: rgb(76 5 25 / 0.85) !important;
}

.tw-stroke-rose-950\/90 {
  stroke: rgb(76 5 25 / 0.9) !important;
}

.tw-stroke-rose-950\/95 {
  stroke: rgb(76 5 25 / 0.95) !important;
}

.tw-stroke-sky-100 {
  stroke: #e0f2fe !important;
}

.tw-stroke-sky-100\/0 {
  stroke: rgb(224 242 254 / 0) !important;
}

.tw-stroke-sky-100\/10 {
  stroke: rgb(224 242 254 / 0.1) !important;
}

.tw-stroke-sky-100\/100 {
  stroke: rgb(224 242 254 / 1) !important;
}

.tw-stroke-sky-100\/15 {
  stroke: rgb(224 242 254 / 0.15) !important;
}

.tw-stroke-sky-100\/20 {
  stroke: rgb(224 242 254 / 0.2) !important;
}

.tw-stroke-sky-100\/25 {
  stroke: rgb(224 242 254 / 0.25) !important;
}

.tw-stroke-sky-100\/30 {
  stroke: rgb(224 242 254 / 0.3) !important;
}

.tw-stroke-sky-100\/35 {
  stroke: rgb(224 242 254 / 0.35) !important;
}

.tw-stroke-sky-100\/40 {
  stroke: rgb(224 242 254 / 0.4) !important;
}

.tw-stroke-sky-100\/45 {
  stroke: rgb(224 242 254 / 0.45) !important;
}

.tw-stroke-sky-100\/5 {
  stroke: rgb(224 242 254 / 0.05) !important;
}

.tw-stroke-sky-100\/50 {
  stroke: rgb(224 242 254 / 0.5) !important;
}

.tw-stroke-sky-100\/55 {
  stroke: rgb(224 242 254 / 0.55) !important;
}

.tw-stroke-sky-100\/60 {
  stroke: rgb(224 242 254 / 0.6) !important;
}

.tw-stroke-sky-100\/65 {
  stroke: rgb(224 242 254 / 0.65) !important;
}

.tw-stroke-sky-100\/70 {
  stroke: rgb(224 242 254 / 0.7) !important;
}

.tw-stroke-sky-100\/75 {
  stroke: rgb(224 242 254 / 0.75) !important;
}

.tw-stroke-sky-100\/80 {
  stroke: rgb(224 242 254 / 0.8) !important;
}

.tw-stroke-sky-100\/85 {
  stroke: rgb(224 242 254 / 0.85) !important;
}

.tw-stroke-sky-100\/90 {
  stroke: rgb(224 242 254 / 0.9) !important;
}

.tw-stroke-sky-100\/95 {
  stroke: rgb(224 242 254 / 0.95) !important;
}

.tw-stroke-sky-200 {
  stroke: #bae6fd !important;
}

.tw-stroke-sky-200\/0 {
  stroke: rgb(186 230 253 / 0) !important;
}

.tw-stroke-sky-200\/10 {
  stroke: rgb(186 230 253 / 0.1) !important;
}

.tw-stroke-sky-200\/100 {
  stroke: rgb(186 230 253 / 1) !important;
}

.tw-stroke-sky-200\/15 {
  stroke: rgb(186 230 253 / 0.15) !important;
}

.tw-stroke-sky-200\/20 {
  stroke: rgb(186 230 253 / 0.2) !important;
}

.tw-stroke-sky-200\/25 {
  stroke: rgb(186 230 253 / 0.25) !important;
}

.tw-stroke-sky-200\/30 {
  stroke: rgb(186 230 253 / 0.3) !important;
}

.tw-stroke-sky-200\/35 {
  stroke: rgb(186 230 253 / 0.35) !important;
}

.tw-stroke-sky-200\/40 {
  stroke: rgb(186 230 253 / 0.4) !important;
}

.tw-stroke-sky-200\/45 {
  stroke: rgb(186 230 253 / 0.45) !important;
}

.tw-stroke-sky-200\/5 {
  stroke: rgb(186 230 253 / 0.05) !important;
}

.tw-stroke-sky-200\/50 {
  stroke: rgb(186 230 253 / 0.5) !important;
}

.tw-stroke-sky-200\/55 {
  stroke: rgb(186 230 253 / 0.55) !important;
}

.tw-stroke-sky-200\/60 {
  stroke: rgb(186 230 253 / 0.6) !important;
}

.tw-stroke-sky-200\/65 {
  stroke: rgb(186 230 253 / 0.65) !important;
}

.tw-stroke-sky-200\/70 {
  stroke: rgb(186 230 253 / 0.7) !important;
}

.tw-stroke-sky-200\/75 {
  stroke: rgb(186 230 253 / 0.75) !important;
}

.tw-stroke-sky-200\/80 {
  stroke: rgb(186 230 253 / 0.8) !important;
}

.tw-stroke-sky-200\/85 {
  stroke: rgb(186 230 253 / 0.85) !important;
}

.tw-stroke-sky-200\/90 {
  stroke: rgb(186 230 253 / 0.9) !important;
}

.tw-stroke-sky-200\/95 {
  stroke: rgb(186 230 253 / 0.95) !important;
}

.tw-stroke-sky-300 {
  stroke: #7dd3fc !important;
}

.tw-stroke-sky-300\/0 {
  stroke: rgb(125 211 252 / 0) !important;
}

.tw-stroke-sky-300\/10 {
  stroke: rgb(125 211 252 / 0.1) !important;
}

.tw-stroke-sky-300\/100 {
  stroke: rgb(125 211 252 / 1) !important;
}

.tw-stroke-sky-300\/15 {
  stroke: rgb(125 211 252 / 0.15) !important;
}

.tw-stroke-sky-300\/20 {
  stroke: rgb(125 211 252 / 0.2) !important;
}

.tw-stroke-sky-300\/25 {
  stroke: rgb(125 211 252 / 0.25) !important;
}

.tw-stroke-sky-300\/30 {
  stroke: rgb(125 211 252 / 0.3) !important;
}

.tw-stroke-sky-300\/35 {
  stroke: rgb(125 211 252 / 0.35) !important;
}

.tw-stroke-sky-300\/40 {
  stroke: rgb(125 211 252 / 0.4) !important;
}

.tw-stroke-sky-300\/45 {
  stroke: rgb(125 211 252 / 0.45) !important;
}

.tw-stroke-sky-300\/5 {
  stroke: rgb(125 211 252 / 0.05) !important;
}

.tw-stroke-sky-300\/50 {
  stroke: rgb(125 211 252 / 0.5) !important;
}

.tw-stroke-sky-300\/55 {
  stroke: rgb(125 211 252 / 0.55) !important;
}

.tw-stroke-sky-300\/60 {
  stroke: rgb(125 211 252 / 0.6) !important;
}

.tw-stroke-sky-300\/65 {
  stroke: rgb(125 211 252 / 0.65) !important;
}

.tw-stroke-sky-300\/70 {
  stroke: rgb(125 211 252 / 0.7) !important;
}

.tw-stroke-sky-300\/75 {
  stroke: rgb(125 211 252 / 0.75) !important;
}

.tw-stroke-sky-300\/80 {
  stroke: rgb(125 211 252 / 0.8) !important;
}

.tw-stroke-sky-300\/85 {
  stroke: rgb(125 211 252 / 0.85) !important;
}

.tw-stroke-sky-300\/90 {
  stroke: rgb(125 211 252 / 0.9) !important;
}

.tw-stroke-sky-300\/95 {
  stroke: rgb(125 211 252 / 0.95) !important;
}

.tw-stroke-sky-400 {
  stroke: #38bdf8 !important;
}

.tw-stroke-sky-400\/0 {
  stroke: rgb(56 189 248 / 0) !important;
}

.tw-stroke-sky-400\/10 {
  stroke: rgb(56 189 248 / 0.1) !important;
}

.tw-stroke-sky-400\/100 {
  stroke: rgb(56 189 248 / 1) !important;
}

.tw-stroke-sky-400\/15 {
  stroke: rgb(56 189 248 / 0.15) !important;
}

.tw-stroke-sky-400\/20 {
  stroke: rgb(56 189 248 / 0.2) !important;
}

.tw-stroke-sky-400\/25 {
  stroke: rgb(56 189 248 / 0.25) !important;
}

.tw-stroke-sky-400\/30 {
  stroke: rgb(56 189 248 / 0.3) !important;
}

.tw-stroke-sky-400\/35 {
  stroke: rgb(56 189 248 / 0.35) !important;
}

.tw-stroke-sky-400\/40 {
  stroke: rgb(56 189 248 / 0.4) !important;
}

.tw-stroke-sky-400\/45 {
  stroke: rgb(56 189 248 / 0.45) !important;
}

.tw-stroke-sky-400\/5 {
  stroke: rgb(56 189 248 / 0.05) !important;
}

.tw-stroke-sky-400\/50 {
  stroke: rgb(56 189 248 / 0.5) !important;
}

.tw-stroke-sky-400\/55 {
  stroke: rgb(56 189 248 / 0.55) !important;
}

.tw-stroke-sky-400\/60 {
  stroke: rgb(56 189 248 / 0.6) !important;
}

.tw-stroke-sky-400\/65 {
  stroke: rgb(56 189 248 / 0.65) !important;
}

.tw-stroke-sky-400\/70 {
  stroke: rgb(56 189 248 / 0.7) !important;
}

.tw-stroke-sky-400\/75 {
  stroke: rgb(56 189 248 / 0.75) !important;
}

.tw-stroke-sky-400\/80 {
  stroke: rgb(56 189 248 / 0.8) !important;
}

.tw-stroke-sky-400\/85 {
  stroke: rgb(56 189 248 / 0.85) !important;
}

.tw-stroke-sky-400\/90 {
  stroke: rgb(56 189 248 / 0.9) !important;
}

.tw-stroke-sky-400\/95 {
  stroke: rgb(56 189 248 / 0.95) !important;
}

.tw-stroke-sky-50 {
  stroke: #f0f9ff !important;
}

.tw-stroke-sky-50\/0 {
  stroke: rgb(240 249 255 / 0) !important;
}

.tw-stroke-sky-50\/10 {
  stroke: rgb(240 249 255 / 0.1) !important;
}

.tw-stroke-sky-50\/100 {
  stroke: rgb(240 249 255 / 1) !important;
}

.tw-stroke-sky-50\/15 {
  stroke: rgb(240 249 255 / 0.15) !important;
}

.tw-stroke-sky-50\/20 {
  stroke: rgb(240 249 255 / 0.2) !important;
}

.tw-stroke-sky-50\/25 {
  stroke: rgb(240 249 255 / 0.25) !important;
}

.tw-stroke-sky-50\/30 {
  stroke: rgb(240 249 255 / 0.3) !important;
}

.tw-stroke-sky-50\/35 {
  stroke: rgb(240 249 255 / 0.35) !important;
}

.tw-stroke-sky-50\/40 {
  stroke: rgb(240 249 255 / 0.4) !important;
}

.tw-stroke-sky-50\/45 {
  stroke: rgb(240 249 255 / 0.45) !important;
}

.tw-stroke-sky-50\/5 {
  stroke: rgb(240 249 255 / 0.05) !important;
}

.tw-stroke-sky-50\/50 {
  stroke: rgb(240 249 255 / 0.5) !important;
}

.tw-stroke-sky-50\/55 {
  stroke: rgb(240 249 255 / 0.55) !important;
}

.tw-stroke-sky-50\/60 {
  stroke: rgb(240 249 255 / 0.6) !important;
}

.tw-stroke-sky-50\/65 {
  stroke: rgb(240 249 255 / 0.65) !important;
}

.tw-stroke-sky-50\/70 {
  stroke: rgb(240 249 255 / 0.7) !important;
}

.tw-stroke-sky-50\/75 {
  stroke: rgb(240 249 255 / 0.75) !important;
}

.tw-stroke-sky-50\/80 {
  stroke: rgb(240 249 255 / 0.8) !important;
}

.tw-stroke-sky-50\/85 {
  stroke: rgb(240 249 255 / 0.85) !important;
}

.tw-stroke-sky-50\/90 {
  stroke: rgb(240 249 255 / 0.9) !important;
}

.tw-stroke-sky-50\/95 {
  stroke: rgb(240 249 255 / 0.95) !important;
}

.tw-stroke-sky-500 {
  stroke: #0ea5e9 !important;
}

.tw-stroke-sky-500\/0 {
  stroke: rgb(14 165 233 / 0) !important;
}

.tw-stroke-sky-500\/10 {
  stroke: rgb(14 165 233 / 0.1) !important;
}

.tw-stroke-sky-500\/100 {
  stroke: rgb(14 165 233 / 1) !important;
}

.tw-stroke-sky-500\/15 {
  stroke: rgb(14 165 233 / 0.15) !important;
}

.tw-stroke-sky-500\/20 {
  stroke: rgb(14 165 233 / 0.2) !important;
}

.tw-stroke-sky-500\/25 {
  stroke: rgb(14 165 233 / 0.25) !important;
}

.tw-stroke-sky-500\/30 {
  stroke: rgb(14 165 233 / 0.3) !important;
}

.tw-stroke-sky-500\/35 {
  stroke: rgb(14 165 233 / 0.35) !important;
}

.tw-stroke-sky-500\/40 {
  stroke: rgb(14 165 233 / 0.4) !important;
}

.tw-stroke-sky-500\/45 {
  stroke: rgb(14 165 233 / 0.45) !important;
}

.tw-stroke-sky-500\/5 {
  stroke: rgb(14 165 233 / 0.05) !important;
}

.tw-stroke-sky-500\/50 {
  stroke: rgb(14 165 233 / 0.5) !important;
}

.tw-stroke-sky-500\/55 {
  stroke: rgb(14 165 233 / 0.55) !important;
}

.tw-stroke-sky-500\/60 {
  stroke: rgb(14 165 233 / 0.6) !important;
}

.tw-stroke-sky-500\/65 {
  stroke: rgb(14 165 233 / 0.65) !important;
}

.tw-stroke-sky-500\/70 {
  stroke: rgb(14 165 233 / 0.7) !important;
}

.tw-stroke-sky-500\/75 {
  stroke: rgb(14 165 233 / 0.75) !important;
}

.tw-stroke-sky-500\/80 {
  stroke: rgb(14 165 233 / 0.8) !important;
}

.tw-stroke-sky-500\/85 {
  stroke: rgb(14 165 233 / 0.85) !important;
}

.tw-stroke-sky-500\/90 {
  stroke: rgb(14 165 233 / 0.9) !important;
}

.tw-stroke-sky-500\/95 {
  stroke: rgb(14 165 233 / 0.95) !important;
}

.tw-stroke-sky-600 {
  stroke: #0284c7 !important;
}

.tw-stroke-sky-600\/0 {
  stroke: rgb(2 132 199 / 0) !important;
}

.tw-stroke-sky-600\/10 {
  stroke: rgb(2 132 199 / 0.1) !important;
}

.tw-stroke-sky-600\/100 {
  stroke: rgb(2 132 199 / 1) !important;
}

.tw-stroke-sky-600\/15 {
  stroke: rgb(2 132 199 / 0.15) !important;
}

.tw-stroke-sky-600\/20 {
  stroke: rgb(2 132 199 / 0.2) !important;
}

.tw-stroke-sky-600\/25 {
  stroke: rgb(2 132 199 / 0.25) !important;
}

.tw-stroke-sky-600\/30 {
  stroke: rgb(2 132 199 / 0.3) !important;
}

.tw-stroke-sky-600\/35 {
  stroke: rgb(2 132 199 / 0.35) !important;
}

.tw-stroke-sky-600\/40 {
  stroke: rgb(2 132 199 / 0.4) !important;
}

.tw-stroke-sky-600\/45 {
  stroke: rgb(2 132 199 / 0.45) !important;
}

.tw-stroke-sky-600\/5 {
  stroke: rgb(2 132 199 / 0.05) !important;
}

.tw-stroke-sky-600\/50 {
  stroke: rgb(2 132 199 / 0.5) !important;
}

.tw-stroke-sky-600\/55 {
  stroke: rgb(2 132 199 / 0.55) !important;
}

.tw-stroke-sky-600\/60 {
  stroke: rgb(2 132 199 / 0.6) !important;
}

.tw-stroke-sky-600\/65 {
  stroke: rgb(2 132 199 / 0.65) !important;
}

.tw-stroke-sky-600\/70 {
  stroke: rgb(2 132 199 / 0.7) !important;
}

.tw-stroke-sky-600\/75 {
  stroke: rgb(2 132 199 / 0.75) !important;
}

.tw-stroke-sky-600\/80 {
  stroke: rgb(2 132 199 / 0.8) !important;
}

.tw-stroke-sky-600\/85 {
  stroke: rgb(2 132 199 / 0.85) !important;
}

.tw-stroke-sky-600\/90 {
  stroke: rgb(2 132 199 / 0.9) !important;
}

.tw-stroke-sky-600\/95 {
  stroke: rgb(2 132 199 / 0.95) !important;
}

.tw-stroke-sky-700 {
  stroke: #0369a1 !important;
}

.tw-stroke-sky-700\/0 {
  stroke: rgb(3 105 161 / 0) !important;
}

.tw-stroke-sky-700\/10 {
  stroke: rgb(3 105 161 / 0.1) !important;
}

.tw-stroke-sky-700\/100 {
  stroke: rgb(3 105 161 / 1) !important;
}

.tw-stroke-sky-700\/15 {
  stroke: rgb(3 105 161 / 0.15) !important;
}

.tw-stroke-sky-700\/20 {
  stroke: rgb(3 105 161 / 0.2) !important;
}

.tw-stroke-sky-700\/25 {
  stroke: rgb(3 105 161 / 0.25) !important;
}

.tw-stroke-sky-700\/30 {
  stroke: rgb(3 105 161 / 0.3) !important;
}

.tw-stroke-sky-700\/35 {
  stroke: rgb(3 105 161 / 0.35) !important;
}

.tw-stroke-sky-700\/40 {
  stroke: rgb(3 105 161 / 0.4) !important;
}

.tw-stroke-sky-700\/45 {
  stroke: rgb(3 105 161 / 0.45) !important;
}

.tw-stroke-sky-700\/5 {
  stroke: rgb(3 105 161 / 0.05) !important;
}

.tw-stroke-sky-700\/50 {
  stroke: rgb(3 105 161 / 0.5) !important;
}

.tw-stroke-sky-700\/55 {
  stroke: rgb(3 105 161 / 0.55) !important;
}

.tw-stroke-sky-700\/60 {
  stroke: rgb(3 105 161 / 0.6) !important;
}

.tw-stroke-sky-700\/65 {
  stroke: rgb(3 105 161 / 0.65) !important;
}

.tw-stroke-sky-700\/70 {
  stroke: rgb(3 105 161 / 0.7) !important;
}

.tw-stroke-sky-700\/75 {
  stroke: rgb(3 105 161 / 0.75) !important;
}

.tw-stroke-sky-700\/80 {
  stroke: rgb(3 105 161 / 0.8) !important;
}

.tw-stroke-sky-700\/85 {
  stroke: rgb(3 105 161 / 0.85) !important;
}

.tw-stroke-sky-700\/90 {
  stroke: rgb(3 105 161 / 0.9) !important;
}

.tw-stroke-sky-700\/95 {
  stroke: rgb(3 105 161 / 0.95) !important;
}

.tw-stroke-sky-800 {
  stroke: #075985 !important;
}

.tw-stroke-sky-800\/0 {
  stroke: rgb(7 89 133 / 0) !important;
}

.tw-stroke-sky-800\/10 {
  stroke: rgb(7 89 133 / 0.1) !important;
}

.tw-stroke-sky-800\/100 {
  stroke: rgb(7 89 133 / 1) !important;
}

.tw-stroke-sky-800\/15 {
  stroke: rgb(7 89 133 / 0.15) !important;
}

.tw-stroke-sky-800\/20 {
  stroke: rgb(7 89 133 / 0.2) !important;
}

.tw-stroke-sky-800\/25 {
  stroke: rgb(7 89 133 / 0.25) !important;
}

.tw-stroke-sky-800\/30 {
  stroke: rgb(7 89 133 / 0.3) !important;
}

.tw-stroke-sky-800\/35 {
  stroke: rgb(7 89 133 / 0.35) !important;
}

.tw-stroke-sky-800\/40 {
  stroke: rgb(7 89 133 / 0.4) !important;
}

.tw-stroke-sky-800\/45 {
  stroke: rgb(7 89 133 / 0.45) !important;
}

.tw-stroke-sky-800\/5 {
  stroke: rgb(7 89 133 / 0.05) !important;
}

.tw-stroke-sky-800\/50 {
  stroke: rgb(7 89 133 / 0.5) !important;
}

.tw-stroke-sky-800\/55 {
  stroke: rgb(7 89 133 / 0.55) !important;
}

.tw-stroke-sky-800\/60 {
  stroke: rgb(7 89 133 / 0.6) !important;
}

.tw-stroke-sky-800\/65 {
  stroke: rgb(7 89 133 / 0.65) !important;
}

.tw-stroke-sky-800\/70 {
  stroke: rgb(7 89 133 / 0.7) !important;
}

.tw-stroke-sky-800\/75 {
  stroke: rgb(7 89 133 / 0.75) !important;
}

.tw-stroke-sky-800\/80 {
  stroke: rgb(7 89 133 / 0.8) !important;
}

.tw-stroke-sky-800\/85 {
  stroke: rgb(7 89 133 / 0.85) !important;
}

.tw-stroke-sky-800\/90 {
  stroke: rgb(7 89 133 / 0.9) !important;
}

.tw-stroke-sky-800\/95 {
  stroke: rgb(7 89 133 / 0.95) !important;
}

.tw-stroke-sky-900 {
  stroke: #0c4a6e !important;
}

.tw-stroke-sky-900\/0 {
  stroke: rgb(12 74 110 / 0) !important;
}

.tw-stroke-sky-900\/10 {
  stroke: rgb(12 74 110 / 0.1) !important;
}

.tw-stroke-sky-900\/100 {
  stroke: rgb(12 74 110 / 1) !important;
}

.tw-stroke-sky-900\/15 {
  stroke: rgb(12 74 110 / 0.15) !important;
}

.tw-stroke-sky-900\/20 {
  stroke: rgb(12 74 110 / 0.2) !important;
}

.tw-stroke-sky-900\/25 {
  stroke: rgb(12 74 110 / 0.25) !important;
}

.tw-stroke-sky-900\/30 {
  stroke: rgb(12 74 110 / 0.3) !important;
}

.tw-stroke-sky-900\/35 {
  stroke: rgb(12 74 110 / 0.35) !important;
}

.tw-stroke-sky-900\/40 {
  stroke: rgb(12 74 110 / 0.4) !important;
}

.tw-stroke-sky-900\/45 {
  stroke: rgb(12 74 110 / 0.45) !important;
}

.tw-stroke-sky-900\/5 {
  stroke: rgb(12 74 110 / 0.05) !important;
}

.tw-stroke-sky-900\/50 {
  stroke: rgb(12 74 110 / 0.5) !important;
}

.tw-stroke-sky-900\/55 {
  stroke: rgb(12 74 110 / 0.55) !important;
}

.tw-stroke-sky-900\/60 {
  stroke: rgb(12 74 110 / 0.6) !important;
}

.tw-stroke-sky-900\/65 {
  stroke: rgb(12 74 110 / 0.65) !important;
}

.tw-stroke-sky-900\/70 {
  stroke: rgb(12 74 110 / 0.7) !important;
}

.tw-stroke-sky-900\/75 {
  stroke: rgb(12 74 110 / 0.75) !important;
}

.tw-stroke-sky-900\/80 {
  stroke: rgb(12 74 110 / 0.8) !important;
}

.tw-stroke-sky-900\/85 {
  stroke: rgb(12 74 110 / 0.85) !important;
}

.tw-stroke-sky-900\/90 {
  stroke: rgb(12 74 110 / 0.9) !important;
}

.tw-stroke-sky-900\/95 {
  stroke: rgb(12 74 110 / 0.95) !important;
}

.tw-stroke-sky-950 {
  stroke: #082f49 !important;
}

.tw-stroke-sky-950\/0 {
  stroke: rgb(8 47 73 / 0) !important;
}

.tw-stroke-sky-950\/10 {
  stroke: rgb(8 47 73 / 0.1) !important;
}

.tw-stroke-sky-950\/100 {
  stroke: rgb(8 47 73 / 1) !important;
}

.tw-stroke-sky-950\/15 {
  stroke: rgb(8 47 73 / 0.15) !important;
}

.tw-stroke-sky-950\/20 {
  stroke: rgb(8 47 73 / 0.2) !important;
}

.tw-stroke-sky-950\/25 {
  stroke: rgb(8 47 73 / 0.25) !important;
}

.tw-stroke-sky-950\/30 {
  stroke: rgb(8 47 73 / 0.3) !important;
}

.tw-stroke-sky-950\/35 {
  stroke: rgb(8 47 73 / 0.35) !important;
}

.tw-stroke-sky-950\/40 {
  stroke: rgb(8 47 73 / 0.4) !important;
}

.tw-stroke-sky-950\/45 {
  stroke: rgb(8 47 73 / 0.45) !important;
}

.tw-stroke-sky-950\/5 {
  stroke: rgb(8 47 73 / 0.05) !important;
}

.tw-stroke-sky-950\/50 {
  stroke: rgb(8 47 73 / 0.5) !important;
}

.tw-stroke-sky-950\/55 {
  stroke: rgb(8 47 73 / 0.55) !important;
}

.tw-stroke-sky-950\/60 {
  stroke: rgb(8 47 73 / 0.6) !important;
}

.tw-stroke-sky-950\/65 {
  stroke: rgb(8 47 73 / 0.65) !important;
}

.tw-stroke-sky-950\/70 {
  stroke: rgb(8 47 73 / 0.7) !important;
}

.tw-stroke-sky-950\/75 {
  stroke: rgb(8 47 73 / 0.75) !important;
}

.tw-stroke-sky-950\/80 {
  stroke: rgb(8 47 73 / 0.8) !important;
}

.tw-stroke-sky-950\/85 {
  stroke: rgb(8 47 73 / 0.85) !important;
}

.tw-stroke-sky-950\/90 {
  stroke: rgb(8 47 73 / 0.9) !important;
}

.tw-stroke-sky-950\/95 {
  stroke: rgb(8 47 73 / 0.95) !important;
}

.tw-stroke-slate-100 {
  stroke: #f1f5f9 !important;
}

.tw-stroke-slate-100\/0 {
  stroke: rgb(241 245 249 / 0) !important;
}

.tw-stroke-slate-100\/10 {
  stroke: rgb(241 245 249 / 0.1) !important;
}

.tw-stroke-slate-100\/100 {
  stroke: rgb(241 245 249 / 1) !important;
}

.tw-stroke-slate-100\/15 {
  stroke: rgb(241 245 249 / 0.15) !important;
}

.tw-stroke-slate-100\/20 {
  stroke: rgb(241 245 249 / 0.2) !important;
}

.tw-stroke-slate-100\/25 {
  stroke: rgb(241 245 249 / 0.25) !important;
}

.tw-stroke-slate-100\/30 {
  stroke: rgb(241 245 249 / 0.3) !important;
}

.tw-stroke-slate-100\/35 {
  stroke: rgb(241 245 249 / 0.35) !important;
}

.tw-stroke-slate-100\/40 {
  stroke: rgb(241 245 249 / 0.4) !important;
}

.tw-stroke-slate-100\/45 {
  stroke: rgb(241 245 249 / 0.45) !important;
}

.tw-stroke-slate-100\/5 {
  stroke: rgb(241 245 249 / 0.05) !important;
}

.tw-stroke-slate-100\/50 {
  stroke: rgb(241 245 249 / 0.5) !important;
}

.tw-stroke-slate-100\/55 {
  stroke: rgb(241 245 249 / 0.55) !important;
}

.tw-stroke-slate-100\/60 {
  stroke: rgb(241 245 249 / 0.6) !important;
}

.tw-stroke-slate-100\/65 {
  stroke: rgb(241 245 249 / 0.65) !important;
}

.tw-stroke-slate-100\/70 {
  stroke: rgb(241 245 249 / 0.7) !important;
}

.tw-stroke-slate-100\/75 {
  stroke: rgb(241 245 249 / 0.75) !important;
}

.tw-stroke-slate-100\/80 {
  stroke: rgb(241 245 249 / 0.8) !important;
}

.tw-stroke-slate-100\/85 {
  stroke: rgb(241 245 249 / 0.85) !important;
}

.tw-stroke-slate-100\/90 {
  stroke: rgb(241 245 249 / 0.9) !important;
}

.tw-stroke-slate-100\/95 {
  stroke: rgb(241 245 249 / 0.95) !important;
}

.tw-stroke-slate-200 {
  stroke: #e2e8f0 !important;
}

.tw-stroke-slate-200\/0 {
  stroke: rgb(226 232 240 / 0) !important;
}

.tw-stroke-slate-200\/10 {
  stroke: rgb(226 232 240 / 0.1) !important;
}

.tw-stroke-slate-200\/100 {
  stroke: rgb(226 232 240 / 1) !important;
}

.tw-stroke-slate-200\/15 {
  stroke: rgb(226 232 240 / 0.15) !important;
}

.tw-stroke-slate-200\/20 {
  stroke: rgb(226 232 240 / 0.2) !important;
}

.tw-stroke-slate-200\/25 {
  stroke: rgb(226 232 240 / 0.25) !important;
}

.tw-stroke-slate-200\/30 {
  stroke: rgb(226 232 240 / 0.3) !important;
}

.tw-stroke-slate-200\/35 {
  stroke: rgb(226 232 240 / 0.35) !important;
}

.tw-stroke-slate-200\/40 {
  stroke: rgb(226 232 240 / 0.4) !important;
}

.tw-stroke-slate-200\/45 {
  stroke: rgb(226 232 240 / 0.45) !important;
}

.tw-stroke-slate-200\/5 {
  stroke: rgb(226 232 240 / 0.05) !important;
}

.tw-stroke-slate-200\/50 {
  stroke: rgb(226 232 240 / 0.5) !important;
}

.tw-stroke-slate-200\/55 {
  stroke: rgb(226 232 240 / 0.55) !important;
}

.tw-stroke-slate-200\/60 {
  stroke: rgb(226 232 240 / 0.6) !important;
}

.tw-stroke-slate-200\/65 {
  stroke: rgb(226 232 240 / 0.65) !important;
}

.tw-stroke-slate-200\/70 {
  stroke: rgb(226 232 240 / 0.7) !important;
}

.tw-stroke-slate-200\/75 {
  stroke: rgb(226 232 240 / 0.75) !important;
}

.tw-stroke-slate-200\/80 {
  stroke: rgb(226 232 240 / 0.8) !important;
}

.tw-stroke-slate-200\/85 {
  stroke: rgb(226 232 240 / 0.85) !important;
}

.tw-stroke-slate-200\/90 {
  stroke: rgb(226 232 240 / 0.9) !important;
}

.tw-stroke-slate-200\/95 {
  stroke: rgb(226 232 240 / 0.95) !important;
}

.tw-stroke-slate-300 {
  stroke: #cbd5e1 !important;
}

.tw-stroke-slate-300\/0 {
  stroke: rgb(203 213 225 / 0) !important;
}

.tw-stroke-slate-300\/10 {
  stroke: rgb(203 213 225 / 0.1) !important;
}

.tw-stroke-slate-300\/100 {
  stroke: rgb(203 213 225 / 1) !important;
}

.tw-stroke-slate-300\/15 {
  stroke: rgb(203 213 225 / 0.15) !important;
}

.tw-stroke-slate-300\/20 {
  stroke: rgb(203 213 225 / 0.2) !important;
}

.tw-stroke-slate-300\/25 {
  stroke: rgb(203 213 225 / 0.25) !important;
}

.tw-stroke-slate-300\/30 {
  stroke: rgb(203 213 225 / 0.3) !important;
}

.tw-stroke-slate-300\/35 {
  stroke: rgb(203 213 225 / 0.35) !important;
}

.tw-stroke-slate-300\/40 {
  stroke: rgb(203 213 225 / 0.4) !important;
}

.tw-stroke-slate-300\/45 {
  stroke: rgb(203 213 225 / 0.45) !important;
}

.tw-stroke-slate-300\/5 {
  stroke: rgb(203 213 225 / 0.05) !important;
}

.tw-stroke-slate-300\/50 {
  stroke: rgb(203 213 225 / 0.5) !important;
}

.tw-stroke-slate-300\/55 {
  stroke: rgb(203 213 225 / 0.55) !important;
}

.tw-stroke-slate-300\/60 {
  stroke: rgb(203 213 225 / 0.6) !important;
}

.tw-stroke-slate-300\/65 {
  stroke: rgb(203 213 225 / 0.65) !important;
}

.tw-stroke-slate-300\/70 {
  stroke: rgb(203 213 225 / 0.7) !important;
}

.tw-stroke-slate-300\/75 {
  stroke: rgb(203 213 225 / 0.75) !important;
}

.tw-stroke-slate-300\/80 {
  stroke: rgb(203 213 225 / 0.8) !important;
}

.tw-stroke-slate-300\/85 {
  stroke: rgb(203 213 225 / 0.85) !important;
}

.tw-stroke-slate-300\/90 {
  stroke: rgb(203 213 225 / 0.9) !important;
}

.tw-stroke-slate-300\/95 {
  stroke: rgb(203 213 225 / 0.95) !important;
}

.tw-stroke-slate-400 {
  stroke: #94a3b8 !important;
}

.tw-stroke-slate-400\/0 {
  stroke: rgb(148 163 184 / 0) !important;
}

.tw-stroke-slate-400\/10 {
  stroke: rgb(148 163 184 / 0.1) !important;
}

.tw-stroke-slate-400\/100 {
  stroke: rgb(148 163 184 / 1) !important;
}

.tw-stroke-slate-400\/15 {
  stroke: rgb(148 163 184 / 0.15) !important;
}

.tw-stroke-slate-400\/20 {
  stroke: rgb(148 163 184 / 0.2) !important;
}

.tw-stroke-slate-400\/25 {
  stroke: rgb(148 163 184 / 0.25) !important;
}

.tw-stroke-slate-400\/30 {
  stroke: rgb(148 163 184 / 0.3) !important;
}

.tw-stroke-slate-400\/35 {
  stroke: rgb(148 163 184 / 0.35) !important;
}

.tw-stroke-slate-400\/40 {
  stroke: rgb(148 163 184 / 0.4) !important;
}

.tw-stroke-slate-400\/45 {
  stroke: rgb(148 163 184 / 0.45) !important;
}

.tw-stroke-slate-400\/5 {
  stroke: rgb(148 163 184 / 0.05) !important;
}

.tw-stroke-slate-400\/50 {
  stroke: rgb(148 163 184 / 0.5) !important;
}

.tw-stroke-slate-400\/55 {
  stroke: rgb(148 163 184 / 0.55) !important;
}

.tw-stroke-slate-400\/60 {
  stroke: rgb(148 163 184 / 0.6) !important;
}

.tw-stroke-slate-400\/65 {
  stroke: rgb(148 163 184 / 0.65) !important;
}

.tw-stroke-slate-400\/70 {
  stroke: rgb(148 163 184 / 0.7) !important;
}

.tw-stroke-slate-400\/75 {
  stroke: rgb(148 163 184 / 0.75) !important;
}

.tw-stroke-slate-400\/80 {
  stroke: rgb(148 163 184 / 0.8) !important;
}

.tw-stroke-slate-400\/85 {
  stroke: rgb(148 163 184 / 0.85) !important;
}

.tw-stroke-slate-400\/90 {
  stroke: rgb(148 163 184 / 0.9) !important;
}

.tw-stroke-slate-400\/95 {
  stroke: rgb(148 163 184 / 0.95) !important;
}

.tw-stroke-slate-50 {
  stroke: #f8fafc !important;
}

.tw-stroke-slate-50\/0 {
  stroke: rgb(248 250 252 / 0) !important;
}

.tw-stroke-slate-50\/10 {
  stroke: rgb(248 250 252 / 0.1) !important;
}

.tw-stroke-slate-50\/100 {
  stroke: rgb(248 250 252 / 1) !important;
}

.tw-stroke-slate-50\/15 {
  stroke: rgb(248 250 252 / 0.15) !important;
}

.tw-stroke-slate-50\/20 {
  stroke: rgb(248 250 252 / 0.2) !important;
}

.tw-stroke-slate-50\/25 {
  stroke: rgb(248 250 252 / 0.25) !important;
}

.tw-stroke-slate-50\/30 {
  stroke: rgb(248 250 252 / 0.3) !important;
}

.tw-stroke-slate-50\/35 {
  stroke: rgb(248 250 252 / 0.35) !important;
}

.tw-stroke-slate-50\/40 {
  stroke: rgb(248 250 252 / 0.4) !important;
}

.tw-stroke-slate-50\/45 {
  stroke: rgb(248 250 252 / 0.45) !important;
}

.tw-stroke-slate-50\/5 {
  stroke: rgb(248 250 252 / 0.05) !important;
}

.tw-stroke-slate-50\/50 {
  stroke: rgb(248 250 252 / 0.5) !important;
}

.tw-stroke-slate-50\/55 {
  stroke: rgb(248 250 252 / 0.55) !important;
}

.tw-stroke-slate-50\/60 {
  stroke: rgb(248 250 252 / 0.6) !important;
}

.tw-stroke-slate-50\/65 {
  stroke: rgb(248 250 252 / 0.65) !important;
}

.tw-stroke-slate-50\/70 {
  stroke: rgb(248 250 252 / 0.7) !important;
}

.tw-stroke-slate-50\/75 {
  stroke: rgb(248 250 252 / 0.75) !important;
}

.tw-stroke-slate-50\/80 {
  stroke: rgb(248 250 252 / 0.8) !important;
}

.tw-stroke-slate-50\/85 {
  stroke: rgb(248 250 252 / 0.85) !important;
}

.tw-stroke-slate-50\/90 {
  stroke: rgb(248 250 252 / 0.9) !important;
}

.tw-stroke-slate-50\/95 {
  stroke: rgb(248 250 252 / 0.95) !important;
}

.tw-stroke-slate-500 {
  stroke: #64748b !important;
}

.tw-stroke-slate-500\/0 {
  stroke: rgb(100 116 139 / 0) !important;
}

.tw-stroke-slate-500\/10 {
  stroke: rgb(100 116 139 / 0.1) !important;
}

.tw-stroke-slate-500\/100 {
  stroke: rgb(100 116 139 / 1) !important;
}

.tw-stroke-slate-500\/15 {
  stroke: rgb(100 116 139 / 0.15) !important;
}

.tw-stroke-slate-500\/20 {
  stroke: rgb(100 116 139 / 0.2) !important;
}

.tw-stroke-slate-500\/25 {
  stroke: rgb(100 116 139 / 0.25) !important;
}

.tw-stroke-slate-500\/30 {
  stroke: rgb(100 116 139 / 0.3) !important;
}

.tw-stroke-slate-500\/35 {
  stroke: rgb(100 116 139 / 0.35) !important;
}

.tw-stroke-slate-500\/40 {
  stroke: rgb(100 116 139 / 0.4) !important;
}

.tw-stroke-slate-500\/45 {
  stroke: rgb(100 116 139 / 0.45) !important;
}

.tw-stroke-slate-500\/5 {
  stroke: rgb(100 116 139 / 0.05) !important;
}

.tw-stroke-slate-500\/50 {
  stroke: rgb(100 116 139 / 0.5) !important;
}

.tw-stroke-slate-500\/55 {
  stroke: rgb(100 116 139 / 0.55) !important;
}

.tw-stroke-slate-500\/60 {
  stroke: rgb(100 116 139 / 0.6) !important;
}

.tw-stroke-slate-500\/65 {
  stroke: rgb(100 116 139 / 0.65) !important;
}

.tw-stroke-slate-500\/70 {
  stroke: rgb(100 116 139 / 0.7) !important;
}

.tw-stroke-slate-500\/75 {
  stroke: rgb(100 116 139 / 0.75) !important;
}

.tw-stroke-slate-500\/80 {
  stroke: rgb(100 116 139 / 0.8) !important;
}

.tw-stroke-slate-500\/85 {
  stroke: rgb(100 116 139 / 0.85) !important;
}

.tw-stroke-slate-500\/90 {
  stroke: rgb(100 116 139 / 0.9) !important;
}

.tw-stroke-slate-500\/95 {
  stroke: rgb(100 116 139 / 0.95) !important;
}

.tw-stroke-slate-600 {
  stroke: #475569 !important;
}

.tw-stroke-slate-600\/0 {
  stroke: rgb(71 85 105 / 0) !important;
}

.tw-stroke-slate-600\/10 {
  stroke: rgb(71 85 105 / 0.1) !important;
}

.tw-stroke-slate-600\/100 {
  stroke: rgb(71 85 105 / 1) !important;
}

.tw-stroke-slate-600\/15 {
  stroke: rgb(71 85 105 / 0.15) !important;
}

.tw-stroke-slate-600\/20 {
  stroke: rgb(71 85 105 / 0.2) !important;
}

.tw-stroke-slate-600\/25 {
  stroke: rgb(71 85 105 / 0.25) !important;
}

.tw-stroke-slate-600\/30 {
  stroke: rgb(71 85 105 / 0.3) !important;
}

.tw-stroke-slate-600\/35 {
  stroke: rgb(71 85 105 / 0.35) !important;
}

.tw-stroke-slate-600\/40 {
  stroke: rgb(71 85 105 / 0.4) !important;
}

.tw-stroke-slate-600\/45 {
  stroke: rgb(71 85 105 / 0.45) !important;
}

.tw-stroke-slate-600\/5 {
  stroke: rgb(71 85 105 / 0.05) !important;
}

.tw-stroke-slate-600\/50 {
  stroke: rgb(71 85 105 / 0.5) !important;
}

.tw-stroke-slate-600\/55 {
  stroke: rgb(71 85 105 / 0.55) !important;
}

.tw-stroke-slate-600\/60 {
  stroke: rgb(71 85 105 / 0.6) !important;
}

.tw-stroke-slate-600\/65 {
  stroke: rgb(71 85 105 / 0.65) !important;
}

.tw-stroke-slate-600\/70 {
  stroke: rgb(71 85 105 / 0.7) !important;
}

.tw-stroke-slate-600\/75 {
  stroke: rgb(71 85 105 / 0.75) !important;
}

.tw-stroke-slate-600\/80 {
  stroke: rgb(71 85 105 / 0.8) !important;
}

.tw-stroke-slate-600\/85 {
  stroke: rgb(71 85 105 / 0.85) !important;
}

.tw-stroke-slate-600\/90 {
  stroke: rgb(71 85 105 / 0.9) !important;
}

.tw-stroke-slate-600\/95 {
  stroke: rgb(71 85 105 / 0.95) !important;
}

.tw-stroke-slate-700 {
  stroke: #334155 !important;
}

.tw-stroke-slate-700\/0 {
  stroke: rgb(51 65 85 / 0) !important;
}

.tw-stroke-slate-700\/10 {
  stroke: rgb(51 65 85 / 0.1) !important;
}

.tw-stroke-slate-700\/100 {
  stroke: rgb(51 65 85 / 1) !important;
}

.tw-stroke-slate-700\/15 {
  stroke: rgb(51 65 85 / 0.15) !important;
}

.tw-stroke-slate-700\/20 {
  stroke: rgb(51 65 85 / 0.2) !important;
}

.tw-stroke-slate-700\/25 {
  stroke: rgb(51 65 85 / 0.25) !important;
}

.tw-stroke-slate-700\/30 {
  stroke: rgb(51 65 85 / 0.3) !important;
}

.tw-stroke-slate-700\/35 {
  stroke: rgb(51 65 85 / 0.35) !important;
}

.tw-stroke-slate-700\/40 {
  stroke: rgb(51 65 85 / 0.4) !important;
}

.tw-stroke-slate-700\/45 {
  stroke: rgb(51 65 85 / 0.45) !important;
}

.tw-stroke-slate-700\/5 {
  stroke: rgb(51 65 85 / 0.05) !important;
}

.tw-stroke-slate-700\/50 {
  stroke: rgb(51 65 85 / 0.5) !important;
}

.tw-stroke-slate-700\/55 {
  stroke: rgb(51 65 85 / 0.55) !important;
}

.tw-stroke-slate-700\/60 {
  stroke: rgb(51 65 85 / 0.6) !important;
}

.tw-stroke-slate-700\/65 {
  stroke: rgb(51 65 85 / 0.65) !important;
}

.tw-stroke-slate-700\/70 {
  stroke: rgb(51 65 85 / 0.7) !important;
}

.tw-stroke-slate-700\/75 {
  stroke: rgb(51 65 85 / 0.75) !important;
}

.tw-stroke-slate-700\/80 {
  stroke: rgb(51 65 85 / 0.8) !important;
}

.tw-stroke-slate-700\/85 {
  stroke: rgb(51 65 85 / 0.85) !important;
}

.tw-stroke-slate-700\/90 {
  stroke: rgb(51 65 85 / 0.9) !important;
}

.tw-stroke-slate-700\/95 {
  stroke: rgb(51 65 85 / 0.95) !important;
}

.tw-stroke-slate-800 {
  stroke: #1e293b !important;
}

.tw-stroke-slate-800\/0 {
  stroke: rgb(30 41 59 / 0) !important;
}

.tw-stroke-slate-800\/10 {
  stroke: rgb(30 41 59 / 0.1) !important;
}

.tw-stroke-slate-800\/100 {
  stroke: rgb(30 41 59 / 1) !important;
}

.tw-stroke-slate-800\/15 {
  stroke: rgb(30 41 59 / 0.15) !important;
}

.tw-stroke-slate-800\/20 {
  stroke: rgb(30 41 59 / 0.2) !important;
}

.tw-stroke-slate-800\/25 {
  stroke: rgb(30 41 59 / 0.25) !important;
}

.tw-stroke-slate-800\/30 {
  stroke: rgb(30 41 59 / 0.3) !important;
}

.tw-stroke-slate-800\/35 {
  stroke: rgb(30 41 59 / 0.35) !important;
}

.tw-stroke-slate-800\/40 {
  stroke: rgb(30 41 59 / 0.4) !important;
}

.tw-stroke-slate-800\/45 {
  stroke: rgb(30 41 59 / 0.45) !important;
}

.tw-stroke-slate-800\/5 {
  stroke: rgb(30 41 59 / 0.05) !important;
}

.tw-stroke-slate-800\/50 {
  stroke: rgb(30 41 59 / 0.5) !important;
}

.tw-stroke-slate-800\/55 {
  stroke: rgb(30 41 59 / 0.55) !important;
}

.tw-stroke-slate-800\/60 {
  stroke: rgb(30 41 59 / 0.6) !important;
}

.tw-stroke-slate-800\/65 {
  stroke: rgb(30 41 59 / 0.65) !important;
}

.tw-stroke-slate-800\/70 {
  stroke: rgb(30 41 59 / 0.7) !important;
}

.tw-stroke-slate-800\/75 {
  stroke: rgb(30 41 59 / 0.75) !important;
}

.tw-stroke-slate-800\/80 {
  stroke: rgb(30 41 59 / 0.8) !important;
}

.tw-stroke-slate-800\/85 {
  stroke: rgb(30 41 59 / 0.85) !important;
}

.tw-stroke-slate-800\/90 {
  stroke: rgb(30 41 59 / 0.9) !important;
}

.tw-stroke-slate-800\/95 {
  stroke: rgb(30 41 59 / 0.95) !important;
}

.tw-stroke-slate-900 {
  stroke: #0f172a !important;
}

.tw-stroke-slate-900\/0 {
  stroke: rgb(15 23 42 / 0) !important;
}

.tw-stroke-slate-900\/10 {
  stroke: rgb(15 23 42 / 0.1) !important;
}

.tw-stroke-slate-900\/100 {
  stroke: rgb(15 23 42 / 1) !important;
}

.tw-stroke-slate-900\/15 {
  stroke: rgb(15 23 42 / 0.15) !important;
}

.tw-stroke-slate-900\/20 {
  stroke: rgb(15 23 42 / 0.2) !important;
}

.tw-stroke-slate-900\/25 {
  stroke: rgb(15 23 42 / 0.25) !important;
}

.tw-stroke-slate-900\/30 {
  stroke: rgb(15 23 42 / 0.3) !important;
}

.tw-stroke-slate-900\/35 {
  stroke: rgb(15 23 42 / 0.35) !important;
}

.tw-stroke-slate-900\/40 {
  stroke: rgb(15 23 42 / 0.4) !important;
}

.tw-stroke-slate-900\/45 {
  stroke: rgb(15 23 42 / 0.45) !important;
}

.tw-stroke-slate-900\/5 {
  stroke: rgb(15 23 42 / 0.05) !important;
}

.tw-stroke-slate-900\/50 {
  stroke: rgb(15 23 42 / 0.5) !important;
}

.tw-stroke-slate-900\/55 {
  stroke: rgb(15 23 42 / 0.55) !important;
}

.tw-stroke-slate-900\/60 {
  stroke: rgb(15 23 42 / 0.6) !important;
}

.tw-stroke-slate-900\/65 {
  stroke: rgb(15 23 42 / 0.65) !important;
}

.tw-stroke-slate-900\/70 {
  stroke: rgb(15 23 42 / 0.7) !important;
}

.tw-stroke-slate-900\/75 {
  stroke: rgb(15 23 42 / 0.75) !important;
}

.tw-stroke-slate-900\/80 {
  stroke: rgb(15 23 42 / 0.8) !important;
}

.tw-stroke-slate-900\/85 {
  stroke: rgb(15 23 42 / 0.85) !important;
}

.tw-stroke-slate-900\/90 {
  stroke: rgb(15 23 42 / 0.9) !important;
}

.tw-stroke-slate-900\/95 {
  stroke: rgb(15 23 42 / 0.95) !important;
}

.tw-stroke-slate-950 {
  stroke: #020617 !important;
}

.tw-stroke-slate-950\/0 {
  stroke: rgb(2 6 23 / 0) !important;
}

.tw-stroke-slate-950\/10 {
  stroke: rgb(2 6 23 / 0.1) !important;
}

.tw-stroke-slate-950\/100 {
  stroke: rgb(2 6 23 / 1) !important;
}

.tw-stroke-slate-950\/15 {
  stroke: rgb(2 6 23 / 0.15) !important;
}

.tw-stroke-slate-950\/20 {
  stroke: rgb(2 6 23 / 0.2) !important;
}

.tw-stroke-slate-950\/25 {
  stroke: rgb(2 6 23 / 0.25) !important;
}

.tw-stroke-slate-950\/30 {
  stroke: rgb(2 6 23 / 0.3) !important;
}

.tw-stroke-slate-950\/35 {
  stroke: rgb(2 6 23 / 0.35) !important;
}

.tw-stroke-slate-950\/40 {
  stroke: rgb(2 6 23 / 0.4) !important;
}

.tw-stroke-slate-950\/45 {
  stroke: rgb(2 6 23 / 0.45) !important;
}

.tw-stroke-slate-950\/5 {
  stroke: rgb(2 6 23 / 0.05) !important;
}

.tw-stroke-slate-950\/50 {
  stroke: rgb(2 6 23 / 0.5) !important;
}

.tw-stroke-slate-950\/55 {
  stroke: rgb(2 6 23 / 0.55) !important;
}

.tw-stroke-slate-950\/60 {
  stroke: rgb(2 6 23 / 0.6) !important;
}

.tw-stroke-slate-950\/65 {
  stroke: rgb(2 6 23 / 0.65) !important;
}

.tw-stroke-slate-950\/70 {
  stroke: rgb(2 6 23 / 0.7) !important;
}

.tw-stroke-slate-950\/75 {
  stroke: rgb(2 6 23 / 0.75) !important;
}

.tw-stroke-slate-950\/80 {
  stroke: rgb(2 6 23 / 0.8) !important;
}

.tw-stroke-slate-950\/85 {
  stroke: rgb(2 6 23 / 0.85) !important;
}

.tw-stroke-slate-950\/90 {
  stroke: rgb(2 6 23 / 0.9) !important;
}

.tw-stroke-slate-950\/95 {
  stroke: rgb(2 6 23 / 0.95) !important;
}

.tw-stroke-stone-100 {
  stroke: #f5f5f4 !important;
}

.tw-stroke-stone-100\/0 {
  stroke: rgb(245 245 244 / 0) !important;
}

.tw-stroke-stone-100\/10 {
  stroke: rgb(245 245 244 / 0.1) !important;
}

.tw-stroke-stone-100\/100 {
  stroke: rgb(245 245 244 / 1) !important;
}

.tw-stroke-stone-100\/15 {
  stroke: rgb(245 245 244 / 0.15) !important;
}

.tw-stroke-stone-100\/20 {
  stroke: rgb(245 245 244 / 0.2) !important;
}

.tw-stroke-stone-100\/25 {
  stroke: rgb(245 245 244 / 0.25) !important;
}

.tw-stroke-stone-100\/30 {
  stroke: rgb(245 245 244 / 0.3) !important;
}

.tw-stroke-stone-100\/35 {
  stroke: rgb(245 245 244 / 0.35) !important;
}

.tw-stroke-stone-100\/40 {
  stroke: rgb(245 245 244 / 0.4) !important;
}

.tw-stroke-stone-100\/45 {
  stroke: rgb(245 245 244 / 0.45) !important;
}

.tw-stroke-stone-100\/5 {
  stroke: rgb(245 245 244 / 0.05) !important;
}

.tw-stroke-stone-100\/50 {
  stroke: rgb(245 245 244 / 0.5) !important;
}

.tw-stroke-stone-100\/55 {
  stroke: rgb(245 245 244 / 0.55) !important;
}

.tw-stroke-stone-100\/60 {
  stroke: rgb(245 245 244 / 0.6) !important;
}

.tw-stroke-stone-100\/65 {
  stroke: rgb(245 245 244 / 0.65) !important;
}

.tw-stroke-stone-100\/70 {
  stroke: rgb(245 245 244 / 0.7) !important;
}

.tw-stroke-stone-100\/75 {
  stroke: rgb(245 245 244 / 0.75) !important;
}

.tw-stroke-stone-100\/80 {
  stroke: rgb(245 245 244 / 0.8) !important;
}

.tw-stroke-stone-100\/85 {
  stroke: rgb(245 245 244 / 0.85) !important;
}

.tw-stroke-stone-100\/90 {
  stroke: rgb(245 245 244 / 0.9) !important;
}

.tw-stroke-stone-100\/95 {
  stroke: rgb(245 245 244 / 0.95) !important;
}

.tw-stroke-stone-200 {
  stroke: #e7e5e4 !important;
}

.tw-stroke-stone-200\/0 {
  stroke: rgb(231 229 228 / 0) !important;
}

.tw-stroke-stone-200\/10 {
  stroke: rgb(231 229 228 / 0.1) !important;
}

.tw-stroke-stone-200\/100 {
  stroke: rgb(231 229 228 / 1) !important;
}

.tw-stroke-stone-200\/15 {
  stroke: rgb(231 229 228 / 0.15) !important;
}

.tw-stroke-stone-200\/20 {
  stroke: rgb(231 229 228 / 0.2) !important;
}

.tw-stroke-stone-200\/25 {
  stroke: rgb(231 229 228 / 0.25) !important;
}

.tw-stroke-stone-200\/30 {
  stroke: rgb(231 229 228 / 0.3) !important;
}

.tw-stroke-stone-200\/35 {
  stroke: rgb(231 229 228 / 0.35) !important;
}

.tw-stroke-stone-200\/40 {
  stroke: rgb(231 229 228 / 0.4) !important;
}

.tw-stroke-stone-200\/45 {
  stroke: rgb(231 229 228 / 0.45) !important;
}

.tw-stroke-stone-200\/5 {
  stroke: rgb(231 229 228 / 0.05) !important;
}

.tw-stroke-stone-200\/50 {
  stroke: rgb(231 229 228 / 0.5) !important;
}

.tw-stroke-stone-200\/55 {
  stroke: rgb(231 229 228 / 0.55) !important;
}

.tw-stroke-stone-200\/60 {
  stroke: rgb(231 229 228 / 0.6) !important;
}

.tw-stroke-stone-200\/65 {
  stroke: rgb(231 229 228 / 0.65) !important;
}

.tw-stroke-stone-200\/70 {
  stroke: rgb(231 229 228 / 0.7) !important;
}

.tw-stroke-stone-200\/75 {
  stroke: rgb(231 229 228 / 0.75) !important;
}

.tw-stroke-stone-200\/80 {
  stroke: rgb(231 229 228 / 0.8) !important;
}

.tw-stroke-stone-200\/85 {
  stroke: rgb(231 229 228 / 0.85) !important;
}

.tw-stroke-stone-200\/90 {
  stroke: rgb(231 229 228 / 0.9) !important;
}

.tw-stroke-stone-200\/95 {
  stroke: rgb(231 229 228 / 0.95) !important;
}

.tw-stroke-stone-300 {
  stroke: #d6d3d1 !important;
}

.tw-stroke-stone-300\/0 {
  stroke: rgb(214 211 209 / 0) !important;
}

.tw-stroke-stone-300\/10 {
  stroke: rgb(214 211 209 / 0.1) !important;
}

.tw-stroke-stone-300\/100 {
  stroke: rgb(214 211 209 / 1) !important;
}

.tw-stroke-stone-300\/15 {
  stroke: rgb(214 211 209 / 0.15) !important;
}

.tw-stroke-stone-300\/20 {
  stroke: rgb(214 211 209 / 0.2) !important;
}

.tw-stroke-stone-300\/25 {
  stroke: rgb(214 211 209 / 0.25) !important;
}

.tw-stroke-stone-300\/30 {
  stroke: rgb(214 211 209 / 0.3) !important;
}

.tw-stroke-stone-300\/35 {
  stroke: rgb(214 211 209 / 0.35) !important;
}

.tw-stroke-stone-300\/40 {
  stroke: rgb(214 211 209 / 0.4) !important;
}

.tw-stroke-stone-300\/45 {
  stroke: rgb(214 211 209 / 0.45) !important;
}

.tw-stroke-stone-300\/5 {
  stroke: rgb(214 211 209 / 0.05) !important;
}

.tw-stroke-stone-300\/50 {
  stroke: rgb(214 211 209 / 0.5) !important;
}

.tw-stroke-stone-300\/55 {
  stroke: rgb(214 211 209 / 0.55) !important;
}

.tw-stroke-stone-300\/60 {
  stroke: rgb(214 211 209 / 0.6) !important;
}

.tw-stroke-stone-300\/65 {
  stroke: rgb(214 211 209 / 0.65) !important;
}

.tw-stroke-stone-300\/70 {
  stroke: rgb(214 211 209 / 0.7) !important;
}

.tw-stroke-stone-300\/75 {
  stroke: rgb(214 211 209 / 0.75) !important;
}

.tw-stroke-stone-300\/80 {
  stroke: rgb(214 211 209 / 0.8) !important;
}

.tw-stroke-stone-300\/85 {
  stroke: rgb(214 211 209 / 0.85) !important;
}

.tw-stroke-stone-300\/90 {
  stroke: rgb(214 211 209 / 0.9) !important;
}

.tw-stroke-stone-300\/95 {
  stroke: rgb(214 211 209 / 0.95) !important;
}

.tw-stroke-stone-400 {
  stroke: #a8a29e !important;
}

.tw-stroke-stone-400\/0 {
  stroke: rgb(168 162 158 / 0) !important;
}

.tw-stroke-stone-400\/10 {
  stroke: rgb(168 162 158 / 0.1) !important;
}

.tw-stroke-stone-400\/100 {
  stroke: rgb(168 162 158 / 1) !important;
}

.tw-stroke-stone-400\/15 {
  stroke: rgb(168 162 158 / 0.15) !important;
}

.tw-stroke-stone-400\/20 {
  stroke: rgb(168 162 158 / 0.2) !important;
}

.tw-stroke-stone-400\/25 {
  stroke: rgb(168 162 158 / 0.25) !important;
}

.tw-stroke-stone-400\/30 {
  stroke: rgb(168 162 158 / 0.3) !important;
}

.tw-stroke-stone-400\/35 {
  stroke: rgb(168 162 158 / 0.35) !important;
}

.tw-stroke-stone-400\/40 {
  stroke: rgb(168 162 158 / 0.4) !important;
}

.tw-stroke-stone-400\/45 {
  stroke: rgb(168 162 158 / 0.45) !important;
}

.tw-stroke-stone-400\/5 {
  stroke: rgb(168 162 158 / 0.05) !important;
}

.tw-stroke-stone-400\/50 {
  stroke: rgb(168 162 158 / 0.5) !important;
}

.tw-stroke-stone-400\/55 {
  stroke: rgb(168 162 158 / 0.55) !important;
}

.tw-stroke-stone-400\/60 {
  stroke: rgb(168 162 158 / 0.6) !important;
}

.tw-stroke-stone-400\/65 {
  stroke: rgb(168 162 158 / 0.65) !important;
}

.tw-stroke-stone-400\/70 {
  stroke: rgb(168 162 158 / 0.7) !important;
}

.tw-stroke-stone-400\/75 {
  stroke: rgb(168 162 158 / 0.75) !important;
}

.tw-stroke-stone-400\/80 {
  stroke: rgb(168 162 158 / 0.8) !important;
}

.tw-stroke-stone-400\/85 {
  stroke: rgb(168 162 158 / 0.85) !important;
}

.tw-stroke-stone-400\/90 {
  stroke: rgb(168 162 158 / 0.9) !important;
}

.tw-stroke-stone-400\/95 {
  stroke: rgb(168 162 158 / 0.95) !important;
}

.tw-stroke-stone-50 {
  stroke: #fafaf9 !important;
}

.tw-stroke-stone-50\/0 {
  stroke: rgb(250 250 249 / 0) !important;
}

.tw-stroke-stone-50\/10 {
  stroke: rgb(250 250 249 / 0.1) !important;
}

.tw-stroke-stone-50\/100 {
  stroke: rgb(250 250 249 / 1) !important;
}

.tw-stroke-stone-50\/15 {
  stroke: rgb(250 250 249 / 0.15) !important;
}

.tw-stroke-stone-50\/20 {
  stroke: rgb(250 250 249 / 0.2) !important;
}

.tw-stroke-stone-50\/25 {
  stroke: rgb(250 250 249 / 0.25) !important;
}

.tw-stroke-stone-50\/30 {
  stroke: rgb(250 250 249 / 0.3) !important;
}

.tw-stroke-stone-50\/35 {
  stroke: rgb(250 250 249 / 0.35) !important;
}

.tw-stroke-stone-50\/40 {
  stroke: rgb(250 250 249 / 0.4) !important;
}

.tw-stroke-stone-50\/45 {
  stroke: rgb(250 250 249 / 0.45) !important;
}

.tw-stroke-stone-50\/5 {
  stroke: rgb(250 250 249 / 0.05) !important;
}

.tw-stroke-stone-50\/50 {
  stroke: rgb(250 250 249 / 0.5) !important;
}

.tw-stroke-stone-50\/55 {
  stroke: rgb(250 250 249 / 0.55) !important;
}

.tw-stroke-stone-50\/60 {
  stroke: rgb(250 250 249 / 0.6) !important;
}

.tw-stroke-stone-50\/65 {
  stroke: rgb(250 250 249 / 0.65) !important;
}

.tw-stroke-stone-50\/70 {
  stroke: rgb(250 250 249 / 0.7) !important;
}

.tw-stroke-stone-50\/75 {
  stroke: rgb(250 250 249 / 0.75) !important;
}

.tw-stroke-stone-50\/80 {
  stroke: rgb(250 250 249 / 0.8) !important;
}

.tw-stroke-stone-50\/85 {
  stroke: rgb(250 250 249 / 0.85) !important;
}

.tw-stroke-stone-50\/90 {
  stroke: rgb(250 250 249 / 0.9) !important;
}

.tw-stroke-stone-50\/95 {
  stroke: rgb(250 250 249 / 0.95) !important;
}

.tw-stroke-stone-500 {
  stroke: #78716c !important;
}

.tw-stroke-stone-500\/0 {
  stroke: rgb(120 113 108 / 0) !important;
}

.tw-stroke-stone-500\/10 {
  stroke: rgb(120 113 108 / 0.1) !important;
}

.tw-stroke-stone-500\/100 {
  stroke: rgb(120 113 108 / 1) !important;
}

.tw-stroke-stone-500\/15 {
  stroke: rgb(120 113 108 / 0.15) !important;
}

.tw-stroke-stone-500\/20 {
  stroke: rgb(120 113 108 / 0.2) !important;
}

.tw-stroke-stone-500\/25 {
  stroke: rgb(120 113 108 / 0.25) !important;
}

.tw-stroke-stone-500\/30 {
  stroke: rgb(120 113 108 / 0.3) !important;
}

.tw-stroke-stone-500\/35 {
  stroke: rgb(120 113 108 / 0.35) !important;
}

.tw-stroke-stone-500\/40 {
  stroke: rgb(120 113 108 / 0.4) !important;
}

.tw-stroke-stone-500\/45 {
  stroke: rgb(120 113 108 / 0.45) !important;
}

.tw-stroke-stone-500\/5 {
  stroke: rgb(120 113 108 / 0.05) !important;
}

.tw-stroke-stone-500\/50 {
  stroke: rgb(120 113 108 / 0.5) !important;
}

.tw-stroke-stone-500\/55 {
  stroke: rgb(120 113 108 / 0.55) !important;
}

.tw-stroke-stone-500\/60 {
  stroke: rgb(120 113 108 / 0.6) !important;
}

.tw-stroke-stone-500\/65 {
  stroke: rgb(120 113 108 / 0.65) !important;
}

.tw-stroke-stone-500\/70 {
  stroke: rgb(120 113 108 / 0.7) !important;
}

.tw-stroke-stone-500\/75 {
  stroke: rgb(120 113 108 / 0.75) !important;
}

.tw-stroke-stone-500\/80 {
  stroke: rgb(120 113 108 / 0.8) !important;
}

.tw-stroke-stone-500\/85 {
  stroke: rgb(120 113 108 / 0.85) !important;
}

.tw-stroke-stone-500\/90 {
  stroke: rgb(120 113 108 / 0.9) !important;
}

.tw-stroke-stone-500\/95 {
  stroke: rgb(120 113 108 / 0.95) !important;
}

.tw-stroke-stone-600 {
  stroke: #57534e !important;
}

.tw-stroke-stone-600\/0 {
  stroke: rgb(87 83 78 / 0) !important;
}

.tw-stroke-stone-600\/10 {
  stroke: rgb(87 83 78 / 0.1) !important;
}

.tw-stroke-stone-600\/100 {
  stroke: rgb(87 83 78 / 1) !important;
}

.tw-stroke-stone-600\/15 {
  stroke: rgb(87 83 78 / 0.15) !important;
}

.tw-stroke-stone-600\/20 {
  stroke: rgb(87 83 78 / 0.2) !important;
}

.tw-stroke-stone-600\/25 {
  stroke: rgb(87 83 78 / 0.25) !important;
}

.tw-stroke-stone-600\/30 {
  stroke: rgb(87 83 78 / 0.3) !important;
}

.tw-stroke-stone-600\/35 {
  stroke: rgb(87 83 78 / 0.35) !important;
}

.tw-stroke-stone-600\/40 {
  stroke: rgb(87 83 78 / 0.4) !important;
}

.tw-stroke-stone-600\/45 {
  stroke: rgb(87 83 78 / 0.45) !important;
}

.tw-stroke-stone-600\/5 {
  stroke: rgb(87 83 78 / 0.05) !important;
}

.tw-stroke-stone-600\/50 {
  stroke: rgb(87 83 78 / 0.5) !important;
}

.tw-stroke-stone-600\/55 {
  stroke: rgb(87 83 78 / 0.55) !important;
}

.tw-stroke-stone-600\/60 {
  stroke: rgb(87 83 78 / 0.6) !important;
}

.tw-stroke-stone-600\/65 {
  stroke: rgb(87 83 78 / 0.65) !important;
}

.tw-stroke-stone-600\/70 {
  stroke: rgb(87 83 78 / 0.7) !important;
}

.tw-stroke-stone-600\/75 {
  stroke: rgb(87 83 78 / 0.75) !important;
}

.tw-stroke-stone-600\/80 {
  stroke: rgb(87 83 78 / 0.8) !important;
}

.tw-stroke-stone-600\/85 {
  stroke: rgb(87 83 78 / 0.85) !important;
}

.tw-stroke-stone-600\/90 {
  stroke: rgb(87 83 78 / 0.9) !important;
}

.tw-stroke-stone-600\/95 {
  stroke: rgb(87 83 78 / 0.95) !important;
}

.tw-stroke-stone-700 {
  stroke: #44403c !important;
}

.tw-stroke-stone-700\/0 {
  stroke: rgb(68 64 60 / 0) !important;
}

.tw-stroke-stone-700\/10 {
  stroke: rgb(68 64 60 / 0.1) !important;
}

.tw-stroke-stone-700\/100 {
  stroke: rgb(68 64 60 / 1) !important;
}

.tw-stroke-stone-700\/15 {
  stroke: rgb(68 64 60 / 0.15) !important;
}

.tw-stroke-stone-700\/20 {
  stroke: rgb(68 64 60 / 0.2) !important;
}

.tw-stroke-stone-700\/25 {
  stroke: rgb(68 64 60 / 0.25) !important;
}

.tw-stroke-stone-700\/30 {
  stroke: rgb(68 64 60 / 0.3) !important;
}

.tw-stroke-stone-700\/35 {
  stroke: rgb(68 64 60 / 0.35) !important;
}

.tw-stroke-stone-700\/40 {
  stroke: rgb(68 64 60 / 0.4) !important;
}

.tw-stroke-stone-700\/45 {
  stroke: rgb(68 64 60 / 0.45) !important;
}

.tw-stroke-stone-700\/5 {
  stroke: rgb(68 64 60 / 0.05) !important;
}

.tw-stroke-stone-700\/50 {
  stroke: rgb(68 64 60 / 0.5) !important;
}

.tw-stroke-stone-700\/55 {
  stroke: rgb(68 64 60 / 0.55) !important;
}

.tw-stroke-stone-700\/60 {
  stroke: rgb(68 64 60 / 0.6) !important;
}

.tw-stroke-stone-700\/65 {
  stroke: rgb(68 64 60 / 0.65) !important;
}

.tw-stroke-stone-700\/70 {
  stroke: rgb(68 64 60 / 0.7) !important;
}

.tw-stroke-stone-700\/75 {
  stroke: rgb(68 64 60 / 0.75) !important;
}

.tw-stroke-stone-700\/80 {
  stroke: rgb(68 64 60 / 0.8) !important;
}

.tw-stroke-stone-700\/85 {
  stroke: rgb(68 64 60 / 0.85) !important;
}

.tw-stroke-stone-700\/90 {
  stroke: rgb(68 64 60 / 0.9) !important;
}

.tw-stroke-stone-700\/95 {
  stroke: rgb(68 64 60 / 0.95) !important;
}

.tw-stroke-stone-800 {
  stroke: #292524 !important;
}

.tw-stroke-stone-800\/0 {
  stroke: rgb(41 37 36 / 0) !important;
}

.tw-stroke-stone-800\/10 {
  stroke: rgb(41 37 36 / 0.1) !important;
}

.tw-stroke-stone-800\/100 {
  stroke: rgb(41 37 36 / 1) !important;
}

.tw-stroke-stone-800\/15 {
  stroke: rgb(41 37 36 / 0.15) !important;
}

.tw-stroke-stone-800\/20 {
  stroke: rgb(41 37 36 / 0.2) !important;
}

.tw-stroke-stone-800\/25 {
  stroke: rgb(41 37 36 / 0.25) !important;
}

.tw-stroke-stone-800\/30 {
  stroke: rgb(41 37 36 / 0.3) !important;
}

.tw-stroke-stone-800\/35 {
  stroke: rgb(41 37 36 / 0.35) !important;
}

.tw-stroke-stone-800\/40 {
  stroke: rgb(41 37 36 / 0.4) !important;
}

.tw-stroke-stone-800\/45 {
  stroke: rgb(41 37 36 / 0.45) !important;
}

.tw-stroke-stone-800\/5 {
  stroke: rgb(41 37 36 / 0.05) !important;
}

.tw-stroke-stone-800\/50 {
  stroke: rgb(41 37 36 / 0.5) !important;
}

.tw-stroke-stone-800\/55 {
  stroke: rgb(41 37 36 / 0.55) !important;
}

.tw-stroke-stone-800\/60 {
  stroke: rgb(41 37 36 / 0.6) !important;
}

.tw-stroke-stone-800\/65 {
  stroke: rgb(41 37 36 / 0.65) !important;
}

.tw-stroke-stone-800\/70 {
  stroke: rgb(41 37 36 / 0.7) !important;
}

.tw-stroke-stone-800\/75 {
  stroke: rgb(41 37 36 / 0.75) !important;
}

.tw-stroke-stone-800\/80 {
  stroke: rgb(41 37 36 / 0.8) !important;
}

.tw-stroke-stone-800\/85 {
  stroke: rgb(41 37 36 / 0.85) !important;
}

.tw-stroke-stone-800\/90 {
  stroke: rgb(41 37 36 / 0.9) !important;
}

.tw-stroke-stone-800\/95 {
  stroke: rgb(41 37 36 / 0.95) !important;
}

.tw-stroke-stone-900 {
  stroke: #1c1917 !important;
}

.tw-stroke-stone-900\/0 {
  stroke: rgb(28 25 23 / 0) !important;
}

.tw-stroke-stone-900\/10 {
  stroke: rgb(28 25 23 / 0.1) !important;
}

.tw-stroke-stone-900\/100 {
  stroke: rgb(28 25 23 / 1) !important;
}

.tw-stroke-stone-900\/15 {
  stroke: rgb(28 25 23 / 0.15) !important;
}

.tw-stroke-stone-900\/20 {
  stroke: rgb(28 25 23 / 0.2) !important;
}

.tw-stroke-stone-900\/25 {
  stroke: rgb(28 25 23 / 0.25) !important;
}

.tw-stroke-stone-900\/30 {
  stroke: rgb(28 25 23 / 0.3) !important;
}

.tw-stroke-stone-900\/35 {
  stroke: rgb(28 25 23 / 0.35) !important;
}

.tw-stroke-stone-900\/40 {
  stroke: rgb(28 25 23 / 0.4) !important;
}

.tw-stroke-stone-900\/45 {
  stroke: rgb(28 25 23 / 0.45) !important;
}

.tw-stroke-stone-900\/5 {
  stroke: rgb(28 25 23 / 0.05) !important;
}

.tw-stroke-stone-900\/50 {
  stroke: rgb(28 25 23 / 0.5) !important;
}

.tw-stroke-stone-900\/55 {
  stroke: rgb(28 25 23 / 0.55) !important;
}

.tw-stroke-stone-900\/60 {
  stroke: rgb(28 25 23 / 0.6) !important;
}

.tw-stroke-stone-900\/65 {
  stroke: rgb(28 25 23 / 0.65) !important;
}

.tw-stroke-stone-900\/70 {
  stroke: rgb(28 25 23 / 0.7) !important;
}

.tw-stroke-stone-900\/75 {
  stroke: rgb(28 25 23 / 0.75) !important;
}

.tw-stroke-stone-900\/80 {
  stroke: rgb(28 25 23 / 0.8) !important;
}

.tw-stroke-stone-900\/85 {
  stroke: rgb(28 25 23 / 0.85) !important;
}

.tw-stroke-stone-900\/90 {
  stroke: rgb(28 25 23 / 0.9) !important;
}

.tw-stroke-stone-900\/95 {
  stroke: rgb(28 25 23 / 0.95) !important;
}

.tw-stroke-stone-950 {
  stroke: #0c0a09 !important;
}

.tw-stroke-stone-950\/0 {
  stroke: rgb(12 10 9 / 0) !important;
}

.tw-stroke-stone-950\/10 {
  stroke: rgb(12 10 9 / 0.1) !important;
}

.tw-stroke-stone-950\/100 {
  stroke: rgb(12 10 9 / 1) !important;
}

.tw-stroke-stone-950\/15 {
  stroke: rgb(12 10 9 / 0.15) !important;
}

.tw-stroke-stone-950\/20 {
  stroke: rgb(12 10 9 / 0.2) !important;
}

.tw-stroke-stone-950\/25 {
  stroke: rgb(12 10 9 / 0.25) !important;
}

.tw-stroke-stone-950\/30 {
  stroke: rgb(12 10 9 / 0.3) !important;
}

.tw-stroke-stone-950\/35 {
  stroke: rgb(12 10 9 / 0.35) !important;
}

.tw-stroke-stone-950\/40 {
  stroke: rgb(12 10 9 / 0.4) !important;
}

.tw-stroke-stone-950\/45 {
  stroke: rgb(12 10 9 / 0.45) !important;
}

.tw-stroke-stone-950\/5 {
  stroke: rgb(12 10 9 / 0.05) !important;
}

.tw-stroke-stone-950\/50 {
  stroke: rgb(12 10 9 / 0.5) !important;
}

.tw-stroke-stone-950\/55 {
  stroke: rgb(12 10 9 / 0.55) !important;
}

.tw-stroke-stone-950\/60 {
  stroke: rgb(12 10 9 / 0.6) !important;
}

.tw-stroke-stone-950\/65 {
  stroke: rgb(12 10 9 / 0.65) !important;
}

.tw-stroke-stone-950\/70 {
  stroke: rgb(12 10 9 / 0.7) !important;
}

.tw-stroke-stone-950\/75 {
  stroke: rgb(12 10 9 / 0.75) !important;
}

.tw-stroke-stone-950\/80 {
  stroke: rgb(12 10 9 / 0.8) !important;
}

.tw-stroke-stone-950\/85 {
  stroke: rgb(12 10 9 / 0.85) !important;
}

.tw-stroke-stone-950\/90 {
  stroke: rgb(12 10 9 / 0.9) !important;
}

.tw-stroke-stone-950\/95 {
  stroke: rgb(12 10 9 / 0.95) !important;
}

.tw-stroke-teal-100 {
  stroke: #ccfbf1 !important;
}

.tw-stroke-teal-100\/0 {
  stroke: rgb(204 251 241 / 0) !important;
}

.tw-stroke-teal-100\/10 {
  stroke: rgb(204 251 241 / 0.1) !important;
}

.tw-stroke-teal-100\/100 {
  stroke: rgb(204 251 241 / 1) !important;
}

.tw-stroke-teal-100\/15 {
  stroke: rgb(204 251 241 / 0.15) !important;
}

.tw-stroke-teal-100\/20 {
  stroke: rgb(204 251 241 / 0.2) !important;
}

.tw-stroke-teal-100\/25 {
  stroke: rgb(204 251 241 / 0.25) !important;
}

.tw-stroke-teal-100\/30 {
  stroke: rgb(204 251 241 / 0.3) !important;
}

.tw-stroke-teal-100\/35 {
  stroke: rgb(204 251 241 / 0.35) !important;
}

.tw-stroke-teal-100\/40 {
  stroke: rgb(204 251 241 / 0.4) !important;
}

.tw-stroke-teal-100\/45 {
  stroke: rgb(204 251 241 / 0.45) !important;
}

.tw-stroke-teal-100\/5 {
  stroke: rgb(204 251 241 / 0.05) !important;
}

.tw-stroke-teal-100\/50 {
  stroke: rgb(204 251 241 / 0.5) !important;
}

.tw-stroke-teal-100\/55 {
  stroke: rgb(204 251 241 / 0.55) !important;
}

.tw-stroke-teal-100\/60 {
  stroke: rgb(204 251 241 / 0.6) !important;
}

.tw-stroke-teal-100\/65 {
  stroke: rgb(204 251 241 / 0.65) !important;
}

.tw-stroke-teal-100\/70 {
  stroke: rgb(204 251 241 / 0.7) !important;
}

.tw-stroke-teal-100\/75 {
  stroke: rgb(204 251 241 / 0.75) !important;
}

.tw-stroke-teal-100\/80 {
  stroke: rgb(204 251 241 / 0.8) !important;
}

.tw-stroke-teal-100\/85 {
  stroke: rgb(204 251 241 / 0.85) !important;
}

.tw-stroke-teal-100\/90 {
  stroke: rgb(204 251 241 / 0.9) !important;
}

.tw-stroke-teal-100\/95 {
  stroke: rgb(204 251 241 / 0.95) !important;
}

.tw-stroke-teal-200 {
  stroke: #99f6e4 !important;
}

.tw-stroke-teal-200\/0 {
  stroke: rgb(153 246 228 / 0) !important;
}

.tw-stroke-teal-200\/10 {
  stroke: rgb(153 246 228 / 0.1) !important;
}

.tw-stroke-teal-200\/100 {
  stroke: rgb(153 246 228 / 1) !important;
}

.tw-stroke-teal-200\/15 {
  stroke: rgb(153 246 228 / 0.15) !important;
}

.tw-stroke-teal-200\/20 {
  stroke: rgb(153 246 228 / 0.2) !important;
}

.tw-stroke-teal-200\/25 {
  stroke: rgb(153 246 228 / 0.25) !important;
}

.tw-stroke-teal-200\/30 {
  stroke: rgb(153 246 228 / 0.3) !important;
}

.tw-stroke-teal-200\/35 {
  stroke: rgb(153 246 228 / 0.35) !important;
}

.tw-stroke-teal-200\/40 {
  stroke: rgb(153 246 228 / 0.4) !important;
}

.tw-stroke-teal-200\/45 {
  stroke: rgb(153 246 228 / 0.45) !important;
}

.tw-stroke-teal-200\/5 {
  stroke: rgb(153 246 228 / 0.05) !important;
}

.tw-stroke-teal-200\/50 {
  stroke: rgb(153 246 228 / 0.5) !important;
}

.tw-stroke-teal-200\/55 {
  stroke: rgb(153 246 228 / 0.55) !important;
}

.tw-stroke-teal-200\/60 {
  stroke: rgb(153 246 228 / 0.6) !important;
}

.tw-stroke-teal-200\/65 {
  stroke: rgb(153 246 228 / 0.65) !important;
}

.tw-stroke-teal-200\/70 {
  stroke: rgb(153 246 228 / 0.7) !important;
}

.tw-stroke-teal-200\/75 {
  stroke: rgb(153 246 228 / 0.75) !important;
}

.tw-stroke-teal-200\/80 {
  stroke: rgb(153 246 228 / 0.8) !important;
}

.tw-stroke-teal-200\/85 {
  stroke: rgb(153 246 228 / 0.85) !important;
}

.tw-stroke-teal-200\/90 {
  stroke: rgb(153 246 228 / 0.9) !important;
}

.tw-stroke-teal-200\/95 {
  stroke: rgb(153 246 228 / 0.95) !important;
}

.tw-stroke-teal-300 {
  stroke: #5eead4 !important;
}

.tw-stroke-teal-300\/0 {
  stroke: rgb(94 234 212 / 0) !important;
}

.tw-stroke-teal-300\/10 {
  stroke: rgb(94 234 212 / 0.1) !important;
}

.tw-stroke-teal-300\/100 {
  stroke: rgb(94 234 212 / 1) !important;
}

.tw-stroke-teal-300\/15 {
  stroke: rgb(94 234 212 / 0.15) !important;
}

.tw-stroke-teal-300\/20 {
  stroke: rgb(94 234 212 / 0.2) !important;
}

.tw-stroke-teal-300\/25 {
  stroke: rgb(94 234 212 / 0.25) !important;
}

.tw-stroke-teal-300\/30 {
  stroke: rgb(94 234 212 / 0.3) !important;
}

.tw-stroke-teal-300\/35 {
  stroke: rgb(94 234 212 / 0.35) !important;
}

.tw-stroke-teal-300\/40 {
  stroke: rgb(94 234 212 / 0.4) !important;
}

.tw-stroke-teal-300\/45 {
  stroke: rgb(94 234 212 / 0.45) !important;
}

.tw-stroke-teal-300\/5 {
  stroke: rgb(94 234 212 / 0.05) !important;
}

.tw-stroke-teal-300\/50 {
  stroke: rgb(94 234 212 / 0.5) !important;
}

.tw-stroke-teal-300\/55 {
  stroke: rgb(94 234 212 / 0.55) !important;
}

.tw-stroke-teal-300\/60 {
  stroke: rgb(94 234 212 / 0.6) !important;
}

.tw-stroke-teal-300\/65 {
  stroke: rgb(94 234 212 / 0.65) !important;
}

.tw-stroke-teal-300\/70 {
  stroke: rgb(94 234 212 / 0.7) !important;
}

.tw-stroke-teal-300\/75 {
  stroke: rgb(94 234 212 / 0.75) !important;
}

.tw-stroke-teal-300\/80 {
  stroke: rgb(94 234 212 / 0.8) !important;
}

.tw-stroke-teal-300\/85 {
  stroke: rgb(94 234 212 / 0.85) !important;
}

.tw-stroke-teal-300\/90 {
  stroke: rgb(94 234 212 / 0.9) !important;
}

.tw-stroke-teal-300\/95 {
  stroke: rgb(94 234 212 / 0.95) !important;
}

.tw-stroke-teal-400 {
  stroke: #2dd4bf !important;
}

.tw-stroke-teal-400\/0 {
  stroke: rgb(45 212 191 / 0) !important;
}

.tw-stroke-teal-400\/10 {
  stroke: rgb(45 212 191 / 0.1) !important;
}

.tw-stroke-teal-400\/100 {
  stroke: rgb(45 212 191 / 1) !important;
}

.tw-stroke-teal-400\/15 {
  stroke: rgb(45 212 191 / 0.15) !important;
}

.tw-stroke-teal-400\/20 {
  stroke: rgb(45 212 191 / 0.2) !important;
}

.tw-stroke-teal-400\/25 {
  stroke: rgb(45 212 191 / 0.25) !important;
}

.tw-stroke-teal-400\/30 {
  stroke: rgb(45 212 191 / 0.3) !important;
}

.tw-stroke-teal-400\/35 {
  stroke: rgb(45 212 191 / 0.35) !important;
}

.tw-stroke-teal-400\/40 {
  stroke: rgb(45 212 191 / 0.4) !important;
}

.tw-stroke-teal-400\/45 {
  stroke: rgb(45 212 191 / 0.45) !important;
}

.tw-stroke-teal-400\/5 {
  stroke: rgb(45 212 191 / 0.05) !important;
}

.tw-stroke-teal-400\/50 {
  stroke: rgb(45 212 191 / 0.5) !important;
}

.tw-stroke-teal-400\/55 {
  stroke: rgb(45 212 191 / 0.55) !important;
}

.tw-stroke-teal-400\/60 {
  stroke: rgb(45 212 191 / 0.6) !important;
}

.tw-stroke-teal-400\/65 {
  stroke: rgb(45 212 191 / 0.65) !important;
}

.tw-stroke-teal-400\/70 {
  stroke: rgb(45 212 191 / 0.7) !important;
}

.tw-stroke-teal-400\/75 {
  stroke: rgb(45 212 191 / 0.75) !important;
}

.tw-stroke-teal-400\/80 {
  stroke: rgb(45 212 191 / 0.8) !important;
}

.tw-stroke-teal-400\/85 {
  stroke: rgb(45 212 191 / 0.85) !important;
}

.tw-stroke-teal-400\/90 {
  stroke: rgb(45 212 191 / 0.9) !important;
}

.tw-stroke-teal-400\/95 {
  stroke: rgb(45 212 191 / 0.95) !important;
}

.tw-stroke-teal-50 {
  stroke: #f0fdfa !important;
}

.tw-stroke-teal-50\/0 {
  stroke: rgb(240 253 250 / 0) !important;
}

.tw-stroke-teal-50\/10 {
  stroke: rgb(240 253 250 / 0.1) !important;
}

.tw-stroke-teal-50\/100 {
  stroke: rgb(240 253 250 / 1) !important;
}

.tw-stroke-teal-50\/15 {
  stroke: rgb(240 253 250 / 0.15) !important;
}

.tw-stroke-teal-50\/20 {
  stroke: rgb(240 253 250 / 0.2) !important;
}

.tw-stroke-teal-50\/25 {
  stroke: rgb(240 253 250 / 0.25) !important;
}

.tw-stroke-teal-50\/30 {
  stroke: rgb(240 253 250 / 0.3) !important;
}

.tw-stroke-teal-50\/35 {
  stroke: rgb(240 253 250 / 0.35) !important;
}

.tw-stroke-teal-50\/40 {
  stroke: rgb(240 253 250 / 0.4) !important;
}

.tw-stroke-teal-50\/45 {
  stroke: rgb(240 253 250 / 0.45) !important;
}

.tw-stroke-teal-50\/5 {
  stroke: rgb(240 253 250 / 0.05) !important;
}

.tw-stroke-teal-50\/50 {
  stroke: rgb(240 253 250 / 0.5) !important;
}

.tw-stroke-teal-50\/55 {
  stroke: rgb(240 253 250 / 0.55) !important;
}

.tw-stroke-teal-50\/60 {
  stroke: rgb(240 253 250 / 0.6) !important;
}

.tw-stroke-teal-50\/65 {
  stroke: rgb(240 253 250 / 0.65) !important;
}

.tw-stroke-teal-50\/70 {
  stroke: rgb(240 253 250 / 0.7) !important;
}

.tw-stroke-teal-50\/75 {
  stroke: rgb(240 253 250 / 0.75) !important;
}

.tw-stroke-teal-50\/80 {
  stroke: rgb(240 253 250 / 0.8) !important;
}

.tw-stroke-teal-50\/85 {
  stroke: rgb(240 253 250 / 0.85) !important;
}

.tw-stroke-teal-50\/90 {
  stroke: rgb(240 253 250 / 0.9) !important;
}

.tw-stroke-teal-50\/95 {
  stroke: rgb(240 253 250 / 0.95) !important;
}

.tw-stroke-teal-500 {
  stroke: #14b8a6 !important;
}

.tw-stroke-teal-500\/0 {
  stroke: rgb(20 184 166 / 0) !important;
}

.tw-stroke-teal-500\/10 {
  stroke: rgb(20 184 166 / 0.1) !important;
}

.tw-stroke-teal-500\/100 {
  stroke: rgb(20 184 166 / 1) !important;
}

.tw-stroke-teal-500\/15 {
  stroke: rgb(20 184 166 / 0.15) !important;
}

.tw-stroke-teal-500\/20 {
  stroke: rgb(20 184 166 / 0.2) !important;
}

.tw-stroke-teal-500\/25 {
  stroke: rgb(20 184 166 / 0.25) !important;
}

.tw-stroke-teal-500\/30 {
  stroke: rgb(20 184 166 / 0.3) !important;
}

.tw-stroke-teal-500\/35 {
  stroke: rgb(20 184 166 / 0.35) !important;
}

.tw-stroke-teal-500\/40 {
  stroke: rgb(20 184 166 / 0.4) !important;
}

.tw-stroke-teal-500\/45 {
  stroke: rgb(20 184 166 / 0.45) !important;
}

.tw-stroke-teal-500\/5 {
  stroke: rgb(20 184 166 / 0.05) !important;
}

.tw-stroke-teal-500\/50 {
  stroke: rgb(20 184 166 / 0.5) !important;
}

.tw-stroke-teal-500\/55 {
  stroke: rgb(20 184 166 / 0.55) !important;
}

.tw-stroke-teal-500\/60 {
  stroke: rgb(20 184 166 / 0.6) !important;
}

.tw-stroke-teal-500\/65 {
  stroke: rgb(20 184 166 / 0.65) !important;
}

.tw-stroke-teal-500\/70 {
  stroke: rgb(20 184 166 / 0.7) !important;
}

.tw-stroke-teal-500\/75 {
  stroke: rgb(20 184 166 / 0.75) !important;
}

.tw-stroke-teal-500\/80 {
  stroke: rgb(20 184 166 / 0.8) !important;
}

.tw-stroke-teal-500\/85 {
  stroke: rgb(20 184 166 / 0.85) !important;
}

.tw-stroke-teal-500\/90 {
  stroke: rgb(20 184 166 / 0.9) !important;
}

.tw-stroke-teal-500\/95 {
  stroke: rgb(20 184 166 / 0.95) !important;
}

.tw-stroke-teal-600 {
  stroke: #0d9488 !important;
}

.tw-stroke-teal-600\/0 {
  stroke: rgb(13 148 136 / 0) !important;
}

.tw-stroke-teal-600\/10 {
  stroke: rgb(13 148 136 / 0.1) !important;
}

.tw-stroke-teal-600\/100 {
  stroke: rgb(13 148 136 / 1) !important;
}

.tw-stroke-teal-600\/15 {
  stroke: rgb(13 148 136 / 0.15) !important;
}

.tw-stroke-teal-600\/20 {
  stroke: rgb(13 148 136 / 0.2) !important;
}

.tw-stroke-teal-600\/25 {
  stroke: rgb(13 148 136 / 0.25) !important;
}

.tw-stroke-teal-600\/30 {
  stroke: rgb(13 148 136 / 0.3) !important;
}

.tw-stroke-teal-600\/35 {
  stroke: rgb(13 148 136 / 0.35) !important;
}

.tw-stroke-teal-600\/40 {
  stroke: rgb(13 148 136 / 0.4) !important;
}

.tw-stroke-teal-600\/45 {
  stroke: rgb(13 148 136 / 0.45) !important;
}

.tw-stroke-teal-600\/5 {
  stroke: rgb(13 148 136 / 0.05) !important;
}

.tw-stroke-teal-600\/50 {
  stroke: rgb(13 148 136 / 0.5) !important;
}

.tw-stroke-teal-600\/55 {
  stroke: rgb(13 148 136 / 0.55) !important;
}

.tw-stroke-teal-600\/60 {
  stroke: rgb(13 148 136 / 0.6) !important;
}

.tw-stroke-teal-600\/65 {
  stroke: rgb(13 148 136 / 0.65) !important;
}

.tw-stroke-teal-600\/70 {
  stroke: rgb(13 148 136 / 0.7) !important;
}

.tw-stroke-teal-600\/75 {
  stroke: rgb(13 148 136 / 0.75) !important;
}

.tw-stroke-teal-600\/80 {
  stroke: rgb(13 148 136 / 0.8) !important;
}

.tw-stroke-teal-600\/85 {
  stroke: rgb(13 148 136 / 0.85) !important;
}

.tw-stroke-teal-600\/90 {
  stroke: rgb(13 148 136 / 0.9) !important;
}

.tw-stroke-teal-600\/95 {
  stroke: rgb(13 148 136 / 0.95) !important;
}

.tw-stroke-teal-700 {
  stroke: #0f766e !important;
}

.tw-stroke-teal-700\/0 {
  stroke: rgb(15 118 110 / 0) !important;
}

.tw-stroke-teal-700\/10 {
  stroke: rgb(15 118 110 / 0.1) !important;
}

.tw-stroke-teal-700\/100 {
  stroke: rgb(15 118 110 / 1) !important;
}

.tw-stroke-teal-700\/15 {
  stroke: rgb(15 118 110 / 0.15) !important;
}

.tw-stroke-teal-700\/20 {
  stroke: rgb(15 118 110 / 0.2) !important;
}

.tw-stroke-teal-700\/25 {
  stroke: rgb(15 118 110 / 0.25) !important;
}

.tw-stroke-teal-700\/30 {
  stroke: rgb(15 118 110 / 0.3) !important;
}

.tw-stroke-teal-700\/35 {
  stroke: rgb(15 118 110 / 0.35) !important;
}

.tw-stroke-teal-700\/40 {
  stroke: rgb(15 118 110 / 0.4) !important;
}

.tw-stroke-teal-700\/45 {
  stroke: rgb(15 118 110 / 0.45) !important;
}

.tw-stroke-teal-700\/5 {
  stroke: rgb(15 118 110 / 0.05) !important;
}

.tw-stroke-teal-700\/50 {
  stroke: rgb(15 118 110 / 0.5) !important;
}

.tw-stroke-teal-700\/55 {
  stroke: rgb(15 118 110 / 0.55) !important;
}

.tw-stroke-teal-700\/60 {
  stroke: rgb(15 118 110 / 0.6) !important;
}

.tw-stroke-teal-700\/65 {
  stroke: rgb(15 118 110 / 0.65) !important;
}

.tw-stroke-teal-700\/70 {
  stroke: rgb(15 118 110 / 0.7) !important;
}

.tw-stroke-teal-700\/75 {
  stroke: rgb(15 118 110 / 0.75) !important;
}

.tw-stroke-teal-700\/80 {
  stroke: rgb(15 118 110 / 0.8) !important;
}

.tw-stroke-teal-700\/85 {
  stroke: rgb(15 118 110 / 0.85) !important;
}

.tw-stroke-teal-700\/90 {
  stroke: rgb(15 118 110 / 0.9) !important;
}

.tw-stroke-teal-700\/95 {
  stroke: rgb(15 118 110 / 0.95) !important;
}

.tw-stroke-teal-800 {
  stroke: #115e59 !important;
}

.tw-stroke-teal-800\/0 {
  stroke: rgb(17 94 89 / 0) !important;
}

.tw-stroke-teal-800\/10 {
  stroke: rgb(17 94 89 / 0.1) !important;
}

.tw-stroke-teal-800\/100 {
  stroke: rgb(17 94 89 / 1) !important;
}

.tw-stroke-teal-800\/15 {
  stroke: rgb(17 94 89 / 0.15) !important;
}

.tw-stroke-teal-800\/20 {
  stroke: rgb(17 94 89 / 0.2) !important;
}

.tw-stroke-teal-800\/25 {
  stroke: rgb(17 94 89 / 0.25) !important;
}

.tw-stroke-teal-800\/30 {
  stroke: rgb(17 94 89 / 0.3) !important;
}

.tw-stroke-teal-800\/35 {
  stroke: rgb(17 94 89 / 0.35) !important;
}

.tw-stroke-teal-800\/40 {
  stroke: rgb(17 94 89 / 0.4) !important;
}

.tw-stroke-teal-800\/45 {
  stroke: rgb(17 94 89 / 0.45) !important;
}

.tw-stroke-teal-800\/5 {
  stroke: rgb(17 94 89 / 0.05) !important;
}

.tw-stroke-teal-800\/50 {
  stroke: rgb(17 94 89 / 0.5) !important;
}

.tw-stroke-teal-800\/55 {
  stroke: rgb(17 94 89 / 0.55) !important;
}

.tw-stroke-teal-800\/60 {
  stroke: rgb(17 94 89 / 0.6) !important;
}

.tw-stroke-teal-800\/65 {
  stroke: rgb(17 94 89 / 0.65) !important;
}

.tw-stroke-teal-800\/70 {
  stroke: rgb(17 94 89 / 0.7) !important;
}

.tw-stroke-teal-800\/75 {
  stroke: rgb(17 94 89 / 0.75) !important;
}

.tw-stroke-teal-800\/80 {
  stroke: rgb(17 94 89 / 0.8) !important;
}

.tw-stroke-teal-800\/85 {
  stroke: rgb(17 94 89 / 0.85) !important;
}

.tw-stroke-teal-800\/90 {
  stroke: rgb(17 94 89 / 0.9) !important;
}

.tw-stroke-teal-800\/95 {
  stroke: rgb(17 94 89 / 0.95) !important;
}

.tw-stroke-teal-900 {
  stroke: #134e4a !important;
}

.tw-stroke-teal-900\/0 {
  stroke: rgb(19 78 74 / 0) !important;
}

.tw-stroke-teal-900\/10 {
  stroke: rgb(19 78 74 / 0.1) !important;
}

.tw-stroke-teal-900\/100 {
  stroke: rgb(19 78 74 / 1) !important;
}

.tw-stroke-teal-900\/15 {
  stroke: rgb(19 78 74 / 0.15) !important;
}

.tw-stroke-teal-900\/20 {
  stroke: rgb(19 78 74 / 0.2) !important;
}

.tw-stroke-teal-900\/25 {
  stroke: rgb(19 78 74 / 0.25) !important;
}

.tw-stroke-teal-900\/30 {
  stroke: rgb(19 78 74 / 0.3) !important;
}

.tw-stroke-teal-900\/35 {
  stroke: rgb(19 78 74 / 0.35) !important;
}

.tw-stroke-teal-900\/40 {
  stroke: rgb(19 78 74 / 0.4) !important;
}

.tw-stroke-teal-900\/45 {
  stroke: rgb(19 78 74 / 0.45) !important;
}

.tw-stroke-teal-900\/5 {
  stroke: rgb(19 78 74 / 0.05) !important;
}

.tw-stroke-teal-900\/50 {
  stroke: rgb(19 78 74 / 0.5) !important;
}

.tw-stroke-teal-900\/55 {
  stroke: rgb(19 78 74 / 0.55) !important;
}

.tw-stroke-teal-900\/60 {
  stroke: rgb(19 78 74 / 0.6) !important;
}

.tw-stroke-teal-900\/65 {
  stroke: rgb(19 78 74 / 0.65) !important;
}

.tw-stroke-teal-900\/70 {
  stroke: rgb(19 78 74 / 0.7) !important;
}

.tw-stroke-teal-900\/75 {
  stroke: rgb(19 78 74 / 0.75) !important;
}

.tw-stroke-teal-900\/80 {
  stroke: rgb(19 78 74 / 0.8) !important;
}

.tw-stroke-teal-900\/85 {
  stroke: rgb(19 78 74 / 0.85) !important;
}

.tw-stroke-teal-900\/90 {
  stroke: rgb(19 78 74 / 0.9) !important;
}

.tw-stroke-teal-900\/95 {
  stroke: rgb(19 78 74 / 0.95) !important;
}

.tw-stroke-teal-950 {
  stroke: #042f2e !important;
}

.tw-stroke-teal-950\/0 {
  stroke: rgb(4 47 46 / 0) !important;
}

.tw-stroke-teal-950\/10 {
  stroke: rgb(4 47 46 / 0.1) !important;
}

.tw-stroke-teal-950\/100 {
  stroke: rgb(4 47 46 / 1) !important;
}

.tw-stroke-teal-950\/15 {
  stroke: rgb(4 47 46 / 0.15) !important;
}

.tw-stroke-teal-950\/20 {
  stroke: rgb(4 47 46 / 0.2) !important;
}

.tw-stroke-teal-950\/25 {
  stroke: rgb(4 47 46 / 0.25) !important;
}

.tw-stroke-teal-950\/30 {
  stroke: rgb(4 47 46 / 0.3) !important;
}

.tw-stroke-teal-950\/35 {
  stroke: rgb(4 47 46 / 0.35) !important;
}

.tw-stroke-teal-950\/40 {
  stroke: rgb(4 47 46 / 0.4) !important;
}

.tw-stroke-teal-950\/45 {
  stroke: rgb(4 47 46 / 0.45) !important;
}

.tw-stroke-teal-950\/5 {
  stroke: rgb(4 47 46 / 0.05) !important;
}

.tw-stroke-teal-950\/50 {
  stroke: rgb(4 47 46 / 0.5) !important;
}

.tw-stroke-teal-950\/55 {
  stroke: rgb(4 47 46 / 0.55) !important;
}

.tw-stroke-teal-950\/60 {
  stroke: rgb(4 47 46 / 0.6) !important;
}

.tw-stroke-teal-950\/65 {
  stroke: rgb(4 47 46 / 0.65) !important;
}

.tw-stroke-teal-950\/70 {
  stroke: rgb(4 47 46 / 0.7) !important;
}

.tw-stroke-teal-950\/75 {
  stroke: rgb(4 47 46 / 0.75) !important;
}

.tw-stroke-teal-950\/80 {
  stroke: rgb(4 47 46 / 0.8) !important;
}

.tw-stroke-teal-950\/85 {
  stroke: rgb(4 47 46 / 0.85) !important;
}

.tw-stroke-teal-950\/90 {
  stroke: rgb(4 47 46 / 0.9) !important;
}

.tw-stroke-teal-950\/95 {
  stroke: rgb(4 47 46 / 0.95) !important;
}

.tw-stroke-transparent {
  stroke: transparent !important;
}

.tw-stroke-transparent\/0 {
  stroke: rgb(0 0 0 / 0) !important;
}

.tw-stroke-transparent\/10 {
  stroke: rgb(0 0 0 / 0.1) !important;
}

.tw-stroke-transparent\/100 {
  stroke: rgb(0 0 0 / 1) !important;
}

.tw-stroke-transparent\/15 {
  stroke: rgb(0 0 0 / 0.15) !important;
}

.tw-stroke-transparent\/20 {
  stroke: rgb(0 0 0 / 0.2) !important;
}

.tw-stroke-transparent\/25 {
  stroke: rgb(0 0 0 / 0.25) !important;
}

.tw-stroke-transparent\/30 {
  stroke: rgb(0 0 0 / 0.3) !important;
}

.tw-stroke-transparent\/35 {
  stroke: rgb(0 0 0 / 0.35) !important;
}

.tw-stroke-transparent\/40 {
  stroke: rgb(0 0 0 / 0.4) !important;
}

.tw-stroke-transparent\/45 {
  stroke: rgb(0 0 0 / 0.45) !important;
}

.tw-stroke-transparent\/5 {
  stroke: rgb(0 0 0 / 0.05) !important;
}

.tw-stroke-transparent\/50 {
  stroke: rgb(0 0 0 / 0.5) !important;
}

.tw-stroke-transparent\/55 {
  stroke: rgb(0 0 0 / 0.55) !important;
}

.tw-stroke-transparent\/60 {
  stroke: rgb(0 0 0 / 0.6) !important;
}

.tw-stroke-transparent\/65 {
  stroke: rgb(0 0 0 / 0.65) !important;
}

.tw-stroke-transparent\/70 {
  stroke: rgb(0 0 0 / 0.7) !important;
}

.tw-stroke-transparent\/75 {
  stroke: rgb(0 0 0 / 0.75) !important;
}

.tw-stroke-transparent\/80 {
  stroke: rgb(0 0 0 / 0.8) !important;
}

.tw-stroke-transparent\/85 {
  stroke: rgb(0 0 0 / 0.85) !important;
}

.tw-stroke-transparent\/90 {
  stroke: rgb(0 0 0 / 0.9) !important;
}

.tw-stroke-transparent\/95 {
  stroke: rgb(0 0 0 / 0.95) !important;
}

.tw-stroke-violet-100 {
  stroke: #ede9fe !important;
}

.tw-stroke-violet-100\/0 {
  stroke: rgb(237 233 254 / 0) !important;
}

.tw-stroke-violet-100\/10 {
  stroke: rgb(237 233 254 / 0.1) !important;
}

.tw-stroke-violet-100\/100 {
  stroke: rgb(237 233 254 / 1) !important;
}

.tw-stroke-violet-100\/15 {
  stroke: rgb(237 233 254 / 0.15) !important;
}

.tw-stroke-violet-100\/20 {
  stroke: rgb(237 233 254 / 0.2) !important;
}

.tw-stroke-violet-100\/25 {
  stroke: rgb(237 233 254 / 0.25) !important;
}

.tw-stroke-violet-100\/30 {
  stroke: rgb(237 233 254 / 0.3) !important;
}

.tw-stroke-violet-100\/35 {
  stroke: rgb(237 233 254 / 0.35) !important;
}

.tw-stroke-violet-100\/40 {
  stroke: rgb(237 233 254 / 0.4) !important;
}

.tw-stroke-violet-100\/45 {
  stroke: rgb(237 233 254 / 0.45) !important;
}

.tw-stroke-violet-100\/5 {
  stroke: rgb(237 233 254 / 0.05) !important;
}

.tw-stroke-violet-100\/50 {
  stroke: rgb(237 233 254 / 0.5) !important;
}

.tw-stroke-violet-100\/55 {
  stroke: rgb(237 233 254 / 0.55) !important;
}

.tw-stroke-violet-100\/60 {
  stroke: rgb(237 233 254 / 0.6) !important;
}

.tw-stroke-violet-100\/65 {
  stroke: rgb(237 233 254 / 0.65) !important;
}

.tw-stroke-violet-100\/70 {
  stroke: rgb(237 233 254 / 0.7) !important;
}

.tw-stroke-violet-100\/75 {
  stroke: rgb(237 233 254 / 0.75) !important;
}

.tw-stroke-violet-100\/80 {
  stroke: rgb(237 233 254 / 0.8) !important;
}

.tw-stroke-violet-100\/85 {
  stroke: rgb(237 233 254 / 0.85) !important;
}

.tw-stroke-violet-100\/90 {
  stroke: rgb(237 233 254 / 0.9) !important;
}

.tw-stroke-violet-100\/95 {
  stroke: rgb(237 233 254 / 0.95) !important;
}

.tw-stroke-violet-200 {
  stroke: #ddd6fe !important;
}

.tw-stroke-violet-200\/0 {
  stroke: rgb(221 214 254 / 0) !important;
}

.tw-stroke-violet-200\/10 {
  stroke: rgb(221 214 254 / 0.1) !important;
}

.tw-stroke-violet-200\/100 {
  stroke: rgb(221 214 254 / 1) !important;
}

.tw-stroke-violet-200\/15 {
  stroke: rgb(221 214 254 / 0.15) !important;
}

.tw-stroke-violet-200\/20 {
  stroke: rgb(221 214 254 / 0.2) !important;
}

.tw-stroke-violet-200\/25 {
  stroke: rgb(221 214 254 / 0.25) !important;
}

.tw-stroke-violet-200\/30 {
  stroke: rgb(221 214 254 / 0.3) !important;
}

.tw-stroke-violet-200\/35 {
  stroke: rgb(221 214 254 / 0.35) !important;
}

.tw-stroke-violet-200\/40 {
  stroke: rgb(221 214 254 / 0.4) !important;
}

.tw-stroke-violet-200\/45 {
  stroke: rgb(221 214 254 / 0.45) !important;
}

.tw-stroke-violet-200\/5 {
  stroke: rgb(221 214 254 / 0.05) !important;
}

.tw-stroke-violet-200\/50 {
  stroke: rgb(221 214 254 / 0.5) !important;
}

.tw-stroke-violet-200\/55 {
  stroke: rgb(221 214 254 / 0.55) !important;
}

.tw-stroke-violet-200\/60 {
  stroke: rgb(221 214 254 / 0.6) !important;
}

.tw-stroke-violet-200\/65 {
  stroke: rgb(221 214 254 / 0.65) !important;
}

.tw-stroke-violet-200\/70 {
  stroke: rgb(221 214 254 / 0.7) !important;
}

.tw-stroke-violet-200\/75 {
  stroke: rgb(221 214 254 / 0.75) !important;
}

.tw-stroke-violet-200\/80 {
  stroke: rgb(221 214 254 / 0.8) !important;
}

.tw-stroke-violet-200\/85 {
  stroke: rgb(221 214 254 / 0.85) !important;
}

.tw-stroke-violet-200\/90 {
  stroke: rgb(221 214 254 / 0.9) !important;
}

.tw-stroke-violet-200\/95 {
  stroke: rgb(221 214 254 / 0.95) !important;
}

.tw-stroke-violet-300 {
  stroke: #c4b5fd !important;
}

.tw-stroke-violet-300\/0 {
  stroke: rgb(196 181 253 / 0) !important;
}

.tw-stroke-violet-300\/10 {
  stroke: rgb(196 181 253 / 0.1) !important;
}

.tw-stroke-violet-300\/100 {
  stroke: rgb(196 181 253 / 1) !important;
}

.tw-stroke-violet-300\/15 {
  stroke: rgb(196 181 253 / 0.15) !important;
}

.tw-stroke-violet-300\/20 {
  stroke: rgb(196 181 253 / 0.2) !important;
}

.tw-stroke-violet-300\/25 {
  stroke: rgb(196 181 253 / 0.25) !important;
}

.tw-stroke-violet-300\/30 {
  stroke: rgb(196 181 253 / 0.3) !important;
}

.tw-stroke-violet-300\/35 {
  stroke: rgb(196 181 253 / 0.35) !important;
}

.tw-stroke-violet-300\/40 {
  stroke: rgb(196 181 253 / 0.4) !important;
}

.tw-stroke-violet-300\/45 {
  stroke: rgb(196 181 253 / 0.45) !important;
}

.tw-stroke-violet-300\/5 {
  stroke: rgb(196 181 253 / 0.05) !important;
}

.tw-stroke-violet-300\/50 {
  stroke: rgb(196 181 253 / 0.5) !important;
}

.tw-stroke-violet-300\/55 {
  stroke: rgb(196 181 253 / 0.55) !important;
}

.tw-stroke-violet-300\/60 {
  stroke: rgb(196 181 253 / 0.6) !important;
}

.tw-stroke-violet-300\/65 {
  stroke: rgb(196 181 253 / 0.65) !important;
}

.tw-stroke-violet-300\/70 {
  stroke: rgb(196 181 253 / 0.7) !important;
}

.tw-stroke-violet-300\/75 {
  stroke: rgb(196 181 253 / 0.75) !important;
}

.tw-stroke-violet-300\/80 {
  stroke: rgb(196 181 253 / 0.8) !important;
}

.tw-stroke-violet-300\/85 {
  stroke: rgb(196 181 253 / 0.85) !important;
}

.tw-stroke-violet-300\/90 {
  stroke: rgb(196 181 253 / 0.9) !important;
}

.tw-stroke-violet-300\/95 {
  stroke: rgb(196 181 253 / 0.95) !important;
}

.tw-stroke-violet-400 {
  stroke: #a78bfa !important;
}

.tw-stroke-violet-400\/0 {
  stroke: rgb(167 139 250 / 0) !important;
}

.tw-stroke-violet-400\/10 {
  stroke: rgb(167 139 250 / 0.1) !important;
}

.tw-stroke-violet-400\/100 {
  stroke: rgb(167 139 250 / 1) !important;
}

.tw-stroke-violet-400\/15 {
  stroke: rgb(167 139 250 / 0.15) !important;
}

.tw-stroke-violet-400\/20 {
  stroke: rgb(167 139 250 / 0.2) !important;
}

.tw-stroke-violet-400\/25 {
  stroke: rgb(167 139 250 / 0.25) !important;
}

.tw-stroke-violet-400\/30 {
  stroke: rgb(167 139 250 / 0.3) !important;
}

.tw-stroke-violet-400\/35 {
  stroke: rgb(167 139 250 / 0.35) !important;
}

.tw-stroke-violet-400\/40 {
  stroke: rgb(167 139 250 / 0.4) !important;
}

.tw-stroke-violet-400\/45 {
  stroke: rgb(167 139 250 / 0.45) !important;
}

.tw-stroke-violet-400\/5 {
  stroke: rgb(167 139 250 / 0.05) !important;
}

.tw-stroke-violet-400\/50 {
  stroke: rgb(167 139 250 / 0.5) !important;
}

.tw-stroke-violet-400\/55 {
  stroke: rgb(167 139 250 / 0.55) !important;
}

.tw-stroke-violet-400\/60 {
  stroke: rgb(167 139 250 / 0.6) !important;
}

.tw-stroke-violet-400\/65 {
  stroke: rgb(167 139 250 / 0.65) !important;
}

.tw-stroke-violet-400\/70 {
  stroke: rgb(167 139 250 / 0.7) !important;
}

.tw-stroke-violet-400\/75 {
  stroke: rgb(167 139 250 / 0.75) !important;
}

.tw-stroke-violet-400\/80 {
  stroke: rgb(167 139 250 / 0.8) !important;
}

.tw-stroke-violet-400\/85 {
  stroke: rgb(167 139 250 / 0.85) !important;
}

.tw-stroke-violet-400\/90 {
  stroke: rgb(167 139 250 / 0.9) !important;
}

.tw-stroke-violet-400\/95 {
  stroke: rgb(167 139 250 / 0.95) !important;
}

.tw-stroke-violet-50 {
  stroke: #f5f3ff !important;
}

.tw-stroke-violet-50\/0 {
  stroke: rgb(245 243 255 / 0) !important;
}

.tw-stroke-violet-50\/10 {
  stroke: rgb(245 243 255 / 0.1) !important;
}

.tw-stroke-violet-50\/100 {
  stroke: rgb(245 243 255 / 1) !important;
}

.tw-stroke-violet-50\/15 {
  stroke: rgb(245 243 255 / 0.15) !important;
}

.tw-stroke-violet-50\/20 {
  stroke: rgb(245 243 255 / 0.2) !important;
}

.tw-stroke-violet-50\/25 {
  stroke: rgb(245 243 255 / 0.25) !important;
}

.tw-stroke-violet-50\/30 {
  stroke: rgb(245 243 255 / 0.3) !important;
}

.tw-stroke-violet-50\/35 {
  stroke: rgb(245 243 255 / 0.35) !important;
}

.tw-stroke-violet-50\/40 {
  stroke: rgb(245 243 255 / 0.4) !important;
}

.tw-stroke-violet-50\/45 {
  stroke: rgb(245 243 255 / 0.45) !important;
}

.tw-stroke-violet-50\/5 {
  stroke: rgb(245 243 255 / 0.05) !important;
}

.tw-stroke-violet-50\/50 {
  stroke: rgb(245 243 255 / 0.5) !important;
}

.tw-stroke-violet-50\/55 {
  stroke: rgb(245 243 255 / 0.55) !important;
}

.tw-stroke-violet-50\/60 {
  stroke: rgb(245 243 255 / 0.6) !important;
}

.tw-stroke-violet-50\/65 {
  stroke: rgb(245 243 255 / 0.65) !important;
}

.tw-stroke-violet-50\/70 {
  stroke: rgb(245 243 255 / 0.7) !important;
}

.tw-stroke-violet-50\/75 {
  stroke: rgb(245 243 255 / 0.75) !important;
}

.tw-stroke-violet-50\/80 {
  stroke: rgb(245 243 255 / 0.8) !important;
}

.tw-stroke-violet-50\/85 {
  stroke: rgb(245 243 255 / 0.85) !important;
}

.tw-stroke-violet-50\/90 {
  stroke: rgb(245 243 255 / 0.9) !important;
}

.tw-stroke-violet-50\/95 {
  stroke: rgb(245 243 255 / 0.95) !important;
}

.tw-stroke-violet-500 {
  stroke: #8b5cf6 !important;
}

.tw-stroke-violet-500\/0 {
  stroke: rgb(139 92 246 / 0) !important;
}

.tw-stroke-violet-500\/10 {
  stroke: rgb(139 92 246 / 0.1) !important;
}

.tw-stroke-violet-500\/100 {
  stroke: rgb(139 92 246 / 1) !important;
}

.tw-stroke-violet-500\/15 {
  stroke: rgb(139 92 246 / 0.15) !important;
}

.tw-stroke-violet-500\/20 {
  stroke: rgb(139 92 246 / 0.2) !important;
}

.tw-stroke-violet-500\/25 {
  stroke: rgb(139 92 246 / 0.25) !important;
}

.tw-stroke-violet-500\/30 {
  stroke: rgb(139 92 246 / 0.3) !important;
}

.tw-stroke-violet-500\/35 {
  stroke: rgb(139 92 246 / 0.35) !important;
}

.tw-stroke-violet-500\/40 {
  stroke: rgb(139 92 246 / 0.4) !important;
}

.tw-stroke-violet-500\/45 {
  stroke: rgb(139 92 246 / 0.45) !important;
}

.tw-stroke-violet-500\/5 {
  stroke: rgb(139 92 246 / 0.05) !important;
}

.tw-stroke-violet-500\/50 {
  stroke: rgb(139 92 246 / 0.5) !important;
}

.tw-stroke-violet-500\/55 {
  stroke: rgb(139 92 246 / 0.55) !important;
}

.tw-stroke-violet-500\/60 {
  stroke: rgb(139 92 246 / 0.6) !important;
}

.tw-stroke-violet-500\/65 {
  stroke: rgb(139 92 246 / 0.65) !important;
}

.tw-stroke-violet-500\/70 {
  stroke: rgb(139 92 246 / 0.7) !important;
}

.tw-stroke-violet-500\/75 {
  stroke: rgb(139 92 246 / 0.75) !important;
}

.tw-stroke-violet-500\/80 {
  stroke: rgb(139 92 246 / 0.8) !important;
}

.tw-stroke-violet-500\/85 {
  stroke: rgb(139 92 246 / 0.85) !important;
}

.tw-stroke-violet-500\/90 {
  stroke: rgb(139 92 246 / 0.9) !important;
}

.tw-stroke-violet-500\/95 {
  stroke: rgb(139 92 246 / 0.95) !important;
}

.tw-stroke-violet-600 {
  stroke: #7c3aed !important;
}

.tw-stroke-violet-600\/0 {
  stroke: rgb(124 58 237 / 0) !important;
}

.tw-stroke-violet-600\/10 {
  stroke: rgb(124 58 237 / 0.1) !important;
}

.tw-stroke-violet-600\/100 {
  stroke: rgb(124 58 237 / 1) !important;
}

.tw-stroke-violet-600\/15 {
  stroke: rgb(124 58 237 / 0.15) !important;
}

.tw-stroke-violet-600\/20 {
  stroke: rgb(124 58 237 / 0.2) !important;
}

.tw-stroke-violet-600\/25 {
  stroke: rgb(124 58 237 / 0.25) !important;
}

.tw-stroke-violet-600\/30 {
  stroke: rgb(124 58 237 / 0.3) !important;
}

.tw-stroke-violet-600\/35 {
  stroke: rgb(124 58 237 / 0.35) !important;
}

.tw-stroke-violet-600\/40 {
  stroke: rgb(124 58 237 / 0.4) !important;
}

.tw-stroke-violet-600\/45 {
  stroke: rgb(124 58 237 / 0.45) !important;
}

.tw-stroke-violet-600\/5 {
  stroke: rgb(124 58 237 / 0.05) !important;
}

.tw-stroke-violet-600\/50 {
  stroke: rgb(124 58 237 / 0.5) !important;
}

.tw-stroke-violet-600\/55 {
  stroke: rgb(124 58 237 / 0.55) !important;
}

.tw-stroke-violet-600\/60 {
  stroke: rgb(124 58 237 / 0.6) !important;
}

.tw-stroke-violet-600\/65 {
  stroke: rgb(124 58 237 / 0.65) !important;
}

.tw-stroke-violet-600\/70 {
  stroke: rgb(124 58 237 / 0.7) !important;
}

.tw-stroke-violet-600\/75 {
  stroke: rgb(124 58 237 / 0.75) !important;
}

.tw-stroke-violet-600\/80 {
  stroke: rgb(124 58 237 / 0.8) !important;
}

.tw-stroke-violet-600\/85 {
  stroke: rgb(124 58 237 / 0.85) !important;
}

.tw-stroke-violet-600\/90 {
  stroke: rgb(124 58 237 / 0.9) !important;
}

.tw-stroke-violet-600\/95 {
  stroke: rgb(124 58 237 / 0.95) !important;
}

.tw-stroke-violet-700 {
  stroke: #6d28d9 !important;
}

.tw-stroke-violet-700\/0 {
  stroke: rgb(109 40 217 / 0) !important;
}

.tw-stroke-violet-700\/10 {
  stroke: rgb(109 40 217 / 0.1) !important;
}

.tw-stroke-violet-700\/100 {
  stroke: rgb(109 40 217 / 1) !important;
}

.tw-stroke-violet-700\/15 {
  stroke: rgb(109 40 217 / 0.15) !important;
}

.tw-stroke-violet-700\/20 {
  stroke: rgb(109 40 217 / 0.2) !important;
}

.tw-stroke-violet-700\/25 {
  stroke: rgb(109 40 217 / 0.25) !important;
}

.tw-stroke-violet-700\/30 {
  stroke: rgb(109 40 217 / 0.3) !important;
}

.tw-stroke-violet-700\/35 {
  stroke: rgb(109 40 217 / 0.35) !important;
}

.tw-stroke-violet-700\/40 {
  stroke: rgb(109 40 217 / 0.4) !important;
}

.tw-stroke-violet-700\/45 {
  stroke: rgb(109 40 217 / 0.45) !important;
}

.tw-stroke-violet-700\/5 {
  stroke: rgb(109 40 217 / 0.05) !important;
}

.tw-stroke-violet-700\/50 {
  stroke: rgb(109 40 217 / 0.5) !important;
}

.tw-stroke-violet-700\/55 {
  stroke: rgb(109 40 217 / 0.55) !important;
}

.tw-stroke-violet-700\/60 {
  stroke: rgb(109 40 217 / 0.6) !important;
}

.tw-stroke-violet-700\/65 {
  stroke: rgb(109 40 217 / 0.65) !important;
}

.tw-stroke-violet-700\/70 {
  stroke: rgb(109 40 217 / 0.7) !important;
}

.tw-stroke-violet-700\/75 {
  stroke: rgb(109 40 217 / 0.75) !important;
}

.tw-stroke-violet-700\/80 {
  stroke: rgb(109 40 217 / 0.8) !important;
}

.tw-stroke-violet-700\/85 {
  stroke: rgb(109 40 217 / 0.85) !important;
}

.tw-stroke-violet-700\/90 {
  stroke: rgb(109 40 217 / 0.9) !important;
}

.tw-stroke-violet-700\/95 {
  stroke: rgb(109 40 217 / 0.95) !important;
}

.tw-stroke-violet-800 {
  stroke: #5b21b6 !important;
}

.tw-stroke-violet-800\/0 {
  stroke: rgb(91 33 182 / 0) !important;
}

.tw-stroke-violet-800\/10 {
  stroke: rgb(91 33 182 / 0.1) !important;
}

.tw-stroke-violet-800\/100 {
  stroke: rgb(91 33 182 / 1) !important;
}

.tw-stroke-violet-800\/15 {
  stroke: rgb(91 33 182 / 0.15) !important;
}

.tw-stroke-violet-800\/20 {
  stroke: rgb(91 33 182 / 0.2) !important;
}

.tw-stroke-violet-800\/25 {
  stroke: rgb(91 33 182 / 0.25) !important;
}

.tw-stroke-violet-800\/30 {
  stroke: rgb(91 33 182 / 0.3) !important;
}

.tw-stroke-violet-800\/35 {
  stroke: rgb(91 33 182 / 0.35) !important;
}

.tw-stroke-violet-800\/40 {
  stroke: rgb(91 33 182 / 0.4) !important;
}

.tw-stroke-violet-800\/45 {
  stroke: rgb(91 33 182 / 0.45) !important;
}

.tw-stroke-violet-800\/5 {
  stroke: rgb(91 33 182 / 0.05) !important;
}

.tw-stroke-violet-800\/50 {
  stroke: rgb(91 33 182 / 0.5) !important;
}

.tw-stroke-violet-800\/55 {
  stroke: rgb(91 33 182 / 0.55) !important;
}

.tw-stroke-violet-800\/60 {
  stroke: rgb(91 33 182 / 0.6) !important;
}

.tw-stroke-violet-800\/65 {
  stroke: rgb(91 33 182 / 0.65) !important;
}

.tw-stroke-violet-800\/70 {
  stroke: rgb(91 33 182 / 0.7) !important;
}

.tw-stroke-violet-800\/75 {
  stroke: rgb(91 33 182 / 0.75) !important;
}

.tw-stroke-violet-800\/80 {
  stroke: rgb(91 33 182 / 0.8) !important;
}

.tw-stroke-violet-800\/85 {
  stroke: rgb(91 33 182 / 0.85) !important;
}

.tw-stroke-violet-800\/90 {
  stroke: rgb(91 33 182 / 0.9) !important;
}

.tw-stroke-violet-800\/95 {
  stroke: rgb(91 33 182 / 0.95) !important;
}

.tw-stroke-violet-900 {
  stroke: #4c1d95 !important;
}

.tw-stroke-violet-900\/0 {
  stroke: rgb(76 29 149 / 0) !important;
}

.tw-stroke-violet-900\/10 {
  stroke: rgb(76 29 149 / 0.1) !important;
}

.tw-stroke-violet-900\/100 {
  stroke: rgb(76 29 149 / 1) !important;
}

.tw-stroke-violet-900\/15 {
  stroke: rgb(76 29 149 / 0.15) !important;
}

.tw-stroke-violet-900\/20 {
  stroke: rgb(76 29 149 / 0.2) !important;
}

.tw-stroke-violet-900\/25 {
  stroke: rgb(76 29 149 / 0.25) !important;
}

.tw-stroke-violet-900\/30 {
  stroke: rgb(76 29 149 / 0.3) !important;
}

.tw-stroke-violet-900\/35 {
  stroke: rgb(76 29 149 / 0.35) !important;
}

.tw-stroke-violet-900\/40 {
  stroke: rgb(76 29 149 / 0.4) !important;
}

.tw-stroke-violet-900\/45 {
  stroke: rgb(76 29 149 / 0.45) !important;
}

.tw-stroke-violet-900\/5 {
  stroke: rgb(76 29 149 / 0.05) !important;
}

.tw-stroke-violet-900\/50 {
  stroke: rgb(76 29 149 / 0.5) !important;
}

.tw-stroke-violet-900\/55 {
  stroke: rgb(76 29 149 / 0.55) !important;
}

.tw-stroke-violet-900\/60 {
  stroke: rgb(76 29 149 / 0.6) !important;
}

.tw-stroke-violet-900\/65 {
  stroke: rgb(76 29 149 / 0.65) !important;
}

.tw-stroke-violet-900\/70 {
  stroke: rgb(76 29 149 / 0.7) !important;
}

.tw-stroke-violet-900\/75 {
  stroke: rgb(76 29 149 / 0.75) !important;
}

.tw-stroke-violet-900\/80 {
  stroke: rgb(76 29 149 / 0.8) !important;
}

.tw-stroke-violet-900\/85 {
  stroke: rgb(76 29 149 / 0.85) !important;
}

.tw-stroke-violet-900\/90 {
  stroke: rgb(76 29 149 / 0.9) !important;
}

.tw-stroke-violet-900\/95 {
  stroke: rgb(76 29 149 / 0.95) !important;
}

.tw-stroke-violet-950 {
  stroke: #2e1065 !important;
}

.tw-stroke-violet-950\/0 {
  stroke: rgb(46 16 101 / 0) !important;
}

.tw-stroke-violet-950\/10 {
  stroke: rgb(46 16 101 / 0.1) !important;
}

.tw-stroke-violet-950\/100 {
  stroke: rgb(46 16 101 / 1) !important;
}

.tw-stroke-violet-950\/15 {
  stroke: rgb(46 16 101 / 0.15) !important;
}

.tw-stroke-violet-950\/20 {
  stroke: rgb(46 16 101 / 0.2) !important;
}

.tw-stroke-violet-950\/25 {
  stroke: rgb(46 16 101 / 0.25) !important;
}

.tw-stroke-violet-950\/30 {
  stroke: rgb(46 16 101 / 0.3) !important;
}

.tw-stroke-violet-950\/35 {
  stroke: rgb(46 16 101 / 0.35) !important;
}

.tw-stroke-violet-950\/40 {
  stroke: rgb(46 16 101 / 0.4) !important;
}

.tw-stroke-violet-950\/45 {
  stroke: rgb(46 16 101 / 0.45) !important;
}

.tw-stroke-violet-950\/5 {
  stroke: rgb(46 16 101 / 0.05) !important;
}

.tw-stroke-violet-950\/50 {
  stroke: rgb(46 16 101 / 0.5) !important;
}

.tw-stroke-violet-950\/55 {
  stroke: rgb(46 16 101 / 0.55) !important;
}

.tw-stroke-violet-950\/60 {
  stroke: rgb(46 16 101 / 0.6) !important;
}

.tw-stroke-violet-950\/65 {
  stroke: rgb(46 16 101 / 0.65) !important;
}

.tw-stroke-violet-950\/70 {
  stroke: rgb(46 16 101 / 0.7) !important;
}

.tw-stroke-violet-950\/75 {
  stroke: rgb(46 16 101 / 0.75) !important;
}

.tw-stroke-violet-950\/80 {
  stroke: rgb(46 16 101 / 0.8) !important;
}

.tw-stroke-violet-950\/85 {
  stroke: rgb(46 16 101 / 0.85) !important;
}

.tw-stroke-violet-950\/90 {
  stroke: rgb(46 16 101 / 0.9) !important;
}

.tw-stroke-violet-950\/95 {
  stroke: rgb(46 16 101 / 0.95) !important;
}

.tw-stroke-white {
  stroke: #fff !important;
}

.tw-stroke-white\/0 {
  stroke: rgb(255 255 255 / 0) !important;
}

.tw-stroke-white\/10 {
  stroke: rgb(255 255 255 / 0.1) !important;
}

.tw-stroke-white\/100 {
  stroke: rgb(255 255 255 / 1) !important;
}

.tw-stroke-white\/15 {
  stroke: rgb(255 255 255 / 0.15) !important;
}

.tw-stroke-white\/20 {
  stroke: rgb(255 255 255 / 0.2) !important;
}

.tw-stroke-white\/25 {
  stroke: rgb(255 255 255 / 0.25) !important;
}

.tw-stroke-white\/30 {
  stroke: rgb(255 255 255 / 0.3) !important;
}

.tw-stroke-white\/35 {
  stroke: rgb(255 255 255 / 0.35) !important;
}

.tw-stroke-white\/40 {
  stroke: rgb(255 255 255 / 0.4) !important;
}

.tw-stroke-white\/45 {
  stroke: rgb(255 255 255 / 0.45) !important;
}

.tw-stroke-white\/5 {
  stroke: rgb(255 255 255 / 0.05) !important;
}

.tw-stroke-white\/50 {
  stroke: rgb(255 255 255 / 0.5) !important;
}

.tw-stroke-white\/55 {
  stroke: rgb(255 255 255 / 0.55) !important;
}

.tw-stroke-white\/60 {
  stroke: rgb(255 255 255 / 0.6) !important;
}

.tw-stroke-white\/65 {
  stroke: rgb(255 255 255 / 0.65) !important;
}

.tw-stroke-white\/70 {
  stroke: rgb(255 255 255 / 0.7) !important;
}

.tw-stroke-white\/75 {
  stroke: rgb(255 255 255 / 0.75) !important;
}

.tw-stroke-white\/80 {
  stroke: rgb(255 255 255 / 0.8) !important;
}

.tw-stroke-white\/85 {
  stroke: rgb(255 255 255 / 0.85) !important;
}

.tw-stroke-white\/90 {
  stroke: rgb(255 255 255 / 0.9) !important;
}

.tw-stroke-white\/95 {
  stroke: rgb(255 255 255 / 0.95) !important;
}

.tw-stroke-yellow-100 {
  stroke: #fef9c3 !important;
}

.tw-stroke-yellow-100\/0 {
  stroke: rgb(254 249 195 / 0) !important;
}

.tw-stroke-yellow-100\/10 {
  stroke: rgb(254 249 195 / 0.1) !important;
}

.tw-stroke-yellow-100\/100 {
  stroke: rgb(254 249 195 / 1) !important;
}

.tw-stroke-yellow-100\/15 {
  stroke: rgb(254 249 195 / 0.15) !important;
}

.tw-stroke-yellow-100\/20 {
  stroke: rgb(254 249 195 / 0.2) !important;
}

.tw-stroke-yellow-100\/25 {
  stroke: rgb(254 249 195 / 0.25) !important;
}

.tw-stroke-yellow-100\/30 {
  stroke: rgb(254 249 195 / 0.3) !important;
}

.tw-stroke-yellow-100\/35 {
  stroke: rgb(254 249 195 / 0.35) !important;
}

.tw-stroke-yellow-100\/40 {
  stroke: rgb(254 249 195 / 0.4) !important;
}

.tw-stroke-yellow-100\/45 {
  stroke: rgb(254 249 195 / 0.45) !important;
}

.tw-stroke-yellow-100\/5 {
  stroke: rgb(254 249 195 / 0.05) !important;
}

.tw-stroke-yellow-100\/50 {
  stroke: rgb(254 249 195 / 0.5) !important;
}

.tw-stroke-yellow-100\/55 {
  stroke: rgb(254 249 195 / 0.55) !important;
}

.tw-stroke-yellow-100\/60 {
  stroke: rgb(254 249 195 / 0.6) !important;
}

.tw-stroke-yellow-100\/65 {
  stroke: rgb(254 249 195 / 0.65) !important;
}

.tw-stroke-yellow-100\/70 {
  stroke: rgb(254 249 195 / 0.7) !important;
}

.tw-stroke-yellow-100\/75 {
  stroke: rgb(254 249 195 / 0.75) !important;
}

.tw-stroke-yellow-100\/80 {
  stroke: rgb(254 249 195 / 0.8) !important;
}

.tw-stroke-yellow-100\/85 {
  stroke: rgb(254 249 195 / 0.85) !important;
}

.tw-stroke-yellow-100\/90 {
  stroke: rgb(254 249 195 / 0.9) !important;
}

.tw-stroke-yellow-100\/95 {
  stroke: rgb(254 249 195 / 0.95) !important;
}

.tw-stroke-yellow-200 {
  stroke: #fef08a !important;
}

.tw-stroke-yellow-200\/0 {
  stroke: rgb(254 240 138 / 0) !important;
}

.tw-stroke-yellow-200\/10 {
  stroke: rgb(254 240 138 / 0.1) !important;
}

.tw-stroke-yellow-200\/100 {
  stroke: rgb(254 240 138 / 1) !important;
}

.tw-stroke-yellow-200\/15 {
  stroke: rgb(254 240 138 / 0.15) !important;
}

.tw-stroke-yellow-200\/20 {
  stroke: rgb(254 240 138 / 0.2) !important;
}

.tw-stroke-yellow-200\/25 {
  stroke: rgb(254 240 138 / 0.25) !important;
}

.tw-stroke-yellow-200\/30 {
  stroke: rgb(254 240 138 / 0.3) !important;
}

.tw-stroke-yellow-200\/35 {
  stroke: rgb(254 240 138 / 0.35) !important;
}

.tw-stroke-yellow-200\/40 {
  stroke: rgb(254 240 138 / 0.4) !important;
}

.tw-stroke-yellow-200\/45 {
  stroke: rgb(254 240 138 / 0.45) !important;
}

.tw-stroke-yellow-200\/5 {
  stroke: rgb(254 240 138 / 0.05) !important;
}

.tw-stroke-yellow-200\/50 {
  stroke: rgb(254 240 138 / 0.5) !important;
}

.tw-stroke-yellow-200\/55 {
  stroke: rgb(254 240 138 / 0.55) !important;
}

.tw-stroke-yellow-200\/60 {
  stroke: rgb(254 240 138 / 0.6) !important;
}

.tw-stroke-yellow-200\/65 {
  stroke: rgb(254 240 138 / 0.65) !important;
}

.tw-stroke-yellow-200\/70 {
  stroke: rgb(254 240 138 / 0.7) !important;
}

.tw-stroke-yellow-200\/75 {
  stroke: rgb(254 240 138 / 0.75) !important;
}

.tw-stroke-yellow-200\/80 {
  stroke: rgb(254 240 138 / 0.8) !important;
}

.tw-stroke-yellow-200\/85 {
  stroke: rgb(254 240 138 / 0.85) !important;
}

.tw-stroke-yellow-200\/90 {
  stroke: rgb(254 240 138 / 0.9) !important;
}

.tw-stroke-yellow-200\/95 {
  stroke: rgb(254 240 138 / 0.95) !important;
}

.tw-stroke-yellow-300 {
  stroke: #fde047 !important;
}

.tw-stroke-yellow-300\/0 {
  stroke: rgb(253 224 71 / 0) !important;
}

.tw-stroke-yellow-300\/10 {
  stroke: rgb(253 224 71 / 0.1) !important;
}

.tw-stroke-yellow-300\/100 {
  stroke: rgb(253 224 71 / 1) !important;
}

.tw-stroke-yellow-300\/15 {
  stroke: rgb(253 224 71 / 0.15) !important;
}

.tw-stroke-yellow-300\/20 {
  stroke: rgb(253 224 71 / 0.2) !important;
}

.tw-stroke-yellow-300\/25 {
  stroke: rgb(253 224 71 / 0.25) !important;
}

.tw-stroke-yellow-300\/30 {
  stroke: rgb(253 224 71 / 0.3) !important;
}

.tw-stroke-yellow-300\/35 {
  stroke: rgb(253 224 71 / 0.35) !important;
}

.tw-stroke-yellow-300\/40 {
  stroke: rgb(253 224 71 / 0.4) !important;
}

.tw-stroke-yellow-300\/45 {
  stroke: rgb(253 224 71 / 0.45) !important;
}

.tw-stroke-yellow-300\/5 {
  stroke: rgb(253 224 71 / 0.05) !important;
}

.tw-stroke-yellow-300\/50 {
  stroke: rgb(253 224 71 / 0.5) !important;
}

.tw-stroke-yellow-300\/55 {
  stroke: rgb(253 224 71 / 0.55) !important;
}

.tw-stroke-yellow-300\/60 {
  stroke: rgb(253 224 71 / 0.6) !important;
}

.tw-stroke-yellow-300\/65 {
  stroke: rgb(253 224 71 / 0.65) !important;
}

.tw-stroke-yellow-300\/70 {
  stroke: rgb(253 224 71 / 0.7) !important;
}

.tw-stroke-yellow-300\/75 {
  stroke: rgb(253 224 71 / 0.75) !important;
}

.tw-stroke-yellow-300\/80 {
  stroke: rgb(253 224 71 / 0.8) !important;
}

.tw-stroke-yellow-300\/85 {
  stroke: rgb(253 224 71 / 0.85) !important;
}

.tw-stroke-yellow-300\/90 {
  stroke: rgb(253 224 71 / 0.9) !important;
}

.tw-stroke-yellow-300\/95 {
  stroke: rgb(253 224 71 / 0.95) !important;
}

.tw-stroke-yellow-400 {
  stroke: #facc15 !important;
}

.tw-stroke-yellow-400\/0 {
  stroke: rgb(250 204 21 / 0) !important;
}

.tw-stroke-yellow-400\/10 {
  stroke: rgb(250 204 21 / 0.1) !important;
}

.tw-stroke-yellow-400\/100 {
  stroke: rgb(250 204 21 / 1) !important;
}

.tw-stroke-yellow-400\/15 {
  stroke: rgb(250 204 21 / 0.15) !important;
}

.tw-stroke-yellow-400\/20 {
  stroke: rgb(250 204 21 / 0.2) !important;
}

.tw-stroke-yellow-400\/25 {
  stroke: rgb(250 204 21 / 0.25) !important;
}

.tw-stroke-yellow-400\/30 {
  stroke: rgb(250 204 21 / 0.3) !important;
}

.tw-stroke-yellow-400\/35 {
  stroke: rgb(250 204 21 / 0.35) !important;
}

.tw-stroke-yellow-400\/40 {
  stroke: rgb(250 204 21 / 0.4) !important;
}

.tw-stroke-yellow-400\/45 {
  stroke: rgb(250 204 21 / 0.45) !important;
}

.tw-stroke-yellow-400\/5 {
  stroke: rgb(250 204 21 / 0.05) !important;
}

.tw-stroke-yellow-400\/50 {
  stroke: rgb(250 204 21 / 0.5) !important;
}

.tw-stroke-yellow-400\/55 {
  stroke: rgb(250 204 21 / 0.55) !important;
}

.tw-stroke-yellow-400\/60 {
  stroke: rgb(250 204 21 / 0.6) !important;
}

.tw-stroke-yellow-400\/65 {
  stroke: rgb(250 204 21 / 0.65) !important;
}

.tw-stroke-yellow-400\/70 {
  stroke: rgb(250 204 21 / 0.7) !important;
}

.tw-stroke-yellow-400\/75 {
  stroke: rgb(250 204 21 / 0.75) !important;
}

.tw-stroke-yellow-400\/80 {
  stroke: rgb(250 204 21 / 0.8) !important;
}

.tw-stroke-yellow-400\/85 {
  stroke: rgb(250 204 21 / 0.85) !important;
}

.tw-stroke-yellow-400\/90 {
  stroke: rgb(250 204 21 / 0.9) !important;
}

.tw-stroke-yellow-400\/95 {
  stroke: rgb(250 204 21 / 0.95) !important;
}

.tw-stroke-yellow-50 {
  stroke: #fefce8 !important;
}

.tw-stroke-yellow-50\/0 {
  stroke: rgb(254 252 232 / 0) !important;
}

.tw-stroke-yellow-50\/10 {
  stroke: rgb(254 252 232 / 0.1) !important;
}

.tw-stroke-yellow-50\/100 {
  stroke: rgb(254 252 232 / 1) !important;
}

.tw-stroke-yellow-50\/15 {
  stroke: rgb(254 252 232 / 0.15) !important;
}

.tw-stroke-yellow-50\/20 {
  stroke: rgb(254 252 232 / 0.2) !important;
}

.tw-stroke-yellow-50\/25 {
  stroke: rgb(254 252 232 / 0.25) !important;
}

.tw-stroke-yellow-50\/30 {
  stroke: rgb(254 252 232 / 0.3) !important;
}

.tw-stroke-yellow-50\/35 {
  stroke: rgb(254 252 232 / 0.35) !important;
}

.tw-stroke-yellow-50\/40 {
  stroke: rgb(254 252 232 / 0.4) !important;
}

.tw-stroke-yellow-50\/45 {
  stroke: rgb(254 252 232 / 0.45) !important;
}

.tw-stroke-yellow-50\/5 {
  stroke: rgb(254 252 232 / 0.05) !important;
}

.tw-stroke-yellow-50\/50 {
  stroke: rgb(254 252 232 / 0.5) !important;
}

.tw-stroke-yellow-50\/55 {
  stroke: rgb(254 252 232 / 0.55) !important;
}

.tw-stroke-yellow-50\/60 {
  stroke: rgb(254 252 232 / 0.6) !important;
}

.tw-stroke-yellow-50\/65 {
  stroke: rgb(254 252 232 / 0.65) !important;
}

.tw-stroke-yellow-50\/70 {
  stroke: rgb(254 252 232 / 0.7) !important;
}

.tw-stroke-yellow-50\/75 {
  stroke: rgb(254 252 232 / 0.75) !important;
}

.tw-stroke-yellow-50\/80 {
  stroke: rgb(254 252 232 / 0.8) !important;
}

.tw-stroke-yellow-50\/85 {
  stroke: rgb(254 252 232 / 0.85) !important;
}

.tw-stroke-yellow-50\/90 {
  stroke: rgb(254 252 232 / 0.9) !important;
}

.tw-stroke-yellow-50\/95 {
  stroke: rgb(254 252 232 / 0.95) !important;
}

.tw-stroke-yellow-500 {
  stroke: #eab308 !important;
}

.tw-stroke-yellow-500\/0 {
  stroke: rgb(234 179 8 / 0) !important;
}

.tw-stroke-yellow-500\/10 {
  stroke: rgb(234 179 8 / 0.1) !important;
}

.tw-stroke-yellow-500\/100 {
  stroke: rgb(234 179 8 / 1) !important;
}

.tw-stroke-yellow-500\/15 {
  stroke: rgb(234 179 8 / 0.15) !important;
}

.tw-stroke-yellow-500\/20 {
  stroke: rgb(234 179 8 / 0.2) !important;
}

.tw-stroke-yellow-500\/25 {
  stroke: rgb(234 179 8 / 0.25) !important;
}

.tw-stroke-yellow-500\/30 {
  stroke: rgb(234 179 8 / 0.3) !important;
}

.tw-stroke-yellow-500\/35 {
  stroke: rgb(234 179 8 / 0.35) !important;
}

.tw-stroke-yellow-500\/40 {
  stroke: rgb(234 179 8 / 0.4) !important;
}

.tw-stroke-yellow-500\/45 {
  stroke: rgb(234 179 8 / 0.45) !important;
}

.tw-stroke-yellow-500\/5 {
  stroke: rgb(234 179 8 / 0.05) !important;
}

.tw-stroke-yellow-500\/50 {
  stroke: rgb(234 179 8 / 0.5) !important;
}

.tw-stroke-yellow-500\/55 {
  stroke: rgb(234 179 8 / 0.55) !important;
}

.tw-stroke-yellow-500\/60 {
  stroke: rgb(234 179 8 / 0.6) !important;
}

.tw-stroke-yellow-500\/65 {
  stroke: rgb(234 179 8 / 0.65) !important;
}

.tw-stroke-yellow-500\/70 {
  stroke: rgb(234 179 8 / 0.7) !important;
}

.tw-stroke-yellow-500\/75 {
  stroke: rgb(234 179 8 / 0.75) !important;
}

.tw-stroke-yellow-500\/80 {
  stroke: rgb(234 179 8 / 0.8) !important;
}

.tw-stroke-yellow-500\/85 {
  stroke: rgb(234 179 8 / 0.85) !important;
}

.tw-stroke-yellow-500\/90 {
  stroke: rgb(234 179 8 / 0.9) !important;
}

.tw-stroke-yellow-500\/95 {
  stroke: rgb(234 179 8 / 0.95) !important;
}

.tw-stroke-yellow-600 {
  stroke: #ca8a04 !important;
}

.tw-stroke-yellow-600\/0 {
  stroke: rgb(202 138 4 / 0) !important;
}

.tw-stroke-yellow-600\/10 {
  stroke: rgb(202 138 4 / 0.1) !important;
}

.tw-stroke-yellow-600\/100 {
  stroke: rgb(202 138 4 / 1) !important;
}

.tw-stroke-yellow-600\/15 {
  stroke: rgb(202 138 4 / 0.15) !important;
}

.tw-stroke-yellow-600\/20 {
  stroke: rgb(202 138 4 / 0.2) !important;
}

.tw-stroke-yellow-600\/25 {
  stroke: rgb(202 138 4 / 0.25) !important;
}

.tw-stroke-yellow-600\/30 {
  stroke: rgb(202 138 4 / 0.3) !important;
}

.tw-stroke-yellow-600\/35 {
  stroke: rgb(202 138 4 / 0.35) !important;
}

.tw-stroke-yellow-600\/40 {
  stroke: rgb(202 138 4 / 0.4) !important;
}

.tw-stroke-yellow-600\/45 {
  stroke: rgb(202 138 4 / 0.45) !important;
}

.tw-stroke-yellow-600\/5 {
  stroke: rgb(202 138 4 / 0.05) !important;
}

.tw-stroke-yellow-600\/50 {
  stroke: rgb(202 138 4 / 0.5) !important;
}

.tw-stroke-yellow-600\/55 {
  stroke: rgb(202 138 4 / 0.55) !important;
}

.tw-stroke-yellow-600\/60 {
  stroke: rgb(202 138 4 / 0.6) !important;
}

.tw-stroke-yellow-600\/65 {
  stroke: rgb(202 138 4 / 0.65) !important;
}

.tw-stroke-yellow-600\/70 {
  stroke: rgb(202 138 4 / 0.7) !important;
}

.tw-stroke-yellow-600\/75 {
  stroke: rgb(202 138 4 / 0.75) !important;
}

.tw-stroke-yellow-600\/80 {
  stroke: rgb(202 138 4 / 0.8) !important;
}

.tw-stroke-yellow-600\/85 {
  stroke: rgb(202 138 4 / 0.85) !important;
}

.tw-stroke-yellow-600\/90 {
  stroke: rgb(202 138 4 / 0.9) !important;
}

.tw-stroke-yellow-600\/95 {
  stroke: rgb(202 138 4 / 0.95) !important;
}

.tw-stroke-yellow-700 {
  stroke: #a16207 !important;
}

.tw-stroke-yellow-700\/0 {
  stroke: rgb(161 98 7 / 0) !important;
}

.tw-stroke-yellow-700\/10 {
  stroke: rgb(161 98 7 / 0.1) !important;
}

.tw-stroke-yellow-700\/100 {
  stroke: rgb(161 98 7 / 1) !important;
}

.tw-stroke-yellow-700\/15 {
  stroke: rgb(161 98 7 / 0.15) !important;
}

.tw-stroke-yellow-700\/20 {
  stroke: rgb(161 98 7 / 0.2) !important;
}

.tw-stroke-yellow-700\/25 {
  stroke: rgb(161 98 7 / 0.25) !important;
}

.tw-stroke-yellow-700\/30 {
  stroke: rgb(161 98 7 / 0.3) !important;
}

.tw-stroke-yellow-700\/35 {
  stroke: rgb(161 98 7 / 0.35) !important;
}

.tw-stroke-yellow-700\/40 {
  stroke: rgb(161 98 7 / 0.4) !important;
}

.tw-stroke-yellow-700\/45 {
  stroke: rgb(161 98 7 / 0.45) !important;
}

.tw-stroke-yellow-700\/5 {
  stroke: rgb(161 98 7 / 0.05) !important;
}

.tw-stroke-yellow-700\/50 {
  stroke: rgb(161 98 7 / 0.5) !important;
}

.tw-stroke-yellow-700\/55 {
  stroke: rgb(161 98 7 / 0.55) !important;
}

.tw-stroke-yellow-700\/60 {
  stroke: rgb(161 98 7 / 0.6) !important;
}

.tw-stroke-yellow-700\/65 {
  stroke: rgb(161 98 7 / 0.65) !important;
}

.tw-stroke-yellow-700\/70 {
  stroke: rgb(161 98 7 / 0.7) !important;
}

.tw-stroke-yellow-700\/75 {
  stroke: rgb(161 98 7 / 0.75) !important;
}

.tw-stroke-yellow-700\/80 {
  stroke: rgb(161 98 7 / 0.8) !important;
}

.tw-stroke-yellow-700\/85 {
  stroke: rgb(161 98 7 / 0.85) !important;
}

.tw-stroke-yellow-700\/90 {
  stroke: rgb(161 98 7 / 0.9) !important;
}

.tw-stroke-yellow-700\/95 {
  stroke: rgb(161 98 7 / 0.95) !important;
}

.tw-stroke-yellow-800 {
  stroke: #854d0e !important;
}

.tw-stroke-yellow-800\/0 {
  stroke: rgb(133 77 14 / 0) !important;
}

.tw-stroke-yellow-800\/10 {
  stroke: rgb(133 77 14 / 0.1) !important;
}

.tw-stroke-yellow-800\/100 {
  stroke: rgb(133 77 14 / 1) !important;
}

.tw-stroke-yellow-800\/15 {
  stroke: rgb(133 77 14 / 0.15) !important;
}

.tw-stroke-yellow-800\/20 {
  stroke: rgb(133 77 14 / 0.2) !important;
}

.tw-stroke-yellow-800\/25 {
  stroke: rgb(133 77 14 / 0.25) !important;
}

.tw-stroke-yellow-800\/30 {
  stroke: rgb(133 77 14 / 0.3) !important;
}

.tw-stroke-yellow-800\/35 {
  stroke: rgb(133 77 14 / 0.35) !important;
}

.tw-stroke-yellow-800\/40 {
  stroke: rgb(133 77 14 / 0.4) !important;
}

.tw-stroke-yellow-800\/45 {
  stroke: rgb(133 77 14 / 0.45) !important;
}

.tw-stroke-yellow-800\/5 {
  stroke: rgb(133 77 14 / 0.05) !important;
}

.tw-stroke-yellow-800\/50 {
  stroke: rgb(133 77 14 / 0.5) !important;
}

.tw-stroke-yellow-800\/55 {
  stroke: rgb(133 77 14 / 0.55) !important;
}

.tw-stroke-yellow-800\/60 {
  stroke: rgb(133 77 14 / 0.6) !important;
}

.tw-stroke-yellow-800\/65 {
  stroke: rgb(133 77 14 / 0.65) !important;
}

.tw-stroke-yellow-800\/70 {
  stroke: rgb(133 77 14 / 0.7) !important;
}

.tw-stroke-yellow-800\/75 {
  stroke: rgb(133 77 14 / 0.75) !important;
}

.tw-stroke-yellow-800\/80 {
  stroke: rgb(133 77 14 / 0.8) !important;
}

.tw-stroke-yellow-800\/85 {
  stroke: rgb(133 77 14 / 0.85) !important;
}

.tw-stroke-yellow-800\/90 {
  stroke: rgb(133 77 14 / 0.9) !important;
}

.tw-stroke-yellow-800\/95 {
  stroke: rgb(133 77 14 / 0.95) !important;
}

.tw-stroke-yellow-900 {
  stroke: #713f12 !important;
}

.tw-stroke-yellow-900\/0 {
  stroke: rgb(113 63 18 / 0) !important;
}

.tw-stroke-yellow-900\/10 {
  stroke: rgb(113 63 18 / 0.1) !important;
}

.tw-stroke-yellow-900\/100 {
  stroke: rgb(113 63 18 / 1) !important;
}

.tw-stroke-yellow-900\/15 {
  stroke: rgb(113 63 18 / 0.15) !important;
}

.tw-stroke-yellow-900\/20 {
  stroke: rgb(113 63 18 / 0.2) !important;
}

.tw-stroke-yellow-900\/25 {
  stroke: rgb(113 63 18 / 0.25) !important;
}

.tw-stroke-yellow-900\/30 {
  stroke: rgb(113 63 18 / 0.3) !important;
}

.tw-stroke-yellow-900\/35 {
  stroke: rgb(113 63 18 / 0.35) !important;
}

.tw-stroke-yellow-900\/40 {
  stroke: rgb(113 63 18 / 0.4) !important;
}

.tw-stroke-yellow-900\/45 {
  stroke: rgb(113 63 18 / 0.45) !important;
}

.tw-stroke-yellow-900\/5 {
  stroke: rgb(113 63 18 / 0.05) !important;
}

.tw-stroke-yellow-900\/50 {
  stroke: rgb(113 63 18 / 0.5) !important;
}

.tw-stroke-yellow-900\/55 {
  stroke: rgb(113 63 18 / 0.55) !important;
}

.tw-stroke-yellow-900\/60 {
  stroke: rgb(113 63 18 / 0.6) !important;
}

.tw-stroke-yellow-900\/65 {
  stroke: rgb(113 63 18 / 0.65) !important;
}

.tw-stroke-yellow-900\/70 {
  stroke: rgb(113 63 18 / 0.7) !important;
}

.tw-stroke-yellow-900\/75 {
  stroke: rgb(113 63 18 / 0.75) !important;
}

.tw-stroke-yellow-900\/80 {
  stroke: rgb(113 63 18 / 0.8) !important;
}

.tw-stroke-yellow-900\/85 {
  stroke: rgb(113 63 18 / 0.85) !important;
}

.tw-stroke-yellow-900\/90 {
  stroke: rgb(113 63 18 / 0.9) !important;
}

.tw-stroke-yellow-900\/95 {
  stroke: rgb(113 63 18 / 0.95) !important;
}

.tw-stroke-yellow-950 {
  stroke: #422006 !important;
}

.tw-stroke-yellow-950\/0 {
  stroke: rgb(66 32 6 / 0) !important;
}

.tw-stroke-yellow-950\/10 {
  stroke: rgb(66 32 6 / 0.1) !important;
}

.tw-stroke-yellow-950\/100 {
  stroke: rgb(66 32 6 / 1) !important;
}

.tw-stroke-yellow-950\/15 {
  stroke: rgb(66 32 6 / 0.15) !important;
}

.tw-stroke-yellow-950\/20 {
  stroke: rgb(66 32 6 / 0.2) !important;
}

.tw-stroke-yellow-950\/25 {
  stroke: rgb(66 32 6 / 0.25) !important;
}

.tw-stroke-yellow-950\/30 {
  stroke: rgb(66 32 6 / 0.3) !important;
}

.tw-stroke-yellow-950\/35 {
  stroke: rgb(66 32 6 / 0.35) !important;
}

.tw-stroke-yellow-950\/40 {
  stroke: rgb(66 32 6 / 0.4) !important;
}

.tw-stroke-yellow-950\/45 {
  stroke: rgb(66 32 6 / 0.45) !important;
}

.tw-stroke-yellow-950\/5 {
  stroke: rgb(66 32 6 / 0.05) !important;
}

.tw-stroke-yellow-950\/50 {
  stroke: rgb(66 32 6 / 0.5) !important;
}

.tw-stroke-yellow-950\/55 {
  stroke: rgb(66 32 6 / 0.55) !important;
}

.tw-stroke-yellow-950\/60 {
  stroke: rgb(66 32 6 / 0.6) !important;
}

.tw-stroke-yellow-950\/65 {
  stroke: rgb(66 32 6 / 0.65) !important;
}

.tw-stroke-yellow-950\/70 {
  stroke: rgb(66 32 6 / 0.7) !important;
}

.tw-stroke-yellow-950\/75 {
  stroke: rgb(66 32 6 / 0.75) !important;
}

.tw-stroke-yellow-950\/80 {
  stroke: rgb(66 32 6 / 0.8) !important;
}

.tw-stroke-yellow-950\/85 {
  stroke: rgb(66 32 6 / 0.85) !important;
}

.tw-stroke-yellow-950\/90 {
  stroke: rgb(66 32 6 / 0.9) !important;
}

.tw-stroke-yellow-950\/95 {
  stroke: rgb(66 32 6 / 0.95) !important;
}

.tw-stroke-zinc-100 {
  stroke: #f4f4f5 !important;
}

.tw-stroke-zinc-100\/0 {
  stroke: rgb(244 244 245 / 0) !important;
}

.tw-stroke-zinc-100\/10 {
  stroke: rgb(244 244 245 / 0.1) !important;
}

.tw-stroke-zinc-100\/100 {
  stroke: rgb(244 244 245 / 1) !important;
}

.tw-stroke-zinc-100\/15 {
  stroke: rgb(244 244 245 / 0.15) !important;
}

.tw-stroke-zinc-100\/20 {
  stroke: rgb(244 244 245 / 0.2) !important;
}

.tw-stroke-zinc-100\/25 {
  stroke: rgb(244 244 245 / 0.25) !important;
}

.tw-stroke-zinc-100\/30 {
  stroke: rgb(244 244 245 / 0.3) !important;
}

.tw-stroke-zinc-100\/35 {
  stroke: rgb(244 244 245 / 0.35) !important;
}

.tw-stroke-zinc-100\/40 {
  stroke: rgb(244 244 245 / 0.4) !important;
}

.tw-stroke-zinc-100\/45 {
  stroke: rgb(244 244 245 / 0.45) !important;
}

.tw-stroke-zinc-100\/5 {
  stroke: rgb(244 244 245 / 0.05) !important;
}

.tw-stroke-zinc-100\/50 {
  stroke: rgb(244 244 245 / 0.5) !important;
}

.tw-stroke-zinc-100\/55 {
  stroke: rgb(244 244 245 / 0.55) !important;
}

.tw-stroke-zinc-100\/60 {
  stroke: rgb(244 244 245 / 0.6) !important;
}

.tw-stroke-zinc-100\/65 {
  stroke: rgb(244 244 245 / 0.65) !important;
}

.tw-stroke-zinc-100\/70 {
  stroke: rgb(244 244 245 / 0.7) !important;
}

.tw-stroke-zinc-100\/75 {
  stroke: rgb(244 244 245 / 0.75) !important;
}

.tw-stroke-zinc-100\/80 {
  stroke: rgb(244 244 245 / 0.8) !important;
}

.tw-stroke-zinc-100\/85 {
  stroke: rgb(244 244 245 / 0.85) !important;
}

.tw-stroke-zinc-100\/90 {
  stroke: rgb(244 244 245 / 0.9) !important;
}

.tw-stroke-zinc-100\/95 {
  stroke: rgb(244 244 245 / 0.95) !important;
}

.tw-stroke-zinc-200 {
  stroke: #e4e4e7 !important;
}

.tw-stroke-zinc-200\/0 {
  stroke: rgb(228 228 231 / 0) !important;
}

.tw-stroke-zinc-200\/10 {
  stroke: rgb(228 228 231 / 0.1) !important;
}

.tw-stroke-zinc-200\/100 {
  stroke: rgb(228 228 231 / 1) !important;
}

.tw-stroke-zinc-200\/15 {
  stroke: rgb(228 228 231 / 0.15) !important;
}

.tw-stroke-zinc-200\/20 {
  stroke: rgb(228 228 231 / 0.2) !important;
}

.tw-stroke-zinc-200\/25 {
  stroke: rgb(228 228 231 / 0.25) !important;
}

.tw-stroke-zinc-200\/30 {
  stroke: rgb(228 228 231 / 0.3) !important;
}

.tw-stroke-zinc-200\/35 {
  stroke: rgb(228 228 231 / 0.35) !important;
}

.tw-stroke-zinc-200\/40 {
  stroke: rgb(228 228 231 / 0.4) !important;
}

.tw-stroke-zinc-200\/45 {
  stroke: rgb(228 228 231 / 0.45) !important;
}

.tw-stroke-zinc-200\/5 {
  stroke: rgb(228 228 231 / 0.05) !important;
}

.tw-stroke-zinc-200\/50 {
  stroke: rgb(228 228 231 / 0.5) !important;
}

.tw-stroke-zinc-200\/55 {
  stroke: rgb(228 228 231 / 0.55) !important;
}

.tw-stroke-zinc-200\/60 {
  stroke: rgb(228 228 231 / 0.6) !important;
}

.tw-stroke-zinc-200\/65 {
  stroke: rgb(228 228 231 / 0.65) !important;
}

.tw-stroke-zinc-200\/70 {
  stroke: rgb(228 228 231 / 0.7) !important;
}

.tw-stroke-zinc-200\/75 {
  stroke: rgb(228 228 231 / 0.75) !important;
}

.tw-stroke-zinc-200\/80 {
  stroke: rgb(228 228 231 / 0.8) !important;
}

.tw-stroke-zinc-200\/85 {
  stroke: rgb(228 228 231 / 0.85) !important;
}

.tw-stroke-zinc-200\/90 {
  stroke: rgb(228 228 231 / 0.9) !important;
}

.tw-stroke-zinc-200\/95 {
  stroke: rgb(228 228 231 / 0.95) !important;
}

.tw-stroke-zinc-300 {
  stroke: #d4d4d8 !important;
}

.tw-stroke-zinc-300\/0 {
  stroke: rgb(212 212 216 / 0) !important;
}

.tw-stroke-zinc-300\/10 {
  stroke: rgb(212 212 216 / 0.1) !important;
}

.tw-stroke-zinc-300\/100 {
  stroke: rgb(212 212 216 / 1) !important;
}

.tw-stroke-zinc-300\/15 {
  stroke: rgb(212 212 216 / 0.15) !important;
}

.tw-stroke-zinc-300\/20 {
  stroke: rgb(212 212 216 / 0.2) !important;
}

.tw-stroke-zinc-300\/25 {
  stroke: rgb(212 212 216 / 0.25) !important;
}

.tw-stroke-zinc-300\/30 {
  stroke: rgb(212 212 216 / 0.3) !important;
}

.tw-stroke-zinc-300\/35 {
  stroke: rgb(212 212 216 / 0.35) !important;
}

.tw-stroke-zinc-300\/40 {
  stroke: rgb(212 212 216 / 0.4) !important;
}

.tw-stroke-zinc-300\/45 {
  stroke: rgb(212 212 216 / 0.45) !important;
}

.tw-stroke-zinc-300\/5 {
  stroke: rgb(212 212 216 / 0.05) !important;
}

.tw-stroke-zinc-300\/50 {
  stroke: rgb(212 212 216 / 0.5) !important;
}

.tw-stroke-zinc-300\/55 {
  stroke: rgb(212 212 216 / 0.55) !important;
}

.tw-stroke-zinc-300\/60 {
  stroke: rgb(212 212 216 / 0.6) !important;
}

.tw-stroke-zinc-300\/65 {
  stroke: rgb(212 212 216 / 0.65) !important;
}

.tw-stroke-zinc-300\/70 {
  stroke: rgb(212 212 216 / 0.7) !important;
}

.tw-stroke-zinc-300\/75 {
  stroke: rgb(212 212 216 / 0.75) !important;
}

.tw-stroke-zinc-300\/80 {
  stroke: rgb(212 212 216 / 0.8) !important;
}

.tw-stroke-zinc-300\/85 {
  stroke: rgb(212 212 216 / 0.85) !important;
}

.tw-stroke-zinc-300\/90 {
  stroke: rgb(212 212 216 / 0.9) !important;
}

.tw-stroke-zinc-300\/95 {
  stroke: rgb(212 212 216 / 0.95) !important;
}

.tw-stroke-zinc-400 {
  stroke: #a1a1aa !important;
}

.tw-stroke-zinc-400\/0 {
  stroke: rgb(161 161 170 / 0) !important;
}

.tw-stroke-zinc-400\/10 {
  stroke: rgb(161 161 170 / 0.1) !important;
}

.tw-stroke-zinc-400\/100 {
  stroke: rgb(161 161 170 / 1) !important;
}

.tw-stroke-zinc-400\/15 {
  stroke: rgb(161 161 170 / 0.15) !important;
}

.tw-stroke-zinc-400\/20 {
  stroke: rgb(161 161 170 / 0.2) !important;
}

.tw-stroke-zinc-400\/25 {
  stroke: rgb(161 161 170 / 0.25) !important;
}

.tw-stroke-zinc-400\/30 {
  stroke: rgb(161 161 170 / 0.3) !important;
}

.tw-stroke-zinc-400\/35 {
  stroke: rgb(161 161 170 / 0.35) !important;
}

.tw-stroke-zinc-400\/40 {
  stroke: rgb(161 161 170 / 0.4) !important;
}

.tw-stroke-zinc-400\/45 {
  stroke: rgb(161 161 170 / 0.45) !important;
}

.tw-stroke-zinc-400\/5 {
  stroke: rgb(161 161 170 / 0.05) !important;
}

.tw-stroke-zinc-400\/50 {
  stroke: rgb(161 161 170 / 0.5) !important;
}

.tw-stroke-zinc-400\/55 {
  stroke: rgb(161 161 170 / 0.55) !important;
}

.tw-stroke-zinc-400\/60 {
  stroke: rgb(161 161 170 / 0.6) !important;
}

.tw-stroke-zinc-400\/65 {
  stroke: rgb(161 161 170 / 0.65) !important;
}

.tw-stroke-zinc-400\/70 {
  stroke: rgb(161 161 170 / 0.7) !important;
}

.tw-stroke-zinc-400\/75 {
  stroke: rgb(161 161 170 / 0.75) !important;
}

.tw-stroke-zinc-400\/80 {
  stroke: rgb(161 161 170 / 0.8) !important;
}

.tw-stroke-zinc-400\/85 {
  stroke: rgb(161 161 170 / 0.85) !important;
}

.tw-stroke-zinc-400\/90 {
  stroke: rgb(161 161 170 / 0.9) !important;
}

.tw-stroke-zinc-400\/95 {
  stroke: rgb(161 161 170 / 0.95) !important;
}

.tw-stroke-zinc-50 {
  stroke: #fafafa !important;
}

.tw-stroke-zinc-50\/0 {
  stroke: rgb(250 250 250 / 0) !important;
}

.tw-stroke-zinc-50\/10 {
  stroke: rgb(250 250 250 / 0.1) !important;
}

.tw-stroke-zinc-50\/100 {
  stroke: rgb(250 250 250 / 1) !important;
}

.tw-stroke-zinc-50\/15 {
  stroke: rgb(250 250 250 / 0.15) !important;
}

.tw-stroke-zinc-50\/20 {
  stroke: rgb(250 250 250 / 0.2) !important;
}

.tw-stroke-zinc-50\/25 {
  stroke: rgb(250 250 250 / 0.25) !important;
}

.tw-stroke-zinc-50\/30 {
  stroke: rgb(250 250 250 / 0.3) !important;
}

.tw-stroke-zinc-50\/35 {
  stroke: rgb(250 250 250 / 0.35) !important;
}

.tw-stroke-zinc-50\/40 {
  stroke: rgb(250 250 250 / 0.4) !important;
}

.tw-stroke-zinc-50\/45 {
  stroke: rgb(250 250 250 / 0.45) !important;
}

.tw-stroke-zinc-50\/5 {
  stroke: rgb(250 250 250 / 0.05) !important;
}

.tw-stroke-zinc-50\/50 {
  stroke: rgb(250 250 250 / 0.5) !important;
}

.tw-stroke-zinc-50\/55 {
  stroke: rgb(250 250 250 / 0.55) !important;
}

.tw-stroke-zinc-50\/60 {
  stroke: rgb(250 250 250 / 0.6) !important;
}

.tw-stroke-zinc-50\/65 {
  stroke: rgb(250 250 250 / 0.65) !important;
}

.tw-stroke-zinc-50\/70 {
  stroke: rgb(250 250 250 / 0.7) !important;
}

.tw-stroke-zinc-50\/75 {
  stroke: rgb(250 250 250 / 0.75) !important;
}

.tw-stroke-zinc-50\/80 {
  stroke: rgb(250 250 250 / 0.8) !important;
}

.tw-stroke-zinc-50\/85 {
  stroke: rgb(250 250 250 / 0.85) !important;
}

.tw-stroke-zinc-50\/90 {
  stroke: rgb(250 250 250 / 0.9) !important;
}

.tw-stroke-zinc-50\/95 {
  stroke: rgb(250 250 250 / 0.95) !important;
}

.tw-stroke-zinc-500 {
  stroke: #71717a !important;
}

.tw-stroke-zinc-500\/0 {
  stroke: rgb(113 113 122 / 0) !important;
}

.tw-stroke-zinc-500\/10 {
  stroke: rgb(113 113 122 / 0.1) !important;
}

.tw-stroke-zinc-500\/100 {
  stroke: rgb(113 113 122 / 1) !important;
}

.tw-stroke-zinc-500\/15 {
  stroke: rgb(113 113 122 / 0.15) !important;
}

.tw-stroke-zinc-500\/20 {
  stroke: rgb(113 113 122 / 0.2) !important;
}

.tw-stroke-zinc-500\/25 {
  stroke: rgb(113 113 122 / 0.25) !important;
}

.tw-stroke-zinc-500\/30 {
  stroke: rgb(113 113 122 / 0.3) !important;
}

.tw-stroke-zinc-500\/35 {
  stroke: rgb(113 113 122 / 0.35) !important;
}

.tw-stroke-zinc-500\/40 {
  stroke: rgb(113 113 122 / 0.4) !important;
}

.tw-stroke-zinc-500\/45 {
  stroke: rgb(113 113 122 / 0.45) !important;
}

.tw-stroke-zinc-500\/5 {
  stroke: rgb(113 113 122 / 0.05) !important;
}

.tw-stroke-zinc-500\/50 {
  stroke: rgb(113 113 122 / 0.5) !important;
}

.tw-stroke-zinc-500\/55 {
  stroke: rgb(113 113 122 / 0.55) !important;
}

.tw-stroke-zinc-500\/60 {
  stroke: rgb(113 113 122 / 0.6) !important;
}

.tw-stroke-zinc-500\/65 {
  stroke: rgb(113 113 122 / 0.65) !important;
}

.tw-stroke-zinc-500\/70 {
  stroke: rgb(113 113 122 / 0.7) !important;
}

.tw-stroke-zinc-500\/75 {
  stroke: rgb(113 113 122 / 0.75) !important;
}

.tw-stroke-zinc-500\/80 {
  stroke: rgb(113 113 122 / 0.8) !important;
}

.tw-stroke-zinc-500\/85 {
  stroke: rgb(113 113 122 / 0.85) !important;
}

.tw-stroke-zinc-500\/90 {
  stroke: rgb(113 113 122 / 0.9) !important;
}

.tw-stroke-zinc-500\/95 {
  stroke: rgb(113 113 122 / 0.95) !important;
}

.tw-stroke-zinc-600 {
  stroke: #52525b !important;
}

.tw-stroke-zinc-600\/0 {
  stroke: rgb(82 82 91 / 0) !important;
}

.tw-stroke-zinc-600\/10 {
  stroke: rgb(82 82 91 / 0.1) !important;
}

.tw-stroke-zinc-600\/100 {
  stroke: rgb(82 82 91 / 1) !important;
}

.tw-stroke-zinc-600\/15 {
  stroke: rgb(82 82 91 / 0.15) !important;
}

.tw-stroke-zinc-600\/20 {
  stroke: rgb(82 82 91 / 0.2) !important;
}

.tw-stroke-zinc-600\/25 {
  stroke: rgb(82 82 91 / 0.25) !important;
}

.tw-stroke-zinc-600\/30 {
  stroke: rgb(82 82 91 / 0.3) !important;
}

.tw-stroke-zinc-600\/35 {
  stroke: rgb(82 82 91 / 0.35) !important;
}

.tw-stroke-zinc-600\/40 {
  stroke: rgb(82 82 91 / 0.4) !important;
}

.tw-stroke-zinc-600\/45 {
  stroke: rgb(82 82 91 / 0.45) !important;
}

.tw-stroke-zinc-600\/5 {
  stroke: rgb(82 82 91 / 0.05) !important;
}

.tw-stroke-zinc-600\/50 {
  stroke: rgb(82 82 91 / 0.5) !important;
}

.tw-stroke-zinc-600\/55 {
  stroke: rgb(82 82 91 / 0.55) !important;
}

.tw-stroke-zinc-600\/60 {
  stroke: rgb(82 82 91 / 0.6) !important;
}

.tw-stroke-zinc-600\/65 {
  stroke: rgb(82 82 91 / 0.65) !important;
}

.tw-stroke-zinc-600\/70 {
  stroke: rgb(82 82 91 / 0.7) !important;
}

.tw-stroke-zinc-600\/75 {
  stroke: rgb(82 82 91 / 0.75) !important;
}

.tw-stroke-zinc-600\/80 {
  stroke: rgb(82 82 91 / 0.8) !important;
}

.tw-stroke-zinc-600\/85 {
  stroke: rgb(82 82 91 / 0.85) !important;
}

.tw-stroke-zinc-600\/90 {
  stroke: rgb(82 82 91 / 0.9) !important;
}

.tw-stroke-zinc-600\/95 {
  stroke: rgb(82 82 91 / 0.95) !important;
}

.tw-stroke-zinc-700 {
  stroke: #3f3f46 !important;
}

.tw-stroke-zinc-700\/0 {
  stroke: rgb(63 63 70 / 0) !important;
}

.tw-stroke-zinc-700\/10 {
  stroke: rgb(63 63 70 / 0.1) !important;
}

.tw-stroke-zinc-700\/100 {
  stroke: rgb(63 63 70 / 1) !important;
}

.tw-stroke-zinc-700\/15 {
  stroke: rgb(63 63 70 / 0.15) !important;
}

.tw-stroke-zinc-700\/20 {
  stroke: rgb(63 63 70 / 0.2) !important;
}

.tw-stroke-zinc-700\/25 {
  stroke: rgb(63 63 70 / 0.25) !important;
}

.tw-stroke-zinc-700\/30 {
  stroke: rgb(63 63 70 / 0.3) !important;
}

.tw-stroke-zinc-700\/35 {
  stroke: rgb(63 63 70 / 0.35) !important;
}

.tw-stroke-zinc-700\/40 {
  stroke: rgb(63 63 70 / 0.4) !important;
}

.tw-stroke-zinc-700\/45 {
  stroke: rgb(63 63 70 / 0.45) !important;
}

.tw-stroke-zinc-700\/5 {
  stroke: rgb(63 63 70 / 0.05) !important;
}

.tw-stroke-zinc-700\/50 {
  stroke: rgb(63 63 70 / 0.5) !important;
}

.tw-stroke-zinc-700\/55 {
  stroke: rgb(63 63 70 / 0.55) !important;
}

.tw-stroke-zinc-700\/60 {
  stroke: rgb(63 63 70 / 0.6) !important;
}

.tw-stroke-zinc-700\/65 {
  stroke: rgb(63 63 70 / 0.65) !important;
}

.tw-stroke-zinc-700\/70 {
  stroke: rgb(63 63 70 / 0.7) !important;
}

.tw-stroke-zinc-700\/75 {
  stroke: rgb(63 63 70 / 0.75) !important;
}

.tw-stroke-zinc-700\/80 {
  stroke: rgb(63 63 70 / 0.8) !important;
}

.tw-stroke-zinc-700\/85 {
  stroke: rgb(63 63 70 / 0.85) !important;
}

.tw-stroke-zinc-700\/90 {
  stroke: rgb(63 63 70 / 0.9) !important;
}

.tw-stroke-zinc-700\/95 {
  stroke: rgb(63 63 70 / 0.95) !important;
}

.tw-stroke-zinc-800 {
  stroke: #27272a !important;
}

.tw-stroke-zinc-800\/0 {
  stroke: rgb(39 39 42 / 0) !important;
}

.tw-stroke-zinc-800\/10 {
  stroke: rgb(39 39 42 / 0.1) !important;
}

.tw-stroke-zinc-800\/100 {
  stroke: rgb(39 39 42 / 1) !important;
}

.tw-stroke-zinc-800\/15 {
  stroke: rgb(39 39 42 / 0.15) !important;
}

.tw-stroke-zinc-800\/20 {
  stroke: rgb(39 39 42 / 0.2) !important;
}

.tw-stroke-zinc-800\/25 {
  stroke: rgb(39 39 42 / 0.25) !important;
}

.tw-stroke-zinc-800\/30 {
  stroke: rgb(39 39 42 / 0.3) !important;
}

.tw-stroke-zinc-800\/35 {
  stroke: rgb(39 39 42 / 0.35) !important;
}

.tw-stroke-zinc-800\/40 {
  stroke: rgb(39 39 42 / 0.4) !important;
}

.tw-stroke-zinc-800\/45 {
  stroke: rgb(39 39 42 / 0.45) !important;
}

.tw-stroke-zinc-800\/5 {
  stroke: rgb(39 39 42 / 0.05) !important;
}

.tw-stroke-zinc-800\/50 {
  stroke: rgb(39 39 42 / 0.5) !important;
}

.tw-stroke-zinc-800\/55 {
  stroke: rgb(39 39 42 / 0.55) !important;
}

.tw-stroke-zinc-800\/60 {
  stroke: rgb(39 39 42 / 0.6) !important;
}

.tw-stroke-zinc-800\/65 {
  stroke: rgb(39 39 42 / 0.65) !important;
}

.tw-stroke-zinc-800\/70 {
  stroke: rgb(39 39 42 / 0.7) !important;
}

.tw-stroke-zinc-800\/75 {
  stroke: rgb(39 39 42 / 0.75) !important;
}

.tw-stroke-zinc-800\/80 {
  stroke: rgb(39 39 42 / 0.8) !important;
}

.tw-stroke-zinc-800\/85 {
  stroke: rgb(39 39 42 / 0.85) !important;
}

.tw-stroke-zinc-800\/90 {
  stroke: rgb(39 39 42 / 0.9) !important;
}

.tw-stroke-zinc-800\/95 {
  stroke: rgb(39 39 42 / 0.95) !important;
}

.tw-stroke-zinc-900 {
  stroke: #18181b !important;
}

.tw-stroke-zinc-900\/0 {
  stroke: rgb(24 24 27 / 0) !important;
}

.tw-stroke-zinc-900\/10 {
  stroke: rgb(24 24 27 / 0.1) !important;
}

.tw-stroke-zinc-900\/100 {
  stroke: rgb(24 24 27 / 1) !important;
}

.tw-stroke-zinc-900\/15 {
  stroke: rgb(24 24 27 / 0.15) !important;
}

.tw-stroke-zinc-900\/20 {
  stroke: rgb(24 24 27 / 0.2) !important;
}

.tw-stroke-zinc-900\/25 {
  stroke: rgb(24 24 27 / 0.25) !important;
}

.tw-stroke-zinc-900\/30 {
  stroke: rgb(24 24 27 / 0.3) !important;
}

.tw-stroke-zinc-900\/35 {
  stroke: rgb(24 24 27 / 0.35) !important;
}

.tw-stroke-zinc-900\/40 {
  stroke: rgb(24 24 27 / 0.4) !important;
}

.tw-stroke-zinc-900\/45 {
  stroke: rgb(24 24 27 / 0.45) !important;
}

.tw-stroke-zinc-900\/5 {
  stroke: rgb(24 24 27 / 0.05) !important;
}

.tw-stroke-zinc-900\/50 {
  stroke: rgb(24 24 27 / 0.5) !important;
}

.tw-stroke-zinc-900\/55 {
  stroke: rgb(24 24 27 / 0.55) !important;
}

.tw-stroke-zinc-900\/60 {
  stroke: rgb(24 24 27 / 0.6) !important;
}

.tw-stroke-zinc-900\/65 {
  stroke: rgb(24 24 27 / 0.65) !important;
}

.tw-stroke-zinc-900\/70 {
  stroke: rgb(24 24 27 / 0.7) !important;
}

.tw-stroke-zinc-900\/75 {
  stroke: rgb(24 24 27 / 0.75) !important;
}

.tw-stroke-zinc-900\/80 {
  stroke: rgb(24 24 27 / 0.8) !important;
}

.tw-stroke-zinc-900\/85 {
  stroke: rgb(24 24 27 / 0.85) !important;
}

.tw-stroke-zinc-900\/90 {
  stroke: rgb(24 24 27 / 0.9) !important;
}

.tw-stroke-zinc-900\/95 {
  stroke: rgb(24 24 27 / 0.95) !important;
}

.tw-stroke-zinc-950 {
  stroke: #09090b !important;
}

.tw-stroke-zinc-950\/0 {
  stroke: rgb(9 9 11 / 0) !important;
}

.tw-stroke-zinc-950\/10 {
  stroke: rgb(9 9 11 / 0.1) !important;
}

.tw-stroke-zinc-950\/100 {
  stroke: rgb(9 9 11 / 1) !important;
}

.tw-stroke-zinc-950\/15 {
  stroke: rgb(9 9 11 / 0.15) !important;
}

.tw-stroke-zinc-950\/20 {
  stroke: rgb(9 9 11 / 0.2) !important;
}

.tw-stroke-zinc-950\/25 {
  stroke: rgb(9 9 11 / 0.25) !important;
}

.tw-stroke-zinc-950\/30 {
  stroke: rgb(9 9 11 / 0.3) !important;
}

.tw-stroke-zinc-950\/35 {
  stroke: rgb(9 9 11 / 0.35) !important;
}

.tw-stroke-zinc-950\/40 {
  stroke: rgb(9 9 11 / 0.4) !important;
}

.tw-stroke-zinc-950\/45 {
  stroke: rgb(9 9 11 / 0.45) !important;
}

.tw-stroke-zinc-950\/5 {
  stroke: rgb(9 9 11 / 0.05) !important;
}

.tw-stroke-zinc-950\/50 {
  stroke: rgb(9 9 11 / 0.5) !important;
}

.tw-stroke-zinc-950\/55 {
  stroke: rgb(9 9 11 / 0.55) !important;
}

.tw-stroke-zinc-950\/60 {
  stroke: rgb(9 9 11 / 0.6) !important;
}

.tw-stroke-zinc-950\/65 {
  stroke: rgb(9 9 11 / 0.65) !important;
}

.tw-stroke-zinc-950\/70 {
  stroke: rgb(9 9 11 / 0.7) !important;
}

.tw-stroke-zinc-950\/75 {
  stroke: rgb(9 9 11 / 0.75) !important;
}

.tw-stroke-zinc-950\/80 {
  stroke: rgb(9 9 11 / 0.8) !important;
}

.tw-stroke-zinc-950\/85 {
  stroke: rgb(9 9 11 / 0.85) !important;
}

.tw-stroke-zinc-950\/90 {
  stroke: rgb(9 9 11 / 0.9) !important;
}

.tw-stroke-zinc-950\/95 {
  stroke: rgb(9 9 11 / 0.95) !important;
}

.tw-stroke-0 {
  stroke-width: 0 !important;
}

.tw-stroke-1 {
  stroke-width: 1 !important;
}

.tw-stroke-2 {
  stroke-width: 2 !important;
}

.tw-object-contain {
  object-fit: contain !important;
}

.tw-object-cover {
  object-fit: cover !important;
}

.tw-object-fill {
  object-fit: fill !important;
}

.tw-object-none {
  object-fit: none !important;
}

.tw-object-scale-down {
  object-fit: scale-down !important;
}

.tw-object-bottom {
  object-position: bottom !important;
}

.tw-object-center {
  object-position: center !important;
}

.tw-object-left {
  object-position: left !important;
}

.tw-object-left-bottom {
  object-position: left bottom !important;
}

.tw-object-left-top {
  object-position: left top !important;
}

.tw-object-right {
  object-position: right !important;
}

.tw-object-right-bottom {
  object-position: right bottom !important;
}

.tw-object-right-top {
  object-position: right top !important;
}

.tw-object-top {
  object-position: top !important;
}

.tw-p-0 {
  padding: 0px !important;
}

.tw-p-0\.5 {
  padding: 0.125rem !important;
}

.tw-p-1 {
  padding: 0.25rem !important;
}

.tw-p-1\.5 {
  padding: 0.375rem !important;
}

.tw-p-10 {
  padding: 2.5rem !important;
}

.tw-p-11 {
  padding: 2.75rem !important;
}

.tw-p-12 {
  padding: 3rem !important;
}

.tw-p-14 {
  padding: 3.5rem !important;
}

.tw-p-16 {
  padding: 4rem !important;
}

.tw-p-2 {
  padding: 0.5rem !important;
}

.tw-p-2\.5 {
  padding: 0.625rem !important;
}

.tw-p-20 {
  padding: 5rem !important;
}

.tw-p-24 {
  padding: 6rem !important;
}

.tw-p-28 {
  padding: 7rem !important;
}

.tw-p-3 {
  padding: 0.75rem !important;
}

.tw-p-3\.5 {
  padding: 0.875rem !important;
}

.tw-p-32 {
  padding: 8rem !important;
}

.tw-p-36 {
  padding: 9rem !important;
}

.tw-p-4 {
  padding: 1rem !important;
}

.tw-p-40 {
  padding: 10rem !important;
}

.tw-p-44 {
  padding: 11rem !important;
}

.tw-p-48 {
  padding: 12rem !important;
}

.tw-p-5 {
  padding: 1.25rem !important;
}

.tw-p-52 {
  padding: 13rem !important;
}

.tw-p-56 {
  padding: 14rem !important;
}

.tw-p-6 {
  padding: 1.5rem !important;
}

.tw-p-60 {
  padding: 15rem !important;
}

.tw-p-64 {
  padding: 16rem !important;
}

.tw-p-7 {
  padding: 1.75rem !important;
}

.tw-p-72 {
  padding: 18rem !important;
}

.tw-p-8 {
  padding: 2rem !important;
}

.tw-p-80 {
  padding: 20rem !important;
}

.tw-p-9 {
  padding: 2.25rem !important;
}

.tw-p-96 {
  padding: 24rem !important;
}

.tw-p-px {
  padding: 1px !important;
}

.tw-px-0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.tw-px-0\.5 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}

.tw-px-1 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}

.tw-px-1\.5 {
  padding-left: 0.375rem !important;
  padding-right: 0.375rem !important;
}

.tw-px-10 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}

.tw-px-11 {
  padding-left: 2.75rem !important;
  padding-right: 2.75rem !important;
}

.tw-px-12 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.tw-px-14 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}

.tw-px-16 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.tw-px-2 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}

.tw-px-2\.5 {
  padding-left: 0.625rem !important;
  padding-right: 0.625rem !important;
}

.tw-px-20 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}

.tw-px-24 {
  padding-left: 6rem !important;
  padding-right: 6rem !important;
}

.tw-px-28 {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
}

.tw-px-3 {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}

.tw-px-3\.5 {
  padding-left: 0.875rem !important;
  padding-right: 0.875rem !important;
}

.tw-px-32 {
  padding-left: 8rem !important;
  padding-right: 8rem !important;
}

.tw-px-36 {
  padding-left: 9rem !important;
  padding-right: 9rem !important;
}

.tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.tw-px-40 {
  padding-left: 10rem !important;
  padding-right: 10rem !important;
}

.tw-px-44 {
  padding-left: 11rem !important;
  padding-right: 11rem !important;
}

.tw-px-48 {
  padding-left: 12rem !important;
  padding-right: 12rem !important;
}

.tw-px-5 {
  padding-left: 1.25rem !important;
  padding-right: 1.25rem !important;
}

.tw-px-52 {
  padding-left: 13rem !important;
  padding-right: 13rem !important;
}

.tw-px-56 {
  padding-left: 14rem !important;
  padding-right: 14rem !important;
}

.tw-px-6 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.tw-px-60 {
  padding-left: 15rem !important;
  padding-right: 15rem !important;
}

.tw-px-64 {
  padding-left: 16rem !important;
  padding-right: 16rem !important;
}

.tw-px-7 {
  padding-left: 1.75rem !important;
  padding-right: 1.75rem !important;
}

.tw-px-72 {
  padding-left: 18rem !important;
  padding-right: 18rem !important;
}

.tw-px-8 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.tw-px-80 {
  padding-left: 20rem !important;
  padding-right: 20rem !important;
}

.tw-px-9 {
  padding-left: 2.25rem !important;
  padding-right: 2.25rem !important;
}

.tw-px-96 {
  padding-left: 24rem !important;
  padding-right: 24rem !important;
}

.tw-px-px {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.tw-py-0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.tw-py-0\.5 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}

.tw-py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.tw-py-1\.5 {
  padding-top: 0.375rem !important;
  padding-bottom: 0.375rem !important;
}

.tw-py-10 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.tw-py-11 {
  padding-top: 2.75rem !important;
  padding-bottom: 2.75rem !important;
}

.tw-py-12 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.tw-py-14 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}

.tw-py-16 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.tw-py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.tw-py-2\.5 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.tw-py-20 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.tw-py-24 {
  padding-top: 6rem !important;
  padding-bottom: 6rem !important;
}

.tw-py-28 {
  padding-top: 7rem !important;
  padding-bottom: 7rem !important;
}

.tw-py-3 {
  padding-top: 0.75rem !important;
  padding-bottom: 0.75rem !important;
}

.tw-py-3\.5 {
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
}

.tw-py-32 {
  padding-top: 8rem !important;
  padding-bottom: 8rem !important;
}

.tw-py-36 {
  padding-top: 9rem !important;
  padding-bottom: 9rem !important;
}

.tw-py-4 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.tw-py-40 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.tw-py-44 {
  padding-top: 11rem !important;
  padding-bottom: 11rem !important;
}

.tw-py-48 {
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.tw-py-5 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.tw-py-52 {
  padding-top: 13rem !important;
  padding-bottom: 13rem !important;
}

.tw-py-56 {
  padding-top: 14rem !important;
  padding-bottom: 14rem !important;
}

.tw-py-6 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.tw-py-60 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.tw-py-64 {
  padding-top: 16rem !important;
  padding-bottom: 16rem !important;
}

.tw-py-7 {
  padding-top: 1.75rem !important;
  padding-bottom: 1.75rem !important;
}

.tw-py-72 {
  padding-top: 18rem !important;
  padding-bottom: 18rem !important;
}

.tw-py-8 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.tw-py-80 {
  padding-top: 20rem !important;
  padding-bottom: 20rem !important;
}

.tw-py-9 {
  padding-top: 2.25rem !important;
  padding-bottom: 2.25rem !important;
}

.tw-py-96 {
  padding-top: 24rem !important;
  padding-bottom: 24rem !important;
}

.tw-py-px {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.tw-pb-0 {
  padding-bottom: 0px !important;
}

.tw-pb-0\.5 {
  padding-bottom: 0.125rem !important;
}

.tw-pb-1 {
  padding-bottom: 0.25rem !important;
}

.tw-pb-1\.5 {
  padding-bottom: 0.375rem !important;
}

.tw-pb-10 {
  padding-bottom: 2.5rem !important;
}

.tw-pb-11 {
  padding-bottom: 2.75rem !important;
}

.tw-pb-12 {
  padding-bottom: 3rem !important;
}

.tw-pb-14 {
  padding-bottom: 3.5rem !important;
}

.tw-pb-16 {
  padding-bottom: 4rem !important;
}

.tw-pb-2 {
  padding-bottom: 0.5rem !important;
}

.tw-pb-2\.5 {
  padding-bottom: 0.625rem !important;
}

.tw-pb-20 {
  padding-bottom: 5rem !important;
}

.tw-pb-24 {
  padding-bottom: 6rem !important;
}

.tw-pb-28 {
  padding-bottom: 7rem !important;
}

.tw-pb-3 {
  padding-bottom: 0.75rem !important;
}

.tw-pb-3\.5 {
  padding-bottom: 0.875rem !important;
}

.tw-pb-32 {
  padding-bottom: 8rem !important;
}

.tw-pb-36 {
  padding-bottom: 9rem !important;
}

.tw-pb-4 {
  padding-bottom: 1rem !important;
}

.tw-pb-40 {
  padding-bottom: 10rem !important;
}

.tw-pb-44 {
  padding-bottom: 11rem !important;
}

.tw-pb-48 {
  padding-bottom: 12rem !important;
}

.tw-pb-5 {
  padding-bottom: 1.25rem !important;
}

.tw-pb-52 {
  padding-bottom: 13rem !important;
}

.tw-pb-56 {
  padding-bottom: 14rem !important;
}

.tw-pb-6 {
  padding-bottom: 1.5rem !important;
}

.tw-pb-60 {
  padding-bottom: 15rem !important;
}

.tw-pb-64 {
  padding-bottom: 16rem !important;
}

.tw-pb-7 {
  padding-bottom: 1.75rem !important;
}

.tw-pb-72 {
  padding-bottom: 18rem !important;
}

.tw-pb-8 {
  padding-bottom: 2rem !important;
}

.tw-pb-80 {
  padding-bottom: 20rem !important;
}

.tw-pb-9 {
  padding-bottom: 2.25rem !important;
}

.tw-pb-96 {
  padding-bottom: 24rem !important;
}

.tw-pb-px {
  padding-bottom: 1px !important;
}

.tw-pe-0 {
  padding-inline-end: 0px !important;
}

.tw-pe-0\.5 {
  padding-inline-end: 0.125rem !important;
}

.tw-pe-1 {
  padding-inline-end: 0.25rem !important;
}

.tw-pe-1\.5 {
  padding-inline-end: 0.375rem !important;
}

.tw-pe-10 {
  padding-inline-end: 2.5rem !important;
}

.tw-pe-11 {
  padding-inline-end: 2.75rem !important;
}

.tw-pe-12 {
  padding-inline-end: 3rem !important;
}

.tw-pe-14 {
  padding-inline-end: 3.5rem !important;
}

.tw-pe-16 {
  padding-inline-end: 4rem !important;
}

.tw-pe-2 {
  padding-inline-end: 0.5rem !important;
}

.tw-pe-2\.5 {
  padding-inline-end: 0.625rem !important;
}

.tw-pe-20 {
  padding-inline-end: 5rem !important;
}

.tw-pe-24 {
  padding-inline-end: 6rem !important;
}

.tw-pe-28 {
  padding-inline-end: 7rem !important;
}

.tw-pe-3 {
  padding-inline-end: 0.75rem !important;
}

.tw-pe-3\.5 {
  padding-inline-end: 0.875rem !important;
}

.tw-pe-32 {
  padding-inline-end: 8rem !important;
}

.tw-pe-36 {
  padding-inline-end: 9rem !important;
}

.tw-pe-4 {
  padding-inline-end: 1rem !important;
}

.tw-pe-40 {
  padding-inline-end: 10rem !important;
}

.tw-pe-44 {
  padding-inline-end: 11rem !important;
}

.tw-pe-48 {
  padding-inline-end: 12rem !important;
}

.tw-pe-5 {
  padding-inline-end: 1.25rem !important;
}

.tw-pe-52 {
  padding-inline-end: 13rem !important;
}

.tw-pe-56 {
  padding-inline-end: 14rem !important;
}

.tw-pe-6 {
  padding-inline-end: 1.5rem !important;
}

.tw-pe-60 {
  padding-inline-end: 15rem !important;
}

.tw-pe-64 {
  padding-inline-end: 16rem !important;
}

.tw-pe-7 {
  padding-inline-end: 1.75rem !important;
}

.tw-pe-72 {
  padding-inline-end: 18rem !important;
}

.tw-pe-8 {
  padding-inline-end: 2rem !important;
}

.tw-pe-80 {
  padding-inline-end: 20rem !important;
}

.tw-pe-9 {
  padding-inline-end: 2.25rem !important;
}

.tw-pe-96 {
  padding-inline-end: 24rem !important;
}

.tw-pe-px {
  padding-inline-end: 1px !important;
}

.tw-pl-0 {
  padding-left: 0px !important;
}

.tw-pl-0\.5 {
  padding-left: 0.125rem !important;
}

.tw-pl-1 {
  padding-left: 0.25rem !important;
}

.tw-pl-1\.5 {
  padding-left: 0.375rem !important;
}

.tw-pl-10 {
  padding-left: 2.5rem !important;
}

.tw-pl-11 {
  padding-left: 2.75rem !important;
}

.tw-pl-12 {
  padding-left: 3rem !important;
}

.tw-pl-14 {
  padding-left: 3.5rem !important;
}

.tw-pl-16 {
  padding-left: 4rem !important;
}

.tw-pl-2 {
  padding-left: 0.5rem !important;
}

.tw-pl-2\.5 {
  padding-left: 0.625rem !important;
}

.tw-pl-20 {
  padding-left: 5rem !important;
}

.tw-pl-24 {
  padding-left: 6rem !important;
}

.tw-pl-28 {
  padding-left: 7rem !important;
}

.tw-pl-3 {
  padding-left: 0.75rem !important;
}

.tw-pl-3\.5 {
  padding-left: 0.875rem !important;
}

.tw-pl-32 {
  padding-left: 8rem !important;
}

.tw-pl-36 {
  padding-left: 9rem !important;
}

.tw-pl-4 {
  padding-left: 1rem !important;
}

.tw-pl-40 {
  padding-left: 10rem !important;
}

.tw-pl-44 {
  padding-left: 11rem !important;
}

.tw-pl-48 {
  padding-left: 12rem !important;
}

.tw-pl-5 {
  padding-left: 1.25rem !important;
}

.tw-pl-52 {
  padding-left: 13rem !important;
}

.tw-pl-56 {
  padding-left: 14rem !important;
}

.tw-pl-6 {
  padding-left: 1.5rem !important;
}

.tw-pl-60 {
  padding-left: 15rem !important;
}

.tw-pl-64 {
  padding-left: 16rem !important;
}

.tw-pl-7 {
  padding-left: 1.75rem !important;
}

.tw-pl-72 {
  padding-left: 18rem !important;
}

.tw-pl-8 {
  padding-left: 2rem !important;
}

.tw-pl-80 {
  padding-left: 20rem !important;
}

.tw-pl-9 {
  padding-left: 2.25rem !important;
}

.tw-pl-96 {
  padding-left: 24rem !important;
}

.tw-pl-px {
  padding-left: 1px !important;
}

.tw-pr-0 {
  padding-right: 0px !important;
}

.tw-pr-0\.5 {
  padding-right: 0.125rem !important;
}

.tw-pr-1 {
  padding-right: 0.25rem !important;
}

.tw-pr-1\.5 {
  padding-right: 0.375rem !important;
}

.tw-pr-10 {
  padding-right: 2.5rem !important;
}

.tw-pr-11 {
  padding-right: 2.75rem !important;
}

.tw-pr-12 {
  padding-right: 3rem !important;
}

.tw-pr-14 {
  padding-right: 3.5rem !important;
}

.tw-pr-16 {
  padding-right: 4rem !important;
}

.tw-pr-2 {
  padding-right: 0.5rem !important;
}

.tw-pr-2\.5 {
  padding-right: 0.625rem !important;
}

.tw-pr-20 {
  padding-right: 5rem !important;
}

.tw-pr-24 {
  padding-right: 6rem !important;
}

.tw-pr-28 {
  padding-right: 7rem !important;
}

.tw-pr-3 {
  padding-right: 0.75rem !important;
}

.tw-pr-3\.5 {
  padding-right: 0.875rem !important;
}

.tw-pr-32 {
  padding-right: 8rem !important;
}

.tw-pr-36 {
  padding-right: 9rem !important;
}

.tw-pr-4 {
  padding-right: 1rem !important;
}

.tw-pr-40 {
  padding-right: 10rem !important;
}

.tw-pr-44 {
  padding-right: 11rem !important;
}

.tw-pr-48 {
  padding-right: 12rem !important;
}

.tw-pr-5 {
  padding-right: 1.25rem !important;
}

.tw-pr-52 {
  padding-right: 13rem !important;
}

.tw-pr-56 {
  padding-right: 14rem !important;
}

.tw-pr-6 {
  padding-right: 1.5rem !important;
}

.tw-pr-60 {
  padding-right: 15rem !important;
}

.tw-pr-64 {
  padding-right: 16rem !important;
}

.tw-pr-7 {
  padding-right: 1.75rem !important;
}

.tw-pr-72 {
  padding-right: 18rem !important;
}

.tw-pr-8 {
  padding-right: 2rem !important;
}

.tw-pr-80 {
  padding-right: 20rem !important;
}

.tw-pr-9 {
  padding-right: 2.25rem !important;
}

.tw-pr-96 {
  padding-right: 24rem !important;
}

.tw-pr-px {
  padding-right: 1px !important;
}

.tw-ps-0 {
  padding-inline-start: 0px !important;
}

.tw-ps-0\.5 {
  padding-inline-start: 0.125rem !important;
}

.tw-ps-1 {
  padding-inline-start: 0.25rem !important;
}

.tw-ps-1\.5 {
  padding-inline-start: 0.375rem !important;
}

.tw-ps-10 {
  padding-inline-start: 2.5rem !important;
}

.tw-ps-11 {
  padding-inline-start: 2.75rem !important;
}

.tw-ps-12 {
  padding-inline-start: 3rem !important;
}

.tw-ps-14 {
  padding-inline-start: 3.5rem !important;
}

.tw-ps-16 {
  padding-inline-start: 4rem !important;
}

.tw-ps-2 {
  padding-inline-start: 0.5rem !important;
}

.tw-ps-2\.5 {
  padding-inline-start: 0.625rem !important;
}

.tw-ps-20 {
  padding-inline-start: 5rem !important;
}

.tw-ps-24 {
  padding-inline-start: 6rem !important;
}

.tw-ps-28 {
  padding-inline-start: 7rem !important;
}

.tw-ps-3 {
  padding-inline-start: 0.75rem !important;
}

.tw-ps-3\.5 {
  padding-inline-start: 0.875rem !important;
}

.tw-ps-32 {
  padding-inline-start: 8rem !important;
}

.tw-ps-36 {
  padding-inline-start: 9rem !important;
}

.tw-ps-4 {
  padding-inline-start: 1rem !important;
}

.tw-ps-40 {
  padding-inline-start: 10rem !important;
}

.tw-ps-44 {
  padding-inline-start: 11rem !important;
}

.tw-ps-48 {
  padding-inline-start: 12rem !important;
}

.tw-ps-5 {
  padding-inline-start: 1.25rem !important;
}

.tw-ps-52 {
  padding-inline-start: 13rem !important;
}

.tw-ps-56 {
  padding-inline-start: 14rem !important;
}

.tw-ps-6 {
  padding-inline-start: 1.5rem !important;
}

.tw-ps-60 {
  padding-inline-start: 15rem !important;
}

.tw-ps-64 {
  padding-inline-start: 16rem !important;
}

.tw-ps-7 {
  padding-inline-start: 1.75rem !important;
}

.tw-ps-72 {
  padding-inline-start: 18rem !important;
}

.tw-ps-8 {
  padding-inline-start: 2rem !important;
}

.tw-ps-80 {
  padding-inline-start: 20rem !important;
}

.tw-ps-9 {
  padding-inline-start: 2.25rem !important;
}

.tw-ps-96 {
  padding-inline-start: 24rem !important;
}

.tw-ps-px {
  padding-inline-start: 1px !important;
}

.tw-pt-0 {
  padding-top: 0px !important;
}

.tw-pt-0\.5 {
  padding-top: 0.125rem !important;
}

.tw-pt-1 {
  padding-top: 0.25rem !important;
}

.tw-pt-1\.5 {
  padding-top: 0.375rem !important;
}

.tw-pt-10 {
  padding-top: 2.5rem !important;
}

.tw-pt-11 {
  padding-top: 2.75rem !important;
}

.tw-pt-12 {
  padding-top: 3rem !important;
}

.tw-pt-14 {
  padding-top: 3.5rem !important;
}

.tw-pt-16 {
  padding-top: 4rem !important;
}

.tw-pt-2 {
  padding-top: 0.5rem !important;
}

.tw-pt-2\.5 {
  padding-top: 0.625rem !important;
}

.tw-pt-20 {
  padding-top: 5rem !important;
}

.tw-pt-24 {
  padding-top: 6rem !important;
}

.tw-pt-28 {
  padding-top: 7rem !important;
}

.tw-pt-3 {
  padding-top: 0.75rem !important;
}

.tw-pt-3\.5 {
  padding-top: 0.875rem !important;
}

.tw-pt-32 {
  padding-top: 8rem !important;
}

.tw-pt-36 {
  padding-top: 9rem !important;
}

.tw-pt-4 {
  padding-top: 1rem !important;
}

.tw-pt-40 {
  padding-top: 10rem !important;
}

.tw-pt-44 {
  padding-top: 11rem !important;
}

.tw-pt-48 {
  padding-top: 12rem !important;
}

.tw-pt-5 {
  padding-top: 1.25rem !important;
}

.tw-pt-52 {
  padding-top: 13rem !important;
}

.tw-pt-56 {
  padding-top: 14rem !important;
}

.tw-pt-6 {
  padding-top: 1.5rem !important;
}

.tw-pt-60 {
  padding-top: 15rem !important;
}

.tw-pt-64 {
  padding-top: 16rem !important;
}

.tw-pt-7 {
  padding-top: 1.75rem !important;
}

.tw-pt-72 {
  padding-top: 18rem !important;
}

.tw-pt-8 {
  padding-top: 2rem !important;
}

.tw-pt-80 {
  padding-top: 20rem !important;
}

.tw-pt-9 {
  padding-top: 2.25rem !important;
}

.tw-pt-96 {
  padding-top: 24rem !important;
}

.tw-pt-px {
  padding-top: 1px !important;
}

.tw-text-left {
  text-align: left !important;
}

.tw-text-center {
  text-align: center !important;
}

.tw-text-right {
  text-align: right !important;
}

.tw-text-justify {
  text-align: justify !important;
}

.tw-text-start {
  text-align: start !important;
}

.tw-text-end {
  text-align: end !important;
}

.-tw-indent-0 {
  text-indent: -0px !important;
}

.-tw-indent-0\.5 {
  text-indent: -0.125rem !important;
}

.-tw-indent-1 {
  text-indent: -0.25rem !important;
}

.-tw-indent-1\.5 {
  text-indent: -0.375rem !important;
}

.-tw-indent-10 {
  text-indent: -2.5rem !important;
}

.-tw-indent-11 {
  text-indent: -2.75rem !important;
}

.-tw-indent-12 {
  text-indent: -3rem !important;
}

.-tw-indent-14 {
  text-indent: -3.5rem !important;
}

.-tw-indent-16 {
  text-indent: -4rem !important;
}

.-tw-indent-2 {
  text-indent: -0.5rem !important;
}

.-tw-indent-2\.5 {
  text-indent: -0.625rem !important;
}

.-tw-indent-20 {
  text-indent: -5rem !important;
}

.-tw-indent-24 {
  text-indent: -6rem !important;
}

.-tw-indent-28 {
  text-indent: -7rem !important;
}

.-tw-indent-3 {
  text-indent: -0.75rem !important;
}

.-tw-indent-3\.5 {
  text-indent: -0.875rem !important;
}

.-tw-indent-32 {
  text-indent: -8rem !important;
}

.-tw-indent-36 {
  text-indent: -9rem !important;
}

.-tw-indent-4 {
  text-indent: -1rem !important;
}

.-tw-indent-40 {
  text-indent: -10rem !important;
}

.-tw-indent-44 {
  text-indent: -11rem !important;
}

.-tw-indent-48 {
  text-indent: -12rem !important;
}

.-tw-indent-5 {
  text-indent: -1.25rem !important;
}

.-tw-indent-52 {
  text-indent: -13rem !important;
}

.-tw-indent-56 {
  text-indent: -14rem !important;
}

.-tw-indent-6 {
  text-indent: -1.5rem !important;
}

.-tw-indent-60 {
  text-indent: -15rem !important;
}

.-tw-indent-64 {
  text-indent: -16rem !important;
}

.-tw-indent-7 {
  text-indent: -1.75rem !important;
}

.-tw-indent-72 {
  text-indent: -18rem !important;
}

.-tw-indent-8 {
  text-indent: -2rem !important;
}

.-tw-indent-80 {
  text-indent: -20rem !important;
}

.-tw-indent-9 {
  text-indent: -2.25rem !important;
}

.-tw-indent-96 {
  text-indent: -24rem !important;
}

.-tw-indent-px {
  text-indent: -1px !important;
}

.tw--indent-0 {
  text-indent: -0px !important;
}

.tw--indent-0\.5 {
  text-indent: -0.125rem !important;
}

.tw--indent-1 {
  text-indent: -0.25rem !important;
}

.tw--indent-1\.5 {
  text-indent: -0.375rem !important;
}

.tw--indent-10 {
  text-indent: -2.5rem !important;
}

.tw--indent-11 {
  text-indent: -2.75rem !important;
}

.tw--indent-12 {
  text-indent: -3rem !important;
}

.tw--indent-14 {
  text-indent: -3.5rem !important;
}

.tw--indent-16 {
  text-indent: -4rem !important;
}

.tw--indent-2 {
  text-indent: -0.5rem !important;
}

.tw--indent-2\.5 {
  text-indent: -0.625rem !important;
}

.tw--indent-20 {
  text-indent: -5rem !important;
}

.tw--indent-24 {
  text-indent: -6rem !important;
}

.tw--indent-28 {
  text-indent: -7rem !important;
}

.tw--indent-3 {
  text-indent: -0.75rem !important;
}

.tw--indent-3\.5 {
  text-indent: -0.875rem !important;
}

.tw--indent-32 {
  text-indent: -8rem !important;
}

.tw--indent-36 {
  text-indent: -9rem !important;
}

.tw--indent-4 {
  text-indent: -1rem !important;
}

.tw--indent-40 {
  text-indent: -10rem !important;
}

.tw--indent-44 {
  text-indent: -11rem !important;
}

.tw--indent-48 {
  text-indent: -12rem !important;
}

.tw--indent-5 {
  text-indent: -1.25rem !important;
}

.tw--indent-52 {
  text-indent: -13rem !important;
}

.tw--indent-56 {
  text-indent: -14rem !important;
}

.tw--indent-6 {
  text-indent: -1.5rem !important;
}

.tw--indent-60 {
  text-indent: -15rem !important;
}

.tw--indent-64 {
  text-indent: -16rem !important;
}

.tw--indent-7 {
  text-indent: -1.75rem !important;
}

.tw--indent-72 {
  text-indent: -18rem !important;
}

.tw--indent-8 {
  text-indent: -2rem !important;
}

.tw--indent-80 {
  text-indent: -20rem !important;
}

.tw--indent-9 {
  text-indent: -2.25rem !important;
}

.tw--indent-96 {
  text-indent: -24rem !important;
}

.tw--indent-px {
  text-indent: -1px !important;
}

.tw-indent-0 {
  text-indent: 0px !important;
}

.tw-indent-0\.5 {
  text-indent: 0.125rem !important;
}

.tw-indent-1 {
  text-indent: 0.25rem !important;
}

.tw-indent-1\.5 {
  text-indent: 0.375rem !important;
}

.tw-indent-10 {
  text-indent: 2.5rem !important;
}

.tw-indent-11 {
  text-indent: 2.75rem !important;
}

.tw-indent-12 {
  text-indent: 3rem !important;
}

.tw-indent-14 {
  text-indent: 3.5rem !important;
}

.tw-indent-16 {
  text-indent: 4rem !important;
}

.tw-indent-2 {
  text-indent: 0.5rem !important;
}

.tw-indent-2\.5 {
  text-indent: 0.625rem !important;
}

.tw-indent-20 {
  text-indent: 5rem !important;
}

.tw-indent-24 {
  text-indent: 6rem !important;
}

.tw-indent-28 {
  text-indent: 7rem !important;
}

.tw-indent-3 {
  text-indent: 0.75rem !important;
}

.tw-indent-3\.5 {
  text-indent: 0.875rem !important;
}

.tw-indent-32 {
  text-indent: 8rem !important;
}

.tw-indent-36 {
  text-indent: 9rem !important;
}

.tw-indent-4 {
  text-indent: 1rem !important;
}

.tw-indent-40 {
  text-indent: 10rem !important;
}

.tw-indent-44 {
  text-indent: 11rem !important;
}

.tw-indent-48 {
  text-indent: 12rem !important;
}

.tw-indent-5 {
  text-indent: 1.25rem !important;
}

.tw-indent-52 {
  text-indent: 13rem !important;
}

.tw-indent-56 {
  text-indent: 14rem !important;
}

.tw-indent-6 {
  text-indent: 1.5rem !important;
}

.tw-indent-60 {
  text-indent: 15rem !important;
}

.tw-indent-64 {
  text-indent: 16rem !important;
}

.tw-indent-7 {
  text-indent: 1.75rem !important;
}

.tw-indent-72 {
  text-indent: 18rem !important;
}

.tw-indent-8 {
  text-indent: 2rem !important;
}

.tw-indent-80 {
  text-indent: 20rem !important;
}

.tw-indent-9 {
  text-indent: 2.25rem !important;
}

.tw-indent-96 {
  text-indent: 24rem !important;
}

.tw-indent-px {
  text-indent: 1px !important;
}

.tw-align-baseline {
  vertical-align: baseline !important;
}

.tw-align-top {
  vertical-align: top !important;
}

.tw-align-middle {
  vertical-align: middle !important;
}

.tw-align-bottom {
  vertical-align: bottom !important;
}

.tw-align-text-top {
  vertical-align: text-top !important;
}

.tw-align-text-bottom {
  vertical-align: text-bottom !important;
}

.tw-align-sub {
  vertical-align: sub !important;
}

.tw-align-super {
  vertical-align: super !important;
}

.tw-font-mono {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.tw-font-sans {
  font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
}

.tw-font-serif {
  font-family: ui-serif, Georgia, Cambria, "Times New Roman", Times, serif !important;
}

.tw-text-2xl {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
}

.tw-text-3xl {
  font-size: 1.875rem !important;
  line-height: 2.25rem !important;
}

.tw-text-4xl {
  font-size: 2.25rem !important;
  line-height: 2.5rem !important;
}

.tw-text-5xl {
  font-size: 3rem !important;
  line-height: 1 !important;
}

.tw-text-6xl {
  font-size: 3.75rem !important;
  line-height: 1 !important;
}

.tw-text-7xl {
  font-size: 4.5rem !important;
  line-height: 1 !important;
}

.tw-text-8xl {
  font-size: 6rem !important;
  line-height: 1 !important;
}

.tw-text-9xl {
  font-size: 8rem !important;
  line-height: 1 !important;
}

.tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.tw-text-lg {
  font-size: 1.125rem !important;
  line-height: 1.75rem !important;
}

.tw-text-sm {
  font-size: 0.875rem !important;
  line-height: 1.25rem !important;
}

.tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.tw-text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important;
}

.tw-font-black {
  font-weight: 900 !important;
}

.tw-font-bold {
  font-weight: 700 !important;
}

.tw-font-extrabold {
  font-weight: 800 !important;
}

.tw-font-extralight {
  font-weight: 200 !important;
}

.tw-font-light {
  font-weight: 300 !important;
}

.tw-font-medium {
  font-weight: 500 !important;
}

.tw-font-normal {
  font-weight: 400 !important;
}

.tw-font-semibold {
  font-weight: 600 !important;
}

.tw-font-thin {
  font-weight: 100 !important;
}

.tw-uppercase {
  text-transform: uppercase !important;
}

.tw-lowercase {
  text-transform: lowercase !important;
}

.tw-capitalize {
  text-transform: capitalize !important;
}

.tw-normal-case {
  text-transform: none !important;
}

.tw-italic {
  font-style: italic !important;
}

.tw-not-italic {
  font-style: normal !important;
}

.tw-normal-nums {
  font-variant-numeric: normal !important;
}

.tw-ordinal {
  --tw-ordinal: ordinal !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-slashed-zero {
  --tw-slashed-zero: slashed-zero !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-lining-nums {
  --tw-numeric-figure: lining-nums !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-oldstyle-nums {
  --tw-numeric-figure: oldstyle-nums !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-proportional-nums {
  --tw-numeric-spacing: proportional-nums !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-tabular-nums {
  --tw-numeric-spacing: tabular-nums !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-diagonal-fractions {
  --tw-numeric-fraction: diagonal-fractions !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-stacked-fractions {
  --tw-numeric-fraction: stacked-fractions !important;
  font-variant-numeric: var(--tw-ordinal) var(--tw-slashed-zero) var(--tw-numeric-figure) var(--tw-numeric-spacing) var(--tw-numeric-fraction) !important;
}

.tw-leading-10 {
  line-height: 2.5rem !important;
}

.tw-leading-3 {
  line-height: .75rem !important;
}

.tw-leading-4 {
  line-height: 1rem !important;
}

.tw-leading-5 {
  line-height: 1.25rem !important;
}

.tw-leading-6 {
  line-height: 1.5rem !important;
}

.tw-leading-7 {
  line-height: 1.75rem !important;
}

.tw-leading-8 {
  line-height: 2rem !important;
}

.tw-leading-9 {
  line-height: 2.25rem !important;
}

.tw-leading-loose {
  line-height: 2 !important;
}

.tw-leading-none {
  line-height: 1 !important;
}

.tw-leading-normal {
  line-height: 1.5 !important;
}

.tw-leading-relaxed {
  line-height: 1.625 !important;
}

.tw-leading-snug {
  line-height: 1.375 !important;
}

.tw-leading-tight {
  line-height: 1.25 !important;
}

.-tw-tracking-normal {
  letter-spacing: -0em !important;
}

.-tw-tracking-tight {
  letter-spacing: 0.025em !important;
}

.-tw-tracking-tighter {
  letter-spacing: 0.05em !important;
}

.-tw-tracking-wide {
  letter-spacing: -0.025em !important;
}

.-tw-tracking-wider {
  letter-spacing: -0.05em !important;
}

.-tw-tracking-widest {
  letter-spacing: -0.1em !important;
}

.tw--tracking-normal {
  letter-spacing: -0em !important;
}

.tw--tracking-tight {
  letter-spacing: 0.025em !important;
}

.tw--tracking-tighter {
  letter-spacing: 0.05em !important;
}

.tw--tracking-wide {
  letter-spacing: -0.025em !important;
}

.tw--tracking-wider {
  letter-spacing: -0.05em !important;
}

.tw--tracking-widest {
  letter-spacing: -0.1em !important;
}

.tw-tracking-normal {
  letter-spacing: 0em !important;
}

.tw-tracking-tight {
  letter-spacing: -0.025em !important;
}

.tw-tracking-tighter {
  letter-spacing: -0.05em !important;
}

.tw-tracking-wide {
  letter-spacing: 0.025em !important;
}

.tw-tracking-wider {
  letter-spacing: 0.05em !important;
}

.tw-tracking-widest {
  letter-spacing: 0.1em !important;
}

.tw-text-amber-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 243 199 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-100\/0 {
  color: rgb(254 243 199 / 0) !important;
}

.tw-text-amber-100\/10 {
  color: rgb(254 243 199 / 0.1) !important;
}

.tw-text-amber-100\/100 {
  color: rgb(254 243 199 / 1) !important;
}

.tw-text-amber-100\/15 {
  color: rgb(254 243 199 / 0.15) !important;
}

.tw-text-amber-100\/20 {
  color: rgb(254 243 199 / 0.2) !important;
}

.tw-text-amber-100\/25 {
  color: rgb(254 243 199 / 0.25) !important;
}

.tw-text-amber-100\/30 {
  color: rgb(254 243 199 / 0.3) !important;
}

.tw-text-amber-100\/35 {
  color: rgb(254 243 199 / 0.35) !important;
}

.tw-text-amber-100\/40 {
  color: rgb(254 243 199 / 0.4) !important;
}

.tw-text-amber-100\/45 {
  color: rgb(254 243 199 / 0.45) !important;
}

.tw-text-amber-100\/5 {
  color: rgb(254 243 199 / 0.05) !important;
}

.tw-text-amber-100\/50 {
  color: rgb(254 243 199 / 0.5) !important;
}

.tw-text-amber-100\/55 {
  color: rgb(254 243 199 / 0.55) !important;
}

.tw-text-amber-100\/60 {
  color: rgb(254 243 199 / 0.6) !important;
}

.tw-text-amber-100\/65 {
  color: rgb(254 243 199 / 0.65) !important;
}

.tw-text-amber-100\/70 {
  color: rgb(254 243 199 / 0.7) !important;
}

.tw-text-amber-100\/75 {
  color: rgb(254 243 199 / 0.75) !important;
}

.tw-text-amber-100\/80 {
  color: rgb(254 243 199 / 0.8) !important;
}

.tw-text-amber-100\/85 {
  color: rgb(254 243 199 / 0.85) !important;
}

.tw-text-amber-100\/90 {
  color: rgb(254 243 199 / 0.9) !important;
}

.tw-text-amber-100\/95 {
  color: rgb(254 243 199 / 0.95) !important;
}

.tw-text-amber-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 230 138 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-200\/0 {
  color: rgb(253 230 138 / 0) !important;
}

.tw-text-amber-200\/10 {
  color: rgb(253 230 138 / 0.1) !important;
}

.tw-text-amber-200\/100 {
  color: rgb(253 230 138 / 1) !important;
}

.tw-text-amber-200\/15 {
  color: rgb(253 230 138 / 0.15) !important;
}

.tw-text-amber-200\/20 {
  color: rgb(253 230 138 / 0.2) !important;
}

.tw-text-amber-200\/25 {
  color: rgb(253 230 138 / 0.25) !important;
}

.tw-text-amber-200\/30 {
  color: rgb(253 230 138 / 0.3) !important;
}

.tw-text-amber-200\/35 {
  color: rgb(253 230 138 / 0.35) !important;
}

.tw-text-amber-200\/40 {
  color: rgb(253 230 138 / 0.4) !important;
}

.tw-text-amber-200\/45 {
  color: rgb(253 230 138 / 0.45) !important;
}

.tw-text-amber-200\/5 {
  color: rgb(253 230 138 / 0.05) !important;
}

.tw-text-amber-200\/50 {
  color: rgb(253 230 138 / 0.5) !important;
}

.tw-text-amber-200\/55 {
  color: rgb(253 230 138 / 0.55) !important;
}

.tw-text-amber-200\/60 {
  color: rgb(253 230 138 / 0.6) !important;
}

.tw-text-amber-200\/65 {
  color: rgb(253 230 138 / 0.65) !important;
}

.tw-text-amber-200\/70 {
  color: rgb(253 230 138 / 0.7) !important;
}

.tw-text-amber-200\/75 {
  color: rgb(253 230 138 / 0.75) !important;
}

.tw-text-amber-200\/80 {
  color: rgb(253 230 138 / 0.8) !important;
}

.tw-text-amber-200\/85 {
  color: rgb(253 230 138 / 0.85) !important;
}

.tw-text-amber-200\/90 {
  color: rgb(253 230 138 / 0.9) !important;
}

.tw-text-amber-200\/95 {
  color: rgb(253 230 138 / 0.95) !important;
}

.tw-text-amber-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(252 211 77 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-300\/0 {
  color: rgb(252 211 77 / 0) !important;
}

.tw-text-amber-300\/10 {
  color: rgb(252 211 77 / 0.1) !important;
}

.tw-text-amber-300\/100 {
  color: rgb(252 211 77 / 1) !important;
}

.tw-text-amber-300\/15 {
  color: rgb(252 211 77 / 0.15) !important;
}

.tw-text-amber-300\/20 {
  color: rgb(252 211 77 / 0.2) !important;
}

.tw-text-amber-300\/25 {
  color: rgb(252 211 77 / 0.25) !important;
}

.tw-text-amber-300\/30 {
  color: rgb(252 211 77 / 0.3) !important;
}

.tw-text-amber-300\/35 {
  color: rgb(252 211 77 / 0.35) !important;
}

.tw-text-amber-300\/40 {
  color: rgb(252 211 77 / 0.4) !important;
}

.tw-text-amber-300\/45 {
  color: rgb(252 211 77 / 0.45) !important;
}

.tw-text-amber-300\/5 {
  color: rgb(252 211 77 / 0.05) !important;
}

.tw-text-amber-300\/50 {
  color: rgb(252 211 77 / 0.5) !important;
}

.tw-text-amber-300\/55 {
  color: rgb(252 211 77 / 0.55) !important;
}

.tw-text-amber-300\/60 {
  color: rgb(252 211 77 / 0.6) !important;
}

.tw-text-amber-300\/65 {
  color: rgb(252 211 77 / 0.65) !important;
}

.tw-text-amber-300\/70 {
  color: rgb(252 211 77 / 0.7) !important;
}

.tw-text-amber-300\/75 {
  color: rgb(252 211 77 / 0.75) !important;
}

.tw-text-amber-300\/80 {
  color: rgb(252 211 77 / 0.8) !important;
}

.tw-text-amber-300\/85 {
  color: rgb(252 211 77 / 0.85) !important;
}

.tw-text-amber-300\/90 {
  color: rgb(252 211 77 / 0.9) !important;
}

.tw-text-amber-300\/95 {
  color: rgb(252 211 77 / 0.95) !important;
}

.tw-text-amber-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 191 36 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-400\/0 {
  color: rgb(251 191 36 / 0) !important;
}

.tw-text-amber-400\/10 {
  color: rgb(251 191 36 / 0.1) !important;
}

.tw-text-amber-400\/100 {
  color: rgb(251 191 36 / 1) !important;
}

.tw-text-amber-400\/15 {
  color: rgb(251 191 36 / 0.15) !important;
}

.tw-text-amber-400\/20 {
  color: rgb(251 191 36 / 0.2) !important;
}

.tw-text-amber-400\/25 {
  color: rgb(251 191 36 / 0.25) !important;
}

.tw-text-amber-400\/30 {
  color: rgb(251 191 36 / 0.3) !important;
}

.tw-text-amber-400\/35 {
  color: rgb(251 191 36 / 0.35) !important;
}

.tw-text-amber-400\/40 {
  color: rgb(251 191 36 / 0.4) !important;
}

.tw-text-amber-400\/45 {
  color: rgb(251 191 36 / 0.45) !important;
}

.tw-text-amber-400\/5 {
  color: rgb(251 191 36 / 0.05) !important;
}

.tw-text-amber-400\/50 {
  color: rgb(251 191 36 / 0.5) !important;
}

.tw-text-amber-400\/55 {
  color: rgb(251 191 36 / 0.55) !important;
}

.tw-text-amber-400\/60 {
  color: rgb(251 191 36 / 0.6) !important;
}

.tw-text-amber-400\/65 {
  color: rgb(251 191 36 / 0.65) !important;
}

.tw-text-amber-400\/70 {
  color: rgb(251 191 36 / 0.7) !important;
}

.tw-text-amber-400\/75 {
  color: rgb(251 191 36 / 0.75) !important;
}

.tw-text-amber-400\/80 {
  color: rgb(251 191 36 / 0.8) !important;
}

.tw-text-amber-400\/85 {
  color: rgb(251 191 36 / 0.85) !important;
}

.tw-text-amber-400\/90 {
  color: rgb(251 191 36 / 0.9) !important;
}

.tw-text-amber-400\/95 {
  color: rgb(251 191 36 / 0.95) !important;
}

.tw-text-amber-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 251 235 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-50\/0 {
  color: rgb(255 251 235 / 0) !important;
}

.tw-text-amber-50\/10 {
  color: rgb(255 251 235 / 0.1) !important;
}

.tw-text-amber-50\/100 {
  color: rgb(255 251 235 / 1) !important;
}

.tw-text-amber-50\/15 {
  color: rgb(255 251 235 / 0.15) !important;
}

.tw-text-amber-50\/20 {
  color: rgb(255 251 235 / 0.2) !important;
}

.tw-text-amber-50\/25 {
  color: rgb(255 251 235 / 0.25) !important;
}

.tw-text-amber-50\/30 {
  color: rgb(255 251 235 / 0.3) !important;
}

.tw-text-amber-50\/35 {
  color: rgb(255 251 235 / 0.35) !important;
}

.tw-text-amber-50\/40 {
  color: rgb(255 251 235 / 0.4) !important;
}

.tw-text-amber-50\/45 {
  color: rgb(255 251 235 / 0.45) !important;
}

.tw-text-amber-50\/5 {
  color: rgb(255 251 235 / 0.05) !important;
}

.tw-text-amber-50\/50 {
  color: rgb(255 251 235 / 0.5) !important;
}

.tw-text-amber-50\/55 {
  color: rgb(255 251 235 / 0.55) !important;
}

.tw-text-amber-50\/60 {
  color: rgb(255 251 235 / 0.6) !important;
}

.tw-text-amber-50\/65 {
  color: rgb(255 251 235 / 0.65) !important;
}

.tw-text-amber-50\/70 {
  color: rgb(255 251 235 / 0.7) !important;
}

.tw-text-amber-50\/75 {
  color: rgb(255 251 235 / 0.75) !important;
}

.tw-text-amber-50\/80 {
  color: rgb(255 251 235 / 0.8) !important;
}

.tw-text-amber-50\/85 {
  color: rgb(255 251 235 / 0.85) !important;
}

.tw-text-amber-50\/90 {
  color: rgb(255 251 235 / 0.9) !important;
}

.tw-text-amber-50\/95 {
  color: rgb(255 251 235 / 0.95) !important;
}

.tw-text-amber-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 158 11 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-500\/0 {
  color: rgb(245 158 11 / 0) !important;
}

.tw-text-amber-500\/10 {
  color: rgb(245 158 11 / 0.1) !important;
}

.tw-text-amber-500\/100 {
  color: rgb(245 158 11 / 1) !important;
}

.tw-text-amber-500\/15 {
  color: rgb(245 158 11 / 0.15) !important;
}

.tw-text-amber-500\/20 {
  color: rgb(245 158 11 / 0.2) !important;
}

.tw-text-amber-500\/25 {
  color: rgb(245 158 11 / 0.25) !important;
}

.tw-text-amber-500\/30 {
  color: rgb(245 158 11 / 0.3) !important;
}

.tw-text-amber-500\/35 {
  color: rgb(245 158 11 / 0.35) !important;
}

.tw-text-amber-500\/40 {
  color: rgb(245 158 11 / 0.4) !important;
}

.tw-text-amber-500\/45 {
  color: rgb(245 158 11 / 0.45) !important;
}

.tw-text-amber-500\/5 {
  color: rgb(245 158 11 / 0.05) !important;
}

.tw-text-amber-500\/50 {
  color: rgb(245 158 11 / 0.5) !important;
}

.tw-text-amber-500\/55 {
  color: rgb(245 158 11 / 0.55) !important;
}

.tw-text-amber-500\/60 {
  color: rgb(245 158 11 / 0.6) !important;
}

.tw-text-amber-500\/65 {
  color: rgb(245 158 11 / 0.65) !important;
}

.tw-text-amber-500\/70 {
  color: rgb(245 158 11 / 0.7) !important;
}

.tw-text-amber-500\/75 {
  color: rgb(245 158 11 / 0.75) !important;
}

.tw-text-amber-500\/80 {
  color: rgb(245 158 11 / 0.8) !important;
}

.tw-text-amber-500\/85 {
  color: rgb(245 158 11 / 0.85) !important;
}

.tw-text-amber-500\/90 {
  color: rgb(245 158 11 / 0.9) !important;
}

.tw-text-amber-500\/95 {
  color: rgb(245 158 11 / 0.95) !important;
}

.tw-text-amber-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(217 119 6 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-600\/0 {
  color: rgb(217 119 6 / 0) !important;
}

.tw-text-amber-600\/10 {
  color: rgb(217 119 6 / 0.1) !important;
}

.tw-text-amber-600\/100 {
  color: rgb(217 119 6 / 1) !important;
}

.tw-text-amber-600\/15 {
  color: rgb(217 119 6 / 0.15) !important;
}

.tw-text-amber-600\/20 {
  color: rgb(217 119 6 / 0.2) !important;
}

.tw-text-amber-600\/25 {
  color: rgb(217 119 6 / 0.25) !important;
}

.tw-text-amber-600\/30 {
  color: rgb(217 119 6 / 0.3) !important;
}

.tw-text-amber-600\/35 {
  color: rgb(217 119 6 / 0.35) !important;
}

.tw-text-amber-600\/40 {
  color: rgb(217 119 6 / 0.4) !important;
}

.tw-text-amber-600\/45 {
  color: rgb(217 119 6 / 0.45) !important;
}

.tw-text-amber-600\/5 {
  color: rgb(217 119 6 / 0.05) !important;
}

.tw-text-amber-600\/50 {
  color: rgb(217 119 6 / 0.5) !important;
}

.tw-text-amber-600\/55 {
  color: rgb(217 119 6 / 0.55) !important;
}

.tw-text-amber-600\/60 {
  color: rgb(217 119 6 / 0.6) !important;
}

.tw-text-amber-600\/65 {
  color: rgb(217 119 6 / 0.65) !important;
}

.tw-text-amber-600\/70 {
  color: rgb(217 119 6 / 0.7) !important;
}

.tw-text-amber-600\/75 {
  color: rgb(217 119 6 / 0.75) !important;
}

.tw-text-amber-600\/80 {
  color: rgb(217 119 6 / 0.8) !important;
}

.tw-text-amber-600\/85 {
  color: rgb(217 119 6 / 0.85) !important;
}

.tw-text-amber-600\/90 {
  color: rgb(217 119 6 / 0.9) !important;
}

.tw-text-amber-600\/95 {
  color: rgb(217 119 6 / 0.95) !important;
}

.tw-text-amber-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(180 83 9 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-700\/0 {
  color: rgb(180 83 9 / 0) !important;
}

.tw-text-amber-700\/10 {
  color: rgb(180 83 9 / 0.1) !important;
}

.tw-text-amber-700\/100 {
  color: rgb(180 83 9 / 1) !important;
}

.tw-text-amber-700\/15 {
  color: rgb(180 83 9 / 0.15) !important;
}

.tw-text-amber-700\/20 {
  color: rgb(180 83 9 / 0.2) !important;
}

.tw-text-amber-700\/25 {
  color: rgb(180 83 9 / 0.25) !important;
}

.tw-text-amber-700\/30 {
  color: rgb(180 83 9 / 0.3) !important;
}

.tw-text-amber-700\/35 {
  color: rgb(180 83 9 / 0.35) !important;
}

.tw-text-amber-700\/40 {
  color: rgb(180 83 9 / 0.4) !important;
}

.tw-text-amber-700\/45 {
  color: rgb(180 83 9 / 0.45) !important;
}

.tw-text-amber-700\/5 {
  color: rgb(180 83 9 / 0.05) !important;
}

.tw-text-amber-700\/50 {
  color: rgb(180 83 9 / 0.5) !important;
}

.tw-text-amber-700\/55 {
  color: rgb(180 83 9 / 0.55) !important;
}

.tw-text-amber-700\/60 {
  color: rgb(180 83 9 / 0.6) !important;
}

.tw-text-amber-700\/65 {
  color: rgb(180 83 9 / 0.65) !important;
}

.tw-text-amber-700\/70 {
  color: rgb(180 83 9 / 0.7) !important;
}

.tw-text-amber-700\/75 {
  color: rgb(180 83 9 / 0.75) !important;
}

.tw-text-amber-700\/80 {
  color: rgb(180 83 9 / 0.8) !important;
}

.tw-text-amber-700\/85 {
  color: rgb(180 83 9 / 0.85) !important;
}

.tw-text-amber-700\/90 {
  color: rgb(180 83 9 / 0.9) !important;
}

.tw-text-amber-700\/95 {
  color: rgb(180 83 9 / 0.95) !important;
}

.tw-text-amber-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(146 64 14 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-800\/0 {
  color: rgb(146 64 14 / 0) !important;
}

.tw-text-amber-800\/10 {
  color: rgb(146 64 14 / 0.1) !important;
}

.tw-text-amber-800\/100 {
  color: rgb(146 64 14 / 1) !important;
}

.tw-text-amber-800\/15 {
  color: rgb(146 64 14 / 0.15) !important;
}

.tw-text-amber-800\/20 {
  color: rgb(146 64 14 / 0.2) !important;
}

.tw-text-amber-800\/25 {
  color: rgb(146 64 14 / 0.25) !important;
}

.tw-text-amber-800\/30 {
  color: rgb(146 64 14 / 0.3) !important;
}

.tw-text-amber-800\/35 {
  color: rgb(146 64 14 / 0.35) !important;
}

.tw-text-amber-800\/40 {
  color: rgb(146 64 14 / 0.4) !important;
}

.tw-text-amber-800\/45 {
  color: rgb(146 64 14 / 0.45) !important;
}

.tw-text-amber-800\/5 {
  color: rgb(146 64 14 / 0.05) !important;
}

.tw-text-amber-800\/50 {
  color: rgb(146 64 14 / 0.5) !important;
}

.tw-text-amber-800\/55 {
  color: rgb(146 64 14 / 0.55) !important;
}

.tw-text-amber-800\/60 {
  color: rgb(146 64 14 / 0.6) !important;
}

.tw-text-amber-800\/65 {
  color: rgb(146 64 14 / 0.65) !important;
}

.tw-text-amber-800\/70 {
  color: rgb(146 64 14 / 0.7) !important;
}

.tw-text-amber-800\/75 {
  color: rgb(146 64 14 / 0.75) !important;
}

.tw-text-amber-800\/80 {
  color: rgb(146 64 14 / 0.8) !important;
}

.tw-text-amber-800\/85 {
  color: rgb(146 64 14 / 0.85) !important;
}

.tw-text-amber-800\/90 {
  color: rgb(146 64 14 / 0.9) !important;
}

.tw-text-amber-800\/95 {
  color: rgb(146 64 14 / 0.95) !important;
}

.tw-text-amber-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(120 53 15 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-900\/0 {
  color: rgb(120 53 15 / 0) !important;
}

.tw-text-amber-900\/10 {
  color: rgb(120 53 15 / 0.1) !important;
}

.tw-text-amber-900\/100 {
  color: rgb(120 53 15 / 1) !important;
}

.tw-text-amber-900\/15 {
  color: rgb(120 53 15 / 0.15) !important;
}

.tw-text-amber-900\/20 {
  color: rgb(120 53 15 / 0.2) !important;
}

.tw-text-amber-900\/25 {
  color: rgb(120 53 15 / 0.25) !important;
}

.tw-text-amber-900\/30 {
  color: rgb(120 53 15 / 0.3) !important;
}

.tw-text-amber-900\/35 {
  color: rgb(120 53 15 / 0.35) !important;
}

.tw-text-amber-900\/40 {
  color: rgb(120 53 15 / 0.4) !important;
}

.tw-text-amber-900\/45 {
  color: rgb(120 53 15 / 0.45) !important;
}

.tw-text-amber-900\/5 {
  color: rgb(120 53 15 / 0.05) !important;
}

.tw-text-amber-900\/50 {
  color: rgb(120 53 15 / 0.5) !important;
}

.tw-text-amber-900\/55 {
  color: rgb(120 53 15 / 0.55) !important;
}

.tw-text-amber-900\/60 {
  color: rgb(120 53 15 / 0.6) !important;
}

.tw-text-amber-900\/65 {
  color: rgb(120 53 15 / 0.65) !important;
}

.tw-text-amber-900\/70 {
  color: rgb(120 53 15 / 0.7) !important;
}

.tw-text-amber-900\/75 {
  color: rgb(120 53 15 / 0.75) !important;
}

.tw-text-amber-900\/80 {
  color: rgb(120 53 15 / 0.8) !important;
}

.tw-text-amber-900\/85 {
  color: rgb(120 53 15 / 0.85) !important;
}

.tw-text-amber-900\/90 {
  color: rgb(120 53 15 / 0.9) !important;
}

.tw-text-amber-900\/95 {
  color: rgb(120 53 15 / 0.95) !important;
}

.tw-text-amber-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(69 26 3 / var(--tw-text-opacity)) !important;
}

.tw-text-amber-950\/0 {
  color: rgb(69 26 3 / 0) !important;
}

.tw-text-amber-950\/10 {
  color: rgb(69 26 3 / 0.1) !important;
}

.tw-text-amber-950\/100 {
  color: rgb(69 26 3 / 1) !important;
}

.tw-text-amber-950\/15 {
  color: rgb(69 26 3 / 0.15) !important;
}

.tw-text-amber-950\/20 {
  color: rgb(69 26 3 / 0.2) !important;
}

.tw-text-amber-950\/25 {
  color: rgb(69 26 3 / 0.25) !important;
}

.tw-text-amber-950\/30 {
  color: rgb(69 26 3 / 0.3) !important;
}

.tw-text-amber-950\/35 {
  color: rgb(69 26 3 / 0.35) !important;
}

.tw-text-amber-950\/40 {
  color: rgb(69 26 3 / 0.4) !important;
}

.tw-text-amber-950\/45 {
  color: rgb(69 26 3 / 0.45) !important;
}

.tw-text-amber-950\/5 {
  color: rgb(69 26 3 / 0.05) !important;
}

.tw-text-amber-950\/50 {
  color: rgb(69 26 3 / 0.5) !important;
}

.tw-text-amber-950\/55 {
  color: rgb(69 26 3 / 0.55) !important;
}

.tw-text-amber-950\/60 {
  color: rgb(69 26 3 / 0.6) !important;
}

.tw-text-amber-950\/65 {
  color: rgb(69 26 3 / 0.65) !important;
}

.tw-text-amber-950\/70 {
  color: rgb(69 26 3 / 0.7) !important;
}

.tw-text-amber-950\/75 {
  color: rgb(69 26 3 / 0.75) !important;
}

.tw-text-amber-950\/80 {
  color: rgb(69 26 3 / 0.8) !important;
}

.tw-text-amber-950\/85 {
  color: rgb(69 26 3 / 0.85) !important;
}

.tw-text-amber-950\/90 {
  color: rgb(69 26 3 / 0.9) !important;
}

.tw-text-amber-950\/95 {
  color: rgb(69 26 3 / 0.95) !important;
}

.tw-text-black {
  --tw-text-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-text-opacity)) !important;
}

.tw-text-black\/0 {
  color: rgb(0 0 0 / 0) !important;
}

.tw-text-black\/10 {
  color: rgb(0 0 0 / 0.1) !important;
}

.tw-text-black\/100 {
  color: rgb(0 0 0 / 1) !important;
}

.tw-text-black\/15 {
  color: rgb(0 0 0 / 0.15) !important;
}

.tw-text-black\/20 {
  color: rgb(0 0 0 / 0.2) !important;
}

.tw-text-black\/25 {
  color: rgb(0 0 0 / 0.25) !important;
}

.tw-text-black\/30 {
  color: rgb(0 0 0 / 0.3) !important;
}

.tw-text-black\/35 {
  color: rgb(0 0 0 / 0.35) !important;
}

.tw-text-black\/40 {
  color: rgb(0 0 0 / 0.4) !important;
}

.tw-text-black\/45 {
  color: rgb(0 0 0 / 0.45) !important;
}

.tw-text-black\/5 {
  color: rgb(0 0 0 / 0.05) !important;
}

.tw-text-black\/50 {
  color: rgb(0 0 0 / 0.5) !important;
}

.tw-text-black\/55 {
  color: rgb(0 0 0 / 0.55) !important;
}

.tw-text-black\/60 {
  color: rgb(0 0 0 / 0.6) !important;
}

.tw-text-black\/65 {
  color: rgb(0 0 0 / 0.65) !important;
}

.tw-text-black\/70 {
  color: rgb(0 0 0 / 0.7) !important;
}

.tw-text-black\/75 {
  color: rgb(0 0 0 / 0.75) !important;
}

.tw-text-black\/80 {
  color: rgb(0 0 0 / 0.8) !important;
}

.tw-text-black\/85 {
  color: rgb(0 0 0 / 0.85) !important;
}

.tw-text-black\/90 {
  color: rgb(0 0 0 / 0.9) !important;
}

.tw-text-black\/95 {
  color: rgb(0 0 0 / 0.95) !important;
}

.tw-text-blue-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(219 234 254 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-100\/0 {
  color: rgb(219 234 254 / 0) !important;
}

.tw-text-blue-100\/10 {
  color: rgb(219 234 254 / 0.1) !important;
}

.tw-text-blue-100\/100 {
  color: rgb(219 234 254 / 1) !important;
}

.tw-text-blue-100\/15 {
  color: rgb(219 234 254 / 0.15) !important;
}

.tw-text-blue-100\/20 {
  color: rgb(219 234 254 / 0.2) !important;
}

.tw-text-blue-100\/25 {
  color: rgb(219 234 254 / 0.25) !important;
}

.tw-text-blue-100\/30 {
  color: rgb(219 234 254 / 0.3) !important;
}

.tw-text-blue-100\/35 {
  color: rgb(219 234 254 / 0.35) !important;
}

.tw-text-blue-100\/40 {
  color: rgb(219 234 254 / 0.4) !important;
}

.tw-text-blue-100\/45 {
  color: rgb(219 234 254 / 0.45) !important;
}

.tw-text-blue-100\/5 {
  color: rgb(219 234 254 / 0.05) !important;
}

.tw-text-blue-100\/50 {
  color: rgb(219 234 254 / 0.5) !important;
}

.tw-text-blue-100\/55 {
  color: rgb(219 234 254 / 0.55) !important;
}

.tw-text-blue-100\/60 {
  color: rgb(219 234 254 / 0.6) !important;
}

.tw-text-blue-100\/65 {
  color: rgb(219 234 254 / 0.65) !important;
}

.tw-text-blue-100\/70 {
  color: rgb(219 234 254 / 0.7) !important;
}

.tw-text-blue-100\/75 {
  color: rgb(219 234 254 / 0.75) !important;
}

.tw-text-blue-100\/80 {
  color: rgb(219 234 254 / 0.8) !important;
}

.tw-text-blue-100\/85 {
  color: rgb(219 234 254 / 0.85) !important;
}

.tw-text-blue-100\/90 {
  color: rgb(219 234 254 / 0.9) !important;
}

.tw-text-blue-100\/95 {
  color: rgb(219 234 254 / 0.95) !important;
}

.tw-text-blue-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(191 219 254 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-200\/0 {
  color: rgb(191 219 254 / 0) !important;
}

.tw-text-blue-200\/10 {
  color: rgb(191 219 254 / 0.1) !important;
}

.tw-text-blue-200\/100 {
  color: rgb(191 219 254 / 1) !important;
}

.tw-text-blue-200\/15 {
  color: rgb(191 219 254 / 0.15) !important;
}

.tw-text-blue-200\/20 {
  color: rgb(191 219 254 / 0.2) !important;
}

.tw-text-blue-200\/25 {
  color: rgb(191 219 254 / 0.25) !important;
}

.tw-text-blue-200\/30 {
  color: rgb(191 219 254 / 0.3) !important;
}

.tw-text-blue-200\/35 {
  color: rgb(191 219 254 / 0.35) !important;
}

.tw-text-blue-200\/40 {
  color: rgb(191 219 254 / 0.4) !important;
}

.tw-text-blue-200\/45 {
  color: rgb(191 219 254 / 0.45) !important;
}

.tw-text-blue-200\/5 {
  color: rgb(191 219 254 / 0.05) !important;
}

.tw-text-blue-200\/50 {
  color: rgb(191 219 254 / 0.5) !important;
}

.tw-text-blue-200\/55 {
  color: rgb(191 219 254 / 0.55) !important;
}

.tw-text-blue-200\/60 {
  color: rgb(191 219 254 / 0.6) !important;
}

.tw-text-blue-200\/65 {
  color: rgb(191 219 254 / 0.65) !important;
}

.tw-text-blue-200\/70 {
  color: rgb(191 219 254 / 0.7) !important;
}

.tw-text-blue-200\/75 {
  color: rgb(191 219 254 / 0.75) !important;
}

.tw-text-blue-200\/80 {
  color: rgb(191 219 254 / 0.8) !important;
}

.tw-text-blue-200\/85 {
  color: rgb(191 219 254 / 0.85) !important;
}

.tw-text-blue-200\/90 {
  color: rgb(191 219 254 / 0.9) !important;
}

.tw-text-blue-200\/95 {
  color: rgb(191 219 254 / 0.95) !important;
}

.tw-text-blue-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(147 197 253 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-300\/0 {
  color: rgb(147 197 253 / 0) !important;
}

.tw-text-blue-300\/10 {
  color: rgb(147 197 253 / 0.1) !important;
}

.tw-text-blue-300\/100 {
  color: rgb(147 197 253 / 1) !important;
}

.tw-text-blue-300\/15 {
  color: rgb(147 197 253 / 0.15) !important;
}

.tw-text-blue-300\/20 {
  color: rgb(147 197 253 / 0.2) !important;
}

.tw-text-blue-300\/25 {
  color: rgb(147 197 253 / 0.25) !important;
}

.tw-text-blue-300\/30 {
  color: rgb(147 197 253 / 0.3) !important;
}

.tw-text-blue-300\/35 {
  color: rgb(147 197 253 / 0.35) !important;
}

.tw-text-blue-300\/40 {
  color: rgb(147 197 253 / 0.4) !important;
}

.tw-text-blue-300\/45 {
  color: rgb(147 197 253 / 0.45) !important;
}

.tw-text-blue-300\/5 {
  color: rgb(147 197 253 / 0.05) !important;
}

.tw-text-blue-300\/50 {
  color: rgb(147 197 253 / 0.5) !important;
}

.tw-text-blue-300\/55 {
  color: rgb(147 197 253 / 0.55) !important;
}

.tw-text-blue-300\/60 {
  color: rgb(147 197 253 / 0.6) !important;
}

.tw-text-blue-300\/65 {
  color: rgb(147 197 253 / 0.65) !important;
}

.tw-text-blue-300\/70 {
  color: rgb(147 197 253 / 0.7) !important;
}

.tw-text-blue-300\/75 {
  color: rgb(147 197 253 / 0.75) !important;
}

.tw-text-blue-300\/80 {
  color: rgb(147 197 253 / 0.8) !important;
}

.tw-text-blue-300\/85 {
  color: rgb(147 197 253 / 0.85) !important;
}

.tw-text-blue-300\/90 {
  color: rgb(147 197 253 / 0.9) !important;
}

.tw-text-blue-300\/95 {
  color: rgb(147 197 253 / 0.95) !important;
}

.tw-text-blue-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(96 165 250 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-400\/0 {
  color: rgb(96 165 250 / 0) !important;
}

.tw-text-blue-400\/10 {
  color: rgb(96 165 250 / 0.1) !important;
}

.tw-text-blue-400\/100 {
  color: rgb(96 165 250 / 1) !important;
}

.tw-text-blue-400\/15 {
  color: rgb(96 165 250 / 0.15) !important;
}

.tw-text-blue-400\/20 {
  color: rgb(96 165 250 / 0.2) !important;
}

.tw-text-blue-400\/25 {
  color: rgb(96 165 250 / 0.25) !important;
}

.tw-text-blue-400\/30 {
  color: rgb(96 165 250 / 0.3) !important;
}

.tw-text-blue-400\/35 {
  color: rgb(96 165 250 / 0.35) !important;
}

.tw-text-blue-400\/40 {
  color: rgb(96 165 250 / 0.4) !important;
}

.tw-text-blue-400\/45 {
  color: rgb(96 165 250 / 0.45) !important;
}

.tw-text-blue-400\/5 {
  color: rgb(96 165 250 / 0.05) !important;
}

.tw-text-blue-400\/50 {
  color: rgb(96 165 250 / 0.5) !important;
}

.tw-text-blue-400\/55 {
  color: rgb(96 165 250 / 0.55) !important;
}

.tw-text-blue-400\/60 {
  color: rgb(96 165 250 / 0.6) !important;
}

.tw-text-blue-400\/65 {
  color: rgb(96 165 250 / 0.65) !important;
}

.tw-text-blue-400\/70 {
  color: rgb(96 165 250 / 0.7) !important;
}

.tw-text-blue-400\/75 {
  color: rgb(96 165 250 / 0.75) !important;
}

.tw-text-blue-400\/80 {
  color: rgb(96 165 250 / 0.8) !important;
}

.tw-text-blue-400\/85 {
  color: rgb(96 165 250 / 0.85) !important;
}

.tw-text-blue-400\/90 {
  color: rgb(96 165 250 / 0.9) !important;
}

.tw-text-blue-400\/95 {
  color: rgb(96 165 250 / 0.95) !important;
}

.tw-text-blue-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 246 255 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-50\/0 {
  color: rgb(239 246 255 / 0) !important;
}

.tw-text-blue-50\/10 {
  color: rgb(239 246 255 / 0.1) !important;
}

.tw-text-blue-50\/100 {
  color: rgb(239 246 255 / 1) !important;
}

.tw-text-blue-50\/15 {
  color: rgb(239 246 255 / 0.15) !important;
}

.tw-text-blue-50\/20 {
  color: rgb(239 246 255 / 0.2) !important;
}

.tw-text-blue-50\/25 {
  color: rgb(239 246 255 / 0.25) !important;
}

.tw-text-blue-50\/30 {
  color: rgb(239 246 255 / 0.3) !important;
}

.tw-text-blue-50\/35 {
  color: rgb(239 246 255 / 0.35) !important;
}

.tw-text-blue-50\/40 {
  color: rgb(239 246 255 / 0.4) !important;
}

.tw-text-blue-50\/45 {
  color: rgb(239 246 255 / 0.45) !important;
}

.tw-text-blue-50\/5 {
  color: rgb(239 246 255 / 0.05) !important;
}

.tw-text-blue-50\/50 {
  color: rgb(239 246 255 / 0.5) !important;
}

.tw-text-blue-50\/55 {
  color: rgb(239 246 255 / 0.55) !important;
}

.tw-text-blue-50\/60 {
  color: rgb(239 246 255 / 0.6) !important;
}

.tw-text-blue-50\/65 {
  color: rgb(239 246 255 / 0.65) !important;
}

.tw-text-blue-50\/70 {
  color: rgb(239 246 255 / 0.7) !important;
}

.tw-text-blue-50\/75 {
  color: rgb(239 246 255 / 0.75) !important;
}

.tw-text-blue-50\/80 {
  color: rgb(239 246 255 / 0.8) !important;
}

.tw-text-blue-50\/85 {
  color: rgb(239 246 255 / 0.85) !important;
}

.tw-text-blue-50\/90 {
  color: rgb(239 246 255 / 0.9) !important;
}

.tw-text-blue-50\/95 {
  color: rgb(239 246 255 / 0.95) !important;
}

.tw-text-blue-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(59 130 246 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-500\/0 {
  color: rgb(59 130 246 / 0) !important;
}

.tw-text-blue-500\/10 {
  color: rgb(59 130 246 / 0.1) !important;
}

.tw-text-blue-500\/100 {
  color: rgb(59 130 246 / 1) !important;
}

.tw-text-blue-500\/15 {
  color: rgb(59 130 246 / 0.15) !important;
}

.tw-text-blue-500\/20 {
  color: rgb(59 130 246 / 0.2) !important;
}

.tw-text-blue-500\/25 {
  color: rgb(59 130 246 / 0.25) !important;
}

.tw-text-blue-500\/30 {
  color: rgb(59 130 246 / 0.3) !important;
}

.tw-text-blue-500\/35 {
  color: rgb(59 130 246 / 0.35) !important;
}

.tw-text-blue-500\/40 {
  color: rgb(59 130 246 / 0.4) !important;
}

.tw-text-blue-500\/45 {
  color: rgb(59 130 246 / 0.45) !important;
}

.tw-text-blue-500\/5 {
  color: rgb(59 130 246 / 0.05) !important;
}

.tw-text-blue-500\/50 {
  color: rgb(59 130 246 / 0.5) !important;
}

.tw-text-blue-500\/55 {
  color: rgb(59 130 246 / 0.55) !important;
}

.tw-text-blue-500\/60 {
  color: rgb(59 130 246 / 0.6) !important;
}

.tw-text-blue-500\/65 {
  color: rgb(59 130 246 / 0.65) !important;
}

.tw-text-blue-500\/70 {
  color: rgb(59 130 246 / 0.7) !important;
}

.tw-text-blue-500\/75 {
  color: rgb(59 130 246 / 0.75) !important;
}

.tw-text-blue-500\/80 {
  color: rgb(59 130 246 / 0.8) !important;
}

.tw-text-blue-500\/85 {
  color: rgb(59 130 246 / 0.85) !important;
}

.tw-text-blue-500\/90 {
  color: rgb(59 130 246 / 0.9) !important;
}

.tw-text-blue-500\/95 {
  color: rgb(59 130 246 / 0.95) !important;
}

.tw-text-blue-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(37 99 235 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-600\/0 {
  color: rgb(37 99 235 / 0) !important;
}

.tw-text-blue-600\/10 {
  color: rgb(37 99 235 / 0.1) !important;
}

.tw-text-blue-600\/100 {
  color: rgb(37 99 235 / 1) !important;
}

.tw-text-blue-600\/15 {
  color: rgb(37 99 235 / 0.15) !important;
}

.tw-text-blue-600\/20 {
  color: rgb(37 99 235 / 0.2) !important;
}

.tw-text-blue-600\/25 {
  color: rgb(37 99 235 / 0.25) !important;
}

.tw-text-blue-600\/30 {
  color: rgb(37 99 235 / 0.3) !important;
}

.tw-text-blue-600\/35 {
  color: rgb(37 99 235 / 0.35) !important;
}

.tw-text-blue-600\/40 {
  color: rgb(37 99 235 / 0.4) !important;
}

.tw-text-blue-600\/45 {
  color: rgb(37 99 235 / 0.45) !important;
}

.tw-text-blue-600\/5 {
  color: rgb(37 99 235 / 0.05) !important;
}

.tw-text-blue-600\/50 {
  color: rgb(37 99 235 / 0.5) !important;
}

.tw-text-blue-600\/55 {
  color: rgb(37 99 235 / 0.55) !important;
}

.tw-text-blue-600\/60 {
  color: rgb(37 99 235 / 0.6) !important;
}

.tw-text-blue-600\/65 {
  color: rgb(37 99 235 / 0.65) !important;
}

.tw-text-blue-600\/70 {
  color: rgb(37 99 235 / 0.7) !important;
}

.tw-text-blue-600\/75 {
  color: rgb(37 99 235 / 0.75) !important;
}

.tw-text-blue-600\/80 {
  color: rgb(37 99 235 / 0.8) !important;
}

.tw-text-blue-600\/85 {
  color: rgb(37 99 235 / 0.85) !important;
}

.tw-text-blue-600\/90 {
  color: rgb(37 99 235 / 0.9) !important;
}

.tw-text-blue-600\/95 {
  color: rgb(37 99 235 / 0.95) !important;
}

.tw-text-blue-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(29 78 216 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-700\/0 {
  color: rgb(29 78 216 / 0) !important;
}

.tw-text-blue-700\/10 {
  color: rgb(29 78 216 / 0.1) !important;
}

.tw-text-blue-700\/100 {
  color: rgb(29 78 216 / 1) !important;
}

.tw-text-blue-700\/15 {
  color: rgb(29 78 216 / 0.15) !important;
}

.tw-text-blue-700\/20 {
  color: rgb(29 78 216 / 0.2) !important;
}

.tw-text-blue-700\/25 {
  color: rgb(29 78 216 / 0.25) !important;
}

.tw-text-blue-700\/30 {
  color: rgb(29 78 216 / 0.3) !important;
}

.tw-text-blue-700\/35 {
  color: rgb(29 78 216 / 0.35) !important;
}

.tw-text-blue-700\/40 {
  color: rgb(29 78 216 / 0.4) !important;
}

.tw-text-blue-700\/45 {
  color: rgb(29 78 216 / 0.45) !important;
}

.tw-text-blue-700\/5 {
  color: rgb(29 78 216 / 0.05) !important;
}

.tw-text-blue-700\/50 {
  color: rgb(29 78 216 / 0.5) !important;
}

.tw-text-blue-700\/55 {
  color: rgb(29 78 216 / 0.55) !important;
}

.tw-text-blue-700\/60 {
  color: rgb(29 78 216 / 0.6) !important;
}

.tw-text-blue-700\/65 {
  color: rgb(29 78 216 / 0.65) !important;
}

.tw-text-blue-700\/70 {
  color: rgb(29 78 216 / 0.7) !important;
}

.tw-text-blue-700\/75 {
  color: rgb(29 78 216 / 0.75) !important;
}

.tw-text-blue-700\/80 {
  color: rgb(29 78 216 / 0.8) !important;
}

.tw-text-blue-700\/85 {
  color: rgb(29 78 216 / 0.85) !important;
}

.tw-text-blue-700\/90 {
  color: rgb(29 78 216 / 0.9) !important;
}

.tw-text-blue-700\/95 {
  color: rgb(29 78 216 / 0.95) !important;
}

.tw-text-blue-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 64 175 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-800\/0 {
  color: rgb(30 64 175 / 0) !important;
}

.tw-text-blue-800\/10 {
  color: rgb(30 64 175 / 0.1) !important;
}

.tw-text-blue-800\/100 {
  color: rgb(30 64 175 / 1) !important;
}

.tw-text-blue-800\/15 {
  color: rgb(30 64 175 / 0.15) !important;
}

.tw-text-blue-800\/20 {
  color: rgb(30 64 175 / 0.2) !important;
}

.tw-text-blue-800\/25 {
  color: rgb(30 64 175 / 0.25) !important;
}

.tw-text-blue-800\/30 {
  color: rgb(30 64 175 / 0.3) !important;
}

.tw-text-blue-800\/35 {
  color: rgb(30 64 175 / 0.35) !important;
}

.tw-text-blue-800\/40 {
  color: rgb(30 64 175 / 0.4) !important;
}

.tw-text-blue-800\/45 {
  color: rgb(30 64 175 / 0.45) !important;
}

.tw-text-blue-800\/5 {
  color: rgb(30 64 175 / 0.05) !important;
}

.tw-text-blue-800\/50 {
  color: rgb(30 64 175 / 0.5) !important;
}

.tw-text-blue-800\/55 {
  color: rgb(30 64 175 / 0.55) !important;
}

.tw-text-blue-800\/60 {
  color: rgb(30 64 175 / 0.6) !important;
}

.tw-text-blue-800\/65 {
  color: rgb(30 64 175 / 0.65) !important;
}

.tw-text-blue-800\/70 {
  color: rgb(30 64 175 / 0.7) !important;
}

.tw-text-blue-800\/75 {
  color: rgb(30 64 175 / 0.75) !important;
}

.tw-text-blue-800\/80 {
  color: rgb(30 64 175 / 0.8) !important;
}

.tw-text-blue-800\/85 {
  color: rgb(30 64 175 / 0.85) !important;
}

.tw-text-blue-800\/90 {
  color: rgb(30 64 175 / 0.9) !important;
}

.tw-text-blue-800\/95 {
  color: rgb(30 64 175 / 0.95) !important;
}

.tw-text-blue-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 58 138 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-900\/0 {
  color: rgb(30 58 138 / 0) !important;
}

.tw-text-blue-900\/10 {
  color: rgb(30 58 138 / 0.1) !important;
}

.tw-text-blue-900\/100 {
  color: rgb(30 58 138 / 1) !important;
}

.tw-text-blue-900\/15 {
  color: rgb(30 58 138 / 0.15) !important;
}

.tw-text-blue-900\/20 {
  color: rgb(30 58 138 / 0.2) !important;
}

.tw-text-blue-900\/25 {
  color: rgb(30 58 138 / 0.25) !important;
}

.tw-text-blue-900\/30 {
  color: rgb(30 58 138 / 0.3) !important;
}

.tw-text-blue-900\/35 {
  color: rgb(30 58 138 / 0.35) !important;
}

.tw-text-blue-900\/40 {
  color: rgb(30 58 138 / 0.4) !important;
}

.tw-text-blue-900\/45 {
  color: rgb(30 58 138 / 0.45) !important;
}

.tw-text-blue-900\/5 {
  color: rgb(30 58 138 / 0.05) !important;
}

.tw-text-blue-900\/50 {
  color: rgb(30 58 138 / 0.5) !important;
}

.tw-text-blue-900\/55 {
  color: rgb(30 58 138 / 0.55) !important;
}

.tw-text-blue-900\/60 {
  color: rgb(30 58 138 / 0.6) !important;
}

.tw-text-blue-900\/65 {
  color: rgb(30 58 138 / 0.65) !important;
}

.tw-text-blue-900\/70 {
  color: rgb(30 58 138 / 0.7) !important;
}

.tw-text-blue-900\/75 {
  color: rgb(30 58 138 / 0.75) !important;
}

.tw-text-blue-900\/80 {
  color: rgb(30 58 138 / 0.8) !important;
}

.tw-text-blue-900\/85 {
  color: rgb(30 58 138 / 0.85) !important;
}

.tw-text-blue-900\/90 {
  color: rgb(30 58 138 / 0.9) !important;
}

.tw-text-blue-900\/95 {
  color: rgb(30 58 138 / 0.95) !important;
}

.tw-text-blue-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(23 37 84 / var(--tw-text-opacity)) !important;
}

.tw-text-blue-950\/0 {
  color: rgb(23 37 84 / 0) !important;
}

.tw-text-blue-950\/10 {
  color: rgb(23 37 84 / 0.1) !important;
}

.tw-text-blue-950\/100 {
  color: rgb(23 37 84 / 1) !important;
}

.tw-text-blue-950\/15 {
  color: rgb(23 37 84 / 0.15) !important;
}

.tw-text-blue-950\/20 {
  color: rgb(23 37 84 / 0.2) !important;
}

.tw-text-blue-950\/25 {
  color: rgb(23 37 84 / 0.25) !important;
}

.tw-text-blue-950\/30 {
  color: rgb(23 37 84 / 0.3) !important;
}

.tw-text-blue-950\/35 {
  color: rgb(23 37 84 / 0.35) !important;
}

.tw-text-blue-950\/40 {
  color: rgb(23 37 84 / 0.4) !important;
}

.tw-text-blue-950\/45 {
  color: rgb(23 37 84 / 0.45) !important;
}

.tw-text-blue-950\/5 {
  color: rgb(23 37 84 / 0.05) !important;
}

.tw-text-blue-950\/50 {
  color: rgb(23 37 84 / 0.5) !important;
}

.tw-text-blue-950\/55 {
  color: rgb(23 37 84 / 0.55) !important;
}

.tw-text-blue-950\/60 {
  color: rgb(23 37 84 / 0.6) !important;
}

.tw-text-blue-950\/65 {
  color: rgb(23 37 84 / 0.65) !important;
}

.tw-text-blue-950\/70 {
  color: rgb(23 37 84 / 0.7) !important;
}

.tw-text-blue-950\/75 {
  color: rgb(23 37 84 / 0.75) !important;
}

.tw-text-blue-950\/80 {
  color: rgb(23 37 84 / 0.8) !important;
}

.tw-text-blue-950\/85 {
  color: rgb(23 37 84 / 0.85) !important;
}

.tw-text-blue-950\/90 {
  color: rgb(23 37 84 / 0.9) !important;
}

.tw-text-blue-950\/95 {
  color: rgb(23 37 84 / 0.95) !important;
}

.tw-text-current {
  color: currentColor !important;
}

.tw-text-cyan-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(207 250 254 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-100\/0 {
  color: rgb(207 250 254 / 0) !important;
}

.tw-text-cyan-100\/10 {
  color: rgb(207 250 254 / 0.1) !important;
}

.tw-text-cyan-100\/100 {
  color: rgb(207 250 254 / 1) !important;
}

.tw-text-cyan-100\/15 {
  color: rgb(207 250 254 / 0.15) !important;
}

.tw-text-cyan-100\/20 {
  color: rgb(207 250 254 / 0.2) !important;
}

.tw-text-cyan-100\/25 {
  color: rgb(207 250 254 / 0.25) !important;
}

.tw-text-cyan-100\/30 {
  color: rgb(207 250 254 / 0.3) !important;
}

.tw-text-cyan-100\/35 {
  color: rgb(207 250 254 / 0.35) !important;
}

.tw-text-cyan-100\/40 {
  color: rgb(207 250 254 / 0.4) !important;
}

.tw-text-cyan-100\/45 {
  color: rgb(207 250 254 / 0.45) !important;
}

.tw-text-cyan-100\/5 {
  color: rgb(207 250 254 / 0.05) !important;
}

.tw-text-cyan-100\/50 {
  color: rgb(207 250 254 / 0.5) !important;
}

.tw-text-cyan-100\/55 {
  color: rgb(207 250 254 / 0.55) !important;
}

.tw-text-cyan-100\/60 {
  color: rgb(207 250 254 / 0.6) !important;
}

.tw-text-cyan-100\/65 {
  color: rgb(207 250 254 / 0.65) !important;
}

.tw-text-cyan-100\/70 {
  color: rgb(207 250 254 / 0.7) !important;
}

.tw-text-cyan-100\/75 {
  color: rgb(207 250 254 / 0.75) !important;
}

.tw-text-cyan-100\/80 {
  color: rgb(207 250 254 / 0.8) !important;
}

.tw-text-cyan-100\/85 {
  color: rgb(207 250 254 / 0.85) !important;
}

.tw-text-cyan-100\/90 {
  color: rgb(207 250 254 / 0.9) !important;
}

.tw-text-cyan-100\/95 {
  color: rgb(207 250 254 / 0.95) !important;
}

.tw-text-cyan-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(165 243 252 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-200\/0 {
  color: rgb(165 243 252 / 0) !important;
}

.tw-text-cyan-200\/10 {
  color: rgb(165 243 252 / 0.1) !important;
}

.tw-text-cyan-200\/100 {
  color: rgb(165 243 252 / 1) !important;
}

.tw-text-cyan-200\/15 {
  color: rgb(165 243 252 / 0.15) !important;
}

.tw-text-cyan-200\/20 {
  color: rgb(165 243 252 / 0.2) !important;
}

.tw-text-cyan-200\/25 {
  color: rgb(165 243 252 / 0.25) !important;
}

.tw-text-cyan-200\/30 {
  color: rgb(165 243 252 / 0.3) !important;
}

.tw-text-cyan-200\/35 {
  color: rgb(165 243 252 / 0.35) !important;
}

.tw-text-cyan-200\/40 {
  color: rgb(165 243 252 / 0.4) !important;
}

.tw-text-cyan-200\/45 {
  color: rgb(165 243 252 / 0.45) !important;
}

.tw-text-cyan-200\/5 {
  color: rgb(165 243 252 / 0.05) !important;
}

.tw-text-cyan-200\/50 {
  color: rgb(165 243 252 / 0.5) !important;
}

.tw-text-cyan-200\/55 {
  color: rgb(165 243 252 / 0.55) !important;
}

.tw-text-cyan-200\/60 {
  color: rgb(165 243 252 / 0.6) !important;
}

.tw-text-cyan-200\/65 {
  color: rgb(165 243 252 / 0.65) !important;
}

.tw-text-cyan-200\/70 {
  color: rgb(165 243 252 / 0.7) !important;
}

.tw-text-cyan-200\/75 {
  color: rgb(165 243 252 / 0.75) !important;
}

.tw-text-cyan-200\/80 {
  color: rgb(165 243 252 / 0.8) !important;
}

.tw-text-cyan-200\/85 {
  color: rgb(165 243 252 / 0.85) !important;
}

.tw-text-cyan-200\/90 {
  color: rgb(165 243 252 / 0.9) !important;
}

.tw-text-cyan-200\/95 {
  color: rgb(165 243 252 / 0.95) !important;
}

.tw-text-cyan-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(103 232 249 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-300\/0 {
  color: rgb(103 232 249 / 0) !important;
}

.tw-text-cyan-300\/10 {
  color: rgb(103 232 249 / 0.1) !important;
}

.tw-text-cyan-300\/100 {
  color: rgb(103 232 249 / 1) !important;
}

.tw-text-cyan-300\/15 {
  color: rgb(103 232 249 / 0.15) !important;
}

.tw-text-cyan-300\/20 {
  color: rgb(103 232 249 / 0.2) !important;
}

.tw-text-cyan-300\/25 {
  color: rgb(103 232 249 / 0.25) !important;
}

.tw-text-cyan-300\/30 {
  color: rgb(103 232 249 / 0.3) !important;
}

.tw-text-cyan-300\/35 {
  color: rgb(103 232 249 / 0.35) !important;
}

.tw-text-cyan-300\/40 {
  color: rgb(103 232 249 / 0.4) !important;
}

.tw-text-cyan-300\/45 {
  color: rgb(103 232 249 / 0.45) !important;
}

.tw-text-cyan-300\/5 {
  color: rgb(103 232 249 / 0.05) !important;
}

.tw-text-cyan-300\/50 {
  color: rgb(103 232 249 / 0.5) !important;
}

.tw-text-cyan-300\/55 {
  color: rgb(103 232 249 / 0.55) !important;
}

.tw-text-cyan-300\/60 {
  color: rgb(103 232 249 / 0.6) !important;
}

.tw-text-cyan-300\/65 {
  color: rgb(103 232 249 / 0.65) !important;
}

.tw-text-cyan-300\/70 {
  color: rgb(103 232 249 / 0.7) !important;
}

.tw-text-cyan-300\/75 {
  color: rgb(103 232 249 / 0.75) !important;
}

.tw-text-cyan-300\/80 {
  color: rgb(103 232 249 / 0.8) !important;
}

.tw-text-cyan-300\/85 {
  color: rgb(103 232 249 / 0.85) !important;
}

.tw-text-cyan-300\/90 {
  color: rgb(103 232 249 / 0.9) !important;
}

.tw-text-cyan-300\/95 {
  color: rgb(103 232 249 / 0.95) !important;
}

.tw-text-cyan-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(34 211 238 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-400\/0 {
  color: rgb(34 211 238 / 0) !important;
}

.tw-text-cyan-400\/10 {
  color: rgb(34 211 238 / 0.1) !important;
}

.tw-text-cyan-400\/100 {
  color: rgb(34 211 238 / 1) !important;
}

.tw-text-cyan-400\/15 {
  color: rgb(34 211 238 / 0.15) !important;
}

.tw-text-cyan-400\/20 {
  color: rgb(34 211 238 / 0.2) !important;
}

.tw-text-cyan-400\/25 {
  color: rgb(34 211 238 / 0.25) !important;
}

.tw-text-cyan-400\/30 {
  color: rgb(34 211 238 / 0.3) !important;
}

.tw-text-cyan-400\/35 {
  color: rgb(34 211 238 / 0.35) !important;
}

.tw-text-cyan-400\/40 {
  color: rgb(34 211 238 / 0.4) !important;
}

.tw-text-cyan-400\/45 {
  color: rgb(34 211 238 / 0.45) !important;
}

.tw-text-cyan-400\/5 {
  color: rgb(34 211 238 / 0.05) !important;
}

.tw-text-cyan-400\/50 {
  color: rgb(34 211 238 / 0.5) !important;
}

.tw-text-cyan-400\/55 {
  color: rgb(34 211 238 / 0.55) !important;
}

.tw-text-cyan-400\/60 {
  color: rgb(34 211 238 / 0.6) !important;
}

.tw-text-cyan-400\/65 {
  color: rgb(34 211 238 / 0.65) !important;
}

.tw-text-cyan-400\/70 {
  color: rgb(34 211 238 / 0.7) !important;
}

.tw-text-cyan-400\/75 {
  color: rgb(34 211 238 / 0.75) !important;
}

.tw-text-cyan-400\/80 {
  color: rgb(34 211 238 / 0.8) !important;
}

.tw-text-cyan-400\/85 {
  color: rgb(34 211 238 / 0.85) !important;
}

.tw-text-cyan-400\/90 {
  color: rgb(34 211 238 / 0.9) !important;
}

.tw-text-cyan-400\/95 {
  color: rgb(34 211 238 / 0.95) !important;
}

.tw-text-cyan-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(236 254 255 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-50\/0 {
  color: rgb(236 254 255 / 0) !important;
}

.tw-text-cyan-50\/10 {
  color: rgb(236 254 255 / 0.1) !important;
}

.tw-text-cyan-50\/100 {
  color: rgb(236 254 255 / 1) !important;
}

.tw-text-cyan-50\/15 {
  color: rgb(236 254 255 / 0.15) !important;
}

.tw-text-cyan-50\/20 {
  color: rgb(236 254 255 / 0.2) !important;
}

.tw-text-cyan-50\/25 {
  color: rgb(236 254 255 / 0.25) !important;
}

.tw-text-cyan-50\/30 {
  color: rgb(236 254 255 / 0.3) !important;
}

.tw-text-cyan-50\/35 {
  color: rgb(236 254 255 / 0.35) !important;
}

.tw-text-cyan-50\/40 {
  color: rgb(236 254 255 / 0.4) !important;
}

.tw-text-cyan-50\/45 {
  color: rgb(236 254 255 / 0.45) !important;
}

.tw-text-cyan-50\/5 {
  color: rgb(236 254 255 / 0.05) !important;
}

.tw-text-cyan-50\/50 {
  color: rgb(236 254 255 / 0.5) !important;
}

.tw-text-cyan-50\/55 {
  color: rgb(236 254 255 / 0.55) !important;
}

.tw-text-cyan-50\/60 {
  color: rgb(236 254 255 / 0.6) !important;
}

.tw-text-cyan-50\/65 {
  color: rgb(236 254 255 / 0.65) !important;
}

.tw-text-cyan-50\/70 {
  color: rgb(236 254 255 / 0.7) !important;
}

.tw-text-cyan-50\/75 {
  color: rgb(236 254 255 / 0.75) !important;
}

.tw-text-cyan-50\/80 {
  color: rgb(236 254 255 / 0.8) !important;
}

.tw-text-cyan-50\/85 {
  color: rgb(236 254 255 / 0.85) !important;
}

.tw-text-cyan-50\/90 {
  color: rgb(236 254 255 / 0.9) !important;
}

.tw-text-cyan-50\/95 {
  color: rgb(236 254 255 / 0.95) !important;
}

.tw-text-cyan-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(6 182 212 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-500\/0 {
  color: rgb(6 182 212 / 0) !important;
}

.tw-text-cyan-500\/10 {
  color: rgb(6 182 212 / 0.1) !important;
}

.tw-text-cyan-500\/100 {
  color: rgb(6 182 212 / 1) !important;
}

.tw-text-cyan-500\/15 {
  color: rgb(6 182 212 / 0.15) !important;
}

.tw-text-cyan-500\/20 {
  color: rgb(6 182 212 / 0.2) !important;
}

.tw-text-cyan-500\/25 {
  color: rgb(6 182 212 / 0.25) !important;
}

.tw-text-cyan-500\/30 {
  color: rgb(6 182 212 / 0.3) !important;
}

.tw-text-cyan-500\/35 {
  color: rgb(6 182 212 / 0.35) !important;
}

.tw-text-cyan-500\/40 {
  color: rgb(6 182 212 / 0.4) !important;
}

.tw-text-cyan-500\/45 {
  color: rgb(6 182 212 / 0.45) !important;
}

.tw-text-cyan-500\/5 {
  color: rgb(6 182 212 / 0.05) !important;
}

.tw-text-cyan-500\/50 {
  color: rgb(6 182 212 / 0.5) !important;
}

.tw-text-cyan-500\/55 {
  color: rgb(6 182 212 / 0.55) !important;
}

.tw-text-cyan-500\/60 {
  color: rgb(6 182 212 / 0.6) !important;
}

.tw-text-cyan-500\/65 {
  color: rgb(6 182 212 / 0.65) !important;
}

.tw-text-cyan-500\/70 {
  color: rgb(6 182 212 / 0.7) !important;
}

.tw-text-cyan-500\/75 {
  color: rgb(6 182 212 / 0.75) !important;
}

.tw-text-cyan-500\/80 {
  color: rgb(6 182 212 / 0.8) !important;
}

.tw-text-cyan-500\/85 {
  color: rgb(6 182 212 / 0.85) !important;
}

.tw-text-cyan-500\/90 {
  color: rgb(6 182 212 / 0.9) !important;
}

.tw-text-cyan-500\/95 {
  color: rgb(6 182 212 / 0.95) !important;
}

.tw-text-cyan-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(8 145 178 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-600\/0 {
  color: rgb(8 145 178 / 0) !important;
}

.tw-text-cyan-600\/10 {
  color: rgb(8 145 178 / 0.1) !important;
}

.tw-text-cyan-600\/100 {
  color: rgb(8 145 178 / 1) !important;
}

.tw-text-cyan-600\/15 {
  color: rgb(8 145 178 / 0.15) !important;
}

.tw-text-cyan-600\/20 {
  color: rgb(8 145 178 / 0.2) !important;
}

.tw-text-cyan-600\/25 {
  color: rgb(8 145 178 / 0.25) !important;
}

.tw-text-cyan-600\/30 {
  color: rgb(8 145 178 / 0.3) !important;
}

.tw-text-cyan-600\/35 {
  color: rgb(8 145 178 / 0.35) !important;
}

.tw-text-cyan-600\/40 {
  color: rgb(8 145 178 / 0.4) !important;
}

.tw-text-cyan-600\/45 {
  color: rgb(8 145 178 / 0.45) !important;
}

.tw-text-cyan-600\/5 {
  color: rgb(8 145 178 / 0.05) !important;
}

.tw-text-cyan-600\/50 {
  color: rgb(8 145 178 / 0.5) !important;
}

.tw-text-cyan-600\/55 {
  color: rgb(8 145 178 / 0.55) !important;
}

.tw-text-cyan-600\/60 {
  color: rgb(8 145 178 / 0.6) !important;
}

.tw-text-cyan-600\/65 {
  color: rgb(8 145 178 / 0.65) !important;
}

.tw-text-cyan-600\/70 {
  color: rgb(8 145 178 / 0.7) !important;
}

.tw-text-cyan-600\/75 {
  color: rgb(8 145 178 / 0.75) !important;
}

.tw-text-cyan-600\/80 {
  color: rgb(8 145 178 / 0.8) !important;
}

.tw-text-cyan-600\/85 {
  color: rgb(8 145 178 / 0.85) !important;
}

.tw-text-cyan-600\/90 {
  color: rgb(8 145 178 / 0.9) !important;
}

.tw-text-cyan-600\/95 {
  color: rgb(8 145 178 / 0.95) !important;
}

.tw-text-cyan-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(14 116 144 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-700\/0 {
  color: rgb(14 116 144 / 0) !important;
}

.tw-text-cyan-700\/10 {
  color: rgb(14 116 144 / 0.1) !important;
}

.tw-text-cyan-700\/100 {
  color: rgb(14 116 144 / 1) !important;
}

.tw-text-cyan-700\/15 {
  color: rgb(14 116 144 / 0.15) !important;
}

.tw-text-cyan-700\/20 {
  color: rgb(14 116 144 / 0.2) !important;
}

.tw-text-cyan-700\/25 {
  color: rgb(14 116 144 / 0.25) !important;
}

.tw-text-cyan-700\/30 {
  color: rgb(14 116 144 / 0.3) !important;
}

.tw-text-cyan-700\/35 {
  color: rgb(14 116 144 / 0.35) !important;
}

.tw-text-cyan-700\/40 {
  color: rgb(14 116 144 / 0.4) !important;
}

.tw-text-cyan-700\/45 {
  color: rgb(14 116 144 / 0.45) !important;
}

.tw-text-cyan-700\/5 {
  color: rgb(14 116 144 / 0.05) !important;
}

.tw-text-cyan-700\/50 {
  color: rgb(14 116 144 / 0.5) !important;
}

.tw-text-cyan-700\/55 {
  color: rgb(14 116 144 / 0.55) !important;
}

.tw-text-cyan-700\/60 {
  color: rgb(14 116 144 / 0.6) !important;
}

.tw-text-cyan-700\/65 {
  color: rgb(14 116 144 / 0.65) !important;
}

.tw-text-cyan-700\/70 {
  color: rgb(14 116 144 / 0.7) !important;
}

.tw-text-cyan-700\/75 {
  color: rgb(14 116 144 / 0.75) !important;
}

.tw-text-cyan-700\/80 {
  color: rgb(14 116 144 / 0.8) !important;
}

.tw-text-cyan-700\/85 {
  color: rgb(14 116 144 / 0.85) !important;
}

.tw-text-cyan-700\/90 {
  color: rgb(14 116 144 / 0.9) !important;
}

.tw-text-cyan-700\/95 {
  color: rgb(14 116 144 / 0.95) !important;
}

.tw-text-cyan-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(21 94 117 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-800\/0 {
  color: rgb(21 94 117 / 0) !important;
}

.tw-text-cyan-800\/10 {
  color: rgb(21 94 117 / 0.1) !important;
}

.tw-text-cyan-800\/100 {
  color: rgb(21 94 117 / 1) !important;
}

.tw-text-cyan-800\/15 {
  color: rgb(21 94 117 / 0.15) !important;
}

.tw-text-cyan-800\/20 {
  color: rgb(21 94 117 / 0.2) !important;
}

.tw-text-cyan-800\/25 {
  color: rgb(21 94 117 / 0.25) !important;
}

.tw-text-cyan-800\/30 {
  color: rgb(21 94 117 / 0.3) !important;
}

.tw-text-cyan-800\/35 {
  color: rgb(21 94 117 / 0.35) !important;
}

.tw-text-cyan-800\/40 {
  color: rgb(21 94 117 / 0.4) !important;
}

.tw-text-cyan-800\/45 {
  color: rgb(21 94 117 / 0.45) !important;
}

.tw-text-cyan-800\/5 {
  color: rgb(21 94 117 / 0.05) !important;
}

.tw-text-cyan-800\/50 {
  color: rgb(21 94 117 / 0.5) !important;
}

.tw-text-cyan-800\/55 {
  color: rgb(21 94 117 / 0.55) !important;
}

.tw-text-cyan-800\/60 {
  color: rgb(21 94 117 / 0.6) !important;
}

.tw-text-cyan-800\/65 {
  color: rgb(21 94 117 / 0.65) !important;
}

.tw-text-cyan-800\/70 {
  color: rgb(21 94 117 / 0.7) !important;
}

.tw-text-cyan-800\/75 {
  color: rgb(21 94 117 / 0.75) !important;
}

.tw-text-cyan-800\/80 {
  color: rgb(21 94 117 / 0.8) !important;
}

.tw-text-cyan-800\/85 {
  color: rgb(21 94 117 / 0.85) !important;
}

.tw-text-cyan-800\/90 {
  color: rgb(21 94 117 / 0.9) !important;
}

.tw-text-cyan-800\/95 {
  color: rgb(21 94 117 / 0.95) !important;
}

.tw-text-cyan-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 78 99 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-900\/0 {
  color: rgb(22 78 99 / 0) !important;
}

.tw-text-cyan-900\/10 {
  color: rgb(22 78 99 / 0.1) !important;
}

.tw-text-cyan-900\/100 {
  color: rgb(22 78 99 / 1) !important;
}

.tw-text-cyan-900\/15 {
  color: rgb(22 78 99 / 0.15) !important;
}

.tw-text-cyan-900\/20 {
  color: rgb(22 78 99 / 0.2) !important;
}

.tw-text-cyan-900\/25 {
  color: rgb(22 78 99 / 0.25) !important;
}

.tw-text-cyan-900\/30 {
  color: rgb(22 78 99 / 0.3) !important;
}

.tw-text-cyan-900\/35 {
  color: rgb(22 78 99 / 0.35) !important;
}

.tw-text-cyan-900\/40 {
  color: rgb(22 78 99 / 0.4) !important;
}

.tw-text-cyan-900\/45 {
  color: rgb(22 78 99 / 0.45) !important;
}

.tw-text-cyan-900\/5 {
  color: rgb(22 78 99 / 0.05) !important;
}

.tw-text-cyan-900\/50 {
  color: rgb(22 78 99 / 0.5) !important;
}

.tw-text-cyan-900\/55 {
  color: rgb(22 78 99 / 0.55) !important;
}

.tw-text-cyan-900\/60 {
  color: rgb(22 78 99 / 0.6) !important;
}

.tw-text-cyan-900\/65 {
  color: rgb(22 78 99 / 0.65) !important;
}

.tw-text-cyan-900\/70 {
  color: rgb(22 78 99 / 0.7) !important;
}

.tw-text-cyan-900\/75 {
  color: rgb(22 78 99 / 0.75) !important;
}

.tw-text-cyan-900\/80 {
  color: rgb(22 78 99 / 0.8) !important;
}

.tw-text-cyan-900\/85 {
  color: rgb(22 78 99 / 0.85) !important;
}

.tw-text-cyan-900\/90 {
  color: rgb(22 78 99 / 0.9) !important;
}

.tw-text-cyan-900\/95 {
  color: rgb(22 78 99 / 0.95) !important;
}

.tw-text-cyan-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(8 51 68 / var(--tw-text-opacity)) !important;
}

.tw-text-cyan-950\/0 {
  color: rgb(8 51 68 / 0) !important;
}

.tw-text-cyan-950\/10 {
  color: rgb(8 51 68 / 0.1) !important;
}

.tw-text-cyan-950\/100 {
  color: rgb(8 51 68 / 1) !important;
}

.tw-text-cyan-950\/15 {
  color: rgb(8 51 68 / 0.15) !important;
}

.tw-text-cyan-950\/20 {
  color: rgb(8 51 68 / 0.2) !important;
}

.tw-text-cyan-950\/25 {
  color: rgb(8 51 68 / 0.25) !important;
}

.tw-text-cyan-950\/30 {
  color: rgb(8 51 68 / 0.3) !important;
}

.tw-text-cyan-950\/35 {
  color: rgb(8 51 68 / 0.35) !important;
}

.tw-text-cyan-950\/40 {
  color: rgb(8 51 68 / 0.4) !important;
}

.tw-text-cyan-950\/45 {
  color: rgb(8 51 68 / 0.45) !important;
}

.tw-text-cyan-950\/5 {
  color: rgb(8 51 68 / 0.05) !important;
}

.tw-text-cyan-950\/50 {
  color: rgb(8 51 68 / 0.5) !important;
}

.tw-text-cyan-950\/55 {
  color: rgb(8 51 68 / 0.55) !important;
}

.tw-text-cyan-950\/60 {
  color: rgb(8 51 68 / 0.6) !important;
}

.tw-text-cyan-950\/65 {
  color: rgb(8 51 68 / 0.65) !important;
}

.tw-text-cyan-950\/70 {
  color: rgb(8 51 68 / 0.7) !important;
}

.tw-text-cyan-950\/75 {
  color: rgb(8 51 68 / 0.75) !important;
}

.tw-text-cyan-950\/80 {
  color: rgb(8 51 68 / 0.8) !important;
}

.tw-text-cyan-950\/85 {
  color: rgb(8 51 68 / 0.85) !important;
}

.tw-text-cyan-950\/90 {
  color: rgb(8 51 68 / 0.9) !important;
}

.tw-text-cyan-950\/95 {
  color: rgb(8 51 68 / 0.95) !important;
}

.tw-text-emerald-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 250 229 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-100\/0 {
  color: rgb(209 250 229 / 0) !important;
}

.tw-text-emerald-100\/10 {
  color: rgb(209 250 229 / 0.1) !important;
}

.tw-text-emerald-100\/100 {
  color: rgb(209 250 229 / 1) !important;
}

.tw-text-emerald-100\/15 {
  color: rgb(209 250 229 / 0.15) !important;
}

.tw-text-emerald-100\/20 {
  color: rgb(209 250 229 / 0.2) !important;
}

.tw-text-emerald-100\/25 {
  color: rgb(209 250 229 / 0.25) !important;
}

.tw-text-emerald-100\/30 {
  color: rgb(209 250 229 / 0.3) !important;
}

.tw-text-emerald-100\/35 {
  color: rgb(209 250 229 / 0.35) !important;
}

.tw-text-emerald-100\/40 {
  color: rgb(209 250 229 / 0.4) !important;
}

.tw-text-emerald-100\/45 {
  color: rgb(209 250 229 / 0.45) !important;
}

.tw-text-emerald-100\/5 {
  color: rgb(209 250 229 / 0.05) !important;
}

.tw-text-emerald-100\/50 {
  color: rgb(209 250 229 / 0.5) !important;
}

.tw-text-emerald-100\/55 {
  color: rgb(209 250 229 / 0.55) !important;
}

.tw-text-emerald-100\/60 {
  color: rgb(209 250 229 / 0.6) !important;
}

.tw-text-emerald-100\/65 {
  color: rgb(209 250 229 / 0.65) !important;
}

.tw-text-emerald-100\/70 {
  color: rgb(209 250 229 / 0.7) !important;
}

.tw-text-emerald-100\/75 {
  color: rgb(209 250 229 / 0.75) !important;
}

.tw-text-emerald-100\/80 {
  color: rgb(209 250 229 / 0.8) !important;
}

.tw-text-emerald-100\/85 {
  color: rgb(209 250 229 / 0.85) !important;
}

.tw-text-emerald-100\/90 {
  color: rgb(209 250 229 / 0.9) !important;
}

.tw-text-emerald-100\/95 {
  color: rgb(209 250 229 / 0.95) !important;
}

.tw-text-emerald-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(167 243 208 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-200\/0 {
  color: rgb(167 243 208 / 0) !important;
}

.tw-text-emerald-200\/10 {
  color: rgb(167 243 208 / 0.1) !important;
}

.tw-text-emerald-200\/100 {
  color: rgb(167 243 208 / 1) !important;
}

.tw-text-emerald-200\/15 {
  color: rgb(167 243 208 / 0.15) !important;
}

.tw-text-emerald-200\/20 {
  color: rgb(167 243 208 / 0.2) !important;
}

.tw-text-emerald-200\/25 {
  color: rgb(167 243 208 / 0.25) !important;
}

.tw-text-emerald-200\/30 {
  color: rgb(167 243 208 / 0.3) !important;
}

.tw-text-emerald-200\/35 {
  color: rgb(167 243 208 / 0.35) !important;
}

.tw-text-emerald-200\/40 {
  color: rgb(167 243 208 / 0.4) !important;
}

.tw-text-emerald-200\/45 {
  color: rgb(167 243 208 / 0.45) !important;
}

.tw-text-emerald-200\/5 {
  color: rgb(167 243 208 / 0.05) !important;
}

.tw-text-emerald-200\/50 {
  color: rgb(167 243 208 / 0.5) !important;
}

.tw-text-emerald-200\/55 {
  color: rgb(167 243 208 / 0.55) !important;
}

.tw-text-emerald-200\/60 {
  color: rgb(167 243 208 / 0.6) !important;
}

.tw-text-emerald-200\/65 {
  color: rgb(167 243 208 / 0.65) !important;
}

.tw-text-emerald-200\/70 {
  color: rgb(167 243 208 / 0.7) !important;
}

.tw-text-emerald-200\/75 {
  color: rgb(167 243 208 / 0.75) !important;
}

.tw-text-emerald-200\/80 {
  color: rgb(167 243 208 / 0.8) !important;
}

.tw-text-emerald-200\/85 {
  color: rgb(167 243 208 / 0.85) !important;
}

.tw-text-emerald-200\/90 {
  color: rgb(167 243 208 / 0.9) !important;
}

.tw-text-emerald-200\/95 {
  color: rgb(167 243 208 / 0.95) !important;
}

.tw-text-emerald-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(110 231 183 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-300\/0 {
  color: rgb(110 231 183 / 0) !important;
}

.tw-text-emerald-300\/10 {
  color: rgb(110 231 183 / 0.1) !important;
}

.tw-text-emerald-300\/100 {
  color: rgb(110 231 183 / 1) !important;
}

.tw-text-emerald-300\/15 {
  color: rgb(110 231 183 / 0.15) !important;
}

.tw-text-emerald-300\/20 {
  color: rgb(110 231 183 / 0.2) !important;
}

.tw-text-emerald-300\/25 {
  color: rgb(110 231 183 / 0.25) !important;
}

.tw-text-emerald-300\/30 {
  color: rgb(110 231 183 / 0.3) !important;
}

.tw-text-emerald-300\/35 {
  color: rgb(110 231 183 / 0.35) !important;
}

.tw-text-emerald-300\/40 {
  color: rgb(110 231 183 / 0.4) !important;
}

.tw-text-emerald-300\/45 {
  color: rgb(110 231 183 / 0.45) !important;
}

.tw-text-emerald-300\/5 {
  color: rgb(110 231 183 / 0.05) !important;
}

.tw-text-emerald-300\/50 {
  color: rgb(110 231 183 / 0.5) !important;
}

.tw-text-emerald-300\/55 {
  color: rgb(110 231 183 / 0.55) !important;
}

.tw-text-emerald-300\/60 {
  color: rgb(110 231 183 / 0.6) !important;
}

.tw-text-emerald-300\/65 {
  color: rgb(110 231 183 / 0.65) !important;
}

.tw-text-emerald-300\/70 {
  color: rgb(110 231 183 / 0.7) !important;
}

.tw-text-emerald-300\/75 {
  color: rgb(110 231 183 / 0.75) !important;
}

.tw-text-emerald-300\/80 {
  color: rgb(110 231 183 / 0.8) !important;
}

.tw-text-emerald-300\/85 {
  color: rgb(110 231 183 / 0.85) !important;
}

.tw-text-emerald-300\/90 {
  color: rgb(110 231 183 / 0.9) !important;
}

.tw-text-emerald-300\/95 {
  color: rgb(110 231 183 / 0.95) !important;
}

.tw-text-emerald-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(52 211 153 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-400\/0 {
  color: rgb(52 211 153 / 0) !important;
}

.tw-text-emerald-400\/10 {
  color: rgb(52 211 153 / 0.1) !important;
}

.tw-text-emerald-400\/100 {
  color: rgb(52 211 153 / 1) !important;
}

.tw-text-emerald-400\/15 {
  color: rgb(52 211 153 / 0.15) !important;
}

.tw-text-emerald-400\/20 {
  color: rgb(52 211 153 / 0.2) !important;
}

.tw-text-emerald-400\/25 {
  color: rgb(52 211 153 / 0.25) !important;
}

.tw-text-emerald-400\/30 {
  color: rgb(52 211 153 / 0.3) !important;
}

.tw-text-emerald-400\/35 {
  color: rgb(52 211 153 / 0.35) !important;
}

.tw-text-emerald-400\/40 {
  color: rgb(52 211 153 / 0.4) !important;
}

.tw-text-emerald-400\/45 {
  color: rgb(52 211 153 / 0.45) !important;
}

.tw-text-emerald-400\/5 {
  color: rgb(52 211 153 / 0.05) !important;
}

.tw-text-emerald-400\/50 {
  color: rgb(52 211 153 / 0.5) !important;
}

.tw-text-emerald-400\/55 {
  color: rgb(52 211 153 / 0.55) !important;
}

.tw-text-emerald-400\/60 {
  color: rgb(52 211 153 / 0.6) !important;
}

.tw-text-emerald-400\/65 {
  color: rgb(52 211 153 / 0.65) !important;
}

.tw-text-emerald-400\/70 {
  color: rgb(52 211 153 / 0.7) !important;
}

.tw-text-emerald-400\/75 {
  color: rgb(52 211 153 / 0.75) !important;
}

.tw-text-emerald-400\/80 {
  color: rgb(52 211 153 / 0.8) !important;
}

.tw-text-emerald-400\/85 {
  color: rgb(52 211 153 / 0.85) !important;
}

.tw-text-emerald-400\/90 {
  color: rgb(52 211 153 / 0.9) !important;
}

.tw-text-emerald-400\/95 {
  color: rgb(52 211 153 / 0.95) !important;
}

.tw-text-emerald-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(236 253 245 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-50\/0 {
  color: rgb(236 253 245 / 0) !important;
}

.tw-text-emerald-50\/10 {
  color: rgb(236 253 245 / 0.1) !important;
}

.tw-text-emerald-50\/100 {
  color: rgb(236 253 245 / 1) !important;
}

.tw-text-emerald-50\/15 {
  color: rgb(236 253 245 / 0.15) !important;
}

.tw-text-emerald-50\/20 {
  color: rgb(236 253 245 / 0.2) !important;
}

.tw-text-emerald-50\/25 {
  color: rgb(236 253 245 / 0.25) !important;
}

.tw-text-emerald-50\/30 {
  color: rgb(236 253 245 / 0.3) !important;
}

.tw-text-emerald-50\/35 {
  color: rgb(236 253 245 / 0.35) !important;
}

.tw-text-emerald-50\/40 {
  color: rgb(236 253 245 / 0.4) !important;
}

.tw-text-emerald-50\/45 {
  color: rgb(236 253 245 / 0.45) !important;
}

.tw-text-emerald-50\/5 {
  color: rgb(236 253 245 / 0.05) !important;
}

.tw-text-emerald-50\/50 {
  color: rgb(236 253 245 / 0.5) !important;
}

.tw-text-emerald-50\/55 {
  color: rgb(236 253 245 / 0.55) !important;
}

.tw-text-emerald-50\/60 {
  color: rgb(236 253 245 / 0.6) !important;
}

.tw-text-emerald-50\/65 {
  color: rgb(236 253 245 / 0.65) !important;
}

.tw-text-emerald-50\/70 {
  color: rgb(236 253 245 / 0.7) !important;
}

.tw-text-emerald-50\/75 {
  color: rgb(236 253 245 / 0.75) !important;
}

.tw-text-emerald-50\/80 {
  color: rgb(236 253 245 / 0.8) !important;
}

.tw-text-emerald-50\/85 {
  color: rgb(236 253 245 / 0.85) !important;
}

.tw-text-emerald-50\/90 {
  color: rgb(236 253 245 / 0.9) !important;
}

.tw-text-emerald-50\/95 {
  color: rgb(236 253 245 / 0.95) !important;
}

.tw-text-emerald-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(16 185 129 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-500\/0 {
  color: rgb(16 185 129 / 0) !important;
}

.tw-text-emerald-500\/10 {
  color: rgb(16 185 129 / 0.1) !important;
}

.tw-text-emerald-500\/100 {
  color: rgb(16 185 129 / 1) !important;
}

.tw-text-emerald-500\/15 {
  color: rgb(16 185 129 / 0.15) !important;
}

.tw-text-emerald-500\/20 {
  color: rgb(16 185 129 / 0.2) !important;
}

.tw-text-emerald-500\/25 {
  color: rgb(16 185 129 / 0.25) !important;
}

.tw-text-emerald-500\/30 {
  color: rgb(16 185 129 / 0.3) !important;
}

.tw-text-emerald-500\/35 {
  color: rgb(16 185 129 / 0.35) !important;
}

.tw-text-emerald-500\/40 {
  color: rgb(16 185 129 / 0.4) !important;
}

.tw-text-emerald-500\/45 {
  color: rgb(16 185 129 / 0.45) !important;
}

.tw-text-emerald-500\/5 {
  color: rgb(16 185 129 / 0.05) !important;
}

.tw-text-emerald-500\/50 {
  color: rgb(16 185 129 / 0.5) !important;
}

.tw-text-emerald-500\/55 {
  color: rgb(16 185 129 / 0.55) !important;
}

.tw-text-emerald-500\/60 {
  color: rgb(16 185 129 / 0.6) !important;
}

.tw-text-emerald-500\/65 {
  color: rgb(16 185 129 / 0.65) !important;
}

.tw-text-emerald-500\/70 {
  color: rgb(16 185 129 / 0.7) !important;
}

.tw-text-emerald-500\/75 {
  color: rgb(16 185 129 / 0.75) !important;
}

.tw-text-emerald-500\/80 {
  color: rgb(16 185 129 / 0.8) !important;
}

.tw-text-emerald-500\/85 {
  color: rgb(16 185 129 / 0.85) !important;
}

.tw-text-emerald-500\/90 {
  color: rgb(16 185 129 / 0.9) !important;
}

.tw-text-emerald-500\/95 {
  color: rgb(16 185 129 / 0.95) !important;
}

.tw-text-emerald-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(5 150 105 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-600\/0 {
  color: rgb(5 150 105 / 0) !important;
}

.tw-text-emerald-600\/10 {
  color: rgb(5 150 105 / 0.1) !important;
}

.tw-text-emerald-600\/100 {
  color: rgb(5 150 105 / 1) !important;
}

.tw-text-emerald-600\/15 {
  color: rgb(5 150 105 / 0.15) !important;
}

.tw-text-emerald-600\/20 {
  color: rgb(5 150 105 / 0.2) !important;
}

.tw-text-emerald-600\/25 {
  color: rgb(5 150 105 / 0.25) !important;
}

.tw-text-emerald-600\/30 {
  color: rgb(5 150 105 / 0.3) !important;
}

.tw-text-emerald-600\/35 {
  color: rgb(5 150 105 / 0.35) !important;
}

.tw-text-emerald-600\/40 {
  color: rgb(5 150 105 / 0.4) !important;
}

.tw-text-emerald-600\/45 {
  color: rgb(5 150 105 / 0.45) !important;
}

.tw-text-emerald-600\/5 {
  color: rgb(5 150 105 / 0.05) !important;
}

.tw-text-emerald-600\/50 {
  color: rgb(5 150 105 / 0.5) !important;
}

.tw-text-emerald-600\/55 {
  color: rgb(5 150 105 / 0.55) !important;
}

.tw-text-emerald-600\/60 {
  color: rgb(5 150 105 / 0.6) !important;
}

.tw-text-emerald-600\/65 {
  color: rgb(5 150 105 / 0.65) !important;
}

.tw-text-emerald-600\/70 {
  color: rgb(5 150 105 / 0.7) !important;
}

.tw-text-emerald-600\/75 {
  color: rgb(5 150 105 / 0.75) !important;
}

.tw-text-emerald-600\/80 {
  color: rgb(5 150 105 / 0.8) !important;
}

.tw-text-emerald-600\/85 {
  color: rgb(5 150 105 / 0.85) !important;
}

.tw-text-emerald-600\/90 {
  color: rgb(5 150 105 / 0.9) !important;
}

.tw-text-emerald-600\/95 {
  color: rgb(5 150 105 / 0.95) !important;
}

.tw-text-emerald-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(4 120 87 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-700\/0 {
  color: rgb(4 120 87 / 0) !important;
}

.tw-text-emerald-700\/10 {
  color: rgb(4 120 87 / 0.1) !important;
}

.tw-text-emerald-700\/100 {
  color: rgb(4 120 87 / 1) !important;
}

.tw-text-emerald-700\/15 {
  color: rgb(4 120 87 / 0.15) !important;
}

.tw-text-emerald-700\/20 {
  color: rgb(4 120 87 / 0.2) !important;
}

.tw-text-emerald-700\/25 {
  color: rgb(4 120 87 / 0.25) !important;
}

.tw-text-emerald-700\/30 {
  color: rgb(4 120 87 / 0.3) !important;
}

.tw-text-emerald-700\/35 {
  color: rgb(4 120 87 / 0.35) !important;
}

.tw-text-emerald-700\/40 {
  color: rgb(4 120 87 / 0.4) !important;
}

.tw-text-emerald-700\/45 {
  color: rgb(4 120 87 / 0.45) !important;
}

.tw-text-emerald-700\/5 {
  color: rgb(4 120 87 / 0.05) !important;
}

.tw-text-emerald-700\/50 {
  color: rgb(4 120 87 / 0.5) !important;
}

.tw-text-emerald-700\/55 {
  color: rgb(4 120 87 / 0.55) !important;
}

.tw-text-emerald-700\/60 {
  color: rgb(4 120 87 / 0.6) !important;
}

.tw-text-emerald-700\/65 {
  color: rgb(4 120 87 / 0.65) !important;
}

.tw-text-emerald-700\/70 {
  color: rgb(4 120 87 / 0.7) !important;
}

.tw-text-emerald-700\/75 {
  color: rgb(4 120 87 / 0.75) !important;
}

.tw-text-emerald-700\/80 {
  color: rgb(4 120 87 / 0.8) !important;
}

.tw-text-emerald-700\/85 {
  color: rgb(4 120 87 / 0.85) !important;
}

.tw-text-emerald-700\/90 {
  color: rgb(4 120 87 / 0.9) !important;
}

.tw-text-emerald-700\/95 {
  color: rgb(4 120 87 / 0.95) !important;
}

.tw-text-emerald-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(6 95 70 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-800\/0 {
  color: rgb(6 95 70 / 0) !important;
}

.tw-text-emerald-800\/10 {
  color: rgb(6 95 70 / 0.1) !important;
}

.tw-text-emerald-800\/100 {
  color: rgb(6 95 70 / 1) !important;
}

.tw-text-emerald-800\/15 {
  color: rgb(6 95 70 / 0.15) !important;
}

.tw-text-emerald-800\/20 {
  color: rgb(6 95 70 / 0.2) !important;
}

.tw-text-emerald-800\/25 {
  color: rgb(6 95 70 / 0.25) !important;
}

.tw-text-emerald-800\/30 {
  color: rgb(6 95 70 / 0.3) !important;
}

.tw-text-emerald-800\/35 {
  color: rgb(6 95 70 / 0.35) !important;
}

.tw-text-emerald-800\/40 {
  color: rgb(6 95 70 / 0.4) !important;
}

.tw-text-emerald-800\/45 {
  color: rgb(6 95 70 / 0.45) !important;
}

.tw-text-emerald-800\/5 {
  color: rgb(6 95 70 / 0.05) !important;
}

.tw-text-emerald-800\/50 {
  color: rgb(6 95 70 / 0.5) !important;
}

.tw-text-emerald-800\/55 {
  color: rgb(6 95 70 / 0.55) !important;
}

.tw-text-emerald-800\/60 {
  color: rgb(6 95 70 / 0.6) !important;
}

.tw-text-emerald-800\/65 {
  color: rgb(6 95 70 / 0.65) !important;
}

.tw-text-emerald-800\/70 {
  color: rgb(6 95 70 / 0.7) !important;
}

.tw-text-emerald-800\/75 {
  color: rgb(6 95 70 / 0.75) !important;
}

.tw-text-emerald-800\/80 {
  color: rgb(6 95 70 / 0.8) !important;
}

.tw-text-emerald-800\/85 {
  color: rgb(6 95 70 / 0.85) !important;
}

.tw-text-emerald-800\/90 {
  color: rgb(6 95 70 / 0.9) !important;
}

.tw-text-emerald-800\/95 {
  color: rgb(6 95 70 / 0.95) !important;
}

.tw-text-emerald-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(6 78 59 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-900\/0 {
  color: rgb(6 78 59 / 0) !important;
}

.tw-text-emerald-900\/10 {
  color: rgb(6 78 59 / 0.1) !important;
}

.tw-text-emerald-900\/100 {
  color: rgb(6 78 59 / 1) !important;
}

.tw-text-emerald-900\/15 {
  color: rgb(6 78 59 / 0.15) !important;
}

.tw-text-emerald-900\/20 {
  color: rgb(6 78 59 / 0.2) !important;
}

.tw-text-emerald-900\/25 {
  color: rgb(6 78 59 / 0.25) !important;
}

.tw-text-emerald-900\/30 {
  color: rgb(6 78 59 / 0.3) !important;
}

.tw-text-emerald-900\/35 {
  color: rgb(6 78 59 / 0.35) !important;
}

.tw-text-emerald-900\/40 {
  color: rgb(6 78 59 / 0.4) !important;
}

.tw-text-emerald-900\/45 {
  color: rgb(6 78 59 / 0.45) !important;
}

.tw-text-emerald-900\/5 {
  color: rgb(6 78 59 / 0.05) !important;
}

.tw-text-emerald-900\/50 {
  color: rgb(6 78 59 / 0.5) !important;
}

.tw-text-emerald-900\/55 {
  color: rgb(6 78 59 / 0.55) !important;
}

.tw-text-emerald-900\/60 {
  color: rgb(6 78 59 / 0.6) !important;
}

.tw-text-emerald-900\/65 {
  color: rgb(6 78 59 / 0.65) !important;
}

.tw-text-emerald-900\/70 {
  color: rgb(6 78 59 / 0.7) !important;
}

.tw-text-emerald-900\/75 {
  color: rgb(6 78 59 / 0.75) !important;
}

.tw-text-emerald-900\/80 {
  color: rgb(6 78 59 / 0.8) !important;
}

.tw-text-emerald-900\/85 {
  color: rgb(6 78 59 / 0.85) !important;
}

.tw-text-emerald-900\/90 {
  color: rgb(6 78 59 / 0.9) !important;
}

.tw-text-emerald-900\/95 {
  color: rgb(6 78 59 / 0.95) !important;
}

.tw-text-emerald-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(2 44 34 / var(--tw-text-opacity)) !important;
}

.tw-text-emerald-950\/0 {
  color: rgb(2 44 34 / 0) !important;
}

.tw-text-emerald-950\/10 {
  color: rgb(2 44 34 / 0.1) !important;
}

.tw-text-emerald-950\/100 {
  color: rgb(2 44 34 / 1) !important;
}

.tw-text-emerald-950\/15 {
  color: rgb(2 44 34 / 0.15) !important;
}

.tw-text-emerald-950\/20 {
  color: rgb(2 44 34 / 0.2) !important;
}

.tw-text-emerald-950\/25 {
  color: rgb(2 44 34 / 0.25) !important;
}

.tw-text-emerald-950\/30 {
  color: rgb(2 44 34 / 0.3) !important;
}

.tw-text-emerald-950\/35 {
  color: rgb(2 44 34 / 0.35) !important;
}

.tw-text-emerald-950\/40 {
  color: rgb(2 44 34 / 0.4) !important;
}

.tw-text-emerald-950\/45 {
  color: rgb(2 44 34 / 0.45) !important;
}

.tw-text-emerald-950\/5 {
  color: rgb(2 44 34 / 0.05) !important;
}

.tw-text-emerald-950\/50 {
  color: rgb(2 44 34 / 0.5) !important;
}

.tw-text-emerald-950\/55 {
  color: rgb(2 44 34 / 0.55) !important;
}

.tw-text-emerald-950\/60 {
  color: rgb(2 44 34 / 0.6) !important;
}

.tw-text-emerald-950\/65 {
  color: rgb(2 44 34 / 0.65) !important;
}

.tw-text-emerald-950\/70 {
  color: rgb(2 44 34 / 0.7) !important;
}

.tw-text-emerald-950\/75 {
  color: rgb(2 44 34 / 0.75) !important;
}

.tw-text-emerald-950\/80 {
  color: rgb(2 44 34 / 0.8) !important;
}

.tw-text-emerald-950\/85 {
  color: rgb(2 44 34 / 0.85) !important;
}

.tw-text-emerald-950\/90 {
  color: rgb(2 44 34 / 0.9) !important;
}

.tw-text-emerald-950\/95 {
  color: rgb(2 44 34 / 0.95) !important;
}

.tw-text-fuchsia-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(250 232 255 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-100\/0 {
  color: rgb(250 232 255 / 0) !important;
}

.tw-text-fuchsia-100\/10 {
  color: rgb(250 232 255 / 0.1) !important;
}

.tw-text-fuchsia-100\/100 {
  color: rgb(250 232 255 / 1) !important;
}

.tw-text-fuchsia-100\/15 {
  color: rgb(250 232 255 / 0.15) !important;
}

.tw-text-fuchsia-100\/20 {
  color: rgb(250 232 255 / 0.2) !important;
}

.tw-text-fuchsia-100\/25 {
  color: rgb(250 232 255 / 0.25) !important;
}

.tw-text-fuchsia-100\/30 {
  color: rgb(250 232 255 / 0.3) !important;
}

.tw-text-fuchsia-100\/35 {
  color: rgb(250 232 255 / 0.35) !important;
}

.tw-text-fuchsia-100\/40 {
  color: rgb(250 232 255 / 0.4) !important;
}

.tw-text-fuchsia-100\/45 {
  color: rgb(250 232 255 / 0.45) !important;
}

.tw-text-fuchsia-100\/5 {
  color: rgb(250 232 255 / 0.05) !important;
}

.tw-text-fuchsia-100\/50 {
  color: rgb(250 232 255 / 0.5) !important;
}

.tw-text-fuchsia-100\/55 {
  color: rgb(250 232 255 / 0.55) !important;
}

.tw-text-fuchsia-100\/60 {
  color: rgb(250 232 255 / 0.6) !important;
}

.tw-text-fuchsia-100\/65 {
  color: rgb(250 232 255 / 0.65) !important;
}

.tw-text-fuchsia-100\/70 {
  color: rgb(250 232 255 / 0.7) !important;
}

.tw-text-fuchsia-100\/75 {
  color: rgb(250 232 255 / 0.75) !important;
}

.tw-text-fuchsia-100\/80 {
  color: rgb(250 232 255 / 0.8) !important;
}

.tw-text-fuchsia-100\/85 {
  color: rgb(250 232 255 / 0.85) !important;
}

.tw-text-fuchsia-100\/90 {
  color: rgb(250 232 255 / 0.9) !important;
}

.tw-text-fuchsia-100\/95 {
  color: rgb(250 232 255 / 0.95) !important;
}

.tw-text-fuchsia-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 208 254 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-200\/0 {
  color: rgb(245 208 254 / 0) !important;
}

.tw-text-fuchsia-200\/10 {
  color: rgb(245 208 254 / 0.1) !important;
}

.tw-text-fuchsia-200\/100 {
  color: rgb(245 208 254 / 1) !important;
}

.tw-text-fuchsia-200\/15 {
  color: rgb(245 208 254 / 0.15) !important;
}

.tw-text-fuchsia-200\/20 {
  color: rgb(245 208 254 / 0.2) !important;
}

.tw-text-fuchsia-200\/25 {
  color: rgb(245 208 254 / 0.25) !important;
}

.tw-text-fuchsia-200\/30 {
  color: rgb(245 208 254 / 0.3) !important;
}

.tw-text-fuchsia-200\/35 {
  color: rgb(245 208 254 / 0.35) !important;
}

.tw-text-fuchsia-200\/40 {
  color: rgb(245 208 254 / 0.4) !important;
}

.tw-text-fuchsia-200\/45 {
  color: rgb(245 208 254 / 0.45) !important;
}

.tw-text-fuchsia-200\/5 {
  color: rgb(245 208 254 / 0.05) !important;
}

.tw-text-fuchsia-200\/50 {
  color: rgb(245 208 254 / 0.5) !important;
}

.tw-text-fuchsia-200\/55 {
  color: rgb(245 208 254 / 0.55) !important;
}

.tw-text-fuchsia-200\/60 {
  color: rgb(245 208 254 / 0.6) !important;
}

.tw-text-fuchsia-200\/65 {
  color: rgb(245 208 254 / 0.65) !important;
}

.tw-text-fuchsia-200\/70 {
  color: rgb(245 208 254 / 0.7) !important;
}

.tw-text-fuchsia-200\/75 {
  color: rgb(245 208 254 / 0.75) !important;
}

.tw-text-fuchsia-200\/80 {
  color: rgb(245 208 254 / 0.8) !important;
}

.tw-text-fuchsia-200\/85 {
  color: rgb(245 208 254 / 0.85) !important;
}

.tw-text-fuchsia-200\/90 {
  color: rgb(245 208 254 / 0.9) !important;
}

.tw-text-fuchsia-200\/95 {
  color: rgb(245 208 254 / 0.95) !important;
}

.tw-text-fuchsia-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(240 171 252 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-300\/0 {
  color: rgb(240 171 252 / 0) !important;
}

.tw-text-fuchsia-300\/10 {
  color: rgb(240 171 252 / 0.1) !important;
}

.tw-text-fuchsia-300\/100 {
  color: rgb(240 171 252 / 1) !important;
}

.tw-text-fuchsia-300\/15 {
  color: rgb(240 171 252 / 0.15) !important;
}

.tw-text-fuchsia-300\/20 {
  color: rgb(240 171 252 / 0.2) !important;
}

.tw-text-fuchsia-300\/25 {
  color: rgb(240 171 252 / 0.25) !important;
}

.tw-text-fuchsia-300\/30 {
  color: rgb(240 171 252 / 0.3) !important;
}

.tw-text-fuchsia-300\/35 {
  color: rgb(240 171 252 / 0.35) !important;
}

.tw-text-fuchsia-300\/40 {
  color: rgb(240 171 252 / 0.4) !important;
}

.tw-text-fuchsia-300\/45 {
  color: rgb(240 171 252 / 0.45) !important;
}

.tw-text-fuchsia-300\/5 {
  color: rgb(240 171 252 / 0.05) !important;
}

.tw-text-fuchsia-300\/50 {
  color: rgb(240 171 252 / 0.5) !important;
}

.tw-text-fuchsia-300\/55 {
  color: rgb(240 171 252 / 0.55) !important;
}

.tw-text-fuchsia-300\/60 {
  color: rgb(240 171 252 / 0.6) !important;
}

.tw-text-fuchsia-300\/65 {
  color: rgb(240 171 252 / 0.65) !important;
}

.tw-text-fuchsia-300\/70 {
  color: rgb(240 171 252 / 0.7) !important;
}

.tw-text-fuchsia-300\/75 {
  color: rgb(240 171 252 / 0.75) !important;
}

.tw-text-fuchsia-300\/80 {
  color: rgb(240 171 252 / 0.8) !important;
}

.tw-text-fuchsia-300\/85 {
  color: rgb(240 171 252 / 0.85) !important;
}

.tw-text-fuchsia-300\/90 {
  color: rgb(240 171 252 / 0.9) !important;
}

.tw-text-fuchsia-300\/95 {
  color: rgb(240 171 252 / 0.95) !important;
}

.tw-text-fuchsia-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(232 121 249 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-400\/0 {
  color: rgb(232 121 249 / 0) !important;
}

.tw-text-fuchsia-400\/10 {
  color: rgb(232 121 249 / 0.1) !important;
}

.tw-text-fuchsia-400\/100 {
  color: rgb(232 121 249 / 1) !important;
}

.tw-text-fuchsia-400\/15 {
  color: rgb(232 121 249 / 0.15) !important;
}

.tw-text-fuchsia-400\/20 {
  color: rgb(232 121 249 / 0.2) !important;
}

.tw-text-fuchsia-400\/25 {
  color: rgb(232 121 249 / 0.25) !important;
}

.tw-text-fuchsia-400\/30 {
  color: rgb(232 121 249 / 0.3) !important;
}

.tw-text-fuchsia-400\/35 {
  color: rgb(232 121 249 / 0.35) !important;
}

.tw-text-fuchsia-400\/40 {
  color: rgb(232 121 249 / 0.4) !important;
}

.tw-text-fuchsia-400\/45 {
  color: rgb(232 121 249 / 0.45) !important;
}

.tw-text-fuchsia-400\/5 {
  color: rgb(232 121 249 / 0.05) !important;
}

.tw-text-fuchsia-400\/50 {
  color: rgb(232 121 249 / 0.5) !important;
}

.tw-text-fuchsia-400\/55 {
  color: rgb(232 121 249 / 0.55) !important;
}

.tw-text-fuchsia-400\/60 {
  color: rgb(232 121 249 / 0.6) !important;
}

.tw-text-fuchsia-400\/65 {
  color: rgb(232 121 249 / 0.65) !important;
}

.tw-text-fuchsia-400\/70 {
  color: rgb(232 121 249 / 0.7) !important;
}

.tw-text-fuchsia-400\/75 {
  color: rgb(232 121 249 / 0.75) !important;
}

.tw-text-fuchsia-400\/80 {
  color: rgb(232 121 249 / 0.8) !important;
}

.tw-text-fuchsia-400\/85 {
  color: rgb(232 121 249 / 0.85) !important;
}

.tw-text-fuchsia-400\/90 {
  color: rgb(232 121 249 / 0.9) !important;
}

.tw-text-fuchsia-400\/95 {
  color: rgb(232 121 249 / 0.95) !important;
}

.tw-text-fuchsia-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 244 255 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-50\/0 {
  color: rgb(253 244 255 / 0) !important;
}

.tw-text-fuchsia-50\/10 {
  color: rgb(253 244 255 / 0.1) !important;
}

.tw-text-fuchsia-50\/100 {
  color: rgb(253 244 255 / 1) !important;
}

.tw-text-fuchsia-50\/15 {
  color: rgb(253 244 255 / 0.15) !important;
}

.tw-text-fuchsia-50\/20 {
  color: rgb(253 244 255 / 0.2) !important;
}

.tw-text-fuchsia-50\/25 {
  color: rgb(253 244 255 / 0.25) !important;
}

.tw-text-fuchsia-50\/30 {
  color: rgb(253 244 255 / 0.3) !important;
}

.tw-text-fuchsia-50\/35 {
  color: rgb(253 244 255 / 0.35) !important;
}

.tw-text-fuchsia-50\/40 {
  color: rgb(253 244 255 / 0.4) !important;
}

.tw-text-fuchsia-50\/45 {
  color: rgb(253 244 255 / 0.45) !important;
}

.tw-text-fuchsia-50\/5 {
  color: rgb(253 244 255 / 0.05) !important;
}

.tw-text-fuchsia-50\/50 {
  color: rgb(253 244 255 / 0.5) !important;
}

.tw-text-fuchsia-50\/55 {
  color: rgb(253 244 255 / 0.55) !important;
}

.tw-text-fuchsia-50\/60 {
  color: rgb(253 244 255 / 0.6) !important;
}

.tw-text-fuchsia-50\/65 {
  color: rgb(253 244 255 / 0.65) !important;
}

.tw-text-fuchsia-50\/70 {
  color: rgb(253 244 255 / 0.7) !important;
}

.tw-text-fuchsia-50\/75 {
  color: rgb(253 244 255 / 0.75) !important;
}

.tw-text-fuchsia-50\/80 {
  color: rgb(253 244 255 / 0.8) !important;
}

.tw-text-fuchsia-50\/85 {
  color: rgb(253 244 255 / 0.85) !important;
}

.tw-text-fuchsia-50\/90 {
  color: rgb(253 244 255 / 0.9) !important;
}

.tw-text-fuchsia-50\/95 {
  color: rgb(253 244 255 / 0.95) !important;
}

.tw-text-fuchsia-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(217 70 239 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-500\/0 {
  color: rgb(217 70 239 / 0) !important;
}

.tw-text-fuchsia-500\/10 {
  color: rgb(217 70 239 / 0.1) !important;
}

.tw-text-fuchsia-500\/100 {
  color: rgb(217 70 239 / 1) !important;
}

.tw-text-fuchsia-500\/15 {
  color: rgb(217 70 239 / 0.15) !important;
}

.tw-text-fuchsia-500\/20 {
  color: rgb(217 70 239 / 0.2) !important;
}

.tw-text-fuchsia-500\/25 {
  color: rgb(217 70 239 / 0.25) !important;
}

.tw-text-fuchsia-500\/30 {
  color: rgb(217 70 239 / 0.3) !important;
}

.tw-text-fuchsia-500\/35 {
  color: rgb(217 70 239 / 0.35) !important;
}

.tw-text-fuchsia-500\/40 {
  color: rgb(217 70 239 / 0.4) !important;
}

.tw-text-fuchsia-500\/45 {
  color: rgb(217 70 239 / 0.45) !important;
}

.tw-text-fuchsia-500\/5 {
  color: rgb(217 70 239 / 0.05) !important;
}

.tw-text-fuchsia-500\/50 {
  color: rgb(217 70 239 / 0.5) !important;
}

.tw-text-fuchsia-500\/55 {
  color: rgb(217 70 239 / 0.55) !important;
}

.tw-text-fuchsia-500\/60 {
  color: rgb(217 70 239 / 0.6) !important;
}

.tw-text-fuchsia-500\/65 {
  color: rgb(217 70 239 / 0.65) !important;
}

.tw-text-fuchsia-500\/70 {
  color: rgb(217 70 239 / 0.7) !important;
}

.tw-text-fuchsia-500\/75 {
  color: rgb(217 70 239 / 0.75) !important;
}

.tw-text-fuchsia-500\/80 {
  color: rgb(217 70 239 / 0.8) !important;
}

.tw-text-fuchsia-500\/85 {
  color: rgb(217 70 239 / 0.85) !important;
}

.tw-text-fuchsia-500\/90 {
  color: rgb(217 70 239 / 0.9) !important;
}

.tw-text-fuchsia-500\/95 {
  color: rgb(217 70 239 / 0.95) !important;
}

.tw-text-fuchsia-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(192 38 211 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-600\/0 {
  color: rgb(192 38 211 / 0) !important;
}

.tw-text-fuchsia-600\/10 {
  color: rgb(192 38 211 / 0.1) !important;
}

.tw-text-fuchsia-600\/100 {
  color: rgb(192 38 211 / 1) !important;
}

.tw-text-fuchsia-600\/15 {
  color: rgb(192 38 211 / 0.15) !important;
}

.tw-text-fuchsia-600\/20 {
  color: rgb(192 38 211 / 0.2) !important;
}

.tw-text-fuchsia-600\/25 {
  color: rgb(192 38 211 / 0.25) !important;
}

.tw-text-fuchsia-600\/30 {
  color: rgb(192 38 211 / 0.3) !important;
}

.tw-text-fuchsia-600\/35 {
  color: rgb(192 38 211 / 0.35) !important;
}

.tw-text-fuchsia-600\/40 {
  color: rgb(192 38 211 / 0.4) !important;
}

.tw-text-fuchsia-600\/45 {
  color: rgb(192 38 211 / 0.45) !important;
}

.tw-text-fuchsia-600\/5 {
  color: rgb(192 38 211 / 0.05) !important;
}

.tw-text-fuchsia-600\/50 {
  color: rgb(192 38 211 / 0.5) !important;
}

.tw-text-fuchsia-600\/55 {
  color: rgb(192 38 211 / 0.55) !important;
}

.tw-text-fuchsia-600\/60 {
  color: rgb(192 38 211 / 0.6) !important;
}

.tw-text-fuchsia-600\/65 {
  color: rgb(192 38 211 / 0.65) !important;
}

.tw-text-fuchsia-600\/70 {
  color: rgb(192 38 211 / 0.7) !important;
}

.tw-text-fuchsia-600\/75 {
  color: rgb(192 38 211 / 0.75) !important;
}

.tw-text-fuchsia-600\/80 {
  color: rgb(192 38 211 / 0.8) !important;
}

.tw-text-fuchsia-600\/85 {
  color: rgb(192 38 211 / 0.85) !important;
}

.tw-text-fuchsia-600\/90 {
  color: rgb(192 38 211 / 0.9) !important;
}

.tw-text-fuchsia-600\/95 {
  color: rgb(192 38 211 / 0.95) !important;
}

.tw-text-fuchsia-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(162 28 175 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-700\/0 {
  color: rgb(162 28 175 / 0) !important;
}

.tw-text-fuchsia-700\/10 {
  color: rgb(162 28 175 / 0.1) !important;
}

.tw-text-fuchsia-700\/100 {
  color: rgb(162 28 175 / 1) !important;
}

.tw-text-fuchsia-700\/15 {
  color: rgb(162 28 175 / 0.15) !important;
}

.tw-text-fuchsia-700\/20 {
  color: rgb(162 28 175 / 0.2) !important;
}

.tw-text-fuchsia-700\/25 {
  color: rgb(162 28 175 / 0.25) !important;
}

.tw-text-fuchsia-700\/30 {
  color: rgb(162 28 175 / 0.3) !important;
}

.tw-text-fuchsia-700\/35 {
  color: rgb(162 28 175 / 0.35) !important;
}

.tw-text-fuchsia-700\/40 {
  color: rgb(162 28 175 / 0.4) !important;
}

.tw-text-fuchsia-700\/45 {
  color: rgb(162 28 175 / 0.45) !important;
}

.tw-text-fuchsia-700\/5 {
  color: rgb(162 28 175 / 0.05) !important;
}

.tw-text-fuchsia-700\/50 {
  color: rgb(162 28 175 / 0.5) !important;
}

.tw-text-fuchsia-700\/55 {
  color: rgb(162 28 175 / 0.55) !important;
}

.tw-text-fuchsia-700\/60 {
  color: rgb(162 28 175 / 0.6) !important;
}

.tw-text-fuchsia-700\/65 {
  color: rgb(162 28 175 / 0.65) !important;
}

.tw-text-fuchsia-700\/70 {
  color: rgb(162 28 175 / 0.7) !important;
}

.tw-text-fuchsia-700\/75 {
  color: rgb(162 28 175 / 0.75) !important;
}

.tw-text-fuchsia-700\/80 {
  color: rgb(162 28 175 / 0.8) !important;
}

.tw-text-fuchsia-700\/85 {
  color: rgb(162 28 175 / 0.85) !important;
}

.tw-text-fuchsia-700\/90 {
  color: rgb(162 28 175 / 0.9) !important;
}

.tw-text-fuchsia-700\/95 {
  color: rgb(162 28 175 / 0.95) !important;
}

.tw-text-fuchsia-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(134 25 143 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-800\/0 {
  color: rgb(134 25 143 / 0) !important;
}

.tw-text-fuchsia-800\/10 {
  color: rgb(134 25 143 / 0.1) !important;
}

.tw-text-fuchsia-800\/100 {
  color: rgb(134 25 143 / 1) !important;
}

.tw-text-fuchsia-800\/15 {
  color: rgb(134 25 143 / 0.15) !important;
}

.tw-text-fuchsia-800\/20 {
  color: rgb(134 25 143 / 0.2) !important;
}

.tw-text-fuchsia-800\/25 {
  color: rgb(134 25 143 / 0.25) !important;
}

.tw-text-fuchsia-800\/30 {
  color: rgb(134 25 143 / 0.3) !important;
}

.tw-text-fuchsia-800\/35 {
  color: rgb(134 25 143 / 0.35) !important;
}

.tw-text-fuchsia-800\/40 {
  color: rgb(134 25 143 / 0.4) !important;
}

.tw-text-fuchsia-800\/45 {
  color: rgb(134 25 143 / 0.45) !important;
}

.tw-text-fuchsia-800\/5 {
  color: rgb(134 25 143 / 0.05) !important;
}

.tw-text-fuchsia-800\/50 {
  color: rgb(134 25 143 / 0.5) !important;
}

.tw-text-fuchsia-800\/55 {
  color: rgb(134 25 143 / 0.55) !important;
}

.tw-text-fuchsia-800\/60 {
  color: rgb(134 25 143 / 0.6) !important;
}

.tw-text-fuchsia-800\/65 {
  color: rgb(134 25 143 / 0.65) !important;
}

.tw-text-fuchsia-800\/70 {
  color: rgb(134 25 143 / 0.7) !important;
}

.tw-text-fuchsia-800\/75 {
  color: rgb(134 25 143 / 0.75) !important;
}

.tw-text-fuchsia-800\/80 {
  color: rgb(134 25 143 / 0.8) !important;
}

.tw-text-fuchsia-800\/85 {
  color: rgb(134 25 143 / 0.85) !important;
}

.tw-text-fuchsia-800\/90 {
  color: rgb(134 25 143 / 0.9) !important;
}

.tw-text-fuchsia-800\/95 {
  color: rgb(134 25 143 / 0.95) !important;
}

.tw-text-fuchsia-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(112 26 117 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-900\/0 {
  color: rgb(112 26 117 / 0) !important;
}

.tw-text-fuchsia-900\/10 {
  color: rgb(112 26 117 / 0.1) !important;
}

.tw-text-fuchsia-900\/100 {
  color: rgb(112 26 117 / 1) !important;
}

.tw-text-fuchsia-900\/15 {
  color: rgb(112 26 117 / 0.15) !important;
}

.tw-text-fuchsia-900\/20 {
  color: rgb(112 26 117 / 0.2) !important;
}

.tw-text-fuchsia-900\/25 {
  color: rgb(112 26 117 / 0.25) !important;
}

.tw-text-fuchsia-900\/30 {
  color: rgb(112 26 117 / 0.3) !important;
}

.tw-text-fuchsia-900\/35 {
  color: rgb(112 26 117 / 0.35) !important;
}

.tw-text-fuchsia-900\/40 {
  color: rgb(112 26 117 / 0.4) !important;
}

.tw-text-fuchsia-900\/45 {
  color: rgb(112 26 117 / 0.45) !important;
}

.tw-text-fuchsia-900\/5 {
  color: rgb(112 26 117 / 0.05) !important;
}

.tw-text-fuchsia-900\/50 {
  color: rgb(112 26 117 / 0.5) !important;
}

.tw-text-fuchsia-900\/55 {
  color: rgb(112 26 117 / 0.55) !important;
}

.tw-text-fuchsia-900\/60 {
  color: rgb(112 26 117 / 0.6) !important;
}

.tw-text-fuchsia-900\/65 {
  color: rgb(112 26 117 / 0.65) !important;
}

.tw-text-fuchsia-900\/70 {
  color: rgb(112 26 117 / 0.7) !important;
}

.tw-text-fuchsia-900\/75 {
  color: rgb(112 26 117 / 0.75) !important;
}

.tw-text-fuchsia-900\/80 {
  color: rgb(112 26 117 / 0.8) !important;
}

.tw-text-fuchsia-900\/85 {
  color: rgb(112 26 117 / 0.85) !important;
}

.tw-text-fuchsia-900\/90 {
  color: rgb(112 26 117 / 0.9) !important;
}

.tw-text-fuchsia-900\/95 {
  color: rgb(112 26 117 / 0.95) !important;
}

.tw-text-fuchsia-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(74 4 78 / var(--tw-text-opacity)) !important;
}

.tw-text-fuchsia-950\/0 {
  color: rgb(74 4 78 / 0) !important;
}

.tw-text-fuchsia-950\/10 {
  color: rgb(74 4 78 / 0.1) !important;
}

.tw-text-fuchsia-950\/100 {
  color: rgb(74 4 78 / 1) !important;
}

.tw-text-fuchsia-950\/15 {
  color: rgb(74 4 78 / 0.15) !important;
}

.tw-text-fuchsia-950\/20 {
  color: rgb(74 4 78 / 0.2) !important;
}

.tw-text-fuchsia-950\/25 {
  color: rgb(74 4 78 / 0.25) !important;
}

.tw-text-fuchsia-950\/30 {
  color: rgb(74 4 78 / 0.3) !important;
}

.tw-text-fuchsia-950\/35 {
  color: rgb(74 4 78 / 0.35) !important;
}

.tw-text-fuchsia-950\/40 {
  color: rgb(74 4 78 / 0.4) !important;
}

.tw-text-fuchsia-950\/45 {
  color: rgb(74 4 78 / 0.45) !important;
}

.tw-text-fuchsia-950\/5 {
  color: rgb(74 4 78 / 0.05) !important;
}

.tw-text-fuchsia-950\/50 {
  color: rgb(74 4 78 / 0.5) !important;
}

.tw-text-fuchsia-950\/55 {
  color: rgb(74 4 78 / 0.55) !important;
}

.tw-text-fuchsia-950\/60 {
  color: rgb(74 4 78 / 0.6) !important;
}

.tw-text-fuchsia-950\/65 {
  color: rgb(74 4 78 / 0.65) !important;
}

.tw-text-fuchsia-950\/70 {
  color: rgb(74 4 78 / 0.7) !important;
}

.tw-text-fuchsia-950\/75 {
  color: rgb(74 4 78 / 0.75) !important;
}

.tw-text-fuchsia-950\/80 {
  color: rgb(74 4 78 / 0.8) !important;
}

.tw-text-fuchsia-950\/85 {
  color: rgb(74 4 78 / 0.85) !important;
}

.tw-text-fuchsia-950\/90 {
  color: rgb(74 4 78 / 0.9) !important;
}

.tw-text-fuchsia-950\/95 {
  color: rgb(74 4 78 / 0.95) !important;
}

.tw-text-gray-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-100\/0 {
  color: rgb(243 244 246 / 0) !important;
}

.tw-text-gray-100\/10 {
  color: rgb(243 244 246 / 0.1) !important;
}

.tw-text-gray-100\/100 {
  color: rgb(243 244 246 / 1) !important;
}

.tw-text-gray-100\/15 {
  color: rgb(243 244 246 / 0.15) !important;
}

.tw-text-gray-100\/20 {
  color: rgb(243 244 246 / 0.2) !important;
}

.tw-text-gray-100\/25 {
  color: rgb(243 244 246 / 0.25) !important;
}

.tw-text-gray-100\/30 {
  color: rgb(243 244 246 / 0.3) !important;
}

.tw-text-gray-100\/35 {
  color: rgb(243 244 246 / 0.35) !important;
}

.tw-text-gray-100\/40 {
  color: rgb(243 244 246 / 0.4) !important;
}

.tw-text-gray-100\/45 {
  color: rgb(243 244 246 / 0.45) !important;
}

.tw-text-gray-100\/5 {
  color: rgb(243 244 246 / 0.05) !important;
}

.tw-text-gray-100\/50 {
  color: rgb(243 244 246 / 0.5) !important;
}

.tw-text-gray-100\/55 {
  color: rgb(243 244 246 / 0.55) !important;
}

.tw-text-gray-100\/60 {
  color: rgb(243 244 246 / 0.6) !important;
}

.tw-text-gray-100\/65 {
  color: rgb(243 244 246 / 0.65) !important;
}

.tw-text-gray-100\/70 {
  color: rgb(243 244 246 / 0.7) !important;
}

.tw-text-gray-100\/75 {
  color: rgb(243 244 246 / 0.75) !important;
}

.tw-text-gray-100\/80 {
  color: rgb(243 244 246 / 0.8) !important;
}

.tw-text-gray-100\/85 {
  color: rgb(243 244 246 / 0.85) !important;
}

.tw-text-gray-100\/90 {
  color: rgb(243 244 246 / 0.9) !important;
}

.tw-text-gray-100\/95 {
  color: rgb(243 244 246 / 0.95) !important;
}

.tw-text-gray-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-200\/0 {
  color: rgb(229 231 235 / 0) !important;
}

.tw-text-gray-200\/10 {
  color: rgb(229 231 235 / 0.1) !important;
}

.tw-text-gray-200\/100 {
  color: rgb(229 231 235 / 1) !important;
}

.tw-text-gray-200\/15 {
  color: rgb(229 231 235 / 0.15) !important;
}

.tw-text-gray-200\/20 {
  color: rgb(229 231 235 / 0.2) !important;
}

.tw-text-gray-200\/25 {
  color: rgb(229 231 235 / 0.25) !important;
}

.tw-text-gray-200\/30 {
  color: rgb(229 231 235 / 0.3) !important;
}

.tw-text-gray-200\/35 {
  color: rgb(229 231 235 / 0.35) !important;
}

.tw-text-gray-200\/40 {
  color: rgb(229 231 235 / 0.4) !important;
}

.tw-text-gray-200\/45 {
  color: rgb(229 231 235 / 0.45) !important;
}

.tw-text-gray-200\/5 {
  color: rgb(229 231 235 / 0.05) !important;
}

.tw-text-gray-200\/50 {
  color: rgb(229 231 235 / 0.5) !important;
}

.tw-text-gray-200\/55 {
  color: rgb(229 231 235 / 0.55) !important;
}

.tw-text-gray-200\/60 {
  color: rgb(229 231 235 / 0.6) !important;
}

.tw-text-gray-200\/65 {
  color: rgb(229 231 235 / 0.65) !important;
}

.tw-text-gray-200\/70 {
  color: rgb(229 231 235 / 0.7) !important;
}

.tw-text-gray-200\/75 {
  color: rgb(229 231 235 / 0.75) !important;
}

.tw-text-gray-200\/80 {
  color: rgb(229 231 235 / 0.8) !important;
}

.tw-text-gray-200\/85 {
  color: rgb(229 231 235 / 0.85) !important;
}

.tw-text-gray-200\/90 {
  color: rgb(229 231 235 / 0.9) !important;
}

.tw-text-gray-200\/95 {
  color: rgb(229 231 235 / 0.95) !important;
}

.tw-text-gray-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-300\/0 {
  color: rgb(209 213 219 / 0) !important;
}

.tw-text-gray-300\/10 {
  color: rgb(209 213 219 / 0.1) !important;
}

.tw-text-gray-300\/100 {
  color: rgb(209 213 219 / 1) !important;
}

.tw-text-gray-300\/15 {
  color: rgb(209 213 219 / 0.15) !important;
}

.tw-text-gray-300\/20 {
  color: rgb(209 213 219 / 0.2) !important;
}

.tw-text-gray-300\/25 {
  color: rgb(209 213 219 / 0.25) !important;
}

.tw-text-gray-300\/30 {
  color: rgb(209 213 219 / 0.3) !important;
}

.tw-text-gray-300\/35 {
  color: rgb(209 213 219 / 0.35) !important;
}

.tw-text-gray-300\/40 {
  color: rgb(209 213 219 / 0.4) !important;
}

.tw-text-gray-300\/45 {
  color: rgb(209 213 219 / 0.45) !important;
}

.tw-text-gray-300\/5 {
  color: rgb(209 213 219 / 0.05) !important;
}

.tw-text-gray-300\/50 {
  color: rgb(209 213 219 / 0.5) !important;
}

.tw-text-gray-300\/55 {
  color: rgb(209 213 219 / 0.55) !important;
}

.tw-text-gray-300\/60 {
  color: rgb(209 213 219 / 0.6) !important;
}

.tw-text-gray-300\/65 {
  color: rgb(209 213 219 / 0.65) !important;
}

.tw-text-gray-300\/70 {
  color: rgb(209 213 219 / 0.7) !important;
}

.tw-text-gray-300\/75 {
  color: rgb(209 213 219 / 0.75) !important;
}

.tw-text-gray-300\/80 {
  color: rgb(209 213 219 / 0.8) !important;
}

.tw-text-gray-300\/85 {
  color: rgb(209 213 219 / 0.85) !important;
}

.tw-text-gray-300\/90 {
  color: rgb(209 213 219 / 0.9) !important;
}

.tw-text-gray-300\/95 {
  color: rgb(209 213 219 / 0.95) !important;
}

.tw-text-gray-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-400\/0 {
  color: rgb(156 163 175 / 0) !important;
}

.tw-text-gray-400\/10 {
  color: rgb(156 163 175 / 0.1) !important;
}

.tw-text-gray-400\/100 {
  color: rgb(156 163 175 / 1) !important;
}

.tw-text-gray-400\/15 {
  color: rgb(156 163 175 / 0.15) !important;
}

.tw-text-gray-400\/20 {
  color: rgb(156 163 175 / 0.2) !important;
}

.tw-text-gray-400\/25 {
  color: rgb(156 163 175 / 0.25) !important;
}

.tw-text-gray-400\/30 {
  color: rgb(156 163 175 / 0.3) !important;
}

.tw-text-gray-400\/35 {
  color: rgb(156 163 175 / 0.35) !important;
}

.tw-text-gray-400\/40 {
  color: rgb(156 163 175 / 0.4) !important;
}

.tw-text-gray-400\/45 {
  color: rgb(156 163 175 / 0.45) !important;
}

.tw-text-gray-400\/5 {
  color: rgb(156 163 175 / 0.05) !important;
}

.tw-text-gray-400\/50 {
  color: rgb(156 163 175 / 0.5) !important;
}

.tw-text-gray-400\/55 {
  color: rgb(156 163 175 / 0.55) !important;
}

.tw-text-gray-400\/60 {
  color: rgb(156 163 175 / 0.6) !important;
}

.tw-text-gray-400\/65 {
  color: rgb(156 163 175 / 0.65) !important;
}

.tw-text-gray-400\/70 {
  color: rgb(156 163 175 / 0.7) !important;
}

.tw-text-gray-400\/75 {
  color: rgb(156 163 175 / 0.75) !important;
}

.tw-text-gray-400\/80 {
  color: rgb(156 163 175 / 0.8) !important;
}

.tw-text-gray-400\/85 {
  color: rgb(156 163 175 / 0.85) !important;
}

.tw-text-gray-400\/90 {
  color: rgb(156 163 175 / 0.9) !important;
}

.tw-text-gray-400\/95 {
  color: rgb(156 163 175 / 0.95) !important;
}

.tw-text-gray-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-50\/0 {
  color: rgb(249 250 251 / 0) !important;
}

.tw-text-gray-50\/10 {
  color: rgb(249 250 251 / 0.1) !important;
}

.tw-text-gray-50\/100 {
  color: rgb(249 250 251 / 1) !important;
}

.tw-text-gray-50\/15 {
  color: rgb(249 250 251 / 0.15) !important;
}

.tw-text-gray-50\/20 {
  color: rgb(249 250 251 / 0.2) !important;
}

.tw-text-gray-50\/25 {
  color: rgb(249 250 251 / 0.25) !important;
}

.tw-text-gray-50\/30 {
  color: rgb(249 250 251 / 0.3) !important;
}

.tw-text-gray-50\/35 {
  color: rgb(249 250 251 / 0.35) !important;
}

.tw-text-gray-50\/40 {
  color: rgb(249 250 251 / 0.4) !important;
}

.tw-text-gray-50\/45 {
  color: rgb(249 250 251 / 0.45) !important;
}

.tw-text-gray-50\/5 {
  color: rgb(249 250 251 / 0.05) !important;
}

.tw-text-gray-50\/50 {
  color: rgb(249 250 251 / 0.5) !important;
}

.tw-text-gray-50\/55 {
  color: rgb(249 250 251 / 0.55) !important;
}

.tw-text-gray-50\/60 {
  color: rgb(249 250 251 / 0.6) !important;
}

.tw-text-gray-50\/65 {
  color: rgb(249 250 251 / 0.65) !important;
}

.tw-text-gray-50\/70 {
  color: rgb(249 250 251 / 0.7) !important;
}

.tw-text-gray-50\/75 {
  color: rgb(249 250 251 / 0.75) !important;
}

.tw-text-gray-50\/80 {
  color: rgb(249 250 251 / 0.8) !important;
}

.tw-text-gray-50\/85 {
  color: rgb(249 250 251 / 0.85) !important;
}

.tw-text-gray-50\/90 {
  color: rgb(249 250 251 / 0.9) !important;
}

.tw-text-gray-50\/95 {
  color: rgb(249 250 251 / 0.95) !important;
}

.tw-text-gray-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-500\/0 {
  color: rgb(107 114 128 / 0) !important;
}

.tw-text-gray-500\/10 {
  color: rgb(107 114 128 / 0.1) !important;
}

.tw-text-gray-500\/100 {
  color: rgb(107 114 128 / 1) !important;
}

.tw-text-gray-500\/15 {
  color: rgb(107 114 128 / 0.15) !important;
}

.tw-text-gray-500\/20 {
  color: rgb(107 114 128 / 0.2) !important;
}

.tw-text-gray-500\/25 {
  color: rgb(107 114 128 / 0.25) !important;
}

.tw-text-gray-500\/30 {
  color: rgb(107 114 128 / 0.3) !important;
}

.tw-text-gray-500\/35 {
  color: rgb(107 114 128 / 0.35) !important;
}

.tw-text-gray-500\/40 {
  color: rgb(107 114 128 / 0.4) !important;
}

.tw-text-gray-500\/45 {
  color: rgb(107 114 128 / 0.45) !important;
}

.tw-text-gray-500\/5 {
  color: rgb(107 114 128 / 0.05) !important;
}

.tw-text-gray-500\/50 {
  color: rgb(107 114 128 / 0.5) !important;
}

.tw-text-gray-500\/55 {
  color: rgb(107 114 128 / 0.55) !important;
}

.tw-text-gray-500\/60 {
  color: rgb(107 114 128 / 0.6) !important;
}

.tw-text-gray-500\/65 {
  color: rgb(107 114 128 / 0.65) !important;
}

.tw-text-gray-500\/70 {
  color: rgb(107 114 128 / 0.7) !important;
}

.tw-text-gray-500\/75 {
  color: rgb(107 114 128 / 0.75) !important;
}

.tw-text-gray-500\/80 {
  color: rgb(107 114 128 / 0.8) !important;
}

.tw-text-gray-500\/85 {
  color: rgb(107 114 128 / 0.85) !important;
}

.tw-text-gray-500\/90 {
  color: rgb(107 114 128 / 0.9) !important;
}

.tw-text-gray-500\/95 {
  color: rgb(107 114 128 / 0.95) !important;
}

.tw-text-gray-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-600\/0 {
  color: rgb(75 85 99 / 0) !important;
}

.tw-text-gray-600\/10 {
  color: rgb(75 85 99 / 0.1) !important;
}

.tw-text-gray-600\/100 {
  color: rgb(75 85 99 / 1) !important;
}

.tw-text-gray-600\/15 {
  color: rgb(75 85 99 / 0.15) !important;
}

.tw-text-gray-600\/20 {
  color: rgb(75 85 99 / 0.2) !important;
}

.tw-text-gray-600\/25 {
  color: rgb(75 85 99 / 0.25) !important;
}

.tw-text-gray-600\/30 {
  color: rgb(75 85 99 / 0.3) !important;
}

.tw-text-gray-600\/35 {
  color: rgb(75 85 99 / 0.35) !important;
}

.tw-text-gray-600\/40 {
  color: rgb(75 85 99 / 0.4) !important;
}

.tw-text-gray-600\/45 {
  color: rgb(75 85 99 / 0.45) !important;
}

.tw-text-gray-600\/5 {
  color: rgb(75 85 99 / 0.05) !important;
}

.tw-text-gray-600\/50 {
  color: rgb(75 85 99 / 0.5) !important;
}

.tw-text-gray-600\/55 {
  color: rgb(75 85 99 / 0.55) !important;
}

.tw-text-gray-600\/60 {
  color: rgb(75 85 99 / 0.6) !important;
}

.tw-text-gray-600\/65 {
  color: rgb(75 85 99 / 0.65) !important;
}

.tw-text-gray-600\/70 {
  color: rgb(75 85 99 / 0.7) !important;
}

.tw-text-gray-600\/75 {
  color: rgb(75 85 99 / 0.75) !important;
}

.tw-text-gray-600\/80 {
  color: rgb(75 85 99 / 0.8) !important;
}

.tw-text-gray-600\/85 {
  color: rgb(75 85 99 / 0.85) !important;
}

.tw-text-gray-600\/90 {
  color: rgb(75 85 99 / 0.9) !important;
}

.tw-text-gray-600\/95 {
  color: rgb(75 85 99 / 0.95) !important;
}

.tw-text-gray-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-700\/0 {
  color: rgb(55 65 81 / 0) !important;
}

.tw-text-gray-700\/10 {
  color: rgb(55 65 81 / 0.1) !important;
}

.tw-text-gray-700\/100 {
  color: rgb(55 65 81 / 1) !important;
}

.tw-text-gray-700\/15 {
  color: rgb(55 65 81 / 0.15) !important;
}

.tw-text-gray-700\/20 {
  color: rgb(55 65 81 / 0.2) !important;
}

.tw-text-gray-700\/25 {
  color: rgb(55 65 81 / 0.25) !important;
}

.tw-text-gray-700\/30 {
  color: rgb(55 65 81 / 0.3) !important;
}

.tw-text-gray-700\/35 {
  color: rgb(55 65 81 / 0.35) !important;
}

.tw-text-gray-700\/40 {
  color: rgb(55 65 81 / 0.4) !important;
}

.tw-text-gray-700\/45 {
  color: rgb(55 65 81 / 0.45) !important;
}

.tw-text-gray-700\/5 {
  color: rgb(55 65 81 / 0.05) !important;
}

.tw-text-gray-700\/50 {
  color: rgb(55 65 81 / 0.5) !important;
}

.tw-text-gray-700\/55 {
  color: rgb(55 65 81 / 0.55) !important;
}

.tw-text-gray-700\/60 {
  color: rgb(55 65 81 / 0.6) !important;
}

.tw-text-gray-700\/65 {
  color: rgb(55 65 81 / 0.65) !important;
}

.tw-text-gray-700\/70 {
  color: rgb(55 65 81 / 0.7) !important;
}

.tw-text-gray-700\/75 {
  color: rgb(55 65 81 / 0.75) !important;
}

.tw-text-gray-700\/80 {
  color: rgb(55 65 81 / 0.8) !important;
}

.tw-text-gray-700\/85 {
  color: rgb(55 65 81 / 0.85) !important;
}

.tw-text-gray-700\/90 {
  color: rgb(55 65 81 / 0.9) !important;
}

.tw-text-gray-700\/95 {
  color: rgb(55 65 81 / 0.95) !important;
}

.tw-text-gray-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(31 41 55 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-800\/0 {
  color: rgb(31 41 55 / 0) !important;
}

.tw-text-gray-800\/10 {
  color: rgb(31 41 55 / 0.1) !important;
}

.tw-text-gray-800\/100 {
  color: rgb(31 41 55 / 1) !important;
}

.tw-text-gray-800\/15 {
  color: rgb(31 41 55 / 0.15) !important;
}

.tw-text-gray-800\/20 {
  color: rgb(31 41 55 / 0.2) !important;
}

.tw-text-gray-800\/25 {
  color: rgb(31 41 55 / 0.25) !important;
}

.tw-text-gray-800\/30 {
  color: rgb(31 41 55 / 0.3) !important;
}

.tw-text-gray-800\/35 {
  color: rgb(31 41 55 / 0.35) !important;
}

.tw-text-gray-800\/40 {
  color: rgb(31 41 55 / 0.4) !important;
}

.tw-text-gray-800\/45 {
  color: rgb(31 41 55 / 0.45) !important;
}

.tw-text-gray-800\/5 {
  color: rgb(31 41 55 / 0.05) !important;
}

.tw-text-gray-800\/50 {
  color: rgb(31 41 55 / 0.5) !important;
}

.tw-text-gray-800\/55 {
  color: rgb(31 41 55 / 0.55) !important;
}

.tw-text-gray-800\/60 {
  color: rgb(31 41 55 / 0.6) !important;
}

.tw-text-gray-800\/65 {
  color: rgb(31 41 55 / 0.65) !important;
}

.tw-text-gray-800\/70 {
  color: rgb(31 41 55 / 0.7) !important;
}

.tw-text-gray-800\/75 {
  color: rgb(31 41 55 / 0.75) !important;
}

.tw-text-gray-800\/80 {
  color: rgb(31 41 55 / 0.8) !important;
}

.tw-text-gray-800\/85 {
  color: rgb(31 41 55 / 0.85) !important;
}

.tw-text-gray-800\/90 {
  color: rgb(31 41 55 / 0.9) !important;
}

.tw-text-gray-800\/95 {
  color: rgb(31 41 55 / 0.95) !important;
}

.tw-text-gray-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-900\/0 {
  color: rgb(17 24 39 / 0) !important;
}

.tw-text-gray-900\/10 {
  color: rgb(17 24 39 / 0.1) !important;
}

.tw-text-gray-900\/100 {
  color: rgb(17 24 39 / 1) !important;
}

.tw-text-gray-900\/15 {
  color: rgb(17 24 39 / 0.15) !important;
}

.tw-text-gray-900\/20 {
  color: rgb(17 24 39 / 0.2) !important;
}

.tw-text-gray-900\/25 {
  color: rgb(17 24 39 / 0.25) !important;
}

.tw-text-gray-900\/30 {
  color: rgb(17 24 39 / 0.3) !important;
}

.tw-text-gray-900\/35 {
  color: rgb(17 24 39 / 0.35) !important;
}

.tw-text-gray-900\/40 {
  color: rgb(17 24 39 / 0.4) !important;
}

.tw-text-gray-900\/45 {
  color: rgb(17 24 39 / 0.45) !important;
}

.tw-text-gray-900\/5 {
  color: rgb(17 24 39 / 0.05) !important;
}

.tw-text-gray-900\/50 {
  color: rgb(17 24 39 / 0.5) !important;
}

.tw-text-gray-900\/55 {
  color: rgb(17 24 39 / 0.55) !important;
}

.tw-text-gray-900\/60 {
  color: rgb(17 24 39 / 0.6) !important;
}

.tw-text-gray-900\/65 {
  color: rgb(17 24 39 / 0.65) !important;
}

.tw-text-gray-900\/70 {
  color: rgb(17 24 39 / 0.7) !important;
}

.tw-text-gray-900\/75 {
  color: rgb(17 24 39 / 0.75) !important;
}

.tw-text-gray-900\/80 {
  color: rgb(17 24 39 / 0.8) !important;
}

.tw-text-gray-900\/85 {
  color: rgb(17 24 39 / 0.85) !important;
}

.tw-text-gray-900\/90 {
  color: rgb(17 24 39 / 0.9) !important;
}

.tw-text-gray-900\/95 {
  color: rgb(17 24 39 / 0.95) !important;
}

.tw-text-gray-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(3 7 18 / var(--tw-text-opacity)) !important;
}

.tw-text-gray-950\/0 {
  color: rgb(3 7 18 / 0) !important;
}

.tw-text-gray-950\/10 {
  color: rgb(3 7 18 / 0.1) !important;
}

.tw-text-gray-950\/100 {
  color: rgb(3 7 18 / 1) !important;
}

.tw-text-gray-950\/15 {
  color: rgb(3 7 18 / 0.15) !important;
}

.tw-text-gray-950\/20 {
  color: rgb(3 7 18 / 0.2) !important;
}

.tw-text-gray-950\/25 {
  color: rgb(3 7 18 / 0.25) !important;
}

.tw-text-gray-950\/30 {
  color: rgb(3 7 18 / 0.3) !important;
}

.tw-text-gray-950\/35 {
  color: rgb(3 7 18 / 0.35) !important;
}

.tw-text-gray-950\/40 {
  color: rgb(3 7 18 / 0.4) !important;
}

.tw-text-gray-950\/45 {
  color: rgb(3 7 18 / 0.45) !important;
}

.tw-text-gray-950\/5 {
  color: rgb(3 7 18 / 0.05) !important;
}

.tw-text-gray-950\/50 {
  color: rgb(3 7 18 / 0.5) !important;
}

.tw-text-gray-950\/55 {
  color: rgb(3 7 18 / 0.55) !important;
}

.tw-text-gray-950\/60 {
  color: rgb(3 7 18 / 0.6) !important;
}

.tw-text-gray-950\/65 {
  color: rgb(3 7 18 / 0.65) !important;
}

.tw-text-gray-950\/70 {
  color: rgb(3 7 18 / 0.7) !important;
}

.tw-text-gray-950\/75 {
  color: rgb(3 7 18 / 0.75) !important;
}

.tw-text-gray-950\/80 {
  color: rgb(3 7 18 / 0.8) !important;
}

.tw-text-gray-950\/85 {
  color: rgb(3 7 18 / 0.85) !important;
}

.tw-text-gray-950\/90 {
  color: rgb(3 7 18 / 0.9) !important;
}

.tw-text-gray-950\/95 {
  color: rgb(3 7 18 / 0.95) !important;
}

.tw-text-green-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 252 231 / var(--tw-text-opacity)) !important;
}

.tw-text-green-100\/0 {
  color: rgb(220 252 231 / 0) !important;
}

.tw-text-green-100\/10 {
  color: rgb(220 252 231 / 0.1) !important;
}

.tw-text-green-100\/100 {
  color: rgb(220 252 231 / 1) !important;
}

.tw-text-green-100\/15 {
  color: rgb(220 252 231 / 0.15) !important;
}

.tw-text-green-100\/20 {
  color: rgb(220 252 231 / 0.2) !important;
}

.tw-text-green-100\/25 {
  color: rgb(220 252 231 / 0.25) !important;
}

.tw-text-green-100\/30 {
  color: rgb(220 252 231 / 0.3) !important;
}

.tw-text-green-100\/35 {
  color: rgb(220 252 231 / 0.35) !important;
}

.tw-text-green-100\/40 {
  color: rgb(220 252 231 / 0.4) !important;
}

.tw-text-green-100\/45 {
  color: rgb(220 252 231 / 0.45) !important;
}

.tw-text-green-100\/5 {
  color: rgb(220 252 231 / 0.05) !important;
}

.tw-text-green-100\/50 {
  color: rgb(220 252 231 / 0.5) !important;
}

.tw-text-green-100\/55 {
  color: rgb(220 252 231 / 0.55) !important;
}

.tw-text-green-100\/60 {
  color: rgb(220 252 231 / 0.6) !important;
}

.tw-text-green-100\/65 {
  color: rgb(220 252 231 / 0.65) !important;
}

.tw-text-green-100\/70 {
  color: rgb(220 252 231 / 0.7) !important;
}

.tw-text-green-100\/75 {
  color: rgb(220 252 231 / 0.75) !important;
}

.tw-text-green-100\/80 {
  color: rgb(220 252 231 / 0.8) !important;
}

.tw-text-green-100\/85 {
  color: rgb(220 252 231 / 0.85) !important;
}

.tw-text-green-100\/90 {
  color: rgb(220 252 231 / 0.9) !important;
}

.tw-text-green-100\/95 {
  color: rgb(220 252 231 / 0.95) !important;
}

.tw-text-green-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(187 247 208 / var(--tw-text-opacity)) !important;
}

.tw-text-green-200\/0 {
  color: rgb(187 247 208 / 0) !important;
}

.tw-text-green-200\/10 {
  color: rgb(187 247 208 / 0.1) !important;
}

.tw-text-green-200\/100 {
  color: rgb(187 247 208 / 1) !important;
}

.tw-text-green-200\/15 {
  color: rgb(187 247 208 / 0.15) !important;
}

.tw-text-green-200\/20 {
  color: rgb(187 247 208 / 0.2) !important;
}

.tw-text-green-200\/25 {
  color: rgb(187 247 208 / 0.25) !important;
}

.tw-text-green-200\/30 {
  color: rgb(187 247 208 / 0.3) !important;
}

.tw-text-green-200\/35 {
  color: rgb(187 247 208 / 0.35) !important;
}

.tw-text-green-200\/40 {
  color: rgb(187 247 208 / 0.4) !important;
}

.tw-text-green-200\/45 {
  color: rgb(187 247 208 / 0.45) !important;
}

.tw-text-green-200\/5 {
  color: rgb(187 247 208 / 0.05) !important;
}

.tw-text-green-200\/50 {
  color: rgb(187 247 208 / 0.5) !important;
}

.tw-text-green-200\/55 {
  color: rgb(187 247 208 / 0.55) !important;
}

.tw-text-green-200\/60 {
  color: rgb(187 247 208 / 0.6) !important;
}

.tw-text-green-200\/65 {
  color: rgb(187 247 208 / 0.65) !important;
}

.tw-text-green-200\/70 {
  color: rgb(187 247 208 / 0.7) !important;
}

.tw-text-green-200\/75 {
  color: rgb(187 247 208 / 0.75) !important;
}

.tw-text-green-200\/80 {
  color: rgb(187 247 208 / 0.8) !important;
}

.tw-text-green-200\/85 {
  color: rgb(187 247 208 / 0.85) !important;
}

.tw-text-green-200\/90 {
  color: rgb(187 247 208 / 0.9) !important;
}

.tw-text-green-200\/95 {
  color: rgb(187 247 208 / 0.95) !important;
}

.tw-text-green-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(134 239 172 / var(--tw-text-opacity)) !important;
}

.tw-text-green-300\/0 {
  color: rgb(134 239 172 / 0) !important;
}

.tw-text-green-300\/10 {
  color: rgb(134 239 172 / 0.1) !important;
}

.tw-text-green-300\/100 {
  color: rgb(134 239 172 / 1) !important;
}

.tw-text-green-300\/15 {
  color: rgb(134 239 172 / 0.15) !important;
}

.tw-text-green-300\/20 {
  color: rgb(134 239 172 / 0.2) !important;
}

.tw-text-green-300\/25 {
  color: rgb(134 239 172 / 0.25) !important;
}

.tw-text-green-300\/30 {
  color: rgb(134 239 172 / 0.3) !important;
}

.tw-text-green-300\/35 {
  color: rgb(134 239 172 / 0.35) !important;
}

.tw-text-green-300\/40 {
  color: rgb(134 239 172 / 0.4) !important;
}

.tw-text-green-300\/45 {
  color: rgb(134 239 172 / 0.45) !important;
}

.tw-text-green-300\/5 {
  color: rgb(134 239 172 / 0.05) !important;
}

.tw-text-green-300\/50 {
  color: rgb(134 239 172 / 0.5) !important;
}

.tw-text-green-300\/55 {
  color: rgb(134 239 172 / 0.55) !important;
}

.tw-text-green-300\/60 {
  color: rgb(134 239 172 / 0.6) !important;
}

.tw-text-green-300\/65 {
  color: rgb(134 239 172 / 0.65) !important;
}

.tw-text-green-300\/70 {
  color: rgb(134 239 172 / 0.7) !important;
}

.tw-text-green-300\/75 {
  color: rgb(134 239 172 / 0.75) !important;
}

.tw-text-green-300\/80 {
  color: rgb(134 239 172 / 0.8) !important;
}

.tw-text-green-300\/85 {
  color: rgb(134 239 172 / 0.85) !important;
}

.tw-text-green-300\/90 {
  color: rgb(134 239 172 / 0.9) !important;
}

.tw-text-green-300\/95 {
  color: rgb(134 239 172 / 0.95) !important;
}

.tw-text-green-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(74 222 128 / var(--tw-text-opacity)) !important;
}

.tw-text-green-400\/0 {
  color: rgb(74 222 128 / 0) !important;
}

.tw-text-green-400\/10 {
  color: rgb(74 222 128 / 0.1) !important;
}

.tw-text-green-400\/100 {
  color: rgb(74 222 128 / 1) !important;
}

.tw-text-green-400\/15 {
  color: rgb(74 222 128 / 0.15) !important;
}

.tw-text-green-400\/20 {
  color: rgb(74 222 128 / 0.2) !important;
}

.tw-text-green-400\/25 {
  color: rgb(74 222 128 / 0.25) !important;
}

.tw-text-green-400\/30 {
  color: rgb(74 222 128 / 0.3) !important;
}

.tw-text-green-400\/35 {
  color: rgb(74 222 128 / 0.35) !important;
}

.tw-text-green-400\/40 {
  color: rgb(74 222 128 / 0.4) !important;
}

.tw-text-green-400\/45 {
  color: rgb(74 222 128 / 0.45) !important;
}

.tw-text-green-400\/5 {
  color: rgb(74 222 128 / 0.05) !important;
}

.tw-text-green-400\/50 {
  color: rgb(74 222 128 / 0.5) !important;
}

.tw-text-green-400\/55 {
  color: rgb(74 222 128 / 0.55) !important;
}

.tw-text-green-400\/60 {
  color: rgb(74 222 128 / 0.6) !important;
}

.tw-text-green-400\/65 {
  color: rgb(74 222 128 / 0.65) !important;
}

.tw-text-green-400\/70 {
  color: rgb(74 222 128 / 0.7) !important;
}

.tw-text-green-400\/75 {
  color: rgb(74 222 128 / 0.75) !important;
}

.tw-text-green-400\/80 {
  color: rgb(74 222 128 / 0.8) !important;
}

.tw-text-green-400\/85 {
  color: rgb(74 222 128 / 0.85) !important;
}

.tw-text-green-400\/90 {
  color: rgb(74 222 128 / 0.9) !important;
}

.tw-text-green-400\/95 {
  color: rgb(74 222 128 / 0.95) !important;
}

.tw-text-green-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(240 253 244 / var(--tw-text-opacity)) !important;
}

.tw-text-green-50\/0 {
  color: rgb(240 253 244 / 0) !important;
}

.tw-text-green-50\/10 {
  color: rgb(240 253 244 / 0.1) !important;
}

.tw-text-green-50\/100 {
  color: rgb(240 253 244 / 1) !important;
}

.tw-text-green-50\/15 {
  color: rgb(240 253 244 / 0.15) !important;
}

.tw-text-green-50\/20 {
  color: rgb(240 253 244 / 0.2) !important;
}

.tw-text-green-50\/25 {
  color: rgb(240 253 244 / 0.25) !important;
}

.tw-text-green-50\/30 {
  color: rgb(240 253 244 / 0.3) !important;
}

.tw-text-green-50\/35 {
  color: rgb(240 253 244 / 0.35) !important;
}

.tw-text-green-50\/40 {
  color: rgb(240 253 244 / 0.4) !important;
}

.tw-text-green-50\/45 {
  color: rgb(240 253 244 / 0.45) !important;
}

.tw-text-green-50\/5 {
  color: rgb(240 253 244 / 0.05) !important;
}

.tw-text-green-50\/50 {
  color: rgb(240 253 244 / 0.5) !important;
}

.tw-text-green-50\/55 {
  color: rgb(240 253 244 / 0.55) !important;
}

.tw-text-green-50\/60 {
  color: rgb(240 253 244 / 0.6) !important;
}

.tw-text-green-50\/65 {
  color: rgb(240 253 244 / 0.65) !important;
}

.tw-text-green-50\/70 {
  color: rgb(240 253 244 / 0.7) !important;
}

.tw-text-green-50\/75 {
  color: rgb(240 253 244 / 0.75) !important;
}

.tw-text-green-50\/80 {
  color: rgb(240 253 244 / 0.8) !important;
}

.tw-text-green-50\/85 {
  color: rgb(240 253 244 / 0.85) !important;
}

.tw-text-green-50\/90 {
  color: rgb(240 253 244 / 0.9) !important;
}

.tw-text-green-50\/95 {
  color: rgb(240 253 244 / 0.95) !important;
}

.tw-text-green-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-text-opacity)) !important;
}

.tw-text-green-500\/0 {
  color: rgb(34 197 94 / 0) !important;
}

.tw-text-green-500\/10 {
  color: rgb(34 197 94 / 0.1) !important;
}

.tw-text-green-500\/100 {
  color: rgb(34 197 94 / 1) !important;
}

.tw-text-green-500\/15 {
  color: rgb(34 197 94 / 0.15) !important;
}

.tw-text-green-500\/20 {
  color: rgb(34 197 94 / 0.2) !important;
}

.tw-text-green-500\/25 {
  color: rgb(34 197 94 / 0.25) !important;
}

.tw-text-green-500\/30 {
  color: rgb(34 197 94 / 0.3) !important;
}

.tw-text-green-500\/35 {
  color: rgb(34 197 94 / 0.35) !important;
}

.tw-text-green-500\/40 {
  color: rgb(34 197 94 / 0.4) !important;
}

.tw-text-green-500\/45 {
  color: rgb(34 197 94 / 0.45) !important;
}

.tw-text-green-500\/5 {
  color: rgb(34 197 94 / 0.05) !important;
}

.tw-text-green-500\/50 {
  color: rgb(34 197 94 / 0.5) !important;
}

.tw-text-green-500\/55 {
  color: rgb(34 197 94 / 0.55) !important;
}

.tw-text-green-500\/60 {
  color: rgb(34 197 94 / 0.6) !important;
}

.tw-text-green-500\/65 {
  color: rgb(34 197 94 / 0.65) !important;
}

.tw-text-green-500\/70 {
  color: rgb(34 197 94 / 0.7) !important;
}

.tw-text-green-500\/75 {
  color: rgb(34 197 94 / 0.75) !important;
}

.tw-text-green-500\/80 {
  color: rgb(34 197 94 / 0.8) !important;
}

.tw-text-green-500\/85 {
  color: rgb(34 197 94 / 0.85) !important;
}

.tw-text-green-500\/90 {
  color: rgb(34 197 94 / 0.9) !important;
}

.tw-text-green-500\/95 {
  color: rgb(34 197 94 / 0.95) !important;
}

.tw-text-green-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 163 74 / var(--tw-text-opacity)) !important;
}

.tw-text-green-600\/0 {
  color: rgb(22 163 74 / 0) !important;
}

.tw-text-green-600\/10 {
  color: rgb(22 163 74 / 0.1) !important;
}

.tw-text-green-600\/100 {
  color: rgb(22 163 74 / 1) !important;
}

.tw-text-green-600\/15 {
  color: rgb(22 163 74 / 0.15) !important;
}

.tw-text-green-600\/20 {
  color: rgb(22 163 74 / 0.2) !important;
}

.tw-text-green-600\/25 {
  color: rgb(22 163 74 / 0.25) !important;
}

.tw-text-green-600\/30 {
  color: rgb(22 163 74 / 0.3) !important;
}

.tw-text-green-600\/35 {
  color: rgb(22 163 74 / 0.35) !important;
}

.tw-text-green-600\/40 {
  color: rgb(22 163 74 / 0.4) !important;
}

.tw-text-green-600\/45 {
  color: rgb(22 163 74 / 0.45) !important;
}

.tw-text-green-600\/5 {
  color: rgb(22 163 74 / 0.05) !important;
}

.tw-text-green-600\/50 {
  color: rgb(22 163 74 / 0.5) !important;
}

.tw-text-green-600\/55 {
  color: rgb(22 163 74 / 0.55) !important;
}

.tw-text-green-600\/60 {
  color: rgb(22 163 74 / 0.6) !important;
}

.tw-text-green-600\/65 {
  color: rgb(22 163 74 / 0.65) !important;
}

.tw-text-green-600\/70 {
  color: rgb(22 163 74 / 0.7) !important;
}

.tw-text-green-600\/75 {
  color: rgb(22 163 74 / 0.75) !important;
}

.tw-text-green-600\/80 {
  color: rgb(22 163 74 / 0.8) !important;
}

.tw-text-green-600\/85 {
  color: rgb(22 163 74 / 0.85) !important;
}

.tw-text-green-600\/90 {
  color: rgb(22 163 74 / 0.9) !important;
}

.tw-text-green-600\/95 {
  color: rgb(22 163 74 / 0.95) !important;
}

.tw-text-green-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(21 128 61 / var(--tw-text-opacity)) !important;
}

.tw-text-green-700\/0 {
  color: rgb(21 128 61 / 0) !important;
}

.tw-text-green-700\/10 {
  color: rgb(21 128 61 / 0.1) !important;
}

.tw-text-green-700\/100 {
  color: rgb(21 128 61 / 1) !important;
}

.tw-text-green-700\/15 {
  color: rgb(21 128 61 / 0.15) !important;
}

.tw-text-green-700\/20 {
  color: rgb(21 128 61 / 0.2) !important;
}

.tw-text-green-700\/25 {
  color: rgb(21 128 61 / 0.25) !important;
}

.tw-text-green-700\/30 {
  color: rgb(21 128 61 / 0.3) !important;
}

.tw-text-green-700\/35 {
  color: rgb(21 128 61 / 0.35) !important;
}

.tw-text-green-700\/40 {
  color: rgb(21 128 61 / 0.4) !important;
}

.tw-text-green-700\/45 {
  color: rgb(21 128 61 / 0.45) !important;
}

.tw-text-green-700\/5 {
  color: rgb(21 128 61 / 0.05) !important;
}

.tw-text-green-700\/50 {
  color: rgb(21 128 61 / 0.5) !important;
}

.tw-text-green-700\/55 {
  color: rgb(21 128 61 / 0.55) !important;
}

.tw-text-green-700\/60 {
  color: rgb(21 128 61 / 0.6) !important;
}

.tw-text-green-700\/65 {
  color: rgb(21 128 61 / 0.65) !important;
}

.tw-text-green-700\/70 {
  color: rgb(21 128 61 / 0.7) !important;
}

.tw-text-green-700\/75 {
  color: rgb(21 128 61 / 0.75) !important;
}

.tw-text-green-700\/80 {
  color: rgb(21 128 61 / 0.8) !important;
}

.tw-text-green-700\/85 {
  color: rgb(21 128 61 / 0.85) !important;
}

.tw-text-green-700\/90 {
  color: rgb(21 128 61 / 0.9) !important;
}

.tw-text-green-700\/95 {
  color: rgb(21 128 61 / 0.95) !important;
}

.tw-text-green-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(22 101 52 / var(--tw-text-opacity)) !important;
}

.tw-text-green-800\/0 {
  color: rgb(22 101 52 / 0) !important;
}

.tw-text-green-800\/10 {
  color: rgb(22 101 52 / 0.1) !important;
}

.tw-text-green-800\/100 {
  color: rgb(22 101 52 / 1) !important;
}

.tw-text-green-800\/15 {
  color: rgb(22 101 52 / 0.15) !important;
}

.tw-text-green-800\/20 {
  color: rgb(22 101 52 / 0.2) !important;
}

.tw-text-green-800\/25 {
  color: rgb(22 101 52 / 0.25) !important;
}

.tw-text-green-800\/30 {
  color: rgb(22 101 52 / 0.3) !important;
}

.tw-text-green-800\/35 {
  color: rgb(22 101 52 / 0.35) !important;
}

.tw-text-green-800\/40 {
  color: rgb(22 101 52 / 0.4) !important;
}

.tw-text-green-800\/45 {
  color: rgb(22 101 52 / 0.45) !important;
}

.tw-text-green-800\/5 {
  color: rgb(22 101 52 / 0.05) !important;
}

.tw-text-green-800\/50 {
  color: rgb(22 101 52 / 0.5) !important;
}

.tw-text-green-800\/55 {
  color: rgb(22 101 52 / 0.55) !important;
}

.tw-text-green-800\/60 {
  color: rgb(22 101 52 / 0.6) !important;
}

.tw-text-green-800\/65 {
  color: rgb(22 101 52 / 0.65) !important;
}

.tw-text-green-800\/70 {
  color: rgb(22 101 52 / 0.7) !important;
}

.tw-text-green-800\/75 {
  color: rgb(22 101 52 / 0.75) !important;
}

.tw-text-green-800\/80 {
  color: rgb(22 101 52 / 0.8) !important;
}

.tw-text-green-800\/85 {
  color: rgb(22 101 52 / 0.85) !important;
}

.tw-text-green-800\/90 {
  color: rgb(22 101 52 / 0.9) !important;
}

.tw-text-green-800\/95 {
  color: rgb(22 101 52 / 0.95) !important;
}

.tw-text-green-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(20 83 45 / var(--tw-text-opacity)) !important;
}

.tw-text-green-900\/0 {
  color: rgb(20 83 45 / 0) !important;
}

.tw-text-green-900\/10 {
  color: rgb(20 83 45 / 0.1) !important;
}

.tw-text-green-900\/100 {
  color: rgb(20 83 45 / 1) !important;
}

.tw-text-green-900\/15 {
  color: rgb(20 83 45 / 0.15) !important;
}

.tw-text-green-900\/20 {
  color: rgb(20 83 45 / 0.2) !important;
}

.tw-text-green-900\/25 {
  color: rgb(20 83 45 / 0.25) !important;
}

.tw-text-green-900\/30 {
  color: rgb(20 83 45 / 0.3) !important;
}

.tw-text-green-900\/35 {
  color: rgb(20 83 45 / 0.35) !important;
}

.tw-text-green-900\/40 {
  color: rgb(20 83 45 / 0.4) !important;
}

.tw-text-green-900\/45 {
  color: rgb(20 83 45 / 0.45) !important;
}

.tw-text-green-900\/5 {
  color: rgb(20 83 45 / 0.05) !important;
}

.tw-text-green-900\/50 {
  color: rgb(20 83 45 / 0.5) !important;
}

.tw-text-green-900\/55 {
  color: rgb(20 83 45 / 0.55) !important;
}

.tw-text-green-900\/60 {
  color: rgb(20 83 45 / 0.6) !important;
}

.tw-text-green-900\/65 {
  color: rgb(20 83 45 / 0.65) !important;
}

.tw-text-green-900\/70 {
  color: rgb(20 83 45 / 0.7) !important;
}

.tw-text-green-900\/75 {
  color: rgb(20 83 45 / 0.75) !important;
}

.tw-text-green-900\/80 {
  color: rgb(20 83 45 / 0.8) !important;
}

.tw-text-green-900\/85 {
  color: rgb(20 83 45 / 0.85) !important;
}

.tw-text-green-900\/90 {
  color: rgb(20 83 45 / 0.9) !important;
}

.tw-text-green-900\/95 {
  color: rgb(20 83 45 / 0.95) !important;
}

.tw-text-green-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(5 46 22 / var(--tw-text-opacity)) !important;
}

.tw-text-green-950\/0 {
  color: rgb(5 46 22 / 0) !important;
}

.tw-text-green-950\/10 {
  color: rgb(5 46 22 / 0.1) !important;
}

.tw-text-green-950\/100 {
  color: rgb(5 46 22 / 1) !important;
}

.tw-text-green-950\/15 {
  color: rgb(5 46 22 / 0.15) !important;
}

.tw-text-green-950\/20 {
  color: rgb(5 46 22 / 0.2) !important;
}

.tw-text-green-950\/25 {
  color: rgb(5 46 22 / 0.25) !important;
}

.tw-text-green-950\/30 {
  color: rgb(5 46 22 / 0.3) !important;
}

.tw-text-green-950\/35 {
  color: rgb(5 46 22 / 0.35) !important;
}

.tw-text-green-950\/40 {
  color: rgb(5 46 22 / 0.4) !important;
}

.tw-text-green-950\/45 {
  color: rgb(5 46 22 / 0.45) !important;
}

.tw-text-green-950\/5 {
  color: rgb(5 46 22 / 0.05) !important;
}

.tw-text-green-950\/50 {
  color: rgb(5 46 22 / 0.5) !important;
}

.tw-text-green-950\/55 {
  color: rgb(5 46 22 / 0.55) !important;
}

.tw-text-green-950\/60 {
  color: rgb(5 46 22 / 0.6) !important;
}

.tw-text-green-950\/65 {
  color: rgb(5 46 22 / 0.65) !important;
}

.tw-text-green-950\/70 {
  color: rgb(5 46 22 / 0.7) !important;
}

.tw-text-green-950\/75 {
  color: rgb(5 46 22 / 0.75) !important;
}

.tw-text-green-950\/80 {
  color: rgb(5 46 22 / 0.8) !important;
}

.tw-text-green-950\/85 {
  color: rgb(5 46 22 / 0.85) !important;
}

.tw-text-green-950\/90 {
  color: rgb(5 46 22 / 0.9) !important;
}

.tw-text-green-950\/95 {
  color: rgb(5 46 22 / 0.95) !important;
}

.tw-text-indigo-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(224 231 255 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-100\/0 {
  color: rgb(224 231 255 / 0) !important;
}

.tw-text-indigo-100\/10 {
  color: rgb(224 231 255 / 0.1) !important;
}

.tw-text-indigo-100\/100 {
  color: rgb(224 231 255 / 1) !important;
}

.tw-text-indigo-100\/15 {
  color: rgb(224 231 255 / 0.15) !important;
}

.tw-text-indigo-100\/20 {
  color: rgb(224 231 255 / 0.2) !important;
}

.tw-text-indigo-100\/25 {
  color: rgb(224 231 255 / 0.25) !important;
}

.tw-text-indigo-100\/30 {
  color: rgb(224 231 255 / 0.3) !important;
}

.tw-text-indigo-100\/35 {
  color: rgb(224 231 255 / 0.35) !important;
}

.tw-text-indigo-100\/40 {
  color: rgb(224 231 255 / 0.4) !important;
}

.tw-text-indigo-100\/45 {
  color: rgb(224 231 255 / 0.45) !important;
}

.tw-text-indigo-100\/5 {
  color: rgb(224 231 255 / 0.05) !important;
}

.tw-text-indigo-100\/50 {
  color: rgb(224 231 255 / 0.5) !important;
}

.tw-text-indigo-100\/55 {
  color: rgb(224 231 255 / 0.55) !important;
}

.tw-text-indigo-100\/60 {
  color: rgb(224 231 255 / 0.6) !important;
}

.tw-text-indigo-100\/65 {
  color: rgb(224 231 255 / 0.65) !important;
}

.tw-text-indigo-100\/70 {
  color: rgb(224 231 255 / 0.7) !important;
}

.tw-text-indigo-100\/75 {
  color: rgb(224 231 255 / 0.75) !important;
}

.tw-text-indigo-100\/80 {
  color: rgb(224 231 255 / 0.8) !important;
}

.tw-text-indigo-100\/85 {
  color: rgb(224 231 255 / 0.85) !important;
}

.tw-text-indigo-100\/90 {
  color: rgb(224 231 255 / 0.9) !important;
}

.tw-text-indigo-100\/95 {
  color: rgb(224 231 255 / 0.95) !important;
}

.tw-text-indigo-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(199 210 254 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-200\/0 {
  color: rgb(199 210 254 / 0) !important;
}

.tw-text-indigo-200\/10 {
  color: rgb(199 210 254 / 0.1) !important;
}

.tw-text-indigo-200\/100 {
  color: rgb(199 210 254 / 1) !important;
}

.tw-text-indigo-200\/15 {
  color: rgb(199 210 254 / 0.15) !important;
}

.tw-text-indigo-200\/20 {
  color: rgb(199 210 254 / 0.2) !important;
}

.tw-text-indigo-200\/25 {
  color: rgb(199 210 254 / 0.25) !important;
}

.tw-text-indigo-200\/30 {
  color: rgb(199 210 254 / 0.3) !important;
}

.tw-text-indigo-200\/35 {
  color: rgb(199 210 254 / 0.35) !important;
}

.tw-text-indigo-200\/40 {
  color: rgb(199 210 254 / 0.4) !important;
}

.tw-text-indigo-200\/45 {
  color: rgb(199 210 254 / 0.45) !important;
}

.tw-text-indigo-200\/5 {
  color: rgb(199 210 254 / 0.05) !important;
}

.tw-text-indigo-200\/50 {
  color: rgb(199 210 254 / 0.5) !important;
}

.tw-text-indigo-200\/55 {
  color: rgb(199 210 254 / 0.55) !important;
}

.tw-text-indigo-200\/60 {
  color: rgb(199 210 254 / 0.6) !important;
}

.tw-text-indigo-200\/65 {
  color: rgb(199 210 254 / 0.65) !important;
}

.tw-text-indigo-200\/70 {
  color: rgb(199 210 254 / 0.7) !important;
}

.tw-text-indigo-200\/75 {
  color: rgb(199 210 254 / 0.75) !important;
}

.tw-text-indigo-200\/80 {
  color: rgb(199 210 254 / 0.8) !important;
}

.tw-text-indigo-200\/85 {
  color: rgb(199 210 254 / 0.85) !important;
}

.tw-text-indigo-200\/90 {
  color: rgb(199 210 254 / 0.9) !important;
}

.tw-text-indigo-200\/95 {
  color: rgb(199 210 254 / 0.95) !important;
}

.tw-text-indigo-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(165 180 252 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-300\/0 {
  color: rgb(165 180 252 / 0) !important;
}

.tw-text-indigo-300\/10 {
  color: rgb(165 180 252 / 0.1) !important;
}

.tw-text-indigo-300\/100 {
  color: rgb(165 180 252 / 1) !important;
}

.tw-text-indigo-300\/15 {
  color: rgb(165 180 252 / 0.15) !important;
}

.tw-text-indigo-300\/20 {
  color: rgb(165 180 252 / 0.2) !important;
}

.tw-text-indigo-300\/25 {
  color: rgb(165 180 252 / 0.25) !important;
}

.tw-text-indigo-300\/30 {
  color: rgb(165 180 252 / 0.3) !important;
}

.tw-text-indigo-300\/35 {
  color: rgb(165 180 252 / 0.35) !important;
}

.tw-text-indigo-300\/40 {
  color: rgb(165 180 252 / 0.4) !important;
}

.tw-text-indigo-300\/45 {
  color: rgb(165 180 252 / 0.45) !important;
}

.tw-text-indigo-300\/5 {
  color: rgb(165 180 252 / 0.05) !important;
}

.tw-text-indigo-300\/50 {
  color: rgb(165 180 252 / 0.5) !important;
}

.tw-text-indigo-300\/55 {
  color: rgb(165 180 252 / 0.55) !important;
}

.tw-text-indigo-300\/60 {
  color: rgb(165 180 252 / 0.6) !important;
}

.tw-text-indigo-300\/65 {
  color: rgb(165 180 252 / 0.65) !important;
}

.tw-text-indigo-300\/70 {
  color: rgb(165 180 252 / 0.7) !important;
}

.tw-text-indigo-300\/75 {
  color: rgb(165 180 252 / 0.75) !important;
}

.tw-text-indigo-300\/80 {
  color: rgb(165 180 252 / 0.8) !important;
}

.tw-text-indigo-300\/85 {
  color: rgb(165 180 252 / 0.85) !important;
}

.tw-text-indigo-300\/90 {
  color: rgb(165 180 252 / 0.9) !important;
}

.tw-text-indigo-300\/95 {
  color: rgb(165 180 252 / 0.95) !important;
}

.tw-text-indigo-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(129 140 248 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-400\/0 {
  color: rgb(129 140 248 / 0) !important;
}

.tw-text-indigo-400\/10 {
  color: rgb(129 140 248 / 0.1) !important;
}

.tw-text-indigo-400\/100 {
  color: rgb(129 140 248 / 1) !important;
}

.tw-text-indigo-400\/15 {
  color: rgb(129 140 248 / 0.15) !important;
}

.tw-text-indigo-400\/20 {
  color: rgb(129 140 248 / 0.2) !important;
}

.tw-text-indigo-400\/25 {
  color: rgb(129 140 248 / 0.25) !important;
}

.tw-text-indigo-400\/30 {
  color: rgb(129 140 248 / 0.3) !important;
}

.tw-text-indigo-400\/35 {
  color: rgb(129 140 248 / 0.35) !important;
}

.tw-text-indigo-400\/40 {
  color: rgb(129 140 248 / 0.4) !important;
}

.tw-text-indigo-400\/45 {
  color: rgb(129 140 248 / 0.45) !important;
}

.tw-text-indigo-400\/5 {
  color: rgb(129 140 248 / 0.05) !important;
}

.tw-text-indigo-400\/50 {
  color: rgb(129 140 248 / 0.5) !important;
}

.tw-text-indigo-400\/55 {
  color: rgb(129 140 248 / 0.55) !important;
}

.tw-text-indigo-400\/60 {
  color: rgb(129 140 248 / 0.6) !important;
}

.tw-text-indigo-400\/65 {
  color: rgb(129 140 248 / 0.65) !important;
}

.tw-text-indigo-400\/70 {
  color: rgb(129 140 248 / 0.7) !important;
}

.tw-text-indigo-400\/75 {
  color: rgb(129 140 248 / 0.75) !important;
}

.tw-text-indigo-400\/80 {
  color: rgb(129 140 248 / 0.8) !important;
}

.tw-text-indigo-400\/85 {
  color: rgb(129 140 248 / 0.85) !important;
}

.tw-text-indigo-400\/90 {
  color: rgb(129 140 248 / 0.9) !important;
}

.tw-text-indigo-400\/95 {
  color: rgb(129 140 248 / 0.95) !important;
}

.tw-text-indigo-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(238 242 255 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-50\/0 {
  color: rgb(238 242 255 / 0) !important;
}

.tw-text-indigo-50\/10 {
  color: rgb(238 242 255 / 0.1) !important;
}

.tw-text-indigo-50\/100 {
  color: rgb(238 242 255 / 1) !important;
}

.tw-text-indigo-50\/15 {
  color: rgb(238 242 255 / 0.15) !important;
}

.tw-text-indigo-50\/20 {
  color: rgb(238 242 255 / 0.2) !important;
}

.tw-text-indigo-50\/25 {
  color: rgb(238 242 255 / 0.25) !important;
}

.tw-text-indigo-50\/30 {
  color: rgb(238 242 255 / 0.3) !important;
}

.tw-text-indigo-50\/35 {
  color: rgb(238 242 255 / 0.35) !important;
}

.tw-text-indigo-50\/40 {
  color: rgb(238 242 255 / 0.4) !important;
}

.tw-text-indigo-50\/45 {
  color: rgb(238 242 255 / 0.45) !important;
}

.tw-text-indigo-50\/5 {
  color: rgb(238 242 255 / 0.05) !important;
}

.tw-text-indigo-50\/50 {
  color: rgb(238 242 255 / 0.5) !important;
}

.tw-text-indigo-50\/55 {
  color: rgb(238 242 255 / 0.55) !important;
}

.tw-text-indigo-50\/60 {
  color: rgb(238 242 255 / 0.6) !important;
}

.tw-text-indigo-50\/65 {
  color: rgb(238 242 255 / 0.65) !important;
}

.tw-text-indigo-50\/70 {
  color: rgb(238 242 255 / 0.7) !important;
}

.tw-text-indigo-50\/75 {
  color: rgb(238 242 255 / 0.75) !important;
}

.tw-text-indigo-50\/80 {
  color: rgb(238 242 255 / 0.8) !important;
}

.tw-text-indigo-50\/85 {
  color: rgb(238 242 255 / 0.85) !important;
}

.tw-text-indigo-50\/90 {
  color: rgb(238 242 255 / 0.9) !important;
}

.tw-text-indigo-50\/95 {
  color: rgb(238 242 255 / 0.95) !important;
}

.tw-text-indigo-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(99 102 241 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-500\/0 {
  color: rgb(99 102 241 / 0) !important;
}

.tw-text-indigo-500\/10 {
  color: rgb(99 102 241 / 0.1) !important;
}

.tw-text-indigo-500\/100 {
  color: rgb(99 102 241 / 1) !important;
}

.tw-text-indigo-500\/15 {
  color: rgb(99 102 241 / 0.15) !important;
}

.tw-text-indigo-500\/20 {
  color: rgb(99 102 241 / 0.2) !important;
}

.tw-text-indigo-500\/25 {
  color: rgb(99 102 241 / 0.25) !important;
}

.tw-text-indigo-500\/30 {
  color: rgb(99 102 241 / 0.3) !important;
}

.tw-text-indigo-500\/35 {
  color: rgb(99 102 241 / 0.35) !important;
}

.tw-text-indigo-500\/40 {
  color: rgb(99 102 241 / 0.4) !important;
}

.tw-text-indigo-500\/45 {
  color: rgb(99 102 241 / 0.45) !important;
}

.tw-text-indigo-500\/5 {
  color: rgb(99 102 241 / 0.05) !important;
}

.tw-text-indigo-500\/50 {
  color: rgb(99 102 241 / 0.5) !important;
}

.tw-text-indigo-500\/55 {
  color: rgb(99 102 241 / 0.55) !important;
}

.tw-text-indigo-500\/60 {
  color: rgb(99 102 241 / 0.6) !important;
}

.tw-text-indigo-500\/65 {
  color: rgb(99 102 241 / 0.65) !important;
}

.tw-text-indigo-500\/70 {
  color: rgb(99 102 241 / 0.7) !important;
}

.tw-text-indigo-500\/75 {
  color: rgb(99 102 241 / 0.75) !important;
}

.tw-text-indigo-500\/80 {
  color: rgb(99 102 241 / 0.8) !important;
}

.tw-text-indigo-500\/85 {
  color: rgb(99 102 241 / 0.85) !important;
}

.tw-text-indigo-500\/90 {
  color: rgb(99 102 241 / 0.9) !important;
}

.tw-text-indigo-500\/95 {
  color: rgb(99 102 241 / 0.95) !important;
}

.tw-text-indigo-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-600\/0 {
  color: rgb(79 70 229 / 0) !important;
}

.tw-text-indigo-600\/10 {
  color: rgb(79 70 229 / 0.1) !important;
}

.tw-text-indigo-600\/100 {
  color: rgb(79 70 229 / 1) !important;
}

.tw-text-indigo-600\/15 {
  color: rgb(79 70 229 / 0.15) !important;
}

.tw-text-indigo-600\/20 {
  color: rgb(79 70 229 / 0.2) !important;
}

.tw-text-indigo-600\/25 {
  color: rgb(79 70 229 / 0.25) !important;
}

.tw-text-indigo-600\/30 {
  color: rgb(79 70 229 / 0.3) !important;
}

.tw-text-indigo-600\/35 {
  color: rgb(79 70 229 / 0.35) !important;
}

.tw-text-indigo-600\/40 {
  color: rgb(79 70 229 / 0.4) !important;
}

.tw-text-indigo-600\/45 {
  color: rgb(79 70 229 / 0.45) !important;
}

.tw-text-indigo-600\/5 {
  color: rgb(79 70 229 / 0.05) !important;
}

.tw-text-indigo-600\/50 {
  color: rgb(79 70 229 / 0.5) !important;
}

.tw-text-indigo-600\/55 {
  color: rgb(79 70 229 / 0.55) !important;
}

.tw-text-indigo-600\/60 {
  color: rgb(79 70 229 / 0.6) !important;
}

.tw-text-indigo-600\/65 {
  color: rgb(79 70 229 / 0.65) !important;
}

.tw-text-indigo-600\/70 {
  color: rgb(79 70 229 / 0.7) !important;
}

.tw-text-indigo-600\/75 {
  color: rgb(79 70 229 / 0.75) !important;
}

.tw-text-indigo-600\/80 {
  color: rgb(79 70 229 / 0.8) !important;
}

.tw-text-indigo-600\/85 {
  color: rgb(79 70 229 / 0.85) !important;
}

.tw-text-indigo-600\/90 {
  color: rgb(79 70 229 / 0.9) !important;
}

.tw-text-indigo-600\/95 {
  color: rgb(79 70 229 / 0.95) !important;
}

.tw-text-indigo-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(67 56 202 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-700\/0 {
  color: rgb(67 56 202 / 0) !important;
}

.tw-text-indigo-700\/10 {
  color: rgb(67 56 202 / 0.1) !important;
}

.tw-text-indigo-700\/100 {
  color: rgb(67 56 202 / 1) !important;
}

.tw-text-indigo-700\/15 {
  color: rgb(67 56 202 / 0.15) !important;
}

.tw-text-indigo-700\/20 {
  color: rgb(67 56 202 / 0.2) !important;
}

.tw-text-indigo-700\/25 {
  color: rgb(67 56 202 / 0.25) !important;
}

.tw-text-indigo-700\/30 {
  color: rgb(67 56 202 / 0.3) !important;
}

.tw-text-indigo-700\/35 {
  color: rgb(67 56 202 / 0.35) !important;
}

.tw-text-indigo-700\/40 {
  color: rgb(67 56 202 / 0.4) !important;
}

.tw-text-indigo-700\/45 {
  color: rgb(67 56 202 / 0.45) !important;
}

.tw-text-indigo-700\/5 {
  color: rgb(67 56 202 / 0.05) !important;
}

.tw-text-indigo-700\/50 {
  color: rgb(67 56 202 / 0.5) !important;
}

.tw-text-indigo-700\/55 {
  color: rgb(67 56 202 / 0.55) !important;
}

.tw-text-indigo-700\/60 {
  color: rgb(67 56 202 / 0.6) !important;
}

.tw-text-indigo-700\/65 {
  color: rgb(67 56 202 / 0.65) !important;
}

.tw-text-indigo-700\/70 {
  color: rgb(67 56 202 / 0.7) !important;
}

.tw-text-indigo-700\/75 {
  color: rgb(67 56 202 / 0.75) !important;
}

.tw-text-indigo-700\/80 {
  color: rgb(67 56 202 / 0.8) !important;
}

.tw-text-indigo-700\/85 {
  color: rgb(67 56 202 / 0.85) !important;
}

.tw-text-indigo-700\/90 {
  color: rgb(67 56 202 / 0.9) !important;
}

.tw-text-indigo-700\/95 {
  color: rgb(67 56 202 / 0.95) !important;
}

.tw-text-indigo-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(55 48 163 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-800\/0 {
  color: rgb(55 48 163 / 0) !important;
}

.tw-text-indigo-800\/10 {
  color: rgb(55 48 163 / 0.1) !important;
}

.tw-text-indigo-800\/100 {
  color: rgb(55 48 163 / 1) !important;
}

.tw-text-indigo-800\/15 {
  color: rgb(55 48 163 / 0.15) !important;
}

.tw-text-indigo-800\/20 {
  color: rgb(55 48 163 / 0.2) !important;
}

.tw-text-indigo-800\/25 {
  color: rgb(55 48 163 / 0.25) !important;
}

.tw-text-indigo-800\/30 {
  color: rgb(55 48 163 / 0.3) !important;
}

.tw-text-indigo-800\/35 {
  color: rgb(55 48 163 / 0.35) !important;
}

.tw-text-indigo-800\/40 {
  color: rgb(55 48 163 / 0.4) !important;
}

.tw-text-indigo-800\/45 {
  color: rgb(55 48 163 / 0.45) !important;
}

.tw-text-indigo-800\/5 {
  color: rgb(55 48 163 / 0.05) !important;
}

.tw-text-indigo-800\/50 {
  color: rgb(55 48 163 / 0.5) !important;
}

.tw-text-indigo-800\/55 {
  color: rgb(55 48 163 / 0.55) !important;
}

.tw-text-indigo-800\/60 {
  color: rgb(55 48 163 / 0.6) !important;
}

.tw-text-indigo-800\/65 {
  color: rgb(55 48 163 / 0.65) !important;
}

.tw-text-indigo-800\/70 {
  color: rgb(55 48 163 / 0.7) !important;
}

.tw-text-indigo-800\/75 {
  color: rgb(55 48 163 / 0.75) !important;
}

.tw-text-indigo-800\/80 {
  color: rgb(55 48 163 / 0.8) !important;
}

.tw-text-indigo-800\/85 {
  color: rgb(55 48 163 / 0.85) !important;
}

.tw-text-indigo-800\/90 {
  color: rgb(55 48 163 / 0.9) !important;
}

.tw-text-indigo-800\/95 {
  color: rgb(55 48 163 / 0.95) !important;
}

.tw-text-indigo-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(49 46 129 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-900\/0 {
  color: rgb(49 46 129 / 0) !important;
}

.tw-text-indigo-900\/10 {
  color: rgb(49 46 129 / 0.1) !important;
}

.tw-text-indigo-900\/100 {
  color: rgb(49 46 129 / 1) !important;
}

.tw-text-indigo-900\/15 {
  color: rgb(49 46 129 / 0.15) !important;
}

.tw-text-indigo-900\/20 {
  color: rgb(49 46 129 / 0.2) !important;
}

.tw-text-indigo-900\/25 {
  color: rgb(49 46 129 / 0.25) !important;
}

.tw-text-indigo-900\/30 {
  color: rgb(49 46 129 / 0.3) !important;
}

.tw-text-indigo-900\/35 {
  color: rgb(49 46 129 / 0.35) !important;
}

.tw-text-indigo-900\/40 {
  color: rgb(49 46 129 / 0.4) !important;
}

.tw-text-indigo-900\/45 {
  color: rgb(49 46 129 / 0.45) !important;
}

.tw-text-indigo-900\/5 {
  color: rgb(49 46 129 / 0.05) !important;
}

.tw-text-indigo-900\/50 {
  color: rgb(49 46 129 / 0.5) !important;
}

.tw-text-indigo-900\/55 {
  color: rgb(49 46 129 / 0.55) !important;
}

.tw-text-indigo-900\/60 {
  color: rgb(49 46 129 / 0.6) !important;
}

.tw-text-indigo-900\/65 {
  color: rgb(49 46 129 / 0.65) !important;
}

.tw-text-indigo-900\/70 {
  color: rgb(49 46 129 / 0.7) !important;
}

.tw-text-indigo-900\/75 {
  color: rgb(49 46 129 / 0.75) !important;
}

.tw-text-indigo-900\/80 {
  color: rgb(49 46 129 / 0.8) !important;
}

.tw-text-indigo-900\/85 {
  color: rgb(49 46 129 / 0.85) !important;
}

.tw-text-indigo-900\/90 {
  color: rgb(49 46 129 / 0.9) !important;
}

.tw-text-indigo-900\/95 {
  color: rgb(49 46 129 / 0.95) !important;
}

.tw-text-indigo-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 27 75 / var(--tw-text-opacity)) !important;
}

.tw-text-indigo-950\/0 {
  color: rgb(30 27 75 / 0) !important;
}

.tw-text-indigo-950\/10 {
  color: rgb(30 27 75 / 0.1) !important;
}

.tw-text-indigo-950\/100 {
  color: rgb(30 27 75 / 1) !important;
}

.tw-text-indigo-950\/15 {
  color: rgb(30 27 75 / 0.15) !important;
}

.tw-text-indigo-950\/20 {
  color: rgb(30 27 75 / 0.2) !important;
}

.tw-text-indigo-950\/25 {
  color: rgb(30 27 75 / 0.25) !important;
}

.tw-text-indigo-950\/30 {
  color: rgb(30 27 75 / 0.3) !important;
}

.tw-text-indigo-950\/35 {
  color: rgb(30 27 75 / 0.35) !important;
}

.tw-text-indigo-950\/40 {
  color: rgb(30 27 75 / 0.4) !important;
}

.tw-text-indigo-950\/45 {
  color: rgb(30 27 75 / 0.45) !important;
}

.tw-text-indigo-950\/5 {
  color: rgb(30 27 75 / 0.05) !important;
}

.tw-text-indigo-950\/50 {
  color: rgb(30 27 75 / 0.5) !important;
}

.tw-text-indigo-950\/55 {
  color: rgb(30 27 75 / 0.55) !important;
}

.tw-text-indigo-950\/60 {
  color: rgb(30 27 75 / 0.6) !important;
}

.tw-text-indigo-950\/65 {
  color: rgb(30 27 75 / 0.65) !important;
}

.tw-text-indigo-950\/70 {
  color: rgb(30 27 75 / 0.7) !important;
}

.tw-text-indigo-950\/75 {
  color: rgb(30 27 75 / 0.75) !important;
}

.tw-text-indigo-950\/80 {
  color: rgb(30 27 75 / 0.8) !important;
}

.tw-text-indigo-950\/85 {
  color: rgb(30 27 75 / 0.85) !important;
}

.tw-text-indigo-950\/90 {
  color: rgb(30 27 75 / 0.9) !important;
}

.tw-text-indigo-950\/95 {
  color: rgb(30 27 75 / 0.95) !important;
}

.tw-text-inherit {
  color: inherit !important;
}

.tw-text-lime-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(236 252 203 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-100\/0 {
  color: rgb(236 252 203 / 0) !important;
}

.tw-text-lime-100\/10 {
  color: rgb(236 252 203 / 0.1) !important;
}

.tw-text-lime-100\/100 {
  color: rgb(236 252 203 / 1) !important;
}

.tw-text-lime-100\/15 {
  color: rgb(236 252 203 / 0.15) !important;
}

.tw-text-lime-100\/20 {
  color: rgb(236 252 203 / 0.2) !important;
}

.tw-text-lime-100\/25 {
  color: rgb(236 252 203 / 0.25) !important;
}

.tw-text-lime-100\/30 {
  color: rgb(236 252 203 / 0.3) !important;
}

.tw-text-lime-100\/35 {
  color: rgb(236 252 203 / 0.35) !important;
}

.tw-text-lime-100\/40 {
  color: rgb(236 252 203 / 0.4) !important;
}

.tw-text-lime-100\/45 {
  color: rgb(236 252 203 / 0.45) !important;
}

.tw-text-lime-100\/5 {
  color: rgb(236 252 203 / 0.05) !important;
}

.tw-text-lime-100\/50 {
  color: rgb(236 252 203 / 0.5) !important;
}

.tw-text-lime-100\/55 {
  color: rgb(236 252 203 / 0.55) !important;
}

.tw-text-lime-100\/60 {
  color: rgb(236 252 203 / 0.6) !important;
}

.tw-text-lime-100\/65 {
  color: rgb(236 252 203 / 0.65) !important;
}

.tw-text-lime-100\/70 {
  color: rgb(236 252 203 / 0.7) !important;
}

.tw-text-lime-100\/75 {
  color: rgb(236 252 203 / 0.75) !important;
}

.tw-text-lime-100\/80 {
  color: rgb(236 252 203 / 0.8) !important;
}

.tw-text-lime-100\/85 {
  color: rgb(236 252 203 / 0.85) !important;
}

.tw-text-lime-100\/90 {
  color: rgb(236 252 203 / 0.9) !important;
}

.tw-text-lime-100\/95 {
  color: rgb(236 252 203 / 0.95) !important;
}

.tw-text-lime-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(217 249 157 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-200\/0 {
  color: rgb(217 249 157 / 0) !important;
}

.tw-text-lime-200\/10 {
  color: rgb(217 249 157 / 0.1) !important;
}

.tw-text-lime-200\/100 {
  color: rgb(217 249 157 / 1) !important;
}

.tw-text-lime-200\/15 {
  color: rgb(217 249 157 / 0.15) !important;
}

.tw-text-lime-200\/20 {
  color: rgb(217 249 157 / 0.2) !important;
}

.tw-text-lime-200\/25 {
  color: rgb(217 249 157 / 0.25) !important;
}

.tw-text-lime-200\/30 {
  color: rgb(217 249 157 / 0.3) !important;
}

.tw-text-lime-200\/35 {
  color: rgb(217 249 157 / 0.35) !important;
}

.tw-text-lime-200\/40 {
  color: rgb(217 249 157 / 0.4) !important;
}

.tw-text-lime-200\/45 {
  color: rgb(217 249 157 / 0.45) !important;
}

.tw-text-lime-200\/5 {
  color: rgb(217 249 157 / 0.05) !important;
}

.tw-text-lime-200\/50 {
  color: rgb(217 249 157 / 0.5) !important;
}

.tw-text-lime-200\/55 {
  color: rgb(217 249 157 / 0.55) !important;
}

.tw-text-lime-200\/60 {
  color: rgb(217 249 157 / 0.6) !important;
}

.tw-text-lime-200\/65 {
  color: rgb(217 249 157 / 0.65) !important;
}

.tw-text-lime-200\/70 {
  color: rgb(217 249 157 / 0.7) !important;
}

.tw-text-lime-200\/75 {
  color: rgb(217 249 157 / 0.75) !important;
}

.tw-text-lime-200\/80 {
  color: rgb(217 249 157 / 0.8) !important;
}

.tw-text-lime-200\/85 {
  color: rgb(217 249 157 / 0.85) !important;
}

.tw-text-lime-200\/90 {
  color: rgb(217 249 157 / 0.9) !important;
}

.tw-text-lime-200\/95 {
  color: rgb(217 249 157 / 0.95) !important;
}

.tw-text-lime-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(190 242 100 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-300\/0 {
  color: rgb(190 242 100 / 0) !important;
}

.tw-text-lime-300\/10 {
  color: rgb(190 242 100 / 0.1) !important;
}

.tw-text-lime-300\/100 {
  color: rgb(190 242 100 / 1) !important;
}

.tw-text-lime-300\/15 {
  color: rgb(190 242 100 / 0.15) !important;
}

.tw-text-lime-300\/20 {
  color: rgb(190 242 100 / 0.2) !important;
}

.tw-text-lime-300\/25 {
  color: rgb(190 242 100 / 0.25) !important;
}

.tw-text-lime-300\/30 {
  color: rgb(190 242 100 / 0.3) !important;
}

.tw-text-lime-300\/35 {
  color: rgb(190 242 100 / 0.35) !important;
}

.tw-text-lime-300\/40 {
  color: rgb(190 242 100 / 0.4) !important;
}

.tw-text-lime-300\/45 {
  color: rgb(190 242 100 / 0.45) !important;
}

.tw-text-lime-300\/5 {
  color: rgb(190 242 100 / 0.05) !important;
}

.tw-text-lime-300\/50 {
  color: rgb(190 242 100 / 0.5) !important;
}

.tw-text-lime-300\/55 {
  color: rgb(190 242 100 / 0.55) !important;
}

.tw-text-lime-300\/60 {
  color: rgb(190 242 100 / 0.6) !important;
}

.tw-text-lime-300\/65 {
  color: rgb(190 242 100 / 0.65) !important;
}

.tw-text-lime-300\/70 {
  color: rgb(190 242 100 / 0.7) !important;
}

.tw-text-lime-300\/75 {
  color: rgb(190 242 100 / 0.75) !important;
}

.tw-text-lime-300\/80 {
  color: rgb(190 242 100 / 0.8) !important;
}

.tw-text-lime-300\/85 {
  color: rgb(190 242 100 / 0.85) !important;
}

.tw-text-lime-300\/90 {
  color: rgb(190 242 100 / 0.9) !important;
}

.tw-text-lime-300\/95 {
  color: rgb(190 242 100 / 0.95) !important;
}

.tw-text-lime-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(163 230 53 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-400\/0 {
  color: rgb(163 230 53 / 0) !important;
}

.tw-text-lime-400\/10 {
  color: rgb(163 230 53 / 0.1) !important;
}

.tw-text-lime-400\/100 {
  color: rgb(163 230 53 / 1) !important;
}

.tw-text-lime-400\/15 {
  color: rgb(163 230 53 / 0.15) !important;
}

.tw-text-lime-400\/20 {
  color: rgb(163 230 53 / 0.2) !important;
}

.tw-text-lime-400\/25 {
  color: rgb(163 230 53 / 0.25) !important;
}

.tw-text-lime-400\/30 {
  color: rgb(163 230 53 / 0.3) !important;
}

.tw-text-lime-400\/35 {
  color: rgb(163 230 53 / 0.35) !important;
}

.tw-text-lime-400\/40 {
  color: rgb(163 230 53 / 0.4) !important;
}

.tw-text-lime-400\/45 {
  color: rgb(163 230 53 / 0.45) !important;
}

.tw-text-lime-400\/5 {
  color: rgb(163 230 53 / 0.05) !important;
}

.tw-text-lime-400\/50 {
  color: rgb(163 230 53 / 0.5) !important;
}

.tw-text-lime-400\/55 {
  color: rgb(163 230 53 / 0.55) !important;
}

.tw-text-lime-400\/60 {
  color: rgb(163 230 53 / 0.6) !important;
}

.tw-text-lime-400\/65 {
  color: rgb(163 230 53 / 0.65) !important;
}

.tw-text-lime-400\/70 {
  color: rgb(163 230 53 / 0.7) !important;
}

.tw-text-lime-400\/75 {
  color: rgb(163 230 53 / 0.75) !important;
}

.tw-text-lime-400\/80 {
  color: rgb(163 230 53 / 0.8) !important;
}

.tw-text-lime-400\/85 {
  color: rgb(163 230 53 / 0.85) !important;
}

.tw-text-lime-400\/90 {
  color: rgb(163 230 53 / 0.9) !important;
}

.tw-text-lime-400\/95 {
  color: rgb(163 230 53 / 0.95) !important;
}

.tw-text-lime-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(247 254 231 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-50\/0 {
  color: rgb(247 254 231 / 0) !important;
}

.tw-text-lime-50\/10 {
  color: rgb(247 254 231 / 0.1) !important;
}

.tw-text-lime-50\/100 {
  color: rgb(247 254 231 / 1) !important;
}

.tw-text-lime-50\/15 {
  color: rgb(247 254 231 / 0.15) !important;
}

.tw-text-lime-50\/20 {
  color: rgb(247 254 231 / 0.2) !important;
}

.tw-text-lime-50\/25 {
  color: rgb(247 254 231 / 0.25) !important;
}

.tw-text-lime-50\/30 {
  color: rgb(247 254 231 / 0.3) !important;
}

.tw-text-lime-50\/35 {
  color: rgb(247 254 231 / 0.35) !important;
}

.tw-text-lime-50\/40 {
  color: rgb(247 254 231 / 0.4) !important;
}

.tw-text-lime-50\/45 {
  color: rgb(247 254 231 / 0.45) !important;
}

.tw-text-lime-50\/5 {
  color: rgb(247 254 231 / 0.05) !important;
}

.tw-text-lime-50\/50 {
  color: rgb(247 254 231 / 0.5) !important;
}

.tw-text-lime-50\/55 {
  color: rgb(247 254 231 / 0.55) !important;
}

.tw-text-lime-50\/60 {
  color: rgb(247 254 231 / 0.6) !important;
}

.tw-text-lime-50\/65 {
  color: rgb(247 254 231 / 0.65) !important;
}

.tw-text-lime-50\/70 {
  color: rgb(247 254 231 / 0.7) !important;
}

.tw-text-lime-50\/75 {
  color: rgb(247 254 231 / 0.75) !important;
}

.tw-text-lime-50\/80 {
  color: rgb(247 254 231 / 0.8) !important;
}

.tw-text-lime-50\/85 {
  color: rgb(247 254 231 / 0.85) !important;
}

.tw-text-lime-50\/90 {
  color: rgb(247 254 231 / 0.9) !important;
}

.tw-text-lime-50\/95 {
  color: rgb(247 254 231 / 0.95) !important;
}

.tw-text-lime-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(132 204 22 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-500\/0 {
  color: rgb(132 204 22 / 0) !important;
}

.tw-text-lime-500\/10 {
  color: rgb(132 204 22 / 0.1) !important;
}

.tw-text-lime-500\/100 {
  color: rgb(132 204 22 / 1) !important;
}

.tw-text-lime-500\/15 {
  color: rgb(132 204 22 / 0.15) !important;
}

.tw-text-lime-500\/20 {
  color: rgb(132 204 22 / 0.2) !important;
}

.tw-text-lime-500\/25 {
  color: rgb(132 204 22 / 0.25) !important;
}

.tw-text-lime-500\/30 {
  color: rgb(132 204 22 / 0.3) !important;
}

.tw-text-lime-500\/35 {
  color: rgb(132 204 22 / 0.35) !important;
}

.tw-text-lime-500\/40 {
  color: rgb(132 204 22 / 0.4) !important;
}

.tw-text-lime-500\/45 {
  color: rgb(132 204 22 / 0.45) !important;
}

.tw-text-lime-500\/5 {
  color: rgb(132 204 22 / 0.05) !important;
}

.tw-text-lime-500\/50 {
  color: rgb(132 204 22 / 0.5) !important;
}

.tw-text-lime-500\/55 {
  color: rgb(132 204 22 / 0.55) !important;
}

.tw-text-lime-500\/60 {
  color: rgb(132 204 22 / 0.6) !important;
}

.tw-text-lime-500\/65 {
  color: rgb(132 204 22 / 0.65) !important;
}

.tw-text-lime-500\/70 {
  color: rgb(132 204 22 / 0.7) !important;
}

.tw-text-lime-500\/75 {
  color: rgb(132 204 22 / 0.75) !important;
}

.tw-text-lime-500\/80 {
  color: rgb(132 204 22 / 0.8) !important;
}

.tw-text-lime-500\/85 {
  color: rgb(132 204 22 / 0.85) !important;
}

.tw-text-lime-500\/90 {
  color: rgb(132 204 22 / 0.9) !important;
}

.tw-text-lime-500\/95 {
  color: rgb(132 204 22 / 0.95) !important;
}

.tw-text-lime-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(101 163 13 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-600\/0 {
  color: rgb(101 163 13 / 0) !important;
}

.tw-text-lime-600\/10 {
  color: rgb(101 163 13 / 0.1) !important;
}

.tw-text-lime-600\/100 {
  color: rgb(101 163 13 / 1) !important;
}

.tw-text-lime-600\/15 {
  color: rgb(101 163 13 / 0.15) !important;
}

.tw-text-lime-600\/20 {
  color: rgb(101 163 13 / 0.2) !important;
}

.tw-text-lime-600\/25 {
  color: rgb(101 163 13 / 0.25) !important;
}

.tw-text-lime-600\/30 {
  color: rgb(101 163 13 / 0.3) !important;
}

.tw-text-lime-600\/35 {
  color: rgb(101 163 13 / 0.35) !important;
}

.tw-text-lime-600\/40 {
  color: rgb(101 163 13 / 0.4) !important;
}

.tw-text-lime-600\/45 {
  color: rgb(101 163 13 / 0.45) !important;
}

.tw-text-lime-600\/5 {
  color: rgb(101 163 13 / 0.05) !important;
}

.tw-text-lime-600\/50 {
  color: rgb(101 163 13 / 0.5) !important;
}

.tw-text-lime-600\/55 {
  color: rgb(101 163 13 / 0.55) !important;
}

.tw-text-lime-600\/60 {
  color: rgb(101 163 13 / 0.6) !important;
}

.tw-text-lime-600\/65 {
  color: rgb(101 163 13 / 0.65) !important;
}

.tw-text-lime-600\/70 {
  color: rgb(101 163 13 / 0.7) !important;
}

.tw-text-lime-600\/75 {
  color: rgb(101 163 13 / 0.75) !important;
}

.tw-text-lime-600\/80 {
  color: rgb(101 163 13 / 0.8) !important;
}

.tw-text-lime-600\/85 {
  color: rgb(101 163 13 / 0.85) !important;
}

.tw-text-lime-600\/90 {
  color: rgb(101 163 13 / 0.9) !important;
}

.tw-text-lime-600\/95 {
  color: rgb(101 163 13 / 0.95) !important;
}

.tw-text-lime-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(77 124 15 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-700\/0 {
  color: rgb(77 124 15 / 0) !important;
}

.tw-text-lime-700\/10 {
  color: rgb(77 124 15 / 0.1) !important;
}

.tw-text-lime-700\/100 {
  color: rgb(77 124 15 / 1) !important;
}

.tw-text-lime-700\/15 {
  color: rgb(77 124 15 / 0.15) !important;
}

.tw-text-lime-700\/20 {
  color: rgb(77 124 15 / 0.2) !important;
}

.tw-text-lime-700\/25 {
  color: rgb(77 124 15 / 0.25) !important;
}

.tw-text-lime-700\/30 {
  color: rgb(77 124 15 / 0.3) !important;
}

.tw-text-lime-700\/35 {
  color: rgb(77 124 15 / 0.35) !important;
}

.tw-text-lime-700\/40 {
  color: rgb(77 124 15 / 0.4) !important;
}

.tw-text-lime-700\/45 {
  color: rgb(77 124 15 / 0.45) !important;
}

.tw-text-lime-700\/5 {
  color: rgb(77 124 15 / 0.05) !important;
}

.tw-text-lime-700\/50 {
  color: rgb(77 124 15 / 0.5) !important;
}

.tw-text-lime-700\/55 {
  color: rgb(77 124 15 / 0.55) !important;
}

.tw-text-lime-700\/60 {
  color: rgb(77 124 15 / 0.6) !important;
}

.tw-text-lime-700\/65 {
  color: rgb(77 124 15 / 0.65) !important;
}

.tw-text-lime-700\/70 {
  color: rgb(77 124 15 / 0.7) !important;
}

.tw-text-lime-700\/75 {
  color: rgb(77 124 15 / 0.75) !important;
}

.tw-text-lime-700\/80 {
  color: rgb(77 124 15 / 0.8) !important;
}

.tw-text-lime-700\/85 {
  color: rgb(77 124 15 / 0.85) !important;
}

.tw-text-lime-700\/90 {
  color: rgb(77 124 15 / 0.9) !important;
}

.tw-text-lime-700\/95 {
  color: rgb(77 124 15 / 0.95) !important;
}

.tw-text-lime-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(63 98 18 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-800\/0 {
  color: rgb(63 98 18 / 0) !important;
}

.tw-text-lime-800\/10 {
  color: rgb(63 98 18 / 0.1) !important;
}

.tw-text-lime-800\/100 {
  color: rgb(63 98 18 / 1) !important;
}

.tw-text-lime-800\/15 {
  color: rgb(63 98 18 / 0.15) !important;
}

.tw-text-lime-800\/20 {
  color: rgb(63 98 18 / 0.2) !important;
}

.tw-text-lime-800\/25 {
  color: rgb(63 98 18 / 0.25) !important;
}

.tw-text-lime-800\/30 {
  color: rgb(63 98 18 / 0.3) !important;
}

.tw-text-lime-800\/35 {
  color: rgb(63 98 18 / 0.35) !important;
}

.tw-text-lime-800\/40 {
  color: rgb(63 98 18 / 0.4) !important;
}

.tw-text-lime-800\/45 {
  color: rgb(63 98 18 / 0.45) !important;
}

.tw-text-lime-800\/5 {
  color: rgb(63 98 18 / 0.05) !important;
}

.tw-text-lime-800\/50 {
  color: rgb(63 98 18 / 0.5) !important;
}

.tw-text-lime-800\/55 {
  color: rgb(63 98 18 / 0.55) !important;
}

.tw-text-lime-800\/60 {
  color: rgb(63 98 18 / 0.6) !important;
}

.tw-text-lime-800\/65 {
  color: rgb(63 98 18 / 0.65) !important;
}

.tw-text-lime-800\/70 {
  color: rgb(63 98 18 / 0.7) !important;
}

.tw-text-lime-800\/75 {
  color: rgb(63 98 18 / 0.75) !important;
}

.tw-text-lime-800\/80 {
  color: rgb(63 98 18 / 0.8) !important;
}

.tw-text-lime-800\/85 {
  color: rgb(63 98 18 / 0.85) !important;
}

.tw-text-lime-800\/90 {
  color: rgb(63 98 18 / 0.9) !important;
}

.tw-text-lime-800\/95 {
  color: rgb(63 98 18 / 0.95) !important;
}

.tw-text-lime-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(54 83 20 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-900\/0 {
  color: rgb(54 83 20 / 0) !important;
}

.tw-text-lime-900\/10 {
  color: rgb(54 83 20 / 0.1) !important;
}

.tw-text-lime-900\/100 {
  color: rgb(54 83 20 / 1) !important;
}

.tw-text-lime-900\/15 {
  color: rgb(54 83 20 / 0.15) !important;
}

.tw-text-lime-900\/20 {
  color: rgb(54 83 20 / 0.2) !important;
}

.tw-text-lime-900\/25 {
  color: rgb(54 83 20 / 0.25) !important;
}

.tw-text-lime-900\/30 {
  color: rgb(54 83 20 / 0.3) !important;
}

.tw-text-lime-900\/35 {
  color: rgb(54 83 20 / 0.35) !important;
}

.tw-text-lime-900\/40 {
  color: rgb(54 83 20 / 0.4) !important;
}

.tw-text-lime-900\/45 {
  color: rgb(54 83 20 / 0.45) !important;
}

.tw-text-lime-900\/5 {
  color: rgb(54 83 20 / 0.05) !important;
}

.tw-text-lime-900\/50 {
  color: rgb(54 83 20 / 0.5) !important;
}

.tw-text-lime-900\/55 {
  color: rgb(54 83 20 / 0.55) !important;
}

.tw-text-lime-900\/60 {
  color: rgb(54 83 20 / 0.6) !important;
}

.tw-text-lime-900\/65 {
  color: rgb(54 83 20 / 0.65) !important;
}

.tw-text-lime-900\/70 {
  color: rgb(54 83 20 / 0.7) !important;
}

.tw-text-lime-900\/75 {
  color: rgb(54 83 20 / 0.75) !important;
}

.tw-text-lime-900\/80 {
  color: rgb(54 83 20 / 0.8) !important;
}

.tw-text-lime-900\/85 {
  color: rgb(54 83 20 / 0.85) !important;
}

.tw-text-lime-900\/90 {
  color: rgb(54 83 20 / 0.9) !important;
}

.tw-text-lime-900\/95 {
  color: rgb(54 83 20 / 0.95) !important;
}

.tw-text-lime-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(26 46 5 / var(--tw-text-opacity)) !important;
}

.tw-text-lime-950\/0 {
  color: rgb(26 46 5 / 0) !important;
}

.tw-text-lime-950\/10 {
  color: rgb(26 46 5 / 0.1) !important;
}

.tw-text-lime-950\/100 {
  color: rgb(26 46 5 / 1) !important;
}

.tw-text-lime-950\/15 {
  color: rgb(26 46 5 / 0.15) !important;
}

.tw-text-lime-950\/20 {
  color: rgb(26 46 5 / 0.2) !important;
}

.tw-text-lime-950\/25 {
  color: rgb(26 46 5 / 0.25) !important;
}

.tw-text-lime-950\/30 {
  color: rgb(26 46 5 / 0.3) !important;
}

.tw-text-lime-950\/35 {
  color: rgb(26 46 5 / 0.35) !important;
}

.tw-text-lime-950\/40 {
  color: rgb(26 46 5 / 0.4) !important;
}

.tw-text-lime-950\/45 {
  color: rgb(26 46 5 / 0.45) !important;
}

.tw-text-lime-950\/5 {
  color: rgb(26 46 5 / 0.05) !important;
}

.tw-text-lime-950\/50 {
  color: rgb(26 46 5 / 0.5) !important;
}

.tw-text-lime-950\/55 {
  color: rgb(26 46 5 / 0.55) !important;
}

.tw-text-lime-950\/60 {
  color: rgb(26 46 5 / 0.6) !important;
}

.tw-text-lime-950\/65 {
  color: rgb(26 46 5 / 0.65) !important;
}

.tw-text-lime-950\/70 {
  color: rgb(26 46 5 / 0.7) !important;
}

.tw-text-lime-950\/75 {
  color: rgb(26 46 5 / 0.75) !important;
}

.tw-text-lime-950\/80 {
  color: rgb(26 46 5 / 0.8) !important;
}

.tw-text-lime-950\/85 {
  color: rgb(26 46 5 / 0.85) !important;
}

.tw-text-lime-950\/90 {
  color: rgb(26 46 5 / 0.9) !important;
}

.tw-text-lime-950\/95 {
  color: rgb(26 46 5 / 0.95) !important;
}

.tw-text-neutral-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 245 245 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-100\/0 {
  color: rgb(245 245 245 / 0) !important;
}

.tw-text-neutral-100\/10 {
  color: rgb(245 245 245 / 0.1) !important;
}

.tw-text-neutral-100\/100 {
  color: rgb(245 245 245 / 1) !important;
}

.tw-text-neutral-100\/15 {
  color: rgb(245 245 245 / 0.15) !important;
}

.tw-text-neutral-100\/20 {
  color: rgb(245 245 245 / 0.2) !important;
}

.tw-text-neutral-100\/25 {
  color: rgb(245 245 245 / 0.25) !important;
}

.tw-text-neutral-100\/30 {
  color: rgb(245 245 245 / 0.3) !important;
}

.tw-text-neutral-100\/35 {
  color: rgb(245 245 245 / 0.35) !important;
}

.tw-text-neutral-100\/40 {
  color: rgb(245 245 245 / 0.4) !important;
}

.tw-text-neutral-100\/45 {
  color: rgb(245 245 245 / 0.45) !important;
}

.tw-text-neutral-100\/5 {
  color: rgb(245 245 245 / 0.05) !important;
}

.tw-text-neutral-100\/50 {
  color: rgb(245 245 245 / 0.5) !important;
}

.tw-text-neutral-100\/55 {
  color: rgb(245 245 245 / 0.55) !important;
}

.tw-text-neutral-100\/60 {
  color: rgb(245 245 245 / 0.6) !important;
}

.tw-text-neutral-100\/65 {
  color: rgb(245 245 245 / 0.65) !important;
}

.tw-text-neutral-100\/70 {
  color: rgb(245 245 245 / 0.7) !important;
}

.tw-text-neutral-100\/75 {
  color: rgb(245 245 245 / 0.75) !important;
}

.tw-text-neutral-100\/80 {
  color: rgb(245 245 245 / 0.8) !important;
}

.tw-text-neutral-100\/85 {
  color: rgb(245 245 245 / 0.85) !important;
}

.tw-text-neutral-100\/90 {
  color: rgb(245 245 245 / 0.9) !important;
}

.tw-text-neutral-100\/95 {
  color: rgb(245 245 245 / 0.95) !important;
}

.tw-text-neutral-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(229 229 229 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-200\/0 {
  color: rgb(229 229 229 / 0) !important;
}

.tw-text-neutral-200\/10 {
  color: rgb(229 229 229 / 0.1) !important;
}

.tw-text-neutral-200\/100 {
  color: rgb(229 229 229 / 1) !important;
}

.tw-text-neutral-200\/15 {
  color: rgb(229 229 229 / 0.15) !important;
}

.tw-text-neutral-200\/20 {
  color: rgb(229 229 229 / 0.2) !important;
}

.tw-text-neutral-200\/25 {
  color: rgb(229 229 229 / 0.25) !important;
}

.tw-text-neutral-200\/30 {
  color: rgb(229 229 229 / 0.3) !important;
}

.tw-text-neutral-200\/35 {
  color: rgb(229 229 229 / 0.35) !important;
}

.tw-text-neutral-200\/40 {
  color: rgb(229 229 229 / 0.4) !important;
}

.tw-text-neutral-200\/45 {
  color: rgb(229 229 229 / 0.45) !important;
}

.tw-text-neutral-200\/5 {
  color: rgb(229 229 229 / 0.05) !important;
}

.tw-text-neutral-200\/50 {
  color: rgb(229 229 229 / 0.5) !important;
}

.tw-text-neutral-200\/55 {
  color: rgb(229 229 229 / 0.55) !important;
}

.tw-text-neutral-200\/60 {
  color: rgb(229 229 229 / 0.6) !important;
}

.tw-text-neutral-200\/65 {
  color: rgb(229 229 229 / 0.65) !important;
}

.tw-text-neutral-200\/70 {
  color: rgb(229 229 229 / 0.7) !important;
}

.tw-text-neutral-200\/75 {
  color: rgb(229 229 229 / 0.75) !important;
}

.tw-text-neutral-200\/80 {
  color: rgb(229 229 229 / 0.8) !important;
}

.tw-text-neutral-200\/85 {
  color: rgb(229 229 229 / 0.85) !important;
}

.tw-text-neutral-200\/90 {
  color: rgb(229 229 229 / 0.9) !important;
}

.tw-text-neutral-200\/95 {
  color: rgb(229 229 229 / 0.95) !important;
}

.tw-text-neutral-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(212 212 212 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-300\/0 {
  color: rgb(212 212 212 / 0) !important;
}

.tw-text-neutral-300\/10 {
  color: rgb(212 212 212 / 0.1) !important;
}

.tw-text-neutral-300\/100 {
  color: rgb(212 212 212 / 1) !important;
}

.tw-text-neutral-300\/15 {
  color: rgb(212 212 212 / 0.15) !important;
}

.tw-text-neutral-300\/20 {
  color: rgb(212 212 212 / 0.2) !important;
}

.tw-text-neutral-300\/25 {
  color: rgb(212 212 212 / 0.25) !important;
}

.tw-text-neutral-300\/30 {
  color: rgb(212 212 212 / 0.3) !important;
}

.tw-text-neutral-300\/35 {
  color: rgb(212 212 212 / 0.35) !important;
}

.tw-text-neutral-300\/40 {
  color: rgb(212 212 212 / 0.4) !important;
}

.tw-text-neutral-300\/45 {
  color: rgb(212 212 212 / 0.45) !important;
}

.tw-text-neutral-300\/5 {
  color: rgb(212 212 212 / 0.05) !important;
}

.tw-text-neutral-300\/50 {
  color: rgb(212 212 212 / 0.5) !important;
}

.tw-text-neutral-300\/55 {
  color: rgb(212 212 212 / 0.55) !important;
}

.tw-text-neutral-300\/60 {
  color: rgb(212 212 212 / 0.6) !important;
}

.tw-text-neutral-300\/65 {
  color: rgb(212 212 212 / 0.65) !important;
}

.tw-text-neutral-300\/70 {
  color: rgb(212 212 212 / 0.7) !important;
}

.tw-text-neutral-300\/75 {
  color: rgb(212 212 212 / 0.75) !important;
}

.tw-text-neutral-300\/80 {
  color: rgb(212 212 212 / 0.8) !important;
}

.tw-text-neutral-300\/85 {
  color: rgb(212 212 212 / 0.85) !important;
}

.tw-text-neutral-300\/90 {
  color: rgb(212 212 212 / 0.9) !important;
}

.tw-text-neutral-300\/95 {
  color: rgb(212 212 212 / 0.95) !important;
}

.tw-text-neutral-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(163 163 163 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-400\/0 {
  color: rgb(163 163 163 / 0) !important;
}

.tw-text-neutral-400\/10 {
  color: rgb(163 163 163 / 0.1) !important;
}

.tw-text-neutral-400\/100 {
  color: rgb(163 163 163 / 1) !important;
}

.tw-text-neutral-400\/15 {
  color: rgb(163 163 163 / 0.15) !important;
}

.tw-text-neutral-400\/20 {
  color: rgb(163 163 163 / 0.2) !important;
}

.tw-text-neutral-400\/25 {
  color: rgb(163 163 163 / 0.25) !important;
}

.tw-text-neutral-400\/30 {
  color: rgb(163 163 163 / 0.3) !important;
}

.tw-text-neutral-400\/35 {
  color: rgb(163 163 163 / 0.35) !important;
}

.tw-text-neutral-400\/40 {
  color: rgb(163 163 163 / 0.4) !important;
}

.tw-text-neutral-400\/45 {
  color: rgb(163 163 163 / 0.45) !important;
}

.tw-text-neutral-400\/5 {
  color: rgb(163 163 163 / 0.05) !important;
}

.tw-text-neutral-400\/50 {
  color: rgb(163 163 163 / 0.5) !important;
}

.tw-text-neutral-400\/55 {
  color: rgb(163 163 163 / 0.55) !important;
}

.tw-text-neutral-400\/60 {
  color: rgb(163 163 163 / 0.6) !important;
}

.tw-text-neutral-400\/65 {
  color: rgb(163 163 163 / 0.65) !important;
}

.tw-text-neutral-400\/70 {
  color: rgb(163 163 163 / 0.7) !important;
}

.tw-text-neutral-400\/75 {
  color: rgb(163 163 163 / 0.75) !important;
}

.tw-text-neutral-400\/80 {
  color: rgb(163 163 163 / 0.8) !important;
}

.tw-text-neutral-400\/85 {
  color: rgb(163 163 163 / 0.85) !important;
}

.tw-text-neutral-400\/90 {
  color: rgb(163 163 163 / 0.9) !important;
}

.tw-text-neutral-400\/95 {
  color: rgb(163 163 163 / 0.95) !important;
}

.tw-text-neutral-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(250 250 250 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-50\/0 {
  color: rgb(250 250 250 / 0) !important;
}

.tw-text-neutral-50\/10 {
  color: rgb(250 250 250 / 0.1) !important;
}

.tw-text-neutral-50\/100 {
  color: rgb(250 250 250 / 1) !important;
}

.tw-text-neutral-50\/15 {
  color: rgb(250 250 250 / 0.15) !important;
}

.tw-text-neutral-50\/20 {
  color: rgb(250 250 250 / 0.2) !important;
}

.tw-text-neutral-50\/25 {
  color: rgb(250 250 250 / 0.25) !important;
}

.tw-text-neutral-50\/30 {
  color: rgb(250 250 250 / 0.3) !important;
}

.tw-text-neutral-50\/35 {
  color: rgb(250 250 250 / 0.35) !important;
}

.tw-text-neutral-50\/40 {
  color: rgb(250 250 250 / 0.4) !important;
}

.tw-text-neutral-50\/45 {
  color: rgb(250 250 250 / 0.45) !important;
}

.tw-text-neutral-50\/5 {
  color: rgb(250 250 250 / 0.05) !important;
}

.tw-text-neutral-50\/50 {
  color: rgb(250 250 250 / 0.5) !important;
}

.tw-text-neutral-50\/55 {
  color: rgb(250 250 250 / 0.55) !important;
}

.tw-text-neutral-50\/60 {
  color: rgb(250 250 250 / 0.6) !important;
}

.tw-text-neutral-50\/65 {
  color: rgb(250 250 250 / 0.65) !important;
}

.tw-text-neutral-50\/70 {
  color: rgb(250 250 250 / 0.7) !important;
}

.tw-text-neutral-50\/75 {
  color: rgb(250 250 250 / 0.75) !important;
}

.tw-text-neutral-50\/80 {
  color: rgb(250 250 250 / 0.8) !important;
}

.tw-text-neutral-50\/85 {
  color: rgb(250 250 250 / 0.85) !important;
}

.tw-text-neutral-50\/90 {
  color: rgb(250 250 250 / 0.9) !important;
}

.tw-text-neutral-50\/95 {
  color: rgb(250 250 250 / 0.95) !important;
}

.tw-text-neutral-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(115 115 115 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-500\/0 {
  color: rgb(115 115 115 / 0) !important;
}

.tw-text-neutral-500\/10 {
  color: rgb(115 115 115 / 0.1) !important;
}

.tw-text-neutral-500\/100 {
  color: rgb(115 115 115 / 1) !important;
}

.tw-text-neutral-500\/15 {
  color: rgb(115 115 115 / 0.15) !important;
}

.tw-text-neutral-500\/20 {
  color: rgb(115 115 115 / 0.2) !important;
}

.tw-text-neutral-500\/25 {
  color: rgb(115 115 115 / 0.25) !important;
}

.tw-text-neutral-500\/30 {
  color: rgb(115 115 115 / 0.3) !important;
}

.tw-text-neutral-500\/35 {
  color: rgb(115 115 115 / 0.35) !important;
}

.tw-text-neutral-500\/40 {
  color: rgb(115 115 115 / 0.4) !important;
}

.tw-text-neutral-500\/45 {
  color: rgb(115 115 115 / 0.45) !important;
}

.tw-text-neutral-500\/5 {
  color: rgb(115 115 115 / 0.05) !important;
}

.tw-text-neutral-500\/50 {
  color: rgb(115 115 115 / 0.5) !important;
}

.tw-text-neutral-500\/55 {
  color: rgb(115 115 115 / 0.55) !important;
}

.tw-text-neutral-500\/60 {
  color: rgb(115 115 115 / 0.6) !important;
}

.tw-text-neutral-500\/65 {
  color: rgb(115 115 115 / 0.65) !important;
}

.tw-text-neutral-500\/70 {
  color: rgb(115 115 115 / 0.7) !important;
}

.tw-text-neutral-500\/75 {
  color: rgb(115 115 115 / 0.75) !important;
}

.tw-text-neutral-500\/80 {
  color: rgb(115 115 115 / 0.8) !important;
}

.tw-text-neutral-500\/85 {
  color: rgb(115 115 115 / 0.85) !important;
}

.tw-text-neutral-500\/90 {
  color: rgb(115 115 115 / 0.9) !important;
}

.tw-text-neutral-500\/95 {
  color: rgb(115 115 115 / 0.95) !important;
}

.tw-text-neutral-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(82 82 82 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-600\/0 {
  color: rgb(82 82 82 / 0) !important;
}

.tw-text-neutral-600\/10 {
  color: rgb(82 82 82 / 0.1) !important;
}

.tw-text-neutral-600\/100 {
  color: rgb(82 82 82 / 1) !important;
}

.tw-text-neutral-600\/15 {
  color: rgb(82 82 82 / 0.15) !important;
}

.tw-text-neutral-600\/20 {
  color: rgb(82 82 82 / 0.2) !important;
}

.tw-text-neutral-600\/25 {
  color: rgb(82 82 82 / 0.25) !important;
}

.tw-text-neutral-600\/30 {
  color: rgb(82 82 82 / 0.3) !important;
}

.tw-text-neutral-600\/35 {
  color: rgb(82 82 82 / 0.35) !important;
}

.tw-text-neutral-600\/40 {
  color: rgb(82 82 82 / 0.4) !important;
}

.tw-text-neutral-600\/45 {
  color: rgb(82 82 82 / 0.45) !important;
}

.tw-text-neutral-600\/5 {
  color: rgb(82 82 82 / 0.05) !important;
}

.tw-text-neutral-600\/50 {
  color: rgb(82 82 82 / 0.5) !important;
}

.tw-text-neutral-600\/55 {
  color: rgb(82 82 82 / 0.55) !important;
}

.tw-text-neutral-600\/60 {
  color: rgb(82 82 82 / 0.6) !important;
}

.tw-text-neutral-600\/65 {
  color: rgb(82 82 82 / 0.65) !important;
}

.tw-text-neutral-600\/70 {
  color: rgb(82 82 82 / 0.7) !important;
}

.tw-text-neutral-600\/75 {
  color: rgb(82 82 82 / 0.75) !important;
}

.tw-text-neutral-600\/80 {
  color: rgb(82 82 82 / 0.8) !important;
}

.tw-text-neutral-600\/85 {
  color: rgb(82 82 82 / 0.85) !important;
}

.tw-text-neutral-600\/90 {
  color: rgb(82 82 82 / 0.9) !important;
}

.tw-text-neutral-600\/95 {
  color: rgb(82 82 82 / 0.95) !important;
}

.tw-text-neutral-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(64 64 64 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-700\/0 {
  color: rgb(64 64 64 / 0) !important;
}

.tw-text-neutral-700\/10 {
  color: rgb(64 64 64 / 0.1) !important;
}

.tw-text-neutral-700\/100 {
  color: rgb(64 64 64 / 1) !important;
}

.tw-text-neutral-700\/15 {
  color: rgb(64 64 64 / 0.15) !important;
}

.tw-text-neutral-700\/20 {
  color: rgb(64 64 64 / 0.2) !important;
}

.tw-text-neutral-700\/25 {
  color: rgb(64 64 64 / 0.25) !important;
}

.tw-text-neutral-700\/30 {
  color: rgb(64 64 64 / 0.3) !important;
}

.tw-text-neutral-700\/35 {
  color: rgb(64 64 64 / 0.35) !important;
}

.tw-text-neutral-700\/40 {
  color: rgb(64 64 64 / 0.4) !important;
}

.tw-text-neutral-700\/45 {
  color: rgb(64 64 64 / 0.45) !important;
}

.tw-text-neutral-700\/5 {
  color: rgb(64 64 64 / 0.05) !important;
}

.tw-text-neutral-700\/50 {
  color: rgb(64 64 64 / 0.5) !important;
}

.tw-text-neutral-700\/55 {
  color: rgb(64 64 64 / 0.55) !important;
}

.tw-text-neutral-700\/60 {
  color: rgb(64 64 64 / 0.6) !important;
}

.tw-text-neutral-700\/65 {
  color: rgb(64 64 64 / 0.65) !important;
}

.tw-text-neutral-700\/70 {
  color: rgb(64 64 64 / 0.7) !important;
}

.tw-text-neutral-700\/75 {
  color: rgb(64 64 64 / 0.75) !important;
}

.tw-text-neutral-700\/80 {
  color: rgb(64 64 64 / 0.8) !important;
}

.tw-text-neutral-700\/85 {
  color: rgb(64 64 64 / 0.85) !important;
}

.tw-text-neutral-700\/90 {
  color: rgb(64 64 64 / 0.9) !important;
}

.tw-text-neutral-700\/95 {
  color: rgb(64 64 64 / 0.95) !important;
}

.tw-text-neutral-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(38 38 38 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-800\/0 {
  color: rgb(38 38 38 / 0) !important;
}

.tw-text-neutral-800\/10 {
  color: rgb(38 38 38 / 0.1) !important;
}

.tw-text-neutral-800\/100 {
  color: rgb(38 38 38 / 1) !important;
}

.tw-text-neutral-800\/15 {
  color: rgb(38 38 38 / 0.15) !important;
}

.tw-text-neutral-800\/20 {
  color: rgb(38 38 38 / 0.2) !important;
}

.tw-text-neutral-800\/25 {
  color: rgb(38 38 38 / 0.25) !important;
}

.tw-text-neutral-800\/30 {
  color: rgb(38 38 38 / 0.3) !important;
}

.tw-text-neutral-800\/35 {
  color: rgb(38 38 38 / 0.35) !important;
}

.tw-text-neutral-800\/40 {
  color: rgb(38 38 38 / 0.4) !important;
}

.tw-text-neutral-800\/45 {
  color: rgb(38 38 38 / 0.45) !important;
}

.tw-text-neutral-800\/5 {
  color: rgb(38 38 38 / 0.05) !important;
}

.tw-text-neutral-800\/50 {
  color: rgb(38 38 38 / 0.5) !important;
}

.tw-text-neutral-800\/55 {
  color: rgb(38 38 38 / 0.55) !important;
}

.tw-text-neutral-800\/60 {
  color: rgb(38 38 38 / 0.6) !important;
}

.tw-text-neutral-800\/65 {
  color: rgb(38 38 38 / 0.65) !important;
}

.tw-text-neutral-800\/70 {
  color: rgb(38 38 38 / 0.7) !important;
}

.tw-text-neutral-800\/75 {
  color: rgb(38 38 38 / 0.75) !important;
}

.tw-text-neutral-800\/80 {
  color: rgb(38 38 38 / 0.8) !important;
}

.tw-text-neutral-800\/85 {
  color: rgb(38 38 38 / 0.85) !important;
}

.tw-text-neutral-800\/90 {
  color: rgb(38 38 38 / 0.9) !important;
}

.tw-text-neutral-800\/95 {
  color: rgb(38 38 38 / 0.95) !important;
}

.tw-text-neutral-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(23 23 23 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-900\/0 {
  color: rgb(23 23 23 / 0) !important;
}

.tw-text-neutral-900\/10 {
  color: rgb(23 23 23 / 0.1) !important;
}

.tw-text-neutral-900\/100 {
  color: rgb(23 23 23 / 1) !important;
}

.tw-text-neutral-900\/15 {
  color: rgb(23 23 23 / 0.15) !important;
}

.tw-text-neutral-900\/20 {
  color: rgb(23 23 23 / 0.2) !important;
}

.tw-text-neutral-900\/25 {
  color: rgb(23 23 23 / 0.25) !important;
}

.tw-text-neutral-900\/30 {
  color: rgb(23 23 23 / 0.3) !important;
}

.tw-text-neutral-900\/35 {
  color: rgb(23 23 23 / 0.35) !important;
}

.tw-text-neutral-900\/40 {
  color: rgb(23 23 23 / 0.4) !important;
}

.tw-text-neutral-900\/45 {
  color: rgb(23 23 23 / 0.45) !important;
}

.tw-text-neutral-900\/5 {
  color: rgb(23 23 23 / 0.05) !important;
}

.tw-text-neutral-900\/50 {
  color: rgb(23 23 23 / 0.5) !important;
}

.tw-text-neutral-900\/55 {
  color: rgb(23 23 23 / 0.55) !important;
}

.tw-text-neutral-900\/60 {
  color: rgb(23 23 23 / 0.6) !important;
}

.tw-text-neutral-900\/65 {
  color: rgb(23 23 23 / 0.65) !important;
}

.tw-text-neutral-900\/70 {
  color: rgb(23 23 23 / 0.7) !important;
}

.tw-text-neutral-900\/75 {
  color: rgb(23 23 23 / 0.75) !important;
}

.tw-text-neutral-900\/80 {
  color: rgb(23 23 23 / 0.8) !important;
}

.tw-text-neutral-900\/85 {
  color: rgb(23 23 23 / 0.85) !important;
}

.tw-text-neutral-900\/90 {
  color: rgb(23 23 23 / 0.9) !important;
}

.tw-text-neutral-900\/95 {
  color: rgb(23 23 23 / 0.95) !important;
}

.tw-text-neutral-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(10 10 10 / var(--tw-text-opacity)) !important;
}

.tw-text-neutral-950\/0 {
  color: rgb(10 10 10 / 0) !important;
}

.tw-text-neutral-950\/10 {
  color: rgb(10 10 10 / 0.1) !important;
}

.tw-text-neutral-950\/100 {
  color: rgb(10 10 10 / 1) !important;
}

.tw-text-neutral-950\/15 {
  color: rgb(10 10 10 / 0.15) !important;
}

.tw-text-neutral-950\/20 {
  color: rgb(10 10 10 / 0.2) !important;
}

.tw-text-neutral-950\/25 {
  color: rgb(10 10 10 / 0.25) !important;
}

.tw-text-neutral-950\/30 {
  color: rgb(10 10 10 / 0.3) !important;
}

.tw-text-neutral-950\/35 {
  color: rgb(10 10 10 / 0.35) !important;
}

.tw-text-neutral-950\/40 {
  color: rgb(10 10 10 / 0.4) !important;
}

.tw-text-neutral-950\/45 {
  color: rgb(10 10 10 / 0.45) !important;
}

.tw-text-neutral-950\/5 {
  color: rgb(10 10 10 / 0.05) !important;
}

.tw-text-neutral-950\/50 {
  color: rgb(10 10 10 / 0.5) !important;
}

.tw-text-neutral-950\/55 {
  color: rgb(10 10 10 / 0.55) !important;
}

.tw-text-neutral-950\/60 {
  color: rgb(10 10 10 / 0.6) !important;
}

.tw-text-neutral-950\/65 {
  color: rgb(10 10 10 / 0.65) !important;
}

.tw-text-neutral-950\/70 {
  color: rgb(10 10 10 / 0.7) !important;
}

.tw-text-neutral-950\/75 {
  color: rgb(10 10 10 / 0.75) !important;
}

.tw-text-neutral-950\/80 {
  color: rgb(10 10 10 / 0.8) !important;
}

.tw-text-neutral-950\/85 {
  color: rgb(10 10 10 / 0.85) !important;
}

.tw-text-neutral-950\/90 {
  color: rgb(10 10 10 / 0.9) !important;
}

.tw-text-neutral-950\/95 {
  color: rgb(10 10 10 / 0.95) !important;
}

.tw-text-orange-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 237 213 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-100\/0 {
  color: rgb(255 237 213 / 0) !important;
}

.tw-text-orange-100\/10 {
  color: rgb(255 237 213 / 0.1) !important;
}

.tw-text-orange-100\/100 {
  color: rgb(255 237 213 / 1) !important;
}

.tw-text-orange-100\/15 {
  color: rgb(255 237 213 / 0.15) !important;
}

.tw-text-orange-100\/20 {
  color: rgb(255 237 213 / 0.2) !important;
}

.tw-text-orange-100\/25 {
  color: rgb(255 237 213 / 0.25) !important;
}

.tw-text-orange-100\/30 {
  color: rgb(255 237 213 / 0.3) !important;
}

.tw-text-orange-100\/35 {
  color: rgb(255 237 213 / 0.35) !important;
}

.tw-text-orange-100\/40 {
  color: rgb(255 237 213 / 0.4) !important;
}

.tw-text-orange-100\/45 {
  color: rgb(255 237 213 / 0.45) !important;
}

.tw-text-orange-100\/5 {
  color: rgb(255 237 213 / 0.05) !important;
}

.tw-text-orange-100\/50 {
  color: rgb(255 237 213 / 0.5) !important;
}

.tw-text-orange-100\/55 {
  color: rgb(255 237 213 / 0.55) !important;
}

.tw-text-orange-100\/60 {
  color: rgb(255 237 213 / 0.6) !important;
}

.tw-text-orange-100\/65 {
  color: rgb(255 237 213 / 0.65) !important;
}

.tw-text-orange-100\/70 {
  color: rgb(255 237 213 / 0.7) !important;
}

.tw-text-orange-100\/75 {
  color: rgb(255 237 213 / 0.75) !important;
}

.tw-text-orange-100\/80 {
  color: rgb(255 237 213 / 0.8) !important;
}

.tw-text-orange-100\/85 {
  color: rgb(255 237 213 / 0.85) !important;
}

.tw-text-orange-100\/90 {
  color: rgb(255 237 213 / 0.9) !important;
}

.tw-text-orange-100\/95 {
  color: rgb(255 237 213 / 0.95) !important;
}

.tw-text-orange-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 215 170 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-200\/0 {
  color: rgb(254 215 170 / 0) !important;
}

.tw-text-orange-200\/10 {
  color: rgb(254 215 170 / 0.1) !important;
}

.tw-text-orange-200\/100 {
  color: rgb(254 215 170 / 1) !important;
}

.tw-text-orange-200\/15 {
  color: rgb(254 215 170 / 0.15) !important;
}

.tw-text-orange-200\/20 {
  color: rgb(254 215 170 / 0.2) !important;
}

.tw-text-orange-200\/25 {
  color: rgb(254 215 170 / 0.25) !important;
}

.tw-text-orange-200\/30 {
  color: rgb(254 215 170 / 0.3) !important;
}

.tw-text-orange-200\/35 {
  color: rgb(254 215 170 / 0.35) !important;
}

.tw-text-orange-200\/40 {
  color: rgb(254 215 170 / 0.4) !important;
}

.tw-text-orange-200\/45 {
  color: rgb(254 215 170 / 0.45) !important;
}

.tw-text-orange-200\/5 {
  color: rgb(254 215 170 / 0.05) !important;
}

.tw-text-orange-200\/50 {
  color: rgb(254 215 170 / 0.5) !important;
}

.tw-text-orange-200\/55 {
  color: rgb(254 215 170 / 0.55) !important;
}

.tw-text-orange-200\/60 {
  color: rgb(254 215 170 / 0.6) !important;
}

.tw-text-orange-200\/65 {
  color: rgb(254 215 170 / 0.65) !important;
}

.tw-text-orange-200\/70 {
  color: rgb(254 215 170 / 0.7) !important;
}

.tw-text-orange-200\/75 {
  color: rgb(254 215 170 / 0.75) !important;
}

.tw-text-orange-200\/80 {
  color: rgb(254 215 170 / 0.8) !important;
}

.tw-text-orange-200\/85 {
  color: rgb(254 215 170 / 0.85) !important;
}

.tw-text-orange-200\/90 {
  color: rgb(254 215 170 / 0.9) !important;
}

.tw-text-orange-200\/95 {
  color: rgb(254 215 170 / 0.95) !important;
}

.tw-text-orange-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 186 116 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-300\/0 {
  color: rgb(253 186 116 / 0) !important;
}

.tw-text-orange-300\/10 {
  color: rgb(253 186 116 / 0.1) !important;
}

.tw-text-orange-300\/100 {
  color: rgb(253 186 116 / 1) !important;
}

.tw-text-orange-300\/15 {
  color: rgb(253 186 116 / 0.15) !important;
}

.tw-text-orange-300\/20 {
  color: rgb(253 186 116 / 0.2) !important;
}

.tw-text-orange-300\/25 {
  color: rgb(253 186 116 / 0.25) !important;
}

.tw-text-orange-300\/30 {
  color: rgb(253 186 116 / 0.3) !important;
}

.tw-text-orange-300\/35 {
  color: rgb(253 186 116 / 0.35) !important;
}

.tw-text-orange-300\/40 {
  color: rgb(253 186 116 / 0.4) !important;
}

.tw-text-orange-300\/45 {
  color: rgb(253 186 116 / 0.45) !important;
}

.tw-text-orange-300\/5 {
  color: rgb(253 186 116 / 0.05) !important;
}

.tw-text-orange-300\/50 {
  color: rgb(253 186 116 / 0.5) !important;
}

.tw-text-orange-300\/55 {
  color: rgb(253 186 116 / 0.55) !important;
}

.tw-text-orange-300\/60 {
  color: rgb(253 186 116 / 0.6) !important;
}

.tw-text-orange-300\/65 {
  color: rgb(253 186 116 / 0.65) !important;
}

.tw-text-orange-300\/70 {
  color: rgb(253 186 116 / 0.7) !important;
}

.tw-text-orange-300\/75 {
  color: rgb(253 186 116 / 0.75) !important;
}

.tw-text-orange-300\/80 {
  color: rgb(253 186 116 / 0.8) !important;
}

.tw-text-orange-300\/85 {
  color: rgb(253 186 116 / 0.85) !important;
}

.tw-text-orange-300\/90 {
  color: rgb(253 186 116 / 0.9) !important;
}

.tw-text-orange-300\/95 {
  color: rgb(253 186 116 / 0.95) !important;
}

.tw-text-orange-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 146 60 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-400\/0 {
  color: rgb(251 146 60 / 0) !important;
}

.tw-text-orange-400\/10 {
  color: rgb(251 146 60 / 0.1) !important;
}

.tw-text-orange-400\/100 {
  color: rgb(251 146 60 / 1) !important;
}

.tw-text-orange-400\/15 {
  color: rgb(251 146 60 / 0.15) !important;
}

.tw-text-orange-400\/20 {
  color: rgb(251 146 60 / 0.2) !important;
}

.tw-text-orange-400\/25 {
  color: rgb(251 146 60 / 0.25) !important;
}

.tw-text-orange-400\/30 {
  color: rgb(251 146 60 / 0.3) !important;
}

.tw-text-orange-400\/35 {
  color: rgb(251 146 60 / 0.35) !important;
}

.tw-text-orange-400\/40 {
  color: rgb(251 146 60 / 0.4) !important;
}

.tw-text-orange-400\/45 {
  color: rgb(251 146 60 / 0.45) !important;
}

.tw-text-orange-400\/5 {
  color: rgb(251 146 60 / 0.05) !important;
}

.tw-text-orange-400\/50 {
  color: rgb(251 146 60 / 0.5) !important;
}

.tw-text-orange-400\/55 {
  color: rgb(251 146 60 / 0.55) !important;
}

.tw-text-orange-400\/60 {
  color: rgb(251 146 60 / 0.6) !important;
}

.tw-text-orange-400\/65 {
  color: rgb(251 146 60 / 0.65) !important;
}

.tw-text-orange-400\/70 {
  color: rgb(251 146 60 / 0.7) !important;
}

.tw-text-orange-400\/75 {
  color: rgb(251 146 60 / 0.75) !important;
}

.tw-text-orange-400\/80 {
  color: rgb(251 146 60 / 0.8) !important;
}

.tw-text-orange-400\/85 {
  color: rgb(251 146 60 / 0.85) !important;
}

.tw-text-orange-400\/90 {
  color: rgb(251 146 60 / 0.9) !important;
}

.tw-text-orange-400\/95 {
  color: rgb(251 146 60 / 0.95) !important;
}

.tw-text-orange-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 247 237 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-50\/0 {
  color: rgb(255 247 237 / 0) !important;
}

.tw-text-orange-50\/10 {
  color: rgb(255 247 237 / 0.1) !important;
}

.tw-text-orange-50\/100 {
  color: rgb(255 247 237 / 1) !important;
}

.tw-text-orange-50\/15 {
  color: rgb(255 247 237 / 0.15) !important;
}

.tw-text-orange-50\/20 {
  color: rgb(255 247 237 / 0.2) !important;
}

.tw-text-orange-50\/25 {
  color: rgb(255 247 237 / 0.25) !important;
}

.tw-text-orange-50\/30 {
  color: rgb(255 247 237 / 0.3) !important;
}

.tw-text-orange-50\/35 {
  color: rgb(255 247 237 / 0.35) !important;
}

.tw-text-orange-50\/40 {
  color: rgb(255 247 237 / 0.4) !important;
}

.tw-text-orange-50\/45 {
  color: rgb(255 247 237 / 0.45) !important;
}

.tw-text-orange-50\/5 {
  color: rgb(255 247 237 / 0.05) !important;
}

.tw-text-orange-50\/50 {
  color: rgb(255 247 237 / 0.5) !important;
}

.tw-text-orange-50\/55 {
  color: rgb(255 247 237 / 0.55) !important;
}

.tw-text-orange-50\/60 {
  color: rgb(255 247 237 / 0.6) !important;
}

.tw-text-orange-50\/65 {
  color: rgb(255 247 237 / 0.65) !important;
}

.tw-text-orange-50\/70 {
  color: rgb(255 247 237 / 0.7) !important;
}

.tw-text-orange-50\/75 {
  color: rgb(255 247 237 / 0.75) !important;
}

.tw-text-orange-50\/80 {
  color: rgb(255 247 237 / 0.8) !important;
}

.tw-text-orange-50\/85 {
  color: rgb(255 247 237 / 0.85) !important;
}

.tw-text-orange-50\/90 {
  color: rgb(255 247 237 / 0.9) !important;
}

.tw-text-orange-50\/95 {
  color: rgb(255 247 237 / 0.95) !important;
}

.tw-text-orange-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 115 22 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-500\/0 {
  color: rgb(249 115 22 / 0) !important;
}

.tw-text-orange-500\/10 {
  color: rgb(249 115 22 / 0.1) !important;
}

.tw-text-orange-500\/100 {
  color: rgb(249 115 22 / 1) !important;
}

.tw-text-orange-500\/15 {
  color: rgb(249 115 22 / 0.15) !important;
}

.tw-text-orange-500\/20 {
  color: rgb(249 115 22 / 0.2) !important;
}

.tw-text-orange-500\/25 {
  color: rgb(249 115 22 / 0.25) !important;
}

.tw-text-orange-500\/30 {
  color: rgb(249 115 22 / 0.3) !important;
}

.tw-text-orange-500\/35 {
  color: rgb(249 115 22 / 0.35) !important;
}

.tw-text-orange-500\/40 {
  color: rgb(249 115 22 / 0.4) !important;
}

.tw-text-orange-500\/45 {
  color: rgb(249 115 22 / 0.45) !important;
}

.tw-text-orange-500\/5 {
  color: rgb(249 115 22 / 0.05) !important;
}

.tw-text-orange-500\/50 {
  color: rgb(249 115 22 / 0.5) !important;
}

.tw-text-orange-500\/55 {
  color: rgb(249 115 22 / 0.55) !important;
}

.tw-text-orange-500\/60 {
  color: rgb(249 115 22 / 0.6) !important;
}

.tw-text-orange-500\/65 {
  color: rgb(249 115 22 / 0.65) !important;
}

.tw-text-orange-500\/70 {
  color: rgb(249 115 22 / 0.7) !important;
}

.tw-text-orange-500\/75 {
  color: rgb(249 115 22 / 0.75) !important;
}

.tw-text-orange-500\/80 {
  color: rgb(249 115 22 / 0.8) !important;
}

.tw-text-orange-500\/85 {
  color: rgb(249 115 22 / 0.85) !important;
}

.tw-text-orange-500\/90 {
  color: rgb(249 115 22 / 0.9) !important;
}

.tw-text-orange-500\/95 {
  color: rgb(249 115 22 / 0.95) !important;
}

.tw-text-orange-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 88 12 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-600\/0 {
  color: rgb(234 88 12 / 0) !important;
}

.tw-text-orange-600\/10 {
  color: rgb(234 88 12 / 0.1) !important;
}

.tw-text-orange-600\/100 {
  color: rgb(234 88 12 / 1) !important;
}

.tw-text-orange-600\/15 {
  color: rgb(234 88 12 / 0.15) !important;
}

.tw-text-orange-600\/20 {
  color: rgb(234 88 12 / 0.2) !important;
}

.tw-text-orange-600\/25 {
  color: rgb(234 88 12 / 0.25) !important;
}

.tw-text-orange-600\/30 {
  color: rgb(234 88 12 / 0.3) !important;
}

.tw-text-orange-600\/35 {
  color: rgb(234 88 12 / 0.35) !important;
}

.tw-text-orange-600\/40 {
  color: rgb(234 88 12 / 0.4) !important;
}

.tw-text-orange-600\/45 {
  color: rgb(234 88 12 / 0.45) !important;
}

.tw-text-orange-600\/5 {
  color: rgb(234 88 12 / 0.05) !important;
}

.tw-text-orange-600\/50 {
  color: rgb(234 88 12 / 0.5) !important;
}

.tw-text-orange-600\/55 {
  color: rgb(234 88 12 / 0.55) !important;
}

.tw-text-orange-600\/60 {
  color: rgb(234 88 12 / 0.6) !important;
}

.tw-text-orange-600\/65 {
  color: rgb(234 88 12 / 0.65) !important;
}

.tw-text-orange-600\/70 {
  color: rgb(234 88 12 / 0.7) !important;
}

.tw-text-orange-600\/75 {
  color: rgb(234 88 12 / 0.75) !important;
}

.tw-text-orange-600\/80 {
  color: rgb(234 88 12 / 0.8) !important;
}

.tw-text-orange-600\/85 {
  color: rgb(234 88 12 / 0.85) !important;
}

.tw-text-orange-600\/90 {
  color: rgb(234 88 12 / 0.9) !important;
}

.tw-text-orange-600\/95 {
  color: rgb(234 88 12 / 0.95) !important;
}

.tw-text-orange-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(194 65 12 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-700\/0 {
  color: rgb(194 65 12 / 0) !important;
}

.tw-text-orange-700\/10 {
  color: rgb(194 65 12 / 0.1) !important;
}

.tw-text-orange-700\/100 {
  color: rgb(194 65 12 / 1) !important;
}

.tw-text-orange-700\/15 {
  color: rgb(194 65 12 / 0.15) !important;
}

.tw-text-orange-700\/20 {
  color: rgb(194 65 12 / 0.2) !important;
}

.tw-text-orange-700\/25 {
  color: rgb(194 65 12 / 0.25) !important;
}

.tw-text-orange-700\/30 {
  color: rgb(194 65 12 / 0.3) !important;
}

.tw-text-orange-700\/35 {
  color: rgb(194 65 12 / 0.35) !important;
}

.tw-text-orange-700\/40 {
  color: rgb(194 65 12 / 0.4) !important;
}

.tw-text-orange-700\/45 {
  color: rgb(194 65 12 / 0.45) !important;
}

.tw-text-orange-700\/5 {
  color: rgb(194 65 12 / 0.05) !important;
}

.tw-text-orange-700\/50 {
  color: rgb(194 65 12 / 0.5) !important;
}

.tw-text-orange-700\/55 {
  color: rgb(194 65 12 / 0.55) !important;
}

.tw-text-orange-700\/60 {
  color: rgb(194 65 12 / 0.6) !important;
}

.tw-text-orange-700\/65 {
  color: rgb(194 65 12 / 0.65) !important;
}

.tw-text-orange-700\/70 {
  color: rgb(194 65 12 / 0.7) !important;
}

.tw-text-orange-700\/75 {
  color: rgb(194 65 12 / 0.75) !important;
}

.tw-text-orange-700\/80 {
  color: rgb(194 65 12 / 0.8) !important;
}

.tw-text-orange-700\/85 {
  color: rgb(194 65 12 / 0.85) !important;
}

.tw-text-orange-700\/90 {
  color: rgb(194 65 12 / 0.9) !important;
}

.tw-text-orange-700\/95 {
  color: rgb(194 65 12 / 0.95) !important;
}

.tw-text-orange-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(154 52 18 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-800\/0 {
  color: rgb(154 52 18 / 0) !important;
}

.tw-text-orange-800\/10 {
  color: rgb(154 52 18 / 0.1) !important;
}

.tw-text-orange-800\/100 {
  color: rgb(154 52 18 / 1) !important;
}

.tw-text-orange-800\/15 {
  color: rgb(154 52 18 / 0.15) !important;
}

.tw-text-orange-800\/20 {
  color: rgb(154 52 18 / 0.2) !important;
}

.tw-text-orange-800\/25 {
  color: rgb(154 52 18 / 0.25) !important;
}

.tw-text-orange-800\/30 {
  color: rgb(154 52 18 / 0.3) !important;
}

.tw-text-orange-800\/35 {
  color: rgb(154 52 18 / 0.35) !important;
}

.tw-text-orange-800\/40 {
  color: rgb(154 52 18 / 0.4) !important;
}

.tw-text-orange-800\/45 {
  color: rgb(154 52 18 / 0.45) !important;
}

.tw-text-orange-800\/5 {
  color: rgb(154 52 18 / 0.05) !important;
}

.tw-text-orange-800\/50 {
  color: rgb(154 52 18 / 0.5) !important;
}

.tw-text-orange-800\/55 {
  color: rgb(154 52 18 / 0.55) !important;
}

.tw-text-orange-800\/60 {
  color: rgb(154 52 18 / 0.6) !important;
}

.tw-text-orange-800\/65 {
  color: rgb(154 52 18 / 0.65) !important;
}

.tw-text-orange-800\/70 {
  color: rgb(154 52 18 / 0.7) !important;
}

.tw-text-orange-800\/75 {
  color: rgb(154 52 18 / 0.75) !important;
}

.tw-text-orange-800\/80 {
  color: rgb(154 52 18 / 0.8) !important;
}

.tw-text-orange-800\/85 {
  color: rgb(154 52 18 / 0.85) !important;
}

.tw-text-orange-800\/90 {
  color: rgb(154 52 18 / 0.9) !important;
}

.tw-text-orange-800\/95 {
  color: rgb(154 52 18 / 0.95) !important;
}

.tw-text-orange-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(124 45 18 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-900\/0 {
  color: rgb(124 45 18 / 0) !important;
}

.tw-text-orange-900\/10 {
  color: rgb(124 45 18 / 0.1) !important;
}

.tw-text-orange-900\/100 {
  color: rgb(124 45 18 / 1) !important;
}

.tw-text-orange-900\/15 {
  color: rgb(124 45 18 / 0.15) !important;
}

.tw-text-orange-900\/20 {
  color: rgb(124 45 18 / 0.2) !important;
}

.tw-text-orange-900\/25 {
  color: rgb(124 45 18 / 0.25) !important;
}

.tw-text-orange-900\/30 {
  color: rgb(124 45 18 / 0.3) !important;
}

.tw-text-orange-900\/35 {
  color: rgb(124 45 18 / 0.35) !important;
}

.tw-text-orange-900\/40 {
  color: rgb(124 45 18 / 0.4) !important;
}

.tw-text-orange-900\/45 {
  color: rgb(124 45 18 / 0.45) !important;
}

.tw-text-orange-900\/5 {
  color: rgb(124 45 18 / 0.05) !important;
}

.tw-text-orange-900\/50 {
  color: rgb(124 45 18 / 0.5) !important;
}

.tw-text-orange-900\/55 {
  color: rgb(124 45 18 / 0.55) !important;
}

.tw-text-orange-900\/60 {
  color: rgb(124 45 18 / 0.6) !important;
}

.tw-text-orange-900\/65 {
  color: rgb(124 45 18 / 0.65) !important;
}

.tw-text-orange-900\/70 {
  color: rgb(124 45 18 / 0.7) !important;
}

.tw-text-orange-900\/75 {
  color: rgb(124 45 18 / 0.75) !important;
}

.tw-text-orange-900\/80 {
  color: rgb(124 45 18 / 0.8) !important;
}

.tw-text-orange-900\/85 {
  color: rgb(124 45 18 / 0.85) !important;
}

.tw-text-orange-900\/90 {
  color: rgb(124 45 18 / 0.9) !important;
}

.tw-text-orange-900\/95 {
  color: rgb(124 45 18 / 0.95) !important;
}

.tw-text-orange-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(67 20 7 / var(--tw-text-opacity)) !important;
}

.tw-text-orange-950\/0 {
  color: rgb(67 20 7 / 0) !important;
}

.tw-text-orange-950\/10 {
  color: rgb(67 20 7 / 0.1) !important;
}

.tw-text-orange-950\/100 {
  color: rgb(67 20 7 / 1) !important;
}

.tw-text-orange-950\/15 {
  color: rgb(67 20 7 / 0.15) !important;
}

.tw-text-orange-950\/20 {
  color: rgb(67 20 7 / 0.2) !important;
}

.tw-text-orange-950\/25 {
  color: rgb(67 20 7 / 0.25) !important;
}

.tw-text-orange-950\/30 {
  color: rgb(67 20 7 / 0.3) !important;
}

.tw-text-orange-950\/35 {
  color: rgb(67 20 7 / 0.35) !important;
}

.tw-text-orange-950\/40 {
  color: rgb(67 20 7 / 0.4) !important;
}

.tw-text-orange-950\/45 {
  color: rgb(67 20 7 / 0.45) !important;
}

.tw-text-orange-950\/5 {
  color: rgb(67 20 7 / 0.05) !important;
}

.tw-text-orange-950\/50 {
  color: rgb(67 20 7 / 0.5) !important;
}

.tw-text-orange-950\/55 {
  color: rgb(67 20 7 / 0.55) !important;
}

.tw-text-orange-950\/60 {
  color: rgb(67 20 7 / 0.6) !important;
}

.tw-text-orange-950\/65 {
  color: rgb(67 20 7 / 0.65) !important;
}

.tw-text-orange-950\/70 {
  color: rgb(67 20 7 / 0.7) !important;
}

.tw-text-orange-950\/75 {
  color: rgb(67 20 7 / 0.75) !important;
}

.tw-text-orange-950\/80 {
  color: rgb(67 20 7 / 0.8) !important;
}

.tw-text-orange-950\/85 {
  color: rgb(67 20 7 / 0.85) !important;
}

.tw-text-orange-950\/90 {
  color: rgb(67 20 7 / 0.9) !important;
}

.tw-text-orange-950\/95 {
  color: rgb(67 20 7 / 0.95) !important;
}

.tw-text-pink-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(252 231 243 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-100\/0 {
  color: rgb(252 231 243 / 0) !important;
}

.tw-text-pink-100\/10 {
  color: rgb(252 231 243 / 0.1) !important;
}

.tw-text-pink-100\/100 {
  color: rgb(252 231 243 / 1) !important;
}

.tw-text-pink-100\/15 {
  color: rgb(252 231 243 / 0.15) !important;
}

.tw-text-pink-100\/20 {
  color: rgb(252 231 243 / 0.2) !important;
}

.tw-text-pink-100\/25 {
  color: rgb(252 231 243 / 0.25) !important;
}

.tw-text-pink-100\/30 {
  color: rgb(252 231 243 / 0.3) !important;
}

.tw-text-pink-100\/35 {
  color: rgb(252 231 243 / 0.35) !important;
}

.tw-text-pink-100\/40 {
  color: rgb(252 231 243 / 0.4) !important;
}

.tw-text-pink-100\/45 {
  color: rgb(252 231 243 / 0.45) !important;
}

.tw-text-pink-100\/5 {
  color: rgb(252 231 243 / 0.05) !important;
}

.tw-text-pink-100\/50 {
  color: rgb(252 231 243 / 0.5) !important;
}

.tw-text-pink-100\/55 {
  color: rgb(252 231 243 / 0.55) !important;
}

.tw-text-pink-100\/60 {
  color: rgb(252 231 243 / 0.6) !important;
}

.tw-text-pink-100\/65 {
  color: rgb(252 231 243 / 0.65) !important;
}

.tw-text-pink-100\/70 {
  color: rgb(252 231 243 / 0.7) !important;
}

.tw-text-pink-100\/75 {
  color: rgb(252 231 243 / 0.75) !important;
}

.tw-text-pink-100\/80 {
  color: rgb(252 231 243 / 0.8) !important;
}

.tw-text-pink-100\/85 {
  color: rgb(252 231 243 / 0.85) !important;
}

.tw-text-pink-100\/90 {
  color: rgb(252 231 243 / 0.9) !important;
}

.tw-text-pink-100\/95 {
  color: rgb(252 231 243 / 0.95) !important;
}

.tw-text-pink-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 207 232 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-200\/0 {
  color: rgb(251 207 232 / 0) !important;
}

.tw-text-pink-200\/10 {
  color: rgb(251 207 232 / 0.1) !important;
}

.tw-text-pink-200\/100 {
  color: rgb(251 207 232 / 1) !important;
}

.tw-text-pink-200\/15 {
  color: rgb(251 207 232 / 0.15) !important;
}

.tw-text-pink-200\/20 {
  color: rgb(251 207 232 / 0.2) !important;
}

.tw-text-pink-200\/25 {
  color: rgb(251 207 232 / 0.25) !important;
}

.tw-text-pink-200\/30 {
  color: rgb(251 207 232 / 0.3) !important;
}

.tw-text-pink-200\/35 {
  color: rgb(251 207 232 / 0.35) !important;
}

.tw-text-pink-200\/40 {
  color: rgb(251 207 232 / 0.4) !important;
}

.tw-text-pink-200\/45 {
  color: rgb(251 207 232 / 0.45) !important;
}

.tw-text-pink-200\/5 {
  color: rgb(251 207 232 / 0.05) !important;
}

.tw-text-pink-200\/50 {
  color: rgb(251 207 232 / 0.5) !important;
}

.tw-text-pink-200\/55 {
  color: rgb(251 207 232 / 0.55) !important;
}

.tw-text-pink-200\/60 {
  color: rgb(251 207 232 / 0.6) !important;
}

.tw-text-pink-200\/65 {
  color: rgb(251 207 232 / 0.65) !important;
}

.tw-text-pink-200\/70 {
  color: rgb(251 207 232 / 0.7) !important;
}

.tw-text-pink-200\/75 {
  color: rgb(251 207 232 / 0.75) !important;
}

.tw-text-pink-200\/80 {
  color: rgb(251 207 232 / 0.8) !important;
}

.tw-text-pink-200\/85 {
  color: rgb(251 207 232 / 0.85) !important;
}

.tw-text-pink-200\/90 {
  color: rgb(251 207 232 / 0.9) !important;
}

.tw-text-pink-200\/95 {
  color: rgb(251 207 232 / 0.95) !important;
}

.tw-text-pink-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(249 168 212 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-300\/0 {
  color: rgb(249 168 212 / 0) !important;
}

.tw-text-pink-300\/10 {
  color: rgb(249 168 212 / 0.1) !important;
}

.tw-text-pink-300\/100 {
  color: rgb(249 168 212 / 1) !important;
}

.tw-text-pink-300\/15 {
  color: rgb(249 168 212 / 0.15) !important;
}

.tw-text-pink-300\/20 {
  color: rgb(249 168 212 / 0.2) !important;
}

.tw-text-pink-300\/25 {
  color: rgb(249 168 212 / 0.25) !important;
}

.tw-text-pink-300\/30 {
  color: rgb(249 168 212 / 0.3) !important;
}

.tw-text-pink-300\/35 {
  color: rgb(249 168 212 / 0.35) !important;
}

.tw-text-pink-300\/40 {
  color: rgb(249 168 212 / 0.4) !important;
}

.tw-text-pink-300\/45 {
  color: rgb(249 168 212 / 0.45) !important;
}

.tw-text-pink-300\/5 {
  color: rgb(249 168 212 / 0.05) !important;
}

.tw-text-pink-300\/50 {
  color: rgb(249 168 212 / 0.5) !important;
}

.tw-text-pink-300\/55 {
  color: rgb(249 168 212 / 0.55) !important;
}

.tw-text-pink-300\/60 {
  color: rgb(249 168 212 / 0.6) !important;
}

.tw-text-pink-300\/65 {
  color: rgb(249 168 212 / 0.65) !important;
}

.tw-text-pink-300\/70 {
  color: rgb(249 168 212 / 0.7) !important;
}

.tw-text-pink-300\/75 {
  color: rgb(249 168 212 / 0.75) !important;
}

.tw-text-pink-300\/80 {
  color: rgb(249 168 212 / 0.8) !important;
}

.tw-text-pink-300\/85 {
  color: rgb(249 168 212 / 0.85) !important;
}

.tw-text-pink-300\/90 {
  color: rgb(249 168 212 / 0.9) !important;
}

.tw-text-pink-300\/95 {
  color: rgb(249 168 212 / 0.95) !important;
}

.tw-text-pink-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(244 114 182 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-400\/0 {
  color: rgb(244 114 182 / 0) !important;
}

.tw-text-pink-400\/10 {
  color: rgb(244 114 182 / 0.1) !important;
}

.tw-text-pink-400\/100 {
  color: rgb(244 114 182 / 1) !important;
}

.tw-text-pink-400\/15 {
  color: rgb(244 114 182 / 0.15) !important;
}

.tw-text-pink-400\/20 {
  color: rgb(244 114 182 / 0.2) !important;
}

.tw-text-pink-400\/25 {
  color: rgb(244 114 182 / 0.25) !important;
}

.tw-text-pink-400\/30 {
  color: rgb(244 114 182 / 0.3) !important;
}

.tw-text-pink-400\/35 {
  color: rgb(244 114 182 / 0.35) !important;
}

.tw-text-pink-400\/40 {
  color: rgb(244 114 182 / 0.4) !important;
}

.tw-text-pink-400\/45 {
  color: rgb(244 114 182 / 0.45) !important;
}

.tw-text-pink-400\/5 {
  color: rgb(244 114 182 / 0.05) !important;
}

.tw-text-pink-400\/50 {
  color: rgb(244 114 182 / 0.5) !important;
}

.tw-text-pink-400\/55 {
  color: rgb(244 114 182 / 0.55) !important;
}

.tw-text-pink-400\/60 {
  color: rgb(244 114 182 / 0.6) !important;
}

.tw-text-pink-400\/65 {
  color: rgb(244 114 182 / 0.65) !important;
}

.tw-text-pink-400\/70 {
  color: rgb(244 114 182 / 0.7) !important;
}

.tw-text-pink-400\/75 {
  color: rgb(244 114 182 / 0.75) !important;
}

.tw-text-pink-400\/80 {
  color: rgb(244 114 182 / 0.8) !important;
}

.tw-text-pink-400\/85 {
  color: rgb(244 114 182 / 0.85) !important;
}

.tw-text-pink-400\/90 {
  color: rgb(244 114 182 / 0.9) !important;
}

.tw-text-pink-400\/95 {
  color: rgb(244 114 182 / 0.95) !important;
}

.tw-text-pink-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 242 248 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-50\/0 {
  color: rgb(253 242 248 / 0) !important;
}

.tw-text-pink-50\/10 {
  color: rgb(253 242 248 / 0.1) !important;
}

.tw-text-pink-50\/100 {
  color: rgb(253 242 248 / 1) !important;
}

.tw-text-pink-50\/15 {
  color: rgb(253 242 248 / 0.15) !important;
}

.tw-text-pink-50\/20 {
  color: rgb(253 242 248 / 0.2) !important;
}

.tw-text-pink-50\/25 {
  color: rgb(253 242 248 / 0.25) !important;
}

.tw-text-pink-50\/30 {
  color: rgb(253 242 248 / 0.3) !important;
}

.tw-text-pink-50\/35 {
  color: rgb(253 242 248 / 0.35) !important;
}

.tw-text-pink-50\/40 {
  color: rgb(253 242 248 / 0.4) !important;
}

.tw-text-pink-50\/45 {
  color: rgb(253 242 248 / 0.45) !important;
}

.tw-text-pink-50\/5 {
  color: rgb(253 242 248 / 0.05) !important;
}

.tw-text-pink-50\/50 {
  color: rgb(253 242 248 / 0.5) !important;
}

.tw-text-pink-50\/55 {
  color: rgb(253 242 248 / 0.55) !important;
}

.tw-text-pink-50\/60 {
  color: rgb(253 242 248 / 0.6) !important;
}

.tw-text-pink-50\/65 {
  color: rgb(253 242 248 / 0.65) !important;
}

.tw-text-pink-50\/70 {
  color: rgb(253 242 248 / 0.7) !important;
}

.tw-text-pink-50\/75 {
  color: rgb(253 242 248 / 0.75) !important;
}

.tw-text-pink-50\/80 {
  color: rgb(253 242 248 / 0.8) !important;
}

.tw-text-pink-50\/85 {
  color: rgb(253 242 248 / 0.85) !important;
}

.tw-text-pink-50\/90 {
  color: rgb(253 242 248 / 0.9) !important;
}

.tw-text-pink-50\/95 {
  color: rgb(253 242 248 / 0.95) !important;
}

.tw-text-pink-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(236 72 153 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-500\/0 {
  color: rgb(236 72 153 / 0) !important;
}

.tw-text-pink-500\/10 {
  color: rgb(236 72 153 / 0.1) !important;
}

.tw-text-pink-500\/100 {
  color: rgb(236 72 153 / 1) !important;
}

.tw-text-pink-500\/15 {
  color: rgb(236 72 153 / 0.15) !important;
}

.tw-text-pink-500\/20 {
  color: rgb(236 72 153 / 0.2) !important;
}

.tw-text-pink-500\/25 {
  color: rgb(236 72 153 / 0.25) !important;
}

.tw-text-pink-500\/30 {
  color: rgb(236 72 153 / 0.3) !important;
}

.tw-text-pink-500\/35 {
  color: rgb(236 72 153 / 0.35) !important;
}

.tw-text-pink-500\/40 {
  color: rgb(236 72 153 / 0.4) !important;
}

.tw-text-pink-500\/45 {
  color: rgb(236 72 153 / 0.45) !important;
}

.tw-text-pink-500\/5 {
  color: rgb(236 72 153 / 0.05) !important;
}

.tw-text-pink-500\/50 {
  color: rgb(236 72 153 / 0.5) !important;
}

.tw-text-pink-500\/55 {
  color: rgb(236 72 153 / 0.55) !important;
}

.tw-text-pink-500\/60 {
  color: rgb(236 72 153 / 0.6) !important;
}

.tw-text-pink-500\/65 {
  color: rgb(236 72 153 / 0.65) !important;
}

.tw-text-pink-500\/70 {
  color: rgb(236 72 153 / 0.7) !important;
}

.tw-text-pink-500\/75 {
  color: rgb(236 72 153 / 0.75) !important;
}

.tw-text-pink-500\/80 {
  color: rgb(236 72 153 / 0.8) !important;
}

.tw-text-pink-500\/85 {
  color: rgb(236 72 153 / 0.85) !important;
}

.tw-text-pink-500\/90 {
  color: rgb(236 72 153 / 0.9) !important;
}

.tw-text-pink-500\/95 {
  color: rgb(236 72 153 / 0.95) !important;
}

.tw-text-pink-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(219 39 119 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-600\/0 {
  color: rgb(219 39 119 / 0) !important;
}

.tw-text-pink-600\/10 {
  color: rgb(219 39 119 / 0.1) !important;
}

.tw-text-pink-600\/100 {
  color: rgb(219 39 119 / 1) !important;
}

.tw-text-pink-600\/15 {
  color: rgb(219 39 119 / 0.15) !important;
}

.tw-text-pink-600\/20 {
  color: rgb(219 39 119 / 0.2) !important;
}

.tw-text-pink-600\/25 {
  color: rgb(219 39 119 / 0.25) !important;
}

.tw-text-pink-600\/30 {
  color: rgb(219 39 119 / 0.3) !important;
}

.tw-text-pink-600\/35 {
  color: rgb(219 39 119 / 0.35) !important;
}

.tw-text-pink-600\/40 {
  color: rgb(219 39 119 / 0.4) !important;
}

.tw-text-pink-600\/45 {
  color: rgb(219 39 119 / 0.45) !important;
}

.tw-text-pink-600\/5 {
  color: rgb(219 39 119 / 0.05) !important;
}

.tw-text-pink-600\/50 {
  color: rgb(219 39 119 / 0.5) !important;
}

.tw-text-pink-600\/55 {
  color: rgb(219 39 119 / 0.55) !important;
}

.tw-text-pink-600\/60 {
  color: rgb(219 39 119 / 0.6) !important;
}

.tw-text-pink-600\/65 {
  color: rgb(219 39 119 / 0.65) !important;
}

.tw-text-pink-600\/70 {
  color: rgb(219 39 119 / 0.7) !important;
}

.tw-text-pink-600\/75 {
  color: rgb(219 39 119 / 0.75) !important;
}

.tw-text-pink-600\/80 {
  color: rgb(219 39 119 / 0.8) !important;
}

.tw-text-pink-600\/85 {
  color: rgb(219 39 119 / 0.85) !important;
}

.tw-text-pink-600\/90 {
  color: rgb(219 39 119 / 0.9) !important;
}

.tw-text-pink-600\/95 {
  color: rgb(219 39 119 / 0.95) !important;
}

.tw-text-pink-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(190 24 93 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-700\/0 {
  color: rgb(190 24 93 / 0) !important;
}

.tw-text-pink-700\/10 {
  color: rgb(190 24 93 / 0.1) !important;
}

.tw-text-pink-700\/100 {
  color: rgb(190 24 93 / 1) !important;
}

.tw-text-pink-700\/15 {
  color: rgb(190 24 93 / 0.15) !important;
}

.tw-text-pink-700\/20 {
  color: rgb(190 24 93 / 0.2) !important;
}

.tw-text-pink-700\/25 {
  color: rgb(190 24 93 / 0.25) !important;
}

.tw-text-pink-700\/30 {
  color: rgb(190 24 93 / 0.3) !important;
}

.tw-text-pink-700\/35 {
  color: rgb(190 24 93 / 0.35) !important;
}

.tw-text-pink-700\/40 {
  color: rgb(190 24 93 / 0.4) !important;
}

.tw-text-pink-700\/45 {
  color: rgb(190 24 93 / 0.45) !important;
}

.tw-text-pink-700\/5 {
  color: rgb(190 24 93 / 0.05) !important;
}

.tw-text-pink-700\/50 {
  color: rgb(190 24 93 / 0.5) !important;
}

.tw-text-pink-700\/55 {
  color: rgb(190 24 93 / 0.55) !important;
}

.tw-text-pink-700\/60 {
  color: rgb(190 24 93 / 0.6) !important;
}

.tw-text-pink-700\/65 {
  color: rgb(190 24 93 / 0.65) !important;
}

.tw-text-pink-700\/70 {
  color: rgb(190 24 93 / 0.7) !important;
}

.tw-text-pink-700\/75 {
  color: rgb(190 24 93 / 0.75) !important;
}

.tw-text-pink-700\/80 {
  color: rgb(190 24 93 / 0.8) !important;
}

.tw-text-pink-700\/85 {
  color: rgb(190 24 93 / 0.85) !important;
}

.tw-text-pink-700\/90 {
  color: rgb(190 24 93 / 0.9) !important;
}

.tw-text-pink-700\/95 {
  color: rgb(190 24 93 / 0.95) !important;
}

.tw-text-pink-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(157 23 77 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-800\/0 {
  color: rgb(157 23 77 / 0) !important;
}

.tw-text-pink-800\/10 {
  color: rgb(157 23 77 / 0.1) !important;
}

.tw-text-pink-800\/100 {
  color: rgb(157 23 77 / 1) !important;
}

.tw-text-pink-800\/15 {
  color: rgb(157 23 77 / 0.15) !important;
}

.tw-text-pink-800\/20 {
  color: rgb(157 23 77 / 0.2) !important;
}

.tw-text-pink-800\/25 {
  color: rgb(157 23 77 / 0.25) !important;
}

.tw-text-pink-800\/30 {
  color: rgb(157 23 77 / 0.3) !important;
}

.tw-text-pink-800\/35 {
  color: rgb(157 23 77 / 0.35) !important;
}

.tw-text-pink-800\/40 {
  color: rgb(157 23 77 / 0.4) !important;
}

.tw-text-pink-800\/45 {
  color: rgb(157 23 77 / 0.45) !important;
}

.tw-text-pink-800\/5 {
  color: rgb(157 23 77 / 0.05) !important;
}

.tw-text-pink-800\/50 {
  color: rgb(157 23 77 / 0.5) !important;
}

.tw-text-pink-800\/55 {
  color: rgb(157 23 77 / 0.55) !important;
}

.tw-text-pink-800\/60 {
  color: rgb(157 23 77 / 0.6) !important;
}

.tw-text-pink-800\/65 {
  color: rgb(157 23 77 / 0.65) !important;
}

.tw-text-pink-800\/70 {
  color: rgb(157 23 77 / 0.7) !important;
}

.tw-text-pink-800\/75 {
  color: rgb(157 23 77 / 0.75) !important;
}

.tw-text-pink-800\/80 {
  color: rgb(157 23 77 / 0.8) !important;
}

.tw-text-pink-800\/85 {
  color: rgb(157 23 77 / 0.85) !important;
}

.tw-text-pink-800\/90 {
  color: rgb(157 23 77 / 0.9) !important;
}

.tw-text-pink-800\/95 {
  color: rgb(157 23 77 / 0.95) !important;
}

.tw-text-pink-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(131 24 67 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-900\/0 {
  color: rgb(131 24 67 / 0) !important;
}

.tw-text-pink-900\/10 {
  color: rgb(131 24 67 / 0.1) !important;
}

.tw-text-pink-900\/100 {
  color: rgb(131 24 67 / 1) !important;
}

.tw-text-pink-900\/15 {
  color: rgb(131 24 67 / 0.15) !important;
}

.tw-text-pink-900\/20 {
  color: rgb(131 24 67 / 0.2) !important;
}

.tw-text-pink-900\/25 {
  color: rgb(131 24 67 / 0.25) !important;
}

.tw-text-pink-900\/30 {
  color: rgb(131 24 67 / 0.3) !important;
}

.tw-text-pink-900\/35 {
  color: rgb(131 24 67 / 0.35) !important;
}

.tw-text-pink-900\/40 {
  color: rgb(131 24 67 / 0.4) !important;
}

.tw-text-pink-900\/45 {
  color: rgb(131 24 67 / 0.45) !important;
}

.tw-text-pink-900\/5 {
  color: rgb(131 24 67 / 0.05) !important;
}

.tw-text-pink-900\/50 {
  color: rgb(131 24 67 / 0.5) !important;
}

.tw-text-pink-900\/55 {
  color: rgb(131 24 67 / 0.55) !important;
}

.tw-text-pink-900\/60 {
  color: rgb(131 24 67 / 0.6) !important;
}

.tw-text-pink-900\/65 {
  color: rgb(131 24 67 / 0.65) !important;
}

.tw-text-pink-900\/70 {
  color: rgb(131 24 67 / 0.7) !important;
}

.tw-text-pink-900\/75 {
  color: rgb(131 24 67 / 0.75) !important;
}

.tw-text-pink-900\/80 {
  color: rgb(131 24 67 / 0.8) !important;
}

.tw-text-pink-900\/85 {
  color: rgb(131 24 67 / 0.85) !important;
}

.tw-text-pink-900\/90 {
  color: rgb(131 24 67 / 0.9) !important;
}

.tw-text-pink-900\/95 {
  color: rgb(131 24 67 / 0.95) !important;
}

.tw-text-pink-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(80 7 36 / var(--tw-text-opacity)) !important;
}

.tw-text-pink-950\/0 {
  color: rgb(80 7 36 / 0) !important;
}

.tw-text-pink-950\/10 {
  color: rgb(80 7 36 / 0.1) !important;
}

.tw-text-pink-950\/100 {
  color: rgb(80 7 36 / 1) !important;
}

.tw-text-pink-950\/15 {
  color: rgb(80 7 36 / 0.15) !important;
}

.tw-text-pink-950\/20 {
  color: rgb(80 7 36 / 0.2) !important;
}

.tw-text-pink-950\/25 {
  color: rgb(80 7 36 / 0.25) !important;
}

.tw-text-pink-950\/30 {
  color: rgb(80 7 36 / 0.3) !important;
}

.tw-text-pink-950\/35 {
  color: rgb(80 7 36 / 0.35) !important;
}

.tw-text-pink-950\/40 {
  color: rgb(80 7 36 / 0.4) !important;
}

.tw-text-pink-950\/45 {
  color: rgb(80 7 36 / 0.45) !important;
}

.tw-text-pink-950\/5 {
  color: rgb(80 7 36 / 0.05) !important;
}

.tw-text-pink-950\/50 {
  color: rgb(80 7 36 / 0.5) !important;
}

.tw-text-pink-950\/55 {
  color: rgb(80 7 36 / 0.55) !important;
}

.tw-text-pink-950\/60 {
  color: rgb(80 7 36 / 0.6) !important;
}

.tw-text-pink-950\/65 {
  color: rgb(80 7 36 / 0.65) !important;
}

.tw-text-pink-950\/70 {
  color: rgb(80 7 36 / 0.7) !important;
}

.tw-text-pink-950\/75 {
  color: rgb(80 7 36 / 0.75) !important;
}

.tw-text-pink-950\/80 {
  color: rgb(80 7 36 / 0.8) !important;
}

.tw-text-pink-950\/85 {
  color: rgb(80 7 36 / 0.85) !important;
}

.tw-text-pink-950\/90 {
  color: rgb(80 7 36 / 0.9) !important;
}

.tw-text-pink-950\/95 {
  color: rgb(80 7 36 / 0.95) !important;
}

.tw-text-purple-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(243 232 255 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-100\/0 {
  color: rgb(243 232 255 / 0) !important;
}

.tw-text-purple-100\/10 {
  color: rgb(243 232 255 / 0.1) !important;
}

.tw-text-purple-100\/100 {
  color: rgb(243 232 255 / 1) !important;
}

.tw-text-purple-100\/15 {
  color: rgb(243 232 255 / 0.15) !important;
}

.tw-text-purple-100\/20 {
  color: rgb(243 232 255 / 0.2) !important;
}

.tw-text-purple-100\/25 {
  color: rgb(243 232 255 / 0.25) !important;
}

.tw-text-purple-100\/30 {
  color: rgb(243 232 255 / 0.3) !important;
}

.tw-text-purple-100\/35 {
  color: rgb(243 232 255 / 0.35) !important;
}

.tw-text-purple-100\/40 {
  color: rgb(243 232 255 / 0.4) !important;
}

.tw-text-purple-100\/45 {
  color: rgb(243 232 255 / 0.45) !important;
}

.tw-text-purple-100\/5 {
  color: rgb(243 232 255 / 0.05) !important;
}

.tw-text-purple-100\/50 {
  color: rgb(243 232 255 / 0.5) !important;
}

.tw-text-purple-100\/55 {
  color: rgb(243 232 255 / 0.55) !important;
}

.tw-text-purple-100\/60 {
  color: rgb(243 232 255 / 0.6) !important;
}

.tw-text-purple-100\/65 {
  color: rgb(243 232 255 / 0.65) !important;
}

.tw-text-purple-100\/70 {
  color: rgb(243 232 255 / 0.7) !important;
}

.tw-text-purple-100\/75 {
  color: rgb(243 232 255 / 0.75) !important;
}

.tw-text-purple-100\/80 {
  color: rgb(243 232 255 / 0.8) !important;
}

.tw-text-purple-100\/85 {
  color: rgb(243 232 255 / 0.85) !important;
}

.tw-text-purple-100\/90 {
  color: rgb(243 232 255 / 0.9) !important;
}

.tw-text-purple-100\/95 {
  color: rgb(243 232 255 / 0.95) !important;
}

.tw-text-purple-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(233 213 255 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-200\/0 {
  color: rgb(233 213 255 / 0) !important;
}

.tw-text-purple-200\/10 {
  color: rgb(233 213 255 / 0.1) !important;
}

.tw-text-purple-200\/100 {
  color: rgb(233 213 255 / 1) !important;
}

.tw-text-purple-200\/15 {
  color: rgb(233 213 255 / 0.15) !important;
}

.tw-text-purple-200\/20 {
  color: rgb(233 213 255 / 0.2) !important;
}

.tw-text-purple-200\/25 {
  color: rgb(233 213 255 / 0.25) !important;
}

.tw-text-purple-200\/30 {
  color: rgb(233 213 255 / 0.3) !important;
}

.tw-text-purple-200\/35 {
  color: rgb(233 213 255 / 0.35) !important;
}

.tw-text-purple-200\/40 {
  color: rgb(233 213 255 / 0.4) !important;
}

.tw-text-purple-200\/45 {
  color: rgb(233 213 255 / 0.45) !important;
}

.tw-text-purple-200\/5 {
  color: rgb(233 213 255 / 0.05) !important;
}

.tw-text-purple-200\/50 {
  color: rgb(233 213 255 / 0.5) !important;
}

.tw-text-purple-200\/55 {
  color: rgb(233 213 255 / 0.55) !important;
}

.tw-text-purple-200\/60 {
  color: rgb(233 213 255 / 0.6) !important;
}

.tw-text-purple-200\/65 {
  color: rgb(233 213 255 / 0.65) !important;
}

.tw-text-purple-200\/70 {
  color: rgb(233 213 255 / 0.7) !important;
}

.tw-text-purple-200\/75 {
  color: rgb(233 213 255 / 0.75) !important;
}

.tw-text-purple-200\/80 {
  color: rgb(233 213 255 / 0.8) !important;
}

.tw-text-purple-200\/85 {
  color: rgb(233 213 255 / 0.85) !important;
}

.tw-text-purple-200\/90 {
  color: rgb(233 213 255 / 0.9) !important;
}

.tw-text-purple-200\/95 {
  color: rgb(233 213 255 / 0.95) !important;
}

.tw-text-purple-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(216 180 254 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-300\/0 {
  color: rgb(216 180 254 / 0) !important;
}

.tw-text-purple-300\/10 {
  color: rgb(216 180 254 / 0.1) !important;
}

.tw-text-purple-300\/100 {
  color: rgb(216 180 254 / 1) !important;
}

.tw-text-purple-300\/15 {
  color: rgb(216 180 254 / 0.15) !important;
}

.tw-text-purple-300\/20 {
  color: rgb(216 180 254 / 0.2) !important;
}

.tw-text-purple-300\/25 {
  color: rgb(216 180 254 / 0.25) !important;
}

.tw-text-purple-300\/30 {
  color: rgb(216 180 254 / 0.3) !important;
}

.tw-text-purple-300\/35 {
  color: rgb(216 180 254 / 0.35) !important;
}

.tw-text-purple-300\/40 {
  color: rgb(216 180 254 / 0.4) !important;
}

.tw-text-purple-300\/45 {
  color: rgb(216 180 254 / 0.45) !important;
}

.tw-text-purple-300\/5 {
  color: rgb(216 180 254 / 0.05) !important;
}

.tw-text-purple-300\/50 {
  color: rgb(216 180 254 / 0.5) !important;
}

.tw-text-purple-300\/55 {
  color: rgb(216 180 254 / 0.55) !important;
}

.tw-text-purple-300\/60 {
  color: rgb(216 180 254 / 0.6) !important;
}

.tw-text-purple-300\/65 {
  color: rgb(216 180 254 / 0.65) !important;
}

.tw-text-purple-300\/70 {
  color: rgb(216 180 254 / 0.7) !important;
}

.tw-text-purple-300\/75 {
  color: rgb(216 180 254 / 0.75) !important;
}

.tw-text-purple-300\/80 {
  color: rgb(216 180 254 / 0.8) !important;
}

.tw-text-purple-300\/85 {
  color: rgb(216 180 254 / 0.85) !important;
}

.tw-text-purple-300\/90 {
  color: rgb(216 180 254 / 0.9) !important;
}

.tw-text-purple-300\/95 {
  color: rgb(216 180 254 / 0.95) !important;
}

.tw-text-purple-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(192 132 252 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-400\/0 {
  color: rgb(192 132 252 / 0) !important;
}

.tw-text-purple-400\/10 {
  color: rgb(192 132 252 / 0.1) !important;
}

.tw-text-purple-400\/100 {
  color: rgb(192 132 252 / 1) !important;
}

.tw-text-purple-400\/15 {
  color: rgb(192 132 252 / 0.15) !important;
}

.tw-text-purple-400\/20 {
  color: rgb(192 132 252 / 0.2) !important;
}

.tw-text-purple-400\/25 {
  color: rgb(192 132 252 / 0.25) !important;
}

.tw-text-purple-400\/30 {
  color: rgb(192 132 252 / 0.3) !important;
}

.tw-text-purple-400\/35 {
  color: rgb(192 132 252 / 0.35) !important;
}

.tw-text-purple-400\/40 {
  color: rgb(192 132 252 / 0.4) !important;
}

.tw-text-purple-400\/45 {
  color: rgb(192 132 252 / 0.45) !important;
}

.tw-text-purple-400\/5 {
  color: rgb(192 132 252 / 0.05) !important;
}

.tw-text-purple-400\/50 {
  color: rgb(192 132 252 / 0.5) !important;
}

.tw-text-purple-400\/55 {
  color: rgb(192 132 252 / 0.55) !important;
}

.tw-text-purple-400\/60 {
  color: rgb(192 132 252 / 0.6) !important;
}

.tw-text-purple-400\/65 {
  color: rgb(192 132 252 / 0.65) !important;
}

.tw-text-purple-400\/70 {
  color: rgb(192 132 252 / 0.7) !important;
}

.tw-text-purple-400\/75 {
  color: rgb(192 132 252 / 0.75) !important;
}

.tw-text-purple-400\/80 {
  color: rgb(192 132 252 / 0.8) !important;
}

.tw-text-purple-400\/85 {
  color: rgb(192 132 252 / 0.85) !important;
}

.tw-text-purple-400\/90 {
  color: rgb(192 132 252 / 0.9) !important;
}

.tw-text-purple-400\/95 {
  color: rgb(192 132 252 / 0.95) !important;
}

.tw-text-purple-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(250 245 255 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-50\/0 {
  color: rgb(250 245 255 / 0) !important;
}

.tw-text-purple-50\/10 {
  color: rgb(250 245 255 / 0.1) !important;
}

.tw-text-purple-50\/100 {
  color: rgb(250 245 255 / 1) !important;
}

.tw-text-purple-50\/15 {
  color: rgb(250 245 255 / 0.15) !important;
}

.tw-text-purple-50\/20 {
  color: rgb(250 245 255 / 0.2) !important;
}

.tw-text-purple-50\/25 {
  color: rgb(250 245 255 / 0.25) !important;
}

.tw-text-purple-50\/30 {
  color: rgb(250 245 255 / 0.3) !important;
}

.tw-text-purple-50\/35 {
  color: rgb(250 245 255 / 0.35) !important;
}

.tw-text-purple-50\/40 {
  color: rgb(250 245 255 / 0.4) !important;
}

.tw-text-purple-50\/45 {
  color: rgb(250 245 255 / 0.45) !important;
}

.tw-text-purple-50\/5 {
  color: rgb(250 245 255 / 0.05) !important;
}

.tw-text-purple-50\/50 {
  color: rgb(250 245 255 / 0.5) !important;
}

.tw-text-purple-50\/55 {
  color: rgb(250 245 255 / 0.55) !important;
}

.tw-text-purple-50\/60 {
  color: rgb(250 245 255 / 0.6) !important;
}

.tw-text-purple-50\/65 {
  color: rgb(250 245 255 / 0.65) !important;
}

.tw-text-purple-50\/70 {
  color: rgb(250 245 255 / 0.7) !important;
}

.tw-text-purple-50\/75 {
  color: rgb(250 245 255 / 0.75) !important;
}

.tw-text-purple-50\/80 {
  color: rgb(250 245 255 / 0.8) !important;
}

.tw-text-purple-50\/85 {
  color: rgb(250 245 255 / 0.85) !important;
}

.tw-text-purple-50\/90 {
  color: rgb(250 245 255 / 0.9) !important;
}

.tw-text-purple-50\/95 {
  color: rgb(250 245 255 / 0.95) !important;
}

.tw-text-purple-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(168 85 247 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-500\/0 {
  color: rgb(168 85 247 / 0) !important;
}

.tw-text-purple-500\/10 {
  color: rgb(168 85 247 / 0.1) !important;
}

.tw-text-purple-500\/100 {
  color: rgb(168 85 247 / 1) !important;
}

.tw-text-purple-500\/15 {
  color: rgb(168 85 247 / 0.15) !important;
}

.tw-text-purple-500\/20 {
  color: rgb(168 85 247 / 0.2) !important;
}

.tw-text-purple-500\/25 {
  color: rgb(168 85 247 / 0.25) !important;
}

.tw-text-purple-500\/30 {
  color: rgb(168 85 247 / 0.3) !important;
}

.tw-text-purple-500\/35 {
  color: rgb(168 85 247 / 0.35) !important;
}

.tw-text-purple-500\/40 {
  color: rgb(168 85 247 / 0.4) !important;
}

.tw-text-purple-500\/45 {
  color: rgb(168 85 247 / 0.45) !important;
}

.tw-text-purple-500\/5 {
  color: rgb(168 85 247 / 0.05) !important;
}

.tw-text-purple-500\/50 {
  color: rgb(168 85 247 / 0.5) !important;
}

.tw-text-purple-500\/55 {
  color: rgb(168 85 247 / 0.55) !important;
}

.tw-text-purple-500\/60 {
  color: rgb(168 85 247 / 0.6) !important;
}

.tw-text-purple-500\/65 {
  color: rgb(168 85 247 / 0.65) !important;
}

.tw-text-purple-500\/70 {
  color: rgb(168 85 247 / 0.7) !important;
}

.tw-text-purple-500\/75 {
  color: rgb(168 85 247 / 0.75) !important;
}

.tw-text-purple-500\/80 {
  color: rgb(168 85 247 / 0.8) !important;
}

.tw-text-purple-500\/85 {
  color: rgb(168 85 247 / 0.85) !important;
}

.tw-text-purple-500\/90 {
  color: rgb(168 85 247 / 0.9) !important;
}

.tw-text-purple-500\/95 {
  color: rgb(168 85 247 / 0.95) !important;
}

.tw-text-purple-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(147 51 234 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-600\/0 {
  color: rgb(147 51 234 / 0) !important;
}

.tw-text-purple-600\/10 {
  color: rgb(147 51 234 / 0.1) !important;
}

.tw-text-purple-600\/100 {
  color: rgb(147 51 234 / 1) !important;
}

.tw-text-purple-600\/15 {
  color: rgb(147 51 234 / 0.15) !important;
}

.tw-text-purple-600\/20 {
  color: rgb(147 51 234 / 0.2) !important;
}

.tw-text-purple-600\/25 {
  color: rgb(147 51 234 / 0.25) !important;
}

.tw-text-purple-600\/30 {
  color: rgb(147 51 234 / 0.3) !important;
}

.tw-text-purple-600\/35 {
  color: rgb(147 51 234 / 0.35) !important;
}

.tw-text-purple-600\/40 {
  color: rgb(147 51 234 / 0.4) !important;
}

.tw-text-purple-600\/45 {
  color: rgb(147 51 234 / 0.45) !important;
}

.tw-text-purple-600\/5 {
  color: rgb(147 51 234 / 0.05) !important;
}

.tw-text-purple-600\/50 {
  color: rgb(147 51 234 / 0.5) !important;
}

.tw-text-purple-600\/55 {
  color: rgb(147 51 234 / 0.55) !important;
}

.tw-text-purple-600\/60 {
  color: rgb(147 51 234 / 0.6) !important;
}

.tw-text-purple-600\/65 {
  color: rgb(147 51 234 / 0.65) !important;
}

.tw-text-purple-600\/70 {
  color: rgb(147 51 234 / 0.7) !important;
}

.tw-text-purple-600\/75 {
  color: rgb(147 51 234 / 0.75) !important;
}

.tw-text-purple-600\/80 {
  color: rgb(147 51 234 / 0.8) !important;
}

.tw-text-purple-600\/85 {
  color: rgb(147 51 234 / 0.85) !important;
}

.tw-text-purple-600\/90 {
  color: rgb(147 51 234 / 0.9) !important;
}

.tw-text-purple-600\/95 {
  color: rgb(147 51 234 / 0.95) !important;
}

.tw-text-purple-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(126 34 206 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-700\/0 {
  color: rgb(126 34 206 / 0) !important;
}

.tw-text-purple-700\/10 {
  color: rgb(126 34 206 / 0.1) !important;
}

.tw-text-purple-700\/100 {
  color: rgb(126 34 206 / 1) !important;
}

.tw-text-purple-700\/15 {
  color: rgb(126 34 206 / 0.15) !important;
}

.tw-text-purple-700\/20 {
  color: rgb(126 34 206 / 0.2) !important;
}

.tw-text-purple-700\/25 {
  color: rgb(126 34 206 / 0.25) !important;
}

.tw-text-purple-700\/30 {
  color: rgb(126 34 206 / 0.3) !important;
}

.tw-text-purple-700\/35 {
  color: rgb(126 34 206 / 0.35) !important;
}

.tw-text-purple-700\/40 {
  color: rgb(126 34 206 / 0.4) !important;
}

.tw-text-purple-700\/45 {
  color: rgb(126 34 206 / 0.45) !important;
}

.tw-text-purple-700\/5 {
  color: rgb(126 34 206 / 0.05) !important;
}

.tw-text-purple-700\/50 {
  color: rgb(126 34 206 / 0.5) !important;
}

.tw-text-purple-700\/55 {
  color: rgb(126 34 206 / 0.55) !important;
}

.tw-text-purple-700\/60 {
  color: rgb(126 34 206 / 0.6) !important;
}

.tw-text-purple-700\/65 {
  color: rgb(126 34 206 / 0.65) !important;
}

.tw-text-purple-700\/70 {
  color: rgb(126 34 206 / 0.7) !important;
}

.tw-text-purple-700\/75 {
  color: rgb(126 34 206 / 0.75) !important;
}

.tw-text-purple-700\/80 {
  color: rgb(126 34 206 / 0.8) !important;
}

.tw-text-purple-700\/85 {
  color: rgb(126 34 206 / 0.85) !important;
}

.tw-text-purple-700\/90 {
  color: rgb(126 34 206 / 0.9) !important;
}

.tw-text-purple-700\/95 {
  color: rgb(126 34 206 / 0.95) !important;
}

.tw-text-purple-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(107 33 168 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-800\/0 {
  color: rgb(107 33 168 / 0) !important;
}

.tw-text-purple-800\/10 {
  color: rgb(107 33 168 / 0.1) !important;
}

.tw-text-purple-800\/100 {
  color: rgb(107 33 168 / 1) !important;
}

.tw-text-purple-800\/15 {
  color: rgb(107 33 168 / 0.15) !important;
}

.tw-text-purple-800\/20 {
  color: rgb(107 33 168 / 0.2) !important;
}

.tw-text-purple-800\/25 {
  color: rgb(107 33 168 / 0.25) !important;
}

.tw-text-purple-800\/30 {
  color: rgb(107 33 168 / 0.3) !important;
}

.tw-text-purple-800\/35 {
  color: rgb(107 33 168 / 0.35) !important;
}

.tw-text-purple-800\/40 {
  color: rgb(107 33 168 / 0.4) !important;
}

.tw-text-purple-800\/45 {
  color: rgb(107 33 168 / 0.45) !important;
}

.tw-text-purple-800\/5 {
  color: rgb(107 33 168 / 0.05) !important;
}

.tw-text-purple-800\/50 {
  color: rgb(107 33 168 / 0.5) !important;
}

.tw-text-purple-800\/55 {
  color: rgb(107 33 168 / 0.55) !important;
}

.tw-text-purple-800\/60 {
  color: rgb(107 33 168 / 0.6) !important;
}

.tw-text-purple-800\/65 {
  color: rgb(107 33 168 / 0.65) !important;
}

.tw-text-purple-800\/70 {
  color: rgb(107 33 168 / 0.7) !important;
}

.tw-text-purple-800\/75 {
  color: rgb(107 33 168 / 0.75) !important;
}

.tw-text-purple-800\/80 {
  color: rgb(107 33 168 / 0.8) !important;
}

.tw-text-purple-800\/85 {
  color: rgb(107 33 168 / 0.85) !important;
}

.tw-text-purple-800\/90 {
  color: rgb(107 33 168 / 0.9) !important;
}

.tw-text-purple-800\/95 {
  color: rgb(107 33 168 / 0.95) !important;
}

.tw-text-purple-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(88 28 135 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-900\/0 {
  color: rgb(88 28 135 / 0) !important;
}

.tw-text-purple-900\/10 {
  color: rgb(88 28 135 / 0.1) !important;
}

.tw-text-purple-900\/100 {
  color: rgb(88 28 135 / 1) !important;
}

.tw-text-purple-900\/15 {
  color: rgb(88 28 135 / 0.15) !important;
}

.tw-text-purple-900\/20 {
  color: rgb(88 28 135 / 0.2) !important;
}

.tw-text-purple-900\/25 {
  color: rgb(88 28 135 / 0.25) !important;
}

.tw-text-purple-900\/30 {
  color: rgb(88 28 135 / 0.3) !important;
}

.tw-text-purple-900\/35 {
  color: rgb(88 28 135 / 0.35) !important;
}

.tw-text-purple-900\/40 {
  color: rgb(88 28 135 / 0.4) !important;
}

.tw-text-purple-900\/45 {
  color: rgb(88 28 135 / 0.45) !important;
}

.tw-text-purple-900\/5 {
  color: rgb(88 28 135 / 0.05) !important;
}

.tw-text-purple-900\/50 {
  color: rgb(88 28 135 / 0.5) !important;
}

.tw-text-purple-900\/55 {
  color: rgb(88 28 135 / 0.55) !important;
}

.tw-text-purple-900\/60 {
  color: rgb(88 28 135 / 0.6) !important;
}

.tw-text-purple-900\/65 {
  color: rgb(88 28 135 / 0.65) !important;
}

.tw-text-purple-900\/70 {
  color: rgb(88 28 135 / 0.7) !important;
}

.tw-text-purple-900\/75 {
  color: rgb(88 28 135 / 0.75) !important;
}

.tw-text-purple-900\/80 {
  color: rgb(88 28 135 / 0.8) !important;
}

.tw-text-purple-900\/85 {
  color: rgb(88 28 135 / 0.85) !important;
}

.tw-text-purple-900\/90 {
  color: rgb(88 28 135 / 0.9) !important;
}

.tw-text-purple-900\/95 {
  color: rgb(88 28 135 / 0.95) !important;
}

.tw-text-purple-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(59 7 100 / var(--tw-text-opacity)) !important;
}

.tw-text-purple-950\/0 {
  color: rgb(59 7 100 / 0) !important;
}

.tw-text-purple-950\/10 {
  color: rgb(59 7 100 / 0.1) !important;
}

.tw-text-purple-950\/100 {
  color: rgb(59 7 100 / 1) !important;
}

.tw-text-purple-950\/15 {
  color: rgb(59 7 100 / 0.15) !important;
}

.tw-text-purple-950\/20 {
  color: rgb(59 7 100 / 0.2) !important;
}

.tw-text-purple-950\/25 {
  color: rgb(59 7 100 / 0.25) !important;
}

.tw-text-purple-950\/30 {
  color: rgb(59 7 100 / 0.3) !important;
}

.tw-text-purple-950\/35 {
  color: rgb(59 7 100 / 0.35) !important;
}

.tw-text-purple-950\/40 {
  color: rgb(59 7 100 / 0.4) !important;
}

.tw-text-purple-950\/45 {
  color: rgb(59 7 100 / 0.45) !important;
}

.tw-text-purple-950\/5 {
  color: rgb(59 7 100 / 0.05) !important;
}

.tw-text-purple-950\/50 {
  color: rgb(59 7 100 / 0.5) !important;
}

.tw-text-purple-950\/55 {
  color: rgb(59 7 100 / 0.55) !important;
}

.tw-text-purple-950\/60 {
  color: rgb(59 7 100 / 0.6) !important;
}

.tw-text-purple-950\/65 {
  color: rgb(59 7 100 / 0.65) !important;
}

.tw-text-purple-950\/70 {
  color: rgb(59 7 100 / 0.7) !important;
}

.tw-text-purple-950\/75 {
  color: rgb(59 7 100 / 0.75) !important;
}

.tw-text-purple-950\/80 {
  color: rgb(59 7 100 / 0.8) !important;
}

.tw-text-purple-950\/85 {
  color: rgb(59 7 100 / 0.85) !important;
}

.tw-text-purple-950\/90 {
  color: rgb(59 7 100 / 0.9) !important;
}

.tw-text-purple-950\/95 {
  color: rgb(59 7 100 / 0.95) !important;
}

.tw-text-red-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 226 226 / var(--tw-text-opacity)) !important;
}

.tw-text-red-100\/0 {
  color: rgb(254 226 226 / 0) !important;
}

.tw-text-red-100\/10 {
  color: rgb(254 226 226 / 0.1) !important;
}

.tw-text-red-100\/100 {
  color: rgb(254 226 226 / 1) !important;
}

.tw-text-red-100\/15 {
  color: rgb(254 226 226 / 0.15) !important;
}

.tw-text-red-100\/20 {
  color: rgb(254 226 226 / 0.2) !important;
}

.tw-text-red-100\/25 {
  color: rgb(254 226 226 / 0.25) !important;
}

.tw-text-red-100\/30 {
  color: rgb(254 226 226 / 0.3) !important;
}

.tw-text-red-100\/35 {
  color: rgb(254 226 226 / 0.35) !important;
}

.tw-text-red-100\/40 {
  color: rgb(254 226 226 / 0.4) !important;
}

.tw-text-red-100\/45 {
  color: rgb(254 226 226 / 0.45) !important;
}

.tw-text-red-100\/5 {
  color: rgb(254 226 226 / 0.05) !important;
}

.tw-text-red-100\/50 {
  color: rgb(254 226 226 / 0.5) !important;
}

.tw-text-red-100\/55 {
  color: rgb(254 226 226 / 0.55) !important;
}

.tw-text-red-100\/60 {
  color: rgb(254 226 226 / 0.6) !important;
}

.tw-text-red-100\/65 {
  color: rgb(254 226 226 / 0.65) !important;
}

.tw-text-red-100\/70 {
  color: rgb(254 226 226 / 0.7) !important;
}

.tw-text-red-100\/75 {
  color: rgb(254 226 226 / 0.75) !important;
}

.tw-text-red-100\/80 {
  color: rgb(254 226 226 / 0.8) !important;
}

.tw-text-red-100\/85 {
  color: rgb(254 226 226 / 0.85) !important;
}

.tw-text-red-100\/90 {
  color: rgb(254 226 226 / 0.9) !important;
}

.tw-text-red-100\/95 {
  color: rgb(254 226 226 / 0.95) !important;
}

.tw-text-red-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 202 202 / var(--tw-text-opacity)) !important;
}

.tw-text-red-200\/0 {
  color: rgb(254 202 202 / 0) !important;
}

.tw-text-red-200\/10 {
  color: rgb(254 202 202 / 0.1) !important;
}

.tw-text-red-200\/100 {
  color: rgb(254 202 202 / 1) !important;
}

.tw-text-red-200\/15 {
  color: rgb(254 202 202 / 0.15) !important;
}

.tw-text-red-200\/20 {
  color: rgb(254 202 202 / 0.2) !important;
}

.tw-text-red-200\/25 {
  color: rgb(254 202 202 / 0.25) !important;
}

.tw-text-red-200\/30 {
  color: rgb(254 202 202 / 0.3) !important;
}

.tw-text-red-200\/35 {
  color: rgb(254 202 202 / 0.35) !important;
}

.tw-text-red-200\/40 {
  color: rgb(254 202 202 / 0.4) !important;
}

.tw-text-red-200\/45 {
  color: rgb(254 202 202 / 0.45) !important;
}

.tw-text-red-200\/5 {
  color: rgb(254 202 202 / 0.05) !important;
}

.tw-text-red-200\/50 {
  color: rgb(254 202 202 / 0.5) !important;
}

.tw-text-red-200\/55 {
  color: rgb(254 202 202 / 0.55) !important;
}

.tw-text-red-200\/60 {
  color: rgb(254 202 202 / 0.6) !important;
}

.tw-text-red-200\/65 {
  color: rgb(254 202 202 / 0.65) !important;
}

.tw-text-red-200\/70 {
  color: rgb(254 202 202 / 0.7) !important;
}

.tw-text-red-200\/75 {
  color: rgb(254 202 202 / 0.75) !important;
}

.tw-text-red-200\/80 {
  color: rgb(254 202 202 / 0.8) !important;
}

.tw-text-red-200\/85 {
  color: rgb(254 202 202 / 0.85) !important;
}

.tw-text-red-200\/90 {
  color: rgb(254 202 202 / 0.9) !important;
}

.tw-text-red-200\/95 {
  color: rgb(254 202 202 / 0.95) !important;
}

.tw-text-red-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(252 165 165 / var(--tw-text-opacity)) !important;
}

.tw-text-red-300\/0 {
  color: rgb(252 165 165 / 0) !important;
}

.tw-text-red-300\/10 {
  color: rgb(252 165 165 / 0.1) !important;
}

.tw-text-red-300\/100 {
  color: rgb(252 165 165 / 1) !important;
}

.tw-text-red-300\/15 {
  color: rgb(252 165 165 / 0.15) !important;
}

.tw-text-red-300\/20 {
  color: rgb(252 165 165 / 0.2) !important;
}

.tw-text-red-300\/25 {
  color: rgb(252 165 165 / 0.25) !important;
}

.tw-text-red-300\/30 {
  color: rgb(252 165 165 / 0.3) !important;
}

.tw-text-red-300\/35 {
  color: rgb(252 165 165 / 0.35) !important;
}

.tw-text-red-300\/40 {
  color: rgb(252 165 165 / 0.4) !important;
}

.tw-text-red-300\/45 {
  color: rgb(252 165 165 / 0.45) !important;
}

.tw-text-red-300\/5 {
  color: rgb(252 165 165 / 0.05) !important;
}

.tw-text-red-300\/50 {
  color: rgb(252 165 165 / 0.5) !important;
}

.tw-text-red-300\/55 {
  color: rgb(252 165 165 / 0.55) !important;
}

.tw-text-red-300\/60 {
  color: rgb(252 165 165 / 0.6) !important;
}

.tw-text-red-300\/65 {
  color: rgb(252 165 165 / 0.65) !important;
}

.tw-text-red-300\/70 {
  color: rgb(252 165 165 / 0.7) !important;
}

.tw-text-red-300\/75 {
  color: rgb(252 165 165 / 0.75) !important;
}

.tw-text-red-300\/80 {
  color: rgb(252 165 165 / 0.8) !important;
}

.tw-text-red-300\/85 {
  color: rgb(252 165 165 / 0.85) !important;
}

.tw-text-red-300\/90 {
  color: rgb(252 165 165 / 0.9) !important;
}

.tw-text-red-300\/95 {
  color: rgb(252 165 165 / 0.95) !important;
}

.tw-text-red-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-text-opacity)) !important;
}

.tw-text-red-400\/0 {
  color: rgb(248 113 113 / 0) !important;
}

.tw-text-red-400\/10 {
  color: rgb(248 113 113 / 0.1) !important;
}

.tw-text-red-400\/100 {
  color: rgb(248 113 113 / 1) !important;
}

.tw-text-red-400\/15 {
  color: rgb(248 113 113 / 0.15) !important;
}

.tw-text-red-400\/20 {
  color: rgb(248 113 113 / 0.2) !important;
}

.tw-text-red-400\/25 {
  color: rgb(248 113 113 / 0.25) !important;
}

.tw-text-red-400\/30 {
  color: rgb(248 113 113 / 0.3) !important;
}

.tw-text-red-400\/35 {
  color: rgb(248 113 113 / 0.35) !important;
}

.tw-text-red-400\/40 {
  color: rgb(248 113 113 / 0.4) !important;
}

.tw-text-red-400\/45 {
  color: rgb(248 113 113 / 0.45) !important;
}

.tw-text-red-400\/5 {
  color: rgb(248 113 113 / 0.05) !important;
}

.tw-text-red-400\/50 {
  color: rgb(248 113 113 / 0.5) !important;
}

.tw-text-red-400\/55 {
  color: rgb(248 113 113 / 0.55) !important;
}

.tw-text-red-400\/60 {
  color: rgb(248 113 113 / 0.6) !important;
}

.tw-text-red-400\/65 {
  color: rgb(248 113 113 / 0.65) !important;
}

.tw-text-red-400\/70 {
  color: rgb(248 113 113 / 0.7) !important;
}

.tw-text-red-400\/75 {
  color: rgb(248 113 113 / 0.75) !important;
}

.tw-text-red-400\/80 {
  color: rgb(248 113 113 / 0.8) !important;
}

.tw-text-red-400\/85 {
  color: rgb(248 113 113 / 0.85) !important;
}

.tw-text-red-400\/90 {
  color: rgb(248 113 113 / 0.9) !important;
}

.tw-text-red-400\/95 {
  color: rgb(248 113 113 / 0.95) !important;
}

.tw-text-red-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 242 242 / var(--tw-text-opacity)) !important;
}

.tw-text-red-50\/0 {
  color: rgb(254 242 242 / 0) !important;
}

.tw-text-red-50\/10 {
  color: rgb(254 242 242 / 0.1) !important;
}

.tw-text-red-50\/100 {
  color: rgb(254 242 242 / 1) !important;
}

.tw-text-red-50\/15 {
  color: rgb(254 242 242 / 0.15) !important;
}

.tw-text-red-50\/20 {
  color: rgb(254 242 242 / 0.2) !important;
}

.tw-text-red-50\/25 {
  color: rgb(254 242 242 / 0.25) !important;
}

.tw-text-red-50\/30 {
  color: rgb(254 242 242 / 0.3) !important;
}

.tw-text-red-50\/35 {
  color: rgb(254 242 242 / 0.35) !important;
}

.tw-text-red-50\/40 {
  color: rgb(254 242 242 / 0.4) !important;
}

.tw-text-red-50\/45 {
  color: rgb(254 242 242 / 0.45) !important;
}

.tw-text-red-50\/5 {
  color: rgb(254 242 242 / 0.05) !important;
}

.tw-text-red-50\/50 {
  color: rgb(254 242 242 / 0.5) !important;
}

.tw-text-red-50\/55 {
  color: rgb(254 242 242 / 0.55) !important;
}

.tw-text-red-50\/60 {
  color: rgb(254 242 242 / 0.6) !important;
}

.tw-text-red-50\/65 {
  color: rgb(254 242 242 / 0.65) !important;
}

.tw-text-red-50\/70 {
  color: rgb(254 242 242 / 0.7) !important;
}

.tw-text-red-50\/75 {
  color: rgb(254 242 242 / 0.75) !important;
}

.tw-text-red-50\/80 {
  color: rgb(254 242 242 / 0.8) !important;
}

.tw-text-red-50\/85 {
  color: rgb(254 242 242 / 0.85) !important;
}

.tw-text-red-50\/90 {
  color: rgb(254 242 242 / 0.9) !important;
}

.tw-text-red-50\/95 {
  color: rgb(254 242 242 / 0.95) !important;
}

.tw-text-red-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-text-opacity)) !important;
}

.tw-text-red-500\/0 {
  color: rgb(239 68 68 / 0) !important;
}

.tw-text-red-500\/10 {
  color: rgb(239 68 68 / 0.1) !important;
}

.tw-text-red-500\/100 {
  color: rgb(239 68 68 / 1) !important;
}

.tw-text-red-500\/15 {
  color: rgb(239 68 68 / 0.15) !important;
}

.tw-text-red-500\/20 {
  color: rgb(239 68 68 / 0.2) !important;
}

.tw-text-red-500\/25 {
  color: rgb(239 68 68 / 0.25) !important;
}

.tw-text-red-500\/30 {
  color: rgb(239 68 68 / 0.3) !important;
}

.tw-text-red-500\/35 {
  color: rgb(239 68 68 / 0.35) !important;
}

.tw-text-red-500\/40 {
  color: rgb(239 68 68 / 0.4) !important;
}

.tw-text-red-500\/45 {
  color: rgb(239 68 68 / 0.45) !important;
}

.tw-text-red-500\/5 {
  color: rgb(239 68 68 / 0.05) !important;
}

.tw-text-red-500\/50 {
  color: rgb(239 68 68 / 0.5) !important;
}

.tw-text-red-500\/55 {
  color: rgb(239 68 68 / 0.55) !important;
}

.tw-text-red-500\/60 {
  color: rgb(239 68 68 / 0.6) !important;
}

.tw-text-red-500\/65 {
  color: rgb(239 68 68 / 0.65) !important;
}

.tw-text-red-500\/70 {
  color: rgb(239 68 68 / 0.7) !important;
}

.tw-text-red-500\/75 {
  color: rgb(239 68 68 / 0.75) !important;
}

.tw-text-red-500\/80 {
  color: rgb(239 68 68 / 0.8) !important;
}

.tw-text-red-500\/85 {
  color: rgb(239 68 68 / 0.85) !important;
}

.tw-text-red-500\/90 {
  color: rgb(239 68 68 / 0.9) !important;
}

.tw-text-red-500\/95 {
  color: rgb(239 68 68 / 0.95) !important;
}

.tw-text-red-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-text-opacity)) !important;
}

.tw-text-red-600\/0 {
  color: rgb(220 38 38 / 0) !important;
}

.tw-text-red-600\/10 {
  color: rgb(220 38 38 / 0.1) !important;
}

.tw-text-red-600\/100 {
  color: rgb(220 38 38 / 1) !important;
}

.tw-text-red-600\/15 {
  color: rgb(220 38 38 / 0.15) !important;
}

.tw-text-red-600\/20 {
  color: rgb(220 38 38 / 0.2) !important;
}

.tw-text-red-600\/25 {
  color: rgb(220 38 38 / 0.25) !important;
}

.tw-text-red-600\/30 {
  color: rgb(220 38 38 / 0.3) !important;
}

.tw-text-red-600\/35 {
  color: rgb(220 38 38 / 0.35) !important;
}

.tw-text-red-600\/40 {
  color: rgb(220 38 38 / 0.4) !important;
}

.tw-text-red-600\/45 {
  color: rgb(220 38 38 / 0.45) !important;
}

.tw-text-red-600\/5 {
  color: rgb(220 38 38 / 0.05) !important;
}

.tw-text-red-600\/50 {
  color: rgb(220 38 38 / 0.5) !important;
}

.tw-text-red-600\/55 {
  color: rgb(220 38 38 / 0.55) !important;
}

.tw-text-red-600\/60 {
  color: rgb(220 38 38 / 0.6) !important;
}

.tw-text-red-600\/65 {
  color: rgb(220 38 38 / 0.65) !important;
}

.tw-text-red-600\/70 {
  color: rgb(220 38 38 / 0.7) !important;
}

.tw-text-red-600\/75 {
  color: rgb(220 38 38 / 0.75) !important;
}

.tw-text-red-600\/80 {
  color: rgb(220 38 38 / 0.8) !important;
}

.tw-text-red-600\/85 {
  color: rgb(220 38 38 / 0.85) !important;
}

.tw-text-red-600\/90 {
  color: rgb(220 38 38 / 0.9) !important;
}

.tw-text-red-600\/95 {
  color: rgb(220 38 38 / 0.95) !important;
}

.tw-text-red-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(185 28 28 / var(--tw-text-opacity)) !important;
}

.tw-text-red-700\/0 {
  color: rgb(185 28 28 / 0) !important;
}

.tw-text-red-700\/10 {
  color: rgb(185 28 28 / 0.1) !important;
}

.tw-text-red-700\/100 {
  color: rgb(185 28 28 / 1) !important;
}

.tw-text-red-700\/15 {
  color: rgb(185 28 28 / 0.15) !important;
}

.tw-text-red-700\/20 {
  color: rgb(185 28 28 / 0.2) !important;
}

.tw-text-red-700\/25 {
  color: rgb(185 28 28 / 0.25) !important;
}

.tw-text-red-700\/30 {
  color: rgb(185 28 28 / 0.3) !important;
}

.tw-text-red-700\/35 {
  color: rgb(185 28 28 / 0.35) !important;
}

.tw-text-red-700\/40 {
  color: rgb(185 28 28 / 0.4) !important;
}

.tw-text-red-700\/45 {
  color: rgb(185 28 28 / 0.45) !important;
}

.tw-text-red-700\/5 {
  color: rgb(185 28 28 / 0.05) !important;
}

.tw-text-red-700\/50 {
  color: rgb(185 28 28 / 0.5) !important;
}

.tw-text-red-700\/55 {
  color: rgb(185 28 28 / 0.55) !important;
}

.tw-text-red-700\/60 {
  color: rgb(185 28 28 / 0.6) !important;
}

.tw-text-red-700\/65 {
  color: rgb(185 28 28 / 0.65) !important;
}

.tw-text-red-700\/70 {
  color: rgb(185 28 28 / 0.7) !important;
}

.tw-text-red-700\/75 {
  color: rgb(185 28 28 / 0.75) !important;
}

.tw-text-red-700\/80 {
  color: rgb(185 28 28 / 0.8) !important;
}

.tw-text-red-700\/85 {
  color: rgb(185 28 28 / 0.85) !important;
}

.tw-text-red-700\/90 {
  color: rgb(185 28 28 / 0.9) !important;
}

.tw-text-red-700\/95 {
  color: rgb(185 28 28 / 0.95) !important;
}

.tw-text-red-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(153 27 27 / var(--tw-text-opacity)) !important;
}

.tw-text-red-800\/0 {
  color: rgb(153 27 27 / 0) !important;
}

.tw-text-red-800\/10 {
  color: rgb(153 27 27 / 0.1) !important;
}

.tw-text-red-800\/100 {
  color: rgb(153 27 27 / 1) !important;
}

.tw-text-red-800\/15 {
  color: rgb(153 27 27 / 0.15) !important;
}

.tw-text-red-800\/20 {
  color: rgb(153 27 27 / 0.2) !important;
}

.tw-text-red-800\/25 {
  color: rgb(153 27 27 / 0.25) !important;
}

.tw-text-red-800\/30 {
  color: rgb(153 27 27 / 0.3) !important;
}

.tw-text-red-800\/35 {
  color: rgb(153 27 27 / 0.35) !important;
}

.tw-text-red-800\/40 {
  color: rgb(153 27 27 / 0.4) !important;
}

.tw-text-red-800\/45 {
  color: rgb(153 27 27 / 0.45) !important;
}

.tw-text-red-800\/5 {
  color: rgb(153 27 27 / 0.05) !important;
}

.tw-text-red-800\/50 {
  color: rgb(153 27 27 / 0.5) !important;
}

.tw-text-red-800\/55 {
  color: rgb(153 27 27 / 0.55) !important;
}

.tw-text-red-800\/60 {
  color: rgb(153 27 27 / 0.6) !important;
}

.tw-text-red-800\/65 {
  color: rgb(153 27 27 / 0.65) !important;
}

.tw-text-red-800\/70 {
  color: rgb(153 27 27 / 0.7) !important;
}

.tw-text-red-800\/75 {
  color: rgb(153 27 27 / 0.75) !important;
}

.tw-text-red-800\/80 {
  color: rgb(153 27 27 / 0.8) !important;
}

.tw-text-red-800\/85 {
  color: rgb(153 27 27 / 0.85) !important;
}

.tw-text-red-800\/90 {
  color: rgb(153 27 27 / 0.9) !important;
}

.tw-text-red-800\/95 {
  color: rgb(153 27 27 / 0.95) !important;
}

.tw-text-red-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(127 29 29 / var(--tw-text-opacity)) !important;
}

.tw-text-red-900\/0 {
  color: rgb(127 29 29 / 0) !important;
}

.tw-text-red-900\/10 {
  color: rgb(127 29 29 / 0.1) !important;
}

.tw-text-red-900\/100 {
  color: rgb(127 29 29 / 1) !important;
}

.tw-text-red-900\/15 {
  color: rgb(127 29 29 / 0.15) !important;
}

.tw-text-red-900\/20 {
  color: rgb(127 29 29 / 0.2) !important;
}

.tw-text-red-900\/25 {
  color: rgb(127 29 29 / 0.25) !important;
}

.tw-text-red-900\/30 {
  color: rgb(127 29 29 / 0.3) !important;
}

.tw-text-red-900\/35 {
  color: rgb(127 29 29 / 0.35) !important;
}

.tw-text-red-900\/40 {
  color: rgb(127 29 29 / 0.4) !important;
}

.tw-text-red-900\/45 {
  color: rgb(127 29 29 / 0.45) !important;
}

.tw-text-red-900\/5 {
  color: rgb(127 29 29 / 0.05) !important;
}

.tw-text-red-900\/50 {
  color: rgb(127 29 29 / 0.5) !important;
}

.tw-text-red-900\/55 {
  color: rgb(127 29 29 / 0.55) !important;
}

.tw-text-red-900\/60 {
  color: rgb(127 29 29 / 0.6) !important;
}

.tw-text-red-900\/65 {
  color: rgb(127 29 29 / 0.65) !important;
}

.tw-text-red-900\/70 {
  color: rgb(127 29 29 / 0.7) !important;
}

.tw-text-red-900\/75 {
  color: rgb(127 29 29 / 0.75) !important;
}

.tw-text-red-900\/80 {
  color: rgb(127 29 29 / 0.8) !important;
}

.tw-text-red-900\/85 {
  color: rgb(127 29 29 / 0.85) !important;
}

.tw-text-red-900\/90 {
  color: rgb(127 29 29 / 0.9) !important;
}

.tw-text-red-900\/95 {
  color: rgb(127 29 29 / 0.95) !important;
}

.tw-text-red-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(69 10 10 / var(--tw-text-opacity)) !important;
}

.tw-text-red-950\/0 {
  color: rgb(69 10 10 / 0) !important;
}

.tw-text-red-950\/10 {
  color: rgb(69 10 10 / 0.1) !important;
}

.tw-text-red-950\/100 {
  color: rgb(69 10 10 / 1) !important;
}

.tw-text-red-950\/15 {
  color: rgb(69 10 10 / 0.15) !important;
}

.tw-text-red-950\/20 {
  color: rgb(69 10 10 / 0.2) !important;
}

.tw-text-red-950\/25 {
  color: rgb(69 10 10 / 0.25) !important;
}

.tw-text-red-950\/30 {
  color: rgb(69 10 10 / 0.3) !important;
}

.tw-text-red-950\/35 {
  color: rgb(69 10 10 / 0.35) !important;
}

.tw-text-red-950\/40 {
  color: rgb(69 10 10 / 0.4) !important;
}

.tw-text-red-950\/45 {
  color: rgb(69 10 10 / 0.45) !important;
}

.tw-text-red-950\/5 {
  color: rgb(69 10 10 / 0.05) !important;
}

.tw-text-red-950\/50 {
  color: rgb(69 10 10 / 0.5) !important;
}

.tw-text-red-950\/55 {
  color: rgb(69 10 10 / 0.55) !important;
}

.tw-text-red-950\/60 {
  color: rgb(69 10 10 / 0.6) !important;
}

.tw-text-red-950\/65 {
  color: rgb(69 10 10 / 0.65) !important;
}

.tw-text-red-950\/70 {
  color: rgb(69 10 10 / 0.7) !important;
}

.tw-text-red-950\/75 {
  color: rgb(69 10 10 / 0.75) !important;
}

.tw-text-red-950\/80 {
  color: rgb(69 10 10 / 0.8) !important;
}

.tw-text-red-950\/85 {
  color: rgb(69 10 10 / 0.85) !important;
}

.tw-text-red-950\/90 {
  color: rgb(69 10 10 / 0.9) !important;
}

.tw-text-red-950\/95 {
  color: rgb(69 10 10 / 0.95) !important;
}

.tw-text-rose-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 228 230 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-100\/0 {
  color: rgb(255 228 230 / 0) !important;
}

.tw-text-rose-100\/10 {
  color: rgb(255 228 230 / 0.1) !important;
}

.tw-text-rose-100\/100 {
  color: rgb(255 228 230 / 1) !important;
}

.tw-text-rose-100\/15 {
  color: rgb(255 228 230 / 0.15) !important;
}

.tw-text-rose-100\/20 {
  color: rgb(255 228 230 / 0.2) !important;
}

.tw-text-rose-100\/25 {
  color: rgb(255 228 230 / 0.25) !important;
}

.tw-text-rose-100\/30 {
  color: rgb(255 228 230 / 0.3) !important;
}

.tw-text-rose-100\/35 {
  color: rgb(255 228 230 / 0.35) !important;
}

.tw-text-rose-100\/40 {
  color: rgb(255 228 230 / 0.4) !important;
}

.tw-text-rose-100\/45 {
  color: rgb(255 228 230 / 0.45) !important;
}

.tw-text-rose-100\/5 {
  color: rgb(255 228 230 / 0.05) !important;
}

.tw-text-rose-100\/50 {
  color: rgb(255 228 230 / 0.5) !important;
}

.tw-text-rose-100\/55 {
  color: rgb(255 228 230 / 0.55) !important;
}

.tw-text-rose-100\/60 {
  color: rgb(255 228 230 / 0.6) !important;
}

.tw-text-rose-100\/65 {
  color: rgb(255 228 230 / 0.65) !important;
}

.tw-text-rose-100\/70 {
  color: rgb(255 228 230 / 0.7) !important;
}

.tw-text-rose-100\/75 {
  color: rgb(255 228 230 / 0.75) !important;
}

.tw-text-rose-100\/80 {
  color: rgb(255 228 230 / 0.8) !important;
}

.tw-text-rose-100\/85 {
  color: rgb(255 228 230 / 0.85) !important;
}

.tw-text-rose-100\/90 {
  color: rgb(255 228 230 / 0.9) !important;
}

.tw-text-rose-100\/95 {
  color: rgb(255 228 230 / 0.95) !important;
}

.tw-text-rose-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 205 211 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-200\/0 {
  color: rgb(254 205 211 / 0) !important;
}

.tw-text-rose-200\/10 {
  color: rgb(254 205 211 / 0.1) !important;
}

.tw-text-rose-200\/100 {
  color: rgb(254 205 211 / 1) !important;
}

.tw-text-rose-200\/15 {
  color: rgb(254 205 211 / 0.15) !important;
}

.tw-text-rose-200\/20 {
  color: rgb(254 205 211 / 0.2) !important;
}

.tw-text-rose-200\/25 {
  color: rgb(254 205 211 / 0.25) !important;
}

.tw-text-rose-200\/30 {
  color: rgb(254 205 211 / 0.3) !important;
}

.tw-text-rose-200\/35 {
  color: rgb(254 205 211 / 0.35) !important;
}

.tw-text-rose-200\/40 {
  color: rgb(254 205 211 / 0.4) !important;
}

.tw-text-rose-200\/45 {
  color: rgb(254 205 211 / 0.45) !important;
}

.tw-text-rose-200\/5 {
  color: rgb(254 205 211 / 0.05) !important;
}

.tw-text-rose-200\/50 {
  color: rgb(254 205 211 / 0.5) !important;
}

.tw-text-rose-200\/55 {
  color: rgb(254 205 211 / 0.55) !important;
}

.tw-text-rose-200\/60 {
  color: rgb(254 205 211 / 0.6) !important;
}

.tw-text-rose-200\/65 {
  color: rgb(254 205 211 / 0.65) !important;
}

.tw-text-rose-200\/70 {
  color: rgb(254 205 211 / 0.7) !important;
}

.tw-text-rose-200\/75 {
  color: rgb(254 205 211 / 0.75) !important;
}

.tw-text-rose-200\/80 {
  color: rgb(254 205 211 / 0.8) !important;
}

.tw-text-rose-200\/85 {
  color: rgb(254 205 211 / 0.85) !important;
}

.tw-text-rose-200\/90 {
  color: rgb(254 205 211 / 0.9) !important;
}

.tw-text-rose-200\/95 {
  color: rgb(254 205 211 / 0.95) !important;
}

.tw-text-rose-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 164 175 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-300\/0 {
  color: rgb(253 164 175 / 0) !important;
}

.tw-text-rose-300\/10 {
  color: rgb(253 164 175 / 0.1) !important;
}

.tw-text-rose-300\/100 {
  color: rgb(253 164 175 / 1) !important;
}

.tw-text-rose-300\/15 {
  color: rgb(253 164 175 / 0.15) !important;
}

.tw-text-rose-300\/20 {
  color: rgb(253 164 175 / 0.2) !important;
}

.tw-text-rose-300\/25 {
  color: rgb(253 164 175 / 0.25) !important;
}

.tw-text-rose-300\/30 {
  color: rgb(253 164 175 / 0.3) !important;
}

.tw-text-rose-300\/35 {
  color: rgb(253 164 175 / 0.35) !important;
}

.tw-text-rose-300\/40 {
  color: rgb(253 164 175 / 0.4) !important;
}

.tw-text-rose-300\/45 {
  color: rgb(253 164 175 / 0.45) !important;
}

.tw-text-rose-300\/5 {
  color: rgb(253 164 175 / 0.05) !important;
}

.tw-text-rose-300\/50 {
  color: rgb(253 164 175 / 0.5) !important;
}

.tw-text-rose-300\/55 {
  color: rgb(253 164 175 / 0.55) !important;
}

.tw-text-rose-300\/60 {
  color: rgb(253 164 175 / 0.6) !important;
}

.tw-text-rose-300\/65 {
  color: rgb(253 164 175 / 0.65) !important;
}

.tw-text-rose-300\/70 {
  color: rgb(253 164 175 / 0.7) !important;
}

.tw-text-rose-300\/75 {
  color: rgb(253 164 175 / 0.75) !important;
}

.tw-text-rose-300\/80 {
  color: rgb(253 164 175 / 0.8) !important;
}

.tw-text-rose-300\/85 {
  color: rgb(253 164 175 / 0.85) !important;
}

.tw-text-rose-300\/90 {
  color: rgb(253 164 175 / 0.9) !important;
}

.tw-text-rose-300\/95 {
  color: rgb(253 164 175 / 0.95) !important;
}

.tw-text-rose-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(251 113 133 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-400\/0 {
  color: rgb(251 113 133 / 0) !important;
}

.tw-text-rose-400\/10 {
  color: rgb(251 113 133 / 0.1) !important;
}

.tw-text-rose-400\/100 {
  color: rgb(251 113 133 / 1) !important;
}

.tw-text-rose-400\/15 {
  color: rgb(251 113 133 / 0.15) !important;
}

.tw-text-rose-400\/20 {
  color: rgb(251 113 133 / 0.2) !important;
}

.tw-text-rose-400\/25 {
  color: rgb(251 113 133 / 0.25) !important;
}

.tw-text-rose-400\/30 {
  color: rgb(251 113 133 / 0.3) !important;
}

.tw-text-rose-400\/35 {
  color: rgb(251 113 133 / 0.35) !important;
}

.tw-text-rose-400\/40 {
  color: rgb(251 113 133 / 0.4) !important;
}

.tw-text-rose-400\/45 {
  color: rgb(251 113 133 / 0.45) !important;
}

.tw-text-rose-400\/5 {
  color: rgb(251 113 133 / 0.05) !important;
}

.tw-text-rose-400\/50 {
  color: rgb(251 113 133 / 0.5) !important;
}

.tw-text-rose-400\/55 {
  color: rgb(251 113 133 / 0.55) !important;
}

.tw-text-rose-400\/60 {
  color: rgb(251 113 133 / 0.6) !important;
}

.tw-text-rose-400\/65 {
  color: rgb(251 113 133 / 0.65) !important;
}

.tw-text-rose-400\/70 {
  color: rgb(251 113 133 / 0.7) !important;
}

.tw-text-rose-400\/75 {
  color: rgb(251 113 133 / 0.75) !important;
}

.tw-text-rose-400\/80 {
  color: rgb(251 113 133 / 0.8) !important;
}

.tw-text-rose-400\/85 {
  color: rgb(251 113 133 / 0.85) !important;
}

.tw-text-rose-400\/90 {
  color: rgb(251 113 133 / 0.9) !important;
}

.tw-text-rose-400\/95 {
  color: rgb(251 113 133 / 0.95) !important;
}

.tw-text-rose-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(255 241 242 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-50\/0 {
  color: rgb(255 241 242 / 0) !important;
}

.tw-text-rose-50\/10 {
  color: rgb(255 241 242 / 0.1) !important;
}

.tw-text-rose-50\/100 {
  color: rgb(255 241 242 / 1) !important;
}

.tw-text-rose-50\/15 {
  color: rgb(255 241 242 / 0.15) !important;
}

.tw-text-rose-50\/20 {
  color: rgb(255 241 242 / 0.2) !important;
}

.tw-text-rose-50\/25 {
  color: rgb(255 241 242 / 0.25) !important;
}

.tw-text-rose-50\/30 {
  color: rgb(255 241 242 / 0.3) !important;
}

.tw-text-rose-50\/35 {
  color: rgb(255 241 242 / 0.35) !important;
}

.tw-text-rose-50\/40 {
  color: rgb(255 241 242 / 0.4) !important;
}

.tw-text-rose-50\/45 {
  color: rgb(255 241 242 / 0.45) !important;
}

.tw-text-rose-50\/5 {
  color: rgb(255 241 242 / 0.05) !important;
}

.tw-text-rose-50\/50 {
  color: rgb(255 241 242 / 0.5) !important;
}

.tw-text-rose-50\/55 {
  color: rgb(255 241 242 / 0.55) !important;
}

.tw-text-rose-50\/60 {
  color: rgb(255 241 242 / 0.6) !important;
}

.tw-text-rose-50\/65 {
  color: rgb(255 241 242 / 0.65) !important;
}

.tw-text-rose-50\/70 {
  color: rgb(255 241 242 / 0.7) !important;
}

.tw-text-rose-50\/75 {
  color: rgb(255 241 242 / 0.75) !important;
}

.tw-text-rose-50\/80 {
  color: rgb(255 241 242 / 0.8) !important;
}

.tw-text-rose-50\/85 {
  color: rgb(255 241 242 / 0.85) !important;
}

.tw-text-rose-50\/90 {
  color: rgb(255 241 242 / 0.9) !important;
}

.tw-text-rose-50\/95 {
  color: rgb(255 241 242 / 0.95) !important;
}

.tw-text-rose-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(244 63 94 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-500\/0 {
  color: rgb(244 63 94 / 0) !important;
}

.tw-text-rose-500\/10 {
  color: rgb(244 63 94 / 0.1) !important;
}

.tw-text-rose-500\/100 {
  color: rgb(244 63 94 / 1) !important;
}

.tw-text-rose-500\/15 {
  color: rgb(244 63 94 / 0.15) !important;
}

.tw-text-rose-500\/20 {
  color: rgb(244 63 94 / 0.2) !important;
}

.tw-text-rose-500\/25 {
  color: rgb(244 63 94 / 0.25) !important;
}

.tw-text-rose-500\/30 {
  color: rgb(244 63 94 / 0.3) !important;
}

.tw-text-rose-500\/35 {
  color: rgb(244 63 94 / 0.35) !important;
}

.tw-text-rose-500\/40 {
  color: rgb(244 63 94 / 0.4) !important;
}

.tw-text-rose-500\/45 {
  color: rgb(244 63 94 / 0.45) !important;
}

.tw-text-rose-500\/5 {
  color: rgb(244 63 94 / 0.05) !important;
}

.tw-text-rose-500\/50 {
  color: rgb(244 63 94 / 0.5) !important;
}

.tw-text-rose-500\/55 {
  color: rgb(244 63 94 / 0.55) !important;
}

.tw-text-rose-500\/60 {
  color: rgb(244 63 94 / 0.6) !important;
}

.tw-text-rose-500\/65 {
  color: rgb(244 63 94 / 0.65) !important;
}

.tw-text-rose-500\/70 {
  color: rgb(244 63 94 / 0.7) !important;
}

.tw-text-rose-500\/75 {
  color: rgb(244 63 94 / 0.75) !important;
}

.tw-text-rose-500\/80 {
  color: rgb(244 63 94 / 0.8) !important;
}

.tw-text-rose-500\/85 {
  color: rgb(244 63 94 / 0.85) !important;
}

.tw-text-rose-500\/90 {
  color: rgb(244 63 94 / 0.9) !important;
}

.tw-text-rose-500\/95 {
  color: rgb(244 63 94 / 0.95) !important;
}

.tw-text-rose-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(225 29 72 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-600\/0 {
  color: rgb(225 29 72 / 0) !important;
}

.tw-text-rose-600\/10 {
  color: rgb(225 29 72 / 0.1) !important;
}

.tw-text-rose-600\/100 {
  color: rgb(225 29 72 / 1) !important;
}

.tw-text-rose-600\/15 {
  color: rgb(225 29 72 / 0.15) !important;
}

.tw-text-rose-600\/20 {
  color: rgb(225 29 72 / 0.2) !important;
}

.tw-text-rose-600\/25 {
  color: rgb(225 29 72 / 0.25) !important;
}

.tw-text-rose-600\/30 {
  color: rgb(225 29 72 / 0.3) !important;
}

.tw-text-rose-600\/35 {
  color: rgb(225 29 72 / 0.35) !important;
}

.tw-text-rose-600\/40 {
  color: rgb(225 29 72 / 0.4) !important;
}

.tw-text-rose-600\/45 {
  color: rgb(225 29 72 / 0.45) !important;
}

.tw-text-rose-600\/5 {
  color: rgb(225 29 72 / 0.05) !important;
}

.tw-text-rose-600\/50 {
  color: rgb(225 29 72 / 0.5) !important;
}

.tw-text-rose-600\/55 {
  color: rgb(225 29 72 / 0.55) !important;
}

.tw-text-rose-600\/60 {
  color: rgb(225 29 72 / 0.6) !important;
}

.tw-text-rose-600\/65 {
  color: rgb(225 29 72 / 0.65) !important;
}

.tw-text-rose-600\/70 {
  color: rgb(225 29 72 / 0.7) !important;
}

.tw-text-rose-600\/75 {
  color: rgb(225 29 72 / 0.75) !important;
}

.tw-text-rose-600\/80 {
  color: rgb(225 29 72 / 0.8) !important;
}

.tw-text-rose-600\/85 {
  color: rgb(225 29 72 / 0.85) !important;
}

.tw-text-rose-600\/90 {
  color: rgb(225 29 72 / 0.9) !important;
}

.tw-text-rose-600\/95 {
  color: rgb(225 29 72 / 0.95) !important;
}

.tw-text-rose-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(190 18 60 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-700\/0 {
  color: rgb(190 18 60 / 0) !important;
}

.tw-text-rose-700\/10 {
  color: rgb(190 18 60 / 0.1) !important;
}

.tw-text-rose-700\/100 {
  color: rgb(190 18 60 / 1) !important;
}

.tw-text-rose-700\/15 {
  color: rgb(190 18 60 / 0.15) !important;
}

.tw-text-rose-700\/20 {
  color: rgb(190 18 60 / 0.2) !important;
}

.tw-text-rose-700\/25 {
  color: rgb(190 18 60 / 0.25) !important;
}

.tw-text-rose-700\/30 {
  color: rgb(190 18 60 / 0.3) !important;
}

.tw-text-rose-700\/35 {
  color: rgb(190 18 60 / 0.35) !important;
}

.tw-text-rose-700\/40 {
  color: rgb(190 18 60 / 0.4) !important;
}

.tw-text-rose-700\/45 {
  color: rgb(190 18 60 / 0.45) !important;
}

.tw-text-rose-700\/5 {
  color: rgb(190 18 60 / 0.05) !important;
}

.tw-text-rose-700\/50 {
  color: rgb(190 18 60 / 0.5) !important;
}

.tw-text-rose-700\/55 {
  color: rgb(190 18 60 / 0.55) !important;
}

.tw-text-rose-700\/60 {
  color: rgb(190 18 60 / 0.6) !important;
}

.tw-text-rose-700\/65 {
  color: rgb(190 18 60 / 0.65) !important;
}

.tw-text-rose-700\/70 {
  color: rgb(190 18 60 / 0.7) !important;
}

.tw-text-rose-700\/75 {
  color: rgb(190 18 60 / 0.75) !important;
}

.tw-text-rose-700\/80 {
  color: rgb(190 18 60 / 0.8) !important;
}

.tw-text-rose-700\/85 {
  color: rgb(190 18 60 / 0.85) !important;
}

.tw-text-rose-700\/90 {
  color: rgb(190 18 60 / 0.9) !important;
}

.tw-text-rose-700\/95 {
  color: rgb(190 18 60 / 0.95) !important;
}

.tw-text-rose-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(159 18 57 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-800\/0 {
  color: rgb(159 18 57 / 0) !important;
}

.tw-text-rose-800\/10 {
  color: rgb(159 18 57 / 0.1) !important;
}

.tw-text-rose-800\/100 {
  color: rgb(159 18 57 / 1) !important;
}

.tw-text-rose-800\/15 {
  color: rgb(159 18 57 / 0.15) !important;
}

.tw-text-rose-800\/20 {
  color: rgb(159 18 57 / 0.2) !important;
}

.tw-text-rose-800\/25 {
  color: rgb(159 18 57 / 0.25) !important;
}

.tw-text-rose-800\/30 {
  color: rgb(159 18 57 / 0.3) !important;
}

.tw-text-rose-800\/35 {
  color: rgb(159 18 57 / 0.35) !important;
}

.tw-text-rose-800\/40 {
  color: rgb(159 18 57 / 0.4) !important;
}

.tw-text-rose-800\/45 {
  color: rgb(159 18 57 / 0.45) !important;
}

.tw-text-rose-800\/5 {
  color: rgb(159 18 57 / 0.05) !important;
}

.tw-text-rose-800\/50 {
  color: rgb(159 18 57 / 0.5) !important;
}

.tw-text-rose-800\/55 {
  color: rgb(159 18 57 / 0.55) !important;
}

.tw-text-rose-800\/60 {
  color: rgb(159 18 57 / 0.6) !important;
}

.tw-text-rose-800\/65 {
  color: rgb(159 18 57 / 0.65) !important;
}

.tw-text-rose-800\/70 {
  color: rgb(159 18 57 / 0.7) !important;
}

.tw-text-rose-800\/75 {
  color: rgb(159 18 57 / 0.75) !important;
}

.tw-text-rose-800\/80 {
  color: rgb(159 18 57 / 0.8) !important;
}

.tw-text-rose-800\/85 {
  color: rgb(159 18 57 / 0.85) !important;
}

.tw-text-rose-800\/90 {
  color: rgb(159 18 57 / 0.9) !important;
}

.tw-text-rose-800\/95 {
  color: rgb(159 18 57 / 0.95) !important;
}

.tw-text-rose-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(136 19 55 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-900\/0 {
  color: rgb(136 19 55 / 0) !important;
}

.tw-text-rose-900\/10 {
  color: rgb(136 19 55 / 0.1) !important;
}

.tw-text-rose-900\/100 {
  color: rgb(136 19 55 / 1) !important;
}

.tw-text-rose-900\/15 {
  color: rgb(136 19 55 / 0.15) !important;
}

.tw-text-rose-900\/20 {
  color: rgb(136 19 55 / 0.2) !important;
}

.tw-text-rose-900\/25 {
  color: rgb(136 19 55 / 0.25) !important;
}

.tw-text-rose-900\/30 {
  color: rgb(136 19 55 / 0.3) !important;
}

.tw-text-rose-900\/35 {
  color: rgb(136 19 55 / 0.35) !important;
}

.tw-text-rose-900\/40 {
  color: rgb(136 19 55 / 0.4) !important;
}

.tw-text-rose-900\/45 {
  color: rgb(136 19 55 / 0.45) !important;
}

.tw-text-rose-900\/5 {
  color: rgb(136 19 55 / 0.05) !important;
}

.tw-text-rose-900\/50 {
  color: rgb(136 19 55 / 0.5) !important;
}

.tw-text-rose-900\/55 {
  color: rgb(136 19 55 / 0.55) !important;
}

.tw-text-rose-900\/60 {
  color: rgb(136 19 55 / 0.6) !important;
}

.tw-text-rose-900\/65 {
  color: rgb(136 19 55 / 0.65) !important;
}

.tw-text-rose-900\/70 {
  color: rgb(136 19 55 / 0.7) !important;
}

.tw-text-rose-900\/75 {
  color: rgb(136 19 55 / 0.75) !important;
}

.tw-text-rose-900\/80 {
  color: rgb(136 19 55 / 0.8) !important;
}

.tw-text-rose-900\/85 {
  color: rgb(136 19 55 / 0.85) !important;
}

.tw-text-rose-900\/90 {
  color: rgb(136 19 55 / 0.9) !important;
}

.tw-text-rose-900\/95 {
  color: rgb(136 19 55 / 0.95) !important;
}

.tw-text-rose-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(76 5 25 / var(--tw-text-opacity)) !important;
}

.tw-text-rose-950\/0 {
  color: rgb(76 5 25 / 0) !important;
}

.tw-text-rose-950\/10 {
  color: rgb(76 5 25 / 0.1) !important;
}

.tw-text-rose-950\/100 {
  color: rgb(76 5 25 / 1) !important;
}

.tw-text-rose-950\/15 {
  color: rgb(76 5 25 / 0.15) !important;
}

.tw-text-rose-950\/20 {
  color: rgb(76 5 25 / 0.2) !important;
}

.tw-text-rose-950\/25 {
  color: rgb(76 5 25 / 0.25) !important;
}

.tw-text-rose-950\/30 {
  color: rgb(76 5 25 / 0.3) !important;
}

.tw-text-rose-950\/35 {
  color: rgb(76 5 25 / 0.35) !important;
}

.tw-text-rose-950\/40 {
  color: rgb(76 5 25 / 0.4) !important;
}

.tw-text-rose-950\/45 {
  color: rgb(76 5 25 / 0.45) !important;
}

.tw-text-rose-950\/5 {
  color: rgb(76 5 25 / 0.05) !important;
}

.tw-text-rose-950\/50 {
  color: rgb(76 5 25 / 0.5) !important;
}

.tw-text-rose-950\/55 {
  color: rgb(76 5 25 / 0.55) !important;
}

.tw-text-rose-950\/60 {
  color: rgb(76 5 25 / 0.6) !important;
}

.tw-text-rose-950\/65 {
  color: rgb(76 5 25 / 0.65) !important;
}

.tw-text-rose-950\/70 {
  color: rgb(76 5 25 / 0.7) !important;
}

.tw-text-rose-950\/75 {
  color: rgb(76 5 25 / 0.75) !important;
}

.tw-text-rose-950\/80 {
  color: rgb(76 5 25 / 0.8) !important;
}

.tw-text-rose-950\/85 {
  color: rgb(76 5 25 / 0.85) !important;
}

.tw-text-rose-950\/90 {
  color: rgb(76 5 25 / 0.9) !important;
}

.tw-text-rose-950\/95 {
  color: rgb(76 5 25 / 0.95) !important;
}

.tw-text-sky-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(224 242 254 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-100\/0 {
  color: rgb(224 242 254 / 0) !important;
}

.tw-text-sky-100\/10 {
  color: rgb(224 242 254 / 0.1) !important;
}

.tw-text-sky-100\/100 {
  color: rgb(224 242 254 / 1) !important;
}

.tw-text-sky-100\/15 {
  color: rgb(224 242 254 / 0.15) !important;
}

.tw-text-sky-100\/20 {
  color: rgb(224 242 254 / 0.2) !important;
}

.tw-text-sky-100\/25 {
  color: rgb(224 242 254 / 0.25) !important;
}

.tw-text-sky-100\/30 {
  color: rgb(224 242 254 / 0.3) !important;
}

.tw-text-sky-100\/35 {
  color: rgb(224 242 254 / 0.35) !important;
}

.tw-text-sky-100\/40 {
  color: rgb(224 242 254 / 0.4) !important;
}

.tw-text-sky-100\/45 {
  color: rgb(224 242 254 / 0.45) !important;
}

.tw-text-sky-100\/5 {
  color: rgb(224 242 254 / 0.05) !important;
}

.tw-text-sky-100\/50 {
  color: rgb(224 242 254 / 0.5) !important;
}

.tw-text-sky-100\/55 {
  color: rgb(224 242 254 / 0.55) !important;
}

.tw-text-sky-100\/60 {
  color: rgb(224 242 254 / 0.6) !important;
}

.tw-text-sky-100\/65 {
  color: rgb(224 242 254 / 0.65) !important;
}

.tw-text-sky-100\/70 {
  color: rgb(224 242 254 / 0.7) !important;
}

.tw-text-sky-100\/75 {
  color: rgb(224 242 254 / 0.75) !important;
}

.tw-text-sky-100\/80 {
  color: rgb(224 242 254 / 0.8) !important;
}

.tw-text-sky-100\/85 {
  color: rgb(224 242 254 / 0.85) !important;
}

.tw-text-sky-100\/90 {
  color: rgb(224 242 254 / 0.9) !important;
}

.tw-text-sky-100\/95 {
  color: rgb(224 242 254 / 0.95) !important;
}

.tw-text-sky-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(186 230 253 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-200\/0 {
  color: rgb(186 230 253 / 0) !important;
}

.tw-text-sky-200\/10 {
  color: rgb(186 230 253 / 0.1) !important;
}

.tw-text-sky-200\/100 {
  color: rgb(186 230 253 / 1) !important;
}

.tw-text-sky-200\/15 {
  color: rgb(186 230 253 / 0.15) !important;
}

.tw-text-sky-200\/20 {
  color: rgb(186 230 253 / 0.2) !important;
}

.tw-text-sky-200\/25 {
  color: rgb(186 230 253 / 0.25) !important;
}

.tw-text-sky-200\/30 {
  color: rgb(186 230 253 / 0.3) !important;
}

.tw-text-sky-200\/35 {
  color: rgb(186 230 253 / 0.35) !important;
}

.tw-text-sky-200\/40 {
  color: rgb(186 230 253 / 0.4) !important;
}

.tw-text-sky-200\/45 {
  color: rgb(186 230 253 / 0.45) !important;
}

.tw-text-sky-200\/5 {
  color: rgb(186 230 253 / 0.05) !important;
}

.tw-text-sky-200\/50 {
  color: rgb(186 230 253 / 0.5) !important;
}

.tw-text-sky-200\/55 {
  color: rgb(186 230 253 / 0.55) !important;
}

.tw-text-sky-200\/60 {
  color: rgb(186 230 253 / 0.6) !important;
}

.tw-text-sky-200\/65 {
  color: rgb(186 230 253 / 0.65) !important;
}

.tw-text-sky-200\/70 {
  color: rgb(186 230 253 / 0.7) !important;
}

.tw-text-sky-200\/75 {
  color: rgb(186 230 253 / 0.75) !important;
}

.tw-text-sky-200\/80 {
  color: rgb(186 230 253 / 0.8) !important;
}

.tw-text-sky-200\/85 {
  color: rgb(186 230 253 / 0.85) !important;
}

.tw-text-sky-200\/90 {
  color: rgb(186 230 253 / 0.9) !important;
}

.tw-text-sky-200\/95 {
  color: rgb(186 230 253 / 0.95) !important;
}

.tw-text-sky-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(125 211 252 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-300\/0 {
  color: rgb(125 211 252 / 0) !important;
}

.tw-text-sky-300\/10 {
  color: rgb(125 211 252 / 0.1) !important;
}

.tw-text-sky-300\/100 {
  color: rgb(125 211 252 / 1) !important;
}

.tw-text-sky-300\/15 {
  color: rgb(125 211 252 / 0.15) !important;
}

.tw-text-sky-300\/20 {
  color: rgb(125 211 252 / 0.2) !important;
}

.tw-text-sky-300\/25 {
  color: rgb(125 211 252 / 0.25) !important;
}

.tw-text-sky-300\/30 {
  color: rgb(125 211 252 / 0.3) !important;
}

.tw-text-sky-300\/35 {
  color: rgb(125 211 252 / 0.35) !important;
}

.tw-text-sky-300\/40 {
  color: rgb(125 211 252 / 0.4) !important;
}

.tw-text-sky-300\/45 {
  color: rgb(125 211 252 / 0.45) !important;
}

.tw-text-sky-300\/5 {
  color: rgb(125 211 252 / 0.05) !important;
}

.tw-text-sky-300\/50 {
  color: rgb(125 211 252 / 0.5) !important;
}

.tw-text-sky-300\/55 {
  color: rgb(125 211 252 / 0.55) !important;
}

.tw-text-sky-300\/60 {
  color: rgb(125 211 252 / 0.6) !important;
}

.tw-text-sky-300\/65 {
  color: rgb(125 211 252 / 0.65) !important;
}

.tw-text-sky-300\/70 {
  color: rgb(125 211 252 / 0.7) !important;
}

.tw-text-sky-300\/75 {
  color: rgb(125 211 252 / 0.75) !important;
}

.tw-text-sky-300\/80 {
  color: rgb(125 211 252 / 0.8) !important;
}

.tw-text-sky-300\/85 {
  color: rgb(125 211 252 / 0.85) !important;
}

.tw-text-sky-300\/90 {
  color: rgb(125 211 252 / 0.9) !important;
}

.tw-text-sky-300\/95 {
  color: rgb(125 211 252 / 0.95) !important;
}

.tw-text-sky-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(56 189 248 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-400\/0 {
  color: rgb(56 189 248 / 0) !important;
}

.tw-text-sky-400\/10 {
  color: rgb(56 189 248 / 0.1) !important;
}

.tw-text-sky-400\/100 {
  color: rgb(56 189 248 / 1) !important;
}

.tw-text-sky-400\/15 {
  color: rgb(56 189 248 / 0.15) !important;
}

.tw-text-sky-400\/20 {
  color: rgb(56 189 248 / 0.2) !important;
}

.tw-text-sky-400\/25 {
  color: rgb(56 189 248 / 0.25) !important;
}

.tw-text-sky-400\/30 {
  color: rgb(56 189 248 / 0.3) !important;
}

.tw-text-sky-400\/35 {
  color: rgb(56 189 248 / 0.35) !important;
}

.tw-text-sky-400\/40 {
  color: rgb(56 189 248 / 0.4) !important;
}

.tw-text-sky-400\/45 {
  color: rgb(56 189 248 / 0.45) !important;
}

.tw-text-sky-400\/5 {
  color: rgb(56 189 248 / 0.05) !important;
}

.tw-text-sky-400\/50 {
  color: rgb(56 189 248 / 0.5) !important;
}

.tw-text-sky-400\/55 {
  color: rgb(56 189 248 / 0.55) !important;
}

.tw-text-sky-400\/60 {
  color: rgb(56 189 248 / 0.6) !important;
}

.tw-text-sky-400\/65 {
  color: rgb(56 189 248 / 0.65) !important;
}

.tw-text-sky-400\/70 {
  color: rgb(56 189 248 / 0.7) !important;
}

.tw-text-sky-400\/75 {
  color: rgb(56 189 248 / 0.75) !important;
}

.tw-text-sky-400\/80 {
  color: rgb(56 189 248 / 0.8) !important;
}

.tw-text-sky-400\/85 {
  color: rgb(56 189 248 / 0.85) !important;
}

.tw-text-sky-400\/90 {
  color: rgb(56 189 248 / 0.9) !important;
}

.tw-text-sky-400\/95 {
  color: rgb(56 189 248 / 0.95) !important;
}

.tw-text-sky-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(240 249 255 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-50\/0 {
  color: rgb(240 249 255 / 0) !important;
}

.tw-text-sky-50\/10 {
  color: rgb(240 249 255 / 0.1) !important;
}

.tw-text-sky-50\/100 {
  color: rgb(240 249 255 / 1) !important;
}

.tw-text-sky-50\/15 {
  color: rgb(240 249 255 / 0.15) !important;
}

.tw-text-sky-50\/20 {
  color: rgb(240 249 255 / 0.2) !important;
}

.tw-text-sky-50\/25 {
  color: rgb(240 249 255 / 0.25) !important;
}

.tw-text-sky-50\/30 {
  color: rgb(240 249 255 / 0.3) !important;
}

.tw-text-sky-50\/35 {
  color: rgb(240 249 255 / 0.35) !important;
}

.tw-text-sky-50\/40 {
  color: rgb(240 249 255 / 0.4) !important;
}

.tw-text-sky-50\/45 {
  color: rgb(240 249 255 / 0.45) !important;
}

.tw-text-sky-50\/5 {
  color: rgb(240 249 255 / 0.05) !important;
}

.tw-text-sky-50\/50 {
  color: rgb(240 249 255 / 0.5) !important;
}

.tw-text-sky-50\/55 {
  color: rgb(240 249 255 / 0.55) !important;
}

.tw-text-sky-50\/60 {
  color: rgb(240 249 255 / 0.6) !important;
}

.tw-text-sky-50\/65 {
  color: rgb(240 249 255 / 0.65) !important;
}

.tw-text-sky-50\/70 {
  color: rgb(240 249 255 / 0.7) !important;
}

.tw-text-sky-50\/75 {
  color: rgb(240 249 255 / 0.75) !important;
}

.tw-text-sky-50\/80 {
  color: rgb(240 249 255 / 0.8) !important;
}

.tw-text-sky-50\/85 {
  color: rgb(240 249 255 / 0.85) !important;
}

.tw-text-sky-50\/90 {
  color: rgb(240 249 255 / 0.9) !important;
}

.tw-text-sky-50\/95 {
  color: rgb(240 249 255 / 0.95) !important;
}

.tw-text-sky-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(14 165 233 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-500\/0 {
  color: rgb(14 165 233 / 0) !important;
}

.tw-text-sky-500\/10 {
  color: rgb(14 165 233 / 0.1) !important;
}

.tw-text-sky-500\/100 {
  color: rgb(14 165 233 / 1) !important;
}

.tw-text-sky-500\/15 {
  color: rgb(14 165 233 / 0.15) !important;
}

.tw-text-sky-500\/20 {
  color: rgb(14 165 233 / 0.2) !important;
}

.tw-text-sky-500\/25 {
  color: rgb(14 165 233 / 0.25) !important;
}

.tw-text-sky-500\/30 {
  color: rgb(14 165 233 / 0.3) !important;
}

.tw-text-sky-500\/35 {
  color: rgb(14 165 233 / 0.35) !important;
}

.tw-text-sky-500\/40 {
  color: rgb(14 165 233 / 0.4) !important;
}

.tw-text-sky-500\/45 {
  color: rgb(14 165 233 / 0.45) !important;
}

.tw-text-sky-500\/5 {
  color: rgb(14 165 233 / 0.05) !important;
}

.tw-text-sky-500\/50 {
  color: rgb(14 165 233 / 0.5) !important;
}

.tw-text-sky-500\/55 {
  color: rgb(14 165 233 / 0.55) !important;
}

.tw-text-sky-500\/60 {
  color: rgb(14 165 233 / 0.6) !important;
}

.tw-text-sky-500\/65 {
  color: rgb(14 165 233 / 0.65) !important;
}

.tw-text-sky-500\/70 {
  color: rgb(14 165 233 / 0.7) !important;
}

.tw-text-sky-500\/75 {
  color: rgb(14 165 233 / 0.75) !important;
}

.tw-text-sky-500\/80 {
  color: rgb(14 165 233 / 0.8) !important;
}

.tw-text-sky-500\/85 {
  color: rgb(14 165 233 / 0.85) !important;
}

.tw-text-sky-500\/90 {
  color: rgb(14 165 233 / 0.9) !important;
}

.tw-text-sky-500\/95 {
  color: rgb(14 165 233 / 0.95) !important;
}

.tw-text-sky-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(2 132 199 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-600\/0 {
  color: rgb(2 132 199 / 0) !important;
}

.tw-text-sky-600\/10 {
  color: rgb(2 132 199 / 0.1) !important;
}

.tw-text-sky-600\/100 {
  color: rgb(2 132 199 / 1) !important;
}

.tw-text-sky-600\/15 {
  color: rgb(2 132 199 / 0.15) !important;
}

.tw-text-sky-600\/20 {
  color: rgb(2 132 199 / 0.2) !important;
}

.tw-text-sky-600\/25 {
  color: rgb(2 132 199 / 0.25) !important;
}

.tw-text-sky-600\/30 {
  color: rgb(2 132 199 / 0.3) !important;
}

.tw-text-sky-600\/35 {
  color: rgb(2 132 199 / 0.35) !important;
}

.tw-text-sky-600\/40 {
  color: rgb(2 132 199 / 0.4) !important;
}

.tw-text-sky-600\/45 {
  color: rgb(2 132 199 / 0.45) !important;
}

.tw-text-sky-600\/5 {
  color: rgb(2 132 199 / 0.05) !important;
}

.tw-text-sky-600\/50 {
  color: rgb(2 132 199 / 0.5) !important;
}

.tw-text-sky-600\/55 {
  color: rgb(2 132 199 / 0.55) !important;
}

.tw-text-sky-600\/60 {
  color: rgb(2 132 199 / 0.6) !important;
}

.tw-text-sky-600\/65 {
  color: rgb(2 132 199 / 0.65) !important;
}

.tw-text-sky-600\/70 {
  color: rgb(2 132 199 / 0.7) !important;
}

.tw-text-sky-600\/75 {
  color: rgb(2 132 199 / 0.75) !important;
}

.tw-text-sky-600\/80 {
  color: rgb(2 132 199 / 0.8) !important;
}

.tw-text-sky-600\/85 {
  color: rgb(2 132 199 / 0.85) !important;
}

.tw-text-sky-600\/90 {
  color: rgb(2 132 199 / 0.9) !important;
}

.tw-text-sky-600\/95 {
  color: rgb(2 132 199 / 0.95) !important;
}

.tw-text-sky-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(3 105 161 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-700\/0 {
  color: rgb(3 105 161 / 0) !important;
}

.tw-text-sky-700\/10 {
  color: rgb(3 105 161 / 0.1) !important;
}

.tw-text-sky-700\/100 {
  color: rgb(3 105 161 / 1) !important;
}

.tw-text-sky-700\/15 {
  color: rgb(3 105 161 / 0.15) !important;
}

.tw-text-sky-700\/20 {
  color: rgb(3 105 161 / 0.2) !important;
}

.tw-text-sky-700\/25 {
  color: rgb(3 105 161 / 0.25) !important;
}

.tw-text-sky-700\/30 {
  color: rgb(3 105 161 / 0.3) !important;
}

.tw-text-sky-700\/35 {
  color: rgb(3 105 161 / 0.35) !important;
}

.tw-text-sky-700\/40 {
  color: rgb(3 105 161 / 0.4) !important;
}

.tw-text-sky-700\/45 {
  color: rgb(3 105 161 / 0.45) !important;
}

.tw-text-sky-700\/5 {
  color: rgb(3 105 161 / 0.05) !important;
}

.tw-text-sky-700\/50 {
  color: rgb(3 105 161 / 0.5) !important;
}

.tw-text-sky-700\/55 {
  color: rgb(3 105 161 / 0.55) !important;
}

.tw-text-sky-700\/60 {
  color: rgb(3 105 161 / 0.6) !important;
}

.tw-text-sky-700\/65 {
  color: rgb(3 105 161 / 0.65) !important;
}

.tw-text-sky-700\/70 {
  color: rgb(3 105 161 / 0.7) !important;
}

.tw-text-sky-700\/75 {
  color: rgb(3 105 161 / 0.75) !important;
}

.tw-text-sky-700\/80 {
  color: rgb(3 105 161 / 0.8) !important;
}

.tw-text-sky-700\/85 {
  color: rgb(3 105 161 / 0.85) !important;
}

.tw-text-sky-700\/90 {
  color: rgb(3 105 161 / 0.9) !important;
}

.tw-text-sky-700\/95 {
  color: rgb(3 105 161 / 0.95) !important;
}

.tw-text-sky-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(7 89 133 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-800\/0 {
  color: rgb(7 89 133 / 0) !important;
}

.tw-text-sky-800\/10 {
  color: rgb(7 89 133 / 0.1) !important;
}

.tw-text-sky-800\/100 {
  color: rgb(7 89 133 / 1) !important;
}

.tw-text-sky-800\/15 {
  color: rgb(7 89 133 / 0.15) !important;
}

.tw-text-sky-800\/20 {
  color: rgb(7 89 133 / 0.2) !important;
}

.tw-text-sky-800\/25 {
  color: rgb(7 89 133 / 0.25) !important;
}

.tw-text-sky-800\/30 {
  color: rgb(7 89 133 / 0.3) !important;
}

.tw-text-sky-800\/35 {
  color: rgb(7 89 133 / 0.35) !important;
}

.tw-text-sky-800\/40 {
  color: rgb(7 89 133 / 0.4) !important;
}

.tw-text-sky-800\/45 {
  color: rgb(7 89 133 / 0.45) !important;
}

.tw-text-sky-800\/5 {
  color: rgb(7 89 133 / 0.05) !important;
}

.tw-text-sky-800\/50 {
  color: rgb(7 89 133 / 0.5) !important;
}

.tw-text-sky-800\/55 {
  color: rgb(7 89 133 / 0.55) !important;
}

.tw-text-sky-800\/60 {
  color: rgb(7 89 133 / 0.6) !important;
}

.tw-text-sky-800\/65 {
  color: rgb(7 89 133 / 0.65) !important;
}

.tw-text-sky-800\/70 {
  color: rgb(7 89 133 / 0.7) !important;
}

.tw-text-sky-800\/75 {
  color: rgb(7 89 133 / 0.75) !important;
}

.tw-text-sky-800\/80 {
  color: rgb(7 89 133 / 0.8) !important;
}

.tw-text-sky-800\/85 {
  color: rgb(7 89 133 / 0.85) !important;
}

.tw-text-sky-800\/90 {
  color: rgb(7 89 133 / 0.9) !important;
}

.tw-text-sky-800\/95 {
  color: rgb(7 89 133 / 0.95) !important;
}

.tw-text-sky-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(12 74 110 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-900\/0 {
  color: rgb(12 74 110 / 0) !important;
}

.tw-text-sky-900\/10 {
  color: rgb(12 74 110 / 0.1) !important;
}

.tw-text-sky-900\/100 {
  color: rgb(12 74 110 / 1) !important;
}

.tw-text-sky-900\/15 {
  color: rgb(12 74 110 / 0.15) !important;
}

.tw-text-sky-900\/20 {
  color: rgb(12 74 110 / 0.2) !important;
}

.tw-text-sky-900\/25 {
  color: rgb(12 74 110 / 0.25) !important;
}

.tw-text-sky-900\/30 {
  color: rgb(12 74 110 / 0.3) !important;
}

.tw-text-sky-900\/35 {
  color: rgb(12 74 110 / 0.35) !important;
}

.tw-text-sky-900\/40 {
  color: rgb(12 74 110 / 0.4) !important;
}

.tw-text-sky-900\/45 {
  color: rgb(12 74 110 / 0.45) !important;
}

.tw-text-sky-900\/5 {
  color: rgb(12 74 110 / 0.05) !important;
}

.tw-text-sky-900\/50 {
  color: rgb(12 74 110 / 0.5) !important;
}

.tw-text-sky-900\/55 {
  color: rgb(12 74 110 / 0.55) !important;
}

.tw-text-sky-900\/60 {
  color: rgb(12 74 110 / 0.6) !important;
}

.tw-text-sky-900\/65 {
  color: rgb(12 74 110 / 0.65) !important;
}

.tw-text-sky-900\/70 {
  color: rgb(12 74 110 / 0.7) !important;
}

.tw-text-sky-900\/75 {
  color: rgb(12 74 110 / 0.75) !important;
}

.tw-text-sky-900\/80 {
  color: rgb(12 74 110 / 0.8) !important;
}

.tw-text-sky-900\/85 {
  color: rgb(12 74 110 / 0.85) !important;
}

.tw-text-sky-900\/90 {
  color: rgb(12 74 110 / 0.9) !important;
}

.tw-text-sky-900\/95 {
  color: rgb(12 74 110 / 0.95) !important;
}

.tw-text-sky-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(8 47 73 / var(--tw-text-opacity)) !important;
}

.tw-text-sky-950\/0 {
  color: rgb(8 47 73 / 0) !important;
}

.tw-text-sky-950\/10 {
  color: rgb(8 47 73 / 0.1) !important;
}

.tw-text-sky-950\/100 {
  color: rgb(8 47 73 / 1) !important;
}

.tw-text-sky-950\/15 {
  color: rgb(8 47 73 / 0.15) !important;
}

.tw-text-sky-950\/20 {
  color: rgb(8 47 73 / 0.2) !important;
}

.tw-text-sky-950\/25 {
  color: rgb(8 47 73 / 0.25) !important;
}

.tw-text-sky-950\/30 {
  color: rgb(8 47 73 / 0.3) !important;
}

.tw-text-sky-950\/35 {
  color: rgb(8 47 73 / 0.35) !important;
}

.tw-text-sky-950\/40 {
  color: rgb(8 47 73 / 0.4) !important;
}

.tw-text-sky-950\/45 {
  color: rgb(8 47 73 / 0.45) !important;
}

.tw-text-sky-950\/5 {
  color: rgb(8 47 73 / 0.05) !important;
}

.tw-text-sky-950\/50 {
  color: rgb(8 47 73 / 0.5) !important;
}

.tw-text-sky-950\/55 {
  color: rgb(8 47 73 / 0.55) !important;
}

.tw-text-sky-950\/60 {
  color: rgb(8 47 73 / 0.6) !important;
}

.tw-text-sky-950\/65 {
  color: rgb(8 47 73 / 0.65) !important;
}

.tw-text-sky-950\/70 {
  color: rgb(8 47 73 / 0.7) !important;
}

.tw-text-sky-950\/75 {
  color: rgb(8 47 73 / 0.75) !important;
}

.tw-text-sky-950\/80 {
  color: rgb(8 47 73 / 0.8) !important;
}

.tw-text-sky-950\/85 {
  color: rgb(8 47 73 / 0.85) !important;
}

.tw-text-sky-950\/90 {
  color: rgb(8 47 73 / 0.9) !important;
}

.tw-text-sky-950\/95 {
  color: rgb(8 47 73 / 0.95) !important;
}

.tw-text-slate-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(241 245 249 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-100\/0 {
  color: rgb(241 245 249 / 0) !important;
}

.tw-text-slate-100\/10 {
  color: rgb(241 245 249 / 0.1) !important;
}

.tw-text-slate-100\/100 {
  color: rgb(241 245 249 / 1) !important;
}

.tw-text-slate-100\/15 {
  color: rgb(241 245 249 / 0.15) !important;
}

.tw-text-slate-100\/20 {
  color: rgb(241 245 249 / 0.2) !important;
}

.tw-text-slate-100\/25 {
  color: rgb(241 245 249 / 0.25) !important;
}

.tw-text-slate-100\/30 {
  color: rgb(241 245 249 / 0.3) !important;
}

.tw-text-slate-100\/35 {
  color: rgb(241 245 249 / 0.35) !important;
}

.tw-text-slate-100\/40 {
  color: rgb(241 245 249 / 0.4) !important;
}

.tw-text-slate-100\/45 {
  color: rgb(241 245 249 / 0.45) !important;
}

.tw-text-slate-100\/5 {
  color: rgb(241 245 249 / 0.05) !important;
}

.tw-text-slate-100\/50 {
  color: rgb(241 245 249 / 0.5) !important;
}

.tw-text-slate-100\/55 {
  color: rgb(241 245 249 / 0.55) !important;
}

.tw-text-slate-100\/60 {
  color: rgb(241 245 249 / 0.6) !important;
}

.tw-text-slate-100\/65 {
  color: rgb(241 245 249 / 0.65) !important;
}

.tw-text-slate-100\/70 {
  color: rgb(241 245 249 / 0.7) !important;
}

.tw-text-slate-100\/75 {
  color: rgb(241 245 249 / 0.75) !important;
}

.tw-text-slate-100\/80 {
  color: rgb(241 245 249 / 0.8) !important;
}

.tw-text-slate-100\/85 {
  color: rgb(241 245 249 / 0.85) !important;
}

.tw-text-slate-100\/90 {
  color: rgb(241 245 249 / 0.9) !important;
}

.tw-text-slate-100\/95 {
  color: rgb(241 245 249 / 0.95) !important;
}

.tw-text-slate-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-200\/0 {
  color: rgb(226 232 240 / 0) !important;
}

.tw-text-slate-200\/10 {
  color: rgb(226 232 240 / 0.1) !important;
}

.tw-text-slate-200\/100 {
  color: rgb(226 232 240 / 1) !important;
}

.tw-text-slate-200\/15 {
  color: rgb(226 232 240 / 0.15) !important;
}

.tw-text-slate-200\/20 {
  color: rgb(226 232 240 / 0.2) !important;
}

.tw-text-slate-200\/25 {
  color: rgb(226 232 240 / 0.25) !important;
}

.tw-text-slate-200\/30 {
  color: rgb(226 232 240 / 0.3) !important;
}

.tw-text-slate-200\/35 {
  color: rgb(226 232 240 / 0.35) !important;
}

.tw-text-slate-200\/40 {
  color: rgb(226 232 240 / 0.4) !important;
}

.tw-text-slate-200\/45 {
  color: rgb(226 232 240 / 0.45) !important;
}

.tw-text-slate-200\/5 {
  color: rgb(226 232 240 / 0.05) !important;
}

.tw-text-slate-200\/50 {
  color: rgb(226 232 240 / 0.5) !important;
}

.tw-text-slate-200\/55 {
  color: rgb(226 232 240 / 0.55) !important;
}

.tw-text-slate-200\/60 {
  color: rgb(226 232 240 / 0.6) !important;
}

.tw-text-slate-200\/65 {
  color: rgb(226 232 240 / 0.65) !important;
}

.tw-text-slate-200\/70 {
  color: rgb(226 232 240 / 0.7) !important;
}

.tw-text-slate-200\/75 {
  color: rgb(226 232 240 / 0.75) !important;
}

.tw-text-slate-200\/80 {
  color: rgb(226 232 240 / 0.8) !important;
}

.tw-text-slate-200\/85 {
  color: rgb(226 232 240 / 0.85) !important;
}

.tw-text-slate-200\/90 {
  color: rgb(226 232 240 / 0.9) !important;
}

.tw-text-slate-200\/95 {
  color: rgb(226 232 240 / 0.95) !important;
}

.tw-text-slate-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(203 213 225 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-300\/0 {
  color: rgb(203 213 225 / 0) !important;
}

.tw-text-slate-300\/10 {
  color: rgb(203 213 225 / 0.1) !important;
}

.tw-text-slate-300\/100 {
  color: rgb(203 213 225 / 1) !important;
}

.tw-text-slate-300\/15 {
  color: rgb(203 213 225 / 0.15) !important;
}

.tw-text-slate-300\/20 {
  color: rgb(203 213 225 / 0.2) !important;
}

.tw-text-slate-300\/25 {
  color: rgb(203 213 225 / 0.25) !important;
}

.tw-text-slate-300\/30 {
  color: rgb(203 213 225 / 0.3) !important;
}

.tw-text-slate-300\/35 {
  color: rgb(203 213 225 / 0.35) !important;
}

.tw-text-slate-300\/40 {
  color: rgb(203 213 225 / 0.4) !important;
}

.tw-text-slate-300\/45 {
  color: rgb(203 213 225 / 0.45) !important;
}

.tw-text-slate-300\/5 {
  color: rgb(203 213 225 / 0.05) !important;
}

.tw-text-slate-300\/50 {
  color: rgb(203 213 225 / 0.5) !important;
}

.tw-text-slate-300\/55 {
  color: rgb(203 213 225 / 0.55) !important;
}

.tw-text-slate-300\/60 {
  color: rgb(203 213 225 / 0.6) !important;
}

.tw-text-slate-300\/65 {
  color: rgb(203 213 225 / 0.65) !important;
}

.tw-text-slate-300\/70 {
  color: rgb(203 213 225 / 0.7) !important;
}

.tw-text-slate-300\/75 {
  color: rgb(203 213 225 / 0.75) !important;
}

.tw-text-slate-300\/80 {
  color: rgb(203 213 225 / 0.8) !important;
}

.tw-text-slate-300\/85 {
  color: rgb(203 213 225 / 0.85) !important;
}

.tw-text-slate-300\/90 {
  color: rgb(203 213 225 / 0.9) !important;
}

.tw-text-slate-300\/95 {
  color: rgb(203 213 225 / 0.95) !important;
}

.tw-text-slate-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-400\/0 {
  color: rgb(148 163 184 / 0) !important;
}

.tw-text-slate-400\/10 {
  color: rgb(148 163 184 / 0.1) !important;
}

.tw-text-slate-400\/100 {
  color: rgb(148 163 184 / 1) !important;
}

.tw-text-slate-400\/15 {
  color: rgb(148 163 184 / 0.15) !important;
}

.tw-text-slate-400\/20 {
  color: rgb(148 163 184 / 0.2) !important;
}

.tw-text-slate-400\/25 {
  color: rgb(148 163 184 / 0.25) !important;
}

.tw-text-slate-400\/30 {
  color: rgb(148 163 184 / 0.3) !important;
}

.tw-text-slate-400\/35 {
  color: rgb(148 163 184 / 0.35) !important;
}

.tw-text-slate-400\/40 {
  color: rgb(148 163 184 / 0.4) !important;
}

.tw-text-slate-400\/45 {
  color: rgb(148 163 184 / 0.45) !important;
}

.tw-text-slate-400\/5 {
  color: rgb(148 163 184 / 0.05) !important;
}

.tw-text-slate-400\/50 {
  color: rgb(148 163 184 / 0.5) !important;
}

.tw-text-slate-400\/55 {
  color: rgb(148 163 184 / 0.55) !important;
}

.tw-text-slate-400\/60 {
  color: rgb(148 163 184 / 0.6) !important;
}

.tw-text-slate-400\/65 {
  color: rgb(148 163 184 / 0.65) !important;
}

.tw-text-slate-400\/70 {
  color: rgb(148 163 184 / 0.7) !important;
}

.tw-text-slate-400\/75 {
  color: rgb(148 163 184 / 0.75) !important;
}

.tw-text-slate-400\/80 {
  color: rgb(148 163 184 / 0.8) !important;
}

.tw-text-slate-400\/85 {
  color: rgb(148 163 184 / 0.85) !important;
}

.tw-text-slate-400\/90 {
  color: rgb(148 163 184 / 0.9) !important;
}

.tw-text-slate-400\/95 {
  color: rgb(148 163 184 / 0.95) !important;
}

.tw-text-slate-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(248 250 252 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-50\/0 {
  color: rgb(248 250 252 / 0) !important;
}

.tw-text-slate-50\/10 {
  color: rgb(248 250 252 / 0.1) !important;
}

.tw-text-slate-50\/100 {
  color: rgb(248 250 252 / 1) !important;
}

.tw-text-slate-50\/15 {
  color: rgb(248 250 252 / 0.15) !important;
}

.tw-text-slate-50\/20 {
  color: rgb(248 250 252 / 0.2) !important;
}

.tw-text-slate-50\/25 {
  color: rgb(248 250 252 / 0.25) !important;
}

.tw-text-slate-50\/30 {
  color: rgb(248 250 252 / 0.3) !important;
}

.tw-text-slate-50\/35 {
  color: rgb(248 250 252 / 0.35) !important;
}

.tw-text-slate-50\/40 {
  color: rgb(248 250 252 / 0.4) !important;
}

.tw-text-slate-50\/45 {
  color: rgb(248 250 252 / 0.45) !important;
}

.tw-text-slate-50\/5 {
  color: rgb(248 250 252 / 0.05) !important;
}

.tw-text-slate-50\/50 {
  color: rgb(248 250 252 / 0.5) !important;
}

.tw-text-slate-50\/55 {
  color: rgb(248 250 252 / 0.55) !important;
}

.tw-text-slate-50\/60 {
  color: rgb(248 250 252 / 0.6) !important;
}

.tw-text-slate-50\/65 {
  color: rgb(248 250 252 / 0.65) !important;
}

.tw-text-slate-50\/70 {
  color: rgb(248 250 252 / 0.7) !important;
}

.tw-text-slate-50\/75 {
  color: rgb(248 250 252 / 0.75) !important;
}

.tw-text-slate-50\/80 {
  color: rgb(248 250 252 / 0.8) !important;
}

.tw-text-slate-50\/85 {
  color: rgb(248 250 252 / 0.85) !important;
}

.tw-text-slate-50\/90 {
  color: rgb(248 250 252 / 0.9) !important;
}

.tw-text-slate-50\/95 {
  color: rgb(248 250 252 / 0.95) !important;
}

.tw-text-slate-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-500\/0 {
  color: rgb(100 116 139 / 0) !important;
}

.tw-text-slate-500\/10 {
  color: rgb(100 116 139 / 0.1) !important;
}

.tw-text-slate-500\/100 {
  color: rgb(100 116 139 / 1) !important;
}

.tw-text-slate-500\/15 {
  color: rgb(100 116 139 / 0.15) !important;
}

.tw-text-slate-500\/20 {
  color: rgb(100 116 139 / 0.2) !important;
}

.tw-text-slate-500\/25 {
  color: rgb(100 116 139 / 0.25) !important;
}

.tw-text-slate-500\/30 {
  color: rgb(100 116 139 / 0.3) !important;
}

.tw-text-slate-500\/35 {
  color: rgb(100 116 139 / 0.35) !important;
}

.tw-text-slate-500\/40 {
  color: rgb(100 116 139 / 0.4) !important;
}

.tw-text-slate-500\/45 {
  color: rgb(100 116 139 / 0.45) !important;
}

.tw-text-slate-500\/5 {
  color: rgb(100 116 139 / 0.05) !important;
}

.tw-text-slate-500\/50 {
  color: rgb(100 116 139 / 0.5) !important;
}

.tw-text-slate-500\/55 {
  color: rgb(100 116 139 / 0.55) !important;
}

.tw-text-slate-500\/60 {
  color: rgb(100 116 139 / 0.6) !important;
}

.tw-text-slate-500\/65 {
  color: rgb(100 116 139 / 0.65) !important;
}

.tw-text-slate-500\/70 {
  color: rgb(100 116 139 / 0.7) !important;
}

.tw-text-slate-500\/75 {
  color: rgb(100 116 139 / 0.75) !important;
}

.tw-text-slate-500\/80 {
  color: rgb(100 116 139 / 0.8) !important;
}

.tw-text-slate-500\/85 {
  color: rgb(100 116 139 / 0.85) !important;
}

.tw-text-slate-500\/90 {
  color: rgb(100 116 139 / 0.9) !important;
}

.tw-text-slate-500\/95 {
  color: rgb(100 116 139 / 0.95) !important;
}

.tw-text-slate-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(71 85 105 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-600\/0 {
  color: rgb(71 85 105 / 0) !important;
}

.tw-text-slate-600\/10 {
  color: rgb(71 85 105 / 0.1) !important;
}

.tw-text-slate-600\/100 {
  color: rgb(71 85 105 / 1) !important;
}

.tw-text-slate-600\/15 {
  color: rgb(71 85 105 / 0.15) !important;
}

.tw-text-slate-600\/20 {
  color: rgb(71 85 105 / 0.2) !important;
}

.tw-text-slate-600\/25 {
  color: rgb(71 85 105 / 0.25) !important;
}

.tw-text-slate-600\/30 {
  color: rgb(71 85 105 / 0.3) !important;
}

.tw-text-slate-600\/35 {
  color: rgb(71 85 105 / 0.35) !important;
}

.tw-text-slate-600\/40 {
  color: rgb(71 85 105 / 0.4) !important;
}

.tw-text-slate-600\/45 {
  color: rgb(71 85 105 / 0.45) !important;
}

.tw-text-slate-600\/5 {
  color: rgb(71 85 105 / 0.05) !important;
}

.tw-text-slate-600\/50 {
  color: rgb(71 85 105 / 0.5) !important;
}

.tw-text-slate-600\/55 {
  color: rgb(71 85 105 / 0.55) !important;
}

.tw-text-slate-600\/60 {
  color: rgb(71 85 105 / 0.6) !important;
}

.tw-text-slate-600\/65 {
  color: rgb(71 85 105 / 0.65) !important;
}

.tw-text-slate-600\/70 {
  color: rgb(71 85 105 / 0.7) !important;
}

.tw-text-slate-600\/75 {
  color: rgb(71 85 105 / 0.75) !important;
}

.tw-text-slate-600\/80 {
  color: rgb(71 85 105 / 0.8) !important;
}

.tw-text-slate-600\/85 {
  color: rgb(71 85 105 / 0.85) !important;
}

.tw-text-slate-600\/90 {
  color: rgb(71 85 105 / 0.9) !important;
}

.tw-text-slate-600\/95 {
  color: rgb(71 85 105 / 0.95) !important;
}

.tw-text-slate-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(51 65 85 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-700\/0 {
  color: rgb(51 65 85 / 0) !important;
}

.tw-text-slate-700\/10 {
  color: rgb(51 65 85 / 0.1) !important;
}

.tw-text-slate-700\/100 {
  color: rgb(51 65 85 / 1) !important;
}

.tw-text-slate-700\/15 {
  color: rgb(51 65 85 / 0.15) !important;
}

.tw-text-slate-700\/20 {
  color: rgb(51 65 85 / 0.2) !important;
}

.tw-text-slate-700\/25 {
  color: rgb(51 65 85 / 0.25) !important;
}

.tw-text-slate-700\/30 {
  color: rgb(51 65 85 / 0.3) !important;
}

.tw-text-slate-700\/35 {
  color: rgb(51 65 85 / 0.35) !important;
}

.tw-text-slate-700\/40 {
  color: rgb(51 65 85 / 0.4) !important;
}

.tw-text-slate-700\/45 {
  color: rgb(51 65 85 / 0.45) !important;
}

.tw-text-slate-700\/5 {
  color: rgb(51 65 85 / 0.05) !important;
}

.tw-text-slate-700\/50 {
  color: rgb(51 65 85 / 0.5) !important;
}

.tw-text-slate-700\/55 {
  color: rgb(51 65 85 / 0.55) !important;
}

.tw-text-slate-700\/60 {
  color: rgb(51 65 85 / 0.6) !important;
}

.tw-text-slate-700\/65 {
  color: rgb(51 65 85 / 0.65) !important;
}

.tw-text-slate-700\/70 {
  color: rgb(51 65 85 / 0.7) !important;
}

.tw-text-slate-700\/75 {
  color: rgb(51 65 85 / 0.75) !important;
}

.tw-text-slate-700\/80 {
  color: rgb(51 65 85 / 0.8) !important;
}

.tw-text-slate-700\/85 {
  color: rgb(51 65 85 / 0.85) !important;
}

.tw-text-slate-700\/90 {
  color: rgb(51 65 85 / 0.9) !important;
}

.tw-text-slate-700\/95 {
  color: rgb(51 65 85 / 0.95) !important;
}

.tw-text-slate-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(30 41 59 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-800\/0 {
  color: rgb(30 41 59 / 0) !important;
}

.tw-text-slate-800\/10 {
  color: rgb(30 41 59 / 0.1) !important;
}

.tw-text-slate-800\/100 {
  color: rgb(30 41 59 / 1) !important;
}

.tw-text-slate-800\/15 {
  color: rgb(30 41 59 / 0.15) !important;
}

.tw-text-slate-800\/20 {
  color: rgb(30 41 59 / 0.2) !important;
}

.tw-text-slate-800\/25 {
  color: rgb(30 41 59 / 0.25) !important;
}

.tw-text-slate-800\/30 {
  color: rgb(30 41 59 / 0.3) !important;
}

.tw-text-slate-800\/35 {
  color: rgb(30 41 59 / 0.35) !important;
}

.tw-text-slate-800\/40 {
  color: rgb(30 41 59 / 0.4) !important;
}

.tw-text-slate-800\/45 {
  color: rgb(30 41 59 / 0.45) !important;
}

.tw-text-slate-800\/5 {
  color: rgb(30 41 59 / 0.05) !important;
}

.tw-text-slate-800\/50 {
  color: rgb(30 41 59 / 0.5) !important;
}

.tw-text-slate-800\/55 {
  color: rgb(30 41 59 / 0.55) !important;
}

.tw-text-slate-800\/60 {
  color: rgb(30 41 59 / 0.6) !important;
}

.tw-text-slate-800\/65 {
  color: rgb(30 41 59 / 0.65) !important;
}

.tw-text-slate-800\/70 {
  color: rgb(30 41 59 / 0.7) !important;
}

.tw-text-slate-800\/75 {
  color: rgb(30 41 59 / 0.75) !important;
}

.tw-text-slate-800\/80 {
  color: rgb(30 41 59 / 0.8) !important;
}

.tw-text-slate-800\/85 {
  color: rgb(30 41 59 / 0.85) !important;
}

.tw-text-slate-800\/90 {
  color: rgb(30 41 59 / 0.9) !important;
}

.tw-text-slate-800\/95 {
  color: rgb(30 41 59 / 0.95) !important;
}

.tw-text-slate-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-900\/0 {
  color: rgb(15 23 42 / 0) !important;
}

.tw-text-slate-900\/10 {
  color: rgb(15 23 42 / 0.1) !important;
}

.tw-text-slate-900\/100 {
  color: rgb(15 23 42 / 1) !important;
}

.tw-text-slate-900\/15 {
  color: rgb(15 23 42 / 0.15) !important;
}

.tw-text-slate-900\/20 {
  color: rgb(15 23 42 / 0.2) !important;
}

.tw-text-slate-900\/25 {
  color: rgb(15 23 42 / 0.25) !important;
}

.tw-text-slate-900\/30 {
  color: rgb(15 23 42 / 0.3) !important;
}

.tw-text-slate-900\/35 {
  color: rgb(15 23 42 / 0.35) !important;
}

.tw-text-slate-900\/40 {
  color: rgb(15 23 42 / 0.4) !important;
}

.tw-text-slate-900\/45 {
  color: rgb(15 23 42 / 0.45) !important;
}

.tw-text-slate-900\/5 {
  color: rgb(15 23 42 / 0.05) !important;
}

.tw-text-slate-900\/50 {
  color: rgb(15 23 42 / 0.5) !important;
}

.tw-text-slate-900\/55 {
  color: rgb(15 23 42 / 0.55) !important;
}

.tw-text-slate-900\/60 {
  color: rgb(15 23 42 / 0.6) !important;
}

.tw-text-slate-900\/65 {
  color: rgb(15 23 42 / 0.65) !important;
}

.tw-text-slate-900\/70 {
  color: rgb(15 23 42 / 0.7) !important;
}

.tw-text-slate-900\/75 {
  color: rgb(15 23 42 / 0.75) !important;
}

.tw-text-slate-900\/80 {
  color: rgb(15 23 42 / 0.8) !important;
}

.tw-text-slate-900\/85 {
  color: rgb(15 23 42 / 0.85) !important;
}

.tw-text-slate-900\/90 {
  color: rgb(15 23 42 / 0.9) !important;
}

.tw-text-slate-900\/95 {
  color: rgb(15 23 42 / 0.95) !important;
}

.tw-text-slate-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(2 6 23 / var(--tw-text-opacity)) !important;
}

.tw-text-slate-950\/0 {
  color: rgb(2 6 23 / 0) !important;
}

.tw-text-slate-950\/10 {
  color: rgb(2 6 23 / 0.1) !important;
}

.tw-text-slate-950\/100 {
  color: rgb(2 6 23 / 1) !important;
}

.tw-text-slate-950\/15 {
  color: rgb(2 6 23 / 0.15) !important;
}

.tw-text-slate-950\/20 {
  color: rgb(2 6 23 / 0.2) !important;
}

.tw-text-slate-950\/25 {
  color: rgb(2 6 23 / 0.25) !important;
}

.tw-text-slate-950\/30 {
  color: rgb(2 6 23 / 0.3) !important;
}

.tw-text-slate-950\/35 {
  color: rgb(2 6 23 / 0.35) !important;
}

.tw-text-slate-950\/40 {
  color: rgb(2 6 23 / 0.4) !important;
}

.tw-text-slate-950\/45 {
  color: rgb(2 6 23 / 0.45) !important;
}

.tw-text-slate-950\/5 {
  color: rgb(2 6 23 / 0.05) !important;
}

.tw-text-slate-950\/50 {
  color: rgb(2 6 23 / 0.5) !important;
}

.tw-text-slate-950\/55 {
  color: rgb(2 6 23 / 0.55) !important;
}

.tw-text-slate-950\/60 {
  color: rgb(2 6 23 / 0.6) !important;
}

.tw-text-slate-950\/65 {
  color: rgb(2 6 23 / 0.65) !important;
}

.tw-text-slate-950\/70 {
  color: rgb(2 6 23 / 0.7) !important;
}

.tw-text-slate-950\/75 {
  color: rgb(2 6 23 / 0.75) !important;
}

.tw-text-slate-950\/80 {
  color: rgb(2 6 23 / 0.8) !important;
}

.tw-text-slate-950\/85 {
  color: rgb(2 6 23 / 0.85) !important;
}

.tw-text-slate-950\/90 {
  color: rgb(2 6 23 / 0.9) !important;
}

.tw-text-slate-950\/95 {
  color: rgb(2 6 23 / 0.95) !important;
}

.tw-text-stone-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 245 244 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-100\/0 {
  color: rgb(245 245 244 / 0) !important;
}

.tw-text-stone-100\/10 {
  color: rgb(245 245 244 / 0.1) !important;
}

.tw-text-stone-100\/100 {
  color: rgb(245 245 244 / 1) !important;
}

.tw-text-stone-100\/15 {
  color: rgb(245 245 244 / 0.15) !important;
}

.tw-text-stone-100\/20 {
  color: rgb(245 245 244 / 0.2) !important;
}

.tw-text-stone-100\/25 {
  color: rgb(245 245 244 / 0.25) !important;
}

.tw-text-stone-100\/30 {
  color: rgb(245 245 244 / 0.3) !important;
}

.tw-text-stone-100\/35 {
  color: rgb(245 245 244 / 0.35) !important;
}

.tw-text-stone-100\/40 {
  color: rgb(245 245 244 / 0.4) !important;
}

.tw-text-stone-100\/45 {
  color: rgb(245 245 244 / 0.45) !important;
}

.tw-text-stone-100\/5 {
  color: rgb(245 245 244 / 0.05) !important;
}

.tw-text-stone-100\/50 {
  color: rgb(245 245 244 / 0.5) !important;
}

.tw-text-stone-100\/55 {
  color: rgb(245 245 244 / 0.55) !important;
}

.tw-text-stone-100\/60 {
  color: rgb(245 245 244 / 0.6) !important;
}

.tw-text-stone-100\/65 {
  color: rgb(245 245 244 / 0.65) !important;
}

.tw-text-stone-100\/70 {
  color: rgb(245 245 244 / 0.7) !important;
}

.tw-text-stone-100\/75 {
  color: rgb(245 245 244 / 0.75) !important;
}

.tw-text-stone-100\/80 {
  color: rgb(245 245 244 / 0.8) !important;
}

.tw-text-stone-100\/85 {
  color: rgb(245 245 244 / 0.85) !important;
}

.tw-text-stone-100\/90 {
  color: rgb(245 245 244 / 0.9) !important;
}

.tw-text-stone-100\/95 {
  color: rgb(245 245 244 / 0.95) !important;
}

.tw-text-stone-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(231 229 228 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-200\/0 {
  color: rgb(231 229 228 / 0) !important;
}

.tw-text-stone-200\/10 {
  color: rgb(231 229 228 / 0.1) !important;
}

.tw-text-stone-200\/100 {
  color: rgb(231 229 228 / 1) !important;
}

.tw-text-stone-200\/15 {
  color: rgb(231 229 228 / 0.15) !important;
}

.tw-text-stone-200\/20 {
  color: rgb(231 229 228 / 0.2) !important;
}

.tw-text-stone-200\/25 {
  color: rgb(231 229 228 / 0.25) !important;
}

.tw-text-stone-200\/30 {
  color: rgb(231 229 228 / 0.3) !important;
}

.tw-text-stone-200\/35 {
  color: rgb(231 229 228 / 0.35) !important;
}

.tw-text-stone-200\/40 {
  color: rgb(231 229 228 / 0.4) !important;
}

.tw-text-stone-200\/45 {
  color: rgb(231 229 228 / 0.45) !important;
}

.tw-text-stone-200\/5 {
  color: rgb(231 229 228 / 0.05) !important;
}

.tw-text-stone-200\/50 {
  color: rgb(231 229 228 / 0.5) !important;
}

.tw-text-stone-200\/55 {
  color: rgb(231 229 228 / 0.55) !important;
}

.tw-text-stone-200\/60 {
  color: rgb(231 229 228 / 0.6) !important;
}

.tw-text-stone-200\/65 {
  color: rgb(231 229 228 / 0.65) !important;
}

.tw-text-stone-200\/70 {
  color: rgb(231 229 228 / 0.7) !important;
}

.tw-text-stone-200\/75 {
  color: rgb(231 229 228 / 0.75) !important;
}

.tw-text-stone-200\/80 {
  color: rgb(231 229 228 / 0.8) !important;
}

.tw-text-stone-200\/85 {
  color: rgb(231 229 228 / 0.85) !important;
}

.tw-text-stone-200\/90 {
  color: rgb(231 229 228 / 0.9) !important;
}

.tw-text-stone-200\/95 {
  color: rgb(231 229 228 / 0.95) !important;
}

.tw-text-stone-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(214 211 209 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-300\/0 {
  color: rgb(214 211 209 / 0) !important;
}

.tw-text-stone-300\/10 {
  color: rgb(214 211 209 / 0.1) !important;
}

.tw-text-stone-300\/100 {
  color: rgb(214 211 209 / 1) !important;
}

.tw-text-stone-300\/15 {
  color: rgb(214 211 209 / 0.15) !important;
}

.tw-text-stone-300\/20 {
  color: rgb(214 211 209 / 0.2) !important;
}

.tw-text-stone-300\/25 {
  color: rgb(214 211 209 / 0.25) !important;
}

.tw-text-stone-300\/30 {
  color: rgb(214 211 209 / 0.3) !important;
}

.tw-text-stone-300\/35 {
  color: rgb(214 211 209 / 0.35) !important;
}

.tw-text-stone-300\/40 {
  color: rgb(214 211 209 / 0.4) !important;
}

.tw-text-stone-300\/45 {
  color: rgb(214 211 209 / 0.45) !important;
}

.tw-text-stone-300\/5 {
  color: rgb(214 211 209 / 0.05) !important;
}

.tw-text-stone-300\/50 {
  color: rgb(214 211 209 / 0.5) !important;
}

.tw-text-stone-300\/55 {
  color: rgb(214 211 209 / 0.55) !important;
}

.tw-text-stone-300\/60 {
  color: rgb(214 211 209 / 0.6) !important;
}

.tw-text-stone-300\/65 {
  color: rgb(214 211 209 / 0.65) !important;
}

.tw-text-stone-300\/70 {
  color: rgb(214 211 209 / 0.7) !important;
}

.tw-text-stone-300\/75 {
  color: rgb(214 211 209 / 0.75) !important;
}

.tw-text-stone-300\/80 {
  color: rgb(214 211 209 / 0.8) !important;
}

.tw-text-stone-300\/85 {
  color: rgb(214 211 209 / 0.85) !important;
}

.tw-text-stone-300\/90 {
  color: rgb(214 211 209 / 0.9) !important;
}

.tw-text-stone-300\/95 {
  color: rgb(214 211 209 / 0.95) !important;
}

.tw-text-stone-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(168 162 158 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-400\/0 {
  color: rgb(168 162 158 / 0) !important;
}

.tw-text-stone-400\/10 {
  color: rgb(168 162 158 / 0.1) !important;
}

.tw-text-stone-400\/100 {
  color: rgb(168 162 158 / 1) !important;
}

.tw-text-stone-400\/15 {
  color: rgb(168 162 158 / 0.15) !important;
}

.tw-text-stone-400\/20 {
  color: rgb(168 162 158 / 0.2) !important;
}

.tw-text-stone-400\/25 {
  color: rgb(168 162 158 / 0.25) !important;
}

.tw-text-stone-400\/30 {
  color: rgb(168 162 158 / 0.3) !important;
}

.tw-text-stone-400\/35 {
  color: rgb(168 162 158 / 0.35) !important;
}

.tw-text-stone-400\/40 {
  color: rgb(168 162 158 / 0.4) !important;
}

.tw-text-stone-400\/45 {
  color: rgb(168 162 158 / 0.45) !important;
}

.tw-text-stone-400\/5 {
  color: rgb(168 162 158 / 0.05) !important;
}

.tw-text-stone-400\/50 {
  color: rgb(168 162 158 / 0.5) !important;
}

.tw-text-stone-400\/55 {
  color: rgb(168 162 158 / 0.55) !important;
}

.tw-text-stone-400\/60 {
  color: rgb(168 162 158 / 0.6) !important;
}

.tw-text-stone-400\/65 {
  color: rgb(168 162 158 / 0.65) !important;
}

.tw-text-stone-400\/70 {
  color: rgb(168 162 158 / 0.7) !important;
}

.tw-text-stone-400\/75 {
  color: rgb(168 162 158 / 0.75) !important;
}

.tw-text-stone-400\/80 {
  color: rgb(168 162 158 / 0.8) !important;
}

.tw-text-stone-400\/85 {
  color: rgb(168 162 158 / 0.85) !important;
}

.tw-text-stone-400\/90 {
  color: rgb(168 162 158 / 0.9) !important;
}

.tw-text-stone-400\/95 {
  color: rgb(168 162 158 / 0.95) !important;
}

.tw-text-stone-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(250 250 249 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-50\/0 {
  color: rgb(250 250 249 / 0) !important;
}

.tw-text-stone-50\/10 {
  color: rgb(250 250 249 / 0.1) !important;
}

.tw-text-stone-50\/100 {
  color: rgb(250 250 249 / 1) !important;
}

.tw-text-stone-50\/15 {
  color: rgb(250 250 249 / 0.15) !important;
}

.tw-text-stone-50\/20 {
  color: rgb(250 250 249 / 0.2) !important;
}

.tw-text-stone-50\/25 {
  color: rgb(250 250 249 / 0.25) !important;
}

.tw-text-stone-50\/30 {
  color: rgb(250 250 249 / 0.3) !important;
}

.tw-text-stone-50\/35 {
  color: rgb(250 250 249 / 0.35) !important;
}

.tw-text-stone-50\/40 {
  color: rgb(250 250 249 / 0.4) !important;
}

.tw-text-stone-50\/45 {
  color: rgb(250 250 249 / 0.45) !important;
}

.tw-text-stone-50\/5 {
  color: rgb(250 250 249 / 0.05) !important;
}

.tw-text-stone-50\/50 {
  color: rgb(250 250 249 / 0.5) !important;
}

.tw-text-stone-50\/55 {
  color: rgb(250 250 249 / 0.55) !important;
}

.tw-text-stone-50\/60 {
  color: rgb(250 250 249 / 0.6) !important;
}

.tw-text-stone-50\/65 {
  color: rgb(250 250 249 / 0.65) !important;
}

.tw-text-stone-50\/70 {
  color: rgb(250 250 249 / 0.7) !important;
}

.tw-text-stone-50\/75 {
  color: rgb(250 250 249 / 0.75) !important;
}

.tw-text-stone-50\/80 {
  color: rgb(250 250 249 / 0.8) !important;
}

.tw-text-stone-50\/85 {
  color: rgb(250 250 249 / 0.85) !important;
}

.tw-text-stone-50\/90 {
  color: rgb(250 250 249 / 0.9) !important;
}

.tw-text-stone-50\/95 {
  color: rgb(250 250 249 / 0.95) !important;
}

.tw-text-stone-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(120 113 108 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-500\/0 {
  color: rgb(120 113 108 / 0) !important;
}

.tw-text-stone-500\/10 {
  color: rgb(120 113 108 / 0.1) !important;
}

.tw-text-stone-500\/100 {
  color: rgb(120 113 108 / 1) !important;
}

.tw-text-stone-500\/15 {
  color: rgb(120 113 108 / 0.15) !important;
}

.tw-text-stone-500\/20 {
  color: rgb(120 113 108 / 0.2) !important;
}

.tw-text-stone-500\/25 {
  color: rgb(120 113 108 / 0.25) !important;
}

.tw-text-stone-500\/30 {
  color: rgb(120 113 108 / 0.3) !important;
}

.tw-text-stone-500\/35 {
  color: rgb(120 113 108 / 0.35) !important;
}

.tw-text-stone-500\/40 {
  color: rgb(120 113 108 / 0.4) !important;
}

.tw-text-stone-500\/45 {
  color: rgb(120 113 108 / 0.45) !important;
}

.tw-text-stone-500\/5 {
  color: rgb(120 113 108 / 0.05) !important;
}

.tw-text-stone-500\/50 {
  color: rgb(120 113 108 / 0.5) !important;
}

.tw-text-stone-500\/55 {
  color: rgb(120 113 108 / 0.55) !important;
}

.tw-text-stone-500\/60 {
  color: rgb(120 113 108 / 0.6) !important;
}

.tw-text-stone-500\/65 {
  color: rgb(120 113 108 / 0.65) !important;
}

.tw-text-stone-500\/70 {
  color: rgb(120 113 108 / 0.7) !important;
}

.tw-text-stone-500\/75 {
  color: rgb(120 113 108 / 0.75) !important;
}

.tw-text-stone-500\/80 {
  color: rgb(120 113 108 / 0.8) !important;
}

.tw-text-stone-500\/85 {
  color: rgb(120 113 108 / 0.85) !important;
}

.tw-text-stone-500\/90 {
  color: rgb(120 113 108 / 0.9) !important;
}

.tw-text-stone-500\/95 {
  color: rgb(120 113 108 / 0.95) !important;
}

.tw-text-stone-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(87 83 78 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-600\/0 {
  color: rgb(87 83 78 / 0) !important;
}

.tw-text-stone-600\/10 {
  color: rgb(87 83 78 / 0.1) !important;
}

.tw-text-stone-600\/100 {
  color: rgb(87 83 78 / 1) !important;
}

.tw-text-stone-600\/15 {
  color: rgb(87 83 78 / 0.15) !important;
}

.tw-text-stone-600\/20 {
  color: rgb(87 83 78 / 0.2) !important;
}

.tw-text-stone-600\/25 {
  color: rgb(87 83 78 / 0.25) !important;
}

.tw-text-stone-600\/30 {
  color: rgb(87 83 78 / 0.3) !important;
}

.tw-text-stone-600\/35 {
  color: rgb(87 83 78 / 0.35) !important;
}

.tw-text-stone-600\/40 {
  color: rgb(87 83 78 / 0.4) !important;
}

.tw-text-stone-600\/45 {
  color: rgb(87 83 78 / 0.45) !important;
}

.tw-text-stone-600\/5 {
  color: rgb(87 83 78 / 0.05) !important;
}

.tw-text-stone-600\/50 {
  color: rgb(87 83 78 / 0.5) !important;
}

.tw-text-stone-600\/55 {
  color: rgb(87 83 78 / 0.55) !important;
}

.tw-text-stone-600\/60 {
  color: rgb(87 83 78 / 0.6) !important;
}

.tw-text-stone-600\/65 {
  color: rgb(87 83 78 / 0.65) !important;
}

.tw-text-stone-600\/70 {
  color: rgb(87 83 78 / 0.7) !important;
}

.tw-text-stone-600\/75 {
  color: rgb(87 83 78 / 0.75) !important;
}

.tw-text-stone-600\/80 {
  color: rgb(87 83 78 / 0.8) !important;
}

.tw-text-stone-600\/85 {
  color: rgb(87 83 78 / 0.85) !important;
}

.tw-text-stone-600\/90 {
  color: rgb(87 83 78 / 0.9) !important;
}

.tw-text-stone-600\/95 {
  color: rgb(87 83 78 / 0.95) !important;
}

.tw-text-stone-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(68 64 60 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-700\/0 {
  color: rgb(68 64 60 / 0) !important;
}

.tw-text-stone-700\/10 {
  color: rgb(68 64 60 / 0.1) !important;
}

.tw-text-stone-700\/100 {
  color: rgb(68 64 60 / 1) !important;
}

.tw-text-stone-700\/15 {
  color: rgb(68 64 60 / 0.15) !important;
}

.tw-text-stone-700\/20 {
  color: rgb(68 64 60 / 0.2) !important;
}

.tw-text-stone-700\/25 {
  color: rgb(68 64 60 / 0.25) !important;
}

.tw-text-stone-700\/30 {
  color: rgb(68 64 60 / 0.3) !important;
}

.tw-text-stone-700\/35 {
  color: rgb(68 64 60 / 0.35) !important;
}

.tw-text-stone-700\/40 {
  color: rgb(68 64 60 / 0.4) !important;
}

.tw-text-stone-700\/45 {
  color: rgb(68 64 60 / 0.45) !important;
}

.tw-text-stone-700\/5 {
  color: rgb(68 64 60 / 0.05) !important;
}

.tw-text-stone-700\/50 {
  color: rgb(68 64 60 / 0.5) !important;
}

.tw-text-stone-700\/55 {
  color: rgb(68 64 60 / 0.55) !important;
}

.tw-text-stone-700\/60 {
  color: rgb(68 64 60 / 0.6) !important;
}

.tw-text-stone-700\/65 {
  color: rgb(68 64 60 / 0.65) !important;
}

.tw-text-stone-700\/70 {
  color: rgb(68 64 60 / 0.7) !important;
}

.tw-text-stone-700\/75 {
  color: rgb(68 64 60 / 0.75) !important;
}

.tw-text-stone-700\/80 {
  color: rgb(68 64 60 / 0.8) !important;
}

.tw-text-stone-700\/85 {
  color: rgb(68 64 60 / 0.85) !important;
}

.tw-text-stone-700\/90 {
  color: rgb(68 64 60 / 0.9) !important;
}

.tw-text-stone-700\/95 {
  color: rgb(68 64 60 / 0.95) !important;
}

.tw-text-stone-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(41 37 36 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-800\/0 {
  color: rgb(41 37 36 / 0) !important;
}

.tw-text-stone-800\/10 {
  color: rgb(41 37 36 / 0.1) !important;
}

.tw-text-stone-800\/100 {
  color: rgb(41 37 36 / 1) !important;
}

.tw-text-stone-800\/15 {
  color: rgb(41 37 36 / 0.15) !important;
}

.tw-text-stone-800\/20 {
  color: rgb(41 37 36 / 0.2) !important;
}

.tw-text-stone-800\/25 {
  color: rgb(41 37 36 / 0.25) !important;
}

.tw-text-stone-800\/30 {
  color: rgb(41 37 36 / 0.3) !important;
}

.tw-text-stone-800\/35 {
  color: rgb(41 37 36 / 0.35) !important;
}

.tw-text-stone-800\/40 {
  color: rgb(41 37 36 / 0.4) !important;
}

.tw-text-stone-800\/45 {
  color: rgb(41 37 36 / 0.45) !important;
}

.tw-text-stone-800\/5 {
  color: rgb(41 37 36 / 0.05) !important;
}

.tw-text-stone-800\/50 {
  color: rgb(41 37 36 / 0.5) !important;
}

.tw-text-stone-800\/55 {
  color: rgb(41 37 36 / 0.55) !important;
}

.tw-text-stone-800\/60 {
  color: rgb(41 37 36 / 0.6) !important;
}

.tw-text-stone-800\/65 {
  color: rgb(41 37 36 / 0.65) !important;
}

.tw-text-stone-800\/70 {
  color: rgb(41 37 36 / 0.7) !important;
}

.tw-text-stone-800\/75 {
  color: rgb(41 37 36 / 0.75) !important;
}

.tw-text-stone-800\/80 {
  color: rgb(41 37 36 / 0.8) !important;
}

.tw-text-stone-800\/85 {
  color: rgb(41 37 36 / 0.85) !important;
}

.tw-text-stone-800\/90 {
  color: rgb(41 37 36 / 0.9) !important;
}

.tw-text-stone-800\/95 {
  color: rgb(41 37 36 / 0.95) !important;
}

.tw-text-stone-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(28 25 23 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-900\/0 {
  color: rgb(28 25 23 / 0) !important;
}

.tw-text-stone-900\/10 {
  color: rgb(28 25 23 / 0.1) !important;
}

.tw-text-stone-900\/100 {
  color: rgb(28 25 23 / 1) !important;
}

.tw-text-stone-900\/15 {
  color: rgb(28 25 23 / 0.15) !important;
}

.tw-text-stone-900\/20 {
  color: rgb(28 25 23 / 0.2) !important;
}

.tw-text-stone-900\/25 {
  color: rgb(28 25 23 / 0.25) !important;
}

.tw-text-stone-900\/30 {
  color: rgb(28 25 23 / 0.3) !important;
}

.tw-text-stone-900\/35 {
  color: rgb(28 25 23 / 0.35) !important;
}

.tw-text-stone-900\/40 {
  color: rgb(28 25 23 / 0.4) !important;
}

.tw-text-stone-900\/45 {
  color: rgb(28 25 23 / 0.45) !important;
}

.tw-text-stone-900\/5 {
  color: rgb(28 25 23 / 0.05) !important;
}

.tw-text-stone-900\/50 {
  color: rgb(28 25 23 / 0.5) !important;
}

.tw-text-stone-900\/55 {
  color: rgb(28 25 23 / 0.55) !important;
}

.tw-text-stone-900\/60 {
  color: rgb(28 25 23 / 0.6) !important;
}

.tw-text-stone-900\/65 {
  color: rgb(28 25 23 / 0.65) !important;
}

.tw-text-stone-900\/70 {
  color: rgb(28 25 23 / 0.7) !important;
}

.tw-text-stone-900\/75 {
  color: rgb(28 25 23 / 0.75) !important;
}

.tw-text-stone-900\/80 {
  color: rgb(28 25 23 / 0.8) !important;
}

.tw-text-stone-900\/85 {
  color: rgb(28 25 23 / 0.85) !important;
}

.tw-text-stone-900\/90 {
  color: rgb(28 25 23 / 0.9) !important;
}

.tw-text-stone-900\/95 {
  color: rgb(28 25 23 / 0.95) !important;
}

.tw-text-stone-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(12 10 9 / var(--tw-text-opacity)) !important;
}

.tw-text-stone-950\/0 {
  color: rgb(12 10 9 / 0) !important;
}

.tw-text-stone-950\/10 {
  color: rgb(12 10 9 / 0.1) !important;
}

.tw-text-stone-950\/100 {
  color: rgb(12 10 9 / 1) !important;
}

.tw-text-stone-950\/15 {
  color: rgb(12 10 9 / 0.15) !important;
}

.tw-text-stone-950\/20 {
  color: rgb(12 10 9 / 0.2) !important;
}

.tw-text-stone-950\/25 {
  color: rgb(12 10 9 / 0.25) !important;
}

.tw-text-stone-950\/30 {
  color: rgb(12 10 9 / 0.3) !important;
}

.tw-text-stone-950\/35 {
  color: rgb(12 10 9 / 0.35) !important;
}

.tw-text-stone-950\/40 {
  color: rgb(12 10 9 / 0.4) !important;
}

.tw-text-stone-950\/45 {
  color: rgb(12 10 9 / 0.45) !important;
}

.tw-text-stone-950\/5 {
  color: rgb(12 10 9 / 0.05) !important;
}

.tw-text-stone-950\/50 {
  color: rgb(12 10 9 / 0.5) !important;
}

.tw-text-stone-950\/55 {
  color: rgb(12 10 9 / 0.55) !important;
}

.tw-text-stone-950\/60 {
  color: rgb(12 10 9 / 0.6) !important;
}

.tw-text-stone-950\/65 {
  color: rgb(12 10 9 / 0.65) !important;
}

.tw-text-stone-950\/70 {
  color: rgb(12 10 9 / 0.7) !important;
}

.tw-text-stone-950\/75 {
  color: rgb(12 10 9 / 0.75) !important;
}

.tw-text-stone-950\/80 {
  color: rgb(12 10 9 / 0.8) !important;
}

.tw-text-stone-950\/85 {
  color: rgb(12 10 9 / 0.85) !important;
}

.tw-text-stone-950\/90 {
  color: rgb(12 10 9 / 0.9) !important;
}

.tw-text-stone-950\/95 {
  color: rgb(12 10 9 / 0.95) !important;
}

.tw-text-teal-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(204 251 241 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-100\/0 {
  color: rgb(204 251 241 / 0) !important;
}

.tw-text-teal-100\/10 {
  color: rgb(204 251 241 / 0.1) !important;
}

.tw-text-teal-100\/100 {
  color: rgb(204 251 241 / 1) !important;
}

.tw-text-teal-100\/15 {
  color: rgb(204 251 241 / 0.15) !important;
}

.tw-text-teal-100\/20 {
  color: rgb(204 251 241 / 0.2) !important;
}

.tw-text-teal-100\/25 {
  color: rgb(204 251 241 / 0.25) !important;
}

.tw-text-teal-100\/30 {
  color: rgb(204 251 241 / 0.3) !important;
}

.tw-text-teal-100\/35 {
  color: rgb(204 251 241 / 0.35) !important;
}

.tw-text-teal-100\/40 {
  color: rgb(204 251 241 / 0.4) !important;
}

.tw-text-teal-100\/45 {
  color: rgb(204 251 241 / 0.45) !important;
}

.tw-text-teal-100\/5 {
  color: rgb(204 251 241 / 0.05) !important;
}

.tw-text-teal-100\/50 {
  color: rgb(204 251 241 / 0.5) !important;
}

.tw-text-teal-100\/55 {
  color: rgb(204 251 241 / 0.55) !important;
}

.tw-text-teal-100\/60 {
  color: rgb(204 251 241 / 0.6) !important;
}

.tw-text-teal-100\/65 {
  color: rgb(204 251 241 / 0.65) !important;
}

.tw-text-teal-100\/70 {
  color: rgb(204 251 241 / 0.7) !important;
}

.tw-text-teal-100\/75 {
  color: rgb(204 251 241 / 0.75) !important;
}

.tw-text-teal-100\/80 {
  color: rgb(204 251 241 / 0.8) !important;
}

.tw-text-teal-100\/85 {
  color: rgb(204 251 241 / 0.85) !important;
}

.tw-text-teal-100\/90 {
  color: rgb(204 251 241 / 0.9) !important;
}

.tw-text-teal-100\/95 {
  color: rgb(204 251 241 / 0.95) !important;
}

.tw-text-teal-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(153 246 228 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-200\/0 {
  color: rgb(153 246 228 / 0) !important;
}

.tw-text-teal-200\/10 {
  color: rgb(153 246 228 / 0.1) !important;
}

.tw-text-teal-200\/100 {
  color: rgb(153 246 228 / 1) !important;
}

.tw-text-teal-200\/15 {
  color: rgb(153 246 228 / 0.15) !important;
}

.tw-text-teal-200\/20 {
  color: rgb(153 246 228 / 0.2) !important;
}

.tw-text-teal-200\/25 {
  color: rgb(153 246 228 / 0.25) !important;
}

.tw-text-teal-200\/30 {
  color: rgb(153 246 228 / 0.3) !important;
}

.tw-text-teal-200\/35 {
  color: rgb(153 246 228 / 0.35) !important;
}

.tw-text-teal-200\/40 {
  color: rgb(153 246 228 / 0.4) !important;
}

.tw-text-teal-200\/45 {
  color: rgb(153 246 228 / 0.45) !important;
}

.tw-text-teal-200\/5 {
  color: rgb(153 246 228 / 0.05) !important;
}

.tw-text-teal-200\/50 {
  color: rgb(153 246 228 / 0.5) !important;
}

.tw-text-teal-200\/55 {
  color: rgb(153 246 228 / 0.55) !important;
}

.tw-text-teal-200\/60 {
  color: rgb(153 246 228 / 0.6) !important;
}

.tw-text-teal-200\/65 {
  color: rgb(153 246 228 / 0.65) !important;
}

.tw-text-teal-200\/70 {
  color: rgb(153 246 228 / 0.7) !important;
}

.tw-text-teal-200\/75 {
  color: rgb(153 246 228 / 0.75) !important;
}

.tw-text-teal-200\/80 {
  color: rgb(153 246 228 / 0.8) !important;
}

.tw-text-teal-200\/85 {
  color: rgb(153 246 228 / 0.85) !important;
}

.tw-text-teal-200\/90 {
  color: rgb(153 246 228 / 0.9) !important;
}

.tw-text-teal-200\/95 {
  color: rgb(153 246 228 / 0.95) !important;
}

.tw-text-teal-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(94 234 212 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-300\/0 {
  color: rgb(94 234 212 / 0) !important;
}

.tw-text-teal-300\/10 {
  color: rgb(94 234 212 / 0.1) !important;
}

.tw-text-teal-300\/100 {
  color: rgb(94 234 212 / 1) !important;
}

.tw-text-teal-300\/15 {
  color: rgb(94 234 212 / 0.15) !important;
}

.tw-text-teal-300\/20 {
  color: rgb(94 234 212 / 0.2) !important;
}

.tw-text-teal-300\/25 {
  color: rgb(94 234 212 / 0.25) !important;
}

.tw-text-teal-300\/30 {
  color: rgb(94 234 212 / 0.3) !important;
}

.tw-text-teal-300\/35 {
  color: rgb(94 234 212 / 0.35) !important;
}

.tw-text-teal-300\/40 {
  color: rgb(94 234 212 / 0.4) !important;
}

.tw-text-teal-300\/45 {
  color: rgb(94 234 212 / 0.45) !important;
}

.tw-text-teal-300\/5 {
  color: rgb(94 234 212 / 0.05) !important;
}

.tw-text-teal-300\/50 {
  color: rgb(94 234 212 / 0.5) !important;
}

.tw-text-teal-300\/55 {
  color: rgb(94 234 212 / 0.55) !important;
}

.tw-text-teal-300\/60 {
  color: rgb(94 234 212 / 0.6) !important;
}

.tw-text-teal-300\/65 {
  color: rgb(94 234 212 / 0.65) !important;
}

.tw-text-teal-300\/70 {
  color: rgb(94 234 212 / 0.7) !important;
}

.tw-text-teal-300\/75 {
  color: rgb(94 234 212 / 0.75) !important;
}

.tw-text-teal-300\/80 {
  color: rgb(94 234 212 / 0.8) !important;
}

.tw-text-teal-300\/85 {
  color: rgb(94 234 212 / 0.85) !important;
}

.tw-text-teal-300\/90 {
  color: rgb(94 234 212 / 0.9) !important;
}

.tw-text-teal-300\/95 {
  color: rgb(94 234 212 / 0.95) !important;
}

.tw-text-teal-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(45 212 191 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-400\/0 {
  color: rgb(45 212 191 / 0) !important;
}

.tw-text-teal-400\/10 {
  color: rgb(45 212 191 / 0.1) !important;
}

.tw-text-teal-400\/100 {
  color: rgb(45 212 191 / 1) !important;
}

.tw-text-teal-400\/15 {
  color: rgb(45 212 191 / 0.15) !important;
}

.tw-text-teal-400\/20 {
  color: rgb(45 212 191 / 0.2) !important;
}

.tw-text-teal-400\/25 {
  color: rgb(45 212 191 / 0.25) !important;
}

.tw-text-teal-400\/30 {
  color: rgb(45 212 191 / 0.3) !important;
}

.tw-text-teal-400\/35 {
  color: rgb(45 212 191 / 0.35) !important;
}

.tw-text-teal-400\/40 {
  color: rgb(45 212 191 / 0.4) !important;
}

.tw-text-teal-400\/45 {
  color: rgb(45 212 191 / 0.45) !important;
}

.tw-text-teal-400\/5 {
  color: rgb(45 212 191 / 0.05) !important;
}

.tw-text-teal-400\/50 {
  color: rgb(45 212 191 / 0.5) !important;
}

.tw-text-teal-400\/55 {
  color: rgb(45 212 191 / 0.55) !important;
}

.tw-text-teal-400\/60 {
  color: rgb(45 212 191 / 0.6) !important;
}

.tw-text-teal-400\/65 {
  color: rgb(45 212 191 / 0.65) !important;
}

.tw-text-teal-400\/70 {
  color: rgb(45 212 191 / 0.7) !important;
}

.tw-text-teal-400\/75 {
  color: rgb(45 212 191 / 0.75) !important;
}

.tw-text-teal-400\/80 {
  color: rgb(45 212 191 / 0.8) !important;
}

.tw-text-teal-400\/85 {
  color: rgb(45 212 191 / 0.85) !important;
}

.tw-text-teal-400\/90 {
  color: rgb(45 212 191 / 0.9) !important;
}

.tw-text-teal-400\/95 {
  color: rgb(45 212 191 / 0.95) !important;
}

.tw-text-teal-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(240 253 250 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-50\/0 {
  color: rgb(240 253 250 / 0) !important;
}

.tw-text-teal-50\/10 {
  color: rgb(240 253 250 / 0.1) !important;
}

.tw-text-teal-50\/100 {
  color: rgb(240 253 250 / 1) !important;
}

.tw-text-teal-50\/15 {
  color: rgb(240 253 250 / 0.15) !important;
}

.tw-text-teal-50\/20 {
  color: rgb(240 253 250 / 0.2) !important;
}

.tw-text-teal-50\/25 {
  color: rgb(240 253 250 / 0.25) !important;
}

.tw-text-teal-50\/30 {
  color: rgb(240 253 250 / 0.3) !important;
}

.tw-text-teal-50\/35 {
  color: rgb(240 253 250 / 0.35) !important;
}

.tw-text-teal-50\/40 {
  color: rgb(240 253 250 / 0.4) !important;
}

.tw-text-teal-50\/45 {
  color: rgb(240 253 250 / 0.45) !important;
}

.tw-text-teal-50\/5 {
  color: rgb(240 253 250 / 0.05) !important;
}

.tw-text-teal-50\/50 {
  color: rgb(240 253 250 / 0.5) !important;
}

.tw-text-teal-50\/55 {
  color: rgb(240 253 250 / 0.55) !important;
}

.tw-text-teal-50\/60 {
  color: rgb(240 253 250 / 0.6) !important;
}

.tw-text-teal-50\/65 {
  color: rgb(240 253 250 / 0.65) !important;
}

.tw-text-teal-50\/70 {
  color: rgb(240 253 250 / 0.7) !important;
}

.tw-text-teal-50\/75 {
  color: rgb(240 253 250 / 0.75) !important;
}

.tw-text-teal-50\/80 {
  color: rgb(240 253 250 / 0.8) !important;
}

.tw-text-teal-50\/85 {
  color: rgb(240 253 250 / 0.85) !important;
}

.tw-text-teal-50\/90 {
  color: rgb(240 253 250 / 0.9) !important;
}

.tw-text-teal-50\/95 {
  color: rgb(240 253 250 / 0.95) !important;
}

.tw-text-teal-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(20 184 166 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-500\/0 {
  color: rgb(20 184 166 / 0) !important;
}

.tw-text-teal-500\/10 {
  color: rgb(20 184 166 / 0.1) !important;
}

.tw-text-teal-500\/100 {
  color: rgb(20 184 166 / 1) !important;
}

.tw-text-teal-500\/15 {
  color: rgb(20 184 166 / 0.15) !important;
}

.tw-text-teal-500\/20 {
  color: rgb(20 184 166 / 0.2) !important;
}

.tw-text-teal-500\/25 {
  color: rgb(20 184 166 / 0.25) !important;
}

.tw-text-teal-500\/30 {
  color: rgb(20 184 166 / 0.3) !important;
}

.tw-text-teal-500\/35 {
  color: rgb(20 184 166 / 0.35) !important;
}

.tw-text-teal-500\/40 {
  color: rgb(20 184 166 / 0.4) !important;
}

.tw-text-teal-500\/45 {
  color: rgb(20 184 166 / 0.45) !important;
}

.tw-text-teal-500\/5 {
  color: rgb(20 184 166 / 0.05) !important;
}

.tw-text-teal-500\/50 {
  color: rgb(20 184 166 / 0.5) !important;
}

.tw-text-teal-500\/55 {
  color: rgb(20 184 166 / 0.55) !important;
}

.tw-text-teal-500\/60 {
  color: rgb(20 184 166 / 0.6) !important;
}

.tw-text-teal-500\/65 {
  color: rgb(20 184 166 / 0.65) !important;
}

.tw-text-teal-500\/70 {
  color: rgb(20 184 166 / 0.7) !important;
}

.tw-text-teal-500\/75 {
  color: rgb(20 184 166 / 0.75) !important;
}

.tw-text-teal-500\/80 {
  color: rgb(20 184 166 / 0.8) !important;
}

.tw-text-teal-500\/85 {
  color: rgb(20 184 166 / 0.85) !important;
}

.tw-text-teal-500\/90 {
  color: rgb(20 184 166 / 0.9) !important;
}

.tw-text-teal-500\/95 {
  color: rgb(20 184 166 / 0.95) !important;
}

.tw-text-teal-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(13 148 136 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-600\/0 {
  color: rgb(13 148 136 / 0) !important;
}

.tw-text-teal-600\/10 {
  color: rgb(13 148 136 / 0.1) !important;
}

.tw-text-teal-600\/100 {
  color: rgb(13 148 136 / 1) !important;
}

.tw-text-teal-600\/15 {
  color: rgb(13 148 136 / 0.15) !important;
}

.tw-text-teal-600\/20 {
  color: rgb(13 148 136 / 0.2) !important;
}

.tw-text-teal-600\/25 {
  color: rgb(13 148 136 / 0.25) !important;
}

.tw-text-teal-600\/30 {
  color: rgb(13 148 136 / 0.3) !important;
}

.tw-text-teal-600\/35 {
  color: rgb(13 148 136 / 0.35) !important;
}

.tw-text-teal-600\/40 {
  color: rgb(13 148 136 / 0.4) !important;
}

.tw-text-teal-600\/45 {
  color: rgb(13 148 136 / 0.45) !important;
}

.tw-text-teal-600\/5 {
  color: rgb(13 148 136 / 0.05) !important;
}

.tw-text-teal-600\/50 {
  color: rgb(13 148 136 / 0.5) !important;
}

.tw-text-teal-600\/55 {
  color: rgb(13 148 136 / 0.55) !important;
}

.tw-text-teal-600\/60 {
  color: rgb(13 148 136 / 0.6) !important;
}

.tw-text-teal-600\/65 {
  color: rgb(13 148 136 / 0.65) !important;
}

.tw-text-teal-600\/70 {
  color: rgb(13 148 136 / 0.7) !important;
}

.tw-text-teal-600\/75 {
  color: rgb(13 148 136 / 0.75) !important;
}

.tw-text-teal-600\/80 {
  color: rgb(13 148 136 / 0.8) !important;
}

.tw-text-teal-600\/85 {
  color: rgb(13 148 136 / 0.85) !important;
}

.tw-text-teal-600\/90 {
  color: rgb(13 148 136 / 0.9) !important;
}

.tw-text-teal-600\/95 {
  color: rgb(13 148 136 / 0.95) !important;
}

.tw-text-teal-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(15 118 110 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-700\/0 {
  color: rgb(15 118 110 / 0) !important;
}

.tw-text-teal-700\/10 {
  color: rgb(15 118 110 / 0.1) !important;
}

.tw-text-teal-700\/100 {
  color: rgb(15 118 110 / 1) !important;
}

.tw-text-teal-700\/15 {
  color: rgb(15 118 110 / 0.15) !important;
}

.tw-text-teal-700\/20 {
  color: rgb(15 118 110 / 0.2) !important;
}

.tw-text-teal-700\/25 {
  color: rgb(15 118 110 / 0.25) !important;
}

.tw-text-teal-700\/30 {
  color: rgb(15 118 110 / 0.3) !important;
}

.tw-text-teal-700\/35 {
  color: rgb(15 118 110 / 0.35) !important;
}

.tw-text-teal-700\/40 {
  color: rgb(15 118 110 / 0.4) !important;
}

.tw-text-teal-700\/45 {
  color: rgb(15 118 110 / 0.45) !important;
}

.tw-text-teal-700\/5 {
  color: rgb(15 118 110 / 0.05) !important;
}

.tw-text-teal-700\/50 {
  color: rgb(15 118 110 / 0.5) !important;
}

.tw-text-teal-700\/55 {
  color: rgb(15 118 110 / 0.55) !important;
}

.tw-text-teal-700\/60 {
  color: rgb(15 118 110 / 0.6) !important;
}

.tw-text-teal-700\/65 {
  color: rgb(15 118 110 / 0.65) !important;
}

.tw-text-teal-700\/70 {
  color: rgb(15 118 110 / 0.7) !important;
}

.tw-text-teal-700\/75 {
  color: rgb(15 118 110 / 0.75) !important;
}

.tw-text-teal-700\/80 {
  color: rgb(15 118 110 / 0.8) !important;
}

.tw-text-teal-700\/85 {
  color: rgb(15 118 110 / 0.85) !important;
}

.tw-text-teal-700\/90 {
  color: rgb(15 118 110 / 0.9) !important;
}

.tw-text-teal-700\/95 {
  color: rgb(15 118 110 / 0.95) !important;
}

.tw-text-teal-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(17 94 89 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-800\/0 {
  color: rgb(17 94 89 / 0) !important;
}

.tw-text-teal-800\/10 {
  color: rgb(17 94 89 / 0.1) !important;
}

.tw-text-teal-800\/100 {
  color: rgb(17 94 89 / 1) !important;
}

.tw-text-teal-800\/15 {
  color: rgb(17 94 89 / 0.15) !important;
}

.tw-text-teal-800\/20 {
  color: rgb(17 94 89 / 0.2) !important;
}

.tw-text-teal-800\/25 {
  color: rgb(17 94 89 / 0.25) !important;
}

.tw-text-teal-800\/30 {
  color: rgb(17 94 89 / 0.3) !important;
}

.tw-text-teal-800\/35 {
  color: rgb(17 94 89 / 0.35) !important;
}

.tw-text-teal-800\/40 {
  color: rgb(17 94 89 / 0.4) !important;
}

.tw-text-teal-800\/45 {
  color: rgb(17 94 89 / 0.45) !important;
}

.tw-text-teal-800\/5 {
  color: rgb(17 94 89 / 0.05) !important;
}

.tw-text-teal-800\/50 {
  color: rgb(17 94 89 / 0.5) !important;
}

.tw-text-teal-800\/55 {
  color: rgb(17 94 89 / 0.55) !important;
}

.tw-text-teal-800\/60 {
  color: rgb(17 94 89 / 0.6) !important;
}

.tw-text-teal-800\/65 {
  color: rgb(17 94 89 / 0.65) !important;
}

.tw-text-teal-800\/70 {
  color: rgb(17 94 89 / 0.7) !important;
}

.tw-text-teal-800\/75 {
  color: rgb(17 94 89 / 0.75) !important;
}

.tw-text-teal-800\/80 {
  color: rgb(17 94 89 / 0.8) !important;
}

.tw-text-teal-800\/85 {
  color: rgb(17 94 89 / 0.85) !important;
}

.tw-text-teal-800\/90 {
  color: rgb(17 94 89 / 0.9) !important;
}

.tw-text-teal-800\/95 {
  color: rgb(17 94 89 / 0.95) !important;
}

.tw-text-teal-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(19 78 74 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-900\/0 {
  color: rgb(19 78 74 / 0) !important;
}

.tw-text-teal-900\/10 {
  color: rgb(19 78 74 / 0.1) !important;
}

.tw-text-teal-900\/100 {
  color: rgb(19 78 74 / 1) !important;
}

.tw-text-teal-900\/15 {
  color: rgb(19 78 74 / 0.15) !important;
}

.tw-text-teal-900\/20 {
  color: rgb(19 78 74 / 0.2) !important;
}

.tw-text-teal-900\/25 {
  color: rgb(19 78 74 / 0.25) !important;
}

.tw-text-teal-900\/30 {
  color: rgb(19 78 74 / 0.3) !important;
}

.tw-text-teal-900\/35 {
  color: rgb(19 78 74 / 0.35) !important;
}

.tw-text-teal-900\/40 {
  color: rgb(19 78 74 / 0.4) !important;
}

.tw-text-teal-900\/45 {
  color: rgb(19 78 74 / 0.45) !important;
}

.tw-text-teal-900\/5 {
  color: rgb(19 78 74 / 0.05) !important;
}

.tw-text-teal-900\/50 {
  color: rgb(19 78 74 / 0.5) !important;
}

.tw-text-teal-900\/55 {
  color: rgb(19 78 74 / 0.55) !important;
}

.tw-text-teal-900\/60 {
  color: rgb(19 78 74 / 0.6) !important;
}

.tw-text-teal-900\/65 {
  color: rgb(19 78 74 / 0.65) !important;
}

.tw-text-teal-900\/70 {
  color: rgb(19 78 74 / 0.7) !important;
}

.tw-text-teal-900\/75 {
  color: rgb(19 78 74 / 0.75) !important;
}

.tw-text-teal-900\/80 {
  color: rgb(19 78 74 / 0.8) !important;
}

.tw-text-teal-900\/85 {
  color: rgb(19 78 74 / 0.85) !important;
}

.tw-text-teal-900\/90 {
  color: rgb(19 78 74 / 0.9) !important;
}

.tw-text-teal-900\/95 {
  color: rgb(19 78 74 / 0.95) !important;
}

.tw-text-teal-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(4 47 46 / var(--tw-text-opacity)) !important;
}

.tw-text-teal-950\/0 {
  color: rgb(4 47 46 / 0) !important;
}

.tw-text-teal-950\/10 {
  color: rgb(4 47 46 / 0.1) !important;
}

.tw-text-teal-950\/100 {
  color: rgb(4 47 46 / 1) !important;
}

.tw-text-teal-950\/15 {
  color: rgb(4 47 46 / 0.15) !important;
}

.tw-text-teal-950\/20 {
  color: rgb(4 47 46 / 0.2) !important;
}

.tw-text-teal-950\/25 {
  color: rgb(4 47 46 / 0.25) !important;
}

.tw-text-teal-950\/30 {
  color: rgb(4 47 46 / 0.3) !important;
}

.tw-text-teal-950\/35 {
  color: rgb(4 47 46 / 0.35) !important;
}

.tw-text-teal-950\/40 {
  color: rgb(4 47 46 / 0.4) !important;
}

.tw-text-teal-950\/45 {
  color: rgb(4 47 46 / 0.45) !important;
}

.tw-text-teal-950\/5 {
  color: rgb(4 47 46 / 0.05) !important;
}

.tw-text-teal-950\/50 {
  color: rgb(4 47 46 / 0.5) !important;
}

.tw-text-teal-950\/55 {
  color: rgb(4 47 46 / 0.55) !important;
}

.tw-text-teal-950\/60 {
  color: rgb(4 47 46 / 0.6) !important;
}

.tw-text-teal-950\/65 {
  color: rgb(4 47 46 / 0.65) !important;
}

.tw-text-teal-950\/70 {
  color: rgb(4 47 46 / 0.7) !important;
}

.tw-text-teal-950\/75 {
  color: rgb(4 47 46 / 0.75) !important;
}

.tw-text-teal-950\/80 {
  color: rgb(4 47 46 / 0.8) !important;
}

.tw-text-teal-950\/85 {
  color: rgb(4 47 46 / 0.85) !important;
}

.tw-text-teal-950\/90 {
  color: rgb(4 47 46 / 0.9) !important;
}

.tw-text-teal-950\/95 {
  color: rgb(4 47 46 / 0.95) !important;
}

.tw-text-transparent {
  color: transparent !important;
}

.tw-text-transparent\/0 {
  color: rgb(0 0 0 / 0) !important;
}

.tw-text-transparent\/10 {
  color: rgb(0 0 0 / 0.1) !important;
}

.tw-text-transparent\/100 {
  color: rgb(0 0 0 / 1) !important;
}

.tw-text-transparent\/15 {
  color: rgb(0 0 0 / 0.15) !important;
}

.tw-text-transparent\/20 {
  color: rgb(0 0 0 / 0.2) !important;
}

.tw-text-transparent\/25 {
  color: rgb(0 0 0 / 0.25) !important;
}

.tw-text-transparent\/30 {
  color: rgb(0 0 0 / 0.3) !important;
}

.tw-text-transparent\/35 {
  color: rgb(0 0 0 / 0.35) !important;
}

.tw-text-transparent\/40 {
  color: rgb(0 0 0 / 0.4) !important;
}

.tw-text-transparent\/45 {
  color: rgb(0 0 0 / 0.45) !important;
}

.tw-text-transparent\/5 {
  color: rgb(0 0 0 / 0.05) !important;
}

.tw-text-transparent\/50 {
  color: rgb(0 0 0 / 0.5) !important;
}

.tw-text-transparent\/55 {
  color: rgb(0 0 0 / 0.55) !important;
}

.tw-text-transparent\/60 {
  color: rgb(0 0 0 / 0.6) !important;
}

.tw-text-transparent\/65 {
  color: rgb(0 0 0 / 0.65) !important;
}

.tw-text-transparent\/70 {
  color: rgb(0 0 0 / 0.7) !important;
}

.tw-text-transparent\/75 {
  color: rgb(0 0 0 / 0.75) !important;
}

.tw-text-transparent\/80 {
  color: rgb(0 0 0 / 0.8) !important;
}

.tw-text-transparent\/85 {
  color: rgb(0 0 0 / 0.85) !important;
}

.tw-text-transparent\/90 {
  color: rgb(0 0 0 / 0.9) !important;
}

.tw-text-transparent\/95 {
  color: rgb(0 0 0 / 0.95) !important;
}

.tw-text-violet-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(237 233 254 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-100\/0 {
  color: rgb(237 233 254 / 0) !important;
}

.tw-text-violet-100\/10 {
  color: rgb(237 233 254 / 0.1) !important;
}

.tw-text-violet-100\/100 {
  color: rgb(237 233 254 / 1) !important;
}

.tw-text-violet-100\/15 {
  color: rgb(237 233 254 / 0.15) !important;
}

.tw-text-violet-100\/20 {
  color: rgb(237 233 254 / 0.2) !important;
}

.tw-text-violet-100\/25 {
  color: rgb(237 233 254 / 0.25) !important;
}

.tw-text-violet-100\/30 {
  color: rgb(237 233 254 / 0.3) !important;
}

.tw-text-violet-100\/35 {
  color: rgb(237 233 254 / 0.35) !important;
}

.tw-text-violet-100\/40 {
  color: rgb(237 233 254 / 0.4) !important;
}

.tw-text-violet-100\/45 {
  color: rgb(237 233 254 / 0.45) !important;
}

.tw-text-violet-100\/5 {
  color: rgb(237 233 254 / 0.05) !important;
}

.tw-text-violet-100\/50 {
  color: rgb(237 233 254 / 0.5) !important;
}

.tw-text-violet-100\/55 {
  color: rgb(237 233 254 / 0.55) !important;
}

.tw-text-violet-100\/60 {
  color: rgb(237 233 254 / 0.6) !important;
}

.tw-text-violet-100\/65 {
  color: rgb(237 233 254 / 0.65) !important;
}

.tw-text-violet-100\/70 {
  color: rgb(237 233 254 / 0.7) !important;
}

.tw-text-violet-100\/75 {
  color: rgb(237 233 254 / 0.75) !important;
}

.tw-text-violet-100\/80 {
  color: rgb(237 233 254 / 0.8) !important;
}

.tw-text-violet-100\/85 {
  color: rgb(237 233 254 / 0.85) !important;
}

.tw-text-violet-100\/90 {
  color: rgb(237 233 254 / 0.9) !important;
}

.tw-text-violet-100\/95 {
  color: rgb(237 233 254 / 0.95) !important;
}

.tw-text-violet-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(221 214 254 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-200\/0 {
  color: rgb(221 214 254 / 0) !important;
}

.tw-text-violet-200\/10 {
  color: rgb(221 214 254 / 0.1) !important;
}

.tw-text-violet-200\/100 {
  color: rgb(221 214 254 / 1) !important;
}

.tw-text-violet-200\/15 {
  color: rgb(221 214 254 / 0.15) !important;
}

.tw-text-violet-200\/20 {
  color: rgb(221 214 254 / 0.2) !important;
}

.tw-text-violet-200\/25 {
  color: rgb(221 214 254 / 0.25) !important;
}

.tw-text-violet-200\/30 {
  color: rgb(221 214 254 / 0.3) !important;
}

.tw-text-violet-200\/35 {
  color: rgb(221 214 254 / 0.35) !important;
}

.tw-text-violet-200\/40 {
  color: rgb(221 214 254 / 0.4) !important;
}

.tw-text-violet-200\/45 {
  color: rgb(221 214 254 / 0.45) !important;
}

.tw-text-violet-200\/5 {
  color: rgb(221 214 254 / 0.05) !important;
}

.tw-text-violet-200\/50 {
  color: rgb(221 214 254 / 0.5) !important;
}

.tw-text-violet-200\/55 {
  color: rgb(221 214 254 / 0.55) !important;
}

.tw-text-violet-200\/60 {
  color: rgb(221 214 254 / 0.6) !important;
}

.tw-text-violet-200\/65 {
  color: rgb(221 214 254 / 0.65) !important;
}

.tw-text-violet-200\/70 {
  color: rgb(221 214 254 / 0.7) !important;
}

.tw-text-violet-200\/75 {
  color: rgb(221 214 254 / 0.75) !important;
}

.tw-text-violet-200\/80 {
  color: rgb(221 214 254 / 0.8) !important;
}

.tw-text-violet-200\/85 {
  color: rgb(221 214 254 / 0.85) !important;
}

.tw-text-violet-200\/90 {
  color: rgb(221 214 254 / 0.9) !important;
}

.tw-text-violet-200\/95 {
  color: rgb(221 214 254 / 0.95) !important;
}

.tw-text-violet-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(196 181 253 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-300\/0 {
  color: rgb(196 181 253 / 0) !important;
}

.tw-text-violet-300\/10 {
  color: rgb(196 181 253 / 0.1) !important;
}

.tw-text-violet-300\/100 {
  color: rgb(196 181 253 / 1) !important;
}

.tw-text-violet-300\/15 {
  color: rgb(196 181 253 / 0.15) !important;
}

.tw-text-violet-300\/20 {
  color: rgb(196 181 253 / 0.2) !important;
}

.tw-text-violet-300\/25 {
  color: rgb(196 181 253 / 0.25) !important;
}

.tw-text-violet-300\/30 {
  color: rgb(196 181 253 / 0.3) !important;
}

.tw-text-violet-300\/35 {
  color: rgb(196 181 253 / 0.35) !important;
}

.tw-text-violet-300\/40 {
  color: rgb(196 181 253 / 0.4) !important;
}

.tw-text-violet-300\/45 {
  color: rgb(196 181 253 / 0.45) !important;
}

.tw-text-violet-300\/5 {
  color: rgb(196 181 253 / 0.05) !important;
}

.tw-text-violet-300\/50 {
  color: rgb(196 181 253 / 0.5) !important;
}

.tw-text-violet-300\/55 {
  color: rgb(196 181 253 / 0.55) !important;
}

.tw-text-violet-300\/60 {
  color: rgb(196 181 253 / 0.6) !important;
}

.tw-text-violet-300\/65 {
  color: rgb(196 181 253 / 0.65) !important;
}

.tw-text-violet-300\/70 {
  color: rgb(196 181 253 / 0.7) !important;
}

.tw-text-violet-300\/75 {
  color: rgb(196 181 253 / 0.75) !important;
}

.tw-text-violet-300\/80 {
  color: rgb(196 181 253 / 0.8) !important;
}

.tw-text-violet-300\/85 {
  color: rgb(196 181 253 / 0.85) !important;
}

.tw-text-violet-300\/90 {
  color: rgb(196 181 253 / 0.9) !important;
}

.tw-text-violet-300\/95 {
  color: rgb(196 181 253 / 0.95) !important;
}

.tw-text-violet-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(167 139 250 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-400\/0 {
  color: rgb(167 139 250 / 0) !important;
}

.tw-text-violet-400\/10 {
  color: rgb(167 139 250 / 0.1) !important;
}

.tw-text-violet-400\/100 {
  color: rgb(167 139 250 / 1) !important;
}

.tw-text-violet-400\/15 {
  color: rgb(167 139 250 / 0.15) !important;
}

.tw-text-violet-400\/20 {
  color: rgb(167 139 250 / 0.2) !important;
}

.tw-text-violet-400\/25 {
  color: rgb(167 139 250 / 0.25) !important;
}

.tw-text-violet-400\/30 {
  color: rgb(167 139 250 / 0.3) !important;
}

.tw-text-violet-400\/35 {
  color: rgb(167 139 250 / 0.35) !important;
}

.tw-text-violet-400\/40 {
  color: rgb(167 139 250 / 0.4) !important;
}

.tw-text-violet-400\/45 {
  color: rgb(167 139 250 / 0.45) !important;
}

.tw-text-violet-400\/5 {
  color: rgb(167 139 250 / 0.05) !important;
}

.tw-text-violet-400\/50 {
  color: rgb(167 139 250 / 0.5) !important;
}

.tw-text-violet-400\/55 {
  color: rgb(167 139 250 / 0.55) !important;
}

.tw-text-violet-400\/60 {
  color: rgb(167 139 250 / 0.6) !important;
}

.tw-text-violet-400\/65 {
  color: rgb(167 139 250 / 0.65) !important;
}

.tw-text-violet-400\/70 {
  color: rgb(167 139 250 / 0.7) !important;
}

.tw-text-violet-400\/75 {
  color: rgb(167 139 250 / 0.75) !important;
}

.tw-text-violet-400\/80 {
  color: rgb(167 139 250 / 0.8) !important;
}

.tw-text-violet-400\/85 {
  color: rgb(167 139 250 / 0.85) !important;
}

.tw-text-violet-400\/90 {
  color: rgb(167 139 250 / 0.9) !important;
}

.tw-text-violet-400\/95 {
  color: rgb(167 139 250 / 0.95) !important;
}

.tw-text-violet-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(245 243 255 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-50\/0 {
  color: rgb(245 243 255 / 0) !important;
}

.tw-text-violet-50\/10 {
  color: rgb(245 243 255 / 0.1) !important;
}

.tw-text-violet-50\/100 {
  color: rgb(245 243 255 / 1) !important;
}

.tw-text-violet-50\/15 {
  color: rgb(245 243 255 / 0.15) !important;
}

.tw-text-violet-50\/20 {
  color: rgb(245 243 255 / 0.2) !important;
}

.tw-text-violet-50\/25 {
  color: rgb(245 243 255 / 0.25) !important;
}

.tw-text-violet-50\/30 {
  color: rgb(245 243 255 / 0.3) !important;
}

.tw-text-violet-50\/35 {
  color: rgb(245 243 255 / 0.35) !important;
}

.tw-text-violet-50\/40 {
  color: rgb(245 243 255 / 0.4) !important;
}

.tw-text-violet-50\/45 {
  color: rgb(245 243 255 / 0.45) !important;
}

.tw-text-violet-50\/5 {
  color: rgb(245 243 255 / 0.05) !important;
}

.tw-text-violet-50\/50 {
  color: rgb(245 243 255 / 0.5) !important;
}

.tw-text-violet-50\/55 {
  color: rgb(245 243 255 / 0.55) !important;
}

.tw-text-violet-50\/60 {
  color: rgb(245 243 255 / 0.6) !important;
}

.tw-text-violet-50\/65 {
  color: rgb(245 243 255 / 0.65) !important;
}

.tw-text-violet-50\/70 {
  color: rgb(245 243 255 / 0.7) !important;
}

.tw-text-violet-50\/75 {
  color: rgb(245 243 255 / 0.75) !important;
}

.tw-text-violet-50\/80 {
  color: rgb(245 243 255 / 0.8) !important;
}

.tw-text-violet-50\/85 {
  color: rgb(245 243 255 / 0.85) !important;
}

.tw-text-violet-50\/90 {
  color: rgb(245 243 255 / 0.9) !important;
}

.tw-text-violet-50\/95 {
  color: rgb(245 243 255 / 0.95) !important;
}

.tw-text-violet-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(139 92 246 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-500\/0 {
  color: rgb(139 92 246 / 0) !important;
}

.tw-text-violet-500\/10 {
  color: rgb(139 92 246 / 0.1) !important;
}

.tw-text-violet-500\/100 {
  color: rgb(139 92 246 / 1) !important;
}

.tw-text-violet-500\/15 {
  color: rgb(139 92 246 / 0.15) !important;
}

.tw-text-violet-500\/20 {
  color: rgb(139 92 246 / 0.2) !important;
}

.tw-text-violet-500\/25 {
  color: rgb(139 92 246 / 0.25) !important;
}

.tw-text-violet-500\/30 {
  color: rgb(139 92 246 / 0.3) !important;
}

.tw-text-violet-500\/35 {
  color: rgb(139 92 246 / 0.35) !important;
}

.tw-text-violet-500\/40 {
  color: rgb(139 92 246 / 0.4) !important;
}

.tw-text-violet-500\/45 {
  color: rgb(139 92 246 / 0.45) !important;
}

.tw-text-violet-500\/5 {
  color: rgb(139 92 246 / 0.05) !important;
}

.tw-text-violet-500\/50 {
  color: rgb(139 92 246 / 0.5) !important;
}

.tw-text-violet-500\/55 {
  color: rgb(139 92 246 / 0.55) !important;
}

.tw-text-violet-500\/60 {
  color: rgb(139 92 246 / 0.6) !important;
}

.tw-text-violet-500\/65 {
  color: rgb(139 92 246 / 0.65) !important;
}

.tw-text-violet-500\/70 {
  color: rgb(139 92 246 / 0.7) !important;
}

.tw-text-violet-500\/75 {
  color: rgb(139 92 246 / 0.75) !important;
}

.tw-text-violet-500\/80 {
  color: rgb(139 92 246 / 0.8) !important;
}

.tw-text-violet-500\/85 {
  color: rgb(139 92 246 / 0.85) !important;
}

.tw-text-violet-500\/90 {
  color: rgb(139 92 246 / 0.9) !important;
}

.tw-text-violet-500\/95 {
  color: rgb(139 92 246 / 0.95) !important;
}

.tw-text-violet-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(124 58 237 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-600\/0 {
  color: rgb(124 58 237 / 0) !important;
}

.tw-text-violet-600\/10 {
  color: rgb(124 58 237 / 0.1) !important;
}

.tw-text-violet-600\/100 {
  color: rgb(124 58 237 / 1) !important;
}

.tw-text-violet-600\/15 {
  color: rgb(124 58 237 / 0.15) !important;
}

.tw-text-violet-600\/20 {
  color: rgb(124 58 237 / 0.2) !important;
}

.tw-text-violet-600\/25 {
  color: rgb(124 58 237 / 0.25) !important;
}

.tw-text-violet-600\/30 {
  color: rgb(124 58 237 / 0.3) !important;
}

.tw-text-violet-600\/35 {
  color: rgb(124 58 237 / 0.35) !important;
}

.tw-text-violet-600\/40 {
  color: rgb(124 58 237 / 0.4) !important;
}

.tw-text-violet-600\/45 {
  color: rgb(124 58 237 / 0.45) !important;
}

.tw-text-violet-600\/5 {
  color: rgb(124 58 237 / 0.05) !important;
}

.tw-text-violet-600\/50 {
  color: rgb(124 58 237 / 0.5) !important;
}

.tw-text-violet-600\/55 {
  color: rgb(124 58 237 / 0.55) !important;
}

.tw-text-violet-600\/60 {
  color: rgb(124 58 237 / 0.6) !important;
}

.tw-text-violet-600\/65 {
  color: rgb(124 58 237 / 0.65) !important;
}

.tw-text-violet-600\/70 {
  color: rgb(124 58 237 / 0.7) !important;
}

.tw-text-violet-600\/75 {
  color: rgb(124 58 237 / 0.75) !important;
}

.tw-text-violet-600\/80 {
  color: rgb(124 58 237 / 0.8) !important;
}

.tw-text-violet-600\/85 {
  color: rgb(124 58 237 / 0.85) !important;
}

.tw-text-violet-600\/90 {
  color: rgb(124 58 237 / 0.9) !important;
}

.tw-text-violet-600\/95 {
  color: rgb(124 58 237 / 0.95) !important;
}

.tw-text-violet-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(109 40 217 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-700\/0 {
  color: rgb(109 40 217 / 0) !important;
}

.tw-text-violet-700\/10 {
  color: rgb(109 40 217 / 0.1) !important;
}

.tw-text-violet-700\/100 {
  color: rgb(109 40 217 / 1) !important;
}

.tw-text-violet-700\/15 {
  color: rgb(109 40 217 / 0.15) !important;
}

.tw-text-violet-700\/20 {
  color: rgb(109 40 217 / 0.2) !important;
}

.tw-text-violet-700\/25 {
  color: rgb(109 40 217 / 0.25) !important;
}

.tw-text-violet-700\/30 {
  color: rgb(109 40 217 / 0.3) !important;
}

.tw-text-violet-700\/35 {
  color: rgb(109 40 217 / 0.35) !important;
}

.tw-text-violet-700\/40 {
  color: rgb(109 40 217 / 0.4) !important;
}

.tw-text-violet-700\/45 {
  color: rgb(109 40 217 / 0.45) !important;
}

.tw-text-violet-700\/5 {
  color: rgb(109 40 217 / 0.05) !important;
}

.tw-text-violet-700\/50 {
  color: rgb(109 40 217 / 0.5) !important;
}

.tw-text-violet-700\/55 {
  color: rgb(109 40 217 / 0.55) !important;
}

.tw-text-violet-700\/60 {
  color: rgb(109 40 217 / 0.6) !important;
}

.tw-text-violet-700\/65 {
  color: rgb(109 40 217 / 0.65) !important;
}

.tw-text-violet-700\/70 {
  color: rgb(109 40 217 / 0.7) !important;
}

.tw-text-violet-700\/75 {
  color: rgb(109 40 217 / 0.75) !important;
}

.tw-text-violet-700\/80 {
  color: rgb(109 40 217 / 0.8) !important;
}

.tw-text-violet-700\/85 {
  color: rgb(109 40 217 / 0.85) !important;
}

.tw-text-violet-700\/90 {
  color: rgb(109 40 217 / 0.9) !important;
}

.tw-text-violet-700\/95 {
  color: rgb(109 40 217 / 0.95) !important;
}

.tw-text-violet-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(91 33 182 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-800\/0 {
  color: rgb(91 33 182 / 0) !important;
}

.tw-text-violet-800\/10 {
  color: rgb(91 33 182 / 0.1) !important;
}

.tw-text-violet-800\/100 {
  color: rgb(91 33 182 / 1) !important;
}

.tw-text-violet-800\/15 {
  color: rgb(91 33 182 / 0.15) !important;
}

.tw-text-violet-800\/20 {
  color: rgb(91 33 182 / 0.2) !important;
}

.tw-text-violet-800\/25 {
  color: rgb(91 33 182 / 0.25) !important;
}

.tw-text-violet-800\/30 {
  color: rgb(91 33 182 / 0.3) !important;
}

.tw-text-violet-800\/35 {
  color: rgb(91 33 182 / 0.35) !important;
}

.tw-text-violet-800\/40 {
  color: rgb(91 33 182 / 0.4) !important;
}

.tw-text-violet-800\/45 {
  color: rgb(91 33 182 / 0.45) !important;
}

.tw-text-violet-800\/5 {
  color: rgb(91 33 182 / 0.05) !important;
}

.tw-text-violet-800\/50 {
  color: rgb(91 33 182 / 0.5) !important;
}

.tw-text-violet-800\/55 {
  color: rgb(91 33 182 / 0.55) !important;
}

.tw-text-violet-800\/60 {
  color: rgb(91 33 182 / 0.6) !important;
}

.tw-text-violet-800\/65 {
  color: rgb(91 33 182 / 0.65) !important;
}

.tw-text-violet-800\/70 {
  color: rgb(91 33 182 / 0.7) !important;
}

.tw-text-violet-800\/75 {
  color: rgb(91 33 182 / 0.75) !important;
}

.tw-text-violet-800\/80 {
  color: rgb(91 33 182 / 0.8) !important;
}

.tw-text-violet-800\/85 {
  color: rgb(91 33 182 / 0.85) !important;
}

.tw-text-violet-800\/90 {
  color: rgb(91 33 182 / 0.9) !important;
}

.tw-text-violet-800\/95 {
  color: rgb(91 33 182 / 0.95) !important;
}

.tw-text-violet-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(76 29 149 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-900\/0 {
  color: rgb(76 29 149 / 0) !important;
}

.tw-text-violet-900\/10 {
  color: rgb(76 29 149 / 0.1) !important;
}

.tw-text-violet-900\/100 {
  color: rgb(76 29 149 / 1) !important;
}

.tw-text-violet-900\/15 {
  color: rgb(76 29 149 / 0.15) !important;
}

.tw-text-violet-900\/20 {
  color: rgb(76 29 149 / 0.2) !important;
}

.tw-text-violet-900\/25 {
  color: rgb(76 29 149 / 0.25) !important;
}

.tw-text-violet-900\/30 {
  color: rgb(76 29 149 / 0.3) !important;
}

.tw-text-violet-900\/35 {
  color: rgb(76 29 149 / 0.35) !important;
}

.tw-text-violet-900\/40 {
  color: rgb(76 29 149 / 0.4) !important;
}

.tw-text-violet-900\/45 {
  color: rgb(76 29 149 / 0.45) !important;
}

.tw-text-violet-900\/5 {
  color: rgb(76 29 149 / 0.05) !important;
}

.tw-text-violet-900\/50 {
  color: rgb(76 29 149 / 0.5) !important;
}

.tw-text-violet-900\/55 {
  color: rgb(76 29 149 / 0.55) !important;
}

.tw-text-violet-900\/60 {
  color: rgb(76 29 149 / 0.6) !important;
}

.tw-text-violet-900\/65 {
  color: rgb(76 29 149 / 0.65) !important;
}

.tw-text-violet-900\/70 {
  color: rgb(76 29 149 / 0.7) !important;
}

.tw-text-violet-900\/75 {
  color: rgb(76 29 149 / 0.75) !important;
}

.tw-text-violet-900\/80 {
  color: rgb(76 29 149 / 0.8) !important;
}

.tw-text-violet-900\/85 {
  color: rgb(76 29 149 / 0.85) !important;
}

.tw-text-violet-900\/90 {
  color: rgb(76 29 149 / 0.9) !important;
}

.tw-text-violet-900\/95 {
  color: rgb(76 29 149 / 0.95) !important;
}

.tw-text-violet-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(46 16 101 / var(--tw-text-opacity)) !important;
}

.tw-text-violet-950\/0 {
  color: rgb(46 16 101 / 0) !important;
}

.tw-text-violet-950\/10 {
  color: rgb(46 16 101 / 0.1) !important;
}

.tw-text-violet-950\/100 {
  color: rgb(46 16 101 / 1) !important;
}

.tw-text-violet-950\/15 {
  color: rgb(46 16 101 / 0.15) !important;
}

.tw-text-violet-950\/20 {
  color: rgb(46 16 101 / 0.2) !important;
}

.tw-text-violet-950\/25 {
  color: rgb(46 16 101 / 0.25) !important;
}

.tw-text-violet-950\/30 {
  color: rgb(46 16 101 / 0.3) !important;
}

.tw-text-violet-950\/35 {
  color: rgb(46 16 101 / 0.35) !important;
}

.tw-text-violet-950\/40 {
  color: rgb(46 16 101 / 0.4) !important;
}

.tw-text-violet-950\/45 {
  color: rgb(46 16 101 / 0.45) !important;
}

.tw-text-violet-950\/5 {
  color: rgb(46 16 101 / 0.05) !important;
}

.tw-text-violet-950\/50 {
  color: rgb(46 16 101 / 0.5) !important;
}

.tw-text-violet-950\/55 {
  color: rgb(46 16 101 / 0.55) !important;
}

.tw-text-violet-950\/60 {
  color: rgb(46 16 101 / 0.6) !important;
}

.tw-text-violet-950\/65 {
  color: rgb(46 16 101 / 0.65) !important;
}

.tw-text-violet-950\/70 {
  color: rgb(46 16 101 / 0.7) !important;
}

.tw-text-violet-950\/75 {
  color: rgb(46 16 101 / 0.75) !important;
}

.tw-text-violet-950\/80 {
  color: rgb(46 16 101 / 0.8) !important;
}

.tw-text-violet-950\/85 {
  color: rgb(46 16 101 / 0.85) !important;
}

.tw-text-violet-950\/90 {
  color: rgb(46 16 101 / 0.9) !important;
}

.tw-text-violet-950\/95 {
  color: rgb(46 16 101 / 0.95) !important;
}

.tw-text-white {
  --tw-text-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
}

.tw-text-white\/0 {
  color: rgb(255 255 255 / 0) !important;
}

.tw-text-white\/10 {
  color: rgb(255 255 255 / 0.1) !important;
}

.tw-text-white\/100 {
  color: rgb(255 255 255 / 1) !important;
}

.tw-text-white\/15 {
  color: rgb(255 255 255 / 0.15) !important;
}

.tw-text-white\/20 {
  color: rgb(255 255 255 / 0.2) !important;
}

.tw-text-white\/25 {
  color: rgb(255 255 255 / 0.25) !important;
}

.tw-text-white\/30 {
  color: rgb(255 255 255 / 0.3) !important;
}

.tw-text-white\/35 {
  color: rgb(255 255 255 / 0.35) !important;
}

.tw-text-white\/40 {
  color: rgb(255 255 255 / 0.4) !important;
}

.tw-text-white\/45 {
  color: rgb(255 255 255 / 0.45) !important;
}

.tw-text-white\/5 {
  color: rgb(255 255 255 / 0.05) !important;
}

.tw-text-white\/50 {
  color: rgb(255 255 255 / 0.5) !important;
}

.tw-text-white\/55 {
  color: rgb(255 255 255 / 0.55) !important;
}

.tw-text-white\/60 {
  color: rgb(255 255 255 / 0.6) !important;
}

.tw-text-white\/65 {
  color: rgb(255 255 255 / 0.65) !important;
}

.tw-text-white\/70 {
  color: rgb(255 255 255 / 0.7) !important;
}

.tw-text-white\/75 {
  color: rgb(255 255 255 / 0.75) !important;
}

.tw-text-white\/80 {
  color: rgb(255 255 255 / 0.8) !important;
}

.tw-text-white\/85 {
  color: rgb(255 255 255 / 0.85) !important;
}

.tw-text-white\/90 {
  color: rgb(255 255 255 / 0.9) !important;
}

.tw-text-white\/95 {
  color: rgb(255 255 255 / 0.95) !important;
}

.tw-text-yellow-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 249 195 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-100\/0 {
  color: rgb(254 249 195 / 0) !important;
}

.tw-text-yellow-100\/10 {
  color: rgb(254 249 195 / 0.1) !important;
}

.tw-text-yellow-100\/100 {
  color: rgb(254 249 195 / 1) !important;
}

.tw-text-yellow-100\/15 {
  color: rgb(254 249 195 / 0.15) !important;
}

.tw-text-yellow-100\/20 {
  color: rgb(254 249 195 / 0.2) !important;
}

.tw-text-yellow-100\/25 {
  color: rgb(254 249 195 / 0.25) !important;
}

.tw-text-yellow-100\/30 {
  color: rgb(254 249 195 / 0.3) !important;
}

.tw-text-yellow-100\/35 {
  color: rgb(254 249 195 / 0.35) !important;
}

.tw-text-yellow-100\/40 {
  color: rgb(254 249 195 / 0.4) !important;
}

.tw-text-yellow-100\/45 {
  color: rgb(254 249 195 / 0.45) !important;
}

.tw-text-yellow-100\/5 {
  color: rgb(254 249 195 / 0.05) !important;
}

.tw-text-yellow-100\/50 {
  color: rgb(254 249 195 / 0.5) !important;
}

.tw-text-yellow-100\/55 {
  color: rgb(254 249 195 / 0.55) !important;
}

.tw-text-yellow-100\/60 {
  color: rgb(254 249 195 / 0.6) !important;
}

.tw-text-yellow-100\/65 {
  color: rgb(254 249 195 / 0.65) !important;
}

.tw-text-yellow-100\/70 {
  color: rgb(254 249 195 / 0.7) !important;
}

.tw-text-yellow-100\/75 {
  color: rgb(254 249 195 / 0.75) !important;
}

.tw-text-yellow-100\/80 {
  color: rgb(254 249 195 / 0.8) !important;
}

.tw-text-yellow-100\/85 {
  color: rgb(254 249 195 / 0.85) !important;
}

.tw-text-yellow-100\/90 {
  color: rgb(254 249 195 / 0.9) !important;
}

.tw-text-yellow-100\/95 {
  color: rgb(254 249 195 / 0.95) !important;
}

.tw-text-yellow-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 240 138 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-200\/0 {
  color: rgb(254 240 138 / 0) !important;
}

.tw-text-yellow-200\/10 {
  color: rgb(254 240 138 / 0.1) !important;
}

.tw-text-yellow-200\/100 {
  color: rgb(254 240 138 / 1) !important;
}

.tw-text-yellow-200\/15 {
  color: rgb(254 240 138 / 0.15) !important;
}

.tw-text-yellow-200\/20 {
  color: rgb(254 240 138 / 0.2) !important;
}

.tw-text-yellow-200\/25 {
  color: rgb(254 240 138 / 0.25) !important;
}

.tw-text-yellow-200\/30 {
  color: rgb(254 240 138 / 0.3) !important;
}

.tw-text-yellow-200\/35 {
  color: rgb(254 240 138 / 0.35) !important;
}

.tw-text-yellow-200\/40 {
  color: rgb(254 240 138 / 0.4) !important;
}

.tw-text-yellow-200\/45 {
  color: rgb(254 240 138 / 0.45) !important;
}

.tw-text-yellow-200\/5 {
  color: rgb(254 240 138 / 0.05) !important;
}

.tw-text-yellow-200\/50 {
  color: rgb(254 240 138 / 0.5) !important;
}

.tw-text-yellow-200\/55 {
  color: rgb(254 240 138 / 0.55) !important;
}

.tw-text-yellow-200\/60 {
  color: rgb(254 240 138 / 0.6) !important;
}

.tw-text-yellow-200\/65 {
  color: rgb(254 240 138 / 0.65) !important;
}

.tw-text-yellow-200\/70 {
  color: rgb(254 240 138 / 0.7) !important;
}

.tw-text-yellow-200\/75 {
  color: rgb(254 240 138 / 0.75) !important;
}

.tw-text-yellow-200\/80 {
  color: rgb(254 240 138 / 0.8) !important;
}

.tw-text-yellow-200\/85 {
  color: rgb(254 240 138 / 0.85) !important;
}

.tw-text-yellow-200\/90 {
  color: rgb(254 240 138 / 0.9) !important;
}

.tw-text-yellow-200\/95 {
  color: rgb(254 240 138 / 0.95) !important;
}

.tw-text-yellow-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(253 224 71 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-300\/0 {
  color: rgb(253 224 71 / 0) !important;
}

.tw-text-yellow-300\/10 {
  color: rgb(253 224 71 / 0.1) !important;
}

.tw-text-yellow-300\/100 {
  color: rgb(253 224 71 / 1) !important;
}

.tw-text-yellow-300\/15 {
  color: rgb(253 224 71 / 0.15) !important;
}

.tw-text-yellow-300\/20 {
  color: rgb(253 224 71 / 0.2) !important;
}

.tw-text-yellow-300\/25 {
  color: rgb(253 224 71 / 0.25) !important;
}

.tw-text-yellow-300\/30 {
  color: rgb(253 224 71 / 0.3) !important;
}

.tw-text-yellow-300\/35 {
  color: rgb(253 224 71 / 0.35) !important;
}

.tw-text-yellow-300\/40 {
  color: rgb(253 224 71 / 0.4) !important;
}

.tw-text-yellow-300\/45 {
  color: rgb(253 224 71 / 0.45) !important;
}

.tw-text-yellow-300\/5 {
  color: rgb(253 224 71 / 0.05) !important;
}

.tw-text-yellow-300\/50 {
  color: rgb(253 224 71 / 0.5) !important;
}

.tw-text-yellow-300\/55 {
  color: rgb(253 224 71 / 0.55) !important;
}

.tw-text-yellow-300\/60 {
  color: rgb(253 224 71 / 0.6) !important;
}

.tw-text-yellow-300\/65 {
  color: rgb(253 224 71 / 0.65) !important;
}

.tw-text-yellow-300\/70 {
  color: rgb(253 224 71 / 0.7) !important;
}

.tw-text-yellow-300\/75 {
  color: rgb(253 224 71 / 0.75) !important;
}

.tw-text-yellow-300\/80 {
  color: rgb(253 224 71 / 0.8) !important;
}

.tw-text-yellow-300\/85 {
  color: rgb(253 224 71 / 0.85) !important;
}

.tw-text-yellow-300\/90 {
  color: rgb(253 224 71 / 0.9) !important;
}

.tw-text-yellow-300\/95 {
  color: rgb(253 224 71 / 0.95) !important;
}

.tw-text-yellow-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(250 204 21 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-400\/0 {
  color: rgb(250 204 21 / 0) !important;
}

.tw-text-yellow-400\/10 {
  color: rgb(250 204 21 / 0.1) !important;
}

.tw-text-yellow-400\/100 {
  color: rgb(250 204 21 / 1) !important;
}

.tw-text-yellow-400\/15 {
  color: rgb(250 204 21 / 0.15) !important;
}

.tw-text-yellow-400\/20 {
  color: rgb(250 204 21 / 0.2) !important;
}

.tw-text-yellow-400\/25 {
  color: rgb(250 204 21 / 0.25) !important;
}

.tw-text-yellow-400\/30 {
  color: rgb(250 204 21 / 0.3) !important;
}

.tw-text-yellow-400\/35 {
  color: rgb(250 204 21 / 0.35) !important;
}

.tw-text-yellow-400\/40 {
  color: rgb(250 204 21 / 0.4) !important;
}

.tw-text-yellow-400\/45 {
  color: rgb(250 204 21 / 0.45) !important;
}

.tw-text-yellow-400\/5 {
  color: rgb(250 204 21 / 0.05) !important;
}

.tw-text-yellow-400\/50 {
  color: rgb(250 204 21 / 0.5) !important;
}

.tw-text-yellow-400\/55 {
  color: rgb(250 204 21 / 0.55) !important;
}

.tw-text-yellow-400\/60 {
  color: rgb(250 204 21 / 0.6) !important;
}

.tw-text-yellow-400\/65 {
  color: rgb(250 204 21 / 0.65) !important;
}

.tw-text-yellow-400\/70 {
  color: rgb(250 204 21 / 0.7) !important;
}

.tw-text-yellow-400\/75 {
  color: rgb(250 204 21 / 0.75) !important;
}

.tw-text-yellow-400\/80 {
  color: rgb(250 204 21 / 0.8) !important;
}

.tw-text-yellow-400\/85 {
  color: rgb(250 204 21 / 0.85) !important;
}

.tw-text-yellow-400\/90 {
  color: rgb(250 204 21 / 0.9) !important;
}

.tw-text-yellow-400\/95 {
  color: rgb(250 204 21 / 0.95) !important;
}

.tw-text-yellow-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(254 252 232 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-50\/0 {
  color: rgb(254 252 232 / 0) !important;
}

.tw-text-yellow-50\/10 {
  color: rgb(254 252 232 / 0.1) !important;
}

.tw-text-yellow-50\/100 {
  color: rgb(254 252 232 / 1) !important;
}

.tw-text-yellow-50\/15 {
  color: rgb(254 252 232 / 0.15) !important;
}

.tw-text-yellow-50\/20 {
  color: rgb(254 252 232 / 0.2) !important;
}

.tw-text-yellow-50\/25 {
  color: rgb(254 252 232 / 0.25) !important;
}

.tw-text-yellow-50\/30 {
  color: rgb(254 252 232 / 0.3) !important;
}

.tw-text-yellow-50\/35 {
  color: rgb(254 252 232 / 0.35) !important;
}

.tw-text-yellow-50\/40 {
  color: rgb(254 252 232 / 0.4) !important;
}

.tw-text-yellow-50\/45 {
  color: rgb(254 252 232 / 0.45) !important;
}

.tw-text-yellow-50\/5 {
  color: rgb(254 252 232 / 0.05) !important;
}

.tw-text-yellow-50\/50 {
  color: rgb(254 252 232 / 0.5) !important;
}

.tw-text-yellow-50\/55 {
  color: rgb(254 252 232 / 0.55) !important;
}

.tw-text-yellow-50\/60 {
  color: rgb(254 252 232 / 0.6) !important;
}

.tw-text-yellow-50\/65 {
  color: rgb(254 252 232 / 0.65) !important;
}

.tw-text-yellow-50\/70 {
  color: rgb(254 252 232 / 0.7) !important;
}

.tw-text-yellow-50\/75 {
  color: rgb(254 252 232 / 0.75) !important;
}

.tw-text-yellow-50\/80 {
  color: rgb(254 252 232 / 0.8) !important;
}

.tw-text-yellow-50\/85 {
  color: rgb(254 252 232 / 0.85) !important;
}

.tw-text-yellow-50\/90 {
  color: rgb(254 252 232 / 0.9) !important;
}

.tw-text-yellow-50\/95 {
  color: rgb(254 252 232 / 0.95) !important;
}

.tw-text-yellow-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-500\/0 {
  color: rgb(234 179 8 / 0) !important;
}

.tw-text-yellow-500\/10 {
  color: rgb(234 179 8 / 0.1) !important;
}

.tw-text-yellow-500\/100 {
  color: rgb(234 179 8 / 1) !important;
}

.tw-text-yellow-500\/15 {
  color: rgb(234 179 8 / 0.15) !important;
}

.tw-text-yellow-500\/20 {
  color: rgb(234 179 8 / 0.2) !important;
}

.tw-text-yellow-500\/25 {
  color: rgb(234 179 8 / 0.25) !important;
}

.tw-text-yellow-500\/30 {
  color: rgb(234 179 8 / 0.3) !important;
}

.tw-text-yellow-500\/35 {
  color: rgb(234 179 8 / 0.35) !important;
}

.tw-text-yellow-500\/40 {
  color: rgb(234 179 8 / 0.4) !important;
}

.tw-text-yellow-500\/45 {
  color: rgb(234 179 8 / 0.45) !important;
}

.tw-text-yellow-500\/5 {
  color: rgb(234 179 8 / 0.05) !important;
}

.tw-text-yellow-500\/50 {
  color: rgb(234 179 8 / 0.5) !important;
}

.tw-text-yellow-500\/55 {
  color: rgb(234 179 8 / 0.55) !important;
}

.tw-text-yellow-500\/60 {
  color: rgb(234 179 8 / 0.6) !important;
}

.tw-text-yellow-500\/65 {
  color: rgb(234 179 8 / 0.65) !important;
}

.tw-text-yellow-500\/70 {
  color: rgb(234 179 8 / 0.7) !important;
}

.tw-text-yellow-500\/75 {
  color: rgb(234 179 8 / 0.75) !important;
}

.tw-text-yellow-500\/80 {
  color: rgb(234 179 8 / 0.8) !important;
}

.tw-text-yellow-500\/85 {
  color: rgb(234 179 8 / 0.85) !important;
}

.tw-text-yellow-500\/90 {
  color: rgb(234 179 8 / 0.9) !important;
}

.tw-text-yellow-500\/95 {
  color: rgb(234 179 8 / 0.95) !important;
}

.tw-text-yellow-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(202 138 4 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-600\/0 {
  color: rgb(202 138 4 / 0) !important;
}

.tw-text-yellow-600\/10 {
  color: rgb(202 138 4 / 0.1) !important;
}

.tw-text-yellow-600\/100 {
  color: rgb(202 138 4 / 1) !important;
}

.tw-text-yellow-600\/15 {
  color: rgb(202 138 4 / 0.15) !important;
}

.tw-text-yellow-600\/20 {
  color: rgb(202 138 4 / 0.2) !important;
}

.tw-text-yellow-600\/25 {
  color: rgb(202 138 4 / 0.25) !important;
}

.tw-text-yellow-600\/30 {
  color: rgb(202 138 4 / 0.3) !important;
}

.tw-text-yellow-600\/35 {
  color: rgb(202 138 4 / 0.35) !important;
}

.tw-text-yellow-600\/40 {
  color: rgb(202 138 4 / 0.4) !important;
}

.tw-text-yellow-600\/45 {
  color: rgb(202 138 4 / 0.45) !important;
}

.tw-text-yellow-600\/5 {
  color: rgb(202 138 4 / 0.05) !important;
}

.tw-text-yellow-600\/50 {
  color: rgb(202 138 4 / 0.5) !important;
}

.tw-text-yellow-600\/55 {
  color: rgb(202 138 4 / 0.55) !important;
}

.tw-text-yellow-600\/60 {
  color: rgb(202 138 4 / 0.6) !important;
}

.tw-text-yellow-600\/65 {
  color: rgb(202 138 4 / 0.65) !important;
}

.tw-text-yellow-600\/70 {
  color: rgb(202 138 4 / 0.7) !important;
}

.tw-text-yellow-600\/75 {
  color: rgb(202 138 4 / 0.75) !important;
}

.tw-text-yellow-600\/80 {
  color: rgb(202 138 4 / 0.8) !important;
}

.tw-text-yellow-600\/85 {
  color: rgb(202 138 4 / 0.85) !important;
}

.tw-text-yellow-600\/90 {
  color: rgb(202 138 4 / 0.9) !important;
}

.tw-text-yellow-600\/95 {
  color: rgb(202 138 4 / 0.95) !important;
}

.tw-text-yellow-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(161 98 7 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-700\/0 {
  color: rgb(161 98 7 / 0) !important;
}

.tw-text-yellow-700\/10 {
  color: rgb(161 98 7 / 0.1) !important;
}

.tw-text-yellow-700\/100 {
  color: rgb(161 98 7 / 1) !important;
}

.tw-text-yellow-700\/15 {
  color: rgb(161 98 7 / 0.15) !important;
}

.tw-text-yellow-700\/20 {
  color: rgb(161 98 7 / 0.2) !important;
}

.tw-text-yellow-700\/25 {
  color: rgb(161 98 7 / 0.25) !important;
}

.tw-text-yellow-700\/30 {
  color: rgb(161 98 7 / 0.3) !important;
}

.tw-text-yellow-700\/35 {
  color: rgb(161 98 7 / 0.35) !important;
}

.tw-text-yellow-700\/40 {
  color: rgb(161 98 7 / 0.4) !important;
}

.tw-text-yellow-700\/45 {
  color: rgb(161 98 7 / 0.45) !important;
}

.tw-text-yellow-700\/5 {
  color: rgb(161 98 7 / 0.05) !important;
}

.tw-text-yellow-700\/50 {
  color: rgb(161 98 7 / 0.5) !important;
}

.tw-text-yellow-700\/55 {
  color: rgb(161 98 7 / 0.55) !important;
}

.tw-text-yellow-700\/60 {
  color: rgb(161 98 7 / 0.6) !important;
}

.tw-text-yellow-700\/65 {
  color: rgb(161 98 7 / 0.65) !important;
}

.tw-text-yellow-700\/70 {
  color: rgb(161 98 7 / 0.7) !important;
}

.tw-text-yellow-700\/75 {
  color: rgb(161 98 7 / 0.75) !important;
}

.tw-text-yellow-700\/80 {
  color: rgb(161 98 7 / 0.8) !important;
}

.tw-text-yellow-700\/85 {
  color: rgb(161 98 7 / 0.85) !important;
}

.tw-text-yellow-700\/90 {
  color: rgb(161 98 7 / 0.9) !important;
}

.tw-text-yellow-700\/95 {
  color: rgb(161 98 7 / 0.95) !important;
}

.tw-text-yellow-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(133 77 14 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-800\/0 {
  color: rgb(133 77 14 / 0) !important;
}

.tw-text-yellow-800\/10 {
  color: rgb(133 77 14 / 0.1) !important;
}

.tw-text-yellow-800\/100 {
  color: rgb(133 77 14 / 1) !important;
}

.tw-text-yellow-800\/15 {
  color: rgb(133 77 14 / 0.15) !important;
}

.tw-text-yellow-800\/20 {
  color: rgb(133 77 14 / 0.2) !important;
}

.tw-text-yellow-800\/25 {
  color: rgb(133 77 14 / 0.25) !important;
}

.tw-text-yellow-800\/30 {
  color: rgb(133 77 14 / 0.3) !important;
}

.tw-text-yellow-800\/35 {
  color: rgb(133 77 14 / 0.35) !important;
}

.tw-text-yellow-800\/40 {
  color: rgb(133 77 14 / 0.4) !important;
}

.tw-text-yellow-800\/45 {
  color: rgb(133 77 14 / 0.45) !important;
}

.tw-text-yellow-800\/5 {
  color: rgb(133 77 14 / 0.05) !important;
}

.tw-text-yellow-800\/50 {
  color: rgb(133 77 14 / 0.5) !important;
}

.tw-text-yellow-800\/55 {
  color: rgb(133 77 14 / 0.55) !important;
}

.tw-text-yellow-800\/60 {
  color: rgb(133 77 14 / 0.6) !important;
}

.tw-text-yellow-800\/65 {
  color: rgb(133 77 14 / 0.65) !important;
}

.tw-text-yellow-800\/70 {
  color: rgb(133 77 14 / 0.7) !important;
}

.tw-text-yellow-800\/75 {
  color: rgb(133 77 14 / 0.75) !important;
}

.tw-text-yellow-800\/80 {
  color: rgb(133 77 14 / 0.8) !important;
}

.tw-text-yellow-800\/85 {
  color: rgb(133 77 14 / 0.85) !important;
}

.tw-text-yellow-800\/90 {
  color: rgb(133 77 14 / 0.9) !important;
}

.tw-text-yellow-800\/95 {
  color: rgb(133 77 14 / 0.95) !important;
}

.tw-text-yellow-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(113 63 18 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-900\/0 {
  color: rgb(113 63 18 / 0) !important;
}

.tw-text-yellow-900\/10 {
  color: rgb(113 63 18 / 0.1) !important;
}

.tw-text-yellow-900\/100 {
  color: rgb(113 63 18 / 1) !important;
}

.tw-text-yellow-900\/15 {
  color: rgb(113 63 18 / 0.15) !important;
}

.tw-text-yellow-900\/20 {
  color: rgb(113 63 18 / 0.2) !important;
}

.tw-text-yellow-900\/25 {
  color: rgb(113 63 18 / 0.25) !important;
}

.tw-text-yellow-900\/30 {
  color: rgb(113 63 18 / 0.3) !important;
}

.tw-text-yellow-900\/35 {
  color: rgb(113 63 18 / 0.35) !important;
}

.tw-text-yellow-900\/40 {
  color: rgb(113 63 18 / 0.4) !important;
}

.tw-text-yellow-900\/45 {
  color: rgb(113 63 18 / 0.45) !important;
}

.tw-text-yellow-900\/5 {
  color: rgb(113 63 18 / 0.05) !important;
}

.tw-text-yellow-900\/50 {
  color: rgb(113 63 18 / 0.5) !important;
}

.tw-text-yellow-900\/55 {
  color: rgb(113 63 18 / 0.55) !important;
}

.tw-text-yellow-900\/60 {
  color: rgb(113 63 18 / 0.6) !important;
}

.tw-text-yellow-900\/65 {
  color: rgb(113 63 18 / 0.65) !important;
}

.tw-text-yellow-900\/70 {
  color: rgb(113 63 18 / 0.7) !important;
}

.tw-text-yellow-900\/75 {
  color: rgb(113 63 18 / 0.75) !important;
}

.tw-text-yellow-900\/80 {
  color: rgb(113 63 18 / 0.8) !important;
}

.tw-text-yellow-900\/85 {
  color: rgb(113 63 18 / 0.85) !important;
}

.tw-text-yellow-900\/90 {
  color: rgb(113 63 18 / 0.9) !important;
}

.tw-text-yellow-900\/95 {
  color: rgb(113 63 18 / 0.95) !important;
}

.tw-text-yellow-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(66 32 6 / var(--tw-text-opacity)) !important;
}

.tw-text-yellow-950\/0 {
  color: rgb(66 32 6 / 0) !important;
}

.tw-text-yellow-950\/10 {
  color: rgb(66 32 6 / 0.1) !important;
}

.tw-text-yellow-950\/100 {
  color: rgb(66 32 6 / 1) !important;
}

.tw-text-yellow-950\/15 {
  color: rgb(66 32 6 / 0.15) !important;
}

.tw-text-yellow-950\/20 {
  color: rgb(66 32 6 / 0.2) !important;
}

.tw-text-yellow-950\/25 {
  color: rgb(66 32 6 / 0.25) !important;
}

.tw-text-yellow-950\/30 {
  color: rgb(66 32 6 / 0.3) !important;
}

.tw-text-yellow-950\/35 {
  color: rgb(66 32 6 / 0.35) !important;
}

.tw-text-yellow-950\/40 {
  color: rgb(66 32 6 / 0.4) !important;
}

.tw-text-yellow-950\/45 {
  color: rgb(66 32 6 / 0.45) !important;
}

.tw-text-yellow-950\/5 {
  color: rgb(66 32 6 / 0.05) !important;
}

.tw-text-yellow-950\/50 {
  color: rgb(66 32 6 / 0.5) !important;
}

.tw-text-yellow-950\/55 {
  color: rgb(66 32 6 / 0.55) !important;
}

.tw-text-yellow-950\/60 {
  color: rgb(66 32 6 / 0.6) !important;
}

.tw-text-yellow-950\/65 {
  color: rgb(66 32 6 / 0.65) !important;
}

.tw-text-yellow-950\/70 {
  color: rgb(66 32 6 / 0.7) !important;
}

.tw-text-yellow-950\/75 {
  color: rgb(66 32 6 / 0.75) !important;
}

.tw-text-yellow-950\/80 {
  color: rgb(66 32 6 / 0.8) !important;
}

.tw-text-yellow-950\/85 {
  color: rgb(66 32 6 / 0.85) !important;
}

.tw-text-yellow-950\/90 {
  color: rgb(66 32 6 / 0.9) !important;
}

.tw-text-yellow-950\/95 {
  color: rgb(66 32 6 / 0.95) !important;
}

.tw-text-zinc-100 {
  --tw-text-opacity: 1 !important;
  color: rgb(244 244 245 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-100\/0 {
  color: rgb(244 244 245 / 0) !important;
}

.tw-text-zinc-100\/10 {
  color: rgb(244 244 245 / 0.1) !important;
}

.tw-text-zinc-100\/100 {
  color: rgb(244 244 245 / 1) !important;
}

.tw-text-zinc-100\/15 {
  color: rgb(244 244 245 / 0.15) !important;
}

.tw-text-zinc-100\/20 {
  color: rgb(244 244 245 / 0.2) !important;
}

.tw-text-zinc-100\/25 {
  color: rgb(244 244 245 / 0.25) !important;
}

.tw-text-zinc-100\/30 {
  color: rgb(244 244 245 / 0.3) !important;
}

.tw-text-zinc-100\/35 {
  color: rgb(244 244 245 / 0.35) !important;
}

.tw-text-zinc-100\/40 {
  color: rgb(244 244 245 / 0.4) !important;
}

.tw-text-zinc-100\/45 {
  color: rgb(244 244 245 / 0.45) !important;
}

.tw-text-zinc-100\/5 {
  color: rgb(244 244 245 / 0.05) !important;
}

.tw-text-zinc-100\/50 {
  color: rgb(244 244 245 / 0.5) !important;
}

.tw-text-zinc-100\/55 {
  color: rgb(244 244 245 / 0.55) !important;
}

.tw-text-zinc-100\/60 {
  color: rgb(244 244 245 / 0.6) !important;
}

.tw-text-zinc-100\/65 {
  color: rgb(244 244 245 / 0.65) !important;
}

.tw-text-zinc-100\/70 {
  color: rgb(244 244 245 / 0.7) !important;
}

.tw-text-zinc-100\/75 {
  color: rgb(244 244 245 / 0.75) !important;
}

.tw-text-zinc-100\/80 {
  color: rgb(244 244 245 / 0.8) !important;
}

.tw-text-zinc-100\/85 {
  color: rgb(244 244 245 / 0.85) !important;
}

.tw-text-zinc-100\/90 {
  color: rgb(244 244 245 / 0.9) !important;
}

.tw-text-zinc-100\/95 {
  color: rgb(244 244 245 / 0.95) !important;
}

.tw-text-zinc-200 {
  --tw-text-opacity: 1 !important;
  color: rgb(228 228 231 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-200\/0 {
  color: rgb(228 228 231 / 0) !important;
}

.tw-text-zinc-200\/10 {
  color: rgb(228 228 231 / 0.1) !important;
}

.tw-text-zinc-200\/100 {
  color: rgb(228 228 231 / 1) !important;
}

.tw-text-zinc-200\/15 {
  color: rgb(228 228 231 / 0.15) !important;
}

.tw-text-zinc-200\/20 {
  color: rgb(228 228 231 / 0.2) !important;
}

.tw-text-zinc-200\/25 {
  color: rgb(228 228 231 / 0.25) !important;
}

.tw-text-zinc-200\/30 {
  color: rgb(228 228 231 / 0.3) !important;
}

.tw-text-zinc-200\/35 {
  color: rgb(228 228 231 / 0.35) !important;
}

.tw-text-zinc-200\/40 {
  color: rgb(228 228 231 / 0.4) !important;
}

.tw-text-zinc-200\/45 {
  color: rgb(228 228 231 / 0.45) !important;
}

.tw-text-zinc-200\/5 {
  color: rgb(228 228 231 / 0.05) !important;
}

.tw-text-zinc-200\/50 {
  color: rgb(228 228 231 / 0.5) !important;
}

.tw-text-zinc-200\/55 {
  color: rgb(228 228 231 / 0.55) !important;
}

.tw-text-zinc-200\/60 {
  color: rgb(228 228 231 / 0.6) !important;
}

.tw-text-zinc-200\/65 {
  color: rgb(228 228 231 / 0.65) !important;
}

.tw-text-zinc-200\/70 {
  color: rgb(228 228 231 / 0.7) !important;
}

.tw-text-zinc-200\/75 {
  color: rgb(228 228 231 / 0.75) !important;
}

.tw-text-zinc-200\/80 {
  color: rgb(228 228 231 / 0.8) !important;
}

.tw-text-zinc-200\/85 {
  color: rgb(228 228 231 / 0.85) !important;
}

.tw-text-zinc-200\/90 {
  color: rgb(228 228 231 / 0.9) !important;
}

.tw-text-zinc-200\/95 {
  color: rgb(228 228 231 / 0.95) !important;
}

.tw-text-zinc-300 {
  --tw-text-opacity: 1 !important;
  color: rgb(212 212 216 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-300\/0 {
  color: rgb(212 212 216 / 0) !important;
}

.tw-text-zinc-300\/10 {
  color: rgb(212 212 216 / 0.1) !important;
}

.tw-text-zinc-300\/100 {
  color: rgb(212 212 216 / 1) !important;
}

.tw-text-zinc-300\/15 {
  color: rgb(212 212 216 / 0.15) !important;
}

.tw-text-zinc-300\/20 {
  color: rgb(212 212 216 / 0.2) !important;
}

.tw-text-zinc-300\/25 {
  color: rgb(212 212 216 / 0.25) !important;
}

.tw-text-zinc-300\/30 {
  color: rgb(212 212 216 / 0.3) !important;
}

.tw-text-zinc-300\/35 {
  color: rgb(212 212 216 / 0.35) !important;
}

.tw-text-zinc-300\/40 {
  color: rgb(212 212 216 / 0.4) !important;
}

.tw-text-zinc-300\/45 {
  color: rgb(212 212 216 / 0.45) !important;
}

.tw-text-zinc-300\/5 {
  color: rgb(212 212 216 / 0.05) !important;
}

.tw-text-zinc-300\/50 {
  color: rgb(212 212 216 / 0.5) !important;
}

.tw-text-zinc-300\/55 {
  color: rgb(212 212 216 / 0.55) !important;
}

.tw-text-zinc-300\/60 {
  color: rgb(212 212 216 / 0.6) !important;
}

.tw-text-zinc-300\/65 {
  color: rgb(212 212 216 / 0.65) !important;
}

.tw-text-zinc-300\/70 {
  color: rgb(212 212 216 / 0.7) !important;
}

.tw-text-zinc-300\/75 {
  color: rgb(212 212 216 / 0.75) !important;
}

.tw-text-zinc-300\/80 {
  color: rgb(212 212 216 / 0.8) !important;
}

.tw-text-zinc-300\/85 {
  color: rgb(212 212 216 / 0.85) !important;
}

.tw-text-zinc-300\/90 {
  color: rgb(212 212 216 / 0.9) !important;
}

.tw-text-zinc-300\/95 {
  color: rgb(212 212 216 / 0.95) !important;
}

.tw-text-zinc-400 {
  --tw-text-opacity: 1 !important;
  color: rgb(161 161 170 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-400\/0 {
  color: rgb(161 161 170 / 0) !important;
}

.tw-text-zinc-400\/10 {
  color: rgb(161 161 170 / 0.1) !important;
}

.tw-text-zinc-400\/100 {
  color: rgb(161 161 170 / 1) !important;
}

.tw-text-zinc-400\/15 {
  color: rgb(161 161 170 / 0.15) !important;
}

.tw-text-zinc-400\/20 {
  color: rgb(161 161 170 / 0.2) !important;
}

.tw-text-zinc-400\/25 {
  color: rgb(161 161 170 / 0.25) !important;
}

.tw-text-zinc-400\/30 {
  color: rgb(161 161 170 / 0.3) !important;
}

.tw-text-zinc-400\/35 {
  color: rgb(161 161 170 / 0.35) !important;
}

.tw-text-zinc-400\/40 {
  color: rgb(161 161 170 / 0.4) !important;
}

.tw-text-zinc-400\/45 {
  color: rgb(161 161 170 / 0.45) !important;
}

.tw-text-zinc-400\/5 {
  color: rgb(161 161 170 / 0.05) !important;
}

.tw-text-zinc-400\/50 {
  color: rgb(161 161 170 / 0.5) !important;
}

.tw-text-zinc-400\/55 {
  color: rgb(161 161 170 / 0.55) !important;
}

.tw-text-zinc-400\/60 {
  color: rgb(161 161 170 / 0.6) !important;
}

.tw-text-zinc-400\/65 {
  color: rgb(161 161 170 / 0.65) !important;
}

.tw-text-zinc-400\/70 {
  color: rgb(161 161 170 / 0.7) !important;
}

.tw-text-zinc-400\/75 {
  color: rgb(161 161 170 / 0.75) !important;
}

.tw-text-zinc-400\/80 {
  color: rgb(161 161 170 / 0.8) !important;
}

.tw-text-zinc-400\/85 {
  color: rgb(161 161 170 / 0.85) !important;
}

.tw-text-zinc-400\/90 {
  color: rgb(161 161 170 / 0.9) !important;
}

.tw-text-zinc-400\/95 {
  color: rgb(161 161 170 / 0.95) !important;
}

.tw-text-zinc-50 {
  --tw-text-opacity: 1 !important;
  color: rgb(250 250 250 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-50\/0 {
  color: rgb(250 250 250 / 0) !important;
}

.tw-text-zinc-50\/10 {
  color: rgb(250 250 250 / 0.1) !important;
}

.tw-text-zinc-50\/100 {
  color: rgb(250 250 250 / 1) !important;
}

.tw-text-zinc-50\/15 {
  color: rgb(250 250 250 / 0.15) !important;
}

.tw-text-zinc-50\/20 {
  color: rgb(250 250 250 / 0.2) !important;
}

.tw-text-zinc-50\/25 {
  color: rgb(250 250 250 / 0.25) !important;
}

.tw-text-zinc-50\/30 {
  color: rgb(250 250 250 / 0.3) !important;
}

.tw-text-zinc-50\/35 {
  color: rgb(250 250 250 / 0.35) !important;
}

.tw-text-zinc-50\/40 {
  color: rgb(250 250 250 / 0.4) !important;
}

.tw-text-zinc-50\/45 {
  color: rgb(250 250 250 / 0.45) !important;
}

.tw-text-zinc-50\/5 {
  color: rgb(250 250 250 / 0.05) !important;
}

.tw-text-zinc-50\/50 {
  color: rgb(250 250 250 / 0.5) !important;
}

.tw-text-zinc-50\/55 {
  color: rgb(250 250 250 / 0.55) !important;
}

.tw-text-zinc-50\/60 {
  color: rgb(250 250 250 / 0.6) !important;
}

.tw-text-zinc-50\/65 {
  color: rgb(250 250 250 / 0.65) !important;
}

.tw-text-zinc-50\/70 {
  color: rgb(250 250 250 / 0.7) !important;
}

.tw-text-zinc-50\/75 {
  color: rgb(250 250 250 / 0.75) !important;
}

.tw-text-zinc-50\/80 {
  color: rgb(250 250 250 / 0.8) !important;
}

.tw-text-zinc-50\/85 {
  color: rgb(250 250 250 / 0.85) !important;
}

.tw-text-zinc-50\/90 {
  color: rgb(250 250 250 / 0.9) !important;
}

.tw-text-zinc-50\/95 {
  color: rgb(250 250 250 / 0.95) !important;
}

.tw-text-zinc-500 {
  --tw-text-opacity: 1 !important;
  color: rgb(113 113 122 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-500\/0 {
  color: rgb(113 113 122 / 0) !important;
}

.tw-text-zinc-500\/10 {
  color: rgb(113 113 122 / 0.1) !important;
}

.tw-text-zinc-500\/100 {
  color: rgb(113 113 122 / 1) !important;
}

.tw-text-zinc-500\/15 {
  color: rgb(113 113 122 / 0.15) !important;
}

.tw-text-zinc-500\/20 {
  color: rgb(113 113 122 / 0.2) !important;
}

.tw-text-zinc-500\/25 {
  color: rgb(113 113 122 / 0.25) !important;
}

.tw-text-zinc-500\/30 {
  color: rgb(113 113 122 / 0.3) !important;
}

.tw-text-zinc-500\/35 {
  color: rgb(113 113 122 / 0.35) !important;
}

.tw-text-zinc-500\/40 {
  color: rgb(113 113 122 / 0.4) !important;
}

.tw-text-zinc-500\/45 {
  color: rgb(113 113 122 / 0.45) !important;
}

.tw-text-zinc-500\/5 {
  color: rgb(113 113 122 / 0.05) !important;
}

.tw-text-zinc-500\/50 {
  color: rgb(113 113 122 / 0.5) !important;
}

.tw-text-zinc-500\/55 {
  color: rgb(113 113 122 / 0.55) !important;
}

.tw-text-zinc-500\/60 {
  color: rgb(113 113 122 / 0.6) !important;
}

.tw-text-zinc-500\/65 {
  color: rgb(113 113 122 / 0.65) !important;
}

.tw-text-zinc-500\/70 {
  color: rgb(113 113 122 / 0.7) !important;
}

.tw-text-zinc-500\/75 {
  color: rgb(113 113 122 / 0.75) !important;
}

.tw-text-zinc-500\/80 {
  color: rgb(113 113 122 / 0.8) !important;
}

.tw-text-zinc-500\/85 {
  color: rgb(113 113 122 / 0.85) !important;
}

.tw-text-zinc-500\/90 {
  color: rgb(113 113 122 / 0.9) !important;
}

.tw-text-zinc-500\/95 {
  color: rgb(113 113 122 / 0.95) !important;
}

.tw-text-zinc-600 {
  --tw-text-opacity: 1 !important;
  color: rgb(82 82 91 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-600\/0 {
  color: rgb(82 82 91 / 0) !important;
}

.tw-text-zinc-600\/10 {
  color: rgb(82 82 91 / 0.1) !important;
}

.tw-text-zinc-600\/100 {
  color: rgb(82 82 91 / 1) !important;
}

.tw-text-zinc-600\/15 {
  color: rgb(82 82 91 / 0.15) !important;
}

.tw-text-zinc-600\/20 {
  color: rgb(82 82 91 / 0.2) !important;
}

.tw-text-zinc-600\/25 {
  color: rgb(82 82 91 / 0.25) !important;
}

.tw-text-zinc-600\/30 {
  color: rgb(82 82 91 / 0.3) !important;
}

.tw-text-zinc-600\/35 {
  color: rgb(82 82 91 / 0.35) !important;
}

.tw-text-zinc-600\/40 {
  color: rgb(82 82 91 / 0.4) !important;
}

.tw-text-zinc-600\/45 {
  color: rgb(82 82 91 / 0.45) !important;
}

.tw-text-zinc-600\/5 {
  color: rgb(82 82 91 / 0.05) !important;
}

.tw-text-zinc-600\/50 {
  color: rgb(82 82 91 / 0.5) !important;
}

.tw-text-zinc-600\/55 {
  color: rgb(82 82 91 / 0.55) !important;
}

.tw-text-zinc-600\/60 {
  color: rgb(82 82 91 / 0.6) !important;
}

.tw-text-zinc-600\/65 {
  color: rgb(82 82 91 / 0.65) !important;
}

.tw-text-zinc-600\/70 {
  color: rgb(82 82 91 / 0.7) !important;
}

.tw-text-zinc-600\/75 {
  color: rgb(82 82 91 / 0.75) !important;
}

.tw-text-zinc-600\/80 {
  color: rgb(82 82 91 / 0.8) !important;
}

.tw-text-zinc-600\/85 {
  color: rgb(82 82 91 / 0.85) !important;
}

.tw-text-zinc-600\/90 {
  color: rgb(82 82 91 / 0.9) !important;
}

.tw-text-zinc-600\/95 {
  color: rgb(82 82 91 / 0.95) !important;
}

.tw-text-zinc-700 {
  --tw-text-opacity: 1 !important;
  color: rgb(63 63 70 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-700\/0 {
  color: rgb(63 63 70 / 0) !important;
}

.tw-text-zinc-700\/10 {
  color: rgb(63 63 70 / 0.1) !important;
}

.tw-text-zinc-700\/100 {
  color: rgb(63 63 70 / 1) !important;
}

.tw-text-zinc-700\/15 {
  color: rgb(63 63 70 / 0.15) !important;
}

.tw-text-zinc-700\/20 {
  color: rgb(63 63 70 / 0.2) !important;
}

.tw-text-zinc-700\/25 {
  color: rgb(63 63 70 / 0.25) !important;
}

.tw-text-zinc-700\/30 {
  color: rgb(63 63 70 / 0.3) !important;
}

.tw-text-zinc-700\/35 {
  color: rgb(63 63 70 / 0.35) !important;
}

.tw-text-zinc-700\/40 {
  color: rgb(63 63 70 / 0.4) !important;
}

.tw-text-zinc-700\/45 {
  color: rgb(63 63 70 / 0.45) !important;
}

.tw-text-zinc-700\/5 {
  color: rgb(63 63 70 / 0.05) !important;
}

.tw-text-zinc-700\/50 {
  color: rgb(63 63 70 / 0.5) !important;
}

.tw-text-zinc-700\/55 {
  color: rgb(63 63 70 / 0.55) !important;
}

.tw-text-zinc-700\/60 {
  color: rgb(63 63 70 / 0.6) !important;
}

.tw-text-zinc-700\/65 {
  color: rgb(63 63 70 / 0.65) !important;
}

.tw-text-zinc-700\/70 {
  color: rgb(63 63 70 / 0.7) !important;
}

.tw-text-zinc-700\/75 {
  color: rgb(63 63 70 / 0.75) !important;
}

.tw-text-zinc-700\/80 {
  color: rgb(63 63 70 / 0.8) !important;
}

.tw-text-zinc-700\/85 {
  color: rgb(63 63 70 / 0.85) !important;
}

.tw-text-zinc-700\/90 {
  color: rgb(63 63 70 / 0.9) !important;
}

.tw-text-zinc-700\/95 {
  color: rgb(63 63 70 / 0.95) !important;
}

.tw-text-zinc-800 {
  --tw-text-opacity: 1 !important;
  color: rgb(39 39 42 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-800\/0 {
  color: rgb(39 39 42 / 0) !important;
}

.tw-text-zinc-800\/10 {
  color: rgb(39 39 42 / 0.1) !important;
}

.tw-text-zinc-800\/100 {
  color: rgb(39 39 42 / 1) !important;
}

.tw-text-zinc-800\/15 {
  color: rgb(39 39 42 / 0.15) !important;
}

.tw-text-zinc-800\/20 {
  color: rgb(39 39 42 / 0.2) !important;
}

.tw-text-zinc-800\/25 {
  color: rgb(39 39 42 / 0.25) !important;
}

.tw-text-zinc-800\/30 {
  color: rgb(39 39 42 / 0.3) !important;
}

.tw-text-zinc-800\/35 {
  color: rgb(39 39 42 / 0.35) !important;
}

.tw-text-zinc-800\/40 {
  color: rgb(39 39 42 / 0.4) !important;
}

.tw-text-zinc-800\/45 {
  color: rgb(39 39 42 / 0.45) !important;
}

.tw-text-zinc-800\/5 {
  color: rgb(39 39 42 / 0.05) !important;
}

.tw-text-zinc-800\/50 {
  color: rgb(39 39 42 / 0.5) !important;
}

.tw-text-zinc-800\/55 {
  color: rgb(39 39 42 / 0.55) !important;
}

.tw-text-zinc-800\/60 {
  color: rgb(39 39 42 / 0.6) !important;
}

.tw-text-zinc-800\/65 {
  color: rgb(39 39 42 / 0.65) !important;
}

.tw-text-zinc-800\/70 {
  color: rgb(39 39 42 / 0.7) !important;
}

.tw-text-zinc-800\/75 {
  color: rgb(39 39 42 / 0.75) !important;
}

.tw-text-zinc-800\/80 {
  color: rgb(39 39 42 / 0.8) !important;
}

.tw-text-zinc-800\/85 {
  color: rgb(39 39 42 / 0.85) !important;
}

.tw-text-zinc-800\/90 {
  color: rgb(39 39 42 / 0.9) !important;
}

.tw-text-zinc-800\/95 {
  color: rgb(39 39 42 / 0.95) !important;
}

.tw-text-zinc-900 {
  --tw-text-opacity: 1 !important;
  color: rgb(24 24 27 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-900\/0 {
  color: rgb(24 24 27 / 0) !important;
}

.tw-text-zinc-900\/10 {
  color: rgb(24 24 27 / 0.1) !important;
}

.tw-text-zinc-900\/100 {
  color: rgb(24 24 27 / 1) !important;
}

.tw-text-zinc-900\/15 {
  color: rgb(24 24 27 / 0.15) !important;
}

.tw-text-zinc-900\/20 {
  color: rgb(24 24 27 / 0.2) !important;
}

.tw-text-zinc-900\/25 {
  color: rgb(24 24 27 / 0.25) !important;
}

.tw-text-zinc-900\/30 {
  color: rgb(24 24 27 / 0.3) !important;
}

.tw-text-zinc-900\/35 {
  color: rgb(24 24 27 / 0.35) !important;
}

.tw-text-zinc-900\/40 {
  color: rgb(24 24 27 / 0.4) !important;
}

.tw-text-zinc-900\/45 {
  color: rgb(24 24 27 / 0.45) !important;
}

.tw-text-zinc-900\/5 {
  color: rgb(24 24 27 / 0.05) !important;
}

.tw-text-zinc-900\/50 {
  color: rgb(24 24 27 / 0.5) !important;
}

.tw-text-zinc-900\/55 {
  color: rgb(24 24 27 / 0.55) !important;
}

.tw-text-zinc-900\/60 {
  color: rgb(24 24 27 / 0.6) !important;
}

.tw-text-zinc-900\/65 {
  color: rgb(24 24 27 / 0.65) !important;
}

.tw-text-zinc-900\/70 {
  color: rgb(24 24 27 / 0.7) !important;
}

.tw-text-zinc-900\/75 {
  color: rgb(24 24 27 / 0.75) !important;
}

.tw-text-zinc-900\/80 {
  color: rgb(24 24 27 / 0.8) !important;
}

.tw-text-zinc-900\/85 {
  color: rgb(24 24 27 / 0.85) !important;
}

.tw-text-zinc-900\/90 {
  color: rgb(24 24 27 / 0.9) !important;
}

.tw-text-zinc-900\/95 {
  color: rgb(24 24 27 / 0.95) !important;
}

.tw-text-zinc-950 {
  --tw-text-opacity: 1 !important;
  color: rgb(9 9 11 / var(--tw-text-opacity)) !important;
}

.tw-text-zinc-950\/0 {
  color: rgb(9 9 11 / 0) !important;
}

.tw-text-zinc-950\/10 {
  color: rgb(9 9 11 / 0.1) !important;
}

.tw-text-zinc-950\/100 {
  color: rgb(9 9 11 / 1) !important;
}

.tw-text-zinc-950\/15 {
  color: rgb(9 9 11 / 0.15) !important;
}

.tw-text-zinc-950\/20 {
  color: rgb(9 9 11 / 0.2) !important;
}

.tw-text-zinc-950\/25 {
  color: rgb(9 9 11 / 0.25) !important;
}

.tw-text-zinc-950\/30 {
  color: rgb(9 9 11 / 0.3) !important;
}

.tw-text-zinc-950\/35 {
  color: rgb(9 9 11 / 0.35) !important;
}

.tw-text-zinc-950\/40 {
  color: rgb(9 9 11 / 0.4) !important;
}

.tw-text-zinc-950\/45 {
  color: rgb(9 9 11 / 0.45) !important;
}

.tw-text-zinc-950\/5 {
  color: rgb(9 9 11 / 0.05) !important;
}

.tw-text-zinc-950\/50 {
  color: rgb(9 9 11 / 0.5) !important;
}

.tw-text-zinc-950\/55 {
  color: rgb(9 9 11 / 0.55) !important;
}

.tw-text-zinc-950\/60 {
  color: rgb(9 9 11 / 0.6) !important;
}

.tw-text-zinc-950\/65 {
  color: rgb(9 9 11 / 0.65) !important;
}

.tw-text-zinc-950\/70 {
  color: rgb(9 9 11 / 0.7) !important;
}

.tw-text-zinc-950\/75 {
  color: rgb(9 9 11 / 0.75) !important;
}

.tw-text-zinc-950\/80 {
  color: rgb(9 9 11 / 0.8) !important;
}

.tw-text-zinc-950\/85 {
  color: rgb(9 9 11 / 0.85) !important;
}

.tw-text-zinc-950\/90 {
  color: rgb(9 9 11 / 0.9) !important;
}

.tw-text-zinc-950\/95 {
  color: rgb(9 9 11 / 0.95) !important;
}

.tw-text-opacity-0 {
  --tw-text-opacity: 0 !important;
}

.tw-text-opacity-10 {
  --tw-text-opacity: 0.1 !important;
}

.tw-text-opacity-100 {
  --tw-text-opacity: 1 !important;
}

.tw-text-opacity-15 {
  --tw-text-opacity: 0.15 !important;
}

.tw-text-opacity-20 {
  --tw-text-opacity: 0.2 !important;
}

.tw-text-opacity-25 {
  --tw-text-opacity: 0.25 !important;
}

.tw-text-opacity-30 {
  --tw-text-opacity: 0.3 !important;
}

.tw-text-opacity-35 {
  --tw-text-opacity: 0.35 !important;
}

.tw-text-opacity-40 {
  --tw-text-opacity: 0.4 !important;
}

.tw-text-opacity-45 {
  --tw-text-opacity: 0.45 !important;
}

.tw-text-opacity-5 {
  --tw-text-opacity: 0.05 !important;
}

.tw-text-opacity-50 {
  --tw-text-opacity: 0.5 !important;
}

.tw-text-opacity-55 {
  --tw-text-opacity: 0.55 !important;
}

.tw-text-opacity-60 {
  --tw-text-opacity: 0.6 !important;
}

.tw-text-opacity-65 {
  --tw-text-opacity: 0.65 !important;
}

.tw-text-opacity-70 {
  --tw-text-opacity: 0.7 !important;
}

.tw-text-opacity-75 {
  --tw-text-opacity: 0.75 !important;
}

.tw-text-opacity-80 {
  --tw-text-opacity: 0.8 !important;
}

.tw-text-opacity-85 {
  --tw-text-opacity: 0.85 !important;
}

.tw-text-opacity-90 {
  --tw-text-opacity: 0.9 !important;
}

.tw-text-opacity-95 {
  --tw-text-opacity: 0.95 !important;
}

.tw-underline {
  text-decoration-line: underline !important;
}

.tw-overline {
  text-decoration-line: overline !important;
}

.tw-line-through {
  text-decoration-line: line-through !important;
}

.tw-no-underline {
  text-decoration-line: none !important;
}

.tw-decoration-amber-100 {
  text-decoration-color: #fef3c7 !important;
}

.tw-decoration-amber-100\/0 {
  text-decoration-color: rgb(254 243 199 / 0) !important;
}

.tw-decoration-amber-100\/10 {
  text-decoration-color: rgb(254 243 199 / 0.1) !important;
}

.tw-decoration-amber-100\/100 {
  text-decoration-color: rgb(254 243 199 / 1) !important;
}

.tw-decoration-amber-100\/15 {
  text-decoration-color: rgb(254 243 199 / 0.15) !important;
}

.tw-decoration-amber-100\/20 {
  text-decoration-color: rgb(254 243 199 / 0.2) !important;
}

.tw-decoration-amber-100\/25 {
  text-decoration-color: rgb(254 243 199 / 0.25) !important;
}

.tw-decoration-amber-100\/30 {
  text-decoration-color: rgb(254 243 199 / 0.3) !important;
}

.tw-decoration-amber-100\/35 {
  text-decoration-color: rgb(254 243 199 / 0.35) !important;
}

.tw-decoration-amber-100\/40 {
  text-decoration-color: rgb(254 243 199 / 0.4) !important;
}

.tw-decoration-amber-100\/45 {
  text-decoration-color: rgb(254 243 199 / 0.45) !important;
}

.tw-decoration-amber-100\/5 {
  text-decoration-color: rgb(254 243 199 / 0.05) !important;
}

.tw-decoration-amber-100\/50 {
  text-decoration-color: rgb(254 243 199 / 0.5) !important;
}

.tw-decoration-amber-100\/55 {
  text-decoration-color: rgb(254 243 199 / 0.55) !important;
}

.tw-decoration-amber-100\/60 {
  text-decoration-color: rgb(254 243 199 / 0.6) !important;
}

.tw-decoration-amber-100\/65 {
  text-decoration-color: rgb(254 243 199 / 0.65) !important;
}

.tw-decoration-amber-100\/70 {
  text-decoration-color: rgb(254 243 199 / 0.7) !important;
}

.tw-decoration-amber-100\/75 {
  text-decoration-color: rgb(254 243 199 / 0.75) !important;
}

.tw-decoration-amber-100\/80 {
  text-decoration-color: rgb(254 243 199 / 0.8) !important;
}

.tw-decoration-amber-100\/85 {
  text-decoration-color: rgb(254 243 199 / 0.85) !important;
}

.tw-decoration-amber-100\/90 {
  text-decoration-color: rgb(254 243 199 / 0.9) !important;
}

.tw-decoration-amber-100\/95 {
  text-decoration-color: rgb(254 243 199 / 0.95) !important;
}

.tw-decoration-amber-200 {
  text-decoration-color: #fde68a !important;
}

.tw-decoration-amber-200\/0 {
  text-decoration-color: rgb(253 230 138 / 0) !important;
}

.tw-decoration-amber-200\/10 {
  text-decoration-color: rgb(253 230 138 / 0.1) !important;
}

.tw-decoration-amber-200\/100 {
  text-decoration-color: rgb(253 230 138 / 1) !important;
}

.tw-decoration-amber-200\/15 {
  text-decoration-color: rgb(253 230 138 / 0.15) !important;
}

.tw-decoration-amber-200\/20 {
  text-decoration-color: rgb(253 230 138 / 0.2) !important;
}

.tw-decoration-amber-200\/25 {
  text-decoration-color: rgb(253 230 138 / 0.25) !important;
}

.tw-decoration-amber-200\/30 {
  text-decoration-color: rgb(253 230 138 / 0.3) !important;
}

.tw-decoration-amber-200\/35 {
  text-decoration-color: rgb(253 230 138 / 0.35) !important;
}

.tw-decoration-amber-200\/40 {
  text-decoration-color: rgb(253 230 138 / 0.4) !important;
}

.tw-decoration-amber-200\/45 {
  text-decoration-color: rgb(253 230 138 / 0.45) !important;
}

.tw-decoration-amber-200\/5 {
  text-decoration-color: rgb(253 230 138 / 0.05) !important;
}

.tw-decoration-amber-200\/50 {
  text-decoration-color: rgb(253 230 138 / 0.5) !important;
}

.tw-decoration-amber-200\/55 {
  text-decoration-color: rgb(253 230 138 / 0.55) !important;
}

.tw-decoration-amber-200\/60 {
  text-decoration-color: rgb(253 230 138 / 0.6) !important;
}

.tw-decoration-amber-200\/65 {
  text-decoration-color: rgb(253 230 138 / 0.65) !important;
}

.tw-decoration-amber-200\/70 {
  text-decoration-color: rgb(253 230 138 / 0.7) !important;
}

.tw-decoration-amber-200\/75 {
  text-decoration-color: rgb(253 230 138 / 0.75) !important;
}

.tw-decoration-amber-200\/80 {
  text-decoration-color: rgb(253 230 138 / 0.8) !important;
}

.tw-decoration-amber-200\/85 {
  text-decoration-color: rgb(253 230 138 / 0.85) !important;
}

.tw-decoration-amber-200\/90 {
  text-decoration-color: rgb(253 230 138 / 0.9) !important;
}

.tw-decoration-amber-200\/95 {
  text-decoration-color: rgb(253 230 138 / 0.95) !important;
}

.tw-decoration-amber-300 {
  text-decoration-color: #fcd34d !important;
}

.tw-decoration-amber-300\/0 {
  text-decoration-color: rgb(252 211 77 / 0) !important;
}

.tw-decoration-amber-300\/10 {
  text-decoration-color: rgb(252 211 77 / 0.1) !important;
}

.tw-decoration-amber-300\/100 {
  text-decoration-color: rgb(252 211 77 / 1) !important;
}

.tw-decoration-amber-300\/15 {
  text-decoration-color: rgb(252 211 77 / 0.15) !important;
}

.tw-decoration-amber-300\/20 {
  text-decoration-color: rgb(252 211 77 / 0.2) !important;
}

.tw-decoration-amber-300\/25 {
  text-decoration-color: rgb(252 211 77 / 0.25) !important;
}

.tw-decoration-amber-300\/30 {
  text-decoration-color: rgb(252 211 77 / 0.3) !important;
}

.tw-decoration-amber-300\/35 {
  text-decoration-color: rgb(252 211 77 / 0.35) !important;
}

.tw-decoration-amber-300\/40 {
  text-decoration-color: rgb(252 211 77 / 0.4) !important;
}

.tw-decoration-amber-300\/45 {
  text-decoration-color: rgb(252 211 77 / 0.45) !important;
}

.tw-decoration-amber-300\/5 {
  text-decoration-color: rgb(252 211 77 / 0.05) !important;
}

.tw-decoration-amber-300\/50 {
  text-decoration-color: rgb(252 211 77 / 0.5) !important;
}

.tw-decoration-amber-300\/55 {
  text-decoration-color: rgb(252 211 77 / 0.55) !important;
}

.tw-decoration-amber-300\/60 {
  text-decoration-color: rgb(252 211 77 / 0.6) !important;
}

.tw-decoration-amber-300\/65 {
  text-decoration-color: rgb(252 211 77 / 0.65) !important;
}

.tw-decoration-amber-300\/70 {
  text-decoration-color: rgb(252 211 77 / 0.7) !important;
}

.tw-decoration-amber-300\/75 {
  text-decoration-color: rgb(252 211 77 / 0.75) !important;
}

.tw-decoration-amber-300\/80 {
  text-decoration-color: rgb(252 211 77 / 0.8) !important;
}

.tw-decoration-amber-300\/85 {
  text-decoration-color: rgb(252 211 77 / 0.85) !important;
}

.tw-decoration-amber-300\/90 {
  text-decoration-color: rgb(252 211 77 / 0.9) !important;
}

.tw-decoration-amber-300\/95 {
  text-decoration-color: rgb(252 211 77 / 0.95) !important;
}

.tw-decoration-amber-400 {
  text-decoration-color: #fbbf24 !important;
}

.tw-decoration-amber-400\/0 {
  text-decoration-color: rgb(251 191 36 / 0) !important;
}

.tw-decoration-amber-400\/10 {
  text-decoration-color: rgb(251 191 36 / 0.1) !important;
}

.tw-decoration-amber-400\/100 {
  text-decoration-color: rgb(251 191 36 / 1) !important;
}

.tw-decoration-amber-400\/15 {
  text-decoration-color: rgb(251 191 36 / 0.15) !important;
}

.tw-decoration-amber-400\/20 {
  text-decoration-color: rgb(251 191 36 / 0.2) !important;
}

.tw-decoration-amber-400\/25 {
  text-decoration-color: rgb(251 191 36 / 0.25) !important;
}

.tw-decoration-amber-400\/30 {
  text-decoration-color: rgb(251 191 36 / 0.3) !important;
}

.tw-decoration-amber-400\/35 {
  text-decoration-color: rgb(251 191 36 / 0.35) !important;
}

.tw-decoration-amber-400\/40 {
  text-decoration-color: rgb(251 191 36 / 0.4) !important;
}

.tw-decoration-amber-400\/45 {
  text-decoration-color: rgb(251 191 36 / 0.45) !important;
}

.tw-decoration-amber-400\/5 {
  text-decoration-color: rgb(251 191 36 / 0.05) !important;
}

.tw-decoration-amber-400\/50 {
  text-decoration-color: rgb(251 191 36 / 0.5) !important;
}

.tw-decoration-amber-400\/55 {
  text-decoration-color: rgb(251 191 36 / 0.55) !important;
}

.tw-decoration-amber-400\/60 {
  text-decoration-color: rgb(251 191 36 / 0.6) !important;
}

.tw-decoration-amber-400\/65 {
  text-decoration-color: rgb(251 191 36 / 0.65) !important;
}

.tw-decoration-amber-400\/70 {
  text-decoration-color: rgb(251 191 36 / 0.7) !important;
}

.tw-decoration-amber-400\/75 {
  text-decoration-color: rgb(251 191 36 / 0.75) !important;
}

.tw-decoration-amber-400\/80 {
  text-decoration-color: rgb(251 191 36 / 0.8) !important;
}

.tw-decoration-amber-400\/85 {
  text-decoration-color: rgb(251 191 36 / 0.85) !important;
}

.tw-decoration-amber-400\/90 {
  text-decoration-color: rgb(251 191 36 / 0.9) !important;
}

.tw-decoration-amber-400\/95 {
  text-decoration-color: rgb(251 191 36 / 0.95) !important;
}

.tw-decoration-amber-50 {
  text-decoration-color: #fffbeb !important;
}

.tw-decoration-amber-50\/0 {
  text-decoration-color: rgb(255 251 235 / 0) !important;
}

.tw-decoration-amber-50\/10 {
  text-decoration-color: rgb(255 251 235 / 0.1) !important;
}

.tw-decoration-amber-50\/100 {
  text-decoration-color: rgb(255 251 235 / 1) !important;
}

.tw-decoration-amber-50\/15 {
  text-decoration-color: rgb(255 251 235 / 0.15) !important;
}

.tw-decoration-amber-50\/20 {
  text-decoration-color: rgb(255 251 235 / 0.2) !important;
}

.tw-decoration-amber-50\/25 {
  text-decoration-color: rgb(255 251 235 / 0.25) !important;
}

.tw-decoration-amber-50\/30 {
  text-decoration-color: rgb(255 251 235 / 0.3) !important;
}

.tw-decoration-amber-50\/35 {
  text-decoration-color: rgb(255 251 235 / 0.35) !important;
}

.tw-decoration-amber-50\/40 {
  text-decoration-color: rgb(255 251 235 / 0.4) !important;
}

.tw-decoration-amber-50\/45 {
  text-decoration-color: rgb(255 251 235 / 0.45) !important;
}

.tw-decoration-amber-50\/5 {
  text-decoration-color: rgb(255 251 235 / 0.05) !important;
}

.tw-decoration-amber-50\/50 {
  text-decoration-color: rgb(255 251 235 / 0.5) !important;
}

.tw-decoration-amber-50\/55 {
  text-decoration-color: rgb(255 251 235 / 0.55) !important;
}

.tw-decoration-amber-50\/60 {
  text-decoration-color: rgb(255 251 235 / 0.6) !important;
}

.tw-decoration-amber-50\/65 {
  text-decoration-color: rgb(255 251 235 / 0.65) !important;
}

.tw-decoration-amber-50\/70 {
  text-decoration-color: rgb(255 251 235 / 0.7) !important;
}

.tw-decoration-amber-50\/75 {
  text-decoration-color: rgb(255 251 235 / 0.75) !important;
}

.tw-decoration-amber-50\/80 {
  text-decoration-color: rgb(255 251 235 / 0.8) !important;
}

.tw-decoration-amber-50\/85 {
  text-decoration-color: rgb(255 251 235 / 0.85) !important;
}

.tw-decoration-amber-50\/90 {
  text-decoration-color: rgb(255 251 235 / 0.9) !important;
}

.tw-decoration-amber-50\/95 {
  text-decoration-color: rgb(255 251 235 / 0.95) !important;
}

.tw-decoration-amber-500 {
  text-decoration-color: #f59e0b !important;
}

.tw-decoration-amber-500\/0 {
  text-decoration-color: rgb(245 158 11 / 0) !important;
}

.tw-decoration-amber-500\/10 {
  text-decoration-color: rgb(245 158 11 / 0.1) !important;
}

.tw-decoration-amber-500\/100 {
  text-decoration-color: rgb(245 158 11 / 1) !important;
}

.tw-decoration-amber-500\/15 {
  text-decoration-color: rgb(245 158 11 / 0.15) !important;
}

.tw-decoration-amber-500\/20 {
  text-decoration-color: rgb(245 158 11 / 0.2) !important;
}

.tw-decoration-amber-500\/25 {
  text-decoration-color: rgb(245 158 11 / 0.25) !important;
}

.tw-decoration-amber-500\/30 {
  text-decoration-color: rgb(245 158 11 / 0.3) !important;
}

.tw-decoration-amber-500\/35 {
  text-decoration-color: rgb(245 158 11 / 0.35) !important;
}

.tw-decoration-amber-500\/40 {
  text-decoration-color: rgb(245 158 11 / 0.4) !important;
}

.tw-decoration-amber-500\/45 {
  text-decoration-color: rgb(245 158 11 / 0.45) !important;
}

.tw-decoration-amber-500\/5 {
  text-decoration-color: rgb(245 158 11 / 0.05) !important;
}

.tw-decoration-amber-500\/50 {
  text-decoration-color: rgb(245 158 11 / 0.5) !important;
}

.tw-decoration-amber-500\/55 {
  text-decoration-color: rgb(245 158 11 / 0.55) !important;
}

.tw-decoration-amber-500\/60 {
  text-decoration-color: rgb(245 158 11 / 0.6) !important;
}

.tw-decoration-amber-500\/65 {
  text-decoration-color: rgb(245 158 11 / 0.65) !important;
}

.tw-decoration-amber-500\/70 {
  text-decoration-color: rgb(245 158 11 / 0.7) !important;
}

.tw-decoration-amber-500\/75 {
  text-decoration-color: rgb(245 158 11 / 0.75) !important;
}

.tw-decoration-amber-500\/80 {
  text-decoration-color: rgb(245 158 11 / 0.8) !important;
}

.tw-decoration-amber-500\/85 {
  text-decoration-color: rgb(245 158 11 / 0.85) !important;
}

.tw-decoration-amber-500\/90 {
  text-decoration-color: rgb(245 158 11 / 0.9) !important;
}

.tw-decoration-amber-500\/95 {
  text-decoration-color: rgb(245 158 11 / 0.95) !important;
}

.tw-decoration-amber-600 {
  text-decoration-color: #d97706 !important;
}

.tw-decoration-amber-600\/0 {
  text-decoration-color: rgb(217 119 6 / 0) !important;
}

.tw-decoration-amber-600\/10 {
  text-decoration-color: rgb(217 119 6 / 0.1) !important;
}

.tw-decoration-amber-600\/100 {
  text-decoration-color: rgb(217 119 6 / 1) !important;
}

.tw-decoration-amber-600\/15 {
  text-decoration-color: rgb(217 119 6 / 0.15) !important;
}

.tw-decoration-amber-600\/20 {
  text-decoration-color: rgb(217 119 6 / 0.2) !important;
}

.tw-decoration-amber-600\/25 {
  text-decoration-color: rgb(217 119 6 / 0.25) !important;
}

.tw-decoration-amber-600\/30 {
  text-decoration-color: rgb(217 119 6 / 0.3) !important;
}

.tw-decoration-amber-600\/35 {
  text-decoration-color: rgb(217 119 6 / 0.35) !important;
}

.tw-decoration-amber-600\/40 {
  text-decoration-color: rgb(217 119 6 / 0.4) !important;
}

.tw-decoration-amber-600\/45 {
  text-decoration-color: rgb(217 119 6 / 0.45) !important;
}

.tw-decoration-amber-600\/5 {
  text-decoration-color: rgb(217 119 6 / 0.05) !important;
}

.tw-decoration-amber-600\/50 {
  text-decoration-color: rgb(217 119 6 / 0.5) !important;
}

.tw-decoration-amber-600\/55 {
  text-decoration-color: rgb(217 119 6 / 0.55) !important;
}

.tw-decoration-amber-600\/60 {
  text-decoration-color: rgb(217 119 6 / 0.6) !important;
}

.tw-decoration-amber-600\/65 {
  text-decoration-color: rgb(217 119 6 / 0.65) !important;
}

.tw-decoration-amber-600\/70 {
  text-decoration-color: rgb(217 119 6 / 0.7) !important;
}

.tw-decoration-amber-600\/75 {
  text-decoration-color: rgb(217 119 6 / 0.75) !important;
}

.tw-decoration-amber-600\/80 {
  text-decoration-color: rgb(217 119 6 / 0.8) !important;
}

.tw-decoration-amber-600\/85 {
  text-decoration-color: rgb(217 119 6 / 0.85) !important;
}

.tw-decoration-amber-600\/90 {
  text-decoration-color: rgb(217 119 6 / 0.9) !important;
}

.tw-decoration-amber-600\/95 {
  text-decoration-color: rgb(217 119 6 / 0.95) !important;
}

.tw-decoration-amber-700 {
  text-decoration-color: #b45309 !important;
}

.tw-decoration-amber-700\/0 {
  text-decoration-color: rgb(180 83 9 / 0) !important;
}

.tw-decoration-amber-700\/10 {
  text-decoration-color: rgb(180 83 9 / 0.1) !important;
}

.tw-decoration-amber-700\/100 {
  text-decoration-color: rgb(180 83 9 / 1) !important;
}

.tw-decoration-amber-700\/15 {
  text-decoration-color: rgb(180 83 9 / 0.15) !important;
}

.tw-decoration-amber-700\/20 {
  text-decoration-color: rgb(180 83 9 / 0.2) !important;
}

.tw-decoration-amber-700\/25 {
  text-decoration-color: rgb(180 83 9 / 0.25) !important;
}

.tw-decoration-amber-700\/30 {
  text-decoration-color: rgb(180 83 9 / 0.3) !important;
}

.tw-decoration-amber-700\/35 {
  text-decoration-color: rgb(180 83 9 / 0.35) !important;
}

.tw-decoration-amber-700\/40 {
  text-decoration-color: rgb(180 83 9 / 0.4) !important;
}

.tw-decoration-amber-700\/45 {
  text-decoration-color: rgb(180 83 9 / 0.45) !important;
}

.tw-decoration-amber-700\/5 {
  text-decoration-color: rgb(180 83 9 / 0.05) !important;
}

.tw-decoration-amber-700\/50 {
  text-decoration-color: rgb(180 83 9 / 0.5) !important;
}

.tw-decoration-amber-700\/55 {
  text-decoration-color: rgb(180 83 9 / 0.55) !important;
}

.tw-decoration-amber-700\/60 {
  text-decoration-color: rgb(180 83 9 / 0.6) !important;
}

.tw-decoration-amber-700\/65 {
  text-decoration-color: rgb(180 83 9 / 0.65) !important;
}

.tw-decoration-amber-700\/70 {
  text-decoration-color: rgb(180 83 9 / 0.7) !important;
}

.tw-decoration-amber-700\/75 {
  text-decoration-color: rgb(180 83 9 / 0.75) !important;
}

.tw-decoration-amber-700\/80 {
  text-decoration-color: rgb(180 83 9 / 0.8) !important;
}

.tw-decoration-amber-700\/85 {
  text-decoration-color: rgb(180 83 9 / 0.85) !important;
}

.tw-decoration-amber-700\/90 {
  text-decoration-color: rgb(180 83 9 / 0.9) !important;
}

.tw-decoration-amber-700\/95 {
  text-decoration-color: rgb(180 83 9 / 0.95) !important;
}

.tw-decoration-amber-800 {
  text-decoration-color: #92400e !important;
}

.tw-decoration-amber-800\/0 {
  text-decoration-color: rgb(146 64 14 / 0) !important;
}

.tw-decoration-amber-800\/10 {
  text-decoration-color: rgb(146 64 14 / 0.1) !important;
}

.tw-decoration-amber-800\/100 {
  text-decoration-color: rgb(146 64 14 / 1) !important;
}

.tw-decoration-amber-800\/15 {
  text-decoration-color: rgb(146 64 14 / 0.15) !important;
}

.tw-decoration-amber-800\/20 {
  text-decoration-color: rgb(146 64 14 / 0.2) !important;
}

.tw-decoration-amber-800\/25 {
  text-decoration-color: rgb(146 64 14 / 0.25) !important;
}

.tw-decoration-amber-800\/30 {
  text-decoration-color: rgb(146 64 14 / 0.3) !important;
}

.tw-decoration-amber-800\/35 {
  text-decoration-color: rgb(146 64 14 / 0.35) !important;
}

.tw-decoration-amber-800\/40 {
  text-decoration-color: rgb(146 64 14 / 0.4) !important;
}

.tw-decoration-amber-800\/45 {
  text-decoration-color: rgb(146 64 14 / 0.45) !important;
}

.tw-decoration-amber-800\/5 {
  text-decoration-color: rgb(146 64 14 / 0.05) !important;
}

.tw-decoration-amber-800\/50 {
  text-decoration-color: rgb(146 64 14 / 0.5) !important;
}

.tw-decoration-amber-800\/55 {
  text-decoration-color: rgb(146 64 14 / 0.55) !important;
}

.tw-decoration-amber-800\/60 {
  text-decoration-color: rgb(146 64 14 / 0.6) !important;
}

.tw-decoration-amber-800\/65 {
  text-decoration-color: rgb(146 64 14 / 0.65) !important;
}

.tw-decoration-amber-800\/70 {
  text-decoration-color: rgb(146 64 14 / 0.7) !important;
}

.tw-decoration-amber-800\/75 {
  text-decoration-color: rgb(146 64 14 / 0.75) !important;
}

.tw-decoration-amber-800\/80 {
  text-decoration-color: rgb(146 64 14 / 0.8) !important;
}

.tw-decoration-amber-800\/85 {
  text-decoration-color: rgb(146 64 14 / 0.85) !important;
}

.tw-decoration-amber-800\/90 {
  text-decoration-color: rgb(146 64 14 / 0.9) !important;
}

.tw-decoration-amber-800\/95 {
  text-decoration-color: rgb(146 64 14 / 0.95) !important;
}

.tw-decoration-amber-900 {
  text-decoration-color: #78350f !important;
}

.tw-decoration-amber-900\/0 {
  text-decoration-color: rgb(120 53 15 / 0) !important;
}

.tw-decoration-amber-900\/10 {
  text-decoration-color: rgb(120 53 15 / 0.1) !important;
}

.tw-decoration-amber-900\/100 {
  text-decoration-color: rgb(120 53 15 / 1) !important;
}

.tw-decoration-amber-900\/15 {
  text-decoration-color: rgb(120 53 15 / 0.15) !important;
}

.tw-decoration-amber-900\/20 {
  text-decoration-color: rgb(120 53 15 / 0.2) !important;
}

.tw-decoration-amber-900\/25 {
  text-decoration-color: rgb(120 53 15 / 0.25) !important;
}

.tw-decoration-amber-900\/30 {
  text-decoration-color: rgb(120 53 15 / 0.3) !important;
}

.tw-decoration-amber-900\/35 {
  text-decoration-color: rgb(120 53 15 / 0.35) !important;
}

.tw-decoration-amber-900\/40 {
  text-decoration-color: rgb(120 53 15 / 0.4) !important;
}

.tw-decoration-amber-900\/45 {
  text-decoration-color: rgb(120 53 15 / 0.45) !important;
}

.tw-decoration-amber-900\/5 {
  text-decoration-color: rgb(120 53 15 / 0.05) !important;
}

.tw-decoration-amber-900\/50 {
  text-decoration-color: rgb(120 53 15 / 0.5) !important;
}

.tw-decoration-amber-900\/55 {
  text-decoration-color: rgb(120 53 15 / 0.55) !important;
}

.tw-decoration-amber-900\/60 {
  text-decoration-color: rgb(120 53 15 / 0.6) !important;
}

.tw-decoration-amber-900\/65 {
  text-decoration-color: rgb(120 53 15 / 0.65) !important;
}

.tw-decoration-amber-900\/70 {
  text-decoration-color: rgb(120 53 15 / 0.7) !important;
}

.tw-decoration-amber-900\/75 {
  text-decoration-color: rgb(120 53 15 / 0.75) !important;
}

.tw-decoration-amber-900\/80 {
  text-decoration-color: rgb(120 53 15 / 0.8) !important;
}

.tw-decoration-amber-900\/85 {
  text-decoration-color: rgb(120 53 15 / 0.85) !important;
}

.tw-decoration-amber-900\/90 {
  text-decoration-color: rgb(120 53 15 / 0.9) !important;
}

.tw-decoration-amber-900\/95 {
  text-decoration-color: rgb(120 53 15 / 0.95) !important;
}

.tw-decoration-amber-950 {
  text-decoration-color: #451a03 !important;
}

.tw-decoration-amber-950\/0 {
  text-decoration-color: rgb(69 26 3 / 0) !important;
}

.tw-decoration-amber-950\/10 {
  text-decoration-color: rgb(69 26 3 / 0.1) !important;
}

.tw-decoration-amber-950\/100 {
  text-decoration-color: rgb(69 26 3 / 1) !important;
}

.tw-decoration-amber-950\/15 {
  text-decoration-color: rgb(69 26 3 / 0.15) !important;
}

.tw-decoration-amber-950\/20 {
  text-decoration-color: rgb(69 26 3 / 0.2) !important;
}

.tw-decoration-amber-950\/25 {
  text-decoration-color: rgb(69 26 3 / 0.25) !important;
}

.tw-decoration-amber-950\/30 {
  text-decoration-color: rgb(69 26 3 / 0.3) !important;
}

.tw-decoration-amber-950\/35 {
  text-decoration-color: rgb(69 26 3 / 0.35) !important;
}

.tw-decoration-amber-950\/40 {
  text-decoration-color: rgb(69 26 3 / 0.4) !important;
}

.tw-decoration-amber-950\/45 {
  text-decoration-color: rgb(69 26 3 / 0.45) !important;
}

.tw-decoration-amber-950\/5 {
  text-decoration-color: rgb(69 26 3 / 0.05) !important;
}

.tw-decoration-amber-950\/50 {
  text-decoration-color: rgb(69 26 3 / 0.5) !important;
}

.tw-decoration-amber-950\/55 {
  text-decoration-color: rgb(69 26 3 / 0.55) !important;
}

.tw-decoration-amber-950\/60 {
  text-decoration-color: rgb(69 26 3 / 0.6) !important;
}

.tw-decoration-amber-950\/65 {
  text-decoration-color: rgb(69 26 3 / 0.65) !important;
}

.tw-decoration-amber-950\/70 {
  text-decoration-color: rgb(69 26 3 / 0.7) !important;
}

.tw-decoration-amber-950\/75 {
  text-decoration-color: rgb(69 26 3 / 0.75) !important;
}

.tw-decoration-amber-950\/80 {
  text-decoration-color: rgb(69 26 3 / 0.8) !important;
}

.tw-decoration-amber-950\/85 {
  text-decoration-color: rgb(69 26 3 / 0.85) !important;
}

.tw-decoration-amber-950\/90 {
  text-decoration-color: rgb(69 26 3 / 0.9) !important;
}

.tw-decoration-amber-950\/95 {
  text-decoration-color: rgb(69 26 3 / 0.95) !important;
}

.tw-decoration-black {
  text-decoration-color: #000 !important;
}

.tw-decoration-black\/0 {
  text-decoration-color: rgb(0 0 0 / 0) !important;
}

.tw-decoration-black\/10 {
  text-decoration-color: rgb(0 0 0 / 0.1) !important;
}

.tw-decoration-black\/100 {
  text-decoration-color: rgb(0 0 0 / 1) !important;
}

.tw-decoration-black\/15 {
  text-decoration-color: rgb(0 0 0 / 0.15) !important;
}

.tw-decoration-black\/20 {
  text-decoration-color: rgb(0 0 0 / 0.2) !important;
}

.tw-decoration-black\/25 {
  text-decoration-color: rgb(0 0 0 / 0.25) !important;
}

.tw-decoration-black\/30 {
  text-decoration-color: rgb(0 0 0 / 0.3) !important;
}

.tw-decoration-black\/35 {
  text-decoration-color: rgb(0 0 0 / 0.35) !important;
}

.tw-decoration-black\/40 {
  text-decoration-color: rgb(0 0 0 / 0.4) !important;
}

.tw-decoration-black\/45 {
  text-decoration-color: rgb(0 0 0 / 0.45) !important;
}

.tw-decoration-black\/5 {
  text-decoration-color: rgb(0 0 0 / 0.05) !important;
}

.tw-decoration-black\/50 {
  text-decoration-color: rgb(0 0 0 / 0.5) !important;
}

.tw-decoration-black\/55 {
  text-decoration-color: rgb(0 0 0 / 0.55) !important;
}

.tw-decoration-black\/60 {
  text-decoration-color: rgb(0 0 0 / 0.6) !important;
}

.tw-decoration-black\/65 {
  text-decoration-color: rgb(0 0 0 / 0.65) !important;
}

.tw-decoration-black\/70 {
  text-decoration-color: rgb(0 0 0 / 0.7) !important;
}

.tw-decoration-black\/75 {
  text-decoration-color: rgb(0 0 0 / 0.75) !important;
}

.tw-decoration-black\/80 {
  text-decoration-color: rgb(0 0 0 / 0.8) !important;
}

.tw-decoration-black\/85 {
  text-decoration-color: rgb(0 0 0 / 0.85) !important;
}

.tw-decoration-black\/90 {
  text-decoration-color: rgb(0 0 0 / 0.9) !important;
}

.tw-decoration-black\/95 {
  text-decoration-color: rgb(0 0 0 / 0.95) !important;
}

.tw-decoration-blue-100 {
  text-decoration-color: #dbeafe !important;
}

.tw-decoration-blue-100\/0 {
  text-decoration-color: rgb(219 234 254 / 0) !important;
}

.tw-decoration-blue-100\/10 {
  text-decoration-color: rgb(219 234 254 / 0.1) !important;
}

.tw-decoration-blue-100\/100 {
  text-decoration-color: rgb(219 234 254 / 1) !important;
}

.tw-decoration-blue-100\/15 {
  text-decoration-color: rgb(219 234 254 / 0.15) !important;
}

.tw-decoration-blue-100\/20 {
  text-decoration-color: rgb(219 234 254 / 0.2) !important;
}

.tw-decoration-blue-100\/25 {
  text-decoration-color: rgb(219 234 254 / 0.25) !important;
}

.tw-decoration-blue-100\/30 {
  text-decoration-color: rgb(219 234 254 / 0.3) !important;
}

.tw-decoration-blue-100\/35 {
  text-decoration-color: rgb(219 234 254 / 0.35) !important;
}

.tw-decoration-blue-100\/40 {
  text-decoration-color: rgb(219 234 254 / 0.4) !important;
}

.tw-decoration-blue-100\/45 {
  text-decoration-color: rgb(219 234 254 / 0.45) !important;
}

.tw-decoration-blue-100\/5 {
  text-decoration-color: rgb(219 234 254 / 0.05) !important;
}

.tw-decoration-blue-100\/50 {
  text-decoration-color: rgb(219 234 254 / 0.5) !important;
}

.tw-decoration-blue-100\/55 {
  text-decoration-color: rgb(219 234 254 / 0.55) !important;
}

.tw-decoration-blue-100\/60 {
  text-decoration-color: rgb(219 234 254 / 0.6) !important;
}

.tw-decoration-blue-100\/65 {
  text-decoration-color: rgb(219 234 254 / 0.65) !important;
}

.tw-decoration-blue-100\/70 {
  text-decoration-color: rgb(219 234 254 / 0.7) !important;
}

.tw-decoration-blue-100\/75 {
  text-decoration-color: rgb(219 234 254 / 0.75) !important;
}

.tw-decoration-blue-100\/80 {
  text-decoration-color: rgb(219 234 254 / 0.8) !important;
}

.tw-decoration-blue-100\/85 {
  text-decoration-color: rgb(219 234 254 / 0.85) !important;
}

.tw-decoration-blue-100\/90 {
  text-decoration-color: rgb(219 234 254 / 0.9) !important;
}

.tw-decoration-blue-100\/95 {
  text-decoration-color: rgb(219 234 254 / 0.95) !important;
}

.tw-decoration-blue-200 {
  text-decoration-color: #bfdbfe !important;
}

.tw-decoration-blue-200\/0 {
  text-decoration-color: rgb(191 219 254 / 0) !important;
}

.tw-decoration-blue-200\/10 {
  text-decoration-color: rgb(191 219 254 / 0.1) !important;
}

.tw-decoration-blue-200\/100 {
  text-decoration-color: rgb(191 219 254 / 1) !important;
}

.tw-decoration-blue-200\/15 {
  text-decoration-color: rgb(191 219 254 / 0.15) !important;
}

.tw-decoration-blue-200\/20 {
  text-decoration-color: rgb(191 219 254 / 0.2) !important;
}

.tw-decoration-blue-200\/25 {
  text-decoration-color: rgb(191 219 254 / 0.25) !important;
}

.tw-decoration-blue-200\/30 {
  text-decoration-color: rgb(191 219 254 / 0.3) !important;
}

.tw-decoration-blue-200\/35 {
  text-decoration-color: rgb(191 219 254 / 0.35) !important;
}

.tw-decoration-blue-200\/40 {
  text-decoration-color: rgb(191 219 254 / 0.4) !important;
}

.tw-decoration-blue-200\/45 {
  text-decoration-color: rgb(191 219 254 / 0.45) !important;
}

.tw-decoration-blue-200\/5 {
  text-decoration-color: rgb(191 219 254 / 0.05) !important;
}

.tw-decoration-blue-200\/50 {
  text-decoration-color: rgb(191 219 254 / 0.5) !important;
}

.tw-decoration-blue-200\/55 {
  text-decoration-color: rgb(191 219 254 / 0.55) !important;
}

.tw-decoration-blue-200\/60 {
  text-decoration-color: rgb(191 219 254 / 0.6) !important;
}

.tw-decoration-blue-200\/65 {
  text-decoration-color: rgb(191 219 254 / 0.65) !important;
}

.tw-decoration-blue-200\/70 {
  text-decoration-color: rgb(191 219 254 / 0.7) !important;
}

.tw-decoration-blue-200\/75 {
  text-decoration-color: rgb(191 219 254 / 0.75) !important;
}

.tw-decoration-blue-200\/80 {
  text-decoration-color: rgb(191 219 254 / 0.8) !important;
}

.tw-decoration-blue-200\/85 {
  text-decoration-color: rgb(191 219 254 / 0.85) !important;
}

.tw-decoration-blue-200\/90 {
  text-decoration-color: rgb(191 219 254 / 0.9) !important;
}

.tw-decoration-blue-200\/95 {
  text-decoration-color: rgb(191 219 254 / 0.95) !important;
}

.tw-decoration-blue-300 {
  text-decoration-color: #93c5fd !important;
}

.tw-decoration-blue-300\/0 {
  text-decoration-color: rgb(147 197 253 / 0) !important;
}

.tw-decoration-blue-300\/10 {
  text-decoration-color: rgb(147 197 253 / 0.1) !important;
}

.tw-decoration-blue-300\/100 {
  text-decoration-color: rgb(147 197 253 / 1) !important;
}

.tw-decoration-blue-300\/15 {
  text-decoration-color: rgb(147 197 253 / 0.15) !important;
}

.tw-decoration-blue-300\/20 {
  text-decoration-color: rgb(147 197 253 / 0.2) !important;
}

.tw-decoration-blue-300\/25 {
  text-decoration-color: rgb(147 197 253 / 0.25) !important;
}

.tw-decoration-blue-300\/30 {
  text-decoration-color: rgb(147 197 253 / 0.3) !important;
}

.tw-decoration-blue-300\/35 {
  text-decoration-color: rgb(147 197 253 / 0.35) !important;
}

.tw-decoration-blue-300\/40 {
  text-decoration-color: rgb(147 197 253 / 0.4) !important;
}

.tw-decoration-blue-300\/45 {
  text-decoration-color: rgb(147 197 253 / 0.45) !important;
}

.tw-decoration-blue-300\/5 {
  text-decoration-color: rgb(147 197 253 / 0.05) !important;
}

.tw-decoration-blue-300\/50 {
  text-decoration-color: rgb(147 197 253 / 0.5) !important;
}

.tw-decoration-blue-300\/55 {
  text-decoration-color: rgb(147 197 253 / 0.55) !important;
}

.tw-decoration-blue-300\/60 {
  text-decoration-color: rgb(147 197 253 / 0.6) !important;
}

.tw-decoration-blue-300\/65 {
  text-decoration-color: rgb(147 197 253 / 0.65) !important;
}

.tw-decoration-blue-300\/70 {
  text-decoration-color: rgb(147 197 253 / 0.7) !important;
}

.tw-decoration-blue-300\/75 {
  text-decoration-color: rgb(147 197 253 / 0.75) !important;
}

.tw-decoration-blue-300\/80 {
  text-decoration-color: rgb(147 197 253 / 0.8) !important;
}

.tw-decoration-blue-300\/85 {
  text-decoration-color: rgb(147 197 253 / 0.85) !important;
}

.tw-decoration-blue-300\/90 {
  text-decoration-color: rgb(147 197 253 / 0.9) !important;
}

.tw-decoration-blue-300\/95 {
  text-decoration-color: rgb(147 197 253 / 0.95) !important;
}

.tw-decoration-blue-400 {
  text-decoration-color: #60a5fa !important;
}

.tw-decoration-blue-400\/0 {
  text-decoration-color: rgb(96 165 250 / 0) !important;
}

.tw-decoration-blue-400\/10 {
  text-decoration-color: rgb(96 165 250 / 0.1) !important;
}

.tw-decoration-blue-400\/100 {
  text-decoration-color: rgb(96 165 250 / 1) !important;
}

.tw-decoration-blue-400\/15 {
  text-decoration-color: rgb(96 165 250 / 0.15) !important;
}

.tw-decoration-blue-400\/20 {
  text-decoration-color: rgb(96 165 250 / 0.2) !important;
}

.tw-decoration-blue-400\/25 {
  text-decoration-color: rgb(96 165 250 / 0.25) !important;
}

.tw-decoration-blue-400\/30 {
  text-decoration-color: rgb(96 165 250 / 0.3) !important;
}

.tw-decoration-blue-400\/35 {
  text-decoration-color: rgb(96 165 250 / 0.35) !important;
}

.tw-decoration-blue-400\/40 {
  text-decoration-color: rgb(96 165 250 / 0.4) !important;
}

.tw-decoration-blue-400\/45 {
  text-decoration-color: rgb(96 165 250 / 0.45) !important;
}

.tw-decoration-blue-400\/5 {
  text-decoration-color: rgb(96 165 250 / 0.05) !important;
}

.tw-decoration-blue-400\/50 {
  text-decoration-color: rgb(96 165 250 / 0.5) !important;
}

.tw-decoration-blue-400\/55 {
  text-decoration-color: rgb(96 165 250 / 0.55) !important;
}

.tw-decoration-blue-400\/60 {
  text-decoration-color: rgb(96 165 250 / 0.6) !important;
}

.tw-decoration-blue-400\/65 {
  text-decoration-color: rgb(96 165 250 / 0.65) !important;
}

.tw-decoration-blue-400\/70 {
  text-decoration-color: rgb(96 165 250 / 0.7) !important;
}

.tw-decoration-blue-400\/75 {
  text-decoration-color: rgb(96 165 250 / 0.75) !important;
}

.tw-decoration-blue-400\/80 {
  text-decoration-color: rgb(96 165 250 / 0.8) !important;
}

.tw-decoration-blue-400\/85 {
  text-decoration-color: rgb(96 165 250 / 0.85) !important;
}

.tw-decoration-blue-400\/90 {
  text-decoration-color: rgb(96 165 250 / 0.9) !important;
}

.tw-decoration-blue-400\/95 {
  text-decoration-color: rgb(96 165 250 / 0.95) !important;
}

.tw-decoration-blue-50 {
  text-decoration-color: #eff6ff !important;
}

.tw-decoration-blue-50\/0 {
  text-decoration-color: rgb(239 246 255 / 0) !important;
}

.tw-decoration-blue-50\/10 {
  text-decoration-color: rgb(239 246 255 / 0.1) !important;
}

.tw-decoration-blue-50\/100 {
  text-decoration-color: rgb(239 246 255 / 1) !important;
}

.tw-decoration-blue-50\/15 {
  text-decoration-color: rgb(239 246 255 / 0.15) !important;
}

.tw-decoration-blue-50\/20 {
  text-decoration-color: rgb(239 246 255 / 0.2) !important;
}

.tw-decoration-blue-50\/25 {
  text-decoration-color: rgb(239 246 255 / 0.25) !important;
}

.tw-decoration-blue-50\/30 {
  text-decoration-color: rgb(239 246 255 / 0.3) !important;
}

.tw-decoration-blue-50\/35 {
  text-decoration-color: rgb(239 246 255 / 0.35) !important;
}

.tw-decoration-blue-50\/40 {
  text-decoration-color: rgb(239 246 255 / 0.4) !important;
}

.tw-decoration-blue-50\/45 {
  text-decoration-color: rgb(239 246 255 / 0.45) !important;
}

.tw-decoration-blue-50\/5 {
  text-decoration-color: rgb(239 246 255 / 0.05) !important;
}

.tw-decoration-blue-50\/50 {
  text-decoration-color: rgb(239 246 255 / 0.5) !important;
}

.tw-decoration-blue-50\/55 {
  text-decoration-color: rgb(239 246 255 / 0.55) !important;
}

.tw-decoration-blue-50\/60 {
  text-decoration-color: rgb(239 246 255 / 0.6) !important;
}

.tw-decoration-blue-50\/65 {
  text-decoration-color: rgb(239 246 255 / 0.65) !important;
}

.tw-decoration-blue-50\/70 {
  text-decoration-color: rgb(239 246 255 / 0.7) !important;
}

.tw-decoration-blue-50\/75 {
  text-decoration-color: rgb(239 246 255 / 0.75) !important;
}

.tw-decoration-blue-50\/80 {
  text-decoration-color: rgb(239 246 255 / 0.8) !important;
}

.tw-decoration-blue-50\/85 {
  text-decoration-color: rgb(239 246 255 / 0.85) !important;
}

.tw-decoration-blue-50\/90 {
  text-decoration-color: rgb(239 246 255 / 0.9) !important;
}

.tw-decoration-blue-50\/95 {
  text-decoration-color: rgb(239 246 255 / 0.95) !important;
}

.tw-decoration-blue-500 {
  text-decoration-color: #3b82f6 !important;
}

.tw-decoration-blue-500\/0 {
  text-decoration-color: rgb(59 130 246 / 0) !important;
}

.tw-decoration-blue-500\/10 {
  text-decoration-color: rgb(59 130 246 / 0.1) !important;
}

.tw-decoration-blue-500\/100 {
  text-decoration-color: rgb(59 130 246 / 1) !important;
}

.tw-decoration-blue-500\/15 {
  text-decoration-color: rgb(59 130 246 / 0.15) !important;
}

.tw-decoration-blue-500\/20 {
  text-decoration-color: rgb(59 130 246 / 0.2) !important;
}

.tw-decoration-blue-500\/25 {
  text-decoration-color: rgb(59 130 246 / 0.25) !important;
}

.tw-decoration-blue-500\/30 {
  text-decoration-color: rgb(59 130 246 / 0.3) !important;
}

.tw-decoration-blue-500\/35 {
  text-decoration-color: rgb(59 130 246 / 0.35) !important;
}

.tw-decoration-blue-500\/40 {
  text-decoration-color: rgb(59 130 246 / 0.4) !important;
}

.tw-decoration-blue-500\/45 {
  text-decoration-color: rgb(59 130 246 / 0.45) !important;
}

.tw-decoration-blue-500\/5 {
  text-decoration-color: rgb(59 130 246 / 0.05) !important;
}

.tw-decoration-blue-500\/50 {
  text-decoration-color: rgb(59 130 246 / 0.5) !important;
}

.tw-decoration-blue-500\/55 {
  text-decoration-color: rgb(59 130 246 / 0.55) !important;
}

.tw-decoration-blue-500\/60 {
  text-decoration-color: rgb(59 130 246 / 0.6) !important;
}

.tw-decoration-blue-500\/65 {
  text-decoration-color: rgb(59 130 246 / 0.65) !important;
}

.tw-decoration-blue-500\/70 {
  text-decoration-color: rgb(59 130 246 / 0.7) !important;
}

.tw-decoration-blue-500\/75 {
  text-decoration-color: rgb(59 130 246 / 0.75) !important;
}

.tw-decoration-blue-500\/80 {
  text-decoration-color: rgb(59 130 246 / 0.8) !important;
}

.tw-decoration-blue-500\/85 {
  text-decoration-color: rgb(59 130 246 / 0.85) !important;
}

.tw-decoration-blue-500\/90 {
  text-decoration-color: rgb(59 130 246 / 0.9) !important;
}

.tw-decoration-blue-500\/95 {
  text-decoration-color: rgb(59 130 246 / 0.95) !important;
}

.tw-decoration-blue-600 {
  text-decoration-color: #2563eb !important;
}

.tw-decoration-blue-600\/0 {
  text-decoration-color: rgb(37 99 235 / 0) !important;
}

.tw-decoration-blue-600\/10 {
  text-decoration-color: rgb(37 99 235 / 0.1) !important;
}

.tw-decoration-blue-600\/100 {
  text-decoration-color: rgb(37 99 235 / 1) !important;
}

.tw-decoration-blue-600\/15 {
  text-decoration-color: rgb(37 99 235 / 0.15) !important;
}

.tw-decoration-blue-600\/20 {
  text-decoration-color: rgb(37 99 235 / 0.2) !important;
}

.tw-decoration-blue-600\/25 {
  text-decoration-color: rgb(37 99 235 / 0.25) !important;
}

.tw-decoration-blue-600\/30 {
  text-decoration-color: rgb(37 99 235 / 0.3) !important;
}

.tw-decoration-blue-600\/35 {
  text-decoration-color: rgb(37 99 235 / 0.35) !important;
}

.tw-decoration-blue-600\/40 {
  text-decoration-color: rgb(37 99 235 / 0.4) !important;
}

.tw-decoration-blue-600\/45 {
  text-decoration-color: rgb(37 99 235 / 0.45) !important;
}

.tw-decoration-blue-600\/5 {
  text-decoration-color: rgb(37 99 235 / 0.05) !important;
}

.tw-decoration-blue-600\/50 {
  text-decoration-color: rgb(37 99 235 / 0.5) !important;
}

.tw-decoration-blue-600\/55 {
  text-decoration-color: rgb(37 99 235 / 0.55) !important;
}

.tw-decoration-blue-600\/60 {
  text-decoration-color: rgb(37 99 235 / 0.6) !important;
}

.tw-decoration-blue-600\/65 {
  text-decoration-color: rgb(37 99 235 / 0.65) !important;
}

.tw-decoration-blue-600\/70 {
  text-decoration-color: rgb(37 99 235 / 0.7) !important;
}

.tw-decoration-blue-600\/75 {
  text-decoration-color: rgb(37 99 235 / 0.75) !important;
}

.tw-decoration-blue-600\/80 {
  text-decoration-color: rgb(37 99 235 / 0.8) !important;
}

.tw-decoration-blue-600\/85 {
  text-decoration-color: rgb(37 99 235 / 0.85) !important;
}

.tw-decoration-blue-600\/90 {
  text-decoration-color: rgb(37 99 235 / 0.9) !important;
}

.tw-decoration-blue-600\/95 {
  text-decoration-color: rgb(37 99 235 / 0.95) !important;
}

.tw-decoration-blue-700 {
  text-decoration-color: #1d4ed8 !important;
}

.tw-decoration-blue-700\/0 {
  text-decoration-color: rgb(29 78 216 / 0) !important;
}

.tw-decoration-blue-700\/10 {
  text-decoration-color: rgb(29 78 216 / 0.1) !important;
}

.tw-decoration-blue-700\/100 {
  text-decoration-color: rgb(29 78 216 / 1) !important;
}

.tw-decoration-blue-700\/15 {
  text-decoration-color: rgb(29 78 216 / 0.15) !important;
}

.tw-decoration-blue-700\/20 {
  text-decoration-color: rgb(29 78 216 / 0.2) !important;
}

.tw-decoration-blue-700\/25 {
  text-decoration-color: rgb(29 78 216 / 0.25) !important;
}

.tw-decoration-blue-700\/30 {
  text-decoration-color: rgb(29 78 216 / 0.3) !important;
}

.tw-decoration-blue-700\/35 {
  text-decoration-color: rgb(29 78 216 / 0.35) !important;
}

.tw-decoration-blue-700\/40 {
  text-decoration-color: rgb(29 78 216 / 0.4) !important;
}

.tw-decoration-blue-700\/45 {
  text-decoration-color: rgb(29 78 216 / 0.45) !important;
}

.tw-decoration-blue-700\/5 {
  text-decoration-color: rgb(29 78 216 / 0.05) !important;
}

.tw-decoration-blue-700\/50 {
  text-decoration-color: rgb(29 78 216 / 0.5) !important;
}

.tw-decoration-blue-700\/55 {
  text-decoration-color: rgb(29 78 216 / 0.55) !important;
}

.tw-decoration-blue-700\/60 {
  text-decoration-color: rgb(29 78 216 / 0.6) !important;
}

.tw-decoration-blue-700\/65 {
  text-decoration-color: rgb(29 78 216 / 0.65) !important;
}

.tw-decoration-blue-700\/70 {
  text-decoration-color: rgb(29 78 216 / 0.7) !important;
}

.tw-decoration-blue-700\/75 {
  text-decoration-color: rgb(29 78 216 / 0.75) !important;
}

.tw-decoration-blue-700\/80 {
  text-decoration-color: rgb(29 78 216 / 0.8) !important;
}

.tw-decoration-blue-700\/85 {
  text-decoration-color: rgb(29 78 216 / 0.85) !important;
}

.tw-decoration-blue-700\/90 {
  text-decoration-color: rgb(29 78 216 / 0.9) !important;
}

.tw-decoration-blue-700\/95 {
  text-decoration-color: rgb(29 78 216 / 0.95) !important;
}

.tw-decoration-blue-800 {
  text-decoration-color: #1e40af !important;
}

.tw-decoration-blue-800\/0 {
  text-decoration-color: rgb(30 64 175 / 0) !important;
}

.tw-decoration-blue-800\/10 {
  text-decoration-color: rgb(30 64 175 / 0.1) !important;
}

.tw-decoration-blue-800\/100 {
  text-decoration-color: rgb(30 64 175 / 1) !important;
}

.tw-decoration-blue-800\/15 {
  text-decoration-color: rgb(30 64 175 / 0.15) !important;
}

.tw-decoration-blue-800\/20 {
  text-decoration-color: rgb(30 64 175 / 0.2) !important;
}

.tw-decoration-blue-800\/25 {
  text-decoration-color: rgb(30 64 175 / 0.25) !important;
}

.tw-decoration-blue-800\/30 {
  text-decoration-color: rgb(30 64 175 / 0.3) !important;
}

.tw-decoration-blue-800\/35 {
  text-decoration-color: rgb(30 64 175 / 0.35) !important;
}

.tw-decoration-blue-800\/40 {
  text-decoration-color: rgb(30 64 175 / 0.4) !important;
}

.tw-decoration-blue-800\/45 {
  text-decoration-color: rgb(30 64 175 / 0.45) !important;
}

.tw-decoration-blue-800\/5 {
  text-decoration-color: rgb(30 64 175 / 0.05) !important;
}

.tw-decoration-blue-800\/50 {
  text-decoration-color: rgb(30 64 175 / 0.5) !important;
}

.tw-decoration-blue-800\/55 {
  text-decoration-color: rgb(30 64 175 / 0.55) !important;
}

.tw-decoration-blue-800\/60 {
  text-decoration-color: rgb(30 64 175 / 0.6) !important;
}

.tw-decoration-blue-800\/65 {
  text-decoration-color: rgb(30 64 175 / 0.65) !important;
}

.tw-decoration-blue-800\/70 {
  text-decoration-color: rgb(30 64 175 / 0.7) !important;
}

.tw-decoration-blue-800\/75 {
  text-decoration-color: rgb(30 64 175 / 0.75) !important;
}

.tw-decoration-blue-800\/80 {
  text-decoration-color: rgb(30 64 175 / 0.8) !important;
}

.tw-decoration-blue-800\/85 {
  text-decoration-color: rgb(30 64 175 / 0.85) !important;
}

.tw-decoration-blue-800\/90 {
  text-decoration-color: rgb(30 64 175 / 0.9) !important;
}

.tw-decoration-blue-800\/95 {
  text-decoration-color: rgb(30 64 175 / 0.95) !important;
}

.tw-decoration-blue-900 {
  text-decoration-color: #1e3a8a !important;
}

.tw-decoration-blue-900\/0 {
  text-decoration-color: rgb(30 58 138 / 0) !important;
}

.tw-decoration-blue-900\/10 {
  text-decoration-color: rgb(30 58 138 / 0.1) !important;
}

.tw-decoration-blue-900\/100 {
  text-decoration-color: rgb(30 58 138 / 1) !important;
}

.tw-decoration-blue-900\/15 {
  text-decoration-color: rgb(30 58 138 / 0.15) !important;
}

.tw-decoration-blue-900\/20 {
  text-decoration-color: rgb(30 58 138 / 0.2) !important;
}

.tw-decoration-blue-900\/25 {
  text-decoration-color: rgb(30 58 138 / 0.25) !important;
}

.tw-decoration-blue-900\/30 {
  text-decoration-color: rgb(30 58 138 / 0.3) !important;
}

.tw-decoration-blue-900\/35 {
  text-decoration-color: rgb(30 58 138 / 0.35) !important;
}

.tw-decoration-blue-900\/40 {
  text-decoration-color: rgb(30 58 138 / 0.4) !important;
}

.tw-decoration-blue-900\/45 {
  text-decoration-color: rgb(30 58 138 / 0.45) !important;
}

.tw-decoration-blue-900\/5 {
  text-decoration-color: rgb(30 58 138 / 0.05) !important;
}

.tw-decoration-blue-900\/50 {
  text-decoration-color: rgb(30 58 138 / 0.5) !important;
}

.tw-decoration-blue-900\/55 {
  text-decoration-color: rgb(30 58 138 / 0.55) !important;
}

.tw-decoration-blue-900\/60 {
  text-decoration-color: rgb(30 58 138 / 0.6) !important;
}

.tw-decoration-blue-900\/65 {
  text-decoration-color: rgb(30 58 138 / 0.65) !important;
}

.tw-decoration-blue-900\/70 {
  text-decoration-color: rgb(30 58 138 / 0.7) !important;
}

.tw-decoration-blue-900\/75 {
  text-decoration-color: rgb(30 58 138 / 0.75) !important;
}

.tw-decoration-blue-900\/80 {
  text-decoration-color: rgb(30 58 138 / 0.8) !important;
}

.tw-decoration-blue-900\/85 {
  text-decoration-color: rgb(30 58 138 / 0.85) !important;
}

.tw-decoration-blue-900\/90 {
  text-decoration-color: rgb(30 58 138 / 0.9) !important;
}

.tw-decoration-blue-900\/95 {
  text-decoration-color: rgb(30 58 138 / 0.95) !important;
}

.tw-decoration-blue-950 {
  text-decoration-color: #172554 !important;
}

.tw-decoration-blue-950\/0 {
  text-decoration-color: rgb(23 37 84 / 0) !important;
}

.tw-decoration-blue-950\/10 {
  text-decoration-color: rgb(23 37 84 / 0.1) !important;
}

.tw-decoration-blue-950\/100 {
  text-decoration-color: rgb(23 37 84 / 1) !important;
}

.tw-decoration-blue-950\/15 {
  text-decoration-color: rgb(23 37 84 / 0.15) !important;
}

.tw-decoration-blue-950\/20 {
  text-decoration-color: rgb(23 37 84 / 0.2) !important;
}

.tw-decoration-blue-950\/25 {
  text-decoration-color: rgb(23 37 84 / 0.25) !important;
}

.tw-decoration-blue-950\/30 {
  text-decoration-color: rgb(23 37 84 / 0.3) !important;
}

.tw-decoration-blue-950\/35 {
  text-decoration-color: rgb(23 37 84 / 0.35) !important;
}

.tw-decoration-blue-950\/40 {
  text-decoration-color: rgb(23 37 84 / 0.4) !important;
}

.tw-decoration-blue-950\/45 {
  text-decoration-color: rgb(23 37 84 / 0.45) !important;
}

.tw-decoration-blue-950\/5 {
  text-decoration-color: rgb(23 37 84 / 0.05) !important;
}

.tw-decoration-blue-950\/50 {
  text-decoration-color: rgb(23 37 84 / 0.5) !important;
}

.tw-decoration-blue-950\/55 {
  text-decoration-color: rgb(23 37 84 / 0.55) !important;
}

.tw-decoration-blue-950\/60 {
  text-decoration-color: rgb(23 37 84 / 0.6) !important;
}

.tw-decoration-blue-950\/65 {
  text-decoration-color: rgb(23 37 84 / 0.65) !important;
}

.tw-decoration-blue-950\/70 {
  text-decoration-color: rgb(23 37 84 / 0.7) !important;
}

.tw-decoration-blue-950\/75 {
  text-decoration-color: rgb(23 37 84 / 0.75) !important;
}

.tw-decoration-blue-950\/80 {
  text-decoration-color: rgb(23 37 84 / 0.8) !important;
}

.tw-decoration-blue-950\/85 {
  text-decoration-color: rgb(23 37 84 / 0.85) !important;
}

.tw-decoration-blue-950\/90 {
  text-decoration-color: rgb(23 37 84 / 0.9) !important;
}

.tw-decoration-blue-950\/95 {
  text-decoration-color: rgb(23 37 84 / 0.95) !important;
}

.tw-decoration-current {
  text-decoration-color: currentColor !important;
}

.tw-decoration-cyan-100 {
  text-decoration-color: #cffafe !important;
}

.tw-decoration-cyan-100\/0 {
  text-decoration-color: rgb(207 250 254 / 0) !important;
}

.tw-decoration-cyan-100\/10 {
  text-decoration-color: rgb(207 250 254 / 0.1) !important;
}

.tw-decoration-cyan-100\/100 {
  text-decoration-color: rgb(207 250 254 / 1) !important;
}

.tw-decoration-cyan-100\/15 {
  text-decoration-color: rgb(207 250 254 / 0.15) !important;
}

.tw-decoration-cyan-100\/20 {
  text-decoration-color: rgb(207 250 254 / 0.2) !important;
}

.tw-decoration-cyan-100\/25 {
  text-decoration-color: rgb(207 250 254 / 0.25) !important;
}

.tw-decoration-cyan-100\/30 {
  text-decoration-color: rgb(207 250 254 / 0.3) !important;
}

.tw-decoration-cyan-100\/35 {
  text-decoration-color: rgb(207 250 254 / 0.35) !important;
}

.tw-decoration-cyan-100\/40 {
  text-decoration-color: rgb(207 250 254 / 0.4) !important;
}

.tw-decoration-cyan-100\/45 {
  text-decoration-color: rgb(207 250 254 / 0.45) !important;
}

.tw-decoration-cyan-100\/5 {
  text-decoration-color: rgb(207 250 254 / 0.05) !important;
}

.tw-decoration-cyan-100\/50 {
  text-decoration-color: rgb(207 250 254 / 0.5) !important;
}

.tw-decoration-cyan-100\/55 {
  text-decoration-color: rgb(207 250 254 / 0.55) !important;
}

.tw-decoration-cyan-100\/60 {
  text-decoration-color: rgb(207 250 254 / 0.6) !important;
}

.tw-decoration-cyan-100\/65 {
  text-decoration-color: rgb(207 250 254 / 0.65) !important;
}

.tw-decoration-cyan-100\/70 {
  text-decoration-color: rgb(207 250 254 / 0.7) !important;
}

.tw-decoration-cyan-100\/75 {
  text-decoration-color: rgb(207 250 254 / 0.75) !important;
}

.tw-decoration-cyan-100\/80 {
  text-decoration-color: rgb(207 250 254 / 0.8) !important;
}

.tw-decoration-cyan-100\/85 {
  text-decoration-color: rgb(207 250 254 / 0.85) !important;
}

.tw-decoration-cyan-100\/90 {
  text-decoration-color: rgb(207 250 254 / 0.9) !important;
}

.tw-decoration-cyan-100\/95 {
  text-decoration-color: rgb(207 250 254 / 0.95) !important;
}

.tw-decoration-cyan-200 {
  text-decoration-color: #a5f3fc !important;
}

.tw-decoration-cyan-200\/0 {
  text-decoration-color: rgb(165 243 252 / 0) !important;
}

.tw-decoration-cyan-200\/10 {
  text-decoration-color: rgb(165 243 252 / 0.1) !important;
}

.tw-decoration-cyan-200\/100 {
  text-decoration-color: rgb(165 243 252 / 1) !important;
}

.tw-decoration-cyan-200\/15 {
  text-decoration-color: rgb(165 243 252 / 0.15) !important;
}

.tw-decoration-cyan-200\/20 {
  text-decoration-color: rgb(165 243 252 / 0.2) !important;
}

.tw-decoration-cyan-200\/25 {
  text-decoration-color: rgb(165 243 252 / 0.25) !important;
}

.tw-decoration-cyan-200\/30 {
  text-decoration-color: rgb(165 243 252 / 0.3) !important;
}

.tw-decoration-cyan-200\/35 {
  text-decoration-color: rgb(165 243 252 / 0.35) !important;
}

.tw-decoration-cyan-200\/40 {
  text-decoration-color: rgb(165 243 252 / 0.4) !important;
}

.tw-decoration-cyan-200\/45 {
  text-decoration-color: rgb(165 243 252 / 0.45) !important;
}

.tw-decoration-cyan-200\/5 {
  text-decoration-color: rgb(165 243 252 / 0.05) !important;
}

.tw-decoration-cyan-200\/50 {
  text-decoration-color: rgb(165 243 252 / 0.5) !important;
}

.tw-decoration-cyan-200\/55 {
  text-decoration-color: rgb(165 243 252 / 0.55) !important;
}

.tw-decoration-cyan-200\/60 {
  text-decoration-color: rgb(165 243 252 / 0.6) !important;
}

.tw-decoration-cyan-200\/65 {
  text-decoration-color: rgb(165 243 252 / 0.65) !important;
}

.tw-decoration-cyan-200\/70 {
  text-decoration-color: rgb(165 243 252 / 0.7) !important;
}

.tw-decoration-cyan-200\/75 {
  text-decoration-color: rgb(165 243 252 / 0.75) !important;
}

.tw-decoration-cyan-200\/80 {
  text-decoration-color: rgb(165 243 252 / 0.8) !important;
}

.tw-decoration-cyan-200\/85 {
  text-decoration-color: rgb(165 243 252 / 0.85) !important;
}

.tw-decoration-cyan-200\/90 {
  text-decoration-color: rgb(165 243 252 / 0.9) !important;
}

.tw-decoration-cyan-200\/95 {
  text-decoration-color: rgb(165 243 252 / 0.95) !important;
}

.tw-decoration-cyan-300 {
  text-decoration-color: #67e8f9 !important;
}

.tw-decoration-cyan-300\/0 {
  text-decoration-color: rgb(103 232 249 / 0) !important;
}

.tw-decoration-cyan-300\/10 {
  text-decoration-color: rgb(103 232 249 / 0.1) !important;
}

.tw-decoration-cyan-300\/100 {
  text-decoration-color: rgb(103 232 249 / 1) !important;
}

.tw-decoration-cyan-300\/15 {
  text-decoration-color: rgb(103 232 249 / 0.15) !important;
}

.tw-decoration-cyan-300\/20 {
  text-decoration-color: rgb(103 232 249 / 0.2) !important;
}

.tw-decoration-cyan-300\/25 {
  text-decoration-color: rgb(103 232 249 / 0.25) !important;
}

.tw-decoration-cyan-300\/30 {
  text-decoration-color: rgb(103 232 249 / 0.3) !important;
}

.tw-decoration-cyan-300\/35 {
  text-decoration-color: rgb(103 232 249 / 0.35) !important;
}

.tw-decoration-cyan-300\/40 {
  text-decoration-color: rgb(103 232 249 / 0.4) !important;
}

.tw-decoration-cyan-300\/45 {
  text-decoration-color: rgb(103 232 249 / 0.45) !important;
}

.tw-decoration-cyan-300\/5 {
  text-decoration-color: rgb(103 232 249 / 0.05) !important;
}

.tw-decoration-cyan-300\/50 {
  text-decoration-color: rgb(103 232 249 / 0.5) !important;
}

.tw-decoration-cyan-300\/55 {
  text-decoration-color: rgb(103 232 249 / 0.55) !important;
}

.tw-decoration-cyan-300\/60 {
  text-decoration-color: rgb(103 232 249 / 0.6) !important;
}

.tw-decoration-cyan-300\/65 {
  text-decoration-color: rgb(103 232 249 / 0.65) !important;
}

.tw-decoration-cyan-300\/70 {
  text-decoration-color: rgb(103 232 249 / 0.7) !important;
}

.tw-decoration-cyan-300\/75 {
  text-decoration-color: rgb(103 232 249 / 0.75) !important;
}

.tw-decoration-cyan-300\/80 {
  text-decoration-color: rgb(103 232 249 / 0.8) !important;
}

.tw-decoration-cyan-300\/85 {
  text-decoration-color: rgb(103 232 249 / 0.85) !important;
}

.tw-decoration-cyan-300\/90 {
  text-decoration-color: rgb(103 232 249 / 0.9) !important;
}

.tw-decoration-cyan-300\/95 {
  text-decoration-color: rgb(103 232 249 / 0.95) !important;
}

.tw-decoration-cyan-400 {
  text-decoration-color: #22d3ee !important;
}

.tw-decoration-cyan-400\/0 {
  text-decoration-color: rgb(34 211 238 / 0) !important;
}

.tw-decoration-cyan-400\/10 {
  text-decoration-color: rgb(34 211 238 / 0.1) !important;
}

.tw-decoration-cyan-400\/100 {
  text-decoration-color: rgb(34 211 238 / 1) !important;
}

.tw-decoration-cyan-400\/15 {
  text-decoration-color: rgb(34 211 238 / 0.15) !important;
}

.tw-decoration-cyan-400\/20 {
  text-decoration-color: rgb(34 211 238 / 0.2) !important;
}

.tw-decoration-cyan-400\/25 {
  text-decoration-color: rgb(34 211 238 / 0.25) !important;
}

.tw-decoration-cyan-400\/30 {
  text-decoration-color: rgb(34 211 238 / 0.3) !important;
}

.tw-decoration-cyan-400\/35 {
  text-decoration-color: rgb(34 211 238 / 0.35) !important;
}

.tw-decoration-cyan-400\/40 {
  text-decoration-color: rgb(34 211 238 / 0.4) !important;
}

.tw-decoration-cyan-400\/45 {
  text-decoration-color: rgb(34 211 238 / 0.45) !important;
}

.tw-decoration-cyan-400\/5 {
  text-decoration-color: rgb(34 211 238 / 0.05) !important;
}

.tw-decoration-cyan-400\/50 {
  text-decoration-color: rgb(34 211 238 / 0.5) !important;
}

.tw-decoration-cyan-400\/55 {
  text-decoration-color: rgb(34 211 238 / 0.55) !important;
}

.tw-decoration-cyan-400\/60 {
  text-decoration-color: rgb(34 211 238 / 0.6) !important;
}

.tw-decoration-cyan-400\/65 {
  text-decoration-color: rgb(34 211 238 / 0.65) !important;
}

.tw-decoration-cyan-400\/70 {
  text-decoration-color: rgb(34 211 238 / 0.7) !important;
}

.tw-decoration-cyan-400\/75 {
  text-decoration-color: rgb(34 211 238 / 0.75) !important;
}

.tw-decoration-cyan-400\/80 {
  text-decoration-color: rgb(34 211 238 / 0.8) !important;
}

.tw-decoration-cyan-400\/85 {
  text-decoration-color: rgb(34 211 238 / 0.85) !important;
}

.tw-decoration-cyan-400\/90 {
  text-decoration-color: rgb(34 211 238 / 0.9) !important;
}

.tw-decoration-cyan-400\/95 {
  text-decoration-color: rgb(34 211 238 / 0.95) !important;
}

.tw-decoration-cyan-50 {
  text-decoration-color: #ecfeff !important;
}

.tw-decoration-cyan-50\/0 {
  text-decoration-color: rgb(236 254 255 / 0) !important;
}

.tw-decoration-cyan-50\/10 {
  text-decoration-color: rgb(236 254 255 / 0.1) !important;
}

.tw-decoration-cyan-50\/100 {
  text-decoration-color: rgb(236 254 255 / 1) !important;
}

.tw-decoration-cyan-50\/15 {
  text-decoration-color: rgb(236 254 255 / 0.15) !important;
}

.tw-decoration-cyan-50\/20 {
  text-decoration-color: rgb(236 254 255 / 0.2) !important;
}

.tw-decoration-cyan-50\/25 {
  text-decoration-color: rgb(236 254 255 / 0.25) !important;
}

.tw-decoration-cyan-50\/30 {
  text-decoration-color: rgb(236 254 255 / 0.3) !important;
}

.tw-decoration-cyan-50\/35 {
  text-decoration-color: rgb(236 254 255 / 0.35) !important;
}

.tw-decoration-cyan-50\/40 {
  text-decoration-color: rgb(236 254 255 / 0.4) !important;
}

.tw-decoration-cyan-50\/45 {
  text-decoration-color: rgb(236 254 255 / 0.45) !important;
}

.tw-decoration-cyan-50\/5 {
  text-decoration-color: rgb(236 254 255 / 0.05) !important;
}

.tw-decoration-cyan-50\/50 {
  text-decoration-color: rgb(236 254 255 / 0.5) !important;
}

.tw-decoration-cyan-50\/55 {
  text-decoration-color: rgb(236 254 255 / 0.55) !important;
}

.tw-decoration-cyan-50\/60 {
  text-decoration-color: rgb(236 254 255 / 0.6) !important;
}

.tw-decoration-cyan-50\/65 {
  text-decoration-color: rgb(236 254 255 / 0.65) !important;
}

.tw-decoration-cyan-50\/70 {
  text-decoration-color: rgb(236 254 255 / 0.7) !important;
}

.tw-decoration-cyan-50\/75 {
  text-decoration-color: rgb(236 254 255 / 0.75) !important;
}

.tw-decoration-cyan-50\/80 {
  text-decoration-color: rgb(236 254 255 / 0.8) !important;
}

.tw-decoration-cyan-50\/85 {
  text-decoration-color: rgb(236 254 255 / 0.85) !important;
}

.tw-decoration-cyan-50\/90 {
  text-decoration-color: rgb(236 254 255 / 0.9) !important;
}

.tw-decoration-cyan-50\/95 {
  text-decoration-color: rgb(236 254 255 / 0.95) !important;
}

.tw-decoration-cyan-500 {
  text-decoration-color: #06b6d4 !important;
}

.tw-decoration-cyan-500\/0 {
  text-decoration-color: rgb(6 182 212 / 0) !important;
}

.tw-decoration-cyan-500\/10 {
  text-decoration-color: rgb(6 182 212 / 0.1) !important;
}

.tw-decoration-cyan-500\/100 {
  text-decoration-color: rgb(6 182 212 / 1) !important;
}

.tw-decoration-cyan-500\/15 {
  text-decoration-color: rgb(6 182 212 / 0.15) !important;
}

.tw-decoration-cyan-500\/20 {
  text-decoration-color: rgb(6 182 212 / 0.2) !important;
}

.tw-decoration-cyan-500\/25 {
  text-decoration-color: rgb(6 182 212 / 0.25) !important;
}

.tw-decoration-cyan-500\/30 {
  text-decoration-color: rgb(6 182 212 / 0.3) !important;
}

.tw-decoration-cyan-500\/35 {
  text-decoration-color: rgb(6 182 212 / 0.35) !important;
}

.tw-decoration-cyan-500\/40 {
  text-decoration-color: rgb(6 182 212 / 0.4) !important;
}

.tw-decoration-cyan-500\/45 {
  text-decoration-color: rgb(6 182 212 / 0.45) !important;
}

.tw-decoration-cyan-500\/5 {
  text-decoration-color: rgb(6 182 212 / 0.05) !important;
}

.tw-decoration-cyan-500\/50 {
  text-decoration-color: rgb(6 182 212 / 0.5) !important;
}

.tw-decoration-cyan-500\/55 {
  text-decoration-color: rgb(6 182 212 / 0.55) !important;
}

.tw-decoration-cyan-500\/60 {
  text-decoration-color: rgb(6 182 212 / 0.6) !important;
}

.tw-decoration-cyan-500\/65 {
  text-decoration-color: rgb(6 182 212 / 0.65) !important;
}

.tw-decoration-cyan-500\/70 {
  text-decoration-color: rgb(6 182 212 / 0.7) !important;
}

.tw-decoration-cyan-500\/75 {
  text-decoration-color: rgb(6 182 212 / 0.75) !important;
}

.tw-decoration-cyan-500\/80 {
  text-decoration-color: rgb(6 182 212 / 0.8) !important;
}

.tw-decoration-cyan-500\/85 {
  text-decoration-color: rgb(6 182 212 / 0.85) !important;
}

.tw-decoration-cyan-500\/90 {
  text-decoration-color: rgb(6 182 212 / 0.9) !important;
}

.tw-decoration-cyan-500\/95 {
  text-decoration-color: rgb(6 182 212 / 0.95) !important;
}

.tw-decoration-cyan-600 {
  text-decoration-color: #0891b2 !important;
}

.tw-decoration-cyan-600\/0 {
  text-decoration-color: rgb(8 145 178 / 0) !important;
}

.tw-decoration-cyan-600\/10 {
  text-decoration-color: rgb(8 145 178 / 0.1) !important;
}

.tw-decoration-cyan-600\/100 {
  text-decoration-color: rgb(8 145 178 / 1) !important;
}

.tw-decoration-cyan-600\/15 {
  text-decoration-color: rgb(8 145 178 / 0.15) !important;
}

.tw-decoration-cyan-600\/20 {
  text-decoration-color: rgb(8 145 178 / 0.2) !important;
}

.tw-decoration-cyan-600\/25 {
  text-decoration-color: rgb(8 145 178 / 0.25) !important;
}

.tw-decoration-cyan-600\/30 {
  text-decoration-color: rgb(8 145 178 / 0.3) !important;
}

.tw-decoration-cyan-600\/35 {
  text-decoration-color: rgb(8 145 178 / 0.35) !important;
}

.tw-decoration-cyan-600\/40 {
  text-decoration-color: rgb(8 145 178 / 0.4) !important;
}

.tw-decoration-cyan-600\/45 {
  text-decoration-color: rgb(8 145 178 / 0.45) !important;
}

.tw-decoration-cyan-600\/5 {
  text-decoration-color: rgb(8 145 178 / 0.05) !important;
}

.tw-decoration-cyan-600\/50 {
  text-decoration-color: rgb(8 145 178 / 0.5) !important;
}

.tw-decoration-cyan-600\/55 {
  text-decoration-color: rgb(8 145 178 / 0.55) !important;
}

.tw-decoration-cyan-600\/60 {
  text-decoration-color: rgb(8 145 178 / 0.6) !important;
}

.tw-decoration-cyan-600\/65 {
  text-decoration-color: rgb(8 145 178 / 0.65) !important;
}

.tw-decoration-cyan-600\/70 {
  text-decoration-color: rgb(8 145 178 / 0.7) !important;
}

.tw-decoration-cyan-600\/75 {
  text-decoration-color: rgb(8 145 178 / 0.75) !important;
}

.tw-decoration-cyan-600\/80 {
  text-decoration-color: rgb(8 145 178 / 0.8) !important;
}

.tw-decoration-cyan-600\/85 {
  text-decoration-color: rgb(8 145 178 / 0.85) !important;
}

.tw-decoration-cyan-600\/90 {
  text-decoration-color: rgb(8 145 178 / 0.9) !important;
}

.tw-decoration-cyan-600\/95 {
  text-decoration-color: rgb(8 145 178 / 0.95) !important;
}

.tw-decoration-cyan-700 {
  text-decoration-color: #0e7490 !important;
}

.tw-decoration-cyan-700\/0 {
  text-decoration-color: rgb(14 116 144 / 0) !important;
}

.tw-decoration-cyan-700\/10 {
  text-decoration-color: rgb(14 116 144 / 0.1) !important;
}

.tw-decoration-cyan-700\/100 {
  text-decoration-color: rgb(14 116 144 / 1) !important;
}

.tw-decoration-cyan-700\/15 {
  text-decoration-color: rgb(14 116 144 / 0.15) !important;
}

.tw-decoration-cyan-700\/20 {
  text-decoration-color: rgb(14 116 144 / 0.2) !important;
}

.tw-decoration-cyan-700\/25 {
  text-decoration-color: rgb(14 116 144 / 0.25) !important;
}

.tw-decoration-cyan-700\/30 {
  text-decoration-color: rgb(14 116 144 / 0.3) !important;
}

.tw-decoration-cyan-700\/35 {
  text-decoration-color: rgb(14 116 144 / 0.35) !important;
}

.tw-decoration-cyan-700\/40 {
  text-decoration-color: rgb(14 116 144 / 0.4) !important;
}

.tw-decoration-cyan-700\/45 {
  text-decoration-color: rgb(14 116 144 / 0.45) !important;
}

.tw-decoration-cyan-700\/5 {
  text-decoration-color: rgb(14 116 144 / 0.05) !important;
}

.tw-decoration-cyan-700\/50 {
  text-decoration-color: rgb(14 116 144 / 0.5) !important;
}

.tw-decoration-cyan-700\/55 {
  text-decoration-color: rgb(14 116 144 / 0.55) !important;
}

.tw-decoration-cyan-700\/60 {
  text-decoration-color: rgb(14 116 144 / 0.6) !important;
}

.tw-decoration-cyan-700\/65 {
  text-decoration-color: rgb(14 116 144 / 0.65) !important;
}

.tw-decoration-cyan-700\/70 {
  text-decoration-color: rgb(14 116 144 / 0.7) !important;
}

.tw-decoration-cyan-700\/75 {
  text-decoration-color: rgb(14 116 144 / 0.75) !important;
}

.tw-decoration-cyan-700\/80 {
  text-decoration-color: rgb(14 116 144 / 0.8) !important;
}

.tw-decoration-cyan-700\/85 {
  text-decoration-color: rgb(14 116 144 / 0.85) !important;
}

.tw-decoration-cyan-700\/90 {
  text-decoration-color: rgb(14 116 144 / 0.9) !important;
}

.tw-decoration-cyan-700\/95 {
  text-decoration-color: rgb(14 116 144 / 0.95) !important;
}

.tw-decoration-cyan-800 {
  text-decoration-color: #155e75 !important;
}

.tw-decoration-cyan-800\/0 {
  text-decoration-color: rgb(21 94 117 / 0) !important;
}

.tw-decoration-cyan-800\/10 {
  text-decoration-color: rgb(21 94 117 / 0.1) !important;
}

.tw-decoration-cyan-800\/100 {
  text-decoration-color: rgb(21 94 117 / 1) !important;
}

.tw-decoration-cyan-800\/15 {
  text-decoration-color: rgb(21 94 117 / 0.15) !important;
}

.tw-decoration-cyan-800\/20 {
  text-decoration-color: rgb(21 94 117 / 0.2) !important;
}

.tw-decoration-cyan-800\/25 {
  text-decoration-color: rgb(21 94 117 / 0.25) !important;
}

.tw-decoration-cyan-800\/30 {
  text-decoration-color: rgb(21 94 117 / 0.3) !important;
}

.tw-decoration-cyan-800\/35 {
  text-decoration-color: rgb(21 94 117 / 0.35) !important;
}

.tw-decoration-cyan-800\/40 {
  text-decoration-color: rgb(21 94 117 / 0.4) !important;
}

.tw-decoration-cyan-800\/45 {
  text-decoration-color: rgb(21 94 117 / 0.45) !important;
}

.tw-decoration-cyan-800\/5 {
  text-decoration-color: rgb(21 94 117 / 0.05) !important;
}

.tw-decoration-cyan-800\/50 {
  text-decoration-color: rgb(21 94 117 / 0.5) !important;
}

.tw-decoration-cyan-800\/55 {
  text-decoration-color: rgb(21 94 117 / 0.55) !important;
}

.tw-decoration-cyan-800\/60 {
  text-decoration-color: rgb(21 94 117 / 0.6) !important;
}

.tw-decoration-cyan-800\/65 {
  text-decoration-color: rgb(21 94 117 / 0.65) !important;
}

.tw-decoration-cyan-800\/70 {
  text-decoration-color: rgb(21 94 117 / 0.7) !important;
}

.tw-decoration-cyan-800\/75 {
  text-decoration-color: rgb(21 94 117 / 0.75) !important;
}

.tw-decoration-cyan-800\/80 {
  text-decoration-color: rgb(21 94 117 / 0.8) !important;
}

.tw-decoration-cyan-800\/85 {
  text-decoration-color: rgb(21 94 117 / 0.85) !important;
}

.tw-decoration-cyan-800\/90 {
  text-decoration-color: rgb(21 94 117 / 0.9) !important;
}

.tw-decoration-cyan-800\/95 {
  text-decoration-color: rgb(21 94 117 / 0.95) !important;
}

.tw-decoration-cyan-900 {
  text-decoration-color: #164e63 !important;
}

.tw-decoration-cyan-900\/0 {
  text-decoration-color: rgb(22 78 99 / 0) !important;
}

.tw-decoration-cyan-900\/10 {
  text-decoration-color: rgb(22 78 99 / 0.1) !important;
}

.tw-decoration-cyan-900\/100 {
  text-decoration-color: rgb(22 78 99 / 1) !important;
}

.tw-decoration-cyan-900\/15 {
  text-decoration-color: rgb(22 78 99 / 0.15) !important;
}

.tw-decoration-cyan-900\/20 {
  text-decoration-color: rgb(22 78 99 / 0.2) !important;
}

.tw-decoration-cyan-900\/25 {
  text-decoration-color: rgb(22 78 99 / 0.25) !important;
}

.tw-decoration-cyan-900\/30 {
  text-decoration-color: rgb(22 78 99 / 0.3) !important;
}

.tw-decoration-cyan-900\/35 {
  text-decoration-color: rgb(22 78 99 / 0.35) !important;
}

.tw-decoration-cyan-900\/40 {
  text-decoration-color: rgb(22 78 99 / 0.4) !important;
}

.tw-decoration-cyan-900\/45 {
  text-decoration-color: rgb(22 78 99 / 0.45) !important;
}

.tw-decoration-cyan-900\/5 {
  text-decoration-color: rgb(22 78 99 / 0.05) !important;
}

.tw-decoration-cyan-900\/50 {
  text-decoration-color: rgb(22 78 99 / 0.5) !important;
}

.tw-decoration-cyan-900\/55 {
  text-decoration-color: rgb(22 78 99 / 0.55) !important;
}

.tw-decoration-cyan-900\/60 {
  text-decoration-color: rgb(22 78 99 / 0.6) !important;
}

.tw-decoration-cyan-900\/65 {
  text-decoration-color: rgb(22 78 99 / 0.65) !important;
}

.tw-decoration-cyan-900\/70 {
  text-decoration-color: rgb(22 78 99 / 0.7) !important;
}

.tw-decoration-cyan-900\/75 {
  text-decoration-color: rgb(22 78 99 / 0.75) !important;
}

.tw-decoration-cyan-900\/80 {
  text-decoration-color: rgb(22 78 99 / 0.8) !important;
}

.tw-decoration-cyan-900\/85 {
  text-decoration-color: rgb(22 78 99 / 0.85) !important;
}

.tw-decoration-cyan-900\/90 {
  text-decoration-color: rgb(22 78 99 / 0.9) !important;
}

.tw-decoration-cyan-900\/95 {
  text-decoration-color: rgb(22 78 99 / 0.95) !important;
}

.tw-decoration-cyan-950 {
  text-decoration-color: #083344 !important;
}

.tw-decoration-cyan-950\/0 {
  text-decoration-color: rgb(8 51 68 / 0) !important;
}

.tw-decoration-cyan-950\/10 {
  text-decoration-color: rgb(8 51 68 / 0.1) !important;
}

.tw-decoration-cyan-950\/100 {
  text-decoration-color: rgb(8 51 68 / 1) !important;
}

.tw-decoration-cyan-950\/15 {
  text-decoration-color: rgb(8 51 68 / 0.15) !important;
}

.tw-decoration-cyan-950\/20 {
  text-decoration-color: rgb(8 51 68 / 0.2) !important;
}

.tw-decoration-cyan-950\/25 {
  text-decoration-color: rgb(8 51 68 / 0.25) !important;
}

.tw-decoration-cyan-950\/30 {
  text-decoration-color: rgb(8 51 68 / 0.3) !important;
}

.tw-decoration-cyan-950\/35 {
  text-decoration-color: rgb(8 51 68 / 0.35) !important;
}

.tw-decoration-cyan-950\/40 {
  text-decoration-color: rgb(8 51 68 / 0.4) !important;
}

.tw-decoration-cyan-950\/45 {
  text-decoration-color: rgb(8 51 68 / 0.45) !important;
}

.tw-decoration-cyan-950\/5 {
  text-decoration-color: rgb(8 51 68 / 0.05) !important;
}

.tw-decoration-cyan-950\/50 {
  text-decoration-color: rgb(8 51 68 / 0.5) !important;
}

.tw-decoration-cyan-950\/55 {
  text-decoration-color: rgb(8 51 68 / 0.55) !important;
}

.tw-decoration-cyan-950\/60 {
  text-decoration-color: rgb(8 51 68 / 0.6) !important;
}

.tw-decoration-cyan-950\/65 {
  text-decoration-color: rgb(8 51 68 / 0.65) !important;
}

.tw-decoration-cyan-950\/70 {
  text-decoration-color: rgb(8 51 68 / 0.7) !important;
}

.tw-decoration-cyan-950\/75 {
  text-decoration-color: rgb(8 51 68 / 0.75) !important;
}

.tw-decoration-cyan-950\/80 {
  text-decoration-color: rgb(8 51 68 / 0.8) !important;
}

.tw-decoration-cyan-950\/85 {
  text-decoration-color: rgb(8 51 68 / 0.85) !important;
}

.tw-decoration-cyan-950\/90 {
  text-decoration-color: rgb(8 51 68 / 0.9) !important;
}

.tw-decoration-cyan-950\/95 {
  text-decoration-color: rgb(8 51 68 / 0.95) !important;
}

.tw-decoration-emerald-100 {
  text-decoration-color: #d1fae5 !important;
}

.tw-decoration-emerald-100\/0 {
  text-decoration-color: rgb(209 250 229 / 0) !important;
}

.tw-decoration-emerald-100\/10 {
  text-decoration-color: rgb(209 250 229 / 0.1) !important;
}

.tw-decoration-emerald-100\/100 {
  text-decoration-color: rgb(209 250 229 / 1) !important;
}

.tw-decoration-emerald-100\/15 {
  text-decoration-color: rgb(209 250 229 / 0.15) !important;
}

.tw-decoration-emerald-100\/20 {
  text-decoration-color: rgb(209 250 229 / 0.2) !important;
}

.tw-decoration-emerald-100\/25 {
  text-decoration-color: rgb(209 250 229 / 0.25) !important;
}

.tw-decoration-emerald-100\/30 {
  text-decoration-color: rgb(209 250 229 / 0.3) !important;
}

.tw-decoration-emerald-100\/35 {
  text-decoration-color: rgb(209 250 229 / 0.35) !important;
}

.tw-decoration-emerald-100\/40 {
  text-decoration-color: rgb(209 250 229 / 0.4) !important;
}

.tw-decoration-emerald-100\/45 {
  text-decoration-color: rgb(209 250 229 / 0.45) !important;
}

.tw-decoration-emerald-100\/5 {
  text-decoration-color: rgb(209 250 229 / 0.05) !important;
}

.tw-decoration-emerald-100\/50 {
  text-decoration-color: rgb(209 250 229 / 0.5) !important;
}

.tw-decoration-emerald-100\/55 {
  text-decoration-color: rgb(209 250 229 / 0.55) !important;
}

.tw-decoration-emerald-100\/60 {
  text-decoration-color: rgb(209 250 229 / 0.6) !important;
}

.tw-decoration-emerald-100\/65 {
  text-decoration-color: rgb(209 250 229 / 0.65) !important;
}

.tw-decoration-emerald-100\/70 {
  text-decoration-color: rgb(209 250 229 / 0.7) !important;
}

.tw-decoration-emerald-100\/75 {
  text-decoration-color: rgb(209 250 229 / 0.75) !important;
}

.tw-decoration-emerald-100\/80 {
  text-decoration-color: rgb(209 250 229 / 0.8) !important;
}

.tw-decoration-emerald-100\/85 {
  text-decoration-color: rgb(209 250 229 / 0.85) !important;
}

.tw-decoration-emerald-100\/90 {
  text-decoration-color: rgb(209 250 229 / 0.9) !important;
}

.tw-decoration-emerald-100\/95 {
  text-decoration-color: rgb(209 250 229 / 0.95) !important;
}

.tw-decoration-emerald-200 {
  text-decoration-color: #a7f3d0 !important;
}

.tw-decoration-emerald-200\/0 {
  text-decoration-color: rgb(167 243 208 / 0) !important;
}

.tw-decoration-emerald-200\/10 {
  text-decoration-color: rgb(167 243 208 / 0.1) !important;
}

.tw-decoration-emerald-200\/100 {
  text-decoration-color: rgb(167 243 208 / 1) !important;
}

.tw-decoration-emerald-200\/15 {
  text-decoration-color: rgb(167 243 208 / 0.15) !important;
}

.tw-decoration-emerald-200\/20 {
  text-decoration-color: rgb(167 243 208 / 0.2) !important;
}

.tw-decoration-emerald-200\/25 {
  text-decoration-color: rgb(167 243 208 / 0.25) !important;
}

.tw-decoration-emerald-200\/30 {
  text-decoration-color: rgb(167 243 208 / 0.3) !important;
}

.tw-decoration-emerald-200\/35 {
  text-decoration-color: rgb(167 243 208 / 0.35) !important;
}

.tw-decoration-emerald-200\/40 {
  text-decoration-color: rgb(167 243 208 / 0.4) !important;
}

.tw-decoration-emerald-200\/45 {
  text-decoration-color: rgb(167 243 208 / 0.45) !important;
}

.tw-decoration-emerald-200\/5 {
  text-decoration-color: rgb(167 243 208 / 0.05) !important;
}

.tw-decoration-emerald-200\/50 {
  text-decoration-color: rgb(167 243 208 / 0.5) !important;
}

.tw-decoration-emerald-200\/55 {
  text-decoration-color: rgb(167 243 208 / 0.55) !important;
}

.tw-decoration-emerald-200\/60 {
  text-decoration-color: rgb(167 243 208 / 0.6) !important;
}

.tw-decoration-emerald-200\/65 {
  text-decoration-color: rgb(167 243 208 / 0.65) !important;
}

.tw-decoration-emerald-200\/70 {
  text-decoration-color: rgb(167 243 208 / 0.7) !important;
}

.tw-decoration-emerald-200\/75 {
  text-decoration-color: rgb(167 243 208 / 0.75) !important;
}

.tw-decoration-emerald-200\/80 {
  text-decoration-color: rgb(167 243 208 / 0.8) !important;
}

.tw-decoration-emerald-200\/85 {
  text-decoration-color: rgb(167 243 208 / 0.85) !important;
}

.tw-decoration-emerald-200\/90 {
  text-decoration-color: rgb(167 243 208 / 0.9) !important;
}

.tw-decoration-emerald-200\/95 {
  text-decoration-color: rgb(167 243 208 / 0.95) !important;
}

.tw-decoration-emerald-300 {
  text-decoration-color: #6ee7b7 !important;
}

.tw-decoration-emerald-300\/0 {
  text-decoration-color: rgb(110 231 183 / 0) !important;
}

.tw-decoration-emerald-300\/10 {
  text-decoration-color: rgb(110 231 183 / 0.1) !important;
}

.tw-decoration-emerald-300\/100 {
  text-decoration-color: rgb(110 231 183 / 1) !important;
}

.tw-decoration-emerald-300\/15 {
  text-decoration-color: rgb(110 231 183 / 0.15) !important;
}

.tw-decoration-emerald-300\/20 {
  text-decoration-color: rgb(110 231 183 / 0.2) !important;
}

.tw-decoration-emerald-300\/25 {
  text-decoration-color: rgb(110 231 183 / 0.25) !important;
}

.tw-decoration-emerald-300\/30 {
  text-decoration-color: rgb(110 231 183 / 0.3) !important;
}

.tw-decoration-emerald-300\/35 {
  text-decoration-color: rgb(110 231 183 / 0.35) !important;
}

.tw-decoration-emerald-300\/40 {
  text-decoration-color: rgb(110 231 183 / 0.4) !important;
}

.tw-decoration-emerald-300\/45 {
  text-decoration-color: rgb(110 231 183 / 0.45) !important;
}

.tw-decoration-emerald-300\/5 {
  text-decoration-color: rgb(110 231 183 / 0.05) !important;
}

.tw-decoration-emerald-300\/50 {
  text-decoration-color: rgb(110 231 183 / 0.5) !important;
}

.tw-decoration-emerald-300\/55 {
  text-decoration-color: rgb(110 231 183 / 0.55) !important;
}

.tw-decoration-emerald-300\/60 {
  text-decoration-color: rgb(110 231 183 / 0.6) !important;
}

.tw-decoration-emerald-300\/65 {
  text-decoration-color: rgb(110 231 183 / 0.65) !important;
}

.tw-decoration-emerald-300\/70 {
  text-decoration-color: rgb(110 231 183 / 0.7) !important;
}

.tw-decoration-emerald-300\/75 {
  text-decoration-color: rgb(110 231 183 / 0.75) !important;
}

.tw-decoration-emerald-300\/80 {
  text-decoration-color: rgb(110 231 183 / 0.8) !important;
}

.tw-decoration-emerald-300\/85 {
  text-decoration-color: rgb(110 231 183 / 0.85) !important;
}

.tw-decoration-emerald-300\/90 {
  text-decoration-color: rgb(110 231 183 / 0.9) !important;
}

.tw-decoration-emerald-300\/95 {
  text-decoration-color: rgb(110 231 183 / 0.95) !important;
}

.tw-decoration-emerald-400 {
  text-decoration-color: #34d399 !important;
}

.tw-decoration-emerald-400\/0 {
  text-decoration-color: rgb(52 211 153 / 0) !important;
}

.tw-decoration-emerald-400\/10 {
  text-decoration-color: rgb(52 211 153 / 0.1) !important;
}

.tw-decoration-emerald-400\/100 {
  text-decoration-color: rgb(52 211 153 / 1) !important;
}

.tw-decoration-emerald-400\/15 {
  text-decoration-color: rgb(52 211 153 / 0.15) !important;
}

.tw-decoration-emerald-400\/20 {
  text-decoration-color: rgb(52 211 153 / 0.2) !important;
}

.tw-decoration-emerald-400\/25 {
  text-decoration-color: rgb(52 211 153 / 0.25) !important;
}

.tw-decoration-emerald-400\/30 {
  text-decoration-color: rgb(52 211 153 / 0.3) !important;
}

.tw-decoration-emerald-400\/35 {
  text-decoration-color: rgb(52 211 153 / 0.35) !important;
}

.tw-decoration-emerald-400\/40 {
  text-decoration-color: rgb(52 211 153 / 0.4) !important;
}

.tw-decoration-emerald-400\/45 {
  text-decoration-color: rgb(52 211 153 / 0.45) !important;
}

.tw-decoration-emerald-400\/5 {
  text-decoration-color: rgb(52 211 153 / 0.05) !important;
}

.tw-decoration-emerald-400\/50 {
  text-decoration-color: rgb(52 211 153 / 0.5) !important;
}

.tw-decoration-emerald-400\/55 {
  text-decoration-color: rgb(52 211 153 / 0.55) !important;
}

.tw-decoration-emerald-400\/60 {
  text-decoration-color: rgb(52 211 153 / 0.6) !important;
}

.tw-decoration-emerald-400\/65 {
  text-decoration-color: rgb(52 211 153 / 0.65) !important;
}

.tw-decoration-emerald-400\/70 {
  text-decoration-color: rgb(52 211 153 / 0.7) !important;
}

.tw-decoration-emerald-400\/75 {
  text-decoration-color: rgb(52 211 153 / 0.75) !important;
}

.tw-decoration-emerald-400\/80 {
  text-decoration-color: rgb(52 211 153 / 0.8) !important;
}

.tw-decoration-emerald-400\/85 {
  text-decoration-color: rgb(52 211 153 / 0.85) !important;
}

.tw-decoration-emerald-400\/90 {
  text-decoration-color: rgb(52 211 153 / 0.9) !important;
}

.tw-decoration-emerald-400\/95 {
  text-decoration-color: rgb(52 211 153 / 0.95) !important;
}

.tw-decoration-emerald-50 {
  text-decoration-color: #ecfdf5 !important;
}

.tw-decoration-emerald-50\/0 {
  text-decoration-color: rgb(236 253 245 / 0) !important;
}

.tw-decoration-emerald-50\/10 {
  text-decoration-color: rgb(236 253 245 / 0.1) !important;
}

.tw-decoration-emerald-50\/100 {
  text-decoration-color: rgb(236 253 245 / 1) !important;
}

.tw-decoration-emerald-50\/15 {
  text-decoration-color: rgb(236 253 245 / 0.15) !important;
}

.tw-decoration-emerald-50\/20 {
  text-decoration-color: rgb(236 253 245 / 0.2) !important;
}

.tw-decoration-emerald-50\/25 {
  text-decoration-color: rgb(236 253 245 / 0.25) !important;
}

.tw-decoration-emerald-50\/30 {
  text-decoration-color: rgb(236 253 245 / 0.3) !important;
}

.tw-decoration-emerald-50\/35 {
  text-decoration-color: rgb(236 253 245 / 0.35) !important;
}

.tw-decoration-emerald-50\/40 {
  text-decoration-color: rgb(236 253 245 / 0.4) !important;
}

.tw-decoration-emerald-50\/45 {
  text-decoration-color: rgb(236 253 245 / 0.45) !important;
}

.tw-decoration-emerald-50\/5 {
  text-decoration-color: rgb(236 253 245 / 0.05) !important;
}

.tw-decoration-emerald-50\/50 {
  text-decoration-color: rgb(236 253 245 / 0.5) !important;
}

.tw-decoration-emerald-50\/55 {
  text-decoration-color: rgb(236 253 245 / 0.55) !important;
}

.tw-decoration-emerald-50\/60 {
  text-decoration-color: rgb(236 253 245 / 0.6) !important;
}

.tw-decoration-emerald-50\/65 {
  text-decoration-color: rgb(236 253 245 / 0.65) !important;
}

.tw-decoration-emerald-50\/70 {
  text-decoration-color: rgb(236 253 245 / 0.7) !important;
}

.tw-decoration-emerald-50\/75 {
  text-decoration-color: rgb(236 253 245 / 0.75) !important;
}

.tw-decoration-emerald-50\/80 {
  text-decoration-color: rgb(236 253 245 / 0.8) !important;
}

.tw-decoration-emerald-50\/85 {
  text-decoration-color: rgb(236 253 245 / 0.85) !important;
}

.tw-decoration-emerald-50\/90 {
  text-decoration-color: rgb(236 253 245 / 0.9) !important;
}

.tw-decoration-emerald-50\/95 {
  text-decoration-color: rgb(236 253 245 / 0.95) !important;
}

.tw-decoration-emerald-500 {
  text-decoration-color: #10b981 !important;
}

.tw-decoration-emerald-500\/0 {
  text-decoration-color: rgb(16 185 129 / 0) !important;
}

.tw-decoration-emerald-500\/10 {
  text-decoration-color: rgb(16 185 129 / 0.1) !important;
}

.tw-decoration-emerald-500\/100 {
  text-decoration-color: rgb(16 185 129 / 1) !important;
}

.tw-decoration-emerald-500\/15 {
  text-decoration-color: rgb(16 185 129 / 0.15) !important;
}

.tw-decoration-emerald-500\/20 {
  text-decoration-color: rgb(16 185 129 / 0.2) !important;
}

.tw-decoration-emerald-500\/25 {
  text-decoration-color: rgb(16 185 129 / 0.25) !important;
}

.tw-decoration-emerald-500\/30 {
  text-decoration-color: rgb(16 185 129 / 0.3) !important;
}

.tw-decoration-emerald-500\/35 {
  text-decoration-color: rgb(16 185 129 / 0.35) !important;
}

.tw-decoration-emerald-500\/40 {
  text-decoration-color: rgb(16 185 129 / 0.4) !important;
}

.tw-decoration-emerald-500\/45 {
  text-decoration-color: rgb(16 185 129 / 0.45) !important;
}

.tw-decoration-emerald-500\/5 {
  text-decoration-color: rgb(16 185 129 / 0.05) !important;
}

.tw-decoration-emerald-500\/50 {
  text-decoration-color: rgb(16 185 129 / 0.5) !important;
}

.tw-decoration-emerald-500\/55 {
  text-decoration-color: rgb(16 185 129 / 0.55) !important;
}

.tw-decoration-emerald-500\/60 {
  text-decoration-color: rgb(16 185 129 / 0.6) !important;
}

.tw-decoration-emerald-500\/65 {
  text-decoration-color: rgb(16 185 129 / 0.65) !important;
}

.tw-decoration-emerald-500\/70 {
  text-decoration-color: rgb(16 185 129 / 0.7) !important;
}

.tw-decoration-emerald-500\/75 {
  text-decoration-color: rgb(16 185 129 / 0.75) !important;
}

.tw-decoration-emerald-500\/80 {
  text-decoration-color: rgb(16 185 129 / 0.8) !important;
}

.tw-decoration-emerald-500\/85 {
  text-decoration-color: rgb(16 185 129 / 0.85) !important;
}

.tw-decoration-emerald-500\/90 {
  text-decoration-color: rgb(16 185 129 / 0.9) !important;
}

.tw-decoration-emerald-500\/95 {
  text-decoration-color: rgb(16 185 129 / 0.95) !important;
}

.tw-decoration-emerald-600 {
  text-decoration-color: #059669 !important;
}

.tw-decoration-emerald-600\/0 {
  text-decoration-color: rgb(5 150 105 / 0) !important;
}

.tw-decoration-emerald-600\/10 {
  text-decoration-color: rgb(5 150 105 / 0.1) !important;
}

.tw-decoration-emerald-600\/100 {
  text-decoration-color: rgb(5 150 105 / 1) !important;
}

.tw-decoration-emerald-600\/15 {
  text-decoration-color: rgb(5 150 105 / 0.15) !important;
}

.tw-decoration-emerald-600\/20 {
  text-decoration-color: rgb(5 150 105 / 0.2) !important;
}

.tw-decoration-emerald-600\/25 {
  text-decoration-color: rgb(5 150 105 / 0.25) !important;
}

.tw-decoration-emerald-600\/30 {
  text-decoration-color: rgb(5 150 105 / 0.3) !important;
}

.tw-decoration-emerald-600\/35 {
  text-decoration-color: rgb(5 150 105 / 0.35) !important;
}

.tw-decoration-emerald-600\/40 {
  text-decoration-color: rgb(5 150 105 / 0.4) !important;
}

.tw-decoration-emerald-600\/45 {
  text-decoration-color: rgb(5 150 105 / 0.45) !important;
}

.tw-decoration-emerald-600\/5 {
  text-decoration-color: rgb(5 150 105 / 0.05) !important;
}

.tw-decoration-emerald-600\/50 {
  text-decoration-color: rgb(5 150 105 / 0.5) !important;
}

.tw-decoration-emerald-600\/55 {
  text-decoration-color: rgb(5 150 105 / 0.55) !important;
}

.tw-decoration-emerald-600\/60 {
  text-decoration-color: rgb(5 150 105 / 0.6) !important;
}

.tw-decoration-emerald-600\/65 {
  text-decoration-color: rgb(5 150 105 / 0.65) !important;
}

.tw-decoration-emerald-600\/70 {
  text-decoration-color: rgb(5 150 105 / 0.7) !important;
}

.tw-decoration-emerald-600\/75 {
  text-decoration-color: rgb(5 150 105 / 0.75) !important;
}

.tw-decoration-emerald-600\/80 {
  text-decoration-color: rgb(5 150 105 / 0.8) !important;
}

.tw-decoration-emerald-600\/85 {
  text-decoration-color: rgb(5 150 105 / 0.85) !important;
}

.tw-decoration-emerald-600\/90 {
  text-decoration-color: rgb(5 150 105 / 0.9) !important;
}

.tw-decoration-emerald-600\/95 {
  text-decoration-color: rgb(5 150 105 / 0.95) !important;
}

.tw-decoration-emerald-700 {
  text-decoration-color: #047857 !important;
}

.tw-decoration-emerald-700\/0 {
  text-decoration-color: rgb(4 120 87 / 0) !important;
}

.tw-decoration-emerald-700\/10 {
  text-decoration-color: rgb(4 120 87 / 0.1) !important;
}

.tw-decoration-emerald-700\/100 {
  text-decoration-color: rgb(4 120 87 / 1) !important;
}

.tw-decoration-emerald-700\/15 {
  text-decoration-color: rgb(4 120 87 / 0.15) !important;
}

.tw-decoration-emerald-700\/20 {
  text-decoration-color: rgb(4 120 87 / 0.2) !important;
}

.tw-decoration-emerald-700\/25 {
  text-decoration-color: rgb(4 120 87 / 0.25) !important;
}

.tw-decoration-emerald-700\/30 {
  text-decoration-color: rgb(4 120 87 / 0.3) !important;
}

.tw-decoration-emerald-700\/35 {
  text-decoration-color: rgb(4 120 87 / 0.35) !important;
}

.tw-decoration-emerald-700\/40 {
  text-decoration-color: rgb(4 120 87 / 0.4) !important;
}

.tw-decoration-emerald-700\/45 {
  text-decoration-color: rgb(4 120 87 / 0.45) !important;
}

.tw-decoration-emerald-700\/5 {
  text-decoration-color: rgb(4 120 87 / 0.05) !important;
}

.tw-decoration-emerald-700\/50 {
  text-decoration-color: rgb(4 120 87 / 0.5) !important;
}

.tw-decoration-emerald-700\/55 {
  text-decoration-color: rgb(4 120 87 / 0.55) !important;
}

.tw-decoration-emerald-700\/60 {
  text-decoration-color: rgb(4 120 87 / 0.6) !important;
}

.tw-decoration-emerald-700\/65 {
  text-decoration-color: rgb(4 120 87 / 0.65) !important;
}

.tw-decoration-emerald-700\/70 {
  text-decoration-color: rgb(4 120 87 / 0.7) !important;
}

.tw-decoration-emerald-700\/75 {
  text-decoration-color: rgb(4 120 87 / 0.75) !important;
}

.tw-decoration-emerald-700\/80 {
  text-decoration-color: rgb(4 120 87 / 0.8) !important;
}

.tw-decoration-emerald-700\/85 {
  text-decoration-color: rgb(4 120 87 / 0.85) !important;
}

.tw-decoration-emerald-700\/90 {
  text-decoration-color: rgb(4 120 87 / 0.9) !important;
}

.tw-decoration-emerald-700\/95 {
  text-decoration-color: rgb(4 120 87 / 0.95) !important;
}

.tw-decoration-emerald-800 {
  text-decoration-color: #065f46 !important;
}

.tw-decoration-emerald-800\/0 {
  text-decoration-color: rgb(6 95 70 / 0) !important;
}

.tw-decoration-emerald-800\/10 {
  text-decoration-color: rgb(6 95 70 / 0.1) !important;
}

.tw-decoration-emerald-800\/100 {
  text-decoration-color: rgb(6 95 70 / 1) !important;
}

.tw-decoration-emerald-800\/15 {
  text-decoration-color: rgb(6 95 70 / 0.15) !important;
}

.tw-decoration-emerald-800\/20 {
  text-decoration-color: rgb(6 95 70 / 0.2) !important;
}

.tw-decoration-emerald-800\/25 {
  text-decoration-color: rgb(6 95 70 / 0.25) !important;
}

.tw-decoration-emerald-800\/30 {
  text-decoration-color: rgb(6 95 70 / 0.3) !important;
}

.tw-decoration-emerald-800\/35 {
  text-decoration-color: rgb(6 95 70 / 0.35) !important;
}

.tw-decoration-emerald-800\/40 {
  text-decoration-color: rgb(6 95 70 / 0.4) !important;
}

.tw-decoration-emerald-800\/45 {
  text-decoration-color: rgb(6 95 70 / 0.45) !important;
}

.tw-decoration-emerald-800\/5 {
  text-decoration-color: rgb(6 95 70 / 0.05) !important;
}

.tw-decoration-emerald-800\/50 {
  text-decoration-color: rgb(6 95 70 / 0.5) !important;
}

.tw-decoration-emerald-800\/55 {
  text-decoration-color: rgb(6 95 70 / 0.55) !important;
}

.tw-decoration-emerald-800\/60 {
  text-decoration-color: rgb(6 95 70 / 0.6) !important;
}

.tw-decoration-emerald-800\/65 {
  text-decoration-color: rgb(6 95 70 / 0.65) !important;
}

.tw-decoration-emerald-800\/70 {
  text-decoration-color: rgb(6 95 70 / 0.7) !important;
}

.tw-decoration-emerald-800\/75 {
  text-decoration-color: rgb(6 95 70 / 0.75) !important;
}

.tw-decoration-emerald-800\/80 {
  text-decoration-color: rgb(6 95 70 / 0.8) !important;
}

.tw-decoration-emerald-800\/85 {
  text-decoration-color: rgb(6 95 70 / 0.85) !important;
}

.tw-decoration-emerald-800\/90 {
  text-decoration-color: rgb(6 95 70 / 0.9) !important;
}

.tw-decoration-emerald-800\/95 {
  text-decoration-color: rgb(6 95 70 / 0.95) !important;
}

.tw-decoration-emerald-900 {
  text-decoration-color: #064e3b !important;
}

.tw-decoration-emerald-900\/0 {
  text-decoration-color: rgb(6 78 59 / 0) !important;
}

.tw-decoration-emerald-900\/10 {
  text-decoration-color: rgb(6 78 59 / 0.1) !important;
}

.tw-decoration-emerald-900\/100 {
  text-decoration-color: rgb(6 78 59 / 1) !important;
}

.tw-decoration-emerald-900\/15 {
  text-decoration-color: rgb(6 78 59 / 0.15) !important;
}

.tw-decoration-emerald-900\/20 {
  text-decoration-color: rgb(6 78 59 / 0.2) !important;
}

.tw-decoration-emerald-900\/25 {
  text-decoration-color: rgb(6 78 59 / 0.25) !important;
}

.tw-decoration-emerald-900\/30 {
  text-decoration-color: rgb(6 78 59 / 0.3) !important;
}

.tw-decoration-emerald-900\/35 {
  text-decoration-color: rgb(6 78 59 / 0.35) !important;
}

.tw-decoration-emerald-900\/40 {
  text-decoration-color: rgb(6 78 59 / 0.4) !important;
}

.tw-decoration-emerald-900\/45 {
  text-decoration-color: rgb(6 78 59 / 0.45) !important;
}

.tw-decoration-emerald-900\/5 {
  text-decoration-color: rgb(6 78 59 / 0.05) !important;
}

.tw-decoration-emerald-900\/50 {
  text-decoration-color: rgb(6 78 59 / 0.5) !important;
}

.tw-decoration-emerald-900\/55 {
  text-decoration-color: rgb(6 78 59 / 0.55) !important;
}

.tw-decoration-emerald-900\/60 {
  text-decoration-color: rgb(6 78 59 / 0.6) !important;
}

.tw-decoration-emerald-900\/65 {
  text-decoration-color: rgb(6 78 59 / 0.65) !important;
}

.tw-decoration-emerald-900\/70 {
  text-decoration-color: rgb(6 78 59 / 0.7) !important;
}

.tw-decoration-emerald-900\/75 {
  text-decoration-color: rgb(6 78 59 / 0.75) !important;
}

.tw-decoration-emerald-900\/80 {
  text-decoration-color: rgb(6 78 59 / 0.8) !important;
}

.tw-decoration-emerald-900\/85 {
  text-decoration-color: rgb(6 78 59 / 0.85) !important;
}

.tw-decoration-emerald-900\/90 {
  text-decoration-color: rgb(6 78 59 / 0.9) !important;
}

.tw-decoration-emerald-900\/95 {
  text-decoration-color: rgb(6 78 59 / 0.95) !important;
}

.tw-decoration-emerald-950 {
  text-decoration-color: #022c22 !important;
}

.tw-decoration-emerald-950\/0 {
  text-decoration-color: rgb(2 44 34 / 0) !important;
}

.tw-decoration-emerald-950\/10 {
  text-decoration-color: rgb(2 44 34 / 0.1) !important;
}

.tw-decoration-emerald-950\/100 {
  text-decoration-color: rgb(2 44 34 / 1) !important;
}

.tw-decoration-emerald-950\/15 {
  text-decoration-color: rgb(2 44 34 / 0.15) !important;
}

.tw-decoration-emerald-950\/20 {
  text-decoration-color: rgb(2 44 34 / 0.2) !important;
}

.tw-decoration-emerald-950\/25 {
  text-decoration-color: rgb(2 44 34 / 0.25) !important;
}

.tw-decoration-emerald-950\/30 {
  text-decoration-color: rgb(2 44 34 / 0.3) !important;
}

.tw-decoration-emerald-950\/35 {
  text-decoration-color: rgb(2 44 34 / 0.35) !important;
}

.tw-decoration-emerald-950\/40 {
  text-decoration-color: rgb(2 44 34 / 0.4) !important;
}

.tw-decoration-emerald-950\/45 {
  text-decoration-color: rgb(2 44 34 / 0.45) !important;
}

.tw-decoration-emerald-950\/5 {
  text-decoration-color: rgb(2 44 34 / 0.05) !important;
}

.tw-decoration-emerald-950\/50 {
  text-decoration-color: rgb(2 44 34 / 0.5) !important;
}

.tw-decoration-emerald-950\/55 {
  text-decoration-color: rgb(2 44 34 / 0.55) !important;
}

.tw-decoration-emerald-950\/60 {
  text-decoration-color: rgb(2 44 34 / 0.6) !important;
}

.tw-decoration-emerald-950\/65 {
  text-decoration-color: rgb(2 44 34 / 0.65) !important;
}

.tw-decoration-emerald-950\/70 {
  text-decoration-color: rgb(2 44 34 / 0.7) !important;
}

.tw-decoration-emerald-950\/75 {
  text-decoration-color: rgb(2 44 34 / 0.75) !important;
}

.tw-decoration-emerald-950\/80 {
  text-decoration-color: rgb(2 44 34 / 0.8) !important;
}

.tw-decoration-emerald-950\/85 {
  text-decoration-color: rgb(2 44 34 / 0.85) !important;
}

.tw-decoration-emerald-950\/90 {
  text-decoration-color: rgb(2 44 34 / 0.9) !important;
}

.tw-decoration-emerald-950\/95 {
  text-decoration-color: rgb(2 44 34 / 0.95) !important;
}

.tw-decoration-fuchsia-100 {
  text-decoration-color: #fae8ff !important;
}

.tw-decoration-fuchsia-100\/0 {
  text-decoration-color: rgb(250 232 255 / 0) !important;
}

.tw-decoration-fuchsia-100\/10 {
  text-decoration-color: rgb(250 232 255 / 0.1) !important;
}

.tw-decoration-fuchsia-100\/100 {
  text-decoration-color: rgb(250 232 255 / 1) !important;
}

.tw-decoration-fuchsia-100\/15 {
  text-decoration-color: rgb(250 232 255 / 0.15) !important;
}

.tw-decoration-fuchsia-100\/20 {
  text-decoration-color: rgb(250 232 255 / 0.2) !important;
}

.tw-decoration-fuchsia-100\/25 {
  text-decoration-color: rgb(250 232 255 / 0.25) !important;
}

.tw-decoration-fuchsia-100\/30 {
  text-decoration-color: rgb(250 232 255 / 0.3) !important;
}

.tw-decoration-fuchsia-100\/35 {
  text-decoration-color: rgb(250 232 255 / 0.35) !important;
}

.tw-decoration-fuchsia-100\/40 {
  text-decoration-color: rgb(250 232 255 / 0.4) !important;
}

.tw-decoration-fuchsia-100\/45 {
  text-decoration-color: rgb(250 232 255 / 0.45) !important;
}

.tw-decoration-fuchsia-100\/5 {
  text-decoration-color: rgb(250 232 255 / 0.05) !important;
}

.tw-decoration-fuchsia-100\/50 {
  text-decoration-color: rgb(250 232 255 / 0.5) !important;
}

.tw-decoration-fuchsia-100\/55 {
  text-decoration-color: rgb(250 232 255 / 0.55) !important;
}

.tw-decoration-fuchsia-100\/60 {
  text-decoration-color: rgb(250 232 255 / 0.6) !important;
}

.tw-decoration-fuchsia-100\/65 {
  text-decoration-color: rgb(250 232 255 / 0.65) !important;
}

.tw-decoration-fuchsia-100\/70 {
  text-decoration-color: rgb(250 232 255 / 0.7) !important;
}

.tw-decoration-fuchsia-100\/75 {
  text-decoration-color: rgb(250 232 255 / 0.75) !important;
}

.tw-decoration-fuchsia-100\/80 {
  text-decoration-color: rgb(250 232 255 / 0.8) !important;
}

.tw-decoration-fuchsia-100\/85 {
  text-decoration-color: rgb(250 232 255 / 0.85) !important;
}

.tw-decoration-fuchsia-100\/90 {
  text-decoration-color: rgb(250 232 255 / 0.9) !important;
}

.tw-decoration-fuchsia-100\/95 {
  text-decoration-color: rgb(250 232 255 / 0.95) !important;
}

.tw-decoration-fuchsia-200 {
  text-decoration-color: #f5d0fe !important;
}

.tw-decoration-fuchsia-200\/0 {
  text-decoration-color: rgb(245 208 254 / 0) !important;
}

.tw-decoration-fuchsia-200\/10 {
  text-decoration-color: rgb(245 208 254 / 0.1) !important;
}

.tw-decoration-fuchsia-200\/100 {
  text-decoration-color: rgb(245 208 254 / 1) !important;
}

.tw-decoration-fuchsia-200\/15 {
  text-decoration-color: rgb(245 208 254 / 0.15) !important;
}

.tw-decoration-fuchsia-200\/20 {
  text-decoration-color: rgb(245 208 254 / 0.2) !important;
}

.tw-decoration-fuchsia-200\/25 {
  text-decoration-color: rgb(245 208 254 / 0.25) !important;
}

.tw-decoration-fuchsia-200\/30 {
  text-decoration-color: rgb(245 208 254 / 0.3) !important;
}

.tw-decoration-fuchsia-200\/35 {
  text-decoration-color: rgb(245 208 254 / 0.35) !important;
}

.tw-decoration-fuchsia-200\/40 {
  text-decoration-color: rgb(245 208 254 / 0.4) !important;
}

.tw-decoration-fuchsia-200\/45 {
  text-decoration-color: rgb(245 208 254 / 0.45) !important;
}

.tw-decoration-fuchsia-200\/5 {
  text-decoration-color: rgb(245 208 254 / 0.05) !important;
}

.tw-decoration-fuchsia-200\/50 {
  text-decoration-color: rgb(245 208 254 / 0.5) !important;
}

.tw-decoration-fuchsia-200\/55 {
  text-decoration-color: rgb(245 208 254 / 0.55) !important;
}

.tw-decoration-fuchsia-200\/60 {
  text-decoration-color: rgb(245 208 254 / 0.6) !important;
}

.tw-decoration-fuchsia-200\/65 {
  text-decoration-color: rgb(245 208 254 / 0.65) !important;
}

.tw-decoration-fuchsia-200\/70 {
  text-decoration-color: rgb(245 208 254 / 0.7) !important;
}

.tw-decoration-fuchsia-200\/75 {
  text-decoration-color: rgb(245 208 254 / 0.75) !important;
}

.tw-decoration-fuchsia-200\/80 {
  text-decoration-color: rgb(245 208 254 / 0.8) !important;
}

.tw-decoration-fuchsia-200\/85 {
  text-decoration-color: rgb(245 208 254 / 0.85) !important;
}

.tw-decoration-fuchsia-200\/90 {
  text-decoration-color: rgb(245 208 254 / 0.9) !important;
}

.tw-decoration-fuchsia-200\/95 {
  text-decoration-color: rgb(245 208 254 / 0.95) !important;
}

.tw-decoration-fuchsia-300 {
  text-decoration-color: #f0abfc !important;
}

.tw-decoration-fuchsia-300\/0 {
  text-decoration-color: rgb(240 171 252 / 0) !important;
}

.tw-decoration-fuchsia-300\/10 {
  text-decoration-color: rgb(240 171 252 / 0.1) !important;
}

.tw-decoration-fuchsia-300\/100 {
  text-decoration-color: rgb(240 171 252 / 1) !important;
}

.tw-decoration-fuchsia-300\/15 {
  text-decoration-color: rgb(240 171 252 / 0.15) !important;
}

.tw-decoration-fuchsia-300\/20 {
  text-decoration-color: rgb(240 171 252 / 0.2) !important;
}

.tw-decoration-fuchsia-300\/25 {
  text-decoration-color: rgb(240 171 252 / 0.25) !important;
}

.tw-decoration-fuchsia-300\/30 {
  text-decoration-color: rgb(240 171 252 / 0.3) !important;
}

.tw-decoration-fuchsia-300\/35 {
  text-decoration-color: rgb(240 171 252 / 0.35) !important;
}

.tw-decoration-fuchsia-300\/40 {
  text-decoration-color: rgb(240 171 252 / 0.4) !important;
}

.tw-decoration-fuchsia-300\/45 {
  text-decoration-color: rgb(240 171 252 / 0.45) !important;
}

.tw-decoration-fuchsia-300\/5 {
  text-decoration-color: rgb(240 171 252 / 0.05) !important;
}

.tw-decoration-fuchsia-300\/50 {
  text-decoration-color: rgb(240 171 252 / 0.5) !important;
}

.tw-decoration-fuchsia-300\/55 {
  text-decoration-color: rgb(240 171 252 / 0.55) !important;
}

.tw-decoration-fuchsia-300\/60 {
  text-decoration-color: rgb(240 171 252 / 0.6) !important;
}

.tw-decoration-fuchsia-300\/65 {
  text-decoration-color: rgb(240 171 252 / 0.65) !important;
}

.tw-decoration-fuchsia-300\/70 {
  text-decoration-color: rgb(240 171 252 / 0.7) !important;
}

.tw-decoration-fuchsia-300\/75 {
  text-decoration-color: rgb(240 171 252 / 0.75) !important;
}

.tw-decoration-fuchsia-300\/80 {
  text-decoration-color: rgb(240 171 252 / 0.8) !important;
}

.tw-decoration-fuchsia-300\/85 {
  text-decoration-color: rgb(240 171 252 / 0.85) !important;
}

.tw-decoration-fuchsia-300\/90 {
  text-decoration-color: rgb(240 171 252 / 0.9) !important;
}

.tw-decoration-fuchsia-300\/95 {
  text-decoration-color: rgb(240 171 252 / 0.95) !important;
}

.tw-decoration-fuchsia-400 {
  text-decoration-color: #e879f9 !important;
}

.tw-decoration-fuchsia-400\/0 {
  text-decoration-color: rgb(232 121 249 / 0) !important;
}

.tw-decoration-fuchsia-400\/10 {
  text-decoration-color: rgb(232 121 249 / 0.1) !important;
}

.tw-decoration-fuchsia-400\/100 {
  text-decoration-color: rgb(232 121 249 / 1) !important;
}

.tw-decoration-fuchsia-400\/15 {
  text-decoration-color: rgb(232 121 249 / 0.15) !important;
}

.tw-decoration-fuchsia-400\/20 {
  text-decoration-color: rgb(232 121 249 / 0.2) !important;
}

.tw-decoration-fuchsia-400\/25 {
  text-decoration-color: rgb(232 121 249 / 0.25) !important;
}

.tw-decoration-fuchsia-400\/30 {
  text-decoration-color: rgb(232 121 249 / 0.3) !important;
}

.tw-decoration-fuchsia-400\/35 {
  text-decoration-color: rgb(232 121 249 / 0.35) !important;
}

.tw-decoration-fuchsia-400\/40 {
  text-decoration-color: rgb(232 121 249 / 0.4) !important;
}

.tw-decoration-fuchsia-400\/45 {
  text-decoration-color: rgb(232 121 249 / 0.45) !important;
}

.tw-decoration-fuchsia-400\/5 {
  text-decoration-color: rgb(232 121 249 / 0.05) !important;
}

.tw-decoration-fuchsia-400\/50 {
  text-decoration-color: rgb(232 121 249 / 0.5) !important;
}

.tw-decoration-fuchsia-400\/55 {
  text-decoration-color: rgb(232 121 249 / 0.55) !important;
}

.tw-decoration-fuchsia-400\/60 {
  text-decoration-color: rgb(232 121 249 / 0.6) !important;
}

.tw-decoration-fuchsia-400\/65 {
  text-decoration-color: rgb(232 121 249 / 0.65) !important;
}

.tw-decoration-fuchsia-400\/70 {
  text-decoration-color: rgb(232 121 249 / 0.7) !important;
}

.tw-decoration-fuchsia-400\/75 {
  text-decoration-color: rgb(232 121 249 / 0.75) !important;
}

.tw-decoration-fuchsia-400\/80 {
  text-decoration-color: rgb(232 121 249 / 0.8) !important;
}

.tw-decoration-fuchsia-400\/85 {
  text-decoration-color: rgb(232 121 249 / 0.85) !important;
}

.tw-decoration-fuchsia-400\/90 {
  text-decoration-color: rgb(232 121 249 / 0.9) !important;
}

.tw-decoration-fuchsia-400\/95 {
  text-decoration-color: rgb(232 121 249 / 0.95) !important;
}

.tw-decoration-fuchsia-50 {
  text-decoration-color: #fdf4ff !important;
}

.tw-decoration-fuchsia-50\/0 {
  text-decoration-color: rgb(253 244 255 / 0) !important;
}

.tw-decoration-fuchsia-50\/10 {
  text-decoration-color: rgb(253 244 255 / 0.1) !important;
}

.tw-decoration-fuchsia-50\/100 {
  text-decoration-color: rgb(253 244 255 / 1) !important;
}

.tw-decoration-fuchsia-50\/15 {
  text-decoration-color: rgb(253 244 255 / 0.15) !important;
}

.tw-decoration-fuchsia-50\/20 {
  text-decoration-color: rgb(253 244 255 / 0.2) !important;
}

.tw-decoration-fuchsia-50\/25 {
  text-decoration-color: rgb(253 244 255 / 0.25) !important;
}

.tw-decoration-fuchsia-50\/30 {
  text-decoration-color: rgb(253 244 255 / 0.3) !important;
}

.tw-decoration-fuchsia-50\/35 {
  text-decoration-color: rgb(253 244 255 / 0.35) !important;
}

.tw-decoration-fuchsia-50\/40 {
  text-decoration-color: rgb(253 244 255 / 0.4) !important;
}

.tw-decoration-fuchsia-50\/45 {
  text-decoration-color: rgb(253 244 255 / 0.45) !important;
}

.tw-decoration-fuchsia-50\/5 {
  text-decoration-color: rgb(253 244 255 / 0.05) !important;
}

.tw-decoration-fuchsia-50\/50 {
  text-decoration-color: rgb(253 244 255 / 0.5) !important;
}

.tw-decoration-fuchsia-50\/55 {
  text-decoration-color: rgb(253 244 255 / 0.55) !important;
}

.tw-decoration-fuchsia-50\/60 {
  text-decoration-color: rgb(253 244 255 / 0.6) !important;
}

.tw-decoration-fuchsia-50\/65 {
  text-decoration-color: rgb(253 244 255 / 0.65) !important;
}

.tw-decoration-fuchsia-50\/70 {
  text-decoration-color: rgb(253 244 255 / 0.7) !important;
}

.tw-decoration-fuchsia-50\/75 {
  text-decoration-color: rgb(253 244 255 / 0.75) !important;
}

.tw-decoration-fuchsia-50\/80 {
  text-decoration-color: rgb(253 244 255 / 0.8) !important;
}

.tw-decoration-fuchsia-50\/85 {
  text-decoration-color: rgb(253 244 255 / 0.85) !important;
}

.tw-decoration-fuchsia-50\/90 {
  text-decoration-color: rgb(253 244 255 / 0.9) !important;
}

.tw-decoration-fuchsia-50\/95 {
  text-decoration-color: rgb(253 244 255 / 0.95) !important;
}

.tw-decoration-fuchsia-500 {
  text-decoration-color: #d946ef !important;
}

.tw-decoration-fuchsia-500\/0 {
  text-decoration-color: rgb(217 70 239 / 0) !important;
}

.tw-decoration-fuchsia-500\/10 {
  text-decoration-color: rgb(217 70 239 / 0.1) !important;
}

.tw-decoration-fuchsia-500\/100 {
  text-decoration-color: rgb(217 70 239 / 1) !important;
}

.tw-decoration-fuchsia-500\/15 {
  text-decoration-color: rgb(217 70 239 / 0.15) !important;
}

.tw-decoration-fuchsia-500\/20 {
  text-decoration-color: rgb(217 70 239 / 0.2) !important;
}

.tw-decoration-fuchsia-500\/25 {
  text-decoration-color: rgb(217 70 239 / 0.25) !important;
}

.tw-decoration-fuchsia-500\/30 {
  text-decoration-color: rgb(217 70 239 / 0.3) !important;
}

.tw-decoration-fuchsia-500\/35 {
  text-decoration-color: rgb(217 70 239 / 0.35) !important;
}

.tw-decoration-fuchsia-500\/40 {
  text-decoration-color: rgb(217 70 239 / 0.4) !important;
}

.tw-decoration-fuchsia-500\/45 {
  text-decoration-color: rgb(217 70 239 / 0.45) !important;
}

.tw-decoration-fuchsia-500\/5 {
  text-decoration-color: rgb(217 70 239 / 0.05) !important;
}

.tw-decoration-fuchsia-500\/50 {
  text-decoration-color: rgb(217 70 239 / 0.5) !important;
}

.tw-decoration-fuchsia-500\/55 {
  text-decoration-color: rgb(217 70 239 / 0.55) !important;
}

.tw-decoration-fuchsia-500\/60 {
  text-decoration-color: rgb(217 70 239 / 0.6) !important;
}

.tw-decoration-fuchsia-500\/65 {
  text-decoration-color: rgb(217 70 239 / 0.65) !important;
}

.tw-decoration-fuchsia-500\/70 {
  text-decoration-color: rgb(217 70 239 / 0.7) !important;
}

.tw-decoration-fuchsia-500\/75 {
  text-decoration-color: rgb(217 70 239 / 0.75) !important;
}

.tw-decoration-fuchsia-500\/80 {
  text-decoration-color: rgb(217 70 239 / 0.8) !important;
}

.tw-decoration-fuchsia-500\/85 {
  text-decoration-color: rgb(217 70 239 / 0.85) !important;
}

.tw-decoration-fuchsia-500\/90 {
  text-decoration-color: rgb(217 70 239 / 0.9) !important;
}

.tw-decoration-fuchsia-500\/95 {
  text-decoration-color: rgb(217 70 239 / 0.95) !important;
}

.tw-decoration-fuchsia-600 {
  text-decoration-color: #c026d3 !important;
}

.tw-decoration-fuchsia-600\/0 {
  text-decoration-color: rgb(192 38 211 / 0) !important;
}

.tw-decoration-fuchsia-600\/10 {
  text-decoration-color: rgb(192 38 211 / 0.1) !important;
}

.tw-decoration-fuchsia-600\/100 {
  text-decoration-color: rgb(192 38 211 / 1) !important;
}

.tw-decoration-fuchsia-600\/15 {
  text-decoration-color: rgb(192 38 211 / 0.15) !important;
}

.tw-decoration-fuchsia-600\/20 {
  text-decoration-color: rgb(192 38 211 / 0.2) !important;
}

.tw-decoration-fuchsia-600\/25 {
  text-decoration-color: rgb(192 38 211 / 0.25) !important;
}

.tw-decoration-fuchsia-600\/30 {
  text-decoration-color: rgb(192 38 211 / 0.3) !important;
}

.tw-decoration-fuchsia-600\/35 {
  text-decoration-color: rgb(192 38 211 / 0.35) !important;
}

.tw-decoration-fuchsia-600\/40 {
  text-decoration-color: rgb(192 38 211 / 0.4) !important;
}

.tw-decoration-fuchsia-600\/45 {
  text-decoration-color: rgb(192 38 211 / 0.45) !important;
}

.tw-decoration-fuchsia-600\/5 {
  text-decoration-color: rgb(192 38 211 / 0.05) !important;
}

.tw-decoration-fuchsia-600\/50 {
  text-decoration-color: rgb(192 38 211 / 0.5) !important;
}

.tw-decoration-fuchsia-600\/55 {
  text-decoration-color: rgb(192 38 211 / 0.55) !important;
}

.tw-decoration-fuchsia-600\/60 {
  text-decoration-color: rgb(192 38 211 / 0.6) !important;
}

.tw-decoration-fuchsia-600\/65 {
  text-decoration-color: rgb(192 38 211 / 0.65) !important;
}

.tw-decoration-fuchsia-600\/70 {
  text-decoration-color: rgb(192 38 211 / 0.7) !important;
}

.tw-decoration-fuchsia-600\/75 {
  text-decoration-color: rgb(192 38 211 / 0.75) !important;
}

.tw-decoration-fuchsia-600\/80 {
  text-decoration-color: rgb(192 38 211 / 0.8) !important;
}

.tw-decoration-fuchsia-600\/85 {
  text-decoration-color: rgb(192 38 211 / 0.85) !important;
}

.tw-decoration-fuchsia-600\/90 {
  text-decoration-color: rgb(192 38 211 / 0.9) !important;
}

.tw-decoration-fuchsia-600\/95 {
  text-decoration-color: rgb(192 38 211 / 0.95) !important;
}

.tw-decoration-fuchsia-700 {
  text-decoration-color: #a21caf !important;
}

.tw-decoration-fuchsia-700\/0 {
  text-decoration-color: rgb(162 28 175 / 0) !important;
}

.tw-decoration-fuchsia-700\/10 {
  text-decoration-color: rgb(162 28 175 / 0.1) !important;
}

.tw-decoration-fuchsia-700\/100 {
  text-decoration-color: rgb(162 28 175 / 1) !important;
}

.tw-decoration-fuchsia-700\/15 {
  text-decoration-color: rgb(162 28 175 / 0.15) !important;
}

.tw-decoration-fuchsia-700\/20 {
  text-decoration-color: rgb(162 28 175 / 0.2) !important;
}

.tw-decoration-fuchsia-700\/25 {
  text-decoration-color: rgb(162 28 175 / 0.25) !important;
}

.tw-decoration-fuchsia-700\/30 {
  text-decoration-color: rgb(162 28 175 / 0.3) !important;
}

.tw-decoration-fuchsia-700\/35 {
  text-decoration-color: rgb(162 28 175 / 0.35) !important;
}

.tw-decoration-fuchsia-700\/40 {
  text-decoration-color: rgb(162 28 175 / 0.4) !important;
}

.tw-decoration-fuchsia-700\/45 {
  text-decoration-color: rgb(162 28 175 / 0.45) !important;
}

.tw-decoration-fuchsia-700\/5 {
  text-decoration-color: rgb(162 28 175 / 0.05) !important;
}

.tw-decoration-fuchsia-700\/50 {
  text-decoration-color: rgb(162 28 175 / 0.5) !important;
}

.tw-decoration-fuchsia-700\/55 {
  text-decoration-color: rgb(162 28 175 / 0.55) !important;
}

.tw-decoration-fuchsia-700\/60 {
  text-decoration-color: rgb(162 28 175 / 0.6) !important;
}

.tw-decoration-fuchsia-700\/65 {
  text-decoration-color: rgb(162 28 175 / 0.65) !important;
}

.tw-decoration-fuchsia-700\/70 {
  text-decoration-color: rgb(162 28 175 / 0.7) !important;
}

.tw-decoration-fuchsia-700\/75 {
  text-decoration-color: rgb(162 28 175 / 0.75) !important;
}

.tw-decoration-fuchsia-700\/80 {
  text-decoration-color: rgb(162 28 175 / 0.8) !important;
}

.tw-decoration-fuchsia-700\/85 {
  text-decoration-color: rgb(162 28 175 / 0.85) !important;
}

.tw-decoration-fuchsia-700\/90 {
  text-decoration-color: rgb(162 28 175 / 0.9) !important;
}

.tw-decoration-fuchsia-700\/95 {
  text-decoration-color: rgb(162 28 175 / 0.95) !important;
}

.tw-decoration-fuchsia-800 {
  text-decoration-color: #86198f !important;
}

.tw-decoration-fuchsia-800\/0 {
  text-decoration-color: rgb(134 25 143 / 0) !important;
}

.tw-decoration-fuchsia-800\/10 {
  text-decoration-color: rgb(134 25 143 / 0.1) !important;
}

.tw-decoration-fuchsia-800\/100 {
  text-decoration-color: rgb(134 25 143 / 1) !important;
}

.tw-decoration-fuchsia-800\/15 {
  text-decoration-color: rgb(134 25 143 / 0.15) !important;
}

.tw-decoration-fuchsia-800\/20 {
  text-decoration-color: rgb(134 25 143 / 0.2) !important;
}

.tw-decoration-fuchsia-800\/25 {
  text-decoration-color: rgb(134 25 143 / 0.25) !important;
}

.tw-decoration-fuchsia-800\/30 {
  text-decoration-color: rgb(134 25 143 / 0.3) !important;
}

.tw-decoration-fuchsia-800\/35 {
  text-decoration-color: rgb(134 25 143 / 0.35) !important;
}

.tw-decoration-fuchsia-800\/40 {
  text-decoration-color: rgb(134 25 143 / 0.4) !important;
}

.tw-decoration-fuchsia-800\/45 {
  text-decoration-color: rgb(134 25 143 / 0.45) !important;
}

.tw-decoration-fuchsia-800\/5 {
  text-decoration-color: rgb(134 25 143 / 0.05) !important;
}

.tw-decoration-fuchsia-800\/50 {
  text-decoration-color: rgb(134 25 143 / 0.5) !important;
}

.tw-decoration-fuchsia-800\/55 {
  text-decoration-color: rgb(134 25 143 / 0.55) !important;
}

.tw-decoration-fuchsia-800\/60 {
  text-decoration-color: rgb(134 25 143 / 0.6) !important;
}

.tw-decoration-fuchsia-800\/65 {
  text-decoration-color: rgb(134 25 143 / 0.65) !important;
}

.tw-decoration-fuchsia-800\/70 {
  text-decoration-color: rgb(134 25 143 / 0.7) !important;
}

.tw-decoration-fuchsia-800\/75 {
  text-decoration-color: rgb(134 25 143 / 0.75) !important;
}

.tw-decoration-fuchsia-800\/80 {
  text-decoration-color: rgb(134 25 143 / 0.8) !important;
}

.tw-decoration-fuchsia-800\/85 {
  text-decoration-color: rgb(134 25 143 / 0.85) !important;
}

.tw-decoration-fuchsia-800\/90 {
  text-decoration-color: rgb(134 25 143 / 0.9) !important;
}

.tw-decoration-fuchsia-800\/95 {
  text-decoration-color: rgb(134 25 143 / 0.95) !important;
}

.tw-decoration-fuchsia-900 {
  text-decoration-color: #701a75 !important;
}

.tw-decoration-fuchsia-900\/0 {
  text-decoration-color: rgb(112 26 117 / 0) !important;
}

.tw-decoration-fuchsia-900\/10 {
  text-decoration-color: rgb(112 26 117 / 0.1) !important;
}

.tw-decoration-fuchsia-900\/100 {
  text-decoration-color: rgb(112 26 117 / 1) !important;
}

.tw-decoration-fuchsia-900\/15 {
  text-decoration-color: rgb(112 26 117 / 0.15) !important;
}

.tw-decoration-fuchsia-900\/20 {
  text-decoration-color: rgb(112 26 117 / 0.2) !important;
}

.tw-decoration-fuchsia-900\/25 {
  text-decoration-color: rgb(112 26 117 / 0.25) !important;
}

.tw-decoration-fuchsia-900\/30 {
  text-decoration-color: rgb(112 26 117 / 0.3) !important;
}

.tw-decoration-fuchsia-900\/35 {
  text-decoration-color: rgb(112 26 117 / 0.35) !important;
}

.tw-decoration-fuchsia-900\/40 {
  text-decoration-color: rgb(112 26 117 / 0.4) !important;
}

.tw-decoration-fuchsia-900\/45 {
  text-decoration-color: rgb(112 26 117 / 0.45) !important;
}

.tw-decoration-fuchsia-900\/5 {
  text-decoration-color: rgb(112 26 117 / 0.05) !important;
}

.tw-decoration-fuchsia-900\/50 {
  text-decoration-color: rgb(112 26 117 / 0.5) !important;
}

.tw-decoration-fuchsia-900\/55 {
  text-decoration-color: rgb(112 26 117 / 0.55) !important;
}

.tw-decoration-fuchsia-900\/60 {
  text-decoration-color: rgb(112 26 117 / 0.6) !important;
}

.tw-decoration-fuchsia-900\/65 {
  text-decoration-color: rgb(112 26 117 / 0.65) !important;
}

.tw-decoration-fuchsia-900\/70 {
  text-decoration-color: rgb(112 26 117 / 0.7) !important;
}

.tw-decoration-fuchsia-900\/75 {
  text-decoration-color: rgb(112 26 117 / 0.75) !important;
}

.tw-decoration-fuchsia-900\/80 {
  text-decoration-color: rgb(112 26 117 / 0.8) !important;
}

.tw-decoration-fuchsia-900\/85 {
  text-decoration-color: rgb(112 26 117 / 0.85) !important;
}

.tw-decoration-fuchsia-900\/90 {
  text-decoration-color: rgb(112 26 117 / 0.9) !important;
}

.tw-decoration-fuchsia-900\/95 {
  text-decoration-color: rgb(112 26 117 / 0.95) !important;
}

.tw-decoration-fuchsia-950 {
  text-decoration-color: #4a044e !important;
}

.tw-decoration-fuchsia-950\/0 {
  text-decoration-color: rgb(74 4 78 / 0) !important;
}

.tw-decoration-fuchsia-950\/10 {
  text-decoration-color: rgb(74 4 78 / 0.1) !important;
}

.tw-decoration-fuchsia-950\/100 {
  text-decoration-color: rgb(74 4 78 / 1) !important;
}

.tw-decoration-fuchsia-950\/15 {
  text-decoration-color: rgb(74 4 78 / 0.15) !important;
}

.tw-decoration-fuchsia-950\/20 {
  text-decoration-color: rgb(74 4 78 / 0.2) !important;
}

.tw-decoration-fuchsia-950\/25 {
  text-decoration-color: rgb(74 4 78 / 0.25) !important;
}

.tw-decoration-fuchsia-950\/30 {
  text-decoration-color: rgb(74 4 78 / 0.3) !important;
}

.tw-decoration-fuchsia-950\/35 {
  text-decoration-color: rgb(74 4 78 / 0.35) !important;
}

.tw-decoration-fuchsia-950\/40 {
  text-decoration-color: rgb(74 4 78 / 0.4) !important;
}

.tw-decoration-fuchsia-950\/45 {
  text-decoration-color: rgb(74 4 78 / 0.45) !important;
}

.tw-decoration-fuchsia-950\/5 {
  text-decoration-color: rgb(74 4 78 / 0.05) !important;
}

.tw-decoration-fuchsia-950\/50 {
  text-decoration-color: rgb(74 4 78 / 0.5) !important;
}

.tw-decoration-fuchsia-950\/55 {
  text-decoration-color: rgb(74 4 78 / 0.55) !important;
}

.tw-decoration-fuchsia-950\/60 {
  text-decoration-color: rgb(74 4 78 / 0.6) !important;
}

.tw-decoration-fuchsia-950\/65 {
  text-decoration-color: rgb(74 4 78 / 0.65) !important;
}

.tw-decoration-fuchsia-950\/70 {
  text-decoration-color: rgb(74 4 78 / 0.7) !important;
}

.tw-decoration-fuchsia-950\/75 {
  text-decoration-color: rgb(74 4 78 / 0.75) !important;
}

.tw-decoration-fuchsia-950\/80 {
  text-decoration-color: rgb(74 4 78 / 0.8) !important;
}

.tw-decoration-fuchsia-950\/85 {
  text-decoration-color: rgb(74 4 78 / 0.85) !important;
}

.tw-decoration-fuchsia-950\/90 {
  text-decoration-color: rgb(74 4 78 / 0.9) !important;
}

.tw-decoration-fuchsia-950\/95 {
  text-decoration-color: rgb(74 4 78 / 0.95) !important;
}

.tw-decoration-gray-100 {
  text-decoration-color: #f3f4f6 !important;
}

.tw-decoration-gray-100\/0 {
  text-decoration-color: rgb(243 244 246 / 0) !important;
}

.tw-decoration-gray-100\/10 {
  text-decoration-color: rgb(243 244 246 / 0.1) !important;
}

.tw-decoration-gray-100\/100 {
  text-decoration-color: rgb(243 244 246 / 1) !important;
}

.tw-decoration-gray-100\/15 {
  text-decoration-color: rgb(243 244 246 / 0.15) !important;
}

.tw-decoration-gray-100\/20 {
  text-decoration-color: rgb(243 244 246 / 0.2) !important;
}

.tw-decoration-gray-100\/25 {
  text-decoration-color: rgb(243 244 246 / 0.25) !important;
}

.tw-decoration-gray-100\/30 {
  text-decoration-color: rgb(243 244 246 / 0.3) !important;
}

.tw-decoration-gray-100\/35 {
  text-decoration-color: rgb(243 244 246 / 0.35) !important;
}

.tw-decoration-gray-100\/40 {
  text-decoration-color: rgb(243 244 246 / 0.4) !important;
}

.tw-decoration-gray-100\/45 {
  text-decoration-color: rgb(243 244 246 / 0.45) !important;
}

.tw-decoration-gray-100\/5 {
  text-decoration-color: rgb(243 244 246 / 0.05) !important;
}

.tw-decoration-gray-100\/50 {
  text-decoration-color: rgb(243 244 246 / 0.5) !important;
}

.tw-decoration-gray-100\/55 {
  text-decoration-color: rgb(243 244 246 / 0.55) !important;
}

.tw-decoration-gray-100\/60 {
  text-decoration-color: rgb(243 244 246 / 0.6) !important;
}

.tw-decoration-gray-100\/65 {
  text-decoration-color: rgb(243 244 246 / 0.65) !important;
}

.tw-decoration-gray-100\/70 {
  text-decoration-color: rgb(243 244 246 / 0.7) !important;
}

.tw-decoration-gray-100\/75 {
  text-decoration-color: rgb(243 244 246 / 0.75) !important;
}

.tw-decoration-gray-100\/80 {
  text-decoration-color: rgb(243 244 246 / 0.8) !important;
}

.tw-decoration-gray-100\/85 {
  text-decoration-color: rgb(243 244 246 / 0.85) !important;
}

.tw-decoration-gray-100\/90 {
  text-decoration-color: rgb(243 244 246 / 0.9) !important;
}

.tw-decoration-gray-100\/95 {
  text-decoration-color: rgb(243 244 246 / 0.95) !important;
}

.tw-decoration-gray-200 {
  text-decoration-color: #e5e7eb !important;
}

.tw-decoration-gray-200\/0 {
  text-decoration-color: rgb(229 231 235 / 0) !important;
}

.tw-decoration-gray-200\/10 {
  text-decoration-color: rgb(229 231 235 / 0.1) !important;
}

.tw-decoration-gray-200\/100 {
  text-decoration-color: rgb(229 231 235 / 1) !important;
}

.tw-decoration-gray-200\/15 {
  text-decoration-color: rgb(229 231 235 / 0.15) !important;
}

.tw-decoration-gray-200\/20 {
  text-decoration-color: rgb(229 231 235 / 0.2) !important;
}

.tw-decoration-gray-200\/25 {
  text-decoration-color: rgb(229 231 235 / 0.25) !important;
}

.tw-decoration-gray-200\/30 {
  text-decoration-color: rgb(229 231 235 / 0.3) !important;
}

.tw-decoration-gray-200\/35 {
  text-decoration-color: rgb(229 231 235 / 0.35) !important;
}

.tw-decoration-gray-200\/40 {
  text-decoration-color: rgb(229 231 235 / 0.4) !important;
}

.tw-decoration-gray-200\/45 {
  text-decoration-color: rgb(229 231 235 / 0.45) !important;
}

.tw-decoration-gray-200\/5 {
  text-decoration-color: rgb(229 231 235 / 0.05) !important;
}

.tw-decoration-gray-200\/50 {
  text-decoration-color: rgb(229 231 235 / 0.5) !important;
}

.tw-decoration-gray-200\/55 {
  text-decoration-color: rgb(229 231 235 / 0.55) !important;
}

.tw-decoration-gray-200\/60 {
  text-decoration-color: rgb(229 231 235 / 0.6) !important;
}

.tw-decoration-gray-200\/65 {
  text-decoration-color: rgb(229 231 235 / 0.65) !important;
}

.tw-decoration-gray-200\/70 {
  text-decoration-color: rgb(229 231 235 / 0.7) !important;
}

.tw-decoration-gray-200\/75 {
  text-decoration-color: rgb(229 231 235 / 0.75) !important;
}

.tw-decoration-gray-200\/80 {
  text-decoration-color: rgb(229 231 235 / 0.8) !important;
}

.tw-decoration-gray-200\/85 {
  text-decoration-color: rgb(229 231 235 / 0.85) !important;
}

.tw-decoration-gray-200\/90 {
  text-decoration-color: rgb(229 231 235 / 0.9) !important;
}

.tw-decoration-gray-200\/95 {
  text-decoration-color: rgb(229 231 235 / 0.95) !important;
}

.tw-decoration-gray-300 {
  text-decoration-color: #d1d5db !important;
}

.tw-decoration-gray-300\/0 {
  text-decoration-color: rgb(209 213 219 / 0) !important;
}

.tw-decoration-gray-300\/10 {
  text-decoration-color: rgb(209 213 219 / 0.1) !important;
}

.tw-decoration-gray-300\/100 {
  text-decoration-color: rgb(209 213 219 / 1) !important;
}

.tw-decoration-gray-300\/15 {
  text-decoration-color: rgb(209 213 219 / 0.15) !important;
}

.tw-decoration-gray-300\/20 {
  text-decoration-color: rgb(209 213 219 / 0.2) !important;
}

.tw-decoration-gray-300\/25 {
  text-decoration-color: rgb(209 213 219 / 0.25) !important;
}

.tw-decoration-gray-300\/30 {
  text-decoration-color: rgb(209 213 219 / 0.3) !important;
}

.tw-decoration-gray-300\/35 {
  text-decoration-color: rgb(209 213 219 / 0.35) !important;
}

.tw-decoration-gray-300\/40 {
  text-decoration-color: rgb(209 213 219 / 0.4) !important;
}

.tw-decoration-gray-300\/45 {
  text-decoration-color: rgb(209 213 219 / 0.45) !important;
}

.tw-decoration-gray-300\/5 {
  text-decoration-color: rgb(209 213 219 / 0.05) !important;
}

.tw-decoration-gray-300\/50 {
  text-decoration-color: rgb(209 213 219 / 0.5) !important;
}

.tw-decoration-gray-300\/55 {
  text-decoration-color: rgb(209 213 219 / 0.55) !important;
}

.tw-decoration-gray-300\/60 {
  text-decoration-color: rgb(209 213 219 / 0.6) !important;
}

.tw-decoration-gray-300\/65 {
  text-decoration-color: rgb(209 213 219 / 0.65) !important;
}

.tw-decoration-gray-300\/70 {
  text-decoration-color: rgb(209 213 219 / 0.7) !important;
}

.tw-decoration-gray-300\/75 {
  text-decoration-color: rgb(209 213 219 / 0.75) !important;
}

.tw-decoration-gray-300\/80 {
  text-decoration-color: rgb(209 213 219 / 0.8) !important;
}

.tw-decoration-gray-300\/85 {
  text-decoration-color: rgb(209 213 219 / 0.85) !important;
}

.tw-decoration-gray-300\/90 {
  text-decoration-color: rgb(209 213 219 / 0.9) !important;
}

.tw-decoration-gray-300\/95 {
  text-decoration-color: rgb(209 213 219 / 0.95) !important;
}

.tw-decoration-gray-400 {
  text-decoration-color: #9ca3af !important;
}

.tw-decoration-gray-400\/0 {
  text-decoration-color: rgb(156 163 175 / 0) !important;
}

.tw-decoration-gray-400\/10 {
  text-decoration-color: rgb(156 163 175 / 0.1) !important;
}

.tw-decoration-gray-400\/100 {
  text-decoration-color: rgb(156 163 175 / 1) !important;
}

.tw-decoration-gray-400\/15 {
  text-decoration-color: rgb(156 163 175 / 0.15) !important;
}

.tw-decoration-gray-400\/20 {
  text-decoration-color: rgb(156 163 175 / 0.2) !important;
}

.tw-decoration-gray-400\/25 {
  text-decoration-color: rgb(156 163 175 / 0.25) !important;
}

.tw-decoration-gray-400\/30 {
  text-decoration-color: rgb(156 163 175 / 0.3) !important;
}

.tw-decoration-gray-400\/35 {
  text-decoration-color: rgb(156 163 175 / 0.35) !important;
}

.tw-decoration-gray-400\/40 {
  text-decoration-color: rgb(156 163 175 / 0.4) !important;
}

.tw-decoration-gray-400\/45 {
  text-decoration-color: rgb(156 163 175 / 0.45) !important;
}

.tw-decoration-gray-400\/5 {
  text-decoration-color: rgb(156 163 175 / 0.05) !important;
}

.tw-decoration-gray-400\/50 {
  text-decoration-color: rgb(156 163 175 / 0.5) !important;
}

.tw-decoration-gray-400\/55 {
  text-decoration-color: rgb(156 163 175 / 0.55) !important;
}

.tw-decoration-gray-400\/60 {
  text-decoration-color: rgb(156 163 175 / 0.6) !important;
}

.tw-decoration-gray-400\/65 {
  text-decoration-color: rgb(156 163 175 / 0.65) !important;
}

.tw-decoration-gray-400\/70 {
  text-decoration-color: rgb(156 163 175 / 0.7) !important;
}

.tw-decoration-gray-400\/75 {
  text-decoration-color: rgb(156 163 175 / 0.75) !important;
}

.tw-decoration-gray-400\/80 {
  text-decoration-color: rgb(156 163 175 / 0.8) !important;
}

.tw-decoration-gray-400\/85 {
  text-decoration-color: rgb(156 163 175 / 0.85) !important;
}

.tw-decoration-gray-400\/90 {
  text-decoration-color: rgb(156 163 175 / 0.9) !important;
}

.tw-decoration-gray-400\/95 {
  text-decoration-color: rgb(156 163 175 / 0.95) !important;
}

.tw-decoration-gray-50 {
  text-decoration-color: #f9fafb !important;
}

.tw-decoration-gray-50\/0 {
  text-decoration-color: rgb(249 250 251 / 0) !important;
}

.tw-decoration-gray-50\/10 {
  text-decoration-color: rgb(249 250 251 / 0.1) !important;
}

.tw-decoration-gray-50\/100 {
  text-decoration-color: rgb(249 250 251 / 1) !important;
}

.tw-decoration-gray-50\/15 {
  text-decoration-color: rgb(249 250 251 / 0.15) !important;
}

.tw-decoration-gray-50\/20 {
  text-decoration-color: rgb(249 250 251 / 0.2) !important;
}

.tw-decoration-gray-50\/25 {
  text-decoration-color: rgb(249 250 251 / 0.25) !important;
}

.tw-decoration-gray-50\/30 {
  text-decoration-color: rgb(249 250 251 / 0.3) !important;
}

.tw-decoration-gray-50\/35 {
  text-decoration-color: rgb(249 250 251 / 0.35) !important;
}

.tw-decoration-gray-50\/40 {
  text-decoration-color: rgb(249 250 251 / 0.4) !important;
}

.tw-decoration-gray-50\/45 {
  text-decoration-color: rgb(249 250 251 / 0.45) !important;
}

.tw-decoration-gray-50\/5 {
  text-decoration-color: rgb(249 250 251 / 0.05) !important;
}

.tw-decoration-gray-50\/50 {
  text-decoration-color: rgb(249 250 251 / 0.5) !important;
}

.tw-decoration-gray-50\/55 {
  text-decoration-color: rgb(249 250 251 / 0.55) !important;
}

.tw-decoration-gray-50\/60 {
  text-decoration-color: rgb(249 250 251 / 0.6) !important;
}

.tw-decoration-gray-50\/65 {
  text-decoration-color: rgb(249 250 251 / 0.65) !important;
}

.tw-decoration-gray-50\/70 {
  text-decoration-color: rgb(249 250 251 / 0.7) !important;
}

.tw-decoration-gray-50\/75 {
  text-decoration-color: rgb(249 250 251 / 0.75) !important;
}

.tw-decoration-gray-50\/80 {
  text-decoration-color: rgb(249 250 251 / 0.8) !important;
}

.tw-decoration-gray-50\/85 {
  text-decoration-color: rgb(249 250 251 / 0.85) !important;
}

.tw-decoration-gray-50\/90 {
  text-decoration-color: rgb(249 250 251 / 0.9) !important;
}

.tw-decoration-gray-50\/95 {
  text-decoration-color: rgb(249 250 251 / 0.95) !important;
}

.tw-decoration-gray-500 {
  text-decoration-color: #6b7280 !important;
}

.tw-decoration-gray-500\/0 {
  text-decoration-color: rgb(107 114 128 / 0) !important;
}

.tw-decoration-gray-500\/10 {
  text-decoration-color: rgb(107 114 128 / 0.1) !important;
}

.tw-decoration-gray-500\/100 {
  text-decoration-color: rgb(107 114 128 / 1) !important;
}

.tw-decoration-gray-500\/15 {
  text-decoration-color: rgb(107 114 128 / 0.15) !important;
}

.tw-decoration-gray-500\/20 {
  text-decoration-color: rgb(107 114 128 / 0.2) !important;
}

.tw-decoration-gray-500\/25 {
  text-decoration-color: rgb(107 114 128 / 0.25) !important;
}

.tw-decoration-gray-500\/30 {
  text-decoration-color: rgb(107 114 128 / 0.3) !important;
}

.tw-decoration-gray-500\/35 {
  text-decoration-color: rgb(107 114 128 / 0.35) !important;
}

.tw-decoration-gray-500\/40 {
  text-decoration-color: rgb(107 114 128 / 0.4) !important;
}

.tw-decoration-gray-500\/45 {
  text-decoration-color: rgb(107 114 128 / 0.45) !important;
}

.tw-decoration-gray-500\/5 {
  text-decoration-color: rgb(107 114 128 / 0.05) !important;
}

.tw-decoration-gray-500\/50 {
  text-decoration-color: rgb(107 114 128 / 0.5) !important;
}

.tw-decoration-gray-500\/55 {
  text-decoration-color: rgb(107 114 128 / 0.55) !important;
}

.tw-decoration-gray-500\/60 {
  text-decoration-color: rgb(107 114 128 / 0.6) !important;
}

.tw-decoration-gray-500\/65 {
  text-decoration-color: rgb(107 114 128 / 0.65) !important;
}

.tw-decoration-gray-500\/70 {
  text-decoration-color: rgb(107 114 128 / 0.7) !important;
}

.tw-decoration-gray-500\/75 {
  text-decoration-color: rgb(107 114 128 / 0.75) !important;
}

.tw-decoration-gray-500\/80 {
  text-decoration-color: rgb(107 114 128 / 0.8) !important;
}

.tw-decoration-gray-500\/85 {
  text-decoration-color: rgb(107 114 128 / 0.85) !important;
}

.tw-decoration-gray-500\/90 {
  text-decoration-color: rgb(107 114 128 / 0.9) !important;
}

.tw-decoration-gray-500\/95 {
  text-decoration-color: rgb(107 114 128 / 0.95) !important;
}

.tw-decoration-gray-600 {
  text-decoration-color: #4b5563 !important;
}

.tw-decoration-gray-600\/0 {
  text-decoration-color: rgb(75 85 99 / 0) !important;
}

.tw-decoration-gray-600\/10 {
  text-decoration-color: rgb(75 85 99 / 0.1) !important;
}

.tw-decoration-gray-600\/100 {
  text-decoration-color: rgb(75 85 99 / 1) !important;
}

.tw-decoration-gray-600\/15 {
  text-decoration-color: rgb(75 85 99 / 0.15) !important;
}

.tw-decoration-gray-600\/20 {
  text-decoration-color: rgb(75 85 99 / 0.2) !important;
}

.tw-decoration-gray-600\/25 {
  text-decoration-color: rgb(75 85 99 / 0.25) !important;
}

.tw-decoration-gray-600\/30 {
  text-decoration-color: rgb(75 85 99 / 0.3) !important;
}

.tw-decoration-gray-600\/35 {
  text-decoration-color: rgb(75 85 99 / 0.35) !important;
}

.tw-decoration-gray-600\/40 {
  text-decoration-color: rgb(75 85 99 / 0.4) !important;
}

.tw-decoration-gray-600\/45 {
  text-decoration-color: rgb(75 85 99 / 0.45) !important;
}

.tw-decoration-gray-600\/5 {
  text-decoration-color: rgb(75 85 99 / 0.05) !important;
}

.tw-decoration-gray-600\/50 {
  text-decoration-color: rgb(75 85 99 / 0.5) !important;
}

.tw-decoration-gray-600\/55 {
  text-decoration-color: rgb(75 85 99 / 0.55) !important;
}

.tw-decoration-gray-600\/60 {
  text-decoration-color: rgb(75 85 99 / 0.6) !important;
}

.tw-decoration-gray-600\/65 {
  text-decoration-color: rgb(75 85 99 / 0.65) !important;
}

.tw-decoration-gray-600\/70 {
  text-decoration-color: rgb(75 85 99 / 0.7) !important;
}

.tw-decoration-gray-600\/75 {
  text-decoration-color: rgb(75 85 99 / 0.75) !important;
}

.tw-decoration-gray-600\/80 {
  text-decoration-color: rgb(75 85 99 / 0.8) !important;
}

.tw-decoration-gray-600\/85 {
  text-decoration-color: rgb(75 85 99 / 0.85) !important;
}

.tw-decoration-gray-600\/90 {
  text-decoration-color: rgb(75 85 99 / 0.9) !important;
}

.tw-decoration-gray-600\/95 {
  text-decoration-color: rgb(75 85 99 / 0.95) !important;
}

.tw-decoration-gray-700 {
  text-decoration-color: #374151 !important;
}

.tw-decoration-gray-700\/0 {
  text-decoration-color: rgb(55 65 81 / 0) !important;
}

.tw-decoration-gray-700\/10 {
  text-decoration-color: rgb(55 65 81 / 0.1) !important;
}

.tw-decoration-gray-700\/100 {
  text-decoration-color: rgb(55 65 81 / 1) !important;
}

.tw-decoration-gray-700\/15 {
  text-decoration-color: rgb(55 65 81 / 0.15) !important;
}

.tw-decoration-gray-700\/20 {
  text-decoration-color: rgb(55 65 81 / 0.2) !important;
}

.tw-decoration-gray-700\/25 {
  text-decoration-color: rgb(55 65 81 / 0.25) !important;
}

.tw-decoration-gray-700\/30 {
  text-decoration-color: rgb(55 65 81 / 0.3) !important;
}

.tw-decoration-gray-700\/35 {
  text-decoration-color: rgb(55 65 81 / 0.35) !important;
}

.tw-decoration-gray-700\/40 {
  text-decoration-color: rgb(55 65 81 / 0.4) !important;
}

.tw-decoration-gray-700\/45 {
  text-decoration-color: rgb(55 65 81 / 0.45) !important;
}

.tw-decoration-gray-700\/5 {
  text-decoration-color: rgb(55 65 81 / 0.05) !important;
}

.tw-decoration-gray-700\/50 {
  text-decoration-color: rgb(55 65 81 / 0.5) !important;
}

.tw-decoration-gray-700\/55 {
  text-decoration-color: rgb(55 65 81 / 0.55) !important;
}

.tw-decoration-gray-700\/60 {
  text-decoration-color: rgb(55 65 81 / 0.6) !important;
}

.tw-decoration-gray-700\/65 {
  text-decoration-color: rgb(55 65 81 / 0.65) !important;
}

.tw-decoration-gray-700\/70 {
  text-decoration-color: rgb(55 65 81 / 0.7) !important;
}

.tw-decoration-gray-700\/75 {
  text-decoration-color: rgb(55 65 81 / 0.75) !important;
}

.tw-decoration-gray-700\/80 {
  text-decoration-color: rgb(55 65 81 / 0.8) !important;
}

.tw-decoration-gray-700\/85 {
  text-decoration-color: rgb(55 65 81 / 0.85) !important;
}

.tw-decoration-gray-700\/90 {
  text-decoration-color: rgb(55 65 81 / 0.9) !important;
}

.tw-decoration-gray-700\/95 {
  text-decoration-color: rgb(55 65 81 / 0.95) !important;
}

.tw-decoration-gray-800 {
  text-decoration-color: #1f2937 !important;
}

.tw-decoration-gray-800\/0 {
  text-decoration-color: rgb(31 41 55 / 0) !important;
}

.tw-decoration-gray-800\/10 {
  text-decoration-color: rgb(31 41 55 / 0.1) !important;
}

.tw-decoration-gray-800\/100 {
  text-decoration-color: rgb(31 41 55 / 1) !important;
}

.tw-decoration-gray-800\/15 {
  text-decoration-color: rgb(31 41 55 / 0.15) !important;
}

.tw-decoration-gray-800\/20 {
  text-decoration-color: rgb(31 41 55 / 0.2) !important;
}

.tw-decoration-gray-800\/25 {
  text-decoration-color: rgb(31 41 55 / 0.25) !important;
}

.tw-decoration-gray-800\/30 {
  text-decoration-color: rgb(31 41 55 / 0.3) !important;
}

.tw-decoration-gray-800\/35 {
  text-decoration-color: rgb(31 41 55 / 0.35) !important;
}

.tw-decoration-gray-800\/40 {
  text-decoration-color: rgb(31 41 55 / 0.4) !important;
}

.tw-decoration-gray-800\/45 {
  text-decoration-color: rgb(31 41 55 / 0.45) !important;
}

.tw-decoration-gray-800\/5 {
  text-decoration-color: rgb(31 41 55 / 0.05) !important;
}

.tw-decoration-gray-800\/50 {
  text-decoration-color: rgb(31 41 55 / 0.5) !important;
}

.tw-decoration-gray-800\/55 {
  text-decoration-color: rgb(31 41 55 / 0.55) !important;
}

.tw-decoration-gray-800\/60 {
  text-decoration-color: rgb(31 41 55 / 0.6) !important;
}

.tw-decoration-gray-800\/65 {
  text-decoration-color: rgb(31 41 55 / 0.65) !important;
}

.tw-decoration-gray-800\/70 {
  text-decoration-color: rgb(31 41 55 / 0.7) !important;
}

.tw-decoration-gray-800\/75 {
  text-decoration-color: rgb(31 41 55 / 0.75) !important;
}

.tw-decoration-gray-800\/80 {
  text-decoration-color: rgb(31 41 55 / 0.8) !important;
}

.tw-decoration-gray-800\/85 {
  text-decoration-color: rgb(31 41 55 / 0.85) !important;
}

.tw-decoration-gray-800\/90 {
  text-decoration-color: rgb(31 41 55 / 0.9) !important;
}

.tw-decoration-gray-800\/95 {
  text-decoration-color: rgb(31 41 55 / 0.95) !important;
}

.tw-decoration-gray-900 {
  text-decoration-color: #111827 !important;
}

.tw-decoration-gray-900\/0 {
  text-decoration-color: rgb(17 24 39 / 0) !important;
}

.tw-decoration-gray-900\/10 {
  text-decoration-color: rgb(17 24 39 / 0.1) !important;
}

.tw-decoration-gray-900\/100 {
  text-decoration-color: rgb(17 24 39 / 1) !important;
}

.tw-decoration-gray-900\/15 {
  text-decoration-color: rgb(17 24 39 / 0.15) !important;
}

.tw-decoration-gray-900\/20 {
  text-decoration-color: rgb(17 24 39 / 0.2) !important;
}

.tw-decoration-gray-900\/25 {
  text-decoration-color: rgb(17 24 39 / 0.25) !important;
}

.tw-decoration-gray-900\/30 {
  text-decoration-color: rgb(17 24 39 / 0.3) !important;
}

.tw-decoration-gray-900\/35 {
  text-decoration-color: rgb(17 24 39 / 0.35) !important;
}

.tw-decoration-gray-900\/40 {
  text-decoration-color: rgb(17 24 39 / 0.4) !important;
}

.tw-decoration-gray-900\/45 {
  text-decoration-color: rgb(17 24 39 / 0.45) !important;
}

.tw-decoration-gray-900\/5 {
  text-decoration-color: rgb(17 24 39 / 0.05) !important;
}

.tw-decoration-gray-900\/50 {
  text-decoration-color: rgb(17 24 39 / 0.5) !important;
}

.tw-decoration-gray-900\/55 {
  text-decoration-color: rgb(17 24 39 / 0.55) !important;
}

.tw-decoration-gray-900\/60 {
  text-decoration-color: rgb(17 24 39 / 0.6) !important;
}

.tw-decoration-gray-900\/65 {
  text-decoration-color: rgb(17 24 39 / 0.65) !important;
}

.tw-decoration-gray-900\/70 {
  text-decoration-color: rgb(17 24 39 / 0.7) !important;
}

.tw-decoration-gray-900\/75 {
  text-decoration-color: rgb(17 24 39 / 0.75) !important;
}

.tw-decoration-gray-900\/80 {
  text-decoration-color: rgb(17 24 39 / 0.8) !important;
}

.tw-decoration-gray-900\/85 {
  text-decoration-color: rgb(17 24 39 / 0.85) !important;
}

.tw-decoration-gray-900\/90 {
  text-decoration-color: rgb(17 24 39 / 0.9) !important;
}

.tw-decoration-gray-900\/95 {
  text-decoration-color: rgb(17 24 39 / 0.95) !important;
}

.tw-decoration-gray-950 {
  text-decoration-color: #030712 !important;
}

.tw-decoration-gray-950\/0 {
  text-decoration-color: rgb(3 7 18 / 0) !important;
}

.tw-decoration-gray-950\/10 {
  text-decoration-color: rgb(3 7 18 / 0.1) !important;
}

.tw-decoration-gray-950\/100 {
  text-decoration-color: rgb(3 7 18 / 1) !important;
}

.tw-decoration-gray-950\/15 {
  text-decoration-color: rgb(3 7 18 / 0.15) !important;
}

.tw-decoration-gray-950\/20 {
  text-decoration-color: rgb(3 7 18 / 0.2) !important;
}

.tw-decoration-gray-950\/25 {
  text-decoration-color: rgb(3 7 18 / 0.25) !important;
}

.tw-decoration-gray-950\/30 {
  text-decoration-color: rgb(3 7 18 / 0.3) !important;
}

.tw-decoration-gray-950\/35 {
  text-decoration-color: rgb(3 7 18 / 0.35) !important;
}

.tw-decoration-gray-950\/40 {
  text-decoration-color: rgb(3 7 18 / 0.4) !important;
}

.tw-decoration-gray-950\/45 {
  text-decoration-color: rgb(3 7 18 / 0.45) !important;
}

.tw-decoration-gray-950\/5 {
  text-decoration-color: rgb(3 7 18 / 0.05) !important;
}

.tw-decoration-gray-950\/50 {
  text-decoration-color: rgb(3 7 18 / 0.5) !important;
}

.tw-decoration-gray-950\/55 {
  text-decoration-color: rgb(3 7 18 / 0.55) !important;
}

.tw-decoration-gray-950\/60 {
  text-decoration-color: rgb(3 7 18 / 0.6) !important;
}

.tw-decoration-gray-950\/65 {
  text-decoration-color: rgb(3 7 18 / 0.65) !important;
}

.tw-decoration-gray-950\/70 {
  text-decoration-color: rgb(3 7 18 / 0.7) !important;
}

.tw-decoration-gray-950\/75 {
  text-decoration-color: rgb(3 7 18 / 0.75) !important;
}

.tw-decoration-gray-950\/80 {
  text-decoration-color: rgb(3 7 18 / 0.8) !important;
}

.tw-decoration-gray-950\/85 {
  text-decoration-color: rgb(3 7 18 / 0.85) !important;
}

.tw-decoration-gray-950\/90 {
  text-decoration-color: rgb(3 7 18 / 0.9) !important;
}

.tw-decoration-gray-950\/95 {
  text-decoration-color: rgb(3 7 18 / 0.95) !important;
}

.tw-decoration-green-100 {
  text-decoration-color: #dcfce7 !important;
}

.tw-decoration-green-100\/0 {
  text-decoration-color: rgb(220 252 231 / 0) !important;
}

.tw-decoration-green-100\/10 {
  text-decoration-color: rgb(220 252 231 / 0.1) !important;
}

.tw-decoration-green-100\/100 {
  text-decoration-color: rgb(220 252 231 / 1) !important;
}

.tw-decoration-green-100\/15 {
  text-decoration-color: rgb(220 252 231 / 0.15) !important;
}

.tw-decoration-green-100\/20 {
  text-decoration-color: rgb(220 252 231 / 0.2) !important;
}

.tw-decoration-green-100\/25 {
  text-decoration-color: rgb(220 252 231 / 0.25) !important;
}

.tw-decoration-green-100\/30 {
  text-decoration-color: rgb(220 252 231 / 0.3) !important;
}

.tw-decoration-green-100\/35 {
  text-decoration-color: rgb(220 252 231 / 0.35) !important;
}

.tw-decoration-green-100\/40 {
  text-decoration-color: rgb(220 252 231 / 0.4) !important;
}

.tw-decoration-green-100\/45 {
  text-decoration-color: rgb(220 252 231 / 0.45) !important;
}

.tw-decoration-green-100\/5 {
  text-decoration-color: rgb(220 252 231 / 0.05) !important;
}

.tw-decoration-green-100\/50 {
  text-decoration-color: rgb(220 252 231 / 0.5) !important;
}

.tw-decoration-green-100\/55 {
  text-decoration-color: rgb(220 252 231 / 0.55) !important;
}

.tw-decoration-green-100\/60 {
  text-decoration-color: rgb(220 252 231 / 0.6) !important;
}

.tw-decoration-green-100\/65 {
  text-decoration-color: rgb(220 252 231 / 0.65) !important;
}

.tw-decoration-green-100\/70 {
  text-decoration-color: rgb(220 252 231 / 0.7) !important;
}

.tw-decoration-green-100\/75 {
  text-decoration-color: rgb(220 252 231 / 0.75) !important;
}

.tw-decoration-green-100\/80 {
  text-decoration-color: rgb(220 252 231 / 0.8) !important;
}

.tw-decoration-green-100\/85 {
  text-decoration-color: rgb(220 252 231 / 0.85) !important;
}

.tw-decoration-green-100\/90 {
  text-decoration-color: rgb(220 252 231 / 0.9) !important;
}

.tw-decoration-green-100\/95 {
  text-decoration-color: rgb(220 252 231 / 0.95) !important;
}

.tw-decoration-green-200 {
  text-decoration-color: #bbf7d0 !important;
}

.tw-decoration-green-200\/0 {
  text-decoration-color: rgb(187 247 208 / 0) !important;
}

.tw-decoration-green-200\/10 {
  text-decoration-color: rgb(187 247 208 / 0.1) !important;
}

.tw-decoration-green-200\/100 {
  text-decoration-color: rgb(187 247 208 / 1) !important;
}

.tw-decoration-green-200\/15 {
  text-decoration-color: rgb(187 247 208 / 0.15) !important;
}

.tw-decoration-green-200\/20 {
  text-decoration-color: rgb(187 247 208 / 0.2) !important;
}

.tw-decoration-green-200\/25 {
  text-decoration-color: rgb(187 247 208 / 0.25) !important;
}

.tw-decoration-green-200\/30 {
  text-decoration-color: rgb(187 247 208 / 0.3) !important;
}

.tw-decoration-green-200\/35 {
  text-decoration-color: rgb(187 247 208 / 0.35) !important;
}

.tw-decoration-green-200\/40 {
  text-decoration-color: rgb(187 247 208 / 0.4) !important;
}

.tw-decoration-green-200\/45 {
  text-decoration-color: rgb(187 247 208 / 0.45) !important;
}

.tw-decoration-green-200\/5 {
  text-decoration-color: rgb(187 247 208 / 0.05) !important;
}

.tw-decoration-green-200\/50 {
  text-decoration-color: rgb(187 247 208 / 0.5) !important;
}

.tw-decoration-green-200\/55 {
  text-decoration-color: rgb(187 247 208 / 0.55) !important;
}

.tw-decoration-green-200\/60 {
  text-decoration-color: rgb(187 247 208 / 0.6) !important;
}

.tw-decoration-green-200\/65 {
  text-decoration-color: rgb(187 247 208 / 0.65) !important;
}

.tw-decoration-green-200\/70 {
  text-decoration-color: rgb(187 247 208 / 0.7) !important;
}

.tw-decoration-green-200\/75 {
  text-decoration-color: rgb(187 247 208 / 0.75) !important;
}

.tw-decoration-green-200\/80 {
  text-decoration-color: rgb(187 247 208 / 0.8) !important;
}

.tw-decoration-green-200\/85 {
  text-decoration-color: rgb(187 247 208 / 0.85) !important;
}

.tw-decoration-green-200\/90 {
  text-decoration-color: rgb(187 247 208 / 0.9) !important;
}

.tw-decoration-green-200\/95 {
  text-decoration-color: rgb(187 247 208 / 0.95) !important;
}

.tw-decoration-green-300 {
  text-decoration-color: #86efac !important;
}

.tw-decoration-green-300\/0 {
  text-decoration-color: rgb(134 239 172 / 0) !important;
}

.tw-decoration-green-300\/10 {
  text-decoration-color: rgb(134 239 172 / 0.1) !important;
}

.tw-decoration-green-300\/100 {
  text-decoration-color: rgb(134 239 172 / 1) !important;
}

.tw-decoration-green-300\/15 {
  text-decoration-color: rgb(134 239 172 / 0.15) !important;
}

.tw-decoration-green-300\/20 {
  text-decoration-color: rgb(134 239 172 / 0.2) !important;
}

.tw-decoration-green-300\/25 {
  text-decoration-color: rgb(134 239 172 / 0.25) !important;
}

.tw-decoration-green-300\/30 {
  text-decoration-color: rgb(134 239 172 / 0.3) !important;
}

.tw-decoration-green-300\/35 {
  text-decoration-color: rgb(134 239 172 / 0.35) !important;
}

.tw-decoration-green-300\/40 {
  text-decoration-color: rgb(134 239 172 / 0.4) !important;
}

.tw-decoration-green-300\/45 {
  text-decoration-color: rgb(134 239 172 / 0.45) !important;
}

.tw-decoration-green-300\/5 {
  text-decoration-color: rgb(134 239 172 / 0.05) !important;
}

.tw-decoration-green-300\/50 {
  text-decoration-color: rgb(134 239 172 / 0.5) !important;
}

.tw-decoration-green-300\/55 {
  text-decoration-color: rgb(134 239 172 / 0.55) !important;
}

.tw-decoration-green-300\/60 {
  text-decoration-color: rgb(134 239 172 / 0.6) !important;
}

.tw-decoration-green-300\/65 {
  text-decoration-color: rgb(134 239 172 / 0.65) !important;
}

.tw-decoration-green-300\/70 {
  text-decoration-color: rgb(134 239 172 / 0.7) !important;
}

.tw-decoration-green-300\/75 {
  text-decoration-color: rgb(134 239 172 / 0.75) !important;
}

.tw-decoration-green-300\/80 {
  text-decoration-color: rgb(134 239 172 / 0.8) !important;
}

.tw-decoration-green-300\/85 {
  text-decoration-color: rgb(134 239 172 / 0.85) !important;
}

.tw-decoration-green-300\/90 {
  text-decoration-color: rgb(134 239 172 / 0.9) !important;
}

.tw-decoration-green-300\/95 {
  text-decoration-color: rgb(134 239 172 / 0.95) !important;
}

.tw-decoration-green-400 {
  text-decoration-color: #4ade80 !important;
}

.tw-decoration-green-400\/0 {
  text-decoration-color: rgb(74 222 128 / 0) !important;
}

.tw-decoration-green-400\/10 {
  text-decoration-color: rgb(74 222 128 / 0.1) !important;
}

.tw-decoration-green-400\/100 {
  text-decoration-color: rgb(74 222 128 / 1) !important;
}

.tw-decoration-green-400\/15 {
  text-decoration-color: rgb(74 222 128 / 0.15) !important;
}

.tw-decoration-green-400\/20 {
  text-decoration-color: rgb(74 222 128 / 0.2) !important;
}

.tw-decoration-green-400\/25 {
  text-decoration-color: rgb(74 222 128 / 0.25) !important;
}

.tw-decoration-green-400\/30 {
  text-decoration-color: rgb(74 222 128 / 0.3) !important;
}

.tw-decoration-green-400\/35 {
  text-decoration-color: rgb(74 222 128 / 0.35) !important;
}

.tw-decoration-green-400\/40 {
  text-decoration-color: rgb(74 222 128 / 0.4) !important;
}

.tw-decoration-green-400\/45 {
  text-decoration-color: rgb(74 222 128 / 0.45) !important;
}

.tw-decoration-green-400\/5 {
  text-decoration-color: rgb(74 222 128 / 0.05) !important;
}

.tw-decoration-green-400\/50 {
  text-decoration-color: rgb(74 222 128 / 0.5) !important;
}

.tw-decoration-green-400\/55 {
  text-decoration-color: rgb(74 222 128 / 0.55) !important;
}

.tw-decoration-green-400\/60 {
  text-decoration-color: rgb(74 222 128 / 0.6) !important;
}

.tw-decoration-green-400\/65 {
  text-decoration-color: rgb(74 222 128 / 0.65) !important;
}

.tw-decoration-green-400\/70 {
  text-decoration-color: rgb(74 222 128 / 0.7) !important;
}

.tw-decoration-green-400\/75 {
  text-decoration-color: rgb(74 222 128 / 0.75) !important;
}

.tw-decoration-green-400\/80 {
  text-decoration-color: rgb(74 222 128 / 0.8) !important;
}

.tw-decoration-green-400\/85 {
  text-decoration-color: rgb(74 222 128 / 0.85) !important;
}

.tw-decoration-green-400\/90 {
  text-decoration-color: rgb(74 222 128 / 0.9) !important;
}

.tw-decoration-green-400\/95 {
  text-decoration-color: rgb(74 222 128 / 0.95) !important;
}

.tw-decoration-green-50 {
  text-decoration-color: #f0fdf4 !important;
}

.tw-decoration-green-50\/0 {
  text-decoration-color: rgb(240 253 244 / 0) !important;
}

.tw-decoration-green-50\/10 {
  text-decoration-color: rgb(240 253 244 / 0.1) !important;
}

.tw-decoration-green-50\/100 {
  text-decoration-color: rgb(240 253 244 / 1) !important;
}

.tw-decoration-green-50\/15 {
  text-decoration-color: rgb(240 253 244 / 0.15) !important;
}

.tw-decoration-green-50\/20 {
  text-decoration-color: rgb(240 253 244 / 0.2) !important;
}

.tw-decoration-green-50\/25 {
  text-decoration-color: rgb(240 253 244 / 0.25) !important;
}

.tw-decoration-green-50\/30 {
  text-decoration-color: rgb(240 253 244 / 0.3) !important;
}

.tw-decoration-green-50\/35 {
  text-decoration-color: rgb(240 253 244 / 0.35) !important;
}

.tw-decoration-green-50\/40 {
  text-decoration-color: rgb(240 253 244 / 0.4) !important;
}

.tw-decoration-green-50\/45 {
  text-decoration-color: rgb(240 253 244 / 0.45) !important;
}

.tw-decoration-green-50\/5 {
  text-decoration-color: rgb(240 253 244 / 0.05) !important;
}

.tw-decoration-green-50\/50 {
  text-decoration-color: rgb(240 253 244 / 0.5) !important;
}

.tw-decoration-green-50\/55 {
  text-decoration-color: rgb(240 253 244 / 0.55) !important;
}

.tw-decoration-green-50\/60 {
  text-decoration-color: rgb(240 253 244 / 0.6) !important;
}

.tw-decoration-green-50\/65 {
  text-decoration-color: rgb(240 253 244 / 0.65) !important;
}

.tw-decoration-green-50\/70 {
  text-decoration-color: rgb(240 253 244 / 0.7) !important;
}

.tw-decoration-green-50\/75 {
  text-decoration-color: rgb(240 253 244 / 0.75) !important;
}

.tw-decoration-green-50\/80 {
  text-decoration-color: rgb(240 253 244 / 0.8) !important;
}

.tw-decoration-green-50\/85 {
  text-decoration-color: rgb(240 253 244 / 0.85) !important;
}

.tw-decoration-green-50\/90 {
  text-decoration-color: rgb(240 253 244 / 0.9) !important;
}

.tw-decoration-green-50\/95 {
  text-decoration-color: rgb(240 253 244 / 0.95) !important;
}

.tw-decoration-green-500 {
  text-decoration-color: #22c55e !important;
}

.tw-decoration-green-500\/0 {
  text-decoration-color: rgb(34 197 94 / 0) !important;
}

.tw-decoration-green-500\/10 {
  text-decoration-color: rgb(34 197 94 / 0.1) !important;
}

.tw-decoration-green-500\/100 {
  text-decoration-color: rgb(34 197 94 / 1) !important;
}

.tw-decoration-green-500\/15 {
  text-decoration-color: rgb(34 197 94 / 0.15) !important;
}

.tw-decoration-green-500\/20 {
  text-decoration-color: rgb(34 197 94 / 0.2) !important;
}

.tw-decoration-green-500\/25 {
  text-decoration-color: rgb(34 197 94 / 0.25) !important;
}

.tw-decoration-green-500\/30 {
  text-decoration-color: rgb(34 197 94 / 0.3) !important;
}

.tw-decoration-green-500\/35 {
  text-decoration-color: rgb(34 197 94 / 0.35) !important;
}

.tw-decoration-green-500\/40 {
  text-decoration-color: rgb(34 197 94 / 0.4) !important;
}

.tw-decoration-green-500\/45 {
  text-decoration-color: rgb(34 197 94 / 0.45) !important;
}

.tw-decoration-green-500\/5 {
  text-decoration-color: rgb(34 197 94 / 0.05) !important;
}

.tw-decoration-green-500\/50 {
  text-decoration-color: rgb(34 197 94 / 0.5) !important;
}

.tw-decoration-green-500\/55 {
  text-decoration-color: rgb(34 197 94 / 0.55) !important;
}

.tw-decoration-green-500\/60 {
  text-decoration-color: rgb(34 197 94 / 0.6) !important;
}

.tw-decoration-green-500\/65 {
  text-decoration-color: rgb(34 197 94 / 0.65) !important;
}

.tw-decoration-green-500\/70 {
  text-decoration-color: rgb(34 197 94 / 0.7) !important;
}

.tw-decoration-green-500\/75 {
  text-decoration-color: rgb(34 197 94 / 0.75) !important;
}

.tw-decoration-green-500\/80 {
  text-decoration-color: rgb(34 197 94 / 0.8) !important;
}

.tw-decoration-green-500\/85 {
  text-decoration-color: rgb(34 197 94 / 0.85) !important;
}

.tw-decoration-green-500\/90 {
  text-decoration-color: rgb(34 197 94 / 0.9) !important;
}

.tw-decoration-green-500\/95 {
  text-decoration-color: rgb(34 197 94 / 0.95) !important;
}

.tw-decoration-green-600 {
  text-decoration-color: #16a34a !important;
}

.tw-decoration-green-600\/0 {
  text-decoration-color: rgb(22 163 74 / 0) !important;
}

.tw-decoration-green-600\/10 {
  text-decoration-color: rgb(22 163 74 / 0.1) !important;
}

.tw-decoration-green-600\/100 {
  text-decoration-color: rgb(22 163 74 / 1) !important;
}

.tw-decoration-green-600\/15 {
  text-decoration-color: rgb(22 163 74 / 0.15) !important;
}

.tw-decoration-green-600\/20 {
  text-decoration-color: rgb(22 163 74 / 0.2) !important;
}

.tw-decoration-green-600\/25 {
  text-decoration-color: rgb(22 163 74 / 0.25) !important;
}

.tw-decoration-green-600\/30 {
  text-decoration-color: rgb(22 163 74 / 0.3) !important;
}

.tw-decoration-green-600\/35 {
  text-decoration-color: rgb(22 163 74 / 0.35) !important;
}

.tw-decoration-green-600\/40 {
  text-decoration-color: rgb(22 163 74 / 0.4) !important;
}

.tw-decoration-green-600\/45 {
  text-decoration-color: rgb(22 163 74 / 0.45) !important;
}

.tw-decoration-green-600\/5 {
  text-decoration-color: rgb(22 163 74 / 0.05) !important;
}

.tw-decoration-green-600\/50 {
  text-decoration-color: rgb(22 163 74 / 0.5) !important;
}

.tw-decoration-green-600\/55 {
  text-decoration-color: rgb(22 163 74 / 0.55) !important;
}

.tw-decoration-green-600\/60 {
  text-decoration-color: rgb(22 163 74 / 0.6) !important;
}

.tw-decoration-green-600\/65 {
  text-decoration-color: rgb(22 163 74 / 0.65) !important;
}

.tw-decoration-green-600\/70 {
  text-decoration-color: rgb(22 163 74 / 0.7) !important;
}

.tw-decoration-green-600\/75 {
  text-decoration-color: rgb(22 163 74 / 0.75) !important;
}

.tw-decoration-green-600\/80 {
  text-decoration-color: rgb(22 163 74 / 0.8) !important;
}

.tw-decoration-green-600\/85 {
  text-decoration-color: rgb(22 163 74 / 0.85) !important;
}

.tw-decoration-green-600\/90 {
  text-decoration-color: rgb(22 163 74 / 0.9) !important;
}

.tw-decoration-green-600\/95 {
  text-decoration-color: rgb(22 163 74 / 0.95) !important;
}

.tw-decoration-green-700 {
  text-decoration-color: #15803d !important;
}

.tw-decoration-green-700\/0 {
  text-decoration-color: rgb(21 128 61 / 0) !important;
}

.tw-decoration-green-700\/10 {
  text-decoration-color: rgb(21 128 61 / 0.1) !important;
}

.tw-decoration-green-700\/100 {
  text-decoration-color: rgb(21 128 61 / 1) !important;
}

.tw-decoration-green-700\/15 {
  text-decoration-color: rgb(21 128 61 / 0.15) !important;
}

.tw-decoration-green-700\/20 {
  text-decoration-color: rgb(21 128 61 / 0.2) !important;
}

.tw-decoration-green-700\/25 {
  text-decoration-color: rgb(21 128 61 / 0.25) !important;
}

.tw-decoration-green-700\/30 {
  text-decoration-color: rgb(21 128 61 / 0.3) !important;
}

.tw-decoration-green-700\/35 {
  text-decoration-color: rgb(21 128 61 / 0.35) !important;
}

.tw-decoration-green-700\/40 {
  text-decoration-color: rgb(21 128 61 / 0.4) !important;
}

.tw-decoration-green-700\/45 {
  text-decoration-color: rgb(21 128 61 / 0.45) !important;
}

.tw-decoration-green-700\/5 {
  text-decoration-color: rgb(21 128 61 / 0.05) !important;
}

.tw-decoration-green-700\/50 {
  text-decoration-color: rgb(21 128 61 / 0.5) !important;
}

.tw-decoration-green-700\/55 {
  text-decoration-color: rgb(21 128 61 / 0.55) !important;
}

.tw-decoration-green-700\/60 {
  text-decoration-color: rgb(21 128 61 / 0.6) !important;
}

.tw-decoration-green-700\/65 {
  text-decoration-color: rgb(21 128 61 / 0.65) !important;
}

.tw-decoration-green-700\/70 {
  text-decoration-color: rgb(21 128 61 / 0.7) !important;
}

.tw-decoration-green-700\/75 {
  text-decoration-color: rgb(21 128 61 / 0.75) !important;
}

.tw-decoration-green-700\/80 {
  text-decoration-color: rgb(21 128 61 / 0.8) !important;
}

.tw-decoration-green-700\/85 {
  text-decoration-color: rgb(21 128 61 / 0.85) !important;
}

.tw-decoration-green-700\/90 {
  text-decoration-color: rgb(21 128 61 / 0.9) !important;
}

.tw-decoration-green-700\/95 {
  text-decoration-color: rgb(21 128 61 / 0.95) !important;
}

.tw-decoration-green-800 {
  text-decoration-color: #166534 !important;
}

.tw-decoration-green-800\/0 {
  text-decoration-color: rgb(22 101 52 / 0) !important;
}

.tw-decoration-green-800\/10 {
  text-decoration-color: rgb(22 101 52 / 0.1) !important;
}

.tw-decoration-green-800\/100 {
  text-decoration-color: rgb(22 101 52 / 1) !important;
}

.tw-decoration-green-800\/15 {
  text-decoration-color: rgb(22 101 52 / 0.15) !important;
}

.tw-decoration-green-800\/20 {
  text-decoration-color: rgb(22 101 52 / 0.2) !important;
}

.tw-decoration-green-800\/25 {
  text-decoration-color: rgb(22 101 52 / 0.25) !important;
}

.tw-decoration-green-800\/30 {
  text-decoration-color: rgb(22 101 52 / 0.3) !important;
}

.tw-decoration-green-800\/35 {
  text-decoration-color: rgb(22 101 52 / 0.35) !important;
}

.tw-decoration-green-800\/40 {
  text-decoration-color: rgb(22 101 52 / 0.4) !important;
}

.tw-decoration-green-800\/45 {
  text-decoration-color: rgb(22 101 52 / 0.45) !important;
}

.tw-decoration-green-800\/5 {
  text-decoration-color: rgb(22 101 52 / 0.05) !important;
}

.tw-decoration-green-800\/50 {
  text-decoration-color: rgb(22 101 52 / 0.5) !important;
}

.tw-decoration-green-800\/55 {
  text-decoration-color: rgb(22 101 52 / 0.55) !important;
}

.tw-decoration-green-800\/60 {
  text-decoration-color: rgb(22 101 52 / 0.6) !important;
}

.tw-decoration-green-800\/65 {
  text-decoration-color: rgb(22 101 52 / 0.65) !important;
}

.tw-decoration-green-800\/70 {
  text-decoration-color: rgb(22 101 52 / 0.7) !important;
}

.tw-decoration-green-800\/75 {
  text-decoration-color: rgb(22 101 52 / 0.75) !important;
}

.tw-decoration-green-800\/80 {
  text-decoration-color: rgb(22 101 52 / 0.8) !important;
}

.tw-decoration-green-800\/85 {
  text-decoration-color: rgb(22 101 52 / 0.85) !important;
}

.tw-decoration-green-800\/90 {
  text-decoration-color: rgb(22 101 52 / 0.9) !important;
}

.tw-decoration-green-800\/95 {
  text-decoration-color: rgb(22 101 52 / 0.95) !important;
}

.tw-decoration-green-900 {
  text-decoration-color: #14532d !important;
}

.tw-decoration-green-900\/0 {
  text-decoration-color: rgb(20 83 45 / 0) !important;
}

.tw-decoration-green-900\/10 {
  text-decoration-color: rgb(20 83 45 / 0.1) !important;
}

.tw-decoration-green-900\/100 {
  text-decoration-color: rgb(20 83 45 / 1) !important;
}

.tw-decoration-green-900\/15 {
  text-decoration-color: rgb(20 83 45 / 0.15) !important;
}

.tw-decoration-green-900\/20 {
  text-decoration-color: rgb(20 83 45 / 0.2) !important;
}

.tw-decoration-green-900\/25 {
  text-decoration-color: rgb(20 83 45 / 0.25) !important;
}

.tw-decoration-green-900\/30 {
  text-decoration-color: rgb(20 83 45 / 0.3) !important;
}

.tw-decoration-green-900\/35 {
  text-decoration-color: rgb(20 83 45 / 0.35) !important;
}

.tw-decoration-green-900\/40 {
  text-decoration-color: rgb(20 83 45 / 0.4) !important;
}

.tw-decoration-green-900\/45 {
  text-decoration-color: rgb(20 83 45 / 0.45) !important;
}

.tw-decoration-green-900\/5 {
  text-decoration-color: rgb(20 83 45 / 0.05) !important;
}

.tw-decoration-green-900\/50 {
  text-decoration-color: rgb(20 83 45 / 0.5) !important;
}

.tw-decoration-green-900\/55 {
  text-decoration-color: rgb(20 83 45 / 0.55) !important;
}

.tw-decoration-green-900\/60 {
  text-decoration-color: rgb(20 83 45 / 0.6) !important;
}

.tw-decoration-green-900\/65 {
  text-decoration-color: rgb(20 83 45 / 0.65) !important;
}

.tw-decoration-green-900\/70 {
  text-decoration-color: rgb(20 83 45 / 0.7) !important;
}

.tw-decoration-green-900\/75 {
  text-decoration-color: rgb(20 83 45 / 0.75) !important;
}

.tw-decoration-green-900\/80 {
  text-decoration-color: rgb(20 83 45 / 0.8) !important;
}

.tw-decoration-green-900\/85 {
  text-decoration-color: rgb(20 83 45 / 0.85) !important;
}

.tw-decoration-green-900\/90 {
  text-decoration-color: rgb(20 83 45 / 0.9) !important;
}

.tw-decoration-green-900\/95 {
  text-decoration-color: rgb(20 83 45 / 0.95) !important;
}

.tw-decoration-green-950 {
  text-decoration-color: #052e16 !important;
}

.tw-decoration-green-950\/0 {
  text-decoration-color: rgb(5 46 22 / 0) !important;
}

.tw-decoration-green-950\/10 {
  text-decoration-color: rgb(5 46 22 / 0.1) !important;
}

.tw-decoration-green-950\/100 {
  text-decoration-color: rgb(5 46 22 / 1) !important;
}

.tw-decoration-green-950\/15 {
  text-decoration-color: rgb(5 46 22 / 0.15) !important;
}

.tw-decoration-green-950\/20 {
  text-decoration-color: rgb(5 46 22 / 0.2) !important;
}

.tw-decoration-green-950\/25 {
  text-decoration-color: rgb(5 46 22 / 0.25) !important;
}

.tw-decoration-green-950\/30 {
  text-decoration-color: rgb(5 46 22 / 0.3) !important;
}

.tw-decoration-green-950\/35 {
  text-decoration-color: rgb(5 46 22 / 0.35) !important;
}

.tw-decoration-green-950\/40 {
  text-decoration-color: rgb(5 46 22 / 0.4) !important;
}

.tw-decoration-green-950\/45 {
  text-decoration-color: rgb(5 46 22 / 0.45) !important;
}

.tw-decoration-green-950\/5 {
  text-decoration-color: rgb(5 46 22 / 0.05) !important;
}

.tw-decoration-green-950\/50 {
  text-decoration-color: rgb(5 46 22 / 0.5) !important;
}

.tw-decoration-green-950\/55 {
  text-decoration-color: rgb(5 46 22 / 0.55) !important;
}

.tw-decoration-green-950\/60 {
  text-decoration-color: rgb(5 46 22 / 0.6) !important;
}

.tw-decoration-green-950\/65 {
  text-decoration-color: rgb(5 46 22 / 0.65) !important;
}

.tw-decoration-green-950\/70 {
  text-decoration-color: rgb(5 46 22 / 0.7) !important;
}

.tw-decoration-green-950\/75 {
  text-decoration-color: rgb(5 46 22 / 0.75) !important;
}

.tw-decoration-green-950\/80 {
  text-decoration-color: rgb(5 46 22 / 0.8) !important;
}

.tw-decoration-green-950\/85 {
  text-decoration-color: rgb(5 46 22 / 0.85) !important;
}

.tw-decoration-green-950\/90 {
  text-decoration-color: rgb(5 46 22 / 0.9) !important;
}

.tw-decoration-green-950\/95 {
  text-decoration-color: rgb(5 46 22 / 0.95) !important;
}

.tw-decoration-indigo-100 {
  text-decoration-color: #e0e7ff !important;
}

.tw-decoration-indigo-100\/0 {
  text-decoration-color: rgb(224 231 255 / 0) !important;
}

.tw-decoration-indigo-100\/10 {
  text-decoration-color: rgb(224 231 255 / 0.1) !important;
}

.tw-decoration-indigo-100\/100 {
  text-decoration-color: rgb(224 231 255 / 1) !important;
}

.tw-decoration-indigo-100\/15 {
  text-decoration-color: rgb(224 231 255 / 0.15) !important;
}

.tw-decoration-indigo-100\/20 {
  text-decoration-color: rgb(224 231 255 / 0.2) !important;
}

.tw-decoration-indigo-100\/25 {
  text-decoration-color: rgb(224 231 255 / 0.25) !important;
}

.tw-decoration-indigo-100\/30 {
  text-decoration-color: rgb(224 231 255 / 0.3) !important;
}

.tw-decoration-indigo-100\/35 {
  text-decoration-color: rgb(224 231 255 / 0.35) !important;
}

.tw-decoration-indigo-100\/40 {
  text-decoration-color: rgb(224 231 255 / 0.4) !important;
}

.tw-decoration-indigo-100\/45 {
  text-decoration-color: rgb(224 231 255 / 0.45) !important;
}

.tw-decoration-indigo-100\/5 {
  text-decoration-color: rgb(224 231 255 / 0.05) !important;
}

.tw-decoration-indigo-100\/50 {
  text-decoration-color: rgb(224 231 255 / 0.5) !important;
}

.tw-decoration-indigo-100\/55 {
  text-decoration-color: rgb(224 231 255 / 0.55) !important;
}

.tw-decoration-indigo-100\/60 {
  text-decoration-color: rgb(224 231 255 / 0.6) !important;
}

.tw-decoration-indigo-100\/65 {
  text-decoration-color: rgb(224 231 255 / 0.65) !important;
}

.tw-decoration-indigo-100\/70 {
  text-decoration-color: rgb(224 231 255 / 0.7) !important;
}

.tw-decoration-indigo-100\/75 {
  text-decoration-color: rgb(224 231 255 / 0.75) !important;
}

.tw-decoration-indigo-100\/80 {
  text-decoration-color: rgb(224 231 255 / 0.8) !important;
}

.tw-decoration-indigo-100\/85 {
  text-decoration-color: rgb(224 231 255 / 0.85) !important;
}

.tw-decoration-indigo-100\/90 {
  text-decoration-color: rgb(224 231 255 / 0.9) !important;
}

.tw-decoration-indigo-100\/95 {
  text-decoration-color: rgb(224 231 255 / 0.95) !important;
}

.tw-decoration-indigo-200 {
  text-decoration-color: #c7d2fe !important;
}

.tw-decoration-indigo-200\/0 {
  text-decoration-color: rgb(199 210 254 / 0) !important;
}

.tw-decoration-indigo-200\/10 {
  text-decoration-color: rgb(199 210 254 / 0.1) !important;
}

.tw-decoration-indigo-200\/100 {
  text-decoration-color: rgb(199 210 254 / 1) !important;
}

.tw-decoration-indigo-200\/15 {
  text-decoration-color: rgb(199 210 254 / 0.15) !important;
}

.tw-decoration-indigo-200\/20 {
  text-decoration-color: rgb(199 210 254 / 0.2) !important;
}

.tw-decoration-indigo-200\/25 {
  text-decoration-color: rgb(199 210 254 / 0.25) !important;
}

.tw-decoration-indigo-200\/30 {
  text-decoration-color: rgb(199 210 254 / 0.3) !important;
}

.tw-decoration-indigo-200\/35 {
  text-decoration-color: rgb(199 210 254 / 0.35) !important;
}

.tw-decoration-indigo-200\/40 {
  text-decoration-color: rgb(199 210 254 / 0.4) !important;
}

.tw-decoration-indigo-200\/45 {
  text-decoration-color: rgb(199 210 254 / 0.45) !important;
}

.tw-decoration-indigo-200\/5 {
  text-decoration-color: rgb(199 210 254 / 0.05) !important;
}

.tw-decoration-indigo-200\/50 {
  text-decoration-color: rgb(199 210 254 / 0.5) !important;
}

.tw-decoration-indigo-200\/55 {
  text-decoration-color: rgb(199 210 254 / 0.55) !important;
}

.tw-decoration-indigo-200\/60 {
  text-decoration-color: rgb(199 210 254 / 0.6) !important;
}

.tw-decoration-indigo-200\/65 {
  text-decoration-color: rgb(199 210 254 / 0.65) !important;
}

.tw-decoration-indigo-200\/70 {
  text-decoration-color: rgb(199 210 254 / 0.7) !important;
}

.tw-decoration-indigo-200\/75 {
  text-decoration-color: rgb(199 210 254 / 0.75) !important;
}

.tw-decoration-indigo-200\/80 {
  text-decoration-color: rgb(199 210 254 / 0.8) !important;
}

.tw-decoration-indigo-200\/85 {
  text-decoration-color: rgb(199 210 254 / 0.85) !important;
}

.tw-decoration-indigo-200\/90 {
  text-decoration-color: rgb(199 210 254 / 0.9) !important;
}

.tw-decoration-indigo-200\/95 {
  text-decoration-color: rgb(199 210 254 / 0.95) !important;
}

.tw-decoration-indigo-300 {
  text-decoration-color: #a5b4fc !important;
}

.tw-decoration-indigo-300\/0 {
  text-decoration-color: rgb(165 180 252 / 0) !important;
}

.tw-decoration-indigo-300\/10 {
  text-decoration-color: rgb(165 180 252 / 0.1) !important;
}

.tw-decoration-indigo-300\/100 {
  text-decoration-color: rgb(165 180 252 / 1) !important;
}

.tw-decoration-indigo-300\/15 {
  text-decoration-color: rgb(165 180 252 / 0.15) !important;
}

.tw-decoration-indigo-300\/20 {
  text-decoration-color: rgb(165 180 252 / 0.2) !important;
}

.tw-decoration-indigo-300\/25 {
  text-decoration-color: rgb(165 180 252 / 0.25) !important;
}

.tw-decoration-indigo-300\/30 {
  text-decoration-color: rgb(165 180 252 / 0.3) !important;
}

.tw-decoration-indigo-300\/35 {
  text-decoration-color: rgb(165 180 252 / 0.35) !important;
}

.tw-decoration-indigo-300\/40 {
  text-decoration-color: rgb(165 180 252 / 0.4) !important;
}

.tw-decoration-indigo-300\/45 {
  text-decoration-color: rgb(165 180 252 / 0.45) !important;
}

.tw-decoration-indigo-300\/5 {
  text-decoration-color: rgb(165 180 252 / 0.05) !important;
}

.tw-decoration-indigo-300\/50 {
  text-decoration-color: rgb(165 180 252 / 0.5) !important;
}

.tw-decoration-indigo-300\/55 {
  text-decoration-color: rgb(165 180 252 / 0.55) !important;
}

.tw-decoration-indigo-300\/60 {
  text-decoration-color: rgb(165 180 252 / 0.6) !important;
}

.tw-decoration-indigo-300\/65 {
  text-decoration-color: rgb(165 180 252 / 0.65) !important;
}

.tw-decoration-indigo-300\/70 {
  text-decoration-color: rgb(165 180 252 / 0.7) !important;
}

.tw-decoration-indigo-300\/75 {
  text-decoration-color: rgb(165 180 252 / 0.75) !important;
}

.tw-decoration-indigo-300\/80 {
  text-decoration-color: rgb(165 180 252 / 0.8) !important;
}

.tw-decoration-indigo-300\/85 {
  text-decoration-color: rgb(165 180 252 / 0.85) !important;
}

.tw-decoration-indigo-300\/90 {
  text-decoration-color: rgb(165 180 252 / 0.9) !important;
}

.tw-decoration-indigo-300\/95 {
  text-decoration-color: rgb(165 180 252 / 0.95) !important;
}

.tw-decoration-indigo-400 {
  text-decoration-color: #818cf8 !important;
}

.tw-decoration-indigo-400\/0 {
  text-decoration-color: rgb(129 140 248 / 0) !important;
}

.tw-decoration-indigo-400\/10 {
  text-decoration-color: rgb(129 140 248 / 0.1) !important;
}

.tw-decoration-indigo-400\/100 {
  text-decoration-color: rgb(129 140 248 / 1) !important;
}

.tw-decoration-indigo-400\/15 {
  text-decoration-color: rgb(129 140 248 / 0.15) !important;
}

.tw-decoration-indigo-400\/20 {
  text-decoration-color: rgb(129 140 248 / 0.2) !important;
}

.tw-decoration-indigo-400\/25 {
  text-decoration-color: rgb(129 140 248 / 0.25) !important;
}

.tw-decoration-indigo-400\/30 {
  text-decoration-color: rgb(129 140 248 / 0.3) !important;
}

.tw-decoration-indigo-400\/35 {
  text-decoration-color: rgb(129 140 248 / 0.35) !important;
}

.tw-decoration-indigo-400\/40 {
  text-decoration-color: rgb(129 140 248 / 0.4) !important;
}

.tw-decoration-indigo-400\/45 {
  text-decoration-color: rgb(129 140 248 / 0.45) !important;
}

.tw-decoration-indigo-400\/5 {
  text-decoration-color: rgb(129 140 248 / 0.05) !important;
}

.tw-decoration-indigo-400\/50 {
  text-decoration-color: rgb(129 140 248 / 0.5) !important;
}

.tw-decoration-indigo-400\/55 {
  text-decoration-color: rgb(129 140 248 / 0.55) !important;
}

.tw-decoration-indigo-400\/60 {
  text-decoration-color: rgb(129 140 248 / 0.6) !important;
}

.tw-decoration-indigo-400\/65 {
  text-decoration-color: rgb(129 140 248 / 0.65) !important;
}

.tw-decoration-indigo-400\/70 {
  text-decoration-color: rgb(129 140 248 / 0.7) !important;
}

.tw-decoration-indigo-400\/75 {
  text-decoration-color: rgb(129 140 248 / 0.75) !important;
}

.tw-decoration-indigo-400\/80 {
  text-decoration-color: rgb(129 140 248 / 0.8) !important;
}

.tw-decoration-indigo-400\/85 {
  text-decoration-color: rgb(129 140 248 / 0.85) !important;
}

.tw-decoration-indigo-400\/90 {
  text-decoration-color: rgb(129 140 248 / 0.9) !important;
}

.tw-decoration-indigo-400\/95 {
  text-decoration-color: rgb(129 140 248 / 0.95) !important;
}

.tw-decoration-indigo-50 {
  text-decoration-color: #eef2ff !important;
}

.tw-decoration-indigo-50\/0 {
  text-decoration-color: rgb(238 242 255 / 0) !important;
}

.tw-decoration-indigo-50\/10 {
  text-decoration-color: rgb(238 242 255 / 0.1) !important;
}

.tw-decoration-indigo-50\/100 {
  text-decoration-color: rgb(238 242 255 / 1) !important;
}

.tw-decoration-indigo-50\/15 {
  text-decoration-color: rgb(238 242 255 / 0.15) !important;
}

.tw-decoration-indigo-50\/20 {
  text-decoration-color: rgb(238 242 255 / 0.2) !important;
}

.tw-decoration-indigo-50\/25 {
  text-decoration-color: rgb(238 242 255 / 0.25) !important;
}

.tw-decoration-indigo-50\/30 {
  text-decoration-color: rgb(238 242 255 / 0.3) !important;
}

.tw-decoration-indigo-50\/35 {
  text-decoration-color: rgb(238 242 255 / 0.35) !important;
}

.tw-decoration-indigo-50\/40 {
  text-decoration-color: rgb(238 242 255 / 0.4) !important;
}

.tw-decoration-indigo-50\/45 {
  text-decoration-color: rgb(238 242 255 / 0.45) !important;
}

.tw-decoration-indigo-50\/5 {
  text-decoration-color: rgb(238 242 255 / 0.05) !important;
}

.tw-decoration-indigo-50\/50 {
  text-decoration-color: rgb(238 242 255 / 0.5) !important;
}

.tw-decoration-indigo-50\/55 {
  text-decoration-color: rgb(238 242 255 / 0.55) !important;
}

.tw-decoration-indigo-50\/60 {
  text-decoration-color: rgb(238 242 255 / 0.6) !important;
}

.tw-decoration-indigo-50\/65 {
  text-decoration-color: rgb(238 242 255 / 0.65) !important;
}

.tw-decoration-indigo-50\/70 {
  text-decoration-color: rgb(238 242 255 / 0.7) !important;
}

.tw-decoration-indigo-50\/75 {
  text-decoration-color: rgb(238 242 255 / 0.75) !important;
}

.tw-decoration-indigo-50\/80 {
  text-decoration-color: rgb(238 242 255 / 0.8) !important;
}

.tw-decoration-indigo-50\/85 {
  text-decoration-color: rgb(238 242 255 / 0.85) !important;
}

.tw-decoration-indigo-50\/90 {
  text-decoration-color: rgb(238 242 255 / 0.9) !important;
}

.tw-decoration-indigo-50\/95 {
  text-decoration-color: rgb(238 242 255 / 0.95) !important;
}

.tw-decoration-indigo-500 {
  text-decoration-color: #6366f1 !important;
}

.tw-decoration-indigo-500\/0 {
  text-decoration-color: rgb(99 102 241 / 0) !important;
}

.tw-decoration-indigo-500\/10 {
  text-decoration-color: rgb(99 102 241 / 0.1) !important;
}

.tw-decoration-indigo-500\/100 {
  text-decoration-color: rgb(99 102 241 / 1) !important;
}

.tw-decoration-indigo-500\/15 {
  text-decoration-color: rgb(99 102 241 / 0.15) !important;
}

.tw-decoration-indigo-500\/20 {
  text-decoration-color: rgb(99 102 241 / 0.2) !important;
}

.tw-decoration-indigo-500\/25 {
  text-decoration-color: rgb(99 102 241 / 0.25) !important;
}

.tw-decoration-indigo-500\/30 {
  text-decoration-color: rgb(99 102 241 / 0.3) !important;
}

.tw-decoration-indigo-500\/35 {
  text-decoration-color: rgb(99 102 241 / 0.35) !important;
}

.tw-decoration-indigo-500\/40 {
  text-decoration-color: rgb(99 102 241 / 0.4) !important;
}

.tw-decoration-indigo-500\/45 {
  text-decoration-color: rgb(99 102 241 / 0.45) !important;
}

.tw-decoration-indigo-500\/5 {
  text-decoration-color: rgb(99 102 241 / 0.05) !important;
}

.tw-decoration-indigo-500\/50 {
  text-decoration-color: rgb(99 102 241 / 0.5) !important;
}

.tw-decoration-indigo-500\/55 {
  text-decoration-color: rgb(99 102 241 / 0.55) !important;
}

.tw-decoration-indigo-500\/60 {
  text-decoration-color: rgb(99 102 241 / 0.6) !important;
}

.tw-decoration-indigo-500\/65 {
  text-decoration-color: rgb(99 102 241 / 0.65) !important;
}

.tw-decoration-indigo-500\/70 {
  text-decoration-color: rgb(99 102 241 / 0.7) !important;
}

.tw-decoration-indigo-500\/75 {
  text-decoration-color: rgb(99 102 241 / 0.75) !important;
}

.tw-decoration-indigo-500\/80 {
  text-decoration-color: rgb(99 102 241 / 0.8) !important;
}

.tw-decoration-indigo-500\/85 {
  text-decoration-color: rgb(99 102 241 / 0.85) !important;
}

.tw-decoration-indigo-500\/90 {
  text-decoration-color: rgb(99 102 241 / 0.9) !important;
}

.tw-decoration-indigo-500\/95 {
  text-decoration-color: rgb(99 102 241 / 0.95) !important;
}

.tw-decoration-indigo-600 {
  text-decoration-color: #4f46e5 !important;
}

.tw-decoration-indigo-600\/0 {
  text-decoration-color: rgb(79 70 229 / 0) !important;
}

.tw-decoration-indigo-600\/10 {
  text-decoration-color: rgb(79 70 229 / 0.1) !important;
}

.tw-decoration-indigo-600\/100 {
  text-decoration-color: rgb(79 70 229 / 1) !important;
}

.tw-decoration-indigo-600\/15 {
  text-decoration-color: rgb(79 70 229 / 0.15) !important;
}

.tw-decoration-indigo-600\/20 {
  text-decoration-color: rgb(79 70 229 / 0.2) !important;
}

.tw-decoration-indigo-600\/25 {
  text-decoration-color: rgb(79 70 229 / 0.25) !important;
}

.tw-decoration-indigo-600\/30 {
  text-decoration-color: rgb(79 70 229 / 0.3) !important;
}

.tw-decoration-indigo-600\/35 {
  text-decoration-color: rgb(79 70 229 / 0.35) !important;
}

.tw-decoration-indigo-600\/40 {
  text-decoration-color: rgb(79 70 229 / 0.4) !important;
}

.tw-decoration-indigo-600\/45 {
  text-decoration-color: rgb(79 70 229 / 0.45) !important;
}

.tw-decoration-indigo-600\/5 {
  text-decoration-color: rgb(79 70 229 / 0.05) !important;
}

.tw-decoration-indigo-600\/50 {
  text-decoration-color: rgb(79 70 229 / 0.5) !important;
}

.tw-decoration-indigo-600\/55 {
  text-decoration-color: rgb(79 70 229 / 0.55) !important;
}

.tw-decoration-indigo-600\/60 {
  text-decoration-color: rgb(79 70 229 / 0.6) !important;
}

.tw-decoration-indigo-600\/65 {
  text-decoration-color: rgb(79 70 229 / 0.65) !important;
}

.tw-decoration-indigo-600\/70 {
  text-decoration-color: rgb(79 70 229 / 0.7) !important;
}

.tw-decoration-indigo-600\/75 {
  text-decoration-color: rgb(79 70 229 / 0.75) !important;
}

.tw-decoration-indigo-600\/80 {
  text-decoration-color: rgb(79 70 229 / 0.8) !important;
}

.tw-decoration-indigo-600\/85 {
  text-decoration-color: rgb(79 70 229 / 0.85) !important;
}

.tw-decoration-indigo-600\/90 {
  text-decoration-color: rgb(79 70 229 / 0.9) !important;
}

.tw-decoration-indigo-600\/95 {
  text-decoration-color: rgb(79 70 229 / 0.95) !important;
}

.tw-decoration-indigo-700 {
  text-decoration-color: #4338ca !important;
}

.tw-decoration-indigo-700\/0 {
  text-decoration-color: rgb(67 56 202 / 0) !important;
}

.tw-decoration-indigo-700\/10 {
  text-decoration-color: rgb(67 56 202 / 0.1) !important;
}

.tw-decoration-indigo-700\/100 {
  text-decoration-color: rgb(67 56 202 / 1) !important;
}

.tw-decoration-indigo-700\/15 {
  text-decoration-color: rgb(67 56 202 / 0.15) !important;
}

.tw-decoration-indigo-700\/20 {
  text-decoration-color: rgb(67 56 202 / 0.2) !important;
}

.tw-decoration-indigo-700\/25 {
  text-decoration-color: rgb(67 56 202 / 0.25) !important;
}

.tw-decoration-indigo-700\/30 {
  text-decoration-color: rgb(67 56 202 / 0.3) !important;
}

.tw-decoration-indigo-700\/35 {
  text-decoration-color: rgb(67 56 202 / 0.35) !important;
}

.tw-decoration-indigo-700\/40 {
  text-decoration-color: rgb(67 56 202 / 0.4) !important;
}

.tw-decoration-indigo-700\/45 {
  text-decoration-color: rgb(67 56 202 / 0.45) !important;
}

.tw-decoration-indigo-700\/5 {
  text-decoration-color: rgb(67 56 202 / 0.05) !important;
}

.tw-decoration-indigo-700\/50 {
  text-decoration-color: rgb(67 56 202 / 0.5) !important;
}

.tw-decoration-indigo-700\/55 {
  text-decoration-color: rgb(67 56 202 / 0.55) !important;
}

.tw-decoration-indigo-700\/60 {
  text-decoration-color: rgb(67 56 202 / 0.6) !important;
}

.tw-decoration-indigo-700\/65 {
  text-decoration-color: rgb(67 56 202 / 0.65) !important;
}

.tw-decoration-indigo-700\/70 {
  text-decoration-color: rgb(67 56 202 / 0.7) !important;
}

.tw-decoration-indigo-700\/75 {
  text-decoration-color: rgb(67 56 202 / 0.75) !important;
}

.tw-decoration-indigo-700\/80 {
  text-decoration-color: rgb(67 56 202 / 0.8) !important;
}

.tw-decoration-indigo-700\/85 {
  text-decoration-color: rgb(67 56 202 / 0.85) !important;
}

.tw-decoration-indigo-700\/90 {
  text-decoration-color: rgb(67 56 202 / 0.9) !important;
}

.tw-decoration-indigo-700\/95 {
  text-decoration-color: rgb(67 56 202 / 0.95) !important;
}

.tw-decoration-indigo-800 {
  text-decoration-color: #3730a3 !important;
}

.tw-decoration-indigo-800\/0 {
  text-decoration-color: rgb(55 48 163 / 0) !important;
}

.tw-decoration-indigo-800\/10 {
  text-decoration-color: rgb(55 48 163 / 0.1) !important;
}

.tw-decoration-indigo-800\/100 {
  text-decoration-color: rgb(55 48 163 / 1) !important;
}

.tw-decoration-indigo-800\/15 {
  text-decoration-color: rgb(55 48 163 / 0.15) !important;
}

.tw-decoration-indigo-800\/20 {
  text-decoration-color: rgb(55 48 163 / 0.2) !important;
}

.tw-decoration-indigo-800\/25 {
  text-decoration-color: rgb(55 48 163 / 0.25) !important;
}

.tw-decoration-indigo-800\/30 {
  text-decoration-color: rgb(55 48 163 / 0.3) !important;
}

.tw-decoration-indigo-800\/35 {
  text-decoration-color: rgb(55 48 163 / 0.35) !important;
}

.tw-decoration-indigo-800\/40 {
  text-decoration-color: rgb(55 48 163 / 0.4) !important;
}

.tw-decoration-indigo-800\/45 {
  text-decoration-color: rgb(55 48 163 / 0.45) !important;
}

.tw-decoration-indigo-800\/5 {
  text-decoration-color: rgb(55 48 163 / 0.05) !important;
}

.tw-decoration-indigo-800\/50 {
  text-decoration-color: rgb(55 48 163 / 0.5) !important;
}

.tw-decoration-indigo-800\/55 {
  text-decoration-color: rgb(55 48 163 / 0.55) !important;
}

.tw-decoration-indigo-800\/60 {
  text-decoration-color: rgb(55 48 163 / 0.6) !important;
}

.tw-decoration-indigo-800\/65 {
  text-decoration-color: rgb(55 48 163 / 0.65) !important;
}

.tw-decoration-indigo-800\/70 {
  text-decoration-color: rgb(55 48 163 / 0.7) !important;
}

.tw-decoration-indigo-800\/75 {
  text-decoration-color: rgb(55 48 163 / 0.75) !important;
}

.tw-decoration-indigo-800\/80 {
  text-decoration-color: rgb(55 48 163 / 0.8) !important;
}

.tw-decoration-indigo-800\/85 {
  text-decoration-color: rgb(55 48 163 / 0.85) !important;
}

.tw-decoration-indigo-800\/90 {
  text-decoration-color: rgb(55 48 163 / 0.9) !important;
}

.tw-decoration-indigo-800\/95 {
  text-decoration-color: rgb(55 48 163 / 0.95) !important;
}

.tw-decoration-indigo-900 {
  text-decoration-color: #312e81 !important;
}

.tw-decoration-indigo-900\/0 {
  text-decoration-color: rgb(49 46 129 / 0) !important;
}

.tw-decoration-indigo-900\/10 {
  text-decoration-color: rgb(49 46 129 / 0.1) !important;
}

.tw-decoration-indigo-900\/100 {
  text-decoration-color: rgb(49 46 129 / 1) !important;
}

.tw-decoration-indigo-900\/15 {
  text-decoration-color: rgb(49 46 129 / 0.15) !important;
}

.tw-decoration-indigo-900\/20 {
  text-decoration-color: rgb(49 46 129 / 0.2) !important;
}

.tw-decoration-indigo-900\/25 {
  text-decoration-color: rgb(49 46 129 / 0.25) !important;
}

.tw-decoration-indigo-900\/30 {
  text-decoration-color: rgb(49 46 129 / 0.3) !important;
}

.tw-decoration-indigo-900\/35 {
  text-decoration-color: rgb(49 46 129 / 0.35) !important;
}

.tw-decoration-indigo-900\/40 {
  text-decoration-color: rgb(49 46 129 / 0.4) !important;
}

.tw-decoration-indigo-900\/45 {
  text-decoration-color: rgb(49 46 129 / 0.45) !important;
}

.tw-decoration-indigo-900\/5 {
  text-decoration-color: rgb(49 46 129 / 0.05) !important;
}

.tw-decoration-indigo-900\/50 {
  text-decoration-color: rgb(49 46 129 / 0.5) !important;
}

.tw-decoration-indigo-900\/55 {
  text-decoration-color: rgb(49 46 129 / 0.55) !important;
}

.tw-decoration-indigo-900\/60 {
  text-decoration-color: rgb(49 46 129 / 0.6) !important;
}

.tw-decoration-indigo-900\/65 {
  text-decoration-color: rgb(49 46 129 / 0.65) !important;
}

.tw-decoration-indigo-900\/70 {
  text-decoration-color: rgb(49 46 129 / 0.7) !important;
}

.tw-decoration-indigo-900\/75 {
  text-decoration-color: rgb(49 46 129 / 0.75) !important;
}

.tw-decoration-indigo-900\/80 {
  text-decoration-color: rgb(49 46 129 / 0.8) !important;
}

.tw-decoration-indigo-900\/85 {
  text-decoration-color: rgb(49 46 129 / 0.85) !important;
}

.tw-decoration-indigo-900\/90 {
  text-decoration-color: rgb(49 46 129 / 0.9) !important;
}

.tw-decoration-indigo-900\/95 {
  text-decoration-color: rgb(49 46 129 / 0.95) !important;
}

.tw-decoration-indigo-950 {
  text-decoration-color: #1e1b4b !important;
}

.tw-decoration-indigo-950\/0 {
  text-decoration-color: rgb(30 27 75 / 0) !important;
}

.tw-decoration-indigo-950\/10 {
  text-decoration-color: rgb(30 27 75 / 0.1) !important;
}

.tw-decoration-indigo-950\/100 {
  text-decoration-color: rgb(30 27 75 / 1) !important;
}

.tw-decoration-indigo-950\/15 {
  text-decoration-color: rgb(30 27 75 / 0.15) !important;
}

.tw-decoration-indigo-950\/20 {
  text-decoration-color: rgb(30 27 75 / 0.2) !important;
}

.tw-decoration-indigo-950\/25 {
  text-decoration-color: rgb(30 27 75 / 0.25) !important;
}

.tw-decoration-indigo-950\/30 {
  text-decoration-color: rgb(30 27 75 / 0.3) !important;
}

.tw-decoration-indigo-950\/35 {
  text-decoration-color: rgb(30 27 75 / 0.35) !important;
}

.tw-decoration-indigo-950\/40 {
  text-decoration-color: rgb(30 27 75 / 0.4) !important;
}

.tw-decoration-indigo-950\/45 {
  text-decoration-color: rgb(30 27 75 / 0.45) !important;
}

.tw-decoration-indigo-950\/5 {
  text-decoration-color: rgb(30 27 75 / 0.05) !important;
}

.tw-decoration-indigo-950\/50 {
  text-decoration-color: rgb(30 27 75 / 0.5) !important;
}

.tw-decoration-indigo-950\/55 {
  text-decoration-color: rgb(30 27 75 / 0.55) !important;
}

.tw-decoration-indigo-950\/60 {
  text-decoration-color: rgb(30 27 75 / 0.6) !important;
}

.tw-decoration-indigo-950\/65 {
  text-decoration-color: rgb(30 27 75 / 0.65) !important;
}

.tw-decoration-indigo-950\/70 {
  text-decoration-color: rgb(30 27 75 / 0.7) !important;
}

.tw-decoration-indigo-950\/75 {
  text-decoration-color: rgb(30 27 75 / 0.75) !important;
}

.tw-decoration-indigo-950\/80 {
  text-decoration-color: rgb(30 27 75 / 0.8) !important;
}

.tw-decoration-indigo-950\/85 {
  text-decoration-color: rgb(30 27 75 / 0.85) !important;
}

.tw-decoration-indigo-950\/90 {
  text-decoration-color: rgb(30 27 75 / 0.9) !important;
}

.tw-decoration-indigo-950\/95 {
  text-decoration-color: rgb(30 27 75 / 0.95) !important;
}

.tw-decoration-inherit {
  text-decoration-color: inherit !important;
}

.tw-decoration-lime-100 {
  text-decoration-color: #ecfccb !important;
}

.tw-decoration-lime-100\/0 {
  text-decoration-color: rgb(236 252 203 / 0) !important;
}

.tw-decoration-lime-100\/10 {
  text-decoration-color: rgb(236 252 203 / 0.1) !important;
}

.tw-decoration-lime-100\/100 {
  text-decoration-color: rgb(236 252 203 / 1) !important;
}

.tw-decoration-lime-100\/15 {
  text-decoration-color: rgb(236 252 203 / 0.15) !important;
}

.tw-decoration-lime-100\/20 {
  text-decoration-color: rgb(236 252 203 / 0.2) !important;
}

.tw-decoration-lime-100\/25 {
  text-decoration-color: rgb(236 252 203 / 0.25) !important;
}

.tw-decoration-lime-100\/30 {
  text-decoration-color: rgb(236 252 203 / 0.3) !important;
}

.tw-decoration-lime-100\/35 {
  text-decoration-color: rgb(236 252 203 / 0.35) !important;
}

.tw-decoration-lime-100\/40 {
  text-decoration-color: rgb(236 252 203 / 0.4) !important;
}

.tw-decoration-lime-100\/45 {
  text-decoration-color: rgb(236 252 203 / 0.45) !important;
}

.tw-decoration-lime-100\/5 {
  text-decoration-color: rgb(236 252 203 / 0.05) !important;
}

.tw-decoration-lime-100\/50 {
  text-decoration-color: rgb(236 252 203 / 0.5) !important;
}

.tw-decoration-lime-100\/55 {
  text-decoration-color: rgb(236 252 203 / 0.55) !important;
}

.tw-decoration-lime-100\/60 {
  text-decoration-color: rgb(236 252 203 / 0.6) !important;
}

.tw-decoration-lime-100\/65 {
  text-decoration-color: rgb(236 252 203 / 0.65) !important;
}

.tw-decoration-lime-100\/70 {
  text-decoration-color: rgb(236 252 203 / 0.7) !important;
}

.tw-decoration-lime-100\/75 {
  text-decoration-color: rgb(236 252 203 / 0.75) !important;
}

.tw-decoration-lime-100\/80 {
  text-decoration-color: rgb(236 252 203 / 0.8) !important;
}

.tw-decoration-lime-100\/85 {
  text-decoration-color: rgb(236 252 203 / 0.85) !important;
}

.tw-decoration-lime-100\/90 {
  text-decoration-color: rgb(236 252 203 / 0.9) !important;
}

.tw-decoration-lime-100\/95 {
  text-decoration-color: rgb(236 252 203 / 0.95) !important;
}

.tw-decoration-lime-200 {
  text-decoration-color: #d9f99d !important;
}

.tw-decoration-lime-200\/0 {
  text-decoration-color: rgb(217 249 157 / 0) !important;
}

.tw-decoration-lime-200\/10 {
  text-decoration-color: rgb(217 249 157 / 0.1) !important;
}

.tw-decoration-lime-200\/100 {
  text-decoration-color: rgb(217 249 157 / 1) !important;
}

.tw-decoration-lime-200\/15 {
  text-decoration-color: rgb(217 249 157 / 0.15) !important;
}

.tw-decoration-lime-200\/20 {
  text-decoration-color: rgb(217 249 157 / 0.2) !important;
}

.tw-decoration-lime-200\/25 {
  text-decoration-color: rgb(217 249 157 / 0.25) !important;
}

.tw-decoration-lime-200\/30 {
  text-decoration-color: rgb(217 249 157 / 0.3) !important;
}

.tw-decoration-lime-200\/35 {
  text-decoration-color: rgb(217 249 157 / 0.35) !important;
}

.tw-decoration-lime-200\/40 {
  text-decoration-color: rgb(217 249 157 / 0.4) !important;
}

.tw-decoration-lime-200\/45 {
  text-decoration-color: rgb(217 249 157 / 0.45) !important;
}

.tw-decoration-lime-200\/5 {
  text-decoration-color: rgb(217 249 157 / 0.05) !important;
}

.tw-decoration-lime-200\/50 {
  text-decoration-color: rgb(217 249 157 / 0.5) !important;
}

.tw-decoration-lime-200\/55 {
  text-decoration-color: rgb(217 249 157 / 0.55) !important;
}

.tw-decoration-lime-200\/60 {
  text-decoration-color: rgb(217 249 157 / 0.6) !important;
}

.tw-decoration-lime-200\/65 {
  text-decoration-color: rgb(217 249 157 / 0.65) !important;
}

.tw-decoration-lime-200\/70 {
  text-decoration-color: rgb(217 249 157 / 0.7) !important;
}

.tw-decoration-lime-200\/75 {
  text-decoration-color: rgb(217 249 157 / 0.75) !important;
}

.tw-decoration-lime-200\/80 {
  text-decoration-color: rgb(217 249 157 / 0.8) !important;
}

.tw-decoration-lime-200\/85 {
  text-decoration-color: rgb(217 249 157 / 0.85) !important;
}

.tw-decoration-lime-200\/90 {
  text-decoration-color: rgb(217 249 157 / 0.9) !important;
}

.tw-decoration-lime-200\/95 {
  text-decoration-color: rgb(217 249 157 / 0.95) !important;
}

.tw-decoration-lime-300 {
  text-decoration-color: #bef264 !important;
}

.tw-decoration-lime-300\/0 {
  text-decoration-color: rgb(190 242 100 / 0) !important;
}

.tw-decoration-lime-300\/10 {
  text-decoration-color: rgb(190 242 100 / 0.1) !important;
}

.tw-decoration-lime-300\/100 {
  text-decoration-color: rgb(190 242 100 / 1) !important;
}

.tw-decoration-lime-300\/15 {
  text-decoration-color: rgb(190 242 100 / 0.15) !important;
}

.tw-decoration-lime-300\/20 {
  text-decoration-color: rgb(190 242 100 / 0.2) !important;
}

.tw-decoration-lime-300\/25 {
  text-decoration-color: rgb(190 242 100 / 0.25) !important;
}

.tw-decoration-lime-300\/30 {
  text-decoration-color: rgb(190 242 100 / 0.3) !important;
}

.tw-decoration-lime-300\/35 {
  text-decoration-color: rgb(190 242 100 / 0.35) !important;
}

.tw-decoration-lime-300\/40 {
  text-decoration-color: rgb(190 242 100 / 0.4) !important;
}

.tw-decoration-lime-300\/45 {
  text-decoration-color: rgb(190 242 100 / 0.45) !important;
}

.tw-decoration-lime-300\/5 {
  text-decoration-color: rgb(190 242 100 / 0.05) !important;
}

.tw-decoration-lime-300\/50 {
  text-decoration-color: rgb(190 242 100 / 0.5) !important;
}

.tw-decoration-lime-300\/55 {
  text-decoration-color: rgb(190 242 100 / 0.55) !important;
}

.tw-decoration-lime-300\/60 {
  text-decoration-color: rgb(190 242 100 / 0.6) !important;
}

.tw-decoration-lime-300\/65 {
  text-decoration-color: rgb(190 242 100 / 0.65) !important;
}

.tw-decoration-lime-300\/70 {
  text-decoration-color: rgb(190 242 100 / 0.7) !important;
}

.tw-decoration-lime-300\/75 {
  text-decoration-color: rgb(190 242 100 / 0.75) !important;
}

.tw-decoration-lime-300\/80 {
  text-decoration-color: rgb(190 242 100 / 0.8) !important;
}

.tw-decoration-lime-300\/85 {
  text-decoration-color: rgb(190 242 100 / 0.85) !important;
}

.tw-decoration-lime-300\/90 {
  text-decoration-color: rgb(190 242 100 / 0.9) !important;
}

.tw-decoration-lime-300\/95 {
  text-decoration-color: rgb(190 242 100 / 0.95) !important;
}

.tw-decoration-lime-400 {
  text-decoration-color: #a3e635 !important;
}

.tw-decoration-lime-400\/0 {
  text-decoration-color: rgb(163 230 53 / 0) !important;
}

.tw-decoration-lime-400\/10 {
  text-decoration-color: rgb(163 230 53 / 0.1) !important;
}

.tw-decoration-lime-400\/100 {
  text-decoration-color: rgb(163 230 53 / 1) !important;
}

.tw-decoration-lime-400\/15 {
  text-decoration-color: rgb(163 230 53 / 0.15) !important;
}

.tw-decoration-lime-400\/20 {
  text-decoration-color: rgb(163 230 53 / 0.2) !important;
}

.tw-decoration-lime-400\/25 {
  text-decoration-color: rgb(163 230 53 / 0.25) !important;
}

.tw-decoration-lime-400\/30 {
  text-decoration-color: rgb(163 230 53 / 0.3) !important;
}

.tw-decoration-lime-400\/35 {
  text-decoration-color: rgb(163 230 53 / 0.35) !important;
}

.tw-decoration-lime-400\/40 {
  text-decoration-color: rgb(163 230 53 / 0.4) !important;
}

.tw-decoration-lime-400\/45 {
  text-decoration-color: rgb(163 230 53 / 0.45) !important;
}

.tw-decoration-lime-400\/5 {
  text-decoration-color: rgb(163 230 53 / 0.05) !important;
}

.tw-decoration-lime-400\/50 {
  text-decoration-color: rgb(163 230 53 / 0.5) !important;
}

.tw-decoration-lime-400\/55 {
  text-decoration-color: rgb(163 230 53 / 0.55) !important;
}

.tw-decoration-lime-400\/60 {
  text-decoration-color: rgb(163 230 53 / 0.6) !important;
}

.tw-decoration-lime-400\/65 {
  text-decoration-color: rgb(163 230 53 / 0.65) !important;
}

.tw-decoration-lime-400\/70 {
  text-decoration-color: rgb(163 230 53 / 0.7) !important;
}

.tw-decoration-lime-400\/75 {
  text-decoration-color: rgb(163 230 53 / 0.75) !important;
}

.tw-decoration-lime-400\/80 {
  text-decoration-color: rgb(163 230 53 / 0.8) !important;
}

.tw-decoration-lime-400\/85 {
  text-decoration-color: rgb(163 230 53 / 0.85) !important;
}

.tw-decoration-lime-400\/90 {
  text-decoration-color: rgb(163 230 53 / 0.9) !important;
}

.tw-decoration-lime-400\/95 {
  text-decoration-color: rgb(163 230 53 / 0.95) !important;
}

.tw-decoration-lime-50 {
  text-decoration-color: #f7fee7 !important;
}

.tw-decoration-lime-50\/0 {
  text-decoration-color: rgb(247 254 231 / 0) !important;
}

.tw-decoration-lime-50\/10 {
  text-decoration-color: rgb(247 254 231 / 0.1) !important;
}

.tw-decoration-lime-50\/100 {
  text-decoration-color: rgb(247 254 231 / 1) !important;
}

.tw-decoration-lime-50\/15 {
  text-decoration-color: rgb(247 254 231 / 0.15) !important;
}

.tw-decoration-lime-50\/20 {
  text-decoration-color: rgb(247 254 231 / 0.2) !important;
}

.tw-decoration-lime-50\/25 {
  text-decoration-color: rgb(247 254 231 / 0.25) !important;
}

.tw-decoration-lime-50\/30 {
  text-decoration-color: rgb(247 254 231 / 0.3) !important;
}

.tw-decoration-lime-50\/35 {
  text-decoration-color: rgb(247 254 231 / 0.35) !important;
}

.tw-decoration-lime-50\/40 {
  text-decoration-color: rgb(247 254 231 / 0.4) !important;
}

.tw-decoration-lime-50\/45 {
  text-decoration-color: rgb(247 254 231 / 0.45) !important;
}

.tw-decoration-lime-50\/5 {
  text-decoration-color: rgb(247 254 231 / 0.05) !important;
}

.tw-decoration-lime-50\/50 {
  text-decoration-color: rgb(247 254 231 / 0.5) !important;
}

.tw-decoration-lime-50\/55 {
  text-decoration-color: rgb(247 254 231 / 0.55) !important;
}

.tw-decoration-lime-50\/60 {
  text-decoration-color: rgb(247 254 231 / 0.6) !important;
}

.tw-decoration-lime-50\/65 {
  text-decoration-color: rgb(247 254 231 / 0.65) !important;
}

.tw-decoration-lime-50\/70 {
  text-decoration-color: rgb(247 254 231 / 0.7) !important;
}

.tw-decoration-lime-50\/75 {
  text-decoration-color: rgb(247 254 231 / 0.75) !important;
}

.tw-decoration-lime-50\/80 {
  text-decoration-color: rgb(247 254 231 / 0.8) !important;
}

.tw-decoration-lime-50\/85 {
  text-decoration-color: rgb(247 254 231 / 0.85) !important;
}

.tw-decoration-lime-50\/90 {
  text-decoration-color: rgb(247 254 231 / 0.9) !important;
}

.tw-decoration-lime-50\/95 {
  text-decoration-color: rgb(247 254 231 / 0.95) !important;
}

.tw-decoration-lime-500 {
  text-decoration-color: #84cc16 !important;
}

.tw-decoration-lime-500\/0 {
  text-decoration-color: rgb(132 204 22 / 0) !important;
}

.tw-decoration-lime-500\/10 {
  text-decoration-color: rgb(132 204 22 / 0.1) !important;
}

.tw-decoration-lime-500\/100 {
  text-decoration-color: rgb(132 204 22 / 1) !important;
}

.tw-decoration-lime-500\/15 {
  text-decoration-color: rgb(132 204 22 / 0.15) !important;
}

.tw-decoration-lime-500\/20 {
  text-decoration-color: rgb(132 204 22 / 0.2) !important;
}

.tw-decoration-lime-500\/25 {
  text-decoration-color: rgb(132 204 22 / 0.25) !important;
}

.tw-decoration-lime-500\/30 {
  text-decoration-color: rgb(132 204 22 / 0.3) !important;
}

.tw-decoration-lime-500\/35 {
  text-decoration-color: rgb(132 204 22 / 0.35) !important;
}

.tw-decoration-lime-500\/40 {
  text-decoration-color: rgb(132 204 22 / 0.4) !important;
}

.tw-decoration-lime-500\/45 {
  text-decoration-color: rgb(132 204 22 / 0.45) !important;
}

.tw-decoration-lime-500\/5 {
  text-decoration-color: rgb(132 204 22 / 0.05) !important;
}

.tw-decoration-lime-500\/50 {
  text-decoration-color: rgb(132 204 22 / 0.5) !important;
}

.tw-decoration-lime-500\/55 {
  text-decoration-color: rgb(132 204 22 / 0.55) !important;
}

.tw-decoration-lime-500\/60 {
  text-decoration-color: rgb(132 204 22 / 0.6) !important;
}

.tw-decoration-lime-500\/65 {
  text-decoration-color: rgb(132 204 22 / 0.65) !important;
}

.tw-decoration-lime-500\/70 {
  text-decoration-color: rgb(132 204 22 / 0.7) !important;
}

.tw-decoration-lime-500\/75 {
  text-decoration-color: rgb(132 204 22 / 0.75) !important;
}

.tw-decoration-lime-500\/80 {
  text-decoration-color: rgb(132 204 22 / 0.8) !important;
}

.tw-decoration-lime-500\/85 {
  text-decoration-color: rgb(132 204 22 / 0.85) !important;
}

.tw-decoration-lime-500\/90 {
  text-decoration-color: rgb(132 204 22 / 0.9) !important;
}

.tw-decoration-lime-500\/95 {
  text-decoration-color: rgb(132 204 22 / 0.95) !important;
}

.tw-decoration-lime-600 {
  text-decoration-color: #65a30d !important;
}

.tw-decoration-lime-600\/0 {
  text-decoration-color: rgb(101 163 13 / 0) !important;
}

.tw-decoration-lime-600\/10 {
  text-decoration-color: rgb(101 163 13 / 0.1) !important;
}

.tw-decoration-lime-600\/100 {
  text-decoration-color: rgb(101 163 13 / 1) !important;
}

.tw-decoration-lime-600\/15 {
  text-decoration-color: rgb(101 163 13 / 0.15) !important;
}

.tw-decoration-lime-600\/20 {
  text-decoration-color: rgb(101 163 13 / 0.2) !important;
}

.tw-decoration-lime-600\/25 {
  text-decoration-color: rgb(101 163 13 / 0.25) !important;
}

.tw-decoration-lime-600\/30 {
  text-decoration-color: rgb(101 163 13 / 0.3) !important;
}

.tw-decoration-lime-600\/35 {
  text-decoration-color: rgb(101 163 13 / 0.35) !important;
}

.tw-decoration-lime-600\/40 {
  text-decoration-color: rgb(101 163 13 / 0.4) !important;
}

.tw-decoration-lime-600\/45 {
  text-decoration-color: rgb(101 163 13 / 0.45) !important;
}

.tw-decoration-lime-600\/5 {
  text-decoration-color: rgb(101 163 13 / 0.05) !important;
}

.tw-decoration-lime-600\/50 {
  text-decoration-color: rgb(101 163 13 / 0.5) !important;
}

.tw-decoration-lime-600\/55 {
  text-decoration-color: rgb(101 163 13 / 0.55) !important;
}

.tw-decoration-lime-600\/60 {
  text-decoration-color: rgb(101 163 13 / 0.6) !important;
}

.tw-decoration-lime-600\/65 {
  text-decoration-color: rgb(101 163 13 / 0.65) !important;
}

.tw-decoration-lime-600\/70 {
  text-decoration-color: rgb(101 163 13 / 0.7) !important;
}

.tw-decoration-lime-600\/75 {
  text-decoration-color: rgb(101 163 13 / 0.75) !important;
}

.tw-decoration-lime-600\/80 {
  text-decoration-color: rgb(101 163 13 / 0.8) !important;
}

.tw-decoration-lime-600\/85 {
  text-decoration-color: rgb(101 163 13 / 0.85) !important;
}

.tw-decoration-lime-600\/90 {
  text-decoration-color: rgb(101 163 13 / 0.9) !important;
}

.tw-decoration-lime-600\/95 {
  text-decoration-color: rgb(101 163 13 / 0.95) !important;
}

.tw-decoration-lime-700 {
  text-decoration-color: #4d7c0f !important;
}

.tw-decoration-lime-700\/0 {
  text-decoration-color: rgb(77 124 15 / 0) !important;
}

.tw-decoration-lime-700\/10 {
  text-decoration-color: rgb(77 124 15 / 0.1) !important;
}

.tw-decoration-lime-700\/100 {
  text-decoration-color: rgb(77 124 15 / 1) !important;
}

.tw-decoration-lime-700\/15 {
  text-decoration-color: rgb(77 124 15 / 0.15) !important;
}

.tw-decoration-lime-700\/20 {
  text-decoration-color: rgb(77 124 15 / 0.2) !important;
}

.tw-decoration-lime-700\/25 {
  text-decoration-color: rgb(77 124 15 / 0.25) !important;
}

.tw-decoration-lime-700\/30 {
  text-decoration-color: rgb(77 124 15 / 0.3) !important;
}

.tw-decoration-lime-700\/35 {
  text-decoration-color: rgb(77 124 15 / 0.35) !important;
}

.tw-decoration-lime-700\/40 {
  text-decoration-color: rgb(77 124 15 / 0.4) !important;
}

.tw-decoration-lime-700\/45 {
  text-decoration-color: rgb(77 124 15 / 0.45) !important;
}

.tw-decoration-lime-700\/5 {
  text-decoration-color: rgb(77 124 15 / 0.05) !important;
}

.tw-decoration-lime-700\/50 {
  text-decoration-color: rgb(77 124 15 / 0.5) !important;
}

.tw-decoration-lime-700\/55 {
  text-decoration-color: rgb(77 124 15 / 0.55) !important;
}

.tw-decoration-lime-700\/60 {
  text-decoration-color: rgb(77 124 15 / 0.6) !important;
}

.tw-decoration-lime-700\/65 {
  text-decoration-color: rgb(77 124 15 / 0.65) !important;
}

.tw-decoration-lime-700\/70 {
  text-decoration-color: rgb(77 124 15 / 0.7) !important;
}

.tw-decoration-lime-700\/75 {
  text-decoration-color: rgb(77 124 15 / 0.75) !important;
}

.tw-decoration-lime-700\/80 {
  text-decoration-color: rgb(77 124 15 / 0.8) !important;
}

.tw-decoration-lime-700\/85 {
  text-decoration-color: rgb(77 124 15 / 0.85) !important;
}

.tw-decoration-lime-700\/90 {
  text-decoration-color: rgb(77 124 15 / 0.9) !important;
}

.tw-decoration-lime-700\/95 {
  text-decoration-color: rgb(77 124 15 / 0.95) !important;
}

.tw-decoration-lime-800 {
  text-decoration-color: #3f6212 !important;
}

.tw-decoration-lime-800\/0 {
  text-decoration-color: rgb(63 98 18 / 0) !important;
}

.tw-decoration-lime-800\/10 {
  text-decoration-color: rgb(63 98 18 / 0.1) !important;
}

.tw-decoration-lime-800\/100 {
  text-decoration-color: rgb(63 98 18 / 1) !important;
}

.tw-decoration-lime-800\/15 {
  text-decoration-color: rgb(63 98 18 / 0.15) !important;
}

.tw-decoration-lime-800\/20 {
  text-decoration-color: rgb(63 98 18 / 0.2) !important;
}

.tw-decoration-lime-800\/25 {
  text-decoration-color: rgb(63 98 18 / 0.25) !important;
}

.tw-decoration-lime-800\/30 {
  text-decoration-color: rgb(63 98 18 / 0.3) !important;
}

.tw-decoration-lime-800\/35 {
  text-decoration-color: rgb(63 98 18 / 0.35) !important;
}

.tw-decoration-lime-800\/40 {
  text-decoration-color: rgb(63 98 18 / 0.4) !important;
}

.tw-decoration-lime-800\/45 {
  text-decoration-color: rgb(63 98 18 / 0.45) !important;
}

.tw-decoration-lime-800\/5 {
  text-decoration-color: rgb(63 98 18 / 0.05) !important;
}

.tw-decoration-lime-800\/50 {
  text-decoration-color: rgb(63 98 18 / 0.5) !important;
}

.tw-decoration-lime-800\/55 {
  text-decoration-color: rgb(63 98 18 / 0.55) !important;
}

.tw-decoration-lime-800\/60 {
  text-decoration-color: rgb(63 98 18 / 0.6) !important;
}

.tw-decoration-lime-800\/65 {
  text-decoration-color: rgb(63 98 18 / 0.65) !important;
}

.tw-decoration-lime-800\/70 {
  text-decoration-color: rgb(63 98 18 / 0.7) !important;
}

.tw-decoration-lime-800\/75 {
  text-decoration-color: rgb(63 98 18 / 0.75) !important;
}

.tw-decoration-lime-800\/80 {
  text-decoration-color: rgb(63 98 18 / 0.8) !important;
}

.tw-decoration-lime-800\/85 {
  text-decoration-color: rgb(63 98 18 / 0.85) !important;
}

.tw-decoration-lime-800\/90 {
  text-decoration-color: rgb(63 98 18 / 0.9) !important;
}

.tw-decoration-lime-800\/95 {
  text-decoration-color: rgb(63 98 18 / 0.95) !important;
}

.tw-decoration-lime-900 {
  text-decoration-color: #365314 !important;
}

.tw-decoration-lime-900\/0 {
  text-decoration-color: rgb(54 83 20 / 0) !important;
}

.tw-decoration-lime-900\/10 {
  text-decoration-color: rgb(54 83 20 / 0.1) !important;
}

.tw-decoration-lime-900\/100 {
  text-decoration-color: rgb(54 83 20 / 1) !important;
}

.tw-decoration-lime-900\/15 {
  text-decoration-color: rgb(54 83 20 / 0.15) !important;
}

.tw-decoration-lime-900\/20 {
  text-decoration-color: rgb(54 83 20 / 0.2) !important;
}

.tw-decoration-lime-900\/25 {
  text-decoration-color: rgb(54 83 20 / 0.25) !important;
}

.tw-decoration-lime-900\/30 {
  text-decoration-color: rgb(54 83 20 / 0.3) !important;
}

.tw-decoration-lime-900\/35 {
  text-decoration-color: rgb(54 83 20 / 0.35) !important;
}

.tw-decoration-lime-900\/40 {
  text-decoration-color: rgb(54 83 20 / 0.4) !important;
}

.tw-decoration-lime-900\/45 {
  text-decoration-color: rgb(54 83 20 / 0.45) !important;
}

.tw-decoration-lime-900\/5 {
  text-decoration-color: rgb(54 83 20 / 0.05) !important;
}

.tw-decoration-lime-900\/50 {
  text-decoration-color: rgb(54 83 20 / 0.5) !important;
}

.tw-decoration-lime-900\/55 {
  text-decoration-color: rgb(54 83 20 / 0.55) !important;
}

.tw-decoration-lime-900\/60 {
  text-decoration-color: rgb(54 83 20 / 0.6) !important;
}

.tw-decoration-lime-900\/65 {
  text-decoration-color: rgb(54 83 20 / 0.65) !important;
}

.tw-decoration-lime-900\/70 {
  text-decoration-color: rgb(54 83 20 / 0.7) !important;
}

.tw-decoration-lime-900\/75 {
  text-decoration-color: rgb(54 83 20 / 0.75) !important;
}

.tw-decoration-lime-900\/80 {
  text-decoration-color: rgb(54 83 20 / 0.8) !important;
}

.tw-decoration-lime-900\/85 {
  text-decoration-color: rgb(54 83 20 / 0.85) !important;
}

.tw-decoration-lime-900\/90 {
  text-decoration-color: rgb(54 83 20 / 0.9) !important;
}

.tw-decoration-lime-900\/95 {
  text-decoration-color: rgb(54 83 20 / 0.95) !important;
}

.tw-decoration-lime-950 {
  text-decoration-color: #1a2e05 !important;
}

.tw-decoration-lime-950\/0 {
  text-decoration-color: rgb(26 46 5 / 0) !important;
}

.tw-decoration-lime-950\/10 {
  text-decoration-color: rgb(26 46 5 / 0.1) !important;
}

.tw-decoration-lime-950\/100 {
  text-decoration-color: rgb(26 46 5 / 1) !important;
}

.tw-decoration-lime-950\/15 {
  text-decoration-color: rgb(26 46 5 / 0.15) !important;
}

.tw-decoration-lime-950\/20 {
  text-decoration-color: rgb(26 46 5 / 0.2) !important;
}

.tw-decoration-lime-950\/25 {
  text-decoration-color: rgb(26 46 5 / 0.25) !important;
}

.tw-decoration-lime-950\/30 {
  text-decoration-color: rgb(26 46 5 / 0.3) !important;
}

.tw-decoration-lime-950\/35 {
  text-decoration-color: rgb(26 46 5 / 0.35) !important;
}

.tw-decoration-lime-950\/40 {
  text-decoration-color: rgb(26 46 5 / 0.4) !important;
}

.tw-decoration-lime-950\/45 {
  text-decoration-color: rgb(26 46 5 / 0.45) !important;
}

.tw-decoration-lime-950\/5 {
  text-decoration-color: rgb(26 46 5 / 0.05) !important;
}

.tw-decoration-lime-950\/50 {
  text-decoration-color: rgb(26 46 5 / 0.5) !important;
}

.tw-decoration-lime-950\/55 {
  text-decoration-color: rgb(26 46 5 / 0.55) !important;
}

.tw-decoration-lime-950\/60 {
  text-decoration-color: rgb(26 46 5 / 0.6) !important;
}

.tw-decoration-lime-950\/65 {
  text-decoration-color: rgb(26 46 5 / 0.65) !important;
}

.tw-decoration-lime-950\/70 {
  text-decoration-color: rgb(26 46 5 / 0.7) !important;
}

.tw-decoration-lime-950\/75 {
  text-decoration-color: rgb(26 46 5 / 0.75) !important;
}

.tw-decoration-lime-950\/80 {
  text-decoration-color: rgb(26 46 5 / 0.8) !important;
}

.tw-decoration-lime-950\/85 {
  text-decoration-color: rgb(26 46 5 / 0.85) !important;
}

.tw-decoration-lime-950\/90 {
  text-decoration-color: rgb(26 46 5 / 0.9) !important;
}

.tw-decoration-lime-950\/95 {
  text-decoration-color: rgb(26 46 5 / 0.95) !important;
}

.tw-decoration-neutral-100 {
  text-decoration-color: #f5f5f5 !important;
}

.tw-decoration-neutral-100\/0 {
  text-decoration-color: rgb(245 245 245 / 0) !important;
}

.tw-decoration-neutral-100\/10 {
  text-decoration-color: rgb(245 245 245 / 0.1) !important;
}

.tw-decoration-neutral-100\/100 {
  text-decoration-color: rgb(245 245 245 / 1) !important;
}

.tw-decoration-neutral-100\/15 {
  text-decoration-color: rgb(245 245 245 / 0.15) !important;
}

.tw-decoration-neutral-100\/20 {
  text-decoration-color: rgb(245 245 245 / 0.2) !important;
}

.tw-decoration-neutral-100\/25 {
  text-decoration-color: rgb(245 245 245 / 0.25) !important;
}

.tw-decoration-neutral-100\/30 {
  text-decoration-color: rgb(245 245 245 / 0.3) !important;
}

.tw-decoration-neutral-100\/35 {
  text-decoration-color: rgb(245 245 245 / 0.35) !important;
}

.tw-decoration-neutral-100\/40 {
  text-decoration-color: rgb(245 245 245 / 0.4) !important;
}

.tw-decoration-neutral-100\/45 {
  text-decoration-color: rgb(245 245 245 / 0.45) !important;
}

.tw-decoration-neutral-100\/5 {
  text-decoration-color: rgb(245 245 245 / 0.05) !important;
}

.tw-decoration-neutral-100\/50 {
  text-decoration-color: rgb(245 245 245 / 0.5) !important;
}

.tw-decoration-neutral-100\/55 {
  text-decoration-color: rgb(245 245 245 / 0.55) !important;
}

.tw-decoration-neutral-100\/60 {
  text-decoration-color: rgb(245 245 245 / 0.6) !important;
}

.tw-decoration-neutral-100\/65 {
  text-decoration-color: rgb(245 245 245 / 0.65) !important;
}

.tw-decoration-neutral-100\/70 {
  text-decoration-color: rgb(245 245 245 / 0.7) !important;
}

.tw-decoration-neutral-100\/75 {
  text-decoration-color: rgb(245 245 245 / 0.75) !important;
}

.tw-decoration-neutral-100\/80 {
  text-decoration-color: rgb(245 245 245 / 0.8) !important;
}

.tw-decoration-neutral-100\/85 {
  text-decoration-color: rgb(245 245 245 / 0.85) !important;
}

.tw-decoration-neutral-100\/90 {
  text-decoration-color: rgb(245 245 245 / 0.9) !important;
}

.tw-decoration-neutral-100\/95 {
  text-decoration-color: rgb(245 245 245 / 0.95) !important;
}

.tw-decoration-neutral-200 {
  text-decoration-color: #e5e5e5 !important;
}

.tw-decoration-neutral-200\/0 {
  text-decoration-color: rgb(229 229 229 / 0) !important;
}

.tw-decoration-neutral-200\/10 {
  text-decoration-color: rgb(229 229 229 / 0.1) !important;
}

.tw-decoration-neutral-200\/100 {
  text-decoration-color: rgb(229 229 229 / 1) !important;
}

.tw-decoration-neutral-200\/15 {
  text-decoration-color: rgb(229 229 229 / 0.15) !important;
}

.tw-decoration-neutral-200\/20 {
  text-decoration-color: rgb(229 229 229 / 0.2) !important;
}

.tw-decoration-neutral-200\/25 {
  text-decoration-color: rgb(229 229 229 / 0.25) !important;
}

.tw-decoration-neutral-200\/30 {
  text-decoration-color: rgb(229 229 229 / 0.3) !important;
}

.tw-decoration-neutral-200\/35 {
  text-decoration-color: rgb(229 229 229 / 0.35) !important;
}

.tw-decoration-neutral-200\/40 {
  text-decoration-color: rgb(229 229 229 / 0.4) !important;
}

.tw-decoration-neutral-200\/45 {
  text-decoration-color: rgb(229 229 229 / 0.45) !important;
}

.tw-decoration-neutral-200\/5 {
  text-decoration-color: rgb(229 229 229 / 0.05) !important;
}

.tw-decoration-neutral-200\/50 {
  text-decoration-color: rgb(229 229 229 / 0.5) !important;
}

.tw-decoration-neutral-200\/55 {
  text-decoration-color: rgb(229 229 229 / 0.55) !important;
}

.tw-decoration-neutral-200\/60 {
  text-decoration-color: rgb(229 229 229 / 0.6) !important;
}

.tw-decoration-neutral-200\/65 {
  text-decoration-color: rgb(229 229 229 / 0.65) !important;
}

.tw-decoration-neutral-200\/70 {
  text-decoration-color: rgb(229 229 229 / 0.7) !important;
}

.tw-decoration-neutral-200\/75 {
  text-decoration-color: rgb(229 229 229 / 0.75) !important;
}

.tw-decoration-neutral-200\/80 {
  text-decoration-color: rgb(229 229 229 / 0.8) !important;
}

.tw-decoration-neutral-200\/85 {
  text-decoration-color: rgb(229 229 229 / 0.85) !important;
}

.tw-decoration-neutral-200\/90 {
  text-decoration-color: rgb(229 229 229 / 0.9) !important;
}

.tw-decoration-neutral-200\/95 {
  text-decoration-color: rgb(229 229 229 / 0.95) !important;
}

.tw-decoration-neutral-300 {
  text-decoration-color: #d4d4d4 !important;
}

.tw-decoration-neutral-300\/0 {
  text-decoration-color: rgb(212 212 212 / 0) !important;
}

.tw-decoration-neutral-300\/10 {
  text-decoration-color: rgb(212 212 212 / 0.1) !important;
}

.tw-decoration-neutral-300\/100 {
  text-decoration-color: rgb(212 212 212 / 1) !important;
}

.tw-decoration-neutral-300\/15 {
  text-decoration-color: rgb(212 212 212 / 0.15) !important;
}

.tw-decoration-neutral-300\/20 {
  text-decoration-color: rgb(212 212 212 / 0.2) !important;
}

.tw-decoration-neutral-300\/25 {
  text-decoration-color: rgb(212 212 212 / 0.25) !important;
}

.tw-decoration-neutral-300\/30 {
  text-decoration-color: rgb(212 212 212 / 0.3) !important;
}

.tw-decoration-neutral-300\/35 {
  text-decoration-color: rgb(212 212 212 / 0.35) !important;
}

.tw-decoration-neutral-300\/40 {
  text-decoration-color: rgb(212 212 212 / 0.4) !important;
}

.tw-decoration-neutral-300\/45 {
  text-decoration-color: rgb(212 212 212 / 0.45) !important;
}

.tw-decoration-neutral-300\/5 {
  text-decoration-color: rgb(212 212 212 / 0.05) !important;
}

.tw-decoration-neutral-300\/50 {
  text-decoration-color: rgb(212 212 212 / 0.5) !important;
}

.tw-decoration-neutral-300\/55 {
  text-decoration-color: rgb(212 212 212 / 0.55) !important;
}

.tw-decoration-neutral-300\/60 {
  text-decoration-color: rgb(212 212 212 / 0.6) !important;
}

.tw-decoration-neutral-300\/65 {
  text-decoration-color: rgb(212 212 212 / 0.65) !important;
}

.tw-decoration-neutral-300\/70 {
  text-decoration-color: rgb(212 212 212 / 0.7) !important;
}

.tw-decoration-neutral-300\/75 {
  text-decoration-color: rgb(212 212 212 / 0.75) !important;
}

.tw-decoration-neutral-300\/80 {
  text-decoration-color: rgb(212 212 212 / 0.8) !important;
}

.tw-decoration-neutral-300\/85 {
  text-decoration-color: rgb(212 212 212 / 0.85) !important;
}

.tw-decoration-neutral-300\/90 {
  text-decoration-color: rgb(212 212 212 / 0.9) !important;
}

.tw-decoration-neutral-300\/95 {
  text-decoration-color: rgb(212 212 212 / 0.95) !important;
}

.tw-decoration-neutral-400 {
  text-decoration-color: #a3a3a3 !important;
}

.tw-decoration-neutral-400\/0 {
  text-decoration-color: rgb(163 163 163 / 0) !important;
}

.tw-decoration-neutral-400\/10 {
  text-decoration-color: rgb(163 163 163 / 0.1) !important;
}

.tw-decoration-neutral-400\/100 {
  text-decoration-color: rgb(163 163 163 / 1) !important;
}

.tw-decoration-neutral-400\/15 {
  text-decoration-color: rgb(163 163 163 / 0.15) !important;
}

.tw-decoration-neutral-400\/20 {
  text-decoration-color: rgb(163 163 163 / 0.2) !important;
}

.tw-decoration-neutral-400\/25 {
  text-decoration-color: rgb(163 163 163 / 0.25) !important;
}

.tw-decoration-neutral-400\/30 {
  text-decoration-color: rgb(163 163 163 / 0.3) !important;
}

.tw-decoration-neutral-400\/35 {
  text-decoration-color: rgb(163 163 163 / 0.35) !important;
}

.tw-decoration-neutral-400\/40 {
  text-decoration-color: rgb(163 163 163 / 0.4) !important;
}

.tw-decoration-neutral-400\/45 {
  text-decoration-color: rgb(163 163 163 / 0.45) !important;
}

.tw-decoration-neutral-400\/5 {
  text-decoration-color: rgb(163 163 163 / 0.05) !important;
}

.tw-decoration-neutral-400\/50 {
  text-decoration-color: rgb(163 163 163 / 0.5) !important;
}

.tw-decoration-neutral-400\/55 {
  text-decoration-color: rgb(163 163 163 / 0.55) !important;
}

.tw-decoration-neutral-400\/60 {
  text-decoration-color: rgb(163 163 163 / 0.6) !important;
}

.tw-decoration-neutral-400\/65 {
  text-decoration-color: rgb(163 163 163 / 0.65) !important;
}

.tw-decoration-neutral-400\/70 {
  text-decoration-color: rgb(163 163 163 / 0.7) !important;
}

.tw-decoration-neutral-400\/75 {
  text-decoration-color: rgb(163 163 163 / 0.75) !important;
}

.tw-decoration-neutral-400\/80 {
  text-decoration-color: rgb(163 163 163 / 0.8) !important;
}

.tw-decoration-neutral-400\/85 {
  text-decoration-color: rgb(163 163 163 / 0.85) !important;
}

.tw-decoration-neutral-400\/90 {
  text-decoration-color: rgb(163 163 163 / 0.9) !important;
}

.tw-decoration-neutral-400\/95 {
  text-decoration-color: rgb(163 163 163 / 0.95) !important;
}

.tw-decoration-neutral-50 {
  text-decoration-color: #fafafa !important;
}

.tw-decoration-neutral-50\/0 {
  text-decoration-color: rgb(250 250 250 / 0) !important;
}

.tw-decoration-neutral-50\/10 {
  text-decoration-color: rgb(250 250 250 / 0.1) !important;
}

.tw-decoration-neutral-50\/100 {
  text-decoration-color: rgb(250 250 250 / 1) !important;
}

.tw-decoration-neutral-50\/15 {
  text-decoration-color: rgb(250 250 250 / 0.15) !important;
}

.tw-decoration-neutral-50\/20 {
  text-decoration-color: rgb(250 250 250 / 0.2) !important;
}

.tw-decoration-neutral-50\/25 {
  text-decoration-color: rgb(250 250 250 / 0.25) !important;
}

.tw-decoration-neutral-50\/30 {
  text-decoration-color: rgb(250 250 250 / 0.3) !important;
}

.tw-decoration-neutral-50\/35 {
  text-decoration-color: rgb(250 250 250 / 0.35) !important;
}

.tw-decoration-neutral-50\/40 {
  text-decoration-color: rgb(250 250 250 / 0.4) !important;
}

.tw-decoration-neutral-50\/45 {
  text-decoration-color: rgb(250 250 250 / 0.45) !important;
}

.tw-decoration-neutral-50\/5 {
  text-decoration-color: rgb(250 250 250 / 0.05) !important;
}

.tw-decoration-neutral-50\/50 {
  text-decoration-color: rgb(250 250 250 / 0.5) !important;
}

.tw-decoration-neutral-50\/55 {
  text-decoration-color: rgb(250 250 250 / 0.55) !important;
}

.tw-decoration-neutral-50\/60 {
  text-decoration-color: rgb(250 250 250 / 0.6) !important;
}

.tw-decoration-neutral-50\/65 {
  text-decoration-color: rgb(250 250 250 / 0.65) !important;
}

.tw-decoration-neutral-50\/70 {
  text-decoration-color: rgb(250 250 250 / 0.7) !important;
}

.tw-decoration-neutral-50\/75 {
  text-decoration-color: rgb(250 250 250 / 0.75) !important;
}

.tw-decoration-neutral-50\/80 {
  text-decoration-color: rgb(250 250 250 / 0.8) !important;
}

.tw-decoration-neutral-50\/85 {
  text-decoration-color: rgb(250 250 250 / 0.85) !important;
}

.tw-decoration-neutral-50\/90 {
  text-decoration-color: rgb(250 250 250 / 0.9) !important;
}

.tw-decoration-neutral-50\/95 {
  text-decoration-color: rgb(250 250 250 / 0.95) !important;
}

.tw-decoration-neutral-500 {
  text-decoration-color: #737373 !important;
}

.tw-decoration-neutral-500\/0 {
  text-decoration-color: rgb(115 115 115 / 0) !important;
}

.tw-decoration-neutral-500\/10 {
  text-decoration-color: rgb(115 115 115 / 0.1) !important;
}

.tw-decoration-neutral-500\/100 {
  text-decoration-color: rgb(115 115 115 / 1) !important;
}

.tw-decoration-neutral-500\/15 {
  text-decoration-color: rgb(115 115 115 / 0.15) !important;
}

.tw-decoration-neutral-500\/20 {
  text-decoration-color: rgb(115 115 115 / 0.2) !important;
}

.tw-decoration-neutral-500\/25 {
  text-decoration-color: rgb(115 115 115 / 0.25) !important;
}

.tw-decoration-neutral-500\/30 {
  text-decoration-color: rgb(115 115 115 / 0.3) !important;
}

.tw-decoration-neutral-500\/35 {
  text-decoration-color: rgb(115 115 115 / 0.35) !important;
}

.tw-decoration-neutral-500\/40 {
  text-decoration-color: rgb(115 115 115 / 0.4) !important;
}

.tw-decoration-neutral-500\/45 {
  text-decoration-color: rgb(115 115 115 / 0.45) !important;
}

.tw-decoration-neutral-500\/5 {
  text-decoration-color: rgb(115 115 115 / 0.05) !important;
}

.tw-decoration-neutral-500\/50 {
  text-decoration-color: rgb(115 115 115 / 0.5) !important;
}

.tw-decoration-neutral-500\/55 {
  text-decoration-color: rgb(115 115 115 / 0.55) !important;
}

.tw-decoration-neutral-500\/60 {
  text-decoration-color: rgb(115 115 115 / 0.6) !important;
}

.tw-decoration-neutral-500\/65 {
  text-decoration-color: rgb(115 115 115 / 0.65) !important;
}

.tw-decoration-neutral-500\/70 {
  text-decoration-color: rgb(115 115 115 / 0.7) !important;
}

.tw-decoration-neutral-500\/75 {
  text-decoration-color: rgb(115 115 115 / 0.75) !important;
}

.tw-decoration-neutral-500\/80 {
  text-decoration-color: rgb(115 115 115 / 0.8) !important;
}

.tw-decoration-neutral-500\/85 {
  text-decoration-color: rgb(115 115 115 / 0.85) !important;
}

.tw-decoration-neutral-500\/90 {
  text-decoration-color: rgb(115 115 115 / 0.9) !important;
}

.tw-decoration-neutral-500\/95 {
  text-decoration-color: rgb(115 115 115 / 0.95) !important;
}

.tw-decoration-neutral-600 {
  text-decoration-color: #525252 !important;
}

.tw-decoration-neutral-600\/0 {
  text-decoration-color: rgb(82 82 82 / 0) !important;
}

.tw-decoration-neutral-600\/10 {
  text-decoration-color: rgb(82 82 82 / 0.1) !important;
}

.tw-decoration-neutral-600\/100 {
  text-decoration-color: rgb(82 82 82 / 1) !important;
}

.tw-decoration-neutral-600\/15 {
  text-decoration-color: rgb(82 82 82 / 0.15) !important;
}

.tw-decoration-neutral-600\/20 {
  text-decoration-color: rgb(82 82 82 / 0.2) !important;
}

.tw-decoration-neutral-600\/25 {
  text-decoration-color: rgb(82 82 82 / 0.25) !important;
}

.tw-decoration-neutral-600\/30 {
  text-decoration-color: rgb(82 82 82 / 0.3) !important;
}

.tw-decoration-neutral-600\/35 {
  text-decoration-color: rgb(82 82 82 / 0.35) !important;
}

.tw-decoration-neutral-600\/40 {
  text-decoration-color: rgb(82 82 82 / 0.4) !important;
}

.tw-decoration-neutral-600\/45 {
  text-decoration-color: rgb(82 82 82 / 0.45) !important;
}

.tw-decoration-neutral-600\/5 {
  text-decoration-color: rgb(82 82 82 / 0.05) !important;
}

.tw-decoration-neutral-600\/50 {
  text-decoration-color: rgb(82 82 82 / 0.5) !important;
}

.tw-decoration-neutral-600\/55 {
  text-decoration-color: rgb(82 82 82 / 0.55) !important;
}

.tw-decoration-neutral-600\/60 {
  text-decoration-color: rgb(82 82 82 / 0.6) !important;
}

.tw-decoration-neutral-600\/65 {
  text-decoration-color: rgb(82 82 82 / 0.65) !important;
}

.tw-decoration-neutral-600\/70 {
  text-decoration-color: rgb(82 82 82 / 0.7) !important;
}

.tw-decoration-neutral-600\/75 {
  text-decoration-color: rgb(82 82 82 / 0.75) !important;
}

.tw-decoration-neutral-600\/80 {
  text-decoration-color: rgb(82 82 82 / 0.8) !important;
}

.tw-decoration-neutral-600\/85 {
  text-decoration-color: rgb(82 82 82 / 0.85) !important;
}

.tw-decoration-neutral-600\/90 {
  text-decoration-color: rgb(82 82 82 / 0.9) !important;
}

.tw-decoration-neutral-600\/95 {
  text-decoration-color: rgb(82 82 82 / 0.95) !important;
}

.tw-decoration-neutral-700 {
  text-decoration-color: #404040 !important;
}

.tw-decoration-neutral-700\/0 {
  text-decoration-color: rgb(64 64 64 / 0) !important;
}

.tw-decoration-neutral-700\/10 {
  text-decoration-color: rgb(64 64 64 / 0.1) !important;
}

.tw-decoration-neutral-700\/100 {
  text-decoration-color: rgb(64 64 64 / 1) !important;
}

.tw-decoration-neutral-700\/15 {
  text-decoration-color: rgb(64 64 64 / 0.15) !important;
}

.tw-decoration-neutral-700\/20 {
  text-decoration-color: rgb(64 64 64 / 0.2) !important;
}

.tw-decoration-neutral-700\/25 {
  text-decoration-color: rgb(64 64 64 / 0.25) !important;
}

.tw-decoration-neutral-700\/30 {
  text-decoration-color: rgb(64 64 64 / 0.3) !important;
}

.tw-decoration-neutral-700\/35 {
  text-decoration-color: rgb(64 64 64 / 0.35) !important;
}

.tw-decoration-neutral-700\/40 {
  text-decoration-color: rgb(64 64 64 / 0.4) !important;
}

.tw-decoration-neutral-700\/45 {
  text-decoration-color: rgb(64 64 64 / 0.45) !important;
}

.tw-decoration-neutral-700\/5 {
  text-decoration-color: rgb(64 64 64 / 0.05) !important;
}

.tw-decoration-neutral-700\/50 {
  text-decoration-color: rgb(64 64 64 / 0.5) !important;
}

.tw-decoration-neutral-700\/55 {
  text-decoration-color: rgb(64 64 64 / 0.55) !important;
}

.tw-decoration-neutral-700\/60 {
  text-decoration-color: rgb(64 64 64 / 0.6) !important;
}

.tw-decoration-neutral-700\/65 {
  text-decoration-color: rgb(64 64 64 / 0.65) !important;
}

.tw-decoration-neutral-700\/70 {
  text-decoration-color: rgb(64 64 64 / 0.7) !important;
}

.tw-decoration-neutral-700\/75 {
  text-decoration-color: rgb(64 64 64 / 0.75) !important;
}

.tw-decoration-neutral-700\/80 {
  text-decoration-color: rgb(64 64 64 / 0.8) !important;
}

.tw-decoration-neutral-700\/85 {
  text-decoration-color: rgb(64 64 64 / 0.85) !important;
}

.tw-decoration-neutral-700\/90 {
  text-decoration-color: rgb(64 64 64 / 0.9) !important;
}

.tw-decoration-neutral-700\/95 {
  text-decoration-color: rgb(64 64 64 / 0.95) !important;
}

.tw-decoration-neutral-800 {
  text-decoration-color: #262626 !important;
}

.tw-decoration-neutral-800\/0 {
  text-decoration-color: rgb(38 38 38 / 0) !important;
}

.tw-decoration-neutral-800\/10 {
  text-decoration-color: rgb(38 38 38 / 0.1) !important;
}

.tw-decoration-neutral-800\/100 {
  text-decoration-color: rgb(38 38 38 / 1) !important;
}

.tw-decoration-neutral-800\/15 {
  text-decoration-color: rgb(38 38 38 / 0.15) !important;
}

.tw-decoration-neutral-800\/20 {
  text-decoration-color: rgb(38 38 38 / 0.2) !important;
}

.tw-decoration-neutral-800\/25 {
  text-decoration-color: rgb(38 38 38 / 0.25) !important;
}

.tw-decoration-neutral-800\/30 {
  text-decoration-color: rgb(38 38 38 / 0.3) !important;
}

.tw-decoration-neutral-800\/35 {
  text-decoration-color: rgb(38 38 38 / 0.35) !important;
}

.tw-decoration-neutral-800\/40 {
  text-decoration-color: rgb(38 38 38 / 0.4) !important;
}

.tw-decoration-neutral-800\/45 {
  text-decoration-color: rgb(38 38 38 / 0.45) !important;
}

.tw-decoration-neutral-800\/5 {
  text-decoration-color: rgb(38 38 38 / 0.05) !important;
}

.tw-decoration-neutral-800\/50 {
  text-decoration-color: rgb(38 38 38 / 0.5) !important;
}

.tw-decoration-neutral-800\/55 {
  text-decoration-color: rgb(38 38 38 / 0.55) !important;
}

.tw-decoration-neutral-800\/60 {
  text-decoration-color: rgb(38 38 38 / 0.6) !important;
}

.tw-decoration-neutral-800\/65 {
  text-decoration-color: rgb(38 38 38 / 0.65) !important;
}

.tw-decoration-neutral-800\/70 {
  text-decoration-color: rgb(38 38 38 / 0.7) !important;
}

.tw-decoration-neutral-800\/75 {
  text-decoration-color: rgb(38 38 38 / 0.75) !important;
}

.tw-decoration-neutral-800\/80 {
  text-decoration-color: rgb(38 38 38 / 0.8) !important;
}

.tw-decoration-neutral-800\/85 {
  text-decoration-color: rgb(38 38 38 / 0.85) !important;
}

.tw-decoration-neutral-800\/90 {
  text-decoration-color: rgb(38 38 38 / 0.9) !important;
}

.tw-decoration-neutral-800\/95 {
  text-decoration-color: rgb(38 38 38 / 0.95) !important;
}

.tw-decoration-neutral-900 {
  text-decoration-color: #171717 !important;
}

.tw-decoration-neutral-900\/0 {
  text-decoration-color: rgb(23 23 23 / 0) !important;
}

.tw-decoration-neutral-900\/10 {
  text-decoration-color: rgb(23 23 23 / 0.1) !important;
}

.tw-decoration-neutral-900\/100 {
  text-decoration-color: rgb(23 23 23 / 1) !important;
}

.tw-decoration-neutral-900\/15 {
  text-decoration-color: rgb(23 23 23 / 0.15) !important;
}

.tw-decoration-neutral-900\/20 {
  text-decoration-color: rgb(23 23 23 / 0.2) !important;
}

.tw-decoration-neutral-900\/25 {
  text-decoration-color: rgb(23 23 23 / 0.25) !important;
}

.tw-decoration-neutral-900\/30 {
  text-decoration-color: rgb(23 23 23 / 0.3) !important;
}

.tw-decoration-neutral-900\/35 {
  text-decoration-color: rgb(23 23 23 / 0.35) !important;
}

.tw-decoration-neutral-900\/40 {
  text-decoration-color: rgb(23 23 23 / 0.4) !important;
}

.tw-decoration-neutral-900\/45 {
  text-decoration-color: rgb(23 23 23 / 0.45) !important;
}

.tw-decoration-neutral-900\/5 {
  text-decoration-color: rgb(23 23 23 / 0.05) !important;
}

.tw-decoration-neutral-900\/50 {
  text-decoration-color: rgb(23 23 23 / 0.5) !important;
}

.tw-decoration-neutral-900\/55 {
  text-decoration-color: rgb(23 23 23 / 0.55) !important;
}

.tw-decoration-neutral-900\/60 {
  text-decoration-color: rgb(23 23 23 / 0.6) !important;
}

.tw-decoration-neutral-900\/65 {
  text-decoration-color: rgb(23 23 23 / 0.65) !important;
}

.tw-decoration-neutral-900\/70 {
  text-decoration-color: rgb(23 23 23 / 0.7) !important;
}

.tw-decoration-neutral-900\/75 {
  text-decoration-color: rgb(23 23 23 / 0.75) !important;
}

.tw-decoration-neutral-900\/80 {
  text-decoration-color: rgb(23 23 23 / 0.8) !important;
}

.tw-decoration-neutral-900\/85 {
  text-decoration-color: rgb(23 23 23 / 0.85) !important;
}

.tw-decoration-neutral-900\/90 {
  text-decoration-color: rgb(23 23 23 / 0.9) !important;
}

.tw-decoration-neutral-900\/95 {
  text-decoration-color: rgb(23 23 23 / 0.95) !important;
}

.tw-decoration-neutral-950 {
  text-decoration-color: #0a0a0a !important;
}

.tw-decoration-neutral-950\/0 {
  text-decoration-color: rgb(10 10 10 / 0) !important;
}

.tw-decoration-neutral-950\/10 {
  text-decoration-color: rgb(10 10 10 / 0.1) !important;
}

.tw-decoration-neutral-950\/100 {
  text-decoration-color: rgb(10 10 10 / 1) !important;
}

.tw-decoration-neutral-950\/15 {
  text-decoration-color: rgb(10 10 10 / 0.15) !important;
}

.tw-decoration-neutral-950\/20 {
  text-decoration-color: rgb(10 10 10 / 0.2) !important;
}

.tw-decoration-neutral-950\/25 {
  text-decoration-color: rgb(10 10 10 / 0.25) !important;
}

.tw-decoration-neutral-950\/30 {
  text-decoration-color: rgb(10 10 10 / 0.3) !important;
}

.tw-decoration-neutral-950\/35 {
  text-decoration-color: rgb(10 10 10 / 0.35) !important;
}

.tw-decoration-neutral-950\/40 {
  text-decoration-color: rgb(10 10 10 / 0.4) !important;
}

.tw-decoration-neutral-950\/45 {
  text-decoration-color: rgb(10 10 10 / 0.45) !important;
}

.tw-decoration-neutral-950\/5 {
  text-decoration-color: rgb(10 10 10 / 0.05) !important;
}

.tw-decoration-neutral-950\/50 {
  text-decoration-color: rgb(10 10 10 / 0.5) !important;
}

.tw-decoration-neutral-950\/55 {
  text-decoration-color: rgb(10 10 10 / 0.55) !important;
}

.tw-decoration-neutral-950\/60 {
  text-decoration-color: rgb(10 10 10 / 0.6) !important;
}

.tw-decoration-neutral-950\/65 {
  text-decoration-color: rgb(10 10 10 / 0.65) !important;
}

.tw-decoration-neutral-950\/70 {
  text-decoration-color: rgb(10 10 10 / 0.7) !important;
}

.tw-decoration-neutral-950\/75 {
  text-decoration-color: rgb(10 10 10 / 0.75) !important;
}

.tw-decoration-neutral-950\/80 {
  text-decoration-color: rgb(10 10 10 / 0.8) !important;
}

.tw-decoration-neutral-950\/85 {
  text-decoration-color: rgb(10 10 10 / 0.85) !important;
}

.tw-decoration-neutral-950\/90 {
  text-decoration-color: rgb(10 10 10 / 0.9) !important;
}

.tw-decoration-neutral-950\/95 {
  text-decoration-color: rgb(10 10 10 / 0.95) !important;
}

.tw-decoration-orange-100 {
  text-decoration-color: #ffedd5 !important;
}

.tw-decoration-orange-100\/0 {
  text-decoration-color: rgb(255 237 213 / 0) !important;
}

.tw-decoration-orange-100\/10 {
  text-decoration-color: rgb(255 237 213 / 0.1) !important;
}

.tw-decoration-orange-100\/100 {
  text-decoration-color: rgb(255 237 213 / 1) !important;
}

.tw-decoration-orange-100\/15 {
  text-decoration-color: rgb(255 237 213 / 0.15) !important;
}

.tw-decoration-orange-100\/20 {
  text-decoration-color: rgb(255 237 213 / 0.2) !important;
}

.tw-decoration-orange-100\/25 {
  text-decoration-color: rgb(255 237 213 / 0.25) !important;
}

.tw-decoration-orange-100\/30 {
  text-decoration-color: rgb(255 237 213 / 0.3) !important;
}

.tw-decoration-orange-100\/35 {
  text-decoration-color: rgb(255 237 213 / 0.35) !important;
}

.tw-decoration-orange-100\/40 {
  text-decoration-color: rgb(255 237 213 / 0.4) !important;
}

.tw-decoration-orange-100\/45 {
  text-decoration-color: rgb(255 237 213 / 0.45) !important;
}

.tw-decoration-orange-100\/5 {
  text-decoration-color: rgb(255 237 213 / 0.05) !important;
}

.tw-decoration-orange-100\/50 {
  text-decoration-color: rgb(255 237 213 / 0.5) !important;
}

.tw-decoration-orange-100\/55 {
  text-decoration-color: rgb(255 237 213 / 0.55) !important;
}

.tw-decoration-orange-100\/60 {
  text-decoration-color: rgb(255 237 213 / 0.6) !important;
}

.tw-decoration-orange-100\/65 {
  text-decoration-color: rgb(255 237 213 / 0.65) !important;
}

.tw-decoration-orange-100\/70 {
  text-decoration-color: rgb(255 237 213 / 0.7) !important;
}

.tw-decoration-orange-100\/75 {
  text-decoration-color: rgb(255 237 213 / 0.75) !important;
}

.tw-decoration-orange-100\/80 {
  text-decoration-color: rgb(255 237 213 / 0.8) !important;
}

.tw-decoration-orange-100\/85 {
  text-decoration-color: rgb(255 237 213 / 0.85) !important;
}

.tw-decoration-orange-100\/90 {
  text-decoration-color: rgb(255 237 213 / 0.9) !important;
}

.tw-decoration-orange-100\/95 {
  text-decoration-color: rgb(255 237 213 / 0.95) !important;
}

.tw-decoration-orange-200 {
  text-decoration-color: #fed7aa !important;
}

.tw-decoration-orange-200\/0 {
  text-decoration-color: rgb(254 215 170 / 0) !important;
}

.tw-decoration-orange-200\/10 {
  text-decoration-color: rgb(254 215 170 / 0.1) !important;
}

.tw-decoration-orange-200\/100 {
  text-decoration-color: rgb(254 215 170 / 1) !important;
}

.tw-decoration-orange-200\/15 {
  text-decoration-color: rgb(254 215 170 / 0.15) !important;
}

.tw-decoration-orange-200\/20 {
  text-decoration-color: rgb(254 215 170 / 0.2) !important;
}

.tw-decoration-orange-200\/25 {
  text-decoration-color: rgb(254 215 170 / 0.25) !important;
}

.tw-decoration-orange-200\/30 {
  text-decoration-color: rgb(254 215 170 / 0.3) !important;
}

.tw-decoration-orange-200\/35 {
  text-decoration-color: rgb(254 215 170 / 0.35) !important;
}

.tw-decoration-orange-200\/40 {
  text-decoration-color: rgb(254 215 170 / 0.4) !important;
}

.tw-decoration-orange-200\/45 {
  text-decoration-color: rgb(254 215 170 / 0.45) !important;
}

.tw-decoration-orange-200\/5 {
  text-decoration-color: rgb(254 215 170 / 0.05) !important;
}

.tw-decoration-orange-200\/50 {
  text-decoration-color: rgb(254 215 170 / 0.5) !important;
}

.tw-decoration-orange-200\/55 {
  text-decoration-color: rgb(254 215 170 / 0.55) !important;
}

.tw-decoration-orange-200\/60 {
  text-decoration-color: rgb(254 215 170 / 0.6) !important;
}

.tw-decoration-orange-200\/65 {
  text-decoration-color: rgb(254 215 170 / 0.65) !important;
}

.tw-decoration-orange-200\/70 {
  text-decoration-color: rgb(254 215 170 / 0.7) !important;
}

.tw-decoration-orange-200\/75 {
  text-decoration-color: rgb(254 215 170 / 0.75) !important;
}

.tw-decoration-orange-200\/80 {
  text-decoration-color: rgb(254 215 170 / 0.8) !important;
}

.tw-decoration-orange-200\/85 {
  text-decoration-color: rgb(254 215 170 / 0.85) !important;
}

.tw-decoration-orange-200\/90 {
  text-decoration-color: rgb(254 215 170 / 0.9) !important;
}

.tw-decoration-orange-200\/95 {
  text-decoration-color: rgb(254 215 170 / 0.95) !important;
}

.tw-decoration-orange-300 {
  text-decoration-color: #fdba74 !important;
}

.tw-decoration-orange-300\/0 {
  text-decoration-color: rgb(253 186 116 / 0) !important;
}

.tw-decoration-orange-300\/10 {
  text-decoration-color: rgb(253 186 116 / 0.1) !important;
}

.tw-decoration-orange-300\/100 {
  text-decoration-color: rgb(253 186 116 / 1) !important;
}

.tw-decoration-orange-300\/15 {
  text-decoration-color: rgb(253 186 116 / 0.15) !important;
}

.tw-decoration-orange-300\/20 {
  text-decoration-color: rgb(253 186 116 / 0.2) !important;
}

.tw-decoration-orange-300\/25 {
  text-decoration-color: rgb(253 186 116 / 0.25) !important;
}

.tw-decoration-orange-300\/30 {
  text-decoration-color: rgb(253 186 116 / 0.3) !important;
}

.tw-decoration-orange-300\/35 {
  text-decoration-color: rgb(253 186 116 / 0.35) !important;
}

.tw-decoration-orange-300\/40 {
  text-decoration-color: rgb(253 186 116 / 0.4) !important;
}

.tw-decoration-orange-300\/45 {
  text-decoration-color: rgb(253 186 116 / 0.45) !important;
}

.tw-decoration-orange-300\/5 {
  text-decoration-color: rgb(253 186 116 / 0.05) !important;
}

.tw-decoration-orange-300\/50 {
  text-decoration-color: rgb(253 186 116 / 0.5) !important;
}

.tw-decoration-orange-300\/55 {
  text-decoration-color: rgb(253 186 116 / 0.55) !important;
}

.tw-decoration-orange-300\/60 {
  text-decoration-color: rgb(253 186 116 / 0.6) !important;
}

.tw-decoration-orange-300\/65 {
  text-decoration-color: rgb(253 186 116 / 0.65) !important;
}

.tw-decoration-orange-300\/70 {
  text-decoration-color: rgb(253 186 116 / 0.7) !important;
}

.tw-decoration-orange-300\/75 {
  text-decoration-color: rgb(253 186 116 / 0.75) !important;
}

.tw-decoration-orange-300\/80 {
  text-decoration-color: rgb(253 186 116 / 0.8) !important;
}

.tw-decoration-orange-300\/85 {
  text-decoration-color: rgb(253 186 116 / 0.85) !important;
}

.tw-decoration-orange-300\/90 {
  text-decoration-color: rgb(253 186 116 / 0.9) !important;
}

.tw-decoration-orange-300\/95 {
  text-decoration-color: rgb(253 186 116 / 0.95) !important;
}

.tw-decoration-orange-400 {
  text-decoration-color: #fb923c !important;
}

.tw-decoration-orange-400\/0 {
  text-decoration-color: rgb(251 146 60 / 0) !important;
}

.tw-decoration-orange-400\/10 {
  text-decoration-color: rgb(251 146 60 / 0.1) !important;
}

.tw-decoration-orange-400\/100 {
  text-decoration-color: rgb(251 146 60 / 1) !important;
}

.tw-decoration-orange-400\/15 {
  text-decoration-color: rgb(251 146 60 / 0.15) !important;
}

.tw-decoration-orange-400\/20 {
  text-decoration-color: rgb(251 146 60 / 0.2) !important;
}

.tw-decoration-orange-400\/25 {
  text-decoration-color: rgb(251 146 60 / 0.25) !important;
}

.tw-decoration-orange-400\/30 {
  text-decoration-color: rgb(251 146 60 / 0.3) !important;
}

.tw-decoration-orange-400\/35 {
  text-decoration-color: rgb(251 146 60 / 0.35) !important;
}

.tw-decoration-orange-400\/40 {
  text-decoration-color: rgb(251 146 60 / 0.4) !important;
}

.tw-decoration-orange-400\/45 {
  text-decoration-color: rgb(251 146 60 / 0.45) !important;
}

.tw-decoration-orange-400\/5 {
  text-decoration-color: rgb(251 146 60 / 0.05) !important;
}

.tw-decoration-orange-400\/50 {
  text-decoration-color: rgb(251 146 60 / 0.5) !important;
}

.tw-decoration-orange-400\/55 {
  text-decoration-color: rgb(251 146 60 / 0.55) !important;
}

.tw-decoration-orange-400\/60 {
  text-decoration-color: rgb(251 146 60 / 0.6) !important;
}

.tw-decoration-orange-400\/65 {
  text-decoration-color: rgb(251 146 60 / 0.65) !important;
}

.tw-decoration-orange-400\/70 {
  text-decoration-color: rgb(251 146 60 / 0.7) !important;
}

.tw-decoration-orange-400\/75 {
  text-decoration-color: rgb(251 146 60 / 0.75) !important;
}

.tw-decoration-orange-400\/80 {
  text-decoration-color: rgb(251 146 60 / 0.8) !important;
}

.tw-decoration-orange-400\/85 {
  text-decoration-color: rgb(251 146 60 / 0.85) !important;
}

.tw-decoration-orange-400\/90 {
  text-decoration-color: rgb(251 146 60 / 0.9) !important;
}

.tw-decoration-orange-400\/95 {
  text-decoration-color: rgb(251 146 60 / 0.95) !important;
}

.tw-decoration-orange-50 {
  text-decoration-color: #fff7ed !important;
}

.tw-decoration-orange-50\/0 {
  text-decoration-color: rgb(255 247 237 / 0) !important;
}

.tw-decoration-orange-50\/10 {
  text-decoration-color: rgb(255 247 237 / 0.1) !important;
}

.tw-decoration-orange-50\/100 {
  text-decoration-color: rgb(255 247 237 / 1) !important;
}

.tw-decoration-orange-50\/15 {
  text-decoration-color: rgb(255 247 237 / 0.15) !important;
}

.tw-decoration-orange-50\/20 {
  text-decoration-color: rgb(255 247 237 / 0.2) !important;
}

.tw-decoration-orange-50\/25 {
  text-decoration-color: rgb(255 247 237 / 0.25) !important;
}

.tw-decoration-orange-50\/30 {
  text-decoration-color: rgb(255 247 237 / 0.3) !important;
}

.tw-decoration-orange-50\/35 {
  text-decoration-color: rgb(255 247 237 / 0.35) !important;
}

.tw-decoration-orange-50\/40 {
  text-decoration-color: rgb(255 247 237 / 0.4) !important;
}

.tw-decoration-orange-50\/45 {
  text-decoration-color: rgb(255 247 237 / 0.45) !important;
}

.tw-decoration-orange-50\/5 {
  text-decoration-color: rgb(255 247 237 / 0.05) !important;
}

.tw-decoration-orange-50\/50 {
  text-decoration-color: rgb(255 247 237 / 0.5) !important;
}

.tw-decoration-orange-50\/55 {
  text-decoration-color: rgb(255 247 237 / 0.55) !important;
}

.tw-decoration-orange-50\/60 {
  text-decoration-color: rgb(255 247 237 / 0.6) !important;
}

.tw-decoration-orange-50\/65 {
  text-decoration-color: rgb(255 247 237 / 0.65) !important;
}

.tw-decoration-orange-50\/70 {
  text-decoration-color: rgb(255 247 237 / 0.7) !important;
}

.tw-decoration-orange-50\/75 {
  text-decoration-color: rgb(255 247 237 / 0.75) !important;
}

.tw-decoration-orange-50\/80 {
  text-decoration-color: rgb(255 247 237 / 0.8) !important;
}

.tw-decoration-orange-50\/85 {
  text-decoration-color: rgb(255 247 237 / 0.85) !important;
}

.tw-decoration-orange-50\/90 {
  text-decoration-color: rgb(255 247 237 / 0.9) !important;
}

.tw-decoration-orange-50\/95 {
  text-decoration-color: rgb(255 247 237 / 0.95) !important;
}

.tw-decoration-orange-500 {
  text-decoration-color: #f97316 !important;
}

.tw-decoration-orange-500\/0 {
  text-decoration-color: rgb(249 115 22 / 0) !important;
}

.tw-decoration-orange-500\/10 {
  text-decoration-color: rgb(249 115 22 / 0.1) !important;
}

.tw-decoration-orange-500\/100 {
  text-decoration-color: rgb(249 115 22 / 1) !important;
}

.tw-decoration-orange-500\/15 {
  text-decoration-color: rgb(249 115 22 / 0.15) !important;
}

.tw-decoration-orange-500\/20 {
  text-decoration-color: rgb(249 115 22 / 0.2) !important;
}

.tw-decoration-orange-500\/25 {
  text-decoration-color: rgb(249 115 22 / 0.25) !important;
}

.tw-decoration-orange-500\/30 {
  text-decoration-color: rgb(249 115 22 / 0.3) !important;
}

.tw-decoration-orange-500\/35 {
  text-decoration-color: rgb(249 115 22 / 0.35) !important;
}

.tw-decoration-orange-500\/40 {
  text-decoration-color: rgb(249 115 22 / 0.4) !important;
}

.tw-decoration-orange-500\/45 {
  text-decoration-color: rgb(249 115 22 / 0.45) !important;
}

.tw-decoration-orange-500\/5 {
  text-decoration-color: rgb(249 115 22 / 0.05) !important;
}

.tw-decoration-orange-500\/50 {
  text-decoration-color: rgb(249 115 22 / 0.5) !important;
}

.tw-decoration-orange-500\/55 {
  text-decoration-color: rgb(249 115 22 / 0.55) !important;
}

.tw-decoration-orange-500\/60 {
  text-decoration-color: rgb(249 115 22 / 0.6) !important;
}

.tw-decoration-orange-500\/65 {
  text-decoration-color: rgb(249 115 22 / 0.65) !important;
}

.tw-decoration-orange-500\/70 {
  text-decoration-color: rgb(249 115 22 / 0.7) !important;
}

.tw-decoration-orange-500\/75 {
  text-decoration-color: rgb(249 115 22 / 0.75) !important;
}

.tw-decoration-orange-500\/80 {
  text-decoration-color: rgb(249 115 22 / 0.8) !important;
}

.tw-decoration-orange-500\/85 {
  text-decoration-color: rgb(249 115 22 / 0.85) !important;
}

.tw-decoration-orange-500\/90 {
  text-decoration-color: rgb(249 115 22 / 0.9) !important;
}

.tw-decoration-orange-500\/95 {
  text-decoration-color: rgb(249 115 22 / 0.95) !important;
}

.tw-decoration-orange-600 {
  text-decoration-color: #ea580c !important;
}

.tw-decoration-orange-600\/0 {
  text-decoration-color: rgb(234 88 12 / 0) !important;
}

.tw-decoration-orange-600\/10 {
  text-decoration-color: rgb(234 88 12 / 0.1) !important;
}

.tw-decoration-orange-600\/100 {
  text-decoration-color: rgb(234 88 12 / 1) !important;
}

.tw-decoration-orange-600\/15 {
  text-decoration-color: rgb(234 88 12 / 0.15) !important;
}

.tw-decoration-orange-600\/20 {
  text-decoration-color: rgb(234 88 12 / 0.2) !important;
}

.tw-decoration-orange-600\/25 {
  text-decoration-color: rgb(234 88 12 / 0.25) !important;
}

.tw-decoration-orange-600\/30 {
  text-decoration-color: rgb(234 88 12 / 0.3) !important;
}

.tw-decoration-orange-600\/35 {
  text-decoration-color: rgb(234 88 12 / 0.35) !important;
}

.tw-decoration-orange-600\/40 {
  text-decoration-color: rgb(234 88 12 / 0.4) !important;
}

.tw-decoration-orange-600\/45 {
  text-decoration-color: rgb(234 88 12 / 0.45) !important;
}

.tw-decoration-orange-600\/5 {
  text-decoration-color: rgb(234 88 12 / 0.05) !important;
}

.tw-decoration-orange-600\/50 {
  text-decoration-color: rgb(234 88 12 / 0.5) !important;
}

.tw-decoration-orange-600\/55 {
  text-decoration-color: rgb(234 88 12 / 0.55) !important;
}

.tw-decoration-orange-600\/60 {
  text-decoration-color: rgb(234 88 12 / 0.6) !important;
}

.tw-decoration-orange-600\/65 {
  text-decoration-color: rgb(234 88 12 / 0.65) !important;
}

.tw-decoration-orange-600\/70 {
  text-decoration-color: rgb(234 88 12 / 0.7) !important;
}

.tw-decoration-orange-600\/75 {
  text-decoration-color: rgb(234 88 12 / 0.75) !important;
}

.tw-decoration-orange-600\/80 {
  text-decoration-color: rgb(234 88 12 / 0.8) !important;
}

.tw-decoration-orange-600\/85 {
  text-decoration-color: rgb(234 88 12 / 0.85) !important;
}

.tw-decoration-orange-600\/90 {
  text-decoration-color: rgb(234 88 12 / 0.9) !important;
}

.tw-decoration-orange-600\/95 {
  text-decoration-color: rgb(234 88 12 / 0.95) !important;
}

.tw-decoration-orange-700 {
  text-decoration-color: #c2410c !important;
}

.tw-decoration-orange-700\/0 {
  text-decoration-color: rgb(194 65 12 / 0) !important;
}

.tw-decoration-orange-700\/10 {
  text-decoration-color: rgb(194 65 12 / 0.1) !important;
}

.tw-decoration-orange-700\/100 {
  text-decoration-color: rgb(194 65 12 / 1) !important;
}

.tw-decoration-orange-700\/15 {
  text-decoration-color: rgb(194 65 12 / 0.15) !important;
}

.tw-decoration-orange-700\/20 {
  text-decoration-color: rgb(194 65 12 / 0.2) !important;
}

.tw-decoration-orange-700\/25 {
  text-decoration-color: rgb(194 65 12 / 0.25) !important;
}

.tw-decoration-orange-700\/30 {
  text-decoration-color: rgb(194 65 12 / 0.3) !important;
}

.tw-decoration-orange-700\/35 {
  text-decoration-color: rgb(194 65 12 / 0.35) !important;
}

.tw-decoration-orange-700\/40 {
  text-decoration-color: rgb(194 65 12 / 0.4) !important;
}

.tw-decoration-orange-700\/45 {
  text-decoration-color: rgb(194 65 12 / 0.45) !important;
}

.tw-decoration-orange-700\/5 {
  text-decoration-color: rgb(194 65 12 / 0.05) !important;
}

.tw-decoration-orange-700\/50 {
  text-decoration-color: rgb(194 65 12 / 0.5) !important;
}

.tw-decoration-orange-700\/55 {
  text-decoration-color: rgb(194 65 12 / 0.55) !important;
}

.tw-decoration-orange-700\/60 {
  text-decoration-color: rgb(194 65 12 / 0.6) !important;
}

.tw-decoration-orange-700\/65 {
  text-decoration-color: rgb(194 65 12 / 0.65) !important;
}

.tw-decoration-orange-700\/70 {
  text-decoration-color: rgb(194 65 12 / 0.7) !important;
}

.tw-decoration-orange-700\/75 {
  text-decoration-color: rgb(194 65 12 / 0.75) !important;
}

.tw-decoration-orange-700\/80 {
  text-decoration-color: rgb(194 65 12 / 0.8) !important;
}

.tw-decoration-orange-700\/85 {
  text-decoration-color: rgb(194 65 12 / 0.85) !important;
}

.tw-decoration-orange-700\/90 {
  text-decoration-color: rgb(194 65 12 / 0.9) !important;
}

.tw-decoration-orange-700\/95 {
  text-decoration-color: rgb(194 65 12 / 0.95) !important;
}

.tw-decoration-orange-800 {
  text-decoration-color: #9a3412 !important;
}

.tw-decoration-orange-800\/0 {
  text-decoration-color: rgb(154 52 18 / 0) !important;
}

.tw-decoration-orange-800\/10 {
  text-decoration-color: rgb(154 52 18 / 0.1) !important;
}

.tw-decoration-orange-800\/100 {
  text-decoration-color: rgb(154 52 18 / 1) !important;
}

.tw-decoration-orange-800\/15 {
  text-decoration-color: rgb(154 52 18 / 0.15) !important;
}

.tw-decoration-orange-800\/20 {
  text-decoration-color: rgb(154 52 18 / 0.2) !important;
}

.tw-decoration-orange-800\/25 {
  text-decoration-color: rgb(154 52 18 / 0.25) !important;
}

.tw-decoration-orange-800\/30 {
  text-decoration-color: rgb(154 52 18 / 0.3) !important;
}

.tw-decoration-orange-800\/35 {
  text-decoration-color: rgb(154 52 18 / 0.35) !important;
}

.tw-decoration-orange-800\/40 {
  text-decoration-color: rgb(154 52 18 / 0.4) !important;
}

.tw-decoration-orange-800\/45 {
  text-decoration-color: rgb(154 52 18 / 0.45) !important;
}

.tw-decoration-orange-800\/5 {
  text-decoration-color: rgb(154 52 18 / 0.05) !important;
}

.tw-decoration-orange-800\/50 {
  text-decoration-color: rgb(154 52 18 / 0.5) !important;
}

.tw-decoration-orange-800\/55 {
  text-decoration-color: rgb(154 52 18 / 0.55) !important;
}

.tw-decoration-orange-800\/60 {
  text-decoration-color: rgb(154 52 18 / 0.6) !important;
}

.tw-decoration-orange-800\/65 {
  text-decoration-color: rgb(154 52 18 / 0.65) !important;
}

.tw-decoration-orange-800\/70 {
  text-decoration-color: rgb(154 52 18 / 0.7) !important;
}

.tw-decoration-orange-800\/75 {
  text-decoration-color: rgb(154 52 18 / 0.75) !important;
}

.tw-decoration-orange-800\/80 {
  text-decoration-color: rgb(154 52 18 / 0.8) !important;
}

.tw-decoration-orange-800\/85 {
  text-decoration-color: rgb(154 52 18 / 0.85) !important;
}

.tw-decoration-orange-800\/90 {
  text-decoration-color: rgb(154 52 18 / 0.9) !important;
}

.tw-decoration-orange-800\/95 {
  text-decoration-color: rgb(154 52 18 / 0.95) !important;
}

.tw-decoration-orange-900 {
  text-decoration-color: #7c2d12 !important;
}

.tw-decoration-orange-900\/0 {
  text-decoration-color: rgb(124 45 18 / 0) !important;
}

.tw-decoration-orange-900\/10 {
  text-decoration-color: rgb(124 45 18 / 0.1) !important;
}

.tw-decoration-orange-900\/100 {
  text-decoration-color: rgb(124 45 18 / 1) !important;
}

.tw-decoration-orange-900\/15 {
  text-decoration-color: rgb(124 45 18 / 0.15) !important;
}

.tw-decoration-orange-900\/20 {
  text-decoration-color: rgb(124 45 18 / 0.2) !important;
}

.tw-decoration-orange-900\/25 {
  text-decoration-color: rgb(124 45 18 / 0.25) !important;
}

.tw-decoration-orange-900\/30 {
  text-decoration-color: rgb(124 45 18 / 0.3) !important;
}

.tw-decoration-orange-900\/35 {
  text-decoration-color: rgb(124 45 18 / 0.35) !important;
}

.tw-decoration-orange-900\/40 {
  text-decoration-color: rgb(124 45 18 / 0.4) !important;
}

.tw-decoration-orange-900\/45 {
  text-decoration-color: rgb(124 45 18 / 0.45) !important;
}

.tw-decoration-orange-900\/5 {
  text-decoration-color: rgb(124 45 18 / 0.05) !important;
}

.tw-decoration-orange-900\/50 {
  text-decoration-color: rgb(124 45 18 / 0.5) !important;
}

.tw-decoration-orange-900\/55 {
  text-decoration-color: rgb(124 45 18 / 0.55) !important;
}

.tw-decoration-orange-900\/60 {
  text-decoration-color: rgb(124 45 18 / 0.6) !important;
}

.tw-decoration-orange-900\/65 {
  text-decoration-color: rgb(124 45 18 / 0.65) !important;
}

.tw-decoration-orange-900\/70 {
  text-decoration-color: rgb(124 45 18 / 0.7) !important;
}

.tw-decoration-orange-900\/75 {
  text-decoration-color: rgb(124 45 18 / 0.75) !important;
}

.tw-decoration-orange-900\/80 {
  text-decoration-color: rgb(124 45 18 / 0.8) !important;
}

.tw-decoration-orange-900\/85 {
  text-decoration-color: rgb(124 45 18 / 0.85) !important;
}

.tw-decoration-orange-900\/90 {
  text-decoration-color: rgb(124 45 18 / 0.9) !important;
}

.tw-decoration-orange-900\/95 {
  text-decoration-color: rgb(124 45 18 / 0.95) !important;
}

.tw-decoration-orange-950 {
  text-decoration-color: #431407 !important;
}

.tw-decoration-orange-950\/0 {
  text-decoration-color: rgb(67 20 7 / 0) !important;
}

.tw-decoration-orange-950\/10 {
  text-decoration-color: rgb(67 20 7 / 0.1) !important;
}

.tw-decoration-orange-950\/100 {
  text-decoration-color: rgb(67 20 7 / 1) !important;
}

.tw-decoration-orange-950\/15 {
  text-decoration-color: rgb(67 20 7 / 0.15) !important;
}

.tw-decoration-orange-950\/20 {
  text-decoration-color: rgb(67 20 7 / 0.2) !important;
}

.tw-decoration-orange-950\/25 {
  text-decoration-color: rgb(67 20 7 / 0.25) !important;
}

.tw-decoration-orange-950\/30 {
  text-decoration-color: rgb(67 20 7 / 0.3) !important;
}

.tw-decoration-orange-950\/35 {
  text-decoration-color: rgb(67 20 7 / 0.35) !important;
}

.tw-decoration-orange-950\/40 {
  text-decoration-color: rgb(67 20 7 / 0.4) !important;
}

.tw-decoration-orange-950\/45 {
  text-decoration-color: rgb(67 20 7 / 0.45) !important;
}

.tw-decoration-orange-950\/5 {
  text-decoration-color: rgb(67 20 7 / 0.05) !important;
}

.tw-decoration-orange-950\/50 {
  text-decoration-color: rgb(67 20 7 / 0.5) !important;
}

.tw-decoration-orange-950\/55 {
  text-decoration-color: rgb(67 20 7 / 0.55) !important;
}

.tw-decoration-orange-950\/60 {
  text-decoration-color: rgb(67 20 7 / 0.6) !important;
}

.tw-decoration-orange-950\/65 {
  text-decoration-color: rgb(67 20 7 / 0.65) !important;
}

.tw-decoration-orange-950\/70 {
  text-decoration-color: rgb(67 20 7 / 0.7) !important;
}

.tw-decoration-orange-950\/75 {
  text-decoration-color: rgb(67 20 7 / 0.75) !important;
}

.tw-decoration-orange-950\/80 {
  text-decoration-color: rgb(67 20 7 / 0.8) !important;
}

.tw-decoration-orange-950\/85 {
  text-decoration-color: rgb(67 20 7 / 0.85) !important;
}

.tw-decoration-orange-950\/90 {
  text-decoration-color: rgb(67 20 7 / 0.9) !important;
}

.tw-decoration-orange-950\/95 {
  text-decoration-color: rgb(67 20 7 / 0.95) !important;
}

.tw-decoration-pink-100 {
  text-decoration-color: #fce7f3 !important;
}

.tw-decoration-pink-100\/0 {
  text-decoration-color: rgb(252 231 243 / 0) !important;
}

.tw-decoration-pink-100\/10 {
  text-decoration-color: rgb(252 231 243 / 0.1) !important;
}

.tw-decoration-pink-100\/100 {
  text-decoration-color: rgb(252 231 243 / 1) !important;
}

.tw-decoration-pink-100\/15 {
  text-decoration-color: rgb(252 231 243 / 0.15) !important;
}

.tw-decoration-pink-100\/20 {
  text-decoration-color: rgb(252 231 243 / 0.2) !important;
}

.tw-decoration-pink-100\/25 {
  text-decoration-color: rgb(252 231 243 / 0.25) !important;
}

.tw-decoration-pink-100\/30 {
  text-decoration-color: rgb(252 231 243 / 0.3) !important;
}

.tw-decoration-pink-100\/35 {
  text-decoration-color: rgb(252 231 243 / 0.35) !important;
}

.tw-decoration-pink-100\/40 {
  text-decoration-color: rgb(252 231 243 / 0.4) !important;
}

.tw-decoration-pink-100\/45 {
  text-decoration-color: rgb(252 231 243 / 0.45) !important;
}

.tw-decoration-pink-100\/5 {
  text-decoration-color: rgb(252 231 243 / 0.05) !important;
}

.tw-decoration-pink-100\/50 {
  text-decoration-color: rgb(252 231 243 / 0.5) !important;
}

.tw-decoration-pink-100\/55 {
  text-decoration-color: rgb(252 231 243 / 0.55) !important;
}

.tw-decoration-pink-100\/60 {
  text-decoration-color: rgb(252 231 243 / 0.6) !important;
}

.tw-decoration-pink-100\/65 {
  text-decoration-color: rgb(252 231 243 / 0.65) !important;
}

.tw-decoration-pink-100\/70 {
  text-decoration-color: rgb(252 231 243 / 0.7) !important;
}

.tw-decoration-pink-100\/75 {
  text-decoration-color: rgb(252 231 243 / 0.75) !important;
}

.tw-decoration-pink-100\/80 {
  text-decoration-color: rgb(252 231 243 / 0.8) !important;
}

.tw-decoration-pink-100\/85 {
  text-decoration-color: rgb(252 231 243 / 0.85) !important;
}

.tw-decoration-pink-100\/90 {
  text-decoration-color: rgb(252 231 243 / 0.9) !important;
}

.tw-decoration-pink-100\/95 {
  text-decoration-color: rgb(252 231 243 / 0.95) !important;
}

.tw-decoration-pink-200 {
  text-decoration-color: #fbcfe8 !important;
}

.tw-decoration-pink-200\/0 {
  text-decoration-color: rgb(251 207 232 / 0) !important;
}

.tw-decoration-pink-200\/10 {
  text-decoration-color: rgb(251 207 232 / 0.1) !important;
}

.tw-decoration-pink-200\/100 {
  text-decoration-color: rgb(251 207 232 / 1) !important;
}

.tw-decoration-pink-200\/15 {
  text-decoration-color: rgb(251 207 232 / 0.15) !important;
}

.tw-decoration-pink-200\/20 {
  text-decoration-color: rgb(251 207 232 / 0.2) !important;
}

.tw-decoration-pink-200\/25 {
  text-decoration-color: rgb(251 207 232 / 0.25) !important;
}

.tw-decoration-pink-200\/30 {
  text-decoration-color: rgb(251 207 232 / 0.3) !important;
}

.tw-decoration-pink-200\/35 {
  text-decoration-color: rgb(251 207 232 / 0.35) !important;
}

.tw-decoration-pink-200\/40 {
  text-decoration-color: rgb(251 207 232 / 0.4) !important;
}

.tw-decoration-pink-200\/45 {
  text-decoration-color: rgb(251 207 232 / 0.45) !important;
}

.tw-decoration-pink-200\/5 {
  text-decoration-color: rgb(251 207 232 / 0.05) !important;
}

.tw-decoration-pink-200\/50 {
  text-decoration-color: rgb(251 207 232 / 0.5) !important;
}

.tw-decoration-pink-200\/55 {
  text-decoration-color: rgb(251 207 232 / 0.55) !important;
}

.tw-decoration-pink-200\/60 {
  text-decoration-color: rgb(251 207 232 / 0.6) !important;
}

.tw-decoration-pink-200\/65 {
  text-decoration-color: rgb(251 207 232 / 0.65) !important;
}

.tw-decoration-pink-200\/70 {
  text-decoration-color: rgb(251 207 232 / 0.7) !important;
}

.tw-decoration-pink-200\/75 {
  text-decoration-color: rgb(251 207 232 / 0.75) !important;
}

.tw-decoration-pink-200\/80 {
  text-decoration-color: rgb(251 207 232 / 0.8) !important;
}

.tw-decoration-pink-200\/85 {
  text-decoration-color: rgb(251 207 232 / 0.85) !important;
}

.tw-decoration-pink-200\/90 {
  text-decoration-color: rgb(251 207 232 / 0.9) !important;
}

.tw-decoration-pink-200\/95 {
  text-decoration-color: rgb(251 207 232 / 0.95) !important;
}

.tw-decoration-pink-300 {
  text-decoration-color: #f9a8d4 !important;
}

.tw-decoration-pink-300\/0 {
  text-decoration-color: rgb(249 168 212 / 0) !important;
}

.tw-decoration-pink-300\/10 {
  text-decoration-color: rgb(249 168 212 / 0.1) !important;
}

.tw-decoration-pink-300\/100 {
  text-decoration-color: rgb(249 168 212 / 1) !important;
}

.tw-decoration-pink-300\/15 {
  text-decoration-color: rgb(249 168 212 / 0.15) !important;
}

.tw-decoration-pink-300\/20 {
  text-decoration-color: rgb(249 168 212 / 0.2) !important;
}

.tw-decoration-pink-300\/25 {
  text-decoration-color: rgb(249 168 212 / 0.25) !important;
}

.tw-decoration-pink-300\/30 {
  text-decoration-color: rgb(249 168 212 / 0.3) !important;
}

.tw-decoration-pink-300\/35 {
  text-decoration-color: rgb(249 168 212 / 0.35) !important;
}

.tw-decoration-pink-300\/40 {
  text-decoration-color: rgb(249 168 212 / 0.4) !important;
}

.tw-decoration-pink-300\/45 {
  text-decoration-color: rgb(249 168 212 / 0.45) !important;
}

.tw-decoration-pink-300\/5 {
  text-decoration-color: rgb(249 168 212 / 0.05) !important;
}

.tw-decoration-pink-300\/50 {
  text-decoration-color: rgb(249 168 212 / 0.5) !important;
}

.tw-decoration-pink-300\/55 {
  text-decoration-color: rgb(249 168 212 / 0.55) !important;
}

.tw-decoration-pink-300\/60 {
  text-decoration-color: rgb(249 168 212 / 0.6) !important;
}

.tw-decoration-pink-300\/65 {
  text-decoration-color: rgb(249 168 212 / 0.65) !important;
}

.tw-decoration-pink-300\/70 {
  text-decoration-color: rgb(249 168 212 / 0.7) !important;
}

.tw-decoration-pink-300\/75 {
  text-decoration-color: rgb(249 168 212 / 0.75) !important;
}

.tw-decoration-pink-300\/80 {
  text-decoration-color: rgb(249 168 212 / 0.8) !important;
}

.tw-decoration-pink-300\/85 {
  text-decoration-color: rgb(249 168 212 / 0.85) !important;
}

.tw-decoration-pink-300\/90 {
  text-decoration-color: rgb(249 168 212 / 0.9) !important;
}

.tw-decoration-pink-300\/95 {
  text-decoration-color: rgb(249 168 212 / 0.95) !important;
}

.tw-decoration-pink-400 {
  text-decoration-color: #f472b6 !important;
}

.tw-decoration-pink-400\/0 {
  text-decoration-color: rgb(244 114 182 / 0) !important;
}

.tw-decoration-pink-400\/10 {
  text-decoration-color: rgb(244 114 182 / 0.1) !important;
}

.tw-decoration-pink-400\/100 {
  text-decoration-color: rgb(244 114 182 / 1) !important;
}

.tw-decoration-pink-400\/15 {
  text-decoration-color: rgb(244 114 182 / 0.15) !important;
}

.tw-decoration-pink-400\/20 {
  text-decoration-color: rgb(244 114 182 / 0.2) !important;
}

.tw-decoration-pink-400\/25 {
  text-decoration-color: rgb(244 114 182 / 0.25) !important;
}

.tw-decoration-pink-400\/30 {
  text-decoration-color: rgb(244 114 182 / 0.3) !important;
}

.tw-decoration-pink-400\/35 {
  text-decoration-color: rgb(244 114 182 / 0.35) !important;
}

.tw-decoration-pink-400\/40 {
  text-decoration-color: rgb(244 114 182 / 0.4) !important;
}

.tw-decoration-pink-400\/45 {
  text-decoration-color: rgb(244 114 182 / 0.45) !important;
}

.tw-decoration-pink-400\/5 {
  text-decoration-color: rgb(244 114 182 / 0.05) !important;
}

.tw-decoration-pink-400\/50 {
  text-decoration-color: rgb(244 114 182 / 0.5) !important;
}

.tw-decoration-pink-400\/55 {
  text-decoration-color: rgb(244 114 182 / 0.55) !important;
}

.tw-decoration-pink-400\/60 {
  text-decoration-color: rgb(244 114 182 / 0.6) !important;
}

.tw-decoration-pink-400\/65 {
  text-decoration-color: rgb(244 114 182 / 0.65) !important;
}

.tw-decoration-pink-400\/70 {
  text-decoration-color: rgb(244 114 182 / 0.7) !important;
}

.tw-decoration-pink-400\/75 {
  text-decoration-color: rgb(244 114 182 / 0.75) !important;
}

.tw-decoration-pink-400\/80 {
  text-decoration-color: rgb(244 114 182 / 0.8) !important;
}

.tw-decoration-pink-400\/85 {
  text-decoration-color: rgb(244 114 182 / 0.85) !important;
}

.tw-decoration-pink-400\/90 {
  text-decoration-color: rgb(244 114 182 / 0.9) !important;
}

.tw-decoration-pink-400\/95 {
  text-decoration-color: rgb(244 114 182 / 0.95) !important;
}

.tw-decoration-pink-50 {
  text-decoration-color: #fdf2f8 !important;
}

.tw-decoration-pink-50\/0 {
  text-decoration-color: rgb(253 242 248 / 0) !important;
}

.tw-decoration-pink-50\/10 {
  text-decoration-color: rgb(253 242 248 / 0.1) !important;
}

.tw-decoration-pink-50\/100 {
  text-decoration-color: rgb(253 242 248 / 1) !important;
}

.tw-decoration-pink-50\/15 {
  text-decoration-color: rgb(253 242 248 / 0.15) !important;
}

.tw-decoration-pink-50\/20 {
  text-decoration-color: rgb(253 242 248 / 0.2) !important;
}

.tw-decoration-pink-50\/25 {
  text-decoration-color: rgb(253 242 248 / 0.25) !important;
}

.tw-decoration-pink-50\/30 {
  text-decoration-color: rgb(253 242 248 / 0.3) !important;
}

.tw-decoration-pink-50\/35 {
  text-decoration-color: rgb(253 242 248 / 0.35) !important;
}

.tw-decoration-pink-50\/40 {
  text-decoration-color: rgb(253 242 248 / 0.4) !important;
}

.tw-decoration-pink-50\/45 {
  text-decoration-color: rgb(253 242 248 / 0.45) !important;
}

.tw-decoration-pink-50\/5 {
  text-decoration-color: rgb(253 242 248 / 0.05) !important;
}

.tw-decoration-pink-50\/50 {
  text-decoration-color: rgb(253 242 248 / 0.5) !important;
}

.tw-decoration-pink-50\/55 {
  text-decoration-color: rgb(253 242 248 / 0.55) !important;
}

.tw-decoration-pink-50\/60 {
  text-decoration-color: rgb(253 242 248 / 0.6) !important;
}

.tw-decoration-pink-50\/65 {
  text-decoration-color: rgb(253 242 248 / 0.65) !important;
}

.tw-decoration-pink-50\/70 {
  text-decoration-color: rgb(253 242 248 / 0.7) !important;
}

.tw-decoration-pink-50\/75 {
  text-decoration-color: rgb(253 242 248 / 0.75) !important;
}

.tw-decoration-pink-50\/80 {
  text-decoration-color: rgb(253 242 248 / 0.8) !important;
}

.tw-decoration-pink-50\/85 {
  text-decoration-color: rgb(253 242 248 / 0.85) !important;
}

.tw-decoration-pink-50\/90 {
  text-decoration-color: rgb(253 242 248 / 0.9) !important;
}

.tw-decoration-pink-50\/95 {
  text-decoration-color: rgb(253 242 248 / 0.95) !important;
}

.tw-decoration-pink-500 {
  text-decoration-color: #ec4899 !important;
}

.tw-decoration-pink-500\/0 {
  text-decoration-color: rgb(236 72 153 / 0) !important;
}

.tw-decoration-pink-500\/10 {
  text-decoration-color: rgb(236 72 153 / 0.1) !important;
}

.tw-decoration-pink-500\/100 {
  text-decoration-color: rgb(236 72 153 / 1) !important;
}

.tw-decoration-pink-500\/15 {
  text-decoration-color: rgb(236 72 153 / 0.15) !important;
}

.tw-decoration-pink-500\/20 {
  text-decoration-color: rgb(236 72 153 / 0.2) !important;
}

.tw-decoration-pink-500\/25 {
  text-decoration-color: rgb(236 72 153 / 0.25) !important;
}

.tw-decoration-pink-500\/30 {
  text-decoration-color: rgb(236 72 153 / 0.3) !important;
}

.tw-decoration-pink-500\/35 {
  text-decoration-color: rgb(236 72 153 / 0.35) !important;
}

.tw-decoration-pink-500\/40 {
  text-decoration-color: rgb(236 72 153 / 0.4) !important;
}

.tw-decoration-pink-500\/45 {
  text-decoration-color: rgb(236 72 153 / 0.45) !important;
}

.tw-decoration-pink-500\/5 {
  text-decoration-color: rgb(236 72 153 / 0.05) !important;
}

.tw-decoration-pink-500\/50 {
  text-decoration-color: rgb(236 72 153 / 0.5) !important;
}

.tw-decoration-pink-500\/55 {
  text-decoration-color: rgb(236 72 153 / 0.55) !important;
}

.tw-decoration-pink-500\/60 {
  text-decoration-color: rgb(236 72 153 / 0.6) !important;
}

.tw-decoration-pink-500\/65 {
  text-decoration-color: rgb(236 72 153 / 0.65) !important;
}

.tw-decoration-pink-500\/70 {
  text-decoration-color: rgb(236 72 153 / 0.7) !important;
}

.tw-decoration-pink-500\/75 {
  text-decoration-color: rgb(236 72 153 / 0.75) !important;
}

.tw-decoration-pink-500\/80 {
  text-decoration-color: rgb(236 72 153 / 0.8) !important;
}

.tw-decoration-pink-500\/85 {
  text-decoration-color: rgb(236 72 153 / 0.85) !important;
}

.tw-decoration-pink-500\/90 {
  text-decoration-color: rgb(236 72 153 / 0.9) !important;
}

.tw-decoration-pink-500\/95 {
  text-decoration-color: rgb(236 72 153 / 0.95) !important;
}

.tw-decoration-pink-600 {
  text-decoration-color: #db2777 !important;
}

.tw-decoration-pink-600\/0 {
  text-decoration-color: rgb(219 39 119 / 0) !important;
}

.tw-decoration-pink-600\/10 {
  text-decoration-color: rgb(219 39 119 / 0.1) !important;
}

.tw-decoration-pink-600\/100 {
  text-decoration-color: rgb(219 39 119 / 1) !important;
}

.tw-decoration-pink-600\/15 {
  text-decoration-color: rgb(219 39 119 / 0.15) !important;
}

.tw-decoration-pink-600\/20 {
  text-decoration-color: rgb(219 39 119 / 0.2) !important;
}

.tw-decoration-pink-600\/25 {
  text-decoration-color: rgb(219 39 119 / 0.25) !important;
}

.tw-decoration-pink-600\/30 {
  text-decoration-color: rgb(219 39 119 / 0.3) !important;
}

.tw-decoration-pink-600\/35 {
  text-decoration-color: rgb(219 39 119 / 0.35) !important;
}

.tw-decoration-pink-600\/40 {
  text-decoration-color: rgb(219 39 119 / 0.4) !important;
}

.tw-decoration-pink-600\/45 {
  text-decoration-color: rgb(219 39 119 / 0.45) !important;
}

.tw-decoration-pink-600\/5 {
  text-decoration-color: rgb(219 39 119 / 0.05) !important;
}

.tw-decoration-pink-600\/50 {
  text-decoration-color: rgb(219 39 119 / 0.5) !important;
}

.tw-decoration-pink-600\/55 {
  text-decoration-color: rgb(219 39 119 / 0.55) !important;
}

.tw-decoration-pink-600\/60 {
  text-decoration-color: rgb(219 39 119 / 0.6) !important;
}

.tw-decoration-pink-600\/65 {
  text-decoration-color: rgb(219 39 119 / 0.65) !important;
}

.tw-decoration-pink-600\/70 {
  text-decoration-color: rgb(219 39 119 / 0.7) !important;
}

.tw-decoration-pink-600\/75 {
  text-decoration-color: rgb(219 39 119 / 0.75) !important;
}

.tw-decoration-pink-600\/80 {
  text-decoration-color: rgb(219 39 119 / 0.8) !important;
}

.tw-decoration-pink-600\/85 {
  text-decoration-color: rgb(219 39 119 / 0.85) !important;
}

.tw-decoration-pink-600\/90 {
  text-decoration-color: rgb(219 39 119 / 0.9) !important;
}

.tw-decoration-pink-600\/95 {
  text-decoration-color: rgb(219 39 119 / 0.95) !important;
}

.tw-decoration-pink-700 {
  text-decoration-color: #be185d !important;
}

.tw-decoration-pink-700\/0 {
  text-decoration-color: rgb(190 24 93 / 0) !important;
}

.tw-decoration-pink-700\/10 {
  text-decoration-color: rgb(190 24 93 / 0.1) !important;
}

.tw-decoration-pink-700\/100 {
  text-decoration-color: rgb(190 24 93 / 1) !important;
}

.tw-decoration-pink-700\/15 {
  text-decoration-color: rgb(190 24 93 / 0.15) !important;
}

.tw-decoration-pink-700\/20 {
  text-decoration-color: rgb(190 24 93 / 0.2) !important;
}

.tw-decoration-pink-700\/25 {
  text-decoration-color: rgb(190 24 93 / 0.25) !important;
}

.tw-decoration-pink-700\/30 {
  text-decoration-color: rgb(190 24 93 / 0.3) !important;
}

.tw-decoration-pink-700\/35 {
  text-decoration-color: rgb(190 24 93 / 0.35) !important;
}

.tw-decoration-pink-700\/40 {
  text-decoration-color: rgb(190 24 93 / 0.4) !important;
}

.tw-decoration-pink-700\/45 {
  text-decoration-color: rgb(190 24 93 / 0.45) !important;
}

.tw-decoration-pink-700\/5 {
  text-decoration-color: rgb(190 24 93 / 0.05) !important;
}

.tw-decoration-pink-700\/50 {
  text-decoration-color: rgb(190 24 93 / 0.5) !important;
}

.tw-decoration-pink-700\/55 {
  text-decoration-color: rgb(190 24 93 / 0.55) !important;
}

.tw-decoration-pink-700\/60 {
  text-decoration-color: rgb(190 24 93 / 0.6) !important;
}

.tw-decoration-pink-700\/65 {
  text-decoration-color: rgb(190 24 93 / 0.65) !important;
}

.tw-decoration-pink-700\/70 {
  text-decoration-color: rgb(190 24 93 / 0.7) !important;
}

.tw-decoration-pink-700\/75 {
  text-decoration-color: rgb(190 24 93 / 0.75) !important;
}

.tw-decoration-pink-700\/80 {
  text-decoration-color: rgb(190 24 93 / 0.8) !important;
}

.tw-decoration-pink-700\/85 {
  text-decoration-color: rgb(190 24 93 / 0.85) !important;
}

.tw-decoration-pink-700\/90 {
  text-decoration-color: rgb(190 24 93 / 0.9) !important;
}

.tw-decoration-pink-700\/95 {
  text-decoration-color: rgb(190 24 93 / 0.95) !important;
}

.tw-decoration-pink-800 {
  text-decoration-color: #9d174d !important;
}

.tw-decoration-pink-800\/0 {
  text-decoration-color: rgb(157 23 77 / 0) !important;
}

.tw-decoration-pink-800\/10 {
  text-decoration-color: rgb(157 23 77 / 0.1) !important;
}

.tw-decoration-pink-800\/100 {
  text-decoration-color: rgb(157 23 77 / 1) !important;
}

.tw-decoration-pink-800\/15 {
  text-decoration-color: rgb(157 23 77 / 0.15) !important;
}

.tw-decoration-pink-800\/20 {
  text-decoration-color: rgb(157 23 77 / 0.2) !important;
}

.tw-decoration-pink-800\/25 {
  text-decoration-color: rgb(157 23 77 / 0.25) !important;
}

.tw-decoration-pink-800\/30 {
  text-decoration-color: rgb(157 23 77 / 0.3) !important;
}

.tw-decoration-pink-800\/35 {
  text-decoration-color: rgb(157 23 77 / 0.35) !important;
}

.tw-decoration-pink-800\/40 {
  text-decoration-color: rgb(157 23 77 / 0.4) !important;
}

.tw-decoration-pink-800\/45 {
  text-decoration-color: rgb(157 23 77 / 0.45) !important;
}

.tw-decoration-pink-800\/5 {
  text-decoration-color: rgb(157 23 77 / 0.05) !important;
}

.tw-decoration-pink-800\/50 {
  text-decoration-color: rgb(157 23 77 / 0.5) !important;
}

.tw-decoration-pink-800\/55 {
  text-decoration-color: rgb(157 23 77 / 0.55) !important;
}

.tw-decoration-pink-800\/60 {
  text-decoration-color: rgb(157 23 77 / 0.6) !important;
}

.tw-decoration-pink-800\/65 {
  text-decoration-color: rgb(157 23 77 / 0.65) !important;
}

.tw-decoration-pink-800\/70 {
  text-decoration-color: rgb(157 23 77 / 0.7) !important;
}

.tw-decoration-pink-800\/75 {
  text-decoration-color: rgb(157 23 77 / 0.75) !important;
}

.tw-decoration-pink-800\/80 {
  text-decoration-color: rgb(157 23 77 / 0.8) !important;
}

.tw-decoration-pink-800\/85 {
  text-decoration-color: rgb(157 23 77 / 0.85) !important;
}

.tw-decoration-pink-800\/90 {
  text-decoration-color: rgb(157 23 77 / 0.9) !important;
}

.tw-decoration-pink-800\/95 {
  text-decoration-color: rgb(157 23 77 / 0.95) !important;
}

.tw-decoration-pink-900 {
  text-decoration-color: #831843 !important;
}

.tw-decoration-pink-900\/0 {
  text-decoration-color: rgb(131 24 67 / 0) !important;
}

.tw-decoration-pink-900\/10 {
  text-decoration-color: rgb(131 24 67 / 0.1) !important;
}

.tw-decoration-pink-900\/100 {
  text-decoration-color: rgb(131 24 67 / 1) !important;
}

.tw-decoration-pink-900\/15 {
  text-decoration-color: rgb(131 24 67 / 0.15) !important;
}

.tw-decoration-pink-900\/20 {
  text-decoration-color: rgb(131 24 67 / 0.2) !important;
}

.tw-decoration-pink-900\/25 {
  text-decoration-color: rgb(131 24 67 / 0.25) !important;
}

.tw-decoration-pink-900\/30 {
  text-decoration-color: rgb(131 24 67 / 0.3) !important;
}

.tw-decoration-pink-900\/35 {
  text-decoration-color: rgb(131 24 67 / 0.35) !important;
}

.tw-decoration-pink-900\/40 {
  text-decoration-color: rgb(131 24 67 / 0.4) !important;
}

.tw-decoration-pink-900\/45 {
  text-decoration-color: rgb(131 24 67 / 0.45) !important;
}

.tw-decoration-pink-900\/5 {
  text-decoration-color: rgb(131 24 67 / 0.05) !important;
}

.tw-decoration-pink-900\/50 {
  text-decoration-color: rgb(131 24 67 / 0.5) !important;
}

.tw-decoration-pink-900\/55 {
  text-decoration-color: rgb(131 24 67 / 0.55) !important;
}

.tw-decoration-pink-900\/60 {
  text-decoration-color: rgb(131 24 67 / 0.6) !important;
}

.tw-decoration-pink-900\/65 {
  text-decoration-color: rgb(131 24 67 / 0.65) !important;
}

.tw-decoration-pink-900\/70 {
  text-decoration-color: rgb(131 24 67 / 0.7) !important;
}

.tw-decoration-pink-900\/75 {
  text-decoration-color: rgb(131 24 67 / 0.75) !important;
}

.tw-decoration-pink-900\/80 {
  text-decoration-color: rgb(131 24 67 / 0.8) !important;
}

.tw-decoration-pink-900\/85 {
  text-decoration-color: rgb(131 24 67 / 0.85) !important;
}

.tw-decoration-pink-900\/90 {
  text-decoration-color: rgb(131 24 67 / 0.9) !important;
}

.tw-decoration-pink-900\/95 {
  text-decoration-color: rgb(131 24 67 / 0.95) !important;
}

.tw-decoration-pink-950 {
  text-decoration-color: #500724 !important;
}

.tw-decoration-pink-950\/0 {
  text-decoration-color: rgb(80 7 36 / 0) !important;
}

.tw-decoration-pink-950\/10 {
  text-decoration-color: rgb(80 7 36 / 0.1) !important;
}

.tw-decoration-pink-950\/100 {
  text-decoration-color: rgb(80 7 36 / 1) !important;
}

.tw-decoration-pink-950\/15 {
  text-decoration-color: rgb(80 7 36 / 0.15) !important;
}

.tw-decoration-pink-950\/20 {
  text-decoration-color: rgb(80 7 36 / 0.2) !important;
}

.tw-decoration-pink-950\/25 {
  text-decoration-color: rgb(80 7 36 / 0.25) !important;
}

.tw-decoration-pink-950\/30 {
  text-decoration-color: rgb(80 7 36 / 0.3) !important;
}

.tw-decoration-pink-950\/35 {
  text-decoration-color: rgb(80 7 36 / 0.35) !important;
}

.tw-decoration-pink-950\/40 {
  text-decoration-color: rgb(80 7 36 / 0.4) !important;
}

.tw-decoration-pink-950\/45 {
  text-decoration-color: rgb(80 7 36 / 0.45) !important;
}

.tw-decoration-pink-950\/5 {
  text-decoration-color: rgb(80 7 36 / 0.05) !important;
}

.tw-decoration-pink-950\/50 {
  text-decoration-color: rgb(80 7 36 / 0.5) !important;
}

.tw-decoration-pink-950\/55 {
  text-decoration-color: rgb(80 7 36 / 0.55) !important;
}

.tw-decoration-pink-950\/60 {
  text-decoration-color: rgb(80 7 36 / 0.6) !important;
}

.tw-decoration-pink-950\/65 {
  text-decoration-color: rgb(80 7 36 / 0.65) !important;
}

.tw-decoration-pink-950\/70 {
  text-decoration-color: rgb(80 7 36 / 0.7) !important;
}

.tw-decoration-pink-950\/75 {
  text-decoration-color: rgb(80 7 36 / 0.75) !important;
}

.tw-decoration-pink-950\/80 {
  text-decoration-color: rgb(80 7 36 / 0.8) !important;
}

.tw-decoration-pink-950\/85 {
  text-decoration-color: rgb(80 7 36 / 0.85) !important;
}

.tw-decoration-pink-950\/90 {
  text-decoration-color: rgb(80 7 36 / 0.9) !important;
}

.tw-decoration-pink-950\/95 {
  text-decoration-color: rgb(80 7 36 / 0.95) !important;
}

.tw-decoration-purple-100 {
  text-decoration-color: #f3e8ff !important;
}

.tw-decoration-purple-100\/0 {
  text-decoration-color: rgb(243 232 255 / 0) !important;
}

.tw-decoration-purple-100\/10 {
  text-decoration-color: rgb(243 232 255 / 0.1) !important;
}

.tw-decoration-purple-100\/100 {
  text-decoration-color: rgb(243 232 255 / 1) !important;
}

.tw-decoration-purple-100\/15 {
  text-decoration-color: rgb(243 232 255 / 0.15) !important;
}

.tw-decoration-purple-100\/20 {
  text-decoration-color: rgb(243 232 255 / 0.2) !important;
}

.tw-decoration-purple-100\/25 {
  text-decoration-color: rgb(243 232 255 / 0.25) !important;
}

.tw-decoration-purple-100\/30 {
  text-decoration-color: rgb(243 232 255 / 0.3) !important;
}

.tw-decoration-purple-100\/35 {
  text-decoration-color: rgb(243 232 255 / 0.35) !important;
}

.tw-decoration-purple-100\/40 {
  text-decoration-color: rgb(243 232 255 / 0.4) !important;
}

.tw-decoration-purple-100\/45 {
  text-decoration-color: rgb(243 232 255 / 0.45) !important;
}

.tw-decoration-purple-100\/5 {
  text-decoration-color: rgb(243 232 255 / 0.05) !important;
}

.tw-decoration-purple-100\/50 {
  text-decoration-color: rgb(243 232 255 / 0.5) !important;
}

.tw-decoration-purple-100\/55 {
  text-decoration-color: rgb(243 232 255 / 0.55) !important;
}

.tw-decoration-purple-100\/60 {
  text-decoration-color: rgb(243 232 255 / 0.6) !important;
}

.tw-decoration-purple-100\/65 {
  text-decoration-color: rgb(243 232 255 / 0.65) !important;
}

.tw-decoration-purple-100\/70 {
  text-decoration-color: rgb(243 232 255 / 0.7) !important;
}

.tw-decoration-purple-100\/75 {
  text-decoration-color: rgb(243 232 255 / 0.75) !important;
}

.tw-decoration-purple-100\/80 {
  text-decoration-color: rgb(243 232 255 / 0.8) !important;
}

.tw-decoration-purple-100\/85 {
  text-decoration-color: rgb(243 232 255 / 0.85) !important;
}

.tw-decoration-purple-100\/90 {
  text-decoration-color: rgb(243 232 255 / 0.9) !important;
}

.tw-decoration-purple-100\/95 {
  text-decoration-color: rgb(243 232 255 / 0.95) !important;
}

.tw-decoration-purple-200 {
  text-decoration-color: #e9d5ff !important;
}

.tw-decoration-purple-200\/0 {
  text-decoration-color: rgb(233 213 255 / 0) !important;
}

.tw-decoration-purple-200\/10 {
  text-decoration-color: rgb(233 213 255 / 0.1) !important;
}

.tw-decoration-purple-200\/100 {
  text-decoration-color: rgb(233 213 255 / 1) !important;
}

.tw-decoration-purple-200\/15 {
  text-decoration-color: rgb(233 213 255 / 0.15) !important;
}

.tw-decoration-purple-200\/20 {
  text-decoration-color: rgb(233 213 255 / 0.2) !important;
}

.tw-decoration-purple-200\/25 {
  text-decoration-color: rgb(233 213 255 / 0.25) !important;
}

.tw-decoration-purple-200\/30 {
  text-decoration-color: rgb(233 213 255 / 0.3) !important;
}

.tw-decoration-purple-200\/35 {
  text-decoration-color: rgb(233 213 255 / 0.35) !important;
}

.tw-decoration-purple-200\/40 {
  text-decoration-color: rgb(233 213 255 / 0.4) !important;
}

.tw-decoration-purple-200\/45 {
  text-decoration-color: rgb(233 213 255 / 0.45) !important;
}

.tw-decoration-purple-200\/5 {
  text-decoration-color: rgb(233 213 255 / 0.05) !important;
}

.tw-decoration-purple-200\/50 {
  text-decoration-color: rgb(233 213 255 / 0.5) !important;
}

.tw-decoration-purple-200\/55 {
  text-decoration-color: rgb(233 213 255 / 0.55) !important;
}

.tw-decoration-purple-200\/60 {
  text-decoration-color: rgb(233 213 255 / 0.6) !important;
}

.tw-decoration-purple-200\/65 {
  text-decoration-color: rgb(233 213 255 / 0.65) !important;
}

.tw-decoration-purple-200\/70 {
  text-decoration-color: rgb(233 213 255 / 0.7) !important;
}

.tw-decoration-purple-200\/75 {
  text-decoration-color: rgb(233 213 255 / 0.75) !important;
}

.tw-decoration-purple-200\/80 {
  text-decoration-color: rgb(233 213 255 / 0.8) !important;
}

.tw-decoration-purple-200\/85 {
  text-decoration-color: rgb(233 213 255 / 0.85) !important;
}

.tw-decoration-purple-200\/90 {
  text-decoration-color: rgb(233 213 255 / 0.9) !important;
}

.tw-decoration-purple-200\/95 {
  text-decoration-color: rgb(233 213 255 / 0.95) !important;
}

.tw-decoration-purple-300 {
  text-decoration-color: #d8b4fe !important;
}

.tw-decoration-purple-300\/0 {
  text-decoration-color: rgb(216 180 254 / 0) !important;
}

.tw-decoration-purple-300\/10 {
  text-decoration-color: rgb(216 180 254 / 0.1) !important;
}

.tw-decoration-purple-300\/100 {
  text-decoration-color: rgb(216 180 254 / 1) !important;
}

.tw-decoration-purple-300\/15 {
  text-decoration-color: rgb(216 180 254 / 0.15) !important;
}

.tw-decoration-purple-300\/20 {
  text-decoration-color: rgb(216 180 254 / 0.2) !important;
}

.tw-decoration-purple-300\/25 {
  text-decoration-color: rgb(216 180 254 / 0.25) !important;
}

.tw-decoration-purple-300\/30 {
  text-decoration-color: rgb(216 180 254 / 0.3) !important;
}

.tw-decoration-purple-300\/35 {
  text-decoration-color: rgb(216 180 254 / 0.35) !important;
}

.tw-decoration-purple-300\/40 {
  text-decoration-color: rgb(216 180 254 / 0.4) !important;
}

.tw-decoration-purple-300\/45 {
  text-decoration-color: rgb(216 180 254 / 0.45) !important;
}

.tw-decoration-purple-300\/5 {
  text-decoration-color: rgb(216 180 254 / 0.05) !important;
}

.tw-decoration-purple-300\/50 {
  text-decoration-color: rgb(216 180 254 / 0.5) !important;
}

.tw-decoration-purple-300\/55 {
  text-decoration-color: rgb(216 180 254 / 0.55) !important;
}

.tw-decoration-purple-300\/60 {
  text-decoration-color: rgb(216 180 254 / 0.6) !important;
}

.tw-decoration-purple-300\/65 {
  text-decoration-color: rgb(216 180 254 / 0.65) !important;
}

.tw-decoration-purple-300\/70 {
  text-decoration-color: rgb(216 180 254 / 0.7) !important;
}

.tw-decoration-purple-300\/75 {
  text-decoration-color: rgb(216 180 254 / 0.75) !important;
}

.tw-decoration-purple-300\/80 {
  text-decoration-color: rgb(216 180 254 / 0.8) !important;
}

.tw-decoration-purple-300\/85 {
  text-decoration-color: rgb(216 180 254 / 0.85) !important;
}

.tw-decoration-purple-300\/90 {
  text-decoration-color: rgb(216 180 254 / 0.9) !important;
}

.tw-decoration-purple-300\/95 {
  text-decoration-color: rgb(216 180 254 / 0.95) !important;
}

.tw-decoration-purple-400 {
  text-decoration-color: #c084fc !important;
}

.tw-decoration-purple-400\/0 {
  text-decoration-color: rgb(192 132 252 / 0) !important;
}

.tw-decoration-purple-400\/10 {
  text-decoration-color: rgb(192 132 252 / 0.1) !important;
}

.tw-decoration-purple-400\/100 {
  text-decoration-color: rgb(192 132 252 / 1) !important;
}

.tw-decoration-purple-400\/15 {
  text-decoration-color: rgb(192 132 252 / 0.15) !important;
}

.tw-decoration-purple-400\/20 {
  text-decoration-color: rgb(192 132 252 / 0.2) !important;
}

.tw-decoration-purple-400\/25 {
  text-decoration-color: rgb(192 132 252 / 0.25) !important;
}

.tw-decoration-purple-400\/30 {
  text-decoration-color: rgb(192 132 252 / 0.3) !important;
}

.tw-decoration-purple-400\/35 {
  text-decoration-color: rgb(192 132 252 / 0.35) !important;
}

.tw-decoration-purple-400\/40 {
  text-decoration-color: rgb(192 132 252 / 0.4) !important;
}

.tw-decoration-purple-400\/45 {
  text-decoration-color: rgb(192 132 252 / 0.45) !important;
}

.tw-decoration-purple-400\/5 {
  text-decoration-color: rgb(192 132 252 / 0.05) !important;
}

.tw-decoration-purple-400\/50 {
  text-decoration-color: rgb(192 132 252 / 0.5) !important;
}

.tw-decoration-purple-400\/55 {
  text-decoration-color: rgb(192 132 252 / 0.55) !important;
}

.tw-decoration-purple-400\/60 {
  text-decoration-color: rgb(192 132 252 / 0.6) !important;
}

.tw-decoration-purple-400\/65 {
  text-decoration-color: rgb(192 132 252 / 0.65) !important;
}

.tw-decoration-purple-400\/70 {
  text-decoration-color: rgb(192 132 252 / 0.7) !important;
}

.tw-decoration-purple-400\/75 {
  text-decoration-color: rgb(192 132 252 / 0.75) !important;
}

.tw-decoration-purple-400\/80 {
  text-decoration-color: rgb(192 132 252 / 0.8) !important;
}

.tw-decoration-purple-400\/85 {
  text-decoration-color: rgb(192 132 252 / 0.85) !important;
}

.tw-decoration-purple-400\/90 {
  text-decoration-color: rgb(192 132 252 / 0.9) !important;
}

.tw-decoration-purple-400\/95 {
  text-decoration-color: rgb(192 132 252 / 0.95) !important;
}

.tw-decoration-purple-50 {
  text-decoration-color: #faf5ff !important;
}

.tw-decoration-purple-50\/0 {
  text-decoration-color: rgb(250 245 255 / 0) !important;
}

.tw-decoration-purple-50\/10 {
  text-decoration-color: rgb(250 245 255 / 0.1) !important;
}

.tw-decoration-purple-50\/100 {
  text-decoration-color: rgb(250 245 255 / 1) !important;
}

.tw-decoration-purple-50\/15 {
  text-decoration-color: rgb(250 245 255 / 0.15) !important;
}

.tw-decoration-purple-50\/20 {
  text-decoration-color: rgb(250 245 255 / 0.2) !important;
}

.tw-decoration-purple-50\/25 {
  text-decoration-color: rgb(250 245 255 / 0.25) !important;
}

.tw-decoration-purple-50\/30 {
  text-decoration-color: rgb(250 245 255 / 0.3) !important;
}

.tw-decoration-purple-50\/35 {
  text-decoration-color: rgb(250 245 255 / 0.35) !important;
}

.tw-decoration-purple-50\/40 {
  text-decoration-color: rgb(250 245 255 / 0.4) !important;
}

.tw-decoration-purple-50\/45 {
  text-decoration-color: rgb(250 245 255 / 0.45) !important;
}

.tw-decoration-purple-50\/5 {
  text-decoration-color: rgb(250 245 255 / 0.05) !important;
}

.tw-decoration-purple-50\/50 {
  text-decoration-color: rgb(250 245 255 / 0.5) !important;
}

.tw-decoration-purple-50\/55 {
  text-decoration-color: rgb(250 245 255 / 0.55) !important;
}

.tw-decoration-purple-50\/60 {
  text-decoration-color: rgb(250 245 255 / 0.6) !important;
}

.tw-decoration-purple-50\/65 {
  text-decoration-color: rgb(250 245 255 / 0.65) !important;
}

.tw-decoration-purple-50\/70 {
  text-decoration-color: rgb(250 245 255 / 0.7) !important;
}

.tw-decoration-purple-50\/75 {
  text-decoration-color: rgb(250 245 255 / 0.75) !important;
}

.tw-decoration-purple-50\/80 {
  text-decoration-color: rgb(250 245 255 / 0.8) !important;
}

.tw-decoration-purple-50\/85 {
  text-decoration-color: rgb(250 245 255 / 0.85) !important;
}

.tw-decoration-purple-50\/90 {
  text-decoration-color: rgb(250 245 255 / 0.9) !important;
}

.tw-decoration-purple-50\/95 {
  text-decoration-color: rgb(250 245 255 / 0.95) !important;
}

.tw-decoration-purple-500 {
  text-decoration-color: #a855f7 !important;
}

.tw-decoration-purple-500\/0 {
  text-decoration-color: rgb(168 85 247 / 0) !important;
}

.tw-decoration-purple-500\/10 {
  text-decoration-color: rgb(168 85 247 / 0.1) !important;
}

.tw-decoration-purple-500\/100 {
  text-decoration-color: rgb(168 85 247 / 1) !important;
}

.tw-decoration-purple-500\/15 {
  text-decoration-color: rgb(168 85 247 / 0.15) !important;
}

.tw-decoration-purple-500\/20 {
  text-decoration-color: rgb(168 85 247 / 0.2) !important;
}

.tw-decoration-purple-500\/25 {
  text-decoration-color: rgb(168 85 247 / 0.25) !important;
}

.tw-decoration-purple-500\/30 {
  text-decoration-color: rgb(168 85 247 / 0.3) !important;
}

.tw-decoration-purple-500\/35 {
  text-decoration-color: rgb(168 85 247 / 0.35) !important;
}

.tw-decoration-purple-500\/40 {
  text-decoration-color: rgb(168 85 247 / 0.4) !important;
}

.tw-decoration-purple-500\/45 {
  text-decoration-color: rgb(168 85 247 / 0.45) !important;
}

.tw-decoration-purple-500\/5 {
  text-decoration-color: rgb(168 85 247 / 0.05) !important;
}

.tw-decoration-purple-500\/50 {
  text-decoration-color: rgb(168 85 247 / 0.5) !important;
}

.tw-decoration-purple-500\/55 {
  text-decoration-color: rgb(168 85 247 / 0.55) !important;
}

.tw-decoration-purple-500\/60 {
  text-decoration-color: rgb(168 85 247 / 0.6) !important;
}

.tw-decoration-purple-500\/65 {
  text-decoration-color: rgb(168 85 247 / 0.65) !important;
}

.tw-decoration-purple-500\/70 {
  text-decoration-color: rgb(168 85 247 / 0.7) !important;
}

.tw-decoration-purple-500\/75 {
  text-decoration-color: rgb(168 85 247 / 0.75) !important;
}

.tw-decoration-purple-500\/80 {
  text-decoration-color: rgb(168 85 247 / 0.8) !important;
}

.tw-decoration-purple-500\/85 {
  text-decoration-color: rgb(168 85 247 / 0.85) !important;
}

.tw-decoration-purple-500\/90 {
  text-decoration-color: rgb(168 85 247 / 0.9) !important;
}

.tw-decoration-purple-500\/95 {
  text-decoration-color: rgb(168 85 247 / 0.95) !important;
}

.tw-decoration-purple-600 {
  text-decoration-color: #9333ea !important;
}

.tw-decoration-purple-600\/0 {
  text-decoration-color: rgb(147 51 234 / 0) !important;
}

.tw-decoration-purple-600\/10 {
  text-decoration-color: rgb(147 51 234 / 0.1) !important;
}

.tw-decoration-purple-600\/100 {
  text-decoration-color: rgb(147 51 234 / 1) !important;
}

.tw-decoration-purple-600\/15 {
  text-decoration-color: rgb(147 51 234 / 0.15) !important;
}

.tw-decoration-purple-600\/20 {
  text-decoration-color: rgb(147 51 234 / 0.2) !important;
}

.tw-decoration-purple-600\/25 {
  text-decoration-color: rgb(147 51 234 / 0.25) !important;
}

.tw-decoration-purple-600\/30 {
  text-decoration-color: rgb(147 51 234 / 0.3) !important;
}

.tw-decoration-purple-600\/35 {
  text-decoration-color: rgb(147 51 234 / 0.35) !important;
}

.tw-decoration-purple-600\/40 {
  text-decoration-color: rgb(147 51 234 / 0.4) !important;
}

.tw-decoration-purple-600\/45 {
  text-decoration-color: rgb(147 51 234 / 0.45) !important;
}

.tw-decoration-purple-600\/5 {
  text-decoration-color: rgb(147 51 234 / 0.05) !important;
}

.tw-decoration-purple-600\/50 {
  text-decoration-color: rgb(147 51 234 / 0.5) !important;
}

.tw-decoration-purple-600\/55 {
  text-decoration-color: rgb(147 51 234 / 0.55) !important;
}

.tw-decoration-purple-600\/60 {
  text-decoration-color: rgb(147 51 234 / 0.6) !important;
}

.tw-decoration-purple-600\/65 {
  text-decoration-color: rgb(147 51 234 / 0.65) !important;
}

.tw-decoration-purple-600\/70 {
  text-decoration-color: rgb(147 51 234 / 0.7) !important;
}

.tw-decoration-purple-600\/75 {
  text-decoration-color: rgb(147 51 234 / 0.75) !important;
}

.tw-decoration-purple-600\/80 {
  text-decoration-color: rgb(147 51 234 / 0.8) !important;
}

.tw-decoration-purple-600\/85 {
  text-decoration-color: rgb(147 51 234 / 0.85) !important;
}

.tw-decoration-purple-600\/90 {
  text-decoration-color: rgb(147 51 234 / 0.9) !important;
}

.tw-decoration-purple-600\/95 {
  text-decoration-color: rgb(147 51 234 / 0.95) !important;
}

.tw-decoration-purple-700 {
  text-decoration-color: #7e22ce !important;
}

.tw-decoration-purple-700\/0 {
  text-decoration-color: rgb(126 34 206 / 0) !important;
}

.tw-decoration-purple-700\/10 {
  text-decoration-color: rgb(126 34 206 / 0.1) !important;
}

.tw-decoration-purple-700\/100 {
  text-decoration-color: rgb(126 34 206 / 1) !important;
}

.tw-decoration-purple-700\/15 {
  text-decoration-color: rgb(126 34 206 / 0.15) !important;
}

.tw-decoration-purple-700\/20 {
  text-decoration-color: rgb(126 34 206 / 0.2) !important;
}

.tw-decoration-purple-700\/25 {
  text-decoration-color: rgb(126 34 206 / 0.25) !important;
}

.tw-decoration-purple-700\/30 {
  text-decoration-color: rgb(126 34 206 / 0.3) !important;
}

.tw-decoration-purple-700\/35 {
  text-decoration-color: rgb(126 34 206 / 0.35) !important;
}

.tw-decoration-purple-700\/40 {
  text-decoration-color: rgb(126 34 206 / 0.4) !important;
}

.tw-decoration-purple-700\/45 {
  text-decoration-color: rgb(126 34 206 / 0.45) !important;
}

.tw-decoration-purple-700\/5 {
  text-decoration-color: rgb(126 34 206 / 0.05) !important;
}

.tw-decoration-purple-700\/50 {
  text-decoration-color: rgb(126 34 206 / 0.5) !important;
}

.tw-decoration-purple-700\/55 {
  text-decoration-color: rgb(126 34 206 / 0.55) !important;
}

.tw-decoration-purple-700\/60 {
  text-decoration-color: rgb(126 34 206 / 0.6) !important;
}

.tw-decoration-purple-700\/65 {
  text-decoration-color: rgb(126 34 206 / 0.65) !important;
}

.tw-decoration-purple-700\/70 {
  text-decoration-color: rgb(126 34 206 / 0.7) !important;
}

.tw-decoration-purple-700\/75 {
  text-decoration-color: rgb(126 34 206 / 0.75) !important;
}

.tw-decoration-purple-700\/80 {
  text-decoration-color: rgb(126 34 206 / 0.8) !important;
}

.tw-decoration-purple-700\/85 {
  text-decoration-color: rgb(126 34 206 / 0.85) !important;
}

.tw-decoration-purple-700\/90 {
  text-decoration-color: rgb(126 34 206 / 0.9) !important;
}

.tw-decoration-purple-700\/95 {
  text-decoration-color: rgb(126 34 206 / 0.95) !important;
}

.tw-decoration-purple-800 {
  text-decoration-color: #6b21a8 !important;
}

.tw-decoration-purple-800\/0 {
  text-decoration-color: rgb(107 33 168 / 0) !important;
}

.tw-decoration-purple-800\/10 {
  text-decoration-color: rgb(107 33 168 / 0.1) !important;
}

.tw-decoration-purple-800\/100 {
  text-decoration-color: rgb(107 33 168 / 1) !important;
}

.tw-decoration-purple-800\/15 {
  text-decoration-color: rgb(107 33 168 / 0.15) !important;
}

.tw-decoration-purple-800\/20 {
  text-decoration-color: rgb(107 33 168 / 0.2) !important;
}

.tw-decoration-purple-800\/25 {
  text-decoration-color: rgb(107 33 168 / 0.25) !important;
}

.tw-decoration-purple-800\/30 {
  text-decoration-color: rgb(107 33 168 / 0.3) !important;
}

.tw-decoration-purple-800\/35 {
  text-decoration-color: rgb(107 33 168 / 0.35) !important;
}

.tw-decoration-purple-800\/40 {
  text-decoration-color: rgb(107 33 168 / 0.4) !important;
}

.tw-decoration-purple-800\/45 {
  text-decoration-color: rgb(107 33 168 / 0.45) !important;
}

.tw-decoration-purple-800\/5 {
  text-decoration-color: rgb(107 33 168 / 0.05) !important;
}

.tw-decoration-purple-800\/50 {
  text-decoration-color: rgb(107 33 168 / 0.5) !important;
}

.tw-decoration-purple-800\/55 {
  text-decoration-color: rgb(107 33 168 / 0.55) !important;
}

.tw-decoration-purple-800\/60 {
  text-decoration-color: rgb(107 33 168 / 0.6) !important;
}

.tw-decoration-purple-800\/65 {
  text-decoration-color: rgb(107 33 168 / 0.65) !important;
}

.tw-decoration-purple-800\/70 {
  text-decoration-color: rgb(107 33 168 / 0.7) !important;
}

.tw-decoration-purple-800\/75 {
  text-decoration-color: rgb(107 33 168 / 0.75) !important;
}

.tw-decoration-purple-800\/80 {
  text-decoration-color: rgb(107 33 168 / 0.8) !important;
}

.tw-decoration-purple-800\/85 {
  text-decoration-color: rgb(107 33 168 / 0.85) !important;
}

.tw-decoration-purple-800\/90 {
  text-decoration-color: rgb(107 33 168 / 0.9) !important;
}

.tw-decoration-purple-800\/95 {
  text-decoration-color: rgb(107 33 168 / 0.95) !important;
}

.tw-decoration-purple-900 {
  text-decoration-color: #581c87 !important;
}

.tw-decoration-purple-900\/0 {
  text-decoration-color: rgb(88 28 135 / 0) !important;
}

.tw-decoration-purple-900\/10 {
  text-decoration-color: rgb(88 28 135 / 0.1) !important;
}

.tw-decoration-purple-900\/100 {
  text-decoration-color: rgb(88 28 135 / 1) !important;
}

.tw-decoration-purple-900\/15 {
  text-decoration-color: rgb(88 28 135 / 0.15) !important;
}

.tw-decoration-purple-900\/20 {
  text-decoration-color: rgb(88 28 135 / 0.2) !important;
}

.tw-decoration-purple-900\/25 {
  text-decoration-color: rgb(88 28 135 / 0.25) !important;
}

.tw-decoration-purple-900\/30 {
  text-decoration-color: rgb(88 28 135 / 0.3) !important;
}

.tw-decoration-purple-900\/35 {
  text-decoration-color: rgb(88 28 135 / 0.35) !important;
}

.tw-decoration-purple-900\/40 {
  text-decoration-color: rgb(88 28 135 / 0.4) !important;
}

.tw-decoration-purple-900\/45 {
  text-decoration-color: rgb(88 28 135 / 0.45) !important;
}

.tw-decoration-purple-900\/5 {
  text-decoration-color: rgb(88 28 135 / 0.05) !important;
}

.tw-decoration-purple-900\/50 {
  text-decoration-color: rgb(88 28 135 / 0.5) !important;
}

.tw-decoration-purple-900\/55 {
  text-decoration-color: rgb(88 28 135 / 0.55) !important;
}

.tw-decoration-purple-900\/60 {
  text-decoration-color: rgb(88 28 135 / 0.6) !important;
}

.tw-decoration-purple-900\/65 {
  text-decoration-color: rgb(88 28 135 / 0.65) !important;
}

.tw-decoration-purple-900\/70 {
  text-decoration-color: rgb(88 28 135 / 0.7) !important;
}

.tw-decoration-purple-900\/75 {
  text-decoration-color: rgb(88 28 135 / 0.75) !important;
}

.tw-decoration-purple-900\/80 {
  text-decoration-color: rgb(88 28 135 / 0.8) !important;
}

.tw-decoration-purple-900\/85 {
  text-decoration-color: rgb(88 28 135 / 0.85) !important;
}

.tw-decoration-purple-900\/90 {
  text-decoration-color: rgb(88 28 135 / 0.9) !important;
}

.tw-decoration-purple-900\/95 {
  text-decoration-color: rgb(88 28 135 / 0.95) !important;
}

.tw-decoration-purple-950 {
  text-decoration-color: #3b0764 !important;
}

.tw-decoration-purple-950\/0 {
  text-decoration-color: rgb(59 7 100 / 0) !important;
}

.tw-decoration-purple-950\/10 {
  text-decoration-color: rgb(59 7 100 / 0.1) !important;
}

.tw-decoration-purple-950\/100 {
  text-decoration-color: rgb(59 7 100 / 1) !important;
}

.tw-decoration-purple-950\/15 {
  text-decoration-color: rgb(59 7 100 / 0.15) !important;
}

.tw-decoration-purple-950\/20 {
  text-decoration-color: rgb(59 7 100 / 0.2) !important;
}

.tw-decoration-purple-950\/25 {
  text-decoration-color: rgb(59 7 100 / 0.25) !important;
}

.tw-decoration-purple-950\/30 {
  text-decoration-color: rgb(59 7 100 / 0.3) !important;
}

.tw-decoration-purple-950\/35 {
  text-decoration-color: rgb(59 7 100 / 0.35) !important;
}

.tw-decoration-purple-950\/40 {
  text-decoration-color: rgb(59 7 100 / 0.4) !important;
}

.tw-decoration-purple-950\/45 {
  text-decoration-color: rgb(59 7 100 / 0.45) !important;
}

.tw-decoration-purple-950\/5 {
  text-decoration-color: rgb(59 7 100 / 0.05) !important;
}

.tw-decoration-purple-950\/50 {
  text-decoration-color: rgb(59 7 100 / 0.5) !important;
}

.tw-decoration-purple-950\/55 {
  text-decoration-color: rgb(59 7 100 / 0.55) !important;
}

.tw-decoration-purple-950\/60 {
  text-decoration-color: rgb(59 7 100 / 0.6) !important;
}

.tw-decoration-purple-950\/65 {
  text-decoration-color: rgb(59 7 100 / 0.65) !important;
}

.tw-decoration-purple-950\/70 {
  text-decoration-color: rgb(59 7 100 / 0.7) !important;
}

.tw-decoration-purple-950\/75 {
  text-decoration-color: rgb(59 7 100 / 0.75) !important;
}

.tw-decoration-purple-950\/80 {
  text-decoration-color: rgb(59 7 100 / 0.8) !important;
}

.tw-decoration-purple-950\/85 {
  text-decoration-color: rgb(59 7 100 / 0.85) !important;
}

.tw-decoration-purple-950\/90 {
  text-decoration-color: rgb(59 7 100 / 0.9) !important;
}

.tw-decoration-purple-950\/95 {
  text-decoration-color: rgb(59 7 100 / 0.95) !important;
}

.tw-decoration-red-100 {
  text-decoration-color: #fee2e2 !important;
}

.tw-decoration-red-100\/0 {
  text-decoration-color: rgb(254 226 226 / 0) !important;
}

.tw-decoration-red-100\/10 {
  text-decoration-color: rgb(254 226 226 / 0.1) !important;
}

.tw-decoration-red-100\/100 {
  text-decoration-color: rgb(254 226 226 / 1) !important;
}

.tw-decoration-red-100\/15 {
  text-decoration-color: rgb(254 226 226 / 0.15) !important;
}

.tw-decoration-red-100\/20 {
  text-decoration-color: rgb(254 226 226 / 0.2) !important;
}

.tw-decoration-red-100\/25 {
  text-decoration-color: rgb(254 226 226 / 0.25) !important;
}

.tw-decoration-red-100\/30 {
  text-decoration-color: rgb(254 226 226 / 0.3) !important;
}

.tw-decoration-red-100\/35 {
  text-decoration-color: rgb(254 226 226 / 0.35) !important;
}

.tw-decoration-red-100\/40 {
  text-decoration-color: rgb(254 226 226 / 0.4) !important;
}

.tw-decoration-red-100\/45 {
  text-decoration-color: rgb(254 226 226 / 0.45) !important;
}

.tw-decoration-red-100\/5 {
  text-decoration-color: rgb(254 226 226 / 0.05) !important;
}

.tw-decoration-red-100\/50 {
  text-decoration-color: rgb(254 226 226 / 0.5) !important;
}

.tw-decoration-red-100\/55 {
  text-decoration-color: rgb(254 226 226 / 0.55) !important;
}

.tw-decoration-red-100\/60 {
  text-decoration-color: rgb(254 226 226 / 0.6) !important;
}

.tw-decoration-red-100\/65 {
  text-decoration-color: rgb(254 226 226 / 0.65) !important;
}

.tw-decoration-red-100\/70 {
  text-decoration-color: rgb(254 226 226 / 0.7) !important;
}

.tw-decoration-red-100\/75 {
  text-decoration-color: rgb(254 226 226 / 0.75) !important;
}

.tw-decoration-red-100\/80 {
  text-decoration-color: rgb(254 226 226 / 0.8) !important;
}

.tw-decoration-red-100\/85 {
  text-decoration-color: rgb(254 226 226 / 0.85) !important;
}

.tw-decoration-red-100\/90 {
  text-decoration-color: rgb(254 226 226 / 0.9) !important;
}

.tw-decoration-red-100\/95 {
  text-decoration-color: rgb(254 226 226 / 0.95) !important;
}

.tw-decoration-red-200 {
  text-decoration-color: #fecaca !important;
}

.tw-decoration-red-200\/0 {
  text-decoration-color: rgb(254 202 202 / 0) !important;
}

.tw-decoration-red-200\/10 {
  text-decoration-color: rgb(254 202 202 / 0.1) !important;
}

.tw-decoration-red-200\/100 {
  text-decoration-color: rgb(254 202 202 / 1) !important;
}

.tw-decoration-red-200\/15 {
  text-decoration-color: rgb(254 202 202 / 0.15) !important;
}

.tw-decoration-red-200\/20 {
  text-decoration-color: rgb(254 202 202 / 0.2) !important;
}

.tw-decoration-red-200\/25 {
  text-decoration-color: rgb(254 202 202 / 0.25) !important;
}

.tw-decoration-red-200\/30 {
  text-decoration-color: rgb(254 202 202 / 0.3) !important;
}

.tw-decoration-red-200\/35 {
  text-decoration-color: rgb(254 202 202 / 0.35) !important;
}

.tw-decoration-red-200\/40 {
  text-decoration-color: rgb(254 202 202 / 0.4) !important;
}

.tw-decoration-red-200\/45 {
  text-decoration-color: rgb(254 202 202 / 0.45) !important;
}

.tw-decoration-red-200\/5 {
  text-decoration-color: rgb(254 202 202 / 0.05) !important;
}

.tw-decoration-red-200\/50 {
  text-decoration-color: rgb(254 202 202 / 0.5) !important;
}

.tw-decoration-red-200\/55 {
  text-decoration-color: rgb(254 202 202 / 0.55) !important;
}

.tw-decoration-red-200\/60 {
  text-decoration-color: rgb(254 202 202 / 0.6) !important;
}

.tw-decoration-red-200\/65 {
  text-decoration-color: rgb(254 202 202 / 0.65) !important;
}

.tw-decoration-red-200\/70 {
  text-decoration-color: rgb(254 202 202 / 0.7) !important;
}

.tw-decoration-red-200\/75 {
  text-decoration-color: rgb(254 202 202 / 0.75) !important;
}

.tw-decoration-red-200\/80 {
  text-decoration-color: rgb(254 202 202 / 0.8) !important;
}

.tw-decoration-red-200\/85 {
  text-decoration-color: rgb(254 202 202 / 0.85) !important;
}

.tw-decoration-red-200\/90 {
  text-decoration-color: rgb(254 202 202 / 0.9) !important;
}

.tw-decoration-red-200\/95 {
  text-decoration-color: rgb(254 202 202 / 0.95) !important;
}

.tw-decoration-red-300 {
  text-decoration-color: #fca5a5 !important;
}

.tw-decoration-red-300\/0 {
  text-decoration-color: rgb(252 165 165 / 0) !important;
}

.tw-decoration-red-300\/10 {
  text-decoration-color: rgb(252 165 165 / 0.1) !important;
}

.tw-decoration-red-300\/100 {
  text-decoration-color: rgb(252 165 165 / 1) !important;
}

.tw-decoration-red-300\/15 {
  text-decoration-color: rgb(252 165 165 / 0.15) !important;
}

.tw-decoration-red-300\/20 {
  text-decoration-color: rgb(252 165 165 / 0.2) !important;
}

.tw-decoration-red-300\/25 {
  text-decoration-color: rgb(252 165 165 / 0.25) !important;
}

.tw-decoration-red-300\/30 {
  text-decoration-color: rgb(252 165 165 / 0.3) !important;
}

.tw-decoration-red-300\/35 {
  text-decoration-color: rgb(252 165 165 / 0.35) !important;
}

.tw-decoration-red-300\/40 {
  text-decoration-color: rgb(252 165 165 / 0.4) !important;
}

.tw-decoration-red-300\/45 {
  text-decoration-color: rgb(252 165 165 / 0.45) !important;
}

.tw-decoration-red-300\/5 {
  text-decoration-color: rgb(252 165 165 / 0.05) !important;
}

.tw-decoration-red-300\/50 {
  text-decoration-color: rgb(252 165 165 / 0.5) !important;
}

.tw-decoration-red-300\/55 {
  text-decoration-color: rgb(252 165 165 / 0.55) !important;
}

.tw-decoration-red-300\/60 {
  text-decoration-color: rgb(252 165 165 / 0.6) !important;
}

.tw-decoration-red-300\/65 {
  text-decoration-color: rgb(252 165 165 / 0.65) !important;
}

.tw-decoration-red-300\/70 {
  text-decoration-color: rgb(252 165 165 / 0.7) !important;
}

.tw-decoration-red-300\/75 {
  text-decoration-color: rgb(252 165 165 / 0.75) !important;
}

.tw-decoration-red-300\/80 {
  text-decoration-color: rgb(252 165 165 / 0.8) !important;
}

.tw-decoration-red-300\/85 {
  text-decoration-color: rgb(252 165 165 / 0.85) !important;
}

.tw-decoration-red-300\/90 {
  text-decoration-color: rgb(252 165 165 / 0.9) !important;
}

.tw-decoration-red-300\/95 {
  text-decoration-color: rgb(252 165 165 / 0.95) !important;
}

.tw-decoration-red-400 {
  text-decoration-color: #f87171 !important;
}

.tw-decoration-red-400\/0 {
  text-decoration-color: rgb(248 113 113 / 0) !important;
}

.tw-decoration-red-400\/10 {
  text-decoration-color: rgb(248 113 113 / 0.1) !important;
}

.tw-decoration-red-400\/100 {
  text-decoration-color: rgb(248 113 113 / 1) !important;
}

.tw-decoration-red-400\/15 {
  text-decoration-color: rgb(248 113 113 / 0.15) !important;
}

.tw-decoration-red-400\/20 {
  text-decoration-color: rgb(248 113 113 / 0.2) !important;
}

.tw-decoration-red-400\/25 {
  text-decoration-color: rgb(248 113 113 / 0.25) !important;
}

.tw-decoration-red-400\/30 {
  text-decoration-color: rgb(248 113 113 / 0.3) !important;
}

.tw-decoration-red-400\/35 {
  text-decoration-color: rgb(248 113 113 / 0.35) !important;
}

.tw-decoration-red-400\/40 {
  text-decoration-color: rgb(248 113 113 / 0.4) !important;
}

.tw-decoration-red-400\/45 {
  text-decoration-color: rgb(248 113 113 / 0.45) !important;
}

.tw-decoration-red-400\/5 {
  text-decoration-color: rgb(248 113 113 / 0.05) !important;
}

.tw-decoration-red-400\/50 {
  text-decoration-color: rgb(248 113 113 / 0.5) !important;
}

.tw-decoration-red-400\/55 {
  text-decoration-color: rgb(248 113 113 / 0.55) !important;
}

.tw-decoration-red-400\/60 {
  text-decoration-color: rgb(248 113 113 / 0.6) !important;
}

.tw-decoration-red-400\/65 {
  text-decoration-color: rgb(248 113 113 / 0.65) !important;
}

.tw-decoration-red-400\/70 {
  text-decoration-color: rgb(248 113 113 / 0.7) !important;
}

.tw-decoration-red-400\/75 {
  text-decoration-color: rgb(248 113 113 / 0.75) !important;
}

.tw-decoration-red-400\/80 {
  text-decoration-color: rgb(248 113 113 / 0.8) !important;
}

.tw-decoration-red-400\/85 {
  text-decoration-color: rgb(248 113 113 / 0.85) !important;
}

.tw-decoration-red-400\/90 {
  text-decoration-color: rgb(248 113 113 / 0.9) !important;
}

.tw-decoration-red-400\/95 {
  text-decoration-color: rgb(248 113 113 / 0.95) !important;
}

.tw-decoration-red-50 {
  text-decoration-color: #fef2f2 !important;
}

.tw-decoration-red-50\/0 {
  text-decoration-color: rgb(254 242 242 / 0) !important;
}

.tw-decoration-red-50\/10 {
  text-decoration-color: rgb(254 242 242 / 0.1) !important;
}

.tw-decoration-red-50\/100 {
  text-decoration-color: rgb(254 242 242 / 1) !important;
}

.tw-decoration-red-50\/15 {
  text-decoration-color: rgb(254 242 242 / 0.15) !important;
}

.tw-decoration-red-50\/20 {
  text-decoration-color: rgb(254 242 242 / 0.2) !important;
}

.tw-decoration-red-50\/25 {
  text-decoration-color: rgb(254 242 242 / 0.25) !important;
}

.tw-decoration-red-50\/30 {
  text-decoration-color: rgb(254 242 242 / 0.3) !important;
}

.tw-decoration-red-50\/35 {
  text-decoration-color: rgb(254 242 242 / 0.35) !important;
}

.tw-decoration-red-50\/40 {
  text-decoration-color: rgb(254 242 242 / 0.4) !important;
}

.tw-decoration-red-50\/45 {
  text-decoration-color: rgb(254 242 242 / 0.45) !important;
}

.tw-decoration-red-50\/5 {
  text-decoration-color: rgb(254 242 242 / 0.05) !important;
}

.tw-decoration-red-50\/50 {
  text-decoration-color: rgb(254 242 242 / 0.5) !important;
}

.tw-decoration-red-50\/55 {
  text-decoration-color: rgb(254 242 242 / 0.55) !important;
}

.tw-decoration-red-50\/60 {
  text-decoration-color: rgb(254 242 242 / 0.6) !important;
}

.tw-decoration-red-50\/65 {
  text-decoration-color: rgb(254 242 242 / 0.65) !important;
}

.tw-decoration-red-50\/70 {
  text-decoration-color: rgb(254 242 242 / 0.7) !important;
}

.tw-decoration-red-50\/75 {
  text-decoration-color: rgb(254 242 242 / 0.75) !important;
}

.tw-decoration-red-50\/80 {
  text-decoration-color: rgb(254 242 242 / 0.8) !important;
}

.tw-decoration-red-50\/85 {
  text-decoration-color: rgb(254 242 242 / 0.85) !important;
}

.tw-decoration-red-50\/90 {
  text-decoration-color: rgb(254 242 242 / 0.9) !important;
}

.tw-decoration-red-50\/95 {
  text-decoration-color: rgb(254 242 242 / 0.95) !important;
}

.tw-decoration-red-500 {
  text-decoration-color: #ef4444 !important;
}

.tw-decoration-red-500\/0 {
  text-decoration-color: rgb(239 68 68 / 0) !important;
}

.tw-decoration-red-500\/10 {
  text-decoration-color: rgb(239 68 68 / 0.1) !important;
}

.tw-decoration-red-500\/100 {
  text-decoration-color: rgb(239 68 68 / 1) !important;
}

.tw-decoration-red-500\/15 {
  text-decoration-color: rgb(239 68 68 / 0.15) !important;
}

.tw-decoration-red-500\/20 {
  text-decoration-color: rgb(239 68 68 / 0.2) !important;
}

.tw-decoration-red-500\/25 {
  text-decoration-color: rgb(239 68 68 / 0.25) !important;
}

.tw-decoration-red-500\/30 {
  text-decoration-color: rgb(239 68 68 / 0.3) !important;
}

.tw-decoration-red-500\/35 {
  text-decoration-color: rgb(239 68 68 / 0.35) !important;
}

.tw-decoration-red-500\/40 {
  text-decoration-color: rgb(239 68 68 / 0.4) !important;
}

.tw-decoration-red-500\/45 {
  text-decoration-color: rgb(239 68 68 / 0.45) !important;
}

.tw-decoration-red-500\/5 {
  text-decoration-color: rgb(239 68 68 / 0.05) !important;
}

.tw-decoration-red-500\/50 {
  text-decoration-color: rgb(239 68 68 / 0.5) !important;
}

.tw-decoration-red-500\/55 {
  text-decoration-color: rgb(239 68 68 / 0.55) !important;
}

.tw-decoration-red-500\/60 {
  text-decoration-color: rgb(239 68 68 / 0.6) !important;
}

.tw-decoration-red-500\/65 {
  text-decoration-color: rgb(239 68 68 / 0.65) !important;
}

.tw-decoration-red-500\/70 {
  text-decoration-color: rgb(239 68 68 / 0.7) !important;
}

.tw-decoration-red-500\/75 {
  text-decoration-color: rgb(239 68 68 / 0.75) !important;
}

.tw-decoration-red-500\/80 {
  text-decoration-color: rgb(239 68 68 / 0.8) !important;
}

.tw-decoration-red-500\/85 {
  text-decoration-color: rgb(239 68 68 / 0.85) !important;
}

.tw-decoration-red-500\/90 {
  text-decoration-color: rgb(239 68 68 / 0.9) !important;
}

.tw-decoration-red-500\/95 {
  text-decoration-color: rgb(239 68 68 / 0.95) !important;
}

.tw-decoration-red-600 {
  text-decoration-color: #dc2626 !important;
}

.tw-decoration-red-600\/0 {
  text-decoration-color: rgb(220 38 38 / 0) !important;
}

.tw-decoration-red-600\/10 {
  text-decoration-color: rgb(220 38 38 / 0.1) !important;
}

.tw-decoration-red-600\/100 {
  text-decoration-color: rgb(220 38 38 / 1) !important;
}

.tw-decoration-red-600\/15 {
  text-decoration-color: rgb(220 38 38 / 0.15) !important;
}

.tw-decoration-red-600\/20 {
  text-decoration-color: rgb(220 38 38 / 0.2) !important;
}

.tw-decoration-red-600\/25 {
  text-decoration-color: rgb(220 38 38 / 0.25) !important;
}

.tw-decoration-red-600\/30 {
  text-decoration-color: rgb(220 38 38 / 0.3) !important;
}

.tw-decoration-red-600\/35 {
  text-decoration-color: rgb(220 38 38 / 0.35) !important;
}

.tw-decoration-red-600\/40 {
  text-decoration-color: rgb(220 38 38 / 0.4) !important;
}

.tw-decoration-red-600\/45 {
  text-decoration-color: rgb(220 38 38 / 0.45) !important;
}

.tw-decoration-red-600\/5 {
  text-decoration-color: rgb(220 38 38 / 0.05) !important;
}

.tw-decoration-red-600\/50 {
  text-decoration-color: rgb(220 38 38 / 0.5) !important;
}

.tw-decoration-red-600\/55 {
  text-decoration-color: rgb(220 38 38 / 0.55) !important;
}

.tw-decoration-red-600\/60 {
  text-decoration-color: rgb(220 38 38 / 0.6) !important;
}

.tw-decoration-red-600\/65 {
  text-decoration-color: rgb(220 38 38 / 0.65) !important;
}

.tw-decoration-red-600\/70 {
  text-decoration-color: rgb(220 38 38 / 0.7) !important;
}

.tw-decoration-red-600\/75 {
  text-decoration-color: rgb(220 38 38 / 0.75) !important;
}

.tw-decoration-red-600\/80 {
  text-decoration-color: rgb(220 38 38 / 0.8) !important;
}

.tw-decoration-red-600\/85 {
  text-decoration-color: rgb(220 38 38 / 0.85) !important;
}

.tw-decoration-red-600\/90 {
  text-decoration-color: rgb(220 38 38 / 0.9) !important;
}

.tw-decoration-red-600\/95 {
  text-decoration-color: rgb(220 38 38 / 0.95) !important;
}

.tw-decoration-red-700 {
  text-decoration-color: #b91c1c !important;
}

.tw-decoration-red-700\/0 {
  text-decoration-color: rgb(185 28 28 / 0) !important;
}

.tw-decoration-red-700\/10 {
  text-decoration-color: rgb(185 28 28 / 0.1) !important;
}

.tw-decoration-red-700\/100 {
  text-decoration-color: rgb(185 28 28 / 1) !important;
}

.tw-decoration-red-700\/15 {
  text-decoration-color: rgb(185 28 28 / 0.15) !important;
}

.tw-decoration-red-700\/20 {
  text-decoration-color: rgb(185 28 28 / 0.2) !important;
}

.tw-decoration-red-700\/25 {
  text-decoration-color: rgb(185 28 28 / 0.25) !important;
}

.tw-decoration-red-700\/30 {
  text-decoration-color: rgb(185 28 28 / 0.3) !important;
}

.tw-decoration-red-700\/35 {
  text-decoration-color: rgb(185 28 28 / 0.35) !important;
}

.tw-decoration-red-700\/40 {
  text-decoration-color: rgb(185 28 28 / 0.4) !important;
}

.tw-decoration-red-700\/45 {
  text-decoration-color: rgb(185 28 28 / 0.45) !important;
}

.tw-decoration-red-700\/5 {
  text-decoration-color: rgb(185 28 28 / 0.05) !important;
}

.tw-decoration-red-700\/50 {
  text-decoration-color: rgb(185 28 28 / 0.5) !important;
}

.tw-decoration-red-700\/55 {
  text-decoration-color: rgb(185 28 28 / 0.55) !important;
}

.tw-decoration-red-700\/60 {
  text-decoration-color: rgb(185 28 28 / 0.6) !important;
}

.tw-decoration-red-700\/65 {
  text-decoration-color: rgb(185 28 28 / 0.65) !important;
}

.tw-decoration-red-700\/70 {
  text-decoration-color: rgb(185 28 28 / 0.7) !important;
}

.tw-decoration-red-700\/75 {
  text-decoration-color: rgb(185 28 28 / 0.75) !important;
}

.tw-decoration-red-700\/80 {
  text-decoration-color: rgb(185 28 28 / 0.8) !important;
}

.tw-decoration-red-700\/85 {
  text-decoration-color: rgb(185 28 28 / 0.85) !important;
}

.tw-decoration-red-700\/90 {
  text-decoration-color: rgb(185 28 28 / 0.9) !important;
}

.tw-decoration-red-700\/95 {
  text-decoration-color: rgb(185 28 28 / 0.95) !important;
}

.tw-decoration-red-800 {
  text-decoration-color: #991b1b !important;
}

.tw-decoration-red-800\/0 {
  text-decoration-color: rgb(153 27 27 / 0) !important;
}

.tw-decoration-red-800\/10 {
  text-decoration-color: rgb(153 27 27 / 0.1) !important;
}

.tw-decoration-red-800\/100 {
  text-decoration-color: rgb(153 27 27 / 1) !important;
}

.tw-decoration-red-800\/15 {
  text-decoration-color: rgb(153 27 27 / 0.15) !important;
}

.tw-decoration-red-800\/20 {
  text-decoration-color: rgb(153 27 27 / 0.2) !important;
}

.tw-decoration-red-800\/25 {
  text-decoration-color: rgb(153 27 27 / 0.25) !important;
}

.tw-decoration-red-800\/30 {
  text-decoration-color: rgb(153 27 27 / 0.3) !important;
}

.tw-decoration-red-800\/35 {
  text-decoration-color: rgb(153 27 27 / 0.35) !important;
}

.tw-decoration-red-800\/40 {
  text-decoration-color: rgb(153 27 27 / 0.4) !important;
}

.tw-decoration-red-800\/45 {
  text-decoration-color: rgb(153 27 27 / 0.45) !important;
}

.tw-decoration-red-800\/5 {
  text-decoration-color: rgb(153 27 27 / 0.05) !important;
}

.tw-decoration-red-800\/50 {
  text-decoration-color: rgb(153 27 27 / 0.5) !important;
}

.tw-decoration-red-800\/55 {
  text-decoration-color: rgb(153 27 27 / 0.55) !important;
}

.tw-decoration-red-800\/60 {
  text-decoration-color: rgb(153 27 27 / 0.6) !important;
}

.tw-decoration-red-800\/65 {
  text-decoration-color: rgb(153 27 27 / 0.65) !important;
}

.tw-decoration-red-800\/70 {
  text-decoration-color: rgb(153 27 27 / 0.7) !important;
}

.tw-decoration-red-800\/75 {
  text-decoration-color: rgb(153 27 27 / 0.75) !important;
}

.tw-decoration-red-800\/80 {
  text-decoration-color: rgb(153 27 27 / 0.8) !important;
}

.tw-decoration-red-800\/85 {
  text-decoration-color: rgb(153 27 27 / 0.85) !important;
}

.tw-decoration-red-800\/90 {
  text-decoration-color: rgb(153 27 27 / 0.9) !important;
}

.tw-decoration-red-800\/95 {
  text-decoration-color: rgb(153 27 27 / 0.95) !important;
}

.tw-decoration-red-900 {
  text-decoration-color: #7f1d1d !important;
}

.tw-decoration-red-900\/0 {
  text-decoration-color: rgb(127 29 29 / 0) !important;
}

.tw-decoration-red-900\/10 {
  text-decoration-color: rgb(127 29 29 / 0.1) !important;
}

.tw-decoration-red-900\/100 {
  text-decoration-color: rgb(127 29 29 / 1) !important;
}

.tw-decoration-red-900\/15 {
  text-decoration-color: rgb(127 29 29 / 0.15) !important;
}

.tw-decoration-red-900\/20 {
  text-decoration-color: rgb(127 29 29 / 0.2) !important;
}

.tw-decoration-red-900\/25 {
  text-decoration-color: rgb(127 29 29 / 0.25) !important;
}

.tw-decoration-red-900\/30 {
  text-decoration-color: rgb(127 29 29 / 0.3) !important;
}

.tw-decoration-red-900\/35 {
  text-decoration-color: rgb(127 29 29 / 0.35) !important;
}

.tw-decoration-red-900\/40 {
  text-decoration-color: rgb(127 29 29 / 0.4) !important;
}

.tw-decoration-red-900\/45 {
  text-decoration-color: rgb(127 29 29 / 0.45) !important;
}

.tw-decoration-red-900\/5 {
  text-decoration-color: rgb(127 29 29 / 0.05) !important;
}

.tw-decoration-red-900\/50 {
  text-decoration-color: rgb(127 29 29 / 0.5) !important;
}

.tw-decoration-red-900\/55 {
  text-decoration-color: rgb(127 29 29 / 0.55) !important;
}

.tw-decoration-red-900\/60 {
  text-decoration-color: rgb(127 29 29 / 0.6) !important;
}

.tw-decoration-red-900\/65 {
  text-decoration-color: rgb(127 29 29 / 0.65) !important;
}

.tw-decoration-red-900\/70 {
  text-decoration-color: rgb(127 29 29 / 0.7) !important;
}

.tw-decoration-red-900\/75 {
  text-decoration-color: rgb(127 29 29 / 0.75) !important;
}

.tw-decoration-red-900\/80 {
  text-decoration-color: rgb(127 29 29 / 0.8) !important;
}

.tw-decoration-red-900\/85 {
  text-decoration-color: rgb(127 29 29 / 0.85) !important;
}

.tw-decoration-red-900\/90 {
  text-decoration-color: rgb(127 29 29 / 0.9) !important;
}

.tw-decoration-red-900\/95 {
  text-decoration-color: rgb(127 29 29 / 0.95) !important;
}

.tw-decoration-red-950 {
  text-decoration-color: #450a0a !important;
}

.tw-decoration-red-950\/0 {
  text-decoration-color: rgb(69 10 10 / 0) !important;
}

.tw-decoration-red-950\/10 {
  text-decoration-color: rgb(69 10 10 / 0.1) !important;
}

.tw-decoration-red-950\/100 {
  text-decoration-color: rgb(69 10 10 / 1) !important;
}

.tw-decoration-red-950\/15 {
  text-decoration-color: rgb(69 10 10 / 0.15) !important;
}

.tw-decoration-red-950\/20 {
  text-decoration-color: rgb(69 10 10 / 0.2) !important;
}

.tw-decoration-red-950\/25 {
  text-decoration-color: rgb(69 10 10 / 0.25) !important;
}

.tw-decoration-red-950\/30 {
  text-decoration-color: rgb(69 10 10 / 0.3) !important;
}

.tw-decoration-red-950\/35 {
  text-decoration-color: rgb(69 10 10 / 0.35) !important;
}

.tw-decoration-red-950\/40 {
  text-decoration-color: rgb(69 10 10 / 0.4) !important;
}

.tw-decoration-red-950\/45 {
  text-decoration-color: rgb(69 10 10 / 0.45) !important;
}

.tw-decoration-red-950\/5 {
  text-decoration-color: rgb(69 10 10 / 0.05) !important;
}

.tw-decoration-red-950\/50 {
  text-decoration-color: rgb(69 10 10 / 0.5) !important;
}

.tw-decoration-red-950\/55 {
  text-decoration-color: rgb(69 10 10 / 0.55) !important;
}

.tw-decoration-red-950\/60 {
  text-decoration-color: rgb(69 10 10 / 0.6) !important;
}

.tw-decoration-red-950\/65 {
  text-decoration-color: rgb(69 10 10 / 0.65) !important;
}

.tw-decoration-red-950\/70 {
  text-decoration-color: rgb(69 10 10 / 0.7) !important;
}

.tw-decoration-red-950\/75 {
  text-decoration-color: rgb(69 10 10 / 0.75) !important;
}

.tw-decoration-red-950\/80 {
  text-decoration-color: rgb(69 10 10 / 0.8) !important;
}

.tw-decoration-red-950\/85 {
  text-decoration-color: rgb(69 10 10 / 0.85) !important;
}

.tw-decoration-red-950\/90 {
  text-decoration-color: rgb(69 10 10 / 0.9) !important;
}

.tw-decoration-red-950\/95 {
  text-decoration-color: rgb(69 10 10 / 0.95) !important;
}

.tw-decoration-rose-100 {
  text-decoration-color: #ffe4e6 !important;
}

.tw-decoration-rose-100\/0 {
  text-decoration-color: rgb(255 228 230 / 0) !important;
}

.tw-decoration-rose-100\/10 {
  text-decoration-color: rgb(255 228 230 / 0.1) !important;
}

.tw-decoration-rose-100\/100 {
  text-decoration-color: rgb(255 228 230 / 1) !important;
}

.tw-decoration-rose-100\/15 {
  text-decoration-color: rgb(255 228 230 / 0.15) !important;
}

.tw-decoration-rose-100\/20 {
  text-decoration-color: rgb(255 228 230 / 0.2) !important;
}

.tw-decoration-rose-100\/25 {
  text-decoration-color: rgb(255 228 230 / 0.25) !important;
}

.tw-decoration-rose-100\/30 {
  text-decoration-color: rgb(255 228 230 / 0.3) !important;
}

.tw-decoration-rose-100\/35 {
  text-decoration-color: rgb(255 228 230 / 0.35) !important;
}

.tw-decoration-rose-100\/40 {
  text-decoration-color: rgb(255 228 230 / 0.4) !important;
}

.tw-decoration-rose-100\/45 {
  text-decoration-color: rgb(255 228 230 / 0.45) !important;
}

.tw-decoration-rose-100\/5 {
  text-decoration-color: rgb(255 228 230 / 0.05) !important;
}

.tw-decoration-rose-100\/50 {
  text-decoration-color: rgb(255 228 230 / 0.5) !important;
}

.tw-decoration-rose-100\/55 {
  text-decoration-color: rgb(255 228 230 / 0.55) !important;
}

.tw-decoration-rose-100\/60 {
  text-decoration-color: rgb(255 228 230 / 0.6) !important;
}

.tw-decoration-rose-100\/65 {
  text-decoration-color: rgb(255 228 230 / 0.65) !important;
}

.tw-decoration-rose-100\/70 {
  text-decoration-color: rgb(255 228 230 / 0.7) !important;
}

.tw-decoration-rose-100\/75 {
  text-decoration-color: rgb(255 228 230 / 0.75) !important;
}

.tw-decoration-rose-100\/80 {
  text-decoration-color: rgb(255 228 230 / 0.8) !important;
}

.tw-decoration-rose-100\/85 {
  text-decoration-color: rgb(255 228 230 / 0.85) !important;
}

.tw-decoration-rose-100\/90 {
  text-decoration-color: rgb(255 228 230 / 0.9) !important;
}

.tw-decoration-rose-100\/95 {
  text-decoration-color: rgb(255 228 230 / 0.95) !important;
}

.tw-decoration-rose-200 {
  text-decoration-color: #fecdd3 !important;
}

.tw-decoration-rose-200\/0 {
  text-decoration-color: rgb(254 205 211 / 0) !important;
}

.tw-decoration-rose-200\/10 {
  text-decoration-color: rgb(254 205 211 / 0.1) !important;
}

.tw-decoration-rose-200\/100 {
  text-decoration-color: rgb(254 205 211 / 1) !important;
}

.tw-decoration-rose-200\/15 {
  text-decoration-color: rgb(254 205 211 / 0.15) !important;
}

.tw-decoration-rose-200\/20 {
  text-decoration-color: rgb(254 205 211 / 0.2) !important;
}

.tw-decoration-rose-200\/25 {
  text-decoration-color: rgb(254 205 211 / 0.25) !important;
}

.tw-decoration-rose-200\/30 {
  text-decoration-color: rgb(254 205 211 / 0.3) !important;
}

.tw-decoration-rose-200\/35 {
  text-decoration-color: rgb(254 205 211 / 0.35) !important;
}

.tw-decoration-rose-200\/40 {
  text-decoration-color: rgb(254 205 211 / 0.4) !important;
}

.tw-decoration-rose-200\/45 {
  text-decoration-color: rgb(254 205 211 / 0.45) !important;
}

.tw-decoration-rose-200\/5 {
  text-decoration-color: rgb(254 205 211 / 0.05) !important;
}

.tw-decoration-rose-200\/50 {
  text-decoration-color: rgb(254 205 211 / 0.5) !important;
}

.tw-decoration-rose-200\/55 {
  text-decoration-color: rgb(254 205 211 / 0.55) !important;
}

.tw-decoration-rose-200\/60 {
  text-decoration-color: rgb(254 205 211 / 0.6) !important;
}

.tw-decoration-rose-200\/65 {
  text-decoration-color: rgb(254 205 211 / 0.65) !important;
}

.tw-decoration-rose-200\/70 {
  text-decoration-color: rgb(254 205 211 / 0.7) !important;
}

.tw-decoration-rose-200\/75 {
  text-decoration-color: rgb(254 205 211 / 0.75) !important;
}

.tw-decoration-rose-200\/80 {
  text-decoration-color: rgb(254 205 211 / 0.8) !important;
}

.tw-decoration-rose-200\/85 {
  text-decoration-color: rgb(254 205 211 / 0.85) !important;
}

.tw-decoration-rose-200\/90 {
  text-decoration-color: rgb(254 205 211 / 0.9) !important;
}

.tw-decoration-rose-200\/95 {
  text-decoration-color: rgb(254 205 211 / 0.95) !important;
}

.tw-decoration-rose-300 {
  text-decoration-color: #fda4af !important;
}

.tw-decoration-rose-300\/0 {
  text-decoration-color: rgb(253 164 175 / 0) !important;
}

.tw-decoration-rose-300\/10 {
  text-decoration-color: rgb(253 164 175 / 0.1) !important;
}

.tw-decoration-rose-300\/100 {
  text-decoration-color: rgb(253 164 175 / 1) !important;
}

.tw-decoration-rose-300\/15 {
  text-decoration-color: rgb(253 164 175 / 0.15) !important;
}

.tw-decoration-rose-300\/20 {
  text-decoration-color: rgb(253 164 175 / 0.2) !important;
}

.tw-decoration-rose-300\/25 {
  text-decoration-color: rgb(253 164 175 / 0.25) !important;
}

.tw-decoration-rose-300\/30 {
  text-decoration-color: rgb(253 164 175 / 0.3) !important;
}

.tw-decoration-rose-300\/35 {
  text-decoration-color: rgb(253 164 175 / 0.35) !important;
}

.tw-decoration-rose-300\/40 {
  text-decoration-color: rgb(253 164 175 / 0.4) !important;
}

.tw-decoration-rose-300\/45 {
  text-decoration-color: rgb(253 164 175 / 0.45) !important;
}

.tw-decoration-rose-300\/5 {
  text-decoration-color: rgb(253 164 175 / 0.05) !important;
}

.tw-decoration-rose-300\/50 {
  text-decoration-color: rgb(253 164 175 / 0.5) !important;
}

.tw-decoration-rose-300\/55 {
  text-decoration-color: rgb(253 164 175 / 0.55) !important;
}

.tw-decoration-rose-300\/60 {
  text-decoration-color: rgb(253 164 175 / 0.6) !important;
}

.tw-decoration-rose-300\/65 {
  text-decoration-color: rgb(253 164 175 / 0.65) !important;
}

.tw-decoration-rose-300\/70 {
  text-decoration-color: rgb(253 164 175 / 0.7) !important;
}

.tw-decoration-rose-300\/75 {
  text-decoration-color: rgb(253 164 175 / 0.75) !important;
}

.tw-decoration-rose-300\/80 {
  text-decoration-color: rgb(253 164 175 / 0.8) !important;
}

.tw-decoration-rose-300\/85 {
  text-decoration-color: rgb(253 164 175 / 0.85) !important;
}

.tw-decoration-rose-300\/90 {
  text-decoration-color: rgb(253 164 175 / 0.9) !important;
}

.tw-decoration-rose-300\/95 {
  text-decoration-color: rgb(253 164 175 / 0.95) !important;
}

.tw-decoration-rose-400 {
  text-decoration-color: #fb7185 !important;
}

.tw-decoration-rose-400\/0 {
  text-decoration-color: rgb(251 113 133 / 0) !important;
}

.tw-decoration-rose-400\/10 {
  text-decoration-color: rgb(251 113 133 / 0.1) !important;
}

.tw-decoration-rose-400\/100 {
  text-decoration-color: rgb(251 113 133 / 1) !important;
}

.tw-decoration-rose-400\/15 {
  text-decoration-color: rgb(251 113 133 / 0.15) !important;
}

.tw-decoration-rose-400\/20 {
  text-decoration-color: rgb(251 113 133 / 0.2) !important;
}

.tw-decoration-rose-400\/25 {
  text-decoration-color: rgb(251 113 133 / 0.25) !important;
}

.tw-decoration-rose-400\/30 {
  text-decoration-color: rgb(251 113 133 / 0.3) !important;
}

.tw-decoration-rose-400\/35 {
  text-decoration-color: rgb(251 113 133 / 0.35) !important;
}

.tw-decoration-rose-400\/40 {
  text-decoration-color: rgb(251 113 133 / 0.4) !important;
}

.tw-decoration-rose-400\/45 {
  text-decoration-color: rgb(251 113 133 / 0.45) !important;
}

.tw-decoration-rose-400\/5 {
  text-decoration-color: rgb(251 113 133 / 0.05) !important;
}

.tw-decoration-rose-400\/50 {
  text-decoration-color: rgb(251 113 133 / 0.5) !important;
}

.tw-decoration-rose-400\/55 {
  text-decoration-color: rgb(251 113 133 / 0.55) !important;
}

.tw-decoration-rose-400\/60 {
  text-decoration-color: rgb(251 113 133 / 0.6) !important;
}

.tw-decoration-rose-400\/65 {
  text-decoration-color: rgb(251 113 133 / 0.65) !important;
}

.tw-decoration-rose-400\/70 {
  text-decoration-color: rgb(251 113 133 / 0.7) !important;
}

.tw-decoration-rose-400\/75 {
  text-decoration-color: rgb(251 113 133 / 0.75) !important;
}

.tw-decoration-rose-400\/80 {
  text-decoration-color: rgb(251 113 133 / 0.8) !important;
}

.tw-decoration-rose-400\/85 {
  text-decoration-color: rgb(251 113 133 / 0.85) !important;
}

.tw-decoration-rose-400\/90 {
  text-decoration-color: rgb(251 113 133 / 0.9) !important;
}

.tw-decoration-rose-400\/95 {
  text-decoration-color: rgb(251 113 133 / 0.95) !important;
}

.tw-decoration-rose-50 {
  text-decoration-color: #fff1f2 !important;
}

.tw-decoration-rose-50\/0 {
  text-decoration-color: rgb(255 241 242 / 0) !important;
}

.tw-decoration-rose-50\/10 {
  text-decoration-color: rgb(255 241 242 / 0.1) !important;
}

.tw-decoration-rose-50\/100 {
  text-decoration-color: rgb(255 241 242 / 1) !important;
}

.tw-decoration-rose-50\/15 {
  text-decoration-color: rgb(255 241 242 / 0.15) !important;
}

.tw-decoration-rose-50\/20 {
  text-decoration-color: rgb(255 241 242 / 0.2) !important;
}

.tw-decoration-rose-50\/25 {
  text-decoration-color: rgb(255 241 242 / 0.25) !important;
}

.tw-decoration-rose-50\/30 {
  text-decoration-color: rgb(255 241 242 / 0.3) !important;
}

.tw-decoration-rose-50\/35 {
  text-decoration-color: rgb(255 241 242 / 0.35) !important;
}

.tw-decoration-rose-50\/40 {
  text-decoration-color: rgb(255 241 242 / 0.4) !important;
}

.tw-decoration-rose-50\/45 {
  text-decoration-color: rgb(255 241 242 / 0.45) !important;
}

.tw-decoration-rose-50\/5 {
  text-decoration-color: rgb(255 241 242 / 0.05) !important;
}

.tw-decoration-rose-50\/50 {
  text-decoration-color: rgb(255 241 242 / 0.5) !important;
}

.tw-decoration-rose-50\/55 {
  text-decoration-color: rgb(255 241 242 / 0.55) !important;
}

.tw-decoration-rose-50\/60 {
  text-decoration-color: rgb(255 241 242 / 0.6) !important;
}

.tw-decoration-rose-50\/65 {
  text-decoration-color: rgb(255 241 242 / 0.65) !important;
}

.tw-decoration-rose-50\/70 {
  text-decoration-color: rgb(255 241 242 / 0.7) !important;
}

.tw-decoration-rose-50\/75 {
  text-decoration-color: rgb(255 241 242 / 0.75) !important;
}

.tw-decoration-rose-50\/80 {
  text-decoration-color: rgb(255 241 242 / 0.8) !important;
}

.tw-decoration-rose-50\/85 {
  text-decoration-color: rgb(255 241 242 / 0.85) !important;
}

.tw-decoration-rose-50\/90 {
  text-decoration-color: rgb(255 241 242 / 0.9) !important;
}

.tw-decoration-rose-50\/95 {
  text-decoration-color: rgb(255 241 242 / 0.95) !important;
}

.tw-decoration-rose-500 {
  text-decoration-color: #f43f5e !important;
}

.tw-decoration-rose-500\/0 {
  text-decoration-color: rgb(244 63 94 / 0) !important;
}

.tw-decoration-rose-500\/10 {
  text-decoration-color: rgb(244 63 94 / 0.1) !important;
}

.tw-decoration-rose-500\/100 {
  text-decoration-color: rgb(244 63 94 / 1) !important;
}

.tw-decoration-rose-500\/15 {
  text-decoration-color: rgb(244 63 94 / 0.15) !important;
}

.tw-decoration-rose-500\/20 {
  text-decoration-color: rgb(244 63 94 / 0.2) !important;
}

.tw-decoration-rose-500\/25 {
  text-decoration-color: rgb(244 63 94 / 0.25) !important;
}

.tw-decoration-rose-500\/30 {
  text-decoration-color: rgb(244 63 94 / 0.3) !important;
}

.tw-decoration-rose-500\/35 {
  text-decoration-color: rgb(244 63 94 / 0.35) !important;
}

.tw-decoration-rose-500\/40 {
  text-decoration-color: rgb(244 63 94 / 0.4) !important;
}

.tw-decoration-rose-500\/45 {
  text-decoration-color: rgb(244 63 94 / 0.45) !important;
}

.tw-decoration-rose-500\/5 {
  text-decoration-color: rgb(244 63 94 / 0.05) !important;
}

.tw-decoration-rose-500\/50 {
  text-decoration-color: rgb(244 63 94 / 0.5) !important;
}

.tw-decoration-rose-500\/55 {
  text-decoration-color: rgb(244 63 94 / 0.55) !important;
}

.tw-decoration-rose-500\/60 {
  text-decoration-color: rgb(244 63 94 / 0.6) !important;
}

.tw-decoration-rose-500\/65 {
  text-decoration-color: rgb(244 63 94 / 0.65) !important;
}

.tw-decoration-rose-500\/70 {
  text-decoration-color: rgb(244 63 94 / 0.7) !important;
}

.tw-decoration-rose-500\/75 {
  text-decoration-color: rgb(244 63 94 / 0.75) !important;
}

.tw-decoration-rose-500\/80 {
  text-decoration-color: rgb(244 63 94 / 0.8) !important;
}

.tw-decoration-rose-500\/85 {
  text-decoration-color: rgb(244 63 94 / 0.85) !important;
}

.tw-decoration-rose-500\/90 {
  text-decoration-color: rgb(244 63 94 / 0.9) !important;
}

.tw-decoration-rose-500\/95 {
  text-decoration-color: rgb(244 63 94 / 0.95) !important;
}

.tw-decoration-rose-600 {
  text-decoration-color: #e11d48 !important;
}

.tw-decoration-rose-600\/0 {
  text-decoration-color: rgb(225 29 72 / 0) !important;
}

.tw-decoration-rose-600\/10 {
  text-decoration-color: rgb(225 29 72 / 0.1) !important;
}

.tw-decoration-rose-600\/100 {
  text-decoration-color: rgb(225 29 72 / 1) !important;
}

.tw-decoration-rose-600\/15 {
  text-decoration-color: rgb(225 29 72 / 0.15) !important;
}

.tw-decoration-rose-600\/20 {
  text-decoration-color: rgb(225 29 72 / 0.2) !important;
}

.tw-decoration-rose-600\/25 {
  text-decoration-color: rgb(225 29 72 / 0.25) !important;
}

.tw-decoration-rose-600\/30 {
  text-decoration-color: rgb(225 29 72 / 0.3) !important;
}

.tw-decoration-rose-600\/35 {
  text-decoration-color: rgb(225 29 72 / 0.35) !important;
}

.tw-decoration-rose-600\/40 {
  text-decoration-color: rgb(225 29 72 / 0.4) !important;
}

.tw-decoration-rose-600\/45 {
  text-decoration-color: rgb(225 29 72 / 0.45) !important;
}

.tw-decoration-rose-600\/5 {
  text-decoration-color: rgb(225 29 72 / 0.05) !important;
}

.tw-decoration-rose-600\/50 {
  text-decoration-color: rgb(225 29 72 / 0.5) !important;
}

.tw-decoration-rose-600\/55 {
  text-decoration-color: rgb(225 29 72 / 0.55) !important;
}

.tw-decoration-rose-600\/60 {
  text-decoration-color: rgb(225 29 72 / 0.6) !important;
}

.tw-decoration-rose-600\/65 {
  text-decoration-color: rgb(225 29 72 / 0.65) !important;
}

.tw-decoration-rose-600\/70 {
  text-decoration-color: rgb(225 29 72 / 0.7) !important;
}

.tw-decoration-rose-600\/75 {
  text-decoration-color: rgb(225 29 72 / 0.75) !important;
}

.tw-decoration-rose-600\/80 {
  text-decoration-color: rgb(225 29 72 / 0.8) !important;
}

.tw-decoration-rose-600\/85 {
  text-decoration-color: rgb(225 29 72 / 0.85) !important;
}

.tw-decoration-rose-600\/90 {
  text-decoration-color: rgb(225 29 72 / 0.9) !important;
}

.tw-decoration-rose-600\/95 {
  text-decoration-color: rgb(225 29 72 / 0.95) !important;
}

.tw-decoration-rose-700 {
  text-decoration-color: #be123c !important;
}

.tw-decoration-rose-700\/0 {
  text-decoration-color: rgb(190 18 60 / 0) !important;
}

.tw-decoration-rose-700\/10 {
  text-decoration-color: rgb(190 18 60 / 0.1) !important;
}

.tw-decoration-rose-700\/100 {
  text-decoration-color: rgb(190 18 60 / 1) !important;
}

.tw-decoration-rose-700\/15 {
  text-decoration-color: rgb(190 18 60 / 0.15) !important;
}

.tw-decoration-rose-700\/20 {
  text-decoration-color: rgb(190 18 60 / 0.2) !important;
}

.tw-decoration-rose-700\/25 {
  text-decoration-color: rgb(190 18 60 / 0.25) !important;
}

.tw-decoration-rose-700\/30 {
  text-decoration-color: rgb(190 18 60 / 0.3) !important;
}

.tw-decoration-rose-700\/35 {
  text-decoration-color: rgb(190 18 60 / 0.35) !important;
}

.tw-decoration-rose-700\/40 {
  text-decoration-color: rgb(190 18 60 / 0.4) !important;
}

.tw-decoration-rose-700\/45 {
  text-decoration-color: rgb(190 18 60 / 0.45) !important;
}

.tw-decoration-rose-700\/5 {
  text-decoration-color: rgb(190 18 60 / 0.05) !important;
}

.tw-decoration-rose-700\/50 {
  text-decoration-color: rgb(190 18 60 / 0.5) !important;
}

.tw-decoration-rose-700\/55 {
  text-decoration-color: rgb(190 18 60 / 0.55) !important;
}

.tw-decoration-rose-700\/60 {
  text-decoration-color: rgb(190 18 60 / 0.6) !important;
}

.tw-decoration-rose-700\/65 {
  text-decoration-color: rgb(190 18 60 / 0.65) !important;
}

.tw-decoration-rose-700\/70 {
  text-decoration-color: rgb(190 18 60 / 0.7) !important;
}

.tw-decoration-rose-700\/75 {
  text-decoration-color: rgb(190 18 60 / 0.75) !important;
}

.tw-decoration-rose-700\/80 {
  text-decoration-color: rgb(190 18 60 / 0.8) !important;
}

.tw-decoration-rose-700\/85 {
  text-decoration-color: rgb(190 18 60 / 0.85) !important;
}

.tw-decoration-rose-700\/90 {
  text-decoration-color: rgb(190 18 60 / 0.9) !important;
}

.tw-decoration-rose-700\/95 {
  text-decoration-color: rgb(190 18 60 / 0.95) !important;
}

.tw-decoration-rose-800 {
  text-decoration-color: #9f1239 !important;
}

.tw-decoration-rose-800\/0 {
  text-decoration-color: rgb(159 18 57 / 0) !important;
}

.tw-decoration-rose-800\/10 {
  text-decoration-color: rgb(159 18 57 / 0.1) !important;
}

.tw-decoration-rose-800\/100 {
  text-decoration-color: rgb(159 18 57 / 1) !important;
}

.tw-decoration-rose-800\/15 {
  text-decoration-color: rgb(159 18 57 / 0.15) !important;
}

.tw-decoration-rose-800\/20 {
  text-decoration-color: rgb(159 18 57 / 0.2) !important;
}

.tw-decoration-rose-800\/25 {
  text-decoration-color: rgb(159 18 57 / 0.25) !important;
}

.tw-decoration-rose-800\/30 {
  text-decoration-color: rgb(159 18 57 / 0.3) !important;
}

.tw-decoration-rose-800\/35 {
  text-decoration-color: rgb(159 18 57 / 0.35) !important;
}

.tw-decoration-rose-800\/40 {
  text-decoration-color: rgb(159 18 57 / 0.4) !important;
}

.tw-decoration-rose-800\/45 {
  text-decoration-color: rgb(159 18 57 / 0.45) !important;
}

.tw-decoration-rose-800\/5 {
  text-decoration-color: rgb(159 18 57 / 0.05) !important;
}

.tw-decoration-rose-800\/50 {
  text-decoration-color: rgb(159 18 57 / 0.5) !important;
}

.tw-decoration-rose-800\/55 {
  text-decoration-color: rgb(159 18 57 / 0.55) !important;
}

.tw-decoration-rose-800\/60 {
  text-decoration-color: rgb(159 18 57 / 0.6) !important;
}

.tw-decoration-rose-800\/65 {
  text-decoration-color: rgb(159 18 57 / 0.65) !important;
}

.tw-decoration-rose-800\/70 {
  text-decoration-color: rgb(159 18 57 / 0.7) !important;
}

.tw-decoration-rose-800\/75 {
  text-decoration-color: rgb(159 18 57 / 0.75) !important;
}

.tw-decoration-rose-800\/80 {
  text-decoration-color: rgb(159 18 57 / 0.8) !important;
}

.tw-decoration-rose-800\/85 {
  text-decoration-color: rgb(159 18 57 / 0.85) !important;
}

.tw-decoration-rose-800\/90 {
  text-decoration-color: rgb(159 18 57 / 0.9) !important;
}

.tw-decoration-rose-800\/95 {
  text-decoration-color: rgb(159 18 57 / 0.95) !important;
}

.tw-decoration-rose-900 {
  text-decoration-color: #881337 !important;
}

.tw-decoration-rose-900\/0 {
  text-decoration-color: rgb(136 19 55 / 0) !important;
}

.tw-decoration-rose-900\/10 {
  text-decoration-color: rgb(136 19 55 / 0.1) !important;
}

.tw-decoration-rose-900\/100 {
  text-decoration-color: rgb(136 19 55 / 1) !important;
}

.tw-decoration-rose-900\/15 {
  text-decoration-color: rgb(136 19 55 / 0.15) !important;
}

.tw-decoration-rose-900\/20 {
  text-decoration-color: rgb(136 19 55 / 0.2) !important;
}

.tw-decoration-rose-900\/25 {
  text-decoration-color: rgb(136 19 55 / 0.25) !important;
}

.tw-decoration-rose-900\/30 {
  text-decoration-color: rgb(136 19 55 / 0.3) !important;
}

.tw-decoration-rose-900\/35 {
  text-decoration-color: rgb(136 19 55 / 0.35) !important;
}

.tw-decoration-rose-900\/40 {
  text-decoration-color: rgb(136 19 55 / 0.4) !important;
}

.tw-decoration-rose-900\/45 {
  text-decoration-color: rgb(136 19 55 / 0.45) !important;
}

.tw-decoration-rose-900\/5 {
  text-decoration-color: rgb(136 19 55 / 0.05) !important;
}

.tw-decoration-rose-900\/50 {
  text-decoration-color: rgb(136 19 55 / 0.5) !important;
}

.tw-decoration-rose-900\/55 {
  text-decoration-color: rgb(136 19 55 / 0.55) !important;
}

.tw-decoration-rose-900\/60 {
  text-decoration-color: rgb(136 19 55 / 0.6) !important;
}

.tw-decoration-rose-900\/65 {
  text-decoration-color: rgb(136 19 55 / 0.65) !important;
}

.tw-decoration-rose-900\/70 {
  text-decoration-color: rgb(136 19 55 / 0.7) !important;
}

.tw-decoration-rose-900\/75 {
  text-decoration-color: rgb(136 19 55 / 0.75) !important;
}

.tw-decoration-rose-900\/80 {
  text-decoration-color: rgb(136 19 55 / 0.8) !important;
}

.tw-decoration-rose-900\/85 {
  text-decoration-color: rgb(136 19 55 / 0.85) !important;
}

.tw-decoration-rose-900\/90 {
  text-decoration-color: rgb(136 19 55 / 0.9) !important;
}

.tw-decoration-rose-900\/95 {
  text-decoration-color: rgb(136 19 55 / 0.95) !important;
}

.tw-decoration-rose-950 {
  text-decoration-color: #4c0519 !important;
}

.tw-decoration-rose-950\/0 {
  text-decoration-color: rgb(76 5 25 / 0) !important;
}

.tw-decoration-rose-950\/10 {
  text-decoration-color: rgb(76 5 25 / 0.1) !important;
}

.tw-decoration-rose-950\/100 {
  text-decoration-color: rgb(76 5 25 / 1) !important;
}

.tw-decoration-rose-950\/15 {
  text-decoration-color: rgb(76 5 25 / 0.15) !important;
}

.tw-decoration-rose-950\/20 {
  text-decoration-color: rgb(76 5 25 / 0.2) !important;
}

.tw-decoration-rose-950\/25 {
  text-decoration-color: rgb(76 5 25 / 0.25) !important;
}

.tw-decoration-rose-950\/30 {
  text-decoration-color: rgb(76 5 25 / 0.3) !important;
}

.tw-decoration-rose-950\/35 {
  text-decoration-color: rgb(76 5 25 / 0.35) !important;
}

.tw-decoration-rose-950\/40 {
  text-decoration-color: rgb(76 5 25 / 0.4) !important;
}

.tw-decoration-rose-950\/45 {
  text-decoration-color: rgb(76 5 25 / 0.45) !important;
}

.tw-decoration-rose-950\/5 {
  text-decoration-color: rgb(76 5 25 / 0.05) !important;
}

.tw-decoration-rose-950\/50 {
  text-decoration-color: rgb(76 5 25 / 0.5) !important;
}

.tw-decoration-rose-950\/55 {
  text-decoration-color: rgb(76 5 25 / 0.55) !important;
}

.tw-decoration-rose-950\/60 {
  text-decoration-color: rgb(76 5 25 / 0.6) !important;
}

.tw-decoration-rose-950\/65 {
  text-decoration-color: rgb(76 5 25 / 0.65) !important;
}

.tw-decoration-rose-950\/70 {
  text-decoration-color: rgb(76 5 25 / 0.7) !important;
}

.tw-decoration-rose-950\/75 {
  text-decoration-color: rgb(76 5 25 / 0.75) !important;
}

.tw-decoration-rose-950\/80 {
  text-decoration-color: rgb(76 5 25 / 0.8) !important;
}

.tw-decoration-rose-950\/85 {
  text-decoration-color: rgb(76 5 25 / 0.85) !important;
}

.tw-decoration-rose-950\/90 {
  text-decoration-color: rgb(76 5 25 / 0.9) !important;
}

.tw-decoration-rose-950\/95 {
  text-decoration-color: rgb(76 5 25 / 0.95) !important;
}

.tw-decoration-sky-100 {
  text-decoration-color: #e0f2fe !important;
}

.tw-decoration-sky-100\/0 {
  text-decoration-color: rgb(224 242 254 / 0) !important;
}

.tw-decoration-sky-100\/10 {
  text-decoration-color: rgb(224 242 254 / 0.1) !important;
}

.tw-decoration-sky-100\/100 {
  text-decoration-color: rgb(224 242 254 / 1) !important;
}

.tw-decoration-sky-100\/15 {
  text-decoration-color: rgb(224 242 254 / 0.15) !important;
}

.tw-decoration-sky-100\/20 {
  text-decoration-color: rgb(224 242 254 / 0.2) !important;
}

.tw-decoration-sky-100\/25 {
  text-decoration-color: rgb(224 242 254 / 0.25) !important;
}

.tw-decoration-sky-100\/30 {
  text-decoration-color: rgb(224 242 254 / 0.3) !important;
}

.tw-decoration-sky-100\/35 {
  text-decoration-color: rgb(224 242 254 / 0.35) !important;
}

.tw-decoration-sky-100\/40 {
  text-decoration-color: rgb(224 242 254 / 0.4) !important;
}

.tw-decoration-sky-100\/45 {
  text-decoration-color: rgb(224 242 254 / 0.45) !important;
}

.tw-decoration-sky-100\/5 {
  text-decoration-color: rgb(224 242 254 / 0.05) !important;
}

.tw-decoration-sky-100\/50 {
  text-decoration-color: rgb(224 242 254 / 0.5) !important;
}

.tw-decoration-sky-100\/55 {
  text-decoration-color: rgb(224 242 254 / 0.55) !important;
}

.tw-decoration-sky-100\/60 {
  text-decoration-color: rgb(224 242 254 / 0.6) !important;
}

.tw-decoration-sky-100\/65 {
  text-decoration-color: rgb(224 242 254 / 0.65) !important;
}

.tw-decoration-sky-100\/70 {
  text-decoration-color: rgb(224 242 254 / 0.7) !important;
}

.tw-decoration-sky-100\/75 {
  text-decoration-color: rgb(224 242 254 / 0.75) !important;
}

.tw-decoration-sky-100\/80 {
  text-decoration-color: rgb(224 242 254 / 0.8) !important;
}

.tw-decoration-sky-100\/85 {
  text-decoration-color: rgb(224 242 254 / 0.85) !important;
}

.tw-decoration-sky-100\/90 {
  text-decoration-color: rgb(224 242 254 / 0.9) !important;
}

.tw-decoration-sky-100\/95 {
  text-decoration-color: rgb(224 242 254 / 0.95) !important;
}

.tw-decoration-sky-200 {
  text-decoration-color: #bae6fd !important;
}

.tw-decoration-sky-200\/0 {
  text-decoration-color: rgb(186 230 253 / 0) !important;
}

.tw-decoration-sky-200\/10 {
  text-decoration-color: rgb(186 230 253 / 0.1) !important;
}

.tw-decoration-sky-200\/100 {
  text-decoration-color: rgb(186 230 253 / 1) !important;
}

.tw-decoration-sky-200\/15 {
  text-decoration-color: rgb(186 230 253 / 0.15) !important;
}

.tw-decoration-sky-200\/20 {
  text-decoration-color: rgb(186 230 253 / 0.2) !important;
}

.tw-decoration-sky-200\/25 {
  text-decoration-color: rgb(186 230 253 / 0.25) !important;
}

.tw-decoration-sky-200\/30 {
  text-decoration-color: rgb(186 230 253 / 0.3) !important;
}

.tw-decoration-sky-200\/35 {
  text-decoration-color: rgb(186 230 253 / 0.35) !important;
}

.tw-decoration-sky-200\/40 {
  text-decoration-color: rgb(186 230 253 / 0.4) !important;
}

.tw-decoration-sky-200\/45 {
  text-decoration-color: rgb(186 230 253 / 0.45) !important;
}

.tw-decoration-sky-200\/5 {
  text-decoration-color: rgb(186 230 253 / 0.05) !important;
}

.tw-decoration-sky-200\/50 {
  text-decoration-color: rgb(186 230 253 / 0.5) !important;
}

.tw-decoration-sky-200\/55 {
  text-decoration-color: rgb(186 230 253 / 0.55) !important;
}

.tw-decoration-sky-200\/60 {
  text-decoration-color: rgb(186 230 253 / 0.6) !important;
}

.tw-decoration-sky-200\/65 {
  text-decoration-color: rgb(186 230 253 / 0.65) !important;
}

.tw-decoration-sky-200\/70 {
  text-decoration-color: rgb(186 230 253 / 0.7) !important;
}

.tw-decoration-sky-200\/75 {
  text-decoration-color: rgb(186 230 253 / 0.75) !important;
}

.tw-decoration-sky-200\/80 {
  text-decoration-color: rgb(186 230 253 / 0.8) !important;
}

.tw-decoration-sky-200\/85 {
  text-decoration-color: rgb(186 230 253 / 0.85) !important;
}

.tw-decoration-sky-200\/90 {
  text-decoration-color: rgb(186 230 253 / 0.9) !important;
}

.tw-decoration-sky-200\/95 {
  text-decoration-color: rgb(186 230 253 / 0.95) !important;
}

.tw-decoration-sky-300 {
  text-decoration-color: #7dd3fc !important;
}

.tw-decoration-sky-300\/0 {
  text-decoration-color: rgb(125 211 252 / 0) !important;
}

.tw-decoration-sky-300\/10 {
  text-decoration-color: rgb(125 211 252 / 0.1) !important;
}

.tw-decoration-sky-300\/100 {
  text-decoration-color: rgb(125 211 252 / 1) !important;
}

.tw-decoration-sky-300\/15 {
  text-decoration-color: rgb(125 211 252 / 0.15) !important;
}

.tw-decoration-sky-300\/20 {
  text-decoration-color: rgb(125 211 252 / 0.2) !important;
}

.tw-decoration-sky-300\/25 {
  text-decoration-color: rgb(125 211 252 / 0.25) !important;
}

.tw-decoration-sky-300\/30 {
  text-decoration-color: rgb(125 211 252 / 0.3) !important;
}

.tw-decoration-sky-300\/35 {
  text-decoration-color: rgb(125 211 252 / 0.35) !important;
}

.tw-decoration-sky-300\/40 {
  text-decoration-color: rgb(125 211 252 / 0.4) !important;
}

.tw-decoration-sky-300\/45 {
  text-decoration-color: rgb(125 211 252 / 0.45) !important;
}

.tw-decoration-sky-300\/5 {
  text-decoration-color: rgb(125 211 252 / 0.05) !important;
}

.tw-decoration-sky-300\/50 {
  text-decoration-color: rgb(125 211 252 / 0.5) !important;
}

.tw-decoration-sky-300\/55 {
  text-decoration-color: rgb(125 211 252 / 0.55) !important;
}

.tw-decoration-sky-300\/60 {
  text-decoration-color: rgb(125 211 252 / 0.6) !important;
}

.tw-decoration-sky-300\/65 {
  text-decoration-color: rgb(125 211 252 / 0.65) !important;
}

.tw-decoration-sky-300\/70 {
  text-decoration-color: rgb(125 211 252 / 0.7) !important;
}

.tw-decoration-sky-300\/75 {
  text-decoration-color: rgb(125 211 252 / 0.75) !important;
}

.tw-decoration-sky-300\/80 {
  text-decoration-color: rgb(125 211 252 / 0.8) !important;
}

.tw-decoration-sky-300\/85 {
  text-decoration-color: rgb(125 211 252 / 0.85) !important;
}

.tw-decoration-sky-300\/90 {
  text-decoration-color: rgb(125 211 252 / 0.9) !important;
}

.tw-decoration-sky-300\/95 {
  text-decoration-color: rgb(125 211 252 / 0.95) !important;
}

.tw-decoration-sky-400 {
  text-decoration-color: #38bdf8 !important;
}

.tw-decoration-sky-400\/0 {
  text-decoration-color: rgb(56 189 248 / 0) !important;
}

.tw-decoration-sky-400\/10 {
  text-decoration-color: rgb(56 189 248 / 0.1) !important;
}

.tw-decoration-sky-400\/100 {
  text-decoration-color: rgb(56 189 248 / 1) !important;
}

.tw-decoration-sky-400\/15 {
  text-decoration-color: rgb(56 189 248 / 0.15) !important;
}

.tw-decoration-sky-400\/20 {
  text-decoration-color: rgb(56 189 248 / 0.2) !important;
}

.tw-decoration-sky-400\/25 {
  text-decoration-color: rgb(56 189 248 / 0.25) !important;
}

.tw-decoration-sky-400\/30 {
  text-decoration-color: rgb(56 189 248 / 0.3) !important;
}

.tw-decoration-sky-400\/35 {
  text-decoration-color: rgb(56 189 248 / 0.35) !important;
}

.tw-decoration-sky-400\/40 {
  text-decoration-color: rgb(56 189 248 / 0.4) !important;
}

.tw-decoration-sky-400\/45 {
  text-decoration-color: rgb(56 189 248 / 0.45) !important;
}

.tw-decoration-sky-400\/5 {
  text-decoration-color: rgb(56 189 248 / 0.05) !important;
}

.tw-decoration-sky-400\/50 {
  text-decoration-color: rgb(56 189 248 / 0.5) !important;
}

.tw-decoration-sky-400\/55 {
  text-decoration-color: rgb(56 189 248 / 0.55) !important;
}

.tw-decoration-sky-400\/60 {
  text-decoration-color: rgb(56 189 248 / 0.6) !important;
}

.tw-decoration-sky-400\/65 {
  text-decoration-color: rgb(56 189 248 / 0.65) !important;
}

.tw-decoration-sky-400\/70 {
  text-decoration-color: rgb(56 189 248 / 0.7) !important;
}

.tw-decoration-sky-400\/75 {
  text-decoration-color: rgb(56 189 248 / 0.75) !important;
}

.tw-decoration-sky-400\/80 {
  text-decoration-color: rgb(56 189 248 / 0.8) !important;
}

.tw-decoration-sky-400\/85 {
  text-decoration-color: rgb(56 189 248 / 0.85) !important;
}

.tw-decoration-sky-400\/90 {
  text-decoration-color: rgb(56 189 248 / 0.9) !important;
}

.tw-decoration-sky-400\/95 {
  text-decoration-color: rgb(56 189 248 / 0.95) !important;
}

.tw-decoration-sky-50 {
  text-decoration-color: #f0f9ff !important;
}

.tw-decoration-sky-50\/0 {
  text-decoration-color: rgb(240 249 255 / 0) !important;
}

.tw-decoration-sky-50\/10 {
  text-decoration-color: rgb(240 249 255 / 0.1) !important;
}

.tw-decoration-sky-50\/100 {
  text-decoration-color: rgb(240 249 255 / 1) !important;
}

.tw-decoration-sky-50\/15 {
  text-decoration-color: rgb(240 249 255 / 0.15) !important;
}

.tw-decoration-sky-50\/20 {
  text-decoration-color: rgb(240 249 255 / 0.2) !important;
}

.tw-decoration-sky-50\/25 {
  text-decoration-color: rgb(240 249 255 / 0.25) !important;
}

.tw-decoration-sky-50\/30 {
  text-decoration-color: rgb(240 249 255 / 0.3) !important;
}

.tw-decoration-sky-50\/35 {
  text-decoration-color: rgb(240 249 255 / 0.35) !important;
}

.tw-decoration-sky-50\/40 {
  text-decoration-color: rgb(240 249 255 / 0.4) !important;
}

.tw-decoration-sky-50\/45 {
  text-decoration-color: rgb(240 249 255 / 0.45) !important;
}

.tw-decoration-sky-50\/5 {
  text-decoration-color: rgb(240 249 255 / 0.05) !important;
}

.tw-decoration-sky-50\/50 {
  text-decoration-color: rgb(240 249 255 / 0.5) !important;
}

.tw-decoration-sky-50\/55 {
  text-decoration-color: rgb(240 249 255 / 0.55) !important;
}

.tw-decoration-sky-50\/60 {
  text-decoration-color: rgb(240 249 255 / 0.6) !important;
}

.tw-decoration-sky-50\/65 {
  text-decoration-color: rgb(240 249 255 / 0.65) !important;
}

.tw-decoration-sky-50\/70 {
  text-decoration-color: rgb(240 249 255 / 0.7) !important;
}

.tw-decoration-sky-50\/75 {
  text-decoration-color: rgb(240 249 255 / 0.75) !important;
}

.tw-decoration-sky-50\/80 {
  text-decoration-color: rgb(240 249 255 / 0.8) !important;
}

.tw-decoration-sky-50\/85 {
  text-decoration-color: rgb(240 249 255 / 0.85) !important;
}

.tw-decoration-sky-50\/90 {
  text-decoration-color: rgb(240 249 255 / 0.9) !important;
}

.tw-decoration-sky-50\/95 {
  text-decoration-color: rgb(240 249 255 / 0.95) !important;
}

.tw-decoration-sky-500 {
  text-decoration-color: #0ea5e9 !important;
}

.tw-decoration-sky-500\/0 {
  text-decoration-color: rgb(14 165 233 / 0) !important;
}

.tw-decoration-sky-500\/10 {
  text-decoration-color: rgb(14 165 233 / 0.1) !important;
}

.tw-decoration-sky-500\/100 {
  text-decoration-color: rgb(14 165 233 / 1) !important;
}

.tw-decoration-sky-500\/15 {
  text-decoration-color: rgb(14 165 233 / 0.15) !important;
}

.tw-decoration-sky-500\/20 {
  text-decoration-color: rgb(14 165 233 / 0.2) !important;
}

.tw-decoration-sky-500\/25 {
  text-decoration-color: rgb(14 165 233 / 0.25) !important;
}

.tw-decoration-sky-500\/30 {
  text-decoration-color: rgb(14 165 233 / 0.3) !important;
}

.tw-decoration-sky-500\/35 {
  text-decoration-color: rgb(14 165 233 / 0.35) !important;
}

.tw-decoration-sky-500\/40 {
  text-decoration-color: rgb(14 165 233 / 0.4) !important;
}

.tw-decoration-sky-500\/45 {
  text-decoration-color: rgb(14 165 233 / 0.45) !important;
}

.tw-decoration-sky-500\/5 {
  text-decoration-color: rgb(14 165 233 / 0.05) !important;
}

.tw-decoration-sky-500\/50 {
  text-decoration-color: rgb(14 165 233 / 0.5) !important;
}

.tw-decoration-sky-500\/55 {
  text-decoration-color: rgb(14 165 233 / 0.55) !important;
}

.tw-decoration-sky-500\/60 {
  text-decoration-color: rgb(14 165 233 / 0.6) !important;
}

.tw-decoration-sky-500\/65 {
  text-decoration-color: rgb(14 165 233 / 0.65) !important;
}

.tw-decoration-sky-500\/70 {
  text-decoration-color: rgb(14 165 233 / 0.7) !important;
}

.tw-decoration-sky-500\/75 {
  text-decoration-color: rgb(14 165 233 / 0.75) !important;
}

.tw-decoration-sky-500\/80 {
  text-decoration-color: rgb(14 165 233 / 0.8) !important;
}

.tw-decoration-sky-500\/85 {
  text-decoration-color: rgb(14 165 233 / 0.85) !important;
}

.tw-decoration-sky-500\/90 {
  text-decoration-color: rgb(14 165 233 / 0.9) !important;
}

.tw-decoration-sky-500\/95 {
  text-decoration-color: rgb(14 165 233 / 0.95) !important;
}

.tw-decoration-sky-600 {
  text-decoration-color: #0284c7 !important;
}

.tw-decoration-sky-600\/0 {
  text-decoration-color: rgb(2 132 199 / 0) !important;
}

.tw-decoration-sky-600\/10 {
  text-decoration-color: rgb(2 132 199 / 0.1) !important;
}

.tw-decoration-sky-600\/100 {
  text-decoration-color: rgb(2 132 199 / 1) !important;
}

.tw-decoration-sky-600\/15 {
  text-decoration-color: rgb(2 132 199 / 0.15) !important;
}

.tw-decoration-sky-600\/20 {
  text-decoration-color: rgb(2 132 199 / 0.2) !important;
}

.tw-decoration-sky-600\/25 {
  text-decoration-color: rgb(2 132 199 / 0.25) !important;
}

.tw-decoration-sky-600\/30 {
  text-decoration-color: rgb(2 132 199 / 0.3) !important;
}

.tw-decoration-sky-600\/35 {
  text-decoration-color: rgb(2 132 199 / 0.35) !important;
}

.tw-decoration-sky-600\/40 {
  text-decoration-color: rgb(2 132 199 / 0.4) !important;
}

.tw-decoration-sky-600\/45 {
  text-decoration-color: rgb(2 132 199 / 0.45) !important;
}

.tw-decoration-sky-600\/5 {
  text-decoration-color: rgb(2 132 199 / 0.05) !important;
}

.tw-decoration-sky-600\/50 {
  text-decoration-color: rgb(2 132 199 / 0.5) !important;
}

.tw-decoration-sky-600\/55 {
  text-decoration-color: rgb(2 132 199 / 0.55) !important;
}

.tw-decoration-sky-600\/60 {
  text-decoration-color: rgb(2 132 199 / 0.6) !important;
}

.tw-decoration-sky-600\/65 {
  text-decoration-color: rgb(2 132 199 / 0.65) !important;
}

.tw-decoration-sky-600\/70 {
  text-decoration-color: rgb(2 132 199 / 0.7) !important;
}

.tw-decoration-sky-600\/75 {
  text-decoration-color: rgb(2 132 199 / 0.75) !important;
}

.tw-decoration-sky-600\/80 {
  text-decoration-color: rgb(2 132 199 / 0.8) !important;
}

.tw-decoration-sky-600\/85 {
  text-decoration-color: rgb(2 132 199 / 0.85) !important;
}

.tw-decoration-sky-600\/90 {
  text-decoration-color: rgb(2 132 199 / 0.9) !important;
}

.tw-decoration-sky-600\/95 {
  text-decoration-color: rgb(2 132 199 / 0.95) !important;
}

.tw-decoration-sky-700 {
  text-decoration-color: #0369a1 !important;
}

.tw-decoration-sky-700\/0 {
  text-decoration-color: rgb(3 105 161 / 0) !important;
}

.tw-decoration-sky-700\/10 {
  text-decoration-color: rgb(3 105 161 / 0.1) !important;
}

.tw-decoration-sky-700\/100 {
  text-decoration-color: rgb(3 105 161 / 1) !important;
}

.tw-decoration-sky-700\/15 {
  text-decoration-color: rgb(3 105 161 / 0.15) !important;
}

.tw-decoration-sky-700\/20 {
  text-decoration-color: rgb(3 105 161 / 0.2) !important;
}

.tw-decoration-sky-700\/25 {
  text-decoration-color: rgb(3 105 161 / 0.25) !important;
}

.tw-decoration-sky-700\/30 {
  text-decoration-color: rgb(3 105 161 / 0.3) !important;
}

.tw-decoration-sky-700\/35 {
  text-decoration-color: rgb(3 105 161 / 0.35) !important;
}

.tw-decoration-sky-700\/40 {
  text-decoration-color: rgb(3 105 161 / 0.4) !important;
}

.tw-decoration-sky-700\/45 {
  text-decoration-color: rgb(3 105 161 / 0.45) !important;
}

.tw-decoration-sky-700\/5 {
  text-decoration-color: rgb(3 105 161 / 0.05) !important;
}

.tw-decoration-sky-700\/50 {
  text-decoration-color: rgb(3 105 161 / 0.5) !important;
}

.tw-decoration-sky-700\/55 {
  text-decoration-color: rgb(3 105 161 / 0.55) !important;
}

.tw-decoration-sky-700\/60 {
  text-decoration-color: rgb(3 105 161 / 0.6) !important;
}

.tw-decoration-sky-700\/65 {
  text-decoration-color: rgb(3 105 161 / 0.65) !important;
}

.tw-decoration-sky-700\/70 {
  text-decoration-color: rgb(3 105 161 / 0.7) !important;
}

.tw-decoration-sky-700\/75 {
  text-decoration-color: rgb(3 105 161 / 0.75) !important;
}

.tw-decoration-sky-700\/80 {
  text-decoration-color: rgb(3 105 161 / 0.8) !important;
}

.tw-decoration-sky-700\/85 {
  text-decoration-color: rgb(3 105 161 / 0.85) !important;
}

.tw-decoration-sky-700\/90 {
  text-decoration-color: rgb(3 105 161 / 0.9) !important;
}

.tw-decoration-sky-700\/95 {
  text-decoration-color: rgb(3 105 161 / 0.95) !important;
}

.tw-decoration-sky-800 {
  text-decoration-color: #075985 !important;
}

.tw-decoration-sky-800\/0 {
  text-decoration-color: rgb(7 89 133 / 0) !important;
}

.tw-decoration-sky-800\/10 {
  text-decoration-color: rgb(7 89 133 / 0.1) !important;
}

.tw-decoration-sky-800\/100 {
  text-decoration-color: rgb(7 89 133 / 1) !important;
}

.tw-decoration-sky-800\/15 {
  text-decoration-color: rgb(7 89 133 / 0.15) !important;
}

.tw-decoration-sky-800\/20 {
  text-decoration-color: rgb(7 89 133 / 0.2) !important;
}

.tw-decoration-sky-800\/25 {
  text-decoration-color: rgb(7 89 133 / 0.25) !important;
}

.tw-decoration-sky-800\/30 {
  text-decoration-color: rgb(7 89 133 / 0.3) !important;
}

.tw-decoration-sky-800\/35 {
  text-decoration-color: rgb(7 89 133 / 0.35) !important;
}

.tw-decoration-sky-800\/40 {
  text-decoration-color: rgb(7 89 133 / 0.4) !important;
}

.tw-decoration-sky-800\/45 {
  text-decoration-color: rgb(7 89 133 / 0.45) !important;
}

.tw-decoration-sky-800\/5 {
  text-decoration-color: rgb(7 89 133 / 0.05) !important;
}

.tw-decoration-sky-800\/50 {
  text-decoration-color: rgb(7 89 133 / 0.5) !important;
}

.tw-decoration-sky-800\/55 {
  text-decoration-color: rgb(7 89 133 / 0.55) !important;
}

.tw-decoration-sky-800\/60 {
  text-decoration-color: rgb(7 89 133 / 0.6) !important;
}

.tw-decoration-sky-800\/65 {
  text-decoration-color: rgb(7 89 133 / 0.65) !important;
}

.tw-decoration-sky-800\/70 {
  text-decoration-color: rgb(7 89 133 / 0.7) !important;
}

.tw-decoration-sky-800\/75 {
  text-decoration-color: rgb(7 89 133 / 0.75) !important;
}

.tw-decoration-sky-800\/80 {
  text-decoration-color: rgb(7 89 133 / 0.8) !important;
}

.tw-decoration-sky-800\/85 {
  text-decoration-color: rgb(7 89 133 / 0.85) !important;
}

.tw-decoration-sky-800\/90 {
  text-decoration-color: rgb(7 89 133 / 0.9) !important;
}

.tw-decoration-sky-800\/95 {
  text-decoration-color: rgb(7 89 133 / 0.95) !important;
}

.tw-decoration-sky-900 {
  text-decoration-color: #0c4a6e !important;
}

.tw-decoration-sky-900\/0 {
  text-decoration-color: rgb(12 74 110 / 0) !important;
}

.tw-decoration-sky-900\/10 {
  text-decoration-color: rgb(12 74 110 / 0.1) !important;
}

.tw-decoration-sky-900\/100 {
  text-decoration-color: rgb(12 74 110 / 1) !important;
}

.tw-decoration-sky-900\/15 {
  text-decoration-color: rgb(12 74 110 / 0.15) !important;
}

.tw-decoration-sky-900\/20 {
  text-decoration-color: rgb(12 74 110 / 0.2) !important;
}

.tw-decoration-sky-900\/25 {
  text-decoration-color: rgb(12 74 110 / 0.25) !important;
}

.tw-decoration-sky-900\/30 {
  text-decoration-color: rgb(12 74 110 / 0.3) !important;
}

.tw-decoration-sky-900\/35 {
  text-decoration-color: rgb(12 74 110 / 0.35) !important;
}

.tw-decoration-sky-900\/40 {
  text-decoration-color: rgb(12 74 110 / 0.4) !important;
}

.tw-decoration-sky-900\/45 {
  text-decoration-color: rgb(12 74 110 / 0.45) !important;
}

.tw-decoration-sky-900\/5 {
  text-decoration-color: rgb(12 74 110 / 0.05) !important;
}

.tw-decoration-sky-900\/50 {
  text-decoration-color: rgb(12 74 110 / 0.5) !important;
}

.tw-decoration-sky-900\/55 {
  text-decoration-color: rgb(12 74 110 / 0.55) !important;
}

.tw-decoration-sky-900\/60 {
  text-decoration-color: rgb(12 74 110 / 0.6) !important;
}

.tw-decoration-sky-900\/65 {
  text-decoration-color: rgb(12 74 110 / 0.65) !important;
}

.tw-decoration-sky-900\/70 {
  text-decoration-color: rgb(12 74 110 / 0.7) !important;
}

.tw-decoration-sky-900\/75 {
  text-decoration-color: rgb(12 74 110 / 0.75) !important;
}

.tw-decoration-sky-900\/80 {
  text-decoration-color: rgb(12 74 110 / 0.8) !important;
}

.tw-decoration-sky-900\/85 {
  text-decoration-color: rgb(12 74 110 / 0.85) !important;
}

.tw-decoration-sky-900\/90 {
  text-decoration-color: rgb(12 74 110 / 0.9) !important;
}

.tw-decoration-sky-900\/95 {
  text-decoration-color: rgb(12 74 110 / 0.95) !important;
}

.tw-decoration-sky-950 {
  text-decoration-color: #082f49 !important;
}

.tw-decoration-sky-950\/0 {
  text-decoration-color: rgb(8 47 73 / 0) !important;
}

.tw-decoration-sky-950\/10 {
  text-decoration-color: rgb(8 47 73 / 0.1) !important;
}

.tw-decoration-sky-950\/100 {
  text-decoration-color: rgb(8 47 73 / 1) !important;
}

.tw-decoration-sky-950\/15 {
  text-decoration-color: rgb(8 47 73 / 0.15) !important;
}

.tw-decoration-sky-950\/20 {
  text-decoration-color: rgb(8 47 73 / 0.2) !important;
}

.tw-decoration-sky-950\/25 {
  text-decoration-color: rgb(8 47 73 / 0.25) !important;
}

.tw-decoration-sky-950\/30 {
  text-decoration-color: rgb(8 47 73 / 0.3) !important;
}

.tw-decoration-sky-950\/35 {
  text-decoration-color: rgb(8 47 73 / 0.35) !important;
}

.tw-decoration-sky-950\/40 {
  text-decoration-color: rgb(8 47 73 / 0.4) !important;
}

.tw-decoration-sky-950\/45 {
  text-decoration-color: rgb(8 47 73 / 0.45) !important;
}

.tw-decoration-sky-950\/5 {
  text-decoration-color: rgb(8 47 73 / 0.05) !important;
}

.tw-decoration-sky-950\/50 {
  text-decoration-color: rgb(8 47 73 / 0.5) !important;
}

.tw-decoration-sky-950\/55 {
  text-decoration-color: rgb(8 47 73 / 0.55) !important;
}

.tw-decoration-sky-950\/60 {
  text-decoration-color: rgb(8 47 73 / 0.6) !important;
}

.tw-decoration-sky-950\/65 {
  text-decoration-color: rgb(8 47 73 / 0.65) !important;
}

.tw-decoration-sky-950\/70 {
  text-decoration-color: rgb(8 47 73 / 0.7) !important;
}

.tw-decoration-sky-950\/75 {
  text-decoration-color: rgb(8 47 73 / 0.75) !important;
}

.tw-decoration-sky-950\/80 {
  text-decoration-color: rgb(8 47 73 / 0.8) !important;
}

.tw-decoration-sky-950\/85 {
  text-decoration-color: rgb(8 47 73 / 0.85) !important;
}

.tw-decoration-sky-950\/90 {
  text-decoration-color: rgb(8 47 73 / 0.9) !important;
}

.tw-decoration-sky-950\/95 {
  text-decoration-color: rgb(8 47 73 / 0.95) !important;
}

.tw-decoration-slate-100 {
  text-decoration-color: #f1f5f9 !important;
}

.tw-decoration-slate-100\/0 {
  text-decoration-color: rgb(241 245 249 / 0) !important;
}

.tw-decoration-slate-100\/10 {
  text-decoration-color: rgb(241 245 249 / 0.1) !important;
}

.tw-decoration-slate-100\/100 {
  text-decoration-color: rgb(241 245 249 / 1) !important;
}

.tw-decoration-slate-100\/15 {
  text-decoration-color: rgb(241 245 249 / 0.15) !important;
}

.tw-decoration-slate-100\/20 {
  text-decoration-color: rgb(241 245 249 / 0.2) !important;
}

.tw-decoration-slate-100\/25 {
  text-decoration-color: rgb(241 245 249 / 0.25) !important;
}

.tw-decoration-slate-100\/30 {
  text-decoration-color: rgb(241 245 249 / 0.3) !important;
}

.tw-decoration-slate-100\/35 {
  text-decoration-color: rgb(241 245 249 / 0.35) !important;
}

.tw-decoration-slate-100\/40 {
  text-decoration-color: rgb(241 245 249 / 0.4) !important;
}

.tw-decoration-slate-100\/45 {
  text-decoration-color: rgb(241 245 249 / 0.45) !important;
}

.tw-decoration-slate-100\/5 {
  text-decoration-color: rgb(241 245 249 / 0.05) !important;
}

.tw-decoration-slate-100\/50 {
  text-decoration-color: rgb(241 245 249 / 0.5) !important;
}

.tw-decoration-slate-100\/55 {
  text-decoration-color: rgb(241 245 249 / 0.55) !important;
}

.tw-decoration-slate-100\/60 {
  text-decoration-color: rgb(241 245 249 / 0.6) !important;
}

.tw-decoration-slate-100\/65 {
  text-decoration-color: rgb(241 245 249 / 0.65) !important;
}

.tw-decoration-slate-100\/70 {
  text-decoration-color: rgb(241 245 249 / 0.7) !important;
}

.tw-decoration-slate-100\/75 {
  text-decoration-color: rgb(241 245 249 / 0.75) !important;
}

.tw-decoration-slate-100\/80 {
  text-decoration-color: rgb(241 245 249 / 0.8) !important;
}

.tw-decoration-slate-100\/85 {
  text-decoration-color: rgb(241 245 249 / 0.85) !important;
}

.tw-decoration-slate-100\/90 {
  text-decoration-color: rgb(241 245 249 / 0.9) !important;
}

.tw-decoration-slate-100\/95 {
  text-decoration-color: rgb(241 245 249 / 0.95) !important;
}

.tw-decoration-slate-200 {
  text-decoration-color: #e2e8f0 !important;
}

.tw-decoration-slate-200\/0 {
  text-decoration-color: rgb(226 232 240 / 0) !important;
}

.tw-decoration-slate-200\/10 {
  text-decoration-color: rgb(226 232 240 / 0.1) !important;
}

.tw-decoration-slate-200\/100 {
  text-decoration-color: rgb(226 232 240 / 1) !important;
}

.tw-decoration-slate-200\/15 {
  text-decoration-color: rgb(226 232 240 / 0.15) !important;
}

.tw-decoration-slate-200\/20 {
  text-decoration-color: rgb(226 232 240 / 0.2) !important;
}

.tw-decoration-slate-200\/25 {
  text-decoration-color: rgb(226 232 240 / 0.25) !important;
}

.tw-decoration-slate-200\/30 {
  text-decoration-color: rgb(226 232 240 / 0.3) !important;
}

.tw-decoration-slate-200\/35 {
  text-decoration-color: rgb(226 232 240 / 0.35) !important;
}

.tw-decoration-slate-200\/40 {
  text-decoration-color: rgb(226 232 240 / 0.4) !important;
}

.tw-decoration-slate-200\/45 {
  text-decoration-color: rgb(226 232 240 / 0.45) !important;
}

.tw-decoration-slate-200\/5 {
  text-decoration-color: rgb(226 232 240 / 0.05) !important;
}

.tw-decoration-slate-200\/50 {
  text-decoration-color: rgb(226 232 240 / 0.5) !important;
}

.tw-decoration-slate-200\/55 {
  text-decoration-color: rgb(226 232 240 / 0.55) !important;
}

.tw-decoration-slate-200\/60 {
  text-decoration-color: rgb(226 232 240 / 0.6) !important;
}

.tw-decoration-slate-200\/65 {
  text-decoration-color: rgb(226 232 240 / 0.65) !important;
}

.tw-decoration-slate-200\/70 {
  text-decoration-color: rgb(226 232 240 / 0.7) !important;
}

.tw-decoration-slate-200\/75 {
  text-decoration-color: rgb(226 232 240 / 0.75) !important;
}

.tw-decoration-slate-200\/80 {
  text-decoration-color: rgb(226 232 240 / 0.8) !important;
}

.tw-decoration-slate-200\/85 {
  text-decoration-color: rgb(226 232 240 / 0.85) !important;
}

.tw-decoration-slate-200\/90 {
  text-decoration-color: rgb(226 232 240 / 0.9) !important;
}

.tw-decoration-slate-200\/95 {
  text-decoration-color: rgb(226 232 240 / 0.95) !important;
}

.tw-decoration-slate-300 {
  text-decoration-color: #cbd5e1 !important;
}

.tw-decoration-slate-300\/0 {
  text-decoration-color: rgb(203 213 225 / 0) !important;
}

.tw-decoration-slate-300\/10 {
  text-decoration-color: rgb(203 213 225 / 0.1) !important;
}

.tw-decoration-slate-300\/100 {
  text-decoration-color: rgb(203 213 225 / 1) !important;
}

.tw-decoration-slate-300\/15 {
  text-decoration-color: rgb(203 213 225 / 0.15) !important;
}

.tw-decoration-slate-300\/20 {
  text-decoration-color: rgb(203 213 225 / 0.2) !important;
}

.tw-decoration-slate-300\/25 {
  text-decoration-color: rgb(203 213 225 / 0.25) !important;
}

.tw-decoration-slate-300\/30 {
  text-decoration-color: rgb(203 213 225 / 0.3) !important;
}

.tw-decoration-slate-300\/35 {
  text-decoration-color: rgb(203 213 225 / 0.35) !important;
}

.tw-decoration-slate-300\/40 {
  text-decoration-color: rgb(203 213 225 / 0.4) !important;
}

.tw-decoration-slate-300\/45 {
  text-decoration-color: rgb(203 213 225 / 0.45) !important;
}

.tw-decoration-slate-300\/5 {
  text-decoration-color: rgb(203 213 225 / 0.05) !important;
}

.tw-decoration-slate-300\/50 {
  text-decoration-color: rgb(203 213 225 / 0.5) !important;
}

.tw-decoration-slate-300\/55 {
  text-decoration-color: rgb(203 213 225 / 0.55) !important;
}

.tw-decoration-slate-300\/60 {
  text-decoration-color: rgb(203 213 225 / 0.6) !important;
}

.tw-decoration-slate-300\/65 {
  text-decoration-color: rgb(203 213 225 / 0.65) !important;
}

.tw-decoration-slate-300\/70 {
  text-decoration-color: rgb(203 213 225 / 0.7) !important;
}

.tw-decoration-slate-300\/75 {
  text-decoration-color: rgb(203 213 225 / 0.75) !important;
}

.tw-decoration-slate-300\/80 {
  text-decoration-color: rgb(203 213 225 / 0.8) !important;
}

.tw-decoration-slate-300\/85 {
  text-decoration-color: rgb(203 213 225 / 0.85) !important;
}

.tw-decoration-slate-300\/90 {
  text-decoration-color: rgb(203 213 225 / 0.9) !important;
}

.tw-decoration-slate-300\/95 {
  text-decoration-color: rgb(203 213 225 / 0.95) !important;
}

.tw-decoration-slate-400 {
  text-decoration-color: #94a3b8 !important;
}

.tw-decoration-slate-400\/0 {
  text-decoration-color: rgb(148 163 184 / 0) !important;
}

.tw-decoration-slate-400\/10 {
  text-decoration-color: rgb(148 163 184 / 0.1) !important;
}

.tw-decoration-slate-400\/100 {
  text-decoration-color: rgb(148 163 184 / 1) !important;
}

.tw-decoration-slate-400\/15 {
  text-decoration-color: rgb(148 163 184 / 0.15) !important;
}

.tw-decoration-slate-400\/20 {
  text-decoration-color: rgb(148 163 184 / 0.2) !important;
}

.tw-decoration-slate-400\/25 {
  text-decoration-color: rgb(148 163 184 / 0.25) !important;
}

.tw-decoration-slate-400\/30 {
  text-decoration-color: rgb(148 163 184 / 0.3) !important;
}

.tw-decoration-slate-400\/35 {
  text-decoration-color: rgb(148 163 184 / 0.35) !important;
}

.tw-decoration-slate-400\/40 {
  text-decoration-color: rgb(148 163 184 / 0.4) !important;
}

.tw-decoration-slate-400\/45 {
  text-decoration-color: rgb(148 163 184 / 0.45) !important;
}

.tw-decoration-slate-400\/5 {
  text-decoration-color: rgb(148 163 184 / 0.05) !important;
}

.tw-decoration-slate-400\/50 {
  text-decoration-color: rgb(148 163 184 / 0.5) !important;
}

.tw-decoration-slate-400\/55 {
  text-decoration-color: rgb(148 163 184 / 0.55) !important;
}

.tw-decoration-slate-400\/60 {
  text-decoration-color: rgb(148 163 184 / 0.6) !important;
}

.tw-decoration-slate-400\/65 {
  text-decoration-color: rgb(148 163 184 / 0.65) !important;
}

.tw-decoration-slate-400\/70 {
  text-decoration-color: rgb(148 163 184 / 0.7) !important;
}

.tw-decoration-slate-400\/75 {
  text-decoration-color: rgb(148 163 184 / 0.75) !important;
}

.tw-decoration-slate-400\/80 {
  text-decoration-color: rgb(148 163 184 / 0.8) !important;
}

.tw-decoration-slate-400\/85 {
  text-decoration-color: rgb(148 163 184 / 0.85) !important;
}

.tw-decoration-slate-400\/90 {
  text-decoration-color: rgb(148 163 184 / 0.9) !important;
}

.tw-decoration-slate-400\/95 {
  text-decoration-color: rgb(148 163 184 / 0.95) !important;
}

.tw-decoration-slate-50 {
  text-decoration-color: #f8fafc !important;
}

.tw-decoration-slate-50\/0 {
  text-decoration-color: rgb(248 250 252 / 0) !important;
}

.tw-decoration-slate-50\/10 {
  text-decoration-color: rgb(248 250 252 / 0.1) !important;
}

.tw-decoration-slate-50\/100 {
  text-decoration-color: rgb(248 250 252 / 1) !important;
}

.tw-decoration-slate-50\/15 {
  text-decoration-color: rgb(248 250 252 / 0.15) !important;
}

.tw-decoration-slate-50\/20 {
  text-decoration-color: rgb(248 250 252 / 0.2) !important;
}

.tw-decoration-slate-50\/25 {
  text-decoration-color: rgb(248 250 252 / 0.25) !important;
}

.tw-decoration-slate-50\/30 {
  text-decoration-color: rgb(248 250 252 / 0.3) !important;
}

.tw-decoration-slate-50\/35 {
  text-decoration-color: rgb(248 250 252 / 0.35) !important;
}

.tw-decoration-slate-50\/40 {
  text-decoration-color: rgb(248 250 252 / 0.4) !important;
}

.tw-decoration-slate-50\/45 {
  text-decoration-color: rgb(248 250 252 / 0.45) !important;
}

.tw-decoration-slate-50\/5 {
  text-decoration-color: rgb(248 250 252 / 0.05) !important;
}

.tw-decoration-slate-50\/50 {
  text-decoration-color: rgb(248 250 252 / 0.5) !important;
}

.tw-decoration-slate-50\/55 {
  text-decoration-color: rgb(248 250 252 / 0.55) !important;
}

.tw-decoration-slate-50\/60 {
  text-decoration-color: rgb(248 250 252 / 0.6) !important;
}

.tw-decoration-slate-50\/65 {
  text-decoration-color: rgb(248 250 252 / 0.65) !important;
}

.tw-decoration-slate-50\/70 {
  text-decoration-color: rgb(248 250 252 / 0.7) !important;
}

.tw-decoration-slate-50\/75 {
  text-decoration-color: rgb(248 250 252 / 0.75) !important;
}

.tw-decoration-slate-50\/80 {
  text-decoration-color: rgb(248 250 252 / 0.8) !important;
}

.tw-decoration-slate-50\/85 {
  text-decoration-color: rgb(248 250 252 / 0.85) !important;
}

.tw-decoration-slate-50\/90 {
  text-decoration-color: rgb(248 250 252 / 0.9) !important;
}

.tw-decoration-slate-50\/95 {
  text-decoration-color: rgb(248 250 252 / 0.95) !important;
}

.tw-decoration-slate-500 {
  text-decoration-color: #64748b !important;
}

.tw-decoration-slate-500\/0 {
  text-decoration-color: rgb(100 116 139 / 0) !important;
}

.tw-decoration-slate-500\/10 {
  text-decoration-color: rgb(100 116 139 / 0.1) !important;
}

.tw-decoration-slate-500\/100 {
  text-decoration-color: rgb(100 116 139 / 1) !important;
}

.tw-decoration-slate-500\/15 {
  text-decoration-color: rgb(100 116 139 / 0.15) !important;
}

.tw-decoration-slate-500\/20 {
  text-decoration-color: rgb(100 116 139 / 0.2) !important;
}

.tw-decoration-slate-500\/25 {
  text-decoration-color: rgb(100 116 139 / 0.25) !important;
}

.tw-decoration-slate-500\/30 {
  text-decoration-color: rgb(100 116 139 / 0.3) !important;
}

.tw-decoration-slate-500\/35 {
  text-decoration-color: rgb(100 116 139 / 0.35) !important;
}

.tw-decoration-slate-500\/40 {
  text-decoration-color: rgb(100 116 139 / 0.4) !important;
}

.tw-decoration-slate-500\/45 {
  text-decoration-color: rgb(100 116 139 / 0.45) !important;
}

.tw-decoration-slate-500\/5 {
  text-decoration-color: rgb(100 116 139 / 0.05) !important;
}

.tw-decoration-slate-500\/50 {
  text-decoration-color: rgb(100 116 139 / 0.5) !important;
}

.tw-decoration-slate-500\/55 {
  text-decoration-color: rgb(100 116 139 / 0.55) !important;
}

.tw-decoration-slate-500\/60 {
  text-decoration-color: rgb(100 116 139 / 0.6) !important;
}

.tw-decoration-slate-500\/65 {
  text-decoration-color: rgb(100 116 139 / 0.65) !important;
}

.tw-decoration-slate-500\/70 {
  text-decoration-color: rgb(100 116 139 / 0.7) !important;
}

.tw-decoration-slate-500\/75 {
  text-decoration-color: rgb(100 116 139 / 0.75) !important;
}

.tw-decoration-slate-500\/80 {
  text-decoration-color: rgb(100 116 139 / 0.8) !important;
}

.tw-decoration-slate-500\/85 {
  text-decoration-color: rgb(100 116 139 / 0.85) !important;
}

.tw-decoration-slate-500\/90 {
  text-decoration-color: rgb(100 116 139 / 0.9) !important;
}

.tw-decoration-slate-500\/95 {
  text-decoration-color: rgb(100 116 139 / 0.95) !important;
}

.tw-decoration-slate-600 {
  text-decoration-color: #475569 !important;
}

.tw-decoration-slate-600\/0 {
  text-decoration-color: rgb(71 85 105 / 0) !important;
}

.tw-decoration-slate-600\/10 {
  text-decoration-color: rgb(71 85 105 / 0.1) !important;
}

.tw-decoration-slate-600\/100 {
  text-decoration-color: rgb(71 85 105 / 1) !important;
}

.tw-decoration-slate-600\/15 {
  text-decoration-color: rgb(71 85 105 / 0.15) !important;
}

.tw-decoration-slate-600\/20 {
  text-decoration-color: rgb(71 85 105 / 0.2) !important;
}

.tw-decoration-slate-600\/25 {
  text-decoration-color: rgb(71 85 105 / 0.25) !important;
}

.tw-decoration-slate-600\/30 {
  text-decoration-color: rgb(71 85 105 / 0.3) !important;
}

.tw-decoration-slate-600\/35 {
  text-decoration-color: rgb(71 85 105 / 0.35) !important;
}

.tw-decoration-slate-600\/40 {
  text-decoration-color: rgb(71 85 105 / 0.4) !important;
}

.tw-decoration-slate-600\/45 {
  text-decoration-color: rgb(71 85 105 / 0.45) !important;
}

.tw-decoration-slate-600\/5 {
  text-decoration-color: rgb(71 85 105 / 0.05) !important;
}

.tw-decoration-slate-600\/50 {
  text-decoration-color: rgb(71 85 105 / 0.5) !important;
}

.tw-decoration-slate-600\/55 {
  text-decoration-color: rgb(71 85 105 / 0.55) !important;
}

.tw-decoration-slate-600\/60 {
  text-decoration-color: rgb(71 85 105 / 0.6) !important;
}

.tw-decoration-slate-600\/65 {
  text-decoration-color: rgb(71 85 105 / 0.65) !important;
}

.tw-decoration-slate-600\/70 {
  text-decoration-color: rgb(71 85 105 / 0.7) !important;
}

.tw-decoration-slate-600\/75 {
  text-decoration-color: rgb(71 85 105 / 0.75) !important;
}

.tw-decoration-slate-600\/80 {
  text-decoration-color: rgb(71 85 105 / 0.8) !important;
}

.tw-decoration-slate-600\/85 {
  text-decoration-color: rgb(71 85 105 / 0.85) !important;
}

.tw-decoration-slate-600\/90 {
  text-decoration-color: rgb(71 85 105 / 0.9) !important;
}

.tw-decoration-slate-600\/95 {
  text-decoration-color: rgb(71 85 105 / 0.95) !important;
}

.tw-decoration-slate-700 {
  text-decoration-color: #334155 !important;
}

.tw-decoration-slate-700\/0 {
  text-decoration-color: rgb(51 65 85 / 0) !important;
}

.tw-decoration-slate-700\/10 {
  text-decoration-color: rgb(51 65 85 / 0.1) !important;
}

.tw-decoration-slate-700\/100 {
  text-decoration-color: rgb(51 65 85 / 1) !important;
}

.tw-decoration-slate-700\/15 {
  text-decoration-color: rgb(51 65 85 / 0.15) !important;
}

.tw-decoration-slate-700\/20 {
  text-decoration-color: rgb(51 65 85 / 0.2) !important;
}

.tw-decoration-slate-700\/25 {
  text-decoration-color: rgb(51 65 85 / 0.25) !important;
}

.tw-decoration-slate-700\/30 {
  text-decoration-color: rgb(51 65 85 / 0.3) !important;
}

.tw-decoration-slate-700\/35 {
  text-decoration-color: rgb(51 65 85 / 0.35) !important;
}

.tw-decoration-slate-700\/40 {
  text-decoration-color: rgb(51 65 85 / 0.4) !important;
}

.tw-decoration-slate-700\/45 {
  text-decoration-color: rgb(51 65 85 / 0.45) !important;
}

.tw-decoration-slate-700\/5 {
  text-decoration-color: rgb(51 65 85 / 0.05) !important;
}

.tw-decoration-slate-700\/50 {
  text-decoration-color: rgb(51 65 85 / 0.5) !important;
}

.tw-decoration-slate-700\/55 {
  text-decoration-color: rgb(51 65 85 / 0.55) !important;
}

.tw-decoration-slate-700\/60 {
  text-decoration-color: rgb(51 65 85 / 0.6) !important;
}

.tw-decoration-slate-700\/65 {
  text-decoration-color: rgb(51 65 85 / 0.65) !important;
}

.tw-decoration-slate-700\/70 {
  text-decoration-color: rgb(51 65 85 / 0.7) !important;
}

.tw-decoration-slate-700\/75 {
  text-decoration-color: rgb(51 65 85 / 0.75) !important;
}

.tw-decoration-slate-700\/80 {
  text-decoration-color: rgb(51 65 85 / 0.8) !important;
}

.tw-decoration-slate-700\/85 {
  text-decoration-color: rgb(51 65 85 / 0.85) !important;
}

.tw-decoration-slate-700\/90 {
  text-decoration-color: rgb(51 65 85 / 0.9) !important;
}

.tw-decoration-slate-700\/95 {
  text-decoration-color: rgb(51 65 85 / 0.95) !important;
}

.tw-decoration-slate-800 {
  text-decoration-color: #1e293b !important;
}

.tw-decoration-slate-800\/0 {
  text-decoration-color: rgb(30 41 59 / 0) !important;
}

.tw-decoration-slate-800\/10 {
  text-decoration-color: rgb(30 41 59 / 0.1) !important;
}

.tw-decoration-slate-800\/100 {
  text-decoration-color: rgb(30 41 59 / 1) !important;
}

.tw-decoration-slate-800\/15 {
  text-decoration-color: rgb(30 41 59 / 0.15) !important;
}

.tw-decoration-slate-800\/20 {
  text-decoration-color: rgb(30 41 59 / 0.2) !important;
}

.tw-decoration-slate-800\/25 {
  text-decoration-color: rgb(30 41 59 / 0.25) !important;
}

.tw-decoration-slate-800\/30 {
  text-decoration-color: rgb(30 41 59 / 0.3) !important;
}

.tw-decoration-slate-800\/35 {
  text-decoration-color: rgb(30 41 59 / 0.35) !important;
}

.tw-decoration-slate-800\/40 {
  text-decoration-color: rgb(30 41 59 / 0.4) !important;
}

.tw-decoration-slate-800\/45 {
  text-decoration-color: rgb(30 41 59 / 0.45) !important;
}

.tw-decoration-slate-800\/5 {
  text-decoration-color: rgb(30 41 59 / 0.05) !important;
}

.tw-decoration-slate-800\/50 {
  text-decoration-color: rgb(30 41 59 / 0.5) !important;
}

.tw-decoration-slate-800\/55 {
  text-decoration-color: rgb(30 41 59 / 0.55) !important;
}

.tw-decoration-slate-800\/60 {
  text-decoration-color: rgb(30 41 59 / 0.6) !important;
}

.tw-decoration-slate-800\/65 {
  text-decoration-color: rgb(30 41 59 / 0.65) !important;
}

.tw-decoration-slate-800\/70 {
  text-decoration-color: rgb(30 41 59 / 0.7) !important;
}

.tw-decoration-slate-800\/75 {
  text-decoration-color: rgb(30 41 59 / 0.75) !important;
}

.tw-decoration-slate-800\/80 {
  text-decoration-color: rgb(30 41 59 / 0.8) !important;
}

.tw-decoration-slate-800\/85 {
  text-decoration-color: rgb(30 41 59 / 0.85) !important;
}

.tw-decoration-slate-800\/90 {
  text-decoration-color: rgb(30 41 59 / 0.9) !important;
}

.tw-decoration-slate-800\/95 {
  text-decoration-color: rgb(30 41 59 / 0.95) !important;
}

.tw-decoration-slate-900 {
  text-decoration-color: #0f172a !important;
}

.tw-decoration-slate-900\/0 {
  text-decoration-color: rgb(15 23 42 / 0) !important;
}

.tw-decoration-slate-900\/10 {
  text-decoration-color: rgb(15 23 42 / 0.1) !important;
}

.tw-decoration-slate-900\/100 {
  text-decoration-color: rgb(15 23 42 / 1) !important;
}

.tw-decoration-slate-900\/15 {
  text-decoration-color: rgb(15 23 42 / 0.15) !important;
}

.tw-decoration-slate-900\/20 {
  text-decoration-color: rgb(15 23 42 / 0.2) !important;
}

.tw-decoration-slate-900\/25 {
  text-decoration-color: rgb(15 23 42 / 0.25) !important;
}

.tw-decoration-slate-900\/30 {
  text-decoration-color: rgb(15 23 42 / 0.3) !important;
}

.tw-decoration-slate-900\/35 {
  text-decoration-color: rgb(15 23 42 / 0.35) !important;
}

.tw-decoration-slate-900\/40 {
  text-decoration-color: rgb(15 23 42 / 0.4) !important;
}

.tw-decoration-slate-900\/45 {
  text-decoration-color: rgb(15 23 42 / 0.45) !important;
}

.tw-decoration-slate-900\/5 {
  text-decoration-color: rgb(15 23 42 / 0.05) !important;
}

.tw-decoration-slate-900\/50 {
  text-decoration-color: rgb(15 23 42 / 0.5) !important;
}

.tw-decoration-slate-900\/55 {
  text-decoration-color: rgb(15 23 42 / 0.55) !important;
}

.tw-decoration-slate-900\/60 {
  text-decoration-color: rgb(15 23 42 / 0.6) !important;
}

.tw-decoration-slate-900\/65 {
  text-decoration-color: rgb(15 23 42 / 0.65) !important;
}

.tw-decoration-slate-900\/70 {
  text-decoration-color: rgb(15 23 42 / 0.7) !important;
}

.tw-decoration-slate-900\/75 {
  text-decoration-color: rgb(15 23 42 / 0.75) !important;
}

.tw-decoration-slate-900\/80 {
  text-decoration-color: rgb(15 23 42 / 0.8) !important;
}

.tw-decoration-slate-900\/85 {
  text-decoration-color: rgb(15 23 42 / 0.85) !important;
}

.tw-decoration-slate-900\/90 {
  text-decoration-color: rgb(15 23 42 / 0.9) !important;
}

.tw-decoration-slate-900\/95 {
  text-decoration-color: rgb(15 23 42 / 0.95) !important;
}

.tw-decoration-slate-950 {
  text-decoration-color: #020617 !important;
}

.tw-decoration-slate-950\/0 {
  text-decoration-color: rgb(2 6 23 / 0) !important;
}

.tw-decoration-slate-950\/10 {
  text-decoration-color: rgb(2 6 23 / 0.1) !important;
}

.tw-decoration-slate-950\/100 {
  text-decoration-color: rgb(2 6 23 / 1) !important;
}

.tw-decoration-slate-950\/15 {
  text-decoration-color: rgb(2 6 23 / 0.15) !important;
}

.tw-decoration-slate-950\/20 {
  text-decoration-color: rgb(2 6 23 / 0.2) !important;
}

.tw-decoration-slate-950\/25 {
  text-decoration-color: rgb(2 6 23 / 0.25) !important;
}

.tw-decoration-slate-950\/30 {
  text-decoration-color: rgb(2 6 23 / 0.3) !important;
}

.tw-decoration-slate-950\/35 {
  text-decoration-color: rgb(2 6 23 / 0.35) !important;
}

.tw-decoration-slate-950\/40 {
  text-decoration-color: rgb(2 6 23 / 0.4) !important;
}

.tw-decoration-slate-950\/45 {
  text-decoration-color: rgb(2 6 23 / 0.45) !important;
}

.tw-decoration-slate-950\/5 {
  text-decoration-color: rgb(2 6 23 / 0.05) !important;
}

.tw-decoration-slate-950\/50 {
  text-decoration-color: rgb(2 6 23 / 0.5) !important;
}

.tw-decoration-slate-950\/55 {
  text-decoration-color: rgb(2 6 23 / 0.55) !important;
}

.tw-decoration-slate-950\/60 {
  text-decoration-color: rgb(2 6 23 / 0.6) !important;
}

.tw-decoration-slate-950\/65 {
  text-decoration-color: rgb(2 6 23 / 0.65) !important;
}

.tw-decoration-slate-950\/70 {
  text-decoration-color: rgb(2 6 23 / 0.7) !important;
}

.tw-decoration-slate-950\/75 {
  text-decoration-color: rgb(2 6 23 / 0.75) !important;
}

.tw-decoration-slate-950\/80 {
  text-decoration-color: rgb(2 6 23 / 0.8) !important;
}

.tw-decoration-slate-950\/85 {
  text-decoration-color: rgb(2 6 23 / 0.85) !important;
}

.tw-decoration-slate-950\/90 {
  text-decoration-color: rgb(2 6 23 / 0.9) !important;
}

.tw-decoration-slate-950\/95 {
  text-decoration-color: rgb(2 6 23 / 0.95) !important;
}

.tw-decoration-stone-100 {
  text-decoration-color: #f5f5f4 !important;
}

.tw-decoration-stone-100\/0 {
  text-decoration-color: rgb(245 245 244 / 0) !important;
}

.tw-decoration-stone-100\/10 {
  text-decoration-color: rgb(245 245 244 / 0.1) !important;
}

.tw-decoration-stone-100\/100 {
  text-decoration-color: rgb(245 245 244 / 1) !important;
}

.tw-decoration-stone-100\/15 {
  text-decoration-color: rgb(245 245 244 / 0.15) !important;
}

.tw-decoration-stone-100\/20 {
  text-decoration-color: rgb(245 245 244 / 0.2) !important;
}

.tw-decoration-stone-100\/25 {
  text-decoration-color: rgb(245 245 244 / 0.25) !important;
}

.tw-decoration-stone-100\/30 {
  text-decoration-color: rgb(245 245 244 / 0.3) !important;
}

.tw-decoration-stone-100\/35 {
  text-decoration-color: rgb(245 245 244 / 0.35) !important;
}

.tw-decoration-stone-100\/40 {
  text-decoration-color: rgb(245 245 244 / 0.4) !important;
}

.tw-decoration-stone-100\/45 {
  text-decoration-color: rgb(245 245 244 / 0.45) !important;
}

.tw-decoration-stone-100\/5 {
  text-decoration-color: rgb(245 245 244 / 0.05) !important;
}

.tw-decoration-stone-100\/50 {
  text-decoration-color: rgb(245 245 244 / 0.5) !important;
}

.tw-decoration-stone-100\/55 {
  text-decoration-color: rgb(245 245 244 / 0.55) !important;
}

.tw-decoration-stone-100\/60 {
  text-decoration-color: rgb(245 245 244 / 0.6) !important;
}

.tw-decoration-stone-100\/65 {
  text-decoration-color: rgb(245 245 244 / 0.65) !important;
}

.tw-decoration-stone-100\/70 {
  text-decoration-color: rgb(245 245 244 / 0.7) !important;
}

.tw-decoration-stone-100\/75 {
  text-decoration-color: rgb(245 245 244 / 0.75) !important;
}

.tw-decoration-stone-100\/80 {
  text-decoration-color: rgb(245 245 244 / 0.8) !important;
}

.tw-decoration-stone-100\/85 {
  text-decoration-color: rgb(245 245 244 / 0.85) !important;
}

.tw-decoration-stone-100\/90 {
  text-decoration-color: rgb(245 245 244 / 0.9) !important;
}

.tw-decoration-stone-100\/95 {
  text-decoration-color: rgb(245 245 244 / 0.95) !important;
}

.tw-decoration-stone-200 {
  text-decoration-color: #e7e5e4 !important;
}

.tw-decoration-stone-200\/0 {
  text-decoration-color: rgb(231 229 228 / 0) !important;
}

.tw-decoration-stone-200\/10 {
  text-decoration-color: rgb(231 229 228 / 0.1) !important;
}

.tw-decoration-stone-200\/100 {
  text-decoration-color: rgb(231 229 228 / 1) !important;
}

.tw-decoration-stone-200\/15 {
  text-decoration-color: rgb(231 229 228 / 0.15) !important;
}

.tw-decoration-stone-200\/20 {
  text-decoration-color: rgb(231 229 228 / 0.2) !important;
}

.tw-decoration-stone-200\/25 {
  text-decoration-color: rgb(231 229 228 / 0.25) !important;
}

.tw-decoration-stone-200\/30 {
  text-decoration-color: rgb(231 229 228 / 0.3) !important;
}

.tw-decoration-stone-200\/35 {
  text-decoration-color: rgb(231 229 228 / 0.35) !important;
}

.tw-decoration-stone-200\/40 {
  text-decoration-color: rgb(231 229 228 / 0.4) !important;
}

.tw-decoration-stone-200\/45 {
  text-decoration-color: rgb(231 229 228 / 0.45) !important;
}

.tw-decoration-stone-200\/5 {
  text-decoration-color: rgb(231 229 228 / 0.05) !important;
}

.tw-decoration-stone-200\/50 {
  text-decoration-color: rgb(231 229 228 / 0.5) !important;
}

.tw-decoration-stone-200\/55 {
  text-decoration-color: rgb(231 229 228 / 0.55) !important;
}

.tw-decoration-stone-200\/60 {
  text-decoration-color: rgb(231 229 228 / 0.6) !important;
}

.tw-decoration-stone-200\/65 {
  text-decoration-color: rgb(231 229 228 / 0.65) !important;
}

.tw-decoration-stone-200\/70 {
  text-decoration-color: rgb(231 229 228 / 0.7) !important;
}

.tw-decoration-stone-200\/75 {
  text-decoration-color: rgb(231 229 228 / 0.75) !important;
}

.tw-decoration-stone-200\/80 {
  text-decoration-color: rgb(231 229 228 / 0.8) !important;
}

.tw-decoration-stone-200\/85 {
  text-decoration-color: rgb(231 229 228 / 0.85) !important;
}

.tw-decoration-stone-200\/90 {
  text-decoration-color: rgb(231 229 228 / 0.9) !important;
}

.tw-decoration-stone-200\/95 {
  text-decoration-color: rgb(231 229 228 / 0.95) !important;
}

.tw-decoration-stone-300 {
  text-decoration-color: #d6d3d1 !important;
}

.tw-decoration-stone-300\/0 {
  text-decoration-color: rgb(214 211 209 / 0) !important;
}

.tw-decoration-stone-300\/10 {
  text-decoration-color: rgb(214 211 209 / 0.1) !important;
}

.tw-decoration-stone-300\/100 {
  text-decoration-color: rgb(214 211 209 / 1) !important;
}

.tw-decoration-stone-300\/15 {
  text-decoration-color: rgb(214 211 209 / 0.15) !important;
}

.tw-decoration-stone-300\/20 {
  text-decoration-color: rgb(214 211 209 / 0.2) !important;
}

.tw-decoration-stone-300\/25 {
  text-decoration-color: rgb(214 211 209 / 0.25) !important;
}

.tw-decoration-stone-300\/30 {
  text-decoration-color: rgb(214 211 209 / 0.3) !important;
}

.tw-decoration-stone-300\/35 {
  text-decoration-color: rgb(214 211 209 / 0.35) !important;
}

.tw-decoration-stone-300\/40 {
  text-decoration-color: rgb(214 211 209 / 0.4) !important;
}

.tw-decoration-stone-300\/45 {
  text-decoration-color: rgb(214 211 209 / 0.45) !important;
}

.tw-decoration-stone-300\/5 {
  text-decoration-color: rgb(214 211 209 / 0.05) !important;
}

.tw-decoration-stone-300\/50 {
  text-decoration-color: rgb(214 211 209 / 0.5) !important;
}

.tw-decoration-stone-300\/55 {
  text-decoration-color: rgb(214 211 209 / 0.55) !important;
}

.tw-decoration-stone-300\/60 {
  text-decoration-color: rgb(214 211 209 / 0.6) !important;
}

.tw-decoration-stone-300\/65 {
  text-decoration-color: rgb(214 211 209 / 0.65) !important;
}

.tw-decoration-stone-300\/70 {
  text-decoration-color: rgb(214 211 209 / 0.7) !important;
}

.tw-decoration-stone-300\/75 {
  text-decoration-color: rgb(214 211 209 / 0.75) !important;
}

.tw-decoration-stone-300\/80 {
  text-decoration-color: rgb(214 211 209 / 0.8) !important;
}

.tw-decoration-stone-300\/85 {
  text-decoration-color: rgb(214 211 209 / 0.85) !important;
}

.tw-decoration-stone-300\/90 {
  text-decoration-color: rgb(214 211 209 / 0.9) !important;
}

.tw-decoration-stone-300\/95 {
  text-decoration-color: rgb(214 211 209 / 0.95) !important;
}

.tw-decoration-stone-400 {
  text-decoration-color: #a8a29e !important;
}

.tw-decoration-stone-400\/0 {
  text-decoration-color: rgb(168 162 158 / 0) !important;
}

.tw-decoration-stone-400\/10 {
  text-decoration-color: rgb(168 162 158 / 0.1) !important;
}

.tw-decoration-stone-400\/100 {
  text-decoration-color: rgb(168 162 158 / 1) !important;
}

.tw-decoration-stone-400\/15 {
  text-decoration-color: rgb(168 162 158 / 0.15) !important;
}

.tw-decoration-stone-400\/20 {
  text-decoration-color: rgb(168 162 158 / 0.2) !important;
}

.tw-decoration-stone-400\/25 {
  text-decoration-color: rgb(168 162 158 / 0.25) !important;
}

.tw-decoration-stone-400\/30 {
  text-decoration-color: rgb(168 162 158 / 0.3) !important;
}

.tw-decoration-stone-400\/35 {
  text-decoration-color: rgb(168 162 158 / 0.35) !important;
}

.tw-decoration-stone-400\/40 {
  text-decoration-color: rgb(168 162 158 / 0.4) !important;
}

.tw-decoration-stone-400\/45 {
  text-decoration-color: rgb(168 162 158 / 0.45) !important;
}

.tw-decoration-stone-400\/5 {
  text-decoration-color: rgb(168 162 158 / 0.05) !important;
}

.tw-decoration-stone-400\/50 {
  text-decoration-color: rgb(168 162 158 / 0.5) !important;
}

.tw-decoration-stone-400\/55 {
  text-decoration-color: rgb(168 162 158 / 0.55) !important;
}

.tw-decoration-stone-400\/60 {
  text-decoration-color: rgb(168 162 158 / 0.6) !important;
}

.tw-decoration-stone-400\/65 {
  text-decoration-color: rgb(168 162 158 / 0.65) !important;
}

.tw-decoration-stone-400\/70 {
  text-decoration-color: rgb(168 162 158 / 0.7) !important;
}

.tw-decoration-stone-400\/75 {
  text-decoration-color: rgb(168 162 158 / 0.75) !important;
}

.tw-decoration-stone-400\/80 {
  text-decoration-color: rgb(168 162 158 / 0.8) !important;
}

.tw-decoration-stone-400\/85 {
  text-decoration-color: rgb(168 162 158 / 0.85) !important;
}

.tw-decoration-stone-400\/90 {
  text-decoration-color: rgb(168 162 158 / 0.9) !important;
}

.tw-decoration-stone-400\/95 {
  text-decoration-color: rgb(168 162 158 / 0.95) !important;
}

.tw-decoration-stone-50 {
  text-decoration-color: #fafaf9 !important;
}

.tw-decoration-stone-50\/0 {
  text-decoration-color: rgb(250 250 249 / 0) !important;
}

.tw-decoration-stone-50\/10 {
  text-decoration-color: rgb(250 250 249 / 0.1) !important;
}

.tw-decoration-stone-50\/100 {
  text-decoration-color: rgb(250 250 249 / 1) !important;
}

.tw-decoration-stone-50\/15 {
  text-decoration-color: rgb(250 250 249 / 0.15) !important;
}

.tw-decoration-stone-50\/20 {
  text-decoration-color: rgb(250 250 249 / 0.2) !important;
}

.tw-decoration-stone-50\/25 {
  text-decoration-color: rgb(250 250 249 / 0.25) !important;
}

.tw-decoration-stone-50\/30 {
  text-decoration-color: rgb(250 250 249 / 0.3) !important;
}

.tw-decoration-stone-50\/35 {
  text-decoration-color: rgb(250 250 249 / 0.35) !important;
}

.tw-decoration-stone-50\/40 {
  text-decoration-color: rgb(250 250 249 / 0.4) !important;
}

.tw-decoration-stone-50\/45 {
  text-decoration-color: rgb(250 250 249 / 0.45) !important;
}

.tw-decoration-stone-50\/5 {
  text-decoration-color: rgb(250 250 249 / 0.05) !important;
}

.tw-decoration-stone-50\/50 {
  text-decoration-color: rgb(250 250 249 / 0.5) !important;
}

.tw-decoration-stone-50\/55 {
  text-decoration-color: rgb(250 250 249 / 0.55) !important;
}

.tw-decoration-stone-50\/60 {
  text-decoration-color: rgb(250 250 249 / 0.6) !important;
}

.tw-decoration-stone-50\/65 {
  text-decoration-color: rgb(250 250 249 / 0.65) !important;
}

.tw-decoration-stone-50\/70 {
  text-decoration-color: rgb(250 250 249 / 0.7) !important;
}

.tw-decoration-stone-50\/75 {
  text-decoration-color: rgb(250 250 249 / 0.75) !important;
}

.tw-decoration-stone-50\/80 {
  text-decoration-color: rgb(250 250 249 / 0.8) !important;
}

.tw-decoration-stone-50\/85 {
  text-decoration-color: rgb(250 250 249 / 0.85) !important;
}

.tw-decoration-stone-50\/90 {
  text-decoration-color: rgb(250 250 249 / 0.9) !important;
}

.tw-decoration-stone-50\/95 {
  text-decoration-color: rgb(250 250 249 / 0.95) !important;
}

.tw-decoration-stone-500 {
  text-decoration-color: #78716c !important;
}

.tw-decoration-stone-500\/0 {
  text-decoration-color: rgb(120 113 108 / 0) !important;
}

.tw-decoration-stone-500\/10 {
  text-decoration-color: rgb(120 113 108 / 0.1) !important;
}

.tw-decoration-stone-500\/100 {
  text-decoration-color: rgb(120 113 108 / 1) !important;
}

.tw-decoration-stone-500\/15 {
  text-decoration-color: rgb(120 113 108 / 0.15) !important;
}

.tw-decoration-stone-500\/20 {
  text-decoration-color: rgb(120 113 108 / 0.2) !important;
}

.tw-decoration-stone-500\/25 {
  text-decoration-color: rgb(120 113 108 / 0.25) !important;
}

.tw-decoration-stone-500\/30 {
  text-decoration-color: rgb(120 113 108 / 0.3) !important;
}

.tw-decoration-stone-500\/35 {
  text-decoration-color: rgb(120 113 108 / 0.35) !important;
}

.tw-decoration-stone-500\/40 {
  text-decoration-color: rgb(120 113 108 / 0.4) !important;
}

.tw-decoration-stone-500\/45 {
  text-decoration-color: rgb(120 113 108 / 0.45) !important;
}

.tw-decoration-stone-500\/5 {
  text-decoration-color: rgb(120 113 108 / 0.05) !important;
}

.tw-decoration-stone-500\/50 {
  text-decoration-color: rgb(120 113 108 / 0.5) !important;
}

.tw-decoration-stone-500\/55 {
  text-decoration-color: rgb(120 113 108 / 0.55) !important;
}

.tw-decoration-stone-500\/60 {
  text-decoration-color: rgb(120 113 108 / 0.6) !important;
}

.tw-decoration-stone-500\/65 {
  text-decoration-color: rgb(120 113 108 / 0.65) !important;
}

.tw-decoration-stone-500\/70 {
  text-decoration-color: rgb(120 113 108 / 0.7) !important;
}

.tw-decoration-stone-500\/75 {
  text-decoration-color: rgb(120 113 108 / 0.75) !important;
}

.tw-decoration-stone-500\/80 {
  text-decoration-color: rgb(120 113 108 / 0.8) !important;
}

.tw-decoration-stone-500\/85 {
  text-decoration-color: rgb(120 113 108 / 0.85) !important;
}

.tw-decoration-stone-500\/90 {
  text-decoration-color: rgb(120 113 108 / 0.9) !important;
}

.tw-decoration-stone-500\/95 {
  text-decoration-color: rgb(120 113 108 / 0.95) !important;
}

.tw-decoration-stone-600 {
  text-decoration-color: #57534e !important;
}

.tw-decoration-stone-600\/0 {
  text-decoration-color: rgb(87 83 78 / 0) !important;
}

.tw-decoration-stone-600\/10 {
  text-decoration-color: rgb(87 83 78 / 0.1) !important;
}

.tw-decoration-stone-600\/100 {
  text-decoration-color: rgb(87 83 78 / 1) !important;
}

.tw-decoration-stone-600\/15 {
  text-decoration-color: rgb(87 83 78 / 0.15) !important;
}

.tw-decoration-stone-600\/20 {
  text-decoration-color: rgb(87 83 78 / 0.2) !important;
}

.tw-decoration-stone-600\/25 {
  text-decoration-color: rgb(87 83 78 / 0.25) !important;
}

.tw-decoration-stone-600\/30 {
  text-decoration-color: rgb(87 83 78 / 0.3) !important;
}

.tw-decoration-stone-600\/35 {
  text-decoration-color: rgb(87 83 78 / 0.35) !important;
}

.tw-decoration-stone-600\/40 {
  text-decoration-color: rgb(87 83 78 / 0.4) !important;
}

.tw-decoration-stone-600\/45 {
  text-decoration-color: rgb(87 83 78 / 0.45) !important;
}

.tw-decoration-stone-600\/5 {
  text-decoration-color: rgb(87 83 78 / 0.05) !important;
}

.tw-decoration-stone-600\/50 {
  text-decoration-color: rgb(87 83 78 / 0.5) !important;
}

.tw-decoration-stone-600\/55 {
  text-decoration-color: rgb(87 83 78 / 0.55) !important;
}

.tw-decoration-stone-600\/60 {
  text-decoration-color: rgb(87 83 78 / 0.6) !important;
}

.tw-decoration-stone-600\/65 {
  text-decoration-color: rgb(87 83 78 / 0.65) !important;
}

.tw-decoration-stone-600\/70 {
  text-decoration-color: rgb(87 83 78 / 0.7) !important;
}

.tw-decoration-stone-600\/75 {
  text-decoration-color: rgb(87 83 78 / 0.75) !important;
}

.tw-decoration-stone-600\/80 {
  text-decoration-color: rgb(87 83 78 / 0.8) !important;
}

.tw-decoration-stone-600\/85 {
  text-decoration-color: rgb(87 83 78 / 0.85) !important;
}

.tw-decoration-stone-600\/90 {
  text-decoration-color: rgb(87 83 78 / 0.9) !important;
}

.tw-decoration-stone-600\/95 {
  text-decoration-color: rgb(87 83 78 / 0.95) !important;
}

.tw-decoration-stone-700 {
  text-decoration-color: #44403c !important;
}

.tw-decoration-stone-700\/0 {
  text-decoration-color: rgb(68 64 60 / 0) !important;
}

.tw-decoration-stone-700\/10 {
  text-decoration-color: rgb(68 64 60 / 0.1) !important;
}

.tw-decoration-stone-700\/100 {
  text-decoration-color: rgb(68 64 60 / 1) !important;
}

.tw-decoration-stone-700\/15 {
  text-decoration-color: rgb(68 64 60 / 0.15) !important;
}

.tw-decoration-stone-700\/20 {
  text-decoration-color: rgb(68 64 60 / 0.2) !important;
}

.tw-decoration-stone-700\/25 {
  text-decoration-color: rgb(68 64 60 / 0.25) !important;
}

.tw-decoration-stone-700\/30 {
  text-decoration-color: rgb(68 64 60 / 0.3) !important;
}

.tw-decoration-stone-700\/35 {
  text-decoration-color: rgb(68 64 60 / 0.35) !important;
}

.tw-decoration-stone-700\/40 {
  text-decoration-color: rgb(68 64 60 / 0.4) !important;
}

.tw-decoration-stone-700\/45 {
  text-decoration-color: rgb(68 64 60 / 0.45) !important;
}

.tw-decoration-stone-700\/5 {
  text-decoration-color: rgb(68 64 60 / 0.05) !important;
}

.tw-decoration-stone-700\/50 {
  text-decoration-color: rgb(68 64 60 / 0.5) !important;
}

.tw-decoration-stone-700\/55 {
  text-decoration-color: rgb(68 64 60 / 0.55) !important;
}

.tw-decoration-stone-700\/60 {
  text-decoration-color: rgb(68 64 60 / 0.6) !important;
}

.tw-decoration-stone-700\/65 {
  text-decoration-color: rgb(68 64 60 / 0.65) !important;
}

.tw-decoration-stone-700\/70 {
  text-decoration-color: rgb(68 64 60 / 0.7) !important;
}

.tw-decoration-stone-700\/75 {
  text-decoration-color: rgb(68 64 60 / 0.75) !important;
}

.tw-decoration-stone-700\/80 {
  text-decoration-color: rgb(68 64 60 / 0.8) !important;
}

.tw-decoration-stone-700\/85 {
  text-decoration-color: rgb(68 64 60 / 0.85) !important;
}

.tw-decoration-stone-700\/90 {
  text-decoration-color: rgb(68 64 60 / 0.9) !important;
}

.tw-decoration-stone-700\/95 {
  text-decoration-color: rgb(68 64 60 / 0.95) !important;
}

.tw-decoration-stone-800 {
  text-decoration-color: #292524 !important;
}

.tw-decoration-stone-800\/0 {
  text-decoration-color: rgb(41 37 36 / 0) !important;
}

.tw-decoration-stone-800\/10 {
  text-decoration-color: rgb(41 37 36 / 0.1) !important;
}

.tw-decoration-stone-800\/100 {
  text-decoration-color: rgb(41 37 36 / 1) !important;
}

.tw-decoration-stone-800\/15 {
  text-decoration-color: rgb(41 37 36 / 0.15) !important;
}

.tw-decoration-stone-800\/20 {
  text-decoration-color: rgb(41 37 36 / 0.2) !important;
}

.tw-decoration-stone-800\/25 {
  text-decoration-color: rgb(41 37 36 / 0.25) !important;
}

.tw-decoration-stone-800\/30 {
  text-decoration-color: rgb(41 37 36 / 0.3) !important;
}

.tw-decoration-stone-800\/35 {
  text-decoration-color: rgb(41 37 36 / 0.35) !important;
}

.tw-decoration-stone-800\/40 {
  text-decoration-color: rgb(41 37 36 / 0.4) !important;
}

.tw-decoration-stone-800\/45 {
  text-decoration-color: rgb(41 37 36 / 0.45) !important;
}

.tw-decoration-stone-800\/5 {
  text-decoration-color: rgb(41 37 36 / 0.05) !important;
}

.tw-decoration-stone-800\/50 {
  text-decoration-color: rgb(41 37 36 / 0.5) !important;
}

.tw-decoration-stone-800\/55 {
  text-decoration-color: rgb(41 37 36 / 0.55) !important;
}

.tw-decoration-stone-800\/60 {
  text-decoration-color: rgb(41 37 36 / 0.6) !important;
}

.tw-decoration-stone-800\/65 {
  text-decoration-color: rgb(41 37 36 / 0.65) !important;
}

.tw-decoration-stone-800\/70 {
  text-decoration-color: rgb(41 37 36 / 0.7) !important;
}

.tw-decoration-stone-800\/75 {
  text-decoration-color: rgb(41 37 36 / 0.75) !important;
}

.tw-decoration-stone-800\/80 {
  text-decoration-color: rgb(41 37 36 / 0.8) !important;
}

.tw-decoration-stone-800\/85 {
  text-decoration-color: rgb(41 37 36 / 0.85) !important;
}

.tw-decoration-stone-800\/90 {
  text-decoration-color: rgb(41 37 36 / 0.9) !important;
}

.tw-decoration-stone-800\/95 {
  text-decoration-color: rgb(41 37 36 / 0.95) !important;
}

.tw-decoration-stone-900 {
  text-decoration-color: #1c1917 !important;
}

.tw-decoration-stone-900\/0 {
  text-decoration-color: rgb(28 25 23 / 0) !important;
}

.tw-decoration-stone-900\/10 {
  text-decoration-color: rgb(28 25 23 / 0.1) !important;
}

.tw-decoration-stone-900\/100 {
  text-decoration-color: rgb(28 25 23 / 1) !important;
}

.tw-decoration-stone-900\/15 {
  text-decoration-color: rgb(28 25 23 / 0.15) !important;
}

.tw-decoration-stone-900\/20 {
  text-decoration-color: rgb(28 25 23 / 0.2) !important;
}

.tw-decoration-stone-900\/25 {
  text-decoration-color: rgb(28 25 23 / 0.25) !important;
}

.tw-decoration-stone-900\/30 {
  text-decoration-color: rgb(28 25 23 / 0.3) !important;
}

.tw-decoration-stone-900\/35 {
  text-decoration-color: rgb(28 25 23 / 0.35) !important;
}

.tw-decoration-stone-900\/40 {
  text-decoration-color: rgb(28 25 23 / 0.4) !important;
}

.tw-decoration-stone-900\/45 {
  text-decoration-color: rgb(28 25 23 / 0.45) !important;
}

.tw-decoration-stone-900\/5 {
  text-decoration-color: rgb(28 25 23 / 0.05) !important;
}

.tw-decoration-stone-900\/50 {
  text-decoration-color: rgb(28 25 23 / 0.5) !important;
}

.tw-decoration-stone-900\/55 {
  text-decoration-color: rgb(28 25 23 / 0.55) !important;
}

.tw-decoration-stone-900\/60 {
  text-decoration-color: rgb(28 25 23 / 0.6) !important;
}

.tw-decoration-stone-900\/65 {
  text-decoration-color: rgb(28 25 23 / 0.65) !important;
}

.tw-decoration-stone-900\/70 {
  text-decoration-color: rgb(28 25 23 / 0.7) !important;
}

.tw-decoration-stone-900\/75 {
  text-decoration-color: rgb(28 25 23 / 0.75) !important;
}

.tw-decoration-stone-900\/80 {
  text-decoration-color: rgb(28 25 23 / 0.8) !important;
}

.tw-decoration-stone-900\/85 {
  text-decoration-color: rgb(28 25 23 / 0.85) !important;
}

.tw-decoration-stone-900\/90 {
  text-decoration-color: rgb(28 25 23 / 0.9) !important;
}

.tw-decoration-stone-900\/95 {
  text-decoration-color: rgb(28 25 23 / 0.95) !important;
}

.tw-decoration-stone-950 {
  text-decoration-color: #0c0a09 !important;
}

.tw-decoration-stone-950\/0 {
  text-decoration-color: rgb(12 10 9 / 0) !important;
}

.tw-decoration-stone-950\/10 {
  text-decoration-color: rgb(12 10 9 / 0.1) !important;
}

.tw-decoration-stone-950\/100 {
  text-decoration-color: rgb(12 10 9 / 1) !important;
}

.tw-decoration-stone-950\/15 {
  text-decoration-color: rgb(12 10 9 / 0.15) !important;
}

.tw-decoration-stone-950\/20 {
  text-decoration-color: rgb(12 10 9 / 0.2) !important;
}

.tw-decoration-stone-950\/25 {
  text-decoration-color: rgb(12 10 9 / 0.25) !important;
}

.tw-decoration-stone-950\/30 {
  text-decoration-color: rgb(12 10 9 / 0.3) !important;
}

.tw-decoration-stone-950\/35 {
  text-decoration-color: rgb(12 10 9 / 0.35) !important;
}

.tw-decoration-stone-950\/40 {
  text-decoration-color: rgb(12 10 9 / 0.4) !important;
}

.tw-decoration-stone-950\/45 {
  text-decoration-color: rgb(12 10 9 / 0.45) !important;
}

.tw-decoration-stone-950\/5 {
  text-decoration-color: rgb(12 10 9 / 0.05) !important;
}

.tw-decoration-stone-950\/50 {
  text-decoration-color: rgb(12 10 9 / 0.5) !important;
}

.tw-decoration-stone-950\/55 {
  text-decoration-color: rgb(12 10 9 / 0.55) !important;
}

.tw-decoration-stone-950\/60 {
  text-decoration-color: rgb(12 10 9 / 0.6) !important;
}

.tw-decoration-stone-950\/65 {
  text-decoration-color: rgb(12 10 9 / 0.65) !important;
}

.tw-decoration-stone-950\/70 {
  text-decoration-color: rgb(12 10 9 / 0.7) !important;
}

.tw-decoration-stone-950\/75 {
  text-decoration-color: rgb(12 10 9 / 0.75) !important;
}

.tw-decoration-stone-950\/80 {
  text-decoration-color: rgb(12 10 9 / 0.8) !important;
}

.tw-decoration-stone-950\/85 {
  text-decoration-color: rgb(12 10 9 / 0.85) !important;
}

.tw-decoration-stone-950\/90 {
  text-decoration-color: rgb(12 10 9 / 0.9) !important;
}

.tw-decoration-stone-950\/95 {
  text-decoration-color: rgb(12 10 9 / 0.95) !important;
}

.tw-decoration-teal-100 {
  text-decoration-color: #ccfbf1 !important;
}

.tw-decoration-teal-100\/0 {
  text-decoration-color: rgb(204 251 241 / 0) !important;
}

.tw-decoration-teal-100\/10 {
  text-decoration-color: rgb(204 251 241 / 0.1) !important;
}

.tw-decoration-teal-100\/100 {
  text-decoration-color: rgb(204 251 241 / 1) !important;
}

.tw-decoration-teal-100\/15 {
  text-decoration-color: rgb(204 251 241 / 0.15) !important;
}

.tw-decoration-teal-100\/20 {
  text-decoration-color: rgb(204 251 241 / 0.2) !important;
}

.tw-decoration-teal-100\/25 {
  text-decoration-color: rgb(204 251 241 / 0.25) !important;
}

.tw-decoration-teal-100\/30 {
  text-decoration-color: rgb(204 251 241 / 0.3) !important;
}

.tw-decoration-teal-100\/35 {
  text-decoration-color: rgb(204 251 241 / 0.35) !important;
}

.tw-decoration-teal-100\/40 {
  text-decoration-color: rgb(204 251 241 / 0.4) !important;
}

.tw-decoration-teal-100\/45 {
  text-decoration-color: rgb(204 251 241 / 0.45) !important;
}

.tw-decoration-teal-100\/5 {
  text-decoration-color: rgb(204 251 241 / 0.05) !important;
}

.tw-decoration-teal-100\/50 {
  text-decoration-color: rgb(204 251 241 / 0.5) !important;
}

.tw-decoration-teal-100\/55 {
  text-decoration-color: rgb(204 251 241 / 0.55) !important;
}

.tw-decoration-teal-100\/60 {
  text-decoration-color: rgb(204 251 241 / 0.6) !important;
}

.tw-decoration-teal-100\/65 {
  text-decoration-color: rgb(204 251 241 / 0.65) !important;
}

.tw-decoration-teal-100\/70 {
  text-decoration-color: rgb(204 251 241 / 0.7) !important;
}

.tw-decoration-teal-100\/75 {
  text-decoration-color: rgb(204 251 241 / 0.75) !important;
}

.tw-decoration-teal-100\/80 {
  text-decoration-color: rgb(204 251 241 / 0.8) !important;
}

.tw-decoration-teal-100\/85 {
  text-decoration-color: rgb(204 251 241 / 0.85) !important;
}

.tw-decoration-teal-100\/90 {
  text-decoration-color: rgb(204 251 241 / 0.9) !important;
}

.tw-decoration-teal-100\/95 {
  text-decoration-color: rgb(204 251 241 / 0.95) !important;
}

.tw-decoration-teal-200 {
  text-decoration-color: #99f6e4 !important;
}

.tw-decoration-teal-200\/0 {
  text-decoration-color: rgb(153 246 228 / 0) !important;
}

.tw-decoration-teal-200\/10 {
  text-decoration-color: rgb(153 246 228 / 0.1) !important;
}

.tw-decoration-teal-200\/100 {
  text-decoration-color: rgb(153 246 228 / 1) !important;
}

.tw-decoration-teal-200\/15 {
  text-decoration-color: rgb(153 246 228 / 0.15) !important;
}

.tw-decoration-teal-200\/20 {
  text-decoration-color: rgb(153 246 228 / 0.2) !important;
}

.tw-decoration-teal-200\/25 {
  text-decoration-color: rgb(153 246 228 / 0.25) !important;
}

.tw-decoration-teal-200\/30 {
  text-decoration-color: rgb(153 246 228 / 0.3) !important;
}

.tw-decoration-teal-200\/35 {
  text-decoration-color: rgb(153 246 228 / 0.35) !important;
}

.tw-decoration-teal-200\/40 {
  text-decoration-color: rgb(153 246 228 / 0.4) !important;
}

.tw-decoration-teal-200\/45 {
  text-decoration-color: rgb(153 246 228 / 0.45) !important;
}

.tw-decoration-teal-200\/5 {
  text-decoration-color: rgb(153 246 228 / 0.05) !important;
}

.tw-decoration-teal-200\/50 {
  text-decoration-color: rgb(153 246 228 / 0.5) !important;
}

.tw-decoration-teal-200\/55 {
  text-decoration-color: rgb(153 246 228 / 0.55) !important;
}

.tw-decoration-teal-200\/60 {
  text-decoration-color: rgb(153 246 228 / 0.6) !important;
}

.tw-decoration-teal-200\/65 {
  text-decoration-color: rgb(153 246 228 / 0.65) !important;
}

.tw-decoration-teal-200\/70 {
  text-decoration-color: rgb(153 246 228 / 0.7) !important;
}

.tw-decoration-teal-200\/75 {
  text-decoration-color: rgb(153 246 228 / 0.75) !important;
}

.tw-decoration-teal-200\/80 {
  text-decoration-color: rgb(153 246 228 / 0.8) !important;
}

.tw-decoration-teal-200\/85 {
  text-decoration-color: rgb(153 246 228 / 0.85) !important;
}

.tw-decoration-teal-200\/90 {
  text-decoration-color: rgb(153 246 228 / 0.9) !important;
}

.tw-decoration-teal-200\/95 {
  text-decoration-color: rgb(153 246 228 / 0.95) !important;
}

.tw-decoration-teal-300 {
  text-decoration-color: #5eead4 !important;
}

.tw-decoration-teal-300\/0 {
  text-decoration-color: rgb(94 234 212 / 0) !important;
}

.tw-decoration-teal-300\/10 {
  text-decoration-color: rgb(94 234 212 / 0.1) !important;
}

.tw-decoration-teal-300\/100 {
  text-decoration-color: rgb(94 234 212 / 1) !important;
}

.tw-decoration-teal-300\/15 {
  text-decoration-color: rgb(94 234 212 / 0.15) !important;
}

.tw-decoration-teal-300\/20 {
  text-decoration-color: rgb(94 234 212 / 0.2) !important;
}

.tw-decoration-teal-300\/25 {
  text-decoration-color: rgb(94 234 212 / 0.25) !important;
}

.tw-decoration-teal-300\/30 {
  text-decoration-color: rgb(94 234 212 / 0.3) !important;
}

.tw-decoration-teal-300\/35 {
  text-decoration-color: rgb(94 234 212 / 0.35) !important;
}

.tw-decoration-teal-300\/40 {
  text-decoration-color: rgb(94 234 212 / 0.4) !important;
}

.tw-decoration-teal-300\/45 {
  text-decoration-color: rgb(94 234 212 / 0.45) !important;
}

.tw-decoration-teal-300\/5 {
  text-decoration-color: rgb(94 234 212 / 0.05) !important;
}

.tw-decoration-teal-300\/50 {
  text-decoration-color: rgb(94 234 212 / 0.5) !important;
}

.tw-decoration-teal-300\/55 {
  text-decoration-color: rgb(94 234 212 / 0.55) !important;
}

.tw-decoration-teal-300\/60 {
  text-decoration-color: rgb(94 234 212 / 0.6) !important;
}

.tw-decoration-teal-300\/65 {
  text-decoration-color: rgb(94 234 212 / 0.65) !important;
}

.tw-decoration-teal-300\/70 {
  text-decoration-color: rgb(94 234 212 / 0.7) !important;
}

.tw-decoration-teal-300\/75 {
  text-decoration-color: rgb(94 234 212 / 0.75) !important;
}

.tw-decoration-teal-300\/80 {
  text-decoration-color: rgb(94 234 212 / 0.8) !important;
}

.tw-decoration-teal-300\/85 {
  text-decoration-color: rgb(94 234 212 / 0.85) !important;
}

.tw-decoration-teal-300\/90 {
  text-decoration-color: rgb(94 234 212 / 0.9) !important;
}

.tw-decoration-teal-300\/95 {
  text-decoration-color: rgb(94 234 212 / 0.95) !important;
}

.tw-decoration-teal-400 {
  text-decoration-color: #2dd4bf !important;
}

.tw-decoration-teal-400\/0 {
  text-decoration-color: rgb(45 212 191 / 0) !important;
}

.tw-decoration-teal-400\/10 {
  text-decoration-color: rgb(45 212 191 / 0.1) !important;
}

.tw-decoration-teal-400\/100 {
  text-decoration-color: rgb(45 212 191 / 1) !important;
}

.tw-decoration-teal-400\/15 {
  text-decoration-color: rgb(45 212 191 / 0.15) !important;
}

.tw-decoration-teal-400\/20 {
  text-decoration-color: rgb(45 212 191 / 0.2) !important;
}

.tw-decoration-teal-400\/25 {
  text-decoration-color: rgb(45 212 191 / 0.25) !important;
}

.tw-decoration-teal-400\/30 {
  text-decoration-color: rgb(45 212 191 / 0.3) !important;
}

.tw-decoration-teal-400\/35 {
  text-decoration-color: rgb(45 212 191 / 0.35) !important;
}

.tw-decoration-teal-400\/40 {
  text-decoration-color: rgb(45 212 191 / 0.4) !important;
}

.tw-decoration-teal-400\/45 {
  text-decoration-color: rgb(45 212 191 / 0.45) !important;
}

.tw-decoration-teal-400\/5 {
  text-decoration-color: rgb(45 212 191 / 0.05) !important;
}

.tw-decoration-teal-400\/50 {
  text-decoration-color: rgb(45 212 191 / 0.5) !important;
}

.tw-decoration-teal-400\/55 {
  text-decoration-color: rgb(45 212 191 / 0.55) !important;
}

.tw-decoration-teal-400\/60 {
  text-decoration-color: rgb(45 212 191 / 0.6) !important;
}

.tw-decoration-teal-400\/65 {
  text-decoration-color: rgb(45 212 191 / 0.65) !important;
}

.tw-decoration-teal-400\/70 {
  text-decoration-color: rgb(45 212 191 / 0.7) !important;
}

.tw-decoration-teal-400\/75 {
  text-decoration-color: rgb(45 212 191 / 0.75) !important;
}

.tw-decoration-teal-400\/80 {
  text-decoration-color: rgb(45 212 191 / 0.8) !important;
}

.tw-decoration-teal-400\/85 {
  text-decoration-color: rgb(45 212 191 / 0.85) !important;
}

.tw-decoration-teal-400\/90 {
  text-decoration-color: rgb(45 212 191 / 0.9) !important;
}

.tw-decoration-teal-400\/95 {
  text-decoration-color: rgb(45 212 191 / 0.95) !important;
}

.tw-decoration-teal-50 {
  text-decoration-color: #f0fdfa !important;
}

.tw-decoration-teal-50\/0 {
  text-decoration-color: rgb(240 253 250 / 0) !important;
}

.tw-decoration-teal-50\/10 {
  text-decoration-color: rgb(240 253 250 / 0.1) !important;
}

.tw-decoration-teal-50\/100 {
  text-decoration-color: rgb(240 253 250 / 1) !important;
}

.tw-decoration-teal-50\/15 {
  text-decoration-color: rgb(240 253 250 / 0.15) !important;
}

.tw-decoration-teal-50\/20 {
  text-decoration-color: rgb(240 253 250 / 0.2) !important;
}

.tw-decoration-teal-50\/25 {
  text-decoration-color: rgb(240 253 250 / 0.25) !important;
}

.tw-decoration-teal-50\/30 {
  text-decoration-color: rgb(240 253 250 / 0.3) !important;
}

.tw-decoration-teal-50\/35 {
  text-decoration-color: rgb(240 253 250 / 0.35) !important;
}

.tw-decoration-teal-50\/40 {
  text-decoration-color: rgb(240 253 250 / 0.4) !important;
}

.tw-decoration-teal-50\/45 {
  text-decoration-color: rgb(240 253 250 / 0.45) !important;
}

.tw-decoration-teal-50\/5 {
  text-decoration-color: rgb(240 253 250 / 0.05) !important;
}

.tw-decoration-teal-50\/50 {
  text-decoration-color: rgb(240 253 250 / 0.5) !important;
}

.tw-decoration-teal-50\/55 {
  text-decoration-color: rgb(240 253 250 / 0.55) !important;
}

.tw-decoration-teal-50\/60 {
  text-decoration-color: rgb(240 253 250 / 0.6) !important;
}

.tw-decoration-teal-50\/65 {
  text-decoration-color: rgb(240 253 250 / 0.65) !important;
}

.tw-decoration-teal-50\/70 {
  text-decoration-color: rgb(240 253 250 / 0.7) !important;
}

.tw-decoration-teal-50\/75 {
  text-decoration-color: rgb(240 253 250 / 0.75) !important;
}

.tw-decoration-teal-50\/80 {
  text-decoration-color: rgb(240 253 250 / 0.8) !important;
}

.tw-decoration-teal-50\/85 {
  text-decoration-color: rgb(240 253 250 / 0.85) !important;
}

.tw-decoration-teal-50\/90 {
  text-decoration-color: rgb(240 253 250 / 0.9) !important;
}

.tw-decoration-teal-50\/95 {
  text-decoration-color: rgb(240 253 250 / 0.95) !important;
}

.tw-decoration-teal-500 {
  text-decoration-color: #14b8a6 !important;
}

.tw-decoration-teal-500\/0 {
  text-decoration-color: rgb(20 184 166 / 0) !important;
}

.tw-decoration-teal-500\/10 {
  text-decoration-color: rgb(20 184 166 / 0.1) !important;
}

.tw-decoration-teal-500\/100 {
  text-decoration-color: rgb(20 184 166 / 1) !important;
}

.tw-decoration-teal-500\/15 {
  text-decoration-color: rgb(20 184 166 / 0.15) !important;
}

.tw-decoration-teal-500\/20 {
  text-decoration-color: rgb(20 184 166 / 0.2) !important;
}

.tw-decoration-teal-500\/25 {
  text-decoration-color: rgb(20 184 166 / 0.25) !important;
}

.tw-decoration-teal-500\/30 {
  text-decoration-color: rgb(20 184 166 / 0.3) !important;
}

.tw-decoration-teal-500\/35 {
  text-decoration-color: rgb(20 184 166 / 0.35) !important;
}

.tw-decoration-teal-500\/40 {
  text-decoration-color: rgb(20 184 166 / 0.4) !important;
}

.tw-decoration-teal-500\/45 {
  text-decoration-color: rgb(20 184 166 / 0.45) !important;
}

.tw-decoration-teal-500\/5 {
  text-decoration-color: rgb(20 184 166 / 0.05) !important;
}

.tw-decoration-teal-500\/50 {
  text-decoration-color: rgb(20 184 166 / 0.5) !important;
}

.tw-decoration-teal-500\/55 {
  text-decoration-color: rgb(20 184 166 / 0.55) !important;
}

.tw-decoration-teal-500\/60 {
  text-decoration-color: rgb(20 184 166 / 0.6) !important;
}

.tw-decoration-teal-500\/65 {
  text-decoration-color: rgb(20 184 166 / 0.65) !important;
}

.tw-decoration-teal-500\/70 {
  text-decoration-color: rgb(20 184 166 / 0.7) !important;
}

.tw-decoration-teal-500\/75 {
  text-decoration-color: rgb(20 184 166 / 0.75) !important;
}

.tw-decoration-teal-500\/80 {
  text-decoration-color: rgb(20 184 166 / 0.8) !important;
}

.tw-decoration-teal-500\/85 {
  text-decoration-color: rgb(20 184 166 / 0.85) !important;
}

.tw-decoration-teal-500\/90 {
  text-decoration-color: rgb(20 184 166 / 0.9) !important;
}

.tw-decoration-teal-500\/95 {
  text-decoration-color: rgb(20 184 166 / 0.95) !important;
}

.tw-decoration-teal-600 {
  text-decoration-color: #0d9488 !important;
}

.tw-decoration-teal-600\/0 {
  text-decoration-color: rgb(13 148 136 / 0) !important;
}

.tw-decoration-teal-600\/10 {
  text-decoration-color: rgb(13 148 136 / 0.1) !important;
}

.tw-decoration-teal-600\/100 {
  text-decoration-color: rgb(13 148 136 / 1) !important;
}

.tw-decoration-teal-600\/15 {
  text-decoration-color: rgb(13 148 136 / 0.15) !important;
}

.tw-decoration-teal-600\/20 {
  text-decoration-color: rgb(13 148 136 / 0.2) !important;
}

.tw-decoration-teal-600\/25 {
  text-decoration-color: rgb(13 148 136 / 0.25) !important;
}

.tw-decoration-teal-600\/30 {
  text-decoration-color: rgb(13 148 136 / 0.3) !important;
}

.tw-decoration-teal-600\/35 {
  text-decoration-color: rgb(13 148 136 / 0.35) !important;
}

.tw-decoration-teal-600\/40 {
  text-decoration-color: rgb(13 148 136 / 0.4) !important;
}

.tw-decoration-teal-600\/45 {
  text-decoration-color: rgb(13 148 136 / 0.45) !important;
}

.tw-decoration-teal-600\/5 {
  text-decoration-color: rgb(13 148 136 / 0.05) !important;
}

.tw-decoration-teal-600\/50 {
  text-decoration-color: rgb(13 148 136 / 0.5) !important;
}

.tw-decoration-teal-600\/55 {
  text-decoration-color: rgb(13 148 136 / 0.55) !important;
}

.tw-decoration-teal-600\/60 {
  text-decoration-color: rgb(13 148 136 / 0.6) !important;
}

.tw-decoration-teal-600\/65 {
  text-decoration-color: rgb(13 148 136 / 0.65) !important;
}

.tw-decoration-teal-600\/70 {
  text-decoration-color: rgb(13 148 136 / 0.7) !important;
}

.tw-decoration-teal-600\/75 {
  text-decoration-color: rgb(13 148 136 / 0.75) !important;
}

.tw-decoration-teal-600\/80 {
  text-decoration-color: rgb(13 148 136 / 0.8) !important;
}

.tw-decoration-teal-600\/85 {
  text-decoration-color: rgb(13 148 136 / 0.85) !important;
}

.tw-decoration-teal-600\/90 {
  text-decoration-color: rgb(13 148 136 / 0.9) !important;
}

.tw-decoration-teal-600\/95 {
  text-decoration-color: rgb(13 148 136 / 0.95) !important;
}

.tw-decoration-teal-700 {
  text-decoration-color: #0f766e !important;
}

.tw-decoration-teal-700\/0 {
  text-decoration-color: rgb(15 118 110 / 0) !important;
}

.tw-decoration-teal-700\/10 {
  text-decoration-color: rgb(15 118 110 / 0.1) !important;
}

.tw-decoration-teal-700\/100 {
  text-decoration-color: rgb(15 118 110 / 1) !important;
}

.tw-decoration-teal-700\/15 {
  text-decoration-color: rgb(15 118 110 / 0.15) !important;
}

.tw-decoration-teal-700\/20 {
  text-decoration-color: rgb(15 118 110 / 0.2) !important;
}

.tw-decoration-teal-700\/25 {
  text-decoration-color: rgb(15 118 110 / 0.25) !important;
}

.tw-decoration-teal-700\/30 {
  text-decoration-color: rgb(15 118 110 / 0.3) !important;
}

.tw-decoration-teal-700\/35 {
  text-decoration-color: rgb(15 118 110 / 0.35) !important;
}

.tw-decoration-teal-700\/40 {
  text-decoration-color: rgb(15 118 110 / 0.4) !important;
}

.tw-decoration-teal-700\/45 {
  text-decoration-color: rgb(15 118 110 / 0.45) !important;
}

.tw-decoration-teal-700\/5 {
  text-decoration-color: rgb(15 118 110 / 0.05) !important;
}

.tw-decoration-teal-700\/50 {
  text-decoration-color: rgb(15 118 110 / 0.5) !important;
}

.tw-decoration-teal-700\/55 {
  text-decoration-color: rgb(15 118 110 / 0.55) !important;
}

.tw-decoration-teal-700\/60 {
  text-decoration-color: rgb(15 118 110 / 0.6) !important;
}

.tw-decoration-teal-700\/65 {
  text-decoration-color: rgb(15 118 110 / 0.65) !important;
}

.tw-decoration-teal-700\/70 {
  text-decoration-color: rgb(15 118 110 / 0.7) !important;
}

.tw-decoration-teal-700\/75 {
  text-decoration-color: rgb(15 118 110 / 0.75) !important;
}

.tw-decoration-teal-700\/80 {
  text-decoration-color: rgb(15 118 110 / 0.8) !important;
}

.tw-decoration-teal-700\/85 {
  text-decoration-color: rgb(15 118 110 / 0.85) !important;
}

.tw-decoration-teal-700\/90 {
  text-decoration-color: rgb(15 118 110 / 0.9) !important;
}

.tw-decoration-teal-700\/95 {
  text-decoration-color: rgb(15 118 110 / 0.95) !important;
}

.tw-decoration-teal-800 {
  text-decoration-color: #115e59 !important;
}

.tw-decoration-teal-800\/0 {
  text-decoration-color: rgb(17 94 89 / 0) !important;
}

.tw-decoration-teal-800\/10 {
  text-decoration-color: rgb(17 94 89 / 0.1) !important;
}

.tw-decoration-teal-800\/100 {
  text-decoration-color: rgb(17 94 89 / 1) !important;
}

.tw-decoration-teal-800\/15 {
  text-decoration-color: rgb(17 94 89 / 0.15) !important;
}

.tw-decoration-teal-800\/20 {
  text-decoration-color: rgb(17 94 89 / 0.2) !important;
}

.tw-decoration-teal-800\/25 {
  text-decoration-color: rgb(17 94 89 / 0.25) !important;
}

.tw-decoration-teal-800\/30 {
  text-decoration-color: rgb(17 94 89 / 0.3) !important;
}

.tw-decoration-teal-800\/35 {
  text-decoration-color: rgb(17 94 89 / 0.35) !important;
}

.tw-decoration-teal-800\/40 {
  text-decoration-color: rgb(17 94 89 / 0.4) !important;
}

.tw-decoration-teal-800\/45 {
  text-decoration-color: rgb(17 94 89 / 0.45) !important;
}

.tw-decoration-teal-800\/5 {
  text-decoration-color: rgb(17 94 89 / 0.05) !important;
}

.tw-decoration-teal-800\/50 {
  text-decoration-color: rgb(17 94 89 / 0.5) !important;
}

.tw-decoration-teal-800\/55 {
  text-decoration-color: rgb(17 94 89 / 0.55) !important;
}

.tw-decoration-teal-800\/60 {
  text-decoration-color: rgb(17 94 89 / 0.6) !important;
}

.tw-decoration-teal-800\/65 {
  text-decoration-color: rgb(17 94 89 / 0.65) !important;
}

.tw-decoration-teal-800\/70 {
  text-decoration-color: rgb(17 94 89 / 0.7) !important;
}

.tw-decoration-teal-800\/75 {
  text-decoration-color: rgb(17 94 89 / 0.75) !important;
}

.tw-decoration-teal-800\/80 {
  text-decoration-color: rgb(17 94 89 / 0.8) !important;
}

.tw-decoration-teal-800\/85 {
  text-decoration-color: rgb(17 94 89 / 0.85) !important;
}

.tw-decoration-teal-800\/90 {
  text-decoration-color: rgb(17 94 89 / 0.9) !important;
}

.tw-decoration-teal-800\/95 {
  text-decoration-color: rgb(17 94 89 / 0.95) !important;
}

.tw-decoration-teal-900 {
  text-decoration-color: #134e4a !important;
}

.tw-decoration-teal-900\/0 {
  text-decoration-color: rgb(19 78 74 / 0) !important;
}

.tw-decoration-teal-900\/10 {
  text-decoration-color: rgb(19 78 74 / 0.1) !important;
}

.tw-decoration-teal-900\/100 {
  text-decoration-color: rgb(19 78 74 / 1) !important;
}

.tw-decoration-teal-900\/15 {
  text-decoration-color: rgb(19 78 74 / 0.15) !important;
}

.tw-decoration-teal-900\/20 {
  text-decoration-color: rgb(19 78 74 / 0.2) !important;
}

.tw-decoration-teal-900\/25 {
  text-decoration-color: rgb(19 78 74 / 0.25) !important;
}

.tw-decoration-teal-900\/30 {
  text-decoration-color: rgb(19 78 74 / 0.3) !important;
}

.tw-decoration-teal-900\/35 {
  text-decoration-color: rgb(19 78 74 / 0.35) !important;
}

.tw-decoration-teal-900\/40 {
  text-decoration-color: rgb(19 78 74 / 0.4) !important;
}

.tw-decoration-teal-900\/45 {
  text-decoration-color: rgb(19 78 74 / 0.45) !important;
}

.tw-decoration-teal-900\/5 {
  text-decoration-color: rgb(19 78 74 / 0.05) !important;
}

.tw-decoration-teal-900\/50 {
  text-decoration-color: rgb(19 78 74 / 0.5) !important;
}

.tw-decoration-teal-900\/55 {
  text-decoration-color: rgb(19 78 74 / 0.55) !important;
}

.tw-decoration-teal-900\/60 {
  text-decoration-color: rgb(19 78 74 / 0.6) !important;
}

.tw-decoration-teal-900\/65 {
  text-decoration-color: rgb(19 78 74 / 0.65) !important;
}

.tw-decoration-teal-900\/70 {
  text-decoration-color: rgb(19 78 74 / 0.7) !important;
}

.tw-decoration-teal-900\/75 {
  text-decoration-color: rgb(19 78 74 / 0.75) !important;
}

.tw-decoration-teal-900\/80 {
  text-decoration-color: rgb(19 78 74 / 0.8) !important;
}

.tw-decoration-teal-900\/85 {
  text-decoration-color: rgb(19 78 74 / 0.85) !important;
}

.tw-decoration-teal-900\/90 {
  text-decoration-color: rgb(19 78 74 / 0.9) !important;
}

.tw-decoration-teal-900\/95 {
  text-decoration-color: rgb(19 78 74 / 0.95) !important;
}

.tw-decoration-teal-950 {
  text-decoration-color: #042f2e !important;
}

.tw-decoration-teal-950\/0 {
  text-decoration-color: rgb(4 47 46 / 0) !important;
}

.tw-decoration-teal-950\/10 {
  text-decoration-color: rgb(4 47 46 / 0.1) !important;
}

.tw-decoration-teal-950\/100 {
  text-decoration-color: rgb(4 47 46 / 1) !important;
}

.tw-decoration-teal-950\/15 {
  text-decoration-color: rgb(4 47 46 / 0.15) !important;
}

.tw-decoration-teal-950\/20 {
  text-decoration-color: rgb(4 47 46 / 0.2) !important;
}

.tw-decoration-teal-950\/25 {
  text-decoration-color: rgb(4 47 46 / 0.25) !important;
}

.tw-decoration-teal-950\/30 {
  text-decoration-color: rgb(4 47 46 / 0.3) !important;
}

.tw-decoration-teal-950\/35 {
  text-decoration-color: rgb(4 47 46 / 0.35) !important;
}

.tw-decoration-teal-950\/40 {
  text-decoration-color: rgb(4 47 46 / 0.4) !important;
}

.tw-decoration-teal-950\/45 {
  text-decoration-color: rgb(4 47 46 / 0.45) !important;
}

.tw-decoration-teal-950\/5 {
  text-decoration-color: rgb(4 47 46 / 0.05) !important;
}

.tw-decoration-teal-950\/50 {
  text-decoration-color: rgb(4 47 46 / 0.5) !important;
}

.tw-decoration-teal-950\/55 {
  text-decoration-color: rgb(4 47 46 / 0.55) !important;
}

.tw-decoration-teal-950\/60 {
  text-decoration-color: rgb(4 47 46 / 0.6) !important;
}

.tw-decoration-teal-950\/65 {
  text-decoration-color: rgb(4 47 46 / 0.65) !important;
}

.tw-decoration-teal-950\/70 {
  text-decoration-color: rgb(4 47 46 / 0.7) !important;
}

.tw-decoration-teal-950\/75 {
  text-decoration-color: rgb(4 47 46 / 0.75) !important;
}

.tw-decoration-teal-950\/80 {
  text-decoration-color: rgb(4 47 46 / 0.8) !important;
}

.tw-decoration-teal-950\/85 {
  text-decoration-color: rgb(4 47 46 / 0.85) !important;
}

.tw-decoration-teal-950\/90 {
  text-decoration-color: rgb(4 47 46 / 0.9) !important;
}

.tw-decoration-teal-950\/95 {
  text-decoration-color: rgb(4 47 46 / 0.95) !important;
}

.tw-decoration-transparent {
  text-decoration-color: transparent !important;
}

.tw-decoration-transparent\/0 {
  text-decoration-color: rgb(0 0 0 / 0) !important;
}

.tw-decoration-transparent\/10 {
  text-decoration-color: rgb(0 0 0 / 0.1) !important;
}

.tw-decoration-transparent\/100 {
  text-decoration-color: rgb(0 0 0 / 1) !important;
}

.tw-decoration-transparent\/15 {
  text-decoration-color: rgb(0 0 0 / 0.15) !important;
}

.tw-decoration-transparent\/20 {
  text-decoration-color: rgb(0 0 0 / 0.2) !important;
}

.tw-decoration-transparent\/25 {
  text-decoration-color: rgb(0 0 0 / 0.25) !important;
}

.tw-decoration-transparent\/30 {
  text-decoration-color: rgb(0 0 0 / 0.3) !important;
}

.tw-decoration-transparent\/35 {
  text-decoration-color: rgb(0 0 0 / 0.35) !important;
}

.tw-decoration-transparent\/40 {
  text-decoration-color: rgb(0 0 0 / 0.4) !important;
}

.tw-decoration-transparent\/45 {
  text-decoration-color: rgb(0 0 0 / 0.45) !important;
}

.tw-decoration-transparent\/5 {
  text-decoration-color: rgb(0 0 0 / 0.05) !important;
}

.tw-decoration-transparent\/50 {
  text-decoration-color: rgb(0 0 0 / 0.5) !important;
}

.tw-decoration-transparent\/55 {
  text-decoration-color: rgb(0 0 0 / 0.55) !important;
}

.tw-decoration-transparent\/60 {
  text-decoration-color: rgb(0 0 0 / 0.6) !important;
}

.tw-decoration-transparent\/65 {
  text-decoration-color: rgb(0 0 0 / 0.65) !important;
}

.tw-decoration-transparent\/70 {
  text-decoration-color: rgb(0 0 0 / 0.7) !important;
}

.tw-decoration-transparent\/75 {
  text-decoration-color: rgb(0 0 0 / 0.75) !important;
}

.tw-decoration-transparent\/80 {
  text-decoration-color: rgb(0 0 0 / 0.8) !important;
}

.tw-decoration-transparent\/85 {
  text-decoration-color: rgb(0 0 0 / 0.85) !important;
}

.tw-decoration-transparent\/90 {
  text-decoration-color: rgb(0 0 0 / 0.9) !important;
}

.tw-decoration-transparent\/95 {
  text-decoration-color: rgb(0 0 0 / 0.95) !important;
}

.tw-decoration-violet-100 {
  text-decoration-color: #ede9fe !important;
}

.tw-decoration-violet-100\/0 {
  text-decoration-color: rgb(237 233 254 / 0) !important;
}

.tw-decoration-violet-100\/10 {
  text-decoration-color: rgb(237 233 254 / 0.1) !important;
}

.tw-decoration-violet-100\/100 {
  text-decoration-color: rgb(237 233 254 / 1) !important;
}

.tw-decoration-violet-100\/15 {
  text-decoration-color: rgb(237 233 254 / 0.15) !important;
}

.tw-decoration-violet-100\/20 {
  text-decoration-color: rgb(237 233 254 / 0.2) !important;
}

.tw-decoration-violet-100\/25 {
  text-decoration-color: rgb(237 233 254 / 0.25) !important;
}

.tw-decoration-violet-100\/30 {
  text-decoration-color: rgb(237 233 254 / 0.3) !important;
}

.tw-decoration-violet-100\/35 {
  text-decoration-color: rgb(237 233 254 / 0.35) !important;
}

.tw-decoration-violet-100\/40 {
  text-decoration-color: rgb(237 233 254 / 0.4) !important;
}

.tw-decoration-violet-100\/45 {
  text-decoration-color: rgb(237 233 254 / 0.45) !important;
}

.tw-decoration-violet-100\/5 {
  text-decoration-color: rgb(237 233 254 / 0.05) !important;
}

.tw-decoration-violet-100\/50 {
  text-decoration-color: rgb(237 233 254 / 0.5) !important;
}

.tw-decoration-violet-100\/55 {
  text-decoration-color: rgb(237 233 254 / 0.55) !important;
}

.tw-decoration-violet-100\/60 {
  text-decoration-color: rgb(237 233 254 / 0.6) !important;
}

.tw-decoration-violet-100\/65 {
  text-decoration-color: rgb(237 233 254 / 0.65) !important;
}

.tw-decoration-violet-100\/70 {
  text-decoration-color: rgb(237 233 254 / 0.7) !important;
}

.tw-decoration-violet-100\/75 {
  text-decoration-color: rgb(237 233 254 / 0.75) !important;
}

.tw-decoration-violet-100\/80 {
  text-decoration-color: rgb(237 233 254 / 0.8) !important;
}

.tw-decoration-violet-100\/85 {
  text-decoration-color: rgb(237 233 254 / 0.85) !important;
}

.tw-decoration-violet-100\/90 {
  text-decoration-color: rgb(237 233 254 / 0.9) !important;
}

.tw-decoration-violet-100\/95 {
  text-decoration-color: rgb(237 233 254 / 0.95) !important;
}

.tw-decoration-violet-200 {
  text-decoration-color: #ddd6fe !important;
}

.tw-decoration-violet-200\/0 {
  text-decoration-color: rgb(221 214 254 / 0) !important;
}

.tw-decoration-violet-200\/10 {
  text-decoration-color: rgb(221 214 254 / 0.1) !important;
}

.tw-decoration-violet-200\/100 {
  text-decoration-color: rgb(221 214 254 / 1) !important;
}

.tw-decoration-violet-200\/15 {
  text-decoration-color: rgb(221 214 254 / 0.15) !important;
}

.tw-decoration-violet-200\/20 {
  text-decoration-color: rgb(221 214 254 / 0.2) !important;
}

.tw-decoration-violet-200\/25 {
  text-decoration-color: rgb(221 214 254 / 0.25) !important;
}

.tw-decoration-violet-200\/30 {
  text-decoration-color: rgb(221 214 254 / 0.3) !important;
}

.tw-decoration-violet-200\/35 {
  text-decoration-color: rgb(221 214 254 / 0.35) !important;
}

.tw-decoration-violet-200\/40 {
  text-decoration-color: rgb(221 214 254 / 0.4) !important;
}

.tw-decoration-violet-200\/45 {
  text-decoration-color: rgb(221 214 254 / 0.45) !important;
}

.tw-decoration-violet-200\/5 {
  text-decoration-color: rgb(221 214 254 / 0.05) !important;
}

.tw-decoration-violet-200\/50 {
  text-decoration-color: rgb(221 214 254 / 0.5) !important;
}

.tw-decoration-violet-200\/55 {
  text-decoration-color: rgb(221 214 254 / 0.55) !important;
}

.tw-decoration-violet-200\/60 {
  text-decoration-color: rgb(221 214 254 / 0.6) !important;
}

.tw-decoration-violet-200\/65 {
  text-decoration-color: rgb(221 214 254 / 0.65) !important;
}

.tw-decoration-violet-200\/70 {
  text-decoration-color: rgb(221 214 254 / 0.7) !important;
}

.tw-decoration-violet-200\/75 {
  text-decoration-color: rgb(221 214 254 / 0.75) !important;
}

.tw-decoration-violet-200\/80 {
  text-decoration-color: rgb(221 214 254 / 0.8) !important;
}

.tw-decoration-violet-200\/85 {
  text-decoration-color: rgb(221 214 254 / 0.85) !important;
}

.tw-decoration-violet-200\/90 {
  text-decoration-color: rgb(221 214 254 / 0.9) !important;
}

.tw-decoration-violet-200\/95 {
  text-decoration-color: rgb(221 214 254 / 0.95) !important;
}

.tw-decoration-violet-300 {
  text-decoration-color: #c4b5fd !important;
}

.tw-decoration-violet-300\/0 {
  text-decoration-color: rgb(196 181 253 / 0) !important;
}

.tw-decoration-violet-300\/10 {
  text-decoration-color: rgb(196 181 253 / 0.1) !important;
}

.tw-decoration-violet-300\/100 {
  text-decoration-color: rgb(196 181 253 / 1) !important;
}

.tw-decoration-violet-300\/15 {
  text-decoration-color: rgb(196 181 253 / 0.15) !important;
}

.tw-decoration-violet-300\/20 {
  text-decoration-color: rgb(196 181 253 / 0.2) !important;
}

.tw-decoration-violet-300\/25 {
  text-decoration-color: rgb(196 181 253 / 0.25) !important;
}

.tw-decoration-violet-300\/30 {
  text-decoration-color: rgb(196 181 253 / 0.3) !important;
}

.tw-decoration-violet-300\/35 {
  text-decoration-color: rgb(196 181 253 / 0.35) !important;
}

.tw-decoration-violet-300\/40 {
  text-decoration-color: rgb(196 181 253 / 0.4) !important;
}

.tw-decoration-violet-300\/45 {
  text-decoration-color: rgb(196 181 253 / 0.45) !important;
}

.tw-decoration-violet-300\/5 {
  text-decoration-color: rgb(196 181 253 / 0.05) !important;
}

.tw-decoration-violet-300\/50 {
  text-decoration-color: rgb(196 181 253 / 0.5) !important;
}

.tw-decoration-violet-300\/55 {
  text-decoration-color: rgb(196 181 253 / 0.55) !important;
}

.tw-decoration-violet-300\/60 {
  text-decoration-color: rgb(196 181 253 / 0.6) !important;
}

.tw-decoration-violet-300\/65 {
  text-decoration-color: rgb(196 181 253 / 0.65) !important;
}

.tw-decoration-violet-300\/70 {
  text-decoration-color: rgb(196 181 253 / 0.7) !important;
}

.tw-decoration-violet-300\/75 {
  text-decoration-color: rgb(196 181 253 / 0.75) !important;
}

.tw-decoration-violet-300\/80 {
  text-decoration-color: rgb(196 181 253 / 0.8) !important;
}

.tw-decoration-violet-300\/85 {
  text-decoration-color: rgb(196 181 253 / 0.85) !important;
}

.tw-decoration-violet-300\/90 {
  text-decoration-color: rgb(196 181 253 / 0.9) !important;
}

.tw-decoration-violet-300\/95 {
  text-decoration-color: rgb(196 181 253 / 0.95) !important;
}

.tw-decoration-violet-400 {
  text-decoration-color: #a78bfa !important;
}

.tw-decoration-violet-400\/0 {
  text-decoration-color: rgb(167 139 250 / 0) !important;
}

.tw-decoration-violet-400\/10 {
  text-decoration-color: rgb(167 139 250 / 0.1) !important;
}

.tw-decoration-violet-400\/100 {
  text-decoration-color: rgb(167 139 250 / 1) !important;
}

.tw-decoration-violet-400\/15 {
  text-decoration-color: rgb(167 139 250 / 0.15) !important;
}

.tw-decoration-violet-400\/20 {
  text-decoration-color: rgb(167 139 250 / 0.2) !important;
}

.tw-decoration-violet-400\/25 {
  text-decoration-color: rgb(167 139 250 / 0.25) !important;
}

.tw-decoration-violet-400\/30 {
  text-decoration-color: rgb(167 139 250 / 0.3) !important;
}

.tw-decoration-violet-400\/35 {
  text-decoration-color: rgb(167 139 250 / 0.35) !important;
}

.tw-decoration-violet-400\/40 {
  text-decoration-color: rgb(167 139 250 / 0.4) !important;
}

.tw-decoration-violet-400\/45 {
  text-decoration-color: rgb(167 139 250 / 0.45) !important;
}

.tw-decoration-violet-400\/5 {
  text-decoration-color: rgb(167 139 250 / 0.05) !important;
}

.tw-decoration-violet-400\/50 {
  text-decoration-color: rgb(167 139 250 / 0.5) !important;
}

.tw-decoration-violet-400\/55 {
  text-decoration-color: rgb(167 139 250 / 0.55) !important;
}

.tw-decoration-violet-400\/60 {
  text-decoration-color: rgb(167 139 250 / 0.6) !important;
}

.tw-decoration-violet-400\/65 {
  text-decoration-color: rgb(167 139 250 / 0.65) !important;
}

.tw-decoration-violet-400\/70 {
  text-decoration-color: rgb(167 139 250 / 0.7) !important;
}

.tw-decoration-violet-400\/75 {
  text-decoration-color: rgb(167 139 250 / 0.75) !important;
}

.tw-decoration-violet-400\/80 {
  text-decoration-color: rgb(167 139 250 / 0.8) !important;
}

.tw-decoration-violet-400\/85 {
  text-decoration-color: rgb(167 139 250 / 0.85) !important;
}

.tw-decoration-violet-400\/90 {
  text-decoration-color: rgb(167 139 250 / 0.9) !important;
}

.tw-decoration-violet-400\/95 {
  text-decoration-color: rgb(167 139 250 / 0.95) !important;
}

.tw-decoration-violet-50 {
  text-decoration-color: #f5f3ff !important;
}

.tw-decoration-violet-50\/0 {
  text-decoration-color: rgb(245 243 255 / 0) !important;
}

.tw-decoration-violet-50\/10 {
  text-decoration-color: rgb(245 243 255 / 0.1) !important;
}

.tw-decoration-violet-50\/100 {
  text-decoration-color: rgb(245 243 255 / 1) !important;
}

.tw-decoration-violet-50\/15 {
  text-decoration-color: rgb(245 243 255 / 0.15) !important;
}

.tw-decoration-violet-50\/20 {
  text-decoration-color: rgb(245 243 255 / 0.2) !important;
}

.tw-decoration-violet-50\/25 {
  text-decoration-color: rgb(245 243 255 / 0.25) !important;
}

.tw-decoration-violet-50\/30 {
  text-decoration-color: rgb(245 243 255 / 0.3) !important;
}

.tw-decoration-violet-50\/35 {
  text-decoration-color: rgb(245 243 255 / 0.35) !important;
}

.tw-decoration-violet-50\/40 {
  text-decoration-color: rgb(245 243 255 / 0.4) !important;
}

.tw-decoration-violet-50\/45 {
  text-decoration-color: rgb(245 243 255 / 0.45) !important;
}

.tw-decoration-violet-50\/5 {
  text-decoration-color: rgb(245 243 255 / 0.05) !important;
}

.tw-decoration-violet-50\/50 {
  text-decoration-color: rgb(245 243 255 / 0.5) !important;
}

.tw-decoration-violet-50\/55 {
  text-decoration-color: rgb(245 243 255 / 0.55) !important;
}

.tw-decoration-violet-50\/60 {
  text-decoration-color: rgb(245 243 255 / 0.6) !important;
}

.tw-decoration-violet-50\/65 {
  text-decoration-color: rgb(245 243 255 / 0.65) !important;
}

.tw-decoration-violet-50\/70 {
  text-decoration-color: rgb(245 243 255 / 0.7) !important;
}

.tw-decoration-violet-50\/75 {
  text-decoration-color: rgb(245 243 255 / 0.75) !important;
}

.tw-decoration-violet-50\/80 {
  text-decoration-color: rgb(245 243 255 / 0.8) !important;
}

.tw-decoration-violet-50\/85 {
  text-decoration-color: rgb(245 243 255 / 0.85) !important;
}

.tw-decoration-violet-50\/90 {
  text-decoration-color: rgb(245 243 255 / 0.9) !important;
}

.tw-decoration-violet-50\/95 {
  text-decoration-color: rgb(245 243 255 / 0.95) !important;
}

.tw-decoration-violet-500 {
  text-decoration-color: #8b5cf6 !important;
}

.tw-decoration-violet-500\/0 {
  text-decoration-color: rgb(139 92 246 / 0) !important;
}

.tw-decoration-violet-500\/10 {
  text-decoration-color: rgb(139 92 246 / 0.1) !important;
}

.tw-decoration-violet-500\/100 {
  text-decoration-color: rgb(139 92 246 / 1) !important;
}

.tw-decoration-violet-500\/15 {
  text-decoration-color: rgb(139 92 246 / 0.15) !important;
}

.tw-decoration-violet-500\/20 {
  text-decoration-color: rgb(139 92 246 / 0.2) !important;
}

.tw-decoration-violet-500\/25 {
  text-decoration-color: rgb(139 92 246 / 0.25) !important;
}

.tw-decoration-violet-500\/30 {
  text-decoration-color: rgb(139 92 246 / 0.3) !important;
}

.tw-decoration-violet-500\/35 {
  text-decoration-color: rgb(139 92 246 / 0.35) !important;
}

.tw-decoration-violet-500\/40 {
  text-decoration-color: rgb(139 92 246 / 0.4) !important;
}

.tw-decoration-violet-500\/45 {
  text-decoration-color: rgb(139 92 246 / 0.45) !important;
}

.tw-decoration-violet-500\/5 {
  text-decoration-color: rgb(139 92 246 / 0.05) !important;
}

.tw-decoration-violet-500\/50 {
  text-decoration-color: rgb(139 92 246 / 0.5) !important;
}

.tw-decoration-violet-500\/55 {
  text-decoration-color: rgb(139 92 246 / 0.55) !important;
}

.tw-decoration-violet-500\/60 {
  text-decoration-color: rgb(139 92 246 / 0.6) !important;
}

.tw-decoration-violet-500\/65 {
  text-decoration-color: rgb(139 92 246 / 0.65) !important;
}

.tw-decoration-violet-500\/70 {
  text-decoration-color: rgb(139 92 246 / 0.7) !important;
}

.tw-decoration-violet-500\/75 {
  text-decoration-color: rgb(139 92 246 / 0.75) !important;
}

.tw-decoration-violet-500\/80 {
  text-decoration-color: rgb(139 92 246 / 0.8) !important;
}

.tw-decoration-violet-500\/85 {
  text-decoration-color: rgb(139 92 246 / 0.85) !important;
}

.tw-decoration-violet-500\/90 {
  text-decoration-color: rgb(139 92 246 / 0.9) !important;
}

.tw-decoration-violet-500\/95 {
  text-decoration-color: rgb(139 92 246 / 0.95) !important;
}

.tw-decoration-violet-600 {
  text-decoration-color: #7c3aed !important;
}

.tw-decoration-violet-600\/0 {
  text-decoration-color: rgb(124 58 237 / 0) !important;
}

.tw-decoration-violet-600\/10 {
  text-decoration-color: rgb(124 58 237 / 0.1) !important;
}

.tw-decoration-violet-600\/100 {
  text-decoration-color: rgb(124 58 237 / 1) !important;
}

.tw-decoration-violet-600\/15 {
  text-decoration-color: rgb(124 58 237 / 0.15) !important;
}

.tw-decoration-violet-600\/20 {
  text-decoration-color: rgb(124 58 237 / 0.2) !important;
}

.tw-decoration-violet-600\/25 {
  text-decoration-color: rgb(124 58 237 / 0.25) !important;
}

.tw-decoration-violet-600\/30 {
  text-decoration-color: rgb(124 58 237 / 0.3) !important;
}

.tw-decoration-violet-600\/35 {
  text-decoration-color: rgb(124 58 237 / 0.35) !important;
}

.tw-decoration-violet-600\/40 {
  text-decoration-color: rgb(124 58 237 / 0.4) !important;
}

.tw-decoration-violet-600\/45 {
  text-decoration-color: rgb(124 58 237 / 0.45) !important;
}

.tw-decoration-violet-600\/5 {
  text-decoration-color: rgb(124 58 237 / 0.05) !important;
}

.tw-decoration-violet-600\/50 {
  text-decoration-color: rgb(124 58 237 / 0.5) !important;
}

.tw-decoration-violet-600\/55 {
  text-decoration-color: rgb(124 58 237 / 0.55) !important;
}

.tw-decoration-violet-600\/60 {
  text-decoration-color: rgb(124 58 237 / 0.6) !important;
}

.tw-decoration-violet-600\/65 {
  text-decoration-color: rgb(124 58 237 / 0.65) !important;
}

.tw-decoration-violet-600\/70 {
  text-decoration-color: rgb(124 58 237 / 0.7) !important;
}

.tw-decoration-violet-600\/75 {
  text-decoration-color: rgb(124 58 237 / 0.75) !important;
}

.tw-decoration-violet-600\/80 {
  text-decoration-color: rgb(124 58 237 / 0.8) !important;
}

.tw-decoration-violet-600\/85 {
  text-decoration-color: rgb(124 58 237 / 0.85) !important;
}

.tw-decoration-violet-600\/90 {
  text-decoration-color: rgb(124 58 237 / 0.9) !important;
}

.tw-decoration-violet-600\/95 {
  text-decoration-color: rgb(124 58 237 / 0.95) !important;
}

.tw-decoration-violet-700 {
  text-decoration-color: #6d28d9 !important;
}

.tw-decoration-violet-700\/0 {
  text-decoration-color: rgb(109 40 217 / 0) !important;
}

.tw-decoration-violet-700\/10 {
  text-decoration-color: rgb(109 40 217 / 0.1) !important;
}

.tw-decoration-violet-700\/100 {
  text-decoration-color: rgb(109 40 217 / 1) !important;
}

.tw-decoration-violet-700\/15 {
  text-decoration-color: rgb(109 40 217 / 0.15) !important;
}

.tw-decoration-violet-700\/20 {
  text-decoration-color: rgb(109 40 217 / 0.2) !important;
}

.tw-decoration-violet-700\/25 {
  text-decoration-color: rgb(109 40 217 / 0.25) !important;
}

.tw-decoration-violet-700\/30 {
  text-decoration-color: rgb(109 40 217 / 0.3) !important;
}

.tw-decoration-violet-700\/35 {
  text-decoration-color: rgb(109 40 217 / 0.35) !important;
}

.tw-decoration-violet-700\/40 {
  text-decoration-color: rgb(109 40 217 / 0.4) !important;
}

.tw-decoration-violet-700\/45 {
  text-decoration-color: rgb(109 40 217 / 0.45) !important;
}

.tw-decoration-violet-700\/5 {
  text-decoration-color: rgb(109 40 217 / 0.05) !important;
}

.tw-decoration-violet-700\/50 {
  text-decoration-color: rgb(109 40 217 / 0.5) !important;
}

.tw-decoration-violet-700\/55 {
  text-decoration-color: rgb(109 40 217 / 0.55) !important;
}

.tw-decoration-violet-700\/60 {
  text-decoration-color: rgb(109 40 217 / 0.6) !important;
}

.tw-decoration-violet-700\/65 {
  text-decoration-color: rgb(109 40 217 / 0.65) !important;
}

.tw-decoration-violet-700\/70 {
  text-decoration-color: rgb(109 40 217 / 0.7) !important;
}

.tw-decoration-violet-700\/75 {
  text-decoration-color: rgb(109 40 217 / 0.75) !important;
}

.tw-decoration-violet-700\/80 {
  text-decoration-color: rgb(109 40 217 / 0.8) !important;
}

.tw-decoration-violet-700\/85 {
  text-decoration-color: rgb(109 40 217 / 0.85) !important;
}

.tw-decoration-violet-700\/90 {
  text-decoration-color: rgb(109 40 217 / 0.9) !important;
}

.tw-decoration-violet-700\/95 {
  text-decoration-color: rgb(109 40 217 / 0.95) !important;
}

.tw-decoration-violet-800 {
  text-decoration-color: #5b21b6 !important;
}

.tw-decoration-violet-800\/0 {
  text-decoration-color: rgb(91 33 182 / 0) !important;
}

.tw-decoration-violet-800\/10 {
  text-decoration-color: rgb(91 33 182 / 0.1) !important;
}

.tw-decoration-violet-800\/100 {
  text-decoration-color: rgb(91 33 182 / 1) !important;
}

.tw-decoration-violet-800\/15 {
  text-decoration-color: rgb(91 33 182 / 0.15) !important;
}

.tw-decoration-violet-800\/20 {
  text-decoration-color: rgb(91 33 182 / 0.2) !important;
}

.tw-decoration-violet-800\/25 {
  text-decoration-color: rgb(91 33 182 / 0.25) !important;
}

.tw-decoration-violet-800\/30 {
  text-decoration-color: rgb(91 33 182 / 0.3) !important;
}

.tw-decoration-violet-800\/35 {
  text-decoration-color: rgb(91 33 182 / 0.35) !important;
}

.tw-decoration-violet-800\/40 {
  text-decoration-color: rgb(91 33 182 / 0.4) !important;
}

.tw-decoration-violet-800\/45 {
  text-decoration-color: rgb(91 33 182 / 0.45) !important;
}

.tw-decoration-violet-800\/5 {
  text-decoration-color: rgb(91 33 182 / 0.05) !important;
}

.tw-decoration-violet-800\/50 {
  text-decoration-color: rgb(91 33 182 / 0.5) !important;
}

.tw-decoration-violet-800\/55 {
  text-decoration-color: rgb(91 33 182 / 0.55) !important;
}

.tw-decoration-violet-800\/60 {
  text-decoration-color: rgb(91 33 182 / 0.6) !important;
}

.tw-decoration-violet-800\/65 {
  text-decoration-color: rgb(91 33 182 / 0.65) !important;
}

.tw-decoration-violet-800\/70 {
  text-decoration-color: rgb(91 33 182 / 0.7) !important;
}

.tw-decoration-violet-800\/75 {
  text-decoration-color: rgb(91 33 182 / 0.75) !important;
}

.tw-decoration-violet-800\/80 {
  text-decoration-color: rgb(91 33 182 / 0.8) !important;
}

.tw-decoration-violet-800\/85 {
  text-decoration-color: rgb(91 33 182 / 0.85) !important;
}

.tw-decoration-violet-800\/90 {
  text-decoration-color: rgb(91 33 182 / 0.9) !important;
}

.tw-decoration-violet-800\/95 {
  text-decoration-color: rgb(91 33 182 / 0.95) !important;
}

.tw-decoration-violet-900 {
  text-decoration-color: #4c1d95 !important;
}

.tw-decoration-violet-900\/0 {
  text-decoration-color: rgb(76 29 149 / 0) !important;
}

.tw-decoration-violet-900\/10 {
  text-decoration-color: rgb(76 29 149 / 0.1) !important;
}

.tw-decoration-violet-900\/100 {
  text-decoration-color: rgb(76 29 149 / 1) !important;
}

.tw-decoration-violet-900\/15 {
  text-decoration-color: rgb(76 29 149 / 0.15) !important;
}

.tw-decoration-violet-900\/20 {
  text-decoration-color: rgb(76 29 149 / 0.2) !important;
}

.tw-decoration-violet-900\/25 {
  text-decoration-color: rgb(76 29 149 / 0.25) !important;
}

.tw-decoration-violet-900\/30 {
  text-decoration-color: rgb(76 29 149 / 0.3) !important;
}

.tw-decoration-violet-900\/35 {
  text-decoration-color: rgb(76 29 149 / 0.35) !important;
}

.tw-decoration-violet-900\/40 {
  text-decoration-color: rgb(76 29 149 / 0.4) !important;
}

.tw-decoration-violet-900\/45 {
  text-decoration-color: rgb(76 29 149 / 0.45) !important;
}

.tw-decoration-violet-900\/5 {
  text-decoration-color: rgb(76 29 149 / 0.05) !important;
}

.tw-decoration-violet-900\/50 {
  text-decoration-color: rgb(76 29 149 / 0.5) !important;
}

.tw-decoration-violet-900\/55 {
  text-decoration-color: rgb(76 29 149 / 0.55) !important;
}

.tw-decoration-violet-900\/60 {
  text-decoration-color: rgb(76 29 149 / 0.6) !important;
}

.tw-decoration-violet-900\/65 {
  text-decoration-color: rgb(76 29 149 / 0.65) !important;
}

.tw-decoration-violet-900\/70 {
  text-decoration-color: rgb(76 29 149 / 0.7) !important;
}

.tw-decoration-violet-900\/75 {
  text-decoration-color: rgb(76 29 149 / 0.75) !important;
}

.tw-decoration-violet-900\/80 {
  text-decoration-color: rgb(76 29 149 / 0.8) !important;
}

.tw-decoration-violet-900\/85 {
  text-decoration-color: rgb(76 29 149 / 0.85) !important;
}

.tw-decoration-violet-900\/90 {
  text-decoration-color: rgb(76 29 149 / 0.9) !important;
}

.tw-decoration-violet-900\/95 {
  text-decoration-color: rgb(76 29 149 / 0.95) !important;
}

.tw-decoration-violet-950 {
  text-decoration-color: #2e1065 !important;
}

.tw-decoration-violet-950\/0 {
  text-decoration-color: rgb(46 16 101 / 0) !important;
}

.tw-decoration-violet-950\/10 {
  text-decoration-color: rgb(46 16 101 / 0.1) !important;
}

.tw-decoration-violet-950\/100 {
  text-decoration-color: rgb(46 16 101 / 1) !important;
}

.tw-decoration-violet-950\/15 {
  text-decoration-color: rgb(46 16 101 / 0.15) !important;
}

.tw-decoration-violet-950\/20 {
  text-decoration-color: rgb(46 16 101 / 0.2) !important;
}

.tw-decoration-violet-950\/25 {
  text-decoration-color: rgb(46 16 101 / 0.25) !important;
}

.tw-decoration-violet-950\/30 {
  text-decoration-color: rgb(46 16 101 / 0.3) !important;
}

.tw-decoration-violet-950\/35 {
  text-decoration-color: rgb(46 16 101 / 0.35) !important;
}

.tw-decoration-violet-950\/40 {
  text-decoration-color: rgb(46 16 101 / 0.4) !important;
}

.tw-decoration-violet-950\/45 {
  text-decoration-color: rgb(46 16 101 / 0.45) !important;
}

.tw-decoration-violet-950\/5 {
  text-decoration-color: rgb(46 16 101 / 0.05) !important;
}

.tw-decoration-violet-950\/50 {
  text-decoration-color: rgb(46 16 101 / 0.5) !important;
}

.tw-decoration-violet-950\/55 {
  text-decoration-color: rgb(46 16 101 / 0.55) !important;
}

.tw-decoration-violet-950\/60 {
  text-decoration-color: rgb(46 16 101 / 0.6) !important;
}

.tw-decoration-violet-950\/65 {
  text-decoration-color: rgb(46 16 101 / 0.65) !important;
}

.tw-decoration-violet-950\/70 {
  text-decoration-color: rgb(46 16 101 / 0.7) !important;
}

.tw-decoration-violet-950\/75 {
  text-decoration-color: rgb(46 16 101 / 0.75) !important;
}

.tw-decoration-violet-950\/80 {
  text-decoration-color: rgb(46 16 101 / 0.8) !important;
}

.tw-decoration-violet-950\/85 {
  text-decoration-color: rgb(46 16 101 / 0.85) !important;
}

.tw-decoration-violet-950\/90 {
  text-decoration-color: rgb(46 16 101 / 0.9) !important;
}

.tw-decoration-violet-950\/95 {
  text-decoration-color: rgb(46 16 101 / 0.95) !important;
}

.tw-decoration-white {
  text-decoration-color: #fff !important;
}

.tw-decoration-white\/0 {
  text-decoration-color: rgb(255 255 255 / 0) !important;
}

.tw-decoration-white\/10 {
  text-decoration-color: rgb(255 255 255 / 0.1) !important;
}

.tw-decoration-white\/100 {
  text-decoration-color: rgb(255 255 255 / 1) !important;
}

.tw-decoration-white\/15 {
  text-decoration-color: rgb(255 255 255 / 0.15) !important;
}

.tw-decoration-white\/20 {
  text-decoration-color: rgb(255 255 255 / 0.2) !important;
}

.tw-decoration-white\/25 {
  text-decoration-color: rgb(255 255 255 / 0.25) !important;
}

.tw-decoration-white\/30 {
  text-decoration-color: rgb(255 255 255 / 0.3) !important;
}

.tw-decoration-white\/35 {
  text-decoration-color: rgb(255 255 255 / 0.35) !important;
}

.tw-decoration-white\/40 {
  text-decoration-color: rgb(255 255 255 / 0.4) !important;
}

.tw-decoration-white\/45 {
  text-decoration-color: rgb(255 255 255 / 0.45) !important;
}

.tw-decoration-white\/5 {
  text-decoration-color: rgb(255 255 255 / 0.05) !important;
}

.tw-decoration-white\/50 {
  text-decoration-color: rgb(255 255 255 / 0.5) !important;
}

.tw-decoration-white\/55 {
  text-decoration-color: rgb(255 255 255 / 0.55) !important;
}

.tw-decoration-white\/60 {
  text-decoration-color: rgb(255 255 255 / 0.6) !important;
}

.tw-decoration-white\/65 {
  text-decoration-color: rgb(255 255 255 / 0.65) !important;
}

.tw-decoration-white\/70 {
  text-decoration-color: rgb(255 255 255 / 0.7) !important;
}

.tw-decoration-white\/75 {
  text-decoration-color: rgb(255 255 255 / 0.75) !important;
}

.tw-decoration-white\/80 {
  text-decoration-color: rgb(255 255 255 / 0.8) !important;
}

.tw-decoration-white\/85 {
  text-decoration-color: rgb(255 255 255 / 0.85) !important;
}

.tw-decoration-white\/90 {
  text-decoration-color: rgb(255 255 255 / 0.9) !important;
}

.tw-decoration-white\/95 {
  text-decoration-color: rgb(255 255 255 / 0.95) !important;
}

.tw-decoration-yellow-100 {
  text-decoration-color: #fef9c3 !important;
}

.tw-decoration-yellow-100\/0 {
  text-decoration-color: rgb(254 249 195 / 0) !important;
}

.tw-decoration-yellow-100\/10 {
  text-decoration-color: rgb(254 249 195 / 0.1) !important;
}

.tw-decoration-yellow-100\/100 {
  text-decoration-color: rgb(254 249 195 / 1) !important;
}

.tw-decoration-yellow-100\/15 {
  text-decoration-color: rgb(254 249 195 / 0.15) !important;
}

.tw-decoration-yellow-100\/20 {
  text-decoration-color: rgb(254 249 195 / 0.2) !important;
}

.tw-decoration-yellow-100\/25 {
  text-decoration-color: rgb(254 249 195 / 0.25) !important;
}

.tw-decoration-yellow-100\/30 {
  text-decoration-color: rgb(254 249 195 / 0.3) !important;
}

.tw-decoration-yellow-100\/35 {
  text-decoration-color: rgb(254 249 195 / 0.35) !important;
}

.tw-decoration-yellow-100\/40 {
  text-decoration-color: rgb(254 249 195 / 0.4) !important;
}

.tw-decoration-yellow-100\/45 {
  text-decoration-color: rgb(254 249 195 / 0.45) !important;
}

.tw-decoration-yellow-100\/5 {
  text-decoration-color: rgb(254 249 195 / 0.05) !important;
}

.tw-decoration-yellow-100\/50 {
  text-decoration-color: rgb(254 249 195 / 0.5) !important;
}

.tw-decoration-yellow-100\/55 {
  text-decoration-color: rgb(254 249 195 / 0.55) !important;
}

.tw-decoration-yellow-100\/60 {
  text-decoration-color: rgb(254 249 195 / 0.6) !important;
}

.tw-decoration-yellow-100\/65 {
  text-decoration-color: rgb(254 249 195 / 0.65) !important;
}

.tw-decoration-yellow-100\/70 {
  text-decoration-color: rgb(254 249 195 / 0.7) !important;
}

.tw-decoration-yellow-100\/75 {
  text-decoration-color: rgb(254 249 195 / 0.75) !important;
}

.tw-decoration-yellow-100\/80 {
  text-decoration-color: rgb(254 249 195 / 0.8) !important;
}

.tw-decoration-yellow-100\/85 {
  text-decoration-color: rgb(254 249 195 / 0.85) !important;
}

.tw-decoration-yellow-100\/90 {
  text-decoration-color: rgb(254 249 195 / 0.9) !important;
}

.tw-decoration-yellow-100\/95 {
  text-decoration-color: rgb(254 249 195 / 0.95) !important;
}

.tw-decoration-yellow-200 {
  text-decoration-color: #fef08a !important;
}

.tw-decoration-yellow-200\/0 {
  text-decoration-color: rgb(254 240 138 / 0) !important;
}

.tw-decoration-yellow-200\/10 {
  text-decoration-color: rgb(254 240 138 / 0.1) !important;
}

.tw-decoration-yellow-200\/100 {
  text-decoration-color: rgb(254 240 138 / 1) !important;
}

.tw-decoration-yellow-200\/15 {
  text-decoration-color: rgb(254 240 138 / 0.15) !important;
}

.tw-decoration-yellow-200\/20 {
  text-decoration-color: rgb(254 240 138 / 0.2) !important;
}

.tw-decoration-yellow-200\/25 {
  text-decoration-color: rgb(254 240 138 / 0.25) !important;
}

.tw-decoration-yellow-200\/30 {
  text-decoration-color: rgb(254 240 138 / 0.3) !important;
}

.tw-decoration-yellow-200\/35 {
  text-decoration-color: rgb(254 240 138 / 0.35) !important;
}

.tw-decoration-yellow-200\/40 {
  text-decoration-color: rgb(254 240 138 / 0.4) !important;
}

.tw-decoration-yellow-200\/45 {
  text-decoration-color: rgb(254 240 138 / 0.45) !important;
}

.tw-decoration-yellow-200\/5 {
  text-decoration-color: rgb(254 240 138 / 0.05) !important;
}

.tw-decoration-yellow-200\/50 {
  text-decoration-color: rgb(254 240 138 / 0.5) !important;
}

.tw-decoration-yellow-200\/55 {
  text-decoration-color: rgb(254 240 138 / 0.55) !important;
}

.tw-decoration-yellow-200\/60 {
  text-decoration-color: rgb(254 240 138 / 0.6) !important;
}

.tw-decoration-yellow-200\/65 {
  text-decoration-color: rgb(254 240 138 / 0.65) !important;
}

.tw-decoration-yellow-200\/70 {
  text-decoration-color: rgb(254 240 138 / 0.7) !important;
}

.tw-decoration-yellow-200\/75 {
  text-decoration-color: rgb(254 240 138 / 0.75) !important;
}

.tw-decoration-yellow-200\/80 {
  text-decoration-color: rgb(254 240 138 / 0.8) !important;
}

.tw-decoration-yellow-200\/85 {
  text-decoration-color: rgb(254 240 138 / 0.85) !important;
}

.tw-decoration-yellow-200\/90 {
  text-decoration-color: rgb(254 240 138 / 0.9) !important;
}

.tw-decoration-yellow-200\/95 {
  text-decoration-color: rgb(254 240 138 / 0.95) !important;
}

.tw-decoration-yellow-300 {
  text-decoration-color: #fde047 !important;
}

.tw-decoration-yellow-300\/0 {
  text-decoration-color: rgb(253 224 71 / 0) !important;
}

.tw-decoration-yellow-300\/10 {
  text-decoration-color: rgb(253 224 71 / 0.1) !important;
}

.tw-decoration-yellow-300\/100 {
  text-decoration-color: rgb(253 224 71 / 1) !important;
}

.tw-decoration-yellow-300\/15 {
  text-decoration-color: rgb(253 224 71 / 0.15) !important;
}

.tw-decoration-yellow-300\/20 {
  text-decoration-color: rgb(253 224 71 / 0.2) !important;
}

.tw-decoration-yellow-300\/25 {
  text-decoration-color: rgb(253 224 71 / 0.25) !important;
}

.tw-decoration-yellow-300\/30 {
  text-decoration-color: rgb(253 224 71 / 0.3) !important;
}

.tw-decoration-yellow-300\/35 {
  text-decoration-color: rgb(253 224 71 / 0.35) !important;
}

.tw-decoration-yellow-300\/40 {
  text-decoration-color: rgb(253 224 71 / 0.4) !important;
}

.tw-decoration-yellow-300\/45 {
  text-decoration-color: rgb(253 224 71 / 0.45) !important;
}

.tw-decoration-yellow-300\/5 {
  text-decoration-color: rgb(253 224 71 / 0.05) !important;
}

.tw-decoration-yellow-300\/50 {
  text-decoration-color: rgb(253 224 71 / 0.5) !important;
}

.tw-decoration-yellow-300\/55 {
  text-decoration-color: rgb(253 224 71 / 0.55) !important;
}

.tw-decoration-yellow-300\/60 {
  text-decoration-color: rgb(253 224 71 / 0.6) !important;
}

.tw-decoration-yellow-300\/65 {
  text-decoration-color: rgb(253 224 71 / 0.65) !important;
}

.tw-decoration-yellow-300\/70 {
  text-decoration-color: rgb(253 224 71 / 0.7) !important;
}

.tw-decoration-yellow-300\/75 {
  text-decoration-color: rgb(253 224 71 / 0.75) !important;
}

.tw-decoration-yellow-300\/80 {
  text-decoration-color: rgb(253 224 71 / 0.8) !important;
}

.tw-decoration-yellow-300\/85 {
  text-decoration-color: rgb(253 224 71 / 0.85) !important;
}

.tw-decoration-yellow-300\/90 {
  text-decoration-color: rgb(253 224 71 / 0.9) !important;
}

.tw-decoration-yellow-300\/95 {
  text-decoration-color: rgb(253 224 71 / 0.95) !important;
}

.tw-decoration-yellow-400 {
  text-decoration-color: #facc15 !important;
}

.tw-decoration-yellow-400\/0 {
  text-decoration-color: rgb(250 204 21 / 0) !important;
}

.tw-decoration-yellow-400\/10 {
  text-decoration-color: rgb(250 204 21 / 0.1) !important;
}

.tw-decoration-yellow-400\/100 {
  text-decoration-color: rgb(250 204 21 / 1) !important;
}

.tw-decoration-yellow-400\/15 {
  text-decoration-color: rgb(250 204 21 / 0.15) !important;
}

.tw-decoration-yellow-400\/20 {
  text-decoration-color: rgb(250 204 21 / 0.2) !important;
}

.tw-decoration-yellow-400\/25 {
  text-decoration-color: rgb(250 204 21 / 0.25) !important;
}

.tw-decoration-yellow-400\/30 {
  text-decoration-color: rgb(250 204 21 / 0.3) !important;
}

.tw-decoration-yellow-400\/35 {
  text-decoration-color: rgb(250 204 21 / 0.35) !important;
}

.tw-decoration-yellow-400\/40 {
  text-decoration-color: rgb(250 204 21 / 0.4) !important;
}

.tw-decoration-yellow-400\/45 {
  text-decoration-color: rgb(250 204 21 / 0.45) !important;
}

.tw-decoration-yellow-400\/5 {
  text-decoration-color: rgb(250 204 21 / 0.05) !important;
}

.tw-decoration-yellow-400\/50 {
  text-decoration-color: rgb(250 204 21 / 0.5) !important;
}

.tw-decoration-yellow-400\/55 {
  text-decoration-color: rgb(250 204 21 / 0.55) !important;
}

.tw-decoration-yellow-400\/60 {
  text-decoration-color: rgb(250 204 21 / 0.6) !important;
}

.tw-decoration-yellow-400\/65 {
  text-decoration-color: rgb(250 204 21 / 0.65) !important;
}

.tw-decoration-yellow-400\/70 {
  text-decoration-color: rgb(250 204 21 / 0.7) !important;
}

.tw-decoration-yellow-400\/75 {
  text-decoration-color: rgb(250 204 21 / 0.75) !important;
}

.tw-decoration-yellow-400\/80 {
  text-decoration-color: rgb(250 204 21 / 0.8) !important;
}

.tw-decoration-yellow-400\/85 {
  text-decoration-color: rgb(250 204 21 / 0.85) !important;
}

.tw-decoration-yellow-400\/90 {
  text-decoration-color: rgb(250 204 21 / 0.9) !important;
}

.tw-decoration-yellow-400\/95 {
  text-decoration-color: rgb(250 204 21 / 0.95) !important;
}

.tw-decoration-yellow-50 {
  text-decoration-color: #fefce8 !important;
}

.tw-decoration-yellow-50\/0 {
  text-decoration-color: rgb(254 252 232 / 0) !important;
}

.tw-decoration-yellow-50\/10 {
  text-decoration-color: rgb(254 252 232 / 0.1) !important;
}

.tw-decoration-yellow-50\/100 {
  text-decoration-color: rgb(254 252 232 / 1) !important;
}

.tw-decoration-yellow-50\/15 {
  text-decoration-color: rgb(254 252 232 / 0.15) !important;
}

.tw-decoration-yellow-50\/20 {
  text-decoration-color: rgb(254 252 232 / 0.2) !important;
}

.tw-decoration-yellow-50\/25 {
  text-decoration-color: rgb(254 252 232 / 0.25) !important;
}

.tw-decoration-yellow-50\/30 {
  text-decoration-color: rgb(254 252 232 / 0.3) !important;
}

.tw-decoration-yellow-50\/35 {
  text-decoration-color: rgb(254 252 232 / 0.35) !important;
}

.tw-decoration-yellow-50\/40 {
  text-decoration-color: rgb(254 252 232 / 0.4) !important;
}

.tw-decoration-yellow-50\/45 {
  text-decoration-color: rgb(254 252 232 / 0.45) !important;
}

.tw-decoration-yellow-50\/5 {
  text-decoration-color: rgb(254 252 232 / 0.05) !important;
}

.tw-decoration-yellow-50\/50 {
  text-decoration-color: rgb(254 252 232 / 0.5) !important;
}

.tw-decoration-yellow-50\/55 {
  text-decoration-color: rgb(254 252 232 / 0.55) !important;
}

.tw-decoration-yellow-50\/60 {
  text-decoration-color: rgb(254 252 232 / 0.6) !important;
}

.tw-decoration-yellow-50\/65 {
  text-decoration-color: rgb(254 252 232 / 0.65) !important;
}

.tw-decoration-yellow-50\/70 {
  text-decoration-color: rgb(254 252 232 / 0.7) !important;
}

.tw-decoration-yellow-50\/75 {
  text-decoration-color: rgb(254 252 232 / 0.75) !important;
}

.tw-decoration-yellow-50\/80 {
  text-decoration-color: rgb(254 252 232 / 0.8) !important;
}

.tw-decoration-yellow-50\/85 {
  text-decoration-color: rgb(254 252 232 / 0.85) !important;
}

.tw-decoration-yellow-50\/90 {
  text-decoration-color: rgb(254 252 232 / 0.9) !important;
}

.tw-decoration-yellow-50\/95 {
  text-decoration-color: rgb(254 252 232 / 0.95) !important;
}

.tw-decoration-yellow-500 {
  text-decoration-color: #eab308 !important;
}

.tw-decoration-yellow-500\/0 {
  text-decoration-color: rgb(234 179 8 / 0) !important;
}

.tw-decoration-yellow-500\/10 {
  text-decoration-color: rgb(234 179 8 / 0.1) !important;
}

.tw-decoration-yellow-500\/100 {
  text-decoration-color: rgb(234 179 8 / 1) !important;
}

.tw-decoration-yellow-500\/15 {
  text-decoration-color: rgb(234 179 8 / 0.15) !important;
}

.tw-decoration-yellow-500\/20 {
  text-decoration-color: rgb(234 179 8 / 0.2) !important;
}

.tw-decoration-yellow-500\/25 {
  text-decoration-color: rgb(234 179 8 / 0.25) !important;
}

.tw-decoration-yellow-500\/30 {
  text-decoration-color: rgb(234 179 8 / 0.3) !important;
}

.tw-decoration-yellow-500\/35 {
  text-decoration-color: rgb(234 179 8 / 0.35) !important;
}

.tw-decoration-yellow-500\/40 {
  text-decoration-color: rgb(234 179 8 / 0.4) !important;
}

.tw-decoration-yellow-500\/45 {
  text-decoration-color: rgb(234 179 8 / 0.45) !important;
}

.tw-decoration-yellow-500\/5 {
  text-decoration-color: rgb(234 179 8 / 0.05) !important;
}

.tw-decoration-yellow-500\/50 {
  text-decoration-color: rgb(234 179 8 / 0.5) !important;
}

.tw-decoration-yellow-500\/55 {
  text-decoration-color: rgb(234 179 8 / 0.55) !important;
}

.tw-decoration-yellow-500\/60 {
  text-decoration-color: rgb(234 179 8 / 0.6) !important;
}

.tw-decoration-yellow-500\/65 {
  text-decoration-color: rgb(234 179 8 / 0.65) !important;
}

.tw-decoration-yellow-500\/70 {
  text-decoration-color: rgb(234 179 8 / 0.7) !important;
}

.tw-decoration-yellow-500\/75 {
  text-decoration-color: rgb(234 179 8 / 0.75) !important;
}

.tw-decoration-yellow-500\/80 {
  text-decoration-color: rgb(234 179 8 / 0.8) !important;
}

.tw-decoration-yellow-500\/85 {
  text-decoration-color: rgb(234 179 8 / 0.85) !important;
}

.tw-decoration-yellow-500\/90 {
  text-decoration-color: rgb(234 179 8 / 0.9) !important;
}

.tw-decoration-yellow-500\/95 {
  text-decoration-color: rgb(234 179 8 / 0.95) !important;
}

.tw-decoration-yellow-600 {
  text-decoration-color: #ca8a04 !important;
}

.tw-decoration-yellow-600\/0 {
  text-decoration-color: rgb(202 138 4 / 0) !important;
}

.tw-decoration-yellow-600\/10 {
  text-decoration-color: rgb(202 138 4 / 0.1) !important;
}

.tw-decoration-yellow-600\/100 {
  text-decoration-color: rgb(202 138 4 / 1) !important;
}

.tw-decoration-yellow-600\/15 {
  text-decoration-color: rgb(202 138 4 / 0.15) !important;
}

.tw-decoration-yellow-600\/20 {
  text-decoration-color: rgb(202 138 4 / 0.2) !important;
}

.tw-decoration-yellow-600\/25 {
  text-decoration-color: rgb(202 138 4 / 0.25) !important;
}

.tw-decoration-yellow-600\/30 {
  text-decoration-color: rgb(202 138 4 / 0.3) !important;
}

.tw-decoration-yellow-600\/35 {
  text-decoration-color: rgb(202 138 4 / 0.35) !important;
}

.tw-decoration-yellow-600\/40 {
  text-decoration-color: rgb(202 138 4 / 0.4) !important;
}

.tw-decoration-yellow-600\/45 {
  text-decoration-color: rgb(202 138 4 / 0.45) !important;
}

.tw-decoration-yellow-600\/5 {
  text-decoration-color: rgb(202 138 4 / 0.05) !important;
}

.tw-decoration-yellow-600\/50 {
  text-decoration-color: rgb(202 138 4 / 0.5) !important;
}

.tw-decoration-yellow-600\/55 {
  text-decoration-color: rgb(202 138 4 / 0.55) !important;
}

.tw-decoration-yellow-600\/60 {
  text-decoration-color: rgb(202 138 4 / 0.6) !important;
}

.tw-decoration-yellow-600\/65 {
  text-decoration-color: rgb(202 138 4 / 0.65) !important;
}

.tw-decoration-yellow-600\/70 {
  text-decoration-color: rgb(202 138 4 / 0.7) !important;
}

.tw-decoration-yellow-600\/75 {
  text-decoration-color: rgb(202 138 4 / 0.75) !important;
}

.tw-decoration-yellow-600\/80 {
  text-decoration-color: rgb(202 138 4 / 0.8) !important;
}

.tw-decoration-yellow-600\/85 {
  text-decoration-color: rgb(202 138 4 / 0.85) !important;
}

.tw-decoration-yellow-600\/90 {
  text-decoration-color: rgb(202 138 4 / 0.9) !important;
}

.tw-decoration-yellow-600\/95 {
  text-decoration-color: rgb(202 138 4 / 0.95) !important;
}

.tw-decoration-yellow-700 {
  text-decoration-color: #a16207 !important;
}

.tw-decoration-yellow-700\/0 {
  text-decoration-color: rgb(161 98 7 / 0) !important;
}

.tw-decoration-yellow-700\/10 {
  text-decoration-color: rgb(161 98 7 / 0.1) !important;
}

.tw-decoration-yellow-700\/100 {
  text-decoration-color: rgb(161 98 7 / 1) !important;
}

.tw-decoration-yellow-700\/15 {
  text-decoration-color: rgb(161 98 7 / 0.15) !important;
}

.tw-decoration-yellow-700\/20 {
  text-decoration-color: rgb(161 98 7 / 0.2) !important;
}

.tw-decoration-yellow-700\/25 {
  text-decoration-color: rgb(161 98 7 / 0.25) !important;
}

.tw-decoration-yellow-700\/30 {
  text-decoration-color: rgb(161 98 7 / 0.3) !important;
}

.tw-decoration-yellow-700\/35 {
  text-decoration-color: rgb(161 98 7 / 0.35) !important;
}

.tw-decoration-yellow-700\/40 {
  text-decoration-color: rgb(161 98 7 / 0.4) !important;
}

.tw-decoration-yellow-700\/45 {
  text-decoration-color: rgb(161 98 7 / 0.45) !important;
}

.tw-decoration-yellow-700\/5 {
  text-decoration-color: rgb(161 98 7 / 0.05) !important;
}

.tw-decoration-yellow-700\/50 {
  text-decoration-color: rgb(161 98 7 / 0.5) !important;
}

.tw-decoration-yellow-700\/55 {
  text-decoration-color: rgb(161 98 7 / 0.55) !important;
}

.tw-decoration-yellow-700\/60 {
  text-decoration-color: rgb(161 98 7 / 0.6) !important;
}

.tw-decoration-yellow-700\/65 {
  text-decoration-color: rgb(161 98 7 / 0.65) !important;
}

.tw-decoration-yellow-700\/70 {
  text-decoration-color: rgb(161 98 7 / 0.7) !important;
}

.tw-decoration-yellow-700\/75 {
  text-decoration-color: rgb(161 98 7 / 0.75) !important;
}

.tw-decoration-yellow-700\/80 {
  text-decoration-color: rgb(161 98 7 / 0.8) !important;
}

.tw-decoration-yellow-700\/85 {
  text-decoration-color: rgb(161 98 7 / 0.85) !important;
}

.tw-decoration-yellow-700\/90 {
  text-decoration-color: rgb(161 98 7 / 0.9) !important;
}

.tw-decoration-yellow-700\/95 {
  text-decoration-color: rgb(161 98 7 / 0.95) !important;
}

.tw-decoration-yellow-800 {
  text-decoration-color: #854d0e !important;
}

.tw-decoration-yellow-800\/0 {
  text-decoration-color: rgb(133 77 14 / 0) !important;
}

.tw-decoration-yellow-800\/10 {
  text-decoration-color: rgb(133 77 14 / 0.1) !important;
}

.tw-decoration-yellow-800\/100 {
  text-decoration-color: rgb(133 77 14 / 1) !important;
}

.tw-decoration-yellow-800\/15 {
  text-decoration-color: rgb(133 77 14 / 0.15) !important;
}

.tw-decoration-yellow-800\/20 {
  text-decoration-color: rgb(133 77 14 / 0.2) !important;
}

.tw-decoration-yellow-800\/25 {
  text-decoration-color: rgb(133 77 14 / 0.25) !important;
}

.tw-decoration-yellow-800\/30 {
  text-decoration-color: rgb(133 77 14 / 0.3) !important;
}

.tw-decoration-yellow-800\/35 {
  text-decoration-color: rgb(133 77 14 / 0.35) !important;
}

.tw-decoration-yellow-800\/40 {
  text-decoration-color: rgb(133 77 14 / 0.4) !important;
}

.tw-decoration-yellow-800\/45 {
  text-decoration-color: rgb(133 77 14 / 0.45) !important;
}

.tw-decoration-yellow-800\/5 {
  text-decoration-color: rgb(133 77 14 / 0.05) !important;
}

.tw-decoration-yellow-800\/50 {
  text-decoration-color: rgb(133 77 14 / 0.5) !important;
}

.tw-decoration-yellow-800\/55 {
  text-decoration-color: rgb(133 77 14 / 0.55) !important;
}

.tw-decoration-yellow-800\/60 {
  text-decoration-color: rgb(133 77 14 / 0.6) !important;
}

.tw-decoration-yellow-800\/65 {
  text-decoration-color: rgb(133 77 14 / 0.65) !important;
}

.tw-decoration-yellow-800\/70 {
  text-decoration-color: rgb(133 77 14 / 0.7) !important;
}

.tw-decoration-yellow-800\/75 {
  text-decoration-color: rgb(133 77 14 / 0.75) !important;
}

.tw-decoration-yellow-800\/80 {
  text-decoration-color: rgb(133 77 14 / 0.8) !important;
}

.tw-decoration-yellow-800\/85 {
  text-decoration-color: rgb(133 77 14 / 0.85) !important;
}

.tw-decoration-yellow-800\/90 {
  text-decoration-color: rgb(133 77 14 / 0.9) !important;
}

.tw-decoration-yellow-800\/95 {
  text-decoration-color: rgb(133 77 14 / 0.95) !important;
}

.tw-decoration-yellow-900 {
  text-decoration-color: #713f12 !important;
}

.tw-decoration-yellow-900\/0 {
  text-decoration-color: rgb(113 63 18 / 0) !important;
}

.tw-decoration-yellow-900\/10 {
  text-decoration-color: rgb(113 63 18 / 0.1) !important;
}

.tw-decoration-yellow-900\/100 {
  text-decoration-color: rgb(113 63 18 / 1) !important;
}

.tw-decoration-yellow-900\/15 {
  text-decoration-color: rgb(113 63 18 / 0.15) !important;
}

.tw-decoration-yellow-900\/20 {
  text-decoration-color: rgb(113 63 18 / 0.2) !important;
}

.tw-decoration-yellow-900\/25 {
  text-decoration-color: rgb(113 63 18 / 0.25) !important;
}

.tw-decoration-yellow-900\/30 {
  text-decoration-color: rgb(113 63 18 / 0.3) !important;
}

.tw-decoration-yellow-900\/35 {
  text-decoration-color: rgb(113 63 18 / 0.35) !important;
}

.tw-decoration-yellow-900\/40 {
  text-decoration-color: rgb(113 63 18 / 0.4) !important;
}

.tw-decoration-yellow-900\/45 {
  text-decoration-color: rgb(113 63 18 / 0.45) !important;
}

.tw-decoration-yellow-900\/5 {
  text-decoration-color: rgb(113 63 18 / 0.05) !important;
}

.tw-decoration-yellow-900\/50 {
  text-decoration-color: rgb(113 63 18 / 0.5) !important;
}

.tw-decoration-yellow-900\/55 {
  text-decoration-color: rgb(113 63 18 / 0.55) !important;
}

.tw-decoration-yellow-900\/60 {
  text-decoration-color: rgb(113 63 18 / 0.6) !important;
}

.tw-decoration-yellow-900\/65 {
  text-decoration-color: rgb(113 63 18 / 0.65) !important;
}

.tw-decoration-yellow-900\/70 {
  text-decoration-color: rgb(113 63 18 / 0.7) !important;
}

.tw-decoration-yellow-900\/75 {
  text-decoration-color: rgb(113 63 18 / 0.75) !important;
}

.tw-decoration-yellow-900\/80 {
  text-decoration-color: rgb(113 63 18 / 0.8) !important;
}

.tw-decoration-yellow-900\/85 {
  text-decoration-color: rgb(113 63 18 / 0.85) !important;
}

.tw-decoration-yellow-900\/90 {
  text-decoration-color: rgb(113 63 18 / 0.9) !important;
}

.tw-decoration-yellow-900\/95 {
  text-decoration-color: rgb(113 63 18 / 0.95) !important;
}

.tw-decoration-yellow-950 {
  text-decoration-color: #422006 !important;
}

.tw-decoration-yellow-950\/0 {
  text-decoration-color: rgb(66 32 6 / 0) !important;
}

.tw-decoration-yellow-950\/10 {
  text-decoration-color: rgb(66 32 6 / 0.1) !important;
}

.tw-decoration-yellow-950\/100 {
  text-decoration-color: rgb(66 32 6 / 1) !important;
}

.tw-decoration-yellow-950\/15 {
  text-decoration-color: rgb(66 32 6 / 0.15) !important;
}

.tw-decoration-yellow-950\/20 {
  text-decoration-color: rgb(66 32 6 / 0.2) !important;
}

.tw-decoration-yellow-950\/25 {
  text-decoration-color: rgb(66 32 6 / 0.25) !important;
}

.tw-decoration-yellow-950\/30 {
  text-decoration-color: rgb(66 32 6 / 0.3) !important;
}

.tw-decoration-yellow-950\/35 {
  text-decoration-color: rgb(66 32 6 / 0.35) !important;
}

.tw-decoration-yellow-950\/40 {
  text-decoration-color: rgb(66 32 6 / 0.4) !important;
}

.tw-decoration-yellow-950\/45 {
  text-decoration-color: rgb(66 32 6 / 0.45) !important;
}

.tw-decoration-yellow-950\/5 {
  text-decoration-color: rgb(66 32 6 / 0.05) !important;
}

.tw-decoration-yellow-950\/50 {
  text-decoration-color: rgb(66 32 6 / 0.5) !important;
}

.tw-decoration-yellow-950\/55 {
  text-decoration-color: rgb(66 32 6 / 0.55) !important;
}

.tw-decoration-yellow-950\/60 {
  text-decoration-color: rgb(66 32 6 / 0.6) !important;
}

.tw-decoration-yellow-950\/65 {
  text-decoration-color: rgb(66 32 6 / 0.65) !important;
}

.tw-decoration-yellow-950\/70 {
  text-decoration-color: rgb(66 32 6 / 0.7) !important;
}

.tw-decoration-yellow-950\/75 {
  text-decoration-color: rgb(66 32 6 / 0.75) !important;
}

.tw-decoration-yellow-950\/80 {
  text-decoration-color: rgb(66 32 6 / 0.8) !important;
}

.tw-decoration-yellow-950\/85 {
  text-decoration-color: rgb(66 32 6 / 0.85) !important;
}

.tw-decoration-yellow-950\/90 {
  text-decoration-color: rgb(66 32 6 / 0.9) !important;
}

.tw-decoration-yellow-950\/95 {
  text-decoration-color: rgb(66 32 6 / 0.95) !important;
}

.tw-decoration-zinc-100 {
  text-decoration-color: #f4f4f5 !important;
}

.tw-decoration-zinc-100\/0 {
  text-decoration-color: rgb(244 244 245 / 0) !important;
}

.tw-decoration-zinc-100\/10 {
  text-decoration-color: rgb(244 244 245 / 0.1) !important;
}

.tw-decoration-zinc-100\/100 {
  text-decoration-color: rgb(244 244 245 / 1) !important;
}

.tw-decoration-zinc-100\/15 {
  text-decoration-color: rgb(244 244 245 / 0.15) !important;
}

.tw-decoration-zinc-100\/20 {
  text-decoration-color: rgb(244 244 245 / 0.2) !important;
}

.tw-decoration-zinc-100\/25 {
  text-decoration-color: rgb(244 244 245 / 0.25) !important;
}

.tw-decoration-zinc-100\/30 {
  text-decoration-color: rgb(244 244 245 / 0.3) !important;
}

.tw-decoration-zinc-100\/35 {
  text-decoration-color: rgb(244 244 245 / 0.35) !important;
}

.tw-decoration-zinc-100\/40 {
  text-decoration-color: rgb(244 244 245 / 0.4) !important;
}

.tw-decoration-zinc-100\/45 {
  text-decoration-color: rgb(244 244 245 / 0.45) !important;
}

.tw-decoration-zinc-100\/5 {
  text-decoration-color: rgb(244 244 245 / 0.05) !important;
}

.tw-decoration-zinc-100\/50 {
  text-decoration-color: rgb(244 244 245 / 0.5) !important;
}

.tw-decoration-zinc-100\/55 {
  text-decoration-color: rgb(244 244 245 / 0.55) !important;
}

.tw-decoration-zinc-100\/60 {
  text-decoration-color: rgb(244 244 245 / 0.6) !important;
}

.tw-decoration-zinc-100\/65 {
  text-decoration-color: rgb(244 244 245 / 0.65) !important;
}

.tw-decoration-zinc-100\/70 {
  text-decoration-color: rgb(244 244 245 / 0.7) !important;
}

.tw-decoration-zinc-100\/75 {
  text-decoration-color: rgb(244 244 245 / 0.75) !important;
}

.tw-decoration-zinc-100\/80 {
  text-decoration-color: rgb(244 244 245 / 0.8) !important;
}

.tw-decoration-zinc-100\/85 {
  text-decoration-color: rgb(244 244 245 / 0.85) !important;
}

.tw-decoration-zinc-100\/90 {
  text-decoration-color: rgb(244 244 245 / 0.9) !important;
}

.tw-decoration-zinc-100\/95 {
  text-decoration-color: rgb(244 244 245 / 0.95) !important;
}

.tw-decoration-zinc-200 {
  text-decoration-color: #e4e4e7 !important;
}

.tw-decoration-zinc-200\/0 {
  text-decoration-color: rgb(228 228 231 / 0) !important;
}

.tw-decoration-zinc-200\/10 {
  text-decoration-color: rgb(228 228 231 / 0.1) !important;
}

.tw-decoration-zinc-200\/100 {
  text-decoration-color: rgb(228 228 231 / 1) !important;
}

.tw-decoration-zinc-200\/15 {
  text-decoration-color: rgb(228 228 231 / 0.15) !important;
}

.tw-decoration-zinc-200\/20 {
  text-decoration-color: rgb(228 228 231 / 0.2) !important;
}

.tw-decoration-zinc-200\/25 {
  text-decoration-color: rgb(228 228 231 / 0.25) !important;
}

.tw-decoration-zinc-200\/30 {
  text-decoration-color: rgb(228 228 231 / 0.3) !important;
}

.tw-decoration-zinc-200\/35 {
  text-decoration-color: rgb(228 228 231 / 0.35) !important;
}

.tw-decoration-zinc-200\/40 {
  text-decoration-color: rgb(228 228 231 / 0.4) !important;
}

.tw-decoration-zinc-200\/45 {
  text-decoration-color: rgb(228 228 231 / 0.45) !important;
}

.tw-decoration-zinc-200\/5 {
  text-decoration-color: rgb(228 228 231 / 0.05) !important;
}

.tw-decoration-zinc-200\/50 {
  text-decoration-color: rgb(228 228 231 / 0.5) !important;
}

.tw-decoration-zinc-200\/55 {
  text-decoration-color: rgb(228 228 231 / 0.55) !important;
}

.tw-decoration-zinc-200\/60 {
  text-decoration-color: rgb(228 228 231 / 0.6) !important;
}

.tw-decoration-zinc-200\/65 {
  text-decoration-color: rgb(228 228 231 / 0.65) !important;
}

.tw-decoration-zinc-200\/70 {
  text-decoration-color: rgb(228 228 231 / 0.7) !important;
}

.tw-decoration-zinc-200\/75 {
  text-decoration-color: rgb(228 228 231 / 0.75) !important;
}

.tw-decoration-zinc-200\/80 {
  text-decoration-color: rgb(228 228 231 / 0.8) !important;
}

.tw-decoration-zinc-200\/85 {
  text-decoration-color: rgb(228 228 231 / 0.85) !important;
}

.tw-decoration-zinc-200\/90 {
  text-decoration-color: rgb(228 228 231 / 0.9) !important;
}

.tw-decoration-zinc-200\/95 {
  text-decoration-color: rgb(228 228 231 / 0.95) !important;
}

.tw-decoration-zinc-300 {
  text-decoration-color: #d4d4d8 !important;
}

.tw-decoration-zinc-300\/0 {
  text-decoration-color: rgb(212 212 216 / 0) !important;
}

.tw-decoration-zinc-300\/10 {
  text-decoration-color: rgb(212 212 216 / 0.1) !important;
}

.tw-decoration-zinc-300\/100 {
  text-decoration-color: rgb(212 212 216 / 1) !important;
}

.tw-decoration-zinc-300\/15 {
  text-decoration-color: rgb(212 212 216 / 0.15) !important;
}

.tw-decoration-zinc-300\/20 {
  text-decoration-color: rgb(212 212 216 / 0.2) !important;
}

.tw-decoration-zinc-300\/25 {
  text-decoration-color: rgb(212 212 216 / 0.25) !important;
}

.tw-decoration-zinc-300\/30 {
  text-decoration-color: rgb(212 212 216 / 0.3) !important;
}

.tw-decoration-zinc-300\/35 {
  text-decoration-color: rgb(212 212 216 / 0.35) !important;
}

.tw-decoration-zinc-300\/40 {
  text-decoration-color: rgb(212 212 216 / 0.4) !important;
}

.tw-decoration-zinc-300\/45 {
  text-decoration-color: rgb(212 212 216 / 0.45) !important;
}

.tw-decoration-zinc-300\/5 {
  text-decoration-color: rgb(212 212 216 / 0.05) !important;
}

.tw-decoration-zinc-300\/50 {
  text-decoration-color: rgb(212 212 216 / 0.5) !important;
}

.tw-decoration-zinc-300\/55 {
  text-decoration-color: rgb(212 212 216 / 0.55) !important;
}

.tw-decoration-zinc-300\/60 {
  text-decoration-color: rgb(212 212 216 / 0.6) !important;
}

.tw-decoration-zinc-300\/65 {
  text-decoration-color: rgb(212 212 216 / 0.65) !important;
}

.tw-decoration-zinc-300\/70 {
  text-decoration-color: rgb(212 212 216 / 0.7) !important;
}

.tw-decoration-zinc-300\/75 {
  text-decoration-color: rgb(212 212 216 / 0.75) !important;
}

.tw-decoration-zinc-300\/80 {
  text-decoration-color: rgb(212 212 216 / 0.8) !important;
}

.tw-decoration-zinc-300\/85 {
  text-decoration-color: rgb(212 212 216 / 0.85) !important;
}

.tw-decoration-zinc-300\/90 {
  text-decoration-color: rgb(212 212 216 / 0.9) !important;
}

.tw-decoration-zinc-300\/95 {
  text-decoration-color: rgb(212 212 216 / 0.95) !important;
}

.tw-decoration-zinc-400 {
  text-decoration-color: #a1a1aa !important;
}

.tw-decoration-zinc-400\/0 {
  text-decoration-color: rgb(161 161 170 / 0) !important;
}

.tw-decoration-zinc-400\/10 {
  text-decoration-color: rgb(161 161 170 / 0.1) !important;
}

.tw-decoration-zinc-400\/100 {
  text-decoration-color: rgb(161 161 170 / 1) !important;
}

.tw-decoration-zinc-400\/15 {
  text-decoration-color: rgb(161 161 170 / 0.15) !important;
}

.tw-decoration-zinc-400\/20 {
  text-decoration-color: rgb(161 161 170 / 0.2) !important;
}

.tw-decoration-zinc-400\/25 {
  text-decoration-color: rgb(161 161 170 / 0.25) !important;
}

.tw-decoration-zinc-400\/30 {
  text-decoration-color: rgb(161 161 170 / 0.3) !important;
}

.tw-decoration-zinc-400\/35 {
  text-decoration-color: rgb(161 161 170 / 0.35) !important;
}

.tw-decoration-zinc-400\/40 {
  text-decoration-color: rgb(161 161 170 / 0.4) !important;
}

.tw-decoration-zinc-400\/45 {
  text-decoration-color: rgb(161 161 170 / 0.45) !important;
}

.tw-decoration-zinc-400\/5 {
  text-decoration-color: rgb(161 161 170 / 0.05) !important;
}

.tw-decoration-zinc-400\/50 {
  text-decoration-color: rgb(161 161 170 / 0.5) !important;
}

.tw-decoration-zinc-400\/55 {
  text-decoration-color: rgb(161 161 170 / 0.55) !important;
}

.tw-decoration-zinc-400\/60 {
  text-decoration-color: rgb(161 161 170 / 0.6) !important;
}

.tw-decoration-zinc-400\/65 {
  text-decoration-color: rgb(161 161 170 / 0.65) !important;
}

.tw-decoration-zinc-400\/70 {
  text-decoration-color: rgb(161 161 170 / 0.7) !important;
}

.tw-decoration-zinc-400\/75 {
  text-decoration-color: rgb(161 161 170 / 0.75) !important;
}

.tw-decoration-zinc-400\/80 {
  text-decoration-color: rgb(161 161 170 / 0.8) !important;
}

.tw-decoration-zinc-400\/85 {
  text-decoration-color: rgb(161 161 170 / 0.85) !important;
}

.tw-decoration-zinc-400\/90 {
  text-decoration-color: rgb(161 161 170 / 0.9) !important;
}

.tw-decoration-zinc-400\/95 {
  text-decoration-color: rgb(161 161 170 / 0.95) !important;
}

.tw-decoration-zinc-50 {
  text-decoration-color: #fafafa !important;
}

.tw-decoration-zinc-50\/0 {
  text-decoration-color: rgb(250 250 250 / 0) !important;
}

.tw-decoration-zinc-50\/10 {
  text-decoration-color: rgb(250 250 250 / 0.1) !important;
}

.tw-decoration-zinc-50\/100 {
  text-decoration-color: rgb(250 250 250 / 1) !important;
}

.tw-decoration-zinc-50\/15 {
  text-decoration-color: rgb(250 250 250 / 0.15) !important;
}

.tw-decoration-zinc-50\/20 {
  text-decoration-color: rgb(250 250 250 / 0.2) !important;
}

.tw-decoration-zinc-50\/25 {
  text-decoration-color: rgb(250 250 250 / 0.25) !important;
}

.tw-decoration-zinc-50\/30 {
  text-decoration-color: rgb(250 250 250 / 0.3) !important;
}

.tw-decoration-zinc-50\/35 {
  text-decoration-color: rgb(250 250 250 / 0.35) !important;
}

.tw-decoration-zinc-50\/40 {
  text-decoration-color: rgb(250 250 250 / 0.4) !important;
}

.tw-decoration-zinc-50\/45 {
  text-decoration-color: rgb(250 250 250 / 0.45) !important;
}

.tw-decoration-zinc-50\/5 {
  text-decoration-color: rgb(250 250 250 / 0.05) !important;
}

.tw-decoration-zinc-50\/50 {
  text-decoration-color: rgb(250 250 250 / 0.5) !important;
}

.tw-decoration-zinc-50\/55 {
  text-decoration-color: rgb(250 250 250 / 0.55) !important;
}

.tw-decoration-zinc-50\/60 {
  text-decoration-color: rgb(250 250 250 / 0.6) !important;
}

.tw-decoration-zinc-50\/65 {
  text-decoration-color: rgb(250 250 250 / 0.65) !important;
}

.tw-decoration-zinc-50\/70 {
  text-decoration-color: rgb(250 250 250 / 0.7) !important;
}

.tw-decoration-zinc-50\/75 {
  text-decoration-color: rgb(250 250 250 / 0.75) !important;
}

.tw-decoration-zinc-50\/80 {
  text-decoration-color: rgb(250 250 250 / 0.8) !important;
}

.tw-decoration-zinc-50\/85 {
  text-decoration-color: rgb(250 250 250 / 0.85) !important;
}

.tw-decoration-zinc-50\/90 {
  text-decoration-color: rgb(250 250 250 / 0.9) !important;
}

.tw-decoration-zinc-50\/95 {
  text-decoration-color: rgb(250 250 250 / 0.95) !important;
}

.tw-decoration-zinc-500 {
  text-decoration-color: #71717a !important;
}

.tw-decoration-zinc-500\/0 {
  text-decoration-color: rgb(113 113 122 / 0) !important;
}

.tw-decoration-zinc-500\/10 {
  text-decoration-color: rgb(113 113 122 / 0.1) !important;
}

.tw-decoration-zinc-500\/100 {
  text-decoration-color: rgb(113 113 122 / 1) !important;
}

.tw-decoration-zinc-500\/15 {
  text-decoration-color: rgb(113 113 122 / 0.15) !important;
}

.tw-decoration-zinc-500\/20 {
  text-decoration-color: rgb(113 113 122 / 0.2) !important;
}

.tw-decoration-zinc-500\/25 {
  text-decoration-color: rgb(113 113 122 / 0.25) !important;
}

.tw-decoration-zinc-500\/30 {
  text-decoration-color: rgb(113 113 122 / 0.3) !important;
}

.tw-decoration-zinc-500\/35 {
  text-decoration-color: rgb(113 113 122 / 0.35) !important;
}

.tw-decoration-zinc-500\/40 {
  text-decoration-color: rgb(113 113 122 / 0.4) !important;
}

.tw-decoration-zinc-500\/45 {
  text-decoration-color: rgb(113 113 122 / 0.45) !important;
}

.tw-decoration-zinc-500\/5 {
  text-decoration-color: rgb(113 113 122 / 0.05) !important;
}

.tw-decoration-zinc-500\/50 {
  text-decoration-color: rgb(113 113 122 / 0.5) !important;
}

.tw-decoration-zinc-500\/55 {
  text-decoration-color: rgb(113 113 122 / 0.55) !important;
}

.tw-decoration-zinc-500\/60 {
  text-decoration-color: rgb(113 113 122 / 0.6) !important;
}

.tw-decoration-zinc-500\/65 {
  text-decoration-color: rgb(113 113 122 / 0.65) !important;
}

.tw-decoration-zinc-500\/70 {
  text-decoration-color: rgb(113 113 122 / 0.7) !important;
}

.tw-decoration-zinc-500\/75 {
  text-decoration-color: rgb(113 113 122 / 0.75) !important;
}

.tw-decoration-zinc-500\/80 {
  text-decoration-color: rgb(113 113 122 / 0.8) !important;
}

.tw-decoration-zinc-500\/85 {
  text-decoration-color: rgb(113 113 122 / 0.85) !important;
}

.tw-decoration-zinc-500\/90 {
  text-decoration-color: rgb(113 113 122 / 0.9) !important;
}

.tw-decoration-zinc-500\/95 {
  text-decoration-color: rgb(113 113 122 / 0.95) !important;
}

.tw-decoration-zinc-600 {
  text-decoration-color: #52525b !important;
}

.tw-decoration-zinc-600\/0 {
  text-decoration-color: rgb(82 82 91 / 0) !important;
}

.tw-decoration-zinc-600\/10 {
  text-decoration-color: rgb(82 82 91 / 0.1) !important;
}

.tw-decoration-zinc-600\/100 {
  text-decoration-color: rgb(82 82 91 / 1) !important;
}

.tw-decoration-zinc-600\/15 {
  text-decoration-color: rgb(82 82 91 / 0.15) !important;
}

.tw-decoration-zinc-600\/20 {
  text-decoration-color: rgb(82 82 91 / 0.2) !important;
}

.tw-decoration-zinc-600\/25 {
  text-decoration-color: rgb(82 82 91 / 0.25) !important;
}

.tw-decoration-zinc-600\/30 {
  text-decoration-color: rgb(82 82 91 / 0.3) !important;
}

.tw-decoration-zinc-600\/35 {
  text-decoration-color: rgb(82 82 91 / 0.35) !important;
}

.tw-decoration-zinc-600\/40 {
  text-decoration-color: rgb(82 82 91 / 0.4) !important;
}

.tw-decoration-zinc-600\/45 {
  text-decoration-color: rgb(82 82 91 / 0.45) !important;
}

.tw-decoration-zinc-600\/5 {
  text-decoration-color: rgb(82 82 91 / 0.05) !important;
}

.tw-decoration-zinc-600\/50 {
  text-decoration-color: rgb(82 82 91 / 0.5) !important;
}

.tw-decoration-zinc-600\/55 {
  text-decoration-color: rgb(82 82 91 / 0.55) !important;
}

.tw-decoration-zinc-600\/60 {
  text-decoration-color: rgb(82 82 91 / 0.6) !important;
}

.tw-decoration-zinc-600\/65 {
  text-decoration-color: rgb(82 82 91 / 0.65) !important;
}

.tw-decoration-zinc-600\/70 {
  text-decoration-color: rgb(82 82 91 / 0.7) !important;
}

.tw-decoration-zinc-600\/75 {
  text-decoration-color: rgb(82 82 91 / 0.75) !important;
}

.tw-decoration-zinc-600\/80 {
  text-decoration-color: rgb(82 82 91 / 0.8) !important;
}

.tw-decoration-zinc-600\/85 {
  text-decoration-color: rgb(82 82 91 / 0.85) !important;
}

.tw-decoration-zinc-600\/90 {
  text-decoration-color: rgb(82 82 91 / 0.9) !important;
}

.tw-decoration-zinc-600\/95 {
  text-decoration-color: rgb(82 82 91 / 0.95) !important;
}

.tw-decoration-zinc-700 {
  text-decoration-color: #3f3f46 !important;
}

.tw-decoration-zinc-700\/0 {
  text-decoration-color: rgb(63 63 70 / 0) !important;
}

.tw-decoration-zinc-700\/10 {
  text-decoration-color: rgb(63 63 70 / 0.1) !important;
}

.tw-decoration-zinc-700\/100 {
  text-decoration-color: rgb(63 63 70 / 1) !important;
}

.tw-decoration-zinc-700\/15 {
  text-decoration-color: rgb(63 63 70 / 0.15) !important;
}

.tw-decoration-zinc-700\/20 {
  text-decoration-color: rgb(63 63 70 / 0.2) !important;
}

.tw-decoration-zinc-700\/25 {
  text-decoration-color: rgb(63 63 70 / 0.25) !important;
}

.tw-decoration-zinc-700\/30 {
  text-decoration-color: rgb(63 63 70 / 0.3) !important;
}

.tw-decoration-zinc-700\/35 {
  text-decoration-color: rgb(63 63 70 / 0.35) !important;
}

.tw-decoration-zinc-700\/40 {
  text-decoration-color: rgb(63 63 70 / 0.4) !important;
}

.tw-decoration-zinc-700\/45 {
  text-decoration-color: rgb(63 63 70 / 0.45) !important;
}

.tw-decoration-zinc-700\/5 {
  text-decoration-color: rgb(63 63 70 / 0.05) !important;
}

.tw-decoration-zinc-700\/50 {
  text-decoration-color: rgb(63 63 70 / 0.5) !important;
}

.tw-decoration-zinc-700\/55 {
  text-decoration-color: rgb(63 63 70 / 0.55) !important;
}

.tw-decoration-zinc-700\/60 {
  text-decoration-color: rgb(63 63 70 / 0.6) !important;
}

.tw-decoration-zinc-700\/65 {
  text-decoration-color: rgb(63 63 70 / 0.65) !important;
}

.tw-decoration-zinc-700\/70 {
  text-decoration-color: rgb(63 63 70 / 0.7) !important;
}

.tw-decoration-zinc-700\/75 {
  text-decoration-color: rgb(63 63 70 / 0.75) !important;
}

.tw-decoration-zinc-700\/80 {
  text-decoration-color: rgb(63 63 70 / 0.8) !important;
}

.tw-decoration-zinc-700\/85 {
  text-decoration-color: rgb(63 63 70 / 0.85) !important;
}

.tw-decoration-zinc-700\/90 {
  text-decoration-color: rgb(63 63 70 / 0.9) !important;
}

.tw-decoration-zinc-700\/95 {
  text-decoration-color: rgb(63 63 70 / 0.95) !important;
}

.tw-decoration-zinc-800 {
  text-decoration-color: #27272a !important;
}

.tw-decoration-zinc-800\/0 {
  text-decoration-color: rgb(39 39 42 / 0) !important;
}

.tw-decoration-zinc-800\/10 {
  text-decoration-color: rgb(39 39 42 / 0.1) !important;
}

.tw-decoration-zinc-800\/100 {
  text-decoration-color: rgb(39 39 42 / 1) !important;
}

.tw-decoration-zinc-800\/15 {
  text-decoration-color: rgb(39 39 42 / 0.15) !important;
}

.tw-decoration-zinc-800\/20 {
  text-decoration-color: rgb(39 39 42 / 0.2) !important;
}

.tw-decoration-zinc-800\/25 {
  text-decoration-color: rgb(39 39 42 / 0.25) !important;
}

.tw-decoration-zinc-800\/30 {
  text-decoration-color: rgb(39 39 42 / 0.3) !important;
}

.tw-decoration-zinc-800\/35 {
  text-decoration-color: rgb(39 39 42 / 0.35) !important;
}

.tw-decoration-zinc-800\/40 {
  text-decoration-color: rgb(39 39 42 / 0.4) !important;
}

.tw-decoration-zinc-800\/45 {
  text-decoration-color: rgb(39 39 42 / 0.45) !important;
}

.tw-decoration-zinc-800\/5 {
  text-decoration-color: rgb(39 39 42 / 0.05) !important;
}

.tw-decoration-zinc-800\/50 {
  text-decoration-color: rgb(39 39 42 / 0.5) !important;
}

.tw-decoration-zinc-800\/55 {
  text-decoration-color: rgb(39 39 42 / 0.55) !important;
}

.tw-decoration-zinc-800\/60 {
  text-decoration-color: rgb(39 39 42 / 0.6) !important;
}

.tw-decoration-zinc-800\/65 {
  text-decoration-color: rgb(39 39 42 / 0.65) !important;
}

.tw-decoration-zinc-800\/70 {
  text-decoration-color: rgb(39 39 42 / 0.7) !important;
}

.tw-decoration-zinc-800\/75 {
  text-decoration-color: rgb(39 39 42 / 0.75) !important;
}

.tw-decoration-zinc-800\/80 {
  text-decoration-color: rgb(39 39 42 / 0.8) !important;
}

.tw-decoration-zinc-800\/85 {
  text-decoration-color: rgb(39 39 42 / 0.85) !important;
}

.tw-decoration-zinc-800\/90 {
  text-decoration-color: rgb(39 39 42 / 0.9) !important;
}

.tw-decoration-zinc-800\/95 {
  text-decoration-color: rgb(39 39 42 / 0.95) !important;
}

.tw-decoration-zinc-900 {
  text-decoration-color: #18181b !important;
}

.tw-decoration-zinc-900\/0 {
  text-decoration-color: rgb(24 24 27 / 0) !important;
}

.tw-decoration-zinc-900\/10 {
  text-decoration-color: rgb(24 24 27 / 0.1) !important;
}

.tw-decoration-zinc-900\/100 {
  text-decoration-color: rgb(24 24 27 / 1) !important;
}

.tw-decoration-zinc-900\/15 {
  text-decoration-color: rgb(24 24 27 / 0.15) !important;
}

.tw-decoration-zinc-900\/20 {
  text-decoration-color: rgb(24 24 27 / 0.2) !important;
}

.tw-decoration-zinc-900\/25 {
  text-decoration-color: rgb(24 24 27 / 0.25) !important;
}

.tw-decoration-zinc-900\/30 {
  text-decoration-color: rgb(24 24 27 / 0.3) !important;
}

.tw-decoration-zinc-900\/35 {
  text-decoration-color: rgb(24 24 27 / 0.35) !important;
}

.tw-decoration-zinc-900\/40 {
  text-decoration-color: rgb(24 24 27 / 0.4) !important;
}

.tw-decoration-zinc-900\/45 {
  text-decoration-color: rgb(24 24 27 / 0.45) !important;
}

.tw-decoration-zinc-900\/5 {
  text-decoration-color: rgb(24 24 27 / 0.05) !important;
}

.tw-decoration-zinc-900\/50 {
  text-decoration-color: rgb(24 24 27 / 0.5) !important;
}

.tw-decoration-zinc-900\/55 {
  text-decoration-color: rgb(24 24 27 / 0.55) !important;
}

.tw-decoration-zinc-900\/60 {
  text-decoration-color: rgb(24 24 27 / 0.6) !important;
}

.tw-decoration-zinc-900\/65 {
  text-decoration-color: rgb(24 24 27 / 0.65) !important;
}

.tw-decoration-zinc-900\/70 {
  text-decoration-color: rgb(24 24 27 / 0.7) !important;
}

.tw-decoration-zinc-900\/75 {
  text-decoration-color: rgb(24 24 27 / 0.75) !important;
}

.tw-decoration-zinc-900\/80 {
  text-decoration-color: rgb(24 24 27 / 0.8) !important;
}

.tw-decoration-zinc-900\/85 {
  text-decoration-color: rgb(24 24 27 / 0.85) !important;
}

.tw-decoration-zinc-900\/90 {
  text-decoration-color: rgb(24 24 27 / 0.9) !important;
}

.tw-decoration-zinc-900\/95 {
  text-decoration-color: rgb(24 24 27 / 0.95) !important;
}

.tw-decoration-zinc-950 {
  text-decoration-color: #09090b !important;
}

.tw-decoration-zinc-950\/0 {
  text-decoration-color: rgb(9 9 11 / 0) !important;
}

.tw-decoration-zinc-950\/10 {
  text-decoration-color: rgb(9 9 11 / 0.1) !important;
}

.tw-decoration-zinc-950\/100 {
  text-decoration-color: rgb(9 9 11 / 1) !important;
}

.tw-decoration-zinc-950\/15 {
  text-decoration-color: rgb(9 9 11 / 0.15) !important;
}

.tw-decoration-zinc-950\/20 {
  text-decoration-color: rgb(9 9 11 / 0.2) !important;
}

.tw-decoration-zinc-950\/25 {
  text-decoration-color: rgb(9 9 11 / 0.25) !important;
}

.tw-decoration-zinc-950\/30 {
  text-decoration-color: rgb(9 9 11 / 0.3) !important;
}

.tw-decoration-zinc-950\/35 {
  text-decoration-color: rgb(9 9 11 / 0.35) !important;
}

.tw-decoration-zinc-950\/40 {
  text-decoration-color: rgb(9 9 11 / 0.4) !important;
}

.tw-decoration-zinc-950\/45 {
  text-decoration-color: rgb(9 9 11 / 0.45) !important;
}

.tw-decoration-zinc-950\/5 {
  text-decoration-color: rgb(9 9 11 / 0.05) !important;
}

.tw-decoration-zinc-950\/50 {
  text-decoration-color: rgb(9 9 11 / 0.5) !important;
}

.tw-decoration-zinc-950\/55 {
  text-decoration-color: rgb(9 9 11 / 0.55) !important;
}

.tw-decoration-zinc-950\/60 {
  text-decoration-color: rgb(9 9 11 / 0.6) !important;
}

.tw-decoration-zinc-950\/65 {
  text-decoration-color: rgb(9 9 11 / 0.65) !important;
}

.tw-decoration-zinc-950\/70 {
  text-decoration-color: rgb(9 9 11 / 0.7) !important;
}

.tw-decoration-zinc-950\/75 {
  text-decoration-color: rgb(9 9 11 / 0.75) !important;
}

.tw-decoration-zinc-950\/80 {
  text-decoration-color: rgb(9 9 11 / 0.8) !important;
}

.tw-decoration-zinc-950\/85 {
  text-decoration-color: rgb(9 9 11 / 0.85) !important;
}

.tw-decoration-zinc-950\/90 {
  text-decoration-color: rgb(9 9 11 / 0.9) !important;
}

.tw-decoration-zinc-950\/95 {
  text-decoration-color: rgb(9 9 11 / 0.95) !important;
}

.tw-decoration-solid {
  text-decoration-style: solid !important;
}

.tw-decoration-double {
  text-decoration-style: double !important;
}

.tw-decoration-dotted {
  text-decoration-style: dotted !important;
}

.tw-decoration-dashed {
  text-decoration-style: dashed !important;
}

.tw-decoration-wavy {
  text-decoration-style: wavy !important;
}

.tw-decoration-0 {
  text-decoration-thickness: 0px !important;
}

.tw-decoration-1 {
  text-decoration-thickness: 1px !important;
}

.tw-decoration-2 {
  text-decoration-thickness: 2px !important;
}

.tw-decoration-4 {
  text-decoration-thickness: 4px !important;
}

.tw-decoration-8 {
  text-decoration-thickness: 8px !important;
}

.tw-decoration-auto {
  text-decoration-thickness: auto !important;
}

.tw-decoration-from-font {
  text-decoration-thickness: from-font !important;
}

.tw-underline-offset-0 {
  text-underline-offset: 0px !important;
}

.tw-underline-offset-1 {
  text-underline-offset: 1px !important;
}

.tw-underline-offset-2 {
  text-underline-offset: 2px !important;
}

.tw-underline-offset-4 {
  text-underline-offset: 4px !important;
}

.tw-underline-offset-8 {
  text-underline-offset: 8px !important;
}

.tw-underline-offset-auto {
  text-underline-offset: auto !important;
}

.tw-antialiased {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.tw-subpixel-antialiased {
  -webkit-font-smoothing: auto !important;
  -moz-osx-font-smoothing: auto !important;
}

.tw-placeholder-amber-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 243 199 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-100\/0::placeholder {
  color: rgb(254 243 199 / 0) !important;
}

.tw-placeholder-amber-100\/10::placeholder {
  color: rgb(254 243 199 / 0.1) !important;
}

.tw-placeholder-amber-100\/100::placeholder {
  color: rgb(254 243 199 / 1) !important;
}

.tw-placeholder-amber-100\/15::placeholder {
  color: rgb(254 243 199 / 0.15) !important;
}

.tw-placeholder-amber-100\/20::placeholder {
  color: rgb(254 243 199 / 0.2) !important;
}

.tw-placeholder-amber-100\/25::placeholder {
  color: rgb(254 243 199 / 0.25) !important;
}

.tw-placeholder-amber-100\/30::placeholder {
  color: rgb(254 243 199 / 0.3) !important;
}

.tw-placeholder-amber-100\/35::placeholder {
  color: rgb(254 243 199 / 0.35) !important;
}

.tw-placeholder-amber-100\/40::placeholder {
  color: rgb(254 243 199 / 0.4) !important;
}

.tw-placeholder-amber-100\/45::placeholder {
  color: rgb(254 243 199 / 0.45) !important;
}

.tw-placeholder-amber-100\/5::placeholder {
  color: rgb(254 243 199 / 0.05) !important;
}

.tw-placeholder-amber-100\/50::placeholder {
  color: rgb(254 243 199 / 0.5) !important;
}

.tw-placeholder-amber-100\/55::placeholder {
  color: rgb(254 243 199 / 0.55) !important;
}

.tw-placeholder-amber-100\/60::placeholder {
  color: rgb(254 243 199 / 0.6) !important;
}

.tw-placeholder-amber-100\/65::placeholder {
  color: rgb(254 243 199 / 0.65) !important;
}

.tw-placeholder-amber-100\/70::placeholder {
  color: rgb(254 243 199 / 0.7) !important;
}

.tw-placeholder-amber-100\/75::placeholder {
  color: rgb(254 243 199 / 0.75) !important;
}

.tw-placeholder-amber-100\/80::placeholder {
  color: rgb(254 243 199 / 0.8) !important;
}

.tw-placeholder-amber-100\/85::placeholder {
  color: rgb(254 243 199 / 0.85) !important;
}

.tw-placeholder-amber-100\/90::placeholder {
  color: rgb(254 243 199 / 0.9) !important;
}

.tw-placeholder-amber-100\/95::placeholder {
  color: rgb(254 243 199 / 0.95) !important;
}

.tw-placeholder-amber-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(253 230 138 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-200\/0::placeholder {
  color: rgb(253 230 138 / 0) !important;
}

.tw-placeholder-amber-200\/10::placeholder {
  color: rgb(253 230 138 / 0.1) !important;
}

.tw-placeholder-amber-200\/100::placeholder {
  color: rgb(253 230 138 / 1) !important;
}

.tw-placeholder-amber-200\/15::placeholder {
  color: rgb(253 230 138 / 0.15) !important;
}

.tw-placeholder-amber-200\/20::placeholder {
  color: rgb(253 230 138 / 0.2) !important;
}

.tw-placeholder-amber-200\/25::placeholder {
  color: rgb(253 230 138 / 0.25) !important;
}

.tw-placeholder-amber-200\/30::placeholder {
  color: rgb(253 230 138 / 0.3) !important;
}

.tw-placeholder-amber-200\/35::placeholder {
  color: rgb(253 230 138 / 0.35) !important;
}

.tw-placeholder-amber-200\/40::placeholder {
  color: rgb(253 230 138 / 0.4) !important;
}

.tw-placeholder-amber-200\/45::placeholder {
  color: rgb(253 230 138 / 0.45) !important;
}

.tw-placeholder-amber-200\/5::placeholder {
  color: rgb(253 230 138 / 0.05) !important;
}

.tw-placeholder-amber-200\/50::placeholder {
  color: rgb(253 230 138 / 0.5) !important;
}

.tw-placeholder-amber-200\/55::placeholder {
  color: rgb(253 230 138 / 0.55) !important;
}

.tw-placeholder-amber-200\/60::placeholder {
  color: rgb(253 230 138 / 0.6) !important;
}

.tw-placeholder-amber-200\/65::placeholder {
  color: rgb(253 230 138 / 0.65) !important;
}

.tw-placeholder-amber-200\/70::placeholder {
  color: rgb(253 230 138 / 0.7) !important;
}

.tw-placeholder-amber-200\/75::placeholder {
  color: rgb(253 230 138 / 0.75) !important;
}

.tw-placeholder-amber-200\/80::placeholder {
  color: rgb(253 230 138 / 0.8) !important;
}

.tw-placeholder-amber-200\/85::placeholder {
  color: rgb(253 230 138 / 0.85) !important;
}

.tw-placeholder-amber-200\/90::placeholder {
  color: rgb(253 230 138 / 0.9) !important;
}

.tw-placeholder-amber-200\/95::placeholder {
  color: rgb(253 230 138 / 0.95) !important;
}

.tw-placeholder-amber-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(252 211 77 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-300\/0::placeholder {
  color: rgb(252 211 77 / 0) !important;
}

.tw-placeholder-amber-300\/10::placeholder {
  color: rgb(252 211 77 / 0.1) !important;
}

.tw-placeholder-amber-300\/100::placeholder {
  color: rgb(252 211 77 / 1) !important;
}

.tw-placeholder-amber-300\/15::placeholder {
  color: rgb(252 211 77 / 0.15) !important;
}

.tw-placeholder-amber-300\/20::placeholder {
  color: rgb(252 211 77 / 0.2) !important;
}

.tw-placeholder-amber-300\/25::placeholder {
  color: rgb(252 211 77 / 0.25) !important;
}

.tw-placeholder-amber-300\/30::placeholder {
  color: rgb(252 211 77 / 0.3) !important;
}

.tw-placeholder-amber-300\/35::placeholder {
  color: rgb(252 211 77 / 0.35) !important;
}

.tw-placeholder-amber-300\/40::placeholder {
  color: rgb(252 211 77 / 0.4) !important;
}

.tw-placeholder-amber-300\/45::placeholder {
  color: rgb(252 211 77 / 0.45) !important;
}

.tw-placeholder-amber-300\/5::placeholder {
  color: rgb(252 211 77 / 0.05) !important;
}

.tw-placeholder-amber-300\/50::placeholder {
  color: rgb(252 211 77 / 0.5) !important;
}

.tw-placeholder-amber-300\/55::placeholder {
  color: rgb(252 211 77 / 0.55) !important;
}

.tw-placeholder-amber-300\/60::placeholder {
  color: rgb(252 211 77 / 0.6) !important;
}

.tw-placeholder-amber-300\/65::placeholder {
  color: rgb(252 211 77 / 0.65) !important;
}

.tw-placeholder-amber-300\/70::placeholder {
  color: rgb(252 211 77 / 0.7) !important;
}

.tw-placeholder-amber-300\/75::placeholder {
  color: rgb(252 211 77 / 0.75) !important;
}

.tw-placeholder-amber-300\/80::placeholder {
  color: rgb(252 211 77 / 0.8) !important;
}

.tw-placeholder-amber-300\/85::placeholder {
  color: rgb(252 211 77 / 0.85) !important;
}

.tw-placeholder-amber-300\/90::placeholder {
  color: rgb(252 211 77 / 0.9) !important;
}

.tw-placeholder-amber-300\/95::placeholder {
  color: rgb(252 211 77 / 0.95) !important;
}

.tw-placeholder-amber-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(251 191 36 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-400\/0::placeholder {
  color: rgb(251 191 36 / 0) !important;
}

.tw-placeholder-amber-400\/10::placeholder {
  color: rgb(251 191 36 / 0.1) !important;
}

.tw-placeholder-amber-400\/100::placeholder {
  color: rgb(251 191 36 / 1) !important;
}

.tw-placeholder-amber-400\/15::placeholder {
  color: rgb(251 191 36 / 0.15) !important;
}

.tw-placeholder-amber-400\/20::placeholder {
  color: rgb(251 191 36 / 0.2) !important;
}

.tw-placeholder-amber-400\/25::placeholder {
  color: rgb(251 191 36 / 0.25) !important;
}

.tw-placeholder-amber-400\/30::placeholder {
  color: rgb(251 191 36 / 0.3) !important;
}

.tw-placeholder-amber-400\/35::placeholder {
  color: rgb(251 191 36 / 0.35) !important;
}

.tw-placeholder-amber-400\/40::placeholder {
  color: rgb(251 191 36 / 0.4) !important;
}

.tw-placeholder-amber-400\/45::placeholder {
  color: rgb(251 191 36 / 0.45) !important;
}

.tw-placeholder-amber-400\/5::placeholder {
  color: rgb(251 191 36 / 0.05) !important;
}

.tw-placeholder-amber-400\/50::placeholder {
  color: rgb(251 191 36 / 0.5) !important;
}

.tw-placeholder-amber-400\/55::placeholder {
  color: rgb(251 191 36 / 0.55) !important;
}

.tw-placeholder-amber-400\/60::placeholder {
  color: rgb(251 191 36 / 0.6) !important;
}

.tw-placeholder-amber-400\/65::placeholder {
  color: rgb(251 191 36 / 0.65) !important;
}

.tw-placeholder-amber-400\/70::placeholder {
  color: rgb(251 191 36 / 0.7) !important;
}

.tw-placeholder-amber-400\/75::placeholder {
  color: rgb(251 191 36 / 0.75) !important;
}

.tw-placeholder-amber-400\/80::placeholder {
  color: rgb(251 191 36 / 0.8) !important;
}

.tw-placeholder-amber-400\/85::placeholder {
  color: rgb(251 191 36 / 0.85) !important;
}

.tw-placeholder-amber-400\/90::placeholder {
  color: rgb(251 191 36 / 0.9) !important;
}

.tw-placeholder-amber-400\/95::placeholder {
  color: rgb(251 191 36 / 0.95) !important;
}

.tw-placeholder-amber-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(255 251 235 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-50\/0::placeholder {
  color: rgb(255 251 235 / 0) !important;
}

.tw-placeholder-amber-50\/10::placeholder {
  color: rgb(255 251 235 / 0.1) !important;
}

.tw-placeholder-amber-50\/100::placeholder {
  color: rgb(255 251 235 / 1) !important;
}

.tw-placeholder-amber-50\/15::placeholder {
  color: rgb(255 251 235 / 0.15) !important;
}

.tw-placeholder-amber-50\/20::placeholder {
  color: rgb(255 251 235 / 0.2) !important;
}

.tw-placeholder-amber-50\/25::placeholder {
  color: rgb(255 251 235 / 0.25) !important;
}

.tw-placeholder-amber-50\/30::placeholder {
  color: rgb(255 251 235 / 0.3) !important;
}

.tw-placeholder-amber-50\/35::placeholder {
  color: rgb(255 251 235 / 0.35) !important;
}

.tw-placeholder-amber-50\/40::placeholder {
  color: rgb(255 251 235 / 0.4) !important;
}

.tw-placeholder-amber-50\/45::placeholder {
  color: rgb(255 251 235 / 0.45) !important;
}

.tw-placeholder-amber-50\/5::placeholder {
  color: rgb(255 251 235 / 0.05) !important;
}

.tw-placeholder-amber-50\/50::placeholder {
  color: rgb(255 251 235 / 0.5) !important;
}

.tw-placeholder-amber-50\/55::placeholder {
  color: rgb(255 251 235 / 0.55) !important;
}

.tw-placeholder-amber-50\/60::placeholder {
  color: rgb(255 251 235 / 0.6) !important;
}

.tw-placeholder-amber-50\/65::placeholder {
  color: rgb(255 251 235 / 0.65) !important;
}

.tw-placeholder-amber-50\/70::placeholder {
  color: rgb(255 251 235 / 0.7) !important;
}

.tw-placeholder-amber-50\/75::placeholder {
  color: rgb(255 251 235 / 0.75) !important;
}

.tw-placeholder-amber-50\/80::placeholder {
  color: rgb(255 251 235 / 0.8) !important;
}

.tw-placeholder-amber-50\/85::placeholder {
  color: rgb(255 251 235 / 0.85) !important;
}

.tw-placeholder-amber-50\/90::placeholder {
  color: rgb(255 251 235 / 0.9) !important;
}

.tw-placeholder-amber-50\/95::placeholder {
  color: rgb(255 251 235 / 0.95) !important;
}

.tw-placeholder-amber-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(245 158 11 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-500\/0::placeholder {
  color: rgb(245 158 11 / 0) !important;
}

.tw-placeholder-amber-500\/10::placeholder {
  color: rgb(245 158 11 / 0.1) !important;
}

.tw-placeholder-amber-500\/100::placeholder {
  color: rgb(245 158 11 / 1) !important;
}

.tw-placeholder-amber-500\/15::placeholder {
  color: rgb(245 158 11 / 0.15) !important;
}

.tw-placeholder-amber-500\/20::placeholder {
  color: rgb(245 158 11 / 0.2) !important;
}

.tw-placeholder-amber-500\/25::placeholder {
  color: rgb(245 158 11 / 0.25) !important;
}

.tw-placeholder-amber-500\/30::placeholder {
  color: rgb(245 158 11 / 0.3) !important;
}

.tw-placeholder-amber-500\/35::placeholder {
  color: rgb(245 158 11 / 0.35) !important;
}

.tw-placeholder-amber-500\/40::placeholder {
  color: rgb(245 158 11 / 0.4) !important;
}

.tw-placeholder-amber-500\/45::placeholder {
  color: rgb(245 158 11 / 0.45) !important;
}

.tw-placeholder-amber-500\/5::placeholder {
  color: rgb(245 158 11 / 0.05) !important;
}

.tw-placeholder-amber-500\/50::placeholder {
  color: rgb(245 158 11 / 0.5) !important;
}

.tw-placeholder-amber-500\/55::placeholder {
  color: rgb(245 158 11 / 0.55) !important;
}

.tw-placeholder-amber-500\/60::placeholder {
  color: rgb(245 158 11 / 0.6) !important;
}

.tw-placeholder-amber-500\/65::placeholder {
  color: rgb(245 158 11 / 0.65) !important;
}

.tw-placeholder-amber-500\/70::placeholder {
  color: rgb(245 158 11 / 0.7) !important;
}

.tw-placeholder-amber-500\/75::placeholder {
  color: rgb(245 158 11 / 0.75) !important;
}

.tw-placeholder-amber-500\/80::placeholder {
  color: rgb(245 158 11 / 0.8) !important;
}

.tw-placeholder-amber-500\/85::placeholder {
  color: rgb(245 158 11 / 0.85) !important;
}

.tw-placeholder-amber-500\/90::placeholder {
  color: rgb(245 158 11 / 0.9) !important;
}

.tw-placeholder-amber-500\/95::placeholder {
  color: rgb(245 158 11 / 0.95) !important;
}

.tw-placeholder-amber-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(217 119 6 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-600\/0::placeholder {
  color: rgb(217 119 6 / 0) !important;
}

.tw-placeholder-amber-600\/10::placeholder {
  color: rgb(217 119 6 / 0.1) !important;
}

.tw-placeholder-amber-600\/100::placeholder {
  color: rgb(217 119 6 / 1) !important;
}

.tw-placeholder-amber-600\/15::placeholder {
  color: rgb(217 119 6 / 0.15) !important;
}

.tw-placeholder-amber-600\/20::placeholder {
  color: rgb(217 119 6 / 0.2) !important;
}

.tw-placeholder-amber-600\/25::placeholder {
  color: rgb(217 119 6 / 0.25) !important;
}

.tw-placeholder-amber-600\/30::placeholder {
  color: rgb(217 119 6 / 0.3) !important;
}

.tw-placeholder-amber-600\/35::placeholder {
  color: rgb(217 119 6 / 0.35) !important;
}

.tw-placeholder-amber-600\/40::placeholder {
  color: rgb(217 119 6 / 0.4) !important;
}

.tw-placeholder-amber-600\/45::placeholder {
  color: rgb(217 119 6 / 0.45) !important;
}

.tw-placeholder-amber-600\/5::placeholder {
  color: rgb(217 119 6 / 0.05) !important;
}

.tw-placeholder-amber-600\/50::placeholder {
  color: rgb(217 119 6 / 0.5) !important;
}

.tw-placeholder-amber-600\/55::placeholder {
  color: rgb(217 119 6 / 0.55) !important;
}

.tw-placeholder-amber-600\/60::placeholder {
  color: rgb(217 119 6 / 0.6) !important;
}

.tw-placeholder-amber-600\/65::placeholder {
  color: rgb(217 119 6 / 0.65) !important;
}

.tw-placeholder-amber-600\/70::placeholder {
  color: rgb(217 119 6 / 0.7) !important;
}

.tw-placeholder-amber-600\/75::placeholder {
  color: rgb(217 119 6 / 0.75) !important;
}

.tw-placeholder-amber-600\/80::placeholder {
  color: rgb(217 119 6 / 0.8) !important;
}

.tw-placeholder-amber-600\/85::placeholder {
  color: rgb(217 119 6 / 0.85) !important;
}

.tw-placeholder-amber-600\/90::placeholder {
  color: rgb(217 119 6 / 0.9) !important;
}

.tw-placeholder-amber-600\/95::placeholder {
  color: rgb(217 119 6 / 0.95) !important;
}

.tw-placeholder-amber-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(180 83 9 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-700\/0::placeholder {
  color: rgb(180 83 9 / 0) !important;
}

.tw-placeholder-amber-700\/10::placeholder {
  color: rgb(180 83 9 / 0.1) !important;
}

.tw-placeholder-amber-700\/100::placeholder {
  color: rgb(180 83 9 / 1) !important;
}

.tw-placeholder-amber-700\/15::placeholder {
  color: rgb(180 83 9 / 0.15) !important;
}

.tw-placeholder-amber-700\/20::placeholder {
  color: rgb(180 83 9 / 0.2) !important;
}

.tw-placeholder-amber-700\/25::placeholder {
  color: rgb(180 83 9 / 0.25) !important;
}

.tw-placeholder-amber-700\/30::placeholder {
  color: rgb(180 83 9 / 0.3) !important;
}

.tw-placeholder-amber-700\/35::placeholder {
  color: rgb(180 83 9 / 0.35) !important;
}

.tw-placeholder-amber-700\/40::placeholder {
  color: rgb(180 83 9 / 0.4) !important;
}

.tw-placeholder-amber-700\/45::placeholder {
  color: rgb(180 83 9 / 0.45) !important;
}

.tw-placeholder-amber-700\/5::placeholder {
  color: rgb(180 83 9 / 0.05) !important;
}

.tw-placeholder-amber-700\/50::placeholder {
  color: rgb(180 83 9 / 0.5) !important;
}

.tw-placeholder-amber-700\/55::placeholder {
  color: rgb(180 83 9 / 0.55) !important;
}

.tw-placeholder-amber-700\/60::placeholder {
  color: rgb(180 83 9 / 0.6) !important;
}

.tw-placeholder-amber-700\/65::placeholder {
  color: rgb(180 83 9 / 0.65) !important;
}

.tw-placeholder-amber-700\/70::placeholder {
  color: rgb(180 83 9 / 0.7) !important;
}

.tw-placeholder-amber-700\/75::placeholder {
  color: rgb(180 83 9 / 0.75) !important;
}

.tw-placeholder-amber-700\/80::placeholder {
  color: rgb(180 83 9 / 0.8) !important;
}

.tw-placeholder-amber-700\/85::placeholder {
  color: rgb(180 83 9 / 0.85) !important;
}

.tw-placeholder-amber-700\/90::placeholder {
  color: rgb(180 83 9 / 0.9) !important;
}

.tw-placeholder-amber-700\/95::placeholder {
  color: rgb(180 83 9 / 0.95) !important;
}

.tw-placeholder-amber-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(146 64 14 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-800\/0::placeholder {
  color: rgb(146 64 14 / 0) !important;
}

.tw-placeholder-amber-800\/10::placeholder {
  color: rgb(146 64 14 / 0.1) !important;
}

.tw-placeholder-amber-800\/100::placeholder {
  color: rgb(146 64 14 / 1) !important;
}

.tw-placeholder-amber-800\/15::placeholder {
  color: rgb(146 64 14 / 0.15) !important;
}

.tw-placeholder-amber-800\/20::placeholder {
  color: rgb(146 64 14 / 0.2) !important;
}

.tw-placeholder-amber-800\/25::placeholder {
  color: rgb(146 64 14 / 0.25) !important;
}

.tw-placeholder-amber-800\/30::placeholder {
  color: rgb(146 64 14 / 0.3) !important;
}

.tw-placeholder-amber-800\/35::placeholder {
  color: rgb(146 64 14 / 0.35) !important;
}

.tw-placeholder-amber-800\/40::placeholder {
  color: rgb(146 64 14 / 0.4) !important;
}

.tw-placeholder-amber-800\/45::placeholder {
  color: rgb(146 64 14 / 0.45) !important;
}

.tw-placeholder-amber-800\/5::placeholder {
  color: rgb(146 64 14 / 0.05) !important;
}

.tw-placeholder-amber-800\/50::placeholder {
  color: rgb(146 64 14 / 0.5) !important;
}

.tw-placeholder-amber-800\/55::placeholder {
  color: rgb(146 64 14 / 0.55) !important;
}

.tw-placeholder-amber-800\/60::placeholder {
  color: rgb(146 64 14 / 0.6) !important;
}

.tw-placeholder-amber-800\/65::placeholder {
  color: rgb(146 64 14 / 0.65) !important;
}

.tw-placeholder-amber-800\/70::placeholder {
  color: rgb(146 64 14 / 0.7) !important;
}

.tw-placeholder-amber-800\/75::placeholder {
  color: rgb(146 64 14 / 0.75) !important;
}

.tw-placeholder-amber-800\/80::placeholder {
  color: rgb(146 64 14 / 0.8) !important;
}

.tw-placeholder-amber-800\/85::placeholder {
  color: rgb(146 64 14 / 0.85) !important;
}

.tw-placeholder-amber-800\/90::placeholder {
  color: rgb(146 64 14 / 0.9) !important;
}

.tw-placeholder-amber-800\/95::placeholder {
  color: rgb(146 64 14 / 0.95) !important;
}

.tw-placeholder-amber-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(120 53 15 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-900\/0::placeholder {
  color: rgb(120 53 15 / 0) !important;
}

.tw-placeholder-amber-900\/10::placeholder {
  color: rgb(120 53 15 / 0.1) !important;
}

.tw-placeholder-amber-900\/100::placeholder {
  color: rgb(120 53 15 / 1) !important;
}

.tw-placeholder-amber-900\/15::placeholder {
  color: rgb(120 53 15 / 0.15) !important;
}

.tw-placeholder-amber-900\/20::placeholder {
  color: rgb(120 53 15 / 0.2) !important;
}

.tw-placeholder-amber-900\/25::placeholder {
  color: rgb(120 53 15 / 0.25) !important;
}

.tw-placeholder-amber-900\/30::placeholder {
  color: rgb(120 53 15 / 0.3) !important;
}

.tw-placeholder-amber-900\/35::placeholder {
  color: rgb(120 53 15 / 0.35) !important;
}

.tw-placeholder-amber-900\/40::placeholder {
  color: rgb(120 53 15 / 0.4) !important;
}

.tw-placeholder-amber-900\/45::placeholder {
  color: rgb(120 53 15 / 0.45) !important;
}

.tw-placeholder-amber-900\/5::placeholder {
  color: rgb(120 53 15 / 0.05) !important;
}

.tw-placeholder-amber-900\/50::placeholder {
  color: rgb(120 53 15 / 0.5) !important;
}

.tw-placeholder-amber-900\/55::placeholder {
  color: rgb(120 53 15 / 0.55) !important;
}

.tw-placeholder-amber-900\/60::placeholder {
  color: rgb(120 53 15 / 0.6) !important;
}

.tw-placeholder-amber-900\/65::placeholder {
  color: rgb(120 53 15 / 0.65) !important;
}

.tw-placeholder-amber-900\/70::placeholder {
  color: rgb(120 53 15 / 0.7) !important;
}

.tw-placeholder-amber-900\/75::placeholder {
  color: rgb(120 53 15 / 0.75) !important;
}

.tw-placeholder-amber-900\/80::placeholder {
  color: rgb(120 53 15 / 0.8) !important;
}

.tw-placeholder-amber-900\/85::placeholder {
  color: rgb(120 53 15 / 0.85) !important;
}

.tw-placeholder-amber-900\/90::placeholder {
  color: rgb(120 53 15 / 0.9) !important;
}

.tw-placeholder-amber-900\/95::placeholder {
  color: rgb(120 53 15 / 0.95) !important;
}

.tw-placeholder-amber-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(69 26 3 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-amber-950\/0::placeholder {
  color: rgb(69 26 3 / 0) !important;
}

.tw-placeholder-amber-950\/10::placeholder {
  color: rgb(69 26 3 / 0.1) !important;
}

.tw-placeholder-amber-950\/100::placeholder {
  color: rgb(69 26 3 / 1) !important;
}

.tw-placeholder-amber-950\/15::placeholder {
  color: rgb(69 26 3 / 0.15) !important;
}

.tw-placeholder-amber-950\/20::placeholder {
  color: rgb(69 26 3 / 0.2) !important;
}

.tw-placeholder-amber-950\/25::placeholder {
  color: rgb(69 26 3 / 0.25) !important;
}

.tw-placeholder-amber-950\/30::placeholder {
  color: rgb(69 26 3 / 0.3) !important;
}

.tw-placeholder-amber-950\/35::placeholder {
  color: rgb(69 26 3 / 0.35) !important;
}

.tw-placeholder-amber-950\/40::placeholder {
  color: rgb(69 26 3 / 0.4) !important;
}

.tw-placeholder-amber-950\/45::placeholder {
  color: rgb(69 26 3 / 0.45) !important;
}

.tw-placeholder-amber-950\/5::placeholder {
  color: rgb(69 26 3 / 0.05) !important;
}

.tw-placeholder-amber-950\/50::placeholder {
  color: rgb(69 26 3 / 0.5) !important;
}

.tw-placeholder-amber-950\/55::placeholder {
  color: rgb(69 26 3 / 0.55) !important;
}

.tw-placeholder-amber-950\/60::placeholder {
  color: rgb(69 26 3 / 0.6) !important;
}

.tw-placeholder-amber-950\/65::placeholder {
  color: rgb(69 26 3 / 0.65) !important;
}

.tw-placeholder-amber-950\/70::placeholder {
  color: rgb(69 26 3 / 0.7) !important;
}

.tw-placeholder-amber-950\/75::placeholder {
  color: rgb(69 26 3 / 0.75) !important;
}

.tw-placeholder-amber-950\/80::placeholder {
  color: rgb(69 26 3 / 0.8) !important;
}

.tw-placeholder-amber-950\/85::placeholder {
  color: rgb(69 26 3 / 0.85) !important;
}

.tw-placeholder-amber-950\/90::placeholder {
  color: rgb(69 26 3 / 0.9) !important;
}

.tw-placeholder-amber-950\/95::placeholder {
  color: rgb(69 26 3 / 0.95) !important;
}

.tw-placeholder-black::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(0 0 0 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-black\/0::placeholder {
  color: rgb(0 0 0 / 0) !important;
}

.tw-placeholder-black\/10::placeholder {
  color: rgb(0 0 0 / 0.1) !important;
}

.tw-placeholder-black\/100::placeholder {
  color: rgb(0 0 0 / 1) !important;
}

.tw-placeholder-black\/15::placeholder {
  color: rgb(0 0 0 / 0.15) !important;
}

.tw-placeholder-black\/20::placeholder {
  color: rgb(0 0 0 / 0.2) !important;
}

.tw-placeholder-black\/25::placeholder {
  color: rgb(0 0 0 / 0.25) !important;
}

.tw-placeholder-black\/30::placeholder {
  color: rgb(0 0 0 / 0.3) !important;
}

.tw-placeholder-black\/35::placeholder {
  color: rgb(0 0 0 / 0.35) !important;
}

.tw-placeholder-black\/40::placeholder {
  color: rgb(0 0 0 / 0.4) !important;
}

.tw-placeholder-black\/45::placeholder {
  color: rgb(0 0 0 / 0.45) !important;
}

.tw-placeholder-black\/5::placeholder {
  color: rgb(0 0 0 / 0.05) !important;
}

.tw-placeholder-black\/50::placeholder {
  color: rgb(0 0 0 / 0.5) !important;
}

.tw-placeholder-black\/55::placeholder {
  color: rgb(0 0 0 / 0.55) !important;
}

.tw-placeholder-black\/60::placeholder {
  color: rgb(0 0 0 / 0.6) !important;
}

.tw-placeholder-black\/65::placeholder {
  color: rgb(0 0 0 / 0.65) !important;
}

.tw-placeholder-black\/70::placeholder {
  color: rgb(0 0 0 / 0.7) !important;
}

.tw-placeholder-black\/75::placeholder {
  color: rgb(0 0 0 / 0.75) !important;
}

.tw-placeholder-black\/80::placeholder {
  color: rgb(0 0 0 / 0.8) !important;
}

.tw-placeholder-black\/85::placeholder {
  color: rgb(0 0 0 / 0.85) !important;
}

.tw-placeholder-black\/90::placeholder {
  color: rgb(0 0 0 / 0.9) !important;
}

.tw-placeholder-black\/95::placeholder {
  color: rgb(0 0 0 / 0.95) !important;
}

.tw-placeholder-blue-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(219 234 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-100\/0::placeholder {
  color: rgb(219 234 254 / 0) !important;
}

.tw-placeholder-blue-100\/10::placeholder {
  color: rgb(219 234 254 / 0.1) !important;
}

.tw-placeholder-blue-100\/100::placeholder {
  color: rgb(219 234 254 / 1) !important;
}

.tw-placeholder-blue-100\/15::placeholder {
  color: rgb(219 234 254 / 0.15) !important;
}

.tw-placeholder-blue-100\/20::placeholder {
  color: rgb(219 234 254 / 0.2) !important;
}

.tw-placeholder-blue-100\/25::placeholder {
  color: rgb(219 234 254 / 0.25) !important;
}

.tw-placeholder-blue-100\/30::placeholder {
  color: rgb(219 234 254 / 0.3) !important;
}

.tw-placeholder-blue-100\/35::placeholder {
  color: rgb(219 234 254 / 0.35) !important;
}

.tw-placeholder-blue-100\/40::placeholder {
  color: rgb(219 234 254 / 0.4) !important;
}

.tw-placeholder-blue-100\/45::placeholder {
  color: rgb(219 234 254 / 0.45) !important;
}

.tw-placeholder-blue-100\/5::placeholder {
  color: rgb(219 234 254 / 0.05) !important;
}

.tw-placeholder-blue-100\/50::placeholder {
  color: rgb(219 234 254 / 0.5) !important;
}

.tw-placeholder-blue-100\/55::placeholder {
  color: rgb(219 234 254 / 0.55) !important;
}

.tw-placeholder-blue-100\/60::placeholder {
  color: rgb(219 234 254 / 0.6) !important;
}

.tw-placeholder-blue-100\/65::placeholder {
  color: rgb(219 234 254 / 0.65) !important;
}

.tw-placeholder-blue-100\/70::placeholder {
  color: rgb(219 234 254 / 0.7) !important;
}

.tw-placeholder-blue-100\/75::placeholder {
  color: rgb(219 234 254 / 0.75) !important;
}

.tw-placeholder-blue-100\/80::placeholder {
  color: rgb(219 234 254 / 0.8) !important;
}

.tw-placeholder-blue-100\/85::placeholder {
  color: rgb(219 234 254 / 0.85) !important;
}

.tw-placeholder-blue-100\/90::placeholder {
  color: rgb(219 234 254 / 0.9) !important;
}

.tw-placeholder-blue-100\/95::placeholder {
  color: rgb(219 234 254 / 0.95) !important;
}

.tw-placeholder-blue-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(191 219 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-200\/0::placeholder {
  color: rgb(191 219 254 / 0) !important;
}

.tw-placeholder-blue-200\/10::placeholder {
  color: rgb(191 219 254 / 0.1) !important;
}

.tw-placeholder-blue-200\/100::placeholder {
  color: rgb(191 219 254 / 1) !important;
}

.tw-placeholder-blue-200\/15::placeholder {
  color: rgb(191 219 254 / 0.15) !important;
}

.tw-placeholder-blue-200\/20::placeholder {
  color: rgb(191 219 254 / 0.2) !important;
}

.tw-placeholder-blue-200\/25::placeholder {
  color: rgb(191 219 254 / 0.25) !important;
}

.tw-placeholder-blue-200\/30::placeholder {
  color: rgb(191 219 254 / 0.3) !important;
}

.tw-placeholder-blue-200\/35::placeholder {
  color: rgb(191 219 254 / 0.35) !important;
}

.tw-placeholder-blue-200\/40::placeholder {
  color: rgb(191 219 254 / 0.4) !important;
}

.tw-placeholder-blue-200\/45::placeholder {
  color: rgb(191 219 254 / 0.45) !important;
}

.tw-placeholder-blue-200\/5::placeholder {
  color: rgb(191 219 254 / 0.05) !important;
}

.tw-placeholder-blue-200\/50::placeholder {
  color: rgb(191 219 254 / 0.5) !important;
}

.tw-placeholder-blue-200\/55::placeholder {
  color: rgb(191 219 254 / 0.55) !important;
}

.tw-placeholder-blue-200\/60::placeholder {
  color: rgb(191 219 254 / 0.6) !important;
}

.tw-placeholder-blue-200\/65::placeholder {
  color: rgb(191 219 254 / 0.65) !important;
}

.tw-placeholder-blue-200\/70::placeholder {
  color: rgb(191 219 254 / 0.7) !important;
}

.tw-placeholder-blue-200\/75::placeholder {
  color: rgb(191 219 254 / 0.75) !important;
}

.tw-placeholder-blue-200\/80::placeholder {
  color: rgb(191 219 254 / 0.8) !important;
}

.tw-placeholder-blue-200\/85::placeholder {
  color: rgb(191 219 254 / 0.85) !important;
}

.tw-placeholder-blue-200\/90::placeholder {
  color: rgb(191 219 254 / 0.9) !important;
}

.tw-placeholder-blue-200\/95::placeholder {
  color: rgb(191 219 254 / 0.95) !important;
}

.tw-placeholder-blue-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(147 197 253 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-300\/0::placeholder {
  color: rgb(147 197 253 / 0) !important;
}

.tw-placeholder-blue-300\/10::placeholder {
  color: rgb(147 197 253 / 0.1) !important;
}

.tw-placeholder-blue-300\/100::placeholder {
  color: rgb(147 197 253 / 1) !important;
}

.tw-placeholder-blue-300\/15::placeholder {
  color: rgb(147 197 253 / 0.15) !important;
}

.tw-placeholder-blue-300\/20::placeholder {
  color: rgb(147 197 253 / 0.2) !important;
}

.tw-placeholder-blue-300\/25::placeholder {
  color: rgb(147 197 253 / 0.25) !important;
}

.tw-placeholder-blue-300\/30::placeholder {
  color: rgb(147 197 253 / 0.3) !important;
}

.tw-placeholder-blue-300\/35::placeholder {
  color: rgb(147 197 253 / 0.35) !important;
}

.tw-placeholder-blue-300\/40::placeholder {
  color: rgb(147 197 253 / 0.4) !important;
}

.tw-placeholder-blue-300\/45::placeholder {
  color: rgb(147 197 253 / 0.45) !important;
}

.tw-placeholder-blue-300\/5::placeholder {
  color: rgb(147 197 253 / 0.05) !important;
}

.tw-placeholder-blue-300\/50::placeholder {
  color: rgb(147 197 253 / 0.5) !important;
}

.tw-placeholder-blue-300\/55::placeholder {
  color: rgb(147 197 253 / 0.55) !important;
}

.tw-placeholder-blue-300\/60::placeholder {
  color: rgb(147 197 253 / 0.6) !important;
}

.tw-placeholder-blue-300\/65::placeholder {
  color: rgb(147 197 253 / 0.65) !important;
}

.tw-placeholder-blue-300\/70::placeholder {
  color: rgb(147 197 253 / 0.7) !important;
}

.tw-placeholder-blue-300\/75::placeholder {
  color: rgb(147 197 253 / 0.75) !important;
}

.tw-placeholder-blue-300\/80::placeholder {
  color: rgb(147 197 253 / 0.8) !important;
}

.tw-placeholder-blue-300\/85::placeholder {
  color: rgb(147 197 253 / 0.85) !important;
}

.tw-placeholder-blue-300\/90::placeholder {
  color: rgb(147 197 253 / 0.9) !important;
}

.tw-placeholder-blue-300\/95::placeholder {
  color: rgb(147 197 253 / 0.95) !important;
}

.tw-placeholder-blue-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(96 165 250 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-400\/0::placeholder {
  color: rgb(96 165 250 / 0) !important;
}

.tw-placeholder-blue-400\/10::placeholder {
  color: rgb(96 165 250 / 0.1) !important;
}

.tw-placeholder-blue-400\/100::placeholder {
  color: rgb(96 165 250 / 1) !important;
}

.tw-placeholder-blue-400\/15::placeholder {
  color: rgb(96 165 250 / 0.15) !important;
}

.tw-placeholder-blue-400\/20::placeholder {
  color: rgb(96 165 250 / 0.2) !important;
}

.tw-placeholder-blue-400\/25::placeholder {
  color: rgb(96 165 250 / 0.25) !important;
}

.tw-placeholder-blue-400\/30::placeholder {
  color: rgb(96 165 250 / 0.3) !important;
}

.tw-placeholder-blue-400\/35::placeholder {
  color: rgb(96 165 250 / 0.35) !important;
}

.tw-placeholder-blue-400\/40::placeholder {
  color: rgb(96 165 250 / 0.4) !important;
}

.tw-placeholder-blue-400\/45::placeholder {
  color: rgb(96 165 250 / 0.45) !important;
}

.tw-placeholder-blue-400\/5::placeholder {
  color: rgb(96 165 250 / 0.05) !important;
}

.tw-placeholder-blue-400\/50::placeholder {
  color: rgb(96 165 250 / 0.5) !important;
}

.tw-placeholder-blue-400\/55::placeholder {
  color: rgb(96 165 250 / 0.55) !important;
}

.tw-placeholder-blue-400\/60::placeholder {
  color: rgb(96 165 250 / 0.6) !important;
}

.tw-placeholder-blue-400\/65::placeholder {
  color: rgb(96 165 250 / 0.65) !important;
}

.tw-placeholder-blue-400\/70::placeholder {
  color: rgb(96 165 250 / 0.7) !important;
}

.tw-placeholder-blue-400\/75::placeholder {
  color: rgb(96 165 250 / 0.75) !important;
}

.tw-placeholder-blue-400\/80::placeholder {
  color: rgb(96 165 250 / 0.8) !important;
}

.tw-placeholder-blue-400\/85::placeholder {
  color: rgb(96 165 250 / 0.85) !important;
}

.tw-placeholder-blue-400\/90::placeholder {
  color: rgb(96 165 250 / 0.9) !important;
}

.tw-placeholder-blue-400\/95::placeholder {
  color: rgb(96 165 250 / 0.95) !important;
}

.tw-placeholder-blue-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(239 246 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-50\/0::placeholder {
  color: rgb(239 246 255 / 0) !important;
}

.tw-placeholder-blue-50\/10::placeholder {
  color: rgb(239 246 255 / 0.1) !important;
}

.tw-placeholder-blue-50\/100::placeholder {
  color: rgb(239 246 255 / 1) !important;
}

.tw-placeholder-blue-50\/15::placeholder {
  color: rgb(239 246 255 / 0.15) !important;
}

.tw-placeholder-blue-50\/20::placeholder {
  color: rgb(239 246 255 / 0.2) !important;
}

.tw-placeholder-blue-50\/25::placeholder {
  color: rgb(239 246 255 / 0.25) !important;
}

.tw-placeholder-blue-50\/30::placeholder {
  color: rgb(239 246 255 / 0.3) !important;
}

.tw-placeholder-blue-50\/35::placeholder {
  color: rgb(239 246 255 / 0.35) !important;
}

.tw-placeholder-blue-50\/40::placeholder {
  color: rgb(239 246 255 / 0.4) !important;
}

.tw-placeholder-blue-50\/45::placeholder {
  color: rgb(239 246 255 / 0.45) !important;
}

.tw-placeholder-blue-50\/5::placeholder {
  color: rgb(239 246 255 / 0.05) !important;
}

.tw-placeholder-blue-50\/50::placeholder {
  color: rgb(239 246 255 / 0.5) !important;
}

.tw-placeholder-blue-50\/55::placeholder {
  color: rgb(239 246 255 / 0.55) !important;
}

.tw-placeholder-blue-50\/60::placeholder {
  color: rgb(239 246 255 / 0.6) !important;
}

.tw-placeholder-blue-50\/65::placeholder {
  color: rgb(239 246 255 / 0.65) !important;
}

.tw-placeholder-blue-50\/70::placeholder {
  color: rgb(239 246 255 / 0.7) !important;
}

.tw-placeholder-blue-50\/75::placeholder {
  color: rgb(239 246 255 / 0.75) !important;
}

.tw-placeholder-blue-50\/80::placeholder {
  color: rgb(239 246 255 / 0.8) !important;
}

.tw-placeholder-blue-50\/85::placeholder {
  color: rgb(239 246 255 / 0.85) !important;
}

.tw-placeholder-blue-50\/90::placeholder {
  color: rgb(239 246 255 / 0.9) !important;
}

.tw-placeholder-blue-50\/95::placeholder {
  color: rgb(239 246 255 / 0.95) !important;
}

.tw-placeholder-blue-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(59 130 246 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-500\/0::placeholder {
  color: rgb(59 130 246 / 0) !important;
}

.tw-placeholder-blue-500\/10::placeholder {
  color: rgb(59 130 246 / 0.1) !important;
}

.tw-placeholder-blue-500\/100::placeholder {
  color: rgb(59 130 246 / 1) !important;
}

.tw-placeholder-blue-500\/15::placeholder {
  color: rgb(59 130 246 / 0.15) !important;
}

.tw-placeholder-blue-500\/20::placeholder {
  color: rgb(59 130 246 / 0.2) !important;
}

.tw-placeholder-blue-500\/25::placeholder {
  color: rgb(59 130 246 / 0.25) !important;
}

.tw-placeholder-blue-500\/30::placeholder {
  color: rgb(59 130 246 / 0.3) !important;
}

.tw-placeholder-blue-500\/35::placeholder {
  color: rgb(59 130 246 / 0.35) !important;
}

.tw-placeholder-blue-500\/40::placeholder {
  color: rgb(59 130 246 / 0.4) !important;
}

.tw-placeholder-blue-500\/45::placeholder {
  color: rgb(59 130 246 / 0.45) !important;
}

.tw-placeholder-blue-500\/5::placeholder {
  color: rgb(59 130 246 / 0.05) !important;
}

.tw-placeholder-blue-500\/50::placeholder {
  color: rgb(59 130 246 / 0.5) !important;
}

.tw-placeholder-blue-500\/55::placeholder {
  color: rgb(59 130 246 / 0.55) !important;
}

.tw-placeholder-blue-500\/60::placeholder {
  color: rgb(59 130 246 / 0.6) !important;
}

.tw-placeholder-blue-500\/65::placeholder {
  color: rgb(59 130 246 / 0.65) !important;
}

.tw-placeholder-blue-500\/70::placeholder {
  color: rgb(59 130 246 / 0.7) !important;
}

.tw-placeholder-blue-500\/75::placeholder {
  color: rgb(59 130 246 / 0.75) !important;
}

.tw-placeholder-blue-500\/80::placeholder {
  color: rgb(59 130 246 / 0.8) !important;
}

.tw-placeholder-blue-500\/85::placeholder {
  color: rgb(59 130 246 / 0.85) !important;
}

.tw-placeholder-blue-500\/90::placeholder {
  color: rgb(59 130 246 / 0.9) !important;
}

.tw-placeholder-blue-500\/95::placeholder {
  color: rgb(59 130 246 / 0.95) !important;
}

.tw-placeholder-blue-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(37 99 235 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-600\/0::placeholder {
  color: rgb(37 99 235 / 0) !important;
}

.tw-placeholder-blue-600\/10::placeholder {
  color: rgb(37 99 235 / 0.1) !important;
}

.tw-placeholder-blue-600\/100::placeholder {
  color: rgb(37 99 235 / 1) !important;
}

.tw-placeholder-blue-600\/15::placeholder {
  color: rgb(37 99 235 / 0.15) !important;
}

.tw-placeholder-blue-600\/20::placeholder {
  color: rgb(37 99 235 / 0.2) !important;
}

.tw-placeholder-blue-600\/25::placeholder {
  color: rgb(37 99 235 / 0.25) !important;
}

.tw-placeholder-blue-600\/30::placeholder {
  color: rgb(37 99 235 / 0.3) !important;
}

.tw-placeholder-blue-600\/35::placeholder {
  color: rgb(37 99 235 / 0.35) !important;
}

.tw-placeholder-blue-600\/40::placeholder {
  color: rgb(37 99 235 / 0.4) !important;
}

.tw-placeholder-blue-600\/45::placeholder {
  color: rgb(37 99 235 / 0.45) !important;
}

.tw-placeholder-blue-600\/5::placeholder {
  color: rgb(37 99 235 / 0.05) !important;
}

.tw-placeholder-blue-600\/50::placeholder {
  color: rgb(37 99 235 / 0.5) !important;
}

.tw-placeholder-blue-600\/55::placeholder {
  color: rgb(37 99 235 / 0.55) !important;
}

.tw-placeholder-blue-600\/60::placeholder {
  color: rgb(37 99 235 / 0.6) !important;
}

.tw-placeholder-blue-600\/65::placeholder {
  color: rgb(37 99 235 / 0.65) !important;
}

.tw-placeholder-blue-600\/70::placeholder {
  color: rgb(37 99 235 / 0.7) !important;
}

.tw-placeholder-blue-600\/75::placeholder {
  color: rgb(37 99 235 / 0.75) !important;
}

.tw-placeholder-blue-600\/80::placeholder {
  color: rgb(37 99 235 / 0.8) !important;
}

.tw-placeholder-blue-600\/85::placeholder {
  color: rgb(37 99 235 / 0.85) !important;
}

.tw-placeholder-blue-600\/90::placeholder {
  color: rgb(37 99 235 / 0.9) !important;
}

.tw-placeholder-blue-600\/95::placeholder {
  color: rgb(37 99 235 / 0.95) !important;
}

.tw-placeholder-blue-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(29 78 216 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-700\/0::placeholder {
  color: rgb(29 78 216 / 0) !important;
}

.tw-placeholder-blue-700\/10::placeholder {
  color: rgb(29 78 216 / 0.1) !important;
}

.tw-placeholder-blue-700\/100::placeholder {
  color: rgb(29 78 216 / 1) !important;
}

.tw-placeholder-blue-700\/15::placeholder {
  color: rgb(29 78 216 / 0.15) !important;
}

.tw-placeholder-blue-700\/20::placeholder {
  color: rgb(29 78 216 / 0.2) !important;
}

.tw-placeholder-blue-700\/25::placeholder {
  color: rgb(29 78 216 / 0.25) !important;
}

.tw-placeholder-blue-700\/30::placeholder {
  color: rgb(29 78 216 / 0.3) !important;
}

.tw-placeholder-blue-700\/35::placeholder {
  color: rgb(29 78 216 / 0.35) !important;
}

.tw-placeholder-blue-700\/40::placeholder {
  color: rgb(29 78 216 / 0.4) !important;
}

.tw-placeholder-blue-700\/45::placeholder {
  color: rgb(29 78 216 / 0.45) !important;
}

.tw-placeholder-blue-700\/5::placeholder {
  color: rgb(29 78 216 / 0.05) !important;
}

.tw-placeholder-blue-700\/50::placeholder {
  color: rgb(29 78 216 / 0.5) !important;
}

.tw-placeholder-blue-700\/55::placeholder {
  color: rgb(29 78 216 / 0.55) !important;
}

.tw-placeholder-blue-700\/60::placeholder {
  color: rgb(29 78 216 / 0.6) !important;
}

.tw-placeholder-blue-700\/65::placeholder {
  color: rgb(29 78 216 / 0.65) !important;
}

.tw-placeholder-blue-700\/70::placeholder {
  color: rgb(29 78 216 / 0.7) !important;
}

.tw-placeholder-blue-700\/75::placeholder {
  color: rgb(29 78 216 / 0.75) !important;
}

.tw-placeholder-blue-700\/80::placeholder {
  color: rgb(29 78 216 / 0.8) !important;
}

.tw-placeholder-blue-700\/85::placeholder {
  color: rgb(29 78 216 / 0.85) !important;
}

.tw-placeholder-blue-700\/90::placeholder {
  color: rgb(29 78 216 / 0.9) !important;
}

.tw-placeholder-blue-700\/95::placeholder {
  color: rgb(29 78 216 / 0.95) !important;
}

.tw-placeholder-blue-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(30 64 175 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-800\/0::placeholder {
  color: rgb(30 64 175 / 0) !important;
}

.tw-placeholder-blue-800\/10::placeholder {
  color: rgb(30 64 175 / 0.1) !important;
}

.tw-placeholder-blue-800\/100::placeholder {
  color: rgb(30 64 175 / 1) !important;
}

.tw-placeholder-blue-800\/15::placeholder {
  color: rgb(30 64 175 / 0.15) !important;
}

.tw-placeholder-blue-800\/20::placeholder {
  color: rgb(30 64 175 / 0.2) !important;
}

.tw-placeholder-blue-800\/25::placeholder {
  color: rgb(30 64 175 / 0.25) !important;
}

.tw-placeholder-blue-800\/30::placeholder {
  color: rgb(30 64 175 / 0.3) !important;
}

.tw-placeholder-blue-800\/35::placeholder {
  color: rgb(30 64 175 / 0.35) !important;
}

.tw-placeholder-blue-800\/40::placeholder {
  color: rgb(30 64 175 / 0.4) !important;
}

.tw-placeholder-blue-800\/45::placeholder {
  color: rgb(30 64 175 / 0.45) !important;
}

.tw-placeholder-blue-800\/5::placeholder {
  color: rgb(30 64 175 / 0.05) !important;
}

.tw-placeholder-blue-800\/50::placeholder {
  color: rgb(30 64 175 / 0.5) !important;
}

.tw-placeholder-blue-800\/55::placeholder {
  color: rgb(30 64 175 / 0.55) !important;
}

.tw-placeholder-blue-800\/60::placeholder {
  color: rgb(30 64 175 / 0.6) !important;
}

.tw-placeholder-blue-800\/65::placeholder {
  color: rgb(30 64 175 / 0.65) !important;
}

.tw-placeholder-blue-800\/70::placeholder {
  color: rgb(30 64 175 / 0.7) !important;
}

.tw-placeholder-blue-800\/75::placeholder {
  color: rgb(30 64 175 / 0.75) !important;
}

.tw-placeholder-blue-800\/80::placeholder {
  color: rgb(30 64 175 / 0.8) !important;
}

.tw-placeholder-blue-800\/85::placeholder {
  color: rgb(30 64 175 / 0.85) !important;
}

.tw-placeholder-blue-800\/90::placeholder {
  color: rgb(30 64 175 / 0.9) !important;
}

.tw-placeholder-blue-800\/95::placeholder {
  color: rgb(30 64 175 / 0.95) !important;
}

.tw-placeholder-blue-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(30 58 138 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-900\/0::placeholder {
  color: rgb(30 58 138 / 0) !important;
}

.tw-placeholder-blue-900\/10::placeholder {
  color: rgb(30 58 138 / 0.1) !important;
}

.tw-placeholder-blue-900\/100::placeholder {
  color: rgb(30 58 138 / 1) !important;
}

.tw-placeholder-blue-900\/15::placeholder {
  color: rgb(30 58 138 / 0.15) !important;
}

.tw-placeholder-blue-900\/20::placeholder {
  color: rgb(30 58 138 / 0.2) !important;
}

.tw-placeholder-blue-900\/25::placeholder {
  color: rgb(30 58 138 / 0.25) !important;
}

.tw-placeholder-blue-900\/30::placeholder {
  color: rgb(30 58 138 / 0.3) !important;
}

.tw-placeholder-blue-900\/35::placeholder {
  color: rgb(30 58 138 / 0.35) !important;
}

.tw-placeholder-blue-900\/40::placeholder {
  color: rgb(30 58 138 / 0.4) !important;
}

.tw-placeholder-blue-900\/45::placeholder {
  color: rgb(30 58 138 / 0.45) !important;
}

.tw-placeholder-blue-900\/5::placeholder {
  color: rgb(30 58 138 / 0.05) !important;
}

.tw-placeholder-blue-900\/50::placeholder {
  color: rgb(30 58 138 / 0.5) !important;
}

.tw-placeholder-blue-900\/55::placeholder {
  color: rgb(30 58 138 / 0.55) !important;
}

.tw-placeholder-blue-900\/60::placeholder {
  color: rgb(30 58 138 / 0.6) !important;
}

.tw-placeholder-blue-900\/65::placeholder {
  color: rgb(30 58 138 / 0.65) !important;
}

.tw-placeholder-blue-900\/70::placeholder {
  color: rgb(30 58 138 / 0.7) !important;
}

.tw-placeholder-blue-900\/75::placeholder {
  color: rgb(30 58 138 / 0.75) !important;
}

.tw-placeholder-blue-900\/80::placeholder {
  color: rgb(30 58 138 / 0.8) !important;
}

.tw-placeholder-blue-900\/85::placeholder {
  color: rgb(30 58 138 / 0.85) !important;
}

.tw-placeholder-blue-900\/90::placeholder {
  color: rgb(30 58 138 / 0.9) !important;
}

.tw-placeholder-blue-900\/95::placeholder {
  color: rgb(30 58 138 / 0.95) !important;
}

.tw-placeholder-blue-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(23 37 84 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-blue-950\/0::placeholder {
  color: rgb(23 37 84 / 0) !important;
}

.tw-placeholder-blue-950\/10::placeholder {
  color: rgb(23 37 84 / 0.1) !important;
}

.tw-placeholder-blue-950\/100::placeholder {
  color: rgb(23 37 84 / 1) !important;
}

.tw-placeholder-blue-950\/15::placeholder {
  color: rgb(23 37 84 / 0.15) !important;
}

.tw-placeholder-blue-950\/20::placeholder {
  color: rgb(23 37 84 / 0.2) !important;
}

.tw-placeholder-blue-950\/25::placeholder {
  color: rgb(23 37 84 / 0.25) !important;
}

.tw-placeholder-blue-950\/30::placeholder {
  color: rgb(23 37 84 / 0.3) !important;
}

.tw-placeholder-blue-950\/35::placeholder {
  color: rgb(23 37 84 / 0.35) !important;
}

.tw-placeholder-blue-950\/40::placeholder {
  color: rgb(23 37 84 / 0.4) !important;
}

.tw-placeholder-blue-950\/45::placeholder {
  color: rgb(23 37 84 / 0.45) !important;
}

.tw-placeholder-blue-950\/5::placeholder {
  color: rgb(23 37 84 / 0.05) !important;
}

.tw-placeholder-blue-950\/50::placeholder {
  color: rgb(23 37 84 / 0.5) !important;
}

.tw-placeholder-blue-950\/55::placeholder {
  color: rgb(23 37 84 / 0.55) !important;
}

.tw-placeholder-blue-950\/60::placeholder {
  color: rgb(23 37 84 / 0.6) !important;
}

.tw-placeholder-blue-950\/65::placeholder {
  color: rgb(23 37 84 / 0.65) !important;
}

.tw-placeholder-blue-950\/70::placeholder {
  color: rgb(23 37 84 / 0.7) !important;
}

.tw-placeholder-blue-950\/75::placeholder {
  color: rgb(23 37 84 / 0.75) !important;
}

.tw-placeholder-blue-950\/80::placeholder {
  color: rgb(23 37 84 / 0.8) !important;
}

.tw-placeholder-blue-950\/85::placeholder {
  color: rgb(23 37 84 / 0.85) !important;
}

.tw-placeholder-blue-950\/90::placeholder {
  color: rgb(23 37 84 / 0.9) !important;
}

.tw-placeholder-blue-950\/95::placeholder {
  color: rgb(23 37 84 / 0.95) !important;
}

.tw-placeholder-current::placeholder {
  color: currentColor !important;
}

.tw-placeholder-cyan-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(207 250 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-100\/0::placeholder {
  color: rgb(207 250 254 / 0) !important;
}

.tw-placeholder-cyan-100\/10::placeholder {
  color: rgb(207 250 254 / 0.1) !important;
}

.tw-placeholder-cyan-100\/100::placeholder {
  color: rgb(207 250 254 / 1) !important;
}

.tw-placeholder-cyan-100\/15::placeholder {
  color: rgb(207 250 254 / 0.15) !important;
}

.tw-placeholder-cyan-100\/20::placeholder {
  color: rgb(207 250 254 / 0.2) !important;
}

.tw-placeholder-cyan-100\/25::placeholder {
  color: rgb(207 250 254 / 0.25) !important;
}

.tw-placeholder-cyan-100\/30::placeholder {
  color: rgb(207 250 254 / 0.3) !important;
}

.tw-placeholder-cyan-100\/35::placeholder {
  color: rgb(207 250 254 / 0.35) !important;
}

.tw-placeholder-cyan-100\/40::placeholder {
  color: rgb(207 250 254 / 0.4) !important;
}

.tw-placeholder-cyan-100\/45::placeholder {
  color: rgb(207 250 254 / 0.45) !important;
}

.tw-placeholder-cyan-100\/5::placeholder {
  color: rgb(207 250 254 / 0.05) !important;
}

.tw-placeholder-cyan-100\/50::placeholder {
  color: rgb(207 250 254 / 0.5) !important;
}

.tw-placeholder-cyan-100\/55::placeholder {
  color: rgb(207 250 254 / 0.55) !important;
}

.tw-placeholder-cyan-100\/60::placeholder {
  color: rgb(207 250 254 / 0.6) !important;
}

.tw-placeholder-cyan-100\/65::placeholder {
  color: rgb(207 250 254 / 0.65) !important;
}

.tw-placeholder-cyan-100\/70::placeholder {
  color: rgb(207 250 254 / 0.7) !important;
}

.tw-placeholder-cyan-100\/75::placeholder {
  color: rgb(207 250 254 / 0.75) !important;
}

.tw-placeholder-cyan-100\/80::placeholder {
  color: rgb(207 250 254 / 0.8) !important;
}

.tw-placeholder-cyan-100\/85::placeholder {
  color: rgb(207 250 254 / 0.85) !important;
}

.tw-placeholder-cyan-100\/90::placeholder {
  color: rgb(207 250 254 / 0.9) !important;
}

.tw-placeholder-cyan-100\/95::placeholder {
  color: rgb(207 250 254 / 0.95) !important;
}

.tw-placeholder-cyan-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(165 243 252 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-200\/0::placeholder {
  color: rgb(165 243 252 / 0) !important;
}

.tw-placeholder-cyan-200\/10::placeholder {
  color: rgb(165 243 252 / 0.1) !important;
}

.tw-placeholder-cyan-200\/100::placeholder {
  color: rgb(165 243 252 / 1) !important;
}

.tw-placeholder-cyan-200\/15::placeholder {
  color: rgb(165 243 252 / 0.15) !important;
}

.tw-placeholder-cyan-200\/20::placeholder {
  color: rgb(165 243 252 / 0.2) !important;
}

.tw-placeholder-cyan-200\/25::placeholder {
  color: rgb(165 243 252 / 0.25) !important;
}

.tw-placeholder-cyan-200\/30::placeholder {
  color: rgb(165 243 252 / 0.3) !important;
}

.tw-placeholder-cyan-200\/35::placeholder {
  color: rgb(165 243 252 / 0.35) !important;
}

.tw-placeholder-cyan-200\/40::placeholder {
  color: rgb(165 243 252 / 0.4) !important;
}

.tw-placeholder-cyan-200\/45::placeholder {
  color: rgb(165 243 252 / 0.45) !important;
}

.tw-placeholder-cyan-200\/5::placeholder {
  color: rgb(165 243 252 / 0.05) !important;
}

.tw-placeholder-cyan-200\/50::placeholder {
  color: rgb(165 243 252 / 0.5) !important;
}

.tw-placeholder-cyan-200\/55::placeholder {
  color: rgb(165 243 252 / 0.55) !important;
}

.tw-placeholder-cyan-200\/60::placeholder {
  color: rgb(165 243 252 / 0.6) !important;
}

.tw-placeholder-cyan-200\/65::placeholder {
  color: rgb(165 243 252 / 0.65) !important;
}

.tw-placeholder-cyan-200\/70::placeholder {
  color: rgb(165 243 252 / 0.7) !important;
}

.tw-placeholder-cyan-200\/75::placeholder {
  color: rgb(165 243 252 / 0.75) !important;
}

.tw-placeholder-cyan-200\/80::placeholder {
  color: rgb(165 243 252 / 0.8) !important;
}

.tw-placeholder-cyan-200\/85::placeholder {
  color: rgb(165 243 252 / 0.85) !important;
}

.tw-placeholder-cyan-200\/90::placeholder {
  color: rgb(165 243 252 / 0.9) !important;
}

.tw-placeholder-cyan-200\/95::placeholder {
  color: rgb(165 243 252 / 0.95) !important;
}

.tw-placeholder-cyan-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(103 232 249 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-300\/0::placeholder {
  color: rgb(103 232 249 / 0) !important;
}

.tw-placeholder-cyan-300\/10::placeholder {
  color: rgb(103 232 249 / 0.1) !important;
}

.tw-placeholder-cyan-300\/100::placeholder {
  color: rgb(103 232 249 / 1) !important;
}

.tw-placeholder-cyan-300\/15::placeholder {
  color: rgb(103 232 249 / 0.15) !important;
}

.tw-placeholder-cyan-300\/20::placeholder {
  color: rgb(103 232 249 / 0.2) !important;
}

.tw-placeholder-cyan-300\/25::placeholder {
  color: rgb(103 232 249 / 0.25) !important;
}

.tw-placeholder-cyan-300\/30::placeholder {
  color: rgb(103 232 249 / 0.3) !important;
}

.tw-placeholder-cyan-300\/35::placeholder {
  color: rgb(103 232 249 / 0.35) !important;
}

.tw-placeholder-cyan-300\/40::placeholder {
  color: rgb(103 232 249 / 0.4) !important;
}

.tw-placeholder-cyan-300\/45::placeholder {
  color: rgb(103 232 249 / 0.45) !important;
}

.tw-placeholder-cyan-300\/5::placeholder {
  color: rgb(103 232 249 / 0.05) !important;
}

.tw-placeholder-cyan-300\/50::placeholder {
  color: rgb(103 232 249 / 0.5) !important;
}

.tw-placeholder-cyan-300\/55::placeholder {
  color: rgb(103 232 249 / 0.55) !important;
}

.tw-placeholder-cyan-300\/60::placeholder {
  color: rgb(103 232 249 / 0.6) !important;
}

.tw-placeholder-cyan-300\/65::placeholder {
  color: rgb(103 232 249 / 0.65) !important;
}

.tw-placeholder-cyan-300\/70::placeholder {
  color: rgb(103 232 249 / 0.7) !important;
}

.tw-placeholder-cyan-300\/75::placeholder {
  color: rgb(103 232 249 / 0.75) !important;
}

.tw-placeholder-cyan-300\/80::placeholder {
  color: rgb(103 232 249 / 0.8) !important;
}

.tw-placeholder-cyan-300\/85::placeholder {
  color: rgb(103 232 249 / 0.85) !important;
}

.tw-placeholder-cyan-300\/90::placeholder {
  color: rgb(103 232 249 / 0.9) !important;
}

.tw-placeholder-cyan-300\/95::placeholder {
  color: rgb(103 232 249 / 0.95) !important;
}

.tw-placeholder-cyan-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(34 211 238 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-400\/0::placeholder {
  color: rgb(34 211 238 / 0) !important;
}

.tw-placeholder-cyan-400\/10::placeholder {
  color: rgb(34 211 238 / 0.1) !important;
}

.tw-placeholder-cyan-400\/100::placeholder {
  color: rgb(34 211 238 / 1) !important;
}

.tw-placeholder-cyan-400\/15::placeholder {
  color: rgb(34 211 238 / 0.15) !important;
}

.tw-placeholder-cyan-400\/20::placeholder {
  color: rgb(34 211 238 / 0.2) !important;
}

.tw-placeholder-cyan-400\/25::placeholder {
  color: rgb(34 211 238 / 0.25) !important;
}

.tw-placeholder-cyan-400\/30::placeholder {
  color: rgb(34 211 238 / 0.3) !important;
}

.tw-placeholder-cyan-400\/35::placeholder {
  color: rgb(34 211 238 / 0.35) !important;
}

.tw-placeholder-cyan-400\/40::placeholder {
  color: rgb(34 211 238 / 0.4) !important;
}

.tw-placeholder-cyan-400\/45::placeholder {
  color: rgb(34 211 238 / 0.45) !important;
}

.tw-placeholder-cyan-400\/5::placeholder {
  color: rgb(34 211 238 / 0.05) !important;
}

.tw-placeholder-cyan-400\/50::placeholder {
  color: rgb(34 211 238 / 0.5) !important;
}

.tw-placeholder-cyan-400\/55::placeholder {
  color: rgb(34 211 238 / 0.55) !important;
}

.tw-placeholder-cyan-400\/60::placeholder {
  color: rgb(34 211 238 / 0.6) !important;
}

.tw-placeholder-cyan-400\/65::placeholder {
  color: rgb(34 211 238 / 0.65) !important;
}

.tw-placeholder-cyan-400\/70::placeholder {
  color: rgb(34 211 238 / 0.7) !important;
}

.tw-placeholder-cyan-400\/75::placeholder {
  color: rgb(34 211 238 / 0.75) !important;
}

.tw-placeholder-cyan-400\/80::placeholder {
  color: rgb(34 211 238 / 0.8) !important;
}

.tw-placeholder-cyan-400\/85::placeholder {
  color: rgb(34 211 238 / 0.85) !important;
}

.tw-placeholder-cyan-400\/90::placeholder {
  color: rgb(34 211 238 / 0.9) !important;
}

.tw-placeholder-cyan-400\/95::placeholder {
  color: rgb(34 211 238 / 0.95) !important;
}

.tw-placeholder-cyan-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(236 254 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-50\/0::placeholder {
  color: rgb(236 254 255 / 0) !important;
}

.tw-placeholder-cyan-50\/10::placeholder {
  color: rgb(236 254 255 / 0.1) !important;
}

.tw-placeholder-cyan-50\/100::placeholder {
  color: rgb(236 254 255 / 1) !important;
}

.tw-placeholder-cyan-50\/15::placeholder {
  color: rgb(236 254 255 / 0.15) !important;
}

.tw-placeholder-cyan-50\/20::placeholder {
  color: rgb(236 254 255 / 0.2) !important;
}

.tw-placeholder-cyan-50\/25::placeholder {
  color: rgb(236 254 255 / 0.25) !important;
}

.tw-placeholder-cyan-50\/30::placeholder {
  color: rgb(236 254 255 / 0.3) !important;
}

.tw-placeholder-cyan-50\/35::placeholder {
  color: rgb(236 254 255 / 0.35) !important;
}

.tw-placeholder-cyan-50\/40::placeholder {
  color: rgb(236 254 255 / 0.4) !important;
}

.tw-placeholder-cyan-50\/45::placeholder {
  color: rgb(236 254 255 / 0.45) !important;
}

.tw-placeholder-cyan-50\/5::placeholder {
  color: rgb(236 254 255 / 0.05) !important;
}

.tw-placeholder-cyan-50\/50::placeholder {
  color: rgb(236 254 255 / 0.5) !important;
}

.tw-placeholder-cyan-50\/55::placeholder {
  color: rgb(236 254 255 / 0.55) !important;
}

.tw-placeholder-cyan-50\/60::placeholder {
  color: rgb(236 254 255 / 0.6) !important;
}

.tw-placeholder-cyan-50\/65::placeholder {
  color: rgb(236 254 255 / 0.65) !important;
}

.tw-placeholder-cyan-50\/70::placeholder {
  color: rgb(236 254 255 / 0.7) !important;
}

.tw-placeholder-cyan-50\/75::placeholder {
  color: rgb(236 254 255 / 0.75) !important;
}

.tw-placeholder-cyan-50\/80::placeholder {
  color: rgb(236 254 255 / 0.8) !important;
}

.tw-placeholder-cyan-50\/85::placeholder {
  color: rgb(236 254 255 / 0.85) !important;
}

.tw-placeholder-cyan-50\/90::placeholder {
  color: rgb(236 254 255 / 0.9) !important;
}

.tw-placeholder-cyan-50\/95::placeholder {
  color: rgb(236 254 255 / 0.95) !important;
}

.tw-placeholder-cyan-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(6 182 212 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-500\/0::placeholder {
  color: rgb(6 182 212 / 0) !important;
}

.tw-placeholder-cyan-500\/10::placeholder {
  color: rgb(6 182 212 / 0.1) !important;
}

.tw-placeholder-cyan-500\/100::placeholder {
  color: rgb(6 182 212 / 1) !important;
}

.tw-placeholder-cyan-500\/15::placeholder {
  color: rgb(6 182 212 / 0.15) !important;
}

.tw-placeholder-cyan-500\/20::placeholder {
  color: rgb(6 182 212 / 0.2) !important;
}

.tw-placeholder-cyan-500\/25::placeholder {
  color: rgb(6 182 212 / 0.25) !important;
}

.tw-placeholder-cyan-500\/30::placeholder {
  color: rgb(6 182 212 / 0.3) !important;
}

.tw-placeholder-cyan-500\/35::placeholder {
  color: rgb(6 182 212 / 0.35) !important;
}

.tw-placeholder-cyan-500\/40::placeholder {
  color: rgb(6 182 212 / 0.4) !important;
}

.tw-placeholder-cyan-500\/45::placeholder {
  color: rgb(6 182 212 / 0.45) !important;
}

.tw-placeholder-cyan-500\/5::placeholder {
  color: rgb(6 182 212 / 0.05) !important;
}

.tw-placeholder-cyan-500\/50::placeholder {
  color: rgb(6 182 212 / 0.5) !important;
}

.tw-placeholder-cyan-500\/55::placeholder {
  color: rgb(6 182 212 / 0.55) !important;
}

.tw-placeholder-cyan-500\/60::placeholder {
  color: rgb(6 182 212 / 0.6) !important;
}

.tw-placeholder-cyan-500\/65::placeholder {
  color: rgb(6 182 212 / 0.65) !important;
}

.tw-placeholder-cyan-500\/70::placeholder {
  color: rgb(6 182 212 / 0.7) !important;
}

.tw-placeholder-cyan-500\/75::placeholder {
  color: rgb(6 182 212 / 0.75) !important;
}

.tw-placeholder-cyan-500\/80::placeholder {
  color: rgb(6 182 212 / 0.8) !important;
}

.tw-placeholder-cyan-500\/85::placeholder {
  color: rgb(6 182 212 / 0.85) !important;
}

.tw-placeholder-cyan-500\/90::placeholder {
  color: rgb(6 182 212 / 0.9) !important;
}

.tw-placeholder-cyan-500\/95::placeholder {
  color: rgb(6 182 212 / 0.95) !important;
}

.tw-placeholder-cyan-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(8 145 178 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-600\/0::placeholder {
  color: rgb(8 145 178 / 0) !important;
}

.tw-placeholder-cyan-600\/10::placeholder {
  color: rgb(8 145 178 / 0.1) !important;
}

.tw-placeholder-cyan-600\/100::placeholder {
  color: rgb(8 145 178 / 1) !important;
}

.tw-placeholder-cyan-600\/15::placeholder {
  color: rgb(8 145 178 / 0.15) !important;
}

.tw-placeholder-cyan-600\/20::placeholder {
  color: rgb(8 145 178 / 0.2) !important;
}

.tw-placeholder-cyan-600\/25::placeholder {
  color: rgb(8 145 178 / 0.25) !important;
}

.tw-placeholder-cyan-600\/30::placeholder {
  color: rgb(8 145 178 / 0.3) !important;
}

.tw-placeholder-cyan-600\/35::placeholder {
  color: rgb(8 145 178 / 0.35) !important;
}

.tw-placeholder-cyan-600\/40::placeholder {
  color: rgb(8 145 178 / 0.4) !important;
}

.tw-placeholder-cyan-600\/45::placeholder {
  color: rgb(8 145 178 / 0.45) !important;
}

.tw-placeholder-cyan-600\/5::placeholder {
  color: rgb(8 145 178 / 0.05) !important;
}

.tw-placeholder-cyan-600\/50::placeholder {
  color: rgb(8 145 178 / 0.5) !important;
}

.tw-placeholder-cyan-600\/55::placeholder {
  color: rgb(8 145 178 / 0.55) !important;
}

.tw-placeholder-cyan-600\/60::placeholder {
  color: rgb(8 145 178 / 0.6) !important;
}

.tw-placeholder-cyan-600\/65::placeholder {
  color: rgb(8 145 178 / 0.65) !important;
}

.tw-placeholder-cyan-600\/70::placeholder {
  color: rgb(8 145 178 / 0.7) !important;
}

.tw-placeholder-cyan-600\/75::placeholder {
  color: rgb(8 145 178 / 0.75) !important;
}

.tw-placeholder-cyan-600\/80::placeholder {
  color: rgb(8 145 178 / 0.8) !important;
}

.tw-placeholder-cyan-600\/85::placeholder {
  color: rgb(8 145 178 / 0.85) !important;
}

.tw-placeholder-cyan-600\/90::placeholder {
  color: rgb(8 145 178 / 0.9) !important;
}

.tw-placeholder-cyan-600\/95::placeholder {
  color: rgb(8 145 178 / 0.95) !important;
}

.tw-placeholder-cyan-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(14 116 144 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-700\/0::placeholder {
  color: rgb(14 116 144 / 0) !important;
}

.tw-placeholder-cyan-700\/10::placeholder {
  color: rgb(14 116 144 / 0.1) !important;
}

.tw-placeholder-cyan-700\/100::placeholder {
  color: rgb(14 116 144 / 1) !important;
}

.tw-placeholder-cyan-700\/15::placeholder {
  color: rgb(14 116 144 / 0.15) !important;
}

.tw-placeholder-cyan-700\/20::placeholder {
  color: rgb(14 116 144 / 0.2) !important;
}

.tw-placeholder-cyan-700\/25::placeholder {
  color: rgb(14 116 144 / 0.25) !important;
}

.tw-placeholder-cyan-700\/30::placeholder {
  color: rgb(14 116 144 / 0.3) !important;
}

.tw-placeholder-cyan-700\/35::placeholder {
  color: rgb(14 116 144 / 0.35) !important;
}

.tw-placeholder-cyan-700\/40::placeholder {
  color: rgb(14 116 144 / 0.4) !important;
}

.tw-placeholder-cyan-700\/45::placeholder {
  color: rgb(14 116 144 / 0.45) !important;
}

.tw-placeholder-cyan-700\/5::placeholder {
  color: rgb(14 116 144 / 0.05) !important;
}

.tw-placeholder-cyan-700\/50::placeholder {
  color: rgb(14 116 144 / 0.5) !important;
}

.tw-placeholder-cyan-700\/55::placeholder {
  color: rgb(14 116 144 / 0.55) !important;
}

.tw-placeholder-cyan-700\/60::placeholder {
  color: rgb(14 116 144 / 0.6) !important;
}

.tw-placeholder-cyan-700\/65::placeholder {
  color: rgb(14 116 144 / 0.65) !important;
}

.tw-placeholder-cyan-700\/70::placeholder {
  color: rgb(14 116 144 / 0.7) !important;
}

.tw-placeholder-cyan-700\/75::placeholder {
  color: rgb(14 116 144 / 0.75) !important;
}

.tw-placeholder-cyan-700\/80::placeholder {
  color: rgb(14 116 144 / 0.8) !important;
}

.tw-placeholder-cyan-700\/85::placeholder {
  color: rgb(14 116 144 / 0.85) !important;
}

.tw-placeholder-cyan-700\/90::placeholder {
  color: rgb(14 116 144 / 0.9) !important;
}

.tw-placeholder-cyan-700\/95::placeholder {
  color: rgb(14 116 144 / 0.95) !important;
}

.tw-placeholder-cyan-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(21 94 117 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-800\/0::placeholder {
  color: rgb(21 94 117 / 0) !important;
}

.tw-placeholder-cyan-800\/10::placeholder {
  color: rgb(21 94 117 / 0.1) !important;
}

.tw-placeholder-cyan-800\/100::placeholder {
  color: rgb(21 94 117 / 1) !important;
}

.tw-placeholder-cyan-800\/15::placeholder {
  color: rgb(21 94 117 / 0.15) !important;
}

.tw-placeholder-cyan-800\/20::placeholder {
  color: rgb(21 94 117 / 0.2) !important;
}

.tw-placeholder-cyan-800\/25::placeholder {
  color: rgb(21 94 117 / 0.25) !important;
}

.tw-placeholder-cyan-800\/30::placeholder {
  color: rgb(21 94 117 / 0.3) !important;
}

.tw-placeholder-cyan-800\/35::placeholder {
  color: rgb(21 94 117 / 0.35) !important;
}

.tw-placeholder-cyan-800\/40::placeholder {
  color: rgb(21 94 117 / 0.4) !important;
}

.tw-placeholder-cyan-800\/45::placeholder {
  color: rgb(21 94 117 / 0.45) !important;
}

.tw-placeholder-cyan-800\/5::placeholder {
  color: rgb(21 94 117 / 0.05) !important;
}

.tw-placeholder-cyan-800\/50::placeholder {
  color: rgb(21 94 117 / 0.5) !important;
}

.tw-placeholder-cyan-800\/55::placeholder {
  color: rgb(21 94 117 / 0.55) !important;
}

.tw-placeholder-cyan-800\/60::placeholder {
  color: rgb(21 94 117 / 0.6) !important;
}

.tw-placeholder-cyan-800\/65::placeholder {
  color: rgb(21 94 117 / 0.65) !important;
}

.tw-placeholder-cyan-800\/70::placeholder {
  color: rgb(21 94 117 / 0.7) !important;
}

.tw-placeholder-cyan-800\/75::placeholder {
  color: rgb(21 94 117 / 0.75) !important;
}

.tw-placeholder-cyan-800\/80::placeholder {
  color: rgb(21 94 117 / 0.8) !important;
}

.tw-placeholder-cyan-800\/85::placeholder {
  color: rgb(21 94 117 / 0.85) !important;
}

.tw-placeholder-cyan-800\/90::placeholder {
  color: rgb(21 94 117 / 0.9) !important;
}

.tw-placeholder-cyan-800\/95::placeholder {
  color: rgb(21 94 117 / 0.95) !important;
}

.tw-placeholder-cyan-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(22 78 99 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-900\/0::placeholder {
  color: rgb(22 78 99 / 0) !important;
}

.tw-placeholder-cyan-900\/10::placeholder {
  color: rgb(22 78 99 / 0.1) !important;
}

.tw-placeholder-cyan-900\/100::placeholder {
  color: rgb(22 78 99 / 1) !important;
}

.tw-placeholder-cyan-900\/15::placeholder {
  color: rgb(22 78 99 / 0.15) !important;
}

.tw-placeholder-cyan-900\/20::placeholder {
  color: rgb(22 78 99 / 0.2) !important;
}

.tw-placeholder-cyan-900\/25::placeholder {
  color: rgb(22 78 99 / 0.25) !important;
}

.tw-placeholder-cyan-900\/30::placeholder {
  color: rgb(22 78 99 / 0.3) !important;
}

.tw-placeholder-cyan-900\/35::placeholder {
  color: rgb(22 78 99 / 0.35) !important;
}

.tw-placeholder-cyan-900\/40::placeholder {
  color: rgb(22 78 99 / 0.4) !important;
}

.tw-placeholder-cyan-900\/45::placeholder {
  color: rgb(22 78 99 / 0.45) !important;
}

.tw-placeholder-cyan-900\/5::placeholder {
  color: rgb(22 78 99 / 0.05) !important;
}

.tw-placeholder-cyan-900\/50::placeholder {
  color: rgb(22 78 99 / 0.5) !important;
}

.tw-placeholder-cyan-900\/55::placeholder {
  color: rgb(22 78 99 / 0.55) !important;
}

.tw-placeholder-cyan-900\/60::placeholder {
  color: rgb(22 78 99 / 0.6) !important;
}

.tw-placeholder-cyan-900\/65::placeholder {
  color: rgb(22 78 99 / 0.65) !important;
}

.tw-placeholder-cyan-900\/70::placeholder {
  color: rgb(22 78 99 / 0.7) !important;
}

.tw-placeholder-cyan-900\/75::placeholder {
  color: rgb(22 78 99 / 0.75) !important;
}

.tw-placeholder-cyan-900\/80::placeholder {
  color: rgb(22 78 99 / 0.8) !important;
}

.tw-placeholder-cyan-900\/85::placeholder {
  color: rgb(22 78 99 / 0.85) !important;
}

.tw-placeholder-cyan-900\/90::placeholder {
  color: rgb(22 78 99 / 0.9) !important;
}

.tw-placeholder-cyan-900\/95::placeholder {
  color: rgb(22 78 99 / 0.95) !important;
}

.tw-placeholder-cyan-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(8 51 68 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-cyan-950\/0::placeholder {
  color: rgb(8 51 68 / 0) !important;
}

.tw-placeholder-cyan-950\/10::placeholder {
  color: rgb(8 51 68 / 0.1) !important;
}

.tw-placeholder-cyan-950\/100::placeholder {
  color: rgb(8 51 68 / 1) !important;
}

.tw-placeholder-cyan-950\/15::placeholder {
  color: rgb(8 51 68 / 0.15) !important;
}

.tw-placeholder-cyan-950\/20::placeholder {
  color: rgb(8 51 68 / 0.2) !important;
}

.tw-placeholder-cyan-950\/25::placeholder {
  color: rgb(8 51 68 / 0.25) !important;
}

.tw-placeholder-cyan-950\/30::placeholder {
  color: rgb(8 51 68 / 0.3) !important;
}

.tw-placeholder-cyan-950\/35::placeholder {
  color: rgb(8 51 68 / 0.35) !important;
}

.tw-placeholder-cyan-950\/40::placeholder {
  color: rgb(8 51 68 / 0.4) !important;
}

.tw-placeholder-cyan-950\/45::placeholder {
  color: rgb(8 51 68 / 0.45) !important;
}

.tw-placeholder-cyan-950\/5::placeholder {
  color: rgb(8 51 68 / 0.05) !important;
}

.tw-placeholder-cyan-950\/50::placeholder {
  color: rgb(8 51 68 / 0.5) !important;
}

.tw-placeholder-cyan-950\/55::placeholder {
  color: rgb(8 51 68 / 0.55) !important;
}

.tw-placeholder-cyan-950\/60::placeholder {
  color: rgb(8 51 68 / 0.6) !important;
}

.tw-placeholder-cyan-950\/65::placeholder {
  color: rgb(8 51 68 / 0.65) !important;
}

.tw-placeholder-cyan-950\/70::placeholder {
  color: rgb(8 51 68 / 0.7) !important;
}

.tw-placeholder-cyan-950\/75::placeholder {
  color: rgb(8 51 68 / 0.75) !important;
}

.tw-placeholder-cyan-950\/80::placeholder {
  color: rgb(8 51 68 / 0.8) !important;
}

.tw-placeholder-cyan-950\/85::placeholder {
  color: rgb(8 51 68 / 0.85) !important;
}

.tw-placeholder-cyan-950\/90::placeholder {
  color: rgb(8 51 68 / 0.9) !important;
}

.tw-placeholder-cyan-950\/95::placeholder {
  color: rgb(8 51 68 / 0.95) !important;
}

.tw-placeholder-emerald-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(209 250 229 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-100\/0::placeholder {
  color: rgb(209 250 229 / 0) !important;
}

.tw-placeholder-emerald-100\/10::placeholder {
  color: rgb(209 250 229 / 0.1) !important;
}

.tw-placeholder-emerald-100\/100::placeholder {
  color: rgb(209 250 229 / 1) !important;
}

.tw-placeholder-emerald-100\/15::placeholder {
  color: rgb(209 250 229 / 0.15) !important;
}

.tw-placeholder-emerald-100\/20::placeholder {
  color: rgb(209 250 229 / 0.2) !important;
}

.tw-placeholder-emerald-100\/25::placeholder {
  color: rgb(209 250 229 / 0.25) !important;
}

.tw-placeholder-emerald-100\/30::placeholder {
  color: rgb(209 250 229 / 0.3) !important;
}

.tw-placeholder-emerald-100\/35::placeholder {
  color: rgb(209 250 229 / 0.35) !important;
}

.tw-placeholder-emerald-100\/40::placeholder {
  color: rgb(209 250 229 / 0.4) !important;
}

.tw-placeholder-emerald-100\/45::placeholder {
  color: rgb(209 250 229 / 0.45) !important;
}

.tw-placeholder-emerald-100\/5::placeholder {
  color: rgb(209 250 229 / 0.05) !important;
}

.tw-placeholder-emerald-100\/50::placeholder {
  color: rgb(209 250 229 / 0.5) !important;
}

.tw-placeholder-emerald-100\/55::placeholder {
  color: rgb(209 250 229 / 0.55) !important;
}

.tw-placeholder-emerald-100\/60::placeholder {
  color: rgb(209 250 229 / 0.6) !important;
}

.tw-placeholder-emerald-100\/65::placeholder {
  color: rgb(209 250 229 / 0.65) !important;
}

.tw-placeholder-emerald-100\/70::placeholder {
  color: rgb(209 250 229 / 0.7) !important;
}

.tw-placeholder-emerald-100\/75::placeholder {
  color: rgb(209 250 229 / 0.75) !important;
}

.tw-placeholder-emerald-100\/80::placeholder {
  color: rgb(209 250 229 / 0.8) !important;
}

.tw-placeholder-emerald-100\/85::placeholder {
  color: rgb(209 250 229 / 0.85) !important;
}

.tw-placeholder-emerald-100\/90::placeholder {
  color: rgb(209 250 229 / 0.9) !important;
}

.tw-placeholder-emerald-100\/95::placeholder {
  color: rgb(209 250 229 / 0.95) !important;
}

.tw-placeholder-emerald-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(167 243 208 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-200\/0::placeholder {
  color: rgb(167 243 208 / 0) !important;
}

.tw-placeholder-emerald-200\/10::placeholder {
  color: rgb(167 243 208 / 0.1) !important;
}

.tw-placeholder-emerald-200\/100::placeholder {
  color: rgb(167 243 208 / 1) !important;
}

.tw-placeholder-emerald-200\/15::placeholder {
  color: rgb(167 243 208 / 0.15) !important;
}

.tw-placeholder-emerald-200\/20::placeholder {
  color: rgb(167 243 208 / 0.2) !important;
}

.tw-placeholder-emerald-200\/25::placeholder {
  color: rgb(167 243 208 / 0.25) !important;
}

.tw-placeholder-emerald-200\/30::placeholder {
  color: rgb(167 243 208 / 0.3) !important;
}

.tw-placeholder-emerald-200\/35::placeholder {
  color: rgb(167 243 208 / 0.35) !important;
}

.tw-placeholder-emerald-200\/40::placeholder {
  color: rgb(167 243 208 / 0.4) !important;
}

.tw-placeholder-emerald-200\/45::placeholder {
  color: rgb(167 243 208 / 0.45) !important;
}

.tw-placeholder-emerald-200\/5::placeholder {
  color: rgb(167 243 208 / 0.05) !important;
}

.tw-placeholder-emerald-200\/50::placeholder {
  color: rgb(167 243 208 / 0.5) !important;
}

.tw-placeholder-emerald-200\/55::placeholder {
  color: rgb(167 243 208 / 0.55) !important;
}

.tw-placeholder-emerald-200\/60::placeholder {
  color: rgb(167 243 208 / 0.6) !important;
}

.tw-placeholder-emerald-200\/65::placeholder {
  color: rgb(167 243 208 / 0.65) !important;
}

.tw-placeholder-emerald-200\/70::placeholder {
  color: rgb(167 243 208 / 0.7) !important;
}

.tw-placeholder-emerald-200\/75::placeholder {
  color: rgb(167 243 208 / 0.75) !important;
}

.tw-placeholder-emerald-200\/80::placeholder {
  color: rgb(167 243 208 / 0.8) !important;
}

.tw-placeholder-emerald-200\/85::placeholder {
  color: rgb(167 243 208 / 0.85) !important;
}

.tw-placeholder-emerald-200\/90::placeholder {
  color: rgb(167 243 208 / 0.9) !important;
}

.tw-placeholder-emerald-200\/95::placeholder {
  color: rgb(167 243 208 / 0.95) !important;
}

.tw-placeholder-emerald-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(110 231 183 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-300\/0::placeholder {
  color: rgb(110 231 183 / 0) !important;
}

.tw-placeholder-emerald-300\/10::placeholder {
  color: rgb(110 231 183 / 0.1) !important;
}

.tw-placeholder-emerald-300\/100::placeholder {
  color: rgb(110 231 183 / 1) !important;
}

.tw-placeholder-emerald-300\/15::placeholder {
  color: rgb(110 231 183 / 0.15) !important;
}

.tw-placeholder-emerald-300\/20::placeholder {
  color: rgb(110 231 183 / 0.2) !important;
}

.tw-placeholder-emerald-300\/25::placeholder {
  color: rgb(110 231 183 / 0.25) !important;
}

.tw-placeholder-emerald-300\/30::placeholder {
  color: rgb(110 231 183 / 0.3) !important;
}

.tw-placeholder-emerald-300\/35::placeholder {
  color: rgb(110 231 183 / 0.35) !important;
}

.tw-placeholder-emerald-300\/40::placeholder {
  color: rgb(110 231 183 / 0.4) !important;
}

.tw-placeholder-emerald-300\/45::placeholder {
  color: rgb(110 231 183 / 0.45) !important;
}

.tw-placeholder-emerald-300\/5::placeholder {
  color: rgb(110 231 183 / 0.05) !important;
}

.tw-placeholder-emerald-300\/50::placeholder {
  color: rgb(110 231 183 / 0.5) !important;
}

.tw-placeholder-emerald-300\/55::placeholder {
  color: rgb(110 231 183 / 0.55) !important;
}

.tw-placeholder-emerald-300\/60::placeholder {
  color: rgb(110 231 183 / 0.6) !important;
}

.tw-placeholder-emerald-300\/65::placeholder {
  color: rgb(110 231 183 / 0.65) !important;
}

.tw-placeholder-emerald-300\/70::placeholder {
  color: rgb(110 231 183 / 0.7) !important;
}

.tw-placeholder-emerald-300\/75::placeholder {
  color: rgb(110 231 183 / 0.75) !important;
}

.tw-placeholder-emerald-300\/80::placeholder {
  color: rgb(110 231 183 / 0.8) !important;
}

.tw-placeholder-emerald-300\/85::placeholder {
  color: rgb(110 231 183 / 0.85) !important;
}

.tw-placeholder-emerald-300\/90::placeholder {
  color: rgb(110 231 183 / 0.9) !important;
}

.tw-placeholder-emerald-300\/95::placeholder {
  color: rgb(110 231 183 / 0.95) !important;
}

.tw-placeholder-emerald-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(52 211 153 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-400\/0::placeholder {
  color: rgb(52 211 153 / 0) !important;
}

.tw-placeholder-emerald-400\/10::placeholder {
  color: rgb(52 211 153 / 0.1) !important;
}

.tw-placeholder-emerald-400\/100::placeholder {
  color: rgb(52 211 153 / 1) !important;
}

.tw-placeholder-emerald-400\/15::placeholder {
  color: rgb(52 211 153 / 0.15) !important;
}

.tw-placeholder-emerald-400\/20::placeholder {
  color: rgb(52 211 153 / 0.2) !important;
}

.tw-placeholder-emerald-400\/25::placeholder {
  color: rgb(52 211 153 / 0.25) !important;
}

.tw-placeholder-emerald-400\/30::placeholder {
  color: rgb(52 211 153 / 0.3) !important;
}

.tw-placeholder-emerald-400\/35::placeholder {
  color: rgb(52 211 153 / 0.35) !important;
}

.tw-placeholder-emerald-400\/40::placeholder {
  color: rgb(52 211 153 / 0.4) !important;
}

.tw-placeholder-emerald-400\/45::placeholder {
  color: rgb(52 211 153 / 0.45) !important;
}

.tw-placeholder-emerald-400\/5::placeholder {
  color: rgb(52 211 153 / 0.05) !important;
}

.tw-placeholder-emerald-400\/50::placeholder {
  color: rgb(52 211 153 / 0.5) !important;
}

.tw-placeholder-emerald-400\/55::placeholder {
  color: rgb(52 211 153 / 0.55) !important;
}

.tw-placeholder-emerald-400\/60::placeholder {
  color: rgb(52 211 153 / 0.6) !important;
}

.tw-placeholder-emerald-400\/65::placeholder {
  color: rgb(52 211 153 / 0.65) !important;
}

.tw-placeholder-emerald-400\/70::placeholder {
  color: rgb(52 211 153 / 0.7) !important;
}

.tw-placeholder-emerald-400\/75::placeholder {
  color: rgb(52 211 153 / 0.75) !important;
}

.tw-placeholder-emerald-400\/80::placeholder {
  color: rgb(52 211 153 / 0.8) !important;
}

.tw-placeholder-emerald-400\/85::placeholder {
  color: rgb(52 211 153 / 0.85) !important;
}

.tw-placeholder-emerald-400\/90::placeholder {
  color: rgb(52 211 153 / 0.9) !important;
}

.tw-placeholder-emerald-400\/95::placeholder {
  color: rgb(52 211 153 / 0.95) !important;
}

.tw-placeholder-emerald-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(236 253 245 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-50\/0::placeholder {
  color: rgb(236 253 245 / 0) !important;
}

.tw-placeholder-emerald-50\/10::placeholder {
  color: rgb(236 253 245 / 0.1) !important;
}

.tw-placeholder-emerald-50\/100::placeholder {
  color: rgb(236 253 245 / 1) !important;
}

.tw-placeholder-emerald-50\/15::placeholder {
  color: rgb(236 253 245 / 0.15) !important;
}

.tw-placeholder-emerald-50\/20::placeholder {
  color: rgb(236 253 245 / 0.2) !important;
}

.tw-placeholder-emerald-50\/25::placeholder {
  color: rgb(236 253 245 / 0.25) !important;
}

.tw-placeholder-emerald-50\/30::placeholder {
  color: rgb(236 253 245 / 0.3) !important;
}

.tw-placeholder-emerald-50\/35::placeholder {
  color: rgb(236 253 245 / 0.35) !important;
}

.tw-placeholder-emerald-50\/40::placeholder {
  color: rgb(236 253 245 / 0.4) !important;
}

.tw-placeholder-emerald-50\/45::placeholder {
  color: rgb(236 253 245 / 0.45) !important;
}

.tw-placeholder-emerald-50\/5::placeholder {
  color: rgb(236 253 245 / 0.05) !important;
}

.tw-placeholder-emerald-50\/50::placeholder {
  color: rgb(236 253 245 / 0.5) !important;
}

.tw-placeholder-emerald-50\/55::placeholder {
  color: rgb(236 253 245 / 0.55) !important;
}

.tw-placeholder-emerald-50\/60::placeholder {
  color: rgb(236 253 245 / 0.6) !important;
}

.tw-placeholder-emerald-50\/65::placeholder {
  color: rgb(236 253 245 / 0.65) !important;
}

.tw-placeholder-emerald-50\/70::placeholder {
  color: rgb(236 253 245 / 0.7) !important;
}

.tw-placeholder-emerald-50\/75::placeholder {
  color: rgb(236 253 245 / 0.75) !important;
}

.tw-placeholder-emerald-50\/80::placeholder {
  color: rgb(236 253 245 / 0.8) !important;
}

.tw-placeholder-emerald-50\/85::placeholder {
  color: rgb(236 253 245 / 0.85) !important;
}

.tw-placeholder-emerald-50\/90::placeholder {
  color: rgb(236 253 245 / 0.9) !important;
}

.tw-placeholder-emerald-50\/95::placeholder {
  color: rgb(236 253 245 / 0.95) !important;
}

.tw-placeholder-emerald-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(16 185 129 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-500\/0::placeholder {
  color: rgb(16 185 129 / 0) !important;
}

.tw-placeholder-emerald-500\/10::placeholder {
  color: rgb(16 185 129 / 0.1) !important;
}

.tw-placeholder-emerald-500\/100::placeholder {
  color: rgb(16 185 129 / 1) !important;
}

.tw-placeholder-emerald-500\/15::placeholder {
  color: rgb(16 185 129 / 0.15) !important;
}

.tw-placeholder-emerald-500\/20::placeholder {
  color: rgb(16 185 129 / 0.2) !important;
}

.tw-placeholder-emerald-500\/25::placeholder {
  color: rgb(16 185 129 / 0.25) !important;
}

.tw-placeholder-emerald-500\/30::placeholder {
  color: rgb(16 185 129 / 0.3) !important;
}

.tw-placeholder-emerald-500\/35::placeholder {
  color: rgb(16 185 129 / 0.35) !important;
}

.tw-placeholder-emerald-500\/40::placeholder {
  color: rgb(16 185 129 / 0.4) !important;
}

.tw-placeholder-emerald-500\/45::placeholder {
  color: rgb(16 185 129 / 0.45) !important;
}

.tw-placeholder-emerald-500\/5::placeholder {
  color: rgb(16 185 129 / 0.05) !important;
}

.tw-placeholder-emerald-500\/50::placeholder {
  color: rgb(16 185 129 / 0.5) !important;
}

.tw-placeholder-emerald-500\/55::placeholder {
  color: rgb(16 185 129 / 0.55) !important;
}

.tw-placeholder-emerald-500\/60::placeholder {
  color: rgb(16 185 129 / 0.6) !important;
}

.tw-placeholder-emerald-500\/65::placeholder {
  color: rgb(16 185 129 / 0.65) !important;
}

.tw-placeholder-emerald-500\/70::placeholder {
  color: rgb(16 185 129 / 0.7) !important;
}

.tw-placeholder-emerald-500\/75::placeholder {
  color: rgb(16 185 129 / 0.75) !important;
}

.tw-placeholder-emerald-500\/80::placeholder {
  color: rgb(16 185 129 / 0.8) !important;
}

.tw-placeholder-emerald-500\/85::placeholder {
  color: rgb(16 185 129 / 0.85) !important;
}

.tw-placeholder-emerald-500\/90::placeholder {
  color: rgb(16 185 129 / 0.9) !important;
}

.tw-placeholder-emerald-500\/95::placeholder {
  color: rgb(16 185 129 / 0.95) !important;
}

.tw-placeholder-emerald-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(5 150 105 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-600\/0::placeholder {
  color: rgb(5 150 105 / 0) !important;
}

.tw-placeholder-emerald-600\/10::placeholder {
  color: rgb(5 150 105 / 0.1) !important;
}

.tw-placeholder-emerald-600\/100::placeholder {
  color: rgb(5 150 105 / 1) !important;
}

.tw-placeholder-emerald-600\/15::placeholder {
  color: rgb(5 150 105 / 0.15) !important;
}

.tw-placeholder-emerald-600\/20::placeholder {
  color: rgb(5 150 105 / 0.2) !important;
}

.tw-placeholder-emerald-600\/25::placeholder {
  color: rgb(5 150 105 / 0.25) !important;
}

.tw-placeholder-emerald-600\/30::placeholder {
  color: rgb(5 150 105 / 0.3) !important;
}

.tw-placeholder-emerald-600\/35::placeholder {
  color: rgb(5 150 105 / 0.35) !important;
}

.tw-placeholder-emerald-600\/40::placeholder {
  color: rgb(5 150 105 / 0.4) !important;
}

.tw-placeholder-emerald-600\/45::placeholder {
  color: rgb(5 150 105 / 0.45) !important;
}

.tw-placeholder-emerald-600\/5::placeholder {
  color: rgb(5 150 105 / 0.05) !important;
}

.tw-placeholder-emerald-600\/50::placeholder {
  color: rgb(5 150 105 / 0.5) !important;
}

.tw-placeholder-emerald-600\/55::placeholder {
  color: rgb(5 150 105 / 0.55) !important;
}

.tw-placeholder-emerald-600\/60::placeholder {
  color: rgb(5 150 105 / 0.6) !important;
}

.tw-placeholder-emerald-600\/65::placeholder {
  color: rgb(5 150 105 / 0.65) !important;
}

.tw-placeholder-emerald-600\/70::placeholder {
  color: rgb(5 150 105 / 0.7) !important;
}

.tw-placeholder-emerald-600\/75::placeholder {
  color: rgb(5 150 105 / 0.75) !important;
}

.tw-placeholder-emerald-600\/80::placeholder {
  color: rgb(5 150 105 / 0.8) !important;
}

.tw-placeholder-emerald-600\/85::placeholder {
  color: rgb(5 150 105 / 0.85) !important;
}

.tw-placeholder-emerald-600\/90::placeholder {
  color: rgb(5 150 105 / 0.9) !important;
}

.tw-placeholder-emerald-600\/95::placeholder {
  color: rgb(5 150 105 / 0.95) !important;
}

.tw-placeholder-emerald-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(4 120 87 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-700\/0::placeholder {
  color: rgb(4 120 87 / 0) !important;
}

.tw-placeholder-emerald-700\/10::placeholder {
  color: rgb(4 120 87 / 0.1) !important;
}

.tw-placeholder-emerald-700\/100::placeholder {
  color: rgb(4 120 87 / 1) !important;
}

.tw-placeholder-emerald-700\/15::placeholder {
  color: rgb(4 120 87 / 0.15) !important;
}

.tw-placeholder-emerald-700\/20::placeholder {
  color: rgb(4 120 87 / 0.2) !important;
}

.tw-placeholder-emerald-700\/25::placeholder {
  color: rgb(4 120 87 / 0.25) !important;
}

.tw-placeholder-emerald-700\/30::placeholder {
  color: rgb(4 120 87 / 0.3) !important;
}

.tw-placeholder-emerald-700\/35::placeholder {
  color: rgb(4 120 87 / 0.35) !important;
}

.tw-placeholder-emerald-700\/40::placeholder {
  color: rgb(4 120 87 / 0.4) !important;
}

.tw-placeholder-emerald-700\/45::placeholder {
  color: rgb(4 120 87 / 0.45) !important;
}

.tw-placeholder-emerald-700\/5::placeholder {
  color: rgb(4 120 87 / 0.05) !important;
}

.tw-placeholder-emerald-700\/50::placeholder {
  color: rgb(4 120 87 / 0.5) !important;
}

.tw-placeholder-emerald-700\/55::placeholder {
  color: rgb(4 120 87 / 0.55) !important;
}

.tw-placeholder-emerald-700\/60::placeholder {
  color: rgb(4 120 87 / 0.6) !important;
}

.tw-placeholder-emerald-700\/65::placeholder {
  color: rgb(4 120 87 / 0.65) !important;
}

.tw-placeholder-emerald-700\/70::placeholder {
  color: rgb(4 120 87 / 0.7) !important;
}

.tw-placeholder-emerald-700\/75::placeholder {
  color: rgb(4 120 87 / 0.75) !important;
}

.tw-placeholder-emerald-700\/80::placeholder {
  color: rgb(4 120 87 / 0.8) !important;
}

.tw-placeholder-emerald-700\/85::placeholder {
  color: rgb(4 120 87 / 0.85) !important;
}

.tw-placeholder-emerald-700\/90::placeholder {
  color: rgb(4 120 87 / 0.9) !important;
}

.tw-placeholder-emerald-700\/95::placeholder {
  color: rgb(4 120 87 / 0.95) !important;
}

.tw-placeholder-emerald-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(6 95 70 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-800\/0::placeholder {
  color: rgb(6 95 70 / 0) !important;
}

.tw-placeholder-emerald-800\/10::placeholder {
  color: rgb(6 95 70 / 0.1) !important;
}

.tw-placeholder-emerald-800\/100::placeholder {
  color: rgb(6 95 70 / 1) !important;
}

.tw-placeholder-emerald-800\/15::placeholder {
  color: rgb(6 95 70 / 0.15) !important;
}

.tw-placeholder-emerald-800\/20::placeholder {
  color: rgb(6 95 70 / 0.2) !important;
}

.tw-placeholder-emerald-800\/25::placeholder {
  color: rgb(6 95 70 / 0.25) !important;
}

.tw-placeholder-emerald-800\/30::placeholder {
  color: rgb(6 95 70 / 0.3) !important;
}

.tw-placeholder-emerald-800\/35::placeholder {
  color: rgb(6 95 70 / 0.35) !important;
}

.tw-placeholder-emerald-800\/40::placeholder {
  color: rgb(6 95 70 / 0.4) !important;
}

.tw-placeholder-emerald-800\/45::placeholder {
  color: rgb(6 95 70 / 0.45) !important;
}

.tw-placeholder-emerald-800\/5::placeholder {
  color: rgb(6 95 70 / 0.05) !important;
}

.tw-placeholder-emerald-800\/50::placeholder {
  color: rgb(6 95 70 / 0.5) !important;
}

.tw-placeholder-emerald-800\/55::placeholder {
  color: rgb(6 95 70 / 0.55) !important;
}

.tw-placeholder-emerald-800\/60::placeholder {
  color: rgb(6 95 70 / 0.6) !important;
}

.tw-placeholder-emerald-800\/65::placeholder {
  color: rgb(6 95 70 / 0.65) !important;
}

.tw-placeholder-emerald-800\/70::placeholder {
  color: rgb(6 95 70 / 0.7) !important;
}

.tw-placeholder-emerald-800\/75::placeholder {
  color: rgb(6 95 70 / 0.75) !important;
}

.tw-placeholder-emerald-800\/80::placeholder {
  color: rgb(6 95 70 / 0.8) !important;
}

.tw-placeholder-emerald-800\/85::placeholder {
  color: rgb(6 95 70 / 0.85) !important;
}

.tw-placeholder-emerald-800\/90::placeholder {
  color: rgb(6 95 70 / 0.9) !important;
}

.tw-placeholder-emerald-800\/95::placeholder {
  color: rgb(6 95 70 / 0.95) !important;
}

.tw-placeholder-emerald-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(6 78 59 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-900\/0::placeholder {
  color: rgb(6 78 59 / 0) !important;
}

.tw-placeholder-emerald-900\/10::placeholder {
  color: rgb(6 78 59 / 0.1) !important;
}

.tw-placeholder-emerald-900\/100::placeholder {
  color: rgb(6 78 59 / 1) !important;
}

.tw-placeholder-emerald-900\/15::placeholder {
  color: rgb(6 78 59 / 0.15) !important;
}

.tw-placeholder-emerald-900\/20::placeholder {
  color: rgb(6 78 59 / 0.2) !important;
}

.tw-placeholder-emerald-900\/25::placeholder {
  color: rgb(6 78 59 / 0.25) !important;
}

.tw-placeholder-emerald-900\/30::placeholder {
  color: rgb(6 78 59 / 0.3) !important;
}

.tw-placeholder-emerald-900\/35::placeholder {
  color: rgb(6 78 59 / 0.35) !important;
}

.tw-placeholder-emerald-900\/40::placeholder {
  color: rgb(6 78 59 / 0.4) !important;
}

.tw-placeholder-emerald-900\/45::placeholder {
  color: rgb(6 78 59 / 0.45) !important;
}

.tw-placeholder-emerald-900\/5::placeholder {
  color: rgb(6 78 59 / 0.05) !important;
}

.tw-placeholder-emerald-900\/50::placeholder {
  color: rgb(6 78 59 / 0.5) !important;
}

.tw-placeholder-emerald-900\/55::placeholder {
  color: rgb(6 78 59 / 0.55) !important;
}

.tw-placeholder-emerald-900\/60::placeholder {
  color: rgb(6 78 59 / 0.6) !important;
}

.tw-placeholder-emerald-900\/65::placeholder {
  color: rgb(6 78 59 / 0.65) !important;
}

.tw-placeholder-emerald-900\/70::placeholder {
  color: rgb(6 78 59 / 0.7) !important;
}

.tw-placeholder-emerald-900\/75::placeholder {
  color: rgb(6 78 59 / 0.75) !important;
}

.tw-placeholder-emerald-900\/80::placeholder {
  color: rgb(6 78 59 / 0.8) !important;
}

.tw-placeholder-emerald-900\/85::placeholder {
  color: rgb(6 78 59 / 0.85) !important;
}

.tw-placeholder-emerald-900\/90::placeholder {
  color: rgb(6 78 59 / 0.9) !important;
}

.tw-placeholder-emerald-900\/95::placeholder {
  color: rgb(6 78 59 / 0.95) !important;
}

.tw-placeholder-emerald-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(2 44 34 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-emerald-950\/0::placeholder {
  color: rgb(2 44 34 / 0) !important;
}

.tw-placeholder-emerald-950\/10::placeholder {
  color: rgb(2 44 34 / 0.1) !important;
}

.tw-placeholder-emerald-950\/100::placeholder {
  color: rgb(2 44 34 / 1) !important;
}

.tw-placeholder-emerald-950\/15::placeholder {
  color: rgb(2 44 34 / 0.15) !important;
}

.tw-placeholder-emerald-950\/20::placeholder {
  color: rgb(2 44 34 / 0.2) !important;
}

.tw-placeholder-emerald-950\/25::placeholder {
  color: rgb(2 44 34 / 0.25) !important;
}

.tw-placeholder-emerald-950\/30::placeholder {
  color: rgb(2 44 34 / 0.3) !important;
}

.tw-placeholder-emerald-950\/35::placeholder {
  color: rgb(2 44 34 / 0.35) !important;
}

.tw-placeholder-emerald-950\/40::placeholder {
  color: rgb(2 44 34 / 0.4) !important;
}

.tw-placeholder-emerald-950\/45::placeholder {
  color: rgb(2 44 34 / 0.45) !important;
}

.tw-placeholder-emerald-950\/5::placeholder {
  color: rgb(2 44 34 / 0.05) !important;
}

.tw-placeholder-emerald-950\/50::placeholder {
  color: rgb(2 44 34 / 0.5) !important;
}

.tw-placeholder-emerald-950\/55::placeholder {
  color: rgb(2 44 34 / 0.55) !important;
}

.tw-placeholder-emerald-950\/60::placeholder {
  color: rgb(2 44 34 / 0.6) !important;
}

.tw-placeholder-emerald-950\/65::placeholder {
  color: rgb(2 44 34 / 0.65) !important;
}

.tw-placeholder-emerald-950\/70::placeholder {
  color: rgb(2 44 34 / 0.7) !important;
}

.tw-placeholder-emerald-950\/75::placeholder {
  color: rgb(2 44 34 / 0.75) !important;
}

.tw-placeholder-emerald-950\/80::placeholder {
  color: rgb(2 44 34 / 0.8) !important;
}

.tw-placeholder-emerald-950\/85::placeholder {
  color: rgb(2 44 34 / 0.85) !important;
}

.tw-placeholder-emerald-950\/90::placeholder {
  color: rgb(2 44 34 / 0.9) !important;
}

.tw-placeholder-emerald-950\/95::placeholder {
  color: rgb(2 44 34 / 0.95) !important;
}

.tw-placeholder-fuchsia-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(250 232 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-100\/0::placeholder {
  color: rgb(250 232 255 / 0) !important;
}

.tw-placeholder-fuchsia-100\/10::placeholder {
  color: rgb(250 232 255 / 0.1) !important;
}

.tw-placeholder-fuchsia-100\/100::placeholder {
  color: rgb(250 232 255 / 1) !important;
}

.tw-placeholder-fuchsia-100\/15::placeholder {
  color: rgb(250 232 255 / 0.15) !important;
}

.tw-placeholder-fuchsia-100\/20::placeholder {
  color: rgb(250 232 255 / 0.2) !important;
}

.tw-placeholder-fuchsia-100\/25::placeholder {
  color: rgb(250 232 255 / 0.25) !important;
}

.tw-placeholder-fuchsia-100\/30::placeholder {
  color: rgb(250 232 255 / 0.3) !important;
}

.tw-placeholder-fuchsia-100\/35::placeholder {
  color: rgb(250 232 255 / 0.35) !important;
}

.tw-placeholder-fuchsia-100\/40::placeholder {
  color: rgb(250 232 255 / 0.4) !important;
}

.tw-placeholder-fuchsia-100\/45::placeholder {
  color: rgb(250 232 255 / 0.45) !important;
}

.tw-placeholder-fuchsia-100\/5::placeholder {
  color: rgb(250 232 255 / 0.05) !important;
}

.tw-placeholder-fuchsia-100\/50::placeholder {
  color: rgb(250 232 255 / 0.5) !important;
}

.tw-placeholder-fuchsia-100\/55::placeholder {
  color: rgb(250 232 255 / 0.55) !important;
}

.tw-placeholder-fuchsia-100\/60::placeholder {
  color: rgb(250 232 255 / 0.6) !important;
}

.tw-placeholder-fuchsia-100\/65::placeholder {
  color: rgb(250 232 255 / 0.65) !important;
}

.tw-placeholder-fuchsia-100\/70::placeholder {
  color: rgb(250 232 255 / 0.7) !important;
}

.tw-placeholder-fuchsia-100\/75::placeholder {
  color: rgb(250 232 255 / 0.75) !important;
}

.tw-placeholder-fuchsia-100\/80::placeholder {
  color: rgb(250 232 255 / 0.8) !important;
}

.tw-placeholder-fuchsia-100\/85::placeholder {
  color: rgb(250 232 255 / 0.85) !important;
}

.tw-placeholder-fuchsia-100\/90::placeholder {
  color: rgb(250 232 255 / 0.9) !important;
}

.tw-placeholder-fuchsia-100\/95::placeholder {
  color: rgb(250 232 255 / 0.95) !important;
}

.tw-placeholder-fuchsia-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(245 208 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-200\/0::placeholder {
  color: rgb(245 208 254 / 0) !important;
}

.tw-placeholder-fuchsia-200\/10::placeholder {
  color: rgb(245 208 254 / 0.1) !important;
}

.tw-placeholder-fuchsia-200\/100::placeholder {
  color: rgb(245 208 254 / 1) !important;
}

.tw-placeholder-fuchsia-200\/15::placeholder {
  color: rgb(245 208 254 / 0.15) !important;
}

.tw-placeholder-fuchsia-200\/20::placeholder {
  color: rgb(245 208 254 / 0.2) !important;
}

.tw-placeholder-fuchsia-200\/25::placeholder {
  color: rgb(245 208 254 / 0.25) !important;
}

.tw-placeholder-fuchsia-200\/30::placeholder {
  color: rgb(245 208 254 / 0.3) !important;
}

.tw-placeholder-fuchsia-200\/35::placeholder {
  color: rgb(245 208 254 / 0.35) !important;
}

.tw-placeholder-fuchsia-200\/40::placeholder {
  color: rgb(245 208 254 / 0.4) !important;
}

.tw-placeholder-fuchsia-200\/45::placeholder {
  color: rgb(245 208 254 / 0.45) !important;
}

.tw-placeholder-fuchsia-200\/5::placeholder {
  color: rgb(245 208 254 / 0.05) !important;
}

.tw-placeholder-fuchsia-200\/50::placeholder {
  color: rgb(245 208 254 / 0.5) !important;
}

.tw-placeholder-fuchsia-200\/55::placeholder {
  color: rgb(245 208 254 / 0.55) !important;
}

.tw-placeholder-fuchsia-200\/60::placeholder {
  color: rgb(245 208 254 / 0.6) !important;
}

.tw-placeholder-fuchsia-200\/65::placeholder {
  color: rgb(245 208 254 / 0.65) !important;
}

.tw-placeholder-fuchsia-200\/70::placeholder {
  color: rgb(245 208 254 / 0.7) !important;
}

.tw-placeholder-fuchsia-200\/75::placeholder {
  color: rgb(245 208 254 / 0.75) !important;
}

.tw-placeholder-fuchsia-200\/80::placeholder {
  color: rgb(245 208 254 / 0.8) !important;
}

.tw-placeholder-fuchsia-200\/85::placeholder {
  color: rgb(245 208 254 / 0.85) !important;
}

.tw-placeholder-fuchsia-200\/90::placeholder {
  color: rgb(245 208 254 / 0.9) !important;
}

.tw-placeholder-fuchsia-200\/95::placeholder {
  color: rgb(245 208 254 / 0.95) !important;
}

.tw-placeholder-fuchsia-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(240 171 252 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-300\/0::placeholder {
  color: rgb(240 171 252 / 0) !important;
}

.tw-placeholder-fuchsia-300\/10::placeholder {
  color: rgb(240 171 252 / 0.1) !important;
}

.tw-placeholder-fuchsia-300\/100::placeholder {
  color: rgb(240 171 252 / 1) !important;
}

.tw-placeholder-fuchsia-300\/15::placeholder {
  color: rgb(240 171 252 / 0.15) !important;
}

.tw-placeholder-fuchsia-300\/20::placeholder {
  color: rgb(240 171 252 / 0.2) !important;
}

.tw-placeholder-fuchsia-300\/25::placeholder {
  color: rgb(240 171 252 / 0.25) !important;
}

.tw-placeholder-fuchsia-300\/30::placeholder {
  color: rgb(240 171 252 / 0.3) !important;
}

.tw-placeholder-fuchsia-300\/35::placeholder {
  color: rgb(240 171 252 / 0.35) !important;
}

.tw-placeholder-fuchsia-300\/40::placeholder {
  color: rgb(240 171 252 / 0.4) !important;
}

.tw-placeholder-fuchsia-300\/45::placeholder {
  color: rgb(240 171 252 / 0.45) !important;
}

.tw-placeholder-fuchsia-300\/5::placeholder {
  color: rgb(240 171 252 / 0.05) !important;
}

.tw-placeholder-fuchsia-300\/50::placeholder {
  color: rgb(240 171 252 / 0.5) !important;
}

.tw-placeholder-fuchsia-300\/55::placeholder {
  color: rgb(240 171 252 / 0.55) !important;
}

.tw-placeholder-fuchsia-300\/60::placeholder {
  color: rgb(240 171 252 / 0.6) !important;
}

.tw-placeholder-fuchsia-300\/65::placeholder {
  color: rgb(240 171 252 / 0.65) !important;
}

.tw-placeholder-fuchsia-300\/70::placeholder {
  color: rgb(240 171 252 / 0.7) !important;
}

.tw-placeholder-fuchsia-300\/75::placeholder {
  color: rgb(240 171 252 / 0.75) !important;
}

.tw-placeholder-fuchsia-300\/80::placeholder {
  color: rgb(240 171 252 / 0.8) !important;
}

.tw-placeholder-fuchsia-300\/85::placeholder {
  color: rgb(240 171 252 / 0.85) !important;
}

.tw-placeholder-fuchsia-300\/90::placeholder {
  color: rgb(240 171 252 / 0.9) !important;
}

.tw-placeholder-fuchsia-300\/95::placeholder {
  color: rgb(240 171 252 / 0.95) !important;
}

.tw-placeholder-fuchsia-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(232 121 249 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-400\/0::placeholder {
  color: rgb(232 121 249 / 0) !important;
}

.tw-placeholder-fuchsia-400\/10::placeholder {
  color: rgb(232 121 249 / 0.1) !important;
}

.tw-placeholder-fuchsia-400\/100::placeholder {
  color: rgb(232 121 249 / 1) !important;
}

.tw-placeholder-fuchsia-400\/15::placeholder {
  color: rgb(232 121 249 / 0.15) !important;
}

.tw-placeholder-fuchsia-400\/20::placeholder {
  color: rgb(232 121 249 / 0.2) !important;
}

.tw-placeholder-fuchsia-400\/25::placeholder {
  color: rgb(232 121 249 / 0.25) !important;
}

.tw-placeholder-fuchsia-400\/30::placeholder {
  color: rgb(232 121 249 / 0.3) !important;
}

.tw-placeholder-fuchsia-400\/35::placeholder {
  color: rgb(232 121 249 / 0.35) !important;
}

.tw-placeholder-fuchsia-400\/40::placeholder {
  color: rgb(232 121 249 / 0.4) !important;
}

.tw-placeholder-fuchsia-400\/45::placeholder {
  color: rgb(232 121 249 / 0.45) !important;
}

.tw-placeholder-fuchsia-400\/5::placeholder {
  color: rgb(232 121 249 / 0.05) !important;
}

.tw-placeholder-fuchsia-400\/50::placeholder {
  color: rgb(232 121 249 / 0.5) !important;
}

.tw-placeholder-fuchsia-400\/55::placeholder {
  color: rgb(232 121 249 / 0.55) !important;
}

.tw-placeholder-fuchsia-400\/60::placeholder {
  color: rgb(232 121 249 / 0.6) !important;
}

.tw-placeholder-fuchsia-400\/65::placeholder {
  color: rgb(232 121 249 / 0.65) !important;
}

.tw-placeholder-fuchsia-400\/70::placeholder {
  color: rgb(232 121 249 / 0.7) !important;
}

.tw-placeholder-fuchsia-400\/75::placeholder {
  color: rgb(232 121 249 / 0.75) !important;
}

.tw-placeholder-fuchsia-400\/80::placeholder {
  color: rgb(232 121 249 / 0.8) !important;
}

.tw-placeholder-fuchsia-400\/85::placeholder {
  color: rgb(232 121 249 / 0.85) !important;
}

.tw-placeholder-fuchsia-400\/90::placeholder {
  color: rgb(232 121 249 / 0.9) !important;
}

.tw-placeholder-fuchsia-400\/95::placeholder {
  color: rgb(232 121 249 / 0.95) !important;
}

.tw-placeholder-fuchsia-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(253 244 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-50\/0::placeholder {
  color: rgb(253 244 255 / 0) !important;
}

.tw-placeholder-fuchsia-50\/10::placeholder {
  color: rgb(253 244 255 / 0.1) !important;
}

.tw-placeholder-fuchsia-50\/100::placeholder {
  color: rgb(253 244 255 / 1) !important;
}

.tw-placeholder-fuchsia-50\/15::placeholder {
  color: rgb(253 244 255 / 0.15) !important;
}

.tw-placeholder-fuchsia-50\/20::placeholder {
  color: rgb(253 244 255 / 0.2) !important;
}

.tw-placeholder-fuchsia-50\/25::placeholder {
  color: rgb(253 244 255 / 0.25) !important;
}

.tw-placeholder-fuchsia-50\/30::placeholder {
  color: rgb(253 244 255 / 0.3) !important;
}

.tw-placeholder-fuchsia-50\/35::placeholder {
  color: rgb(253 244 255 / 0.35) !important;
}

.tw-placeholder-fuchsia-50\/40::placeholder {
  color: rgb(253 244 255 / 0.4) !important;
}

.tw-placeholder-fuchsia-50\/45::placeholder {
  color: rgb(253 244 255 / 0.45) !important;
}

.tw-placeholder-fuchsia-50\/5::placeholder {
  color: rgb(253 244 255 / 0.05) !important;
}

.tw-placeholder-fuchsia-50\/50::placeholder {
  color: rgb(253 244 255 / 0.5) !important;
}

.tw-placeholder-fuchsia-50\/55::placeholder {
  color: rgb(253 244 255 / 0.55) !important;
}

.tw-placeholder-fuchsia-50\/60::placeholder {
  color: rgb(253 244 255 / 0.6) !important;
}

.tw-placeholder-fuchsia-50\/65::placeholder {
  color: rgb(253 244 255 / 0.65) !important;
}

.tw-placeholder-fuchsia-50\/70::placeholder {
  color: rgb(253 244 255 / 0.7) !important;
}

.tw-placeholder-fuchsia-50\/75::placeholder {
  color: rgb(253 244 255 / 0.75) !important;
}

.tw-placeholder-fuchsia-50\/80::placeholder {
  color: rgb(253 244 255 / 0.8) !important;
}

.tw-placeholder-fuchsia-50\/85::placeholder {
  color: rgb(253 244 255 / 0.85) !important;
}

.tw-placeholder-fuchsia-50\/90::placeholder {
  color: rgb(253 244 255 / 0.9) !important;
}

.tw-placeholder-fuchsia-50\/95::placeholder {
  color: rgb(253 244 255 / 0.95) !important;
}

.tw-placeholder-fuchsia-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(217 70 239 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-500\/0::placeholder {
  color: rgb(217 70 239 / 0) !important;
}

.tw-placeholder-fuchsia-500\/10::placeholder {
  color: rgb(217 70 239 / 0.1) !important;
}

.tw-placeholder-fuchsia-500\/100::placeholder {
  color: rgb(217 70 239 / 1) !important;
}

.tw-placeholder-fuchsia-500\/15::placeholder {
  color: rgb(217 70 239 / 0.15) !important;
}

.tw-placeholder-fuchsia-500\/20::placeholder {
  color: rgb(217 70 239 / 0.2) !important;
}

.tw-placeholder-fuchsia-500\/25::placeholder {
  color: rgb(217 70 239 / 0.25) !important;
}

.tw-placeholder-fuchsia-500\/30::placeholder {
  color: rgb(217 70 239 / 0.3) !important;
}

.tw-placeholder-fuchsia-500\/35::placeholder {
  color: rgb(217 70 239 / 0.35) !important;
}

.tw-placeholder-fuchsia-500\/40::placeholder {
  color: rgb(217 70 239 / 0.4) !important;
}

.tw-placeholder-fuchsia-500\/45::placeholder {
  color: rgb(217 70 239 / 0.45) !important;
}

.tw-placeholder-fuchsia-500\/5::placeholder {
  color: rgb(217 70 239 / 0.05) !important;
}

.tw-placeholder-fuchsia-500\/50::placeholder {
  color: rgb(217 70 239 / 0.5) !important;
}

.tw-placeholder-fuchsia-500\/55::placeholder {
  color: rgb(217 70 239 / 0.55) !important;
}

.tw-placeholder-fuchsia-500\/60::placeholder {
  color: rgb(217 70 239 / 0.6) !important;
}

.tw-placeholder-fuchsia-500\/65::placeholder {
  color: rgb(217 70 239 / 0.65) !important;
}

.tw-placeholder-fuchsia-500\/70::placeholder {
  color: rgb(217 70 239 / 0.7) !important;
}

.tw-placeholder-fuchsia-500\/75::placeholder {
  color: rgb(217 70 239 / 0.75) !important;
}

.tw-placeholder-fuchsia-500\/80::placeholder {
  color: rgb(217 70 239 / 0.8) !important;
}

.tw-placeholder-fuchsia-500\/85::placeholder {
  color: rgb(217 70 239 / 0.85) !important;
}

.tw-placeholder-fuchsia-500\/90::placeholder {
  color: rgb(217 70 239 / 0.9) !important;
}

.tw-placeholder-fuchsia-500\/95::placeholder {
  color: rgb(217 70 239 / 0.95) !important;
}

.tw-placeholder-fuchsia-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(192 38 211 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-600\/0::placeholder {
  color: rgb(192 38 211 / 0) !important;
}

.tw-placeholder-fuchsia-600\/10::placeholder {
  color: rgb(192 38 211 / 0.1) !important;
}

.tw-placeholder-fuchsia-600\/100::placeholder {
  color: rgb(192 38 211 / 1) !important;
}

.tw-placeholder-fuchsia-600\/15::placeholder {
  color: rgb(192 38 211 / 0.15) !important;
}

.tw-placeholder-fuchsia-600\/20::placeholder {
  color: rgb(192 38 211 / 0.2) !important;
}

.tw-placeholder-fuchsia-600\/25::placeholder {
  color: rgb(192 38 211 / 0.25) !important;
}

.tw-placeholder-fuchsia-600\/30::placeholder {
  color: rgb(192 38 211 / 0.3) !important;
}

.tw-placeholder-fuchsia-600\/35::placeholder {
  color: rgb(192 38 211 / 0.35) !important;
}

.tw-placeholder-fuchsia-600\/40::placeholder {
  color: rgb(192 38 211 / 0.4) !important;
}

.tw-placeholder-fuchsia-600\/45::placeholder {
  color: rgb(192 38 211 / 0.45) !important;
}

.tw-placeholder-fuchsia-600\/5::placeholder {
  color: rgb(192 38 211 / 0.05) !important;
}

.tw-placeholder-fuchsia-600\/50::placeholder {
  color: rgb(192 38 211 / 0.5) !important;
}

.tw-placeholder-fuchsia-600\/55::placeholder {
  color: rgb(192 38 211 / 0.55) !important;
}

.tw-placeholder-fuchsia-600\/60::placeholder {
  color: rgb(192 38 211 / 0.6) !important;
}

.tw-placeholder-fuchsia-600\/65::placeholder {
  color: rgb(192 38 211 / 0.65) !important;
}

.tw-placeholder-fuchsia-600\/70::placeholder {
  color: rgb(192 38 211 / 0.7) !important;
}

.tw-placeholder-fuchsia-600\/75::placeholder {
  color: rgb(192 38 211 / 0.75) !important;
}

.tw-placeholder-fuchsia-600\/80::placeholder {
  color: rgb(192 38 211 / 0.8) !important;
}

.tw-placeholder-fuchsia-600\/85::placeholder {
  color: rgb(192 38 211 / 0.85) !important;
}

.tw-placeholder-fuchsia-600\/90::placeholder {
  color: rgb(192 38 211 / 0.9) !important;
}

.tw-placeholder-fuchsia-600\/95::placeholder {
  color: rgb(192 38 211 / 0.95) !important;
}

.tw-placeholder-fuchsia-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(162 28 175 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-700\/0::placeholder {
  color: rgb(162 28 175 / 0) !important;
}

.tw-placeholder-fuchsia-700\/10::placeholder {
  color: rgb(162 28 175 / 0.1) !important;
}

.tw-placeholder-fuchsia-700\/100::placeholder {
  color: rgb(162 28 175 / 1) !important;
}

.tw-placeholder-fuchsia-700\/15::placeholder {
  color: rgb(162 28 175 / 0.15) !important;
}

.tw-placeholder-fuchsia-700\/20::placeholder {
  color: rgb(162 28 175 / 0.2) !important;
}

.tw-placeholder-fuchsia-700\/25::placeholder {
  color: rgb(162 28 175 / 0.25) !important;
}

.tw-placeholder-fuchsia-700\/30::placeholder {
  color: rgb(162 28 175 / 0.3) !important;
}

.tw-placeholder-fuchsia-700\/35::placeholder {
  color: rgb(162 28 175 / 0.35) !important;
}

.tw-placeholder-fuchsia-700\/40::placeholder {
  color: rgb(162 28 175 / 0.4) !important;
}

.tw-placeholder-fuchsia-700\/45::placeholder {
  color: rgb(162 28 175 / 0.45) !important;
}

.tw-placeholder-fuchsia-700\/5::placeholder {
  color: rgb(162 28 175 / 0.05) !important;
}

.tw-placeholder-fuchsia-700\/50::placeholder {
  color: rgb(162 28 175 / 0.5) !important;
}

.tw-placeholder-fuchsia-700\/55::placeholder {
  color: rgb(162 28 175 / 0.55) !important;
}

.tw-placeholder-fuchsia-700\/60::placeholder {
  color: rgb(162 28 175 / 0.6) !important;
}

.tw-placeholder-fuchsia-700\/65::placeholder {
  color: rgb(162 28 175 / 0.65) !important;
}

.tw-placeholder-fuchsia-700\/70::placeholder {
  color: rgb(162 28 175 / 0.7) !important;
}

.tw-placeholder-fuchsia-700\/75::placeholder {
  color: rgb(162 28 175 / 0.75) !important;
}

.tw-placeholder-fuchsia-700\/80::placeholder {
  color: rgb(162 28 175 / 0.8) !important;
}

.tw-placeholder-fuchsia-700\/85::placeholder {
  color: rgb(162 28 175 / 0.85) !important;
}

.tw-placeholder-fuchsia-700\/90::placeholder {
  color: rgb(162 28 175 / 0.9) !important;
}

.tw-placeholder-fuchsia-700\/95::placeholder {
  color: rgb(162 28 175 / 0.95) !important;
}

.tw-placeholder-fuchsia-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(134 25 143 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-800\/0::placeholder {
  color: rgb(134 25 143 / 0) !important;
}

.tw-placeholder-fuchsia-800\/10::placeholder {
  color: rgb(134 25 143 / 0.1) !important;
}

.tw-placeholder-fuchsia-800\/100::placeholder {
  color: rgb(134 25 143 / 1) !important;
}

.tw-placeholder-fuchsia-800\/15::placeholder {
  color: rgb(134 25 143 / 0.15) !important;
}

.tw-placeholder-fuchsia-800\/20::placeholder {
  color: rgb(134 25 143 / 0.2) !important;
}

.tw-placeholder-fuchsia-800\/25::placeholder {
  color: rgb(134 25 143 / 0.25) !important;
}

.tw-placeholder-fuchsia-800\/30::placeholder {
  color: rgb(134 25 143 / 0.3) !important;
}

.tw-placeholder-fuchsia-800\/35::placeholder {
  color: rgb(134 25 143 / 0.35) !important;
}

.tw-placeholder-fuchsia-800\/40::placeholder {
  color: rgb(134 25 143 / 0.4) !important;
}

.tw-placeholder-fuchsia-800\/45::placeholder {
  color: rgb(134 25 143 / 0.45) !important;
}

.tw-placeholder-fuchsia-800\/5::placeholder {
  color: rgb(134 25 143 / 0.05) !important;
}

.tw-placeholder-fuchsia-800\/50::placeholder {
  color: rgb(134 25 143 / 0.5) !important;
}

.tw-placeholder-fuchsia-800\/55::placeholder {
  color: rgb(134 25 143 / 0.55) !important;
}

.tw-placeholder-fuchsia-800\/60::placeholder {
  color: rgb(134 25 143 / 0.6) !important;
}

.tw-placeholder-fuchsia-800\/65::placeholder {
  color: rgb(134 25 143 / 0.65) !important;
}

.tw-placeholder-fuchsia-800\/70::placeholder {
  color: rgb(134 25 143 / 0.7) !important;
}

.tw-placeholder-fuchsia-800\/75::placeholder {
  color: rgb(134 25 143 / 0.75) !important;
}

.tw-placeholder-fuchsia-800\/80::placeholder {
  color: rgb(134 25 143 / 0.8) !important;
}

.tw-placeholder-fuchsia-800\/85::placeholder {
  color: rgb(134 25 143 / 0.85) !important;
}

.tw-placeholder-fuchsia-800\/90::placeholder {
  color: rgb(134 25 143 / 0.9) !important;
}

.tw-placeholder-fuchsia-800\/95::placeholder {
  color: rgb(134 25 143 / 0.95) !important;
}

.tw-placeholder-fuchsia-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(112 26 117 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-900\/0::placeholder {
  color: rgb(112 26 117 / 0) !important;
}

.tw-placeholder-fuchsia-900\/10::placeholder {
  color: rgb(112 26 117 / 0.1) !important;
}

.tw-placeholder-fuchsia-900\/100::placeholder {
  color: rgb(112 26 117 / 1) !important;
}

.tw-placeholder-fuchsia-900\/15::placeholder {
  color: rgb(112 26 117 / 0.15) !important;
}

.tw-placeholder-fuchsia-900\/20::placeholder {
  color: rgb(112 26 117 / 0.2) !important;
}

.tw-placeholder-fuchsia-900\/25::placeholder {
  color: rgb(112 26 117 / 0.25) !important;
}

.tw-placeholder-fuchsia-900\/30::placeholder {
  color: rgb(112 26 117 / 0.3) !important;
}

.tw-placeholder-fuchsia-900\/35::placeholder {
  color: rgb(112 26 117 / 0.35) !important;
}

.tw-placeholder-fuchsia-900\/40::placeholder {
  color: rgb(112 26 117 / 0.4) !important;
}

.tw-placeholder-fuchsia-900\/45::placeholder {
  color: rgb(112 26 117 / 0.45) !important;
}

.tw-placeholder-fuchsia-900\/5::placeholder {
  color: rgb(112 26 117 / 0.05) !important;
}

.tw-placeholder-fuchsia-900\/50::placeholder {
  color: rgb(112 26 117 / 0.5) !important;
}

.tw-placeholder-fuchsia-900\/55::placeholder {
  color: rgb(112 26 117 / 0.55) !important;
}

.tw-placeholder-fuchsia-900\/60::placeholder {
  color: rgb(112 26 117 / 0.6) !important;
}

.tw-placeholder-fuchsia-900\/65::placeholder {
  color: rgb(112 26 117 / 0.65) !important;
}

.tw-placeholder-fuchsia-900\/70::placeholder {
  color: rgb(112 26 117 / 0.7) !important;
}

.tw-placeholder-fuchsia-900\/75::placeholder {
  color: rgb(112 26 117 / 0.75) !important;
}

.tw-placeholder-fuchsia-900\/80::placeholder {
  color: rgb(112 26 117 / 0.8) !important;
}

.tw-placeholder-fuchsia-900\/85::placeholder {
  color: rgb(112 26 117 / 0.85) !important;
}

.tw-placeholder-fuchsia-900\/90::placeholder {
  color: rgb(112 26 117 / 0.9) !important;
}

.tw-placeholder-fuchsia-900\/95::placeholder {
  color: rgb(112 26 117 / 0.95) !important;
}

.tw-placeholder-fuchsia-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(74 4 78 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-fuchsia-950\/0::placeholder {
  color: rgb(74 4 78 / 0) !important;
}

.tw-placeholder-fuchsia-950\/10::placeholder {
  color: rgb(74 4 78 / 0.1) !important;
}

.tw-placeholder-fuchsia-950\/100::placeholder {
  color: rgb(74 4 78 / 1) !important;
}

.tw-placeholder-fuchsia-950\/15::placeholder {
  color: rgb(74 4 78 / 0.15) !important;
}

.tw-placeholder-fuchsia-950\/20::placeholder {
  color: rgb(74 4 78 / 0.2) !important;
}

.tw-placeholder-fuchsia-950\/25::placeholder {
  color: rgb(74 4 78 / 0.25) !important;
}

.tw-placeholder-fuchsia-950\/30::placeholder {
  color: rgb(74 4 78 / 0.3) !important;
}

.tw-placeholder-fuchsia-950\/35::placeholder {
  color: rgb(74 4 78 / 0.35) !important;
}

.tw-placeholder-fuchsia-950\/40::placeholder {
  color: rgb(74 4 78 / 0.4) !important;
}

.tw-placeholder-fuchsia-950\/45::placeholder {
  color: rgb(74 4 78 / 0.45) !important;
}

.tw-placeholder-fuchsia-950\/5::placeholder {
  color: rgb(74 4 78 / 0.05) !important;
}

.tw-placeholder-fuchsia-950\/50::placeholder {
  color: rgb(74 4 78 / 0.5) !important;
}

.tw-placeholder-fuchsia-950\/55::placeholder {
  color: rgb(74 4 78 / 0.55) !important;
}

.tw-placeholder-fuchsia-950\/60::placeholder {
  color: rgb(74 4 78 / 0.6) !important;
}

.tw-placeholder-fuchsia-950\/65::placeholder {
  color: rgb(74 4 78 / 0.65) !important;
}

.tw-placeholder-fuchsia-950\/70::placeholder {
  color: rgb(74 4 78 / 0.7) !important;
}

.tw-placeholder-fuchsia-950\/75::placeholder {
  color: rgb(74 4 78 / 0.75) !important;
}

.tw-placeholder-fuchsia-950\/80::placeholder {
  color: rgb(74 4 78 / 0.8) !important;
}

.tw-placeholder-fuchsia-950\/85::placeholder {
  color: rgb(74 4 78 / 0.85) !important;
}

.tw-placeholder-fuchsia-950\/90::placeholder {
  color: rgb(74 4 78 / 0.9) !important;
}

.tw-placeholder-fuchsia-950\/95::placeholder {
  color: rgb(74 4 78 / 0.95) !important;
}

.tw-placeholder-gray-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(243 244 246 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-100\/0::placeholder {
  color: rgb(243 244 246 / 0) !important;
}

.tw-placeholder-gray-100\/10::placeholder {
  color: rgb(243 244 246 / 0.1) !important;
}

.tw-placeholder-gray-100\/100::placeholder {
  color: rgb(243 244 246 / 1) !important;
}

.tw-placeholder-gray-100\/15::placeholder {
  color: rgb(243 244 246 / 0.15) !important;
}

.tw-placeholder-gray-100\/20::placeholder {
  color: rgb(243 244 246 / 0.2) !important;
}

.tw-placeholder-gray-100\/25::placeholder {
  color: rgb(243 244 246 / 0.25) !important;
}

.tw-placeholder-gray-100\/30::placeholder {
  color: rgb(243 244 246 / 0.3) !important;
}

.tw-placeholder-gray-100\/35::placeholder {
  color: rgb(243 244 246 / 0.35) !important;
}

.tw-placeholder-gray-100\/40::placeholder {
  color: rgb(243 244 246 / 0.4) !important;
}

.tw-placeholder-gray-100\/45::placeholder {
  color: rgb(243 244 246 / 0.45) !important;
}

.tw-placeholder-gray-100\/5::placeholder {
  color: rgb(243 244 246 / 0.05) !important;
}

.tw-placeholder-gray-100\/50::placeholder {
  color: rgb(243 244 246 / 0.5) !important;
}

.tw-placeholder-gray-100\/55::placeholder {
  color: rgb(243 244 246 / 0.55) !important;
}

.tw-placeholder-gray-100\/60::placeholder {
  color: rgb(243 244 246 / 0.6) !important;
}

.tw-placeholder-gray-100\/65::placeholder {
  color: rgb(243 244 246 / 0.65) !important;
}

.tw-placeholder-gray-100\/70::placeholder {
  color: rgb(243 244 246 / 0.7) !important;
}

.tw-placeholder-gray-100\/75::placeholder {
  color: rgb(243 244 246 / 0.75) !important;
}

.tw-placeholder-gray-100\/80::placeholder {
  color: rgb(243 244 246 / 0.8) !important;
}

.tw-placeholder-gray-100\/85::placeholder {
  color: rgb(243 244 246 / 0.85) !important;
}

.tw-placeholder-gray-100\/90::placeholder {
  color: rgb(243 244 246 / 0.9) !important;
}

.tw-placeholder-gray-100\/95::placeholder {
  color: rgb(243 244 246 / 0.95) !important;
}

.tw-placeholder-gray-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(229 231 235 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-200\/0::placeholder {
  color: rgb(229 231 235 / 0) !important;
}

.tw-placeholder-gray-200\/10::placeholder {
  color: rgb(229 231 235 / 0.1) !important;
}

.tw-placeholder-gray-200\/100::placeholder {
  color: rgb(229 231 235 / 1) !important;
}

.tw-placeholder-gray-200\/15::placeholder {
  color: rgb(229 231 235 / 0.15) !important;
}

.tw-placeholder-gray-200\/20::placeholder {
  color: rgb(229 231 235 / 0.2) !important;
}

.tw-placeholder-gray-200\/25::placeholder {
  color: rgb(229 231 235 / 0.25) !important;
}

.tw-placeholder-gray-200\/30::placeholder {
  color: rgb(229 231 235 / 0.3) !important;
}

.tw-placeholder-gray-200\/35::placeholder {
  color: rgb(229 231 235 / 0.35) !important;
}

.tw-placeholder-gray-200\/40::placeholder {
  color: rgb(229 231 235 / 0.4) !important;
}

.tw-placeholder-gray-200\/45::placeholder {
  color: rgb(229 231 235 / 0.45) !important;
}

.tw-placeholder-gray-200\/5::placeholder {
  color: rgb(229 231 235 / 0.05) !important;
}

.tw-placeholder-gray-200\/50::placeholder {
  color: rgb(229 231 235 / 0.5) !important;
}

.tw-placeholder-gray-200\/55::placeholder {
  color: rgb(229 231 235 / 0.55) !important;
}

.tw-placeholder-gray-200\/60::placeholder {
  color: rgb(229 231 235 / 0.6) !important;
}

.tw-placeholder-gray-200\/65::placeholder {
  color: rgb(229 231 235 / 0.65) !important;
}

.tw-placeholder-gray-200\/70::placeholder {
  color: rgb(229 231 235 / 0.7) !important;
}

.tw-placeholder-gray-200\/75::placeholder {
  color: rgb(229 231 235 / 0.75) !important;
}

.tw-placeholder-gray-200\/80::placeholder {
  color: rgb(229 231 235 / 0.8) !important;
}

.tw-placeholder-gray-200\/85::placeholder {
  color: rgb(229 231 235 / 0.85) !important;
}

.tw-placeholder-gray-200\/90::placeholder {
  color: rgb(229 231 235 / 0.9) !important;
}

.tw-placeholder-gray-200\/95::placeholder {
  color: rgb(229 231 235 / 0.95) !important;
}

.tw-placeholder-gray-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(209 213 219 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-300\/0::placeholder {
  color: rgb(209 213 219 / 0) !important;
}

.tw-placeholder-gray-300\/10::placeholder {
  color: rgb(209 213 219 / 0.1) !important;
}

.tw-placeholder-gray-300\/100::placeholder {
  color: rgb(209 213 219 / 1) !important;
}

.tw-placeholder-gray-300\/15::placeholder {
  color: rgb(209 213 219 / 0.15) !important;
}

.tw-placeholder-gray-300\/20::placeholder {
  color: rgb(209 213 219 / 0.2) !important;
}

.tw-placeholder-gray-300\/25::placeholder {
  color: rgb(209 213 219 / 0.25) !important;
}

.tw-placeholder-gray-300\/30::placeholder {
  color: rgb(209 213 219 / 0.3) !important;
}

.tw-placeholder-gray-300\/35::placeholder {
  color: rgb(209 213 219 / 0.35) !important;
}

.tw-placeholder-gray-300\/40::placeholder {
  color: rgb(209 213 219 / 0.4) !important;
}

.tw-placeholder-gray-300\/45::placeholder {
  color: rgb(209 213 219 / 0.45) !important;
}

.tw-placeholder-gray-300\/5::placeholder {
  color: rgb(209 213 219 / 0.05) !important;
}

.tw-placeholder-gray-300\/50::placeholder {
  color: rgb(209 213 219 / 0.5) !important;
}

.tw-placeholder-gray-300\/55::placeholder {
  color: rgb(209 213 219 / 0.55) !important;
}

.tw-placeholder-gray-300\/60::placeholder {
  color: rgb(209 213 219 / 0.6) !important;
}

.tw-placeholder-gray-300\/65::placeholder {
  color: rgb(209 213 219 / 0.65) !important;
}

.tw-placeholder-gray-300\/70::placeholder {
  color: rgb(209 213 219 / 0.7) !important;
}

.tw-placeholder-gray-300\/75::placeholder {
  color: rgb(209 213 219 / 0.75) !important;
}

.tw-placeholder-gray-300\/80::placeholder {
  color: rgb(209 213 219 / 0.8) !important;
}

.tw-placeholder-gray-300\/85::placeholder {
  color: rgb(209 213 219 / 0.85) !important;
}

.tw-placeholder-gray-300\/90::placeholder {
  color: rgb(209 213 219 / 0.9) !important;
}

.tw-placeholder-gray-300\/95::placeholder {
  color: rgb(209 213 219 / 0.95) !important;
}

.tw-placeholder-gray-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(156 163 175 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-400\/0::placeholder {
  color: rgb(156 163 175 / 0) !important;
}

.tw-placeholder-gray-400\/10::placeholder {
  color: rgb(156 163 175 / 0.1) !important;
}

.tw-placeholder-gray-400\/100::placeholder {
  color: rgb(156 163 175 / 1) !important;
}

.tw-placeholder-gray-400\/15::placeholder {
  color: rgb(156 163 175 / 0.15) !important;
}

.tw-placeholder-gray-400\/20::placeholder {
  color: rgb(156 163 175 / 0.2) !important;
}

.tw-placeholder-gray-400\/25::placeholder {
  color: rgb(156 163 175 / 0.25) !important;
}

.tw-placeholder-gray-400\/30::placeholder {
  color: rgb(156 163 175 / 0.3) !important;
}

.tw-placeholder-gray-400\/35::placeholder {
  color: rgb(156 163 175 / 0.35) !important;
}

.tw-placeholder-gray-400\/40::placeholder {
  color: rgb(156 163 175 / 0.4) !important;
}

.tw-placeholder-gray-400\/45::placeholder {
  color: rgb(156 163 175 / 0.45) !important;
}

.tw-placeholder-gray-400\/5::placeholder {
  color: rgb(156 163 175 / 0.05) !important;
}

.tw-placeholder-gray-400\/50::placeholder {
  color: rgb(156 163 175 / 0.5) !important;
}

.tw-placeholder-gray-400\/55::placeholder {
  color: rgb(156 163 175 / 0.55) !important;
}

.tw-placeholder-gray-400\/60::placeholder {
  color: rgb(156 163 175 / 0.6) !important;
}

.tw-placeholder-gray-400\/65::placeholder {
  color: rgb(156 163 175 / 0.65) !important;
}

.tw-placeholder-gray-400\/70::placeholder {
  color: rgb(156 163 175 / 0.7) !important;
}

.tw-placeholder-gray-400\/75::placeholder {
  color: rgb(156 163 175 / 0.75) !important;
}

.tw-placeholder-gray-400\/80::placeholder {
  color: rgb(156 163 175 / 0.8) !important;
}

.tw-placeholder-gray-400\/85::placeholder {
  color: rgb(156 163 175 / 0.85) !important;
}

.tw-placeholder-gray-400\/90::placeholder {
  color: rgb(156 163 175 / 0.9) !important;
}

.tw-placeholder-gray-400\/95::placeholder {
  color: rgb(156 163 175 / 0.95) !important;
}

.tw-placeholder-gray-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(249 250 251 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-50\/0::placeholder {
  color: rgb(249 250 251 / 0) !important;
}

.tw-placeholder-gray-50\/10::placeholder {
  color: rgb(249 250 251 / 0.1) !important;
}

.tw-placeholder-gray-50\/100::placeholder {
  color: rgb(249 250 251 / 1) !important;
}

.tw-placeholder-gray-50\/15::placeholder {
  color: rgb(249 250 251 / 0.15) !important;
}

.tw-placeholder-gray-50\/20::placeholder {
  color: rgb(249 250 251 / 0.2) !important;
}

.tw-placeholder-gray-50\/25::placeholder {
  color: rgb(249 250 251 / 0.25) !important;
}

.tw-placeholder-gray-50\/30::placeholder {
  color: rgb(249 250 251 / 0.3) !important;
}

.tw-placeholder-gray-50\/35::placeholder {
  color: rgb(249 250 251 / 0.35) !important;
}

.tw-placeholder-gray-50\/40::placeholder {
  color: rgb(249 250 251 / 0.4) !important;
}

.tw-placeholder-gray-50\/45::placeholder {
  color: rgb(249 250 251 / 0.45) !important;
}

.tw-placeholder-gray-50\/5::placeholder {
  color: rgb(249 250 251 / 0.05) !important;
}

.tw-placeholder-gray-50\/50::placeholder {
  color: rgb(249 250 251 / 0.5) !important;
}

.tw-placeholder-gray-50\/55::placeholder {
  color: rgb(249 250 251 / 0.55) !important;
}

.tw-placeholder-gray-50\/60::placeholder {
  color: rgb(249 250 251 / 0.6) !important;
}

.tw-placeholder-gray-50\/65::placeholder {
  color: rgb(249 250 251 / 0.65) !important;
}

.tw-placeholder-gray-50\/70::placeholder {
  color: rgb(249 250 251 / 0.7) !important;
}

.tw-placeholder-gray-50\/75::placeholder {
  color: rgb(249 250 251 / 0.75) !important;
}

.tw-placeholder-gray-50\/80::placeholder {
  color: rgb(249 250 251 / 0.8) !important;
}

.tw-placeholder-gray-50\/85::placeholder {
  color: rgb(249 250 251 / 0.85) !important;
}

.tw-placeholder-gray-50\/90::placeholder {
  color: rgb(249 250 251 / 0.9) !important;
}

.tw-placeholder-gray-50\/95::placeholder {
  color: rgb(249 250 251 / 0.95) !important;
}

.tw-placeholder-gray-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(107 114 128 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-500\/0::placeholder {
  color: rgb(107 114 128 / 0) !important;
}

.tw-placeholder-gray-500\/10::placeholder {
  color: rgb(107 114 128 / 0.1) !important;
}

.tw-placeholder-gray-500\/100::placeholder {
  color: rgb(107 114 128 / 1) !important;
}

.tw-placeholder-gray-500\/15::placeholder {
  color: rgb(107 114 128 / 0.15) !important;
}

.tw-placeholder-gray-500\/20::placeholder {
  color: rgb(107 114 128 / 0.2) !important;
}

.tw-placeholder-gray-500\/25::placeholder {
  color: rgb(107 114 128 / 0.25) !important;
}

.tw-placeholder-gray-500\/30::placeholder {
  color: rgb(107 114 128 / 0.3) !important;
}

.tw-placeholder-gray-500\/35::placeholder {
  color: rgb(107 114 128 / 0.35) !important;
}

.tw-placeholder-gray-500\/40::placeholder {
  color: rgb(107 114 128 / 0.4) !important;
}

.tw-placeholder-gray-500\/45::placeholder {
  color: rgb(107 114 128 / 0.45) !important;
}

.tw-placeholder-gray-500\/5::placeholder {
  color: rgb(107 114 128 / 0.05) !important;
}

.tw-placeholder-gray-500\/50::placeholder {
  color: rgb(107 114 128 / 0.5) !important;
}

.tw-placeholder-gray-500\/55::placeholder {
  color: rgb(107 114 128 / 0.55) !important;
}

.tw-placeholder-gray-500\/60::placeholder {
  color: rgb(107 114 128 / 0.6) !important;
}

.tw-placeholder-gray-500\/65::placeholder {
  color: rgb(107 114 128 / 0.65) !important;
}

.tw-placeholder-gray-500\/70::placeholder {
  color: rgb(107 114 128 / 0.7) !important;
}

.tw-placeholder-gray-500\/75::placeholder {
  color: rgb(107 114 128 / 0.75) !important;
}

.tw-placeholder-gray-500\/80::placeholder {
  color: rgb(107 114 128 / 0.8) !important;
}

.tw-placeholder-gray-500\/85::placeholder {
  color: rgb(107 114 128 / 0.85) !important;
}

.tw-placeholder-gray-500\/90::placeholder {
  color: rgb(107 114 128 / 0.9) !important;
}

.tw-placeholder-gray-500\/95::placeholder {
  color: rgb(107 114 128 / 0.95) !important;
}

.tw-placeholder-gray-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-600\/0::placeholder {
  color: rgb(75 85 99 / 0) !important;
}

.tw-placeholder-gray-600\/10::placeholder {
  color: rgb(75 85 99 / 0.1) !important;
}

.tw-placeholder-gray-600\/100::placeholder {
  color: rgb(75 85 99 / 1) !important;
}

.tw-placeholder-gray-600\/15::placeholder {
  color: rgb(75 85 99 / 0.15) !important;
}

.tw-placeholder-gray-600\/20::placeholder {
  color: rgb(75 85 99 / 0.2) !important;
}

.tw-placeholder-gray-600\/25::placeholder {
  color: rgb(75 85 99 / 0.25) !important;
}

.tw-placeholder-gray-600\/30::placeholder {
  color: rgb(75 85 99 / 0.3) !important;
}

.tw-placeholder-gray-600\/35::placeholder {
  color: rgb(75 85 99 / 0.35) !important;
}

.tw-placeholder-gray-600\/40::placeholder {
  color: rgb(75 85 99 / 0.4) !important;
}

.tw-placeholder-gray-600\/45::placeholder {
  color: rgb(75 85 99 / 0.45) !important;
}

.tw-placeholder-gray-600\/5::placeholder {
  color: rgb(75 85 99 / 0.05) !important;
}

.tw-placeholder-gray-600\/50::placeholder {
  color: rgb(75 85 99 / 0.5) !important;
}

.tw-placeholder-gray-600\/55::placeholder {
  color: rgb(75 85 99 / 0.55) !important;
}

.tw-placeholder-gray-600\/60::placeholder {
  color: rgb(75 85 99 / 0.6) !important;
}

.tw-placeholder-gray-600\/65::placeholder {
  color: rgb(75 85 99 / 0.65) !important;
}

.tw-placeholder-gray-600\/70::placeholder {
  color: rgb(75 85 99 / 0.7) !important;
}

.tw-placeholder-gray-600\/75::placeholder {
  color: rgb(75 85 99 / 0.75) !important;
}

.tw-placeholder-gray-600\/80::placeholder {
  color: rgb(75 85 99 / 0.8) !important;
}

.tw-placeholder-gray-600\/85::placeholder {
  color: rgb(75 85 99 / 0.85) !important;
}

.tw-placeholder-gray-600\/90::placeholder {
  color: rgb(75 85 99 / 0.9) !important;
}

.tw-placeholder-gray-600\/95::placeholder {
  color: rgb(75 85 99 / 0.95) !important;
}

.tw-placeholder-gray-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(55 65 81 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-700\/0::placeholder {
  color: rgb(55 65 81 / 0) !important;
}

.tw-placeholder-gray-700\/10::placeholder {
  color: rgb(55 65 81 / 0.1) !important;
}

.tw-placeholder-gray-700\/100::placeholder {
  color: rgb(55 65 81 / 1) !important;
}

.tw-placeholder-gray-700\/15::placeholder {
  color: rgb(55 65 81 / 0.15) !important;
}

.tw-placeholder-gray-700\/20::placeholder {
  color: rgb(55 65 81 / 0.2) !important;
}

.tw-placeholder-gray-700\/25::placeholder {
  color: rgb(55 65 81 / 0.25) !important;
}

.tw-placeholder-gray-700\/30::placeholder {
  color: rgb(55 65 81 / 0.3) !important;
}

.tw-placeholder-gray-700\/35::placeholder {
  color: rgb(55 65 81 / 0.35) !important;
}

.tw-placeholder-gray-700\/40::placeholder {
  color: rgb(55 65 81 / 0.4) !important;
}

.tw-placeholder-gray-700\/45::placeholder {
  color: rgb(55 65 81 / 0.45) !important;
}

.tw-placeholder-gray-700\/5::placeholder {
  color: rgb(55 65 81 / 0.05) !important;
}

.tw-placeholder-gray-700\/50::placeholder {
  color: rgb(55 65 81 / 0.5) !important;
}

.tw-placeholder-gray-700\/55::placeholder {
  color: rgb(55 65 81 / 0.55) !important;
}

.tw-placeholder-gray-700\/60::placeholder {
  color: rgb(55 65 81 / 0.6) !important;
}

.tw-placeholder-gray-700\/65::placeholder {
  color: rgb(55 65 81 / 0.65) !important;
}

.tw-placeholder-gray-700\/70::placeholder {
  color: rgb(55 65 81 / 0.7) !important;
}

.tw-placeholder-gray-700\/75::placeholder {
  color: rgb(55 65 81 / 0.75) !important;
}

.tw-placeholder-gray-700\/80::placeholder {
  color: rgb(55 65 81 / 0.8) !important;
}

.tw-placeholder-gray-700\/85::placeholder {
  color: rgb(55 65 81 / 0.85) !important;
}

.tw-placeholder-gray-700\/90::placeholder {
  color: rgb(55 65 81 / 0.9) !important;
}

.tw-placeholder-gray-700\/95::placeholder {
  color: rgb(55 65 81 / 0.95) !important;
}

.tw-placeholder-gray-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(31 41 55 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-800\/0::placeholder {
  color: rgb(31 41 55 / 0) !important;
}

.tw-placeholder-gray-800\/10::placeholder {
  color: rgb(31 41 55 / 0.1) !important;
}

.tw-placeholder-gray-800\/100::placeholder {
  color: rgb(31 41 55 / 1) !important;
}

.tw-placeholder-gray-800\/15::placeholder {
  color: rgb(31 41 55 / 0.15) !important;
}

.tw-placeholder-gray-800\/20::placeholder {
  color: rgb(31 41 55 / 0.2) !important;
}

.tw-placeholder-gray-800\/25::placeholder {
  color: rgb(31 41 55 / 0.25) !important;
}

.tw-placeholder-gray-800\/30::placeholder {
  color: rgb(31 41 55 / 0.3) !important;
}

.tw-placeholder-gray-800\/35::placeholder {
  color: rgb(31 41 55 / 0.35) !important;
}

.tw-placeholder-gray-800\/40::placeholder {
  color: rgb(31 41 55 / 0.4) !important;
}

.tw-placeholder-gray-800\/45::placeholder {
  color: rgb(31 41 55 / 0.45) !important;
}

.tw-placeholder-gray-800\/5::placeholder {
  color: rgb(31 41 55 / 0.05) !important;
}

.tw-placeholder-gray-800\/50::placeholder {
  color: rgb(31 41 55 / 0.5) !important;
}

.tw-placeholder-gray-800\/55::placeholder {
  color: rgb(31 41 55 / 0.55) !important;
}

.tw-placeholder-gray-800\/60::placeholder {
  color: rgb(31 41 55 / 0.6) !important;
}

.tw-placeholder-gray-800\/65::placeholder {
  color: rgb(31 41 55 / 0.65) !important;
}

.tw-placeholder-gray-800\/70::placeholder {
  color: rgb(31 41 55 / 0.7) !important;
}

.tw-placeholder-gray-800\/75::placeholder {
  color: rgb(31 41 55 / 0.75) !important;
}

.tw-placeholder-gray-800\/80::placeholder {
  color: rgb(31 41 55 / 0.8) !important;
}

.tw-placeholder-gray-800\/85::placeholder {
  color: rgb(31 41 55 / 0.85) !important;
}

.tw-placeholder-gray-800\/90::placeholder {
  color: rgb(31 41 55 / 0.9) !important;
}

.tw-placeholder-gray-800\/95::placeholder {
  color: rgb(31 41 55 / 0.95) !important;
}

.tw-placeholder-gray-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(17 24 39 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-900\/0::placeholder {
  color: rgb(17 24 39 / 0) !important;
}

.tw-placeholder-gray-900\/10::placeholder {
  color: rgb(17 24 39 / 0.1) !important;
}

.tw-placeholder-gray-900\/100::placeholder {
  color: rgb(17 24 39 / 1) !important;
}

.tw-placeholder-gray-900\/15::placeholder {
  color: rgb(17 24 39 / 0.15) !important;
}

.tw-placeholder-gray-900\/20::placeholder {
  color: rgb(17 24 39 / 0.2) !important;
}

.tw-placeholder-gray-900\/25::placeholder {
  color: rgb(17 24 39 / 0.25) !important;
}

.tw-placeholder-gray-900\/30::placeholder {
  color: rgb(17 24 39 / 0.3) !important;
}

.tw-placeholder-gray-900\/35::placeholder {
  color: rgb(17 24 39 / 0.35) !important;
}

.tw-placeholder-gray-900\/40::placeholder {
  color: rgb(17 24 39 / 0.4) !important;
}

.tw-placeholder-gray-900\/45::placeholder {
  color: rgb(17 24 39 / 0.45) !important;
}

.tw-placeholder-gray-900\/5::placeholder {
  color: rgb(17 24 39 / 0.05) !important;
}

.tw-placeholder-gray-900\/50::placeholder {
  color: rgb(17 24 39 / 0.5) !important;
}

.tw-placeholder-gray-900\/55::placeholder {
  color: rgb(17 24 39 / 0.55) !important;
}

.tw-placeholder-gray-900\/60::placeholder {
  color: rgb(17 24 39 / 0.6) !important;
}

.tw-placeholder-gray-900\/65::placeholder {
  color: rgb(17 24 39 / 0.65) !important;
}

.tw-placeholder-gray-900\/70::placeholder {
  color: rgb(17 24 39 / 0.7) !important;
}

.tw-placeholder-gray-900\/75::placeholder {
  color: rgb(17 24 39 / 0.75) !important;
}

.tw-placeholder-gray-900\/80::placeholder {
  color: rgb(17 24 39 / 0.8) !important;
}

.tw-placeholder-gray-900\/85::placeholder {
  color: rgb(17 24 39 / 0.85) !important;
}

.tw-placeholder-gray-900\/90::placeholder {
  color: rgb(17 24 39 / 0.9) !important;
}

.tw-placeholder-gray-900\/95::placeholder {
  color: rgb(17 24 39 / 0.95) !important;
}

.tw-placeholder-gray-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(3 7 18 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-gray-950\/0::placeholder {
  color: rgb(3 7 18 / 0) !important;
}

.tw-placeholder-gray-950\/10::placeholder {
  color: rgb(3 7 18 / 0.1) !important;
}

.tw-placeholder-gray-950\/100::placeholder {
  color: rgb(3 7 18 / 1) !important;
}

.tw-placeholder-gray-950\/15::placeholder {
  color: rgb(3 7 18 / 0.15) !important;
}

.tw-placeholder-gray-950\/20::placeholder {
  color: rgb(3 7 18 / 0.2) !important;
}

.tw-placeholder-gray-950\/25::placeholder {
  color: rgb(3 7 18 / 0.25) !important;
}

.tw-placeholder-gray-950\/30::placeholder {
  color: rgb(3 7 18 / 0.3) !important;
}

.tw-placeholder-gray-950\/35::placeholder {
  color: rgb(3 7 18 / 0.35) !important;
}

.tw-placeholder-gray-950\/40::placeholder {
  color: rgb(3 7 18 / 0.4) !important;
}

.tw-placeholder-gray-950\/45::placeholder {
  color: rgb(3 7 18 / 0.45) !important;
}

.tw-placeholder-gray-950\/5::placeholder {
  color: rgb(3 7 18 / 0.05) !important;
}

.tw-placeholder-gray-950\/50::placeholder {
  color: rgb(3 7 18 / 0.5) !important;
}

.tw-placeholder-gray-950\/55::placeholder {
  color: rgb(3 7 18 / 0.55) !important;
}

.tw-placeholder-gray-950\/60::placeholder {
  color: rgb(3 7 18 / 0.6) !important;
}

.tw-placeholder-gray-950\/65::placeholder {
  color: rgb(3 7 18 / 0.65) !important;
}

.tw-placeholder-gray-950\/70::placeholder {
  color: rgb(3 7 18 / 0.7) !important;
}

.tw-placeholder-gray-950\/75::placeholder {
  color: rgb(3 7 18 / 0.75) !important;
}

.tw-placeholder-gray-950\/80::placeholder {
  color: rgb(3 7 18 / 0.8) !important;
}

.tw-placeholder-gray-950\/85::placeholder {
  color: rgb(3 7 18 / 0.85) !important;
}

.tw-placeholder-gray-950\/90::placeholder {
  color: rgb(3 7 18 / 0.9) !important;
}

.tw-placeholder-gray-950\/95::placeholder {
  color: rgb(3 7 18 / 0.95) !important;
}

.tw-placeholder-green-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(220 252 231 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-100\/0::placeholder {
  color: rgb(220 252 231 / 0) !important;
}

.tw-placeholder-green-100\/10::placeholder {
  color: rgb(220 252 231 / 0.1) !important;
}

.tw-placeholder-green-100\/100::placeholder {
  color: rgb(220 252 231 / 1) !important;
}

.tw-placeholder-green-100\/15::placeholder {
  color: rgb(220 252 231 / 0.15) !important;
}

.tw-placeholder-green-100\/20::placeholder {
  color: rgb(220 252 231 / 0.2) !important;
}

.tw-placeholder-green-100\/25::placeholder {
  color: rgb(220 252 231 / 0.25) !important;
}

.tw-placeholder-green-100\/30::placeholder {
  color: rgb(220 252 231 / 0.3) !important;
}

.tw-placeholder-green-100\/35::placeholder {
  color: rgb(220 252 231 / 0.35) !important;
}

.tw-placeholder-green-100\/40::placeholder {
  color: rgb(220 252 231 / 0.4) !important;
}

.tw-placeholder-green-100\/45::placeholder {
  color: rgb(220 252 231 / 0.45) !important;
}

.tw-placeholder-green-100\/5::placeholder {
  color: rgb(220 252 231 / 0.05) !important;
}

.tw-placeholder-green-100\/50::placeholder {
  color: rgb(220 252 231 / 0.5) !important;
}

.tw-placeholder-green-100\/55::placeholder {
  color: rgb(220 252 231 / 0.55) !important;
}

.tw-placeholder-green-100\/60::placeholder {
  color: rgb(220 252 231 / 0.6) !important;
}

.tw-placeholder-green-100\/65::placeholder {
  color: rgb(220 252 231 / 0.65) !important;
}

.tw-placeholder-green-100\/70::placeholder {
  color: rgb(220 252 231 / 0.7) !important;
}

.tw-placeholder-green-100\/75::placeholder {
  color: rgb(220 252 231 / 0.75) !important;
}

.tw-placeholder-green-100\/80::placeholder {
  color: rgb(220 252 231 / 0.8) !important;
}

.tw-placeholder-green-100\/85::placeholder {
  color: rgb(220 252 231 / 0.85) !important;
}

.tw-placeholder-green-100\/90::placeholder {
  color: rgb(220 252 231 / 0.9) !important;
}

.tw-placeholder-green-100\/95::placeholder {
  color: rgb(220 252 231 / 0.95) !important;
}

.tw-placeholder-green-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(187 247 208 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-200\/0::placeholder {
  color: rgb(187 247 208 / 0) !important;
}

.tw-placeholder-green-200\/10::placeholder {
  color: rgb(187 247 208 / 0.1) !important;
}

.tw-placeholder-green-200\/100::placeholder {
  color: rgb(187 247 208 / 1) !important;
}

.tw-placeholder-green-200\/15::placeholder {
  color: rgb(187 247 208 / 0.15) !important;
}

.tw-placeholder-green-200\/20::placeholder {
  color: rgb(187 247 208 / 0.2) !important;
}

.tw-placeholder-green-200\/25::placeholder {
  color: rgb(187 247 208 / 0.25) !important;
}

.tw-placeholder-green-200\/30::placeholder {
  color: rgb(187 247 208 / 0.3) !important;
}

.tw-placeholder-green-200\/35::placeholder {
  color: rgb(187 247 208 / 0.35) !important;
}

.tw-placeholder-green-200\/40::placeholder {
  color: rgb(187 247 208 / 0.4) !important;
}

.tw-placeholder-green-200\/45::placeholder {
  color: rgb(187 247 208 / 0.45) !important;
}

.tw-placeholder-green-200\/5::placeholder {
  color: rgb(187 247 208 / 0.05) !important;
}

.tw-placeholder-green-200\/50::placeholder {
  color: rgb(187 247 208 / 0.5) !important;
}

.tw-placeholder-green-200\/55::placeholder {
  color: rgb(187 247 208 / 0.55) !important;
}

.tw-placeholder-green-200\/60::placeholder {
  color: rgb(187 247 208 / 0.6) !important;
}

.tw-placeholder-green-200\/65::placeholder {
  color: rgb(187 247 208 / 0.65) !important;
}

.tw-placeholder-green-200\/70::placeholder {
  color: rgb(187 247 208 / 0.7) !important;
}

.tw-placeholder-green-200\/75::placeholder {
  color: rgb(187 247 208 / 0.75) !important;
}

.tw-placeholder-green-200\/80::placeholder {
  color: rgb(187 247 208 / 0.8) !important;
}

.tw-placeholder-green-200\/85::placeholder {
  color: rgb(187 247 208 / 0.85) !important;
}

.tw-placeholder-green-200\/90::placeholder {
  color: rgb(187 247 208 / 0.9) !important;
}

.tw-placeholder-green-200\/95::placeholder {
  color: rgb(187 247 208 / 0.95) !important;
}

.tw-placeholder-green-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(134 239 172 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-300\/0::placeholder {
  color: rgb(134 239 172 / 0) !important;
}

.tw-placeholder-green-300\/10::placeholder {
  color: rgb(134 239 172 / 0.1) !important;
}

.tw-placeholder-green-300\/100::placeholder {
  color: rgb(134 239 172 / 1) !important;
}

.tw-placeholder-green-300\/15::placeholder {
  color: rgb(134 239 172 / 0.15) !important;
}

.tw-placeholder-green-300\/20::placeholder {
  color: rgb(134 239 172 / 0.2) !important;
}

.tw-placeholder-green-300\/25::placeholder {
  color: rgb(134 239 172 / 0.25) !important;
}

.tw-placeholder-green-300\/30::placeholder {
  color: rgb(134 239 172 / 0.3) !important;
}

.tw-placeholder-green-300\/35::placeholder {
  color: rgb(134 239 172 / 0.35) !important;
}

.tw-placeholder-green-300\/40::placeholder {
  color: rgb(134 239 172 / 0.4) !important;
}

.tw-placeholder-green-300\/45::placeholder {
  color: rgb(134 239 172 / 0.45) !important;
}

.tw-placeholder-green-300\/5::placeholder {
  color: rgb(134 239 172 / 0.05) !important;
}

.tw-placeholder-green-300\/50::placeholder {
  color: rgb(134 239 172 / 0.5) !important;
}

.tw-placeholder-green-300\/55::placeholder {
  color: rgb(134 239 172 / 0.55) !important;
}

.tw-placeholder-green-300\/60::placeholder {
  color: rgb(134 239 172 / 0.6) !important;
}

.tw-placeholder-green-300\/65::placeholder {
  color: rgb(134 239 172 / 0.65) !important;
}

.tw-placeholder-green-300\/70::placeholder {
  color: rgb(134 239 172 / 0.7) !important;
}

.tw-placeholder-green-300\/75::placeholder {
  color: rgb(134 239 172 / 0.75) !important;
}

.tw-placeholder-green-300\/80::placeholder {
  color: rgb(134 239 172 / 0.8) !important;
}

.tw-placeholder-green-300\/85::placeholder {
  color: rgb(134 239 172 / 0.85) !important;
}

.tw-placeholder-green-300\/90::placeholder {
  color: rgb(134 239 172 / 0.9) !important;
}

.tw-placeholder-green-300\/95::placeholder {
  color: rgb(134 239 172 / 0.95) !important;
}

.tw-placeholder-green-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(74 222 128 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-400\/0::placeholder {
  color: rgb(74 222 128 / 0) !important;
}

.tw-placeholder-green-400\/10::placeholder {
  color: rgb(74 222 128 / 0.1) !important;
}

.tw-placeholder-green-400\/100::placeholder {
  color: rgb(74 222 128 / 1) !important;
}

.tw-placeholder-green-400\/15::placeholder {
  color: rgb(74 222 128 / 0.15) !important;
}

.tw-placeholder-green-400\/20::placeholder {
  color: rgb(74 222 128 / 0.2) !important;
}

.tw-placeholder-green-400\/25::placeholder {
  color: rgb(74 222 128 / 0.25) !important;
}

.tw-placeholder-green-400\/30::placeholder {
  color: rgb(74 222 128 / 0.3) !important;
}

.tw-placeholder-green-400\/35::placeholder {
  color: rgb(74 222 128 / 0.35) !important;
}

.tw-placeholder-green-400\/40::placeholder {
  color: rgb(74 222 128 / 0.4) !important;
}

.tw-placeholder-green-400\/45::placeholder {
  color: rgb(74 222 128 / 0.45) !important;
}

.tw-placeholder-green-400\/5::placeholder {
  color: rgb(74 222 128 / 0.05) !important;
}

.tw-placeholder-green-400\/50::placeholder {
  color: rgb(74 222 128 / 0.5) !important;
}

.tw-placeholder-green-400\/55::placeholder {
  color: rgb(74 222 128 / 0.55) !important;
}

.tw-placeholder-green-400\/60::placeholder {
  color: rgb(74 222 128 / 0.6) !important;
}

.tw-placeholder-green-400\/65::placeholder {
  color: rgb(74 222 128 / 0.65) !important;
}

.tw-placeholder-green-400\/70::placeholder {
  color: rgb(74 222 128 / 0.7) !important;
}

.tw-placeholder-green-400\/75::placeholder {
  color: rgb(74 222 128 / 0.75) !important;
}

.tw-placeholder-green-400\/80::placeholder {
  color: rgb(74 222 128 / 0.8) !important;
}

.tw-placeholder-green-400\/85::placeholder {
  color: rgb(74 222 128 / 0.85) !important;
}

.tw-placeholder-green-400\/90::placeholder {
  color: rgb(74 222 128 / 0.9) !important;
}

.tw-placeholder-green-400\/95::placeholder {
  color: rgb(74 222 128 / 0.95) !important;
}

.tw-placeholder-green-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(240 253 244 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-50\/0::placeholder {
  color: rgb(240 253 244 / 0) !important;
}

.tw-placeholder-green-50\/10::placeholder {
  color: rgb(240 253 244 / 0.1) !important;
}

.tw-placeholder-green-50\/100::placeholder {
  color: rgb(240 253 244 / 1) !important;
}

.tw-placeholder-green-50\/15::placeholder {
  color: rgb(240 253 244 / 0.15) !important;
}

.tw-placeholder-green-50\/20::placeholder {
  color: rgb(240 253 244 / 0.2) !important;
}

.tw-placeholder-green-50\/25::placeholder {
  color: rgb(240 253 244 / 0.25) !important;
}

.tw-placeholder-green-50\/30::placeholder {
  color: rgb(240 253 244 / 0.3) !important;
}

.tw-placeholder-green-50\/35::placeholder {
  color: rgb(240 253 244 / 0.35) !important;
}

.tw-placeholder-green-50\/40::placeholder {
  color: rgb(240 253 244 / 0.4) !important;
}

.tw-placeholder-green-50\/45::placeholder {
  color: rgb(240 253 244 / 0.45) !important;
}

.tw-placeholder-green-50\/5::placeholder {
  color: rgb(240 253 244 / 0.05) !important;
}

.tw-placeholder-green-50\/50::placeholder {
  color: rgb(240 253 244 / 0.5) !important;
}

.tw-placeholder-green-50\/55::placeholder {
  color: rgb(240 253 244 / 0.55) !important;
}

.tw-placeholder-green-50\/60::placeholder {
  color: rgb(240 253 244 / 0.6) !important;
}

.tw-placeholder-green-50\/65::placeholder {
  color: rgb(240 253 244 / 0.65) !important;
}

.tw-placeholder-green-50\/70::placeholder {
  color: rgb(240 253 244 / 0.7) !important;
}

.tw-placeholder-green-50\/75::placeholder {
  color: rgb(240 253 244 / 0.75) !important;
}

.tw-placeholder-green-50\/80::placeholder {
  color: rgb(240 253 244 / 0.8) !important;
}

.tw-placeholder-green-50\/85::placeholder {
  color: rgb(240 253 244 / 0.85) !important;
}

.tw-placeholder-green-50\/90::placeholder {
  color: rgb(240 253 244 / 0.9) !important;
}

.tw-placeholder-green-50\/95::placeholder {
  color: rgb(240 253 244 / 0.95) !important;
}

.tw-placeholder-green-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(34 197 94 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-500\/0::placeholder {
  color: rgb(34 197 94 / 0) !important;
}

.tw-placeholder-green-500\/10::placeholder {
  color: rgb(34 197 94 / 0.1) !important;
}

.tw-placeholder-green-500\/100::placeholder {
  color: rgb(34 197 94 / 1) !important;
}

.tw-placeholder-green-500\/15::placeholder {
  color: rgb(34 197 94 / 0.15) !important;
}

.tw-placeholder-green-500\/20::placeholder {
  color: rgb(34 197 94 / 0.2) !important;
}

.tw-placeholder-green-500\/25::placeholder {
  color: rgb(34 197 94 / 0.25) !important;
}

.tw-placeholder-green-500\/30::placeholder {
  color: rgb(34 197 94 / 0.3) !important;
}

.tw-placeholder-green-500\/35::placeholder {
  color: rgb(34 197 94 / 0.35) !important;
}

.tw-placeholder-green-500\/40::placeholder {
  color: rgb(34 197 94 / 0.4) !important;
}

.tw-placeholder-green-500\/45::placeholder {
  color: rgb(34 197 94 / 0.45) !important;
}

.tw-placeholder-green-500\/5::placeholder {
  color: rgb(34 197 94 / 0.05) !important;
}

.tw-placeholder-green-500\/50::placeholder {
  color: rgb(34 197 94 / 0.5) !important;
}

.tw-placeholder-green-500\/55::placeholder {
  color: rgb(34 197 94 / 0.55) !important;
}

.tw-placeholder-green-500\/60::placeholder {
  color: rgb(34 197 94 / 0.6) !important;
}

.tw-placeholder-green-500\/65::placeholder {
  color: rgb(34 197 94 / 0.65) !important;
}

.tw-placeholder-green-500\/70::placeholder {
  color: rgb(34 197 94 / 0.7) !important;
}

.tw-placeholder-green-500\/75::placeholder {
  color: rgb(34 197 94 / 0.75) !important;
}

.tw-placeholder-green-500\/80::placeholder {
  color: rgb(34 197 94 / 0.8) !important;
}

.tw-placeholder-green-500\/85::placeholder {
  color: rgb(34 197 94 / 0.85) !important;
}

.tw-placeholder-green-500\/90::placeholder {
  color: rgb(34 197 94 / 0.9) !important;
}

.tw-placeholder-green-500\/95::placeholder {
  color: rgb(34 197 94 / 0.95) !important;
}

.tw-placeholder-green-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(22 163 74 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-600\/0::placeholder {
  color: rgb(22 163 74 / 0) !important;
}

.tw-placeholder-green-600\/10::placeholder {
  color: rgb(22 163 74 / 0.1) !important;
}

.tw-placeholder-green-600\/100::placeholder {
  color: rgb(22 163 74 / 1) !important;
}

.tw-placeholder-green-600\/15::placeholder {
  color: rgb(22 163 74 / 0.15) !important;
}

.tw-placeholder-green-600\/20::placeholder {
  color: rgb(22 163 74 / 0.2) !important;
}

.tw-placeholder-green-600\/25::placeholder {
  color: rgb(22 163 74 / 0.25) !important;
}

.tw-placeholder-green-600\/30::placeholder {
  color: rgb(22 163 74 / 0.3) !important;
}

.tw-placeholder-green-600\/35::placeholder {
  color: rgb(22 163 74 / 0.35) !important;
}

.tw-placeholder-green-600\/40::placeholder {
  color: rgb(22 163 74 / 0.4) !important;
}

.tw-placeholder-green-600\/45::placeholder {
  color: rgb(22 163 74 / 0.45) !important;
}

.tw-placeholder-green-600\/5::placeholder {
  color: rgb(22 163 74 / 0.05) !important;
}

.tw-placeholder-green-600\/50::placeholder {
  color: rgb(22 163 74 / 0.5) !important;
}

.tw-placeholder-green-600\/55::placeholder {
  color: rgb(22 163 74 / 0.55) !important;
}

.tw-placeholder-green-600\/60::placeholder {
  color: rgb(22 163 74 / 0.6) !important;
}

.tw-placeholder-green-600\/65::placeholder {
  color: rgb(22 163 74 / 0.65) !important;
}

.tw-placeholder-green-600\/70::placeholder {
  color: rgb(22 163 74 / 0.7) !important;
}

.tw-placeholder-green-600\/75::placeholder {
  color: rgb(22 163 74 / 0.75) !important;
}

.tw-placeholder-green-600\/80::placeholder {
  color: rgb(22 163 74 / 0.8) !important;
}

.tw-placeholder-green-600\/85::placeholder {
  color: rgb(22 163 74 / 0.85) !important;
}

.tw-placeholder-green-600\/90::placeholder {
  color: rgb(22 163 74 / 0.9) !important;
}

.tw-placeholder-green-600\/95::placeholder {
  color: rgb(22 163 74 / 0.95) !important;
}

.tw-placeholder-green-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(21 128 61 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-700\/0::placeholder {
  color: rgb(21 128 61 / 0) !important;
}

.tw-placeholder-green-700\/10::placeholder {
  color: rgb(21 128 61 / 0.1) !important;
}

.tw-placeholder-green-700\/100::placeholder {
  color: rgb(21 128 61 / 1) !important;
}

.tw-placeholder-green-700\/15::placeholder {
  color: rgb(21 128 61 / 0.15) !important;
}

.tw-placeholder-green-700\/20::placeholder {
  color: rgb(21 128 61 / 0.2) !important;
}

.tw-placeholder-green-700\/25::placeholder {
  color: rgb(21 128 61 / 0.25) !important;
}

.tw-placeholder-green-700\/30::placeholder {
  color: rgb(21 128 61 / 0.3) !important;
}

.tw-placeholder-green-700\/35::placeholder {
  color: rgb(21 128 61 / 0.35) !important;
}

.tw-placeholder-green-700\/40::placeholder {
  color: rgb(21 128 61 / 0.4) !important;
}

.tw-placeholder-green-700\/45::placeholder {
  color: rgb(21 128 61 / 0.45) !important;
}

.tw-placeholder-green-700\/5::placeholder {
  color: rgb(21 128 61 / 0.05) !important;
}

.tw-placeholder-green-700\/50::placeholder {
  color: rgb(21 128 61 / 0.5) !important;
}

.tw-placeholder-green-700\/55::placeholder {
  color: rgb(21 128 61 / 0.55) !important;
}

.tw-placeholder-green-700\/60::placeholder {
  color: rgb(21 128 61 / 0.6) !important;
}

.tw-placeholder-green-700\/65::placeholder {
  color: rgb(21 128 61 / 0.65) !important;
}

.tw-placeholder-green-700\/70::placeholder {
  color: rgb(21 128 61 / 0.7) !important;
}

.tw-placeholder-green-700\/75::placeholder {
  color: rgb(21 128 61 / 0.75) !important;
}

.tw-placeholder-green-700\/80::placeholder {
  color: rgb(21 128 61 / 0.8) !important;
}

.tw-placeholder-green-700\/85::placeholder {
  color: rgb(21 128 61 / 0.85) !important;
}

.tw-placeholder-green-700\/90::placeholder {
  color: rgb(21 128 61 / 0.9) !important;
}

.tw-placeholder-green-700\/95::placeholder {
  color: rgb(21 128 61 / 0.95) !important;
}

.tw-placeholder-green-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(22 101 52 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-800\/0::placeholder {
  color: rgb(22 101 52 / 0) !important;
}

.tw-placeholder-green-800\/10::placeholder {
  color: rgb(22 101 52 / 0.1) !important;
}

.tw-placeholder-green-800\/100::placeholder {
  color: rgb(22 101 52 / 1) !important;
}

.tw-placeholder-green-800\/15::placeholder {
  color: rgb(22 101 52 / 0.15) !important;
}

.tw-placeholder-green-800\/20::placeholder {
  color: rgb(22 101 52 / 0.2) !important;
}

.tw-placeholder-green-800\/25::placeholder {
  color: rgb(22 101 52 / 0.25) !important;
}

.tw-placeholder-green-800\/30::placeholder {
  color: rgb(22 101 52 / 0.3) !important;
}

.tw-placeholder-green-800\/35::placeholder {
  color: rgb(22 101 52 / 0.35) !important;
}

.tw-placeholder-green-800\/40::placeholder {
  color: rgb(22 101 52 / 0.4) !important;
}

.tw-placeholder-green-800\/45::placeholder {
  color: rgb(22 101 52 / 0.45) !important;
}

.tw-placeholder-green-800\/5::placeholder {
  color: rgb(22 101 52 / 0.05) !important;
}

.tw-placeholder-green-800\/50::placeholder {
  color: rgb(22 101 52 / 0.5) !important;
}

.tw-placeholder-green-800\/55::placeholder {
  color: rgb(22 101 52 / 0.55) !important;
}

.tw-placeholder-green-800\/60::placeholder {
  color: rgb(22 101 52 / 0.6) !important;
}

.tw-placeholder-green-800\/65::placeholder {
  color: rgb(22 101 52 / 0.65) !important;
}

.tw-placeholder-green-800\/70::placeholder {
  color: rgb(22 101 52 / 0.7) !important;
}

.tw-placeholder-green-800\/75::placeholder {
  color: rgb(22 101 52 / 0.75) !important;
}

.tw-placeholder-green-800\/80::placeholder {
  color: rgb(22 101 52 / 0.8) !important;
}

.tw-placeholder-green-800\/85::placeholder {
  color: rgb(22 101 52 / 0.85) !important;
}

.tw-placeholder-green-800\/90::placeholder {
  color: rgb(22 101 52 / 0.9) !important;
}

.tw-placeholder-green-800\/95::placeholder {
  color: rgb(22 101 52 / 0.95) !important;
}

.tw-placeholder-green-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(20 83 45 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-900\/0::placeholder {
  color: rgb(20 83 45 / 0) !important;
}

.tw-placeholder-green-900\/10::placeholder {
  color: rgb(20 83 45 / 0.1) !important;
}

.tw-placeholder-green-900\/100::placeholder {
  color: rgb(20 83 45 / 1) !important;
}

.tw-placeholder-green-900\/15::placeholder {
  color: rgb(20 83 45 / 0.15) !important;
}

.tw-placeholder-green-900\/20::placeholder {
  color: rgb(20 83 45 / 0.2) !important;
}

.tw-placeholder-green-900\/25::placeholder {
  color: rgb(20 83 45 / 0.25) !important;
}

.tw-placeholder-green-900\/30::placeholder {
  color: rgb(20 83 45 / 0.3) !important;
}

.tw-placeholder-green-900\/35::placeholder {
  color: rgb(20 83 45 / 0.35) !important;
}

.tw-placeholder-green-900\/40::placeholder {
  color: rgb(20 83 45 / 0.4) !important;
}

.tw-placeholder-green-900\/45::placeholder {
  color: rgb(20 83 45 / 0.45) !important;
}

.tw-placeholder-green-900\/5::placeholder {
  color: rgb(20 83 45 / 0.05) !important;
}

.tw-placeholder-green-900\/50::placeholder {
  color: rgb(20 83 45 / 0.5) !important;
}

.tw-placeholder-green-900\/55::placeholder {
  color: rgb(20 83 45 / 0.55) !important;
}

.tw-placeholder-green-900\/60::placeholder {
  color: rgb(20 83 45 / 0.6) !important;
}

.tw-placeholder-green-900\/65::placeholder {
  color: rgb(20 83 45 / 0.65) !important;
}

.tw-placeholder-green-900\/70::placeholder {
  color: rgb(20 83 45 / 0.7) !important;
}

.tw-placeholder-green-900\/75::placeholder {
  color: rgb(20 83 45 / 0.75) !important;
}

.tw-placeholder-green-900\/80::placeholder {
  color: rgb(20 83 45 / 0.8) !important;
}

.tw-placeholder-green-900\/85::placeholder {
  color: rgb(20 83 45 / 0.85) !important;
}

.tw-placeholder-green-900\/90::placeholder {
  color: rgb(20 83 45 / 0.9) !important;
}

.tw-placeholder-green-900\/95::placeholder {
  color: rgb(20 83 45 / 0.95) !important;
}

.tw-placeholder-green-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(5 46 22 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-green-950\/0::placeholder {
  color: rgb(5 46 22 / 0) !important;
}

.tw-placeholder-green-950\/10::placeholder {
  color: rgb(5 46 22 / 0.1) !important;
}

.tw-placeholder-green-950\/100::placeholder {
  color: rgb(5 46 22 / 1) !important;
}

.tw-placeholder-green-950\/15::placeholder {
  color: rgb(5 46 22 / 0.15) !important;
}

.tw-placeholder-green-950\/20::placeholder {
  color: rgb(5 46 22 / 0.2) !important;
}

.tw-placeholder-green-950\/25::placeholder {
  color: rgb(5 46 22 / 0.25) !important;
}

.tw-placeholder-green-950\/30::placeholder {
  color: rgb(5 46 22 / 0.3) !important;
}

.tw-placeholder-green-950\/35::placeholder {
  color: rgb(5 46 22 / 0.35) !important;
}

.tw-placeholder-green-950\/40::placeholder {
  color: rgb(5 46 22 / 0.4) !important;
}

.tw-placeholder-green-950\/45::placeholder {
  color: rgb(5 46 22 / 0.45) !important;
}

.tw-placeholder-green-950\/5::placeholder {
  color: rgb(5 46 22 / 0.05) !important;
}

.tw-placeholder-green-950\/50::placeholder {
  color: rgb(5 46 22 / 0.5) !important;
}

.tw-placeholder-green-950\/55::placeholder {
  color: rgb(5 46 22 / 0.55) !important;
}

.tw-placeholder-green-950\/60::placeholder {
  color: rgb(5 46 22 / 0.6) !important;
}

.tw-placeholder-green-950\/65::placeholder {
  color: rgb(5 46 22 / 0.65) !important;
}

.tw-placeholder-green-950\/70::placeholder {
  color: rgb(5 46 22 / 0.7) !important;
}

.tw-placeholder-green-950\/75::placeholder {
  color: rgb(5 46 22 / 0.75) !important;
}

.tw-placeholder-green-950\/80::placeholder {
  color: rgb(5 46 22 / 0.8) !important;
}

.tw-placeholder-green-950\/85::placeholder {
  color: rgb(5 46 22 / 0.85) !important;
}

.tw-placeholder-green-950\/90::placeholder {
  color: rgb(5 46 22 / 0.9) !important;
}

.tw-placeholder-green-950\/95::placeholder {
  color: rgb(5 46 22 / 0.95) !important;
}

.tw-placeholder-indigo-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(224 231 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-100\/0::placeholder {
  color: rgb(224 231 255 / 0) !important;
}

.tw-placeholder-indigo-100\/10::placeholder {
  color: rgb(224 231 255 / 0.1) !important;
}

.tw-placeholder-indigo-100\/100::placeholder {
  color: rgb(224 231 255 / 1) !important;
}

.tw-placeholder-indigo-100\/15::placeholder {
  color: rgb(224 231 255 / 0.15) !important;
}

.tw-placeholder-indigo-100\/20::placeholder {
  color: rgb(224 231 255 / 0.2) !important;
}

.tw-placeholder-indigo-100\/25::placeholder {
  color: rgb(224 231 255 / 0.25) !important;
}

.tw-placeholder-indigo-100\/30::placeholder {
  color: rgb(224 231 255 / 0.3) !important;
}

.tw-placeholder-indigo-100\/35::placeholder {
  color: rgb(224 231 255 / 0.35) !important;
}

.tw-placeholder-indigo-100\/40::placeholder {
  color: rgb(224 231 255 / 0.4) !important;
}

.tw-placeholder-indigo-100\/45::placeholder {
  color: rgb(224 231 255 / 0.45) !important;
}

.tw-placeholder-indigo-100\/5::placeholder {
  color: rgb(224 231 255 / 0.05) !important;
}

.tw-placeholder-indigo-100\/50::placeholder {
  color: rgb(224 231 255 / 0.5) !important;
}

.tw-placeholder-indigo-100\/55::placeholder {
  color: rgb(224 231 255 / 0.55) !important;
}

.tw-placeholder-indigo-100\/60::placeholder {
  color: rgb(224 231 255 / 0.6) !important;
}

.tw-placeholder-indigo-100\/65::placeholder {
  color: rgb(224 231 255 / 0.65) !important;
}

.tw-placeholder-indigo-100\/70::placeholder {
  color: rgb(224 231 255 / 0.7) !important;
}

.tw-placeholder-indigo-100\/75::placeholder {
  color: rgb(224 231 255 / 0.75) !important;
}

.tw-placeholder-indigo-100\/80::placeholder {
  color: rgb(224 231 255 / 0.8) !important;
}

.tw-placeholder-indigo-100\/85::placeholder {
  color: rgb(224 231 255 / 0.85) !important;
}

.tw-placeholder-indigo-100\/90::placeholder {
  color: rgb(224 231 255 / 0.9) !important;
}

.tw-placeholder-indigo-100\/95::placeholder {
  color: rgb(224 231 255 / 0.95) !important;
}

.tw-placeholder-indigo-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(199 210 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-200\/0::placeholder {
  color: rgb(199 210 254 / 0) !important;
}

.tw-placeholder-indigo-200\/10::placeholder {
  color: rgb(199 210 254 / 0.1) !important;
}

.tw-placeholder-indigo-200\/100::placeholder {
  color: rgb(199 210 254 / 1) !important;
}

.tw-placeholder-indigo-200\/15::placeholder {
  color: rgb(199 210 254 / 0.15) !important;
}

.tw-placeholder-indigo-200\/20::placeholder {
  color: rgb(199 210 254 / 0.2) !important;
}

.tw-placeholder-indigo-200\/25::placeholder {
  color: rgb(199 210 254 / 0.25) !important;
}

.tw-placeholder-indigo-200\/30::placeholder {
  color: rgb(199 210 254 / 0.3) !important;
}

.tw-placeholder-indigo-200\/35::placeholder {
  color: rgb(199 210 254 / 0.35) !important;
}

.tw-placeholder-indigo-200\/40::placeholder {
  color: rgb(199 210 254 / 0.4) !important;
}

.tw-placeholder-indigo-200\/45::placeholder {
  color: rgb(199 210 254 / 0.45) !important;
}

.tw-placeholder-indigo-200\/5::placeholder {
  color: rgb(199 210 254 / 0.05) !important;
}

.tw-placeholder-indigo-200\/50::placeholder {
  color: rgb(199 210 254 / 0.5) !important;
}

.tw-placeholder-indigo-200\/55::placeholder {
  color: rgb(199 210 254 / 0.55) !important;
}

.tw-placeholder-indigo-200\/60::placeholder {
  color: rgb(199 210 254 / 0.6) !important;
}

.tw-placeholder-indigo-200\/65::placeholder {
  color: rgb(199 210 254 / 0.65) !important;
}

.tw-placeholder-indigo-200\/70::placeholder {
  color: rgb(199 210 254 / 0.7) !important;
}

.tw-placeholder-indigo-200\/75::placeholder {
  color: rgb(199 210 254 / 0.75) !important;
}

.tw-placeholder-indigo-200\/80::placeholder {
  color: rgb(199 210 254 / 0.8) !important;
}

.tw-placeholder-indigo-200\/85::placeholder {
  color: rgb(199 210 254 / 0.85) !important;
}

.tw-placeholder-indigo-200\/90::placeholder {
  color: rgb(199 210 254 / 0.9) !important;
}

.tw-placeholder-indigo-200\/95::placeholder {
  color: rgb(199 210 254 / 0.95) !important;
}

.tw-placeholder-indigo-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(165 180 252 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-300\/0::placeholder {
  color: rgb(165 180 252 / 0) !important;
}

.tw-placeholder-indigo-300\/10::placeholder {
  color: rgb(165 180 252 / 0.1) !important;
}

.tw-placeholder-indigo-300\/100::placeholder {
  color: rgb(165 180 252 / 1) !important;
}

.tw-placeholder-indigo-300\/15::placeholder {
  color: rgb(165 180 252 / 0.15) !important;
}

.tw-placeholder-indigo-300\/20::placeholder {
  color: rgb(165 180 252 / 0.2) !important;
}

.tw-placeholder-indigo-300\/25::placeholder {
  color: rgb(165 180 252 / 0.25) !important;
}

.tw-placeholder-indigo-300\/30::placeholder {
  color: rgb(165 180 252 / 0.3) !important;
}

.tw-placeholder-indigo-300\/35::placeholder {
  color: rgb(165 180 252 / 0.35) !important;
}

.tw-placeholder-indigo-300\/40::placeholder {
  color: rgb(165 180 252 / 0.4) !important;
}

.tw-placeholder-indigo-300\/45::placeholder {
  color: rgb(165 180 252 / 0.45) !important;
}

.tw-placeholder-indigo-300\/5::placeholder {
  color: rgb(165 180 252 / 0.05) !important;
}

.tw-placeholder-indigo-300\/50::placeholder {
  color: rgb(165 180 252 / 0.5) !important;
}

.tw-placeholder-indigo-300\/55::placeholder {
  color: rgb(165 180 252 / 0.55) !important;
}

.tw-placeholder-indigo-300\/60::placeholder {
  color: rgb(165 180 252 / 0.6) !important;
}

.tw-placeholder-indigo-300\/65::placeholder {
  color: rgb(165 180 252 / 0.65) !important;
}

.tw-placeholder-indigo-300\/70::placeholder {
  color: rgb(165 180 252 / 0.7) !important;
}

.tw-placeholder-indigo-300\/75::placeholder {
  color: rgb(165 180 252 / 0.75) !important;
}

.tw-placeholder-indigo-300\/80::placeholder {
  color: rgb(165 180 252 / 0.8) !important;
}

.tw-placeholder-indigo-300\/85::placeholder {
  color: rgb(165 180 252 / 0.85) !important;
}

.tw-placeholder-indigo-300\/90::placeholder {
  color: rgb(165 180 252 / 0.9) !important;
}

.tw-placeholder-indigo-300\/95::placeholder {
  color: rgb(165 180 252 / 0.95) !important;
}

.tw-placeholder-indigo-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(129 140 248 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-400\/0::placeholder {
  color: rgb(129 140 248 / 0) !important;
}

.tw-placeholder-indigo-400\/10::placeholder {
  color: rgb(129 140 248 / 0.1) !important;
}

.tw-placeholder-indigo-400\/100::placeholder {
  color: rgb(129 140 248 / 1) !important;
}

.tw-placeholder-indigo-400\/15::placeholder {
  color: rgb(129 140 248 / 0.15) !important;
}

.tw-placeholder-indigo-400\/20::placeholder {
  color: rgb(129 140 248 / 0.2) !important;
}

.tw-placeholder-indigo-400\/25::placeholder {
  color: rgb(129 140 248 / 0.25) !important;
}

.tw-placeholder-indigo-400\/30::placeholder {
  color: rgb(129 140 248 / 0.3) !important;
}

.tw-placeholder-indigo-400\/35::placeholder {
  color: rgb(129 140 248 / 0.35) !important;
}

.tw-placeholder-indigo-400\/40::placeholder {
  color: rgb(129 140 248 / 0.4) !important;
}

.tw-placeholder-indigo-400\/45::placeholder {
  color: rgb(129 140 248 / 0.45) !important;
}

.tw-placeholder-indigo-400\/5::placeholder {
  color: rgb(129 140 248 / 0.05) !important;
}

.tw-placeholder-indigo-400\/50::placeholder {
  color: rgb(129 140 248 / 0.5) !important;
}

.tw-placeholder-indigo-400\/55::placeholder {
  color: rgb(129 140 248 / 0.55) !important;
}

.tw-placeholder-indigo-400\/60::placeholder {
  color: rgb(129 140 248 / 0.6) !important;
}

.tw-placeholder-indigo-400\/65::placeholder {
  color: rgb(129 140 248 / 0.65) !important;
}

.tw-placeholder-indigo-400\/70::placeholder {
  color: rgb(129 140 248 / 0.7) !important;
}

.tw-placeholder-indigo-400\/75::placeholder {
  color: rgb(129 140 248 / 0.75) !important;
}

.tw-placeholder-indigo-400\/80::placeholder {
  color: rgb(129 140 248 / 0.8) !important;
}

.tw-placeholder-indigo-400\/85::placeholder {
  color: rgb(129 140 248 / 0.85) !important;
}

.tw-placeholder-indigo-400\/90::placeholder {
  color: rgb(129 140 248 / 0.9) !important;
}

.tw-placeholder-indigo-400\/95::placeholder {
  color: rgb(129 140 248 / 0.95) !important;
}

.tw-placeholder-indigo-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(238 242 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-50\/0::placeholder {
  color: rgb(238 242 255 / 0) !important;
}

.tw-placeholder-indigo-50\/10::placeholder {
  color: rgb(238 242 255 / 0.1) !important;
}

.tw-placeholder-indigo-50\/100::placeholder {
  color: rgb(238 242 255 / 1) !important;
}

.tw-placeholder-indigo-50\/15::placeholder {
  color: rgb(238 242 255 / 0.15) !important;
}

.tw-placeholder-indigo-50\/20::placeholder {
  color: rgb(238 242 255 / 0.2) !important;
}

.tw-placeholder-indigo-50\/25::placeholder {
  color: rgb(238 242 255 / 0.25) !important;
}

.tw-placeholder-indigo-50\/30::placeholder {
  color: rgb(238 242 255 / 0.3) !important;
}

.tw-placeholder-indigo-50\/35::placeholder {
  color: rgb(238 242 255 / 0.35) !important;
}

.tw-placeholder-indigo-50\/40::placeholder {
  color: rgb(238 242 255 / 0.4) !important;
}

.tw-placeholder-indigo-50\/45::placeholder {
  color: rgb(238 242 255 / 0.45) !important;
}

.tw-placeholder-indigo-50\/5::placeholder {
  color: rgb(238 242 255 / 0.05) !important;
}

.tw-placeholder-indigo-50\/50::placeholder {
  color: rgb(238 242 255 / 0.5) !important;
}

.tw-placeholder-indigo-50\/55::placeholder {
  color: rgb(238 242 255 / 0.55) !important;
}

.tw-placeholder-indigo-50\/60::placeholder {
  color: rgb(238 242 255 / 0.6) !important;
}

.tw-placeholder-indigo-50\/65::placeholder {
  color: rgb(238 242 255 / 0.65) !important;
}

.tw-placeholder-indigo-50\/70::placeholder {
  color: rgb(238 242 255 / 0.7) !important;
}

.tw-placeholder-indigo-50\/75::placeholder {
  color: rgb(238 242 255 / 0.75) !important;
}

.tw-placeholder-indigo-50\/80::placeholder {
  color: rgb(238 242 255 / 0.8) !important;
}

.tw-placeholder-indigo-50\/85::placeholder {
  color: rgb(238 242 255 / 0.85) !important;
}

.tw-placeholder-indigo-50\/90::placeholder {
  color: rgb(238 242 255 / 0.9) !important;
}

.tw-placeholder-indigo-50\/95::placeholder {
  color: rgb(238 242 255 / 0.95) !important;
}

.tw-placeholder-indigo-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(99 102 241 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-500\/0::placeholder {
  color: rgb(99 102 241 / 0) !important;
}

.tw-placeholder-indigo-500\/10::placeholder {
  color: rgb(99 102 241 / 0.1) !important;
}

.tw-placeholder-indigo-500\/100::placeholder {
  color: rgb(99 102 241 / 1) !important;
}

.tw-placeholder-indigo-500\/15::placeholder {
  color: rgb(99 102 241 / 0.15) !important;
}

.tw-placeholder-indigo-500\/20::placeholder {
  color: rgb(99 102 241 / 0.2) !important;
}

.tw-placeholder-indigo-500\/25::placeholder {
  color: rgb(99 102 241 / 0.25) !important;
}

.tw-placeholder-indigo-500\/30::placeholder {
  color: rgb(99 102 241 / 0.3) !important;
}

.tw-placeholder-indigo-500\/35::placeholder {
  color: rgb(99 102 241 / 0.35) !important;
}

.tw-placeholder-indigo-500\/40::placeholder {
  color: rgb(99 102 241 / 0.4) !important;
}

.tw-placeholder-indigo-500\/45::placeholder {
  color: rgb(99 102 241 / 0.45) !important;
}

.tw-placeholder-indigo-500\/5::placeholder {
  color: rgb(99 102 241 / 0.05) !important;
}

.tw-placeholder-indigo-500\/50::placeholder {
  color: rgb(99 102 241 / 0.5) !important;
}

.tw-placeholder-indigo-500\/55::placeholder {
  color: rgb(99 102 241 / 0.55) !important;
}

.tw-placeholder-indigo-500\/60::placeholder {
  color: rgb(99 102 241 / 0.6) !important;
}

.tw-placeholder-indigo-500\/65::placeholder {
  color: rgb(99 102 241 / 0.65) !important;
}

.tw-placeholder-indigo-500\/70::placeholder {
  color: rgb(99 102 241 / 0.7) !important;
}

.tw-placeholder-indigo-500\/75::placeholder {
  color: rgb(99 102 241 / 0.75) !important;
}

.tw-placeholder-indigo-500\/80::placeholder {
  color: rgb(99 102 241 / 0.8) !important;
}

.tw-placeholder-indigo-500\/85::placeholder {
  color: rgb(99 102 241 / 0.85) !important;
}

.tw-placeholder-indigo-500\/90::placeholder {
  color: rgb(99 102 241 / 0.9) !important;
}

.tw-placeholder-indigo-500\/95::placeholder {
  color: rgb(99 102 241 / 0.95) !important;
}

.tw-placeholder-indigo-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(79 70 229 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-600\/0::placeholder {
  color: rgb(79 70 229 / 0) !important;
}

.tw-placeholder-indigo-600\/10::placeholder {
  color: rgb(79 70 229 / 0.1) !important;
}

.tw-placeholder-indigo-600\/100::placeholder {
  color: rgb(79 70 229 / 1) !important;
}

.tw-placeholder-indigo-600\/15::placeholder {
  color: rgb(79 70 229 / 0.15) !important;
}

.tw-placeholder-indigo-600\/20::placeholder {
  color: rgb(79 70 229 / 0.2) !important;
}

.tw-placeholder-indigo-600\/25::placeholder {
  color: rgb(79 70 229 / 0.25) !important;
}

.tw-placeholder-indigo-600\/30::placeholder {
  color: rgb(79 70 229 / 0.3) !important;
}

.tw-placeholder-indigo-600\/35::placeholder {
  color: rgb(79 70 229 / 0.35) !important;
}

.tw-placeholder-indigo-600\/40::placeholder {
  color: rgb(79 70 229 / 0.4) !important;
}

.tw-placeholder-indigo-600\/45::placeholder {
  color: rgb(79 70 229 / 0.45) !important;
}

.tw-placeholder-indigo-600\/5::placeholder {
  color: rgb(79 70 229 / 0.05) !important;
}

.tw-placeholder-indigo-600\/50::placeholder {
  color: rgb(79 70 229 / 0.5) !important;
}

.tw-placeholder-indigo-600\/55::placeholder {
  color: rgb(79 70 229 / 0.55) !important;
}

.tw-placeholder-indigo-600\/60::placeholder {
  color: rgb(79 70 229 / 0.6) !important;
}

.tw-placeholder-indigo-600\/65::placeholder {
  color: rgb(79 70 229 / 0.65) !important;
}

.tw-placeholder-indigo-600\/70::placeholder {
  color: rgb(79 70 229 / 0.7) !important;
}

.tw-placeholder-indigo-600\/75::placeholder {
  color: rgb(79 70 229 / 0.75) !important;
}

.tw-placeholder-indigo-600\/80::placeholder {
  color: rgb(79 70 229 / 0.8) !important;
}

.tw-placeholder-indigo-600\/85::placeholder {
  color: rgb(79 70 229 / 0.85) !important;
}

.tw-placeholder-indigo-600\/90::placeholder {
  color: rgb(79 70 229 / 0.9) !important;
}

.tw-placeholder-indigo-600\/95::placeholder {
  color: rgb(79 70 229 / 0.95) !important;
}

.tw-placeholder-indigo-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(67 56 202 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-700\/0::placeholder {
  color: rgb(67 56 202 / 0) !important;
}

.tw-placeholder-indigo-700\/10::placeholder {
  color: rgb(67 56 202 / 0.1) !important;
}

.tw-placeholder-indigo-700\/100::placeholder {
  color: rgb(67 56 202 / 1) !important;
}

.tw-placeholder-indigo-700\/15::placeholder {
  color: rgb(67 56 202 / 0.15) !important;
}

.tw-placeholder-indigo-700\/20::placeholder {
  color: rgb(67 56 202 / 0.2) !important;
}

.tw-placeholder-indigo-700\/25::placeholder {
  color: rgb(67 56 202 / 0.25) !important;
}

.tw-placeholder-indigo-700\/30::placeholder {
  color: rgb(67 56 202 / 0.3) !important;
}

.tw-placeholder-indigo-700\/35::placeholder {
  color: rgb(67 56 202 / 0.35) !important;
}

.tw-placeholder-indigo-700\/40::placeholder {
  color: rgb(67 56 202 / 0.4) !important;
}

.tw-placeholder-indigo-700\/45::placeholder {
  color: rgb(67 56 202 / 0.45) !important;
}

.tw-placeholder-indigo-700\/5::placeholder {
  color: rgb(67 56 202 / 0.05) !important;
}

.tw-placeholder-indigo-700\/50::placeholder {
  color: rgb(67 56 202 / 0.5) !important;
}

.tw-placeholder-indigo-700\/55::placeholder {
  color: rgb(67 56 202 / 0.55) !important;
}

.tw-placeholder-indigo-700\/60::placeholder {
  color: rgb(67 56 202 / 0.6) !important;
}

.tw-placeholder-indigo-700\/65::placeholder {
  color: rgb(67 56 202 / 0.65) !important;
}

.tw-placeholder-indigo-700\/70::placeholder {
  color: rgb(67 56 202 / 0.7) !important;
}

.tw-placeholder-indigo-700\/75::placeholder {
  color: rgb(67 56 202 / 0.75) !important;
}

.tw-placeholder-indigo-700\/80::placeholder {
  color: rgb(67 56 202 / 0.8) !important;
}

.tw-placeholder-indigo-700\/85::placeholder {
  color: rgb(67 56 202 / 0.85) !important;
}

.tw-placeholder-indigo-700\/90::placeholder {
  color: rgb(67 56 202 / 0.9) !important;
}

.tw-placeholder-indigo-700\/95::placeholder {
  color: rgb(67 56 202 / 0.95) !important;
}

.tw-placeholder-indigo-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(55 48 163 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-800\/0::placeholder {
  color: rgb(55 48 163 / 0) !important;
}

.tw-placeholder-indigo-800\/10::placeholder {
  color: rgb(55 48 163 / 0.1) !important;
}

.tw-placeholder-indigo-800\/100::placeholder {
  color: rgb(55 48 163 / 1) !important;
}

.tw-placeholder-indigo-800\/15::placeholder {
  color: rgb(55 48 163 / 0.15) !important;
}

.tw-placeholder-indigo-800\/20::placeholder {
  color: rgb(55 48 163 / 0.2) !important;
}

.tw-placeholder-indigo-800\/25::placeholder {
  color: rgb(55 48 163 / 0.25) !important;
}

.tw-placeholder-indigo-800\/30::placeholder {
  color: rgb(55 48 163 / 0.3) !important;
}

.tw-placeholder-indigo-800\/35::placeholder {
  color: rgb(55 48 163 / 0.35) !important;
}

.tw-placeholder-indigo-800\/40::placeholder {
  color: rgb(55 48 163 / 0.4) !important;
}

.tw-placeholder-indigo-800\/45::placeholder {
  color: rgb(55 48 163 / 0.45) !important;
}

.tw-placeholder-indigo-800\/5::placeholder {
  color: rgb(55 48 163 / 0.05) !important;
}

.tw-placeholder-indigo-800\/50::placeholder {
  color: rgb(55 48 163 / 0.5) !important;
}

.tw-placeholder-indigo-800\/55::placeholder {
  color: rgb(55 48 163 / 0.55) !important;
}

.tw-placeholder-indigo-800\/60::placeholder {
  color: rgb(55 48 163 / 0.6) !important;
}

.tw-placeholder-indigo-800\/65::placeholder {
  color: rgb(55 48 163 / 0.65) !important;
}

.tw-placeholder-indigo-800\/70::placeholder {
  color: rgb(55 48 163 / 0.7) !important;
}

.tw-placeholder-indigo-800\/75::placeholder {
  color: rgb(55 48 163 / 0.75) !important;
}

.tw-placeholder-indigo-800\/80::placeholder {
  color: rgb(55 48 163 / 0.8) !important;
}

.tw-placeholder-indigo-800\/85::placeholder {
  color: rgb(55 48 163 / 0.85) !important;
}

.tw-placeholder-indigo-800\/90::placeholder {
  color: rgb(55 48 163 / 0.9) !important;
}

.tw-placeholder-indigo-800\/95::placeholder {
  color: rgb(55 48 163 / 0.95) !important;
}

.tw-placeholder-indigo-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(49 46 129 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-900\/0::placeholder {
  color: rgb(49 46 129 / 0) !important;
}

.tw-placeholder-indigo-900\/10::placeholder {
  color: rgb(49 46 129 / 0.1) !important;
}

.tw-placeholder-indigo-900\/100::placeholder {
  color: rgb(49 46 129 / 1) !important;
}

.tw-placeholder-indigo-900\/15::placeholder {
  color: rgb(49 46 129 / 0.15) !important;
}

.tw-placeholder-indigo-900\/20::placeholder {
  color: rgb(49 46 129 / 0.2) !important;
}

.tw-placeholder-indigo-900\/25::placeholder {
  color: rgb(49 46 129 / 0.25) !important;
}

.tw-placeholder-indigo-900\/30::placeholder {
  color: rgb(49 46 129 / 0.3) !important;
}

.tw-placeholder-indigo-900\/35::placeholder {
  color: rgb(49 46 129 / 0.35) !important;
}

.tw-placeholder-indigo-900\/40::placeholder {
  color: rgb(49 46 129 / 0.4) !important;
}

.tw-placeholder-indigo-900\/45::placeholder {
  color: rgb(49 46 129 / 0.45) !important;
}

.tw-placeholder-indigo-900\/5::placeholder {
  color: rgb(49 46 129 / 0.05) !important;
}

.tw-placeholder-indigo-900\/50::placeholder {
  color: rgb(49 46 129 / 0.5) !important;
}

.tw-placeholder-indigo-900\/55::placeholder {
  color: rgb(49 46 129 / 0.55) !important;
}

.tw-placeholder-indigo-900\/60::placeholder {
  color: rgb(49 46 129 / 0.6) !important;
}

.tw-placeholder-indigo-900\/65::placeholder {
  color: rgb(49 46 129 / 0.65) !important;
}

.tw-placeholder-indigo-900\/70::placeholder {
  color: rgb(49 46 129 / 0.7) !important;
}

.tw-placeholder-indigo-900\/75::placeholder {
  color: rgb(49 46 129 / 0.75) !important;
}

.tw-placeholder-indigo-900\/80::placeholder {
  color: rgb(49 46 129 / 0.8) !important;
}

.tw-placeholder-indigo-900\/85::placeholder {
  color: rgb(49 46 129 / 0.85) !important;
}

.tw-placeholder-indigo-900\/90::placeholder {
  color: rgb(49 46 129 / 0.9) !important;
}

.tw-placeholder-indigo-900\/95::placeholder {
  color: rgb(49 46 129 / 0.95) !important;
}

.tw-placeholder-indigo-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(30 27 75 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-indigo-950\/0::placeholder {
  color: rgb(30 27 75 / 0) !important;
}

.tw-placeholder-indigo-950\/10::placeholder {
  color: rgb(30 27 75 / 0.1) !important;
}

.tw-placeholder-indigo-950\/100::placeholder {
  color: rgb(30 27 75 / 1) !important;
}

.tw-placeholder-indigo-950\/15::placeholder {
  color: rgb(30 27 75 / 0.15) !important;
}

.tw-placeholder-indigo-950\/20::placeholder {
  color: rgb(30 27 75 / 0.2) !important;
}

.tw-placeholder-indigo-950\/25::placeholder {
  color: rgb(30 27 75 / 0.25) !important;
}

.tw-placeholder-indigo-950\/30::placeholder {
  color: rgb(30 27 75 / 0.3) !important;
}

.tw-placeholder-indigo-950\/35::placeholder {
  color: rgb(30 27 75 / 0.35) !important;
}

.tw-placeholder-indigo-950\/40::placeholder {
  color: rgb(30 27 75 / 0.4) !important;
}

.tw-placeholder-indigo-950\/45::placeholder {
  color: rgb(30 27 75 / 0.45) !important;
}

.tw-placeholder-indigo-950\/5::placeholder {
  color: rgb(30 27 75 / 0.05) !important;
}

.tw-placeholder-indigo-950\/50::placeholder {
  color: rgb(30 27 75 / 0.5) !important;
}

.tw-placeholder-indigo-950\/55::placeholder {
  color: rgb(30 27 75 / 0.55) !important;
}

.tw-placeholder-indigo-950\/60::placeholder {
  color: rgb(30 27 75 / 0.6) !important;
}

.tw-placeholder-indigo-950\/65::placeholder {
  color: rgb(30 27 75 / 0.65) !important;
}

.tw-placeholder-indigo-950\/70::placeholder {
  color: rgb(30 27 75 / 0.7) !important;
}

.tw-placeholder-indigo-950\/75::placeholder {
  color: rgb(30 27 75 / 0.75) !important;
}

.tw-placeholder-indigo-950\/80::placeholder {
  color: rgb(30 27 75 / 0.8) !important;
}

.tw-placeholder-indigo-950\/85::placeholder {
  color: rgb(30 27 75 / 0.85) !important;
}

.tw-placeholder-indigo-950\/90::placeholder {
  color: rgb(30 27 75 / 0.9) !important;
}

.tw-placeholder-indigo-950\/95::placeholder {
  color: rgb(30 27 75 / 0.95) !important;
}

.tw-placeholder-inherit::placeholder {
  color: inherit !important;
}

.tw-placeholder-lime-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(236 252 203 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-100\/0::placeholder {
  color: rgb(236 252 203 / 0) !important;
}

.tw-placeholder-lime-100\/10::placeholder {
  color: rgb(236 252 203 / 0.1) !important;
}

.tw-placeholder-lime-100\/100::placeholder {
  color: rgb(236 252 203 / 1) !important;
}

.tw-placeholder-lime-100\/15::placeholder {
  color: rgb(236 252 203 / 0.15) !important;
}

.tw-placeholder-lime-100\/20::placeholder {
  color: rgb(236 252 203 / 0.2) !important;
}

.tw-placeholder-lime-100\/25::placeholder {
  color: rgb(236 252 203 / 0.25) !important;
}

.tw-placeholder-lime-100\/30::placeholder {
  color: rgb(236 252 203 / 0.3) !important;
}

.tw-placeholder-lime-100\/35::placeholder {
  color: rgb(236 252 203 / 0.35) !important;
}

.tw-placeholder-lime-100\/40::placeholder {
  color: rgb(236 252 203 / 0.4) !important;
}

.tw-placeholder-lime-100\/45::placeholder {
  color: rgb(236 252 203 / 0.45) !important;
}

.tw-placeholder-lime-100\/5::placeholder {
  color: rgb(236 252 203 / 0.05) !important;
}

.tw-placeholder-lime-100\/50::placeholder {
  color: rgb(236 252 203 / 0.5) !important;
}

.tw-placeholder-lime-100\/55::placeholder {
  color: rgb(236 252 203 / 0.55) !important;
}

.tw-placeholder-lime-100\/60::placeholder {
  color: rgb(236 252 203 / 0.6) !important;
}

.tw-placeholder-lime-100\/65::placeholder {
  color: rgb(236 252 203 / 0.65) !important;
}

.tw-placeholder-lime-100\/70::placeholder {
  color: rgb(236 252 203 / 0.7) !important;
}

.tw-placeholder-lime-100\/75::placeholder {
  color: rgb(236 252 203 / 0.75) !important;
}

.tw-placeholder-lime-100\/80::placeholder {
  color: rgb(236 252 203 / 0.8) !important;
}

.tw-placeholder-lime-100\/85::placeholder {
  color: rgb(236 252 203 / 0.85) !important;
}

.tw-placeholder-lime-100\/90::placeholder {
  color: rgb(236 252 203 / 0.9) !important;
}

.tw-placeholder-lime-100\/95::placeholder {
  color: rgb(236 252 203 / 0.95) !important;
}

.tw-placeholder-lime-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(217 249 157 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-200\/0::placeholder {
  color: rgb(217 249 157 / 0) !important;
}

.tw-placeholder-lime-200\/10::placeholder {
  color: rgb(217 249 157 / 0.1) !important;
}

.tw-placeholder-lime-200\/100::placeholder {
  color: rgb(217 249 157 / 1) !important;
}

.tw-placeholder-lime-200\/15::placeholder {
  color: rgb(217 249 157 / 0.15) !important;
}

.tw-placeholder-lime-200\/20::placeholder {
  color: rgb(217 249 157 / 0.2) !important;
}

.tw-placeholder-lime-200\/25::placeholder {
  color: rgb(217 249 157 / 0.25) !important;
}

.tw-placeholder-lime-200\/30::placeholder {
  color: rgb(217 249 157 / 0.3) !important;
}

.tw-placeholder-lime-200\/35::placeholder {
  color: rgb(217 249 157 / 0.35) !important;
}

.tw-placeholder-lime-200\/40::placeholder {
  color: rgb(217 249 157 / 0.4) !important;
}

.tw-placeholder-lime-200\/45::placeholder {
  color: rgb(217 249 157 / 0.45) !important;
}

.tw-placeholder-lime-200\/5::placeholder {
  color: rgb(217 249 157 / 0.05) !important;
}

.tw-placeholder-lime-200\/50::placeholder {
  color: rgb(217 249 157 / 0.5) !important;
}

.tw-placeholder-lime-200\/55::placeholder {
  color: rgb(217 249 157 / 0.55) !important;
}

.tw-placeholder-lime-200\/60::placeholder {
  color: rgb(217 249 157 / 0.6) !important;
}

.tw-placeholder-lime-200\/65::placeholder {
  color: rgb(217 249 157 / 0.65) !important;
}

.tw-placeholder-lime-200\/70::placeholder {
  color: rgb(217 249 157 / 0.7) !important;
}

.tw-placeholder-lime-200\/75::placeholder {
  color: rgb(217 249 157 / 0.75) !important;
}

.tw-placeholder-lime-200\/80::placeholder {
  color: rgb(217 249 157 / 0.8) !important;
}

.tw-placeholder-lime-200\/85::placeholder {
  color: rgb(217 249 157 / 0.85) !important;
}

.tw-placeholder-lime-200\/90::placeholder {
  color: rgb(217 249 157 / 0.9) !important;
}

.tw-placeholder-lime-200\/95::placeholder {
  color: rgb(217 249 157 / 0.95) !important;
}

.tw-placeholder-lime-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(190 242 100 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-300\/0::placeholder {
  color: rgb(190 242 100 / 0) !important;
}

.tw-placeholder-lime-300\/10::placeholder {
  color: rgb(190 242 100 / 0.1) !important;
}

.tw-placeholder-lime-300\/100::placeholder {
  color: rgb(190 242 100 / 1) !important;
}

.tw-placeholder-lime-300\/15::placeholder {
  color: rgb(190 242 100 / 0.15) !important;
}

.tw-placeholder-lime-300\/20::placeholder {
  color: rgb(190 242 100 / 0.2) !important;
}

.tw-placeholder-lime-300\/25::placeholder {
  color: rgb(190 242 100 / 0.25) !important;
}

.tw-placeholder-lime-300\/30::placeholder {
  color: rgb(190 242 100 / 0.3) !important;
}

.tw-placeholder-lime-300\/35::placeholder {
  color: rgb(190 242 100 / 0.35) !important;
}

.tw-placeholder-lime-300\/40::placeholder {
  color: rgb(190 242 100 / 0.4) !important;
}

.tw-placeholder-lime-300\/45::placeholder {
  color: rgb(190 242 100 / 0.45) !important;
}

.tw-placeholder-lime-300\/5::placeholder {
  color: rgb(190 242 100 / 0.05) !important;
}

.tw-placeholder-lime-300\/50::placeholder {
  color: rgb(190 242 100 / 0.5) !important;
}

.tw-placeholder-lime-300\/55::placeholder {
  color: rgb(190 242 100 / 0.55) !important;
}

.tw-placeholder-lime-300\/60::placeholder {
  color: rgb(190 242 100 / 0.6) !important;
}

.tw-placeholder-lime-300\/65::placeholder {
  color: rgb(190 242 100 / 0.65) !important;
}

.tw-placeholder-lime-300\/70::placeholder {
  color: rgb(190 242 100 / 0.7) !important;
}

.tw-placeholder-lime-300\/75::placeholder {
  color: rgb(190 242 100 / 0.75) !important;
}

.tw-placeholder-lime-300\/80::placeholder {
  color: rgb(190 242 100 / 0.8) !important;
}

.tw-placeholder-lime-300\/85::placeholder {
  color: rgb(190 242 100 / 0.85) !important;
}

.tw-placeholder-lime-300\/90::placeholder {
  color: rgb(190 242 100 / 0.9) !important;
}

.tw-placeholder-lime-300\/95::placeholder {
  color: rgb(190 242 100 / 0.95) !important;
}

.tw-placeholder-lime-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(163 230 53 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-400\/0::placeholder {
  color: rgb(163 230 53 / 0) !important;
}

.tw-placeholder-lime-400\/10::placeholder {
  color: rgb(163 230 53 / 0.1) !important;
}

.tw-placeholder-lime-400\/100::placeholder {
  color: rgb(163 230 53 / 1) !important;
}

.tw-placeholder-lime-400\/15::placeholder {
  color: rgb(163 230 53 / 0.15) !important;
}

.tw-placeholder-lime-400\/20::placeholder {
  color: rgb(163 230 53 / 0.2) !important;
}

.tw-placeholder-lime-400\/25::placeholder {
  color: rgb(163 230 53 / 0.25) !important;
}

.tw-placeholder-lime-400\/30::placeholder {
  color: rgb(163 230 53 / 0.3) !important;
}

.tw-placeholder-lime-400\/35::placeholder {
  color: rgb(163 230 53 / 0.35) !important;
}

.tw-placeholder-lime-400\/40::placeholder {
  color: rgb(163 230 53 / 0.4) !important;
}

.tw-placeholder-lime-400\/45::placeholder {
  color: rgb(163 230 53 / 0.45) !important;
}

.tw-placeholder-lime-400\/5::placeholder {
  color: rgb(163 230 53 / 0.05) !important;
}

.tw-placeholder-lime-400\/50::placeholder {
  color: rgb(163 230 53 / 0.5) !important;
}

.tw-placeholder-lime-400\/55::placeholder {
  color: rgb(163 230 53 / 0.55) !important;
}

.tw-placeholder-lime-400\/60::placeholder {
  color: rgb(163 230 53 / 0.6) !important;
}

.tw-placeholder-lime-400\/65::placeholder {
  color: rgb(163 230 53 / 0.65) !important;
}

.tw-placeholder-lime-400\/70::placeholder {
  color: rgb(163 230 53 / 0.7) !important;
}

.tw-placeholder-lime-400\/75::placeholder {
  color: rgb(163 230 53 / 0.75) !important;
}

.tw-placeholder-lime-400\/80::placeholder {
  color: rgb(163 230 53 / 0.8) !important;
}

.tw-placeholder-lime-400\/85::placeholder {
  color: rgb(163 230 53 / 0.85) !important;
}

.tw-placeholder-lime-400\/90::placeholder {
  color: rgb(163 230 53 / 0.9) !important;
}

.tw-placeholder-lime-400\/95::placeholder {
  color: rgb(163 230 53 / 0.95) !important;
}

.tw-placeholder-lime-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(247 254 231 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-50\/0::placeholder {
  color: rgb(247 254 231 / 0) !important;
}

.tw-placeholder-lime-50\/10::placeholder {
  color: rgb(247 254 231 / 0.1) !important;
}

.tw-placeholder-lime-50\/100::placeholder {
  color: rgb(247 254 231 / 1) !important;
}

.tw-placeholder-lime-50\/15::placeholder {
  color: rgb(247 254 231 / 0.15) !important;
}

.tw-placeholder-lime-50\/20::placeholder {
  color: rgb(247 254 231 / 0.2) !important;
}

.tw-placeholder-lime-50\/25::placeholder {
  color: rgb(247 254 231 / 0.25) !important;
}

.tw-placeholder-lime-50\/30::placeholder {
  color: rgb(247 254 231 / 0.3) !important;
}

.tw-placeholder-lime-50\/35::placeholder {
  color: rgb(247 254 231 / 0.35) !important;
}

.tw-placeholder-lime-50\/40::placeholder {
  color: rgb(247 254 231 / 0.4) !important;
}

.tw-placeholder-lime-50\/45::placeholder {
  color: rgb(247 254 231 / 0.45) !important;
}

.tw-placeholder-lime-50\/5::placeholder {
  color: rgb(247 254 231 / 0.05) !important;
}

.tw-placeholder-lime-50\/50::placeholder {
  color: rgb(247 254 231 / 0.5) !important;
}

.tw-placeholder-lime-50\/55::placeholder {
  color: rgb(247 254 231 / 0.55) !important;
}

.tw-placeholder-lime-50\/60::placeholder {
  color: rgb(247 254 231 / 0.6) !important;
}

.tw-placeholder-lime-50\/65::placeholder {
  color: rgb(247 254 231 / 0.65) !important;
}

.tw-placeholder-lime-50\/70::placeholder {
  color: rgb(247 254 231 / 0.7) !important;
}

.tw-placeholder-lime-50\/75::placeholder {
  color: rgb(247 254 231 / 0.75) !important;
}

.tw-placeholder-lime-50\/80::placeholder {
  color: rgb(247 254 231 / 0.8) !important;
}

.tw-placeholder-lime-50\/85::placeholder {
  color: rgb(247 254 231 / 0.85) !important;
}

.tw-placeholder-lime-50\/90::placeholder {
  color: rgb(247 254 231 / 0.9) !important;
}

.tw-placeholder-lime-50\/95::placeholder {
  color: rgb(247 254 231 / 0.95) !important;
}

.tw-placeholder-lime-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(132 204 22 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-500\/0::placeholder {
  color: rgb(132 204 22 / 0) !important;
}

.tw-placeholder-lime-500\/10::placeholder {
  color: rgb(132 204 22 / 0.1) !important;
}

.tw-placeholder-lime-500\/100::placeholder {
  color: rgb(132 204 22 / 1) !important;
}

.tw-placeholder-lime-500\/15::placeholder {
  color: rgb(132 204 22 / 0.15) !important;
}

.tw-placeholder-lime-500\/20::placeholder {
  color: rgb(132 204 22 / 0.2) !important;
}

.tw-placeholder-lime-500\/25::placeholder {
  color: rgb(132 204 22 / 0.25) !important;
}

.tw-placeholder-lime-500\/30::placeholder {
  color: rgb(132 204 22 / 0.3) !important;
}

.tw-placeholder-lime-500\/35::placeholder {
  color: rgb(132 204 22 / 0.35) !important;
}

.tw-placeholder-lime-500\/40::placeholder {
  color: rgb(132 204 22 / 0.4) !important;
}

.tw-placeholder-lime-500\/45::placeholder {
  color: rgb(132 204 22 / 0.45) !important;
}

.tw-placeholder-lime-500\/5::placeholder {
  color: rgb(132 204 22 / 0.05) !important;
}

.tw-placeholder-lime-500\/50::placeholder {
  color: rgb(132 204 22 / 0.5) !important;
}

.tw-placeholder-lime-500\/55::placeholder {
  color: rgb(132 204 22 / 0.55) !important;
}

.tw-placeholder-lime-500\/60::placeholder {
  color: rgb(132 204 22 / 0.6) !important;
}

.tw-placeholder-lime-500\/65::placeholder {
  color: rgb(132 204 22 / 0.65) !important;
}

.tw-placeholder-lime-500\/70::placeholder {
  color: rgb(132 204 22 / 0.7) !important;
}

.tw-placeholder-lime-500\/75::placeholder {
  color: rgb(132 204 22 / 0.75) !important;
}

.tw-placeholder-lime-500\/80::placeholder {
  color: rgb(132 204 22 / 0.8) !important;
}

.tw-placeholder-lime-500\/85::placeholder {
  color: rgb(132 204 22 / 0.85) !important;
}

.tw-placeholder-lime-500\/90::placeholder {
  color: rgb(132 204 22 / 0.9) !important;
}

.tw-placeholder-lime-500\/95::placeholder {
  color: rgb(132 204 22 / 0.95) !important;
}

.tw-placeholder-lime-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(101 163 13 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-600\/0::placeholder {
  color: rgb(101 163 13 / 0) !important;
}

.tw-placeholder-lime-600\/10::placeholder {
  color: rgb(101 163 13 / 0.1) !important;
}

.tw-placeholder-lime-600\/100::placeholder {
  color: rgb(101 163 13 / 1) !important;
}

.tw-placeholder-lime-600\/15::placeholder {
  color: rgb(101 163 13 / 0.15) !important;
}

.tw-placeholder-lime-600\/20::placeholder {
  color: rgb(101 163 13 / 0.2) !important;
}

.tw-placeholder-lime-600\/25::placeholder {
  color: rgb(101 163 13 / 0.25) !important;
}

.tw-placeholder-lime-600\/30::placeholder {
  color: rgb(101 163 13 / 0.3) !important;
}

.tw-placeholder-lime-600\/35::placeholder {
  color: rgb(101 163 13 / 0.35) !important;
}

.tw-placeholder-lime-600\/40::placeholder {
  color: rgb(101 163 13 / 0.4) !important;
}

.tw-placeholder-lime-600\/45::placeholder {
  color: rgb(101 163 13 / 0.45) !important;
}

.tw-placeholder-lime-600\/5::placeholder {
  color: rgb(101 163 13 / 0.05) !important;
}

.tw-placeholder-lime-600\/50::placeholder {
  color: rgb(101 163 13 / 0.5) !important;
}

.tw-placeholder-lime-600\/55::placeholder {
  color: rgb(101 163 13 / 0.55) !important;
}

.tw-placeholder-lime-600\/60::placeholder {
  color: rgb(101 163 13 / 0.6) !important;
}

.tw-placeholder-lime-600\/65::placeholder {
  color: rgb(101 163 13 / 0.65) !important;
}

.tw-placeholder-lime-600\/70::placeholder {
  color: rgb(101 163 13 / 0.7) !important;
}

.tw-placeholder-lime-600\/75::placeholder {
  color: rgb(101 163 13 / 0.75) !important;
}

.tw-placeholder-lime-600\/80::placeholder {
  color: rgb(101 163 13 / 0.8) !important;
}

.tw-placeholder-lime-600\/85::placeholder {
  color: rgb(101 163 13 / 0.85) !important;
}

.tw-placeholder-lime-600\/90::placeholder {
  color: rgb(101 163 13 / 0.9) !important;
}

.tw-placeholder-lime-600\/95::placeholder {
  color: rgb(101 163 13 / 0.95) !important;
}

.tw-placeholder-lime-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(77 124 15 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-700\/0::placeholder {
  color: rgb(77 124 15 / 0) !important;
}

.tw-placeholder-lime-700\/10::placeholder {
  color: rgb(77 124 15 / 0.1) !important;
}

.tw-placeholder-lime-700\/100::placeholder {
  color: rgb(77 124 15 / 1) !important;
}

.tw-placeholder-lime-700\/15::placeholder {
  color: rgb(77 124 15 / 0.15) !important;
}

.tw-placeholder-lime-700\/20::placeholder {
  color: rgb(77 124 15 / 0.2) !important;
}

.tw-placeholder-lime-700\/25::placeholder {
  color: rgb(77 124 15 / 0.25) !important;
}

.tw-placeholder-lime-700\/30::placeholder {
  color: rgb(77 124 15 / 0.3) !important;
}

.tw-placeholder-lime-700\/35::placeholder {
  color: rgb(77 124 15 / 0.35) !important;
}

.tw-placeholder-lime-700\/40::placeholder {
  color: rgb(77 124 15 / 0.4) !important;
}

.tw-placeholder-lime-700\/45::placeholder {
  color: rgb(77 124 15 / 0.45) !important;
}

.tw-placeholder-lime-700\/5::placeholder {
  color: rgb(77 124 15 / 0.05) !important;
}

.tw-placeholder-lime-700\/50::placeholder {
  color: rgb(77 124 15 / 0.5) !important;
}

.tw-placeholder-lime-700\/55::placeholder {
  color: rgb(77 124 15 / 0.55) !important;
}

.tw-placeholder-lime-700\/60::placeholder {
  color: rgb(77 124 15 / 0.6) !important;
}

.tw-placeholder-lime-700\/65::placeholder {
  color: rgb(77 124 15 / 0.65) !important;
}

.tw-placeholder-lime-700\/70::placeholder {
  color: rgb(77 124 15 / 0.7) !important;
}

.tw-placeholder-lime-700\/75::placeholder {
  color: rgb(77 124 15 / 0.75) !important;
}

.tw-placeholder-lime-700\/80::placeholder {
  color: rgb(77 124 15 / 0.8) !important;
}

.tw-placeholder-lime-700\/85::placeholder {
  color: rgb(77 124 15 / 0.85) !important;
}

.tw-placeholder-lime-700\/90::placeholder {
  color: rgb(77 124 15 / 0.9) !important;
}

.tw-placeholder-lime-700\/95::placeholder {
  color: rgb(77 124 15 / 0.95) !important;
}

.tw-placeholder-lime-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(63 98 18 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-800\/0::placeholder {
  color: rgb(63 98 18 / 0) !important;
}

.tw-placeholder-lime-800\/10::placeholder {
  color: rgb(63 98 18 / 0.1) !important;
}

.tw-placeholder-lime-800\/100::placeholder {
  color: rgb(63 98 18 / 1) !important;
}

.tw-placeholder-lime-800\/15::placeholder {
  color: rgb(63 98 18 / 0.15) !important;
}

.tw-placeholder-lime-800\/20::placeholder {
  color: rgb(63 98 18 / 0.2) !important;
}

.tw-placeholder-lime-800\/25::placeholder {
  color: rgb(63 98 18 / 0.25) !important;
}

.tw-placeholder-lime-800\/30::placeholder {
  color: rgb(63 98 18 / 0.3) !important;
}

.tw-placeholder-lime-800\/35::placeholder {
  color: rgb(63 98 18 / 0.35) !important;
}

.tw-placeholder-lime-800\/40::placeholder {
  color: rgb(63 98 18 / 0.4) !important;
}

.tw-placeholder-lime-800\/45::placeholder {
  color: rgb(63 98 18 / 0.45) !important;
}

.tw-placeholder-lime-800\/5::placeholder {
  color: rgb(63 98 18 / 0.05) !important;
}

.tw-placeholder-lime-800\/50::placeholder {
  color: rgb(63 98 18 / 0.5) !important;
}

.tw-placeholder-lime-800\/55::placeholder {
  color: rgb(63 98 18 / 0.55) !important;
}

.tw-placeholder-lime-800\/60::placeholder {
  color: rgb(63 98 18 / 0.6) !important;
}

.tw-placeholder-lime-800\/65::placeholder {
  color: rgb(63 98 18 / 0.65) !important;
}

.tw-placeholder-lime-800\/70::placeholder {
  color: rgb(63 98 18 / 0.7) !important;
}

.tw-placeholder-lime-800\/75::placeholder {
  color: rgb(63 98 18 / 0.75) !important;
}

.tw-placeholder-lime-800\/80::placeholder {
  color: rgb(63 98 18 / 0.8) !important;
}

.tw-placeholder-lime-800\/85::placeholder {
  color: rgb(63 98 18 / 0.85) !important;
}

.tw-placeholder-lime-800\/90::placeholder {
  color: rgb(63 98 18 / 0.9) !important;
}

.tw-placeholder-lime-800\/95::placeholder {
  color: rgb(63 98 18 / 0.95) !important;
}

.tw-placeholder-lime-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(54 83 20 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-900\/0::placeholder {
  color: rgb(54 83 20 / 0) !important;
}

.tw-placeholder-lime-900\/10::placeholder {
  color: rgb(54 83 20 / 0.1) !important;
}

.tw-placeholder-lime-900\/100::placeholder {
  color: rgb(54 83 20 / 1) !important;
}

.tw-placeholder-lime-900\/15::placeholder {
  color: rgb(54 83 20 / 0.15) !important;
}

.tw-placeholder-lime-900\/20::placeholder {
  color: rgb(54 83 20 / 0.2) !important;
}

.tw-placeholder-lime-900\/25::placeholder {
  color: rgb(54 83 20 / 0.25) !important;
}

.tw-placeholder-lime-900\/30::placeholder {
  color: rgb(54 83 20 / 0.3) !important;
}

.tw-placeholder-lime-900\/35::placeholder {
  color: rgb(54 83 20 / 0.35) !important;
}

.tw-placeholder-lime-900\/40::placeholder {
  color: rgb(54 83 20 / 0.4) !important;
}

.tw-placeholder-lime-900\/45::placeholder {
  color: rgb(54 83 20 / 0.45) !important;
}

.tw-placeholder-lime-900\/5::placeholder {
  color: rgb(54 83 20 / 0.05) !important;
}

.tw-placeholder-lime-900\/50::placeholder {
  color: rgb(54 83 20 / 0.5) !important;
}

.tw-placeholder-lime-900\/55::placeholder {
  color: rgb(54 83 20 / 0.55) !important;
}

.tw-placeholder-lime-900\/60::placeholder {
  color: rgb(54 83 20 / 0.6) !important;
}

.tw-placeholder-lime-900\/65::placeholder {
  color: rgb(54 83 20 / 0.65) !important;
}

.tw-placeholder-lime-900\/70::placeholder {
  color: rgb(54 83 20 / 0.7) !important;
}

.tw-placeholder-lime-900\/75::placeholder {
  color: rgb(54 83 20 / 0.75) !important;
}

.tw-placeholder-lime-900\/80::placeholder {
  color: rgb(54 83 20 / 0.8) !important;
}

.tw-placeholder-lime-900\/85::placeholder {
  color: rgb(54 83 20 / 0.85) !important;
}

.tw-placeholder-lime-900\/90::placeholder {
  color: rgb(54 83 20 / 0.9) !important;
}

.tw-placeholder-lime-900\/95::placeholder {
  color: rgb(54 83 20 / 0.95) !important;
}

.tw-placeholder-lime-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(26 46 5 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-lime-950\/0::placeholder {
  color: rgb(26 46 5 / 0) !important;
}

.tw-placeholder-lime-950\/10::placeholder {
  color: rgb(26 46 5 / 0.1) !important;
}

.tw-placeholder-lime-950\/100::placeholder {
  color: rgb(26 46 5 / 1) !important;
}

.tw-placeholder-lime-950\/15::placeholder {
  color: rgb(26 46 5 / 0.15) !important;
}

.tw-placeholder-lime-950\/20::placeholder {
  color: rgb(26 46 5 / 0.2) !important;
}

.tw-placeholder-lime-950\/25::placeholder {
  color: rgb(26 46 5 / 0.25) !important;
}

.tw-placeholder-lime-950\/30::placeholder {
  color: rgb(26 46 5 / 0.3) !important;
}

.tw-placeholder-lime-950\/35::placeholder {
  color: rgb(26 46 5 / 0.35) !important;
}

.tw-placeholder-lime-950\/40::placeholder {
  color: rgb(26 46 5 / 0.4) !important;
}

.tw-placeholder-lime-950\/45::placeholder {
  color: rgb(26 46 5 / 0.45) !important;
}

.tw-placeholder-lime-950\/5::placeholder {
  color: rgb(26 46 5 / 0.05) !important;
}

.tw-placeholder-lime-950\/50::placeholder {
  color: rgb(26 46 5 / 0.5) !important;
}

.tw-placeholder-lime-950\/55::placeholder {
  color: rgb(26 46 5 / 0.55) !important;
}

.tw-placeholder-lime-950\/60::placeholder {
  color: rgb(26 46 5 / 0.6) !important;
}

.tw-placeholder-lime-950\/65::placeholder {
  color: rgb(26 46 5 / 0.65) !important;
}

.tw-placeholder-lime-950\/70::placeholder {
  color: rgb(26 46 5 / 0.7) !important;
}

.tw-placeholder-lime-950\/75::placeholder {
  color: rgb(26 46 5 / 0.75) !important;
}

.tw-placeholder-lime-950\/80::placeholder {
  color: rgb(26 46 5 / 0.8) !important;
}

.tw-placeholder-lime-950\/85::placeholder {
  color: rgb(26 46 5 / 0.85) !important;
}

.tw-placeholder-lime-950\/90::placeholder {
  color: rgb(26 46 5 / 0.9) !important;
}

.tw-placeholder-lime-950\/95::placeholder {
  color: rgb(26 46 5 / 0.95) !important;
}

.tw-placeholder-neutral-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(245 245 245 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-100\/0::placeholder {
  color: rgb(245 245 245 / 0) !important;
}

.tw-placeholder-neutral-100\/10::placeholder {
  color: rgb(245 245 245 / 0.1) !important;
}

.tw-placeholder-neutral-100\/100::placeholder {
  color: rgb(245 245 245 / 1) !important;
}

.tw-placeholder-neutral-100\/15::placeholder {
  color: rgb(245 245 245 / 0.15) !important;
}

.tw-placeholder-neutral-100\/20::placeholder {
  color: rgb(245 245 245 / 0.2) !important;
}

.tw-placeholder-neutral-100\/25::placeholder {
  color: rgb(245 245 245 / 0.25) !important;
}

.tw-placeholder-neutral-100\/30::placeholder {
  color: rgb(245 245 245 / 0.3) !important;
}

.tw-placeholder-neutral-100\/35::placeholder {
  color: rgb(245 245 245 / 0.35) !important;
}

.tw-placeholder-neutral-100\/40::placeholder {
  color: rgb(245 245 245 / 0.4) !important;
}

.tw-placeholder-neutral-100\/45::placeholder {
  color: rgb(245 245 245 / 0.45) !important;
}

.tw-placeholder-neutral-100\/5::placeholder {
  color: rgb(245 245 245 / 0.05) !important;
}

.tw-placeholder-neutral-100\/50::placeholder {
  color: rgb(245 245 245 / 0.5) !important;
}

.tw-placeholder-neutral-100\/55::placeholder {
  color: rgb(245 245 245 / 0.55) !important;
}

.tw-placeholder-neutral-100\/60::placeholder {
  color: rgb(245 245 245 / 0.6) !important;
}

.tw-placeholder-neutral-100\/65::placeholder {
  color: rgb(245 245 245 / 0.65) !important;
}

.tw-placeholder-neutral-100\/70::placeholder {
  color: rgb(245 245 245 / 0.7) !important;
}

.tw-placeholder-neutral-100\/75::placeholder {
  color: rgb(245 245 245 / 0.75) !important;
}

.tw-placeholder-neutral-100\/80::placeholder {
  color: rgb(245 245 245 / 0.8) !important;
}

.tw-placeholder-neutral-100\/85::placeholder {
  color: rgb(245 245 245 / 0.85) !important;
}

.tw-placeholder-neutral-100\/90::placeholder {
  color: rgb(245 245 245 / 0.9) !important;
}

.tw-placeholder-neutral-100\/95::placeholder {
  color: rgb(245 245 245 / 0.95) !important;
}

.tw-placeholder-neutral-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(229 229 229 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-200\/0::placeholder {
  color: rgb(229 229 229 / 0) !important;
}

.tw-placeholder-neutral-200\/10::placeholder {
  color: rgb(229 229 229 / 0.1) !important;
}

.tw-placeholder-neutral-200\/100::placeholder {
  color: rgb(229 229 229 / 1) !important;
}

.tw-placeholder-neutral-200\/15::placeholder {
  color: rgb(229 229 229 / 0.15) !important;
}

.tw-placeholder-neutral-200\/20::placeholder {
  color: rgb(229 229 229 / 0.2) !important;
}

.tw-placeholder-neutral-200\/25::placeholder {
  color: rgb(229 229 229 / 0.25) !important;
}

.tw-placeholder-neutral-200\/30::placeholder {
  color: rgb(229 229 229 / 0.3) !important;
}

.tw-placeholder-neutral-200\/35::placeholder {
  color: rgb(229 229 229 / 0.35) !important;
}

.tw-placeholder-neutral-200\/40::placeholder {
  color: rgb(229 229 229 / 0.4) !important;
}

.tw-placeholder-neutral-200\/45::placeholder {
  color: rgb(229 229 229 / 0.45) !important;
}

.tw-placeholder-neutral-200\/5::placeholder {
  color: rgb(229 229 229 / 0.05) !important;
}

.tw-placeholder-neutral-200\/50::placeholder {
  color: rgb(229 229 229 / 0.5) !important;
}

.tw-placeholder-neutral-200\/55::placeholder {
  color: rgb(229 229 229 / 0.55) !important;
}

.tw-placeholder-neutral-200\/60::placeholder {
  color: rgb(229 229 229 / 0.6) !important;
}

.tw-placeholder-neutral-200\/65::placeholder {
  color: rgb(229 229 229 / 0.65) !important;
}

.tw-placeholder-neutral-200\/70::placeholder {
  color: rgb(229 229 229 / 0.7) !important;
}

.tw-placeholder-neutral-200\/75::placeholder {
  color: rgb(229 229 229 / 0.75) !important;
}

.tw-placeholder-neutral-200\/80::placeholder {
  color: rgb(229 229 229 / 0.8) !important;
}

.tw-placeholder-neutral-200\/85::placeholder {
  color: rgb(229 229 229 / 0.85) !important;
}

.tw-placeholder-neutral-200\/90::placeholder {
  color: rgb(229 229 229 / 0.9) !important;
}

.tw-placeholder-neutral-200\/95::placeholder {
  color: rgb(229 229 229 / 0.95) !important;
}

.tw-placeholder-neutral-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(212 212 212 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-300\/0::placeholder {
  color: rgb(212 212 212 / 0) !important;
}

.tw-placeholder-neutral-300\/10::placeholder {
  color: rgb(212 212 212 / 0.1) !important;
}

.tw-placeholder-neutral-300\/100::placeholder {
  color: rgb(212 212 212 / 1) !important;
}

.tw-placeholder-neutral-300\/15::placeholder {
  color: rgb(212 212 212 / 0.15) !important;
}

.tw-placeholder-neutral-300\/20::placeholder {
  color: rgb(212 212 212 / 0.2) !important;
}

.tw-placeholder-neutral-300\/25::placeholder {
  color: rgb(212 212 212 / 0.25) !important;
}

.tw-placeholder-neutral-300\/30::placeholder {
  color: rgb(212 212 212 / 0.3) !important;
}

.tw-placeholder-neutral-300\/35::placeholder {
  color: rgb(212 212 212 / 0.35) !important;
}

.tw-placeholder-neutral-300\/40::placeholder {
  color: rgb(212 212 212 / 0.4) !important;
}

.tw-placeholder-neutral-300\/45::placeholder {
  color: rgb(212 212 212 / 0.45) !important;
}

.tw-placeholder-neutral-300\/5::placeholder {
  color: rgb(212 212 212 / 0.05) !important;
}

.tw-placeholder-neutral-300\/50::placeholder {
  color: rgb(212 212 212 / 0.5) !important;
}

.tw-placeholder-neutral-300\/55::placeholder {
  color: rgb(212 212 212 / 0.55) !important;
}

.tw-placeholder-neutral-300\/60::placeholder {
  color: rgb(212 212 212 / 0.6) !important;
}

.tw-placeholder-neutral-300\/65::placeholder {
  color: rgb(212 212 212 / 0.65) !important;
}

.tw-placeholder-neutral-300\/70::placeholder {
  color: rgb(212 212 212 / 0.7) !important;
}

.tw-placeholder-neutral-300\/75::placeholder {
  color: rgb(212 212 212 / 0.75) !important;
}

.tw-placeholder-neutral-300\/80::placeholder {
  color: rgb(212 212 212 / 0.8) !important;
}

.tw-placeholder-neutral-300\/85::placeholder {
  color: rgb(212 212 212 / 0.85) !important;
}

.tw-placeholder-neutral-300\/90::placeholder {
  color: rgb(212 212 212 / 0.9) !important;
}

.tw-placeholder-neutral-300\/95::placeholder {
  color: rgb(212 212 212 / 0.95) !important;
}

.tw-placeholder-neutral-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(163 163 163 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-400\/0::placeholder {
  color: rgb(163 163 163 / 0) !important;
}

.tw-placeholder-neutral-400\/10::placeholder {
  color: rgb(163 163 163 / 0.1) !important;
}

.tw-placeholder-neutral-400\/100::placeholder {
  color: rgb(163 163 163 / 1) !important;
}

.tw-placeholder-neutral-400\/15::placeholder {
  color: rgb(163 163 163 / 0.15) !important;
}

.tw-placeholder-neutral-400\/20::placeholder {
  color: rgb(163 163 163 / 0.2) !important;
}

.tw-placeholder-neutral-400\/25::placeholder {
  color: rgb(163 163 163 / 0.25) !important;
}

.tw-placeholder-neutral-400\/30::placeholder {
  color: rgb(163 163 163 / 0.3) !important;
}

.tw-placeholder-neutral-400\/35::placeholder {
  color: rgb(163 163 163 / 0.35) !important;
}

.tw-placeholder-neutral-400\/40::placeholder {
  color: rgb(163 163 163 / 0.4) !important;
}

.tw-placeholder-neutral-400\/45::placeholder {
  color: rgb(163 163 163 / 0.45) !important;
}

.tw-placeholder-neutral-400\/5::placeholder {
  color: rgb(163 163 163 / 0.05) !important;
}

.tw-placeholder-neutral-400\/50::placeholder {
  color: rgb(163 163 163 / 0.5) !important;
}

.tw-placeholder-neutral-400\/55::placeholder {
  color: rgb(163 163 163 / 0.55) !important;
}

.tw-placeholder-neutral-400\/60::placeholder {
  color: rgb(163 163 163 / 0.6) !important;
}

.tw-placeholder-neutral-400\/65::placeholder {
  color: rgb(163 163 163 / 0.65) !important;
}

.tw-placeholder-neutral-400\/70::placeholder {
  color: rgb(163 163 163 / 0.7) !important;
}

.tw-placeholder-neutral-400\/75::placeholder {
  color: rgb(163 163 163 / 0.75) !important;
}

.tw-placeholder-neutral-400\/80::placeholder {
  color: rgb(163 163 163 / 0.8) !important;
}

.tw-placeholder-neutral-400\/85::placeholder {
  color: rgb(163 163 163 / 0.85) !important;
}

.tw-placeholder-neutral-400\/90::placeholder {
  color: rgb(163 163 163 / 0.9) !important;
}

.tw-placeholder-neutral-400\/95::placeholder {
  color: rgb(163 163 163 / 0.95) !important;
}

.tw-placeholder-neutral-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(250 250 250 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-50\/0::placeholder {
  color: rgb(250 250 250 / 0) !important;
}

.tw-placeholder-neutral-50\/10::placeholder {
  color: rgb(250 250 250 / 0.1) !important;
}

.tw-placeholder-neutral-50\/100::placeholder {
  color: rgb(250 250 250 / 1) !important;
}

.tw-placeholder-neutral-50\/15::placeholder {
  color: rgb(250 250 250 / 0.15) !important;
}

.tw-placeholder-neutral-50\/20::placeholder {
  color: rgb(250 250 250 / 0.2) !important;
}

.tw-placeholder-neutral-50\/25::placeholder {
  color: rgb(250 250 250 / 0.25) !important;
}

.tw-placeholder-neutral-50\/30::placeholder {
  color: rgb(250 250 250 / 0.3) !important;
}

.tw-placeholder-neutral-50\/35::placeholder {
  color: rgb(250 250 250 / 0.35) !important;
}

.tw-placeholder-neutral-50\/40::placeholder {
  color: rgb(250 250 250 / 0.4) !important;
}

.tw-placeholder-neutral-50\/45::placeholder {
  color: rgb(250 250 250 / 0.45) !important;
}

.tw-placeholder-neutral-50\/5::placeholder {
  color: rgb(250 250 250 / 0.05) !important;
}

.tw-placeholder-neutral-50\/50::placeholder {
  color: rgb(250 250 250 / 0.5) !important;
}

.tw-placeholder-neutral-50\/55::placeholder {
  color: rgb(250 250 250 / 0.55) !important;
}

.tw-placeholder-neutral-50\/60::placeholder {
  color: rgb(250 250 250 / 0.6) !important;
}

.tw-placeholder-neutral-50\/65::placeholder {
  color: rgb(250 250 250 / 0.65) !important;
}

.tw-placeholder-neutral-50\/70::placeholder {
  color: rgb(250 250 250 / 0.7) !important;
}

.tw-placeholder-neutral-50\/75::placeholder {
  color: rgb(250 250 250 / 0.75) !important;
}

.tw-placeholder-neutral-50\/80::placeholder {
  color: rgb(250 250 250 / 0.8) !important;
}

.tw-placeholder-neutral-50\/85::placeholder {
  color: rgb(250 250 250 / 0.85) !important;
}

.tw-placeholder-neutral-50\/90::placeholder {
  color: rgb(250 250 250 / 0.9) !important;
}

.tw-placeholder-neutral-50\/95::placeholder {
  color: rgb(250 250 250 / 0.95) !important;
}

.tw-placeholder-neutral-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(115 115 115 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-500\/0::placeholder {
  color: rgb(115 115 115 / 0) !important;
}

.tw-placeholder-neutral-500\/10::placeholder {
  color: rgb(115 115 115 / 0.1) !important;
}

.tw-placeholder-neutral-500\/100::placeholder {
  color: rgb(115 115 115 / 1) !important;
}

.tw-placeholder-neutral-500\/15::placeholder {
  color: rgb(115 115 115 / 0.15) !important;
}

.tw-placeholder-neutral-500\/20::placeholder {
  color: rgb(115 115 115 / 0.2) !important;
}

.tw-placeholder-neutral-500\/25::placeholder {
  color: rgb(115 115 115 / 0.25) !important;
}

.tw-placeholder-neutral-500\/30::placeholder {
  color: rgb(115 115 115 / 0.3) !important;
}

.tw-placeholder-neutral-500\/35::placeholder {
  color: rgb(115 115 115 / 0.35) !important;
}

.tw-placeholder-neutral-500\/40::placeholder {
  color: rgb(115 115 115 / 0.4) !important;
}

.tw-placeholder-neutral-500\/45::placeholder {
  color: rgb(115 115 115 / 0.45) !important;
}

.tw-placeholder-neutral-500\/5::placeholder {
  color: rgb(115 115 115 / 0.05) !important;
}

.tw-placeholder-neutral-500\/50::placeholder {
  color: rgb(115 115 115 / 0.5) !important;
}

.tw-placeholder-neutral-500\/55::placeholder {
  color: rgb(115 115 115 / 0.55) !important;
}

.tw-placeholder-neutral-500\/60::placeholder {
  color: rgb(115 115 115 / 0.6) !important;
}

.tw-placeholder-neutral-500\/65::placeholder {
  color: rgb(115 115 115 / 0.65) !important;
}

.tw-placeholder-neutral-500\/70::placeholder {
  color: rgb(115 115 115 / 0.7) !important;
}

.tw-placeholder-neutral-500\/75::placeholder {
  color: rgb(115 115 115 / 0.75) !important;
}

.tw-placeholder-neutral-500\/80::placeholder {
  color: rgb(115 115 115 / 0.8) !important;
}

.tw-placeholder-neutral-500\/85::placeholder {
  color: rgb(115 115 115 / 0.85) !important;
}

.tw-placeholder-neutral-500\/90::placeholder {
  color: rgb(115 115 115 / 0.9) !important;
}

.tw-placeholder-neutral-500\/95::placeholder {
  color: rgb(115 115 115 / 0.95) !important;
}

.tw-placeholder-neutral-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(82 82 82 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-600\/0::placeholder {
  color: rgb(82 82 82 / 0) !important;
}

.tw-placeholder-neutral-600\/10::placeholder {
  color: rgb(82 82 82 / 0.1) !important;
}

.tw-placeholder-neutral-600\/100::placeholder {
  color: rgb(82 82 82 / 1) !important;
}

.tw-placeholder-neutral-600\/15::placeholder {
  color: rgb(82 82 82 / 0.15) !important;
}

.tw-placeholder-neutral-600\/20::placeholder {
  color: rgb(82 82 82 / 0.2) !important;
}

.tw-placeholder-neutral-600\/25::placeholder {
  color: rgb(82 82 82 / 0.25) !important;
}

.tw-placeholder-neutral-600\/30::placeholder {
  color: rgb(82 82 82 / 0.3) !important;
}

.tw-placeholder-neutral-600\/35::placeholder {
  color: rgb(82 82 82 / 0.35) !important;
}

.tw-placeholder-neutral-600\/40::placeholder {
  color: rgb(82 82 82 / 0.4) !important;
}

.tw-placeholder-neutral-600\/45::placeholder {
  color: rgb(82 82 82 / 0.45) !important;
}

.tw-placeholder-neutral-600\/5::placeholder {
  color: rgb(82 82 82 / 0.05) !important;
}

.tw-placeholder-neutral-600\/50::placeholder {
  color: rgb(82 82 82 / 0.5) !important;
}

.tw-placeholder-neutral-600\/55::placeholder {
  color: rgb(82 82 82 / 0.55) !important;
}

.tw-placeholder-neutral-600\/60::placeholder {
  color: rgb(82 82 82 / 0.6) !important;
}

.tw-placeholder-neutral-600\/65::placeholder {
  color: rgb(82 82 82 / 0.65) !important;
}

.tw-placeholder-neutral-600\/70::placeholder {
  color: rgb(82 82 82 / 0.7) !important;
}

.tw-placeholder-neutral-600\/75::placeholder {
  color: rgb(82 82 82 / 0.75) !important;
}

.tw-placeholder-neutral-600\/80::placeholder {
  color: rgb(82 82 82 / 0.8) !important;
}

.tw-placeholder-neutral-600\/85::placeholder {
  color: rgb(82 82 82 / 0.85) !important;
}

.tw-placeholder-neutral-600\/90::placeholder {
  color: rgb(82 82 82 / 0.9) !important;
}

.tw-placeholder-neutral-600\/95::placeholder {
  color: rgb(82 82 82 / 0.95) !important;
}

.tw-placeholder-neutral-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(64 64 64 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-700\/0::placeholder {
  color: rgb(64 64 64 / 0) !important;
}

.tw-placeholder-neutral-700\/10::placeholder {
  color: rgb(64 64 64 / 0.1) !important;
}

.tw-placeholder-neutral-700\/100::placeholder {
  color: rgb(64 64 64 / 1) !important;
}

.tw-placeholder-neutral-700\/15::placeholder {
  color: rgb(64 64 64 / 0.15) !important;
}

.tw-placeholder-neutral-700\/20::placeholder {
  color: rgb(64 64 64 / 0.2) !important;
}

.tw-placeholder-neutral-700\/25::placeholder {
  color: rgb(64 64 64 / 0.25) !important;
}

.tw-placeholder-neutral-700\/30::placeholder {
  color: rgb(64 64 64 / 0.3) !important;
}

.tw-placeholder-neutral-700\/35::placeholder {
  color: rgb(64 64 64 / 0.35) !important;
}

.tw-placeholder-neutral-700\/40::placeholder {
  color: rgb(64 64 64 / 0.4) !important;
}

.tw-placeholder-neutral-700\/45::placeholder {
  color: rgb(64 64 64 / 0.45) !important;
}

.tw-placeholder-neutral-700\/5::placeholder {
  color: rgb(64 64 64 / 0.05) !important;
}

.tw-placeholder-neutral-700\/50::placeholder {
  color: rgb(64 64 64 / 0.5) !important;
}

.tw-placeholder-neutral-700\/55::placeholder {
  color: rgb(64 64 64 / 0.55) !important;
}

.tw-placeholder-neutral-700\/60::placeholder {
  color: rgb(64 64 64 / 0.6) !important;
}

.tw-placeholder-neutral-700\/65::placeholder {
  color: rgb(64 64 64 / 0.65) !important;
}

.tw-placeholder-neutral-700\/70::placeholder {
  color: rgb(64 64 64 / 0.7) !important;
}

.tw-placeholder-neutral-700\/75::placeholder {
  color: rgb(64 64 64 / 0.75) !important;
}

.tw-placeholder-neutral-700\/80::placeholder {
  color: rgb(64 64 64 / 0.8) !important;
}

.tw-placeholder-neutral-700\/85::placeholder {
  color: rgb(64 64 64 / 0.85) !important;
}

.tw-placeholder-neutral-700\/90::placeholder {
  color: rgb(64 64 64 / 0.9) !important;
}

.tw-placeholder-neutral-700\/95::placeholder {
  color: rgb(64 64 64 / 0.95) !important;
}

.tw-placeholder-neutral-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(38 38 38 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-800\/0::placeholder {
  color: rgb(38 38 38 / 0) !important;
}

.tw-placeholder-neutral-800\/10::placeholder {
  color: rgb(38 38 38 / 0.1) !important;
}

.tw-placeholder-neutral-800\/100::placeholder {
  color: rgb(38 38 38 / 1) !important;
}

.tw-placeholder-neutral-800\/15::placeholder {
  color: rgb(38 38 38 / 0.15) !important;
}

.tw-placeholder-neutral-800\/20::placeholder {
  color: rgb(38 38 38 / 0.2) !important;
}

.tw-placeholder-neutral-800\/25::placeholder {
  color: rgb(38 38 38 / 0.25) !important;
}

.tw-placeholder-neutral-800\/30::placeholder {
  color: rgb(38 38 38 / 0.3) !important;
}

.tw-placeholder-neutral-800\/35::placeholder {
  color: rgb(38 38 38 / 0.35) !important;
}

.tw-placeholder-neutral-800\/40::placeholder {
  color: rgb(38 38 38 / 0.4) !important;
}

.tw-placeholder-neutral-800\/45::placeholder {
  color: rgb(38 38 38 / 0.45) !important;
}

.tw-placeholder-neutral-800\/5::placeholder {
  color: rgb(38 38 38 / 0.05) !important;
}

.tw-placeholder-neutral-800\/50::placeholder {
  color: rgb(38 38 38 / 0.5) !important;
}

.tw-placeholder-neutral-800\/55::placeholder {
  color: rgb(38 38 38 / 0.55) !important;
}

.tw-placeholder-neutral-800\/60::placeholder {
  color: rgb(38 38 38 / 0.6) !important;
}

.tw-placeholder-neutral-800\/65::placeholder {
  color: rgb(38 38 38 / 0.65) !important;
}

.tw-placeholder-neutral-800\/70::placeholder {
  color: rgb(38 38 38 / 0.7) !important;
}

.tw-placeholder-neutral-800\/75::placeholder {
  color: rgb(38 38 38 / 0.75) !important;
}

.tw-placeholder-neutral-800\/80::placeholder {
  color: rgb(38 38 38 / 0.8) !important;
}

.tw-placeholder-neutral-800\/85::placeholder {
  color: rgb(38 38 38 / 0.85) !important;
}

.tw-placeholder-neutral-800\/90::placeholder {
  color: rgb(38 38 38 / 0.9) !important;
}

.tw-placeholder-neutral-800\/95::placeholder {
  color: rgb(38 38 38 / 0.95) !important;
}

.tw-placeholder-neutral-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(23 23 23 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-900\/0::placeholder {
  color: rgb(23 23 23 / 0) !important;
}

.tw-placeholder-neutral-900\/10::placeholder {
  color: rgb(23 23 23 / 0.1) !important;
}

.tw-placeholder-neutral-900\/100::placeholder {
  color: rgb(23 23 23 / 1) !important;
}

.tw-placeholder-neutral-900\/15::placeholder {
  color: rgb(23 23 23 / 0.15) !important;
}

.tw-placeholder-neutral-900\/20::placeholder {
  color: rgb(23 23 23 / 0.2) !important;
}

.tw-placeholder-neutral-900\/25::placeholder {
  color: rgb(23 23 23 / 0.25) !important;
}

.tw-placeholder-neutral-900\/30::placeholder {
  color: rgb(23 23 23 / 0.3) !important;
}

.tw-placeholder-neutral-900\/35::placeholder {
  color: rgb(23 23 23 / 0.35) !important;
}

.tw-placeholder-neutral-900\/40::placeholder {
  color: rgb(23 23 23 / 0.4) !important;
}

.tw-placeholder-neutral-900\/45::placeholder {
  color: rgb(23 23 23 / 0.45) !important;
}

.tw-placeholder-neutral-900\/5::placeholder {
  color: rgb(23 23 23 / 0.05) !important;
}

.tw-placeholder-neutral-900\/50::placeholder {
  color: rgb(23 23 23 / 0.5) !important;
}

.tw-placeholder-neutral-900\/55::placeholder {
  color: rgb(23 23 23 / 0.55) !important;
}

.tw-placeholder-neutral-900\/60::placeholder {
  color: rgb(23 23 23 / 0.6) !important;
}

.tw-placeholder-neutral-900\/65::placeholder {
  color: rgb(23 23 23 / 0.65) !important;
}

.tw-placeholder-neutral-900\/70::placeholder {
  color: rgb(23 23 23 / 0.7) !important;
}

.tw-placeholder-neutral-900\/75::placeholder {
  color: rgb(23 23 23 / 0.75) !important;
}

.tw-placeholder-neutral-900\/80::placeholder {
  color: rgb(23 23 23 / 0.8) !important;
}

.tw-placeholder-neutral-900\/85::placeholder {
  color: rgb(23 23 23 / 0.85) !important;
}

.tw-placeholder-neutral-900\/90::placeholder {
  color: rgb(23 23 23 / 0.9) !important;
}

.tw-placeholder-neutral-900\/95::placeholder {
  color: rgb(23 23 23 / 0.95) !important;
}

.tw-placeholder-neutral-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(10 10 10 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-neutral-950\/0::placeholder {
  color: rgb(10 10 10 / 0) !important;
}

.tw-placeholder-neutral-950\/10::placeholder {
  color: rgb(10 10 10 / 0.1) !important;
}

.tw-placeholder-neutral-950\/100::placeholder {
  color: rgb(10 10 10 / 1) !important;
}

.tw-placeholder-neutral-950\/15::placeholder {
  color: rgb(10 10 10 / 0.15) !important;
}

.tw-placeholder-neutral-950\/20::placeholder {
  color: rgb(10 10 10 / 0.2) !important;
}

.tw-placeholder-neutral-950\/25::placeholder {
  color: rgb(10 10 10 / 0.25) !important;
}

.tw-placeholder-neutral-950\/30::placeholder {
  color: rgb(10 10 10 / 0.3) !important;
}

.tw-placeholder-neutral-950\/35::placeholder {
  color: rgb(10 10 10 / 0.35) !important;
}

.tw-placeholder-neutral-950\/40::placeholder {
  color: rgb(10 10 10 / 0.4) !important;
}

.tw-placeholder-neutral-950\/45::placeholder {
  color: rgb(10 10 10 / 0.45) !important;
}

.tw-placeholder-neutral-950\/5::placeholder {
  color: rgb(10 10 10 / 0.05) !important;
}

.tw-placeholder-neutral-950\/50::placeholder {
  color: rgb(10 10 10 / 0.5) !important;
}

.tw-placeholder-neutral-950\/55::placeholder {
  color: rgb(10 10 10 / 0.55) !important;
}

.tw-placeholder-neutral-950\/60::placeholder {
  color: rgb(10 10 10 / 0.6) !important;
}

.tw-placeholder-neutral-950\/65::placeholder {
  color: rgb(10 10 10 / 0.65) !important;
}

.tw-placeholder-neutral-950\/70::placeholder {
  color: rgb(10 10 10 / 0.7) !important;
}

.tw-placeholder-neutral-950\/75::placeholder {
  color: rgb(10 10 10 / 0.75) !important;
}

.tw-placeholder-neutral-950\/80::placeholder {
  color: rgb(10 10 10 / 0.8) !important;
}

.tw-placeholder-neutral-950\/85::placeholder {
  color: rgb(10 10 10 / 0.85) !important;
}

.tw-placeholder-neutral-950\/90::placeholder {
  color: rgb(10 10 10 / 0.9) !important;
}

.tw-placeholder-neutral-950\/95::placeholder {
  color: rgb(10 10 10 / 0.95) !important;
}

.tw-placeholder-orange-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(255 237 213 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-100\/0::placeholder {
  color: rgb(255 237 213 / 0) !important;
}

.tw-placeholder-orange-100\/10::placeholder {
  color: rgb(255 237 213 / 0.1) !important;
}

.tw-placeholder-orange-100\/100::placeholder {
  color: rgb(255 237 213 / 1) !important;
}

.tw-placeholder-orange-100\/15::placeholder {
  color: rgb(255 237 213 / 0.15) !important;
}

.tw-placeholder-orange-100\/20::placeholder {
  color: rgb(255 237 213 / 0.2) !important;
}

.tw-placeholder-orange-100\/25::placeholder {
  color: rgb(255 237 213 / 0.25) !important;
}

.tw-placeholder-orange-100\/30::placeholder {
  color: rgb(255 237 213 / 0.3) !important;
}

.tw-placeholder-orange-100\/35::placeholder {
  color: rgb(255 237 213 / 0.35) !important;
}

.tw-placeholder-orange-100\/40::placeholder {
  color: rgb(255 237 213 / 0.4) !important;
}

.tw-placeholder-orange-100\/45::placeholder {
  color: rgb(255 237 213 / 0.45) !important;
}

.tw-placeholder-orange-100\/5::placeholder {
  color: rgb(255 237 213 / 0.05) !important;
}

.tw-placeholder-orange-100\/50::placeholder {
  color: rgb(255 237 213 / 0.5) !important;
}

.tw-placeholder-orange-100\/55::placeholder {
  color: rgb(255 237 213 / 0.55) !important;
}

.tw-placeholder-orange-100\/60::placeholder {
  color: rgb(255 237 213 / 0.6) !important;
}

.tw-placeholder-orange-100\/65::placeholder {
  color: rgb(255 237 213 / 0.65) !important;
}

.tw-placeholder-orange-100\/70::placeholder {
  color: rgb(255 237 213 / 0.7) !important;
}

.tw-placeholder-orange-100\/75::placeholder {
  color: rgb(255 237 213 / 0.75) !important;
}

.tw-placeholder-orange-100\/80::placeholder {
  color: rgb(255 237 213 / 0.8) !important;
}

.tw-placeholder-orange-100\/85::placeholder {
  color: rgb(255 237 213 / 0.85) !important;
}

.tw-placeholder-orange-100\/90::placeholder {
  color: rgb(255 237 213 / 0.9) !important;
}

.tw-placeholder-orange-100\/95::placeholder {
  color: rgb(255 237 213 / 0.95) !important;
}

.tw-placeholder-orange-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 215 170 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-200\/0::placeholder {
  color: rgb(254 215 170 / 0) !important;
}

.tw-placeholder-orange-200\/10::placeholder {
  color: rgb(254 215 170 / 0.1) !important;
}

.tw-placeholder-orange-200\/100::placeholder {
  color: rgb(254 215 170 / 1) !important;
}

.tw-placeholder-orange-200\/15::placeholder {
  color: rgb(254 215 170 / 0.15) !important;
}

.tw-placeholder-orange-200\/20::placeholder {
  color: rgb(254 215 170 / 0.2) !important;
}

.tw-placeholder-orange-200\/25::placeholder {
  color: rgb(254 215 170 / 0.25) !important;
}

.tw-placeholder-orange-200\/30::placeholder {
  color: rgb(254 215 170 / 0.3) !important;
}

.tw-placeholder-orange-200\/35::placeholder {
  color: rgb(254 215 170 / 0.35) !important;
}

.tw-placeholder-orange-200\/40::placeholder {
  color: rgb(254 215 170 / 0.4) !important;
}

.tw-placeholder-orange-200\/45::placeholder {
  color: rgb(254 215 170 / 0.45) !important;
}

.tw-placeholder-orange-200\/5::placeholder {
  color: rgb(254 215 170 / 0.05) !important;
}

.tw-placeholder-orange-200\/50::placeholder {
  color: rgb(254 215 170 / 0.5) !important;
}

.tw-placeholder-orange-200\/55::placeholder {
  color: rgb(254 215 170 / 0.55) !important;
}

.tw-placeholder-orange-200\/60::placeholder {
  color: rgb(254 215 170 / 0.6) !important;
}

.tw-placeholder-orange-200\/65::placeholder {
  color: rgb(254 215 170 / 0.65) !important;
}

.tw-placeholder-orange-200\/70::placeholder {
  color: rgb(254 215 170 / 0.7) !important;
}

.tw-placeholder-orange-200\/75::placeholder {
  color: rgb(254 215 170 / 0.75) !important;
}

.tw-placeholder-orange-200\/80::placeholder {
  color: rgb(254 215 170 / 0.8) !important;
}

.tw-placeholder-orange-200\/85::placeholder {
  color: rgb(254 215 170 / 0.85) !important;
}

.tw-placeholder-orange-200\/90::placeholder {
  color: rgb(254 215 170 / 0.9) !important;
}

.tw-placeholder-orange-200\/95::placeholder {
  color: rgb(254 215 170 / 0.95) !important;
}

.tw-placeholder-orange-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(253 186 116 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-300\/0::placeholder {
  color: rgb(253 186 116 / 0) !important;
}

.tw-placeholder-orange-300\/10::placeholder {
  color: rgb(253 186 116 / 0.1) !important;
}

.tw-placeholder-orange-300\/100::placeholder {
  color: rgb(253 186 116 / 1) !important;
}

.tw-placeholder-orange-300\/15::placeholder {
  color: rgb(253 186 116 / 0.15) !important;
}

.tw-placeholder-orange-300\/20::placeholder {
  color: rgb(253 186 116 / 0.2) !important;
}

.tw-placeholder-orange-300\/25::placeholder {
  color: rgb(253 186 116 / 0.25) !important;
}

.tw-placeholder-orange-300\/30::placeholder {
  color: rgb(253 186 116 / 0.3) !important;
}

.tw-placeholder-orange-300\/35::placeholder {
  color: rgb(253 186 116 / 0.35) !important;
}

.tw-placeholder-orange-300\/40::placeholder {
  color: rgb(253 186 116 / 0.4) !important;
}

.tw-placeholder-orange-300\/45::placeholder {
  color: rgb(253 186 116 / 0.45) !important;
}

.tw-placeholder-orange-300\/5::placeholder {
  color: rgb(253 186 116 / 0.05) !important;
}

.tw-placeholder-orange-300\/50::placeholder {
  color: rgb(253 186 116 / 0.5) !important;
}

.tw-placeholder-orange-300\/55::placeholder {
  color: rgb(253 186 116 / 0.55) !important;
}

.tw-placeholder-orange-300\/60::placeholder {
  color: rgb(253 186 116 / 0.6) !important;
}

.tw-placeholder-orange-300\/65::placeholder {
  color: rgb(253 186 116 / 0.65) !important;
}

.tw-placeholder-orange-300\/70::placeholder {
  color: rgb(253 186 116 / 0.7) !important;
}

.tw-placeholder-orange-300\/75::placeholder {
  color: rgb(253 186 116 / 0.75) !important;
}

.tw-placeholder-orange-300\/80::placeholder {
  color: rgb(253 186 116 / 0.8) !important;
}

.tw-placeholder-orange-300\/85::placeholder {
  color: rgb(253 186 116 / 0.85) !important;
}

.tw-placeholder-orange-300\/90::placeholder {
  color: rgb(253 186 116 / 0.9) !important;
}

.tw-placeholder-orange-300\/95::placeholder {
  color: rgb(253 186 116 / 0.95) !important;
}

.tw-placeholder-orange-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(251 146 60 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-400\/0::placeholder {
  color: rgb(251 146 60 / 0) !important;
}

.tw-placeholder-orange-400\/10::placeholder {
  color: rgb(251 146 60 / 0.1) !important;
}

.tw-placeholder-orange-400\/100::placeholder {
  color: rgb(251 146 60 / 1) !important;
}

.tw-placeholder-orange-400\/15::placeholder {
  color: rgb(251 146 60 / 0.15) !important;
}

.tw-placeholder-orange-400\/20::placeholder {
  color: rgb(251 146 60 / 0.2) !important;
}

.tw-placeholder-orange-400\/25::placeholder {
  color: rgb(251 146 60 / 0.25) !important;
}

.tw-placeholder-orange-400\/30::placeholder {
  color: rgb(251 146 60 / 0.3) !important;
}

.tw-placeholder-orange-400\/35::placeholder {
  color: rgb(251 146 60 / 0.35) !important;
}

.tw-placeholder-orange-400\/40::placeholder {
  color: rgb(251 146 60 / 0.4) !important;
}

.tw-placeholder-orange-400\/45::placeholder {
  color: rgb(251 146 60 / 0.45) !important;
}

.tw-placeholder-orange-400\/5::placeholder {
  color: rgb(251 146 60 / 0.05) !important;
}

.tw-placeholder-orange-400\/50::placeholder {
  color: rgb(251 146 60 / 0.5) !important;
}

.tw-placeholder-orange-400\/55::placeholder {
  color: rgb(251 146 60 / 0.55) !important;
}

.tw-placeholder-orange-400\/60::placeholder {
  color: rgb(251 146 60 / 0.6) !important;
}

.tw-placeholder-orange-400\/65::placeholder {
  color: rgb(251 146 60 / 0.65) !important;
}

.tw-placeholder-orange-400\/70::placeholder {
  color: rgb(251 146 60 / 0.7) !important;
}

.tw-placeholder-orange-400\/75::placeholder {
  color: rgb(251 146 60 / 0.75) !important;
}

.tw-placeholder-orange-400\/80::placeholder {
  color: rgb(251 146 60 / 0.8) !important;
}

.tw-placeholder-orange-400\/85::placeholder {
  color: rgb(251 146 60 / 0.85) !important;
}

.tw-placeholder-orange-400\/90::placeholder {
  color: rgb(251 146 60 / 0.9) !important;
}

.tw-placeholder-orange-400\/95::placeholder {
  color: rgb(251 146 60 / 0.95) !important;
}

.tw-placeholder-orange-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(255 247 237 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-50\/0::placeholder {
  color: rgb(255 247 237 / 0) !important;
}

.tw-placeholder-orange-50\/10::placeholder {
  color: rgb(255 247 237 / 0.1) !important;
}

.tw-placeholder-orange-50\/100::placeholder {
  color: rgb(255 247 237 / 1) !important;
}

.tw-placeholder-orange-50\/15::placeholder {
  color: rgb(255 247 237 / 0.15) !important;
}

.tw-placeholder-orange-50\/20::placeholder {
  color: rgb(255 247 237 / 0.2) !important;
}

.tw-placeholder-orange-50\/25::placeholder {
  color: rgb(255 247 237 / 0.25) !important;
}

.tw-placeholder-orange-50\/30::placeholder {
  color: rgb(255 247 237 / 0.3) !important;
}

.tw-placeholder-orange-50\/35::placeholder {
  color: rgb(255 247 237 / 0.35) !important;
}

.tw-placeholder-orange-50\/40::placeholder {
  color: rgb(255 247 237 / 0.4) !important;
}

.tw-placeholder-orange-50\/45::placeholder {
  color: rgb(255 247 237 / 0.45) !important;
}

.tw-placeholder-orange-50\/5::placeholder {
  color: rgb(255 247 237 / 0.05) !important;
}

.tw-placeholder-orange-50\/50::placeholder {
  color: rgb(255 247 237 / 0.5) !important;
}

.tw-placeholder-orange-50\/55::placeholder {
  color: rgb(255 247 237 / 0.55) !important;
}

.tw-placeholder-orange-50\/60::placeholder {
  color: rgb(255 247 237 / 0.6) !important;
}

.tw-placeholder-orange-50\/65::placeholder {
  color: rgb(255 247 237 / 0.65) !important;
}

.tw-placeholder-orange-50\/70::placeholder {
  color: rgb(255 247 237 / 0.7) !important;
}

.tw-placeholder-orange-50\/75::placeholder {
  color: rgb(255 247 237 / 0.75) !important;
}

.tw-placeholder-orange-50\/80::placeholder {
  color: rgb(255 247 237 / 0.8) !important;
}

.tw-placeholder-orange-50\/85::placeholder {
  color: rgb(255 247 237 / 0.85) !important;
}

.tw-placeholder-orange-50\/90::placeholder {
  color: rgb(255 247 237 / 0.9) !important;
}

.tw-placeholder-orange-50\/95::placeholder {
  color: rgb(255 247 237 / 0.95) !important;
}

.tw-placeholder-orange-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(249 115 22 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-500\/0::placeholder {
  color: rgb(249 115 22 / 0) !important;
}

.tw-placeholder-orange-500\/10::placeholder {
  color: rgb(249 115 22 / 0.1) !important;
}

.tw-placeholder-orange-500\/100::placeholder {
  color: rgb(249 115 22 / 1) !important;
}

.tw-placeholder-orange-500\/15::placeholder {
  color: rgb(249 115 22 / 0.15) !important;
}

.tw-placeholder-orange-500\/20::placeholder {
  color: rgb(249 115 22 / 0.2) !important;
}

.tw-placeholder-orange-500\/25::placeholder {
  color: rgb(249 115 22 / 0.25) !important;
}

.tw-placeholder-orange-500\/30::placeholder {
  color: rgb(249 115 22 / 0.3) !important;
}

.tw-placeholder-orange-500\/35::placeholder {
  color: rgb(249 115 22 / 0.35) !important;
}

.tw-placeholder-orange-500\/40::placeholder {
  color: rgb(249 115 22 / 0.4) !important;
}

.tw-placeholder-orange-500\/45::placeholder {
  color: rgb(249 115 22 / 0.45) !important;
}

.tw-placeholder-orange-500\/5::placeholder {
  color: rgb(249 115 22 / 0.05) !important;
}

.tw-placeholder-orange-500\/50::placeholder {
  color: rgb(249 115 22 / 0.5) !important;
}

.tw-placeholder-orange-500\/55::placeholder {
  color: rgb(249 115 22 / 0.55) !important;
}

.tw-placeholder-orange-500\/60::placeholder {
  color: rgb(249 115 22 / 0.6) !important;
}

.tw-placeholder-orange-500\/65::placeholder {
  color: rgb(249 115 22 / 0.65) !important;
}

.tw-placeholder-orange-500\/70::placeholder {
  color: rgb(249 115 22 / 0.7) !important;
}

.tw-placeholder-orange-500\/75::placeholder {
  color: rgb(249 115 22 / 0.75) !important;
}

.tw-placeholder-orange-500\/80::placeholder {
  color: rgb(249 115 22 / 0.8) !important;
}

.tw-placeholder-orange-500\/85::placeholder {
  color: rgb(249 115 22 / 0.85) !important;
}

.tw-placeholder-orange-500\/90::placeholder {
  color: rgb(249 115 22 / 0.9) !important;
}

.tw-placeholder-orange-500\/95::placeholder {
  color: rgb(249 115 22 / 0.95) !important;
}

.tw-placeholder-orange-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(234 88 12 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-600\/0::placeholder {
  color: rgb(234 88 12 / 0) !important;
}

.tw-placeholder-orange-600\/10::placeholder {
  color: rgb(234 88 12 / 0.1) !important;
}

.tw-placeholder-orange-600\/100::placeholder {
  color: rgb(234 88 12 / 1) !important;
}

.tw-placeholder-orange-600\/15::placeholder {
  color: rgb(234 88 12 / 0.15) !important;
}

.tw-placeholder-orange-600\/20::placeholder {
  color: rgb(234 88 12 / 0.2) !important;
}

.tw-placeholder-orange-600\/25::placeholder {
  color: rgb(234 88 12 / 0.25) !important;
}

.tw-placeholder-orange-600\/30::placeholder {
  color: rgb(234 88 12 / 0.3) !important;
}

.tw-placeholder-orange-600\/35::placeholder {
  color: rgb(234 88 12 / 0.35) !important;
}

.tw-placeholder-orange-600\/40::placeholder {
  color: rgb(234 88 12 / 0.4) !important;
}

.tw-placeholder-orange-600\/45::placeholder {
  color: rgb(234 88 12 / 0.45) !important;
}

.tw-placeholder-orange-600\/5::placeholder {
  color: rgb(234 88 12 / 0.05) !important;
}

.tw-placeholder-orange-600\/50::placeholder {
  color: rgb(234 88 12 / 0.5) !important;
}

.tw-placeholder-orange-600\/55::placeholder {
  color: rgb(234 88 12 / 0.55) !important;
}

.tw-placeholder-orange-600\/60::placeholder {
  color: rgb(234 88 12 / 0.6) !important;
}

.tw-placeholder-orange-600\/65::placeholder {
  color: rgb(234 88 12 / 0.65) !important;
}

.tw-placeholder-orange-600\/70::placeholder {
  color: rgb(234 88 12 / 0.7) !important;
}

.tw-placeholder-orange-600\/75::placeholder {
  color: rgb(234 88 12 / 0.75) !important;
}

.tw-placeholder-orange-600\/80::placeholder {
  color: rgb(234 88 12 / 0.8) !important;
}

.tw-placeholder-orange-600\/85::placeholder {
  color: rgb(234 88 12 / 0.85) !important;
}

.tw-placeholder-orange-600\/90::placeholder {
  color: rgb(234 88 12 / 0.9) !important;
}

.tw-placeholder-orange-600\/95::placeholder {
  color: rgb(234 88 12 / 0.95) !important;
}

.tw-placeholder-orange-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(194 65 12 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-700\/0::placeholder {
  color: rgb(194 65 12 / 0) !important;
}

.tw-placeholder-orange-700\/10::placeholder {
  color: rgb(194 65 12 / 0.1) !important;
}

.tw-placeholder-orange-700\/100::placeholder {
  color: rgb(194 65 12 / 1) !important;
}

.tw-placeholder-orange-700\/15::placeholder {
  color: rgb(194 65 12 / 0.15) !important;
}

.tw-placeholder-orange-700\/20::placeholder {
  color: rgb(194 65 12 / 0.2) !important;
}

.tw-placeholder-orange-700\/25::placeholder {
  color: rgb(194 65 12 / 0.25) !important;
}

.tw-placeholder-orange-700\/30::placeholder {
  color: rgb(194 65 12 / 0.3) !important;
}

.tw-placeholder-orange-700\/35::placeholder {
  color: rgb(194 65 12 / 0.35) !important;
}

.tw-placeholder-orange-700\/40::placeholder {
  color: rgb(194 65 12 / 0.4) !important;
}

.tw-placeholder-orange-700\/45::placeholder {
  color: rgb(194 65 12 / 0.45) !important;
}

.tw-placeholder-orange-700\/5::placeholder {
  color: rgb(194 65 12 / 0.05) !important;
}

.tw-placeholder-orange-700\/50::placeholder {
  color: rgb(194 65 12 / 0.5) !important;
}

.tw-placeholder-orange-700\/55::placeholder {
  color: rgb(194 65 12 / 0.55) !important;
}

.tw-placeholder-orange-700\/60::placeholder {
  color: rgb(194 65 12 / 0.6) !important;
}

.tw-placeholder-orange-700\/65::placeholder {
  color: rgb(194 65 12 / 0.65) !important;
}

.tw-placeholder-orange-700\/70::placeholder {
  color: rgb(194 65 12 / 0.7) !important;
}

.tw-placeholder-orange-700\/75::placeholder {
  color: rgb(194 65 12 / 0.75) !important;
}

.tw-placeholder-orange-700\/80::placeholder {
  color: rgb(194 65 12 / 0.8) !important;
}

.tw-placeholder-orange-700\/85::placeholder {
  color: rgb(194 65 12 / 0.85) !important;
}

.tw-placeholder-orange-700\/90::placeholder {
  color: rgb(194 65 12 / 0.9) !important;
}

.tw-placeholder-orange-700\/95::placeholder {
  color: rgb(194 65 12 / 0.95) !important;
}

.tw-placeholder-orange-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(154 52 18 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-800\/0::placeholder {
  color: rgb(154 52 18 / 0) !important;
}

.tw-placeholder-orange-800\/10::placeholder {
  color: rgb(154 52 18 / 0.1) !important;
}

.tw-placeholder-orange-800\/100::placeholder {
  color: rgb(154 52 18 / 1) !important;
}

.tw-placeholder-orange-800\/15::placeholder {
  color: rgb(154 52 18 / 0.15) !important;
}

.tw-placeholder-orange-800\/20::placeholder {
  color: rgb(154 52 18 / 0.2) !important;
}

.tw-placeholder-orange-800\/25::placeholder {
  color: rgb(154 52 18 / 0.25) !important;
}

.tw-placeholder-orange-800\/30::placeholder {
  color: rgb(154 52 18 / 0.3) !important;
}

.tw-placeholder-orange-800\/35::placeholder {
  color: rgb(154 52 18 / 0.35) !important;
}

.tw-placeholder-orange-800\/40::placeholder {
  color: rgb(154 52 18 / 0.4) !important;
}

.tw-placeholder-orange-800\/45::placeholder {
  color: rgb(154 52 18 / 0.45) !important;
}

.tw-placeholder-orange-800\/5::placeholder {
  color: rgb(154 52 18 / 0.05) !important;
}

.tw-placeholder-orange-800\/50::placeholder {
  color: rgb(154 52 18 / 0.5) !important;
}

.tw-placeholder-orange-800\/55::placeholder {
  color: rgb(154 52 18 / 0.55) !important;
}

.tw-placeholder-orange-800\/60::placeholder {
  color: rgb(154 52 18 / 0.6) !important;
}

.tw-placeholder-orange-800\/65::placeholder {
  color: rgb(154 52 18 / 0.65) !important;
}

.tw-placeholder-orange-800\/70::placeholder {
  color: rgb(154 52 18 / 0.7) !important;
}

.tw-placeholder-orange-800\/75::placeholder {
  color: rgb(154 52 18 / 0.75) !important;
}

.tw-placeholder-orange-800\/80::placeholder {
  color: rgb(154 52 18 / 0.8) !important;
}

.tw-placeholder-orange-800\/85::placeholder {
  color: rgb(154 52 18 / 0.85) !important;
}

.tw-placeholder-orange-800\/90::placeholder {
  color: rgb(154 52 18 / 0.9) !important;
}

.tw-placeholder-orange-800\/95::placeholder {
  color: rgb(154 52 18 / 0.95) !important;
}

.tw-placeholder-orange-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(124 45 18 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-900\/0::placeholder {
  color: rgb(124 45 18 / 0) !important;
}

.tw-placeholder-orange-900\/10::placeholder {
  color: rgb(124 45 18 / 0.1) !important;
}

.tw-placeholder-orange-900\/100::placeholder {
  color: rgb(124 45 18 / 1) !important;
}

.tw-placeholder-orange-900\/15::placeholder {
  color: rgb(124 45 18 / 0.15) !important;
}

.tw-placeholder-orange-900\/20::placeholder {
  color: rgb(124 45 18 / 0.2) !important;
}

.tw-placeholder-orange-900\/25::placeholder {
  color: rgb(124 45 18 / 0.25) !important;
}

.tw-placeholder-orange-900\/30::placeholder {
  color: rgb(124 45 18 / 0.3) !important;
}

.tw-placeholder-orange-900\/35::placeholder {
  color: rgb(124 45 18 / 0.35) !important;
}

.tw-placeholder-orange-900\/40::placeholder {
  color: rgb(124 45 18 / 0.4) !important;
}

.tw-placeholder-orange-900\/45::placeholder {
  color: rgb(124 45 18 / 0.45) !important;
}

.tw-placeholder-orange-900\/5::placeholder {
  color: rgb(124 45 18 / 0.05) !important;
}

.tw-placeholder-orange-900\/50::placeholder {
  color: rgb(124 45 18 / 0.5) !important;
}

.tw-placeholder-orange-900\/55::placeholder {
  color: rgb(124 45 18 / 0.55) !important;
}

.tw-placeholder-orange-900\/60::placeholder {
  color: rgb(124 45 18 / 0.6) !important;
}

.tw-placeholder-orange-900\/65::placeholder {
  color: rgb(124 45 18 / 0.65) !important;
}

.tw-placeholder-orange-900\/70::placeholder {
  color: rgb(124 45 18 / 0.7) !important;
}

.tw-placeholder-orange-900\/75::placeholder {
  color: rgb(124 45 18 / 0.75) !important;
}

.tw-placeholder-orange-900\/80::placeholder {
  color: rgb(124 45 18 / 0.8) !important;
}

.tw-placeholder-orange-900\/85::placeholder {
  color: rgb(124 45 18 / 0.85) !important;
}

.tw-placeholder-orange-900\/90::placeholder {
  color: rgb(124 45 18 / 0.9) !important;
}

.tw-placeholder-orange-900\/95::placeholder {
  color: rgb(124 45 18 / 0.95) !important;
}

.tw-placeholder-orange-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(67 20 7 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-orange-950\/0::placeholder {
  color: rgb(67 20 7 / 0) !important;
}

.tw-placeholder-orange-950\/10::placeholder {
  color: rgb(67 20 7 / 0.1) !important;
}

.tw-placeholder-orange-950\/100::placeholder {
  color: rgb(67 20 7 / 1) !important;
}

.tw-placeholder-orange-950\/15::placeholder {
  color: rgb(67 20 7 / 0.15) !important;
}

.tw-placeholder-orange-950\/20::placeholder {
  color: rgb(67 20 7 / 0.2) !important;
}

.tw-placeholder-orange-950\/25::placeholder {
  color: rgb(67 20 7 / 0.25) !important;
}

.tw-placeholder-orange-950\/30::placeholder {
  color: rgb(67 20 7 / 0.3) !important;
}

.tw-placeholder-orange-950\/35::placeholder {
  color: rgb(67 20 7 / 0.35) !important;
}

.tw-placeholder-orange-950\/40::placeholder {
  color: rgb(67 20 7 / 0.4) !important;
}

.tw-placeholder-orange-950\/45::placeholder {
  color: rgb(67 20 7 / 0.45) !important;
}

.tw-placeholder-orange-950\/5::placeholder {
  color: rgb(67 20 7 / 0.05) !important;
}

.tw-placeholder-orange-950\/50::placeholder {
  color: rgb(67 20 7 / 0.5) !important;
}

.tw-placeholder-orange-950\/55::placeholder {
  color: rgb(67 20 7 / 0.55) !important;
}

.tw-placeholder-orange-950\/60::placeholder {
  color: rgb(67 20 7 / 0.6) !important;
}

.tw-placeholder-orange-950\/65::placeholder {
  color: rgb(67 20 7 / 0.65) !important;
}

.tw-placeholder-orange-950\/70::placeholder {
  color: rgb(67 20 7 / 0.7) !important;
}

.tw-placeholder-orange-950\/75::placeholder {
  color: rgb(67 20 7 / 0.75) !important;
}

.tw-placeholder-orange-950\/80::placeholder {
  color: rgb(67 20 7 / 0.8) !important;
}

.tw-placeholder-orange-950\/85::placeholder {
  color: rgb(67 20 7 / 0.85) !important;
}

.tw-placeholder-orange-950\/90::placeholder {
  color: rgb(67 20 7 / 0.9) !important;
}

.tw-placeholder-orange-950\/95::placeholder {
  color: rgb(67 20 7 / 0.95) !important;
}

.tw-placeholder-pink-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(252 231 243 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-100\/0::placeholder {
  color: rgb(252 231 243 / 0) !important;
}

.tw-placeholder-pink-100\/10::placeholder {
  color: rgb(252 231 243 / 0.1) !important;
}

.tw-placeholder-pink-100\/100::placeholder {
  color: rgb(252 231 243 / 1) !important;
}

.tw-placeholder-pink-100\/15::placeholder {
  color: rgb(252 231 243 / 0.15) !important;
}

.tw-placeholder-pink-100\/20::placeholder {
  color: rgb(252 231 243 / 0.2) !important;
}

.tw-placeholder-pink-100\/25::placeholder {
  color: rgb(252 231 243 / 0.25) !important;
}

.tw-placeholder-pink-100\/30::placeholder {
  color: rgb(252 231 243 / 0.3) !important;
}

.tw-placeholder-pink-100\/35::placeholder {
  color: rgb(252 231 243 / 0.35) !important;
}

.tw-placeholder-pink-100\/40::placeholder {
  color: rgb(252 231 243 / 0.4) !important;
}

.tw-placeholder-pink-100\/45::placeholder {
  color: rgb(252 231 243 / 0.45) !important;
}

.tw-placeholder-pink-100\/5::placeholder {
  color: rgb(252 231 243 / 0.05) !important;
}

.tw-placeholder-pink-100\/50::placeholder {
  color: rgb(252 231 243 / 0.5) !important;
}

.tw-placeholder-pink-100\/55::placeholder {
  color: rgb(252 231 243 / 0.55) !important;
}

.tw-placeholder-pink-100\/60::placeholder {
  color: rgb(252 231 243 / 0.6) !important;
}

.tw-placeholder-pink-100\/65::placeholder {
  color: rgb(252 231 243 / 0.65) !important;
}

.tw-placeholder-pink-100\/70::placeholder {
  color: rgb(252 231 243 / 0.7) !important;
}

.tw-placeholder-pink-100\/75::placeholder {
  color: rgb(252 231 243 / 0.75) !important;
}

.tw-placeholder-pink-100\/80::placeholder {
  color: rgb(252 231 243 / 0.8) !important;
}

.tw-placeholder-pink-100\/85::placeholder {
  color: rgb(252 231 243 / 0.85) !important;
}

.tw-placeholder-pink-100\/90::placeholder {
  color: rgb(252 231 243 / 0.9) !important;
}

.tw-placeholder-pink-100\/95::placeholder {
  color: rgb(252 231 243 / 0.95) !important;
}

.tw-placeholder-pink-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(251 207 232 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-200\/0::placeholder {
  color: rgb(251 207 232 / 0) !important;
}

.tw-placeholder-pink-200\/10::placeholder {
  color: rgb(251 207 232 / 0.1) !important;
}

.tw-placeholder-pink-200\/100::placeholder {
  color: rgb(251 207 232 / 1) !important;
}

.tw-placeholder-pink-200\/15::placeholder {
  color: rgb(251 207 232 / 0.15) !important;
}

.tw-placeholder-pink-200\/20::placeholder {
  color: rgb(251 207 232 / 0.2) !important;
}

.tw-placeholder-pink-200\/25::placeholder {
  color: rgb(251 207 232 / 0.25) !important;
}

.tw-placeholder-pink-200\/30::placeholder {
  color: rgb(251 207 232 / 0.3) !important;
}

.tw-placeholder-pink-200\/35::placeholder {
  color: rgb(251 207 232 / 0.35) !important;
}

.tw-placeholder-pink-200\/40::placeholder {
  color: rgb(251 207 232 / 0.4) !important;
}

.tw-placeholder-pink-200\/45::placeholder {
  color: rgb(251 207 232 / 0.45) !important;
}

.tw-placeholder-pink-200\/5::placeholder {
  color: rgb(251 207 232 / 0.05) !important;
}

.tw-placeholder-pink-200\/50::placeholder {
  color: rgb(251 207 232 / 0.5) !important;
}

.tw-placeholder-pink-200\/55::placeholder {
  color: rgb(251 207 232 / 0.55) !important;
}

.tw-placeholder-pink-200\/60::placeholder {
  color: rgb(251 207 232 / 0.6) !important;
}

.tw-placeholder-pink-200\/65::placeholder {
  color: rgb(251 207 232 / 0.65) !important;
}

.tw-placeholder-pink-200\/70::placeholder {
  color: rgb(251 207 232 / 0.7) !important;
}

.tw-placeholder-pink-200\/75::placeholder {
  color: rgb(251 207 232 / 0.75) !important;
}

.tw-placeholder-pink-200\/80::placeholder {
  color: rgb(251 207 232 / 0.8) !important;
}

.tw-placeholder-pink-200\/85::placeholder {
  color: rgb(251 207 232 / 0.85) !important;
}

.tw-placeholder-pink-200\/90::placeholder {
  color: rgb(251 207 232 / 0.9) !important;
}

.tw-placeholder-pink-200\/95::placeholder {
  color: rgb(251 207 232 / 0.95) !important;
}

.tw-placeholder-pink-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(249 168 212 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-300\/0::placeholder {
  color: rgb(249 168 212 / 0) !important;
}

.tw-placeholder-pink-300\/10::placeholder {
  color: rgb(249 168 212 / 0.1) !important;
}

.tw-placeholder-pink-300\/100::placeholder {
  color: rgb(249 168 212 / 1) !important;
}

.tw-placeholder-pink-300\/15::placeholder {
  color: rgb(249 168 212 / 0.15) !important;
}

.tw-placeholder-pink-300\/20::placeholder {
  color: rgb(249 168 212 / 0.2) !important;
}

.tw-placeholder-pink-300\/25::placeholder {
  color: rgb(249 168 212 / 0.25) !important;
}

.tw-placeholder-pink-300\/30::placeholder {
  color: rgb(249 168 212 / 0.3) !important;
}

.tw-placeholder-pink-300\/35::placeholder {
  color: rgb(249 168 212 / 0.35) !important;
}

.tw-placeholder-pink-300\/40::placeholder {
  color: rgb(249 168 212 / 0.4) !important;
}

.tw-placeholder-pink-300\/45::placeholder {
  color: rgb(249 168 212 / 0.45) !important;
}

.tw-placeholder-pink-300\/5::placeholder {
  color: rgb(249 168 212 / 0.05) !important;
}

.tw-placeholder-pink-300\/50::placeholder {
  color: rgb(249 168 212 / 0.5) !important;
}

.tw-placeholder-pink-300\/55::placeholder {
  color: rgb(249 168 212 / 0.55) !important;
}

.tw-placeholder-pink-300\/60::placeholder {
  color: rgb(249 168 212 / 0.6) !important;
}

.tw-placeholder-pink-300\/65::placeholder {
  color: rgb(249 168 212 / 0.65) !important;
}

.tw-placeholder-pink-300\/70::placeholder {
  color: rgb(249 168 212 / 0.7) !important;
}

.tw-placeholder-pink-300\/75::placeholder {
  color: rgb(249 168 212 / 0.75) !important;
}

.tw-placeholder-pink-300\/80::placeholder {
  color: rgb(249 168 212 / 0.8) !important;
}

.tw-placeholder-pink-300\/85::placeholder {
  color: rgb(249 168 212 / 0.85) !important;
}

.tw-placeholder-pink-300\/90::placeholder {
  color: rgb(249 168 212 / 0.9) !important;
}

.tw-placeholder-pink-300\/95::placeholder {
  color: rgb(249 168 212 / 0.95) !important;
}

.tw-placeholder-pink-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(244 114 182 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-400\/0::placeholder {
  color: rgb(244 114 182 / 0) !important;
}

.tw-placeholder-pink-400\/10::placeholder {
  color: rgb(244 114 182 / 0.1) !important;
}

.tw-placeholder-pink-400\/100::placeholder {
  color: rgb(244 114 182 / 1) !important;
}

.tw-placeholder-pink-400\/15::placeholder {
  color: rgb(244 114 182 / 0.15) !important;
}

.tw-placeholder-pink-400\/20::placeholder {
  color: rgb(244 114 182 / 0.2) !important;
}

.tw-placeholder-pink-400\/25::placeholder {
  color: rgb(244 114 182 / 0.25) !important;
}

.tw-placeholder-pink-400\/30::placeholder {
  color: rgb(244 114 182 / 0.3) !important;
}

.tw-placeholder-pink-400\/35::placeholder {
  color: rgb(244 114 182 / 0.35) !important;
}

.tw-placeholder-pink-400\/40::placeholder {
  color: rgb(244 114 182 / 0.4) !important;
}

.tw-placeholder-pink-400\/45::placeholder {
  color: rgb(244 114 182 / 0.45) !important;
}

.tw-placeholder-pink-400\/5::placeholder {
  color: rgb(244 114 182 / 0.05) !important;
}

.tw-placeholder-pink-400\/50::placeholder {
  color: rgb(244 114 182 / 0.5) !important;
}

.tw-placeholder-pink-400\/55::placeholder {
  color: rgb(244 114 182 / 0.55) !important;
}

.tw-placeholder-pink-400\/60::placeholder {
  color: rgb(244 114 182 / 0.6) !important;
}

.tw-placeholder-pink-400\/65::placeholder {
  color: rgb(244 114 182 / 0.65) !important;
}

.tw-placeholder-pink-400\/70::placeholder {
  color: rgb(244 114 182 / 0.7) !important;
}

.tw-placeholder-pink-400\/75::placeholder {
  color: rgb(244 114 182 / 0.75) !important;
}

.tw-placeholder-pink-400\/80::placeholder {
  color: rgb(244 114 182 / 0.8) !important;
}

.tw-placeholder-pink-400\/85::placeholder {
  color: rgb(244 114 182 / 0.85) !important;
}

.tw-placeholder-pink-400\/90::placeholder {
  color: rgb(244 114 182 / 0.9) !important;
}

.tw-placeholder-pink-400\/95::placeholder {
  color: rgb(244 114 182 / 0.95) !important;
}

.tw-placeholder-pink-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(253 242 248 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-50\/0::placeholder {
  color: rgb(253 242 248 / 0) !important;
}

.tw-placeholder-pink-50\/10::placeholder {
  color: rgb(253 242 248 / 0.1) !important;
}

.tw-placeholder-pink-50\/100::placeholder {
  color: rgb(253 242 248 / 1) !important;
}

.tw-placeholder-pink-50\/15::placeholder {
  color: rgb(253 242 248 / 0.15) !important;
}

.tw-placeholder-pink-50\/20::placeholder {
  color: rgb(253 242 248 / 0.2) !important;
}

.tw-placeholder-pink-50\/25::placeholder {
  color: rgb(253 242 248 / 0.25) !important;
}

.tw-placeholder-pink-50\/30::placeholder {
  color: rgb(253 242 248 / 0.3) !important;
}

.tw-placeholder-pink-50\/35::placeholder {
  color: rgb(253 242 248 / 0.35) !important;
}

.tw-placeholder-pink-50\/40::placeholder {
  color: rgb(253 242 248 / 0.4) !important;
}

.tw-placeholder-pink-50\/45::placeholder {
  color: rgb(253 242 248 / 0.45) !important;
}

.tw-placeholder-pink-50\/5::placeholder {
  color: rgb(253 242 248 / 0.05) !important;
}

.tw-placeholder-pink-50\/50::placeholder {
  color: rgb(253 242 248 / 0.5) !important;
}

.tw-placeholder-pink-50\/55::placeholder {
  color: rgb(253 242 248 / 0.55) !important;
}

.tw-placeholder-pink-50\/60::placeholder {
  color: rgb(253 242 248 / 0.6) !important;
}

.tw-placeholder-pink-50\/65::placeholder {
  color: rgb(253 242 248 / 0.65) !important;
}

.tw-placeholder-pink-50\/70::placeholder {
  color: rgb(253 242 248 / 0.7) !important;
}

.tw-placeholder-pink-50\/75::placeholder {
  color: rgb(253 242 248 / 0.75) !important;
}

.tw-placeholder-pink-50\/80::placeholder {
  color: rgb(253 242 248 / 0.8) !important;
}

.tw-placeholder-pink-50\/85::placeholder {
  color: rgb(253 242 248 / 0.85) !important;
}

.tw-placeholder-pink-50\/90::placeholder {
  color: rgb(253 242 248 / 0.9) !important;
}

.tw-placeholder-pink-50\/95::placeholder {
  color: rgb(253 242 248 / 0.95) !important;
}

.tw-placeholder-pink-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(236 72 153 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-500\/0::placeholder {
  color: rgb(236 72 153 / 0) !important;
}

.tw-placeholder-pink-500\/10::placeholder {
  color: rgb(236 72 153 / 0.1) !important;
}

.tw-placeholder-pink-500\/100::placeholder {
  color: rgb(236 72 153 / 1) !important;
}

.tw-placeholder-pink-500\/15::placeholder {
  color: rgb(236 72 153 / 0.15) !important;
}

.tw-placeholder-pink-500\/20::placeholder {
  color: rgb(236 72 153 / 0.2) !important;
}

.tw-placeholder-pink-500\/25::placeholder {
  color: rgb(236 72 153 / 0.25) !important;
}

.tw-placeholder-pink-500\/30::placeholder {
  color: rgb(236 72 153 / 0.3) !important;
}

.tw-placeholder-pink-500\/35::placeholder {
  color: rgb(236 72 153 / 0.35) !important;
}

.tw-placeholder-pink-500\/40::placeholder {
  color: rgb(236 72 153 / 0.4) !important;
}

.tw-placeholder-pink-500\/45::placeholder {
  color: rgb(236 72 153 / 0.45) !important;
}

.tw-placeholder-pink-500\/5::placeholder {
  color: rgb(236 72 153 / 0.05) !important;
}

.tw-placeholder-pink-500\/50::placeholder {
  color: rgb(236 72 153 / 0.5) !important;
}

.tw-placeholder-pink-500\/55::placeholder {
  color: rgb(236 72 153 / 0.55) !important;
}

.tw-placeholder-pink-500\/60::placeholder {
  color: rgb(236 72 153 / 0.6) !important;
}

.tw-placeholder-pink-500\/65::placeholder {
  color: rgb(236 72 153 / 0.65) !important;
}

.tw-placeholder-pink-500\/70::placeholder {
  color: rgb(236 72 153 / 0.7) !important;
}

.tw-placeholder-pink-500\/75::placeholder {
  color: rgb(236 72 153 / 0.75) !important;
}

.tw-placeholder-pink-500\/80::placeholder {
  color: rgb(236 72 153 / 0.8) !important;
}

.tw-placeholder-pink-500\/85::placeholder {
  color: rgb(236 72 153 / 0.85) !important;
}

.tw-placeholder-pink-500\/90::placeholder {
  color: rgb(236 72 153 / 0.9) !important;
}

.tw-placeholder-pink-500\/95::placeholder {
  color: rgb(236 72 153 / 0.95) !important;
}

.tw-placeholder-pink-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(219 39 119 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-600\/0::placeholder {
  color: rgb(219 39 119 / 0) !important;
}

.tw-placeholder-pink-600\/10::placeholder {
  color: rgb(219 39 119 / 0.1) !important;
}

.tw-placeholder-pink-600\/100::placeholder {
  color: rgb(219 39 119 / 1) !important;
}

.tw-placeholder-pink-600\/15::placeholder {
  color: rgb(219 39 119 / 0.15) !important;
}

.tw-placeholder-pink-600\/20::placeholder {
  color: rgb(219 39 119 / 0.2) !important;
}

.tw-placeholder-pink-600\/25::placeholder {
  color: rgb(219 39 119 / 0.25) !important;
}

.tw-placeholder-pink-600\/30::placeholder {
  color: rgb(219 39 119 / 0.3) !important;
}

.tw-placeholder-pink-600\/35::placeholder {
  color: rgb(219 39 119 / 0.35) !important;
}

.tw-placeholder-pink-600\/40::placeholder {
  color: rgb(219 39 119 / 0.4) !important;
}

.tw-placeholder-pink-600\/45::placeholder {
  color: rgb(219 39 119 / 0.45) !important;
}

.tw-placeholder-pink-600\/5::placeholder {
  color: rgb(219 39 119 / 0.05) !important;
}

.tw-placeholder-pink-600\/50::placeholder {
  color: rgb(219 39 119 / 0.5) !important;
}

.tw-placeholder-pink-600\/55::placeholder {
  color: rgb(219 39 119 / 0.55) !important;
}

.tw-placeholder-pink-600\/60::placeholder {
  color: rgb(219 39 119 / 0.6) !important;
}

.tw-placeholder-pink-600\/65::placeholder {
  color: rgb(219 39 119 / 0.65) !important;
}

.tw-placeholder-pink-600\/70::placeholder {
  color: rgb(219 39 119 / 0.7) !important;
}

.tw-placeholder-pink-600\/75::placeholder {
  color: rgb(219 39 119 / 0.75) !important;
}

.tw-placeholder-pink-600\/80::placeholder {
  color: rgb(219 39 119 / 0.8) !important;
}

.tw-placeholder-pink-600\/85::placeholder {
  color: rgb(219 39 119 / 0.85) !important;
}

.tw-placeholder-pink-600\/90::placeholder {
  color: rgb(219 39 119 / 0.9) !important;
}

.tw-placeholder-pink-600\/95::placeholder {
  color: rgb(219 39 119 / 0.95) !important;
}

.tw-placeholder-pink-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(190 24 93 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-700\/0::placeholder {
  color: rgb(190 24 93 / 0) !important;
}

.tw-placeholder-pink-700\/10::placeholder {
  color: rgb(190 24 93 / 0.1) !important;
}

.tw-placeholder-pink-700\/100::placeholder {
  color: rgb(190 24 93 / 1) !important;
}

.tw-placeholder-pink-700\/15::placeholder {
  color: rgb(190 24 93 / 0.15) !important;
}

.tw-placeholder-pink-700\/20::placeholder {
  color: rgb(190 24 93 / 0.2) !important;
}

.tw-placeholder-pink-700\/25::placeholder {
  color: rgb(190 24 93 / 0.25) !important;
}

.tw-placeholder-pink-700\/30::placeholder {
  color: rgb(190 24 93 / 0.3) !important;
}

.tw-placeholder-pink-700\/35::placeholder {
  color: rgb(190 24 93 / 0.35) !important;
}

.tw-placeholder-pink-700\/40::placeholder {
  color: rgb(190 24 93 / 0.4) !important;
}

.tw-placeholder-pink-700\/45::placeholder {
  color: rgb(190 24 93 / 0.45) !important;
}

.tw-placeholder-pink-700\/5::placeholder {
  color: rgb(190 24 93 / 0.05) !important;
}

.tw-placeholder-pink-700\/50::placeholder {
  color: rgb(190 24 93 / 0.5) !important;
}

.tw-placeholder-pink-700\/55::placeholder {
  color: rgb(190 24 93 / 0.55) !important;
}

.tw-placeholder-pink-700\/60::placeholder {
  color: rgb(190 24 93 / 0.6) !important;
}

.tw-placeholder-pink-700\/65::placeholder {
  color: rgb(190 24 93 / 0.65) !important;
}

.tw-placeholder-pink-700\/70::placeholder {
  color: rgb(190 24 93 / 0.7) !important;
}

.tw-placeholder-pink-700\/75::placeholder {
  color: rgb(190 24 93 / 0.75) !important;
}

.tw-placeholder-pink-700\/80::placeholder {
  color: rgb(190 24 93 / 0.8) !important;
}

.tw-placeholder-pink-700\/85::placeholder {
  color: rgb(190 24 93 / 0.85) !important;
}

.tw-placeholder-pink-700\/90::placeholder {
  color: rgb(190 24 93 / 0.9) !important;
}

.tw-placeholder-pink-700\/95::placeholder {
  color: rgb(190 24 93 / 0.95) !important;
}

.tw-placeholder-pink-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(157 23 77 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-800\/0::placeholder {
  color: rgb(157 23 77 / 0) !important;
}

.tw-placeholder-pink-800\/10::placeholder {
  color: rgb(157 23 77 / 0.1) !important;
}

.tw-placeholder-pink-800\/100::placeholder {
  color: rgb(157 23 77 / 1) !important;
}

.tw-placeholder-pink-800\/15::placeholder {
  color: rgb(157 23 77 / 0.15) !important;
}

.tw-placeholder-pink-800\/20::placeholder {
  color: rgb(157 23 77 / 0.2) !important;
}

.tw-placeholder-pink-800\/25::placeholder {
  color: rgb(157 23 77 / 0.25) !important;
}

.tw-placeholder-pink-800\/30::placeholder {
  color: rgb(157 23 77 / 0.3) !important;
}

.tw-placeholder-pink-800\/35::placeholder {
  color: rgb(157 23 77 / 0.35) !important;
}

.tw-placeholder-pink-800\/40::placeholder {
  color: rgb(157 23 77 / 0.4) !important;
}

.tw-placeholder-pink-800\/45::placeholder {
  color: rgb(157 23 77 / 0.45) !important;
}

.tw-placeholder-pink-800\/5::placeholder {
  color: rgb(157 23 77 / 0.05) !important;
}

.tw-placeholder-pink-800\/50::placeholder {
  color: rgb(157 23 77 / 0.5) !important;
}

.tw-placeholder-pink-800\/55::placeholder {
  color: rgb(157 23 77 / 0.55) !important;
}

.tw-placeholder-pink-800\/60::placeholder {
  color: rgb(157 23 77 / 0.6) !important;
}

.tw-placeholder-pink-800\/65::placeholder {
  color: rgb(157 23 77 / 0.65) !important;
}

.tw-placeholder-pink-800\/70::placeholder {
  color: rgb(157 23 77 / 0.7) !important;
}

.tw-placeholder-pink-800\/75::placeholder {
  color: rgb(157 23 77 / 0.75) !important;
}

.tw-placeholder-pink-800\/80::placeholder {
  color: rgb(157 23 77 / 0.8) !important;
}

.tw-placeholder-pink-800\/85::placeholder {
  color: rgb(157 23 77 / 0.85) !important;
}

.tw-placeholder-pink-800\/90::placeholder {
  color: rgb(157 23 77 / 0.9) !important;
}

.tw-placeholder-pink-800\/95::placeholder {
  color: rgb(157 23 77 / 0.95) !important;
}

.tw-placeholder-pink-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(131 24 67 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-900\/0::placeholder {
  color: rgb(131 24 67 / 0) !important;
}

.tw-placeholder-pink-900\/10::placeholder {
  color: rgb(131 24 67 / 0.1) !important;
}

.tw-placeholder-pink-900\/100::placeholder {
  color: rgb(131 24 67 / 1) !important;
}

.tw-placeholder-pink-900\/15::placeholder {
  color: rgb(131 24 67 / 0.15) !important;
}

.tw-placeholder-pink-900\/20::placeholder {
  color: rgb(131 24 67 / 0.2) !important;
}

.tw-placeholder-pink-900\/25::placeholder {
  color: rgb(131 24 67 / 0.25) !important;
}

.tw-placeholder-pink-900\/30::placeholder {
  color: rgb(131 24 67 / 0.3) !important;
}

.tw-placeholder-pink-900\/35::placeholder {
  color: rgb(131 24 67 / 0.35) !important;
}

.tw-placeholder-pink-900\/40::placeholder {
  color: rgb(131 24 67 / 0.4) !important;
}

.tw-placeholder-pink-900\/45::placeholder {
  color: rgb(131 24 67 / 0.45) !important;
}

.tw-placeholder-pink-900\/5::placeholder {
  color: rgb(131 24 67 / 0.05) !important;
}

.tw-placeholder-pink-900\/50::placeholder {
  color: rgb(131 24 67 / 0.5) !important;
}

.tw-placeholder-pink-900\/55::placeholder {
  color: rgb(131 24 67 / 0.55) !important;
}

.tw-placeholder-pink-900\/60::placeholder {
  color: rgb(131 24 67 / 0.6) !important;
}

.tw-placeholder-pink-900\/65::placeholder {
  color: rgb(131 24 67 / 0.65) !important;
}

.tw-placeholder-pink-900\/70::placeholder {
  color: rgb(131 24 67 / 0.7) !important;
}

.tw-placeholder-pink-900\/75::placeholder {
  color: rgb(131 24 67 / 0.75) !important;
}

.tw-placeholder-pink-900\/80::placeholder {
  color: rgb(131 24 67 / 0.8) !important;
}

.tw-placeholder-pink-900\/85::placeholder {
  color: rgb(131 24 67 / 0.85) !important;
}

.tw-placeholder-pink-900\/90::placeholder {
  color: rgb(131 24 67 / 0.9) !important;
}

.tw-placeholder-pink-900\/95::placeholder {
  color: rgb(131 24 67 / 0.95) !important;
}

.tw-placeholder-pink-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(80 7 36 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-pink-950\/0::placeholder {
  color: rgb(80 7 36 / 0) !important;
}

.tw-placeholder-pink-950\/10::placeholder {
  color: rgb(80 7 36 / 0.1) !important;
}

.tw-placeholder-pink-950\/100::placeholder {
  color: rgb(80 7 36 / 1) !important;
}

.tw-placeholder-pink-950\/15::placeholder {
  color: rgb(80 7 36 / 0.15) !important;
}

.tw-placeholder-pink-950\/20::placeholder {
  color: rgb(80 7 36 / 0.2) !important;
}

.tw-placeholder-pink-950\/25::placeholder {
  color: rgb(80 7 36 / 0.25) !important;
}

.tw-placeholder-pink-950\/30::placeholder {
  color: rgb(80 7 36 / 0.3) !important;
}

.tw-placeholder-pink-950\/35::placeholder {
  color: rgb(80 7 36 / 0.35) !important;
}

.tw-placeholder-pink-950\/40::placeholder {
  color: rgb(80 7 36 / 0.4) !important;
}

.tw-placeholder-pink-950\/45::placeholder {
  color: rgb(80 7 36 / 0.45) !important;
}

.tw-placeholder-pink-950\/5::placeholder {
  color: rgb(80 7 36 / 0.05) !important;
}

.tw-placeholder-pink-950\/50::placeholder {
  color: rgb(80 7 36 / 0.5) !important;
}

.tw-placeholder-pink-950\/55::placeholder {
  color: rgb(80 7 36 / 0.55) !important;
}

.tw-placeholder-pink-950\/60::placeholder {
  color: rgb(80 7 36 / 0.6) !important;
}

.tw-placeholder-pink-950\/65::placeholder {
  color: rgb(80 7 36 / 0.65) !important;
}

.tw-placeholder-pink-950\/70::placeholder {
  color: rgb(80 7 36 / 0.7) !important;
}

.tw-placeholder-pink-950\/75::placeholder {
  color: rgb(80 7 36 / 0.75) !important;
}

.tw-placeholder-pink-950\/80::placeholder {
  color: rgb(80 7 36 / 0.8) !important;
}

.tw-placeholder-pink-950\/85::placeholder {
  color: rgb(80 7 36 / 0.85) !important;
}

.tw-placeholder-pink-950\/90::placeholder {
  color: rgb(80 7 36 / 0.9) !important;
}

.tw-placeholder-pink-950\/95::placeholder {
  color: rgb(80 7 36 / 0.95) !important;
}

.tw-placeholder-purple-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(243 232 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-100\/0::placeholder {
  color: rgb(243 232 255 / 0) !important;
}

.tw-placeholder-purple-100\/10::placeholder {
  color: rgb(243 232 255 / 0.1) !important;
}

.tw-placeholder-purple-100\/100::placeholder {
  color: rgb(243 232 255 / 1) !important;
}

.tw-placeholder-purple-100\/15::placeholder {
  color: rgb(243 232 255 / 0.15) !important;
}

.tw-placeholder-purple-100\/20::placeholder {
  color: rgb(243 232 255 / 0.2) !important;
}

.tw-placeholder-purple-100\/25::placeholder {
  color: rgb(243 232 255 / 0.25) !important;
}

.tw-placeholder-purple-100\/30::placeholder {
  color: rgb(243 232 255 / 0.3) !important;
}

.tw-placeholder-purple-100\/35::placeholder {
  color: rgb(243 232 255 / 0.35) !important;
}

.tw-placeholder-purple-100\/40::placeholder {
  color: rgb(243 232 255 / 0.4) !important;
}

.tw-placeholder-purple-100\/45::placeholder {
  color: rgb(243 232 255 / 0.45) !important;
}

.tw-placeholder-purple-100\/5::placeholder {
  color: rgb(243 232 255 / 0.05) !important;
}

.tw-placeholder-purple-100\/50::placeholder {
  color: rgb(243 232 255 / 0.5) !important;
}

.tw-placeholder-purple-100\/55::placeholder {
  color: rgb(243 232 255 / 0.55) !important;
}

.tw-placeholder-purple-100\/60::placeholder {
  color: rgb(243 232 255 / 0.6) !important;
}

.tw-placeholder-purple-100\/65::placeholder {
  color: rgb(243 232 255 / 0.65) !important;
}

.tw-placeholder-purple-100\/70::placeholder {
  color: rgb(243 232 255 / 0.7) !important;
}

.tw-placeholder-purple-100\/75::placeholder {
  color: rgb(243 232 255 / 0.75) !important;
}

.tw-placeholder-purple-100\/80::placeholder {
  color: rgb(243 232 255 / 0.8) !important;
}

.tw-placeholder-purple-100\/85::placeholder {
  color: rgb(243 232 255 / 0.85) !important;
}

.tw-placeholder-purple-100\/90::placeholder {
  color: rgb(243 232 255 / 0.9) !important;
}

.tw-placeholder-purple-100\/95::placeholder {
  color: rgb(243 232 255 / 0.95) !important;
}

.tw-placeholder-purple-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(233 213 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-200\/0::placeholder {
  color: rgb(233 213 255 / 0) !important;
}

.tw-placeholder-purple-200\/10::placeholder {
  color: rgb(233 213 255 / 0.1) !important;
}

.tw-placeholder-purple-200\/100::placeholder {
  color: rgb(233 213 255 / 1) !important;
}

.tw-placeholder-purple-200\/15::placeholder {
  color: rgb(233 213 255 / 0.15) !important;
}

.tw-placeholder-purple-200\/20::placeholder {
  color: rgb(233 213 255 / 0.2) !important;
}

.tw-placeholder-purple-200\/25::placeholder {
  color: rgb(233 213 255 / 0.25) !important;
}

.tw-placeholder-purple-200\/30::placeholder {
  color: rgb(233 213 255 / 0.3) !important;
}

.tw-placeholder-purple-200\/35::placeholder {
  color: rgb(233 213 255 / 0.35) !important;
}

.tw-placeholder-purple-200\/40::placeholder {
  color: rgb(233 213 255 / 0.4) !important;
}

.tw-placeholder-purple-200\/45::placeholder {
  color: rgb(233 213 255 / 0.45) !important;
}

.tw-placeholder-purple-200\/5::placeholder {
  color: rgb(233 213 255 / 0.05) !important;
}

.tw-placeholder-purple-200\/50::placeholder {
  color: rgb(233 213 255 / 0.5) !important;
}

.tw-placeholder-purple-200\/55::placeholder {
  color: rgb(233 213 255 / 0.55) !important;
}

.tw-placeholder-purple-200\/60::placeholder {
  color: rgb(233 213 255 / 0.6) !important;
}

.tw-placeholder-purple-200\/65::placeholder {
  color: rgb(233 213 255 / 0.65) !important;
}

.tw-placeholder-purple-200\/70::placeholder {
  color: rgb(233 213 255 / 0.7) !important;
}

.tw-placeholder-purple-200\/75::placeholder {
  color: rgb(233 213 255 / 0.75) !important;
}

.tw-placeholder-purple-200\/80::placeholder {
  color: rgb(233 213 255 / 0.8) !important;
}

.tw-placeholder-purple-200\/85::placeholder {
  color: rgb(233 213 255 / 0.85) !important;
}

.tw-placeholder-purple-200\/90::placeholder {
  color: rgb(233 213 255 / 0.9) !important;
}

.tw-placeholder-purple-200\/95::placeholder {
  color: rgb(233 213 255 / 0.95) !important;
}

.tw-placeholder-purple-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(216 180 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-300\/0::placeholder {
  color: rgb(216 180 254 / 0) !important;
}

.tw-placeholder-purple-300\/10::placeholder {
  color: rgb(216 180 254 / 0.1) !important;
}

.tw-placeholder-purple-300\/100::placeholder {
  color: rgb(216 180 254 / 1) !important;
}

.tw-placeholder-purple-300\/15::placeholder {
  color: rgb(216 180 254 / 0.15) !important;
}

.tw-placeholder-purple-300\/20::placeholder {
  color: rgb(216 180 254 / 0.2) !important;
}

.tw-placeholder-purple-300\/25::placeholder {
  color: rgb(216 180 254 / 0.25) !important;
}

.tw-placeholder-purple-300\/30::placeholder {
  color: rgb(216 180 254 / 0.3) !important;
}

.tw-placeholder-purple-300\/35::placeholder {
  color: rgb(216 180 254 / 0.35) !important;
}

.tw-placeholder-purple-300\/40::placeholder {
  color: rgb(216 180 254 / 0.4) !important;
}

.tw-placeholder-purple-300\/45::placeholder {
  color: rgb(216 180 254 / 0.45) !important;
}

.tw-placeholder-purple-300\/5::placeholder {
  color: rgb(216 180 254 / 0.05) !important;
}

.tw-placeholder-purple-300\/50::placeholder {
  color: rgb(216 180 254 / 0.5) !important;
}

.tw-placeholder-purple-300\/55::placeholder {
  color: rgb(216 180 254 / 0.55) !important;
}

.tw-placeholder-purple-300\/60::placeholder {
  color: rgb(216 180 254 / 0.6) !important;
}

.tw-placeholder-purple-300\/65::placeholder {
  color: rgb(216 180 254 / 0.65) !important;
}

.tw-placeholder-purple-300\/70::placeholder {
  color: rgb(216 180 254 / 0.7) !important;
}

.tw-placeholder-purple-300\/75::placeholder {
  color: rgb(216 180 254 / 0.75) !important;
}

.tw-placeholder-purple-300\/80::placeholder {
  color: rgb(216 180 254 / 0.8) !important;
}

.tw-placeholder-purple-300\/85::placeholder {
  color: rgb(216 180 254 / 0.85) !important;
}

.tw-placeholder-purple-300\/90::placeholder {
  color: rgb(216 180 254 / 0.9) !important;
}

.tw-placeholder-purple-300\/95::placeholder {
  color: rgb(216 180 254 / 0.95) !important;
}

.tw-placeholder-purple-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(192 132 252 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-400\/0::placeholder {
  color: rgb(192 132 252 / 0) !important;
}

.tw-placeholder-purple-400\/10::placeholder {
  color: rgb(192 132 252 / 0.1) !important;
}

.tw-placeholder-purple-400\/100::placeholder {
  color: rgb(192 132 252 / 1) !important;
}

.tw-placeholder-purple-400\/15::placeholder {
  color: rgb(192 132 252 / 0.15) !important;
}

.tw-placeholder-purple-400\/20::placeholder {
  color: rgb(192 132 252 / 0.2) !important;
}

.tw-placeholder-purple-400\/25::placeholder {
  color: rgb(192 132 252 / 0.25) !important;
}

.tw-placeholder-purple-400\/30::placeholder {
  color: rgb(192 132 252 / 0.3) !important;
}

.tw-placeholder-purple-400\/35::placeholder {
  color: rgb(192 132 252 / 0.35) !important;
}

.tw-placeholder-purple-400\/40::placeholder {
  color: rgb(192 132 252 / 0.4) !important;
}

.tw-placeholder-purple-400\/45::placeholder {
  color: rgb(192 132 252 / 0.45) !important;
}

.tw-placeholder-purple-400\/5::placeholder {
  color: rgb(192 132 252 / 0.05) !important;
}

.tw-placeholder-purple-400\/50::placeholder {
  color: rgb(192 132 252 / 0.5) !important;
}

.tw-placeholder-purple-400\/55::placeholder {
  color: rgb(192 132 252 / 0.55) !important;
}

.tw-placeholder-purple-400\/60::placeholder {
  color: rgb(192 132 252 / 0.6) !important;
}

.tw-placeholder-purple-400\/65::placeholder {
  color: rgb(192 132 252 / 0.65) !important;
}

.tw-placeholder-purple-400\/70::placeholder {
  color: rgb(192 132 252 / 0.7) !important;
}

.tw-placeholder-purple-400\/75::placeholder {
  color: rgb(192 132 252 / 0.75) !important;
}

.tw-placeholder-purple-400\/80::placeholder {
  color: rgb(192 132 252 / 0.8) !important;
}

.tw-placeholder-purple-400\/85::placeholder {
  color: rgb(192 132 252 / 0.85) !important;
}

.tw-placeholder-purple-400\/90::placeholder {
  color: rgb(192 132 252 / 0.9) !important;
}

.tw-placeholder-purple-400\/95::placeholder {
  color: rgb(192 132 252 / 0.95) !important;
}

.tw-placeholder-purple-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(250 245 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-50\/0::placeholder {
  color: rgb(250 245 255 / 0) !important;
}

.tw-placeholder-purple-50\/10::placeholder {
  color: rgb(250 245 255 / 0.1) !important;
}

.tw-placeholder-purple-50\/100::placeholder {
  color: rgb(250 245 255 / 1) !important;
}

.tw-placeholder-purple-50\/15::placeholder {
  color: rgb(250 245 255 / 0.15) !important;
}

.tw-placeholder-purple-50\/20::placeholder {
  color: rgb(250 245 255 / 0.2) !important;
}

.tw-placeholder-purple-50\/25::placeholder {
  color: rgb(250 245 255 / 0.25) !important;
}

.tw-placeholder-purple-50\/30::placeholder {
  color: rgb(250 245 255 / 0.3) !important;
}

.tw-placeholder-purple-50\/35::placeholder {
  color: rgb(250 245 255 / 0.35) !important;
}

.tw-placeholder-purple-50\/40::placeholder {
  color: rgb(250 245 255 / 0.4) !important;
}

.tw-placeholder-purple-50\/45::placeholder {
  color: rgb(250 245 255 / 0.45) !important;
}

.tw-placeholder-purple-50\/5::placeholder {
  color: rgb(250 245 255 / 0.05) !important;
}

.tw-placeholder-purple-50\/50::placeholder {
  color: rgb(250 245 255 / 0.5) !important;
}

.tw-placeholder-purple-50\/55::placeholder {
  color: rgb(250 245 255 / 0.55) !important;
}

.tw-placeholder-purple-50\/60::placeholder {
  color: rgb(250 245 255 / 0.6) !important;
}

.tw-placeholder-purple-50\/65::placeholder {
  color: rgb(250 245 255 / 0.65) !important;
}

.tw-placeholder-purple-50\/70::placeholder {
  color: rgb(250 245 255 / 0.7) !important;
}

.tw-placeholder-purple-50\/75::placeholder {
  color: rgb(250 245 255 / 0.75) !important;
}

.tw-placeholder-purple-50\/80::placeholder {
  color: rgb(250 245 255 / 0.8) !important;
}

.tw-placeholder-purple-50\/85::placeholder {
  color: rgb(250 245 255 / 0.85) !important;
}

.tw-placeholder-purple-50\/90::placeholder {
  color: rgb(250 245 255 / 0.9) !important;
}

.tw-placeholder-purple-50\/95::placeholder {
  color: rgb(250 245 255 / 0.95) !important;
}

.tw-placeholder-purple-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(168 85 247 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-500\/0::placeholder {
  color: rgb(168 85 247 / 0) !important;
}

.tw-placeholder-purple-500\/10::placeholder {
  color: rgb(168 85 247 / 0.1) !important;
}

.tw-placeholder-purple-500\/100::placeholder {
  color: rgb(168 85 247 / 1) !important;
}

.tw-placeholder-purple-500\/15::placeholder {
  color: rgb(168 85 247 / 0.15) !important;
}

.tw-placeholder-purple-500\/20::placeholder {
  color: rgb(168 85 247 / 0.2) !important;
}

.tw-placeholder-purple-500\/25::placeholder {
  color: rgb(168 85 247 / 0.25) !important;
}

.tw-placeholder-purple-500\/30::placeholder {
  color: rgb(168 85 247 / 0.3) !important;
}

.tw-placeholder-purple-500\/35::placeholder {
  color: rgb(168 85 247 / 0.35) !important;
}

.tw-placeholder-purple-500\/40::placeholder {
  color: rgb(168 85 247 / 0.4) !important;
}

.tw-placeholder-purple-500\/45::placeholder {
  color: rgb(168 85 247 / 0.45) !important;
}

.tw-placeholder-purple-500\/5::placeholder {
  color: rgb(168 85 247 / 0.05) !important;
}

.tw-placeholder-purple-500\/50::placeholder {
  color: rgb(168 85 247 / 0.5) !important;
}

.tw-placeholder-purple-500\/55::placeholder {
  color: rgb(168 85 247 / 0.55) !important;
}

.tw-placeholder-purple-500\/60::placeholder {
  color: rgb(168 85 247 / 0.6) !important;
}

.tw-placeholder-purple-500\/65::placeholder {
  color: rgb(168 85 247 / 0.65) !important;
}

.tw-placeholder-purple-500\/70::placeholder {
  color: rgb(168 85 247 / 0.7) !important;
}

.tw-placeholder-purple-500\/75::placeholder {
  color: rgb(168 85 247 / 0.75) !important;
}

.tw-placeholder-purple-500\/80::placeholder {
  color: rgb(168 85 247 / 0.8) !important;
}

.tw-placeholder-purple-500\/85::placeholder {
  color: rgb(168 85 247 / 0.85) !important;
}

.tw-placeholder-purple-500\/90::placeholder {
  color: rgb(168 85 247 / 0.9) !important;
}

.tw-placeholder-purple-500\/95::placeholder {
  color: rgb(168 85 247 / 0.95) !important;
}

.tw-placeholder-purple-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(147 51 234 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-600\/0::placeholder {
  color: rgb(147 51 234 / 0) !important;
}

.tw-placeholder-purple-600\/10::placeholder {
  color: rgb(147 51 234 / 0.1) !important;
}

.tw-placeholder-purple-600\/100::placeholder {
  color: rgb(147 51 234 / 1) !important;
}

.tw-placeholder-purple-600\/15::placeholder {
  color: rgb(147 51 234 / 0.15) !important;
}

.tw-placeholder-purple-600\/20::placeholder {
  color: rgb(147 51 234 / 0.2) !important;
}

.tw-placeholder-purple-600\/25::placeholder {
  color: rgb(147 51 234 / 0.25) !important;
}

.tw-placeholder-purple-600\/30::placeholder {
  color: rgb(147 51 234 / 0.3) !important;
}

.tw-placeholder-purple-600\/35::placeholder {
  color: rgb(147 51 234 / 0.35) !important;
}

.tw-placeholder-purple-600\/40::placeholder {
  color: rgb(147 51 234 / 0.4) !important;
}

.tw-placeholder-purple-600\/45::placeholder {
  color: rgb(147 51 234 / 0.45) !important;
}

.tw-placeholder-purple-600\/5::placeholder {
  color: rgb(147 51 234 / 0.05) !important;
}

.tw-placeholder-purple-600\/50::placeholder {
  color: rgb(147 51 234 / 0.5) !important;
}

.tw-placeholder-purple-600\/55::placeholder {
  color: rgb(147 51 234 / 0.55) !important;
}

.tw-placeholder-purple-600\/60::placeholder {
  color: rgb(147 51 234 / 0.6) !important;
}

.tw-placeholder-purple-600\/65::placeholder {
  color: rgb(147 51 234 / 0.65) !important;
}

.tw-placeholder-purple-600\/70::placeholder {
  color: rgb(147 51 234 / 0.7) !important;
}

.tw-placeholder-purple-600\/75::placeholder {
  color: rgb(147 51 234 / 0.75) !important;
}

.tw-placeholder-purple-600\/80::placeholder {
  color: rgb(147 51 234 / 0.8) !important;
}

.tw-placeholder-purple-600\/85::placeholder {
  color: rgb(147 51 234 / 0.85) !important;
}

.tw-placeholder-purple-600\/90::placeholder {
  color: rgb(147 51 234 / 0.9) !important;
}

.tw-placeholder-purple-600\/95::placeholder {
  color: rgb(147 51 234 / 0.95) !important;
}

.tw-placeholder-purple-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(126 34 206 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-700\/0::placeholder {
  color: rgb(126 34 206 / 0) !important;
}

.tw-placeholder-purple-700\/10::placeholder {
  color: rgb(126 34 206 / 0.1) !important;
}

.tw-placeholder-purple-700\/100::placeholder {
  color: rgb(126 34 206 / 1) !important;
}

.tw-placeholder-purple-700\/15::placeholder {
  color: rgb(126 34 206 / 0.15) !important;
}

.tw-placeholder-purple-700\/20::placeholder {
  color: rgb(126 34 206 / 0.2) !important;
}

.tw-placeholder-purple-700\/25::placeholder {
  color: rgb(126 34 206 / 0.25) !important;
}

.tw-placeholder-purple-700\/30::placeholder {
  color: rgb(126 34 206 / 0.3) !important;
}

.tw-placeholder-purple-700\/35::placeholder {
  color: rgb(126 34 206 / 0.35) !important;
}

.tw-placeholder-purple-700\/40::placeholder {
  color: rgb(126 34 206 / 0.4) !important;
}

.tw-placeholder-purple-700\/45::placeholder {
  color: rgb(126 34 206 / 0.45) !important;
}

.tw-placeholder-purple-700\/5::placeholder {
  color: rgb(126 34 206 / 0.05) !important;
}

.tw-placeholder-purple-700\/50::placeholder {
  color: rgb(126 34 206 / 0.5) !important;
}

.tw-placeholder-purple-700\/55::placeholder {
  color: rgb(126 34 206 / 0.55) !important;
}

.tw-placeholder-purple-700\/60::placeholder {
  color: rgb(126 34 206 / 0.6) !important;
}

.tw-placeholder-purple-700\/65::placeholder {
  color: rgb(126 34 206 / 0.65) !important;
}

.tw-placeholder-purple-700\/70::placeholder {
  color: rgb(126 34 206 / 0.7) !important;
}

.tw-placeholder-purple-700\/75::placeholder {
  color: rgb(126 34 206 / 0.75) !important;
}

.tw-placeholder-purple-700\/80::placeholder {
  color: rgb(126 34 206 / 0.8) !important;
}

.tw-placeholder-purple-700\/85::placeholder {
  color: rgb(126 34 206 / 0.85) !important;
}

.tw-placeholder-purple-700\/90::placeholder {
  color: rgb(126 34 206 / 0.9) !important;
}

.tw-placeholder-purple-700\/95::placeholder {
  color: rgb(126 34 206 / 0.95) !important;
}

.tw-placeholder-purple-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(107 33 168 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-800\/0::placeholder {
  color: rgb(107 33 168 / 0) !important;
}

.tw-placeholder-purple-800\/10::placeholder {
  color: rgb(107 33 168 / 0.1) !important;
}

.tw-placeholder-purple-800\/100::placeholder {
  color: rgb(107 33 168 / 1) !important;
}

.tw-placeholder-purple-800\/15::placeholder {
  color: rgb(107 33 168 / 0.15) !important;
}

.tw-placeholder-purple-800\/20::placeholder {
  color: rgb(107 33 168 / 0.2) !important;
}

.tw-placeholder-purple-800\/25::placeholder {
  color: rgb(107 33 168 / 0.25) !important;
}

.tw-placeholder-purple-800\/30::placeholder {
  color: rgb(107 33 168 / 0.3) !important;
}

.tw-placeholder-purple-800\/35::placeholder {
  color: rgb(107 33 168 / 0.35) !important;
}

.tw-placeholder-purple-800\/40::placeholder {
  color: rgb(107 33 168 / 0.4) !important;
}

.tw-placeholder-purple-800\/45::placeholder {
  color: rgb(107 33 168 / 0.45) !important;
}

.tw-placeholder-purple-800\/5::placeholder {
  color: rgb(107 33 168 / 0.05) !important;
}

.tw-placeholder-purple-800\/50::placeholder {
  color: rgb(107 33 168 / 0.5) !important;
}

.tw-placeholder-purple-800\/55::placeholder {
  color: rgb(107 33 168 / 0.55) !important;
}

.tw-placeholder-purple-800\/60::placeholder {
  color: rgb(107 33 168 / 0.6) !important;
}

.tw-placeholder-purple-800\/65::placeholder {
  color: rgb(107 33 168 / 0.65) !important;
}

.tw-placeholder-purple-800\/70::placeholder {
  color: rgb(107 33 168 / 0.7) !important;
}

.tw-placeholder-purple-800\/75::placeholder {
  color: rgb(107 33 168 / 0.75) !important;
}

.tw-placeholder-purple-800\/80::placeholder {
  color: rgb(107 33 168 / 0.8) !important;
}

.tw-placeholder-purple-800\/85::placeholder {
  color: rgb(107 33 168 / 0.85) !important;
}

.tw-placeholder-purple-800\/90::placeholder {
  color: rgb(107 33 168 / 0.9) !important;
}

.tw-placeholder-purple-800\/95::placeholder {
  color: rgb(107 33 168 / 0.95) !important;
}

.tw-placeholder-purple-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(88 28 135 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-900\/0::placeholder {
  color: rgb(88 28 135 / 0) !important;
}

.tw-placeholder-purple-900\/10::placeholder {
  color: rgb(88 28 135 / 0.1) !important;
}

.tw-placeholder-purple-900\/100::placeholder {
  color: rgb(88 28 135 / 1) !important;
}

.tw-placeholder-purple-900\/15::placeholder {
  color: rgb(88 28 135 / 0.15) !important;
}

.tw-placeholder-purple-900\/20::placeholder {
  color: rgb(88 28 135 / 0.2) !important;
}

.tw-placeholder-purple-900\/25::placeholder {
  color: rgb(88 28 135 / 0.25) !important;
}

.tw-placeholder-purple-900\/30::placeholder {
  color: rgb(88 28 135 / 0.3) !important;
}

.tw-placeholder-purple-900\/35::placeholder {
  color: rgb(88 28 135 / 0.35) !important;
}

.tw-placeholder-purple-900\/40::placeholder {
  color: rgb(88 28 135 / 0.4) !important;
}

.tw-placeholder-purple-900\/45::placeholder {
  color: rgb(88 28 135 / 0.45) !important;
}

.tw-placeholder-purple-900\/5::placeholder {
  color: rgb(88 28 135 / 0.05) !important;
}

.tw-placeholder-purple-900\/50::placeholder {
  color: rgb(88 28 135 / 0.5) !important;
}

.tw-placeholder-purple-900\/55::placeholder {
  color: rgb(88 28 135 / 0.55) !important;
}

.tw-placeholder-purple-900\/60::placeholder {
  color: rgb(88 28 135 / 0.6) !important;
}

.tw-placeholder-purple-900\/65::placeholder {
  color: rgb(88 28 135 / 0.65) !important;
}

.tw-placeholder-purple-900\/70::placeholder {
  color: rgb(88 28 135 / 0.7) !important;
}

.tw-placeholder-purple-900\/75::placeholder {
  color: rgb(88 28 135 / 0.75) !important;
}

.tw-placeholder-purple-900\/80::placeholder {
  color: rgb(88 28 135 / 0.8) !important;
}

.tw-placeholder-purple-900\/85::placeholder {
  color: rgb(88 28 135 / 0.85) !important;
}

.tw-placeholder-purple-900\/90::placeholder {
  color: rgb(88 28 135 / 0.9) !important;
}

.tw-placeholder-purple-900\/95::placeholder {
  color: rgb(88 28 135 / 0.95) !important;
}

.tw-placeholder-purple-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(59 7 100 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-purple-950\/0::placeholder {
  color: rgb(59 7 100 / 0) !important;
}

.tw-placeholder-purple-950\/10::placeholder {
  color: rgb(59 7 100 / 0.1) !important;
}

.tw-placeholder-purple-950\/100::placeholder {
  color: rgb(59 7 100 / 1) !important;
}

.tw-placeholder-purple-950\/15::placeholder {
  color: rgb(59 7 100 / 0.15) !important;
}

.tw-placeholder-purple-950\/20::placeholder {
  color: rgb(59 7 100 / 0.2) !important;
}

.tw-placeholder-purple-950\/25::placeholder {
  color: rgb(59 7 100 / 0.25) !important;
}

.tw-placeholder-purple-950\/30::placeholder {
  color: rgb(59 7 100 / 0.3) !important;
}

.tw-placeholder-purple-950\/35::placeholder {
  color: rgb(59 7 100 / 0.35) !important;
}

.tw-placeholder-purple-950\/40::placeholder {
  color: rgb(59 7 100 / 0.4) !important;
}

.tw-placeholder-purple-950\/45::placeholder {
  color: rgb(59 7 100 / 0.45) !important;
}

.tw-placeholder-purple-950\/5::placeholder {
  color: rgb(59 7 100 / 0.05) !important;
}

.tw-placeholder-purple-950\/50::placeholder {
  color: rgb(59 7 100 / 0.5) !important;
}

.tw-placeholder-purple-950\/55::placeholder {
  color: rgb(59 7 100 / 0.55) !important;
}

.tw-placeholder-purple-950\/60::placeholder {
  color: rgb(59 7 100 / 0.6) !important;
}

.tw-placeholder-purple-950\/65::placeholder {
  color: rgb(59 7 100 / 0.65) !important;
}

.tw-placeholder-purple-950\/70::placeholder {
  color: rgb(59 7 100 / 0.7) !important;
}

.tw-placeholder-purple-950\/75::placeholder {
  color: rgb(59 7 100 / 0.75) !important;
}

.tw-placeholder-purple-950\/80::placeholder {
  color: rgb(59 7 100 / 0.8) !important;
}

.tw-placeholder-purple-950\/85::placeholder {
  color: rgb(59 7 100 / 0.85) !important;
}

.tw-placeholder-purple-950\/90::placeholder {
  color: rgb(59 7 100 / 0.9) !important;
}

.tw-placeholder-purple-950\/95::placeholder {
  color: rgb(59 7 100 / 0.95) !important;
}

.tw-placeholder-red-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 226 226 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-100\/0::placeholder {
  color: rgb(254 226 226 / 0) !important;
}

.tw-placeholder-red-100\/10::placeholder {
  color: rgb(254 226 226 / 0.1) !important;
}

.tw-placeholder-red-100\/100::placeholder {
  color: rgb(254 226 226 / 1) !important;
}

.tw-placeholder-red-100\/15::placeholder {
  color: rgb(254 226 226 / 0.15) !important;
}

.tw-placeholder-red-100\/20::placeholder {
  color: rgb(254 226 226 / 0.2) !important;
}

.tw-placeholder-red-100\/25::placeholder {
  color: rgb(254 226 226 / 0.25) !important;
}

.tw-placeholder-red-100\/30::placeholder {
  color: rgb(254 226 226 / 0.3) !important;
}

.tw-placeholder-red-100\/35::placeholder {
  color: rgb(254 226 226 / 0.35) !important;
}

.tw-placeholder-red-100\/40::placeholder {
  color: rgb(254 226 226 / 0.4) !important;
}

.tw-placeholder-red-100\/45::placeholder {
  color: rgb(254 226 226 / 0.45) !important;
}

.tw-placeholder-red-100\/5::placeholder {
  color: rgb(254 226 226 / 0.05) !important;
}

.tw-placeholder-red-100\/50::placeholder {
  color: rgb(254 226 226 / 0.5) !important;
}

.tw-placeholder-red-100\/55::placeholder {
  color: rgb(254 226 226 / 0.55) !important;
}

.tw-placeholder-red-100\/60::placeholder {
  color: rgb(254 226 226 / 0.6) !important;
}

.tw-placeholder-red-100\/65::placeholder {
  color: rgb(254 226 226 / 0.65) !important;
}

.tw-placeholder-red-100\/70::placeholder {
  color: rgb(254 226 226 / 0.7) !important;
}

.tw-placeholder-red-100\/75::placeholder {
  color: rgb(254 226 226 / 0.75) !important;
}

.tw-placeholder-red-100\/80::placeholder {
  color: rgb(254 226 226 / 0.8) !important;
}

.tw-placeholder-red-100\/85::placeholder {
  color: rgb(254 226 226 / 0.85) !important;
}

.tw-placeholder-red-100\/90::placeholder {
  color: rgb(254 226 226 / 0.9) !important;
}

.tw-placeholder-red-100\/95::placeholder {
  color: rgb(254 226 226 / 0.95) !important;
}

.tw-placeholder-red-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 202 202 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-200\/0::placeholder {
  color: rgb(254 202 202 / 0) !important;
}

.tw-placeholder-red-200\/10::placeholder {
  color: rgb(254 202 202 / 0.1) !important;
}

.tw-placeholder-red-200\/100::placeholder {
  color: rgb(254 202 202 / 1) !important;
}

.tw-placeholder-red-200\/15::placeholder {
  color: rgb(254 202 202 / 0.15) !important;
}

.tw-placeholder-red-200\/20::placeholder {
  color: rgb(254 202 202 / 0.2) !important;
}

.tw-placeholder-red-200\/25::placeholder {
  color: rgb(254 202 202 / 0.25) !important;
}

.tw-placeholder-red-200\/30::placeholder {
  color: rgb(254 202 202 / 0.3) !important;
}

.tw-placeholder-red-200\/35::placeholder {
  color: rgb(254 202 202 / 0.35) !important;
}

.tw-placeholder-red-200\/40::placeholder {
  color: rgb(254 202 202 / 0.4) !important;
}

.tw-placeholder-red-200\/45::placeholder {
  color: rgb(254 202 202 / 0.45) !important;
}

.tw-placeholder-red-200\/5::placeholder {
  color: rgb(254 202 202 / 0.05) !important;
}

.tw-placeholder-red-200\/50::placeholder {
  color: rgb(254 202 202 / 0.5) !important;
}

.tw-placeholder-red-200\/55::placeholder {
  color: rgb(254 202 202 / 0.55) !important;
}

.tw-placeholder-red-200\/60::placeholder {
  color: rgb(254 202 202 / 0.6) !important;
}

.tw-placeholder-red-200\/65::placeholder {
  color: rgb(254 202 202 / 0.65) !important;
}

.tw-placeholder-red-200\/70::placeholder {
  color: rgb(254 202 202 / 0.7) !important;
}

.tw-placeholder-red-200\/75::placeholder {
  color: rgb(254 202 202 / 0.75) !important;
}

.tw-placeholder-red-200\/80::placeholder {
  color: rgb(254 202 202 / 0.8) !important;
}

.tw-placeholder-red-200\/85::placeholder {
  color: rgb(254 202 202 / 0.85) !important;
}

.tw-placeholder-red-200\/90::placeholder {
  color: rgb(254 202 202 / 0.9) !important;
}

.tw-placeholder-red-200\/95::placeholder {
  color: rgb(254 202 202 / 0.95) !important;
}

.tw-placeholder-red-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(252 165 165 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-300\/0::placeholder {
  color: rgb(252 165 165 / 0) !important;
}

.tw-placeholder-red-300\/10::placeholder {
  color: rgb(252 165 165 / 0.1) !important;
}

.tw-placeholder-red-300\/100::placeholder {
  color: rgb(252 165 165 / 1) !important;
}

.tw-placeholder-red-300\/15::placeholder {
  color: rgb(252 165 165 / 0.15) !important;
}

.tw-placeholder-red-300\/20::placeholder {
  color: rgb(252 165 165 / 0.2) !important;
}

.tw-placeholder-red-300\/25::placeholder {
  color: rgb(252 165 165 / 0.25) !important;
}

.tw-placeholder-red-300\/30::placeholder {
  color: rgb(252 165 165 / 0.3) !important;
}

.tw-placeholder-red-300\/35::placeholder {
  color: rgb(252 165 165 / 0.35) !important;
}

.tw-placeholder-red-300\/40::placeholder {
  color: rgb(252 165 165 / 0.4) !important;
}

.tw-placeholder-red-300\/45::placeholder {
  color: rgb(252 165 165 / 0.45) !important;
}

.tw-placeholder-red-300\/5::placeholder {
  color: rgb(252 165 165 / 0.05) !important;
}

.tw-placeholder-red-300\/50::placeholder {
  color: rgb(252 165 165 / 0.5) !important;
}

.tw-placeholder-red-300\/55::placeholder {
  color: rgb(252 165 165 / 0.55) !important;
}

.tw-placeholder-red-300\/60::placeholder {
  color: rgb(252 165 165 / 0.6) !important;
}

.tw-placeholder-red-300\/65::placeholder {
  color: rgb(252 165 165 / 0.65) !important;
}

.tw-placeholder-red-300\/70::placeholder {
  color: rgb(252 165 165 / 0.7) !important;
}

.tw-placeholder-red-300\/75::placeholder {
  color: rgb(252 165 165 / 0.75) !important;
}

.tw-placeholder-red-300\/80::placeholder {
  color: rgb(252 165 165 / 0.8) !important;
}

.tw-placeholder-red-300\/85::placeholder {
  color: rgb(252 165 165 / 0.85) !important;
}

.tw-placeholder-red-300\/90::placeholder {
  color: rgb(252 165 165 / 0.9) !important;
}

.tw-placeholder-red-300\/95::placeholder {
  color: rgb(252 165 165 / 0.95) !important;
}

.tw-placeholder-red-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(248 113 113 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-400\/0::placeholder {
  color: rgb(248 113 113 / 0) !important;
}

.tw-placeholder-red-400\/10::placeholder {
  color: rgb(248 113 113 / 0.1) !important;
}

.tw-placeholder-red-400\/100::placeholder {
  color: rgb(248 113 113 / 1) !important;
}

.tw-placeholder-red-400\/15::placeholder {
  color: rgb(248 113 113 / 0.15) !important;
}

.tw-placeholder-red-400\/20::placeholder {
  color: rgb(248 113 113 / 0.2) !important;
}

.tw-placeholder-red-400\/25::placeholder {
  color: rgb(248 113 113 / 0.25) !important;
}

.tw-placeholder-red-400\/30::placeholder {
  color: rgb(248 113 113 / 0.3) !important;
}

.tw-placeholder-red-400\/35::placeholder {
  color: rgb(248 113 113 / 0.35) !important;
}

.tw-placeholder-red-400\/40::placeholder {
  color: rgb(248 113 113 / 0.4) !important;
}

.tw-placeholder-red-400\/45::placeholder {
  color: rgb(248 113 113 / 0.45) !important;
}

.tw-placeholder-red-400\/5::placeholder {
  color: rgb(248 113 113 / 0.05) !important;
}

.tw-placeholder-red-400\/50::placeholder {
  color: rgb(248 113 113 / 0.5) !important;
}

.tw-placeholder-red-400\/55::placeholder {
  color: rgb(248 113 113 / 0.55) !important;
}

.tw-placeholder-red-400\/60::placeholder {
  color: rgb(248 113 113 / 0.6) !important;
}

.tw-placeholder-red-400\/65::placeholder {
  color: rgb(248 113 113 / 0.65) !important;
}

.tw-placeholder-red-400\/70::placeholder {
  color: rgb(248 113 113 / 0.7) !important;
}

.tw-placeholder-red-400\/75::placeholder {
  color: rgb(248 113 113 / 0.75) !important;
}

.tw-placeholder-red-400\/80::placeholder {
  color: rgb(248 113 113 / 0.8) !important;
}

.tw-placeholder-red-400\/85::placeholder {
  color: rgb(248 113 113 / 0.85) !important;
}

.tw-placeholder-red-400\/90::placeholder {
  color: rgb(248 113 113 / 0.9) !important;
}

.tw-placeholder-red-400\/95::placeholder {
  color: rgb(248 113 113 / 0.95) !important;
}

.tw-placeholder-red-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 242 242 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-50\/0::placeholder {
  color: rgb(254 242 242 / 0) !important;
}

.tw-placeholder-red-50\/10::placeholder {
  color: rgb(254 242 242 / 0.1) !important;
}

.tw-placeholder-red-50\/100::placeholder {
  color: rgb(254 242 242 / 1) !important;
}

.tw-placeholder-red-50\/15::placeholder {
  color: rgb(254 242 242 / 0.15) !important;
}

.tw-placeholder-red-50\/20::placeholder {
  color: rgb(254 242 242 / 0.2) !important;
}

.tw-placeholder-red-50\/25::placeholder {
  color: rgb(254 242 242 / 0.25) !important;
}

.tw-placeholder-red-50\/30::placeholder {
  color: rgb(254 242 242 / 0.3) !important;
}

.tw-placeholder-red-50\/35::placeholder {
  color: rgb(254 242 242 / 0.35) !important;
}

.tw-placeholder-red-50\/40::placeholder {
  color: rgb(254 242 242 / 0.4) !important;
}

.tw-placeholder-red-50\/45::placeholder {
  color: rgb(254 242 242 / 0.45) !important;
}

.tw-placeholder-red-50\/5::placeholder {
  color: rgb(254 242 242 / 0.05) !important;
}

.tw-placeholder-red-50\/50::placeholder {
  color: rgb(254 242 242 / 0.5) !important;
}

.tw-placeholder-red-50\/55::placeholder {
  color: rgb(254 242 242 / 0.55) !important;
}

.tw-placeholder-red-50\/60::placeholder {
  color: rgb(254 242 242 / 0.6) !important;
}

.tw-placeholder-red-50\/65::placeholder {
  color: rgb(254 242 242 / 0.65) !important;
}

.tw-placeholder-red-50\/70::placeholder {
  color: rgb(254 242 242 / 0.7) !important;
}

.tw-placeholder-red-50\/75::placeholder {
  color: rgb(254 242 242 / 0.75) !important;
}

.tw-placeholder-red-50\/80::placeholder {
  color: rgb(254 242 242 / 0.8) !important;
}

.tw-placeholder-red-50\/85::placeholder {
  color: rgb(254 242 242 / 0.85) !important;
}

.tw-placeholder-red-50\/90::placeholder {
  color: rgb(254 242 242 / 0.9) !important;
}

.tw-placeholder-red-50\/95::placeholder {
  color: rgb(254 242 242 / 0.95) !important;
}

.tw-placeholder-red-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(239 68 68 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-500\/0::placeholder {
  color: rgb(239 68 68 / 0) !important;
}

.tw-placeholder-red-500\/10::placeholder {
  color: rgb(239 68 68 / 0.1) !important;
}

.tw-placeholder-red-500\/100::placeholder {
  color: rgb(239 68 68 / 1) !important;
}

.tw-placeholder-red-500\/15::placeholder {
  color: rgb(239 68 68 / 0.15) !important;
}

.tw-placeholder-red-500\/20::placeholder {
  color: rgb(239 68 68 / 0.2) !important;
}

.tw-placeholder-red-500\/25::placeholder {
  color: rgb(239 68 68 / 0.25) !important;
}

.tw-placeholder-red-500\/30::placeholder {
  color: rgb(239 68 68 / 0.3) !important;
}

.tw-placeholder-red-500\/35::placeholder {
  color: rgb(239 68 68 / 0.35) !important;
}

.tw-placeholder-red-500\/40::placeholder {
  color: rgb(239 68 68 / 0.4) !important;
}

.tw-placeholder-red-500\/45::placeholder {
  color: rgb(239 68 68 / 0.45) !important;
}

.tw-placeholder-red-500\/5::placeholder {
  color: rgb(239 68 68 / 0.05) !important;
}

.tw-placeholder-red-500\/50::placeholder {
  color: rgb(239 68 68 / 0.5) !important;
}

.tw-placeholder-red-500\/55::placeholder {
  color: rgb(239 68 68 / 0.55) !important;
}

.tw-placeholder-red-500\/60::placeholder {
  color: rgb(239 68 68 / 0.6) !important;
}

.tw-placeholder-red-500\/65::placeholder {
  color: rgb(239 68 68 / 0.65) !important;
}

.tw-placeholder-red-500\/70::placeholder {
  color: rgb(239 68 68 / 0.7) !important;
}

.tw-placeholder-red-500\/75::placeholder {
  color: rgb(239 68 68 / 0.75) !important;
}

.tw-placeholder-red-500\/80::placeholder {
  color: rgb(239 68 68 / 0.8) !important;
}

.tw-placeholder-red-500\/85::placeholder {
  color: rgb(239 68 68 / 0.85) !important;
}

.tw-placeholder-red-500\/90::placeholder {
  color: rgb(239 68 68 / 0.9) !important;
}

.tw-placeholder-red-500\/95::placeholder {
  color: rgb(239 68 68 / 0.95) !important;
}

.tw-placeholder-red-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(220 38 38 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-600\/0::placeholder {
  color: rgb(220 38 38 / 0) !important;
}

.tw-placeholder-red-600\/10::placeholder {
  color: rgb(220 38 38 / 0.1) !important;
}

.tw-placeholder-red-600\/100::placeholder {
  color: rgb(220 38 38 / 1) !important;
}

.tw-placeholder-red-600\/15::placeholder {
  color: rgb(220 38 38 / 0.15) !important;
}

.tw-placeholder-red-600\/20::placeholder {
  color: rgb(220 38 38 / 0.2) !important;
}

.tw-placeholder-red-600\/25::placeholder {
  color: rgb(220 38 38 / 0.25) !important;
}

.tw-placeholder-red-600\/30::placeholder {
  color: rgb(220 38 38 / 0.3) !important;
}

.tw-placeholder-red-600\/35::placeholder {
  color: rgb(220 38 38 / 0.35) !important;
}

.tw-placeholder-red-600\/40::placeholder {
  color: rgb(220 38 38 / 0.4) !important;
}

.tw-placeholder-red-600\/45::placeholder {
  color: rgb(220 38 38 / 0.45) !important;
}

.tw-placeholder-red-600\/5::placeholder {
  color: rgb(220 38 38 / 0.05) !important;
}

.tw-placeholder-red-600\/50::placeholder {
  color: rgb(220 38 38 / 0.5) !important;
}

.tw-placeholder-red-600\/55::placeholder {
  color: rgb(220 38 38 / 0.55) !important;
}

.tw-placeholder-red-600\/60::placeholder {
  color: rgb(220 38 38 / 0.6) !important;
}

.tw-placeholder-red-600\/65::placeholder {
  color: rgb(220 38 38 / 0.65) !important;
}

.tw-placeholder-red-600\/70::placeholder {
  color: rgb(220 38 38 / 0.7) !important;
}

.tw-placeholder-red-600\/75::placeholder {
  color: rgb(220 38 38 / 0.75) !important;
}

.tw-placeholder-red-600\/80::placeholder {
  color: rgb(220 38 38 / 0.8) !important;
}

.tw-placeholder-red-600\/85::placeholder {
  color: rgb(220 38 38 / 0.85) !important;
}

.tw-placeholder-red-600\/90::placeholder {
  color: rgb(220 38 38 / 0.9) !important;
}

.tw-placeholder-red-600\/95::placeholder {
  color: rgb(220 38 38 / 0.95) !important;
}

.tw-placeholder-red-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(185 28 28 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-700\/0::placeholder {
  color: rgb(185 28 28 / 0) !important;
}

.tw-placeholder-red-700\/10::placeholder {
  color: rgb(185 28 28 / 0.1) !important;
}

.tw-placeholder-red-700\/100::placeholder {
  color: rgb(185 28 28 / 1) !important;
}

.tw-placeholder-red-700\/15::placeholder {
  color: rgb(185 28 28 / 0.15) !important;
}

.tw-placeholder-red-700\/20::placeholder {
  color: rgb(185 28 28 / 0.2) !important;
}

.tw-placeholder-red-700\/25::placeholder {
  color: rgb(185 28 28 / 0.25) !important;
}

.tw-placeholder-red-700\/30::placeholder {
  color: rgb(185 28 28 / 0.3) !important;
}

.tw-placeholder-red-700\/35::placeholder {
  color: rgb(185 28 28 / 0.35) !important;
}

.tw-placeholder-red-700\/40::placeholder {
  color: rgb(185 28 28 / 0.4) !important;
}

.tw-placeholder-red-700\/45::placeholder {
  color: rgb(185 28 28 / 0.45) !important;
}

.tw-placeholder-red-700\/5::placeholder {
  color: rgb(185 28 28 / 0.05) !important;
}

.tw-placeholder-red-700\/50::placeholder {
  color: rgb(185 28 28 / 0.5) !important;
}

.tw-placeholder-red-700\/55::placeholder {
  color: rgb(185 28 28 / 0.55) !important;
}

.tw-placeholder-red-700\/60::placeholder {
  color: rgb(185 28 28 / 0.6) !important;
}

.tw-placeholder-red-700\/65::placeholder {
  color: rgb(185 28 28 / 0.65) !important;
}

.tw-placeholder-red-700\/70::placeholder {
  color: rgb(185 28 28 / 0.7) !important;
}

.tw-placeholder-red-700\/75::placeholder {
  color: rgb(185 28 28 / 0.75) !important;
}

.tw-placeholder-red-700\/80::placeholder {
  color: rgb(185 28 28 / 0.8) !important;
}

.tw-placeholder-red-700\/85::placeholder {
  color: rgb(185 28 28 / 0.85) !important;
}

.tw-placeholder-red-700\/90::placeholder {
  color: rgb(185 28 28 / 0.9) !important;
}

.tw-placeholder-red-700\/95::placeholder {
  color: rgb(185 28 28 / 0.95) !important;
}

.tw-placeholder-red-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(153 27 27 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-800\/0::placeholder {
  color: rgb(153 27 27 / 0) !important;
}

.tw-placeholder-red-800\/10::placeholder {
  color: rgb(153 27 27 / 0.1) !important;
}

.tw-placeholder-red-800\/100::placeholder {
  color: rgb(153 27 27 / 1) !important;
}

.tw-placeholder-red-800\/15::placeholder {
  color: rgb(153 27 27 / 0.15) !important;
}

.tw-placeholder-red-800\/20::placeholder {
  color: rgb(153 27 27 / 0.2) !important;
}

.tw-placeholder-red-800\/25::placeholder {
  color: rgb(153 27 27 / 0.25) !important;
}

.tw-placeholder-red-800\/30::placeholder {
  color: rgb(153 27 27 / 0.3) !important;
}

.tw-placeholder-red-800\/35::placeholder {
  color: rgb(153 27 27 / 0.35) !important;
}

.tw-placeholder-red-800\/40::placeholder {
  color: rgb(153 27 27 / 0.4) !important;
}

.tw-placeholder-red-800\/45::placeholder {
  color: rgb(153 27 27 / 0.45) !important;
}

.tw-placeholder-red-800\/5::placeholder {
  color: rgb(153 27 27 / 0.05) !important;
}

.tw-placeholder-red-800\/50::placeholder {
  color: rgb(153 27 27 / 0.5) !important;
}

.tw-placeholder-red-800\/55::placeholder {
  color: rgb(153 27 27 / 0.55) !important;
}

.tw-placeholder-red-800\/60::placeholder {
  color: rgb(153 27 27 / 0.6) !important;
}

.tw-placeholder-red-800\/65::placeholder {
  color: rgb(153 27 27 / 0.65) !important;
}

.tw-placeholder-red-800\/70::placeholder {
  color: rgb(153 27 27 / 0.7) !important;
}

.tw-placeholder-red-800\/75::placeholder {
  color: rgb(153 27 27 / 0.75) !important;
}

.tw-placeholder-red-800\/80::placeholder {
  color: rgb(153 27 27 / 0.8) !important;
}

.tw-placeholder-red-800\/85::placeholder {
  color: rgb(153 27 27 / 0.85) !important;
}

.tw-placeholder-red-800\/90::placeholder {
  color: rgb(153 27 27 / 0.9) !important;
}

.tw-placeholder-red-800\/95::placeholder {
  color: rgb(153 27 27 / 0.95) !important;
}

.tw-placeholder-red-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(127 29 29 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-900\/0::placeholder {
  color: rgb(127 29 29 / 0) !important;
}

.tw-placeholder-red-900\/10::placeholder {
  color: rgb(127 29 29 / 0.1) !important;
}

.tw-placeholder-red-900\/100::placeholder {
  color: rgb(127 29 29 / 1) !important;
}

.tw-placeholder-red-900\/15::placeholder {
  color: rgb(127 29 29 / 0.15) !important;
}

.tw-placeholder-red-900\/20::placeholder {
  color: rgb(127 29 29 / 0.2) !important;
}

.tw-placeholder-red-900\/25::placeholder {
  color: rgb(127 29 29 / 0.25) !important;
}

.tw-placeholder-red-900\/30::placeholder {
  color: rgb(127 29 29 / 0.3) !important;
}

.tw-placeholder-red-900\/35::placeholder {
  color: rgb(127 29 29 / 0.35) !important;
}

.tw-placeholder-red-900\/40::placeholder {
  color: rgb(127 29 29 / 0.4) !important;
}

.tw-placeholder-red-900\/45::placeholder {
  color: rgb(127 29 29 / 0.45) !important;
}

.tw-placeholder-red-900\/5::placeholder {
  color: rgb(127 29 29 / 0.05) !important;
}

.tw-placeholder-red-900\/50::placeholder {
  color: rgb(127 29 29 / 0.5) !important;
}

.tw-placeholder-red-900\/55::placeholder {
  color: rgb(127 29 29 / 0.55) !important;
}

.tw-placeholder-red-900\/60::placeholder {
  color: rgb(127 29 29 / 0.6) !important;
}

.tw-placeholder-red-900\/65::placeholder {
  color: rgb(127 29 29 / 0.65) !important;
}

.tw-placeholder-red-900\/70::placeholder {
  color: rgb(127 29 29 / 0.7) !important;
}

.tw-placeholder-red-900\/75::placeholder {
  color: rgb(127 29 29 / 0.75) !important;
}

.tw-placeholder-red-900\/80::placeholder {
  color: rgb(127 29 29 / 0.8) !important;
}

.tw-placeholder-red-900\/85::placeholder {
  color: rgb(127 29 29 / 0.85) !important;
}

.tw-placeholder-red-900\/90::placeholder {
  color: rgb(127 29 29 / 0.9) !important;
}

.tw-placeholder-red-900\/95::placeholder {
  color: rgb(127 29 29 / 0.95) !important;
}

.tw-placeholder-red-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(69 10 10 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-red-950\/0::placeholder {
  color: rgb(69 10 10 / 0) !important;
}

.tw-placeholder-red-950\/10::placeholder {
  color: rgb(69 10 10 / 0.1) !important;
}

.tw-placeholder-red-950\/100::placeholder {
  color: rgb(69 10 10 / 1) !important;
}

.tw-placeholder-red-950\/15::placeholder {
  color: rgb(69 10 10 / 0.15) !important;
}

.tw-placeholder-red-950\/20::placeholder {
  color: rgb(69 10 10 / 0.2) !important;
}

.tw-placeholder-red-950\/25::placeholder {
  color: rgb(69 10 10 / 0.25) !important;
}

.tw-placeholder-red-950\/30::placeholder {
  color: rgb(69 10 10 / 0.3) !important;
}

.tw-placeholder-red-950\/35::placeholder {
  color: rgb(69 10 10 / 0.35) !important;
}

.tw-placeholder-red-950\/40::placeholder {
  color: rgb(69 10 10 / 0.4) !important;
}

.tw-placeholder-red-950\/45::placeholder {
  color: rgb(69 10 10 / 0.45) !important;
}

.tw-placeholder-red-950\/5::placeholder {
  color: rgb(69 10 10 / 0.05) !important;
}

.tw-placeholder-red-950\/50::placeholder {
  color: rgb(69 10 10 / 0.5) !important;
}

.tw-placeholder-red-950\/55::placeholder {
  color: rgb(69 10 10 / 0.55) !important;
}

.tw-placeholder-red-950\/60::placeholder {
  color: rgb(69 10 10 / 0.6) !important;
}

.tw-placeholder-red-950\/65::placeholder {
  color: rgb(69 10 10 / 0.65) !important;
}

.tw-placeholder-red-950\/70::placeholder {
  color: rgb(69 10 10 / 0.7) !important;
}

.tw-placeholder-red-950\/75::placeholder {
  color: rgb(69 10 10 / 0.75) !important;
}

.tw-placeholder-red-950\/80::placeholder {
  color: rgb(69 10 10 / 0.8) !important;
}

.tw-placeholder-red-950\/85::placeholder {
  color: rgb(69 10 10 / 0.85) !important;
}

.tw-placeholder-red-950\/90::placeholder {
  color: rgb(69 10 10 / 0.9) !important;
}

.tw-placeholder-red-950\/95::placeholder {
  color: rgb(69 10 10 / 0.95) !important;
}

.tw-placeholder-rose-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(255 228 230 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-100\/0::placeholder {
  color: rgb(255 228 230 / 0) !important;
}

.tw-placeholder-rose-100\/10::placeholder {
  color: rgb(255 228 230 / 0.1) !important;
}

.tw-placeholder-rose-100\/100::placeholder {
  color: rgb(255 228 230 / 1) !important;
}

.tw-placeholder-rose-100\/15::placeholder {
  color: rgb(255 228 230 / 0.15) !important;
}

.tw-placeholder-rose-100\/20::placeholder {
  color: rgb(255 228 230 / 0.2) !important;
}

.tw-placeholder-rose-100\/25::placeholder {
  color: rgb(255 228 230 / 0.25) !important;
}

.tw-placeholder-rose-100\/30::placeholder {
  color: rgb(255 228 230 / 0.3) !important;
}

.tw-placeholder-rose-100\/35::placeholder {
  color: rgb(255 228 230 / 0.35) !important;
}

.tw-placeholder-rose-100\/40::placeholder {
  color: rgb(255 228 230 / 0.4) !important;
}

.tw-placeholder-rose-100\/45::placeholder {
  color: rgb(255 228 230 / 0.45) !important;
}

.tw-placeholder-rose-100\/5::placeholder {
  color: rgb(255 228 230 / 0.05) !important;
}

.tw-placeholder-rose-100\/50::placeholder {
  color: rgb(255 228 230 / 0.5) !important;
}

.tw-placeholder-rose-100\/55::placeholder {
  color: rgb(255 228 230 / 0.55) !important;
}

.tw-placeholder-rose-100\/60::placeholder {
  color: rgb(255 228 230 / 0.6) !important;
}

.tw-placeholder-rose-100\/65::placeholder {
  color: rgb(255 228 230 / 0.65) !important;
}

.tw-placeholder-rose-100\/70::placeholder {
  color: rgb(255 228 230 / 0.7) !important;
}

.tw-placeholder-rose-100\/75::placeholder {
  color: rgb(255 228 230 / 0.75) !important;
}

.tw-placeholder-rose-100\/80::placeholder {
  color: rgb(255 228 230 / 0.8) !important;
}

.tw-placeholder-rose-100\/85::placeholder {
  color: rgb(255 228 230 / 0.85) !important;
}

.tw-placeholder-rose-100\/90::placeholder {
  color: rgb(255 228 230 / 0.9) !important;
}

.tw-placeholder-rose-100\/95::placeholder {
  color: rgb(255 228 230 / 0.95) !important;
}

.tw-placeholder-rose-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 205 211 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-200\/0::placeholder {
  color: rgb(254 205 211 / 0) !important;
}

.tw-placeholder-rose-200\/10::placeholder {
  color: rgb(254 205 211 / 0.1) !important;
}

.tw-placeholder-rose-200\/100::placeholder {
  color: rgb(254 205 211 / 1) !important;
}

.tw-placeholder-rose-200\/15::placeholder {
  color: rgb(254 205 211 / 0.15) !important;
}

.tw-placeholder-rose-200\/20::placeholder {
  color: rgb(254 205 211 / 0.2) !important;
}

.tw-placeholder-rose-200\/25::placeholder {
  color: rgb(254 205 211 / 0.25) !important;
}

.tw-placeholder-rose-200\/30::placeholder {
  color: rgb(254 205 211 / 0.3) !important;
}

.tw-placeholder-rose-200\/35::placeholder {
  color: rgb(254 205 211 / 0.35) !important;
}

.tw-placeholder-rose-200\/40::placeholder {
  color: rgb(254 205 211 / 0.4) !important;
}

.tw-placeholder-rose-200\/45::placeholder {
  color: rgb(254 205 211 / 0.45) !important;
}

.tw-placeholder-rose-200\/5::placeholder {
  color: rgb(254 205 211 / 0.05) !important;
}

.tw-placeholder-rose-200\/50::placeholder {
  color: rgb(254 205 211 / 0.5) !important;
}

.tw-placeholder-rose-200\/55::placeholder {
  color: rgb(254 205 211 / 0.55) !important;
}

.tw-placeholder-rose-200\/60::placeholder {
  color: rgb(254 205 211 / 0.6) !important;
}

.tw-placeholder-rose-200\/65::placeholder {
  color: rgb(254 205 211 / 0.65) !important;
}

.tw-placeholder-rose-200\/70::placeholder {
  color: rgb(254 205 211 / 0.7) !important;
}

.tw-placeholder-rose-200\/75::placeholder {
  color: rgb(254 205 211 / 0.75) !important;
}

.tw-placeholder-rose-200\/80::placeholder {
  color: rgb(254 205 211 / 0.8) !important;
}

.tw-placeholder-rose-200\/85::placeholder {
  color: rgb(254 205 211 / 0.85) !important;
}

.tw-placeholder-rose-200\/90::placeholder {
  color: rgb(254 205 211 / 0.9) !important;
}

.tw-placeholder-rose-200\/95::placeholder {
  color: rgb(254 205 211 / 0.95) !important;
}

.tw-placeholder-rose-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(253 164 175 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-300\/0::placeholder {
  color: rgb(253 164 175 / 0) !important;
}

.tw-placeholder-rose-300\/10::placeholder {
  color: rgb(253 164 175 / 0.1) !important;
}

.tw-placeholder-rose-300\/100::placeholder {
  color: rgb(253 164 175 / 1) !important;
}

.tw-placeholder-rose-300\/15::placeholder {
  color: rgb(253 164 175 / 0.15) !important;
}

.tw-placeholder-rose-300\/20::placeholder {
  color: rgb(253 164 175 / 0.2) !important;
}

.tw-placeholder-rose-300\/25::placeholder {
  color: rgb(253 164 175 / 0.25) !important;
}

.tw-placeholder-rose-300\/30::placeholder {
  color: rgb(253 164 175 / 0.3) !important;
}

.tw-placeholder-rose-300\/35::placeholder {
  color: rgb(253 164 175 / 0.35) !important;
}

.tw-placeholder-rose-300\/40::placeholder {
  color: rgb(253 164 175 / 0.4) !important;
}

.tw-placeholder-rose-300\/45::placeholder {
  color: rgb(253 164 175 / 0.45) !important;
}

.tw-placeholder-rose-300\/5::placeholder {
  color: rgb(253 164 175 / 0.05) !important;
}

.tw-placeholder-rose-300\/50::placeholder {
  color: rgb(253 164 175 / 0.5) !important;
}

.tw-placeholder-rose-300\/55::placeholder {
  color: rgb(253 164 175 / 0.55) !important;
}

.tw-placeholder-rose-300\/60::placeholder {
  color: rgb(253 164 175 / 0.6) !important;
}

.tw-placeholder-rose-300\/65::placeholder {
  color: rgb(253 164 175 / 0.65) !important;
}

.tw-placeholder-rose-300\/70::placeholder {
  color: rgb(253 164 175 / 0.7) !important;
}

.tw-placeholder-rose-300\/75::placeholder {
  color: rgb(253 164 175 / 0.75) !important;
}

.tw-placeholder-rose-300\/80::placeholder {
  color: rgb(253 164 175 / 0.8) !important;
}

.tw-placeholder-rose-300\/85::placeholder {
  color: rgb(253 164 175 / 0.85) !important;
}

.tw-placeholder-rose-300\/90::placeholder {
  color: rgb(253 164 175 / 0.9) !important;
}

.tw-placeholder-rose-300\/95::placeholder {
  color: rgb(253 164 175 / 0.95) !important;
}

.tw-placeholder-rose-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(251 113 133 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-400\/0::placeholder {
  color: rgb(251 113 133 / 0) !important;
}

.tw-placeholder-rose-400\/10::placeholder {
  color: rgb(251 113 133 / 0.1) !important;
}

.tw-placeholder-rose-400\/100::placeholder {
  color: rgb(251 113 133 / 1) !important;
}

.tw-placeholder-rose-400\/15::placeholder {
  color: rgb(251 113 133 / 0.15) !important;
}

.tw-placeholder-rose-400\/20::placeholder {
  color: rgb(251 113 133 / 0.2) !important;
}

.tw-placeholder-rose-400\/25::placeholder {
  color: rgb(251 113 133 / 0.25) !important;
}

.tw-placeholder-rose-400\/30::placeholder {
  color: rgb(251 113 133 / 0.3) !important;
}

.tw-placeholder-rose-400\/35::placeholder {
  color: rgb(251 113 133 / 0.35) !important;
}

.tw-placeholder-rose-400\/40::placeholder {
  color: rgb(251 113 133 / 0.4) !important;
}

.tw-placeholder-rose-400\/45::placeholder {
  color: rgb(251 113 133 / 0.45) !important;
}

.tw-placeholder-rose-400\/5::placeholder {
  color: rgb(251 113 133 / 0.05) !important;
}

.tw-placeholder-rose-400\/50::placeholder {
  color: rgb(251 113 133 / 0.5) !important;
}

.tw-placeholder-rose-400\/55::placeholder {
  color: rgb(251 113 133 / 0.55) !important;
}

.tw-placeholder-rose-400\/60::placeholder {
  color: rgb(251 113 133 / 0.6) !important;
}

.tw-placeholder-rose-400\/65::placeholder {
  color: rgb(251 113 133 / 0.65) !important;
}

.tw-placeholder-rose-400\/70::placeholder {
  color: rgb(251 113 133 / 0.7) !important;
}

.tw-placeholder-rose-400\/75::placeholder {
  color: rgb(251 113 133 / 0.75) !important;
}

.tw-placeholder-rose-400\/80::placeholder {
  color: rgb(251 113 133 / 0.8) !important;
}

.tw-placeholder-rose-400\/85::placeholder {
  color: rgb(251 113 133 / 0.85) !important;
}

.tw-placeholder-rose-400\/90::placeholder {
  color: rgb(251 113 133 / 0.9) !important;
}

.tw-placeholder-rose-400\/95::placeholder {
  color: rgb(251 113 133 / 0.95) !important;
}

.tw-placeholder-rose-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(255 241 242 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-50\/0::placeholder {
  color: rgb(255 241 242 / 0) !important;
}

.tw-placeholder-rose-50\/10::placeholder {
  color: rgb(255 241 242 / 0.1) !important;
}

.tw-placeholder-rose-50\/100::placeholder {
  color: rgb(255 241 242 / 1) !important;
}

.tw-placeholder-rose-50\/15::placeholder {
  color: rgb(255 241 242 / 0.15) !important;
}

.tw-placeholder-rose-50\/20::placeholder {
  color: rgb(255 241 242 / 0.2) !important;
}

.tw-placeholder-rose-50\/25::placeholder {
  color: rgb(255 241 242 / 0.25) !important;
}

.tw-placeholder-rose-50\/30::placeholder {
  color: rgb(255 241 242 / 0.3) !important;
}

.tw-placeholder-rose-50\/35::placeholder {
  color: rgb(255 241 242 / 0.35) !important;
}

.tw-placeholder-rose-50\/40::placeholder {
  color: rgb(255 241 242 / 0.4) !important;
}

.tw-placeholder-rose-50\/45::placeholder {
  color: rgb(255 241 242 / 0.45) !important;
}

.tw-placeholder-rose-50\/5::placeholder {
  color: rgb(255 241 242 / 0.05) !important;
}

.tw-placeholder-rose-50\/50::placeholder {
  color: rgb(255 241 242 / 0.5) !important;
}

.tw-placeholder-rose-50\/55::placeholder {
  color: rgb(255 241 242 / 0.55) !important;
}

.tw-placeholder-rose-50\/60::placeholder {
  color: rgb(255 241 242 / 0.6) !important;
}

.tw-placeholder-rose-50\/65::placeholder {
  color: rgb(255 241 242 / 0.65) !important;
}

.tw-placeholder-rose-50\/70::placeholder {
  color: rgb(255 241 242 / 0.7) !important;
}

.tw-placeholder-rose-50\/75::placeholder {
  color: rgb(255 241 242 / 0.75) !important;
}

.tw-placeholder-rose-50\/80::placeholder {
  color: rgb(255 241 242 / 0.8) !important;
}

.tw-placeholder-rose-50\/85::placeholder {
  color: rgb(255 241 242 / 0.85) !important;
}

.tw-placeholder-rose-50\/90::placeholder {
  color: rgb(255 241 242 / 0.9) !important;
}

.tw-placeholder-rose-50\/95::placeholder {
  color: rgb(255 241 242 / 0.95) !important;
}

.tw-placeholder-rose-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(244 63 94 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-500\/0::placeholder {
  color: rgb(244 63 94 / 0) !important;
}

.tw-placeholder-rose-500\/10::placeholder {
  color: rgb(244 63 94 / 0.1) !important;
}

.tw-placeholder-rose-500\/100::placeholder {
  color: rgb(244 63 94 / 1) !important;
}

.tw-placeholder-rose-500\/15::placeholder {
  color: rgb(244 63 94 / 0.15) !important;
}

.tw-placeholder-rose-500\/20::placeholder {
  color: rgb(244 63 94 / 0.2) !important;
}

.tw-placeholder-rose-500\/25::placeholder {
  color: rgb(244 63 94 / 0.25) !important;
}

.tw-placeholder-rose-500\/30::placeholder {
  color: rgb(244 63 94 / 0.3) !important;
}

.tw-placeholder-rose-500\/35::placeholder {
  color: rgb(244 63 94 / 0.35) !important;
}

.tw-placeholder-rose-500\/40::placeholder {
  color: rgb(244 63 94 / 0.4) !important;
}

.tw-placeholder-rose-500\/45::placeholder {
  color: rgb(244 63 94 / 0.45) !important;
}

.tw-placeholder-rose-500\/5::placeholder {
  color: rgb(244 63 94 / 0.05) !important;
}

.tw-placeholder-rose-500\/50::placeholder {
  color: rgb(244 63 94 / 0.5) !important;
}

.tw-placeholder-rose-500\/55::placeholder {
  color: rgb(244 63 94 / 0.55) !important;
}

.tw-placeholder-rose-500\/60::placeholder {
  color: rgb(244 63 94 / 0.6) !important;
}

.tw-placeholder-rose-500\/65::placeholder {
  color: rgb(244 63 94 / 0.65) !important;
}

.tw-placeholder-rose-500\/70::placeholder {
  color: rgb(244 63 94 / 0.7) !important;
}

.tw-placeholder-rose-500\/75::placeholder {
  color: rgb(244 63 94 / 0.75) !important;
}

.tw-placeholder-rose-500\/80::placeholder {
  color: rgb(244 63 94 / 0.8) !important;
}

.tw-placeholder-rose-500\/85::placeholder {
  color: rgb(244 63 94 / 0.85) !important;
}

.tw-placeholder-rose-500\/90::placeholder {
  color: rgb(244 63 94 / 0.9) !important;
}

.tw-placeholder-rose-500\/95::placeholder {
  color: rgb(244 63 94 / 0.95) !important;
}

.tw-placeholder-rose-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(225 29 72 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-600\/0::placeholder {
  color: rgb(225 29 72 / 0) !important;
}

.tw-placeholder-rose-600\/10::placeholder {
  color: rgb(225 29 72 / 0.1) !important;
}

.tw-placeholder-rose-600\/100::placeholder {
  color: rgb(225 29 72 / 1) !important;
}

.tw-placeholder-rose-600\/15::placeholder {
  color: rgb(225 29 72 / 0.15) !important;
}

.tw-placeholder-rose-600\/20::placeholder {
  color: rgb(225 29 72 / 0.2) !important;
}

.tw-placeholder-rose-600\/25::placeholder {
  color: rgb(225 29 72 / 0.25) !important;
}

.tw-placeholder-rose-600\/30::placeholder {
  color: rgb(225 29 72 / 0.3) !important;
}

.tw-placeholder-rose-600\/35::placeholder {
  color: rgb(225 29 72 / 0.35) !important;
}

.tw-placeholder-rose-600\/40::placeholder {
  color: rgb(225 29 72 / 0.4) !important;
}

.tw-placeholder-rose-600\/45::placeholder {
  color: rgb(225 29 72 / 0.45) !important;
}

.tw-placeholder-rose-600\/5::placeholder {
  color: rgb(225 29 72 / 0.05) !important;
}

.tw-placeholder-rose-600\/50::placeholder {
  color: rgb(225 29 72 / 0.5) !important;
}

.tw-placeholder-rose-600\/55::placeholder {
  color: rgb(225 29 72 / 0.55) !important;
}

.tw-placeholder-rose-600\/60::placeholder {
  color: rgb(225 29 72 / 0.6) !important;
}

.tw-placeholder-rose-600\/65::placeholder {
  color: rgb(225 29 72 / 0.65) !important;
}

.tw-placeholder-rose-600\/70::placeholder {
  color: rgb(225 29 72 / 0.7) !important;
}

.tw-placeholder-rose-600\/75::placeholder {
  color: rgb(225 29 72 / 0.75) !important;
}

.tw-placeholder-rose-600\/80::placeholder {
  color: rgb(225 29 72 / 0.8) !important;
}

.tw-placeholder-rose-600\/85::placeholder {
  color: rgb(225 29 72 / 0.85) !important;
}

.tw-placeholder-rose-600\/90::placeholder {
  color: rgb(225 29 72 / 0.9) !important;
}

.tw-placeholder-rose-600\/95::placeholder {
  color: rgb(225 29 72 / 0.95) !important;
}

.tw-placeholder-rose-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(190 18 60 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-700\/0::placeholder {
  color: rgb(190 18 60 / 0) !important;
}

.tw-placeholder-rose-700\/10::placeholder {
  color: rgb(190 18 60 / 0.1) !important;
}

.tw-placeholder-rose-700\/100::placeholder {
  color: rgb(190 18 60 / 1) !important;
}

.tw-placeholder-rose-700\/15::placeholder {
  color: rgb(190 18 60 / 0.15) !important;
}

.tw-placeholder-rose-700\/20::placeholder {
  color: rgb(190 18 60 / 0.2) !important;
}

.tw-placeholder-rose-700\/25::placeholder {
  color: rgb(190 18 60 / 0.25) !important;
}

.tw-placeholder-rose-700\/30::placeholder {
  color: rgb(190 18 60 / 0.3) !important;
}

.tw-placeholder-rose-700\/35::placeholder {
  color: rgb(190 18 60 / 0.35) !important;
}

.tw-placeholder-rose-700\/40::placeholder {
  color: rgb(190 18 60 / 0.4) !important;
}

.tw-placeholder-rose-700\/45::placeholder {
  color: rgb(190 18 60 / 0.45) !important;
}

.tw-placeholder-rose-700\/5::placeholder {
  color: rgb(190 18 60 / 0.05) !important;
}

.tw-placeholder-rose-700\/50::placeholder {
  color: rgb(190 18 60 / 0.5) !important;
}

.tw-placeholder-rose-700\/55::placeholder {
  color: rgb(190 18 60 / 0.55) !important;
}

.tw-placeholder-rose-700\/60::placeholder {
  color: rgb(190 18 60 / 0.6) !important;
}

.tw-placeholder-rose-700\/65::placeholder {
  color: rgb(190 18 60 / 0.65) !important;
}

.tw-placeholder-rose-700\/70::placeholder {
  color: rgb(190 18 60 / 0.7) !important;
}

.tw-placeholder-rose-700\/75::placeholder {
  color: rgb(190 18 60 / 0.75) !important;
}

.tw-placeholder-rose-700\/80::placeholder {
  color: rgb(190 18 60 / 0.8) !important;
}

.tw-placeholder-rose-700\/85::placeholder {
  color: rgb(190 18 60 / 0.85) !important;
}

.tw-placeholder-rose-700\/90::placeholder {
  color: rgb(190 18 60 / 0.9) !important;
}

.tw-placeholder-rose-700\/95::placeholder {
  color: rgb(190 18 60 / 0.95) !important;
}

.tw-placeholder-rose-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(159 18 57 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-800\/0::placeholder {
  color: rgb(159 18 57 / 0) !important;
}

.tw-placeholder-rose-800\/10::placeholder {
  color: rgb(159 18 57 / 0.1) !important;
}

.tw-placeholder-rose-800\/100::placeholder {
  color: rgb(159 18 57 / 1) !important;
}

.tw-placeholder-rose-800\/15::placeholder {
  color: rgb(159 18 57 / 0.15) !important;
}

.tw-placeholder-rose-800\/20::placeholder {
  color: rgb(159 18 57 / 0.2) !important;
}

.tw-placeholder-rose-800\/25::placeholder {
  color: rgb(159 18 57 / 0.25) !important;
}

.tw-placeholder-rose-800\/30::placeholder {
  color: rgb(159 18 57 / 0.3) !important;
}

.tw-placeholder-rose-800\/35::placeholder {
  color: rgb(159 18 57 / 0.35) !important;
}

.tw-placeholder-rose-800\/40::placeholder {
  color: rgb(159 18 57 / 0.4) !important;
}

.tw-placeholder-rose-800\/45::placeholder {
  color: rgb(159 18 57 / 0.45) !important;
}

.tw-placeholder-rose-800\/5::placeholder {
  color: rgb(159 18 57 / 0.05) !important;
}

.tw-placeholder-rose-800\/50::placeholder {
  color: rgb(159 18 57 / 0.5) !important;
}

.tw-placeholder-rose-800\/55::placeholder {
  color: rgb(159 18 57 / 0.55) !important;
}

.tw-placeholder-rose-800\/60::placeholder {
  color: rgb(159 18 57 / 0.6) !important;
}

.tw-placeholder-rose-800\/65::placeholder {
  color: rgb(159 18 57 / 0.65) !important;
}

.tw-placeholder-rose-800\/70::placeholder {
  color: rgb(159 18 57 / 0.7) !important;
}

.tw-placeholder-rose-800\/75::placeholder {
  color: rgb(159 18 57 / 0.75) !important;
}

.tw-placeholder-rose-800\/80::placeholder {
  color: rgb(159 18 57 / 0.8) !important;
}

.tw-placeholder-rose-800\/85::placeholder {
  color: rgb(159 18 57 / 0.85) !important;
}

.tw-placeholder-rose-800\/90::placeholder {
  color: rgb(159 18 57 / 0.9) !important;
}

.tw-placeholder-rose-800\/95::placeholder {
  color: rgb(159 18 57 / 0.95) !important;
}

.tw-placeholder-rose-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(136 19 55 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-900\/0::placeholder {
  color: rgb(136 19 55 / 0) !important;
}

.tw-placeholder-rose-900\/10::placeholder {
  color: rgb(136 19 55 / 0.1) !important;
}

.tw-placeholder-rose-900\/100::placeholder {
  color: rgb(136 19 55 / 1) !important;
}

.tw-placeholder-rose-900\/15::placeholder {
  color: rgb(136 19 55 / 0.15) !important;
}

.tw-placeholder-rose-900\/20::placeholder {
  color: rgb(136 19 55 / 0.2) !important;
}

.tw-placeholder-rose-900\/25::placeholder {
  color: rgb(136 19 55 / 0.25) !important;
}

.tw-placeholder-rose-900\/30::placeholder {
  color: rgb(136 19 55 / 0.3) !important;
}

.tw-placeholder-rose-900\/35::placeholder {
  color: rgb(136 19 55 / 0.35) !important;
}

.tw-placeholder-rose-900\/40::placeholder {
  color: rgb(136 19 55 / 0.4) !important;
}

.tw-placeholder-rose-900\/45::placeholder {
  color: rgb(136 19 55 / 0.45) !important;
}

.tw-placeholder-rose-900\/5::placeholder {
  color: rgb(136 19 55 / 0.05) !important;
}

.tw-placeholder-rose-900\/50::placeholder {
  color: rgb(136 19 55 / 0.5) !important;
}

.tw-placeholder-rose-900\/55::placeholder {
  color: rgb(136 19 55 / 0.55) !important;
}

.tw-placeholder-rose-900\/60::placeholder {
  color: rgb(136 19 55 / 0.6) !important;
}

.tw-placeholder-rose-900\/65::placeholder {
  color: rgb(136 19 55 / 0.65) !important;
}

.tw-placeholder-rose-900\/70::placeholder {
  color: rgb(136 19 55 / 0.7) !important;
}

.tw-placeholder-rose-900\/75::placeholder {
  color: rgb(136 19 55 / 0.75) !important;
}

.tw-placeholder-rose-900\/80::placeholder {
  color: rgb(136 19 55 / 0.8) !important;
}

.tw-placeholder-rose-900\/85::placeholder {
  color: rgb(136 19 55 / 0.85) !important;
}

.tw-placeholder-rose-900\/90::placeholder {
  color: rgb(136 19 55 / 0.9) !important;
}

.tw-placeholder-rose-900\/95::placeholder {
  color: rgb(136 19 55 / 0.95) !important;
}

.tw-placeholder-rose-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(76 5 25 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-rose-950\/0::placeholder {
  color: rgb(76 5 25 / 0) !important;
}

.tw-placeholder-rose-950\/10::placeholder {
  color: rgb(76 5 25 / 0.1) !important;
}

.tw-placeholder-rose-950\/100::placeholder {
  color: rgb(76 5 25 / 1) !important;
}

.tw-placeholder-rose-950\/15::placeholder {
  color: rgb(76 5 25 / 0.15) !important;
}

.tw-placeholder-rose-950\/20::placeholder {
  color: rgb(76 5 25 / 0.2) !important;
}

.tw-placeholder-rose-950\/25::placeholder {
  color: rgb(76 5 25 / 0.25) !important;
}

.tw-placeholder-rose-950\/30::placeholder {
  color: rgb(76 5 25 / 0.3) !important;
}

.tw-placeholder-rose-950\/35::placeholder {
  color: rgb(76 5 25 / 0.35) !important;
}

.tw-placeholder-rose-950\/40::placeholder {
  color: rgb(76 5 25 / 0.4) !important;
}

.tw-placeholder-rose-950\/45::placeholder {
  color: rgb(76 5 25 / 0.45) !important;
}

.tw-placeholder-rose-950\/5::placeholder {
  color: rgb(76 5 25 / 0.05) !important;
}

.tw-placeholder-rose-950\/50::placeholder {
  color: rgb(76 5 25 / 0.5) !important;
}

.tw-placeholder-rose-950\/55::placeholder {
  color: rgb(76 5 25 / 0.55) !important;
}

.tw-placeholder-rose-950\/60::placeholder {
  color: rgb(76 5 25 / 0.6) !important;
}

.tw-placeholder-rose-950\/65::placeholder {
  color: rgb(76 5 25 / 0.65) !important;
}

.tw-placeholder-rose-950\/70::placeholder {
  color: rgb(76 5 25 / 0.7) !important;
}

.tw-placeholder-rose-950\/75::placeholder {
  color: rgb(76 5 25 / 0.75) !important;
}

.tw-placeholder-rose-950\/80::placeholder {
  color: rgb(76 5 25 / 0.8) !important;
}

.tw-placeholder-rose-950\/85::placeholder {
  color: rgb(76 5 25 / 0.85) !important;
}

.tw-placeholder-rose-950\/90::placeholder {
  color: rgb(76 5 25 / 0.9) !important;
}

.tw-placeholder-rose-950\/95::placeholder {
  color: rgb(76 5 25 / 0.95) !important;
}

.tw-placeholder-sky-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(224 242 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-100\/0::placeholder {
  color: rgb(224 242 254 / 0) !important;
}

.tw-placeholder-sky-100\/10::placeholder {
  color: rgb(224 242 254 / 0.1) !important;
}

.tw-placeholder-sky-100\/100::placeholder {
  color: rgb(224 242 254 / 1) !important;
}

.tw-placeholder-sky-100\/15::placeholder {
  color: rgb(224 242 254 / 0.15) !important;
}

.tw-placeholder-sky-100\/20::placeholder {
  color: rgb(224 242 254 / 0.2) !important;
}

.tw-placeholder-sky-100\/25::placeholder {
  color: rgb(224 242 254 / 0.25) !important;
}

.tw-placeholder-sky-100\/30::placeholder {
  color: rgb(224 242 254 / 0.3) !important;
}

.tw-placeholder-sky-100\/35::placeholder {
  color: rgb(224 242 254 / 0.35) !important;
}

.tw-placeholder-sky-100\/40::placeholder {
  color: rgb(224 242 254 / 0.4) !important;
}

.tw-placeholder-sky-100\/45::placeholder {
  color: rgb(224 242 254 / 0.45) !important;
}

.tw-placeholder-sky-100\/5::placeholder {
  color: rgb(224 242 254 / 0.05) !important;
}

.tw-placeholder-sky-100\/50::placeholder {
  color: rgb(224 242 254 / 0.5) !important;
}

.tw-placeholder-sky-100\/55::placeholder {
  color: rgb(224 242 254 / 0.55) !important;
}

.tw-placeholder-sky-100\/60::placeholder {
  color: rgb(224 242 254 / 0.6) !important;
}

.tw-placeholder-sky-100\/65::placeholder {
  color: rgb(224 242 254 / 0.65) !important;
}

.tw-placeholder-sky-100\/70::placeholder {
  color: rgb(224 242 254 / 0.7) !important;
}

.tw-placeholder-sky-100\/75::placeholder {
  color: rgb(224 242 254 / 0.75) !important;
}

.tw-placeholder-sky-100\/80::placeholder {
  color: rgb(224 242 254 / 0.8) !important;
}

.tw-placeholder-sky-100\/85::placeholder {
  color: rgb(224 242 254 / 0.85) !important;
}

.tw-placeholder-sky-100\/90::placeholder {
  color: rgb(224 242 254 / 0.9) !important;
}

.tw-placeholder-sky-100\/95::placeholder {
  color: rgb(224 242 254 / 0.95) !important;
}

.tw-placeholder-sky-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(186 230 253 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-200\/0::placeholder {
  color: rgb(186 230 253 / 0) !important;
}

.tw-placeholder-sky-200\/10::placeholder {
  color: rgb(186 230 253 / 0.1) !important;
}

.tw-placeholder-sky-200\/100::placeholder {
  color: rgb(186 230 253 / 1) !important;
}

.tw-placeholder-sky-200\/15::placeholder {
  color: rgb(186 230 253 / 0.15) !important;
}

.tw-placeholder-sky-200\/20::placeholder {
  color: rgb(186 230 253 / 0.2) !important;
}

.tw-placeholder-sky-200\/25::placeholder {
  color: rgb(186 230 253 / 0.25) !important;
}

.tw-placeholder-sky-200\/30::placeholder {
  color: rgb(186 230 253 / 0.3) !important;
}

.tw-placeholder-sky-200\/35::placeholder {
  color: rgb(186 230 253 / 0.35) !important;
}

.tw-placeholder-sky-200\/40::placeholder {
  color: rgb(186 230 253 / 0.4) !important;
}

.tw-placeholder-sky-200\/45::placeholder {
  color: rgb(186 230 253 / 0.45) !important;
}

.tw-placeholder-sky-200\/5::placeholder {
  color: rgb(186 230 253 / 0.05) !important;
}

.tw-placeholder-sky-200\/50::placeholder {
  color: rgb(186 230 253 / 0.5) !important;
}

.tw-placeholder-sky-200\/55::placeholder {
  color: rgb(186 230 253 / 0.55) !important;
}

.tw-placeholder-sky-200\/60::placeholder {
  color: rgb(186 230 253 / 0.6) !important;
}

.tw-placeholder-sky-200\/65::placeholder {
  color: rgb(186 230 253 / 0.65) !important;
}

.tw-placeholder-sky-200\/70::placeholder {
  color: rgb(186 230 253 / 0.7) !important;
}

.tw-placeholder-sky-200\/75::placeholder {
  color: rgb(186 230 253 / 0.75) !important;
}

.tw-placeholder-sky-200\/80::placeholder {
  color: rgb(186 230 253 / 0.8) !important;
}

.tw-placeholder-sky-200\/85::placeholder {
  color: rgb(186 230 253 / 0.85) !important;
}

.tw-placeholder-sky-200\/90::placeholder {
  color: rgb(186 230 253 / 0.9) !important;
}

.tw-placeholder-sky-200\/95::placeholder {
  color: rgb(186 230 253 / 0.95) !important;
}

.tw-placeholder-sky-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(125 211 252 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-300\/0::placeholder {
  color: rgb(125 211 252 / 0) !important;
}

.tw-placeholder-sky-300\/10::placeholder {
  color: rgb(125 211 252 / 0.1) !important;
}

.tw-placeholder-sky-300\/100::placeholder {
  color: rgb(125 211 252 / 1) !important;
}

.tw-placeholder-sky-300\/15::placeholder {
  color: rgb(125 211 252 / 0.15) !important;
}

.tw-placeholder-sky-300\/20::placeholder {
  color: rgb(125 211 252 / 0.2) !important;
}

.tw-placeholder-sky-300\/25::placeholder {
  color: rgb(125 211 252 / 0.25) !important;
}

.tw-placeholder-sky-300\/30::placeholder {
  color: rgb(125 211 252 / 0.3) !important;
}

.tw-placeholder-sky-300\/35::placeholder {
  color: rgb(125 211 252 / 0.35) !important;
}

.tw-placeholder-sky-300\/40::placeholder {
  color: rgb(125 211 252 / 0.4) !important;
}

.tw-placeholder-sky-300\/45::placeholder {
  color: rgb(125 211 252 / 0.45) !important;
}

.tw-placeholder-sky-300\/5::placeholder {
  color: rgb(125 211 252 / 0.05) !important;
}

.tw-placeholder-sky-300\/50::placeholder {
  color: rgb(125 211 252 / 0.5) !important;
}

.tw-placeholder-sky-300\/55::placeholder {
  color: rgb(125 211 252 / 0.55) !important;
}

.tw-placeholder-sky-300\/60::placeholder {
  color: rgb(125 211 252 / 0.6) !important;
}

.tw-placeholder-sky-300\/65::placeholder {
  color: rgb(125 211 252 / 0.65) !important;
}

.tw-placeholder-sky-300\/70::placeholder {
  color: rgb(125 211 252 / 0.7) !important;
}

.tw-placeholder-sky-300\/75::placeholder {
  color: rgb(125 211 252 / 0.75) !important;
}

.tw-placeholder-sky-300\/80::placeholder {
  color: rgb(125 211 252 / 0.8) !important;
}

.tw-placeholder-sky-300\/85::placeholder {
  color: rgb(125 211 252 / 0.85) !important;
}

.tw-placeholder-sky-300\/90::placeholder {
  color: rgb(125 211 252 / 0.9) !important;
}

.tw-placeholder-sky-300\/95::placeholder {
  color: rgb(125 211 252 / 0.95) !important;
}

.tw-placeholder-sky-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(56 189 248 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-400\/0::placeholder {
  color: rgb(56 189 248 / 0) !important;
}

.tw-placeholder-sky-400\/10::placeholder {
  color: rgb(56 189 248 / 0.1) !important;
}

.tw-placeholder-sky-400\/100::placeholder {
  color: rgb(56 189 248 / 1) !important;
}

.tw-placeholder-sky-400\/15::placeholder {
  color: rgb(56 189 248 / 0.15) !important;
}

.tw-placeholder-sky-400\/20::placeholder {
  color: rgb(56 189 248 / 0.2) !important;
}

.tw-placeholder-sky-400\/25::placeholder {
  color: rgb(56 189 248 / 0.25) !important;
}

.tw-placeholder-sky-400\/30::placeholder {
  color: rgb(56 189 248 / 0.3) !important;
}

.tw-placeholder-sky-400\/35::placeholder {
  color: rgb(56 189 248 / 0.35) !important;
}

.tw-placeholder-sky-400\/40::placeholder {
  color: rgb(56 189 248 / 0.4) !important;
}

.tw-placeholder-sky-400\/45::placeholder {
  color: rgb(56 189 248 / 0.45) !important;
}

.tw-placeholder-sky-400\/5::placeholder {
  color: rgb(56 189 248 / 0.05) !important;
}

.tw-placeholder-sky-400\/50::placeholder {
  color: rgb(56 189 248 / 0.5) !important;
}

.tw-placeholder-sky-400\/55::placeholder {
  color: rgb(56 189 248 / 0.55) !important;
}

.tw-placeholder-sky-400\/60::placeholder {
  color: rgb(56 189 248 / 0.6) !important;
}

.tw-placeholder-sky-400\/65::placeholder {
  color: rgb(56 189 248 / 0.65) !important;
}

.tw-placeholder-sky-400\/70::placeholder {
  color: rgb(56 189 248 / 0.7) !important;
}

.tw-placeholder-sky-400\/75::placeholder {
  color: rgb(56 189 248 / 0.75) !important;
}

.tw-placeholder-sky-400\/80::placeholder {
  color: rgb(56 189 248 / 0.8) !important;
}

.tw-placeholder-sky-400\/85::placeholder {
  color: rgb(56 189 248 / 0.85) !important;
}

.tw-placeholder-sky-400\/90::placeholder {
  color: rgb(56 189 248 / 0.9) !important;
}

.tw-placeholder-sky-400\/95::placeholder {
  color: rgb(56 189 248 / 0.95) !important;
}

.tw-placeholder-sky-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(240 249 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-50\/0::placeholder {
  color: rgb(240 249 255 / 0) !important;
}

.tw-placeholder-sky-50\/10::placeholder {
  color: rgb(240 249 255 / 0.1) !important;
}

.tw-placeholder-sky-50\/100::placeholder {
  color: rgb(240 249 255 / 1) !important;
}

.tw-placeholder-sky-50\/15::placeholder {
  color: rgb(240 249 255 / 0.15) !important;
}

.tw-placeholder-sky-50\/20::placeholder {
  color: rgb(240 249 255 / 0.2) !important;
}

.tw-placeholder-sky-50\/25::placeholder {
  color: rgb(240 249 255 / 0.25) !important;
}

.tw-placeholder-sky-50\/30::placeholder {
  color: rgb(240 249 255 / 0.3) !important;
}

.tw-placeholder-sky-50\/35::placeholder {
  color: rgb(240 249 255 / 0.35) !important;
}

.tw-placeholder-sky-50\/40::placeholder {
  color: rgb(240 249 255 / 0.4) !important;
}

.tw-placeholder-sky-50\/45::placeholder {
  color: rgb(240 249 255 / 0.45) !important;
}

.tw-placeholder-sky-50\/5::placeholder {
  color: rgb(240 249 255 / 0.05) !important;
}

.tw-placeholder-sky-50\/50::placeholder {
  color: rgb(240 249 255 / 0.5) !important;
}

.tw-placeholder-sky-50\/55::placeholder {
  color: rgb(240 249 255 / 0.55) !important;
}

.tw-placeholder-sky-50\/60::placeholder {
  color: rgb(240 249 255 / 0.6) !important;
}

.tw-placeholder-sky-50\/65::placeholder {
  color: rgb(240 249 255 / 0.65) !important;
}

.tw-placeholder-sky-50\/70::placeholder {
  color: rgb(240 249 255 / 0.7) !important;
}

.tw-placeholder-sky-50\/75::placeholder {
  color: rgb(240 249 255 / 0.75) !important;
}

.tw-placeholder-sky-50\/80::placeholder {
  color: rgb(240 249 255 / 0.8) !important;
}

.tw-placeholder-sky-50\/85::placeholder {
  color: rgb(240 249 255 / 0.85) !important;
}

.tw-placeholder-sky-50\/90::placeholder {
  color: rgb(240 249 255 / 0.9) !important;
}

.tw-placeholder-sky-50\/95::placeholder {
  color: rgb(240 249 255 / 0.95) !important;
}

.tw-placeholder-sky-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(14 165 233 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-500\/0::placeholder {
  color: rgb(14 165 233 / 0) !important;
}

.tw-placeholder-sky-500\/10::placeholder {
  color: rgb(14 165 233 / 0.1) !important;
}

.tw-placeholder-sky-500\/100::placeholder {
  color: rgb(14 165 233 / 1) !important;
}

.tw-placeholder-sky-500\/15::placeholder {
  color: rgb(14 165 233 / 0.15) !important;
}

.tw-placeholder-sky-500\/20::placeholder {
  color: rgb(14 165 233 / 0.2) !important;
}

.tw-placeholder-sky-500\/25::placeholder {
  color: rgb(14 165 233 / 0.25) !important;
}

.tw-placeholder-sky-500\/30::placeholder {
  color: rgb(14 165 233 / 0.3) !important;
}

.tw-placeholder-sky-500\/35::placeholder {
  color: rgb(14 165 233 / 0.35) !important;
}

.tw-placeholder-sky-500\/40::placeholder {
  color: rgb(14 165 233 / 0.4) !important;
}

.tw-placeholder-sky-500\/45::placeholder {
  color: rgb(14 165 233 / 0.45) !important;
}

.tw-placeholder-sky-500\/5::placeholder {
  color: rgb(14 165 233 / 0.05) !important;
}

.tw-placeholder-sky-500\/50::placeholder {
  color: rgb(14 165 233 / 0.5) !important;
}

.tw-placeholder-sky-500\/55::placeholder {
  color: rgb(14 165 233 / 0.55) !important;
}

.tw-placeholder-sky-500\/60::placeholder {
  color: rgb(14 165 233 / 0.6) !important;
}

.tw-placeholder-sky-500\/65::placeholder {
  color: rgb(14 165 233 / 0.65) !important;
}

.tw-placeholder-sky-500\/70::placeholder {
  color: rgb(14 165 233 / 0.7) !important;
}

.tw-placeholder-sky-500\/75::placeholder {
  color: rgb(14 165 233 / 0.75) !important;
}

.tw-placeholder-sky-500\/80::placeholder {
  color: rgb(14 165 233 / 0.8) !important;
}

.tw-placeholder-sky-500\/85::placeholder {
  color: rgb(14 165 233 / 0.85) !important;
}

.tw-placeholder-sky-500\/90::placeholder {
  color: rgb(14 165 233 / 0.9) !important;
}

.tw-placeholder-sky-500\/95::placeholder {
  color: rgb(14 165 233 / 0.95) !important;
}

.tw-placeholder-sky-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(2 132 199 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-600\/0::placeholder {
  color: rgb(2 132 199 / 0) !important;
}

.tw-placeholder-sky-600\/10::placeholder {
  color: rgb(2 132 199 / 0.1) !important;
}

.tw-placeholder-sky-600\/100::placeholder {
  color: rgb(2 132 199 / 1) !important;
}

.tw-placeholder-sky-600\/15::placeholder {
  color: rgb(2 132 199 / 0.15) !important;
}

.tw-placeholder-sky-600\/20::placeholder {
  color: rgb(2 132 199 / 0.2) !important;
}

.tw-placeholder-sky-600\/25::placeholder {
  color: rgb(2 132 199 / 0.25) !important;
}

.tw-placeholder-sky-600\/30::placeholder {
  color: rgb(2 132 199 / 0.3) !important;
}

.tw-placeholder-sky-600\/35::placeholder {
  color: rgb(2 132 199 / 0.35) !important;
}

.tw-placeholder-sky-600\/40::placeholder {
  color: rgb(2 132 199 / 0.4) !important;
}

.tw-placeholder-sky-600\/45::placeholder {
  color: rgb(2 132 199 / 0.45) !important;
}

.tw-placeholder-sky-600\/5::placeholder {
  color: rgb(2 132 199 / 0.05) !important;
}

.tw-placeholder-sky-600\/50::placeholder {
  color: rgb(2 132 199 / 0.5) !important;
}

.tw-placeholder-sky-600\/55::placeholder {
  color: rgb(2 132 199 / 0.55) !important;
}

.tw-placeholder-sky-600\/60::placeholder {
  color: rgb(2 132 199 / 0.6) !important;
}

.tw-placeholder-sky-600\/65::placeholder {
  color: rgb(2 132 199 / 0.65) !important;
}

.tw-placeholder-sky-600\/70::placeholder {
  color: rgb(2 132 199 / 0.7) !important;
}

.tw-placeholder-sky-600\/75::placeholder {
  color: rgb(2 132 199 / 0.75) !important;
}

.tw-placeholder-sky-600\/80::placeholder {
  color: rgb(2 132 199 / 0.8) !important;
}

.tw-placeholder-sky-600\/85::placeholder {
  color: rgb(2 132 199 / 0.85) !important;
}

.tw-placeholder-sky-600\/90::placeholder {
  color: rgb(2 132 199 / 0.9) !important;
}

.tw-placeholder-sky-600\/95::placeholder {
  color: rgb(2 132 199 / 0.95) !important;
}

.tw-placeholder-sky-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(3 105 161 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-700\/0::placeholder {
  color: rgb(3 105 161 / 0) !important;
}

.tw-placeholder-sky-700\/10::placeholder {
  color: rgb(3 105 161 / 0.1) !important;
}

.tw-placeholder-sky-700\/100::placeholder {
  color: rgb(3 105 161 / 1) !important;
}

.tw-placeholder-sky-700\/15::placeholder {
  color: rgb(3 105 161 / 0.15) !important;
}

.tw-placeholder-sky-700\/20::placeholder {
  color: rgb(3 105 161 / 0.2) !important;
}

.tw-placeholder-sky-700\/25::placeholder {
  color: rgb(3 105 161 / 0.25) !important;
}

.tw-placeholder-sky-700\/30::placeholder {
  color: rgb(3 105 161 / 0.3) !important;
}

.tw-placeholder-sky-700\/35::placeholder {
  color: rgb(3 105 161 / 0.35) !important;
}

.tw-placeholder-sky-700\/40::placeholder {
  color: rgb(3 105 161 / 0.4) !important;
}

.tw-placeholder-sky-700\/45::placeholder {
  color: rgb(3 105 161 / 0.45) !important;
}

.tw-placeholder-sky-700\/5::placeholder {
  color: rgb(3 105 161 / 0.05) !important;
}

.tw-placeholder-sky-700\/50::placeholder {
  color: rgb(3 105 161 / 0.5) !important;
}

.tw-placeholder-sky-700\/55::placeholder {
  color: rgb(3 105 161 / 0.55) !important;
}

.tw-placeholder-sky-700\/60::placeholder {
  color: rgb(3 105 161 / 0.6) !important;
}

.tw-placeholder-sky-700\/65::placeholder {
  color: rgb(3 105 161 / 0.65) !important;
}

.tw-placeholder-sky-700\/70::placeholder {
  color: rgb(3 105 161 / 0.7) !important;
}

.tw-placeholder-sky-700\/75::placeholder {
  color: rgb(3 105 161 / 0.75) !important;
}

.tw-placeholder-sky-700\/80::placeholder {
  color: rgb(3 105 161 / 0.8) !important;
}

.tw-placeholder-sky-700\/85::placeholder {
  color: rgb(3 105 161 / 0.85) !important;
}

.tw-placeholder-sky-700\/90::placeholder {
  color: rgb(3 105 161 / 0.9) !important;
}

.tw-placeholder-sky-700\/95::placeholder {
  color: rgb(3 105 161 / 0.95) !important;
}

.tw-placeholder-sky-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(7 89 133 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-800\/0::placeholder {
  color: rgb(7 89 133 / 0) !important;
}

.tw-placeholder-sky-800\/10::placeholder {
  color: rgb(7 89 133 / 0.1) !important;
}

.tw-placeholder-sky-800\/100::placeholder {
  color: rgb(7 89 133 / 1) !important;
}

.tw-placeholder-sky-800\/15::placeholder {
  color: rgb(7 89 133 / 0.15) !important;
}

.tw-placeholder-sky-800\/20::placeholder {
  color: rgb(7 89 133 / 0.2) !important;
}

.tw-placeholder-sky-800\/25::placeholder {
  color: rgb(7 89 133 / 0.25) !important;
}

.tw-placeholder-sky-800\/30::placeholder {
  color: rgb(7 89 133 / 0.3) !important;
}

.tw-placeholder-sky-800\/35::placeholder {
  color: rgb(7 89 133 / 0.35) !important;
}

.tw-placeholder-sky-800\/40::placeholder {
  color: rgb(7 89 133 / 0.4) !important;
}

.tw-placeholder-sky-800\/45::placeholder {
  color: rgb(7 89 133 / 0.45) !important;
}

.tw-placeholder-sky-800\/5::placeholder {
  color: rgb(7 89 133 / 0.05) !important;
}

.tw-placeholder-sky-800\/50::placeholder {
  color: rgb(7 89 133 / 0.5) !important;
}

.tw-placeholder-sky-800\/55::placeholder {
  color: rgb(7 89 133 / 0.55) !important;
}

.tw-placeholder-sky-800\/60::placeholder {
  color: rgb(7 89 133 / 0.6) !important;
}

.tw-placeholder-sky-800\/65::placeholder {
  color: rgb(7 89 133 / 0.65) !important;
}

.tw-placeholder-sky-800\/70::placeholder {
  color: rgb(7 89 133 / 0.7) !important;
}

.tw-placeholder-sky-800\/75::placeholder {
  color: rgb(7 89 133 / 0.75) !important;
}

.tw-placeholder-sky-800\/80::placeholder {
  color: rgb(7 89 133 / 0.8) !important;
}

.tw-placeholder-sky-800\/85::placeholder {
  color: rgb(7 89 133 / 0.85) !important;
}

.tw-placeholder-sky-800\/90::placeholder {
  color: rgb(7 89 133 / 0.9) !important;
}

.tw-placeholder-sky-800\/95::placeholder {
  color: rgb(7 89 133 / 0.95) !important;
}

.tw-placeholder-sky-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(12 74 110 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-900\/0::placeholder {
  color: rgb(12 74 110 / 0) !important;
}

.tw-placeholder-sky-900\/10::placeholder {
  color: rgb(12 74 110 / 0.1) !important;
}

.tw-placeholder-sky-900\/100::placeholder {
  color: rgb(12 74 110 / 1) !important;
}

.tw-placeholder-sky-900\/15::placeholder {
  color: rgb(12 74 110 / 0.15) !important;
}

.tw-placeholder-sky-900\/20::placeholder {
  color: rgb(12 74 110 / 0.2) !important;
}

.tw-placeholder-sky-900\/25::placeholder {
  color: rgb(12 74 110 / 0.25) !important;
}

.tw-placeholder-sky-900\/30::placeholder {
  color: rgb(12 74 110 / 0.3) !important;
}

.tw-placeholder-sky-900\/35::placeholder {
  color: rgb(12 74 110 / 0.35) !important;
}

.tw-placeholder-sky-900\/40::placeholder {
  color: rgb(12 74 110 / 0.4) !important;
}

.tw-placeholder-sky-900\/45::placeholder {
  color: rgb(12 74 110 / 0.45) !important;
}

.tw-placeholder-sky-900\/5::placeholder {
  color: rgb(12 74 110 / 0.05) !important;
}

.tw-placeholder-sky-900\/50::placeholder {
  color: rgb(12 74 110 / 0.5) !important;
}

.tw-placeholder-sky-900\/55::placeholder {
  color: rgb(12 74 110 / 0.55) !important;
}

.tw-placeholder-sky-900\/60::placeholder {
  color: rgb(12 74 110 / 0.6) !important;
}

.tw-placeholder-sky-900\/65::placeholder {
  color: rgb(12 74 110 / 0.65) !important;
}

.tw-placeholder-sky-900\/70::placeholder {
  color: rgb(12 74 110 / 0.7) !important;
}

.tw-placeholder-sky-900\/75::placeholder {
  color: rgb(12 74 110 / 0.75) !important;
}

.tw-placeholder-sky-900\/80::placeholder {
  color: rgb(12 74 110 / 0.8) !important;
}

.tw-placeholder-sky-900\/85::placeholder {
  color: rgb(12 74 110 / 0.85) !important;
}

.tw-placeholder-sky-900\/90::placeholder {
  color: rgb(12 74 110 / 0.9) !important;
}

.tw-placeholder-sky-900\/95::placeholder {
  color: rgb(12 74 110 / 0.95) !important;
}

.tw-placeholder-sky-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(8 47 73 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-sky-950\/0::placeholder {
  color: rgb(8 47 73 / 0) !important;
}

.tw-placeholder-sky-950\/10::placeholder {
  color: rgb(8 47 73 / 0.1) !important;
}

.tw-placeholder-sky-950\/100::placeholder {
  color: rgb(8 47 73 / 1) !important;
}

.tw-placeholder-sky-950\/15::placeholder {
  color: rgb(8 47 73 / 0.15) !important;
}

.tw-placeholder-sky-950\/20::placeholder {
  color: rgb(8 47 73 / 0.2) !important;
}

.tw-placeholder-sky-950\/25::placeholder {
  color: rgb(8 47 73 / 0.25) !important;
}

.tw-placeholder-sky-950\/30::placeholder {
  color: rgb(8 47 73 / 0.3) !important;
}

.tw-placeholder-sky-950\/35::placeholder {
  color: rgb(8 47 73 / 0.35) !important;
}

.tw-placeholder-sky-950\/40::placeholder {
  color: rgb(8 47 73 / 0.4) !important;
}

.tw-placeholder-sky-950\/45::placeholder {
  color: rgb(8 47 73 / 0.45) !important;
}

.tw-placeholder-sky-950\/5::placeholder {
  color: rgb(8 47 73 / 0.05) !important;
}

.tw-placeholder-sky-950\/50::placeholder {
  color: rgb(8 47 73 / 0.5) !important;
}

.tw-placeholder-sky-950\/55::placeholder {
  color: rgb(8 47 73 / 0.55) !important;
}

.tw-placeholder-sky-950\/60::placeholder {
  color: rgb(8 47 73 / 0.6) !important;
}

.tw-placeholder-sky-950\/65::placeholder {
  color: rgb(8 47 73 / 0.65) !important;
}

.tw-placeholder-sky-950\/70::placeholder {
  color: rgb(8 47 73 / 0.7) !important;
}

.tw-placeholder-sky-950\/75::placeholder {
  color: rgb(8 47 73 / 0.75) !important;
}

.tw-placeholder-sky-950\/80::placeholder {
  color: rgb(8 47 73 / 0.8) !important;
}

.tw-placeholder-sky-950\/85::placeholder {
  color: rgb(8 47 73 / 0.85) !important;
}

.tw-placeholder-sky-950\/90::placeholder {
  color: rgb(8 47 73 / 0.9) !important;
}

.tw-placeholder-sky-950\/95::placeholder {
  color: rgb(8 47 73 / 0.95) !important;
}

.tw-placeholder-slate-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(241 245 249 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-100\/0::placeholder {
  color: rgb(241 245 249 / 0) !important;
}

.tw-placeholder-slate-100\/10::placeholder {
  color: rgb(241 245 249 / 0.1) !important;
}

.tw-placeholder-slate-100\/100::placeholder {
  color: rgb(241 245 249 / 1) !important;
}

.tw-placeholder-slate-100\/15::placeholder {
  color: rgb(241 245 249 / 0.15) !important;
}

.tw-placeholder-slate-100\/20::placeholder {
  color: rgb(241 245 249 / 0.2) !important;
}

.tw-placeholder-slate-100\/25::placeholder {
  color: rgb(241 245 249 / 0.25) !important;
}

.tw-placeholder-slate-100\/30::placeholder {
  color: rgb(241 245 249 / 0.3) !important;
}

.tw-placeholder-slate-100\/35::placeholder {
  color: rgb(241 245 249 / 0.35) !important;
}

.tw-placeholder-slate-100\/40::placeholder {
  color: rgb(241 245 249 / 0.4) !important;
}

.tw-placeholder-slate-100\/45::placeholder {
  color: rgb(241 245 249 / 0.45) !important;
}

.tw-placeholder-slate-100\/5::placeholder {
  color: rgb(241 245 249 / 0.05) !important;
}

.tw-placeholder-slate-100\/50::placeholder {
  color: rgb(241 245 249 / 0.5) !important;
}

.tw-placeholder-slate-100\/55::placeholder {
  color: rgb(241 245 249 / 0.55) !important;
}

.tw-placeholder-slate-100\/60::placeholder {
  color: rgb(241 245 249 / 0.6) !important;
}

.tw-placeholder-slate-100\/65::placeholder {
  color: rgb(241 245 249 / 0.65) !important;
}

.tw-placeholder-slate-100\/70::placeholder {
  color: rgb(241 245 249 / 0.7) !important;
}

.tw-placeholder-slate-100\/75::placeholder {
  color: rgb(241 245 249 / 0.75) !important;
}

.tw-placeholder-slate-100\/80::placeholder {
  color: rgb(241 245 249 / 0.8) !important;
}

.tw-placeholder-slate-100\/85::placeholder {
  color: rgb(241 245 249 / 0.85) !important;
}

.tw-placeholder-slate-100\/90::placeholder {
  color: rgb(241 245 249 / 0.9) !important;
}

.tw-placeholder-slate-100\/95::placeholder {
  color: rgb(241 245 249 / 0.95) !important;
}

.tw-placeholder-slate-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(226 232 240 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-200\/0::placeholder {
  color: rgb(226 232 240 / 0) !important;
}

.tw-placeholder-slate-200\/10::placeholder {
  color: rgb(226 232 240 / 0.1) !important;
}

.tw-placeholder-slate-200\/100::placeholder {
  color: rgb(226 232 240 / 1) !important;
}

.tw-placeholder-slate-200\/15::placeholder {
  color: rgb(226 232 240 / 0.15) !important;
}

.tw-placeholder-slate-200\/20::placeholder {
  color: rgb(226 232 240 / 0.2) !important;
}

.tw-placeholder-slate-200\/25::placeholder {
  color: rgb(226 232 240 / 0.25) !important;
}

.tw-placeholder-slate-200\/30::placeholder {
  color: rgb(226 232 240 / 0.3) !important;
}

.tw-placeholder-slate-200\/35::placeholder {
  color: rgb(226 232 240 / 0.35) !important;
}

.tw-placeholder-slate-200\/40::placeholder {
  color: rgb(226 232 240 / 0.4) !important;
}

.tw-placeholder-slate-200\/45::placeholder {
  color: rgb(226 232 240 / 0.45) !important;
}

.tw-placeholder-slate-200\/5::placeholder {
  color: rgb(226 232 240 / 0.05) !important;
}

.tw-placeholder-slate-200\/50::placeholder {
  color: rgb(226 232 240 / 0.5) !important;
}

.tw-placeholder-slate-200\/55::placeholder {
  color: rgb(226 232 240 / 0.55) !important;
}

.tw-placeholder-slate-200\/60::placeholder {
  color: rgb(226 232 240 / 0.6) !important;
}

.tw-placeholder-slate-200\/65::placeholder {
  color: rgb(226 232 240 / 0.65) !important;
}

.tw-placeholder-slate-200\/70::placeholder {
  color: rgb(226 232 240 / 0.7) !important;
}

.tw-placeholder-slate-200\/75::placeholder {
  color: rgb(226 232 240 / 0.75) !important;
}

.tw-placeholder-slate-200\/80::placeholder {
  color: rgb(226 232 240 / 0.8) !important;
}

.tw-placeholder-slate-200\/85::placeholder {
  color: rgb(226 232 240 / 0.85) !important;
}

.tw-placeholder-slate-200\/90::placeholder {
  color: rgb(226 232 240 / 0.9) !important;
}

.tw-placeholder-slate-200\/95::placeholder {
  color: rgb(226 232 240 / 0.95) !important;
}

.tw-placeholder-slate-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(203 213 225 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-300\/0::placeholder {
  color: rgb(203 213 225 / 0) !important;
}

.tw-placeholder-slate-300\/10::placeholder {
  color: rgb(203 213 225 / 0.1) !important;
}

.tw-placeholder-slate-300\/100::placeholder {
  color: rgb(203 213 225 / 1) !important;
}

.tw-placeholder-slate-300\/15::placeholder {
  color: rgb(203 213 225 / 0.15) !important;
}

.tw-placeholder-slate-300\/20::placeholder {
  color: rgb(203 213 225 / 0.2) !important;
}

.tw-placeholder-slate-300\/25::placeholder {
  color: rgb(203 213 225 / 0.25) !important;
}

.tw-placeholder-slate-300\/30::placeholder {
  color: rgb(203 213 225 / 0.3) !important;
}

.tw-placeholder-slate-300\/35::placeholder {
  color: rgb(203 213 225 / 0.35) !important;
}

.tw-placeholder-slate-300\/40::placeholder {
  color: rgb(203 213 225 / 0.4) !important;
}

.tw-placeholder-slate-300\/45::placeholder {
  color: rgb(203 213 225 / 0.45) !important;
}

.tw-placeholder-slate-300\/5::placeholder {
  color: rgb(203 213 225 / 0.05) !important;
}

.tw-placeholder-slate-300\/50::placeholder {
  color: rgb(203 213 225 / 0.5) !important;
}

.tw-placeholder-slate-300\/55::placeholder {
  color: rgb(203 213 225 / 0.55) !important;
}

.tw-placeholder-slate-300\/60::placeholder {
  color: rgb(203 213 225 / 0.6) !important;
}

.tw-placeholder-slate-300\/65::placeholder {
  color: rgb(203 213 225 / 0.65) !important;
}

.tw-placeholder-slate-300\/70::placeholder {
  color: rgb(203 213 225 / 0.7) !important;
}

.tw-placeholder-slate-300\/75::placeholder {
  color: rgb(203 213 225 / 0.75) !important;
}

.tw-placeholder-slate-300\/80::placeholder {
  color: rgb(203 213 225 / 0.8) !important;
}

.tw-placeholder-slate-300\/85::placeholder {
  color: rgb(203 213 225 / 0.85) !important;
}

.tw-placeholder-slate-300\/90::placeholder {
  color: rgb(203 213 225 / 0.9) !important;
}

.tw-placeholder-slate-300\/95::placeholder {
  color: rgb(203 213 225 / 0.95) !important;
}

.tw-placeholder-slate-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(148 163 184 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-400\/0::placeholder {
  color: rgb(148 163 184 / 0) !important;
}

.tw-placeholder-slate-400\/10::placeholder {
  color: rgb(148 163 184 / 0.1) !important;
}

.tw-placeholder-slate-400\/100::placeholder {
  color: rgb(148 163 184 / 1) !important;
}

.tw-placeholder-slate-400\/15::placeholder {
  color: rgb(148 163 184 / 0.15) !important;
}

.tw-placeholder-slate-400\/20::placeholder {
  color: rgb(148 163 184 / 0.2) !important;
}

.tw-placeholder-slate-400\/25::placeholder {
  color: rgb(148 163 184 / 0.25) !important;
}

.tw-placeholder-slate-400\/30::placeholder {
  color: rgb(148 163 184 / 0.3) !important;
}

.tw-placeholder-slate-400\/35::placeholder {
  color: rgb(148 163 184 / 0.35) !important;
}

.tw-placeholder-slate-400\/40::placeholder {
  color: rgb(148 163 184 / 0.4) !important;
}

.tw-placeholder-slate-400\/45::placeholder {
  color: rgb(148 163 184 / 0.45) !important;
}

.tw-placeholder-slate-400\/5::placeholder {
  color: rgb(148 163 184 / 0.05) !important;
}

.tw-placeholder-slate-400\/50::placeholder {
  color: rgb(148 163 184 / 0.5) !important;
}

.tw-placeholder-slate-400\/55::placeholder {
  color: rgb(148 163 184 / 0.55) !important;
}

.tw-placeholder-slate-400\/60::placeholder {
  color: rgb(148 163 184 / 0.6) !important;
}

.tw-placeholder-slate-400\/65::placeholder {
  color: rgb(148 163 184 / 0.65) !important;
}

.tw-placeholder-slate-400\/70::placeholder {
  color: rgb(148 163 184 / 0.7) !important;
}

.tw-placeholder-slate-400\/75::placeholder {
  color: rgb(148 163 184 / 0.75) !important;
}

.tw-placeholder-slate-400\/80::placeholder {
  color: rgb(148 163 184 / 0.8) !important;
}

.tw-placeholder-slate-400\/85::placeholder {
  color: rgb(148 163 184 / 0.85) !important;
}

.tw-placeholder-slate-400\/90::placeholder {
  color: rgb(148 163 184 / 0.9) !important;
}

.tw-placeholder-slate-400\/95::placeholder {
  color: rgb(148 163 184 / 0.95) !important;
}

.tw-placeholder-slate-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(248 250 252 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-50\/0::placeholder {
  color: rgb(248 250 252 / 0) !important;
}

.tw-placeholder-slate-50\/10::placeholder {
  color: rgb(248 250 252 / 0.1) !important;
}

.tw-placeholder-slate-50\/100::placeholder {
  color: rgb(248 250 252 / 1) !important;
}

.tw-placeholder-slate-50\/15::placeholder {
  color: rgb(248 250 252 / 0.15) !important;
}

.tw-placeholder-slate-50\/20::placeholder {
  color: rgb(248 250 252 / 0.2) !important;
}

.tw-placeholder-slate-50\/25::placeholder {
  color: rgb(248 250 252 / 0.25) !important;
}

.tw-placeholder-slate-50\/30::placeholder {
  color: rgb(248 250 252 / 0.3) !important;
}

.tw-placeholder-slate-50\/35::placeholder {
  color: rgb(248 250 252 / 0.35) !important;
}

.tw-placeholder-slate-50\/40::placeholder {
  color: rgb(248 250 252 / 0.4) !important;
}

.tw-placeholder-slate-50\/45::placeholder {
  color: rgb(248 250 252 / 0.45) !important;
}

.tw-placeholder-slate-50\/5::placeholder {
  color: rgb(248 250 252 / 0.05) !important;
}

.tw-placeholder-slate-50\/50::placeholder {
  color: rgb(248 250 252 / 0.5) !important;
}

.tw-placeholder-slate-50\/55::placeholder {
  color: rgb(248 250 252 / 0.55) !important;
}

.tw-placeholder-slate-50\/60::placeholder {
  color: rgb(248 250 252 / 0.6) !important;
}

.tw-placeholder-slate-50\/65::placeholder {
  color: rgb(248 250 252 / 0.65) !important;
}

.tw-placeholder-slate-50\/70::placeholder {
  color: rgb(248 250 252 / 0.7) !important;
}

.tw-placeholder-slate-50\/75::placeholder {
  color: rgb(248 250 252 / 0.75) !important;
}

.tw-placeholder-slate-50\/80::placeholder {
  color: rgb(248 250 252 / 0.8) !important;
}

.tw-placeholder-slate-50\/85::placeholder {
  color: rgb(248 250 252 / 0.85) !important;
}

.tw-placeholder-slate-50\/90::placeholder {
  color: rgb(248 250 252 / 0.9) !important;
}

.tw-placeholder-slate-50\/95::placeholder {
  color: rgb(248 250 252 / 0.95) !important;
}

.tw-placeholder-slate-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(100 116 139 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-500\/0::placeholder {
  color: rgb(100 116 139 / 0) !important;
}

.tw-placeholder-slate-500\/10::placeholder {
  color: rgb(100 116 139 / 0.1) !important;
}

.tw-placeholder-slate-500\/100::placeholder {
  color: rgb(100 116 139 / 1) !important;
}

.tw-placeholder-slate-500\/15::placeholder {
  color: rgb(100 116 139 / 0.15) !important;
}

.tw-placeholder-slate-500\/20::placeholder {
  color: rgb(100 116 139 / 0.2) !important;
}

.tw-placeholder-slate-500\/25::placeholder {
  color: rgb(100 116 139 / 0.25) !important;
}

.tw-placeholder-slate-500\/30::placeholder {
  color: rgb(100 116 139 / 0.3) !important;
}

.tw-placeholder-slate-500\/35::placeholder {
  color: rgb(100 116 139 / 0.35) !important;
}

.tw-placeholder-slate-500\/40::placeholder {
  color: rgb(100 116 139 / 0.4) !important;
}

.tw-placeholder-slate-500\/45::placeholder {
  color: rgb(100 116 139 / 0.45) !important;
}

.tw-placeholder-slate-500\/5::placeholder {
  color: rgb(100 116 139 / 0.05) !important;
}

.tw-placeholder-slate-500\/50::placeholder {
  color: rgb(100 116 139 / 0.5) !important;
}

.tw-placeholder-slate-500\/55::placeholder {
  color: rgb(100 116 139 / 0.55) !important;
}

.tw-placeholder-slate-500\/60::placeholder {
  color: rgb(100 116 139 / 0.6) !important;
}

.tw-placeholder-slate-500\/65::placeholder {
  color: rgb(100 116 139 / 0.65) !important;
}

.tw-placeholder-slate-500\/70::placeholder {
  color: rgb(100 116 139 / 0.7) !important;
}

.tw-placeholder-slate-500\/75::placeholder {
  color: rgb(100 116 139 / 0.75) !important;
}

.tw-placeholder-slate-500\/80::placeholder {
  color: rgb(100 116 139 / 0.8) !important;
}

.tw-placeholder-slate-500\/85::placeholder {
  color: rgb(100 116 139 / 0.85) !important;
}

.tw-placeholder-slate-500\/90::placeholder {
  color: rgb(100 116 139 / 0.9) !important;
}

.tw-placeholder-slate-500\/95::placeholder {
  color: rgb(100 116 139 / 0.95) !important;
}

.tw-placeholder-slate-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(71 85 105 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-600\/0::placeholder {
  color: rgb(71 85 105 / 0) !important;
}

.tw-placeholder-slate-600\/10::placeholder {
  color: rgb(71 85 105 / 0.1) !important;
}

.tw-placeholder-slate-600\/100::placeholder {
  color: rgb(71 85 105 / 1) !important;
}

.tw-placeholder-slate-600\/15::placeholder {
  color: rgb(71 85 105 / 0.15) !important;
}

.tw-placeholder-slate-600\/20::placeholder {
  color: rgb(71 85 105 / 0.2) !important;
}

.tw-placeholder-slate-600\/25::placeholder {
  color: rgb(71 85 105 / 0.25) !important;
}

.tw-placeholder-slate-600\/30::placeholder {
  color: rgb(71 85 105 / 0.3) !important;
}

.tw-placeholder-slate-600\/35::placeholder {
  color: rgb(71 85 105 / 0.35) !important;
}

.tw-placeholder-slate-600\/40::placeholder {
  color: rgb(71 85 105 / 0.4) !important;
}

.tw-placeholder-slate-600\/45::placeholder {
  color: rgb(71 85 105 / 0.45) !important;
}

.tw-placeholder-slate-600\/5::placeholder {
  color: rgb(71 85 105 / 0.05) !important;
}

.tw-placeholder-slate-600\/50::placeholder {
  color: rgb(71 85 105 / 0.5) !important;
}

.tw-placeholder-slate-600\/55::placeholder {
  color: rgb(71 85 105 / 0.55) !important;
}

.tw-placeholder-slate-600\/60::placeholder {
  color: rgb(71 85 105 / 0.6) !important;
}

.tw-placeholder-slate-600\/65::placeholder {
  color: rgb(71 85 105 / 0.65) !important;
}

.tw-placeholder-slate-600\/70::placeholder {
  color: rgb(71 85 105 / 0.7) !important;
}

.tw-placeholder-slate-600\/75::placeholder {
  color: rgb(71 85 105 / 0.75) !important;
}

.tw-placeholder-slate-600\/80::placeholder {
  color: rgb(71 85 105 / 0.8) !important;
}

.tw-placeholder-slate-600\/85::placeholder {
  color: rgb(71 85 105 / 0.85) !important;
}

.tw-placeholder-slate-600\/90::placeholder {
  color: rgb(71 85 105 / 0.9) !important;
}

.tw-placeholder-slate-600\/95::placeholder {
  color: rgb(71 85 105 / 0.95) !important;
}

.tw-placeholder-slate-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(51 65 85 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-700\/0::placeholder {
  color: rgb(51 65 85 / 0) !important;
}

.tw-placeholder-slate-700\/10::placeholder {
  color: rgb(51 65 85 / 0.1) !important;
}

.tw-placeholder-slate-700\/100::placeholder {
  color: rgb(51 65 85 / 1) !important;
}

.tw-placeholder-slate-700\/15::placeholder {
  color: rgb(51 65 85 / 0.15) !important;
}

.tw-placeholder-slate-700\/20::placeholder {
  color: rgb(51 65 85 / 0.2) !important;
}

.tw-placeholder-slate-700\/25::placeholder {
  color: rgb(51 65 85 / 0.25) !important;
}

.tw-placeholder-slate-700\/30::placeholder {
  color: rgb(51 65 85 / 0.3) !important;
}

.tw-placeholder-slate-700\/35::placeholder {
  color: rgb(51 65 85 / 0.35) !important;
}

.tw-placeholder-slate-700\/40::placeholder {
  color: rgb(51 65 85 / 0.4) !important;
}

.tw-placeholder-slate-700\/45::placeholder {
  color: rgb(51 65 85 / 0.45) !important;
}

.tw-placeholder-slate-700\/5::placeholder {
  color: rgb(51 65 85 / 0.05) !important;
}

.tw-placeholder-slate-700\/50::placeholder {
  color: rgb(51 65 85 / 0.5) !important;
}

.tw-placeholder-slate-700\/55::placeholder {
  color: rgb(51 65 85 / 0.55) !important;
}

.tw-placeholder-slate-700\/60::placeholder {
  color: rgb(51 65 85 / 0.6) !important;
}

.tw-placeholder-slate-700\/65::placeholder {
  color: rgb(51 65 85 / 0.65) !important;
}

.tw-placeholder-slate-700\/70::placeholder {
  color: rgb(51 65 85 / 0.7) !important;
}

.tw-placeholder-slate-700\/75::placeholder {
  color: rgb(51 65 85 / 0.75) !important;
}

.tw-placeholder-slate-700\/80::placeholder {
  color: rgb(51 65 85 / 0.8) !important;
}

.tw-placeholder-slate-700\/85::placeholder {
  color: rgb(51 65 85 / 0.85) !important;
}

.tw-placeholder-slate-700\/90::placeholder {
  color: rgb(51 65 85 / 0.9) !important;
}

.tw-placeholder-slate-700\/95::placeholder {
  color: rgb(51 65 85 / 0.95) !important;
}

.tw-placeholder-slate-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(30 41 59 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-800\/0::placeholder {
  color: rgb(30 41 59 / 0) !important;
}

.tw-placeholder-slate-800\/10::placeholder {
  color: rgb(30 41 59 / 0.1) !important;
}

.tw-placeholder-slate-800\/100::placeholder {
  color: rgb(30 41 59 / 1) !important;
}

.tw-placeholder-slate-800\/15::placeholder {
  color: rgb(30 41 59 / 0.15) !important;
}

.tw-placeholder-slate-800\/20::placeholder {
  color: rgb(30 41 59 / 0.2) !important;
}

.tw-placeholder-slate-800\/25::placeholder {
  color: rgb(30 41 59 / 0.25) !important;
}

.tw-placeholder-slate-800\/30::placeholder {
  color: rgb(30 41 59 / 0.3) !important;
}

.tw-placeholder-slate-800\/35::placeholder {
  color: rgb(30 41 59 / 0.35) !important;
}

.tw-placeholder-slate-800\/40::placeholder {
  color: rgb(30 41 59 / 0.4) !important;
}

.tw-placeholder-slate-800\/45::placeholder {
  color: rgb(30 41 59 / 0.45) !important;
}

.tw-placeholder-slate-800\/5::placeholder {
  color: rgb(30 41 59 / 0.05) !important;
}

.tw-placeholder-slate-800\/50::placeholder {
  color: rgb(30 41 59 / 0.5) !important;
}

.tw-placeholder-slate-800\/55::placeholder {
  color: rgb(30 41 59 / 0.55) !important;
}

.tw-placeholder-slate-800\/60::placeholder {
  color: rgb(30 41 59 / 0.6) !important;
}

.tw-placeholder-slate-800\/65::placeholder {
  color: rgb(30 41 59 / 0.65) !important;
}

.tw-placeholder-slate-800\/70::placeholder {
  color: rgb(30 41 59 / 0.7) !important;
}

.tw-placeholder-slate-800\/75::placeholder {
  color: rgb(30 41 59 / 0.75) !important;
}

.tw-placeholder-slate-800\/80::placeholder {
  color: rgb(30 41 59 / 0.8) !important;
}

.tw-placeholder-slate-800\/85::placeholder {
  color: rgb(30 41 59 / 0.85) !important;
}

.tw-placeholder-slate-800\/90::placeholder {
  color: rgb(30 41 59 / 0.9) !important;
}

.tw-placeholder-slate-800\/95::placeholder {
  color: rgb(30 41 59 / 0.95) !important;
}

.tw-placeholder-slate-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(15 23 42 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-900\/0::placeholder {
  color: rgb(15 23 42 / 0) !important;
}

.tw-placeholder-slate-900\/10::placeholder {
  color: rgb(15 23 42 / 0.1) !important;
}

.tw-placeholder-slate-900\/100::placeholder {
  color: rgb(15 23 42 / 1) !important;
}

.tw-placeholder-slate-900\/15::placeholder {
  color: rgb(15 23 42 / 0.15) !important;
}

.tw-placeholder-slate-900\/20::placeholder {
  color: rgb(15 23 42 / 0.2) !important;
}

.tw-placeholder-slate-900\/25::placeholder {
  color: rgb(15 23 42 / 0.25) !important;
}

.tw-placeholder-slate-900\/30::placeholder {
  color: rgb(15 23 42 / 0.3) !important;
}

.tw-placeholder-slate-900\/35::placeholder {
  color: rgb(15 23 42 / 0.35) !important;
}

.tw-placeholder-slate-900\/40::placeholder {
  color: rgb(15 23 42 / 0.4) !important;
}

.tw-placeholder-slate-900\/45::placeholder {
  color: rgb(15 23 42 / 0.45) !important;
}

.tw-placeholder-slate-900\/5::placeholder {
  color: rgb(15 23 42 / 0.05) !important;
}

.tw-placeholder-slate-900\/50::placeholder {
  color: rgb(15 23 42 / 0.5) !important;
}

.tw-placeholder-slate-900\/55::placeholder {
  color: rgb(15 23 42 / 0.55) !important;
}

.tw-placeholder-slate-900\/60::placeholder {
  color: rgb(15 23 42 / 0.6) !important;
}

.tw-placeholder-slate-900\/65::placeholder {
  color: rgb(15 23 42 / 0.65) !important;
}

.tw-placeholder-slate-900\/70::placeholder {
  color: rgb(15 23 42 / 0.7) !important;
}

.tw-placeholder-slate-900\/75::placeholder {
  color: rgb(15 23 42 / 0.75) !important;
}

.tw-placeholder-slate-900\/80::placeholder {
  color: rgb(15 23 42 / 0.8) !important;
}

.tw-placeholder-slate-900\/85::placeholder {
  color: rgb(15 23 42 / 0.85) !important;
}

.tw-placeholder-slate-900\/90::placeholder {
  color: rgb(15 23 42 / 0.9) !important;
}

.tw-placeholder-slate-900\/95::placeholder {
  color: rgb(15 23 42 / 0.95) !important;
}

.tw-placeholder-slate-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(2 6 23 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-slate-950\/0::placeholder {
  color: rgb(2 6 23 / 0) !important;
}

.tw-placeholder-slate-950\/10::placeholder {
  color: rgb(2 6 23 / 0.1) !important;
}

.tw-placeholder-slate-950\/100::placeholder {
  color: rgb(2 6 23 / 1) !important;
}

.tw-placeholder-slate-950\/15::placeholder {
  color: rgb(2 6 23 / 0.15) !important;
}

.tw-placeholder-slate-950\/20::placeholder {
  color: rgb(2 6 23 / 0.2) !important;
}

.tw-placeholder-slate-950\/25::placeholder {
  color: rgb(2 6 23 / 0.25) !important;
}

.tw-placeholder-slate-950\/30::placeholder {
  color: rgb(2 6 23 / 0.3) !important;
}

.tw-placeholder-slate-950\/35::placeholder {
  color: rgb(2 6 23 / 0.35) !important;
}

.tw-placeholder-slate-950\/40::placeholder {
  color: rgb(2 6 23 / 0.4) !important;
}

.tw-placeholder-slate-950\/45::placeholder {
  color: rgb(2 6 23 / 0.45) !important;
}

.tw-placeholder-slate-950\/5::placeholder {
  color: rgb(2 6 23 / 0.05) !important;
}

.tw-placeholder-slate-950\/50::placeholder {
  color: rgb(2 6 23 / 0.5) !important;
}

.tw-placeholder-slate-950\/55::placeholder {
  color: rgb(2 6 23 / 0.55) !important;
}

.tw-placeholder-slate-950\/60::placeholder {
  color: rgb(2 6 23 / 0.6) !important;
}

.tw-placeholder-slate-950\/65::placeholder {
  color: rgb(2 6 23 / 0.65) !important;
}

.tw-placeholder-slate-950\/70::placeholder {
  color: rgb(2 6 23 / 0.7) !important;
}

.tw-placeholder-slate-950\/75::placeholder {
  color: rgb(2 6 23 / 0.75) !important;
}

.tw-placeholder-slate-950\/80::placeholder {
  color: rgb(2 6 23 / 0.8) !important;
}

.tw-placeholder-slate-950\/85::placeholder {
  color: rgb(2 6 23 / 0.85) !important;
}

.tw-placeholder-slate-950\/90::placeholder {
  color: rgb(2 6 23 / 0.9) !important;
}

.tw-placeholder-slate-950\/95::placeholder {
  color: rgb(2 6 23 / 0.95) !important;
}

.tw-placeholder-stone-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(245 245 244 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-100\/0::placeholder {
  color: rgb(245 245 244 / 0) !important;
}

.tw-placeholder-stone-100\/10::placeholder {
  color: rgb(245 245 244 / 0.1) !important;
}

.tw-placeholder-stone-100\/100::placeholder {
  color: rgb(245 245 244 / 1) !important;
}

.tw-placeholder-stone-100\/15::placeholder {
  color: rgb(245 245 244 / 0.15) !important;
}

.tw-placeholder-stone-100\/20::placeholder {
  color: rgb(245 245 244 / 0.2) !important;
}

.tw-placeholder-stone-100\/25::placeholder {
  color: rgb(245 245 244 / 0.25) !important;
}

.tw-placeholder-stone-100\/30::placeholder {
  color: rgb(245 245 244 / 0.3) !important;
}

.tw-placeholder-stone-100\/35::placeholder {
  color: rgb(245 245 244 / 0.35) !important;
}

.tw-placeholder-stone-100\/40::placeholder {
  color: rgb(245 245 244 / 0.4) !important;
}

.tw-placeholder-stone-100\/45::placeholder {
  color: rgb(245 245 244 / 0.45) !important;
}

.tw-placeholder-stone-100\/5::placeholder {
  color: rgb(245 245 244 / 0.05) !important;
}

.tw-placeholder-stone-100\/50::placeholder {
  color: rgb(245 245 244 / 0.5) !important;
}

.tw-placeholder-stone-100\/55::placeholder {
  color: rgb(245 245 244 / 0.55) !important;
}

.tw-placeholder-stone-100\/60::placeholder {
  color: rgb(245 245 244 / 0.6) !important;
}

.tw-placeholder-stone-100\/65::placeholder {
  color: rgb(245 245 244 / 0.65) !important;
}

.tw-placeholder-stone-100\/70::placeholder {
  color: rgb(245 245 244 / 0.7) !important;
}

.tw-placeholder-stone-100\/75::placeholder {
  color: rgb(245 245 244 / 0.75) !important;
}

.tw-placeholder-stone-100\/80::placeholder {
  color: rgb(245 245 244 / 0.8) !important;
}

.tw-placeholder-stone-100\/85::placeholder {
  color: rgb(245 245 244 / 0.85) !important;
}

.tw-placeholder-stone-100\/90::placeholder {
  color: rgb(245 245 244 / 0.9) !important;
}

.tw-placeholder-stone-100\/95::placeholder {
  color: rgb(245 245 244 / 0.95) !important;
}

.tw-placeholder-stone-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(231 229 228 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-200\/0::placeholder {
  color: rgb(231 229 228 / 0) !important;
}

.tw-placeholder-stone-200\/10::placeholder {
  color: rgb(231 229 228 / 0.1) !important;
}

.tw-placeholder-stone-200\/100::placeholder {
  color: rgb(231 229 228 / 1) !important;
}

.tw-placeholder-stone-200\/15::placeholder {
  color: rgb(231 229 228 / 0.15) !important;
}

.tw-placeholder-stone-200\/20::placeholder {
  color: rgb(231 229 228 / 0.2) !important;
}

.tw-placeholder-stone-200\/25::placeholder {
  color: rgb(231 229 228 / 0.25) !important;
}

.tw-placeholder-stone-200\/30::placeholder {
  color: rgb(231 229 228 / 0.3) !important;
}

.tw-placeholder-stone-200\/35::placeholder {
  color: rgb(231 229 228 / 0.35) !important;
}

.tw-placeholder-stone-200\/40::placeholder {
  color: rgb(231 229 228 / 0.4) !important;
}

.tw-placeholder-stone-200\/45::placeholder {
  color: rgb(231 229 228 / 0.45) !important;
}

.tw-placeholder-stone-200\/5::placeholder {
  color: rgb(231 229 228 / 0.05) !important;
}

.tw-placeholder-stone-200\/50::placeholder {
  color: rgb(231 229 228 / 0.5) !important;
}

.tw-placeholder-stone-200\/55::placeholder {
  color: rgb(231 229 228 / 0.55) !important;
}

.tw-placeholder-stone-200\/60::placeholder {
  color: rgb(231 229 228 / 0.6) !important;
}

.tw-placeholder-stone-200\/65::placeholder {
  color: rgb(231 229 228 / 0.65) !important;
}

.tw-placeholder-stone-200\/70::placeholder {
  color: rgb(231 229 228 / 0.7) !important;
}

.tw-placeholder-stone-200\/75::placeholder {
  color: rgb(231 229 228 / 0.75) !important;
}

.tw-placeholder-stone-200\/80::placeholder {
  color: rgb(231 229 228 / 0.8) !important;
}

.tw-placeholder-stone-200\/85::placeholder {
  color: rgb(231 229 228 / 0.85) !important;
}

.tw-placeholder-stone-200\/90::placeholder {
  color: rgb(231 229 228 / 0.9) !important;
}

.tw-placeholder-stone-200\/95::placeholder {
  color: rgb(231 229 228 / 0.95) !important;
}

.tw-placeholder-stone-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(214 211 209 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-300\/0::placeholder {
  color: rgb(214 211 209 / 0) !important;
}

.tw-placeholder-stone-300\/10::placeholder {
  color: rgb(214 211 209 / 0.1) !important;
}

.tw-placeholder-stone-300\/100::placeholder {
  color: rgb(214 211 209 / 1) !important;
}

.tw-placeholder-stone-300\/15::placeholder {
  color: rgb(214 211 209 / 0.15) !important;
}

.tw-placeholder-stone-300\/20::placeholder {
  color: rgb(214 211 209 / 0.2) !important;
}

.tw-placeholder-stone-300\/25::placeholder {
  color: rgb(214 211 209 / 0.25) !important;
}

.tw-placeholder-stone-300\/30::placeholder {
  color: rgb(214 211 209 / 0.3) !important;
}

.tw-placeholder-stone-300\/35::placeholder {
  color: rgb(214 211 209 / 0.35) !important;
}

.tw-placeholder-stone-300\/40::placeholder {
  color: rgb(214 211 209 / 0.4) !important;
}

.tw-placeholder-stone-300\/45::placeholder {
  color: rgb(214 211 209 / 0.45) !important;
}

.tw-placeholder-stone-300\/5::placeholder {
  color: rgb(214 211 209 / 0.05) !important;
}

.tw-placeholder-stone-300\/50::placeholder {
  color: rgb(214 211 209 / 0.5) !important;
}

.tw-placeholder-stone-300\/55::placeholder {
  color: rgb(214 211 209 / 0.55) !important;
}

.tw-placeholder-stone-300\/60::placeholder {
  color: rgb(214 211 209 / 0.6) !important;
}

.tw-placeholder-stone-300\/65::placeholder {
  color: rgb(214 211 209 / 0.65) !important;
}

.tw-placeholder-stone-300\/70::placeholder {
  color: rgb(214 211 209 / 0.7) !important;
}

.tw-placeholder-stone-300\/75::placeholder {
  color: rgb(214 211 209 / 0.75) !important;
}

.tw-placeholder-stone-300\/80::placeholder {
  color: rgb(214 211 209 / 0.8) !important;
}

.tw-placeholder-stone-300\/85::placeholder {
  color: rgb(214 211 209 / 0.85) !important;
}

.tw-placeholder-stone-300\/90::placeholder {
  color: rgb(214 211 209 / 0.9) !important;
}

.tw-placeholder-stone-300\/95::placeholder {
  color: rgb(214 211 209 / 0.95) !important;
}

.tw-placeholder-stone-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(168 162 158 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-400\/0::placeholder {
  color: rgb(168 162 158 / 0) !important;
}

.tw-placeholder-stone-400\/10::placeholder {
  color: rgb(168 162 158 / 0.1) !important;
}

.tw-placeholder-stone-400\/100::placeholder {
  color: rgb(168 162 158 / 1) !important;
}

.tw-placeholder-stone-400\/15::placeholder {
  color: rgb(168 162 158 / 0.15) !important;
}

.tw-placeholder-stone-400\/20::placeholder {
  color: rgb(168 162 158 / 0.2) !important;
}

.tw-placeholder-stone-400\/25::placeholder {
  color: rgb(168 162 158 / 0.25) !important;
}

.tw-placeholder-stone-400\/30::placeholder {
  color: rgb(168 162 158 / 0.3) !important;
}

.tw-placeholder-stone-400\/35::placeholder {
  color: rgb(168 162 158 / 0.35) !important;
}

.tw-placeholder-stone-400\/40::placeholder {
  color: rgb(168 162 158 / 0.4) !important;
}

.tw-placeholder-stone-400\/45::placeholder {
  color: rgb(168 162 158 / 0.45) !important;
}

.tw-placeholder-stone-400\/5::placeholder {
  color: rgb(168 162 158 / 0.05) !important;
}

.tw-placeholder-stone-400\/50::placeholder {
  color: rgb(168 162 158 / 0.5) !important;
}

.tw-placeholder-stone-400\/55::placeholder {
  color: rgb(168 162 158 / 0.55) !important;
}

.tw-placeholder-stone-400\/60::placeholder {
  color: rgb(168 162 158 / 0.6) !important;
}

.tw-placeholder-stone-400\/65::placeholder {
  color: rgb(168 162 158 / 0.65) !important;
}

.tw-placeholder-stone-400\/70::placeholder {
  color: rgb(168 162 158 / 0.7) !important;
}

.tw-placeholder-stone-400\/75::placeholder {
  color: rgb(168 162 158 / 0.75) !important;
}

.tw-placeholder-stone-400\/80::placeholder {
  color: rgb(168 162 158 / 0.8) !important;
}

.tw-placeholder-stone-400\/85::placeholder {
  color: rgb(168 162 158 / 0.85) !important;
}

.tw-placeholder-stone-400\/90::placeholder {
  color: rgb(168 162 158 / 0.9) !important;
}

.tw-placeholder-stone-400\/95::placeholder {
  color: rgb(168 162 158 / 0.95) !important;
}

.tw-placeholder-stone-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(250 250 249 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-50\/0::placeholder {
  color: rgb(250 250 249 / 0) !important;
}

.tw-placeholder-stone-50\/10::placeholder {
  color: rgb(250 250 249 / 0.1) !important;
}

.tw-placeholder-stone-50\/100::placeholder {
  color: rgb(250 250 249 / 1) !important;
}

.tw-placeholder-stone-50\/15::placeholder {
  color: rgb(250 250 249 / 0.15) !important;
}

.tw-placeholder-stone-50\/20::placeholder {
  color: rgb(250 250 249 / 0.2) !important;
}

.tw-placeholder-stone-50\/25::placeholder {
  color: rgb(250 250 249 / 0.25) !important;
}

.tw-placeholder-stone-50\/30::placeholder {
  color: rgb(250 250 249 / 0.3) !important;
}

.tw-placeholder-stone-50\/35::placeholder {
  color: rgb(250 250 249 / 0.35) !important;
}

.tw-placeholder-stone-50\/40::placeholder {
  color: rgb(250 250 249 / 0.4) !important;
}

.tw-placeholder-stone-50\/45::placeholder {
  color: rgb(250 250 249 / 0.45) !important;
}

.tw-placeholder-stone-50\/5::placeholder {
  color: rgb(250 250 249 / 0.05) !important;
}

.tw-placeholder-stone-50\/50::placeholder {
  color: rgb(250 250 249 / 0.5) !important;
}

.tw-placeholder-stone-50\/55::placeholder {
  color: rgb(250 250 249 / 0.55) !important;
}

.tw-placeholder-stone-50\/60::placeholder {
  color: rgb(250 250 249 / 0.6) !important;
}

.tw-placeholder-stone-50\/65::placeholder {
  color: rgb(250 250 249 / 0.65) !important;
}

.tw-placeholder-stone-50\/70::placeholder {
  color: rgb(250 250 249 / 0.7) !important;
}

.tw-placeholder-stone-50\/75::placeholder {
  color: rgb(250 250 249 / 0.75) !important;
}

.tw-placeholder-stone-50\/80::placeholder {
  color: rgb(250 250 249 / 0.8) !important;
}

.tw-placeholder-stone-50\/85::placeholder {
  color: rgb(250 250 249 / 0.85) !important;
}

.tw-placeholder-stone-50\/90::placeholder {
  color: rgb(250 250 249 / 0.9) !important;
}

.tw-placeholder-stone-50\/95::placeholder {
  color: rgb(250 250 249 / 0.95) !important;
}

.tw-placeholder-stone-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(120 113 108 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-500\/0::placeholder {
  color: rgb(120 113 108 / 0) !important;
}

.tw-placeholder-stone-500\/10::placeholder {
  color: rgb(120 113 108 / 0.1) !important;
}

.tw-placeholder-stone-500\/100::placeholder {
  color: rgb(120 113 108 / 1) !important;
}

.tw-placeholder-stone-500\/15::placeholder {
  color: rgb(120 113 108 / 0.15) !important;
}

.tw-placeholder-stone-500\/20::placeholder {
  color: rgb(120 113 108 / 0.2) !important;
}

.tw-placeholder-stone-500\/25::placeholder {
  color: rgb(120 113 108 / 0.25) !important;
}

.tw-placeholder-stone-500\/30::placeholder {
  color: rgb(120 113 108 / 0.3) !important;
}

.tw-placeholder-stone-500\/35::placeholder {
  color: rgb(120 113 108 / 0.35) !important;
}

.tw-placeholder-stone-500\/40::placeholder {
  color: rgb(120 113 108 / 0.4) !important;
}

.tw-placeholder-stone-500\/45::placeholder {
  color: rgb(120 113 108 / 0.45) !important;
}

.tw-placeholder-stone-500\/5::placeholder {
  color: rgb(120 113 108 / 0.05) !important;
}

.tw-placeholder-stone-500\/50::placeholder {
  color: rgb(120 113 108 / 0.5) !important;
}

.tw-placeholder-stone-500\/55::placeholder {
  color: rgb(120 113 108 / 0.55) !important;
}

.tw-placeholder-stone-500\/60::placeholder {
  color: rgb(120 113 108 / 0.6) !important;
}

.tw-placeholder-stone-500\/65::placeholder {
  color: rgb(120 113 108 / 0.65) !important;
}

.tw-placeholder-stone-500\/70::placeholder {
  color: rgb(120 113 108 / 0.7) !important;
}

.tw-placeholder-stone-500\/75::placeholder {
  color: rgb(120 113 108 / 0.75) !important;
}

.tw-placeholder-stone-500\/80::placeholder {
  color: rgb(120 113 108 / 0.8) !important;
}

.tw-placeholder-stone-500\/85::placeholder {
  color: rgb(120 113 108 / 0.85) !important;
}

.tw-placeholder-stone-500\/90::placeholder {
  color: rgb(120 113 108 / 0.9) !important;
}

.tw-placeholder-stone-500\/95::placeholder {
  color: rgb(120 113 108 / 0.95) !important;
}

.tw-placeholder-stone-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(87 83 78 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-600\/0::placeholder {
  color: rgb(87 83 78 / 0) !important;
}

.tw-placeholder-stone-600\/10::placeholder {
  color: rgb(87 83 78 / 0.1) !important;
}

.tw-placeholder-stone-600\/100::placeholder {
  color: rgb(87 83 78 / 1) !important;
}

.tw-placeholder-stone-600\/15::placeholder {
  color: rgb(87 83 78 / 0.15) !important;
}

.tw-placeholder-stone-600\/20::placeholder {
  color: rgb(87 83 78 / 0.2) !important;
}

.tw-placeholder-stone-600\/25::placeholder {
  color: rgb(87 83 78 / 0.25) !important;
}

.tw-placeholder-stone-600\/30::placeholder {
  color: rgb(87 83 78 / 0.3) !important;
}

.tw-placeholder-stone-600\/35::placeholder {
  color: rgb(87 83 78 / 0.35) !important;
}

.tw-placeholder-stone-600\/40::placeholder {
  color: rgb(87 83 78 / 0.4) !important;
}

.tw-placeholder-stone-600\/45::placeholder {
  color: rgb(87 83 78 / 0.45) !important;
}

.tw-placeholder-stone-600\/5::placeholder {
  color: rgb(87 83 78 / 0.05) !important;
}

.tw-placeholder-stone-600\/50::placeholder {
  color: rgb(87 83 78 / 0.5) !important;
}

.tw-placeholder-stone-600\/55::placeholder {
  color: rgb(87 83 78 / 0.55) !important;
}

.tw-placeholder-stone-600\/60::placeholder {
  color: rgb(87 83 78 / 0.6) !important;
}

.tw-placeholder-stone-600\/65::placeholder {
  color: rgb(87 83 78 / 0.65) !important;
}

.tw-placeholder-stone-600\/70::placeholder {
  color: rgb(87 83 78 / 0.7) !important;
}

.tw-placeholder-stone-600\/75::placeholder {
  color: rgb(87 83 78 / 0.75) !important;
}

.tw-placeholder-stone-600\/80::placeholder {
  color: rgb(87 83 78 / 0.8) !important;
}

.tw-placeholder-stone-600\/85::placeholder {
  color: rgb(87 83 78 / 0.85) !important;
}

.tw-placeholder-stone-600\/90::placeholder {
  color: rgb(87 83 78 / 0.9) !important;
}

.tw-placeholder-stone-600\/95::placeholder {
  color: rgb(87 83 78 / 0.95) !important;
}

.tw-placeholder-stone-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(68 64 60 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-700\/0::placeholder {
  color: rgb(68 64 60 / 0) !important;
}

.tw-placeholder-stone-700\/10::placeholder {
  color: rgb(68 64 60 / 0.1) !important;
}

.tw-placeholder-stone-700\/100::placeholder {
  color: rgb(68 64 60 / 1) !important;
}

.tw-placeholder-stone-700\/15::placeholder {
  color: rgb(68 64 60 / 0.15) !important;
}

.tw-placeholder-stone-700\/20::placeholder {
  color: rgb(68 64 60 / 0.2) !important;
}

.tw-placeholder-stone-700\/25::placeholder {
  color: rgb(68 64 60 / 0.25) !important;
}

.tw-placeholder-stone-700\/30::placeholder {
  color: rgb(68 64 60 / 0.3) !important;
}

.tw-placeholder-stone-700\/35::placeholder {
  color: rgb(68 64 60 / 0.35) !important;
}

.tw-placeholder-stone-700\/40::placeholder {
  color: rgb(68 64 60 / 0.4) !important;
}

.tw-placeholder-stone-700\/45::placeholder {
  color: rgb(68 64 60 / 0.45) !important;
}

.tw-placeholder-stone-700\/5::placeholder {
  color: rgb(68 64 60 / 0.05) !important;
}

.tw-placeholder-stone-700\/50::placeholder {
  color: rgb(68 64 60 / 0.5) !important;
}

.tw-placeholder-stone-700\/55::placeholder {
  color: rgb(68 64 60 / 0.55) !important;
}

.tw-placeholder-stone-700\/60::placeholder {
  color: rgb(68 64 60 / 0.6) !important;
}

.tw-placeholder-stone-700\/65::placeholder {
  color: rgb(68 64 60 / 0.65) !important;
}

.tw-placeholder-stone-700\/70::placeholder {
  color: rgb(68 64 60 / 0.7) !important;
}

.tw-placeholder-stone-700\/75::placeholder {
  color: rgb(68 64 60 / 0.75) !important;
}

.tw-placeholder-stone-700\/80::placeholder {
  color: rgb(68 64 60 / 0.8) !important;
}

.tw-placeholder-stone-700\/85::placeholder {
  color: rgb(68 64 60 / 0.85) !important;
}

.tw-placeholder-stone-700\/90::placeholder {
  color: rgb(68 64 60 / 0.9) !important;
}

.tw-placeholder-stone-700\/95::placeholder {
  color: rgb(68 64 60 / 0.95) !important;
}

.tw-placeholder-stone-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(41 37 36 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-800\/0::placeholder {
  color: rgb(41 37 36 / 0) !important;
}

.tw-placeholder-stone-800\/10::placeholder {
  color: rgb(41 37 36 / 0.1) !important;
}

.tw-placeholder-stone-800\/100::placeholder {
  color: rgb(41 37 36 / 1) !important;
}

.tw-placeholder-stone-800\/15::placeholder {
  color: rgb(41 37 36 / 0.15) !important;
}

.tw-placeholder-stone-800\/20::placeholder {
  color: rgb(41 37 36 / 0.2) !important;
}

.tw-placeholder-stone-800\/25::placeholder {
  color: rgb(41 37 36 / 0.25) !important;
}

.tw-placeholder-stone-800\/30::placeholder {
  color: rgb(41 37 36 / 0.3) !important;
}

.tw-placeholder-stone-800\/35::placeholder {
  color: rgb(41 37 36 / 0.35) !important;
}

.tw-placeholder-stone-800\/40::placeholder {
  color: rgb(41 37 36 / 0.4) !important;
}

.tw-placeholder-stone-800\/45::placeholder {
  color: rgb(41 37 36 / 0.45) !important;
}

.tw-placeholder-stone-800\/5::placeholder {
  color: rgb(41 37 36 / 0.05) !important;
}

.tw-placeholder-stone-800\/50::placeholder {
  color: rgb(41 37 36 / 0.5) !important;
}

.tw-placeholder-stone-800\/55::placeholder {
  color: rgb(41 37 36 / 0.55) !important;
}

.tw-placeholder-stone-800\/60::placeholder {
  color: rgb(41 37 36 / 0.6) !important;
}

.tw-placeholder-stone-800\/65::placeholder {
  color: rgb(41 37 36 / 0.65) !important;
}

.tw-placeholder-stone-800\/70::placeholder {
  color: rgb(41 37 36 / 0.7) !important;
}

.tw-placeholder-stone-800\/75::placeholder {
  color: rgb(41 37 36 / 0.75) !important;
}

.tw-placeholder-stone-800\/80::placeholder {
  color: rgb(41 37 36 / 0.8) !important;
}

.tw-placeholder-stone-800\/85::placeholder {
  color: rgb(41 37 36 / 0.85) !important;
}

.tw-placeholder-stone-800\/90::placeholder {
  color: rgb(41 37 36 / 0.9) !important;
}

.tw-placeholder-stone-800\/95::placeholder {
  color: rgb(41 37 36 / 0.95) !important;
}

.tw-placeholder-stone-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(28 25 23 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-900\/0::placeholder {
  color: rgb(28 25 23 / 0) !important;
}

.tw-placeholder-stone-900\/10::placeholder {
  color: rgb(28 25 23 / 0.1) !important;
}

.tw-placeholder-stone-900\/100::placeholder {
  color: rgb(28 25 23 / 1) !important;
}

.tw-placeholder-stone-900\/15::placeholder {
  color: rgb(28 25 23 / 0.15) !important;
}

.tw-placeholder-stone-900\/20::placeholder {
  color: rgb(28 25 23 / 0.2) !important;
}

.tw-placeholder-stone-900\/25::placeholder {
  color: rgb(28 25 23 / 0.25) !important;
}

.tw-placeholder-stone-900\/30::placeholder {
  color: rgb(28 25 23 / 0.3) !important;
}

.tw-placeholder-stone-900\/35::placeholder {
  color: rgb(28 25 23 / 0.35) !important;
}

.tw-placeholder-stone-900\/40::placeholder {
  color: rgb(28 25 23 / 0.4) !important;
}

.tw-placeholder-stone-900\/45::placeholder {
  color: rgb(28 25 23 / 0.45) !important;
}

.tw-placeholder-stone-900\/5::placeholder {
  color: rgb(28 25 23 / 0.05) !important;
}

.tw-placeholder-stone-900\/50::placeholder {
  color: rgb(28 25 23 / 0.5) !important;
}

.tw-placeholder-stone-900\/55::placeholder {
  color: rgb(28 25 23 / 0.55) !important;
}

.tw-placeholder-stone-900\/60::placeholder {
  color: rgb(28 25 23 / 0.6) !important;
}

.tw-placeholder-stone-900\/65::placeholder {
  color: rgb(28 25 23 / 0.65) !important;
}

.tw-placeholder-stone-900\/70::placeholder {
  color: rgb(28 25 23 / 0.7) !important;
}

.tw-placeholder-stone-900\/75::placeholder {
  color: rgb(28 25 23 / 0.75) !important;
}

.tw-placeholder-stone-900\/80::placeholder {
  color: rgb(28 25 23 / 0.8) !important;
}

.tw-placeholder-stone-900\/85::placeholder {
  color: rgb(28 25 23 / 0.85) !important;
}

.tw-placeholder-stone-900\/90::placeholder {
  color: rgb(28 25 23 / 0.9) !important;
}

.tw-placeholder-stone-900\/95::placeholder {
  color: rgb(28 25 23 / 0.95) !important;
}

.tw-placeholder-stone-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(12 10 9 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-stone-950\/0::placeholder {
  color: rgb(12 10 9 / 0) !important;
}

.tw-placeholder-stone-950\/10::placeholder {
  color: rgb(12 10 9 / 0.1) !important;
}

.tw-placeholder-stone-950\/100::placeholder {
  color: rgb(12 10 9 / 1) !important;
}

.tw-placeholder-stone-950\/15::placeholder {
  color: rgb(12 10 9 / 0.15) !important;
}

.tw-placeholder-stone-950\/20::placeholder {
  color: rgb(12 10 9 / 0.2) !important;
}

.tw-placeholder-stone-950\/25::placeholder {
  color: rgb(12 10 9 / 0.25) !important;
}

.tw-placeholder-stone-950\/30::placeholder {
  color: rgb(12 10 9 / 0.3) !important;
}

.tw-placeholder-stone-950\/35::placeholder {
  color: rgb(12 10 9 / 0.35) !important;
}

.tw-placeholder-stone-950\/40::placeholder {
  color: rgb(12 10 9 / 0.4) !important;
}

.tw-placeholder-stone-950\/45::placeholder {
  color: rgb(12 10 9 / 0.45) !important;
}

.tw-placeholder-stone-950\/5::placeholder {
  color: rgb(12 10 9 / 0.05) !important;
}

.tw-placeholder-stone-950\/50::placeholder {
  color: rgb(12 10 9 / 0.5) !important;
}

.tw-placeholder-stone-950\/55::placeholder {
  color: rgb(12 10 9 / 0.55) !important;
}

.tw-placeholder-stone-950\/60::placeholder {
  color: rgb(12 10 9 / 0.6) !important;
}

.tw-placeholder-stone-950\/65::placeholder {
  color: rgb(12 10 9 / 0.65) !important;
}

.tw-placeholder-stone-950\/70::placeholder {
  color: rgb(12 10 9 / 0.7) !important;
}

.tw-placeholder-stone-950\/75::placeholder {
  color: rgb(12 10 9 / 0.75) !important;
}

.tw-placeholder-stone-950\/80::placeholder {
  color: rgb(12 10 9 / 0.8) !important;
}

.tw-placeholder-stone-950\/85::placeholder {
  color: rgb(12 10 9 / 0.85) !important;
}

.tw-placeholder-stone-950\/90::placeholder {
  color: rgb(12 10 9 / 0.9) !important;
}

.tw-placeholder-stone-950\/95::placeholder {
  color: rgb(12 10 9 / 0.95) !important;
}

.tw-placeholder-teal-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(204 251 241 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-100\/0::placeholder {
  color: rgb(204 251 241 / 0) !important;
}

.tw-placeholder-teal-100\/10::placeholder {
  color: rgb(204 251 241 / 0.1) !important;
}

.tw-placeholder-teal-100\/100::placeholder {
  color: rgb(204 251 241 / 1) !important;
}

.tw-placeholder-teal-100\/15::placeholder {
  color: rgb(204 251 241 / 0.15) !important;
}

.tw-placeholder-teal-100\/20::placeholder {
  color: rgb(204 251 241 / 0.2) !important;
}

.tw-placeholder-teal-100\/25::placeholder {
  color: rgb(204 251 241 / 0.25) !important;
}

.tw-placeholder-teal-100\/30::placeholder {
  color: rgb(204 251 241 / 0.3) !important;
}

.tw-placeholder-teal-100\/35::placeholder {
  color: rgb(204 251 241 / 0.35) !important;
}

.tw-placeholder-teal-100\/40::placeholder {
  color: rgb(204 251 241 / 0.4) !important;
}

.tw-placeholder-teal-100\/45::placeholder {
  color: rgb(204 251 241 / 0.45) !important;
}

.tw-placeholder-teal-100\/5::placeholder {
  color: rgb(204 251 241 / 0.05) !important;
}

.tw-placeholder-teal-100\/50::placeholder {
  color: rgb(204 251 241 / 0.5) !important;
}

.tw-placeholder-teal-100\/55::placeholder {
  color: rgb(204 251 241 / 0.55) !important;
}

.tw-placeholder-teal-100\/60::placeholder {
  color: rgb(204 251 241 / 0.6) !important;
}

.tw-placeholder-teal-100\/65::placeholder {
  color: rgb(204 251 241 / 0.65) !important;
}

.tw-placeholder-teal-100\/70::placeholder {
  color: rgb(204 251 241 / 0.7) !important;
}

.tw-placeholder-teal-100\/75::placeholder {
  color: rgb(204 251 241 / 0.75) !important;
}

.tw-placeholder-teal-100\/80::placeholder {
  color: rgb(204 251 241 / 0.8) !important;
}

.tw-placeholder-teal-100\/85::placeholder {
  color: rgb(204 251 241 / 0.85) !important;
}

.tw-placeholder-teal-100\/90::placeholder {
  color: rgb(204 251 241 / 0.9) !important;
}

.tw-placeholder-teal-100\/95::placeholder {
  color: rgb(204 251 241 / 0.95) !important;
}

.tw-placeholder-teal-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(153 246 228 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-200\/0::placeholder {
  color: rgb(153 246 228 / 0) !important;
}

.tw-placeholder-teal-200\/10::placeholder {
  color: rgb(153 246 228 / 0.1) !important;
}

.tw-placeholder-teal-200\/100::placeholder {
  color: rgb(153 246 228 / 1) !important;
}

.tw-placeholder-teal-200\/15::placeholder {
  color: rgb(153 246 228 / 0.15) !important;
}

.tw-placeholder-teal-200\/20::placeholder {
  color: rgb(153 246 228 / 0.2) !important;
}

.tw-placeholder-teal-200\/25::placeholder {
  color: rgb(153 246 228 / 0.25) !important;
}

.tw-placeholder-teal-200\/30::placeholder {
  color: rgb(153 246 228 / 0.3) !important;
}

.tw-placeholder-teal-200\/35::placeholder {
  color: rgb(153 246 228 / 0.35) !important;
}

.tw-placeholder-teal-200\/40::placeholder {
  color: rgb(153 246 228 / 0.4) !important;
}

.tw-placeholder-teal-200\/45::placeholder {
  color: rgb(153 246 228 / 0.45) !important;
}

.tw-placeholder-teal-200\/5::placeholder {
  color: rgb(153 246 228 / 0.05) !important;
}

.tw-placeholder-teal-200\/50::placeholder {
  color: rgb(153 246 228 / 0.5) !important;
}

.tw-placeholder-teal-200\/55::placeholder {
  color: rgb(153 246 228 / 0.55) !important;
}

.tw-placeholder-teal-200\/60::placeholder {
  color: rgb(153 246 228 / 0.6) !important;
}

.tw-placeholder-teal-200\/65::placeholder {
  color: rgb(153 246 228 / 0.65) !important;
}

.tw-placeholder-teal-200\/70::placeholder {
  color: rgb(153 246 228 / 0.7) !important;
}

.tw-placeholder-teal-200\/75::placeholder {
  color: rgb(153 246 228 / 0.75) !important;
}

.tw-placeholder-teal-200\/80::placeholder {
  color: rgb(153 246 228 / 0.8) !important;
}

.tw-placeholder-teal-200\/85::placeholder {
  color: rgb(153 246 228 / 0.85) !important;
}

.tw-placeholder-teal-200\/90::placeholder {
  color: rgb(153 246 228 / 0.9) !important;
}

.tw-placeholder-teal-200\/95::placeholder {
  color: rgb(153 246 228 / 0.95) !important;
}

.tw-placeholder-teal-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(94 234 212 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-300\/0::placeholder {
  color: rgb(94 234 212 / 0) !important;
}

.tw-placeholder-teal-300\/10::placeholder {
  color: rgb(94 234 212 / 0.1) !important;
}

.tw-placeholder-teal-300\/100::placeholder {
  color: rgb(94 234 212 / 1) !important;
}

.tw-placeholder-teal-300\/15::placeholder {
  color: rgb(94 234 212 / 0.15) !important;
}

.tw-placeholder-teal-300\/20::placeholder {
  color: rgb(94 234 212 / 0.2) !important;
}

.tw-placeholder-teal-300\/25::placeholder {
  color: rgb(94 234 212 / 0.25) !important;
}

.tw-placeholder-teal-300\/30::placeholder {
  color: rgb(94 234 212 / 0.3) !important;
}

.tw-placeholder-teal-300\/35::placeholder {
  color: rgb(94 234 212 / 0.35) !important;
}

.tw-placeholder-teal-300\/40::placeholder {
  color: rgb(94 234 212 / 0.4) !important;
}

.tw-placeholder-teal-300\/45::placeholder {
  color: rgb(94 234 212 / 0.45) !important;
}

.tw-placeholder-teal-300\/5::placeholder {
  color: rgb(94 234 212 / 0.05) !important;
}

.tw-placeholder-teal-300\/50::placeholder {
  color: rgb(94 234 212 / 0.5) !important;
}

.tw-placeholder-teal-300\/55::placeholder {
  color: rgb(94 234 212 / 0.55) !important;
}

.tw-placeholder-teal-300\/60::placeholder {
  color: rgb(94 234 212 / 0.6) !important;
}

.tw-placeholder-teal-300\/65::placeholder {
  color: rgb(94 234 212 / 0.65) !important;
}

.tw-placeholder-teal-300\/70::placeholder {
  color: rgb(94 234 212 / 0.7) !important;
}

.tw-placeholder-teal-300\/75::placeholder {
  color: rgb(94 234 212 / 0.75) !important;
}

.tw-placeholder-teal-300\/80::placeholder {
  color: rgb(94 234 212 / 0.8) !important;
}

.tw-placeholder-teal-300\/85::placeholder {
  color: rgb(94 234 212 / 0.85) !important;
}

.tw-placeholder-teal-300\/90::placeholder {
  color: rgb(94 234 212 / 0.9) !important;
}

.tw-placeholder-teal-300\/95::placeholder {
  color: rgb(94 234 212 / 0.95) !important;
}

.tw-placeholder-teal-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(45 212 191 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-400\/0::placeholder {
  color: rgb(45 212 191 / 0) !important;
}

.tw-placeholder-teal-400\/10::placeholder {
  color: rgb(45 212 191 / 0.1) !important;
}

.tw-placeholder-teal-400\/100::placeholder {
  color: rgb(45 212 191 / 1) !important;
}

.tw-placeholder-teal-400\/15::placeholder {
  color: rgb(45 212 191 / 0.15) !important;
}

.tw-placeholder-teal-400\/20::placeholder {
  color: rgb(45 212 191 / 0.2) !important;
}

.tw-placeholder-teal-400\/25::placeholder {
  color: rgb(45 212 191 / 0.25) !important;
}

.tw-placeholder-teal-400\/30::placeholder {
  color: rgb(45 212 191 / 0.3) !important;
}

.tw-placeholder-teal-400\/35::placeholder {
  color: rgb(45 212 191 / 0.35) !important;
}

.tw-placeholder-teal-400\/40::placeholder {
  color: rgb(45 212 191 / 0.4) !important;
}

.tw-placeholder-teal-400\/45::placeholder {
  color: rgb(45 212 191 / 0.45) !important;
}

.tw-placeholder-teal-400\/5::placeholder {
  color: rgb(45 212 191 / 0.05) !important;
}

.tw-placeholder-teal-400\/50::placeholder {
  color: rgb(45 212 191 / 0.5) !important;
}

.tw-placeholder-teal-400\/55::placeholder {
  color: rgb(45 212 191 / 0.55) !important;
}

.tw-placeholder-teal-400\/60::placeholder {
  color: rgb(45 212 191 / 0.6) !important;
}

.tw-placeholder-teal-400\/65::placeholder {
  color: rgb(45 212 191 / 0.65) !important;
}

.tw-placeholder-teal-400\/70::placeholder {
  color: rgb(45 212 191 / 0.7) !important;
}

.tw-placeholder-teal-400\/75::placeholder {
  color: rgb(45 212 191 / 0.75) !important;
}

.tw-placeholder-teal-400\/80::placeholder {
  color: rgb(45 212 191 / 0.8) !important;
}

.tw-placeholder-teal-400\/85::placeholder {
  color: rgb(45 212 191 / 0.85) !important;
}

.tw-placeholder-teal-400\/90::placeholder {
  color: rgb(45 212 191 / 0.9) !important;
}

.tw-placeholder-teal-400\/95::placeholder {
  color: rgb(45 212 191 / 0.95) !important;
}

.tw-placeholder-teal-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(240 253 250 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-50\/0::placeholder {
  color: rgb(240 253 250 / 0) !important;
}

.tw-placeholder-teal-50\/10::placeholder {
  color: rgb(240 253 250 / 0.1) !important;
}

.tw-placeholder-teal-50\/100::placeholder {
  color: rgb(240 253 250 / 1) !important;
}

.tw-placeholder-teal-50\/15::placeholder {
  color: rgb(240 253 250 / 0.15) !important;
}

.tw-placeholder-teal-50\/20::placeholder {
  color: rgb(240 253 250 / 0.2) !important;
}

.tw-placeholder-teal-50\/25::placeholder {
  color: rgb(240 253 250 / 0.25) !important;
}

.tw-placeholder-teal-50\/30::placeholder {
  color: rgb(240 253 250 / 0.3) !important;
}

.tw-placeholder-teal-50\/35::placeholder {
  color: rgb(240 253 250 / 0.35) !important;
}

.tw-placeholder-teal-50\/40::placeholder {
  color: rgb(240 253 250 / 0.4) !important;
}

.tw-placeholder-teal-50\/45::placeholder {
  color: rgb(240 253 250 / 0.45) !important;
}

.tw-placeholder-teal-50\/5::placeholder {
  color: rgb(240 253 250 / 0.05) !important;
}

.tw-placeholder-teal-50\/50::placeholder {
  color: rgb(240 253 250 / 0.5) !important;
}

.tw-placeholder-teal-50\/55::placeholder {
  color: rgb(240 253 250 / 0.55) !important;
}

.tw-placeholder-teal-50\/60::placeholder {
  color: rgb(240 253 250 / 0.6) !important;
}

.tw-placeholder-teal-50\/65::placeholder {
  color: rgb(240 253 250 / 0.65) !important;
}

.tw-placeholder-teal-50\/70::placeholder {
  color: rgb(240 253 250 / 0.7) !important;
}

.tw-placeholder-teal-50\/75::placeholder {
  color: rgb(240 253 250 / 0.75) !important;
}

.tw-placeholder-teal-50\/80::placeholder {
  color: rgb(240 253 250 / 0.8) !important;
}

.tw-placeholder-teal-50\/85::placeholder {
  color: rgb(240 253 250 / 0.85) !important;
}

.tw-placeholder-teal-50\/90::placeholder {
  color: rgb(240 253 250 / 0.9) !important;
}

.tw-placeholder-teal-50\/95::placeholder {
  color: rgb(240 253 250 / 0.95) !important;
}

.tw-placeholder-teal-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(20 184 166 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-500\/0::placeholder {
  color: rgb(20 184 166 / 0) !important;
}

.tw-placeholder-teal-500\/10::placeholder {
  color: rgb(20 184 166 / 0.1) !important;
}

.tw-placeholder-teal-500\/100::placeholder {
  color: rgb(20 184 166 / 1) !important;
}

.tw-placeholder-teal-500\/15::placeholder {
  color: rgb(20 184 166 / 0.15) !important;
}

.tw-placeholder-teal-500\/20::placeholder {
  color: rgb(20 184 166 / 0.2) !important;
}

.tw-placeholder-teal-500\/25::placeholder {
  color: rgb(20 184 166 / 0.25) !important;
}

.tw-placeholder-teal-500\/30::placeholder {
  color: rgb(20 184 166 / 0.3) !important;
}

.tw-placeholder-teal-500\/35::placeholder {
  color: rgb(20 184 166 / 0.35) !important;
}

.tw-placeholder-teal-500\/40::placeholder {
  color: rgb(20 184 166 / 0.4) !important;
}

.tw-placeholder-teal-500\/45::placeholder {
  color: rgb(20 184 166 / 0.45) !important;
}

.tw-placeholder-teal-500\/5::placeholder {
  color: rgb(20 184 166 / 0.05) !important;
}

.tw-placeholder-teal-500\/50::placeholder {
  color: rgb(20 184 166 / 0.5) !important;
}

.tw-placeholder-teal-500\/55::placeholder {
  color: rgb(20 184 166 / 0.55) !important;
}

.tw-placeholder-teal-500\/60::placeholder {
  color: rgb(20 184 166 / 0.6) !important;
}

.tw-placeholder-teal-500\/65::placeholder {
  color: rgb(20 184 166 / 0.65) !important;
}

.tw-placeholder-teal-500\/70::placeholder {
  color: rgb(20 184 166 / 0.7) !important;
}

.tw-placeholder-teal-500\/75::placeholder {
  color: rgb(20 184 166 / 0.75) !important;
}

.tw-placeholder-teal-500\/80::placeholder {
  color: rgb(20 184 166 / 0.8) !important;
}

.tw-placeholder-teal-500\/85::placeholder {
  color: rgb(20 184 166 / 0.85) !important;
}

.tw-placeholder-teal-500\/90::placeholder {
  color: rgb(20 184 166 / 0.9) !important;
}

.tw-placeholder-teal-500\/95::placeholder {
  color: rgb(20 184 166 / 0.95) !important;
}

.tw-placeholder-teal-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(13 148 136 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-600\/0::placeholder {
  color: rgb(13 148 136 / 0) !important;
}

.tw-placeholder-teal-600\/10::placeholder {
  color: rgb(13 148 136 / 0.1) !important;
}

.tw-placeholder-teal-600\/100::placeholder {
  color: rgb(13 148 136 / 1) !important;
}

.tw-placeholder-teal-600\/15::placeholder {
  color: rgb(13 148 136 / 0.15) !important;
}

.tw-placeholder-teal-600\/20::placeholder {
  color: rgb(13 148 136 / 0.2) !important;
}

.tw-placeholder-teal-600\/25::placeholder {
  color: rgb(13 148 136 / 0.25) !important;
}

.tw-placeholder-teal-600\/30::placeholder {
  color: rgb(13 148 136 / 0.3) !important;
}

.tw-placeholder-teal-600\/35::placeholder {
  color: rgb(13 148 136 / 0.35) !important;
}

.tw-placeholder-teal-600\/40::placeholder {
  color: rgb(13 148 136 / 0.4) !important;
}

.tw-placeholder-teal-600\/45::placeholder {
  color: rgb(13 148 136 / 0.45) !important;
}

.tw-placeholder-teal-600\/5::placeholder {
  color: rgb(13 148 136 / 0.05) !important;
}

.tw-placeholder-teal-600\/50::placeholder {
  color: rgb(13 148 136 / 0.5) !important;
}

.tw-placeholder-teal-600\/55::placeholder {
  color: rgb(13 148 136 / 0.55) !important;
}

.tw-placeholder-teal-600\/60::placeholder {
  color: rgb(13 148 136 / 0.6) !important;
}

.tw-placeholder-teal-600\/65::placeholder {
  color: rgb(13 148 136 / 0.65) !important;
}

.tw-placeholder-teal-600\/70::placeholder {
  color: rgb(13 148 136 / 0.7) !important;
}

.tw-placeholder-teal-600\/75::placeholder {
  color: rgb(13 148 136 / 0.75) !important;
}

.tw-placeholder-teal-600\/80::placeholder {
  color: rgb(13 148 136 / 0.8) !important;
}

.tw-placeholder-teal-600\/85::placeholder {
  color: rgb(13 148 136 / 0.85) !important;
}

.tw-placeholder-teal-600\/90::placeholder {
  color: rgb(13 148 136 / 0.9) !important;
}

.tw-placeholder-teal-600\/95::placeholder {
  color: rgb(13 148 136 / 0.95) !important;
}

.tw-placeholder-teal-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(15 118 110 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-700\/0::placeholder {
  color: rgb(15 118 110 / 0) !important;
}

.tw-placeholder-teal-700\/10::placeholder {
  color: rgb(15 118 110 / 0.1) !important;
}

.tw-placeholder-teal-700\/100::placeholder {
  color: rgb(15 118 110 / 1) !important;
}

.tw-placeholder-teal-700\/15::placeholder {
  color: rgb(15 118 110 / 0.15) !important;
}

.tw-placeholder-teal-700\/20::placeholder {
  color: rgb(15 118 110 / 0.2) !important;
}

.tw-placeholder-teal-700\/25::placeholder {
  color: rgb(15 118 110 / 0.25) !important;
}

.tw-placeholder-teal-700\/30::placeholder {
  color: rgb(15 118 110 / 0.3) !important;
}

.tw-placeholder-teal-700\/35::placeholder {
  color: rgb(15 118 110 / 0.35) !important;
}

.tw-placeholder-teal-700\/40::placeholder {
  color: rgb(15 118 110 / 0.4) !important;
}

.tw-placeholder-teal-700\/45::placeholder {
  color: rgb(15 118 110 / 0.45) !important;
}

.tw-placeholder-teal-700\/5::placeholder {
  color: rgb(15 118 110 / 0.05) !important;
}

.tw-placeholder-teal-700\/50::placeholder {
  color: rgb(15 118 110 / 0.5) !important;
}

.tw-placeholder-teal-700\/55::placeholder {
  color: rgb(15 118 110 / 0.55) !important;
}

.tw-placeholder-teal-700\/60::placeholder {
  color: rgb(15 118 110 / 0.6) !important;
}

.tw-placeholder-teal-700\/65::placeholder {
  color: rgb(15 118 110 / 0.65) !important;
}

.tw-placeholder-teal-700\/70::placeholder {
  color: rgb(15 118 110 / 0.7) !important;
}

.tw-placeholder-teal-700\/75::placeholder {
  color: rgb(15 118 110 / 0.75) !important;
}

.tw-placeholder-teal-700\/80::placeholder {
  color: rgb(15 118 110 / 0.8) !important;
}

.tw-placeholder-teal-700\/85::placeholder {
  color: rgb(15 118 110 / 0.85) !important;
}

.tw-placeholder-teal-700\/90::placeholder {
  color: rgb(15 118 110 / 0.9) !important;
}

.tw-placeholder-teal-700\/95::placeholder {
  color: rgb(15 118 110 / 0.95) !important;
}

.tw-placeholder-teal-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(17 94 89 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-800\/0::placeholder {
  color: rgb(17 94 89 / 0) !important;
}

.tw-placeholder-teal-800\/10::placeholder {
  color: rgb(17 94 89 / 0.1) !important;
}

.tw-placeholder-teal-800\/100::placeholder {
  color: rgb(17 94 89 / 1) !important;
}

.tw-placeholder-teal-800\/15::placeholder {
  color: rgb(17 94 89 / 0.15) !important;
}

.tw-placeholder-teal-800\/20::placeholder {
  color: rgb(17 94 89 / 0.2) !important;
}

.tw-placeholder-teal-800\/25::placeholder {
  color: rgb(17 94 89 / 0.25) !important;
}

.tw-placeholder-teal-800\/30::placeholder {
  color: rgb(17 94 89 / 0.3) !important;
}

.tw-placeholder-teal-800\/35::placeholder {
  color: rgb(17 94 89 / 0.35) !important;
}

.tw-placeholder-teal-800\/40::placeholder {
  color: rgb(17 94 89 / 0.4) !important;
}

.tw-placeholder-teal-800\/45::placeholder {
  color: rgb(17 94 89 / 0.45) !important;
}

.tw-placeholder-teal-800\/5::placeholder {
  color: rgb(17 94 89 / 0.05) !important;
}

.tw-placeholder-teal-800\/50::placeholder {
  color: rgb(17 94 89 / 0.5) !important;
}

.tw-placeholder-teal-800\/55::placeholder {
  color: rgb(17 94 89 / 0.55) !important;
}

.tw-placeholder-teal-800\/60::placeholder {
  color: rgb(17 94 89 / 0.6) !important;
}

.tw-placeholder-teal-800\/65::placeholder {
  color: rgb(17 94 89 / 0.65) !important;
}

.tw-placeholder-teal-800\/70::placeholder {
  color: rgb(17 94 89 / 0.7) !important;
}

.tw-placeholder-teal-800\/75::placeholder {
  color: rgb(17 94 89 / 0.75) !important;
}

.tw-placeholder-teal-800\/80::placeholder {
  color: rgb(17 94 89 / 0.8) !important;
}

.tw-placeholder-teal-800\/85::placeholder {
  color: rgb(17 94 89 / 0.85) !important;
}

.tw-placeholder-teal-800\/90::placeholder {
  color: rgb(17 94 89 / 0.9) !important;
}

.tw-placeholder-teal-800\/95::placeholder {
  color: rgb(17 94 89 / 0.95) !important;
}

.tw-placeholder-teal-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(19 78 74 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-900\/0::placeholder {
  color: rgb(19 78 74 / 0) !important;
}

.tw-placeholder-teal-900\/10::placeholder {
  color: rgb(19 78 74 / 0.1) !important;
}

.tw-placeholder-teal-900\/100::placeholder {
  color: rgb(19 78 74 / 1) !important;
}

.tw-placeholder-teal-900\/15::placeholder {
  color: rgb(19 78 74 / 0.15) !important;
}

.tw-placeholder-teal-900\/20::placeholder {
  color: rgb(19 78 74 / 0.2) !important;
}

.tw-placeholder-teal-900\/25::placeholder {
  color: rgb(19 78 74 / 0.25) !important;
}

.tw-placeholder-teal-900\/30::placeholder {
  color: rgb(19 78 74 / 0.3) !important;
}

.tw-placeholder-teal-900\/35::placeholder {
  color: rgb(19 78 74 / 0.35) !important;
}

.tw-placeholder-teal-900\/40::placeholder {
  color: rgb(19 78 74 / 0.4) !important;
}

.tw-placeholder-teal-900\/45::placeholder {
  color: rgb(19 78 74 / 0.45) !important;
}

.tw-placeholder-teal-900\/5::placeholder {
  color: rgb(19 78 74 / 0.05) !important;
}

.tw-placeholder-teal-900\/50::placeholder {
  color: rgb(19 78 74 / 0.5) !important;
}

.tw-placeholder-teal-900\/55::placeholder {
  color: rgb(19 78 74 / 0.55) !important;
}

.tw-placeholder-teal-900\/60::placeholder {
  color: rgb(19 78 74 / 0.6) !important;
}

.tw-placeholder-teal-900\/65::placeholder {
  color: rgb(19 78 74 / 0.65) !important;
}

.tw-placeholder-teal-900\/70::placeholder {
  color: rgb(19 78 74 / 0.7) !important;
}

.tw-placeholder-teal-900\/75::placeholder {
  color: rgb(19 78 74 / 0.75) !important;
}

.tw-placeholder-teal-900\/80::placeholder {
  color: rgb(19 78 74 / 0.8) !important;
}

.tw-placeholder-teal-900\/85::placeholder {
  color: rgb(19 78 74 / 0.85) !important;
}

.tw-placeholder-teal-900\/90::placeholder {
  color: rgb(19 78 74 / 0.9) !important;
}

.tw-placeholder-teal-900\/95::placeholder {
  color: rgb(19 78 74 / 0.95) !important;
}

.tw-placeholder-teal-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(4 47 46 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-teal-950\/0::placeholder {
  color: rgb(4 47 46 / 0) !important;
}

.tw-placeholder-teal-950\/10::placeholder {
  color: rgb(4 47 46 / 0.1) !important;
}

.tw-placeholder-teal-950\/100::placeholder {
  color: rgb(4 47 46 / 1) !important;
}

.tw-placeholder-teal-950\/15::placeholder {
  color: rgb(4 47 46 / 0.15) !important;
}

.tw-placeholder-teal-950\/20::placeholder {
  color: rgb(4 47 46 / 0.2) !important;
}

.tw-placeholder-teal-950\/25::placeholder {
  color: rgb(4 47 46 / 0.25) !important;
}

.tw-placeholder-teal-950\/30::placeholder {
  color: rgb(4 47 46 / 0.3) !important;
}

.tw-placeholder-teal-950\/35::placeholder {
  color: rgb(4 47 46 / 0.35) !important;
}

.tw-placeholder-teal-950\/40::placeholder {
  color: rgb(4 47 46 / 0.4) !important;
}

.tw-placeholder-teal-950\/45::placeholder {
  color: rgb(4 47 46 / 0.45) !important;
}

.tw-placeholder-teal-950\/5::placeholder {
  color: rgb(4 47 46 / 0.05) !important;
}

.tw-placeholder-teal-950\/50::placeholder {
  color: rgb(4 47 46 / 0.5) !important;
}

.tw-placeholder-teal-950\/55::placeholder {
  color: rgb(4 47 46 / 0.55) !important;
}

.tw-placeholder-teal-950\/60::placeholder {
  color: rgb(4 47 46 / 0.6) !important;
}

.tw-placeholder-teal-950\/65::placeholder {
  color: rgb(4 47 46 / 0.65) !important;
}

.tw-placeholder-teal-950\/70::placeholder {
  color: rgb(4 47 46 / 0.7) !important;
}

.tw-placeholder-teal-950\/75::placeholder {
  color: rgb(4 47 46 / 0.75) !important;
}

.tw-placeholder-teal-950\/80::placeholder {
  color: rgb(4 47 46 / 0.8) !important;
}

.tw-placeholder-teal-950\/85::placeholder {
  color: rgb(4 47 46 / 0.85) !important;
}

.tw-placeholder-teal-950\/90::placeholder {
  color: rgb(4 47 46 / 0.9) !important;
}

.tw-placeholder-teal-950\/95::placeholder {
  color: rgb(4 47 46 / 0.95) !important;
}

.tw-placeholder-transparent::placeholder {
  color: transparent !important;
}

.tw-placeholder-transparent\/0::placeholder {
  color: rgb(0 0 0 / 0) !important;
}

.tw-placeholder-transparent\/10::placeholder {
  color: rgb(0 0 0 / 0.1) !important;
}

.tw-placeholder-transparent\/100::placeholder {
  color: rgb(0 0 0 / 1) !important;
}

.tw-placeholder-transparent\/15::placeholder {
  color: rgb(0 0 0 / 0.15) !important;
}

.tw-placeholder-transparent\/20::placeholder {
  color: rgb(0 0 0 / 0.2) !important;
}

.tw-placeholder-transparent\/25::placeholder {
  color: rgb(0 0 0 / 0.25) !important;
}

.tw-placeholder-transparent\/30::placeholder {
  color: rgb(0 0 0 / 0.3) !important;
}

.tw-placeholder-transparent\/35::placeholder {
  color: rgb(0 0 0 / 0.35) !important;
}

.tw-placeholder-transparent\/40::placeholder {
  color: rgb(0 0 0 / 0.4) !important;
}

.tw-placeholder-transparent\/45::placeholder {
  color: rgb(0 0 0 / 0.45) !important;
}

.tw-placeholder-transparent\/5::placeholder {
  color: rgb(0 0 0 / 0.05) !important;
}

.tw-placeholder-transparent\/50::placeholder {
  color: rgb(0 0 0 / 0.5) !important;
}

.tw-placeholder-transparent\/55::placeholder {
  color: rgb(0 0 0 / 0.55) !important;
}

.tw-placeholder-transparent\/60::placeholder {
  color: rgb(0 0 0 / 0.6) !important;
}

.tw-placeholder-transparent\/65::placeholder {
  color: rgb(0 0 0 / 0.65) !important;
}

.tw-placeholder-transparent\/70::placeholder {
  color: rgb(0 0 0 / 0.7) !important;
}

.tw-placeholder-transparent\/75::placeholder {
  color: rgb(0 0 0 / 0.75) !important;
}

.tw-placeholder-transparent\/80::placeholder {
  color: rgb(0 0 0 / 0.8) !important;
}

.tw-placeholder-transparent\/85::placeholder {
  color: rgb(0 0 0 / 0.85) !important;
}

.tw-placeholder-transparent\/90::placeholder {
  color: rgb(0 0 0 / 0.9) !important;
}

.tw-placeholder-transparent\/95::placeholder {
  color: rgb(0 0 0 / 0.95) !important;
}

.tw-placeholder-violet-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(237 233 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-100\/0::placeholder {
  color: rgb(237 233 254 / 0) !important;
}

.tw-placeholder-violet-100\/10::placeholder {
  color: rgb(237 233 254 / 0.1) !important;
}

.tw-placeholder-violet-100\/100::placeholder {
  color: rgb(237 233 254 / 1) !important;
}

.tw-placeholder-violet-100\/15::placeholder {
  color: rgb(237 233 254 / 0.15) !important;
}

.tw-placeholder-violet-100\/20::placeholder {
  color: rgb(237 233 254 / 0.2) !important;
}

.tw-placeholder-violet-100\/25::placeholder {
  color: rgb(237 233 254 / 0.25) !important;
}

.tw-placeholder-violet-100\/30::placeholder {
  color: rgb(237 233 254 / 0.3) !important;
}

.tw-placeholder-violet-100\/35::placeholder {
  color: rgb(237 233 254 / 0.35) !important;
}

.tw-placeholder-violet-100\/40::placeholder {
  color: rgb(237 233 254 / 0.4) !important;
}

.tw-placeholder-violet-100\/45::placeholder {
  color: rgb(237 233 254 / 0.45) !important;
}

.tw-placeholder-violet-100\/5::placeholder {
  color: rgb(237 233 254 / 0.05) !important;
}

.tw-placeholder-violet-100\/50::placeholder {
  color: rgb(237 233 254 / 0.5) !important;
}

.tw-placeholder-violet-100\/55::placeholder {
  color: rgb(237 233 254 / 0.55) !important;
}

.tw-placeholder-violet-100\/60::placeholder {
  color: rgb(237 233 254 / 0.6) !important;
}

.tw-placeholder-violet-100\/65::placeholder {
  color: rgb(237 233 254 / 0.65) !important;
}

.tw-placeholder-violet-100\/70::placeholder {
  color: rgb(237 233 254 / 0.7) !important;
}

.tw-placeholder-violet-100\/75::placeholder {
  color: rgb(237 233 254 / 0.75) !important;
}

.tw-placeholder-violet-100\/80::placeholder {
  color: rgb(237 233 254 / 0.8) !important;
}

.tw-placeholder-violet-100\/85::placeholder {
  color: rgb(237 233 254 / 0.85) !important;
}

.tw-placeholder-violet-100\/90::placeholder {
  color: rgb(237 233 254 / 0.9) !important;
}

.tw-placeholder-violet-100\/95::placeholder {
  color: rgb(237 233 254 / 0.95) !important;
}

.tw-placeholder-violet-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(221 214 254 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-200\/0::placeholder {
  color: rgb(221 214 254 / 0) !important;
}

.tw-placeholder-violet-200\/10::placeholder {
  color: rgb(221 214 254 / 0.1) !important;
}

.tw-placeholder-violet-200\/100::placeholder {
  color: rgb(221 214 254 / 1) !important;
}

.tw-placeholder-violet-200\/15::placeholder {
  color: rgb(221 214 254 / 0.15) !important;
}

.tw-placeholder-violet-200\/20::placeholder {
  color: rgb(221 214 254 / 0.2) !important;
}

.tw-placeholder-violet-200\/25::placeholder {
  color: rgb(221 214 254 / 0.25) !important;
}

.tw-placeholder-violet-200\/30::placeholder {
  color: rgb(221 214 254 / 0.3) !important;
}

.tw-placeholder-violet-200\/35::placeholder {
  color: rgb(221 214 254 / 0.35) !important;
}

.tw-placeholder-violet-200\/40::placeholder {
  color: rgb(221 214 254 / 0.4) !important;
}

.tw-placeholder-violet-200\/45::placeholder {
  color: rgb(221 214 254 / 0.45) !important;
}

.tw-placeholder-violet-200\/5::placeholder {
  color: rgb(221 214 254 / 0.05) !important;
}

.tw-placeholder-violet-200\/50::placeholder {
  color: rgb(221 214 254 / 0.5) !important;
}

.tw-placeholder-violet-200\/55::placeholder {
  color: rgb(221 214 254 / 0.55) !important;
}

.tw-placeholder-violet-200\/60::placeholder {
  color: rgb(221 214 254 / 0.6) !important;
}

.tw-placeholder-violet-200\/65::placeholder {
  color: rgb(221 214 254 / 0.65) !important;
}

.tw-placeholder-violet-200\/70::placeholder {
  color: rgb(221 214 254 / 0.7) !important;
}

.tw-placeholder-violet-200\/75::placeholder {
  color: rgb(221 214 254 / 0.75) !important;
}

.tw-placeholder-violet-200\/80::placeholder {
  color: rgb(221 214 254 / 0.8) !important;
}

.tw-placeholder-violet-200\/85::placeholder {
  color: rgb(221 214 254 / 0.85) !important;
}

.tw-placeholder-violet-200\/90::placeholder {
  color: rgb(221 214 254 / 0.9) !important;
}

.tw-placeholder-violet-200\/95::placeholder {
  color: rgb(221 214 254 / 0.95) !important;
}

.tw-placeholder-violet-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(196 181 253 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-300\/0::placeholder {
  color: rgb(196 181 253 / 0) !important;
}

.tw-placeholder-violet-300\/10::placeholder {
  color: rgb(196 181 253 / 0.1) !important;
}

.tw-placeholder-violet-300\/100::placeholder {
  color: rgb(196 181 253 / 1) !important;
}

.tw-placeholder-violet-300\/15::placeholder {
  color: rgb(196 181 253 / 0.15) !important;
}

.tw-placeholder-violet-300\/20::placeholder {
  color: rgb(196 181 253 / 0.2) !important;
}

.tw-placeholder-violet-300\/25::placeholder {
  color: rgb(196 181 253 / 0.25) !important;
}

.tw-placeholder-violet-300\/30::placeholder {
  color: rgb(196 181 253 / 0.3) !important;
}

.tw-placeholder-violet-300\/35::placeholder {
  color: rgb(196 181 253 / 0.35) !important;
}

.tw-placeholder-violet-300\/40::placeholder {
  color: rgb(196 181 253 / 0.4) !important;
}

.tw-placeholder-violet-300\/45::placeholder {
  color: rgb(196 181 253 / 0.45) !important;
}

.tw-placeholder-violet-300\/5::placeholder {
  color: rgb(196 181 253 / 0.05) !important;
}

.tw-placeholder-violet-300\/50::placeholder {
  color: rgb(196 181 253 / 0.5) !important;
}

.tw-placeholder-violet-300\/55::placeholder {
  color: rgb(196 181 253 / 0.55) !important;
}

.tw-placeholder-violet-300\/60::placeholder {
  color: rgb(196 181 253 / 0.6) !important;
}

.tw-placeholder-violet-300\/65::placeholder {
  color: rgb(196 181 253 / 0.65) !important;
}

.tw-placeholder-violet-300\/70::placeholder {
  color: rgb(196 181 253 / 0.7) !important;
}

.tw-placeholder-violet-300\/75::placeholder {
  color: rgb(196 181 253 / 0.75) !important;
}

.tw-placeholder-violet-300\/80::placeholder {
  color: rgb(196 181 253 / 0.8) !important;
}

.tw-placeholder-violet-300\/85::placeholder {
  color: rgb(196 181 253 / 0.85) !important;
}

.tw-placeholder-violet-300\/90::placeholder {
  color: rgb(196 181 253 / 0.9) !important;
}

.tw-placeholder-violet-300\/95::placeholder {
  color: rgb(196 181 253 / 0.95) !important;
}

.tw-placeholder-violet-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(167 139 250 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-400\/0::placeholder {
  color: rgb(167 139 250 / 0) !important;
}

.tw-placeholder-violet-400\/10::placeholder {
  color: rgb(167 139 250 / 0.1) !important;
}

.tw-placeholder-violet-400\/100::placeholder {
  color: rgb(167 139 250 / 1) !important;
}

.tw-placeholder-violet-400\/15::placeholder {
  color: rgb(167 139 250 / 0.15) !important;
}

.tw-placeholder-violet-400\/20::placeholder {
  color: rgb(167 139 250 / 0.2) !important;
}

.tw-placeholder-violet-400\/25::placeholder {
  color: rgb(167 139 250 / 0.25) !important;
}

.tw-placeholder-violet-400\/30::placeholder {
  color: rgb(167 139 250 / 0.3) !important;
}

.tw-placeholder-violet-400\/35::placeholder {
  color: rgb(167 139 250 / 0.35) !important;
}

.tw-placeholder-violet-400\/40::placeholder {
  color: rgb(167 139 250 / 0.4) !important;
}

.tw-placeholder-violet-400\/45::placeholder {
  color: rgb(167 139 250 / 0.45) !important;
}

.tw-placeholder-violet-400\/5::placeholder {
  color: rgb(167 139 250 / 0.05) !important;
}

.tw-placeholder-violet-400\/50::placeholder {
  color: rgb(167 139 250 / 0.5) !important;
}

.tw-placeholder-violet-400\/55::placeholder {
  color: rgb(167 139 250 / 0.55) !important;
}

.tw-placeholder-violet-400\/60::placeholder {
  color: rgb(167 139 250 / 0.6) !important;
}

.tw-placeholder-violet-400\/65::placeholder {
  color: rgb(167 139 250 / 0.65) !important;
}

.tw-placeholder-violet-400\/70::placeholder {
  color: rgb(167 139 250 / 0.7) !important;
}

.tw-placeholder-violet-400\/75::placeholder {
  color: rgb(167 139 250 / 0.75) !important;
}

.tw-placeholder-violet-400\/80::placeholder {
  color: rgb(167 139 250 / 0.8) !important;
}

.tw-placeholder-violet-400\/85::placeholder {
  color: rgb(167 139 250 / 0.85) !important;
}

.tw-placeholder-violet-400\/90::placeholder {
  color: rgb(167 139 250 / 0.9) !important;
}

.tw-placeholder-violet-400\/95::placeholder {
  color: rgb(167 139 250 / 0.95) !important;
}

.tw-placeholder-violet-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(245 243 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-50\/0::placeholder {
  color: rgb(245 243 255 / 0) !important;
}

.tw-placeholder-violet-50\/10::placeholder {
  color: rgb(245 243 255 / 0.1) !important;
}

.tw-placeholder-violet-50\/100::placeholder {
  color: rgb(245 243 255 / 1) !important;
}

.tw-placeholder-violet-50\/15::placeholder {
  color: rgb(245 243 255 / 0.15) !important;
}

.tw-placeholder-violet-50\/20::placeholder {
  color: rgb(245 243 255 / 0.2) !important;
}

.tw-placeholder-violet-50\/25::placeholder {
  color: rgb(245 243 255 / 0.25) !important;
}

.tw-placeholder-violet-50\/30::placeholder {
  color: rgb(245 243 255 / 0.3) !important;
}

.tw-placeholder-violet-50\/35::placeholder {
  color: rgb(245 243 255 / 0.35) !important;
}

.tw-placeholder-violet-50\/40::placeholder {
  color: rgb(245 243 255 / 0.4) !important;
}

.tw-placeholder-violet-50\/45::placeholder {
  color: rgb(245 243 255 / 0.45) !important;
}

.tw-placeholder-violet-50\/5::placeholder {
  color: rgb(245 243 255 / 0.05) !important;
}

.tw-placeholder-violet-50\/50::placeholder {
  color: rgb(245 243 255 / 0.5) !important;
}

.tw-placeholder-violet-50\/55::placeholder {
  color: rgb(245 243 255 / 0.55) !important;
}

.tw-placeholder-violet-50\/60::placeholder {
  color: rgb(245 243 255 / 0.6) !important;
}

.tw-placeholder-violet-50\/65::placeholder {
  color: rgb(245 243 255 / 0.65) !important;
}

.tw-placeholder-violet-50\/70::placeholder {
  color: rgb(245 243 255 / 0.7) !important;
}

.tw-placeholder-violet-50\/75::placeholder {
  color: rgb(245 243 255 / 0.75) !important;
}

.tw-placeholder-violet-50\/80::placeholder {
  color: rgb(245 243 255 / 0.8) !important;
}

.tw-placeholder-violet-50\/85::placeholder {
  color: rgb(245 243 255 / 0.85) !important;
}

.tw-placeholder-violet-50\/90::placeholder {
  color: rgb(245 243 255 / 0.9) !important;
}

.tw-placeholder-violet-50\/95::placeholder {
  color: rgb(245 243 255 / 0.95) !important;
}

.tw-placeholder-violet-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(139 92 246 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-500\/0::placeholder {
  color: rgb(139 92 246 / 0) !important;
}

.tw-placeholder-violet-500\/10::placeholder {
  color: rgb(139 92 246 / 0.1) !important;
}

.tw-placeholder-violet-500\/100::placeholder {
  color: rgb(139 92 246 / 1) !important;
}

.tw-placeholder-violet-500\/15::placeholder {
  color: rgb(139 92 246 / 0.15) !important;
}

.tw-placeholder-violet-500\/20::placeholder {
  color: rgb(139 92 246 / 0.2) !important;
}

.tw-placeholder-violet-500\/25::placeholder {
  color: rgb(139 92 246 / 0.25) !important;
}

.tw-placeholder-violet-500\/30::placeholder {
  color: rgb(139 92 246 / 0.3) !important;
}

.tw-placeholder-violet-500\/35::placeholder {
  color: rgb(139 92 246 / 0.35) !important;
}

.tw-placeholder-violet-500\/40::placeholder {
  color: rgb(139 92 246 / 0.4) !important;
}

.tw-placeholder-violet-500\/45::placeholder {
  color: rgb(139 92 246 / 0.45) !important;
}

.tw-placeholder-violet-500\/5::placeholder {
  color: rgb(139 92 246 / 0.05) !important;
}

.tw-placeholder-violet-500\/50::placeholder {
  color: rgb(139 92 246 / 0.5) !important;
}

.tw-placeholder-violet-500\/55::placeholder {
  color: rgb(139 92 246 / 0.55) !important;
}

.tw-placeholder-violet-500\/60::placeholder {
  color: rgb(139 92 246 / 0.6) !important;
}

.tw-placeholder-violet-500\/65::placeholder {
  color: rgb(139 92 246 / 0.65) !important;
}

.tw-placeholder-violet-500\/70::placeholder {
  color: rgb(139 92 246 / 0.7) !important;
}

.tw-placeholder-violet-500\/75::placeholder {
  color: rgb(139 92 246 / 0.75) !important;
}

.tw-placeholder-violet-500\/80::placeholder {
  color: rgb(139 92 246 / 0.8) !important;
}

.tw-placeholder-violet-500\/85::placeholder {
  color: rgb(139 92 246 / 0.85) !important;
}

.tw-placeholder-violet-500\/90::placeholder {
  color: rgb(139 92 246 / 0.9) !important;
}

.tw-placeholder-violet-500\/95::placeholder {
  color: rgb(139 92 246 / 0.95) !important;
}

.tw-placeholder-violet-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(124 58 237 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-600\/0::placeholder {
  color: rgb(124 58 237 / 0) !important;
}

.tw-placeholder-violet-600\/10::placeholder {
  color: rgb(124 58 237 / 0.1) !important;
}

.tw-placeholder-violet-600\/100::placeholder {
  color: rgb(124 58 237 / 1) !important;
}

.tw-placeholder-violet-600\/15::placeholder {
  color: rgb(124 58 237 / 0.15) !important;
}

.tw-placeholder-violet-600\/20::placeholder {
  color: rgb(124 58 237 / 0.2) !important;
}

.tw-placeholder-violet-600\/25::placeholder {
  color: rgb(124 58 237 / 0.25) !important;
}

.tw-placeholder-violet-600\/30::placeholder {
  color: rgb(124 58 237 / 0.3) !important;
}

.tw-placeholder-violet-600\/35::placeholder {
  color: rgb(124 58 237 / 0.35) !important;
}

.tw-placeholder-violet-600\/40::placeholder {
  color: rgb(124 58 237 / 0.4) !important;
}

.tw-placeholder-violet-600\/45::placeholder {
  color: rgb(124 58 237 / 0.45) !important;
}

.tw-placeholder-violet-600\/5::placeholder {
  color: rgb(124 58 237 / 0.05) !important;
}

.tw-placeholder-violet-600\/50::placeholder {
  color: rgb(124 58 237 / 0.5) !important;
}

.tw-placeholder-violet-600\/55::placeholder {
  color: rgb(124 58 237 / 0.55) !important;
}

.tw-placeholder-violet-600\/60::placeholder {
  color: rgb(124 58 237 / 0.6) !important;
}

.tw-placeholder-violet-600\/65::placeholder {
  color: rgb(124 58 237 / 0.65) !important;
}

.tw-placeholder-violet-600\/70::placeholder {
  color: rgb(124 58 237 / 0.7) !important;
}

.tw-placeholder-violet-600\/75::placeholder {
  color: rgb(124 58 237 / 0.75) !important;
}

.tw-placeholder-violet-600\/80::placeholder {
  color: rgb(124 58 237 / 0.8) !important;
}

.tw-placeholder-violet-600\/85::placeholder {
  color: rgb(124 58 237 / 0.85) !important;
}

.tw-placeholder-violet-600\/90::placeholder {
  color: rgb(124 58 237 / 0.9) !important;
}

.tw-placeholder-violet-600\/95::placeholder {
  color: rgb(124 58 237 / 0.95) !important;
}

.tw-placeholder-violet-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(109 40 217 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-700\/0::placeholder {
  color: rgb(109 40 217 / 0) !important;
}

.tw-placeholder-violet-700\/10::placeholder {
  color: rgb(109 40 217 / 0.1) !important;
}

.tw-placeholder-violet-700\/100::placeholder {
  color: rgb(109 40 217 / 1) !important;
}

.tw-placeholder-violet-700\/15::placeholder {
  color: rgb(109 40 217 / 0.15) !important;
}

.tw-placeholder-violet-700\/20::placeholder {
  color: rgb(109 40 217 / 0.2) !important;
}

.tw-placeholder-violet-700\/25::placeholder {
  color: rgb(109 40 217 / 0.25) !important;
}

.tw-placeholder-violet-700\/30::placeholder {
  color: rgb(109 40 217 / 0.3) !important;
}

.tw-placeholder-violet-700\/35::placeholder {
  color: rgb(109 40 217 / 0.35) !important;
}

.tw-placeholder-violet-700\/40::placeholder {
  color: rgb(109 40 217 / 0.4) !important;
}

.tw-placeholder-violet-700\/45::placeholder {
  color: rgb(109 40 217 / 0.45) !important;
}

.tw-placeholder-violet-700\/5::placeholder {
  color: rgb(109 40 217 / 0.05) !important;
}

.tw-placeholder-violet-700\/50::placeholder {
  color: rgb(109 40 217 / 0.5) !important;
}

.tw-placeholder-violet-700\/55::placeholder {
  color: rgb(109 40 217 / 0.55) !important;
}

.tw-placeholder-violet-700\/60::placeholder {
  color: rgb(109 40 217 / 0.6) !important;
}

.tw-placeholder-violet-700\/65::placeholder {
  color: rgb(109 40 217 / 0.65) !important;
}

.tw-placeholder-violet-700\/70::placeholder {
  color: rgb(109 40 217 / 0.7) !important;
}

.tw-placeholder-violet-700\/75::placeholder {
  color: rgb(109 40 217 / 0.75) !important;
}

.tw-placeholder-violet-700\/80::placeholder {
  color: rgb(109 40 217 / 0.8) !important;
}

.tw-placeholder-violet-700\/85::placeholder {
  color: rgb(109 40 217 / 0.85) !important;
}

.tw-placeholder-violet-700\/90::placeholder {
  color: rgb(109 40 217 / 0.9) !important;
}

.tw-placeholder-violet-700\/95::placeholder {
  color: rgb(109 40 217 / 0.95) !important;
}

.tw-placeholder-violet-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(91 33 182 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-800\/0::placeholder {
  color: rgb(91 33 182 / 0) !important;
}

.tw-placeholder-violet-800\/10::placeholder {
  color: rgb(91 33 182 / 0.1) !important;
}

.tw-placeholder-violet-800\/100::placeholder {
  color: rgb(91 33 182 / 1) !important;
}

.tw-placeholder-violet-800\/15::placeholder {
  color: rgb(91 33 182 / 0.15) !important;
}

.tw-placeholder-violet-800\/20::placeholder {
  color: rgb(91 33 182 / 0.2) !important;
}

.tw-placeholder-violet-800\/25::placeholder {
  color: rgb(91 33 182 / 0.25) !important;
}

.tw-placeholder-violet-800\/30::placeholder {
  color: rgb(91 33 182 / 0.3) !important;
}

.tw-placeholder-violet-800\/35::placeholder {
  color: rgb(91 33 182 / 0.35) !important;
}

.tw-placeholder-violet-800\/40::placeholder {
  color: rgb(91 33 182 / 0.4) !important;
}

.tw-placeholder-violet-800\/45::placeholder {
  color: rgb(91 33 182 / 0.45) !important;
}

.tw-placeholder-violet-800\/5::placeholder {
  color: rgb(91 33 182 / 0.05) !important;
}

.tw-placeholder-violet-800\/50::placeholder {
  color: rgb(91 33 182 / 0.5) !important;
}

.tw-placeholder-violet-800\/55::placeholder {
  color: rgb(91 33 182 / 0.55) !important;
}

.tw-placeholder-violet-800\/60::placeholder {
  color: rgb(91 33 182 / 0.6) !important;
}

.tw-placeholder-violet-800\/65::placeholder {
  color: rgb(91 33 182 / 0.65) !important;
}

.tw-placeholder-violet-800\/70::placeholder {
  color: rgb(91 33 182 / 0.7) !important;
}

.tw-placeholder-violet-800\/75::placeholder {
  color: rgb(91 33 182 / 0.75) !important;
}

.tw-placeholder-violet-800\/80::placeholder {
  color: rgb(91 33 182 / 0.8) !important;
}

.tw-placeholder-violet-800\/85::placeholder {
  color: rgb(91 33 182 / 0.85) !important;
}

.tw-placeholder-violet-800\/90::placeholder {
  color: rgb(91 33 182 / 0.9) !important;
}

.tw-placeholder-violet-800\/95::placeholder {
  color: rgb(91 33 182 / 0.95) !important;
}

.tw-placeholder-violet-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(76 29 149 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-900\/0::placeholder {
  color: rgb(76 29 149 / 0) !important;
}

.tw-placeholder-violet-900\/10::placeholder {
  color: rgb(76 29 149 / 0.1) !important;
}

.tw-placeholder-violet-900\/100::placeholder {
  color: rgb(76 29 149 / 1) !important;
}

.tw-placeholder-violet-900\/15::placeholder {
  color: rgb(76 29 149 / 0.15) !important;
}

.tw-placeholder-violet-900\/20::placeholder {
  color: rgb(76 29 149 / 0.2) !important;
}

.tw-placeholder-violet-900\/25::placeholder {
  color: rgb(76 29 149 / 0.25) !important;
}

.tw-placeholder-violet-900\/30::placeholder {
  color: rgb(76 29 149 / 0.3) !important;
}

.tw-placeholder-violet-900\/35::placeholder {
  color: rgb(76 29 149 / 0.35) !important;
}

.tw-placeholder-violet-900\/40::placeholder {
  color: rgb(76 29 149 / 0.4) !important;
}

.tw-placeholder-violet-900\/45::placeholder {
  color: rgb(76 29 149 / 0.45) !important;
}

.tw-placeholder-violet-900\/5::placeholder {
  color: rgb(76 29 149 / 0.05) !important;
}

.tw-placeholder-violet-900\/50::placeholder {
  color: rgb(76 29 149 / 0.5) !important;
}

.tw-placeholder-violet-900\/55::placeholder {
  color: rgb(76 29 149 / 0.55) !important;
}

.tw-placeholder-violet-900\/60::placeholder {
  color: rgb(76 29 149 / 0.6) !important;
}

.tw-placeholder-violet-900\/65::placeholder {
  color: rgb(76 29 149 / 0.65) !important;
}

.tw-placeholder-violet-900\/70::placeholder {
  color: rgb(76 29 149 / 0.7) !important;
}

.tw-placeholder-violet-900\/75::placeholder {
  color: rgb(76 29 149 / 0.75) !important;
}

.tw-placeholder-violet-900\/80::placeholder {
  color: rgb(76 29 149 / 0.8) !important;
}

.tw-placeholder-violet-900\/85::placeholder {
  color: rgb(76 29 149 / 0.85) !important;
}

.tw-placeholder-violet-900\/90::placeholder {
  color: rgb(76 29 149 / 0.9) !important;
}

.tw-placeholder-violet-900\/95::placeholder {
  color: rgb(76 29 149 / 0.95) !important;
}

.tw-placeholder-violet-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(46 16 101 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-violet-950\/0::placeholder {
  color: rgb(46 16 101 / 0) !important;
}

.tw-placeholder-violet-950\/10::placeholder {
  color: rgb(46 16 101 / 0.1) !important;
}

.tw-placeholder-violet-950\/100::placeholder {
  color: rgb(46 16 101 / 1) !important;
}

.tw-placeholder-violet-950\/15::placeholder {
  color: rgb(46 16 101 / 0.15) !important;
}

.tw-placeholder-violet-950\/20::placeholder {
  color: rgb(46 16 101 / 0.2) !important;
}

.tw-placeholder-violet-950\/25::placeholder {
  color: rgb(46 16 101 / 0.25) !important;
}

.tw-placeholder-violet-950\/30::placeholder {
  color: rgb(46 16 101 / 0.3) !important;
}

.tw-placeholder-violet-950\/35::placeholder {
  color: rgb(46 16 101 / 0.35) !important;
}

.tw-placeholder-violet-950\/40::placeholder {
  color: rgb(46 16 101 / 0.4) !important;
}

.tw-placeholder-violet-950\/45::placeholder {
  color: rgb(46 16 101 / 0.45) !important;
}

.tw-placeholder-violet-950\/5::placeholder {
  color: rgb(46 16 101 / 0.05) !important;
}

.tw-placeholder-violet-950\/50::placeholder {
  color: rgb(46 16 101 / 0.5) !important;
}

.tw-placeholder-violet-950\/55::placeholder {
  color: rgb(46 16 101 / 0.55) !important;
}

.tw-placeholder-violet-950\/60::placeholder {
  color: rgb(46 16 101 / 0.6) !important;
}

.tw-placeholder-violet-950\/65::placeholder {
  color: rgb(46 16 101 / 0.65) !important;
}

.tw-placeholder-violet-950\/70::placeholder {
  color: rgb(46 16 101 / 0.7) !important;
}

.tw-placeholder-violet-950\/75::placeholder {
  color: rgb(46 16 101 / 0.75) !important;
}

.tw-placeholder-violet-950\/80::placeholder {
  color: rgb(46 16 101 / 0.8) !important;
}

.tw-placeholder-violet-950\/85::placeholder {
  color: rgb(46 16 101 / 0.85) !important;
}

.tw-placeholder-violet-950\/90::placeholder {
  color: rgb(46 16 101 / 0.9) !important;
}

.tw-placeholder-violet-950\/95::placeholder {
  color: rgb(46 16 101 / 0.95) !important;
}

.tw-placeholder-white::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(255 255 255 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-white\/0::placeholder {
  color: rgb(255 255 255 / 0) !important;
}

.tw-placeholder-white\/10::placeholder {
  color: rgb(255 255 255 / 0.1) !important;
}

.tw-placeholder-white\/100::placeholder {
  color: rgb(255 255 255 / 1) !important;
}

.tw-placeholder-white\/15::placeholder {
  color: rgb(255 255 255 / 0.15) !important;
}

.tw-placeholder-white\/20::placeholder {
  color: rgb(255 255 255 / 0.2) !important;
}

.tw-placeholder-white\/25::placeholder {
  color: rgb(255 255 255 / 0.25) !important;
}

.tw-placeholder-white\/30::placeholder {
  color: rgb(255 255 255 / 0.3) !important;
}

.tw-placeholder-white\/35::placeholder {
  color: rgb(255 255 255 / 0.35) !important;
}

.tw-placeholder-white\/40::placeholder {
  color: rgb(255 255 255 / 0.4) !important;
}

.tw-placeholder-white\/45::placeholder {
  color: rgb(255 255 255 / 0.45) !important;
}

.tw-placeholder-white\/5::placeholder {
  color: rgb(255 255 255 / 0.05) !important;
}

.tw-placeholder-white\/50::placeholder {
  color: rgb(255 255 255 / 0.5) !important;
}

.tw-placeholder-white\/55::placeholder {
  color: rgb(255 255 255 / 0.55) !important;
}

.tw-placeholder-white\/60::placeholder {
  color: rgb(255 255 255 / 0.6) !important;
}

.tw-placeholder-white\/65::placeholder {
  color: rgb(255 255 255 / 0.65) !important;
}

.tw-placeholder-white\/70::placeholder {
  color: rgb(255 255 255 / 0.7) !important;
}

.tw-placeholder-white\/75::placeholder {
  color: rgb(255 255 255 / 0.75) !important;
}

.tw-placeholder-white\/80::placeholder {
  color: rgb(255 255 255 / 0.8) !important;
}

.tw-placeholder-white\/85::placeholder {
  color: rgb(255 255 255 / 0.85) !important;
}

.tw-placeholder-white\/90::placeholder {
  color: rgb(255 255 255 / 0.9) !important;
}

.tw-placeholder-white\/95::placeholder {
  color: rgb(255 255 255 / 0.95) !important;
}

.tw-placeholder-yellow-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 249 195 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-100\/0::placeholder {
  color: rgb(254 249 195 / 0) !important;
}

.tw-placeholder-yellow-100\/10::placeholder {
  color: rgb(254 249 195 / 0.1) !important;
}

.tw-placeholder-yellow-100\/100::placeholder {
  color: rgb(254 249 195 / 1) !important;
}

.tw-placeholder-yellow-100\/15::placeholder {
  color: rgb(254 249 195 / 0.15) !important;
}

.tw-placeholder-yellow-100\/20::placeholder {
  color: rgb(254 249 195 / 0.2) !important;
}

.tw-placeholder-yellow-100\/25::placeholder {
  color: rgb(254 249 195 / 0.25) !important;
}

.tw-placeholder-yellow-100\/30::placeholder {
  color: rgb(254 249 195 / 0.3) !important;
}

.tw-placeholder-yellow-100\/35::placeholder {
  color: rgb(254 249 195 / 0.35) !important;
}

.tw-placeholder-yellow-100\/40::placeholder {
  color: rgb(254 249 195 / 0.4) !important;
}

.tw-placeholder-yellow-100\/45::placeholder {
  color: rgb(254 249 195 / 0.45) !important;
}

.tw-placeholder-yellow-100\/5::placeholder {
  color: rgb(254 249 195 / 0.05) !important;
}

.tw-placeholder-yellow-100\/50::placeholder {
  color: rgb(254 249 195 / 0.5) !important;
}

.tw-placeholder-yellow-100\/55::placeholder {
  color: rgb(254 249 195 / 0.55) !important;
}

.tw-placeholder-yellow-100\/60::placeholder {
  color: rgb(254 249 195 / 0.6) !important;
}

.tw-placeholder-yellow-100\/65::placeholder {
  color: rgb(254 249 195 / 0.65) !important;
}

.tw-placeholder-yellow-100\/70::placeholder {
  color: rgb(254 249 195 / 0.7) !important;
}

.tw-placeholder-yellow-100\/75::placeholder {
  color: rgb(254 249 195 / 0.75) !important;
}

.tw-placeholder-yellow-100\/80::placeholder {
  color: rgb(254 249 195 / 0.8) !important;
}

.tw-placeholder-yellow-100\/85::placeholder {
  color: rgb(254 249 195 / 0.85) !important;
}

.tw-placeholder-yellow-100\/90::placeholder {
  color: rgb(254 249 195 / 0.9) !important;
}

.tw-placeholder-yellow-100\/95::placeholder {
  color: rgb(254 249 195 / 0.95) !important;
}

.tw-placeholder-yellow-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 240 138 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-200\/0::placeholder {
  color: rgb(254 240 138 / 0) !important;
}

.tw-placeholder-yellow-200\/10::placeholder {
  color: rgb(254 240 138 / 0.1) !important;
}

.tw-placeholder-yellow-200\/100::placeholder {
  color: rgb(254 240 138 / 1) !important;
}

.tw-placeholder-yellow-200\/15::placeholder {
  color: rgb(254 240 138 / 0.15) !important;
}

.tw-placeholder-yellow-200\/20::placeholder {
  color: rgb(254 240 138 / 0.2) !important;
}

.tw-placeholder-yellow-200\/25::placeholder {
  color: rgb(254 240 138 / 0.25) !important;
}

.tw-placeholder-yellow-200\/30::placeholder {
  color: rgb(254 240 138 / 0.3) !important;
}

.tw-placeholder-yellow-200\/35::placeholder {
  color: rgb(254 240 138 / 0.35) !important;
}

.tw-placeholder-yellow-200\/40::placeholder {
  color: rgb(254 240 138 / 0.4) !important;
}

.tw-placeholder-yellow-200\/45::placeholder {
  color: rgb(254 240 138 / 0.45) !important;
}

.tw-placeholder-yellow-200\/5::placeholder {
  color: rgb(254 240 138 / 0.05) !important;
}

.tw-placeholder-yellow-200\/50::placeholder {
  color: rgb(254 240 138 / 0.5) !important;
}

.tw-placeholder-yellow-200\/55::placeholder {
  color: rgb(254 240 138 / 0.55) !important;
}

.tw-placeholder-yellow-200\/60::placeholder {
  color: rgb(254 240 138 / 0.6) !important;
}

.tw-placeholder-yellow-200\/65::placeholder {
  color: rgb(254 240 138 / 0.65) !important;
}

.tw-placeholder-yellow-200\/70::placeholder {
  color: rgb(254 240 138 / 0.7) !important;
}

.tw-placeholder-yellow-200\/75::placeholder {
  color: rgb(254 240 138 / 0.75) !important;
}

.tw-placeholder-yellow-200\/80::placeholder {
  color: rgb(254 240 138 / 0.8) !important;
}

.tw-placeholder-yellow-200\/85::placeholder {
  color: rgb(254 240 138 / 0.85) !important;
}

.tw-placeholder-yellow-200\/90::placeholder {
  color: rgb(254 240 138 / 0.9) !important;
}

.tw-placeholder-yellow-200\/95::placeholder {
  color: rgb(254 240 138 / 0.95) !important;
}

.tw-placeholder-yellow-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(253 224 71 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-300\/0::placeholder {
  color: rgb(253 224 71 / 0) !important;
}

.tw-placeholder-yellow-300\/10::placeholder {
  color: rgb(253 224 71 / 0.1) !important;
}

.tw-placeholder-yellow-300\/100::placeholder {
  color: rgb(253 224 71 / 1) !important;
}

.tw-placeholder-yellow-300\/15::placeholder {
  color: rgb(253 224 71 / 0.15) !important;
}

.tw-placeholder-yellow-300\/20::placeholder {
  color: rgb(253 224 71 / 0.2) !important;
}

.tw-placeholder-yellow-300\/25::placeholder {
  color: rgb(253 224 71 / 0.25) !important;
}

.tw-placeholder-yellow-300\/30::placeholder {
  color: rgb(253 224 71 / 0.3) !important;
}

.tw-placeholder-yellow-300\/35::placeholder {
  color: rgb(253 224 71 / 0.35) !important;
}

.tw-placeholder-yellow-300\/40::placeholder {
  color: rgb(253 224 71 / 0.4) !important;
}

.tw-placeholder-yellow-300\/45::placeholder {
  color: rgb(253 224 71 / 0.45) !important;
}

.tw-placeholder-yellow-300\/5::placeholder {
  color: rgb(253 224 71 / 0.05) !important;
}

.tw-placeholder-yellow-300\/50::placeholder {
  color: rgb(253 224 71 / 0.5) !important;
}

.tw-placeholder-yellow-300\/55::placeholder {
  color: rgb(253 224 71 / 0.55) !important;
}

.tw-placeholder-yellow-300\/60::placeholder {
  color: rgb(253 224 71 / 0.6) !important;
}

.tw-placeholder-yellow-300\/65::placeholder {
  color: rgb(253 224 71 / 0.65) !important;
}

.tw-placeholder-yellow-300\/70::placeholder {
  color: rgb(253 224 71 / 0.7) !important;
}

.tw-placeholder-yellow-300\/75::placeholder {
  color: rgb(253 224 71 / 0.75) !important;
}

.tw-placeholder-yellow-300\/80::placeholder {
  color: rgb(253 224 71 / 0.8) !important;
}

.tw-placeholder-yellow-300\/85::placeholder {
  color: rgb(253 224 71 / 0.85) !important;
}

.tw-placeholder-yellow-300\/90::placeholder {
  color: rgb(253 224 71 / 0.9) !important;
}

.tw-placeholder-yellow-300\/95::placeholder {
  color: rgb(253 224 71 / 0.95) !important;
}

.tw-placeholder-yellow-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(250 204 21 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-400\/0::placeholder {
  color: rgb(250 204 21 / 0) !important;
}

.tw-placeholder-yellow-400\/10::placeholder {
  color: rgb(250 204 21 / 0.1) !important;
}

.tw-placeholder-yellow-400\/100::placeholder {
  color: rgb(250 204 21 / 1) !important;
}

.tw-placeholder-yellow-400\/15::placeholder {
  color: rgb(250 204 21 / 0.15) !important;
}

.tw-placeholder-yellow-400\/20::placeholder {
  color: rgb(250 204 21 / 0.2) !important;
}

.tw-placeholder-yellow-400\/25::placeholder {
  color: rgb(250 204 21 / 0.25) !important;
}

.tw-placeholder-yellow-400\/30::placeholder {
  color: rgb(250 204 21 / 0.3) !important;
}

.tw-placeholder-yellow-400\/35::placeholder {
  color: rgb(250 204 21 / 0.35) !important;
}

.tw-placeholder-yellow-400\/40::placeholder {
  color: rgb(250 204 21 / 0.4) !important;
}

.tw-placeholder-yellow-400\/45::placeholder {
  color: rgb(250 204 21 / 0.45) !important;
}

.tw-placeholder-yellow-400\/5::placeholder {
  color: rgb(250 204 21 / 0.05) !important;
}

.tw-placeholder-yellow-400\/50::placeholder {
  color: rgb(250 204 21 / 0.5) !important;
}

.tw-placeholder-yellow-400\/55::placeholder {
  color: rgb(250 204 21 / 0.55) !important;
}

.tw-placeholder-yellow-400\/60::placeholder {
  color: rgb(250 204 21 / 0.6) !important;
}

.tw-placeholder-yellow-400\/65::placeholder {
  color: rgb(250 204 21 / 0.65) !important;
}

.tw-placeholder-yellow-400\/70::placeholder {
  color: rgb(250 204 21 / 0.7) !important;
}

.tw-placeholder-yellow-400\/75::placeholder {
  color: rgb(250 204 21 / 0.75) !important;
}

.tw-placeholder-yellow-400\/80::placeholder {
  color: rgb(250 204 21 / 0.8) !important;
}

.tw-placeholder-yellow-400\/85::placeholder {
  color: rgb(250 204 21 / 0.85) !important;
}

.tw-placeholder-yellow-400\/90::placeholder {
  color: rgb(250 204 21 / 0.9) !important;
}

.tw-placeholder-yellow-400\/95::placeholder {
  color: rgb(250 204 21 / 0.95) !important;
}

.tw-placeholder-yellow-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(254 252 232 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-50\/0::placeholder {
  color: rgb(254 252 232 / 0) !important;
}

.tw-placeholder-yellow-50\/10::placeholder {
  color: rgb(254 252 232 / 0.1) !important;
}

.tw-placeholder-yellow-50\/100::placeholder {
  color: rgb(254 252 232 / 1) !important;
}

.tw-placeholder-yellow-50\/15::placeholder {
  color: rgb(254 252 232 / 0.15) !important;
}

.tw-placeholder-yellow-50\/20::placeholder {
  color: rgb(254 252 232 / 0.2) !important;
}

.tw-placeholder-yellow-50\/25::placeholder {
  color: rgb(254 252 232 / 0.25) !important;
}

.tw-placeholder-yellow-50\/30::placeholder {
  color: rgb(254 252 232 / 0.3) !important;
}

.tw-placeholder-yellow-50\/35::placeholder {
  color: rgb(254 252 232 / 0.35) !important;
}

.tw-placeholder-yellow-50\/40::placeholder {
  color: rgb(254 252 232 / 0.4) !important;
}

.tw-placeholder-yellow-50\/45::placeholder {
  color: rgb(254 252 232 / 0.45) !important;
}

.tw-placeholder-yellow-50\/5::placeholder {
  color: rgb(254 252 232 / 0.05) !important;
}

.tw-placeholder-yellow-50\/50::placeholder {
  color: rgb(254 252 232 / 0.5) !important;
}

.tw-placeholder-yellow-50\/55::placeholder {
  color: rgb(254 252 232 / 0.55) !important;
}

.tw-placeholder-yellow-50\/60::placeholder {
  color: rgb(254 252 232 / 0.6) !important;
}

.tw-placeholder-yellow-50\/65::placeholder {
  color: rgb(254 252 232 / 0.65) !important;
}

.tw-placeholder-yellow-50\/70::placeholder {
  color: rgb(254 252 232 / 0.7) !important;
}

.tw-placeholder-yellow-50\/75::placeholder {
  color: rgb(254 252 232 / 0.75) !important;
}

.tw-placeholder-yellow-50\/80::placeholder {
  color: rgb(254 252 232 / 0.8) !important;
}

.tw-placeholder-yellow-50\/85::placeholder {
  color: rgb(254 252 232 / 0.85) !important;
}

.tw-placeholder-yellow-50\/90::placeholder {
  color: rgb(254 252 232 / 0.9) !important;
}

.tw-placeholder-yellow-50\/95::placeholder {
  color: rgb(254 252 232 / 0.95) !important;
}

.tw-placeholder-yellow-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(234 179 8 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-500\/0::placeholder {
  color: rgb(234 179 8 / 0) !important;
}

.tw-placeholder-yellow-500\/10::placeholder {
  color: rgb(234 179 8 / 0.1) !important;
}

.tw-placeholder-yellow-500\/100::placeholder {
  color: rgb(234 179 8 / 1) !important;
}

.tw-placeholder-yellow-500\/15::placeholder {
  color: rgb(234 179 8 / 0.15) !important;
}

.tw-placeholder-yellow-500\/20::placeholder {
  color: rgb(234 179 8 / 0.2) !important;
}

.tw-placeholder-yellow-500\/25::placeholder {
  color: rgb(234 179 8 / 0.25) !important;
}

.tw-placeholder-yellow-500\/30::placeholder {
  color: rgb(234 179 8 / 0.3) !important;
}

.tw-placeholder-yellow-500\/35::placeholder {
  color: rgb(234 179 8 / 0.35) !important;
}

.tw-placeholder-yellow-500\/40::placeholder {
  color: rgb(234 179 8 / 0.4) !important;
}

.tw-placeholder-yellow-500\/45::placeholder {
  color: rgb(234 179 8 / 0.45) !important;
}

.tw-placeholder-yellow-500\/5::placeholder {
  color: rgb(234 179 8 / 0.05) !important;
}

.tw-placeholder-yellow-500\/50::placeholder {
  color: rgb(234 179 8 / 0.5) !important;
}

.tw-placeholder-yellow-500\/55::placeholder {
  color: rgb(234 179 8 / 0.55) !important;
}

.tw-placeholder-yellow-500\/60::placeholder {
  color: rgb(234 179 8 / 0.6) !important;
}

.tw-placeholder-yellow-500\/65::placeholder {
  color: rgb(234 179 8 / 0.65) !important;
}

.tw-placeholder-yellow-500\/70::placeholder {
  color: rgb(234 179 8 / 0.7) !important;
}

.tw-placeholder-yellow-500\/75::placeholder {
  color: rgb(234 179 8 / 0.75) !important;
}

.tw-placeholder-yellow-500\/80::placeholder {
  color: rgb(234 179 8 / 0.8) !important;
}

.tw-placeholder-yellow-500\/85::placeholder {
  color: rgb(234 179 8 / 0.85) !important;
}

.tw-placeholder-yellow-500\/90::placeholder {
  color: rgb(234 179 8 / 0.9) !important;
}

.tw-placeholder-yellow-500\/95::placeholder {
  color: rgb(234 179 8 / 0.95) !important;
}

.tw-placeholder-yellow-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(202 138 4 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-600\/0::placeholder {
  color: rgb(202 138 4 / 0) !important;
}

.tw-placeholder-yellow-600\/10::placeholder {
  color: rgb(202 138 4 / 0.1) !important;
}

.tw-placeholder-yellow-600\/100::placeholder {
  color: rgb(202 138 4 / 1) !important;
}

.tw-placeholder-yellow-600\/15::placeholder {
  color: rgb(202 138 4 / 0.15) !important;
}

.tw-placeholder-yellow-600\/20::placeholder {
  color: rgb(202 138 4 / 0.2) !important;
}

.tw-placeholder-yellow-600\/25::placeholder {
  color: rgb(202 138 4 / 0.25) !important;
}

.tw-placeholder-yellow-600\/30::placeholder {
  color: rgb(202 138 4 / 0.3) !important;
}

.tw-placeholder-yellow-600\/35::placeholder {
  color: rgb(202 138 4 / 0.35) !important;
}

.tw-placeholder-yellow-600\/40::placeholder {
  color: rgb(202 138 4 / 0.4) !important;
}

.tw-placeholder-yellow-600\/45::placeholder {
  color: rgb(202 138 4 / 0.45) !important;
}

.tw-placeholder-yellow-600\/5::placeholder {
  color: rgb(202 138 4 / 0.05) !important;
}

.tw-placeholder-yellow-600\/50::placeholder {
  color: rgb(202 138 4 / 0.5) !important;
}

.tw-placeholder-yellow-600\/55::placeholder {
  color: rgb(202 138 4 / 0.55) !important;
}

.tw-placeholder-yellow-600\/60::placeholder {
  color: rgb(202 138 4 / 0.6) !important;
}

.tw-placeholder-yellow-600\/65::placeholder {
  color: rgb(202 138 4 / 0.65) !important;
}

.tw-placeholder-yellow-600\/70::placeholder {
  color: rgb(202 138 4 / 0.7) !important;
}

.tw-placeholder-yellow-600\/75::placeholder {
  color: rgb(202 138 4 / 0.75) !important;
}

.tw-placeholder-yellow-600\/80::placeholder {
  color: rgb(202 138 4 / 0.8) !important;
}

.tw-placeholder-yellow-600\/85::placeholder {
  color: rgb(202 138 4 / 0.85) !important;
}

.tw-placeholder-yellow-600\/90::placeholder {
  color: rgb(202 138 4 / 0.9) !important;
}

.tw-placeholder-yellow-600\/95::placeholder {
  color: rgb(202 138 4 / 0.95) !important;
}

.tw-placeholder-yellow-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(161 98 7 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-700\/0::placeholder {
  color: rgb(161 98 7 / 0) !important;
}

.tw-placeholder-yellow-700\/10::placeholder {
  color: rgb(161 98 7 / 0.1) !important;
}

.tw-placeholder-yellow-700\/100::placeholder {
  color: rgb(161 98 7 / 1) !important;
}

.tw-placeholder-yellow-700\/15::placeholder {
  color: rgb(161 98 7 / 0.15) !important;
}

.tw-placeholder-yellow-700\/20::placeholder {
  color: rgb(161 98 7 / 0.2) !important;
}

.tw-placeholder-yellow-700\/25::placeholder {
  color: rgb(161 98 7 / 0.25) !important;
}

.tw-placeholder-yellow-700\/30::placeholder {
  color: rgb(161 98 7 / 0.3) !important;
}

.tw-placeholder-yellow-700\/35::placeholder {
  color: rgb(161 98 7 / 0.35) !important;
}

.tw-placeholder-yellow-700\/40::placeholder {
  color: rgb(161 98 7 / 0.4) !important;
}

.tw-placeholder-yellow-700\/45::placeholder {
  color: rgb(161 98 7 / 0.45) !important;
}

.tw-placeholder-yellow-700\/5::placeholder {
  color: rgb(161 98 7 / 0.05) !important;
}

.tw-placeholder-yellow-700\/50::placeholder {
  color: rgb(161 98 7 / 0.5) !important;
}

.tw-placeholder-yellow-700\/55::placeholder {
  color: rgb(161 98 7 / 0.55) !important;
}

.tw-placeholder-yellow-700\/60::placeholder {
  color: rgb(161 98 7 / 0.6) !important;
}

.tw-placeholder-yellow-700\/65::placeholder {
  color: rgb(161 98 7 / 0.65) !important;
}

.tw-placeholder-yellow-700\/70::placeholder {
  color: rgb(161 98 7 / 0.7) !important;
}

.tw-placeholder-yellow-700\/75::placeholder {
  color: rgb(161 98 7 / 0.75) !important;
}

.tw-placeholder-yellow-700\/80::placeholder {
  color: rgb(161 98 7 / 0.8) !important;
}

.tw-placeholder-yellow-700\/85::placeholder {
  color: rgb(161 98 7 / 0.85) !important;
}

.tw-placeholder-yellow-700\/90::placeholder {
  color: rgb(161 98 7 / 0.9) !important;
}

.tw-placeholder-yellow-700\/95::placeholder {
  color: rgb(161 98 7 / 0.95) !important;
}

.tw-placeholder-yellow-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(133 77 14 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-800\/0::placeholder {
  color: rgb(133 77 14 / 0) !important;
}

.tw-placeholder-yellow-800\/10::placeholder {
  color: rgb(133 77 14 / 0.1) !important;
}

.tw-placeholder-yellow-800\/100::placeholder {
  color: rgb(133 77 14 / 1) !important;
}

.tw-placeholder-yellow-800\/15::placeholder {
  color: rgb(133 77 14 / 0.15) !important;
}

.tw-placeholder-yellow-800\/20::placeholder {
  color: rgb(133 77 14 / 0.2) !important;
}

.tw-placeholder-yellow-800\/25::placeholder {
  color: rgb(133 77 14 / 0.25) !important;
}

.tw-placeholder-yellow-800\/30::placeholder {
  color: rgb(133 77 14 / 0.3) !important;
}

.tw-placeholder-yellow-800\/35::placeholder {
  color: rgb(133 77 14 / 0.35) !important;
}

.tw-placeholder-yellow-800\/40::placeholder {
  color: rgb(133 77 14 / 0.4) !important;
}

.tw-placeholder-yellow-800\/45::placeholder {
  color: rgb(133 77 14 / 0.45) !important;
}

.tw-placeholder-yellow-800\/5::placeholder {
  color: rgb(133 77 14 / 0.05) !important;
}

.tw-placeholder-yellow-800\/50::placeholder {
  color: rgb(133 77 14 / 0.5) !important;
}

.tw-placeholder-yellow-800\/55::placeholder {
  color: rgb(133 77 14 / 0.55) !important;
}

.tw-placeholder-yellow-800\/60::placeholder {
  color: rgb(133 77 14 / 0.6) !important;
}

.tw-placeholder-yellow-800\/65::placeholder {
  color: rgb(133 77 14 / 0.65) !important;
}

.tw-placeholder-yellow-800\/70::placeholder {
  color: rgb(133 77 14 / 0.7) !important;
}

.tw-placeholder-yellow-800\/75::placeholder {
  color: rgb(133 77 14 / 0.75) !important;
}

.tw-placeholder-yellow-800\/80::placeholder {
  color: rgb(133 77 14 / 0.8) !important;
}

.tw-placeholder-yellow-800\/85::placeholder {
  color: rgb(133 77 14 / 0.85) !important;
}

.tw-placeholder-yellow-800\/90::placeholder {
  color: rgb(133 77 14 / 0.9) !important;
}

.tw-placeholder-yellow-800\/95::placeholder {
  color: rgb(133 77 14 / 0.95) !important;
}

.tw-placeholder-yellow-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(113 63 18 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-900\/0::placeholder {
  color: rgb(113 63 18 / 0) !important;
}

.tw-placeholder-yellow-900\/10::placeholder {
  color: rgb(113 63 18 / 0.1) !important;
}

.tw-placeholder-yellow-900\/100::placeholder {
  color: rgb(113 63 18 / 1) !important;
}

.tw-placeholder-yellow-900\/15::placeholder {
  color: rgb(113 63 18 / 0.15) !important;
}

.tw-placeholder-yellow-900\/20::placeholder {
  color: rgb(113 63 18 / 0.2) !important;
}

.tw-placeholder-yellow-900\/25::placeholder {
  color: rgb(113 63 18 / 0.25) !important;
}

.tw-placeholder-yellow-900\/30::placeholder {
  color: rgb(113 63 18 / 0.3) !important;
}

.tw-placeholder-yellow-900\/35::placeholder {
  color: rgb(113 63 18 / 0.35) !important;
}

.tw-placeholder-yellow-900\/40::placeholder {
  color: rgb(113 63 18 / 0.4) !important;
}

.tw-placeholder-yellow-900\/45::placeholder {
  color: rgb(113 63 18 / 0.45) !important;
}

.tw-placeholder-yellow-900\/5::placeholder {
  color: rgb(113 63 18 / 0.05) !important;
}

.tw-placeholder-yellow-900\/50::placeholder {
  color: rgb(113 63 18 / 0.5) !important;
}

.tw-placeholder-yellow-900\/55::placeholder {
  color: rgb(113 63 18 / 0.55) !important;
}

.tw-placeholder-yellow-900\/60::placeholder {
  color: rgb(113 63 18 / 0.6) !important;
}

.tw-placeholder-yellow-900\/65::placeholder {
  color: rgb(113 63 18 / 0.65) !important;
}

.tw-placeholder-yellow-900\/70::placeholder {
  color: rgb(113 63 18 / 0.7) !important;
}

.tw-placeholder-yellow-900\/75::placeholder {
  color: rgb(113 63 18 / 0.75) !important;
}

.tw-placeholder-yellow-900\/80::placeholder {
  color: rgb(113 63 18 / 0.8) !important;
}

.tw-placeholder-yellow-900\/85::placeholder {
  color: rgb(113 63 18 / 0.85) !important;
}

.tw-placeholder-yellow-900\/90::placeholder {
  color: rgb(113 63 18 / 0.9) !important;
}

.tw-placeholder-yellow-900\/95::placeholder {
  color: rgb(113 63 18 / 0.95) !important;
}

.tw-placeholder-yellow-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(66 32 6 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-yellow-950\/0::placeholder {
  color: rgb(66 32 6 / 0) !important;
}

.tw-placeholder-yellow-950\/10::placeholder {
  color: rgb(66 32 6 / 0.1) !important;
}

.tw-placeholder-yellow-950\/100::placeholder {
  color: rgb(66 32 6 / 1) !important;
}

.tw-placeholder-yellow-950\/15::placeholder {
  color: rgb(66 32 6 / 0.15) !important;
}

.tw-placeholder-yellow-950\/20::placeholder {
  color: rgb(66 32 6 / 0.2) !important;
}

.tw-placeholder-yellow-950\/25::placeholder {
  color: rgb(66 32 6 / 0.25) !important;
}

.tw-placeholder-yellow-950\/30::placeholder {
  color: rgb(66 32 6 / 0.3) !important;
}

.tw-placeholder-yellow-950\/35::placeholder {
  color: rgb(66 32 6 / 0.35) !important;
}

.tw-placeholder-yellow-950\/40::placeholder {
  color: rgb(66 32 6 / 0.4) !important;
}

.tw-placeholder-yellow-950\/45::placeholder {
  color: rgb(66 32 6 / 0.45) !important;
}

.tw-placeholder-yellow-950\/5::placeholder {
  color: rgb(66 32 6 / 0.05) !important;
}

.tw-placeholder-yellow-950\/50::placeholder {
  color: rgb(66 32 6 / 0.5) !important;
}

.tw-placeholder-yellow-950\/55::placeholder {
  color: rgb(66 32 6 / 0.55) !important;
}

.tw-placeholder-yellow-950\/60::placeholder {
  color: rgb(66 32 6 / 0.6) !important;
}

.tw-placeholder-yellow-950\/65::placeholder {
  color: rgb(66 32 6 / 0.65) !important;
}

.tw-placeholder-yellow-950\/70::placeholder {
  color: rgb(66 32 6 / 0.7) !important;
}

.tw-placeholder-yellow-950\/75::placeholder {
  color: rgb(66 32 6 / 0.75) !important;
}

.tw-placeholder-yellow-950\/80::placeholder {
  color: rgb(66 32 6 / 0.8) !important;
}

.tw-placeholder-yellow-950\/85::placeholder {
  color: rgb(66 32 6 / 0.85) !important;
}

.tw-placeholder-yellow-950\/90::placeholder {
  color: rgb(66 32 6 / 0.9) !important;
}

.tw-placeholder-yellow-950\/95::placeholder {
  color: rgb(66 32 6 / 0.95) !important;
}

.tw-placeholder-zinc-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(244 244 245 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-100\/0::placeholder {
  color: rgb(244 244 245 / 0) !important;
}

.tw-placeholder-zinc-100\/10::placeholder {
  color: rgb(244 244 245 / 0.1) !important;
}

.tw-placeholder-zinc-100\/100::placeholder {
  color: rgb(244 244 245 / 1) !important;
}

.tw-placeholder-zinc-100\/15::placeholder {
  color: rgb(244 244 245 / 0.15) !important;
}

.tw-placeholder-zinc-100\/20::placeholder {
  color: rgb(244 244 245 / 0.2) !important;
}

.tw-placeholder-zinc-100\/25::placeholder {
  color: rgb(244 244 245 / 0.25) !important;
}

.tw-placeholder-zinc-100\/30::placeholder {
  color: rgb(244 244 245 / 0.3) !important;
}

.tw-placeholder-zinc-100\/35::placeholder {
  color: rgb(244 244 245 / 0.35) !important;
}

.tw-placeholder-zinc-100\/40::placeholder {
  color: rgb(244 244 245 / 0.4) !important;
}

.tw-placeholder-zinc-100\/45::placeholder {
  color: rgb(244 244 245 / 0.45) !important;
}

.tw-placeholder-zinc-100\/5::placeholder {
  color: rgb(244 244 245 / 0.05) !important;
}

.tw-placeholder-zinc-100\/50::placeholder {
  color: rgb(244 244 245 / 0.5) !important;
}

.tw-placeholder-zinc-100\/55::placeholder {
  color: rgb(244 244 245 / 0.55) !important;
}

.tw-placeholder-zinc-100\/60::placeholder {
  color: rgb(244 244 245 / 0.6) !important;
}

.tw-placeholder-zinc-100\/65::placeholder {
  color: rgb(244 244 245 / 0.65) !important;
}

.tw-placeholder-zinc-100\/70::placeholder {
  color: rgb(244 244 245 / 0.7) !important;
}

.tw-placeholder-zinc-100\/75::placeholder {
  color: rgb(244 244 245 / 0.75) !important;
}

.tw-placeholder-zinc-100\/80::placeholder {
  color: rgb(244 244 245 / 0.8) !important;
}

.tw-placeholder-zinc-100\/85::placeholder {
  color: rgb(244 244 245 / 0.85) !important;
}

.tw-placeholder-zinc-100\/90::placeholder {
  color: rgb(244 244 245 / 0.9) !important;
}

.tw-placeholder-zinc-100\/95::placeholder {
  color: rgb(244 244 245 / 0.95) !important;
}

.tw-placeholder-zinc-200::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(228 228 231 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-200\/0::placeholder {
  color: rgb(228 228 231 / 0) !important;
}

.tw-placeholder-zinc-200\/10::placeholder {
  color: rgb(228 228 231 / 0.1) !important;
}

.tw-placeholder-zinc-200\/100::placeholder {
  color: rgb(228 228 231 / 1) !important;
}

.tw-placeholder-zinc-200\/15::placeholder {
  color: rgb(228 228 231 / 0.15) !important;
}

.tw-placeholder-zinc-200\/20::placeholder {
  color: rgb(228 228 231 / 0.2) !important;
}

.tw-placeholder-zinc-200\/25::placeholder {
  color: rgb(228 228 231 / 0.25) !important;
}

.tw-placeholder-zinc-200\/30::placeholder {
  color: rgb(228 228 231 / 0.3) !important;
}

.tw-placeholder-zinc-200\/35::placeholder {
  color: rgb(228 228 231 / 0.35) !important;
}

.tw-placeholder-zinc-200\/40::placeholder {
  color: rgb(228 228 231 / 0.4) !important;
}

.tw-placeholder-zinc-200\/45::placeholder {
  color: rgb(228 228 231 / 0.45) !important;
}

.tw-placeholder-zinc-200\/5::placeholder {
  color: rgb(228 228 231 / 0.05) !important;
}

.tw-placeholder-zinc-200\/50::placeholder {
  color: rgb(228 228 231 / 0.5) !important;
}

.tw-placeholder-zinc-200\/55::placeholder {
  color: rgb(228 228 231 / 0.55) !important;
}

.tw-placeholder-zinc-200\/60::placeholder {
  color: rgb(228 228 231 / 0.6) !important;
}

.tw-placeholder-zinc-200\/65::placeholder {
  color: rgb(228 228 231 / 0.65) !important;
}

.tw-placeholder-zinc-200\/70::placeholder {
  color: rgb(228 228 231 / 0.7) !important;
}

.tw-placeholder-zinc-200\/75::placeholder {
  color: rgb(228 228 231 / 0.75) !important;
}

.tw-placeholder-zinc-200\/80::placeholder {
  color: rgb(228 228 231 / 0.8) !important;
}

.tw-placeholder-zinc-200\/85::placeholder {
  color: rgb(228 228 231 / 0.85) !important;
}

.tw-placeholder-zinc-200\/90::placeholder {
  color: rgb(228 228 231 / 0.9) !important;
}

.tw-placeholder-zinc-200\/95::placeholder {
  color: rgb(228 228 231 / 0.95) !important;
}

.tw-placeholder-zinc-300::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(212 212 216 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-300\/0::placeholder {
  color: rgb(212 212 216 / 0) !important;
}

.tw-placeholder-zinc-300\/10::placeholder {
  color: rgb(212 212 216 / 0.1) !important;
}

.tw-placeholder-zinc-300\/100::placeholder {
  color: rgb(212 212 216 / 1) !important;
}

.tw-placeholder-zinc-300\/15::placeholder {
  color: rgb(212 212 216 / 0.15) !important;
}

.tw-placeholder-zinc-300\/20::placeholder {
  color: rgb(212 212 216 / 0.2) !important;
}

.tw-placeholder-zinc-300\/25::placeholder {
  color: rgb(212 212 216 / 0.25) !important;
}

.tw-placeholder-zinc-300\/30::placeholder {
  color: rgb(212 212 216 / 0.3) !important;
}

.tw-placeholder-zinc-300\/35::placeholder {
  color: rgb(212 212 216 / 0.35) !important;
}

.tw-placeholder-zinc-300\/40::placeholder {
  color: rgb(212 212 216 / 0.4) !important;
}

.tw-placeholder-zinc-300\/45::placeholder {
  color: rgb(212 212 216 / 0.45) !important;
}

.tw-placeholder-zinc-300\/5::placeholder {
  color: rgb(212 212 216 / 0.05) !important;
}

.tw-placeholder-zinc-300\/50::placeholder {
  color: rgb(212 212 216 / 0.5) !important;
}

.tw-placeholder-zinc-300\/55::placeholder {
  color: rgb(212 212 216 / 0.55) !important;
}

.tw-placeholder-zinc-300\/60::placeholder {
  color: rgb(212 212 216 / 0.6) !important;
}

.tw-placeholder-zinc-300\/65::placeholder {
  color: rgb(212 212 216 / 0.65) !important;
}

.tw-placeholder-zinc-300\/70::placeholder {
  color: rgb(212 212 216 / 0.7) !important;
}

.tw-placeholder-zinc-300\/75::placeholder {
  color: rgb(212 212 216 / 0.75) !important;
}

.tw-placeholder-zinc-300\/80::placeholder {
  color: rgb(212 212 216 / 0.8) !important;
}

.tw-placeholder-zinc-300\/85::placeholder {
  color: rgb(212 212 216 / 0.85) !important;
}

.tw-placeholder-zinc-300\/90::placeholder {
  color: rgb(212 212 216 / 0.9) !important;
}

.tw-placeholder-zinc-300\/95::placeholder {
  color: rgb(212 212 216 / 0.95) !important;
}

.tw-placeholder-zinc-400::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(161 161 170 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-400\/0::placeholder {
  color: rgb(161 161 170 / 0) !important;
}

.tw-placeholder-zinc-400\/10::placeholder {
  color: rgb(161 161 170 / 0.1) !important;
}

.tw-placeholder-zinc-400\/100::placeholder {
  color: rgb(161 161 170 / 1) !important;
}

.tw-placeholder-zinc-400\/15::placeholder {
  color: rgb(161 161 170 / 0.15) !important;
}

.tw-placeholder-zinc-400\/20::placeholder {
  color: rgb(161 161 170 / 0.2) !important;
}

.tw-placeholder-zinc-400\/25::placeholder {
  color: rgb(161 161 170 / 0.25) !important;
}

.tw-placeholder-zinc-400\/30::placeholder {
  color: rgb(161 161 170 / 0.3) !important;
}

.tw-placeholder-zinc-400\/35::placeholder {
  color: rgb(161 161 170 / 0.35) !important;
}

.tw-placeholder-zinc-400\/40::placeholder {
  color: rgb(161 161 170 / 0.4) !important;
}

.tw-placeholder-zinc-400\/45::placeholder {
  color: rgb(161 161 170 / 0.45) !important;
}

.tw-placeholder-zinc-400\/5::placeholder {
  color: rgb(161 161 170 / 0.05) !important;
}

.tw-placeholder-zinc-400\/50::placeholder {
  color: rgb(161 161 170 / 0.5) !important;
}

.tw-placeholder-zinc-400\/55::placeholder {
  color: rgb(161 161 170 / 0.55) !important;
}

.tw-placeholder-zinc-400\/60::placeholder {
  color: rgb(161 161 170 / 0.6) !important;
}

.tw-placeholder-zinc-400\/65::placeholder {
  color: rgb(161 161 170 / 0.65) !important;
}

.tw-placeholder-zinc-400\/70::placeholder {
  color: rgb(161 161 170 / 0.7) !important;
}

.tw-placeholder-zinc-400\/75::placeholder {
  color: rgb(161 161 170 / 0.75) !important;
}

.tw-placeholder-zinc-400\/80::placeholder {
  color: rgb(161 161 170 / 0.8) !important;
}

.tw-placeholder-zinc-400\/85::placeholder {
  color: rgb(161 161 170 / 0.85) !important;
}

.tw-placeholder-zinc-400\/90::placeholder {
  color: rgb(161 161 170 / 0.9) !important;
}

.tw-placeholder-zinc-400\/95::placeholder {
  color: rgb(161 161 170 / 0.95) !important;
}

.tw-placeholder-zinc-50::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(250 250 250 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-50\/0::placeholder {
  color: rgb(250 250 250 / 0) !important;
}

.tw-placeholder-zinc-50\/10::placeholder {
  color: rgb(250 250 250 / 0.1) !important;
}

.tw-placeholder-zinc-50\/100::placeholder {
  color: rgb(250 250 250 / 1) !important;
}

.tw-placeholder-zinc-50\/15::placeholder {
  color: rgb(250 250 250 / 0.15) !important;
}

.tw-placeholder-zinc-50\/20::placeholder {
  color: rgb(250 250 250 / 0.2) !important;
}

.tw-placeholder-zinc-50\/25::placeholder {
  color: rgb(250 250 250 / 0.25) !important;
}

.tw-placeholder-zinc-50\/30::placeholder {
  color: rgb(250 250 250 / 0.3) !important;
}

.tw-placeholder-zinc-50\/35::placeholder {
  color: rgb(250 250 250 / 0.35) !important;
}

.tw-placeholder-zinc-50\/40::placeholder {
  color: rgb(250 250 250 / 0.4) !important;
}

.tw-placeholder-zinc-50\/45::placeholder {
  color: rgb(250 250 250 / 0.45) !important;
}

.tw-placeholder-zinc-50\/5::placeholder {
  color: rgb(250 250 250 / 0.05) !important;
}

.tw-placeholder-zinc-50\/50::placeholder {
  color: rgb(250 250 250 / 0.5) !important;
}

.tw-placeholder-zinc-50\/55::placeholder {
  color: rgb(250 250 250 / 0.55) !important;
}

.tw-placeholder-zinc-50\/60::placeholder {
  color: rgb(250 250 250 / 0.6) !important;
}

.tw-placeholder-zinc-50\/65::placeholder {
  color: rgb(250 250 250 / 0.65) !important;
}

.tw-placeholder-zinc-50\/70::placeholder {
  color: rgb(250 250 250 / 0.7) !important;
}

.tw-placeholder-zinc-50\/75::placeholder {
  color: rgb(250 250 250 / 0.75) !important;
}

.tw-placeholder-zinc-50\/80::placeholder {
  color: rgb(250 250 250 / 0.8) !important;
}

.tw-placeholder-zinc-50\/85::placeholder {
  color: rgb(250 250 250 / 0.85) !important;
}

.tw-placeholder-zinc-50\/90::placeholder {
  color: rgb(250 250 250 / 0.9) !important;
}

.tw-placeholder-zinc-50\/95::placeholder {
  color: rgb(250 250 250 / 0.95) !important;
}

.tw-placeholder-zinc-500::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(113 113 122 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-500\/0::placeholder {
  color: rgb(113 113 122 / 0) !important;
}

.tw-placeholder-zinc-500\/10::placeholder {
  color: rgb(113 113 122 / 0.1) !important;
}

.tw-placeholder-zinc-500\/100::placeholder {
  color: rgb(113 113 122 / 1) !important;
}

.tw-placeholder-zinc-500\/15::placeholder {
  color: rgb(113 113 122 / 0.15) !important;
}

.tw-placeholder-zinc-500\/20::placeholder {
  color: rgb(113 113 122 / 0.2) !important;
}

.tw-placeholder-zinc-500\/25::placeholder {
  color: rgb(113 113 122 / 0.25) !important;
}

.tw-placeholder-zinc-500\/30::placeholder {
  color: rgb(113 113 122 / 0.3) !important;
}

.tw-placeholder-zinc-500\/35::placeholder {
  color: rgb(113 113 122 / 0.35) !important;
}

.tw-placeholder-zinc-500\/40::placeholder {
  color: rgb(113 113 122 / 0.4) !important;
}

.tw-placeholder-zinc-500\/45::placeholder {
  color: rgb(113 113 122 / 0.45) !important;
}

.tw-placeholder-zinc-500\/5::placeholder {
  color: rgb(113 113 122 / 0.05) !important;
}

.tw-placeholder-zinc-500\/50::placeholder {
  color: rgb(113 113 122 / 0.5) !important;
}

.tw-placeholder-zinc-500\/55::placeholder {
  color: rgb(113 113 122 / 0.55) !important;
}

.tw-placeholder-zinc-500\/60::placeholder {
  color: rgb(113 113 122 / 0.6) !important;
}

.tw-placeholder-zinc-500\/65::placeholder {
  color: rgb(113 113 122 / 0.65) !important;
}

.tw-placeholder-zinc-500\/70::placeholder {
  color: rgb(113 113 122 / 0.7) !important;
}

.tw-placeholder-zinc-500\/75::placeholder {
  color: rgb(113 113 122 / 0.75) !important;
}

.tw-placeholder-zinc-500\/80::placeholder {
  color: rgb(113 113 122 / 0.8) !important;
}

.tw-placeholder-zinc-500\/85::placeholder {
  color: rgb(113 113 122 / 0.85) !important;
}

.tw-placeholder-zinc-500\/90::placeholder {
  color: rgb(113 113 122 / 0.9) !important;
}

.tw-placeholder-zinc-500\/95::placeholder {
  color: rgb(113 113 122 / 0.95) !important;
}

.tw-placeholder-zinc-600::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(82 82 91 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-600\/0::placeholder {
  color: rgb(82 82 91 / 0) !important;
}

.tw-placeholder-zinc-600\/10::placeholder {
  color: rgb(82 82 91 / 0.1) !important;
}

.tw-placeholder-zinc-600\/100::placeholder {
  color: rgb(82 82 91 / 1) !important;
}

.tw-placeholder-zinc-600\/15::placeholder {
  color: rgb(82 82 91 / 0.15) !important;
}

.tw-placeholder-zinc-600\/20::placeholder {
  color: rgb(82 82 91 / 0.2) !important;
}

.tw-placeholder-zinc-600\/25::placeholder {
  color: rgb(82 82 91 / 0.25) !important;
}

.tw-placeholder-zinc-600\/30::placeholder {
  color: rgb(82 82 91 / 0.3) !important;
}

.tw-placeholder-zinc-600\/35::placeholder {
  color: rgb(82 82 91 / 0.35) !important;
}

.tw-placeholder-zinc-600\/40::placeholder {
  color: rgb(82 82 91 / 0.4) !important;
}

.tw-placeholder-zinc-600\/45::placeholder {
  color: rgb(82 82 91 / 0.45) !important;
}

.tw-placeholder-zinc-600\/5::placeholder {
  color: rgb(82 82 91 / 0.05) !important;
}

.tw-placeholder-zinc-600\/50::placeholder {
  color: rgb(82 82 91 / 0.5) !important;
}

.tw-placeholder-zinc-600\/55::placeholder {
  color: rgb(82 82 91 / 0.55) !important;
}

.tw-placeholder-zinc-600\/60::placeholder {
  color: rgb(82 82 91 / 0.6) !important;
}

.tw-placeholder-zinc-600\/65::placeholder {
  color: rgb(82 82 91 / 0.65) !important;
}

.tw-placeholder-zinc-600\/70::placeholder {
  color: rgb(82 82 91 / 0.7) !important;
}

.tw-placeholder-zinc-600\/75::placeholder {
  color: rgb(82 82 91 / 0.75) !important;
}

.tw-placeholder-zinc-600\/80::placeholder {
  color: rgb(82 82 91 / 0.8) !important;
}

.tw-placeholder-zinc-600\/85::placeholder {
  color: rgb(82 82 91 / 0.85) !important;
}

.tw-placeholder-zinc-600\/90::placeholder {
  color: rgb(82 82 91 / 0.9) !important;
}

.tw-placeholder-zinc-600\/95::placeholder {
  color: rgb(82 82 91 / 0.95) !important;
}

.tw-placeholder-zinc-700::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(63 63 70 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-700\/0::placeholder {
  color: rgb(63 63 70 / 0) !important;
}

.tw-placeholder-zinc-700\/10::placeholder {
  color: rgb(63 63 70 / 0.1) !important;
}

.tw-placeholder-zinc-700\/100::placeholder {
  color: rgb(63 63 70 / 1) !important;
}

.tw-placeholder-zinc-700\/15::placeholder {
  color: rgb(63 63 70 / 0.15) !important;
}

.tw-placeholder-zinc-700\/20::placeholder {
  color: rgb(63 63 70 / 0.2) !important;
}

.tw-placeholder-zinc-700\/25::placeholder {
  color: rgb(63 63 70 / 0.25) !important;
}

.tw-placeholder-zinc-700\/30::placeholder {
  color: rgb(63 63 70 / 0.3) !important;
}

.tw-placeholder-zinc-700\/35::placeholder {
  color: rgb(63 63 70 / 0.35) !important;
}

.tw-placeholder-zinc-700\/40::placeholder {
  color: rgb(63 63 70 / 0.4) !important;
}

.tw-placeholder-zinc-700\/45::placeholder {
  color: rgb(63 63 70 / 0.45) !important;
}

.tw-placeholder-zinc-700\/5::placeholder {
  color: rgb(63 63 70 / 0.05) !important;
}

.tw-placeholder-zinc-700\/50::placeholder {
  color: rgb(63 63 70 / 0.5) !important;
}

.tw-placeholder-zinc-700\/55::placeholder {
  color: rgb(63 63 70 / 0.55) !important;
}

.tw-placeholder-zinc-700\/60::placeholder {
  color: rgb(63 63 70 / 0.6) !important;
}

.tw-placeholder-zinc-700\/65::placeholder {
  color: rgb(63 63 70 / 0.65) !important;
}

.tw-placeholder-zinc-700\/70::placeholder {
  color: rgb(63 63 70 / 0.7) !important;
}

.tw-placeholder-zinc-700\/75::placeholder {
  color: rgb(63 63 70 / 0.75) !important;
}

.tw-placeholder-zinc-700\/80::placeholder {
  color: rgb(63 63 70 / 0.8) !important;
}

.tw-placeholder-zinc-700\/85::placeholder {
  color: rgb(63 63 70 / 0.85) !important;
}

.tw-placeholder-zinc-700\/90::placeholder {
  color: rgb(63 63 70 / 0.9) !important;
}

.tw-placeholder-zinc-700\/95::placeholder {
  color: rgb(63 63 70 / 0.95) !important;
}

.tw-placeholder-zinc-800::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(39 39 42 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-800\/0::placeholder {
  color: rgb(39 39 42 / 0) !important;
}

.tw-placeholder-zinc-800\/10::placeholder {
  color: rgb(39 39 42 / 0.1) !important;
}

.tw-placeholder-zinc-800\/100::placeholder {
  color: rgb(39 39 42 / 1) !important;
}

.tw-placeholder-zinc-800\/15::placeholder {
  color: rgb(39 39 42 / 0.15) !important;
}

.tw-placeholder-zinc-800\/20::placeholder {
  color: rgb(39 39 42 / 0.2) !important;
}

.tw-placeholder-zinc-800\/25::placeholder {
  color: rgb(39 39 42 / 0.25) !important;
}

.tw-placeholder-zinc-800\/30::placeholder {
  color: rgb(39 39 42 / 0.3) !important;
}

.tw-placeholder-zinc-800\/35::placeholder {
  color: rgb(39 39 42 / 0.35) !important;
}

.tw-placeholder-zinc-800\/40::placeholder {
  color: rgb(39 39 42 / 0.4) !important;
}

.tw-placeholder-zinc-800\/45::placeholder {
  color: rgb(39 39 42 / 0.45) !important;
}

.tw-placeholder-zinc-800\/5::placeholder {
  color: rgb(39 39 42 / 0.05) !important;
}

.tw-placeholder-zinc-800\/50::placeholder {
  color: rgb(39 39 42 / 0.5) !important;
}

.tw-placeholder-zinc-800\/55::placeholder {
  color: rgb(39 39 42 / 0.55) !important;
}

.tw-placeholder-zinc-800\/60::placeholder {
  color: rgb(39 39 42 / 0.6) !important;
}

.tw-placeholder-zinc-800\/65::placeholder {
  color: rgb(39 39 42 / 0.65) !important;
}

.tw-placeholder-zinc-800\/70::placeholder {
  color: rgb(39 39 42 / 0.7) !important;
}

.tw-placeholder-zinc-800\/75::placeholder {
  color: rgb(39 39 42 / 0.75) !important;
}

.tw-placeholder-zinc-800\/80::placeholder {
  color: rgb(39 39 42 / 0.8) !important;
}

.tw-placeholder-zinc-800\/85::placeholder {
  color: rgb(39 39 42 / 0.85) !important;
}

.tw-placeholder-zinc-800\/90::placeholder {
  color: rgb(39 39 42 / 0.9) !important;
}

.tw-placeholder-zinc-800\/95::placeholder {
  color: rgb(39 39 42 / 0.95) !important;
}

.tw-placeholder-zinc-900::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(24 24 27 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-900\/0::placeholder {
  color: rgb(24 24 27 / 0) !important;
}

.tw-placeholder-zinc-900\/10::placeholder {
  color: rgb(24 24 27 / 0.1) !important;
}

.tw-placeholder-zinc-900\/100::placeholder {
  color: rgb(24 24 27 / 1) !important;
}

.tw-placeholder-zinc-900\/15::placeholder {
  color: rgb(24 24 27 / 0.15) !important;
}

.tw-placeholder-zinc-900\/20::placeholder {
  color: rgb(24 24 27 / 0.2) !important;
}

.tw-placeholder-zinc-900\/25::placeholder {
  color: rgb(24 24 27 / 0.25) !important;
}

.tw-placeholder-zinc-900\/30::placeholder {
  color: rgb(24 24 27 / 0.3) !important;
}

.tw-placeholder-zinc-900\/35::placeholder {
  color: rgb(24 24 27 / 0.35) !important;
}

.tw-placeholder-zinc-900\/40::placeholder {
  color: rgb(24 24 27 / 0.4) !important;
}

.tw-placeholder-zinc-900\/45::placeholder {
  color: rgb(24 24 27 / 0.45) !important;
}

.tw-placeholder-zinc-900\/5::placeholder {
  color: rgb(24 24 27 / 0.05) !important;
}

.tw-placeholder-zinc-900\/50::placeholder {
  color: rgb(24 24 27 / 0.5) !important;
}

.tw-placeholder-zinc-900\/55::placeholder {
  color: rgb(24 24 27 / 0.55) !important;
}

.tw-placeholder-zinc-900\/60::placeholder {
  color: rgb(24 24 27 / 0.6) !important;
}

.tw-placeholder-zinc-900\/65::placeholder {
  color: rgb(24 24 27 / 0.65) !important;
}

.tw-placeholder-zinc-900\/70::placeholder {
  color: rgb(24 24 27 / 0.7) !important;
}

.tw-placeholder-zinc-900\/75::placeholder {
  color: rgb(24 24 27 / 0.75) !important;
}

.tw-placeholder-zinc-900\/80::placeholder {
  color: rgb(24 24 27 / 0.8) !important;
}

.tw-placeholder-zinc-900\/85::placeholder {
  color: rgb(24 24 27 / 0.85) !important;
}

.tw-placeholder-zinc-900\/90::placeholder {
  color: rgb(24 24 27 / 0.9) !important;
}

.tw-placeholder-zinc-900\/95::placeholder {
  color: rgb(24 24 27 / 0.95) !important;
}

.tw-placeholder-zinc-950::placeholder {
  --tw-placeholder-opacity: 1 !important;
  color: rgb(9 9 11 / var(--tw-placeholder-opacity)) !important;
}

.tw-placeholder-zinc-950\/0::placeholder {
  color: rgb(9 9 11 / 0) !important;
}

.tw-placeholder-zinc-950\/10::placeholder {
  color: rgb(9 9 11 / 0.1) !important;
}

.tw-placeholder-zinc-950\/100::placeholder {
  color: rgb(9 9 11 / 1) !important;
}

.tw-placeholder-zinc-950\/15::placeholder {
  color: rgb(9 9 11 / 0.15) !important;
}

.tw-placeholder-zinc-950\/20::placeholder {
  color: rgb(9 9 11 / 0.2) !important;
}

.tw-placeholder-zinc-950\/25::placeholder {
  color: rgb(9 9 11 / 0.25) !important;
}

.tw-placeholder-zinc-950\/30::placeholder {
  color: rgb(9 9 11 / 0.3) !important;
}

.tw-placeholder-zinc-950\/35::placeholder {
  color: rgb(9 9 11 / 0.35) !important;
}

.tw-placeholder-zinc-950\/40::placeholder {
  color: rgb(9 9 11 / 0.4) !important;
}

.tw-placeholder-zinc-950\/45::placeholder {
  color: rgb(9 9 11 / 0.45) !important;
}

.tw-placeholder-zinc-950\/5::placeholder {
  color: rgb(9 9 11 / 0.05) !important;
}

.tw-placeholder-zinc-950\/50::placeholder {
  color: rgb(9 9 11 / 0.5) !important;
}

.tw-placeholder-zinc-950\/55::placeholder {
  color: rgb(9 9 11 / 0.55) !important;
}

.tw-placeholder-zinc-950\/60::placeholder {
  color: rgb(9 9 11 / 0.6) !important;
}

.tw-placeholder-zinc-950\/65::placeholder {
  color: rgb(9 9 11 / 0.65) !important;
}

.tw-placeholder-zinc-950\/70::placeholder {
  color: rgb(9 9 11 / 0.7) !important;
}

.tw-placeholder-zinc-950\/75::placeholder {
  color: rgb(9 9 11 / 0.75) !important;
}

.tw-placeholder-zinc-950\/80::placeholder {
  color: rgb(9 9 11 / 0.8) !important;
}

.tw-placeholder-zinc-950\/85::placeholder {
  color: rgb(9 9 11 / 0.85) !important;
}

.tw-placeholder-zinc-950\/90::placeholder {
  color: rgb(9 9 11 / 0.9) !important;
}

.tw-placeholder-zinc-950\/95::placeholder {
  color: rgb(9 9 11 / 0.95) !important;
}

.tw-placeholder-opacity-0::placeholder {
  --tw-placeholder-opacity: 0 !important;
}

.tw-placeholder-opacity-10::placeholder {
  --tw-placeholder-opacity: 0.1 !important;
}

.tw-placeholder-opacity-100::placeholder {
  --tw-placeholder-opacity: 1 !important;
}

.tw-placeholder-opacity-15::placeholder {
  --tw-placeholder-opacity: 0.15 !important;
}

.tw-placeholder-opacity-20::placeholder {
  --tw-placeholder-opacity: 0.2 !important;
}

.tw-placeholder-opacity-25::placeholder {
  --tw-placeholder-opacity: 0.25 !important;
}

.tw-placeholder-opacity-30::placeholder {
  --tw-placeholder-opacity: 0.3 !important;
}

.tw-placeholder-opacity-35::placeholder {
  --tw-placeholder-opacity: 0.35 !important;
}

.tw-placeholder-opacity-40::placeholder {
  --tw-placeholder-opacity: 0.4 !important;
}

.tw-placeholder-opacity-45::placeholder {
  --tw-placeholder-opacity: 0.45 !important;
}

.tw-placeholder-opacity-5::placeholder {
  --tw-placeholder-opacity: 0.05 !important;
}

.tw-placeholder-opacity-50::placeholder {
  --tw-placeholder-opacity: 0.5 !important;
}

.tw-placeholder-opacity-55::placeholder {
  --tw-placeholder-opacity: 0.55 !important;
}

.tw-placeholder-opacity-60::placeholder {
  --tw-placeholder-opacity: 0.6 !important;
}

.tw-placeholder-opacity-65::placeholder {
  --tw-placeholder-opacity: 0.65 !important;
}

.tw-placeholder-opacity-70::placeholder {
  --tw-placeholder-opacity: 0.7 !important;
}

.tw-placeholder-opacity-75::placeholder {
  --tw-placeholder-opacity: 0.75 !important;
}

.tw-placeholder-opacity-80::placeholder {
  --tw-placeholder-opacity: 0.8 !important;
}

.tw-placeholder-opacity-85::placeholder {
  --tw-placeholder-opacity: 0.85 !important;
}

.tw-placeholder-opacity-90::placeholder {
  --tw-placeholder-opacity: 0.9 !important;
}

.tw-placeholder-opacity-95::placeholder {
  --tw-placeholder-opacity: 0.95 !important;
}

.tw-caret-amber-100 {
  caret-color: #fef3c7 !important;
}

.tw-caret-amber-100\/0 {
  caret-color: rgb(254 243 199 / 0) !important;
}

.tw-caret-amber-100\/10 {
  caret-color: rgb(254 243 199 / 0.1) !important;
}

.tw-caret-amber-100\/100 {
  caret-color: rgb(254 243 199 / 1) !important;
}

.tw-caret-amber-100\/15 {
  caret-color: rgb(254 243 199 / 0.15) !important;
}

.tw-caret-amber-100\/20 {
  caret-color: rgb(254 243 199 / 0.2) !important;
}

.tw-caret-amber-100\/25 {
  caret-color: rgb(254 243 199 / 0.25) !important;
}

.tw-caret-amber-100\/30 {
  caret-color: rgb(254 243 199 / 0.3) !important;
}

.tw-caret-amber-100\/35 {
  caret-color: rgb(254 243 199 / 0.35) !important;
}

.tw-caret-amber-100\/40 {
  caret-color: rgb(254 243 199 / 0.4) !important;
}

.tw-caret-amber-100\/45 {
  caret-color: rgb(254 243 199 / 0.45) !important;
}

.tw-caret-amber-100\/5 {
  caret-color: rgb(254 243 199 / 0.05) !important;
}

.tw-caret-amber-100\/50 {
  caret-color: rgb(254 243 199 / 0.5) !important;
}

.tw-caret-amber-100\/55 {
  caret-color: rgb(254 243 199 / 0.55) !important;
}

.tw-caret-amber-100\/60 {
  caret-color: rgb(254 243 199 / 0.6) !important;
}

.tw-caret-amber-100\/65 {
  caret-color: rgb(254 243 199 / 0.65) !important;
}

.tw-caret-amber-100\/70 {
  caret-color: rgb(254 243 199 / 0.7) !important;
}

.tw-caret-amber-100\/75 {
  caret-color: rgb(254 243 199 / 0.75) !important;
}

.tw-caret-amber-100\/80 {
  caret-color: rgb(254 243 199 / 0.8) !important;
}

.tw-caret-amber-100\/85 {
  caret-color: rgb(254 243 199 / 0.85) !important;
}

.tw-caret-amber-100\/90 {
  caret-color: rgb(254 243 199 / 0.9) !important;
}

.tw-caret-amber-100\/95 {
  caret-color: rgb(254 243 199 / 0.95) !important;
}

.tw-caret-amber-200 {
  caret-color: #fde68a !important;
}

.tw-caret-amber-200\/0 {
  caret-color: rgb(253 230 138 / 0) !important;
}

.tw-caret-amber-200\/10 {
  caret-color: rgb(253 230 138 / 0.1) !important;
}

.tw-caret-amber-200\/100 {
  caret-color: rgb(253 230 138 / 1) !important;
}

.tw-caret-amber-200\/15 {
  caret-color: rgb(253 230 138 / 0.15) !important;
}

.tw-caret-amber-200\/20 {
  caret-color: rgb(253 230 138 / 0.2) !important;
}

.tw-caret-amber-200\/25 {
  caret-color: rgb(253 230 138 / 0.25) !important;
}

.tw-caret-amber-200\/30 {
  caret-color: rgb(253 230 138 / 0.3) !important;
}

.tw-caret-amber-200\/35 {
  caret-color: rgb(253 230 138 / 0.35) !important;
}

.tw-caret-amber-200\/40 {
  caret-color: rgb(253 230 138 / 0.4) !important;
}

.tw-caret-amber-200\/45 {
  caret-color: rgb(253 230 138 / 0.45) !important;
}

.tw-caret-amber-200\/5 {
  caret-color: rgb(253 230 138 / 0.05) !important;
}

.tw-caret-amber-200\/50 {
  caret-color: rgb(253 230 138 / 0.5) !important;
}

.tw-caret-amber-200\/55 {
  caret-color: rgb(253 230 138 / 0.55) !important;
}

.tw-caret-amber-200\/60 {
  caret-color: rgb(253 230 138 / 0.6) !important;
}

.tw-caret-amber-200\/65 {
  caret-color: rgb(253 230 138 / 0.65) !important;
}

.tw-caret-amber-200\/70 {
  caret-color: rgb(253 230 138 / 0.7) !important;
}

.tw-caret-amber-200\/75 {
  caret-color: rgb(253 230 138 / 0.75) !important;
}

.tw-caret-amber-200\/80 {
  caret-color: rgb(253 230 138 / 0.8) !important;
}

.tw-caret-amber-200\/85 {
  caret-color: rgb(253 230 138 / 0.85) !important;
}

.tw-caret-amber-200\/90 {
  caret-color: rgb(253 230 138 / 0.9) !important;
}

.tw-caret-amber-200\/95 {
  caret-color: rgb(253 230 138 / 0.95) !important;
}

.tw-caret-amber-300 {
  caret-color: #fcd34d !important;
}

.tw-caret-amber-300\/0 {
  caret-color: rgb(252 211 77 / 0) !important;
}

.tw-caret-amber-300\/10 {
  caret-color: rgb(252 211 77 / 0.1) !important;
}

.tw-caret-amber-300\/100 {
  caret-color: rgb(252 211 77 / 1) !important;
}

.tw-caret-amber-300\/15 {
  caret-color: rgb(252 211 77 / 0.15) !important;
}

.tw-caret-amber-300\/20 {
  caret-color: rgb(252 211 77 / 0.2) !important;
}

.tw-caret-amber-300\/25 {
  caret-color: rgb(252 211 77 / 0.25) !important;
}

.tw-caret-amber-300\/30 {
  caret-color: rgb(252 211 77 / 0.3) !important;
}

.tw-caret-amber-300\/35 {
  caret-color: rgb(252 211 77 / 0.35) !important;
}

.tw-caret-amber-300\/40 {
  caret-color: rgb(252 211 77 / 0.4) !important;
}

.tw-caret-amber-300\/45 {
  caret-color: rgb(252 211 77 / 0.45) !important;
}

.tw-caret-amber-300\/5 {
  caret-color: rgb(252 211 77 / 0.05) !important;
}

.tw-caret-amber-300\/50 {
  caret-color: rgb(252 211 77 / 0.5) !important;
}

.tw-caret-amber-300\/55 {
  caret-color: rgb(252 211 77 / 0.55) !important;
}

.tw-caret-amber-300\/60 {
  caret-color: rgb(252 211 77 / 0.6) !important;
}

.tw-caret-amber-300\/65 {
  caret-color: rgb(252 211 77 / 0.65) !important;
}

.tw-caret-amber-300\/70 {
  caret-color: rgb(252 211 77 / 0.7) !important;
}

.tw-caret-amber-300\/75 {
  caret-color: rgb(252 211 77 / 0.75) !important;
}

.tw-caret-amber-300\/80 {
  caret-color: rgb(252 211 77 / 0.8) !important;
}

.tw-caret-amber-300\/85 {
  caret-color: rgb(252 211 77 / 0.85) !important;
}

.tw-caret-amber-300\/90 {
  caret-color: rgb(252 211 77 / 0.9) !important;
}

.tw-caret-amber-300\/95 {
  caret-color: rgb(252 211 77 / 0.95) !important;
}

.tw-caret-amber-400 {
  caret-color: #fbbf24 !important;
}

.tw-caret-amber-400\/0 {
  caret-color: rgb(251 191 36 / 0) !important;
}

.tw-caret-amber-400\/10 {
  caret-color: rgb(251 191 36 / 0.1) !important;
}

.tw-caret-amber-400\/100 {
  caret-color: rgb(251 191 36 / 1) !important;
}

.tw-caret-amber-400\/15 {
  caret-color: rgb(251 191 36 / 0.15) !important;
}

.tw-caret-amber-400\/20 {
  caret-color: rgb(251 191 36 / 0.2) !important;
}

.tw-caret-amber-400\/25 {
  caret-color: rgb(251 191 36 / 0.25) !important;
}

.tw-caret-amber-400\/30 {
  caret-color: rgb(251 191 36 / 0.3) !important;
}

.tw-caret-amber-400\/35 {
  caret-color: rgb(251 191 36 / 0.35) !important;
}

.tw-caret-amber-400\/40 {
  caret-color: rgb(251 191 36 / 0.4) !important;
}

.tw-caret-amber-400\/45 {
  caret-color: rgb(251 191 36 / 0.45) !important;
}

.tw-caret-amber-400\/5 {
  caret-color: rgb(251 191 36 / 0.05) !important;
}

.tw-caret-amber-400\/50 {
  caret-color: rgb(251 191 36 / 0.5) !important;
}

.tw-caret-amber-400\/55 {
  caret-color: rgb(251 191 36 / 0.55) !important;
}

.tw-caret-amber-400\/60 {
  caret-color: rgb(251 191 36 / 0.6) !important;
}

.tw-caret-amber-400\/65 {
  caret-color: rgb(251 191 36 / 0.65) !important;
}

.tw-caret-amber-400\/70 {
  caret-color: rgb(251 191 36 / 0.7) !important;
}

.tw-caret-amber-400\/75 {
  caret-color: rgb(251 191 36 / 0.75) !important;
}

.tw-caret-amber-400\/80 {
  caret-color: rgb(251 191 36 / 0.8) !important;
}

.tw-caret-amber-400\/85 {
  caret-color: rgb(251 191 36 / 0.85) !important;
}

.tw-caret-amber-400\/90 {
  caret-color: rgb(251 191 36 / 0.9) !important;
}

.tw-caret-amber-400\/95 {
  caret-color: rgb(251 191 36 / 0.95) !important;
}

.tw-caret-amber-50 {
  caret-color: #fffbeb !important;
}

.tw-caret-amber-50\/0 {
  caret-color: rgb(255 251 235 / 0) !important;
}

.tw-caret-amber-50\/10 {
  caret-color: rgb(255 251 235 / 0.1) !important;
}

.tw-caret-amber-50\/100 {
  caret-color: rgb(255 251 235 / 1) !important;
}

.tw-caret-amber-50\/15 {
  caret-color: rgb(255 251 235 / 0.15) !important;
}

.tw-caret-amber-50\/20 {
  caret-color: rgb(255 251 235 / 0.2) !important;
}

.tw-caret-amber-50\/25 {
  caret-color: rgb(255 251 235 / 0.25) !important;
}

.tw-caret-amber-50\/30 {
  caret-color: rgb(255 251 235 / 0.3) !important;
}

.tw-caret-amber-50\/35 {
  caret-color: rgb(255 251 235 / 0.35) !important;
}

.tw-caret-amber-50\/40 {
  caret-color: rgb(255 251 235 / 0.4) !important;
}

.tw-caret-amber-50\/45 {
  caret-color: rgb(255 251 235 / 0.45) !important;
}

.tw-caret-amber-50\/5 {
  caret-color: rgb(255 251 235 / 0.05) !important;
}

.tw-caret-amber-50\/50 {
  caret-color: rgb(255 251 235 / 0.5) !important;
}

.tw-caret-amber-50\/55 {
  caret-color: rgb(255 251 235 / 0.55) !important;
}

.tw-caret-amber-50\/60 {
  caret-color: rgb(255 251 235 / 0.6) !important;
}

.tw-caret-amber-50\/65 {
  caret-color: rgb(255 251 235 / 0.65) !important;
}

.tw-caret-amber-50\/70 {
  caret-color: rgb(255 251 235 / 0.7) !important;
}

.tw-caret-amber-50\/75 {
  caret-color: rgb(255 251 235 / 0.75) !important;
}

.tw-caret-amber-50\/80 {
  caret-color: rgb(255 251 235 / 0.8) !important;
}

.tw-caret-amber-50\/85 {
  caret-color: rgb(255 251 235 / 0.85) !important;
}

.tw-caret-amber-50\/90 {
  caret-color: rgb(255 251 235 / 0.9) !important;
}

.tw-caret-amber-50\/95 {
  caret-color: rgb(255 251 235 / 0.95) !important;
}

.tw-caret-amber-500 {
  caret-color: #f59e0b !important;
}

.tw-caret-amber-500\/0 {
  caret-color: rgb(245 158 11 / 0) !important;
}

.tw-caret-amber-500\/10 {
  caret-color: rgb(245 158 11 / 0.1) !important;
}

.tw-caret-amber-500\/100 {
  caret-color: rgb(245 158 11 / 1) !important;
}

.tw-caret-amber-500\/15 {
  caret-color: rgb(245 158 11 / 0.15) !important;
}

.tw-caret-amber-500\/20 {
  caret-color: rgb(245 158 11 / 0.2) !important;
}

.tw-caret-amber-500\/25 {
  caret-color: rgb(245 158 11 / 0.25) !important;
}

.tw-caret-amber-500\/30 {
  caret-color: rgb(245 158 11 / 0.3) !important;
}

.tw-caret-amber-500\/35 {
  caret-color: rgb(245 158 11 / 0.35) !important;
}

.tw-caret-amber-500\/40 {
  caret-color: rgb(245 158 11 / 0.4) !important;
}

.tw-caret-amber-500\/45 {
  caret-color: rgb(245 158 11 / 0.45) !important;
}

.tw-caret-amber-500\/5 {
  caret-color: rgb(245 158 11 / 0.05) !important;
}

.tw-caret-amber-500\/50 {
  caret-color: rgb(245 158 11 / 0.5) !important;
}

.tw-caret-amber-500\/55 {
  caret-color: rgb(245 158 11 / 0.55) !important;
}

.tw-caret-amber-500\/60 {
  caret-color: rgb(245 158 11 / 0.6) !important;
}

.tw-caret-amber-500\/65 {
  caret-color: rgb(245 158 11 / 0.65) !important;
}

.tw-caret-amber-500\/70 {
  caret-color: rgb(245 158 11 / 0.7) !important;
}

.tw-caret-amber-500\/75 {
  caret-color: rgb(245 158 11 / 0.75) !important;
}

.tw-caret-amber-500\/80 {
  caret-color: rgb(245 158 11 / 0.8) !important;
}

.tw-caret-amber-500\/85 {
  caret-color: rgb(245 158 11 / 0.85) !important;
}

.tw-caret-amber-500\/90 {
  caret-color: rgb(245 158 11 / 0.9) !important;
}

.tw-caret-amber-500\/95 {
  caret-color: rgb(245 158 11 / 0.95) !important;
}

.tw-caret-amber-600 {
  caret-color: #d97706 !important;
}

.tw-caret-amber-600\/0 {
  caret-color: rgb(217 119 6 / 0) !important;
}

.tw-caret-amber-600\/10 {
  caret-color: rgb(217 119 6 / 0.1) !important;
}

.tw-caret-amber-600\/100 {
  caret-color: rgb(217 119 6 / 1) !important;
}

.tw-caret-amber-600\/15 {
  caret-color: rgb(217 119 6 / 0.15) !important;
}

.tw-caret-amber-600\/20 {
  caret-color: rgb(217 119 6 / 0.2) !important;
}

.tw-caret-amber-600\/25 {
  caret-color: rgb(217 119 6 / 0.25) !important;
}

.tw-caret-amber-600\/30 {
  caret-color: rgb(217 119 6 / 0.3) !important;
}

.tw-caret-amber-600\/35 {
  caret-color: rgb(217 119 6 / 0.35) !important;
}

.tw-caret-amber-600\/40 {
  caret-color: rgb(217 119 6 / 0.4) !important;
}

.tw-caret-amber-600\/45 {
  caret-color: rgb(217 119 6 / 0.45) !important;
}

.tw-caret-amber-600\/5 {
  caret-color: rgb(217 119 6 / 0.05) !important;
}

.tw-caret-amber-600\/50 {
  caret-color: rgb(217 119 6 / 0.5) !important;
}

.tw-caret-amber-600\/55 {
  caret-color: rgb(217 119 6 / 0.55) !important;
}

.tw-caret-amber-600\/60 {
  caret-color: rgb(217 119 6 / 0.6) !important;
}

.tw-caret-amber-600\/65 {
  caret-color: rgb(217 119 6 / 0.65) !important;
}

.tw-caret-amber-600\/70 {
  caret-color: rgb(217 119 6 / 0.7) !important;
}

.tw-caret-amber-600\/75 {
  caret-color: rgb(217 119 6 / 0.75) !important;
}

.tw-caret-amber-600\/80 {
  caret-color: rgb(217 119 6 / 0.8) !important;
}

.tw-caret-amber-600\/85 {
  caret-color: rgb(217 119 6 / 0.85) !important;
}

.tw-caret-amber-600\/90 {
  caret-color: rgb(217 119 6 / 0.9) !important;
}

.tw-caret-amber-600\/95 {
  caret-color: rgb(217 119 6 / 0.95) !important;
}

.tw-caret-amber-700 {
  caret-color: #b45309 !important;
}

.tw-caret-amber-700\/0 {
  caret-color: rgb(180 83 9 / 0) !important;
}

.tw-caret-amber-700\/10 {
  caret-color: rgb(180 83 9 / 0.1) !important;
}

.tw-caret-amber-700\/100 {
  caret-color: rgb(180 83 9 / 1) !important;
}

.tw-caret-amber-700\/15 {
  caret-color: rgb(180 83 9 / 0.15) !important;
}

.tw-caret-amber-700\/20 {
  caret-color: rgb(180 83 9 / 0.2) !important;
}

.tw-caret-amber-700\/25 {
  caret-color: rgb(180 83 9 / 0.25) !important;
}

.tw-caret-amber-700\/30 {
  caret-color: rgb(180 83 9 / 0.3) !important;
}

.tw-caret-amber-700\/35 {
  caret-color: rgb(180 83 9 / 0.35) !important;
}

.tw-caret-amber-700\/40 {
  caret-color: rgb(180 83 9 / 0.4) !important;
}

.tw-caret-amber-700\/45 {
  caret-color: rgb(180 83 9 / 0.45) !important;
}

.tw-caret-amber-700\/5 {
  caret-color: rgb(180 83 9 / 0.05) !important;
}

.tw-caret-amber-700\/50 {
  caret-color: rgb(180 83 9 / 0.5) !important;
}

.tw-caret-amber-700\/55 {
  caret-color: rgb(180 83 9 / 0.55) !important;
}

.tw-caret-amber-700\/60 {
  caret-color: rgb(180 83 9 / 0.6) !important;
}

.tw-caret-amber-700\/65 {
  caret-color: rgb(180 83 9 / 0.65) !important;
}

.tw-caret-amber-700\/70 {
  caret-color: rgb(180 83 9 / 0.7) !important;
}

.tw-caret-amber-700\/75 {
  caret-color: rgb(180 83 9 / 0.75) !important;
}

.tw-caret-amber-700\/80 {
  caret-color: rgb(180 83 9 / 0.8) !important;
}

.tw-caret-amber-700\/85 {
  caret-color: rgb(180 83 9 / 0.85) !important;
}

.tw-caret-amber-700\/90 {
  caret-color: rgb(180 83 9 / 0.9) !important;
}

.tw-caret-amber-700\/95 {
  caret-color: rgb(180 83 9 / 0.95) !important;
}

.tw-caret-amber-800 {
  caret-color: #92400e !important;
}

.tw-caret-amber-800\/0 {
  caret-color: rgb(146 64 14 / 0) !important;
}

.tw-caret-amber-800\/10 {
  caret-color: rgb(146 64 14 / 0.1) !important;
}

.tw-caret-amber-800\/100 {
  caret-color: rgb(146 64 14 / 1) !important;
}

.tw-caret-amber-800\/15 {
  caret-color: rgb(146 64 14 / 0.15) !important;
}

.tw-caret-amber-800\/20 {
  caret-color: rgb(146 64 14 / 0.2) !important;
}

.tw-caret-amber-800\/25 {
  caret-color: rgb(146 64 14 / 0.25) !important;
}

.tw-caret-amber-800\/30 {
  caret-color: rgb(146 64 14 / 0.3) !important;
}

.tw-caret-amber-800\/35 {
  caret-color: rgb(146 64 14 / 0.35) !important;
}

.tw-caret-amber-800\/40 {
  caret-color: rgb(146 64 14 / 0.4) !important;
}

.tw-caret-amber-800\/45 {
  caret-color: rgb(146 64 14 / 0.45) !important;
}

.tw-caret-amber-800\/5 {
  caret-color: rgb(146 64 14 / 0.05) !important;
}

.tw-caret-amber-800\/50 {
  caret-color: rgb(146 64 14 / 0.5) !important;
}

.tw-caret-amber-800\/55 {
  caret-color: rgb(146 64 14 / 0.55) !important;
}

.tw-caret-amber-800\/60 {
  caret-color: rgb(146 64 14 / 0.6) !important;
}

.tw-caret-amber-800\/65 {
  caret-color: rgb(146 64 14 / 0.65) !important;
}

.tw-caret-amber-800\/70 {
  caret-color: rgb(146 64 14 / 0.7) !important;
}

.tw-caret-amber-800\/75 {
  caret-color: rgb(146 64 14 / 0.75) !important;
}

.tw-caret-amber-800\/80 {
  caret-color: rgb(146 64 14 / 0.8) !important;
}

.tw-caret-amber-800\/85 {
  caret-color: rgb(146 64 14 / 0.85) !important;
}

.tw-caret-amber-800\/90 {
  caret-color: rgb(146 64 14 / 0.9) !important;
}

.tw-caret-amber-800\/95 {
  caret-color: rgb(146 64 14 / 0.95) !important;
}

.tw-caret-amber-900 {
  caret-color: #78350f !important;
}

.tw-caret-amber-900\/0 {
  caret-color: rgb(120 53 15 / 0) !important;
}

.tw-caret-amber-900\/10 {
  caret-color: rgb(120 53 15 / 0.1) !important;
}

.tw-caret-amber-900\/100 {
  caret-color: rgb(120 53 15 / 1) !important;
}

.tw-caret-amber-900\/15 {
  caret-color: rgb(120 53 15 / 0.15) !important;
}

.tw-caret-amber-900\/20 {
  caret-color: rgb(120 53 15 / 0.2) !important;
}

.tw-caret-amber-900\/25 {
  caret-color: rgb(120 53 15 / 0.25) !important;
}

.tw-caret-amber-900\/30 {
  caret-color: rgb(120 53 15 / 0.3) !important;
}

.tw-caret-amber-900\/35 {
  caret-color: rgb(120 53 15 / 0.35) !important;
}

.tw-caret-amber-900\/40 {
  caret-color: rgb(120 53 15 / 0.4) !important;
}

.tw-caret-amber-900\/45 {
  caret-color: rgb(120 53 15 / 0.45) !important;
}

.tw-caret-amber-900\/5 {
  caret-color: rgb(120 53 15 / 0.05) !important;
}

.tw-caret-amber-900\/50 {
  caret-color: rgb(120 53 15 / 0.5) !important;
}

.tw-caret-amber-900\/55 {
  caret-color: rgb(120 53 15 / 0.55) !important;
}

.tw-caret-amber-900\/60 {
  caret-color: rgb(120 53 15 / 0.6) !important;
}

.tw-caret-amber-900\/65 {
  caret-color: rgb(120 53 15 / 0.65) !important;
}

.tw-caret-amber-900\/70 {
  caret-color: rgb(120 53 15 / 0.7) !important;
}

.tw-caret-amber-900\/75 {
  caret-color: rgb(120 53 15 / 0.75) !important;
}

.tw-caret-amber-900\/80 {
  caret-color: rgb(120 53 15 / 0.8) !important;
}

.tw-caret-amber-900\/85 {
  caret-color: rgb(120 53 15 / 0.85) !important;
}

.tw-caret-amber-900\/90 {
  caret-color: rgb(120 53 15 / 0.9) !important;
}

.tw-caret-amber-900\/95 {
  caret-color: rgb(120 53 15 / 0.95) !important;
}

.tw-caret-amber-950 {
  caret-color: #451a03 !important;
}

.tw-caret-amber-950\/0 {
  caret-color: rgb(69 26 3 / 0) !important;
}

.tw-caret-amber-950\/10 {
  caret-color: rgb(69 26 3 / 0.1) !important;
}

.tw-caret-amber-950\/100 {
  caret-color: rgb(69 26 3 / 1) !important;
}

.tw-caret-amber-950\/15 {
  caret-color: rgb(69 26 3 / 0.15) !important;
}

.tw-caret-amber-950\/20 {
  caret-color: rgb(69 26 3 / 0.2) !important;
}

.tw-caret-amber-950\/25 {
  caret-color: rgb(69 26 3 / 0.25) !important;
}

.tw-caret-amber-950\/30 {
  caret-color: rgb(69 26 3 / 0.3) !important;
}

.tw-caret-amber-950\/35 {
  caret-color: rgb(69 26 3 / 0.35) !important;
}

.tw-caret-amber-950\/40 {
  caret-color: rgb(69 26 3 / 0.4) !important;
}

.tw-caret-amber-950\/45 {
  caret-color: rgb(69 26 3 / 0.45) !important;
}

.tw-caret-amber-950\/5 {
  caret-color: rgb(69 26 3 / 0.05) !important;
}

.tw-caret-amber-950\/50 {
  caret-color: rgb(69 26 3 / 0.5) !important;
}

.tw-caret-amber-950\/55 {
  caret-color: rgb(69 26 3 / 0.55) !important;
}

.tw-caret-amber-950\/60 {
  caret-color: rgb(69 26 3 / 0.6) !important;
}

.tw-caret-amber-950\/65 {
  caret-color: rgb(69 26 3 / 0.65) !important;
}

.tw-caret-amber-950\/70 {
  caret-color: rgb(69 26 3 / 0.7) !important;
}

.tw-caret-amber-950\/75 {
  caret-color: rgb(69 26 3 / 0.75) !important;
}

.tw-caret-amber-950\/80 {
  caret-color: rgb(69 26 3 / 0.8) !important;
}

.tw-caret-amber-950\/85 {
  caret-color: rgb(69 26 3 / 0.85) !important;
}

.tw-caret-amber-950\/90 {
  caret-color: rgb(69 26 3 / 0.9) !important;
}

.tw-caret-amber-950\/95 {
  caret-color: rgb(69 26 3 / 0.95) !important;
}

.tw-caret-black {
  caret-color: #000 !important;
}

.tw-caret-black\/0 {
  caret-color: rgb(0 0 0 / 0) !important;
}

.tw-caret-black\/10 {
  caret-color: rgb(0 0 0 / 0.1) !important;
}

.tw-caret-black\/100 {
  caret-color: rgb(0 0 0 / 1) !important;
}

.tw-caret-black\/15 {
  caret-color: rgb(0 0 0 / 0.15) !important;
}

.tw-caret-black\/20 {
  caret-color: rgb(0 0 0 / 0.2) !important;
}

.tw-caret-black\/25 {
  caret-color: rgb(0 0 0 / 0.25) !important;
}

.tw-caret-black\/30 {
  caret-color: rgb(0 0 0 / 0.3) !important;
}

.tw-caret-black\/35 {
  caret-color: rgb(0 0 0 / 0.35) !important;
}

.tw-caret-black\/40 {
  caret-color: rgb(0 0 0 / 0.4) !important;
}

.tw-caret-black\/45 {
  caret-color: rgb(0 0 0 / 0.45) !important;
}

.tw-caret-black\/5 {
  caret-color: rgb(0 0 0 / 0.05) !important;
}

.tw-caret-black\/50 {
  caret-color: rgb(0 0 0 / 0.5) !important;
}

.tw-caret-black\/55 {
  caret-color: rgb(0 0 0 / 0.55) !important;
}

.tw-caret-black\/60 {
  caret-color: rgb(0 0 0 / 0.6) !important;
}

.tw-caret-black\/65 {
  caret-color: rgb(0 0 0 / 0.65) !important;
}

.tw-caret-black\/70 {
  caret-color: rgb(0 0 0 / 0.7) !important;
}

.tw-caret-black\/75 {
  caret-color: rgb(0 0 0 / 0.75) !important;
}

.tw-caret-black\/80 {
  caret-color: rgb(0 0 0 / 0.8) !important;
}

.tw-caret-black\/85 {
  caret-color: rgb(0 0 0 / 0.85) !important;
}

.tw-caret-black\/90 {
  caret-color: rgb(0 0 0 / 0.9) !important;
}

.tw-caret-black\/95 {
  caret-color: rgb(0 0 0 / 0.95) !important;
}

.tw-caret-blue-100 {
  caret-color: #dbeafe !important;
}

.tw-caret-blue-100\/0 {
  caret-color: rgb(219 234 254 / 0) !important;
}

.tw-caret-blue-100\/10 {
  caret-color: rgb(219 234 254 / 0.1) !important;
}

.tw-caret-blue-100\/100 {
  caret-color: rgb(219 234 254 / 1) !important;
}

.tw-caret-blue-100\/15 {
  caret-color: rgb(219 234 254 / 0.15) !important;
}

.tw-caret-blue-100\/20 {
  caret-color: rgb(219 234 254 / 0.2) !important;
}

.tw-caret-blue-100\/25 {
  caret-color: rgb(219 234 254 / 0.25) !important;
}

.tw-caret-blue-100\/30 {
  caret-color: rgb(219 234 254 / 0.3) !important;
}

.tw-caret-blue-100\/35 {
  caret-color: rgb(219 234 254 / 0.35) !important;
}

.tw-caret-blue-100\/40 {
  caret-color: rgb(219 234 254 / 0.4) !important;
}

.tw-caret-blue-100\/45 {
  caret-color: rgb(219 234 254 / 0.45) !important;
}

.tw-caret-blue-100\/5 {
  caret-color: rgb(219 234 254 / 0.05) !important;
}

.tw-caret-blue-100\/50 {
  caret-color: rgb(219 234 254 / 0.5) !important;
}

.tw-caret-blue-100\/55 {
  caret-color: rgb(219 234 254 / 0.55) !important;
}

.tw-caret-blue-100\/60 {
  caret-color: rgb(219 234 254 / 0.6) !important;
}

.tw-caret-blue-100\/65 {
  caret-color: rgb(219 234 254 / 0.65) !important;
}

.tw-caret-blue-100\/70 {
  caret-color: rgb(219 234 254 / 0.7) !important;
}

.tw-caret-blue-100\/75 {
  caret-color: rgb(219 234 254 / 0.75) !important;
}

.tw-caret-blue-100\/80 {
  caret-color: rgb(219 234 254 / 0.8) !important;
}

.tw-caret-blue-100\/85 {
  caret-color: rgb(219 234 254 / 0.85) !important;
}

.tw-caret-blue-100\/90 {
  caret-color: rgb(219 234 254 / 0.9) !important;
}

.tw-caret-blue-100\/95 {
  caret-color: rgb(219 234 254 / 0.95) !important;
}

.tw-caret-blue-200 {
  caret-color: #bfdbfe !important;
}

.tw-caret-blue-200\/0 {
  caret-color: rgb(191 219 254 / 0) !important;
}

.tw-caret-blue-200\/10 {
  caret-color: rgb(191 219 254 / 0.1) !important;
}

.tw-caret-blue-200\/100 {
  caret-color: rgb(191 219 254 / 1) !important;
}

.tw-caret-blue-200\/15 {
  caret-color: rgb(191 219 254 / 0.15) !important;
}

.tw-caret-blue-200\/20 {
  caret-color: rgb(191 219 254 / 0.2) !important;
}

.tw-caret-blue-200\/25 {
  caret-color: rgb(191 219 254 / 0.25) !important;
}

.tw-caret-blue-200\/30 {
  caret-color: rgb(191 219 254 / 0.3) !important;
}

.tw-caret-blue-200\/35 {
  caret-color: rgb(191 219 254 / 0.35) !important;
}

.tw-caret-blue-200\/40 {
  caret-color: rgb(191 219 254 / 0.4) !important;
}

.tw-caret-blue-200\/45 {
  caret-color: rgb(191 219 254 / 0.45) !important;
}

.tw-caret-blue-200\/5 {
  caret-color: rgb(191 219 254 / 0.05) !important;
}

.tw-caret-blue-200\/50 {
  caret-color: rgb(191 219 254 / 0.5) !important;
}

.tw-caret-blue-200\/55 {
  caret-color: rgb(191 219 254 / 0.55) !important;
}

.tw-caret-blue-200\/60 {
  caret-color: rgb(191 219 254 / 0.6) !important;
}

.tw-caret-blue-200\/65 {
  caret-color: rgb(191 219 254 / 0.65) !important;
}

.tw-caret-blue-200\/70 {
  caret-color: rgb(191 219 254 / 0.7) !important;
}

.tw-caret-blue-200\/75 {
  caret-color: rgb(191 219 254 / 0.75) !important;
}

.tw-caret-blue-200\/80 {
  caret-color: rgb(191 219 254 / 0.8) !important;
}

.tw-caret-blue-200\/85 {
  caret-color: rgb(191 219 254 / 0.85) !important;
}

.tw-caret-blue-200\/90 {
  caret-color: rgb(191 219 254 / 0.9) !important;
}

.tw-caret-blue-200\/95 {
  caret-color: rgb(191 219 254 / 0.95) !important;
}

.tw-caret-blue-300 {
  caret-color: #93c5fd !important;
}

.tw-caret-blue-300\/0 {
  caret-color: rgb(147 197 253 / 0) !important;
}

.tw-caret-blue-300\/10 {
  caret-color: rgb(147 197 253 / 0.1) !important;
}

.tw-caret-blue-300\/100 {
  caret-color: rgb(147 197 253 / 1) !important;
}

.tw-caret-blue-300\/15 {
  caret-color: rgb(147 197 253 / 0.15) !important;
}

.tw-caret-blue-300\/20 {
  caret-color: rgb(147 197 253 / 0.2) !important;
}

.tw-caret-blue-300\/25 {
  caret-color: rgb(147 197 253 / 0.25) !important;
}

.tw-caret-blue-300\/30 {
  caret-color: rgb(147 197 253 / 0.3) !important;
}

.tw-caret-blue-300\/35 {
  caret-color: rgb(147 197 253 / 0.35) !important;
}

.tw-caret-blue-300\/40 {
  caret-color: rgb(147 197 253 / 0.4) !important;
}

.tw-caret-blue-300\/45 {
  caret-color: rgb(147 197 253 / 0.45) !important;
}

.tw-caret-blue-300\/5 {
  caret-color: rgb(147 197 253 / 0.05) !important;
}

.tw-caret-blue-300\/50 {
  caret-color: rgb(147 197 253 / 0.5) !important;
}

.tw-caret-blue-300\/55 {
  caret-color: rgb(147 197 253 / 0.55) !important;
}

.tw-caret-blue-300\/60 {
  caret-color: rgb(147 197 253 / 0.6) !important;
}

.tw-caret-blue-300\/65 {
  caret-color: rgb(147 197 253 / 0.65) !important;
}

.tw-caret-blue-300\/70 {
  caret-color: rgb(147 197 253 / 0.7) !important;
}

.tw-caret-blue-300\/75 {
  caret-color: rgb(147 197 253 / 0.75) !important;
}

.tw-caret-blue-300\/80 {
  caret-color: rgb(147 197 253 / 0.8) !important;
}

.tw-caret-blue-300\/85 {
  caret-color: rgb(147 197 253 / 0.85) !important;
}

.tw-caret-blue-300\/90 {
  caret-color: rgb(147 197 253 / 0.9) !important;
}

.tw-caret-blue-300\/95 {
  caret-color: rgb(147 197 253 / 0.95) !important;
}

.tw-caret-blue-400 {
  caret-color: #60a5fa !important;
}

.tw-caret-blue-400\/0 {
  caret-color: rgb(96 165 250 / 0) !important;
}

.tw-caret-blue-400\/10 {
  caret-color: rgb(96 165 250 / 0.1) !important;
}

.tw-caret-blue-400\/100 {
  caret-color: rgb(96 165 250 / 1) !important;
}

.tw-caret-blue-400\/15 {
  caret-color: rgb(96 165 250 / 0.15) !important;
}

.tw-caret-blue-400\/20 {
  caret-color: rgb(96 165 250 / 0.2) !important;
}

.tw-caret-blue-400\/25 {
  caret-color: rgb(96 165 250 / 0.25) !important;
}

.tw-caret-blue-400\/30 {
  caret-color: rgb(96 165 250 / 0.3) !important;
}

.tw-caret-blue-400\/35 {
  caret-color: rgb(96 165 250 / 0.35) !important;
}

.tw-caret-blue-400\/40 {
  caret-color: rgb(96 165 250 / 0.4) !important;
}

.tw-caret-blue-400\/45 {
  caret-color: rgb(96 165 250 / 0.45) !important;
}

.tw-caret-blue-400\/5 {
  caret-color: rgb(96 165 250 / 0.05) !important;
}

.tw-caret-blue-400\/50 {
  caret-color: rgb(96 165 250 / 0.5) !important;
}

.tw-caret-blue-400\/55 {
  caret-color: rgb(96 165 250 / 0.55) !important;
}

.tw-caret-blue-400\/60 {
  caret-color: rgb(96 165 250 / 0.6) !important;
}

.tw-caret-blue-400\/65 {
  caret-color: rgb(96 165 250 / 0.65) !important;
}

.tw-caret-blue-400\/70 {
  caret-color: rgb(96 165 250 / 0.7) !important;
}

.tw-caret-blue-400\/75 {
  caret-color: rgb(96 165 250 / 0.75) !important;
}

.tw-caret-blue-400\/80 {
  caret-color: rgb(96 165 250 / 0.8) !important;
}

.tw-caret-blue-400\/85 {
  caret-color: rgb(96 165 250 / 0.85) !important;
}

.tw-caret-blue-400\/90 {
  caret-color: rgb(96 165 250 / 0.9) !important;
}

.tw-caret-blue-400\/95 {
  caret-color: rgb(96 165 250 / 0.95) !important;
}

.tw-caret-blue-50 {
  caret-color: #eff6ff !important;
}

.tw-caret-blue-50\/0 {
  caret-color: rgb(239 246 255 / 0) !important;
}

.tw-caret-blue-50\/10 {
  caret-color: rgb(239 246 255 / 0.1) !important;
}

.tw-caret-blue-50\/100 {
  caret-color: rgb(239 246 255 / 1) !important;
}

.tw-caret-blue-50\/15 {
  caret-color: rgb(239 246 255 / 0.15) !important;
}

.tw-caret-blue-50\/20 {
  caret-color: rgb(239 246 255 / 0.2) !important;
}

.tw-caret-blue-50\/25 {
  caret-color: rgb(239 246 255 / 0.25) !important;
}

.tw-caret-blue-50\/30 {
  caret-color: rgb(239 246 255 / 0.3) !important;
}

.tw-caret-blue-50\/35 {
  caret-color: rgb(239 246 255 / 0.35) !important;
}

.tw-caret-blue-50\/40 {
  caret-color: rgb(239 246 255 / 0.4) !important;
}

.tw-caret-blue-50\/45 {
  caret-color: rgb(239 246 255 / 0.45) !important;
}

.tw-caret-blue-50\/5 {
  caret-color: rgb(239 246 255 / 0.05) !important;
}

.tw-caret-blue-50\/50 {
  caret-color: rgb(239 246 255 / 0.5) !important;
}

.tw-caret-blue-50\/55 {
  caret-color: rgb(239 246 255 / 0.55) !important;
}

.tw-caret-blue-50\/60 {
  caret-color: rgb(239 246 255 / 0.6) !important;
}

.tw-caret-blue-50\/65 {
  caret-color: rgb(239 246 255 / 0.65) !important;
}

.tw-caret-blue-50\/70 {
  caret-color: rgb(239 246 255 / 0.7) !important;
}

.tw-caret-blue-50\/75 {
  caret-color: rgb(239 246 255 / 0.75) !important;
}

.tw-caret-blue-50\/80 {
  caret-color: rgb(239 246 255 / 0.8) !important;
}

.tw-caret-blue-50\/85 {
  caret-color: rgb(239 246 255 / 0.85) !important;
}

.tw-caret-blue-50\/90 {
  caret-color: rgb(239 246 255 / 0.9) !important;
}

.tw-caret-blue-50\/95 {
  caret-color: rgb(239 246 255 / 0.95) !important;
}

.tw-caret-blue-500 {
  caret-color: #3b82f6 !important;
}

.tw-caret-blue-500\/0 {
  caret-color: rgb(59 130 246 / 0) !important;
}

.tw-caret-blue-500\/10 {
  caret-color: rgb(59 130 246 / 0.1) !important;
}

.tw-caret-blue-500\/100 {
  caret-color: rgb(59 130 246 / 1) !important;
}

.tw-caret-blue-500\/15 {
  caret-color: rgb(59 130 246 / 0.15) !important;
}

.tw-caret-blue-500\/20 {
  caret-color: rgb(59 130 246 / 0.2) !important;
}

.tw-caret-blue-500\/25 {
  caret-color: rgb(59 130 246 / 0.25) !important;
}

.tw-caret-blue-500\/30 {
  caret-color: rgb(59 130 246 / 0.3) !important;
}

.tw-caret-blue-500\/35 {
  caret-color: rgb(59 130 246 / 0.35) !important;
}

.tw-caret-blue-500\/40 {
  caret-color: rgb(59 130 246 / 0.4) !important;
}

.tw-caret-blue-500\/45 {
  caret-color: rgb(59 130 246 / 0.45) !important;
}

.tw-caret-blue-500\/5 {
  caret-color: rgb(59 130 246 / 0.05) !important;
}

.tw-caret-blue-500\/50 {
  caret-color: rgb(59 130 246 / 0.5) !important;
}

.tw-caret-blue-500\/55 {
  caret-color: rgb(59 130 246 / 0.55) !important;
}

.tw-caret-blue-500\/60 {
  caret-color: rgb(59 130 246 / 0.6) !important;
}

.tw-caret-blue-500\/65 {
  caret-color: rgb(59 130 246 / 0.65) !important;
}

.tw-caret-blue-500\/70 {
  caret-color: rgb(59 130 246 / 0.7) !important;
}

.tw-caret-blue-500\/75 {
  caret-color: rgb(59 130 246 / 0.75) !important;
}

.tw-caret-blue-500\/80 {
  caret-color: rgb(59 130 246 / 0.8) !important;
}

.tw-caret-blue-500\/85 {
  caret-color: rgb(59 130 246 / 0.85) !important;
}

.tw-caret-blue-500\/90 {
  caret-color: rgb(59 130 246 / 0.9) !important;
}

.tw-caret-blue-500\/95 {
  caret-color: rgb(59 130 246 / 0.95) !important;
}

.tw-caret-blue-600 {
  caret-color: #2563eb !important;
}

.tw-caret-blue-600\/0 {
  caret-color: rgb(37 99 235 / 0) !important;
}

.tw-caret-blue-600\/10 {
  caret-color: rgb(37 99 235 / 0.1) !important;
}

.tw-caret-blue-600\/100 {
  caret-color: rgb(37 99 235 / 1) !important;
}

.tw-caret-blue-600\/15 {
  caret-color: rgb(37 99 235 / 0.15) !important;
}

.tw-caret-blue-600\/20 {
  caret-color: rgb(37 99 235 / 0.2) !important;
}

.tw-caret-blue-600\/25 {
  caret-color: rgb(37 99 235 / 0.25) !important;
}

.tw-caret-blue-600\/30 {
  caret-color: rgb(37 99 235 / 0.3) !important;
}

.tw-caret-blue-600\/35 {
  caret-color: rgb(37 99 235 / 0.35) !important;
}

.tw-caret-blue-600\/40 {
  caret-color: rgb(37 99 235 / 0.4) !important;
}

.tw-caret-blue-600\/45 {
  caret-color: rgb(37 99 235 / 0.45) !important;
}

.tw-caret-blue-600\/5 {
  caret-color: rgb(37 99 235 / 0.05) !important;
}

.tw-caret-blue-600\/50 {
  caret-color: rgb(37 99 235 / 0.5) !important;
}

.tw-caret-blue-600\/55 {
  caret-color: rgb(37 99 235 / 0.55) !important;
}

.tw-caret-blue-600\/60 {
  caret-color: rgb(37 99 235 / 0.6) !important;
}

.tw-caret-blue-600\/65 {
  caret-color: rgb(37 99 235 / 0.65) !important;
}

.tw-caret-blue-600\/70 {
  caret-color: rgb(37 99 235 / 0.7) !important;
}

.tw-caret-blue-600\/75 {
  caret-color: rgb(37 99 235 / 0.75) !important;
}

.tw-caret-blue-600\/80 {
  caret-color: rgb(37 99 235 / 0.8) !important;
}

.tw-caret-blue-600\/85 {
  caret-color: rgb(37 99 235 / 0.85) !important;
}

.tw-caret-blue-600\/90 {
  caret-color: rgb(37 99 235 / 0.9) !important;
}

.tw-caret-blue-600\/95 {
  caret-color: rgb(37 99 235 / 0.95) !important;
}

.tw-caret-blue-700 {
  caret-color: #1d4ed8 !important;
}

.tw-caret-blue-700\/0 {
  caret-color: rgb(29 78 216 / 0) !important;
}

.tw-caret-blue-700\/10 {
  caret-color: rgb(29 78 216 / 0.1) !important;
}

.tw-caret-blue-700\/100 {
  caret-color: rgb(29 78 216 / 1) !important;
}

.tw-caret-blue-700\/15 {
  caret-color: rgb(29 78 216 / 0.15) !important;
}

.tw-caret-blue-700\/20 {
  caret-color: rgb(29 78 216 / 0.2) !important;
}

.tw-caret-blue-700\/25 {
  caret-color: rgb(29 78 216 / 0.25) !important;
}

.tw-caret-blue-700\/30 {
  caret-color: rgb(29 78 216 / 0.3) !important;
}

.tw-caret-blue-700\/35 {
  caret-color: rgb(29 78 216 / 0.35) !important;
}

.tw-caret-blue-700\/40 {
  caret-color: rgb(29 78 216 / 0.4) !important;
}

.tw-caret-blue-700\/45 {
  caret-color: rgb(29 78 216 / 0.45) !important;
}

.tw-caret-blue-700\/5 {
  caret-color: rgb(29 78 216 / 0.05) !important;
}

.tw-caret-blue-700\/50 {
  caret-color: rgb(29 78 216 / 0.5) !important;
}

.tw-caret-blue-700\/55 {
  caret-color: rgb(29 78 216 / 0.55) !important;
}

.tw-caret-blue-700\/60 {
  caret-color: rgb(29 78 216 / 0.6) !important;
}

.tw-caret-blue-700\/65 {
  caret-color: rgb(29 78 216 / 0.65) !important;
}

.tw-caret-blue-700\/70 {
  caret-color: rgb(29 78 216 / 0.7) !important;
}

.tw-caret-blue-700\/75 {
  caret-color: rgb(29 78 216 / 0.75) !important;
}

.tw-caret-blue-700\/80 {
  caret-color: rgb(29 78 216 / 0.8) !important;
}

.tw-caret-blue-700\/85 {
  caret-color: rgb(29 78 216 / 0.85) !important;
}

.tw-caret-blue-700\/90 {
  caret-color: rgb(29 78 216 / 0.9) !important;
}

.tw-caret-blue-700\/95 {
  caret-color: rgb(29 78 216 / 0.95) !important;
}

.tw-caret-blue-800 {
  caret-color: #1e40af !important;
}

.tw-caret-blue-800\/0 {
  caret-color: rgb(30 64 175 / 0) !important;
}

.tw-caret-blue-800\/10 {
  caret-color: rgb(30 64 175 / 0.1) !important;
}

.tw-caret-blue-800\/100 {
  caret-color: rgb(30 64 175 / 1) !important;
}

.tw-caret-blue-800\/15 {
  caret-color: rgb(30 64 175 / 0.15) !important;
}

.tw-caret-blue-800\/20 {
  caret-color: rgb(30 64 175 / 0.2) !important;
}

.tw-caret-blue-800\/25 {
  caret-color: rgb(30 64 175 / 0.25) !important;
}

.tw-caret-blue-800\/30 {
  caret-color: rgb(30 64 175 / 0.3) !important;
}

.tw-caret-blue-800\/35 {
  caret-color: rgb(30 64 175 / 0.35) !important;
}

.tw-caret-blue-800\/40 {
  caret-color: rgb(30 64 175 / 0.4) !important;
}

.tw-caret-blue-800\/45 {
  caret-color: rgb(30 64 175 / 0.45) !important;
}

.tw-caret-blue-800\/5 {
  caret-color: rgb(30 64 175 / 0.05) !important;
}

.tw-caret-blue-800\/50 {
  caret-color: rgb(30 64 175 / 0.5) !important;
}

.tw-caret-blue-800\/55 {
  caret-color: rgb(30 64 175 / 0.55) !important;
}

.tw-caret-blue-800\/60 {
  caret-color: rgb(30 64 175 / 0.6) !important;
}

.tw-caret-blue-800\/65 {
  caret-color: rgb(30 64 175 / 0.65) !important;
}

.tw-caret-blue-800\/70 {
  caret-color: rgb(30 64 175 / 0.7) !important;
}

.tw-caret-blue-800\/75 {
  caret-color: rgb(30 64 175 / 0.75) !important;
}

.tw-caret-blue-800\/80 {
  caret-color: rgb(30 64 175 / 0.8) !important;
}

.tw-caret-blue-800\/85 {
  caret-color: rgb(30 64 175 / 0.85) !important;
}

.tw-caret-blue-800\/90 {
  caret-color: rgb(30 64 175 / 0.9) !important;
}

.tw-caret-blue-800\/95 {
  caret-color: rgb(30 64 175 / 0.95) !important;
}

.tw-caret-blue-900 {
  caret-color: #1e3a8a !important;
}

.tw-caret-blue-900\/0 {
  caret-color: rgb(30 58 138 / 0) !important;
}

.tw-caret-blue-900\/10 {
  caret-color: rgb(30 58 138 / 0.1) !important;
}

.tw-caret-blue-900\/100 {
  caret-color: rgb(30 58 138 / 1) !important;
}

.tw-caret-blue-900\/15 {
  caret-color: rgb(30 58 138 / 0.15) !important;
}

.tw-caret-blue-900\/20 {
  caret-color: rgb(30 58 138 / 0.2) !important;
}

.tw-caret-blue-900\/25 {
  caret-color: rgb(30 58 138 / 0.25) !important;
}

.tw-caret-blue-900\/30 {
  caret-color: rgb(30 58 138 / 0.3) !important;
}

.tw-caret-blue-900\/35 {
  caret-color: rgb(30 58 138 / 0.35) !important;
}

.tw-caret-blue-900\/40 {
  caret-color: rgb(30 58 138 / 0.4) !important;
}

.tw-caret-blue-900\/45 {
  caret-color: rgb(30 58 138 / 0.45) !important;
}

.tw-caret-blue-900\/5 {
  caret-color: rgb(30 58 138 / 0.05) !important;
}

.tw-caret-blue-900\/50 {
  caret-color: rgb(30 58 138 / 0.5) !important;
}

.tw-caret-blue-900\/55 {
  caret-color: rgb(30 58 138 / 0.55) !important;
}

.tw-caret-blue-900\/60 {
  caret-color: rgb(30 58 138 / 0.6) !important;
}

.tw-caret-blue-900\/65 {
  caret-color: rgb(30 58 138 / 0.65) !important;
}

.tw-caret-blue-900\/70 {
  caret-color: rgb(30 58 138 / 0.7) !important;
}

.tw-caret-blue-900\/75 {
  caret-color: rgb(30 58 138 / 0.75) !important;
}

.tw-caret-blue-900\/80 {
  caret-color: rgb(30 58 138 / 0.8) !important;
}

.tw-caret-blue-900\/85 {
  caret-color: rgb(30 58 138 / 0.85) !important;
}

.tw-caret-blue-900\/90 {
  caret-color: rgb(30 58 138 / 0.9) !important;
}

.tw-caret-blue-900\/95 {
  caret-color: rgb(30 58 138 / 0.95) !important;
}

.tw-caret-blue-950 {
  caret-color: #172554 !important;
}

.tw-caret-blue-950\/0 {
  caret-color: rgb(23 37 84 / 0) !important;
}

.tw-caret-blue-950\/10 {
  caret-color: rgb(23 37 84 / 0.1) !important;
}

.tw-caret-blue-950\/100 {
  caret-color: rgb(23 37 84 / 1) !important;
}

.tw-caret-blue-950\/15 {
  caret-color: rgb(23 37 84 / 0.15) !important;
}

.tw-caret-blue-950\/20 {
  caret-color: rgb(23 37 84 / 0.2) !important;
}

.tw-caret-blue-950\/25 {
  caret-color: rgb(23 37 84 / 0.25) !important;
}

.tw-caret-blue-950\/30 {
  caret-color: rgb(23 37 84 / 0.3) !important;
}

.tw-caret-blue-950\/35 {
  caret-color: rgb(23 37 84 / 0.35) !important;
}

.tw-caret-blue-950\/40 {
  caret-color: rgb(23 37 84 / 0.4) !important;
}

.tw-caret-blue-950\/45 {
  caret-color: rgb(23 37 84 / 0.45) !important;
}

.tw-caret-blue-950\/5 {
  caret-color: rgb(23 37 84 / 0.05) !important;
}

.tw-caret-blue-950\/50 {
  caret-color: rgb(23 37 84 / 0.5) !important;
}

.tw-caret-blue-950\/55 {
  caret-color: rgb(23 37 84 / 0.55) !important;
}

.tw-caret-blue-950\/60 {
  caret-color: rgb(23 37 84 / 0.6) !important;
}

.tw-caret-blue-950\/65 {
  caret-color: rgb(23 37 84 / 0.65) !important;
}

.tw-caret-blue-950\/70 {
  caret-color: rgb(23 37 84 / 0.7) !important;
}

.tw-caret-blue-950\/75 {
  caret-color: rgb(23 37 84 / 0.75) !important;
}

.tw-caret-blue-950\/80 {
  caret-color: rgb(23 37 84 / 0.8) !important;
}

.tw-caret-blue-950\/85 {
  caret-color: rgb(23 37 84 / 0.85) !important;
}

.tw-caret-blue-950\/90 {
  caret-color: rgb(23 37 84 / 0.9) !important;
}

.tw-caret-blue-950\/95 {
  caret-color: rgb(23 37 84 / 0.95) !important;
}

.tw-caret-current {
  caret-color: currentColor !important;
}

.tw-caret-cyan-100 {
  caret-color: #cffafe !important;
}

.tw-caret-cyan-100\/0 {
  caret-color: rgb(207 250 254 / 0) !important;
}

.tw-caret-cyan-100\/10 {
  caret-color: rgb(207 250 254 / 0.1) !important;
}

.tw-caret-cyan-100\/100 {
  caret-color: rgb(207 250 254 / 1) !important;
}

.tw-caret-cyan-100\/15 {
  caret-color: rgb(207 250 254 / 0.15) !important;
}

.tw-caret-cyan-100\/20 {
  caret-color: rgb(207 250 254 / 0.2) !important;
}

.tw-caret-cyan-100\/25 {
  caret-color: rgb(207 250 254 / 0.25) !important;
}

.tw-caret-cyan-100\/30 {
  caret-color: rgb(207 250 254 / 0.3) !important;
}

.tw-caret-cyan-100\/35 {
  caret-color: rgb(207 250 254 / 0.35) !important;
}

.tw-caret-cyan-100\/40 {
  caret-color: rgb(207 250 254 / 0.4) !important;
}

.tw-caret-cyan-100\/45 {
  caret-color: rgb(207 250 254 / 0.45) !important;
}

.tw-caret-cyan-100\/5 {
  caret-color: rgb(207 250 254 / 0.05) !important;
}

.tw-caret-cyan-100\/50 {
  caret-color: rgb(207 250 254 / 0.5) !important;
}

.tw-caret-cyan-100\/55 {
  caret-color: rgb(207 250 254 / 0.55) !important;
}

.tw-caret-cyan-100\/60 {
  caret-color: rgb(207 250 254 / 0.6) !important;
}

.tw-caret-cyan-100\/65 {
  caret-color: rgb(207 250 254 / 0.65) !important;
}

.tw-caret-cyan-100\/70 {
  caret-color: rgb(207 250 254 / 0.7) !important;
}

.tw-caret-cyan-100\/75 {
  caret-color: rgb(207 250 254 / 0.75) !important;
}

.tw-caret-cyan-100\/80 {
  caret-color: rgb(207 250 254 / 0.8) !important;
}

.tw-caret-cyan-100\/85 {
  caret-color: rgb(207 250 254 / 0.85) !important;
}

.tw-caret-cyan-100\/90 {
  caret-color: rgb(207 250 254 / 0.9) !important;
}

.tw-caret-cyan-100\/95 {
  caret-color: rgb(207 250 254 / 0.95) !important;
}

.tw-caret-cyan-200 {
  caret-color: #a5f3fc !important;
}

.tw-caret-cyan-200\/0 {
  caret-color: rgb(165 243 252 / 0) !important;
}

.tw-caret-cyan-200\/10 {
  caret-color: rgb(165 243 252 / 0.1) !important;
}

.tw-caret-cyan-200\/100 {
  caret-color: rgb(165 243 252 / 1) !important;
}

.tw-caret-cyan-200\/15 {
  caret-color: rgb(165 243 252 / 0.15) !important;
}

.tw-caret-cyan-200\/20 {
  caret-color: rgb(165 243 252 / 0.2) !important;
}

.tw-caret-cyan-200\/25 {
  caret-color: rgb(165 243 252 / 0.25) !important;
}

.tw-caret-cyan-200\/30 {
  caret-color: rgb(165 243 252 / 0.3) !important;
}

.tw-caret-cyan-200\/35 {
  caret-color: rgb(165 243 252 / 0.35) !important;
}

.tw-caret-cyan-200\/40 {
  caret-color: rgb(165 243 252 / 0.4) !important;
}

.tw-caret-cyan-200\/45 {
  caret-color: rgb(165 243 252 / 0.45) !important;
}

.tw-caret-cyan-200\/5 {
  caret-color: rgb(165 243 252 / 0.05) !important;
}

.tw-caret-cyan-200\/50 {
  caret-color: rgb(165 243 252 / 0.5) !important;
}

.tw-caret-cyan-200\/55 {
  caret-color: rgb(165 243 252 / 0.55) !important;
}

.tw-caret-cyan-200\/60 {
  caret-color: rgb(165 243 252 / 0.6) !important;
}

.tw-caret-cyan-200\/65 {
  caret-color: rgb(165 243 252 / 0.65) !important;
}

.tw-caret-cyan-200\/70 {
  caret-color: rgb(165 243 252 / 0.7) !important;
}

.tw-caret-cyan-200\/75 {
  caret-color: rgb(165 243 252 / 0.75) !important;
}

.tw-caret-cyan-200\/80 {
  caret-color: rgb(165 243 252 / 0.8) !important;
}

.tw-caret-cyan-200\/85 {
  caret-color: rgb(165 243 252 / 0.85) !important;
}

.tw-caret-cyan-200\/90 {
  caret-color: rgb(165 243 252 / 0.9) !important;
}

.tw-caret-cyan-200\/95 {
  caret-color: rgb(165 243 252 / 0.95) !important;
}

.tw-caret-cyan-300 {
  caret-color: #67e8f9 !important;
}

.tw-caret-cyan-300\/0 {
  caret-color: rgb(103 232 249 / 0) !important;
}

.tw-caret-cyan-300\/10 {
  caret-color: rgb(103 232 249 / 0.1) !important;
}

.tw-caret-cyan-300\/100 {
  caret-color: rgb(103 232 249 / 1) !important;
}

.tw-caret-cyan-300\/15 {
  caret-color: rgb(103 232 249 / 0.15) !important;
}

.tw-caret-cyan-300\/20 {
  caret-color: rgb(103 232 249 / 0.2) !important;
}

.tw-caret-cyan-300\/25 {
  caret-color: rgb(103 232 249 / 0.25) !important;
}

.tw-caret-cyan-300\/30 {
  caret-color: rgb(103 232 249 / 0.3) !important;
}

.tw-caret-cyan-300\/35 {
  caret-color: rgb(103 232 249 / 0.35) !important;
}

.tw-caret-cyan-300\/40 {
  caret-color: rgb(103 232 249 / 0.4) !important;
}

.tw-caret-cyan-300\/45 {
  caret-color: rgb(103 232 249 / 0.45) !important;
}

.tw-caret-cyan-300\/5 {
  caret-color: rgb(103 232 249 / 0.05) !important;
}

.tw-caret-cyan-300\/50 {
  caret-color: rgb(103 232 249 / 0.5) !important;
}

.tw-caret-cyan-300\/55 {
  caret-color: rgb(103 232 249 / 0.55) !important;
}

.tw-caret-cyan-300\/60 {
  caret-color: rgb(103 232 249 / 0.6) !important;
}

.tw-caret-cyan-300\/65 {
  caret-color: rgb(103 232 249 / 0.65) !important;
}

.tw-caret-cyan-300\/70 {
  caret-color: rgb(103 232 249 / 0.7) !important;
}

.tw-caret-cyan-300\/75 {
  caret-color: rgb(103 232 249 / 0.75) !important;
}

.tw-caret-cyan-300\/80 {
  caret-color: rgb(103 232 249 / 0.8) !important;
}

.tw-caret-cyan-300\/85 {
  caret-color: rgb(103 232 249 / 0.85) !important;
}

.tw-caret-cyan-300\/90 {
  caret-color: rgb(103 232 249 / 0.9) !important;
}

.tw-caret-cyan-300\/95 {
  caret-color: rgb(103 232 249 / 0.95) !important;
}

.tw-caret-cyan-400 {
  caret-color: #22d3ee !important;
}

.tw-caret-cyan-400\/0 {
  caret-color: rgb(34 211 238 / 0) !important;
}

.tw-caret-cyan-400\/10 {
  caret-color: rgb(34 211 238 / 0.1) !important;
}

.tw-caret-cyan-400\/100 {
  caret-color: rgb(34 211 238 / 1) !important;
}

.tw-caret-cyan-400\/15 {
  caret-color: rgb(34 211 238 / 0.15) !important;
}

.tw-caret-cyan-400\/20 {
  caret-color: rgb(34 211 238 / 0.2) !important;
}

.tw-caret-cyan-400\/25 {
  caret-color: rgb(34 211 238 / 0.25) !important;
}

.tw-caret-cyan-400\/30 {
  caret-color: rgb(34 211 238 / 0.3) !important;
}

.tw-caret-cyan-400\/35 {
  caret-color: rgb(34 211 238 / 0.35) !important;
}

.tw-caret-cyan-400\/40 {
  caret-color: rgb(34 211 238 / 0.4) !important;
}

.tw-caret-cyan-400\/45 {
  caret-color: rgb(34 211 238 / 0.45) !important;
}

.tw-caret-cyan-400\/5 {
  caret-color: rgb(34 211 238 / 0.05) !important;
}

.tw-caret-cyan-400\/50 {
  caret-color: rgb(34 211 238 / 0.5) !important;
}

.tw-caret-cyan-400\/55 {
  caret-color: rgb(34 211 238 / 0.55) !important;
}

.tw-caret-cyan-400\/60 {
  caret-color: rgb(34 211 238 / 0.6) !important;
}

.tw-caret-cyan-400\/65 {
  caret-color: rgb(34 211 238 / 0.65) !important;
}

.tw-caret-cyan-400\/70 {
  caret-color: rgb(34 211 238 / 0.7) !important;
}

.tw-caret-cyan-400\/75 {
  caret-color: rgb(34 211 238 / 0.75) !important;
}

.tw-caret-cyan-400\/80 {
  caret-color: rgb(34 211 238 / 0.8) !important;
}

.tw-caret-cyan-400\/85 {
  caret-color: rgb(34 211 238 / 0.85) !important;
}

.tw-caret-cyan-400\/90 {
  caret-color: rgb(34 211 238 / 0.9) !important;
}

.tw-caret-cyan-400\/95 {
  caret-color: rgb(34 211 238 / 0.95) !important;
}

.tw-caret-cyan-50 {
  caret-color: #ecfeff !important;
}

.tw-caret-cyan-50\/0 {
  caret-color: rgb(236 254 255 / 0) !important;
}

.tw-caret-cyan-50\/10 {
  caret-color: rgb(236 254 255 / 0.1) !important;
}

.tw-caret-cyan-50\/100 {
  caret-color: rgb(236 254 255 / 1) !important;
}

.tw-caret-cyan-50\/15 {
  caret-color: rgb(236 254 255 / 0.15) !important;
}

.tw-caret-cyan-50\/20 {
  caret-color: rgb(236 254 255 / 0.2) !important;
}

.tw-caret-cyan-50\/25 {
  caret-color: rgb(236 254 255 / 0.25) !important;
}

.tw-caret-cyan-50\/30 {
  caret-color: rgb(236 254 255 / 0.3) !important;
}

.tw-caret-cyan-50\/35 {
  caret-color: rgb(236 254 255 / 0.35) !important;
}

.tw-caret-cyan-50\/40 {
  caret-color: rgb(236 254 255 / 0.4) !important;
}

.tw-caret-cyan-50\/45 {
  caret-color: rgb(236 254 255 / 0.45) !important;
}

.tw-caret-cyan-50\/5 {
  caret-color: rgb(236 254 255 / 0.05) !important;
}

.tw-caret-cyan-50\/50 {
  caret-color: rgb(236 254 255 / 0.5) !important;
}

.tw-caret-cyan-50\/55 {
  caret-color: rgb(236 254 255 / 0.55) !important;
}

.tw-caret-cyan-50\/60 {
  caret-color: rgb(236 254 255 / 0.6) !important;
}

.tw-caret-cyan-50\/65 {
  caret-color: rgb(236 254 255 / 0.65) !important;
}

.tw-caret-cyan-50\/70 {
  caret-color: rgb(236 254 255 / 0.7) !important;
}

.tw-caret-cyan-50\/75 {
  caret-color: rgb(236 254 255 / 0.75) !important;
}

.tw-caret-cyan-50\/80 {
  caret-color: rgb(236 254 255 / 0.8) !important;
}

.tw-caret-cyan-50\/85 {
  caret-color: rgb(236 254 255 / 0.85) !important;
}

.tw-caret-cyan-50\/90 {
  caret-color: rgb(236 254 255 / 0.9) !important;
}

.tw-caret-cyan-50\/95 {
  caret-color: rgb(236 254 255 / 0.95) !important;
}

.tw-caret-cyan-500 {
  caret-color: #06b6d4 !important;
}

.tw-caret-cyan-500\/0 {
  caret-color: rgb(6 182 212 / 0) !important;
}

.tw-caret-cyan-500\/10 {
  caret-color: rgb(6 182 212 / 0.1) !important;
}

.tw-caret-cyan-500\/100 {
  caret-color: rgb(6 182 212 / 1) !important;
}

.tw-caret-cyan-500\/15 {
  caret-color: rgb(6 182 212 / 0.15) !important;
}

.tw-caret-cyan-500\/20 {
  caret-color: rgb(6 182 212 / 0.2) !important;
}

.tw-caret-cyan-500\/25 {
  caret-color: rgb(6 182 212 / 0.25) !important;
}

.tw-caret-cyan-500\/30 {
  caret-color: rgb(6 182 212 / 0.3) !important;
}

.tw-caret-cyan-500\/35 {
  caret-color: rgb(6 182 212 / 0.35) !important;
}

.tw-caret-cyan-500\/40 {
  caret-color: rgb(6 182 212 / 0.4) !important;
}

.tw-caret-cyan-500\/45 {
  caret-color: rgb(6 182 212 / 0.45) !important;
}

.tw-caret-cyan-500\/5 {
  caret-color: rgb(6 182 212 / 0.05) !important;
}

.tw-caret-cyan-500\/50 {
  caret-color: rgb(6 182 212 / 0.5) !important;
}

.tw-caret-cyan-500\/55 {
  caret-color: rgb(6 182 212 / 0.55) !important;
}

.tw-caret-cyan-500\/60 {
  caret-color: rgb(6 182 212 / 0.6) !important;
}

.tw-caret-cyan-500\/65 {
  caret-color: rgb(6 182 212 / 0.65) !important;
}

.tw-caret-cyan-500\/70 {
  caret-color: rgb(6 182 212 / 0.7) !important;
}

.tw-caret-cyan-500\/75 {
  caret-color: rgb(6 182 212 / 0.75) !important;
}

.tw-caret-cyan-500\/80 {
  caret-color: rgb(6 182 212 / 0.8) !important;
}

.tw-caret-cyan-500\/85 {
  caret-color: rgb(6 182 212 / 0.85) !important;
}

.tw-caret-cyan-500\/90 {
  caret-color: rgb(6 182 212 / 0.9) !important;
}

.tw-caret-cyan-500\/95 {
  caret-color: rgb(6 182 212 / 0.95) !important;
}

.tw-caret-cyan-600 {
  caret-color: #0891b2 !important;
}

.tw-caret-cyan-600\/0 {
  caret-color: rgb(8 145 178 / 0) !important;
}

.tw-caret-cyan-600\/10 {
  caret-color: rgb(8 145 178 / 0.1) !important;
}

.tw-caret-cyan-600\/100 {
  caret-color: rgb(8 145 178 / 1) !important;
}

.tw-caret-cyan-600\/15 {
  caret-color: rgb(8 145 178 / 0.15) !important;
}

.tw-caret-cyan-600\/20 {
  caret-color: rgb(8 145 178 / 0.2) !important;
}

.tw-caret-cyan-600\/25 {
  caret-color: rgb(8 145 178 / 0.25) !important;
}

.tw-caret-cyan-600\/30 {
  caret-color: rgb(8 145 178 / 0.3) !important;
}

.tw-caret-cyan-600\/35 {
  caret-color: rgb(8 145 178 / 0.35) !important;
}

.tw-caret-cyan-600\/40 {
  caret-color: rgb(8 145 178 / 0.4) !important;
}

.tw-caret-cyan-600\/45 {
  caret-color: rgb(8 145 178 / 0.45) !important;
}

.tw-caret-cyan-600\/5 {
  caret-color: rgb(8 145 178 / 0.05) !important;
}

.tw-caret-cyan-600\/50 {
  caret-color: rgb(8 145 178 / 0.5) !important;
}

.tw-caret-cyan-600\/55 {
  caret-color: rgb(8 145 178 / 0.55) !important;
}

.tw-caret-cyan-600\/60 {
  caret-color: rgb(8 145 178 / 0.6) !important;
}

.tw-caret-cyan-600\/65 {
  caret-color: rgb(8 145 178 / 0.65) !important;
}

.tw-caret-cyan-600\/70 {
  caret-color: rgb(8 145 178 / 0.7) !important;
}

.tw-caret-cyan-600\/75 {
  caret-color: rgb(8 145 178 / 0.75) !important;
}

.tw-caret-cyan-600\/80 {
  caret-color: rgb(8 145 178 / 0.8) !important;
}

.tw-caret-cyan-600\/85 {
  caret-color: rgb(8 145 178 / 0.85) !important;
}

.tw-caret-cyan-600\/90 {
  caret-color: rgb(8 145 178 / 0.9) !important;
}

.tw-caret-cyan-600\/95 {
  caret-color: rgb(8 145 178 / 0.95) !important;
}

.tw-caret-cyan-700 {
  caret-color: #0e7490 !important;
}

.tw-caret-cyan-700\/0 {
  caret-color: rgb(14 116 144 / 0) !important;
}

.tw-caret-cyan-700\/10 {
  caret-color: rgb(14 116 144 / 0.1) !important;
}

.tw-caret-cyan-700\/100 {
  caret-color: rgb(14 116 144 / 1) !important;
}

.tw-caret-cyan-700\/15 {
  caret-color: rgb(14 116 144 / 0.15) !important;
}

.tw-caret-cyan-700\/20 {
  caret-color: rgb(14 116 144 / 0.2) !important;
}

.tw-caret-cyan-700\/25 {
  caret-color: rgb(14 116 144 / 0.25) !important;
}

.tw-caret-cyan-700\/30 {
  caret-color: rgb(14 116 144 / 0.3) !important;
}

.tw-caret-cyan-700\/35 {
  caret-color: rgb(14 116 144 / 0.35) !important;
}

.tw-caret-cyan-700\/40 {
  caret-color: rgb(14 116 144 / 0.4) !important;
}

.tw-caret-cyan-700\/45 {
  caret-color: rgb(14 116 144 / 0.45) !important;
}

.tw-caret-cyan-700\/5 {
  caret-color: rgb(14 116 144 / 0.05) !important;
}

.tw-caret-cyan-700\/50 {
  caret-color: rgb(14 116 144 / 0.5) !important;
}

.tw-caret-cyan-700\/55 {
  caret-color: rgb(14 116 144 / 0.55) !important;
}

.tw-caret-cyan-700\/60 {
  caret-color: rgb(14 116 144 / 0.6) !important;
}

.tw-caret-cyan-700\/65 {
  caret-color: rgb(14 116 144 / 0.65) !important;
}

.tw-caret-cyan-700\/70 {
  caret-color: rgb(14 116 144 / 0.7) !important;
}

.tw-caret-cyan-700\/75 {
  caret-color: rgb(14 116 144 / 0.75) !important;
}

.tw-caret-cyan-700\/80 {
  caret-color: rgb(14 116 144 / 0.8) !important;
}

.tw-caret-cyan-700\/85 {
  caret-color: rgb(14 116 144 / 0.85) !important;
}

.tw-caret-cyan-700\/90 {
  caret-color: rgb(14 116 144 / 0.9) !important;
}

.tw-caret-cyan-700\/95 {
  caret-color: rgb(14 116 144 / 0.95) !important;
}

.tw-caret-cyan-800 {
  caret-color: #155e75 !important;
}

.tw-caret-cyan-800\/0 {
  caret-color: rgb(21 94 117 / 0) !important;
}

.tw-caret-cyan-800\/10 {
  caret-color: rgb(21 94 117 / 0.1) !important;
}

.tw-caret-cyan-800\/100 {
  caret-color: rgb(21 94 117 / 1) !important;
}

.tw-caret-cyan-800\/15 {
  caret-color: rgb(21 94 117 / 0.15) !important;
}

.tw-caret-cyan-800\/20 {
  caret-color: rgb(21 94 117 / 0.2) !important;
}

.tw-caret-cyan-800\/25 {
  caret-color: rgb(21 94 117 / 0.25) !important;
}

.tw-caret-cyan-800\/30 {
  caret-color: rgb(21 94 117 / 0.3) !important;
}

.tw-caret-cyan-800\/35 {
  caret-color: rgb(21 94 117 / 0.35) !important;
}

.tw-caret-cyan-800\/40 {
  caret-color: rgb(21 94 117 / 0.4) !important;
}

.tw-caret-cyan-800\/45 {
  caret-color: rgb(21 94 117 / 0.45) !important;
}

.tw-caret-cyan-800\/5 {
  caret-color: rgb(21 94 117 / 0.05) !important;
}

.tw-caret-cyan-800\/50 {
  caret-color: rgb(21 94 117 / 0.5) !important;
}

.tw-caret-cyan-800\/55 {
  caret-color: rgb(21 94 117 / 0.55) !important;
}

.tw-caret-cyan-800\/60 {
  caret-color: rgb(21 94 117 / 0.6) !important;
}

.tw-caret-cyan-800\/65 {
  caret-color: rgb(21 94 117 / 0.65) !important;
}

.tw-caret-cyan-800\/70 {
  caret-color: rgb(21 94 117 / 0.7) !important;
}

.tw-caret-cyan-800\/75 {
  caret-color: rgb(21 94 117 / 0.75) !important;
}

.tw-caret-cyan-800\/80 {
  caret-color: rgb(21 94 117 / 0.8) !important;
}

.tw-caret-cyan-800\/85 {
  caret-color: rgb(21 94 117 / 0.85) !important;
}

.tw-caret-cyan-800\/90 {
  caret-color: rgb(21 94 117 / 0.9) !important;
}

.tw-caret-cyan-800\/95 {
  caret-color: rgb(21 94 117 / 0.95) !important;
}

.tw-caret-cyan-900 {
  caret-color: #164e63 !important;
}

.tw-caret-cyan-900\/0 {
  caret-color: rgb(22 78 99 / 0) !important;
}

.tw-caret-cyan-900\/10 {
  caret-color: rgb(22 78 99 / 0.1) !important;
}

.tw-caret-cyan-900\/100 {
  caret-color: rgb(22 78 99 / 1) !important;
}

.tw-caret-cyan-900\/15 {
  caret-color: rgb(22 78 99 / 0.15) !important;
}

.tw-caret-cyan-900\/20 {
  caret-color: rgb(22 78 99 / 0.2) !important;
}

.tw-caret-cyan-900\/25 {
  caret-color: rgb(22 78 99 / 0.25) !important;
}

.tw-caret-cyan-900\/30 {
  caret-color: rgb(22 78 99 / 0.3) !important;
}

.tw-caret-cyan-900\/35 {
  caret-color: rgb(22 78 99 / 0.35) !important;
}

.tw-caret-cyan-900\/40 {
  caret-color: rgb(22 78 99 / 0.4) !important;
}

.tw-caret-cyan-900\/45 {
  caret-color: rgb(22 78 99 / 0.45) !important;
}

.tw-caret-cyan-900\/5 {
  caret-color: rgb(22 78 99 / 0.05) !important;
}

.tw-caret-cyan-900\/50 {
  caret-color: rgb(22 78 99 / 0.5) !important;
}

.tw-caret-cyan-900\/55 {
  caret-color: rgb(22 78 99 / 0.55) !important;
}

.tw-caret-cyan-900\/60 {
  caret-color: rgb(22 78 99 / 0.6) !important;
}

.tw-caret-cyan-900\/65 {
  caret-color: rgb(22 78 99 / 0.65) !important;
}

.tw-caret-cyan-900\/70 {
  caret-color: rgb(22 78 99 / 0.7) !important;
}

.tw-caret-cyan-900\/75 {
  caret-color: rgb(22 78 99 / 0.75) !important;
}

.tw-caret-cyan-900\/80 {
  caret-color: rgb(22 78 99 / 0.8) !important;
}

.tw-caret-cyan-900\/85 {
  caret-color: rgb(22 78 99 / 0.85) !important;
}

.tw-caret-cyan-900\/90 {
  caret-color: rgb(22 78 99 / 0.9) !important;
}

.tw-caret-cyan-900\/95 {
  caret-color: rgb(22 78 99 / 0.95) !important;
}

.tw-caret-cyan-950 {
  caret-color: #083344 !important;
}

.tw-caret-cyan-950\/0 {
  caret-color: rgb(8 51 68 / 0) !important;
}

.tw-caret-cyan-950\/10 {
  caret-color: rgb(8 51 68 / 0.1) !important;
}

.tw-caret-cyan-950\/100 {
  caret-color: rgb(8 51 68 / 1) !important;
}

.tw-caret-cyan-950\/15 {
  caret-color: rgb(8 51 68 / 0.15) !important;
}

.tw-caret-cyan-950\/20 {
  caret-color: rgb(8 51 68 / 0.2) !important;
}

.tw-caret-cyan-950\/25 {
  caret-color: rgb(8 51 68 / 0.25) !important;
}

.tw-caret-cyan-950\/30 {
  caret-color: rgb(8 51 68 / 0.3) !important;
}

.tw-caret-cyan-950\/35 {
  caret-color: rgb(8 51 68 / 0.35) !important;
}

.tw-caret-cyan-950\/40 {
  caret-color: rgb(8 51 68 / 0.4) !important;
}

.tw-caret-cyan-950\/45 {
  caret-color: rgb(8 51 68 / 0.45) !important;
}

.tw-caret-cyan-950\/5 {
  caret-color: rgb(8 51 68 / 0.05) !important;
}

.tw-caret-cyan-950\/50 {
  caret-color: rgb(8 51 68 / 0.5) !important;
}

.tw-caret-cyan-950\/55 {
  caret-color: rgb(8 51 68 / 0.55) !important;
}

.tw-caret-cyan-950\/60 {
  caret-color: rgb(8 51 68 / 0.6) !important;
}

.tw-caret-cyan-950\/65 {
  caret-color: rgb(8 51 68 / 0.65) !important;
}

.tw-caret-cyan-950\/70 {
  caret-color: rgb(8 51 68 / 0.7) !important;
}

.tw-caret-cyan-950\/75 {
  caret-color: rgb(8 51 68 / 0.75) !important;
}

.tw-caret-cyan-950\/80 {
  caret-color: rgb(8 51 68 / 0.8) !important;
}

.tw-caret-cyan-950\/85 {
  caret-color: rgb(8 51 68 / 0.85) !important;
}

.tw-caret-cyan-950\/90 {
  caret-color: rgb(8 51 68 / 0.9) !important;
}

.tw-caret-cyan-950\/95 {
  caret-color: rgb(8 51 68 / 0.95) !important;
}

.tw-caret-emerald-100 {
  caret-color: #d1fae5 !important;
}

.tw-caret-emerald-100\/0 {
  caret-color: rgb(209 250 229 / 0) !important;
}

.tw-caret-emerald-100\/10 {
  caret-color: rgb(209 250 229 / 0.1) !important;
}

.tw-caret-emerald-100\/100 {
  caret-color: rgb(209 250 229 / 1) !important;
}

.tw-caret-emerald-100\/15 {
  caret-color: rgb(209 250 229 / 0.15) !important;
}

.tw-caret-emerald-100\/20 {
  caret-color: rgb(209 250 229 / 0.2) !important;
}

.tw-caret-emerald-100\/25 {
  caret-color: rgb(209 250 229 / 0.25) !important;
}

.tw-caret-emerald-100\/30 {
  caret-color: rgb(209 250 229 / 0.3) !important;
}

.tw-caret-emerald-100\/35 {
  caret-color: rgb(209 250 229 / 0.35) !important;
}

.tw-caret-emerald-100\/40 {
  caret-color: rgb(209 250 229 / 0.4) !important;
}

.tw-caret-emerald-100\/45 {
  caret-color: rgb(209 250 229 / 0.45) !important;
}

.tw-caret-emerald-100\/5 {
  caret-color: rgb(209 250 229 / 0.05) !important;
}

.tw-caret-emerald-100\/50 {
  caret-color: rgb(209 250 229 / 0.5) !important;
}

.tw-caret-emerald-100\/55 {
  caret-color: rgb(209 250 229 / 0.55) !important;
}

.tw-caret-emerald-100\/60 {
  caret-color: rgb(209 250 229 / 0.6) !important;
}

.tw-caret-emerald-100\/65 {
  caret-color: rgb(209 250 229 / 0.65) !important;
}

.tw-caret-emerald-100\/70 {
  caret-color: rgb(209 250 229 / 0.7) !important;
}

.tw-caret-emerald-100\/75 {
  caret-color: rgb(209 250 229 / 0.75) !important;
}

.tw-caret-emerald-100\/80 {
  caret-color: rgb(209 250 229 / 0.8) !important;
}

.tw-caret-emerald-100\/85 {
  caret-color: rgb(209 250 229 / 0.85) !important;
}

.tw-caret-emerald-100\/90 {
  caret-color: rgb(209 250 229 / 0.9) !important;
}

.tw-caret-emerald-100\/95 {
  caret-color: rgb(209 250 229 / 0.95) !important;
}

.tw-caret-emerald-200 {
  caret-color: #a7f3d0 !important;
}

.tw-caret-emerald-200\/0 {
  caret-color: rgb(167 243 208 / 0) !important;
}

.tw-caret-emerald-200\/10 {
  caret-color: rgb(167 243 208 / 0.1) !important;
}

.tw-caret-emerald-200\/100 {
  caret-color: rgb(167 243 208 / 1) !important;
}

.tw-caret-emerald-200\/15 {
  caret-color: rgb(167 243 208 / 0.15) !important;
}

.tw-caret-emerald-200\/20 {
  caret-color: rgb(167 243 208 / 0.2) !important;
}

.tw-caret-emerald-200\/25 {
  caret-color: rgb(167 243 208 / 0.25) !important;
}

.tw-caret-emerald-200\/30 {
  caret-color: rgb(167 243 208 / 0.3) !important;
}

.tw-caret-emerald-200\/35 {
  caret-color: rgb(167 243 208 / 0.35) !important;
}

.tw-caret-emerald-200\/40 {
  caret-color: rgb(167 243 208 / 0.4) !important;
}

.tw-caret-emerald-200\/45 {
  caret-color: rgb(167 243 208 / 0.45) !important;
}

.tw-caret-emerald-200\/5 {
  caret-color: rgb(167 243 208 / 0.05) !important;
}

.tw-caret-emerald-200\/50 {
  caret-color: rgb(167 243 208 / 0.5) !important;
}

.tw-caret-emerald-200\/55 {
  caret-color: rgb(167 243 208 / 0.55) !important;
}

.tw-caret-emerald-200\/60 {
  caret-color: rgb(167 243 208 / 0.6) !important;
}

.tw-caret-emerald-200\/65 {
  caret-color: rgb(167 243 208 / 0.65) !important;
}

.tw-caret-emerald-200\/70 {
  caret-color: rgb(167 243 208 / 0.7) !important;
}

.tw-caret-emerald-200\/75 {
  caret-color: rgb(167 243 208 / 0.75) !important;
}

.tw-caret-emerald-200\/80 {
  caret-color: rgb(167 243 208 / 0.8) !important;
}

.tw-caret-emerald-200\/85 {
  caret-color: rgb(167 243 208 / 0.85) !important;
}

.tw-caret-emerald-200\/90 {
  caret-color: rgb(167 243 208 / 0.9) !important;
}

.tw-caret-emerald-200\/95 {
  caret-color: rgb(167 243 208 / 0.95) !important;
}

.tw-caret-emerald-300 {
  caret-color: #6ee7b7 !important;
}

.tw-caret-emerald-300\/0 {
  caret-color: rgb(110 231 183 / 0) !important;
}

.tw-caret-emerald-300\/10 {
  caret-color: rgb(110 231 183 / 0.1) !important;
}

.tw-caret-emerald-300\/100 {
  caret-color: rgb(110 231 183 / 1) !important;
}

.tw-caret-emerald-300\/15 {
  caret-color: rgb(110 231 183 / 0.15) !important;
}

.tw-caret-emerald-300\/20 {
  caret-color: rgb(110 231 183 / 0.2) !important;
}

.tw-caret-emerald-300\/25 {
  caret-color: rgb(110 231 183 / 0.25) !important;
}

.tw-caret-emerald-300\/30 {
  caret-color: rgb(110 231 183 / 0.3) !important;
}

.tw-caret-emerald-300\/35 {
  caret-color: rgb(110 231 183 / 0.35) !important;
}

.tw-caret-emerald-300\/40 {
  caret-color: rgb(110 231 183 / 0.4) !important;
}

.tw-caret-emerald-300\/45 {
  caret-color: rgb(110 231 183 / 0.45) !important;
}

.tw-caret-emerald-300\/5 {
  caret-color: rgb(110 231 183 / 0.05) !important;
}

.tw-caret-emerald-300\/50 {
  caret-color: rgb(110 231 183 / 0.5) !important;
}

.tw-caret-emerald-300\/55 {
  caret-color: rgb(110 231 183 / 0.55) !important;
}

.tw-caret-emerald-300\/60 {
  caret-color: rgb(110 231 183 / 0.6) !important;
}

.tw-caret-emerald-300\/65 {
  caret-color: rgb(110 231 183 / 0.65) !important;
}

.tw-caret-emerald-300\/70 {
  caret-color: rgb(110 231 183 / 0.7) !important;
}

.tw-caret-emerald-300\/75 {
  caret-color: rgb(110 231 183 / 0.75) !important;
}

.tw-caret-emerald-300\/80 {
  caret-color: rgb(110 231 183 / 0.8) !important;
}

.tw-caret-emerald-300\/85 {
  caret-color: rgb(110 231 183 / 0.85) !important;
}

.tw-caret-emerald-300\/90 {
  caret-color: rgb(110 231 183 / 0.9) !important;
}

.tw-caret-emerald-300\/95 {
  caret-color: rgb(110 231 183 / 0.95) !important;
}

.tw-caret-emerald-400 {
  caret-color: #34d399 !important;
}

.tw-caret-emerald-400\/0 {
  caret-color: rgb(52 211 153 / 0) !important;
}

.tw-caret-emerald-400\/10 {
  caret-color: rgb(52 211 153 / 0.1) !important;
}

.tw-caret-emerald-400\/100 {
  caret-color: rgb(52 211 153 / 1) !important;
}

.tw-caret-emerald-400\/15 {
  caret-color: rgb(52 211 153 / 0.15) !important;
}

.tw-caret-emerald-400\/20 {
  caret-color: rgb(52 211 153 / 0.2) !important;
}

.tw-caret-emerald-400\/25 {
  caret-color: rgb(52 211 153 / 0.25) !important;
}

.tw-caret-emerald-400\/30 {
  caret-color: rgb(52 211 153 / 0.3) !important;
}

.tw-caret-emerald-400\/35 {
  caret-color: rgb(52 211 153 / 0.35) !important;
}

.tw-caret-emerald-400\/40 {
  caret-color: rgb(52 211 153 / 0.4) !important;
}

.tw-caret-emerald-400\/45 {
  caret-color: rgb(52 211 153 / 0.45) !important;
}

.tw-caret-emerald-400\/5 {
  caret-color: rgb(52 211 153 / 0.05) !important;
}

.tw-caret-emerald-400\/50 {
  caret-color: rgb(52 211 153 / 0.5) !important;
}

.tw-caret-emerald-400\/55 {
  caret-color: rgb(52 211 153 / 0.55) !important;
}

.tw-caret-emerald-400\/60 {
  caret-color: rgb(52 211 153 / 0.6) !important;
}

.tw-caret-emerald-400\/65 {
  caret-color: rgb(52 211 153 / 0.65) !important;
}

.tw-caret-emerald-400\/70 {
  caret-color: rgb(52 211 153 / 0.7) !important;
}

.tw-caret-emerald-400\/75 {
  caret-color: rgb(52 211 153 / 0.75) !important;
}

.tw-caret-emerald-400\/80 {
  caret-color: rgb(52 211 153 / 0.8) !important;
}

.tw-caret-emerald-400\/85 {
  caret-color: rgb(52 211 153 / 0.85) !important;
}

.tw-caret-emerald-400\/90 {
  caret-color: rgb(52 211 153 / 0.9) !important;
}

.tw-caret-emerald-400\/95 {
  caret-color: rgb(52 211 153 / 0.95) !important;
}

.tw-caret-emerald-50 {
  caret-color: #ecfdf5 !important;
}

.tw-caret-emerald-50\/0 {
  caret-color: rgb(236 253 245 / 0) !important;
}

.tw-caret-emerald-50\/10 {
  caret-color: rgb(236 253 245 / 0.1) !important;
}

.tw-caret-emerald-50\/100 {
  caret-color: rgb(236 253 245 / 1) !important;
}

.tw-caret-emerald-50\/15 {
  caret-color: rgb(236 253 245 / 0.15) !important;
}

.tw-caret-emerald-50\/20 {
  caret-color: rgb(236 253 245 / 0.2) !important;
}

.tw-caret-emerald-50\/25 {
  caret-color: rgb(236 253 245 / 0.25) !important;
}

.tw-caret-emerald-50\/30 {
  caret-color: rgb(236 253 245 / 0.3) !important;
}

.tw-caret-emerald-50\/35 {
  caret-color: rgb(236 253 245 / 0.35) !important;
}

.tw-caret-emerald-50\/40 {
  caret-color: rgb(236 253 245 / 0.4) !important;
}

.tw-caret-emerald-50\/45 {
  caret-color: rgb(236 253 245 / 0.45) !important;
}

.tw-caret-emerald-50\/5 {
  caret-color: rgb(236 253 245 / 0.05) !important;
}

.tw-caret-emerald-50\/50 {
  caret-color: rgb(236 253 245 / 0.5) !important;
}

.tw-caret-emerald-50\/55 {
  caret-color: rgb(236 253 245 / 0.55) !important;
}

.tw-caret-emerald-50\/60 {
  caret-color: rgb(236 253 245 / 0.6) !important;
}

.tw-caret-emerald-50\/65 {
  caret-color: rgb(236 253 245 / 0.65) !important;
}

.tw-caret-emerald-50\/70 {
  caret-color: rgb(236 253 245 / 0.7) !important;
}

.tw-caret-emerald-50\/75 {
  caret-color: rgb(236 253 245 / 0.75) !important;
}

.tw-caret-emerald-50\/80 {
  caret-color: rgb(236 253 245 / 0.8) !important;
}

.tw-caret-emerald-50\/85 {
  caret-color: rgb(236 253 245 / 0.85) !important;
}

.tw-caret-emerald-50\/90 {
  caret-color: rgb(236 253 245 / 0.9) !important;
}

.tw-caret-emerald-50\/95 {
  caret-color: rgb(236 253 245 / 0.95) !important;
}

.tw-caret-emerald-500 {
  caret-color: #10b981 !important;
}

.tw-caret-emerald-500\/0 {
  caret-color: rgb(16 185 129 / 0) !important;
}

.tw-caret-emerald-500\/10 {
  caret-color: rgb(16 185 129 / 0.1) !important;
}

.tw-caret-emerald-500\/100 {
  caret-color: rgb(16 185 129 / 1) !important;
}

.tw-caret-emerald-500\/15 {
  caret-color: rgb(16 185 129 / 0.15) !important;
}

.tw-caret-emerald-500\/20 {
  caret-color: rgb(16 185 129 / 0.2) !important;
}

.tw-caret-emerald-500\/25 {
  caret-color: rgb(16 185 129 / 0.25) !important;
}

.tw-caret-emerald-500\/30 {
  caret-color: rgb(16 185 129 / 0.3) !important;
}

.tw-caret-emerald-500\/35 {
  caret-color: rgb(16 185 129 / 0.35) !important;
}

.tw-caret-emerald-500\/40 {
  caret-color: rgb(16 185 129 / 0.4) !important;
}

.tw-caret-emerald-500\/45 {
  caret-color: rgb(16 185 129 / 0.45) !important;
}

.tw-caret-emerald-500\/5 {
  caret-color: rgb(16 185 129 / 0.05) !important;
}

.tw-caret-emerald-500\/50 {
  caret-color: rgb(16 185 129 / 0.5) !important;
}

.tw-caret-emerald-500\/55 {
  caret-color: rgb(16 185 129 / 0.55) !important;
}

.tw-caret-emerald-500\/60 {
  caret-color: rgb(16 185 129 / 0.6) !important;
}

.tw-caret-emerald-500\/65 {
  caret-color: rgb(16 185 129 / 0.65) !important;
}

.tw-caret-emerald-500\/70 {
  caret-color: rgb(16 185 129 / 0.7) !important;
}

.tw-caret-emerald-500\/75 {
  caret-color: rgb(16 185 129 / 0.75) !important;
}

.tw-caret-emerald-500\/80 {
  caret-color: rgb(16 185 129 / 0.8) !important;
}

.tw-caret-emerald-500\/85 {
  caret-color: rgb(16 185 129 / 0.85) !important;
}

.tw-caret-emerald-500\/90 {
  caret-color: rgb(16 185 129 / 0.9) !important;
}

.tw-caret-emerald-500\/95 {
  caret-color: rgb(16 185 129 / 0.95) !important;
}

.tw-caret-emerald-600 {
  caret-color: #059669 !important;
}

.tw-caret-emerald-600\/0 {
  caret-color: rgb(5 150 105 / 0) !important;
}

.tw-caret-emerald-600\/10 {
  caret-color: rgb(5 150 105 / 0.1) !important;
}

.tw-caret-emerald-600\/100 {
  caret-color: rgb(5 150 105 / 1) !important;
}

.tw-caret-emerald-600\/15 {
  caret-color: rgb(5 150 105 / 0.15) !important;
}

.tw-caret-emerald-600\/20 {
  caret-color: rgb(5 150 105 / 0.2) !important;
}

.tw-caret-emerald-600\/25 {
  caret-color: rgb(5 150 105 / 0.25) !important;
}

.tw-caret-emerald-600\/30 {
  caret-color: rgb(5 150 105 / 0.3) !important;
}

.tw-caret-emerald-600\/35 {
  caret-color: rgb(5 150 105 / 0.35) !important;
}

.tw-caret-emerald-600\/40 {
  caret-color: rgb(5 150 105 / 0.4) !important;
}

.tw-caret-emerald-600\/45 {
  caret-color: rgb(5 150 105 / 0.45) !important;
}

.tw-caret-emerald-600\/5 {
  caret-color: rgb(5 150 105 / 0.05) !important;
}

.tw-caret-emerald-600\/50 {
  caret-color: rgb(5 150 105 / 0.5) !important;
}

.tw-caret-emerald-600\/55 {
  caret-color: rgb(5 150 105 / 0.55) !important;
}

.tw-caret-emerald-600\/60 {
  caret-color: rgb(5 150 105 / 0.6) !important;
}

.tw-caret-emerald-600\/65 {
  caret-color: rgb(5 150 105 / 0.65) !important;
}

.tw-caret-emerald-600\/70 {
  caret-color: rgb(5 150 105 / 0.7) !important;
}

.tw-caret-emerald-600\/75 {
  caret-color: rgb(5 150 105 / 0.75) !important;
}

.tw-caret-emerald-600\/80 {
  caret-color: rgb(5 150 105 / 0.8) !important;
}

.tw-caret-emerald-600\/85 {
  caret-color: rgb(5 150 105 / 0.85) !important;
}

.tw-caret-emerald-600\/90 {
  caret-color: rgb(5 150 105 / 0.9) !important;
}

.tw-caret-emerald-600\/95 {
  caret-color: rgb(5 150 105 / 0.95) !important;
}

.tw-caret-emerald-700 {
  caret-color: #047857 !important;
}

.tw-caret-emerald-700\/0 {
  caret-color: rgb(4 120 87 / 0) !important;
}

.tw-caret-emerald-700\/10 {
  caret-color: rgb(4 120 87 / 0.1) !important;
}

.tw-caret-emerald-700\/100 {
  caret-color: rgb(4 120 87 / 1) !important;
}

.tw-caret-emerald-700\/15 {
  caret-color: rgb(4 120 87 / 0.15) !important;
}

.tw-caret-emerald-700\/20 {
  caret-color: rgb(4 120 87 / 0.2) !important;
}

.tw-caret-emerald-700\/25 {
  caret-color: rgb(4 120 87 / 0.25) !important;
}

.tw-caret-emerald-700\/30 {
  caret-color: rgb(4 120 87 / 0.3) !important;
}

.tw-caret-emerald-700\/35 {
  caret-color: rgb(4 120 87 / 0.35) !important;
}

.tw-caret-emerald-700\/40 {
  caret-color: rgb(4 120 87 / 0.4) !important;
}

.tw-caret-emerald-700\/45 {
  caret-color: rgb(4 120 87 / 0.45) !important;
}

.tw-caret-emerald-700\/5 {
  caret-color: rgb(4 120 87 / 0.05) !important;
}

.tw-caret-emerald-700\/50 {
  caret-color: rgb(4 120 87 / 0.5) !important;
}

.tw-caret-emerald-700\/55 {
  caret-color: rgb(4 120 87 / 0.55) !important;
}

.tw-caret-emerald-700\/60 {
  caret-color: rgb(4 120 87 / 0.6) !important;
}

.tw-caret-emerald-700\/65 {
  caret-color: rgb(4 120 87 / 0.65) !important;
}

.tw-caret-emerald-700\/70 {
  caret-color: rgb(4 120 87 / 0.7) !important;
}

.tw-caret-emerald-700\/75 {
  caret-color: rgb(4 120 87 / 0.75) !important;
}

.tw-caret-emerald-700\/80 {
  caret-color: rgb(4 120 87 / 0.8) !important;
}

.tw-caret-emerald-700\/85 {
  caret-color: rgb(4 120 87 / 0.85) !important;
}

.tw-caret-emerald-700\/90 {
  caret-color: rgb(4 120 87 / 0.9) !important;
}

.tw-caret-emerald-700\/95 {
  caret-color: rgb(4 120 87 / 0.95) !important;
}

.tw-caret-emerald-800 {
  caret-color: #065f46 !important;
}

.tw-caret-emerald-800\/0 {
  caret-color: rgb(6 95 70 / 0) !important;
}

.tw-caret-emerald-800\/10 {
  caret-color: rgb(6 95 70 / 0.1) !important;
}

.tw-caret-emerald-800\/100 {
  caret-color: rgb(6 95 70 / 1) !important;
}

.tw-caret-emerald-800\/15 {
  caret-color: rgb(6 95 70 / 0.15) !important;
}

.tw-caret-emerald-800\/20 {
  caret-color: rgb(6 95 70 / 0.2) !important;
}

.tw-caret-emerald-800\/25 {
  caret-color: rgb(6 95 70 / 0.25) !important;
}

.tw-caret-emerald-800\/30 {
  caret-color: rgb(6 95 70 / 0.3) !important;
}

.tw-caret-emerald-800\/35 {
  caret-color: rgb(6 95 70 / 0.35) !important;
}

.tw-caret-emerald-800\/40 {
  caret-color: rgb(6 95 70 / 0.4) !important;
}

.tw-caret-emerald-800\/45 {
  caret-color: rgb(6 95 70 / 0.45) !important;
}

.tw-caret-emerald-800\/5 {
  caret-color: rgb(6 95 70 / 0.05) !important;
}

.tw-caret-emerald-800\/50 {
  caret-color: rgb(6 95 70 / 0.5) !important;
}

.tw-caret-emerald-800\/55 {
  caret-color: rgb(6 95 70 / 0.55) !important;
}

.tw-caret-emerald-800\/60 {
  caret-color: rgb(6 95 70 / 0.6) !important;
}

.tw-caret-emerald-800\/65 {
  caret-color: rgb(6 95 70 / 0.65) !important;
}

.tw-caret-emerald-800\/70 {
  caret-color: rgb(6 95 70 / 0.7) !important;
}

.tw-caret-emerald-800\/75 {
  caret-color: rgb(6 95 70 / 0.75) !important;
}

.tw-caret-emerald-800\/80 {
  caret-color: rgb(6 95 70 / 0.8) !important;
}

.tw-caret-emerald-800\/85 {
  caret-color: rgb(6 95 70 / 0.85) !important;
}

.tw-caret-emerald-800\/90 {
  caret-color: rgb(6 95 70 / 0.9) !important;
}

.tw-caret-emerald-800\/95 {
  caret-color: rgb(6 95 70 / 0.95) !important;
}

.tw-caret-emerald-900 {
  caret-color: #064e3b !important;
}

.tw-caret-emerald-900\/0 {
  caret-color: rgb(6 78 59 / 0) !important;
}

.tw-caret-emerald-900\/10 {
  caret-color: rgb(6 78 59 / 0.1) !important;
}

.tw-caret-emerald-900\/100 {
  caret-color: rgb(6 78 59 / 1) !important;
}

.tw-caret-emerald-900\/15 {
  caret-color: rgb(6 78 59 / 0.15) !important;
}

.tw-caret-emerald-900\/20 {
  caret-color: rgb(6 78 59 / 0.2) !important;
}

.tw-caret-emerald-900\/25 {
  caret-color: rgb(6 78 59 / 0.25) !important;
}

.tw-caret-emerald-900\/30 {
  caret-color: rgb(6 78 59 / 0.3) !important;
}

.tw-caret-emerald-900\/35 {
  caret-color: rgb(6 78 59 / 0.35) !important;
}

.tw-caret-emerald-900\/40 {
  caret-color: rgb(6 78 59 / 0.4) !important;
}

.tw-caret-emerald-900\/45 {
  caret-color: rgb(6 78 59 / 0.45) !important;
}

.tw-caret-emerald-900\/5 {
  caret-color: rgb(6 78 59 / 0.05) !important;
}

.tw-caret-emerald-900\/50 {
  caret-color: rgb(6 78 59 / 0.5) !important;
}

.tw-caret-emerald-900\/55 {
  caret-color: rgb(6 78 59 / 0.55) !important;
}

.tw-caret-emerald-900\/60 {
  caret-color: rgb(6 78 59 / 0.6) !important;
}

.tw-caret-emerald-900\/65 {
  caret-color: rgb(6 78 59 / 0.65) !important;
}

.tw-caret-emerald-900\/70 {
  caret-color: rgb(6 78 59 / 0.7) !important;
}

.tw-caret-emerald-900\/75 {
  caret-color: rgb(6 78 59 / 0.75) !important;
}

.tw-caret-emerald-900\/80 {
  caret-color: rgb(6 78 59 / 0.8) !important;
}

.tw-caret-emerald-900\/85 {
  caret-color: rgb(6 78 59 / 0.85) !important;
}

.tw-caret-emerald-900\/90 {
  caret-color: rgb(6 78 59 / 0.9) !important;
}

.tw-caret-emerald-900\/95 {
  caret-color: rgb(6 78 59 / 0.95) !important;
}

.tw-caret-emerald-950 {
  caret-color: #022c22 !important;
}

.tw-caret-emerald-950\/0 {
  caret-color: rgb(2 44 34 / 0) !important;
}

.tw-caret-emerald-950\/10 {
  caret-color: rgb(2 44 34 / 0.1) !important;
}

.tw-caret-emerald-950\/100 {
  caret-color: rgb(2 44 34 / 1) !important;
}

.tw-caret-emerald-950\/15 {
  caret-color: rgb(2 44 34 / 0.15) !important;
}

.tw-caret-emerald-950\/20 {
  caret-color: rgb(2 44 34 / 0.2) !important;
}

.tw-caret-emerald-950\/25 {
  caret-color: rgb(2 44 34 / 0.25) !important;
}

.tw-caret-emerald-950\/30 {
  caret-color: rgb(2 44 34 / 0.3) !important;
}

.tw-caret-emerald-950\/35 {
  caret-color: rgb(2 44 34 / 0.35) !important;
}

.tw-caret-emerald-950\/40 {
  caret-color: rgb(2 44 34 / 0.4) !important;
}

.tw-caret-emerald-950\/45 {
  caret-color: rgb(2 44 34 / 0.45) !important;
}

.tw-caret-emerald-950\/5 {
  caret-color: rgb(2 44 34 / 0.05) !important;
}

.tw-caret-emerald-950\/50 {
  caret-color: rgb(2 44 34 / 0.5) !important;
}

.tw-caret-emerald-950\/55 {
  caret-color: rgb(2 44 34 / 0.55) !important;
}

.tw-caret-emerald-950\/60 {
  caret-color: rgb(2 44 34 / 0.6) !important;
}

.tw-caret-emerald-950\/65 {
  caret-color: rgb(2 44 34 / 0.65) !important;
}

.tw-caret-emerald-950\/70 {
  caret-color: rgb(2 44 34 / 0.7) !important;
}

.tw-caret-emerald-950\/75 {
  caret-color: rgb(2 44 34 / 0.75) !important;
}

.tw-caret-emerald-950\/80 {
  caret-color: rgb(2 44 34 / 0.8) !important;
}

.tw-caret-emerald-950\/85 {
  caret-color: rgb(2 44 34 / 0.85) !important;
}

.tw-caret-emerald-950\/90 {
  caret-color: rgb(2 44 34 / 0.9) !important;
}

.tw-caret-emerald-950\/95 {
  caret-color: rgb(2 44 34 / 0.95) !important;
}

.tw-caret-fuchsia-100 {
  caret-color: #fae8ff !important;
}

.tw-caret-fuchsia-100\/0 {
  caret-color: rgb(250 232 255 / 0) !important;
}

.tw-caret-fuchsia-100\/10 {
  caret-color: rgb(250 232 255 / 0.1) !important;
}

.tw-caret-fuchsia-100\/100 {
  caret-color: rgb(250 232 255 / 1) !important;
}

.tw-caret-fuchsia-100\/15 {
  caret-color: rgb(250 232 255 / 0.15) !important;
}

.tw-caret-fuchsia-100\/20 {
  caret-color: rgb(250 232 255 / 0.2) !important;
}

.tw-caret-fuchsia-100\/25 {
  caret-color: rgb(250 232 255 / 0.25) !important;
}

.tw-caret-fuchsia-100\/30 {
  caret-color: rgb(250 232 255 / 0.3) !important;
}

.tw-caret-fuchsia-100\/35 {
  caret-color: rgb(250 232 255 / 0.35) !important;
}

.tw-caret-fuchsia-100\/40 {
  caret-color: rgb(250 232 255 / 0.4) !important;
}

.tw-caret-fuchsia-100\/45 {
  caret-color: rgb(250 232 255 / 0.45) !important;
}

.tw-caret-fuchsia-100\/5 {
  caret-color: rgb(250 232 255 / 0.05) !important;
}

.tw-caret-fuchsia-100\/50 {
  caret-color: rgb(250 232 255 / 0.5) !important;
}

.tw-caret-fuchsia-100\/55 {
  caret-color: rgb(250 232 255 / 0.55) !important;
}

.tw-caret-fuchsia-100\/60 {
  caret-color: rgb(250 232 255 / 0.6) !important;
}

.tw-caret-fuchsia-100\/65 {
  caret-color: rgb(250 232 255 / 0.65) !important;
}

.tw-caret-fuchsia-100\/70 {
  caret-color: rgb(250 232 255 / 0.7) !important;
}

.tw-caret-fuchsia-100\/75 {
  caret-color: rgb(250 232 255 / 0.75) !important;
}

.tw-caret-fuchsia-100\/80 {
  caret-color: rgb(250 232 255 / 0.8) !important;
}

.tw-caret-fuchsia-100\/85 {
  caret-color: rgb(250 232 255 / 0.85) !important;
}

.tw-caret-fuchsia-100\/90 {
  caret-color: rgb(250 232 255 / 0.9) !important;
}

.tw-caret-fuchsia-100\/95 {
  caret-color: rgb(250 232 255 / 0.95) !important;
}

.tw-caret-fuchsia-200 {
  caret-color: #f5d0fe !important;
}

.tw-caret-fuchsia-200\/0 {
  caret-color: rgb(245 208 254 / 0) !important;
}

.tw-caret-fuchsia-200\/10 {
  caret-color: rgb(245 208 254 / 0.1) !important;
}

.tw-caret-fuchsia-200\/100 {
  caret-color: rgb(245 208 254 / 1) !important;
}

.tw-caret-fuchsia-200\/15 {
  caret-color: rgb(245 208 254 / 0.15) !important;
}

.tw-caret-fuchsia-200\/20 {
  caret-color: rgb(245 208 254 / 0.2) !important;
}

.tw-caret-fuchsia-200\/25 {
  caret-color: rgb(245 208 254 / 0.25) !important;
}

.tw-caret-fuchsia-200\/30 {
  caret-color: rgb(245 208 254 / 0.3) !important;
}

.tw-caret-fuchsia-200\/35 {
  caret-color: rgb(245 208 254 / 0.35) !important;
}

.tw-caret-fuchsia-200\/40 {
  caret-color: rgb(245 208 254 / 0.4) !important;
}

.tw-caret-fuchsia-200\/45 {
  caret-color: rgb(245 208 254 / 0.45) !important;
}

.tw-caret-fuchsia-200\/5 {
  caret-color: rgb(245 208 254 / 0.05) !important;
}

.tw-caret-fuchsia-200\/50 {
  caret-color: rgb(245 208 254 / 0.5) !important;
}

.tw-caret-fuchsia-200\/55 {
  caret-color: rgb(245 208 254 / 0.55) !important;
}

.tw-caret-fuchsia-200\/60 {
  caret-color: rgb(245 208 254 / 0.6) !important;
}

.tw-caret-fuchsia-200\/65 {
  caret-color: rgb(245 208 254 / 0.65) !important;
}

.tw-caret-fuchsia-200\/70 {
  caret-color: rgb(245 208 254 / 0.7) !important;
}

.tw-caret-fuchsia-200\/75 {
  caret-color: rgb(245 208 254 / 0.75) !important;
}

.tw-caret-fuchsia-200\/80 {
  caret-color: rgb(245 208 254 / 0.8) !important;
}

.tw-caret-fuchsia-200\/85 {
  caret-color: rgb(245 208 254 / 0.85) !important;
}

.tw-caret-fuchsia-200\/90 {
  caret-color: rgb(245 208 254 / 0.9) !important;
}

.tw-caret-fuchsia-200\/95 {
  caret-color: rgb(245 208 254 / 0.95) !important;
}

.tw-caret-fuchsia-300 {
  caret-color: #f0abfc !important;
}

.tw-caret-fuchsia-300\/0 {
  caret-color: rgb(240 171 252 / 0) !important;
}

.tw-caret-fuchsia-300\/10 {
  caret-color: rgb(240 171 252 / 0.1) !important;
}

.tw-caret-fuchsia-300\/100 {
  caret-color: rgb(240 171 252 / 1) !important;
}

.tw-caret-fuchsia-300\/15 {
  caret-color: rgb(240 171 252 / 0.15) !important;
}

.tw-caret-fuchsia-300\/20 {
  caret-color: rgb(240 171 252 / 0.2) !important;
}

.tw-caret-fuchsia-300\/25 {
  caret-color: rgb(240 171 252 / 0.25) !important;
}

.tw-caret-fuchsia-300\/30 {
  caret-color: rgb(240 171 252 / 0.3) !important;
}

.tw-caret-fuchsia-300\/35 {
  caret-color: rgb(240 171 252 / 0.35) !important;
}

.tw-caret-fuchsia-300\/40 {
  caret-color: rgb(240 171 252 / 0.4) !important;
}

.tw-caret-fuchsia-300\/45 {
  caret-color: rgb(240 171 252 / 0.45) !important;
}

.tw-caret-fuchsia-300\/5 {
  caret-color: rgb(240 171 252 / 0.05) !important;
}

.tw-caret-fuchsia-300\/50 {
  caret-color: rgb(240 171 252 / 0.5) !important;
}

.tw-caret-fuchsia-300\/55 {
  caret-color: rgb(240 171 252 / 0.55) !important;
}

.tw-caret-fuchsia-300\/60 {
  caret-color: rgb(240 171 252 / 0.6) !important;
}

.tw-caret-fuchsia-300\/65 {
  caret-color: rgb(240 171 252 / 0.65) !important;
}

.tw-caret-fuchsia-300\/70 {
  caret-color: rgb(240 171 252 / 0.7) !important;
}

.tw-caret-fuchsia-300\/75 {
  caret-color: rgb(240 171 252 / 0.75) !important;
}

.tw-caret-fuchsia-300\/80 {
  caret-color: rgb(240 171 252 / 0.8) !important;
}

.tw-caret-fuchsia-300\/85 {
  caret-color: rgb(240 171 252 / 0.85) !important;
}

.tw-caret-fuchsia-300\/90 {
  caret-color: rgb(240 171 252 / 0.9) !important;
}

.tw-caret-fuchsia-300\/95 {
  caret-color: rgb(240 171 252 / 0.95) !important;
}

.tw-caret-fuchsia-400 {
  caret-color: #e879f9 !important;
}

.tw-caret-fuchsia-400\/0 {
  caret-color: rgb(232 121 249 / 0) !important;
}

.tw-caret-fuchsia-400\/10 {
  caret-color: rgb(232 121 249 / 0.1) !important;
}

.tw-caret-fuchsia-400\/100 {
  caret-color: rgb(232 121 249 / 1) !important;
}

.tw-caret-fuchsia-400\/15 {
  caret-color: rgb(232 121 249 / 0.15) !important;
}

.tw-caret-fuchsia-400\/20 {
  caret-color: rgb(232 121 249 / 0.2) !important;
}

.tw-caret-fuchsia-400\/25 {
  caret-color: rgb(232 121 249 / 0.25) !important;
}

.tw-caret-fuchsia-400\/30 {
  caret-color: rgb(232 121 249 / 0.3) !important;
}

.tw-caret-fuchsia-400\/35 {
  caret-color: rgb(232 121 249 / 0.35) !important;
}

.tw-caret-fuchsia-400\/40 {
  caret-color: rgb(232 121 249 / 0.4) !important;
}

.tw-caret-fuchsia-400\/45 {
  caret-color: rgb(232 121 249 / 0.45) !important;
}

.tw-caret-fuchsia-400\/5 {
  caret-color: rgb(232 121 249 / 0.05) !important;
}

.tw-caret-fuchsia-400\/50 {
  caret-color: rgb(232 121 249 / 0.5) !important;
}

.tw-caret-fuchsia-400\/55 {
  caret-color: rgb(232 121 249 / 0.55) !important;
}

.tw-caret-fuchsia-400\/60 {
  caret-color: rgb(232 121 249 / 0.6) !important;
}

.tw-caret-fuchsia-400\/65 {
  caret-color: rgb(232 121 249 / 0.65) !important;
}

.tw-caret-fuchsia-400\/70 {
  caret-color: rgb(232 121 249 / 0.7) !important;
}

.tw-caret-fuchsia-400\/75 {
  caret-color: rgb(232 121 249 / 0.75) !important;
}

.tw-caret-fuchsia-400\/80 {
  caret-color: rgb(232 121 249 / 0.8) !important;
}

.tw-caret-fuchsia-400\/85 {
  caret-color: rgb(232 121 249 / 0.85) !important;
}

.tw-caret-fuchsia-400\/90 {
  caret-color: rgb(232 121 249 / 0.9) !important;
}

.tw-caret-fuchsia-400\/95 {
  caret-color: rgb(232 121 249 / 0.95) !important;
}

.tw-caret-fuchsia-50 {
  caret-color: #fdf4ff !important;
}

.tw-caret-fuchsia-50\/0 {
  caret-color: rgb(253 244 255 / 0) !important;
}

.tw-caret-fuchsia-50\/10 {
  caret-color: rgb(253 244 255 / 0.1) !important;
}

.tw-caret-fuchsia-50\/100 {
  caret-color: rgb(253 244 255 / 1) !important;
}

.tw-caret-fuchsia-50\/15 {
  caret-color: rgb(253 244 255 / 0.15) !important;
}

.tw-caret-fuchsia-50\/20 {
  caret-color: rgb(253 244 255 / 0.2) !important;
}

.tw-caret-fuchsia-50\/25 {
  caret-color: rgb(253 244 255 / 0.25) !important;
}

.tw-caret-fuchsia-50\/30 {
  caret-color: rgb(253 244 255 / 0.3) !important;
}

.tw-caret-fuchsia-50\/35 {
  caret-color: rgb(253 244 255 / 0.35) !important;
}

.tw-caret-fuchsia-50\/40 {
  caret-color: rgb(253 244 255 / 0.4) !important;
}

.tw-caret-fuchsia-50\/45 {
  caret-color: rgb(253 244 255 / 0.45) !important;
}

.tw-caret-fuchsia-50\/5 {
  caret-color: rgb(253 244 255 / 0.05) !important;
}

.tw-caret-fuchsia-50\/50 {
  caret-color: rgb(253 244 255 / 0.5) !important;
}

.tw-caret-fuchsia-50\/55 {
  caret-color: rgb(253 244 255 / 0.55) !important;
}

.tw-caret-fuchsia-50\/60 {
  caret-color: rgb(253 244 255 / 0.6) !important;
}

.tw-caret-fuchsia-50\/65 {
  caret-color: rgb(253 244 255 / 0.65) !important;
}

.tw-caret-fuchsia-50\/70 {
  caret-color: rgb(253 244 255 / 0.7) !important;
}

.tw-caret-fuchsia-50\/75 {
  caret-color: rgb(253 244 255 / 0.75) !important;
}

.tw-caret-fuchsia-50\/80 {
  caret-color: rgb(253 244 255 / 0.8) !important;
}

.tw-caret-fuchsia-50\/85 {
  caret-color: rgb(253 244 255 / 0.85) !important;
}

.tw-caret-fuchsia-50\/90 {
  caret-color: rgb(253 244 255 / 0.9) !important;
}

.tw-caret-fuchsia-50\/95 {
  caret-color: rgb(253 244 255 / 0.95) !important;
}

.tw-caret-fuchsia-500 {
  caret-color: #d946ef !important;
}

.tw-caret-fuchsia-500\/0 {
  caret-color: rgb(217 70 239 / 0) !important;
}

.tw-caret-fuchsia-500\/10 {
  caret-color: rgb(217 70 239 / 0.1) !important;
}

.tw-caret-fuchsia-500\/100 {
  caret-color: rgb(217 70 239 / 1) !important;
}

.tw-caret-fuchsia-500\/15 {
  caret-color: rgb(217 70 239 / 0.15) !important;
}

.tw-caret-fuchsia-500\/20 {
  caret-color: rgb(217 70 239 / 0.2) !important;
}

.tw-caret-fuchsia-500\/25 {
  caret-color: rgb(217 70 239 / 0.25) !important;
}

.tw-caret-fuchsia-500\/30 {
  caret-color: rgb(217 70 239 / 0.3) !important;
}

.tw-caret-fuchsia-500\/35 {
  caret-color: rgb(217 70 239 / 0.35) !important;
}

.tw-caret-fuchsia-500\/40 {
  caret-color: rgb(217 70 239 / 0.4) !important;
}

.tw-caret-fuchsia-500\/45 {
  caret-color: rgb(217 70 239 / 0.45) !important;
}

.tw-caret-fuchsia-500\/5 {
  caret-color: rgb(217 70 239 / 0.05) !important;
}

.tw-caret-fuchsia-500\/50 {
  caret-color: rgb(217 70 239 / 0.5) !important;
}

.tw-caret-fuchsia-500\/55 {
  caret-color: rgb(217 70 239 / 0.55) !important;
}

.tw-caret-fuchsia-500\/60 {
  caret-color: rgb(217 70 239 / 0.6) !important;
}

.tw-caret-fuchsia-500\/65 {
  caret-color: rgb(217 70 239 / 0.65) !important;
}

.tw-caret-fuchsia-500\/70 {
  caret-color: rgb(217 70 239 / 0.7) !important;
}

.tw-caret-fuchsia-500\/75 {
  caret-color: rgb(217 70 239 / 0.75) !important;
}

.tw-caret-fuchsia-500\/80 {
  caret-color: rgb(217 70 239 / 0.8) !important;
}

.tw-caret-fuchsia-500\/85 {
  caret-color: rgb(217 70 239 / 0.85) !important;
}

.tw-caret-fuchsia-500\/90 {
  caret-color: rgb(217 70 239 / 0.9) !important;
}

.tw-caret-fuchsia-500\/95 {
  caret-color: rgb(217 70 239 / 0.95) !important;
}

.tw-caret-fuchsia-600 {
  caret-color: #c026d3 !important;
}

.tw-caret-fuchsia-600\/0 {
  caret-color: rgb(192 38 211 / 0) !important;
}

.tw-caret-fuchsia-600\/10 {
  caret-color: rgb(192 38 211 / 0.1) !important;
}

.tw-caret-fuchsia-600\/100 {
  caret-color: rgb(192 38 211 / 1) !important;
}

.tw-caret-fuchsia-600\/15 {
  caret-color: rgb(192 38 211 / 0.15) !important;
}

.tw-caret-fuchsia-600\/20 {
  caret-color: rgb(192 38 211 / 0.2) !important;
}

.tw-caret-fuchsia-600\/25 {
  caret-color: rgb(192 38 211 / 0.25) !important;
}

.tw-caret-fuchsia-600\/30 {
  caret-color: rgb(192 38 211 / 0.3) !important;
}

.tw-caret-fuchsia-600\/35 {
  caret-color: rgb(192 38 211 / 0.35) !important;
}

.tw-caret-fuchsia-600\/40 {
  caret-color: rgb(192 38 211 / 0.4) !important;
}

.tw-caret-fuchsia-600\/45 {
  caret-color: rgb(192 38 211 / 0.45) !important;
}

.tw-caret-fuchsia-600\/5 {
  caret-color: rgb(192 38 211 / 0.05) !important;
}

.tw-caret-fuchsia-600\/50 {
  caret-color: rgb(192 38 211 / 0.5) !important;
}

.tw-caret-fuchsia-600\/55 {
  caret-color: rgb(192 38 211 / 0.55) !important;
}

.tw-caret-fuchsia-600\/60 {
  caret-color: rgb(192 38 211 / 0.6) !important;
}

.tw-caret-fuchsia-600\/65 {
  caret-color: rgb(192 38 211 / 0.65) !important;
}

.tw-caret-fuchsia-600\/70 {
  caret-color: rgb(192 38 211 / 0.7) !important;
}

.tw-caret-fuchsia-600\/75 {
  caret-color: rgb(192 38 211 / 0.75) !important;
}

.tw-caret-fuchsia-600\/80 {
  caret-color: rgb(192 38 211 / 0.8) !important;
}

.tw-caret-fuchsia-600\/85 {
  caret-color: rgb(192 38 211 / 0.85) !important;
}

.tw-caret-fuchsia-600\/90 {
  caret-color: rgb(192 38 211 / 0.9) !important;
}

.tw-caret-fuchsia-600\/95 {
  caret-color: rgb(192 38 211 / 0.95) !important;
}

.tw-caret-fuchsia-700 {
  caret-color: #a21caf !important;
}

.tw-caret-fuchsia-700\/0 {
  caret-color: rgb(162 28 175 / 0) !important;
}

.tw-caret-fuchsia-700\/10 {
  caret-color: rgb(162 28 175 / 0.1) !important;
}

.tw-caret-fuchsia-700\/100 {
  caret-color: rgb(162 28 175 / 1) !important;
}

.tw-caret-fuchsia-700\/15 {
  caret-color: rgb(162 28 175 / 0.15) !important;
}

.tw-caret-fuchsia-700\/20 {
  caret-color: rgb(162 28 175 / 0.2) !important;
}

.tw-caret-fuchsia-700\/25 {
  caret-color: rgb(162 28 175 / 0.25) !important;
}

.tw-caret-fuchsia-700\/30 {
  caret-color: rgb(162 28 175 / 0.3) !important;
}

.tw-caret-fuchsia-700\/35 {
  caret-color: rgb(162 28 175 / 0.35) !important;
}

.tw-caret-fuchsia-700\/40 {
  caret-color: rgb(162 28 175 / 0.4) !important;
}

.tw-caret-fuchsia-700\/45 {
  caret-color: rgb(162 28 175 / 0.45) !important;
}

.tw-caret-fuchsia-700\/5 {
  caret-color: rgb(162 28 175 / 0.05) !important;
}

.tw-caret-fuchsia-700\/50 {
  caret-color: rgb(162 28 175 / 0.5) !important;
}

.tw-caret-fuchsia-700\/55 {
  caret-color: rgb(162 28 175 / 0.55) !important;
}

.tw-caret-fuchsia-700\/60 {
  caret-color: rgb(162 28 175 / 0.6) !important;
}

.tw-caret-fuchsia-700\/65 {
  caret-color: rgb(162 28 175 / 0.65) !important;
}

.tw-caret-fuchsia-700\/70 {
  caret-color: rgb(162 28 175 / 0.7) !important;
}

.tw-caret-fuchsia-700\/75 {
  caret-color: rgb(162 28 175 / 0.75) !important;
}

.tw-caret-fuchsia-700\/80 {
  caret-color: rgb(162 28 175 / 0.8) !important;
}

.tw-caret-fuchsia-700\/85 {
  caret-color: rgb(162 28 175 / 0.85) !important;
}

.tw-caret-fuchsia-700\/90 {
  caret-color: rgb(162 28 175 / 0.9) !important;
}

.tw-caret-fuchsia-700\/95 {
  caret-color: rgb(162 28 175 / 0.95) !important;
}

.tw-caret-fuchsia-800 {
  caret-color: #86198f !important;
}

.tw-caret-fuchsia-800\/0 {
  caret-color: rgb(134 25 143 / 0) !important;
}

.tw-caret-fuchsia-800\/10 {
  caret-color: rgb(134 25 143 / 0.1) !important;
}

.tw-caret-fuchsia-800\/100 {
  caret-color: rgb(134 25 143 / 1) !important;
}

.tw-caret-fuchsia-800\/15 {
  caret-color: rgb(134 25 143 / 0.15) !important;
}

.tw-caret-fuchsia-800\/20 {
  caret-color: rgb(134 25 143 / 0.2) !important;
}

.tw-caret-fuchsia-800\/25 {
  caret-color: rgb(134 25 143 / 0.25) !important;
}

.tw-caret-fuchsia-800\/30 {
  caret-color: rgb(134 25 143 / 0.3) !important;
}

.tw-caret-fuchsia-800\/35 {
  caret-color: rgb(134 25 143 / 0.35) !important;
}

.tw-caret-fuchsia-800\/40 {
  caret-color: rgb(134 25 143 / 0.4) !important;
}

.tw-caret-fuchsia-800\/45 {
  caret-color: rgb(134 25 143 / 0.45) !important;
}

.tw-caret-fuchsia-800\/5 {
  caret-color: rgb(134 25 143 / 0.05) !important;
}

.tw-caret-fuchsia-800\/50 {
  caret-color: rgb(134 25 143 / 0.5) !important;
}

.tw-caret-fuchsia-800\/55 {
  caret-color: rgb(134 25 143 / 0.55) !important;
}

.tw-caret-fuchsia-800\/60 {
  caret-color: rgb(134 25 143 / 0.6) !important;
}

.tw-caret-fuchsia-800\/65 {
  caret-color: rgb(134 25 143 / 0.65) !important;
}

.tw-caret-fuchsia-800\/70 {
  caret-color: rgb(134 25 143 / 0.7) !important;
}

.tw-caret-fuchsia-800\/75 {
  caret-color: rgb(134 25 143 / 0.75) !important;
}

.tw-caret-fuchsia-800\/80 {
  caret-color: rgb(134 25 143 / 0.8) !important;
}

.tw-caret-fuchsia-800\/85 {
  caret-color: rgb(134 25 143 / 0.85) !important;
}

.tw-caret-fuchsia-800\/90 {
  caret-color: rgb(134 25 143 / 0.9) !important;
}

.tw-caret-fuchsia-800\/95 {
  caret-color: rgb(134 25 143 / 0.95) !important;
}

.tw-caret-fuchsia-900 {
  caret-color: #701a75 !important;
}

.tw-caret-fuchsia-900\/0 {
  caret-color: rgb(112 26 117 / 0) !important;
}

.tw-caret-fuchsia-900\/10 {
  caret-color: rgb(112 26 117 / 0.1) !important;
}

.tw-caret-fuchsia-900\/100 {
  caret-color: rgb(112 26 117 / 1) !important;
}

.tw-caret-fuchsia-900\/15 {
  caret-color: rgb(112 26 117 / 0.15) !important;
}

.tw-caret-fuchsia-900\/20 {
  caret-color: rgb(112 26 117 / 0.2) !important;
}

.tw-caret-fuchsia-900\/25 {
  caret-color: rgb(112 26 117 / 0.25) !important;
}

.tw-caret-fuchsia-900\/30 {
  caret-color: rgb(112 26 117 / 0.3) !important;
}

.tw-caret-fuchsia-900\/35 {
  caret-color: rgb(112 26 117 / 0.35) !important;
}

.tw-caret-fuchsia-900\/40 {
  caret-color: rgb(112 26 117 / 0.4) !important;
}

.tw-caret-fuchsia-900\/45 {
  caret-color: rgb(112 26 117 / 0.45) !important;
}

.tw-caret-fuchsia-900\/5 {
  caret-color: rgb(112 26 117 / 0.05) !important;
}

.tw-caret-fuchsia-900\/50 {
  caret-color: rgb(112 26 117 / 0.5) !important;
}

.tw-caret-fuchsia-900\/55 {
  caret-color: rgb(112 26 117 / 0.55) !important;
}

.tw-caret-fuchsia-900\/60 {
  caret-color: rgb(112 26 117 / 0.6) !important;
}

.tw-caret-fuchsia-900\/65 {
  caret-color: rgb(112 26 117 / 0.65) !important;
}

.tw-caret-fuchsia-900\/70 {
  caret-color: rgb(112 26 117 / 0.7) !important;
}

.tw-caret-fuchsia-900\/75 {
  caret-color: rgb(112 26 117 / 0.75) !important;
}

.tw-caret-fuchsia-900\/80 {
  caret-color: rgb(112 26 117 / 0.8) !important;
}

.tw-caret-fuchsia-900\/85 {
  caret-color: rgb(112 26 117 / 0.85) !important;
}

.tw-caret-fuchsia-900\/90 {
  caret-color: rgb(112 26 117 / 0.9) !important;
}

.tw-caret-fuchsia-900\/95 {
  caret-color: rgb(112 26 117 / 0.95) !important;
}

.tw-caret-fuchsia-950 {
  caret-color: #4a044e !important;
}

.tw-caret-fuchsia-950\/0 {
  caret-color: rgb(74 4 78 / 0) !important;
}

.tw-caret-fuchsia-950\/10 {
  caret-color: rgb(74 4 78 / 0.1) !important;
}

.tw-caret-fuchsia-950\/100 {
  caret-color: rgb(74 4 78 / 1) !important;
}

.tw-caret-fuchsia-950\/15 {
  caret-color: rgb(74 4 78 / 0.15) !important;
}

.tw-caret-fuchsia-950\/20 {
  caret-color: rgb(74 4 78 / 0.2) !important;
}

.tw-caret-fuchsia-950\/25 {
  caret-color: rgb(74 4 78 / 0.25) !important;
}

.tw-caret-fuchsia-950\/30 {
  caret-color: rgb(74 4 78 / 0.3) !important;
}

.tw-caret-fuchsia-950\/35 {
  caret-color: rgb(74 4 78 / 0.35) !important;
}

.tw-caret-fuchsia-950\/40 {
  caret-color: rgb(74 4 78 / 0.4) !important;
}

.tw-caret-fuchsia-950\/45 {
  caret-color: rgb(74 4 78 / 0.45) !important;
}

.tw-caret-fuchsia-950\/5 {
  caret-color: rgb(74 4 78 / 0.05) !important;
}

.tw-caret-fuchsia-950\/50 {
  caret-color: rgb(74 4 78 / 0.5) !important;
}

.tw-caret-fuchsia-950\/55 {
  caret-color: rgb(74 4 78 / 0.55) !important;
}

.tw-caret-fuchsia-950\/60 {
  caret-color: rgb(74 4 78 / 0.6) !important;
}

.tw-caret-fuchsia-950\/65 {
  caret-color: rgb(74 4 78 / 0.65) !important;
}

.tw-caret-fuchsia-950\/70 {
  caret-color: rgb(74 4 78 / 0.7) !important;
}

.tw-caret-fuchsia-950\/75 {
  caret-color: rgb(74 4 78 / 0.75) !important;
}

.tw-caret-fuchsia-950\/80 {
  caret-color: rgb(74 4 78 / 0.8) !important;
}

.tw-caret-fuchsia-950\/85 {
  caret-color: rgb(74 4 78 / 0.85) !important;
}

.tw-caret-fuchsia-950\/90 {
  caret-color: rgb(74 4 78 / 0.9) !important;
}

.tw-caret-fuchsia-950\/95 {
  caret-color: rgb(74 4 78 / 0.95) !important;
}

.tw-caret-gray-100 {
  caret-color: #f3f4f6 !important;
}

.tw-caret-gray-100\/0 {
  caret-color: rgb(243 244 246 / 0) !important;
}

.tw-caret-gray-100\/10 {
  caret-color: rgb(243 244 246 / 0.1) !important;
}

.tw-caret-gray-100\/100 {
  caret-color: rgb(243 244 246 / 1) !important;
}

.tw-caret-gray-100\/15 {
  caret-color: rgb(243 244 246 / 0.15) !important;
}

.tw-caret-gray-100\/20 {
  caret-color: rgb(243 244 246 / 0.2) !important;
}

.tw-caret-gray-100\/25 {
  caret-color: rgb(243 244 246 / 0.25) !important;
}

.tw-caret-gray-100\/30 {
  caret-color: rgb(243 244 246 / 0.3) !important;
}

.tw-caret-gray-100\/35 {
  caret-color: rgb(243 244 246 / 0.35) !important;
}

.tw-caret-gray-100\/40 {
  caret-color: rgb(243 244 246 / 0.4) !important;
}

.tw-caret-gray-100\/45 {
  caret-color: rgb(243 244 246 / 0.45) !important;
}

.tw-caret-gray-100\/5 {
  caret-color: rgb(243 244 246 / 0.05) !important;
}

.tw-caret-gray-100\/50 {
  caret-color: rgb(243 244 246 / 0.5) !important;
}

.tw-caret-gray-100\/55 {
  caret-color: rgb(243 244 246 / 0.55) !important;
}

.tw-caret-gray-100\/60 {
  caret-color: rgb(243 244 246 / 0.6) !important;
}

.tw-caret-gray-100\/65 {
  caret-color: rgb(243 244 246 / 0.65) !important;
}

.tw-caret-gray-100\/70 {
  caret-color: rgb(243 244 246 / 0.7) !important;
}

.tw-caret-gray-100\/75 {
  caret-color: rgb(243 244 246 / 0.75) !important;
}

.tw-caret-gray-100\/80 {
  caret-color: rgb(243 244 246 / 0.8) !important;
}

.tw-caret-gray-100\/85 {
  caret-color: rgb(243 244 246 / 0.85) !important;
}

.tw-caret-gray-100\/90 {
  caret-color: rgb(243 244 246 / 0.9) !important;
}

.tw-caret-gray-100\/95 {
  caret-color: rgb(243 244 246 / 0.95) !important;
}

.tw-caret-gray-200 {
  caret-color: #e5e7eb !important;
}

.tw-caret-gray-200\/0 {
  caret-color: rgb(229 231 235 / 0) !important;
}

.tw-caret-gray-200\/10 {
  caret-color: rgb(229 231 235 / 0.1) !important;
}

.tw-caret-gray-200\/100 {
  caret-color: rgb(229 231 235 / 1) !important;
}

.tw-caret-gray-200\/15 {
  caret-color: rgb(229 231 235 / 0.15) !important;
}

.tw-caret-gray-200\/20 {
  caret-color: rgb(229 231 235 / 0.2) !important;
}

.tw-caret-gray-200\/25 {
  caret-color: rgb(229 231 235 / 0.25) !important;
}

.tw-caret-gray-200\/30 {
  caret-color: rgb(229 231 235 / 0.3) !important;
}

.tw-caret-gray-200\/35 {
  caret-color: rgb(229 231 235 / 0.35) !important;
}

.tw-caret-gray-200\/40 {
  caret-color: rgb(229 231 235 / 0.4) !important;
}

.tw-caret-gray-200\/45 {
  caret-color: rgb(229 231 235 / 0.45) !important;
}

.tw-caret-gray-200\/5 {
  caret-color: rgb(229 231 235 / 0.05) !important;
}

.tw-caret-gray-200\/50 {
  caret-color: rgb(229 231 235 / 0.5) !important;
}

.tw-caret-gray-200\/55 {
  caret-color: rgb(229 231 235 / 0.55) !important;
}

.tw-caret-gray-200\/60 {
  caret-color: rgb(229 231 235 / 0.6) !important;
}

.tw-caret-gray-200\/65 {
  caret-color: rgb(229 231 235 / 0.65) !important;
}

.tw-caret-gray-200\/70 {
  caret-color: rgb(229 231 235 / 0.7) !important;
}

.tw-caret-gray-200\/75 {
  caret-color: rgb(229 231 235 / 0.75) !important;
}

.tw-caret-gray-200\/80 {
  caret-color: rgb(229 231 235 / 0.8) !important;
}

.tw-caret-gray-200\/85 {
  caret-color: rgb(229 231 235 / 0.85) !important;
}

.tw-caret-gray-200\/90 {
  caret-color: rgb(229 231 235 / 0.9) !important;
}

.tw-caret-gray-200\/95 {
  caret-color: rgb(229 231 235 / 0.95) !important;
}

.tw-caret-gray-300 {
  caret-color: #d1d5db !important;
}

.tw-caret-gray-300\/0 {
  caret-color: rgb(209 213 219 / 0) !important;
}

.tw-caret-gray-300\/10 {
  caret-color: rgb(209 213 219 / 0.1) !important;
}

.tw-caret-gray-300\/100 {
  caret-color: rgb(209 213 219 / 1) !important;
}

.tw-caret-gray-300\/15 {
  caret-color: rgb(209 213 219 / 0.15) !important;
}

.tw-caret-gray-300\/20 {
  caret-color: rgb(209 213 219 / 0.2) !important;
}

.tw-caret-gray-300\/25 {
  caret-color: rgb(209 213 219 / 0.25) !important;
}

.tw-caret-gray-300\/30 {
  caret-color: rgb(209 213 219 / 0.3) !important;
}

.tw-caret-gray-300\/35 {
  caret-color: rgb(209 213 219 / 0.35) !important;
}

.tw-caret-gray-300\/40 {
  caret-color: rgb(209 213 219 / 0.4) !important;
}

.tw-caret-gray-300\/45 {
  caret-color: rgb(209 213 219 / 0.45) !important;
}

.tw-caret-gray-300\/5 {
  caret-color: rgb(209 213 219 / 0.05) !important;
}

.tw-caret-gray-300\/50 {
  caret-color: rgb(209 213 219 / 0.5) !important;
}

.tw-caret-gray-300\/55 {
  caret-color: rgb(209 213 219 / 0.55) !important;
}

.tw-caret-gray-300\/60 {
  caret-color: rgb(209 213 219 / 0.6) !important;
}

.tw-caret-gray-300\/65 {
  caret-color: rgb(209 213 219 / 0.65) !important;
}

.tw-caret-gray-300\/70 {
  caret-color: rgb(209 213 219 / 0.7) !important;
}

.tw-caret-gray-300\/75 {
  caret-color: rgb(209 213 219 / 0.75) !important;
}

.tw-caret-gray-300\/80 {
  caret-color: rgb(209 213 219 / 0.8) !important;
}

.tw-caret-gray-300\/85 {
  caret-color: rgb(209 213 219 / 0.85) !important;
}

.tw-caret-gray-300\/90 {
  caret-color: rgb(209 213 219 / 0.9) !important;
}

.tw-caret-gray-300\/95 {
  caret-color: rgb(209 213 219 / 0.95) !important;
}

.tw-caret-gray-400 {
  caret-color: #9ca3af !important;
}

.tw-caret-gray-400\/0 {
  caret-color: rgb(156 163 175 / 0) !important;
}

.tw-caret-gray-400\/10 {
  caret-color: rgb(156 163 175 / 0.1) !important;
}

.tw-caret-gray-400\/100 {
  caret-color: rgb(156 163 175 / 1) !important;
}

.tw-caret-gray-400\/15 {
  caret-color: rgb(156 163 175 / 0.15) !important;
}

.tw-caret-gray-400\/20 {
  caret-color: rgb(156 163 175 / 0.2) !important;
}

.tw-caret-gray-400\/25 {
  caret-color: rgb(156 163 175 / 0.25) !important;
}

.tw-caret-gray-400\/30 {
  caret-color: rgb(156 163 175 / 0.3) !important;
}

.tw-caret-gray-400\/35 {
  caret-color: rgb(156 163 175 / 0.35) !important;
}

.tw-caret-gray-400\/40 {
  caret-color: rgb(156 163 175 / 0.4) !important;
}

.tw-caret-gray-400\/45 {
  caret-color: rgb(156 163 175 / 0.45) !important;
}

.tw-caret-gray-400\/5 {
  caret-color: rgb(156 163 175 / 0.05) !important;
}

.tw-caret-gray-400\/50 {
  caret-color: rgb(156 163 175 / 0.5) !important;
}

.tw-caret-gray-400\/55 {
  caret-color: rgb(156 163 175 / 0.55) !important;
}

.tw-caret-gray-400\/60 {
  caret-color: rgb(156 163 175 / 0.6) !important;
}

.tw-caret-gray-400\/65 {
  caret-color: rgb(156 163 175 / 0.65) !important;
}

.tw-caret-gray-400\/70 {
  caret-color: rgb(156 163 175 / 0.7) !important;
}

.tw-caret-gray-400\/75 {
  caret-color: rgb(156 163 175 / 0.75) !important;
}

.tw-caret-gray-400\/80 {
  caret-color: rgb(156 163 175 / 0.8) !important;
}

.tw-caret-gray-400\/85 {
  caret-color: rgb(156 163 175 / 0.85) !important;
}

.tw-caret-gray-400\/90 {
  caret-color: rgb(156 163 175 / 0.9) !important;
}

.tw-caret-gray-400\/95 {
  caret-color: rgb(156 163 175 / 0.95) !important;
}

.tw-caret-gray-50 {
  caret-color: #f9fafb !important;
}

.tw-caret-gray-50\/0 {
  caret-color: rgb(249 250 251 / 0) !important;
}

.tw-caret-gray-50\/10 {
  caret-color: rgb(249 250 251 / 0.1) !important;
}

.tw-caret-gray-50\/100 {
  caret-color: rgb(249 250 251 / 1) !important;
}

.tw-caret-gray-50\/15 {
  caret-color: rgb(249 250 251 / 0.15) !important;
}

.tw-caret-gray-50\/20 {
  caret-color: rgb(249 250 251 / 0.2) !important;
}

.tw-caret-gray-50\/25 {
  caret-color: rgb(249 250 251 / 0.25) !important;
}

.tw-caret-gray-50\/30 {
  caret-color: rgb(249 250 251 / 0.3) !important;
}

.tw-caret-gray-50\/35 {
  caret-color: rgb(249 250 251 / 0.35) !important;
}

.tw-caret-gray-50\/40 {
  caret-color: rgb(249 250 251 / 0.4) !important;
}

.tw-caret-gray-50\/45 {
  caret-color: rgb(249 250 251 / 0.45) !important;
}

.tw-caret-gray-50\/5 {
  caret-color: rgb(249 250 251 / 0.05) !important;
}

.tw-caret-gray-50\/50 {
  caret-color: rgb(249 250 251 / 0.5) !important;
}

.tw-caret-gray-50\/55 {
  caret-color: rgb(249 250 251 / 0.55) !important;
}

.tw-caret-gray-50\/60 {
  caret-color: rgb(249 250 251 / 0.6) !important;
}

.tw-caret-gray-50\/65 {
  caret-color: rgb(249 250 251 / 0.65) !important;
}

.tw-caret-gray-50\/70 {
  caret-color: rgb(249 250 251 / 0.7) !important;
}

.tw-caret-gray-50\/75 {
  caret-color: rgb(249 250 251 / 0.75) !important;
}

.tw-caret-gray-50\/80 {
  caret-color: rgb(249 250 251 / 0.8) !important;
}

.tw-caret-gray-50\/85 {
  caret-color: rgb(249 250 251 / 0.85) !important;
}

.tw-caret-gray-50\/90 {
  caret-color: rgb(249 250 251 / 0.9) !important;
}

.tw-caret-gray-50\/95 {
  caret-color: rgb(249 250 251 / 0.95) !important;
}

.tw-caret-gray-500 {
  caret-color: #6b7280 !important;
}

.tw-caret-gray-500\/0 {
  caret-color: rgb(107 114 128 / 0) !important;
}

.tw-caret-gray-500\/10 {
  caret-color: rgb(107 114 128 / 0.1) !important;
}

.tw-caret-gray-500\/100 {
  caret-color: rgb(107 114 128 / 1) !important;
}

.tw-caret-gray-500\/15 {
  caret-color: rgb(107 114 128 / 0.15) !important;
}

.tw-caret-gray-500\/20 {
  caret-color: rgb(107 114 128 / 0.2) !important;
}

.tw-caret-gray-500\/25 {
  caret-color: rgb(107 114 128 / 0.25) !important;
}

.tw-caret-gray-500\/30 {
  caret-color: rgb(107 114 128 / 0.3) !important;
}

.tw-caret-gray-500\/35 {
  caret-color: rgb(107 114 128 / 0.35) !important;
}

.tw-caret-gray-500\/40 {
  caret-color: rgb(107 114 128 / 0.4) !important;
}

.tw-caret-gray-500\/45 {
  caret-color: rgb(107 114 128 / 0.45) !important;
}

.tw-caret-gray-500\/5 {
  caret-color: rgb(107 114 128 / 0.05) !important;
}

.tw-caret-gray-500\/50 {
  caret-color: rgb(107 114 128 / 0.5) !important;
}

.tw-caret-gray-500\/55 {
  caret-color: rgb(107 114 128 / 0.55) !important;
}

.tw-caret-gray-500\/60 {
  caret-color: rgb(107 114 128 / 0.6) !important;
}

.tw-caret-gray-500\/65 {
  caret-color: rgb(107 114 128 / 0.65) !important;
}

.tw-caret-gray-500\/70 {
  caret-color: rgb(107 114 128 / 0.7) !important;
}

.tw-caret-gray-500\/75 {
  caret-color: rgb(107 114 128 / 0.75) !important;
}

.tw-caret-gray-500\/80 {
  caret-color: rgb(107 114 128 / 0.8) !important;
}

.tw-caret-gray-500\/85 {
  caret-color: rgb(107 114 128 / 0.85) !important;
}

.tw-caret-gray-500\/90 {
  caret-color: rgb(107 114 128 / 0.9) !important;
}

.tw-caret-gray-500\/95 {
  caret-color: rgb(107 114 128 / 0.95) !important;
}

.tw-caret-gray-600 {
  caret-color: #4b5563 !important;
}

.tw-caret-gray-600\/0 {
  caret-color: rgb(75 85 99 / 0) !important;
}

.tw-caret-gray-600\/10 {
  caret-color: rgb(75 85 99 / 0.1) !important;
}

.tw-caret-gray-600\/100 {
  caret-color: rgb(75 85 99 / 1) !important;
}

.tw-caret-gray-600\/15 {
  caret-color: rgb(75 85 99 / 0.15) !important;
}

.tw-caret-gray-600\/20 {
  caret-color: rgb(75 85 99 / 0.2) !important;
}

.tw-caret-gray-600\/25 {
  caret-color: rgb(75 85 99 / 0.25) !important;
}

.tw-caret-gray-600\/30 {
  caret-color: rgb(75 85 99 / 0.3) !important;
}

.tw-caret-gray-600\/35 {
  caret-color: rgb(75 85 99 / 0.35) !important;
}

.tw-caret-gray-600\/40 {
  caret-color: rgb(75 85 99 / 0.4) !important;
}

.tw-caret-gray-600\/45 {
  caret-color: rgb(75 85 99 / 0.45) !important;
}

.tw-caret-gray-600\/5 {
  caret-color: rgb(75 85 99 / 0.05) !important;
}

.tw-caret-gray-600\/50 {
  caret-color: rgb(75 85 99 / 0.5) !important;
}

.tw-caret-gray-600\/55 {
  caret-color: rgb(75 85 99 / 0.55) !important;
}

.tw-caret-gray-600\/60 {
  caret-color: rgb(75 85 99 / 0.6) !important;
}

.tw-caret-gray-600\/65 {
  caret-color: rgb(75 85 99 / 0.65) !important;
}

.tw-caret-gray-600\/70 {
  caret-color: rgb(75 85 99 / 0.7) !important;
}

.tw-caret-gray-600\/75 {
  caret-color: rgb(75 85 99 / 0.75) !important;
}

.tw-caret-gray-600\/80 {
  caret-color: rgb(75 85 99 / 0.8) !important;
}

.tw-caret-gray-600\/85 {
  caret-color: rgb(75 85 99 / 0.85) !important;
}

.tw-caret-gray-600\/90 {
  caret-color: rgb(75 85 99 / 0.9) !important;
}

.tw-caret-gray-600\/95 {
  caret-color: rgb(75 85 99 / 0.95) !important;
}

.tw-caret-gray-700 {
  caret-color: #374151 !important;
}

.tw-caret-gray-700\/0 {
  caret-color: rgb(55 65 81 / 0) !important;
}

.tw-caret-gray-700\/10 {
  caret-color: rgb(55 65 81 / 0.1) !important;
}

.tw-caret-gray-700\/100 {
  caret-color: rgb(55 65 81 / 1) !important;
}

.tw-caret-gray-700\/15 {
  caret-color: rgb(55 65 81 / 0.15) !important;
}

.tw-caret-gray-700\/20 {
  caret-color: rgb(55 65 81 / 0.2) !important;
}

.tw-caret-gray-700\/25 {
  caret-color: rgb(55 65 81 / 0.25) !important;
}

.tw-caret-gray-700\/30 {
  caret-color: rgb(55 65 81 / 0.3) !important;
}

.tw-caret-gray-700\/35 {
  caret-color: rgb(55 65 81 / 0.35) !important;
}

.tw-caret-gray-700\/40 {
  caret-color: rgb(55 65 81 / 0.4) !important;
}

.tw-caret-gray-700\/45 {
  caret-color: rgb(55 65 81 / 0.45) !important;
}

.tw-caret-gray-700\/5 {
  caret-color: rgb(55 65 81 / 0.05) !important;
}

.tw-caret-gray-700\/50 {
  caret-color: rgb(55 65 81 / 0.5) !important;
}

.tw-caret-gray-700\/55 {
  caret-color: rgb(55 65 81 / 0.55) !important;
}

.tw-caret-gray-700\/60 {
  caret-color: rgb(55 65 81 / 0.6) !important;
}

.tw-caret-gray-700\/65 {
  caret-color: rgb(55 65 81 / 0.65) !important;
}

.tw-caret-gray-700\/70 {
  caret-color: rgb(55 65 81 / 0.7) !important;
}

.tw-caret-gray-700\/75 {
  caret-color: rgb(55 65 81 / 0.75) !important;
}

.tw-caret-gray-700\/80 {
  caret-color: rgb(55 65 81 / 0.8) !important;
}

.tw-caret-gray-700\/85 {
  caret-color: rgb(55 65 81 / 0.85) !important;
}

.tw-caret-gray-700\/90 {
  caret-color: rgb(55 65 81 / 0.9) !important;
}

.tw-caret-gray-700\/95 {
  caret-color: rgb(55 65 81 / 0.95) !important;
}

.tw-caret-gray-800 {
  caret-color: #1f2937 !important;
}

.tw-caret-gray-800\/0 {
  caret-color: rgb(31 41 55 / 0) !important;
}

.tw-caret-gray-800\/10 {
  caret-color: rgb(31 41 55 / 0.1) !important;
}

.tw-caret-gray-800\/100 {
  caret-color: rgb(31 41 55 / 1) !important;
}

.tw-caret-gray-800\/15 {
  caret-color: rgb(31 41 55 / 0.15) !important;
}

.tw-caret-gray-800\/20 {
  caret-color: rgb(31 41 55 / 0.2) !important;
}

.tw-caret-gray-800\/25 {
  caret-color: rgb(31 41 55 / 0.25) !important;
}

.tw-caret-gray-800\/30 {
  caret-color: rgb(31 41 55 / 0.3) !important;
}

.tw-caret-gray-800\/35 {
  caret-color: rgb(31 41 55 / 0.35) !important;
}

.tw-caret-gray-800\/40 {
  caret-color: rgb(31 41 55 / 0.4) !important;
}

.tw-caret-gray-800\/45 {
  caret-color: rgb(31 41 55 / 0.45) !important;
}

.tw-caret-gray-800\/5 {
  caret-color: rgb(31 41 55 / 0.05) !important;
}

.tw-caret-gray-800\/50 {
  caret-color: rgb(31 41 55 / 0.5) !important;
}

.tw-caret-gray-800\/55 {
  caret-color: rgb(31 41 55 / 0.55) !important;
}

.tw-caret-gray-800\/60 {
  caret-color: rgb(31 41 55 / 0.6) !important;
}

.tw-caret-gray-800\/65 {
  caret-color: rgb(31 41 55 / 0.65) !important;
}

.tw-caret-gray-800\/70 {
  caret-color: rgb(31 41 55 / 0.7) !important;
}

.tw-caret-gray-800\/75 {
  caret-color: rgb(31 41 55 / 0.75) !important;
}

.tw-caret-gray-800\/80 {
  caret-color: rgb(31 41 55 / 0.8) !important;
}

.tw-caret-gray-800\/85 {
  caret-color: rgb(31 41 55 / 0.85) !important;
}

.tw-caret-gray-800\/90 {
  caret-color: rgb(31 41 55 / 0.9) !important;
}

.tw-caret-gray-800\/95 {
  caret-color: rgb(31 41 55 / 0.95) !important;
}

.tw-caret-gray-900 {
  caret-color: #111827 !important;
}

.tw-caret-gray-900\/0 {
  caret-color: rgb(17 24 39 / 0) !important;
}

.tw-caret-gray-900\/10 {
  caret-color: rgb(17 24 39 / 0.1) !important;
}

.tw-caret-gray-900\/100 {
  caret-color: rgb(17 24 39 / 1) !important;
}

.tw-caret-gray-900\/15 {
  caret-color: rgb(17 24 39 / 0.15) !important;
}

.tw-caret-gray-900\/20 {
  caret-color: rgb(17 24 39 / 0.2) !important;
}

.tw-caret-gray-900\/25 {
  caret-color: rgb(17 24 39 / 0.25) !important;
}

.tw-caret-gray-900\/30 {
  caret-color: rgb(17 24 39 / 0.3) !important;
}

.tw-caret-gray-900\/35 {
  caret-color: rgb(17 24 39 / 0.35) !important;
}

.tw-caret-gray-900\/40 {
  caret-color: rgb(17 24 39 / 0.4) !important;
}

.tw-caret-gray-900\/45 {
  caret-color: rgb(17 24 39 / 0.45) !important;
}

.tw-caret-gray-900\/5 {
  caret-color: rgb(17 24 39 / 0.05) !important;
}

.tw-caret-gray-900\/50 {
  caret-color: rgb(17 24 39 / 0.5) !important;
}

.tw-caret-gray-900\/55 {
  caret-color: rgb(17 24 39 / 0.55) !important;
}

.tw-caret-gray-900\/60 {
  caret-color: rgb(17 24 39 / 0.6) !important;
}

.tw-caret-gray-900\/65 {
  caret-color: rgb(17 24 39 / 0.65) !important;
}

.tw-caret-gray-900\/70 {
  caret-color: rgb(17 24 39 / 0.7) !important;
}

.tw-caret-gray-900\/75 {
  caret-color: rgb(17 24 39 / 0.75) !important;
}

.tw-caret-gray-900\/80 {
  caret-color: rgb(17 24 39 / 0.8) !important;
}

.tw-caret-gray-900\/85 {
  caret-color: rgb(17 24 39 / 0.85) !important;
}

.tw-caret-gray-900\/90 {
  caret-color: rgb(17 24 39 / 0.9) !important;
}

.tw-caret-gray-900\/95 {
  caret-color: rgb(17 24 39 / 0.95) !important;
}

.tw-caret-gray-950 {
  caret-color: #030712 !important;
}

.tw-caret-gray-950\/0 {
  caret-color: rgb(3 7 18 / 0) !important;
}

.tw-caret-gray-950\/10 {
  caret-color: rgb(3 7 18 / 0.1) !important;
}

.tw-caret-gray-950\/100 {
  caret-color: rgb(3 7 18 / 1) !important;
}

.tw-caret-gray-950\/15 {
  caret-color: rgb(3 7 18 / 0.15) !important;
}

.tw-caret-gray-950\/20 {
  caret-color: rgb(3 7 18 / 0.2) !important;
}

.tw-caret-gray-950\/25 {
  caret-color: rgb(3 7 18 / 0.25) !important;
}

.tw-caret-gray-950\/30 {
  caret-color: rgb(3 7 18 / 0.3) !important;
}

.tw-caret-gray-950\/35 {
  caret-color: rgb(3 7 18 / 0.35) !important;
}

.tw-caret-gray-950\/40 {
  caret-color: rgb(3 7 18 / 0.4) !important;
}

.tw-caret-gray-950\/45 {
  caret-color: rgb(3 7 18 / 0.45) !important;
}

.tw-caret-gray-950\/5 {
  caret-color: rgb(3 7 18 / 0.05) !important;
}

.tw-caret-gray-950\/50 {
  caret-color: rgb(3 7 18 / 0.5) !important;
}

.tw-caret-gray-950\/55 {
  caret-color: rgb(3 7 18 / 0.55) !important;
}

.tw-caret-gray-950\/60 {
  caret-color: rgb(3 7 18 / 0.6) !important;
}

.tw-caret-gray-950\/65 {
  caret-color: rgb(3 7 18 / 0.65) !important;
}

.tw-caret-gray-950\/70 {
  caret-color: rgb(3 7 18 / 0.7) !important;
}

.tw-caret-gray-950\/75 {
  caret-color: rgb(3 7 18 / 0.75) !important;
}

.tw-caret-gray-950\/80 {
  caret-color: rgb(3 7 18 / 0.8) !important;
}

.tw-caret-gray-950\/85 {
  caret-color: rgb(3 7 18 / 0.85) !important;
}

.tw-caret-gray-950\/90 {
  caret-color: rgb(3 7 18 / 0.9) !important;
}

.tw-caret-gray-950\/95 {
  caret-color: rgb(3 7 18 / 0.95) !important;
}

.tw-caret-green-100 {
  caret-color: #dcfce7 !important;
}

.tw-caret-green-100\/0 {
  caret-color: rgb(220 252 231 / 0) !important;
}

.tw-caret-green-100\/10 {
  caret-color: rgb(220 252 231 / 0.1) !important;
}

.tw-caret-green-100\/100 {
  caret-color: rgb(220 252 231 / 1) !important;
}

.tw-caret-green-100\/15 {
  caret-color: rgb(220 252 231 / 0.15) !important;
}

.tw-caret-green-100\/20 {
  caret-color: rgb(220 252 231 / 0.2) !important;
}

.tw-caret-green-100\/25 {
  caret-color: rgb(220 252 231 / 0.25) !important;
}

.tw-caret-green-100\/30 {
  caret-color: rgb(220 252 231 / 0.3) !important;
}

.tw-caret-green-100\/35 {
  caret-color: rgb(220 252 231 / 0.35) !important;
}

.tw-caret-green-100\/40 {
  caret-color: rgb(220 252 231 / 0.4) !important;
}

.tw-caret-green-100\/45 {
  caret-color: rgb(220 252 231 / 0.45) !important;
}

.tw-caret-green-100\/5 {
  caret-color: rgb(220 252 231 / 0.05) !important;
}

.tw-caret-green-100\/50 {
  caret-color: rgb(220 252 231 / 0.5) !important;
}

.tw-caret-green-100\/55 {
  caret-color: rgb(220 252 231 / 0.55) !important;
}

.tw-caret-green-100\/60 {
  caret-color: rgb(220 252 231 / 0.6) !important;
}

.tw-caret-green-100\/65 {
  caret-color: rgb(220 252 231 / 0.65) !important;
}

.tw-caret-green-100\/70 {
  caret-color: rgb(220 252 231 / 0.7) !important;
}

.tw-caret-green-100\/75 {
  caret-color: rgb(220 252 231 / 0.75) !important;
}

.tw-caret-green-100\/80 {
  caret-color: rgb(220 252 231 / 0.8) !important;
}

.tw-caret-green-100\/85 {
  caret-color: rgb(220 252 231 / 0.85) !important;
}

.tw-caret-green-100\/90 {
  caret-color: rgb(220 252 231 / 0.9) !important;
}

.tw-caret-green-100\/95 {
  caret-color: rgb(220 252 231 / 0.95) !important;
}

.tw-caret-green-200 {
  caret-color: #bbf7d0 !important;
}

.tw-caret-green-200\/0 {
  caret-color: rgb(187 247 208 / 0) !important;
}

.tw-caret-green-200\/10 {
  caret-color: rgb(187 247 208 / 0.1) !important;
}

.tw-caret-green-200\/100 {
  caret-color: rgb(187 247 208 / 1) !important;
}

.tw-caret-green-200\/15 {
  caret-color: rgb(187 247 208 / 0.15) !important;
}

.tw-caret-green-200\/20 {
  caret-color: rgb(187 247 208 / 0.2) !important;
}

.tw-caret-green-200\/25 {
  caret-color: rgb(187 247 208 / 0.25) !important;
}

.tw-caret-green-200\/30 {
  caret-color: rgb(187 247 208 / 0.3) !important;
}

.tw-caret-green-200\/35 {
  caret-color: rgb(187 247 208 / 0.35) !important;
}

.tw-caret-green-200\/40 {
  caret-color: rgb(187 247 208 / 0.4) !important;
}

.tw-caret-green-200\/45 {
  caret-color: rgb(187 247 208 / 0.45) !important;
}

.tw-caret-green-200\/5 {
  caret-color: rgb(187 247 208 / 0.05) !important;
}

.tw-caret-green-200\/50 {
  caret-color: rgb(187 247 208 / 0.5) !important;
}

.tw-caret-green-200\/55 {
  caret-color: rgb(187 247 208 / 0.55) !important;
}

.tw-caret-green-200\/60 {
  caret-color: rgb(187 247 208 / 0.6) !important;
}

.tw-caret-green-200\/65 {
  caret-color: rgb(187 247 208 / 0.65) !important;
}

.tw-caret-green-200\/70 {
  caret-color: rgb(187 247 208 / 0.7) !important;
}

.tw-caret-green-200\/75 {
  caret-color: rgb(187 247 208 / 0.75) !important;
}

.tw-caret-green-200\/80 {
  caret-color: rgb(187 247 208 / 0.8) !important;
}

.tw-caret-green-200\/85 {
  caret-color: rgb(187 247 208 / 0.85) !important;
}

.tw-caret-green-200\/90 {
  caret-color: rgb(187 247 208 / 0.9) !important;
}

.tw-caret-green-200\/95 {
  caret-color: rgb(187 247 208 / 0.95) !important;
}

.tw-caret-green-300 {
  caret-color: #86efac !important;
}

.tw-caret-green-300\/0 {
  caret-color: rgb(134 239 172 / 0) !important;
}

.tw-caret-green-300\/10 {
  caret-color: rgb(134 239 172 / 0.1) !important;
}

.tw-caret-green-300\/100 {
  caret-color: rgb(134 239 172 / 1) !important;
}

.tw-caret-green-300\/15 {
  caret-color: rgb(134 239 172 / 0.15) !important;
}

.tw-caret-green-300\/20 {
  caret-color: rgb(134 239 172 / 0.2) !important;
}

.tw-caret-green-300\/25 {
  caret-color: rgb(134 239 172 / 0.25) !important;
}

.tw-caret-green-300\/30 {
  caret-color: rgb(134 239 172 / 0.3) !important;
}

.tw-caret-green-300\/35 {
  caret-color: rgb(134 239 172 / 0.35) !important;
}

.tw-caret-green-300\/40 {
  caret-color: rgb(134 239 172 / 0.4) !important;
}

.tw-caret-green-300\/45 {
  caret-color: rgb(134 239 172 / 0.45) !important;
}

.tw-caret-green-300\/5 {
  caret-color: rgb(134 239 172 / 0.05) !important;
}

.tw-caret-green-300\/50 {
  caret-color: rgb(134 239 172 / 0.5) !important;
}

.tw-caret-green-300\/55 {
  caret-color: rgb(134 239 172 / 0.55) !important;
}

.tw-caret-green-300\/60 {
  caret-color: rgb(134 239 172 / 0.6) !important;
}

.tw-caret-green-300\/65 {
  caret-color: rgb(134 239 172 / 0.65) !important;
}

.tw-caret-green-300\/70 {
  caret-color: rgb(134 239 172 / 0.7) !important;
}

.tw-caret-green-300\/75 {
  caret-color: rgb(134 239 172 / 0.75) !important;
}

.tw-caret-green-300\/80 {
  caret-color: rgb(134 239 172 / 0.8) !important;
}

.tw-caret-green-300\/85 {
  caret-color: rgb(134 239 172 / 0.85) !important;
}

.tw-caret-green-300\/90 {
  caret-color: rgb(134 239 172 / 0.9) !important;
}

.tw-caret-green-300\/95 {
  caret-color: rgb(134 239 172 / 0.95) !important;
}

.tw-caret-green-400 {
  caret-color: #4ade80 !important;
}

.tw-caret-green-400\/0 {
  caret-color: rgb(74 222 128 / 0) !important;
}

.tw-caret-green-400\/10 {
  caret-color: rgb(74 222 128 / 0.1) !important;
}

.tw-caret-green-400\/100 {
  caret-color: rgb(74 222 128 / 1) !important;
}

.tw-caret-green-400\/15 {
  caret-color: rgb(74 222 128 / 0.15) !important;
}

.tw-caret-green-400\/20 {
  caret-color: rgb(74 222 128 / 0.2) !important;
}

.tw-caret-green-400\/25 {
  caret-color: rgb(74 222 128 / 0.25) !important;
}

.tw-caret-green-400\/30 {
  caret-color: rgb(74 222 128 / 0.3) !important;
}

.tw-caret-green-400\/35 {
  caret-color: rgb(74 222 128 / 0.35) !important;
}

.tw-caret-green-400\/40 {
  caret-color: rgb(74 222 128 / 0.4) !important;
}

.tw-caret-green-400\/45 {
  caret-color: rgb(74 222 128 / 0.45) !important;
}

.tw-caret-green-400\/5 {
  caret-color: rgb(74 222 128 / 0.05) !important;
}

.tw-caret-green-400\/50 {
  caret-color: rgb(74 222 128 / 0.5) !important;
}

.tw-caret-green-400\/55 {
  caret-color: rgb(74 222 128 / 0.55) !important;
}

.tw-caret-green-400\/60 {
  caret-color: rgb(74 222 128 / 0.6) !important;
}

.tw-caret-green-400\/65 {
  caret-color: rgb(74 222 128 / 0.65) !important;
}

.tw-caret-green-400\/70 {
  caret-color: rgb(74 222 128 / 0.7) !important;
}

.tw-caret-green-400\/75 {
  caret-color: rgb(74 222 128 / 0.75) !important;
}

.tw-caret-green-400\/80 {
  caret-color: rgb(74 222 128 / 0.8) !important;
}

.tw-caret-green-400\/85 {
  caret-color: rgb(74 222 128 / 0.85) !important;
}

.tw-caret-green-400\/90 {
  caret-color: rgb(74 222 128 / 0.9) !important;
}

.tw-caret-green-400\/95 {
  caret-color: rgb(74 222 128 / 0.95) !important;
}

.tw-caret-green-50 {
  caret-color: #f0fdf4 !important;
}

.tw-caret-green-50\/0 {
  caret-color: rgb(240 253 244 / 0) !important;
}

.tw-caret-green-50\/10 {
  caret-color: rgb(240 253 244 / 0.1) !important;
}

.tw-caret-green-50\/100 {
  caret-color: rgb(240 253 244 / 1) !important;
}

.tw-caret-green-50\/15 {
  caret-color: rgb(240 253 244 / 0.15) !important;
}

.tw-caret-green-50\/20 {
  caret-color: rgb(240 253 244 / 0.2) !important;
}

.tw-caret-green-50\/25 {
  caret-color: rgb(240 253 244 / 0.25) !important;
}

.tw-caret-green-50\/30 {
  caret-color: rgb(240 253 244 / 0.3) !important;
}

.tw-caret-green-50\/35 {
  caret-color: rgb(240 253 244 / 0.35) !important;
}

.tw-caret-green-50\/40 {
  caret-color: rgb(240 253 244 / 0.4) !important;
}

.tw-caret-green-50\/45 {
  caret-color: rgb(240 253 244 / 0.45) !important;
}

.tw-caret-green-50\/5 {
  caret-color: rgb(240 253 244 / 0.05) !important;
}

.tw-caret-green-50\/50 {
  caret-color: rgb(240 253 244 / 0.5) !important;
}

.tw-caret-green-50\/55 {
  caret-color: rgb(240 253 244 / 0.55) !important;
}

.tw-caret-green-50\/60 {
  caret-color: rgb(240 253 244 / 0.6) !important;
}

.tw-caret-green-50\/65 {
  caret-color: rgb(240 253 244 / 0.65) !important;
}

.tw-caret-green-50\/70 {
  caret-color: rgb(240 253 244 / 0.7) !important;
}

.tw-caret-green-50\/75 {
  caret-color: rgb(240 253 244 / 0.75) !important;
}

.tw-caret-green-50\/80 {
  caret-color: rgb(240 253 244 / 0.8) !important;
}

.tw-caret-green-50\/85 {
  caret-color: rgb(240 253 244 / 0.85) !important;
}

.tw-caret-green-50\/90 {
  caret-color: rgb(240 253 244 / 0.9) !important;
}

.tw-caret-green-50\/95 {
  caret-color: rgb(240 253 244 / 0.95) !important;
}

.tw-caret-green-500 {
  caret-color: #22c55e !important;
}

.tw-caret-green-500\/0 {
  caret-color: rgb(34 197 94 / 0) !important;
}

.tw-caret-green-500\/10 {
  caret-color: rgb(34 197 94 / 0.1) !important;
}

.tw-caret-green-500\/100 {
  caret-color: rgb(34 197 94 / 1) !important;
}

.tw-caret-green-500\/15 {
  caret-color: rgb(34 197 94 / 0.15) !important;
}

.tw-caret-green-500\/20 {
  caret-color: rgb(34 197 94 / 0.2) !important;
}

.tw-caret-green-500\/25 {
  caret-color: rgb(34 197 94 / 0.25) !important;
}

.tw-caret-green-500\/30 {
  caret-color: rgb(34 197 94 / 0.3) !important;
}

.tw-caret-green-500\/35 {
  caret-color: rgb(34 197 94 / 0.35) !important;
}

.tw-caret-green-500\/40 {
  caret-color: rgb(34 197 94 / 0.4) !important;
}

.tw-caret-green-500\/45 {
  caret-color: rgb(34 197 94 / 0.45) !important;
}

.tw-caret-green-500\/5 {
  caret-color: rgb(34 197 94 / 0.05) !important;
}

.tw-caret-green-500\/50 {
  caret-color: rgb(34 197 94 / 0.5) !important;
}

.tw-caret-green-500\/55 {
  caret-color: rgb(34 197 94 / 0.55) !important;
}

.tw-caret-green-500\/60 {
  caret-color: rgb(34 197 94 / 0.6) !important;
}

.tw-caret-green-500\/65 {
  caret-color: rgb(34 197 94 / 0.65) !important;
}

.tw-caret-green-500\/70 {
  caret-color: rgb(34 197 94 / 0.7) !important;
}

.tw-caret-green-500\/75 {
  caret-color: rgb(34 197 94 / 0.75) !important;
}

.tw-caret-green-500\/80 {
  caret-color: rgb(34 197 94 / 0.8) !important;
}

.tw-caret-green-500\/85 {
  caret-color: rgb(34 197 94 / 0.85) !important;
}

.tw-caret-green-500\/90 {
  caret-color: rgb(34 197 94 / 0.9) !important;
}

.tw-caret-green-500\/95 {
  caret-color: rgb(34 197 94 / 0.95) !important;
}

.tw-caret-green-600 {
  caret-color: #16a34a !important;
}

.tw-caret-green-600\/0 {
  caret-color: rgb(22 163 74 / 0) !important;
}

.tw-caret-green-600\/10 {
  caret-color: rgb(22 163 74 / 0.1) !important;
}

.tw-caret-green-600\/100 {
  caret-color: rgb(22 163 74 / 1) !important;
}

.tw-caret-green-600\/15 {
  caret-color: rgb(22 163 74 / 0.15) !important;
}

.tw-caret-green-600\/20 {
  caret-color: rgb(22 163 74 / 0.2) !important;
}

.tw-caret-green-600\/25 {
  caret-color: rgb(22 163 74 / 0.25) !important;
}

.tw-caret-green-600\/30 {
  caret-color: rgb(22 163 74 / 0.3) !important;
}

.tw-caret-green-600\/35 {
  caret-color: rgb(22 163 74 / 0.35) !important;
}

.tw-caret-green-600\/40 {
  caret-color: rgb(22 163 74 / 0.4) !important;
}

.tw-caret-green-600\/45 {
  caret-color: rgb(22 163 74 / 0.45) !important;
}

.tw-caret-green-600\/5 {
  caret-color: rgb(22 163 74 / 0.05) !important;
}

.tw-caret-green-600\/50 {
  caret-color: rgb(22 163 74 / 0.5) !important;
}

.tw-caret-green-600\/55 {
  caret-color: rgb(22 163 74 / 0.55) !important;
}

.tw-caret-green-600\/60 {
  caret-color: rgb(22 163 74 / 0.6) !important;
}

.tw-caret-green-600\/65 {
  caret-color: rgb(22 163 74 / 0.65) !important;
}

.tw-caret-green-600\/70 {
  caret-color: rgb(22 163 74 / 0.7) !important;
}

.tw-caret-green-600\/75 {
  caret-color: rgb(22 163 74 / 0.75) !important;
}

.tw-caret-green-600\/80 {
  caret-color: rgb(22 163 74 / 0.8) !important;
}

.tw-caret-green-600\/85 {
  caret-color: rgb(22 163 74 / 0.85) !important;
}

.tw-caret-green-600\/90 {
  caret-color: rgb(22 163 74 / 0.9) !important;
}

.tw-caret-green-600\/95 {
  caret-color: rgb(22 163 74 / 0.95) !important;
}

.tw-caret-green-700 {
  caret-color: #15803d !important;
}

.tw-caret-green-700\/0 {
  caret-color: rgb(21 128 61 / 0) !important;
}

.tw-caret-green-700\/10 {
  caret-color: rgb(21 128 61 / 0.1) !important;
}

.tw-caret-green-700\/100 {
  caret-color: rgb(21 128 61 / 1) !important;
}

.tw-caret-green-700\/15 {
  caret-color: rgb(21 128 61 / 0.15) !important;
}

.tw-caret-green-700\/20 {
  caret-color: rgb(21 128 61 / 0.2) !important;
}

.tw-caret-green-700\/25 {
  caret-color: rgb(21 128 61 / 0.25) !important;
}

.tw-caret-green-700\/30 {
  caret-color: rgb(21 128 61 / 0.3) !important;
}

.tw-caret-green-700\/35 {
  caret-color: rgb(21 128 61 / 0.35) !important;
}

.tw-caret-green-700\/40 {
  caret-color: rgb(21 128 61 / 0.4) !important;
}

.tw-caret-green-700\/45 {
  caret-color: rgb(21 128 61 / 0.45) !important;
}

.tw-caret-green-700\/5 {
  caret-color: rgb(21 128 61 / 0.05) !important;
}

.tw-caret-green-700\/50 {
  caret-color: rgb(21 128 61 / 0.5) !important;
}

.tw-caret-green-700\/55 {
  caret-color: rgb(21 128 61 / 0.55) !important;
}

.tw-caret-green-700\/60 {
  caret-color: rgb(21 128 61 / 0.6) !important;
}

.tw-caret-green-700\/65 {
  caret-color: rgb(21 128 61 / 0.65) !important;
}

.tw-caret-green-700\/70 {
  caret-color: rgb(21 128 61 / 0.7) !important;
}

.tw-caret-green-700\/75 {
  caret-color: rgb(21 128 61 / 0.75) !important;
}

.tw-caret-green-700\/80 {
  caret-color: rgb(21 128 61 / 0.8) !important;
}

.tw-caret-green-700\/85 {
  caret-color: rgb(21 128 61 / 0.85) !important;
}

.tw-caret-green-700\/90 {
  caret-color: rgb(21 128 61 / 0.9) !important;
}

.tw-caret-green-700\/95 {
  caret-color: rgb(21 128 61 / 0.95) !important;
}

.tw-caret-green-800 {
  caret-color: #166534 !important;
}

.tw-caret-green-800\/0 {
  caret-color: rgb(22 101 52 / 0) !important;
}

.tw-caret-green-800\/10 {
  caret-color: rgb(22 101 52 / 0.1) !important;
}

.tw-caret-green-800\/100 {
  caret-color: rgb(22 101 52 / 1) !important;
}

.tw-caret-green-800\/15 {
  caret-color: rgb(22 101 52 / 0.15) !important;
}

.tw-caret-green-800\/20 {
  caret-color: rgb(22 101 52 / 0.2) !important;
}

.tw-caret-green-800\/25 {
  caret-color: rgb(22 101 52 / 0.25) !important;
}

.tw-caret-green-800\/30 {
  caret-color: rgb(22 101 52 / 0.3) !important;
}

.tw-caret-green-800\/35 {
  caret-color: rgb(22 101 52 / 0.35) !important;
}

.tw-caret-green-800\/40 {
  caret-color: rgb(22 101 52 / 0.4) !important;
}

.tw-caret-green-800\/45 {
  caret-color: rgb(22 101 52 / 0.45) !important;
}

.tw-caret-green-800\/5 {
  caret-color: rgb(22 101 52 / 0.05) !important;
}

.tw-caret-green-800\/50 {
  caret-color: rgb(22 101 52 / 0.5) !important;
}

.tw-caret-green-800\/55 {
  caret-color: rgb(22 101 52 / 0.55) !important;
}

.tw-caret-green-800\/60 {
  caret-color: rgb(22 101 52 / 0.6) !important;
}

.tw-caret-green-800\/65 {
  caret-color: rgb(22 101 52 / 0.65) !important;
}

.tw-caret-green-800\/70 {
  caret-color: rgb(22 101 52 / 0.7) !important;
}

.tw-caret-green-800\/75 {
  caret-color: rgb(22 101 52 / 0.75) !important;
}

.tw-caret-green-800\/80 {
  caret-color: rgb(22 101 52 / 0.8) !important;
}

.tw-caret-green-800\/85 {
  caret-color: rgb(22 101 52 / 0.85) !important;
}

.tw-caret-green-800\/90 {
  caret-color: rgb(22 101 52 / 0.9) !important;
}

.tw-caret-green-800\/95 {
  caret-color: rgb(22 101 52 / 0.95) !important;
}

.tw-caret-green-900 {
  caret-color: #14532d !important;
}

.tw-caret-green-900\/0 {
  caret-color: rgb(20 83 45 / 0) !important;
}

.tw-caret-green-900\/10 {
  caret-color: rgb(20 83 45 / 0.1) !important;
}

.tw-caret-green-900\/100 {
  caret-color: rgb(20 83 45 / 1) !important;
}

.tw-caret-green-900\/15 {
  caret-color: rgb(20 83 45 / 0.15) !important;
}

.tw-caret-green-900\/20 {
  caret-color: rgb(20 83 45 / 0.2) !important;
}

.tw-caret-green-900\/25 {
  caret-color: rgb(20 83 45 / 0.25) !important;
}

.tw-caret-green-900\/30 {
  caret-color: rgb(20 83 45 / 0.3) !important;
}

.tw-caret-green-900\/35 {
  caret-color: rgb(20 83 45 / 0.35) !important;
}

.tw-caret-green-900\/40 {
  caret-color: rgb(20 83 45 / 0.4) !important;
}

.tw-caret-green-900\/45 {
  caret-color: rgb(20 83 45 / 0.45) !important;
}

.tw-caret-green-900\/5 {
  caret-color: rgb(20 83 45 / 0.05) !important;
}

.tw-caret-green-900\/50 {
  caret-color: rgb(20 83 45 / 0.5) !important;
}

.tw-caret-green-900\/55 {
  caret-color: rgb(20 83 45 / 0.55) !important;
}

.tw-caret-green-900\/60 {
  caret-color: rgb(20 83 45 / 0.6) !important;
}

.tw-caret-green-900\/65 {
  caret-color: rgb(20 83 45 / 0.65) !important;
}

.tw-caret-green-900\/70 {
  caret-color: rgb(20 83 45 / 0.7) !important;
}

.tw-caret-green-900\/75 {
  caret-color: rgb(20 83 45 / 0.75) !important;
}

.tw-caret-green-900\/80 {
  caret-color: rgb(20 83 45 / 0.8) !important;
}

.tw-caret-green-900\/85 {
  caret-color: rgb(20 83 45 / 0.85) !important;
}

.tw-caret-green-900\/90 {
  caret-color: rgb(20 83 45 / 0.9) !important;
}

.tw-caret-green-900\/95 {
  caret-color: rgb(20 83 45 / 0.95) !important;
}

.tw-caret-green-950 {
  caret-color: #052e16 !important;
}

.tw-caret-green-950\/0 {
  caret-color: rgb(5 46 22 / 0) !important;
}

.tw-caret-green-950\/10 {
  caret-color: rgb(5 46 22 / 0.1) !important;
}

.tw-caret-green-950\/100 {
  caret-color: rgb(5 46 22 / 1) !important;
}

.tw-caret-green-950\/15 {
  caret-color: rgb(5 46 22 / 0.15) !important;
}

.tw-caret-green-950\/20 {
  caret-color: rgb(5 46 22 / 0.2) !important;
}

.tw-caret-green-950\/25 {
  caret-color: rgb(5 46 22 / 0.25) !important;
}

.tw-caret-green-950\/30 {
  caret-color: rgb(5 46 22 / 0.3) !important;
}

.tw-caret-green-950\/35 {
  caret-color: rgb(5 46 22 / 0.35) !important;
}

.tw-caret-green-950\/40 {
  caret-color: rgb(5 46 22 / 0.4) !important;
}

.tw-caret-green-950\/45 {
  caret-color: rgb(5 46 22 / 0.45) !important;
}

.tw-caret-green-950\/5 {
  caret-color: rgb(5 46 22 / 0.05) !important;
}

.tw-caret-green-950\/50 {
  caret-color: rgb(5 46 22 / 0.5) !important;
}

.tw-caret-green-950\/55 {
  caret-color: rgb(5 46 22 / 0.55) !important;
}

.tw-caret-green-950\/60 {
  caret-color: rgb(5 46 22 / 0.6) !important;
}

.tw-caret-green-950\/65 {
  caret-color: rgb(5 46 22 / 0.65) !important;
}

.tw-caret-green-950\/70 {
  caret-color: rgb(5 46 22 / 0.7) !important;
}

.tw-caret-green-950\/75 {
  caret-color: rgb(5 46 22 / 0.75) !important;
}

.tw-caret-green-950\/80 {
  caret-color: rgb(5 46 22 / 0.8) !important;
}

.tw-caret-green-950\/85 {
  caret-color: rgb(5 46 22 / 0.85) !important;
}

.tw-caret-green-950\/90 {
  caret-color: rgb(5 46 22 / 0.9) !important;
}

.tw-caret-green-950\/95 {
  caret-color: rgb(5 46 22 / 0.95) !important;
}

.tw-caret-indigo-100 {
  caret-color: #e0e7ff !important;
}

.tw-caret-indigo-100\/0 {
  caret-color: rgb(224 231 255 / 0) !important;
}

.tw-caret-indigo-100\/10 {
  caret-color: rgb(224 231 255 / 0.1) !important;
}

.tw-caret-indigo-100\/100 {
  caret-color: rgb(224 231 255 / 1) !important;
}

.tw-caret-indigo-100\/15 {
  caret-color: rgb(224 231 255 / 0.15) !important;
}

.tw-caret-indigo-100\/20 {
  caret-color: rgb(224 231 255 / 0.2) !important;
}

.tw-caret-indigo-100\/25 {
  caret-color: rgb(224 231 255 / 0.25) !important;
}

.tw-caret-indigo-100\/30 {
  caret-color: rgb(224 231 255 / 0.3) !important;
}

.tw-caret-indigo-100\/35 {
  caret-color: rgb(224 231 255 / 0.35) !important;
}

.tw-caret-indigo-100\/40 {
  caret-color: rgb(224 231 255 / 0.4) !important;
}

.tw-caret-indigo-100\/45 {
  caret-color: rgb(224 231 255 / 0.45) !important;
}

.tw-caret-indigo-100\/5 {
  caret-color: rgb(224 231 255 / 0.05) !important;
}

.tw-caret-indigo-100\/50 {
  caret-color: rgb(224 231 255 / 0.5) !important;
}

.tw-caret-indigo-100\/55 {
  caret-color: rgb(224 231 255 / 0.55) !important;
}

.tw-caret-indigo-100\/60 {
  caret-color: rgb(224 231 255 / 0.6) !important;
}

.tw-caret-indigo-100\/65 {
  caret-color: rgb(224 231 255 / 0.65) !important;
}

.tw-caret-indigo-100\/70 {
  caret-color: rgb(224 231 255 / 0.7) !important;
}

.tw-caret-indigo-100\/75 {
  caret-color: rgb(224 231 255 / 0.75) !important;
}

.tw-caret-indigo-100\/80 {
  caret-color: rgb(224 231 255 / 0.8) !important;
}

.tw-caret-indigo-100\/85 {
  caret-color: rgb(224 231 255 / 0.85) !important;
}

.tw-caret-indigo-100\/90 {
  caret-color: rgb(224 231 255 / 0.9) !important;
}

.tw-caret-indigo-100\/95 {
  caret-color: rgb(224 231 255 / 0.95) !important;
}

.tw-caret-indigo-200 {
  caret-color: #c7d2fe !important;
}

.tw-caret-indigo-200\/0 {
  caret-color: rgb(199 210 254 / 0) !important;
}

.tw-caret-indigo-200\/10 {
  caret-color: rgb(199 210 254 / 0.1) !important;
}

.tw-caret-indigo-200\/100 {
  caret-color: rgb(199 210 254 / 1) !important;
}

.tw-caret-indigo-200\/15 {
  caret-color: rgb(199 210 254 / 0.15) !important;
}

.tw-caret-indigo-200\/20 {
  caret-color: rgb(199 210 254 / 0.2) !important;
}

.tw-caret-indigo-200\/25 {
  caret-color: rgb(199 210 254 / 0.25) !important;
}

.tw-caret-indigo-200\/30 {
  caret-color: rgb(199 210 254 / 0.3) !important;
}

.tw-caret-indigo-200\/35 {
  caret-color: rgb(199 210 254 / 0.35) !important;
}

.tw-caret-indigo-200\/40 {
  caret-color: rgb(199 210 254 / 0.4) !important;
}

.tw-caret-indigo-200\/45 {
  caret-color: rgb(199 210 254 / 0.45) !important;
}

.tw-caret-indigo-200\/5 {
  caret-color: rgb(199 210 254 / 0.05) !important;
}

.tw-caret-indigo-200\/50 {
  caret-color: rgb(199 210 254 / 0.5) !important;
}

.tw-caret-indigo-200\/55 {
  caret-color: rgb(199 210 254 / 0.55) !important;
}

.tw-caret-indigo-200\/60 {
  caret-color: rgb(199 210 254 / 0.6) !important;
}

.tw-caret-indigo-200\/65 {
  caret-color: rgb(199 210 254 / 0.65) !important;
}

.tw-caret-indigo-200\/70 {
  caret-color: rgb(199 210 254 / 0.7) !important;
}

.tw-caret-indigo-200\/75 {
  caret-color: rgb(199 210 254 / 0.75) !important;
}

.tw-caret-indigo-200\/80 {
  caret-color: rgb(199 210 254 / 0.8) !important;
}

.tw-caret-indigo-200\/85 {
  caret-color: rgb(199 210 254 / 0.85) !important;
}

.tw-caret-indigo-200\/90 {
  caret-color: rgb(199 210 254 / 0.9) !important;
}

.tw-caret-indigo-200\/95 {
  caret-color: rgb(199 210 254 / 0.95) !important;
}

.tw-caret-indigo-300 {
  caret-color: #a5b4fc !important;
}

.tw-caret-indigo-300\/0 {
  caret-color: rgb(165 180 252 / 0) !important;
}

.tw-caret-indigo-300\/10 {
  caret-color: rgb(165 180 252 / 0.1) !important;
}

.tw-caret-indigo-300\/100 {
  caret-color: rgb(165 180 252 / 1) !important;
}

.tw-caret-indigo-300\/15 {
  caret-color: rgb(165 180 252 / 0.15) !important;
}

.tw-caret-indigo-300\/20 {
  caret-color: rgb(165 180 252 / 0.2) !important;
}

.tw-caret-indigo-300\/25 {
  caret-color: rgb(165 180 252 / 0.25) !important;
}

.tw-caret-indigo-300\/30 {
  caret-color: rgb(165 180 252 / 0.3) !important;
}

.tw-caret-indigo-300\/35 {
  caret-color: rgb(165 180 252 / 0.35) !important;
}

.tw-caret-indigo-300\/40 {
  caret-color: rgb(165 180 252 / 0.4) !important;
}

.tw-caret-indigo-300\/45 {
  caret-color: rgb(165 180 252 / 0.45) !important;
}

.tw-caret-indigo-300\/5 {
  caret-color: rgb(165 180 252 / 0.05) !important;
}

.tw-caret-indigo-300\/50 {
  caret-color: rgb(165 180 252 / 0.5) !important;
}

.tw-caret-indigo-300\/55 {
  caret-color: rgb(165 180 252 / 0.55) !important;
}

.tw-caret-indigo-300\/60 {
  caret-color: rgb(165 180 252 / 0.6) !important;
}

.tw-caret-indigo-300\/65 {
  caret-color: rgb(165 180 252 / 0.65) !important;
}

.tw-caret-indigo-300\/70 {
  caret-color: rgb(165 180 252 / 0.7) !important;
}

.tw-caret-indigo-300\/75 {
  caret-color: rgb(165 180 252 / 0.75) !important;
}

.tw-caret-indigo-300\/80 {
  caret-color: rgb(165 180 252 / 0.8) !important;
}

.tw-caret-indigo-300\/85 {
  caret-color: rgb(165 180 252 / 0.85) !important;
}

.tw-caret-indigo-300\/90 {
  caret-color: rgb(165 180 252 / 0.9) !important;
}

.tw-caret-indigo-300\/95 {
  caret-color: rgb(165 180 252 / 0.95) !important;
}

.tw-caret-indigo-400 {
  caret-color: #818cf8 !important;
}

.tw-caret-indigo-400\/0 {
  caret-color: rgb(129 140 248 / 0) !important;
}

.tw-caret-indigo-400\/10 {
  caret-color: rgb(129 140 248 / 0.1) !important;
}

.tw-caret-indigo-400\/100 {
  caret-color: rgb(129 140 248 / 1) !important;
}

.tw-caret-indigo-400\/15 {
  caret-color: rgb(129 140 248 / 0.15) !important;
}

.tw-caret-indigo-400\/20 {
  caret-color: rgb(129 140 248 / 0.2) !important;
}

.tw-caret-indigo-400\/25 {
  caret-color: rgb(129 140 248 / 0.25) !important;
}

.tw-caret-indigo-400\/30 {
  caret-color: rgb(129 140 248 / 0.3) !important;
}

.tw-caret-indigo-400\/35 {
  caret-color: rgb(129 140 248 / 0.35) !important;
}

.tw-caret-indigo-400\/40 {
  caret-color: rgb(129 140 248 / 0.4) !important;
}

.tw-caret-indigo-400\/45 {
  caret-color: rgb(129 140 248 / 0.45) !important;
}

.tw-caret-indigo-400\/5 {
  caret-color: rgb(129 140 248 / 0.05) !important;
}

.tw-caret-indigo-400\/50 {
  caret-color: rgb(129 140 248 / 0.5) !important;
}

.tw-caret-indigo-400\/55 {
  caret-color: rgb(129 140 248 / 0.55) !important;
}

.tw-caret-indigo-400\/60 {
  caret-color: rgb(129 140 248 / 0.6) !important;
}

.tw-caret-indigo-400\/65 {
  caret-color: rgb(129 140 248 / 0.65) !important;
}

.tw-caret-indigo-400\/70 {
  caret-color: rgb(129 140 248 / 0.7) !important;
}

.tw-caret-indigo-400\/75 {
  caret-color: rgb(129 140 248 / 0.75) !important;
}

.tw-caret-indigo-400\/80 {
  caret-color: rgb(129 140 248 / 0.8) !important;
}

.tw-caret-indigo-400\/85 {
  caret-color: rgb(129 140 248 / 0.85) !important;
}

.tw-caret-indigo-400\/90 {
  caret-color: rgb(129 140 248 / 0.9) !important;
}

.tw-caret-indigo-400\/95 {
  caret-color: rgb(129 140 248 / 0.95) !important;
}

.tw-caret-indigo-50 {
  caret-color: #eef2ff !important;
}

.tw-caret-indigo-50\/0 {
  caret-color: rgb(238 242 255 / 0) !important;
}

.tw-caret-indigo-50\/10 {
  caret-color: rgb(238 242 255 / 0.1) !important;
}

.tw-caret-indigo-50\/100 {
  caret-color: rgb(238 242 255 / 1) !important;
}

.tw-caret-indigo-50\/15 {
  caret-color: rgb(238 242 255 / 0.15) !important;
}

.tw-caret-indigo-50\/20 {
  caret-color: rgb(238 242 255 / 0.2) !important;
}

.tw-caret-indigo-50\/25 {
  caret-color: rgb(238 242 255 / 0.25) !important;
}

.tw-caret-indigo-50\/30 {
  caret-color: rgb(238 242 255 / 0.3) !important;
}

.tw-caret-indigo-50\/35 {
  caret-color: rgb(238 242 255 / 0.35) !important;
}

.tw-caret-indigo-50\/40 {
  caret-color: rgb(238 242 255 / 0.4) !important;
}

.tw-caret-indigo-50\/45 {
  caret-color: rgb(238 242 255 / 0.45) !important;
}

.tw-caret-indigo-50\/5 {
  caret-color: rgb(238 242 255 / 0.05) !important;
}

.tw-caret-indigo-50\/50 {
  caret-color: rgb(238 242 255 / 0.5) !important;
}

.tw-caret-indigo-50\/55 {
  caret-color: rgb(238 242 255 / 0.55) !important;
}

.tw-caret-indigo-50\/60 {
  caret-color: rgb(238 242 255 / 0.6) !important;
}

.tw-caret-indigo-50\/65 {
  caret-color: rgb(238 242 255 / 0.65) !important;
}

.tw-caret-indigo-50\/70 {
  caret-color: rgb(238 242 255 / 0.7) !important;
}

.tw-caret-indigo-50\/75 {
  caret-color: rgb(238 242 255 / 0.75) !important;
}

.tw-caret-indigo-50\/80 {
  caret-color: rgb(238 242 255 / 0.8) !important;
}

.tw-caret-indigo-50\/85 {
  caret-color: rgb(238 242 255 / 0.85) !important;
}

.tw-caret-indigo-50\/90 {
  caret-color: rgb(238 242 255 / 0.9) !important;
}

.tw-caret-indigo-50\/95 {
  caret-color: rgb(238 242 255 / 0.95) !important;
}

.tw-caret-indigo-500 {
  caret-color: #6366f1 !important;
}

.tw-caret-indigo-500\/0 {
  caret-color: rgb(99 102 241 / 0) !important;
}

.tw-caret-indigo-500\/10 {
  caret-color: rgb(99 102 241 / 0.1) !important;
}

.tw-caret-indigo-500\/100 {
  caret-color: rgb(99 102 241 / 1) !important;
}

.tw-caret-indigo-500\/15 {
  caret-color: rgb(99 102 241 / 0.15) !important;
}

.tw-caret-indigo-500\/20 {
  caret-color: rgb(99 102 241 / 0.2) !important;
}

.tw-caret-indigo-500\/25 {
  caret-color: rgb(99 102 241 / 0.25) !important;
}

.tw-caret-indigo-500\/30 {
  caret-color: rgb(99 102 241 / 0.3) !important;
}

.tw-caret-indigo-500\/35 {
  caret-color: rgb(99 102 241 / 0.35) !important;
}

.tw-caret-indigo-500\/40 {
  caret-color: rgb(99 102 241 / 0.4) !important;
}

.tw-caret-indigo-500\/45 {
  caret-color: rgb(99 102 241 / 0.45) !important;
}

.tw-caret-indigo-500\/5 {
  caret-color: rgb(99 102 241 / 0.05) !important;
}

.tw-caret-indigo-500\/50 {
  caret-color: rgb(99 102 241 / 0.5) !important;
}

.tw-caret-indigo-500\/55 {
  caret-color: rgb(99 102 241 / 0.55) !important;
}

.tw-caret-indigo-500\/60 {
  caret-color: rgb(99 102 241 / 0.6) !important;
}

.tw-caret-indigo-500\/65 {
  caret-color: rgb(99 102 241 / 0.65) !important;
}

.tw-caret-indigo-500\/70 {
  caret-color: rgb(99 102 241 / 0.7) !important;
}

.tw-caret-indigo-500\/75 {
  caret-color: rgb(99 102 241 / 0.75) !important;
}

.tw-caret-indigo-500\/80 {
  caret-color: rgb(99 102 241 / 0.8) !important;
}

.tw-caret-indigo-500\/85 {
  caret-color: rgb(99 102 241 / 0.85) !important;
}

.tw-caret-indigo-500\/90 {
  caret-color: rgb(99 102 241 / 0.9) !important;
}

.tw-caret-indigo-500\/95 {
  caret-color: rgb(99 102 241 / 0.95) !important;
}

.tw-caret-indigo-600 {
  caret-color: #4f46e5 !important;
}

.tw-caret-indigo-600\/0 {
  caret-color: rgb(79 70 229 / 0) !important;
}

.tw-caret-indigo-600\/10 {
  caret-color: rgb(79 70 229 / 0.1) !important;
}

.tw-caret-indigo-600\/100 {
  caret-color: rgb(79 70 229 / 1) !important;
}

.tw-caret-indigo-600\/15 {
  caret-color: rgb(79 70 229 / 0.15) !important;
}

.tw-caret-indigo-600\/20 {
  caret-color: rgb(79 70 229 / 0.2) !important;
}

.tw-caret-indigo-600\/25 {
  caret-color: rgb(79 70 229 / 0.25) !important;
}

.tw-caret-indigo-600\/30 {
  caret-color: rgb(79 70 229 / 0.3) !important;
}

.tw-caret-indigo-600\/35 {
  caret-color: rgb(79 70 229 / 0.35) !important;
}

.tw-caret-indigo-600\/40 {
  caret-color: rgb(79 70 229 / 0.4) !important;
}

.tw-caret-indigo-600\/45 {
  caret-color: rgb(79 70 229 / 0.45) !important;
}

.tw-caret-indigo-600\/5 {
  caret-color: rgb(79 70 229 / 0.05) !important;
}

.tw-caret-indigo-600\/50 {
  caret-color: rgb(79 70 229 / 0.5) !important;
}

.tw-caret-indigo-600\/55 {
  caret-color: rgb(79 70 229 / 0.55) !important;
}

.tw-caret-indigo-600\/60 {
  caret-color: rgb(79 70 229 / 0.6) !important;
}

.tw-caret-indigo-600\/65 {
  caret-color: rgb(79 70 229 / 0.65) !important;
}

.tw-caret-indigo-600\/70 {
  caret-color: rgb(79 70 229 / 0.7) !important;
}

.tw-caret-indigo-600\/75 {
  caret-color: rgb(79 70 229 / 0.75) !important;
}

.tw-caret-indigo-600\/80 {
  caret-color: rgb(79 70 229 / 0.8) !important;
}

.tw-caret-indigo-600\/85 {
  caret-color: rgb(79 70 229 / 0.85) !important;
}

.tw-caret-indigo-600\/90 {
  caret-color: rgb(79 70 229 / 0.9) !important;
}

.tw-caret-indigo-600\/95 {
  caret-color: rgb(79 70 229 / 0.95) !important;
}

.tw-caret-indigo-700 {
  caret-color: #4338ca !important;
}

.tw-caret-indigo-700\/0 {
  caret-color: rgb(67 56 202 / 0) !important;
}

.tw-caret-indigo-700\/10 {
  caret-color: rgb(67 56 202 / 0.1) !important;
}

.tw-caret-indigo-700\/100 {
  caret-color: rgb(67 56 202 / 1) !important;
}

.tw-caret-indigo-700\/15 {
  caret-color: rgb(67 56 202 / 0.15) !important;
}

.tw-caret-indigo-700\/20 {
  caret-color: rgb(67 56 202 / 0.2) !important;
}

.tw-caret-indigo-700\/25 {
  caret-color: rgb(67 56 202 / 0.25) !important;
}

.tw-caret-indigo-700\/30 {
  caret-color: rgb(67 56 202 / 0.3) !important;
}

.tw-caret-indigo-700\/35 {
  caret-color: rgb(67 56 202 / 0.35) !important;
}

.tw-caret-indigo-700\/40 {
  caret-color: rgb(67 56 202 / 0.4) !important;
}

.tw-caret-indigo-700\/45 {
  caret-color: rgb(67 56 202 / 0.45) !important;
}

.tw-caret-indigo-700\/5 {
  caret-color: rgb(67 56 202 / 0.05) !important;
}

.tw-caret-indigo-700\/50 {
  caret-color: rgb(67 56 202 / 0.5) !important;
}

.tw-caret-indigo-700\/55 {
  caret-color: rgb(67 56 202 / 0.55) !important;
}

.tw-caret-indigo-700\/60 {
  caret-color: rgb(67 56 202 / 0.6) !important;
}

.tw-caret-indigo-700\/65 {
  caret-color: rgb(67 56 202 / 0.65) !important;
}

.tw-caret-indigo-700\/70 {
  caret-color: rgb(67 56 202 / 0.7) !important;
}

.tw-caret-indigo-700\/75 {
  caret-color: rgb(67 56 202 / 0.75) !important;
}

.tw-caret-indigo-700\/80 {
  caret-color: rgb(67 56 202 / 0.8) !important;
}

.tw-caret-indigo-700\/85 {
  caret-color: rgb(67 56 202 / 0.85) !important;
}

.tw-caret-indigo-700\/90 {
  caret-color: rgb(67 56 202 / 0.9) !important;
}

.tw-caret-indigo-700\/95 {
  caret-color: rgb(67 56 202 / 0.95) !important;
}

.tw-caret-indigo-800 {
  caret-color: #3730a3 !important;
}

.tw-caret-indigo-800\/0 {
  caret-color: rgb(55 48 163 / 0) !important;
}

.tw-caret-indigo-800\/10 {
  caret-color: rgb(55 48 163 / 0.1) !important;
}

.tw-caret-indigo-800\/100 {
  caret-color: rgb(55 48 163 / 1) !important;
}

.tw-caret-indigo-800\/15 {
  caret-color: rgb(55 48 163 / 0.15) !important;
}

.tw-caret-indigo-800\/20 {
  caret-color: rgb(55 48 163 / 0.2) !important;
}

.tw-caret-indigo-800\/25 {
  caret-color: rgb(55 48 163 / 0.25) !important;
}

.tw-caret-indigo-800\/30 {
  caret-color: rgb(55 48 163 / 0.3) !important;
}

.tw-caret-indigo-800\/35 {
  caret-color: rgb(55 48 163 / 0.35) !important;
}

.tw-caret-indigo-800\/40 {
  caret-color: rgb(55 48 163 / 0.4) !important;
}

.tw-caret-indigo-800\/45 {
  caret-color: rgb(55 48 163 / 0.45) !important;
}

.tw-caret-indigo-800\/5 {
  caret-color: rgb(55 48 163 / 0.05) !important;
}

.tw-caret-indigo-800\/50 {
  caret-color: rgb(55 48 163 / 0.5) !important;
}

.tw-caret-indigo-800\/55 {
  caret-color: rgb(55 48 163 / 0.55) !important;
}

.tw-caret-indigo-800\/60 {
  caret-color: rgb(55 48 163 / 0.6) !important;
}

.tw-caret-indigo-800\/65 {
  caret-color: rgb(55 48 163 / 0.65) !important;
}

.tw-caret-indigo-800\/70 {
  caret-color: rgb(55 48 163 / 0.7) !important;
}

.tw-caret-indigo-800\/75 {
  caret-color: rgb(55 48 163 / 0.75) !important;
}

.tw-caret-indigo-800\/80 {
  caret-color: rgb(55 48 163 / 0.8) !important;
}

.tw-caret-indigo-800\/85 {
  caret-color: rgb(55 48 163 / 0.85) !important;
}

.tw-caret-indigo-800\/90 {
  caret-color: rgb(55 48 163 / 0.9) !important;
}

.tw-caret-indigo-800\/95 {
  caret-color: rgb(55 48 163 / 0.95) !important;
}

.tw-caret-indigo-900 {
  caret-color: #312e81 !important;
}

.tw-caret-indigo-900\/0 {
  caret-color: rgb(49 46 129 / 0) !important;
}

.tw-caret-indigo-900\/10 {
  caret-color: rgb(49 46 129 / 0.1) !important;
}

.tw-caret-indigo-900\/100 {
  caret-color: rgb(49 46 129 / 1) !important;
}

.tw-caret-indigo-900\/15 {
  caret-color: rgb(49 46 129 / 0.15) !important;
}

.tw-caret-indigo-900\/20 {
  caret-color: rgb(49 46 129 / 0.2) !important;
}

.tw-caret-indigo-900\/25 {
  caret-color: rgb(49 46 129 / 0.25) !important;
}

.tw-caret-indigo-900\/30 {
  caret-color: rgb(49 46 129 / 0.3) !important;
}

.tw-caret-indigo-900\/35 {
  caret-color: rgb(49 46 129 / 0.35) !important;
}

.tw-caret-indigo-900\/40 {
  caret-color: rgb(49 46 129 / 0.4) !important;
}

.tw-caret-indigo-900\/45 {
  caret-color: rgb(49 46 129 / 0.45) !important;
}

.tw-caret-indigo-900\/5 {
  caret-color: rgb(49 46 129 / 0.05) !important;
}

.tw-caret-indigo-900\/50 {
  caret-color: rgb(49 46 129 / 0.5) !important;
}

.tw-caret-indigo-900\/55 {
  caret-color: rgb(49 46 129 / 0.55) !important;
}

.tw-caret-indigo-900\/60 {
  caret-color: rgb(49 46 129 / 0.6) !important;
}

.tw-caret-indigo-900\/65 {
  caret-color: rgb(49 46 129 / 0.65) !important;
}

.tw-caret-indigo-900\/70 {
  caret-color: rgb(49 46 129 / 0.7) !important;
}

.tw-caret-indigo-900\/75 {
  caret-color: rgb(49 46 129 / 0.75) !important;
}

.tw-caret-indigo-900\/80 {
  caret-color: rgb(49 46 129 / 0.8) !important;
}

.tw-caret-indigo-900\/85 {
  caret-color: rgb(49 46 129 / 0.85) !important;
}

.tw-caret-indigo-900\/90 {
  caret-color: rgb(49 46 129 / 0.9) !important;
}

.tw-caret-indigo-900\/95 {
  caret-color: rgb(49 46 129 / 0.95) !important;
}

.tw-caret-indigo-950 {
  caret-color: #1e1b4b !important;
}

.tw-caret-indigo-950\/0 {
  caret-color: rgb(30 27 75 / 0) !important;
}

.tw-caret-indigo-950\/10 {
  caret-color: rgb(30 27 75 / 0.1) !important;
}

.tw-caret-indigo-950\/100 {
  caret-color: rgb(30 27 75 / 1) !important;
}

.tw-caret-indigo-950\/15 {
  caret-color: rgb(30 27 75 / 0.15) !important;
}

.tw-caret-indigo-950\/20 {
  caret-color: rgb(30 27 75 / 0.2) !important;
}

.tw-caret-indigo-950\/25 {
  caret-color: rgb(30 27 75 / 0.25) !important;
}

.tw-caret-indigo-950\/30 {
  caret-color: rgb(30 27 75 / 0.3) !important;
}

.tw-caret-indigo-950\/35 {
  caret-color: rgb(30 27 75 / 0.35) !important;
}

.tw-caret-indigo-950\/40 {
  caret-color: rgb(30 27 75 / 0.4) !important;
}

.tw-caret-indigo-950\/45 {
  caret-color: rgb(30 27 75 / 0.45) !important;
}

.tw-caret-indigo-950\/5 {
  caret-color: rgb(30 27 75 / 0.05) !important;
}

.tw-caret-indigo-950\/50 {
  caret-color: rgb(30 27 75 / 0.5) !important;
}

.tw-caret-indigo-950\/55 {
  caret-color: rgb(30 27 75 / 0.55) !important;
}

.tw-caret-indigo-950\/60 {
  caret-color: rgb(30 27 75 / 0.6) !important;
}

.tw-caret-indigo-950\/65 {
  caret-color: rgb(30 27 75 / 0.65) !important;
}

.tw-caret-indigo-950\/70 {
  caret-color: rgb(30 27 75 / 0.7) !important;
}

.tw-caret-indigo-950\/75 {
  caret-color: rgb(30 27 75 / 0.75) !important;
}

.tw-caret-indigo-950\/80 {
  caret-color: rgb(30 27 75 / 0.8) !important;
}

.tw-caret-indigo-950\/85 {
  caret-color: rgb(30 27 75 / 0.85) !important;
}

.tw-caret-indigo-950\/90 {
  caret-color: rgb(30 27 75 / 0.9) !important;
}

.tw-caret-indigo-950\/95 {
  caret-color: rgb(30 27 75 / 0.95) !important;
}

.tw-caret-inherit {
  caret-color: inherit !important;
}

.tw-caret-lime-100 {
  caret-color: #ecfccb !important;
}

.tw-caret-lime-100\/0 {
  caret-color: rgb(236 252 203 / 0) !important;
}

.tw-caret-lime-100\/10 {
  caret-color: rgb(236 252 203 / 0.1) !important;
}

.tw-caret-lime-100\/100 {
  caret-color: rgb(236 252 203 / 1) !important;
}

.tw-caret-lime-100\/15 {
  caret-color: rgb(236 252 203 / 0.15) !important;
}

.tw-caret-lime-100\/20 {
  caret-color: rgb(236 252 203 / 0.2) !important;
}

.tw-caret-lime-100\/25 {
  caret-color: rgb(236 252 203 / 0.25) !important;
}

.tw-caret-lime-100\/30 {
  caret-color: rgb(236 252 203 / 0.3) !important;
}

.tw-caret-lime-100\/35 {
  caret-color: rgb(236 252 203 / 0.35) !important;
}

.tw-caret-lime-100\/40 {
  caret-color: rgb(236 252 203 / 0.4) !important;
}

.tw-caret-lime-100\/45 {
  caret-color: rgb(236 252 203 / 0.45) !important;
}

.tw-caret-lime-100\/5 {
  caret-color: rgb(236 252 203 / 0.05) !important;
}

.tw-caret-lime-100\/50 {
  caret-color: rgb(236 252 203 / 0.5) !important;
}

.tw-caret-lime-100\/55 {
  caret-color: rgb(236 252 203 / 0.55) !important;
}

.tw-caret-lime-100\/60 {
  caret-color: rgb(236 252 203 / 0.6) !important;
}

.tw-caret-lime-100\/65 {
  caret-color: rgb(236 252 203 / 0.65) !important;
}

.tw-caret-lime-100\/70 {
  caret-color: rgb(236 252 203 / 0.7) !important;
}

.tw-caret-lime-100\/75 {
  caret-color: rgb(236 252 203 / 0.75) !important;
}

.tw-caret-lime-100\/80 {
  caret-color: rgb(236 252 203 / 0.8) !important;
}

.tw-caret-lime-100\/85 {
  caret-color: rgb(236 252 203 / 0.85) !important;
}

.tw-caret-lime-100\/90 {
  caret-color: rgb(236 252 203 / 0.9) !important;
}

.tw-caret-lime-100\/95 {
  caret-color: rgb(236 252 203 / 0.95) !important;
}

.tw-caret-lime-200 {
  caret-color: #d9f99d !important;
}

.tw-caret-lime-200\/0 {
  caret-color: rgb(217 249 157 / 0) !important;
}

.tw-caret-lime-200\/10 {
  caret-color: rgb(217 249 157 / 0.1) !important;
}

.tw-caret-lime-200\/100 {
  caret-color: rgb(217 249 157 / 1) !important;
}

.tw-caret-lime-200\/15 {
  caret-color: rgb(217 249 157 / 0.15) !important;
}

.tw-caret-lime-200\/20 {
  caret-color: rgb(217 249 157 / 0.2) !important;
}

.tw-caret-lime-200\/25 {
  caret-color: rgb(217 249 157 / 0.25) !important;
}

.tw-caret-lime-200\/30 {
  caret-color: rgb(217 249 157 / 0.3) !important;
}

.tw-caret-lime-200\/35 {
  caret-color: rgb(217 249 157 / 0.35) !important;
}

.tw-caret-lime-200\/40 {
  caret-color: rgb(217 249 157 / 0.4) !important;
}

.tw-caret-lime-200\/45 {
  caret-color: rgb(217 249 157 / 0.45) !important;
}

.tw-caret-lime-200\/5 {
  caret-color: rgb(217 249 157 / 0.05) !important;
}

.tw-caret-lime-200\/50 {
  caret-color: rgb(217 249 157 / 0.5) !important;
}

.tw-caret-lime-200\/55 {
  caret-color: rgb(217 249 157 / 0.55) !important;
}

.tw-caret-lime-200\/60 {
  caret-color: rgb(217 249 157 / 0.6) !important;
}

.tw-caret-lime-200\/65 {
  caret-color: rgb(217 249 157 / 0.65) !important;
}

.tw-caret-lime-200\/70 {
  caret-color: rgb(217 249 157 / 0.7) !important;
}

.tw-caret-lime-200\/75 {
  caret-color: rgb(217 249 157 / 0.75) !important;
}

.tw-caret-lime-200\/80 {
  caret-color: rgb(217 249 157 / 0.8) !important;
}

.tw-caret-lime-200\/85 {
  caret-color: rgb(217 249 157 / 0.85) !important;
}

.tw-caret-lime-200\/90 {
  caret-color: rgb(217 249 157 / 0.9) !important;
}

.tw-caret-lime-200\/95 {
  caret-color: rgb(217 249 157 / 0.95) !important;
}

.tw-caret-lime-300 {
  caret-color: #bef264 !important;
}

.tw-caret-lime-300\/0 {
  caret-color: rgb(190 242 100 / 0) !important;
}

.tw-caret-lime-300\/10 {
  caret-color: rgb(190 242 100 / 0.1) !important;
}

.tw-caret-lime-300\/100 {
  caret-color: rgb(190 242 100 / 1) !important;
}

.tw-caret-lime-300\/15 {
  caret-color: rgb(190 242 100 / 0.15) !important;
}

.tw-caret-lime-300\/20 {
  caret-color: rgb(190 242 100 / 0.2) !important;
}

.tw-caret-lime-300\/25 {
  caret-color: rgb(190 242 100 / 0.25) !important;
}

.tw-caret-lime-300\/30 {
  caret-color: rgb(190 242 100 / 0.3) !important;
}

.tw-caret-lime-300\/35 {
  caret-color: rgb(190 242 100 / 0.35) !important;
}

.tw-caret-lime-300\/40 {
  caret-color: rgb(190 242 100 / 0.4) !important;
}

.tw-caret-lime-300\/45 {
  caret-color: rgb(190 242 100 / 0.45) !important;
}

.tw-caret-lime-300\/5 {
  caret-color: rgb(190 242 100 / 0.05) !important;
}

.tw-caret-lime-300\/50 {
  caret-color: rgb(190 242 100 / 0.5) !important;
}

.tw-caret-lime-300\/55 {
  caret-color: rgb(190 242 100 / 0.55) !important;
}

.tw-caret-lime-300\/60 {
  caret-color: rgb(190 242 100 / 0.6) !important;
}

.tw-caret-lime-300\/65 {
  caret-color: rgb(190 242 100 / 0.65) !important;
}

.tw-caret-lime-300\/70 {
  caret-color: rgb(190 242 100 / 0.7) !important;
}

.tw-caret-lime-300\/75 {
  caret-color: rgb(190 242 100 / 0.75) !important;
}

.tw-caret-lime-300\/80 {
  caret-color: rgb(190 242 100 / 0.8) !important;
}

.tw-caret-lime-300\/85 {
  caret-color: rgb(190 242 100 / 0.85) !important;
}

.tw-caret-lime-300\/90 {
  caret-color: rgb(190 242 100 / 0.9) !important;
}

.tw-caret-lime-300\/95 {
  caret-color: rgb(190 242 100 / 0.95) !important;
}

.tw-caret-lime-400 {
  caret-color: #a3e635 !important;
}

.tw-caret-lime-400\/0 {
  caret-color: rgb(163 230 53 / 0) !important;
}

.tw-caret-lime-400\/10 {
  caret-color: rgb(163 230 53 / 0.1) !important;
}

.tw-caret-lime-400\/100 {
  caret-color: rgb(163 230 53 / 1) !important;
}

.tw-caret-lime-400\/15 {
  caret-color: rgb(163 230 53 / 0.15) !important;
}

.tw-caret-lime-400\/20 {
  caret-color: rgb(163 230 53 / 0.2) !important;
}

.tw-caret-lime-400\/25 {
  caret-color: rgb(163 230 53 / 0.25) !important;
}

.tw-caret-lime-400\/30 {
  caret-color: rgb(163 230 53 / 0.3) !important;
}

.tw-caret-lime-400\/35 {
  caret-color: rgb(163 230 53 / 0.35) !important;
}

.tw-caret-lime-400\/40 {
  caret-color: rgb(163 230 53 / 0.4) !important;
}

.tw-caret-lime-400\/45 {
  caret-color: rgb(163 230 53 / 0.45) !important;
}

.tw-caret-lime-400\/5 {
  caret-color: rgb(163 230 53 / 0.05) !important;
}

.tw-caret-lime-400\/50 {
  caret-color: rgb(163 230 53 / 0.5) !important;
}

.tw-caret-lime-400\/55 {
  caret-color: rgb(163 230 53 / 0.55) !important;
}

.tw-caret-lime-400\/60 {
  caret-color: rgb(163 230 53 / 0.6) !important;
}

.tw-caret-lime-400\/65 {
  caret-color: rgb(163 230 53 / 0.65) !important;
}

.tw-caret-lime-400\/70 {
  caret-color: rgb(163 230 53 / 0.7) !important;
}

.tw-caret-lime-400\/75 {
  caret-color: rgb(163 230 53 / 0.75) !important;
}

.tw-caret-lime-400\/80 {
  caret-color: rgb(163 230 53 / 0.8) !important;
}

.tw-caret-lime-400\/85 {
  caret-color: rgb(163 230 53 / 0.85) !important;
}

.tw-caret-lime-400\/90 {
  caret-color: rgb(163 230 53 / 0.9) !important;
}

.tw-caret-lime-400\/95 {
  caret-color: rgb(163 230 53 / 0.95) !important;
}

.tw-caret-lime-50 {
  caret-color: #f7fee7 !important;
}

.tw-caret-lime-50\/0 {
  caret-color: rgb(247 254 231 / 0) !important;
}

.tw-caret-lime-50\/10 {
  caret-color: rgb(247 254 231 / 0.1) !important;
}

.tw-caret-lime-50\/100 {
  caret-color: rgb(247 254 231 / 1) !important;
}

.tw-caret-lime-50\/15 {
  caret-color: rgb(247 254 231 / 0.15) !important;
}

.tw-caret-lime-50\/20 {
  caret-color: rgb(247 254 231 / 0.2) !important;
}

.tw-caret-lime-50\/25 {
  caret-color: rgb(247 254 231 / 0.25) !important;
}

.tw-caret-lime-50\/30 {
  caret-color: rgb(247 254 231 / 0.3) !important;
}

.tw-caret-lime-50\/35 {
  caret-color: rgb(247 254 231 / 0.35) !important;
}

.tw-caret-lime-50\/40 {
  caret-color: rgb(247 254 231 / 0.4) !important;
}

.tw-caret-lime-50\/45 {
  caret-color: rgb(247 254 231 / 0.45) !important;
}

.tw-caret-lime-50\/5 {
  caret-color: rgb(247 254 231 / 0.05) !important;
}

.tw-caret-lime-50\/50 {
  caret-color: rgb(247 254 231 / 0.5) !important;
}

.tw-caret-lime-50\/55 {
  caret-color: rgb(247 254 231 / 0.55) !important;
}

.tw-caret-lime-50\/60 {
  caret-color: rgb(247 254 231 / 0.6) !important;
}

.tw-caret-lime-50\/65 {
  caret-color: rgb(247 254 231 / 0.65) !important;
}

.tw-caret-lime-50\/70 {
  caret-color: rgb(247 254 231 / 0.7) !important;
}

.tw-caret-lime-50\/75 {
  caret-color: rgb(247 254 231 / 0.75) !important;
}

.tw-caret-lime-50\/80 {
  caret-color: rgb(247 254 231 / 0.8) !important;
}

.tw-caret-lime-50\/85 {
  caret-color: rgb(247 254 231 / 0.85) !important;
}

.tw-caret-lime-50\/90 {
  caret-color: rgb(247 254 231 / 0.9) !important;
}

.tw-caret-lime-50\/95 {
  caret-color: rgb(247 254 231 / 0.95) !important;
}

.tw-caret-lime-500 {
  caret-color: #84cc16 !important;
}

.tw-caret-lime-500\/0 {
  caret-color: rgb(132 204 22 / 0) !important;
}

.tw-caret-lime-500\/10 {
  caret-color: rgb(132 204 22 / 0.1) !important;
}

.tw-caret-lime-500\/100 {
  caret-color: rgb(132 204 22 / 1) !important;
}

.tw-caret-lime-500\/15 {
  caret-color: rgb(132 204 22 / 0.15) !important;
}

.tw-caret-lime-500\/20 {
  caret-color: rgb(132 204 22 / 0.2) !important;
}

.tw-caret-lime-500\/25 {
  caret-color: rgb(132 204 22 / 0.25) !important;
}

.tw-caret-lime-500\/30 {
  caret-color: rgb(132 204 22 / 0.3) !important;
}

.tw-caret-lime-500\/35 {
  caret-color: rgb(132 204 22 / 0.35) !important;
}

.tw-caret-lime-500\/40 {
  caret-color: rgb(132 204 22 / 0.4) !important;
}

.tw-caret-lime-500\/45 {
  caret-color: rgb(132 204 22 / 0.45) !important;
}

.tw-caret-lime-500\/5 {
  caret-color: rgb(132 204 22 / 0.05) !important;
}

.tw-caret-lime-500\/50 {
  caret-color: rgb(132 204 22 / 0.5) !important;
}

.tw-caret-lime-500\/55 {
  caret-color: rgb(132 204 22 / 0.55) !important;
}

.tw-caret-lime-500\/60 {
  caret-color: rgb(132 204 22 / 0.6) !important;
}

.tw-caret-lime-500\/65 {
  caret-color: rgb(132 204 22 / 0.65) !important;
}

.tw-caret-lime-500\/70 {
  caret-color: rgb(132 204 22 / 0.7) !important;
}

.tw-caret-lime-500\/75 {
  caret-color: rgb(132 204 22 / 0.75) !important;
}

.tw-caret-lime-500\/80 {
  caret-color: rgb(132 204 22 / 0.8) !important;
}

.tw-caret-lime-500\/85 {
  caret-color: rgb(132 204 22 / 0.85) !important;
}

.tw-caret-lime-500\/90 {
  caret-color: rgb(132 204 22 / 0.9) !important;
}

.tw-caret-lime-500\/95 {
  caret-color: rgb(132 204 22 / 0.95) !important;
}

.tw-caret-lime-600 {
  caret-color: #65a30d !important;
}

.tw-caret-lime-600\/0 {
  caret-color: rgb(101 163 13 / 0) !important;
}

.tw-caret-lime-600\/10 {
  caret-color: rgb(101 163 13 / 0.1) !important;
}

.tw-caret-lime-600\/100 {
  caret-color: rgb(101 163 13 / 1) !important;
}

.tw-caret-lime-600\/15 {
  caret-color: rgb(101 163 13 / 0.15) !important;
}

.tw-caret-lime-600\/20 {
  caret-color: rgb(101 163 13 / 0.2) !important;
}

.tw-caret-lime-600\/25 {
  caret-color: rgb(101 163 13 / 0.25) !important;
}

.tw-caret-lime-600\/30 {
  caret-color: rgb(101 163 13 / 0.3) !important;
}

.tw-caret-lime-600\/35 {
  caret-color: rgb(101 163 13 / 0.35) !important;
}

.tw-caret-lime-600\/40 {
  caret-color: rgb(101 163 13 / 0.4) !important;
}

.tw-caret-lime-600\/45 {
  caret-color: rgb(101 163 13 / 0.45) !important;
}

.tw-caret-lime-600\/5 {
  caret-color: rgb(101 163 13 / 0.05) !important;
}

.tw-caret-lime-600\/50 {
  caret-color: rgb(101 163 13 / 0.5) !important;
}

.tw-caret-lime-600\/55 {
  caret-color: rgb(101 163 13 / 0.55) !important;
}

.tw-caret-lime-600\/60 {
  caret-color: rgb(101 163 13 / 0.6) !important;
}

.tw-caret-lime-600\/65 {
  caret-color: rgb(101 163 13 / 0.65) !important;
}

.tw-caret-lime-600\/70 {
  caret-color: rgb(101 163 13 / 0.7) !important;
}

.tw-caret-lime-600\/75 {
  caret-color: rgb(101 163 13 / 0.75) !important;
}

.tw-caret-lime-600\/80 {
  caret-color: rgb(101 163 13 / 0.8) !important;
}

.tw-caret-lime-600\/85 {
  caret-color: rgb(101 163 13 / 0.85) !important;
}

.tw-caret-lime-600\/90 {
  caret-color: rgb(101 163 13 / 0.9) !important;
}

.tw-caret-lime-600\/95 {
  caret-color: rgb(101 163 13 / 0.95) !important;
}

.tw-caret-lime-700 {
  caret-color: #4d7c0f !important;
}

.tw-caret-lime-700\/0 {
  caret-color: rgb(77 124 15 / 0) !important;
}

.tw-caret-lime-700\/10 {
  caret-color: rgb(77 124 15 / 0.1) !important;
}

.tw-caret-lime-700\/100 {
  caret-color: rgb(77 124 15 / 1) !important;
}

.tw-caret-lime-700\/15 {
  caret-color: rgb(77 124 15 / 0.15) !important;
}

.tw-caret-lime-700\/20 {
  caret-color: rgb(77 124 15 / 0.2) !important;
}

.tw-caret-lime-700\/25 {
  caret-color: rgb(77 124 15 / 0.25) !important;
}

.tw-caret-lime-700\/30 {
  caret-color: rgb(77 124 15 / 0.3) !important;
}

.tw-caret-lime-700\/35 {
  caret-color: rgb(77 124 15 / 0.35) !important;
}

.tw-caret-lime-700\/40 {
  caret-color: rgb(77 124 15 / 0.4) !important;
}

.tw-caret-lime-700\/45 {
  caret-color: rgb(77 124 15 / 0.45) !important;
}

.tw-caret-lime-700\/5 {
  caret-color: rgb(77 124 15 / 0.05) !important;
}

.tw-caret-lime-700\/50 {
  caret-color: rgb(77 124 15 / 0.5) !important;
}

.tw-caret-lime-700\/55 {
  caret-color: rgb(77 124 15 / 0.55) !important;
}

.tw-caret-lime-700\/60 {
  caret-color: rgb(77 124 15 / 0.6) !important;
}

.tw-caret-lime-700\/65 {
  caret-color: rgb(77 124 15 / 0.65) !important;
}

.tw-caret-lime-700\/70 {
  caret-color: rgb(77 124 15 / 0.7) !important;
}

.tw-caret-lime-700\/75 {
  caret-color: rgb(77 124 15 / 0.75) !important;
}

.tw-caret-lime-700\/80 {
  caret-color: rgb(77 124 15 / 0.8) !important;
}

.tw-caret-lime-700\/85 {
  caret-color: rgb(77 124 15 / 0.85) !important;
}

.tw-caret-lime-700\/90 {
  caret-color: rgb(77 124 15 / 0.9) !important;
}

.tw-caret-lime-700\/95 {
  caret-color: rgb(77 124 15 / 0.95) !important;
}

.tw-caret-lime-800 {
  caret-color: #3f6212 !important;
}

.tw-caret-lime-800\/0 {
  caret-color: rgb(63 98 18 / 0) !important;
}

.tw-caret-lime-800\/10 {
  caret-color: rgb(63 98 18 / 0.1) !important;
}

.tw-caret-lime-800\/100 {
  caret-color: rgb(63 98 18 / 1) !important;
}

.tw-caret-lime-800\/15 {
  caret-color: rgb(63 98 18 / 0.15) !important;
}

.tw-caret-lime-800\/20 {
  caret-color: rgb(63 98 18 / 0.2) !important;
}

.tw-caret-lime-800\/25 {
  caret-color: rgb(63 98 18 / 0.25) !important;
}

.tw-caret-lime-800\/30 {
  caret-color: rgb(63 98 18 / 0.3) !important;
}

.tw-caret-lime-800\/35 {
  caret-color: rgb(63 98 18 / 0.35) !important;
}

.tw-caret-lime-800\/40 {
  caret-color: rgb(63 98 18 / 0.4) !important;
}

.tw-caret-lime-800\/45 {
  caret-color: rgb(63 98 18 / 0.45) !important;
}

.tw-caret-lime-800\/5 {
  caret-color: rgb(63 98 18 / 0.05) !important;
}

.tw-caret-lime-800\/50 {
  caret-color: rgb(63 98 18 / 0.5) !important;
}

.tw-caret-lime-800\/55 {
  caret-color: rgb(63 98 18 / 0.55) !important;
}

.tw-caret-lime-800\/60 {
  caret-color: rgb(63 98 18 / 0.6) !important;
}

.tw-caret-lime-800\/65 {
  caret-color: rgb(63 98 18 / 0.65) !important;
}

.tw-caret-lime-800\/70 {
  caret-color: rgb(63 98 18 / 0.7) !important;
}

.tw-caret-lime-800\/75 {
  caret-color: rgb(63 98 18 / 0.75) !important;
}

.tw-caret-lime-800\/80 {
  caret-color: rgb(63 98 18 / 0.8) !important;
}

.tw-caret-lime-800\/85 {
  caret-color: rgb(63 98 18 / 0.85) !important;
}

.tw-caret-lime-800\/90 {
  caret-color: rgb(63 98 18 / 0.9) !important;
}

.tw-caret-lime-800\/95 {
  caret-color: rgb(63 98 18 / 0.95) !important;
}

.tw-caret-lime-900 {
  caret-color: #365314 !important;
}

.tw-caret-lime-900\/0 {
  caret-color: rgb(54 83 20 / 0) !important;
}

.tw-caret-lime-900\/10 {
  caret-color: rgb(54 83 20 / 0.1) !important;
}

.tw-caret-lime-900\/100 {
  caret-color: rgb(54 83 20 / 1) !important;
}

.tw-caret-lime-900\/15 {
  caret-color: rgb(54 83 20 / 0.15) !important;
}

.tw-caret-lime-900\/20 {
  caret-color: rgb(54 83 20 / 0.2) !important;
}

.tw-caret-lime-900\/25 {
  caret-color: rgb(54 83 20 / 0.25) !important;
}

.tw-caret-lime-900\/30 {
  caret-color: rgb(54 83 20 / 0.3) !important;
}

.tw-caret-lime-900\/35 {
  caret-color: rgb(54 83 20 / 0.35) !important;
}

.tw-caret-lime-900\/40 {
  caret-color: rgb(54 83 20 / 0.4) !important;
}

.tw-caret-lime-900\/45 {
  caret-color: rgb(54 83 20 / 0.45) !important;
}

.tw-caret-lime-900\/5 {
  caret-color: rgb(54 83 20 / 0.05) !important;
}

.tw-caret-lime-900\/50 {
  caret-color: rgb(54 83 20 / 0.5) !important;
}

.tw-caret-lime-900\/55 {
  caret-color: rgb(54 83 20 / 0.55) !important;
}

.tw-caret-lime-900\/60 {
  caret-color: rgb(54 83 20 / 0.6) !important;
}

.tw-caret-lime-900\/65 {
  caret-color: rgb(54 83 20 / 0.65) !important;
}

.tw-caret-lime-900\/70 {
  caret-color: rgb(54 83 20 / 0.7) !important;
}

.tw-caret-lime-900\/75 {
  caret-color: rgb(54 83 20 / 0.75) !important;
}

.tw-caret-lime-900\/80 {
  caret-color: rgb(54 83 20 / 0.8) !important;
}

.tw-caret-lime-900\/85 {
  caret-color: rgb(54 83 20 / 0.85) !important;
}

.tw-caret-lime-900\/90 {
  caret-color: rgb(54 83 20 / 0.9) !important;
}

.tw-caret-lime-900\/95 {
  caret-color: rgb(54 83 20 / 0.95) !important;
}

.tw-caret-lime-950 {
  caret-color: #1a2e05 !important;
}

.tw-caret-lime-950\/0 {
  caret-color: rgb(26 46 5 / 0) !important;
}

.tw-caret-lime-950\/10 {
  caret-color: rgb(26 46 5 / 0.1) !important;
}

.tw-caret-lime-950\/100 {
  caret-color: rgb(26 46 5 / 1) !important;
}

.tw-caret-lime-950\/15 {
  caret-color: rgb(26 46 5 / 0.15) !important;
}

.tw-caret-lime-950\/20 {
  caret-color: rgb(26 46 5 / 0.2) !important;
}

.tw-caret-lime-950\/25 {
  caret-color: rgb(26 46 5 / 0.25) !important;
}

.tw-caret-lime-950\/30 {
  caret-color: rgb(26 46 5 / 0.3) !important;
}

.tw-caret-lime-950\/35 {
  caret-color: rgb(26 46 5 / 0.35) !important;
}

.tw-caret-lime-950\/40 {
  caret-color: rgb(26 46 5 / 0.4) !important;
}

.tw-caret-lime-950\/45 {
  caret-color: rgb(26 46 5 / 0.45) !important;
}

.tw-caret-lime-950\/5 {
  caret-color: rgb(26 46 5 / 0.05) !important;
}

.tw-caret-lime-950\/50 {
  caret-color: rgb(26 46 5 / 0.5) !important;
}

.tw-caret-lime-950\/55 {
  caret-color: rgb(26 46 5 / 0.55) !important;
}

.tw-caret-lime-950\/60 {
  caret-color: rgb(26 46 5 / 0.6) !important;
}

.tw-caret-lime-950\/65 {
  caret-color: rgb(26 46 5 / 0.65) !important;
}

.tw-caret-lime-950\/70 {
  caret-color: rgb(26 46 5 / 0.7) !important;
}

.tw-caret-lime-950\/75 {
  caret-color: rgb(26 46 5 / 0.75) !important;
}

.tw-caret-lime-950\/80 {
  caret-color: rgb(26 46 5 / 0.8) !important;
}

.tw-caret-lime-950\/85 {
  caret-color: rgb(26 46 5 / 0.85) !important;
}

.tw-caret-lime-950\/90 {
  caret-color: rgb(26 46 5 / 0.9) !important;
}

.tw-caret-lime-950\/95 {
  caret-color: rgb(26 46 5 / 0.95) !important;
}

.tw-caret-neutral-100 {
  caret-color: #f5f5f5 !important;
}

.tw-caret-neutral-100\/0 {
  caret-color: rgb(245 245 245 / 0) !important;
}

.tw-caret-neutral-100\/10 {
  caret-color: rgb(245 245 245 / 0.1) !important;
}

.tw-caret-neutral-100\/100 {
  caret-color: rgb(245 245 245 / 1) !important;
}

.tw-caret-neutral-100\/15 {
  caret-color: rgb(245 245 245 / 0.15) !important;
}

.tw-caret-neutral-100\/20 {
  caret-color: rgb(245 245 245 / 0.2) !important;
}

.tw-caret-neutral-100\/25 {
  caret-color: rgb(245 245 245 / 0.25) !important;
}

.tw-caret-neutral-100\/30 {
  caret-color: rgb(245 245 245 / 0.3) !important;
}

.tw-caret-neutral-100\/35 {
  caret-color: rgb(245 245 245 / 0.35) !important;
}

.tw-caret-neutral-100\/40 {
  caret-color: rgb(245 245 245 / 0.4) !important;
}

.tw-caret-neutral-100\/45 {
  caret-color: rgb(245 245 245 / 0.45) !important;
}

.tw-caret-neutral-100\/5 {
  caret-color: rgb(245 245 245 / 0.05) !important;
}

.tw-caret-neutral-100\/50 {
  caret-color: rgb(245 245 245 / 0.5) !important;
}

.tw-caret-neutral-100\/55 {
  caret-color: rgb(245 245 245 / 0.55) !important;
}

.tw-caret-neutral-100\/60 {
  caret-color: rgb(245 245 245 / 0.6) !important;
}

.tw-caret-neutral-100\/65 {
  caret-color: rgb(245 245 245 / 0.65) !important;
}

.tw-caret-neutral-100\/70 {
  caret-color: rgb(245 245 245 / 0.7) !important;
}

.tw-caret-neutral-100\/75 {
  caret-color: rgb(245 245 245 / 0.75) !important;
}

.tw-caret-neutral-100\/80 {
  caret-color: rgb(245 245 245 / 0.8) !important;
}

.tw-caret-neutral-100\/85 {
  caret-color: rgb(245 245 245 / 0.85) !important;
}

.tw-caret-neutral-100\/90 {
  caret-color: rgb(245 245 245 / 0.9) !important;
}

.tw-caret-neutral-100\/95 {
  caret-color: rgb(245 245 245 / 0.95) !important;
}

.tw-caret-neutral-200 {
  caret-color: #e5e5e5 !important;
}

.tw-caret-neutral-200\/0 {
  caret-color: rgb(229 229 229 / 0) !important;
}

.tw-caret-neutral-200\/10 {
  caret-color: rgb(229 229 229 / 0.1) !important;
}

.tw-caret-neutral-200\/100 {
  caret-color: rgb(229 229 229 / 1) !important;
}

.tw-caret-neutral-200\/15 {
  caret-color: rgb(229 229 229 / 0.15) !important;
}

.tw-caret-neutral-200\/20 {
  caret-color: rgb(229 229 229 / 0.2) !important;
}

.tw-caret-neutral-200\/25 {
  caret-color: rgb(229 229 229 / 0.25) !important;
}

.tw-caret-neutral-200\/30 {
  caret-color: rgb(229 229 229 / 0.3) !important;
}

.tw-caret-neutral-200\/35 {
  caret-color: rgb(229 229 229 / 0.35) !important;
}

.tw-caret-neutral-200\/40 {
  caret-color: rgb(229 229 229 / 0.4) !important;
}

.tw-caret-neutral-200\/45 {
  caret-color: rgb(229 229 229 / 0.45) !important;
}

.tw-caret-neutral-200\/5 {
  caret-color: rgb(229 229 229 / 0.05) !important;
}

.tw-caret-neutral-200\/50 {
  caret-color: rgb(229 229 229 / 0.5) !important;
}

.tw-caret-neutral-200\/55 {
  caret-color: rgb(229 229 229 / 0.55) !important;
}

.tw-caret-neutral-200\/60 {
  caret-color: rgb(229 229 229 / 0.6) !important;
}

.tw-caret-neutral-200\/65 {
  caret-color: rgb(229 229 229 / 0.65) !important;
}

.tw-caret-neutral-200\/70 {
  caret-color: rgb(229 229 229 / 0.7) !important;
}

.tw-caret-neutral-200\/75 {
  caret-color: rgb(229 229 229 / 0.75) !important;
}

.tw-caret-neutral-200\/80 {
  caret-color: rgb(229 229 229 / 0.8) !important;
}

.tw-caret-neutral-200\/85 {
  caret-color: rgb(229 229 229 / 0.85) !important;
}

.tw-caret-neutral-200\/90 {
  caret-color: rgb(229 229 229 / 0.9) !important;
}

.tw-caret-neutral-200\/95 {
  caret-color: rgb(229 229 229 / 0.95) !important;
}

.tw-caret-neutral-300 {
  caret-color: #d4d4d4 !important;
}

.tw-caret-neutral-300\/0 {
  caret-color: rgb(212 212 212 / 0) !important;
}

.tw-caret-neutral-300\/10 {
  caret-color: rgb(212 212 212 / 0.1) !important;
}

.tw-caret-neutral-300\/100 {
  caret-color: rgb(212 212 212 / 1) !important;
}

.tw-caret-neutral-300\/15 {
  caret-color: rgb(212 212 212 / 0.15) !important;
}

.tw-caret-neutral-300\/20 {
  caret-color: rgb(212 212 212 / 0.2) !important;
}

.tw-caret-neutral-300\/25 {
  caret-color: rgb(212 212 212 / 0.25) !important;
}

.tw-caret-neutral-300\/30 {
  caret-color: rgb(212 212 212 / 0.3) !important;
}

.tw-caret-neutral-300\/35 {
  caret-color: rgb(212 212 212 / 0.35) !important;
}

.tw-caret-neutral-300\/40 {
  caret-color: rgb(212 212 212 / 0.4) !important;
}

.tw-caret-neutral-300\/45 {
  caret-color: rgb(212 212 212 / 0.45) !important;
}

.tw-caret-neutral-300\/5 {
  caret-color: rgb(212 212 212 / 0.05) !important;
}

.tw-caret-neutral-300\/50 {
  caret-color: rgb(212 212 212 / 0.5) !important;
}

.tw-caret-neutral-300\/55 {
  caret-color: rgb(212 212 212 / 0.55) !important;
}

.tw-caret-neutral-300\/60 {
  caret-color: rgb(212 212 212 / 0.6) !important;
}

.tw-caret-neutral-300\/65 {
  caret-color: rgb(212 212 212 / 0.65) !important;
}

.tw-caret-neutral-300\/70 {
  caret-color: rgb(212 212 212 / 0.7) !important;
}

.tw-caret-neutral-300\/75 {
  caret-color: rgb(212 212 212 / 0.75) !important;
}

.tw-caret-neutral-300\/80 {
  caret-color: rgb(212 212 212 / 0.8) !important;
}

.tw-caret-neutral-300\/85 {
  caret-color: rgb(212 212 212 / 0.85) !important;
}

.tw-caret-neutral-300\/90 {
  caret-color: rgb(212 212 212 / 0.9) !important;
}

.tw-caret-neutral-300\/95 {
  caret-color: rgb(212 212 212 / 0.95) !important;
}

.tw-caret-neutral-400 {
  caret-color: #a3a3a3 !important;
}

.tw-caret-neutral-400\/0 {
  caret-color: rgb(163 163 163 / 0) !important;
}

.tw-caret-neutral-400\/10 {
  caret-color: rgb(163 163 163 / 0.1) !important;
}

.tw-caret-neutral-400\/100 {
  caret-color: rgb(163 163 163 / 1) !important;
}

.tw-caret-neutral-400\/15 {
  caret-color: rgb(163 163 163 / 0.15) !important;
}

.tw-caret-neutral-400\/20 {
  caret-color: rgb(163 163 163 / 0.2) !important;
}

.tw-caret-neutral-400\/25 {
  caret-color: rgb(163 163 163 / 0.25) !important;
}

.tw-caret-neutral-400\/30 {
  caret-color: rgb(163 163 163 / 0.3) !important;
}

.tw-caret-neutral-400\/35 {
  caret-color: rgb(163 163 163 / 0.35) !important;
}

.tw-caret-neutral-400\/40 {
  caret-color: rgb(163 163 163 / 0.4) !important;
}

.tw-caret-neutral-400\/45 {
  caret-color: rgb(163 163 163 / 0.45) !important;
}

.tw-caret-neutral-400\/5 {
  caret-color: rgb(163 163 163 / 0.05) !important;
}

.tw-caret-neutral-400\/50 {
  caret-color: rgb(163 163 163 / 0.5) !important;
}

.tw-caret-neutral-400\/55 {
  caret-color: rgb(163 163 163 / 0.55) !important;
}

.tw-caret-neutral-400\/60 {
  caret-color: rgb(163 163 163 / 0.6) !important;
}

.tw-caret-neutral-400\/65 {
  caret-color: rgb(163 163 163 / 0.65) !important;
}

.tw-caret-neutral-400\/70 {
  caret-color: rgb(163 163 163 / 0.7) !important;
}

.tw-caret-neutral-400\/75 {
  caret-color: rgb(163 163 163 / 0.75) !important;
}

.tw-caret-neutral-400\/80 {
  caret-color: rgb(163 163 163 / 0.8) !important;
}

.tw-caret-neutral-400\/85 {
  caret-color: rgb(163 163 163 / 0.85) !important;
}

.tw-caret-neutral-400\/90 {
  caret-color: rgb(163 163 163 / 0.9) !important;
}

.tw-caret-neutral-400\/95 {
  caret-color: rgb(163 163 163 / 0.95) !important;
}

.tw-caret-neutral-50 {
  caret-color: #fafafa !important;
}

.tw-caret-neutral-50\/0 {
  caret-color: rgb(250 250 250 / 0) !important;
}

.tw-caret-neutral-50\/10 {
  caret-color: rgb(250 250 250 / 0.1) !important;
}

.tw-caret-neutral-50\/100 {
  caret-color: rgb(250 250 250 / 1) !important;
}

.tw-caret-neutral-50\/15 {
  caret-color: rgb(250 250 250 / 0.15) !important;
}

.tw-caret-neutral-50\/20 {
  caret-color: rgb(250 250 250 / 0.2) !important;
}

.tw-caret-neutral-50\/25 {
  caret-color: rgb(250 250 250 / 0.25) !important;
}

.tw-caret-neutral-50\/30 {
  caret-color: rgb(250 250 250 / 0.3) !important;
}

.tw-caret-neutral-50\/35 {
  caret-color: rgb(250 250 250 / 0.35) !important;
}

.tw-caret-neutral-50\/40 {
  caret-color: rgb(250 250 250 / 0.4) !important;
}

.tw-caret-neutral-50\/45 {
  caret-color: rgb(250 250 250 / 0.45) !important;
}

.tw-caret-neutral-50\/5 {
  caret-color: rgb(250 250 250 / 0.05) !important;
}

.tw-caret-neutral-50\/50 {
  caret-color: rgb(250 250 250 / 0.5) !important;
}

.tw-caret-neutral-50\/55 {
  caret-color: rgb(250 250 250 / 0.55) !important;
}

.tw-caret-neutral-50\/60 {
  caret-color: rgb(250 250 250 / 0.6) !important;
}

.tw-caret-neutral-50\/65 {
  caret-color: rgb(250 250 250 / 0.65) !important;
}

.tw-caret-neutral-50\/70 {
  caret-color: rgb(250 250 250 / 0.7) !important;
}

.tw-caret-neutral-50\/75 {
  caret-color: rgb(250 250 250 / 0.75) !important;
}

.tw-caret-neutral-50\/80 {
  caret-color: rgb(250 250 250 / 0.8) !important;
}

.tw-caret-neutral-50\/85 {
  caret-color: rgb(250 250 250 / 0.85) !important;
}

.tw-caret-neutral-50\/90 {
  caret-color: rgb(250 250 250 / 0.9) !important;
}

.tw-caret-neutral-50\/95 {
  caret-color: rgb(250 250 250 / 0.95) !important;
}

.tw-caret-neutral-500 {
  caret-color: #737373 !important;
}

.tw-caret-neutral-500\/0 {
  caret-color: rgb(115 115 115 / 0) !important;
}

.tw-caret-neutral-500\/10 {
  caret-color: rgb(115 115 115 / 0.1) !important;
}

.tw-caret-neutral-500\/100 {
  caret-color: rgb(115 115 115 / 1) !important;
}

.tw-caret-neutral-500\/15 {
  caret-color: rgb(115 115 115 / 0.15) !important;
}

.tw-caret-neutral-500\/20 {
  caret-color: rgb(115 115 115 / 0.2) !important;
}

.tw-caret-neutral-500\/25 {
  caret-color: rgb(115 115 115 / 0.25) !important;
}

.tw-caret-neutral-500\/30 {
  caret-color: rgb(115 115 115 / 0.3) !important;
}

.tw-caret-neutral-500\/35 {
  caret-color: rgb(115 115 115 / 0.35) !important;
}

.tw-caret-neutral-500\/40 {
  caret-color: rgb(115 115 115 / 0.4) !important;
}

.tw-caret-neutral-500\/45 {
  caret-color: rgb(115 115 115 / 0.45) !important;
}

.tw-caret-neutral-500\/5 {
  caret-color: rgb(115 115 115 / 0.05) !important;
}

.tw-caret-neutral-500\/50 {
  caret-color: rgb(115 115 115 / 0.5) !important;
}

.tw-caret-neutral-500\/55 {
  caret-color: rgb(115 115 115 / 0.55) !important;
}

.tw-caret-neutral-500\/60 {
  caret-color: rgb(115 115 115 / 0.6) !important;
}

.tw-caret-neutral-500\/65 {
  caret-color: rgb(115 115 115 / 0.65) !important;
}

.tw-caret-neutral-500\/70 {
  caret-color: rgb(115 115 115 / 0.7) !important;
}

.tw-caret-neutral-500\/75 {
  caret-color: rgb(115 115 115 / 0.75) !important;
}

.tw-caret-neutral-500\/80 {
  caret-color: rgb(115 115 115 / 0.8) !important;
}

.tw-caret-neutral-500\/85 {
  caret-color: rgb(115 115 115 / 0.85) !important;
}

.tw-caret-neutral-500\/90 {
  caret-color: rgb(115 115 115 / 0.9) !important;
}

.tw-caret-neutral-500\/95 {
  caret-color: rgb(115 115 115 / 0.95) !important;
}

.tw-caret-neutral-600 {
  caret-color: #525252 !important;
}

.tw-caret-neutral-600\/0 {
  caret-color: rgb(82 82 82 / 0) !important;
}

.tw-caret-neutral-600\/10 {
  caret-color: rgb(82 82 82 / 0.1) !important;
}

.tw-caret-neutral-600\/100 {
  caret-color: rgb(82 82 82 / 1) !important;
}

.tw-caret-neutral-600\/15 {
  caret-color: rgb(82 82 82 / 0.15) !important;
}

.tw-caret-neutral-600\/20 {
  caret-color: rgb(82 82 82 / 0.2) !important;
}

.tw-caret-neutral-600\/25 {
  caret-color: rgb(82 82 82 / 0.25) !important;
}

.tw-caret-neutral-600\/30 {
  caret-color: rgb(82 82 82 / 0.3) !important;
}

.tw-caret-neutral-600\/35 {
  caret-color: rgb(82 82 82 / 0.35) !important;
}

.tw-caret-neutral-600\/40 {
  caret-color: rgb(82 82 82 / 0.4) !important;
}

.tw-caret-neutral-600\/45 {
  caret-color: rgb(82 82 82 / 0.45) !important;
}

.tw-caret-neutral-600\/5 {
  caret-color: rgb(82 82 82 / 0.05) !important;
}

.tw-caret-neutral-600\/50 {
  caret-color: rgb(82 82 82 / 0.5) !important;
}

.tw-caret-neutral-600\/55 {
  caret-color: rgb(82 82 82 / 0.55) !important;
}

.tw-caret-neutral-600\/60 {
  caret-color: rgb(82 82 82 / 0.6) !important;
}

.tw-caret-neutral-600\/65 {
  caret-color: rgb(82 82 82 / 0.65) !important;
}

.tw-caret-neutral-600\/70 {
  caret-color: rgb(82 82 82 / 0.7) !important;
}

.tw-caret-neutral-600\/75 {
  caret-color: rgb(82 82 82 / 0.75) !important;
}

.tw-caret-neutral-600\/80 {
  caret-color: rgb(82 82 82 / 0.8) !important;
}

.tw-caret-neutral-600\/85 {
  caret-color: rgb(82 82 82 / 0.85) !important;
}

.tw-caret-neutral-600\/90 {
  caret-color: rgb(82 82 82 / 0.9) !important;
}

.tw-caret-neutral-600\/95 {
  caret-color: rgb(82 82 82 / 0.95) !important;
}

.tw-caret-neutral-700 {
  caret-color: #404040 !important;
}

.tw-caret-neutral-700\/0 {
  caret-color: rgb(64 64 64 / 0) !important;
}

.tw-caret-neutral-700\/10 {
  caret-color: rgb(64 64 64 / 0.1) !important;
}

.tw-caret-neutral-700\/100 {
  caret-color: rgb(64 64 64 / 1) !important;
}

.tw-caret-neutral-700\/15 {
  caret-color: rgb(64 64 64 / 0.15) !important;
}

.tw-caret-neutral-700\/20 {
  caret-color: rgb(64 64 64 / 0.2) !important;
}

.tw-caret-neutral-700\/25 {
  caret-color: rgb(64 64 64 / 0.25) !important;
}

.tw-caret-neutral-700\/30 {
  caret-color: rgb(64 64 64 / 0.3) !important;
}

.tw-caret-neutral-700\/35 {
  caret-color: rgb(64 64 64 / 0.35) !important;
}

.tw-caret-neutral-700\/40 {
  caret-color: rgb(64 64 64 / 0.4) !important;
}

.tw-caret-neutral-700\/45 {
  caret-color: rgb(64 64 64 / 0.45) !important;
}

.tw-caret-neutral-700\/5 {
  caret-color: rgb(64 64 64 / 0.05) !important;
}

.tw-caret-neutral-700\/50 {
  caret-color: rgb(64 64 64 / 0.5) !important;
}

.tw-caret-neutral-700\/55 {
  caret-color: rgb(64 64 64 / 0.55) !important;
}

.tw-caret-neutral-700\/60 {
  caret-color: rgb(64 64 64 / 0.6) !important;
}

.tw-caret-neutral-700\/65 {
  caret-color: rgb(64 64 64 / 0.65) !important;
}

.tw-caret-neutral-700\/70 {
  caret-color: rgb(64 64 64 / 0.7) !important;
}

.tw-caret-neutral-700\/75 {
  caret-color: rgb(64 64 64 / 0.75) !important;
}

.tw-caret-neutral-700\/80 {
  caret-color: rgb(64 64 64 / 0.8) !important;
}

.tw-caret-neutral-700\/85 {
  caret-color: rgb(64 64 64 / 0.85) !important;
}

.tw-caret-neutral-700\/90 {
  caret-color: rgb(64 64 64 / 0.9) !important;
}

.tw-caret-neutral-700\/95 {
  caret-color: rgb(64 64 64 / 0.95) !important;
}

.tw-caret-neutral-800 {
  caret-color: #262626 !important;
}

.tw-caret-neutral-800\/0 {
  caret-color: rgb(38 38 38 / 0) !important;
}

.tw-caret-neutral-800\/10 {
  caret-color: rgb(38 38 38 / 0.1) !important;
}

.tw-caret-neutral-800\/100 {
  caret-color: rgb(38 38 38 / 1) !important;
}

.tw-caret-neutral-800\/15 {
  caret-color: rgb(38 38 38 / 0.15) !important;
}

.tw-caret-neutral-800\/20 {
  caret-color: rgb(38 38 38 / 0.2) !important;
}

.tw-caret-neutral-800\/25 {
  caret-color: rgb(38 38 38 / 0.25) !important;
}

.tw-caret-neutral-800\/30 {
  caret-color: rgb(38 38 38 / 0.3) !important;
}

.tw-caret-neutral-800\/35 {
  caret-color: rgb(38 38 38 / 0.35) !important;
}

.tw-caret-neutral-800\/40 {
  caret-color: rgb(38 38 38 / 0.4) !important;
}

.tw-caret-neutral-800\/45 {
  caret-color: rgb(38 38 38 / 0.45) !important;
}

.tw-caret-neutral-800\/5 {
  caret-color: rgb(38 38 38 / 0.05) !important;
}

.tw-caret-neutral-800\/50 {
  caret-color: rgb(38 38 38 / 0.5) !important;
}

.tw-caret-neutral-800\/55 {
  caret-color: rgb(38 38 38 / 0.55) !important;
}

.tw-caret-neutral-800\/60 {
  caret-color: rgb(38 38 38 / 0.6) !important;
}

.tw-caret-neutral-800\/65 {
  caret-color: rgb(38 38 38 / 0.65) !important;
}

.tw-caret-neutral-800\/70 {
  caret-color: rgb(38 38 38 / 0.7) !important;
}

.tw-caret-neutral-800\/75 {
  caret-color: rgb(38 38 38 / 0.75) !important;
}

.tw-caret-neutral-800\/80 {
  caret-color: rgb(38 38 38 / 0.8) !important;
}

.tw-caret-neutral-800\/85 {
  caret-color: rgb(38 38 38 / 0.85) !important;
}

.tw-caret-neutral-800\/90 {
  caret-color: rgb(38 38 38 / 0.9) !important;
}

.tw-caret-neutral-800\/95 {
  caret-color: rgb(38 38 38 / 0.95) !important;
}

.tw-caret-neutral-900 {
  caret-color: #171717 !important;
}

.tw-caret-neutral-900\/0 {
  caret-color: rgb(23 23 23 / 0) !important;
}

.tw-caret-neutral-900\/10 {
  caret-color: rgb(23 23 23 / 0.1) !important;
}

.tw-caret-neutral-900\/100 {
  caret-color: rgb(23 23 23 / 1) !important;
}

.tw-caret-neutral-900\/15 {
  caret-color: rgb(23 23 23 / 0.15) !important;
}

.tw-caret-neutral-900\/20 {
  caret-color: rgb(23 23 23 / 0.2) !important;
}

.tw-caret-neutral-900\/25 {
  caret-color: rgb(23 23 23 / 0.25) !important;
}

.tw-caret-neutral-900\/30 {
  caret-color: rgb(23 23 23 / 0.3) !important;
}

.tw-caret-neutral-900\/35 {
  caret-color: rgb(23 23 23 / 0.35) !important;
}

.tw-caret-neutral-900\/40 {
  caret-color: rgb(23 23 23 / 0.4) !important;
}

.tw-caret-neutral-900\/45 {
  caret-color: rgb(23 23 23 / 0.45) !important;
}

.tw-caret-neutral-900\/5 {
  caret-color: rgb(23 23 23 / 0.05) !important;
}

.tw-caret-neutral-900\/50 {
  caret-color: rgb(23 23 23 / 0.5) !important;
}

.tw-caret-neutral-900\/55 {
  caret-color: rgb(23 23 23 / 0.55) !important;
}

.tw-caret-neutral-900\/60 {
  caret-color: rgb(23 23 23 / 0.6) !important;
}

.tw-caret-neutral-900\/65 {
  caret-color: rgb(23 23 23 / 0.65) !important;
}

.tw-caret-neutral-900\/70 {
  caret-color: rgb(23 23 23 / 0.7) !important;
}

.tw-caret-neutral-900\/75 {
  caret-color: rgb(23 23 23 / 0.75) !important;
}

.tw-caret-neutral-900\/80 {
  caret-color: rgb(23 23 23 / 0.8) !important;
}

.tw-caret-neutral-900\/85 {
  caret-color: rgb(23 23 23 / 0.85) !important;
}

.tw-caret-neutral-900\/90 {
  caret-color: rgb(23 23 23 / 0.9) !important;
}

.tw-caret-neutral-900\/95 {
  caret-color: rgb(23 23 23 / 0.95) !important;
}

.tw-caret-neutral-950 {
  caret-color: #0a0a0a !important;
}

.tw-caret-neutral-950\/0 {
  caret-color: rgb(10 10 10 / 0) !important;
}

.tw-caret-neutral-950\/10 {
  caret-color: rgb(10 10 10 / 0.1) !important;
}

.tw-caret-neutral-950\/100 {
  caret-color: rgb(10 10 10 / 1) !important;
}

.tw-caret-neutral-950\/15 {
  caret-color: rgb(10 10 10 / 0.15) !important;
}

.tw-caret-neutral-950\/20 {
  caret-color: rgb(10 10 10 / 0.2) !important;
}

.tw-caret-neutral-950\/25 {
  caret-color: rgb(10 10 10 / 0.25) !important;
}

.tw-caret-neutral-950\/30 {
  caret-color: rgb(10 10 10 / 0.3) !important;
}

.tw-caret-neutral-950\/35 {
  caret-color: rgb(10 10 10 / 0.35) !important;
}

.tw-caret-neutral-950\/40 {
  caret-color: rgb(10 10 10 / 0.4) !important;
}

.tw-caret-neutral-950\/45 {
  caret-color: rgb(10 10 10 / 0.45) !important;
}

.tw-caret-neutral-950\/5 {
  caret-color: rgb(10 10 10 / 0.05) !important;
}

.tw-caret-neutral-950\/50 {
  caret-color: rgb(10 10 10 / 0.5) !important;
}

.tw-caret-neutral-950\/55 {
  caret-color: rgb(10 10 10 / 0.55) !important;
}

.tw-caret-neutral-950\/60 {
  caret-color: rgb(10 10 10 / 0.6) !important;
}

.tw-caret-neutral-950\/65 {
  caret-color: rgb(10 10 10 / 0.65) !important;
}

.tw-caret-neutral-950\/70 {
  caret-color: rgb(10 10 10 / 0.7) !important;
}

.tw-caret-neutral-950\/75 {
  caret-color: rgb(10 10 10 / 0.75) !important;
}

.tw-caret-neutral-950\/80 {
  caret-color: rgb(10 10 10 / 0.8) !important;
}

.tw-caret-neutral-950\/85 {
  caret-color: rgb(10 10 10 / 0.85) !important;
}

.tw-caret-neutral-950\/90 {
  caret-color: rgb(10 10 10 / 0.9) !important;
}

.tw-caret-neutral-950\/95 {
  caret-color: rgb(10 10 10 / 0.95) !important;
}

.tw-caret-orange-100 {
  caret-color: #ffedd5 !important;
}

.tw-caret-orange-100\/0 {
  caret-color: rgb(255 237 213 / 0) !important;
}

.tw-caret-orange-100\/10 {
  caret-color: rgb(255 237 213 / 0.1) !important;
}

.tw-caret-orange-100\/100 {
  caret-color: rgb(255 237 213 / 1) !important;
}

.tw-caret-orange-100\/15 {
  caret-color: rgb(255 237 213 / 0.15) !important;
}

.tw-caret-orange-100\/20 {
  caret-color: rgb(255 237 213 / 0.2) !important;
}

.tw-caret-orange-100\/25 {
  caret-color: rgb(255 237 213 / 0.25) !important;
}

.tw-caret-orange-100\/30 {
  caret-color: rgb(255 237 213 / 0.3) !important;
}

.tw-caret-orange-100\/35 {
  caret-color: rgb(255 237 213 / 0.35) !important;
}

.tw-caret-orange-100\/40 {
  caret-color: rgb(255 237 213 / 0.4) !important;
}

.tw-caret-orange-100\/45 {
  caret-color: rgb(255 237 213 / 0.45) !important;
}

.tw-caret-orange-100\/5 {
  caret-color: rgb(255 237 213 / 0.05) !important;
}

.tw-caret-orange-100\/50 {
  caret-color: rgb(255 237 213 / 0.5) !important;
}

.tw-caret-orange-100\/55 {
  caret-color: rgb(255 237 213 / 0.55) !important;
}

.tw-caret-orange-100\/60 {
  caret-color: rgb(255 237 213 / 0.6) !important;
}

.tw-caret-orange-100\/65 {
  caret-color: rgb(255 237 213 / 0.65) !important;
}

.tw-caret-orange-100\/70 {
  caret-color: rgb(255 237 213 / 0.7) !important;
}

.tw-caret-orange-100\/75 {
  caret-color: rgb(255 237 213 / 0.75) !important;
}

.tw-caret-orange-100\/80 {
  caret-color: rgb(255 237 213 / 0.8) !important;
}

.tw-caret-orange-100\/85 {
  caret-color: rgb(255 237 213 / 0.85) !important;
}

.tw-caret-orange-100\/90 {
  caret-color: rgb(255 237 213 / 0.9) !important;
}

.tw-caret-orange-100\/95 {
  caret-color: rgb(255 237 213 / 0.95) !important;
}

.tw-caret-orange-200 {
  caret-color: #fed7aa !important;
}

.tw-caret-orange-200\/0 {
  caret-color: rgb(254 215 170 / 0) !important;
}

.tw-caret-orange-200\/10 {
  caret-color: rgb(254 215 170 / 0.1) !important;
}

.tw-caret-orange-200\/100 {
  caret-color: rgb(254 215 170 / 1) !important;
}

.tw-caret-orange-200\/15 {
  caret-color: rgb(254 215 170 / 0.15) !important;
}

.tw-caret-orange-200\/20 {
  caret-color: rgb(254 215 170 / 0.2) !important;
}

.tw-caret-orange-200\/25 {
  caret-color: rgb(254 215 170 / 0.25) !important;
}

.tw-caret-orange-200\/30 {
  caret-color: rgb(254 215 170 / 0.3) !important;
}

.tw-caret-orange-200\/35 {
  caret-color: rgb(254 215 170 / 0.35) !important;
}

.tw-caret-orange-200\/40 {
  caret-color: rgb(254 215 170 / 0.4) !important;
}

.tw-caret-orange-200\/45 {
  caret-color: rgb(254 215 170 / 0.45) !important;
}

.tw-caret-orange-200\/5 {
  caret-color: rgb(254 215 170 / 0.05) !important;
}

.tw-caret-orange-200\/50 {
  caret-color: rgb(254 215 170 / 0.5) !important;
}

.tw-caret-orange-200\/55 {
  caret-color: rgb(254 215 170 / 0.55) !important;
}

.tw-caret-orange-200\/60 {
  caret-color: rgb(254 215 170 / 0.6) !important;
}

.tw-caret-orange-200\/65 {
  caret-color: rgb(254 215 170 / 0.65) !important;
}

.tw-caret-orange-200\/70 {
  caret-color: rgb(254 215 170 / 0.7) !important;
}

.tw-caret-orange-200\/75 {
  caret-color: rgb(254 215 170 / 0.75) !important;
}

.tw-caret-orange-200\/80 {
  caret-color: rgb(254 215 170 / 0.8) !important;
}

.tw-caret-orange-200\/85 {
  caret-color: rgb(254 215 170 / 0.85) !important;
}

.tw-caret-orange-200\/90 {
  caret-color: rgb(254 215 170 / 0.9) !important;
}

.tw-caret-orange-200\/95 {
  caret-color: rgb(254 215 170 / 0.95) !important;
}

.tw-caret-orange-300 {
  caret-color: #fdba74 !important;
}

.tw-caret-orange-300\/0 {
  caret-color: rgb(253 186 116 / 0) !important;
}

.tw-caret-orange-300\/10 {
  caret-color: rgb(253 186 116 / 0.1) !important;
}

.tw-caret-orange-300\/100 {
  caret-color: rgb(253 186 116 / 1) !important;
}

.tw-caret-orange-300\/15 {
  caret-color: rgb(253 186 116 / 0.15) !important;
}

.tw-caret-orange-300\/20 {
  caret-color: rgb(253 186 116 / 0.2) !important;
}

.tw-caret-orange-300\/25 {
  caret-color: rgb(253 186 116 / 0.25) !important;
}

.tw-caret-orange-300\/30 {
  caret-color: rgb(253 186 116 / 0.3) !important;
}

.tw-caret-orange-300\/35 {
  caret-color: rgb(253 186 116 / 0.35) !important;
}

.tw-caret-orange-300\/40 {
  caret-color: rgb(253 186 116 / 0.4) !important;
}

.tw-caret-orange-300\/45 {
  caret-color: rgb(253 186 116 / 0.45) !important;
}

.tw-caret-orange-300\/5 {
  caret-color: rgb(253 186 116 / 0.05) !important;
}

.tw-caret-orange-300\/50 {
  caret-color: rgb(253 186 116 / 0.5) !important;
}

.tw-caret-orange-300\/55 {
  caret-color: rgb(253 186 116 / 0.55) !important;
}

.tw-caret-orange-300\/60 {
  caret-color: rgb(253 186 116 / 0.6) !important;
}

.tw-caret-orange-300\/65 {
  caret-color: rgb(253 186 116 / 0.65) !important;
}

.tw-caret-orange-300\/70 {
  caret-color: rgb(253 186 116 / 0.7) !important;
}

.tw-caret-orange-300\/75 {
  caret-color: rgb(253 186 116 / 0.75) !important;
}

.tw-caret-orange-300\/80 {
  caret-color: rgb(253 186 116 / 0.8) !important;
}

.tw-caret-orange-300\/85 {
  caret-color: rgb(253 186 116 / 0.85) !important;
}

.tw-caret-orange-300\/90 {
  caret-color: rgb(253 186 116 / 0.9) !important;
}

.tw-caret-orange-300\/95 {
  caret-color: rgb(253 186 116 / 0.95) !important;
}

.tw-caret-orange-400 {
  caret-color: #fb923c !important;
}

.tw-caret-orange-400\/0 {
  caret-color: rgb(251 146 60 / 0) !important;
}

.tw-caret-orange-400\/10 {
  caret-color: rgb(251 146 60 / 0.1) !important;
}

.tw-caret-orange-400\/100 {
  caret-color: rgb(251 146 60 / 1) !important;
}

.tw-caret-orange-400\/15 {
  caret-color: rgb(251 146 60 / 0.15) !important;
}

.tw-caret-orange-400\/20 {
  caret-color: rgb(251 146 60 / 0.2) !important;
}

.tw-caret-orange-400\/25 {
  caret-color: rgb(251 146 60 / 0.25) !important;
}

.tw-caret-orange-400\/30 {
  caret-color: rgb(251 146 60 / 0.3) !important;
}

.tw-caret-orange-400\/35 {
  caret-color: rgb(251 146 60 / 0.35) !important;
}

.tw-caret-orange-400\/40 {
  caret-color: rgb(251 146 60 / 0.4) !important;
}

.tw-caret-orange-400\/45 {
  caret-color: rgb(251 146 60 / 0.45) !important;
}

.tw-caret-orange-400\/5 {
  caret-color: rgb(251 146 60 / 0.05) !important;
}

.tw-caret-orange-400\/50 {
  caret-color: rgb(251 146 60 / 0.5) !important;
}

.tw-caret-orange-400\/55 {
  caret-color: rgb(251 146 60 / 0.55) !important;
}

.tw-caret-orange-400\/60 {
  caret-color: rgb(251 146 60 / 0.6) !important;
}

.tw-caret-orange-400\/65 {
  caret-color: rgb(251 146 60 / 0.65) !important;
}

.tw-caret-orange-400\/70 {
  caret-color: rgb(251 146 60 / 0.7) !important;
}

.tw-caret-orange-400\/75 {
  caret-color: rgb(251 146 60 / 0.75) !important;
}

.tw-caret-orange-400\/80 {
  caret-color: rgb(251 146 60 / 0.8) !important;
}

.tw-caret-orange-400\/85 {
  caret-color: rgb(251 146 60 / 0.85) !important;
}

.tw-caret-orange-400\/90 {
  caret-color: rgb(251 146 60 / 0.9) !important;
}

.tw-caret-orange-400\/95 {
  caret-color: rgb(251 146 60 / 0.95) !important;
}

.tw-caret-orange-50 {
  caret-color: #fff7ed !important;
}

.tw-caret-orange-50\/0 {
  caret-color: rgb(255 247 237 / 0) !important;
}

.tw-caret-orange-50\/10 {
  caret-color: rgb(255 247 237 / 0.1) !important;
}

.tw-caret-orange-50\/100 {
  caret-color: rgb(255 247 237 / 1) !important;
}

.tw-caret-orange-50\/15 {
  caret-color: rgb(255 247 237 / 0.15) !important;
}

.tw-caret-orange-50\/20 {
  caret-color: rgb(255 247 237 / 0.2) !important;
}

.tw-caret-orange-50\/25 {
  caret-color: rgb(255 247 237 / 0.25) !important;
}

.tw-caret-orange-50\/30 {
  caret-color: rgb(255 247 237 / 0.3) !important;
}

.tw-caret-orange-50\/35 {
  caret-color: rgb(255 247 237 / 0.35) !important;
}

.tw-caret-orange-50\/40 {
  caret-color: rgb(255 247 237 / 0.4) !important;
}

.tw-caret-orange-50\/45 {
  caret-color: rgb(255 247 237 / 0.45) !important;
}

.tw-caret-orange-50\/5 {
  caret-color: rgb(255 247 237 / 0.05) !important;
}

.tw-caret-orange-50\/50 {
  caret-color: rgb(255 247 237 / 0.5) !important;
}

.tw-caret-orange-50\/55 {
  caret-color: rgb(255 247 237 / 0.55) !important;
}

.tw-caret-orange-50\/60 {
  caret-color: rgb(255 247 237 / 0.6) !important;
}

.tw-caret-orange-50\/65 {
  caret-color: rgb(255 247 237 / 0.65) !important;
}

.tw-caret-orange-50\/70 {
  caret-color: rgb(255 247 237 / 0.7) !important;
}

.tw-caret-orange-50\/75 {
  caret-color: rgb(255 247 237 / 0.75) !important;
}

.tw-caret-orange-50\/80 {
  caret-color: rgb(255 247 237 / 0.8) !important;
}

.tw-caret-orange-50\/85 {
  caret-color: rgb(255 247 237 / 0.85) !important;
}

.tw-caret-orange-50\/90 {
  caret-color: rgb(255 247 237 / 0.9) !important;
}

.tw-caret-orange-50\/95 {
  caret-color: rgb(255 247 237 / 0.95) !important;
}

.tw-caret-orange-500 {
  caret-color: #f97316 !important;
}

.tw-caret-orange-500\/0 {
  caret-color: rgb(249 115 22 / 0) !important;
}

.tw-caret-orange-500\/10 {
  caret-color: rgb(249 115 22 / 0.1) !important;
}

.tw-caret-orange-500\/100 {
  caret-color: rgb(249 115 22 / 1) !important;
}

.tw-caret-orange-500\/15 {
  caret-color: rgb(249 115 22 / 0.15) !important;
}

.tw-caret-orange-500\/20 {
  caret-color: rgb(249 115 22 / 0.2) !important;
}

.tw-caret-orange-500\/25 {
  caret-color: rgb(249 115 22 / 0.25) !important;
}

.tw-caret-orange-500\/30 {
  caret-color: rgb(249 115 22 / 0.3) !important;
}

.tw-caret-orange-500\/35 {
  caret-color: rgb(249 115 22 / 0.35) !important;
}

.tw-caret-orange-500\/40 {
  caret-color: rgb(249 115 22 / 0.4) !important;
}

.tw-caret-orange-500\/45 {
  caret-color: rgb(249 115 22 / 0.45) !important;
}

.tw-caret-orange-500\/5 {
  caret-color: rgb(249 115 22 / 0.05) !important;
}

.tw-caret-orange-500\/50 {
  caret-color: rgb(249 115 22 / 0.5) !important;
}

.tw-caret-orange-500\/55 {
  caret-color: rgb(249 115 22 / 0.55) !important;
}

.tw-caret-orange-500\/60 {
  caret-color: rgb(249 115 22 / 0.6) !important;
}

.tw-caret-orange-500\/65 {
  caret-color: rgb(249 115 22 / 0.65) !important;
}

.tw-caret-orange-500\/70 {
  caret-color: rgb(249 115 22 / 0.7) !important;
}

.tw-caret-orange-500\/75 {
  caret-color: rgb(249 115 22 / 0.75) !important;
}

.tw-caret-orange-500\/80 {
  caret-color: rgb(249 115 22 / 0.8) !important;
}

.tw-caret-orange-500\/85 {
  caret-color: rgb(249 115 22 / 0.85) !important;
}

.tw-caret-orange-500\/90 {
  caret-color: rgb(249 115 22 / 0.9) !important;
}

.tw-caret-orange-500\/95 {
  caret-color: rgb(249 115 22 / 0.95) !important;
}

.tw-caret-orange-600 {
  caret-color: #ea580c !important;
}

.tw-caret-orange-600\/0 {
  caret-color: rgb(234 88 12 / 0) !important;
}

.tw-caret-orange-600\/10 {
  caret-color: rgb(234 88 12 / 0.1) !important;
}

.tw-caret-orange-600\/100 {
  caret-color: rgb(234 88 12 / 1) !important;
}

.tw-caret-orange-600\/15 {
  caret-color: rgb(234 88 12 / 0.15) !important;
}

.tw-caret-orange-600\/20 {
  caret-color: rgb(234 88 12 / 0.2) !important;
}

.tw-caret-orange-600\/25 {
  caret-color: rgb(234 88 12 / 0.25) !important;
}

.tw-caret-orange-600\/30 {
  caret-color: rgb(234 88 12 / 0.3) !important;
}

.tw-caret-orange-600\/35 {
  caret-color: rgb(234 88 12 / 0.35) !important;
}

.tw-caret-orange-600\/40 {
  caret-color: rgb(234 88 12 / 0.4) !important;
}

.tw-caret-orange-600\/45 {
  caret-color: rgb(234 88 12 / 0.45) !important;
}

.tw-caret-orange-600\/5 {
  caret-color: rgb(234 88 12 / 0.05) !important;
}

.tw-caret-orange-600\/50 {
  caret-color: rgb(234 88 12 / 0.5) !important;
}

.tw-caret-orange-600\/55 {
  caret-color: rgb(234 88 12 / 0.55) !important;
}

.tw-caret-orange-600\/60 {
  caret-color: rgb(234 88 12 / 0.6) !important;
}

.tw-caret-orange-600\/65 {
  caret-color: rgb(234 88 12 / 0.65) !important;
}

.tw-caret-orange-600\/70 {
  caret-color: rgb(234 88 12 / 0.7) !important;
}

.tw-caret-orange-600\/75 {
  caret-color: rgb(234 88 12 / 0.75) !important;
}

.tw-caret-orange-600\/80 {
  caret-color: rgb(234 88 12 / 0.8) !important;
}

.tw-caret-orange-600\/85 {
  caret-color: rgb(234 88 12 / 0.85) !important;
}

.tw-caret-orange-600\/90 {
  caret-color: rgb(234 88 12 / 0.9) !important;
}

.tw-caret-orange-600\/95 {
  caret-color: rgb(234 88 12 / 0.95) !important;
}

.tw-caret-orange-700 {
  caret-color: #c2410c !important;
}

.tw-caret-orange-700\/0 {
  caret-color: rgb(194 65 12 / 0) !important;
}

.tw-caret-orange-700\/10 {
  caret-color: rgb(194 65 12 / 0.1) !important;
}

.tw-caret-orange-700\/100 {
  caret-color: rgb(194 65 12 / 1) !important;
}

.tw-caret-orange-700\/15 {
  caret-color: rgb(194 65 12 / 0.15) !important;
}

.tw-caret-orange-700\/20 {
  caret-color: rgb(194 65 12 / 0.2) !important;
}

.tw-caret-orange-700\/25 {
  caret-color: rgb(194 65 12 / 0.25) !important;
}

.tw-caret-orange-700\/30 {
  caret-color: rgb(194 65 12 / 0.3) !important;
}

.tw-caret-orange-700\/35 {
  caret-color: rgb(194 65 12 / 0.35) !important;
}

.tw-caret-orange-700\/40 {
  caret-color: rgb(194 65 12 / 0.4) !important;
}

.tw-caret-orange-700\/45 {
  caret-color: rgb(194 65 12 / 0.45) !important;
}

.tw-caret-orange-700\/5 {
  caret-color: rgb(194 65 12 / 0.05) !important;
}

.tw-caret-orange-700\/50 {
  caret-color: rgb(194 65 12 / 0.5) !important;
}

.tw-caret-orange-700\/55 {
  caret-color: rgb(194 65 12 / 0.55) !important;
}

.tw-caret-orange-700\/60 {
  caret-color: rgb(194 65 12 / 0.6) !important;
}

.tw-caret-orange-700\/65 {
  caret-color: rgb(194 65 12 / 0.65) !important;
}

.tw-caret-orange-700\/70 {
  caret-color: rgb(194 65 12 / 0.7) !important;
}

.tw-caret-orange-700\/75 {
  caret-color: rgb(194 65 12 / 0.75) !important;
}

.tw-caret-orange-700\/80 {
  caret-color: rgb(194 65 12 / 0.8) !important;
}

.tw-caret-orange-700\/85 {
  caret-color: rgb(194 65 12 / 0.85) !important;
}

.tw-caret-orange-700\/90 {
  caret-color: rgb(194 65 12 / 0.9) !important;
}

.tw-caret-orange-700\/95 {
  caret-color: rgb(194 65 12 / 0.95) !important;
}

.tw-caret-orange-800 {
  caret-color: #9a3412 !important;
}

.tw-caret-orange-800\/0 {
  caret-color: rgb(154 52 18 / 0) !important;
}

.tw-caret-orange-800\/10 {
  caret-color: rgb(154 52 18 / 0.1) !important;
}

.tw-caret-orange-800\/100 {
  caret-color: rgb(154 52 18 / 1) !important;
}

.tw-caret-orange-800\/15 {
  caret-color: rgb(154 52 18 / 0.15) !important;
}

.tw-caret-orange-800\/20 {
  caret-color: rgb(154 52 18 / 0.2) !important;
}

.tw-caret-orange-800\/25 {
  caret-color: rgb(154 52 18 / 0.25) !important;
}

.tw-caret-orange-800\/30 {
  caret-color: rgb(154 52 18 / 0.3) !important;
}

.tw-caret-orange-800\/35 {
  caret-color: rgb(154 52 18 / 0.35) !important;
}

.tw-caret-orange-800\/40 {
  caret-color: rgb(154 52 18 / 0.4) !important;
}

.tw-caret-orange-800\/45 {
  caret-color: rgb(154 52 18 / 0.45) !important;
}

.tw-caret-orange-800\/5 {
  caret-color: rgb(154 52 18 / 0.05) !important;
}

.tw-caret-orange-800\/50 {
  caret-color: rgb(154 52 18 / 0.5) !important;
}

.tw-caret-orange-800\/55 {
  caret-color: rgb(154 52 18 / 0.55) !important;
}

.tw-caret-orange-800\/60 {
  caret-color: rgb(154 52 18 / 0.6) !important;
}

.tw-caret-orange-800\/65 {
  caret-color: rgb(154 52 18 / 0.65) !important;
}

.tw-caret-orange-800\/70 {
  caret-color: rgb(154 52 18 / 0.7) !important;
}

.tw-caret-orange-800\/75 {
  caret-color: rgb(154 52 18 / 0.75) !important;
}

.tw-caret-orange-800\/80 {
  caret-color: rgb(154 52 18 / 0.8) !important;
}

.tw-caret-orange-800\/85 {
  caret-color: rgb(154 52 18 / 0.85) !important;
}

.tw-caret-orange-800\/90 {
  caret-color: rgb(154 52 18 / 0.9) !important;
}

.tw-caret-orange-800\/95 {
  caret-color: rgb(154 52 18 / 0.95) !important;
}

.tw-caret-orange-900 {
  caret-color: #7c2d12 !important;
}

.tw-caret-orange-900\/0 {
  caret-color: rgb(124 45 18 / 0) !important;
}

.tw-caret-orange-900\/10 {
  caret-color: rgb(124 45 18 / 0.1) !important;
}

.tw-caret-orange-900\/100 {
  caret-color: rgb(124 45 18 / 1) !important;
}

.tw-caret-orange-900\/15 {
  caret-color: rgb(124 45 18 / 0.15) !important;
}

.tw-caret-orange-900\/20 {
  caret-color: rgb(124 45 18 / 0.2) !important;
}

.tw-caret-orange-900\/25 {
  caret-color: rgb(124 45 18 / 0.25) !important;
}

.tw-caret-orange-900\/30 {
  caret-color: rgb(124 45 18 / 0.3) !important;
}

.tw-caret-orange-900\/35 {
  caret-color: rgb(124 45 18 / 0.35) !important;
}

.tw-caret-orange-900\/40 {
  caret-color: rgb(124 45 18 / 0.4) !important;
}

.tw-caret-orange-900\/45 {
  caret-color: rgb(124 45 18 / 0.45) !important;
}

.tw-caret-orange-900\/5 {
  caret-color: rgb(124 45 18 / 0.05) !important;
}

.tw-caret-orange-900\/50 {
  caret-color: rgb(124 45 18 / 0.5) !important;
}

.tw-caret-orange-900\/55 {
  caret-color: rgb(124 45 18 / 0.55) !important;
}

.tw-caret-orange-900\/60 {
  caret-color: rgb(124 45 18 / 0.6) !important;
}

.tw-caret-orange-900\/65 {
  caret-color: rgb(124 45 18 / 0.65) !important;
}

.tw-caret-orange-900\/70 {
  caret-color: rgb(124 45 18 / 0.7) !important;
}

.tw-caret-orange-900\/75 {
  caret-color: rgb(124 45 18 / 0.75) !important;
}

.tw-caret-orange-900\/80 {
  caret-color: rgb(124 45 18 / 0.8) !important;
}

.tw-caret-orange-900\/85 {
  caret-color: rgb(124 45 18 / 0.85) !important;
}

.tw-caret-orange-900\/90 {
  caret-color: rgb(124 45 18 / 0.9) !important;
}

.tw-caret-orange-900\/95 {
  caret-color: rgb(124 45 18 / 0.95) !important;
}

.tw-caret-orange-950 {
  caret-color: #431407 !important;
}

.tw-caret-orange-950\/0 {
  caret-color: rgb(67 20 7 / 0) !important;
}

.tw-caret-orange-950\/10 {
  caret-color: rgb(67 20 7 / 0.1) !important;
}

.tw-caret-orange-950\/100 {
  caret-color: rgb(67 20 7 / 1) !important;
}

.tw-caret-orange-950\/15 {
  caret-color: rgb(67 20 7 / 0.15) !important;
}

.tw-caret-orange-950\/20 {
  caret-color: rgb(67 20 7 / 0.2) !important;
}

.tw-caret-orange-950\/25 {
  caret-color: rgb(67 20 7 / 0.25) !important;
}

.tw-caret-orange-950\/30 {
  caret-color: rgb(67 20 7 / 0.3) !important;
}

.tw-caret-orange-950\/35 {
  caret-color: rgb(67 20 7 / 0.35) !important;
}

.tw-caret-orange-950\/40 {
  caret-color: rgb(67 20 7 / 0.4) !important;
}

.tw-caret-orange-950\/45 {
  caret-color: rgb(67 20 7 / 0.45) !important;
}

.tw-caret-orange-950\/5 {
  caret-color: rgb(67 20 7 / 0.05) !important;
}

.tw-caret-orange-950\/50 {
  caret-color: rgb(67 20 7 / 0.5) !important;
}

.tw-caret-orange-950\/55 {
  caret-color: rgb(67 20 7 / 0.55) !important;
}

.tw-caret-orange-950\/60 {
  caret-color: rgb(67 20 7 / 0.6) !important;
}

.tw-caret-orange-950\/65 {
  caret-color: rgb(67 20 7 / 0.65) !important;
}

.tw-caret-orange-950\/70 {
  caret-color: rgb(67 20 7 / 0.7) !important;
}

.tw-caret-orange-950\/75 {
  caret-color: rgb(67 20 7 / 0.75) !important;
}

.tw-caret-orange-950\/80 {
  caret-color: rgb(67 20 7 / 0.8) !important;
}

.tw-caret-orange-950\/85 {
  caret-color: rgb(67 20 7 / 0.85) !important;
}

.tw-caret-orange-950\/90 {
  caret-color: rgb(67 20 7 / 0.9) !important;
}

.tw-caret-orange-950\/95 {
  caret-color: rgb(67 20 7 / 0.95) !important;
}

.tw-caret-pink-100 {
  caret-color: #fce7f3 !important;
}

.tw-caret-pink-100\/0 {
  caret-color: rgb(252 231 243 / 0) !important;
}

.tw-caret-pink-100\/10 {
  caret-color: rgb(252 231 243 / 0.1) !important;
}

.tw-caret-pink-100\/100 {
  caret-color: rgb(252 231 243 / 1) !important;
}

.tw-caret-pink-100\/15 {
  caret-color: rgb(252 231 243 / 0.15) !important;
}

.tw-caret-pink-100\/20 {
  caret-color: rgb(252 231 243 / 0.2) !important;
}

.tw-caret-pink-100\/25 {
  caret-color: rgb(252 231 243 / 0.25) !important;
}

.tw-caret-pink-100\/30 {
  caret-color: rgb(252 231 243 / 0.3) !important;
}

.tw-caret-pink-100\/35 {
  caret-color: rgb(252 231 243 / 0.35) !important;
}

.tw-caret-pink-100\/40 {
  caret-color: rgb(252 231 243 / 0.4) !important;
}

.tw-caret-pink-100\/45 {
  caret-color: rgb(252 231 243 / 0.45) !important;
}

.tw-caret-pink-100\/5 {
  caret-color: rgb(252 231 243 / 0.05) !important;
}

.tw-caret-pink-100\/50 {
  caret-color: rgb(252 231 243 / 0.5) !important;
}

.tw-caret-pink-100\/55 {
  caret-color: rgb(252 231 243 / 0.55) !important;
}

.tw-caret-pink-100\/60 {
  caret-color: rgb(252 231 243 / 0.6) !important;
}

.tw-caret-pink-100\/65 {
  caret-color: rgb(252 231 243 / 0.65) !important;
}

.tw-caret-pink-100\/70 {
  caret-color: rgb(252 231 243 / 0.7) !important;
}

.tw-caret-pink-100\/75 {
  caret-color: rgb(252 231 243 / 0.75) !important;
}

.tw-caret-pink-100\/80 {
  caret-color: rgb(252 231 243 / 0.8) !important;
}

.tw-caret-pink-100\/85 {
  caret-color: rgb(252 231 243 / 0.85) !important;
}

.tw-caret-pink-100\/90 {
  caret-color: rgb(252 231 243 / 0.9) !important;
}

.tw-caret-pink-100\/95 {
  caret-color: rgb(252 231 243 / 0.95) !important;
}

.tw-caret-pink-200 {
  caret-color: #fbcfe8 !important;
}

.tw-caret-pink-200\/0 {
  caret-color: rgb(251 207 232 / 0) !important;
}

.tw-caret-pink-200\/10 {
  caret-color: rgb(251 207 232 / 0.1) !important;
}

.tw-caret-pink-200\/100 {
  caret-color: rgb(251 207 232 / 1) !important;
}

.tw-caret-pink-200\/15 {
  caret-color: rgb(251 207 232 / 0.15) !important;
}

.tw-caret-pink-200\/20 {
  caret-color: rgb(251 207 232 / 0.2) !important;
}

.tw-caret-pink-200\/25 {
  caret-color: rgb(251 207 232 / 0.25) !important;
}

.tw-caret-pink-200\/30 {
  caret-color: rgb(251 207 232 / 0.3) !important;
}

.tw-caret-pink-200\/35 {
  caret-color: rgb(251 207 232 / 0.35) !important;
}

.tw-caret-pink-200\/40 {
  caret-color: rgb(251 207 232 / 0.4) !important;
}

.tw-caret-pink-200\/45 {
  caret-color: rgb(251 207 232 / 0.45) !important;
}

.tw-caret-pink-200\/5 {
  caret-color: rgb(251 207 232 / 0.05) !important;
}

.tw-caret-pink-200\/50 {
  caret-color: rgb(251 207 232 / 0.5) !important;
}

.tw-caret-pink-200\/55 {
  caret-color: rgb(251 207 232 / 0.55) !important;
}

.tw-caret-pink-200\/60 {
  caret-color: rgb(251 207 232 / 0.6) !important;
}

.tw-caret-pink-200\/65 {
  caret-color: rgb(251 207 232 / 0.65) !important;
}

.tw-caret-pink-200\/70 {
  caret-color: rgb(251 207 232 / 0.7) !important;
}

.tw-caret-pink-200\/75 {
  caret-color: rgb(251 207 232 / 0.75) !important;
}

.tw-caret-pink-200\/80 {
  caret-color: rgb(251 207 232 / 0.8) !important;
}

.tw-caret-pink-200\/85 {
  caret-color: rgb(251 207 232 / 0.85) !important;
}

.tw-caret-pink-200\/90 {
  caret-color: rgb(251 207 232 / 0.9) !important;
}

.tw-caret-pink-200\/95 {
  caret-color: rgb(251 207 232 / 0.95) !important;
}

.tw-caret-pink-300 {
  caret-color: #f9a8d4 !important;
}

.tw-caret-pink-300\/0 {
  caret-color: rgb(249 168 212 / 0) !important;
}

.tw-caret-pink-300\/10 {
  caret-color: rgb(249 168 212 / 0.1) !important;
}

.tw-caret-pink-300\/100 {
  caret-color: rgb(249 168 212 / 1) !important;
}

.tw-caret-pink-300\/15 {
  caret-color: rgb(249 168 212 / 0.15) !important;
}

.tw-caret-pink-300\/20 {
  caret-color: rgb(249 168 212 / 0.2) !important;
}

.tw-caret-pink-300\/25 {
  caret-color: rgb(249 168 212 / 0.25) !important;
}

.tw-caret-pink-300\/30 {
  caret-color: rgb(249 168 212 / 0.3) !important;
}

.tw-caret-pink-300\/35 {
  caret-color: rgb(249 168 212 / 0.35) !important;
}

.tw-caret-pink-300\/40 {
  caret-color: rgb(249 168 212 / 0.4) !important;
}

.tw-caret-pink-300\/45 {
  caret-color: rgb(249 168 212 / 0.45) !important;
}

.tw-caret-pink-300\/5 {
  caret-color: rgb(249 168 212 / 0.05) !important;
}

.tw-caret-pink-300\/50 {
  caret-color: rgb(249 168 212 / 0.5) !important;
}

.tw-caret-pink-300\/55 {
  caret-color: rgb(249 168 212 / 0.55) !important;
}

.tw-caret-pink-300\/60 {
  caret-color: rgb(249 168 212 / 0.6) !important;
}

.tw-caret-pink-300\/65 {
  caret-color: rgb(249 168 212 / 0.65) !important;
}

.tw-caret-pink-300\/70 {
  caret-color: rgb(249 168 212 / 0.7) !important;
}

.tw-caret-pink-300\/75 {
  caret-color: rgb(249 168 212 / 0.75) !important;
}

.tw-caret-pink-300\/80 {
  caret-color: rgb(249 168 212 / 0.8) !important;
}

.tw-caret-pink-300\/85 {
  caret-color: rgb(249 168 212 / 0.85) !important;
}

.tw-caret-pink-300\/90 {
  caret-color: rgb(249 168 212 / 0.9) !important;
}

.tw-caret-pink-300\/95 {
  caret-color: rgb(249 168 212 / 0.95) !important;
}

.tw-caret-pink-400 {
  caret-color: #f472b6 !important;
}

.tw-caret-pink-400\/0 {
  caret-color: rgb(244 114 182 / 0) !important;
}

.tw-caret-pink-400\/10 {
  caret-color: rgb(244 114 182 / 0.1) !important;
}

.tw-caret-pink-400\/100 {
  caret-color: rgb(244 114 182 / 1) !important;
}

.tw-caret-pink-400\/15 {
  caret-color: rgb(244 114 182 / 0.15) !important;
}

.tw-caret-pink-400\/20 {
  caret-color: rgb(244 114 182 / 0.2) !important;
}

.tw-caret-pink-400\/25 {
  caret-color: rgb(244 114 182 / 0.25) !important;
}

.tw-caret-pink-400\/30 {
  caret-color: rgb(244 114 182 / 0.3) !important;
}

.tw-caret-pink-400\/35 {
  caret-color: rgb(244 114 182 / 0.35) !important;
}

.tw-caret-pink-400\/40 {
  caret-color: rgb(244 114 182 / 0.4) !important;
}

.tw-caret-pink-400\/45 {
  caret-color: rgb(244 114 182 / 0.45) !important;
}

.tw-caret-pink-400\/5 {
  caret-color: rgb(244 114 182 / 0.05) !important;
}

.tw-caret-pink-400\/50 {
  caret-color: rgb(244 114 182 / 0.5) !important;
}

.tw-caret-pink-400\/55 {
  caret-color: rgb(244 114 182 / 0.55) !important;
}

.tw-caret-pink-400\/60 {
  caret-color: rgb(244 114 182 / 0.6) !important;
}

.tw-caret-pink-400\/65 {
  caret-color: rgb(244 114 182 / 0.65) !important;
}

.tw-caret-pink-400\/70 {
  caret-color: rgb(244 114 182 / 0.7) !important;
}

.tw-caret-pink-400\/75 {
  caret-color: rgb(244 114 182 / 0.75) !important;
}

.tw-caret-pink-400\/80 {
  caret-color: rgb(244 114 182 / 0.8) !important;
}

.tw-caret-pink-400\/85 {
  caret-color: rgb(244 114 182 / 0.85) !important;
}

.tw-caret-pink-400\/90 {
  caret-color: rgb(244 114 182 / 0.9) !important;
}

.tw-caret-pink-400\/95 {
  caret-color: rgb(244 114 182 / 0.95) !important;
}

.tw-caret-pink-50 {
  caret-color: #fdf2f8 !important;
}

.tw-caret-pink-50\/0 {
  caret-color: rgb(253 242 248 / 0) !important;
}

.tw-caret-pink-50\/10 {
  caret-color: rgb(253 242 248 / 0.1) !important;
}

.tw-caret-pink-50\/100 {
  caret-color: rgb(253 242 248 / 1) !important;
}

.tw-caret-pink-50\/15 {
  caret-color: rgb(253 242 248 / 0.15) !important;
}

.tw-caret-pink-50\/20 {
  caret-color: rgb(253 242 248 / 0.2) !important;
}

.tw-caret-pink-50\/25 {
  caret-color: rgb(253 242 248 / 0.25) !important;
}

.tw-caret-pink-50\/30 {
  caret-color: rgb(253 242 248 / 0.3) !important;
}

.tw-caret-pink-50\/35 {
  caret-color: rgb(253 242 248 / 0.35) !important;
}

.tw-caret-pink-50\/40 {
  caret-color: rgb(253 242 248 / 0.4) !important;
}

.tw-caret-pink-50\/45 {
  caret-color: rgb(253 242 248 / 0.45) !important;
}

.tw-caret-pink-50\/5 {
  caret-color: rgb(253 242 248 / 0.05) !important;
}

.tw-caret-pink-50\/50 {
  caret-color: rgb(253 242 248 / 0.5) !important;
}

.tw-caret-pink-50\/55 {
  caret-color: rgb(253 242 248 / 0.55) !important;
}

.tw-caret-pink-50\/60 {
  caret-color: rgb(253 242 248 / 0.6) !important;
}

.tw-caret-pink-50\/65 {
  caret-color: rgb(253 242 248 / 0.65) !important;
}

.tw-caret-pink-50\/70 {
  caret-color: rgb(253 242 248 / 0.7) !important;
}

.tw-caret-pink-50\/75 {
  caret-color: rgb(253 242 248 / 0.75) !important;
}

.tw-caret-pink-50\/80 {
  caret-color: rgb(253 242 248 / 0.8) !important;
}

.tw-caret-pink-50\/85 {
  caret-color: rgb(253 242 248 / 0.85) !important;
}

.tw-caret-pink-50\/90 {
  caret-color: rgb(253 242 248 / 0.9) !important;
}

.tw-caret-pink-50\/95 {
  caret-color: rgb(253 242 248 / 0.95) !important;
}

.tw-caret-pink-500 {
  caret-color: #ec4899 !important;
}

.tw-caret-pink-500\/0 {
  caret-color: rgb(236 72 153 / 0) !important;
}

.tw-caret-pink-500\/10 {
  caret-color: rgb(236 72 153 / 0.1) !important;
}

.tw-caret-pink-500\/100 {
  caret-color: rgb(236 72 153 / 1) !important;
}

.tw-caret-pink-500\/15 {
  caret-color: rgb(236 72 153 / 0.15) !important;
}

.tw-caret-pink-500\/20 {
  caret-color: rgb(236 72 153 / 0.2) !important;
}

.tw-caret-pink-500\/25 {
  caret-color: rgb(236 72 153 / 0.25) !important;
}

.tw-caret-pink-500\/30 {
  caret-color: rgb(236 72 153 / 0.3) !important;
}

.tw-caret-pink-500\/35 {
  caret-color: rgb(236 72 153 / 0.35) !important;
}

.tw-caret-pink-500\/40 {
  caret-color: rgb(236 72 153 / 0.4) !important;
}

.tw-caret-pink-500\/45 {
  caret-color: rgb(236 72 153 / 0.45) !important;
}

.tw-caret-pink-500\/5 {
  caret-color: rgb(236 72 153 / 0.05) !important;
}

.tw-caret-pink-500\/50 {
  caret-color: rgb(236 72 153 / 0.5) !important;
}

.tw-caret-pink-500\/55 {
  caret-color: rgb(236 72 153 / 0.55) !important;
}

.tw-caret-pink-500\/60 {
  caret-color: rgb(236 72 153 / 0.6) !important;
}

.tw-caret-pink-500\/65 {
  caret-color: rgb(236 72 153 / 0.65) !important;
}

.tw-caret-pink-500\/70 {
  caret-color: rgb(236 72 153 / 0.7) !important;
}

.tw-caret-pink-500\/75 {
  caret-color: rgb(236 72 153 / 0.75) !important;
}

.tw-caret-pink-500\/80 {
  caret-color: rgb(236 72 153 / 0.8) !important;
}

.tw-caret-pink-500\/85 {
  caret-color: rgb(236 72 153 / 0.85) !important;
}

.tw-caret-pink-500\/90 {
  caret-color: rgb(236 72 153 / 0.9) !important;
}

.tw-caret-pink-500\/95 {
  caret-color: rgb(236 72 153 / 0.95) !important;
}

.tw-caret-pink-600 {
  caret-color: #db2777 !important;
}

.tw-caret-pink-600\/0 {
  caret-color: rgb(219 39 119 / 0) !important;
}

.tw-caret-pink-600\/10 {
  caret-color: rgb(219 39 119 / 0.1) !important;
}

.tw-caret-pink-600\/100 {
  caret-color: rgb(219 39 119 / 1) !important;
}

.tw-caret-pink-600\/15 {
  caret-color: rgb(219 39 119 / 0.15) !important;
}

.tw-caret-pink-600\/20 {
  caret-color: rgb(219 39 119 / 0.2) !important;
}

.tw-caret-pink-600\/25 {
  caret-color: rgb(219 39 119 / 0.25) !important;
}

.tw-caret-pink-600\/30 {
  caret-color: rgb(219 39 119 / 0.3) !important;
}

.tw-caret-pink-600\/35 {
  caret-color: rgb(219 39 119 / 0.35) !important;
}

.tw-caret-pink-600\/40 {
  caret-color: rgb(219 39 119 / 0.4) !important;
}

.tw-caret-pink-600\/45 {
  caret-color: rgb(219 39 119 / 0.45) !important;
}

.tw-caret-pink-600\/5 {
  caret-color: rgb(219 39 119 / 0.05) !important;
}

.tw-caret-pink-600\/50 {
  caret-color: rgb(219 39 119 / 0.5) !important;
}

.tw-caret-pink-600\/55 {
  caret-color: rgb(219 39 119 / 0.55) !important;
}

.tw-caret-pink-600\/60 {
  caret-color: rgb(219 39 119 / 0.6) !important;
}

.tw-caret-pink-600\/65 {
  caret-color: rgb(219 39 119 / 0.65) !important;
}

.tw-caret-pink-600\/70 {
  caret-color: rgb(219 39 119 / 0.7) !important;
}

.tw-caret-pink-600\/75 {
  caret-color: rgb(219 39 119 / 0.75) !important;
}

.tw-caret-pink-600\/80 {
  caret-color: rgb(219 39 119 / 0.8) !important;
}

.tw-caret-pink-600\/85 {
  caret-color: rgb(219 39 119 / 0.85) !important;
}

.tw-caret-pink-600\/90 {
  caret-color: rgb(219 39 119 / 0.9) !important;
}

.tw-caret-pink-600\/95 {
  caret-color: rgb(219 39 119 / 0.95) !important;
}

.tw-caret-pink-700 {
  caret-color: #be185d !important;
}

.tw-caret-pink-700\/0 {
  caret-color: rgb(190 24 93 / 0) !important;
}

.tw-caret-pink-700\/10 {
  caret-color: rgb(190 24 93 / 0.1) !important;
}

.tw-caret-pink-700\/100 {
  caret-color: rgb(190 24 93 / 1) !important;
}

.tw-caret-pink-700\/15 {
  caret-color: rgb(190 24 93 / 0.15) !important;
}

.tw-caret-pink-700\/20 {
  caret-color: rgb(190 24 93 / 0.2) !important;
}

.tw-caret-pink-700\/25 {
  caret-color: rgb(190 24 93 / 0.25) !important;
}

.tw-caret-pink-700\/30 {
  caret-color: rgb(190 24 93 / 0.3) !important;
}

.tw-caret-pink-700\/35 {
  caret-color: rgb(190 24 93 / 0.35) !important;
}

.tw-caret-pink-700\/40 {
  caret-color: rgb(190 24 93 / 0.4) !important;
}

.tw-caret-pink-700\/45 {
  caret-color: rgb(190 24 93 / 0.45) !important;
}

.tw-caret-pink-700\/5 {
  caret-color: rgb(190 24 93 / 0.05) !important;
}

.tw-caret-pink-700\/50 {
  caret-color: rgb(190 24 93 / 0.5) !important;
}

.tw-caret-pink-700\/55 {
  caret-color: rgb(190 24 93 / 0.55) !important;
}

.tw-caret-pink-700\/60 {
  caret-color: rgb(190 24 93 / 0.6) !important;
}

.tw-caret-pink-700\/65 {
  caret-color: rgb(190 24 93 / 0.65) !important;
}

.tw-caret-pink-700\/70 {
  caret-color: rgb(190 24 93 / 0.7) !important;
}

.tw-caret-pink-700\/75 {
  caret-color: rgb(190 24 93 / 0.75) !important;
}

.tw-caret-pink-700\/80 {
  caret-color: rgb(190 24 93 / 0.8) !important;
}

.tw-caret-pink-700\/85 {
  caret-color: rgb(190 24 93 / 0.85) !important;
}

.tw-caret-pink-700\/90 {
  caret-color: rgb(190 24 93 / 0.9) !important;
}

.tw-caret-pink-700\/95 {
  caret-color: rgb(190 24 93 / 0.95) !important;
}

.tw-caret-pink-800 {
  caret-color: #9d174d !important;
}

.tw-caret-pink-800\/0 {
  caret-color: rgb(157 23 77 / 0) !important;
}

.tw-caret-pink-800\/10 {
  caret-color: rgb(157 23 77 / 0.1) !important;
}

.tw-caret-pink-800\/100 {
  caret-color: rgb(157 23 77 / 1) !important;
}

.tw-caret-pink-800\/15 {
  caret-color: rgb(157 23 77 / 0.15) !important;
}

.tw-caret-pink-800\/20 {
  caret-color: rgb(157 23 77 / 0.2) !important;
}

.tw-caret-pink-800\/25 {
  caret-color: rgb(157 23 77 / 0.25) !important;
}

.tw-caret-pink-800\/30 {
  caret-color: rgb(157 23 77 / 0.3) !important;
}

.tw-caret-pink-800\/35 {
  caret-color: rgb(157 23 77 / 0.35) !important;
}

.tw-caret-pink-800\/40 {
  caret-color: rgb(157 23 77 / 0.4) !important;
}

.tw-caret-pink-800\/45 {
  caret-color: rgb(157 23 77 / 0.45) !important;
}

.tw-caret-pink-800\/5 {
  caret-color: rgb(157 23 77 / 0.05) !important;
}

.tw-caret-pink-800\/50 {
  caret-color: rgb(157 23 77 / 0.5) !important;
}

.tw-caret-pink-800\/55 {
  caret-color: rgb(157 23 77 / 0.55) !important;
}

.tw-caret-pink-800\/60 {
  caret-color: rgb(157 23 77 / 0.6) !important;
}

.tw-caret-pink-800\/65 {
  caret-color: rgb(157 23 77 / 0.65) !important;
}

.tw-caret-pink-800\/70 {
  caret-color: rgb(157 23 77 / 0.7) !important;
}

.tw-caret-pink-800\/75 {
  caret-color: rgb(157 23 77 / 0.75) !important;
}

.tw-caret-pink-800\/80 {
  caret-color: rgb(157 23 77 / 0.8) !important;
}

.tw-caret-pink-800\/85 {
  caret-color: rgb(157 23 77 / 0.85) !important;
}

.tw-caret-pink-800\/90 {
  caret-color: rgb(157 23 77 / 0.9) !important;
}

.tw-caret-pink-800\/95 {
  caret-color: rgb(157 23 77 / 0.95) !important;
}

.tw-caret-pink-900 {
  caret-color: #831843 !important;
}

.tw-caret-pink-900\/0 {
  caret-color: rgb(131 24 67 / 0) !important;
}

.tw-caret-pink-900\/10 {
  caret-color: rgb(131 24 67 / 0.1) !important;
}

.tw-caret-pink-900\/100 {
  caret-color: rgb(131 24 67 / 1) !important;
}

.tw-caret-pink-900\/15 {
  caret-color: rgb(131 24 67 / 0.15) !important;
}

.tw-caret-pink-900\/20 {
  caret-color: rgb(131 24 67 / 0.2) !important;
}

.tw-caret-pink-900\/25 {
  caret-color: rgb(131 24 67 / 0.25) !important;
}

.tw-caret-pink-900\/30 {
  caret-color: rgb(131 24 67 / 0.3) !important;
}

.tw-caret-pink-900\/35 {
  caret-color: rgb(131 24 67 / 0.35) !important;
}

.tw-caret-pink-900\/40 {
  caret-color: rgb(131 24 67 / 0.4) !important;
}

.tw-caret-pink-900\/45 {
  caret-color: rgb(131 24 67 / 0.45) !important;
}

.tw-caret-pink-900\/5 {
  caret-color: rgb(131 24 67 / 0.05) !important;
}

.tw-caret-pink-900\/50 {
  caret-color: rgb(131 24 67 / 0.5) !important;
}

.tw-caret-pink-900\/55 {
  caret-color: rgb(131 24 67 / 0.55) !important;
}

.tw-caret-pink-900\/60 {
  caret-color: rgb(131 24 67 / 0.6) !important;
}

.tw-caret-pink-900\/65 {
  caret-color: rgb(131 24 67 / 0.65) !important;
}

.tw-caret-pink-900\/70 {
  caret-color: rgb(131 24 67 / 0.7) !important;
}

.tw-caret-pink-900\/75 {
  caret-color: rgb(131 24 67 / 0.75) !important;
}

.tw-caret-pink-900\/80 {
  caret-color: rgb(131 24 67 / 0.8) !important;
}

.tw-caret-pink-900\/85 {
  caret-color: rgb(131 24 67 / 0.85) !important;
}

.tw-caret-pink-900\/90 {
  caret-color: rgb(131 24 67 / 0.9) !important;
}

.tw-caret-pink-900\/95 {
  caret-color: rgb(131 24 67 / 0.95) !important;
}

.tw-caret-pink-950 {
  caret-color: #500724 !important;
}

.tw-caret-pink-950\/0 {
  caret-color: rgb(80 7 36 / 0) !important;
}

.tw-caret-pink-950\/10 {
  caret-color: rgb(80 7 36 / 0.1) !important;
}

.tw-caret-pink-950\/100 {
  caret-color: rgb(80 7 36 / 1) !important;
}

.tw-caret-pink-950\/15 {
  caret-color: rgb(80 7 36 / 0.15) !important;
}

.tw-caret-pink-950\/20 {
  caret-color: rgb(80 7 36 / 0.2) !important;
}

.tw-caret-pink-950\/25 {
  caret-color: rgb(80 7 36 / 0.25) !important;
}

.tw-caret-pink-950\/30 {
  caret-color: rgb(80 7 36 / 0.3) !important;
}

.tw-caret-pink-950\/35 {
  caret-color: rgb(80 7 36 / 0.35) !important;
}

.tw-caret-pink-950\/40 {
  caret-color: rgb(80 7 36 / 0.4) !important;
}

.tw-caret-pink-950\/45 {
  caret-color: rgb(80 7 36 / 0.45) !important;
}

.tw-caret-pink-950\/5 {
  caret-color: rgb(80 7 36 / 0.05) !important;
}

.tw-caret-pink-950\/50 {
  caret-color: rgb(80 7 36 / 0.5) !important;
}

.tw-caret-pink-950\/55 {
  caret-color: rgb(80 7 36 / 0.55) !important;
}

.tw-caret-pink-950\/60 {
  caret-color: rgb(80 7 36 / 0.6) !important;
}

.tw-caret-pink-950\/65 {
  caret-color: rgb(80 7 36 / 0.65) !important;
}

.tw-caret-pink-950\/70 {
  caret-color: rgb(80 7 36 / 0.7) !important;
}

.tw-caret-pink-950\/75 {
  caret-color: rgb(80 7 36 / 0.75) !important;
}

.tw-caret-pink-950\/80 {
  caret-color: rgb(80 7 36 / 0.8) !important;
}

.tw-caret-pink-950\/85 {
  caret-color: rgb(80 7 36 / 0.85) !important;
}

.tw-caret-pink-950\/90 {
  caret-color: rgb(80 7 36 / 0.9) !important;
}

.tw-caret-pink-950\/95 {
  caret-color: rgb(80 7 36 / 0.95) !important;
}

.tw-caret-purple-100 {
  caret-color: #f3e8ff !important;
}

.tw-caret-purple-100\/0 {
  caret-color: rgb(243 232 255 / 0) !important;
}

.tw-caret-purple-100\/10 {
  caret-color: rgb(243 232 255 / 0.1) !important;
}

.tw-caret-purple-100\/100 {
  caret-color: rgb(243 232 255 / 1) !important;
}

.tw-caret-purple-100\/15 {
  caret-color: rgb(243 232 255 / 0.15) !important;
}

.tw-caret-purple-100\/20 {
  caret-color: rgb(243 232 255 / 0.2) !important;
}

.tw-caret-purple-100\/25 {
  caret-color: rgb(243 232 255 / 0.25) !important;
}

.tw-caret-purple-100\/30 {
  caret-color: rgb(243 232 255 / 0.3) !important;
}

.tw-caret-purple-100\/35 {
  caret-color: rgb(243 232 255 / 0.35) !important;
}

.tw-caret-purple-100\/40 {
  caret-color: rgb(243 232 255 / 0.4) !important;
}

.tw-caret-purple-100\/45 {
  caret-color: rgb(243 232 255 / 0.45) !important;
}

.tw-caret-purple-100\/5 {
  caret-color: rgb(243 232 255 / 0.05) !important;
}

.tw-caret-purple-100\/50 {
  caret-color: rgb(243 232 255 / 0.5) !important;
}

.tw-caret-purple-100\/55 {
  caret-color: rgb(243 232 255 / 0.55) !important;
}

.tw-caret-purple-100\/60 {
  caret-color: rgb(243 232 255 / 0.6) !important;
}

.tw-caret-purple-100\/65 {
  caret-color: rgb(243 232 255 / 0.65) !important;
}

.tw-caret-purple-100\/70 {
  caret-color: rgb(243 232 255 / 0.7) !important;
}

.tw-caret-purple-100\/75 {
  caret-color: rgb(243 232 255 / 0.75) !important;
}

.tw-caret-purple-100\/80 {
  caret-color: rgb(243 232 255 / 0.8) !important;
}

.tw-caret-purple-100\/85 {
  caret-color: rgb(243 232 255 / 0.85) !important;
}

.tw-caret-purple-100\/90 {
  caret-color: rgb(243 232 255 / 0.9) !important;
}

.tw-caret-purple-100\/95 {
  caret-color: rgb(243 232 255 / 0.95) !important;
}

.tw-caret-purple-200 {
  caret-color: #e9d5ff !important;
}

.tw-caret-purple-200\/0 {
  caret-color: rgb(233 213 255 / 0) !important;
}

.tw-caret-purple-200\/10 {
  caret-color: rgb(233 213 255 / 0.1) !important;
}

.tw-caret-purple-200\/100 {
  caret-color: rgb(233 213 255 / 1) !important;
}

.tw-caret-purple-200\/15 {
  caret-color: rgb(233 213 255 / 0.15) !important;
}

.tw-caret-purple-200\/20 {
  caret-color: rgb(233 213 255 / 0.2) !important;
}

.tw-caret-purple-200\/25 {
  caret-color: rgb(233 213 255 / 0.25) !important;
}

.tw-caret-purple-200\/30 {
  caret-color: rgb(233 213 255 / 0.3) !important;
}

.tw-caret-purple-200\/35 {
  caret-color: rgb(233 213 255 / 0.35) !important;
}

.tw-caret-purple-200\/40 {
  caret-color: rgb(233 213 255 / 0.4) !important;
}

.tw-caret-purple-200\/45 {
  caret-color: rgb(233 213 255 / 0.45) !important;
}

.tw-caret-purple-200\/5 {
  caret-color: rgb(233 213 255 / 0.05) !important;
}

.tw-caret-purple-200\/50 {
  caret-color: rgb(233 213 255 / 0.5) !important;
}

.tw-caret-purple-200\/55 {
  caret-color: rgb(233 213 255 / 0.55) !important;
}

.tw-caret-purple-200\/60 {
  caret-color: rgb(233 213 255 / 0.6) !important;
}

.tw-caret-purple-200\/65 {
  caret-color: rgb(233 213 255 / 0.65) !important;
}

.tw-caret-purple-200\/70 {
  caret-color: rgb(233 213 255 / 0.7) !important;
}

.tw-caret-purple-200\/75 {
  caret-color: rgb(233 213 255 / 0.75) !important;
}

.tw-caret-purple-200\/80 {
  caret-color: rgb(233 213 255 / 0.8) !important;
}

.tw-caret-purple-200\/85 {
  caret-color: rgb(233 213 255 / 0.85) !important;
}

.tw-caret-purple-200\/90 {
  caret-color: rgb(233 213 255 / 0.9) !important;
}

.tw-caret-purple-200\/95 {
  caret-color: rgb(233 213 255 / 0.95) !important;
}

.tw-caret-purple-300 {
  caret-color: #d8b4fe !important;
}

.tw-caret-purple-300\/0 {
  caret-color: rgb(216 180 254 / 0) !important;
}

.tw-caret-purple-300\/10 {
  caret-color: rgb(216 180 254 / 0.1) !important;
}

.tw-caret-purple-300\/100 {
  caret-color: rgb(216 180 254 / 1) !important;
}

.tw-caret-purple-300\/15 {
  caret-color: rgb(216 180 254 / 0.15) !important;
}

.tw-caret-purple-300\/20 {
  caret-color: rgb(216 180 254 / 0.2) !important;
}

.tw-caret-purple-300\/25 {
  caret-color: rgb(216 180 254 / 0.25) !important;
}

.tw-caret-purple-300\/30 {
  caret-color: rgb(216 180 254 / 0.3) !important;
}

.tw-caret-purple-300\/35 {
  caret-color: rgb(216 180 254 / 0.35) !important;
}

.tw-caret-purple-300\/40 {
  caret-color: rgb(216 180 254 / 0.4) !important;
}

.tw-caret-purple-300\/45 {
  caret-color: rgb(216 180 254 / 0.45) !important;
}

.tw-caret-purple-300\/5 {
  caret-color: rgb(216 180 254 / 0.05) !important;
}

.tw-caret-purple-300\/50 {
  caret-color: rgb(216 180 254 / 0.5) !important;
}

.tw-caret-purple-300\/55 {
  caret-color: rgb(216 180 254 / 0.55) !important;
}

.tw-caret-purple-300\/60 {
  caret-color: rgb(216 180 254 / 0.6) !important;
}

.tw-caret-purple-300\/65 {
  caret-color: rgb(216 180 254 / 0.65) !important;
}

.tw-caret-purple-300\/70 {
  caret-color: rgb(216 180 254 / 0.7) !important;
}

.tw-caret-purple-300\/75 {
  caret-color: rgb(216 180 254 / 0.75) !important;
}

.tw-caret-purple-300\/80 {
  caret-color: rgb(216 180 254 / 0.8) !important;
}

.tw-caret-purple-300\/85 {
  caret-color: rgb(216 180 254 / 0.85) !important;
}

.tw-caret-purple-300\/90 {
  caret-color: rgb(216 180 254 / 0.9) !important;
}

.tw-caret-purple-300\/95 {
  caret-color: rgb(216 180 254 / 0.95) !important;
}

.tw-caret-purple-400 {
  caret-color: #c084fc !important;
}

.tw-caret-purple-400\/0 {
  caret-color: rgb(192 132 252 / 0) !important;
}

.tw-caret-purple-400\/10 {
  caret-color: rgb(192 132 252 / 0.1) !important;
}

.tw-caret-purple-400\/100 {
  caret-color: rgb(192 132 252 / 1) !important;
}

.tw-caret-purple-400\/15 {
  caret-color: rgb(192 132 252 / 0.15) !important;
}

.tw-caret-purple-400\/20 {
  caret-color: rgb(192 132 252 / 0.2) !important;
}

.tw-caret-purple-400\/25 {
  caret-color: rgb(192 132 252 / 0.25) !important;
}

.tw-caret-purple-400\/30 {
  caret-color: rgb(192 132 252 / 0.3) !important;
}

.tw-caret-purple-400\/35 {
  caret-color: rgb(192 132 252 / 0.35) !important;
}

.tw-caret-purple-400\/40 {
  caret-color: rgb(192 132 252 / 0.4) !important;
}

.tw-caret-purple-400\/45 {
  caret-color: rgb(192 132 252 / 0.45) !important;
}

.tw-caret-purple-400\/5 {
  caret-color: rgb(192 132 252 / 0.05) !important;
}

.tw-caret-purple-400\/50 {
  caret-color: rgb(192 132 252 / 0.5) !important;
}

.tw-caret-purple-400\/55 {
  caret-color: rgb(192 132 252 / 0.55) !important;
}

.tw-caret-purple-400\/60 {
  caret-color: rgb(192 132 252 / 0.6) !important;
}

.tw-caret-purple-400\/65 {
  caret-color: rgb(192 132 252 / 0.65) !important;
}

.tw-caret-purple-400\/70 {
  caret-color: rgb(192 132 252 / 0.7) !important;
}

.tw-caret-purple-400\/75 {
  caret-color: rgb(192 132 252 / 0.75) !important;
}

.tw-caret-purple-400\/80 {
  caret-color: rgb(192 132 252 / 0.8) !important;
}

.tw-caret-purple-400\/85 {
  caret-color: rgb(192 132 252 / 0.85) !important;
}

.tw-caret-purple-400\/90 {
  caret-color: rgb(192 132 252 / 0.9) !important;
}

.tw-caret-purple-400\/95 {
  caret-color: rgb(192 132 252 / 0.95) !important;
}

.tw-caret-purple-50 {
  caret-color: #faf5ff !important;
}

.tw-caret-purple-50\/0 {
  caret-color: rgb(250 245 255 / 0) !important;
}

.tw-caret-purple-50\/10 {
  caret-color: rgb(250 245 255 / 0.1) !important;
}

.tw-caret-purple-50\/100 {
  caret-color: rgb(250 245 255 / 1) !important;
}

.tw-caret-purple-50\/15 {
  caret-color: rgb(250 245 255 / 0.15) !important;
}

.tw-caret-purple-50\/20 {
  caret-color: rgb(250 245 255 / 0.2) !important;
}

.tw-caret-purple-50\/25 {
  caret-color: rgb(250 245 255 / 0.25) !important;
}

.tw-caret-purple-50\/30 {
  caret-color: rgb(250 245 255 / 0.3) !important;
}

.tw-caret-purple-50\/35 {
  caret-color: rgb(250 245 255 / 0.35) !important;
}

.tw-caret-purple-50\/40 {
  caret-color: rgb(250 245 255 / 0.4) !important;
}

.tw-caret-purple-50\/45 {
  caret-color: rgb(250 245 255 / 0.45) !important;
}

.tw-caret-purple-50\/5 {
  caret-color: rgb(250 245 255 / 0.05) !important;
}

.tw-caret-purple-50\/50 {
  caret-color: rgb(250 245 255 / 0.5) !important;
}

.tw-caret-purple-50\/55 {
  caret-color: rgb(250 245 255 / 0.55) !important;
}

.tw-caret-purple-50\/60 {
  caret-color: rgb(250 245 255 / 0.6) !important;
}

.tw-caret-purple-50\/65 {
  caret-color: rgb(250 245 255 / 0.65) !important;
}

.tw-caret-purple-50\/70 {
  caret-color: rgb(250 245 255 / 0.7) !important;
}

.tw-caret-purple-50\/75 {
  caret-color: rgb(250 245 255 / 0.75) !important;
}

.tw-caret-purple-50\/80 {
  caret-color: rgb(250 245 255 / 0.8) !important;
}

.tw-caret-purple-50\/85 {
  caret-color: rgb(250 245 255 / 0.85) !important;
}

.tw-caret-purple-50\/90 {
  caret-color: rgb(250 245 255 / 0.9) !important;
}

.tw-caret-purple-50\/95 {
  caret-color: rgb(250 245 255 / 0.95) !important;
}

.tw-caret-purple-500 {
  caret-color: #a855f7 !important;
}

.tw-caret-purple-500\/0 {
  caret-color: rgb(168 85 247 / 0) !important;
}

.tw-caret-purple-500\/10 {
  caret-color: rgb(168 85 247 / 0.1) !important;
}

.tw-caret-purple-500\/100 {
  caret-color: rgb(168 85 247 / 1) !important;
}

.tw-caret-purple-500\/15 {
  caret-color: rgb(168 85 247 / 0.15) !important;
}

.tw-caret-purple-500\/20 {
  caret-color: rgb(168 85 247 / 0.2) !important;
}

.tw-caret-purple-500\/25 {
  caret-color: rgb(168 85 247 / 0.25) !important;
}

.tw-caret-purple-500\/30 {
  caret-color: rgb(168 85 247 / 0.3) !important;
}

.tw-caret-purple-500\/35 {
  caret-color: rgb(168 85 247 / 0.35) !important;
}

.tw-caret-purple-500\/40 {
  caret-color: rgb(168 85 247 / 0.4) !important;
}

.tw-caret-purple-500\/45 {
  caret-color: rgb(168 85 247 / 0.45) !important;
}

.tw-caret-purple-500\/5 {
  caret-color: rgb(168 85 247 / 0.05) !important;
}

.tw-caret-purple-500\/50 {
  caret-color: rgb(168 85 247 / 0.5) !important;
}

.tw-caret-purple-500\/55 {
  caret-color: rgb(168 85 247 / 0.55) !important;
}

.tw-caret-purple-500\/60 {
  caret-color: rgb(168 85 247 / 0.6) !important;
}

.tw-caret-purple-500\/65 {
  caret-color: rgb(168 85 247 / 0.65) !important;
}

.tw-caret-purple-500\/70 {
  caret-color: rgb(168 85 247 / 0.7) !important;
}

.tw-caret-purple-500\/75 {
  caret-color: rgb(168 85 247 / 0.75) !important;
}

.tw-caret-purple-500\/80 {
  caret-color: rgb(168 85 247 / 0.8) !important;
}

.tw-caret-purple-500\/85 {
  caret-color: rgb(168 85 247 / 0.85) !important;
}

.tw-caret-purple-500\/90 {
  caret-color: rgb(168 85 247 / 0.9) !important;
}

.tw-caret-purple-500\/95 {
  caret-color: rgb(168 85 247 / 0.95) !important;
}

.tw-caret-purple-600 {
  caret-color: #9333ea !important;
}

.tw-caret-purple-600\/0 {
  caret-color: rgb(147 51 234 / 0) !important;
}

.tw-caret-purple-600\/10 {
  caret-color: rgb(147 51 234 / 0.1) !important;
}

.tw-caret-purple-600\/100 {
  caret-color: rgb(147 51 234 / 1) !important;
}

.tw-caret-purple-600\/15 {
  caret-color: rgb(147 51 234 / 0.15) !important;
}

.tw-caret-purple-600\/20 {
  caret-color: rgb(147 51 234 / 0.2) !important;
}

.tw-caret-purple-600\/25 {
  caret-color: rgb(147 51 234 / 0.25) !important;
}

.tw-caret-purple-600\/30 {
  caret-color: rgb(147 51 234 / 0.3) !important;
}

.tw-caret-purple-600\/35 {
  caret-color: rgb(147 51 234 / 0.35) !important;
}

.tw-caret-purple-600\/40 {
  caret-color: rgb(147 51 234 / 0.4) !important;
}

.tw-caret-purple-600\/45 {
  caret-color: rgb(147 51 234 / 0.45) !important;
}

.tw-caret-purple-600\/5 {
  caret-color: rgb(147 51 234 / 0.05) !important;
}

.tw-caret-purple-600\/50 {
  caret-color: rgb(147 51 234 / 0.5) !important;
}

.tw-caret-purple-600\/55 {
  caret-color: rgb(147 51 234 / 0.55) !important;
}

.tw-caret-purple-600\/60 {
  caret-color: rgb(147 51 234 / 0.6) !important;
}

.tw-caret-purple-600\/65 {
  caret-color: rgb(147 51 234 / 0.65) !important;
}

.tw-caret-purple-600\/70 {
  caret-color: rgb(147 51 234 / 0.7) !important;
}

.tw-caret-purple-600\/75 {
  caret-color: rgb(147 51 234 / 0.75) !important;
}

.tw-caret-purple-600\/80 {
  caret-color: rgb(147 51 234 / 0.8) !important;
}

.tw-caret-purple-600\/85 {
  caret-color: rgb(147 51 234 / 0.85) !important;
}

.tw-caret-purple-600\/90 {
  caret-color: rgb(147 51 234 / 0.9) !important;
}

.tw-caret-purple-600\/95 {
  caret-color: rgb(147 51 234 / 0.95) !important;
}

.tw-caret-purple-700 {
  caret-color: #7e22ce !important;
}

.tw-caret-purple-700\/0 {
  caret-color: rgb(126 34 206 / 0) !important;
}

.tw-caret-purple-700\/10 {
  caret-color: rgb(126 34 206 / 0.1) !important;
}

.tw-caret-purple-700\/100 {
  caret-color: rgb(126 34 206 / 1) !important;
}

.tw-caret-purple-700\/15 {
  caret-color: rgb(126 34 206 / 0.15) !important;
}

.tw-caret-purple-700\/20 {
  caret-color: rgb(126 34 206 / 0.2) !important;
}

.tw-caret-purple-700\/25 {
  caret-color: rgb(126 34 206 / 0.25) !important;
}

.tw-caret-purple-700\/30 {
  caret-color: rgb(126 34 206 / 0.3) !important;
}

.tw-caret-purple-700\/35 {
  caret-color: rgb(126 34 206 / 0.35) !important;
}

.tw-caret-purple-700\/40 {
  caret-color: rgb(126 34 206 / 0.4) !important;
}

.tw-caret-purple-700\/45 {
  caret-color: rgb(126 34 206 / 0.45) !important;
}

.tw-caret-purple-700\/5 {
  caret-color: rgb(126 34 206 / 0.05) !important;
}

.tw-caret-purple-700\/50 {
  caret-color: rgb(126 34 206 / 0.5) !important;
}

.tw-caret-purple-700\/55 {
  caret-color: rgb(126 34 206 / 0.55) !important;
}

.tw-caret-purple-700\/60 {
  caret-color: rgb(126 34 206 / 0.6) !important;
}

.tw-caret-purple-700\/65 {
  caret-color: rgb(126 34 206 / 0.65) !important;
}

.tw-caret-purple-700\/70 {
  caret-color: rgb(126 34 206 / 0.7) !important;
}

.tw-caret-purple-700\/75 {
  caret-color: rgb(126 34 206 / 0.75) !important;
}

.tw-caret-purple-700\/80 {
  caret-color: rgb(126 34 206 / 0.8) !important;
}

.tw-caret-purple-700\/85 {
  caret-color: rgb(126 34 206 / 0.85) !important;
}

.tw-caret-purple-700\/90 {
  caret-color: rgb(126 34 206 / 0.9) !important;
}

.tw-caret-purple-700\/95 {
  caret-color: rgb(126 34 206 / 0.95) !important;
}

.tw-caret-purple-800 {
  caret-color: #6b21a8 !important;
}

.tw-caret-purple-800\/0 {
  caret-color: rgb(107 33 168 / 0) !important;
}

.tw-caret-purple-800\/10 {
  caret-color: rgb(107 33 168 / 0.1) !important;
}

.tw-caret-purple-800\/100 {
  caret-color: rgb(107 33 168 / 1) !important;
}

.tw-caret-purple-800\/15 {
  caret-color: rgb(107 33 168 / 0.15) !important;
}

.tw-caret-purple-800\/20 {
  caret-color: rgb(107 33 168 / 0.2) !important;
}

.tw-caret-purple-800\/25 {
  caret-color: rgb(107 33 168 / 0.25) !important;
}

.tw-caret-purple-800\/30 {
  caret-color: rgb(107 33 168 / 0.3) !important;
}

.tw-caret-purple-800\/35 {
  caret-color: rgb(107 33 168 / 0.35) !important;
}

.tw-caret-purple-800\/40 {
  caret-color: rgb(107 33 168 / 0.4) !important;
}

.tw-caret-purple-800\/45 {
  caret-color: rgb(107 33 168 / 0.45) !important;
}

.tw-caret-purple-800\/5 {
  caret-color: rgb(107 33 168 / 0.05) !important;
}

.tw-caret-purple-800\/50 {
  caret-color: rgb(107 33 168 / 0.5) !important;
}

.tw-caret-purple-800\/55 {
  caret-color: rgb(107 33 168 / 0.55) !important;
}

.tw-caret-purple-800\/60 {
  caret-color: rgb(107 33 168 / 0.6) !important;
}

.tw-caret-purple-800\/65 {
  caret-color: rgb(107 33 168 / 0.65) !important;
}

.tw-caret-purple-800\/70 {
  caret-color: rgb(107 33 168 / 0.7) !important;
}

.tw-caret-purple-800\/75 {
  caret-color: rgb(107 33 168 / 0.75) !important;
}

.tw-caret-purple-800\/80 {
  caret-color: rgb(107 33 168 / 0.8) !important;
}

.tw-caret-purple-800\/85 {
  caret-color: rgb(107 33 168 / 0.85) !important;
}

.tw-caret-purple-800\/90 {
  caret-color: rgb(107 33 168 / 0.9) !important;
}

.tw-caret-purple-800\/95 {
  caret-color: rgb(107 33 168 / 0.95) !important;
}

.tw-caret-purple-900 {
  caret-color: #581c87 !important;
}

.tw-caret-purple-900\/0 {
  caret-color: rgb(88 28 135 / 0) !important;
}

.tw-caret-purple-900\/10 {
  caret-color: rgb(88 28 135 / 0.1) !important;
}

.tw-caret-purple-900\/100 {
  caret-color: rgb(88 28 135 / 1) !important;
}

.tw-caret-purple-900\/15 {
  caret-color: rgb(88 28 135 / 0.15) !important;
}

.tw-caret-purple-900\/20 {
  caret-color: rgb(88 28 135 / 0.2) !important;
}

.tw-caret-purple-900\/25 {
  caret-color: rgb(88 28 135 / 0.25) !important;
}

.tw-caret-purple-900\/30 {
  caret-color: rgb(88 28 135 / 0.3) !important;
}

.tw-caret-purple-900\/35 {
  caret-color: rgb(88 28 135 / 0.35) !important;
}

.tw-caret-purple-900\/40 {
  caret-color: rgb(88 28 135 / 0.4) !important;
}

.tw-caret-purple-900\/45 {
  caret-color: rgb(88 28 135 / 0.45) !important;
}

.tw-caret-purple-900\/5 {
  caret-color: rgb(88 28 135 / 0.05) !important;
}

.tw-caret-purple-900\/50 {
  caret-color: rgb(88 28 135 / 0.5) !important;
}

.tw-caret-purple-900\/55 {
  caret-color: rgb(88 28 135 / 0.55) !important;
}

.tw-caret-purple-900\/60 {
  caret-color: rgb(88 28 135 / 0.6) !important;
}

.tw-caret-purple-900\/65 {
  caret-color: rgb(88 28 135 / 0.65) !important;
}

.tw-caret-purple-900\/70 {
  caret-color: rgb(88 28 135 / 0.7) !important;
}

.tw-caret-purple-900\/75 {
  caret-color: rgb(88 28 135 / 0.75) !important;
}

.tw-caret-purple-900\/80 {
  caret-color: rgb(88 28 135 / 0.8) !important;
}

.tw-caret-purple-900\/85 {
  caret-color: rgb(88 28 135 / 0.85) !important;
}

.tw-caret-purple-900\/90 {
  caret-color: rgb(88 28 135 / 0.9) !important;
}

.tw-caret-purple-900\/95 {
  caret-color: rgb(88 28 135 / 0.95) !important;
}

.tw-caret-purple-950 {
  caret-color: #3b0764 !important;
}

.tw-caret-purple-950\/0 {
  caret-color: rgb(59 7 100 / 0) !important;
}

.tw-caret-purple-950\/10 {
  caret-color: rgb(59 7 100 / 0.1) !important;
}

.tw-caret-purple-950\/100 {
  caret-color: rgb(59 7 100 / 1) !important;
}

.tw-caret-purple-950\/15 {
  caret-color: rgb(59 7 100 / 0.15) !important;
}

.tw-caret-purple-950\/20 {
  caret-color: rgb(59 7 100 / 0.2) !important;
}

.tw-caret-purple-950\/25 {
  caret-color: rgb(59 7 100 / 0.25) !important;
}

.tw-caret-purple-950\/30 {
  caret-color: rgb(59 7 100 / 0.3) !important;
}

.tw-caret-purple-950\/35 {
  caret-color: rgb(59 7 100 / 0.35) !important;
}

.tw-caret-purple-950\/40 {
  caret-color: rgb(59 7 100 / 0.4) !important;
}

.tw-caret-purple-950\/45 {
  caret-color: rgb(59 7 100 / 0.45) !important;
}

.tw-caret-purple-950\/5 {
  caret-color: rgb(59 7 100 / 0.05) !important;
}

.tw-caret-purple-950\/50 {
  caret-color: rgb(59 7 100 / 0.5) !important;
}

.tw-caret-purple-950\/55 {
  caret-color: rgb(59 7 100 / 0.55) !important;
}

.tw-caret-purple-950\/60 {
  caret-color: rgb(59 7 100 / 0.6) !important;
}

.tw-caret-purple-950\/65 {
  caret-color: rgb(59 7 100 / 0.65) !important;
}

.tw-caret-purple-950\/70 {
  caret-color: rgb(59 7 100 / 0.7) !important;
}

.tw-caret-purple-950\/75 {
  caret-color: rgb(59 7 100 / 0.75) !important;
}

.tw-caret-purple-950\/80 {
  caret-color: rgb(59 7 100 / 0.8) !important;
}

.tw-caret-purple-950\/85 {
  caret-color: rgb(59 7 100 / 0.85) !important;
}

.tw-caret-purple-950\/90 {
  caret-color: rgb(59 7 100 / 0.9) !important;
}

.tw-caret-purple-950\/95 {
  caret-color: rgb(59 7 100 / 0.95) !important;
}

.tw-caret-red-100 {
  caret-color: #fee2e2 !important;
}

.tw-caret-red-100\/0 {
  caret-color: rgb(254 226 226 / 0) !important;
}

.tw-caret-red-100\/10 {
  caret-color: rgb(254 226 226 / 0.1) !important;
}

.tw-caret-red-100\/100 {
  caret-color: rgb(254 226 226 / 1) !important;
}

.tw-caret-red-100\/15 {
  caret-color: rgb(254 226 226 / 0.15) !important;
}

.tw-caret-red-100\/20 {
  caret-color: rgb(254 226 226 / 0.2) !important;
}

.tw-caret-red-100\/25 {
  caret-color: rgb(254 226 226 / 0.25) !important;
}

.tw-caret-red-100\/30 {
  caret-color: rgb(254 226 226 / 0.3) !important;
}

.tw-caret-red-100\/35 {
  caret-color: rgb(254 226 226 / 0.35) !important;
}

.tw-caret-red-100\/40 {
  caret-color: rgb(254 226 226 / 0.4) !important;
}

.tw-caret-red-100\/45 {
  caret-color: rgb(254 226 226 / 0.45) !important;
}

.tw-caret-red-100\/5 {
  caret-color: rgb(254 226 226 / 0.05) !important;
}

.tw-caret-red-100\/50 {
  caret-color: rgb(254 226 226 / 0.5) !important;
}

.tw-caret-red-100\/55 {
  caret-color: rgb(254 226 226 / 0.55) !important;
}

.tw-caret-red-100\/60 {
  caret-color: rgb(254 226 226 / 0.6) !important;
}

.tw-caret-red-100\/65 {
  caret-color: rgb(254 226 226 / 0.65) !important;
}

.tw-caret-red-100\/70 {
  caret-color: rgb(254 226 226 / 0.7) !important;
}

.tw-caret-red-100\/75 {
  caret-color: rgb(254 226 226 / 0.75) !important;
}

.tw-caret-red-100\/80 {
  caret-color: rgb(254 226 226 / 0.8) !important;
}

.tw-caret-red-100\/85 {
  caret-color: rgb(254 226 226 / 0.85) !important;
}

.tw-caret-red-100\/90 {
  caret-color: rgb(254 226 226 / 0.9) !important;
}

.tw-caret-red-100\/95 {
  caret-color: rgb(254 226 226 / 0.95) !important;
}

.tw-caret-red-200 {
  caret-color: #fecaca !important;
}

.tw-caret-red-200\/0 {
  caret-color: rgb(254 202 202 / 0) !important;
}

.tw-caret-red-200\/10 {
  caret-color: rgb(254 202 202 / 0.1) !important;
}

.tw-caret-red-200\/100 {
  caret-color: rgb(254 202 202 / 1) !important;
}

.tw-caret-red-200\/15 {
  caret-color: rgb(254 202 202 / 0.15) !important;
}

.tw-caret-red-200\/20 {
  caret-color: rgb(254 202 202 / 0.2) !important;
}

.tw-caret-red-200\/25 {
  caret-color: rgb(254 202 202 / 0.25) !important;
}

.tw-caret-red-200\/30 {
  caret-color: rgb(254 202 202 / 0.3) !important;
}

.tw-caret-red-200\/35 {
  caret-color: rgb(254 202 202 / 0.35) !important;
}

.tw-caret-red-200\/40 {
  caret-color: rgb(254 202 202 / 0.4) !important;
}

.tw-caret-red-200\/45 {
  caret-color: rgb(254 202 202 / 0.45) !important;
}

.tw-caret-red-200\/5 {
  caret-color: rgb(254 202 202 / 0.05) !important;
}

.tw-caret-red-200\/50 {
  caret-color: rgb(254 202 202 / 0.5) !important;
}

.tw-caret-red-200\/55 {
  caret-color: rgb(254 202 202 / 0.55) !important;
}

.tw-caret-red-200\/60 {
  caret-color: rgb(254 202 202 / 0.6) !important;
}

.tw-caret-red-200\/65 {
  caret-color: rgb(254 202 202 / 0.65) !important;
}

.tw-caret-red-200\/70 {
  caret-color: rgb(254 202 202 / 0.7) !important;
}

.tw-caret-red-200\/75 {
  caret-color: rgb(254 202 202 / 0.75) !important;
}

.tw-caret-red-200\/80 {
  caret-color: rgb(254 202 202 / 0.8) !important;
}

.tw-caret-red-200\/85 {
  caret-color: rgb(254 202 202 / 0.85) !important;
}

.tw-caret-red-200\/90 {
  caret-color: rgb(254 202 202 / 0.9) !important;
}

.tw-caret-red-200\/95 {
  caret-color: rgb(254 202 202 / 0.95) !important;
}

.tw-caret-red-300 {
  caret-color: #fca5a5 !important;
}

.tw-caret-red-300\/0 {
  caret-color: rgb(252 165 165 / 0) !important;
}

.tw-caret-red-300\/10 {
  caret-color: rgb(252 165 165 / 0.1) !important;
}

.tw-caret-red-300\/100 {
  caret-color: rgb(252 165 165 / 1) !important;
}

.tw-caret-red-300\/15 {
  caret-color: rgb(252 165 165 / 0.15) !important;
}

.tw-caret-red-300\/20 {
  caret-color: rgb(252 165 165 / 0.2) !important;
}

.tw-caret-red-300\/25 {
  caret-color: rgb(252 165 165 / 0.25) !important;
}

.tw-caret-red-300\/30 {
  caret-color: rgb(252 165 165 / 0.3) !important;
}

.tw-caret-red-300\/35 {
  caret-color: rgb(252 165 165 / 0.35) !important;
}

.tw-caret-red-300\/40 {
  caret-color: rgb(252 165 165 / 0.4) !important;
}

.tw-caret-red-300\/45 {
  caret-color: rgb(252 165 165 / 0.45) !important;
}

.tw-caret-red-300\/5 {
  caret-color: rgb(252 165 165 / 0.05) !important;
}

.tw-caret-red-300\/50 {
  caret-color: rgb(252 165 165 / 0.5) !important;
}

.tw-caret-red-300\/55 {
  caret-color: rgb(252 165 165 / 0.55) !important;
}

.tw-caret-red-300\/60 {
  caret-color: rgb(252 165 165 / 0.6) !important;
}

.tw-caret-red-300\/65 {
  caret-color: rgb(252 165 165 / 0.65) !important;
}

.tw-caret-red-300\/70 {
  caret-color: rgb(252 165 165 / 0.7) !important;
}

.tw-caret-red-300\/75 {
  caret-color: rgb(252 165 165 / 0.75) !important;
}

.tw-caret-red-300\/80 {
  caret-color: rgb(252 165 165 / 0.8) !important;
}

.tw-caret-red-300\/85 {
  caret-color: rgb(252 165 165 / 0.85) !important;
}

.tw-caret-red-300\/90 {
  caret-color: rgb(252 165 165 / 0.9) !important;
}

.tw-caret-red-300\/95 {
  caret-color: rgb(252 165 165 / 0.95) !important;
}

.tw-caret-red-400 {
  caret-color: #f87171 !important;
}

.tw-caret-red-400\/0 {
  caret-color: rgb(248 113 113 / 0) !important;
}

.tw-caret-red-400\/10 {
  caret-color: rgb(248 113 113 / 0.1) !important;
}

.tw-caret-red-400\/100 {
  caret-color: rgb(248 113 113 / 1) !important;
}

.tw-caret-red-400\/15 {
  caret-color: rgb(248 113 113 / 0.15) !important;
}

.tw-caret-red-400\/20 {
  caret-color: rgb(248 113 113 / 0.2) !important;
}

.tw-caret-red-400\/25 {
  caret-color: rgb(248 113 113 / 0.25) !important;
}

.tw-caret-red-400\/30 {
  caret-color: rgb(248 113 113 / 0.3) !important;
}

.tw-caret-red-400\/35 {
  caret-color: rgb(248 113 113 / 0.35) !important;
}

.tw-caret-red-400\/40 {
  caret-color: rgb(248 113 113 / 0.4) !important;
}

.tw-caret-red-400\/45 {
  caret-color: rgb(248 113 113 / 0.45) !important;
}

.tw-caret-red-400\/5 {
  caret-color: rgb(248 113 113 / 0.05) !important;
}

.tw-caret-red-400\/50 {
  caret-color: rgb(248 113 113 / 0.5) !important;
}

.tw-caret-red-400\/55 {
  caret-color: rgb(248 113 113 / 0.55) !important;
}

.tw-caret-red-400\/60 {
  caret-color: rgb(248 113 113 / 0.6) !important;
}

.tw-caret-red-400\/65 {
  caret-color: rgb(248 113 113 / 0.65) !important;
}

.tw-caret-red-400\/70 {
  caret-color: rgb(248 113 113 / 0.7) !important;
}

.tw-caret-red-400\/75 {
  caret-color: rgb(248 113 113 / 0.75) !important;
}

.tw-caret-red-400\/80 {
  caret-color: rgb(248 113 113 / 0.8) !important;
}

.tw-caret-red-400\/85 {
  caret-color: rgb(248 113 113 / 0.85) !important;
}

.tw-caret-red-400\/90 {
  caret-color: rgb(248 113 113 / 0.9) !important;
}

.tw-caret-red-400\/95 {
  caret-color: rgb(248 113 113 / 0.95) !important;
}

.tw-caret-red-50 {
  caret-color: #fef2f2 !important;
}

.tw-caret-red-50\/0 {
  caret-color: rgb(254 242 242 / 0) !important;
}

.tw-caret-red-50\/10 {
  caret-color: rgb(254 242 242 / 0.1) !important;
}

.tw-caret-red-50\/100 {
  caret-color: rgb(254 242 242 / 1) !important;
}

.tw-caret-red-50\/15 {
  caret-color: rgb(254 242 242 / 0.15) !important;
}

.tw-caret-red-50\/20 {
  caret-color: rgb(254 242 242 / 0.2) !important;
}

.tw-caret-red-50\/25 {
  caret-color: rgb(254 242 242 / 0.25) !important;
}

.tw-caret-red-50\/30 {
  caret-color: rgb(254 242 242 / 0.3) !important;
}

.tw-caret-red-50\/35 {
  caret-color: rgb(254 242 242 / 0.35) !important;
}

.tw-caret-red-50\/40 {
  caret-color: rgb(254 242 242 / 0.4) !important;
}

.tw-caret-red-50\/45 {
  caret-color: rgb(254 242 242 / 0.45) !important;
}

.tw-caret-red-50\/5 {
  caret-color: rgb(254 242 242 / 0.05) !important;
}

.tw-caret-red-50\/50 {
  caret-color: rgb(254 242 242 / 0.5) !important;
}

.tw-caret-red-50\/55 {
  caret-color: rgb(254 242 242 / 0.55) !important;
}

.tw-caret-red-50\/60 {
  caret-color: rgb(254 242 242 / 0.6) !important;
}

.tw-caret-red-50\/65 {
  caret-color: rgb(254 242 242 / 0.65) !important;
}

.tw-caret-red-50\/70 {
  caret-color: rgb(254 242 242 / 0.7) !important;
}

.tw-caret-red-50\/75 {
  caret-color: rgb(254 242 242 / 0.75) !important;
}

.tw-caret-red-50\/80 {
  caret-color: rgb(254 242 242 / 0.8) !important;
}

.tw-caret-red-50\/85 {
  caret-color: rgb(254 242 242 / 0.85) !important;
}

.tw-caret-red-50\/90 {
  caret-color: rgb(254 242 242 / 0.9) !important;
}

.tw-caret-red-50\/95 {
  caret-color: rgb(254 242 242 / 0.95) !important;
}

.tw-caret-red-500 {
  caret-color: #ef4444 !important;
}

.tw-caret-red-500\/0 {
  caret-color: rgb(239 68 68 / 0) !important;
}

.tw-caret-red-500\/10 {
  caret-color: rgb(239 68 68 / 0.1) !important;
}

.tw-caret-red-500\/100 {
  caret-color: rgb(239 68 68 / 1) !important;
}

.tw-caret-red-500\/15 {
  caret-color: rgb(239 68 68 / 0.15) !important;
}

.tw-caret-red-500\/20 {
  caret-color: rgb(239 68 68 / 0.2) !important;
}

.tw-caret-red-500\/25 {
  caret-color: rgb(239 68 68 / 0.25) !important;
}

.tw-caret-red-500\/30 {
  caret-color: rgb(239 68 68 / 0.3) !important;
}

.tw-caret-red-500\/35 {
  caret-color: rgb(239 68 68 / 0.35) !important;
}

.tw-caret-red-500\/40 {
  caret-color: rgb(239 68 68 / 0.4) !important;
}

.tw-caret-red-500\/45 {
  caret-color: rgb(239 68 68 / 0.45) !important;
}

.tw-caret-red-500\/5 {
  caret-color: rgb(239 68 68 / 0.05) !important;
}

.tw-caret-red-500\/50 {
  caret-color: rgb(239 68 68 / 0.5) !important;
}

.tw-caret-red-500\/55 {
  caret-color: rgb(239 68 68 / 0.55) !important;
}

.tw-caret-red-500\/60 {
  caret-color: rgb(239 68 68 / 0.6) !important;
}

.tw-caret-red-500\/65 {
  caret-color: rgb(239 68 68 / 0.65) !important;
}

.tw-caret-red-500\/70 {
  caret-color: rgb(239 68 68 / 0.7) !important;
}

.tw-caret-red-500\/75 {
  caret-color: rgb(239 68 68 / 0.75) !important;
}

.tw-caret-red-500\/80 {
  caret-color: rgb(239 68 68 / 0.8) !important;
}

.tw-caret-red-500\/85 {
  caret-color: rgb(239 68 68 / 0.85) !important;
}

.tw-caret-red-500\/90 {
  caret-color: rgb(239 68 68 / 0.9) !important;
}

.tw-caret-red-500\/95 {
  caret-color: rgb(239 68 68 / 0.95) !important;
}

.tw-caret-red-600 {
  caret-color: #dc2626 !important;
}

.tw-caret-red-600\/0 {
  caret-color: rgb(220 38 38 / 0) !important;
}

.tw-caret-red-600\/10 {
  caret-color: rgb(220 38 38 / 0.1) !important;
}

.tw-caret-red-600\/100 {
  caret-color: rgb(220 38 38 / 1) !important;
}

.tw-caret-red-600\/15 {
  caret-color: rgb(220 38 38 / 0.15) !important;
}

.tw-caret-red-600\/20 {
  caret-color: rgb(220 38 38 / 0.2) !important;
}

.tw-caret-red-600\/25 {
  caret-color: rgb(220 38 38 / 0.25) !important;
}

.tw-caret-red-600\/30 {
  caret-color: rgb(220 38 38 / 0.3) !important;
}

.tw-caret-red-600\/35 {
  caret-color: rgb(220 38 38 / 0.35) !important;
}

.tw-caret-red-600\/40 {
  caret-color: rgb(220 38 38 / 0.4) !important;
}

.tw-caret-red-600\/45 {
  caret-color: rgb(220 38 38 / 0.45) !important;
}

.tw-caret-red-600\/5 {
  caret-color: rgb(220 38 38 / 0.05) !important;
}

.tw-caret-red-600\/50 {
  caret-color: rgb(220 38 38 / 0.5) !important;
}

.tw-caret-red-600\/55 {
  caret-color: rgb(220 38 38 / 0.55) !important;
}

.tw-caret-red-600\/60 {
  caret-color: rgb(220 38 38 / 0.6) !important;
}

.tw-caret-red-600\/65 {
  caret-color: rgb(220 38 38 / 0.65) !important;
}

.tw-caret-red-600\/70 {
  caret-color: rgb(220 38 38 / 0.7) !important;
}

.tw-caret-red-600\/75 {
  caret-color: rgb(220 38 38 / 0.75) !important;
}

.tw-caret-red-600\/80 {
  caret-color: rgb(220 38 38 / 0.8) !important;
}

.tw-caret-red-600\/85 {
  caret-color: rgb(220 38 38 / 0.85) !important;
}

.tw-caret-red-600\/90 {
  caret-color: rgb(220 38 38 / 0.9) !important;
}

.tw-caret-red-600\/95 {
  caret-color: rgb(220 38 38 / 0.95) !important;
}

.tw-caret-red-700 {
  caret-color: #b91c1c !important;
}

.tw-caret-red-700\/0 {
  caret-color: rgb(185 28 28 / 0) !important;
}

.tw-caret-red-700\/10 {
  caret-color: rgb(185 28 28 / 0.1) !important;
}

.tw-caret-red-700\/100 {
  caret-color: rgb(185 28 28 / 1) !important;
}

.tw-caret-red-700\/15 {
  caret-color: rgb(185 28 28 / 0.15) !important;
}

.tw-caret-red-700\/20 {
  caret-color: rgb(185 28 28 / 0.2) !important;
}

.tw-caret-red-700\/25 {
  caret-color: rgb(185 28 28 / 0.25) !important;
}

.tw-caret-red-700\/30 {
  caret-color: rgb(185 28 28 / 0.3) !important;
}

.tw-caret-red-700\/35 {
  caret-color: rgb(185 28 28 / 0.35) !important;
}

.tw-caret-red-700\/40 {
  caret-color: rgb(185 28 28 / 0.4) !important;
}

.tw-caret-red-700\/45 {
  caret-color: rgb(185 28 28 / 0.45) !important;
}

.tw-caret-red-700\/5 {
  caret-color: rgb(185 28 28 / 0.05) !important;
}

.tw-caret-red-700\/50 {
  caret-color: rgb(185 28 28 / 0.5) !important;
}

.tw-caret-red-700\/55 {
  caret-color: rgb(185 28 28 / 0.55) !important;
}

.tw-caret-red-700\/60 {
  caret-color: rgb(185 28 28 / 0.6) !important;
}

.tw-caret-red-700\/65 {
  caret-color: rgb(185 28 28 / 0.65) !important;
}

.tw-caret-red-700\/70 {
  caret-color: rgb(185 28 28 / 0.7) !important;
}

.tw-caret-red-700\/75 {
  caret-color: rgb(185 28 28 / 0.75) !important;
}

.tw-caret-red-700\/80 {
  caret-color: rgb(185 28 28 / 0.8) !important;
}

.tw-caret-red-700\/85 {
  caret-color: rgb(185 28 28 / 0.85) !important;
}

.tw-caret-red-700\/90 {
  caret-color: rgb(185 28 28 / 0.9) !important;
}

.tw-caret-red-700\/95 {
  caret-color: rgb(185 28 28 / 0.95) !important;
}

.tw-caret-red-800 {
  caret-color: #991b1b !important;
}

.tw-caret-red-800\/0 {
  caret-color: rgb(153 27 27 / 0) !important;
}

.tw-caret-red-800\/10 {
  caret-color: rgb(153 27 27 / 0.1) !important;
}

.tw-caret-red-800\/100 {
  caret-color: rgb(153 27 27 / 1) !important;
}

.tw-caret-red-800\/15 {
  caret-color: rgb(153 27 27 / 0.15) !important;
}

.tw-caret-red-800\/20 {
  caret-color: rgb(153 27 27 / 0.2) !important;
}

.tw-caret-red-800\/25 {
  caret-color: rgb(153 27 27 / 0.25) !important;
}

.tw-caret-red-800\/30 {
  caret-color: rgb(153 27 27 / 0.3) !important;
}

.tw-caret-red-800\/35 {
  caret-color: rgb(153 27 27 / 0.35) !important;
}

.tw-caret-red-800\/40 {
  caret-color: rgb(153 27 27 / 0.4) !important;
}

.tw-caret-red-800\/45 {
  caret-color: rgb(153 27 27 / 0.45) !important;
}

.tw-caret-red-800\/5 {
  caret-color: rgb(153 27 27 / 0.05) !important;
}

.tw-caret-red-800\/50 {
  caret-color: rgb(153 27 27 / 0.5) !important;
}

.tw-caret-red-800\/55 {
  caret-color: rgb(153 27 27 / 0.55) !important;
}

.tw-caret-red-800\/60 {
  caret-color: rgb(153 27 27 / 0.6) !important;
}

.tw-caret-red-800\/65 {
  caret-color: rgb(153 27 27 / 0.65) !important;
}

.tw-caret-red-800\/70 {
  caret-color: rgb(153 27 27 / 0.7) !important;
}

.tw-caret-red-800\/75 {
  caret-color: rgb(153 27 27 / 0.75) !important;
}

.tw-caret-red-800\/80 {
  caret-color: rgb(153 27 27 / 0.8) !important;
}

.tw-caret-red-800\/85 {
  caret-color: rgb(153 27 27 / 0.85) !important;
}

.tw-caret-red-800\/90 {
  caret-color: rgb(153 27 27 / 0.9) !important;
}

.tw-caret-red-800\/95 {
  caret-color: rgb(153 27 27 / 0.95) !important;
}

.tw-caret-red-900 {
  caret-color: #7f1d1d !important;
}

.tw-caret-red-900\/0 {
  caret-color: rgb(127 29 29 / 0) !important;
}

.tw-caret-red-900\/10 {
  caret-color: rgb(127 29 29 / 0.1) !important;
}

.tw-caret-red-900\/100 {
  caret-color: rgb(127 29 29 / 1) !important;
}

.tw-caret-red-900\/15 {
  caret-color: rgb(127 29 29 / 0.15) !important;
}

.tw-caret-red-900\/20 {
  caret-color: rgb(127 29 29 / 0.2) !important;
}

.tw-caret-red-900\/25 {
  caret-color: rgb(127 29 29 / 0.25) !important;
}

.tw-caret-red-900\/30 {
  caret-color: rgb(127 29 29 / 0.3) !important;
}

.tw-caret-red-900\/35 {
  caret-color: rgb(127 29 29 / 0.35) !important;
}

.tw-caret-red-900\/40 {
  caret-color: rgb(127 29 29 / 0.4) !important;
}

.tw-caret-red-900\/45 {
  caret-color: rgb(127 29 29 / 0.45) !important;
}

.tw-caret-red-900\/5 {
  caret-color: rgb(127 29 29 / 0.05) !important;
}

.tw-caret-red-900\/50 {
  caret-color: rgb(127 29 29 / 0.5) !important;
}

.tw-caret-red-900\/55 {
  caret-color: rgb(127 29 29 / 0.55) !important;
}

.tw-caret-red-900\/60 {
  caret-color: rgb(127 29 29 / 0.6) !important;
}

.tw-caret-red-900\/65 {
  caret-color: rgb(127 29 29 / 0.65) !important;
}

.tw-caret-red-900\/70 {
  caret-color: rgb(127 29 29 / 0.7) !important;
}

.tw-caret-red-900\/75 {
  caret-color: rgb(127 29 29 / 0.75) !important;
}

.tw-caret-red-900\/80 {
  caret-color: rgb(127 29 29 / 0.8) !important;
}

.tw-caret-red-900\/85 {
  caret-color: rgb(127 29 29 / 0.85) !important;
}

.tw-caret-red-900\/90 {
  caret-color: rgb(127 29 29 / 0.9) !important;
}

.tw-caret-red-900\/95 {
  caret-color: rgb(127 29 29 / 0.95) !important;
}

.tw-caret-red-950 {
  caret-color: #450a0a !important;
}

.tw-caret-red-950\/0 {
  caret-color: rgb(69 10 10 / 0) !important;
}

.tw-caret-red-950\/10 {
  caret-color: rgb(69 10 10 / 0.1) !important;
}

.tw-caret-red-950\/100 {
  caret-color: rgb(69 10 10 / 1) !important;
}

.tw-caret-red-950\/15 {
  caret-color: rgb(69 10 10 / 0.15) !important;
}

.tw-caret-red-950\/20 {
  caret-color: rgb(69 10 10 / 0.2) !important;
}

.tw-caret-red-950\/25 {
  caret-color: rgb(69 10 10 / 0.25) !important;
}

.tw-caret-red-950\/30 {
  caret-color: rgb(69 10 10 / 0.3) !important;
}

.tw-caret-red-950\/35 {
  caret-color: rgb(69 10 10 / 0.35) !important;
}

.tw-caret-red-950\/40 {
  caret-color: rgb(69 10 10 / 0.4) !important;
}

.tw-caret-red-950\/45 {
  caret-color: rgb(69 10 10 / 0.45) !important;
}

.tw-caret-red-950\/5 {
  caret-color: rgb(69 10 10 / 0.05) !important;
}

.tw-caret-red-950\/50 {
  caret-color: rgb(69 10 10 / 0.5) !important;
}

.tw-caret-red-950\/55 {
  caret-color: rgb(69 10 10 / 0.55) !important;
}

.tw-caret-red-950\/60 {
  caret-color: rgb(69 10 10 / 0.6) !important;
}

.tw-caret-red-950\/65 {
  caret-color: rgb(69 10 10 / 0.65) !important;
}

.tw-caret-red-950\/70 {
  caret-color: rgb(69 10 10 / 0.7) !important;
}

.tw-caret-red-950\/75 {
  caret-color: rgb(69 10 10 / 0.75) !important;
}

.tw-caret-red-950\/80 {
  caret-color: rgb(69 10 10 / 0.8) !important;
}

.tw-caret-red-950\/85 {
  caret-color: rgb(69 10 10 / 0.85) !important;
}

.tw-caret-red-950\/90 {
  caret-color: rgb(69 10 10 / 0.9) !important;
}

.tw-caret-red-950\/95 {
  caret-color: rgb(69 10 10 / 0.95) !important;
}

.tw-caret-rose-100 {
  caret-color: #ffe4e6 !important;
}

.tw-caret-rose-100\/0 {
  caret-color: rgb(255 228 230 / 0) !important;
}

.tw-caret-rose-100\/10 {
  caret-color: rgb(255 228 230 / 0.1) !important;
}

.tw-caret-rose-100\/100 {
  caret-color: rgb(255 228 230 / 1) !important;
}

.tw-caret-rose-100\/15 {
  caret-color: rgb(255 228 230 / 0.15) !important;
}

.tw-caret-rose-100\/20 {
  caret-color: rgb(255 228 230 / 0.2) !important;
}

.tw-caret-rose-100\/25 {
  caret-color: rgb(255 228 230 / 0.25) !important;
}

.tw-caret-rose-100\/30 {
  caret-color: rgb(255 228 230 / 0.3) !important;
}

.tw-caret-rose-100\/35 {
  caret-color: rgb(255 228 230 / 0.35) !important;
}

.tw-caret-rose-100\/40 {
  caret-color: rgb(255 228 230 / 0.4) !important;
}

.tw-caret-rose-100\/45 {
  caret-color: rgb(255 228 230 / 0.45) !important;
}

.tw-caret-rose-100\/5 {
  caret-color: rgb(255 228 230 / 0.05) !important;
}

.tw-caret-rose-100\/50 {
  caret-color: rgb(255 228 230 / 0.5) !important;
}

.tw-caret-rose-100\/55 {
  caret-color: rgb(255 228 230 / 0.55) !important;
}

.tw-caret-rose-100\/60 {
  caret-color: rgb(255 228 230 / 0.6) !important;
}

.tw-caret-rose-100\/65 {
  caret-color: rgb(255 228 230 / 0.65) !important;
}

.tw-caret-rose-100\/70 {
  caret-color: rgb(255 228 230 / 0.7) !important;
}

.tw-caret-rose-100\/75 {
  caret-color: rgb(255 228 230 / 0.75) !important;
}

.tw-caret-rose-100\/80 {
  caret-color: rgb(255 228 230 / 0.8) !important;
}

.tw-caret-rose-100\/85 {
  caret-color: rgb(255 228 230 / 0.85) !important;
}

.tw-caret-rose-100\/90 {
  caret-color: rgb(255 228 230 / 0.9) !important;
}

.tw-caret-rose-100\/95 {
  caret-color: rgb(255 228 230 / 0.95) !important;
}

.tw-caret-rose-200 {
  caret-color: #fecdd3 !important;
}

.tw-caret-rose-200\/0 {
  caret-color: rgb(254 205 211 / 0) !important;
}

.tw-caret-rose-200\/10 {
  caret-color: rgb(254 205 211 / 0.1) !important;
}

.tw-caret-rose-200\/100 {
  caret-color: rgb(254 205 211 / 1) !important;
}

.tw-caret-rose-200\/15 {
  caret-color: rgb(254 205 211 / 0.15) !important;
}

.tw-caret-rose-200\/20 {
  caret-color: rgb(254 205 211 / 0.2) !important;
}

.tw-caret-rose-200\/25 {
  caret-color: rgb(254 205 211 / 0.25) !important;
}

.tw-caret-rose-200\/30 {
  caret-color: rgb(254 205 211 / 0.3) !important;
}

.tw-caret-rose-200\/35 {
  caret-color: rgb(254 205 211 / 0.35) !important;
}

.tw-caret-rose-200\/40 {
  caret-color: rgb(254 205 211 / 0.4) !important;
}

.tw-caret-rose-200\/45 {
  caret-color: rgb(254 205 211 / 0.45) !important;
}

.tw-caret-rose-200\/5 {
  caret-color: rgb(254 205 211 / 0.05) !important;
}

.tw-caret-rose-200\/50 {
  caret-color: rgb(254 205 211 / 0.5) !important;
}

.tw-caret-rose-200\/55 {
  caret-color: rgb(254 205 211 / 0.55) !important;
}

.tw-caret-rose-200\/60 {
  caret-color: rgb(254 205 211 / 0.6) !important;
}

.tw-caret-rose-200\/65 {
  caret-color: rgb(254 205 211 / 0.65) !important;
}

.tw-caret-rose-200\/70 {
  caret-color: rgb(254 205 211 / 0.7) !important;
}

.tw-caret-rose-200\/75 {
  caret-color: rgb(254 205 211 / 0.75) !important;
}

.tw-caret-rose-200\/80 {
  caret-color: rgb(254 205 211 / 0.8) !important;
}

.tw-caret-rose-200\/85 {
  caret-color: rgb(254 205 211 / 0.85) !important;
}

.tw-caret-rose-200\/90 {
  caret-color: rgb(254 205 211 / 0.9) !important;
}

.tw-caret-rose-200\/95 {
  caret-color: rgb(254 205 211 / 0.95) !important;
}

.tw-caret-rose-300 {
  caret-color: #fda4af !important;
}

.tw-caret-rose-300\/0 {
  caret-color: rgb(253 164 175 / 0) !important;
}

.tw-caret-rose-300\/10 {
  caret-color: rgb(253 164 175 / 0.1) !important;
}

.tw-caret-rose-300\/100 {
  caret-color: rgb(253 164 175 / 1) !important;
}

.tw-caret-rose-300\/15 {
  caret-color: rgb(253 164 175 / 0.15) !important;
}

.tw-caret-rose-300\/20 {
  caret-color: rgb(253 164 175 / 0.2) !important;
}

.tw-caret-rose-300\/25 {
  caret-color: rgb(253 164 175 / 0.25) !important;
}

.tw-caret-rose-300\/30 {
  caret-color: rgb(253 164 175 / 0.3) !important;
}

.tw-caret-rose-300\/35 {
  caret-color: rgb(253 164 175 / 0.35) !important;
}

.tw-caret-rose-300\/40 {
  caret-color: rgb(253 164 175 / 0.4) !important;
}

.tw-caret-rose-300\/45 {
  caret-color: rgb(253 164 175 / 0.45) !important;
}

.tw-caret-rose-300\/5 {
  caret-color: rgb(253 164 175 / 0.05) !important;
}

.tw-caret-rose-300\/50 {
  caret-color: rgb(253 164 175 / 0.5) !important;
}

.tw-caret-rose-300\/55 {
  caret-color: rgb(253 164 175 / 0.55) !important;
}

.tw-caret-rose-300\/60 {
  caret-color: rgb(253 164 175 / 0.6) !important;
}

.tw-caret-rose-300\/65 {
  caret-color: rgb(253 164 175 / 0.65) !important;
}

.tw-caret-rose-300\/70 {
  caret-color: rgb(253 164 175 / 0.7) !important;
}

.tw-caret-rose-300\/75 {
  caret-color: rgb(253 164 175 / 0.75) !important;
}

.tw-caret-rose-300\/80 {
  caret-color: rgb(253 164 175 / 0.8) !important;
}

.tw-caret-rose-300\/85 {
  caret-color: rgb(253 164 175 / 0.85) !important;
}

.tw-caret-rose-300\/90 {
  caret-color: rgb(253 164 175 / 0.9) !important;
}

.tw-caret-rose-300\/95 {
  caret-color: rgb(253 164 175 / 0.95) !important;
}

.tw-caret-rose-400 {
  caret-color: #fb7185 !important;
}

.tw-caret-rose-400\/0 {
  caret-color: rgb(251 113 133 / 0) !important;
}

.tw-caret-rose-400\/10 {
  caret-color: rgb(251 113 133 / 0.1) !important;
}

.tw-caret-rose-400\/100 {
  caret-color: rgb(251 113 133 / 1) !important;
}

.tw-caret-rose-400\/15 {
  caret-color: rgb(251 113 133 / 0.15) !important;
}

.tw-caret-rose-400\/20 {
  caret-color: rgb(251 113 133 / 0.2) !important;
}

.tw-caret-rose-400\/25 {
  caret-color: rgb(251 113 133 / 0.25) !important;
}

.tw-caret-rose-400\/30 {
  caret-color: rgb(251 113 133 / 0.3) !important;
}

.tw-caret-rose-400\/35 {
  caret-color: rgb(251 113 133 / 0.35) !important;
}

.tw-caret-rose-400\/40 {
  caret-color: rgb(251 113 133 / 0.4) !important;
}

.tw-caret-rose-400\/45 {
  caret-color: rgb(251 113 133 / 0.45) !important;
}

.tw-caret-rose-400\/5 {
  caret-color: rgb(251 113 133 / 0.05) !important;
}

.tw-caret-rose-400\/50 {
  caret-color: rgb(251 113 133 / 0.5) !important;
}

.tw-caret-rose-400\/55 {
  caret-color: rgb(251 113 133 / 0.55) !important;
}

.tw-caret-rose-400\/60 {
  caret-color: rgb(251 113 133 / 0.6) !important;
}

.tw-caret-rose-400\/65 {
  caret-color: rgb(251 113 133 / 0.65) !important;
}

.tw-caret-rose-400\/70 {
  caret-color: rgb(251 113 133 / 0.7) !important;
}

.tw-caret-rose-400\/75 {
  caret-color: rgb(251 113 133 / 0.75) !important;
}

.tw-caret-rose-400\/80 {
  caret-color: rgb(251 113 133 / 0.8) !important;
}

.tw-caret-rose-400\/85 {
  caret-color: rgb(251 113 133 / 0.85) !important;
}

.tw-caret-rose-400\/90 {
  caret-color: rgb(251 113 133 / 0.9) !important;
}

.tw-caret-rose-400\/95 {
  caret-color: rgb(251 113 133 / 0.95) !important;
}

.tw-caret-rose-50 {
  caret-color: #fff1f2 !important;
}

.tw-caret-rose-50\/0 {
  caret-color: rgb(255 241 242 / 0) !important;
}

.tw-caret-rose-50\/10 {
  caret-color: rgb(255 241 242 / 0.1) !important;
}

.tw-caret-rose-50\/100 {
  caret-color: rgb(255 241 242 / 1) !important;
}

.tw-caret-rose-50\/15 {
  caret-color: rgb(255 241 242 / 0.15) !important;
}

.tw-caret-rose-50\/20 {
  caret-color: rgb(255 241 242 / 0.2) !important;
}

.tw-caret-rose-50\/25 {
  caret-color: rgb(255 241 242 / 0.25) !important;
}

.tw-caret-rose-50\/30 {
  caret-color: rgb(255 241 242 / 0.3) !important;
}

.tw-caret-rose-50\/35 {
  caret-color: rgb(255 241 242 / 0.35) !important;
}

.tw-caret-rose-50\/40 {
  caret-color: rgb(255 241 242 / 0.4) !important;
}

.tw-caret-rose-50\/45 {
  caret-color: rgb(255 241 242 / 0.45) !important;
}

.tw-caret-rose-50\/5 {
  caret-color: rgb(255 241 242 / 0.05) !important;
}

.tw-caret-rose-50\/50 {
  caret-color: rgb(255 241 242 / 0.5) !important;
}

.tw-caret-rose-50\/55 {
  caret-color: rgb(255 241 242 / 0.55) !important;
}

.tw-caret-rose-50\/60 {
  caret-color: rgb(255 241 242 / 0.6) !important;
}

.tw-caret-rose-50\/65 {
  caret-color: rgb(255 241 242 / 0.65) !important;
}

.tw-caret-rose-50\/70 {
  caret-color: rgb(255 241 242 / 0.7) !important;
}

.tw-caret-rose-50\/75 {
  caret-color: rgb(255 241 242 / 0.75) !important;
}

.tw-caret-rose-50\/80 {
  caret-color: rgb(255 241 242 / 0.8) !important;
}

.tw-caret-rose-50\/85 {
  caret-color: rgb(255 241 242 / 0.85) !important;
}

.tw-caret-rose-50\/90 {
  caret-color: rgb(255 241 242 / 0.9) !important;
}

.tw-caret-rose-50\/95 {
  caret-color: rgb(255 241 242 / 0.95) !important;
}

.tw-caret-rose-500 {
  caret-color: #f43f5e !important;
}

.tw-caret-rose-500\/0 {
  caret-color: rgb(244 63 94 / 0) !important;
}

.tw-caret-rose-500\/10 {
  caret-color: rgb(244 63 94 / 0.1) !important;
}

.tw-caret-rose-500\/100 {
  caret-color: rgb(244 63 94 / 1) !important;
}

.tw-caret-rose-500\/15 {
  caret-color: rgb(244 63 94 / 0.15) !important;
}

.tw-caret-rose-500\/20 {
  caret-color: rgb(244 63 94 / 0.2) !important;
}

.tw-caret-rose-500\/25 {
  caret-color: rgb(244 63 94 / 0.25) !important;
}

.tw-caret-rose-500\/30 {
  caret-color: rgb(244 63 94 / 0.3) !important;
}

.tw-caret-rose-500\/35 {
  caret-color: rgb(244 63 94 / 0.35) !important;
}

.tw-caret-rose-500\/40 {
  caret-color: rgb(244 63 94 / 0.4) !important;
}

.tw-caret-rose-500\/45 {
  caret-color: rgb(244 63 94 / 0.45) !important;
}

.tw-caret-rose-500\/5 {
  caret-color: rgb(244 63 94 / 0.05) !important;
}

.tw-caret-rose-500\/50 {
  caret-color: rgb(244 63 94 / 0.5) !important;
}

.tw-caret-rose-500\/55 {
  caret-color: rgb(244 63 94 / 0.55) !important;
}

.tw-caret-rose-500\/60 {
  caret-color: rgb(244 63 94 / 0.6) !important;
}

.tw-caret-rose-500\/65 {
  caret-color: rgb(244 63 94 / 0.65) !important;
}

.tw-caret-rose-500\/70 {
  caret-color: rgb(244 63 94 / 0.7) !important;
}

.tw-caret-rose-500\/75 {
  caret-color: rgb(244 63 94 / 0.75) !important;
}

.tw-caret-rose-500\/80 {
  caret-color: rgb(244 63 94 / 0.8) !important;
}

.tw-caret-rose-500\/85 {
  caret-color: rgb(244 63 94 / 0.85) !important;
}

.tw-caret-rose-500\/90 {
  caret-color: rgb(244 63 94 / 0.9) !important;
}

.tw-caret-rose-500\/95 {
  caret-color: rgb(244 63 94 / 0.95) !important;
}

.tw-caret-rose-600 {
  caret-color: #e11d48 !important;
}

.tw-caret-rose-600\/0 {
  caret-color: rgb(225 29 72 / 0) !important;
}

.tw-caret-rose-600\/10 {
  caret-color: rgb(225 29 72 / 0.1) !important;
}

.tw-caret-rose-600\/100 {
  caret-color: rgb(225 29 72 / 1) !important;
}

.tw-caret-rose-600\/15 {
  caret-color: rgb(225 29 72 / 0.15) !important;
}

.tw-caret-rose-600\/20 {
  caret-color: rgb(225 29 72 / 0.2) !important;
}

.tw-caret-rose-600\/25 {
  caret-color: rgb(225 29 72 / 0.25) !important;
}

.tw-caret-rose-600\/30 {
  caret-color: rgb(225 29 72 / 0.3) !important;
}

.tw-caret-rose-600\/35 {
  caret-color: rgb(225 29 72 / 0.35) !important;
}

.tw-caret-rose-600\/40 {
  caret-color: rgb(225 29 72 / 0.4) !important;
}

.tw-caret-rose-600\/45 {
  caret-color: rgb(225 29 72 / 0.45) !important;
}

.tw-caret-rose-600\/5 {
  caret-color: rgb(225 29 72 / 0.05) !important;
}

.tw-caret-rose-600\/50 {
  caret-color: rgb(225 29 72 / 0.5) !important;
}

.tw-caret-rose-600\/55 {
  caret-color: rgb(225 29 72 / 0.55) !important;
}

.tw-caret-rose-600\/60 {
  caret-color: rgb(225 29 72 / 0.6) !important;
}

.tw-caret-rose-600\/65 {
  caret-color: rgb(225 29 72 / 0.65) !important;
}

.tw-caret-rose-600\/70 {
  caret-color: rgb(225 29 72 / 0.7) !important;
}

.tw-caret-rose-600\/75 {
  caret-color: rgb(225 29 72 / 0.75) !important;
}

.tw-caret-rose-600\/80 {
  caret-color: rgb(225 29 72 / 0.8) !important;
}

.tw-caret-rose-600\/85 {
  caret-color: rgb(225 29 72 / 0.85) !important;
}

.tw-caret-rose-600\/90 {
  caret-color: rgb(225 29 72 / 0.9) !important;
}

.tw-caret-rose-600\/95 {
  caret-color: rgb(225 29 72 / 0.95) !important;
}

.tw-caret-rose-700 {
  caret-color: #be123c !important;
}

.tw-caret-rose-700\/0 {
  caret-color: rgb(190 18 60 / 0) !important;
}

.tw-caret-rose-700\/10 {
  caret-color: rgb(190 18 60 / 0.1) !important;
}

.tw-caret-rose-700\/100 {
  caret-color: rgb(190 18 60 / 1) !important;
}

.tw-caret-rose-700\/15 {
  caret-color: rgb(190 18 60 / 0.15) !important;
}

.tw-caret-rose-700\/20 {
  caret-color: rgb(190 18 60 / 0.2) !important;
}

.tw-caret-rose-700\/25 {
  caret-color: rgb(190 18 60 / 0.25) !important;
}

.tw-caret-rose-700\/30 {
  caret-color: rgb(190 18 60 / 0.3) !important;
}

.tw-caret-rose-700\/35 {
  caret-color: rgb(190 18 60 / 0.35) !important;
}

.tw-caret-rose-700\/40 {
  caret-color: rgb(190 18 60 / 0.4) !important;
}

.tw-caret-rose-700\/45 {
  caret-color: rgb(190 18 60 / 0.45) !important;
}

.tw-caret-rose-700\/5 {
  caret-color: rgb(190 18 60 / 0.05) !important;
}

.tw-caret-rose-700\/50 {
  caret-color: rgb(190 18 60 / 0.5) !important;
}

.tw-caret-rose-700\/55 {
  caret-color: rgb(190 18 60 / 0.55) !important;
}

.tw-caret-rose-700\/60 {
  caret-color: rgb(190 18 60 / 0.6) !important;
}

.tw-caret-rose-700\/65 {
  caret-color: rgb(190 18 60 / 0.65) !important;
}

.tw-caret-rose-700\/70 {
  caret-color: rgb(190 18 60 / 0.7) !important;
}

.tw-caret-rose-700\/75 {
  caret-color: rgb(190 18 60 / 0.75) !important;
}

.tw-caret-rose-700\/80 {
  caret-color: rgb(190 18 60 / 0.8) !important;
}

.tw-caret-rose-700\/85 {
  caret-color: rgb(190 18 60 / 0.85) !important;
}

.tw-caret-rose-700\/90 {
  caret-color: rgb(190 18 60 / 0.9) !important;
}

.tw-caret-rose-700\/95 {
  caret-color: rgb(190 18 60 / 0.95) !important;
}

.tw-caret-rose-800 {
  caret-color: #9f1239 !important;
}

.tw-caret-rose-800\/0 {
  caret-color: rgb(159 18 57 / 0) !important;
}

.tw-caret-rose-800\/10 {
  caret-color: rgb(159 18 57 / 0.1) !important;
}

.tw-caret-rose-800\/100 {
  caret-color: rgb(159 18 57 / 1) !important;
}

.tw-caret-rose-800\/15 {
  caret-color: rgb(159 18 57 / 0.15) !important;
}

.tw-caret-rose-800\/20 {
  caret-color: rgb(159 18 57 / 0.2) !important;
}

.tw-caret-rose-800\/25 {
  caret-color: rgb(159 18 57 / 0.25) !important;
}

.tw-caret-rose-800\/30 {
  caret-color: rgb(159 18 57 / 0.3) !important;
}

.tw-caret-rose-800\/35 {
  caret-color: rgb(159 18 57 / 0.35) !important;
}

.tw-caret-rose-800\/40 {
  caret-color: rgb(159 18 57 / 0.4) !important;
}

.tw-caret-rose-800\/45 {
  caret-color: rgb(159 18 57 / 0.45) !important;
}

.tw-caret-rose-800\/5 {
  caret-color: rgb(159 18 57 / 0.05) !important;
}

.tw-caret-rose-800\/50 {
  caret-color: rgb(159 18 57 / 0.5) !important;
}

.tw-caret-rose-800\/55 {
  caret-color: rgb(159 18 57 / 0.55) !important;
}

.tw-caret-rose-800\/60 {
  caret-color: rgb(159 18 57 / 0.6) !important;
}

.tw-caret-rose-800\/65 {
  caret-color: rgb(159 18 57 / 0.65) !important;
}

.tw-caret-rose-800\/70 {
  caret-color: rgb(159 18 57 / 0.7) !important;
}

.tw-caret-rose-800\/75 {
  caret-color: rgb(159 18 57 / 0.75) !important;
}

.tw-caret-rose-800\/80 {
  caret-color: rgb(159 18 57 / 0.8) !important;
}

.tw-caret-rose-800\/85 {
  caret-color: rgb(159 18 57 / 0.85) !important;
}

.tw-caret-rose-800\/90 {
  caret-color: rgb(159 18 57 / 0.9) !important;
}

.tw-caret-rose-800\/95 {
  caret-color: rgb(159 18 57 / 0.95) !important;
}

.tw-caret-rose-900 {
  caret-color: #881337 !important;
}

.tw-caret-rose-900\/0 {
  caret-color: rgb(136 19 55 / 0) !important;
}

.tw-caret-rose-900\/10 {
  caret-color: rgb(136 19 55 / 0.1) !important;
}

.tw-caret-rose-900\/100 {
  caret-color: rgb(136 19 55 / 1) !important;
}

.tw-caret-rose-900\/15 {
  caret-color: rgb(136 19 55 / 0.15) !important;
}

.tw-caret-rose-900\/20 {
  caret-color: rgb(136 19 55 / 0.2) !important;
}

.tw-caret-rose-900\/25 {
  caret-color: rgb(136 19 55 / 0.25) !important;
}

.tw-caret-rose-900\/30 {
  caret-color: rgb(136 19 55 / 0.3) !important;
}

.tw-caret-rose-900\/35 {
  caret-color: rgb(136 19 55 / 0.35) !important;
}

.tw-caret-rose-900\/40 {
  caret-color: rgb(136 19 55 / 0.4) !important;
}

.tw-caret-rose-900\/45 {
  caret-color: rgb(136 19 55 / 0.45) !important;
}

.tw-caret-rose-900\/5 {
  caret-color: rgb(136 19 55 / 0.05) !important;
}

.tw-caret-rose-900\/50 {
  caret-color: rgb(136 19 55 / 0.5) !important;
}

.tw-caret-rose-900\/55 {
  caret-color: rgb(136 19 55 / 0.55) !important;
}

.tw-caret-rose-900\/60 {
  caret-color: rgb(136 19 55 / 0.6) !important;
}

.tw-caret-rose-900\/65 {
  caret-color: rgb(136 19 55 / 0.65) !important;
}

.tw-caret-rose-900\/70 {
  caret-color: rgb(136 19 55 / 0.7) !important;
}

.tw-caret-rose-900\/75 {
  caret-color: rgb(136 19 55 / 0.75) !important;
}

.tw-caret-rose-900\/80 {
  caret-color: rgb(136 19 55 / 0.8) !important;
}

.tw-caret-rose-900\/85 {
  caret-color: rgb(136 19 55 / 0.85) !important;
}

.tw-caret-rose-900\/90 {
  caret-color: rgb(136 19 55 / 0.9) !important;
}

.tw-caret-rose-900\/95 {
  caret-color: rgb(136 19 55 / 0.95) !important;
}

.tw-caret-rose-950 {
  caret-color: #4c0519 !important;
}

.tw-caret-rose-950\/0 {
  caret-color: rgb(76 5 25 / 0) !important;
}

.tw-caret-rose-950\/10 {
  caret-color: rgb(76 5 25 / 0.1) !important;
}

.tw-caret-rose-950\/100 {
  caret-color: rgb(76 5 25 / 1) !important;
}

.tw-caret-rose-950\/15 {
  caret-color: rgb(76 5 25 / 0.15) !important;
}

.tw-caret-rose-950\/20 {
  caret-color: rgb(76 5 25 / 0.2) !important;
}

.tw-caret-rose-950\/25 {
  caret-color: rgb(76 5 25 / 0.25) !important;
}

.tw-caret-rose-950\/30 {
  caret-color: rgb(76 5 25 / 0.3) !important;
}

.tw-caret-rose-950\/35 {
  caret-color: rgb(76 5 25 / 0.35) !important;
}

.tw-caret-rose-950\/40 {
  caret-color: rgb(76 5 25 / 0.4) !important;
}

.tw-caret-rose-950\/45 {
  caret-color: rgb(76 5 25 / 0.45) !important;
}

.tw-caret-rose-950\/5 {
  caret-color: rgb(76 5 25 / 0.05) !important;
}

.tw-caret-rose-950\/50 {
  caret-color: rgb(76 5 25 / 0.5) !important;
}

.tw-caret-rose-950\/55 {
  caret-color: rgb(76 5 25 / 0.55) !important;
}

.tw-caret-rose-950\/60 {
  caret-color: rgb(76 5 25 / 0.6) !important;
}

.tw-caret-rose-950\/65 {
  caret-color: rgb(76 5 25 / 0.65) !important;
}

.tw-caret-rose-950\/70 {
  caret-color: rgb(76 5 25 / 0.7) !important;
}

.tw-caret-rose-950\/75 {
  caret-color: rgb(76 5 25 / 0.75) !important;
}

.tw-caret-rose-950\/80 {
  caret-color: rgb(76 5 25 / 0.8) !important;
}

.tw-caret-rose-950\/85 {
  caret-color: rgb(76 5 25 / 0.85) !important;
}

.tw-caret-rose-950\/90 {
  caret-color: rgb(76 5 25 / 0.9) !important;
}

.tw-caret-rose-950\/95 {
  caret-color: rgb(76 5 25 / 0.95) !important;
}

.tw-caret-sky-100 {
  caret-color: #e0f2fe !important;
}

.tw-caret-sky-100\/0 {
  caret-color: rgb(224 242 254 / 0) !important;
}

.tw-caret-sky-100\/10 {
  caret-color: rgb(224 242 254 / 0.1) !important;
}

.tw-caret-sky-100\/100 {
  caret-color: rgb(224 242 254 / 1) !important;
}

.tw-caret-sky-100\/15 {
  caret-color: rgb(224 242 254 / 0.15) !important;
}

.tw-caret-sky-100\/20 {
  caret-color: rgb(224 242 254 / 0.2) !important;
}

.tw-caret-sky-100\/25 {
  caret-color: rgb(224 242 254 / 0.25) !important;
}

.tw-caret-sky-100\/30 {
  caret-color: rgb(224 242 254 / 0.3) !important;
}

.tw-caret-sky-100\/35 {
  caret-color: rgb(224 242 254 / 0.35) !important;
}

.tw-caret-sky-100\/40 {
  caret-color: rgb(224 242 254 / 0.4) !important;
}

.tw-caret-sky-100\/45 {
  caret-color: rgb(224 242 254 / 0.45) !important;
}

.tw-caret-sky-100\/5 {
  caret-color: rgb(224 242 254 / 0.05) !important;
}

.tw-caret-sky-100\/50 {
  caret-color: rgb(224 242 254 / 0.5) !important;
}

.tw-caret-sky-100\/55 {
  caret-color: rgb(224 242 254 / 0.55) !important;
}

.tw-caret-sky-100\/60 {
  caret-color: rgb(224 242 254 / 0.6) !important;
}

.tw-caret-sky-100\/65 {
  caret-color: rgb(224 242 254 / 0.65) !important;
}

.tw-caret-sky-100\/70 {
  caret-color: rgb(224 242 254 / 0.7) !important;
}

.tw-caret-sky-100\/75 {
  caret-color: rgb(224 242 254 / 0.75) !important;
}

.tw-caret-sky-100\/80 {
  caret-color: rgb(224 242 254 / 0.8) !important;
}

.tw-caret-sky-100\/85 {
  caret-color: rgb(224 242 254 / 0.85) !important;
}

.tw-caret-sky-100\/90 {
  caret-color: rgb(224 242 254 / 0.9) !important;
}

.tw-caret-sky-100\/95 {
  caret-color: rgb(224 242 254 / 0.95) !important;
}

.tw-caret-sky-200 {
  caret-color: #bae6fd !important;
}

.tw-caret-sky-200\/0 {
  caret-color: rgb(186 230 253 / 0) !important;
}

.tw-caret-sky-200\/10 {
  caret-color: rgb(186 230 253 / 0.1) !important;
}

.tw-caret-sky-200\/100 {
  caret-color: rgb(186 230 253 / 1) !important;
}

.tw-caret-sky-200\/15 {
  caret-color: rgb(186 230 253 / 0.15) !important;
}

.tw-caret-sky-200\/20 {
  caret-color: rgb(186 230 253 / 0.2) !important;
}

.tw-caret-sky-200\/25 {
  caret-color: rgb(186 230 253 / 0.25) !important;
}

.tw-caret-sky-200\/30 {
  caret-color: rgb(186 230 253 / 0.3) !important;
}

.tw-caret-sky-200\/35 {
  caret-color: rgb(186 230 253 / 0.35) !important;
}

.tw-caret-sky-200\/40 {
  caret-color: rgb(186 230 253 / 0.4) !important;
}

.tw-caret-sky-200\/45 {
  caret-color: rgb(186 230 253 / 0.45) !important;
}

.tw-caret-sky-200\/5 {
  caret-color: rgb(186 230 253 / 0.05) !important;
}

.tw-caret-sky-200\/50 {
  caret-color: rgb(186 230 253 / 0.5) !important;
}

.tw-caret-sky-200\/55 {
  caret-color: rgb(186 230 253 / 0.55) !important;
}

.tw-caret-sky-200\/60 {
  caret-color: rgb(186 230 253 / 0.6) !important;
}

.tw-caret-sky-200\/65 {
  caret-color: rgb(186 230 253 / 0.65) !important;
}

.tw-caret-sky-200\/70 {
  caret-color: rgb(186 230 253 / 0.7) !important;
}

.tw-caret-sky-200\/75 {
  caret-color: rgb(186 230 253 / 0.75) !important;
}

.tw-caret-sky-200\/80 {
  caret-color: rgb(186 230 253 / 0.8) !important;
}

.tw-caret-sky-200\/85 {
  caret-color: rgb(186 230 253 / 0.85) !important;
}

.tw-caret-sky-200\/90 {
  caret-color: rgb(186 230 253 / 0.9) !important;
}

.tw-caret-sky-200\/95 {
  caret-color: rgb(186 230 253 / 0.95) !important;
}

.tw-caret-sky-300 {
  caret-color: #7dd3fc !important;
}

.tw-caret-sky-300\/0 {
  caret-color: rgb(125 211 252 / 0) !important;
}

.tw-caret-sky-300\/10 {
  caret-color: rgb(125 211 252 / 0.1) !important;
}

.tw-caret-sky-300\/100 {
  caret-color: rgb(125 211 252 / 1) !important;
}

.tw-caret-sky-300\/15 {
  caret-color: rgb(125 211 252 / 0.15) !important;
}

.tw-caret-sky-300\/20 {
  caret-color: rgb(125 211 252 / 0.2) !important;
}

.tw-caret-sky-300\/25 {
  caret-color: rgb(125 211 252 / 0.25) !important;
}

.tw-caret-sky-300\/30 {
  caret-color: rgb(125 211 252 / 0.3) !important;
}

.tw-caret-sky-300\/35 {
  caret-color: rgb(125 211 252 / 0.35) !important;
}

.tw-caret-sky-300\/40 {
  caret-color: rgb(125 211 252 / 0.4) !important;
}

.tw-caret-sky-300\/45 {
  caret-color: rgb(125 211 252 / 0.45) !important;
}

.tw-caret-sky-300\/5 {
  caret-color: rgb(125 211 252 / 0.05) !important;
}

.tw-caret-sky-300\/50 {
  caret-color: rgb(125 211 252 / 0.5) !important;
}

.tw-caret-sky-300\/55 {
  caret-color: rgb(125 211 252 / 0.55) !important;
}

.tw-caret-sky-300\/60 {
  caret-color: rgb(125 211 252 / 0.6) !important;
}

.tw-caret-sky-300\/65 {
  caret-color: rgb(125 211 252 / 0.65) !important;
}

.tw-caret-sky-300\/70 {
  caret-color: rgb(125 211 252 / 0.7) !important;
}

.tw-caret-sky-300\/75 {
  caret-color: rgb(125 211 252 / 0.75) !important;
}

.tw-caret-sky-300\/80 {
  caret-color: rgb(125 211 252 / 0.8) !important;
}

.tw-caret-sky-300\/85 {
  caret-color: rgb(125 211 252 / 0.85) !important;
}

.tw-caret-sky-300\/90 {
  caret-color: rgb(125 211 252 / 0.9) !important;
}

.tw-caret-sky-300\/95 {
  caret-color: rgb(125 211 252 / 0.95) !important;
}

.tw-caret-sky-400 {
  caret-color: #38bdf8 !important;
}

.tw-caret-sky-400\/0 {
  caret-color: rgb(56 189 248 / 0) !important;
}

.tw-caret-sky-400\/10 {
  caret-color: rgb(56 189 248 / 0.1) !important;
}

.tw-caret-sky-400\/100 {
  caret-color: rgb(56 189 248 / 1) !important;
}

.tw-caret-sky-400\/15 {
  caret-color: rgb(56 189 248 / 0.15) !important;
}

.tw-caret-sky-400\/20 {
  caret-color: rgb(56 189 248 / 0.2) !important;
}

.tw-caret-sky-400\/25 {
  caret-color: rgb(56 189 248 / 0.25) !important;
}

.tw-caret-sky-400\/30 {
  caret-color: rgb(56 189 248 / 0.3) !important;
}

.tw-caret-sky-400\/35 {
  caret-color: rgb(56 189 248 / 0.35) !important;
}

.tw-caret-sky-400\/40 {
  caret-color: rgb(56 189 248 / 0.4) !important;
}

.tw-caret-sky-400\/45 {
  caret-color: rgb(56 189 248 / 0.45) !important;
}

.tw-caret-sky-400\/5 {
  caret-color: rgb(56 189 248 / 0.05) !important;
}

.tw-caret-sky-400\/50 {
  caret-color: rgb(56 189 248 / 0.5) !important;
}

.tw-caret-sky-400\/55 {
  caret-color: rgb(56 189 248 / 0.55) !important;
}

.tw-caret-sky-400\/60 {
  caret-color: rgb(56 189 248 / 0.6) !important;
}

.tw-caret-sky-400\/65 {
  caret-color: rgb(56 189 248 / 0.65) !important;
}

.tw-caret-sky-400\/70 {
  caret-color: rgb(56 189 248 / 0.7) !important;
}

.tw-caret-sky-400\/75 {
  caret-color: rgb(56 189 248 / 0.75) !important;
}

.tw-caret-sky-400\/80 {
  caret-color: rgb(56 189 248 / 0.8) !important;
}

.tw-caret-sky-400\/85 {
  caret-color: rgb(56 189 248 / 0.85) !important;
}

.tw-caret-sky-400\/90 {
  caret-color: rgb(56 189 248 / 0.9) !important;
}

.tw-caret-sky-400\/95 {
  caret-color: rgb(56 189 248 / 0.95) !important;
}

.tw-caret-sky-50 {
  caret-color: #f0f9ff !important;
}

.tw-caret-sky-50\/0 {
  caret-color: rgb(240 249 255 / 0) !important;
}

.tw-caret-sky-50\/10 {
  caret-color: rgb(240 249 255 / 0.1) !important;
}

.tw-caret-sky-50\/100 {
  caret-color: rgb(240 249 255 / 1) !important;
}

.tw-caret-sky-50\/15 {
  caret-color: rgb(240 249 255 / 0.15) !important;
}

.tw-caret-sky-50\/20 {
  caret-color: rgb(240 249 255 / 0.2) !important;
}

.tw-caret-sky-50\/25 {
  caret-color: rgb(240 249 255 / 0.25) !important;
}

.tw-caret-sky-50\/30 {
  caret-color: rgb(240 249 255 / 0.3) !important;
}

.tw-caret-sky-50\/35 {
  caret-color: rgb(240 249 255 / 0.35) !important;
}

.tw-caret-sky-50\/40 {
  caret-color: rgb(240 249 255 / 0.4) !important;
}

.tw-caret-sky-50\/45 {
  caret-color: rgb(240 249 255 / 0.45) !important;
}

.tw-caret-sky-50\/5 {
  caret-color: rgb(240 249 255 / 0.05) !important;
}

.tw-caret-sky-50\/50 {
  caret-color: rgb(240 249 255 / 0.5) !important;
}

.tw-caret-sky-50\/55 {
  caret-color: rgb(240 249 255 / 0.55) !important;
}

.tw-caret-sky-50\/60 {
  caret-color: rgb(240 249 255 / 0.6) !important;
}

.tw-caret-sky-50\/65 {
  caret-color: rgb(240 249 255 / 0.65) !important;
}

.tw-caret-sky-50\/70 {
  caret-color: rgb(240 249 255 / 0.7) !important;
}

.tw-caret-sky-50\/75 {
  caret-color: rgb(240 249 255 / 0.75) !important;
}

.tw-caret-sky-50\/80 {
  caret-color: rgb(240 249 255 / 0.8) !important;
}

.tw-caret-sky-50\/85 {
  caret-color: rgb(240 249 255 / 0.85) !important;
}

.tw-caret-sky-50\/90 {
  caret-color: rgb(240 249 255 / 0.9) !important;
}

.tw-caret-sky-50\/95 {
  caret-color: rgb(240 249 255 / 0.95) !important;
}

.tw-caret-sky-500 {
  caret-color: #0ea5e9 !important;
}

.tw-caret-sky-500\/0 {
  caret-color: rgb(14 165 233 / 0) !important;
}

.tw-caret-sky-500\/10 {
  caret-color: rgb(14 165 233 / 0.1) !important;
}

.tw-caret-sky-500\/100 {
  caret-color: rgb(14 165 233 / 1) !important;
}

.tw-caret-sky-500\/15 {
  caret-color: rgb(14 165 233 / 0.15) !important;
}

.tw-caret-sky-500\/20 {
  caret-color: rgb(14 165 233 / 0.2) !important;
}

.tw-caret-sky-500\/25 {
  caret-color: rgb(14 165 233 / 0.25) !important;
}

.tw-caret-sky-500\/30 {
  caret-color: rgb(14 165 233 / 0.3) !important;
}

.tw-caret-sky-500\/35 {
  caret-color: rgb(14 165 233 / 0.35) !important;
}

.tw-caret-sky-500\/40 {
  caret-color: rgb(14 165 233 / 0.4) !important;
}

.tw-caret-sky-500\/45 {
  caret-color: rgb(14 165 233 / 0.45) !important;
}

.tw-caret-sky-500\/5 {
  caret-color: rgb(14 165 233 / 0.05) !important;
}

.tw-caret-sky-500\/50 {
  caret-color: rgb(14 165 233 / 0.5) !important;
}

.tw-caret-sky-500\/55 {
  caret-color: rgb(14 165 233 / 0.55) !important;
}

.tw-caret-sky-500\/60 {
  caret-color: rgb(14 165 233 / 0.6) !important;
}

.tw-caret-sky-500\/65 {
  caret-color: rgb(14 165 233 / 0.65) !important;
}

.tw-caret-sky-500\/70 {
  caret-color: rgb(14 165 233 / 0.7) !important;
}

.tw-caret-sky-500\/75 {
  caret-color: rgb(14 165 233 / 0.75) !important;
}

.tw-caret-sky-500\/80 {
  caret-color: rgb(14 165 233 / 0.8) !important;
}

.tw-caret-sky-500\/85 {
  caret-color: rgb(14 165 233 / 0.85) !important;
}

.tw-caret-sky-500\/90 {
  caret-color: rgb(14 165 233 / 0.9) !important;
}

.tw-caret-sky-500\/95 {
  caret-color: rgb(14 165 233 / 0.95) !important;
}

.tw-caret-sky-600 {
  caret-color: #0284c7 !important;
}

.tw-caret-sky-600\/0 {
  caret-color: rgb(2 132 199 / 0) !important;
}

.tw-caret-sky-600\/10 {
  caret-color: rgb(2 132 199 / 0.1) !important;
}

.tw-caret-sky-600\/100 {
  caret-color: rgb(2 132 199 / 1) !important;
}

.tw-caret-sky-600\/15 {
  caret-color: rgb(2 132 199 / 0.15) !important;
}

.tw-caret-sky-600\/20 {
  caret-color: rgb(2 132 199 / 0.2) !important;
}

.tw-caret-sky-600\/25 {
  caret-color: rgb(2 132 199 / 0.25) !important;
}

.tw-caret-sky-600\/30 {
  caret-color: rgb(2 132 199 / 0.3) !important;
}

.tw-caret-sky-600\/35 {
  caret-color: rgb(2 132 199 / 0.35) !important;
}

.tw-caret-sky-600\/40 {
  caret-color: rgb(2 132 199 / 0.4) !important;
}

.tw-caret-sky-600\/45 {
  caret-color: rgb(2 132 199 / 0.45) !important;
}

.tw-caret-sky-600\/5 {
  caret-color: rgb(2 132 199 / 0.05) !important;
}

.tw-caret-sky-600\/50 {
  caret-color: rgb(2 132 199 / 0.5) !important;
}

.tw-caret-sky-600\/55 {
  caret-color: rgb(2 132 199 / 0.55) !important;
}

.tw-caret-sky-600\/60 {
  caret-color: rgb(2 132 199 / 0.6) !important;
}

.tw-caret-sky-600\/65 {
  caret-color: rgb(2 132 199 / 0.65) !important;
}

.tw-caret-sky-600\/70 {
  caret-color: rgb(2 132 199 / 0.7) !important;
}

.tw-caret-sky-600\/75 {
  caret-color: rgb(2 132 199 / 0.75) !important;
}

.tw-caret-sky-600\/80 {
  caret-color: rgb(2 132 199 / 0.8) !important;
}

.tw-caret-sky-600\/85 {
  caret-color: rgb(2 132 199 / 0.85) !important;
}

.tw-caret-sky-600\/90 {
  caret-color: rgb(2 132 199 / 0.9) !important;
}

.tw-caret-sky-600\/95 {
  caret-color: rgb(2 132 199 / 0.95) !important;
}

.tw-caret-sky-700 {
  caret-color: #0369a1 !important;
}

.tw-caret-sky-700\/0 {
  caret-color: rgb(3 105 161 / 0) !important;
}

.tw-caret-sky-700\/10 {
  caret-color: rgb(3 105 161 / 0.1) !important;
}

.tw-caret-sky-700\/100 {
  caret-color: rgb(3 105 161 / 1) !important;
}

.tw-caret-sky-700\/15 {
  caret-color: rgb(3 105 161 / 0.15) !important;
}

.tw-caret-sky-700\/20 {
  caret-color: rgb(3 105 161 / 0.2) !important;
}

.tw-caret-sky-700\/25 {
  caret-color: rgb(3 105 161 / 0.25) !important;
}

.tw-caret-sky-700\/30 {
  caret-color: rgb(3 105 161 / 0.3) !important;
}

.tw-caret-sky-700\/35 {
  caret-color: rgb(3 105 161 / 0.35) !important;
}

.tw-caret-sky-700\/40 {
  caret-color: rgb(3 105 161 / 0.4) !important;
}

.tw-caret-sky-700\/45 {
  caret-color: rgb(3 105 161 / 0.45) !important;
}

.tw-caret-sky-700\/5 {
  caret-color: rgb(3 105 161 / 0.05) !important;
}

.tw-caret-sky-700\/50 {
  caret-color: rgb(3 105 161 / 0.5) !important;
}

.tw-caret-sky-700\/55 {
  caret-color: rgb(3 105 161 / 0.55) !important;
}

.tw-caret-sky-700\/60 {
  caret-color: rgb(3 105 161 / 0.6) !important;
}

.tw-caret-sky-700\/65 {
  caret-color: rgb(3 105 161 / 0.65) !important;
}

.tw-caret-sky-700\/70 {
  caret-color: rgb(3 105 161 / 0.7) !important;
}

.tw-caret-sky-700\/75 {
  caret-color: rgb(3 105 161 / 0.75) !important;
}

.tw-caret-sky-700\/80 {
  caret-color: rgb(3 105 161 / 0.8) !important;
}

.tw-caret-sky-700\/85 {
  caret-color: rgb(3 105 161 / 0.85) !important;
}

.tw-caret-sky-700\/90 {
  caret-color: rgb(3 105 161 / 0.9) !important;
}

.tw-caret-sky-700\/95 {
  caret-color: rgb(3 105 161 / 0.95) !important;
}

.tw-caret-sky-800 {
  caret-color: #075985 !important;
}

.tw-caret-sky-800\/0 {
  caret-color: rgb(7 89 133 / 0) !important;
}

.tw-caret-sky-800\/10 {
  caret-color: rgb(7 89 133 / 0.1) !important;
}

.tw-caret-sky-800\/100 {
  caret-color: rgb(7 89 133 / 1) !important;
}

.tw-caret-sky-800\/15 {
  caret-color: rgb(7 89 133 / 0.15) !important;
}

.tw-caret-sky-800\/20 {
  caret-color: rgb(7 89 133 / 0.2) !important;
}

.tw-caret-sky-800\/25 {
  caret-color: rgb(7 89 133 / 0.25) !important;
}

.tw-caret-sky-800\/30 {
  caret-color: rgb(7 89 133 / 0.3) !important;
}

.tw-caret-sky-800\/35 {
  caret-color: rgb(7 89 133 / 0.35) !important;
}

.tw-caret-sky-800\/40 {
  caret-color: rgb(7 89 133 / 0.4) !important;
}

.tw-caret-sky-800\/45 {
  caret-color: rgb(7 89 133 / 0.45) !important;
}

.tw-caret-sky-800\/5 {
  caret-color: rgb(7 89 133 / 0.05) !important;
}

.tw-caret-sky-800\/50 {
  caret-color: rgb(7 89 133 / 0.5) !important;
}

.tw-caret-sky-800\/55 {
  caret-color: rgb(7 89 133 / 0.55) !important;
}

.tw-caret-sky-800\/60 {
  caret-color: rgb(7 89 133 / 0.6) !important;
}

.tw-caret-sky-800\/65 {
  caret-color: rgb(7 89 133 / 0.65) !important;
}

.tw-caret-sky-800\/70 {
  caret-color: rgb(7 89 133 / 0.7) !important;
}

.tw-caret-sky-800\/75 {
  caret-color: rgb(7 89 133 / 0.75) !important;
}

.tw-caret-sky-800\/80 {
  caret-color: rgb(7 89 133 / 0.8) !important;
}

.tw-caret-sky-800\/85 {
  caret-color: rgb(7 89 133 / 0.85) !important;
}

.tw-caret-sky-800\/90 {
  caret-color: rgb(7 89 133 / 0.9) !important;
}

.tw-caret-sky-800\/95 {
  caret-color: rgb(7 89 133 / 0.95) !important;
}

.tw-caret-sky-900 {
  caret-color: #0c4a6e !important;
}

.tw-caret-sky-900\/0 {
  caret-color: rgb(12 74 110 / 0) !important;
}

.tw-caret-sky-900\/10 {
  caret-color: rgb(12 74 110 / 0.1) !important;
}

.tw-caret-sky-900\/100 {
  caret-color: rgb(12 74 110 / 1) !important;
}

.tw-caret-sky-900\/15 {
  caret-color: rgb(12 74 110 / 0.15) !important;
}

.tw-caret-sky-900\/20 {
  caret-color: rgb(12 74 110 / 0.2) !important;
}

.tw-caret-sky-900\/25 {
  caret-color: rgb(12 74 110 / 0.25) !important;
}

.tw-caret-sky-900\/30 {
  caret-color: rgb(12 74 110 / 0.3) !important;
}

.tw-caret-sky-900\/35 {
  caret-color: rgb(12 74 110 / 0.35) !important;
}

.tw-caret-sky-900\/40 {
  caret-color: rgb(12 74 110 / 0.4) !important;
}

.tw-caret-sky-900\/45 {
  caret-color: rgb(12 74 110 / 0.45) !important;
}

.tw-caret-sky-900\/5 {
  caret-color: rgb(12 74 110 / 0.05) !important;
}

.tw-caret-sky-900\/50 {
  caret-color: rgb(12 74 110 / 0.5) !important;
}

.tw-caret-sky-900\/55 {
  caret-color: rgb(12 74 110 / 0.55) !important;
}

.tw-caret-sky-900\/60 {
  caret-color: rgb(12 74 110 / 0.6) !important;
}

.tw-caret-sky-900\/65 {
  caret-color: rgb(12 74 110 / 0.65) !important;
}

.tw-caret-sky-900\/70 {
  caret-color: rgb(12 74 110 / 0.7) !important;
}

.tw-caret-sky-900\/75 {
  caret-color: rgb(12 74 110 / 0.75) !important;
}

.tw-caret-sky-900\/80 {
  caret-color: rgb(12 74 110 / 0.8) !important;
}

.tw-caret-sky-900\/85 {
  caret-color: rgb(12 74 110 / 0.85) !important;
}

.tw-caret-sky-900\/90 {
  caret-color: rgb(12 74 110 / 0.9) !important;
}

.tw-caret-sky-900\/95 {
  caret-color: rgb(12 74 110 / 0.95) !important;
}

.tw-caret-sky-950 {
  caret-color: #082f49 !important;
}

.tw-caret-sky-950\/0 {
  caret-color: rgb(8 47 73 / 0) !important;
}

.tw-caret-sky-950\/10 {
  caret-color: rgb(8 47 73 / 0.1) !important;
}

.tw-caret-sky-950\/100 {
  caret-color: rgb(8 47 73 / 1) !important;
}

.tw-caret-sky-950\/15 {
  caret-color: rgb(8 47 73 / 0.15) !important;
}

.tw-caret-sky-950\/20 {
  caret-color: rgb(8 47 73 / 0.2) !important;
}

.tw-caret-sky-950\/25 {
  caret-color: rgb(8 47 73 / 0.25) !important;
}

.tw-caret-sky-950\/30 {
  caret-color: rgb(8 47 73 / 0.3) !important;
}

.tw-caret-sky-950\/35 {
  caret-color: rgb(8 47 73 / 0.35) !important;
}

.tw-caret-sky-950\/40 {
  caret-color: rgb(8 47 73 / 0.4) !important;
}

.tw-caret-sky-950\/45 {
  caret-color: rgb(8 47 73 / 0.45) !important;
}

.tw-caret-sky-950\/5 {
  caret-color: rgb(8 47 73 / 0.05) !important;
}

.tw-caret-sky-950\/50 {
  caret-color: rgb(8 47 73 / 0.5) !important;
}

.tw-caret-sky-950\/55 {
  caret-color: rgb(8 47 73 / 0.55) !important;
}

.tw-caret-sky-950\/60 {
  caret-color: rgb(8 47 73 / 0.6) !important;
}

.tw-caret-sky-950\/65 {
  caret-color: rgb(8 47 73 / 0.65) !important;
}

.tw-caret-sky-950\/70 {
  caret-color: rgb(8 47 73 / 0.7) !important;
}

.tw-caret-sky-950\/75 {
  caret-color: rgb(8 47 73 / 0.75) !important;
}

.tw-caret-sky-950\/80 {
  caret-color: rgb(8 47 73 / 0.8) !important;
}

.tw-caret-sky-950\/85 {
  caret-color: rgb(8 47 73 / 0.85) !important;
}

.tw-caret-sky-950\/90 {
  caret-color: rgb(8 47 73 / 0.9) !important;
}

.tw-caret-sky-950\/95 {
  caret-color: rgb(8 47 73 / 0.95) !important;
}

.tw-caret-slate-100 {
  caret-color: #f1f5f9 !important;
}

.tw-caret-slate-100\/0 {
  caret-color: rgb(241 245 249 / 0) !important;
}

.tw-caret-slate-100\/10 {
  caret-color: rgb(241 245 249 / 0.1) !important;
}

.tw-caret-slate-100\/100 {
  caret-color: rgb(241 245 249 / 1) !important;
}

.tw-caret-slate-100\/15 {
  caret-color: rgb(241 245 249 / 0.15) !important;
}

.tw-caret-slate-100\/20 {
  caret-color: rgb(241 245 249 / 0.2) !important;
}

.tw-caret-slate-100\/25 {
  caret-color: rgb(241 245 249 / 0.25) !important;
}

.tw-caret-slate-100\/30 {
  caret-color: rgb(241 245 249 / 0.3) !important;
}

.tw-caret-slate-100\/35 {
  caret-color: rgb(241 245 249 / 0.35) !important;
}

.tw-caret-slate-100\/40 {
  caret-color: rgb(241 245 249 / 0.4) !important;
}

.tw-caret-slate-100\/45 {
  caret-color: rgb(241 245 249 / 0.45) !important;
}

.tw-caret-slate-100\/5 {
  caret-color: rgb(241 245 249 / 0.05) !important;
}

.tw-caret-slate-100\/50 {
  caret-color: rgb(241 245 249 / 0.5) !important;
}

.tw-caret-slate-100\/55 {
  caret-color: rgb(241 245 249 / 0.55) !important;
}

.tw-caret-slate-100\/60 {
  caret-color: rgb(241 245 249 / 0.6) !important;
}

.tw-caret-slate-100\/65 {
  caret-color: rgb(241 245 249 / 0.65) !important;
}

.tw-caret-slate-100\/70 {
  caret-color: rgb(241 245 249 / 0.7) !important;
}

.tw-caret-slate-100\/75 {
  caret-color: rgb(241 245 249 / 0.75) !important;
}

.tw-caret-slate-100\/80 {
  caret-color: rgb(241 245 249 / 0.8) !important;
}

.tw-caret-slate-100\/85 {
  caret-color: rgb(241 245 249 / 0.85) !important;
}

.tw-caret-slate-100\/90 {
  caret-color: rgb(241 245 249 / 0.9) !important;
}

.tw-caret-slate-100\/95 {
  caret-color: rgb(241 245 249 / 0.95) !important;
}

.tw-caret-slate-200 {
  caret-color: #e2e8f0 !important;
}

.tw-caret-slate-200\/0 {
  caret-color: rgb(226 232 240 / 0) !important;
}

.tw-caret-slate-200\/10 {
  caret-color: rgb(226 232 240 / 0.1) !important;
}

.tw-caret-slate-200\/100 {
  caret-color: rgb(226 232 240 / 1) !important;
}

.tw-caret-slate-200\/15 {
  caret-color: rgb(226 232 240 / 0.15) !important;
}

.tw-caret-slate-200\/20 {
  caret-color: rgb(226 232 240 / 0.2) !important;
}

.tw-caret-slate-200\/25 {
  caret-color: rgb(226 232 240 / 0.25) !important;
}

.tw-caret-slate-200\/30 {
  caret-color: rgb(226 232 240 / 0.3) !important;
}

.tw-caret-slate-200\/35 {
  caret-color: rgb(226 232 240 / 0.35) !important;
}

.tw-caret-slate-200\/40 {
  caret-color: rgb(226 232 240 / 0.4) !important;
}

.tw-caret-slate-200\/45 {
  caret-color: rgb(226 232 240 / 0.45) !important;
}

.tw-caret-slate-200\/5 {
  caret-color: rgb(226 232 240 / 0.05) !important;
}

.tw-caret-slate-200\/50 {
  caret-color: rgb(226 232 240 / 0.5) !important;
}

.tw-caret-slate-200\/55 {
  caret-color: rgb(226 232 240 / 0.55) !important;
}

.tw-caret-slate-200\/60 {
  caret-color: rgb(226 232 240 / 0.6) !important;
}

.tw-caret-slate-200\/65 {
  caret-color: rgb(226 232 240 / 0.65) !important;
}

.tw-caret-slate-200\/70 {
  caret-color: rgb(226 232 240 / 0.7) !important;
}

.tw-caret-slate-200\/75 {
  caret-color: rgb(226 232 240 / 0.75) !important;
}

.tw-caret-slate-200\/80 {
  caret-color: rgb(226 232 240 / 0.8) !important;
}

.tw-caret-slate-200\/85 {
  caret-color: rgb(226 232 240 / 0.85) !important;
}

.tw-caret-slate-200\/90 {
  caret-color: rgb(226 232 240 / 0.9) !important;
}

.tw-caret-slate-200\/95 {
  caret-color: rgb(226 232 240 / 0.95) !important;
}

.tw-caret-slate-300 {
  caret-color: #cbd5e1 !important;
}

.tw-caret-slate-300\/0 {
  caret-color: rgb(203 213 225 / 0) !important;
}

.tw-caret-slate-300\/10 {
  caret-color: rgb(203 213 225 / 0.1) !important;
}

.tw-caret-slate-300\/100 {
  caret-color: rgb(203 213 225 / 1) !important;
}

.tw-caret-slate-300\/15 {
  caret-color: rgb(203 213 225 / 0.15) !important;
}

.tw-caret-slate-300\/20 {
  caret-color: rgb(203 213 225 / 0.2) !important;
}

.tw-caret-slate-300\/25 {
  caret-color: rgb(203 213 225 / 0.25) !important;
}

.tw-caret-slate-300\/30 {
  caret-color: rgb(203 213 225 / 0.3) !important;
}

.tw-caret-slate-300\/35 {
  caret-color: rgb(203 213 225 / 0.35) !important;
}

.tw-caret-slate-300\/40 {
  caret-color: rgb(203 213 225 / 0.4) !important;
}

.tw-caret-slate-300\/45 {
  caret-color: rgb(203 213 225 / 0.45) !important;
}

.tw-caret-slate-300\/5 {
  caret-color: rgb(203 213 225 / 0.05) !important;
}

.tw-caret-slate-300\/50 {
  caret-color: rgb(203 213 225 / 0.5) !important;
}

.tw-caret-slate-300\/55 {
  caret-color: rgb(203 213 225 / 0.55) !important;
}

.tw-caret-slate-300\/60 {
  caret-color: rgb(203 213 225 / 0.6) !important;
}

.tw-caret-slate-300\/65 {
  caret-color: rgb(203 213 225 / 0.65) !important;
}

.tw-caret-slate-300\/70 {
  caret-color: rgb(203 213 225 / 0.7) !important;
}

.tw-caret-slate-300\/75 {
  caret-color: rgb(203 213 225 / 0.75) !important;
}

.tw-caret-slate-300\/80 {
  caret-color: rgb(203 213 225 / 0.8) !important;
}

.tw-caret-slate-300\/85 {
  caret-color: rgb(203 213 225 / 0.85) !important;
}

.tw-caret-slate-300\/90 {
  caret-color: rgb(203 213 225 / 0.9) !important;
}

.tw-caret-slate-300\/95 {
  caret-color: rgb(203 213 225 / 0.95) !important;
}

.tw-caret-slate-400 {
  caret-color: #94a3b8 !important;
}

.tw-caret-slate-400\/0 {
  caret-color: rgb(148 163 184 / 0) !important;
}

.tw-caret-slate-400\/10 {
  caret-color: rgb(148 163 184 / 0.1) !important;
}

.tw-caret-slate-400\/100 {
  caret-color: rgb(148 163 184 / 1) !important;
}

.tw-caret-slate-400\/15 {
  caret-color: rgb(148 163 184 / 0.15) !important;
}

.tw-caret-slate-400\/20 {
  caret-color: rgb(148 163 184 / 0.2) !important;
}

.tw-caret-slate-400\/25 {
  caret-color: rgb(148 163 184 / 0.25) !important;
}

.tw-caret-slate-400\/30 {
  caret-color: rgb(148 163 184 / 0.3) !important;
}

.tw-caret-slate-400\/35 {
  caret-color: rgb(148 163 184 / 0.35) !important;
}

.tw-caret-slate-400\/40 {
  caret-color: rgb(148 163 184 / 0.4) !important;
}

.tw-caret-slate-400\/45 {
  caret-color: rgb(148 163 184 / 0.45) !important;
}

.tw-caret-slate-400\/5 {
  caret-color: rgb(148 163 184 / 0.05) !important;
}

.tw-caret-slate-400\/50 {
  caret-color: rgb(148 163 184 / 0.5) !important;
}

.tw-caret-slate-400\/55 {
  caret-color: rgb(148 163 184 / 0.55) !important;
}

.tw-caret-slate-400\/60 {
  caret-color: rgb(148 163 184 / 0.6) !important;
}

.tw-caret-slate-400\/65 {
  caret-color: rgb(148 163 184 / 0.65) !important;
}

.tw-caret-slate-400\/70 {
  caret-color: rgb(148 163 184 / 0.7) !important;
}

.tw-caret-slate-400\/75 {
  caret-color: rgb(148 163 184 / 0.75) !important;
}

.tw-caret-slate-400\/80 {
  caret-color: rgb(148 163 184 / 0.8) !important;
}

.tw-caret-slate-400\/85 {
  caret-color: rgb(148 163 184 / 0.85) !important;
}

.tw-caret-slate-400\/90 {
  caret-color: rgb(148 163 184 / 0.9) !important;
}

.tw-caret-slate-400\/95 {
  caret-color: rgb(148 163 184 / 0.95) !important;
}

.tw-caret-slate-50 {
  caret-color: #f8fafc !important;
}

.tw-caret-slate-50\/0 {
  caret-color: rgb(248 250 252 / 0) !important;
}

.tw-caret-slate-50\/10 {
  caret-color: rgb(248 250 252 / 0.1) !important;
}

.tw-caret-slate-50\/100 {
  caret-color: rgb(248 250 252 / 1) !important;
}

.tw-caret-slate-50\/15 {
  caret-color: rgb(248 250 252 / 0.15) !important;
}

.tw-caret-slate-50\/20 {
  caret-color: rgb(248 250 252 / 0.2) !important;
}

.tw-caret-slate-50\/25 {
  caret-color: rgb(248 250 252 / 0.25) !important;
}

.tw-caret-slate-50\/30 {
  caret-color: rgb(248 250 252 / 0.3) !important;
}

.tw-caret-slate-50\/35 {
  caret-color: rgb(248 250 252 / 0.35) !important;
}

.tw-caret-slate-50\/40 {
  caret-color: rgb(248 250 252 / 0.4) !important;
}

.tw-caret-slate-50\/45 {
  caret-color: rgb(248 250 252 / 0.45) !important;
}

.tw-caret-slate-50\/5 {
  caret-color: rgb(248 250 252 / 0.05) !important;
}

.tw-caret-slate-50\/50 {
  caret-color: rgb(248 250 252 / 0.5) !important;
}

.tw-caret-slate-50\/55 {
  caret-color: rgb(248 250 252 / 0.55) !important;
}

.tw-caret-slate-50\/60 {
  caret-color: rgb(248 250 252 / 0.6) !important;
}

.tw-caret-slate-50\/65 {
  caret-color: rgb(248 250 252 / 0.65) !important;
}

.tw-caret-slate-50\/70 {
  caret-color: rgb(248 250 252 / 0.7) !important;
}

.tw-caret-slate-50\/75 {
  caret-color: rgb(248 250 252 / 0.75) !important;
}

.tw-caret-slate-50\/80 {
  caret-color: rgb(248 250 252 / 0.8) !important;
}

.tw-caret-slate-50\/85 {
  caret-color: rgb(248 250 252 / 0.85) !important;
}

.tw-caret-slate-50\/90 {
  caret-color: rgb(248 250 252 / 0.9) !important;
}

.tw-caret-slate-50\/95 {
  caret-color: rgb(248 250 252 / 0.95) !important;
}

.tw-caret-slate-500 {
  caret-color: #64748b !important;
}

.tw-caret-slate-500\/0 {
  caret-color: rgb(100 116 139 / 0) !important;
}

.tw-caret-slate-500\/10 {
  caret-color: rgb(100 116 139 / 0.1) !important;
}

.tw-caret-slate-500\/100 {
  caret-color: rgb(100 116 139 / 1) !important;
}

.tw-caret-slate-500\/15 {
  caret-color: rgb(100 116 139 / 0.15) !important;
}

.tw-caret-slate-500\/20 {
  caret-color: rgb(100 116 139 / 0.2) !important;
}

.tw-caret-slate-500\/25 {
  caret-color: rgb(100 116 139 / 0.25) !important;
}

.tw-caret-slate-500\/30 {
  caret-color: rgb(100 116 139 / 0.3) !important;
}

.tw-caret-slate-500\/35 {
  caret-color: rgb(100 116 139 / 0.35) !important;
}

.tw-caret-slate-500\/40 {
  caret-color: rgb(100 116 139 / 0.4) !important;
}

.tw-caret-slate-500\/45 {
  caret-color: rgb(100 116 139 / 0.45) !important;
}

.tw-caret-slate-500\/5 {
  caret-color: rgb(100 116 139 / 0.05) !important;
}

.tw-caret-slate-500\/50 {
  caret-color: rgb(100 116 139 / 0.5) !important;
}

.tw-caret-slate-500\/55 {
  caret-color: rgb(100 116 139 / 0.55) !important;
}

.tw-caret-slate-500\/60 {
  caret-color: rgb(100 116 139 / 0.6) !important;
}

.tw-caret-slate-500\/65 {
  caret-color: rgb(100 116 139 / 0.65) !important;
}

.tw-caret-slate-500\/70 {
  caret-color: rgb(100 116 139 / 0.7) !important;
}

.tw-caret-slate-500\/75 {
  caret-color: rgb(100 116 139 / 0.75) !important;
}

.tw-caret-slate-500\/80 {
  caret-color: rgb(100 116 139 / 0.8) !important;
}

.tw-caret-slate-500\/85 {
  caret-color: rgb(100 116 139 / 0.85) !important;
}

.tw-caret-slate-500\/90 {
  caret-color: rgb(100 116 139 / 0.9) !important;
}

.tw-caret-slate-500\/95 {
  caret-color: rgb(100 116 139 / 0.95) !important;
}

.tw-caret-slate-600 {
  caret-color: #475569 !important;
}

.tw-caret-slate-600\/0 {
  caret-color: rgb(71 85 105 / 0) !important;
}

.tw-caret-slate-600\/10 {
  caret-color: rgb(71 85 105 / 0.1) !important;
}

.tw-caret-slate-600\/100 {
  caret-color: rgb(71 85 105 / 1) !important;
}

.tw-caret-slate-600\/15 {
  caret-color: rgb(71 85 105 / 0.15) !important;
}

.tw-caret-slate-600\/20 {
  caret-color: rgb(71 85 105 / 0.2) !important;
}

.tw-caret-slate-600\/25 {
  caret-color: rgb(71 85 105 / 0.25) !important;
}

.tw-caret-slate-600\/30 {
  caret-color: rgb(71 85 105 / 0.3) !important;
}

.tw-caret-slate-600\/35 {
  caret-color: rgb(71 85 105 / 0.35) !important;
}

.tw-caret-slate-600\/40 {
  caret-color: rgb(71 85 105 / 0.4) !important;
}

.tw-caret-slate-600\/45 {
  caret-color: rgb(71 85 105 / 0.45) !important;
}

.tw-caret-slate-600\/5 {
  caret-color: rgb(71 85 105 / 0.05) !important;
}

.tw-caret-slate-600\/50 {
  caret-color: rgb(71 85 105 / 0.5) !important;
}

.tw-caret-slate-600\/55 {
  caret-color: rgb(71 85 105 / 0.55) !important;
}

.tw-caret-slate-600\/60 {
  caret-color: rgb(71 85 105 / 0.6) !important;
}

.tw-caret-slate-600\/65 {
  caret-color: rgb(71 85 105 / 0.65) !important;
}

.tw-caret-slate-600\/70 {
  caret-color: rgb(71 85 105 / 0.7) !important;
}

.tw-caret-slate-600\/75 {
  caret-color: rgb(71 85 105 / 0.75) !important;
}

.tw-caret-slate-600\/80 {
  caret-color: rgb(71 85 105 / 0.8) !important;
}

.tw-caret-slate-600\/85 {
  caret-color: rgb(71 85 105 / 0.85) !important;
}

.tw-caret-slate-600\/90 {
  caret-color: rgb(71 85 105 / 0.9) !important;
}

.tw-caret-slate-600\/95 {
  caret-color: rgb(71 85 105 / 0.95) !important;
}

.tw-caret-slate-700 {
  caret-color: #334155 !important;
}

.tw-caret-slate-700\/0 {
  caret-color: rgb(51 65 85 / 0) !important;
}

.tw-caret-slate-700\/10 {
  caret-color: rgb(51 65 85 / 0.1) !important;
}

.tw-caret-slate-700\/100 {
  caret-color: rgb(51 65 85 / 1) !important;
}

.tw-caret-slate-700\/15 {
  caret-color: rgb(51 65 85 / 0.15) !important;
}

.tw-caret-slate-700\/20 {
  caret-color: rgb(51 65 85 / 0.2) !important;
}

.tw-caret-slate-700\/25 {
  caret-color: rgb(51 65 85 / 0.25) !important;
}

.tw-caret-slate-700\/30 {
  caret-color: rgb(51 65 85 / 0.3) !important;
}

.tw-caret-slate-700\/35 {
  caret-color: rgb(51 65 85 / 0.35) !important;
}

.tw-caret-slate-700\/40 {
  caret-color: rgb(51 65 85 / 0.4) !important;
}

.tw-caret-slate-700\/45 {
  caret-color: rgb(51 65 85 / 0.45) !important;
}

.tw-caret-slate-700\/5 {
  caret-color: rgb(51 65 85 / 0.05) !important;
}

.tw-caret-slate-700\/50 {
  caret-color: rgb(51 65 85 / 0.5) !important;
}

.tw-caret-slate-700\/55 {
  caret-color: rgb(51 65 85 / 0.55) !important;
}

.tw-caret-slate-700\/60 {
  caret-color: rgb(51 65 85 / 0.6) !important;
}

.tw-caret-slate-700\/65 {
  caret-color: rgb(51 65 85 / 0.65) !important;
}

.tw-caret-slate-700\/70 {
  caret-color: rgb(51 65 85 / 0.7) !important;
}

.tw-caret-slate-700\/75 {
  caret-color: rgb(51 65 85 / 0.75) !important;
}

.tw-caret-slate-700\/80 {
  caret-color: rgb(51 65 85 / 0.8) !important;
}

.tw-caret-slate-700\/85 {
  caret-color: rgb(51 65 85 / 0.85) !important;
}

.tw-caret-slate-700\/90 {
  caret-color: rgb(51 65 85 / 0.9) !important;
}

.tw-caret-slate-700\/95 {
  caret-color: rgb(51 65 85 / 0.95) !important;
}

.tw-caret-slate-800 {
  caret-color: #1e293b !important;
}

.tw-caret-slate-800\/0 {
  caret-color: rgb(30 41 59 / 0) !important;
}

.tw-caret-slate-800\/10 {
  caret-color: rgb(30 41 59 / 0.1) !important;
}

.tw-caret-slate-800\/100 {
  caret-color: rgb(30 41 59 / 1) !important;
}

.tw-caret-slate-800\/15 {
  caret-color: rgb(30 41 59 / 0.15) !important;
}

.tw-caret-slate-800\/20 {
  caret-color: rgb(30 41 59 / 0.2) !important;
}

.tw-caret-slate-800\/25 {
  caret-color: rgb(30 41 59 / 0.25) !important;
}

.tw-caret-slate-800\/30 {
  caret-color: rgb(30 41 59 / 0.3) !important;
}

.tw-caret-slate-800\/35 {
  caret-color: rgb(30 41 59 / 0.35) !important;
}

.tw-caret-slate-800\/40 {
  caret-color: rgb(30 41 59 / 0.4) !important;
}

.tw-caret-slate-800\/45 {
  caret-color: rgb(30 41 59 / 0.45) !important;
}

.tw-caret-slate-800\/5 {
  caret-color: rgb(30 41 59 / 0.05) !important;
}

.tw-caret-slate-800\/50 {
  caret-color: rgb(30 41 59 / 0.5) !important;
}

.tw-caret-slate-800\/55 {
  caret-color: rgb(30 41 59 / 0.55) !important;
}

.tw-caret-slate-800\/60 {
  caret-color: rgb(30 41 59 / 0.6) !important;
}

.tw-caret-slate-800\/65 {
  caret-color: rgb(30 41 59 / 0.65) !important;
}

.tw-caret-slate-800\/70 {
  caret-color: rgb(30 41 59 / 0.7) !important;
}

.tw-caret-slate-800\/75 {
  caret-color: rgb(30 41 59 / 0.75) !important;
}

.tw-caret-slate-800\/80 {
  caret-color: rgb(30 41 59 / 0.8) !important;
}

.tw-caret-slate-800\/85 {
  caret-color: rgb(30 41 59 / 0.85) !important;
}

.tw-caret-slate-800\/90 {
  caret-color: rgb(30 41 59 / 0.9) !important;
}

.tw-caret-slate-800\/95 {
  caret-color: rgb(30 41 59 / 0.95) !important;
}

.tw-caret-slate-900 {
  caret-color: #0f172a !important;
}

.tw-caret-slate-900\/0 {
  caret-color: rgb(15 23 42 / 0) !important;
}

.tw-caret-slate-900\/10 {
  caret-color: rgb(15 23 42 / 0.1) !important;
}

.tw-caret-slate-900\/100 {
  caret-color: rgb(15 23 42 / 1) !important;
}

.tw-caret-slate-900\/15 {
  caret-color: rgb(15 23 42 / 0.15) !important;
}

.tw-caret-slate-900\/20 {
  caret-color: rgb(15 23 42 / 0.2) !important;
}

.tw-caret-slate-900\/25 {
  caret-color: rgb(15 23 42 / 0.25) !important;
}

.tw-caret-slate-900\/30 {
  caret-color: rgb(15 23 42 / 0.3) !important;
}

.tw-caret-slate-900\/35 {
  caret-color: rgb(15 23 42 / 0.35) !important;
}

.tw-caret-slate-900\/40 {
  caret-color: rgb(15 23 42 / 0.4) !important;
}

.tw-caret-slate-900\/45 {
  caret-color: rgb(15 23 42 / 0.45) !important;
}

.tw-caret-slate-900\/5 {
  caret-color: rgb(15 23 42 / 0.05) !important;
}

.tw-caret-slate-900\/50 {
  caret-color: rgb(15 23 42 / 0.5) !important;
}

.tw-caret-slate-900\/55 {
  caret-color: rgb(15 23 42 / 0.55) !important;
}

.tw-caret-slate-900\/60 {
  caret-color: rgb(15 23 42 / 0.6) !important;
}

.tw-caret-slate-900\/65 {
  caret-color: rgb(15 23 42 / 0.65) !important;
}

.tw-caret-slate-900\/70 {
  caret-color: rgb(15 23 42 / 0.7) !important;
}

.tw-caret-slate-900\/75 {
  caret-color: rgb(15 23 42 / 0.75) !important;
}

.tw-caret-slate-900\/80 {
  caret-color: rgb(15 23 42 / 0.8) !important;
}

.tw-caret-slate-900\/85 {
  caret-color: rgb(15 23 42 / 0.85) !important;
}

.tw-caret-slate-900\/90 {
  caret-color: rgb(15 23 42 / 0.9) !important;
}

.tw-caret-slate-900\/95 {
  caret-color: rgb(15 23 42 / 0.95) !important;
}

.tw-caret-slate-950 {
  caret-color: #020617 !important;
}

.tw-caret-slate-950\/0 {
  caret-color: rgb(2 6 23 / 0) !important;
}

.tw-caret-slate-950\/10 {
  caret-color: rgb(2 6 23 / 0.1) !important;
}

.tw-caret-slate-950\/100 {
  caret-color: rgb(2 6 23 / 1) !important;
}

.tw-caret-slate-950\/15 {
  caret-color: rgb(2 6 23 / 0.15) !important;
}

.tw-caret-slate-950\/20 {
  caret-color: rgb(2 6 23 / 0.2) !important;
}

.tw-caret-slate-950\/25 {
  caret-color: rgb(2 6 23 / 0.25) !important;
}

.tw-caret-slate-950\/30 {
  caret-color: rgb(2 6 23 / 0.3) !important;
}

.tw-caret-slate-950\/35 {
  caret-color: rgb(2 6 23 / 0.35) !important;
}

.tw-caret-slate-950\/40 {
  caret-color: rgb(2 6 23 / 0.4) !important;
}

.tw-caret-slate-950\/45 {
  caret-color: rgb(2 6 23 / 0.45) !important;
}

.tw-caret-slate-950\/5 {
  caret-color: rgb(2 6 23 / 0.05) !important;
}

.tw-caret-slate-950\/50 {
  caret-color: rgb(2 6 23 / 0.5) !important;
}

.tw-caret-slate-950\/55 {
  caret-color: rgb(2 6 23 / 0.55) !important;
}

.tw-caret-slate-950\/60 {
  caret-color: rgb(2 6 23 / 0.6) !important;
}

.tw-caret-slate-950\/65 {
  caret-color: rgb(2 6 23 / 0.65) !important;
}

.tw-caret-slate-950\/70 {
  caret-color: rgb(2 6 23 / 0.7) !important;
}

.tw-caret-slate-950\/75 {
  caret-color: rgb(2 6 23 / 0.75) !important;
}

.tw-caret-slate-950\/80 {
  caret-color: rgb(2 6 23 / 0.8) !important;
}

.tw-caret-slate-950\/85 {
  caret-color: rgb(2 6 23 / 0.85) !important;
}

.tw-caret-slate-950\/90 {
  caret-color: rgb(2 6 23 / 0.9) !important;
}

.tw-caret-slate-950\/95 {
  caret-color: rgb(2 6 23 / 0.95) !important;
}

.tw-caret-stone-100 {
  caret-color: #f5f5f4 !important;
}

.tw-caret-stone-100\/0 {
  caret-color: rgb(245 245 244 / 0) !important;
}

.tw-caret-stone-100\/10 {
  caret-color: rgb(245 245 244 / 0.1) !important;
}

.tw-caret-stone-100\/100 {
  caret-color: rgb(245 245 244 / 1) !important;
}

.tw-caret-stone-100\/15 {
  caret-color: rgb(245 245 244 / 0.15) !important;
}

.tw-caret-stone-100\/20 {
  caret-color: rgb(245 245 244 / 0.2) !important;
}

.tw-caret-stone-100\/25 {
  caret-color: rgb(245 245 244 / 0.25) !important;
}

.tw-caret-stone-100\/30 {
  caret-color: rgb(245 245 244 / 0.3) !important;
}

.tw-caret-stone-100\/35 {
  caret-color: rgb(245 245 244 / 0.35) !important;
}

.tw-caret-stone-100\/40 {
  caret-color: rgb(245 245 244 / 0.4) !important;
}

.tw-caret-stone-100\/45 {
  caret-color: rgb(245 245 244 / 0.45) !important;
}

.tw-caret-stone-100\/5 {
  caret-color: rgb(245 245 244 / 0.05) !important;
}

.tw-caret-stone-100\/50 {
  caret-color: rgb(245 245 244 / 0.5) !important;
}

.tw-caret-stone-100\/55 {
  caret-color: rgb(245 245 244 / 0.55) !important;
}

.tw-caret-stone-100\/60 {
  caret-color: rgb(245 245 244 / 0.6) !important;
}

.tw-caret-stone-100\/65 {
  caret-color: rgb(245 245 244 / 0.65) !important;
}

.tw-caret-stone-100\/70 {
  caret-color: rgb(245 245 244 / 0.7) !important;
}

.tw-caret-stone-100\/75 {
  caret-color: rgb(245 245 244 / 0.75) !important;
}

.tw-caret-stone-100\/80 {
  caret-color: rgb(245 245 244 / 0.8) !important;
}

.tw-caret-stone-100\/85 {
  caret-color: rgb(245 245 244 / 0.85) !important;
}

.tw-caret-stone-100\/90 {
  caret-color: rgb(245 245 244 / 0.9) !important;
}

.tw-caret-stone-100\/95 {
  caret-color: rgb(245 245 244 / 0.95) !important;
}

.tw-caret-stone-200 {
  caret-color: #e7e5e4 !important;
}

.tw-caret-stone-200\/0 {
  caret-color: rgb(231 229 228 / 0) !important;
}

.tw-caret-stone-200\/10 {
  caret-color: rgb(231 229 228 / 0.1) !important;
}

.tw-caret-stone-200\/100 {
  caret-color: rgb(231 229 228 / 1) !important;
}

.tw-caret-stone-200\/15 {
  caret-color: rgb(231 229 228 / 0.15) !important;
}

.tw-caret-stone-200\/20 {
  caret-color: rgb(231 229 228 / 0.2) !important;
}

.tw-caret-stone-200\/25 {
  caret-color: rgb(231 229 228 / 0.25) !important;
}

.tw-caret-stone-200\/30 {
  caret-color: rgb(231 229 228 / 0.3) !important;
}

.tw-caret-stone-200\/35 {
  caret-color: rgb(231 229 228 / 0.35) !important;
}

.tw-caret-stone-200\/40 {
  caret-color: rgb(231 229 228 / 0.4) !important;
}

.tw-caret-stone-200\/45 {
  caret-color: rgb(231 229 228 / 0.45) !important;
}

.tw-caret-stone-200\/5 {
  caret-color: rgb(231 229 228 / 0.05) !important;
}

.tw-caret-stone-200\/50 {
  caret-color: rgb(231 229 228 / 0.5) !important;
}

.tw-caret-stone-200\/55 {
  caret-color: rgb(231 229 228 / 0.55) !important;
}

.tw-caret-stone-200\/60 {
  caret-color: rgb(231 229 228 / 0.6) !important;
}

.tw-caret-stone-200\/65 {
  caret-color: rgb(231 229 228 / 0.65) !important;
}

.tw-caret-stone-200\/70 {
  caret-color: rgb(231 229 228 / 0.7) !important;
}

.tw-caret-stone-200\/75 {
  caret-color: rgb(231 229 228 / 0.75) !important;
}

.tw-caret-stone-200\/80 {
  caret-color: rgb(231 229 228 / 0.8) !important;
}

.tw-caret-stone-200\/85 {
  caret-color: rgb(231 229 228 / 0.85) !important;
}

.tw-caret-stone-200\/90 {
  caret-color: rgb(231 229 228 / 0.9) !important;
}

.tw-caret-stone-200\/95 {
  caret-color: rgb(231 229 228 / 0.95) !important;
}

.tw-caret-stone-300 {
  caret-color: #d6d3d1 !important;
}

.tw-caret-stone-300\/0 {
  caret-color: rgb(214 211 209 / 0) !important;
}

.tw-caret-stone-300\/10 {
  caret-color: rgb(214 211 209 / 0.1) !important;
}

.tw-caret-stone-300\/100 {
  caret-color: rgb(214 211 209 / 1) !important;
}

.tw-caret-stone-300\/15 {
  caret-color: rgb(214 211 209 / 0.15) !important;
}

.tw-caret-stone-300\/20 {
  caret-color: rgb(214 211 209 / 0.2) !important;
}

.tw-caret-stone-300\/25 {
  caret-color: rgb(214 211 209 / 0.25) !important;
}

.tw-caret-stone-300\/30 {
  caret-color: rgb(214 211 209 / 0.3) !important;
}

.tw-caret-stone-300\/35 {
  caret-color: rgb(214 211 209 / 0.35) !important;
}

.tw-caret-stone-300\/40 {
  caret-color: rgb(214 211 209 / 0.4) !important;
}

.tw-caret-stone-300\/45 {
  caret-color: rgb(214 211 209 / 0.45) !important;
}

.tw-caret-stone-300\/5 {
  caret-color: rgb(214 211 209 / 0.05) !important;
}

.tw-caret-stone-300\/50 {
  caret-color: rgb(214 211 209 / 0.5) !important;
}

.tw-caret-stone-300\/55 {
  caret-color: rgb(214 211 209 / 0.55) !important;
}

.tw-caret-stone-300\/60 {
  caret-color: rgb(214 211 209 / 0.6) !important;
}

.tw-caret-stone-300\/65 {
  caret-color: rgb(214 211 209 / 0.65) !important;
}

.tw-caret-stone-300\/70 {
  caret-color: rgb(214 211 209 / 0.7) !important;
}

.tw-caret-stone-300\/75 {
  caret-color: rgb(214 211 209 / 0.75) !important;
}

.tw-caret-stone-300\/80 {
  caret-color: rgb(214 211 209 / 0.8) !important;
}

.tw-caret-stone-300\/85 {
  caret-color: rgb(214 211 209 / 0.85) !important;
}

.tw-caret-stone-300\/90 {
  caret-color: rgb(214 211 209 / 0.9) !important;
}

.tw-caret-stone-300\/95 {
  caret-color: rgb(214 211 209 / 0.95) !important;
}

.tw-caret-stone-400 {
  caret-color: #a8a29e !important;
}

.tw-caret-stone-400\/0 {
  caret-color: rgb(168 162 158 / 0) !important;
}

.tw-caret-stone-400\/10 {
  caret-color: rgb(168 162 158 / 0.1) !important;
}

.tw-caret-stone-400\/100 {
  caret-color: rgb(168 162 158 / 1) !important;
}

.tw-caret-stone-400\/15 {
  caret-color: rgb(168 162 158 / 0.15) !important;
}

.tw-caret-stone-400\/20 {
  caret-color: rgb(168 162 158 / 0.2) !important;
}

.tw-caret-stone-400\/25 {
  caret-color: rgb(168 162 158 / 0.25) !important;
}

.tw-caret-stone-400\/30 {
  caret-color: rgb(168 162 158 / 0.3) !important;
}

.tw-caret-stone-400\/35 {
  caret-color: rgb(168 162 158 / 0.35) !important;
}

.tw-caret-stone-400\/40 {
  caret-color: rgb(168 162 158 / 0.4) !important;
}

.tw-caret-stone-400\/45 {
  caret-color: rgb(168 162 158 / 0.45) !important;
}

.tw-caret-stone-400\/5 {
  caret-color: rgb(168 162 158 / 0.05) !important;
}

.tw-caret-stone-400\/50 {
  caret-color: rgb(168 162 158 / 0.5) !important;
}

.tw-caret-stone-400\/55 {
  caret-color: rgb(168 162 158 / 0.55) !important;
}

.tw-caret-stone-400\/60 {
  caret-color: rgb(168 162 158 / 0.6) !important;
}

.tw-caret-stone-400\/65 {
  caret-color: rgb(168 162 158 / 0.65) !important;
}

.tw-caret-stone-400\/70 {
  caret-color: rgb(168 162 158 / 0.7) !important;
}

.tw-caret-stone-400\/75 {
  caret-color: rgb(168 162 158 / 0.75) !important;
}

.tw-caret-stone-400\/80 {
  caret-color: rgb(168 162 158 / 0.8) !important;
}

.tw-caret-stone-400\/85 {
  caret-color: rgb(168 162 158 / 0.85) !important;
}

.tw-caret-stone-400\/90 {
  caret-color: rgb(168 162 158 / 0.9) !important;
}

.tw-caret-stone-400\/95 {
  caret-color: rgb(168 162 158 / 0.95) !important;
}

.tw-caret-stone-50 {
  caret-color: #fafaf9 !important;
}

.tw-caret-stone-50\/0 {
  caret-color: rgb(250 250 249 / 0) !important;
}

.tw-caret-stone-50\/10 {
  caret-color: rgb(250 250 249 / 0.1) !important;
}

.tw-caret-stone-50\/100 {
  caret-color: rgb(250 250 249 / 1) !important;
}

.tw-caret-stone-50\/15 {
  caret-color: rgb(250 250 249 / 0.15) !important;
}

.tw-caret-stone-50\/20 {
  caret-color: rgb(250 250 249 / 0.2) !important;
}

.tw-caret-stone-50\/25 {
  caret-color: rgb(250 250 249 / 0.25) !important;
}

.tw-caret-stone-50\/30 {
  caret-color: rgb(250 250 249 / 0.3) !important;
}

.tw-caret-stone-50\/35 {
  caret-color: rgb(250 250 249 / 0.35) !important;
}

.tw-caret-stone-50\/40 {
  caret-color: rgb(250 250 249 / 0.4) !important;
}

.tw-caret-stone-50\/45 {
  caret-color: rgb(250 250 249 / 0.45) !important;
}

.tw-caret-stone-50\/5 {
  caret-color: rgb(250 250 249 / 0.05) !important;
}

.tw-caret-stone-50\/50 {
  caret-color: rgb(250 250 249 / 0.5) !important;
}

.tw-caret-stone-50\/55 {
  caret-color: rgb(250 250 249 / 0.55) !important;
}

.tw-caret-stone-50\/60 {
  caret-color: rgb(250 250 249 / 0.6) !important;
}

.tw-caret-stone-50\/65 {
  caret-color: rgb(250 250 249 / 0.65) !important;
}

.tw-caret-stone-50\/70 {
  caret-color: rgb(250 250 249 / 0.7) !important;
}

.tw-caret-stone-50\/75 {
  caret-color: rgb(250 250 249 / 0.75) !important;
}

.tw-caret-stone-50\/80 {
  caret-color: rgb(250 250 249 / 0.8) !important;
}

.tw-caret-stone-50\/85 {
  caret-color: rgb(250 250 249 / 0.85) !important;
}

.tw-caret-stone-50\/90 {
  caret-color: rgb(250 250 249 / 0.9) !important;
}

.tw-caret-stone-50\/95 {
  caret-color: rgb(250 250 249 / 0.95) !important;
}

.tw-caret-stone-500 {
  caret-color: #78716c !important;
}

.tw-caret-stone-500\/0 {
  caret-color: rgb(120 113 108 / 0) !important;
}

.tw-caret-stone-500\/10 {
  caret-color: rgb(120 113 108 / 0.1) !important;
}

.tw-caret-stone-500\/100 {
  caret-color: rgb(120 113 108 / 1) !important;
}

.tw-caret-stone-500\/15 {
  caret-color: rgb(120 113 108 / 0.15) !important;
}

.tw-caret-stone-500\/20 {
  caret-color: rgb(120 113 108 / 0.2) !important;
}

.tw-caret-stone-500\/25 {
  caret-color: rgb(120 113 108 / 0.25) !important;
}

.tw-caret-stone-500\/30 {
  caret-color: rgb(120 113 108 / 0.3) !important;
}

.tw-caret-stone-500\/35 {
  caret-color: rgb(120 113 108 / 0.35) !important;
}

.tw-caret-stone-500\/40 {
  caret-color: rgb(120 113 108 / 0.4) !important;
}

.tw-caret-stone-500\/45 {
  caret-color: rgb(120 113 108 / 0.45) !important;
}

.tw-caret-stone-500\/5 {
  caret-color: rgb(120 113 108 / 0.05) !important;
}

.tw-caret-stone-500\/50 {
  caret-color: rgb(120 113 108 / 0.5) !important;
}

.tw-caret-stone-500\/55 {
  caret-color: rgb(120 113 108 / 0.55) !important;
}

.tw-caret-stone-500\/60 {
  caret-color: rgb(120 113 108 / 0.6) !important;
}

.tw-caret-stone-500\/65 {
  caret-color: rgb(120 113 108 / 0.65) !important;
}

.tw-caret-stone-500\/70 {
  caret-color: rgb(120 113 108 / 0.7) !important;
}

.tw-caret-stone-500\/75 {
  caret-color: rgb(120 113 108 / 0.75) !important;
}

.tw-caret-stone-500\/80 {
  caret-color: rgb(120 113 108 / 0.8) !important;
}

.tw-caret-stone-500\/85 {
  caret-color: rgb(120 113 108 / 0.85) !important;
}

.tw-caret-stone-500\/90 {
  caret-color: rgb(120 113 108 / 0.9) !important;
}

.tw-caret-stone-500\/95 {
  caret-color: rgb(120 113 108 / 0.95) !important;
}

.tw-caret-stone-600 {
  caret-color: #57534e !important;
}

.tw-caret-stone-600\/0 {
  caret-color: rgb(87 83 78 / 0) !important;
}

.tw-caret-stone-600\/10 {
  caret-color: rgb(87 83 78 / 0.1) !important;
}

.tw-caret-stone-600\/100 {
  caret-color: rgb(87 83 78 / 1) !important;
}

.tw-caret-stone-600\/15 {
  caret-color: rgb(87 83 78 / 0.15) !important;
}

.tw-caret-stone-600\/20 {
  caret-color: rgb(87 83 78 / 0.2) !important;
}

.tw-caret-stone-600\/25 {
  caret-color: rgb(87 83 78 / 0.25) !important;
}

.tw-caret-stone-600\/30 {
  caret-color: rgb(87 83 78 / 0.3) !important;
}

.tw-caret-stone-600\/35 {
  caret-color: rgb(87 83 78 / 0.35) !important;
}

.tw-caret-stone-600\/40 {
  caret-color: rgb(87 83 78 / 0.4) !important;
}

.tw-caret-stone-600\/45 {
  caret-color: rgb(87 83 78 / 0.45) !important;
}

.tw-caret-stone-600\/5 {
  caret-color: rgb(87 83 78 / 0.05) !important;
}

.tw-caret-stone-600\/50 {
  caret-color: rgb(87 83 78 / 0.5) !important;
}

.tw-caret-stone-600\/55 {
  caret-color: rgb(87 83 78 / 0.55) !important;
}

.tw-caret-stone-600\/60 {
  caret-color: rgb(87 83 78 / 0.6) !important;
}

.tw-caret-stone-600\/65 {
  caret-color: rgb(87 83 78 / 0.65) !important;
}

.tw-caret-stone-600\/70 {
  caret-color: rgb(87 83 78 / 0.7) !important;
}

.tw-caret-stone-600\/75 {
  caret-color: rgb(87 83 78 / 0.75) !important;
}

.tw-caret-stone-600\/80 {
  caret-color: rgb(87 83 78 / 0.8) !important;
}

.tw-caret-stone-600\/85 {
  caret-color: rgb(87 83 78 / 0.85) !important;
}

.tw-caret-stone-600\/90 {
  caret-color: rgb(87 83 78 / 0.9) !important;
}

.tw-caret-stone-600\/95 {
  caret-color: rgb(87 83 78 / 0.95) !important;
}

.tw-caret-stone-700 {
  caret-color: #44403c !important;
}

.tw-caret-stone-700\/0 {
  caret-color: rgb(68 64 60 / 0) !important;
}

.tw-caret-stone-700\/10 {
  caret-color: rgb(68 64 60 / 0.1) !important;
}

.tw-caret-stone-700\/100 {
  caret-color: rgb(68 64 60 / 1) !important;
}

.tw-caret-stone-700\/15 {
  caret-color: rgb(68 64 60 / 0.15) !important;
}

.tw-caret-stone-700\/20 {
  caret-color: rgb(68 64 60 / 0.2) !important;
}

.tw-caret-stone-700\/25 {
  caret-color: rgb(68 64 60 / 0.25) !important;
}

.tw-caret-stone-700\/30 {
  caret-color: rgb(68 64 60 / 0.3) !important;
}

.tw-caret-stone-700\/35 {
  caret-color: rgb(68 64 60 / 0.35) !important;
}

.tw-caret-stone-700\/40 {
  caret-color: rgb(68 64 60 / 0.4) !important;
}

.tw-caret-stone-700\/45 {
  caret-color: rgb(68 64 60 / 0.45) !important;
}

.tw-caret-stone-700\/5 {
  caret-color: rgb(68 64 60 / 0.05) !important;
}

.tw-caret-stone-700\/50 {
  caret-color: rgb(68 64 60 / 0.5) !important;
}

.tw-caret-stone-700\/55 {
  caret-color: rgb(68 64 60 / 0.55) !important;
}

.tw-caret-stone-700\/60 {
  caret-color: rgb(68 64 60 / 0.6) !important;
}

.tw-caret-stone-700\/65 {
  caret-color: rgb(68 64 60 / 0.65) !important;
}

.tw-caret-stone-700\/70 {
  caret-color: rgb(68 64 60 / 0.7) !important;
}

.tw-caret-stone-700\/75 {
  caret-color: rgb(68 64 60 / 0.75) !important;
}

.tw-caret-stone-700\/80 {
  caret-color: rgb(68 64 60 / 0.8) !important;
}

.tw-caret-stone-700\/85 {
  caret-color: rgb(68 64 60 / 0.85) !important;
}

.tw-caret-stone-700\/90 {
  caret-color: rgb(68 64 60 / 0.9) !important;
}

.tw-caret-stone-700\/95 {
  caret-color: rgb(68 64 60 / 0.95) !important;
}

.tw-caret-stone-800 {
  caret-color: #292524 !important;
}

.tw-caret-stone-800\/0 {
  caret-color: rgb(41 37 36 / 0) !important;
}

.tw-caret-stone-800\/10 {
  caret-color: rgb(41 37 36 / 0.1) !important;
}

.tw-caret-stone-800\/100 {
  caret-color: rgb(41 37 36 / 1) !important;
}

.tw-caret-stone-800\/15 {
  caret-color: rgb(41 37 36 / 0.15) !important;
}

.tw-caret-stone-800\/20 {
  caret-color: rgb(41 37 36 / 0.2) !important;
}

.tw-caret-stone-800\/25 {
  caret-color: rgb(41 37 36 / 0.25) !important;
}

.tw-caret-stone-800\/30 {
  caret-color: rgb(41 37 36 / 0.3) !important;
}

.tw-caret-stone-800\/35 {
  caret-color: rgb(41 37 36 / 0.35) !important;
}

.tw-caret-stone-800\/40 {
  caret-color: rgb(41 37 36 / 0.4) !important;
}

.tw-caret-stone-800\/45 {
  caret-color: rgb(41 37 36 / 0.45) !important;
}

.tw-caret-stone-800\/5 {
  caret-color: rgb(41 37 36 / 0.05) !important;
}

.tw-caret-stone-800\/50 {
  caret-color: rgb(41 37 36 / 0.5) !important;
}

.tw-caret-stone-800\/55 {
  caret-color: rgb(41 37 36 / 0.55) !important;
}

.tw-caret-stone-800\/60 {
  caret-color: rgb(41 37 36 / 0.6) !important;
}

.tw-caret-stone-800\/65 {
  caret-color: rgb(41 37 36 / 0.65) !important;
}

.tw-caret-stone-800\/70 {
  caret-color: rgb(41 37 36 / 0.7) !important;
}

.tw-caret-stone-800\/75 {
  caret-color: rgb(41 37 36 / 0.75) !important;
}

.tw-caret-stone-800\/80 {
  caret-color: rgb(41 37 36 / 0.8) !important;
}

.tw-caret-stone-800\/85 {
  caret-color: rgb(41 37 36 / 0.85) !important;
}

.tw-caret-stone-800\/90 {
  caret-color: rgb(41 37 36 / 0.9) !important;
}

.tw-caret-stone-800\/95 {
  caret-color: rgb(41 37 36 / 0.95) !important;
}

.tw-caret-stone-900 {
  caret-color: #1c1917 !important;
}

.tw-caret-stone-900\/0 {
  caret-color: rgb(28 25 23 / 0) !important;
}

.tw-caret-stone-900\/10 {
  caret-color: rgb(28 25 23 / 0.1) !important;
}

.tw-caret-stone-900\/100 {
  caret-color: rgb(28 25 23 / 1) !important;
}

.tw-caret-stone-900\/15 {
  caret-color: rgb(28 25 23 / 0.15) !important;
}

.tw-caret-stone-900\/20 {
  caret-color: rgb(28 25 23 / 0.2) !important;
}

.tw-caret-stone-900\/25 {
  caret-color: rgb(28 25 23 / 0.25) !important;
}

.tw-caret-stone-900\/30 {
  caret-color: rgb(28 25 23 / 0.3) !important;
}

.tw-caret-stone-900\/35 {
  caret-color: rgb(28 25 23 / 0.35) !important;
}

.tw-caret-stone-900\/40 {
  caret-color: rgb(28 25 23 / 0.4) !important;
}

.tw-caret-stone-900\/45 {
  caret-color: rgb(28 25 23 / 0.45) !important;
}

.tw-caret-stone-900\/5 {
  caret-color: rgb(28 25 23 / 0.05) !important;
}

.tw-caret-stone-900\/50 {
  caret-color: rgb(28 25 23 / 0.5) !important;
}

.tw-caret-stone-900\/55 {
  caret-color: rgb(28 25 23 / 0.55) !important;
}

.tw-caret-stone-900\/60 {
  caret-color: rgb(28 25 23 / 0.6) !important;
}

.tw-caret-stone-900\/65 {
  caret-color: rgb(28 25 23 / 0.65) !important;
}

.tw-caret-stone-900\/70 {
  caret-color: rgb(28 25 23 / 0.7) !important;
}

.tw-caret-stone-900\/75 {
  caret-color: rgb(28 25 23 / 0.75) !important;
}

.tw-caret-stone-900\/80 {
  caret-color: rgb(28 25 23 / 0.8) !important;
}

.tw-caret-stone-900\/85 {
  caret-color: rgb(28 25 23 / 0.85) !important;
}

.tw-caret-stone-900\/90 {
  caret-color: rgb(28 25 23 / 0.9) !important;
}

.tw-caret-stone-900\/95 {
  caret-color: rgb(28 25 23 / 0.95) !important;
}

.tw-caret-stone-950 {
  caret-color: #0c0a09 !important;
}

.tw-caret-stone-950\/0 {
  caret-color: rgb(12 10 9 / 0) !important;
}

.tw-caret-stone-950\/10 {
  caret-color: rgb(12 10 9 / 0.1) !important;
}

.tw-caret-stone-950\/100 {
  caret-color: rgb(12 10 9 / 1) !important;
}

.tw-caret-stone-950\/15 {
  caret-color: rgb(12 10 9 / 0.15) !important;
}

.tw-caret-stone-950\/20 {
  caret-color: rgb(12 10 9 / 0.2) !important;
}

.tw-caret-stone-950\/25 {
  caret-color: rgb(12 10 9 / 0.25) !important;
}

.tw-caret-stone-950\/30 {
  caret-color: rgb(12 10 9 / 0.3) !important;
}

.tw-caret-stone-950\/35 {
  caret-color: rgb(12 10 9 / 0.35) !important;
}

.tw-caret-stone-950\/40 {
  caret-color: rgb(12 10 9 / 0.4) !important;
}

.tw-caret-stone-950\/45 {
  caret-color: rgb(12 10 9 / 0.45) !important;
}

.tw-caret-stone-950\/5 {
  caret-color: rgb(12 10 9 / 0.05) !important;
}

.tw-caret-stone-950\/50 {
  caret-color: rgb(12 10 9 / 0.5) !important;
}

.tw-caret-stone-950\/55 {
  caret-color: rgb(12 10 9 / 0.55) !important;
}

.tw-caret-stone-950\/60 {
  caret-color: rgb(12 10 9 / 0.6) !important;
}

.tw-caret-stone-950\/65 {
  caret-color: rgb(12 10 9 / 0.65) !important;
}

.tw-caret-stone-950\/70 {
  caret-color: rgb(12 10 9 / 0.7) !important;
}

.tw-caret-stone-950\/75 {
  caret-color: rgb(12 10 9 / 0.75) !important;
}

.tw-caret-stone-950\/80 {
  caret-color: rgb(12 10 9 / 0.8) !important;
}

.tw-caret-stone-950\/85 {
  caret-color: rgb(12 10 9 / 0.85) !important;
}

.tw-caret-stone-950\/90 {
  caret-color: rgb(12 10 9 / 0.9) !important;
}

.tw-caret-stone-950\/95 {
  caret-color: rgb(12 10 9 / 0.95) !important;
}

.tw-caret-teal-100 {
  caret-color: #ccfbf1 !important;
}

.tw-caret-teal-100\/0 {
  caret-color: rgb(204 251 241 / 0) !important;
}

.tw-caret-teal-100\/10 {
  caret-color: rgb(204 251 241 / 0.1) !important;
}

.tw-caret-teal-100\/100 {
  caret-color: rgb(204 251 241 / 1) !important;
}

.tw-caret-teal-100\/15 {
  caret-color: rgb(204 251 241 / 0.15) !important;
}

.tw-caret-teal-100\/20 {
  caret-color: rgb(204 251 241 / 0.2) !important;
}

.tw-caret-teal-100\/25 {
  caret-color: rgb(204 251 241 / 0.25) !important;
}

.tw-caret-teal-100\/30 {
  caret-color: rgb(204 251 241 / 0.3) !important;
}

.tw-caret-teal-100\/35 {
  caret-color: rgb(204 251 241 / 0.35) !important;
}

.tw-caret-teal-100\/40 {
  caret-color: rgb(204 251 241 / 0.4) !important;
}

.tw-caret-teal-100\/45 {
  caret-color: rgb(204 251 241 / 0.45) !important;
}

.tw-caret-teal-100\/5 {
  caret-color: rgb(204 251 241 / 0.05) !important;
}

.tw-caret-teal-100\/50 {
  caret-color: rgb(204 251 241 / 0.5) !important;
}

.tw-caret-teal-100\/55 {
  caret-color: rgb(204 251 241 / 0.55) !important;
}

.tw-caret-teal-100\/60 {
  caret-color: rgb(204 251 241 / 0.6) !important;
}

.tw-caret-teal-100\/65 {
  caret-color: rgb(204 251 241 / 0.65) !important;
}

.tw-caret-teal-100\/70 {
  caret-color: rgb(204 251 241 / 0.7) !important;
}

.tw-caret-teal-100\/75 {
  caret-color: rgb(204 251 241 / 0.75) !important;
}

.tw-caret-teal-100\/80 {
  caret-color: rgb(204 251 241 / 0.8) !important;
}

.tw-caret-teal-100\/85 {
  caret-color: rgb(204 251 241 / 0.85) !important;
}

.tw-caret-teal-100\/90 {
  caret-color: rgb(204 251 241 / 0.9) !important;
}

.tw-caret-teal-100\/95 {
  caret-color: rgb(204 251 241 / 0.95) !important;
}

.tw-caret-teal-200 {
  caret-color: #99f6e4 !important;
}

.tw-caret-teal-200\/0 {
  caret-color: rgb(153 246 228 / 0) !important;
}

.tw-caret-teal-200\/10 {
  caret-color: rgb(153 246 228 / 0.1) !important;
}

.tw-caret-teal-200\/100 {
  caret-color: rgb(153 246 228 / 1) !important;
}

.tw-caret-teal-200\/15 {
  caret-color: rgb(153 246 228 / 0.15) !important;
}

.tw-caret-teal-200\/20 {
  caret-color: rgb(153 246 228 / 0.2) !important;
}

.tw-caret-teal-200\/25 {
  caret-color: rgb(153 246 228 / 0.25) !important;
}

.tw-caret-teal-200\/30 {
  caret-color: rgb(153 246 228 / 0.3) !important;
}

.tw-caret-teal-200\/35 {
  caret-color: rgb(153 246 228 / 0.35) !important;
}

.tw-caret-teal-200\/40 {
  caret-color: rgb(153 246 228 / 0.4) !important;
}

.tw-caret-teal-200\/45 {
  caret-color: rgb(153 246 228 / 0.45) !important;
}

.tw-caret-teal-200\/5 {
  caret-color: rgb(153 246 228 / 0.05) !important;
}

.tw-caret-teal-200\/50 {
  caret-color: rgb(153 246 228 / 0.5) !important;
}

.tw-caret-teal-200\/55 {
  caret-color: rgb(153 246 228 / 0.55) !important;
}

.tw-caret-teal-200\/60 {
  caret-color: rgb(153 246 228 / 0.6) !important;
}

.tw-caret-teal-200\/65 {
  caret-color: rgb(153 246 228 / 0.65) !important;
}

.tw-caret-teal-200\/70 {
  caret-color: rgb(153 246 228 / 0.7) !important;
}

.tw-caret-teal-200\/75 {
  caret-color: rgb(153 246 228 / 0.75) !important;
}

.tw-caret-teal-200\/80 {
  caret-color: rgb(153 246 228 / 0.8) !important;
}

.tw-caret-teal-200\/85 {
  caret-color: rgb(153 246 228 / 0.85) !important;
}

.tw-caret-teal-200\/90 {
  caret-color: rgb(153 246 228 / 0.9) !important;
}

.tw-caret-teal-200\/95 {
  caret-color: rgb(153 246 228 / 0.95) !important;
}

.tw-caret-teal-300 {
  caret-color: #5eead4 !important;
}

.tw-caret-teal-300\/0 {
  caret-color: rgb(94 234 212 / 0) !important;
}

.tw-caret-teal-300\/10 {
  caret-color: rgb(94 234 212 / 0.1) !important;
}

.tw-caret-teal-300\/100 {
  caret-color: rgb(94 234 212 / 1) !important;
}

.tw-caret-teal-300\/15 {
  caret-color: rgb(94 234 212 / 0.15) !important;
}

.tw-caret-teal-300\/20 {
  caret-color: rgb(94 234 212 / 0.2) !important;
}

.tw-caret-teal-300\/25 {
  caret-color: rgb(94 234 212 / 0.25) !important;
}

.tw-caret-teal-300\/30 {
  caret-color: rgb(94 234 212 / 0.3) !important;
}

.tw-caret-teal-300\/35 {
  caret-color: rgb(94 234 212 / 0.35) !important;
}

.tw-caret-teal-300\/40 {
  caret-color: rgb(94 234 212 / 0.4) !important;
}

.tw-caret-teal-300\/45 {
  caret-color: rgb(94 234 212 / 0.45) !important;
}

.tw-caret-teal-300\/5 {
  caret-color: rgb(94 234 212 / 0.05) !important;
}

.tw-caret-teal-300\/50 {
  caret-color: rgb(94 234 212 / 0.5) !important;
}

.tw-caret-teal-300\/55 {
  caret-color: rgb(94 234 212 / 0.55) !important;
}

.tw-caret-teal-300\/60 {
  caret-color: rgb(94 234 212 / 0.6) !important;
}

.tw-caret-teal-300\/65 {
  caret-color: rgb(94 234 212 / 0.65) !important;
}

.tw-caret-teal-300\/70 {
  caret-color: rgb(94 234 212 / 0.7) !important;
}

.tw-caret-teal-300\/75 {
  caret-color: rgb(94 234 212 / 0.75) !important;
}

.tw-caret-teal-300\/80 {
  caret-color: rgb(94 234 212 / 0.8) !important;
}

.tw-caret-teal-300\/85 {
  caret-color: rgb(94 234 212 / 0.85) !important;
}

.tw-caret-teal-300\/90 {
  caret-color: rgb(94 234 212 / 0.9) !important;
}

.tw-caret-teal-300\/95 {
  caret-color: rgb(94 234 212 / 0.95) !important;
}

.tw-caret-teal-400 {
  caret-color: #2dd4bf !important;
}

.tw-caret-teal-400\/0 {
  caret-color: rgb(45 212 191 / 0) !important;
}

.tw-caret-teal-400\/10 {
  caret-color: rgb(45 212 191 / 0.1) !important;
}

.tw-caret-teal-400\/100 {
  caret-color: rgb(45 212 191 / 1) !important;
}

.tw-caret-teal-400\/15 {
  caret-color: rgb(45 212 191 / 0.15) !important;
}

.tw-caret-teal-400\/20 {
  caret-color: rgb(45 212 191 / 0.2) !important;
}

.tw-caret-teal-400\/25 {
  caret-color: rgb(45 212 191 / 0.25) !important;
}

.tw-caret-teal-400\/30 {
  caret-color: rgb(45 212 191 / 0.3) !important;
}

.tw-caret-teal-400\/35 {
  caret-color: rgb(45 212 191 / 0.35) !important;
}

.tw-caret-teal-400\/40 {
  caret-color: rgb(45 212 191 / 0.4) !important;
}

.tw-caret-teal-400\/45 {
  caret-color: rgb(45 212 191 / 0.45) !important;
}

.tw-caret-teal-400\/5 {
  caret-color: rgb(45 212 191 / 0.05) !important;
}

.tw-caret-teal-400\/50 {
  caret-color: rgb(45 212 191 / 0.5) !important;
}

.tw-caret-teal-400\/55 {
  caret-color: rgb(45 212 191 / 0.55) !important;
}

.tw-caret-teal-400\/60 {
  caret-color: rgb(45 212 191 / 0.6) !important;
}

.tw-caret-teal-400\/65 {
  caret-color: rgb(45 212 191 / 0.65) !important;
}

.tw-caret-teal-400\/70 {
  caret-color: rgb(45 212 191 / 0.7) !important;
}

.tw-caret-teal-400\/75 {
  caret-color: rgb(45 212 191 / 0.75) !important;
}

.tw-caret-teal-400\/80 {
  caret-color: rgb(45 212 191 / 0.8) !important;
}

.tw-caret-teal-400\/85 {
  caret-color: rgb(45 212 191 / 0.85) !important;
}

.tw-caret-teal-400\/90 {
  caret-color: rgb(45 212 191 / 0.9) !important;
}

.tw-caret-teal-400\/95 {
  caret-color: rgb(45 212 191 / 0.95) !important;
}

.tw-caret-teal-50 {
  caret-color: #f0fdfa !important;
}

.tw-caret-teal-50\/0 {
  caret-color: rgb(240 253 250 / 0) !important;
}

.tw-caret-teal-50\/10 {
  caret-color: rgb(240 253 250 / 0.1) !important;
}

.tw-caret-teal-50\/100 {
  caret-color: rgb(240 253 250 / 1) !important;
}

.tw-caret-teal-50\/15 {
  caret-color: rgb(240 253 250 / 0.15) !important;
}

.tw-caret-teal-50\/20 {
  caret-color: rgb(240 253 250 / 0.2) !important;
}

.tw-caret-teal-50\/25 {
  caret-color: rgb(240 253 250 / 0.25) !important;
}

.tw-caret-teal-50\/30 {
  caret-color: rgb(240 253 250 / 0.3) !important;
}

.tw-caret-teal-50\/35 {
  caret-color: rgb(240 253 250 / 0.35) !important;
}

.tw-caret-teal-50\/40 {
  caret-color: rgb(240 253 250 / 0.4) !important;
}

.tw-caret-teal-50\/45 {
  caret-color: rgb(240 253 250 / 0.45) !important;
}

.tw-caret-teal-50\/5 {
  caret-color: rgb(240 253 250 / 0.05) !important;
}

.tw-caret-teal-50\/50 {
  caret-color: rgb(240 253 250 / 0.5) !important;
}

.tw-caret-teal-50\/55 {
  caret-color: rgb(240 253 250 / 0.55) !important;
}

.tw-caret-teal-50\/60 {
  caret-color: rgb(240 253 250 / 0.6) !important;
}

.tw-caret-teal-50\/65 {
  caret-color: rgb(240 253 250 / 0.65) !important;
}

.tw-caret-teal-50\/70 {
  caret-color: rgb(240 253 250 / 0.7) !important;
}

.tw-caret-teal-50\/75 {
  caret-color: rgb(240 253 250 / 0.75) !important;
}

.tw-caret-teal-50\/80 {
  caret-color: rgb(240 253 250 / 0.8) !important;
}

.tw-caret-teal-50\/85 {
  caret-color: rgb(240 253 250 / 0.85) !important;
}

.tw-caret-teal-50\/90 {
  caret-color: rgb(240 253 250 / 0.9) !important;
}

.tw-caret-teal-50\/95 {
  caret-color: rgb(240 253 250 / 0.95) !important;
}

.tw-caret-teal-500 {
  caret-color: #14b8a6 !important;
}

.tw-caret-teal-500\/0 {
  caret-color: rgb(20 184 166 / 0) !important;
}

.tw-caret-teal-500\/10 {
  caret-color: rgb(20 184 166 / 0.1) !important;
}

.tw-caret-teal-500\/100 {
  caret-color: rgb(20 184 166 / 1) !important;
}

.tw-caret-teal-500\/15 {
  caret-color: rgb(20 184 166 / 0.15) !important;
}

.tw-caret-teal-500\/20 {
  caret-color: rgb(20 184 166 / 0.2) !important;
}

.tw-caret-teal-500\/25 {
  caret-color: rgb(20 184 166 / 0.25) !important;
}

.tw-caret-teal-500\/30 {
  caret-color: rgb(20 184 166 / 0.3) !important;
}

.tw-caret-teal-500\/35 {
  caret-color: rgb(20 184 166 / 0.35) !important;
}

.tw-caret-teal-500\/40 {
  caret-color: rgb(20 184 166 / 0.4) !important;
}

.tw-caret-teal-500\/45 {
  caret-color: rgb(20 184 166 / 0.45) !important;
}

.tw-caret-teal-500\/5 {
  caret-color: rgb(20 184 166 / 0.05) !important;
}

.tw-caret-teal-500\/50 {
  caret-color: rgb(20 184 166 / 0.5) !important;
}

.tw-caret-teal-500\/55 {
  caret-color: rgb(20 184 166 / 0.55) !important;
}

.tw-caret-teal-500\/60 {
  caret-color: rgb(20 184 166 / 0.6) !important;
}

.tw-caret-teal-500\/65 {
  caret-color: rgb(20 184 166 / 0.65) !important;
}

.tw-caret-teal-500\/70 {
  caret-color: rgb(20 184 166 / 0.7) !important;
}

.tw-caret-teal-500\/75 {
  caret-color: rgb(20 184 166 / 0.75) !important;
}

.tw-caret-teal-500\/80 {
  caret-color: rgb(20 184 166 / 0.8) !important;
}

.tw-caret-teal-500\/85 {
  caret-color: rgb(20 184 166 / 0.85) !important;
}

.tw-caret-teal-500\/90 {
  caret-color: rgb(20 184 166 / 0.9) !important;
}

.tw-caret-teal-500\/95 {
  caret-color: rgb(20 184 166 / 0.95) !important;
}

.tw-caret-teal-600 {
  caret-color: #0d9488 !important;
}

.tw-caret-teal-600\/0 {
  caret-color: rgb(13 148 136 / 0) !important;
}

.tw-caret-teal-600\/10 {
  caret-color: rgb(13 148 136 / 0.1) !important;
}

.tw-caret-teal-600\/100 {
  caret-color: rgb(13 148 136 / 1) !important;
}

.tw-caret-teal-600\/15 {
  caret-color: rgb(13 148 136 / 0.15) !important;
}

.tw-caret-teal-600\/20 {
  caret-color: rgb(13 148 136 / 0.2) !important;
}

.tw-caret-teal-600\/25 {
  caret-color: rgb(13 148 136 / 0.25) !important;
}

.tw-caret-teal-600\/30 {
  caret-color: rgb(13 148 136 / 0.3) !important;
}

.tw-caret-teal-600\/35 {
  caret-color: rgb(13 148 136 / 0.35) !important;
}

.tw-caret-teal-600\/40 {
  caret-color: rgb(13 148 136 / 0.4) !important;
}

.tw-caret-teal-600\/45 {
  caret-color: rgb(13 148 136 / 0.45) !important;
}

.tw-caret-teal-600\/5 {
  caret-color: rgb(13 148 136 / 0.05) !important;
}

.tw-caret-teal-600\/50 {
  caret-color: rgb(13 148 136 / 0.5) !important;
}

.tw-caret-teal-600\/55 {
  caret-color: rgb(13 148 136 / 0.55) !important;
}

.tw-caret-teal-600\/60 {
  caret-color: rgb(13 148 136 / 0.6) !important;
}

.tw-caret-teal-600\/65 {
  caret-color: rgb(13 148 136 / 0.65) !important;
}

.tw-caret-teal-600\/70 {
  caret-color: rgb(13 148 136 / 0.7) !important;
}

.tw-caret-teal-600\/75 {
  caret-color: rgb(13 148 136 / 0.75) !important;
}

.tw-caret-teal-600\/80 {
  caret-color: rgb(13 148 136 / 0.8) !important;
}

.tw-caret-teal-600\/85 {
  caret-color: rgb(13 148 136 / 0.85) !important;
}

.tw-caret-teal-600\/90 {
  caret-color: rgb(13 148 136 / 0.9) !important;
}

.tw-caret-teal-600\/95 {
  caret-color: rgb(13 148 136 / 0.95) !important;
}

.tw-caret-teal-700 {
  caret-color: #0f766e !important;
}

.tw-caret-teal-700\/0 {
  caret-color: rgb(15 118 110 / 0) !important;
}

.tw-caret-teal-700\/10 {
  caret-color: rgb(15 118 110 / 0.1) !important;
}

.tw-caret-teal-700\/100 {
  caret-color: rgb(15 118 110 / 1) !important;
}

.tw-caret-teal-700\/15 {
  caret-color: rgb(15 118 110 / 0.15) !important;
}

.tw-caret-teal-700\/20 {
  caret-color: rgb(15 118 110 / 0.2) !important;
}

.tw-caret-teal-700\/25 {
  caret-color: rgb(15 118 110 / 0.25) !important;
}

.tw-caret-teal-700\/30 {
  caret-color: rgb(15 118 110 / 0.3) !important;
}

.tw-caret-teal-700\/35 {
  caret-color: rgb(15 118 110 / 0.35) !important;
}

.tw-caret-teal-700\/40 {
  caret-color: rgb(15 118 110 / 0.4) !important;
}

.tw-caret-teal-700\/45 {
  caret-color: rgb(15 118 110 / 0.45) !important;
}

.tw-caret-teal-700\/5 {
  caret-color: rgb(15 118 110 / 0.05) !important;
}

.tw-caret-teal-700\/50 {
  caret-color: rgb(15 118 110 / 0.5) !important;
}

.tw-caret-teal-700\/55 {
  caret-color: rgb(15 118 110 / 0.55) !important;
}

.tw-caret-teal-700\/60 {
  caret-color: rgb(15 118 110 / 0.6) !important;
}

.tw-caret-teal-700\/65 {
  caret-color: rgb(15 118 110 / 0.65) !important;
}

.tw-caret-teal-700\/70 {
  caret-color: rgb(15 118 110 / 0.7) !important;
}

.tw-caret-teal-700\/75 {
  caret-color: rgb(15 118 110 / 0.75) !important;
}

.tw-caret-teal-700\/80 {
  caret-color: rgb(15 118 110 / 0.8) !important;
}

.tw-caret-teal-700\/85 {
  caret-color: rgb(15 118 110 / 0.85) !important;
}

.tw-caret-teal-700\/90 {
  caret-color: rgb(15 118 110 / 0.9) !important;
}

.tw-caret-teal-700\/95 {
  caret-color: rgb(15 118 110 / 0.95) !important;
}

.tw-caret-teal-800 {
  caret-color: #115e59 !important;
}

.tw-caret-teal-800\/0 {
  caret-color: rgb(17 94 89 / 0) !important;
}

.tw-caret-teal-800\/10 {
  caret-color: rgb(17 94 89 / 0.1) !important;
}

.tw-caret-teal-800\/100 {
  caret-color: rgb(17 94 89 / 1) !important;
}

.tw-caret-teal-800\/15 {
  caret-color: rgb(17 94 89 / 0.15) !important;
}

.tw-caret-teal-800\/20 {
  caret-color: rgb(17 94 89 / 0.2) !important;
}

.tw-caret-teal-800\/25 {
  caret-color: rgb(17 94 89 / 0.25) !important;
}

.tw-caret-teal-800\/30 {
  caret-color: rgb(17 94 89 / 0.3) !important;
}

.tw-caret-teal-800\/35 {
  caret-color: rgb(17 94 89 / 0.35) !important;
}

.tw-caret-teal-800\/40 {
  caret-color: rgb(17 94 89 / 0.4) !important;
}

.tw-caret-teal-800\/45 {
  caret-color: rgb(17 94 89 / 0.45) !important;
}

.tw-caret-teal-800\/5 {
  caret-color: rgb(17 94 89 / 0.05) !important;
}

.tw-caret-teal-800\/50 {
  caret-color: rgb(17 94 89 / 0.5) !important;
}

.tw-caret-teal-800\/55 {
  caret-color: rgb(17 94 89 / 0.55) !important;
}

.tw-caret-teal-800\/60 {
  caret-color: rgb(17 94 89 / 0.6) !important;
}

.tw-caret-teal-800\/65 {
  caret-color: rgb(17 94 89 / 0.65) !important;
}

.tw-caret-teal-800\/70 {
  caret-color: rgb(17 94 89 / 0.7) !important;
}

.tw-caret-teal-800\/75 {
  caret-color: rgb(17 94 89 / 0.75) !important;
}

.tw-caret-teal-800\/80 {
  caret-color: rgb(17 94 89 / 0.8) !important;
}

.tw-caret-teal-800\/85 {
  caret-color: rgb(17 94 89 / 0.85) !important;
}

.tw-caret-teal-800\/90 {
  caret-color: rgb(17 94 89 / 0.9) !important;
}

.tw-caret-teal-800\/95 {
  caret-color: rgb(17 94 89 / 0.95) !important;
}

.tw-caret-teal-900 {
  caret-color: #134e4a !important;
}

.tw-caret-teal-900\/0 {
  caret-color: rgb(19 78 74 / 0) !important;
}

.tw-caret-teal-900\/10 {
  caret-color: rgb(19 78 74 / 0.1) !important;
}

.tw-caret-teal-900\/100 {
  caret-color: rgb(19 78 74 / 1) !important;
}

.tw-caret-teal-900\/15 {
  caret-color: rgb(19 78 74 / 0.15) !important;
}

.tw-caret-teal-900\/20 {
  caret-color: rgb(19 78 74 / 0.2) !important;
}

.tw-caret-teal-900\/25 {
  caret-color: rgb(19 78 74 / 0.25) !important;
}

.tw-caret-teal-900\/30 {
  caret-color: rgb(19 78 74 / 0.3) !important;
}

.tw-caret-teal-900\/35 {
  caret-color: rgb(19 78 74 / 0.35) !important;
}

.tw-caret-teal-900\/40 {
  caret-color: rgb(19 78 74 / 0.4) !important;
}

.tw-caret-teal-900\/45 {
  caret-color: rgb(19 78 74 / 0.45) !important;
}

.tw-caret-teal-900\/5 {
  caret-color: rgb(19 78 74 / 0.05) !important;
}

.tw-caret-teal-900\/50 {
  caret-color: rgb(19 78 74 / 0.5) !important;
}

.tw-caret-teal-900\/55 {
  caret-color: rgb(19 78 74 / 0.55) !important;
}

.tw-caret-teal-900\/60 {
  caret-color: rgb(19 78 74 / 0.6) !important;
}

.tw-caret-teal-900\/65 {
  caret-color: rgb(19 78 74 / 0.65) !important;
}

.tw-caret-teal-900\/70 {
  caret-color: rgb(19 78 74 / 0.7) !important;
}

.tw-caret-teal-900\/75 {
  caret-color: rgb(19 78 74 / 0.75) !important;
}

.tw-caret-teal-900\/80 {
  caret-color: rgb(19 78 74 / 0.8) !important;
}

.tw-caret-teal-900\/85 {
  caret-color: rgb(19 78 74 / 0.85) !important;
}

.tw-caret-teal-900\/90 {
  caret-color: rgb(19 78 74 / 0.9) !important;
}

.tw-caret-teal-900\/95 {
  caret-color: rgb(19 78 74 / 0.95) !important;
}

.tw-caret-teal-950 {
  caret-color: #042f2e !important;
}

.tw-caret-teal-950\/0 {
  caret-color: rgb(4 47 46 / 0) !important;
}

.tw-caret-teal-950\/10 {
  caret-color: rgb(4 47 46 / 0.1) !important;
}

.tw-caret-teal-950\/100 {
  caret-color: rgb(4 47 46 / 1) !important;
}

.tw-caret-teal-950\/15 {
  caret-color: rgb(4 47 46 / 0.15) !important;
}

.tw-caret-teal-950\/20 {
  caret-color: rgb(4 47 46 / 0.2) !important;
}

.tw-caret-teal-950\/25 {
  caret-color: rgb(4 47 46 / 0.25) !important;
}

.tw-caret-teal-950\/30 {
  caret-color: rgb(4 47 46 / 0.3) !important;
}

.tw-caret-teal-950\/35 {
  caret-color: rgb(4 47 46 / 0.35) !important;
}

.tw-caret-teal-950\/40 {
  caret-color: rgb(4 47 46 / 0.4) !important;
}

.tw-caret-teal-950\/45 {
  caret-color: rgb(4 47 46 / 0.45) !important;
}

.tw-caret-teal-950\/5 {
  caret-color: rgb(4 47 46 / 0.05) !important;
}

.tw-caret-teal-950\/50 {
  caret-color: rgb(4 47 46 / 0.5) !important;
}

.tw-caret-teal-950\/55 {
  caret-color: rgb(4 47 46 / 0.55) !important;
}

.tw-caret-teal-950\/60 {
  caret-color: rgb(4 47 46 / 0.6) !important;
}

.tw-caret-teal-950\/65 {
  caret-color: rgb(4 47 46 / 0.65) !important;
}

.tw-caret-teal-950\/70 {
  caret-color: rgb(4 47 46 / 0.7) !important;
}

.tw-caret-teal-950\/75 {
  caret-color: rgb(4 47 46 / 0.75) !important;
}

.tw-caret-teal-950\/80 {
  caret-color: rgb(4 47 46 / 0.8) !important;
}

.tw-caret-teal-950\/85 {
  caret-color: rgb(4 47 46 / 0.85) !important;
}

.tw-caret-teal-950\/90 {
  caret-color: rgb(4 47 46 / 0.9) !important;
}

.tw-caret-teal-950\/95 {
  caret-color: rgb(4 47 46 / 0.95) !important;
}

.tw-caret-transparent {
  caret-color: transparent !important;
}

.tw-caret-transparent\/0 {
  caret-color: rgb(0 0 0 / 0) !important;
}

.tw-caret-transparent\/10 {
  caret-color: rgb(0 0 0 / 0.1) !important;
}

.tw-caret-transparent\/100 {
  caret-color: rgb(0 0 0 / 1) !important;
}

.tw-caret-transparent\/15 {
  caret-color: rgb(0 0 0 / 0.15) !important;
}

.tw-caret-transparent\/20 {
  caret-color: rgb(0 0 0 / 0.2) !important;
}

.tw-caret-transparent\/25 {
  caret-color: rgb(0 0 0 / 0.25) !important;
}

.tw-caret-transparent\/30 {
  caret-color: rgb(0 0 0 / 0.3) !important;
}

.tw-caret-transparent\/35 {
  caret-color: rgb(0 0 0 / 0.35) !important;
}

.tw-caret-transparent\/40 {
  caret-color: rgb(0 0 0 / 0.4) !important;
}

.tw-caret-transparent\/45 {
  caret-color: rgb(0 0 0 / 0.45) !important;
}

.tw-caret-transparent\/5 {
  caret-color: rgb(0 0 0 / 0.05) !important;
}

.tw-caret-transparent\/50 {
  caret-color: rgb(0 0 0 / 0.5) !important;
}

.tw-caret-transparent\/55 {
  caret-color: rgb(0 0 0 / 0.55) !important;
}

.tw-caret-transparent\/60 {
  caret-color: rgb(0 0 0 / 0.6) !important;
}

.tw-caret-transparent\/65 {
  caret-color: rgb(0 0 0 / 0.65) !important;
}

.tw-caret-transparent\/70 {
  caret-color: rgb(0 0 0 / 0.7) !important;
}

.tw-caret-transparent\/75 {
  caret-color: rgb(0 0 0 / 0.75) !important;
}

.tw-caret-transparent\/80 {
  caret-color: rgb(0 0 0 / 0.8) !important;
}

.tw-caret-transparent\/85 {
  caret-color: rgb(0 0 0 / 0.85) !important;
}

.tw-caret-transparent\/90 {
  caret-color: rgb(0 0 0 / 0.9) !important;
}

.tw-caret-transparent\/95 {
  caret-color: rgb(0 0 0 / 0.95) !important;
}

.tw-caret-violet-100 {
  caret-color: #ede9fe !important;
}

.tw-caret-violet-100\/0 {
  caret-color: rgb(237 233 254 / 0) !important;
}

.tw-caret-violet-100\/10 {
  caret-color: rgb(237 233 254 / 0.1) !important;
}

.tw-caret-violet-100\/100 {
  caret-color: rgb(237 233 254 / 1) !important;
}

.tw-caret-violet-100\/15 {
  caret-color: rgb(237 233 254 / 0.15) !important;
}

.tw-caret-violet-100\/20 {
  caret-color: rgb(237 233 254 / 0.2) !important;
}

.tw-caret-violet-100\/25 {
  caret-color: rgb(237 233 254 / 0.25) !important;
}

.tw-caret-violet-100\/30 {
  caret-color: rgb(237 233 254 / 0.3) !important;
}

.tw-caret-violet-100\/35 {
  caret-color: rgb(237 233 254 / 0.35) !important;
}

.tw-caret-violet-100\/40 {
  caret-color: rgb(237 233 254 / 0.4) !important;
}

.tw-caret-violet-100\/45 {
  caret-color: rgb(237 233 254 / 0.45) !important;
}

.tw-caret-violet-100\/5 {
  caret-color: rgb(237 233 254 / 0.05) !important;
}

.tw-caret-violet-100\/50 {
  caret-color: rgb(237 233 254 / 0.5) !important;
}

.tw-caret-violet-100\/55 {
  caret-color: rgb(237 233 254 / 0.55) !important;
}

.tw-caret-violet-100\/60 {
  caret-color: rgb(237 233 254 / 0.6) !important;
}

.tw-caret-violet-100\/65 {
  caret-color: rgb(237 233 254 / 0.65) !important;
}

.tw-caret-violet-100\/70 {
  caret-color: rgb(237 233 254 / 0.7) !important;
}

.tw-caret-violet-100\/75 {
  caret-color: rgb(237 233 254 / 0.75) !important;
}

.tw-caret-violet-100\/80 {
  caret-color: rgb(237 233 254 / 0.8) !important;
}

.tw-caret-violet-100\/85 {
  caret-color: rgb(237 233 254 / 0.85) !important;
}

.tw-caret-violet-100\/90 {
  caret-color: rgb(237 233 254 / 0.9) !important;
}

.tw-caret-violet-100\/95 {
  caret-color: rgb(237 233 254 / 0.95) !important;
}

.tw-caret-violet-200 {
  caret-color: #ddd6fe !important;
}

.tw-caret-violet-200\/0 {
  caret-color: rgb(221 214 254 / 0) !important;
}

.tw-caret-violet-200\/10 {
  caret-color: rgb(221 214 254 / 0.1) !important;
}

.tw-caret-violet-200\/100 {
  caret-color: rgb(221 214 254 / 1) !important;
}

.tw-caret-violet-200\/15 {
  caret-color: rgb(221 214 254 / 0.15) !important;
}

.tw-caret-violet-200\/20 {
  caret-color: rgb(221 214 254 / 0.2) !important;
}

.tw-caret-violet-200\/25 {
  caret-color: rgb(221 214 254 / 0.25) !important;
}

.tw-caret-violet-200\/30 {
  caret-color: rgb(221 214 254 / 0.3) !important;
}

.tw-caret-violet-200\/35 {
  caret-color: rgb(221 214 254 / 0.35) !important;
}

.tw-caret-violet-200\/40 {
  caret-color: rgb(221 214 254 / 0.4) !important;
}

.tw-caret-violet-200\/45 {
  caret-color: rgb(221 214 254 / 0.45) !important;
}

.tw-caret-violet-200\/5 {
  caret-color: rgb(221 214 254 / 0.05) !important;
}

.tw-caret-violet-200\/50 {
  caret-color: rgb(221 214 254 / 0.5) !important;
}

.tw-caret-violet-200\/55 {
  caret-color: rgb(221 214 254 / 0.55) !important;
}

.tw-caret-violet-200\/60 {
  caret-color: rgb(221 214 254 / 0.6) !important;
}

.tw-caret-violet-200\/65 {
  caret-color: rgb(221 214 254 / 0.65) !important;
}

.tw-caret-violet-200\/70 {
  caret-color: rgb(221 214 254 / 0.7) !important;
}

.tw-caret-violet-200\/75 {
  caret-color: rgb(221 214 254 / 0.75) !important;
}

.tw-caret-violet-200\/80 {
  caret-color: rgb(221 214 254 / 0.8) !important;
}

.tw-caret-violet-200\/85 {
  caret-color: rgb(221 214 254 / 0.85) !important;
}

.tw-caret-violet-200\/90 {
  caret-color: rgb(221 214 254 / 0.9) !important;
}

.tw-caret-violet-200\/95 {
  caret-color: rgb(221 214 254 / 0.95) !important;
}

.tw-caret-violet-300 {
  caret-color: #c4b5fd !important;
}

.tw-caret-violet-300\/0 {
  caret-color: rgb(196 181 253 / 0) !important;
}

.tw-caret-violet-300\/10 {
  caret-color: rgb(196 181 253 / 0.1) !important;
}

.tw-caret-violet-300\/100 {
  caret-color: rgb(196 181 253 / 1) !important;
}

.tw-caret-violet-300\/15 {
  caret-color: rgb(196 181 253 / 0.15) !important;
}

.tw-caret-violet-300\/20 {
  caret-color: rgb(196 181 253 / 0.2) !important;
}

.tw-caret-violet-300\/25 {
  caret-color: rgb(196 181 253 / 0.25) !important;
}

.tw-caret-violet-300\/30 {
  caret-color: rgb(196 181 253 / 0.3) !important;
}

.tw-caret-violet-300\/35 {
  caret-color: rgb(196 181 253 / 0.35) !important;
}

.tw-caret-violet-300\/40 {
  caret-color: rgb(196 181 253 / 0.4) !important;
}

.tw-caret-violet-300\/45 {
  caret-color: rgb(196 181 253 / 0.45) !important;
}

.tw-caret-violet-300\/5 {
  caret-color: rgb(196 181 253 / 0.05) !important;
}

.tw-caret-violet-300\/50 {
  caret-color: rgb(196 181 253 / 0.5) !important;
}

.tw-caret-violet-300\/55 {
  caret-color: rgb(196 181 253 / 0.55) !important;
}

.tw-caret-violet-300\/60 {
  caret-color: rgb(196 181 253 / 0.6) !important;
}

.tw-caret-violet-300\/65 {
  caret-color: rgb(196 181 253 / 0.65) !important;
}

.tw-caret-violet-300\/70 {
  caret-color: rgb(196 181 253 / 0.7) !important;
}

.tw-caret-violet-300\/75 {
  caret-color: rgb(196 181 253 / 0.75) !important;
}

.tw-caret-violet-300\/80 {
  caret-color: rgb(196 181 253 / 0.8) !important;
}

.tw-caret-violet-300\/85 {
  caret-color: rgb(196 181 253 / 0.85) !important;
}

.tw-caret-violet-300\/90 {
  caret-color: rgb(196 181 253 / 0.9) !important;
}

.tw-caret-violet-300\/95 {
  caret-color: rgb(196 181 253 / 0.95) !important;
}

.tw-caret-violet-400 {
  caret-color: #a78bfa !important;
}

.tw-caret-violet-400\/0 {
  caret-color: rgb(167 139 250 / 0) !important;
}

.tw-caret-violet-400\/10 {
  caret-color: rgb(167 139 250 / 0.1) !important;
}

.tw-caret-violet-400\/100 {
  caret-color: rgb(167 139 250 / 1) !important;
}

.tw-caret-violet-400\/15 {
  caret-color: rgb(167 139 250 / 0.15) !important;
}

.tw-caret-violet-400\/20 {
  caret-color: rgb(167 139 250 / 0.2) !important;
}

.tw-caret-violet-400\/25 {
  caret-color: rgb(167 139 250 / 0.25) !important;
}

.tw-caret-violet-400\/30 {
  caret-color: rgb(167 139 250 / 0.3) !important;
}

.tw-caret-violet-400\/35 {
  caret-color: rgb(167 139 250 / 0.35) !important;
}

.tw-caret-violet-400\/40 {
  caret-color: rgb(167 139 250 / 0.4) !important;
}

.tw-caret-violet-400\/45 {
  caret-color: rgb(167 139 250 / 0.45) !important;
}

.tw-caret-violet-400\/5 {
  caret-color: rgb(167 139 250 / 0.05) !important;
}

.tw-caret-violet-400\/50 {
  caret-color: rgb(167 139 250 / 0.5) !important;
}

.tw-caret-violet-400\/55 {
  caret-color: rgb(167 139 250 / 0.55) !important;
}

.tw-caret-violet-400\/60 {
  caret-color: rgb(167 139 250 / 0.6) !important;
}

.tw-caret-violet-400\/65 {
  caret-color: rgb(167 139 250 / 0.65) !important;
}

.tw-caret-violet-400\/70 {
  caret-color: rgb(167 139 250 / 0.7) !important;
}

.tw-caret-violet-400\/75 {
  caret-color: rgb(167 139 250 / 0.75) !important;
}

.tw-caret-violet-400\/80 {
  caret-color: rgb(167 139 250 / 0.8) !important;
}

.tw-caret-violet-400\/85 {
  caret-color: rgb(167 139 250 / 0.85) !important;
}

.tw-caret-violet-400\/90 {
  caret-color: rgb(167 139 250 / 0.9) !important;
}

.tw-caret-violet-400\/95 {
  caret-color: rgb(167 139 250 / 0.95) !important;
}

.tw-caret-violet-50 {
  caret-color: #f5f3ff !important;
}

.tw-caret-violet-50\/0 {
  caret-color: rgb(245 243 255 / 0) !important;
}

.tw-caret-violet-50\/10 {
  caret-color: rgb(245 243 255 / 0.1) !important;
}

.tw-caret-violet-50\/100 {
  caret-color: rgb(245 243 255 / 1) !important;
}

.tw-caret-violet-50\/15 {
  caret-color: rgb(245 243 255 / 0.15) !important;
}

.tw-caret-violet-50\/20 {
  caret-color: rgb(245 243 255 / 0.2) !important;
}

.tw-caret-violet-50\/25 {
  caret-color: rgb(245 243 255 / 0.25) !important;
}

.tw-caret-violet-50\/30 {
  caret-color: rgb(245 243 255 / 0.3) !important;
}

.tw-caret-violet-50\/35 {
  caret-color: rgb(245 243 255 / 0.35) !important;
}

.tw-caret-violet-50\/40 {
  caret-color: rgb(245 243 255 / 0.4) !important;
}

.tw-caret-violet-50\/45 {
  caret-color: rgb(245 243 255 / 0.45) !important;
}

.tw-caret-violet-50\/5 {
  caret-color: rgb(245 243 255 / 0.05) !important;
}

.tw-caret-violet-50\/50 {
  caret-color: rgb(245 243 255 / 0.5) !important;
}

.tw-caret-violet-50\/55 {
  caret-color: rgb(245 243 255 / 0.55) !important;
}

.tw-caret-violet-50\/60 {
  caret-color: rgb(245 243 255 / 0.6) !important;
}

.tw-caret-violet-50\/65 {
  caret-color: rgb(245 243 255 / 0.65) !important;
}

.tw-caret-violet-50\/70 {
  caret-color: rgb(245 243 255 / 0.7) !important;
}

.tw-caret-violet-50\/75 {
  caret-color: rgb(245 243 255 / 0.75) !important;
}

.tw-caret-violet-50\/80 {
  caret-color: rgb(245 243 255 / 0.8) !important;
}

.tw-caret-violet-50\/85 {
  caret-color: rgb(245 243 255 / 0.85) !important;
}

.tw-caret-violet-50\/90 {
  caret-color: rgb(245 243 255 / 0.9) !important;
}

.tw-caret-violet-50\/95 {
  caret-color: rgb(245 243 255 / 0.95) !important;
}

.tw-caret-violet-500 {
  caret-color: #8b5cf6 !important;
}

.tw-caret-violet-500\/0 {
  caret-color: rgb(139 92 246 / 0) !important;
}

.tw-caret-violet-500\/10 {
  caret-color: rgb(139 92 246 / 0.1) !important;
}

.tw-caret-violet-500\/100 {
  caret-color: rgb(139 92 246 / 1) !important;
}

.tw-caret-violet-500\/15 {
  caret-color: rgb(139 92 246 / 0.15) !important;
}

.tw-caret-violet-500\/20 {
  caret-color: rgb(139 92 246 / 0.2) !important;
}

.tw-caret-violet-500\/25 {
  caret-color: rgb(139 92 246 / 0.25) !important;
}

.tw-caret-violet-500\/30 {
  caret-color: rgb(139 92 246 / 0.3) !important;
}

.tw-caret-violet-500\/35 {
  caret-color: rgb(139 92 246 / 0.35) !important;
}

.tw-caret-violet-500\/40 {
  caret-color: rgb(139 92 246 / 0.4) !important;
}

.tw-caret-violet-500\/45 {
  caret-color: rgb(139 92 246 / 0.45) !important;
}

.tw-caret-violet-500\/5 {
  caret-color: rgb(139 92 246 / 0.05) !important;
}

.tw-caret-violet-500\/50 {
  caret-color: rgb(139 92 246 / 0.5) !important;
}

.tw-caret-violet-500\/55 {
  caret-color: rgb(139 92 246 / 0.55) !important;
}

.tw-caret-violet-500\/60 {
  caret-color: rgb(139 92 246 / 0.6) !important;
}

.tw-caret-violet-500\/65 {
  caret-color: rgb(139 92 246 / 0.65) !important;
}

.tw-caret-violet-500\/70 {
  caret-color: rgb(139 92 246 / 0.7) !important;
}

.tw-caret-violet-500\/75 {
  caret-color: rgb(139 92 246 / 0.75) !important;
}

.tw-caret-violet-500\/80 {
  caret-color: rgb(139 92 246 / 0.8) !important;
}

.tw-caret-violet-500\/85 {
  caret-color: rgb(139 92 246 / 0.85) !important;
}

.tw-caret-violet-500\/90 {
  caret-color: rgb(139 92 246 / 0.9) !important;
}

.tw-caret-violet-500\/95 {
  caret-color: rgb(139 92 246 / 0.95) !important;
}

.tw-caret-violet-600 {
  caret-color: #7c3aed !important;
}

.tw-caret-violet-600\/0 {
  caret-color: rgb(124 58 237 / 0) !important;
}

.tw-caret-violet-600\/10 {
  caret-color: rgb(124 58 237 / 0.1) !important;
}

.tw-caret-violet-600\/100 {
  caret-color: rgb(124 58 237 / 1) !important;
}

.tw-caret-violet-600\/15 {
  caret-color: rgb(124 58 237 / 0.15) !important;
}

.tw-caret-violet-600\/20 {
  caret-color: rgb(124 58 237 / 0.2) !important;
}

.tw-caret-violet-600\/25 {
  caret-color: rgb(124 58 237 / 0.25) !important;
}

.tw-caret-violet-600\/30 {
  caret-color: rgb(124 58 237 / 0.3) !important;
}

.tw-caret-violet-600\/35 {
  caret-color: rgb(124 58 237 / 0.35) !important;
}

.tw-caret-violet-600\/40 {
  caret-color: rgb(124 58 237 / 0.4) !important;
}

.tw-caret-violet-600\/45 {
  caret-color: rgb(124 58 237 / 0.45) !important;
}

.tw-caret-violet-600\/5 {
  caret-color: rgb(124 58 237 / 0.05) !important;
}

.tw-caret-violet-600\/50 {
  caret-color: rgb(124 58 237 / 0.5) !important;
}

.tw-caret-violet-600\/55 {
  caret-color: rgb(124 58 237 / 0.55) !important;
}

.tw-caret-violet-600\/60 {
  caret-color: rgb(124 58 237 / 0.6) !important;
}

.tw-caret-violet-600\/65 {
  caret-color: rgb(124 58 237 / 0.65) !important;
}

.tw-caret-violet-600\/70 {
  caret-color: rgb(124 58 237 / 0.7) !important;
}

.tw-caret-violet-600\/75 {
  caret-color: rgb(124 58 237 / 0.75) !important;
}

.tw-caret-violet-600\/80 {
  caret-color: rgb(124 58 237 / 0.8) !important;
}

.tw-caret-violet-600\/85 {
  caret-color: rgb(124 58 237 / 0.85) !important;
}

.tw-caret-violet-600\/90 {
  caret-color: rgb(124 58 237 / 0.9) !important;
}

.tw-caret-violet-600\/95 {
  caret-color: rgb(124 58 237 / 0.95) !important;
}

.tw-caret-violet-700 {
  caret-color: #6d28d9 !important;
}

.tw-caret-violet-700\/0 {
  caret-color: rgb(109 40 217 / 0) !important;
}

.tw-caret-violet-700\/10 {
  caret-color: rgb(109 40 217 / 0.1) !important;
}

.tw-caret-violet-700\/100 {
  caret-color: rgb(109 40 217 / 1) !important;
}

.tw-caret-violet-700\/15 {
  caret-color: rgb(109 40 217 / 0.15) !important;
}

.tw-caret-violet-700\/20 {
  caret-color: rgb(109 40 217 / 0.2) !important;
}

.tw-caret-violet-700\/25 {
  caret-color: rgb(109 40 217 / 0.25) !important;
}

.tw-caret-violet-700\/30 {
  caret-color: rgb(109 40 217 / 0.3) !important;
}

.tw-caret-violet-700\/35 {
  caret-color: rgb(109 40 217 / 0.35) !important;
}

.tw-caret-violet-700\/40 {
  caret-color: rgb(109 40 217 / 0.4) !important;
}

.tw-caret-violet-700\/45 {
  caret-color: rgb(109 40 217 / 0.45) !important;
}

.tw-caret-violet-700\/5 {
  caret-color: rgb(109 40 217 / 0.05) !important;
}

.tw-caret-violet-700\/50 {
  caret-color: rgb(109 40 217 / 0.5) !important;
}

.tw-caret-violet-700\/55 {
  caret-color: rgb(109 40 217 / 0.55) !important;
}

.tw-caret-violet-700\/60 {
  caret-color: rgb(109 40 217 / 0.6) !important;
}

.tw-caret-violet-700\/65 {
  caret-color: rgb(109 40 217 / 0.65) !important;
}

.tw-caret-violet-700\/70 {
  caret-color: rgb(109 40 217 / 0.7) !important;
}

.tw-caret-violet-700\/75 {
  caret-color: rgb(109 40 217 / 0.75) !important;
}

.tw-caret-violet-700\/80 {
  caret-color: rgb(109 40 217 / 0.8) !important;
}

.tw-caret-violet-700\/85 {
  caret-color: rgb(109 40 217 / 0.85) !important;
}

.tw-caret-violet-700\/90 {
  caret-color: rgb(109 40 217 / 0.9) !important;
}

.tw-caret-violet-700\/95 {
  caret-color: rgb(109 40 217 / 0.95) !important;
}

.tw-caret-violet-800 {
  caret-color: #5b21b6 !important;
}

.tw-caret-violet-800\/0 {
  caret-color: rgb(91 33 182 / 0) !important;
}

.tw-caret-violet-800\/10 {
  caret-color: rgb(91 33 182 / 0.1) !important;
}

.tw-caret-violet-800\/100 {
  caret-color: rgb(91 33 182 / 1) !important;
}

.tw-caret-violet-800\/15 {
  caret-color: rgb(91 33 182 / 0.15) !important;
}

.tw-caret-violet-800\/20 {
  caret-color: rgb(91 33 182 / 0.2) !important;
}

.tw-caret-violet-800\/25 {
  caret-color: rgb(91 33 182 / 0.25) !important;
}

.tw-caret-violet-800\/30 {
  caret-color: rgb(91 33 182 / 0.3) !important;
}

.tw-caret-violet-800\/35 {
  caret-color: rgb(91 33 182 / 0.35) !important;
}

.tw-caret-violet-800\/40 {
  caret-color: rgb(91 33 182 / 0.4) !important;
}

.tw-caret-violet-800\/45 {
  caret-color: rgb(91 33 182 / 0.45) !important;
}

.tw-caret-violet-800\/5 {
  caret-color: rgb(91 33 182 / 0.05) !important;
}

.tw-caret-violet-800\/50 {
  caret-color: rgb(91 33 182 / 0.5) !important;
}

.tw-caret-violet-800\/55 {
  caret-color: rgb(91 33 182 / 0.55) !important;
}

.tw-caret-violet-800\/60 {
  caret-color: rgb(91 33 182 / 0.6) !important;
}

.tw-caret-violet-800\/65 {
  caret-color: rgb(91 33 182 / 0.65) !important;
}

.tw-caret-violet-800\/70 {
  caret-color: rgb(91 33 182 / 0.7) !important;
}

.tw-caret-violet-800\/75 {
  caret-color: rgb(91 33 182 / 0.75) !important;
}

.tw-caret-violet-800\/80 {
  caret-color: rgb(91 33 182 / 0.8) !important;
}

.tw-caret-violet-800\/85 {
  caret-color: rgb(91 33 182 / 0.85) !important;
}

.tw-caret-violet-800\/90 {
  caret-color: rgb(91 33 182 / 0.9) !important;
}

.tw-caret-violet-800\/95 {
  caret-color: rgb(91 33 182 / 0.95) !important;
}

.tw-caret-violet-900 {
  caret-color: #4c1d95 !important;
}

.tw-caret-violet-900\/0 {
  caret-color: rgb(76 29 149 / 0) !important;
}

.tw-caret-violet-900\/10 {
  caret-color: rgb(76 29 149 / 0.1) !important;
}

.tw-caret-violet-900\/100 {
  caret-color: rgb(76 29 149 / 1) !important;
}

.tw-caret-violet-900\/15 {
  caret-color: rgb(76 29 149 / 0.15) !important;
}

.tw-caret-violet-900\/20 {
  caret-color: rgb(76 29 149 / 0.2) !important;
}

.tw-caret-violet-900\/25 {
  caret-color: rgb(76 29 149 / 0.25) !important;
}

.tw-caret-violet-900\/30 {
  caret-color: rgb(76 29 149 / 0.3) !important;
}

.tw-caret-violet-900\/35 {
  caret-color: rgb(76 29 149 / 0.35) !important;
}

.tw-caret-violet-900\/40 {
  caret-color: rgb(76 29 149 / 0.4) !important;
}

.tw-caret-violet-900\/45 {
  caret-color: rgb(76 29 149 / 0.45) !important;
}

.tw-caret-violet-900\/5 {
  caret-color: rgb(76 29 149 / 0.05) !important;
}

.tw-caret-violet-900\/50 {
  caret-color: rgb(76 29 149 / 0.5) !important;
}

.tw-caret-violet-900\/55 {
  caret-color: rgb(76 29 149 / 0.55) !important;
}

.tw-caret-violet-900\/60 {
  caret-color: rgb(76 29 149 / 0.6) !important;
}

.tw-caret-violet-900\/65 {
  caret-color: rgb(76 29 149 / 0.65) !important;
}

.tw-caret-violet-900\/70 {
  caret-color: rgb(76 29 149 / 0.7) !important;
}

.tw-caret-violet-900\/75 {
  caret-color: rgb(76 29 149 / 0.75) !important;
}

.tw-caret-violet-900\/80 {
  caret-color: rgb(76 29 149 / 0.8) !important;
}

.tw-caret-violet-900\/85 {
  caret-color: rgb(76 29 149 / 0.85) !important;
}

.tw-caret-violet-900\/90 {
  caret-color: rgb(76 29 149 / 0.9) !important;
}

.tw-caret-violet-900\/95 {
  caret-color: rgb(76 29 149 / 0.95) !important;
}

.tw-caret-violet-950 {
  caret-color: #2e1065 !important;
}

.tw-caret-violet-950\/0 {
  caret-color: rgb(46 16 101 / 0) !important;
}

.tw-caret-violet-950\/10 {
  caret-color: rgb(46 16 101 / 0.1) !important;
}

.tw-caret-violet-950\/100 {
  caret-color: rgb(46 16 101 / 1) !important;
}

.tw-caret-violet-950\/15 {
  caret-color: rgb(46 16 101 / 0.15) !important;
}

.tw-caret-violet-950\/20 {
  caret-color: rgb(46 16 101 / 0.2) !important;
}

.tw-caret-violet-950\/25 {
  caret-color: rgb(46 16 101 / 0.25) !important;
}

.tw-caret-violet-950\/30 {
  caret-color: rgb(46 16 101 / 0.3) !important;
}

.tw-caret-violet-950\/35 {
  caret-color: rgb(46 16 101 / 0.35) !important;
}

.tw-caret-violet-950\/40 {
  caret-color: rgb(46 16 101 / 0.4) !important;
}

.tw-caret-violet-950\/45 {
  caret-color: rgb(46 16 101 / 0.45) !important;
}

.tw-caret-violet-950\/5 {
  caret-color: rgb(46 16 101 / 0.05) !important;
}

.tw-caret-violet-950\/50 {
  caret-color: rgb(46 16 101 / 0.5) !important;
}

.tw-caret-violet-950\/55 {
  caret-color: rgb(46 16 101 / 0.55) !important;
}

.tw-caret-violet-950\/60 {
  caret-color: rgb(46 16 101 / 0.6) !important;
}

.tw-caret-violet-950\/65 {
  caret-color: rgb(46 16 101 / 0.65) !important;
}

.tw-caret-violet-950\/70 {
  caret-color: rgb(46 16 101 / 0.7) !important;
}

.tw-caret-violet-950\/75 {
  caret-color: rgb(46 16 101 / 0.75) !important;
}

.tw-caret-violet-950\/80 {
  caret-color: rgb(46 16 101 / 0.8) !important;
}

.tw-caret-violet-950\/85 {
  caret-color: rgb(46 16 101 / 0.85) !important;
}

.tw-caret-violet-950\/90 {
  caret-color: rgb(46 16 101 / 0.9) !important;
}

.tw-caret-violet-950\/95 {
  caret-color: rgb(46 16 101 / 0.95) !important;
}

.tw-caret-white {
  caret-color: #fff !important;
}

.tw-caret-white\/0 {
  caret-color: rgb(255 255 255 / 0) !important;
}

.tw-caret-white\/10 {
  caret-color: rgb(255 255 255 / 0.1) !important;
}

.tw-caret-white\/100 {
  caret-color: rgb(255 255 255 / 1) !important;
}

.tw-caret-white\/15 {
  caret-color: rgb(255 255 255 / 0.15) !important;
}

.tw-caret-white\/20 {
  caret-color: rgb(255 255 255 / 0.2) !important;
}

.tw-caret-white\/25 {
  caret-color: rgb(255 255 255 / 0.25) !important;
}

.tw-caret-white\/30 {
  caret-color: rgb(255 255 255 / 0.3) !important;
}

.tw-caret-white\/35 {
  caret-color: rgb(255 255 255 / 0.35) !important;
}

.tw-caret-white\/40 {
  caret-color: rgb(255 255 255 / 0.4) !important;
}

.tw-caret-white\/45 {
  caret-color: rgb(255 255 255 / 0.45) !important;
}

.tw-caret-white\/5 {
  caret-color: rgb(255 255 255 / 0.05) !important;
}

.tw-caret-white\/50 {
  caret-color: rgb(255 255 255 / 0.5) !important;
}

.tw-caret-white\/55 {
  caret-color: rgb(255 255 255 / 0.55) !important;
}

.tw-caret-white\/60 {
  caret-color: rgb(255 255 255 / 0.6) !important;
}

.tw-caret-white\/65 {
  caret-color: rgb(255 255 255 / 0.65) !important;
}

.tw-caret-white\/70 {
  caret-color: rgb(255 255 255 / 0.7) !important;
}

.tw-caret-white\/75 {
  caret-color: rgb(255 255 255 / 0.75) !important;
}

.tw-caret-white\/80 {
  caret-color: rgb(255 255 255 / 0.8) !important;
}

.tw-caret-white\/85 {
  caret-color: rgb(255 255 255 / 0.85) !important;
}

.tw-caret-white\/90 {
  caret-color: rgb(255 255 255 / 0.9) !important;
}

.tw-caret-white\/95 {
  caret-color: rgb(255 255 255 / 0.95) !important;
}

.tw-caret-yellow-100 {
  caret-color: #fef9c3 !important;
}

.tw-caret-yellow-100\/0 {
  caret-color: rgb(254 249 195 / 0) !important;
}

.tw-caret-yellow-100\/10 {
  caret-color: rgb(254 249 195 / 0.1) !important;
}

.tw-caret-yellow-100\/100 {
  caret-color: rgb(254 249 195 / 1) !important;
}

.tw-caret-yellow-100\/15 {
  caret-color: rgb(254 249 195 / 0.15) !important;
}

.tw-caret-yellow-100\/20 {
  caret-color: rgb(254 249 195 / 0.2) !important;
}

.tw-caret-yellow-100\/25 {
  caret-color: rgb(254 249 195 / 0.25) !important;
}

.tw-caret-yellow-100\/30 {
  caret-color: rgb(254 249 195 / 0.3) !important;
}

.tw-caret-yellow-100\/35 {
  caret-color: rgb(254 249 195 / 0.35) !important;
}

.tw-caret-yellow-100\/40 {
  caret-color: rgb(254 249 195 / 0.4) !important;
}

.tw-caret-yellow-100\/45 {
  caret-color: rgb(254 249 195 / 0.45) !important;
}

.tw-caret-yellow-100\/5 {
  caret-color: rgb(254 249 195 / 0.05) !important;
}

.tw-caret-yellow-100\/50 {
  caret-color: rgb(254 249 195 / 0.5) !important;
}

.tw-caret-yellow-100\/55 {
  caret-color: rgb(254 249 195 / 0.55) !important;
}

.tw-caret-yellow-100\/60 {
  caret-color: rgb(254 249 195 / 0.6) !important;
}

.tw-caret-yellow-100\/65 {
  caret-color: rgb(254 249 195 / 0.65) !important;
}

.tw-caret-yellow-100\/70 {
  caret-color: rgb(254 249 195 / 0.7) !important;
}

.tw-caret-yellow-100\/75 {
  caret-color: rgb(254 249 195 / 0.75) !important;
}

.tw-caret-yellow-100\/80 {
  caret-color: rgb(254 249 195 / 0.8) !important;
}

.tw-caret-yellow-100\/85 {
  caret-color: rgb(254 249 195 / 0.85) !important;
}

.tw-caret-yellow-100\/90 {
  caret-color: rgb(254 249 195 / 0.9) !important;
}

.tw-caret-yellow-100\/95 {
  caret-color: rgb(254 249 195 / 0.95) !important;
}

.tw-caret-yellow-200 {
  caret-color: #fef08a !important;
}

.tw-caret-yellow-200\/0 {
  caret-color: rgb(254 240 138 / 0) !important;
}

.tw-caret-yellow-200\/10 {
  caret-color: rgb(254 240 138 / 0.1) !important;
}

.tw-caret-yellow-200\/100 {
  caret-color: rgb(254 240 138 / 1) !important;
}

.tw-caret-yellow-200\/15 {
  caret-color: rgb(254 240 138 / 0.15) !important;
}

.tw-caret-yellow-200\/20 {
  caret-color: rgb(254 240 138 / 0.2) !important;
}

.tw-caret-yellow-200\/25 {
  caret-color: rgb(254 240 138 / 0.25) !important;
}

.tw-caret-yellow-200\/30 {
  caret-color: rgb(254 240 138 / 0.3) !important;
}

.tw-caret-yellow-200\/35 {
  caret-color: rgb(254 240 138 / 0.35) !important;
}

.tw-caret-yellow-200\/40 {
  caret-color: rgb(254 240 138 / 0.4) !important;
}

.tw-caret-yellow-200\/45 {
  caret-color: rgb(254 240 138 / 0.45) !important;
}

.tw-caret-yellow-200\/5 {
  caret-color: rgb(254 240 138 / 0.05) !important;
}

.tw-caret-yellow-200\/50 {
  caret-color: rgb(254 240 138 / 0.5) !important;
}

.tw-caret-yellow-200\/55 {
  caret-color: rgb(254 240 138 / 0.55) !important;
}

.tw-caret-yellow-200\/60 {
  caret-color: rgb(254 240 138 / 0.6) !important;
}

.tw-caret-yellow-200\/65 {
  caret-color: rgb(254 240 138 / 0.65) !important;
}

.tw-caret-yellow-200\/70 {
  caret-color: rgb(254 240 138 / 0.7) !important;
}

.tw-caret-yellow-200\/75 {
  caret-color: rgb(254 240 138 / 0.75) !important;
}

.tw-caret-yellow-200\/80 {
  caret-color: rgb(254 240 138 / 0.8) !important;
}

.tw-caret-yellow-200\/85 {
  caret-color: rgb(254 240 138 / 0.85) !important;
}

.tw-caret-yellow-200\/90 {
  caret-color: rgb(254 240 138 / 0.9) !important;
}

.tw-caret-yellow-200\/95 {
  caret-color: rgb(254 240 138 / 0.95) !important;
}

.tw-caret-yellow-300 {
  caret-color: #fde047 !important;
}

.tw-caret-yellow-300\/0 {
  caret-color: rgb(253 224 71 / 0) !important;
}

.tw-caret-yellow-300\/10 {
  caret-color: rgb(253 224 71 / 0.1) !important;
}

.tw-caret-yellow-300\/100 {
  caret-color: rgb(253 224 71 / 1) !important;
}

.tw-caret-yellow-300\/15 {
  caret-color: rgb(253 224 71 / 0.15) !important;
}

.tw-caret-yellow-300\/20 {
  caret-color: rgb(253 224 71 / 0.2) !important;
}

.tw-caret-yellow-300\/25 {
  caret-color: rgb(253 224 71 / 0.25) !important;
}

.tw-caret-yellow-300\/30 {
  caret-color: rgb(253 224 71 / 0.3) !important;
}

.tw-caret-yellow-300\/35 {
  caret-color: rgb(253 224 71 / 0.35) !important;
}

.tw-caret-yellow-300\/40 {
  caret-color: rgb(253 224 71 / 0.4) !important;
}

.tw-caret-yellow-300\/45 {
  caret-color: rgb(253 224 71 / 0.45) !important;
}

.tw-caret-yellow-300\/5 {
  caret-color: rgb(253 224 71 / 0.05) !important;
}

.tw-caret-yellow-300\/50 {
  caret-color: rgb(253 224 71 / 0.5) !important;
}

.tw-caret-yellow-300\/55 {
  caret-color: rgb(253 224 71 / 0.55) !important;
}

.tw-caret-yellow-300\/60 {
  caret-color: rgb(253 224 71 / 0.6) !important;
}

.tw-caret-yellow-300\/65 {
  caret-color: rgb(253 224 71 / 0.65) !important;
}

.tw-caret-yellow-300\/70 {
  caret-color: rgb(253 224 71 / 0.7) !important;
}

.tw-caret-yellow-300\/75 {
  caret-color: rgb(253 224 71 / 0.75) !important;
}

.tw-caret-yellow-300\/80 {
  caret-color: rgb(253 224 71 / 0.8) !important;
}

.tw-caret-yellow-300\/85 {
  caret-color: rgb(253 224 71 / 0.85) !important;
}

.tw-caret-yellow-300\/90 {
  caret-color: rgb(253 224 71 / 0.9) !important;
}

.tw-caret-yellow-300\/95 {
  caret-color: rgb(253 224 71 / 0.95) !important;
}

.tw-caret-yellow-400 {
  caret-color: #facc15 !important;
}

.tw-caret-yellow-400\/0 {
  caret-color: rgb(250 204 21 / 0) !important;
}

.tw-caret-yellow-400\/10 {
  caret-color: rgb(250 204 21 / 0.1) !important;
}

.tw-caret-yellow-400\/100 {
  caret-color: rgb(250 204 21 / 1) !important;
}

.tw-caret-yellow-400\/15 {
  caret-color: rgb(250 204 21 / 0.15) !important;
}

.tw-caret-yellow-400\/20 {
  caret-color: rgb(250 204 21 / 0.2) !important;
}

.tw-caret-yellow-400\/25 {
  caret-color: rgb(250 204 21 / 0.25) !important;
}

.tw-caret-yellow-400\/30 {
  caret-color: rgb(250 204 21 / 0.3) !important;
}

.tw-caret-yellow-400\/35 {
  caret-color: rgb(250 204 21 / 0.35) !important;
}

.tw-caret-yellow-400\/40 {
  caret-color: rgb(250 204 21 / 0.4) !important;
}

.tw-caret-yellow-400\/45 {
  caret-color: rgb(250 204 21 / 0.45) !important;
}

.tw-caret-yellow-400\/5 {
  caret-color: rgb(250 204 21 / 0.05) !important;
}

.tw-caret-yellow-400\/50 {
  caret-color: rgb(250 204 21 / 0.5) !important;
}

.tw-caret-yellow-400\/55 {
  caret-color: rgb(250 204 21 / 0.55) !important;
}

.tw-caret-yellow-400\/60 {
  caret-color: rgb(250 204 21 / 0.6) !important;
}

.tw-caret-yellow-400\/65 {
  caret-color: rgb(250 204 21 / 0.65) !important;
}

.tw-caret-yellow-400\/70 {
  caret-color: rgb(250 204 21 / 0.7) !important;
}

.tw-caret-yellow-400\/75 {
  caret-color: rgb(250 204 21 / 0.75) !important;
}

.tw-caret-yellow-400\/80 {
  caret-color: rgb(250 204 21 / 0.8) !important;
}

.tw-caret-yellow-400\/85 {
  caret-color: rgb(250 204 21 / 0.85) !important;
}

.tw-caret-yellow-400\/90 {
  caret-color: rgb(250 204 21 / 0.9) !important;
}

.tw-caret-yellow-400\/95 {
  caret-color: rgb(250 204 21 / 0.95) !important;
}

.tw-caret-yellow-50 {
  caret-color: #fefce8 !important;
}

.tw-caret-yellow-50\/0 {
  caret-color: rgb(254 252 232 / 0) !important;
}

.tw-caret-yellow-50\/10 {
  caret-color: rgb(254 252 232 / 0.1) !important;
}

.tw-caret-yellow-50\/100 {
  caret-color: rgb(254 252 232 / 1) !important;
}

.tw-caret-yellow-50\/15 {
  caret-color: rgb(254 252 232 / 0.15) !important;
}

.tw-caret-yellow-50\/20 {
  caret-color: rgb(254 252 232 / 0.2) !important;
}

.tw-caret-yellow-50\/25 {
  caret-color: rgb(254 252 232 / 0.25) !important;
}

.tw-caret-yellow-50\/30 {
  caret-color: rgb(254 252 232 / 0.3) !important;
}

.tw-caret-yellow-50\/35 {
  caret-color: rgb(254 252 232 / 0.35) !important;
}

.tw-caret-yellow-50\/40 {
  caret-color: rgb(254 252 232 / 0.4) !important;
}

.tw-caret-yellow-50\/45 {
  caret-color: rgb(254 252 232 / 0.45) !important;
}

.tw-caret-yellow-50\/5 {
  caret-color: rgb(254 252 232 / 0.05) !important;
}

.tw-caret-yellow-50\/50 {
  caret-color: rgb(254 252 232 / 0.5) !important;
}

.tw-caret-yellow-50\/55 {
  caret-color: rgb(254 252 232 / 0.55) !important;
}

.tw-caret-yellow-50\/60 {
  caret-color: rgb(254 252 232 / 0.6) !important;
}

.tw-caret-yellow-50\/65 {
  caret-color: rgb(254 252 232 / 0.65) !important;
}

.tw-caret-yellow-50\/70 {
  caret-color: rgb(254 252 232 / 0.7) !important;
}

.tw-caret-yellow-50\/75 {
  caret-color: rgb(254 252 232 / 0.75) !important;
}

.tw-caret-yellow-50\/80 {
  caret-color: rgb(254 252 232 / 0.8) !important;
}

.tw-caret-yellow-50\/85 {
  caret-color: rgb(254 252 232 / 0.85) !important;
}

.tw-caret-yellow-50\/90 {
  caret-color: rgb(254 252 232 / 0.9) !important;
}

.tw-caret-yellow-50\/95 {
  caret-color: rgb(254 252 232 / 0.95) !important;
}

.tw-caret-yellow-500 {
  caret-color: #eab308 !important;
}

.tw-caret-yellow-500\/0 {
  caret-color: rgb(234 179 8 / 0) !important;
}

.tw-caret-yellow-500\/10 {
  caret-color: rgb(234 179 8 / 0.1) !important;
}

.tw-caret-yellow-500\/100 {
  caret-color: rgb(234 179 8 / 1) !important;
}

.tw-caret-yellow-500\/15 {
  caret-color: rgb(234 179 8 / 0.15) !important;
}

.tw-caret-yellow-500\/20 {
  caret-color: rgb(234 179 8 / 0.2) !important;
}

.tw-caret-yellow-500\/25 {
  caret-color: rgb(234 179 8 / 0.25) !important;
}

.tw-caret-yellow-500\/30 {
  caret-color: rgb(234 179 8 / 0.3) !important;
}

.tw-caret-yellow-500\/35 {
  caret-color: rgb(234 179 8 / 0.35) !important;
}

.tw-caret-yellow-500\/40 {
  caret-color: rgb(234 179 8 / 0.4) !important;
}

.tw-caret-yellow-500\/45 {
  caret-color: rgb(234 179 8 / 0.45) !important;
}

.tw-caret-yellow-500\/5 {
  caret-color: rgb(234 179 8 / 0.05) !important;
}

.tw-caret-yellow-500\/50 {
  caret-color: rgb(234 179 8 / 0.5) !important;
}

.tw-caret-yellow-500\/55 {
  caret-color: rgb(234 179 8 / 0.55) !important;
}

.tw-caret-yellow-500\/60 {
  caret-color: rgb(234 179 8 / 0.6) !important;
}

.tw-caret-yellow-500\/65 {
  caret-color: rgb(234 179 8 / 0.65) !important;
}

.tw-caret-yellow-500\/70 {
  caret-color: rgb(234 179 8 / 0.7) !important;
}

.tw-caret-yellow-500\/75 {
  caret-color: rgb(234 179 8 / 0.75) !important;
}

.tw-caret-yellow-500\/80 {
  caret-color: rgb(234 179 8 / 0.8) !important;
}

.tw-caret-yellow-500\/85 {
  caret-color: rgb(234 179 8 / 0.85) !important;
}

.tw-caret-yellow-500\/90 {
  caret-color: rgb(234 179 8 / 0.9) !important;
}

.tw-caret-yellow-500\/95 {
  caret-color: rgb(234 179 8 / 0.95) !important;
}

.tw-caret-yellow-600 {
  caret-color: #ca8a04 !important;
}

.tw-caret-yellow-600\/0 {
  caret-color: rgb(202 138 4 / 0) !important;
}

.tw-caret-yellow-600\/10 {
  caret-color: rgb(202 138 4 / 0.1) !important;
}

.tw-caret-yellow-600\/100 {
  caret-color: rgb(202 138 4 / 1) !important;
}

.tw-caret-yellow-600\/15 {
  caret-color: rgb(202 138 4 / 0.15) !important;
}

.tw-caret-yellow-600\/20 {
  caret-color: rgb(202 138 4 / 0.2) !important;
}

.tw-caret-yellow-600\/25 {
  caret-color: rgb(202 138 4 / 0.25) !important;
}

.tw-caret-yellow-600\/30 {
  caret-color: rgb(202 138 4 / 0.3) !important;
}

.tw-caret-yellow-600\/35 {
  caret-color: rgb(202 138 4 / 0.35) !important;
}

.tw-caret-yellow-600\/40 {
  caret-color: rgb(202 138 4 / 0.4) !important;
}

.tw-caret-yellow-600\/45 {
  caret-color: rgb(202 138 4 / 0.45) !important;
}

.tw-caret-yellow-600\/5 {
  caret-color: rgb(202 138 4 / 0.05) !important;
}

.tw-caret-yellow-600\/50 {
  caret-color: rgb(202 138 4 / 0.5) !important;
}

.tw-caret-yellow-600\/55 {
  caret-color: rgb(202 138 4 / 0.55) !important;
}

.tw-caret-yellow-600\/60 {
  caret-color: rgb(202 138 4 / 0.6) !important;
}

.tw-caret-yellow-600\/65 {
  caret-color: rgb(202 138 4 / 0.65) !important;
}

.tw-caret-yellow-600\/70 {
  caret-color: rgb(202 138 4 / 0.7) !important;
}

.tw-caret-yellow-600\/75 {
  caret-color: rgb(202 138 4 / 0.75) !important;
}

.tw-caret-yellow-600\/80 {
  caret-color: rgb(202 138 4 / 0.8) !important;
}

.tw-caret-yellow-600\/85 {
  caret-color: rgb(202 138 4 / 0.85) !important;
}

.tw-caret-yellow-600\/90 {
  caret-color: rgb(202 138 4 / 0.9) !important;
}

.tw-caret-yellow-600\/95 {
  caret-color: rgb(202 138 4 / 0.95) !important;
}

.tw-caret-yellow-700 {
  caret-color: #a16207 !important;
}

.tw-caret-yellow-700\/0 {
  caret-color: rgb(161 98 7 / 0) !important;
}

.tw-caret-yellow-700\/10 {
  caret-color: rgb(161 98 7 / 0.1) !important;
}

.tw-caret-yellow-700\/100 {
  caret-color: rgb(161 98 7 / 1) !important;
}

.tw-caret-yellow-700\/15 {
  caret-color: rgb(161 98 7 / 0.15) !important;
}

.tw-caret-yellow-700\/20 {
  caret-color: rgb(161 98 7 / 0.2) !important;
}

.tw-caret-yellow-700\/25 {
  caret-color: rgb(161 98 7 / 0.25) !important;
}

.tw-caret-yellow-700\/30 {
  caret-color: rgb(161 98 7 / 0.3) !important;
}

.tw-caret-yellow-700\/35 {
  caret-color: rgb(161 98 7 / 0.35) !important;
}

.tw-caret-yellow-700\/40 {
  caret-color: rgb(161 98 7 / 0.4) !important;
}

.tw-caret-yellow-700\/45 {
  caret-color: rgb(161 98 7 / 0.45) !important;
}

.tw-caret-yellow-700\/5 {
  caret-color: rgb(161 98 7 / 0.05) !important;
}

.tw-caret-yellow-700\/50 {
  caret-color: rgb(161 98 7 / 0.5) !important;
}

.tw-caret-yellow-700\/55 {
  caret-color: rgb(161 98 7 / 0.55) !important;
}

.tw-caret-yellow-700\/60 {
  caret-color: rgb(161 98 7 / 0.6) !important;
}

.tw-caret-yellow-700\/65 {
  caret-color: rgb(161 98 7 / 0.65) !important;
}

.tw-caret-yellow-700\/70 {
  caret-color: rgb(161 98 7 / 0.7) !important;
}

.tw-caret-yellow-700\/75 {
  caret-color: rgb(161 98 7 / 0.75) !important;
}

.tw-caret-yellow-700\/80 {
  caret-color: rgb(161 98 7 / 0.8) !important;
}

.tw-caret-yellow-700\/85 {
  caret-color: rgb(161 98 7 / 0.85) !important;
}

.tw-caret-yellow-700\/90 {
  caret-color: rgb(161 98 7 / 0.9) !important;
}

.tw-caret-yellow-700\/95 {
  caret-color: rgb(161 98 7 / 0.95) !important;
}

.tw-caret-yellow-800 {
  caret-color: #854d0e !important;
}

.tw-caret-yellow-800\/0 {
  caret-color: rgb(133 77 14 / 0) !important;
}

.tw-caret-yellow-800\/10 {
  caret-color: rgb(133 77 14 / 0.1) !important;
}

.tw-caret-yellow-800\/100 {
  caret-color: rgb(133 77 14 / 1) !important;
}

.tw-caret-yellow-800\/15 {
  caret-color: rgb(133 77 14 / 0.15) !important;
}

.tw-caret-yellow-800\/20 {
  caret-color: rgb(133 77 14 / 0.2) !important;
}

.tw-caret-yellow-800\/25 {
  caret-color: rgb(133 77 14 / 0.25) !important;
}

.tw-caret-yellow-800\/30 {
  caret-color: rgb(133 77 14 / 0.3) !important;
}

.tw-caret-yellow-800\/35 {
  caret-color: rgb(133 77 14 / 0.35) !important;
}

.tw-caret-yellow-800\/40 {
  caret-color: rgb(133 77 14 / 0.4) !important;
}

.tw-caret-yellow-800\/45 {
  caret-color: rgb(133 77 14 / 0.45) !important;
}

.tw-caret-yellow-800\/5 {
  caret-color: rgb(133 77 14 / 0.05) !important;
}

.tw-caret-yellow-800\/50 {
  caret-color: rgb(133 77 14 / 0.5) !important;
}

.tw-caret-yellow-800\/55 {
  caret-color: rgb(133 77 14 / 0.55) !important;
}

.tw-caret-yellow-800\/60 {
  caret-color: rgb(133 77 14 / 0.6) !important;
}

.tw-caret-yellow-800\/65 {
  caret-color: rgb(133 77 14 / 0.65) !important;
}

.tw-caret-yellow-800\/70 {
  caret-color: rgb(133 77 14 / 0.7) !important;
}

.tw-caret-yellow-800\/75 {
  caret-color: rgb(133 77 14 / 0.75) !important;
}

.tw-caret-yellow-800\/80 {
  caret-color: rgb(133 77 14 / 0.8) !important;
}

.tw-caret-yellow-800\/85 {
  caret-color: rgb(133 77 14 / 0.85) !important;
}

.tw-caret-yellow-800\/90 {
  caret-color: rgb(133 77 14 / 0.9) !important;
}

.tw-caret-yellow-800\/95 {
  caret-color: rgb(133 77 14 / 0.95) !important;
}

.tw-caret-yellow-900 {
  caret-color: #713f12 !important;
}

.tw-caret-yellow-900\/0 {
  caret-color: rgb(113 63 18 / 0) !important;
}

.tw-caret-yellow-900\/10 {
  caret-color: rgb(113 63 18 / 0.1) !important;
}

.tw-caret-yellow-900\/100 {
  caret-color: rgb(113 63 18 / 1) !important;
}

.tw-caret-yellow-900\/15 {
  caret-color: rgb(113 63 18 / 0.15) !important;
}

.tw-caret-yellow-900\/20 {
  caret-color: rgb(113 63 18 / 0.2) !important;
}

.tw-caret-yellow-900\/25 {
  caret-color: rgb(113 63 18 / 0.25) !important;
}

.tw-caret-yellow-900\/30 {
  caret-color: rgb(113 63 18 / 0.3) !important;
}

.tw-caret-yellow-900\/35 {
  caret-color: rgb(113 63 18 / 0.35) !important;
}

.tw-caret-yellow-900\/40 {
  caret-color: rgb(113 63 18 / 0.4) !important;
}

.tw-caret-yellow-900\/45 {
  caret-color: rgb(113 63 18 / 0.45) !important;
}

.tw-caret-yellow-900\/5 {
  caret-color: rgb(113 63 18 / 0.05) !important;
}

.tw-caret-yellow-900\/50 {
  caret-color: rgb(113 63 18 / 0.5) !important;
}

.tw-caret-yellow-900\/55 {
  caret-color: rgb(113 63 18 / 0.55) !important;
}

.tw-caret-yellow-900\/60 {
  caret-color: rgb(113 63 18 / 0.6) !important;
}

.tw-caret-yellow-900\/65 {
  caret-color: rgb(113 63 18 / 0.65) !important;
}

.tw-caret-yellow-900\/70 {
  caret-color: rgb(113 63 18 / 0.7) !important;
}

.tw-caret-yellow-900\/75 {
  caret-color: rgb(113 63 18 / 0.75) !important;
}

.tw-caret-yellow-900\/80 {
  caret-color: rgb(113 63 18 / 0.8) !important;
}

.tw-caret-yellow-900\/85 {
  caret-color: rgb(113 63 18 / 0.85) !important;
}

.tw-caret-yellow-900\/90 {
  caret-color: rgb(113 63 18 / 0.9) !important;
}

.tw-caret-yellow-900\/95 {
  caret-color: rgb(113 63 18 / 0.95) !important;
}

.tw-caret-yellow-950 {
  caret-color: #422006 !important;
}

.tw-caret-yellow-950\/0 {
  caret-color: rgb(66 32 6 / 0) !important;
}

.tw-caret-yellow-950\/10 {
  caret-color: rgb(66 32 6 / 0.1) !important;
}

.tw-caret-yellow-950\/100 {
  caret-color: rgb(66 32 6 / 1) !important;
}

.tw-caret-yellow-950\/15 {
  caret-color: rgb(66 32 6 / 0.15) !important;
}

.tw-caret-yellow-950\/20 {
  caret-color: rgb(66 32 6 / 0.2) !important;
}

.tw-caret-yellow-950\/25 {
  caret-color: rgb(66 32 6 / 0.25) !important;
}

.tw-caret-yellow-950\/30 {
  caret-color: rgb(66 32 6 / 0.3) !important;
}

.tw-caret-yellow-950\/35 {
  caret-color: rgb(66 32 6 / 0.35) !important;
}

.tw-caret-yellow-950\/40 {
  caret-color: rgb(66 32 6 / 0.4) !important;
}

.tw-caret-yellow-950\/45 {
  caret-color: rgb(66 32 6 / 0.45) !important;
}

.tw-caret-yellow-950\/5 {
  caret-color: rgb(66 32 6 / 0.05) !important;
}

.tw-caret-yellow-950\/50 {
  caret-color: rgb(66 32 6 / 0.5) !important;
}

.tw-caret-yellow-950\/55 {
  caret-color: rgb(66 32 6 / 0.55) !important;
}

.tw-caret-yellow-950\/60 {
  caret-color: rgb(66 32 6 / 0.6) !important;
}

.tw-caret-yellow-950\/65 {
  caret-color: rgb(66 32 6 / 0.65) !important;
}

.tw-caret-yellow-950\/70 {
  caret-color: rgb(66 32 6 / 0.7) !important;
}

.tw-caret-yellow-950\/75 {
  caret-color: rgb(66 32 6 / 0.75) !important;
}

.tw-caret-yellow-950\/80 {
  caret-color: rgb(66 32 6 / 0.8) !important;
}

.tw-caret-yellow-950\/85 {
  caret-color: rgb(66 32 6 / 0.85) !important;
}

.tw-caret-yellow-950\/90 {
  caret-color: rgb(66 32 6 / 0.9) !important;
}

.tw-caret-yellow-950\/95 {
  caret-color: rgb(66 32 6 / 0.95) !important;
}

.tw-caret-zinc-100 {
  caret-color: #f4f4f5 !important;
}

.tw-caret-zinc-100\/0 {
  caret-color: rgb(244 244 245 / 0) !important;
}

.tw-caret-zinc-100\/10 {
  caret-color: rgb(244 244 245 / 0.1) !important;
}

.tw-caret-zinc-100\/100 {
  caret-color: rgb(244 244 245 / 1) !important;
}

.tw-caret-zinc-100\/15 {
  caret-color: rgb(244 244 245 / 0.15) !important;
}

.tw-caret-zinc-100\/20 {
  caret-color: rgb(244 244 245 / 0.2) !important;
}

.tw-caret-zinc-100\/25 {
  caret-color: rgb(244 244 245 / 0.25) !important;
}

.tw-caret-zinc-100\/30 {
  caret-color: rgb(244 244 245 / 0.3) !important;
}

.tw-caret-zinc-100\/35 {
  caret-color: rgb(244 244 245 / 0.35) !important;
}

.tw-caret-zinc-100\/40 {
  caret-color: rgb(244 244 245 / 0.4) !important;
}

.tw-caret-zinc-100\/45 {
  caret-color: rgb(244 244 245 / 0.45) !important;
}

.tw-caret-zinc-100\/5 {
  caret-color: rgb(244 244 245 / 0.05) !important;
}

.tw-caret-zinc-100\/50 {
  caret-color: rgb(244 244 245 / 0.5) !important;
}

.tw-caret-zinc-100\/55 {
  caret-color: rgb(244 244 245 / 0.55) !important;
}

.tw-caret-zinc-100\/60 {
  caret-color: rgb(244 244 245 / 0.6) !important;
}

.tw-caret-zinc-100\/65 {
  caret-color: rgb(244 244 245 / 0.65) !important;
}

.tw-caret-zinc-100\/70 {
  caret-color: rgb(244 244 245 / 0.7) !important;
}

.tw-caret-zinc-100\/75 {
  caret-color: rgb(244 244 245 / 0.75) !important;
}

.tw-caret-zinc-100\/80 {
  caret-color: rgb(244 244 245 / 0.8) !important;
}

.tw-caret-zinc-100\/85 {
  caret-color: rgb(244 244 245 / 0.85) !important;
}

.tw-caret-zinc-100\/90 {
  caret-color: rgb(244 244 245 / 0.9) !important;
}

.tw-caret-zinc-100\/95 {
  caret-color: rgb(244 244 245 / 0.95) !important;
}

.tw-caret-zinc-200 {
  caret-color: #e4e4e7 !important;
}

.tw-caret-zinc-200\/0 {
  caret-color: rgb(228 228 231 / 0) !important;
}

.tw-caret-zinc-200\/10 {
  caret-color: rgb(228 228 231 / 0.1) !important;
}

.tw-caret-zinc-200\/100 {
  caret-color: rgb(228 228 231 / 1) !important;
}

.tw-caret-zinc-200\/15 {
  caret-color: rgb(228 228 231 / 0.15) !important;
}

.tw-caret-zinc-200\/20 {
  caret-color: rgb(228 228 231 / 0.2) !important;
}

.tw-caret-zinc-200\/25 {
  caret-color: rgb(228 228 231 / 0.25) !important;
}

.tw-caret-zinc-200\/30 {
  caret-color: rgb(228 228 231 / 0.3) !important;
}

.tw-caret-zinc-200\/35 {
  caret-color: rgb(228 228 231 / 0.35) !important;
}

.tw-caret-zinc-200\/40 {
  caret-color: rgb(228 228 231 / 0.4) !important;
}

.tw-caret-zinc-200\/45 {
  caret-color: rgb(228 228 231 / 0.45) !important;
}

.tw-caret-zinc-200\/5 {
  caret-color: rgb(228 228 231 / 0.05) !important;
}

.tw-caret-zinc-200\/50 {
  caret-color: rgb(228 228 231 / 0.5) !important;
}

.tw-caret-zinc-200\/55 {
  caret-color: rgb(228 228 231 / 0.55) !important;
}

.tw-caret-zinc-200\/60 {
  caret-color: rgb(228 228 231 / 0.6) !important;
}

.tw-caret-zinc-200\/65 {
  caret-color: rgb(228 228 231 / 0.65) !important;
}

.tw-caret-zinc-200\/70 {
  caret-color: rgb(228 228 231 / 0.7) !important;
}

.tw-caret-zinc-200\/75 {
  caret-color: rgb(228 228 231 / 0.75) !important;
}

.tw-caret-zinc-200\/80 {
  caret-color: rgb(228 228 231 / 0.8) !important;
}

.tw-caret-zinc-200\/85 {
  caret-color: rgb(228 228 231 / 0.85) !important;
}

.tw-caret-zinc-200\/90 {
  caret-color: rgb(228 228 231 / 0.9) !important;
}

.tw-caret-zinc-200\/95 {
  caret-color: rgb(228 228 231 / 0.95) !important;
}

.tw-caret-zinc-300 {
  caret-color: #d4d4d8 !important;
}

.tw-caret-zinc-300\/0 {
  caret-color: rgb(212 212 216 / 0) !important;
}

.tw-caret-zinc-300\/10 {
  caret-color: rgb(212 212 216 / 0.1) !important;
}

.tw-caret-zinc-300\/100 {
  caret-color: rgb(212 212 216 / 1) !important;
}

.tw-caret-zinc-300\/15 {
  caret-color: rgb(212 212 216 / 0.15) !important;
}

.tw-caret-zinc-300\/20 {
  caret-color: rgb(212 212 216 / 0.2) !important;
}

.tw-caret-zinc-300\/25 {
  caret-color: rgb(212 212 216 / 0.25) !important;
}

.tw-caret-zinc-300\/30 {
  caret-color: rgb(212 212 216 / 0.3) !important;
}

.tw-caret-zinc-300\/35 {
  caret-color: rgb(212 212 216 / 0.35) !important;
}

.tw-caret-zinc-300\/40 {
  caret-color: rgb(212 212 216 / 0.4) !important;
}

.tw-caret-zinc-300\/45 {
  caret-color: rgb(212 212 216 / 0.45) !important;
}

.tw-caret-zinc-300\/5 {
  caret-color: rgb(212 212 216 / 0.05) !important;
}

.tw-caret-zinc-300\/50 {
  caret-color: rgb(212 212 216 / 0.5) !important;
}

.tw-caret-zinc-300\/55 {
  caret-color: rgb(212 212 216 / 0.55) !important;
}

.tw-caret-zinc-300\/60 {
  caret-color: rgb(212 212 216 / 0.6) !important;
}

.tw-caret-zinc-300\/65 {
  caret-color: rgb(212 212 216 / 0.65) !important;
}

.tw-caret-zinc-300\/70 {
  caret-color: rgb(212 212 216 / 0.7) !important;
}

.tw-caret-zinc-300\/75 {
  caret-color: rgb(212 212 216 / 0.75) !important;
}

.tw-caret-zinc-300\/80 {
  caret-color: rgb(212 212 216 / 0.8) !important;
}

.tw-caret-zinc-300\/85 {
  caret-color: rgb(212 212 216 / 0.85) !important;
}

.tw-caret-zinc-300\/90 {
  caret-color: rgb(212 212 216 / 0.9) !important;
}

.tw-caret-zinc-300\/95 {
  caret-color: rgb(212 212 216 / 0.95) !important;
}

.tw-caret-zinc-400 {
  caret-color: #a1a1aa !important;
}

.tw-caret-zinc-400\/0 {
  caret-color: rgb(161 161 170 / 0) !important;
}

.tw-caret-zinc-400\/10 {
  caret-color: rgb(161 161 170 / 0.1) !important;
}

.tw-caret-zinc-400\/100 {
  caret-color: rgb(161 161 170 / 1) !important;
}

.tw-caret-zinc-400\/15 {
  caret-color: rgb(161 161 170 / 0.15) !important;
}

.tw-caret-zinc-400\/20 {
  caret-color: rgb(161 161 170 / 0.2) !important;
}

.tw-caret-zinc-400\/25 {
  caret-color: rgb(161 161 170 / 0.25) !important;
}

.tw-caret-zinc-400\/30 {
  caret-color: rgb(161 161 170 / 0.3) !important;
}

.tw-caret-zinc-400\/35 {
  caret-color: rgb(161 161 170 / 0.35) !important;
}

.tw-caret-zinc-400\/40 {
  caret-color: rgb(161 161 170 / 0.4) !important;
}

.tw-caret-zinc-400\/45 {
  caret-color: rgb(161 161 170 / 0.45) !important;
}

.tw-caret-zinc-400\/5 {
  caret-color: rgb(161 161 170 / 0.05) !important;
}

.tw-caret-zinc-400\/50 {
  caret-color: rgb(161 161 170 / 0.5) !important;
}

.tw-caret-zinc-400\/55 {
  caret-color: rgb(161 161 170 / 0.55) !important;
}

.tw-caret-zinc-400\/60 {
  caret-color: rgb(161 161 170 / 0.6) !important;
}

.tw-caret-zinc-400\/65 {
  caret-color: rgb(161 161 170 / 0.65) !important;
}

.tw-caret-zinc-400\/70 {
  caret-color: rgb(161 161 170 / 0.7) !important;
}

.tw-caret-zinc-400\/75 {
  caret-color: rgb(161 161 170 / 0.75) !important;
}

.tw-caret-zinc-400\/80 {
  caret-color: rgb(161 161 170 / 0.8) !important;
}

.tw-caret-zinc-400\/85 {
  caret-color: rgb(161 161 170 / 0.85) !important;
}

.tw-caret-zinc-400\/90 {
  caret-color: rgb(161 161 170 / 0.9) !important;
}

.tw-caret-zinc-400\/95 {
  caret-color: rgb(161 161 170 / 0.95) !important;
}

.tw-caret-zinc-50 {
  caret-color: #fafafa !important;
}

.tw-caret-zinc-50\/0 {
  caret-color: rgb(250 250 250 / 0) !important;
}

.tw-caret-zinc-50\/10 {
  caret-color: rgb(250 250 250 / 0.1) !important;
}

.tw-caret-zinc-50\/100 {
  caret-color: rgb(250 250 250 / 1) !important;
}

.tw-caret-zinc-50\/15 {
  caret-color: rgb(250 250 250 / 0.15) !important;
}

.tw-caret-zinc-50\/20 {
  caret-color: rgb(250 250 250 / 0.2) !important;
}

.tw-caret-zinc-50\/25 {
  caret-color: rgb(250 250 250 / 0.25) !important;
}

.tw-caret-zinc-50\/30 {
  caret-color: rgb(250 250 250 / 0.3) !important;
}

.tw-caret-zinc-50\/35 {
  caret-color: rgb(250 250 250 / 0.35) !important;
}

.tw-caret-zinc-50\/40 {
  caret-color: rgb(250 250 250 / 0.4) !important;
}

.tw-caret-zinc-50\/45 {
  caret-color: rgb(250 250 250 / 0.45) !important;
}

.tw-caret-zinc-50\/5 {
  caret-color: rgb(250 250 250 / 0.05) !important;
}

.tw-caret-zinc-50\/50 {
  caret-color: rgb(250 250 250 / 0.5) !important;
}

.tw-caret-zinc-50\/55 {
  caret-color: rgb(250 250 250 / 0.55) !important;
}

.tw-caret-zinc-50\/60 {
  caret-color: rgb(250 250 250 / 0.6) !important;
}

.tw-caret-zinc-50\/65 {
  caret-color: rgb(250 250 250 / 0.65) !important;
}

.tw-caret-zinc-50\/70 {
  caret-color: rgb(250 250 250 / 0.7) !important;
}

.tw-caret-zinc-50\/75 {
  caret-color: rgb(250 250 250 / 0.75) !important;
}

.tw-caret-zinc-50\/80 {
  caret-color: rgb(250 250 250 / 0.8) !important;
}

.tw-caret-zinc-50\/85 {
  caret-color: rgb(250 250 250 / 0.85) !important;
}

.tw-caret-zinc-50\/90 {
  caret-color: rgb(250 250 250 / 0.9) !important;
}

.tw-caret-zinc-50\/95 {
  caret-color: rgb(250 250 250 / 0.95) !important;
}

.tw-caret-zinc-500 {
  caret-color: #71717a !important;
}

.tw-caret-zinc-500\/0 {
  caret-color: rgb(113 113 122 / 0) !important;
}

.tw-caret-zinc-500\/10 {
  caret-color: rgb(113 113 122 / 0.1) !important;
}

.tw-caret-zinc-500\/100 {
  caret-color: rgb(113 113 122 / 1) !important;
}

.tw-caret-zinc-500\/15 {
  caret-color: rgb(113 113 122 / 0.15) !important;
}

.tw-caret-zinc-500\/20 {
  caret-color: rgb(113 113 122 / 0.2) !important;
}

.tw-caret-zinc-500\/25 {
  caret-color: rgb(113 113 122 / 0.25) !important;
}

.tw-caret-zinc-500\/30 {
  caret-color: rgb(113 113 122 / 0.3) !important;
}

.tw-caret-zinc-500\/35 {
  caret-color: rgb(113 113 122 / 0.35) !important;
}

.tw-caret-zinc-500\/40 {
  caret-color: rgb(113 113 122 / 0.4) !important;
}

.tw-caret-zinc-500\/45 {
  caret-color: rgb(113 113 122 / 0.45) !important;
}

.tw-caret-zinc-500\/5 {
  caret-color: rgb(113 113 122 / 0.05) !important;
}

.tw-caret-zinc-500\/50 {
  caret-color: rgb(113 113 122 / 0.5) !important;
}

.tw-caret-zinc-500\/55 {
  caret-color: rgb(113 113 122 / 0.55) !important;
}

.tw-caret-zinc-500\/60 {
  caret-color: rgb(113 113 122 / 0.6) !important;
}

.tw-caret-zinc-500\/65 {
  caret-color: rgb(113 113 122 / 0.65) !important;
}

.tw-caret-zinc-500\/70 {
  caret-color: rgb(113 113 122 / 0.7) !important;
}

.tw-caret-zinc-500\/75 {
  caret-color: rgb(113 113 122 / 0.75) !important;
}

.tw-caret-zinc-500\/80 {
  caret-color: rgb(113 113 122 / 0.8) !important;
}

.tw-caret-zinc-500\/85 {
  caret-color: rgb(113 113 122 / 0.85) !important;
}

.tw-caret-zinc-500\/90 {
  caret-color: rgb(113 113 122 / 0.9) !important;
}

.tw-caret-zinc-500\/95 {
  caret-color: rgb(113 113 122 / 0.95) !important;
}

.tw-caret-zinc-600 {
  caret-color: #52525b !important;
}

.tw-caret-zinc-600\/0 {
  caret-color: rgb(82 82 91 / 0) !important;
}

.tw-caret-zinc-600\/10 {
  caret-color: rgb(82 82 91 / 0.1) !important;
}

.tw-caret-zinc-600\/100 {
  caret-color: rgb(82 82 91 / 1) !important;
}

.tw-caret-zinc-600\/15 {
  caret-color: rgb(82 82 91 / 0.15) !important;
}

.tw-caret-zinc-600\/20 {
  caret-color: rgb(82 82 91 / 0.2) !important;
}

.tw-caret-zinc-600\/25 {
  caret-color: rgb(82 82 91 / 0.25) !important;
}

.tw-caret-zinc-600\/30 {
  caret-color: rgb(82 82 91 / 0.3) !important;
}

.tw-caret-zinc-600\/35 {
  caret-color: rgb(82 82 91 / 0.35) !important;
}

.tw-caret-zinc-600\/40 {
  caret-color: rgb(82 82 91 / 0.4) !important;
}

.tw-caret-zinc-600\/45 {
  caret-color: rgb(82 82 91 / 0.45) !important;
}

.tw-caret-zinc-600\/5 {
  caret-color: rgb(82 82 91 / 0.05) !important;
}

.tw-caret-zinc-600\/50 {
  caret-color: rgb(82 82 91 / 0.5) !important;
}

.tw-caret-zinc-600\/55 {
  caret-color: rgb(82 82 91 / 0.55) !important;
}

.tw-caret-zinc-600\/60 {
  caret-color: rgb(82 82 91 / 0.6) !important;
}

.tw-caret-zinc-600\/65 {
  caret-color: rgb(82 82 91 / 0.65) !important;
}

.tw-caret-zinc-600\/70 {
  caret-color: rgb(82 82 91 / 0.7) !important;
}

.tw-caret-zinc-600\/75 {
  caret-color: rgb(82 82 91 / 0.75) !important;
}

.tw-caret-zinc-600\/80 {
  caret-color: rgb(82 82 91 / 0.8) !important;
}

.tw-caret-zinc-600\/85 {
  caret-color: rgb(82 82 91 / 0.85) !important;
}

.tw-caret-zinc-600\/90 {
  caret-color: rgb(82 82 91 / 0.9) !important;
}

.tw-caret-zinc-600\/95 {
  caret-color: rgb(82 82 91 / 0.95) !important;
}

.tw-caret-zinc-700 {
  caret-color: #3f3f46 !important;
}

.tw-caret-zinc-700\/0 {
  caret-color: rgb(63 63 70 / 0) !important;
}

.tw-caret-zinc-700\/10 {
  caret-color: rgb(63 63 70 / 0.1) !important;
}

.tw-caret-zinc-700\/100 {
  caret-color: rgb(63 63 70 / 1) !important;
}

.tw-caret-zinc-700\/15 {
  caret-color: rgb(63 63 70 / 0.15) !important;
}

.tw-caret-zinc-700\/20 {
  caret-color: rgb(63 63 70 / 0.2) !important;
}

.tw-caret-zinc-700\/25 {
  caret-color: rgb(63 63 70 / 0.25) !important;
}

.tw-caret-zinc-700\/30 {
  caret-color: rgb(63 63 70 / 0.3) !important;
}

.tw-caret-zinc-700\/35 {
  caret-color: rgb(63 63 70 / 0.35) !important;
}

.tw-caret-zinc-700\/40 {
  caret-color: rgb(63 63 70 / 0.4) !important;
}

.tw-caret-zinc-700\/45 {
  caret-color: rgb(63 63 70 / 0.45) !important;
}

.tw-caret-zinc-700\/5 {
  caret-color: rgb(63 63 70 / 0.05) !important;
}

.tw-caret-zinc-700\/50 {
  caret-color: rgb(63 63 70 / 0.5) !important;
}

.tw-caret-zinc-700\/55 {
  caret-color: rgb(63 63 70 / 0.55) !important;
}

.tw-caret-zinc-700\/60 {
  caret-color: rgb(63 63 70 / 0.6) !important;
}

.tw-caret-zinc-700\/65 {
  caret-color: rgb(63 63 70 / 0.65) !important;
}

.tw-caret-zinc-700\/70 {
  caret-color: rgb(63 63 70 / 0.7) !important;
}

.tw-caret-zinc-700\/75 {
  caret-color: rgb(63 63 70 / 0.75) !important;
}

.tw-caret-zinc-700\/80 {
  caret-color: rgb(63 63 70 / 0.8) !important;
}

.tw-caret-zinc-700\/85 {
  caret-color: rgb(63 63 70 / 0.85) !important;
}

.tw-caret-zinc-700\/90 {
  caret-color: rgb(63 63 70 / 0.9) !important;
}

.tw-caret-zinc-700\/95 {
  caret-color: rgb(63 63 70 / 0.95) !important;
}

.tw-caret-zinc-800 {
  caret-color: #27272a !important;
}

.tw-caret-zinc-800\/0 {
  caret-color: rgb(39 39 42 / 0) !important;
}

.tw-caret-zinc-800\/10 {
  caret-color: rgb(39 39 42 / 0.1) !important;
}

.tw-caret-zinc-800\/100 {
  caret-color: rgb(39 39 42 / 1) !important;
}

.tw-caret-zinc-800\/15 {
  caret-color: rgb(39 39 42 / 0.15) !important;
}

.tw-caret-zinc-800\/20 {
  caret-color: rgb(39 39 42 / 0.2) !important;
}

.tw-caret-zinc-800\/25 {
  caret-color: rgb(39 39 42 / 0.25) !important;
}

.tw-caret-zinc-800\/30 {
  caret-color: rgb(39 39 42 / 0.3) !important;
}

.tw-caret-zinc-800\/35 {
  caret-color: rgb(39 39 42 / 0.35) !important;
}

.tw-caret-zinc-800\/40 {
  caret-color: rgb(39 39 42 / 0.4) !important;
}

.tw-caret-zinc-800\/45 {
  caret-color: rgb(39 39 42 / 0.45) !important;
}

.tw-caret-zinc-800\/5 {
  caret-color: rgb(39 39 42 / 0.05) !important;
}

.tw-caret-zinc-800\/50 {
  caret-color: rgb(39 39 42 / 0.5) !important;
}

.tw-caret-zinc-800\/55 {
  caret-color: rgb(39 39 42 / 0.55) !important;
}

.tw-caret-zinc-800\/60 {
  caret-color: rgb(39 39 42 / 0.6) !important;
}

.tw-caret-zinc-800\/65 {
  caret-color: rgb(39 39 42 / 0.65) !important;
}

.tw-caret-zinc-800\/70 {
  caret-color: rgb(39 39 42 / 0.7) !important;
}

.tw-caret-zinc-800\/75 {
  caret-color: rgb(39 39 42 / 0.75) !important;
}

.tw-caret-zinc-800\/80 {
  caret-color: rgb(39 39 42 / 0.8) !important;
}

.tw-caret-zinc-800\/85 {
  caret-color: rgb(39 39 42 / 0.85) !important;
}

.tw-caret-zinc-800\/90 {
  caret-color: rgb(39 39 42 / 0.9) !important;
}

.tw-caret-zinc-800\/95 {
  caret-color: rgb(39 39 42 / 0.95) !important;
}

.tw-caret-zinc-900 {
  caret-color: #18181b !important;
}

.tw-caret-zinc-900\/0 {
  caret-color: rgb(24 24 27 / 0) !important;
}

.tw-caret-zinc-900\/10 {
  caret-color: rgb(24 24 27 / 0.1) !important;
}

.tw-caret-zinc-900\/100 {
  caret-color: rgb(24 24 27 / 1) !important;
}

.tw-caret-zinc-900\/15 {
  caret-color: rgb(24 24 27 / 0.15) !important;
}

.tw-caret-zinc-900\/20 {
  caret-color: rgb(24 24 27 / 0.2) !important;
}

.tw-caret-zinc-900\/25 {
  caret-color: rgb(24 24 27 / 0.25) !important;
}

.tw-caret-zinc-900\/30 {
  caret-color: rgb(24 24 27 / 0.3) !important;
}

.tw-caret-zinc-900\/35 {
  caret-color: rgb(24 24 27 / 0.35) !important;
}

.tw-caret-zinc-900\/40 {
  caret-color: rgb(24 24 27 / 0.4) !important;
}

.tw-caret-zinc-900\/45 {
  caret-color: rgb(24 24 27 / 0.45) !important;
}

.tw-caret-zinc-900\/5 {
  caret-color: rgb(24 24 27 / 0.05) !important;
}

.tw-caret-zinc-900\/50 {
  caret-color: rgb(24 24 27 / 0.5) !important;
}

.tw-caret-zinc-900\/55 {
  caret-color: rgb(24 24 27 / 0.55) !important;
}

.tw-caret-zinc-900\/60 {
  caret-color: rgb(24 24 27 / 0.6) !important;
}

.tw-caret-zinc-900\/65 {
  caret-color: rgb(24 24 27 / 0.65) !important;
}

.tw-caret-zinc-900\/70 {
  caret-color: rgb(24 24 27 / 0.7) !important;
}

.tw-caret-zinc-900\/75 {
  caret-color: rgb(24 24 27 / 0.75) !important;
}

.tw-caret-zinc-900\/80 {
  caret-color: rgb(24 24 27 / 0.8) !important;
}

.tw-caret-zinc-900\/85 {
  caret-color: rgb(24 24 27 / 0.85) !important;
}

.tw-caret-zinc-900\/90 {
  caret-color: rgb(24 24 27 / 0.9) !important;
}

.tw-caret-zinc-900\/95 {
  caret-color: rgb(24 24 27 / 0.95) !important;
}

.tw-caret-zinc-950 {
  caret-color: #09090b !important;
}

.tw-caret-zinc-950\/0 {
  caret-color: rgb(9 9 11 / 0) !important;
}

.tw-caret-zinc-950\/10 {
  caret-color: rgb(9 9 11 / 0.1) !important;
}

.tw-caret-zinc-950\/100 {
  caret-color: rgb(9 9 11 / 1) !important;
}

.tw-caret-zinc-950\/15 {
  caret-color: rgb(9 9 11 / 0.15) !important;
}

.tw-caret-zinc-950\/20 {
  caret-color: rgb(9 9 11 / 0.2) !important;
}

.tw-caret-zinc-950\/25 {
  caret-color: rgb(9 9 11 / 0.25) !important;
}

.tw-caret-zinc-950\/30 {
  caret-color: rgb(9 9 11 / 0.3) !important;
}

.tw-caret-zinc-950\/35 {
  caret-color: rgb(9 9 11 / 0.35) !important;
}

.tw-caret-zinc-950\/40 {
  caret-color: rgb(9 9 11 / 0.4) !important;
}

.tw-caret-zinc-950\/45 {
  caret-color: rgb(9 9 11 / 0.45) !important;
}

.tw-caret-zinc-950\/5 {
  caret-color: rgb(9 9 11 / 0.05) !important;
}

.tw-caret-zinc-950\/50 {
  caret-color: rgb(9 9 11 / 0.5) !important;
}

.tw-caret-zinc-950\/55 {
  caret-color: rgb(9 9 11 / 0.55) !important;
}

.tw-caret-zinc-950\/60 {
  caret-color: rgb(9 9 11 / 0.6) !important;
}

.tw-caret-zinc-950\/65 {
  caret-color: rgb(9 9 11 / 0.65) !important;
}

.tw-caret-zinc-950\/70 {
  caret-color: rgb(9 9 11 / 0.7) !important;
}

.tw-caret-zinc-950\/75 {
  caret-color: rgb(9 9 11 / 0.75) !important;
}

.tw-caret-zinc-950\/80 {
  caret-color: rgb(9 9 11 / 0.8) !important;
}

.tw-caret-zinc-950\/85 {
  caret-color: rgb(9 9 11 / 0.85) !important;
}

.tw-caret-zinc-950\/90 {
  caret-color: rgb(9 9 11 / 0.9) !important;
}

.tw-caret-zinc-950\/95 {
  caret-color: rgb(9 9 11 / 0.95) !important;
}

.tw-accent-amber-100 {
  accent-color: #fef3c7 !important;
}

.tw-accent-amber-100\/0 {
  accent-color: rgb(254 243 199 / 0) !important;
}

.tw-accent-amber-100\/10 {
  accent-color: rgb(254 243 199 / 0.1) !important;
}

.tw-accent-amber-100\/100 {
  accent-color: rgb(254 243 199 / 1) !important;
}

.tw-accent-amber-100\/15 {
  accent-color: rgb(254 243 199 / 0.15) !important;
}

.tw-accent-amber-100\/20 {
  accent-color: rgb(254 243 199 / 0.2) !important;
}

.tw-accent-amber-100\/25 {
  accent-color: rgb(254 243 199 / 0.25) !important;
}

.tw-accent-amber-100\/30 {
  accent-color: rgb(254 243 199 / 0.3) !important;
}

.tw-accent-amber-100\/35 {
  accent-color: rgb(254 243 199 / 0.35) !important;
}

.tw-accent-amber-100\/40 {
  accent-color: rgb(254 243 199 / 0.4) !important;
}

.tw-accent-amber-100\/45 {
  accent-color: rgb(254 243 199 / 0.45) !important;
}

.tw-accent-amber-100\/5 {
  accent-color: rgb(254 243 199 / 0.05) !important;
}

.tw-accent-amber-100\/50 {
  accent-color: rgb(254 243 199 / 0.5) !important;
}

.tw-accent-amber-100\/55 {
  accent-color: rgb(254 243 199 / 0.55) !important;
}

.tw-accent-amber-100\/60 {
  accent-color: rgb(254 243 199 / 0.6) !important;
}

.tw-accent-amber-100\/65 {
  accent-color: rgb(254 243 199 / 0.65) !important;
}

.tw-accent-amber-100\/70 {
  accent-color: rgb(254 243 199 / 0.7) !important;
}

.tw-accent-amber-100\/75 {
  accent-color: rgb(254 243 199 / 0.75) !important;
}

.tw-accent-amber-100\/80 {
  accent-color: rgb(254 243 199 / 0.8) !important;
}

.tw-accent-amber-100\/85 {
  accent-color: rgb(254 243 199 / 0.85) !important;
}

.tw-accent-amber-100\/90 {
  accent-color: rgb(254 243 199 / 0.9) !important;
}

.tw-accent-amber-100\/95 {
  accent-color: rgb(254 243 199 / 0.95) !important;
}

.tw-accent-amber-200 {
  accent-color: #fde68a !important;
}

.tw-accent-amber-200\/0 {
  accent-color: rgb(253 230 138 / 0) !important;
}

.tw-accent-amber-200\/10 {
  accent-color: rgb(253 230 138 / 0.1) !important;
}

.tw-accent-amber-200\/100 {
  accent-color: rgb(253 230 138 / 1) !important;
}

.tw-accent-amber-200\/15 {
  accent-color: rgb(253 230 138 / 0.15) !important;
}

.tw-accent-amber-200\/20 {
  accent-color: rgb(253 230 138 / 0.2) !important;
}

.tw-accent-amber-200\/25 {
  accent-color: rgb(253 230 138 / 0.25) !important;
}

.tw-accent-amber-200\/30 {
  accent-color: rgb(253 230 138 / 0.3) !important;
}

.tw-accent-amber-200\/35 {
  accent-color: rgb(253 230 138 / 0.35) !important;
}

.tw-accent-amber-200\/40 {
  accent-color: rgb(253 230 138 / 0.4) !important;
}

.tw-accent-amber-200\/45 {
  accent-color: rgb(253 230 138 / 0.45) !important;
}

.tw-accent-amber-200\/5 {
  accent-color: rgb(253 230 138 / 0.05) !important;
}

.tw-accent-amber-200\/50 {
  accent-color: rgb(253 230 138 / 0.5) !important;
}

.tw-accent-amber-200\/55 {
  accent-color: rgb(253 230 138 / 0.55) !important;
}

.tw-accent-amber-200\/60 {
  accent-color: rgb(253 230 138 / 0.6) !important;
}

.tw-accent-amber-200\/65 {
  accent-color: rgb(253 230 138 / 0.65) !important;
}

.tw-accent-amber-200\/70 {
  accent-color: rgb(253 230 138 / 0.7) !important;
}

.tw-accent-amber-200\/75 {
  accent-color: rgb(253 230 138 / 0.75) !important;
}

.tw-accent-amber-200\/80 {
  accent-color: rgb(253 230 138 / 0.8) !important;
}

.tw-accent-amber-200\/85 {
  accent-color: rgb(253 230 138 / 0.85) !important;
}

.tw-accent-amber-200\/90 {
  accent-color: rgb(253 230 138 / 0.9) !important;
}

.tw-accent-amber-200\/95 {
  accent-color: rgb(253 230 138 / 0.95) !important;
}

.tw-accent-amber-300 {
  accent-color: #fcd34d !important;
}

.tw-accent-amber-300\/0 {
  accent-color: rgb(252 211 77 / 0) !important;
}

.tw-accent-amber-300\/10 {
  accent-color: rgb(252 211 77 / 0.1) !important;
}

.tw-accent-amber-300\/100 {
  accent-color: rgb(252 211 77 / 1) !important;
}

.tw-accent-amber-300\/15 {
  accent-color: rgb(252 211 77 / 0.15) !important;
}

.tw-accent-amber-300\/20 {
  accent-color: rgb(252 211 77 / 0.2) !important;
}

.tw-accent-amber-300\/25 {
  accent-color: rgb(252 211 77 / 0.25) !important;
}

.tw-accent-amber-300\/30 {
  accent-color: rgb(252 211 77 / 0.3) !important;
}

.tw-accent-amber-300\/35 {
  accent-color: rgb(252 211 77 / 0.35) !important;
}

.tw-accent-amber-300\/40 {
  accent-color: rgb(252 211 77 / 0.4) !important;
}

.tw-accent-amber-300\/45 {
  accent-color: rgb(252 211 77 / 0.45) !important;
}

.tw-accent-amber-300\/5 {
  accent-color: rgb(252 211 77 / 0.05) !important;
}

.tw-accent-amber-300\/50 {
  accent-color: rgb(252 211 77 / 0.5) !important;
}

.tw-accent-amber-300\/55 {
  accent-color: rgb(252 211 77 / 0.55) !important;
}

.tw-accent-amber-300\/60 {
  accent-color: rgb(252 211 77 / 0.6) !important;
}

.tw-accent-amber-300\/65 {
  accent-color: rgb(252 211 77 / 0.65) !important;
}

.tw-accent-amber-300\/70 {
  accent-color: rgb(252 211 77 / 0.7) !important;
}

.tw-accent-amber-300\/75 {
  accent-color: rgb(252 211 77 / 0.75) !important;
}

.tw-accent-amber-300\/80 {
  accent-color: rgb(252 211 77 / 0.8) !important;
}

.tw-accent-amber-300\/85 {
  accent-color: rgb(252 211 77 / 0.85) !important;
}

.tw-accent-amber-300\/90 {
  accent-color: rgb(252 211 77 / 0.9) !important;
}

.tw-accent-amber-300\/95 {
  accent-color: rgb(252 211 77 / 0.95) !important;
}

.tw-accent-amber-400 {
  accent-color: #fbbf24 !important;
}

.tw-accent-amber-400\/0 {
  accent-color: rgb(251 191 36 / 0) !important;
}

.tw-accent-amber-400\/10 {
  accent-color: rgb(251 191 36 / 0.1) !important;
}

.tw-accent-amber-400\/100 {
  accent-color: rgb(251 191 36 / 1) !important;
}

.tw-accent-amber-400\/15 {
  accent-color: rgb(251 191 36 / 0.15) !important;
}

.tw-accent-amber-400\/20 {
  accent-color: rgb(251 191 36 / 0.2) !important;
}

.tw-accent-amber-400\/25 {
  accent-color: rgb(251 191 36 / 0.25) !important;
}

.tw-accent-amber-400\/30 {
  accent-color: rgb(251 191 36 / 0.3) !important;
}

.tw-accent-amber-400\/35 {
  accent-color: rgb(251 191 36 / 0.35) !important;
}

.tw-accent-amber-400\/40 {
  accent-color: rgb(251 191 36 / 0.4) !important;
}

.tw-accent-amber-400\/45 {
  accent-color: rgb(251 191 36 / 0.45) !important;
}

.tw-accent-amber-400\/5 {
  accent-color: rgb(251 191 36 / 0.05) !important;
}

.tw-accent-amber-400\/50 {
  accent-color: rgb(251 191 36 / 0.5) !important;
}

.tw-accent-amber-400\/55 {
  accent-color: rgb(251 191 36 / 0.55) !important;
}

.tw-accent-amber-400\/60 {
  accent-color: rgb(251 191 36 / 0.6) !important;
}

.tw-accent-amber-400\/65 {
  accent-color: rgb(251 191 36 / 0.65) !important;
}

.tw-accent-amber-400\/70 {
  accent-color: rgb(251 191 36 / 0.7) !important;
}

.tw-accent-amber-400\/75 {
  accent-color: rgb(251 191 36 / 0.75) !important;
}

.tw-accent-amber-400\/80 {
  accent-color: rgb(251 191 36 / 0.8) !important;
}

.tw-accent-amber-400\/85 {
  accent-color: rgb(251 191 36 / 0.85) !important;
}

.tw-accent-amber-400\/90 {
  accent-color: rgb(251 191 36 / 0.9) !important;
}

.tw-accent-amber-400\/95 {
  accent-color: rgb(251 191 36 / 0.95) !important;
}

.tw-accent-amber-50 {
  accent-color: #fffbeb !important;
}

.tw-accent-amber-50\/0 {
  accent-color: rgb(255 251 235 / 0) !important;
}

.tw-accent-amber-50\/10 {
  accent-color: rgb(255 251 235 / 0.1) !important;
}

.tw-accent-amber-50\/100 {
  accent-color: rgb(255 251 235 / 1) !important;
}

.tw-accent-amber-50\/15 {
  accent-color: rgb(255 251 235 / 0.15) !important;
}

.tw-accent-amber-50\/20 {
  accent-color: rgb(255 251 235 / 0.2) !important;
}

.tw-accent-amber-50\/25 {
  accent-color: rgb(255 251 235 / 0.25) !important;
}

.tw-accent-amber-50\/30 {
  accent-color: rgb(255 251 235 / 0.3) !important;
}

.tw-accent-amber-50\/35 {
  accent-color: rgb(255 251 235 / 0.35) !important;
}

.tw-accent-amber-50\/40 {
  accent-color: rgb(255 251 235 / 0.4) !important;
}

.tw-accent-amber-50\/45 {
  accent-color: rgb(255 251 235 / 0.45) !important;
}

.tw-accent-amber-50\/5 {
  accent-color: rgb(255 251 235 / 0.05) !important;
}

.tw-accent-amber-50\/50 {
  accent-color: rgb(255 251 235 / 0.5) !important;
}

.tw-accent-amber-50\/55 {
  accent-color: rgb(255 251 235 / 0.55) !important;
}

.tw-accent-amber-50\/60 {
  accent-color: rgb(255 251 235 / 0.6) !important;
}

.tw-accent-amber-50\/65 {
  accent-color: rgb(255 251 235 / 0.65) !important;
}

.tw-accent-amber-50\/70 {
  accent-color: rgb(255 251 235 / 0.7) !important;
}

.tw-accent-amber-50\/75 {
  accent-color: rgb(255 251 235 / 0.75) !important;
}

.tw-accent-amber-50\/80 {
  accent-color: rgb(255 251 235 / 0.8) !important;
}

.tw-accent-amber-50\/85 {
  accent-color: rgb(255 251 235 / 0.85) !important;
}

.tw-accent-amber-50\/90 {
  accent-color: rgb(255 251 235 / 0.9) !important;
}

.tw-accent-amber-50\/95 {
  accent-color: rgb(255 251 235 / 0.95) !important;
}

.tw-accent-amber-500 {
  accent-color: #f59e0b !important;
}

.tw-accent-amber-500\/0 {
  accent-color: rgb(245 158 11 / 0) !important;
}

.tw-accent-amber-500\/10 {
  accent-color: rgb(245 158 11 / 0.1) !important;
}

.tw-accent-amber-500\/100 {
  accent-color: rgb(245 158 11 / 1) !important;
}

.tw-accent-amber-500\/15 {
  accent-color: rgb(245 158 11 / 0.15) !important;
}

.tw-accent-amber-500\/20 {
  accent-color: rgb(245 158 11 / 0.2) !important;
}

.tw-accent-amber-500\/25 {
  accent-color: rgb(245 158 11 / 0.25) !important;
}

.tw-accent-amber-500\/30 {
  accent-color: rgb(245 158 11 / 0.3) !important;
}

.tw-accent-amber-500\/35 {
  accent-color: rgb(245 158 11 / 0.35) !important;
}

.tw-accent-amber-500\/40 {
  accent-color: rgb(245 158 11 / 0.4) !important;
}

.tw-accent-amber-500\/45 {
  accent-color: rgb(245 158 11 / 0.45) !important;
}

.tw-accent-amber-500\/5 {
  accent-color: rgb(245 158 11 / 0.05) !important;
}

.tw-accent-amber-500\/50 {
  accent-color: rgb(245 158 11 / 0.5) !important;
}

.tw-accent-amber-500\/55 {
  accent-color: rgb(245 158 11 / 0.55) !important;
}

.tw-accent-amber-500\/60 {
  accent-color: rgb(245 158 11 / 0.6) !important;
}

.tw-accent-amber-500\/65 {
  accent-color: rgb(245 158 11 / 0.65) !important;
}

.tw-accent-amber-500\/70 {
  accent-color: rgb(245 158 11 / 0.7) !important;
}

.tw-accent-amber-500\/75 {
  accent-color: rgb(245 158 11 / 0.75) !important;
}

.tw-accent-amber-500\/80 {
  accent-color: rgb(245 158 11 / 0.8) !important;
}

.tw-accent-amber-500\/85 {
  accent-color: rgb(245 158 11 / 0.85) !important;
}

.tw-accent-amber-500\/90 {
  accent-color: rgb(245 158 11 / 0.9) !important;
}

.tw-accent-amber-500\/95 {
  accent-color: rgb(245 158 11 / 0.95) !important;
}

.tw-accent-amber-600 {
  accent-color: #d97706 !important;
}

.tw-accent-amber-600\/0 {
  accent-color: rgb(217 119 6 / 0) !important;
}

.tw-accent-amber-600\/10 {
  accent-color: rgb(217 119 6 / 0.1) !important;
}

.tw-accent-amber-600\/100 {
  accent-color: rgb(217 119 6 / 1) !important;
}

.tw-accent-amber-600\/15 {
  accent-color: rgb(217 119 6 / 0.15) !important;
}

.tw-accent-amber-600\/20 {
  accent-color: rgb(217 119 6 / 0.2) !important;
}

.tw-accent-amber-600\/25 {
  accent-color: rgb(217 119 6 / 0.25) !important;
}

.tw-accent-amber-600\/30 {
  accent-color: rgb(217 119 6 / 0.3) !important;
}

.tw-accent-amber-600\/35 {
  accent-color: rgb(217 119 6 / 0.35) !important;
}

.tw-accent-amber-600\/40 {
  accent-color: rgb(217 119 6 / 0.4) !important;
}

.tw-accent-amber-600\/45 {
  accent-color: rgb(217 119 6 / 0.45) !important;
}

.tw-accent-amber-600\/5 {
  accent-color: rgb(217 119 6 / 0.05) !important;
}

.tw-accent-amber-600\/50 {
  accent-color: rgb(217 119 6 / 0.5) !important;
}

.tw-accent-amber-600\/55 {
  accent-color: rgb(217 119 6 / 0.55) !important;
}

.tw-accent-amber-600\/60 {
  accent-color: rgb(217 119 6 / 0.6) !important;
}

.tw-accent-amber-600\/65 {
  accent-color: rgb(217 119 6 / 0.65) !important;
}

.tw-accent-amber-600\/70 {
  accent-color: rgb(217 119 6 / 0.7) !important;
}

.tw-accent-amber-600\/75 {
  accent-color: rgb(217 119 6 / 0.75) !important;
}

.tw-accent-amber-600\/80 {
  accent-color: rgb(217 119 6 / 0.8) !important;
}

.tw-accent-amber-600\/85 {
  accent-color: rgb(217 119 6 / 0.85) !important;
}

.tw-accent-amber-600\/90 {
  accent-color: rgb(217 119 6 / 0.9) !important;
}

.tw-accent-amber-600\/95 {
  accent-color: rgb(217 119 6 / 0.95) !important;
}

.tw-accent-amber-700 {
  accent-color: #b45309 !important;
}

.tw-accent-amber-700\/0 {
  accent-color: rgb(180 83 9 / 0) !important;
}

.tw-accent-amber-700\/10 {
  accent-color: rgb(180 83 9 / 0.1) !important;
}

.tw-accent-amber-700\/100 {
  accent-color: rgb(180 83 9 / 1) !important;
}

.tw-accent-amber-700\/15 {
  accent-color: rgb(180 83 9 / 0.15) !important;
}

.tw-accent-amber-700\/20 {
  accent-color: rgb(180 83 9 / 0.2) !important;
}

.tw-accent-amber-700\/25 {
  accent-color: rgb(180 83 9 / 0.25) !important;
}

.tw-accent-amber-700\/30 {
  accent-color: rgb(180 83 9 / 0.3) !important;
}

.tw-accent-amber-700\/35 {
  accent-color: rgb(180 83 9 / 0.35) !important;
}

.tw-accent-amber-700\/40 {
  accent-color: rgb(180 83 9 / 0.4) !important;
}

.tw-accent-amber-700\/45 {
  accent-color: rgb(180 83 9 / 0.45) !important;
}

.tw-accent-amber-700\/5 {
  accent-color: rgb(180 83 9 / 0.05) !important;
}

.tw-accent-amber-700\/50 {
  accent-color: rgb(180 83 9 / 0.5) !important;
}

.tw-accent-amber-700\/55 {
  accent-color: rgb(180 83 9 / 0.55) !important;
}

.tw-accent-amber-700\/60 {
  accent-color: rgb(180 83 9 / 0.6) !important;
}

.tw-accent-amber-700\/65 {
  accent-color: rgb(180 83 9 / 0.65) !important;
}

.tw-accent-amber-700\/70 {
  accent-color: rgb(180 83 9 / 0.7) !important;
}

.tw-accent-amber-700\/75 {
  accent-color: rgb(180 83 9 / 0.75) !important;
}

.tw-accent-amber-700\/80 {
  accent-color: rgb(180 83 9 / 0.8) !important;
}

.tw-accent-amber-700\/85 {
  accent-color: rgb(180 83 9 / 0.85) !important;
}

.tw-accent-amber-700\/90 {
  accent-color: rgb(180 83 9 / 0.9) !important;
}

.tw-accent-amber-700\/95 {
  accent-color: rgb(180 83 9 / 0.95) !important;
}

.tw-accent-amber-800 {
  accent-color: #92400e !important;
}

.tw-accent-amber-800\/0 {
  accent-color: rgb(146 64 14 / 0) !important;
}

.tw-accent-amber-800\/10 {
  accent-color: rgb(146 64 14 / 0.1) !important;
}

.tw-accent-amber-800\/100 {
  accent-color: rgb(146 64 14 / 1) !important;
}

.tw-accent-amber-800\/15 {
  accent-color: rgb(146 64 14 / 0.15) !important;
}

.tw-accent-amber-800\/20 {
  accent-color: rgb(146 64 14 / 0.2) !important;
}

.tw-accent-amber-800\/25 {
  accent-color: rgb(146 64 14 / 0.25) !important;
}

.tw-accent-amber-800\/30 {
  accent-color: rgb(146 64 14 / 0.3) !important;
}

.tw-accent-amber-800\/35 {
  accent-color: rgb(146 64 14 / 0.35) !important;
}

.tw-accent-amber-800\/40 {
  accent-color: rgb(146 64 14 / 0.4) !important;
}

.tw-accent-amber-800\/45 {
  accent-color: rgb(146 64 14 / 0.45) !important;
}

.tw-accent-amber-800\/5 {
  accent-color: rgb(146 64 14 / 0.05) !important;
}

.tw-accent-amber-800\/50 {
  accent-color: rgb(146 64 14 / 0.5) !important;
}

.tw-accent-amber-800\/55 {
  accent-color: rgb(146 64 14 / 0.55) !important;
}

.tw-accent-amber-800\/60 {
  accent-color: rgb(146 64 14 / 0.6) !important;
}

.tw-accent-amber-800\/65 {
  accent-color: rgb(146 64 14 / 0.65) !important;
}

.tw-accent-amber-800\/70 {
  accent-color: rgb(146 64 14 / 0.7) !important;
}

.tw-accent-amber-800\/75 {
  accent-color: rgb(146 64 14 / 0.75) !important;
}

.tw-accent-amber-800\/80 {
  accent-color: rgb(146 64 14 / 0.8) !important;
}

.tw-accent-amber-800\/85 {
  accent-color: rgb(146 64 14 / 0.85) !important;
}

.tw-accent-amber-800\/90 {
  accent-color: rgb(146 64 14 / 0.9) !important;
}

.tw-accent-amber-800\/95 {
  accent-color: rgb(146 64 14 / 0.95) !important;
}

.tw-accent-amber-900 {
  accent-color: #78350f !important;
}

.tw-accent-amber-900\/0 {
  accent-color: rgb(120 53 15 / 0) !important;
}

.tw-accent-amber-900\/10 {
  accent-color: rgb(120 53 15 / 0.1) !important;
}

.tw-accent-amber-900\/100 {
  accent-color: rgb(120 53 15 / 1) !important;
}

.tw-accent-amber-900\/15 {
  accent-color: rgb(120 53 15 / 0.15) !important;
}

.tw-accent-amber-900\/20 {
  accent-color: rgb(120 53 15 / 0.2) !important;
}

.tw-accent-amber-900\/25 {
  accent-color: rgb(120 53 15 / 0.25) !important;
}

.tw-accent-amber-900\/30 {
  accent-color: rgb(120 53 15 / 0.3) !important;
}

.tw-accent-amber-900\/35 {
  accent-color: rgb(120 53 15 / 0.35) !important;
}

.tw-accent-amber-900\/40 {
  accent-color: rgb(120 53 15 / 0.4) !important;
}

.tw-accent-amber-900\/45 {
  accent-color: rgb(120 53 15 / 0.45) !important;
}

.tw-accent-amber-900\/5 {
  accent-color: rgb(120 53 15 / 0.05) !important;
}

.tw-accent-amber-900\/50 {
  accent-color: rgb(120 53 15 / 0.5) !important;
}

.tw-accent-amber-900\/55 {
  accent-color: rgb(120 53 15 / 0.55) !important;
}

.tw-accent-amber-900\/60 {
  accent-color: rgb(120 53 15 / 0.6) !important;
}

.tw-accent-amber-900\/65 {
  accent-color: rgb(120 53 15 / 0.65) !important;
}

.tw-accent-amber-900\/70 {
  accent-color: rgb(120 53 15 / 0.7) !important;
}

.tw-accent-amber-900\/75 {
  accent-color: rgb(120 53 15 / 0.75) !important;
}

.tw-accent-amber-900\/80 {
  accent-color: rgb(120 53 15 / 0.8) !important;
}

.tw-accent-amber-900\/85 {
  accent-color: rgb(120 53 15 / 0.85) !important;
}

.tw-accent-amber-900\/90 {
  accent-color: rgb(120 53 15 / 0.9) !important;
}

.tw-accent-amber-900\/95 {
  accent-color: rgb(120 53 15 / 0.95) !important;
}

.tw-accent-amber-950 {
  accent-color: #451a03 !important;
}

.tw-accent-amber-950\/0 {
  accent-color: rgb(69 26 3 / 0) !important;
}

.tw-accent-amber-950\/10 {
  accent-color: rgb(69 26 3 / 0.1) !important;
}

.tw-accent-amber-950\/100 {
  accent-color: rgb(69 26 3 / 1) !important;
}

.tw-accent-amber-950\/15 {
  accent-color: rgb(69 26 3 / 0.15) !important;
}

.tw-accent-amber-950\/20 {
  accent-color: rgb(69 26 3 / 0.2) !important;
}

.tw-accent-amber-950\/25 {
  accent-color: rgb(69 26 3 / 0.25) !important;
}

.tw-accent-amber-950\/30 {
  accent-color: rgb(69 26 3 / 0.3) !important;
}

.tw-accent-amber-950\/35 {
  accent-color: rgb(69 26 3 / 0.35) !important;
}

.tw-accent-amber-950\/40 {
  accent-color: rgb(69 26 3 / 0.4) !important;
}

.tw-accent-amber-950\/45 {
  accent-color: rgb(69 26 3 / 0.45) !important;
}

.tw-accent-amber-950\/5 {
  accent-color: rgb(69 26 3 / 0.05) !important;
}

.tw-accent-amber-950\/50 {
  accent-color: rgb(69 26 3 / 0.5) !important;
}

.tw-accent-amber-950\/55 {
  accent-color: rgb(69 26 3 / 0.55) !important;
}

.tw-accent-amber-950\/60 {
  accent-color: rgb(69 26 3 / 0.6) !important;
}

.tw-accent-amber-950\/65 {
  accent-color: rgb(69 26 3 / 0.65) !important;
}

.tw-accent-amber-950\/70 {
  accent-color: rgb(69 26 3 / 0.7) !important;
}

.tw-accent-amber-950\/75 {
  accent-color: rgb(69 26 3 / 0.75) !important;
}

.tw-accent-amber-950\/80 {
  accent-color: rgb(69 26 3 / 0.8) !important;
}

.tw-accent-amber-950\/85 {
  accent-color: rgb(69 26 3 / 0.85) !important;
}

.tw-accent-amber-950\/90 {
  accent-color: rgb(69 26 3 / 0.9) !important;
}

.tw-accent-amber-950\/95 {
  accent-color: rgb(69 26 3 / 0.95) !important;
}

.tw-accent-auto {
  accent-color: auto !important;
}

.tw-accent-black {
  accent-color: #000 !important;
}

.tw-accent-black\/0 {
  accent-color: rgb(0 0 0 / 0) !important;
}

.tw-accent-black\/10 {
  accent-color: rgb(0 0 0 / 0.1) !important;
}

.tw-accent-black\/100 {
  accent-color: rgb(0 0 0 / 1) !important;
}

.tw-accent-black\/15 {
  accent-color: rgb(0 0 0 / 0.15) !important;
}

.tw-accent-black\/20 {
  accent-color: rgb(0 0 0 / 0.2) !important;
}

.tw-accent-black\/25 {
  accent-color: rgb(0 0 0 / 0.25) !important;
}

.tw-accent-black\/30 {
  accent-color: rgb(0 0 0 / 0.3) !important;
}

.tw-accent-black\/35 {
  accent-color: rgb(0 0 0 / 0.35) !important;
}

.tw-accent-black\/40 {
  accent-color: rgb(0 0 0 / 0.4) !important;
}

.tw-accent-black\/45 {
  accent-color: rgb(0 0 0 / 0.45) !important;
}

.tw-accent-black\/5 {
  accent-color: rgb(0 0 0 / 0.05) !important;
}

.tw-accent-black\/50 {
  accent-color: rgb(0 0 0 / 0.5) !important;
}

.tw-accent-black\/55 {
  accent-color: rgb(0 0 0 / 0.55) !important;
}

.tw-accent-black\/60 {
  accent-color: rgb(0 0 0 / 0.6) !important;
}

.tw-accent-black\/65 {
  accent-color: rgb(0 0 0 / 0.65) !important;
}

.tw-accent-black\/70 {
  accent-color: rgb(0 0 0 / 0.7) !important;
}

.tw-accent-black\/75 {
  accent-color: rgb(0 0 0 / 0.75) !important;
}

.tw-accent-black\/80 {
  accent-color: rgb(0 0 0 / 0.8) !important;
}

.tw-accent-black\/85 {
  accent-color: rgb(0 0 0 / 0.85) !important;
}

.tw-accent-black\/90 {
  accent-color: rgb(0 0 0 / 0.9) !important;
}

.tw-accent-black\/95 {
  accent-color: rgb(0 0 0 / 0.95) !important;
}

.tw-accent-blue-100 {
  accent-color: #dbeafe !important;
}

.tw-accent-blue-100\/0 {
  accent-color: rgb(219 234 254 / 0) !important;
}

.tw-accent-blue-100\/10 {
  accent-color: rgb(219 234 254 / 0.1) !important;
}

.tw-accent-blue-100\/100 {
  accent-color: rgb(219 234 254 / 1) !important;
}

.tw-accent-blue-100\/15 {
  accent-color: rgb(219 234 254 / 0.15) !important;
}

.tw-accent-blue-100\/20 {
  accent-color: rgb(219 234 254 / 0.2) !important;
}

.tw-accent-blue-100\/25 {
  accent-color: rgb(219 234 254 / 0.25) !important;
}

.tw-accent-blue-100\/30 {
  accent-color: rgb(219 234 254 / 0.3) !important;
}

.tw-accent-blue-100\/35 {
  accent-color: rgb(219 234 254 / 0.35) !important;
}

.tw-accent-blue-100\/40 {
  accent-color: rgb(219 234 254 / 0.4) !important;
}

.tw-accent-blue-100\/45 {
  accent-color: rgb(219 234 254 / 0.45) !important;
}

.tw-accent-blue-100\/5 {
  accent-color: rgb(219 234 254 / 0.05) !important;
}

.tw-accent-blue-100\/50 {
  accent-color: rgb(219 234 254 / 0.5) !important;
}

.tw-accent-blue-100\/55 {
  accent-color: rgb(219 234 254 / 0.55) !important;
}

.tw-accent-blue-100\/60 {
  accent-color: rgb(219 234 254 / 0.6) !important;
}

.tw-accent-blue-100\/65 {
  accent-color: rgb(219 234 254 / 0.65) !important;
}

.tw-accent-blue-100\/70 {
  accent-color: rgb(219 234 254 / 0.7) !important;
}

.tw-accent-blue-100\/75 {
  accent-color: rgb(219 234 254 / 0.75) !important;
}

.tw-accent-blue-100\/80 {
  accent-color: rgb(219 234 254 / 0.8) !important;
}

.tw-accent-blue-100\/85 {
  accent-color: rgb(219 234 254 / 0.85) !important;
}

.tw-accent-blue-100\/90 {
  accent-color: rgb(219 234 254 / 0.9) !important;
}

.tw-accent-blue-100\/95 {
  accent-color: rgb(219 234 254 / 0.95) !important;
}

.tw-accent-blue-200 {
  accent-color: #bfdbfe !important;
}

.tw-accent-blue-200\/0 {
  accent-color: rgb(191 219 254 / 0) !important;
}

.tw-accent-blue-200\/10 {
  accent-color: rgb(191 219 254 / 0.1) !important;
}

.tw-accent-blue-200\/100 {
  accent-color: rgb(191 219 254 / 1) !important;
}

.tw-accent-blue-200\/15 {
  accent-color: rgb(191 219 254 / 0.15) !important;
}

.tw-accent-blue-200\/20 {
  accent-color: rgb(191 219 254 / 0.2) !important;
}

.tw-accent-blue-200\/25 {
  accent-color: rgb(191 219 254 / 0.25) !important;
}

.tw-accent-blue-200\/30 {
  accent-color: rgb(191 219 254 / 0.3) !important;
}

.tw-accent-blue-200\/35 {
  accent-color: rgb(191 219 254 / 0.35) !important;
}

.tw-accent-blue-200\/40 {
  accent-color: rgb(191 219 254 / 0.4) !important;
}

.tw-accent-blue-200\/45 {
  accent-color: rgb(191 219 254 / 0.45) !important;
}

.tw-accent-blue-200\/5 {
  accent-color: rgb(191 219 254 / 0.05) !important;
}

.tw-accent-blue-200\/50 {
  accent-color: rgb(191 219 254 / 0.5) !important;
}

.tw-accent-blue-200\/55 {
  accent-color: rgb(191 219 254 / 0.55) !important;
}

.tw-accent-blue-200\/60 {
  accent-color: rgb(191 219 254 / 0.6) !important;
}

.tw-accent-blue-200\/65 {
  accent-color: rgb(191 219 254 / 0.65) !important;
}

.tw-accent-blue-200\/70 {
  accent-color: rgb(191 219 254 / 0.7) !important;
}

.tw-accent-blue-200\/75 {
  accent-color: rgb(191 219 254 / 0.75) !important;
}

.tw-accent-blue-200\/80 {
  accent-color: rgb(191 219 254 / 0.8) !important;
}

.tw-accent-blue-200\/85 {
  accent-color: rgb(191 219 254 / 0.85) !important;
}

.tw-accent-blue-200\/90 {
  accent-color: rgb(191 219 254 / 0.9) !important;
}

.tw-accent-blue-200\/95 {
  accent-color: rgb(191 219 254 / 0.95) !important;
}

.tw-accent-blue-300 {
  accent-color: #93c5fd !important;
}

.tw-accent-blue-300\/0 {
  accent-color: rgb(147 197 253 / 0) !important;
}

.tw-accent-blue-300\/10 {
  accent-color: rgb(147 197 253 / 0.1) !important;
}

.tw-accent-blue-300\/100 {
  accent-color: rgb(147 197 253 / 1) !important;
}

.tw-accent-blue-300\/15 {
  accent-color: rgb(147 197 253 / 0.15) !important;
}

.tw-accent-blue-300\/20 {
  accent-color: rgb(147 197 253 / 0.2) !important;
}

.tw-accent-blue-300\/25 {
  accent-color: rgb(147 197 253 / 0.25) !important;
}

.tw-accent-blue-300\/30 {
  accent-color: rgb(147 197 253 / 0.3) !important;
}

.tw-accent-blue-300\/35 {
  accent-color: rgb(147 197 253 / 0.35) !important;
}

.tw-accent-blue-300\/40 {
  accent-color: rgb(147 197 253 / 0.4) !important;
}

.tw-accent-blue-300\/45 {
  accent-color: rgb(147 197 253 / 0.45) !important;
}

.tw-accent-blue-300\/5 {
  accent-color: rgb(147 197 253 / 0.05) !important;
}

.tw-accent-blue-300\/50 {
  accent-color: rgb(147 197 253 / 0.5) !important;
}

.tw-accent-blue-300\/55 {
  accent-color: rgb(147 197 253 / 0.55) !important;
}

.tw-accent-blue-300\/60 {
  accent-color: rgb(147 197 253 / 0.6) !important;
}

.tw-accent-blue-300\/65 {
  accent-color: rgb(147 197 253 / 0.65) !important;
}

.tw-accent-blue-300\/70 {
  accent-color: rgb(147 197 253 / 0.7) !important;
}

.tw-accent-blue-300\/75 {
  accent-color: rgb(147 197 253 / 0.75) !important;
}

.tw-accent-blue-300\/80 {
  accent-color: rgb(147 197 253 / 0.8) !important;
}

.tw-accent-blue-300\/85 {
  accent-color: rgb(147 197 253 / 0.85) !important;
}

.tw-accent-blue-300\/90 {
  accent-color: rgb(147 197 253 / 0.9) !important;
}

.tw-accent-blue-300\/95 {
  accent-color: rgb(147 197 253 / 0.95) !important;
}

.tw-accent-blue-400 {
  accent-color: #60a5fa !important;
}

.tw-accent-blue-400\/0 {
  accent-color: rgb(96 165 250 / 0) !important;
}

.tw-accent-blue-400\/10 {
  accent-color: rgb(96 165 250 / 0.1) !important;
}

.tw-accent-blue-400\/100 {
  accent-color: rgb(96 165 250 / 1) !important;
}

.tw-accent-blue-400\/15 {
  accent-color: rgb(96 165 250 / 0.15) !important;
}

.tw-accent-blue-400\/20 {
  accent-color: rgb(96 165 250 / 0.2) !important;
}

.tw-accent-blue-400\/25 {
  accent-color: rgb(96 165 250 / 0.25) !important;
}

.tw-accent-blue-400\/30 {
  accent-color: rgb(96 165 250 / 0.3) !important;
}

.tw-accent-blue-400\/35 {
  accent-color: rgb(96 165 250 / 0.35) !important;
}

.tw-accent-blue-400\/40 {
  accent-color: rgb(96 165 250 / 0.4) !important;
}

.tw-accent-blue-400\/45 {
  accent-color: rgb(96 165 250 / 0.45) !important;
}

.tw-accent-blue-400\/5 {
  accent-color: rgb(96 165 250 / 0.05) !important;
}

.tw-accent-blue-400\/50 {
  accent-color: rgb(96 165 250 / 0.5) !important;
}

.tw-accent-blue-400\/55 {
  accent-color: rgb(96 165 250 / 0.55) !important;
}

.tw-accent-blue-400\/60 {
  accent-color: rgb(96 165 250 / 0.6) !important;
}

.tw-accent-blue-400\/65 {
  accent-color: rgb(96 165 250 / 0.65) !important;
}

.tw-accent-blue-400\/70 {
  accent-color: rgb(96 165 250 / 0.7) !important;
}

.tw-accent-blue-400\/75 {
  accent-color: rgb(96 165 250 / 0.75) !important;
}

.tw-accent-blue-400\/80 {
  accent-color: rgb(96 165 250 / 0.8) !important;
}

.tw-accent-blue-400\/85 {
  accent-color: rgb(96 165 250 / 0.85) !important;
}

.tw-accent-blue-400\/90 {
  accent-color: rgb(96 165 250 / 0.9) !important;
}

.tw-accent-blue-400\/95 {
  accent-color: rgb(96 165 250 / 0.95) !important;
}

.tw-accent-blue-50 {
  accent-color: #eff6ff !important;
}

.tw-accent-blue-50\/0 {
  accent-color: rgb(239 246 255 / 0) !important;
}

.tw-accent-blue-50\/10 {
  accent-color: rgb(239 246 255 / 0.1) !important;
}

.tw-accent-blue-50\/100 {
  accent-color: rgb(239 246 255 / 1) !important;
}

.tw-accent-blue-50\/15 {
  accent-color: rgb(239 246 255 / 0.15) !important;
}

.tw-accent-blue-50\/20 {
  accent-color: rgb(239 246 255 / 0.2) !important;
}

.tw-accent-blue-50\/25 {
  accent-color: rgb(239 246 255 / 0.25) !important;
}

.tw-accent-blue-50\/30 {
  accent-color: rgb(239 246 255 / 0.3) !important;
}

.tw-accent-blue-50\/35 {
  accent-color: rgb(239 246 255 / 0.35) !important;
}

.tw-accent-blue-50\/40 {
  accent-color: rgb(239 246 255 / 0.4) !important;
}

.tw-accent-blue-50\/45 {
  accent-color: rgb(239 246 255 / 0.45) !important;
}

.tw-accent-blue-50\/5 {
  accent-color: rgb(239 246 255 / 0.05) !important;
}

.tw-accent-blue-50\/50 {
  accent-color: rgb(239 246 255 / 0.5) !important;
}

.tw-accent-blue-50\/55 {
  accent-color: rgb(239 246 255 / 0.55) !important;
}

.tw-accent-blue-50\/60 {
  accent-color: rgb(239 246 255 / 0.6) !important;
}

.tw-accent-blue-50\/65 {
  accent-color: rgb(239 246 255 / 0.65) !important;
}

.tw-accent-blue-50\/70 {
  accent-color: rgb(239 246 255 / 0.7) !important;
}

.tw-accent-blue-50\/75 {
  accent-color: rgb(239 246 255 / 0.75) !important;
}

.tw-accent-blue-50\/80 {
  accent-color: rgb(239 246 255 / 0.8) !important;
}

.tw-accent-blue-50\/85 {
  accent-color: rgb(239 246 255 / 0.85) !important;
}

.tw-accent-blue-50\/90 {
  accent-color: rgb(239 246 255 / 0.9) !important;
}

.tw-accent-blue-50\/95 {
  accent-color: rgb(239 246 255 / 0.95) !important;
}

.tw-accent-blue-500 {
  accent-color: #3b82f6 !important;
}

.tw-accent-blue-500\/0 {
  accent-color: rgb(59 130 246 / 0) !important;
}

.tw-accent-blue-500\/10 {
  accent-color: rgb(59 130 246 / 0.1) !important;
}

.tw-accent-blue-500\/100 {
  accent-color: rgb(59 130 246 / 1) !important;
}

.tw-accent-blue-500\/15 {
  accent-color: rgb(59 130 246 / 0.15) !important;
}

.tw-accent-blue-500\/20 {
  accent-color: rgb(59 130 246 / 0.2) !important;
}

.tw-accent-blue-500\/25 {
  accent-color: rgb(59 130 246 / 0.25) !important;
}

.tw-accent-blue-500\/30 {
  accent-color: rgb(59 130 246 / 0.3) !important;
}

.tw-accent-blue-500\/35 {
  accent-color: rgb(59 130 246 / 0.35) !important;
}

.tw-accent-blue-500\/40 {
  accent-color: rgb(59 130 246 / 0.4) !important;
}

.tw-accent-blue-500\/45 {
  accent-color: rgb(59 130 246 / 0.45) !important;
}

.tw-accent-blue-500\/5 {
  accent-color: rgb(59 130 246 / 0.05) !important;
}

.tw-accent-blue-500\/50 {
  accent-color: rgb(59 130 246 / 0.5) !important;
}

.tw-accent-blue-500\/55 {
  accent-color: rgb(59 130 246 / 0.55) !important;
}

.tw-accent-blue-500\/60 {
  accent-color: rgb(59 130 246 / 0.6) !important;
}

.tw-accent-blue-500\/65 {
  accent-color: rgb(59 130 246 / 0.65) !important;
}

.tw-accent-blue-500\/70 {
  accent-color: rgb(59 130 246 / 0.7) !important;
}

.tw-accent-blue-500\/75 {
  accent-color: rgb(59 130 246 / 0.75) !important;
}

.tw-accent-blue-500\/80 {
  accent-color: rgb(59 130 246 / 0.8) !important;
}

.tw-accent-blue-500\/85 {
  accent-color: rgb(59 130 246 / 0.85) !important;
}

.tw-accent-blue-500\/90 {
  accent-color: rgb(59 130 246 / 0.9) !important;
}

.tw-accent-blue-500\/95 {
  accent-color: rgb(59 130 246 / 0.95) !important;
}

.tw-accent-blue-600 {
  accent-color: #2563eb !important;
}

.tw-accent-blue-600\/0 {
  accent-color: rgb(37 99 235 / 0) !important;
}

.tw-accent-blue-600\/10 {
  accent-color: rgb(37 99 235 / 0.1) !important;
}

.tw-accent-blue-600\/100 {
  accent-color: rgb(37 99 235 / 1) !important;
}

.tw-accent-blue-600\/15 {
  accent-color: rgb(37 99 235 / 0.15) !important;
}

.tw-accent-blue-600\/20 {
  accent-color: rgb(37 99 235 / 0.2) !important;
}

.tw-accent-blue-600\/25 {
  accent-color: rgb(37 99 235 / 0.25) !important;
}

.tw-accent-blue-600\/30 {
  accent-color: rgb(37 99 235 / 0.3) !important;
}

.tw-accent-blue-600\/35 {
  accent-color: rgb(37 99 235 / 0.35) !important;
}

.tw-accent-blue-600\/40 {
  accent-color: rgb(37 99 235 / 0.4) !important;
}

.tw-accent-blue-600\/45 {
  accent-color: rgb(37 99 235 / 0.45) !important;
}

.tw-accent-blue-600\/5 {
  accent-color: rgb(37 99 235 / 0.05) !important;
}

.tw-accent-blue-600\/50 {
  accent-color: rgb(37 99 235 / 0.5) !important;
}

.tw-accent-blue-600\/55 {
  accent-color: rgb(37 99 235 / 0.55) !important;
}

.tw-accent-blue-600\/60 {
  accent-color: rgb(37 99 235 / 0.6) !important;
}

.tw-accent-blue-600\/65 {
  accent-color: rgb(37 99 235 / 0.65) !important;
}

.tw-accent-blue-600\/70 {
  accent-color: rgb(37 99 235 / 0.7) !important;
}

.tw-accent-blue-600\/75 {
  accent-color: rgb(37 99 235 / 0.75) !important;
}

.tw-accent-blue-600\/80 {
  accent-color: rgb(37 99 235 / 0.8) !important;
}

.tw-accent-blue-600\/85 {
  accent-color: rgb(37 99 235 / 0.85) !important;
}

.tw-accent-blue-600\/90 {
  accent-color: rgb(37 99 235 / 0.9) !important;
}

.tw-accent-blue-600\/95 {
  accent-color: rgb(37 99 235 / 0.95) !important;
}

.tw-accent-blue-700 {
  accent-color: #1d4ed8 !important;
}

.tw-accent-blue-700\/0 {
  accent-color: rgb(29 78 216 / 0) !important;
}

.tw-accent-blue-700\/10 {
  accent-color: rgb(29 78 216 / 0.1) !important;
}

.tw-accent-blue-700\/100 {
  accent-color: rgb(29 78 216 / 1) !important;
}

.tw-accent-blue-700\/15 {
  accent-color: rgb(29 78 216 / 0.15) !important;
}

.tw-accent-blue-700\/20 {
  accent-color: rgb(29 78 216 / 0.2) !important;
}

.tw-accent-blue-700\/25 {
  accent-color: rgb(29 78 216 / 0.25) !important;
}

.tw-accent-blue-700\/30 {
  accent-color: rgb(29 78 216 / 0.3) !important;
}

.tw-accent-blue-700\/35 {
  accent-color: rgb(29 78 216 / 0.35) !important;
}

.tw-accent-blue-700\/40 {
  accent-color: rgb(29 78 216 / 0.4) !important;
}

.tw-accent-blue-700\/45 {
  accent-color: rgb(29 78 216 / 0.45) !important;
}

.tw-accent-blue-700\/5 {
  accent-color: rgb(29 78 216 / 0.05) !important;
}

.tw-accent-blue-700\/50 {
  accent-color: rgb(29 78 216 / 0.5) !important;
}

.tw-accent-blue-700\/55 {
  accent-color: rgb(29 78 216 / 0.55) !important;
}

.tw-accent-blue-700\/60 {
  accent-color: rgb(29 78 216 / 0.6) !important;
}

.tw-accent-blue-700\/65 {
  accent-color: rgb(29 78 216 / 0.65) !important;
}

.tw-accent-blue-700\/70 {
  accent-color: rgb(29 78 216 / 0.7) !important;
}

.tw-accent-blue-700\/75 {
  accent-color: rgb(29 78 216 / 0.75) !important;
}

.tw-accent-blue-700\/80 {
  accent-color: rgb(29 78 216 / 0.8) !important;
}

.tw-accent-blue-700\/85 {
  accent-color: rgb(29 78 216 / 0.85) !important;
}

.tw-accent-blue-700\/90 {
  accent-color: rgb(29 78 216 / 0.9) !important;
}

.tw-accent-blue-700\/95 {
  accent-color: rgb(29 78 216 / 0.95) !important;
}

.tw-accent-blue-800 {
  accent-color: #1e40af !important;
}

.tw-accent-blue-800\/0 {
  accent-color: rgb(30 64 175 / 0) !important;
}

.tw-accent-blue-800\/10 {
  accent-color: rgb(30 64 175 / 0.1) !important;
}

.tw-accent-blue-800\/100 {
  accent-color: rgb(30 64 175 / 1) !important;
}

.tw-accent-blue-800\/15 {
  accent-color: rgb(30 64 175 / 0.15) !important;
}

.tw-accent-blue-800\/20 {
  accent-color: rgb(30 64 175 / 0.2) !important;
}

.tw-accent-blue-800\/25 {
  accent-color: rgb(30 64 175 / 0.25) !important;
}

.tw-accent-blue-800\/30 {
  accent-color: rgb(30 64 175 / 0.3) !important;
}

.tw-accent-blue-800\/35 {
  accent-color: rgb(30 64 175 / 0.35) !important;
}

.tw-accent-blue-800\/40 {
  accent-color: rgb(30 64 175 / 0.4) !important;
}

.tw-accent-blue-800\/45 {
  accent-color: rgb(30 64 175 / 0.45) !important;
}

.tw-accent-blue-800\/5 {
  accent-color: rgb(30 64 175 / 0.05) !important;
}

.tw-accent-blue-800\/50 {
  accent-color: rgb(30 64 175 / 0.5) !important;
}

.tw-accent-blue-800\/55 {
  accent-color: rgb(30 64 175 / 0.55) !important;
}

.tw-accent-blue-800\/60 {
  accent-color: rgb(30 64 175 / 0.6) !important;
}

.tw-accent-blue-800\/65 {
  accent-color: rgb(30 64 175 / 0.65) !important;
}

.tw-accent-blue-800\/70 {
  accent-color: rgb(30 64 175 / 0.7) !important;
}

.tw-accent-blue-800\/75 {
  accent-color: rgb(30 64 175 / 0.75) !important;
}

.tw-accent-blue-800\/80 {
  accent-color: rgb(30 64 175 / 0.8) !important;
}

.tw-accent-blue-800\/85 {
  accent-color: rgb(30 64 175 / 0.85) !important;
}

.tw-accent-blue-800\/90 {
  accent-color: rgb(30 64 175 / 0.9) !important;
}

.tw-accent-blue-800\/95 {
  accent-color: rgb(30 64 175 / 0.95) !important;
}

.tw-accent-blue-900 {
  accent-color: #1e3a8a !important;
}

.tw-accent-blue-900\/0 {
  accent-color: rgb(30 58 138 / 0) !important;
}

.tw-accent-blue-900\/10 {
  accent-color: rgb(30 58 138 / 0.1) !important;
}

.tw-accent-blue-900\/100 {
  accent-color: rgb(30 58 138 / 1) !important;
}

.tw-accent-blue-900\/15 {
  accent-color: rgb(30 58 138 / 0.15) !important;
}

.tw-accent-blue-900\/20 {
  accent-color: rgb(30 58 138 / 0.2) !important;
}

.tw-accent-blue-900\/25 {
  accent-color: rgb(30 58 138 / 0.25) !important;
}

.tw-accent-blue-900\/30 {
  accent-color: rgb(30 58 138 / 0.3) !important;
}

.tw-accent-blue-900\/35 {
  accent-color: rgb(30 58 138 / 0.35) !important;
}

.tw-accent-blue-900\/40 {
  accent-color: rgb(30 58 138 / 0.4) !important;
}

.tw-accent-blue-900\/45 {
  accent-color: rgb(30 58 138 / 0.45) !important;
}

.tw-accent-blue-900\/5 {
  accent-color: rgb(30 58 138 / 0.05) !important;
}

.tw-accent-blue-900\/50 {
  accent-color: rgb(30 58 138 / 0.5) !important;
}

.tw-accent-blue-900\/55 {
  accent-color: rgb(30 58 138 / 0.55) !important;
}

.tw-accent-blue-900\/60 {
  accent-color: rgb(30 58 138 / 0.6) !important;
}

.tw-accent-blue-900\/65 {
  accent-color: rgb(30 58 138 / 0.65) !important;
}

.tw-accent-blue-900\/70 {
  accent-color: rgb(30 58 138 / 0.7) !important;
}

.tw-accent-blue-900\/75 {
  accent-color: rgb(30 58 138 / 0.75) !important;
}

.tw-accent-blue-900\/80 {
  accent-color: rgb(30 58 138 / 0.8) !important;
}

.tw-accent-blue-900\/85 {
  accent-color: rgb(30 58 138 / 0.85) !important;
}

.tw-accent-blue-900\/90 {
  accent-color: rgb(30 58 138 / 0.9) !important;
}

.tw-accent-blue-900\/95 {
  accent-color: rgb(30 58 138 / 0.95) !important;
}

.tw-accent-blue-950 {
  accent-color: #172554 !important;
}

.tw-accent-blue-950\/0 {
  accent-color: rgb(23 37 84 / 0) !important;
}

.tw-accent-blue-950\/10 {
  accent-color: rgb(23 37 84 / 0.1) !important;
}

.tw-accent-blue-950\/100 {
  accent-color: rgb(23 37 84 / 1) !important;
}

.tw-accent-blue-950\/15 {
  accent-color: rgb(23 37 84 / 0.15) !important;
}

.tw-accent-blue-950\/20 {
  accent-color: rgb(23 37 84 / 0.2) !important;
}

.tw-accent-blue-950\/25 {
  accent-color: rgb(23 37 84 / 0.25) !important;
}

.tw-accent-blue-950\/30 {
  accent-color: rgb(23 37 84 / 0.3) !important;
}

.tw-accent-blue-950\/35 {
  accent-color: rgb(23 37 84 / 0.35) !important;
}

.tw-accent-blue-950\/40 {
  accent-color: rgb(23 37 84 / 0.4) !important;
}

.tw-accent-blue-950\/45 {
  accent-color: rgb(23 37 84 / 0.45) !important;
}

.tw-accent-blue-950\/5 {
  accent-color: rgb(23 37 84 / 0.05) !important;
}

.tw-accent-blue-950\/50 {
  accent-color: rgb(23 37 84 / 0.5) !important;
}

.tw-accent-blue-950\/55 {
  accent-color: rgb(23 37 84 / 0.55) !important;
}

.tw-accent-blue-950\/60 {
  accent-color: rgb(23 37 84 / 0.6) !important;
}

.tw-accent-blue-950\/65 {
  accent-color: rgb(23 37 84 / 0.65) !important;
}

.tw-accent-blue-950\/70 {
  accent-color: rgb(23 37 84 / 0.7) !important;
}

.tw-accent-blue-950\/75 {
  accent-color: rgb(23 37 84 / 0.75) !important;
}

.tw-accent-blue-950\/80 {
  accent-color: rgb(23 37 84 / 0.8) !important;
}

.tw-accent-blue-950\/85 {
  accent-color: rgb(23 37 84 / 0.85) !important;
}

.tw-accent-blue-950\/90 {
  accent-color: rgb(23 37 84 / 0.9) !important;
}

.tw-accent-blue-950\/95 {
  accent-color: rgb(23 37 84 / 0.95) !important;
}

.tw-accent-current {
  accent-color: currentColor !important;
}

.tw-accent-cyan-100 {
  accent-color: #cffafe !important;
}

.tw-accent-cyan-100\/0 {
  accent-color: rgb(207 250 254 / 0) !important;
}

.tw-accent-cyan-100\/10 {
  accent-color: rgb(207 250 254 / 0.1) !important;
}

.tw-accent-cyan-100\/100 {
  accent-color: rgb(207 250 254 / 1) !important;
}

.tw-accent-cyan-100\/15 {
  accent-color: rgb(207 250 254 / 0.15) !important;
}

.tw-accent-cyan-100\/20 {
  accent-color: rgb(207 250 254 / 0.2) !important;
}

.tw-accent-cyan-100\/25 {
  accent-color: rgb(207 250 254 / 0.25) !important;
}

.tw-accent-cyan-100\/30 {
  accent-color: rgb(207 250 254 / 0.3) !important;
}

.tw-accent-cyan-100\/35 {
  accent-color: rgb(207 250 254 / 0.35) !important;
}

.tw-accent-cyan-100\/40 {
  accent-color: rgb(207 250 254 / 0.4) !important;
}

.tw-accent-cyan-100\/45 {
  accent-color: rgb(207 250 254 / 0.45) !important;
}

.tw-accent-cyan-100\/5 {
  accent-color: rgb(207 250 254 / 0.05) !important;
}

.tw-accent-cyan-100\/50 {
  accent-color: rgb(207 250 254 / 0.5) !important;
}

.tw-accent-cyan-100\/55 {
  accent-color: rgb(207 250 254 / 0.55) !important;
}

.tw-accent-cyan-100\/60 {
  accent-color: rgb(207 250 254 / 0.6) !important;
}

.tw-accent-cyan-100\/65 {
  accent-color: rgb(207 250 254 / 0.65) !important;
}

.tw-accent-cyan-100\/70 {
  accent-color: rgb(207 250 254 / 0.7) !important;
}

.tw-accent-cyan-100\/75 {
  accent-color: rgb(207 250 254 / 0.75) !important;
}

.tw-accent-cyan-100\/80 {
  accent-color: rgb(207 250 254 / 0.8) !important;
}

.tw-accent-cyan-100\/85 {
  accent-color: rgb(207 250 254 / 0.85) !important;
}

.tw-accent-cyan-100\/90 {
  accent-color: rgb(207 250 254 / 0.9) !important;
}

.tw-accent-cyan-100\/95 {
  accent-color: rgb(207 250 254 / 0.95) !important;
}

.tw-accent-cyan-200 {
  accent-color: #a5f3fc !important;
}

.tw-accent-cyan-200\/0 {
  accent-color: rgb(165 243 252 / 0) !important;
}

.tw-accent-cyan-200\/10 {
  accent-color: rgb(165 243 252 / 0.1) !important;
}

.tw-accent-cyan-200\/100 {
  accent-color: rgb(165 243 252 / 1) !important;
}

.tw-accent-cyan-200\/15 {
  accent-color: rgb(165 243 252 / 0.15) !important;
}

.tw-accent-cyan-200\/20 {
  accent-color: rgb(165 243 252 / 0.2) !important;
}

.tw-accent-cyan-200\/25 {
  accent-color: rgb(165 243 252 / 0.25) !important;
}

.tw-accent-cyan-200\/30 {
  accent-color: rgb(165 243 252 / 0.3) !important;
}

.tw-accent-cyan-200\/35 {
  accent-color: rgb(165 243 252 / 0.35) !important;
}

.tw-accent-cyan-200\/40 {
  accent-color: rgb(165 243 252 / 0.4) !important;
}

.tw-accent-cyan-200\/45 {
  accent-color: rgb(165 243 252 / 0.45) !important;
}

.tw-accent-cyan-200\/5 {
  accent-color: rgb(165 243 252 / 0.05) !important;
}

.tw-accent-cyan-200\/50 {
  accent-color: rgb(165 243 252 / 0.5) !important;
}

.tw-accent-cyan-200\/55 {
  accent-color: rgb(165 243 252 / 0.55) !important;
}

.tw-accent-cyan-200\/60 {
  accent-color: rgb(165 243 252 / 0.6) !important;
}

.tw-accent-cyan-200\/65 {
  accent-color: rgb(165 243 252 / 0.65) !important;
}

.tw-accent-cyan-200\/70 {
  accent-color: rgb(165 243 252 / 0.7) !important;
}

.tw-accent-cyan-200\/75 {
  accent-color: rgb(165 243 252 / 0.75) !important;
}

.tw-accent-cyan-200\/80 {
  accent-color: rgb(165 243 252 / 0.8) !important;
}

.tw-accent-cyan-200\/85 {
  accent-color: rgb(165 243 252 / 0.85) !important;
}

.tw-accent-cyan-200\/90 {
  accent-color: rgb(165 243 252 / 0.9) !important;
}

.tw-accent-cyan-200\/95 {
  accent-color: rgb(165 243 252 / 0.95) !important;
}

.tw-accent-cyan-300 {
  accent-color: #67e8f9 !important;
}

.tw-accent-cyan-300\/0 {
  accent-color: rgb(103 232 249 / 0) !important;
}

.tw-accent-cyan-300\/10 {
  accent-color: rgb(103 232 249 / 0.1) !important;
}

.tw-accent-cyan-300\/100 {
  accent-color: rgb(103 232 249 / 1) !important;
}

.tw-accent-cyan-300\/15 {
  accent-color: rgb(103 232 249 / 0.15) !important;
}

.tw-accent-cyan-300\/20 {
  accent-color: rgb(103 232 249 / 0.2) !important;
}

.tw-accent-cyan-300\/25 {
  accent-color: rgb(103 232 249 / 0.25) !important;
}

.tw-accent-cyan-300\/30 {
  accent-color: rgb(103 232 249 / 0.3) !important;
}

.tw-accent-cyan-300\/35 {
  accent-color: rgb(103 232 249 / 0.35) !important;
}

.tw-accent-cyan-300\/40 {
  accent-color: rgb(103 232 249 / 0.4) !important;
}

.tw-accent-cyan-300\/45 {
  accent-color: rgb(103 232 249 / 0.45) !important;
}

.tw-accent-cyan-300\/5 {
  accent-color: rgb(103 232 249 / 0.05) !important;
}

.tw-accent-cyan-300\/50 {
  accent-color: rgb(103 232 249 / 0.5) !important;
}

.tw-accent-cyan-300\/55 {
  accent-color: rgb(103 232 249 / 0.55) !important;
}

.tw-accent-cyan-300\/60 {
  accent-color: rgb(103 232 249 / 0.6) !important;
}

.tw-accent-cyan-300\/65 {
  accent-color: rgb(103 232 249 / 0.65) !important;
}

.tw-accent-cyan-300\/70 {
  accent-color: rgb(103 232 249 / 0.7) !important;
}

.tw-accent-cyan-300\/75 {
  accent-color: rgb(103 232 249 / 0.75) !important;
}

.tw-accent-cyan-300\/80 {
  accent-color: rgb(103 232 249 / 0.8) !important;
}

.tw-accent-cyan-300\/85 {
  accent-color: rgb(103 232 249 / 0.85) !important;
}

.tw-accent-cyan-300\/90 {
  accent-color: rgb(103 232 249 / 0.9) !important;
}

.tw-accent-cyan-300\/95 {
  accent-color: rgb(103 232 249 / 0.95) !important;
}

.tw-accent-cyan-400 {
  accent-color: #22d3ee !important;
}

.tw-accent-cyan-400\/0 {
  accent-color: rgb(34 211 238 / 0) !important;
}

.tw-accent-cyan-400\/10 {
  accent-color: rgb(34 211 238 / 0.1) !important;
}

.tw-accent-cyan-400\/100 {
  accent-color: rgb(34 211 238 / 1) !important;
}

.tw-accent-cyan-400\/15 {
  accent-color: rgb(34 211 238 / 0.15) !important;
}

.tw-accent-cyan-400\/20 {
  accent-color: rgb(34 211 238 / 0.2) !important;
}

.tw-accent-cyan-400\/25 {
  accent-color: rgb(34 211 238 / 0.25) !important;
}

.tw-accent-cyan-400\/30 {
  accent-color: rgb(34 211 238 / 0.3) !important;
}

.tw-accent-cyan-400\/35 {
  accent-color: rgb(34 211 238 / 0.35) !important;
}

.tw-accent-cyan-400\/40 {
  accent-color: rgb(34 211 238 / 0.4) !important;
}

.tw-accent-cyan-400\/45 {
  accent-color: rgb(34 211 238 / 0.45) !important;
}

.tw-accent-cyan-400\/5 {
  accent-color: rgb(34 211 238 / 0.05) !important;
}

.tw-accent-cyan-400\/50 {
  accent-color: rgb(34 211 238 / 0.5) !important;
}

.tw-accent-cyan-400\/55 {
  accent-color: rgb(34 211 238 / 0.55) !important;
}

.tw-accent-cyan-400\/60 {
  accent-color: rgb(34 211 238 / 0.6) !important;
}

.tw-accent-cyan-400\/65 {
  accent-color: rgb(34 211 238 / 0.65) !important;
}

.tw-accent-cyan-400\/70 {
  accent-color: rgb(34 211 238 / 0.7) !important;
}

.tw-accent-cyan-400\/75 {
  accent-color: rgb(34 211 238 / 0.75) !important;
}

.tw-accent-cyan-400\/80 {
  accent-color: rgb(34 211 238 / 0.8) !important;
}

.tw-accent-cyan-400\/85 {
  accent-color: rgb(34 211 238 / 0.85) !important;
}

.tw-accent-cyan-400\/90 {
  accent-color: rgb(34 211 238 / 0.9) !important;
}

.tw-accent-cyan-400\/95 {
  accent-color: rgb(34 211 238 / 0.95) !important;
}

.tw-accent-cyan-50 {
  accent-color: #ecfeff !important;
}

.tw-accent-cyan-50\/0 {
  accent-color: rgb(236 254 255 / 0) !important;
}

.tw-accent-cyan-50\/10 {
  accent-color: rgb(236 254 255 / 0.1) !important;
}

.tw-accent-cyan-50\/100 {
  accent-color: rgb(236 254 255 / 1) !important;
}

.tw-accent-cyan-50\/15 {
  accent-color: rgb(236 254 255 / 0.15) !important;
}

.tw-accent-cyan-50\/20 {
  accent-color: rgb(236 254 255 / 0.2) !important;
}

.tw-accent-cyan-50\/25 {
  accent-color: rgb(236 254 255 / 0.25) !important;
}

.tw-accent-cyan-50\/30 {
  accent-color: rgb(236 254 255 / 0.3) !important;
}

.tw-accent-cyan-50\/35 {
  accent-color: rgb(236 254 255 / 0.35) !important;
}

.tw-accent-cyan-50\/40 {
  accent-color: rgb(236 254 255 / 0.4) !important;
}

.tw-accent-cyan-50\/45 {
  accent-color: rgb(236 254 255 / 0.45) !important;
}

.tw-accent-cyan-50\/5 {
  accent-color: rgb(236 254 255 / 0.05) !important;
}

.tw-accent-cyan-50\/50 {
  accent-color: rgb(236 254 255 / 0.5) !important;
}

.tw-accent-cyan-50\/55 {
  accent-color: rgb(236 254 255 / 0.55) !important;
}

.tw-accent-cyan-50\/60 {
  accent-color: rgb(236 254 255 / 0.6) !important;
}

.tw-accent-cyan-50\/65 {
  accent-color: rgb(236 254 255 / 0.65) !important;
}

.tw-accent-cyan-50\/70 {
  accent-color: rgb(236 254 255 / 0.7) !important;
}

.tw-accent-cyan-50\/75 {
  accent-color: rgb(236 254 255 / 0.75) !important;
}

.tw-accent-cyan-50\/80 {
  accent-color: rgb(236 254 255 / 0.8) !important;
}

.tw-accent-cyan-50\/85 {
  accent-color: rgb(236 254 255 / 0.85) !important;
}

.tw-accent-cyan-50\/90 {
  accent-color: rgb(236 254 255 / 0.9) !important;
}

.tw-accent-cyan-50\/95 {
  accent-color: rgb(236 254 255 / 0.95) !important;
}

.tw-accent-cyan-500 {
  accent-color: #06b6d4 !important;
}

.tw-accent-cyan-500\/0 {
  accent-color: rgb(6 182 212 / 0) !important;
}

.tw-accent-cyan-500\/10 {
  accent-color: rgb(6 182 212 / 0.1) !important;
}

.tw-accent-cyan-500\/100 {
  accent-color: rgb(6 182 212 / 1) !important;
}

.tw-accent-cyan-500\/15 {
  accent-color: rgb(6 182 212 / 0.15) !important;
}

.tw-accent-cyan-500\/20 {
  accent-color: rgb(6 182 212 / 0.2) !important;
}

.tw-accent-cyan-500\/25 {
  accent-color: rgb(6 182 212 / 0.25) !important;
}

.tw-accent-cyan-500\/30 {
  accent-color: rgb(6 182 212 / 0.3) !important;
}

.tw-accent-cyan-500\/35 {
  accent-color: rgb(6 182 212 / 0.35) !important;
}

.tw-accent-cyan-500\/40 {
  accent-color: rgb(6 182 212 / 0.4) !important;
}

.tw-accent-cyan-500\/45 {
  accent-color: rgb(6 182 212 / 0.45) !important;
}

.tw-accent-cyan-500\/5 {
  accent-color: rgb(6 182 212 / 0.05) !important;
}

.tw-accent-cyan-500\/50 {
  accent-color: rgb(6 182 212 / 0.5) !important;
}

.tw-accent-cyan-500\/55 {
  accent-color: rgb(6 182 212 / 0.55) !important;
}

.tw-accent-cyan-500\/60 {
  accent-color: rgb(6 182 212 / 0.6) !important;
}

.tw-accent-cyan-500\/65 {
  accent-color: rgb(6 182 212 / 0.65) !important;
}

.tw-accent-cyan-500\/70 {
  accent-color: rgb(6 182 212 / 0.7) !important;
}

.tw-accent-cyan-500\/75 {
  accent-color: rgb(6 182 212 / 0.75) !important;
}

.tw-accent-cyan-500\/80 {
  accent-color: rgb(6 182 212 / 0.8) !important;
}

.tw-accent-cyan-500\/85 {
  accent-color: rgb(6 182 212 / 0.85) !important;
}

.tw-accent-cyan-500\/90 {
  accent-color: rgb(6 182 212 / 0.9) !important;
}

.tw-accent-cyan-500\/95 {
  accent-color: rgb(6 182 212 / 0.95) !important;
}

.tw-accent-cyan-600 {
  accent-color: #0891b2 !important;
}

.tw-accent-cyan-600\/0 {
  accent-color: rgb(8 145 178 / 0) !important;
}

.tw-accent-cyan-600\/10 {
  accent-color: rgb(8 145 178 / 0.1) !important;
}

.tw-accent-cyan-600\/100 {
  accent-color: rgb(8 145 178 / 1) !important;
}

.tw-accent-cyan-600\/15 {
  accent-color: rgb(8 145 178 / 0.15) !important;
}

.tw-accent-cyan-600\/20 {
  accent-color: rgb(8 145 178 / 0.2) !important;
}

.tw-accent-cyan-600\/25 {
  accent-color: rgb(8 145 178 / 0.25) !important;
}

.tw-accent-cyan-600\/30 {
  accent-color: rgb(8 145 178 / 0.3) !important;
}

.tw-accent-cyan-600\/35 {
  accent-color: rgb(8 145 178 / 0.35) !important;
}

.tw-accent-cyan-600\/40 {
  accent-color: rgb(8 145 178 / 0.4) !important;
}

.tw-accent-cyan-600\/45 {
  accent-color: rgb(8 145 178 / 0.45) !important;
}

.tw-accent-cyan-600\/5 {
  accent-color: rgb(8 145 178 / 0.05) !important;
}

.tw-accent-cyan-600\/50 {
  accent-color: rgb(8 145 178 / 0.5) !important;
}

.tw-accent-cyan-600\/55 {
  accent-color: rgb(8 145 178 / 0.55) !important;
}

.tw-accent-cyan-600\/60 {
  accent-color: rgb(8 145 178 / 0.6) !important;
}

.tw-accent-cyan-600\/65 {
  accent-color: rgb(8 145 178 / 0.65) !important;
}

.tw-accent-cyan-600\/70 {
  accent-color: rgb(8 145 178 / 0.7) !important;
}

.tw-accent-cyan-600\/75 {
  accent-color: rgb(8 145 178 / 0.75) !important;
}

.tw-accent-cyan-600\/80 {
  accent-color: rgb(8 145 178 / 0.8) !important;
}

.tw-accent-cyan-600\/85 {
  accent-color: rgb(8 145 178 / 0.85) !important;
}

.tw-accent-cyan-600\/90 {
  accent-color: rgb(8 145 178 / 0.9) !important;
}

.tw-accent-cyan-600\/95 {
  accent-color: rgb(8 145 178 / 0.95) !important;
}

.tw-accent-cyan-700 {
  accent-color: #0e7490 !important;
}

.tw-accent-cyan-700\/0 {
  accent-color: rgb(14 116 144 / 0) !important;
}

.tw-accent-cyan-700\/10 {
  accent-color: rgb(14 116 144 / 0.1) !important;
}

.tw-accent-cyan-700\/100 {
  accent-color: rgb(14 116 144 / 1) !important;
}

.tw-accent-cyan-700\/15 {
  accent-color: rgb(14 116 144 / 0.15) !important;
}

.tw-accent-cyan-700\/20 {
  accent-color: rgb(14 116 144 / 0.2) !important;
}

.tw-accent-cyan-700\/25 {
  accent-color: rgb(14 116 144 / 0.25) !important;
}

.tw-accent-cyan-700\/30 {
  accent-color: rgb(14 116 144 / 0.3) !important;
}

.tw-accent-cyan-700\/35 {
  accent-color: rgb(14 116 144 / 0.35) !important;
}

.tw-accent-cyan-700\/40 {
  accent-color: rgb(14 116 144 / 0.4) !important;
}

.tw-accent-cyan-700\/45 {
  accent-color: rgb(14 116 144 / 0.45) !important;
}

.tw-accent-cyan-700\/5 {
  accent-color: rgb(14 116 144 / 0.05) !important;
}

.tw-accent-cyan-700\/50 {
  accent-color: rgb(14 116 144 / 0.5) !important;
}

.tw-accent-cyan-700\/55 {
  accent-color: rgb(14 116 144 / 0.55) !important;
}

.tw-accent-cyan-700\/60 {
  accent-color: rgb(14 116 144 / 0.6) !important;
}

.tw-accent-cyan-700\/65 {
  accent-color: rgb(14 116 144 / 0.65) !important;
}

.tw-accent-cyan-700\/70 {
  accent-color: rgb(14 116 144 / 0.7) !important;
}

.tw-accent-cyan-700\/75 {
  accent-color: rgb(14 116 144 / 0.75) !important;
}

.tw-accent-cyan-700\/80 {
  accent-color: rgb(14 116 144 / 0.8) !important;
}

.tw-accent-cyan-700\/85 {
  accent-color: rgb(14 116 144 / 0.85) !important;
}

.tw-accent-cyan-700\/90 {
  accent-color: rgb(14 116 144 / 0.9) !important;
}

.tw-accent-cyan-700\/95 {
  accent-color: rgb(14 116 144 / 0.95) !important;
}

.tw-accent-cyan-800 {
  accent-color: #155e75 !important;
}

.tw-accent-cyan-800\/0 {
  accent-color: rgb(21 94 117 / 0) !important;
}

.tw-accent-cyan-800\/10 {
  accent-color: rgb(21 94 117 / 0.1) !important;
}

.tw-accent-cyan-800\/100 {
  accent-color: rgb(21 94 117 / 1) !important;
}

.tw-accent-cyan-800\/15 {
  accent-color: rgb(21 94 117 / 0.15) !important;
}

.tw-accent-cyan-800\/20 {
  accent-color: rgb(21 94 117 / 0.2) !important;
}

.tw-accent-cyan-800\/25 {
  accent-color: rgb(21 94 117 / 0.25) !important;
}

.tw-accent-cyan-800\/30 {
  accent-color: rgb(21 94 117 / 0.3) !important;
}

.tw-accent-cyan-800\/35 {
  accent-color: rgb(21 94 117 / 0.35) !important;
}

.tw-accent-cyan-800\/40 {
  accent-color: rgb(21 94 117 / 0.4) !important;
}

.tw-accent-cyan-800\/45 {
  accent-color: rgb(21 94 117 / 0.45) !important;
}

.tw-accent-cyan-800\/5 {
  accent-color: rgb(21 94 117 / 0.05) !important;
}

.tw-accent-cyan-800\/50 {
  accent-color: rgb(21 94 117 / 0.5) !important;
}

.tw-accent-cyan-800\/55 {
  accent-color: rgb(21 94 117 / 0.55) !important;
}

.tw-accent-cyan-800\/60 {
  accent-color: rgb(21 94 117 / 0.6) !important;
}

.tw-accent-cyan-800\/65 {
  accent-color: rgb(21 94 117 / 0.65) !important;
}

.tw-accent-cyan-800\/70 {
  accent-color: rgb(21 94 117 / 0.7) !important;
}

.tw-accent-cyan-800\/75 {
  accent-color: rgb(21 94 117 / 0.75) !important;
}

.tw-accent-cyan-800\/80 {
  accent-color: rgb(21 94 117 / 0.8) !important;
}

.tw-accent-cyan-800\/85 {
  accent-color: rgb(21 94 117 / 0.85) !important;
}

.tw-accent-cyan-800\/90 {
  accent-color: rgb(21 94 117 / 0.9) !important;
}

.tw-accent-cyan-800\/95 {
  accent-color: rgb(21 94 117 / 0.95) !important;
}

.tw-accent-cyan-900 {
  accent-color: #164e63 !important;
}

.tw-accent-cyan-900\/0 {
  accent-color: rgb(22 78 99 / 0) !important;
}

.tw-accent-cyan-900\/10 {
  accent-color: rgb(22 78 99 / 0.1) !important;
}

.tw-accent-cyan-900\/100 {
  accent-color: rgb(22 78 99 / 1) !important;
}

.tw-accent-cyan-900\/15 {
  accent-color: rgb(22 78 99 / 0.15) !important;
}

.tw-accent-cyan-900\/20 {
  accent-color: rgb(22 78 99 / 0.2) !important;
}

.tw-accent-cyan-900\/25 {
  accent-color: rgb(22 78 99 / 0.25) !important;
}

.tw-accent-cyan-900\/30 {
  accent-color: rgb(22 78 99 / 0.3) !important;
}

.tw-accent-cyan-900\/35 {
  accent-color: rgb(22 78 99 / 0.35) !important;
}

.tw-accent-cyan-900\/40 {
  accent-color: rgb(22 78 99 / 0.4) !important;
}

.tw-accent-cyan-900\/45 {
  accent-color: rgb(22 78 99 / 0.45) !important;
}

.tw-accent-cyan-900\/5 {
  accent-color: rgb(22 78 99 / 0.05) !important;
}

.tw-accent-cyan-900\/50 {
  accent-color: rgb(22 78 99 / 0.5) !important;
}

.tw-accent-cyan-900\/55 {
  accent-color: rgb(22 78 99 / 0.55) !important;
}

.tw-accent-cyan-900\/60 {
  accent-color: rgb(22 78 99 / 0.6) !important;
}

.tw-accent-cyan-900\/65 {
  accent-color: rgb(22 78 99 / 0.65) !important;
}

.tw-accent-cyan-900\/70 {
  accent-color: rgb(22 78 99 / 0.7) !important;
}

.tw-accent-cyan-900\/75 {
  accent-color: rgb(22 78 99 / 0.75) !important;
}

.tw-accent-cyan-900\/80 {
  accent-color: rgb(22 78 99 / 0.8) !important;
}

.tw-accent-cyan-900\/85 {
  accent-color: rgb(22 78 99 / 0.85) !important;
}

.tw-accent-cyan-900\/90 {
  accent-color: rgb(22 78 99 / 0.9) !important;
}

.tw-accent-cyan-900\/95 {
  accent-color: rgb(22 78 99 / 0.95) !important;
}

.tw-accent-cyan-950 {
  accent-color: #083344 !important;
}

.tw-accent-cyan-950\/0 {
  accent-color: rgb(8 51 68 / 0) !important;
}

.tw-accent-cyan-950\/10 {
  accent-color: rgb(8 51 68 / 0.1) !important;
}

.tw-accent-cyan-950\/100 {
  accent-color: rgb(8 51 68 / 1) !important;
}

.tw-accent-cyan-950\/15 {
  accent-color: rgb(8 51 68 / 0.15) !important;
}

.tw-accent-cyan-950\/20 {
  accent-color: rgb(8 51 68 / 0.2) !important;
}

.tw-accent-cyan-950\/25 {
  accent-color: rgb(8 51 68 / 0.25) !important;
}

.tw-accent-cyan-950\/30 {
  accent-color: rgb(8 51 68 / 0.3) !important;
}

.tw-accent-cyan-950\/35 {
  accent-color: rgb(8 51 68 / 0.35) !important;
}

.tw-accent-cyan-950\/40 {
  accent-color: rgb(8 51 68 / 0.4) !important;
}

.tw-accent-cyan-950\/45 {
  accent-color: rgb(8 51 68 / 0.45) !important;
}

.tw-accent-cyan-950\/5 {
  accent-color: rgb(8 51 68 / 0.05) !important;
}

.tw-accent-cyan-950\/50 {
  accent-color: rgb(8 51 68 / 0.5) !important;
}

.tw-accent-cyan-950\/55 {
  accent-color: rgb(8 51 68 / 0.55) !important;
}

.tw-accent-cyan-950\/60 {
  accent-color: rgb(8 51 68 / 0.6) !important;
}

.tw-accent-cyan-950\/65 {
  accent-color: rgb(8 51 68 / 0.65) !important;
}

.tw-accent-cyan-950\/70 {
  accent-color: rgb(8 51 68 / 0.7) !important;
}

.tw-accent-cyan-950\/75 {
  accent-color: rgb(8 51 68 / 0.75) !important;
}

.tw-accent-cyan-950\/80 {
  accent-color: rgb(8 51 68 / 0.8) !important;
}

.tw-accent-cyan-950\/85 {
  accent-color: rgb(8 51 68 / 0.85) !important;
}

.tw-accent-cyan-950\/90 {
  accent-color: rgb(8 51 68 / 0.9) !important;
}

.tw-accent-cyan-950\/95 {
  accent-color: rgb(8 51 68 / 0.95) !important;
}

.tw-accent-emerald-100 {
  accent-color: #d1fae5 !important;
}

.tw-accent-emerald-100\/0 {
  accent-color: rgb(209 250 229 / 0) !important;
}

.tw-accent-emerald-100\/10 {
  accent-color: rgb(209 250 229 / 0.1) !important;
}

.tw-accent-emerald-100\/100 {
  accent-color: rgb(209 250 229 / 1) !important;
}

.tw-accent-emerald-100\/15 {
  accent-color: rgb(209 250 229 / 0.15) !important;
}

.tw-accent-emerald-100\/20 {
  accent-color: rgb(209 250 229 / 0.2) !important;
}

.tw-accent-emerald-100\/25 {
  accent-color: rgb(209 250 229 / 0.25) !important;
}

.tw-accent-emerald-100\/30 {
  accent-color: rgb(209 250 229 / 0.3) !important;
}

.tw-accent-emerald-100\/35 {
  accent-color: rgb(209 250 229 / 0.35) !important;
}

.tw-accent-emerald-100\/40 {
  accent-color: rgb(209 250 229 / 0.4) !important;
}

.tw-accent-emerald-100\/45 {
  accent-color: rgb(209 250 229 / 0.45) !important;
}

.tw-accent-emerald-100\/5 {
  accent-color: rgb(209 250 229 / 0.05) !important;
}

.tw-accent-emerald-100\/50 {
  accent-color: rgb(209 250 229 / 0.5) !important;
}

.tw-accent-emerald-100\/55 {
  accent-color: rgb(209 250 229 / 0.55) !important;
}

.tw-accent-emerald-100\/60 {
  accent-color: rgb(209 250 229 / 0.6) !important;
}

.tw-accent-emerald-100\/65 {
  accent-color: rgb(209 250 229 / 0.65) !important;
}

.tw-accent-emerald-100\/70 {
  accent-color: rgb(209 250 229 / 0.7) !important;
}

.tw-accent-emerald-100\/75 {
  accent-color: rgb(209 250 229 / 0.75) !important;
}

.tw-accent-emerald-100\/80 {
  accent-color: rgb(209 250 229 / 0.8) !important;
}

.tw-accent-emerald-100\/85 {
  accent-color: rgb(209 250 229 / 0.85) !important;
}

.tw-accent-emerald-100\/90 {
  accent-color: rgb(209 250 229 / 0.9) !important;
}

.tw-accent-emerald-100\/95 {
  accent-color: rgb(209 250 229 / 0.95) !important;
}

.tw-accent-emerald-200 {
  accent-color: #a7f3d0 !important;
}

.tw-accent-emerald-200\/0 {
  accent-color: rgb(167 243 208 / 0) !important;
}

.tw-accent-emerald-200\/10 {
  accent-color: rgb(167 243 208 / 0.1) !important;
}

.tw-accent-emerald-200\/100 {
  accent-color: rgb(167 243 208 / 1) !important;
}

.tw-accent-emerald-200\/15 {
  accent-color: rgb(167 243 208 / 0.15) !important;
}

.tw-accent-emerald-200\/20 {
  accent-color: rgb(167 243 208 / 0.2) !important;
}

.tw-accent-emerald-200\/25 {
  accent-color: rgb(167 243 208 / 0.25) !important;
}

.tw-accent-emerald-200\/30 {
  accent-color: rgb(167 243 208 / 0.3) !important;
}

.tw-accent-emerald-200\/35 {
  accent-color: rgb(167 243 208 / 0.35) !important;
}

.tw-accent-emerald-200\/40 {
  accent-color: rgb(167 243 208 / 0.4) !important;
}

.tw-accent-emerald-200\/45 {
  accent-color: rgb(167 243 208 / 0.45) !important;
}

.tw-accent-emerald-200\/5 {
  accent-color: rgb(167 243 208 / 0.05) !important;
}

.tw-accent-emerald-200\/50 {
  accent-color: rgb(167 243 208 / 0.5) !important;
}

.tw-accent-emerald-200\/55 {
  accent-color: rgb(167 243 208 / 0.55) !important;
}

.tw-accent-emerald-200\/60 {
  accent-color: rgb(167 243 208 / 0.6) !important;
}

.tw-accent-emerald-200\/65 {
  accent-color: rgb(167 243 208 / 0.65) !important;
}

.tw-accent-emerald-200\/70 {
  accent-color: rgb(167 243 208 / 0.7) !important;
}

.tw-accent-emerald-200\/75 {
  accent-color: rgb(167 243 208 / 0.75) !important;
}

.tw-accent-emerald-200\/80 {
  accent-color: rgb(167 243 208 / 0.8) !important;
}

.tw-accent-emerald-200\/85 {
  accent-color: rgb(167 243 208 / 0.85) !important;
}

.tw-accent-emerald-200\/90 {
  accent-color: rgb(167 243 208 / 0.9) !important;
}

.tw-accent-emerald-200\/95 {
  accent-color: rgb(167 243 208 / 0.95) !important;
}

.tw-accent-emerald-300 {
  accent-color: #6ee7b7 !important;
}

.tw-accent-emerald-300\/0 {
  accent-color: rgb(110 231 183 / 0) !important;
}

.tw-accent-emerald-300\/10 {
  accent-color: rgb(110 231 183 / 0.1) !important;
}

.tw-accent-emerald-300\/100 {
  accent-color: rgb(110 231 183 / 1) !important;
}

.tw-accent-emerald-300\/15 {
  accent-color: rgb(110 231 183 / 0.15) !important;
}

.tw-accent-emerald-300\/20 {
  accent-color: rgb(110 231 183 / 0.2) !important;
}

.tw-accent-emerald-300\/25 {
  accent-color: rgb(110 231 183 / 0.25) !important;
}

.tw-accent-emerald-300\/30 {
  accent-color: rgb(110 231 183 / 0.3) !important;
}

.tw-accent-emerald-300\/35 {
  accent-color: rgb(110 231 183 / 0.35) !important;
}

.tw-accent-emerald-300\/40 {
  accent-color: rgb(110 231 183 / 0.4) !important;
}

.tw-accent-emerald-300\/45 {
  accent-color: rgb(110 231 183 / 0.45) !important;
}

.tw-accent-emerald-300\/5 {
  accent-color: rgb(110 231 183 / 0.05) !important;
}

.tw-accent-emerald-300\/50 {
  accent-color: rgb(110 231 183 / 0.5) !important;
}

.tw-accent-emerald-300\/55 {
  accent-color: rgb(110 231 183 / 0.55) !important;
}

.tw-accent-emerald-300\/60 {
  accent-color: rgb(110 231 183 / 0.6) !important;
}

.tw-accent-emerald-300\/65 {
  accent-color: rgb(110 231 183 / 0.65) !important;
}

.tw-accent-emerald-300\/70 {
  accent-color: rgb(110 231 183 / 0.7) !important;
}

.tw-accent-emerald-300\/75 {
  accent-color: rgb(110 231 183 / 0.75) !important;
}

.tw-accent-emerald-300\/80 {
  accent-color: rgb(110 231 183 / 0.8) !important;
}

.tw-accent-emerald-300\/85 {
  accent-color: rgb(110 231 183 / 0.85) !important;
}

.tw-accent-emerald-300\/90 {
  accent-color: rgb(110 231 183 / 0.9) !important;
}

.tw-accent-emerald-300\/95 {
  accent-color: rgb(110 231 183 / 0.95) !important;
}

.tw-accent-emerald-400 {
  accent-color: #34d399 !important;
}

.tw-accent-emerald-400\/0 {
  accent-color: rgb(52 211 153 / 0) !important;
}

.tw-accent-emerald-400\/10 {
  accent-color: rgb(52 211 153 / 0.1) !important;
}

.tw-accent-emerald-400\/100 {
  accent-color: rgb(52 211 153 / 1) !important;
}

.tw-accent-emerald-400\/15 {
  accent-color: rgb(52 211 153 / 0.15) !important;
}

.tw-accent-emerald-400\/20 {
  accent-color: rgb(52 211 153 / 0.2) !important;
}

.tw-accent-emerald-400\/25 {
  accent-color: rgb(52 211 153 / 0.25) !important;
}

.tw-accent-emerald-400\/30 {
  accent-color: rgb(52 211 153 / 0.3) !important;
}

.tw-accent-emerald-400\/35 {
  accent-color: rgb(52 211 153 / 0.35) !important;
}

.tw-accent-emerald-400\/40 {
  accent-color: rgb(52 211 153 / 0.4) !important;
}

.tw-accent-emerald-400\/45 {
  accent-color: rgb(52 211 153 / 0.45) !important;
}

.tw-accent-emerald-400\/5 {
  accent-color: rgb(52 211 153 / 0.05) !important;
}

.tw-accent-emerald-400\/50 {
  accent-color: rgb(52 211 153 / 0.5) !important;
}

.tw-accent-emerald-400\/55 {
  accent-color: rgb(52 211 153 / 0.55) !important;
}

.tw-accent-emerald-400\/60 {
  accent-color: rgb(52 211 153 / 0.6) !important;
}

.tw-accent-emerald-400\/65 {
  accent-color: rgb(52 211 153 / 0.65) !important;
}

.tw-accent-emerald-400\/70 {
  accent-color: rgb(52 211 153 / 0.7) !important;
}

.tw-accent-emerald-400\/75 {
  accent-color: rgb(52 211 153 / 0.75) !important;
}

.tw-accent-emerald-400\/80 {
  accent-color: rgb(52 211 153 / 0.8) !important;
}

.tw-accent-emerald-400\/85 {
  accent-color: rgb(52 211 153 / 0.85) !important;
}

.tw-accent-emerald-400\/90 {
  accent-color: rgb(52 211 153 / 0.9) !important;
}

.tw-accent-emerald-400\/95 {
  accent-color: rgb(52 211 153 / 0.95) !important;
}

.tw-accent-emerald-50 {
  accent-color: #ecfdf5 !important;
}

.tw-accent-emerald-50\/0 {
  accent-color: rgb(236 253 245 / 0) !important;
}

.tw-accent-emerald-50\/10 {
  accent-color: rgb(236 253 245 / 0.1) !important;
}

.tw-accent-emerald-50\/100 {
  accent-color: rgb(236 253 245 / 1) !important;
}

.tw-accent-emerald-50\/15 {
  accent-color: rgb(236 253 245 / 0.15) !important;
}

.tw-accent-emerald-50\/20 {
  accent-color: rgb(236 253 245 / 0.2) !important;
}

.tw-accent-emerald-50\/25 {
  accent-color: rgb(236 253 245 / 0.25) !important;
}

.tw-accent-emerald-50\/30 {
  accent-color: rgb(236 253 245 / 0.3) !important;
}

.tw-accent-emerald-50\/35 {
  accent-color: rgb(236 253 245 / 0.35) !important;
}

.tw-accent-emerald-50\/40 {
  accent-color: rgb(236 253 245 / 0.4) !important;
}

.tw-accent-emerald-50\/45 {
  accent-color: rgb(236 253 245 / 0.45) !important;
}

.tw-accent-emerald-50\/5 {
  accent-color: rgb(236 253 245 / 0.05) !important;
}

.tw-accent-emerald-50\/50 {
  accent-color: rgb(236 253 245 / 0.5) !important;
}

.tw-accent-emerald-50\/55 {
  accent-color: rgb(236 253 245 / 0.55) !important;
}

.tw-accent-emerald-50\/60 {
  accent-color: rgb(236 253 245 / 0.6) !important;
}

.tw-accent-emerald-50\/65 {
  accent-color: rgb(236 253 245 / 0.65) !important;
}

.tw-accent-emerald-50\/70 {
  accent-color: rgb(236 253 245 / 0.7) !important;
}

.tw-accent-emerald-50\/75 {
  accent-color: rgb(236 253 245 / 0.75) !important;
}

.tw-accent-emerald-50\/80 {
  accent-color: rgb(236 253 245 / 0.8) !important;
}

.tw-accent-emerald-50\/85 {
  accent-color: rgb(236 253 245 / 0.85) !important;
}

.tw-accent-emerald-50\/90 {
  accent-color: rgb(236 253 245 / 0.9) !important;
}

.tw-accent-emerald-50\/95 {
  accent-color: rgb(236 253 245 / 0.95) !important;
}

.tw-accent-emerald-500 {
  accent-color: #10b981 !important;
}

.tw-accent-emerald-500\/0 {
  accent-color: rgb(16 185 129 / 0) !important;
}

.tw-accent-emerald-500\/10 {
  accent-color: rgb(16 185 129 / 0.1) !important;
}

.tw-accent-emerald-500\/100 {
  accent-color: rgb(16 185 129 / 1) !important;
}

.tw-accent-emerald-500\/15 {
  accent-color: rgb(16 185 129 / 0.15) !important;
}

.tw-accent-emerald-500\/20 {
  accent-color: rgb(16 185 129 / 0.2) !important;
}

.tw-accent-emerald-500\/25 {
  accent-color: rgb(16 185 129 / 0.25) !important;
}

.tw-accent-emerald-500\/30 {
  accent-color: rgb(16 185 129 / 0.3) !important;
}

.tw-accent-emerald-500\/35 {
  accent-color: rgb(16 185 129 / 0.35) !important;
}

.tw-accent-emerald-500\/40 {
  accent-color: rgb(16 185 129 / 0.4) !important;
}

.tw-accent-emerald-500\/45 {
  accent-color: rgb(16 185 129 / 0.45) !important;
}

.tw-accent-emerald-500\/5 {
  accent-color: rgb(16 185 129 / 0.05) !important;
}

.tw-accent-emerald-500\/50 {
  accent-color: rgb(16 185 129 / 0.5) !important;
}

.tw-accent-emerald-500\/55 {
  accent-color: rgb(16 185 129 / 0.55) !important;
}

.tw-accent-emerald-500\/60 {
  accent-color: rgb(16 185 129 / 0.6) !important;
}

.tw-accent-emerald-500\/65 {
  accent-color: rgb(16 185 129 / 0.65) !important;
}

.tw-accent-emerald-500\/70 {
  accent-color: rgb(16 185 129 / 0.7) !important;
}

.tw-accent-emerald-500\/75 {
  accent-color: rgb(16 185 129 / 0.75) !important;
}

.tw-accent-emerald-500\/80 {
  accent-color: rgb(16 185 129 / 0.8) !important;
}

.tw-accent-emerald-500\/85 {
  accent-color: rgb(16 185 129 / 0.85) !important;
}

.tw-accent-emerald-500\/90 {
  accent-color: rgb(16 185 129 / 0.9) !important;
}

.tw-accent-emerald-500\/95 {
  accent-color: rgb(16 185 129 / 0.95) !important;
}

.tw-accent-emerald-600 {
  accent-color: #059669 !important;
}

.tw-accent-emerald-600\/0 {
  accent-color: rgb(5 150 105 / 0) !important;
}

.tw-accent-emerald-600\/10 {
  accent-color: rgb(5 150 105 / 0.1) !important;
}

.tw-accent-emerald-600\/100 {
  accent-color: rgb(5 150 105 / 1) !important;
}

.tw-accent-emerald-600\/15 {
  accent-color: rgb(5 150 105 / 0.15) !important;
}

.tw-accent-emerald-600\/20 {
  accent-color: rgb(5 150 105 / 0.2) !important;
}

.tw-accent-emerald-600\/25 {
  accent-color: rgb(5 150 105 / 0.25) !important;
}

.tw-accent-emerald-600\/30 {
  accent-color: rgb(5 150 105 / 0.3) !important;
}

.tw-accent-emerald-600\/35 {
  accent-color: rgb(5 150 105 / 0.35) !important;
}

.tw-accent-emerald-600\/40 {
  accent-color: rgb(5 150 105 / 0.4) !important;
}

.tw-accent-emerald-600\/45 {
  accent-color: rgb(5 150 105 / 0.45) !important;
}

.tw-accent-emerald-600\/5 {
  accent-color: rgb(5 150 105 / 0.05) !important;
}

.tw-accent-emerald-600\/50 {
  accent-color: rgb(5 150 105 / 0.5) !important;
}

.tw-accent-emerald-600\/55 {
  accent-color: rgb(5 150 105 / 0.55) !important;
}

.tw-accent-emerald-600\/60 {
  accent-color: rgb(5 150 105 / 0.6) !important;
}

.tw-accent-emerald-600\/65 {
  accent-color: rgb(5 150 105 / 0.65) !important;
}

.tw-accent-emerald-600\/70 {
  accent-color: rgb(5 150 105 / 0.7) !important;
}

.tw-accent-emerald-600\/75 {
  accent-color: rgb(5 150 105 / 0.75) !important;
}

.tw-accent-emerald-600\/80 {
  accent-color: rgb(5 150 105 / 0.8) !important;
}

.tw-accent-emerald-600\/85 {
  accent-color: rgb(5 150 105 / 0.85) !important;
}

.tw-accent-emerald-600\/90 {
  accent-color: rgb(5 150 105 / 0.9) !important;
}

.tw-accent-emerald-600\/95 {
  accent-color: rgb(5 150 105 / 0.95) !important;
}

.tw-accent-emerald-700 {
  accent-color: #047857 !important;
}

.tw-accent-emerald-700\/0 {
  accent-color: rgb(4 120 87 / 0) !important;
}

.tw-accent-emerald-700\/10 {
  accent-color: rgb(4 120 87 / 0.1) !important;
}

.tw-accent-emerald-700\/100 {
  accent-color: rgb(4 120 87 / 1) !important;
}

.tw-accent-emerald-700\/15 {
  accent-color: rgb(4 120 87 / 0.15) !important;
}

.tw-accent-emerald-700\/20 {
  accent-color: rgb(4 120 87 / 0.2) !important;
}

.tw-accent-emerald-700\/25 {
  accent-color: rgb(4 120 87 / 0.25) !important;
}

.tw-accent-emerald-700\/30 {
  accent-color: rgb(4 120 87 / 0.3) !important;
}

.tw-accent-emerald-700\/35 {
  accent-color: rgb(4 120 87 / 0.35) !important;
}

.tw-accent-emerald-700\/40 {
  accent-color: rgb(4 120 87 / 0.4) !important;
}

.tw-accent-emerald-700\/45 {
  accent-color: rgb(4 120 87 / 0.45) !important;
}

.tw-accent-emerald-700\/5 {
  accent-color: rgb(4 120 87 / 0.05) !important;
}

.tw-accent-emerald-700\/50 {
  accent-color: rgb(4 120 87 / 0.5) !important;
}

.tw-accent-emerald-700\/55 {
  accent-color: rgb(4 120 87 / 0.55) !important;
}

.tw-accent-emerald-700\/60 {
  accent-color: rgb(4 120 87 / 0.6) !important;
}

.tw-accent-emerald-700\/65 {
  accent-color: rgb(4 120 87 / 0.65) !important;
}

.tw-accent-emerald-700\/70 {
  accent-color: rgb(4 120 87 / 0.7) !important;
}

.tw-accent-emerald-700\/75 {
  accent-color: rgb(4 120 87 / 0.75) !important;
}

.tw-accent-emerald-700\/80 {
  accent-color: rgb(4 120 87 / 0.8) !important;
}

.tw-accent-emerald-700\/85 {
  accent-color: rgb(4 120 87 / 0.85) !important;
}

.tw-accent-emerald-700\/90 {
  accent-color: rgb(4 120 87 / 0.9) !important;
}

.tw-accent-emerald-700\/95 {
  accent-color: rgb(4 120 87 / 0.95) !important;
}

.tw-accent-emerald-800 {
  accent-color: #065f46 !important;
}

.tw-accent-emerald-800\/0 {
  accent-color: rgb(6 95 70 / 0) !important;
}

.tw-accent-emerald-800\/10 {
  accent-color: rgb(6 95 70 / 0.1) !important;
}

.tw-accent-emerald-800\/100 {
  accent-color: rgb(6 95 70 / 1) !important;
}

.tw-accent-emerald-800\/15 {
  accent-color: rgb(6 95 70 / 0.15) !important;
}

.tw-accent-emerald-800\/20 {
  accent-color: rgb(6 95 70 / 0.2) !important;
}

.tw-accent-emerald-800\/25 {
  accent-color: rgb(6 95 70 / 0.25) !important;
}

.tw-accent-emerald-800\/30 {
  accent-color: rgb(6 95 70 / 0.3) !important;
}

.tw-accent-emerald-800\/35 {
  accent-color: rgb(6 95 70 / 0.35) !important;
}

.tw-accent-emerald-800\/40 {
  accent-color: rgb(6 95 70 / 0.4) !important;
}

.tw-accent-emerald-800\/45 {
  accent-color: rgb(6 95 70 / 0.45) !important;
}

.tw-accent-emerald-800\/5 {
  accent-color: rgb(6 95 70 / 0.05) !important;
}

.tw-accent-emerald-800\/50 {
  accent-color: rgb(6 95 70 / 0.5) !important;
}

.tw-accent-emerald-800\/55 {
  accent-color: rgb(6 95 70 / 0.55) !important;
}

.tw-accent-emerald-800\/60 {
  accent-color: rgb(6 95 70 / 0.6) !important;
}

.tw-accent-emerald-800\/65 {
  accent-color: rgb(6 95 70 / 0.65) !important;
}

.tw-accent-emerald-800\/70 {
  accent-color: rgb(6 95 70 / 0.7) !important;
}

.tw-accent-emerald-800\/75 {
  accent-color: rgb(6 95 70 / 0.75) !important;
}

.tw-accent-emerald-800\/80 {
  accent-color: rgb(6 95 70 / 0.8) !important;
}

.tw-accent-emerald-800\/85 {
  accent-color: rgb(6 95 70 / 0.85) !important;
}

.tw-accent-emerald-800\/90 {
  accent-color: rgb(6 95 70 / 0.9) !important;
}

.tw-accent-emerald-800\/95 {
  accent-color: rgb(6 95 70 / 0.95) !important;
}

.tw-accent-emerald-900 {
  accent-color: #064e3b !important;
}

.tw-accent-emerald-900\/0 {
  accent-color: rgb(6 78 59 / 0) !important;
}

.tw-accent-emerald-900\/10 {
  accent-color: rgb(6 78 59 / 0.1) !important;
}

.tw-accent-emerald-900\/100 {
  accent-color: rgb(6 78 59 / 1) !important;
}

.tw-accent-emerald-900\/15 {
  accent-color: rgb(6 78 59 / 0.15) !important;
}

.tw-accent-emerald-900\/20 {
  accent-color: rgb(6 78 59 / 0.2) !important;
}

.tw-accent-emerald-900\/25 {
  accent-color: rgb(6 78 59 / 0.25) !important;
}

.tw-accent-emerald-900\/30 {
  accent-color: rgb(6 78 59 / 0.3) !important;
}

.tw-accent-emerald-900\/35 {
  accent-color: rgb(6 78 59 / 0.35) !important;
}

.tw-accent-emerald-900\/40 {
  accent-color: rgb(6 78 59 / 0.4) !important;
}

.tw-accent-emerald-900\/45 {
  accent-color: rgb(6 78 59 / 0.45) !important;
}

.tw-accent-emerald-900\/5 {
  accent-color: rgb(6 78 59 / 0.05) !important;
}

.tw-accent-emerald-900\/50 {
  accent-color: rgb(6 78 59 / 0.5) !important;
}

.tw-accent-emerald-900\/55 {
  accent-color: rgb(6 78 59 / 0.55) !important;
}

.tw-accent-emerald-900\/60 {
  accent-color: rgb(6 78 59 / 0.6) !important;
}

.tw-accent-emerald-900\/65 {
  accent-color: rgb(6 78 59 / 0.65) !important;
}

.tw-accent-emerald-900\/70 {
  accent-color: rgb(6 78 59 / 0.7) !important;
}

.tw-accent-emerald-900\/75 {
  accent-color: rgb(6 78 59 / 0.75) !important;
}

.tw-accent-emerald-900\/80 {
  accent-color: rgb(6 78 59 / 0.8) !important;
}

.tw-accent-emerald-900\/85 {
  accent-color: rgb(6 78 59 / 0.85) !important;
}

.tw-accent-emerald-900\/90 {
  accent-color: rgb(6 78 59 / 0.9) !important;
}

.tw-accent-emerald-900\/95 {
  accent-color: rgb(6 78 59 / 0.95) !important;
}

.tw-accent-emerald-950 {
  accent-color: #022c22 !important;
}

.tw-accent-emerald-950\/0 {
  accent-color: rgb(2 44 34 / 0) !important;
}

.tw-accent-emerald-950\/10 {
  accent-color: rgb(2 44 34 / 0.1) !important;
}

.tw-accent-emerald-950\/100 {
  accent-color: rgb(2 44 34 / 1) !important;
}

.tw-accent-emerald-950\/15 {
  accent-color: rgb(2 44 34 / 0.15) !important;
}

.tw-accent-emerald-950\/20 {
  accent-color: rgb(2 44 34 / 0.2) !important;
}

.tw-accent-emerald-950\/25 {
  accent-color: rgb(2 44 34 / 0.25) !important;
}

.tw-accent-emerald-950\/30 {
  accent-color: rgb(2 44 34 / 0.3) !important;
}

.tw-accent-emerald-950\/35 {
  accent-color: rgb(2 44 34 / 0.35) !important;
}

.tw-accent-emerald-950\/40 {
  accent-color: rgb(2 44 34 / 0.4) !important;
}

.tw-accent-emerald-950\/45 {
  accent-color: rgb(2 44 34 / 0.45) !important;
}

.tw-accent-emerald-950\/5 {
  accent-color: rgb(2 44 34 / 0.05) !important;
}

.tw-accent-emerald-950\/50 {
  accent-color: rgb(2 44 34 / 0.5) !important;
}

.tw-accent-emerald-950\/55 {
  accent-color: rgb(2 44 34 / 0.55) !important;
}

.tw-accent-emerald-950\/60 {
  accent-color: rgb(2 44 34 / 0.6) !important;
}

.tw-accent-emerald-950\/65 {
  accent-color: rgb(2 44 34 / 0.65) !important;
}

.tw-accent-emerald-950\/70 {
  accent-color: rgb(2 44 34 / 0.7) !important;
}

.tw-accent-emerald-950\/75 {
  accent-color: rgb(2 44 34 / 0.75) !important;
}

.tw-accent-emerald-950\/80 {
  accent-color: rgb(2 44 34 / 0.8) !important;
}

.tw-accent-emerald-950\/85 {
  accent-color: rgb(2 44 34 / 0.85) !important;
}

.tw-accent-emerald-950\/90 {
  accent-color: rgb(2 44 34 / 0.9) !important;
}

.tw-accent-emerald-950\/95 {
  accent-color: rgb(2 44 34 / 0.95) !important;
}

.tw-accent-fuchsia-100 {
  accent-color: #fae8ff !important;
}

.tw-accent-fuchsia-100\/0 {
  accent-color: rgb(250 232 255 / 0) !important;
}

.tw-accent-fuchsia-100\/10 {
  accent-color: rgb(250 232 255 / 0.1) !important;
}

.tw-accent-fuchsia-100\/100 {
  accent-color: rgb(250 232 255 / 1) !important;
}

.tw-accent-fuchsia-100\/15 {
  accent-color: rgb(250 232 255 / 0.15) !important;
}

.tw-accent-fuchsia-100\/20 {
  accent-color: rgb(250 232 255 / 0.2) !important;
}

.tw-accent-fuchsia-100\/25 {
  accent-color: rgb(250 232 255 / 0.25) !important;
}

.tw-accent-fuchsia-100\/30 {
  accent-color: rgb(250 232 255 / 0.3) !important;
}

.tw-accent-fuchsia-100\/35 {
  accent-color: rgb(250 232 255 / 0.35) !important;
}

.tw-accent-fuchsia-100\/40 {
  accent-color: rgb(250 232 255 / 0.4) !important;
}

.tw-accent-fuchsia-100\/45 {
  accent-color: rgb(250 232 255 / 0.45) !important;
}

.tw-accent-fuchsia-100\/5 {
  accent-color: rgb(250 232 255 / 0.05) !important;
}

.tw-accent-fuchsia-100\/50 {
  accent-color: rgb(250 232 255 / 0.5) !important;
}

.tw-accent-fuchsia-100\/55 {
  accent-color: rgb(250 232 255 / 0.55) !important;
}

.tw-accent-fuchsia-100\/60 {
  accent-color: rgb(250 232 255 / 0.6) !important;
}

.tw-accent-fuchsia-100\/65 {
  accent-color: rgb(250 232 255 / 0.65) !important;
}

.tw-accent-fuchsia-100\/70 {
  accent-color: rgb(250 232 255 / 0.7) !important;
}

.tw-accent-fuchsia-100\/75 {
  accent-color: rgb(250 232 255 / 0.75) !important;
}

.tw-accent-fuchsia-100\/80 {
  accent-color: rgb(250 232 255 / 0.8) !important;
}

.tw-accent-fuchsia-100\/85 {
  accent-color: rgb(250 232 255 / 0.85) !important;
}

.tw-accent-fuchsia-100\/90 {
  accent-color: rgb(250 232 255 / 0.9) !important;
}

.tw-accent-fuchsia-100\/95 {
  accent-color: rgb(250 232 255 / 0.95) !important;
}

.tw-accent-fuchsia-200 {
  accent-color: #f5d0fe !important;
}

.tw-accent-fuchsia-200\/0 {
  accent-color: rgb(245 208 254 / 0) !important;
}

.tw-accent-fuchsia-200\/10 {
  accent-color: rgb(245 208 254 / 0.1) !important;
}

.tw-accent-fuchsia-200\/100 {
  accent-color: rgb(245 208 254 / 1) !important;
}

.tw-accent-fuchsia-200\/15 {
  accent-color: rgb(245 208 254 / 0.15) !important;
}

.tw-accent-fuchsia-200\/20 {
  accent-color: rgb(245 208 254 / 0.2) !important;
}

.tw-accent-fuchsia-200\/25 {
  accent-color: rgb(245 208 254 / 0.25) !important;
}

.tw-accent-fuchsia-200\/30 {
  accent-color: rgb(245 208 254 / 0.3) !important;
}

.tw-accent-fuchsia-200\/35 {
  accent-color: rgb(245 208 254 / 0.35) !important;
}

.tw-accent-fuchsia-200\/40 {
  accent-color: rgb(245 208 254 / 0.4) !important;
}

.tw-accent-fuchsia-200\/45 {
  accent-color: rgb(245 208 254 / 0.45) !important;
}

.tw-accent-fuchsia-200\/5 {
  accent-color: rgb(245 208 254 / 0.05) !important;
}

.tw-accent-fuchsia-200\/50 {
  accent-color: rgb(245 208 254 / 0.5) !important;
}

.tw-accent-fuchsia-200\/55 {
  accent-color: rgb(245 208 254 / 0.55) !important;
}

.tw-accent-fuchsia-200\/60 {
  accent-color: rgb(245 208 254 / 0.6) !important;
}

.tw-accent-fuchsia-200\/65 {
  accent-color: rgb(245 208 254 / 0.65) !important;
}

.tw-accent-fuchsia-200\/70 {
  accent-color: rgb(245 208 254 / 0.7) !important;
}

.tw-accent-fuchsia-200\/75 {
  accent-color: rgb(245 208 254 / 0.75) !important;
}

.tw-accent-fuchsia-200\/80 {
  accent-color: rgb(245 208 254 / 0.8) !important;
}

.tw-accent-fuchsia-200\/85 {
  accent-color: rgb(245 208 254 / 0.85) !important;
}

.tw-accent-fuchsia-200\/90 {
  accent-color: rgb(245 208 254 / 0.9) !important;
}

.tw-accent-fuchsia-200\/95 {
  accent-color: rgb(245 208 254 / 0.95) !important;
}

.tw-accent-fuchsia-300 {
  accent-color: #f0abfc !important;
}

.tw-accent-fuchsia-300\/0 {
  accent-color: rgb(240 171 252 / 0) !important;
}

.tw-accent-fuchsia-300\/10 {
  accent-color: rgb(240 171 252 / 0.1) !important;
}

.tw-accent-fuchsia-300\/100 {
  accent-color: rgb(240 171 252 / 1) !important;
}

.tw-accent-fuchsia-300\/15 {
  accent-color: rgb(240 171 252 / 0.15) !important;
}

.tw-accent-fuchsia-300\/20 {
  accent-color: rgb(240 171 252 / 0.2) !important;
}

.tw-accent-fuchsia-300\/25 {
  accent-color: rgb(240 171 252 / 0.25) !important;
}

.tw-accent-fuchsia-300\/30 {
  accent-color: rgb(240 171 252 / 0.3) !important;
}

.tw-accent-fuchsia-300\/35 {
  accent-color: rgb(240 171 252 / 0.35) !important;
}

.tw-accent-fuchsia-300\/40 {
  accent-color: rgb(240 171 252 / 0.4) !important;
}

.tw-accent-fuchsia-300\/45 {
  accent-color: rgb(240 171 252 / 0.45) !important;
}

.tw-accent-fuchsia-300\/5 {
  accent-color: rgb(240 171 252 / 0.05) !important;
}

.tw-accent-fuchsia-300\/50 {
  accent-color: rgb(240 171 252 / 0.5) !important;
}

.tw-accent-fuchsia-300\/55 {
  accent-color: rgb(240 171 252 / 0.55) !important;
}

.tw-accent-fuchsia-300\/60 {
  accent-color: rgb(240 171 252 / 0.6) !important;
}

.tw-accent-fuchsia-300\/65 {
  accent-color: rgb(240 171 252 / 0.65) !important;
}

.tw-accent-fuchsia-300\/70 {
  accent-color: rgb(240 171 252 / 0.7) !important;
}

.tw-accent-fuchsia-300\/75 {
  accent-color: rgb(240 171 252 / 0.75) !important;
}

.tw-accent-fuchsia-300\/80 {
  accent-color: rgb(240 171 252 / 0.8) !important;
}

.tw-accent-fuchsia-300\/85 {
  accent-color: rgb(240 171 252 / 0.85) !important;
}

.tw-accent-fuchsia-300\/90 {
  accent-color: rgb(240 171 252 / 0.9) !important;
}

.tw-accent-fuchsia-300\/95 {
  accent-color: rgb(240 171 252 / 0.95) !important;
}

.tw-accent-fuchsia-400 {
  accent-color: #e879f9 !important;
}

.tw-accent-fuchsia-400\/0 {
  accent-color: rgb(232 121 249 / 0) !important;
}

.tw-accent-fuchsia-400\/10 {
  accent-color: rgb(232 121 249 / 0.1) !important;
}

.tw-accent-fuchsia-400\/100 {
  accent-color: rgb(232 121 249 / 1) !important;
}

.tw-accent-fuchsia-400\/15 {
  accent-color: rgb(232 121 249 / 0.15) !important;
}

.tw-accent-fuchsia-400\/20 {
  accent-color: rgb(232 121 249 / 0.2) !important;
}

.tw-accent-fuchsia-400\/25 {
  accent-color: rgb(232 121 249 / 0.25) !important;
}

.tw-accent-fuchsia-400\/30 {
  accent-color: rgb(232 121 249 / 0.3) !important;
}

.tw-accent-fuchsia-400\/35 {
  accent-color: rgb(232 121 249 / 0.35) !important;
}

.tw-accent-fuchsia-400\/40 {
  accent-color: rgb(232 121 249 / 0.4) !important;
}

.tw-accent-fuchsia-400\/45 {
  accent-color: rgb(232 121 249 / 0.45) !important;
}

.tw-accent-fuchsia-400\/5 {
  accent-color: rgb(232 121 249 / 0.05) !important;
}

.tw-accent-fuchsia-400\/50 {
  accent-color: rgb(232 121 249 / 0.5) !important;
}

.tw-accent-fuchsia-400\/55 {
  accent-color: rgb(232 121 249 / 0.55) !important;
}

.tw-accent-fuchsia-400\/60 {
  accent-color: rgb(232 121 249 / 0.6) !important;
}

.tw-accent-fuchsia-400\/65 {
  accent-color: rgb(232 121 249 / 0.65) !important;
}

.tw-accent-fuchsia-400\/70 {
  accent-color: rgb(232 121 249 / 0.7) !important;
}

.tw-accent-fuchsia-400\/75 {
  accent-color: rgb(232 121 249 / 0.75) !important;
}

.tw-accent-fuchsia-400\/80 {
  accent-color: rgb(232 121 249 / 0.8) !important;
}

.tw-accent-fuchsia-400\/85 {
  accent-color: rgb(232 121 249 / 0.85) !important;
}

.tw-accent-fuchsia-400\/90 {
  accent-color: rgb(232 121 249 / 0.9) !important;
}

.tw-accent-fuchsia-400\/95 {
  accent-color: rgb(232 121 249 / 0.95) !important;
}

.tw-accent-fuchsia-50 {
  accent-color: #fdf4ff !important;
}

.tw-accent-fuchsia-50\/0 {
  accent-color: rgb(253 244 255 / 0) !important;
}

.tw-accent-fuchsia-50\/10 {
  accent-color: rgb(253 244 255 / 0.1) !important;
}

.tw-accent-fuchsia-50\/100 {
  accent-color: rgb(253 244 255 / 1) !important;
}

.tw-accent-fuchsia-50\/15 {
  accent-color: rgb(253 244 255 / 0.15) !important;
}

.tw-accent-fuchsia-50\/20 {
  accent-color: rgb(253 244 255 / 0.2) !important;
}

.tw-accent-fuchsia-50\/25 {
  accent-color: rgb(253 244 255 / 0.25) !important;
}

.tw-accent-fuchsia-50\/30 {
  accent-color: rgb(253 244 255 / 0.3) !important;
}

.tw-accent-fuchsia-50\/35 {
  accent-color: rgb(253 244 255 / 0.35) !important;
}

.tw-accent-fuchsia-50\/40 {
  accent-color: rgb(253 244 255 / 0.4) !important;
}

.tw-accent-fuchsia-50\/45 {
  accent-color: rgb(253 244 255 / 0.45) !important;
}

.tw-accent-fuchsia-50\/5 {
  accent-color: rgb(253 244 255 / 0.05) !important;
}

.tw-accent-fuchsia-50\/50 {
  accent-color: rgb(253 244 255 / 0.5) !important;
}

.tw-accent-fuchsia-50\/55 {
  accent-color: rgb(253 244 255 / 0.55) !important;
}

.tw-accent-fuchsia-50\/60 {
  accent-color: rgb(253 244 255 / 0.6) !important;
}

.tw-accent-fuchsia-50\/65 {
  accent-color: rgb(253 244 255 / 0.65) !important;
}

.tw-accent-fuchsia-50\/70 {
  accent-color: rgb(253 244 255 / 0.7) !important;
}

.tw-accent-fuchsia-50\/75 {
  accent-color: rgb(253 244 255 / 0.75) !important;
}

.tw-accent-fuchsia-50\/80 {
  accent-color: rgb(253 244 255 / 0.8) !important;
}

.tw-accent-fuchsia-50\/85 {
  accent-color: rgb(253 244 255 / 0.85) !important;
}

.tw-accent-fuchsia-50\/90 {
  accent-color: rgb(253 244 255 / 0.9) !important;
}

.tw-accent-fuchsia-50\/95 {
  accent-color: rgb(253 244 255 / 0.95) !important;
}

.tw-accent-fuchsia-500 {
  accent-color: #d946ef !important;
}

.tw-accent-fuchsia-500\/0 {
  accent-color: rgb(217 70 239 / 0) !important;
}

.tw-accent-fuchsia-500\/10 {
  accent-color: rgb(217 70 239 / 0.1) !important;
}

.tw-accent-fuchsia-500\/100 {
  accent-color: rgb(217 70 239 / 1) !important;
}

.tw-accent-fuchsia-500\/15 {
  accent-color: rgb(217 70 239 / 0.15) !important;
}

.tw-accent-fuchsia-500\/20 {
  accent-color: rgb(217 70 239 / 0.2) !important;
}

.tw-accent-fuchsia-500\/25 {
  accent-color: rgb(217 70 239 / 0.25) !important;
}

.tw-accent-fuchsia-500\/30 {
  accent-color: rgb(217 70 239 / 0.3) !important;
}

.tw-accent-fuchsia-500\/35 {
  accent-color: rgb(217 70 239 / 0.35) !important;
}

.tw-accent-fuchsia-500\/40 {
  accent-color: rgb(217 70 239 / 0.4) !important;
}

.tw-accent-fuchsia-500\/45 {
  accent-color: rgb(217 70 239 / 0.45) !important;
}

.tw-accent-fuchsia-500\/5 {
  accent-color: rgb(217 70 239 / 0.05) !important;
}

.tw-accent-fuchsia-500\/50 {
  accent-color: rgb(217 70 239 / 0.5) !important;
}

.tw-accent-fuchsia-500\/55 {
  accent-color: rgb(217 70 239 / 0.55) !important;
}

.tw-accent-fuchsia-500\/60 {
  accent-color: rgb(217 70 239 / 0.6) !important;
}

.tw-accent-fuchsia-500\/65 {
  accent-color: rgb(217 70 239 / 0.65) !important;
}

.tw-accent-fuchsia-500\/70 {
  accent-color: rgb(217 70 239 / 0.7) !important;
}

.tw-accent-fuchsia-500\/75 {
  accent-color: rgb(217 70 239 / 0.75) !important;
}

.tw-accent-fuchsia-500\/80 {
  accent-color: rgb(217 70 239 / 0.8) !important;
}

.tw-accent-fuchsia-500\/85 {
  accent-color: rgb(217 70 239 / 0.85) !important;
}

.tw-accent-fuchsia-500\/90 {
  accent-color: rgb(217 70 239 / 0.9) !important;
}

.tw-accent-fuchsia-500\/95 {
  accent-color: rgb(217 70 239 / 0.95) !important;
}

.tw-accent-fuchsia-600 {
  accent-color: #c026d3 !important;
}

.tw-accent-fuchsia-600\/0 {
  accent-color: rgb(192 38 211 / 0) !important;
}

.tw-accent-fuchsia-600\/10 {
  accent-color: rgb(192 38 211 / 0.1) !important;
}

.tw-accent-fuchsia-600\/100 {
  accent-color: rgb(192 38 211 / 1) !important;
}

.tw-accent-fuchsia-600\/15 {
  accent-color: rgb(192 38 211 / 0.15) !important;
}

.tw-accent-fuchsia-600\/20 {
  accent-color: rgb(192 38 211 / 0.2) !important;
}

.tw-accent-fuchsia-600\/25 {
  accent-color: rgb(192 38 211 / 0.25) !important;
}

.tw-accent-fuchsia-600\/30 {
  accent-color: rgb(192 38 211 / 0.3) !important;
}

.tw-accent-fuchsia-600\/35 {
  accent-color: rgb(192 38 211 / 0.35) !important;
}

.tw-accent-fuchsia-600\/40 {
  accent-color: rgb(192 38 211 / 0.4) !important;
}

.tw-accent-fuchsia-600\/45 {
  accent-color: rgb(192 38 211 / 0.45) !important;
}

.tw-accent-fuchsia-600\/5 {
  accent-color: rgb(192 38 211 / 0.05) !important;
}

.tw-accent-fuchsia-600\/50 {
  accent-color: rgb(192 38 211 / 0.5) !important;
}

.tw-accent-fuchsia-600\/55 {
  accent-color: rgb(192 38 211 / 0.55) !important;
}

.tw-accent-fuchsia-600\/60 {
  accent-color: rgb(192 38 211 / 0.6) !important;
}

.tw-accent-fuchsia-600\/65 {
  accent-color: rgb(192 38 211 / 0.65) !important;
}

.tw-accent-fuchsia-600\/70 {
  accent-color: rgb(192 38 211 / 0.7) !important;
}

.tw-accent-fuchsia-600\/75 {
  accent-color: rgb(192 38 211 / 0.75) !important;
}

.tw-accent-fuchsia-600\/80 {
  accent-color: rgb(192 38 211 / 0.8) !important;
}

.tw-accent-fuchsia-600\/85 {
  accent-color: rgb(192 38 211 / 0.85) !important;
}

.tw-accent-fuchsia-600\/90 {
  accent-color: rgb(192 38 211 / 0.9) !important;
}

.tw-accent-fuchsia-600\/95 {
  accent-color: rgb(192 38 211 / 0.95) !important;
}

.tw-accent-fuchsia-700 {
  accent-color: #a21caf !important;
}

.tw-accent-fuchsia-700\/0 {
  accent-color: rgb(162 28 175 / 0) !important;
}

.tw-accent-fuchsia-700\/10 {
  accent-color: rgb(162 28 175 / 0.1) !important;
}

.tw-accent-fuchsia-700\/100 {
  accent-color: rgb(162 28 175 / 1) !important;
}

.tw-accent-fuchsia-700\/15 {
  accent-color: rgb(162 28 175 / 0.15) !important;
}

.tw-accent-fuchsia-700\/20 {
  accent-color: rgb(162 28 175 / 0.2) !important;
}

.tw-accent-fuchsia-700\/25 {
  accent-color: rgb(162 28 175 / 0.25) !important;
}

.tw-accent-fuchsia-700\/30 {
  accent-color: rgb(162 28 175 / 0.3) !important;
}

.tw-accent-fuchsia-700\/35 {
  accent-color: rgb(162 28 175 / 0.35) !important;
}

.tw-accent-fuchsia-700\/40 {
  accent-color: rgb(162 28 175 / 0.4) !important;
}

.tw-accent-fuchsia-700\/45 {
  accent-color: rgb(162 28 175 / 0.45) !important;
}

.tw-accent-fuchsia-700\/5 {
  accent-color: rgb(162 28 175 / 0.05) !important;
}

.tw-accent-fuchsia-700\/50 {
  accent-color: rgb(162 28 175 / 0.5) !important;
}

.tw-accent-fuchsia-700\/55 {
  accent-color: rgb(162 28 175 / 0.55) !important;
}

.tw-accent-fuchsia-700\/60 {
  accent-color: rgb(162 28 175 / 0.6) !important;
}

.tw-accent-fuchsia-700\/65 {
  accent-color: rgb(162 28 175 / 0.65) !important;
}

.tw-accent-fuchsia-700\/70 {
  accent-color: rgb(162 28 175 / 0.7) !important;
}

.tw-accent-fuchsia-700\/75 {
  accent-color: rgb(162 28 175 / 0.75) !important;
}

.tw-accent-fuchsia-700\/80 {
  accent-color: rgb(162 28 175 / 0.8) !important;
}

.tw-accent-fuchsia-700\/85 {
  accent-color: rgb(162 28 175 / 0.85) !important;
}

.tw-accent-fuchsia-700\/90 {
  accent-color: rgb(162 28 175 / 0.9) !important;
}

.tw-accent-fuchsia-700\/95 {
  accent-color: rgb(162 28 175 / 0.95) !important;
}

.tw-accent-fuchsia-800 {
  accent-color: #86198f !important;
}

.tw-accent-fuchsia-800\/0 {
  accent-color: rgb(134 25 143 / 0) !important;
}

.tw-accent-fuchsia-800\/10 {
  accent-color: rgb(134 25 143 / 0.1) !important;
}

.tw-accent-fuchsia-800\/100 {
  accent-color: rgb(134 25 143 / 1) !important;
}

.tw-accent-fuchsia-800\/15 {
  accent-color: rgb(134 25 143 / 0.15) !important;
}

.tw-accent-fuchsia-800\/20 {
  accent-color: rgb(134 25 143 / 0.2) !important;
}

.tw-accent-fuchsia-800\/25 {
  accent-color: rgb(134 25 143 / 0.25) !important;
}

.tw-accent-fuchsia-800\/30 {
  accent-color: rgb(134 25 143 / 0.3) !important;
}

.tw-accent-fuchsia-800\/35 {
  accent-color: rgb(134 25 143 / 0.35) !important;
}

.tw-accent-fuchsia-800\/40 {
  accent-color: rgb(134 25 143 / 0.4) !important;
}

.tw-accent-fuchsia-800\/45 {
  accent-color: rgb(134 25 143 / 0.45) !important;
}

.tw-accent-fuchsia-800\/5 {
  accent-color: rgb(134 25 143 / 0.05) !important;
}

.tw-accent-fuchsia-800\/50 {
  accent-color: rgb(134 25 143 / 0.5) !important;
}

.tw-accent-fuchsia-800\/55 {
  accent-color: rgb(134 25 143 / 0.55) !important;
}

.tw-accent-fuchsia-800\/60 {
  accent-color: rgb(134 25 143 / 0.6) !important;
}

.tw-accent-fuchsia-800\/65 {
  accent-color: rgb(134 25 143 / 0.65) !important;
}

.tw-accent-fuchsia-800\/70 {
  accent-color: rgb(134 25 143 / 0.7) !important;
}

.tw-accent-fuchsia-800\/75 {
  accent-color: rgb(134 25 143 / 0.75) !important;
}

.tw-accent-fuchsia-800\/80 {
  accent-color: rgb(134 25 143 / 0.8) !important;
}

.tw-accent-fuchsia-800\/85 {
  accent-color: rgb(134 25 143 / 0.85) !important;
}

.tw-accent-fuchsia-800\/90 {
  accent-color: rgb(134 25 143 / 0.9) !important;
}

.tw-accent-fuchsia-800\/95 {
  accent-color: rgb(134 25 143 / 0.95) !important;
}

.tw-accent-fuchsia-900 {
  accent-color: #701a75 !important;
}

.tw-accent-fuchsia-900\/0 {
  accent-color: rgb(112 26 117 / 0) !important;
}

.tw-accent-fuchsia-900\/10 {
  accent-color: rgb(112 26 117 / 0.1) !important;
}

.tw-accent-fuchsia-900\/100 {
  accent-color: rgb(112 26 117 / 1) !important;
}

.tw-accent-fuchsia-900\/15 {
  accent-color: rgb(112 26 117 / 0.15) !important;
}

.tw-accent-fuchsia-900\/20 {
  accent-color: rgb(112 26 117 / 0.2) !important;
}

.tw-accent-fuchsia-900\/25 {
  accent-color: rgb(112 26 117 / 0.25) !important;
}

.tw-accent-fuchsia-900\/30 {
  accent-color: rgb(112 26 117 / 0.3) !important;
}

.tw-accent-fuchsia-900\/35 {
  accent-color: rgb(112 26 117 / 0.35) !important;
}

.tw-accent-fuchsia-900\/40 {
  accent-color: rgb(112 26 117 / 0.4) !important;
}

.tw-accent-fuchsia-900\/45 {
  accent-color: rgb(112 26 117 / 0.45) !important;
}

.tw-accent-fuchsia-900\/5 {
  accent-color: rgb(112 26 117 / 0.05) !important;
}

.tw-accent-fuchsia-900\/50 {
  accent-color: rgb(112 26 117 / 0.5) !important;
}

.tw-accent-fuchsia-900\/55 {
  accent-color: rgb(112 26 117 / 0.55) !important;
}

.tw-accent-fuchsia-900\/60 {
  accent-color: rgb(112 26 117 / 0.6) !important;
}

.tw-accent-fuchsia-900\/65 {
  accent-color: rgb(112 26 117 / 0.65) !important;
}

.tw-accent-fuchsia-900\/70 {
  accent-color: rgb(112 26 117 / 0.7) !important;
}

.tw-accent-fuchsia-900\/75 {
  accent-color: rgb(112 26 117 / 0.75) !important;
}

.tw-accent-fuchsia-900\/80 {
  accent-color: rgb(112 26 117 / 0.8) !important;
}

.tw-accent-fuchsia-900\/85 {
  accent-color: rgb(112 26 117 / 0.85) !important;
}

.tw-accent-fuchsia-900\/90 {
  accent-color: rgb(112 26 117 / 0.9) !important;
}

.tw-accent-fuchsia-900\/95 {
  accent-color: rgb(112 26 117 / 0.95) !important;
}

.tw-accent-fuchsia-950 {
  accent-color: #4a044e !important;
}

.tw-accent-fuchsia-950\/0 {
  accent-color: rgb(74 4 78 / 0) !important;
}

.tw-accent-fuchsia-950\/10 {
  accent-color: rgb(74 4 78 / 0.1) !important;
}

.tw-accent-fuchsia-950\/100 {
  accent-color: rgb(74 4 78 / 1) !important;
}

.tw-accent-fuchsia-950\/15 {
  accent-color: rgb(74 4 78 / 0.15) !important;
}

.tw-accent-fuchsia-950\/20 {
  accent-color: rgb(74 4 78 / 0.2) !important;
}

.tw-accent-fuchsia-950\/25 {
  accent-color: rgb(74 4 78 / 0.25) !important;
}

.tw-accent-fuchsia-950\/30 {
  accent-color: rgb(74 4 78 / 0.3) !important;
}

.tw-accent-fuchsia-950\/35 {
  accent-color: rgb(74 4 78 / 0.35) !important;
}

.tw-accent-fuchsia-950\/40 {
  accent-color: rgb(74 4 78 / 0.4) !important;
}

.tw-accent-fuchsia-950\/45 {
  accent-color: rgb(74 4 78 / 0.45) !important;
}

.tw-accent-fuchsia-950\/5 {
  accent-color: rgb(74 4 78 / 0.05) !important;
}

.tw-accent-fuchsia-950\/50 {
  accent-color: rgb(74 4 78 / 0.5) !important;
}

.tw-accent-fuchsia-950\/55 {
  accent-color: rgb(74 4 78 / 0.55) !important;
}

.tw-accent-fuchsia-950\/60 {
  accent-color: rgb(74 4 78 / 0.6) !important;
}

.tw-accent-fuchsia-950\/65 {
  accent-color: rgb(74 4 78 / 0.65) !important;
}

.tw-accent-fuchsia-950\/70 {
  accent-color: rgb(74 4 78 / 0.7) !important;
}

.tw-accent-fuchsia-950\/75 {
  accent-color: rgb(74 4 78 / 0.75) !important;
}

.tw-accent-fuchsia-950\/80 {
  accent-color: rgb(74 4 78 / 0.8) !important;
}

.tw-accent-fuchsia-950\/85 {
  accent-color: rgb(74 4 78 / 0.85) !important;
}

.tw-accent-fuchsia-950\/90 {
  accent-color: rgb(74 4 78 / 0.9) !important;
}

.tw-accent-fuchsia-950\/95 {
  accent-color: rgb(74 4 78 / 0.95) !important;
}

.tw-accent-gray-100 {
  accent-color: #f3f4f6 !important;
}

.tw-accent-gray-100\/0 {
  accent-color: rgb(243 244 246 / 0) !important;
}

.tw-accent-gray-100\/10 {
  accent-color: rgb(243 244 246 / 0.1) !important;
}

.tw-accent-gray-100\/100 {
  accent-color: rgb(243 244 246 / 1) !important;
}

.tw-accent-gray-100\/15 {
  accent-color: rgb(243 244 246 / 0.15) !important;
}

.tw-accent-gray-100\/20 {
  accent-color: rgb(243 244 246 / 0.2) !important;
}

.tw-accent-gray-100\/25 {
  accent-color: rgb(243 244 246 / 0.25) !important;
}

.tw-accent-gray-100\/30 {
  accent-color: rgb(243 244 246 / 0.3) !important;
}

.tw-accent-gray-100\/35 {
  accent-color: rgb(243 244 246 / 0.35) !important;
}

.tw-accent-gray-100\/40 {
  accent-color: rgb(243 244 246 / 0.4) !important;
}

.tw-accent-gray-100\/45 {
  accent-color: rgb(243 244 246 / 0.45) !important;
}

.tw-accent-gray-100\/5 {
  accent-color: rgb(243 244 246 / 0.05) !important;
}

.tw-accent-gray-100\/50 {
  accent-color: rgb(243 244 246 / 0.5) !important;
}

.tw-accent-gray-100\/55 {
  accent-color: rgb(243 244 246 / 0.55) !important;
}

.tw-accent-gray-100\/60 {
  accent-color: rgb(243 244 246 / 0.6) !important;
}

.tw-accent-gray-100\/65 {
  accent-color: rgb(243 244 246 / 0.65) !important;
}

.tw-accent-gray-100\/70 {
  accent-color: rgb(243 244 246 / 0.7) !important;
}

.tw-accent-gray-100\/75 {
  accent-color: rgb(243 244 246 / 0.75) !important;
}

.tw-accent-gray-100\/80 {
  accent-color: rgb(243 244 246 / 0.8) !important;
}

.tw-accent-gray-100\/85 {
  accent-color: rgb(243 244 246 / 0.85) !important;
}

.tw-accent-gray-100\/90 {
  accent-color: rgb(243 244 246 / 0.9) !important;
}

.tw-accent-gray-100\/95 {
  accent-color: rgb(243 244 246 / 0.95) !important;
}

.tw-accent-gray-200 {
  accent-color: #e5e7eb !important;
}

.tw-accent-gray-200\/0 {
  accent-color: rgb(229 231 235 / 0) !important;
}

.tw-accent-gray-200\/10 {
  accent-color: rgb(229 231 235 / 0.1) !important;
}

.tw-accent-gray-200\/100 {
  accent-color: rgb(229 231 235 / 1) !important;
}

.tw-accent-gray-200\/15 {
  accent-color: rgb(229 231 235 / 0.15) !important;
}

.tw-accent-gray-200\/20 {
  accent-color: rgb(229 231 235 / 0.2) !important;
}

.tw-accent-gray-200\/25 {
  accent-color: rgb(229 231 235 / 0.25) !important;
}

.tw-accent-gray-200\/30 {
  accent-color: rgb(229 231 235 / 0.3) !important;
}

.tw-accent-gray-200\/35 {
  accent-color: rgb(229 231 235 / 0.35) !important;
}

.tw-accent-gray-200\/40 {
  accent-color: rgb(229 231 235 / 0.4) !important;
}

.tw-accent-gray-200\/45 {
  accent-color: rgb(229 231 235 / 0.45) !important;
}

.tw-accent-gray-200\/5 {
  accent-color: rgb(229 231 235 / 0.05) !important;
}

.tw-accent-gray-200\/50 {
  accent-color: rgb(229 231 235 / 0.5) !important;
}

.tw-accent-gray-200\/55 {
  accent-color: rgb(229 231 235 / 0.55) !important;
}

.tw-accent-gray-200\/60 {
  accent-color: rgb(229 231 235 / 0.6) !important;
}

.tw-accent-gray-200\/65 {
  accent-color: rgb(229 231 235 / 0.65) !important;
}

.tw-accent-gray-200\/70 {
  accent-color: rgb(229 231 235 / 0.7) !important;
}

.tw-accent-gray-200\/75 {
  accent-color: rgb(229 231 235 / 0.75) !important;
}

.tw-accent-gray-200\/80 {
  accent-color: rgb(229 231 235 / 0.8) !important;
}

.tw-accent-gray-200\/85 {
  accent-color: rgb(229 231 235 / 0.85) !important;
}

.tw-accent-gray-200\/90 {
  accent-color: rgb(229 231 235 / 0.9) !important;
}

.tw-accent-gray-200\/95 {
  accent-color: rgb(229 231 235 / 0.95) !important;
}

.tw-accent-gray-300 {
  accent-color: #d1d5db !important;
}

.tw-accent-gray-300\/0 {
  accent-color: rgb(209 213 219 / 0) !important;
}

.tw-accent-gray-300\/10 {
  accent-color: rgb(209 213 219 / 0.1) !important;
}

.tw-accent-gray-300\/100 {
  accent-color: rgb(209 213 219 / 1) !important;
}

.tw-accent-gray-300\/15 {
  accent-color: rgb(209 213 219 / 0.15) !important;
}

.tw-accent-gray-300\/20 {
  accent-color: rgb(209 213 219 / 0.2) !important;
}

.tw-accent-gray-300\/25 {
  accent-color: rgb(209 213 219 / 0.25) !important;
}

.tw-accent-gray-300\/30 {
  accent-color: rgb(209 213 219 / 0.3) !important;
}

.tw-accent-gray-300\/35 {
  accent-color: rgb(209 213 219 / 0.35) !important;
}

.tw-accent-gray-300\/40 {
  accent-color: rgb(209 213 219 / 0.4) !important;
}

.tw-accent-gray-300\/45 {
  accent-color: rgb(209 213 219 / 0.45) !important;
}

.tw-accent-gray-300\/5 {
  accent-color: rgb(209 213 219 / 0.05) !important;
}

.tw-accent-gray-300\/50 {
  accent-color: rgb(209 213 219 / 0.5) !important;
}

.tw-accent-gray-300\/55 {
  accent-color: rgb(209 213 219 / 0.55) !important;
}

.tw-accent-gray-300\/60 {
  accent-color: rgb(209 213 219 / 0.6) !important;
}

.tw-accent-gray-300\/65 {
  accent-color: rgb(209 213 219 / 0.65) !important;
}

.tw-accent-gray-300\/70 {
  accent-color: rgb(209 213 219 / 0.7) !important;
}

.tw-accent-gray-300\/75 {
  accent-color: rgb(209 213 219 / 0.75) !important;
}

.tw-accent-gray-300\/80 {
  accent-color: rgb(209 213 219 / 0.8) !important;
}

.tw-accent-gray-300\/85 {
  accent-color: rgb(209 213 219 / 0.85) !important;
}

.tw-accent-gray-300\/90 {
  accent-color: rgb(209 213 219 / 0.9) !important;
}

.tw-accent-gray-300\/95 {
  accent-color: rgb(209 213 219 / 0.95) !important;
}

.tw-accent-gray-400 {
  accent-color: #9ca3af !important;
}

.tw-accent-gray-400\/0 {
  accent-color: rgb(156 163 175 / 0) !important;
}

.tw-accent-gray-400\/10 {
  accent-color: rgb(156 163 175 / 0.1) !important;
}

.tw-accent-gray-400\/100 {
  accent-color: rgb(156 163 175 / 1) !important;
}

.tw-accent-gray-400\/15 {
  accent-color: rgb(156 163 175 / 0.15) !important;
}

.tw-accent-gray-400\/20 {
  accent-color: rgb(156 163 175 / 0.2) !important;
}

.tw-accent-gray-400\/25 {
  accent-color: rgb(156 163 175 / 0.25) !important;
}

.tw-accent-gray-400\/30 {
  accent-color: rgb(156 163 175 / 0.3) !important;
}

.tw-accent-gray-400\/35 {
  accent-color: rgb(156 163 175 / 0.35) !important;
}

.tw-accent-gray-400\/40 {
  accent-color: rgb(156 163 175 / 0.4) !important;
}

.tw-accent-gray-400\/45 {
  accent-color: rgb(156 163 175 / 0.45) !important;
}

.tw-accent-gray-400\/5 {
  accent-color: rgb(156 163 175 / 0.05) !important;
}

.tw-accent-gray-400\/50 {
  accent-color: rgb(156 163 175 / 0.5) !important;
}

.tw-accent-gray-400\/55 {
  accent-color: rgb(156 163 175 / 0.55) !important;
}

.tw-accent-gray-400\/60 {
  accent-color: rgb(156 163 175 / 0.6) !important;
}

.tw-accent-gray-400\/65 {
  accent-color: rgb(156 163 175 / 0.65) !important;
}

.tw-accent-gray-400\/70 {
  accent-color: rgb(156 163 175 / 0.7) !important;
}

.tw-accent-gray-400\/75 {
  accent-color: rgb(156 163 175 / 0.75) !important;
}

.tw-accent-gray-400\/80 {
  accent-color: rgb(156 163 175 / 0.8) !important;
}

.tw-accent-gray-400\/85 {
  accent-color: rgb(156 163 175 / 0.85) !important;
}

.tw-accent-gray-400\/90 {
  accent-color: rgb(156 163 175 / 0.9) !important;
}

.tw-accent-gray-400\/95 {
  accent-color: rgb(156 163 175 / 0.95) !important;
}

.tw-accent-gray-50 {
  accent-color: #f9fafb !important;
}

.tw-accent-gray-50\/0 {
  accent-color: rgb(249 250 251 / 0) !important;
}

.tw-accent-gray-50\/10 {
  accent-color: rgb(249 250 251 / 0.1) !important;
}

.tw-accent-gray-50\/100 {
  accent-color: rgb(249 250 251 / 1) !important;
}

.tw-accent-gray-50\/15 {
  accent-color: rgb(249 250 251 / 0.15) !important;
}

.tw-accent-gray-50\/20 {
  accent-color: rgb(249 250 251 / 0.2) !important;
}

.tw-accent-gray-50\/25 {
  accent-color: rgb(249 250 251 / 0.25) !important;
}

.tw-accent-gray-50\/30 {
  accent-color: rgb(249 250 251 / 0.3) !important;
}

.tw-accent-gray-50\/35 {
  accent-color: rgb(249 250 251 / 0.35) !important;
}

.tw-accent-gray-50\/40 {
  accent-color: rgb(249 250 251 / 0.4) !important;
}

.tw-accent-gray-50\/45 {
  accent-color: rgb(249 250 251 / 0.45) !important;
}

.tw-accent-gray-50\/5 {
  accent-color: rgb(249 250 251 / 0.05) !important;
}

.tw-accent-gray-50\/50 {
  accent-color: rgb(249 250 251 / 0.5) !important;
}

.tw-accent-gray-50\/55 {
  accent-color: rgb(249 250 251 / 0.55) !important;
}

.tw-accent-gray-50\/60 {
  accent-color: rgb(249 250 251 / 0.6) !important;
}

.tw-accent-gray-50\/65 {
  accent-color: rgb(249 250 251 / 0.65) !important;
}

.tw-accent-gray-50\/70 {
  accent-color: rgb(249 250 251 / 0.7) !important;
}

.tw-accent-gray-50\/75 {
  accent-color: rgb(249 250 251 / 0.75) !important;
}

.tw-accent-gray-50\/80 {
  accent-color: rgb(249 250 251 / 0.8) !important;
}

.tw-accent-gray-50\/85 {
  accent-color: rgb(249 250 251 / 0.85) !important;
}

.tw-accent-gray-50\/90 {
  accent-color: rgb(249 250 251 / 0.9) !important;
}

.tw-accent-gray-50\/95 {
  accent-color: rgb(249 250 251 / 0.95) !important;
}

.tw-accent-gray-500 {
  accent-color: #6b7280 !important;
}

.tw-accent-gray-500\/0 {
  accent-color: rgb(107 114 128 / 0) !important;
}

.tw-accent-gray-500\/10 {
  accent-color: rgb(107 114 128 / 0.1) !important;
}

.tw-accent-gray-500\/100 {
  accent-color: rgb(107 114 128 / 1) !important;
}

.tw-accent-gray-500\/15 {
  accent-color: rgb(107 114 128 / 0.15) !important;
}

.tw-accent-gray-500\/20 {
  accent-color: rgb(107 114 128 / 0.2) !important;
}

.tw-accent-gray-500\/25 {
  accent-color: rgb(107 114 128 / 0.25) !important;
}

.tw-accent-gray-500\/30 {
  accent-color: rgb(107 114 128 / 0.3) !important;
}

.tw-accent-gray-500\/35 {
  accent-color: rgb(107 114 128 / 0.35) !important;
}

.tw-accent-gray-500\/40 {
  accent-color: rgb(107 114 128 / 0.4) !important;
}

.tw-accent-gray-500\/45 {
  accent-color: rgb(107 114 128 / 0.45) !important;
}

.tw-accent-gray-500\/5 {
  accent-color: rgb(107 114 128 / 0.05) !important;
}

.tw-accent-gray-500\/50 {
  accent-color: rgb(107 114 128 / 0.5) !important;
}

.tw-accent-gray-500\/55 {
  accent-color: rgb(107 114 128 / 0.55) !important;
}

.tw-accent-gray-500\/60 {
  accent-color: rgb(107 114 128 / 0.6) !important;
}

.tw-accent-gray-500\/65 {
  accent-color: rgb(107 114 128 / 0.65) !important;
}

.tw-accent-gray-500\/70 {
  accent-color: rgb(107 114 128 / 0.7) !important;
}

.tw-accent-gray-500\/75 {
  accent-color: rgb(107 114 128 / 0.75) !important;
}

.tw-accent-gray-500\/80 {
  accent-color: rgb(107 114 128 / 0.8) !important;
}

.tw-accent-gray-500\/85 {
  accent-color: rgb(107 114 128 / 0.85) !important;
}

.tw-accent-gray-500\/90 {
  accent-color: rgb(107 114 128 / 0.9) !important;
}

.tw-accent-gray-500\/95 {
  accent-color: rgb(107 114 128 / 0.95) !important;
}

.tw-accent-gray-600 {
  accent-color: #4b5563 !important;
}

.tw-accent-gray-600\/0 {
  accent-color: rgb(75 85 99 / 0) !important;
}

.tw-accent-gray-600\/10 {
  accent-color: rgb(75 85 99 / 0.1) !important;
}

.tw-accent-gray-600\/100 {
  accent-color: rgb(75 85 99 / 1) !important;
}

.tw-accent-gray-600\/15 {
  accent-color: rgb(75 85 99 / 0.15) !important;
}

.tw-accent-gray-600\/20 {
  accent-color: rgb(75 85 99 / 0.2) !important;
}

.tw-accent-gray-600\/25 {
  accent-color: rgb(75 85 99 / 0.25) !important;
}

.tw-accent-gray-600\/30 {
  accent-color: rgb(75 85 99 / 0.3) !important;
}

.tw-accent-gray-600\/35 {
  accent-color: rgb(75 85 99 / 0.35) !important;
}

.tw-accent-gray-600\/40 {
  accent-color: rgb(75 85 99 / 0.4) !important;
}

.tw-accent-gray-600\/45 {
  accent-color: rgb(75 85 99 / 0.45) !important;
}

.tw-accent-gray-600\/5 {
  accent-color: rgb(75 85 99 / 0.05) !important;
}

.tw-accent-gray-600\/50 {
  accent-color: rgb(75 85 99 / 0.5) !important;
}

.tw-accent-gray-600\/55 {
  accent-color: rgb(75 85 99 / 0.55) !important;
}

.tw-accent-gray-600\/60 {
  accent-color: rgb(75 85 99 / 0.6) !important;
}

.tw-accent-gray-600\/65 {
  accent-color: rgb(75 85 99 / 0.65) !important;
}

.tw-accent-gray-600\/70 {
  accent-color: rgb(75 85 99 / 0.7) !important;
}

.tw-accent-gray-600\/75 {
  accent-color: rgb(75 85 99 / 0.75) !important;
}

.tw-accent-gray-600\/80 {
  accent-color: rgb(75 85 99 / 0.8) !important;
}

.tw-accent-gray-600\/85 {
  accent-color: rgb(75 85 99 / 0.85) !important;
}

.tw-accent-gray-600\/90 {
  accent-color: rgb(75 85 99 / 0.9) !important;
}

.tw-accent-gray-600\/95 {
  accent-color: rgb(75 85 99 / 0.95) !important;
}

.tw-accent-gray-700 {
  accent-color: #374151 !important;
}

.tw-accent-gray-700\/0 {
  accent-color: rgb(55 65 81 / 0) !important;
}

.tw-accent-gray-700\/10 {
  accent-color: rgb(55 65 81 / 0.1) !important;
}

.tw-accent-gray-700\/100 {
  accent-color: rgb(55 65 81 / 1) !important;
}

.tw-accent-gray-700\/15 {
  accent-color: rgb(55 65 81 / 0.15) !important;
}

.tw-accent-gray-700\/20 {
  accent-color: rgb(55 65 81 / 0.2) !important;
}

.tw-accent-gray-700\/25 {
  accent-color: rgb(55 65 81 / 0.25) !important;
}

.tw-accent-gray-700\/30 {
  accent-color: rgb(55 65 81 / 0.3) !important;
}

.tw-accent-gray-700\/35 {
  accent-color: rgb(55 65 81 / 0.35) !important;
}

.tw-accent-gray-700\/40 {
  accent-color: rgb(55 65 81 / 0.4) !important;
}

.tw-accent-gray-700\/45 {
  accent-color: rgb(55 65 81 / 0.45) !important;
}

.tw-accent-gray-700\/5 {
  accent-color: rgb(55 65 81 / 0.05) !important;
}

.tw-accent-gray-700\/50 {
  accent-color: rgb(55 65 81 / 0.5) !important;
}

.tw-accent-gray-700\/55 {
  accent-color: rgb(55 65 81 / 0.55) !important;
}

.tw-accent-gray-700\/60 {
  accent-color: rgb(55 65 81 / 0.6) !important;
}

.tw-accent-gray-700\/65 {
  accent-color: rgb(55 65 81 / 0.65) !important;
}

.tw-accent-gray-700\/70 {
  accent-color: rgb(55 65 81 / 0.7) !important;
}

.tw-accent-gray-700\/75 {
  accent-color: rgb(55 65 81 / 0.75) !important;
}

.tw-accent-gray-700\/80 {
  accent-color: rgb(55 65 81 / 0.8) !important;
}

.tw-accent-gray-700\/85 {
  accent-color: rgb(55 65 81 / 0.85) !important;
}

.tw-accent-gray-700\/90 {
  accent-color: rgb(55 65 81 / 0.9) !important;
}

.tw-accent-gray-700\/95 {
  accent-color: rgb(55 65 81 / 0.95) !important;
}

.tw-accent-gray-800 {
  accent-color: #1f2937 !important;
}

.tw-accent-gray-800\/0 {
  accent-color: rgb(31 41 55 / 0) !important;
}

.tw-accent-gray-800\/10 {
  accent-color: rgb(31 41 55 / 0.1) !important;
}

.tw-accent-gray-800\/100 {
  accent-color: rgb(31 41 55 / 1) !important;
}

.tw-accent-gray-800\/15 {
  accent-color: rgb(31 41 55 / 0.15) !important;
}

.tw-accent-gray-800\/20 {
  accent-color: rgb(31 41 55 / 0.2) !important;
}

.tw-accent-gray-800\/25 {
  accent-color: rgb(31 41 55 / 0.25) !important;
}

.tw-accent-gray-800\/30 {
  accent-color: rgb(31 41 55 / 0.3) !important;
}

.tw-accent-gray-800\/35 {
  accent-color: rgb(31 41 55 / 0.35) !important;
}

.tw-accent-gray-800\/40 {
  accent-color: rgb(31 41 55 / 0.4) !important;
}

.tw-accent-gray-800\/45 {
  accent-color: rgb(31 41 55 / 0.45) !important;
}

.tw-accent-gray-800\/5 {
  accent-color: rgb(31 41 55 / 0.05) !important;
}

.tw-accent-gray-800\/50 {
  accent-color: rgb(31 41 55 / 0.5) !important;
}

.tw-accent-gray-800\/55 {
  accent-color: rgb(31 41 55 / 0.55) !important;
}

.tw-accent-gray-800\/60 {
  accent-color: rgb(31 41 55 / 0.6) !important;
}

.tw-accent-gray-800\/65 {
  accent-color: rgb(31 41 55 / 0.65) !important;
}

.tw-accent-gray-800\/70 {
  accent-color: rgb(31 41 55 / 0.7) !important;
}

.tw-accent-gray-800\/75 {
  accent-color: rgb(31 41 55 / 0.75) !important;
}

.tw-accent-gray-800\/80 {
  accent-color: rgb(31 41 55 / 0.8) !important;
}

.tw-accent-gray-800\/85 {
  accent-color: rgb(31 41 55 / 0.85) !important;
}

.tw-accent-gray-800\/90 {
  accent-color: rgb(31 41 55 / 0.9) !important;
}

.tw-accent-gray-800\/95 {
  accent-color: rgb(31 41 55 / 0.95) !important;
}

.tw-accent-gray-900 {
  accent-color: #111827 !important;
}

.tw-accent-gray-900\/0 {
  accent-color: rgb(17 24 39 / 0) !important;
}

.tw-accent-gray-900\/10 {
  accent-color: rgb(17 24 39 / 0.1) !important;
}

.tw-accent-gray-900\/100 {
  accent-color: rgb(17 24 39 / 1) !important;
}

.tw-accent-gray-900\/15 {
  accent-color: rgb(17 24 39 / 0.15) !important;
}

.tw-accent-gray-900\/20 {
  accent-color: rgb(17 24 39 / 0.2) !important;
}

.tw-accent-gray-900\/25 {
  accent-color: rgb(17 24 39 / 0.25) !important;
}

.tw-accent-gray-900\/30 {
  accent-color: rgb(17 24 39 / 0.3) !important;
}

.tw-accent-gray-900\/35 {
  accent-color: rgb(17 24 39 / 0.35) !important;
}

.tw-accent-gray-900\/40 {
  accent-color: rgb(17 24 39 / 0.4) !important;
}

.tw-accent-gray-900\/45 {
  accent-color: rgb(17 24 39 / 0.45) !important;
}

.tw-accent-gray-900\/5 {
  accent-color: rgb(17 24 39 / 0.05) !important;
}

.tw-accent-gray-900\/50 {
  accent-color: rgb(17 24 39 / 0.5) !important;
}

.tw-accent-gray-900\/55 {
  accent-color: rgb(17 24 39 / 0.55) !important;
}

.tw-accent-gray-900\/60 {
  accent-color: rgb(17 24 39 / 0.6) !important;
}

.tw-accent-gray-900\/65 {
  accent-color: rgb(17 24 39 / 0.65) !important;
}

.tw-accent-gray-900\/70 {
  accent-color: rgb(17 24 39 / 0.7) !important;
}

.tw-accent-gray-900\/75 {
  accent-color: rgb(17 24 39 / 0.75) !important;
}

.tw-accent-gray-900\/80 {
  accent-color: rgb(17 24 39 / 0.8) !important;
}

.tw-accent-gray-900\/85 {
  accent-color: rgb(17 24 39 / 0.85) !important;
}

.tw-accent-gray-900\/90 {
  accent-color: rgb(17 24 39 / 0.9) !important;
}

.tw-accent-gray-900\/95 {
  accent-color: rgb(17 24 39 / 0.95) !important;
}

.tw-accent-gray-950 {
  accent-color: #030712 !important;
}

.tw-accent-gray-950\/0 {
  accent-color: rgb(3 7 18 / 0) !important;
}

.tw-accent-gray-950\/10 {
  accent-color: rgb(3 7 18 / 0.1) !important;
}

.tw-accent-gray-950\/100 {
  accent-color: rgb(3 7 18 / 1) !important;
}

.tw-accent-gray-950\/15 {
  accent-color: rgb(3 7 18 / 0.15) !important;
}

.tw-accent-gray-950\/20 {
  accent-color: rgb(3 7 18 / 0.2) !important;
}

.tw-accent-gray-950\/25 {
  accent-color: rgb(3 7 18 / 0.25) !important;
}

.tw-accent-gray-950\/30 {
  accent-color: rgb(3 7 18 / 0.3) !important;
}

.tw-accent-gray-950\/35 {
  accent-color: rgb(3 7 18 / 0.35) !important;
}

.tw-accent-gray-950\/40 {
  accent-color: rgb(3 7 18 / 0.4) !important;
}

.tw-accent-gray-950\/45 {
  accent-color: rgb(3 7 18 / 0.45) !important;
}

.tw-accent-gray-950\/5 {
  accent-color: rgb(3 7 18 / 0.05) !important;
}

.tw-accent-gray-950\/50 {
  accent-color: rgb(3 7 18 / 0.5) !important;
}

.tw-accent-gray-950\/55 {
  accent-color: rgb(3 7 18 / 0.55) !important;
}

.tw-accent-gray-950\/60 {
  accent-color: rgb(3 7 18 / 0.6) !important;
}

.tw-accent-gray-950\/65 {
  accent-color: rgb(3 7 18 / 0.65) !important;
}

.tw-accent-gray-950\/70 {
  accent-color: rgb(3 7 18 / 0.7) !important;
}

.tw-accent-gray-950\/75 {
  accent-color: rgb(3 7 18 / 0.75) !important;
}

.tw-accent-gray-950\/80 {
  accent-color: rgb(3 7 18 / 0.8) !important;
}

.tw-accent-gray-950\/85 {
  accent-color: rgb(3 7 18 / 0.85) !important;
}

.tw-accent-gray-950\/90 {
  accent-color: rgb(3 7 18 / 0.9) !important;
}

.tw-accent-gray-950\/95 {
  accent-color: rgb(3 7 18 / 0.95) !important;
}

.tw-accent-green-100 {
  accent-color: #dcfce7 !important;
}

.tw-accent-green-100\/0 {
  accent-color: rgb(220 252 231 / 0) !important;
}

.tw-accent-green-100\/10 {
  accent-color: rgb(220 252 231 / 0.1) !important;
}

.tw-accent-green-100\/100 {
  accent-color: rgb(220 252 231 / 1) !important;
}

.tw-accent-green-100\/15 {
  accent-color: rgb(220 252 231 / 0.15) !important;
}

.tw-accent-green-100\/20 {
  accent-color: rgb(220 252 231 / 0.2) !important;
}

.tw-accent-green-100\/25 {
  accent-color: rgb(220 252 231 / 0.25) !important;
}

.tw-accent-green-100\/30 {
  accent-color: rgb(220 252 231 / 0.3) !important;
}

.tw-accent-green-100\/35 {
  accent-color: rgb(220 252 231 / 0.35) !important;
}

.tw-accent-green-100\/40 {
  accent-color: rgb(220 252 231 / 0.4) !important;
}

.tw-accent-green-100\/45 {
  accent-color: rgb(220 252 231 / 0.45) !important;
}

.tw-accent-green-100\/5 {
  accent-color: rgb(220 252 231 / 0.05) !important;
}

.tw-accent-green-100\/50 {
  accent-color: rgb(220 252 231 / 0.5) !important;
}

.tw-accent-green-100\/55 {
  accent-color: rgb(220 252 231 / 0.55) !important;
}

.tw-accent-green-100\/60 {
  accent-color: rgb(220 252 231 / 0.6) !important;
}

.tw-accent-green-100\/65 {
  accent-color: rgb(220 252 231 / 0.65) !important;
}

.tw-accent-green-100\/70 {
  accent-color: rgb(220 252 231 / 0.7) !important;
}

.tw-accent-green-100\/75 {
  accent-color: rgb(220 252 231 / 0.75) !important;
}

.tw-accent-green-100\/80 {
  accent-color: rgb(220 252 231 / 0.8) !important;
}

.tw-accent-green-100\/85 {
  accent-color: rgb(220 252 231 / 0.85) !important;
}

.tw-accent-green-100\/90 {
  accent-color: rgb(220 252 231 / 0.9) !important;
}

.tw-accent-green-100\/95 {
  accent-color: rgb(220 252 231 / 0.95) !important;
}

.tw-accent-green-200 {
  accent-color: #bbf7d0 !important;
}

.tw-accent-green-200\/0 {
  accent-color: rgb(187 247 208 / 0) !important;
}

.tw-accent-green-200\/10 {
  accent-color: rgb(187 247 208 / 0.1) !important;
}

.tw-accent-green-200\/100 {
  accent-color: rgb(187 247 208 / 1) !important;
}

.tw-accent-green-200\/15 {
  accent-color: rgb(187 247 208 / 0.15) !important;
}

.tw-accent-green-200\/20 {
  accent-color: rgb(187 247 208 / 0.2) !important;
}

.tw-accent-green-200\/25 {
  accent-color: rgb(187 247 208 / 0.25) !important;
}

.tw-accent-green-200\/30 {
  accent-color: rgb(187 247 208 / 0.3) !important;
}

.tw-accent-green-200\/35 {
  accent-color: rgb(187 247 208 / 0.35) !important;
}

.tw-accent-green-200\/40 {
  accent-color: rgb(187 247 208 / 0.4) !important;
}

.tw-accent-green-200\/45 {
  accent-color: rgb(187 247 208 / 0.45) !important;
}

.tw-accent-green-200\/5 {
  accent-color: rgb(187 247 208 / 0.05) !important;
}

.tw-accent-green-200\/50 {
  accent-color: rgb(187 247 208 / 0.5) !important;
}

.tw-accent-green-200\/55 {
  accent-color: rgb(187 247 208 / 0.55) !important;
}

.tw-accent-green-200\/60 {
  accent-color: rgb(187 247 208 / 0.6) !important;
}

.tw-accent-green-200\/65 {
  accent-color: rgb(187 247 208 / 0.65) !important;
}

.tw-accent-green-200\/70 {
  accent-color: rgb(187 247 208 / 0.7) !important;
}

.tw-accent-green-200\/75 {
  accent-color: rgb(187 247 208 / 0.75) !important;
}

.tw-accent-green-200\/80 {
  accent-color: rgb(187 247 208 / 0.8) !important;
}

.tw-accent-green-200\/85 {
  accent-color: rgb(187 247 208 / 0.85) !important;
}

.tw-accent-green-200\/90 {
  accent-color: rgb(187 247 208 / 0.9) !important;
}

.tw-accent-green-200\/95 {
  accent-color: rgb(187 247 208 / 0.95) !important;
}

.tw-accent-green-300 {
  accent-color: #86efac !important;
}

.tw-accent-green-300\/0 {
  accent-color: rgb(134 239 172 / 0) !important;
}

.tw-accent-green-300\/10 {
  accent-color: rgb(134 239 172 / 0.1) !important;
}

.tw-accent-green-300\/100 {
  accent-color: rgb(134 239 172 / 1) !important;
}

.tw-accent-green-300\/15 {
  accent-color: rgb(134 239 172 / 0.15) !important;
}

.tw-accent-green-300\/20 {
  accent-color: rgb(134 239 172 / 0.2) !important;
}

.tw-accent-green-300\/25 {
  accent-color: rgb(134 239 172 / 0.25) !important;
}

.tw-accent-green-300\/30 {
  accent-color: rgb(134 239 172 / 0.3) !important;
}

.tw-accent-green-300\/35 {
  accent-color: rgb(134 239 172 / 0.35) !important;
}

.tw-accent-green-300\/40 {
  accent-color: rgb(134 239 172 / 0.4) !important;
}

.tw-accent-green-300\/45 {
  accent-color: rgb(134 239 172 / 0.45) !important;
}

.tw-accent-green-300\/5 {
  accent-color: rgb(134 239 172 / 0.05) !important;
}

.tw-accent-green-300\/50 {
  accent-color: rgb(134 239 172 / 0.5) !important;
}

.tw-accent-green-300\/55 {
  accent-color: rgb(134 239 172 / 0.55) !important;
}

.tw-accent-green-300\/60 {
  accent-color: rgb(134 239 172 / 0.6) !important;
}

.tw-accent-green-300\/65 {
  accent-color: rgb(134 239 172 / 0.65) !important;
}

.tw-accent-green-300\/70 {
  accent-color: rgb(134 239 172 / 0.7) !important;
}

.tw-accent-green-300\/75 {
  accent-color: rgb(134 239 172 / 0.75) !important;
}

.tw-accent-green-300\/80 {
  accent-color: rgb(134 239 172 / 0.8) !important;
}

.tw-accent-green-300\/85 {
  accent-color: rgb(134 239 172 / 0.85) !important;
}

.tw-accent-green-300\/90 {
  accent-color: rgb(134 239 172 / 0.9) !important;
}

.tw-accent-green-300\/95 {
  accent-color: rgb(134 239 172 / 0.95) !important;
}

.tw-accent-green-400 {
  accent-color: #4ade80 !important;
}

.tw-accent-green-400\/0 {
  accent-color: rgb(74 222 128 / 0) !important;
}

.tw-accent-green-400\/10 {
  accent-color: rgb(74 222 128 / 0.1) !important;
}

.tw-accent-green-400\/100 {
  accent-color: rgb(74 222 128 / 1) !important;
}

.tw-accent-green-400\/15 {
  accent-color: rgb(74 222 128 / 0.15) !important;
}

.tw-accent-green-400\/20 {
  accent-color: rgb(74 222 128 / 0.2) !important;
}

.tw-accent-green-400\/25 {
  accent-color: rgb(74 222 128 / 0.25) !important;
}

.tw-accent-green-400\/30 {
  accent-color: rgb(74 222 128 / 0.3) !important;
}

.tw-accent-green-400\/35 {
  accent-color: rgb(74 222 128 / 0.35) !important;
}

.tw-accent-green-400\/40 {
  accent-color: rgb(74 222 128 / 0.4) !important;
}

.tw-accent-green-400\/45 {
  accent-color: rgb(74 222 128 / 0.45) !important;
}

.tw-accent-green-400\/5 {
  accent-color: rgb(74 222 128 / 0.05) !important;
}

.tw-accent-green-400\/50 {
  accent-color: rgb(74 222 128 / 0.5) !important;
}

.tw-accent-green-400\/55 {
  accent-color: rgb(74 222 128 / 0.55) !important;
}

.tw-accent-green-400\/60 {
  accent-color: rgb(74 222 128 / 0.6) !important;
}

.tw-accent-green-400\/65 {
  accent-color: rgb(74 222 128 / 0.65) !important;
}

.tw-accent-green-400\/70 {
  accent-color: rgb(74 222 128 / 0.7) !important;
}

.tw-accent-green-400\/75 {
  accent-color: rgb(74 222 128 / 0.75) !important;
}

.tw-accent-green-400\/80 {
  accent-color: rgb(74 222 128 / 0.8) !important;
}

.tw-accent-green-400\/85 {
  accent-color: rgb(74 222 128 / 0.85) !important;
}

.tw-accent-green-400\/90 {
  accent-color: rgb(74 222 128 / 0.9) !important;
}

.tw-accent-green-400\/95 {
  accent-color: rgb(74 222 128 / 0.95) !important;
}

.tw-accent-green-50 {
  accent-color: #f0fdf4 !important;
}

.tw-accent-green-50\/0 {
  accent-color: rgb(240 253 244 / 0) !important;
}

.tw-accent-green-50\/10 {
  accent-color: rgb(240 253 244 / 0.1) !important;
}

.tw-accent-green-50\/100 {
  accent-color: rgb(240 253 244 / 1) !important;
}

.tw-accent-green-50\/15 {
  accent-color: rgb(240 253 244 / 0.15) !important;
}

.tw-accent-green-50\/20 {
  accent-color: rgb(240 253 244 / 0.2) !important;
}

.tw-accent-green-50\/25 {
  accent-color: rgb(240 253 244 / 0.25) !important;
}

.tw-accent-green-50\/30 {
  accent-color: rgb(240 253 244 / 0.3) !important;
}

.tw-accent-green-50\/35 {
  accent-color: rgb(240 253 244 / 0.35) !important;
}

.tw-accent-green-50\/40 {
  accent-color: rgb(240 253 244 / 0.4) !important;
}

.tw-accent-green-50\/45 {
  accent-color: rgb(240 253 244 / 0.45) !important;
}

.tw-accent-green-50\/5 {
  accent-color: rgb(240 253 244 / 0.05) !important;
}

.tw-accent-green-50\/50 {
  accent-color: rgb(240 253 244 / 0.5) !important;
}

.tw-accent-green-50\/55 {
  accent-color: rgb(240 253 244 / 0.55) !important;
}

.tw-accent-green-50\/60 {
  accent-color: rgb(240 253 244 / 0.6) !important;
}

.tw-accent-green-50\/65 {
  accent-color: rgb(240 253 244 / 0.65) !important;
}

.tw-accent-green-50\/70 {
  accent-color: rgb(240 253 244 / 0.7) !important;
}

.tw-accent-green-50\/75 {
  accent-color: rgb(240 253 244 / 0.75) !important;
}

.tw-accent-green-50\/80 {
  accent-color: rgb(240 253 244 / 0.8) !important;
}

.tw-accent-green-50\/85 {
  accent-color: rgb(240 253 244 / 0.85) !important;
}

.tw-accent-green-50\/90 {
  accent-color: rgb(240 253 244 / 0.9) !important;
}

.tw-accent-green-50\/95 {
  accent-color: rgb(240 253 244 / 0.95) !important;
}

.tw-accent-green-500 {
  accent-color: #22c55e !important;
}

.tw-accent-green-500\/0 {
  accent-color: rgb(34 197 94 / 0) !important;
}

.tw-accent-green-500\/10 {
  accent-color: rgb(34 197 94 / 0.1) !important;
}

.tw-accent-green-500\/100 {
  accent-color: rgb(34 197 94 / 1) !important;
}

.tw-accent-green-500\/15 {
  accent-color: rgb(34 197 94 / 0.15) !important;
}

.tw-accent-green-500\/20 {
  accent-color: rgb(34 197 94 / 0.2) !important;
}

.tw-accent-green-500\/25 {
  accent-color: rgb(34 197 94 / 0.25) !important;
}

.tw-accent-green-500\/30 {
  accent-color: rgb(34 197 94 / 0.3) !important;
}

.tw-accent-green-500\/35 {
  accent-color: rgb(34 197 94 / 0.35) !important;
}

.tw-accent-green-500\/40 {
  accent-color: rgb(34 197 94 / 0.4) !important;
}

.tw-accent-green-500\/45 {
  accent-color: rgb(34 197 94 / 0.45) !important;
}

.tw-accent-green-500\/5 {
  accent-color: rgb(34 197 94 / 0.05) !important;
}

.tw-accent-green-500\/50 {
  accent-color: rgb(34 197 94 / 0.5) !important;
}

.tw-accent-green-500\/55 {
  accent-color: rgb(34 197 94 / 0.55) !important;
}

.tw-accent-green-500\/60 {
  accent-color: rgb(34 197 94 / 0.6) !important;
}

.tw-accent-green-500\/65 {
  accent-color: rgb(34 197 94 / 0.65) !important;
}

.tw-accent-green-500\/70 {
  accent-color: rgb(34 197 94 / 0.7) !important;
}

.tw-accent-green-500\/75 {
  accent-color: rgb(34 197 94 / 0.75) !important;
}

.tw-accent-green-500\/80 {
  accent-color: rgb(34 197 94 / 0.8) !important;
}

.tw-accent-green-500\/85 {
  accent-color: rgb(34 197 94 / 0.85) !important;
}

.tw-accent-green-500\/90 {
  accent-color: rgb(34 197 94 / 0.9) !important;
}

.tw-accent-green-500\/95 {
  accent-color: rgb(34 197 94 / 0.95) !important;
}

.tw-accent-green-600 {
  accent-color: #16a34a !important;
}

.tw-accent-green-600\/0 {
  accent-color: rgb(22 163 74 / 0) !important;
}

.tw-accent-green-600\/10 {
  accent-color: rgb(22 163 74 / 0.1) !important;
}

.tw-accent-green-600\/100 {
  accent-color: rgb(22 163 74 / 1) !important;
}

.tw-accent-green-600\/15 {
  accent-color: rgb(22 163 74 / 0.15) !important;
}

.tw-accent-green-600\/20 {
  accent-color: rgb(22 163 74 / 0.2) !important;
}

.tw-accent-green-600\/25 {
  accent-color: rgb(22 163 74 / 0.25) !important;
}

.tw-accent-green-600\/30 {
  accent-color: rgb(22 163 74 / 0.3) !important;
}

.tw-accent-green-600\/35 {
  accent-color: rgb(22 163 74 / 0.35) !important;
}

.tw-accent-green-600\/40 {
  accent-color: rgb(22 163 74 / 0.4) !important;
}

.tw-accent-green-600\/45 {
  accent-color: rgb(22 163 74 / 0.45) !important;
}

.tw-accent-green-600\/5 {
  accent-color: rgb(22 163 74 / 0.05) !important;
}

.tw-accent-green-600\/50 {
  accent-color: rgb(22 163 74 / 0.5) !important;
}

.tw-accent-green-600\/55 {
  accent-color: rgb(22 163 74 / 0.55) !important;
}

.tw-accent-green-600\/60 {
  accent-color: rgb(22 163 74 / 0.6) !important;
}

.tw-accent-green-600\/65 {
  accent-color: rgb(22 163 74 / 0.65) !important;
}

.tw-accent-green-600\/70 {
  accent-color: rgb(22 163 74 / 0.7) !important;
}

.tw-accent-green-600\/75 {
  accent-color: rgb(22 163 74 / 0.75) !important;
}

.tw-accent-green-600\/80 {
  accent-color: rgb(22 163 74 / 0.8) !important;
}

.tw-accent-green-600\/85 {
  accent-color: rgb(22 163 74 / 0.85) !important;
}

.tw-accent-green-600\/90 {
  accent-color: rgb(22 163 74 / 0.9) !important;
}

.tw-accent-green-600\/95 {
  accent-color: rgb(22 163 74 / 0.95) !important;
}

.tw-accent-green-700 {
  accent-color: #15803d !important;
}

.tw-accent-green-700\/0 {
  accent-color: rgb(21 128 61 / 0) !important;
}

.tw-accent-green-700\/10 {
  accent-color: rgb(21 128 61 / 0.1) !important;
}

.tw-accent-green-700\/100 {
  accent-color: rgb(21 128 61 / 1) !important;
}

.tw-accent-green-700\/15 {
  accent-color: rgb(21 128 61 / 0.15) !important;
}

.tw-accent-green-700\/20 {
  accent-color: rgb(21 128 61 / 0.2) !important;
}

.tw-accent-green-700\/25 {
  accent-color: rgb(21 128 61 / 0.25) !important;
}

.tw-accent-green-700\/30 {
  accent-color: rgb(21 128 61 / 0.3) !important;
}

.tw-accent-green-700\/35 {
  accent-color: rgb(21 128 61 / 0.35) !important;
}

.tw-accent-green-700\/40 {
  accent-color: rgb(21 128 61 / 0.4) !important;
}

.tw-accent-green-700\/45 {
  accent-color: rgb(21 128 61 / 0.45) !important;
}

.tw-accent-green-700\/5 {
  accent-color: rgb(21 128 61 / 0.05) !important;
}

.tw-accent-green-700\/50 {
  accent-color: rgb(21 128 61 / 0.5) !important;
}

.tw-accent-green-700\/55 {
  accent-color: rgb(21 128 61 / 0.55) !important;
}

.tw-accent-green-700\/60 {
  accent-color: rgb(21 128 61 / 0.6) !important;
}

.tw-accent-green-700\/65 {
  accent-color: rgb(21 128 61 / 0.65) !important;
}

.tw-accent-green-700\/70 {
  accent-color: rgb(21 128 61 / 0.7) !important;
}

.tw-accent-green-700\/75 {
  accent-color: rgb(21 128 61 / 0.75) !important;
}

.tw-accent-green-700\/80 {
  accent-color: rgb(21 128 61 / 0.8) !important;
}

.tw-accent-green-700\/85 {
  accent-color: rgb(21 128 61 / 0.85) !important;
}

.tw-accent-green-700\/90 {
  accent-color: rgb(21 128 61 / 0.9) !important;
}

.tw-accent-green-700\/95 {
  accent-color: rgb(21 128 61 / 0.95) !important;
}

.tw-accent-green-800 {
  accent-color: #166534 !important;
}

.tw-accent-green-800\/0 {
  accent-color: rgb(22 101 52 / 0) !important;
}

.tw-accent-green-800\/10 {
  accent-color: rgb(22 101 52 / 0.1) !important;
}

.tw-accent-green-800\/100 {
  accent-color: rgb(22 101 52 / 1) !important;
}

.tw-accent-green-800\/15 {
  accent-color: rgb(22 101 52 / 0.15) !important;
}

.tw-accent-green-800\/20 {
  accent-color: rgb(22 101 52 / 0.2) !important;
}

.tw-accent-green-800\/25 {
  accent-color: rgb(22 101 52 / 0.25) !important;
}

.tw-accent-green-800\/30 {
  accent-color: rgb(22 101 52 / 0.3) !important;
}

.tw-accent-green-800\/35 {
  accent-color: rgb(22 101 52 / 0.35) !important;
}

.tw-accent-green-800\/40 {
  accent-color: rgb(22 101 52 / 0.4) !important;
}

.tw-accent-green-800\/45 {
  accent-color: rgb(22 101 52 / 0.45) !important;
}

.tw-accent-green-800\/5 {
  accent-color: rgb(22 101 52 / 0.05) !important;
}

.tw-accent-green-800\/50 {
  accent-color: rgb(22 101 52 / 0.5) !important;
}

.tw-accent-green-800\/55 {
  accent-color: rgb(22 101 52 / 0.55) !important;
}

.tw-accent-green-800\/60 {
  accent-color: rgb(22 101 52 / 0.6) !important;
}

.tw-accent-green-800\/65 {
  accent-color: rgb(22 101 52 / 0.65) !important;
}

.tw-accent-green-800\/70 {
  accent-color: rgb(22 101 52 / 0.7) !important;
}

.tw-accent-green-800\/75 {
  accent-color: rgb(22 101 52 / 0.75) !important;
}

.tw-accent-green-800\/80 {
  accent-color: rgb(22 101 52 / 0.8) !important;
}

.tw-accent-green-800\/85 {
  accent-color: rgb(22 101 52 / 0.85) !important;
}

.tw-accent-green-800\/90 {
  accent-color: rgb(22 101 52 / 0.9) !important;
}

.tw-accent-green-800\/95 {
  accent-color: rgb(22 101 52 / 0.95) !important;
}

.tw-accent-green-900 {
  accent-color: #14532d !important;
}

.tw-accent-green-900\/0 {
  accent-color: rgb(20 83 45 / 0) !important;
}

.tw-accent-green-900\/10 {
  accent-color: rgb(20 83 45 / 0.1) !important;
}

.tw-accent-green-900\/100 {
  accent-color: rgb(20 83 45 / 1) !important;
}

.tw-accent-green-900\/15 {
  accent-color: rgb(20 83 45 / 0.15) !important;
}

.tw-accent-green-900\/20 {
  accent-color: rgb(20 83 45 / 0.2) !important;
}

.tw-accent-green-900\/25 {
  accent-color: rgb(20 83 45 / 0.25) !important;
}

.tw-accent-green-900\/30 {
  accent-color: rgb(20 83 45 / 0.3) !important;
}

.tw-accent-green-900\/35 {
  accent-color: rgb(20 83 45 / 0.35) !important;
}

.tw-accent-green-900\/40 {
  accent-color: rgb(20 83 45 / 0.4) !important;
}

.tw-accent-green-900\/45 {
  accent-color: rgb(20 83 45 / 0.45) !important;
}

.tw-accent-green-900\/5 {
  accent-color: rgb(20 83 45 / 0.05) !important;
}

.tw-accent-green-900\/50 {
  accent-color: rgb(20 83 45 / 0.5) !important;
}

.tw-accent-green-900\/55 {
  accent-color: rgb(20 83 45 / 0.55) !important;
}

.tw-accent-green-900\/60 {
  accent-color: rgb(20 83 45 / 0.6) !important;
}

.tw-accent-green-900\/65 {
  accent-color: rgb(20 83 45 / 0.65) !important;
}

.tw-accent-green-900\/70 {
  accent-color: rgb(20 83 45 / 0.7) !important;
}

.tw-accent-green-900\/75 {
  accent-color: rgb(20 83 45 / 0.75) !important;
}

.tw-accent-green-900\/80 {
  accent-color: rgb(20 83 45 / 0.8) !important;
}

.tw-accent-green-900\/85 {
  accent-color: rgb(20 83 45 / 0.85) !important;
}

.tw-accent-green-900\/90 {
  accent-color: rgb(20 83 45 / 0.9) !important;
}

.tw-accent-green-900\/95 {
  accent-color: rgb(20 83 45 / 0.95) !important;
}

.tw-accent-green-950 {
  accent-color: #052e16 !important;
}

.tw-accent-green-950\/0 {
  accent-color: rgb(5 46 22 / 0) !important;
}

.tw-accent-green-950\/10 {
  accent-color: rgb(5 46 22 / 0.1) !important;
}

.tw-accent-green-950\/100 {
  accent-color: rgb(5 46 22 / 1) !important;
}

.tw-accent-green-950\/15 {
  accent-color: rgb(5 46 22 / 0.15) !important;
}

.tw-accent-green-950\/20 {
  accent-color: rgb(5 46 22 / 0.2) !important;
}

.tw-accent-green-950\/25 {
  accent-color: rgb(5 46 22 / 0.25) !important;
}

.tw-accent-green-950\/30 {
  accent-color: rgb(5 46 22 / 0.3) !important;
}

.tw-accent-green-950\/35 {
  accent-color: rgb(5 46 22 / 0.35) !important;
}

.tw-accent-green-950\/40 {
  accent-color: rgb(5 46 22 / 0.4) !important;
}

.tw-accent-green-950\/45 {
  accent-color: rgb(5 46 22 / 0.45) !important;
}

.tw-accent-green-950\/5 {
  accent-color: rgb(5 46 22 / 0.05) !important;
}

.tw-accent-green-950\/50 {
  accent-color: rgb(5 46 22 / 0.5) !important;
}

.tw-accent-green-950\/55 {
  accent-color: rgb(5 46 22 / 0.55) !important;
}

.tw-accent-green-950\/60 {
  accent-color: rgb(5 46 22 / 0.6) !important;
}

.tw-accent-green-950\/65 {
  accent-color: rgb(5 46 22 / 0.65) !important;
}

.tw-accent-green-950\/70 {
  accent-color: rgb(5 46 22 / 0.7) !important;
}

.tw-accent-green-950\/75 {
  accent-color: rgb(5 46 22 / 0.75) !important;
}

.tw-accent-green-950\/80 {
  accent-color: rgb(5 46 22 / 0.8) !important;
}

.tw-accent-green-950\/85 {
  accent-color: rgb(5 46 22 / 0.85) !important;
}

.tw-accent-green-950\/90 {
  accent-color: rgb(5 46 22 / 0.9) !important;
}

.tw-accent-green-950\/95 {
  accent-color: rgb(5 46 22 / 0.95) !important;
}

.tw-accent-indigo-100 {
  accent-color: #e0e7ff !important;
}

.tw-accent-indigo-100\/0 {
  accent-color: rgb(224 231 255 / 0) !important;
}

.tw-accent-indigo-100\/10 {
  accent-color: rgb(224 231 255 / 0.1) !important;
}

.tw-accent-indigo-100\/100 {
  accent-color: rgb(224 231 255 / 1) !important;
}

.tw-accent-indigo-100\/15 {
  accent-color: rgb(224 231 255 / 0.15) !important;
}

.tw-accent-indigo-100\/20 {
  accent-color: rgb(224 231 255 / 0.2) !important;
}

.tw-accent-indigo-100\/25 {
  accent-color: rgb(224 231 255 / 0.25) !important;
}

.tw-accent-indigo-100\/30 {
  accent-color: rgb(224 231 255 / 0.3) !important;
}

.tw-accent-indigo-100\/35 {
  accent-color: rgb(224 231 255 / 0.35) !important;
}

.tw-accent-indigo-100\/40 {
  accent-color: rgb(224 231 255 / 0.4) !important;
}

.tw-accent-indigo-100\/45 {
  accent-color: rgb(224 231 255 / 0.45) !important;
}

.tw-accent-indigo-100\/5 {
  accent-color: rgb(224 231 255 / 0.05) !important;
}

.tw-accent-indigo-100\/50 {
  accent-color: rgb(224 231 255 / 0.5) !important;
}

.tw-accent-indigo-100\/55 {
  accent-color: rgb(224 231 255 / 0.55) !important;
}

.tw-accent-indigo-100\/60 {
  accent-color: rgb(224 231 255 / 0.6) !important;
}

.tw-accent-indigo-100\/65 {
  accent-color: rgb(224 231 255 / 0.65) !important;
}

.tw-accent-indigo-100\/70 {
  accent-color: rgb(224 231 255 / 0.7) !important;
}

.tw-accent-indigo-100\/75 {
  accent-color: rgb(224 231 255 / 0.75) !important;
}

.tw-accent-indigo-100\/80 {
  accent-color: rgb(224 231 255 / 0.8) !important;
}

.tw-accent-indigo-100\/85 {
  accent-color: rgb(224 231 255 / 0.85) !important;
}

.tw-accent-indigo-100\/90 {
  accent-color: rgb(224 231 255 / 0.9) !important;
}

.tw-accent-indigo-100\/95 {
  accent-color: rgb(224 231 255 / 0.95) !important;
}

.tw-accent-indigo-200 {
  accent-color: #c7d2fe !important;
}

.tw-accent-indigo-200\/0 {
  accent-color: rgb(199 210 254 / 0) !important;
}

.tw-accent-indigo-200\/10 {
  accent-color: rgb(199 210 254 / 0.1) !important;
}

.tw-accent-indigo-200\/100 {
  accent-color: rgb(199 210 254 / 1) !important;
}

.tw-accent-indigo-200\/15 {
  accent-color: rgb(199 210 254 / 0.15) !important;
}

.tw-accent-indigo-200\/20 {
  accent-color: rgb(199 210 254 / 0.2) !important;
}

.tw-accent-indigo-200\/25 {
  accent-color: rgb(199 210 254 / 0.25) !important;
}

.tw-accent-indigo-200\/30 {
  accent-color: rgb(199 210 254 / 0.3) !important;
}

.tw-accent-indigo-200\/35 {
  accent-color: rgb(199 210 254 / 0.35) !important;
}

.tw-accent-indigo-200\/40 {
  accent-color: rgb(199 210 254 / 0.4) !important;
}

.tw-accent-indigo-200\/45 {
  accent-color: rgb(199 210 254 / 0.45) !important;
}

.tw-accent-indigo-200\/5 {
  accent-color: rgb(199 210 254 / 0.05) !important;
}

.tw-accent-indigo-200\/50 {
  accent-color: rgb(199 210 254 / 0.5) !important;
}

.tw-accent-indigo-200\/55 {
  accent-color: rgb(199 210 254 / 0.55) !important;
}

.tw-accent-indigo-200\/60 {
  accent-color: rgb(199 210 254 / 0.6) !important;
}

.tw-accent-indigo-200\/65 {
  accent-color: rgb(199 210 254 / 0.65) !important;
}

.tw-accent-indigo-200\/70 {
  accent-color: rgb(199 210 254 / 0.7) !important;
}

.tw-accent-indigo-200\/75 {
  accent-color: rgb(199 210 254 / 0.75) !important;
}

.tw-accent-indigo-200\/80 {
  accent-color: rgb(199 210 254 / 0.8) !important;
}

.tw-accent-indigo-200\/85 {
  accent-color: rgb(199 210 254 / 0.85) !important;
}

.tw-accent-indigo-200\/90 {
  accent-color: rgb(199 210 254 / 0.9) !important;
}

.tw-accent-indigo-200\/95 {
  accent-color: rgb(199 210 254 / 0.95) !important;
}

.tw-accent-indigo-300 {
  accent-color: #a5b4fc !important;
}

.tw-accent-indigo-300\/0 {
  accent-color: rgb(165 180 252 / 0) !important;
}

.tw-accent-indigo-300\/10 {
  accent-color: rgb(165 180 252 / 0.1) !important;
}

.tw-accent-indigo-300\/100 {
  accent-color: rgb(165 180 252 / 1) !important;
}

.tw-accent-indigo-300\/15 {
  accent-color: rgb(165 180 252 / 0.15) !important;
}

.tw-accent-indigo-300\/20 {
  accent-color: rgb(165 180 252 / 0.2) !important;
}

.tw-accent-indigo-300\/25 {
  accent-color: rgb(165 180 252 / 0.25) !important;
}

.tw-accent-indigo-300\/30 {
  accent-color: rgb(165 180 252 / 0.3) !important;
}

.tw-accent-indigo-300\/35 {
  accent-color: rgb(165 180 252 / 0.35) !important;
}

.tw-accent-indigo-300\/40 {
  accent-color: rgb(165 180 252 / 0.4) !important;
}

.tw-accent-indigo-300\/45 {
  accent-color: rgb(165 180 252 / 0.45) !important;
}

.tw-accent-indigo-300\/5 {
  accent-color: rgb(165 180 252 / 0.05) !important;
}

.tw-accent-indigo-300\/50 {
  accent-color: rgb(165 180 252 / 0.5) !important;
}

.tw-accent-indigo-300\/55 {
  accent-color: rgb(165 180 252 / 0.55) !important;
}

.tw-accent-indigo-300\/60 {
  accent-color: rgb(165 180 252 / 0.6) !important;
}

.tw-accent-indigo-300\/65 {
  accent-color: rgb(165 180 252 / 0.65) !important;
}

.tw-accent-indigo-300\/70 {
  accent-color: rgb(165 180 252 / 0.7) !important;
}

.tw-accent-indigo-300\/75 {
  accent-color: rgb(165 180 252 / 0.75) !important;
}

.tw-accent-indigo-300\/80 {
  accent-color: rgb(165 180 252 / 0.8) !important;
}

.tw-accent-indigo-300\/85 {
  accent-color: rgb(165 180 252 / 0.85) !important;
}

.tw-accent-indigo-300\/90 {
  accent-color: rgb(165 180 252 / 0.9) !important;
}

.tw-accent-indigo-300\/95 {
  accent-color: rgb(165 180 252 / 0.95) !important;
}

.tw-accent-indigo-400 {
  accent-color: #818cf8 !important;
}

.tw-accent-indigo-400\/0 {
  accent-color: rgb(129 140 248 / 0) !important;
}

.tw-accent-indigo-400\/10 {
  accent-color: rgb(129 140 248 / 0.1) !important;
}

.tw-accent-indigo-400\/100 {
  accent-color: rgb(129 140 248 / 1) !important;
}

.tw-accent-indigo-400\/15 {
  accent-color: rgb(129 140 248 / 0.15) !important;
}

.tw-accent-indigo-400\/20 {
  accent-color: rgb(129 140 248 / 0.2) !important;
}

.tw-accent-indigo-400\/25 {
  accent-color: rgb(129 140 248 / 0.25) !important;
}

.tw-accent-indigo-400\/30 {
  accent-color: rgb(129 140 248 / 0.3) !important;
}

.tw-accent-indigo-400\/35 {
  accent-color: rgb(129 140 248 / 0.35) !important;
}

.tw-accent-indigo-400\/40 {
  accent-color: rgb(129 140 248 / 0.4) !important;
}

.tw-accent-indigo-400\/45 {
  accent-color: rgb(129 140 248 / 0.45) !important;
}

.tw-accent-indigo-400\/5 {
  accent-color: rgb(129 140 248 / 0.05) !important;
}

.tw-accent-indigo-400\/50 {
  accent-color: rgb(129 140 248 / 0.5) !important;
}

.tw-accent-indigo-400\/55 {
  accent-color: rgb(129 140 248 / 0.55) !important;
}

.tw-accent-indigo-400\/60 {
  accent-color: rgb(129 140 248 / 0.6) !important;
}

.tw-accent-indigo-400\/65 {
  accent-color: rgb(129 140 248 / 0.65) !important;
}

.tw-accent-indigo-400\/70 {
  accent-color: rgb(129 140 248 / 0.7) !important;
}

.tw-accent-indigo-400\/75 {
  accent-color: rgb(129 140 248 / 0.75) !important;
}

.tw-accent-indigo-400\/80 {
  accent-color: rgb(129 140 248 / 0.8) !important;
}

.tw-accent-indigo-400\/85 {
  accent-color: rgb(129 140 248 / 0.85) !important;
}

.tw-accent-indigo-400\/90 {
  accent-color: rgb(129 140 248 / 0.9) !important;
}

.tw-accent-indigo-400\/95 {
  accent-color: rgb(129 140 248 / 0.95) !important;
}

.tw-accent-indigo-50 {
  accent-color: #eef2ff !important;
}

.tw-accent-indigo-50\/0 {
  accent-color: rgb(238 242 255 / 0) !important;
}

.tw-accent-indigo-50\/10 {
  accent-color: rgb(238 242 255 / 0.1) !important;
}

.tw-accent-indigo-50\/100 {
  accent-color: rgb(238 242 255 / 1) !important;
}

.tw-accent-indigo-50\/15 {
  accent-color: rgb(238 242 255 / 0.15) !important;
}

.tw-accent-indigo-50\/20 {
  accent-color: rgb(238 242 255 / 0.2) !important;
}

.tw-accent-indigo-50\/25 {
  accent-color: rgb(238 242 255 / 0.25) !important;
}

.tw-accent-indigo-50\/30 {
  accent-color: rgb(238 242 255 / 0.3) !important;
}

.tw-accent-indigo-50\/35 {
  accent-color: rgb(238 242 255 / 0.35) !important;
}

.tw-accent-indigo-50\/40 {
  accent-color: rgb(238 242 255 / 0.4) !important;
}

.tw-accent-indigo-50\/45 {
  accent-color: rgb(238 242 255 / 0.45) !important;
}

.tw-accent-indigo-50\/5 {
  accent-color: rgb(238 242 255 / 0.05) !important;
}

.tw-accent-indigo-50\/50 {
  accent-color: rgb(238 242 255 / 0.5) !important;
}

.tw-accent-indigo-50\/55 {
  accent-color: rgb(238 242 255 / 0.55) !important;
}

.tw-accent-indigo-50\/60 {
  accent-color: rgb(238 242 255 / 0.6) !important;
}

.tw-accent-indigo-50\/65 {
  accent-color: rgb(238 242 255 / 0.65) !important;
}

.tw-accent-indigo-50\/70 {
  accent-color: rgb(238 242 255 / 0.7) !important;
}

.tw-accent-indigo-50\/75 {
  accent-color: rgb(238 242 255 / 0.75) !important;
}

.tw-accent-indigo-50\/80 {
  accent-color: rgb(238 242 255 / 0.8) !important;
}

.tw-accent-indigo-50\/85 {
  accent-color: rgb(238 242 255 / 0.85) !important;
}

.tw-accent-indigo-50\/90 {
  accent-color: rgb(238 242 255 / 0.9) !important;
}

.tw-accent-indigo-50\/95 {
  accent-color: rgb(238 242 255 / 0.95) !important;
}

.tw-accent-indigo-500 {
  accent-color: #6366f1 !important;
}

.tw-accent-indigo-500\/0 {
  accent-color: rgb(99 102 241 / 0) !important;
}

.tw-accent-indigo-500\/10 {
  accent-color: rgb(99 102 241 / 0.1) !important;
}

.tw-accent-indigo-500\/100 {
  accent-color: rgb(99 102 241 / 1) !important;
}

.tw-accent-indigo-500\/15 {
  accent-color: rgb(99 102 241 / 0.15) !important;
}

.tw-accent-indigo-500\/20 {
  accent-color: rgb(99 102 241 / 0.2) !important;
}

.tw-accent-indigo-500\/25 {
  accent-color: rgb(99 102 241 / 0.25) !important;
}

.tw-accent-indigo-500\/30 {
  accent-color: rgb(99 102 241 / 0.3) !important;
}

.tw-accent-indigo-500\/35 {
  accent-color: rgb(99 102 241 / 0.35) !important;
}

.tw-accent-indigo-500\/40 {
  accent-color: rgb(99 102 241 / 0.4) !important;
}

.tw-accent-indigo-500\/45 {
  accent-color: rgb(99 102 241 / 0.45) !important;
}

.tw-accent-indigo-500\/5 {
  accent-color: rgb(99 102 241 / 0.05) !important;
}

.tw-accent-indigo-500\/50 {
  accent-color: rgb(99 102 241 / 0.5) !important;
}

.tw-accent-indigo-500\/55 {
  accent-color: rgb(99 102 241 / 0.55) !important;
}

.tw-accent-indigo-500\/60 {
  accent-color: rgb(99 102 241 / 0.6) !important;
}

.tw-accent-indigo-500\/65 {
  accent-color: rgb(99 102 241 / 0.65) !important;
}

.tw-accent-indigo-500\/70 {
  accent-color: rgb(99 102 241 / 0.7) !important;
}

.tw-accent-indigo-500\/75 {
  accent-color: rgb(99 102 241 / 0.75) !important;
}

.tw-accent-indigo-500\/80 {
  accent-color: rgb(99 102 241 / 0.8) !important;
}

.tw-accent-indigo-500\/85 {
  accent-color: rgb(99 102 241 / 0.85) !important;
}

.tw-accent-indigo-500\/90 {
  accent-color: rgb(99 102 241 / 0.9) !important;
}

.tw-accent-indigo-500\/95 {
  accent-color: rgb(99 102 241 / 0.95) !important;
}

.tw-accent-indigo-600 {
  accent-color: #4f46e5 !important;
}

.tw-accent-indigo-600\/0 {
  accent-color: rgb(79 70 229 / 0) !important;
}

.tw-accent-indigo-600\/10 {
  accent-color: rgb(79 70 229 / 0.1) !important;
}

.tw-accent-indigo-600\/100 {
  accent-color: rgb(79 70 229 / 1) !important;
}

.tw-accent-indigo-600\/15 {
  accent-color: rgb(79 70 229 / 0.15) !important;
}

.tw-accent-indigo-600\/20 {
  accent-color: rgb(79 70 229 / 0.2) !important;
}

.tw-accent-indigo-600\/25 {
  accent-color: rgb(79 70 229 / 0.25) !important;
}

.tw-accent-indigo-600\/30 {
  accent-color: rgb(79 70 229 / 0.3) !important;
}

.tw-accent-indigo-600\/35 {
  accent-color: rgb(79 70 229 / 0.35) !important;
}

.tw-accent-indigo-600\/40 {
  accent-color: rgb(79 70 229 / 0.4) !important;
}

.tw-accent-indigo-600\/45 {
  accent-color: rgb(79 70 229 / 0.45) !important;
}

.tw-accent-indigo-600\/5 {
  accent-color: rgb(79 70 229 / 0.05) !important;
}

.tw-accent-indigo-600\/50 {
  accent-color: rgb(79 70 229 / 0.5) !important;
}

.tw-accent-indigo-600\/55 {
  accent-color: rgb(79 70 229 / 0.55) !important;
}

.tw-accent-indigo-600\/60 {
  accent-color: rgb(79 70 229 / 0.6) !important;
}

.tw-accent-indigo-600\/65 {
  accent-color: rgb(79 70 229 / 0.65) !important;
}

.tw-accent-indigo-600\/70 {
  accent-color: rgb(79 70 229 / 0.7) !important;
}

.tw-accent-indigo-600\/75 {
  accent-color: rgb(79 70 229 / 0.75) !important;
}

.tw-accent-indigo-600\/80 {
  accent-color: rgb(79 70 229 / 0.8) !important;
}

.tw-accent-indigo-600\/85 {
  accent-color: rgb(79 70 229 / 0.85) !important;
}

.tw-accent-indigo-600\/90 {
  accent-color: rgb(79 70 229 / 0.9) !important;
}

.tw-accent-indigo-600\/95 {
  accent-color: rgb(79 70 229 / 0.95) !important;
}

.tw-accent-indigo-700 {
  accent-color: #4338ca !important;
}

.tw-accent-indigo-700\/0 {
  accent-color: rgb(67 56 202 / 0) !important;
}

.tw-accent-indigo-700\/10 {
  accent-color: rgb(67 56 202 / 0.1) !important;
}

.tw-accent-indigo-700\/100 {
  accent-color: rgb(67 56 202 / 1) !important;
}

.tw-accent-indigo-700\/15 {
  accent-color: rgb(67 56 202 / 0.15) !important;
}

.tw-accent-indigo-700\/20 {
  accent-color: rgb(67 56 202 / 0.2) !important;
}

.tw-accent-indigo-700\/25 {
  accent-color: rgb(67 56 202 / 0.25) !important;
}

.tw-accent-indigo-700\/30 {
  accent-color: rgb(67 56 202 / 0.3) !important;
}

.tw-accent-indigo-700\/35 {
  accent-color: rgb(67 56 202 / 0.35) !important;
}

.tw-accent-indigo-700\/40 {
  accent-color: rgb(67 56 202 / 0.4) !important;
}

.tw-accent-indigo-700\/45 {
  accent-color: rgb(67 56 202 / 0.45) !important;
}

.tw-accent-indigo-700\/5 {
  accent-color: rgb(67 56 202 / 0.05) !important;
}

.tw-accent-indigo-700\/50 {
  accent-color: rgb(67 56 202 / 0.5) !important;
}

.tw-accent-indigo-700\/55 {
  accent-color: rgb(67 56 202 / 0.55) !important;
}

.tw-accent-indigo-700\/60 {
  accent-color: rgb(67 56 202 / 0.6) !important;
}

.tw-accent-indigo-700\/65 {
  accent-color: rgb(67 56 202 / 0.65) !important;
}

.tw-accent-indigo-700\/70 {
  accent-color: rgb(67 56 202 / 0.7) !important;
}

.tw-accent-indigo-700\/75 {
  accent-color: rgb(67 56 202 / 0.75) !important;
}

.tw-accent-indigo-700\/80 {
  accent-color: rgb(67 56 202 / 0.8) !important;
}

.tw-accent-indigo-700\/85 {
  accent-color: rgb(67 56 202 / 0.85) !important;
}

.tw-accent-indigo-700\/90 {
  accent-color: rgb(67 56 202 / 0.9) !important;
}

.tw-accent-indigo-700\/95 {
  accent-color: rgb(67 56 202 / 0.95) !important;
}

.tw-accent-indigo-800 {
  accent-color: #3730a3 !important;
}

.tw-accent-indigo-800\/0 {
  accent-color: rgb(55 48 163 / 0) !important;
}

.tw-accent-indigo-800\/10 {
  accent-color: rgb(55 48 163 / 0.1) !important;
}

.tw-accent-indigo-800\/100 {
  accent-color: rgb(55 48 163 / 1) !important;
}

.tw-accent-indigo-800\/15 {
  accent-color: rgb(55 48 163 / 0.15) !important;
}

.tw-accent-indigo-800\/20 {
  accent-color: rgb(55 48 163 / 0.2) !important;
}

.tw-accent-indigo-800\/25 {
  accent-color: rgb(55 48 163 / 0.25) !important;
}

.tw-accent-indigo-800\/30 {
  accent-color: rgb(55 48 163 / 0.3) !important;
}

.tw-accent-indigo-800\/35 {
  accent-color: rgb(55 48 163 / 0.35) !important;
}

.tw-accent-indigo-800\/40 {
  accent-color: rgb(55 48 163 / 0.4) !important;
}

.tw-accent-indigo-800\/45 {
  accent-color: rgb(55 48 163 / 0.45) !important;
}

.tw-accent-indigo-800\/5 {
  accent-color: rgb(55 48 163 / 0.05) !important;
}

.tw-accent-indigo-800\/50 {
  accent-color: rgb(55 48 163 / 0.5) !important;
}

.tw-accent-indigo-800\/55 {
  accent-color: rgb(55 48 163 / 0.55) !important;
}

.tw-accent-indigo-800\/60 {
  accent-color: rgb(55 48 163 / 0.6) !important;
}

.tw-accent-indigo-800\/65 {
  accent-color: rgb(55 48 163 / 0.65) !important;
}

.tw-accent-indigo-800\/70 {
  accent-color: rgb(55 48 163 / 0.7) !important;
}

.tw-accent-indigo-800\/75 {
  accent-color: rgb(55 48 163 / 0.75) !important;
}

.tw-accent-indigo-800\/80 {
  accent-color: rgb(55 48 163 / 0.8) !important;
}

.tw-accent-indigo-800\/85 {
  accent-color: rgb(55 48 163 / 0.85) !important;
}

.tw-accent-indigo-800\/90 {
  accent-color: rgb(55 48 163 / 0.9) !important;
}

.tw-accent-indigo-800\/95 {
  accent-color: rgb(55 48 163 / 0.95) !important;
}

.tw-accent-indigo-900 {
  accent-color: #312e81 !important;
}

.tw-accent-indigo-900\/0 {
  accent-color: rgb(49 46 129 / 0) !important;
}

.tw-accent-indigo-900\/10 {
  accent-color: rgb(49 46 129 / 0.1) !important;
}

.tw-accent-indigo-900\/100 {
  accent-color: rgb(49 46 129 / 1) !important;
}

.tw-accent-indigo-900\/15 {
  accent-color: rgb(49 46 129 / 0.15) !important;
}

.tw-accent-indigo-900\/20 {
  accent-color: rgb(49 46 129 / 0.2) !important;
}

.tw-accent-indigo-900\/25 {
  accent-color: rgb(49 46 129 / 0.25) !important;
}

.tw-accent-indigo-900\/30 {
  accent-color: rgb(49 46 129 / 0.3) !important;
}

.tw-accent-indigo-900\/35 {
  accent-color: rgb(49 46 129 / 0.35) !important;
}

.tw-accent-indigo-900\/40 {
  accent-color: rgb(49 46 129 / 0.4) !important;
}

.tw-accent-indigo-900\/45 {
  accent-color: rgb(49 46 129 / 0.45) !important;
}

.tw-accent-indigo-900\/5 {
  accent-color: rgb(49 46 129 / 0.05) !important;
}

.tw-accent-indigo-900\/50 {
  accent-color: rgb(49 46 129 / 0.5) !important;
}

.tw-accent-indigo-900\/55 {
  accent-color: rgb(49 46 129 / 0.55) !important;
}

.tw-accent-indigo-900\/60 {
  accent-color: rgb(49 46 129 / 0.6) !important;
}

.tw-accent-indigo-900\/65 {
  accent-color: rgb(49 46 129 / 0.65) !important;
}

.tw-accent-indigo-900\/70 {
  accent-color: rgb(49 46 129 / 0.7) !important;
}

.tw-accent-indigo-900\/75 {
  accent-color: rgb(49 46 129 / 0.75) !important;
}

.tw-accent-indigo-900\/80 {
  accent-color: rgb(49 46 129 / 0.8) !important;
}

.tw-accent-indigo-900\/85 {
  accent-color: rgb(49 46 129 / 0.85) !important;
}

.tw-accent-indigo-900\/90 {
  accent-color: rgb(49 46 129 / 0.9) !important;
}

.tw-accent-indigo-900\/95 {
  accent-color: rgb(49 46 129 / 0.95) !important;
}

.tw-accent-indigo-950 {
  accent-color: #1e1b4b !important;
}

.tw-accent-indigo-950\/0 {
  accent-color: rgb(30 27 75 / 0) !important;
}

.tw-accent-indigo-950\/10 {
  accent-color: rgb(30 27 75 / 0.1) !important;
}

.tw-accent-indigo-950\/100 {
  accent-color: rgb(30 27 75 / 1) !important;
}

.tw-accent-indigo-950\/15 {
  accent-color: rgb(30 27 75 / 0.15) !important;
}

.tw-accent-indigo-950\/20 {
  accent-color: rgb(30 27 75 / 0.2) !important;
}

.tw-accent-indigo-950\/25 {
  accent-color: rgb(30 27 75 / 0.25) !important;
}

.tw-accent-indigo-950\/30 {
  accent-color: rgb(30 27 75 / 0.3) !important;
}

.tw-accent-indigo-950\/35 {
  accent-color: rgb(30 27 75 / 0.35) !important;
}

.tw-accent-indigo-950\/40 {
  accent-color: rgb(30 27 75 / 0.4) !important;
}

.tw-accent-indigo-950\/45 {
  accent-color: rgb(30 27 75 / 0.45) !important;
}

.tw-accent-indigo-950\/5 {
  accent-color: rgb(30 27 75 / 0.05) !important;
}

.tw-accent-indigo-950\/50 {
  accent-color: rgb(30 27 75 / 0.5) !important;
}

.tw-accent-indigo-950\/55 {
  accent-color: rgb(30 27 75 / 0.55) !important;
}

.tw-accent-indigo-950\/60 {
  accent-color: rgb(30 27 75 / 0.6) !important;
}

.tw-accent-indigo-950\/65 {
  accent-color: rgb(30 27 75 / 0.65) !important;
}

.tw-accent-indigo-950\/70 {
  accent-color: rgb(30 27 75 / 0.7) !important;
}

.tw-accent-indigo-950\/75 {
  accent-color: rgb(30 27 75 / 0.75) !important;
}

.tw-accent-indigo-950\/80 {
  accent-color: rgb(30 27 75 / 0.8) !important;
}

.tw-accent-indigo-950\/85 {
  accent-color: rgb(30 27 75 / 0.85) !important;
}

.tw-accent-indigo-950\/90 {
  accent-color: rgb(30 27 75 / 0.9) !important;
}

.tw-accent-indigo-950\/95 {
  accent-color: rgb(30 27 75 / 0.95) !important;
}

.tw-accent-inherit {
  accent-color: inherit !important;
}

.tw-accent-lime-100 {
  accent-color: #ecfccb !important;
}

.tw-accent-lime-100\/0 {
  accent-color: rgb(236 252 203 / 0) !important;
}

.tw-accent-lime-100\/10 {
  accent-color: rgb(236 252 203 / 0.1) !important;
}

.tw-accent-lime-100\/100 {
  accent-color: rgb(236 252 203 / 1) !important;
}

.tw-accent-lime-100\/15 {
  accent-color: rgb(236 252 203 / 0.15) !important;
}

.tw-accent-lime-100\/20 {
  accent-color: rgb(236 252 203 / 0.2) !important;
}

.tw-accent-lime-100\/25 {
  accent-color: rgb(236 252 203 / 0.25) !important;
}

.tw-accent-lime-100\/30 {
  accent-color: rgb(236 252 203 / 0.3) !important;
}

.tw-accent-lime-100\/35 {
  accent-color: rgb(236 252 203 / 0.35) !important;
}

.tw-accent-lime-100\/40 {
  accent-color: rgb(236 252 203 / 0.4) !important;
}

.tw-accent-lime-100\/45 {
  accent-color: rgb(236 252 203 / 0.45) !important;
}

.tw-accent-lime-100\/5 {
  accent-color: rgb(236 252 203 / 0.05) !important;
}

.tw-accent-lime-100\/50 {
  accent-color: rgb(236 252 203 / 0.5) !important;
}

.tw-accent-lime-100\/55 {
  accent-color: rgb(236 252 203 / 0.55) !important;
}

.tw-accent-lime-100\/60 {
  accent-color: rgb(236 252 203 / 0.6) !important;
}

.tw-accent-lime-100\/65 {
  accent-color: rgb(236 252 203 / 0.65) !important;
}

.tw-accent-lime-100\/70 {
  accent-color: rgb(236 252 203 / 0.7) !important;
}

.tw-accent-lime-100\/75 {
  accent-color: rgb(236 252 203 / 0.75) !important;
}

.tw-accent-lime-100\/80 {
  accent-color: rgb(236 252 203 / 0.8) !important;
}

.tw-accent-lime-100\/85 {
  accent-color: rgb(236 252 203 / 0.85) !important;
}

.tw-accent-lime-100\/90 {
  accent-color: rgb(236 252 203 / 0.9) !important;
}

.tw-accent-lime-100\/95 {
  accent-color: rgb(236 252 203 / 0.95) !important;
}

.tw-accent-lime-200 {
  accent-color: #d9f99d !important;
}

.tw-accent-lime-200\/0 {
  accent-color: rgb(217 249 157 / 0) !important;
}

.tw-accent-lime-200\/10 {
  accent-color: rgb(217 249 157 / 0.1) !important;
}

.tw-accent-lime-200\/100 {
  accent-color: rgb(217 249 157 / 1) !important;
}

.tw-accent-lime-200\/15 {
  accent-color: rgb(217 249 157 / 0.15) !important;
}

.tw-accent-lime-200\/20 {
  accent-color: rgb(217 249 157 / 0.2) !important;
}

.tw-accent-lime-200\/25 {
  accent-color: rgb(217 249 157 / 0.25) !important;
}

.tw-accent-lime-200\/30 {
  accent-color: rgb(217 249 157 / 0.3) !important;
}

.tw-accent-lime-200\/35 {
  accent-color: rgb(217 249 157 / 0.35) !important;
}

.tw-accent-lime-200\/40 {
  accent-color: rgb(217 249 157 / 0.4) !important;
}

.tw-accent-lime-200\/45 {
  accent-color: rgb(217 249 157 / 0.45) !important;
}

.tw-accent-lime-200\/5 {
  accent-color: rgb(217 249 157 / 0.05) !important;
}

.tw-accent-lime-200\/50 {
  accent-color: rgb(217 249 157 / 0.5) !important;
}

.tw-accent-lime-200\/55 {
  accent-color: rgb(217 249 157 / 0.55) !important;
}

.tw-accent-lime-200\/60 {
  accent-color: rgb(217 249 157 / 0.6) !important;
}

.tw-accent-lime-200\/65 {
  accent-color: rgb(217 249 157 / 0.65) !important;
}

.tw-accent-lime-200\/70 {
  accent-color: rgb(217 249 157 / 0.7) !important;
}

.tw-accent-lime-200\/75 {
  accent-color: rgb(217 249 157 / 0.75) !important;
}

.tw-accent-lime-200\/80 {
  accent-color: rgb(217 249 157 / 0.8) !important;
}

.tw-accent-lime-200\/85 {
  accent-color: rgb(217 249 157 / 0.85) !important;
}

.tw-accent-lime-200\/90 {
  accent-color: rgb(217 249 157 / 0.9) !important;
}

.tw-accent-lime-200\/95 {
  accent-color: rgb(217 249 157 / 0.95) !important;
}

.tw-accent-lime-300 {
  accent-color: #bef264 !important;
}

.tw-accent-lime-300\/0 {
  accent-color: rgb(190 242 100 / 0) !important;
}

.tw-accent-lime-300\/10 {
  accent-color: rgb(190 242 100 / 0.1) !important;
}

.tw-accent-lime-300\/100 {
  accent-color: rgb(190 242 100 / 1) !important;
}

.tw-accent-lime-300\/15 {
  accent-color: rgb(190 242 100 / 0.15) !important;
}

.tw-accent-lime-300\/20 {
  accent-color: rgb(190 242 100 / 0.2) !important;
}

.tw-accent-lime-300\/25 {
  accent-color: rgb(190 242 100 / 0.25) !important;
}

.tw-accent-lime-300\/30 {
  accent-color: rgb(190 242 100 / 0.3) !important;
}

.tw-accent-lime-300\/35 {
  accent-color: rgb(190 242 100 / 0.35) !important;
}

.tw-accent-lime-300\/40 {
  accent-color: rgb(190 242 100 / 0.4) !important;
}

.tw-accent-lime-300\/45 {
  accent-color: rgb(190 242 100 / 0.45) !important;
}

.tw-accent-lime-300\/5 {
  accent-color: rgb(190 242 100 / 0.05) !important;
}

.tw-accent-lime-300\/50 {
  accent-color: rgb(190 242 100 / 0.5) !important;
}

.tw-accent-lime-300\/55 {
  accent-color: rgb(190 242 100 / 0.55) !important;
}

.tw-accent-lime-300\/60 {
  accent-color: rgb(190 242 100 / 0.6) !important;
}

.tw-accent-lime-300\/65 {
  accent-color: rgb(190 242 100 / 0.65) !important;
}

.tw-accent-lime-300\/70 {
  accent-color: rgb(190 242 100 / 0.7) !important;
}

.tw-accent-lime-300\/75 {
  accent-color: rgb(190 242 100 / 0.75) !important;
}

.tw-accent-lime-300\/80 {
  accent-color: rgb(190 242 100 / 0.8) !important;
}

.tw-accent-lime-300\/85 {
  accent-color: rgb(190 242 100 / 0.85) !important;
}

.tw-accent-lime-300\/90 {
  accent-color: rgb(190 242 100 / 0.9) !important;
}

.tw-accent-lime-300\/95 {
  accent-color: rgb(190 242 100 / 0.95) !important;
}

.tw-accent-lime-400 {
  accent-color: #a3e635 !important;
}

.tw-accent-lime-400\/0 {
  accent-color: rgb(163 230 53 / 0) !important;
}

.tw-accent-lime-400\/10 {
  accent-color: rgb(163 230 53 / 0.1) !important;
}

.tw-accent-lime-400\/100 {
  accent-color: rgb(163 230 53 / 1) !important;
}

.tw-accent-lime-400\/15 {
  accent-color: rgb(163 230 53 / 0.15) !important;
}

.tw-accent-lime-400\/20 {
  accent-color: rgb(163 230 53 / 0.2) !important;
}

.tw-accent-lime-400\/25 {
  accent-color: rgb(163 230 53 / 0.25) !important;
}

.tw-accent-lime-400\/30 {
  accent-color: rgb(163 230 53 / 0.3) !important;
}

.tw-accent-lime-400\/35 {
  accent-color: rgb(163 230 53 / 0.35) !important;
}

.tw-accent-lime-400\/40 {
  accent-color: rgb(163 230 53 / 0.4) !important;
}

.tw-accent-lime-400\/45 {
  accent-color: rgb(163 230 53 / 0.45) !important;
}

.tw-accent-lime-400\/5 {
  accent-color: rgb(163 230 53 / 0.05) !important;
}

.tw-accent-lime-400\/50 {
  accent-color: rgb(163 230 53 / 0.5) !important;
}

.tw-accent-lime-400\/55 {
  accent-color: rgb(163 230 53 / 0.55) !important;
}

.tw-accent-lime-400\/60 {
  accent-color: rgb(163 230 53 / 0.6) !important;
}

.tw-accent-lime-400\/65 {
  accent-color: rgb(163 230 53 / 0.65) !important;
}

.tw-accent-lime-400\/70 {
  accent-color: rgb(163 230 53 / 0.7) !important;
}

.tw-accent-lime-400\/75 {
  accent-color: rgb(163 230 53 / 0.75) !important;
}

.tw-accent-lime-400\/80 {
  accent-color: rgb(163 230 53 / 0.8) !important;
}

.tw-accent-lime-400\/85 {
  accent-color: rgb(163 230 53 / 0.85) !important;
}

.tw-accent-lime-400\/90 {
  accent-color: rgb(163 230 53 / 0.9) !important;
}

.tw-accent-lime-400\/95 {
  accent-color: rgb(163 230 53 / 0.95) !important;
}

.tw-accent-lime-50 {
  accent-color: #f7fee7 !important;
}

.tw-accent-lime-50\/0 {
  accent-color: rgb(247 254 231 / 0) !important;
}

.tw-accent-lime-50\/10 {
  accent-color: rgb(247 254 231 / 0.1) !important;
}

.tw-accent-lime-50\/100 {
  accent-color: rgb(247 254 231 / 1) !important;
}

.tw-accent-lime-50\/15 {
  accent-color: rgb(247 254 231 / 0.15) !important;
}

.tw-accent-lime-50\/20 {
  accent-color: rgb(247 254 231 / 0.2) !important;
}

.tw-accent-lime-50\/25 {
  accent-color: rgb(247 254 231 / 0.25) !important;
}

.tw-accent-lime-50\/30 {
  accent-color: rgb(247 254 231 / 0.3) !important;
}

.tw-accent-lime-50\/35 {
  accent-color: rgb(247 254 231 / 0.35) !important;
}

.tw-accent-lime-50\/40 {
  accent-color: rgb(247 254 231 / 0.4) !important;
}

.tw-accent-lime-50\/45 {
  accent-color: rgb(247 254 231 / 0.45) !important;
}

.tw-accent-lime-50\/5 {
  accent-color: rgb(247 254 231 / 0.05) !important;
}

.tw-accent-lime-50\/50 {
  accent-color: rgb(247 254 231 / 0.5) !important;
}

.tw-accent-lime-50\/55 {
  accent-color: rgb(247 254 231 / 0.55) !important;
}

.tw-accent-lime-50\/60 {
  accent-color: rgb(247 254 231 / 0.6) !important;
}

.tw-accent-lime-50\/65 {
  accent-color: rgb(247 254 231 / 0.65) !important;
}

.tw-accent-lime-50\/70 {
  accent-color: rgb(247 254 231 / 0.7) !important;
}

.tw-accent-lime-50\/75 {
  accent-color: rgb(247 254 231 / 0.75) !important;
}

.tw-accent-lime-50\/80 {
  accent-color: rgb(247 254 231 / 0.8) !important;
}

.tw-accent-lime-50\/85 {
  accent-color: rgb(247 254 231 / 0.85) !important;
}

.tw-accent-lime-50\/90 {
  accent-color: rgb(247 254 231 / 0.9) !important;
}

.tw-accent-lime-50\/95 {
  accent-color: rgb(247 254 231 / 0.95) !important;
}

.tw-accent-lime-500 {
  accent-color: #84cc16 !important;
}

.tw-accent-lime-500\/0 {
  accent-color: rgb(132 204 22 / 0) !important;
}

.tw-accent-lime-500\/10 {
  accent-color: rgb(132 204 22 / 0.1) !important;
}

.tw-accent-lime-500\/100 {
  accent-color: rgb(132 204 22 / 1) !important;
}

.tw-accent-lime-500\/15 {
  accent-color: rgb(132 204 22 / 0.15) !important;
}

.tw-accent-lime-500\/20 {
  accent-color: rgb(132 204 22 / 0.2) !important;
}

.tw-accent-lime-500\/25 {
  accent-color: rgb(132 204 22 / 0.25) !important;
}

.tw-accent-lime-500\/30 {
  accent-color: rgb(132 204 22 / 0.3) !important;
}

.tw-accent-lime-500\/35 {
  accent-color: rgb(132 204 22 / 0.35) !important;
}

.tw-accent-lime-500\/40 {
  accent-color: rgb(132 204 22 / 0.4) !important;
}

.tw-accent-lime-500\/45 {
  accent-color: rgb(132 204 22 / 0.45) !important;
}

.tw-accent-lime-500\/5 {
  accent-color: rgb(132 204 22 / 0.05) !important;
}

.tw-accent-lime-500\/50 {
  accent-color: rgb(132 204 22 / 0.5) !important;
}

.tw-accent-lime-500\/55 {
  accent-color: rgb(132 204 22 / 0.55) !important;
}

.tw-accent-lime-500\/60 {
  accent-color: rgb(132 204 22 / 0.6) !important;
}

.tw-accent-lime-500\/65 {
  accent-color: rgb(132 204 22 / 0.65) !important;
}

.tw-accent-lime-500\/70 {
  accent-color: rgb(132 204 22 / 0.7) !important;
}

.tw-accent-lime-500\/75 {
  accent-color: rgb(132 204 22 / 0.75) !important;
}

.tw-accent-lime-500\/80 {
  accent-color: rgb(132 204 22 / 0.8) !important;
}

.tw-accent-lime-500\/85 {
  accent-color: rgb(132 204 22 / 0.85) !important;
}

.tw-accent-lime-500\/90 {
  accent-color: rgb(132 204 22 / 0.9) !important;
}

.tw-accent-lime-500\/95 {
  accent-color: rgb(132 204 22 / 0.95) !important;
}

.tw-accent-lime-600 {
  accent-color: #65a30d !important;
}

.tw-accent-lime-600\/0 {
  accent-color: rgb(101 163 13 / 0) !important;
}

.tw-accent-lime-600\/10 {
  accent-color: rgb(101 163 13 / 0.1) !important;
}

.tw-accent-lime-600\/100 {
  accent-color: rgb(101 163 13 / 1) !important;
}

.tw-accent-lime-600\/15 {
  accent-color: rgb(101 163 13 / 0.15) !important;
}

.tw-accent-lime-600\/20 {
  accent-color: rgb(101 163 13 / 0.2) !important;
}

.tw-accent-lime-600\/25 {
  accent-color: rgb(101 163 13 / 0.25) !important;
}

.tw-accent-lime-600\/30 {
  accent-color: rgb(101 163 13 / 0.3) !important;
}

.tw-accent-lime-600\/35 {
  accent-color: rgb(101 163 13 / 0.35) !important;
}

.tw-accent-lime-600\/40 {
  accent-color: rgb(101 163 13 / 0.4) !important;
}

.tw-accent-lime-600\/45 {
  accent-color: rgb(101 163 13 / 0.45) !important;
}

.tw-accent-lime-600\/5 {
  accent-color: rgb(101 163 13 / 0.05) !important;
}

.tw-accent-lime-600\/50 {
  accent-color: rgb(101 163 13 / 0.5) !important;
}

.tw-accent-lime-600\/55 {
  accent-color: rgb(101 163 13 / 0.55) !important;
}

.tw-accent-lime-600\/60 {
  accent-color: rgb(101 163 13 / 0.6) !important;
}

.tw-accent-lime-600\/65 {
  accent-color: rgb(101 163 13 / 0.65) !important;
}

.tw-accent-lime-600\/70 {
  accent-color: rgb(101 163 13 / 0.7) !important;
}

.tw-accent-lime-600\/75 {
  accent-color: rgb(101 163 13 / 0.75) !important;
}

.tw-accent-lime-600\/80 {
  accent-color: rgb(101 163 13 / 0.8) !important;
}

.tw-accent-lime-600\/85 {
  accent-color: rgb(101 163 13 / 0.85) !important;
}

.tw-accent-lime-600\/90 {
  accent-color: rgb(101 163 13 / 0.9) !important;
}

.tw-accent-lime-600\/95 {
  accent-color: rgb(101 163 13 / 0.95) !important;
}

.tw-accent-lime-700 {
  accent-color: #4d7c0f !important;
}

.tw-accent-lime-700\/0 {
  accent-color: rgb(77 124 15 / 0) !important;
}

.tw-accent-lime-700\/10 {
  accent-color: rgb(77 124 15 / 0.1) !important;
}

.tw-accent-lime-700\/100 {
  accent-color: rgb(77 124 15 / 1) !important;
}

.tw-accent-lime-700\/15 {
  accent-color: rgb(77 124 15 / 0.15) !important;
}

.tw-accent-lime-700\/20 {
  accent-color: rgb(77 124 15 / 0.2) !important;
}

.tw-accent-lime-700\/25 {
  accent-color: rgb(77 124 15 / 0.25) !important;
}

.tw-accent-lime-700\/30 {
  accent-color: rgb(77 124 15 / 0.3) !important;
}

.tw-accent-lime-700\/35 {
  accent-color: rgb(77 124 15 / 0.35) !important;
}

.tw-accent-lime-700\/40 {
  accent-color: rgb(77 124 15 / 0.4) !important;
}

.tw-accent-lime-700\/45 {
  accent-color: rgb(77 124 15 / 0.45) !important;
}

.tw-accent-lime-700\/5 {
  accent-color: rgb(77 124 15 / 0.05) !important;
}

.tw-accent-lime-700\/50 {
  accent-color: rgb(77 124 15 / 0.5) !important;
}

.tw-accent-lime-700\/55 {
  accent-color: rgb(77 124 15 / 0.55) !important;
}

.tw-accent-lime-700\/60 {
  accent-color: rgb(77 124 15 / 0.6) !important;
}

.tw-accent-lime-700\/65 {
  accent-color: rgb(77 124 15 / 0.65) !important;
}

.tw-accent-lime-700\/70 {
  accent-color: rgb(77 124 15 / 0.7) !important;
}

.tw-accent-lime-700\/75 {
  accent-color: rgb(77 124 15 / 0.75) !important;
}

.tw-accent-lime-700\/80 {
  accent-color: rgb(77 124 15 / 0.8) !important;
}

.tw-accent-lime-700\/85 {
  accent-color: rgb(77 124 15 / 0.85) !important;
}

.tw-accent-lime-700\/90 {
  accent-color: rgb(77 124 15 / 0.9) !important;
}

.tw-accent-lime-700\/95 {
  accent-color: rgb(77 124 15 / 0.95) !important;
}

.tw-accent-lime-800 {
  accent-color: #3f6212 !important;
}

.tw-accent-lime-800\/0 {
  accent-color: rgb(63 98 18 / 0) !important;
}

.tw-accent-lime-800\/10 {
  accent-color: rgb(63 98 18 / 0.1) !important;
}

.tw-accent-lime-800\/100 {
  accent-color: rgb(63 98 18 / 1) !important;
}

.tw-accent-lime-800\/15 {
  accent-color: rgb(63 98 18 / 0.15) !important;
}

.tw-accent-lime-800\/20 {
  accent-color: rgb(63 98 18 / 0.2) !important;
}

.tw-accent-lime-800\/25 {
  accent-color: rgb(63 98 18 / 0.25) !important;
}

.tw-accent-lime-800\/30 {
  accent-color: rgb(63 98 18 / 0.3) !important;
}

.tw-accent-lime-800\/35 {
  accent-color: rgb(63 98 18 / 0.35) !important;
}

.tw-accent-lime-800\/40 {
  accent-color: rgb(63 98 18 / 0.4) !important;
}

.tw-accent-lime-800\/45 {
  accent-color: rgb(63 98 18 / 0.45) !important;
}

.tw-accent-lime-800\/5 {
  accent-color: rgb(63 98 18 / 0.05) !important;
}

.tw-accent-lime-800\/50 {
  accent-color: rgb(63 98 18 / 0.5) !important;
}

.tw-accent-lime-800\/55 {
  accent-color: rgb(63 98 18 / 0.55) !important;
}

.tw-accent-lime-800\/60 {
  accent-color: rgb(63 98 18 / 0.6) !important;
}

.tw-accent-lime-800\/65 {
  accent-color: rgb(63 98 18 / 0.65) !important;
}

.tw-accent-lime-800\/70 {
  accent-color: rgb(63 98 18 / 0.7) !important;
}

.tw-accent-lime-800\/75 {
  accent-color: rgb(63 98 18 / 0.75) !important;
}

.tw-accent-lime-800\/80 {
  accent-color: rgb(63 98 18 / 0.8) !important;
}

.tw-accent-lime-800\/85 {
  accent-color: rgb(63 98 18 / 0.85) !important;
}

.tw-accent-lime-800\/90 {
  accent-color: rgb(63 98 18 / 0.9) !important;
}

.tw-accent-lime-800\/95 {
  accent-color: rgb(63 98 18 / 0.95) !important;
}

.tw-accent-lime-900 {
  accent-color: #365314 !important;
}

.tw-accent-lime-900\/0 {
  accent-color: rgb(54 83 20 / 0) !important;
}

.tw-accent-lime-900\/10 {
  accent-color: rgb(54 83 20 / 0.1) !important;
}

.tw-accent-lime-900\/100 {
  accent-color: rgb(54 83 20 / 1) !important;
}

.tw-accent-lime-900\/15 {
  accent-color: rgb(54 83 20 / 0.15) !important;
}

.tw-accent-lime-900\/20 {
  accent-color: rgb(54 83 20 / 0.2) !important;
}

.tw-accent-lime-900\/25 {
  accent-color: rgb(54 83 20 / 0.25) !important;
}

.tw-accent-lime-900\/30 {
  accent-color: rgb(54 83 20 / 0.3) !important;
}

.tw-accent-lime-900\/35 {
  accent-color: rgb(54 83 20 / 0.35) !important;
}

.tw-accent-lime-900\/40 {
  accent-color: rgb(54 83 20 / 0.4) !important;
}

.tw-accent-lime-900\/45 {
  accent-color: rgb(54 83 20 / 0.45) !important;
}

.tw-accent-lime-900\/5 {
  accent-color: rgb(54 83 20 / 0.05) !important;
}

.tw-accent-lime-900\/50 {
  accent-color: rgb(54 83 20 / 0.5) !important;
}

.tw-accent-lime-900\/55 {
  accent-color: rgb(54 83 20 / 0.55) !important;
}

.tw-accent-lime-900\/60 {
  accent-color: rgb(54 83 20 / 0.6) !important;
}

.tw-accent-lime-900\/65 {
  accent-color: rgb(54 83 20 / 0.65) !important;
}

.tw-accent-lime-900\/70 {
  accent-color: rgb(54 83 20 / 0.7) !important;
}

.tw-accent-lime-900\/75 {
  accent-color: rgb(54 83 20 / 0.75) !important;
}

.tw-accent-lime-900\/80 {
  accent-color: rgb(54 83 20 / 0.8) !important;
}

.tw-accent-lime-900\/85 {
  accent-color: rgb(54 83 20 / 0.85) !important;
}

.tw-accent-lime-900\/90 {
  accent-color: rgb(54 83 20 / 0.9) !important;
}

.tw-accent-lime-900\/95 {
  accent-color: rgb(54 83 20 / 0.95) !important;
}

.tw-accent-lime-950 {
  accent-color: #1a2e05 !important;
}

.tw-accent-lime-950\/0 {
  accent-color: rgb(26 46 5 / 0) !important;
}

.tw-accent-lime-950\/10 {
  accent-color: rgb(26 46 5 / 0.1) !important;
}

.tw-accent-lime-950\/100 {
  accent-color: rgb(26 46 5 / 1) !important;
}

.tw-accent-lime-950\/15 {
  accent-color: rgb(26 46 5 / 0.15) !important;
}

.tw-accent-lime-950\/20 {
  accent-color: rgb(26 46 5 / 0.2) !important;
}

.tw-accent-lime-950\/25 {
  accent-color: rgb(26 46 5 / 0.25) !important;
}

.tw-accent-lime-950\/30 {
  accent-color: rgb(26 46 5 / 0.3) !important;
}

.tw-accent-lime-950\/35 {
  accent-color: rgb(26 46 5 / 0.35) !important;
}

.tw-accent-lime-950\/40 {
  accent-color: rgb(26 46 5 / 0.4) !important;
}

.tw-accent-lime-950\/45 {
  accent-color: rgb(26 46 5 / 0.45) !important;
}

.tw-accent-lime-950\/5 {
  accent-color: rgb(26 46 5 / 0.05) !important;
}

.tw-accent-lime-950\/50 {
  accent-color: rgb(26 46 5 / 0.5) !important;
}

.tw-accent-lime-950\/55 {
  accent-color: rgb(26 46 5 / 0.55) !important;
}

.tw-accent-lime-950\/60 {
  accent-color: rgb(26 46 5 / 0.6) !important;
}

.tw-accent-lime-950\/65 {
  accent-color: rgb(26 46 5 / 0.65) !important;
}

.tw-accent-lime-950\/70 {
  accent-color: rgb(26 46 5 / 0.7) !important;
}

.tw-accent-lime-950\/75 {
  accent-color: rgb(26 46 5 / 0.75) !important;
}

.tw-accent-lime-950\/80 {
  accent-color: rgb(26 46 5 / 0.8) !important;
}

.tw-accent-lime-950\/85 {
  accent-color: rgb(26 46 5 / 0.85) !important;
}

.tw-accent-lime-950\/90 {
  accent-color: rgb(26 46 5 / 0.9) !important;
}

.tw-accent-lime-950\/95 {
  accent-color: rgb(26 46 5 / 0.95) !important;
}

.tw-accent-neutral-100 {
  accent-color: #f5f5f5 !important;
}

.tw-accent-neutral-100\/0 {
  accent-color: rgb(245 245 245 / 0) !important;
}

.tw-accent-neutral-100\/10 {
  accent-color: rgb(245 245 245 / 0.1) !important;
}

.tw-accent-neutral-100\/100 {
  accent-color: rgb(245 245 245 / 1) !important;
}

.tw-accent-neutral-100\/15 {
  accent-color: rgb(245 245 245 / 0.15) !important;
}

.tw-accent-neutral-100\/20 {
  accent-color: rgb(245 245 245 / 0.2) !important;
}

.tw-accent-neutral-100\/25 {
  accent-color: rgb(245 245 245 / 0.25) !important;
}

.tw-accent-neutral-100\/30 {
  accent-color: rgb(245 245 245 / 0.3) !important;
}

.tw-accent-neutral-100\/35 {
  accent-color: rgb(245 245 245 / 0.35) !important;
}

.tw-accent-neutral-100\/40 {
  accent-color: rgb(245 245 245 / 0.4) !important;
}

.tw-accent-neutral-100\/45 {
  accent-color: rgb(245 245 245 / 0.45) !important;
}

.tw-accent-neutral-100\/5 {
  accent-color: rgb(245 245 245 / 0.05) !important;
}

.tw-accent-neutral-100\/50 {
  accent-color: rgb(245 245 245 / 0.5) !important;
}

.tw-accent-neutral-100\/55 {
  accent-color: rgb(245 245 245 / 0.55) !important;
}

.tw-accent-neutral-100\/60 {
  accent-color: rgb(245 245 245 / 0.6) !important;
}

.tw-accent-neutral-100\/65 {
  accent-color: rgb(245 245 245 / 0.65) !important;
}

.tw-accent-neutral-100\/70 {
  accent-color: rgb(245 245 245 / 0.7) !important;
}

.tw-accent-neutral-100\/75 {
  accent-color: rgb(245 245 245 / 0.75) !important;
}

.tw-accent-neutral-100\/80 {
  accent-color: rgb(245 245 245 / 0.8) !important;
}

.tw-accent-neutral-100\/85 {
  accent-color: rgb(245 245 245 / 0.85) !important;
}

.tw-accent-neutral-100\/90 {
  accent-color: rgb(245 245 245 / 0.9) !important;
}

.tw-accent-neutral-100\/95 {
  accent-color: rgb(245 245 245 / 0.95) !important;
}

.tw-accent-neutral-200 {
  accent-color: #e5e5e5 !important;
}

.tw-accent-neutral-200\/0 {
  accent-color: rgb(229 229 229 / 0) !important;
}

.tw-accent-neutral-200\/10 {
  accent-color: rgb(229 229 229 / 0.1) !important;
}

.tw-accent-neutral-200\/100 {
  accent-color: rgb(229 229 229 / 1) !important;
}

.tw-accent-neutral-200\/15 {
  accent-color: rgb(229 229 229 / 0.15) !important;
}

.tw-accent-neutral-200\/20 {
  accent-color: rgb(229 229 229 / 0.2) !important;
}

.tw-accent-neutral-200\/25 {
  accent-color: rgb(229 229 229 / 0.25) !important;
}

.tw-accent-neutral-200\/30 {
  accent-color: rgb(229 229 229 / 0.3) !important;
}

.tw-accent-neutral-200\/35 {
  accent-color: rgb(229 229 229 / 0.35) !important;
}

.tw-accent-neutral-200\/40 {
  accent-color: rgb(229 229 229 / 0.4) !important;
}

.tw-accent-neutral-200\/45 {
  accent-color: rgb(229 229 229 / 0.45) !important;
}

.tw-accent-neutral-200\/5 {
  accent-color: rgb(229 229 229 / 0.05) !important;
}

.tw-accent-neutral-200\/50 {
  accent-color: rgb(229 229 229 / 0.5) !important;
}

.tw-accent-neutral-200\/55 {
  accent-color: rgb(229 229 229 / 0.55) !important;
}

.tw-accent-neutral-200\/60 {
  accent-color: rgb(229 229 229 / 0.6) !important;
}

.tw-accent-neutral-200\/65 {
  accent-color: rgb(229 229 229 / 0.65) !important;
}

.tw-accent-neutral-200\/70 {
  accent-color: rgb(229 229 229 / 0.7) !important;
}

.tw-accent-neutral-200\/75 {
  accent-color: rgb(229 229 229 / 0.75) !important;
}

.tw-accent-neutral-200\/80 {
  accent-color: rgb(229 229 229 / 0.8) !important;
}

.tw-accent-neutral-200\/85 {
  accent-color: rgb(229 229 229 / 0.85) !important;
}

.tw-accent-neutral-200\/90 {
  accent-color: rgb(229 229 229 / 0.9) !important;
}

.tw-accent-neutral-200\/95 {
  accent-color: rgb(229 229 229 / 0.95) !important;
}

.tw-accent-neutral-300 {
  accent-color: #d4d4d4 !important;
}

.tw-accent-neutral-300\/0 {
  accent-color: rgb(212 212 212 / 0) !important;
}

.tw-accent-neutral-300\/10 {
  accent-color: rgb(212 212 212 / 0.1) !important;
}

.tw-accent-neutral-300\/100 {
  accent-color: rgb(212 212 212 / 1) !important;
}

.tw-accent-neutral-300\/15 {
  accent-color: rgb(212 212 212 / 0.15) !important;
}

.tw-accent-neutral-300\/20 {
  accent-color: rgb(212 212 212 / 0.2) !important;
}

.tw-accent-neutral-300\/25 {
  accent-color: rgb(212 212 212 / 0.25) !important;
}

.tw-accent-neutral-300\/30 {
  accent-color: rgb(212 212 212 / 0.3) !important;
}

.tw-accent-neutral-300\/35 {
  accent-color: rgb(212 212 212 / 0.35) !important;
}

.tw-accent-neutral-300\/40 {
  accent-color: rgb(212 212 212 / 0.4) !important;
}

.tw-accent-neutral-300\/45 {
  accent-color: rgb(212 212 212 / 0.45) !important;
}

.tw-accent-neutral-300\/5 {
  accent-color: rgb(212 212 212 / 0.05) !important;
}

.tw-accent-neutral-300\/50 {
  accent-color: rgb(212 212 212 / 0.5) !important;
}

.tw-accent-neutral-300\/55 {
  accent-color: rgb(212 212 212 / 0.55) !important;
}

.tw-accent-neutral-300\/60 {
  accent-color: rgb(212 212 212 / 0.6) !important;
}

.tw-accent-neutral-300\/65 {
  accent-color: rgb(212 212 212 / 0.65) !important;
}

.tw-accent-neutral-300\/70 {
  accent-color: rgb(212 212 212 / 0.7) !important;
}

.tw-accent-neutral-300\/75 {
  accent-color: rgb(212 212 212 / 0.75) !important;
}

.tw-accent-neutral-300\/80 {
  accent-color: rgb(212 212 212 / 0.8) !important;
}

.tw-accent-neutral-300\/85 {
  accent-color: rgb(212 212 212 / 0.85) !important;
}

.tw-accent-neutral-300\/90 {
  accent-color: rgb(212 212 212 / 0.9) !important;
}

.tw-accent-neutral-300\/95 {
  accent-color: rgb(212 212 212 / 0.95) !important;
}

.tw-accent-neutral-400 {
  accent-color: #a3a3a3 !important;
}

.tw-accent-neutral-400\/0 {
  accent-color: rgb(163 163 163 / 0) !important;
}

.tw-accent-neutral-400\/10 {
  accent-color: rgb(163 163 163 / 0.1) !important;
}

.tw-accent-neutral-400\/100 {
  accent-color: rgb(163 163 163 / 1) !important;
}

.tw-accent-neutral-400\/15 {
  accent-color: rgb(163 163 163 / 0.15) !important;
}

.tw-accent-neutral-400\/20 {
  accent-color: rgb(163 163 163 / 0.2) !important;
}

.tw-accent-neutral-400\/25 {
  accent-color: rgb(163 163 163 / 0.25) !important;
}

.tw-accent-neutral-400\/30 {
  accent-color: rgb(163 163 163 / 0.3) !important;
}

.tw-accent-neutral-400\/35 {
  accent-color: rgb(163 163 163 / 0.35) !important;
}

.tw-accent-neutral-400\/40 {
  accent-color: rgb(163 163 163 / 0.4) !important;
}

.tw-accent-neutral-400\/45 {
  accent-color: rgb(163 163 163 / 0.45) !important;
}

.tw-accent-neutral-400\/5 {
  accent-color: rgb(163 163 163 / 0.05) !important;
}

.tw-accent-neutral-400\/50 {
  accent-color: rgb(163 163 163 / 0.5) !important;
}

.tw-accent-neutral-400\/55 {
  accent-color: rgb(163 163 163 / 0.55) !important;
}

.tw-accent-neutral-400\/60 {
  accent-color: rgb(163 163 163 / 0.6) !important;
}

.tw-accent-neutral-400\/65 {
  accent-color: rgb(163 163 163 / 0.65) !important;
}

.tw-accent-neutral-400\/70 {
  accent-color: rgb(163 163 163 / 0.7) !important;
}

.tw-accent-neutral-400\/75 {
  accent-color: rgb(163 163 163 / 0.75) !important;
}

.tw-accent-neutral-400\/80 {
  accent-color: rgb(163 163 163 / 0.8) !important;
}

.tw-accent-neutral-400\/85 {
  accent-color: rgb(163 163 163 / 0.85) !important;
}

.tw-accent-neutral-400\/90 {
  accent-color: rgb(163 163 163 / 0.9) !important;
}

.tw-accent-neutral-400\/95 {
  accent-color: rgb(163 163 163 / 0.95) !important;
}

.tw-accent-neutral-50 {
  accent-color: #fafafa !important;
}

.tw-accent-neutral-50\/0 {
  accent-color: rgb(250 250 250 / 0) !important;
}

.tw-accent-neutral-50\/10 {
  accent-color: rgb(250 250 250 / 0.1) !important;
}

.tw-accent-neutral-50\/100 {
  accent-color: rgb(250 250 250 / 1) !important;
}

.tw-accent-neutral-50\/15 {
  accent-color: rgb(250 250 250 / 0.15) !important;
}

.tw-accent-neutral-50\/20 {
  accent-color: rgb(250 250 250 / 0.2) !important;
}

.tw-accent-neutral-50\/25 {
  accent-color: rgb(250 250 250 / 0.25) !important;
}

.tw-accent-neutral-50\/30 {
  accent-color: rgb(250 250 250 / 0.3) !important;
}

.tw-accent-neutral-50\/35 {
  accent-color: rgb(250 250 250 / 0.35) !important;
}

.tw-accent-neutral-50\/40 {
  accent-color: rgb(250 250 250 / 0.4) !important;
}

.tw-accent-neutral-50\/45 {
  accent-color: rgb(250 250 250 / 0.45) !important;
}

.tw-accent-neutral-50\/5 {
  accent-color: rgb(250 250 250 / 0.05) !important;
}

.tw-accent-neutral-50\/50 {
  accent-color: rgb(250 250 250 / 0.5) !important;
}

.tw-accent-neutral-50\/55 {
  accent-color: rgb(250 250 250 / 0.55) !important;
}

.tw-accent-neutral-50\/60 {
  accent-color: rgb(250 250 250 / 0.6) !important;
}

.tw-accent-neutral-50\/65 {
  accent-color: rgb(250 250 250 / 0.65) !important;
}

.tw-accent-neutral-50\/70 {
  accent-color: rgb(250 250 250 / 0.7) !important;
}

.tw-accent-neutral-50\/75 {
  accent-color: rgb(250 250 250 / 0.75) !important;
}

.tw-accent-neutral-50\/80 {
  accent-color: rgb(250 250 250 / 0.8) !important;
}

.tw-accent-neutral-50\/85 {
  accent-color: rgb(250 250 250 / 0.85) !important;
}

.tw-accent-neutral-50\/90 {
  accent-color: rgb(250 250 250 / 0.9) !important;
}

.tw-accent-neutral-50\/95 {
  accent-color: rgb(250 250 250 / 0.95) !important;
}

.tw-accent-neutral-500 {
  accent-color: #737373 !important;
}

.tw-accent-neutral-500\/0 {
  accent-color: rgb(115 115 115 / 0) !important;
}

.tw-accent-neutral-500\/10 {
  accent-color: rgb(115 115 115 / 0.1) !important;
}

.tw-accent-neutral-500\/100 {
  accent-color: rgb(115 115 115 / 1) !important;
}

.tw-accent-neutral-500\/15 {
  accent-color: rgb(115 115 115 / 0.15) !important;
}

.tw-accent-neutral-500\/20 {
  accent-color: rgb(115 115 115 / 0.2) !important;
}

.tw-accent-neutral-500\/25 {
  accent-color: rgb(115 115 115 / 0.25) !important;
}

.tw-accent-neutral-500\/30 {
  accent-color: rgb(115 115 115 / 0.3) !important;
}

.tw-accent-neutral-500\/35 {
  accent-color: rgb(115 115 115 / 0.35) !important;
}

.tw-accent-neutral-500\/40 {
  accent-color: rgb(115 115 115 / 0.4) !important;
}

.tw-accent-neutral-500\/45 {
  accent-color: rgb(115 115 115 / 0.45) !important;
}

.tw-accent-neutral-500\/5 {
  accent-color: rgb(115 115 115 / 0.05) !important;
}

.tw-accent-neutral-500\/50 {
  accent-color: rgb(115 115 115 / 0.5) !important;
}

.tw-accent-neutral-500\/55 {
  accent-color: rgb(115 115 115 / 0.55) !important;
}

.tw-accent-neutral-500\/60 {
  accent-color: rgb(115 115 115 / 0.6) !important;
}

.tw-accent-neutral-500\/65 {
  accent-color: rgb(115 115 115 / 0.65) !important;
}

.tw-accent-neutral-500\/70 {
  accent-color: rgb(115 115 115 / 0.7) !important;
}

.tw-accent-neutral-500\/75 {
  accent-color: rgb(115 115 115 / 0.75) !important;
}

.tw-accent-neutral-500\/80 {
  accent-color: rgb(115 115 115 / 0.8) !important;
}

.tw-accent-neutral-500\/85 {
  accent-color: rgb(115 115 115 / 0.85) !important;
}

.tw-accent-neutral-500\/90 {
  accent-color: rgb(115 115 115 / 0.9) !important;
}

.tw-accent-neutral-500\/95 {
  accent-color: rgb(115 115 115 / 0.95) !important;
}

.tw-accent-neutral-600 {
  accent-color: #525252 !important;
}

.tw-accent-neutral-600\/0 {
  accent-color: rgb(82 82 82 / 0) !important;
}

.tw-accent-neutral-600\/10 {
  accent-color: rgb(82 82 82 / 0.1) !important;
}

.tw-accent-neutral-600\/100 {
  accent-color: rgb(82 82 82 / 1) !important;
}

.tw-accent-neutral-600\/15 {
  accent-color: rgb(82 82 82 / 0.15) !important;
}

.tw-accent-neutral-600\/20 {
  accent-color: rgb(82 82 82 / 0.2) !important;
}

.tw-accent-neutral-600\/25 {
  accent-color: rgb(82 82 82 / 0.25) !important;
}

.tw-accent-neutral-600\/30 {
  accent-color: rgb(82 82 82 / 0.3) !important;
}

.tw-accent-neutral-600\/35 {
  accent-color: rgb(82 82 82 / 0.35) !important;
}

.tw-accent-neutral-600\/40 {
  accent-color: rgb(82 82 82 / 0.4) !important;
}

.tw-accent-neutral-600\/45 {
  accent-color: rgb(82 82 82 / 0.45) !important;
}

.tw-accent-neutral-600\/5 {
  accent-color: rgb(82 82 82 / 0.05) !important;
}

.tw-accent-neutral-600\/50 {
  accent-color: rgb(82 82 82 / 0.5) !important;
}

.tw-accent-neutral-600\/55 {
  accent-color: rgb(82 82 82 / 0.55) !important;
}

.tw-accent-neutral-600\/60 {
  accent-color: rgb(82 82 82 / 0.6) !important;
}

.tw-accent-neutral-600\/65 {
  accent-color: rgb(82 82 82 / 0.65) !important;
}

.tw-accent-neutral-600\/70 {
  accent-color: rgb(82 82 82 / 0.7) !important;
}

.tw-accent-neutral-600\/75 {
  accent-color: rgb(82 82 82 / 0.75) !important;
}

.tw-accent-neutral-600\/80 {
  accent-color: rgb(82 82 82 / 0.8) !important;
}

.tw-accent-neutral-600\/85 {
  accent-color: rgb(82 82 82 / 0.85) !important;
}

.tw-accent-neutral-600\/90 {
  accent-color: rgb(82 82 82 / 0.9) !important;
}

.tw-accent-neutral-600\/95 {
  accent-color: rgb(82 82 82 / 0.95) !important;
}

.tw-accent-neutral-700 {
  accent-color: #404040 !important;
}

.tw-accent-neutral-700\/0 {
  accent-color: rgb(64 64 64 / 0) !important;
}

.tw-accent-neutral-700\/10 {
  accent-color: rgb(64 64 64 / 0.1) !important;
}

.tw-accent-neutral-700\/100 {
  accent-color: rgb(64 64 64 / 1) !important;
}

.tw-accent-neutral-700\/15 {
  accent-color: rgb(64 64 64 / 0.15) !important;
}

.tw-accent-neutral-700\/20 {
  accent-color: rgb(64 64 64 / 0.2) !important;
}

.tw-accent-neutral-700\/25 {
  accent-color: rgb(64 64 64 / 0.25) !important;
}

.tw-accent-neutral-700\/30 {
  accent-color: rgb(64 64 64 / 0.3) !important;
}

.tw-accent-neutral-700\/35 {
  accent-color: rgb(64 64 64 / 0.35) !important;
}

.tw-accent-neutral-700\/40 {
  accent-color: rgb(64 64 64 / 0.4) !important;
}

.tw-accent-neutral-700\/45 {
  accent-color: rgb(64 64 64 / 0.45) !important;
}

.tw-accent-neutral-700\/5 {
  accent-color: rgb(64 64 64 / 0.05) !important;
}

.tw-accent-neutral-700\/50 {
  accent-color: rgb(64 64 64 / 0.5) !important;
}

.tw-accent-neutral-700\/55 {
  accent-color: rgb(64 64 64 / 0.55) !important;
}

.tw-accent-neutral-700\/60 {
  accent-color: rgb(64 64 64 / 0.6) !important;
}

.tw-accent-neutral-700\/65 {
  accent-color: rgb(64 64 64 / 0.65) !important;
}

.tw-accent-neutral-700\/70 {
  accent-color: rgb(64 64 64 / 0.7) !important;
}

.tw-accent-neutral-700\/75 {
  accent-color: rgb(64 64 64 / 0.75) !important;
}

.tw-accent-neutral-700\/80 {
  accent-color: rgb(64 64 64 / 0.8) !important;
}

.tw-accent-neutral-700\/85 {
  accent-color: rgb(64 64 64 / 0.85) !important;
}

.tw-accent-neutral-700\/90 {
  accent-color: rgb(64 64 64 / 0.9) !important;
}

.tw-accent-neutral-700\/95 {
  accent-color: rgb(64 64 64 / 0.95) !important;
}

.tw-accent-neutral-800 {
  accent-color: #262626 !important;
}

.tw-accent-neutral-800\/0 {
  accent-color: rgb(38 38 38 / 0) !important;
}

.tw-accent-neutral-800\/10 {
  accent-color: rgb(38 38 38 / 0.1) !important;
}

.tw-accent-neutral-800\/100 {
  accent-color: rgb(38 38 38 / 1) !important;
}

.tw-accent-neutral-800\/15 {
  accent-color: rgb(38 38 38 / 0.15) !important;
}

.tw-accent-neutral-800\/20 {
  accent-color: rgb(38 38 38 / 0.2) !important;
}

.tw-accent-neutral-800\/25 {
  accent-color: rgb(38 38 38 / 0.25) !important;
}

.tw-accent-neutral-800\/30 {
  accent-color: rgb(38 38 38 / 0.3) !important;
}

.tw-accent-neutral-800\/35 {
  accent-color: rgb(38 38 38 / 0.35) !important;
}

.tw-accent-neutral-800\/40 {
  accent-color: rgb(38 38 38 / 0.4) !important;
}

.tw-accent-neutral-800\/45 {
  accent-color: rgb(38 38 38 / 0.45) !important;
}

.tw-accent-neutral-800\/5 {
  accent-color: rgb(38 38 38 / 0.05) !important;
}

.tw-accent-neutral-800\/50 {
  accent-color: rgb(38 38 38 / 0.5) !important;
}

.tw-accent-neutral-800\/55 {
  accent-color: rgb(38 38 38 / 0.55) !important;
}

.tw-accent-neutral-800\/60 {
  accent-color: rgb(38 38 38 / 0.6) !important;
}

.tw-accent-neutral-800\/65 {
  accent-color: rgb(38 38 38 / 0.65) !important;
}

.tw-accent-neutral-800\/70 {
  accent-color: rgb(38 38 38 / 0.7) !important;
}

.tw-accent-neutral-800\/75 {
  accent-color: rgb(38 38 38 / 0.75) !important;
}

.tw-accent-neutral-800\/80 {
  accent-color: rgb(38 38 38 / 0.8) !important;
}

.tw-accent-neutral-800\/85 {
  accent-color: rgb(38 38 38 / 0.85) !important;
}

.tw-accent-neutral-800\/90 {
  accent-color: rgb(38 38 38 / 0.9) !important;
}

.tw-accent-neutral-800\/95 {
  accent-color: rgb(38 38 38 / 0.95) !important;
}

.tw-accent-neutral-900 {
  accent-color: #171717 !important;
}

.tw-accent-neutral-900\/0 {
  accent-color: rgb(23 23 23 / 0) !important;
}

.tw-accent-neutral-900\/10 {
  accent-color: rgb(23 23 23 / 0.1) !important;
}

.tw-accent-neutral-900\/100 {
  accent-color: rgb(23 23 23 / 1) !important;
}

.tw-accent-neutral-900\/15 {
  accent-color: rgb(23 23 23 / 0.15) !important;
}

.tw-accent-neutral-900\/20 {
  accent-color: rgb(23 23 23 / 0.2) !important;
}

.tw-accent-neutral-900\/25 {
  accent-color: rgb(23 23 23 / 0.25) !important;
}

.tw-accent-neutral-900\/30 {
  accent-color: rgb(23 23 23 / 0.3) !important;
}

.tw-accent-neutral-900\/35 {
  accent-color: rgb(23 23 23 / 0.35) !important;
}

.tw-accent-neutral-900\/40 {
  accent-color: rgb(23 23 23 / 0.4) !important;
}

.tw-accent-neutral-900\/45 {
  accent-color: rgb(23 23 23 / 0.45) !important;
}

.tw-accent-neutral-900\/5 {
  accent-color: rgb(23 23 23 / 0.05) !important;
}

.tw-accent-neutral-900\/50 {
  accent-color: rgb(23 23 23 / 0.5) !important;
}

.tw-accent-neutral-900\/55 {
  accent-color: rgb(23 23 23 / 0.55) !important;
}

.tw-accent-neutral-900\/60 {
  accent-color: rgb(23 23 23 / 0.6) !important;
}

.tw-accent-neutral-900\/65 {
  accent-color: rgb(23 23 23 / 0.65) !important;
}

.tw-accent-neutral-900\/70 {
  accent-color: rgb(23 23 23 / 0.7) !important;
}

.tw-accent-neutral-900\/75 {
  accent-color: rgb(23 23 23 / 0.75) !important;
}

.tw-accent-neutral-900\/80 {
  accent-color: rgb(23 23 23 / 0.8) !important;
}

.tw-accent-neutral-900\/85 {
  accent-color: rgb(23 23 23 / 0.85) !important;
}

.tw-accent-neutral-900\/90 {
  accent-color: rgb(23 23 23 / 0.9) !important;
}

.tw-accent-neutral-900\/95 {
  accent-color: rgb(23 23 23 / 0.95) !important;
}

.tw-accent-neutral-950 {
  accent-color: #0a0a0a !important;
}

.tw-accent-neutral-950\/0 {
  accent-color: rgb(10 10 10 / 0) !important;
}

.tw-accent-neutral-950\/10 {
  accent-color: rgb(10 10 10 / 0.1) !important;
}

.tw-accent-neutral-950\/100 {
  accent-color: rgb(10 10 10 / 1) !important;
}

.tw-accent-neutral-950\/15 {
  accent-color: rgb(10 10 10 / 0.15) !important;
}

.tw-accent-neutral-950\/20 {
  accent-color: rgb(10 10 10 / 0.2) !important;
}

.tw-accent-neutral-950\/25 {
  accent-color: rgb(10 10 10 / 0.25) !important;
}

.tw-accent-neutral-950\/30 {
  accent-color: rgb(10 10 10 / 0.3) !important;
}

.tw-accent-neutral-950\/35 {
  accent-color: rgb(10 10 10 / 0.35) !important;
}

.tw-accent-neutral-950\/40 {
  accent-color: rgb(10 10 10 / 0.4) !important;
}

.tw-accent-neutral-950\/45 {
  accent-color: rgb(10 10 10 / 0.45) !important;
}

.tw-accent-neutral-950\/5 {
  accent-color: rgb(10 10 10 / 0.05) !important;
}

.tw-accent-neutral-950\/50 {
  accent-color: rgb(10 10 10 / 0.5) !important;
}

.tw-accent-neutral-950\/55 {
  accent-color: rgb(10 10 10 / 0.55) !important;
}

.tw-accent-neutral-950\/60 {
  accent-color: rgb(10 10 10 / 0.6) !important;
}

.tw-accent-neutral-950\/65 {
  accent-color: rgb(10 10 10 / 0.65) !important;
}

.tw-accent-neutral-950\/70 {
  accent-color: rgb(10 10 10 / 0.7) !important;
}

.tw-accent-neutral-950\/75 {
  accent-color: rgb(10 10 10 / 0.75) !important;
}

.tw-accent-neutral-950\/80 {
  accent-color: rgb(10 10 10 / 0.8) !important;
}

.tw-accent-neutral-950\/85 {
  accent-color: rgb(10 10 10 / 0.85) !important;
}

.tw-accent-neutral-950\/90 {
  accent-color: rgb(10 10 10 / 0.9) !important;
}

.tw-accent-neutral-950\/95 {
  accent-color: rgb(10 10 10 / 0.95) !important;
}

.tw-accent-orange-100 {
  accent-color: #ffedd5 !important;
}

.tw-accent-orange-100\/0 {
  accent-color: rgb(255 237 213 / 0) !important;
}

.tw-accent-orange-100\/10 {
  accent-color: rgb(255 237 213 / 0.1) !important;
}

.tw-accent-orange-100\/100 {
  accent-color: rgb(255 237 213 / 1) !important;
}

.tw-accent-orange-100\/15 {
  accent-color: rgb(255 237 213 / 0.15) !important;
}

.tw-accent-orange-100\/20 {
  accent-color: rgb(255 237 213 / 0.2) !important;
}

.tw-accent-orange-100\/25 {
  accent-color: rgb(255 237 213 / 0.25) !important;
}

.tw-accent-orange-100\/30 {
  accent-color: rgb(255 237 213 / 0.3) !important;
}

.tw-accent-orange-100\/35 {
  accent-color: rgb(255 237 213 / 0.35) !important;
}

.tw-accent-orange-100\/40 {
  accent-color: rgb(255 237 213 / 0.4) !important;
}

.tw-accent-orange-100\/45 {
  accent-color: rgb(255 237 213 / 0.45) !important;
}

.tw-accent-orange-100\/5 {
  accent-color: rgb(255 237 213 / 0.05) !important;
}

.tw-accent-orange-100\/50 {
  accent-color: rgb(255 237 213 / 0.5) !important;
}

.tw-accent-orange-100\/55 {
  accent-color: rgb(255 237 213 / 0.55) !important;
}

.tw-accent-orange-100\/60 {
  accent-color: rgb(255 237 213 / 0.6) !important;
}

.tw-accent-orange-100\/65 {
  accent-color: rgb(255 237 213 / 0.65) !important;
}

.tw-accent-orange-100\/70 {
  accent-color: rgb(255 237 213 / 0.7) !important;
}

.tw-accent-orange-100\/75 {
  accent-color: rgb(255 237 213 / 0.75) !important;
}

.tw-accent-orange-100\/80 {
  accent-color: rgb(255 237 213 / 0.8) !important;
}

.tw-accent-orange-100\/85 {
  accent-color: rgb(255 237 213 / 0.85) !important;
}

.tw-accent-orange-100\/90 {
  accent-color: rgb(255 237 213 / 0.9) !important;
}

.tw-accent-orange-100\/95 {
  accent-color: rgb(255 237 213 / 0.95) !important;
}

.tw-accent-orange-200 {
  accent-color: #fed7aa !important;
}

.tw-accent-orange-200\/0 {
  accent-color: rgb(254 215 170 / 0) !important;
}

.tw-accent-orange-200\/10 {
  accent-color: rgb(254 215 170 / 0.1) !important;
}

.tw-accent-orange-200\/100 {
  accent-color: rgb(254 215 170 / 1) !important;
}

.tw-accent-orange-200\/15 {
  accent-color: rgb(254 215 170 / 0.15) !important;
}

.tw-accent-orange-200\/20 {
  accent-color: rgb(254 215 170 / 0.2) !important;
}

.tw-accent-orange-200\/25 {
  accent-color: rgb(254 215 170 / 0.25) !important;
}

.tw-accent-orange-200\/30 {
  accent-color: rgb(254 215 170 / 0.3) !important;
}

.tw-accent-orange-200\/35 {
  accent-color: rgb(254 215 170 / 0.35) !important;
}

.tw-accent-orange-200\/40 {
  accent-color: rgb(254 215 170 / 0.4) !important;
}

.tw-accent-orange-200\/45 {
  accent-color: rgb(254 215 170 / 0.45) !important;
}

.tw-accent-orange-200\/5 {
  accent-color: rgb(254 215 170 / 0.05) !important;
}

.tw-accent-orange-200\/50 {
  accent-color: rgb(254 215 170 / 0.5) !important;
}

.tw-accent-orange-200\/55 {
  accent-color: rgb(254 215 170 / 0.55) !important;
}

.tw-accent-orange-200\/60 {
  accent-color: rgb(254 215 170 / 0.6) !important;
}

.tw-accent-orange-200\/65 {
  accent-color: rgb(254 215 170 / 0.65) !important;
}

.tw-accent-orange-200\/70 {
  accent-color: rgb(254 215 170 / 0.7) !important;
}

.tw-accent-orange-200\/75 {
  accent-color: rgb(254 215 170 / 0.75) !important;
}

.tw-accent-orange-200\/80 {
  accent-color: rgb(254 215 170 / 0.8) !important;
}

.tw-accent-orange-200\/85 {
  accent-color: rgb(254 215 170 / 0.85) !important;
}

.tw-accent-orange-200\/90 {
  accent-color: rgb(254 215 170 / 0.9) !important;
}

.tw-accent-orange-200\/95 {
  accent-color: rgb(254 215 170 / 0.95) !important;
}

.tw-accent-orange-300 {
  accent-color: #fdba74 !important;
}

.tw-accent-orange-300\/0 {
  accent-color: rgb(253 186 116 / 0) !important;
}

.tw-accent-orange-300\/10 {
  accent-color: rgb(253 186 116 / 0.1) !important;
}

.tw-accent-orange-300\/100 {
  accent-color: rgb(253 186 116 / 1) !important;
}

.tw-accent-orange-300\/15 {
  accent-color: rgb(253 186 116 / 0.15) !important;
}

.tw-accent-orange-300\/20 {
  accent-color: rgb(253 186 116 / 0.2) !important;
}

.tw-accent-orange-300\/25 {
  accent-color: rgb(253 186 116 / 0.25) !important;
}

.tw-accent-orange-300\/30 {
  accent-color: rgb(253 186 116 / 0.3) !important;
}

.tw-accent-orange-300\/35 {
  accent-color: rgb(253 186 116 / 0.35) !important;
}

.tw-accent-orange-300\/40 {
  accent-color: rgb(253 186 116 / 0.4) !important;
}

.tw-accent-orange-300\/45 {
  accent-color: rgb(253 186 116 / 0.45) !important;
}

.tw-accent-orange-300\/5 {
  accent-color: rgb(253 186 116 / 0.05) !important;
}

.tw-accent-orange-300\/50 {
  accent-color: rgb(253 186 116 / 0.5) !important;
}

.tw-accent-orange-300\/55 {
  accent-color: rgb(253 186 116 / 0.55) !important;
}

.tw-accent-orange-300\/60 {
  accent-color: rgb(253 186 116 / 0.6) !important;
}

.tw-accent-orange-300\/65 {
  accent-color: rgb(253 186 116 / 0.65) !important;
}

.tw-accent-orange-300\/70 {
  accent-color: rgb(253 186 116 / 0.7) !important;
}

.tw-accent-orange-300\/75 {
  accent-color: rgb(253 186 116 / 0.75) !important;
}

.tw-accent-orange-300\/80 {
  accent-color: rgb(253 186 116 / 0.8) !important;
}

.tw-accent-orange-300\/85 {
  accent-color: rgb(253 186 116 / 0.85) !important;
}

.tw-accent-orange-300\/90 {
  accent-color: rgb(253 186 116 / 0.9) !important;
}

.tw-accent-orange-300\/95 {
  accent-color: rgb(253 186 116 / 0.95) !important;
}

.tw-accent-orange-400 {
  accent-color: #fb923c !important;
}

.tw-accent-orange-400\/0 {
  accent-color: rgb(251 146 60 / 0) !important;
}

.tw-accent-orange-400\/10 {
  accent-color: rgb(251 146 60 / 0.1) !important;
}

.tw-accent-orange-400\/100 {
  accent-color: rgb(251 146 60 / 1) !important;
}

.tw-accent-orange-400\/15 {
  accent-color: rgb(251 146 60 / 0.15) !important;
}

.tw-accent-orange-400\/20 {
  accent-color: rgb(251 146 60 / 0.2) !important;
}

.tw-accent-orange-400\/25 {
  accent-color: rgb(251 146 60 / 0.25) !important;
}

.tw-accent-orange-400\/30 {
  accent-color: rgb(251 146 60 / 0.3) !important;
}

.tw-accent-orange-400\/35 {
  accent-color: rgb(251 146 60 / 0.35) !important;
}

.tw-accent-orange-400\/40 {
  accent-color: rgb(251 146 60 / 0.4) !important;
}

.tw-accent-orange-400\/45 {
  accent-color: rgb(251 146 60 / 0.45) !important;
}

.tw-accent-orange-400\/5 {
  accent-color: rgb(251 146 60 / 0.05) !important;
}

.tw-accent-orange-400\/50 {
  accent-color: rgb(251 146 60 / 0.5) !important;
}

.tw-accent-orange-400\/55 {
  accent-color: rgb(251 146 60 / 0.55) !important;
}

.tw-accent-orange-400\/60 {
  accent-color: rgb(251 146 60 / 0.6) !important;
}

.tw-accent-orange-400\/65 {
  accent-color: rgb(251 146 60 / 0.65) !important;
}

.tw-accent-orange-400\/70 {
  accent-color: rgb(251 146 60 / 0.7) !important;
}

.tw-accent-orange-400\/75 {
  accent-color: rgb(251 146 60 / 0.75) !important;
}

.tw-accent-orange-400\/80 {
  accent-color: rgb(251 146 60 / 0.8) !important;
}

.tw-accent-orange-400\/85 {
  accent-color: rgb(251 146 60 / 0.85) !important;
}

.tw-accent-orange-400\/90 {
  accent-color: rgb(251 146 60 / 0.9) !important;
}

.tw-accent-orange-400\/95 {
  accent-color: rgb(251 146 60 / 0.95) !important;
}

.tw-accent-orange-50 {
  accent-color: #fff7ed !important;
}

.tw-accent-orange-50\/0 {
  accent-color: rgb(255 247 237 / 0) !important;
}

.tw-accent-orange-50\/10 {
  accent-color: rgb(255 247 237 / 0.1) !important;
}

.tw-accent-orange-50\/100 {
  accent-color: rgb(255 247 237 / 1) !important;
}

.tw-accent-orange-50\/15 {
  accent-color: rgb(255 247 237 / 0.15) !important;
}

.tw-accent-orange-50\/20 {
  accent-color: rgb(255 247 237 / 0.2) !important;
}

.tw-accent-orange-50\/25 {
  accent-color: rgb(255 247 237 / 0.25) !important;
}

.tw-accent-orange-50\/30 {
  accent-color: rgb(255 247 237 / 0.3) !important;
}

.tw-accent-orange-50\/35 {
  accent-color: rgb(255 247 237 / 0.35) !important;
}

.tw-accent-orange-50\/40 {
  accent-color: rgb(255 247 237 / 0.4) !important;
}

.tw-accent-orange-50\/45 {
  accent-color: rgb(255 247 237 / 0.45) !important;
}

.tw-accent-orange-50\/5 {
  accent-color: rgb(255 247 237 / 0.05) !important;
}

.tw-accent-orange-50\/50 {
  accent-color: rgb(255 247 237 / 0.5) !important;
}

.tw-accent-orange-50\/55 {
  accent-color: rgb(255 247 237 / 0.55) !important;
}

.tw-accent-orange-50\/60 {
  accent-color: rgb(255 247 237 / 0.6) !important;
}

.tw-accent-orange-50\/65 {
  accent-color: rgb(255 247 237 / 0.65) !important;
}

.tw-accent-orange-50\/70 {
  accent-color: rgb(255 247 237 / 0.7) !important;
}

.tw-accent-orange-50\/75 {
  accent-color: rgb(255 247 237 / 0.75) !important;
}

.tw-accent-orange-50\/80 {
  accent-color: rgb(255 247 237 / 0.8) !important;
}

.tw-accent-orange-50\/85 {
  accent-color: rgb(255 247 237 / 0.85) !important;
}

.tw-accent-orange-50\/90 {
  accent-color: rgb(255 247 237 / 0.9) !important;
}

.tw-accent-orange-50\/95 {
  accent-color: rgb(255 247 237 / 0.95) !important;
}

.tw-accent-orange-500 {
  accent-color: #f97316 !important;
}

.tw-accent-orange-500\/0 {
  accent-color: rgb(249 115 22 / 0) !important;
}

.tw-accent-orange-500\/10 {
  accent-color: rgb(249 115 22 / 0.1) !important;
}

.tw-accent-orange-500\/100 {
  accent-color: rgb(249 115 22 / 1) !important;
}

.tw-accent-orange-500\/15 {
  accent-color: rgb(249 115 22 / 0.15) !important;
}

.tw-accent-orange-500\/20 {
  accent-color: rgb(249 115 22 / 0.2) !important;
}

.tw-accent-orange-500\/25 {
  accent-color: rgb(249 115 22 / 0.25) !important;
}

.tw-accent-orange-500\/30 {
  accent-color: rgb(249 115 22 / 0.3) !important;
}

.tw-accent-orange-500\/35 {
  accent-color: rgb(249 115 22 / 0.35) !important;
}

.tw-accent-orange-500\/40 {
  accent-color: rgb(249 115 22 / 0.4) !important;
}

.tw-accent-orange-500\/45 {
  accent-color: rgb(249 115 22 / 0.45) !important;
}

.tw-accent-orange-500\/5 {
  accent-color: rgb(249 115 22 / 0.05) !important;
}

.tw-accent-orange-500\/50 {
  accent-color: rgb(249 115 22 / 0.5) !important;
}

.tw-accent-orange-500\/55 {
  accent-color: rgb(249 115 22 / 0.55) !important;
}

.tw-accent-orange-500\/60 {
  accent-color: rgb(249 115 22 / 0.6) !important;
}

.tw-accent-orange-500\/65 {
  accent-color: rgb(249 115 22 / 0.65) !important;
}

.tw-accent-orange-500\/70 {
  accent-color: rgb(249 115 22 / 0.7) !important;
}

.tw-accent-orange-500\/75 {
  accent-color: rgb(249 115 22 / 0.75) !important;
}

.tw-accent-orange-500\/80 {
  accent-color: rgb(249 115 22 / 0.8) !important;
}

.tw-accent-orange-500\/85 {
  accent-color: rgb(249 115 22 / 0.85) !important;
}

.tw-accent-orange-500\/90 {
  accent-color: rgb(249 115 22 / 0.9) !important;
}

.tw-accent-orange-500\/95 {
  accent-color: rgb(249 115 22 / 0.95) !important;
}

.tw-accent-orange-600 {
  accent-color: #ea580c !important;
}

.tw-accent-orange-600\/0 {
  accent-color: rgb(234 88 12 / 0) !important;
}

.tw-accent-orange-600\/10 {
  accent-color: rgb(234 88 12 / 0.1) !important;
}

.tw-accent-orange-600\/100 {
  accent-color: rgb(234 88 12 / 1) !important;
}

.tw-accent-orange-600\/15 {
  accent-color: rgb(234 88 12 / 0.15) !important;
}

.tw-accent-orange-600\/20 {
  accent-color: rgb(234 88 12 / 0.2) !important;
}

.tw-accent-orange-600\/25 {
  accent-color: rgb(234 88 12 / 0.25) !important;
}

.tw-accent-orange-600\/30 {
  accent-color: rgb(234 88 12 / 0.3) !important;
}

.tw-accent-orange-600\/35 {
  accent-color: rgb(234 88 12 / 0.35) !important;
}

.tw-accent-orange-600\/40 {
  accent-color: rgb(234 88 12 / 0.4) !important;
}

.tw-accent-orange-600\/45 {
  accent-color: rgb(234 88 12 / 0.45) !important;
}

.tw-accent-orange-600\/5 {
  accent-color: rgb(234 88 12 / 0.05) !important;
}

.tw-accent-orange-600\/50 {
  accent-color: rgb(234 88 12 / 0.5) !important;
}

.tw-accent-orange-600\/55 {
  accent-color: rgb(234 88 12 / 0.55) !important;
}

.tw-accent-orange-600\/60 {
  accent-color: rgb(234 88 12 / 0.6) !important;
}

.tw-accent-orange-600\/65 {
  accent-color: rgb(234 88 12 / 0.65) !important;
}

.tw-accent-orange-600\/70 {
  accent-color: rgb(234 88 12 / 0.7) !important;
}

.tw-accent-orange-600\/75 {
  accent-color: rgb(234 88 12 / 0.75) !important;
}

.tw-accent-orange-600\/80 {
  accent-color: rgb(234 88 12 / 0.8) !important;
}

.tw-accent-orange-600\/85 {
  accent-color: rgb(234 88 12 / 0.85) !important;
}

.tw-accent-orange-600\/90 {
  accent-color: rgb(234 88 12 / 0.9) !important;
}

.tw-accent-orange-600\/95 {
  accent-color: rgb(234 88 12 / 0.95) !important;
}

.tw-accent-orange-700 {
  accent-color: #c2410c !important;
}

.tw-accent-orange-700\/0 {
  accent-color: rgb(194 65 12 / 0) !important;
}

.tw-accent-orange-700\/10 {
  accent-color: rgb(194 65 12 / 0.1) !important;
}

.tw-accent-orange-700\/100 {
  accent-color: rgb(194 65 12 / 1) !important;
}

.tw-accent-orange-700\/15 {
  accent-color: rgb(194 65 12 / 0.15) !important;
}

.tw-accent-orange-700\/20 {
  accent-color: rgb(194 65 12 / 0.2) !important;
}

.tw-accent-orange-700\/25 {
  accent-color: rgb(194 65 12 / 0.25) !important;
}

.tw-accent-orange-700\/30 {
  accent-color: rgb(194 65 12 / 0.3) !important;
}

.tw-accent-orange-700\/35 {
  accent-color: rgb(194 65 12 / 0.35) !important;
}

.tw-accent-orange-700\/40 {
  accent-color: rgb(194 65 12 / 0.4) !important;
}

.tw-accent-orange-700\/45 {
  accent-color: rgb(194 65 12 / 0.45) !important;
}

.tw-accent-orange-700\/5 {
  accent-color: rgb(194 65 12 / 0.05) !important;
}

.tw-accent-orange-700\/50 {
  accent-color: rgb(194 65 12 / 0.5) !important;
}

.tw-accent-orange-700\/55 {
  accent-color: rgb(194 65 12 / 0.55) !important;
}

.tw-accent-orange-700\/60 {
  accent-color: rgb(194 65 12 / 0.6) !important;
}

.tw-accent-orange-700\/65 {
  accent-color: rgb(194 65 12 / 0.65) !important;
}

.tw-accent-orange-700\/70 {
  accent-color: rgb(194 65 12 / 0.7) !important;
}

.tw-accent-orange-700\/75 {
  accent-color: rgb(194 65 12 / 0.75) !important;
}

.tw-accent-orange-700\/80 {
  accent-color: rgb(194 65 12 / 0.8) !important;
}

.tw-accent-orange-700\/85 {
  accent-color: rgb(194 65 12 / 0.85) !important;
}

.tw-accent-orange-700\/90 {
  accent-color: rgb(194 65 12 / 0.9) !important;
}

.tw-accent-orange-700\/95 {
  accent-color: rgb(194 65 12 / 0.95) !important;
}

.tw-accent-orange-800 {
  accent-color: #9a3412 !important;
}

.tw-accent-orange-800\/0 {
  accent-color: rgb(154 52 18 / 0) !important;
}

.tw-accent-orange-800\/10 {
  accent-color: rgb(154 52 18 / 0.1) !important;
}

.tw-accent-orange-800\/100 {
  accent-color: rgb(154 52 18 / 1) !important;
}

.tw-accent-orange-800\/15 {
  accent-color: rgb(154 52 18 / 0.15) !important;
}

.tw-accent-orange-800\/20 {
  accent-color: rgb(154 52 18 / 0.2) !important;
}

.tw-accent-orange-800\/25 {
  accent-color: rgb(154 52 18 / 0.25) !important;
}

.tw-accent-orange-800\/30 {
  accent-color: rgb(154 52 18 / 0.3) !important;
}

.tw-accent-orange-800\/35 {
  accent-color: rgb(154 52 18 / 0.35) !important;
}

.tw-accent-orange-800\/40 {
  accent-color: rgb(154 52 18 / 0.4) !important;
}

.tw-accent-orange-800\/45 {
  accent-color: rgb(154 52 18 / 0.45) !important;
}

.tw-accent-orange-800\/5 {
  accent-color: rgb(154 52 18 / 0.05) !important;
}

.tw-accent-orange-800\/50 {
  accent-color: rgb(154 52 18 / 0.5) !important;
}

.tw-accent-orange-800\/55 {
  accent-color: rgb(154 52 18 / 0.55) !important;
}

.tw-accent-orange-800\/60 {
  accent-color: rgb(154 52 18 / 0.6) !important;
}

.tw-accent-orange-800\/65 {
  accent-color: rgb(154 52 18 / 0.65) !important;
}

.tw-accent-orange-800\/70 {
  accent-color: rgb(154 52 18 / 0.7) !important;
}

.tw-accent-orange-800\/75 {
  accent-color: rgb(154 52 18 / 0.75) !important;
}

.tw-accent-orange-800\/80 {
  accent-color: rgb(154 52 18 / 0.8) !important;
}

.tw-accent-orange-800\/85 {
  accent-color: rgb(154 52 18 / 0.85) !important;
}

.tw-accent-orange-800\/90 {
  accent-color: rgb(154 52 18 / 0.9) !important;
}

.tw-accent-orange-800\/95 {
  accent-color: rgb(154 52 18 / 0.95) !important;
}

.tw-accent-orange-900 {
  accent-color: #7c2d12 !important;
}

.tw-accent-orange-900\/0 {
  accent-color: rgb(124 45 18 / 0) !important;
}

.tw-accent-orange-900\/10 {
  accent-color: rgb(124 45 18 / 0.1) !important;
}

.tw-accent-orange-900\/100 {
  accent-color: rgb(124 45 18 / 1) !important;
}

.tw-accent-orange-900\/15 {
  accent-color: rgb(124 45 18 / 0.15) !important;
}

.tw-accent-orange-900\/20 {
  accent-color: rgb(124 45 18 / 0.2) !important;
}

.tw-accent-orange-900\/25 {
  accent-color: rgb(124 45 18 / 0.25) !important;
}

.tw-accent-orange-900\/30 {
  accent-color: rgb(124 45 18 / 0.3) !important;
}

.tw-accent-orange-900\/35 {
  accent-color: rgb(124 45 18 / 0.35) !important;
}

.tw-accent-orange-900\/40 {
  accent-color: rgb(124 45 18 / 0.4) !important;
}

.tw-accent-orange-900\/45 {
  accent-color: rgb(124 45 18 / 0.45) !important;
}

.tw-accent-orange-900\/5 {
  accent-color: rgb(124 45 18 / 0.05) !important;
}

.tw-accent-orange-900\/50 {
  accent-color: rgb(124 45 18 / 0.5) !important;
}

.tw-accent-orange-900\/55 {
  accent-color: rgb(124 45 18 / 0.55) !important;
}

.tw-accent-orange-900\/60 {
  accent-color: rgb(124 45 18 / 0.6) !important;
}

.tw-accent-orange-900\/65 {
  accent-color: rgb(124 45 18 / 0.65) !important;
}

.tw-accent-orange-900\/70 {
  accent-color: rgb(124 45 18 / 0.7) !important;
}

.tw-accent-orange-900\/75 {
  accent-color: rgb(124 45 18 / 0.75) !important;
}

.tw-accent-orange-900\/80 {
  accent-color: rgb(124 45 18 / 0.8) !important;
}

.tw-accent-orange-900\/85 {
  accent-color: rgb(124 45 18 / 0.85) !important;
}

.tw-accent-orange-900\/90 {
  accent-color: rgb(124 45 18 / 0.9) !important;
}

.tw-accent-orange-900\/95 {
  accent-color: rgb(124 45 18 / 0.95) !important;
}

.tw-accent-orange-950 {
  accent-color: #431407 !important;
}

.tw-accent-orange-950\/0 {
  accent-color: rgb(67 20 7 / 0) !important;
}

.tw-accent-orange-950\/10 {
  accent-color: rgb(67 20 7 / 0.1) !important;
}

.tw-accent-orange-950\/100 {
  accent-color: rgb(67 20 7 / 1) !important;
}

.tw-accent-orange-950\/15 {
  accent-color: rgb(67 20 7 / 0.15) !important;
}

.tw-accent-orange-950\/20 {
  accent-color: rgb(67 20 7 / 0.2) !important;
}

.tw-accent-orange-950\/25 {
  accent-color: rgb(67 20 7 / 0.25) !important;
}

.tw-accent-orange-950\/30 {
  accent-color: rgb(67 20 7 / 0.3) !important;
}

.tw-accent-orange-950\/35 {
  accent-color: rgb(67 20 7 / 0.35) !important;
}

.tw-accent-orange-950\/40 {
  accent-color: rgb(67 20 7 / 0.4) !important;
}

.tw-accent-orange-950\/45 {
  accent-color: rgb(67 20 7 / 0.45) !important;
}

.tw-accent-orange-950\/5 {
  accent-color: rgb(67 20 7 / 0.05) !important;
}

.tw-accent-orange-950\/50 {
  accent-color: rgb(67 20 7 / 0.5) !important;
}

.tw-accent-orange-950\/55 {
  accent-color: rgb(67 20 7 / 0.55) !important;
}

.tw-accent-orange-950\/60 {
  accent-color: rgb(67 20 7 / 0.6) !important;
}

.tw-accent-orange-950\/65 {
  accent-color: rgb(67 20 7 / 0.65) !important;
}

.tw-accent-orange-950\/70 {
  accent-color: rgb(67 20 7 / 0.7) !important;
}

.tw-accent-orange-950\/75 {
  accent-color: rgb(67 20 7 / 0.75) !important;
}

.tw-accent-orange-950\/80 {
  accent-color: rgb(67 20 7 / 0.8) !important;
}

.tw-accent-orange-950\/85 {
  accent-color: rgb(67 20 7 / 0.85) !important;
}

.tw-accent-orange-950\/90 {
  accent-color: rgb(67 20 7 / 0.9) !important;
}

.tw-accent-orange-950\/95 {
  accent-color: rgb(67 20 7 / 0.95) !important;
}

.tw-accent-pink-100 {
  accent-color: #fce7f3 !important;
}

.tw-accent-pink-100\/0 {
  accent-color: rgb(252 231 243 / 0) !important;
}

.tw-accent-pink-100\/10 {
  accent-color: rgb(252 231 243 / 0.1) !important;
}

.tw-accent-pink-100\/100 {
  accent-color: rgb(252 231 243 / 1) !important;
}

.tw-accent-pink-100\/15 {
  accent-color: rgb(252 231 243 / 0.15) !important;
}

.tw-accent-pink-100\/20 {
  accent-color: rgb(252 231 243 / 0.2) !important;
}

.tw-accent-pink-100\/25 {
  accent-color: rgb(252 231 243 / 0.25) !important;
}

.tw-accent-pink-100\/30 {
  accent-color: rgb(252 231 243 / 0.3) !important;
}

.tw-accent-pink-100\/35 {
  accent-color: rgb(252 231 243 / 0.35) !important;
}

.tw-accent-pink-100\/40 {
  accent-color: rgb(252 231 243 / 0.4) !important;
}

.tw-accent-pink-100\/45 {
  accent-color: rgb(252 231 243 / 0.45) !important;
}

.tw-accent-pink-100\/5 {
  accent-color: rgb(252 231 243 / 0.05) !important;
}

.tw-accent-pink-100\/50 {
  accent-color: rgb(252 231 243 / 0.5) !important;
}

.tw-accent-pink-100\/55 {
  accent-color: rgb(252 231 243 / 0.55) !important;
}

.tw-accent-pink-100\/60 {
  accent-color: rgb(252 231 243 / 0.6) !important;
}

.tw-accent-pink-100\/65 {
  accent-color: rgb(252 231 243 / 0.65) !important;
}

.tw-accent-pink-100\/70 {
  accent-color: rgb(252 231 243 / 0.7) !important;
}

.tw-accent-pink-100\/75 {
  accent-color: rgb(252 231 243 / 0.75) !important;
}

.tw-accent-pink-100\/80 {
  accent-color: rgb(252 231 243 / 0.8) !important;
}

.tw-accent-pink-100\/85 {
  accent-color: rgb(252 231 243 / 0.85) !important;
}

.tw-accent-pink-100\/90 {
  accent-color: rgb(252 231 243 / 0.9) !important;
}

.tw-accent-pink-100\/95 {
  accent-color: rgb(252 231 243 / 0.95) !important;
}

.tw-accent-pink-200 {
  accent-color: #fbcfe8 !important;
}

.tw-accent-pink-200\/0 {
  accent-color: rgb(251 207 232 / 0) !important;
}

.tw-accent-pink-200\/10 {
  accent-color: rgb(251 207 232 / 0.1) !important;
}

.tw-accent-pink-200\/100 {
  accent-color: rgb(251 207 232 / 1) !important;
}

.tw-accent-pink-200\/15 {
  accent-color: rgb(251 207 232 / 0.15) !important;
}

.tw-accent-pink-200\/20 {
  accent-color: rgb(251 207 232 / 0.2) !important;
}

.tw-accent-pink-200\/25 {
  accent-color: rgb(251 207 232 / 0.25) !important;
}

.tw-accent-pink-200\/30 {
  accent-color: rgb(251 207 232 / 0.3) !important;
}

.tw-accent-pink-200\/35 {
  accent-color: rgb(251 207 232 / 0.35) !important;
}

.tw-accent-pink-200\/40 {
  accent-color: rgb(251 207 232 / 0.4) !important;
}

.tw-accent-pink-200\/45 {
  accent-color: rgb(251 207 232 / 0.45) !important;
}

.tw-accent-pink-200\/5 {
  accent-color: rgb(251 207 232 / 0.05) !important;
}

.tw-accent-pink-200\/50 {
  accent-color: rgb(251 207 232 / 0.5) !important;
}

.tw-accent-pink-200\/55 {
  accent-color: rgb(251 207 232 / 0.55) !important;
}

.tw-accent-pink-200\/60 {
  accent-color: rgb(251 207 232 / 0.6) !important;
}

.tw-accent-pink-200\/65 {
  accent-color: rgb(251 207 232 / 0.65) !important;
}

.tw-accent-pink-200\/70 {
  accent-color: rgb(251 207 232 / 0.7) !important;
}

.tw-accent-pink-200\/75 {
  accent-color: rgb(251 207 232 / 0.75) !important;
}

.tw-accent-pink-200\/80 {
  accent-color: rgb(251 207 232 / 0.8) !important;
}

.tw-accent-pink-200\/85 {
  accent-color: rgb(251 207 232 / 0.85) !important;
}

.tw-accent-pink-200\/90 {
  accent-color: rgb(251 207 232 / 0.9) !important;
}

.tw-accent-pink-200\/95 {
  accent-color: rgb(251 207 232 / 0.95) !important;
}

.tw-accent-pink-300 {
  accent-color: #f9a8d4 !important;
}

.tw-accent-pink-300\/0 {
  accent-color: rgb(249 168 212 / 0) !important;
}

.tw-accent-pink-300\/10 {
  accent-color: rgb(249 168 212 / 0.1) !important;
}

.tw-accent-pink-300\/100 {
  accent-color: rgb(249 168 212 / 1) !important;
}

.tw-accent-pink-300\/15 {
  accent-color: rgb(249 168 212 / 0.15) !important;
}

.tw-accent-pink-300\/20 {
  accent-color: rgb(249 168 212 / 0.2) !important;
}

.tw-accent-pink-300\/25 {
  accent-color: rgb(249 168 212 / 0.25) !important;
}

.tw-accent-pink-300\/30 {
  accent-color: rgb(249 168 212 / 0.3) !important;
}

.tw-accent-pink-300\/35 {
  accent-color: rgb(249 168 212 / 0.35) !important;
}

.tw-accent-pink-300\/40 {
  accent-color: rgb(249 168 212 / 0.4) !important;
}

.tw-accent-pink-300\/45 {
  accent-color: rgb(249 168 212 / 0.45) !important;
}

.tw-accent-pink-300\/5 {
  accent-color: rgb(249 168 212 / 0.05) !important;
}

.tw-accent-pink-300\/50 {
  accent-color: rgb(249 168 212 / 0.5) !important;
}

.tw-accent-pink-300\/55 {
  accent-color: rgb(249 168 212 / 0.55) !important;
}

.tw-accent-pink-300\/60 {
  accent-color: rgb(249 168 212 / 0.6) !important;
}

.tw-accent-pink-300\/65 {
  accent-color: rgb(249 168 212 / 0.65) !important;
}

.tw-accent-pink-300\/70 {
  accent-color: rgb(249 168 212 / 0.7) !important;
}

.tw-accent-pink-300\/75 {
  accent-color: rgb(249 168 212 / 0.75) !important;
}

.tw-accent-pink-300\/80 {
  accent-color: rgb(249 168 212 / 0.8) !important;
}

.tw-accent-pink-300\/85 {
  accent-color: rgb(249 168 212 / 0.85) !important;
}

.tw-accent-pink-300\/90 {
  accent-color: rgb(249 168 212 / 0.9) !important;
}

.tw-accent-pink-300\/95 {
  accent-color: rgb(249 168 212 / 0.95) !important;
}

.tw-accent-pink-400 {
  accent-color: #f472b6 !important;
}

.tw-accent-pink-400\/0 {
  accent-color: rgb(244 114 182 / 0) !important;
}

.tw-accent-pink-400\/10 {
  accent-color: rgb(244 114 182 / 0.1) !important;
}

.tw-accent-pink-400\/100 {
  accent-color: rgb(244 114 182 / 1) !important;
}

.tw-accent-pink-400\/15 {
  accent-color: rgb(244 114 182 / 0.15) !important;
}

.tw-accent-pink-400\/20 {
  accent-color: rgb(244 114 182 / 0.2) !important;
}

.tw-accent-pink-400\/25 {
  accent-color: rgb(244 114 182 / 0.25) !important;
}

.tw-accent-pink-400\/30 {
  accent-color: rgb(244 114 182 / 0.3) !important;
}

.tw-accent-pink-400\/35 {
  accent-color: rgb(244 114 182 / 0.35) !important;
}

.tw-accent-pink-400\/40 {
  accent-color: rgb(244 114 182 / 0.4) !important;
}

.tw-accent-pink-400\/45 {
  accent-color: rgb(244 114 182 / 0.45) !important;
}

.tw-accent-pink-400\/5 {
  accent-color: rgb(244 114 182 / 0.05) !important;
}

.tw-accent-pink-400\/50 {
  accent-color: rgb(244 114 182 / 0.5) !important;
}

.tw-accent-pink-400\/55 {
  accent-color: rgb(244 114 182 / 0.55) !important;
}

.tw-accent-pink-400\/60 {
  accent-color: rgb(244 114 182 / 0.6) !important;
}

.tw-accent-pink-400\/65 {
  accent-color: rgb(244 114 182 / 0.65) !important;
}

.tw-accent-pink-400\/70 {
  accent-color: rgb(244 114 182 / 0.7) !important;
}

.tw-accent-pink-400\/75 {
  accent-color: rgb(244 114 182 / 0.75) !important;
}

.tw-accent-pink-400\/80 {
  accent-color: rgb(244 114 182 / 0.8) !important;
}

.tw-accent-pink-400\/85 {
  accent-color: rgb(244 114 182 / 0.85) !important;
}

.tw-accent-pink-400\/90 {
  accent-color: rgb(244 114 182 / 0.9) !important;
}

.tw-accent-pink-400\/95 {
  accent-color: rgb(244 114 182 / 0.95) !important;
}

.tw-accent-pink-50 {
  accent-color: #fdf2f8 !important;
}

.tw-accent-pink-50\/0 {
  accent-color: rgb(253 242 248 / 0) !important;
}

.tw-accent-pink-50\/10 {
  accent-color: rgb(253 242 248 / 0.1) !important;
}

.tw-accent-pink-50\/100 {
  accent-color: rgb(253 242 248 / 1) !important;
}

.tw-accent-pink-50\/15 {
  accent-color: rgb(253 242 248 / 0.15) !important;
}

.tw-accent-pink-50\/20 {
  accent-color: rgb(253 242 248 / 0.2) !important;
}

.tw-accent-pink-50\/25 {
  accent-color: rgb(253 242 248 / 0.25) !important;
}

.tw-accent-pink-50\/30 {
  accent-color: rgb(253 242 248 / 0.3) !important;
}

.tw-accent-pink-50\/35 {
  accent-color: rgb(253 242 248 / 0.35) !important;
}

.tw-accent-pink-50\/40 {
  accent-color: rgb(253 242 248 / 0.4) !important;
}

.tw-accent-pink-50\/45 {
  accent-color: rgb(253 242 248 / 0.45) !important;
}

.tw-accent-pink-50\/5 {
  accent-color: rgb(253 242 248 / 0.05) !important;
}

.tw-accent-pink-50\/50 {
  accent-color: rgb(253 242 248 / 0.5) !important;
}

.tw-accent-pink-50\/55 {
  accent-color: rgb(253 242 248 / 0.55) !important;
}

.tw-accent-pink-50\/60 {
  accent-color: rgb(253 242 248 / 0.6) !important;
}

.tw-accent-pink-50\/65 {
  accent-color: rgb(253 242 248 / 0.65) !important;
}

.tw-accent-pink-50\/70 {
  accent-color: rgb(253 242 248 / 0.7) !important;
}

.tw-accent-pink-50\/75 {
  accent-color: rgb(253 242 248 / 0.75) !important;
}

.tw-accent-pink-50\/80 {
  accent-color: rgb(253 242 248 / 0.8) !important;
}

.tw-accent-pink-50\/85 {
  accent-color: rgb(253 242 248 / 0.85) !important;
}

.tw-accent-pink-50\/90 {
  accent-color: rgb(253 242 248 / 0.9) !important;
}

.tw-accent-pink-50\/95 {
  accent-color: rgb(253 242 248 / 0.95) !important;
}

.tw-accent-pink-500 {
  accent-color: #ec4899 !important;
}

.tw-accent-pink-500\/0 {
  accent-color: rgb(236 72 153 / 0) !important;
}

.tw-accent-pink-500\/10 {
  accent-color: rgb(236 72 153 / 0.1) !important;
}

.tw-accent-pink-500\/100 {
  accent-color: rgb(236 72 153 / 1) !important;
}

.tw-accent-pink-500\/15 {
  accent-color: rgb(236 72 153 / 0.15) !important;
}

.tw-accent-pink-500\/20 {
  accent-color: rgb(236 72 153 / 0.2) !important;
}

.tw-accent-pink-500\/25 {
  accent-color: rgb(236 72 153 / 0.25) !important;
}

.tw-accent-pink-500\/30 {
  accent-color: rgb(236 72 153 / 0.3) !important;
}

.tw-accent-pink-500\/35 {
  accent-color: rgb(236 72 153 / 0.35) !important;
}

.tw-accent-pink-500\/40 {
  accent-color: rgb(236 72 153 / 0.4) !important;
}

.tw-accent-pink-500\/45 {
  accent-color: rgb(236 72 153 / 0.45) !important;
}

.tw-accent-pink-500\/5 {
  accent-color: rgb(236 72 153 / 0.05) !important;
}

.tw-accent-pink-500\/50 {
  accent-color: rgb(236 72 153 / 0.5) !important;
}

.tw-accent-pink-500\/55 {
  accent-color: rgb(236 72 153 / 0.55) !important;
}

.tw-accent-pink-500\/60 {
  accent-color: rgb(236 72 153 / 0.6) !important;
}

.tw-accent-pink-500\/65 {
  accent-color: rgb(236 72 153 / 0.65) !important;
}

.tw-accent-pink-500\/70 {
  accent-color: rgb(236 72 153 / 0.7) !important;
}

.tw-accent-pink-500\/75 {
  accent-color: rgb(236 72 153 / 0.75) !important;
}

.tw-accent-pink-500\/80 {
  accent-color: rgb(236 72 153 / 0.8) !important;
}

.tw-accent-pink-500\/85 {
  accent-color: rgb(236 72 153 / 0.85) !important;
}

.tw-accent-pink-500\/90 {
  accent-color: rgb(236 72 153 / 0.9) !important;
}

.tw-accent-pink-500\/95 {
  accent-color: rgb(236 72 153 / 0.95) !important;
}

.tw-accent-pink-600 {
  accent-color: #db2777 !important;
}

.tw-accent-pink-600\/0 {
  accent-color: rgb(219 39 119 / 0) !important;
}

.tw-accent-pink-600\/10 {
  accent-color: rgb(219 39 119 / 0.1) !important;
}

.tw-accent-pink-600\/100 {
  accent-color: rgb(219 39 119 / 1) !important;
}

.tw-accent-pink-600\/15 {
  accent-color: rgb(219 39 119 / 0.15) !important;
}

.tw-accent-pink-600\/20 {
  accent-color: rgb(219 39 119 / 0.2) !important;
}

.tw-accent-pink-600\/25 {
  accent-color: rgb(219 39 119 / 0.25) !important;
}

.tw-accent-pink-600\/30 {
  accent-color: rgb(219 39 119 / 0.3) !important;
}

.tw-accent-pink-600\/35 {
  accent-color: rgb(219 39 119 / 0.35) !important;
}

.tw-accent-pink-600\/40 {
  accent-color: rgb(219 39 119 / 0.4) !important;
}

.tw-accent-pink-600\/45 {
  accent-color: rgb(219 39 119 / 0.45) !important;
}

.tw-accent-pink-600\/5 {
  accent-color: rgb(219 39 119 / 0.05) !important;
}

.tw-accent-pink-600\/50 {
  accent-color: rgb(219 39 119 / 0.5) !important;
}

.tw-accent-pink-600\/55 {
  accent-color: rgb(219 39 119 / 0.55) !important;
}

.tw-accent-pink-600\/60 {
  accent-color: rgb(219 39 119 / 0.6) !important;
}

.tw-accent-pink-600\/65 {
  accent-color: rgb(219 39 119 / 0.65) !important;
}

.tw-accent-pink-600\/70 {
  accent-color: rgb(219 39 119 / 0.7) !important;
}

.tw-accent-pink-600\/75 {
  accent-color: rgb(219 39 119 / 0.75) !important;
}

.tw-accent-pink-600\/80 {
  accent-color: rgb(219 39 119 / 0.8) !important;
}

.tw-accent-pink-600\/85 {
  accent-color: rgb(219 39 119 / 0.85) !important;
}

.tw-accent-pink-600\/90 {
  accent-color: rgb(219 39 119 / 0.9) !important;
}

.tw-accent-pink-600\/95 {
  accent-color: rgb(219 39 119 / 0.95) !important;
}

.tw-accent-pink-700 {
  accent-color: #be185d !important;
}

.tw-accent-pink-700\/0 {
  accent-color: rgb(190 24 93 / 0) !important;
}

.tw-accent-pink-700\/10 {
  accent-color: rgb(190 24 93 / 0.1) !important;
}

.tw-accent-pink-700\/100 {
  accent-color: rgb(190 24 93 / 1) !important;
}

.tw-accent-pink-700\/15 {
  accent-color: rgb(190 24 93 / 0.15) !important;
}

.tw-accent-pink-700\/20 {
  accent-color: rgb(190 24 93 / 0.2) !important;
}

.tw-accent-pink-700\/25 {
  accent-color: rgb(190 24 93 / 0.25) !important;
}

.tw-accent-pink-700\/30 {
  accent-color: rgb(190 24 93 / 0.3) !important;
}

.tw-accent-pink-700\/35 {
  accent-color: rgb(190 24 93 / 0.35) !important;
}

.tw-accent-pink-700\/40 {
  accent-color: rgb(190 24 93 / 0.4) !important;
}

.tw-accent-pink-700\/45 {
  accent-color: rgb(190 24 93 / 0.45) !important;
}

.tw-accent-pink-700\/5 {
  accent-color: rgb(190 24 93 / 0.05) !important;
}

.tw-accent-pink-700\/50 {
  accent-color: rgb(190 24 93 / 0.5) !important;
}

.tw-accent-pink-700\/55 {
  accent-color: rgb(190 24 93 / 0.55) !important;
}

.tw-accent-pink-700\/60 {
  accent-color: rgb(190 24 93 / 0.6) !important;
}

.tw-accent-pink-700\/65 {
  accent-color: rgb(190 24 93 / 0.65) !important;
}

.tw-accent-pink-700\/70 {
  accent-color: rgb(190 24 93 / 0.7) !important;
}

.tw-accent-pink-700\/75 {
  accent-color: rgb(190 24 93 / 0.75) !important;
}

.tw-accent-pink-700\/80 {
  accent-color: rgb(190 24 93 / 0.8) !important;
}

.tw-accent-pink-700\/85 {
  accent-color: rgb(190 24 93 / 0.85) !important;
}

.tw-accent-pink-700\/90 {
  accent-color: rgb(190 24 93 / 0.9) !important;
}

.tw-accent-pink-700\/95 {
  accent-color: rgb(190 24 93 / 0.95) !important;
}

.tw-accent-pink-800 {
  accent-color: #9d174d !important;
}

.tw-accent-pink-800\/0 {
  accent-color: rgb(157 23 77 / 0) !important;
}

.tw-accent-pink-800\/10 {
  accent-color: rgb(157 23 77 / 0.1) !important;
}

.tw-accent-pink-800\/100 {
  accent-color: rgb(157 23 77 / 1) !important;
}

.tw-accent-pink-800\/15 {
  accent-color: rgb(157 23 77 / 0.15) !important;
}

.tw-accent-pink-800\/20 {
  accent-color: rgb(157 23 77 / 0.2) !important;
}

.tw-accent-pink-800\/25 {
  accent-color: rgb(157 23 77 / 0.25) !important;
}

.tw-accent-pink-800\/30 {
  accent-color: rgb(157 23 77 / 0.3) !important;
}

.tw-accent-pink-800\/35 {
  accent-color: rgb(157 23 77 / 0.35) !important;
}

.tw-accent-pink-800\/40 {
  accent-color: rgb(157 23 77 / 0.4) !important;
}

.tw-accent-pink-800\/45 {
  accent-color: rgb(157 23 77 / 0.45) !important;
}

.tw-accent-pink-800\/5 {
  accent-color: rgb(157 23 77 / 0.05) !important;
}

.tw-accent-pink-800\/50 {
  accent-color: rgb(157 23 77 / 0.5) !important;
}

.tw-accent-pink-800\/55 {
  accent-color: rgb(157 23 77 / 0.55) !important;
}

.tw-accent-pink-800\/60 {
  accent-color: rgb(157 23 77 / 0.6) !important;
}

.tw-accent-pink-800\/65 {
  accent-color: rgb(157 23 77 / 0.65) !important;
}

.tw-accent-pink-800\/70 {
  accent-color: rgb(157 23 77 / 0.7) !important;
}

.tw-accent-pink-800\/75 {
  accent-color: rgb(157 23 77 / 0.75) !important;
}

.tw-accent-pink-800\/80 {
  accent-color: rgb(157 23 77 / 0.8) !important;
}

.tw-accent-pink-800\/85 {
  accent-color: rgb(157 23 77 / 0.85) !important;
}

.tw-accent-pink-800\/90 {
  accent-color: rgb(157 23 77 / 0.9) !important;
}

.tw-accent-pink-800\/95 {
  accent-color: rgb(157 23 77 / 0.95) !important;
}

.tw-accent-pink-900 {
  accent-color: #831843 !important;
}

.tw-accent-pink-900\/0 {
  accent-color: rgb(131 24 67 / 0) !important;
}

.tw-accent-pink-900\/10 {
  accent-color: rgb(131 24 67 / 0.1) !important;
}

.tw-accent-pink-900\/100 {
  accent-color: rgb(131 24 67 / 1) !important;
}

.tw-accent-pink-900\/15 {
  accent-color: rgb(131 24 67 / 0.15) !important;
}

.tw-accent-pink-900\/20 {
  accent-color: rgb(131 24 67 / 0.2) !important;
}

.tw-accent-pink-900\/25 {
  accent-color: rgb(131 24 67 / 0.25) !important;
}

.tw-accent-pink-900\/30 {
  accent-color: rgb(131 24 67 / 0.3) !important;
}

.tw-accent-pink-900\/35 {
  accent-color: rgb(131 24 67 / 0.35) !important;
}

.tw-accent-pink-900\/40 {
  accent-color: rgb(131 24 67 / 0.4) !important;
}

.tw-accent-pink-900\/45 {
  accent-color: rgb(131 24 67 / 0.45) !important;
}

.tw-accent-pink-900\/5 {
  accent-color: rgb(131 24 67 / 0.05) !important;
}

.tw-accent-pink-900\/50 {
  accent-color: rgb(131 24 67 / 0.5) !important;
}

.tw-accent-pink-900\/55 {
  accent-color: rgb(131 24 67 / 0.55) !important;
}

.tw-accent-pink-900\/60 {
  accent-color: rgb(131 24 67 / 0.6) !important;
}

.tw-accent-pink-900\/65 {
  accent-color: rgb(131 24 67 / 0.65) !important;
}

.tw-accent-pink-900\/70 {
  accent-color: rgb(131 24 67 / 0.7) !important;
}

.tw-accent-pink-900\/75 {
  accent-color: rgb(131 24 67 / 0.75) !important;
}

.tw-accent-pink-900\/80 {
  accent-color: rgb(131 24 67 / 0.8) !important;
}

.tw-accent-pink-900\/85 {
  accent-color: rgb(131 24 67 / 0.85) !important;
}

.tw-accent-pink-900\/90 {
  accent-color: rgb(131 24 67 / 0.9) !important;
}

.tw-accent-pink-900\/95 {
  accent-color: rgb(131 24 67 / 0.95) !important;
}

.tw-accent-pink-950 {
  accent-color: #500724 !important;
}

.tw-accent-pink-950\/0 {
  accent-color: rgb(80 7 36 / 0) !important;
}

.tw-accent-pink-950\/10 {
  accent-color: rgb(80 7 36 / 0.1) !important;
}

.tw-accent-pink-950\/100 {
  accent-color: rgb(80 7 36 / 1) !important;
}

.tw-accent-pink-950\/15 {
  accent-color: rgb(80 7 36 / 0.15) !important;
}

.tw-accent-pink-950\/20 {
  accent-color: rgb(80 7 36 / 0.2) !important;
}

.tw-accent-pink-950\/25 {
  accent-color: rgb(80 7 36 / 0.25) !important;
}

.tw-accent-pink-950\/30 {
  accent-color: rgb(80 7 36 / 0.3) !important;
}

.tw-accent-pink-950\/35 {
  accent-color: rgb(80 7 36 / 0.35) !important;
}

.tw-accent-pink-950\/40 {
  accent-color: rgb(80 7 36 / 0.4) !important;
}

.tw-accent-pink-950\/45 {
  accent-color: rgb(80 7 36 / 0.45) !important;
}

.tw-accent-pink-950\/5 {
  accent-color: rgb(80 7 36 / 0.05) !important;
}

.tw-accent-pink-950\/50 {
  accent-color: rgb(80 7 36 / 0.5) !important;
}

.tw-accent-pink-950\/55 {
  accent-color: rgb(80 7 36 / 0.55) !important;
}

.tw-accent-pink-950\/60 {
  accent-color: rgb(80 7 36 / 0.6) !important;
}

.tw-accent-pink-950\/65 {
  accent-color: rgb(80 7 36 / 0.65) !important;
}

.tw-accent-pink-950\/70 {
  accent-color: rgb(80 7 36 / 0.7) !important;
}

.tw-accent-pink-950\/75 {
  accent-color: rgb(80 7 36 / 0.75) !important;
}

.tw-accent-pink-950\/80 {
  accent-color: rgb(80 7 36 / 0.8) !important;
}

.tw-accent-pink-950\/85 {
  accent-color: rgb(80 7 36 / 0.85) !important;
}

.tw-accent-pink-950\/90 {
  accent-color: rgb(80 7 36 / 0.9) !important;
}

.tw-accent-pink-950\/95 {
  accent-color: rgb(80 7 36 / 0.95) !important;
}

.tw-accent-purple-100 {
  accent-color: #f3e8ff !important;
}

.tw-accent-purple-100\/0 {
  accent-color: rgb(243 232 255 / 0) !important;
}

.tw-accent-purple-100\/10 {
  accent-color: rgb(243 232 255 / 0.1) !important;
}

.tw-accent-purple-100\/100 {
  accent-color: rgb(243 232 255 / 1) !important;
}

.tw-accent-purple-100\/15 {
  accent-color: rgb(243 232 255 / 0.15) !important;
}

.tw-accent-purple-100\/20 {
  accent-color: rgb(243 232 255 / 0.2) !important;
}

.tw-accent-purple-100\/25 {
  accent-color: rgb(243 232 255 / 0.25) !important;
}

.tw-accent-purple-100\/30 {
  accent-color: rgb(243 232 255 / 0.3) !important;
}

.tw-accent-purple-100\/35 {
  accent-color: rgb(243 232 255 / 0.35) !important;
}

.tw-accent-purple-100\/40 {
  accent-color: rgb(243 232 255 / 0.4) !important;
}

.tw-accent-purple-100\/45 {
  accent-color: rgb(243 232 255 / 0.45) !important;
}

.tw-accent-purple-100\/5 {
  accent-color: rgb(243 232 255 / 0.05) !important;
}

.tw-accent-purple-100\/50 {
  accent-color: rgb(243 232 255 / 0.5) !important;
}

.tw-accent-purple-100\/55 {
  accent-color: rgb(243 232 255 / 0.55) !important;
}

.tw-accent-purple-100\/60 {
  accent-color: rgb(243 232 255 / 0.6) !important;
}

.tw-accent-purple-100\/65 {
  accent-color: rgb(243 232 255 / 0.65) !important;
}

.tw-accent-purple-100\/70 {
  accent-color: rgb(243 232 255 / 0.7) !important;
}

.tw-accent-purple-100\/75 {
  accent-color: rgb(243 232 255 / 0.75) !important;
}

.tw-accent-purple-100\/80 {
  accent-color: rgb(243 232 255 / 0.8) !important;
}

.tw-accent-purple-100\/85 {
  accent-color: rgb(243 232 255 / 0.85) !important;
}

.tw-accent-purple-100\/90 {
  accent-color: rgb(243 232 255 / 0.9) !important;
}

.tw-accent-purple-100\/95 {
  accent-color: rgb(243 232 255 / 0.95) !important;
}

.tw-accent-purple-200 {
  accent-color: #e9d5ff !important;
}

.tw-accent-purple-200\/0 {
  accent-color: rgb(233 213 255 / 0) !important;
}

.tw-accent-purple-200\/10 {
  accent-color: rgb(233 213 255 / 0.1) !important;
}

.tw-accent-purple-200\/100 {
  accent-color: rgb(233 213 255 / 1) !important;
}

.tw-accent-purple-200\/15 {
  accent-color: rgb(233 213 255 / 0.15) !important;
}

.tw-accent-purple-200\/20 {
  accent-color: rgb(233 213 255 / 0.2) !important;
}

.tw-accent-purple-200\/25 {
  accent-color: rgb(233 213 255 / 0.25) !important;
}

.tw-accent-purple-200\/30 {
  accent-color: rgb(233 213 255 / 0.3) !important;
}

.tw-accent-purple-200\/35 {
  accent-color: rgb(233 213 255 / 0.35) !important;
}

.tw-accent-purple-200\/40 {
  accent-color: rgb(233 213 255 / 0.4) !important;
}

.tw-accent-purple-200\/45 {
  accent-color: rgb(233 213 255 / 0.45) !important;
}

.tw-accent-purple-200\/5 {
  accent-color: rgb(233 213 255 / 0.05) !important;
}

.tw-accent-purple-200\/50 {
  accent-color: rgb(233 213 255 / 0.5) !important;
}

.tw-accent-purple-200\/55 {
  accent-color: rgb(233 213 255 / 0.55) !important;
}

.tw-accent-purple-200\/60 {
  accent-color: rgb(233 213 255 / 0.6) !important;
}

.tw-accent-purple-200\/65 {
  accent-color: rgb(233 213 255 / 0.65) !important;
}

.tw-accent-purple-200\/70 {
  accent-color: rgb(233 213 255 / 0.7) !important;
}

.tw-accent-purple-200\/75 {
  accent-color: rgb(233 213 255 / 0.75) !important;
}

.tw-accent-purple-200\/80 {
  accent-color: rgb(233 213 255 / 0.8) !important;
}

.tw-accent-purple-200\/85 {
  accent-color: rgb(233 213 255 / 0.85) !important;
}

.tw-accent-purple-200\/90 {
  accent-color: rgb(233 213 255 / 0.9) !important;
}

.tw-accent-purple-200\/95 {
  accent-color: rgb(233 213 255 / 0.95) !important;
}

.tw-accent-purple-300 {
  accent-color: #d8b4fe !important;
}

.tw-accent-purple-300\/0 {
  accent-color: rgb(216 180 254 / 0) !important;
}

.tw-accent-purple-300\/10 {
  accent-color: rgb(216 180 254 / 0.1) !important;
}

.tw-accent-purple-300\/100 {
  accent-color: rgb(216 180 254 / 1) !important;
}

.tw-accent-purple-300\/15 {
  accent-color: rgb(216 180 254 / 0.15) !important;
}

.tw-accent-purple-300\/20 {
  accent-color: rgb(216 180 254 / 0.2) !important;
}

.tw-accent-purple-300\/25 {
  accent-color: rgb(216 180 254 / 0.25) !important;
}

.tw-accent-purple-300\/30 {
  accent-color: rgb(216 180 254 / 0.3) !important;
}

.tw-accent-purple-300\/35 {
  accent-color: rgb(216 180 254 / 0.35) !important;
}

.tw-accent-purple-300\/40 {
  accent-color: rgb(216 180 254 / 0.4) !important;
}

.tw-accent-purple-300\/45 {
  accent-color: rgb(216 180 254 / 0.45) !important;
}

.tw-accent-purple-300\/5 {
  accent-color: rgb(216 180 254 / 0.05) !important;
}

.tw-accent-purple-300\/50 {
  accent-color: rgb(216 180 254 / 0.5) !important;
}

.tw-accent-purple-300\/55 {
  accent-color: rgb(216 180 254 / 0.55) !important;
}

.tw-accent-purple-300\/60 {
  accent-color: rgb(216 180 254 / 0.6) !important;
}

.tw-accent-purple-300\/65 {
  accent-color: rgb(216 180 254 / 0.65) !important;
}

.tw-accent-purple-300\/70 {
  accent-color: rgb(216 180 254 / 0.7) !important;
}

.tw-accent-purple-300\/75 {
  accent-color: rgb(216 180 254 / 0.75) !important;
}

.tw-accent-purple-300\/80 {
  accent-color: rgb(216 180 254 / 0.8) !important;
}

.tw-accent-purple-300\/85 {
  accent-color: rgb(216 180 254 / 0.85) !important;
}

.tw-accent-purple-300\/90 {
  accent-color: rgb(216 180 254 / 0.9) !important;
}

.tw-accent-purple-300\/95 {
  accent-color: rgb(216 180 254 / 0.95) !important;
}

.tw-accent-purple-400 {
  accent-color: #c084fc !important;
}

.tw-accent-purple-400\/0 {
  accent-color: rgb(192 132 252 / 0) !important;
}

.tw-accent-purple-400\/10 {
  accent-color: rgb(192 132 252 / 0.1) !important;
}

.tw-accent-purple-400\/100 {
  accent-color: rgb(192 132 252 / 1) !important;
}

.tw-accent-purple-400\/15 {
  accent-color: rgb(192 132 252 / 0.15) !important;
}

.tw-accent-purple-400\/20 {
  accent-color: rgb(192 132 252 / 0.2) !important;
}

.tw-accent-purple-400\/25 {
  accent-color: rgb(192 132 252 / 0.25) !important;
}

.tw-accent-purple-400\/30 {
  accent-color: rgb(192 132 252 / 0.3) !important;
}

.tw-accent-purple-400\/35 {
  accent-color: rgb(192 132 252 / 0.35) !important;
}

.tw-accent-purple-400\/40 {
  accent-color: rgb(192 132 252 / 0.4) !important;
}

.tw-accent-purple-400\/45 {
  accent-color: rgb(192 132 252 / 0.45) !important;
}

.tw-accent-purple-400\/5 {
  accent-color: rgb(192 132 252 / 0.05) !important;
}

.tw-accent-purple-400\/50 {
  accent-color: rgb(192 132 252 / 0.5) !important;
}

.tw-accent-purple-400\/55 {
  accent-color: rgb(192 132 252 / 0.55) !important;
}

.tw-accent-purple-400\/60 {
  accent-color: rgb(192 132 252 / 0.6) !important;
}

.tw-accent-purple-400\/65 {
  accent-color: rgb(192 132 252 / 0.65) !important;
}

.tw-accent-purple-400\/70 {
  accent-color: rgb(192 132 252 / 0.7) !important;
}

.tw-accent-purple-400\/75 {
  accent-color: rgb(192 132 252 / 0.75) !important;
}

.tw-accent-purple-400\/80 {
  accent-color: rgb(192 132 252 / 0.8) !important;
}

.tw-accent-purple-400\/85 {
  accent-color: rgb(192 132 252 / 0.85) !important;
}

.tw-accent-purple-400\/90 {
  accent-color: rgb(192 132 252 / 0.9) !important;
}

.tw-accent-purple-400\/95 {
  accent-color: rgb(192 132 252 / 0.95) !important;
}

.tw-accent-purple-50 {
  accent-color: #faf5ff !important;
}

.tw-accent-purple-50\/0 {
  accent-color: rgb(250 245 255 / 0) !important;
}

.tw-accent-purple-50\/10 {
  accent-color: rgb(250 245 255 / 0.1) !important;
}

.tw-accent-purple-50\/100 {
  accent-color: rgb(250 245 255 / 1) !important;
}

.tw-accent-purple-50\/15 {
  accent-color: rgb(250 245 255 / 0.15) !important;
}

.tw-accent-purple-50\/20 {
  accent-color: rgb(250 245 255 / 0.2) !important;
}

.tw-accent-purple-50\/25 {
  accent-color: rgb(250 245 255 / 0.25) !important;
}

.tw-accent-purple-50\/30 {
  accent-color: rgb(250 245 255 / 0.3) !important;
}

.tw-accent-purple-50\/35 {
  accent-color: rgb(250 245 255 / 0.35) !important;
}

.tw-accent-purple-50\/40 {
  accent-color: rgb(250 245 255 / 0.4) !important;
}

.tw-accent-purple-50\/45 {
  accent-color: rgb(250 245 255 / 0.45) !important;
}

.tw-accent-purple-50\/5 {
  accent-color: rgb(250 245 255 / 0.05) !important;
}

.tw-accent-purple-50\/50 {
  accent-color: rgb(250 245 255 / 0.5) !important;
}

.tw-accent-purple-50\/55 {
  accent-color: rgb(250 245 255 / 0.55) !important;
}

.tw-accent-purple-50\/60 {
  accent-color: rgb(250 245 255 / 0.6) !important;
}

.tw-accent-purple-50\/65 {
  accent-color: rgb(250 245 255 / 0.65) !important;
}

.tw-accent-purple-50\/70 {
  accent-color: rgb(250 245 255 / 0.7) !important;
}

.tw-accent-purple-50\/75 {
  accent-color: rgb(250 245 255 / 0.75) !important;
}

.tw-accent-purple-50\/80 {
  accent-color: rgb(250 245 255 / 0.8) !important;
}

.tw-accent-purple-50\/85 {
  accent-color: rgb(250 245 255 / 0.85) !important;
}

.tw-accent-purple-50\/90 {
  accent-color: rgb(250 245 255 / 0.9) !important;
}

.tw-accent-purple-50\/95 {
  accent-color: rgb(250 245 255 / 0.95) !important;
}

.tw-accent-purple-500 {
  accent-color: #a855f7 !important;
}

.tw-accent-purple-500\/0 {
  accent-color: rgb(168 85 247 / 0) !important;
}

.tw-accent-purple-500\/10 {
  accent-color: rgb(168 85 247 / 0.1) !important;
}

.tw-accent-purple-500\/100 {
  accent-color: rgb(168 85 247 / 1) !important;
}

.tw-accent-purple-500\/15 {
  accent-color: rgb(168 85 247 / 0.15) !important;
}

.tw-accent-purple-500\/20 {
  accent-color: rgb(168 85 247 / 0.2) !important;
}

.tw-accent-purple-500\/25 {
  accent-color: rgb(168 85 247 / 0.25) !important;
}

.tw-accent-purple-500\/30 {
  accent-color: rgb(168 85 247 / 0.3) !important;
}

.tw-accent-purple-500\/35 {
  accent-color: rgb(168 85 247 / 0.35) !important;
}

.tw-accent-purple-500\/40 {
  accent-color: rgb(168 85 247 / 0.4) !important;
}

.tw-accent-purple-500\/45 {
  accent-color: rgb(168 85 247 / 0.45) !important;
}

.tw-accent-purple-500\/5 {
  accent-color: rgb(168 85 247 / 0.05) !important;
}

.tw-accent-purple-500\/50 {
  accent-color: rgb(168 85 247 / 0.5) !important;
}

.tw-accent-purple-500\/55 {
  accent-color: rgb(168 85 247 / 0.55) !important;
}

.tw-accent-purple-500\/60 {
  accent-color: rgb(168 85 247 / 0.6) !important;
}

.tw-accent-purple-500\/65 {
  accent-color: rgb(168 85 247 / 0.65) !important;
}

.tw-accent-purple-500\/70 {
  accent-color: rgb(168 85 247 / 0.7) !important;
}

.tw-accent-purple-500\/75 {
  accent-color: rgb(168 85 247 / 0.75) !important;
}

.tw-accent-purple-500\/80 {
  accent-color: rgb(168 85 247 / 0.8) !important;
}

.tw-accent-purple-500\/85 {
  accent-color: rgb(168 85 247 / 0.85) !important;
}

.tw-accent-purple-500\/90 {
  accent-color: rgb(168 85 247 / 0.9) !important;
}

.tw-accent-purple-500\/95 {
  accent-color: rgb(168 85 247 / 0.95) !important;
}

.tw-accent-purple-600 {
  accent-color: #9333ea !important;
}

.tw-accent-purple-600\/0 {
  accent-color: rgb(147 51 234 / 0) !important;
}

.tw-accent-purple-600\/10 {
  accent-color: rgb(147 51 234 / 0.1) !important;
}

.tw-accent-purple-600\/100 {
  accent-color: rgb(147 51 234 / 1) !important;
}

.tw-accent-purple-600\/15 {
  accent-color: rgb(147 51 234 / 0.15) !important;
}

.tw-accent-purple-600\/20 {
  accent-color: rgb(147 51 234 / 0.2) !important;
}

.tw-accent-purple-600\/25 {
  accent-color: rgb(147 51 234 / 0.25) !important;
}

.tw-accent-purple-600\/30 {
  accent-color: rgb(147 51 234 / 0.3) !important;
}

.tw-accent-purple-600\/35 {
  accent-color: rgb(147 51 234 / 0.35) !important;
}

.tw-accent-purple-600\/40 {
  accent-color: rgb(147 51 234 / 0.4) !important;
}

.tw-accent-purple-600\/45 {
  accent-color: rgb(147 51 234 / 0.45) !important;
}

.tw-accent-purple-600\/5 {
  accent-color: rgb(147 51 234 / 0.05) !important;
}

.tw-accent-purple-600\/50 {
  accent-color: rgb(147 51 234 / 0.5) !important;
}

.tw-accent-purple-600\/55 {
  accent-color: rgb(147 51 234 / 0.55) !important;
}

.tw-accent-purple-600\/60 {
  accent-color: rgb(147 51 234 / 0.6) !important;
}

.tw-accent-purple-600\/65 {
  accent-color: rgb(147 51 234 / 0.65) !important;
}

.tw-accent-purple-600\/70 {
  accent-color: rgb(147 51 234 / 0.7) !important;
}

.tw-accent-purple-600\/75 {
  accent-color: rgb(147 51 234 / 0.75) !important;
}

.tw-accent-purple-600\/80 {
  accent-color: rgb(147 51 234 / 0.8) !important;
}

.tw-accent-purple-600\/85 {
  accent-color: rgb(147 51 234 / 0.85) !important;
}

.tw-accent-purple-600\/90 {
  accent-color: rgb(147 51 234 / 0.9) !important;
}

.tw-accent-purple-600\/95 {
  accent-color: rgb(147 51 234 / 0.95) !important;
}

.tw-accent-purple-700 {
  accent-color: #7e22ce !important;
}

.tw-accent-purple-700\/0 {
  accent-color: rgb(126 34 206 / 0) !important;
}

.tw-accent-purple-700\/10 {
  accent-color: rgb(126 34 206 / 0.1) !important;
}

.tw-accent-purple-700\/100 {
  accent-color: rgb(126 34 206 / 1) !important;
}

.tw-accent-purple-700\/15 {
  accent-color: rgb(126 34 206 / 0.15) !important;
}

.tw-accent-purple-700\/20 {
  accent-color: rgb(126 34 206 / 0.2) !important;
}

.tw-accent-purple-700\/25 {
  accent-color: rgb(126 34 206 / 0.25) !important;
}

.tw-accent-purple-700\/30 {
  accent-color: rgb(126 34 206 / 0.3) !important;
}

.tw-accent-purple-700\/35 {
  accent-color: rgb(126 34 206 / 0.35) !important;
}

.tw-accent-purple-700\/40 {
  accent-color: rgb(126 34 206 / 0.4) !important;
}

.tw-accent-purple-700\/45 {
  accent-color: rgb(126 34 206 / 0.45) !important;
}

.tw-accent-purple-700\/5 {
  accent-color: rgb(126 34 206 / 0.05) !important;
}

.tw-accent-purple-700\/50 {
  accent-color: rgb(126 34 206 / 0.5) !important;
}

.tw-accent-purple-700\/55 {
  accent-color: rgb(126 34 206 / 0.55) !important;
}

.tw-accent-purple-700\/60 {
  accent-color: rgb(126 34 206 / 0.6) !important;
}

.tw-accent-purple-700\/65 {
  accent-color: rgb(126 34 206 / 0.65) !important;
}

.tw-accent-purple-700\/70 {
  accent-color: rgb(126 34 206 / 0.7) !important;
}

.tw-accent-purple-700\/75 {
  accent-color: rgb(126 34 206 / 0.75) !important;
}

.tw-accent-purple-700\/80 {
  accent-color: rgb(126 34 206 / 0.8) !important;
}

.tw-accent-purple-700\/85 {
  accent-color: rgb(126 34 206 / 0.85) !important;
}

.tw-accent-purple-700\/90 {
  accent-color: rgb(126 34 206 / 0.9) !important;
}

.tw-accent-purple-700\/95 {
  accent-color: rgb(126 34 206 / 0.95) !important;
}

.tw-accent-purple-800 {
  accent-color: #6b21a8 !important;
}

.tw-accent-purple-800\/0 {
  accent-color: rgb(107 33 168 / 0) !important;
}

.tw-accent-purple-800\/10 {
  accent-color: rgb(107 33 168 / 0.1) !important;
}

.tw-accent-purple-800\/100 {
  accent-color: rgb(107 33 168 / 1) !important;
}

.tw-accent-purple-800\/15 {
  accent-color: rgb(107 33 168 / 0.15) !important;
}

.tw-accent-purple-800\/20 {
  accent-color: rgb(107 33 168 / 0.2) !important;
}

.tw-accent-purple-800\/25 {
  accent-color: rgb(107 33 168 / 0.25) !important;
}

.tw-accent-purple-800\/30 {
  accent-color: rgb(107 33 168 / 0.3) !important;
}

.tw-accent-purple-800\/35 {
  accent-color: rgb(107 33 168 / 0.35) !important;
}

.tw-accent-purple-800\/40 {
  accent-color: rgb(107 33 168 / 0.4) !important;
}

.tw-accent-purple-800\/45 {
  accent-color: rgb(107 33 168 / 0.45) !important;
}

.tw-accent-purple-800\/5 {
  accent-color: rgb(107 33 168 / 0.05) !important;
}

.tw-accent-purple-800\/50 {
  accent-color: rgb(107 33 168 / 0.5) !important;
}

.tw-accent-purple-800\/55 {
  accent-color: rgb(107 33 168 / 0.55) !important;
}

.tw-accent-purple-800\/60 {
  accent-color: rgb(107 33 168 / 0.6) !important;
}

.tw-accent-purple-800\/65 {
  accent-color: rgb(107 33 168 / 0.65) !important;
}

.tw-accent-purple-800\/70 {
  accent-color: rgb(107 33 168 / 0.7) !important;
}

.tw-accent-purple-800\/75 {
  accent-color: rgb(107 33 168 / 0.75) !important;
}

.tw-accent-purple-800\/80 {
  accent-color: rgb(107 33 168 / 0.8) !important;
}

.tw-accent-purple-800\/85 {
  accent-color: rgb(107 33 168 / 0.85) !important;
}

.tw-accent-purple-800\/90 {
  accent-color: rgb(107 33 168 / 0.9) !important;
}

.tw-accent-purple-800\/95 {
  accent-color: rgb(107 33 168 / 0.95) !important;
}

.tw-accent-purple-900 {
  accent-color: #581c87 !important;
}

.tw-accent-purple-900\/0 {
  accent-color: rgb(88 28 135 / 0) !important;
}

.tw-accent-purple-900\/10 {
  accent-color: rgb(88 28 135 / 0.1) !important;
}

.tw-accent-purple-900\/100 {
  accent-color: rgb(88 28 135 / 1) !important;
}

.tw-accent-purple-900\/15 {
  accent-color: rgb(88 28 135 / 0.15) !important;
}

.tw-accent-purple-900\/20 {
  accent-color: rgb(88 28 135 / 0.2) !important;
}

.tw-accent-purple-900\/25 {
  accent-color: rgb(88 28 135 / 0.25) !important;
}

.tw-accent-purple-900\/30 {
  accent-color: rgb(88 28 135 / 0.3) !important;
}

.tw-accent-purple-900\/35 {
  accent-color: rgb(88 28 135 / 0.35) !important;
}

.tw-accent-purple-900\/40 {
  accent-color: rgb(88 28 135 / 0.4) !important;
}

.tw-accent-purple-900\/45 {
  accent-color: rgb(88 28 135 / 0.45) !important;
}

.tw-accent-purple-900\/5 {
  accent-color: rgb(88 28 135 / 0.05) !important;
}

.tw-accent-purple-900\/50 {
  accent-color: rgb(88 28 135 / 0.5) !important;
}

.tw-accent-purple-900\/55 {
  accent-color: rgb(88 28 135 / 0.55) !important;
}

.tw-accent-purple-900\/60 {
  accent-color: rgb(88 28 135 / 0.6) !important;
}

.tw-accent-purple-900\/65 {
  accent-color: rgb(88 28 135 / 0.65) !important;
}

.tw-accent-purple-900\/70 {
  accent-color: rgb(88 28 135 / 0.7) !important;
}

.tw-accent-purple-900\/75 {
  accent-color: rgb(88 28 135 / 0.75) !important;
}

.tw-accent-purple-900\/80 {
  accent-color: rgb(88 28 135 / 0.8) !important;
}

.tw-accent-purple-900\/85 {
  accent-color: rgb(88 28 135 / 0.85) !important;
}

.tw-accent-purple-900\/90 {
  accent-color: rgb(88 28 135 / 0.9) !important;
}

.tw-accent-purple-900\/95 {
  accent-color: rgb(88 28 135 / 0.95) !important;
}

.tw-accent-purple-950 {
  accent-color: #3b0764 !important;
}

.tw-accent-purple-950\/0 {
  accent-color: rgb(59 7 100 / 0) !important;
}

.tw-accent-purple-950\/10 {
  accent-color: rgb(59 7 100 / 0.1) !important;
}

.tw-accent-purple-950\/100 {
  accent-color: rgb(59 7 100 / 1) !important;
}

.tw-accent-purple-950\/15 {
  accent-color: rgb(59 7 100 / 0.15) !important;
}

.tw-accent-purple-950\/20 {
  accent-color: rgb(59 7 100 / 0.2) !important;
}

.tw-accent-purple-950\/25 {
  accent-color: rgb(59 7 100 / 0.25) !important;
}

.tw-accent-purple-950\/30 {
  accent-color: rgb(59 7 100 / 0.3) !important;
}

.tw-accent-purple-950\/35 {
  accent-color: rgb(59 7 100 / 0.35) !important;
}

.tw-accent-purple-950\/40 {
  accent-color: rgb(59 7 100 / 0.4) !important;
}

.tw-accent-purple-950\/45 {
  accent-color: rgb(59 7 100 / 0.45) !important;
}

.tw-accent-purple-950\/5 {
  accent-color: rgb(59 7 100 / 0.05) !important;
}

.tw-accent-purple-950\/50 {
  accent-color: rgb(59 7 100 / 0.5) !important;
}

.tw-accent-purple-950\/55 {
  accent-color: rgb(59 7 100 / 0.55) !important;
}

.tw-accent-purple-950\/60 {
  accent-color: rgb(59 7 100 / 0.6) !important;
}

.tw-accent-purple-950\/65 {
  accent-color: rgb(59 7 100 / 0.65) !important;
}

.tw-accent-purple-950\/70 {
  accent-color: rgb(59 7 100 / 0.7) !important;
}

.tw-accent-purple-950\/75 {
  accent-color: rgb(59 7 100 / 0.75) !important;
}

.tw-accent-purple-950\/80 {
  accent-color: rgb(59 7 100 / 0.8) !important;
}

.tw-accent-purple-950\/85 {
  accent-color: rgb(59 7 100 / 0.85) !important;
}

.tw-accent-purple-950\/90 {
  accent-color: rgb(59 7 100 / 0.9) !important;
}

.tw-accent-purple-950\/95 {
  accent-color: rgb(59 7 100 / 0.95) !important;
}

.tw-accent-red-100 {
  accent-color: #fee2e2 !important;
}

.tw-accent-red-100\/0 {
  accent-color: rgb(254 226 226 / 0) !important;
}

.tw-accent-red-100\/10 {
  accent-color: rgb(254 226 226 / 0.1) !important;
}

.tw-accent-red-100\/100 {
  accent-color: rgb(254 226 226 / 1) !important;
}

.tw-accent-red-100\/15 {
  accent-color: rgb(254 226 226 / 0.15) !important;
}

.tw-accent-red-100\/20 {
  accent-color: rgb(254 226 226 / 0.2) !important;
}

.tw-accent-red-100\/25 {
  accent-color: rgb(254 226 226 / 0.25) !important;
}

.tw-accent-red-100\/30 {
  accent-color: rgb(254 226 226 / 0.3) !important;
}

.tw-accent-red-100\/35 {
  accent-color: rgb(254 226 226 / 0.35) !important;
}

.tw-accent-red-100\/40 {
  accent-color: rgb(254 226 226 / 0.4) !important;
}

.tw-accent-red-100\/45 {
  accent-color: rgb(254 226 226 / 0.45) !important;
}

.tw-accent-red-100\/5 {
  accent-color: rgb(254 226 226 / 0.05) !important;
}

.tw-accent-red-100\/50 {
  accent-color: rgb(254 226 226 / 0.5) !important;
}

.tw-accent-red-100\/55 {
  accent-color: rgb(254 226 226 / 0.55) !important;
}

.tw-accent-red-100\/60 {
  accent-color: rgb(254 226 226 / 0.6) !important;
}

.tw-accent-red-100\/65 {
  accent-color: rgb(254 226 226 / 0.65) !important;
}

.tw-accent-red-100\/70 {
  accent-color: rgb(254 226 226 / 0.7) !important;
}

.tw-accent-red-100\/75 {
  accent-color: rgb(254 226 226 / 0.75) !important;
}

.tw-accent-red-100\/80 {
  accent-color: rgb(254 226 226 / 0.8) !important;
}

.tw-accent-red-100\/85 {
  accent-color: rgb(254 226 226 / 0.85) !important;
}

.tw-accent-red-100\/90 {
  accent-color: rgb(254 226 226 / 0.9) !important;
}

.tw-accent-red-100\/95 {
  accent-color: rgb(254 226 226 / 0.95) !important;
}

.tw-accent-red-200 {
  accent-color: #fecaca !important;
}

.tw-accent-red-200\/0 {
  accent-color: rgb(254 202 202 / 0) !important;
}

.tw-accent-red-200\/10 {
  accent-color: rgb(254 202 202 / 0.1) !important;
}

.tw-accent-red-200\/100 {
  accent-color: rgb(254 202 202 / 1) !important;
}

.tw-accent-red-200\/15 {
  accent-color: rgb(254 202 202 / 0.15) !important;
}

.tw-accent-red-200\/20 {
  accent-color: rgb(254 202 202 / 0.2) !important;
}

.tw-accent-red-200\/25 {
  accent-color: rgb(254 202 202 / 0.25) !important;
}

.tw-accent-red-200\/30 {
  accent-color: rgb(254 202 202 / 0.3) !important;
}

.tw-accent-red-200\/35 {
  accent-color: rgb(254 202 202 / 0.35) !important;
}

.tw-accent-red-200\/40 {
  accent-color: rgb(254 202 202 / 0.4) !important;
}

.tw-accent-red-200\/45 {
  accent-color: rgb(254 202 202 / 0.45) !important;
}

.tw-accent-red-200\/5 {
  accent-color: rgb(254 202 202 / 0.05) !important;
}

.tw-accent-red-200\/50 {
  accent-color: rgb(254 202 202 / 0.5) !important;
}

.tw-accent-red-200\/55 {
  accent-color: rgb(254 202 202 / 0.55) !important;
}

.tw-accent-red-200\/60 {
  accent-color: rgb(254 202 202 / 0.6) !important;
}

.tw-accent-red-200\/65 {
  accent-color: rgb(254 202 202 / 0.65) !important;
}

.tw-accent-red-200\/70 {
  accent-color: rgb(254 202 202 / 0.7) !important;
}

.tw-accent-red-200\/75 {
  accent-color: rgb(254 202 202 / 0.75) !important;
}

.tw-accent-red-200\/80 {
  accent-color: rgb(254 202 202 / 0.8) !important;
}

.tw-accent-red-200\/85 {
  accent-color: rgb(254 202 202 / 0.85) !important;
}

.tw-accent-red-200\/90 {
  accent-color: rgb(254 202 202 / 0.9) !important;
}

.tw-accent-red-200\/95 {
  accent-color: rgb(254 202 202 / 0.95) !important;
}

.tw-accent-red-300 {
  accent-color: #fca5a5 !important;
}

.tw-accent-red-300\/0 {
  accent-color: rgb(252 165 165 / 0) !important;
}

.tw-accent-red-300\/10 {
  accent-color: rgb(252 165 165 / 0.1) !important;
}

.tw-accent-red-300\/100 {
  accent-color: rgb(252 165 165 / 1) !important;
}

.tw-accent-red-300\/15 {
  accent-color: rgb(252 165 165 / 0.15) !important;
}

.tw-accent-red-300\/20 {
  accent-color: rgb(252 165 165 / 0.2) !important;
}

.tw-accent-red-300\/25 {
  accent-color: rgb(252 165 165 / 0.25) !important;
}

.tw-accent-red-300\/30 {
  accent-color: rgb(252 165 165 / 0.3) !important;
}

.tw-accent-red-300\/35 {
  accent-color: rgb(252 165 165 / 0.35) !important;
}

.tw-accent-red-300\/40 {
  accent-color: rgb(252 165 165 / 0.4) !important;
}

.tw-accent-red-300\/45 {
  accent-color: rgb(252 165 165 / 0.45) !important;
}

.tw-accent-red-300\/5 {
  accent-color: rgb(252 165 165 / 0.05) !important;
}

.tw-accent-red-300\/50 {
  accent-color: rgb(252 165 165 / 0.5) !important;
}

.tw-accent-red-300\/55 {
  accent-color: rgb(252 165 165 / 0.55) !important;
}

.tw-accent-red-300\/60 {
  accent-color: rgb(252 165 165 / 0.6) !important;
}

.tw-accent-red-300\/65 {
  accent-color: rgb(252 165 165 / 0.65) !important;
}

.tw-accent-red-300\/70 {
  accent-color: rgb(252 165 165 / 0.7) !important;
}

.tw-accent-red-300\/75 {
  accent-color: rgb(252 165 165 / 0.75) !important;
}

.tw-accent-red-300\/80 {
  accent-color: rgb(252 165 165 / 0.8) !important;
}

.tw-accent-red-300\/85 {
  accent-color: rgb(252 165 165 / 0.85) !important;
}

.tw-accent-red-300\/90 {
  accent-color: rgb(252 165 165 / 0.9) !important;
}

.tw-accent-red-300\/95 {
  accent-color: rgb(252 165 165 / 0.95) !important;
}

.tw-accent-red-400 {
  accent-color: #f87171 !important;
}

.tw-accent-red-400\/0 {
  accent-color: rgb(248 113 113 / 0) !important;
}

.tw-accent-red-400\/10 {
  accent-color: rgb(248 113 113 / 0.1) !important;
}

.tw-accent-red-400\/100 {
  accent-color: rgb(248 113 113 / 1) !important;
}

.tw-accent-red-400\/15 {
  accent-color: rgb(248 113 113 / 0.15) !important;
}

.tw-accent-red-400\/20 {
  accent-color: rgb(248 113 113 / 0.2) !important;
}

.tw-accent-red-400\/25 {
  accent-color: rgb(248 113 113 / 0.25) !important;
}

.tw-accent-red-400\/30 {
  accent-color: rgb(248 113 113 / 0.3) !important;
}

.tw-accent-red-400\/35 {
  accent-color: rgb(248 113 113 / 0.35) !important;
}

.tw-accent-red-400\/40 {
  accent-color: rgb(248 113 113 / 0.4) !important;
}

.tw-accent-red-400\/45 {
  accent-color: rgb(248 113 113 / 0.45) !important;
}

.tw-accent-red-400\/5 {
  accent-color: rgb(248 113 113 / 0.05) !important;
}

.tw-accent-red-400\/50 {
  accent-color: rgb(248 113 113 / 0.5) !important;
}

.tw-accent-red-400\/55 {
  accent-color: rgb(248 113 113 / 0.55) !important;
}

.tw-accent-red-400\/60 {
  accent-color: rgb(248 113 113 / 0.6) !important;
}

.tw-accent-red-400\/65 {
  accent-color: rgb(248 113 113 / 0.65) !important;
}

.tw-accent-red-400\/70 {
  accent-color: rgb(248 113 113 / 0.7) !important;
}

.tw-accent-red-400\/75 {
  accent-color: rgb(248 113 113 / 0.75) !important;
}

.tw-accent-red-400\/80 {
  accent-color: rgb(248 113 113 / 0.8) !important;
}

.tw-accent-red-400\/85 {
  accent-color: rgb(248 113 113 / 0.85) !important;
}

.tw-accent-red-400\/90 {
  accent-color: rgb(248 113 113 / 0.9) !important;
}

.tw-accent-red-400\/95 {
  accent-color: rgb(248 113 113 / 0.95) !important;
}

.tw-accent-red-50 {
  accent-color: #fef2f2 !important;
}

.tw-accent-red-50\/0 {
  accent-color: rgb(254 242 242 / 0) !important;
}

.tw-accent-red-50\/10 {
  accent-color: rgb(254 242 242 / 0.1) !important;
}

.tw-accent-red-50\/100 {
  accent-color: rgb(254 242 242 / 1) !important;
}

.tw-accent-red-50\/15 {
  accent-color: rgb(254 242 242 / 0.15) !important;
}

.tw-accent-red-50\/20 {
  accent-color: rgb(254 242 242 / 0.2) !important;
}

.tw-accent-red-50\/25 {
  accent-color: rgb(254 242 242 / 0.25) !important;
}

.tw-accent-red-50\/30 {
  accent-color: rgb(254 242 242 / 0.3) !important;
}

.tw-accent-red-50\/35 {
  accent-color: rgb(254 242 242 / 0.35) !important;
}

.tw-accent-red-50\/40 {
  accent-color: rgb(254 242 242 / 0.4) !important;
}

.tw-accent-red-50\/45 {
  accent-color: rgb(254 242 242 / 0.45) !important;
}

.tw-accent-red-50\/5 {
  accent-color: rgb(254 242 242 / 0.05) !important;
}

.tw-accent-red-50\/50 {
  accent-color: rgb(254 242 242 / 0.5) !important;
}

.tw-accent-red-50\/55 {
  accent-color: rgb(254 242 242 / 0.55) !important;
}

.tw-accent-red-50\/60 {
  accent-color: rgb(254 242 242 / 0.6) !important;
}

.tw-accent-red-50\/65 {
  accent-color: rgb(254 242 242 / 0.65) !important;
}

.tw-accent-red-50\/70 {
  accent-color: rgb(254 242 242 / 0.7) !important;
}

.tw-accent-red-50\/75 {
  accent-color: rgb(254 242 242 / 0.75) !important;
}

.tw-accent-red-50\/80 {
  accent-color: rgb(254 242 242 / 0.8) !important;
}

.tw-accent-red-50\/85 {
  accent-color: rgb(254 242 242 / 0.85) !important;
}

.tw-accent-red-50\/90 {
  accent-color: rgb(254 242 242 / 0.9) !important;
}

.tw-accent-red-50\/95 {
  accent-color: rgb(254 242 242 / 0.95) !important;
}

.tw-accent-red-500 {
  accent-color: #ef4444 !important;
}

.tw-accent-red-500\/0 {
  accent-color: rgb(239 68 68 / 0) !important;
}

.tw-accent-red-500\/10 {
  accent-color: rgb(239 68 68 / 0.1) !important;
}

.tw-accent-red-500\/100 {
  accent-color: rgb(239 68 68 / 1) !important;
}

.tw-accent-red-500\/15 {
  accent-color: rgb(239 68 68 / 0.15) !important;
}

.tw-accent-red-500\/20 {
  accent-color: rgb(239 68 68 / 0.2) !important;
}

.tw-accent-red-500\/25 {
  accent-color: rgb(239 68 68 / 0.25) !important;
}

.tw-accent-red-500\/30 {
  accent-color: rgb(239 68 68 / 0.3) !important;
}

.tw-accent-red-500\/35 {
  accent-color: rgb(239 68 68 / 0.35) !important;
}

.tw-accent-red-500\/40 {
  accent-color: rgb(239 68 68 / 0.4) !important;
}

.tw-accent-red-500\/45 {
  accent-color: rgb(239 68 68 / 0.45) !important;
}

.tw-accent-red-500\/5 {
  accent-color: rgb(239 68 68 / 0.05) !important;
}

.tw-accent-red-500\/50 {
  accent-color: rgb(239 68 68 / 0.5) !important;
}

.tw-accent-red-500\/55 {
  accent-color: rgb(239 68 68 / 0.55) !important;
}

.tw-accent-red-500\/60 {
  accent-color: rgb(239 68 68 / 0.6) !important;
}

.tw-accent-red-500\/65 {
  accent-color: rgb(239 68 68 / 0.65) !important;
}

.tw-accent-red-500\/70 {
  accent-color: rgb(239 68 68 / 0.7) !important;
}

.tw-accent-red-500\/75 {
  accent-color: rgb(239 68 68 / 0.75) !important;
}

.tw-accent-red-500\/80 {
  accent-color: rgb(239 68 68 / 0.8) !important;
}

.tw-accent-red-500\/85 {
  accent-color: rgb(239 68 68 / 0.85) !important;
}

.tw-accent-red-500\/90 {
  accent-color: rgb(239 68 68 / 0.9) !important;
}

.tw-accent-red-500\/95 {
  accent-color: rgb(239 68 68 / 0.95) !important;
}

.tw-accent-red-600 {
  accent-color: #dc2626 !important;
}

.tw-accent-red-600\/0 {
  accent-color: rgb(220 38 38 / 0) !important;
}

.tw-accent-red-600\/10 {
  accent-color: rgb(220 38 38 / 0.1) !important;
}

.tw-accent-red-600\/100 {
  accent-color: rgb(220 38 38 / 1) !important;
}

.tw-accent-red-600\/15 {
  accent-color: rgb(220 38 38 / 0.15) !important;
}

.tw-accent-red-600\/20 {
  accent-color: rgb(220 38 38 / 0.2) !important;
}

.tw-accent-red-600\/25 {
  accent-color: rgb(220 38 38 / 0.25) !important;
}

.tw-accent-red-600\/30 {
  accent-color: rgb(220 38 38 / 0.3) !important;
}

.tw-accent-red-600\/35 {
  accent-color: rgb(220 38 38 / 0.35) !important;
}

.tw-accent-red-600\/40 {
  accent-color: rgb(220 38 38 / 0.4) !important;
}

.tw-accent-red-600\/45 {
  accent-color: rgb(220 38 38 / 0.45) !important;
}

.tw-accent-red-600\/5 {
  accent-color: rgb(220 38 38 / 0.05) !important;
}

.tw-accent-red-600\/50 {
  accent-color: rgb(220 38 38 / 0.5) !important;
}

.tw-accent-red-600\/55 {
  accent-color: rgb(220 38 38 / 0.55) !important;
}

.tw-accent-red-600\/60 {
  accent-color: rgb(220 38 38 / 0.6) !important;
}

.tw-accent-red-600\/65 {
  accent-color: rgb(220 38 38 / 0.65) !important;
}

.tw-accent-red-600\/70 {
  accent-color: rgb(220 38 38 / 0.7) !important;
}

.tw-accent-red-600\/75 {
  accent-color: rgb(220 38 38 / 0.75) !important;
}

.tw-accent-red-600\/80 {
  accent-color: rgb(220 38 38 / 0.8) !important;
}

.tw-accent-red-600\/85 {
  accent-color: rgb(220 38 38 / 0.85) !important;
}

.tw-accent-red-600\/90 {
  accent-color: rgb(220 38 38 / 0.9) !important;
}

.tw-accent-red-600\/95 {
  accent-color: rgb(220 38 38 / 0.95) !important;
}

.tw-accent-red-700 {
  accent-color: #b91c1c !important;
}

.tw-accent-red-700\/0 {
  accent-color: rgb(185 28 28 / 0) !important;
}

.tw-accent-red-700\/10 {
  accent-color: rgb(185 28 28 / 0.1) !important;
}

.tw-accent-red-700\/100 {
  accent-color: rgb(185 28 28 / 1) !important;
}

.tw-accent-red-700\/15 {
  accent-color: rgb(185 28 28 / 0.15) !important;
}

.tw-accent-red-700\/20 {
  accent-color: rgb(185 28 28 / 0.2) !important;
}

.tw-accent-red-700\/25 {
  accent-color: rgb(185 28 28 / 0.25) !important;
}

.tw-accent-red-700\/30 {
  accent-color: rgb(185 28 28 / 0.3) !important;
}

.tw-accent-red-700\/35 {
  accent-color: rgb(185 28 28 / 0.35) !important;
}

.tw-accent-red-700\/40 {
  accent-color: rgb(185 28 28 / 0.4) !important;
}

.tw-accent-red-700\/45 {
  accent-color: rgb(185 28 28 / 0.45) !important;
}

.tw-accent-red-700\/5 {
  accent-color: rgb(185 28 28 / 0.05) !important;
}

.tw-accent-red-700\/50 {
  accent-color: rgb(185 28 28 / 0.5) !important;
}

.tw-accent-red-700\/55 {
  accent-color: rgb(185 28 28 / 0.55) !important;
}

.tw-accent-red-700\/60 {
  accent-color: rgb(185 28 28 / 0.6) !important;
}

.tw-accent-red-700\/65 {
  accent-color: rgb(185 28 28 / 0.65) !important;
}

.tw-accent-red-700\/70 {
  accent-color: rgb(185 28 28 / 0.7) !important;
}

.tw-accent-red-700\/75 {
  accent-color: rgb(185 28 28 / 0.75) !important;
}

.tw-accent-red-700\/80 {
  accent-color: rgb(185 28 28 / 0.8) !important;
}

.tw-accent-red-700\/85 {
  accent-color: rgb(185 28 28 / 0.85) !important;
}

.tw-accent-red-700\/90 {
  accent-color: rgb(185 28 28 / 0.9) !important;
}

.tw-accent-red-700\/95 {
  accent-color: rgb(185 28 28 / 0.95) !important;
}

.tw-accent-red-800 {
  accent-color: #991b1b !important;
}

.tw-accent-red-800\/0 {
  accent-color: rgb(153 27 27 / 0) !important;
}

.tw-accent-red-800\/10 {
  accent-color: rgb(153 27 27 / 0.1) !important;
}

.tw-accent-red-800\/100 {
  accent-color: rgb(153 27 27 / 1) !important;
}

.tw-accent-red-800\/15 {
  accent-color: rgb(153 27 27 / 0.15) !important;
}

.tw-accent-red-800\/20 {
  accent-color: rgb(153 27 27 / 0.2) !important;
}

.tw-accent-red-800\/25 {
  accent-color: rgb(153 27 27 / 0.25) !important;
}

.tw-accent-red-800\/30 {
  accent-color: rgb(153 27 27 / 0.3) !important;
}

.tw-accent-red-800\/35 {
  accent-color: rgb(153 27 27 / 0.35) !important;
}

.tw-accent-red-800\/40 {
  accent-color: rgb(153 27 27 / 0.4) !important;
}

.tw-accent-red-800\/45 {
  accent-color: rgb(153 27 27 / 0.45) !important;
}

.tw-accent-red-800\/5 {
  accent-color: rgb(153 27 27 / 0.05) !important;
}

.tw-accent-red-800\/50 {
  accent-color: rgb(153 27 27 / 0.5) !important;
}

.tw-accent-red-800\/55 {
  accent-color: rgb(153 27 27 / 0.55) !important;
}

.tw-accent-red-800\/60 {
  accent-color: rgb(153 27 27 / 0.6) !important;
}

.tw-accent-red-800\/65 {
  accent-color: rgb(153 27 27 / 0.65) !important;
}

.tw-accent-red-800\/70 {
  accent-color: rgb(153 27 27 / 0.7) !important;
}

.tw-accent-red-800\/75 {
  accent-color: rgb(153 27 27 / 0.75) !important;
}

.tw-accent-red-800\/80 {
  accent-color: rgb(153 27 27 / 0.8) !important;
}

.tw-accent-red-800\/85 {
  accent-color: rgb(153 27 27 / 0.85) !important;
}

.tw-accent-red-800\/90 {
  accent-color: rgb(153 27 27 / 0.9) !important;
}

.tw-accent-red-800\/95 {
  accent-color: rgb(153 27 27 / 0.95) !important;
}

.tw-accent-red-900 {
  accent-color: #7f1d1d !important;
}

.tw-accent-red-900\/0 {
  accent-color: rgb(127 29 29 / 0) !important;
}

.tw-accent-red-900\/10 {
  accent-color: rgb(127 29 29 / 0.1) !important;
}

.tw-accent-red-900\/100 {
  accent-color: rgb(127 29 29 / 1) !important;
}

.tw-accent-red-900\/15 {
  accent-color: rgb(127 29 29 / 0.15) !important;
}

.tw-accent-red-900\/20 {
  accent-color: rgb(127 29 29 / 0.2) !important;
}

.tw-accent-red-900\/25 {
  accent-color: rgb(127 29 29 / 0.25) !important;
}

.tw-accent-red-900\/30 {
  accent-color: rgb(127 29 29 / 0.3) !important;
}

.tw-accent-red-900\/35 {
  accent-color: rgb(127 29 29 / 0.35) !important;
}

.tw-accent-red-900\/40 {
  accent-color: rgb(127 29 29 / 0.4) !important;
}

.tw-accent-red-900\/45 {
  accent-color: rgb(127 29 29 / 0.45) !important;
}

.tw-accent-red-900\/5 {
  accent-color: rgb(127 29 29 / 0.05) !important;
}

.tw-accent-red-900\/50 {
  accent-color: rgb(127 29 29 / 0.5) !important;
}

.tw-accent-red-900\/55 {
  accent-color: rgb(127 29 29 / 0.55) !important;
}

.tw-accent-red-900\/60 {
  accent-color: rgb(127 29 29 / 0.6) !important;
}

.tw-accent-red-900\/65 {
  accent-color: rgb(127 29 29 / 0.65) !important;
}

.tw-accent-red-900\/70 {
  accent-color: rgb(127 29 29 / 0.7) !important;
}

.tw-accent-red-900\/75 {
  accent-color: rgb(127 29 29 / 0.75) !important;
}

.tw-accent-red-900\/80 {
  accent-color: rgb(127 29 29 / 0.8) !important;
}

.tw-accent-red-900\/85 {
  accent-color: rgb(127 29 29 / 0.85) !important;
}

.tw-accent-red-900\/90 {
  accent-color: rgb(127 29 29 / 0.9) !important;
}

.tw-accent-red-900\/95 {
  accent-color: rgb(127 29 29 / 0.95) !important;
}

.tw-accent-red-950 {
  accent-color: #450a0a !important;
}

.tw-accent-red-950\/0 {
  accent-color: rgb(69 10 10 / 0) !important;
}

.tw-accent-red-950\/10 {
  accent-color: rgb(69 10 10 / 0.1) !important;
}

.tw-accent-red-950\/100 {
  accent-color: rgb(69 10 10 / 1) !important;
}

.tw-accent-red-950\/15 {
  accent-color: rgb(69 10 10 / 0.15) !important;
}

.tw-accent-red-950\/20 {
  accent-color: rgb(69 10 10 / 0.2) !important;
}

.tw-accent-red-950\/25 {
  accent-color: rgb(69 10 10 / 0.25) !important;
}

.tw-accent-red-950\/30 {
  accent-color: rgb(69 10 10 / 0.3) !important;
}

.tw-accent-red-950\/35 {
  accent-color: rgb(69 10 10 / 0.35) !important;
}

.tw-accent-red-950\/40 {
  accent-color: rgb(69 10 10 / 0.4) !important;
}

.tw-accent-red-950\/45 {
  accent-color: rgb(69 10 10 / 0.45) !important;
}

.tw-accent-red-950\/5 {
  accent-color: rgb(69 10 10 / 0.05) !important;
}

.tw-accent-red-950\/50 {
  accent-color: rgb(69 10 10 / 0.5) !important;
}

.tw-accent-red-950\/55 {
  accent-color: rgb(69 10 10 / 0.55) !important;
}

.tw-accent-red-950\/60 {
  accent-color: rgb(69 10 10 / 0.6) !important;
}

.tw-accent-red-950\/65 {
  accent-color: rgb(69 10 10 / 0.65) !important;
}

.tw-accent-red-950\/70 {
  accent-color: rgb(69 10 10 / 0.7) !important;
}

.tw-accent-red-950\/75 {
  accent-color: rgb(69 10 10 / 0.75) !important;
}

.tw-accent-red-950\/80 {
  accent-color: rgb(69 10 10 / 0.8) !important;
}

.tw-accent-red-950\/85 {
  accent-color: rgb(69 10 10 / 0.85) !important;
}

.tw-accent-red-950\/90 {
  accent-color: rgb(69 10 10 / 0.9) !important;
}

.tw-accent-red-950\/95 {
  accent-color: rgb(69 10 10 / 0.95) !important;
}

.tw-accent-rose-100 {
  accent-color: #ffe4e6 !important;
}

.tw-accent-rose-100\/0 {
  accent-color: rgb(255 228 230 / 0) !important;
}

.tw-accent-rose-100\/10 {
  accent-color: rgb(255 228 230 / 0.1) !important;
}

.tw-accent-rose-100\/100 {
  accent-color: rgb(255 228 230 / 1) !important;
}

.tw-accent-rose-100\/15 {
  accent-color: rgb(255 228 230 / 0.15) !important;
}

.tw-accent-rose-100\/20 {
  accent-color: rgb(255 228 230 / 0.2) !important;
}

.tw-accent-rose-100\/25 {
  accent-color: rgb(255 228 230 / 0.25) !important;
}

.tw-accent-rose-100\/30 {
  accent-color: rgb(255 228 230 / 0.3) !important;
}

.tw-accent-rose-100\/35 {
  accent-color: rgb(255 228 230 / 0.35) !important;
}

.tw-accent-rose-100\/40 {
  accent-color: rgb(255 228 230 / 0.4) !important;
}

.tw-accent-rose-100\/45 {
  accent-color: rgb(255 228 230 / 0.45) !important;
}

.tw-accent-rose-100\/5 {
  accent-color: rgb(255 228 230 / 0.05) !important;
}

.tw-accent-rose-100\/50 {
  accent-color: rgb(255 228 230 / 0.5) !important;
}

.tw-accent-rose-100\/55 {
  accent-color: rgb(255 228 230 / 0.55) !important;
}

.tw-accent-rose-100\/60 {
  accent-color: rgb(255 228 230 / 0.6) !important;
}

.tw-accent-rose-100\/65 {
  accent-color: rgb(255 228 230 / 0.65) !important;
}

.tw-accent-rose-100\/70 {
  accent-color: rgb(255 228 230 / 0.7) !important;
}

.tw-accent-rose-100\/75 {
  accent-color: rgb(255 228 230 / 0.75) !important;
}

.tw-accent-rose-100\/80 {
  accent-color: rgb(255 228 230 / 0.8) !important;
}

.tw-accent-rose-100\/85 {
  accent-color: rgb(255 228 230 / 0.85) !important;
}

.tw-accent-rose-100\/90 {
  accent-color: rgb(255 228 230 / 0.9) !important;
}

.tw-accent-rose-100\/95 {
  accent-color: rgb(255 228 230 / 0.95) !important;
}

.tw-accent-rose-200 {
  accent-color: #fecdd3 !important;
}

.tw-accent-rose-200\/0 {
  accent-color: rgb(254 205 211 / 0) !important;
}

.tw-accent-rose-200\/10 {
  accent-color: rgb(254 205 211 / 0.1) !important;
}

.tw-accent-rose-200\/100 {
  accent-color: rgb(254 205 211 / 1) !important;
}

.tw-accent-rose-200\/15 {
  accent-color: rgb(254 205 211 / 0.15) !important;
}

.tw-accent-rose-200\/20 {
  accent-color: rgb(254 205 211 / 0.2) !important;
}

.tw-accent-rose-200\/25 {
  accent-color: rgb(254 205 211 / 0.25) !important;
}

.tw-accent-rose-200\/30 {
  accent-color: rgb(254 205 211 / 0.3) !important;
}

.tw-accent-rose-200\/35 {
  accent-color: rgb(254 205 211 / 0.35) !important;
}

.tw-accent-rose-200\/40 {
  accent-color: rgb(254 205 211 / 0.4) !important;
}

.tw-accent-rose-200\/45 {
  accent-color: rgb(254 205 211 / 0.45) !important;
}

.tw-accent-rose-200\/5 {
  accent-color: rgb(254 205 211 / 0.05) !important;
}

.tw-accent-rose-200\/50 {
  accent-color: rgb(254 205 211 / 0.5) !important;
}

.tw-accent-rose-200\/55 {
  accent-color: rgb(254 205 211 / 0.55) !important;
}

.tw-accent-rose-200\/60 {
  accent-color: rgb(254 205 211 / 0.6) !important;
}

.tw-accent-rose-200\/65 {
  accent-color: rgb(254 205 211 / 0.65) !important;
}

.tw-accent-rose-200\/70 {
  accent-color: rgb(254 205 211 / 0.7) !important;
}

.tw-accent-rose-200\/75 {
  accent-color: rgb(254 205 211 / 0.75) !important;
}

.tw-accent-rose-200\/80 {
  accent-color: rgb(254 205 211 / 0.8) !important;
}

.tw-accent-rose-200\/85 {
  accent-color: rgb(254 205 211 / 0.85) !important;
}

.tw-accent-rose-200\/90 {
  accent-color: rgb(254 205 211 / 0.9) !important;
}

.tw-accent-rose-200\/95 {
  accent-color: rgb(254 205 211 / 0.95) !important;
}

.tw-accent-rose-300 {
  accent-color: #fda4af !important;
}

.tw-accent-rose-300\/0 {
  accent-color: rgb(253 164 175 / 0) !important;
}

.tw-accent-rose-300\/10 {
  accent-color: rgb(253 164 175 / 0.1) !important;
}

.tw-accent-rose-300\/100 {
  accent-color: rgb(253 164 175 / 1) !important;
}

.tw-accent-rose-300\/15 {
  accent-color: rgb(253 164 175 / 0.15) !important;
}

.tw-accent-rose-300\/20 {
  accent-color: rgb(253 164 175 / 0.2) !important;
}

.tw-accent-rose-300\/25 {
  accent-color: rgb(253 164 175 / 0.25) !important;
}

.tw-accent-rose-300\/30 {
  accent-color: rgb(253 164 175 / 0.3) !important;
}

.tw-accent-rose-300\/35 {
  accent-color: rgb(253 164 175 / 0.35) !important;
}

.tw-accent-rose-300\/40 {
  accent-color: rgb(253 164 175 / 0.4) !important;
}

.tw-accent-rose-300\/45 {
  accent-color: rgb(253 164 175 / 0.45) !important;
}

.tw-accent-rose-300\/5 {
  accent-color: rgb(253 164 175 / 0.05) !important;
}

.tw-accent-rose-300\/50 {
  accent-color: rgb(253 164 175 / 0.5) !important;
}

.tw-accent-rose-300\/55 {
  accent-color: rgb(253 164 175 / 0.55) !important;
}

.tw-accent-rose-300\/60 {
  accent-color: rgb(253 164 175 / 0.6) !important;
}

.tw-accent-rose-300\/65 {
  accent-color: rgb(253 164 175 / 0.65) !important;
}

.tw-accent-rose-300\/70 {
  accent-color: rgb(253 164 175 / 0.7) !important;
}

.tw-accent-rose-300\/75 {
  accent-color: rgb(253 164 175 / 0.75) !important;
}

.tw-accent-rose-300\/80 {
  accent-color: rgb(253 164 175 / 0.8) !important;
}

.tw-accent-rose-300\/85 {
  accent-color: rgb(253 164 175 / 0.85) !important;
}

.tw-accent-rose-300\/90 {
  accent-color: rgb(253 164 175 / 0.9) !important;
}

.tw-accent-rose-300\/95 {
  accent-color: rgb(253 164 175 / 0.95) !important;
}

.tw-accent-rose-400 {
  accent-color: #fb7185 !important;
}

.tw-accent-rose-400\/0 {
  accent-color: rgb(251 113 133 / 0) !important;
}

.tw-accent-rose-400\/10 {
  accent-color: rgb(251 113 133 / 0.1) !important;
}

.tw-accent-rose-400\/100 {
  accent-color: rgb(251 113 133 / 1) !important;
}

.tw-accent-rose-400\/15 {
  accent-color: rgb(251 113 133 / 0.15) !important;
}

.tw-accent-rose-400\/20 {
  accent-color: rgb(251 113 133 / 0.2) !important;
}

.tw-accent-rose-400\/25 {
  accent-color: rgb(251 113 133 / 0.25) !important;
}

.tw-accent-rose-400\/30 {
  accent-color: rgb(251 113 133 / 0.3) !important;
}

.tw-accent-rose-400\/35 {
  accent-color: rgb(251 113 133 / 0.35) !important;
}

.tw-accent-rose-400\/40 {
  accent-color: rgb(251 113 133 / 0.4) !important;
}

.tw-accent-rose-400\/45 {
  accent-color: rgb(251 113 133 / 0.45) !important;
}

.tw-accent-rose-400\/5 {
  accent-color: rgb(251 113 133 / 0.05) !important;
}

.tw-accent-rose-400\/50 {
  accent-color: rgb(251 113 133 / 0.5) !important;
}

.tw-accent-rose-400\/55 {
  accent-color: rgb(251 113 133 / 0.55) !important;
}

.tw-accent-rose-400\/60 {
  accent-color: rgb(251 113 133 / 0.6) !important;
}

.tw-accent-rose-400\/65 {
  accent-color: rgb(251 113 133 / 0.65) !important;
}

.tw-accent-rose-400\/70 {
  accent-color: rgb(251 113 133 / 0.7) !important;
}

.tw-accent-rose-400\/75 {
  accent-color: rgb(251 113 133 / 0.75) !important;
}

.tw-accent-rose-400\/80 {
  accent-color: rgb(251 113 133 / 0.8) !important;
}

.tw-accent-rose-400\/85 {
  accent-color: rgb(251 113 133 / 0.85) !important;
}

.tw-accent-rose-400\/90 {
  accent-color: rgb(251 113 133 / 0.9) !important;
}

.tw-accent-rose-400\/95 {
  accent-color: rgb(251 113 133 / 0.95) !important;
}

.tw-accent-rose-50 {
  accent-color: #fff1f2 !important;
}

.tw-accent-rose-50\/0 {
  accent-color: rgb(255 241 242 / 0) !important;
}

.tw-accent-rose-50\/10 {
  accent-color: rgb(255 241 242 / 0.1) !important;
}

.tw-accent-rose-50\/100 {
  accent-color: rgb(255 241 242 / 1) !important;
}

.tw-accent-rose-50\/15 {
  accent-color: rgb(255 241 242 / 0.15) !important;
}

.tw-accent-rose-50\/20 {
  accent-color: rgb(255 241 242 / 0.2) !important;
}

.tw-accent-rose-50\/25 {
  accent-color: rgb(255 241 242 / 0.25) !important;
}

.tw-accent-rose-50\/30 {
  accent-color: rgb(255 241 242 / 0.3) !important;
}

.tw-accent-rose-50\/35 {
  accent-color: rgb(255 241 242 / 0.35) !important;
}

.tw-accent-rose-50\/40 {
  accent-color: rgb(255 241 242 / 0.4) !important;
}

.tw-accent-rose-50\/45 {
  accent-color: rgb(255 241 242 / 0.45) !important;
}

.tw-accent-rose-50\/5 {
  accent-color: rgb(255 241 242 / 0.05) !important;
}

.tw-accent-rose-50\/50 {
  accent-color: rgb(255 241 242 / 0.5) !important;
}

.tw-accent-rose-50\/55 {
  accent-color: rgb(255 241 242 / 0.55) !important;
}

.tw-accent-rose-50\/60 {
  accent-color: rgb(255 241 242 / 0.6) !important;
}

.tw-accent-rose-50\/65 {
  accent-color: rgb(255 241 242 / 0.65) !important;
}

.tw-accent-rose-50\/70 {
  accent-color: rgb(255 241 242 / 0.7) !important;
}

.tw-accent-rose-50\/75 {
  accent-color: rgb(255 241 242 / 0.75) !important;
}

.tw-accent-rose-50\/80 {
  accent-color: rgb(255 241 242 / 0.8) !important;
}

.tw-accent-rose-50\/85 {
  accent-color: rgb(255 241 242 / 0.85) !important;
}

.tw-accent-rose-50\/90 {
  accent-color: rgb(255 241 242 / 0.9) !important;
}

.tw-accent-rose-50\/95 {
  accent-color: rgb(255 241 242 / 0.95) !important;
}

.tw-accent-rose-500 {
  accent-color: #f43f5e !important;
}

.tw-accent-rose-500\/0 {
  accent-color: rgb(244 63 94 / 0) !important;
}

.tw-accent-rose-500\/10 {
  accent-color: rgb(244 63 94 / 0.1) !important;
}

.tw-accent-rose-500\/100 {
  accent-color: rgb(244 63 94 / 1) !important;
}

.tw-accent-rose-500\/15 {
  accent-color: rgb(244 63 94 / 0.15) !important;
}

.tw-accent-rose-500\/20 {
  accent-color: rgb(244 63 94 / 0.2) !important;
}

.tw-accent-rose-500\/25 {
  accent-color: rgb(244 63 94 / 0.25) !important;
}

.tw-accent-rose-500\/30 {
  accent-color: rgb(244 63 94 / 0.3) !important;
}

.tw-accent-rose-500\/35 {
  accent-color: rgb(244 63 94 / 0.35) !important;
}

.tw-accent-rose-500\/40 {
  accent-color: rgb(244 63 94 / 0.4) !important;
}

.tw-accent-rose-500\/45 {
  accent-color: rgb(244 63 94 / 0.45) !important;
}

.tw-accent-rose-500\/5 {
  accent-color: rgb(244 63 94 / 0.05) !important;
}

.tw-accent-rose-500\/50 {
  accent-color: rgb(244 63 94 / 0.5) !important;
}

.tw-accent-rose-500\/55 {
  accent-color: rgb(244 63 94 / 0.55) !important;
}

.tw-accent-rose-500\/60 {
  accent-color: rgb(244 63 94 / 0.6) !important;
}

.tw-accent-rose-500\/65 {
  accent-color: rgb(244 63 94 / 0.65) !important;
}

.tw-accent-rose-500\/70 {
  accent-color: rgb(244 63 94 / 0.7) !important;
}

.tw-accent-rose-500\/75 {
  accent-color: rgb(244 63 94 / 0.75) !important;
}

.tw-accent-rose-500\/80 {
  accent-color: rgb(244 63 94 / 0.8) !important;
}

.tw-accent-rose-500\/85 {
  accent-color: rgb(244 63 94 / 0.85) !important;
}

.tw-accent-rose-500\/90 {
  accent-color: rgb(244 63 94 / 0.9) !important;
}

.tw-accent-rose-500\/95 {
  accent-color: rgb(244 63 94 / 0.95) !important;
}

.tw-accent-rose-600 {
  accent-color: #e11d48 !important;
}

.tw-accent-rose-600\/0 {
  accent-color: rgb(225 29 72 / 0) !important;
}

.tw-accent-rose-600\/10 {
  accent-color: rgb(225 29 72 / 0.1) !important;
}

.tw-accent-rose-600\/100 {
  accent-color: rgb(225 29 72 / 1) !important;
}

.tw-accent-rose-600\/15 {
  accent-color: rgb(225 29 72 / 0.15) !important;
}

.tw-accent-rose-600\/20 {
  accent-color: rgb(225 29 72 / 0.2) !important;
}

.tw-accent-rose-600\/25 {
  accent-color: rgb(225 29 72 / 0.25) !important;
}

.tw-accent-rose-600\/30 {
  accent-color: rgb(225 29 72 / 0.3) !important;
}

.tw-accent-rose-600\/35 {
  accent-color: rgb(225 29 72 / 0.35) !important;
}

.tw-accent-rose-600\/40 {
  accent-color: rgb(225 29 72 / 0.4) !important;
}

.tw-accent-rose-600\/45 {
  accent-color: rgb(225 29 72 / 0.45) !important;
}

.tw-accent-rose-600\/5 {
  accent-color: rgb(225 29 72 / 0.05) !important;
}

.tw-accent-rose-600\/50 {
  accent-color: rgb(225 29 72 / 0.5) !important;
}

.tw-accent-rose-600\/55 {
  accent-color: rgb(225 29 72 / 0.55) !important;
}

.tw-accent-rose-600\/60 {
  accent-color: rgb(225 29 72 / 0.6) !important;
}

.tw-accent-rose-600\/65 {
  accent-color: rgb(225 29 72 / 0.65) !important;
}

.tw-accent-rose-600\/70 {
  accent-color: rgb(225 29 72 / 0.7) !important;
}

.tw-accent-rose-600\/75 {
  accent-color: rgb(225 29 72 / 0.75) !important;
}

.tw-accent-rose-600\/80 {
  accent-color: rgb(225 29 72 / 0.8) !important;
}

.tw-accent-rose-600\/85 {
  accent-color: rgb(225 29 72 / 0.85) !important;
}

.tw-accent-rose-600\/90 {
  accent-color: rgb(225 29 72 / 0.9) !important;
}

.tw-accent-rose-600\/95 {
  accent-color: rgb(225 29 72 / 0.95) !important;
}

.tw-accent-rose-700 {
  accent-color: #be123c !important;
}

.tw-accent-rose-700\/0 {
  accent-color: rgb(190 18 60 / 0) !important;
}

.tw-accent-rose-700\/10 {
  accent-color: rgb(190 18 60 / 0.1) !important;
}

.tw-accent-rose-700\/100 {
  accent-color: rgb(190 18 60 / 1) !important;
}

.tw-accent-rose-700\/15 {
  accent-color: rgb(190 18 60 / 0.15) !important;
}

.tw-accent-rose-700\/20 {
  accent-color: rgb(190 18 60 / 0.2) !important;
}

.tw-accent-rose-700\/25 {
  accent-color: rgb(190 18 60 / 0.25) !important;
}

.tw-accent-rose-700\/30 {
  accent-color: rgb(190 18 60 / 0.3) !important;
}

.tw-accent-rose-700\/35 {
  accent-color: rgb(190 18 60 / 0.35) !important;
}

.tw-accent-rose-700\/40 {
  accent-color: rgb(190 18 60 / 0.4) !important;
}

.tw-accent-rose-700\/45 {
  accent-color: rgb(190 18 60 / 0.45) !important;
}

.tw-accent-rose-700\/5 {
  accent-color: rgb(190 18 60 / 0.05) !important;
}

.tw-accent-rose-700\/50 {
  accent-color: rgb(190 18 60 / 0.5) !important;
}

.tw-accent-rose-700\/55 {
  accent-color: rgb(190 18 60 / 0.55) !important;
}

.tw-accent-rose-700\/60 {
  accent-color: rgb(190 18 60 / 0.6) !important;
}

.tw-accent-rose-700\/65 {
  accent-color: rgb(190 18 60 / 0.65) !important;
}

.tw-accent-rose-700\/70 {
  accent-color: rgb(190 18 60 / 0.7) !important;
}

.tw-accent-rose-700\/75 {
  accent-color: rgb(190 18 60 / 0.75) !important;
}

.tw-accent-rose-700\/80 {
  accent-color: rgb(190 18 60 / 0.8) !important;
}

.tw-accent-rose-700\/85 {
  accent-color: rgb(190 18 60 / 0.85) !important;
}

.tw-accent-rose-700\/90 {
  accent-color: rgb(190 18 60 / 0.9) !important;
}

.tw-accent-rose-700\/95 {
  accent-color: rgb(190 18 60 / 0.95) !important;
}

.tw-accent-rose-800 {
  accent-color: #9f1239 !important;
}

.tw-accent-rose-800\/0 {
  accent-color: rgb(159 18 57 / 0) !important;
}

.tw-accent-rose-800\/10 {
  accent-color: rgb(159 18 57 / 0.1) !important;
}

.tw-accent-rose-800\/100 {
  accent-color: rgb(159 18 57 / 1) !important;
}

.tw-accent-rose-800\/15 {
  accent-color: rgb(159 18 57 / 0.15) !important;
}

.tw-accent-rose-800\/20 {
  accent-color: rgb(159 18 57 / 0.2) !important;
}

.tw-accent-rose-800\/25 {
  accent-color: rgb(159 18 57 / 0.25) !important;
}

.tw-accent-rose-800\/30 {
  accent-color: rgb(159 18 57 / 0.3) !important;
}

.tw-accent-rose-800\/35 {
  accent-color: rgb(159 18 57 / 0.35) !important;
}

.tw-accent-rose-800\/40 {
  accent-color: rgb(159 18 57 / 0.4) !important;
}

.tw-accent-rose-800\/45 {
  accent-color: rgb(159 18 57 / 0.45) !important;
}

.tw-accent-rose-800\/5 {
  accent-color: rgb(159 18 57 / 0.05) !important;
}

.tw-accent-rose-800\/50 {
  accent-color: rgb(159 18 57 / 0.5) !important;
}

.tw-accent-rose-800\/55 {
  accent-color: rgb(159 18 57 / 0.55) !important;
}

.tw-accent-rose-800\/60 {
  accent-color: rgb(159 18 57 / 0.6) !important;
}

.tw-accent-rose-800\/65 {
  accent-color: rgb(159 18 57 / 0.65) !important;
}

.tw-accent-rose-800\/70 {
  accent-color: rgb(159 18 57 / 0.7) !important;
}

.tw-accent-rose-800\/75 {
  accent-color: rgb(159 18 57 / 0.75) !important;
}

.tw-accent-rose-800\/80 {
  accent-color: rgb(159 18 57 / 0.8) !important;
}

.tw-accent-rose-800\/85 {
  accent-color: rgb(159 18 57 / 0.85) !important;
}

.tw-accent-rose-800\/90 {
  accent-color: rgb(159 18 57 / 0.9) !important;
}

.tw-accent-rose-800\/95 {
  accent-color: rgb(159 18 57 / 0.95) !important;
}

.tw-accent-rose-900 {
  accent-color: #881337 !important;
}

.tw-accent-rose-900\/0 {
  accent-color: rgb(136 19 55 / 0) !important;
}

.tw-accent-rose-900\/10 {
  accent-color: rgb(136 19 55 / 0.1) !important;
}

.tw-accent-rose-900\/100 {
  accent-color: rgb(136 19 55 / 1) !important;
}

.tw-accent-rose-900\/15 {
  accent-color: rgb(136 19 55 / 0.15) !important;
}

.tw-accent-rose-900\/20 {
  accent-color: rgb(136 19 55 / 0.2) !important;
}

.tw-accent-rose-900\/25 {
  accent-color: rgb(136 19 55 / 0.25) !important;
}

.tw-accent-rose-900\/30 {
  accent-color: rgb(136 19 55 / 0.3) !important;
}

.tw-accent-rose-900\/35 {
  accent-color: rgb(136 19 55 / 0.35) !important;
}

.tw-accent-rose-900\/40 {
  accent-color: rgb(136 19 55 / 0.4) !important;
}

.tw-accent-rose-900\/45 {
  accent-color: rgb(136 19 55 / 0.45) !important;
}

.tw-accent-rose-900\/5 {
  accent-color: rgb(136 19 55 / 0.05) !important;
}

.tw-accent-rose-900\/50 {
  accent-color: rgb(136 19 55 / 0.5) !important;
}

.tw-accent-rose-900\/55 {
  accent-color: rgb(136 19 55 / 0.55) !important;
}

.tw-accent-rose-900\/60 {
  accent-color: rgb(136 19 55 / 0.6) !important;
}

.tw-accent-rose-900\/65 {
  accent-color: rgb(136 19 55 / 0.65) !important;
}

.tw-accent-rose-900\/70 {
  accent-color: rgb(136 19 55 / 0.7) !important;
}

.tw-accent-rose-900\/75 {
  accent-color: rgb(136 19 55 / 0.75) !important;
}

.tw-accent-rose-900\/80 {
  accent-color: rgb(136 19 55 / 0.8) !important;
}

.tw-accent-rose-900\/85 {
  accent-color: rgb(136 19 55 / 0.85) !important;
}

.tw-accent-rose-900\/90 {
  accent-color: rgb(136 19 55 / 0.9) !important;
}

.tw-accent-rose-900\/95 {
  accent-color: rgb(136 19 55 / 0.95) !important;
}

.tw-accent-rose-950 {
  accent-color: #4c0519 !important;
}

.tw-accent-rose-950\/0 {
  accent-color: rgb(76 5 25 / 0) !important;
}

.tw-accent-rose-950\/10 {
  accent-color: rgb(76 5 25 / 0.1) !important;
}

.tw-accent-rose-950\/100 {
  accent-color: rgb(76 5 25 / 1) !important;
}

.tw-accent-rose-950\/15 {
  accent-color: rgb(76 5 25 / 0.15) !important;
}

.tw-accent-rose-950\/20 {
  accent-color: rgb(76 5 25 / 0.2) !important;
}

.tw-accent-rose-950\/25 {
  accent-color: rgb(76 5 25 / 0.25) !important;
}

.tw-accent-rose-950\/30 {
  accent-color: rgb(76 5 25 / 0.3) !important;
}

.tw-accent-rose-950\/35 {
  accent-color: rgb(76 5 25 / 0.35) !important;
}

.tw-accent-rose-950\/40 {
  accent-color: rgb(76 5 25 / 0.4) !important;
}

.tw-accent-rose-950\/45 {
  accent-color: rgb(76 5 25 / 0.45) !important;
}

.tw-accent-rose-950\/5 {
  accent-color: rgb(76 5 25 / 0.05) !important;
}

.tw-accent-rose-950\/50 {
  accent-color: rgb(76 5 25 / 0.5) !important;
}

.tw-accent-rose-950\/55 {
  accent-color: rgb(76 5 25 / 0.55) !important;
}

.tw-accent-rose-950\/60 {
  accent-color: rgb(76 5 25 / 0.6) !important;
}

.tw-accent-rose-950\/65 {
  accent-color: rgb(76 5 25 / 0.65) !important;
}

.tw-accent-rose-950\/70 {
  accent-color: rgb(76 5 25 / 0.7) !important;
}

.tw-accent-rose-950\/75 {
  accent-color: rgb(76 5 25 / 0.75) !important;
}

.tw-accent-rose-950\/80 {
  accent-color: rgb(76 5 25 / 0.8) !important;
}

.tw-accent-rose-950\/85 {
  accent-color: rgb(76 5 25 / 0.85) !important;
}

.tw-accent-rose-950\/90 {
  accent-color: rgb(76 5 25 / 0.9) !important;
}

.tw-accent-rose-950\/95 {
  accent-color: rgb(76 5 25 / 0.95) !important;
}

.tw-accent-sky-100 {
  accent-color: #e0f2fe !important;
}

.tw-accent-sky-100\/0 {
  accent-color: rgb(224 242 254 / 0) !important;
}

.tw-accent-sky-100\/10 {
  accent-color: rgb(224 242 254 / 0.1) !important;
}

.tw-accent-sky-100\/100 {
  accent-color: rgb(224 242 254 / 1) !important;
}

.tw-accent-sky-100\/15 {
  accent-color: rgb(224 242 254 / 0.15) !important;
}

.tw-accent-sky-100\/20 {
  accent-color: rgb(224 242 254 / 0.2) !important;
}

.tw-accent-sky-100\/25 {
  accent-color: rgb(224 242 254 / 0.25) !important;
}

.tw-accent-sky-100\/30 {
  accent-color: rgb(224 242 254 / 0.3) !important;
}

.tw-accent-sky-100\/35 {
  accent-color: rgb(224 242 254 / 0.35) !important;
}

.tw-accent-sky-100\/40 {
  accent-color: rgb(224 242 254 / 0.4) !important;
}

.tw-accent-sky-100\/45 {
  accent-color: rgb(224 242 254 / 0.45) !important;
}

.tw-accent-sky-100\/5 {
  accent-color: rgb(224 242 254 / 0.05) !important;
}

.tw-accent-sky-100\/50 {
  accent-color: rgb(224 242 254 / 0.5) !important;
}

.tw-accent-sky-100\/55 {
  accent-color: rgb(224 242 254 / 0.55) !important;
}

.tw-accent-sky-100\/60 {
  accent-color: rgb(224 242 254 / 0.6) !important;
}

.tw-accent-sky-100\/65 {
  accent-color: rgb(224 242 254 / 0.65) !important;
}

.tw-accent-sky-100\/70 {
  accent-color: rgb(224 242 254 / 0.7) !important;
}

.tw-accent-sky-100\/75 {
  accent-color: rgb(224 242 254 / 0.75) !important;
}

.tw-accent-sky-100\/80 {
  accent-color: rgb(224 242 254 / 0.8) !important;
}

.tw-accent-sky-100\/85 {
  accent-color: rgb(224 242 254 / 0.85) !important;
}

.tw-accent-sky-100\/90 {
  accent-color: rgb(224 242 254 / 0.9) !important;
}

.tw-accent-sky-100\/95 {
  accent-color: rgb(224 242 254 / 0.95) !important;
}

.tw-accent-sky-200 {
  accent-color: #bae6fd !important;
}

.tw-accent-sky-200\/0 {
  accent-color: rgb(186 230 253 / 0) !important;
}

.tw-accent-sky-200\/10 {
  accent-color: rgb(186 230 253 / 0.1) !important;
}

.tw-accent-sky-200\/100 {
  accent-color: rgb(186 230 253 / 1) !important;
}

.tw-accent-sky-200\/15 {
  accent-color: rgb(186 230 253 / 0.15) !important;
}

.tw-accent-sky-200\/20 {
  accent-color: rgb(186 230 253 / 0.2) !important;
}

.tw-accent-sky-200\/25 {
  accent-color: rgb(186 230 253 / 0.25) !important;
}

.tw-accent-sky-200\/30 {
  accent-color: rgb(186 230 253 / 0.3) !important;
}

.tw-accent-sky-200\/35 {
  accent-color: rgb(186 230 253 / 0.35) !important;
}

.tw-accent-sky-200\/40 {
  accent-color: rgb(186 230 253 / 0.4) !important;
}

.tw-accent-sky-200\/45 {
  accent-color: rgb(186 230 253 / 0.45) !important;
}

.tw-accent-sky-200\/5 {
  accent-color: rgb(186 230 253 / 0.05) !important;
}

.tw-accent-sky-200\/50 {
  accent-color: rgb(186 230 253 / 0.5) !important;
}

.tw-accent-sky-200\/55 {
  accent-color: rgb(186 230 253 / 0.55) !important;
}

.tw-accent-sky-200\/60 {
  accent-color: rgb(186 230 253 / 0.6) !important;
}

.tw-accent-sky-200\/65 {
  accent-color: rgb(186 230 253 / 0.65) !important;
}

.tw-accent-sky-200\/70 {
  accent-color: rgb(186 230 253 / 0.7) !important;
}

.tw-accent-sky-200\/75 {
  accent-color: rgb(186 230 253 / 0.75) !important;
}

.tw-accent-sky-200\/80 {
  accent-color: rgb(186 230 253 / 0.8) !important;
}

.tw-accent-sky-200\/85 {
  accent-color: rgb(186 230 253 / 0.85) !important;
}

.tw-accent-sky-200\/90 {
  accent-color: rgb(186 230 253 / 0.9) !important;
}

.tw-accent-sky-200\/95 {
  accent-color: rgb(186 230 253 / 0.95) !important;
}

.tw-accent-sky-300 {
  accent-color: #7dd3fc !important;
}

.tw-accent-sky-300\/0 {
  accent-color: rgb(125 211 252 / 0) !important;
}

.tw-accent-sky-300\/10 {
  accent-color: rgb(125 211 252 / 0.1) !important;
}

.tw-accent-sky-300\/100 {
  accent-color: rgb(125 211 252 / 1) !important;
}

.tw-accent-sky-300\/15 {
  accent-color: rgb(125 211 252 / 0.15) !important;
}

.tw-accent-sky-300\/20 {
  accent-color: rgb(125 211 252 / 0.2) !important;
}

.tw-accent-sky-300\/25 {
  accent-color: rgb(125 211 252 / 0.25) !important;
}

.tw-accent-sky-300\/30 {
  accent-color: rgb(125 211 252 / 0.3) !important;
}

.tw-accent-sky-300\/35 {
  accent-color: rgb(125 211 252 / 0.35) !important;
}

.tw-accent-sky-300\/40 {
  accent-color: rgb(125 211 252 / 0.4) !important;
}

.tw-accent-sky-300\/45 {
  accent-color: rgb(125 211 252 / 0.45) !important;
}

.tw-accent-sky-300\/5 {
  accent-color: rgb(125 211 252 / 0.05) !important;
}

.tw-accent-sky-300\/50 {
  accent-color: rgb(125 211 252 / 0.5) !important;
}

.tw-accent-sky-300\/55 {
  accent-color: rgb(125 211 252 / 0.55) !important;
}

.tw-accent-sky-300\/60 {
  accent-color: rgb(125 211 252 / 0.6) !important;
}

.tw-accent-sky-300\/65 {
  accent-color: rgb(125 211 252 / 0.65) !important;
}

.tw-accent-sky-300\/70 {
  accent-color: rgb(125 211 252 / 0.7) !important;
}

.tw-accent-sky-300\/75 {
  accent-color: rgb(125 211 252 / 0.75) !important;
}

.tw-accent-sky-300\/80 {
  accent-color: rgb(125 211 252 / 0.8) !important;
}

.tw-accent-sky-300\/85 {
  accent-color: rgb(125 211 252 / 0.85) !important;
}

.tw-accent-sky-300\/90 {
  accent-color: rgb(125 211 252 / 0.9) !important;
}

.tw-accent-sky-300\/95 {
  accent-color: rgb(125 211 252 / 0.95) !important;
}

.tw-accent-sky-400 {
  accent-color: #38bdf8 !important;
}

.tw-accent-sky-400\/0 {
  accent-color: rgb(56 189 248 / 0) !important;
}

.tw-accent-sky-400\/10 {
  accent-color: rgb(56 189 248 / 0.1) !important;
}

.tw-accent-sky-400\/100 {
  accent-color: rgb(56 189 248 / 1) !important;
}

.tw-accent-sky-400\/15 {
  accent-color: rgb(56 189 248 / 0.15) !important;
}

.tw-accent-sky-400\/20 {
  accent-color: rgb(56 189 248 / 0.2) !important;
}

.tw-accent-sky-400\/25 {
  accent-color: rgb(56 189 248 / 0.25) !important;
}

.tw-accent-sky-400\/30 {
  accent-color: rgb(56 189 248 / 0.3) !important;
}

.tw-accent-sky-400\/35 {
  accent-color: rgb(56 189 248 / 0.35) !important;
}

.tw-accent-sky-400\/40 {
  accent-color: rgb(56 189 248 / 0.4) !important;
}

.tw-accent-sky-400\/45 {
  accent-color: rgb(56 189 248 / 0.45) !important;
}

.tw-accent-sky-400\/5 {
  accent-color: rgb(56 189 248 / 0.05) !important;
}

.tw-accent-sky-400\/50 {
  accent-color: rgb(56 189 248 / 0.5) !important;
}

.tw-accent-sky-400\/55 {
  accent-color: rgb(56 189 248 / 0.55) !important;
}

.tw-accent-sky-400\/60 {
  accent-color: rgb(56 189 248 / 0.6) !important;
}

.tw-accent-sky-400\/65 {
  accent-color: rgb(56 189 248 / 0.65) !important;
}

.tw-accent-sky-400\/70 {
  accent-color: rgb(56 189 248 / 0.7) !important;
}

.tw-accent-sky-400\/75 {
  accent-color: rgb(56 189 248 / 0.75) !important;
}

.tw-accent-sky-400\/80 {
  accent-color: rgb(56 189 248 / 0.8) !important;
}

.tw-accent-sky-400\/85 {
  accent-color: rgb(56 189 248 / 0.85) !important;
}

.tw-accent-sky-400\/90 {
  accent-color: rgb(56 189 248 / 0.9) !important;
}

.tw-accent-sky-400\/95 {
  accent-color: rgb(56 189 248 / 0.95) !important;
}

.tw-accent-sky-50 {
  accent-color: #f0f9ff !important;
}

.tw-accent-sky-50\/0 {
  accent-color: rgb(240 249 255 / 0) !important;
}

.tw-accent-sky-50\/10 {
  accent-color: rgb(240 249 255 / 0.1) !important;
}

.tw-accent-sky-50\/100 {
  accent-color: rgb(240 249 255 / 1) !important;
}

.tw-accent-sky-50\/15 {
  accent-color: rgb(240 249 255 / 0.15) !important;
}

.tw-accent-sky-50\/20 {
  accent-color: rgb(240 249 255 / 0.2) !important;
}

.tw-accent-sky-50\/25 {
  accent-color: rgb(240 249 255 / 0.25) !important;
}

.tw-accent-sky-50\/30 {
  accent-color: rgb(240 249 255 / 0.3) !important;
}

.tw-accent-sky-50\/35 {
  accent-color: rgb(240 249 255 / 0.35) !important;
}

.tw-accent-sky-50\/40 {
  accent-color: rgb(240 249 255 / 0.4) !important;
}

.tw-accent-sky-50\/45 {
  accent-color: rgb(240 249 255 / 0.45) !important;
}

.tw-accent-sky-50\/5 {
  accent-color: rgb(240 249 255 / 0.05) !important;
}

.tw-accent-sky-50\/50 {
  accent-color: rgb(240 249 255 / 0.5) !important;
}

.tw-accent-sky-50\/55 {
  accent-color: rgb(240 249 255 / 0.55) !important;
}

.tw-accent-sky-50\/60 {
  accent-color: rgb(240 249 255 / 0.6) !important;
}

.tw-accent-sky-50\/65 {
  accent-color: rgb(240 249 255 / 0.65) !important;
}

.tw-accent-sky-50\/70 {
  accent-color: rgb(240 249 255 / 0.7) !important;
}

.tw-accent-sky-50\/75 {
  accent-color: rgb(240 249 255 / 0.75) !important;
}

.tw-accent-sky-50\/80 {
  accent-color: rgb(240 249 255 / 0.8) !important;
}

.tw-accent-sky-50\/85 {
  accent-color: rgb(240 249 255 / 0.85) !important;
}

.tw-accent-sky-50\/90 {
  accent-color: rgb(240 249 255 / 0.9) !important;
}

.tw-accent-sky-50\/95 {
  accent-color: rgb(240 249 255 / 0.95) !important;
}

.tw-accent-sky-500 {
  accent-color: #0ea5e9 !important;
}

.tw-accent-sky-500\/0 {
  accent-color: rgb(14 165 233 / 0) !important;
}

.tw-accent-sky-500\/10 {
  accent-color: rgb(14 165 233 / 0.1) !important;
}

.tw-accent-sky-500\/100 {
  accent-color: rgb(14 165 233 / 1) !important;
}

.tw-accent-sky-500\/15 {
  accent-color: rgb(14 165 233 / 0.15) !important;
}

.tw-accent-sky-500\/20 {
  accent-color: rgb(14 165 233 / 0.2) !important;
}

.tw-accent-sky-500\/25 {
  accent-color: rgb(14 165 233 / 0.25) !important;
}

.tw-accent-sky-500\/30 {
  accent-color: rgb(14 165 233 / 0.3) !important;
}

.tw-accent-sky-500\/35 {
  accent-color: rgb(14 165 233 / 0.35) !important;
}

.tw-accent-sky-500\/40 {
  accent-color: rgb(14 165 233 / 0.4) !important;
}

.tw-accent-sky-500\/45 {
  accent-color: rgb(14 165 233 / 0.45) !important;
}

.tw-accent-sky-500\/5 {
  accent-color: rgb(14 165 233 / 0.05) !important;
}

.tw-accent-sky-500\/50 {
  accent-color: rgb(14 165 233 / 0.5) !important;
}

.tw-accent-sky-500\/55 {
  accent-color: rgb(14 165 233 / 0.55) !important;
}

.tw-accent-sky-500\/60 {
  accent-color: rgb(14 165 233 / 0.6) !important;
}

.tw-accent-sky-500\/65 {
  accent-color: rgb(14 165 233 / 0.65) !important;
}

.tw-accent-sky-500\/70 {
  accent-color: rgb(14 165 233 / 0.7) !important;
}

.tw-accent-sky-500\/75 {
  accent-color: rgb(14 165 233 / 0.75) !important;
}

.tw-accent-sky-500\/80 {
  accent-color: rgb(14 165 233 / 0.8) !important;
}

.tw-accent-sky-500\/85 {
  accent-color: rgb(14 165 233 / 0.85) !important;
}

.tw-accent-sky-500\/90 {
  accent-color: rgb(14 165 233 / 0.9) !important;
}

.tw-accent-sky-500\/95 {
  accent-color: rgb(14 165 233 / 0.95) !important;
}

.tw-accent-sky-600 {
  accent-color: #0284c7 !important;
}

.tw-accent-sky-600\/0 {
  accent-color: rgb(2 132 199 / 0) !important;
}

.tw-accent-sky-600\/10 {
  accent-color: rgb(2 132 199 / 0.1) !important;
}

.tw-accent-sky-600\/100 {
  accent-color: rgb(2 132 199 / 1) !important;
}

.tw-accent-sky-600\/15 {
  accent-color: rgb(2 132 199 / 0.15) !important;
}

.tw-accent-sky-600\/20 {
  accent-color: rgb(2 132 199 / 0.2) !important;
}

.tw-accent-sky-600\/25 {
  accent-color: rgb(2 132 199 / 0.25) !important;
}

.tw-accent-sky-600\/30 {
  accent-color: rgb(2 132 199 / 0.3) !important;
}

.tw-accent-sky-600\/35 {
  accent-color: rgb(2 132 199 / 0.35) !important;
}

.tw-accent-sky-600\/40 {
  accent-color: rgb(2 132 199 / 0.4) !important;
}

.tw-accent-sky-600\/45 {
  accent-color: rgb(2 132 199 / 0.45) !important;
}

.tw-accent-sky-600\/5 {
  accent-color: rgb(2 132 199 / 0.05) !important;
}

.tw-accent-sky-600\/50 {
  accent-color: rgb(2 132 199 / 0.5) !important;
}

.tw-accent-sky-600\/55 {
  accent-color: rgb(2 132 199 / 0.55) !important;
}

.tw-accent-sky-600\/60 {
  accent-color: rgb(2 132 199 / 0.6) !important;
}

.tw-accent-sky-600\/65 {
  accent-color: rgb(2 132 199 / 0.65) !important;
}

.tw-accent-sky-600\/70 {
  accent-color: rgb(2 132 199 / 0.7) !important;
}

.tw-accent-sky-600\/75 {
  accent-color: rgb(2 132 199 / 0.75) !important;
}

.tw-accent-sky-600\/80 {
  accent-color: rgb(2 132 199 / 0.8) !important;
}

.tw-accent-sky-600\/85 {
  accent-color: rgb(2 132 199 / 0.85) !important;
}

.tw-accent-sky-600\/90 {
  accent-color: rgb(2 132 199 / 0.9) !important;
}

.tw-accent-sky-600\/95 {
  accent-color: rgb(2 132 199 / 0.95) !important;
}

.tw-accent-sky-700 {
  accent-color: #0369a1 !important;
}

.tw-accent-sky-700\/0 {
  accent-color: rgb(3 105 161 / 0) !important;
}

.tw-accent-sky-700\/10 {
  accent-color: rgb(3 105 161 / 0.1) !important;
}

.tw-accent-sky-700\/100 {
  accent-color: rgb(3 105 161 / 1) !important;
}

.tw-accent-sky-700\/15 {
  accent-color: rgb(3 105 161 / 0.15) !important;
}

.tw-accent-sky-700\/20 {
  accent-color: rgb(3 105 161 / 0.2) !important;
}

.tw-accent-sky-700\/25 {
  accent-color: rgb(3 105 161 / 0.25) !important;
}

.tw-accent-sky-700\/30 {
  accent-color: rgb(3 105 161 / 0.3) !important;
}

.tw-accent-sky-700\/35 {
  accent-color: rgb(3 105 161 / 0.35) !important;
}

.tw-accent-sky-700\/40 {
  accent-color: rgb(3 105 161 / 0.4) !important;
}

.tw-accent-sky-700\/45 {
  accent-color: rgb(3 105 161 / 0.45) !important;
}

.tw-accent-sky-700\/5 {
  accent-color: rgb(3 105 161 / 0.05) !important;
}

.tw-accent-sky-700\/50 {
  accent-color: rgb(3 105 161 / 0.5) !important;
}

.tw-accent-sky-700\/55 {
  accent-color: rgb(3 105 161 / 0.55) !important;
}

.tw-accent-sky-700\/60 {
  accent-color: rgb(3 105 161 / 0.6) !important;
}

.tw-accent-sky-700\/65 {
  accent-color: rgb(3 105 161 / 0.65) !important;
}

.tw-accent-sky-700\/70 {
  accent-color: rgb(3 105 161 / 0.7) !important;
}

.tw-accent-sky-700\/75 {
  accent-color: rgb(3 105 161 / 0.75) !important;
}

.tw-accent-sky-700\/80 {
  accent-color: rgb(3 105 161 / 0.8) !important;
}

.tw-accent-sky-700\/85 {
  accent-color: rgb(3 105 161 / 0.85) !important;
}

.tw-accent-sky-700\/90 {
  accent-color: rgb(3 105 161 / 0.9) !important;
}

.tw-accent-sky-700\/95 {
  accent-color: rgb(3 105 161 / 0.95) !important;
}

.tw-accent-sky-800 {
  accent-color: #075985 !important;
}

.tw-accent-sky-800\/0 {
  accent-color: rgb(7 89 133 / 0) !important;
}

.tw-accent-sky-800\/10 {
  accent-color: rgb(7 89 133 / 0.1) !important;
}

.tw-accent-sky-800\/100 {
  accent-color: rgb(7 89 133 / 1) !important;
}

.tw-accent-sky-800\/15 {
  accent-color: rgb(7 89 133 / 0.15) !important;
}

.tw-accent-sky-800\/20 {
  accent-color: rgb(7 89 133 / 0.2) !important;
}

.tw-accent-sky-800\/25 {
  accent-color: rgb(7 89 133 / 0.25) !important;
}

.tw-accent-sky-800\/30 {
  accent-color: rgb(7 89 133 / 0.3) !important;
}

.tw-accent-sky-800\/35 {
  accent-color: rgb(7 89 133 / 0.35) !important;
}

.tw-accent-sky-800\/40 {
  accent-color: rgb(7 89 133 / 0.4) !important;
}

.tw-accent-sky-800\/45 {
  accent-color: rgb(7 89 133 / 0.45) !important;
}

.tw-accent-sky-800\/5 {
  accent-color: rgb(7 89 133 / 0.05) !important;
}

.tw-accent-sky-800\/50 {
  accent-color: rgb(7 89 133 / 0.5) !important;
}

.tw-accent-sky-800\/55 {
  accent-color: rgb(7 89 133 / 0.55) !important;
}

.tw-accent-sky-800\/60 {
  accent-color: rgb(7 89 133 / 0.6) !important;
}

.tw-accent-sky-800\/65 {
  accent-color: rgb(7 89 133 / 0.65) !important;
}

.tw-accent-sky-800\/70 {
  accent-color: rgb(7 89 133 / 0.7) !important;
}

.tw-accent-sky-800\/75 {
  accent-color: rgb(7 89 133 / 0.75) !important;
}

.tw-accent-sky-800\/80 {
  accent-color: rgb(7 89 133 / 0.8) !important;
}

.tw-accent-sky-800\/85 {
  accent-color: rgb(7 89 133 / 0.85) !important;
}

.tw-accent-sky-800\/90 {
  accent-color: rgb(7 89 133 / 0.9) !important;
}

.tw-accent-sky-800\/95 {
  accent-color: rgb(7 89 133 / 0.95) !important;
}

.tw-accent-sky-900 {
  accent-color: #0c4a6e !important;
}

.tw-accent-sky-900\/0 {
  accent-color: rgb(12 74 110 / 0) !important;
}

.tw-accent-sky-900\/10 {
  accent-color: rgb(12 74 110 / 0.1) !important;
}

.tw-accent-sky-900\/100 {
  accent-color: rgb(12 74 110 / 1) !important;
}

.tw-accent-sky-900\/15 {
  accent-color: rgb(12 74 110 / 0.15) !important;
}

.tw-accent-sky-900\/20 {
  accent-color: rgb(12 74 110 / 0.2) !important;
}

.tw-accent-sky-900\/25 {
  accent-color: rgb(12 74 110 / 0.25) !important;
}

.tw-accent-sky-900\/30 {
  accent-color: rgb(12 74 110 / 0.3) !important;
}

.tw-accent-sky-900\/35 {
  accent-color: rgb(12 74 110 / 0.35) !important;
}

.tw-accent-sky-900\/40 {
  accent-color: rgb(12 74 110 / 0.4) !important;
}

.tw-accent-sky-900\/45 {
  accent-color: rgb(12 74 110 / 0.45) !important;
}

.tw-accent-sky-900\/5 {
  accent-color: rgb(12 74 110 / 0.05) !important;
}

.tw-accent-sky-900\/50 {
  accent-color: rgb(12 74 110 / 0.5) !important;
}

.tw-accent-sky-900\/55 {
  accent-color: rgb(12 74 110 / 0.55) !important;
}

.tw-accent-sky-900\/60 {
  accent-color: rgb(12 74 110 / 0.6) !important;
}

.tw-accent-sky-900\/65 {
  accent-color: rgb(12 74 110 / 0.65) !important;
}

.tw-accent-sky-900\/70 {
  accent-color: rgb(12 74 110 / 0.7) !important;
}

.tw-accent-sky-900\/75 {
  accent-color: rgb(12 74 110 / 0.75) !important;
}

.tw-accent-sky-900\/80 {
  accent-color: rgb(12 74 110 / 0.8) !important;
}

.tw-accent-sky-900\/85 {
  accent-color: rgb(12 74 110 / 0.85) !important;
}

.tw-accent-sky-900\/90 {
  accent-color: rgb(12 74 110 / 0.9) !important;
}

.tw-accent-sky-900\/95 {
  accent-color: rgb(12 74 110 / 0.95) !important;
}

.tw-accent-sky-950 {
  accent-color: #082f49 !important;
}

.tw-accent-sky-950\/0 {
  accent-color: rgb(8 47 73 / 0) !important;
}

.tw-accent-sky-950\/10 {
  accent-color: rgb(8 47 73 / 0.1) !important;
}

.tw-accent-sky-950\/100 {
  accent-color: rgb(8 47 73 / 1) !important;
}

.tw-accent-sky-950\/15 {
  accent-color: rgb(8 47 73 / 0.15) !important;
}

.tw-accent-sky-950\/20 {
  accent-color: rgb(8 47 73 / 0.2) !important;
}

.tw-accent-sky-950\/25 {
  accent-color: rgb(8 47 73 / 0.25) !important;
}

.tw-accent-sky-950\/30 {
  accent-color: rgb(8 47 73 / 0.3) !important;
}

.tw-accent-sky-950\/35 {
  accent-color: rgb(8 47 73 / 0.35) !important;
}

.tw-accent-sky-950\/40 {
  accent-color: rgb(8 47 73 / 0.4) !important;
}

.tw-accent-sky-950\/45 {
  accent-color: rgb(8 47 73 / 0.45) !important;
}

.tw-accent-sky-950\/5 {
  accent-color: rgb(8 47 73 / 0.05) !important;
}

.tw-accent-sky-950\/50 {
  accent-color: rgb(8 47 73 / 0.5) !important;
}

.tw-accent-sky-950\/55 {
  accent-color: rgb(8 47 73 / 0.55) !important;
}

.tw-accent-sky-950\/60 {
  accent-color: rgb(8 47 73 / 0.6) !important;
}

.tw-accent-sky-950\/65 {
  accent-color: rgb(8 47 73 / 0.65) !important;
}

.tw-accent-sky-950\/70 {
  accent-color: rgb(8 47 73 / 0.7) !important;
}

.tw-accent-sky-950\/75 {
  accent-color: rgb(8 47 73 / 0.75) !important;
}

.tw-accent-sky-950\/80 {
  accent-color: rgb(8 47 73 / 0.8) !important;
}

.tw-accent-sky-950\/85 {
  accent-color: rgb(8 47 73 / 0.85) !important;
}

.tw-accent-sky-950\/90 {
  accent-color: rgb(8 47 73 / 0.9) !important;
}

.tw-accent-sky-950\/95 {
  accent-color: rgb(8 47 73 / 0.95) !important;
}

.tw-accent-slate-100 {
  accent-color: #f1f5f9 !important;
}

.tw-accent-slate-100\/0 {
  accent-color: rgb(241 245 249 / 0) !important;
}

.tw-accent-slate-100\/10 {
  accent-color: rgb(241 245 249 / 0.1) !important;
}

.tw-accent-slate-100\/100 {
  accent-color: rgb(241 245 249 / 1) !important;
}

.tw-accent-slate-100\/15 {
  accent-color: rgb(241 245 249 / 0.15) !important;
}

.tw-accent-slate-100\/20 {
  accent-color: rgb(241 245 249 / 0.2) !important;
}

.tw-accent-slate-100\/25 {
  accent-color: rgb(241 245 249 / 0.25) !important;
}

.tw-accent-slate-100\/30 {
  accent-color: rgb(241 245 249 / 0.3) !important;
}

.tw-accent-slate-100\/35 {
  accent-color: rgb(241 245 249 / 0.35) !important;
}

.tw-accent-slate-100\/40 {
  accent-color: rgb(241 245 249 / 0.4) !important;
}

.tw-accent-slate-100\/45 {
  accent-color: rgb(241 245 249 / 0.45) !important;
}

.tw-accent-slate-100\/5 {
  accent-color: rgb(241 245 249 / 0.05) !important;
}

.tw-accent-slate-100\/50 {
  accent-color: rgb(241 245 249 / 0.5) !important;
}

.tw-accent-slate-100\/55 {
  accent-color: rgb(241 245 249 / 0.55) !important;
}

.tw-accent-slate-100\/60 {
  accent-color: rgb(241 245 249 / 0.6) !important;
}

.tw-accent-slate-100\/65 {
  accent-color: rgb(241 245 249 / 0.65) !important;
}

.tw-accent-slate-100\/70 {
  accent-color: rgb(241 245 249 / 0.7) !important;
}

.tw-accent-slate-100\/75 {
  accent-color: rgb(241 245 249 / 0.75) !important;
}

.tw-accent-slate-100\/80 {
  accent-color: rgb(241 245 249 / 0.8) !important;
}

.tw-accent-slate-100\/85 {
  accent-color: rgb(241 245 249 / 0.85) !important;
}

.tw-accent-slate-100\/90 {
  accent-color: rgb(241 245 249 / 0.9) !important;
}

.tw-accent-slate-100\/95 {
  accent-color: rgb(241 245 249 / 0.95) !important;
}

.tw-accent-slate-200 {
  accent-color: #e2e8f0 !important;
}

.tw-accent-slate-200\/0 {
  accent-color: rgb(226 232 240 / 0) !important;
}

.tw-accent-slate-200\/10 {
  accent-color: rgb(226 232 240 / 0.1) !important;
}

.tw-accent-slate-200\/100 {
  accent-color: rgb(226 232 240 / 1) !important;
}

.tw-accent-slate-200\/15 {
  accent-color: rgb(226 232 240 / 0.15) !important;
}

.tw-accent-slate-200\/20 {
  accent-color: rgb(226 232 240 / 0.2) !important;
}

.tw-accent-slate-200\/25 {
  accent-color: rgb(226 232 240 / 0.25) !important;
}

.tw-accent-slate-200\/30 {
  accent-color: rgb(226 232 240 / 0.3) !important;
}

.tw-accent-slate-200\/35 {
  accent-color: rgb(226 232 240 / 0.35) !important;
}

.tw-accent-slate-200\/40 {
  accent-color: rgb(226 232 240 / 0.4) !important;
}

.tw-accent-slate-200\/45 {
  accent-color: rgb(226 232 240 / 0.45) !important;
}

.tw-accent-slate-200\/5 {
  accent-color: rgb(226 232 240 / 0.05) !important;
}

.tw-accent-slate-200\/50 {
  accent-color: rgb(226 232 240 / 0.5) !important;
}

.tw-accent-slate-200\/55 {
  accent-color: rgb(226 232 240 / 0.55) !important;
}

.tw-accent-slate-200\/60 {
  accent-color: rgb(226 232 240 / 0.6) !important;
}

.tw-accent-slate-200\/65 {
  accent-color: rgb(226 232 240 / 0.65) !important;
}

.tw-accent-slate-200\/70 {
  accent-color: rgb(226 232 240 / 0.7) !important;
}

.tw-accent-slate-200\/75 {
  accent-color: rgb(226 232 240 / 0.75) !important;
}

.tw-accent-slate-200\/80 {
  accent-color: rgb(226 232 240 / 0.8) !important;
}

.tw-accent-slate-200\/85 {
  accent-color: rgb(226 232 240 / 0.85) !important;
}

.tw-accent-slate-200\/90 {
  accent-color: rgb(226 232 240 / 0.9) !important;
}

.tw-accent-slate-200\/95 {
  accent-color: rgb(226 232 240 / 0.95) !important;
}

.tw-accent-slate-300 {
  accent-color: #cbd5e1 !important;
}

.tw-accent-slate-300\/0 {
  accent-color: rgb(203 213 225 / 0) !important;
}

.tw-accent-slate-300\/10 {
  accent-color: rgb(203 213 225 / 0.1) !important;
}

.tw-accent-slate-300\/100 {
  accent-color: rgb(203 213 225 / 1) !important;
}

.tw-accent-slate-300\/15 {
  accent-color: rgb(203 213 225 / 0.15) !important;
}

.tw-accent-slate-300\/20 {
  accent-color: rgb(203 213 225 / 0.2) !important;
}

.tw-accent-slate-300\/25 {
  accent-color: rgb(203 213 225 / 0.25) !important;
}

.tw-accent-slate-300\/30 {
  accent-color: rgb(203 213 225 / 0.3) !important;
}

.tw-accent-slate-300\/35 {
  accent-color: rgb(203 213 225 / 0.35) !important;
}

.tw-accent-slate-300\/40 {
  accent-color: rgb(203 213 225 / 0.4) !important;
}

.tw-accent-slate-300\/45 {
  accent-color: rgb(203 213 225 / 0.45) !important;
}

.tw-accent-slate-300\/5 {
  accent-color: rgb(203 213 225 / 0.05) !important;
}

.tw-accent-slate-300\/50 {
  accent-color: rgb(203 213 225 / 0.5) !important;
}

.tw-accent-slate-300\/55 {
  accent-color: rgb(203 213 225 / 0.55) !important;
}

.tw-accent-slate-300\/60 {
  accent-color: rgb(203 213 225 / 0.6) !important;
}

.tw-accent-slate-300\/65 {
  accent-color: rgb(203 213 225 / 0.65) !important;
}

.tw-accent-slate-300\/70 {
  accent-color: rgb(203 213 225 / 0.7) !important;
}

.tw-accent-slate-300\/75 {
  accent-color: rgb(203 213 225 / 0.75) !important;
}

.tw-accent-slate-300\/80 {
  accent-color: rgb(203 213 225 / 0.8) !important;
}

.tw-accent-slate-300\/85 {
  accent-color: rgb(203 213 225 / 0.85) !important;
}

.tw-accent-slate-300\/90 {
  accent-color: rgb(203 213 225 / 0.9) !important;
}

.tw-accent-slate-300\/95 {
  accent-color: rgb(203 213 225 / 0.95) !important;
}

.tw-accent-slate-400 {
  accent-color: #94a3b8 !important;
}

.tw-accent-slate-400\/0 {
  accent-color: rgb(148 163 184 / 0) !important;
}

.tw-accent-slate-400\/10 {
  accent-color: rgb(148 163 184 / 0.1) !important;
}

.tw-accent-slate-400\/100 {
  accent-color: rgb(148 163 184 / 1) !important;
}

.tw-accent-slate-400\/15 {
  accent-color: rgb(148 163 184 / 0.15) !important;
}

.tw-accent-slate-400\/20 {
  accent-color: rgb(148 163 184 / 0.2) !important;
}

.tw-accent-slate-400\/25 {
  accent-color: rgb(148 163 184 / 0.25) !important;
}

.tw-accent-slate-400\/30 {
  accent-color: rgb(148 163 184 / 0.3) !important;
}

.tw-accent-slate-400\/35 {
  accent-color: rgb(148 163 184 / 0.35) !important;
}

.tw-accent-slate-400\/40 {
  accent-color: rgb(148 163 184 / 0.4) !important;
}

.tw-accent-slate-400\/45 {
  accent-color: rgb(148 163 184 / 0.45) !important;
}

.tw-accent-slate-400\/5 {
  accent-color: rgb(148 163 184 / 0.05) !important;
}

.tw-accent-slate-400\/50 {
  accent-color: rgb(148 163 184 / 0.5) !important;
}

.tw-accent-slate-400\/55 {
  accent-color: rgb(148 163 184 / 0.55) !important;
}

.tw-accent-slate-400\/60 {
  accent-color: rgb(148 163 184 / 0.6) !important;
}

.tw-accent-slate-400\/65 {
  accent-color: rgb(148 163 184 / 0.65) !important;
}

.tw-accent-slate-400\/70 {
  accent-color: rgb(148 163 184 / 0.7) !important;
}

.tw-accent-slate-400\/75 {
  accent-color: rgb(148 163 184 / 0.75) !important;
}

.tw-accent-slate-400\/80 {
  accent-color: rgb(148 163 184 / 0.8) !important;
}

.tw-accent-slate-400\/85 {
  accent-color: rgb(148 163 184 / 0.85) !important;
}

.tw-accent-slate-400\/90 {
  accent-color: rgb(148 163 184 / 0.9) !important;
}

.tw-accent-slate-400\/95 {
  accent-color: rgb(148 163 184 / 0.95) !important;
}

.tw-accent-slate-50 {
  accent-color: #f8fafc !important;
}

.tw-accent-slate-50\/0 {
  accent-color: rgb(248 250 252 / 0) !important;
}

.tw-accent-slate-50\/10 {
  accent-color: rgb(248 250 252 / 0.1) !important;
}

.tw-accent-slate-50\/100 {
  accent-color: rgb(248 250 252 / 1) !important;
}

.tw-accent-slate-50\/15 {
  accent-color: rgb(248 250 252 / 0.15) !important;
}

.tw-accent-slate-50\/20 {
  accent-color: rgb(248 250 252 / 0.2) !important;
}

.tw-accent-slate-50\/25 {
  accent-color: rgb(248 250 252 / 0.25) !important;
}

.tw-accent-slate-50\/30 {
  accent-color: rgb(248 250 252 / 0.3) !important;
}

.tw-accent-slate-50\/35 {
  accent-color: rgb(248 250 252 / 0.35) !important;
}

.tw-accent-slate-50\/40 {
  accent-color: rgb(248 250 252 / 0.4) !important;
}

.tw-accent-slate-50\/45 {
  accent-color: rgb(248 250 252 / 0.45) !important;
}

.tw-accent-slate-50\/5 {
  accent-color: rgb(248 250 252 / 0.05) !important;
}

.tw-accent-slate-50\/50 {
  accent-color: rgb(248 250 252 / 0.5) !important;
}

.tw-accent-slate-50\/55 {
  accent-color: rgb(248 250 252 / 0.55) !important;
}

.tw-accent-slate-50\/60 {
  accent-color: rgb(248 250 252 / 0.6) !important;
}

.tw-accent-slate-50\/65 {
  accent-color: rgb(248 250 252 / 0.65) !important;
}

.tw-accent-slate-50\/70 {
  accent-color: rgb(248 250 252 / 0.7) !important;
}

.tw-accent-slate-50\/75 {
  accent-color: rgb(248 250 252 / 0.75) !important;
}

.tw-accent-slate-50\/80 {
  accent-color: rgb(248 250 252 / 0.8) !important;
}

.tw-accent-slate-50\/85 {
  accent-color: rgb(248 250 252 / 0.85) !important;
}

.tw-accent-slate-50\/90 {
  accent-color: rgb(248 250 252 / 0.9) !important;
}

.tw-accent-slate-50\/95 {
  accent-color: rgb(248 250 252 / 0.95) !important;
}

.tw-accent-slate-500 {
  accent-color: #64748b !important;
}

.tw-accent-slate-500\/0 {
  accent-color: rgb(100 116 139 / 0) !important;
}

.tw-accent-slate-500\/10 {
  accent-color: rgb(100 116 139 / 0.1) !important;
}

.tw-accent-slate-500\/100 {
  accent-color: rgb(100 116 139 / 1) !important;
}

.tw-accent-slate-500\/15 {
  accent-color: rgb(100 116 139 / 0.15) !important;
}

.tw-accent-slate-500\/20 {
  accent-color: rgb(100 116 139 / 0.2) !important;
}

.tw-accent-slate-500\/25 {
  accent-color: rgb(100 116 139 / 0.25) !important;
}

.tw-accent-slate-500\/30 {
  accent-color: rgb(100 116 139 / 0.3) !important;
}

.tw-accent-slate-500\/35 {
  accent-color: rgb(100 116 139 / 0.35) !important;
}

.tw-accent-slate-500\/40 {
  accent-color: rgb(100 116 139 / 0.4) !important;
}

.tw-accent-slate-500\/45 {
  accent-color: rgb(100 116 139 / 0.45) !important;
}

.tw-accent-slate-500\/5 {
  accent-color: rgb(100 116 139 / 0.05) !important;
}

.tw-accent-slate-500\/50 {
  accent-color: rgb(100 116 139 / 0.5) !important;
}

.tw-accent-slate-500\/55 {
  accent-color: rgb(100 116 139 / 0.55) !important;
}

.tw-accent-slate-500\/60 {
  accent-color: rgb(100 116 139 / 0.6) !important;
}

.tw-accent-slate-500\/65 {
  accent-color: rgb(100 116 139 / 0.65) !important;
}

.tw-accent-slate-500\/70 {
  accent-color: rgb(100 116 139 / 0.7) !important;
}

.tw-accent-slate-500\/75 {
  accent-color: rgb(100 116 139 / 0.75) !important;
}

.tw-accent-slate-500\/80 {
  accent-color: rgb(100 116 139 / 0.8) !important;
}

.tw-accent-slate-500\/85 {
  accent-color: rgb(100 116 139 / 0.85) !important;
}

.tw-accent-slate-500\/90 {
  accent-color: rgb(100 116 139 / 0.9) !important;
}

.tw-accent-slate-500\/95 {
  accent-color: rgb(100 116 139 / 0.95) !important;
}

.tw-accent-slate-600 {
  accent-color: #475569 !important;
}

.tw-accent-slate-600\/0 {
  accent-color: rgb(71 85 105 / 0) !important;
}

.tw-accent-slate-600\/10 {
  accent-color: rgb(71 85 105 / 0.1) !important;
}

.tw-accent-slate-600\/100 {
  accent-color: rgb(71 85 105 / 1) !important;
}

.tw-accent-slate-600\/15 {
  accent-color: rgb(71 85 105 / 0.15) !important;
}

.tw-accent-slate-600\/20 {
  accent-color: rgb(71 85 105 / 0.2) !important;
}

.tw-accent-slate-600\/25 {
  accent-color: rgb(71 85 105 / 0.25) !important;
}

.tw-accent-slate-600\/30 {
  accent-color: rgb(71 85 105 / 0.3) !important;
}

.tw-accent-slate-600\/35 {
  accent-color: rgb(71 85 105 / 0.35) !important;
}

.tw-accent-slate-600\/40 {
  accent-color: rgb(71 85 105 / 0.4) !important;
}

.tw-accent-slate-600\/45 {
  accent-color: rgb(71 85 105 / 0.45) !important;
}

.tw-accent-slate-600\/5 {
  accent-color: rgb(71 85 105 / 0.05) !important;
}

.tw-accent-slate-600\/50 {
  accent-color: rgb(71 85 105 / 0.5) !important;
}

.tw-accent-slate-600\/55 {
  accent-color: rgb(71 85 105 / 0.55) !important;
}

.tw-accent-slate-600\/60 {
  accent-color: rgb(71 85 105 / 0.6) !important;
}

.tw-accent-slate-600\/65 {
  accent-color: rgb(71 85 105 / 0.65) !important;
}

.tw-accent-slate-600\/70 {
  accent-color: rgb(71 85 105 / 0.7) !important;
}

.tw-accent-slate-600\/75 {
  accent-color: rgb(71 85 105 / 0.75) !important;
}

.tw-accent-slate-600\/80 {
  accent-color: rgb(71 85 105 / 0.8) !important;
}

.tw-accent-slate-600\/85 {
  accent-color: rgb(71 85 105 / 0.85) !important;
}

.tw-accent-slate-600\/90 {
  accent-color: rgb(71 85 105 / 0.9) !important;
}

.tw-accent-slate-600\/95 {
  accent-color: rgb(71 85 105 / 0.95) !important;
}

.tw-accent-slate-700 {
  accent-color: #334155 !important;
}

.tw-accent-slate-700\/0 {
  accent-color: rgb(51 65 85 / 0) !important;
}

.tw-accent-slate-700\/10 {
  accent-color: rgb(51 65 85 / 0.1) !important;
}

.tw-accent-slate-700\/100 {
  accent-color: rgb(51 65 85 / 1) !important;
}

.tw-accent-slate-700\/15 {
  accent-color: rgb(51 65 85 / 0.15) !important;
}

.tw-accent-slate-700\/20 {
  accent-color: rgb(51 65 85 / 0.2) !important;
}

.tw-accent-slate-700\/25 {
  accent-color: rgb(51 65 85 / 0.25) !important;
}

.tw-accent-slate-700\/30 {
  accent-color: rgb(51 65 85 / 0.3) !important;
}

.tw-accent-slate-700\/35 {
  accent-color: rgb(51 65 85 / 0.35) !important;
}

.tw-accent-slate-700\/40 {
  accent-color: rgb(51 65 85 / 0.4) !important;
}

.tw-accent-slate-700\/45 {
  accent-color: rgb(51 65 85 / 0.45) !important;
}

.tw-accent-slate-700\/5 {
  accent-color: rgb(51 65 85 / 0.05) !important;
}

.tw-accent-slate-700\/50 {
  accent-color: rgb(51 65 85 / 0.5) !important;
}

.tw-accent-slate-700\/55 {
  accent-color: rgb(51 65 85 / 0.55) !important;
}

.tw-accent-slate-700\/60 {
  accent-color: rgb(51 65 85 / 0.6) !important;
}

.tw-accent-slate-700\/65 {
  accent-color: rgb(51 65 85 / 0.65) !important;
}

.tw-accent-slate-700\/70 {
  accent-color: rgb(51 65 85 / 0.7) !important;
}

.tw-accent-slate-700\/75 {
  accent-color: rgb(51 65 85 / 0.75) !important;
}

.tw-accent-slate-700\/80 {
  accent-color: rgb(51 65 85 / 0.8) !important;
}

.tw-accent-slate-700\/85 {
  accent-color: rgb(51 65 85 / 0.85) !important;
}

.tw-accent-slate-700\/90 {
  accent-color: rgb(51 65 85 / 0.9) !important;
}

.tw-accent-slate-700\/95 {
  accent-color: rgb(51 65 85 / 0.95) !important;
}

.tw-accent-slate-800 {
  accent-color: #1e293b !important;
}

.tw-accent-slate-800\/0 {
  accent-color: rgb(30 41 59 / 0) !important;
}

.tw-accent-slate-800\/10 {
  accent-color: rgb(30 41 59 / 0.1) !important;
}

.tw-accent-slate-800\/100 {
  accent-color: rgb(30 41 59 / 1) !important;
}

.tw-accent-slate-800\/15 {
  accent-color: rgb(30 41 59 / 0.15) !important;
}

.tw-accent-slate-800\/20 {
  accent-color: rgb(30 41 59 / 0.2) !important;
}

.tw-accent-slate-800\/25 {
  accent-color: rgb(30 41 59 / 0.25) !important;
}

.tw-accent-slate-800\/30 {
  accent-color: rgb(30 41 59 / 0.3) !important;
}

.tw-accent-slate-800\/35 {
  accent-color: rgb(30 41 59 / 0.35) !important;
}

.tw-accent-slate-800\/40 {
  accent-color: rgb(30 41 59 / 0.4) !important;
}

.tw-accent-slate-800\/45 {
  accent-color: rgb(30 41 59 / 0.45) !important;
}

.tw-accent-slate-800\/5 {
  accent-color: rgb(30 41 59 / 0.05) !important;
}

.tw-accent-slate-800\/50 {
  accent-color: rgb(30 41 59 / 0.5) !important;
}

.tw-accent-slate-800\/55 {
  accent-color: rgb(30 41 59 / 0.55) !important;
}

.tw-accent-slate-800\/60 {
  accent-color: rgb(30 41 59 / 0.6) !important;
}

.tw-accent-slate-800\/65 {
  accent-color: rgb(30 41 59 / 0.65) !important;
}

.tw-accent-slate-800\/70 {
  accent-color: rgb(30 41 59 / 0.7) !important;
}

.tw-accent-slate-800\/75 {
  accent-color: rgb(30 41 59 / 0.75) !important;
}

.tw-accent-slate-800\/80 {
  accent-color: rgb(30 41 59 / 0.8) !important;
}

.tw-accent-slate-800\/85 {
  accent-color: rgb(30 41 59 / 0.85) !important;
}

.tw-accent-slate-800\/90 {
  accent-color: rgb(30 41 59 / 0.9) !important;
}

.tw-accent-slate-800\/95 {
  accent-color: rgb(30 41 59 / 0.95) !important;
}

.tw-accent-slate-900 {
  accent-color: #0f172a !important;
}

.tw-accent-slate-900\/0 {
  accent-color: rgb(15 23 42 / 0) !important;
}

.tw-accent-slate-900\/10 {
  accent-color: rgb(15 23 42 / 0.1) !important;
}

.tw-accent-slate-900\/100 {
  accent-color: rgb(15 23 42 / 1) !important;
}

.tw-accent-slate-900\/15 {
  accent-color: rgb(15 23 42 / 0.15) !important;
}

.tw-accent-slate-900\/20 {
  accent-color: rgb(15 23 42 / 0.2) !important;
}

.tw-accent-slate-900\/25 {
  accent-color: rgb(15 23 42 / 0.25) !important;
}

.tw-accent-slate-900\/30 {
  accent-color: rgb(15 23 42 / 0.3) !important;
}

.tw-accent-slate-900\/35 {
  accent-color: rgb(15 23 42 / 0.35) !important;
}

.tw-accent-slate-900\/40 {
  accent-color: rgb(15 23 42 / 0.4) !important;
}

.tw-accent-slate-900\/45 {
  accent-color: rgb(15 23 42 / 0.45) !important;
}

.tw-accent-slate-900\/5 {
  accent-color: rgb(15 23 42 / 0.05) !important;
}

.tw-accent-slate-900\/50 {
  accent-color: rgb(15 23 42 / 0.5) !important;
}

.tw-accent-slate-900\/55 {
  accent-color: rgb(15 23 42 / 0.55) !important;
}

.tw-accent-slate-900\/60 {
  accent-color: rgb(15 23 42 / 0.6) !important;
}

.tw-accent-slate-900\/65 {
  accent-color: rgb(15 23 42 / 0.65) !important;
}

.tw-accent-slate-900\/70 {
  accent-color: rgb(15 23 42 / 0.7) !important;
}

.tw-accent-slate-900\/75 {
  accent-color: rgb(15 23 42 / 0.75) !important;
}

.tw-accent-slate-900\/80 {
  accent-color: rgb(15 23 42 / 0.8) !important;
}

.tw-accent-slate-900\/85 {
  accent-color: rgb(15 23 42 / 0.85) !important;
}

.tw-accent-slate-900\/90 {
  accent-color: rgb(15 23 42 / 0.9) !important;
}

.tw-accent-slate-900\/95 {
  accent-color: rgb(15 23 42 / 0.95) !important;
}

.tw-accent-slate-950 {
  accent-color: #020617 !important;
}

.tw-accent-slate-950\/0 {
  accent-color: rgb(2 6 23 / 0) !important;
}

.tw-accent-slate-950\/10 {
  accent-color: rgb(2 6 23 / 0.1) !important;
}

.tw-accent-slate-950\/100 {
  accent-color: rgb(2 6 23 / 1) !important;
}

.tw-accent-slate-950\/15 {
  accent-color: rgb(2 6 23 / 0.15) !important;
}

.tw-accent-slate-950\/20 {
  accent-color: rgb(2 6 23 / 0.2) !important;
}

.tw-accent-slate-950\/25 {
  accent-color: rgb(2 6 23 / 0.25) !important;
}

.tw-accent-slate-950\/30 {
  accent-color: rgb(2 6 23 / 0.3) !important;
}

.tw-accent-slate-950\/35 {
  accent-color: rgb(2 6 23 / 0.35) !important;
}

.tw-accent-slate-950\/40 {
  accent-color: rgb(2 6 23 / 0.4) !important;
}

.tw-accent-slate-950\/45 {
  accent-color: rgb(2 6 23 / 0.45) !important;
}

.tw-accent-slate-950\/5 {
  accent-color: rgb(2 6 23 / 0.05) !important;
}

.tw-accent-slate-950\/50 {
  accent-color: rgb(2 6 23 / 0.5) !important;
}

.tw-accent-slate-950\/55 {
  accent-color: rgb(2 6 23 / 0.55) !important;
}

.tw-accent-slate-950\/60 {
  accent-color: rgb(2 6 23 / 0.6) !important;
}

.tw-accent-slate-950\/65 {
  accent-color: rgb(2 6 23 / 0.65) !important;
}

.tw-accent-slate-950\/70 {
  accent-color: rgb(2 6 23 / 0.7) !important;
}

.tw-accent-slate-950\/75 {
  accent-color: rgb(2 6 23 / 0.75) !important;
}

.tw-accent-slate-950\/80 {
  accent-color: rgb(2 6 23 / 0.8) !important;
}

.tw-accent-slate-950\/85 {
  accent-color: rgb(2 6 23 / 0.85) !important;
}

.tw-accent-slate-950\/90 {
  accent-color: rgb(2 6 23 / 0.9) !important;
}

.tw-accent-slate-950\/95 {
  accent-color: rgb(2 6 23 / 0.95) !important;
}

.tw-accent-stone-100 {
  accent-color: #f5f5f4 !important;
}

.tw-accent-stone-100\/0 {
  accent-color: rgb(245 245 244 / 0) !important;
}

.tw-accent-stone-100\/10 {
  accent-color: rgb(245 245 244 / 0.1) !important;
}

.tw-accent-stone-100\/100 {
  accent-color: rgb(245 245 244 / 1) !important;
}

.tw-accent-stone-100\/15 {
  accent-color: rgb(245 245 244 / 0.15) !important;
}

.tw-accent-stone-100\/20 {
  accent-color: rgb(245 245 244 / 0.2) !important;
}

.tw-accent-stone-100\/25 {
  accent-color: rgb(245 245 244 / 0.25) !important;
}

.tw-accent-stone-100\/30 {
  accent-color: rgb(245 245 244 / 0.3) !important;
}

.tw-accent-stone-100\/35 {
  accent-color: rgb(245 245 244 / 0.35) !important;
}

.tw-accent-stone-100\/40 {
  accent-color: rgb(245 245 244 / 0.4) !important;
}

.tw-accent-stone-100\/45 {
  accent-color: rgb(245 245 244 / 0.45) !important;
}

.tw-accent-stone-100\/5 {
  accent-color: rgb(245 245 244 / 0.05) !important;
}

.tw-accent-stone-100\/50 {
  accent-color: rgb(245 245 244 / 0.5) !important;
}

.tw-accent-stone-100\/55 {
  accent-color: rgb(245 245 244 / 0.55) !important;
}

.tw-accent-stone-100\/60 {
  accent-color: rgb(245 245 244 / 0.6) !important;
}

.tw-accent-stone-100\/65 {
  accent-color: rgb(245 245 244 / 0.65) !important;
}

.tw-accent-stone-100\/70 {
  accent-color: rgb(245 245 244 / 0.7) !important;
}

.tw-accent-stone-100\/75 {
  accent-color: rgb(245 245 244 / 0.75) !important;
}

.tw-accent-stone-100\/80 {
  accent-color: rgb(245 245 244 / 0.8) !important;
}

.tw-accent-stone-100\/85 {
  accent-color: rgb(245 245 244 / 0.85) !important;
}

.tw-accent-stone-100\/90 {
  accent-color: rgb(245 245 244 / 0.9) !important;
}

.tw-accent-stone-100\/95 {
  accent-color: rgb(245 245 244 / 0.95) !important;
}

.tw-accent-stone-200 {
  accent-color: #e7e5e4 !important;
}

.tw-accent-stone-200\/0 {
  accent-color: rgb(231 229 228 / 0) !important;
}

.tw-accent-stone-200\/10 {
  accent-color: rgb(231 229 228 / 0.1) !important;
}

.tw-accent-stone-200\/100 {
  accent-color: rgb(231 229 228 / 1) !important;
}

.tw-accent-stone-200\/15 {
  accent-color: rgb(231 229 228 / 0.15) !important;
}

.tw-accent-stone-200\/20 {
  accent-color: rgb(231 229 228 / 0.2) !important;
}

.tw-accent-stone-200\/25 {
  accent-color: rgb(231 229 228 / 0.25) !important;
}

.tw-accent-stone-200\/30 {
  accent-color: rgb(231 229 228 / 0.3) !important;
}

.tw-accent-stone-200\/35 {
  accent-color: rgb(231 229 228 / 0.35) !important;
}

.tw-accent-stone-200\/40 {
  accent-color: rgb(231 229 228 / 0.4) !important;
}

.tw-accent-stone-200\/45 {
  accent-color: rgb(231 229 228 / 0.45) !important;
}

.tw-accent-stone-200\/5 {
  accent-color: rgb(231 229 228 / 0.05) !important;
}

.tw-accent-stone-200\/50 {
  accent-color: rgb(231 229 228 / 0.5) !important;
}

.tw-accent-stone-200\/55 {
  accent-color: rgb(231 229 228 / 0.55) !important;
}

.tw-accent-stone-200\/60 {
  accent-color: rgb(231 229 228 / 0.6) !important;
}

.tw-accent-stone-200\/65 {
  accent-color: rgb(231 229 228 / 0.65) !important;
}

.tw-accent-stone-200\/70 {
  accent-color: rgb(231 229 228 / 0.7) !important;
}

.tw-accent-stone-200\/75 {
  accent-color: rgb(231 229 228 / 0.75) !important;
}

.tw-accent-stone-200\/80 {
  accent-color: rgb(231 229 228 / 0.8) !important;
}

.tw-accent-stone-200\/85 {
  accent-color: rgb(231 229 228 / 0.85) !important;
}

.tw-accent-stone-200\/90 {
  accent-color: rgb(231 229 228 / 0.9) !important;
}

.tw-accent-stone-200\/95 {
  accent-color: rgb(231 229 228 / 0.95) !important;
}

.tw-accent-stone-300 {
  accent-color: #d6d3d1 !important;
}

.tw-accent-stone-300\/0 {
  accent-color: rgb(214 211 209 / 0) !important;
}

.tw-accent-stone-300\/10 {
  accent-color: rgb(214 211 209 / 0.1) !important;
}

.tw-accent-stone-300\/100 {
  accent-color: rgb(214 211 209 / 1) !important;
}

.tw-accent-stone-300\/15 {
  accent-color: rgb(214 211 209 / 0.15) !important;
}

.tw-accent-stone-300\/20 {
  accent-color: rgb(214 211 209 / 0.2) !important;
}

.tw-accent-stone-300\/25 {
  accent-color: rgb(214 211 209 / 0.25) !important;
}

.tw-accent-stone-300\/30 {
  accent-color: rgb(214 211 209 / 0.3) !important;
}

.tw-accent-stone-300\/35 {
  accent-color: rgb(214 211 209 / 0.35) !important;
}

.tw-accent-stone-300\/40 {
  accent-color: rgb(214 211 209 / 0.4) !important;
}

.tw-accent-stone-300\/45 {
  accent-color: rgb(214 211 209 / 0.45) !important;
}

.tw-accent-stone-300\/5 {
  accent-color: rgb(214 211 209 / 0.05) !important;
}

.tw-accent-stone-300\/50 {
  accent-color: rgb(214 211 209 / 0.5) !important;
}

.tw-accent-stone-300\/55 {
  accent-color: rgb(214 211 209 / 0.55) !important;
}

.tw-accent-stone-300\/60 {
  accent-color: rgb(214 211 209 / 0.6) !important;
}

.tw-accent-stone-300\/65 {
  accent-color: rgb(214 211 209 / 0.65) !important;
}

.tw-accent-stone-300\/70 {
  accent-color: rgb(214 211 209 / 0.7) !important;
}

.tw-accent-stone-300\/75 {
  accent-color: rgb(214 211 209 / 0.75) !important;
}

.tw-accent-stone-300\/80 {
  accent-color: rgb(214 211 209 / 0.8) !important;
}

.tw-accent-stone-300\/85 {
  accent-color: rgb(214 211 209 / 0.85) !important;
}

.tw-accent-stone-300\/90 {
  accent-color: rgb(214 211 209 / 0.9) !important;
}

.tw-accent-stone-300\/95 {
  accent-color: rgb(214 211 209 / 0.95) !important;
}

.tw-accent-stone-400 {
  accent-color: #a8a29e !important;
}

.tw-accent-stone-400\/0 {
  accent-color: rgb(168 162 158 / 0) !important;
}

.tw-accent-stone-400\/10 {
  accent-color: rgb(168 162 158 / 0.1) !important;
}

.tw-accent-stone-400\/100 {
  accent-color: rgb(168 162 158 / 1) !important;
}

.tw-accent-stone-400\/15 {
  accent-color: rgb(168 162 158 / 0.15) !important;
}

.tw-accent-stone-400\/20 {
  accent-color: rgb(168 162 158 / 0.2) !important;
}

.tw-accent-stone-400\/25 {
  accent-color: rgb(168 162 158 / 0.25) !important;
}

.tw-accent-stone-400\/30 {
  accent-color: rgb(168 162 158 / 0.3) !important;
}

.tw-accent-stone-400\/35 {
  accent-color: rgb(168 162 158 / 0.35) !important;
}

.tw-accent-stone-400\/40 {
  accent-color: rgb(168 162 158 / 0.4) !important;
}

.tw-accent-stone-400\/45 {
  accent-color: rgb(168 162 158 / 0.45) !important;
}

.tw-accent-stone-400\/5 {
  accent-color: rgb(168 162 158 / 0.05) !important;
}

.tw-accent-stone-400\/50 {
  accent-color: rgb(168 162 158 / 0.5) !important;
}

.tw-accent-stone-400\/55 {
  accent-color: rgb(168 162 158 / 0.55) !important;
}

.tw-accent-stone-400\/60 {
  accent-color: rgb(168 162 158 / 0.6) !important;
}

.tw-accent-stone-400\/65 {
  accent-color: rgb(168 162 158 / 0.65) !important;
}

.tw-accent-stone-400\/70 {
  accent-color: rgb(168 162 158 / 0.7) !important;
}

.tw-accent-stone-400\/75 {
  accent-color: rgb(168 162 158 / 0.75) !important;
}

.tw-accent-stone-400\/80 {
  accent-color: rgb(168 162 158 / 0.8) !important;
}

.tw-accent-stone-400\/85 {
  accent-color: rgb(168 162 158 / 0.85) !important;
}

.tw-accent-stone-400\/90 {
  accent-color: rgb(168 162 158 / 0.9) !important;
}

.tw-accent-stone-400\/95 {
  accent-color: rgb(168 162 158 / 0.95) !important;
}

.tw-accent-stone-50 {
  accent-color: #fafaf9 !important;
}

.tw-accent-stone-50\/0 {
  accent-color: rgb(250 250 249 / 0) !important;
}

.tw-accent-stone-50\/10 {
  accent-color: rgb(250 250 249 / 0.1) !important;
}

.tw-accent-stone-50\/100 {
  accent-color: rgb(250 250 249 / 1) !important;
}

.tw-accent-stone-50\/15 {
  accent-color: rgb(250 250 249 / 0.15) !important;
}

.tw-accent-stone-50\/20 {
  accent-color: rgb(250 250 249 / 0.2) !important;
}

.tw-accent-stone-50\/25 {
  accent-color: rgb(250 250 249 / 0.25) !important;
}

.tw-accent-stone-50\/30 {
  accent-color: rgb(250 250 249 / 0.3) !important;
}

.tw-accent-stone-50\/35 {
  accent-color: rgb(250 250 249 / 0.35) !important;
}

.tw-accent-stone-50\/40 {
  accent-color: rgb(250 250 249 / 0.4) !important;
}

.tw-accent-stone-50\/45 {
  accent-color: rgb(250 250 249 / 0.45) !important;
}

.tw-accent-stone-50\/5 {
  accent-color: rgb(250 250 249 / 0.05) !important;
}

.tw-accent-stone-50\/50 {
  accent-color: rgb(250 250 249 / 0.5) !important;
}

.tw-accent-stone-50\/55 {
  accent-color: rgb(250 250 249 / 0.55) !important;
}

.tw-accent-stone-50\/60 {
  accent-color: rgb(250 250 249 / 0.6) !important;
}

.tw-accent-stone-50\/65 {
  accent-color: rgb(250 250 249 / 0.65) !important;
}

.tw-accent-stone-50\/70 {
  accent-color: rgb(250 250 249 / 0.7) !important;
}

.tw-accent-stone-50\/75 {
  accent-color: rgb(250 250 249 / 0.75) !important;
}

.tw-accent-stone-50\/80 {
  accent-color: rgb(250 250 249 / 0.8) !important;
}

.tw-accent-stone-50\/85 {
  accent-color: rgb(250 250 249 / 0.85) !important;
}

.tw-accent-stone-50\/90 {
  accent-color: rgb(250 250 249 / 0.9) !important;
}

.tw-accent-stone-50\/95 {
  accent-color: rgb(250 250 249 / 0.95) !important;
}

.tw-accent-stone-500 {
  accent-color: #78716c !important;
}

.tw-accent-stone-500\/0 {
  accent-color: rgb(120 113 108 / 0) !important;
}

.tw-accent-stone-500\/10 {
  accent-color: rgb(120 113 108 / 0.1) !important;
}

.tw-accent-stone-500\/100 {
  accent-color: rgb(120 113 108 / 1) !important;
}

.tw-accent-stone-500\/15 {
  accent-color: rgb(120 113 108 / 0.15) !important;
}

.tw-accent-stone-500\/20 {
  accent-color: rgb(120 113 108 / 0.2) !important;
}

.tw-accent-stone-500\/25 {
  accent-color: rgb(120 113 108 / 0.25) !important;
}

.tw-accent-stone-500\/30 {
  accent-color: rgb(120 113 108 / 0.3) !important;
}

.tw-accent-stone-500\/35 {
  accent-color: rgb(120 113 108 / 0.35) !important;
}

.tw-accent-stone-500\/40 {
  accent-color: rgb(120 113 108 / 0.4) !important;
}

.tw-accent-stone-500\/45 {
  accent-color: rgb(120 113 108 / 0.45) !important;
}

.tw-accent-stone-500\/5 {
  accent-color: rgb(120 113 108 / 0.05) !important;
}

.tw-accent-stone-500\/50 {
  accent-color: rgb(120 113 108 / 0.5) !important;
}

.tw-accent-stone-500\/55 {
  accent-color: rgb(120 113 108 / 0.55) !important;
}

.tw-accent-stone-500\/60 {
  accent-color: rgb(120 113 108 / 0.6) !important;
}

.tw-accent-stone-500\/65 {
  accent-color: rgb(120 113 108 / 0.65) !important;
}

.tw-accent-stone-500\/70 {
  accent-color: rgb(120 113 108 / 0.7) !important;
}

.tw-accent-stone-500\/75 {
  accent-color: rgb(120 113 108 / 0.75) !important;
}

.tw-accent-stone-500\/80 {
  accent-color: rgb(120 113 108 / 0.8) !important;
}

.tw-accent-stone-500\/85 {
  accent-color: rgb(120 113 108 / 0.85) !important;
}

.tw-accent-stone-500\/90 {
  accent-color: rgb(120 113 108 / 0.9) !important;
}

.tw-accent-stone-500\/95 {
  accent-color: rgb(120 113 108 / 0.95) !important;
}

.tw-accent-stone-600 {
  accent-color: #57534e !important;
}

.tw-accent-stone-600\/0 {
  accent-color: rgb(87 83 78 / 0) !important;
}

.tw-accent-stone-600\/10 {
  accent-color: rgb(87 83 78 / 0.1) !important;
}

.tw-accent-stone-600\/100 {
  accent-color: rgb(87 83 78 / 1) !important;
}

.tw-accent-stone-600\/15 {
  accent-color: rgb(87 83 78 / 0.15) !important;
}

.tw-accent-stone-600\/20 {
  accent-color: rgb(87 83 78 / 0.2) !important;
}

.tw-accent-stone-600\/25 {
  accent-color: rgb(87 83 78 / 0.25) !important;
}

.tw-accent-stone-600\/30 {
  accent-color: rgb(87 83 78 / 0.3) !important;
}

.tw-accent-stone-600\/35 {
  accent-color: rgb(87 83 78 / 0.35) !important;
}

.tw-accent-stone-600\/40 {
  accent-color: rgb(87 83 78 / 0.4) !important;
}

.tw-accent-stone-600\/45 {
  accent-color: rgb(87 83 78 / 0.45) !important;
}

.tw-accent-stone-600\/5 {
  accent-color: rgb(87 83 78 / 0.05) !important;
}

.tw-accent-stone-600\/50 {
  accent-color: rgb(87 83 78 / 0.5) !important;
}

.tw-accent-stone-600\/55 {
  accent-color: rgb(87 83 78 / 0.55) !important;
}

.tw-accent-stone-600\/60 {
  accent-color: rgb(87 83 78 / 0.6) !important;
}

.tw-accent-stone-600\/65 {
  accent-color: rgb(87 83 78 / 0.65) !important;
}

.tw-accent-stone-600\/70 {
  accent-color: rgb(87 83 78 / 0.7) !important;
}

.tw-accent-stone-600\/75 {
  accent-color: rgb(87 83 78 / 0.75) !important;
}

.tw-accent-stone-600\/80 {
  accent-color: rgb(87 83 78 / 0.8) !important;
}

.tw-accent-stone-600\/85 {
  accent-color: rgb(87 83 78 / 0.85) !important;
}

.tw-accent-stone-600\/90 {
  accent-color: rgb(87 83 78 / 0.9) !important;
}

.tw-accent-stone-600\/95 {
  accent-color: rgb(87 83 78 / 0.95) !important;
}

.tw-accent-stone-700 {
  accent-color: #44403c !important;
}

.tw-accent-stone-700\/0 {
  accent-color: rgb(68 64 60 / 0) !important;
}

.tw-accent-stone-700\/10 {
  accent-color: rgb(68 64 60 / 0.1) !important;
}

.tw-accent-stone-700\/100 {
  accent-color: rgb(68 64 60 / 1) !important;
}

.tw-accent-stone-700\/15 {
  accent-color: rgb(68 64 60 / 0.15) !important;
}

.tw-accent-stone-700\/20 {
  accent-color: rgb(68 64 60 / 0.2) !important;
}

.tw-accent-stone-700\/25 {
  accent-color: rgb(68 64 60 / 0.25) !important;
}

.tw-accent-stone-700\/30 {
  accent-color: rgb(68 64 60 / 0.3) !important;
}

.tw-accent-stone-700\/35 {
  accent-color: rgb(68 64 60 / 0.35) !important;
}

.tw-accent-stone-700\/40 {
  accent-color: rgb(68 64 60 / 0.4) !important;
}

.tw-accent-stone-700\/45 {
  accent-color: rgb(68 64 60 / 0.45) !important;
}

.tw-accent-stone-700\/5 {
  accent-color: rgb(68 64 60 / 0.05) !important;
}

.tw-accent-stone-700\/50 {
  accent-color: rgb(68 64 60 / 0.5) !important;
}

.tw-accent-stone-700\/55 {
  accent-color: rgb(68 64 60 / 0.55) !important;
}

.tw-accent-stone-700\/60 {
  accent-color: rgb(68 64 60 / 0.6) !important;
}

.tw-accent-stone-700\/65 {
  accent-color: rgb(68 64 60 / 0.65) !important;
}

.tw-accent-stone-700\/70 {
  accent-color: rgb(68 64 60 / 0.7) !important;
}

.tw-accent-stone-700\/75 {
  accent-color: rgb(68 64 60 / 0.75) !important;
}

.tw-accent-stone-700\/80 {
  accent-color: rgb(68 64 60 / 0.8) !important;
}

.tw-accent-stone-700\/85 {
  accent-color: rgb(68 64 60 / 0.85) !important;
}

.tw-accent-stone-700\/90 {
  accent-color: rgb(68 64 60 / 0.9) !important;
}

.tw-accent-stone-700\/95 {
  accent-color: rgb(68 64 60 / 0.95) !important;
}

.tw-accent-stone-800 {
  accent-color: #292524 !important;
}

.tw-accent-stone-800\/0 {
  accent-color: rgb(41 37 36 / 0) !important;
}

.tw-accent-stone-800\/10 {
  accent-color: rgb(41 37 36 / 0.1) !important;
}

.tw-accent-stone-800\/100 {
  accent-color: rgb(41 37 36 / 1) !important;
}

.tw-accent-stone-800\/15 {
  accent-color: rgb(41 37 36 / 0.15) !important;
}

.tw-accent-stone-800\/20 {
  accent-color: rgb(41 37 36 / 0.2) !important;
}

.tw-accent-stone-800\/25 {
  accent-color: rgb(41 37 36 / 0.25) !important;
}

.tw-accent-stone-800\/30 {
  accent-color: rgb(41 37 36 / 0.3) !important;
}

.tw-accent-stone-800\/35 {
  accent-color: rgb(41 37 36 / 0.35) !important;
}

.tw-accent-stone-800\/40 {
  accent-color: rgb(41 37 36 / 0.4) !important;
}

.tw-accent-stone-800\/45 {
  accent-color: rgb(41 37 36 / 0.45) !important;
}

.tw-accent-stone-800\/5 {
  accent-color: rgb(41 37 36 / 0.05) !important;
}

.tw-accent-stone-800\/50 {
  accent-color: rgb(41 37 36 / 0.5) !important;
}

.tw-accent-stone-800\/55 {
  accent-color: rgb(41 37 36 / 0.55) !important;
}

.tw-accent-stone-800\/60 {
  accent-color: rgb(41 37 36 / 0.6) !important;
}

.tw-accent-stone-800\/65 {
  accent-color: rgb(41 37 36 / 0.65) !important;
}

.tw-accent-stone-800\/70 {
  accent-color: rgb(41 37 36 / 0.7) !important;
}

.tw-accent-stone-800\/75 {
  accent-color: rgb(41 37 36 / 0.75) !important;
}

.tw-accent-stone-800\/80 {
  accent-color: rgb(41 37 36 / 0.8) !important;
}

.tw-accent-stone-800\/85 {
  accent-color: rgb(41 37 36 / 0.85) !important;
}

.tw-accent-stone-800\/90 {
  accent-color: rgb(41 37 36 / 0.9) !important;
}

.tw-accent-stone-800\/95 {
  accent-color: rgb(41 37 36 / 0.95) !important;
}

.tw-accent-stone-900 {
  accent-color: #1c1917 !important;
}

.tw-accent-stone-900\/0 {
  accent-color: rgb(28 25 23 / 0) !important;
}

.tw-accent-stone-900\/10 {
  accent-color: rgb(28 25 23 / 0.1) !important;
}

.tw-accent-stone-900\/100 {
  accent-color: rgb(28 25 23 / 1) !important;
}

.tw-accent-stone-900\/15 {
  accent-color: rgb(28 25 23 / 0.15) !important;
}

.tw-accent-stone-900\/20 {
  accent-color: rgb(28 25 23 / 0.2) !important;
}

.tw-accent-stone-900\/25 {
  accent-color: rgb(28 25 23 / 0.25) !important;
}

.tw-accent-stone-900\/30 {
  accent-color: rgb(28 25 23 / 0.3) !important;
}

.tw-accent-stone-900\/35 {
  accent-color: rgb(28 25 23 / 0.35) !important;
}

.tw-accent-stone-900\/40 {
  accent-color: rgb(28 25 23 / 0.4) !important;
}

.tw-accent-stone-900\/45 {
  accent-color: rgb(28 25 23 / 0.45) !important;
}

.tw-accent-stone-900\/5 {
  accent-color: rgb(28 25 23 / 0.05) !important;
}

.tw-accent-stone-900\/50 {
  accent-color: rgb(28 25 23 / 0.5) !important;
}

.tw-accent-stone-900\/55 {
  accent-color: rgb(28 25 23 / 0.55) !important;
}

.tw-accent-stone-900\/60 {
  accent-color: rgb(28 25 23 / 0.6) !important;
}

.tw-accent-stone-900\/65 {
  accent-color: rgb(28 25 23 / 0.65) !important;
}

.tw-accent-stone-900\/70 {
  accent-color: rgb(28 25 23 / 0.7) !important;
}

.tw-accent-stone-900\/75 {
  accent-color: rgb(28 25 23 / 0.75) !important;
}

.tw-accent-stone-900\/80 {
  accent-color: rgb(28 25 23 / 0.8) !important;
}

.tw-accent-stone-900\/85 {
  accent-color: rgb(28 25 23 / 0.85) !important;
}

.tw-accent-stone-900\/90 {
  accent-color: rgb(28 25 23 / 0.9) !important;
}

.tw-accent-stone-900\/95 {
  accent-color: rgb(28 25 23 / 0.95) !important;
}

.tw-accent-stone-950 {
  accent-color: #0c0a09 !important;
}

.tw-accent-stone-950\/0 {
  accent-color: rgb(12 10 9 / 0) !important;
}

.tw-accent-stone-950\/10 {
  accent-color: rgb(12 10 9 / 0.1) !important;
}

.tw-accent-stone-950\/100 {
  accent-color: rgb(12 10 9 / 1) !important;
}

.tw-accent-stone-950\/15 {
  accent-color: rgb(12 10 9 / 0.15) !important;
}

.tw-accent-stone-950\/20 {
  accent-color: rgb(12 10 9 / 0.2) !important;
}

.tw-accent-stone-950\/25 {
  accent-color: rgb(12 10 9 / 0.25) !important;
}

.tw-accent-stone-950\/30 {
  accent-color: rgb(12 10 9 / 0.3) !important;
}

.tw-accent-stone-950\/35 {
  accent-color: rgb(12 10 9 / 0.35) !important;
}

.tw-accent-stone-950\/40 {
  accent-color: rgb(12 10 9 / 0.4) !important;
}

.tw-accent-stone-950\/45 {
  accent-color: rgb(12 10 9 / 0.45) !important;
}

.tw-accent-stone-950\/5 {
  accent-color: rgb(12 10 9 / 0.05) !important;
}

.tw-accent-stone-950\/50 {
  accent-color: rgb(12 10 9 / 0.5) !important;
}

.tw-accent-stone-950\/55 {
  accent-color: rgb(12 10 9 / 0.55) !important;
}

.tw-accent-stone-950\/60 {
  accent-color: rgb(12 10 9 / 0.6) !important;
}

.tw-accent-stone-950\/65 {
  accent-color: rgb(12 10 9 / 0.65) !important;
}

.tw-accent-stone-950\/70 {
  accent-color: rgb(12 10 9 / 0.7) !important;
}

.tw-accent-stone-950\/75 {
  accent-color: rgb(12 10 9 / 0.75) !important;
}

.tw-accent-stone-950\/80 {
  accent-color: rgb(12 10 9 / 0.8) !important;
}

.tw-accent-stone-950\/85 {
  accent-color: rgb(12 10 9 / 0.85) !important;
}

.tw-accent-stone-950\/90 {
  accent-color: rgb(12 10 9 / 0.9) !important;
}

.tw-accent-stone-950\/95 {
  accent-color: rgb(12 10 9 / 0.95) !important;
}

.tw-accent-teal-100 {
  accent-color: #ccfbf1 !important;
}

.tw-accent-teal-100\/0 {
  accent-color: rgb(204 251 241 / 0) !important;
}

.tw-accent-teal-100\/10 {
  accent-color: rgb(204 251 241 / 0.1) !important;
}

.tw-accent-teal-100\/100 {
  accent-color: rgb(204 251 241 / 1) !important;
}

.tw-accent-teal-100\/15 {
  accent-color: rgb(204 251 241 / 0.15) !important;
}

.tw-accent-teal-100\/20 {
  accent-color: rgb(204 251 241 / 0.2) !important;
}

.tw-accent-teal-100\/25 {
  accent-color: rgb(204 251 241 / 0.25) !important;
}

.tw-accent-teal-100\/30 {
  accent-color: rgb(204 251 241 / 0.3) !important;
}

.tw-accent-teal-100\/35 {
  accent-color: rgb(204 251 241 / 0.35) !important;
}

.tw-accent-teal-100\/40 {
  accent-color: rgb(204 251 241 / 0.4) !important;
}

.tw-accent-teal-100\/45 {
  accent-color: rgb(204 251 241 / 0.45) !important;
}

.tw-accent-teal-100\/5 {
  accent-color: rgb(204 251 241 / 0.05) !important;
}

.tw-accent-teal-100\/50 {
  accent-color: rgb(204 251 241 / 0.5) !important;
}

.tw-accent-teal-100\/55 {
  accent-color: rgb(204 251 241 / 0.55) !important;
}

.tw-accent-teal-100\/60 {
  accent-color: rgb(204 251 241 / 0.6) !important;
}

.tw-accent-teal-100\/65 {
  accent-color: rgb(204 251 241 / 0.65) !important;
}

.tw-accent-teal-100\/70 {
  accent-color: rgb(204 251 241 / 0.7) !important;
}

.tw-accent-teal-100\/75 {
  accent-color: rgb(204 251 241 / 0.75) !important;
}

.tw-accent-teal-100\/80 {
  accent-color: rgb(204 251 241 / 0.8) !important;
}

.tw-accent-teal-100\/85 {
  accent-color: rgb(204 251 241 / 0.85) !important;
}

.tw-accent-teal-100\/90 {
  accent-color: rgb(204 251 241 / 0.9) !important;
}

.tw-accent-teal-100\/95 {
  accent-color: rgb(204 251 241 / 0.95) !important;
}

.tw-accent-teal-200 {
  accent-color: #99f6e4 !important;
}

.tw-accent-teal-200\/0 {
  accent-color: rgb(153 246 228 / 0) !important;
}

.tw-accent-teal-200\/10 {
  accent-color: rgb(153 246 228 / 0.1) !important;
}

.tw-accent-teal-200\/100 {
  accent-color: rgb(153 246 228 / 1) !important;
}

.tw-accent-teal-200\/15 {
  accent-color: rgb(153 246 228 / 0.15) !important;
}

.tw-accent-teal-200\/20 {
  accent-color: rgb(153 246 228 / 0.2) !important;
}

.tw-accent-teal-200\/25 {
  accent-color: rgb(153 246 228 / 0.25) !important;
}

.tw-accent-teal-200\/30 {
  accent-color: rgb(153 246 228 / 0.3) !important;
}

.tw-accent-teal-200\/35 {
  accent-color: rgb(153 246 228 / 0.35) !important;
}

.tw-accent-teal-200\/40 {
  accent-color: rgb(153 246 228 / 0.4) !important;
}

.tw-accent-teal-200\/45 {
  accent-color: rgb(153 246 228 / 0.45) !important;
}

.tw-accent-teal-200\/5 {
  accent-color: rgb(153 246 228 / 0.05) !important;
}

.tw-accent-teal-200\/50 {
  accent-color: rgb(153 246 228 / 0.5) !important;
}

.tw-accent-teal-200\/55 {
  accent-color: rgb(153 246 228 / 0.55) !important;
}

.tw-accent-teal-200\/60 {
  accent-color: rgb(153 246 228 / 0.6) !important;
}

.tw-accent-teal-200\/65 {
  accent-color: rgb(153 246 228 / 0.65) !important;
}

.tw-accent-teal-200\/70 {
  accent-color: rgb(153 246 228 / 0.7) !important;
}

.tw-accent-teal-200\/75 {
  accent-color: rgb(153 246 228 / 0.75) !important;
}

.tw-accent-teal-200\/80 {
  accent-color: rgb(153 246 228 / 0.8) !important;
}

.tw-accent-teal-200\/85 {
  accent-color: rgb(153 246 228 / 0.85) !important;
}

.tw-accent-teal-200\/90 {
  accent-color: rgb(153 246 228 / 0.9) !important;
}

.tw-accent-teal-200\/95 {
  accent-color: rgb(153 246 228 / 0.95) !important;
}

.tw-accent-teal-300 {
  accent-color: #5eead4 !important;
}

.tw-accent-teal-300\/0 {
  accent-color: rgb(94 234 212 / 0) !important;
}

.tw-accent-teal-300\/10 {
  accent-color: rgb(94 234 212 / 0.1) !important;
}

.tw-accent-teal-300\/100 {
  accent-color: rgb(94 234 212 / 1) !important;
}

.tw-accent-teal-300\/15 {
  accent-color: rgb(94 234 212 / 0.15) !important;
}

.tw-accent-teal-300\/20 {
  accent-color: rgb(94 234 212 / 0.2) !important;
}

.tw-accent-teal-300\/25 {
  accent-color: rgb(94 234 212 / 0.25) !important;
}

.tw-accent-teal-300\/30 {
  accent-color: rgb(94 234 212 / 0.3) !important;
}

.tw-accent-teal-300\/35 {
  accent-color: rgb(94 234 212 / 0.35) !important;
}

.tw-accent-teal-300\/40 {
  accent-color: rgb(94 234 212 / 0.4) !important;
}

.tw-accent-teal-300\/45 {
  accent-color: rgb(94 234 212 / 0.45) !important;
}

.tw-accent-teal-300\/5 {
  accent-color: rgb(94 234 212 / 0.05) !important;
}

.tw-accent-teal-300\/50 {
  accent-color: rgb(94 234 212 / 0.5) !important;
}

.tw-accent-teal-300\/55 {
  accent-color: rgb(94 234 212 / 0.55) !important;
}

.tw-accent-teal-300\/60 {
  accent-color: rgb(94 234 212 / 0.6) !important;
}

.tw-accent-teal-300\/65 {
  accent-color: rgb(94 234 212 / 0.65) !important;
}

.tw-accent-teal-300\/70 {
  accent-color: rgb(94 234 212 / 0.7) !important;
}

.tw-accent-teal-300\/75 {
  accent-color: rgb(94 234 212 / 0.75) !important;
}

.tw-accent-teal-300\/80 {
  accent-color: rgb(94 234 212 / 0.8) !important;
}

.tw-accent-teal-300\/85 {
  accent-color: rgb(94 234 212 / 0.85) !important;
}

.tw-accent-teal-300\/90 {
  accent-color: rgb(94 234 212 / 0.9) !important;
}

.tw-accent-teal-300\/95 {
  accent-color: rgb(94 234 212 / 0.95) !important;
}

.tw-accent-teal-400 {
  accent-color: #2dd4bf !important;
}

.tw-accent-teal-400\/0 {
  accent-color: rgb(45 212 191 / 0) !important;
}

.tw-accent-teal-400\/10 {
  accent-color: rgb(45 212 191 / 0.1) !important;
}

.tw-accent-teal-400\/100 {
  accent-color: rgb(45 212 191 / 1) !important;
}

.tw-accent-teal-400\/15 {
  accent-color: rgb(45 212 191 / 0.15) !important;
}

.tw-accent-teal-400\/20 {
  accent-color: rgb(45 212 191 / 0.2) !important;
}

.tw-accent-teal-400\/25 {
  accent-color: rgb(45 212 191 / 0.25) !important;
}

.tw-accent-teal-400\/30 {
  accent-color: rgb(45 212 191 / 0.3) !important;
}

.tw-accent-teal-400\/35 {
  accent-color: rgb(45 212 191 / 0.35) !important;
}

.tw-accent-teal-400\/40 {
  accent-color: rgb(45 212 191 / 0.4) !important;
}

.tw-accent-teal-400\/45 {
  accent-color: rgb(45 212 191 / 0.45) !important;
}

.tw-accent-teal-400\/5 {
  accent-color: rgb(45 212 191 / 0.05) !important;
}

.tw-accent-teal-400\/50 {
  accent-color: rgb(45 212 191 / 0.5) !important;
}

.tw-accent-teal-400\/55 {
  accent-color: rgb(45 212 191 / 0.55) !important;
}

.tw-accent-teal-400\/60 {
  accent-color: rgb(45 212 191 / 0.6) !important;
}

.tw-accent-teal-400\/65 {
  accent-color: rgb(45 212 191 / 0.65) !important;
}

.tw-accent-teal-400\/70 {
  accent-color: rgb(45 212 191 / 0.7) !important;
}

.tw-accent-teal-400\/75 {
  accent-color: rgb(45 212 191 / 0.75) !important;
}

.tw-accent-teal-400\/80 {
  accent-color: rgb(45 212 191 / 0.8) !important;
}

.tw-accent-teal-400\/85 {
  accent-color: rgb(45 212 191 / 0.85) !important;
}

.tw-accent-teal-400\/90 {
  accent-color: rgb(45 212 191 / 0.9) !important;
}

.tw-accent-teal-400\/95 {
  accent-color: rgb(45 212 191 / 0.95) !important;
}

.tw-accent-teal-50 {
  accent-color: #f0fdfa !important;
}

.tw-accent-teal-50\/0 {
  accent-color: rgb(240 253 250 / 0) !important;
}

.tw-accent-teal-50\/10 {
  accent-color: rgb(240 253 250 / 0.1) !important;
}

.tw-accent-teal-50\/100 {
  accent-color: rgb(240 253 250 / 1) !important;
}

.tw-accent-teal-50\/15 {
  accent-color: rgb(240 253 250 / 0.15) !important;
}

.tw-accent-teal-50\/20 {
  accent-color: rgb(240 253 250 / 0.2) !important;
}

.tw-accent-teal-50\/25 {
  accent-color: rgb(240 253 250 / 0.25) !important;
}

.tw-accent-teal-50\/30 {
  accent-color: rgb(240 253 250 / 0.3) !important;
}

.tw-accent-teal-50\/35 {
  accent-color: rgb(240 253 250 / 0.35) !important;
}

.tw-accent-teal-50\/40 {
  accent-color: rgb(240 253 250 / 0.4) !important;
}

.tw-accent-teal-50\/45 {
  accent-color: rgb(240 253 250 / 0.45) !important;
}

.tw-accent-teal-50\/5 {
  accent-color: rgb(240 253 250 / 0.05) !important;
}

.tw-accent-teal-50\/50 {
  accent-color: rgb(240 253 250 / 0.5) !important;
}

.tw-accent-teal-50\/55 {
  accent-color: rgb(240 253 250 / 0.55) !important;
}

.tw-accent-teal-50\/60 {
  accent-color: rgb(240 253 250 / 0.6) !important;
}

.tw-accent-teal-50\/65 {
  accent-color: rgb(240 253 250 / 0.65) !important;
}

.tw-accent-teal-50\/70 {
  accent-color: rgb(240 253 250 / 0.7) !important;
}

.tw-accent-teal-50\/75 {
  accent-color: rgb(240 253 250 / 0.75) !important;
}

.tw-accent-teal-50\/80 {
  accent-color: rgb(240 253 250 / 0.8) !important;
}

.tw-accent-teal-50\/85 {
  accent-color: rgb(240 253 250 / 0.85) !important;
}

.tw-accent-teal-50\/90 {
  accent-color: rgb(240 253 250 / 0.9) !important;
}

.tw-accent-teal-50\/95 {
  accent-color: rgb(240 253 250 / 0.95) !important;
}

.tw-accent-teal-500 {
  accent-color: #14b8a6 !important;
}

.tw-accent-teal-500\/0 {
  accent-color: rgb(20 184 166 / 0) !important;
}

.tw-accent-teal-500\/10 {
  accent-color: rgb(20 184 166 / 0.1) !important;
}

.tw-accent-teal-500\/100 {
  accent-color: rgb(20 184 166 / 1) !important;
}

.tw-accent-teal-500\/15 {
  accent-color: rgb(20 184 166 / 0.15) !important;
}

.tw-accent-teal-500\/20 {
  accent-color: rgb(20 184 166 / 0.2) !important;
}

.tw-accent-teal-500\/25 {
  accent-color: rgb(20 184 166 / 0.25) !important;
}

.tw-accent-teal-500\/30 {
  accent-color: rgb(20 184 166 / 0.3) !important;
}

.tw-accent-teal-500\/35 {
  accent-color: rgb(20 184 166 / 0.35) !important;
}

.tw-accent-teal-500\/40 {
  accent-color: rgb(20 184 166 / 0.4) !important;
}

.tw-accent-teal-500\/45 {
  accent-color: rgb(20 184 166 / 0.45) !important;
}

.tw-accent-teal-500\/5 {
  accent-color: rgb(20 184 166 / 0.05) !important;
}

.tw-accent-teal-500\/50 {
  accent-color: rgb(20 184 166 / 0.5) !important;
}

.tw-accent-teal-500\/55 {
  accent-color: rgb(20 184 166 / 0.55) !important;
}

.tw-accent-teal-500\/60 {
  accent-color: rgb(20 184 166 / 0.6) !important;
}

.tw-accent-teal-500\/65 {
  accent-color: rgb(20 184 166 / 0.65) !important;
}

.tw-accent-teal-500\/70 {
  accent-color: rgb(20 184 166 / 0.7) !important;
}

.tw-accent-teal-500\/75 {
  accent-color: rgb(20 184 166 / 0.75) !important;
}

.tw-accent-teal-500\/80 {
  accent-color: rgb(20 184 166 / 0.8) !important;
}

.tw-accent-teal-500\/85 {
  accent-color: rgb(20 184 166 / 0.85) !important;
}

.tw-accent-teal-500\/90 {
  accent-color: rgb(20 184 166 / 0.9) !important;
}

.tw-accent-teal-500\/95 {
  accent-color: rgb(20 184 166 / 0.95) !important;
}

.tw-accent-teal-600 {
  accent-color: #0d9488 !important;
}

.tw-accent-teal-600\/0 {
  accent-color: rgb(13 148 136 / 0) !important;
}

.tw-accent-teal-600\/10 {
  accent-color: rgb(13 148 136 / 0.1) !important;
}

.tw-accent-teal-600\/100 {
  accent-color: rgb(13 148 136 / 1) !important;
}

.tw-accent-teal-600\/15 {
  accent-color: rgb(13 148 136 / 0.15) !important;
}

.tw-accent-teal-600\/20 {
  accent-color: rgb(13 148 136 / 0.2) !important;
}

.tw-accent-teal-600\/25 {
  accent-color: rgb(13 148 136 / 0.25) !important;
}

.tw-accent-teal-600\/30 {
  accent-color: rgb(13 148 136 / 0.3) !important;
}

.tw-accent-teal-600\/35 {
  accent-color: rgb(13 148 136 / 0.35) !important;
}

.tw-accent-teal-600\/40 {
  accent-color: rgb(13 148 136 / 0.4) !important;
}

.tw-accent-teal-600\/45 {
  accent-color: rgb(13 148 136 / 0.45) !important;
}

.tw-accent-teal-600\/5 {
  accent-color: rgb(13 148 136 / 0.05) !important;
}

.tw-accent-teal-600\/50 {
  accent-color: rgb(13 148 136 / 0.5) !important;
}

.tw-accent-teal-600\/55 {
  accent-color: rgb(13 148 136 / 0.55) !important;
}

.tw-accent-teal-600\/60 {
  accent-color: rgb(13 148 136 / 0.6) !important;
}

.tw-accent-teal-600\/65 {
  accent-color: rgb(13 148 136 / 0.65) !important;
}

.tw-accent-teal-600\/70 {
  accent-color: rgb(13 148 136 / 0.7) !important;
}

.tw-accent-teal-600\/75 {
  accent-color: rgb(13 148 136 / 0.75) !important;
}

.tw-accent-teal-600\/80 {
  accent-color: rgb(13 148 136 / 0.8) !important;
}

.tw-accent-teal-600\/85 {
  accent-color: rgb(13 148 136 / 0.85) !important;
}

.tw-accent-teal-600\/90 {
  accent-color: rgb(13 148 136 / 0.9) !important;
}

.tw-accent-teal-600\/95 {
  accent-color: rgb(13 148 136 / 0.95) !important;
}

.tw-accent-teal-700 {
  accent-color: #0f766e !important;
}

.tw-accent-teal-700\/0 {
  accent-color: rgb(15 118 110 / 0) !important;
}

.tw-accent-teal-700\/10 {
  accent-color: rgb(15 118 110 / 0.1) !important;
}

.tw-accent-teal-700\/100 {
  accent-color: rgb(15 118 110 / 1) !important;
}

.tw-accent-teal-700\/15 {
  accent-color: rgb(15 118 110 / 0.15) !important;
}

.tw-accent-teal-700\/20 {
  accent-color: rgb(15 118 110 / 0.2) !important;
}

.tw-accent-teal-700\/25 {
  accent-color: rgb(15 118 110 / 0.25) !important;
}

.tw-accent-teal-700\/30 {
  accent-color: rgb(15 118 110 / 0.3) !important;
}

.tw-accent-teal-700\/35 {
  accent-color: rgb(15 118 110 / 0.35) !important;
}

.tw-accent-teal-700\/40 {
  accent-color: rgb(15 118 110 / 0.4) !important;
}

.tw-accent-teal-700\/45 {
  accent-color: rgb(15 118 110 / 0.45) !important;
}

.tw-accent-teal-700\/5 {
  accent-color: rgb(15 118 110 / 0.05) !important;
}

.tw-accent-teal-700\/50 {
  accent-color: rgb(15 118 110 / 0.5) !important;
}

.tw-accent-teal-700\/55 {
  accent-color: rgb(15 118 110 / 0.55) !important;
}

.tw-accent-teal-700\/60 {
  accent-color: rgb(15 118 110 / 0.6) !important;
}

.tw-accent-teal-700\/65 {
  accent-color: rgb(15 118 110 / 0.65) !important;
}

.tw-accent-teal-700\/70 {
  accent-color: rgb(15 118 110 / 0.7) !important;
}

.tw-accent-teal-700\/75 {
  accent-color: rgb(15 118 110 / 0.75) !important;
}

.tw-accent-teal-700\/80 {
  accent-color: rgb(15 118 110 / 0.8) !important;
}

.tw-accent-teal-700\/85 {
  accent-color: rgb(15 118 110 / 0.85) !important;
}

.tw-accent-teal-700\/90 {
  accent-color: rgb(15 118 110 / 0.9) !important;
}

.tw-accent-teal-700\/95 {
  accent-color: rgb(15 118 110 / 0.95) !important;
}

.tw-accent-teal-800 {
  accent-color: #115e59 !important;
}

.tw-accent-teal-800\/0 {
  accent-color: rgb(17 94 89 / 0) !important;
}

.tw-accent-teal-800\/10 {
  accent-color: rgb(17 94 89 / 0.1) !important;
}

.tw-accent-teal-800\/100 {
  accent-color: rgb(17 94 89 / 1) !important;
}

.tw-accent-teal-800\/15 {
  accent-color: rgb(17 94 89 / 0.15) !important;
}

.tw-accent-teal-800\/20 {
  accent-color: rgb(17 94 89 / 0.2) !important;
}

.tw-accent-teal-800\/25 {
  accent-color: rgb(17 94 89 / 0.25) !important;
}

.tw-accent-teal-800\/30 {
  accent-color: rgb(17 94 89 / 0.3) !important;
}

.tw-accent-teal-800\/35 {
  accent-color: rgb(17 94 89 / 0.35) !important;
}

.tw-accent-teal-800\/40 {
  accent-color: rgb(17 94 89 / 0.4) !important;
}

.tw-accent-teal-800\/45 {
  accent-color: rgb(17 94 89 / 0.45) !important;
}

.tw-accent-teal-800\/5 {
  accent-color: rgb(17 94 89 / 0.05) !important;
}

.tw-accent-teal-800\/50 {
  accent-color: rgb(17 94 89 / 0.5) !important;
}

.tw-accent-teal-800\/55 {
  accent-color: rgb(17 94 89 / 0.55) !important;
}

.tw-accent-teal-800\/60 {
  accent-color: rgb(17 94 89 / 0.6) !important;
}

.tw-accent-teal-800\/65 {
  accent-color: rgb(17 94 89 / 0.65) !important;
}

.tw-accent-teal-800\/70 {
  accent-color: rgb(17 94 89 / 0.7) !important;
}

.tw-accent-teal-800\/75 {
  accent-color: rgb(17 94 89 / 0.75) !important;
}

.tw-accent-teal-800\/80 {
  accent-color: rgb(17 94 89 / 0.8) !important;
}

.tw-accent-teal-800\/85 {
  accent-color: rgb(17 94 89 / 0.85) !important;
}

.tw-accent-teal-800\/90 {
  accent-color: rgb(17 94 89 / 0.9) !important;
}

.tw-accent-teal-800\/95 {
  accent-color: rgb(17 94 89 / 0.95) !important;
}

.tw-accent-teal-900 {
  accent-color: #134e4a !important;
}

.tw-accent-teal-900\/0 {
  accent-color: rgb(19 78 74 / 0) !important;
}

.tw-accent-teal-900\/10 {
  accent-color: rgb(19 78 74 / 0.1) !important;
}

.tw-accent-teal-900\/100 {
  accent-color: rgb(19 78 74 / 1) !important;
}

.tw-accent-teal-900\/15 {
  accent-color: rgb(19 78 74 / 0.15) !important;
}

.tw-accent-teal-900\/20 {
  accent-color: rgb(19 78 74 / 0.2) !important;
}

.tw-accent-teal-900\/25 {
  accent-color: rgb(19 78 74 / 0.25) !important;
}

.tw-accent-teal-900\/30 {
  accent-color: rgb(19 78 74 / 0.3) !important;
}

.tw-accent-teal-900\/35 {
  accent-color: rgb(19 78 74 / 0.35) !important;
}

.tw-accent-teal-900\/40 {
  accent-color: rgb(19 78 74 / 0.4) !important;
}

.tw-accent-teal-900\/45 {
  accent-color: rgb(19 78 74 / 0.45) !important;
}

.tw-accent-teal-900\/5 {
  accent-color: rgb(19 78 74 / 0.05) !important;
}

.tw-accent-teal-900\/50 {
  accent-color: rgb(19 78 74 / 0.5) !important;
}

.tw-accent-teal-900\/55 {
  accent-color: rgb(19 78 74 / 0.55) !important;
}

.tw-accent-teal-900\/60 {
  accent-color: rgb(19 78 74 / 0.6) !important;
}

.tw-accent-teal-900\/65 {
  accent-color: rgb(19 78 74 / 0.65) !important;
}

.tw-accent-teal-900\/70 {
  accent-color: rgb(19 78 74 / 0.7) !important;
}

.tw-accent-teal-900\/75 {
  accent-color: rgb(19 78 74 / 0.75) !important;
}

.tw-accent-teal-900\/80 {
  accent-color: rgb(19 78 74 / 0.8) !important;
}

.tw-accent-teal-900\/85 {
  accent-color: rgb(19 78 74 / 0.85) !important;
}

.tw-accent-teal-900\/90 {
  accent-color: rgb(19 78 74 / 0.9) !important;
}

.tw-accent-teal-900\/95 {
  accent-color: rgb(19 78 74 / 0.95) !important;
}

.tw-accent-teal-950 {
  accent-color: #042f2e !important;
}

.tw-accent-teal-950\/0 {
  accent-color: rgb(4 47 46 / 0) !important;
}

.tw-accent-teal-950\/10 {
  accent-color: rgb(4 47 46 / 0.1) !important;
}

.tw-accent-teal-950\/100 {
  accent-color: rgb(4 47 46 / 1) !important;
}

.tw-accent-teal-950\/15 {
  accent-color: rgb(4 47 46 / 0.15) !important;
}

.tw-accent-teal-950\/20 {
  accent-color: rgb(4 47 46 / 0.2) !important;
}

.tw-accent-teal-950\/25 {
  accent-color: rgb(4 47 46 / 0.25) !important;
}

.tw-accent-teal-950\/30 {
  accent-color: rgb(4 47 46 / 0.3) !important;
}

.tw-accent-teal-950\/35 {
  accent-color: rgb(4 47 46 / 0.35) !important;
}

.tw-accent-teal-950\/40 {
  accent-color: rgb(4 47 46 / 0.4) !important;
}

.tw-accent-teal-950\/45 {
  accent-color: rgb(4 47 46 / 0.45) !important;
}

.tw-accent-teal-950\/5 {
  accent-color: rgb(4 47 46 / 0.05) !important;
}

.tw-accent-teal-950\/50 {
  accent-color: rgb(4 47 46 / 0.5) !important;
}

.tw-accent-teal-950\/55 {
  accent-color: rgb(4 47 46 / 0.55) !important;
}

.tw-accent-teal-950\/60 {
  accent-color: rgb(4 47 46 / 0.6) !important;
}

.tw-accent-teal-950\/65 {
  accent-color: rgb(4 47 46 / 0.65) !important;
}

.tw-accent-teal-950\/70 {
  accent-color: rgb(4 47 46 / 0.7) !important;
}

.tw-accent-teal-950\/75 {
  accent-color: rgb(4 47 46 / 0.75) !important;
}

.tw-accent-teal-950\/80 {
  accent-color: rgb(4 47 46 / 0.8) !important;
}

.tw-accent-teal-950\/85 {
  accent-color: rgb(4 47 46 / 0.85) !important;
}

.tw-accent-teal-950\/90 {
  accent-color: rgb(4 47 46 / 0.9) !important;
}

.tw-accent-teal-950\/95 {
  accent-color: rgb(4 47 46 / 0.95) !important;
}

.tw-accent-transparent {
  accent-color: transparent !important;
}

.tw-accent-transparent\/0 {
  accent-color: rgb(0 0 0 / 0) !important;
}

.tw-accent-transparent\/10 {
  accent-color: rgb(0 0 0 / 0.1) !important;
}

.tw-accent-transparent\/100 {
  accent-color: rgb(0 0 0 / 1) !important;
}

.tw-accent-transparent\/15 {
  accent-color: rgb(0 0 0 / 0.15) !important;
}

.tw-accent-transparent\/20 {
  accent-color: rgb(0 0 0 / 0.2) !important;
}

.tw-accent-transparent\/25 {
  accent-color: rgb(0 0 0 / 0.25) !important;
}

.tw-accent-transparent\/30 {
  accent-color: rgb(0 0 0 / 0.3) !important;
}

.tw-accent-transparent\/35 {
  accent-color: rgb(0 0 0 / 0.35) !important;
}

.tw-accent-transparent\/40 {
  accent-color: rgb(0 0 0 / 0.4) !important;
}

.tw-accent-transparent\/45 {
  accent-color: rgb(0 0 0 / 0.45) !important;
}

.tw-accent-transparent\/5 {
  accent-color: rgb(0 0 0 / 0.05) !important;
}

.tw-accent-transparent\/50 {
  accent-color: rgb(0 0 0 / 0.5) !important;
}

.tw-accent-transparent\/55 {
  accent-color: rgb(0 0 0 / 0.55) !important;
}

.tw-accent-transparent\/60 {
  accent-color: rgb(0 0 0 / 0.6) !important;
}

.tw-accent-transparent\/65 {
  accent-color: rgb(0 0 0 / 0.65) !important;
}

.tw-accent-transparent\/70 {
  accent-color: rgb(0 0 0 / 0.7) !important;
}

.tw-accent-transparent\/75 {
  accent-color: rgb(0 0 0 / 0.75) !important;
}

.tw-accent-transparent\/80 {
  accent-color: rgb(0 0 0 / 0.8) !important;
}

.tw-accent-transparent\/85 {
  accent-color: rgb(0 0 0 / 0.85) !important;
}

.tw-accent-transparent\/90 {
  accent-color: rgb(0 0 0 / 0.9) !important;
}

.tw-accent-transparent\/95 {
  accent-color: rgb(0 0 0 / 0.95) !important;
}

.tw-accent-violet-100 {
  accent-color: #ede9fe !important;
}

.tw-accent-violet-100\/0 {
  accent-color: rgb(237 233 254 / 0) !important;
}

.tw-accent-violet-100\/10 {
  accent-color: rgb(237 233 254 / 0.1) !important;
}

.tw-accent-violet-100\/100 {
  accent-color: rgb(237 233 254 / 1) !important;
}

.tw-accent-violet-100\/15 {
  accent-color: rgb(237 233 254 / 0.15) !important;
}

.tw-accent-violet-100\/20 {
  accent-color: rgb(237 233 254 / 0.2) !important;
}

.tw-accent-violet-100\/25 {
  accent-color: rgb(237 233 254 / 0.25) !important;
}

.tw-accent-violet-100\/30 {
  accent-color: rgb(237 233 254 / 0.3) !important;
}

.tw-accent-violet-100\/35 {
  accent-color: rgb(237 233 254 / 0.35) !important;
}

.tw-accent-violet-100\/40 {
  accent-color: rgb(237 233 254 / 0.4) !important;
}

.tw-accent-violet-100\/45 {
  accent-color: rgb(237 233 254 / 0.45) !important;
}

.tw-accent-violet-100\/5 {
  accent-color: rgb(237 233 254 / 0.05) !important;
}

.tw-accent-violet-100\/50 {
  accent-color: rgb(237 233 254 / 0.5) !important;
}

.tw-accent-violet-100\/55 {
  accent-color: rgb(237 233 254 / 0.55) !important;
}

.tw-accent-violet-100\/60 {
  accent-color: rgb(237 233 254 / 0.6) !important;
}

.tw-accent-violet-100\/65 {
  accent-color: rgb(237 233 254 / 0.65) !important;
}

.tw-accent-violet-100\/70 {
  accent-color: rgb(237 233 254 / 0.7) !important;
}

.tw-accent-violet-100\/75 {
  accent-color: rgb(237 233 254 / 0.75) !important;
}

.tw-accent-violet-100\/80 {
  accent-color: rgb(237 233 254 / 0.8) !important;
}

.tw-accent-violet-100\/85 {
  accent-color: rgb(237 233 254 / 0.85) !important;
}

.tw-accent-violet-100\/90 {
  accent-color: rgb(237 233 254 / 0.9) !important;
}

.tw-accent-violet-100\/95 {
  accent-color: rgb(237 233 254 / 0.95) !important;
}

.tw-accent-violet-200 {
  accent-color: #ddd6fe !important;
}

.tw-accent-violet-200\/0 {
  accent-color: rgb(221 214 254 / 0) !important;
}

.tw-accent-violet-200\/10 {
  accent-color: rgb(221 214 254 / 0.1) !important;
}

.tw-accent-violet-200\/100 {
  accent-color: rgb(221 214 254 / 1) !important;
}

.tw-accent-violet-200\/15 {
  accent-color: rgb(221 214 254 / 0.15) !important;
}

.tw-accent-violet-200\/20 {
  accent-color: rgb(221 214 254 / 0.2) !important;
}

.tw-accent-violet-200\/25 {
  accent-color: rgb(221 214 254 / 0.25) !important;
}

.tw-accent-violet-200\/30 {
  accent-color: rgb(221 214 254 / 0.3) !important;
}

.tw-accent-violet-200\/35 {
  accent-color: rgb(221 214 254 / 0.35) !important;
}

.tw-accent-violet-200\/40 {
  accent-color: rgb(221 214 254 / 0.4) !important;
}

.tw-accent-violet-200\/45 {
  accent-color: rgb(221 214 254 / 0.45) !important;
}

.tw-accent-violet-200\/5 {
  accent-color: rgb(221 214 254 / 0.05) !important;
}

.tw-accent-violet-200\/50 {
  accent-color: rgb(221 214 254 / 0.5) !important;
}

.tw-accent-violet-200\/55 {
  accent-color: rgb(221 214 254 / 0.55) !important;
}

.tw-accent-violet-200\/60 {
  accent-color: rgb(221 214 254 / 0.6) !important;
}

.tw-accent-violet-200\/65 {
  accent-color: rgb(221 214 254 / 0.65) !important;
}

.tw-accent-violet-200\/70 {
  accent-color: rgb(221 214 254 / 0.7) !important;
}

.tw-accent-violet-200\/75 {
  accent-color: rgb(221 214 254 / 0.75) !important;
}

.tw-accent-violet-200\/80 {
  accent-color: rgb(221 214 254 / 0.8) !important;
}

.tw-accent-violet-200\/85 {
  accent-color: rgb(221 214 254 / 0.85) !important;
}

.tw-accent-violet-200\/90 {
  accent-color: rgb(221 214 254 / 0.9) !important;
}

.tw-accent-violet-200\/95 {
  accent-color: rgb(221 214 254 / 0.95) !important;
}

.tw-accent-violet-300 {
  accent-color: #c4b5fd !important;
}

.tw-accent-violet-300\/0 {
  accent-color: rgb(196 181 253 / 0) !important;
}

.tw-accent-violet-300\/10 {
  accent-color: rgb(196 181 253 / 0.1) !important;
}

.tw-accent-violet-300\/100 {
  accent-color: rgb(196 181 253 / 1) !important;
}

.tw-accent-violet-300\/15 {
  accent-color: rgb(196 181 253 / 0.15) !important;
}

.tw-accent-violet-300\/20 {
  accent-color: rgb(196 181 253 / 0.2) !important;
}

.tw-accent-violet-300\/25 {
  accent-color: rgb(196 181 253 / 0.25) !important;
}

.tw-accent-violet-300\/30 {
  accent-color: rgb(196 181 253 / 0.3) !important;
}

.tw-accent-violet-300\/35 {
  accent-color: rgb(196 181 253 / 0.35) !important;
}

.tw-accent-violet-300\/40 {
  accent-color: rgb(196 181 253 / 0.4) !important;
}

.tw-accent-violet-300\/45 {
  accent-color: rgb(196 181 253 / 0.45) !important;
}

.tw-accent-violet-300\/5 {
  accent-color: rgb(196 181 253 / 0.05) !important;
}

.tw-accent-violet-300\/50 {
  accent-color: rgb(196 181 253 / 0.5) !important;
}

.tw-accent-violet-300\/55 {
  accent-color: rgb(196 181 253 / 0.55) !important;
}

.tw-accent-violet-300\/60 {
  accent-color: rgb(196 181 253 / 0.6) !important;
}

.tw-accent-violet-300\/65 {
  accent-color: rgb(196 181 253 / 0.65) !important;
}

.tw-accent-violet-300\/70 {
  accent-color: rgb(196 181 253 / 0.7) !important;
}

.tw-accent-violet-300\/75 {
  accent-color: rgb(196 181 253 / 0.75) !important;
}

.tw-accent-violet-300\/80 {
  accent-color: rgb(196 181 253 / 0.8) !important;
}

.tw-accent-violet-300\/85 {
  accent-color: rgb(196 181 253 / 0.85) !important;
}

.tw-accent-violet-300\/90 {
  accent-color: rgb(196 181 253 / 0.9) !important;
}

.tw-accent-violet-300\/95 {
  accent-color: rgb(196 181 253 / 0.95) !important;
}

.tw-accent-violet-400 {
  accent-color: #a78bfa !important;
}

.tw-accent-violet-400\/0 {
  accent-color: rgb(167 139 250 / 0) !important;
}

.tw-accent-violet-400\/10 {
  accent-color: rgb(167 139 250 / 0.1) !important;
}

.tw-accent-violet-400\/100 {
  accent-color: rgb(167 139 250 / 1) !important;
}

.tw-accent-violet-400\/15 {
  accent-color: rgb(167 139 250 / 0.15) !important;
}

.tw-accent-violet-400\/20 {
  accent-color: rgb(167 139 250 / 0.2) !important;
}

.tw-accent-violet-400\/25 {
  accent-color: rgb(167 139 250 / 0.25) !important;
}

.tw-accent-violet-400\/30 {
  accent-color: rgb(167 139 250 / 0.3) !important;
}

.tw-accent-violet-400\/35 {
  accent-color: rgb(167 139 250 / 0.35) !important;
}

.tw-accent-violet-400\/40 {
  accent-color: rgb(167 139 250 / 0.4) !important;
}

.tw-accent-violet-400\/45 {
  accent-color: rgb(167 139 250 / 0.45) !important;
}

.tw-accent-violet-400\/5 {
  accent-color: rgb(167 139 250 / 0.05) !important;
}

.tw-accent-violet-400\/50 {
  accent-color: rgb(167 139 250 / 0.5) !important;
}

.tw-accent-violet-400\/55 {
  accent-color: rgb(167 139 250 / 0.55) !important;
}

.tw-accent-violet-400\/60 {
  accent-color: rgb(167 139 250 / 0.6) !important;
}

.tw-accent-violet-400\/65 {
  accent-color: rgb(167 139 250 / 0.65) !important;
}

.tw-accent-violet-400\/70 {
  accent-color: rgb(167 139 250 / 0.7) !important;
}

.tw-accent-violet-400\/75 {
  accent-color: rgb(167 139 250 / 0.75) !important;
}

.tw-accent-violet-400\/80 {
  accent-color: rgb(167 139 250 / 0.8) !important;
}

.tw-accent-violet-400\/85 {
  accent-color: rgb(167 139 250 / 0.85) !important;
}

.tw-accent-violet-400\/90 {
  accent-color: rgb(167 139 250 / 0.9) !important;
}

.tw-accent-violet-400\/95 {
  accent-color: rgb(167 139 250 / 0.95) !important;
}

.tw-accent-violet-50 {
  accent-color: #f5f3ff !important;
}

.tw-accent-violet-50\/0 {
  accent-color: rgb(245 243 255 / 0) !important;
}

.tw-accent-violet-50\/10 {
  accent-color: rgb(245 243 255 / 0.1) !important;
}

.tw-accent-violet-50\/100 {
  accent-color: rgb(245 243 255 / 1) !important;
}

.tw-accent-violet-50\/15 {
  accent-color: rgb(245 243 255 / 0.15) !important;
}

.tw-accent-violet-50\/20 {
  accent-color: rgb(245 243 255 / 0.2) !important;
}

.tw-accent-violet-50\/25 {
  accent-color: rgb(245 243 255 / 0.25) !important;
}

.tw-accent-violet-50\/30 {
  accent-color: rgb(245 243 255 / 0.3) !important;
}

.tw-accent-violet-50\/35 {
  accent-color: rgb(245 243 255 / 0.35) !important;
}

.tw-accent-violet-50\/40 {
  accent-color: rgb(245 243 255 / 0.4) !important;
}

.tw-accent-violet-50\/45 {
  accent-color: rgb(245 243 255 / 0.45) !important;
}

.tw-accent-violet-50\/5 {
  accent-color: rgb(245 243 255 / 0.05) !important;
}

.tw-accent-violet-50\/50 {
  accent-color: rgb(245 243 255 / 0.5) !important;
}

.tw-accent-violet-50\/55 {
  accent-color: rgb(245 243 255 / 0.55) !important;
}

.tw-accent-violet-50\/60 {
  accent-color: rgb(245 243 255 / 0.6) !important;
}

.tw-accent-violet-50\/65 {
  accent-color: rgb(245 243 255 / 0.65) !important;
}

.tw-accent-violet-50\/70 {
  accent-color: rgb(245 243 255 / 0.7) !important;
}

.tw-accent-violet-50\/75 {
  accent-color: rgb(245 243 255 / 0.75) !important;
}

.tw-accent-violet-50\/80 {
  accent-color: rgb(245 243 255 / 0.8) !important;
}

.tw-accent-violet-50\/85 {
  accent-color: rgb(245 243 255 / 0.85) !important;
}

.tw-accent-violet-50\/90 {
  accent-color: rgb(245 243 255 / 0.9) !important;
}

.tw-accent-violet-50\/95 {
  accent-color: rgb(245 243 255 / 0.95) !important;
}

.tw-accent-violet-500 {
  accent-color: #8b5cf6 !important;
}

.tw-accent-violet-500\/0 {
  accent-color: rgb(139 92 246 / 0) !important;
}

.tw-accent-violet-500\/10 {
  accent-color: rgb(139 92 246 / 0.1) !important;
}

.tw-accent-violet-500\/100 {
  accent-color: rgb(139 92 246 / 1) !important;
}

.tw-accent-violet-500\/15 {
  accent-color: rgb(139 92 246 / 0.15) !important;
}

.tw-accent-violet-500\/20 {
  accent-color: rgb(139 92 246 / 0.2) !important;
}

.tw-accent-violet-500\/25 {
  accent-color: rgb(139 92 246 / 0.25) !important;
}

.tw-accent-violet-500\/30 {
  accent-color: rgb(139 92 246 / 0.3) !important;
}

.tw-accent-violet-500\/35 {
  accent-color: rgb(139 92 246 / 0.35) !important;
}

.tw-accent-violet-500\/40 {
  accent-color: rgb(139 92 246 / 0.4) !important;
}

.tw-accent-violet-500\/45 {
  accent-color: rgb(139 92 246 / 0.45) !important;
}

.tw-accent-violet-500\/5 {
  accent-color: rgb(139 92 246 / 0.05) !important;
}

.tw-accent-violet-500\/50 {
  accent-color: rgb(139 92 246 / 0.5) !important;
}

.tw-accent-violet-500\/55 {
  accent-color: rgb(139 92 246 / 0.55) !important;
}

.tw-accent-violet-500\/60 {
  accent-color: rgb(139 92 246 / 0.6) !important;
}

.tw-accent-violet-500\/65 {
  accent-color: rgb(139 92 246 / 0.65) !important;
}

.tw-accent-violet-500\/70 {
  accent-color: rgb(139 92 246 / 0.7) !important;
}

.tw-accent-violet-500\/75 {
  accent-color: rgb(139 92 246 / 0.75) !important;
}

.tw-accent-violet-500\/80 {
  accent-color: rgb(139 92 246 / 0.8) !important;
}

.tw-accent-violet-500\/85 {
  accent-color: rgb(139 92 246 / 0.85) !important;
}

.tw-accent-violet-500\/90 {
  accent-color: rgb(139 92 246 / 0.9) !important;
}

.tw-accent-violet-500\/95 {
  accent-color: rgb(139 92 246 / 0.95) !important;
}

.tw-accent-violet-600 {
  accent-color: #7c3aed !important;
}

.tw-accent-violet-600\/0 {
  accent-color: rgb(124 58 237 / 0) !important;
}

.tw-accent-violet-600\/10 {
  accent-color: rgb(124 58 237 / 0.1) !important;
}

.tw-accent-violet-600\/100 {
  accent-color: rgb(124 58 237 / 1) !important;
}

.tw-accent-violet-600\/15 {
  accent-color: rgb(124 58 237 / 0.15) !important;
}

.tw-accent-violet-600\/20 {
  accent-color: rgb(124 58 237 / 0.2) !important;
}

.tw-accent-violet-600\/25 {
  accent-color: rgb(124 58 237 / 0.25) !important;
}

.tw-accent-violet-600\/30 {
  accent-color: rgb(124 58 237 / 0.3) !important;
}

.tw-accent-violet-600\/35 {
  accent-color: rgb(124 58 237 / 0.35) !important;
}

.tw-accent-violet-600\/40 {
  accent-color: rgb(124 58 237 / 0.4) !important;
}

.tw-accent-violet-600\/45 {
  accent-color: rgb(124 58 237 / 0.45) !important;
}

.tw-accent-violet-600\/5 {
  accent-color: rgb(124 58 237 / 0.05) !important;
}

.tw-accent-violet-600\/50 {
  accent-color: rgb(124 58 237 / 0.5) !important;
}

.tw-accent-violet-600\/55 {
  accent-color: rgb(124 58 237 / 0.55) !important;
}

.tw-accent-violet-600\/60 {
  accent-color: rgb(124 58 237 / 0.6) !important;
}

.tw-accent-violet-600\/65 {
  accent-color: rgb(124 58 237 / 0.65) !important;
}

.tw-accent-violet-600\/70 {
  accent-color: rgb(124 58 237 / 0.7) !important;
}

.tw-accent-violet-600\/75 {
  accent-color: rgb(124 58 237 / 0.75) !important;
}

.tw-accent-violet-600\/80 {
  accent-color: rgb(124 58 237 / 0.8) !important;
}

.tw-accent-violet-600\/85 {
  accent-color: rgb(124 58 237 / 0.85) !important;
}

.tw-accent-violet-600\/90 {
  accent-color: rgb(124 58 237 / 0.9) !important;
}

.tw-accent-violet-600\/95 {
  accent-color: rgb(124 58 237 / 0.95) !important;
}

.tw-accent-violet-700 {
  accent-color: #6d28d9 !important;
}

.tw-accent-violet-700\/0 {
  accent-color: rgb(109 40 217 / 0) !important;
}

.tw-accent-violet-700\/10 {
  accent-color: rgb(109 40 217 / 0.1) !important;
}

.tw-accent-violet-700\/100 {
  accent-color: rgb(109 40 217 / 1) !important;
}

.tw-accent-violet-700\/15 {
  accent-color: rgb(109 40 217 / 0.15) !important;
}

.tw-accent-violet-700\/20 {
  accent-color: rgb(109 40 217 / 0.2) !important;
}

.tw-accent-violet-700\/25 {
  accent-color: rgb(109 40 217 / 0.25) !important;
}

.tw-accent-violet-700\/30 {
  accent-color: rgb(109 40 217 / 0.3) !important;
}

.tw-accent-violet-700\/35 {
  accent-color: rgb(109 40 217 / 0.35) !important;
}

.tw-accent-violet-700\/40 {
  accent-color: rgb(109 40 217 / 0.4) !important;
}

.tw-accent-violet-700\/45 {
  accent-color: rgb(109 40 217 / 0.45) !important;
}

.tw-accent-violet-700\/5 {
  accent-color: rgb(109 40 217 / 0.05) !important;
}

.tw-accent-violet-700\/50 {
  accent-color: rgb(109 40 217 / 0.5) !important;
}

.tw-accent-violet-700\/55 {
  accent-color: rgb(109 40 217 / 0.55) !important;
}

.tw-accent-violet-700\/60 {
  accent-color: rgb(109 40 217 / 0.6) !important;
}

.tw-accent-violet-700\/65 {
  accent-color: rgb(109 40 217 / 0.65) !important;
}

.tw-accent-violet-700\/70 {
  accent-color: rgb(109 40 217 / 0.7) !important;
}

.tw-accent-violet-700\/75 {
  accent-color: rgb(109 40 217 / 0.75) !important;
}

.tw-accent-violet-700\/80 {
  accent-color: rgb(109 40 217 / 0.8) !important;
}

.tw-accent-violet-700\/85 {
  accent-color: rgb(109 40 217 / 0.85) !important;
}

.tw-accent-violet-700\/90 {
  accent-color: rgb(109 40 217 / 0.9) !important;
}

.tw-accent-violet-700\/95 {
  accent-color: rgb(109 40 217 / 0.95) !important;
}

.tw-accent-violet-800 {
  accent-color: #5b21b6 !important;
}

.tw-accent-violet-800\/0 {
  accent-color: rgb(91 33 182 / 0) !important;
}

.tw-accent-violet-800\/10 {
  accent-color: rgb(91 33 182 / 0.1) !important;
}

.tw-accent-violet-800\/100 {
  accent-color: rgb(91 33 182 / 1) !important;
}

.tw-accent-violet-800\/15 {
  accent-color: rgb(91 33 182 / 0.15) !important;
}

.tw-accent-violet-800\/20 {
  accent-color: rgb(91 33 182 / 0.2) !important;
}

.tw-accent-violet-800\/25 {
  accent-color: rgb(91 33 182 / 0.25) !important;
}

.tw-accent-violet-800\/30 {
  accent-color: rgb(91 33 182 / 0.3) !important;
}

.tw-accent-violet-800\/35 {
  accent-color: rgb(91 33 182 / 0.35) !important;
}

.tw-accent-violet-800\/40 {
  accent-color: rgb(91 33 182 / 0.4) !important;
}

.tw-accent-violet-800\/45 {
  accent-color: rgb(91 33 182 / 0.45) !important;
}

.tw-accent-violet-800\/5 {
  accent-color: rgb(91 33 182 / 0.05) !important;
}

.tw-accent-violet-800\/50 {
  accent-color: rgb(91 33 182 / 0.5) !important;
}

.tw-accent-violet-800\/55 {
  accent-color: rgb(91 33 182 / 0.55) !important;
}

.tw-accent-violet-800\/60 {
  accent-color: rgb(91 33 182 / 0.6) !important;
}

.tw-accent-violet-800\/65 {
  accent-color: rgb(91 33 182 / 0.65) !important;
}

.tw-accent-violet-800\/70 {
  accent-color: rgb(91 33 182 / 0.7) !important;
}

.tw-accent-violet-800\/75 {
  accent-color: rgb(91 33 182 / 0.75) !important;
}

.tw-accent-violet-800\/80 {
  accent-color: rgb(91 33 182 / 0.8) !important;
}

.tw-accent-violet-800\/85 {
  accent-color: rgb(91 33 182 / 0.85) !important;
}

.tw-accent-violet-800\/90 {
  accent-color: rgb(91 33 182 / 0.9) !important;
}

.tw-accent-violet-800\/95 {
  accent-color: rgb(91 33 182 / 0.95) !important;
}

.tw-accent-violet-900 {
  accent-color: #4c1d95 !important;
}

.tw-accent-violet-900\/0 {
  accent-color: rgb(76 29 149 / 0) !important;
}

.tw-accent-violet-900\/10 {
  accent-color: rgb(76 29 149 / 0.1) !important;
}

.tw-accent-violet-900\/100 {
  accent-color: rgb(76 29 149 / 1) !important;
}

.tw-accent-violet-900\/15 {
  accent-color: rgb(76 29 149 / 0.15) !important;
}

.tw-accent-violet-900\/20 {
  accent-color: rgb(76 29 149 / 0.2) !important;
}

.tw-accent-violet-900\/25 {
  accent-color: rgb(76 29 149 / 0.25) !important;
}

.tw-accent-violet-900\/30 {
  accent-color: rgb(76 29 149 / 0.3) !important;
}

.tw-accent-violet-900\/35 {
  accent-color: rgb(76 29 149 / 0.35) !important;
}

.tw-accent-violet-900\/40 {
  accent-color: rgb(76 29 149 / 0.4) !important;
}

.tw-accent-violet-900\/45 {
  accent-color: rgb(76 29 149 / 0.45) !important;
}

.tw-accent-violet-900\/5 {
  accent-color: rgb(76 29 149 / 0.05) !important;
}

.tw-accent-violet-900\/50 {
  accent-color: rgb(76 29 149 / 0.5) !important;
}

.tw-accent-violet-900\/55 {
  accent-color: rgb(76 29 149 / 0.55) !important;
}

.tw-accent-violet-900\/60 {
  accent-color: rgb(76 29 149 / 0.6) !important;
}

.tw-accent-violet-900\/65 {
  accent-color: rgb(76 29 149 / 0.65) !important;
}

.tw-accent-violet-900\/70 {
  accent-color: rgb(76 29 149 / 0.7) !important;
}

.tw-accent-violet-900\/75 {
  accent-color: rgb(76 29 149 / 0.75) !important;
}

.tw-accent-violet-900\/80 {
  accent-color: rgb(76 29 149 / 0.8) !important;
}

.tw-accent-violet-900\/85 {
  accent-color: rgb(76 29 149 / 0.85) !important;
}

.tw-accent-violet-900\/90 {
  accent-color: rgb(76 29 149 / 0.9) !important;
}

.tw-accent-violet-900\/95 {
  accent-color: rgb(76 29 149 / 0.95) !important;
}

.tw-accent-violet-950 {
  accent-color: #2e1065 !important;
}

.tw-accent-violet-950\/0 {
  accent-color: rgb(46 16 101 / 0) !important;
}

.tw-accent-violet-950\/10 {
  accent-color: rgb(46 16 101 / 0.1) !important;
}

.tw-accent-violet-950\/100 {
  accent-color: rgb(46 16 101 / 1) !important;
}

.tw-accent-violet-950\/15 {
  accent-color: rgb(46 16 101 / 0.15) !important;
}

.tw-accent-violet-950\/20 {
  accent-color: rgb(46 16 101 / 0.2) !important;
}

.tw-accent-violet-950\/25 {
  accent-color: rgb(46 16 101 / 0.25) !important;
}

.tw-accent-violet-950\/30 {
  accent-color: rgb(46 16 101 / 0.3) !important;
}

.tw-accent-violet-950\/35 {
  accent-color: rgb(46 16 101 / 0.35) !important;
}

.tw-accent-violet-950\/40 {
  accent-color: rgb(46 16 101 / 0.4) !important;
}

.tw-accent-violet-950\/45 {
  accent-color: rgb(46 16 101 / 0.45) !important;
}

.tw-accent-violet-950\/5 {
  accent-color: rgb(46 16 101 / 0.05) !important;
}

.tw-accent-violet-950\/50 {
  accent-color: rgb(46 16 101 / 0.5) !important;
}

.tw-accent-violet-950\/55 {
  accent-color: rgb(46 16 101 / 0.55) !important;
}

.tw-accent-violet-950\/60 {
  accent-color: rgb(46 16 101 / 0.6) !important;
}

.tw-accent-violet-950\/65 {
  accent-color: rgb(46 16 101 / 0.65) !important;
}

.tw-accent-violet-950\/70 {
  accent-color: rgb(46 16 101 / 0.7) !important;
}

.tw-accent-violet-950\/75 {
  accent-color: rgb(46 16 101 / 0.75) !important;
}

.tw-accent-violet-950\/80 {
  accent-color: rgb(46 16 101 / 0.8) !important;
}

.tw-accent-violet-950\/85 {
  accent-color: rgb(46 16 101 / 0.85) !important;
}

.tw-accent-violet-950\/90 {
  accent-color: rgb(46 16 101 / 0.9) !important;
}

.tw-accent-violet-950\/95 {
  accent-color: rgb(46 16 101 / 0.95) !important;
}

.tw-accent-white {
  accent-color: #fff !important;
}

.tw-accent-white\/0 {
  accent-color: rgb(255 255 255 / 0) !important;
}

.tw-accent-white\/10 {
  accent-color: rgb(255 255 255 / 0.1) !important;
}

.tw-accent-white\/100 {
  accent-color: rgb(255 255 255 / 1) !important;
}

.tw-accent-white\/15 {
  accent-color: rgb(255 255 255 / 0.15) !important;
}

.tw-accent-white\/20 {
  accent-color: rgb(255 255 255 / 0.2) !important;
}

.tw-accent-white\/25 {
  accent-color: rgb(255 255 255 / 0.25) !important;
}

.tw-accent-white\/30 {
  accent-color: rgb(255 255 255 / 0.3) !important;
}

.tw-accent-white\/35 {
  accent-color: rgb(255 255 255 / 0.35) !important;
}

.tw-accent-white\/40 {
  accent-color: rgb(255 255 255 / 0.4) !important;
}

.tw-accent-white\/45 {
  accent-color: rgb(255 255 255 / 0.45) !important;
}

.tw-accent-white\/5 {
  accent-color: rgb(255 255 255 / 0.05) !important;
}

.tw-accent-white\/50 {
  accent-color: rgb(255 255 255 / 0.5) !important;
}

.tw-accent-white\/55 {
  accent-color: rgb(255 255 255 / 0.55) !important;
}

.tw-accent-white\/60 {
  accent-color: rgb(255 255 255 / 0.6) !important;
}

.tw-accent-white\/65 {
  accent-color: rgb(255 255 255 / 0.65) !important;
}

.tw-accent-white\/70 {
  accent-color: rgb(255 255 255 / 0.7) !important;
}

.tw-accent-white\/75 {
  accent-color: rgb(255 255 255 / 0.75) !important;
}

.tw-accent-white\/80 {
  accent-color: rgb(255 255 255 / 0.8) !important;
}

.tw-accent-white\/85 {
  accent-color: rgb(255 255 255 / 0.85) !important;
}

.tw-accent-white\/90 {
  accent-color: rgb(255 255 255 / 0.9) !important;
}

.tw-accent-white\/95 {
  accent-color: rgb(255 255 255 / 0.95) !important;
}

.tw-accent-yellow-100 {
  accent-color: #fef9c3 !important;
}

.tw-accent-yellow-100\/0 {
  accent-color: rgb(254 249 195 / 0) !important;
}

.tw-accent-yellow-100\/10 {
  accent-color: rgb(254 249 195 / 0.1) !important;
}

.tw-accent-yellow-100\/100 {
  accent-color: rgb(254 249 195 / 1) !important;
}

.tw-accent-yellow-100\/15 {
  accent-color: rgb(254 249 195 / 0.15) !important;
}

.tw-accent-yellow-100\/20 {
  accent-color: rgb(254 249 195 / 0.2) !important;
}

.tw-accent-yellow-100\/25 {
  accent-color: rgb(254 249 195 / 0.25) !important;
}

.tw-accent-yellow-100\/30 {
  accent-color: rgb(254 249 195 / 0.3) !important;
}

.tw-accent-yellow-100\/35 {
  accent-color: rgb(254 249 195 / 0.35) !important;
}

.tw-accent-yellow-100\/40 {
  accent-color: rgb(254 249 195 / 0.4) !important;
}

.tw-accent-yellow-100\/45 {
  accent-color: rgb(254 249 195 / 0.45) !important;
}

.tw-accent-yellow-100\/5 {
  accent-color: rgb(254 249 195 / 0.05) !important;
}

.tw-accent-yellow-100\/50 {
  accent-color: rgb(254 249 195 / 0.5) !important;
}

.tw-accent-yellow-100\/55 {
  accent-color: rgb(254 249 195 / 0.55) !important;
}

.tw-accent-yellow-100\/60 {
  accent-color: rgb(254 249 195 / 0.6) !important;
}

.tw-accent-yellow-100\/65 {
  accent-color: rgb(254 249 195 / 0.65) !important;
}

.tw-accent-yellow-100\/70 {
  accent-color: rgb(254 249 195 / 0.7) !important;
}

.tw-accent-yellow-100\/75 {
  accent-color: rgb(254 249 195 / 0.75) !important;
}

.tw-accent-yellow-100\/80 {
  accent-color: rgb(254 249 195 / 0.8) !important;
}

.tw-accent-yellow-100\/85 {
  accent-color: rgb(254 249 195 / 0.85) !important;
}

.tw-accent-yellow-100\/90 {
  accent-color: rgb(254 249 195 / 0.9) !important;
}

.tw-accent-yellow-100\/95 {
  accent-color: rgb(254 249 195 / 0.95) !important;
}

.tw-accent-yellow-200 {
  accent-color: #fef08a !important;
}

.tw-accent-yellow-200\/0 {
  accent-color: rgb(254 240 138 / 0) !important;
}

.tw-accent-yellow-200\/10 {
  accent-color: rgb(254 240 138 / 0.1) !important;
}

.tw-accent-yellow-200\/100 {
  accent-color: rgb(254 240 138 / 1) !important;
}

.tw-accent-yellow-200\/15 {
  accent-color: rgb(254 240 138 / 0.15) !important;
}

.tw-accent-yellow-200\/20 {
  accent-color: rgb(254 240 138 / 0.2) !important;
}

.tw-accent-yellow-200\/25 {
  accent-color: rgb(254 240 138 / 0.25) !important;
}

.tw-accent-yellow-200\/30 {
  accent-color: rgb(254 240 138 / 0.3) !important;
}

.tw-accent-yellow-200\/35 {
  accent-color: rgb(254 240 138 / 0.35) !important;
}

.tw-accent-yellow-200\/40 {
  accent-color: rgb(254 240 138 / 0.4) !important;
}

.tw-accent-yellow-200\/45 {
  accent-color: rgb(254 240 138 / 0.45) !important;
}

.tw-accent-yellow-200\/5 {
  accent-color: rgb(254 240 138 / 0.05) !important;
}

.tw-accent-yellow-200\/50 {
  accent-color: rgb(254 240 138 / 0.5) !important;
}

.tw-accent-yellow-200\/55 {
  accent-color: rgb(254 240 138 / 0.55) !important;
}

.tw-accent-yellow-200\/60 {
  accent-color: rgb(254 240 138 / 0.6) !important;
}

.tw-accent-yellow-200\/65 {
  accent-color: rgb(254 240 138 / 0.65) !important;
}

.tw-accent-yellow-200\/70 {
  accent-color: rgb(254 240 138 / 0.7) !important;
}

.tw-accent-yellow-200\/75 {
  accent-color: rgb(254 240 138 / 0.75) !important;
}

.tw-accent-yellow-200\/80 {
  accent-color: rgb(254 240 138 / 0.8) !important;
}

.tw-accent-yellow-200\/85 {
  accent-color: rgb(254 240 138 / 0.85) !important;
}

.tw-accent-yellow-200\/90 {
  accent-color: rgb(254 240 138 / 0.9) !important;
}

.tw-accent-yellow-200\/95 {
  accent-color: rgb(254 240 138 / 0.95) !important;
}

.tw-accent-yellow-300 {
  accent-color: #fde047 !important;
}

.tw-accent-yellow-300\/0 {
  accent-color: rgb(253 224 71 / 0) !important;
}

.tw-accent-yellow-300\/10 {
  accent-color: rgb(253 224 71 / 0.1) !important;
}

.tw-accent-yellow-300\/100 {
  accent-color: rgb(253 224 71 / 1) !important;
}

.tw-accent-yellow-300\/15 {
  accent-color: rgb(253 224 71 / 0.15) !important;
}

.tw-accent-yellow-300\/20 {
  accent-color: rgb(253 224 71 / 0.2) !important;
}

.tw-accent-yellow-300\/25 {
  accent-color: rgb(253 224 71 / 0.25) !important;
}

.tw-accent-yellow-300\/30 {
  accent-color: rgb(253 224 71 / 0.3) !important;
}

.tw-accent-yellow-300\/35 {
  accent-color: rgb(253 224 71 / 0.35) !important;
}

.tw-accent-yellow-300\/40 {
  accent-color: rgb(253 224 71 / 0.4) !important;
}

.tw-accent-yellow-300\/45 {
  accent-color: rgb(253 224 71 / 0.45) !important;
}

.tw-accent-yellow-300\/5 {
  accent-color: rgb(253 224 71 / 0.05) !important;
}

.tw-accent-yellow-300\/50 {
  accent-color: rgb(253 224 71 / 0.5) !important;
}

.tw-accent-yellow-300\/55 {
  accent-color: rgb(253 224 71 / 0.55) !important;
}

.tw-accent-yellow-300\/60 {
  accent-color: rgb(253 224 71 / 0.6) !important;
}

.tw-accent-yellow-300\/65 {
  accent-color: rgb(253 224 71 / 0.65) !important;
}

.tw-accent-yellow-300\/70 {
  accent-color: rgb(253 224 71 / 0.7) !important;
}

.tw-accent-yellow-300\/75 {
  accent-color: rgb(253 224 71 / 0.75) !important;
}

.tw-accent-yellow-300\/80 {
  accent-color: rgb(253 224 71 / 0.8) !important;
}

.tw-accent-yellow-300\/85 {
  accent-color: rgb(253 224 71 / 0.85) !important;
}

.tw-accent-yellow-300\/90 {
  accent-color: rgb(253 224 71 / 0.9) !important;
}

.tw-accent-yellow-300\/95 {
  accent-color: rgb(253 224 71 / 0.95) !important;
}

.tw-accent-yellow-400 {
  accent-color: #facc15 !important;
}

.tw-accent-yellow-400\/0 {
  accent-color: rgb(250 204 21 / 0) !important;
}

.tw-accent-yellow-400\/10 {
  accent-color: rgb(250 204 21 / 0.1) !important;
}

.tw-accent-yellow-400\/100 {
  accent-color: rgb(250 204 21 / 1) !important;
}

.tw-accent-yellow-400\/15 {
  accent-color: rgb(250 204 21 / 0.15) !important;
}

.tw-accent-yellow-400\/20 {
  accent-color: rgb(250 204 21 / 0.2) !important;
}

.tw-accent-yellow-400\/25 {
  accent-color: rgb(250 204 21 / 0.25) !important;
}

.tw-accent-yellow-400\/30 {
  accent-color: rgb(250 204 21 / 0.3) !important;
}

.tw-accent-yellow-400\/35 {
  accent-color: rgb(250 204 21 / 0.35) !important;
}

.tw-accent-yellow-400\/40 {
  accent-color: rgb(250 204 21 / 0.4) !important;
}

.tw-accent-yellow-400\/45 {
  accent-color: rgb(250 204 21 / 0.45) !important;
}

.tw-accent-yellow-400\/5 {
  accent-color: rgb(250 204 21 / 0.05) !important;
}

.tw-accent-yellow-400\/50 {
  accent-color: rgb(250 204 21 / 0.5) !important;
}

.tw-accent-yellow-400\/55 {
  accent-color: rgb(250 204 21 / 0.55) !important;
}

.tw-accent-yellow-400\/60 {
  accent-color: rgb(250 204 21 / 0.6) !important;
}

.tw-accent-yellow-400\/65 {
  accent-color: rgb(250 204 21 / 0.65) !important;
}

.tw-accent-yellow-400\/70 {
  accent-color: rgb(250 204 21 / 0.7) !important;
}

.tw-accent-yellow-400\/75 {
  accent-color: rgb(250 204 21 / 0.75) !important;
}

.tw-accent-yellow-400\/80 {
  accent-color: rgb(250 204 21 / 0.8) !important;
}

.tw-accent-yellow-400\/85 {
  accent-color: rgb(250 204 21 / 0.85) !important;
}

.tw-accent-yellow-400\/90 {
  accent-color: rgb(250 204 21 / 0.9) !important;
}

.tw-accent-yellow-400\/95 {
  accent-color: rgb(250 204 21 / 0.95) !important;
}

.tw-accent-yellow-50 {
  accent-color: #fefce8 !important;
}

.tw-accent-yellow-50\/0 {
  accent-color: rgb(254 252 232 / 0) !important;
}

.tw-accent-yellow-50\/10 {
  accent-color: rgb(254 252 232 / 0.1) !important;
}

.tw-accent-yellow-50\/100 {
  accent-color: rgb(254 252 232 / 1) !important;
}

.tw-accent-yellow-50\/15 {
  accent-color: rgb(254 252 232 / 0.15) !important;
}

.tw-accent-yellow-50\/20 {
  accent-color: rgb(254 252 232 / 0.2) !important;
}

.tw-accent-yellow-50\/25 {
  accent-color: rgb(254 252 232 / 0.25) !important;
}

.tw-accent-yellow-50\/30 {
  accent-color: rgb(254 252 232 / 0.3) !important;
}

.tw-accent-yellow-50\/35 {
  accent-color: rgb(254 252 232 / 0.35) !important;
}

.tw-accent-yellow-50\/40 {
  accent-color: rgb(254 252 232 / 0.4) !important;
}

.tw-accent-yellow-50\/45 {
  accent-color: rgb(254 252 232 / 0.45) !important;
}

.tw-accent-yellow-50\/5 {
  accent-color: rgb(254 252 232 / 0.05) !important;
}

.tw-accent-yellow-50\/50 {
  accent-color: rgb(254 252 232 / 0.5) !important;
}

.tw-accent-yellow-50\/55 {
  accent-color: rgb(254 252 232 / 0.55) !important;
}

.tw-accent-yellow-50\/60 {
  accent-color: rgb(254 252 232 / 0.6) !important;
}

.tw-accent-yellow-50\/65 {
  accent-color: rgb(254 252 232 / 0.65) !important;
}

.tw-accent-yellow-50\/70 {
  accent-color: rgb(254 252 232 / 0.7) !important;
}

.tw-accent-yellow-50\/75 {
  accent-color: rgb(254 252 232 / 0.75) !important;
}

.tw-accent-yellow-50\/80 {
  accent-color: rgb(254 252 232 / 0.8) !important;
}

.tw-accent-yellow-50\/85 {
  accent-color: rgb(254 252 232 / 0.85) !important;
}

.tw-accent-yellow-50\/90 {
  accent-color: rgb(254 252 232 / 0.9) !important;
}

.tw-accent-yellow-50\/95 {
  accent-color: rgb(254 252 232 / 0.95) !important;
}

.tw-accent-yellow-500 {
  accent-color: #eab308 !important;
}

.tw-accent-yellow-500\/0 {
  accent-color: rgb(234 179 8 / 0) !important;
}

.tw-accent-yellow-500\/10 {
  accent-color: rgb(234 179 8 / 0.1) !important;
}

.tw-accent-yellow-500\/100 {
  accent-color: rgb(234 179 8 / 1) !important;
}

.tw-accent-yellow-500\/15 {
  accent-color: rgb(234 179 8 / 0.15) !important;
}

.tw-accent-yellow-500\/20 {
  accent-color: rgb(234 179 8 / 0.2) !important;
}

.tw-accent-yellow-500\/25 {
  accent-color: rgb(234 179 8 / 0.25) !important;
}

.tw-accent-yellow-500\/30 {
  accent-color: rgb(234 179 8 / 0.3) !important;
}

.tw-accent-yellow-500\/35 {
  accent-color: rgb(234 179 8 / 0.35) !important;
}

.tw-accent-yellow-500\/40 {
  accent-color: rgb(234 179 8 / 0.4) !important;
}

.tw-accent-yellow-500\/45 {
  accent-color: rgb(234 179 8 / 0.45) !important;
}

.tw-accent-yellow-500\/5 {
  accent-color: rgb(234 179 8 / 0.05) !important;
}

.tw-accent-yellow-500\/50 {
  accent-color: rgb(234 179 8 / 0.5) !important;
}

.tw-accent-yellow-500\/55 {
  accent-color: rgb(234 179 8 / 0.55) !important;
}

.tw-accent-yellow-500\/60 {
  accent-color: rgb(234 179 8 / 0.6) !important;
}

.tw-accent-yellow-500\/65 {
  accent-color: rgb(234 179 8 / 0.65) !important;
}

.tw-accent-yellow-500\/70 {
  accent-color: rgb(234 179 8 / 0.7) !important;
}

.tw-accent-yellow-500\/75 {
  accent-color: rgb(234 179 8 / 0.75) !important;
}

.tw-accent-yellow-500\/80 {
  accent-color: rgb(234 179 8 / 0.8) !important;
}

.tw-accent-yellow-500\/85 {
  accent-color: rgb(234 179 8 / 0.85) !important;
}

.tw-accent-yellow-500\/90 {
  accent-color: rgb(234 179 8 / 0.9) !important;
}

.tw-accent-yellow-500\/95 {
  accent-color: rgb(234 179 8 / 0.95) !important;
}

.tw-accent-yellow-600 {
  accent-color: #ca8a04 !important;
}

.tw-accent-yellow-600\/0 {
  accent-color: rgb(202 138 4 / 0) !important;
}

.tw-accent-yellow-600\/10 {
  accent-color: rgb(202 138 4 / 0.1) !important;
}

.tw-accent-yellow-600\/100 {
  accent-color: rgb(202 138 4 / 1) !important;
}

.tw-accent-yellow-600\/15 {
  accent-color: rgb(202 138 4 / 0.15) !important;
}

.tw-accent-yellow-600\/20 {
  accent-color: rgb(202 138 4 / 0.2) !important;
}

.tw-accent-yellow-600\/25 {
  accent-color: rgb(202 138 4 / 0.25) !important;
}

.tw-accent-yellow-600\/30 {
  accent-color: rgb(202 138 4 / 0.3) !important;
}

.tw-accent-yellow-600\/35 {
  accent-color: rgb(202 138 4 / 0.35) !important;
}

.tw-accent-yellow-600\/40 {
  accent-color: rgb(202 138 4 / 0.4) !important;
}

.tw-accent-yellow-600\/45 {
  accent-color: rgb(202 138 4 / 0.45) !important;
}

.tw-accent-yellow-600\/5 {
  accent-color: rgb(202 138 4 / 0.05) !important;
}

.tw-accent-yellow-600\/50 {
  accent-color: rgb(202 138 4 / 0.5) !important;
}

.tw-accent-yellow-600\/55 {
  accent-color: rgb(202 138 4 / 0.55) !important;
}

.tw-accent-yellow-600\/60 {
  accent-color: rgb(202 138 4 / 0.6) !important;
}

.tw-accent-yellow-600\/65 {
  accent-color: rgb(202 138 4 / 0.65) !important;
}

.tw-accent-yellow-600\/70 {
  accent-color: rgb(202 138 4 / 0.7) !important;
}

.tw-accent-yellow-600\/75 {
  accent-color: rgb(202 138 4 / 0.75) !important;
}

.tw-accent-yellow-600\/80 {
  accent-color: rgb(202 138 4 / 0.8) !important;
}

.tw-accent-yellow-600\/85 {
  accent-color: rgb(202 138 4 / 0.85) !important;
}

.tw-accent-yellow-600\/90 {
  accent-color: rgb(202 138 4 / 0.9) !important;
}

.tw-accent-yellow-600\/95 {
  accent-color: rgb(202 138 4 / 0.95) !important;
}

.tw-accent-yellow-700 {
  accent-color: #a16207 !important;
}

.tw-accent-yellow-700\/0 {
  accent-color: rgb(161 98 7 / 0) !important;
}

.tw-accent-yellow-700\/10 {
  accent-color: rgb(161 98 7 / 0.1) !important;
}

.tw-accent-yellow-700\/100 {
  accent-color: rgb(161 98 7 / 1) !important;
}

.tw-accent-yellow-700\/15 {
  accent-color: rgb(161 98 7 / 0.15) !important;
}

.tw-accent-yellow-700\/20 {
  accent-color: rgb(161 98 7 / 0.2) !important;
}

.tw-accent-yellow-700\/25 {
  accent-color: rgb(161 98 7 / 0.25) !important;
}

.tw-accent-yellow-700\/30 {
  accent-color: rgb(161 98 7 / 0.3) !important;
}

.tw-accent-yellow-700\/35 {
  accent-color: rgb(161 98 7 / 0.35) !important;
}

.tw-accent-yellow-700\/40 {
  accent-color: rgb(161 98 7 / 0.4) !important;
}

.tw-accent-yellow-700\/45 {
  accent-color: rgb(161 98 7 / 0.45) !important;
}

.tw-accent-yellow-700\/5 {
  accent-color: rgb(161 98 7 / 0.05) !important;
}

.tw-accent-yellow-700\/50 {
  accent-color: rgb(161 98 7 / 0.5) !important;
}

.tw-accent-yellow-700\/55 {
  accent-color: rgb(161 98 7 / 0.55) !important;
}

.tw-accent-yellow-700\/60 {
  accent-color: rgb(161 98 7 / 0.6) !important;
}

.tw-accent-yellow-700\/65 {
  accent-color: rgb(161 98 7 / 0.65) !important;
}

.tw-accent-yellow-700\/70 {
  accent-color: rgb(161 98 7 / 0.7) !important;
}

.tw-accent-yellow-700\/75 {
  accent-color: rgb(161 98 7 / 0.75) !important;
}

.tw-accent-yellow-700\/80 {
  accent-color: rgb(161 98 7 / 0.8) !important;
}

.tw-accent-yellow-700\/85 {
  accent-color: rgb(161 98 7 / 0.85) !important;
}

.tw-accent-yellow-700\/90 {
  accent-color: rgb(161 98 7 / 0.9) !important;
}

.tw-accent-yellow-700\/95 {
  accent-color: rgb(161 98 7 / 0.95) !important;
}

.tw-accent-yellow-800 {
  accent-color: #854d0e !important;
}

.tw-accent-yellow-800\/0 {
  accent-color: rgb(133 77 14 / 0) !important;
}

.tw-accent-yellow-800\/10 {
  accent-color: rgb(133 77 14 / 0.1) !important;
}

.tw-accent-yellow-800\/100 {
  accent-color: rgb(133 77 14 / 1) !important;
}

.tw-accent-yellow-800\/15 {
  accent-color: rgb(133 77 14 / 0.15) !important;
}

.tw-accent-yellow-800\/20 {
  accent-color: rgb(133 77 14 / 0.2) !important;
}

.tw-accent-yellow-800\/25 {
  accent-color: rgb(133 77 14 / 0.25) !important;
}

.tw-accent-yellow-800\/30 {
  accent-color: rgb(133 77 14 / 0.3) !important;
}

.tw-accent-yellow-800\/35 {
  accent-color: rgb(133 77 14 / 0.35) !important;
}

.tw-accent-yellow-800\/40 {
  accent-color: rgb(133 77 14 / 0.4) !important;
}

.tw-accent-yellow-800\/45 {
  accent-color: rgb(133 77 14 / 0.45) !important;
}

.tw-accent-yellow-800\/5 {
  accent-color: rgb(133 77 14 / 0.05) !important;
}

.tw-accent-yellow-800\/50 {
  accent-color: rgb(133 77 14 / 0.5) !important;
}

.tw-accent-yellow-800\/55 {
  accent-color: rgb(133 77 14 / 0.55) !important;
}

.tw-accent-yellow-800\/60 {
  accent-color: rgb(133 77 14 / 0.6) !important;
}

.tw-accent-yellow-800\/65 {
  accent-color: rgb(133 77 14 / 0.65) !important;
}

.tw-accent-yellow-800\/70 {
  accent-color: rgb(133 77 14 / 0.7) !important;
}

.tw-accent-yellow-800\/75 {
  accent-color: rgb(133 77 14 / 0.75) !important;
}

.tw-accent-yellow-800\/80 {
  accent-color: rgb(133 77 14 / 0.8) !important;
}

.tw-accent-yellow-800\/85 {
  accent-color: rgb(133 77 14 / 0.85) !important;
}

.tw-accent-yellow-800\/90 {
  accent-color: rgb(133 77 14 / 0.9) !important;
}

.tw-accent-yellow-800\/95 {
  accent-color: rgb(133 77 14 / 0.95) !important;
}

.tw-accent-yellow-900 {
  accent-color: #713f12 !important;
}

.tw-accent-yellow-900\/0 {
  accent-color: rgb(113 63 18 / 0) !important;
}

.tw-accent-yellow-900\/10 {
  accent-color: rgb(113 63 18 / 0.1) !important;
}

.tw-accent-yellow-900\/100 {
  accent-color: rgb(113 63 18 / 1) !important;
}

.tw-accent-yellow-900\/15 {
  accent-color: rgb(113 63 18 / 0.15) !important;
}

.tw-accent-yellow-900\/20 {
  accent-color: rgb(113 63 18 / 0.2) !important;
}

.tw-accent-yellow-900\/25 {
  accent-color: rgb(113 63 18 / 0.25) !important;
}

.tw-accent-yellow-900\/30 {
  accent-color: rgb(113 63 18 / 0.3) !important;
}

.tw-accent-yellow-900\/35 {
  accent-color: rgb(113 63 18 / 0.35) !important;
}

.tw-accent-yellow-900\/40 {
  accent-color: rgb(113 63 18 / 0.4) !important;
}

.tw-accent-yellow-900\/45 {
  accent-color: rgb(113 63 18 / 0.45) !important;
}

.tw-accent-yellow-900\/5 {
  accent-color: rgb(113 63 18 / 0.05) !important;
}

.tw-accent-yellow-900\/50 {
  accent-color: rgb(113 63 18 / 0.5) !important;
}

.tw-accent-yellow-900\/55 {
  accent-color: rgb(113 63 18 / 0.55) !important;
}

.tw-accent-yellow-900\/60 {
  accent-color: rgb(113 63 18 / 0.6) !important;
}

.tw-accent-yellow-900\/65 {
  accent-color: rgb(113 63 18 / 0.65) !important;
}

.tw-accent-yellow-900\/70 {
  accent-color: rgb(113 63 18 / 0.7) !important;
}

.tw-accent-yellow-900\/75 {
  accent-color: rgb(113 63 18 / 0.75) !important;
}

.tw-accent-yellow-900\/80 {
  accent-color: rgb(113 63 18 / 0.8) !important;
}

.tw-accent-yellow-900\/85 {
  accent-color: rgb(113 63 18 / 0.85) !important;
}

.tw-accent-yellow-900\/90 {
  accent-color: rgb(113 63 18 / 0.9) !important;
}

.tw-accent-yellow-900\/95 {
  accent-color: rgb(113 63 18 / 0.95) !important;
}

.tw-accent-yellow-950 {
  accent-color: #422006 !important;
}

.tw-accent-yellow-950\/0 {
  accent-color: rgb(66 32 6 / 0) !important;
}

.tw-accent-yellow-950\/10 {
  accent-color: rgb(66 32 6 / 0.1) !important;
}

.tw-accent-yellow-950\/100 {
  accent-color: rgb(66 32 6 / 1) !important;
}

.tw-accent-yellow-950\/15 {
  accent-color: rgb(66 32 6 / 0.15) !important;
}

.tw-accent-yellow-950\/20 {
  accent-color: rgb(66 32 6 / 0.2) !important;
}

.tw-accent-yellow-950\/25 {
  accent-color: rgb(66 32 6 / 0.25) !important;
}

.tw-accent-yellow-950\/30 {
  accent-color: rgb(66 32 6 / 0.3) !important;
}

.tw-accent-yellow-950\/35 {
  accent-color: rgb(66 32 6 / 0.35) !important;
}

.tw-accent-yellow-950\/40 {
  accent-color: rgb(66 32 6 / 0.4) !important;
}

.tw-accent-yellow-950\/45 {
  accent-color: rgb(66 32 6 / 0.45) !important;
}

.tw-accent-yellow-950\/5 {
  accent-color: rgb(66 32 6 / 0.05) !important;
}

.tw-accent-yellow-950\/50 {
  accent-color: rgb(66 32 6 / 0.5) !important;
}

.tw-accent-yellow-950\/55 {
  accent-color: rgb(66 32 6 / 0.55) !important;
}

.tw-accent-yellow-950\/60 {
  accent-color: rgb(66 32 6 / 0.6) !important;
}

.tw-accent-yellow-950\/65 {
  accent-color: rgb(66 32 6 / 0.65) !important;
}

.tw-accent-yellow-950\/70 {
  accent-color: rgb(66 32 6 / 0.7) !important;
}

.tw-accent-yellow-950\/75 {
  accent-color: rgb(66 32 6 / 0.75) !important;
}

.tw-accent-yellow-950\/80 {
  accent-color: rgb(66 32 6 / 0.8) !important;
}

.tw-accent-yellow-950\/85 {
  accent-color: rgb(66 32 6 / 0.85) !important;
}

.tw-accent-yellow-950\/90 {
  accent-color: rgb(66 32 6 / 0.9) !important;
}

.tw-accent-yellow-950\/95 {
  accent-color: rgb(66 32 6 / 0.95) !important;
}

.tw-accent-zinc-100 {
  accent-color: #f4f4f5 !important;
}

.tw-accent-zinc-100\/0 {
  accent-color: rgb(244 244 245 / 0) !important;
}

.tw-accent-zinc-100\/10 {
  accent-color: rgb(244 244 245 / 0.1) !important;
}

.tw-accent-zinc-100\/100 {
  accent-color: rgb(244 244 245 / 1) !important;
}

.tw-accent-zinc-100\/15 {
  accent-color: rgb(244 244 245 / 0.15) !important;
}

.tw-accent-zinc-100\/20 {
  accent-color: rgb(244 244 245 / 0.2) !important;
}

.tw-accent-zinc-100\/25 {
  accent-color: rgb(244 244 245 / 0.25) !important;
}

.tw-accent-zinc-100\/30 {
  accent-color: rgb(244 244 245 / 0.3) !important;
}

.tw-accent-zinc-100\/35 {
  accent-color: rgb(244 244 245 / 0.35) !important;
}

.tw-accent-zinc-100\/40 {
  accent-color: rgb(244 244 245 / 0.4) !important;
}

.tw-accent-zinc-100\/45 {
  accent-color: rgb(244 244 245 / 0.45) !important;
}

.tw-accent-zinc-100\/5 {
  accent-color: rgb(244 244 245 / 0.05) !important;
}

.tw-accent-zinc-100\/50 {
  accent-color: rgb(244 244 245 / 0.5) !important;
}

.tw-accent-zinc-100\/55 {
  accent-color: rgb(244 244 245 / 0.55) !important;
}

.tw-accent-zinc-100\/60 {
  accent-color: rgb(244 244 245 / 0.6) !important;
}

.tw-accent-zinc-100\/65 {
  accent-color: rgb(244 244 245 / 0.65) !important;
}

.tw-accent-zinc-100\/70 {
  accent-color: rgb(244 244 245 / 0.7) !important;
}

.tw-accent-zinc-100\/75 {
  accent-color: rgb(244 244 245 / 0.75) !important;
}

.tw-accent-zinc-100\/80 {
  accent-color: rgb(244 244 245 / 0.8) !important;
}

.tw-accent-zinc-100\/85 {
  accent-color: rgb(244 244 245 / 0.85) !important;
}

.tw-accent-zinc-100\/90 {
  accent-color: rgb(244 244 245 / 0.9) !important;
}

.tw-accent-zinc-100\/95 {
  accent-color: rgb(244 244 245 / 0.95) !important;
}

.tw-accent-zinc-200 {
  accent-color: #e4e4e7 !important;
}

.tw-accent-zinc-200\/0 {
  accent-color: rgb(228 228 231 / 0) !important;
}

.tw-accent-zinc-200\/10 {
  accent-color: rgb(228 228 231 / 0.1) !important;
}

.tw-accent-zinc-200\/100 {
  accent-color: rgb(228 228 231 / 1) !important;
}

.tw-accent-zinc-200\/15 {
  accent-color: rgb(228 228 231 / 0.15) !important;
}

.tw-accent-zinc-200\/20 {
  accent-color: rgb(228 228 231 / 0.2) !important;
}

.tw-accent-zinc-200\/25 {
  accent-color: rgb(228 228 231 / 0.25) !important;
}

.tw-accent-zinc-200\/30 {
  accent-color: rgb(228 228 231 / 0.3) !important;
}

.tw-accent-zinc-200\/35 {
  accent-color: rgb(228 228 231 / 0.35) !important;
}

.tw-accent-zinc-200\/40 {
  accent-color: rgb(228 228 231 / 0.4) !important;
}

.tw-accent-zinc-200\/45 {
  accent-color: rgb(228 228 231 / 0.45) !important;
}

.tw-accent-zinc-200\/5 {
  accent-color: rgb(228 228 231 / 0.05) !important;
}

.tw-accent-zinc-200\/50 {
  accent-color: rgb(228 228 231 / 0.5) !important;
}

.tw-accent-zinc-200\/55 {
  accent-color: rgb(228 228 231 / 0.55) !important;
}

.tw-accent-zinc-200\/60 {
  accent-color: rgb(228 228 231 / 0.6) !important;
}

.tw-accent-zinc-200\/65 {
  accent-color: rgb(228 228 231 / 0.65) !important;
}

.tw-accent-zinc-200\/70 {
  accent-color: rgb(228 228 231 / 0.7) !important;
}

.tw-accent-zinc-200\/75 {
  accent-color: rgb(228 228 231 / 0.75) !important;
}

.tw-accent-zinc-200\/80 {
  accent-color: rgb(228 228 231 / 0.8) !important;
}

.tw-accent-zinc-200\/85 {
  accent-color: rgb(228 228 231 / 0.85) !important;
}

.tw-accent-zinc-200\/90 {
  accent-color: rgb(228 228 231 / 0.9) !important;
}

.tw-accent-zinc-200\/95 {
  accent-color: rgb(228 228 231 / 0.95) !important;
}

.tw-accent-zinc-300 {
  accent-color: #d4d4d8 !important;
}

.tw-accent-zinc-300\/0 {
  accent-color: rgb(212 212 216 / 0) !important;
}

.tw-accent-zinc-300\/10 {
  accent-color: rgb(212 212 216 / 0.1) !important;
}

.tw-accent-zinc-300\/100 {
  accent-color: rgb(212 212 216 / 1) !important;
}

.tw-accent-zinc-300\/15 {
  accent-color: rgb(212 212 216 / 0.15) !important;
}

.tw-accent-zinc-300\/20 {
  accent-color: rgb(212 212 216 / 0.2) !important;
}

.tw-accent-zinc-300\/25 {
  accent-color: rgb(212 212 216 / 0.25) !important;
}

.tw-accent-zinc-300\/30 {
  accent-color: rgb(212 212 216 / 0.3) !important;
}

.tw-accent-zinc-300\/35 {
  accent-color: rgb(212 212 216 / 0.35) !important;
}

.tw-accent-zinc-300\/40 {
  accent-color: rgb(212 212 216 / 0.4) !important;
}

.tw-accent-zinc-300\/45 {
  accent-color: rgb(212 212 216 / 0.45) !important;
}

.tw-accent-zinc-300\/5 {
  accent-color: rgb(212 212 216 / 0.05) !important;
}

.tw-accent-zinc-300\/50 {
  accent-color: rgb(212 212 216 / 0.5) !important;
}

.tw-accent-zinc-300\/55 {
  accent-color: rgb(212 212 216 / 0.55) !important;
}

.tw-accent-zinc-300\/60 {
  accent-color: rgb(212 212 216 / 0.6) !important;
}

.tw-accent-zinc-300\/65 {
  accent-color: rgb(212 212 216 / 0.65) !important;
}

.tw-accent-zinc-300\/70 {
  accent-color: rgb(212 212 216 / 0.7) !important;
}

.tw-accent-zinc-300\/75 {
  accent-color: rgb(212 212 216 / 0.75) !important;
}

.tw-accent-zinc-300\/80 {
  accent-color: rgb(212 212 216 / 0.8) !important;
}

.tw-accent-zinc-300\/85 {
  accent-color: rgb(212 212 216 / 0.85) !important;
}

.tw-accent-zinc-300\/90 {
  accent-color: rgb(212 212 216 / 0.9) !important;
}

.tw-accent-zinc-300\/95 {
  accent-color: rgb(212 212 216 / 0.95) !important;
}

.tw-accent-zinc-400 {
  accent-color: #a1a1aa !important;
}

.tw-accent-zinc-400\/0 {
  accent-color: rgb(161 161 170 / 0) !important;
}

.tw-accent-zinc-400\/10 {
  accent-color: rgb(161 161 170 / 0.1) !important;
}

.tw-accent-zinc-400\/100 {
  accent-color: rgb(161 161 170 / 1) !important;
}

.tw-accent-zinc-400\/15 {
  accent-color: rgb(161 161 170 / 0.15) !important;
}

.tw-accent-zinc-400\/20 {
  accent-color: rgb(161 161 170 / 0.2) !important;
}

.tw-accent-zinc-400\/25 {
  accent-color: rgb(161 161 170 / 0.25) !important;
}

.tw-accent-zinc-400\/30 {
  accent-color: rgb(161 161 170 / 0.3) !important;
}

.tw-accent-zinc-400\/35 {
  accent-color: rgb(161 161 170 / 0.35) !important;
}

.tw-accent-zinc-400\/40 {
  accent-color: rgb(161 161 170 / 0.4) !important;
}

.tw-accent-zinc-400\/45 {
  accent-color: rgb(161 161 170 / 0.45) !important;
}

.tw-accent-zinc-400\/5 {
  accent-color: rgb(161 161 170 / 0.05) !important;
}

.tw-accent-zinc-400\/50 {
  accent-color: rgb(161 161 170 / 0.5) !important;
}

.tw-accent-zinc-400\/55 {
  accent-color: rgb(161 161 170 / 0.55) !important;
}

.tw-accent-zinc-400\/60 {
  accent-color: rgb(161 161 170 / 0.6) !important;
}

.tw-accent-zinc-400\/65 {
  accent-color: rgb(161 161 170 / 0.65) !important;
}

.tw-accent-zinc-400\/70 {
  accent-color: rgb(161 161 170 / 0.7) !important;
}

.tw-accent-zinc-400\/75 {
  accent-color: rgb(161 161 170 / 0.75) !important;
}

.tw-accent-zinc-400\/80 {
  accent-color: rgb(161 161 170 / 0.8) !important;
}

.tw-accent-zinc-400\/85 {
  accent-color: rgb(161 161 170 / 0.85) !important;
}

.tw-accent-zinc-400\/90 {
  accent-color: rgb(161 161 170 / 0.9) !important;
}

.tw-accent-zinc-400\/95 {
  accent-color: rgb(161 161 170 / 0.95) !important;
}

.tw-accent-zinc-50 {
  accent-color: #fafafa !important;
}

.tw-accent-zinc-50\/0 {
  accent-color: rgb(250 250 250 / 0) !important;
}

.tw-accent-zinc-50\/10 {
  accent-color: rgb(250 250 250 / 0.1) !important;
}

.tw-accent-zinc-50\/100 {
  accent-color: rgb(250 250 250 / 1) !important;
}

.tw-accent-zinc-50\/15 {
  accent-color: rgb(250 250 250 / 0.15) !important;
}

.tw-accent-zinc-50\/20 {
  accent-color: rgb(250 250 250 / 0.2) !important;
}

.tw-accent-zinc-50\/25 {
  accent-color: rgb(250 250 250 / 0.25) !important;
}

.tw-accent-zinc-50\/30 {
  accent-color: rgb(250 250 250 / 0.3) !important;
}

.tw-accent-zinc-50\/35 {
  accent-color: rgb(250 250 250 / 0.35) !important;
}

.tw-accent-zinc-50\/40 {
  accent-color: rgb(250 250 250 / 0.4) !important;
}

.tw-accent-zinc-50\/45 {
  accent-color: rgb(250 250 250 / 0.45) !important;
}

.tw-accent-zinc-50\/5 {
  accent-color: rgb(250 250 250 / 0.05) !important;
}

.tw-accent-zinc-50\/50 {
  accent-color: rgb(250 250 250 / 0.5) !important;
}

.tw-accent-zinc-50\/55 {
  accent-color: rgb(250 250 250 / 0.55) !important;
}

.tw-accent-zinc-50\/60 {
  accent-color: rgb(250 250 250 / 0.6) !important;
}

.tw-accent-zinc-50\/65 {
  accent-color: rgb(250 250 250 / 0.65) !important;
}

.tw-accent-zinc-50\/70 {
  accent-color: rgb(250 250 250 / 0.7) !important;
}

.tw-accent-zinc-50\/75 {
  accent-color: rgb(250 250 250 / 0.75) !important;
}

.tw-accent-zinc-50\/80 {
  accent-color: rgb(250 250 250 / 0.8) !important;
}

.tw-accent-zinc-50\/85 {
  accent-color: rgb(250 250 250 / 0.85) !important;
}

.tw-accent-zinc-50\/90 {
  accent-color: rgb(250 250 250 / 0.9) !important;
}

.tw-accent-zinc-50\/95 {
  accent-color: rgb(250 250 250 / 0.95) !important;
}

.tw-accent-zinc-500 {
  accent-color: #71717a !important;
}

.tw-accent-zinc-500\/0 {
  accent-color: rgb(113 113 122 / 0) !important;
}

.tw-accent-zinc-500\/10 {
  accent-color: rgb(113 113 122 / 0.1) !important;
}

.tw-accent-zinc-500\/100 {
  accent-color: rgb(113 113 122 / 1) !important;
}

.tw-accent-zinc-500\/15 {
  accent-color: rgb(113 113 122 / 0.15) !important;
}

.tw-accent-zinc-500\/20 {
  accent-color: rgb(113 113 122 / 0.2) !important;
}

.tw-accent-zinc-500\/25 {
  accent-color: rgb(113 113 122 / 0.25) !important;
}

.tw-accent-zinc-500\/30 {
  accent-color: rgb(113 113 122 / 0.3) !important;
}

.tw-accent-zinc-500\/35 {
  accent-color: rgb(113 113 122 / 0.35) !important;
}

.tw-accent-zinc-500\/40 {
  accent-color: rgb(113 113 122 / 0.4) !important;
}

.tw-accent-zinc-500\/45 {
  accent-color: rgb(113 113 122 / 0.45) !important;
}

.tw-accent-zinc-500\/5 {
  accent-color: rgb(113 113 122 / 0.05) !important;
}

.tw-accent-zinc-500\/50 {
  accent-color: rgb(113 113 122 / 0.5) !important;
}

.tw-accent-zinc-500\/55 {
  accent-color: rgb(113 113 122 / 0.55) !important;
}

.tw-accent-zinc-500\/60 {
  accent-color: rgb(113 113 122 / 0.6) !important;
}

.tw-accent-zinc-500\/65 {
  accent-color: rgb(113 113 122 / 0.65) !important;
}

.tw-accent-zinc-500\/70 {
  accent-color: rgb(113 113 122 / 0.7) !important;
}

.tw-accent-zinc-500\/75 {
  accent-color: rgb(113 113 122 / 0.75) !important;
}

.tw-accent-zinc-500\/80 {
  accent-color: rgb(113 113 122 / 0.8) !important;
}

.tw-accent-zinc-500\/85 {
  accent-color: rgb(113 113 122 / 0.85) !important;
}

.tw-accent-zinc-500\/90 {
  accent-color: rgb(113 113 122 / 0.9) !important;
}

.tw-accent-zinc-500\/95 {
  accent-color: rgb(113 113 122 / 0.95) !important;
}

.tw-accent-zinc-600 {
  accent-color: #52525b !important;
}

.tw-accent-zinc-600\/0 {
  accent-color: rgb(82 82 91 / 0) !important;
}

.tw-accent-zinc-600\/10 {
  accent-color: rgb(82 82 91 / 0.1) !important;
}

.tw-accent-zinc-600\/100 {
  accent-color: rgb(82 82 91 / 1) !important;
}

.tw-accent-zinc-600\/15 {
  accent-color: rgb(82 82 91 / 0.15) !important;
}

.tw-accent-zinc-600\/20 {
  accent-color: rgb(82 82 91 / 0.2) !important;
}

.tw-accent-zinc-600\/25 {
  accent-color: rgb(82 82 91 / 0.25) !important;
}

.tw-accent-zinc-600\/30 {
  accent-color: rgb(82 82 91 / 0.3) !important;
}

.tw-accent-zinc-600\/35 {
  accent-color: rgb(82 82 91 / 0.35) !important;
}

.tw-accent-zinc-600\/40 {
  accent-color: rgb(82 82 91 / 0.4) !important;
}

.tw-accent-zinc-600\/45 {
  accent-color: rgb(82 82 91 / 0.45) !important;
}

.tw-accent-zinc-600\/5 {
  accent-color: rgb(82 82 91 / 0.05) !important;
}

.tw-accent-zinc-600\/50 {
  accent-color: rgb(82 82 91 / 0.5) !important;
}

.tw-accent-zinc-600\/55 {
  accent-color: rgb(82 82 91 / 0.55) !important;
}

.tw-accent-zinc-600\/60 {
  accent-color: rgb(82 82 91 / 0.6) !important;
}

.tw-accent-zinc-600\/65 {
  accent-color: rgb(82 82 91 / 0.65) !important;
}

.tw-accent-zinc-600\/70 {
  accent-color: rgb(82 82 91 / 0.7) !important;
}

.tw-accent-zinc-600\/75 {
  accent-color: rgb(82 82 91 / 0.75) !important;
}

.tw-accent-zinc-600\/80 {
  accent-color: rgb(82 82 91 / 0.8) !important;
}

.tw-accent-zinc-600\/85 {
  accent-color: rgb(82 82 91 / 0.85) !important;
}

.tw-accent-zinc-600\/90 {
  accent-color: rgb(82 82 91 / 0.9) !important;
}

.tw-accent-zinc-600\/95 {
  accent-color: rgb(82 82 91 / 0.95) !important;
}

.tw-accent-zinc-700 {
  accent-color: #3f3f46 !important;
}

.tw-accent-zinc-700\/0 {
  accent-color: rgb(63 63 70 / 0) !important;
}

.tw-accent-zinc-700\/10 {
  accent-color: rgb(63 63 70 / 0.1) !important;
}

.tw-accent-zinc-700\/100 {
  accent-color: rgb(63 63 70 / 1) !important;
}

.tw-accent-zinc-700\/15 {
  accent-color: rgb(63 63 70 / 0.15) !important;
}

.tw-accent-zinc-700\/20 {
  accent-color: rgb(63 63 70 / 0.2) !important;
}

.tw-accent-zinc-700\/25 {
  accent-color: rgb(63 63 70 / 0.25) !important;
}

.tw-accent-zinc-700\/30 {
  accent-color: rgb(63 63 70 / 0.3) !important;
}

.tw-accent-zinc-700\/35 {
  accent-color: rgb(63 63 70 / 0.35) !important;
}

.tw-accent-zinc-700\/40 {
  accent-color: rgb(63 63 70 / 0.4) !important;
}

.tw-accent-zinc-700\/45 {
  accent-color: rgb(63 63 70 / 0.45) !important;
}

.tw-accent-zinc-700\/5 {
  accent-color: rgb(63 63 70 / 0.05) !important;
}

.tw-accent-zinc-700\/50 {
  accent-color: rgb(63 63 70 / 0.5) !important;
}

.tw-accent-zinc-700\/55 {
  accent-color: rgb(63 63 70 / 0.55) !important;
}

.tw-accent-zinc-700\/60 {
  accent-color: rgb(63 63 70 / 0.6) !important;
}

.tw-accent-zinc-700\/65 {
  accent-color: rgb(63 63 70 / 0.65) !important;
}

.tw-accent-zinc-700\/70 {
  accent-color: rgb(63 63 70 / 0.7) !important;
}

.tw-accent-zinc-700\/75 {
  accent-color: rgb(63 63 70 / 0.75) !important;
}

.tw-accent-zinc-700\/80 {
  accent-color: rgb(63 63 70 / 0.8) !important;
}

.tw-accent-zinc-700\/85 {
  accent-color: rgb(63 63 70 / 0.85) !important;
}

.tw-accent-zinc-700\/90 {
  accent-color: rgb(63 63 70 / 0.9) !important;
}

.tw-accent-zinc-700\/95 {
  accent-color: rgb(63 63 70 / 0.95) !important;
}

.tw-accent-zinc-800 {
  accent-color: #27272a !important;
}

.tw-accent-zinc-800\/0 {
  accent-color: rgb(39 39 42 / 0) !important;
}

.tw-accent-zinc-800\/10 {
  accent-color: rgb(39 39 42 / 0.1) !important;
}

.tw-accent-zinc-800\/100 {
  accent-color: rgb(39 39 42 / 1) !important;
}

.tw-accent-zinc-800\/15 {
  accent-color: rgb(39 39 42 / 0.15) !important;
}

.tw-accent-zinc-800\/20 {
  accent-color: rgb(39 39 42 / 0.2) !important;
}

.tw-accent-zinc-800\/25 {
  accent-color: rgb(39 39 42 / 0.25) !important;
}

.tw-accent-zinc-800\/30 {
  accent-color: rgb(39 39 42 / 0.3) !important;
}

.tw-accent-zinc-800\/35 {
  accent-color: rgb(39 39 42 / 0.35) !important;
}

.tw-accent-zinc-800\/40 {
  accent-color: rgb(39 39 42 / 0.4) !important;
}

.tw-accent-zinc-800\/45 {
  accent-color: rgb(39 39 42 / 0.45) !important;
}

.tw-accent-zinc-800\/5 {
  accent-color: rgb(39 39 42 / 0.05) !important;
}

.tw-accent-zinc-800\/50 {
  accent-color: rgb(39 39 42 / 0.5) !important;
}

.tw-accent-zinc-800\/55 {
  accent-color: rgb(39 39 42 / 0.55) !important;
}

.tw-accent-zinc-800\/60 {
  accent-color: rgb(39 39 42 / 0.6) !important;
}

.tw-accent-zinc-800\/65 {
  accent-color: rgb(39 39 42 / 0.65) !important;
}

.tw-accent-zinc-800\/70 {
  accent-color: rgb(39 39 42 / 0.7) !important;
}

.tw-accent-zinc-800\/75 {
  accent-color: rgb(39 39 42 / 0.75) !important;
}

.tw-accent-zinc-800\/80 {
  accent-color: rgb(39 39 42 / 0.8) !important;
}

.tw-accent-zinc-800\/85 {
  accent-color: rgb(39 39 42 / 0.85) !important;
}

.tw-accent-zinc-800\/90 {
  accent-color: rgb(39 39 42 / 0.9) !important;
}

.tw-accent-zinc-800\/95 {
  accent-color: rgb(39 39 42 / 0.95) !important;
}

.tw-accent-zinc-900 {
  accent-color: #18181b !important;
}

.tw-accent-zinc-900\/0 {
  accent-color: rgb(24 24 27 / 0) !important;
}

.tw-accent-zinc-900\/10 {
  accent-color: rgb(24 24 27 / 0.1) !important;
}

.tw-accent-zinc-900\/100 {
  accent-color: rgb(24 24 27 / 1) !important;
}

.tw-accent-zinc-900\/15 {
  accent-color: rgb(24 24 27 / 0.15) !important;
}

.tw-accent-zinc-900\/20 {
  accent-color: rgb(24 24 27 / 0.2) !important;
}

.tw-accent-zinc-900\/25 {
  accent-color: rgb(24 24 27 / 0.25) !important;
}

.tw-accent-zinc-900\/30 {
  accent-color: rgb(24 24 27 / 0.3) !important;
}

.tw-accent-zinc-900\/35 {
  accent-color: rgb(24 24 27 / 0.35) !important;
}

.tw-accent-zinc-900\/40 {
  accent-color: rgb(24 24 27 / 0.4) !important;
}

.tw-accent-zinc-900\/45 {
  accent-color: rgb(24 24 27 / 0.45) !important;
}

.tw-accent-zinc-900\/5 {
  accent-color: rgb(24 24 27 / 0.05) !important;
}

.tw-accent-zinc-900\/50 {
  accent-color: rgb(24 24 27 / 0.5) !important;
}

.tw-accent-zinc-900\/55 {
  accent-color: rgb(24 24 27 / 0.55) !important;
}

.tw-accent-zinc-900\/60 {
  accent-color: rgb(24 24 27 / 0.6) !important;
}

.tw-accent-zinc-900\/65 {
  accent-color: rgb(24 24 27 / 0.65) !important;
}

.tw-accent-zinc-900\/70 {
  accent-color: rgb(24 24 27 / 0.7) !important;
}

.tw-accent-zinc-900\/75 {
  accent-color: rgb(24 24 27 / 0.75) !important;
}

.tw-accent-zinc-900\/80 {
  accent-color: rgb(24 24 27 / 0.8) !important;
}

.tw-accent-zinc-900\/85 {
  accent-color: rgb(24 24 27 / 0.85) !important;
}

.tw-accent-zinc-900\/90 {
  accent-color: rgb(24 24 27 / 0.9) !important;
}

.tw-accent-zinc-900\/95 {
  accent-color: rgb(24 24 27 / 0.95) !important;
}

.tw-accent-zinc-950 {
  accent-color: #09090b !important;
}

.tw-accent-zinc-950\/0 {
  accent-color: rgb(9 9 11 / 0) !important;
}

.tw-accent-zinc-950\/10 {
  accent-color: rgb(9 9 11 / 0.1) !important;
}

.tw-accent-zinc-950\/100 {
  accent-color: rgb(9 9 11 / 1) !important;
}

.tw-accent-zinc-950\/15 {
  accent-color: rgb(9 9 11 / 0.15) !important;
}

.tw-accent-zinc-950\/20 {
  accent-color: rgb(9 9 11 / 0.2) !important;
}

.tw-accent-zinc-950\/25 {
  accent-color: rgb(9 9 11 / 0.25) !important;
}

.tw-accent-zinc-950\/30 {
  accent-color: rgb(9 9 11 / 0.3) !important;
}

.tw-accent-zinc-950\/35 {
  accent-color: rgb(9 9 11 / 0.35) !important;
}

.tw-accent-zinc-950\/40 {
  accent-color: rgb(9 9 11 / 0.4) !important;
}

.tw-accent-zinc-950\/45 {
  accent-color: rgb(9 9 11 / 0.45) !important;
}

.tw-accent-zinc-950\/5 {
  accent-color: rgb(9 9 11 / 0.05) !important;
}

.tw-accent-zinc-950\/50 {
  accent-color: rgb(9 9 11 / 0.5) !important;
}

.tw-accent-zinc-950\/55 {
  accent-color: rgb(9 9 11 / 0.55) !important;
}

.tw-accent-zinc-950\/60 {
  accent-color: rgb(9 9 11 / 0.6) !important;
}

.tw-accent-zinc-950\/65 {
  accent-color: rgb(9 9 11 / 0.65) !important;
}

.tw-accent-zinc-950\/70 {
  accent-color: rgb(9 9 11 / 0.7) !important;
}

.tw-accent-zinc-950\/75 {
  accent-color: rgb(9 9 11 / 0.75) !important;
}

.tw-accent-zinc-950\/80 {
  accent-color: rgb(9 9 11 / 0.8) !important;
}

.tw-accent-zinc-950\/85 {
  accent-color: rgb(9 9 11 / 0.85) !important;
}

.tw-accent-zinc-950\/90 {
  accent-color: rgb(9 9 11 / 0.9) !important;
}

.tw-accent-zinc-950\/95 {
  accent-color: rgb(9 9 11 / 0.95) !important;
}

.tw-opacity-0 {
  opacity: 0 !important;
}

.tw-opacity-10 {
  opacity: 0.1 !important;
}

.tw-opacity-100 {
  opacity: 1 !important;
}

.tw-opacity-15 {
  opacity: 0.15 !important;
}

.tw-opacity-20 {
  opacity: 0.2 !important;
}

.tw-opacity-25 {
  opacity: 0.25 !important;
}

.tw-opacity-30 {
  opacity: 0.3 !important;
}

.tw-opacity-35 {
  opacity: 0.35 !important;
}

.tw-opacity-40 {
  opacity: 0.4 !important;
}

.tw-opacity-45 {
  opacity: 0.45 !important;
}

.tw-opacity-5 {
  opacity: 0.05 !important;
}

.tw-opacity-50 {
  opacity: 0.5 !important;
}

.tw-opacity-55 {
  opacity: 0.55 !important;
}

.tw-opacity-60 {
  opacity: 0.6 !important;
}

.tw-opacity-65 {
  opacity: 0.65 !important;
}

.tw-opacity-70 {
  opacity: 0.7 !important;
}

.tw-opacity-75 {
  opacity: 0.75 !important;
}

.tw-opacity-80 {
  opacity: 0.8 !important;
}

.tw-opacity-85 {
  opacity: 0.85 !important;
}

.tw-opacity-90 {
  opacity: 0.9 !important;
}

.tw-opacity-95 {
  opacity: 0.95 !important;
}

.tw-bg-blend-normal {
  background-blend-mode: normal !important;
}

.tw-bg-blend-multiply {
  background-blend-mode: multiply !important;
}

.tw-bg-blend-screen {
  background-blend-mode: screen !important;
}

.tw-bg-blend-overlay {
  background-blend-mode: overlay !important;
}

.tw-bg-blend-darken {
  background-blend-mode: darken !important;
}

.tw-bg-blend-lighten {
  background-blend-mode: lighten !important;
}

.tw-bg-blend-color-dodge {
  background-blend-mode: color-dodge !important;
}

.tw-bg-blend-color-burn {
  background-blend-mode: color-burn !important;
}

.tw-bg-blend-hard-light {
  background-blend-mode: hard-light !important;
}

.tw-bg-blend-soft-light {
  background-blend-mode: soft-light !important;
}

.tw-bg-blend-difference {
  background-blend-mode: difference !important;
}

.tw-bg-blend-exclusion {
  background-blend-mode: exclusion !important;
}

.tw-bg-blend-hue {
  background-blend-mode: hue !important;
}

.tw-bg-blend-saturation {
  background-blend-mode: saturation !important;
}

.tw-bg-blend-color {
  background-blend-mode: color !important;
}

.tw-bg-blend-luminosity {
  background-blend-mode: luminosity !important;
}

.tw-mix-blend-normal {
  mix-blend-mode: normal !important;
}

.tw-mix-blend-multiply {
  mix-blend-mode: multiply !important;
}

.tw-mix-blend-screen {
  mix-blend-mode: screen !important;
}

.tw-mix-blend-overlay {
  mix-blend-mode: overlay !important;
}

.tw-mix-blend-darken {
  mix-blend-mode: darken !important;
}

.tw-mix-blend-lighten {
  mix-blend-mode: lighten !important;
}

.tw-mix-blend-color-dodge {
  mix-blend-mode: color-dodge !important;
}

.tw-mix-blend-color-burn {
  mix-blend-mode: color-burn !important;
}

.tw-mix-blend-hard-light {
  mix-blend-mode: hard-light !important;
}

.tw-mix-blend-soft-light {
  mix-blend-mode: soft-light !important;
}

.tw-mix-blend-difference {
  mix-blend-mode: difference !important;
}

.tw-mix-blend-exclusion {
  mix-blend-mode: exclusion !important;
}

.tw-mix-blend-hue {
  mix-blend-mode: hue !important;
}

.tw-mix-blend-saturation {
  mix-blend-mode: saturation !important;
}

.tw-mix-blend-color {
  mix-blend-mode: color !important;
}

.tw-mix-blend-luminosity {
  mix-blend-mode: luminosity !important;
}

.tw-mix-blend-plus-lighter {
  mix-blend-mode: plus-lighter !important;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-none {
  --tw-shadow: 0 0 #0000 !important;
  --tw-shadow-colored: 0 0 #0000 !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-sm {
  --tw-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05) !important;
  --tw-shadow-colored: 0 1px 2px 0 var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1) !important;
  --tw-shadow-colored: 0 20px 25px -5px var(--tw-shadow-color), 0 8px 10px -6px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.tw-shadow-amber-100 {
  --tw-shadow-color: #fef3c7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/0 {
  --tw-shadow-color: rgb(254 243 199 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/10 {
  --tw-shadow-color: rgb(254 243 199 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/100 {
  --tw-shadow-color: rgb(254 243 199 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/15 {
  --tw-shadow-color: rgb(254 243 199 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/20 {
  --tw-shadow-color: rgb(254 243 199 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/25 {
  --tw-shadow-color: rgb(254 243 199 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/30 {
  --tw-shadow-color: rgb(254 243 199 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/35 {
  --tw-shadow-color: rgb(254 243 199 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/40 {
  --tw-shadow-color: rgb(254 243 199 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/45 {
  --tw-shadow-color: rgb(254 243 199 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/5 {
  --tw-shadow-color: rgb(254 243 199 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/50 {
  --tw-shadow-color: rgb(254 243 199 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/55 {
  --tw-shadow-color: rgb(254 243 199 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/60 {
  --tw-shadow-color: rgb(254 243 199 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/65 {
  --tw-shadow-color: rgb(254 243 199 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/70 {
  --tw-shadow-color: rgb(254 243 199 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/75 {
  --tw-shadow-color: rgb(254 243 199 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/80 {
  --tw-shadow-color: rgb(254 243 199 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/85 {
  --tw-shadow-color: rgb(254 243 199 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/90 {
  --tw-shadow-color: rgb(254 243 199 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-100\/95 {
  --tw-shadow-color: rgb(254 243 199 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200 {
  --tw-shadow-color: #fde68a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/0 {
  --tw-shadow-color: rgb(253 230 138 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/10 {
  --tw-shadow-color: rgb(253 230 138 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/100 {
  --tw-shadow-color: rgb(253 230 138 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/15 {
  --tw-shadow-color: rgb(253 230 138 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/20 {
  --tw-shadow-color: rgb(253 230 138 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/25 {
  --tw-shadow-color: rgb(253 230 138 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/30 {
  --tw-shadow-color: rgb(253 230 138 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/35 {
  --tw-shadow-color: rgb(253 230 138 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/40 {
  --tw-shadow-color: rgb(253 230 138 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/45 {
  --tw-shadow-color: rgb(253 230 138 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/5 {
  --tw-shadow-color: rgb(253 230 138 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/50 {
  --tw-shadow-color: rgb(253 230 138 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/55 {
  --tw-shadow-color: rgb(253 230 138 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/60 {
  --tw-shadow-color: rgb(253 230 138 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/65 {
  --tw-shadow-color: rgb(253 230 138 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/70 {
  --tw-shadow-color: rgb(253 230 138 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/75 {
  --tw-shadow-color: rgb(253 230 138 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/80 {
  --tw-shadow-color: rgb(253 230 138 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/85 {
  --tw-shadow-color: rgb(253 230 138 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/90 {
  --tw-shadow-color: rgb(253 230 138 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-200\/95 {
  --tw-shadow-color: rgb(253 230 138 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300 {
  --tw-shadow-color: #fcd34d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/0 {
  --tw-shadow-color: rgb(252 211 77 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/10 {
  --tw-shadow-color: rgb(252 211 77 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/100 {
  --tw-shadow-color: rgb(252 211 77 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/15 {
  --tw-shadow-color: rgb(252 211 77 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/20 {
  --tw-shadow-color: rgb(252 211 77 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/25 {
  --tw-shadow-color: rgb(252 211 77 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/30 {
  --tw-shadow-color: rgb(252 211 77 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/35 {
  --tw-shadow-color: rgb(252 211 77 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/40 {
  --tw-shadow-color: rgb(252 211 77 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/45 {
  --tw-shadow-color: rgb(252 211 77 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/5 {
  --tw-shadow-color: rgb(252 211 77 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/50 {
  --tw-shadow-color: rgb(252 211 77 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/55 {
  --tw-shadow-color: rgb(252 211 77 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/60 {
  --tw-shadow-color: rgb(252 211 77 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/65 {
  --tw-shadow-color: rgb(252 211 77 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/70 {
  --tw-shadow-color: rgb(252 211 77 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/75 {
  --tw-shadow-color: rgb(252 211 77 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/80 {
  --tw-shadow-color: rgb(252 211 77 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/85 {
  --tw-shadow-color: rgb(252 211 77 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/90 {
  --tw-shadow-color: rgb(252 211 77 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-300\/95 {
  --tw-shadow-color: rgb(252 211 77 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400 {
  --tw-shadow-color: #fbbf24 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/0 {
  --tw-shadow-color: rgb(251 191 36 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/10 {
  --tw-shadow-color: rgb(251 191 36 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/100 {
  --tw-shadow-color: rgb(251 191 36 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/15 {
  --tw-shadow-color: rgb(251 191 36 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/20 {
  --tw-shadow-color: rgb(251 191 36 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/25 {
  --tw-shadow-color: rgb(251 191 36 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/30 {
  --tw-shadow-color: rgb(251 191 36 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/35 {
  --tw-shadow-color: rgb(251 191 36 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/40 {
  --tw-shadow-color: rgb(251 191 36 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/45 {
  --tw-shadow-color: rgb(251 191 36 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/5 {
  --tw-shadow-color: rgb(251 191 36 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/50 {
  --tw-shadow-color: rgb(251 191 36 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/55 {
  --tw-shadow-color: rgb(251 191 36 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/60 {
  --tw-shadow-color: rgb(251 191 36 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/65 {
  --tw-shadow-color: rgb(251 191 36 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/70 {
  --tw-shadow-color: rgb(251 191 36 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/75 {
  --tw-shadow-color: rgb(251 191 36 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/80 {
  --tw-shadow-color: rgb(251 191 36 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/85 {
  --tw-shadow-color: rgb(251 191 36 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/90 {
  --tw-shadow-color: rgb(251 191 36 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-400\/95 {
  --tw-shadow-color: rgb(251 191 36 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50 {
  --tw-shadow-color: #fffbeb !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/0 {
  --tw-shadow-color: rgb(255 251 235 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/10 {
  --tw-shadow-color: rgb(255 251 235 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/100 {
  --tw-shadow-color: rgb(255 251 235 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/15 {
  --tw-shadow-color: rgb(255 251 235 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/20 {
  --tw-shadow-color: rgb(255 251 235 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/25 {
  --tw-shadow-color: rgb(255 251 235 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/30 {
  --tw-shadow-color: rgb(255 251 235 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/35 {
  --tw-shadow-color: rgb(255 251 235 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/40 {
  --tw-shadow-color: rgb(255 251 235 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/45 {
  --tw-shadow-color: rgb(255 251 235 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/5 {
  --tw-shadow-color: rgb(255 251 235 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/50 {
  --tw-shadow-color: rgb(255 251 235 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/55 {
  --tw-shadow-color: rgb(255 251 235 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/60 {
  --tw-shadow-color: rgb(255 251 235 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/65 {
  --tw-shadow-color: rgb(255 251 235 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/70 {
  --tw-shadow-color: rgb(255 251 235 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/75 {
  --tw-shadow-color: rgb(255 251 235 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/80 {
  --tw-shadow-color: rgb(255 251 235 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/85 {
  --tw-shadow-color: rgb(255 251 235 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/90 {
  --tw-shadow-color: rgb(255 251 235 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-50\/95 {
  --tw-shadow-color: rgb(255 251 235 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500 {
  --tw-shadow-color: #f59e0b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/0 {
  --tw-shadow-color: rgb(245 158 11 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/10 {
  --tw-shadow-color: rgb(245 158 11 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/100 {
  --tw-shadow-color: rgb(245 158 11 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/15 {
  --tw-shadow-color: rgb(245 158 11 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/20 {
  --tw-shadow-color: rgb(245 158 11 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/25 {
  --tw-shadow-color: rgb(245 158 11 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/30 {
  --tw-shadow-color: rgb(245 158 11 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/35 {
  --tw-shadow-color: rgb(245 158 11 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/40 {
  --tw-shadow-color: rgb(245 158 11 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/45 {
  --tw-shadow-color: rgb(245 158 11 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/5 {
  --tw-shadow-color: rgb(245 158 11 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/50 {
  --tw-shadow-color: rgb(245 158 11 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/55 {
  --tw-shadow-color: rgb(245 158 11 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/60 {
  --tw-shadow-color: rgb(245 158 11 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/65 {
  --tw-shadow-color: rgb(245 158 11 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/70 {
  --tw-shadow-color: rgb(245 158 11 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/75 {
  --tw-shadow-color: rgb(245 158 11 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/80 {
  --tw-shadow-color: rgb(245 158 11 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/85 {
  --tw-shadow-color: rgb(245 158 11 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/90 {
  --tw-shadow-color: rgb(245 158 11 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-500\/95 {
  --tw-shadow-color: rgb(245 158 11 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600 {
  --tw-shadow-color: #d97706 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/0 {
  --tw-shadow-color: rgb(217 119 6 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/10 {
  --tw-shadow-color: rgb(217 119 6 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/100 {
  --tw-shadow-color: rgb(217 119 6 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/15 {
  --tw-shadow-color: rgb(217 119 6 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/20 {
  --tw-shadow-color: rgb(217 119 6 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/25 {
  --tw-shadow-color: rgb(217 119 6 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/30 {
  --tw-shadow-color: rgb(217 119 6 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/35 {
  --tw-shadow-color: rgb(217 119 6 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/40 {
  --tw-shadow-color: rgb(217 119 6 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/45 {
  --tw-shadow-color: rgb(217 119 6 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/5 {
  --tw-shadow-color: rgb(217 119 6 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/50 {
  --tw-shadow-color: rgb(217 119 6 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/55 {
  --tw-shadow-color: rgb(217 119 6 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/60 {
  --tw-shadow-color: rgb(217 119 6 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/65 {
  --tw-shadow-color: rgb(217 119 6 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/70 {
  --tw-shadow-color: rgb(217 119 6 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/75 {
  --tw-shadow-color: rgb(217 119 6 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/80 {
  --tw-shadow-color: rgb(217 119 6 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/85 {
  --tw-shadow-color: rgb(217 119 6 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/90 {
  --tw-shadow-color: rgb(217 119 6 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-600\/95 {
  --tw-shadow-color: rgb(217 119 6 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700 {
  --tw-shadow-color: #b45309 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/0 {
  --tw-shadow-color: rgb(180 83 9 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/10 {
  --tw-shadow-color: rgb(180 83 9 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/100 {
  --tw-shadow-color: rgb(180 83 9 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/15 {
  --tw-shadow-color: rgb(180 83 9 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/20 {
  --tw-shadow-color: rgb(180 83 9 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/25 {
  --tw-shadow-color: rgb(180 83 9 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/30 {
  --tw-shadow-color: rgb(180 83 9 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/35 {
  --tw-shadow-color: rgb(180 83 9 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/40 {
  --tw-shadow-color: rgb(180 83 9 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/45 {
  --tw-shadow-color: rgb(180 83 9 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/5 {
  --tw-shadow-color: rgb(180 83 9 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/50 {
  --tw-shadow-color: rgb(180 83 9 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/55 {
  --tw-shadow-color: rgb(180 83 9 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/60 {
  --tw-shadow-color: rgb(180 83 9 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/65 {
  --tw-shadow-color: rgb(180 83 9 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/70 {
  --tw-shadow-color: rgb(180 83 9 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/75 {
  --tw-shadow-color: rgb(180 83 9 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/80 {
  --tw-shadow-color: rgb(180 83 9 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/85 {
  --tw-shadow-color: rgb(180 83 9 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/90 {
  --tw-shadow-color: rgb(180 83 9 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-700\/95 {
  --tw-shadow-color: rgb(180 83 9 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800 {
  --tw-shadow-color: #92400e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/0 {
  --tw-shadow-color: rgb(146 64 14 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/10 {
  --tw-shadow-color: rgb(146 64 14 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/100 {
  --tw-shadow-color: rgb(146 64 14 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/15 {
  --tw-shadow-color: rgb(146 64 14 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/20 {
  --tw-shadow-color: rgb(146 64 14 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/25 {
  --tw-shadow-color: rgb(146 64 14 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/30 {
  --tw-shadow-color: rgb(146 64 14 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/35 {
  --tw-shadow-color: rgb(146 64 14 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/40 {
  --tw-shadow-color: rgb(146 64 14 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/45 {
  --tw-shadow-color: rgb(146 64 14 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/5 {
  --tw-shadow-color: rgb(146 64 14 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/50 {
  --tw-shadow-color: rgb(146 64 14 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/55 {
  --tw-shadow-color: rgb(146 64 14 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/60 {
  --tw-shadow-color: rgb(146 64 14 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/65 {
  --tw-shadow-color: rgb(146 64 14 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/70 {
  --tw-shadow-color: rgb(146 64 14 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/75 {
  --tw-shadow-color: rgb(146 64 14 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/80 {
  --tw-shadow-color: rgb(146 64 14 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/85 {
  --tw-shadow-color: rgb(146 64 14 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/90 {
  --tw-shadow-color: rgb(146 64 14 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-800\/95 {
  --tw-shadow-color: rgb(146 64 14 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900 {
  --tw-shadow-color: #78350f !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/0 {
  --tw-shadow-color: rgb(120 53 15 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/10 {
  --tw-shadow-color: rgb(120 53 15 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/100 {
  --tw-shadow-color: rgb(120 53 15 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/15 {
  --tw-shadow-color: rgb(120 53 15 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/20 {
  --tw-shadow-color: rgb(120 53 15 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/25 {
  --tw-shadow-color: rgb(120 53 15 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/30 {
  --tw-shadow-color: rgb(120 53 15 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/35 {
  --tw-shadow-color: rgb(120 53 15 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/40 {
  --tw-shadow-color: rgb(120 53 15 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/45 {
  --tw-shadow-color: rgb(120 53 15 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/5 {
  --tw-shadow-color: rgb(120 53 15 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/50 {
  --tw-shadow-color: rgb(120 53 15 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/55 {
  --tw-shadow-color: rgb(120 53 15 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/60 {
  --tw-shadow-color: rgb(120 53 15 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/65 {
  --tw-shadow-color: rgb(120 53 15 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/70 {
  --tw-shadow-color: rgb(120 53 15 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/75 {
  --tw-shadow-color: rgb(120 53 15 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/80 {
  --tw-shadow-color: rgb(120 53 15 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/85 {
  --tw-shadow-color: rgb(120 53 15 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/90 {
  --tw-shadow-color: rgb(120 53 15 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-900\/95 {
  --tw-shadow-color: rgb(120 53 15 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950 {
  --tw-shadow-color: #451a03 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/0 {
  --tw-shadow-color: rgb(69 26 3 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/10 {
  --tw-shadow-color: rgb(69 26 3 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/100 {
  --tw-shadow-color: rgb(69 26 3 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/15 {
  --tw-shadow-color: rgb(69 26 3 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/20 {
  --tw-shadow-color: rgb(69 26 3 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/25 {
  --tw-shadow-color: rgb(69 26 3 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/30 {
  --tw-shadow-color: rgb(69 26 3 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/35 {
  --tw-shadow-color: rgb(69 26 3 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/40 {
  --tw-shadow-color: rgb(69 26 3 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/45 {
  --tw-shadow-color: rgb(69 26 3 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/5 {
  --tw-shadow-color: rgb(69 26 3 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/50 {
  --tw-shadow-color: rgb(69 26 3 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/55 {
  --tw-shadow-color: rgb(69 26 3 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/60 {
  --tw-shadow-color: rgb(69 26 3 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/65 {
  --tw-shadow-color: rgb(69 26 3 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/70 {
  --tw-shadow-color: rgb(69 26 3 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/75 {
  --tw-shadow-color: rgb(69 26 3 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/80 {
  --tw-shadow-color: rgb(69 26 3 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/85 {
  --tw-shadow-color: rgb(69 26 3 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/90 {
  --tw-shadow-color: rgb(69 26 3 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-amber-950\/95 {
  --tw-shadow-color: rgb(69 26 3 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black {
  --tw-shadow-color: #000 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/0 {
  --tw-shadow-color: rgb(0 0 0 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/10 {
  --tw-shadow-color: rgb(0 0 0 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/100 {
  --tw-shadow-color: rgb(0 0 0 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/15 {
  --tw-shadow-color: rgb(0 0 0 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/20 {
  --tw-shadow-color: rgb(0 0 0 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/25 {
  --tw-shadow-color: rgb(0 0 0 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/30 {
  --tw-shadow-color: rgb(0 0 0 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/35 {
  --tw-shadow-color: rgb(0 0 0 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/40 {
  --tw-shadow-color: rgb(0 0 0 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/45 {
  --tw-shadow-color: rgb(0 0 0 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/5 {
  --tw-shadow-color: rgb(0 0 0 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/50 {
  --tw-shadow-color: rgb(0 0 0 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/55 {
  --tw-shadow-color: rgb(0 0 0 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/60 {
  --tw-shadow-color: rgb(0 0 0 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/65 {
  --tw-shadow-color: rgb(0 0 0 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/70 {
  --tw-shadow-color: rgb(0 0 0 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/75 {
  --tw-shadow-color: rgb(0 0 0 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/80 {
  --tw-shadow-color: rgb(0 0 0 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/85 {
  --tw-shadow-color: rgb(0 0 0 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/90 {
  --tw-shadow-color: rgb(0 0 0 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-black\/95 {
  --tw-shadow-color: rgb(0 0 0 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100 {
  --tw-shadow-color: #dbeafe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/0 {
  --tw-shadow-color: rgb(219 234 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/10 {
  --tw-shadow-color: rgb(219 234 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/100 {
  --tw-shadow-color: rgb(219 234 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/15 {
  --tw-shadow-color: rgb(219 234 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/20 {
  --tw-shadow-color: rgb(219 234 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/25 {
  --tw-shadow-color: rgb(219 234 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/30 {
  --tw-shadow-color: rgb(219 234 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/35 {
  --tw-shadow-color: rgb(219 234 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/40 {
  --tw-shadow-color: rgb(219 234 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/45 {
  --tw-shadow-color: rgb(219 234 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/5 {
  --tw-shadow-color: rgb(219 234 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/50 {
  --tw-shadow-color: rgb(219 234 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/55 {
  --tw-shadow-color: rgb(219 234 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/60 {
  --tw-shadow-color: rgb(219 234 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/65 {
  --tw-shadow-color: rgb(219 234 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/70 {
  --tw-shadow-color: rgb(219 234 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/75 {
  --tw-shadow-color: rgb(219 234 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/80 {
  --tw-shadow-color: rgb(219 234 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/85 {
  --tw-shadow-color: rgb(219 234 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/90 {
  --tw-shadow-color: rgb(219 234 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-100\/95 {
  --tw-shadow-color: rgb(219 234 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200 {
  --tw-shadow-color: #bfdbfe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/0 {
  --tw-shadow-color: rgb(191 219 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/10 {
  --tw-shadow-color: rgb(191 219 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/100 {
  --tw-shadow-color: rgb(191 219 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/15 {
  --tw-shadow-color: rgb(191 219 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/20 {
  --tw-shadow-color: rgb(191 219 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/25 {
  --tw-shadow-color: rgb(191 219 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/30 {
  --tw-shadow-color: rgb(191 219 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/35 {
  --tw-shadow-color: rgb(191 219 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/40 {
  --tw-shadow-color: rgb(191 219 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/45 {
  --tw-shadow-color: rgb(191 219 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/5 {
  --tw-shadow-color: rgb(191 219 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/50 {
  --tw-shadow-color: rgb(191 219 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/55 {
  --tw-shadow-color: rgb(191 219 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/60 {
  --tw-shadow-color: rgb(191 219 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/65 {
  --tw-shadow-color: rgb(191 219 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/70 {
  --tw-shadow-color: rgb(191 219 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/75 {
  --tw-shadow-color: rgb(191 219 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/80 {
  --tw-shadow-color: rgb(191 219 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/85 {
  --tw-shadow-color: rgb(191 219 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/90 {
  --tw-shadow-color: rgb(191 219 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-200\/95 {
  --tw-shadow-color: rgb(191 219 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300 {
  --tw-shadow-color: #93c5fd !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/0 {
  --tw-shadow-color: rgb(147 197 253 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/10 {
  --tw-shadow-color: rgb(147 197 253 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/100 {
  --tw-shadow-color: rgb(147 197 253 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/15 {
  --tw-shadow-color: rgb(147 197 253 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/20 {
  --tw-shadow-color: rgb(147 197 253 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/25 {
  --tw-shadow-color: rgb(147 197 253 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/30 {
  --tw-shadow-color: rgb(147 197 253 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/35 {
  --tw-shadow-color: rgb(147 197 253 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/40 {
  --tw-shadow-color: rgb(147 197 253 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/45 {
  --tw-shadow-color: rgb(147 197 253 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/5 {
  --tw-shadow-color: rgb(147 197 253 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/50 {
  --tw-shadow-color: rgb(147 197 253 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/55 {
  --tw-shadow-color: rgb(147 197 253 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/60 {
  --tw-shadow-color: rgb(147 197 253 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/65 {
  --tw-shadow-color: rgb(147 197 253 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/70 {
  --tw-shadow-color: rgb(147 197 253 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/75 {
  --tw-shadow-color: rgb(147 197 253 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/80 {
  --tw-shadow-color: rgb(147 197 253 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/85 {
  --tw-shadow-color: rgb(147 197 253 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/90 {
  --tw-shadow-color: rgb(147 197 253 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-300\/95 {
  --tw-shadow-color: rgb(147 197 253 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400 {
  --tw-shadow-color: #60a5fa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/0 {
  --tw-shadow-color: rgb(96 165 250 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/10 {
  --tw-shadow-color: rgb(96 165 250 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/100 {
  --tw-shadow-color: rgb(96 165 250 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/15 {
  --tw-shadow-color: rgb(96 165 250 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/20 {
  --tw-shadow-color: rgb(96 165 250 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/25 {
  --tw-shadow-color: rgb(96 165 250 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/30 {
  --tw-shadow-color: rgb(96 165 250 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/35 {
  --tw-shadow-color: rgb(96 165 250 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/40 {
  --tw-shadow-color: rgb(96 165 250 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/45 {
  --tw-shadow-color: rgb(96 165 250 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/5 {
  --tw-shadow-color: rgb(96 165 250 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/50 {
  --tw-shadow-color: rgb(96 165 250 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/55 {
  --tw-shadow-color: rgb(96 165 250 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/60 {
  --tw-shadow-color: rgb(96 165 250 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/65 {
  --tw-shadow-color: rgb(96 165 250 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/70 {
  --tw-shadow-color: rgb(96 165 250 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/75 {
  --tw-shadow-color: rgb(96 165 250 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/80 {
  --tw-shadow-color: rgb(96 165 250 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/85 {
  --tw-shadow-color: rgb(96 165 250 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/90 {
  --tw-shadow-color: rgb(96 165 250 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-400\/95 {
  --tw-shadow-color: rgb(96 165 250 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50 {
  --tw-shadow-color: #eff6ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/0 {
  --tw-shadow-color: rgb(239 246 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/10 {
  --tw-shadow-color: rgb(239 246 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/100 {
  --tw-shadow-color: rgb(239 246 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/15 {
  --tw-shadow-color: rgb(239 246 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/20 {
  --tw-shadow-color: rgb(239 246 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/25 {
  --tw-shadow-color: rgb(239 246 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/30 {
  --tw-shadow-color: rgb(239 246 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/35 {
  --tw-shadow-color: rgb(239 246 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/40 {
  --tw-shadow-color: rgb(239 246 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/45 {
  --tw-shadow-color: rgb(239 246 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/5 {
  --tw-shadow-color: rgb(239 246 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/50 {
  --tw-shadow-color: rgb(239 246 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/55 {
  --tw-shadow-color: rgb(239 246 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/60 {
  --tw-shadow-color: rgb(239 246 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/65 {
  --tw-shadow-color: rgb(239 246 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/70 {
  --tw-shadow-color: rgb(239 246 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/75 {
  --tw-shadow-color: rgb(239 246 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/80 {
  --tw-shadow-color: rgb(239 246 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/85 {
  --tw-shadow-color: rgb(239 246 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/90 {
  --tw-shadow-color: rgb(239 246 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-50\/95 {
  --tw-shadow-color: rgb(239 246 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500 {
  --tw-shadow-color: #3b82f6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/0 {
  --tw-shadow-color: rgb(59 130 246 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/10 {
  --tw-shadow-color: rgb(59 130 246 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/100 {
  --tw-shadow-color: rgb(59 130 246 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/15 {
  --tw-shadow-color: rgb(59 130 246 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/20 {
  --tw-shadow-color: rgb(59 130 246 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/25 {
  --tw-shadow-color: rgb(59 130 246 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/30 {
  --tw-shadow-color: rgb(59 130 246 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/35 {
  --tw-shadow-color: rgb(59 130 246 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/40 {
  --tw-shadow-color: rgb(59 130 246 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/45 {
  --tw-shadow-color: rgb(59 130 246 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/5 {
  --tw-shadow-color: rgb(59 130 246 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/50 {
  --tw-shadow-color: rgb(59 130 246 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/55 {
  --tw-shadow-color: rgb(59 130 246 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/60 {
  --tw-shadow-color: rgb(59 130 246 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/65 {
  --tw-shadow-color: rgb(59 130 246 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/70 {
  --tw-shadow-color: rgb(59 130 246 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/75 {
  --tw-shadow-color: rgb(59 130 246 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/80 {
  --tw-shadow-color: rgb(59 130 246 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/85 {
  --tw-shadow-color: rgb(59 130 246 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/90 {
  --tw-shadow-color: rgb(59 130 246 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-500\/95 {
  --tw-shadow-color: rgb(59 130 246 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600 {
  --tw-shadow-color: #2563eb !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/0 {
  --tw-shadow-color: rgb(37 99 235 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/10 {
  --tw-shadow-color: rgb(37 99 235 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/100 {
  --tw-shadow-color: rgb(37 99 235 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/15 {
  --tw-shadow-color: rgb(37 99 235 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/20 {
  --tw-shadow-color: rgb(37 99 235 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/25 {
  --tw-shadow-color: rgb(37 99 235 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/30 {
  --tw-shadow-color: rgb(37 99 235 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/35 {
  --tw-shadow-color: rgb(37 99 235 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/40 {
  --tw-shadow-color: rgb(37 99 235 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/45 {
  --tw-shadow-color: rgb(37 99 235 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/5 {
  --tw-shadow-color: rgb(37 99 235 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/50 {
  --tw-shadow-color: rgb(37 99 235 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/55 {
  --tw-shadow-color: rgb(37 99 235 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/60 {
  --tw-shadow-color: rgb(37 99 235 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/65 {
  --tw-shadow-color: rgb(37 99 235 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/70 {
  --tw-shadow-color: rgb(37 99 235 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/75 {
  --tw-shadow-color: rgb(37 99 235 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/80 {
  --tw-shadow-color: rgb(37 99 235 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/85 {
  --tw-shadow-color: rgb(37 99 235 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/90 {
  --tw-shadow-color: rgb(37 99 235 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-600\/95 {
  --tw-shadow-color: rgb(37 99 235 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700 {
  --tw-shadow-color: #1d4ed8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/0 {
  --tw-shadow-color: rgb(29 78 216 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/10 {
  --tw-shadow-color: rgb(29 78 216 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/100 {
  --tw-shadow-color: rgb(29 78 216 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/15 {
  --tw-shadow-color: rgb(29 78 216 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/20 {
  --tw-shadow-color: rgb(29 78 216 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/25 {
  --tw-shadow-color: rgb(29 78 216 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/30 {
  --tw-shadow-color: rgb(29 78 216 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/35 {
  --tw-shadow-color: rgb(29 78 216 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/40 {
  --tw-shadow-color: rgb(29 78 216 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/45 {
  --tw-shadow-color: rgb(29 78 216 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/5 {
  --tw-shadow-color: rgb(29 78 216 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/50 {
  --tw-shadow-color: rgb(29 78 216 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/55 {
  --tw-shadow-color: rgb(29 78 216 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/60 {
  --tw-shadow-color: rgb(29 78 216 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/65 {
  --tw-shadow-color: rgb(29 78 216 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/70 {
  --tw-shadow-color: rgb(29 78 216 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/75 {
  --tw-shadow-color: rgb(29 78 216 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/80 {
  --tw-shadow-color: rgb(29 78 216 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/85 {
  --tw-shadow-color: rgb(29 78 216 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/90 {
  --tw-shadow-color: rgb(29 78 216 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-700\/95 {
  --tw-shadow-color: rgb(29 78 216 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800 {
  --tw-shadow-color: #1e40af !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/0 {
  --tw-shadow-color: rgb(30 64 175 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/10 {
  --tw-shadow-color: rgb(30 64 175 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/100 {
  --tw-shadow-color: rgb(30 64 175 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/15 {
  --tw-shadow-color: rgb(30 64 175 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/20 {
  --tw-shadow-color: rgb(30 64 175 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/25 {
  --tw-shadow-color: rgb(30 64 175 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/30 {
  --tw-shadow-color: rgb(30 64 175 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/35 {
  --tw-shadow-color: rgb(30 64 175 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/40 {
  --tw-shadow-color: rgb(30 64 175 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/45 {
  --tw-shadow-color: rgb(30 64 175 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/5 {
  --tw-shadow-color: rgb(30 64 175 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/50 {
  --tw-shadow-color: rgb(30 64 175 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/55 {
  --tw-shadow-color: rgb(30 64 175 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/60 {
  --tw-shadow-color: rgb(30 64 175 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/65 {
  --tw-shadow-color: rgb(30 64 175 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/70 {
  --tw-shadow-color: rgb(30 64 175 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/75 {
  --tw-shadow-color: rgb(30 64 175 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/80 {
  --tw-shadow-color: rgb(30 64 175 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/85 {
  --tw-shadow-color: rgb(30 64 175 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/90 {
  --tw-shadow-color: rgb(30 64 175 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-800\/95 {
  --tw-shadow-color: rgb(30 64 175 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900 {
  --tw-shadow-color: #1e3a8a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/0 {
  --tw-shadow-color: rgb(30 58 138 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/10 {
  --tw-shadow-color: rgb(30 58 138 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/100 {
  --tw-shadow-color: rgb(30 58 138 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/15 {
  --tw-shadow-color: rgb(30 58 138 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/20 {
  --tw-shadow-color: rgb(30 58 138 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/25 {
  --tw-shadow-color: rgb(30 58 138 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/30 {
  --tw-shadow-color: rgb(30 58 138 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/35 {
  --tw-shadow-color: rgb(30 58 138 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/40 {
  --tw-shadow-color: rgb(30 58 138 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/45 {
  --tw-shadow-color: rgb(30 58 138 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/5 {
  --tw-shadow-color: rgb(30 58 138 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/50 {
  --tw-shadow-color: rgb(30 58 138 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/55 {
  --tw-shadow-color: rgb(30 58 138 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/60 {
  --tw-shadow-color: rgb(30 58 138 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/65 {
  --tw-shadow-color: rgb(30 58 138 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/70 {
  --tw-shadow-color: rgb(30 58 138 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/75 {
  --tw-shadow-color: rgb(30 58 138 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/80 {
  --tw-shadow-color: rgb(30 58 138 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/85 {
  --tw-shadow-color: rgb(30 58 138 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/90 {
  --tw-shadow-color: rgb(30 58 138 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-900\/95 {
  --tw-shadow-color: rgb(30 58 138 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950 {
  --tw-shadow-color: #172554 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/0 {
  --tw-shadow-color: rgb(23 37 84 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/10 {
  --tw-shadow-color: rgb(23 37 84 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/100 {
  --tw-shadow-color: rgb(23 37 84 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/15 {
  --tw-shadow-color: rgb(23 37 84 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/20 {
  --tw-shadow-color: rgb(23 37 84 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/25 {
  --tw-shadow-color: rgb(23 37 84 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/30 {
  --tw-shadow-color: rgb(23 37 84 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/35 {
  --tw-shadow-color: rgb(23 37 84 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/40 {
  --tw-shadow-color: rgb(23 37 84 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/45 {
  --tw-shadow-color: rgb(23 37 84 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/5 {
  --tw-shadow-color: rgb(23 37 84 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/50 {
  --tw-shadow-color: rgb(23 37 84 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/55 {
  --tw-shadow-color: rgb(23 37 84 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/60 {
  --tw-shadow-color: rgb(23 37 84 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/65 {
  --tw-shadow-color: rgb(23 37 84 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/70 {
  --tw-shadow-color: rgb(23 37 84 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/75 {
  --tw-shadow-color: rgb(23 37 84 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/80 {
  --tw-shadow-color: rgb(23 37 84 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/85 {
  --tw-shadow-color: rgb(23 37 84 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/90 {
  --tw-shadow-color: rgb(23 37 84 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-blue-950\/95 {
  --tw-shadow-color: rgb(23 37 84 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-current {
  --tw-shadow-color: currentColor !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100 {
  --tw-shadow-color: #cffafe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/0 {
  --tw-shadow-color: rgb(207 250 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/10 {
  --tw-shadow-color: rgb(207 250 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/100 {
  --tw-shadow-color: rgb(207 250 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/15 {
  --tw-shadow-color: rgb(207 250 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/20 {
  --tw-shadow-color: rgb(207 250 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/25 {
  --tw-shadow-color: rgb(207 250 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/30 {
  --tw-shadow-color: rgb(207 250 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/35 {
  --tw-shadow-color: rgb(207 250 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/40 {
  --tw-shadow-color: rgb(207 250 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/45 {
  --tw-shadow-color: rgb(207 250 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/5 {
  --tw-shadow-color: rgb(207 250 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/50 {
  --tw-shadow-color: rgb(207 250 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/55 {
  --tw-shadow-color: rgb(207 250 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/60 {
  --tw-shadow-color: rgb(207 250 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/65 {
  --tw-shadow-color: rgb(207 250 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/70 {
  --tw-shadow-color: rgb(207 250 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/75 {
  --tw-shadow-color: rgb(207 250 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/80 {
  --tw-shadow-color: rgb(207 250 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/85 {
  --tw-shadow-color: rgb(207 250 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/90 {
  --tw-shadow-color: rgb(207 250 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-100\/95 {
  --tw-shadow-color: rgb(207 250 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200 {
  --tw-shadow-color: #a5f3fc !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/0 {
  --tw-shadow-color: rgb(165 243 252 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/10 {
  --tw-shadow-color: rgb(165 243 252 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/100 {
  --tw-shadow-color: rgb(165 243 252 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/15 {
  --tw-shadow-color: rgb(165 243 252 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/20 {
  --tw-shadow-color: rgb(165 243 252 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/25 {
  --tw-shadow-color: rgb(165 243 252 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/30 {
  --tw-shadow-color: rgb(165 243 252 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/35 {
  --tw-shadow-color: rgb(165 243 252 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/40 {
  --tw-shadow-color: rgb(165 243 252 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/45 {
  --tw-shadow-color: rgb(165 243 252 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/5 {
  --tw-shadow-color: rgb(165 243 252 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/50 {
  --tw-shadow-color: rgb(165 243 252 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/55 {
  --tw-shadow-color: rgb(165 243 252 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/60 {
  --tw-shadow-color: rgb(165 243 252 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/65 {
  --tw-shadow-color: rgb(165 243 252 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/70 {
  --tw-shadow-color: rgb(165 243 252 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/75 {
  --tw-shadow-color: rgb(165 243 252 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/80 {
  --tw-shadow-color: rgb(165 243 252 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/85 {
  --tw-shadow-color: rgb(165 243 252 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/90 {
  --tw-shadow-color: rgb(165 243 252 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-200\/95 {
  --tw-shadow-color: rgb(165 243 252 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300 {
  --tw-shadow-color: #67e8f9 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/0 {
  --tw-shadow-color: rgb(103 232 249 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/10 {
  --tw-shadow-color: rgb(103 232 249 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/100 {
  --tw-shadow-color: rgb(103 232 249 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/15 {
  --tw-shadow-color: rgb(103 232 249 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/20 {
  --tw-shadow-color: rgb(103 232 249 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/25 {
  --tw-shadow-color: rgb(103 232 249 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/30 {
  --tw-shadow-color: rgb(103 232 249 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/35 {
  --tw-shadow-color: rgb(103 232 249 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/40 {
  --tw-shadow-color: rgb(103 232 249 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/45 {
  --tw-shadow-color: rgb(103 232 249 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/5 {
  --tw-shadow-color: rgb(103 232 249 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/50 {
  --tw-shadow-color: rgb(103 232 249 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/55 {
  --tw-shadow-color: rgb(103 232 249 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/60 {
  --tw-shadow-color: rgb(103 232 249 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/65 {
  --tw-shadow-color: rgb(103 232 249 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/70 {
  --tw-shadow-color: rgb(103 232 249 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/75 {
  --tw-shadow-color: rgb(103 232 249 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/80 {
  --tw-shadow-color: rgb(103 232 249 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/85 {
  --tw-shadow-color: rgb(103 232 249 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/90 {
  --tw-shadow-color: rgb(103 232 249 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-300\/95 {
  --tw-shadow-color: rgb(103 232 249 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400 {
  --tw-shadow-color: #22d3ee !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/0 {
  --tw-shadow-color: rgb(34 211 238 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/10 {
  --tw-shadow-color: rgb(34 211 238 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/100 {
  --tw-shadow-color: rgb(34 211 238 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/15 {
  --tw-shadow-color: rgb(34 211 238 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/20 {
  --tw-shadow-color: rgb(34 211 238 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/25 {
  --tw-shadow-color: rgb(34 211 238 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/30 {
  --tw-shadow-color: rgb(34 211 238 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/35 {
  --tw-shadow-color: rgb(34 211 238 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/40 {
  --tw-shadow-color: rgb(34 211 238 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/45 {
  --tw-shadow-color: rgb(34 211 238 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/5 {
  --tw-shadow-color: rgb(34 211 238 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/50 {
  --tw-shadow-color: rgb(34 211 238 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/55 {
  --tw-shadow-color: rgb(34 211 238 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/60 {
  --tw-shadow-color: rgb(34 211 238 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/65 {
  --tw-shadow-color: rgb(34 211 238 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/70 {
  --tw-shadow-color: rgb(34 211 238 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/75 {
  --tw-shadow-color: rgb(34 211 238 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/80 {
  --tw-shadow-color: rgb(34 211 238 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/85 {
  --tw-shadow-color: rgb(34 211 238 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/90 {
  --tw-shadow-color: rgb(34 211 238 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-400\/95 {
  --tw-shadow-color: rgb(34 211 238 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50 {
  --tw-shadow-color: #ecfeff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/0 {
  --tw-shadow-color: rgb(236 254 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/10 {
  --tw-shadow-color: rgb(236 254 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/100 {
  --tw-shadow-color: rgb(236 254 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/15 {
  --tw-shadow-color: rgb(236 254 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/20 {
  --tw-shadow-color: rgb(236 254 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/25 {
  --tw-shadow-color: rgb(236 254 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/30 {
  --tw-shadow-color: rgb(236 254 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/35 {
  --tw-shadow-color: rgb(236 254 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/40 {
  --tw-shadow-color: rgb(236 254 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/45 {
  --tw-shadow-color: rgb(236 254 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/5 {
  --tw-shadow-color: rgb(236 254 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/50 {
  --tw-shadow-color: rgb(236 254 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/55 {
  --tw-shadow-color: rgb(236 254 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/60 {
  --tw-shadow-color: rgb(236 254 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/65 {
  --tw-shadow-color: rgb(236 254 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/70 {
  --tw-shadow-color: rgb(236 254 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/75 {
  --tw-shadow-color: rgb(236 254 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/80 {
  --tw-shadow-color: rgb(236 254 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/85 {
  --tw-shadow-color: rgb(236 254 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/90 {
  --tw-shadow-color: rgb(236 254 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-50\/95 {
  --tw-shadow-color: rgb(236 254 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500 {
  --tw-shadow-color: #06b6d4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/0 {
  --tw-shadow-color: rgb(6 182 212 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/10 {
  --tw-shadow-color: rgb(6 182 212 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/100 {
  --tw-shadow-color: rgb(6 182 212 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/15 {
  --tw-shadow-color: rgb(6 182 212 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/20 {
  --tw-shadow-color: rgb(6 182 212 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/25 {
  --tw-shadow-color: rgb(6 182 212 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/30 {
  --tw-shadow-color: rgb(6 182 212 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/35 {
  --tw-shadow-color: rgb(6 182 212 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/40 {
  --tw-shadow-color: rgb(6 182 212 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/45 {
  --tw-shadow-color: rgb(6 182 212 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/5 {
  --tw-shadow-color: rgb(6 182 212 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/50 {
  --tw-shadow-color: rgb(6 182 212 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/55 {
  --tw-shadow-color: rgb(6 182 212 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/60 {
  --tw-shadow-color: rgb(6 182 212 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/65 {
  --tw-shadow-color: rgb(6 182 212 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/70 {
  --tw-shadow-color: rgb(6 182 212 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/75 {
  --tw-shadow-color: rgb(6 182 212 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/80 {
  --tw-shadow-color: rgb(6 182 212 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/85 {
  --tw-shadow-color: rgb(6 182 212 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/90 {
  --tw-shadow-color: rgb(6 182 212 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-500\/95 {
  --tw-shadow-color: rgb(6 182 212 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600 {
  --tw-shadow-color: #0891b2 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/0 {
  --tw-shadow-color: rgb(8 145 178 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/10 {
  --tw-shadow-color: rgb(8 145 178 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/100 {
  --tw-shadow-color: rgb(8 145 178 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/15 {
  --tw-shadow-color: rgb(8 145 178 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/20 {
  --tw-shadow-color: rgb(8 145 178 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/25 {
  --tw-shadow-color: rgb(8 145 178 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/30 {
  --tw-shadow-color: rgb(8 145 178 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/35 {
  --tw-shadow-color: rgb(8 145 178 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/40 {
  --tw-shadow-color: rgb(8 145 178 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/45 {
  --tw-shadow-color: rgb(8 145 178 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/5 {
  --tw-shadow-color: rgb(8 145 178 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/50 {
  --tw-shadow-color: rgb(8 145 178 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/55 {
  --tw-shadow-color: rgb(8 145 178 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/60 {
  --tw-shadow-color: rgb(8 145 178 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/65 {
  --tw-shadow-color: rgb(8 145 178 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/70 {
  --tw-shadow-color: rgb(8 145 178 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/75 {
  --tw-shadow-color: rgb(8 145 178 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/80 {
  --tw-shadow-color: rgb(8 145 178 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/85 {
  --tw-shadow-color: rgb(8 145 178 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/90 {
  --tw-shadow-color: rgb(8 145 178 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-600\/95 {
  --tw-shadow-color: rgb(8 145 178 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700 {
  --tw-shadow-color: #0e7490 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/0 {
  --tw-shadow-color: rgb(14 116 144 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/10 {
  --tw-shadow-color: rgb(14 116 144 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/100 {
  --tw-shadow-color: rgb(14 116 144 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/15 {
  --tw-shadow-color: rgb(14 116 144 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/20 {
  --tw-shadow-color: rgb(14 116 144 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/25 {
  --tw-shadow-color: rgb(14 116 144 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/30 {
  --tw-shadow-color: rgb(14 116 144 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/35 {
  --tw-shadow-color: rgb(14 116 144 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/40 {
  --tw-shadow-color: rgb(14 116 144 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/45 {
  --tw-shadow-color: rgb(14 116 144 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/5 {
  --tw-shadow-color: rgb(14 116 144 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/50 {
  --tw-shadow-color: rgb(14 116 144 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/55 {
  --tw-shadow-color: rgb(14 116 144 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/60 {
  --tw-shadow-color: rgb(14 116 144 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/65 {
  --tw-shadow-color: rgb(14 116 144 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/70 {
  --tw-shadow-color: rgb(14 116 144 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/75 {
  --tw-shadow-color: rgb(14 116 144 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/80 {
  --tw-shadow-color: rgb(14 116 144 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/85 {
  --tw-shadow-color: rgb(14 116 144 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/90 {
  --tw-shadow-color: rgb(14 116 144 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-700\/95 {
  --tw-shadow-color: rgb(14 116 144 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800 {
  --tw-shadow-color: #155e75 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/0 {
  --tw-shadow-color: rgb(21 94 117 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/10 {
  --tw-shadow-color: rgb(21 94 117 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/100 {
  --tw-shadow-color: rgb(21 94 117 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/15 {
  --tw-shadow-color: rgb(21 94 117 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/20 {
  --tw-shadow-color: rgb(21 94 117 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/25 {
  --tw-shadow-color: rgb(21 94 117 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/30 {
  --tw-shadow-color: rgb(21 94 117 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/35 {
  --tw-shadow-color: rgb(21 94 117 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/40 {
  --tw-shadow-color: rgb(21 94 117 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/45 {
  --tw-shadow-color: rgb(21 94 117 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/5 {
  --tw-shadow-color: rgb(21 94 117 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/50 {
  --tw-shadow-color: rgb(21 94 117 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/55 {
  --tw-shadow-color: rgb(21 94 117 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/60 {
  --tw-shadow-color: rgb(21 94 117 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/65 {
  --tw-shadow-color: rgb(21 94 117 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/70 {
  --tw-shadow-color: rgb(21 94 117 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/75 {
  --tw-shadow-color: rgb(21 94 117 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/80 {
  --tw-shadow-color: rgb(21 94 117 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/85 {
  --tw-shadow-color: rgb(21 94 117 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/90 {
  --tw-shadow-color: rgb(21 94 117 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-800\/95 {
  --tw-shadow-color: rgb(21 94 117 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900 {
  --tw-shadow-color: #164e63 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/0 {
  --tw-shadow-color: rgb(22 78 99 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/10 {
  --tw-shadow-color: rgb(22 78 99 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/100 {
  --tw-shadow-color: rgb(22 78 99 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/15 {
  --tw-shadow-color: rgb(22 78 99 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/20 {
  --tw-shadow-color: rgb(22 78 99 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/25 {
  --tw-shadow-color: rgb(22 78 99 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/30 {
  --tw-shadow-color: rgb(22 78 99 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/35 {
  --tw-shadow-color: rgb(22 78 99 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/40 {
  --tw-shadow-color: rgb(22 78 99 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/45 {
  --tw-shadow-color: rgb(22 78 99 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/5 {
  --tw-shadow-color: rgb(22 78 99 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/50 {
  --tw-shadow-color: rgb(22 78 99 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/55 {
  --tw-shadow-color: rgb(22 78 99 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/60 {
  --tw-shadow-color: rgb(22 78 99 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/65 {
  --tw-shadow-color: rgb(22 78 99 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/70 {
  --tw-shadow-color: rgb(22 78 99 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/75 {
  --tw-shadow-color: rgb(22 78 99 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/80 {
  --tw-shadow-color: rgb(22 78 99 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/85 {
  --tw-shadow-color: rgb(22 78 99 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/90 {
  --tw-shadow-color: rgb(22 78 99 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-900\/95 {
  --tw-shadow-color: rgb(22 78 99 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950 {
  --tw-shadow-color: #083344 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/0 {
  --tw-shadow-color: rgb(8 51 68 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/10 {
  --tw-shadow-color: rgb(8 51 68 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/100 {
  --tw-shadow-color: rgb(8 51 68 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/15 {
  --tw-shadow-color: rgb(8 51 68 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/20 {
  --tw-shadow-color: rgb(8 51 68 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/25 {
  --tw-shadow-color: rgb(8 51 68 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/30 {
  --tw-shadow-color: rgb(8 51 68 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/35 {
  --tw-shadow-color: rgb(8 51 68 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/40 {
  --tw-shadow-color: rgb(8 51 68 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/45 {
  --tw-shadow-color: rgb(8 51 68 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/5 {
  --tw-shadow-color: rgb(8 51 68 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/50 {
  --tw-shadow-color: rgb(8 51 68 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/55 {
  --tw-shadow-color: rgb(8 51 68 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/60 {
  --tw-shadow-color: rgb(8 51 68 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/65 {
  --tw-shadow-color: rgb(8 51 68 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/70 {
  --tw-shadow-color: rgb(8 51 68 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/75 {
  --tw-shadow-color: rgb(8 51 68 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/80 {
  --tw-shadow-color: rgb(8 51 68 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/85 {
  --tw-shadow-color: rgb(8 51 68 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/90 {
  --tw-shadow-color: rgb(8 51 68 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-cyan-950\/95 {
  --tw-shadow-color: rgb(8 51 68 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100 {
  --tw-shadow-color: #d1fae5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/0 {
  --tw-shadow-color: rgb(209 250 229 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/10 {
  --tw-shadow-color: rgb(209 250 229 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/100 {
  --tw-shadow-color: rgb(209 250 229 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/15 {
  --tw-shadow-color: rgb(209 250 229 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/20 {
  --tw-shadow-color: rgb(209 250 229 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/25 {
  --tw-shadow-color: rgb(209 250 229 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/30 {
  --tw-shadow-color: rgb(209 250 229 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/35 {
  --tw-shadow-color: rgb(209 250 229 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/40 {
  --tw-shadow-color: rgb(209 250 229 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/45 {
  --tw-shadow-color: rgb(209 250 229 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/5 {
  --tw-shadow-color: rgb(209 250 229 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/50 {
  --tw-shadow-color: rgb(209 250 229 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/55 {
  --tw-shadow-color: rgb(209 250 229 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/60 {
  --tw-shadow-color: rgb(209 250 229 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/65 {
  --tw-shadow-color: rgb(209 250 229 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/70 {
  --tw-shadow-color: rgb(209 250 229 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/75 {
  --tw-shadow-color: rgb(209 250 229 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/80 {
  --tw-shadow-color: rgb(209 250 229 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/85 {
  --tw-shadow-color: rgb(209 250 229 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/90 {
  --tw-shadow-color: rgb(209 250 229 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-100\/95 {
  --tw-shadow-color: rgb(209 250 229 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200 {
  --tw-shadow-color: #a7f3d0 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/0 {
  --tw-shadow-color: rgb(167 243 208 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/10 {
  --tw-shadow-color: rgb(167 243 208 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/100 {
  --tw-shadow-color: rgb(167 243 208 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/15 {
  --tw-shadow-color: rgb(167 243 208 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/20 {
  --tw-shadow-color: rgb(167 243 208 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/25 {
  --tw-shadow-color: rgb(167 243 208 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/30 {
  --tw-shadow-color: rgb(167 243 208 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/35 {
  --tw-shadow-color: rgb(167 243 208 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/40 {
  --tw-shadow-color: rgb(167 243 208 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/45 {
  --tw-shadow-color: rgb(167 243 208 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/5 {
  --tw-shadow-color: rgb(167 243 208 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/50 {
  --tw-shadow-color: rgb(167 243 208 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/55 {
  --tw-shadow-color: rgb(167 243 208 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/60 {
  --tw-shadow-color: rgb(167 243 208 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/65 {
  --tw-shadow-color: rgb(167 243 208 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/70 {
  --tw-shadow-color: rgb(167 243 208 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/75 {
  --tw-shadow-color: rgb(167 243 208 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/80 {
  --tw-shadow-color: rgb(167 243 208 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/85 {
  --tw-shadow-color: rgb(167 243 208 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/90 {
  --tw-shadow-color: rgb(167 243 208 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-200\/95 {
  --tw-shadow-color: rgb(167 243 208 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300 {
  --tw-shadow-color: #6ee7b7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/0 {
  --tw-shadow-color: rgb(110 231 183 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/10 {
  --tw-shadow-color: rgb(110 231 183 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/100 {
  --tw-shadow-color: rgb(110 231 183 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/15 {
  --tw-shadow-color: rgb(110 231 183 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/20 {
  --tw-shadow-color: rgb(110 231 183 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/25 {
  --tw-shadow-color: rgb(110 231 183 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/30 {
  --tw-shadow-color: rgb(110 231 183 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/35 {
  --tw-shadow-color: rgb(110 231 183 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/40 {
  --tw-shadow-color: rgb(110 231 183 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/45 {
  --tw-shadow-color: rgb(110 231 183 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/5 {
  --tw-shadow-color: rgb(110 231 183 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/50 {
  --tw-shadow-color: rgb(110 231 183 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/55 {
  --tw-shadow-color: rgb(110 231 183 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/60 {
  --tw-shadow-color: rgb(110 231 183 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/65 {
  --tw-shadow-color: rgb(110 231 183 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/70 {
  --tw-shadow-color: rgb(110 231 183 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/75 {
  --tw-shadow-color: rgb(110 231 183 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/80 {
  --tw-shadow-color: rgb(110 231 183 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/85 {
  --tw-shadow-color: rgb(110 231 183 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/90 {
  --tw-shadow-color: rgb(110 231 183 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-300\/95 {
  --tw-shadow-color: rgb(110 231 183 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400 {
  --tw-shadow-color: #34d399 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/0 {
  --tw-shadow-color: rgb(52 211 153 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/10 {
  --tw-shadow-color: rgb(52 211 153 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/100 {
  --tw-shadow-color: rgb(52 211 153 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/15 {
  --tw-shadow-color: rgb(52 211 153 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/20 {
  --tw-shadow-color: rgb(52 211 153 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/25 {
  --tw-shadow-color: rgb(52 211 153 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/30 {
  --tw-shadow-color: rgb(52 211 153 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/35 {
  --tw-shadow-color: rgb(52 211 153 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/40 {
  --tw-shadow-color: rgb(52 211 153 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/45 {
  --tw-shadow-color: rgb(52 211 153 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/5 {
  --tw-shadow-color: rgb(52 211 153 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/50 {
  --tw-shadow-color: rgb(52 211 153 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/55 {
  --tw-shadow-color: rgb(52 211 153 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/60 {
  --tw-shadow-color: rgb(52 211 153 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/65 {
  --tw-shadow-color: rgb(52 211 153 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/70 {
  --tw-shadow-color: rgb(52 211 153 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/75 {
  --tw-shadow-color: rgb(52 211 153 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/80 {
  --tw-shadow-color: rgb(52 211 153 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/85 {
  --tw-shadow-color: rgb(52 211 153 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/90 {
  --tw-shadow-color: rgb(52 211 153 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-400\/95 {
  --tw-shadow-color: rgb(52 211 153 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50 {
  --tw-shadow-color: #ecfdf5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/0 {
  --tw-shadow-color: rgb(236 253 245 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/10 {
  --tw-shadow-color: rgb(236 253 245 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/100 {
  --tw-shadow-color: rgb(236 253 245 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/15 {
  --tw-shadow-color: rgb(236 253 245 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/20 {
  --tw-shadow-color: rgb(236 253 245 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/25 {
  --tw-shadow-color: rgb(236 253 245 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/30 {
  --tw-shadow-color: rgb(236 253 245 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/35 {
  --tw-shadow-color: rgb(236 253 245 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/40 {
  --tw-shadow-color: rgb(236 253 245 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/45 {
  --tw-shadow-color: rgb(236 253 245 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/5 {
  --tw-shadow-color: rgb(236 253 245 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/50 {
  --tw-shadow-color: rgb(236 253 245 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/55 {
  --tw-shadow-color: rgb(236 253 245 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/60 {
  --tw-shadow-color: rgb(236 253 245 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/65 {
  --tw-shadow-color: rgb(236 253 245 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/70 {
  --tw-shadow-color: rgb(236 253 245 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/75 {
  --tw-shadow-color: rgb(236 253 245 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/80 {
  --tw-shadow-color: rgb(236 253 245 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/85 {
  --tw-shadow-color: rgb(236 253 245 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/90 {
  --tw-shadow-color: rgb(236 253 245 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-50\/95 {
  --tw-shadow-color: rgb(236 253 245 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500 {
  --tw-shadow-color: #10b981 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/0 {
  --tw-shadow-color: rgb(16 185 129 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/10 {
  --tw-shadow-color: rgb(16 185 129 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/100 {
  --tw-shadow-color: rgb(16 185 129 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/15 {
  --tw-shadow-color: rgb(16 185 129 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/20 {
  --tw-shadow-color: rgb(16 185 129 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/25 {
  --tw-shadow-color: rgb(16 185 129 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/30 {
  --tw-shadow-color: rgb(16 185 129 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/35 {
  --tw-shadow-color: rgb(16 185 129 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/40 {
  --tw-shadow-color: rgb(16 185 129 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/45 {
  --tw-shadow-color: rgb(16 185 129 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/5 {
  --tw-shadow-color: rgb(16 185 129 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/50 {
  --tw-shadow-color: rgb(16 185 129 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/55 {
  --tw-shadow-color: rgb(16 185 129 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/60 {
  --tw-shadow-color: rgb(16 185 129 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/65 {
  --tw-shadow-color: rgb(16 185 129 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/70 {
  --tw-shadow-color: rgb(16 185 129 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/75 {
  --tw-shadow-color: rgb(16 185 129 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/80 {
  --tw-shadow-color: rgb(16 185 129 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/85 {
  --tw-shadow-color: rgb(16 185 129 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/90 {
  --tw-shadow-color: rgb(16 185 129 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-500\/95 {
  --tw-shadow-color: rgb(16 185 129 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600 {
  --tw-shadow-color: #059669 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/0 {
  --tw-shadow-color: rgb(5 150 105 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/10 {
  --tw-shadow-color: rgb(5 150 105 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/100 {
  --tw-shadow-color: rgb(5 150 105 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/15 {
  --tw-shadow-color: rgb(5 150 105 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/20 {
  --tw-shadow-color: rgb(5 150 105 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/25 {
  --tw-shadow-color: rgb(5 150 105 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/30 {
  --tw-shadow-color: rgb(5 150 105 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/35 {
  --tw-shadow-color: rgb(5 150 105 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/40 {
  --tw-shadow-color: rgb(5 150 105 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/45 {
  --tw-shadow-color: rgb(5 150 105 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/5 {
  --tw-shadow-color: rgb(5 150 105 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/50 {
  --tw-shadow-color: rgb(5 150 105 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/55 {
  --tw-shadow-color: rgb(5 150 105 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/60 {
  --tw-shadow-color: rgb(5 150 105 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/65 {
  --tw-shadow-color: rgb(5 150 105 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/70 {
  --tw-shadow-color: rgb(5 150 105 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/75 {
  --tw-shadow-color: rgb(5 150 105 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/80 {
  --tw-shadow-color: rgb(5 150 105 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/85 {
  --tw-shadow-color: rgb(5 150 105 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/90 {
  --tw-shadow-color: rgb(5 150 105 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-600\/95 {
  --tw-shadow-color: rgb(5 150 105 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700 {
  --tw-shadow-color: #047857 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/0 {
  --tw-shadow-color: rgb(4 120 87 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/10 {
  --tw-shadow-color: rgb(4 120 87 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/100 {
  --tw-shadow-color: rgb(4 120 87 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/15 {
  --tw-shadow-color: rgb(4 120 87 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/20 {
  --tw-shadow-color: rgb(4 120 87 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/25 {
  --tw-shadow-color: rgb(4 120 87 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/30 {
  --tw-shadow-color: rgb(4 120 87 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/35 {
  --tw-shadow-color: rgb(4 120 87 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/40 {
  --tw-shadow-color: rgb(4 120 87 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/45 {
  --tw-shadow-color: rgb(4 120 87 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/5 {
  --tw-shadow-color: rgb(4 120 87 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/50 {
  --tw-shadow-color: rgb(4 120 87 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/55 {
  --tw-shadow-color: rgb(4 120 87 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/60 {
  --tw-shadow-color: rgb(4 120 87 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/65 {
  --tw-shadow-color: rgb(4 120 87 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/70 {
  --tw-shadow-color: rgb(4 120 87 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/75 {
  --tw-shadow-color: rgb(4 120 87 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/80 {
  --tw-shadow-color: rgb(4 120 87 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/85 {
  --tw-shadow-color: rgb(4 120 87 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/90 {
  --tw-shadow-color: rgb(4 120 87 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-700\/95 {
  --tw-shadow-color: rgb(4 120 87 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800 {
  --tw-shadow-color: #065f46 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/0 {
  --tw-shadow-color: rgb(6 95 70 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/10 {
  --tw-shadow-color: rgb(6 95 70 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/100 {
  --tw-shadow-color: rgb(6 95 70 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/15 {
  --tw-shadow-color: rgb(6 95 70 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/20 {
  --tw-shadow-color: rgb(6 95 70 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/25 {
  --tw-shadow-color: rgb(6 95 70 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/30 {
  --tw-shadow-color: rgb(6 95 70 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/35 {
  --tw-shadow-color: rgb(6 95 70 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/40 {
  --tw-shadow-color: rgb(6 95 70 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/45 {
  --tw-shadow-color: rgb(6 95 70 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/5 {
  --tw-shadow-color: rgb(6 95 70 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/50 {
  --tw-shadow-color: rgb(6 95 70 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/55 {
  --tw-shadow-color: rgb(6 95 70 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/60 {
  --tw-shadow-color: rgb(6 95 70 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/65 {
  --tw-shadow-color: rgb(6 95 70 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/70 {
  --tw-shadow-color: rgb(6 95 70 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/75 {
  --tw-shadow-color: rgb(6 95 70 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/80 {
  --tw-shadow-color: rgb(6 95 70 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/85 {
  --tw-shadow-color: rgb(6 95 70 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/90 {
  --tw-shadow-color: rgb(6 95 70 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-800\/95 {
  --tw-shadow-color: rgb(6 95 70 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900 {
  --tw-shadow-color: #064e3b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/0 {
  --tw-shadow-color: rgb(6 78 59 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/10 {
  --tw-shadow-color: rgb(6 78 59 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/100 {
  --tw-shadow-color: rgb(6 78 59 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/15 {
  --tw-shadow-color: rgb(6 78 59 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/20 {
  --tw-shadow-color: rgb(6 78 59 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/25 {
  --tw-shadow-color: rgb(6 78 59 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/30 {
  --tw-shadow-color: rgb(6 78 59 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/35 {
  --tw-shadow-color: rgb(6 78 59 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/40 {
  --tw-shadow-color: rgb(6 78 59 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/45 {
  --tw-shadow-color: rgb(6 78 59 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/5 {
  --tw-shadow-color: rgb(6 78 59 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/50 {
  --tw-shadow-color: rgb(6 78 59 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/55 {
  --tw-shadow-color: rgb(6 78 59 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/60 {
  --tw-shadow-color: rgb(6 78 59 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/65 {
  --tw-shadow-color: rgb(6 78 59 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/70 {
  --tw-shadow-color: rgb(6 78 59 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/75 {
  --tw-shadow-color: rgb(6 78 59 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/80 {
  --tw-shadow-color: rgb(6 78 59 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/85 {
  --tw-shadow-color: rgb(6 78 59 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/90 {
  --tw-shadow-color: rgb(6 78 59 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-900\/95 {
  --tw-shadow-color: rgb(6 78 59 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950 {
  --tw-shadow-color: #022c22 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/0 {
  --tw-shadow-color: rgb(2 44 34 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/10 {
  --tw-shadow-color: rgb(2 44 34 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/100 {
  --tw-shadow-color: rgb(2 44 34 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/15 {
  --tw-shadow-color: rgb(2 44 34 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/20 {
  --tw-shadow-color: rgb(2 44 34 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/25 {
  --tw-shadow-color: rgb(2 44 34 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/30 {
  --tw-shadow-color: rgb(2 44 34 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/35 {
  --tw-shadow-color: rgb(2 44 34 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/40 {
  --tw-shadow-color: rgb(2 44 34 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/45 {
  --tw-shadow-color: rgb(2 44 34 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/5 {
  --tw-shadow-color: rgb(2 44 34 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/50 {
  --tw-shadow-color: rgb(2 44 34 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/55 {
  --tw-shadow-color: rgb(2 44 34 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/60 {
  --tw-shadow-color: rgb(2 44 34 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/65 {
  --tw-shadow-color: rgb(2 44 34 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/70 {
  --tw-shadow-color: rgb(2 44 34 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/75 {
  --tw-shadow-color: rgb(2 44 34 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/80 {
  --tw-shadow-color: rgb(2 44 34 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/85 {
  --tw-shadow-color: rgb(2 44 34 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/90 {
  --tw-shadow-color: rgb(2 44 34 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-emerald-950\/95 {
  --tw-shadow-color: rgb(2 44 34 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100 {
  --tw-shadow-color: #fae8ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/0 {
  --tw-shadow-color: rgb(250 232 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/10 {
  --tw-shadow-color: rgb(250 232 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/100 {
  --tw-shadow-color: rgb(250 232 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/15 {
  --tw-shadow-color: rgb(250 232 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/20 {
  --tw-shadow-color: rgb(250 232 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/25 {
  --tw-shadow-color: rgb(250 232 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/30 {
  --tw-shadow-color: rgb(250 232 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/35 {
  --tw-shadow-color: rgb(250 232 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/40 {
  --tw-shadow-color: rgb(250 232 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/45 {
  --tw-shadow-color: rgb(250 232 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/5 {
  --tw-shadow-color: rgb(250 232 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/50 {
  --tw-shadow-color: rgb(250 232 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/55 {
  --tw-shadow-color: rgb(250 232 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/60 {
  --tw-shadow-color: rgb(250 232 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/65 {
  --tw-shadow-color: rgb(250 232 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/70 {
  --tw-shadow-color: rgb(250 232 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/75 {
  --tw-shadow-color: rgb(250 232 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/80 {
  --tw-shadow-color: rgb(250 232 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/85 {
  --tw-shadow-color: rgb(250 232 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/90 {
  --tw-shadow-color: rgb(250 232 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-100\/95 {
  --tw-shadow-color: rgb(250 232 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200 {
  --tw-shadow-color: #f5d0fe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/0 {
  --tw-shadow-color: rgb(245 208 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/10 {
  --tw-shadow-color: rgb(245 208 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/100 {
  --tw-shadow-color: rgb(245 208 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/15 {
  --tw-shadow-color: rgb(245 208 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/20 {
  --tw-shadow-color: rgb(245 208 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/25 {
  --tw-shadow-color: rgb(245 208 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/30 {
  --tw-shadow-color: rgb(245 208 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/35 {
  --tw-shadow-color: rgb(245 208 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/40 {
  --tw-shadow-color: rgb(245 208 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/45 {
  --tw-shadow-color: rgb(245 208 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/5 {
  --tw-shadow-color: rgb(245 208 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/50 {
  --tw-shadow-color: rgb(245 208 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/55 {
  --tw-shadow-color: rgb(245 208 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/60 {
  --tw-shadow-color: rgb(245 208 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/65 {
  --tw-shadow-color: rgb(245 208 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/70 {
  --tw-shadow-color: rgb(245 208 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/75 {
  --tw-shadow-color: rgb(245 208 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/80 {
  --tw-shadow-color: rgb(245 208 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/85 {
  --tw-shadow-color: rgb(245 208 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/90 {
  --tw-shadow-color: rgb(245 208 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-200\/95 {
  --tw-shadow-color: rgb(245 208 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300 {
  --tw-shadow-color: #f0abfc !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/0 {
  --tw-shadow-color: rgb(240 171 252 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/10 {
  --tw-shadow-color: rgb(240 171 252 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/100 {
  --tw-shadow-color: rgb(240 171 252 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/15 {
  --tw-shadow-color: rgb(240 171 252 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/20 {
  --tw-shadow-color: rgb(240 171 252 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/25 {
  --tw-shadow-color: rgb(240 171 252 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/30 {
  --tw-shadow-color: rgb(240 171 252 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/35 {
  --tw-shadow-color: rgb(240 171 252 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/40 {
  --tw-shadow-color: rgb(240 171 252 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/45 {
  --tw-shadow-color: rgb(240 171 252 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/5 {
  --tw-shadow-color: rgb(240 171 252 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/50 {
  --tw-shadow-color: rgb(240 171 252 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/55 {
  --tw-shadow-color: rgb(240 171 252 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/60 {
  --tw-shadow-color: rgb(240 171 252 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/65 {
  --tw-shadow-color: rgb(240 171 252 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/70 {
  --tw-shadow-color: rgb(240 171 252 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/75 {
  --tw-shadow-color: rgb(240 171 252 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/80 {
  --tw-shadow-color: rgb(240 171 252 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/85 {
  --tw-shadow-color: rgb(240 171 252 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/90 {
  --tw-shadow-color: rgb(240 171 252 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-300\/95 {
  --tw-shadow-color: rgb(240 171 252 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400 {
  --tw-shadow-color: #e879f9 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/0 {
  --tw-shadow-color: rgb(232 121 249 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/10 {
  --tw-shadow-color: rgb(232 121 249 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/100 {
  --tw-shadow-color: rgb(232 121 249 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/15 {
  --tw-shadow-color: rgb(232 121 249 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/20 {
  --tw-shadow-color: rgb(232 121 249 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/25 {
  --tw-shadow-color: rgb(232 121 249 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/30 {
  --tw-shadow-color: rgb(232 121 249 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/35 {
  --tw-shadow-color: rgb(232 121 249 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/40 {
  --tw-shadow-color: rgb(232 121 249 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/45 {
  --tw-shadow-color: rgb(232 121 249 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/5 {
  --tw-shadow-color: rgb(232 121 249 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/50 {
  --tw-shadow-color: rgb(232 121 249 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/55 {
  --tw-shadow-color: rgb(232 121 249 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/60 {
  --tw-shadow-color: rgb(232 121 249 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/65 {
  --tw-shadow-color: rgb(232 121 249 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/70 {
  --tw-shadow-color: rgb(232 121 249 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/75 {
  --tw-shadow-color: rgb(232 121 249 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/80 {
  --tw-shadow-color: rgb(232 121 249 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/85 {
  --tw-shadow-color: rgb(232 121 249 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/90 {
  --tw-shadow-color: rgb(232 121 249 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-400\/95 {
  --tw-shadow-color: rgb(232 121 249 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50 {
  --tw-shadow-color: #fdf4ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/0 {
  --tw-shadow-color: rgb(253 244 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/10 {
  --tw-shadow-color: rgb(253 244 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/100 {
  --tw-shadow-color: rgb(253 244 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/15 {
  --tw-shadow-color: rgb(253 244 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/20 {
  --tw-shadow-color: rgb(253 244 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/25 {
  --tw-shadow-color: rgb(253 244 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/30 {
  --tw-shadow-color: rgb(253 244 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/35 {
  --tw-shadow-color: rgb(253 244 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/40 {
  --tw-shadow-color: rgb(253 244 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/45 {
  --tw-shadow-color: rgb(253 244 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/5 {
  --tw-shadow-color: rgb(253 244 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/50 {
  --tw-shadow-color: rgb(253 244 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/55 {
  --tw-shadow-color: rgb(253 244 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/60 {
  --tw-shadow-color: rgb(253 244 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/65 {
  --tw-shadow-color: rgb(253 244 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/70 {
  --tw-shadow-color: rgb(253 244 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/75 {
  --tw-shadow-color: rgb(253 244 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/80 {
  --tw-shadow-color: rgb(253 244 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/85 {
  --tw-shadow-color: rgb(253 244 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/90 {
  --tw-shadow-color: rgb(253 244 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-50\/95 {
  --tw-shadow-color: rgb(253 244 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500 {
  --tw-shadow-color: #d946ef !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/0 {
  --tw-shadow-color: rgb(217 70 239 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/10 {
  --tw-shadow-color: rgb(217 70 239 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/100 {
  --tw-shadow-color: rgb(217 70 239 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/15 {
  --tw-shadow-color: rgb(217 70 239 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/20 {
  --tw-shadow-color: rgb(217 70 239 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/25 {
  --tw-shadow-color: rgb(217 70 239 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/30 {
  --tw-shadow-color: rgb(217 70 239 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/35 {
  --tw-shadow-color: rgb(217 70 239 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/40 {
  --tw-shadow-color: rgb(217 70 239 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/45 {
  --tw-shadow-color: rgb(217 70 239 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/5 {
  --tw-shadow-color: rgb(217 70 239 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/50 {
  --tw-shadow-color: rgb(217 70 239 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/55 {
  --tw-shadow-color: rgb(217 70 239 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/60 {
  --tw-shadow-color: rgb(217 70 239 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/65 {
  --tw-shadow-color: rgb(217 70 239 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/70 {
  --tw-shadow-color: rgb(217 70 239 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/75 {
  --tw-shadow-color: rgb(217 70 239 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/80 {
  --tw-shadow-color: rgb(217 70 239 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/85 {
  --tw-shadow-color: rgb(217 70 239 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/90 {
  --tw-shadow-color: rgb(217 70 239 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-500\/95 {
  --tw-shadow-color: rgb(217 70 239 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600 {
  --tw-shadow-color: #c026d3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/0 {
  --tw-shadow-color: rgb(192 38 211 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/10 {
  --tw-shadow-color: rgb(192 38 211 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/100 {
  --tw-shadow-color: rgb(192 38 211 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/15 {
  --tw-shadow-color: rgb(192 38 211 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/20 {
  --tw-shadow-color: rgb(192 38 211 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/25 {
  --tw-shadow-color: rgb(192 38 211 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/30 {
  --tw-shadow-color: rgb(192 38 211 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/35 {
  --tw-shadow-color: rgb(192 38 211 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/40 {
  --tw-shadow-color: rgb(192 38 211 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/45 {
  --tw-shadow-color: rgb(192 38 211 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/5 {
  --tw-shadow-color: rgb(192 38 211 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/50 {
  --tw-shadow-color: rgb(192 38 211 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/55 {
  --tw-shadow-color: rgb(192 38 211 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/60 {
  --tw-shadow-color: rgb(192 38 211 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/65 {
  --tw-shadow-color: rgb(192 38 211 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/70 {
  --tw-shadow-color: rgb(192 38 211 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/75 {
  --tw-shadow-color: rgb(192 38 211 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/80 {
  --tw-shadow-color: rgb(192 38 211 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/85 {
  --tw-shadow-color: rgb(192 38 211 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/90 {
  --tw-shadow-color: rgb(192 38 211 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-600\/95 {
  --tw-shadow-color: rgb(192 38 211 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700 {
  --tw-shadow-color: #a21caf !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/0 {
  --tw-shadow-color: rgb(162 28 175 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/10 {
  --tw-shadow-color: rgb(162 28 175 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/100 {
  --tw-shadow-color: rgb(162 28 175 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/15 {
  --tw-shadow-color: rgb(162 28 175 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/20 {
  --tw-shadow-color: rgb(162 28 175 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/25 {
  --tw-shadow-color: rgb(162 28 175 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/30 {
  --tw-shadow-color: rgb(162 28 175 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/35 {
  --tw-shadow-color: rgb(162 28 175 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/40 {
  --tw-shadow-color: rgb(162 28 175 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/45 {
  --tw-shadow-color: rgb(162 28 175 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/5 {
  --tw-shadow-color: rgb(162 28 175 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/50 {
  --tw-shadow-color: rgb(162 28 175 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/55 {
  --tw-shadow-color: rgb(162 28 175 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/60 {
  --tw-shadow-color: rgb(162 28 175 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/65 {
  --tw-shadow-color: rgb(162 28 175 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/70 {
  --tw-shadow-color: rgb(162 28 175 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/75 {
  --tw-shadow-color: rgb(162 28 175 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/80 {
  --tw-shadow-color: rgb(162 28 175 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/85 {
  --tw-shadow-color: rgb(162 28 175 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/90 {
  --tw-shadow-color: rgb(162 28 175 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-700\/95 {
  --tw-shadow-color: rgb(162 28 175 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800 {
  --tw-shadow-color: #86198f !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/0 {
  --tw-shadow-color: rgb(134 25 143 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/10 {
  --tw-shadow-color: rgb(134 25 143 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/100 {
  --tw-shadow-color: rgb(134 25 143 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/15 {
  --tw-shadow-color: rgb(134 25 143 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/20 {
  --tw-shadow-color: rgb(134 25 143 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/25 {
  --tw-shadow-color: rgb(134 25 143 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/30 {
  --tw-shadow-color: rgb(134 25 143 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/35 {
  --tw-shadow-color: rgb(134 25 143 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/40 {
  --tw-shadow-color: rgb(134 25 143 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/45 {
  --tw-shadow-color: rgb(134 25 143 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/5 {
  --tw-shadow-color: rgb(134 25 143 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/50 {
  --tw-shadow-color: rgb(134 25 143 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/55 {
  --tw-shadow-color: rgb(134 25 143 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/60 {
  --tw-shadow-color: rgb(134 25 143 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/65 {
  --tw-shadow-color: rgb(134 25 143 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/70 {
  --tw-shadow-color: rgb(134 25 143 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/75 {
  --tw-shadow-color: rgb(134 25 143 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/80 {
  --tw-shadow-color: rgb(134 25 143 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/85 {
  --tw-shadow-color: rgb(134 25 143 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/90 {
  --tw-shadow-color: rgb(134 25 143 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-800\/95 {
  --tw-shadow-color: rgb(134 25 143 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900 {
  --tw-shadow-color: #701a75 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/0 {
  --tw-shadow-color: rgb(112 26 117 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/10 {
  --tw-shadow-color: rgb(112 26 117 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/100 {
  --tw-shadow-color: rgb(112 26 117 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/15 {
  --tw-shadow-color: rgb(112 26 117 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/20 {
  --tw-shadow-color: rgb(112 26 117 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/25 {
  --tw-shadow-color: rgb(112 26 117 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/30 {
  --tw-shadow-color: rgb(112 26 117 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/35 {
  --tw-shadow-color: rgb(112 26 117 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/40 {
  --tw-shadow-color: rgb(112 26 117 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/45 {
  --tw-shadow-color: rgb(112 26 117 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/5 {
  --tw-shadow-color: rgb(112 26 117 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/50 {
  --tw-shadow-color: rgb(112 26 117 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/55 {
  --tw-shadow-color: rgb(112 26 117 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/60 {
  --tw-shadow-color: rgb(112 26 117 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/65 {
  --tw-shadow-color: rgb(112 26 117 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/70 {
  --tw-shadow-color: rgb(112 26 117 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/75 {
  --tw-shadow-color: rgb(112 26 117 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/80 {
  --tw-shadow-color: rgb(112 26 117 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/85 {
  --tw-shadow-color: rgb(112 26 117 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/90 {
  --tw-shadow-color: rgb(112 26 117 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-900\/95 {
  --tw-shadow-color: rgb(112 26 117 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950 {
  --tw-shadow-color: #4a044e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/0 {
  --tw-shadow-color: rgb(74 4 78 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/10 {
  --tw-shadow-color: rgb(74 4 78 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/100 {
  --tw-shadow-color: rgb(74 4 78 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/15 {
  --tw-shadow-color: rgb(74 4 78 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/20 {
  --tw-shadow-color: rgb(74 4 78 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/25 {
  --tw-shadow-color: rgb(74 4 78 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/30 {
  --tw-shadow-color: rgb(74 4 78 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/35 {
  --tw-shadow-color: rgb(74 4 78 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/40 {
  --tw-shadow-color: rgb(74 4 78 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/45 {
  --tw-shadow-color: rgb(74 4 78 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/5 {
  --tw-shadow-color: rgb(74 4 78 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/50 {
  --tw-shadow-color: rgb(74 4 78 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/55 {
  --tw-shadow-color: rgb(74 4 78 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/60 {
  --tw-shadow-color: rgb(74 4 78 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/65 {
  --tw-shadow-color: rgb(74 4 78 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/70 {
  --tw-shadow-color: rgb(74 4 78 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/75 {
  --tw-shadow-color: rgb(74 4 78 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/80 {
  --tw-shadow-color: rgb(74 4 78 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/85 {
  --tw-shadow-color: rgb(74 4 78 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/90 {
  --tw-shadow-color: rgb(74 4 78 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-fuchsia-950\/95 {
  --tw-shadow-color: rgb(74 4 78 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100 {
  --tw-shadow-color: #f3f4f6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/0 {
  --tw-shadow-color: rgb(243 244 246 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/10 {
  --tw-shadow-color: rgb(243 244 246 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/100 {
  --tw-shadow-color: rgb(243 244 246 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/15 {
  --tw-shadow-color: rgb(243 244 246 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/20 {
  --tw-shadow-color: rgb(243 244 246 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/25 {
  --tw-shadow-color: rgb(243 244 246 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/30 {
  --tw-shadow-color: rgb(243 244 246 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/35 {
  --tw-shadow-color: rgb(243 244 246 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/40 {
  --tw-shadow-color: rgb(243 244 246 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/45 {
  --tw-shadow-color: rgb(243 244 246 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/5 {
  --tw-shadow-color: rgb(243 244 246 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/50 {
  --tw-shadow-color: rgb(243 244 246 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/55 {
  --tw-shadow-color: rgb(243 244 246 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/60 {
  --tw-shadow-color: rgb(243 244 246 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/65 {
  --tw-shadow-color: rgb(243 244 246 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/70 {
  --tw-shadow-color: rgb(243 244 246 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/75 {
  --tw-shadow-color: rgb(243 244 246 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/80 {
  --tw-shadow-color: rgb(243 244 246 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/85 {
  --tw-shadow-color: rgb(243 244 246 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/90 {
  --tw-shadow-color: rgb(243 244 246 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-100\/95 {
  --tw-shadow-color: rgb(243 244 246 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200 {
  --tw-shadow-color: #e5e7eb !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/0 {
  --tw-shadow-color: rgb(229 231 235 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/10 {
  --tw-shadow-color: rgb(229 231 235 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/100 {
  --tw-shadow-color: rgb(229 231 235 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/15 {
  --tw-shadow-color: rgb(229 231 235 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/20 {
  --tw-shadow-color: rgb(229 231 235 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/25 {
  --tw-shadow-color: rgb(229 231 235 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/30 {
  --tw-shadow-color: rgb(229 231 235 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/35 {
  --tw-shadow-color: rgb(229 231 235 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/40 {
  --tw-shadow-color: rgb(229 231 235 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/45 {
  --tw-shadow-color: rgb(229 231 235 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/5 {
  --tw-shadow-color: rgb(229 231 235 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/50 {
  --tw-shadow-color: rgb(229 231 235 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/55 {
  --tw-shadow-color: rgb(229 231 235 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/60 {
  --tw-shadow-color: rgb(229 231 235 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/65 {
  --tw-shadow-color: rgb(229 231 235 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/70 {
  --tw-shadow-color: rgb(229 231 235 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/75 {
  --tw-shadow-color: rgb(229 231 235 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/80 {
  --tw-shadow-color: rgb(229 231 235 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/85 {
  --tw-shadow-color: rgb(229 231 235 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/90 {
  --tw-shadow-color: rgb(229 231 235 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-200\/95 {
  --tw-shadow-color: rgb(229 231 235 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300 {
  --tw-shadow-color: #d1d5db !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/0 {
  --tw-shadow-color: rgb(209 213 219 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/10 {
  --tw-shadow-color: rgb(209 213 219 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/100 {
  --tw-shadow-color: rgb(209 213 219 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/15 {
  --tw-shadow-color: rgb(209 213 219 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/20 {
  --tw-shadow-color: rgb(209 213 219 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/25 {
  --tw-shadow-color: rgb(209 213 219 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/30 {
  --tw-shadow-color: rgb(209 213 219 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/35 {
  --tw-shadow-color: rgb(209 213 219 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/40 {
  --tw-shadow-color: rgb(209 213 219 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/45 {
  --tw-shadow-color: rgb(209 213 219 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/5 {
  --tw-shadow-color: rgb(209 213 219 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/50 {
  --tw-shadow-color: rgb(209 213 219 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/55 {
  --tw-shadow-color: rgb(209 213 219 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/60 {
  --tw-shadow-color: rgb(209 213 219 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/65 {
  --tw-shadow-color: rgb(209 213 219 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/70 {
  --tw-shadow-color: rgb(209 213 219 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/75 {
  --tw-shadow-color: rgb(209 213 219 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/80 {
  --tw-shadow-color: rgb(209 213 219 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/85 {
  --tw-shadow-color: rgb(209 213 219 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/90 {
  --tw-shadow-color: rgb(209 213 219 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-300\/95 {
  --tw-shadow-color: rgb(209 213 219 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400 {
  --tw-shadow-color: #9ca3af !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/0 {
  --tw-shadow-color: rgb(156 163 175 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/10 {
  --tw-shadow-color: rgb(156 163 175 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/100 {
  --tw-shadow-color: rgb(156 163 175 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/15 {
  --tw-shadow-color: rgb(156 163 175 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/20 {
  --tw-shadow-color: rgb(156 163 175 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/25 {
  --tw-shadow-color: rgb(156 163 175 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/30 {
  --tw-shadow-color: rgb(156 163 175 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/35 {
  --tw-shadow-color: rgb(156 163 175 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/40 {
  --tw-shadow-color: rgb(156 163 175 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/45 {
  --tw-shadow-color: rgb(156 163 175 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/5 {
  --tw-shadow-color: rgb(156 163 175 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/50 {
  --tw-shadow-color: rgb(156 163 175 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/55 {
  --tw-shadow-color: rgb(156 163 175 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/60 {
  --tw-shadow-color: rgb(156 163 175 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/65 {
  --tw-shadow-color: rgb(156 163 175 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/70 {
  --tw-shadow-color: rgb(156 163 175 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/75 {
  --tw-shadow-color: rgb(156 163 175 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/80 {
  --tw-shadow-color: rgb(156 163 175 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/85 {
  --tw-shadow-color: rgb(156 163 175 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/90 {
  --tw-shadow-color: rgb(156 163 175 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-400\/95 {
  --tw-shadow-color: rgb(156 163 175 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50 {
  --tw-shadow-color: #f9fafb !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/0 {
  --tw-shadow-color: rgb(249 250 251 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/10 {
  --tw-shadow-color: rgb(249 250 251 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/100 {
  --tw-shadow-color: rgb(249 250 251 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/15 {
  --tw-shadow-color: rgb(249 250 251 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/20 {
  --tw-shadow-color: rgb(249 250 251 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/25 {
  --tw-shadow-color: rgb(249 250 251 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/30 {
  --tw-shadow-color: rgb(249 250 251 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/35 {
  --tw-shadow-color: rgb(249 250 251 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/40 {
  --tw-shadow-color: rgb(249 250 251 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/45 {
  --tw-shadow-color: rgb(249 250 251 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/5 {
  --tw-shadow-color: rgb(249 250 251 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/50 {
  --tw-shadow-color: rgb(249 250 251 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/55 {
  --tw-shadow-color: rgb(249 250 251 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/60 {
  --tw-shadow-color: rgb(249 250 251 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/65 {
  --tw-shadow-color: rgb(249 250 251 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/70 {
  --tw-shadow-color: rgb(249 250 251 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/75 {
  --tw-shadow-color: rgb(249 250 251 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/80 {
  --tw-shadow-color: rgb(249 250 251 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/85 {
  --tw-shadow-color: rgb(249 250 251 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/90 {
  --tw-shadow-color: rgb(249 250 251 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-50\/95 {
  --tw-shadow-color: rgb(249 250 251 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500 {
  --tw-shadow-color: #6b7280 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/0 {
  --tw-shadow-color: rgb(107 114 128 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/10 {
  --tw-shadow-color: rgb(107 114 128 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/100 {
  --tw-shadow-color: rgb(107 114 128 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/15 {
  --tw-shadow-color: rgb(107 114 128 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/20 {
  --tw-shadow-color: rgb(107 114 128 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/25 {
  --tw-shadow-color: rgb(107 114 128 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/30 {
  --tw-shadow-color: rgb(107 114 128 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/35 {
  --tw-shadow-color: rgb(107 114 128 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/40 {
  --tw-shadow-color: rgb(107 114 128 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/45 {
  --tw-shadow-color: rgb(107 114 128 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/5 {
  --tw-shadow-color: rgb(107 114 128 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/50 {
  --tw-shadow-color: rgb(107 114 128 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/55 {
  --tw-shadow-color: rgb(107 114 128 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/60 {
  --tw-shadow-color: rgb(107 114 128 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/65 {
  --tw-shadow-color: rgb(107 114 128 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/70 {
  --tw-shadow-color: rgb(107 114 128 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/75 {
  --tw-shadow-color: rgb(107 114 128 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/80 {
  --tw-shadow-color: rgb(107 114 128 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/85 {
  --tw-shadow-color: rgb(107 114 128 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/90 {
  --tw-shadow-color: rgb(107 114 128 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-500\/95 {
  --tw-shadow-color: rgb(107 114 128 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600 {
  --tw-shadow-color: #4b5563 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/0 {
  --tw-shadow-color: rgb(75 85 99 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/10 {
  --tw-shadow-color: rgb(75 85 99 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/100 {
  --tw-shadow-color: rgb(75 85 99 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/15 {
  --tw-shadow-color: rgb(75 85 99 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/20 {
  --tw-shadow-color: rgb(75 85 99 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/25 {
  --tw-shadow-color: rgb(75 85 99 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/30 {
  --tw-shadow-color: rgb(75 85 99 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/35 {
  --tw-shadow-color: rgb(75 85 99 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/40 {
  --tw-shadow-color: rgb(75 85 99 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/45 {
  --tw-shadow-color: rgb(75 85 99 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/5 {
  --tw-shadow-color: rgb(75 85 99 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/50 {
  --tw-shadow-color: rgb(75 85 99 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/55 {
  --tw-shadow-color: rgb(75 85 99 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/60 {
  --tw-shadow-color: rgb(75 85 99 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/65 {
  --tw-shadow-color: rgb(75 85 99 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/70 {
  --tw-shadow-color: rgb(75 85 99 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/75 {
  --tw-shadow-color: rgb(75 85 99 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/80 {
  --tw-shadow-color: rgb(75 85 99 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/85 {
  --tw-shadow-color: rgb(75 85 99 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/90 {
  --tw-shadow-color: rgb(75 85 99 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-600\/95 {
  --tw-shadow-color: rgb(75 85 99 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700 {
  --tw-shadow-color: #374151 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/0 {
  --tw-shadow-color: rgb(55 65 81 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/10 {
  --tw-shadow-color: rgb(55 65 81 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/100 {
  --tw-shadow-color: rgb(55 65 81 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/15 {
  --tw-shadow-color: rgb(55 65 81 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/20 {
  --tw-shadow-color: rgb(55 65 81 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/25 {
  --tw-shadow-color: rgb(55 65 81 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/30 {
  --tw-shadow-color: rgb(55 65 81 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/35 {
  --tw-shadow-color: rgb(55 65 81 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/40 {
  --tw-shadow-color: rgb(55 65 81 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/45 {
  --tw-shadow-color: rgb(55 65 81 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/5 {
  --tw-shadow-color: rgb(55 65 81 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/50 {
  --tw-shadow-color: rgb(55 65 81 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/55 {
  --tw-shadow-color: rgb(55 65 81 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/60 {
  --tw-shadow-color: rgb(55 65 81 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/65 {
  --tw-shadow-color: rgb(55 65 81 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/70 {
  --tw-shadow-color: rgb(55 65 81 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/75 {
  --tw-shadow-color: rgb(55 65 81 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/80 {
  --tw-shadow-color: rgb(55 65 81 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/85 {
  --tw-shadow-color: rgb(55 65 81 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/90 {
  --tw-shadow-color: rgb(55 65 81 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-700\/95 {
  --tw-shadow-color: rgb(55 65 81 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800 {
  --tw-shadow-color: #1f2937 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/0 {
  --tw-shadow-color: rgb(31 41 55 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/10 {
  --tw-shadow-color: rgb(31 41 55 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/100 {
  --tw-shadow-color: rgb(31 41 55 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/15 {
  --tw-shadow-color: rgb(31 41 55 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/20 {
  --tw-shadow-color: rgb(31 41 55 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/25 {
  --tw-shadow-color: rgb(31 41 55 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/30 {
  --tw-shadow-color: rgb(31 41 55 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/35 {
  --tw-shadow-color: rgb(31 41 55 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/40 {
  --tw-shadow-color: rgb(31 41 55 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/45 {
  --tw-shadow-color: rgb(31 41 55 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/5 {
  --tw-shadow-color: rgb(31 41 55 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/50 {
  --tw-shadow-color: rgb(31 41 55 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/55 {
  --tw-shadow-color: rgb(31 41 55 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/60 {
  --tw-shadow-color: rgb(31 41 55 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/65 {
  --tw-shadow-color: rgb(31 41 55 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/70 {
  --tw-shadow-color: rgb(31 41 55 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/75 {
  --tw-shadow-color: rgb(31 41 55 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/80 {
  --tw-shadow-color: rgb(31 41 55 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/85 {
  --tw-shadow-color: rgb(31 41 55 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/90 {
  --tw-shadow-color: rgb(31 41 55 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-800\/95 {
  --tw-shadow-color: rgb(31 41 55 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900 {
  --tw-shadow-color: #111827 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/0 {
  --tw-shadow-color: rgb(17 24 39 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/10 {
  --tw-shadow-color: rgb(17 24 39 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/100 {
  --tw-shadow-color: rgb(17 24 39 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/15 {
  --tw-shadow-color: rgb(17 24 39 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/20 {
  --tw-shadow-color: rgb(17 24 39 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/25 {
  --tw-shadow-color: rgb(17 24 39 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/30 {
  --tw-shadow-color: rgb(17 24 39 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/35 {
  --tw-shadow-color: rgb(17 24 39 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/40 {
  --tw-shadow-color: rgb(17 24 39 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/45 {
  --tw-shadow-color: rgb(17 24 39 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/5 {
  --tw-shadow-color: rgb(17 24 39 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/50 {
  --tw-shadow-color: rgb(17 24 39 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/55 {
  --tw-shadow-color: rgb(17 24 39 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/60 {
  --tw-shadow-color: rgb(17 24 39 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/65 {
  --tw-shadow-color: rgb(17 24 39 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/70 {
  --tw-shadow-color: rgb(17 24 39 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/75 {
  --tw-shadow-color: rgb(17 24 39 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/80 {
  --tw-shadow-color: rgb(17 24 39 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/85 {
  --tw-shadow-color: rgb(17 24 39 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/90 {
  --tw-shadow-color: rgb(17 24 39 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-900\/95 {
  --tw-shadow-color: rgb(17 24 39 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950 {
  --tw-shadow-color: #030712 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/0 {
  --tw-shadow-color: rgb(3 7 18 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/10 {
  --tw-shadow-color: rgb(3 7 18 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/100 {
  --tw-shadow-color: rgb(3 7 18 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/15 {
  --tw-shadow-color: rgb(3 7 18 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/20 {
  --tw-shadow-color: rgb(3 7 18 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/25 {
  --tw-shadow-color: rgb(3 7 18 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/30 {
  --tw-shadow-color: rgb(3 7 18 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/35 {
  --tw-shadow-color: rgb(3 7 18 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/40 {
  --tw-shadow-color: rgb(3 7 18 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/45 {
  --tw-shadow-color: rgb(3 7 18 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/5 {
  --tw-shadow-color: rgb(3 7 18 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/50 {
  --tw-shadow-color: rgb(3 7 18 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/55 {
  --tw-shadow-color: rgb(3 7 18 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/60 {
  --tw-shadow-color: rgb(3 7 18 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/65 {
  --tw-shadow-color: rgb(3 7 18 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/70 {
  --tw-shadow-color: rgb(3 7 18 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/75 {
  --tw-shadow-color: rgb(3 7 18 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/80 {
  --tw-shadow-color: rgb(3 7 18 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/85 {
  --tw-shadow-color: rgb(3 7 18 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/90 {
  --tw-shadow-color: rgb(3 7 18 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-gray-950\/95 {
  --tw-shadow-color: rgb(3 7 18 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100 {
  --tw-shadow-color: #dcfce7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/0 {
  --tw-shadow-color: rgb(220 252 231 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/10 {
  --tw-shadow-color: rgb(220 252 231 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/100 {
  --tw-shadow-color: rgb(220 252 231 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/15 {
  --tw-shadow-color: rgb(220 252 231 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/20 {
  --tw-shadow-color: rgb(220 252 231 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/25 {
  --tw-shadow-color: rgb(220 252 231 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/30 {
  --tw-shadow-color: rgb(220 252 231 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/35 {
  --tw-shadow-color: rgb(220 252 231 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/40 {
  --tw-shadow-color: rgb(220 252 231 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/45 {
  --tw-shadow-color: rgb(220 252 231 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/5 {
  --tw-shadow-color: rgb(220 252 231 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/50 {
  --tw-shadow-color: rgb(220 252 231 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/55 {
  --tw-shadow-color: rgb(220 252 231 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/60 {
  --tw-shadow-color: rgb(220 252 231 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/65 {
  --tw-shadow-color: rgb(220 252 231 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/70 {
  --tw-shadow-color: rgb(220 252 231 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/75 {
  --tw-shadow-color: rgb(220 252 231 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/80 {
  --tw-shadow-color: rgb(220 252 231 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/85 {
  --tw-shadow-color: rgb(220 252 231 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/90 {
  --tw-shadow-color: rgb(220 252 231 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-100\/95 {
  --tw-shadow-color: rgb(220 252 231 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200 {
  --tw-shadow-color: #bbf7d0 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/0 {
  --tw-shadow-color: rgb(187 247 208 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/10 {
  --tw-shadow-color: rgb(187 247 208 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/100 {
  --tw-shadow-color: rgb(187 247 208 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/15 {
  --tw-shadow-color: rgb(187 247 208 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/20 {
  --tw-shadow-color: rgb(187 247 208 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/25 {
  --tw-shadow-color: rgb(187 247 208 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/30 {
  --tw-shadow-color: rgb(187 247 208 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/35 {
  --tw-shadow-color: rgb(187 247 208 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/40 {
  --tw-shadow-color: rgb(187 247 208 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/45 {
  --tw-shadow-color: rgb(187 247 208 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/5 {
  --tw-shadow-color: rgb(187 247 208 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/50 {
  --tw-shadow-color: rgb(187 247 208 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/55 {
  --tw-shadow-color: rgb(187 247 208 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/60 {
  --tw-shadow-color: rgb(187 247 208 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/65 {
  --tw-shadow-color: rgb(187 247 208 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/70 {
  --tw-shadow-color: rgb(187 247 208 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/75 {
  --tw-shadow-color: rgb(187 247 208 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/80 {
  --tw-shadow-color: rgb(187 247 208 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/85 {
  --tw-shadow-color: rgb(187 247 208 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/90 {
  --tw-shadow-color: rgb(187 247 208 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-200\/95 {
  --tw-shadow-color: rgb(187 247 208 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300 {
  --tw-shadow-color: #86efac !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/0 {
  --tw-shadow-color: rgb(134 239 172 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/10 {
  --tw-shadow-color: rgb(134 239 172 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/100 {
  --tw-shadow-color: rgb(134 239 172 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/15 {
  --tw-shadow-color: rgb(134 239 172 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/20 {
  --tw-shadow-color: rgb(134 239 172 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/25 {
  --tw-shadow-color: rgb(134 239 172 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/30 {
  --tw-shadow-color: rgb(134 239 172 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/35 {
  --tw-shadow-color: rgb(134 239 172 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/40 {
  --tw-shadow-color: rgb(134 239 172 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/45 {
  --tw-shadow-color: rgb(134 239 172 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/5 {
  --tw-shadow-color: rgb(134 239 172 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/50 {
  --tw-shadow-color: rgb(134 239 172 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/55 {
  --tw-shadow-color: rgb(134 239 172 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/60 {
  --tw-shadow-color: rgb(134 239 172 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/65 {
  --tw-shadow-color: rgb(134 239 172 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/70 {
  --tw-shadow-color: rgb(134 239 172 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/75 {
  --tw-shadow-color: rgb(134 239 172 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/80 {
  --tw-shadow-color: rgb(134 239 172 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/85 {
  --tw-shadow-color: rgb(134 239 172 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/90 {
  --tw-shadow-color: rgb(134 239 172 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-300\/95 {
  --tw-shadow-color: rgb(134 239 172 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400 {
  --tw-shadow-color: #4ade80 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/0 {
  --tw-shadow-color: rgb(74 222 128 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/10 {
  --tw-shadow-color: rgb(74 222 128 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/100 {
  --tw-shadow-color: rgb(74 222 128 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/15 {
  --tw-shadow-color: rgb(74 222 128 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/20 {
  --tw-shadow-color: rgb(74 222 128 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/25 {
  --tw-shadow-color: rgb(74 222 128 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/30 {
  --tw-shadow-color: rgb(74 222 128 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/35 {
  --tw-shadow-color: rgb(74 222 128 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/40 {
  --tw-shadow-color: rgb(74 222 128 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/45 {
  --tw-shadow-color: rgb(74 222 128 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/5 {
  --tw-shadow-color: rgb(74 222 128 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/50 {
  --tw-shadow-color: rgb(74 222 128 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/55 {
  --tw-shadow-color: rgb(74 222 128 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/60 {
  --tw-shadow-color: rgb(74 222 128 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/65 {
  --tw-shadow-color: rgb(74 222 128 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/70 {
  --tw-shadow-color: rgb(74 222 128 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/75 {
  --tw-shadow-color: rgb(74 222 128 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/80 {
  --tw-shadow-color: rgb(74 222 128 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/85 {
  --tw-shadow-color: rgb(74 222 128 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/90 {
  --tw-shadow-color: rgb(74 222 128 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-400\/95 {
  --tw-shadow-color: rgb(74 222 128 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50 {
  --tw-shadow-color: #f0fdf4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/0 {
  --tw-shadow-color: rgb(240 253 244 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/10 {
  --tw-shadow-color: rgb(240 253 244 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/100 {
  --tw-shadow-color: rgb(240 253 244 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/15 {
  --tw-shadow-color: rgb(240 253 244 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/20 {
  --tw-shadow-color: rgb(240 253 244 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/25 {
  --tw-shadow-color: rgb(240 253 244 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/30 {
  --tw-shadow-color: rgb(240 253 244 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/35 {
  --tw-shadow-color: rgb(240 253 244 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/40 {
  --tw-shadow-color: rgb(240 253 244 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/45 {
  --tw-shadow-color: rgb(240 253 244 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/5 {
  --tw-shadow-color: rgb(240 253 244 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/50 {
  --tw-shadow-color: rgb(240 253 244 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/55 {
  --tw-shadow-color: rgb(240 253 244 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/60 {
  --tw-shadow-color: rgb(240 253 244 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/65 {
  --tw-shadow-color: rgb(240 253 244 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/70 {
  --tw-shadow-color: rgb(240 253 244 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/75 {
  --tw-shadow-color: rgb(240 253 244 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/80 {
  --tw-shadow-color: rgb(240 253 244 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/85 {
  --tw-shadow-color: rgb(240 253 244 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/90 {
  --tw-shadow-color: rgb(240 253 244 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-50\/95 {
  --tw-shadow-color: rgb(240 253 244 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500 {
  --tw-shadow-color: #22c55e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/0 {
  --tw-shadow-color: rgb(34 197 94 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/10 {
  --tw-shadow-color: rgb(34 197 94 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/100 {
  --tw-shadow-color: rgb(34 197 94 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/15 {
  --tw-shadow-color: rgb(34 197 94 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/20 {
  --tw-shadow-color: rgb(34 197 94 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/25 {
  --tw-shadow-color: rgb(34 197 94 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/30 {
  --tw-shadow-color: rgb(34 197 94 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/35 {
  --tw-shadow-color: rgb(34 197 94 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/40 {
  --tw-shadow-color: rgb(34 197 94 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/45 {
  --tw-shadow-color: rgb(34 197 94 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/5 {
  --tw-shadow-color: rgb(34 197 94 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/50 {
  --tw-shadow-color: rgb(34 197 94 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/55 {
  --tw-shadow-color: rgb(34 197 94 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/60 {
  --tw-shadow-color: rgb(34 197 94 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/65 {
  --tw-shadow-color: rgb(34 197 94 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/70 {
  --tw-shadow-color: rgb(34 197 94 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/75 {
  --tw-shadow-color: rgb(34 197 94 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/80 {
  --tw-shadow-color: rgb(34 197 94 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/85 {
  --tw-shadow-color: rgb(34 197 94 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/90 {
  --tw-shadow-color: rgb(34 197 94 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-500\/95 {
  --tw-shadow-color: rgb(34 197 94 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600 {
  --tw-shadow-color: #16a34a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/0 {
  --tw-shadow-color: rgb(22 163 74 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/10 {
  --tw-shadow-color: rgb(22 163 74 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/100 {
  --tw-shadow-color: rgb(22 163 74 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/15 {
  --tw-shadow-color: rgb(22 163 74 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/20 {
  --tw-shadow-color: rgb(22 163 74 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/25 {
  --tw-shadow-color: rgb(22 163 74 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/30 {
  --tw-shadow-color: rgb(22 163 74 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/35 {
  --tw-shadow-color: rgb(22 163 74 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/40 {
  --tw-shadow-color: rgb(22 163 74 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/45 {
  --tw-shadow-color: rgb(22 163 74 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/5 {
  --tw-shadow-color: rgb(22 163 74 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/50 {
  --tw-shadow-color: rgb(22 163 74 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/55 {
  --tw-shadow-color: rgb(22 163 74 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/60 {
  --tw-shadow-color: rgb(22 163 74 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/65 {
  --tw-shadow-color: rgb(22 163 74 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/70 {
  --tw-shadow-color: rgb(22 163 74 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/75 {
  --tw-shadow-color: rgb(22 163 74 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/80 {
  --tw-shadow-color: rgb(22 163 74 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/85 {
  --tw-shadow-color: rgb(22 163 74 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/90 {
  --tw-shadow-color: rgb(22 163 74 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-600\/95 {
  --tw-shadow-color: rgb(22 163 74 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700 {
  --tw-shadow-color: #15803d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/0 {
  --tw-shadow-color: rgb(21 128 61 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/10 {
  --tw-shadow-color: rgb(21 128 61 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/100 {
  --tw-shadow-color: rgb(21 128 61 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/15 {
  --tw-shadow-color: rgb(21 128 61 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/20 {
  --tw-shadow-color: rgb(21 128 61 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/25 {
  --tw-shadow-color: rgb(21 128 61 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/30 {
  --tw-shadow-color: rgb(21 128 61 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/35 {
  --tw-shadow-color: rgb(21 128 61 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/40 {
  --tw-shadow-color: rgb(21 128 61 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/45 {
  --tw-shadow-color: rgb(21 128 61 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/5 {
  --tw-shadow-color: rgb(21 128 61 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/50 {
  --tw-shadow-color: rgb(21 128 61 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/55 {
  --tw-shadow-color: rgb(21 128 61 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/60 {
  --tw-shadow-color: rgb(21 128 61 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/65 {
  --tw-shadow-color: rgb(21 128 61 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/70 {
  --tw-shadow-color: rgb(21 128 61 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/75 {
  --tw-shadow-color: rgb(21 128 61 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/80 {
  --tw-shadow-color: rgb(21 128 61 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/85 {
  --tw-shadow-color: rgb(21 128 61 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/90 {
  --tw-shadow-color: rgb(21 128 61 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-700\/95 {
  --tw-shadow-color: rgb(21 128 61 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800 {
  --tw-shadow-color: #166534 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/0 {
  --tw-shadow-color: rgb(22 101 52 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/10 {
  --tw-shadow-color: rgb(22 101 52 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/100 {
  --tw-shadow-color: rgb(22 101 52 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/15 {
  --tw-shadow-color: rgb(22 101 52 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/20 {
  --tw-shadow-color: rgb(22 101 52 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/25 {
  --tw-shadow-color: rgb(22 101 52 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/30 {
  --tw-shadow-color: rgb(22 101 52 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/35 {
  --tw-shadow-color: rgb(22 101 52 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/40 {
  --tw-shadow-color: rgb(22 101 52 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/45 {
  --tw-shadow-color: rgb(22 101 52 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/5 {
  --tw-shadow-color: rgb(22 101 52 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/50 {
  --tw-shadow-color: rgb(22 101 52 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/55 {
  --tw-shadow-color: rgb(22 101 52 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/60 {
  --tw-shadow-color: rgb(22 101 52 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/65 {
  --tw-shadow-color: rgb(22 101 52 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/70 {
  --tw-shadow-color: rgb(22 101 52 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/75 {
  --tw-shadow-color: rgb(22 101 52 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/80 {
  --tw-shadow-color: rgb(22 101 52 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/85 {
  --tw-shadow-color: rgb(22 101 52 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/90 {
  --tw-shadow-color: rgb(22 101 52 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-800\/95 {
  --tw-shadow-color: rgb(22 101 52 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900 {
  --tw-shadow-color: #14532d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/0 {
  --tw-shadow-color: rgb(20 83 45 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/10 {
  --tw-shadow-color: rgb(20 83 45 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/100 {
  --tw-shadow-color: rgb(20 83 45 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/15 {
  --tw-shadow-color: rgb(20 83 45 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/20 {
  --tw-shadow-color: rgb(20 83 45 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/25 {
  --tw-shadow-color: rgb(20 83 45 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/30 {
  --tw-shadow-color: rgb(20 83 45 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/35 {
  --tw-shadow-color: rgb(20 83 45 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/40 {
  --tw-shadow-color: rgb(20 83 45 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/45 {
  --tw-shadow-color: rgb(20 83 45 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/5 {
  --tw-shadow-color: rgb(20 83 45 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/50 {
  --tw-shadow-color: rgb(20 83 45 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/55 {
  --tw-shadow-color: rgb(20 83 45 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/60 {
  --tw-shadow-color: rgb(20 83 45 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/65 {
  --tw-shadow-color: rgb(20 83 45 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/70 {
  --tw-shadow-color: rgb(20 83 45 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/75 {
  --tw-shadow-color: rgb(20 83 45 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/80 {
  --tw-shadow-color: rgb(20 83 45 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/85 {
  --tw-shadow-color: rgb(20 83 45 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/90 {
  --tw-shadow-color: rgb(20 83 45 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-900\/95 {
  --tw-shadow-color: rgb(20 83 45 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950 {
  --tw-shadow-color: #052e16 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/0 {
  --tw-shadow-color: rgb(5 46 22 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/10 {
  --tw-shadow-color: rgb(5 46 22 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/100 {
  --tw-shadow-color: rgb(5 46 22 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/15 {
  --tw-shadow-color: rgb(5 46 22 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/20 {
  --tw-shadow-color: rgb(5 46 22 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/25 {
  --tw-shadow-color: rgb(5 46 22 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/30 {
  --tw-shadow-color: rgb(5 46 22 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/35 {
  --tw-shadow-color: rgb(5 46 22 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/40 {
  --tw-shadow-color: rgb(5 46 22 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/45 {
  --tw-shadow-color: rgb(5 46 22 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/5 {
  --tw-shadow-color: rgb(5 46 22 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/50 {
  --tw-shadow-color: rgb(5 46 22 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/55 {
  --tw-shadow-color: rgb(5 46 22 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/60 {
  --tw-shadow-color: rgb(5 46 22 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/65 {
  --tw-shadow-color: rgb(5 46 22 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/70 {
  --tw-shadow-color: rgb(5 46 22 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/75 {
  --tw-shadow-color: rgb(5 46 22 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/80 {
  --tw-shadow-color: rgb(5 46 22 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/85 {
  --tw-shadow-color: rgb(5 46 22 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/90 {
  --tw-shadow-color: rgb(5 46 22 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-green-950\/95 {
  --tw-shadow-color: rgb(5 46 22 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100 {
  --tw-shadow-color: #e0e7ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/0 {
  --tw-shadow-color: rgb(224 231 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/10 {
  --tw-shadow-color: rgb(224 231 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/100 {
  --tw-shadow-color: rgb(224 231 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/15 {
  --tw-shadow-color: rgb(224 231 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/20 {
  --tw-shadow-color: rgb(224 231 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/25 {
  --tw-shadow-color: rgb(224 231 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/30 {
  --tw-shadow-color: rgb(224 231 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/35 {
  --tw-shadow-color: rgb(224 231 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/40 {
  --tw-shadow-color: rgb(224 231 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/45 {
  --tw-shadow-color: rgb(224 231 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/5 {
  --tw-shadow-color: rgb(224 231 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/50 {
  --tw-shadow-color: rgb(224 231 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/55 {
  --tw-shadow-color: rgb(224 231 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/60 {
  --tw-shadow-color: rgb(224 231 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/65 {
  --tw-shadow-color: rgb(224 231 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/70 {
  --tw-shadow-color: rgb(224 231 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/75 {
  --tw-shadow-color: rgb(224 231 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/80 {
  --tw-shadow-color: rgb(224 231 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/85 {
  --tw-shadow-color: rgb(224 231 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/90 {
  --tw-shadow-color: rgb(224 231 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-100\/95 {
  --tw-shadow-color: rgb(224 231 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200 {
  --tw-shadow-color: #c7d2fe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/0 {
  --tw-shadow-color: rgb(199 210 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/10 {
  --tw-shadow-color: rgb(199 210 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/100 {
  --tw-shadow-color: rgb(199 210 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/15 {
  --tw-shadow-color: rgb(199 210 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/20 {
  --tw-shadow-color: rgb(199 210 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/25 {
  --tw-shadow-color: rgb(199 210 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/30 {
  --tw-shadow-color: rgb(199 210 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/35 {
  --tw-shadow-color: rgb(199 210 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/40 {
  --tw-shadow-color: rgb(199 210 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/45 {
  --tw-shadow-color: rgb(199 210 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/5 {
  --tw-shadow-color: rgb(199 210 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/50 {
  --tw-shadow-color: rgb(199 210 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/55 {
  --tw-shadow-color: rgb(199 210 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/60 {
  --tw-shadow-color: rgb(199 210 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/65 {
  --tw-shadow-color: rgb(199 210 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/70 {
  --tw-shadow-color: rgb(199 210 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/75 {
  --tw-shadow-color: rgb(199 210 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/80 {
  --tw-shadow-color: rgb(199 210 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/85 {
  --tw-shadow-color: rgb(199 210 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/90 {
  --tw-shadow-color: rgb(199 210 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-200\/95 {
  --tw-shadow-color: rgb(199 210 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300 {
  --tw-shadow-color: #a5b4fc !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/0 {
  --tw-shadow-color: rgb(165 180 252 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/10 {
  --tw-shadow-color: rgb(165 180 252 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/100 {
  --tw-shadow-color: rgb(165 180 252 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/15 {
  --tw-shadow-color: rgb(165 180 252 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/20 {
  --tw-shadow-color: rgb(165 180 252 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/25 {
  --tw-shadow-color: rgb(165 180 252 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/30 {
  --tw-shadow-color: rgb(165 180 252 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/35 {
  --tw-shadow-color: rgb(165 180 252 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/40 {
  --tw-shadow-color: rgb(165 180 252 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/45 {
  --tw-shadow-color: rgb(165 180 252 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/5 {
  --tw-shadow-color: rgb(165 180 252 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/50 {
  --tw-shadow-color: rgb(165 180 252 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/55 {
  --tw-shadow-color: rgb(165 180 252 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/60 {
  --tw-shadow-color: rgb(165 180 252 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/65 {
  --tw-shadow-color: rgb(165 180 252 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/70 {
  --tw-shadow-color: rgb(165 180 252 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/75 {
  --tw-shadow-color: rgb(165 180 252 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/80 {
  --tw-shadow-color: rgb(165 180 252 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/85 {
  --tw-shadow-color: rgb(165 180 252 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/90 {
  --tw-shadow-color: rgb(165 180 252 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-300\/95 {
  --tw-shadow-color: rgb(165 180 252 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400 {
  --tw-shadow-color: #818cf8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/0 {
  --tw-shadow-color: rgb(129 140 248 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/10 {
  --tw-shadow-color: rgb(129 140 248 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/100 {
  --tw-shadow-color: rgb(129 140 248 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/15 {
  --tw-shadow-color: rgb(129 140 248 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/20 {
  --tw-shadow-color: rgb(129 140 248 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/25 {
  --tw-shadow-color: rgb(129 140 248 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/30 {
  --tw-shadow-color: rgb(129 140 248 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/35 {
  --tw-shadow-color: rgb(129 140 248 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/40 {
  --tw-shadow-color: rgb(129 140 248 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/45 {
  --tw-shadow-color: rgb(129 140 248 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/5 {
  --tw-shadow-color: rgb(129 140 248 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/50 {
  --tw-shadow-color: rgb(129 140 248 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/55 {
  --tw-shadow-color: rgb(129 140 248 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/60 {
  --tw-shadow-color: rgb(129 140 248 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/65 {
  --tw-shadow-color: rgb(129 140 248 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/70 {
  --tw-shadow-color: rgb(129 140 248 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/75 {
  --tw-shadow-color: rgb(129 140 248 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/80 {
  --tw-shadow-color: rgb(129 140 248 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/85 {
  --tw-shadow-color: rgb(129 140 248 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/90 {
  --tw-shadow-color: rgb(129 140 248 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-400\/95 {
  --tw-shadow-color: rgb(129 140 248 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50 {
  --tw-shadow-color: #eef2ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/0 {
  --tw-shadow-color: rgb(238 242 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/10 {
  --tw-shadow-color: rgb(238 242 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/100 {
  --tw-shadow-color: rgb(238 242 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/15 {
  --tw-shadow-color: rgb(238 242 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/20 {
  --tw-shadow-color: rgb(238 242 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/25 {
  --tw-shadow-color: rgb(238 242 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/30 {
  --tw-shadow-color: rgb(238 242 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/35 {
  --tw-shadow-color: rgb(238 242 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/40 {
  --tw-shadow-color: rgb(238 242 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/45 {
  --tw-shadow-color: rgb(238 242 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/5 {
  --tw-shadow-color: rgb(238 242 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/50 {
  --tw-shadow-color: rgb(238 242 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/55 {
  --tw-shadow-color: rgb(238 242 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/60 {
  --tw-shadow-color: rgb(238 242 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/65 {
  --tw-shadow-color: rgb(238 242 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/70 {
  --tw-shadow-color: rgb(238 242 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/75 {
  --tw-shadow-color: rgb(238 242 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/80 {
  --tw-shadow-color: rgb(238 242 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/85 {
  --tw-shadow-color: rgb(238 242 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/90 {
  --tw-shadow-color: rgb(238 242 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-50\/95 {
  --tw-shadow-color: rgb(238 242 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500 {
  --tw-shadow-color: #6366f1 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/0 {
  --tw-shadow-color: rgb(99 102 241 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/10 {
  --tw-shadow-color: rgb(99 102 241 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/100 {
  --tw-shadow-color: rgb(99 102 241 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/15 {
  --tw-shadow-color: rgb(99 102 241 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/20 {
  --tw-shadow-color: rgb(99 102 241 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/25 {
  --tw-shadow-color: rgb(99 102 241 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/30 {
  --tw-shadow-color: rgb(99 102 241 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/35 {
  --tw-shadow-color: rgb(99 102 241 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/40 {
  --tw-shadow-color: rgb(99 102 241 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/45 {
  --tw-shadow-color: rgb(99 102 241 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/5 {
  --tw-shadow-color: rgb(99 102 241 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/50 {
  --tw-shadow-color: rgb(99 102 241 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/55 {
  --tw-shadow-color: rgb(99 102 241 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/60 {
  --tw-shadow-color: rgb(99 102 241 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/65 {
  --tw-shadow-color: rgb(99 102 241 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/70 {
  --tw-shadow-color: rgb(99 102 241 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/75 {
  --tw-shadow-color: rgb(99 102 241 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/80 {
  --tw-shadow-color: rgb(99 102 241 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/85 {
  --tw-shadow-color: rgb(99 102 241 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/90 {
  --tw-shadow-color: rgb(99 102 241 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-500\/95 {
  --tw-shadow-color: rgb(99 102 241 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600 {
  --tw-shadow-color: #4f46e5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/0 {
  --tw-shadow-color: rgb(79 70 229 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/10 {
  --tw-shadow-color: rgb(79 70 229 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/100 {
  --tw-shadow-color: rgb(79 70 229 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/15 {
  --tw-shadow-color: rgb(79 70 229 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/20 {
  --tw-shadow-color: rgb(79 70 229 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/25 {
  --tw-shadow-color: rgb(79 70 229 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/30 {
  --tw-shadow-color: rgb(79 70 229 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/35 {
  --tw-shadow-color: rgb(79 70 229 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/40 {
  --tw-shadow-color: rgb(79 70 229 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/45 {
  --tw-shadow-color: rgb(79 70 229 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/5 {
  --tw-shadow-color: rgb(79 70 229 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/50 {
  --tw-shadow-color: rgb(79 70 229 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/55 {
  --tw-shadow-color: rgb(79 70 229 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/60 {
  --tw-shadow-color: rgb(79 70 229 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/65 {
  --tw-shadow-color: rgb(79 70 229 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/70 {
  --tw-shadow-color: rgb(79 70 229 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/75 {
  --tw-shadow-color: rgb(79 70 229 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/80 {
  --tw-shadow-color: rgb(79 70 229 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/85 {
  --tw-shadow-color: rgb(79 70 229 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/90 {
  --tw-shadow-color: rgb(79 70 229 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-600\/95 {
  --tw-shadow-color: rgb(79 70 229 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700 {
  --tw-shadow-color: #4338ca !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/0 {
  --tw-shadow-color: rgb(67 56 202 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/10 {
  --tw-shadow-color: rgb(67 56 202 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/100 {
  --tw-shadow-color: rgb(67 56 202 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/15 {
  --tw-shadow-color: rgb(67 56 202 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/20 {
  --tw-shadow-color: rgb(67 56 202 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/25 {
  --tw-shadow-color: rgb(67 56 202 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/30 {
  --tw-shadow-color: rgb(67 56 202 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/35 {
  --tw-shadow-color: rgb(67 56 202 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/40 {
  --tw-shadow-color: rgb(67 56 202 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/45 {
  --tw-shadow-color: rgb(67 56 202 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/5 {
  --tw-shadow-color: rgb(67 56 202 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/50 {
  --tw-shadow-color: rgb(67 56 202 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/55 {
  --tw-shadow-color: rgb(67 56 202 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/60 {
  --tw-shadow-color: rgb(67 56 202 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/65 {
  --tw-shadow-color: rgb(67 56 202 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/70 {
  --tw-shadow-color: rgb(67 56 202 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/75 {
  --tw-shadow-color: rgb(67 56 202 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/80 {
  --tw-shadow-color: rgb(67 56 202 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/85 {
  --tw-shadow-color: rgb(67 56 202 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/90 {
  --tw-shadow-color: rgb(67 56 202 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-700\/95 {
  --tw-shadow-color: rgb(67 56 202 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800 {
  --tw-shadow-color: #3730a3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/0 {
  --tw-shadow-color: rgb(55 48 163 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/10 {
  --tw-shadow-color: rgb(55 48 163 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/100 {
  --tw-shadow-color: rgb(55 48 163 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/15 {
  --tw-shadow-color: rgb(55 48 163 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/20 {
  --tw-shadow-color: rgb(55 48 163 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/25 {
  --tw-shadow-color: rgb(55 48 163 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/30 {
  --tw-shadow-color: rgb(55 48 163 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/35 {
  --tw-shadow-color: rgb(55 48 163 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/40 {
  --tw-shadow-color: rgb(55 48 163 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/45 {
  --tw-shadow-color: rgb(55 48 163 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/5 {
  --tw-shadow-color: rgb(55 48 163 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/50 {
  --tw-shadow-color: rgb(55 48 163 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/55 {
  --tw-shadow-color: rgb(55 48 163 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/60 {
  --tw-shadow-color: rgb(55 48 163 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/65 {
  --tw-shadow-color: rgb(55 48 163 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/70 {
  --tw-shadow-color: rgb(55 48 163 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/75 {
  --tw-shadow-color: rgb(55 48 163 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/80 {
  --tw-shadow-color: rgb(55 48 163 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/85 {
  --tw-shadow-color: rgb(55 48 163 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/90 {
  --tw-shadow-color: rgb(55 48 163 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-800\/95 {
  --tw-shadow-color: rgb(55 48 163 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900 {
  --tw-shadow-color: #312e81 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/0 {
  --tw-shadow-color: rgb(49 46 129 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/10 {
  --tw-shadow-color: rgb(49 46 129 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/100 {
  --tw-shadow-color: rgb(49 46 129 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/15 {
  --tw-shadow-color: rgb(49 46 129 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/20 {
  --tw-shadow-color: rgb(49 46 129 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/25 {
  --tw-shadow-color: rgb(49 46 129 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/30 {
  --tw-shadow-color: rgb(49 46 129 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/35 {
  --tw-shadow-color: rgb(49 46 129 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/40 {
  --tw-shadow-color: rgb(49 46 129 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/45 {
  --tw-shadow-color: rgb(49 46 129 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/5 {
  --tw-shadow-color: rgb(49 46 129 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/50 {
  --tw-shadow-color: rgb(49 46 129 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/55 {
  --tw-shadow-color: rgb(49 46 129 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/60 {
  --tw-shadow-color: rgb(49 46 129 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/65 {
  --tw-shadow-color: rgb(49 46 129 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/70 {
  --tw-shadow-color: rgb(49 46 129 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/75 {
  --tw-shadow-color: rgb(49 46 129 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/80 {
  --tw-shadow-color: rgb(49 46 129 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/85 {
  --tw-shadow-color: rgb(49 46 129 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/90 {
  --tw-shadow-color: rgb(49 46 129 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-900\/95 {
  --tw-shadow-color: rgb(49 46 129 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950 {
  --tw-shadow-color: #1e1b4b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/0 {
  --tw-shadow-color: rgb(30 27 75 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/10 {
  --tw-shadow-color: rgb(30 27 75 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/100 {
  --tw-shadow-color: rgb(30 27 75 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/15 {
  --tw-shadow-color: rgb(30 27 75 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/20 {
  --tw-shadow-color: rgb(30 27 75 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/25 {
  --tw-shadow-color: rgb(30 27 75 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/30 {
  --tw-shadow-color: rgb(30 27 75 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/35 {
  --tw-shadow-color: rgb(30 27 75 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/40 {
  --tw-shadow-color: rgb(30 27 75 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/45 {
  --tw-shadow-color: rgb(30 27 75 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/5 {
  --tw-shadow-color: rgb(30 27 75 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/50 {
  --tw-shadow-color: rgb(30 27 75 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/55 {
  --tw-shadow-color: rgb(30 27 75 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/60 {
  --tw-shadow-color: rgb(30 27 75 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/65 {
  --tw-shadow-color: rgb(30 27 75 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/70 {
  --tw-shadow-color: rgb(30 27 75 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/75 {
  --tw-shadow-color: rgb(30 27 75 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/80 {
  --tw-shadow-color: rgb(30 27 75 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/85 {
  --tw-shadow-color: rgb(30 27 75 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/90 {
  --tw-shadow-color: rgb(30 27 75 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-indigo-950\/95 {
  --tw-shadow-color: rgb(30 27 75 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-inherit {
  --tw-shadow-color: inherit !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100 {
  --tw-shadow-color: #ecfccb !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/0 {
  --tw-shadow-color: rgb(236 252 203 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/10 {
  --tw-shadow-color: rgb(236 252 203 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/100 {
  --tw-shadow-color: rgb(236 252 203 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/15 {
  --tw-shadow-color: rgb(236 252 203 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/20 {
  --tw-shadow-color: rgb(236 252 203 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/25 {
  --tw-shadow-color: rgb(236 252 203 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/30 {
  --tw-shadow-color: rgb(236 252 203 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/35 {
  --tw-shadow-color: rgb(236 252 203 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/40 {
  --tw-shadow-color: rgb(236 252 203 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/45 {
  --tw-shadow-color: rgb(236 252 203 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/5 {
  --tw-shadow-color: rgb(236 252 203 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/50 {
  --tw-shadow-color: rgb(236 252 203 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/55 {
  --tw-shadow-color: rgb(236 252 203 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/60 {
  --tw-shadow-color: rgb(236 252 203 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/65 {
  --tw-shadow-color: rgb(236 252 203 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/70 {
  --tw-shadow-color: rgb(236 252 203 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/75 {
  --tw-shadow-color: rgb(236 252 203 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/80 {
  --tw-shadow-color: rgb(236 252 203 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/85 {
  --tw-shadow-color: rgb(236 252 203 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/90 {
  --tw-shadow-color: rgb(236 252 203 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-100\/95 {
  --tw-shadow-color: rgb(236 252 203 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200 {
  --tw-shadow-color: #d9f99d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/0 {
  --tw-shadow-color: rgb(217 249 157 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/10 {
  --tw-shadow-color: rgb(217 249 157 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/100 {
  --tw-shadow-color: rgb(217 249 157 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/15 {
  --tw-shadow-color: rgb(217 249 157 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/20 {
  --tw-shadow-color: rgb(217 249 157 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/25 {
  --tw-shadow-color: rgb(217 249 157 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/30 {
  --tw-shadow-color: rgb(217 249 157 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/35 {
  --tw-shadow-color: rgb(217 249 157 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/40 {
  --tw-shadow-color: rgb(217 249 157 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/45 {
  --tw-shadow-color: rgb(217 249 157 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/5 {
  --tw-shadow-color: rgb(217 249 157 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/50 {
  --tw-shadow-color: rgb(217 249 157 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/55 {
  --tw-shadow-color: rgb(217 249 157 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/60 {
  --tw-shadow-color: rgb(217 249 157 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/65 {
  --tw-shadow-color: rgb(217 249 157 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/70 {
  --tw-shadow-color: rgb(217 249 157 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/75 {
  --tw-shadow-color: rgb(217 249 157 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/80 {
  --tw-shadow-color: rgb(217 249 157 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/85 {
  --tw-shadow-color: rgb(217 249 157 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/90 {
  --tw-shadow-color: rgb(217 249 157 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-200\/95 {
  --tw-shadow-color: rgb(217 249 157 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300 {
  --tw-shadow-color: #bef264 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/0 {
  --tw-shadow-color: rgb(190 242 100 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/10 {
  --tw-shadow-color: rgb(190 242 100 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/100 {
  --tw-shadow-color: rgb(190 242 100 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/15 {
  --tw-shadow-color: rgb(190 242 100 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/20 {
  --tw-shadow-color: rgb(190 242 100 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/25 {
  --tw-shadow-color: rgb(190 242 100 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/30 {
  --tw-shadow-color: rgb(190 242 100 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/35 {
  --tw-shadow-color: rgb(190 242 100 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/40 {
  --tw-shadow-color: rgb(190 242 100 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/45 {
  --tw-shadow-color: rgb(190 242 100 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/5 {
  --tw-shadow-color: rgb(190 242 100 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/50 {
  --tw-shadow-color: rgb(190 242 100 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/55 {
  --tw-shadow-color: rgb(190 242 100 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/60 {
  --tw-shadow-color: rgb(190 242 100 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/65 {
  --tw-shadow-color: rgb(190 242 100 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/70 {
  --tw-shadow-color: rgb(190 242 100 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/75 {
  --tw-shadow-color: rgb(190 242 100 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/80 {
  --tw-shadow-color: rgb(190 242 100 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/85 {
  --tw-shadow-color: rgb(190 242 100 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/90 {
  --tw-shadow-color: rgb(190 242 100 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-300\/95 {
  --tw-shadow-color: rgb(190 242 100 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400 {
  --tw-shadow-color: #a3e635 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/0 {
  --tw-shadow-color: rgb(163 230 53 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/10 {
  --tw-shadow-color: rgb(163 230 53 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/100 {
  --tw-shadow-color: rgb(163 230 53 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/15 {
  --tw-shadow-color: rgb(163 230 53 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/20 {
  --tw-shadow-color: rgb(163 230 53 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/25 {
  --tw-shadow-color: rgb(163 230 53 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/30 {
  --tw-shadow-color: rgb(163 230 53 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/35 {
  --tw-shadow-color: rgb(163 230 53 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/40 {
  --tw-shadow-color: rgb(163 230 53 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/45 {
  --tw-shadow-color: rgb(163 230 53 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/5 {
  --tw-shadow-color: rgb(163 230 53 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/50 {
  --tw-shadow-color: rgb(163 230 53 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/55 {
  --tw-shadow-color: rgb(163 230 53 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/60 {
  --tw-shadow-color: rgb(163 230 53 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/65 {
  --tw-shadow-color: rgb(163 230 53 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/70 {
  --tw-shadow-color: rgb(163 230 53 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/75 {
  --tw-shadow-color: rgb(163 230 53 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/80 {
  --tw-shadow-color: rgb(163 230 53 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/85 {
  --tw-shadow-color: rgb(163 230 53 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/90 {
  --tw-shadow-color: rgb(163 230 53 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-400\/95 {
  --tw-shadow-color: rgb(163 230 53 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50 {
  --tw-shadow-color: #f7fee7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/0 {
  --tw-shadow-color: rgb(247 254 231 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/10 {
  --tw-shadow-color: rgb(247 254 231 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/100 {
  --tw-shadow-color: rgb(247 254 231 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/15 {
  --tw-shadow-color: rgb(247 254 231 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/20 {
  --tw-shadow-color: rgb(247 254 231 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/25 {
  --tw-shadow-color: rgb(247 254 231 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/30 {
  --tw-shadow-color: rgb(247 254 231 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/35 {
  --tw-shadow-color: rgb(247 254 231 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/40 {
  --tw-shadow-color: rgb(247 254 231 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/45 {
  --tw-shadow-color: rgb(247 254 231 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/5 {
  --tw-shadow-color: rgb(247 254 231 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/50 {
  --tw-shadow-color: rgb(247 254 231 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/55 {
  --tw-shadow-color: rgb(247 254 231 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/60 {
  --tw-shadow-color: rgb(247 254 231 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/65 {
  --tw-shadow-color: rgb(247 254 231 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/70 {
  --tw-shadow-color: rgb(247 254 231 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/75 {
  --tw-shadow-color: rgb(247 254 231 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/80 {
  --tw-shadow-color: rgb(247 254 231 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/85 {
  --tw-shadow-color: rgb(247 254 231 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/90 {
  --tw-shadow-color: rgb(247 254 231 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-50\/95 {
  --tw-shadow-color: rgb(247 254 231 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500 {
  --tw-shadow-color: #84cc16 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/0 {
  --tw-shadow-color: rgb(132 204 22 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/10 {
  --tw-shadow-color: rgb(132 204 22 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/100 {
  --tw-shadow-color: rgb(132 204 22 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/15 {
  --tw-shadow-color: rgb(132 204 22 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/20 {
  --tw-shadow-color: rgb(132 204 22 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/25 {
  --tw-shadow-color: rgb(132 204 22 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/30 {
  --tw-shadow-color: rgb(132 204 22 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/35 {
  --tw-shadow-color: rgb(132 204 22 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/40 {
  --tw-shadow-color: rgb(132 204 22 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/45 {
  --tw-shadow-color: rgb(132 204 22 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/5 {
  --tw-shadow-color: rgb(132 204 22 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/50 {
  --tw-shadow-color: rgb(132 204 22 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/55 {
  --tw-shadow-color: rgb(132 204 22 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/60 {
  --tw-shadow-color: rgb(132 204 22 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/65 {
  --tw-shadow-color: rgb(132 204 22 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/70 {
  --tw-shadow-color: rgb(132 204 22 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/75 {
  --tw-shadow-color: rgb(132 204 22 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/80 {
  --tw-shadow-color: rgb(132 204 22 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/85 {
  --tw-shadow-color: rgb(132 204 22 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/90 {
  --tw-shadow-color: rgb(132 204 22 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-500\/95 {
  --tw-shadow-color: rgb(132 204 22 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600 {
  --tw-shadow-color: #65a30d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/0 {
  --tw-shadow-color: rgb(101 163 13 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/10 {
  --tw-shadow-color: rgb(101 163 13 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/100 {
  --tw-shadow-color: rgb(101 163 13 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/15 {
  --tw-shadow-color: rgb(101 163 13 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/20 {
  --tw-shadow-color: rgb(101 163 13 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/25 {
  --tw-shadow-color: rgb(101 163 13 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/30 {
  --tw-shadow-color: rgb(101 163 13 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/35 {
  --tw-shadow-color: rgb(101 163 13 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/40 {
  --tw-shadow-color: rgb(101 163 13 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/45 {
  --tw-shadow-color: rgb(101 163 13 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/5 {
  --tw-shadow-color: rgb(101 163 13 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/50 {
  --tw-shadow-color: rgb(101 163 13 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/55 {
  --tw-shadow-color: rgb(101 163 13 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/60 {
  --tw-shadow-color: rgb(101 163 13 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/65 {
  --tw-shadow-color: rgb(101 163 13 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/70 {
  --tw-shadow-color: rgb(101 163 13 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/75 {
  --tw-shadow-color: rgb(101 163 13 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/80 {
  --tw-shadow-color: rgb(101 163 13 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/85 {
  --tw-shadow-color: rgb(101 163 13 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/90 {
  --tw-shadow-color: rgb(101 163 13 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-600\/95 {
  --tw-shadow-color: rgb(101 163 13 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700 {
  --tw-shadow-color: #4d7c0f !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/0 {
  --tw-shadow-color: rgb(77 124 15 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/10 {
  --tw-shadow-color: rgb(77 124 15 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/100 {
  --tw-shadow-color: rgb(77 124 15 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/15 {
  --tw-shadow-color: rgb(77 124 15 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/20 {
  --tw-shadow-color: rgb(77 124 15 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/25 {
  --tw-shadow-color: rgb(77 124 15 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/30 {
  --tw-shadow-color: rgb(77 124 15 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/35 {
  --tw-shadow-color: rgb(77 124 15 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/40 {
  --tw-shadow-color: rgb(77 124 15 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/45 {
  --tw-shadow-color: rgb(77 124 15 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/5 {
  --tw-shadow-color: rgb(77 124 15 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/50 {
  --tw-shadow-color: rgb(77 124 15 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/55 {
  --tw-shadow-color: rgb(77 124 15 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/60 {
  --tw-shadow-color: rgb(77 124 15 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/65 {
  --tw-shadow-color: rgb(77 124 15 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/70 {
  --tw-shadow-color: rgb(77 124 15 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/75 {
  --tw-shadow-color: rgb(77 124 15 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/80 {
  --tw-shadow-color: rgb(77 124 15 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/85 {
  --tw-shadow-color: rgb(77 124 15 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/90 {
  --tw-shadow-color: rgb(77 124 15 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-700\/95 {
  --tw-shadow-color: rgb(77 124 15 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800 {
  --tw-shadow-color: #3f6212 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/0 {
  --tw-shadow-color: rgb(63 98 18 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/10 {
  --tw-shadow-color: rgb(63 98 18 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/100 {
  --tw-shadow-color: rgb(63 98 18 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/15 {
  --tw-shadow-color: rgb(63 98 18 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/20 {
  --tw-shadow-color: rgb(63 98 18 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/25 {
  --tw-shadow-color: rgb(63 98 18 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/30 {
  --tw-shadow-color: rgb(63 98 18 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/35 {
  --tw-shadow-color: rgb(63 98 18 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/40 {
  --tw-shadow-color: rgb(63 98 18 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/45 {
  --tw-shadow-color: rgb(63 98 18 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/5 {
  --tw-shadow-color: rgb(63 98 18 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/50 {
  --tw-shadow-color: rgb(63 98 18 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/55 {
  --tw-shadow-color: rgb(63 98 18 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/60 {
  --tw-shadow-color: rgb(63 98 18 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/65 {
  --tw-shadow-color: rgb(63 98 18 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/70 {
  --tw-shadow-color: rgb(63 98 18 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/75 {
  --tw-shadow-color: rgb(63 98 18 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/80 {
  --tw-shadow-color: rgb(63 98 18 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/85 {
  --tw-shadow-color: rgb(63 98 18 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/90 {
  --tw-shadow-color: rgb(63 98 18 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-800\/95 {
  --tw-shadow-color: rgb(63 98 18 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900 {
  --tw-shadow-color: #365314 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/0 {
  --tw-shadow-color: rgb(54 83 20 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/10 {
  --tw-shadow-color: rgb(54 83 20 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/100 {
  --tw-shadow-color: rgb(54 83 20 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/15 {
  --tw-shadow-color: rgb(54 83 20 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/20 {
  --tw-shadow-color: rgb(54 83 20 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/25 {
  --tw-shadow-color: rgb(54 83 20 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/30 {
  --tw-shadow-color: rgb(54 83 20 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/35 {
  --tw-shadow-color: rgb(54 83 20 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/40 {
  --tw-shadow-color: rgb(54 83 20 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/45 {
  --tw-shadow-color: rgb(54 83 20 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/5 {
  --tw-shadow-color: rgb(54 83 20 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/50 {
  --tw-shadow-color: rgb(54 83 20 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/55 {
  --tw-shadow-color: rgb(54 83 20 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/60 {
  --tw-shadow-color: rgb(54 83 20 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/65 {
  --tw-shadow-color: rgb(54 83 20 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/70 {
  --tw-shadow-color: rgb(54 83 20 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/75 {
  --tw-shadow-color: rgb(54 83 20 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/80 {
  --tw-shadow-color: rgb(54 83 20 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/85 {
  --tw-shadow-color: rgb(54 83 20 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/90 {
  --tw-shadow-color: rgb(54 83 20 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-900\/95 {
  --tw-shadow-color: rgb(54 83 20 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950 {
  --tw-shadow-color: #1a2e05 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/0 {
  --tw-shadow-color: rgb(26 46 5 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/10 {
  --tw-shadow-color: rgb(26 46 5 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/100 {
  --tw-shadow-color: rgb(26 46 5 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/15 {
  --tw-shadow-color: rgb(26 46 5 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/20 {
  --tw-shadow-color: rgb(26 46 5 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/25 {
  --tw-shadow-color: rgb(26 46 5 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/30 {
  --tw-shadow-color: rgb(26 46 5 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/35 {
  --tw-shadow-color: rgb(26 46 5 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/40 {
  --tw-shadow-color: rgb(26 46 5 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/45 {
  --tw-shadow-color: rgb(26 46 5 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/5 {
  --tw-shadow-color: rgb(26 46 5 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/50 {
  --tw-shadow-color: rgb(26 46 5 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/55 {
  --tw-shadow-color: rgb(26 46 5 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/60 {
  --tw-shadow-color: rgb(26 46 5 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/65 {
  --tw-shadow-color: rgb(26 46 5 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/70 {
  --tw-shadow-color: rgb(26 46 5 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/75 {
  --tw-shadow-color: rgb(26 46 5 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/80 {
  --tw-shadow-color: rgb(26 46 5 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/85 {
  --tw-shadow-color: rgb(26 46 5 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/90 {
  --tw-shadow-color: rgb(26 46 5 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-lime-950\/95 {
  --tw-shadow-color: rgb(26 46 5 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100 {
  --tw-shadow-color: #f5f5f5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/0 {
  --tw-shadow-color: rgb(245 245 245 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/10 {
  --tw-shadow-color: rgb(245 245 245 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/100 {
  --tw-shadow-color: rgb(245 245 245 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/15 {
  --tw-shadow-color: rgb(245 245 245 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/20 {
  --tw-shadow-color: rgb(245 245 245 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/25 {
  --tw-shadow-color: rgb(245 245 245 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/30 {
  --tw-shadow-color: rgb(245 245 245 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/35 {
  --tw-shadow-color: rgb(245 245 245 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/40 {
  --tw-shadow-color: rgb(245 245 245 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/45 {
  --tw-shadow-color: rgb(245 245 245 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/5 {
  --tw-shadow-color: rgb(245 245 245 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/50 {
  --tw-shadow-color: rgb(245 245 245 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/55 {
  --tw-shadow-color: rgb(245 245 245 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/60 {
  --tw-shadow-color: rgb(245 245 245 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/65 {
  --tw-shadow-color: rgb(245 245 245 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/70 {
  --tw-shadow-color: rgb(245 245 245 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/75 {
  --tw-shadow-color: rgb(245 245 245 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/80 {
  --tw-shadow-color: rgb(245 245 245 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/85 {
  --tw-shadow-color: rgb(245 245 245 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/90 {
  --tw-shadow-color: rgb(245 245 245 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-100\/95 {
  --tw-shadow-color: rgb(245 245 245 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200 {
  --tw-shadow-color: #e5e5e5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/0 {
  --tw-shadow-color: rgb(229 229 229 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/10 {
  --tw-shadow-color: rgb(229 229 229 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/100 {
  --tw-shadow-color: rgb(229 229 229 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/15 {
  --tw-shadow-color: rgb(229 229 229 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/20 {
  --tw-shadow-color: rgb(229 229 229 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/25 {
  --tw-shadow-color: rgb(229 229 229 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/30 {
  --tw-shadow-color: rgb(229 229 229 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/35 {
  --tw-shadow-color: rgb(229 229 229 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/40 {
  --tw-shadow-color: rgb(229 229 229 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/45 {
  --tw-shadow-color: rgb(229 229 229 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/5 {
  --tw-shadow-color: rgb(229 229 229 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/50 {
  --tw-shadow-color: rgb(229 229 229 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/55 {
  --tw-shadow-color: rgb(229 229 229 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/60 {
  --tw-shadow-color: rgb(229 229 229 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/65 {
  --tw-shadow-color: rgb(229 229 229 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/70 {
  --tw-shadow-color: rgb(229 229 229 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/75 {
  --tw-shadow-color: rgb(229 229 229 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/80 {
  --tw-shadow-color: rgb(229 229 229 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/85 {
  --tw-shadow-color: rgb(229 229 229 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/90 {
  --tw-shadow-color: rgb(229 229 229 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-200\/95 {
  --tw-shadow-color: rgb(229 229 229 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300 {
  --tw-shadow-color: #d4d4d4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/0 {
  --tw-shadow-color: rgb(212 212 212 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/10 {
  --tw-shadow-color: rgb(212 212 212 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/100 {
  --tw-shadow-color: rgb(212 212 212 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/15 {
  --tw-shadow-color: rgb(212 212 212 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/20 {
  --tw-shadow-color: rgb(212 212 212 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/25 {
  --tw-shadow-color: rgb(212 212 212 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/30 {
  --tw-shadow-color: rgb(212 212 212 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/35 {
  --tw-shadow-color: rgb(212 212 212 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/40 {
  --tw-shadow-color: rgb(212 212 212 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/45 {
  --tw-shadow-color: rgb(212 212 212 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/5 {
  --tw-shadow-color: rgb(212 212 212 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/50 {
  --tw-shadow-color: rgb(212 212 212 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/55 {
  --tw-shadow-color: rgb(212 212 212 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/60 {
  --tw-shadow-color: rgb(212 212 212 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/65 {
  --tw-shadow-color: rgb(212 212 212 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/70 {
  --tw-shadow-color: rgb(212 212 212 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/75 {
  --tw-shadow-color: rgb(212 212 212 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/80 {
  --tw-shadow-color: rgb(212 212 212 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/85 {
  --tw-shadow-color: rgb(212 212 212 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/90 {
  --tw-shadow-color: rgb(212 212 212 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-300\/95 {
  --tw-shadow-color: rgb(212 212 212 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400 {
  --tw-shadow-color: #a3a3a3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/0 {
  --tw-shadow-color: rgb(163 163 163 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/10 {
  --tw-shadow-color: rgb(163 163 163 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/100 {
  --tw-shadow-color: rgb(163 163 163 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/15 {
  --tw-shadow-color: rgb(163 163 163 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/20 {
  --tw-shadow-color: rgb(163 163 163 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/25 {
  --tw-shadow-color: rgb(163 163 163 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/30 {
  --tw-shadow-color: rgb(163 163 163 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/35 {
  --tw-shadow-color: rgb(163 163 163 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/40 {
  --tw-shadow-color: rgb(163 163 163 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/45 {
  --tw-shadow-color: rgb(163 163 163 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/5 {
  --tw-shadow-color: rgb(163 163 163 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/50 {
  --tw-shadow-color: rgb(163 163 163 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/55 {
  --tw-shadow-color: rgb(163 163 163 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/60 {
  --tw-shadow-color: rgb(163 163 163 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/65 {
  --tw-shadow-color: rgb(163 163 163 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/70 {
  --tw-shadow-color: rgb(163 163 163 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/75 {
  --tw-shadow-color: rgb(163 163 163 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/80 {
  --tw-shadow-color: rgb(163 163 163 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/85 {
  --tw-shadow-color: rgb(163 163 163 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/90 {
  --tw-shadow-color: rgb(163 163 163 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-400\/95 {
  --tw-shadow-color: rgb(163 163 163 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50 {
  --tw-shadow-color: #fafafa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/0 {
  --tw-shadow-color: rgb(250 250 250 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/10 {
  --tw-shadow-color: rgb(250 250 250 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/100 {
  --tw-shadow-color: rgb(250 250 250 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/15 {
  --tw-shadow-color: rgb(250 250 250 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/20 {
  --tw-shadow-color: rgb(250 250 250 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/25 {
  --tw-shadow-color: rgb(250 250 250 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/30 {
  --tw-shadow-color: rgb(250 250 250 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/35 {
  --tw-shadow-color: rgb(250 250 250 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/40 {
  --tw-shadow-color: rgb(250 250 250 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/45 {
  --tw-shadow-color: rgb(250 250 250 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/5 {
  --tw-shadow-color: rgb(250 250 250 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/50 {
  --tw-shadow-color: rgb(250 250 250 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/55 {
  --tw-shadow-color: rgb(250 250 250 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/60 {
  --tw-shadow-color: rgb(250 250 250 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/65 {
  --tw-shadow-color: rgb(250 250 250 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/70 {
  --tw-shadow-color: rgb(250 250 250 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/75 {
  --tw-shadow-color: rgb(250 250 250 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/80 {
  --tw-shadow-color: rgb(250 250 250 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/85 {
  --tw-shadow-color: rgb(250 250 250 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/90 {
  --tw-shadow-color: rgb(250 250 250 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-50\/95 {
  --tw-shadow-color: rgb(250 250 250 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500 {
  --tw-shadow-color: #737373 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/0 {
  --tw-shadow-color: rgb(115 115 115 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/10 {
  --tw-shadow-color: rgb(115 115 115 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/100 {
  --tw-shadow-color: rgb(115 115 115 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/15 {
  --tw-shadow-color: rgb(115 115 115 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/20 {
  --tw-shadow-color: rgb(115 115 115 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/25 {
  --tw-shadow-color: rgb(115 115 115 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/30 {
  --tw-shadow-color: rgb(115 115 115 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/35 {
  --tw-shadow-color: rgb(115 115 115 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/40 {
  --tw-shadow-color: rgb(115 115 115 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/45 {
  --tw-shadow-color: rgb(115 115 115 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/5 {
  --tw-shadow-color: rgb(115 115 115 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/50 {
  --tw-shadow-color: rgb(115 115 115 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/55 {
  --tw-shadow-color: rgb(115 115 115 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/60 {
  --tw-shadow-color: rgb(115 115 115 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/65 {
  --tw-shadow-color: rgb(115 115 115 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/70 {
  --tw-shadow-color: rgb(115 115 115 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/75 {
  --tw-shadow-color: rgb(115 115 115 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/80 {
  --tw-shadow-color: rgb(115 115 115 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/85 {
  --tw-shadow-color: rgb(115 115 115 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/90 {
  --tw-shadow-color: rgb(115 115 115 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-500\/95 {
  --tw-shadow-color: rgb(115 115 115 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600 {
  --tw-shadow-color: #525252 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/0 {
  --tw-shadow-color: rgb(82 82 82 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/10 {
  --tw-shadow-color: rgb(82 82 82 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/100 {
  --tw-shadow-color: rgb(82 82 82 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/15 {
  --tw-shadow-color: rgb(82 82 82 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/20 {
  --tw-shadow-color: rgb(82 82 82 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/25 {
  --tw-shadow-color: rgb(82 82 82 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/30 {
  --tw-shadow-color: rgb(82 82 82 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/35 {
  --tw-shadow-color: rgb(82 82 82 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/40 {
  --tw-shadow-color: rgb(82 82 82 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/45 {
  --tw-shadow-color: rgb(82 82 82 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/5 {
  --tw-shadow-color: rgb(82 82 82 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/50 {
  --tw-shadow-color: rgb(82 82 82 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/55 {
  --tw-shadow-color: rgb(82 82 82 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/60 {
  --tw-shadow-color: rgb(82 82 82 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/65 {
  --tw-shadow-color: rgb(82 82 82 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/70 {
  --tw-shadow-color: rgb(82 82 82 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/75 {
  --tw-shadow-color: rgb(82 82 82 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/80 {
  --tw-shadow-color: rgb(82 82 82 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/85 {
  --tw-shadow-color: rgb(82 82 82 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/90 {
  --tw-shadow-color: rgb(82 82 82 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-600\/95 {
  --tw-shadow-color: rgb(82 82 82 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700 {
  --tw-shadow-color: #404040 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/0 {
  --tw-shadow-color: rgb(64 64 64 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/10 {
  --tw-shadow-color: rgb(64 64 64 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/100 {
  --tw-shadow-color: rgb(64 64 64 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/15 {
  --tw-shadow-color: rgb(64 64 64 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/20 {
  --tw-shadow-color: rgb(64 64 64 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/25 {
  --tw-shadow-color: rgb(64 64 64 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/30 {
  --tw-shadow-color: rgb(64 64 64 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/35 {
  --tw-shadow-color: rgb(64 64 64 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/40 {
  --tw-shadow-color: rgb(64 64 64 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/45 {
  --tw-shadow-color: rgb(64 64 64 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/5 {
  --tw-shadow-color: rgb(64 64 64 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/50 {
  --tw-shadow-color: rgb(64 64 64 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/55 {
  --tw-shadow-color: rgb(64 64 64 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/60 {
  --tw-shadow-color: rgb(64 64 64 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/65 {
  --tw-shadow-color: rgb(64 64 64 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/70 {
  --tw-shadow-color: rgb(64 64 64 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/75 {
  --tw-shadow-color: rgb(64 64 64 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/80 {
  --tw-shadow-color: rgb(64 64 64 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/85 {
  --tw-shadow-color: rgb(64 64 64 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/90 {
  --tw-shadow-color: rgb(64 64 64 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-700\/95 {
  --tw-shadow-color: rgb(64 64 64 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800 {
  --tw-shadow-color: #262626 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/0 {
  --tw-shadow-color: rgb(38 38 38 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/10 {
  --tw-shadow-color: rgb(38 38 38 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/100 {
  --tw-shadow-color: rgb(38 38 38 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/15 {
  --tw-shadow-color: rgb(38 38 38 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/20 {
  --tw-shadow-color: rgb(38 38 38 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/25 {
  --tw-shadow-color: rgb(38 38 38 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/30 {
  --tw-shadow-color: rgb(38 38 38 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/35 {
  --tw-shadow-color: rgb(38 38 38 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/40 {
  --tw-shadow-color: rgb(38 38 38 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/45 {
  --tw-shadow-color: rgb(38 38 38 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/5 {
  --tw-shadow-color: rgb(38 38 38 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/50 {
  --tw-shadow-color: rgb(38 38 38 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/55 {
  --tw-shadow-color: rgb(38 38 38 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/60 {
  --tw-shadow-color: rgb(38 38 38 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/65 {
  --tw-shadow-color: rgb(38 38 38 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/70 {
  --tw-shadow-color: rgb(38 38 38 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/75 {
  --tw-shadow-color: rgb(38 38 38 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/80 {
  --tw-shadow-color: rgb(38 38 38 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/85 {
  --tw-shadow-color: rgb(38 38 38 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/90 {
  --tw-shadow-color: rgb(38 38 38 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-800\/95 {
  --tw-shadow-color: rgb(38 38 38 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900 {
  --tw-shadow-color: #171717 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/0 {
  --tw-shadow-color: rgb(23 23 23 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/10 {
  --tw-shadow-color: rgb(23 23 23 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/100 {
  --tw-shadow-color: rgb(23 23 23 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/15 {
  --tw-shadow-color: rgb(23 23 23 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/20 {
  --tw-shadow-color: rgb(23 23 23 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/25 {
  --tw-shadow-color: rgb(23 23 23 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/30 {
  --tw-shadow-color: rgb(23 23 23 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/35 {
  --tw-shadow-color: rgb(23 23 23 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/40 {
  --tw-shadow-color: rgb(23 23 23 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/45 {
  --tw-shadow-color: rgb(23 23 23 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/5 {
  --tw-shadow-color: rgb(23 23 23 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/50 {
  --tw-shadow-color: rgb(23 23 23 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/55 {
  --tw-shadow-color: rgb(23 23 23 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/60 {
  --tw-shadow-color: rgb(23 23 23 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/65 {
  --tw-shadow-color: rgb(23 23 23 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/70 {
  --tw-shadow-color: rgb(23 23 23 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/75 {
  --tw-shadow-color: rgb(23 23 23 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/80 {
  --tw-shadow-color: rgb(23 23 23 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/85 {
  --tw-shadow-color: rgb(23 23 23 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/90 {
  --tw-shadow-color: rgb(23 23 23 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-900\/95 {
  --tw-shadow-color: rgb(23 23 23 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950 {
  --tw-shadow-color: #0a0a0a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/0 {
  --tw-shadow-color: rgb(10 10 10 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/10 {
  --tw-shadow-color: rgb(10 10 10 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/100 {
  --tw-shadow-color: rgb(10 10 10 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/15 {
  --tw-shadow-color: rgb(10 10 10 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/20 {
  --tw-shadow-color: rgb(10 10 10 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/25 {
  --tw-shadow-color: rgb(10 10 10 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/30 {
  --tw-shadow-color: rgb(10 10 10 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/35 {
  --tw-shadow-color: rgb(10 10 10 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/40 {
  --tw-shadow-color: rgb(10 10 10 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/45 {
  --tw-shadow-color: rgb(10 10 10 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/5 {
  --tw-shadow-color: rgb(10 10 10 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/50 {
  --tw-shadow-color: rgb(10 10 10 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/55 {
  --tw-shadow-color: rgb(10 10 10 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/60 {
  --tw-shadow-color: rgb(10 10 10 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/65 {
  --tw-shadow-color: rgb(10 10 10 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/70 {
  --tw-shadow-color: rgb(10 10 10 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/75 {
  --tw-shadow-color: rgb(10 10 10 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/80 {
  --tw-shadow-color: rgb(10 10 10 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/85 {
  --tw-shadow-color: rgb(10 10 10 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/90 {
  --tw-shadow-color: rgb(10 10 10 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-neutral-950\/95 {
  --tw-shadow-color: rgb(10 10 10 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100 {
  --tw-shadow-color: #ffedd5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/0 {
  --tw-shadow-color: rgb(255 237 213 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/10 {
  --tw-shadow-color: rgb(255 237 213 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/100 {
  --tw-shadow-color: rgb(255 237 213 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/15 {
  --tw-shadow-color: rgb(255 237 213 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/20 {
  --tw-shadow-color: rgb(255 237 213 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/25 {
  --tw-shadow-color: rgb(255 237 213 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/30 {
  --tw-shadow-color: rgb(255 237 213 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/35 {
  --tw-shadow-color: rgb(255 237 213 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/40 {
  --tw-shadow-color: rgb(255 237 213 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/45 {
  --tw-shadow-color: rgb(255 237 213 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/5 {
  --tw-shadow-color: rgb(255 237 213 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/50 {
  --tw-shadow-color: rgb(255 237 213 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/55 {
  --tw-shadow-color: rgb(255 237 213 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/60 {
  --tw-shadow-color: rgb(255 237 213 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/65 {
  --tw-shadow-color: rgb(255 237 213 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/70 {
  --tw-shadow-color: rgb(255 237 213 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/75 {
  --tw-shadow-color: rgb(255 237 213 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/80 {
  --tw-shadow-color: rgb(255 237 213 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/85 {
  --tw-shadow-color: rgb(255 237 213 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/90 {
  --tw-shadow-color: rgb(255 237 213 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-100\/95 {
  --tw-shadow-color: rgb(255 237 213 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200 {
  --tw-shadow-color: #fed7aa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/0 {
  --tw-shadow-color: rgb(254 215 170 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/10 {
  --tw-shadow-color: rgb(254 215 170 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/100 {
  --tw-shadow-color: rgb(254 215 170 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/15 {
  --tw-shadow-color: rgb(254 215 170 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/20 {
  --tw-shadow-color: rgb(254 215 170 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/25 {
  --tw-shadow-color: rgb(254 215 170 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/30 {
  --tw-shadow-color: rgb(254 215 170 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/35 {
  --tw-shadow-color: rgb(254 215 170 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/40 {
  --tw-shadow-color: rgb(254 215 170 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/45 {
  --tw-shadow-color: rgb(254 215 170 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/5 {
  --tw-shadow-color: rgb(254 215 170 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/50 {
  --tw-shadow-color: rgb(254 215 170 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/55 {
  --tw-shadow-color: rgb(254 215 170 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/60 {
  --tw-shadow-color: rgb(254 215 170 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/65 {
  --tw-shadow-color: rgb(254 215 170 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/70 {
  --tw-shadow-color: rgb(254 215 170 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/75 {
  --tw-shadow-color: rgb(254 215 170 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/80 {
  --tw-shadow-color: rgb(254 215 170 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/85 {
  --tw-shadow-color: rgb(254 215 170 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/90 {
  --tw-shadow-color: rgb(254 215 170 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-200\/95 {
  --tw-shadow-color: rgb(254 215 170 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300 {
  --tw-shadow-color: #fdba74 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/0 {
  --tw-shadow-color: rgb(253 186 116 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/10 {
  --tw-shadow-color: rgb(253 186 116 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/100 {
  --tw-shadow-color: rgb(253 186 116 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/15 {
  --tw-shadow-color: rgb(253 186 116 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/20 {
  --tw-shadow-color: rgb(253 186 116 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/25 {
  --tw-shadow-color: rgb(253 186 116 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/30 {
  --tw-shadow-color: rgb(253 186 116 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/35 {
  --tw-shadow-color: rgb(253 186 116 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/40 {
  --tw-shadow-color: rgb(253 186 116 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/45 {
  --tw-shadow-color: rgb(253 186 116 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/5 {
  --tw-shadow-color: rgb(253 186 116 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/50 {
  --tw-shadow-color: rgb(253 186 116 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/55 {
  --tw-shadow-color: rgb(253 186 116 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/60 {
  --tw-shadow-color: rgb(253 186 116 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/65 {
  --tw-shadow-color: rgb(253 186 116 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/70 {
  --tw-shadow-color: rgb(253 186 116 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/75 {
  --tw-shadow-color: rgb(253 186 116 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/80 {
  --tw-shadow-color: rgb(253 186 116 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/85 {
  --tw-shadow-color: rgb(253 186 116 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/90 {
  --tw-shadow-color: rgb(253 186 116 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-300\/95 {
  --tw-shadow-color: rgb(253 186 116 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400 {
  --tw-shadow-color: #fb923c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/0 {
  --tw-shadow-color: rgb(251 146 60 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/10 {
  --tw-shadow-color: rgb(251 146 60 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/100 {
  --tw-shadow-color: rgb(251 146 60 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/15 {
  --tw-shadow-color: rgb(251 146 60 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/20 {
  --tw-shadow-color: rgb(251 146 60 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/25 {
  --tw-shadow-color: rgb(251 146 60 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/30 {
  --tw-shadow-color: rgb(251 146 60 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/35 {
  --tw-shadow-color: rgb(251 146 60 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/40 {
  --tw-shadow-color: rgb(251 146 60 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/45 {
  --tw-shadow-color: rgb(251 146 60 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/5 {
  --tw-shadow-color: rgb(251 146 60 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/50 {
  --tw-shadow-color: rgb(251 146 60 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/55 {
  --tw-shadow-color: rgb(251 146 60 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/60 {
  --tw-shadow-color: rgb(251 146 60 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/65 {
  --tw-shadow-color: rgb(251 146 60 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/70 {
  --tw-shadow-color: rgb(251 146 60 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/75 {
  --tw-shadow-color: rgb(251 146 60 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/80 {
  --tw-shadow-color: rgb(251 146 60 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/85 {
  --tw-shadow-color: rgb(251 146 60 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/90 {
  --tw-shadow-color: rgb(251 146 60 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-400\/95 {
  --tw-shadow-color: rgb(251 146 60 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50 {
  --tw-shadow-color: #fff7ed !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/0 {
  --tw-shadow-color: rgb(255 247 237 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/10 {
  --tw-shadow-color: rgb(255 247 237 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/100 {
  --tw-shadow-color: rgb(255 247 237 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/15 {
  --tw-shadow-color: rgb(255 247 237 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/20 {
  --tw-shadow-color: rgb(255 247 237 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/25 {
  --tw-shadow-color: rgb(255 247 237 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/30 {
  --tw-shadow-color: rgb(255 247 237 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/35 {
  --tw-shadow-color: rgb(255 247 237 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/40 {
  --tw-shadow-color: rgb(255 247 237 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/45 {
  --tw-shadow-color: rgb(255 247 237 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/5 {
  --tw-shadow-color: rgb(255 247 237 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/50 {
  --tw-shadow-color: rgb(255 247 237 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/55 {
  --tw-shadow-color: rgb(255 247 237 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/60 {
  --tw-shadow-color: rgb(255 247 237 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/65 {
  --tw-shadow-color: rgb(255 247 237 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/70 {
  --tw-shadow-color: rgb(255 247 237 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/75 {
  --tw-shadow-color: rgb(255 247 237 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/80 {
  --tw-shadow-color: rgb(255 247 237 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/85 {
  --tw-shadow-color: rgb(255 247 237 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/90 {
  --tw-shadow-color: rgb(255 247 237 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-50\/95 {
  --tw-shadow-color: rgb(255 247 237 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500 {
  --tw-shadow-color: #f97316 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/0 {
  --tw-shadow-color: rgb(249 115 22 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/10 {
  --tw-shadow-color: rgb(249 115 22 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/100 {
  --tw-shadow-color: rgb(249 115 22 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/15 {
  --tw-shadow-color: rgb(249 115 22 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/20 {
  --tw-shadow-color: rgb(249 115 22 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/25 {
  --tw-shadow-color: rgb(249 115 22 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/30 {
  --tw-shadow-color: rgb(249 115 22 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/35 {
  --tw-shadow-color: rgb(249 115 22 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/40 {
  --tw-shadow-color: rgb(249 115 22 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/45 {
  --tw-shadow-color: rgb(249 115 22 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/5 {
  --tw-shadow-color: rgb(249 115 22 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/50 {
  --tw-shadow-color: rgb(249 115 22 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/55 {
  --tw-shadow-color: rgb(249 115 22 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/60 {
  --tw-shadow-color: rgb(249 115 22 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/65 {
  --tw-shadow-color: rgb(249 115 22 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/70 {
  --tw-shadow-color: rgb(249 115 22 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/75 {
  --tw-shadow-color: rgb(249 115 22 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/80 {
  --tw-shadow-color: rgb(249 115 22 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/85 {
  --tw-shadow-color: rgb(249 115 22 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/90 {
  --tw-shadow-color: rgb(249 115 22 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-500\/95 {
  --tw-shadow-color: rgb(249 115 22 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600 {
  --tw-shadow-color: #ea580c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/0 {
  --tw-shadow-color: rgb(234 88 12 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/10 {
  --tw-shadow-color: rgb(234 88 12 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/100 {
  --tw-shadow-color: rgb(234 88 12 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/15 {
  --tw-shadow-color: rgb(234 88 12 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/20 {
  --tw-shadow-color: rgb(234 88 12 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/25 {
  --tw-shadow-color: rgb(234 88 12 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/30 {
  --tw-shadow-color: rgb(234 88 12 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/35 {
  --tw-shadow-color: rgb(234 88 12 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/40 {
  --tw-shadow-color: rgb(234 88 12 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/45 {
  --tw-shadow-color: rgb(234 88 12 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/5 {
  --tw-shadow-color: rgb(234 88 12 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/50 {
  --tw-shadow-color: rgb(234 88 12 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/55 {
  --tw-shadow-color: rgb(234 88 12 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/60 {
  --tw-shadow-color: rgb(234 88 12 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/65 {
  --tw-shadow-color: rgb(234 88 12 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/70 {
  --tw-shadow-color: rgb(234 88 12 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/75 {
  --tw-shadow-color: rgb(234 88 12 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/80 {
  --tw-shadow-color: rgb(234 88 12 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/85 {
  --tw-shadow-color: rgb(234 88 12 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/90 {
  --tw-shadow-color: rgb(234 88 12 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-600\/95 {
  --tw-shadow-color: rgb(234 88 12 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700 {
  --tw-shadow-color: #c2410c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/0 {
  --tw-shadow-color: rgb(194 65 12 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/10 {
  --tw-shadow-color: rgb(194 65 12 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/100 {
  --tw-shadow-color: rgb(194 65 12 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/15 {
  --tw-shadow-color: rgb(194 65 12 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/20 {
  --tw-shadow-color: rgb(194 65 12 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/25 {
  --tw-shadow-color: rgb(194 65 12 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/30 {
  --tw-shadow-color: rgb(194 65 12 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/35 {
  --tw-shadow-color: rgb(194 65 12 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/40 {
  --tw-shadow-color: rgb(194 65 12 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/45 {
  --tw-shadow-color: rgb(194 65 12 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/5 {
  --tw-shadow-color: rgb(194 65 12 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/50 {
  --tw-shadow-color: rgb(194 65 12 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/55 {
  --tw-shadow-color: rgb(194 65 12 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/60 {
  --tw-shadow-color: rgb(194 65 12 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/65 {
  --tw-shadow-color: rgb(194 65 12 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/70 {
  --tw-shadow-color: rgb(194 65 12 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/75 {
  --tw-shadow-color: rgb(194 65 12 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/80 {
  --tw-shadow-color: rgb(194 65 12 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/85 {
  --tw-shadow-color: rgb(194 65 12 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/90 {
  --tw-shadow-color: rgb(194 65 12 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-700\/95 {
  --tw-shadow-color: rgb(194 65 12 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800 {
  --tw-shadow-color: #9a3412 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/0 {
  --tw-shadow-color: rgb(154 52 18 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/10 {
  --tw-shadow-color: rgb(154 52 18 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/100 {
  --tw-shadow-color: rgb(154 52 18 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/15 {
  --tw-shadow-color: rgb(154 52 18 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/20 {
  --tw-shadow-color: rgb(154 52 18 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/25 {
  --tw-shadow-color: rgb(154 52 18 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/30 {
  --tw-shadow-color: rgb(154 52 18 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/35 {
  --tw-shadow-color: rgb(154 52 18 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/40 {
  --tw-shadow-color: rgb(154 52 18 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/45 {
  --tw-shadow-color: rgb(154 52 18 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/5 {
  --tw-shadow-color: rgb(154 52 18 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/50 {
  --tw-shadow-color: rgb(154 52 18 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/55 {
  --tw-shadow-color: rgb(154 52 18 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/60 {
  --tw-shadow-color: rgb(154 52 18 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/65 {
  --tw-shadow-color: rgb(154 52 18 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/70 {
  --tw-shadow-color: rgb(154 52 18 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/75 {
  --tw-shadow-color: rgb(154 52 18 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/80 {
  --tw-shadow-color: rgb(154 52 18 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/85 {
  --tw-shadow-color: rgb(154 52 18 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/90 {
  --tw-shadow-color: rgb(154 52 18 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-800\/95 {
  --tw-shadow-color: rgb(154 52 18 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900 {
  --tw-shadow-color: #7c2d12 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/0 {
  --tw-shadow-color: rgb(124 45 18 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/10 {
  --tw-shadow-color: rgb(124 45 18 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/100 {
  --tw-shadow-color: rgb(124 45 18 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/15 {
  --tw-shadow-color: rgb(124 45 18 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/20 {
  --tw-shadow-color: rgb(124 45 18 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/25 {
  --tw-shadow-color: rgb(124 45 18 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/30 {
  --tw-shadow-color: rgb(124 45 18 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/35 {
  --tw-shadow-color: rgb(124 45 18 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/40 {
  --tw-shadow-color: rgb(124 45 18 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/45 {
  --tw-shadow-color: rgb(124 45 18 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/5 {
  --tw-shadow-color: rgb(124 45 18 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/50 {
  --tw-shadow-color: rgb(124 45 18 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/55 {
  --tw-shadow-color: rgb(124 45 18 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/60 {
  --tw-shadow-color: rgb(124 45 18 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/65 {
  --tw-shadow-color: rgb(124 45 18 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/70 {
  --tw-shadow-color: rgb(124 45 18 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/75 {
  --tw-shadow-color: rgb(124 45 18 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/80 {
  --tw-shadow-color: rgb(124 45 18 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/85 {
  --tw-shadow-color: rgb(124 45 18 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/90 {
  --tw-shadow-color: rgb(124 45 18 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-900\/95 {
  --tw-shadow-color: rgb(124 45 18 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950 {
  --tw-shadow-color: #431407 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/0 {
  --tw-shadow-color: rgb(67 20 7 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/10 {
  --tw-shadow-color: rgb(67 20 7 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/100 {
  --tw-shadow-color: rgb(67 20 7 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/15 {
  --tw-shadow-color: rgb(67 20 7 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/20 {
  --tw-shadow-color: rgb(67 20 7 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/25 {
  --tw-shadow-color: rgb(67 20 7 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/30 {
  --tw-shadow-color: rgb(67 20 7 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/35 {
  --tw-shadow-color: rgb(67 20 7 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/40 {
  --tw-shadow-color: rgb(67 20 7 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/45 {
  --tw-shadow-color: rgb(67 20 7 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/5 {
  --tw-shadow-color: rgb(67 20 7 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/50 {
  --tw-shadow-color: rgb(67 20 7 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/55 {
  --tw-shadow-color: rgb(67 20 7 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/60 {
  --tw-shadow-color: rgb(67 20 7 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/65 {
  --tw-shadow-color: rgb(67 20 7 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/70 {
  --tw-shadow-color: rgb(67 20 7 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/75 {
  --tw-shadow-color: rgb(67 20 7 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/80 {
  --tw-shadow-color: rgb(67 20 7 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/85 {
  --tw-shadow-color: rgb(67 20 7 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/90 {
  --tw-shadow-color: rgb(67 20 7 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-orange-950\/95 {
  --tw-shadow-color: rgb(67 20 7 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100 {
  --tw-shadow-color: #fce7f3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/0 {
  --tw-shadow-color: rgb(252 231 243 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/10 {
  --tw-shadow-color: rgb(252 231 243 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/100 {
  --tw-shadow-color: rgb(252 231 243 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/15 {
  --tw-shadow-color: rgb(252 231 243 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/20 {
  --tw-shadow-color: rgb(252 231 243 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/25 {
  --tw-shadow-color: rgb(252 231 243 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/30 {
  --tw-shadow-color: rgb(252 231 243 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/35 {
  --tw-shadow-color: rgb(252 231 243 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/40 {
  --tw-shadow-color: rgb(252 231 243 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/45 {
  --tw-shadow-color: rgb(252 231 243 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/5 {
  --tw-shadow-color: rgb(252 231 243 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/50 {
  --tw-shadow-color: rgb(252 231 243 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/55 {
  --tw-shadow-color: rgb(252 231 243 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/60 {
  --tw-shadow-color: rgb(252 231 243 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/65 {
  --tw-shadow-color: rgb(252 231 243 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/70 {
  --tw-shadow-color: rgb(252 231 243 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/75 {
  --tw-shadow-color: rgb(252 231 243 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/80 {
  --tw-shadow-color: rgb(252 231 243 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/85 {
  --tw-shadow-color: rgb(252 231 243 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/90 {
  --tw-shadow-color: rgb(252 231 243 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-100\/95 {
  --tw-shadow-color: rgb(252 231 243 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200 {
  --tw-shadow-color: #fbcfe8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/0 {
  --tw-shadow-color: rgb(251 207 232 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/10 {
  --tw-shadow-color: rgb(251 207 232 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/100 {
  --tw-shadow-color: rgb(251 207 232 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/15 {
  --tw-shadow-color: rgb(251 207 232 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/20 {
  --tw-shadow-color: rgb(251 207 232 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/25 {
  --tw-shadow-color: rgb(251 207 232 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/30 {
  --tw-shadow-color: rgb(251 207 232 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/35 {
  --tw-shadow-color: rgb(251 207 232 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/40 {
  --tw-shadow-color: rgb(251 207 232 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/45 {
  --tw-shadow-color: rgb(251 207 232 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/5 {
  --tw-shadow-color: rgb(251 207 232 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/50 {
  --tw-shadow-color: rgb(251 207 232 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/55 {
  --tw-shadow-color: rgb(251 207 232 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/60 {
  --tw-shadow-color: rgb(251 207 232 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/65 {
  --tw-shadow-color: rgb(251 207 232 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/70 {
  --tw-shadow-color: rgb(251 207 232 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/75 {
  --tw-shadow-color: rgb(251 207 232 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/80 {
  --tw-shadow-color: rgb(251 207 232 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/85 {
  --tw-shadow-color: rgb(251 207 232 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/90 {
  --tw-shadow-color: rgb(251 207 232 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-200\/95 {
  --tw-shadow-color: rgb(251 207 232 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300 {
  --tw-shadow-color: #f9a8d4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/0 {
  --tw-shadow-color: rgb(249 168 212 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/10 {
  --tw-shadow-color: rgb(249 168 212 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/100 {
  --tw-shadow-color: rgb(249 168 212 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/15 {
  --tw-shadow-color: rgb(249 168 212 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/20 {
  --tw-shadow-color: rgb(249 168 212 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/25 {
  --tw-shadow-color: rgb(249 168 212 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/30 {
  --tw-shadow-color: rgb(249 168 212 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/35 {
  --tw-shadow-color: rgb(249 168 212 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/40 {
  --tw-shadow-color: rgb(249 168 212 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/45 {
  --tw-shadow-color: rgb(249 168 212 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/5 {
  --tw-shadow-color: rgb(249 168 212 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/50 {
  --tw-shadow-color: rgb(249 168 212 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/55 {
  --tw-shadow-color: rgb(249 168 212 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/60 {
  --tw-shadow-color: rgb(249 168 212 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/65 {
  --tw-shadow-color: rgb(249 168 212 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/70 {
  --tw-shadow-color: rgb(249 168 212 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/75 {
  --tw-shadow-color: rgb(249 168 212 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/80 {
  --tw-shadow-color: rgb(249 168 212 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/85 {
  --tw-shadow-color: rgb(249 168 212 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/90 {
  --tw-shadow-color: rgb(249 168 212 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-300\/95 {
  --tw-shadow-color: rgb(249 168 212 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400 {
  --tw-shadow-color: #f472b6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/0 {
  --tw-shadow-color: rgb(244 114 182 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/10 {
  --tw-shadow-color: rgb(244 114 182 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/100 {
  --tw-shadow-color: rgb(244 114 182 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/15 {
  --tw-shadow-color: rgb(244 114 182 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/20 {
  --tw-shadow-color: rgb(244 114 182 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/25 {
  --tw-shadow-color: rgb(244 114 182 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/30 {
  --tw-shadow-color: rgb(244 114 182 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/35 {
  --tw-shadow-color: rgb(244 114 182 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/40 {
  --tw-shadow-color: rgb(244 114 182 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/45 {
  --tw-shadow-color: rgb(244 114 182 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/5 {
  --tw-shadow-color: rgb(244 114 182 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/50 {
  --tw-shadow-color: rgb(244 114 182 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/55 {
  --tw-shadow-color: rgb(244 114 182 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/60 {
  --tw-shadow-color: rgb(244 114 182 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/65 {
  --tw-shadow-color: rgb(244 114 182 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/70 {
  --tw-shadow-color: rgb(244 114 182 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/75 {
  --tw-shadow-color: rgb(244 114 182 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/80 {
  --tw-shadow-color: rgb(244 114 182 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/85 {
  --tw-shadow-color: rgb(244 114 182 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/90 {
  --tw-shadow-color: rgb(244 114 182 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-400\/95 {
  --tw-shadow-color: rgb(244 114 182 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50 {
  --tw-shadow-color: #fdf2f8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/0 {
  --tw-shadow-color: rgb(253 242 248 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/10 {
  --tw-shadow-color: rgb(253 242 248 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/100 {
  --tw-shadow-color: rgb(253 242 248 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/15 {
  --tw-shadow-color: rgb(253 242 248 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/20 {
  --tw-shadow-color: rgb(253 242 248 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/25 {
  --tw-shadow-color: rgb(253 242 248 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/30 {
  --tw-shadow-color: rgb(253 242 248 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/35 {
  --tw-shadow-color: rgb(253 242 248 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/40 {
  --tw-shadow-color: rgb(253 242 248 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/45 {
  --tw-shadow-color: rgb(253 242 248 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/5 {
  --tw-shadow-color: rgb(253 242 248 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/50 {
  --tw-shadow-color: rgb(253 242 248 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/55 {
  --tw-shadow-color: rgb(253 242 248 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/60 {
  --tw-shadow-color: rgb(253 242 248 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/65 {
  --tw-shadow-color: rgb(253 242 248 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/70 {
  --tw-shadow-color: rgb(253 242 248 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/75 {
  --tw-shadow-color: rgb(253 242 248 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/80 {
  --tw-shadow-color: rgb(253 242 248 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/85 {
  --tw-shadow-color: rgb(253 242 248 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/90 {
  --tw-shadow-color: rgb(253 242 248 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-50\/95 {
  --tw-shadow-color: rgb(253 242 248 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500 {
  --tw-shadow-color: #ec4899 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/0 {
  --tw-shadow-color: rgb(236 72 153 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/10 {
  --tw-shadow-color: rgb(236 72 153 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/100 {
  --tw-shadow-color: rgb(236 72 153 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/15 {
  --tw-shadow-color: rgb(236 72 153 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/20 {
  --tw-shadow-color: rgb(236 72 153 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/25 {
  --tw-shadow-color: rgb(236 72 153 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/30 {
  --tw-shadow-color: rgb(236 72 153 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/35 {
  --tw-shadow-color: rgb(236 72 153 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/40 {
  --tw-shadow-color: rgb(236 72 153 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/45 {
  --tw-shadow-color: rgb(236 72 153 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/5 {
  --tw-shadow-color: rgb(236 72 153 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/50 {
  --tw-shadow-color: rgb(236 72 153 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/55 {
  --tw-shadow-color: rgb(236 72 153 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/60 {
  --tw-shadow-color: rgb(236 72 153 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/65 {
  --tw-shadow-color: rgb(236 72 153 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/70 {
  --tw-shadow-color: rgb(236 72 153 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/75 {
  --tw-shadow-color: rgb(236 72 153 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/80 {
  --tw-shadow-color: rgb(236 72 153 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/85 {
  --tw-shadow-color: rgb(236 72 153 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/90 {
  --tw-shadow-color: rgb(236 72 153 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-500\/95 {
  --tw-shadow-color: rgb(236 72 153 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600 {
  --tw-shadow-color: #db2777 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/0 {
  --tw-shadow-color: rgb(219 39 119 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/10 {
  --tw-shadow-color: rgb(219 39 119 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/100 {
  --tw-shadow-color: rgb(219 39 119 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/15 {
  --tw-shadow-color: rgb(219 39 119 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/20 {
  --tw-shadow-color: rgb(219 39 119 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/25 {
  --tw-shadow-color: rgb(219 39 119 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/30 {
  --tw-shadow-color: rgb(219 39 119 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/35 {
  --tw-shadow-color: rgb(219 39 119 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/40 {
  --tw-shadow-color: rgb(219 39 119 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/45 {
  --tw-shadow-color: rgb(219 39 119 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/5 {
  --tw-shadow-color: rgb(219 39 119 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/50 {
  --tw-shadow-color: rgb(219 39 119 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/55 {
  --tw-shadow-color: rgb(219 39 119 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/60 {
  --tw-shadow-color: rgb(219 39 119 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/65 {
  --tw-shadow-color: rgb(219 39 119 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/70 {
  --tw-shadow-color: rgb(219 39 119 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/75 {
  --tw-shadow-color: rgb(219 39 119 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/80 {
  --tw-shadow-color: rgb(219 39 119 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/85 {
  --tw-shadow-color: rgb(219 39 119 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/90 {
  --tw-shadow-color: rgb(219 39 119 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-600\/95 {
  --tw-shadow-color: rgb(219 39 119 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700 {
  --tw-shadow-color: #be185d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/0 {
  --tw-shadow-color: rgb(190 24 93 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/10 {
  --tw-shadow-color: rgb(190 24 93 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/100 {
  --tw-shadow-color: rgb(190 24 93 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/15 {
  --tw-shadow-color: rgb(190 24 93 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/20 {
  --tw-shadow-color: rgb(190 24 93 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/25 {
  --tw-shadow-color: rgb(190 24 93 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/30 {
  --tw-shadow-color: rgb(190 24 93 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/35 {
  --tw-shadow-color: rgb(190 24 93 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/40 {
  --tw-shadow-color: rgb(190 24 93 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/45 {
  --tw-shadow-color: rgb(190 24 93 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/5 {
  --tw-shadow-color: rgb(190 24 93 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/50 {
  --tw-shadow-color: rgb(190 24 93 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/55 {
  --tw-shadow-color: rgb(190 24 93 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/60 {
  --tw-shadow-color: rgb(190 24 93 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/65 {
  --tw-shadow-color: rgb(190 24 93 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/70 {
  --tw-shadow-color: rgb(190 24 93 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/75 {
  --tw-shadow-color: rgb(190 24 93 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/80 {
  --tw-shadow-color: rgb(190 24 93 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/85 {
  --tw-shadow-color: rgb(190 24 93 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/90 {
  --tw-shadow-color: rgb(190 24 93 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-700\/95 {
  --tw-shadow-color: rgb(190 24 93 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800 {
  --tw-shadow-color: #9d174d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/0 {
  --tw-shadow-color: rgb(157 23 77 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/10 {
  --tw-shadow-color: rgb(157 23 77 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/100 {
  --tw-shadow-color: rgb(157 23 77 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/15 {
  --tw-shadow-color: rgb(157 23 77 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/20 {
  --tw-shadow-color: rgb(157 23 77 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/25 {
  --tw-shadow-color: rgb(157 23 77 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/30 {
  --tw-shadow-color: rgb(157 23 77 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/35 {
  --tw-shadow-color: rgb(157 23 77 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/40 {
  --tw-shadow-color: rgb(157 23 77 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/45 {
  --tw-shadow-color: rgb(157 23 77 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/5 {
  --tw-shadow-color: rgb(157 23 77 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/50 {
  --tw-shadow-color: rgb(157 23 77 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/55 {
  --tw-shadow-color: rgb(157 23 77 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/60 {
  --tw-shadow-color: rgb(157 23 77 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/65 {
  --tw-shadow-color: rgb(157 23 77 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/70 {
  --tw-shadow-color: rgb(157 23 77 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/75 {
  --tw-shadow-color: rgb(157 23 77 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/80 {
  --tw-shadow-color: rgb(157 23 77 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/85 {
  --tw-shadow-color: rgb(157 23 77 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/90 {
  --tw-shadow-color: rgb(157 23 77 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-800\/95 {
  --tw-shadow-color: rgb(157 23 77 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900 {
  --tw-shadow-color: #831843 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/0 {
  --tw-shadow-color: rgb(131 24 67 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/10 {
  --tw-shadow-color: rgb(131 24 67 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/100 {
  --tw-shadow-color: rgb(131 24 67 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/15 {
  --tw-shadow-color: rgb(131 24 67 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/20 {
  --tw-shadow-color: rgb(131 24 67 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/25 {
  --tw-shadow-color: rgb(131 24 67 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/30 {
  --tw-shadow-color: rgb(131 24 67 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/35 {
  --tw-shadow-color: rgb(131 24 67 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/40 {
  --tw-shadow-color: rgb(131 24 67 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/45 {
  --tw-shadow-color: rgb(131 24 67 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/5 {
  --tw-shadow-color: rgb(131 24 67 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/50 {
  --tw-shadow-color: rgb(131 24 67 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/55 {
  --tw-shadow-color: rgb(131 24 67 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/60 {
  --tw-shadow-color: rgb(131 24 67 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/65 {
  --tw-shadow-color: rgb(131 24 67 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/70 {
  --tw-shadow-color: rgb(131 24 67 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/75 {
  --tw-shadow-color: rgb(131 24 67 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/80 {
  --tw-shadow-color: rgb(131 24 67 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/85 {
  --tw-shadow-color: rgb(131 24 67 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/90 {
  --tw-shadow-color: rgb(131 24 67 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-900\/95 {
  --tw-shadow-color: rgb(131 24 67 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950 {
  --tw-shadow-color: #500724 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/0 {
  --tw-shadow-color: rgb(80 7 36 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/10 {
  --tw-shadow-color: rgb(80 7 36 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/100 {
  --tw-shadow-color: rgb(80 7 36 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/15 {
  --tw-shadow-color: rgb(80 7 36 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/20 {
  --tw-shadow-color: rgb(80 7 36 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/25 {
  --tw-shadow-color: rgb(80 7 36 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/30 {
  --tw-shadow-color: rgb(80 7 36 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/35 {
  --tw-shadow-color: rgb(80 7 36 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/40 {
  --tw-shadow-color: rgb(80 7 36 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/45 {
  --tw-shadow-color: rgb(80 7 36 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/5 {
  --tw-shadow-color: rgb(80 7 36 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/50 {
  --tw-shadow-color: rgb(80 7 36 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/55 {
  --tw-shadow-color: rgb(80 7 36 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/60 {
  --tw-shadow-color: rgb(80 7 36 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/65 {
  --tw-shadow-color: rgb(80 7 36 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/70 {
  --tw-shadow-color: rgb(80 7 36 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/75 {
  --tw-shadow-color: rgb(80 7 36 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/80 {
  --tw-shadow-color: rgb(80 7 36 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/85 {
  --tw-shadow-color: rgb(80 7 36 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/90 {
  --tw-shadow-color: rgb(80 7 36 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-pink-950\/95 {
  --tw-shadow-color: rgb(80 7 36 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100 {
  --tw-shadow-color: #f3e8ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/0 {
  --tw-shadow-color: rgb(243 232 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/10 {
  --tw-shadow-color: rgb(243 232 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/100 {
  --tw-shadow-color: rgb(243 232 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/15 {
  --tw-shadow-color: rgb(243 232 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/20 {
  --tw-shadow-color: rgb(243 232 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/25 {
  --tw-shadow-color: rgb(243 232 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/30 {
  --tw-shadow-color: rgb(243 232 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/35 {
  --tw-shadow-color: rgb(243 232 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/40 {
  --tw-shadow-color: rgb(243 232 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/45 {
  --tw-shadow-color: rgb(243 232 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/5 {
  --tw-shadow-color: rgb(243 232 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/50 {
  --tw-shadow-color: rgb(243 232 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/55 {
  --tw-shadow-color: rgb(243 232 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/60 {
  --tw-shadow-color: rgb(243 232 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/65 {
  --tw-shadow-color: rgb(243 232 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/70 {
  --tw-shadow-color: rgb(243 232 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/75 {
  --tw-shadow-color: rgb(243 232 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/80 {
  --tw-shadow-color: rgb(243 232 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/85 {
  --tw-shadow-color: rgb(243 232 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/90 {
  --tw-shadow-color: rgb(243 232 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-100\/95 {
  --tw-shadow-color: rgb(243 232 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200 {
  --tw-shadow-color: #e9d5ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/0 {
  --tw-shadow-color: rgb(233 213 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/10 {
  --tw-shadow-color: rgb(233 213 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/100 {
  --tw-shadow-color: rgb(233 213 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/15 {
  --tw-shadow-color: rgb(233 213 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/20 {
  --tw-shadow-color: rgb(233 213 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/25 {
  --tw-shadow-color: rgb(233 213 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/30 {
  --tw-shadow-color: rgb(233 213 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/35 {
  --tw-shadow-color: rgb(233 213 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/40 {
  --tw-shadow-color: rgb(233 213 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/45 {
  --tw-shadow-color: rgb(233 213 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/5 {
  --tw-shadow-color: rgb(233 213 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/50 {
  --tw-shadow-color: rgb(233 213 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/55 {
  --tw-shadow-color: rgb(233 213 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/60 {
  --tw-shadow-color: rgb(233 213 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/65 {
  --tw-shadow-color: rgb(233 213 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/70 {
  --tw-shadow-color: rgb(233 213 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/75 {
  --tw-shadow-color: rgb(233 213 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/80 {
  --tw-shadow-color: rgb(233 213 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/85 {
  --tw-shadow-color: rgb(233 213 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/90 {
  --tw-shadow-color: rgb(233 213 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-200\/95 {
  --tw-shadow-color: rgb(233 213 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300 {
  --tw-shadow-color: #d8b4fe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/0 {
  --tw-shadow-color: rgb(216 180 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/10 {
  --tw-shadow-color: rgb(216 180 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/100 {
  --tw-shadow-color: rgb(216 180 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/15 {
  --tw-shadow-color: rgb(216 180 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/20 {
  --tw-shadow-color: rgb(216 180 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/25 {
  --tw-shadow-color: rgb(216 180 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/30 {
  --tw-shadow-color: rgb(216 180 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/35 {
  --tw-shadow-color: rgb(216 180 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/40 {
  --tw-shadow-color: rgb(216 180 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/45 {
  --tw-shadow-color: rgb(216 180 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/5 {
  --tw-shadow-color: rgb(216 180 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/50 {
  --tw-shadow-color: rgb(216 180 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/55 {
  --tw-shadow-color: rgb(216 180 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/60 {
  --tw-shadow-color: rgb(216 180 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/65 {
  --tw-shadow-color: rgb(216 180 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/70 {
  --tw-shadow-color: rgb(216 180 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/75 {
  --tw-shadow-color: rgb(216 180 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/80 {
  --tw-shadow-color: rgb(216 180 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/85 {
  --tw-shadow-color: rgb(216 180 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/90 {
  --tw-shadow-color: rgb(216 180 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-300\/95 {
  --tw-shadow-color: rgb(216 180 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400 {
  --tw-shadow-color: #c084fc !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/0 {
  --tw-shadow-color: rgb(192 132 252 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/10 {
  --tw-shadow-color: rgb(192 132 252 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/100 {
  --tw-shadow-color: rgb(192 132 252 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/15 {
  --tw-shadow-color: rgb(192 132 252 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/20 {
  --tw-shadow-color: rgb(192 132 252 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/25 {
  --tw-shadow-color: rgb(192 132 252 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/30 {
  --tw-shadow-color: rgb(192 132 252 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/35 {
  --tw-shadow-color: rgb(192 132 252 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/40 {
  --tw-shadow-color: rgb(192 132 252 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/45 {
  --tw-shadow-color: rgb(192 132 252 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/5 {
  --tw-shadow-color: rgb(192 132 252 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/50 {
  --tw-shadow-color: rgb(192 132 252 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/55 {
  --tw-shadow-color: rgb(192 132 252 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/60 {
  --tw-shadow-color: rgb(192 132 252 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/65 {
  --tw-shadow-color: rgb(192 132 252 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/70 {
  --tw-shadow-color: rgb(192 132 252 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/75 {
  --tw-shadow-color: rgb(192 132 252 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/80 {
  --tw-shadow-color: rgb(192 132 252 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/85 {
  --tw-shadow-color: rgb(192 132 252 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/90 {
  --tw-shadow-color: rgb(192 132 252 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-400\/95 {
  --tw-shadow-color: rgb(192 132 252 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50 {
  --tw-shadow-color: #faf5ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/0 {
  --tw-shadow-color: rgb(250 245 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/10 {
  --tw-shadow-color: rgb(250 245 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/100 {
  --tw-shadow-color: rgb(250 245 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/15 {
  --tw-shadow-color: rgb(250 245 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/20 {
  --tw-shadow-color: rgb(250 245 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/25 {
  --tw-shadow-color: rgb(250 245 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/30 {
  --tw-shadow-color: rgb(250 245 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/35 {
  --tw-shadow-color: rgb(250 245 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/40 {
  --tw-shadow-color: rgb(250 245 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/45 {
  --tw-shadow-color: rgb(250 245 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/5 {
  --tw-shadow-color: rgb(250 245 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/50 {
  --tw-shadow-color: rgb(250 245 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/55 {
  --tw-shadow-color: rgb(250 245 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/60 {
  --tw-shadow-color: rgb(250 245 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/65 {
  --tw-shadow-color: rgb(250 245 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/70 {
  --tw-shadow-color: rgb(250 245 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/75 {
  --tw-shadow-color: rgb(250 245 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/80 {
  --tw-shadow-color: rgb(250 245 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/85 {
  --tw-shadow-color: rgb(250 245 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/90 {
  --tw-shadow-color: rgb(250 245 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-50\/95 {
  --tw-shadow-color: rgb(250 245 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500 {
  --tw-shadow-color: #a855f7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/0 {
  --tw-shadow-color: rgb(168 85 247 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/10 {
  --tw-shadow-color: rgb(168 85 247 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/100 {
  --tw-shadow-color: rgb(168 85 247 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/15 {
  --tw-shadow-color: rgb(168 85 247 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/20 {
  --tw-shadow-color: rgb(168 85 247 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/25 {
  --tw-shadow-color: rgb(168 85 247 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/30 {
  --tw-shadow-color: rgb(168 85 247 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/35 {
  --tw-shadow-color: rgb(168 85 247 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/40 {
  --tw-shadow-color: rgb(168 85 247 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/45 {
  --tw-shadow-color: rgb(168 85 247 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/5 {
  --tw-shadow-color: rgb(168 85 247 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/50 {
  --tw-shadow-color: rgb(168 85 247 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/55 {
  --tw-shadow-color: rgb(168 85 247 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/60 {
  --tw-shadow-color: rgb(168 85 247 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/65 {
  --tw-shadow-color: rgb(168 85 247 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/70 {
  --tw-shadow-color: rgb(168 85 247 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/75 {
  --tw-shadow-color: rgb(168 85 247 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/80 {
  --tw-shadow-color: rgb(168 85 247 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/85 {
  --tw-shadow-color: rgb(168 85 247 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/90 {
  --tw-shadow-color: rgb(168 85 247 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-500\/95 {
  --tw-shadow-color: rgb(168 85 247 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600 {
  --tw-shadow-color: #9333ea !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/0 {
  --tw-shadow-color: rgb(147 51 234 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/10 {
  --tw-shadow-color: rgb(147 51 234 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/100 {
  --tw-shadow-color: rgb(147 51 234 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/15 {
  --tw-shadow-color: rgb(147 51 234 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/20 {
  --tw-shadow-color: rgb(147 51 234 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/25 {
  --tw-shadow-color: rgb(147 51 234 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/30 {
  --tw-shadow-color: rgb(147 51 234 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/35 {
  --tw-shadow-color: rgb(147 51 234 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/40 {
  --tw-shadow-color: rgb(147 51 234 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/45 {
  --tw-shadow-color: rgb(147 51 234 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/5 {
  --tw-shadow-color: rgb(147 51 234 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/50 {
  --tw-shadow-color: rgb(147 51 234 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/55 {
  --tw-shadow-color: rgb(147 51 234 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/60 {
  --tw-shadow-color: rgb(147 51 234 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/65 {
  --tw-shadow-color: rgb(147 51 234 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/70 {
  --tw-shadow-color: rgb(147 51 234 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/75 {
  --tw-shadow-color: rgb(147 51 234 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/80 {
  --tw-shadow-color: rgb(147 51 234 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/85 {
  --tw-shadow-color: rgb(147 51 234 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/90 {
  --tw-shadow-color: rgb(147 51 234 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-600\/95 {
  --tw-shadow-color: rgb(147 51 234 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700 {
  --tw-shadow-color: #7e22ce !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/0 {
  --tw-shadow-color: rgb(126 34 206 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/10 {
  --tw-shadow-color: rgb(126 34 206 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/100 {
  --tw-shadow-color: rgb(126 34 206 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/15 {
  --tw-shadow-color: rgb(126 34 206 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/20 {
  --tw-shadow-color: rgb(126 34 206 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/25 {
  --tw-shadow-color: rgb(126 34 206 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/30 {
  --tw-shadow-color: rgb(126 34 206 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/35 {
  --tw-shadow-color: rgb(126 34 206 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/40 {
  --tw-shadow-color: rgb(126 34 206 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/45 {
  --tw-shadow-color: rgb(126 34 206 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/5 {
  --tw-shadow-color: rgb(126 34 206 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/50 {
  --tw-shadow-color: rgb(126 34 206 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/55 {
  --tw-shadow-color: rgb(126 34 206 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/60 {
  --tw-shadow-color: rgb(126 34 206 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/65 {
  --tw-shadow-color: rgb(126 34 206 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/70 {
  --tw-shadow-color: rgb(126 34 206 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/75 {
  --tw-shadow-color: rgb(126 34 206 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/80 {
  --tw-shadow-color: rgb(126 34 206 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/85 {
  --tw-shadow-color: rgb(126 34 206 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/90 {
  --tw-shadow-color: rgb(126 34 206 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-700\/95 {
  --tw-shadow-color: rgb(126 34 206 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800 {
  --tw-shadow-color: #6b21a8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/0 {
  --tw-shadow-color: rgb(107 33 168 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/10 {
  --tw-shadow-color: rgb(107 33 168 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/100 {
  --tw-shadow-color: rgb(107 33 168 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/15 {
  --tw-shadow-color: rgb(107 33 168 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/20 {
  --tw-shadow-color: rgb(107 33 168 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/25 {
  --tw-shadow-color: rgb(107 33 168 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/30 {
  --tw-shadow-color: rgb(107 33 168 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/35 {
  --tw-shadow-color: rgb(107 33 168 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/40 {
  --tw-shadow-color: rgb(107 33 168 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/45 {
  --tw-shadow-color: rgb(107 33 168 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/5 {
  --tw-shadow-color: rgb(107 33 168 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/50 {
  --tw-shadow-color: rgb(107 33 168 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/55 {
  --tw-shadow-color: rgb(107 33 168 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/60 {
  --tw-shadow-color: rgb(107 33 168 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/65 {
  --tw-shadow-color: rgb(107 33 168 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/70 {
  --tw-shadow-color: rgb(107 33 168 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/75 {
  --tw-shadow-color: rgb(107 33 168 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/80 {
  --tw-shadow-color: rgb(107 33 168 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/85 {
  --tw-shadow-color: rgb(107 33 168 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/90 {
  --tw-shadow-color: rgb(107 33 168 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-800\/95 {
  --tw-shadow-color: rgb(107 33 168 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900 {
  --tw-shadow-color: #581c87 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/0 {
  --tw-shadow-color: rgb(88 28 135 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/10 {
  --tw-shadow-color: rgb(88 28 135 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/100 {
  --tw-shadow-color: rgb(88 28 135 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/15 {
  --tw-shadow-color: rgb(88 28 135 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/20 {
  --tw-shadow-color: rgb(88 28 135 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/25 {
  --tw-shadow-color: rgb(88 28 135 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/30 {
  --tw-shadow-color: rgb(88 28 135 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/35 {
  --tw-shadow-color: rgb(88 28 135 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/40 {
  --tw-shadow-color: rgb(88 28 135 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/45 {
  --tw-shadow-color: rgb(88 28 135 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/5 {
  --tw-shadow-color: rgb(88 28 135 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/50 {
  --tw-shadow-color: rgb(88 28 135 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/55 {
  --tw-shadow-color: rgb(88 28 135 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/60 {
  --tw-shadow-color: rgb(88 28 135 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/65 {
  --tw-shadow-color: rgb(88 28 135 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/70 {
  --tw-shadow-color: rgb(88 28 135 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/75 {
  --tw-shadow-color: rgb(88 28 135 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/80 {
  --tw-shadow-color: rgb(88 28 135 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/85 {
  --tw-shadow-color: rgb(88 28 135 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/90 {
  --tw-shadow-color: rgb(88 28 135 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-900\/95 {
  --tw-shadow-color: rgb(88 28 135 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950 {
  --tw-shadow-color: #3b0764 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/0 {
  --tw-shadow-color: rgb(59 7 100 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/10 {
  --tw-shadow-color: rgb(59 7 100 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/100 {
  --tw-shadow-color: rgb(59 7 100 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/15 {
  --tw-shadow-color: rgb(59 7 100 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/20 {
  --tw-shadow-color: rgb(59 7 100 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/25 {
  --tw-shadow-color: rgb(59 7 100 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/30 {
  --tw-shadow-color: rgb(59 7 100 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/35 {
  --tw-shadow-color: rgb(59 7 100 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/40 {
  --tw-shadow-color: rgb(59 7 100 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/45 {
  --tw-shadow-color: rgb(59 7 100 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/5 {
  --tw-shadow-color: rgb(59 7 100 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/50 {
  --tw-shadow-color: rgb(59 7 100 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/55 {
  --tw-shadow-color: rgb(59 7 100 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/60 {
  --tw-shadow-color: rgb(59 7 100 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/65 {
  --tw-shadow-color: rgb(59 7 100 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/70 {
  --tw-shadow-color: rgb(59 7 100 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/75 {
  --tw-shadow-color: rgb(59 7 100 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/80 {
  --tw-shadow-color: rgb(59 7 100 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/85 {
  --tw-shadow-color: rgb(59 7 100 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/90 {
  --tw-shadow-color: rgb(59 7 100 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-purple-950\/95 {
  --tw-shadow-color: rgb(59 7 100 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100 {
  --tw-shadow-color: #fee2e2 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/0 {
  --tw-shadow-color: rgb(254 226 226 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/10 {
  --tw-shadow-color: rgb(254 226 226 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/100 {
  --tw-shadow-color: rgb(254 226 226 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/15 {
  --tw-shadow-color: rgb(254 226 226 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/20 {
  --tw-shadow-color: rgb(254 226 226 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/25 {
  --tw-shadow-color: rgb(254 226 226 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/30 {
  --tw-shadow-color: rgb(254 226 226 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/35 {
  --tw-shadow-color: rgb(254 226 226 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/40 {
  --tw-shadow-color: rgb(254 226 226 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/45 {
  --tw-shadow-color: rgb(254 226 226 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/5 {
  --tw-shadow-color: rgb(254 226 226 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/50 {
  --tw-shadow-color: rgb(254 226 226 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/55 {
  --tw-shadow-color: rgb(254 226 226 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/60 {
  --tw-shadow-color: rgb(254 226 226 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/65 {
  --tw-shadow-color: rgb(254 226 226 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/70 {
  --tw-shadow-color: rgb(254 226 226 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/75 {
  --tw-shadow-color: rgb(254 226 226 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/80 {
  --tw-shadow-color: rgb(254 226 226 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/85 {
  --tw-shadow-color: rgb(254 226 226 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/90 {
  --tw-shadow-color: rgb(254 226 226 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-100\/95 {
  --tw-shadow-color: rgb(254 226 226 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200 {
  --tw-shadow-color: #fecaca !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/0 {
  --tw-shadow-color: rgb(254 202 202 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/10 {
  --tw-shadow-color: rgb(254 202 202 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/100 {
  --tw-shadow-color: rgb(254 202 202 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/15 {
  --tw-shadow-color: rgb(254 202 202 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/20 {
  --tw-shadow-color: rgb(254 202 202 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/25 {
  --tw-shadow-color: rgb(254 202 202 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/30 {
  --tw-shadow-color: rgb(254 202 202 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/35 {
  --tw-shadow-color: rgb(254 202 202 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/40 {
  --tw-shadow-color: rgb(254 202 202 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/45 {
  --tw-shadow-color: rgb(254 202 202 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/5 {
  --tw-shadow-color: rgb(254 202 202 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/50 {
  --tw-shadow-color: rgb(254 202 202 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/55 {
  --tw-shadow-color: rgb(254 202 202 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/60 {
  --tw-shadow-color: rgb(254 202 202 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/65 {
  --tw-shadow-color: rgb(254 202 202 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/70 {
  --tw-shadow-color: rgb(254 202 202 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/75 {
  --tw-shadow-color: rgb(254 202 202 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/80 {
  --tw-shadow-color: rgb(254 202 202 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/85 {
  --tw-shadow-color: rgb(254 202 202 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/90 {
  --tw-shadow-color: rgb(254 202 202 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-200\/95 {
  --tw-shadow-color: rgb(254 202 202 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300 {
  --tw-shadow-color: #fca5a5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/0 {
  --tw-shadow-color: rgb(252 165 165 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/10 {
  --tw-shadow-color: rgb(252 165 165 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/100 {
  --tw-shadow-color: rgb(252 165 165 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/15 {
  --tw-shadow-color: rgb(252 165 165 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/20 {
  --tw-shadow-color: rgb(252 165 165 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/25 {
  --tw-shadow-color: rgb(252 165 165 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/30 {
  --tw-shadow-color: rgb(252 165 165 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/35 {
  --tw-shadow-color: rgb(252 165 165 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/40 {
  --tw-shadow-color: rgb(252 165 165 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/45 {
  --tw-shadow-color: rgb(252 165 165 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/5 {
  --tw-shadow-color: rgb(252 165 165 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/50 {
  --tw-shadow-color: rgb(252 165 165 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/55 {
  --tw-shadow-color: rgb(252 165 165 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/60 {
  --tw-shadow-color: rgb(252 165 165 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/65 {
  --tw-shadow-color: rgb(252 165 165 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/70 {
  --tw-shadow-color: rgb(252 165 165 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/75 {
  --tw-shadow-color: rgb(252 165 165 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/80 {
  --tw-shadow-color: rgb(252 165 165 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/85 {
  --tw-shadow-color: rgb(252 165 165 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/90 {
  --tw-shadow-color: rgb(252 165 165 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-300\/95 {
  --tw-shadow-color: rgb(252 165 165 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400 {
  --tw-shadow-color: #f87171 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/0 {
  --tw-shadow-color: rgb(248 113 113 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/10 {
  --tw-shadow-color: rgb(248 113 113 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/100 {
  --tw-shadow-color: rgb(248 113 113 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/15 {
  --tw-shadow-color: rgb(248 113 113 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/20 {
  --tw-shadow-color: rgb(248 113 113 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/25 {
  --tw-shadow-color: rgb(248 113 113 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/30 {
  --tw-shadow-color: rgb(248 113 113 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/35 {
  --tw-shadow-color: rgb(248 113 113 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/40 {
  --tw-shadow-color: rgb(248 113 113 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/45 {
  --tw-shadow-color: rgb(248 113 113 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/5 {
  --tw-shadow-color: rgb(248 113 113 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/50 {
  --tw-shadow-color: rgb(248 113 113 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/55 {
  --tw-shadow-color: rgb(248 113 113 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/60 {
  --tw-shadow-color: rgb(248 113 113 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/65 {
  --tw-shadow-color: rgb(248 113 113 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/70 {
  --tw-shadow-color: rgb(248 113 113 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/75 {
  --tw-shadow-color: rgb(248 113 113 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/80 {
  --tw-shadow-color: rgb(248 113 113 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/85 {
  --tw-shadow-color: rgb(248 113 113 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/90 {
  --tw-shadow-color: rgb(248 113 113 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-400\/95 {
  --tw-shadow-color: rgb(248 113 113 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50 {
  --tw-shadow-color: #fef2f2 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/0 {
  --tw-shadow-color: rgb(254 242 242 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/10 {
  --tw-shadow-color: rgb(254 242 242 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/100 {
  --tw-shadow-color: rgb(254 242 242 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/15 {
  --tw-shadow-color: rgb(254 242 242 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/20 {
  --tw-shadow-color: rgb(254 242 242 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/25 {
  --tw-shadow-color: rgb(254 242 242 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/30 {
  --tw-shadow-color: rgb(254 242 242 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/35 {
  --tw-shadow-color: rgb(254 242 242 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/40 {
  --tw-shadow-color: rgb(254 242 242 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/45 {
  --tw-shadow-color: rgb(254 242 242 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/5 {
  --tw-shadow-color: rgb(254 242 242 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/50 {
  --tw-shadow-color: rgb(254 242 242 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/55 {
  --tw-shadow-color: rgb(254 242 242 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/60 {
  --tw-shadow-color: rgb(254 242 242 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/65 {
  --tw-shadow-color: rgb(254 242 242 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/70 {
  --tw-shadow-color: rgb(254 242 242 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/75 {
  --tw-shadow-color: rgb(254 242 242 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/80 {
  --tw-shadow-color: rgb(254 242 242 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/85 {
  --tw-shadow-color: rgb(254 242 242 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/90 {
  --tw-shadow-color: rgb(254 242 242 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-50\/95 {
  --tw-shadow-color: rgb(254 242 242 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500 {
  --tw-shadow-color: #ef4444 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/0 {
  --tw-shadow-color: rgb(239 68 68 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/10 {
  --tw-shadow-color: rgb(239 68 68 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/100 {
  --tw-shadow-color: rgb(239 68 68 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/15 {
  --tw-shadow-color: rgb(239 68 68 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/20 {
  --tw-shadow-color: rgb(239 68 68 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/25 {
  --tw-shadow-color: rgb(239 68 68 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/30 {
  --tw-shadow-color: rgb(239 68 68 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/35 {
  --tw-shadow-color: rgb(239 68 68 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/40 {
  --tw-shadow-color: rgb(239 68 68 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/45 {
  --tw-shadow-color: rgb(239 68 68 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/5 {
  --tw-shadow-color: rgb(239 68 68 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/50 {
  --tw-shadow-color: rgb(239 68 68 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/55 {
  --tw-shadow-color: rgb(239 68 68 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/60 {
  --tw-shadow-color: rgb(239 68 68 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/65 {
  --tw-shadow-color: rgb(239 68 68 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/70 {
  --tw-shadow-color: rgb(239 68 68 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/75 {
  --tw-shadow-color: rgb(239 68 68 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/80 {
  --tw-shadow-color: rgb(239 68 68 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/85 {
  --tw-shadow-color: rgb(239 68 68 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/90 {
  --tw-shadow-color: rgb(239 68 68 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-500\/95 {
  --tw-shadow-color: rgb(239 68 68 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600 {
  --tw-shadow-color: #dc2626 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/0 {
  --tw-shadow-color: rgb(220 38 38 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/10 {
  --tw-shadow-color: rgb(220 38 38 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/100 {
  --tw-shadow-color: rgb(220 38 38 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/15 {
  --tw-shadow-color: rgb(220 38 38 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/20 {
  --tw-shadow-color: rgb(220 38 38 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/25 {
  --tw-shadow-color: rgb(220 38 38 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/30 {
  --tw-shadow-color: rgb(220 38 38 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/35 {
  --tw-shadow-color: rgb(220 38 38 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/40 {
  --tw-shadow-color: rgb(220 38 38 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/45 {
  --tw-shadow-color: rgb(220 38 38 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/5 {
  --tw-shadow-color: rgb(220 38 38 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/50 {
  --tw-shadow-color: rgb(220 38 38 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/55 {
  --tw-shadow-color: rgb(220 38 38 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/60 {
  --tw-shadow-color: rgb(220 38 38 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/65 {
  --tw-shadow-color: rgb(220 38 38 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/70 {
  --tw-shadow-color: rgb(220 38 38 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/75 {
  --tw-shadow-color: rgb(220 38 38 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/80 {
  --tw-shadow-color: rgb(220 38 38 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/85 {
  --tw-shadow-color: rgb(220 38 38 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/90 {
  --tw-shadow-color: rgb(220 38 38 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-600\/95 {
  --tw-shadow-color: rgb(220 38 38 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700 {
  --tw-shadow-color: #b91c1c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/0 {
  --tw-shadow-color: rgb(185 28 28 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/10 {
  --tw-shadow-color: rgb(185 28 28 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/100 {
  --tw-shadow-color: rgb(185 28 28 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/15 {
  --tw-shadow-color: rgb(185 28 28 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/20 {
  --tw-shadow-color: rgb(185 28 28 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/25 {
  --tw-shadow-color: rgb(185 28 28 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/30 {
  --tw-shadow-color: rgb(185 28 28 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/35 {
  --tw-shadow-color: rgb(185 28 28 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/40 {
  --tw-shadow-color: rgb(185 28 28 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/45 {
  --tw-shadow-color: rgb(185 28 28 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/5 {
  --tw-shadow-color: rgb(185 28 28 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/50 {
  --tw-shadow-color: rgb(185 28 28 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/55 {
  --tw-shadow-color: rgb(185 28 28 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/60 {
  --tw-shadow-color: rgb(185 28 28 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/65 {
  --tw-shadow-color: rgb(185 28 28 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/70 {
  --tw-shadow-color: rgb(185 28 28 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/75 {
  --tw-shadow-color: rgb(185 28 28 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/80 {
  --tw-shadow-color: rgb(185 28 28 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/85 {
  --tw-shadow-color: rgb(185 28 28 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/90 {
  --tw-shadow-color: rgb(185 28 28 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-700\/95 {
  --tw-shadow-color: rgb(185 28 28 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800 {
  --tw-shadow-color: #991b1b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/0 {
  --tw-shadow-color: rgb(153 27 27 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/10 {
  --tw-shadow-color: rgb(153 27 27 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/100 {
  --tw-shadow-color: rgb(153 27 27 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/15 {
  --tw-shadow-color: rgb(153 27 27 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/20 {
  --tw-shadow-color: rgb(153 27 27 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/25 {
  --tw-shadow-color: rgb(153 27 27 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/30 {
  --tw-shadow-color: rgb(153 27 27 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/35 {
  --tw-shadow-color: rgb(153 27 27 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/40 {
  --tw-shadow-color: rgb(153 27 27 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/45 {
  --tw-shadow-color: rgb(153 27 27 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/5 {
  --tw-shadow-color: rgb(153 27 27 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/50 {
  --tw-shadow-color: rgb(153 27 27 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/55 {
  --tw-shadow-color: rgb(153 27 27 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/60 {
  --tw-shadow-color: rgb(153 27 27 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/65 {
  --tw-shadow-color: rgb(153 27 27 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/70 {
  --tw-shadow-color: rgb(153 27 27 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/75 {
  --tw-shadow-color: rgb(153 27 27 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/80 {
  --tw-shadow-color: rgb(153 27 27 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/85 {
  --tw-shadow-color: rgb(153 27 27 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/90 {
  --tw-shadow-color: rgb(153 27 27 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-800\/95 {
  --tw-shadow-color: rgb(153 27 27 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900 {
  --tw-shadow-color: #7f1d1d !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/0 {
  --tw-shadow-color: rgb(127 29 29 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/10 {
  --tw-shadow-color: rgb(127 29 29 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/100 {
  --tw-shadow-color: rgb(127 29 29 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/15 {
  --tw-shadow-color: rgb(127 29 29 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/20 {
  --tw-shadow-color: rgb(127 29 29 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/25 {
  --tw-shadow-color: rgb(127 29 29 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/30 {
  --tw-shadow-color: rgb(127 29 29 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/35 {
  --tw-shadow-color: rgb(127 29 29 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/40 {
  --tw-shadow-color: rgb(127 29 29 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/45 {
  --tw-shadow-color: rgb(127 29 29 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/5 {
  --tw-shadow-color: rgb(127 29 29 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/50 {
  --tw-shadow-color: rgb(127 29 29 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/55 {
  --tw-shadow-color: rgb(127 29 29 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/60 {
  --tw-shadow-color: rgb(127 29 29 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/65 {
  --tw-shadow-color: rgb(127 29 29 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/70 {
  --tw-shadow-color: rgb(127 29 29 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/75 {
  --tw-shadow-color: rgb(127 29 29 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/80 {
  --tw-shadow-color: rgb(127 29 29 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/85 {
  --tw-shadow-color: rgb(127 29 29 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/90 {
  --tw-shadow-color: rgb(127 29 29 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-900\/95 {
  --tw-shadow-color: rgb(127 29 29 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950 {
  --tw-shadow-color: #450a0a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/0 {
  --tw-shadow-color: rgb(69 10 10 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/10 {
  --tw-shadow-color: rgb(69 10 10 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/100 {
  --tw-shadow-color: rgb(69 10 10 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/15 {
  --tw-shadow-color: rgb(69 10 10 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/20 {
  --tw-shadow-color: rgb(69 10 10 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/25 {
  --tw-shadow-color: rgb(69 10 10 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/30 {
  --tw-shadow-color: rgb(69 10 10 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/35 {
  --tw-shadow-color: rgb(69 10 10 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/40 {
  --tw-shadow-color: rgb(69 10 10 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/45 {
  --tw-shadow-color: rgb(69 10 10 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/5 {
  --tw-shadow-color: rgb(69 10 10 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/50 {
  --tw-shadow-color: rgb(69 10 10 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/55 {
  --tw-shadow-color: rgb(69 10 10 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/60 {
  --tw-shadow-color: rgb(69 10 10 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/65 {
  --tw-shadow-color: rgb(69 10 10 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/70 {
  --tw-shadow-color: rgb(69 10 10 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/75 {
  --tw-shadow-color: rgb(69 10 10 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/80 {
  --tw-shadow-color: rgb(69 10 10 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/85 {
  --tw-shadow-color: rgb(69 10 10 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/90 {
  --tw-shadow-color: rgb(69 10 10 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-red-950\/95 {
  --tw-shadow-color: rgb(69 10 10 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100 {
  --tw-shadow-color: #ffe4e6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/0 {
  --tw-shadow-color: rgb(255 228 230 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/10 {
  --tw-shadow-color: rgb(255 228 230 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/100 {
  --tw-shadow-color: rgb(255 228 230 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/15 {
  --tw-shadow-color: rgb(255 228 230 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/20 {
  --tw-shadow-color: rgb(255 228 230 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/25 {
  --tw-shadow-color: rgb(255 228 230 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/30 {
  --tw-shadow-color: rgb(255 228 230 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/35 {
  --tw-shadow-color: rgb(255 228 230 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/40 {
  --tw-shadow-color: rgb(255 228 230 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/45 {
  --tw-shadow-color: rgb(255 228 230 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/5 {
  --tw-shadow-color: rgb(255 228 230 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/50 {
  --tw-shadow-color: rgb(255 228 230 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/55 {
  --tw-shadow-color: rgb(255 228 230 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/60 {
  --tw-shadow-color: rgb(255 228 230 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/65 {
  --tw-shadow-color: rgb(255 228 230 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/70 {
  --tw-shadow-color: rgb(255 228 230 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/75 {
  --tw-shadow-color: rgb(255 228 230 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/80 {
  --tw-shadow-color: rgb(255 228 230 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/85 {
  --tw-shadow-color: rgb(255 228 230 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/90 {
  --tw-shadow-color: rgb(255 228 230 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-100\/95 {
  --tw-shadow-color: rgb(255 228 230 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200 {
  --tw-shadow-color: #fecdd3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/0 {
  --tw-shadow-color: rgb(254 205 211 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/10 {
  --tw-shadow-color: rgb(254 205 211 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/100 {
  --tw-shadow-color: rgb(254 205 211 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/15 {
  --tw-shadow-color: rgb(254 205 211 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/20 {
  --tw-shadow-color: rgb(254 205 211 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/25 {
  --tw-shadow-color: rgb(254 205 211 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/30 {
  --tw-shadow-color: rgb(254 205 211 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/35 {
  --tw-shadow-color: rgb(254 205 211 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/40 {
  --tw-shadow-color: rgb(254 205 211 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/45 {
  --tw-shadow-color: rgb(254 205 211 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/5 {
  --tw-shadow-color: rgb(254 205 211 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/50 {
  --tw-shadow-color: rgb(254 205 211 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/55 {
  --tw-shadow-color: rgb(254 205 211 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/60 {
  --tw-shadow-color: rgb(254 205 211 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/65 {
  --tw-shadow-color: rgb(254 205 211 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/70 {
  --tw-shadow-color: rgb(254 205 211 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/75 {
  --tw-shadow-color: rgb(254 205 211 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/80 {
  --tw-shadow-color: rgb(254 205 211 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/85 {
  --tw-shadow-color: rgb(254 205 211 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/90 {
  --tw-shadow-color: rgb(254 205 211 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-200\/95 {
  --tw-shadow-color: rgb(254 205 211 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300 {
  --tw-shadow-color: #fda4af !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/0 {
  --tw-shadow-color: rgb(253 164 175 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/10 {
  --tw-shadow-color: rgb(253 164 175 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/100 {
  --tw-shadow-color: rgb(253 164 175 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/15 {
  --tw-shadow-color: rgb(253 164 175 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/20 {
  --tw-shadow-color: rgb(253 164 175 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/25 {
  --tw-shadow-color: rgb(253 164 175 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/30 {
  --tw-shadow-color: rgb(253 164 175 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/35 {
  --tw-shadow-color: rgb(253 164 175 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/40 {
  --tw-shadow-color: rgb(253 164 175 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/45 {
  --tw-shadow-color: rgb(253 164 175 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/5 {
  --tw-shadow-color: rgb(253 164 175 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/50 {
  --tw-shadow-color: rgb(253 164 175 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/55 {
  --tw-shadow-color: rgb(253 164 175 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/60 {
  --tw-shadow-color: rgb(253 164 175 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/65 {
  --tw-shadow-color: rgb(253 164 175 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/70 {
  --tw-shadow-color: rgb(253 164 175 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/75 {
  --tw-shadow-color: rgb(253 164 175 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/80 {
  --tw-shadow-color: rgb(253 164 175 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/85 {
  --tw-shadow-color: rgb(253 164 175 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/90 {
  --tw-shadow-color: rgb(253 164 175 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-300\/95 {
  --tw-shadow-color: rgb(253 164 175 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400 {
  --tw-shadow-color: #fb7185 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/0 {
  --tw-shadow-color: rgb(251 113 133 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/10 {
  --tw-shadow-color: rgb(251 113 133 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/100 {
  --tw-shadow-color: rgb(251 113 133 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/15 {
  --tw-shadow-color: rgb(251 113 133 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/20 {
  --tw-shadow-color: rgb(251 113 133 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/25 {
  --tw-shadow-color: rgb(251 113 133 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/30 {
  --tw-shadow-color: rgb(251 113 133 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/35 {
  --tw-shadow-color: rgb(251 113 133 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/40 {
  --tw-shadow-color: rgb(251 113 133 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/45 {
  --tw-shadow-color: rgb(251 113 133 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/5 {
  --tw-shadow-color: rgb(251 113 133 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/50 {
  --tw-shadow-color: rgb(251 113 133 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/55 {
  --tw-shadow-color: rgb(251 113 133 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/60 {
  --tw-shadow-color: rgb(251 113 133 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/65 {
  --tw-shadow-color: rgb(251 113 133 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/70 {
  --tw-shadow-color: rgb(251 113 133 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/75 {
  --tw-shadow-color: rgb(251 113 133 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/80 {
  --tw-shadow-color: rgb(251 113 133 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/85 {
  --tw-shadow-color: rgb(251 113 133 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/90 {
  --tw-shadow-color: rgb(251 113 133 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-400\/95 {
  --tw-shadow-color: rgb(251 113 133 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50 {
  --tw-shadow-color: #fff1f2 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/0 {
  --tw-shadow-color: rgb(255 241 242 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/10 {
  --tw-shadow-color: rgb(255 241 242 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/100 {
  --tw-shadow-color: rgb(255 241 242 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/15 {
  --tw-shadow-color: rgb(255 241 242 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/20 {
  --tw-shadow-color: rgb(255 241 242 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/25 {
  --tw-shadow-color: rgb(255 241 242 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/30 {
  --tw-shadow-color: rgb(255 241 242 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/35 {
  --tw-shadow-color: rgb(255 241 242 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/40 {
  --tw-shadow-color: rgb(255 241 242 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/45 {
  --tw-shadow-color: rgb(255 241 242 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/5 {
  --tw-shadow-color: rgb(255 241 242 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/50 {
  --tw-shadow-color: rgb(255 241 242 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/55 {
  --tw-shadow-color: rgb(255 241 242 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/60 {
  --tw-shadow-color: rgb(255 241 242 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/65 {
  --tw-shadow-color: rgb(255 241 242 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/70 {
  --tw-shadow-color: rgb(255 241 242 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/75 {
  --tw-shadow-color: rgb(255 241 242 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/80 {
  --tw-shadow-color: rgb(255 241 242 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/85 {
  --tw-shadow-color: rgb(255 241 242 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/90 {
  --tw-shadow-color: rgb(255 241 242 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-50\/95 {
  --tw-shadow-color: rgb(255 241 242 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500 {
  --tw-shadow-color: #f43f5e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/0 {
  --tw-shadow-color: rgb(244 63 94 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/10 {
  --tw-shadow-color: rgb(244 63 94 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/100 {
  --tw-shadow-color: rgb(244 63 94 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/15 {
  --tw-shadow-color: rgb(244 63 94 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/20 {
  --tw-shadow-color: rgb(244 63 94 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/25 {
  --tw-shadow-color: rgb(244 63 94 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/30 {
  --tw-shadow-color: rgb(244 63 94 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/35 {
  --tw-shadow-color: rgb(244 63 94 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/40 {
  --tw-shadow-color: rgb(244 63 94 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/45 {
  --tw-shadow-color: rgb(244 63 94 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/5 {
  --tw-shadow-color: rgb(244 63 94 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/50 {
  --tw-shadow-color: rgb(244 63 94 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/55 {
  --tw-shadow-color: rgb(244 63 94 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/60 {
  --tw-shadow-color: rgb(244 63 94 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/65 {
  --tw-shadow-color: rgb(244 63 94 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/70 {
  --tw-shadow-color: rgb(244 63 94 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/75 {
  --tw-shadow-color: rgb(244 63 94 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/80 {
  --tw-shadow-color: rgb(244 63 94 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/85 {
  --tw-shadow-color: rgb(244 63 94 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/90 {
  --tw-shadow-color: rgb(244 63 94 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-500\/95 {
  --tw-shadow-color: rgb(244 63 94 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600 {
  --tw-shadow-color: #e11d48 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/0 {
  --tw-shadow-color: rgb(225 29 72 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/10 {
  --tw-shadow-color: rgb(225 29 72 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/100 {
  --tw-shadow-color: rgb(225 29 72 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/15 {
  --tw-shadow-color: rgb(225 29 72 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/20 {
  --tw-shadow-color: rgb(225 29 72 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/25 {
  --tw-shadow-color: rgb(225 29 72 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/30 {
  --tw-shadow-color: rgb(225 29 72 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/35 {
  --tw-shadow-color: rgb(225 29 72 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/40 {
  --tw-shadow-color: rgb(225 29 72 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/45 {
  --tw-shadow-color: rgb(225 29 72 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/5 {
  --tw-shadow-color: rgb(225 29 72 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/50 {
  --tw-shadow-color: rgb(225 29 72 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/55 {
  --tw-shadow-color: rgb(225 29 72 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/60 {
  --tw-shadow-color: rgb(225 29 72 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/65 {
  --tw-shadow-color: rgb(225 29 72 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/70 {
  --tw-shadow-color: rgb(225 29 72 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/75 {
  --tw-shadow-color: rgb(225 29 72 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/80 {
  --tw-shadow-color: rgb(225 29 72 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/85 {
  --tw-shadow-color: rgb(225 29 72 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/90 {
  --tw-shadow-color: rgb(225 29 72 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-600\/95 {
  --tw-shadow-color: rgb(225 29 72 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700 {
  --tw-shadow-color: #be123c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/0 {
  --tw-shadow-color: rgb(190 18 60 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/10 {
  --tw-shadow-color: rgb(190 18 60 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/100 {
  --tw-shadow-color: rgb(190 18 60 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/15 {
  --tw-shadow-color: rgb(190 18 60 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/20 {
  --tw-shadow-color: rgb(190 18 60 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/25 {
  --tw-shadow-color: rgb(190 18 60 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/30 {
  --tw-shadow-color: rgb(190 18 60 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/35 {
  --tw-shadow-color: rgb(190 18 60 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/40 {
  --tw-shadow-color: rgb(190 18 60 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/45 {
  --tw-shadow-color: rgb(190 18 60 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/5 {
  --tw-shadow-color: rgb(190 18 60 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/50 {
  --tw-shadow-color: rgb(190 18 60 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/55 {
  --tw-shadow-color: rgb(190 18 60 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/60 {
  --tw-shadow-color: rgb(190 18 60 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/65 {
  --tw-shadow-color: rgb(190 18 60 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/70 {
  --tw-shadow-color: rgb(190 18 60 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/75 {
  --tw-shadow-color: rgb(190 18 60 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/80 {
  --tw-shadow-color: rgb(190 18 60 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/85 {
  --tw-shadow-color: rgb(190 18 60 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/90 {
  --tw-shadow-color: rgb(190 18 60 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-700\/95 {
  --tw-shadow-color: rgb(190 18 60 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800 {
  --tw-shadow-color: #9f1239 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/0 {
  --tw-shadow-color: rgb(159 18 57 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/10 {
  --tw-shadow-color: rgb(159 18 57 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/100 {
  --tw-shadow-color: rgb(159 18 57 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/15 {
  --tw-shadow-color: rgb(159 18 57 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/20 {
  --tw-shadow-color: rgb(159 18 57 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/25 {
  --tw-shadow-color: rgb(159 18 57 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/30 {
  --tw-shadow-color: rgb(159 18 57 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/35 {
  --tw-shadow-color: rgb(159 18 57 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/40 {
  --tw-shadow-color: rgb(159 18 57 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/45 {
  --tw-shadow-color: rgb(159 18 57 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/5 {
  --tw-shadow-color: rgb(159 18 57 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/50 {
  --tw-shadow-color: rgb(159 18 57 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/55 {
  --tw-shadow-color: rgb(159 18 57 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/60 {
  --tw-shadow-color: rgb(159 18 57 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/65 {
  --tw-shadow-color: rgb(159 18 57 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/70 {
  --tw-shadow-color: rgb(159 18 57 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/75 {
  --tw-shadow-color: rgb(159 18 57 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/80 {
  --tw-shadow-color: rgb(159 18 57 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/85 {
  --tw-shadow-color: rgb(159 18 57 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/90 {
  --tw-shadow-color: rgb(159 18 57 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-800\/95 {
  --tw-shadow-color: rgb(159 18 57 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900 {
  --tw-shadow-color: #881337 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/0 {
  --tw-shadow-color: rgb(136 19 55 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/10 {
  --tw-shadow-color: rgb(136 19 55 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/100 {
  --tw-shadow-color: rgb(136 19 55 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/15 {
  --tw-shadow-color: rgb(136 19 55 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/20 {
  --tw-shadow-color: rgb(136 19 55 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/25 {
  --tw-shadow-color: rgb(136 19 55 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/30 {
  --tw-shadow-color: rgb(136 19 55 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/35 {
  --tw-shadow-color: rgb(136 19 55 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/40 {
  --tw-shadow-color: rgb(136 19 55 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/45 {
  --tw-shadow-color: rgb(136 19 55 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/5 {
  --tw-shadow-color: rgb(136 19 55 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/50 {
  --tw-shadow-color: rgb(136 19 55 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/55 {
  --tw-shadow-color: rgb(136 19 55 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/60 {
  --tw-shadow-color: rgb(136 19 55 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/65 {
  --tw-shadow-color: rgb(136 19 55 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/70 {
  --tw-shadow-color: rgb(136 19 55 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/75 {
  --tw-shadow-color: rgb(136 19 55 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/80 {
  --tw-shadow-color: rgb(136 19 55 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/85 {
  --tw-shadow-color: rgb(136 19 55 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/90 {
  --tw-shadow-color: rgb(136 19 55 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-900\/95 {
  --tw-shadow-color: rgb(136 19 55 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950 {
  --tw-shadow-color: #4c0519 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/0 {
  --tw-shadow-color: rgb(76 5 25 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/10 {
  --tw-shadow-color: rgb(76 5 25 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/100 {
  --tw-shadow-color: rgb(76 5 25 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/15 {
  --tw-shadow-color: rgb(76 5 25 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/20 {
  --tw-shadow-color: rgb(76 5 25 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/25 {
  --tw-shadow-color: rgb(76 5 25 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/30 {
  --tw-shadow-color: rgb(76 5 25 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/35 {
  --tw-shadow-color: rgb(76 5 25 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/40 {
  --tw-shadow-color: rgb(76 5 25 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/45 {
  --tw-shadow-color: rgb(76 5 25 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/5 {
  --tw-shadow-color: rgb(76 5 25 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/50 {
  --tw-shadow-color: rgb(76 5 25 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/55 {
  --tw-shadow-color: rgb(76 5 25 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/60 {
  --tw-shadow-color: rgb(76 5 25 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/65 {
  --tw-shadow-color: rgb(76 5 25 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/70 {
  --tw-shadow-color: rgb(76 5 25 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/75 {
  --tw-shadow-color: rgb(76 5 25 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/80 {
  --tw-shadow-color: rgb(76 5 25 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/85 {
  --tw-shadow-color: rgb(76 5 25 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/90 {
  --tw-shadow-color: rgb(76 5 25 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-rose-950\/95 {
  --tw-shadow-color: rgb(76 5 25 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100 {
  --tw-shadow-color: #e0f2fe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/0 {
  --tw-shadow-color: rgb(224 242 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/10 {
  --tw-shadow-color: rgb(224 242 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/100 {
  --tw-shadow-color: rgb(224 242 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/15 {
  --tw-shadow-color: rgb(224 242 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/20 {
  --tw-shadow-color: rgb(224 242 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/25 {
  --tw-shadow-color: rgb(224 242 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/30 {
  --tw-shadow-color: rgb(224 242 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/35 {
  --tw-shadow-color: rgb(224 242 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/40 {
  --tw-shadow-color: rgb(224 242 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/45 {
  --tw-shadow-color: rgb(224 242 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/5 {
  --tw-shadow-color: rgb(224 242 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/50 {
  --tw-shadow-color: rgb(224 242 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/55 {
  --tw-shadow-color: rgb(224 242 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/60 {
  --tw-shadow-color: rgb(224 242 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/65 {
  --tw-shadow-color: rgb(224 242 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/70 {
  --tw-shadow-color: rgb(224 242 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/75 {
  --tw-shadow-color: rgb(224 242 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/80 {
  --tw-shadow-color: rgb(224 242 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/85 {
  --tw-shadow-color: rgb(224 242 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/90 {
  --tw-shadow-color: rgb(224 242 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-100\/95 {
  --tw-shadow-color: rgb(224 242 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200 {
  --tw-shadow-color: #bae6fd !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/0 {
  --tw-shadow-color: rgb(186 230 253 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/10 {
  --tw-shadow-color: rgb(186 230 253 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/100 {
  --tw-shadow-color: rgb(186 230 253 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/15 {
  --tw-shadow-color: rgb(186 230 253 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/20 {
  --tw-shadow-color: rgb(186 230 253 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/25 {
  --tw-shadow-color: rgb(186 230 253 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/30 {
  --tw-shadow-color: rgb(186 230 253 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/35 {
  --tw-shadow-color: rgb(186 230 253 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/40 {
  --tw-shadow-color: rgb(186 230 253 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/45 {
  --tw-shadow-color: rgb(186 230 253 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/5 {
  --tw-shadow-color: rgb(186 230 253 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/50 {
  --tw-shadow-color: rgb(186 230 253 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/55 {
  --tw-shadow-color: rgb(186 230 253 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/60 {
  --tw-shadow-color: rgb(186 230 253 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/65 {
  --tw-shadow-color: rgb(186 230 253 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/70 {
  --tw-shadow-color: rgb(186 230 253 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/75 {
  --tw-shadow-color: rgb(186 230 253 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/80 {
  --tw-shadow-color: rgb(186 230 253 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/85 {
  --tw-shadow-color: rgb(186 230 253 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/90 {
  --tw-shadow-color: rgb(186 230 253 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-200\/95 {
  --tw-shadow-color: rgb(186 230 253 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300 {
  --tw-shadow-color: #7dd3fc !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/0 {
  --tw-shadow-color: rgb(125 211 252 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/10 {
  --tw-shadow-color: rgb(125 211 252 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/100 {
  --tw-shadow-color: rgb(125 211 252 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/15 {
  --tw-shadow-color: rgb(125 211 252 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/20 {
  --tw-shadow-color: rgb(125 211 252 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/25 {
  --tw-shadow-color: rgb(125 211 252 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/30 {
  --tw-shadow-color: rgb(125 211 252 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/35 {
  --tw-shadow-color: rgb(125 211 252 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/40 {
  --tw-shadow-color: rgb(125 211 252 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/45 {
  --tw-shadow-color: rgb(125 211 252 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/5 {
  --tw-shadow-color: rgb(125 211 252 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/50 {
  --tw-shadow-color: rgb(125 211 252 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/55 {
  --tw-shadow-color: rgb(125 211 252 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/60 {
  --tw-shadow-color: rgb(125 211 252 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/65 {
  --tw-shadow-color: rgb(125 211 252 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/70 {
  --tw-shadow-color: rgb(125 211 252 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/75 {
  --tw-shadow-color: rgb(125 211 252 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/80 {
  --tw-shadow-color: rgb(125 211 252 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/85 {
  --tw-shadow-color: rgb(125 211 252 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/90 {
  --tw-shadow-color: rgb(125 211 252 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-300\/95 {
  --tw-shadow-color: rgb(125 211 252 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400 {
  --tw-shadow-color: #38bdf8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/0 {
  --tw-shadow-color: rgb(56 189 248 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/10 {
  --tw-shadow-color: rgb(56 189 248 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/100 {
  --tw-shadow-color: rgb(56 189 248 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/15 {
  --tw-shadow-color: rgb(56 189 248 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/20 {
  --tw-shadow-color: rgb(56 189 248 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/25 {
  --tw-shadow-color: rgb(56 189 248 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/30 {
  --tw-shadow-color: rgb(56 189 248 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/35 {
  --tw-shadow-color: rgb(56 189 248 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/40 {
  --tw-shadow-color: rgb(56 189 248 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/45 {
  --tw-shadow-color: rgb(56 189 248 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/5 {
  --tw-shadow-color: rgb(56 189 248 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/50 {
  --tw-shadow-color: rgb(56 189 248 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/55 {
  --tw-shadow-color: rgb(56 189 248 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/60 {
  --tw-shadow-color: rgb(56 189 248 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/65 {
  --tw-shadow-color: rgb(56 189 248 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/70 {
  --tw-shadow-color: rgb(56 189 248 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/75 {
  --tw-shadow-color: rgb(56 189 248 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/80 {
  --tw-shadow-color: rgb(56 189 248 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/85 {
  --tw-shadow-color: rgb(56 189 248 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/90 {
  --tw-shadow-color: rgb(56 189 248 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-400\/95 {
  --tw-shadow-color: rgb(56 189 248 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50 {
  --tw-shadow-color: #f0f9ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/0 {
  --tw-shadow-color: rgb(240 249 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/10 {
  --tw-shadow-color: rgb(240 249 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/100 {
  --tw-shadow-color: rgb(240 249 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/15 {
  --tw-shadow-color: rgb(240 249 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/20 {
  --tw-shadow-color: rgb(240 249 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/25 {
  --tw-shadow-color: rgb(240 249 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/30 {
  --tw-shadow-color: rgb(240 249 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/35 {
  --tw-shadow-color: rgb(240 249 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/40 {
  --tw-shadow-color: rgb(240 249 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/45 {
  --tw-shadow-color: rgb(240 249 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/5 {
  --tw-shadow-color: rgb(240 249 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/50 {
  --tw-shadow-color: rgb(240 249 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/55 {
  --tw-shadow-color: rgb(240 249 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/60 {
  --tw-shadow-color: rgb(240 249 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/65 {
  --tw-shadow-color: rgb(240 249 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/70 {
  --tw-shadow-color: rgb(240 249 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/75 {
  --tw-shadow-color: rgb(240 249 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/80 {
  --tw-shadow-color: rgb(240 249 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/85 {
  --tw-shadow-color: rgb(240 249 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/90 {
  --tw-shadow-color: rgb(240 249 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-50\/95 {
  --tw-shadow-color: rgb(240 249 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500 {
  --tw-shadow-color: #0ea5e9 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/0 {
  --tw-shadow-color: rgb(14 165 233 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/10 {
  --tw-shadow-color: rgb(14 165 233 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/100 {
  --tw-shadow-color: rgb(14 165 233 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/15 {
  --tw-shadow-color: rgb(14 165 233 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/20 {
  --tw-shadow-color: rgb(14 165 233 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/25 {
  --tw-shadow-color: rgb(14 165 233 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/30 {
  --tw-shadow-color: rgb(14 165 233 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/35 {
  --tw-shadow-color: rgb(14 165 233 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/40 {
  --tw-shadow-color: rgb(14 165 233 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/45 {
  --tw-shadow-color: rgb(14 165 233 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/5 {
  --tw-shadow-color: rgb(14 165 233 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/50 {
  --tw-shadow-color: rgb(14 165 233 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/55 {
  --tw-shadow-color: rgb(14 165 233 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/60 {
  --tw-shadow-color: rgb(14 165 233 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/65 {
  --tw-shadow-color: rgb(14 165 233 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/70 {
  --tw-shadow-color: rgb(14 165 233 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/75 {
  --tw-shadow-color: rgb(14 165 233 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/80 {
  --tw-shadow-color: rgb(14 165 233 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/85 {
  --tw-shadow-color: rgb(14 165 233 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/90 {
  --tw-shadow-color: rgb(14 165 233 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-500\/95 {
  --tw-shadow-color: rgb(14 165 233 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600 {
  --tw-shadow-color: #0284c7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/0 {
  --tw-shadow-color: rgb(2 132 199 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/10 {
  --tw-shadow-color: rgb(2 132 199 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/100 {
  --tw-shadow-color: rgb(2 132 199 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/15 {
  --tw-shadow-color: rgb(2 132 199 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/20 {
  --tw-shadow-color: rgb(2 132 199 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/25 {
  --tw-shadow-color: rgb(2 132 199 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/30 {
  --tw-shadow-color: rgb(2 132 199 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/35 {
  --tw-shadow-color: rgb(2 132 199 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/40 {
  --tw-shadow-color: rgb(2 132 199 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/45 {
  --tw-shadow-color: rgb(2 132 199 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/5 {
  --tw-shadow-color: rgb(2 132 199 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/50 {
  --tw-shadow-color: rgb(2 132 199 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/55 {
  --tw-shadow-color: rgb(2 132 199 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/60 {
  --tw-shadow-color: rgb(2 132 199 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/65 {
  --tw-shadow-color: rgb(2 132 199 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/70 {
  --tw-shadow-color: rgb(2 132 199 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/75 {
  --tw-shadow-color: rgb(2 132 199 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/80 {
  --tw-shadow-color: rgb(2 132 199 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/85 {
  --tw-shadow-color: rgb(2 132 199 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/90 {
  --tw-shadow-color: rgb(2 132 199 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-600\/95 {
  --tw-shadow-color: rgb(2 132 199 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700 {
  --tw-shadow-color: #0369a1 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/0 {
  --tw-shadow-color: rgb(3 105 161 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/10 {
  --tw-shadow-color: rgb(3 105 161 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/100 {
  --tw-shadow-color: rgb(3 105 161 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/15 {
  --tw-shadow-color: rgb(3 105 161 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/20 {
  --tw-shadow-color: rgb(3 105 161 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/25 {
  --tw-shadow-color: rgb(3 105 161 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/30 {
  --tw-shadow-color: rgb(3 105 161 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/35 {
  --tw-shadow-color: rgb(3 105 161 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/40 {
  --tw-shadow-color: rgb(3 105 161 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/45 {
  --tw-shadow-color: rgb(3 105 161 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/5 {
  --tw-shadow-color: rgb(3 105 161 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/50 {
  --tw-shadow-color: rgb(3 105 161 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/55 {
  --tw-shadow-color: rgb(3 105 161 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/60 {
  --tw-shadow-color: rgb(3 105 161 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/65 {
  --tw-shadow-color: rgb(3 105 161 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/70 {
  --tw-shadow-color: rgb(3 105 161 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/75 {
  --tw-shadow-color: rgb(3 105 161 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/80 {
  --tw-shadow-color: rgb(3 105 161 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/85 {
  --tw-shadow-color: rgb(3 105 161 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/90 {
  --tw-shadow-color: rgb(3 105 161 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-700\/95 {
  --tw-shadow-color: rgb(3 105 161 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800 {
  --tw-shadow-color: #075985 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/0 {
  --tw-shadow-color: rgb(7 89 133 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/10 {
  --tw-shadow-color: rgb(7 89 133 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/100 {
  --tw-shadow-color: rgb(7 89 133 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/15 {
  --tw-shadow-color: rgb(7 89 133 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/20 {
  --tw-shadow-color: rgb(7 89 133 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/25 {
  --tw-shadow-color: rgb(7 89 133 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/30 {
  --tw-shadow-color: rgb(7 89 133 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/35 {
  --tw-shadow-color: rgb(7 89 133 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/40 {
  --tw-shadow-color: rgb(7 89 133 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/45 {
  --tw-shadow-color: rgb(7 89 133 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/5 {
  --tw-shadow-color: rgb(7 89 133 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/50 {
  --tw-shadow-color: rgb(7 89 133 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/55 {
  --tw-shadow-color: rgb(7 89 133 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/60 {
  --tw-shadow-color: rgb(7 89 133 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/65 {
  --tw-shadow-color: rgb(7 89 133 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/70 {
  --tw-shadow-color: rgb(7 89 133 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/75 {
  --tw-shadow-color: rgb(7 89 133 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/80 {
  --tw-shadow-color: rgb(7 89 133 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/85 {
  --tw-shadow-color: rgb(7 89 133 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/90 {
  --tw-shadow-color: rgb(7 89 133 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-800\/95 {
  --tw-shadow-color: rgb(7 89 133 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900 {
  --tw-shadow-color: #0c4a6e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/0 {
  --tw-shadow-color: rgb(12 74 110 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/10 {
  --tw-shadow-color: rgb(12 74 110 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/100 {
  --tw-shadow-color: rgb(12 74 110 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/15 {
  --tw-shadow-color: rgb(12 74 110 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/20 {
  --tw-shadow-color: rgb(12 74 110 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/25 {
  --tw-shadow-color: rgb(12 74 110 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/30 {
  --tw-shadow-color: rgb(12 74 110 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/35 {
  --tw-shadow-color: rgb(12 74 110 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/40 {
  --tw-shadow-color: rgb(12 74 110 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/45 {
  --tw-shadow-color: rgb(12 74 110 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/5 {
  --tw-shadow-color: rgb(12 74 110 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/50 {
  --tw-shadow-color: rgb(12 74 110 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/55 {
  --tw-shadow-color: rgb(12 74 110 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/60 {
  --tw-shadow-color: rgb(12 74 110 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/65 {
  --tw-shadow-color: rgb(12 74 110 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/70 {
  --tw-shadow-color: rgb(12 74 110 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/75 {
  --tw-shadow-color: rgb(12 74 110 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/80 {
  --tw-shadow-color: rgb(12 74 110 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/85 {
  --tw-shadow-color: rgb(12 74 110 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/90 {
  --tw-shadow-color: rgb(12 74 110 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-900\/95 {
  --tw-shadow-color: rgb(12 74 110 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950 {
  --tw-shadow-color: #082f49 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/0 {
  --tw-shadow-color: rgb(8 47 73 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/10 {
  --tw-shadow-color: rgb(8 47 73 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/100 {
  --tw-shadow-color: rgb(8 47 73 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/15 {
  --tw-shadow-color: rgb(8 47 73 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/20 {
  --tw-shadow-color: rgb(8 47 73 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/25 {
  --tw-shadow-color: rgb(8 47 73 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/30 {
  --tw-shadow-color: rgb(8 47 73 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/35 {
  --tw-shadow-color: rgb(8 47 73 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/40 {
  --tw-shadow-color: rgb(8 47 73 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/45 {
  --tw-shadow-color: rgb(8 47 73 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/5 {
  --tw-shadow-color: rgb(8 47 73 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/50 {
  --tw-shadow-color: rgb(8 47 73 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/55 {
  --tw-shadow-color: rgb(8 47 73 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/60 {
  --tw-shadow-color: rgb(8 47 73 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/65 {
  --tw-shadow-color: rgb(8 47 73 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/70 {
  --tw-shadow-color: rgb(8 47 73 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/75 {
  --tw-shadow-color: rgb(8 47 73 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/80 {
  --tw-shadow-color: rgb(8 47 73 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/85 {
  --tw-shadow-color: rgb(8 47 73 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/90 {
  --tw-shadow-color: rgb(8 47 73 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-sky-950\/95 {
  --tw-shadow-color: rgb(8 47 73 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100 {
  --tw-shadow-color: #f1f5f9 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/0 {
  --tw-shadow-color: rgb(241 245 249 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/10 {
  --tw-shadow-color: rgb(241 245 249 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/100 {
  --tw-shadow-color: rgb(241 245 249 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/15 {
  --tw-shadow-color: rgb(241 245 249 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/20 {
  --tw-shadow-color: rgb(241 245 249 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/25 {
  --tw-shadow-color: rgb(241 245 249 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/30 {
  --tw-shadow-color: rgb(241 245 249 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/35 {
  --tw-shadow-color: rgb(241 245 249 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/40 {
  --tw-shadow-color: rgb(241 245 249 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/45 {
  --tw-shadow-color: rgb(241 245 249 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/5 {
  --tw-shadow-color: rgb(241 245 249 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/50 {
  --tw-shadow-color: rgb(241 245 249 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/55 {
  --tw-shadow-color: rgb(241 245 249 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/60 {
  --tw-shadow-color: rgb(241 245 249 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/65 {
  --tw-shadow-color: rgb(241 245 249 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/70 {
  --tw-shadow-color: rgb(241 245 249 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/75 {
  --tw-shadow-color: rgb(241 245 249 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/80 {
  --tw-shadow-color: rgb(241 245 249 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/85 {
  --tw-shadow-color: rgb(241 245 249 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/90 {
  --tw-shadow-color: rgb(241 245 249 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-100\/95 {
  --tw-shadow-color: rgb(241 245 249 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200 {
  --tw-shadow-color: #e2e8f0 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/0 {
  --tw-shadow-color: rgb(226 232 240 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/10 {
  --tw-shadow-color: rgb(226 232 240 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/100 {
  --tw-shadow-color: rgb(226 232 240 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/15 {
  --tw-shadow-color: rgb(226 232 240 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/20 {
  --tw-shadow-color: rgb(226 232 240 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/25 {
  --tw-shadow-color: rgb(226 232 240 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/30 {
  --tw-shadow-color: rgb(226 232 240 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/35 {
  --tw-shadow-color: rgb(226 232 240 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/40 {
  --tw-shadow-color: rgb(226 232 240 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/45 {
  --tw-shadow-color: rgb(226 232 240 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/5 {
  --tw-shadow-color: rgb(226 232 240 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/50 {
  --tw-shadow-color: rgb(226 232 240 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/55 {
  --tw-shadow-color: rgb(226 232 240 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/60 {
  --tw-shadow-color: rgb(226 232 240 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/65 {
  --tw-shadow-color: rgb(226 232 240 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/70 {
  --tw-shadow-color: rgb(226 232 240 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/75 {
  --tw-shadow-color: rgb(226 232 240 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/80 {
  --tw-shadow-color: rgb(226 232 240 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/85 {
  --tw-shadow-color: rgb(226 232 240 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/90 {
  --tw-shadow-color: rgb(226 232 240 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-200\/95 {
  --tw-shadow-color: rgb(226 232 240 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300 {
  --tw-shadow-color: #cbd5e1 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/0 {
  --tw-shadow-color: rgb(203 213 225 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/10 {
  --tw-shadow-color: rgb(203 213 225 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/100 {
  --tw-shadow-color: rgb(203 213 225 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/15 {
  --tw-shadow-color: rgb(203 213 225 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/20 {
  --tw-shadow-color: rgb(203 213 225 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/25 {
  --tw-shadow-color: rgb(203 213 225 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/30 {
  --tw-shadow-color: rgb(203 213 225 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/35 {
  --tw-shadow-color: rgb(203 213 225 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/40 {
  --tw-shadow-color: rgb(203 213 225 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/45 {
  --tw-shadow-color: rgb(203 213 225 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/5 {
  --tw-shadow-color: rgb(203 213 225 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/50 {
  --tw-shadow-color: rgb(203 213 225 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/55 {
  --tw-shadow-color: rgb(203 213 225 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/60 {
  --tw-shadow-color: rgb(203 213 225 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/65 {
  --tw-shadow-color: rgb(203 213 225 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/70 {
  --tw-shadow-color: rgb(203 213 225 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/75 {
  --tw-shadow-color: rgb(203 213 225 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/80 {
  --tw-shadow-color: rgb(203 213 225 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/85 {
  --tw-shadow-color: rgb(203 213 225 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/90 {
  --tw-shadow-color: rgb(203 213 225 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-300\/95 {
  --tw-shadow-color: rgb(203 213 225 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400 {
  --tw-shadow-color: #94a3b8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/0 {
  --tw-shadow-color: rgb(148 163 184 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/10 {
  --tw-shadow-color: rgb(148 163 184 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/100 {
  --tw-shadow-color: rgb(148 163 184 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/15 {
  --tw-shadow-color: rgb(148 163 184 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/20 {
  --tw-shadow-color: rgb(148 163 184 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/25 {
  --tw-shadow-color: rgb(148 163 184 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/30 {
  --tw-shadow-color: rgb(148 163 184 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/35 {
  --tw-shadow-color: rgb(148 163 184 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/40 {
  --tw-shadow-color: rgb(148 163 184 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/45 {
  --tw-shadow-color: rgb(148 163 184 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/5 {
  --tw-shadow-color: rgb(148 163 184 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/50 {
  --tw-shadow-color: rgb(148 163 184 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/55 {
  --tw-shadow-color: rgb(148 163 184 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/60 {
  --tw-shadow-color: rgb(148 163 184 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/65 {
  --tw-shadow-color: rgb(148 163 184 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/70 {
  --tw-shadow-color: rgb(148 163 184 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/75 {
  --tw-shadow-color: rgb(148 163 184 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/80 {
  --tw-shadow-color: rgb(148 163 184 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/85 {
  --tw-shadow-color: rgb(148 163 184 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/90 {
  --tw-shadow-color: rgb(148 163 184 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-400\/95 {
  --tw-shadow-color: rgb(148 163 184 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50 {
  --tw-shadow-color: #f8fafc !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/0 {
  --tw-shadow-color: rgb(248 250 252 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/10 {
  --tw-shadow-color: rgb(248 250 252 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/100 {
  --tw-shadow-color: rgb(248 250 252 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/15 {
  --tw-shadow-color: rgb(248 250 252 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/20 {
  --tw-shadow-color: rgb(248 250 252 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/25 {
  --tw-shadow-color: rgb(248 250 252 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/30 {
  --tw-shadow-color: rgb(248 250 252 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/35 {
  --tw-shadow-color: rgb(248 250 252 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/40 {
  --tw-shadow-color: rgb(248 250 252 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/45 {
  --tw-shadow-color: rgb(248 250 252 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/5 {
  --tw-shadow-color: rgb(248 250 252 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/50 {
  --tw-shadow-color: rgb(248 250 252 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/55 {
  --tw-shadow-color: rgb(248 250 252 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/60 {
  --tw-shadow-color: rgb(248 250 252 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/65 {
  --tw-shadow-color: rgb(248 250 252 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/70 {
  --tw-shadow-color: rgb(248 250 252 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/75 {
  --tw-shadow-color: rgb(248 250 252 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/80 {
  --tw-shadow-color: rgb(248 250 252 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/85 {
  --tw-shadow-color: rgb(248 250 252 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/90 {
  --tw-shadow-color: rgb(248 250 252 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-50\/95 {
  --tw-shadow-color: rgb(248 250 252 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500 {
  --tw-shadow-color: #64748b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/0 {
  --tw-shadow-color: rgb(100 116 139 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/10 {
  --tw-shadow-color: rgb(100 116 139 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/100 {
  --tw-shadow-color: rgb(100 116 139 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/15 {
  --tw-shadow-color: rgb(100 116 139 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/20 {
  --tw-shadow-color: rgb(100 116 139 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/25 {
  --tw-shadow-color: rgb(100 116 139 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/30 {
  --tw-shadow-color: rgb(100 116 139 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/35 {
  --tw-shadow-color: rgb(100 116 139 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/40 {
  --tw-shadow-color: rgb(100 116 139 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/45 {
  --tw-shadow-color: rgb(100 116 139 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/5 {
  --tw-shadow-color: rgb(100 116 139 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/50 {
  --tw-shadow-color: rgb(100 116 139 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/55 {
  --tw-shadow-color: rgb(100 116 139 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/60 {
  --tw-shadow-color: rgb(100 116 139 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/65 {
  --tw-shadow-color: rgb(100 116 139 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/70 {
  --tw-shadow-color: rgb(100 116 139 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/75 {
  --tw-shadow-color: rgb(100 116 139 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/80 {
  --tw-shadow-color: rgb(100 116 139 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/85 {
  --tw-shadow-color: rgb(100 116 139 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/90 {
  --tw-shadow-color: rgb(100 116 139 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-500\/95 {
  --tw-shadow-color: rgb(100 116 139 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600 {
  --tw-shadow-color: #475569 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/0 {
  --tw-shadow-color: rgb(71 85 105 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/10 {
  --tw-shadow-color: rgb(71 85 105 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/100 {
  --tw-shadow-color: rgb(71 85 105 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/15 {
  --tw-shadow-color: rgb(71 85 105 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/20 {
  --tw-shadow-color: rgb(71 85 105 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/25 {
  --tw-shadow-color: rgb(71 85 105 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/30 {
  --tw-shadow-color: rgb(71 85 105 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/35 {
  --tw-shadow-color: rgb(71 85 105 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/40 {
  --tw-shadow-color: rgb(71 85 105 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/45 {
  --tw-shadow-color: rgb(71 85 105 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/5 {
  --tw-shadow-color: rgb(71 85 105 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/50 {
  --tw-shadow-color: rgb(71 85 105 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/55 {
  --tw-shadow-color: rgb(71 85 105 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/60 {
  --tw-shadow-color: rgb(71 85 105 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/65 {
  --tw-shadow-color: rgb(71 85 105 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/70 {
  --tw-shadow-color: rgb(71 85 105 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/75 {
  --tw-shadow-color: rgb(71 85 105 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/80 {
  --tw-shadow-color: rgb(71 85 105 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/85 {
  --tw-shadow-color: rgb(71 85 105 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/90 {
  --tw-shadow-color: rgb(71 85 105 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-600\/95 {
  --tw-shadow-color: rgb(71 85 105 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700 {
  --tw-shadow-color: #334155 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/0 {
  --tw-shadow-color: rgb(51 65 85 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/10 {
  --tw-shadow-color: rgb(51 65 85 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/100 {
  --tw-shadow-color: rgb(51 65 85 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/15 {
  --tw-shadow-color: rgb(51 65 85 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/20 {
  --tw-shadow-color: rgb(51 65 85 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/25 {
  --tw-shadow-color: rgb(51 65 85 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/30 {
  --tw-shadow-color: rgb(51 65 85 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/35 {
  --tw-shadow-color: rgb(51 65 85 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/40 {
  --tw-shadow-color: rgb(51 65 85 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/45 {
  --tw-shadow-color: rgb(51 65 85 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/5 {
  --tw-shadow-color: rgb(51 65 85 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/50 {
  --tw-shadow-color: rgb(51 65 85 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/55 {
  --tw-shadow-color: rgb(51 65 85 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/60 {
  --tw-shadow-color: rgb(51 65 85 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/65 {
  --tw-shadow-color: rgb(51 65 85 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/70 {
  --tw-shadow-color: rgb(51 65 85 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/75 {
  --tw-shadow-color: rgb(51 65 85 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/80 {
  --tw-shadow-color: rgb(51 65 85 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/85 {
  --tw-shadow-color: rgb(51 65 85 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/90 {
  --tw-shadow-color: rgb(51 65 85 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-700\/95 {
  --tw-shadow-color: rgb(51 65 85 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800 {
  --tw-shadow-color: #1e293b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/0 {
  --tw-shadow-color: rgb(30 41 59 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/10 {
  --tw-shadow-color: rgb(30 41 59 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/100 {
  --tw-shadow-color: rgb(30 41 59 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/15 {
  --tw-shadow-color: rgb(30 41 59 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/20 {
  --tw-shadow-color: rgb(30 41 59 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/25 {
  --tw-shadow-color: rgb(30 41 59 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/30 {
  --tw-shadow-color: rgb(30 41 59 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/35 {
  --tw-shadow-color: rgb(30 41 59 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/40 {
  --tw-shadow-color: rgb(30 41 59 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/45 {
  --tw-shadow-color: rgb(30 41 59 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/5 {
  --tw-shadow-color: rgb(30 41 59 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/50 {
  --tw-shadow-color: rgb(30 41 59 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/55 {
  --tw-shadow-color: rgb(30 41 59 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/60 {
  --tw-shadow-color: rgb(30 41 59 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/65 {
  --tw-shadow-color: rgb(30 41 59 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/70 {
  --tw-shadow-color: rgb(30 41 59 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/75 {
  --tw-shadow-color: rgb(30 41 59 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/80 {
  --tw-shadow-color: rgb(30 41 59 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/85 {
  --tw-shadow-color: rgb(30 41 59 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/90 {
  --tw-shadow-color: rgb(30 41 59 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-800\/95 {
  --tw-shadow-color: rgb(30 41 59 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900 {
  --tw-shadow-color: #0f172a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/0 {
  --tw-shadow-color: rgb(15 23 42 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/10 {
  --tw-shadow-color: rgb(15 23 42 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/100 {
  --tw-shadow-color: rgb(15 23 42 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/15 {
  --tw-shadow-color: rgb(15 23 42 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/20 {
  --tw-shadow-color: rgb(15 23 42 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/25 {
  --tw-shadow-color: rgb(15 23 42 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/30 {
  --tw-shadow-color: rgb(15 23 42 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/35 {
  --tw-shadow-color: rgb(15 23 42 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/40 {
  --tw-shadow-color: rgb(15 23 42 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/45 {
  --tw-shadow-color: rgb(15 23 42 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/5 {
  --tw-shadow-color: rgb(15 23 42 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/50 {
  --tw-shadow-color: rgb(15 23 42 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/55 {
  --tw-shadow-color: rgb(15 23 42 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/60 {
  --tw-shadow-color: rgb(15 23 42 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/65 {
  --tw-shadow-color: rgb(15 23 42 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/70 {
  --tw-shadow-color: rgb(15 23 42 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/75 {
  --tw-shadow-color: rgb(15 23 42 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/80 {
  --tw-shadow-color: rgb(15 23 42 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/85 {
  --tw-shadow-color: rgb(15 23 42 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/90 {
  --tw-shadow-color: rgb(15 23 42 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-900\/95 {
  --tw-shadow-color: rgb(15 23 42 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950 {
  --tw-shadow-color: #020617 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/0 {
  --tw-shadow-color: rgb(2 6 23 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/10 {
  --tw-shadow-color: rgb(2 6 23 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/100 {
  --tw-shadow-color: rgb(2 6 23 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/15 {
  --tw-shadow-color: rgb(2 6 23 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/20 {
  --tw-shadow-color: rgb(2 6 23 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/25 {
  --tw-shadow-color: rgb(2 6 23 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/30 {
  --tw-shadow-color: rgb(2 6 23 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/35 {
  --tw-shadow-color: rgb(2 6 23 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/40 {
  --tw-shadow-color: rgb(2 6 23 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/45 {
  --tw-shadow-color: rgb(2 6 23 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/5 {
  --tw-shadow-color: rgb(2 6 23 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/50 {
  --tw-shadow-color: rgb(2 6 23 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/55 {
  --tw-shadow-color: rgb(2 6 23 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/60 {
  --tw-shadow-color: rgb(2 6 23 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/65 {
  --tw-shadow-color: rgb(2 6 23 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/70 {
  --tw-shadow-color: rgb(2 6 23 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/75 {
  --tw-shadow-color: rgb(2 6 23 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/80 {
  --tw-shadow-color: rgb(2 6 23 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/85 {
  --tw-shadow-color: rgb(2 6 23 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/90 {
  --tw-shadow-color: rgb(2 6 23 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-slate-950\/95 {
  --tw-shadow-color: rgb(2 6 23 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100 {
  --tw-shadow-color: #f5f5f4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/0 {
  --tw-shadow-color: rgb(245 245 244 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/10 {
  --tw-shadow-color: rgb(245 245 244 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/100 {
  --tw-shadow-color: rgb(245 245 244 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/15 {
  --tw-shadow-color: rgb(245 245 244 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/20 {
  --tw-shadow-color: rgb(245 245 244 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/25 {
  --tw-shadow-color: rgb(245 245 244 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/30 {
  --tw-shadow-color: rgb(245 245 244 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/35 {
  --tw-shadow-color: rgb(245 245 244 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/40 {
  --tw-shadow-color: rgb(245 245 244 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/45 {
  --tw-shadow-color: rgb(245 245 244 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/5 {
  --tw-shadow-color: rgb(245 245 244 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/50 {
  --tw-shadow-color: rgb(245 245 244 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/55 {
  --tw-shadow-color: rgb(245 245 244 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/60 {
  --tw-shadow-color: rgb(245 245 244 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/65 {
  --tw-shadow-color: rgb(245 245 244 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/70 {
  --tw-shadow-color: rgb(245 245 244 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/75 {
  --tw-shadow-color: rgb(245 245 244 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/80 {
  --tw-shadow-color: rgb(245 245 244 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/85 {
  --tw-shadow-color: rgb(245 245 244 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/90 {
  --tw-shadow-color: rgb(245 245 244 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-100\/95 {
  --tw-shadow-color: rgb(245 245 244 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200 {
  --tw-shadow-color: #e7e5e4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/0 {
  --tw-shadow-color: rgb(231 229 228 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/10 {
  --tw-shadow-color: rgb(231 229 228 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/100 {
  --tw-shadow-color: rgb(231 229 228 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/15 {
  --tw-shadow-color: rgb(231 229 228 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/20 {
  --tw-shadow-color: rgb(231 229 228 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/25 {
  --tw-shadow-color: rgb(231 229 228 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/30 {
  --tw-shadow-color: rgb(231 229 228 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/35 {
  --tw-shadow-color: rgb(231 229 228 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/40 {
  --tw-shadow-color: rgb(231 229 228 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/45 {
  --tw-shadow-color: rgb(231 229 228 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/5 {
  --tw-shadow-color: rgb(231 229 228 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/50 {
  --tw-shadow-color: rgb(231 229 228 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/55 {
  --tw-shadow-color: rgb(231 229 228 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/60 {
  --tw-shadow-color: rgb(231 229 228 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/65 {
  --tw-shadow-color: rgb(231 229 228 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/70 {
  --tw-shadow-color: rgb(231 229 228 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/75 {
  --tw-shadow-color: rgb(231 229 228 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/80 {
  --tw-shadow-color: rgb(231 229 228 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/85 {
  --tw-shadow-color: rgb(231 229 228 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/90 {
  --tw-shadow-color: rgb(231 229 228 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-200\/95 {
  --tw-shadow-color: rgb(231 229 228 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300 {
  --tw-shadow-color: #d6d3d1 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/0 {
  --tw-shadow-color: rgb(214 211 209 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/10 {
  --tw-shadow-color: rgb(214 211 209 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/100 {
  --tw-shadow-color: rgb(214 211 209 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/15 {
  --tw-shadow-color: rgb(214 211 209 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/20 {
  --tw-shadow-color: rgb(214 211 209 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/25 {
  --tw-shadow-color: rgb(214 211 209 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/30 {
  --tw-shadow-color: rgb(214 211 209 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/35 {
  --tw-shadow-color: rgb(214 211 209 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/40 {
  --tw-shadow-color: rgb(214 211 209 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/45 {
  --tw-shadow-color: rgb(214 211 209 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/5 {
  --tw-shadow-color: rgb(214 211 209 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/50 {
  --tw-shadow-color: rgb(214 211 209 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/55 {
  --tw-shadow-color: rgb(214 211 209 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/60 {
  --tw-shadow-color: rgb(214 211 209 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/65 {
  --tw-shadow-color: rgb(214 211 209 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/70 {
  --tw-shadow-color: rgb(214 211 209 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/75 {
  --tw-shadow-color: rgb(214 211 209 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/80 {
  --tw-shadow-color: rgb(214 211 209 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/85 {
  --tw-shadow-color: rgb(214 211 209 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/90 {
  --tw-shadow-color: rgb(214 211 209 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-300\/95 {
  --tw-shadow-color: rgb(214 211 209 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400 {
  --tw-shadow-color: #a8a29e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/0 {
  --tw-shadow-color: rgb(168 162 158 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/10 {
  --tw-shadow-color: rgb(168 162 158 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/100 {
  --tw-shadow-color: rgb(168 162 158 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/15 {
  --tw-shadow-color: rgb(168 162 158 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/20 {
  --tw-shadow-color: rgb(168 162 158 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/25 {
  --tw-shadow-color: rgb(168 162 158 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/30 {
  --tw-shadow-color: rgb(168 162 158 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/35 {
  --tw-shadow-color: rgb(168 162 158 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/40 {
  --tw-shadow-color: rgb(168 162 158 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/45 {
  --tw-shadow-color: rgb(168 162 158 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/5 {
  --tw-shadow-color: rgb(168 162 158 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/50 {
  --tw-shadow-color: rgb(168 162 158 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/55 {
  --tw-shadow-color: rgb(168 162 158 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/60 {
  --tw-shadow-color: rgb(168 162 158 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/65 {
  --tw-shadow-color: rgb(168 162 158 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/70 {
  --tw-shadow-color: rgb(168 162 158 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/75 {
  --tw-shadow-color: rgb(168 162 158 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/80 {
  --tw-shadow-color: rgb(168 162 158 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/85 {
  --tw-shadow-color: rgb(168 162 158 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/90 {
  --tw-shadow-color: rgb(168 162 158 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-400\/95 {
  --tw-shadow-color: rgb(168 162 158 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50 {
  --tw-shadow-color: #fafaf9 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/0 {
  --tw-shadow-color: rgb(250 250 249 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/10 {
  --tw-shadow-color: rgb(250 250 249 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/100 {
  --tw-shadow-color: rgb(250 250 249 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/15 {
  --tw-shadow-color: rgb(250 250 249 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/20 {
  --tw-shadow-color: rgb(250 250 249 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/25 {
  --tw-shadow-color: rgb(250 250 249 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/30 {
  --tw-shadow-color: rgb(250 250 249 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/35 {
  --tw-shadow-color: rgb(250 250 249 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/40 {
  --tw-shadow-color: rgb(250 250 249 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/45 {
  --tw-shadow-color: rgb(250 250 249 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/5 {
  --tw-shadow-color: rgb(250 250 249 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/50 {
  --tw-shadow-color: rgb(250 250 249 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/55 {
  --tw-shadow-color: rgb(250 250 249 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/60 {
  --tw-shadow-color: rgb(250 250 249 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/65 {
  --tw-shadow-color: rgb(250 250 249 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/70 {
  --tw-shadow-color: rgb(250 250 249 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/75 {
  --tw-shadow-color: rgb(250 250 249 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/80 {
  --tw-shadow-color: rgb(250 250 249 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/85 {
  --tw-shadow-color: rgb(250 250 249 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/90 {
  --tw-shadow-color: rgb(250 250 249 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-50\/95 {
  --tw-shadow-color: rgb(250 250 249 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500 {
  --tw-shadow-color: #78716c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/0 {
  --tw-shadow-color: rgb(120 113 108 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/10 {
  --tw-shadow-color: rgb(120 113 108 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/100 {
  --tw-shadow-color: rgb(120 113 108 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/15 {
  --tw-shadow-color: rgb(120 113 108 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/20 {
  --tw-shadow-color: rgb(120 113 108 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/25 {
  --tw-shadow-color: rgb(120 113 108 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/30 {
  --tw-shadow-color: rgb(120 113 108 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/35 {
  --tw-shadow-color: rgb(120 113 108 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/40 {
  --tw-shadow-color: rgb(120 113 108 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/45 {
  --tw-shadow-color: rgb(120 113 108 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/5 {
  --tw-shadow-color: rgb(120 113 108 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/50 {
  --tw-shadow-color: rgb(120 113 108 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/55 {
  --tw-shadow-color: rgb(120 113 108 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/60 {
  --tw-shadow-color: rgb(120 113 108 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/65 {
  --tw-shadow-color: rgb(120 113 108 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/70 {
  --tw-shadow-color: rgb(120 113 108 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/75 {
  --tw-shadow-color: rgb(120 113 108 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/80 {
  --tw-shadow-color: rgb(120 113 108 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/85 {
  --tw-shadow-color: rgb(120 113 108 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/90 {
  --tw-shadow-color: rgb(120 113 108 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-500\/95 {
  --tw-shadow-color: rgb(120 113 108 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600 {
  --tw-shadow-color: #57534e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/0 {
  --tw-shadow-color: rgb(87 83 78 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/10 {
  --tw-shadow-color: rgb(87 83 78 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/100 {
  --tw-shadow-color: rgb(87 83 78 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/15 {
  --tw-shadow-color: rgb(87 83 78 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/20 {
  --tw-shadow-color: rgb(87 83 78 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/25 {
  --tw-shadow-color: rgb(87 83 78 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/30 {
  --tw-shadow-color: rgb(87 83 78 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/35 {
  --tw-shadow-color: rgb(87 83 78 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/40 {
  --tw-shadow-color: rgb(87 83 78 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/45 {
  --tw-shadow-color: rgb(87 83 78 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/5 {
  --tw-shadow-color: rgb(87 83 78 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/50 {
  --tw-shadow-color: rgb(87 83 78 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/55 {
  --tw-shadow-color: rgb(87 83 78 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/60 {
  --tw-shadow-color: rgb(87 83 78 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/65 {
  --tw-shadow-color: rgb(87 83 78 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/70 {
  --tw-shadow-color: rgb(87 83 78 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/75 {
  --tw-shadow-color: rgb(87 83 78 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/80 {
  --tw-shadow-color: rgb(87 83 78 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/85 {
  --tw-shadow-color: rgb(87 83 78 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/90 {
  --tw-shadow-color: rgb(87 83 78 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-600\/95 {
  --tw-shadow-color: rgb(87 83 78 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700 {
  --tw-shadow-color: #44403c !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/0 {
  --tw-shadow-color: rgb(68 64 60 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/10 {
  --tw-shadow-color: rgb(68 64 60 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/100 {
  --tw-shadow-color: rgb(68 64 60 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/15 {
  --tw-shadow-color: rgb(68 64 60 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/20 {
  --tw-shadow-color: rgb(68 64 60 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/25 {
  --tw-shadow-color: rgb(68 64 60 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/30 {
  --tw-shadow-color: rgb(68 64 60 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/35 {
  --tw-shadow-color: rgb(68 64 60 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/40 {
  --tw-shadow-color: rgb(68 64 60 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/45 {
  --tw-shadow-color: rgb(68 64 60 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/5 {
  --tw-shadow-color: rgb(68 64 60 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/50 {
  --tw-shadow-color: rgb(68 64 60 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/55 {
  --tw-shadow-color: rgb(68 64 60 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/60 {
  --tw-shadow-color: rgb(68 64 60 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/65 {
  --tw-shadow-color: rgb(68 64 60 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/70 {
  --tw-shadow-color: rgb(68 64 60 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/75 {
  --tw-shadow-color: rgb(68 64 60 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/80 {
  --tw-shadow-color: rgb(68 64 60 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/85 {
  --tw-shadow-color: rgb(68 64 60 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/90 {
  --tw-shadow-color: rgb(68 64 60 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-700\/95 {
  --tw-shadow-color: rgb(68 64 60 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800 {
  --tw-shadow-color: #292524 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/0 {
  --tw-shadow-color: rgb(41 37 36 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/10 {
  --tw-shadow-color: rgb(41 37 36 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/100 {
  --tw-shadow-color: rgb(41 37 36 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/15 {
  --tw-shadow-color: rgb(41 37 36 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/20 {
  --tw-shadow-color: rgb(41 37 36 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/25 {
  --tw-shadow-color: rgb(41 37 36 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/30 {
  --tw-shadow-color: rgb(41 37 36 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/35 {
  --tw-shadow-color: rgb(41 37 36 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/40 {
  --tw-shadow-color: rgb(41 37 36 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/45 {
  --tw-shadow-color: rgb(41 37 36 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/5 {
  --tw-shadow-color: rgb(41 37 36 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/50 {
  --tw-shadow-color: rgb(41 37 36 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/55 {
  --tw-shadow-color: rgb(41 37 36 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/60 {
  --tw-shadow-color: rgb(41 37 36 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/65 {
  --tw-shadow-color: rgb(41 37 36 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/70 {
  --tw-shadow-color: rgb(41 37 36 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/75 {
  --tw-shadow-color: rgb(41 37 36 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/80 {
  --tw-shadow-color: rgb(41 37 36 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/85 {
  --tw-shadow-color: rgb(41 37 36 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/90 {
  --tw-shadow-color: rgb(41 37 36 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-800\/95 {
  --tw-shadow-color: rgb(41 37 36 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900 {
  --tw-shadow-color: #1c1917 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/0 {
  --tw-shadow-color: rgb(28 25 23 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/10 {
  --tw-shadow-color: rgb(28 25 23 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/100 {
  --tw-shadow-color: rgb(28 25 23 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/15 {
  --tw-shadow-color: rgb(28 25 23 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/20 {
  --tw-shadow-color: rgb(28 25 23 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/25 {
  --tw-shadow-color: rgb(28 25 23 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/30 {
  --tw-shadow-color: rgb(28 25 23 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/35 {
  --tw-shadow-color: rgb(28 25 23 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/40 {
  --tw-shadow-color: rgb(28 25 23 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/45 {
  --tw-shadow-color: rgb(28 25 23 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/5 {
  --tw-shadow-color: rgb(28 25 23 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/50 {
  --tw-shadow-color: rgb(28 25 23 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/55 {
  --tw-shadow-color: rgb(28 25 23 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/60 {
  --tw-shadow-color: rgb(28 25 23 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/65 {
  --tw-shadow-color: rgb(28 25 23 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/70 {
  --tw-shadow-color: rgb(28 25 23 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/75 {
  --tw-shadow-color: rgb(28 25 23 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/80 {
  --tw-shadow-color: rgb(28 25 23 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/85 {
  --tw-shadow-color: rgb(28 25 23 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/90 {
  --tw-shadow-color: rgb(28 25 23 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-900\/95 {
  --tw-shadow-color: rgb(28 25 23 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950 {
  --tw-shadow-color: #0c0a09 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/0 {
  --tw-shadow-color: rgb(12 10 9 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/10 {
  --tw-shadow-color: rgb(12 10 9 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/100 {
  --tw-shadow-color: rgb(12 10 9 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/15 {
  --tw-shadow-color: rgb(12 10 9 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/20 {
  --tw-shadow-color: rgb(12 10 9 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/25 {
  --tw-shadow-color: rgb(12 10 9 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/30 {
  --tw-shadow-color: rgb(12 10 9 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/35 {
  --tw-shadow-color: rgb(12 10 9 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/40 {
  --tw-shadow-color: rgb(12 10 9 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/45 {
  --tw-shadow-color: rgb(12 10 9 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/5 {
  --tw-shadow-color: rgb(12 10 9 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/50 {
  --tw-shadow-color: rgb(12 10 9 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/55 {
  --tw-shadow-color: rgb(12 10 9 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/60 {
  --tw-shadow-color: rgb(12 10 9 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/65 {
  --tw-shadow-color: rgb(12 10 9 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/70 {
  --tw-shadow-color: rgb(12 10 9 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/75 {
  --tw-shadow-color: rgb(12 10 9 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/80 {
  --tw-shadow-color: rgb(12 10 9 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/85 {
  --tw-shadow-color: rgb(12 10 9 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/90 {
  --tw-shadow-color: rgb(12 10 9 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-stone-950\/95 {
  --tw-shadow-color: rgb(12 10 9 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100 {
  --tw-shadow-color: #ccfbf1 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/0 {
  --tw-shadow-color: rgb(204 251 241 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/10 {
  --tw-shadow-color: rgb(204 251 241 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/100 {
  --tw-shadow-color: rgb(204 251 241 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/15 {
  --tw-shadow-color: rgb(204 251 241 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/20 {
  --tw-shadow-color: rgb(204 251 241 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/25 {
  --tw-shadow-color: rgb(204 251 241 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/30 {
  --tw-shadow-color: rgb(204 251 241 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/35 {
  --tw-shadow-color: rgb(204 251 241 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/40 {
  --tw-shadow-color: rgb(204 251 241 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/45 {
  --tw-shadow-color: rgb(204 251 241 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/5 {
  --tw-shadow-color: rgb(204 251 241 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/50 {
  --tw-shadow-color: rgb(204 251 241 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/55 {
  --tw-shadow-color: rgb(204 251 241 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/60 {
  --tw-shadow-color: rgb(204 251 241 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/65 {
  --tw-shadow-color: rgb(204 251 241 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/70 {
  --tw-shadow-color: rgb(204 251 241 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/75 {
  --tw-shadow-color: rgb(204 251 241 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/80 {
  --tw-shadow-color: rgb(204 251 241 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/85 {
  --tw-shadow-color: rgb(204 251 241 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/90 {
  --tw-shadow-color: rgb(204 251 241 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-100\/95 {
  --tw-shadow-color: rgb(204 251 241 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200 {
  --tw-shadow-color: #99f6e4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/0 {
  --tw-shadow-color: rgb(153 246 228 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/10 {
  --tw-shadow-color: rgb(153 246 228 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/100 {
  --tw-shadow-color: rgb(153 246 228 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/15 {
  --tw-shadow-color: rgb(153 246 228 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/20 {
  --tw-shadow-color: rgb(153 246 228 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/25 {
  --tw-shadow-color: rgb(153 246 228 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/30 {
  --tw-shadow-color: rgb(153 246 228 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/35 {
  --tw-shadow-color: rgb(153 246 228 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/40 {
  --tw-shadow-color: rgb(153 246 228 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/45 {
  --tw-shadow-color: rgb(153 246 228 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/5 {
  --tw-shadow-color: rgb(153 246 228 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/50 {
  --tw-shadow-color: rgb(153 246 228 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/55 {
  --tw-shadow-color: rgb(153 246 228 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/60 {
  --tw-shadow-color: rgb(153 246 228 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/65 {
  --tw-shadow-color: rgb(153 246 228 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/70 {
  --tw-shadow-color: rgb(153 246 228 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/75 {
  --tw-shadow-color: rgb(153 246 228 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/80 {
  --tw-shadow-color: rgb(153 246 228 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/85 {
  --tw-shadow-color: rgb(153 246 228 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/90 {
  --tw-shadow-color: rgb(153 246 228 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-200\/95 {
  --tw-shadow-color: rgb(153 246 228 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300 {
  --tw-shadow-color: #5eead4 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/0 {
  --tw-shadow-color: rgb(94 234 212 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/10 {
  --tw-shadow-color: rgb(94 234 212 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/100 {
  --tw-shadow-color: rgb(94 234 212 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/15 {
  --tw-shadow-color: rgb(94 234 212 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/20 {
  --tw-shadow-color: rgb(94 234 212 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/25 {
  --tw-shadow-color: rgb(94 234 212 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/30 {
  --tw-shadow-color: rgb(94 234 212 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/35 {
  --tw-shadow-color: rgb(94 234 212 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/40 {
  --tw-shadow-color: rgb(94 234 212 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/45 {
  --tw-shadow-color: rgb(94 234 212 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/5 {
  --tw-shadow-color: rgb(94 234 212 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/50 {
  --tw-shadow-color: rgb(94 234 212 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/55 {
  --tw-shadow-color: rgb(94 234 212 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/60 {
  --tw-shadow-color: rgb(94 234 212 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/65 {
  --tw-shadow-color: rgb(94 234 212 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/70 {
  --tw-shadow-color: rgb(94 234 212 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/75 {
  --tw-shadow-color: rgb(94 234 212 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/80 {
  --tw-shadow-color: rgb(94 234 212 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/85 {
  --tw-shadow-color: rgb(94 234 212 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/90 {
  --tw-shadow-color: rgb(94 234 212 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-300\/95 {
  --tw-shadow-color: rgb(94 234 212 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400 {
  --tw-shadow-color: #2dd4bf !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/0 {
  --tw-shadow-color: rgb(45 212 191 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/10 {
  --tw-shadow-color: rgb(45 212 191 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/100 {
  --tw-shadow-color: rgb(45 212 191 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/15 {
  --tw-shadow-color: rgb(45 212 191 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/20 {
  --tw-shadow-color: rgb(45 212 191 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/25 {
  --tw-shadow-color: rgb(45 212 191 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/30 {
  --tw-shadow-color: rgb(45 212 191 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/35 {
  --tw-shadow-color: rgb(45 212 191 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/40 {
  --tw-shadow-color: rgb(45 212 191 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/45 {
  --tw-shadow-color: rgb(45 212 191 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/5 {
  --tw-shadow-color: rgb(45 212 191 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/50 {
  --tw-shadow-color: rgb(45 212 191 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/55 {
  --tw-shadow-color: rgb(45 212 191 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/60 {
  --tw-shadow-color: rgb(45 212 191 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/65 {
  --tw-shadow-color: rgb(45 212 191 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/70 {
  --tw-shadow-color: rgb(45 212 191 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/75 {
  --tw-shadow-color: rgb(45 212 191 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/80 {
  --tw-shadow-color: rgb(45 212 191 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/85 {
  --tw-shadow-color: rgb(45 212 191 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/90 {
  --tw-shadow-color: rgb(45 212 191 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-400\/95 {
  --tw-shadow-color: rgb(45 212 191 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50 {
  --tw-shadow-color: #f0fdfa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/0 {
  --tw-shadow-color: rgb(240 253 250 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/10 {
  --tw-shadow-color: rgb(240 253 250 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/100 {
  --tw-shadow-color: rgb(240 253 250 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/15 {
  --tw-shadow-color: rgb(240 253 250 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/20 {
  --tw-shadow-color: rgb(240 253 250 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/25 {
  --tw-shadow-color: rgb(240 253 250 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/30 {
  --tw-shadow-color: rgb(240 253 250 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/35 {
  --tw-shadow-color: rgb(240 253 250 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/40 {
  --tw-shadow-color: rgb(240 253 250 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/45 {
  --tw-shadow-color: rgb(240 253 250 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/5 {
  --tw-shadow-color: rgb(240 253 250 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/50 {
  --tw-shadow-color: rgb(240 253 250 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/55 {
  --tw-shadow-color: rgb(240 253 250 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/60 {
  --tw-shadow-color: rgb(240 253 250 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/65 {
  --tw-shadow-color: rgb(240 253 250 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/70 {
  --tw-shadow-color: rgb(240 253 250 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/75 {
  --tw-shadow-color: rgb(240 253 250 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/80 {
  --tw-shadow-color: rgb(240 253 250 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/85 {
  --tw-shadow-color: rgb(240 253 250 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/90 {
  --tw-shadow-color: rgb(240 253 250 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-50\/95 {
  --tw-shadow-color: rgb(240 253 250 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500 {
  --tw-shadow-color: #14b8a6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/0 {
  --tw-shadow-color: rgb(20 184 166 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/10 {
  --tw-shadow-color: rgb(20 184 166 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/100 {
  --tw-shadow-color: rgb(20 184 166 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/15 {
  --tw-shadow-color: rgb(20 184 166 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/20 {
  --tw-shadow-color: rgb(20 184 166 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/25 {
  --tw-shadow-color: rgb(20 184 166 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/30 {
  --tw-shadow-color: rgb(20 184 166 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/35 {
  --tw-shadow-color: rgb(20 184 166 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/40 {
  --tw-shadow-color: rgb(20 184 166 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/45 {
  --tw-shadow-color: rgb(20 184 166 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/5 {
  --tw-shadow-color: rgb(20 184 166 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/50 {
  --tw-shadow-color: rgb(20 184 166 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/55 {
  --tw-shadow-color: rgb(20 184 166 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/60 {
  --tw-shadow-color: rgb(20 184 166 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/65 {
  --tw-shadow-color: rgb(20 184 166 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/70 {
  --tw-shadow-color: rgb(20 184 166 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/75 {
  --tw-shadow-color: rgb(20 184 166 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/80 {
  --tw-shadow-color: rgb(20 184 166 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/85 {
  --tw-shadow-color: rgb(20 184 166 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/90 {
  --tw-shadow-color: rgb(20 184 166 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-500\/95 {
  --tw-shadow-color: rgb(20 184 166 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600 {
  --tw-shadow-color: #0d9488 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/0 {
  --tw-shadow-color: rgb(13 148 136 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/10 {
  --tw-shadow-color: rgb(13 148 136 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/100 {
  --tw-shadow-color: rgb(13 148 136 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/15 {
  --tw-shadow-color: rgb(13 148 136 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/20 {
  --tw-shadow-color: rgb(13 148 136 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/25 {
  --tw-shadow-color: rgb(13 148 136 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/30 {
  --tw-shadow-color: rgb(13 148 136 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/35 {
  --tw-shadow-color: rgb(13 148 136 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/40 {
  --tw-shadow-color: rgb(13 148 136 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/45 {
  --tw-shadow-color: rgb(13 148 136 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/5 {
  --tw-shadow-color: rgb(13 148 136 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/50 {
  --tw-shadow-color: rgb(13 148 136 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/55 {
  --tw-shadow-color: rgb(13 148 136 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/60 {
  --tw-shadow-color: rgb(13 148 136 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/65 {
  --tw-shadow-color: rgb(13 148 136 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/70 {
  --tw-shadow-color: rgb(13 148 136 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/75 {
  --tw-shadow-color: rgb(13 148 136 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/80 {
  --tw-shadow-color: rgb(13 148 136 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/85 {
  --tw-shadow-color: rgb(13 148 136 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/90 {
  --tw-shadow-color: rgb(13 148 136 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-600\/95 {
  --tw-shadow-color: rgb(13 148 136 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700 {
  --tw-shadow-color: #0f766e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/0 {
  --tw-shadow-color: rgb(15 118 110 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/10 {
  --tw-shadow-color: rgb(15 118 110 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/100 {
  --tw-shadow-color: rgb(15 118 110 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/15 {
  --tw-shadow-color: rgb(15 118 110 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/20 {
  --tw-shadow-color: rgb(15 118 110 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/25 {
  --tw-shadow-color: rgb(15 118 110 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/30 {
  --tw-shadow-color: rgb(15 118 110 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/35 {
  --tw-shadow-color: rgb(15 118 110 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/40 {
  --tw-shadow-color: rgb(15 118 110 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/45 {
  --tw-shadow-color: rgb(15 118 110 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/5 {
  --tw-shadow-color: rgb(15 118 110 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/50 {
  --tw-shadow-color: rgb(15 118 110 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/55 {
  --tw-shadow-color: rgb(15 118 110 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/60 {
  --tw-shadow-color: rgb(15 118 110 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/65 {
  --tw-shadow-color: rgb(15 118 110 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/70 {
  --tw-shadow-color: rgb(15 118 110 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/75 {
  --tw-shadow-color: rgb(15 118 110 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/80 {
  --tw-shadow-color: rgb(15 118 110 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/85 {
  --tw-shadow-color: rgb(15 118 110 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/90 {
  --tw-shadow-color: rgb(15 118 110 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-700\/95 {
  --tw-shadow-color: rgb(15 118 110 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800 {
  --tw-shadow-color: #115e59 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/0 {
  --tw-shadow-color: rgb(17 94 89 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/10 {
  --tw-shadow-color: rgb(17 94 89 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/100 {
  --tw-shadow-color: rgb(17 94 89 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/15 {
  --tw-shadow-color: rgb(17 94 89 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/20 {
  --tw-shadow-color: rgb(17 94 89 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/25 {
  --tw-shadow-color: rgb(17 94 89 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/30 {
  --tw-shadow-color: rgb(17 94 89 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/35 {
  --tw-shadow-color: rgb(17 94 89 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/40 {
  --tw-shadow-color: rgb(17 94 89 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/45 {
  --tw-shadow-color: rgb(17 94 89 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/5 {
  --tw-shadow-color: rgb(17 94 89 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/50 {
  --tw-shadow-color: rgb(17 94 89 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/55 {
  --tw-shadow-color: rgb(17 94 89 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/60 {
  --tw-shadow-color: rgb(17 94 89 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/65 {
  --tw-shadow-color: rgb(17 94 89 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/70 {
  --tw-shadow-color: rgb(17 94 89 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/75 {
  --tw-shadow-color: rgb(17 94 89 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/80 {
  --tw-shadow-color: rgb(17 94 89 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/85 {
  --tw-shadow-color: rgb(17 94 89 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/90 {
  --tw-shadow-color: rgb(17 94 89 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-800\/95 {
  --tw-shadow-color: rgb(17 94 89 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900 {
  --tw-shadow-color: #134e4a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/0 {
  --tw-shadow-color: rgb(19 78 74 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/10 {
  --tw-shadow-color: rgb(19 78 74 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/100 {
  --tw-shadow-color: rgb(19 78 74 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/15 {
  --tw-shadow-color: rgb(19 78 74 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/20 {
  --tw-shadow-color: rgb(19 78 74 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/25 {
  --tw-shadow-color: rgb(19 78 74 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/30 {
  --tw-shadow-color: rgb(19 78 74 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/35 {
  --tw-shadow-color: rgb(19 78 74 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/40 {
  --tw-shadow-color: rgb(19 78 74 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/45 {
  --tw-shadow-color: rgb(19 78 74 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/5 {
  --tw-shadow-color: rgb(19 78 74 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/50 {
  --tw-shadow-color: rgb(19 78 74 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/55 {
  --tw-shadow-color: rgb(19 78 74 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/60 {
  --tw-shadow-color: rgb(19 78 74 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/65 {
  --tw-shadow-color: rgb(19 78 74 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/70 {
  --tw-shadow-color: rgb(19 78 74 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/75 {
  --tw-shadow-color: rgb(19 78 74 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/80 {
  --tw-shadow-color: rgb(19 78 74 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/85 {
  --tw-shadow-color: rgb(19 78 74 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/90 {
  --tw-shadow-color: rgb(19 78 74 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-900\/95 {
  --tw-shadow-color: rgb(19 78 74 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950 {
  --tw-shadow-color: #042f2e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/0 {
  --tw-shadow-color: rgb(4 47 46 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/10 {
  --tw-shadow-color: rgb(4 47 46 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/100 {
  --tw-shadow-color: rgb(4 47 46 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/15 {
  --tw-shadow-color: rgb(4 47 46 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/20 {
  --tw-shadow-color: rgb(4 47 46 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/25 {
  --tw-shadow-color: rgb(4 47 46 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/30 {
  --tw-shadow-color: rgb(4 47 46 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/35 {
  --tw-shadow-color: rgb(4 47 46 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/40 {
  --tw-shadow-color: rgb(4 47 46 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/45 {
  --tw-shadow-color: rgb(4 47 46 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/5 {
  --tw-shadow-color: rgb(4 47 46 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/50 {
  --tw-shadow-color: rgb(4 47 46 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/55 {
  --tw-shadow-color: rgb(4 47 46 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/60 {
  --tw-shadow-color: rgb(4 47 46 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/65 {
  --tw-shadow-color: rgb(4 47 46 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/70 {
  --tw-shadow-color: rgb(4 47 46 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/75 {
  --tw-shadow-color: rgb(4 47 46 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/80 {
  --tw-shadow-color: rgb(4 47 46 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/85 {
  --tw-shadow-color: rgb(4 47 46 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/90 {
  --tw-shadow-color: rgb(4 47 46 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-teal-950\/95 {
  --tw-shadow-color: rgb(4 47 46 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent {
  --tw-shadow-color: transparent !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/0 {
  --tw-shadow-color: rgb(0 0 0 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/10 {
  --tw-shadow-color: rgb(0 0 0 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/100 {
  --tw-shadow-color: rgb(0 0 0 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/15 {
  --tw-shadow-color: rgb(0 0 0 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/20 {
  --tw-shadow-color: rgb(0 0 0 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/25 {
  --tw-shadow-color: rgb(0 0 0 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/30 {
  --tw-shadow-color: rgb(0 0 0 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/35 {
  --tw-shadow-color: rgb(0 0 0 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/40 {
  --tw-shadow-color: rgb(0 0 0 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/45 {
  --tw-shadow-color: rgb(0 0 0 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/5 {
  --tw-shadow-color: rgb(0 0 0 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/50 {
  --tw-shadow-color: rgb(0 0 0 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/55 {
  --tw-shadow-color: rgb(0 0 0 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/60 {
  --tw-shadow-color: rgb(0 0 0 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/65 {
  --tw-shadow-color: rgb(0 0 0 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/70 {
  --tw-shadow-color: rgb(0 0 0 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/75 {
  --tw-shadow-color: rgb(0 0 0 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/80 {
  --tw-shadow-color: rgb(0 0 0 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/85 {
  --tw-shadow-color: rgb(0 0 0 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/90 {
  --tw-shadow-color: rgb(0 0 0 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-transparent\/95 {
  --tw-shadow-color: rgb(0 0 0 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100 {
  --tw-shadow-color: #ede9fe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/0 {
  --tw-shadow-color: rgb(237 233 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/10 {
  --tw-shadow-color: rgb(237 233 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/100 {
  --tw-shadow-color: rgb(237 233 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/15 {
  --tw-shadow-color: rgb(237 233 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/20 {
  --tw-shadow-color: rgb(237 233 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/25 {
  --tw-shadow-color: rgb(237 233 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/30 {
  --tw-shadow-color: rgb(237 233 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/35 {
  --tw-shadow-color: rgb(237 233 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/40 {
  --tw-shadow-color: rgb(237 233 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/45 {
  --tw-shadow-color: rgb(237 233 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/5 {
  --tw-shadow-color: rgb(237 233 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/50 {
  --tw-shadow-color: rgb(237 233 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/55 {
  --tw-shadow-color: rgb(237 233 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/60 {
  --tw-shadow-color: rgb(237 233 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/65 {
  --tw-shadow-color: rgb(237 233 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/70 {
  --tw-shadow-color: rgb(237 233 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/75 {
  --tw-shadow-color: rgb(237 233 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/80 {
  --tw-shadow-color: rgb(237 233 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/85 {
  --tw-shadow-color: rgb(237 233 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/90 {
  --tw-shadow-color: rgb(237 233 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-100\/95 {
  --tw-shadow-color: rgb(237 233 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200 {
  --tw-shadow-color: #ddd6fe !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/0 {
  --tw-shadow-color: rgb(221 214 254 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/10 {
  --tw-shadow-color: rgb(221 214 254 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/100 {
  --tw-shadow-color: rgb(221 214 254 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/15 {
  --tw-shadow-color: rgb(221 214 254 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/20 {
  --tw-shadow-color: rgb(221 214 254 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/25 {
  --tw-shadow-color: rgb(221 214 254 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/30 {
  --tw-shadow-color: rgb(221 214 254 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/35 {
  --tw-shadow-color: rgb(221 214 254 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/40 {
  --tw-shadow-color: rgb(221 214 254 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/45 {
  --tw-shadow-color: rgb(221 214 254 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/5 {
  --tw-shadow-color: rgb(221 214 254 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/50 {
  --tw-shadow-color: rgb(221 214 254 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/55 {
  --tw-shadow-color: rgb(221 214 254 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/60 {
  --tw-shadow-color: rgb(221 214 254 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/65 {
  --tw-shadow-color: rgb(221 214 254 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/70 {
  --tw-shadow-color: rgb(221 214 254 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/75 {
  --tw-shadow-color: rgb(221 214 254 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/80 {
  --tw-shadow-color: rgb(221 214 254 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/85 {
  --tw-shadow-color: rgb(221 214 254 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/90 {
  --tw-shadow-color: rgb(221 214 254 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-200\/95 {
  --tw-shadow-color: rgb(221 214 254 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300 {
  --tw-shadow-color: #c4b5fd !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/0 {
  --tw-shadow-color: rgb(196 181 253 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/10 {
  --tw-shadow-color: rgb(196 181 253 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/100 {
  --tw-shadow-color: rgb(196 181 253 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/15 {
  --tw-shadow-color: rgb(196 181 253 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/20 {
  --tw-shadow-color: rgb(196 181 253 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/25 {
  --tw-shadow-color: rgb(196 181 253 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/30 {
  --tw-shadow-color: rgb(196 181 253 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/35 {
  --tw-shadow-color: rgb(196 181 253 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/40 {
  --tw-shadow-color: rgb(196 181 253 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/45 {
  --tw-shadow-color: rgb(196 181 253 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/5 {
  --tw-shadow-color: rgb(196 181 253 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/50 {
  --tw-shadow-color: rgb(196 181 253 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/55 {
  --tw-shadow-color: rgb(196 181 253 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/60 {
  --tw-shadow-color: rgb(196 181 253 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/65 {
  --tw-shadow-color: rgb(196 181 253 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/70 {
  --tw-shadow-color: rgb(196 181 253 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/75 {
  --tw-shadow-color: rgb(196 181 253 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/80 {
  --tw-shadow-color: rgb(196 181 253 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/85 {
  --tw-shadow-color: rgb(196 181 253 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/90 {
  --tw-shadow-color: rgb(196 181 253 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-300\/95 {
  --tw-shadow-color: rgb(196 181 253 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400 {
  --tw-shadow-color: #a78bfa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/0 {
  --tw-shadow-color: rgb(167 139 250 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/10 {
  --tw-shadow-color: rgb(167 139 250 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/100 {
  --tw-shadow-color: rgb(167 139 250 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/15 {
  --tw-shadow-color: rgb(167 139 250 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/20 {
  --tw-shadow-color: rgb(167 139 250 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/25 {
  --tw-shadow-color: rgb(167 139 250 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/30 {
  --tw-shadow-color: rgb(167 139 250 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/35 {
  --tw-shadow-color: rgb(167 139 250 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/40 {
  --tw-shadow-color: rgb(167 139 250 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/45 {
  --tw-shadow-color: rgb(167 139 250 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/5 {
  --tw-shadow-color: rgb(167 139 250 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/50 {
  --tw-shadow-color: rgb(167 139 250 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/55 {
  --tw-shadow-color: rgb(167 139 250 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/60 {
  --tw-shadow-color: rgb(167 139 250 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/65 {
  --tw-shadow-color: rgb(167 139 250 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/70 {
  --tw-shadow-color: rgb(167 139 250 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/75 {
  --tw-shadow-color: rgb(167 139 250 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/80 {
  --tw-shadow-color: rgb(167 139 250 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/85 {
  --tw-shadow-color: rgb(167 139 250 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/90 {
  --tw-shadow-color: rgb(167 139 250 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-400\/95 {
  --tw-shadow-color: rgb(167 139 250 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50 {
  --tw-shadow-color: #f5f3ff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/0 {
  --tw-shadow-color: rgb(245 243 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/10 {
  --tw-shadow-color: rgb(245 243 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/100 {
  --tw-shadow-color: rgb(245 243 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/15 {
  --tw-shadow-color: rgb(245 243 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/20 {
  --tw-shadow-color: rgb(245 243 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/25 {
  --tw-shadow-color: rgb(245 243 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/30 {
  --tw-shadow-color: rgb(245 243 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/35 {
  --tw-shadow-color: rgb(245 243 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/40 {
  --tw-shadow-color: rgb(245 243 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/45 {
  --tw-shadow-color: rgb(245 243 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/5 {
  --tw-shadow-color: rgb(245 243 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/50 {
  --tw-shadow-color: rgb(245 243 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/55 {
  --tw-shadow-color: rgb(245 243 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/60 {
  --tw-shadow-color: rgb(245 243 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/65 {
  --tw-shadow-color: rgb(245 243 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/70 {
  --tw-shadow-color: rgb(245 243 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/75 {
  --tw-shadow-color: rgb(245 243 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/80 {
  --tw-shadow-color: rgb(245 243 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/85 {
  --tw-shadow-color: rgb(245 243 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/90 {
  --tw-shadow-color: rgb(245 243 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-50\/95 {
  --tw-shadow-color: rgb(245 243 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500 {
  --tw-shadow-color: #8b5cf6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/0 {
  --tw-shadow-color: rgb(139 92 246 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/10 {
  --tw-shadow-color: rgb(139 92 246 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/100 {
  --tw-shadow-color: rgb(139 92 246 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/15 {
  --tw-shadow-color: rgb(139 92 246 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/20 {
  --tw-shadow-color: rgb(139 92 246 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/25 {
  --tw-shadow-color: rgb(139 92 246 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/30 {
  --tw-shadow-color: rgb(139 92 246 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/35 {
  --tw-shadow-color: rgb(139 92 246 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/40 {
  --tw-shadow-color: rgb(139 92 246 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/45 {
  --tw-shadow-color: rgb(139 92 246 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/5 {
  --tw-shadow-color: rgb(139 92 246 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/50 {
  --tw-shadow-color: rgb(139 92 246 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/55 {
  --tw-shadow-color: rgb(139 92 246 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/60 {
  --tw-shadow-color: rgb(139 92 246 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/65 {
  --tw-shadow-color: rgb(139 92 246 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/70 {
  --tw-shadow-color: rgb(139 92 246 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/75 {
  --tw-shadow-color: rgb(139 92 246 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/80 {
  --tw-shadow-color: rgb(139 92 246 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/85 {
  --tw-shadow-color: rgb(139 92 246 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/90 {
  --tw-shadow-color: rgb(139 92 246 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-500\/95 {
  --tw-shadow-color: rgb(139 92 246 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600 {
  --tw-shadow-color: #7c3aed !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/0 {
  --tw-shadow-color: rgb(124 58 237 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/10 {
  --tw-shadow-color: rgb(124 58 237 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/100 {
  --tw-shadow-color: rgb(124 58 237 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/15 {
  --tw-shadow-color: rgb(124 58 237 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/20 {
  --tw-shadow-color: rgb(124 58 237 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/25 {
  --tw-shadow-color: rgb(124 58 237 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/30 {
  --tw-shadow-color: rgb(124 58 237 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/35 {
  --tw-shadow-color: rgb(124 58 237 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/40 {
  --tw-shadow-color: rgb(124 58 237 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/45 {
  --tw-shadow-color: rgb(124 58 237 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/5 {
  --tw-shadow-color: rgb(124 58 237 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/50 {
  --tw-shadow-color: rgb(124 58 237 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/55 {
  --tw-shadow-color: rgb(124 58 237 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/60 {
  --tw-shadow-color: rgb(124 58 237 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/65 {
  --tw-shadow-color: rgb(124 58 237 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/70 {
  --tw-shadow-color: rgb(124 58 237 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/75 {
  --tw-shadow-color: rgb(124 58 237 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/80 {
  --tw-shadow-color: rgb(124 58 237 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/85 {
  --tw-shadow-color: rgb(124 58 237 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/90 {
  --tw-shadow-color: rgb(124 58 237 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-600\/95 {
  --tw-shadow-color: rgb(124 58 237 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700 {
  --tw-shadow-color: #6d28d9 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/0 {
  --tw-shadow-color: rgb(109 40 217 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/10 {
  --tw-shadow-color: rgb(109 40 217 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/100 {
  --tw-shadow-color: rgb(109 40 217 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/15 {
  --tw-shadow-color: rgb(109 40 217 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/20 {
  --tw-shadow-color: rgb(109 40 217 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/25 {
  --tw-shadow-color: rgb(109 40 217 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/30 {
  --tw-shadow-color: rgb(109 40 217 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/35 {
  --tw-shadow-color: rgb(109 40 217 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/40 {
  --tw-shadow-color: rgb(109 40 217 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/45 {
  --tw-shadow-color: rgb(109 40 217 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/5 {
  --tw-shadow-color: rgb(109 40 217 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/50 {
  --tw-shadow-color: rgb(109 40 217 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/55 {
  --tw-shadow-color: rgb(109 40 217 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/60 {
  --tw-shadow-color: rgb(109 40 217 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/65 {
  --tw-shadow-color: rgb(109 40 217 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/70 {
  --tw-shadow-color: rgb(109 40 217 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/75 {
  --tw-shadow-color: rgb(109 40 217 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/80 {
  --tw-shadow-color: rgb(109 40 217 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/85 {
  --tw-shadow-color: rgb(109 40 217 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/90 {
  --tw-shadow-color: rgb(109 40 217 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-700\/95 {
  --tw-shadow-color: rgb(109 40 217 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800 {
  --tw-shadow-color: #5b21b6 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/0 {
  --tw-shadow-color: rgb(91 33 182 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/10 {
  --tw-shadow-color: rgb(91 33 182 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/100 {
  --tw-shadow-color: rgb(91 33 182 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/15 {
  --tw-shadow-color: rgb(91 33 182 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/20 {
  --tw-shadow-color: rgb(91 33 182 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/25 {
  --tw-shadow-color: rgb(91 33 182 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/30 {
  --tw-shadow-color: rgb(91 33 182 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/35 {
  --tw-shadow-color: rgb(91 33 182 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/40 {
  --tw-shadow-color: rgb(91 33 182 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/45 {
  --tw-shadow-color: rgb(91 33 182 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/5 {
  --tw-shadow-color: rgb(91 33 182 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/50 {
  --tw-shadow-color: rgb(91 33 182 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/55 {
  --tw-shadow-color: rgb(91 33 182 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/60 {
  --tw-shadow-color: rgb(91 33 182 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/65 {
  --tw-shadow-color: rgb(91 33 182 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/70 {
  --tw-shadow-color: rgb(91 33 182 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/75 {
  --tw-shadow-color: rgb(91 33 182 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/80 {
  --tw-shadow-color: rgb(91 33 182 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/85 {
  --tw-shadow-color: rgb(91 33 182 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/90 {
  --tw-shadow-color: rgb(91 33 182 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-800\/95 {
  --tw-shadow-color: rgb(91 33 182 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900 {
  --tw-shadow-color: #4c1d95 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/0 {
  --tw-shadow-color: rgb(76 29 149 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/10 {
  --tw-shadow-color: rgb(76 29 149 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/100 {
  --tw-shadow-color: rgb(76 29 149 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/15 {
  --tw-shadow-color: rgb(76 29 149 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/20 {
  --tw-shadow-color: rgb(76 29 149 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/25 {
  --tw-shadow-color: rgb(76 29 149 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/30 {
  --tw-shadow-color: rgb(76 29 149 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/35 {
  --tw-shadow-color: rgb(76 29 149 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/40 {
  --tw-shadow-color: rgb(76 29 149 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/45 {
  --tw-shadow-color: rgb(76 29 149 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/5 {
  --tw-shadow-color: rgb(76 29 149 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/50 {
  --tw-shadow-color: rgb(76 29 149 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/55 {
  --tw-shadow-color: rgb(76 29 149 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/60 {
  --tw-shadow-color: rgb(76 29 149 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/65 {
  --tw-shadow-color: rgb(76 29 149 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/70 {
  --tw-shadow-color: rgb(76 29 149 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/75 {
  --tw-shadow-color: rgb(76 29 149 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/80 {
  --tw-shadow-color: rgb(76 29 149 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/85 {
  --tw-shadow-color: rgb(76 29 149 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/90 {
  --tw-shadow-color: rgb(76 29 149 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-900\/95 {
  --tw-shadow-color: rgb(76 29 149 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950 {
  --tw-shadow-color: #2e1065 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/0 {
  --tw-shadow-color: rgb(46 16 101 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/10 {
  --tw-shadow-color: rgb(46 16 101 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/100 {
  --tw-shadow-color: rgb(46 16 101 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/15 {
  --tw-shadow-color: rgb(46 16 101 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/20 {
  --tw-shadow-color: rgb(46 16 101 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/25 {
  --tw-shadow-color: rgb(46 16 101 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/30 {
  --tw-shadow-color: rgb(46 16 101 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/35 {
  --tw-shadow-color: rgb(46 16 101 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/40 {
  --tw-shadow-color: rgb(46 16 101 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/45 {
  --tw-shadow-color: rgb(46 16 101 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/5 {
  --tw-shadow-color: rgb(46 16 101 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/50 {
  --tw-shadow-color: rgb(46 16 101 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/55 {
  --tw-shadow-color: rgb(46 16 101 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/60 {
  --tw-shadow-color: rgb(46 16 101 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/65 {
  --tw-shadow-color: rgb(46 16 101 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/70 {
  --tw-shadow-color: rgb(46 16 101 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/75 {
  --tw-shadow-color: rgb(46 16 101 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/80 {
  --tw-shadow-color: rgb(46 16 101 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/85 {
  --tw-shadow-color: rgb(46 16 101 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/90 {
  --tw-shadow-color: rgb(46 16 101 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-violet-950\/95 {
  --tw-shadow-color: rgb(46 16 101 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white {
  --tw-shadow-color: #fff !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/0 {
  --tw-shadow-color: rgb(255 255 255 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/10 {
  --tw-shadow-color: rgb(255 255 255 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/100 {
  --tw-shadow-color: rgb(255 255 255 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/15 {
  --tw-shadow-color: rgb(255 255 255 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/20 {
  --tw-shadow-color: rgb(255 255 255 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/25 {
  --tw-shadow-color: rgb(255 255 255 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/30 {
  --tw-shadow-color: rgb(255 255 255 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/35 {
  --tw-shadow-color: rgb(255 255 255 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/40 {
  --tw-shadow-color: rgb(255 255 255 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/45 {
  --tw-shadow-color: rgb(255 255 255 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/5 {
  --tw-shadow-color: rgb(255 255 255 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/50 {
  --tw-shadow-color: rgb(255 255 255 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/55 {
  --tw-shadow-color: rgb(255 255 255 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/60 {
  --tw-shadow-color: rgb(255 255 255 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/65 {
  --tw-shadow-color: rgb(255 255 255 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/70 {
  --tw-shadow-color: rgb(255 255 255 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/75 {
  --tw-shadow-color: rgb(255 255 255 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/80 {
  --tw-shadow-color: rgb(255 255 255 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/85 {
  --tw-shadow-color: rgb(255 255 255 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/90 {
  --tw-shadow-color: rgb(255 255 255 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-white\/95 {
  --tw-shadow-color: rgb(255 255 255 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100 {
  --tw-shadow-color: #fef9c3 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/0 {
  --tw-shadow-color: rgb(254 249 195 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/10 {
  --tw-shadow-color: rgb(254 249 195 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/100 {
  --tw-shadow-color: rgb(254 249 195 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/15 {
  --tw-shadow-color: rgb(254 249 195 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/20 {
  --tw-shadow-color: rgb(254 249 195 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/25 {
  --tw-shadow-color: rgb(254 249 195 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/30 {
  --tw-shadow-color: rgb(254 249 195 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/35 {
  --tw-shadow-color: rgb(254 249 195 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/40 {
  --tw-shadow-color: rgb(254 249 195 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/45 {
  --tw-shadow-color: rgb(254 249 195 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/5 {
  --tw-shadow-color: rgb(254 249 195 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/50 {
  --tw-shadow-color: rgb(254 249 195 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/55 {
  --tw-shadow-color: rgb(254 249 195 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/60 {
  --tw-shadow-color: rgb(254 249 195 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/65 {
  --tw-shadow-color: rgb(254 249 195 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/70 {
  --tw-shadow-color: rgb(254 249 195 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/75 {
  --tw-shadow-color: rgb(254 249 195 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/80 {
  --tw-shadow-color: rgb(254 249 195 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/85 {
  --tw-shadow-color: rgb(254 249 195 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/90 {
  --tw-shadow-color: rgb(254 249 195 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-100\/95 {
  --tw-shadow-color: rgb(254 249 195 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200 {
  --tw-shadow-color: #fef08a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/0 {
  --tw-shadow-color: rgb(254 240 138 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/10 {
  --tw-shadow-color: rgb(254 240 138 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/100 {
  --tw-shadow-color: rgb(254 240 138 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/15 {
  --tw-shadow-color: rgb(254 240 138 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/20 {
  --tw-shadow-color: rgb(254 240 138 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/25 {
  --tw-shadow-color: rgb(254 240 138 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/30 {
  --tw-shadow-color: rgb(254 240 138 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/35 {
  --tw-shadow-color: rgb(254 240 138 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/40 {
  --tw-shadow-color: rgb(254 240 138 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/45 {
  --tw-shadow-color: rgb(254 240 138 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/5 {
  --tw-shadow-color: rgb(254 240 138 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/50 {
  --tw-shadow-color: rgb(254 240 138 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/55 {
  --tw-shadow-color: rgb(254 240 138 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/60 {
  --tw-shadow-color: rgb(254 240 138 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/65 {
  --tw-shadow-color: rgb(254 240 138 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/70 {
  --tw-shadow-color: rgb(254 240 138 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/75 {
  --tw-shadow-color: rgb(254 240 138 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/80 {
  --tw-shadow-color: rgb(254 240 138 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/85 {
  --tw-shadow-color: rgb(254 240 138 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/90 {
  --tw-shadow-color: rgb(254 240 138 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-200\/95 {
  --tw-shadow-color: rgb(254 240 138 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300 {
  --tw-shadow-color: #fde047 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/0 {
  --tw-shadow-color: rgb(253 224 71 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/10 {
  --tw-shadow-color: rgb(253 224 71 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/100 {
  --tw-shadow-color: rgb(253 224 71 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/15 {
  --tw-shadow-color: rgb(253 224 71 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/20 {
  --tw-shadow-color: rgb(253 224 71 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/25 {
  --tw-shadow-color: rgb(253 224 71 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/30 {
  --tw-shadow-color: rgb(253 224 71 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/35 {
  --tw-shadow-color: rgb(253 224 71 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/40 {
  --tw-shadow-color: rgb(253 224 71 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/45 {
  --tw-shadow-color: rgb(253 224 71 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/5 {
  --tw-shadow-color: rgb(253 224 71 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/50 {
  --tw-shadow-color: rgb(253 224 71 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/55 {
  --tw-shadow-color: rgb(253 224 71 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/60 {
  --tw-shadow-color: rgb(253 224 71 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/65 {
  --tw-shadow-color: rgb(253 224 71 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/70 {
  --tw-shadow-color: rgb(253 224 71 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/75 {
  --tw-shadow-color: rgb(253 224 71 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/80 {
  --tw-shadow-color: rgb(253 224 71 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/85 {
  --tw-shadow-color: rgb(253 224 71 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/90 {
  --tw-shadow-color: rgb(253 224 71 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-300\/95 {
  --tw-shadow-color: rgb(253 224 71 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400 {
  --tw-shadow-color: #facc15 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/0 {
  --tw-shadow-color: rgb(250 204 21 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/10 {
  --tw-shadow-color: rgb(250 204 21 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/100 {
  --tw-shadow-color: rgb(250 204 21 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/15 {
  --tw-shadow-color: rgb(250 204 21 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/20 {
  --tw-shadow-color: rgb(250 204 21 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/25 {
  --tw-shadow-color: rgb(250 204 21 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/30 {
  --tw-shadow-color: rgb(250 204 21 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/35 {
  --tw-shadow-color: rgb(250 204 21 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/40 {
  --tw-shadow-color: rgb(250 204 21 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/45 {
  --tw-shadow-color: rgb(250 204 21 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/5 {
  --tw-shadow-color: rgb(250 204 21 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/50 {
  --tw-shadow-color: rgb(250 204 21 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/55 {
  --tw-shadow-color: rgb(250 204 21 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/60 {
  --tw-shadow-color: rgb(250 204 21 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/65 {
  --tw-shadow-color: rgb(250 204 21 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/70 {
  --tw-shadow-color: rgb(250 204 21 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/75 {
  --tw-shadow-color: rgb(250 204 21 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/80 {
  --tw-shadow-color: rgb(250 204 21 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/85 {
  --tw-shadow-color: rgb(250 204 21 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/90 {
  --tw-shadow-color: rgb(250 204 21 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-400\/95 {
  --tw-shadow-color: rgb(250 204 21 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50 {
  --tw-shadow-color: #fefce8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/0 {
  --tw-shadow-color: rgb(254 252 232 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/10 {
  --tw-shadow-color: rgb(254 252 232 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/100 {
  --tw-shadow-color: rgb(254 252 232 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/15 {
  --tw-shadow-color: rgb(254 252 232 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/20 {
  --tw-shadow-color: rgb(254 252 232 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/25 {
  --tw-shadow-color: rgb(254 252 232 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/30 {
  --tw-shadow-color: rgb(254 252 232 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/35 {
  --tw-shadow-color: rgb(254 252 232 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/40 {
  --tw-shadow-color: rgb(254 252 232 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/45 {
  --tw-shadow-color: rgb(254 252 232 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/5 {
  --tw-shadow-color: rgb(254 252 232 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/50 {
  --tw-shadow-color: rgb(254 252 232 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/55 {
  --tw-shadow-color: rgb(254 252 232 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/60 {
  --tw-shadow-color: rgb(254 252 232 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/65 {
  --tw-shadow-color: rgb(254 252 232 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/70 {
  --tw-shadow-color: rgb(254 252 232 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/75 {
  --tw-shadow-color: rgb(254 252 232 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/80 {
  --tw-shadow-color: rgb(254 252 232 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/85 {
  --tw-shadow-color: rgb(254 252 232 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/90 {
  --tw-shadow-color: rgb(254 252 232 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-50\/95 {
  --tw-shadow-color: rgb(254 252 232 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500 {
  --tw-shadow-color: #eab308 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/0 {
  --tw-shadow-color: rgb(234 179 8 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/10 {
  --tw-shadow-color: rgb(234 179 8 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/100 {
  --tw-shadow-color: rgb(234 179 8 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/15 {
  --tw-shadow-color: rgb(234 179 8 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/20 {
  --tw-shadow-color: rgb(234 179 8 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/25 {
  --tw-shadow-color: rgb(234 179 8 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/30 {
  --tw-shadow-color: rgb(234 179 8 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/35 {
  --tw-shadow-color: rgb(234 179 8 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/40 {
  --tw-shadow-color: rgb(234 179 8 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/45 {
  --tw-shadow-color: rgb(234 179 8 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/5 {
  --tw-shadow-color: rgb(234 179 8 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/50 {
  --tw-shadow-color: rgb(234 179 8 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/55 {
  --tw-shadow-color: rgb(234 179 8 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/60 {
  --tw-shadow-color: rgb(234 179 8 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/65 {
  --tw-shadow-color: rgb(234 179 8 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/70 {
  --tw-shadow-color: rgb(234 179 8 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/75 {
  --tw-shadow-color: rgb(234 179 8 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/80 {
  --tw-shadow-color: rgb(234 179 8 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/85 {
  --tw-shadow-color: rgb(234 179 8 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/90 {
  --tw-shadow-color: rgb(234 179 8 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-500\/95 {
  --tw-shadow-color: rgb(234 179 8 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600 {
  --tw-shadow-color: #ca8a04 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/0 {
  --tw-shadow-color: rgb(202 138 4 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/10 {
  --tw-shadow-color: rgb(202 138 4 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/100 {
  --tw-shadow-color: rgb(202 138 4 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/15 {
  --tw-shadow-color: rgb(202 138 4 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/20 {
  --tw-shadow-color: rgb(202 138 4 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/25 {
  --tw-shadow-color: rgb(202 138 4 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/30 {
  --tw-shadow-color: rgb(202 138 4 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/35 {
  --tw-shadow-color: rgb(202 138 4 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/40 {
  --tw-shadow-color: rgb(202 138 4 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/45 {
  --tw-shadow-color: rgb(202 138 4 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/5 {
  --tw-shadow-color: rgb(202 138 4 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/50 {
  --tw-shadow-color: rgb(202 138 4 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/55 {
  --tw-shadow-color: rgb(202 138 4 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/60 {
  --tw-shadow-color: rgb(202 138 4 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/65 {
  --tw-shadow-color: rgb(202 138 4 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/70 {
  --tw-shadow-color: rgb(202 138 4 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/75 {
  --tw-shadow-color: rgb(202 138 4 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/80 {
  --tw-shadow-color: rgb(202 138 4 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/85 {
  --tw-shadow-color: rgb(202 138 4 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/90 {
  --tw-shadow-color: rgb(202 138 4 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-600\/95 {
  --tw-shadow-color: rgb(202 138 4 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700 {
  --tw-shadow-color: #a16207 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/0 {
  --tw-shadow-color: rgb(161 98 7 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/10 {
  --tw-shadow-color: rgb(161 98 7 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/100 {
  --tw-shadow-color: rgb(161 98 7 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/15 {
  --tw-shadow-color: rgb(161 98 7 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/20 {
  --tw-shadow-color: rgb(161 98 7 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/25 {
  --tw-shadow-color: rgb(161 98 7 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/30 {
  --tw-shadow-color: rgb(161 98 7 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/35 {
  --tw-shadow-color: rgb(161 98 7 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/40 {
  --tw-shadow-color: rgb(161 98 7 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/45 {
  --tw-shadow-color: rgb(161 98 7 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/5 {
  --tw-shadow-color: rgb(161 98 7 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/50 {
  --tw-shadow-color: rgb(161 98 7 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/55 {
  --tw-shadow-color: rgb(161 98 7 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/60 {
  --tw-shadow-color: rgb(161 98 7 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/65 {
  --tw-shadow-color: rgb(161 98 7 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/70 {
  --tw-shadow-color: rgb(161 98 7 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/75 {
  --tw-shadow-color: rgb(161 98 7 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/80 {
  --tw-shadow-color: rgb(161 98 7 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/85 {
  --tw-shadow-color: rgb(161 98 7 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/90 {
  --tw-shadow-color: rgb(161 98 7 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-700\/95 {
  --tw-shadow-color: rgb(161 98 7 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800 {
  --tw-shadow-color: #854d0e !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/0 {
  --tw-shadow-color: rgb(133 77 14 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/10 {
  --tw-shadow-color: rgb(133 77 14 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/100 {
  --tw-shadow-color: rgb(133 77 14 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/15 {
  --tw-shadow-color: rgb(133 77 14 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/20 {
  --tw-shadow-color: rgb(133 77 14 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/25 {
  --tw-shadow-color: rgb(133 77 14 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/30 {
  --tw-shadow-color: rgb(133 77 14 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/35 {
  --tw-shadow-color: rgb(133 77 14 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/40 {
  --tw-shadow-color: rgb(133 77 14 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/45 {
  --tw-shadow-color: rgb(133 77 14 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/5 {
  --tw-shadow-color: rgb(133 77 14 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/50 {
  --tw-shadow-color: rgb(133 77 14 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/55 {
  --tw-shadow-color: rgb(133 77 14 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/60 {
  --tw-shadow-color: rgb(133 77 14 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/65 {
  --tw-shadow-color: rgb(133 77 14 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/70 {
  --tw-shadow-color: rgb(133 77 14 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/75 {
  --tw-shadow-color: rgb(133 77 14 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/80 {
  --tw-shadow-color: rgb(133 77 14 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/85 {
  --tw-shadow-color: rgb(133 77 14 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/90 {
  --tw-shadow-color: rgb(133 77 14 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-800\/95 {
  --tw-shadow-color: rgb(133 77 14 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900 {
  --tw-shadow-color: #713f12 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/0 {
  --tw-shadow-color: rgb(113 63 18 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/10 {
  --tw-shadow-color: rgb(113 63 18 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/100 {
  --tw-shadow-color: rgb(113 63 18 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/15 {
  --tw-shadow-color: rgb(113 63 18 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/20 {
  --tw-shadow-color: rgb(113 63 18 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/25 {
  --tw-shadow-color: rgb(113 63 18 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/30 {
  --tw-shadow-color: rgb(113 63 18 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/35 {
  --tw-shadow-color: rgb(113 63 18 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/40 {
  --tw-shadow-color: rgb(113 63 18 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/45 {
  --tw-shadow-color: rgb(113 63 18 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/5 {
  --tw-shadow-color: rgb(113 63 18 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/50 {
  --tw-shadow-color: rgb(113 63 18 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/55 {
  --tw-shadow-color: rgb(113 63 18 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/60 {
  --tw-shadow-color: rgb(113 63 18 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/65 {
  --tw-shadow-color: rgb(113 63 18 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/70 {
  --tw-shadow-color: rgb(113 63 18 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/75 {
  --tw-shadow-color: rgb(113 63 18 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/80 {
  --tw-shadow-color: rgb(113 63 18 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/85 {
  --tw-shadow-color: rgb(113 63 18 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/90 {
  --tw-shadow-color: rgb(113 63 18 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-900\/95 {
  --tw-shadow-color: rgb(113 63 18 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950 {
  --tw-shadow-color: #422006 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/0 {
  --tw-shadow-color: rgb(66 32 6 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/10 {
  --tw-shadow-color: rgb(66 32 6 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/100 {
  --tw-shadow-color: rgb(66 32 6 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/15 {
  --tw-shadow-color: rgb(66 32 6 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/20 {
  --tw-shadow-color: rgb(66 32 6 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/25 {
  --tw-shadow-color: rgb(66 32 6 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/30 {
  --tw-shadow-color: rgb(66 32 6 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/35 {
  --tw-shadow-color: rgb(66 32 6 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/40 {
  --tw-shadow-color: rgb(66 32 6 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/45 {
  --tw-shadow-color: rgb(66 32 6 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/5 {
  --tw-shadow-color: rgb(66 32 6 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/50 {
  --tw-shadow-color: rgb(66 32 6 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/55 {
  --tw-shadow-color: rgb(66 32 6 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/60 {
  --tw-shadow-color: rgb(66 32 6 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/65 {
  --tw-shadow-color: rgb(66 32 6 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/70 {
  --tw-shadow-color: rgb(66 32 6 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/75 {
  --tw-shadow-color: rgb(66 32 6 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/80 {
  --tw-shadow-color: rgb(66 32 6 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/85 {
  --tw-shadow-color: rgb(66 32 6 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/90 {
  --tw-shadow-color: rgb(66 32 6 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-yellow-950\/95 {
  --tw-shadow-color: rgb(66 32 6 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100 {
  --tw-shadow-color: #f4f4f5 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/0 {
  --tw-shadow-color: rgb(244 244 245 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/10 {
  --tw-shadow-color: rgb(244 244 245 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/100 {
  --tw-shadow-color: rgb(244 244 245 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/15 {
  --tw-shadow-color: rgb(244 244 245 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/20 {
  --tw-shadow-color: rgb(244 244 245 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/25 {
  --tw-shadow-color: rgb(244 244 245 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/30 {
  --tw-shadow-color: rgb(244 244 245 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/35 {
  --tw-shadow-color: rgb(244 244 245 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/40 {
  --tw-shadow-color: rgb(244 244 245 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/45 {
  --tw-shadow-color: rgb(244 244 245 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/5 {
  --tw-shadow-color: rgb(244 244 245 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/50 {
  --tw-shadow-color: rgb(244 244 245 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/55 {
  --tw-shadow-color: rgb(244 244 245 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/60 {
  --tw-shadow-color: rgb(244 244 245 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/65 {
  --tw-shadow-color: rgb(244 244 245 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/70 {
  --tw-shadow-color: rgb(244 244 245 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/75 {
  --tw-shadow-color: rgb(244 244 245 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/80 {
  --tw-shadow-color: rgb(244 244 245 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/85 {
  --tw-shadow-color: rgb(244 244 245 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/90 {
  --tw-shadow-color: rgb(244 244 245 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-100\/95 {
  --tw-shadow-color: rgb(244 244 245 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200 {
  --tw-shadow-color: #e4e4e7 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/0 {
  --tw-shadow-color: rgb(228 228 231 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/10 {
  --tw-shadow-color: rgb(228 228 231 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/100 {
  --tw-shadow-color: rgb(228 228 231 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/15 {
  --tw-shadow-color: rgb(228 228 231 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/20 {
  --tw-shadow-color: rgb(228 228 231 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/25 {
  --tw-shadow-color: rgb(228 228 231 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/30 {
  --tw-shadow-color: rgb(228 228 231 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/35 {
  --tw-shadow-color: rgb(228 228 231 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/40 {
  --tw-shadow-color: rgb(228 228 231 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/45 {
  --tw-shadow-color: rgb(228 228 231 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/5 {
  --tw-shadow-color: rgb(228 228 231 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/50 {
  --tw-shadow-color: rgb(228 228 231 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/55 {
  --tw-shadow-color: rgb(228 228 231 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/60 {
  --tw-shadow-color: rgb(228 228 231 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/65 {
  --tw-shadow-color: rgb(228 228 231 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/70 {
  --tw-shadow-color: rgb(228 228 231 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/75 {
  --tw-shadow-color: rgb(228 228 231 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/80 {
  --tw-shadow-color: rgb(228 228 231 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/85 {
  --tw-shadow-color: rgb(228 228 231 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/90 {
  --tw-shadow-color: rgb(228 228 231 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-200\/95 {
  --tw-shadow-color: rgb(228 228 231 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300 {
  --tw-shadow-color: #d4d4d8 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/0 {
  --tw-shadow-color: rgb(212 212 216 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/10 {
  --tw-shadow-color: rgb(212 212 216 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/100 {
  --tw-shadow-color: rgb(212 212 216 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/15 {
  --tw-shadow-color: rgb(212 212 216 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/20 {
  --tw-shadow-color: rgb(212 212 216 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/25 {
  --tw-shadow-color: rgb(212 212 216 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/30 {
  --tw-shadow-color: rgb(212 212 216 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/35 {
  --tw-shadow-color: rgb(212 212 216 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/40 {
  --tw-shadow-color: rgb(212 212 216 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/45 {
  --tw-shadow-color: rgb(212 212 216 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/5 {
  --tw-shadow-color: rgb(212 212 216 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/50 {
  --tw-shadow-color: rgb(212 212 216 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/55 {
  --tw-shadow-color: rgb(212 212 216 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/60 {
  --tw-shadow-color: rgb(212 212 216 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/65 {
  --tw-shadow-color: rgb(212 212 216 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/70 {
  --tw-shadow-color: rgb(212 212 216 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/75 {
  --tw-shadow-color: rgb(212 212 216 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/80 {
  --tw-shadow-color: rgb(212 212 216 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/85 {
  --tw-shadow-color: rgb(212 212 216 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/90 {
  --tw-shadow-color: rgb(212 212 216 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-300\/95 {
  --tw-shadow-color: rgb(212 212 216 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400 {
  --tw-shadow-color: #a1a1aa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/0 {
  --tw-shadow-color: rgb(161 161 170 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/10 {
  --tw-shadow-color: rgb(161 161 170 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/100 {
  --tw-shadow-color: rgb(161 161 170 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/15 {
  --tw-shadow-color: rgb(161 161 170 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/20 {
  --tw-shadow-color: rgb(161 161 170 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/25 {
  --tw-shadow-color: rgb(161 161 170 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/30 {
  --tw-shadow-color: rgb(161 161 170 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/35 {
  --tw-shadow-color: rgb(161 161 170 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/40 {
  --tw-shadow-color: rgb(161 161 170 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/45 {
  --tw-shadow-color: rgb(161 161 170 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/5 {
  --tw-shadow-color: rgb(161 161 170 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/50 {
  --tw-shadow-color: rgb(161 161 170 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/55 {
  --tw-shadow-color: rgb(161 161 170 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/60 {
  --tw-shadow-color: rgb(161 161 170 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/65 {
  --tw-shadow-color: rgb(161 161 170 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/70 {
  --tw-shadow-color: rgb(161 161 170 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/75 {
  --tw-shadow-color: rgb(161 161 170 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/80 {
  --tw-shadow-color: rgb(161 161 170 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/85 {
  --tw-shadow-color: rgb(161 161 170 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/90 {
  --tw-shadow-color: rgb(161 161 170 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-400\/95 {
  --tw-shadow-color: rgb(161 161 170 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50 {
  --tw-shadow-color: #fafafa !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/0 {
  --tw-shadow-color: rgb(250 250 250 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/10 {
  --tw-shadow-color: rgb(250 250 250 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/100 {
  --tw-shadow-color: rgb(250 250 250 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/15 {
  --tw-shadow-color: rgb(250 250 250 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/20 {
  --tw-shadow-color: rgb(250 250 250 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/25 {
  --tw-shadow-color: rgb(250 250 250 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/30 {
  --tw-shadow-color: rgb(250 250 250 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/35 {
  --tw-shadow-color: rgb(250 250 250 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/40 {
  --tw-shadow-color: rgb(250 250 250 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/45 {
  --tw-shadow-color: rgb(250 250 250 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/5 {
  --tw-shadow-color: rgb(250 250 250 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/50 {
  --tw-shadow-color: rgb(250 250 250 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/55 {
  --tw-shadow-color: rgb(250 250 250 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/60 {
  --tw-shadow-color: rgb(250 250 250 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/65 {
  --tw-shadow-color: rgb(250 250 250 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/70 {
  --tw-shadow-color: rgb(250 250 250 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/75 {
  --tw-shadow-color: rgb(250 250 250 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/80 {
  --tw-shadow-color: rgb(250 250 250 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/85 {
  --tw-shadow-color: rgb(250 250 250 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/90 {
  --tw-shadow-color: rgb(250 250 250 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-50\/95 {
  --tw-shadow-color: rgb(250 250 250 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500 {
  --tw-shadow-color: #71717a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/0 {
  --tw-shadow-color: rgb(113 113 122 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/10 {
  --tw-shadow-color: rgb(113 113 122 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/100 {
  --tw-shadow-color: rgb(113 113 122 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/15 {
  --tw-shadow-color: rgb(113 113 122 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/20 {
  --tw-shadow-color: rgb(113 113 122 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/25 {
  --tw-shadow-color: rgb(113 113 122 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/30 {
  --tw-shadow-color: rgb(113 113 122 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/35 {
  --tw-shadow-color: rgb(113 113 122 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/40 {
  --tw-shadow-color: rgb(113 113 122 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/45 {
  --tw-shadow-color: rgb(113 113 122 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/5 {
  --tw-shadow-color: rgb(113 113 122 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/50 {
  --tw-shadow-color: rgb(113 113 122 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/55 {
  --tw-shadow-color: rgb(113 113 122 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/60 {
  --tw-shadow-color: rgb(113 113 122 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/65 {
  --tw-shadow-color: rgb(113 113 122 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/70 {
  --tw-shadow-color: rgb(113 113 122 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/75 {
  --tw-shadow-color: rgb(113 113 122 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/80 {
  --tw-shadow-color: rgb(113 113 122 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/85 {
  --tw-shadow-color: rgb(113 113 122 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/90 {
  --tw-shadow-color: rgb(113 113 122 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-500\/95 {
  --tw-shadow-color: rgb(113 113 122 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600 {
  --tw-shadow-color: #52525b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/0 {
  --tw-shadow-color: rgb(82 82 91 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/10 {
  --tw-shadow-color: rgb(82 82 91 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/100 {
  --tw-shadow-color: rgb(82 82 91 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/15 {
  --tw-shadow-color: rgb(82 82 91 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/20 {
  --tw-shadow-color: rgb(82 82 91 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/25 {
  --tw-shadow-color: rgb(82 82 91 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/30 {
  --tw-shadow-color: rgb(82 82 91 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/35 {
  --tw-shadow-color: rgb(82 82 91 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/40 {
  --tw-shadow-color: rgb(82 82 91 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/45 {
  --tw-shadow-color: rgb(82 82 91 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/5 {
  --tw-shadow-color: rgb(82 82 91 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/50 {
  --tw-shadow-color: rgb(82 82 91 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/55 {
  --tw-shadow-color: rgb(82 82 91 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/60 {
  --tw-shadow-color: rgb(82 82 91 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/65 {
  --tw-shadow-color: rgb(82 82 91 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/70 {
  --tw-shadow-color: rgb(82 82 91 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/75 {
  --tw-shadow-color: rgb(82 82 91 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/80 {
  --tw-shadow-color: rgb(82 82 91 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/85 {
  --tw-shadow-color: rgb(82 82 91 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/90 {
  --tw-shadow-color: rgb(82 82 91 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-600\/95 {
  --tw-shadow-color: rgb(82 82 91 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700 {
  --tw-shadow-color: #3f3f46 !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/0 {
  --tw-shadow-color: rgb(63 63 70 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/10 {
  --tw-shadow-color: rgb(63 63 70 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/100 {
  --tw-shadow-color: rgb(63 63 70 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/15 {
  --tw-shadow-color: rgb(63 63 70 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/20 {
  --tw-shadow-color: rgb(63 63 70 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/25 {
  --tw-shadow-color: rgb(63 63 70 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/30 {
  --tw-shadow-color: rgb(63 63 70 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/35 {
  --tw-shadow-color: rgb(63 63 70 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/40 {
  --tw-shadow-color: rgb(63 63 70 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/45 {
  --tw-shadow-color: rgb(63 63 70 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/5 {
  --tw-shadow-color: rgb(63 63 70 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/50 {
  --tw-shadow-color: rgb(63 63 70 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/55 {
  --tw-shadow-color: rgb(63 63 70 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/60 {
  --tw-shadow-color: rgb(63 63 70 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/65 {
  --tw-shadow-color: rgb(63 63 70 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/70 {
  --tw-shadow-color: rgb(63 63 70 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/75 {
  --tw-shadow-color: rgb(63 63 70 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/80 {
  --tw-shadow-color: rgb(63 63 70 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/85 {
  --tw-shadow-color: rgb(63 63 70 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/90 {
  --tw-shadow-color: rgb(63 63 70 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-700\/95 {
  --tw-shadow-color: rgb(63 63 70 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800 {
  --tw-shadow-color: #27272a !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/0 {
  --tw-shadow-color: rgb(39 39 42 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/10 {
  --tw-shadow-color: rgb(39 39 42 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/100 {
  --tw-shadow-color: rgb(39 39 42 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/15 {
  --tw-shadow-color: rgb(39 39 42 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/20 {
  --tw-shadow-color: rgb(39 39 42 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/25 {
  --tw-shadow-color: rgb(39 39 42 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/30 {
  --tw-shadow-color: rgb(39 39 42 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/35 {
  --tw-shadow-color: rgb(39 39 42 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/40 {
  --tw-shadow-color: rgb(39 39 42 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/45 {
  --tw-shadow-color: rgb(39 39 42 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/5 {
  --tw-shadow-color: rgb(39 39 42 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/50 {
  --tw-shadow-color: rgb(39 39 42 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/55 {
  --tw-shadow-color: rgb(39 39 42 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/60 {
  --tw-shadow-color: rgb(39 39 42 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/65 {
  --tw-shadow-color: rgb(39 39 42 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/70 {
  --tw-shadow-color: rgb(39 39 42 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/75 {
  --tw-shadow-color: rgb(39 39 42 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/80 {
  --tw-shadow-color: rgb(39 39 42 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/85 {
  --tw-shadow-color: rgb(39 39 42 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/90 {
  --tw-shadow-color: rgb(39 39 42 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-800\/95 {
  --tw-shadow-color: rgb(39 39 42 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900 {
  --tw-shadow-color: #18181b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/0 {
  --tw-shadow-color: rgb(24 24 27 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/10 {
  --tw-shadow-color: rgb(24 24 27 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/100 {
  --tw-shadow-color: rgb(24 24 27 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/15 {
  --tw-shadow-color: rgb(24 24 27 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/20 {
  --tw-shadow-color: rgb(24 24 27 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/25 {
  --tw-shadow-color: rgb(24 24 27 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/30 {
  --tw-shadow-color: rgb(24 24 27 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/35 {
  --tw-shadow-color: rgb(24 24 27 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/40 {
  --tw-shadow-color: rgb(24 24 27 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/45 {
  --tw-shadow-color: rgb(24 24 27 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/5 {
  --tw-shadow-color: rgb(24 24 27 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/50 {
  --tw-shadow-color: rgb(24 24 27 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/55 {
  --tw-shadow-color: rgb(24 24 27 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/60 {
  --tw-shadow-color: rgb(24 24 27 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/65 {
  --tw-shadow-color: rgb(24 24 27 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/70 {
  --tw-shadow-color: rgb(24 24 27 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/75 {
  --tw-shadow-color: rgb(24 24 27 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/80 {
  --tw-shadow-color: rgb(24 24 27 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/85 {
  --tw-shadow-color: rgb(24 24 27 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/90 {
  --tw-shadow-color: rgb(24 24 27 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-900\/95 {
  --tw-shadow-color: rgb(24 24 27 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950 {
  --tw-shadow-color: #09090b !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/0 {
  --tw-shadow-color: rgb(9 9 11 / 0) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/10 {
  --tw-shadow-color: rgb(9 9 11 / 0.1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/100 {
  --tw-shadow-color: rgb(9 9 11 / 1) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/15 {
  --tw-shadow-color: rgb(9 9 11 / 0.15) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/20 {
  --tw-shadow-color: rgb(9 9 11 / 0.2) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/25 {
  --tw-shadow-color: rgb(9 9 11 / 0.25) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/30 {
  --tw-shadow-color: rgb(9 9 11 / 0.3) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/35 {
  --tw-shadow-color: rgb(9 9 11 / 0.35) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/40 {
  --tw-shadow-color: rgb(9 9 11 / 0.4) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/45 {
  --tw-shadow-color: rgb(9 9 11 / 0.45) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/5 {
  --tw-shadow-color: rgb(9 9 11 / 0.05) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/50 {
  --tw-shadow-color: rgb(9 9 11 / 0.5) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/55 {
  --tw-shadow-color: rgb(9 9 11 / 0.55) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/60 {
  --tw-shadow-color: rgb(9 9 11 / 0.6) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/65 {
  --tw-shadow-color: rgb(9 9 11 / 0.65) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/70 {
  --tw-shadow-color: rgb(9 9 11 / 0.7) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/75 {
  --tw-shadow-color: rgb(9 9 11 / 0.75) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/80 {
  --tw-shadow-color: rgb(9 9 11 / 0.8) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/85 {
  --tw-shadow-color: rgb(9 9 11 / 0.85) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/90 {
  --tw-shadow-color: rgb(9 9 11 / 0.9) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-shadow-zinc-950\/95 {
  --tw-shadow-color: rgb(9 9 11 / 0.95) !important;
  --tw-shadow: var(--tw-shadow-colored) !important;
}

.tw-outline-none {
  outline: 2px solid transparent !important;
  outline-offset: 2px !important;
}

.tw-outline {
  outline-style: solid !important;
}

.tw-outline-dashed {
  outline-style: dashed !important;
}

.tw-outline-dotted {
  outline-style: dotted !important;
}

.tw-outline-double {
  outline-style: double !important;
}

.tw-outline-0 {
  outline-width: 0px !important;
}

.tw-outline-1 {
  outline-width: 1px !important;
}

.tw-outline-2 {
  outline-width: 2px !important;
}

.tw-outline-4 {
  outline-width: 4px !important;
}

.tw-outline-8 {
  outline-width: 8px !important;
}

.-tw-outline-offset-0 {
  outline-offset: -0px !important;
}

.-tw-outline-offset-1 {
  outline-offset: -1px !important;
}

.-tw-outline-offset-2 {
  outline-offset: -2px !important;
}

.-tw-outline-offset-4 {
  outline-offset: -4px !important;
}

.-tw-outline-offset-8 {
  outline-offset: -8px !important;
}

.tw--outline-offset-0 {
  outline-offset: -0px !important;
}

.tw--outline-offset-1 {
  outline-offset: -1px !important;
}

.tw--outline-offset-2 {
  outline-offset: -2px !important;
}

.tw--outline-offset-4 {
  outline-offset: -4px !important;
}

.tw--outline-offset-8 {
  outline-offset: -8px !important;
}

.tw-outline-offset-0 {
  outline-offset: 0px !important;
}

.tw-outline-offset-1 {
  outline-offset: 1px !important;
}

.tw-outline-offset-2 {
  outline-offset: 2px !important;
}

.tw-outline-offset-4 {
  outline-offset: 4px !important;
}

.tw-outline-offset-8 {
  outline-offset: 8px !important;
}

.tw-outline-amber-100 {
  outline-color: #fef3c7 !important;
}

.tw-outline-amber-100\/0 {
  outline-color: rgb(254 243 199 / 0) !important;
}

.tw-outline-amber-100\/10 {
  outline-color: rgb(254 243 199 / 0.1) !important;
}

.tw-outline-amber-100\/100 {
  outline-color: rgb(254 243 199 / 1) !important;
}

.tw-outline-amber-100\/15 {
  outline-color: rgb(254 243 199 / 0.15) !important;
}

.tw-outline-amber-100\/20 {
  outline-color: rgb(254 243 199 / 0.2) !important;
}

.tw-outline-amber-100\/25 {
  outline-color: rgb(254 243 199 / 0.25) !important;
}

.tw-outline-amber-100\/30 {
  outline-color: rgb(254 243 199 / 0.3) !important;
}

.tw-outline-amber-100\/35 {
  outline-color: rgb(254 243 199 / 0.35) !important;
}

.tw-outline-amber-100\/40 {
  outline-color: rgb(254 243 199 / 0.4) !important;
}

.tw-outline-amber-100\/45 {
  outline-color: rgb(254 243 199 / 0.45) !important;
}

.tw-outline-amber-100\/5 {
  outline-color: rgb(254 243 199 / 0.05) !important;
}

.tw-outline-amber-100\/50 {
  outline-color: rgb(254 243 199 / 0.5) !important;
}

.tw-outline-amber-100\/55 {
  outline-color: rgb(254 243 199 / 0.55) !important;
}

.tw-outline-amber-100\/60 {
  outline-color: rgb(254 243 199 / 0.6) !important;
}

.tw-outline-amber-100\/65 {
  outline-color: rgb(254 243 199 / 0.65) !important;
}

.tw-outline-amber-100\/70 {
  outline-color: rgb(254 243 199 / 0.7) !important;
}

.tw-outline-amber-100\/75 {
  outline-color: rgb(254 243 199 / 0.75) !important;
}

.tw-outline-amber-100\/80 {
  outline-color: rgb(254 243 199 / 0.8) !important;
}

.tw-outline-amber-100\/85 {
  outline-color: rgb(254 243 199 / 0.85) !important;
}

.tw-outline-amber-100\/90 {
  outline-color: rgb(254 243 199 / 0.9) !important;
}

.tw-outline-amber-100\/95 {
  outline-color: rgb(254 243 199 / 0.95) !important;
}

.tw-outline-amber-200 {
  outline-color: #fde68a !important;
}

.tw-outline-amber-200\/0 {
  outline-color: rgb(253 230 138 / 0) !important;
}

.tw-outline-amber-200\/10 {
  outline-color: rgb(253 230 138 / 0.1) !important;
}

.tw-outline-amber-200\/100 {
  outline-color: rgb(253 230 138 / 1) !important;
}

.tw-outline-amber-200\/15 {
  outline-color: rgb(253 230 138 / 0.15) !important;
}

.tw-outline-amber-200\/20 {
  outline-color: rgb(253 230 138 / 0.2) !important;
}

.tw-outline-amber-200\/25 {
  outline-color: rgb(253 230 138 / 0.25) !important;
}

.tw-outline-amber-200\/30 {
  outline-color: rgb(253 230 138 / 0.3) !important;
}

.tw-outline-amber-200\/35 {
  outline-color: rgb(253 230 138 / 0.35) !important;
}

.tw-outline-amber-200\/40 {
  outline-color: rgb(253 230 138 / 0.4) !important;
}

.tw-outline-amber-200\/45 {
  outline-color: rgb(253 230 138 / 0.45) !important;
}

.tw-outline-amber-200\/5 {
  outline-color: rgb(253 230 138 / 0.05) !important;
}

.tw-outline-amber-200\/50 {
  outline-color: rgb(253 230 138 / 0.5) !important;
}

.tw-outline-amber-200\/55 {
  outline-color: rgb(253 230 138 / 0.55) !important;
}

.tw-outline-amber-200\/60 {
  outline-color: rgb(253 230 138 / 0.6) !important;
}

.tw-outline-amber-200\/65 {
  outline-color: rgb(253 230 138 / 0.65) !important;
}

.tw-outline-amber-200\/70 {
  outline-color: rgb(253 230 138 / 0.7) !important;
}

.tw-outline-amber-200\/75 {
  outline-color: rgb(253 230 138 / 0.75) !important;
}

.tw-outline-amber-200\/80 {
  outline-color: rgb(253 230 138 / 0.8) !important;
}

.tw-outline-amber-200\/85 {
  outline-color: rgb(253 230 138 / 0.85) !important;
}

.tw-outline-amber-200\/90 {
  outline-color: rgb(253 230 138 / 0.9) !important;
}

.tw-outline-amber-200\/95 {
  outline-color: rgb(253 230 138 / 0.95) !important;
}

.tw-outline-amber-300 {
  outline-color: #fcd34d !important;
}

.tw-outline-amber-300\/0 {
  outline-color: rgb(252 211 77 / 0) !important;
}

.tw-outline-amber-300\/10 {
  outline-color: rgb(252 211 77 / 0.1) !important;
}

.tw-outline-amber-300\/100 {
  outline-color: rgb(252 211 77 / 1) !important;
}

.tw-outline-amber-300\/15 {
  outline-color: rgb(252 211 77 / 0.15) !important;
}

.tw-outline-amber-300\/20 {
  outline-color: rgb(252 211 77 / 0.2) !important;
}

.tw-outline-amber-300\/25 {
  outline-color: rgb(252 211 77 / 0.25) !important;
}

.tw-outline-amber-300\/30 {
  outline-color: rgb(252 211 77 / 0.3) !important;
}

.tw-outline-amber-300\/35 {
  outline-color: rgb(252 211 77 / 0.35) !important;
}

.tw-outline-amber-300\/40 {
  outline-color: rgb(252 211 77 / 0.4) !important;
}

.tw-outline-amber-300\/45 {
  outline-color: rgb(252 211 77 / 0.45) !important;
}

.tw-outline-amber-300\/5 {
  outline-color: rgb(252 211 77 / 0.05) !important;
}

.tw-outline-amber-300\/50 {
  outline-color: rgb(252 211 77 / 0.5) !important;
}

.tw-outline-amber-300\/55 {
  outline-color: rgb(252 211 77 / 0.55) !important;
}

.tw-outline-amber-300\/60 {
  outline-color: rgb(252 211 77 / 0.6) !important;
}

.tw-outline-amber-300\/65 {
  outline-color: rgb(252 211 77 / 0.65) !important;
}

.tw-outline-amber-300\/70 {
  outline-color: rgb(252 211 77 / 0.7) !important;
}

.tw-outline-amber-300\/75 {
  outline-color: rgb(252 211 77 / 0.75) !important;
}

.tw-outline-amber-300\/80 {
  outline-color: rgb(252 211 77 / 0.8) !important;
}

.tw-outline-amber-300\/85 {
  outline-color: rgb(252 211 77 / 0.85) !important;
}

.tw-outline-amber-300\/90 {
  outline-color: rgb(252 211 77 / 0.9) !important;
}

.tw-outline-amber-300\/95 {
  outline-color: rgb(252 211 77 / 0.95) !important;
}

.tw-outline-amber-400 {
  outline-color: #fbbf24 !important;
}

.tw-outline-amber-400\/0 {
  outline-color: rgb(251 191 36 / 0) !important;
}

.tw-outline-amber-400\/10 {
  outline-color: rgb(251 191 36 / 0.1) !important;
}

.tw-outline-amber-400\/100 {
  outline-color: rgb(251 191 36 / 1) !important;
}

.tw-outline-amber-400\/15 {
  outline-color: rgb(251 191 36 / 0.15) !important;
}

.tw-outline-amber-400\/20 {
  outline-color: rgb(251 191 36 / 0.2) !important;
}

.tw-outline-amber-400\/25 {
  outline-color: rgb(251 191 36 / 0.25) !important;
}

.tw-outline-amber-400\/30 {
  outline-color: rgb(251 191 36 / 0.3) !important;
}

.tw-outline-amber-400\/35 {
  outline-color: rgb(251 191 36 / 0.35) !important;
}

.tw-outline-amber-400\/40 {
  outline-color: rgb(251 191 36 / 0.4) !important;
}

.tw-outline-amber-400\/45 {
  outline-color: rgb(251 191 36 / 0.45) !important;
}

.tw-outline-amber-400\/5 {
  outline-color: rgb(251 191 36 / 0.05) !important;
}

.tw-outline-amber-400\/50 {
  outline-color: rgb(251 191 36 / 0.5) !important;
}

.tw-outline-amber-400\/55 {
  outline-color: rgb(251 191 36 / 0.55) !important;
}

.tw-outline-amber-400\/60 {
  outline-color: rgb(251 191 36 / 0.6) !important;
}

.tw-outline-amber-400\/65 {
  outline-color: rgb(251 191 36 / 0.65) !important;
}

.tw-outline-amber-400\/70 {
  outline-color: rgb(251 191 36 / 0.7) !important;
}

.tw-outline-amber-400\/75 {
  outline-color: rgb(251 191 36 / 0.75) !important;
}

.tw-outline-amber-400\/80 {
  outline-color: rgb(251 191 36 / 0.8) !important;
}

.tw-outline-amber-400\/85 {
  outline-color: rgb(251 191 36 / 0.85) !important;
}

.tw-outline-amber-400\/90 {
  outline-color: rgb(251 191 36 / 0.9) !important;
}

.tw-outline-amber-400\/95 {
  outline-color: rgb(251 191 36 / 0.95) !important;
}

.tw-outline-amber-50 {
  outline-color: #fffbeb !important;
}

.tw-outline-amber-50\/0 {
  outline-color: rgb(255 251 235 / 0) !important;
}

.tw-outline-amber-50\/10 {
  outline-color: rgb(255 251 235 / 0.1) !important;
}

.tw-outline-amber-50\/100 {
  outline-color: rgb(255 251 235 / 1) !important;
}

.tw-outline-amber-50\/15 {
  outline-color: rgb(255 251 235 / 0.15) !important;
}

.tw-outline-amber-50\/20 {
  outline-color: rgb(255 251 235 / 0.2) !important;
}

.tw-outline-amber-50\/25 {
  outline-color: rgb(255 251 235 / 0.25) !important;
}

.tw-outline-amber-50\/30 {
  outline-color: rgb(255 251 235 / 0.3) !important;
}

.tw-outline-amber-50\/35 {
  outline-color: rgb(255 251 235 / 0.35) !important;
}

.tw-outline-amber-50\/40 {
  outline-color: rgb(255 251 235 / 0.4) !important;
}

.tw-outline-amber-50\/45 {
  outline-color: rgb(255 251 235 / 0.45) !important;
}

.tw-outline-amber-50\/5 {
  outline-color: rgb(255 251 235 / 0.05) !important;
}

.tw-outline-amber-50\/50 {
  outline-color: rgb(255 251 235 / 0.5) !important;
}

.tw-outline-amber-50\/55 {
  outline-color: rgb(255 251 235 / 0.55) !important;
}

.tw-outline-amber-50\/60 {
  outline-color: rgb(255 251 235 / 0.6) !important;
}

.tw-outline-amber-50\/65 {
  outline-color: rgb(255 251 235 / 0.65) !important;
}

.tw-outline-amber-50\/70 {
  outline-color: rgb(255 251 235 / 0.7) !important;
}

.tw-outline-amber-50\/75 {
  outline-color: rgb(255 251 235 / 0.75) !important;
}

.tw-outline-amber-50\/80 {
  outline-color: rgb(255 251 235 / 0.8) !important;
}

.tw-outline-amber-50\/85 {
  outline-color: rgb(255 251 235 / 0.85) !important;
}

.tw-outline-amber-50\/90 {
  outline-color: rgb(255 251 235 / 0.9) !important;
}

.tw-outline-amber-50\/95 {
  outline-color: rgb(255 251 235 / 0.95) !important;
}

.tw-outline-amber-500 {
  outline-color: #f59e0b !important;
}

.tw-outline-amber-500\/0 {
  outline-color: rgb(245 158 11 / 0) !important;
}

.tw-outline-amber-500\/10 {
  outline-color: rgb(245 158 11 / 0.1) !important;
}

.tw-outline-amber-500\/100 {
  outline-color: rgb(245 158 11 / 1) !important;
}

.tw-outline-amber-500\/15 {
  outline-color: rgb(245 158 11 / 0.15) !important;
}

.tw-outline-amber-500\/20 {
  outline-color: rgb(245 158 11 / 0.2) !important;
}

.tw-outline-amber-500\/25 {
  outline-color: rgb(245 158 11 / 0.25) !important;
}

.tw-outline-amber-500\/30 {
  outline-color: rgb(245 158 11 / 0.3) !important;
}

.tw-outline-amber-500\/35 {
  outline-color: rgb(245 158 11 / 0.35) !important;
}

.tw-outline-amber-500\/40 {
  outline-color: rgb(245 158 11 / 0.4) !important;
}

.tw-outline-amber-500\/45 {
  outline-color: rgb(245 158 11 / 0.45) !important;
}

.tw-outline-amber-500\/5 {
  outline-color: rgb(245 158 11 / 0.05) !important;
}

.tw-outline-amber-500\/50 {
  outline-color: rgb(245 158 11 / 0.5) !important;
}

.tw-outline-amber-500\/55 {
  outline-color: rgb(245 158 11 / 0.55) !important;
}

.tw-outline-amber-500\/60 {
  outline-color: rgb(245 158 11 / 0.6) !important;
}

.tw-outline-amber-500\/65 {
  outline-color: rgb(245 158 11 / 0.65) !important;
}

.tw-outline-amber-500\/70 {
  outline-color: rgb(245 158 11 / 0.7) !important;
}

.tw-outline-amber-500\/75 {
  outline-color: rgb(245 158 11 / 0.75) !important;
}

.tw-outline-amber-500\/80 {
  outline-color: rgb(245 158 11 / 0.8) !important;
}

.tw-outline-amber-500\/85 {
  outline-color: rgb(245 158 11 / 0.85) !important;
}

.tw-outline-amber-500\/90 {
  outline-color: rgb(245 158 11 / 0.9) !important;
}

.tw-outline-amber-500\/95 {
  outline-color: rgb(245 158 11 / 0.95) !important;
}

.tw-outline-amber-600 {
  outline-color: #d97706 !important;
}

.tw-outline-amber-600\/0 {
  outline-color: rgb(217 119 6 / 0) !important;
}

.tw-outline-amber-600\/10 {
  outline-color: rgb(217 119 6 / 0.1) !important;
}

.tw-outline-amber-600\/100 {
  outline-color: rgb(217 119 6 / 1) !important;
}

.tw-outline-amber-600\/15 {
  outline-color: rgb(217 119 6 / 0.15) !important;
}

.tw-outline-amber-600\/20 {
  outline-color: rgb(217 119 6 / 0.2) !important;
}

.tw-outline-amber-600\/25 {
  outline-color: rgb(217 119 6 / 0.25) !important;
}

.tw-outline-amber-600\/30 {
  outline-color: rgb(217 119 6 / 0.3) !important;
}

.tw-outline-amber-600\/35 {
  outline-color: rgb(217 119 6 / 0.35) !important;
}

.tw-outline-amber-600\/40 {
  outline-color: rgb(217 119 6 / 0.4) !important;
}

.tw-outline-amber-600\/45 {
  outline-color: rgb(217 119 6 / 0.45) !important;
}

.tw-outline-amber-600\/5 {
  outline-color: rgb(217 119 6 / 0.05) !important;
}

.tw-outline-amber-600\/50 {
  outline-color: rgb(217 119 6 / 0.5) !important;
}

.tw-outline-amber-600\/55 {
  outline-color: rgb(217 119 6 / 0.55) !important;
}

.tw-outline-amber-600\/60 {
  outline-color: rgb(217 119 6 / 0.6) !important;
}

.tw-outline-amber-600\/65 {
  outline-color: rgb(217 119 6 / 0.65) !important;
}

.tw-outline-amber-600\/70 {
  outline-color: rgb(217 119 6 / 0.7) !important;
}

.tw-outline-amber-600\/75 {
  outline-color: rgb(217 119 6 / 0.75) !important;
}

.tw-outline-amber-600\/80 {
  outline-color: rgb(217 119 6 / 0.8) !important;
}

.tw-outline-amber-600\/85 {
  outline-color: rgb(217 119 6 / 0.85) !important;
}

.tw-outline-amber-600\/90 {
  outline-color: rgb(217 119 6 / 0.9) !important;
}

.tw-outline-amber-600\/95 {
  outline-color: rgb(217 119 6 / 0.95) !important;
}

.tw-outline-amber-700 {
  outline-color: #b45309 !important;
}

.tw-outline-amber-700\/0 {
  outline-color: rgb(180 83 9 / 0) !important;
}

.tw-outline-amber-700\/10 {
  outline-color: rgb(180 83 9 / 0.1) !important;
}

.tw-outline-amber-700\/100 {
  outline-color: rgb(180 83 9 / 1) !important;
}

.tw-outline-amber-700\/15 {
  outline-color: rgb(180 83 9 / 0.15) !important;
}

.tw-outline-amber-700\/20 {
  outline-color: rgb(180 83 9 / 0.2) !important;
}

.tw-outline-amber-700\/25 {
  outline-color: rgb(180 83 9 / 0.25) !important;
}

.tw-outline-amber-700\/30 {
  outline-color: rgb(180 83 9 / 0.3) !important;
}

.tw-outline-amber-700\/35 {
  outline-color: rgb(180 83 9 / 0.35) !important;
}

.tw-outline-amber-700\/40 {
  outline-color: rgb(180 83 9 / 0.4) !important;
}

.tw-outline-amber-700\/45 {
  outline-color: rgb(180 83 9 / 0.45) !important;
}

.tw-outline-amber-700\/5 {
  outline-color: rgb(180 83 9 / 0.05) !important;
}

.tw-outline-amber-700\/50 {
  outline-color: rgb(180 83 9 / 0.5) !important;
}

.tw-outline-amber-700\/55 {
  outline-color: rgb(180 83 9 / 0.55) !important;
}

.tw-outline-amber-700\/60 {
  outline-color: rgb(180 83 9 / 0.6) !important;
}

.tw-outline-amber-700\/65 {
  outline-color: rgb(180 83 9 / 0.65) !important;
}

.tw-outline-amber-700\/70 {
  outline-color: rgb(180 83 9 / 0.7) !important;
}

.tw-outline-amber-700\/75 {
  outline-color: rgb(180 83 9 / 0.75) !important;
}

.tw-outline-amber-700\/80 {
  outline-color: rgb(180 83 9 / 0.8) !important;
}

.tw-outline-amber-700\/85 {
  outline-color: rgb(180 83 9 / 0.85) !important;
}

.tw-outline-amber-700\/90 {
  outline-color: rgb(180 83 9 / 0.9) !important;
}

.tw-outline-amber-700\/95 {
  outline-color: rgb(180 83 9 / 0.95) !important;
}

.tw-outline-amber-800 {
  outline-color: #92400e !important;
}

.tw-outline-amber-800\/0 {
  outline-color: rgb(146 64 14 / 0) !important;
}

.tw-outline-amber-800\/10 {
  outline-color: rgb(146 64 14 / 0.1) !important;
}

.tw-outline-amber-800\/100 {
  outline-color: rgb(146 64 14 / 1) !important;
}

.tw-outline-amber-800\/15 {
  outline-color: rgb(146 64 14 / 0.15) !important;
}

.tw-outline-amber-800\/20 {
  outline-color: rgb(146 64 14 / 0.2) !important;
}

.tw-outline-amber-800\/25 {
  outline-color: rgb(146 64 14 / 0.25) !important;
}

.tw-outline-amber-800\/30 {
  outline-color: rgb(146 64 14 / 0.3) !important;
}

.tw-outline-amber-800\/35 {
  outline-color: rgb(146 64 14 / 0.35) !important;
}

.tw-outline-amber-800\/40 {
  outline-color: rgb(146 64 14 / 0.4) !important;
}

.tw-outline-amber-800\/45 {
  outline-color: rgb(146 64 14 / 0.45) !important;
}

.tw-outline-amber-800\/5 {
  outline-color: rgb(146 64 14 / 0.05) !important;
}

.tw-outline-amber-800\/50 {
  outline-color: rgb(146 64 14 / 0.5) !important;
}

.tw-outline-amber-800\/55 {
  outline-color: rgb(146 64 14 / 0.55) !important;
}

.tw-outline-amber-800\/60 {
  outline-color: rgb(146 64 14 / 0.6) !important;
}

.tw-outline-amber-800\/65 {
  outline-color: rgb(146 64 14 / 0.65) !important;
}

.tw-outline-amber-800\/70 {
  outline-color: rgb(146 64 14 / 0.7) !important;
}

.tw-outline-amber-800\/75 {
  outline-color: rgb(146 64 14 / 0.75) !important;
}

.tw-outline-amber-800\/80 {
  outline-color: rgb(146 64 14 / 0.8) !important;
}

.tw-outline-amber-800\/85 {
  outline-color: rgb(146 64 14 / 0.85) !important;
}

.tw-outline-amber-800\/90 {
  outline-color: rgb(146 64 14 / 0.9) !important;
}

.tw-outline-amber-800\/95 {
  outline-color: rgb(146 64 14 / 0.95) !important;
}

.tw-outline-amber-900 {
  outline-color: #78350f !important;
}

.tw-outline-amber-900\/0 {
  outline-color: rgb(120 53 15 / 0) !important;
}

.tw-outline-amber-900\/10 {
  outline-color: rgb(120 53 15 / 0.1) !important;
}

.tw-outline-amber-900\/100 {
  outline-color: rgb(120 53 15 / 1) !important;
}

.tw-outline-amber-900\/15 {
  outline-color: rgb(120 53 15 / 0.15) !important;
}

.tw-outline-amber-900\/20 {
  outline-color: rgb(120 53 15 / 0.2) !important;
}

.tw-outline-amber-900\/25 {
  outline-color: rgb(120 53 15 / 0.25) !important;
}

.tw-outline-amber-900\/30 {
  outline-color: rgb(120 53 15 / 0.3) !important;
}

.tw-outline-amber-900\/35 {
  outline-color: rgb(120 53 15 / 0.35) !important;
}

.tw-outline-amber-900\/40 {
  outline-color: rgb(120 53 15 / 0.4) !important;
}

.tw-outline-amber-900\/45 {
  outline-color: rgb(120 53 15 / 0.45) !important;
}

.tw-outline-amber-900\/5 {
  outline-color: rgb(120 53 15 / 0.05) !important;
}

.tw-outline-amber-900\/50 {
  outline-color: rgb(120 53 15 / 0.5) !important;
}

.tw-outline-amber-900\/55 {
  outline-color: rgb(120 53 15 / 0.55) !important;
}

.tw-outline-amber-900\/60 {
  outline-color: rgb(120 53 15 / 0.6) !important;
}

.tw-outline-amber-900\/65 {
  outline-color: rgb(120 53 15 / 0.65) !important;
}

.tw-outline-amber-900\/70 {
  outline-color: rgb(120 53 15 / 0.7) !important;
}

.tw-outline-amber-900\/75 {
  outline-color: rgb(120 53 15 / 0.75) !important;
}

.tw-outline-amber-900\/80 {
  outline-color: rgb(120 53 15 / 0.8) !important;
}

.tw-outline-amber-900\/85 {
  outline-color: rgb(120 53 15 / 0.85) !important;
}

.tw-outline-amber-900\/90 {
  outline-color: rgb(120 53 15 / 0.9) !important;
}

.tw-outline-amber-900\/95 {
  outline-color: rgb(120 53 15 / 0.95) !important;
}

.tw-outline-amber-950 {
  outline-color: #451a03 !important;
}

.tw-outline-amber-950\/0 {
  outline-color: rgb(69 26 3 / 0) !important;
}

.tw-outline-amber-950\/10 {
  outline-color: rgb(69 26 3 / 0.1) !important;
}

.tw-outline-amber-950\/100 {
  outline-color: rgb(69 26 3 / 1) !important;
}

.tw-outline-amber-950\/15 {
  outline-color: rgb(69 26 3 / 0.15) !important;
}

.tw-outline-amber-950\/20 {
  outline-color: rgb(69 26 3 / 0.2) !important;
}

.tw-outline-amber-950\/25 {
  outline-color: rgb(69 26 3 / 0.25) !important;
}

.tw-outline-amber-950\/30 {
  outline-color: rgb(69 26 3 / 0.3) !important;
}

.tw-outline-amber-950\/35 {
  outline-color: rgb(69 26 3 / 0.35) !important;
}

.tw-outline-amber-950\/40 {
  outline-color: rgb(69 26 3 / 0.4) !important;
}

.tw-outline-amber-950\/45 {
  outline-color: rgb(69 26 3 / 0.45) !important;
}

.tw-outline-amber-950\/5 {
  outline-color: rgb(69 26 3 / 0.05) !important;
}

.tw-outline-amber-950\/50 {
  outline-color: rgb(69 26 3 / 0.5) !important;
}

.tw-outline-amber-950\/55 {
  outline-color: rgb(69 26 3 / 0.55) !important;
}

.tw-outline-amber-950\/60 {
  outline-color: rgb(69 26 3 / 0.6) !important;
}

.tw-outline-amber-950\/65 {
  outline-color: rgb(69 26 3 / 0.65) !important;
}

.tw-outline-amber-950\/70 {
  outline-color: rgb(69 26 3 / 0.7) !important;
}

.tw-outline-amber-950\/75 {
  outline-color: rgb(69 26 3 / 0.75) !important;
}

.tw-outline-amber-950\/80 {
  outline-color: rgb(69 26 3 / 0.8) !important;
}

.tw-outline-amber-950\/85 {
  outline-color: rgb(69 26 3 / 0.85) !important;
}

.tw-outline-amber-950\/90 {
  outline-color: rgb(69 26 3 / 0.9) !important;
}

.tw-outline-amber-950\/95 {
  outline-color: rgb(69 26 3 / 0.95) !important;
}

.tw-outline-black {
  outline-color: #000 !important;
}

.tw-outline-black\/0 {
  outline-color: rgb(0 0 0 / 0) !important;
}

.tw-outline-black\/10 {
  outline-color: rgb(0 0 0 / 0.1) !important;
}

.tw-outline-black\/100 {
  outline-color: rgb(0 0 0 / 1) !important;
}

.tw-outline-black\/15 {
  outline-color: rgb(0 0 0 / 0.15) !important;
}

.tw-outline-black\/20 {
  outline-color: rgb(0 0 0 / 0.2) !important;
}

.tw-outline-black\/25 {
  outline-color: rgb(0 0 0 / 0.25) !important;
}

.tw-outline-black\/30 {
  outline-color: rgb(0 0 0 / 0.3) !important;
}

.tw-outline-black\/35 {
  outline-color: rgb(0 0 0 / 0.35) !important;
}

.tw-outline-black\/40 {
  outline-color: rgb(0 0 0 / 0.4) !important;
}

.tw-outline-black\/45 {
  outline-color: rgb(0 0 0 / 0.45) !important;
}

.tw-outline-black\/5 {
  outline-color: rgb(0 0 0 / 0.05) !important;
}

.tw-outline-black\/50 {
  outline-color: rgb(0 0 0 / 0.5) !important;
}

.tw-outline-black\/55 {
  outline-color: rgb(0 0 0 / 0.55) !important;
}

.tw-outline-black\/60 {
  outline-color: rgb(0 0 0 / 0.6) !important;
}

.tw-outline-black\/65 {
  outline-color: rgb(0 0 0 / 0.65) !important;
}

.tw-outline-black\/70 {
  outline-color: rgb(0 0 0 / 0.7) !important;
}

.tw-outline-black\/75 {
  outline-color: rgb(0 0 0 / 0.75) !important;
}

.tw-outline-black\/80 {
  outline-color: rgb(0 0 0 / 0.8) !important;
}

.tw-outline-black\/85 {
  outline-color: rgb(0 0 0 / 0.85) !important;
}

.tw-outline-black\/90 {
  outline-color: rgb(0 0 0 / 0.9) !important;
}

.tw-outline-black\/95 {
  outline-color: rgb(0 0 0 / 0.95) !important;
}

.tw-outline-blue-100 {
  outline-color: #dbeafe !important;
}

.tw-outline-blue-100\/0 {
  outline-color: rgb(219 234 254 / 0) !important;
}

.tw-outline-blue-100\/10 {
  outline-color: rgb(219 234 254 / 0.1) !important;
}

.tw-outline-blue-100\/100 {
  outline-color: rgb(219 234 254 / 1) !important;
}

.tw-outline-blue-100\/15 {
  outline-color: rgb(219 234 254 / 0.15) !important;
}

.tw-outline-blue-100\/20 {
  outline-color: rgb(219 234 254 / 0.2) !important;
}

.tw-outline-blue-100\/25 {
  outline-color: rgb(219 234 254 / 0.25) !important;
}

.tw-outline-blue-100\/30 {
  outline-color: rgb(219 234 254 / 0.3) !important;
}

.tw-outline-blue-100\/35 {
  outline-color: rgb(219 234 254 / 0.35) !important;
}

.tw-outline-blue-100\/40 {
  outline-color: rgb(219 234 254 / 0.4) !important;
}

.tw-outline-blue-100\/45 {
  outline-color: rgb(219 234 254 / 0.45) !important;
}

.tw-outline-blue-100\/5 {
  outline-color: rgb(219 234 254 / 0.05) !important;
}

.tw-outline-blue-100\/50 {
  outline-color: rgb(219 234 254 / 0.5) !important;
}

.tw-outline-blue-100\/55 {
  outline-color: rgb(219 234 254 / 0.55) !important;
}

.tw-outline-blue-100\/60 {
  outline-color: rgb(219 234 254 / 0.6) !important;
}

.tw-outline-blue-100\/65 {
  outline-color: rgb(219 234 254 / 0.65) !important;
}

.tw-outline-blue-100\/70 {
  outline-color: rgb(219 234 254 / 0.7) !important;
}

.tw-outline-blue-100\/75 {
  outline-color: rgb(219 234 254 / 0.75) !important;
}

.tw-outline-blue-100\/80 {
  outline-color: rgb(219 234 254 / 0.8) !important;
}

.tw-outline-blue-100\/85 {
  outline-color: rgb(219 234 254 / 0.85) !important;
}

.tw-outline-blue-100\/90 {
  outline-color: rgb(219 234 254 / 0.9) !important;
}

.tw-outline-blue-100\/95 {
  outline-color: rgb(219 234 254 / 0.95) !important;
}

.tw-outline-blue-200 {
  outline-color: #bfdbfe !important;
}

.tw-outline-blue-200\/0 {
  outline-color: rgb(191 219 254 / 0) !important;
}

.tw-outline-blue-200\/10 {
  outline-color: rgb(191 219 254 / 0.1) !important;
}

.tw-outline-blue-200\/100 {
  outline-color: rgb(191 219 254 / 1) !important;
}

.tw-outline-blue-200\/15 {
  outline-color: rgb(191 219 254 / 0.15) !important;
}

.tw-outline-blue-200\/20 {
  outline-color: rgb(191 219 254 / 0.2) !important;
}

.tw-outline-blue-200\/25 {
  outline-color: rgb(191 219 254 / 0.25) !important;
}

.tw-outline-blue-200\/30 {
  outline-color: rgb(191 219 254 / 0.3) !important;
}

.tw-outline-blue-200\/35 {
  outline-color: rgb(191 219 254 / 0.35) !important;
}

.tw-outline-blue-200\/40 {
  outline-color: rgb(191 219 254 / 0.4) !important;
}

.tw-outline-blue-200\/45 {
  outline-color: rgb(191 219 254 / 0.45) !important;
}

.tw-outline-blue-200\/5 {
  outline-color: rgb(191 219 254 / 0.05) !important;
}

.tw-outline-blue-200\/50 {
  outline-color: rgb(191 219 254 / 0.5) !important;
}

.tw-outline-blue-200\/55 {
  outline-color: rgb(191 219 254 / 0.55) !important;
}

.tw-outline-blue-200\/60 {
  outline-color: rgb(191 219 254 / 0.6) !important;
}

.tw-outline-blue-200\/65 {
  outline-color: rgb(191 219 254 / 0.65) !important;
}

.tw-outline-blue-200\/70 {
  outline-color: rgb(191 219 254 / 0.7) !important;
}

.tw-outline-blue-200\/75 {
  outline-color: rgb(191 219 254 / 0.75) !important;
}

.tw-outline-blue-200\/80 {
  outline-color: rgb(191 219 254 / 0.8) !important;
}

.tw-outline-blue-200\/85 {
  outline-color: rgb(191 219 254 / 0.85) !important;
}

.tw-outline-blue-200\/90 {
  outline-color: rgb(191 219 254 / 0.9) !important;
}

.tw-outline-blue-200\/95 {
  outline-color: rgb(191 219 254 / 0.95) !important;
}

.tw-outline-blue-300 {
  outline-color: #93c5fd !important;
}

.tw-outline-blue-300\/0 {
  outline-color: rgb(147 197 253 / 0) !important;
}

.tw-outline-blue-300\/10 {
  outline-color: rgb(147 197 253 / 0.1) !important;
}

.tw-outline-blue-300\/100 {
  outline-color: rgb(147 197 253 / 1) !important;
}

.tw-outline-blue-300\/15 {
  outline-color: rgb(147 197 253 / 0.15) !important;
}

.tw-outline-blue-300\/20 {
  outline-color: rgb(147 197 253 / 0.2) !important;
}

.tw-outline-blue-300\/25 {
  outline-color: rgb(147 197 253 / 0.25) !important;
}

.tw-outline-blue-300\/30 {
  outline-color: rgb(147 197 253 / 0.3) !important;
}

.tw-outline-blue-300\/35 {
  outline-color: rgb(147 197 253 / 0.35) !important;
}

.tw-outline-blue-300\/40 {
  outline-color: rgb(147 197 253 / 0.4) !important;
}

.tw-outline-blue-300\/45 {
  outline-color: rgb(147 197 253 / 0.45) !important;
}

.tw-outline-blue-300\/5 {
  outline-color: rgb(147 197 253 / 0.05) !important;
}

.tw-outline-blue-300\/50 {
  outline-color: rgb(147 197 253 / 0.5) !important;
}

.tw-outline-blue-300\/55 {
  outline-color: rgb(147 197 253 / 0.55) !important;
}

.tw-outline-blue-300\/60 {
  outline-color: rgb(147 197 253 / 0.6) !important;
}

.tw-outline-blue-300\/65 {
  outline-color: rgb(147 197 253 / 0.65) !important;
}

.tw-outline-blue-300\/70 {
  outline-color: rgb(147 197 253 / 0.7) !important;
}

.tw-outline-blue-300\/75 {
  outline-color: rgb(147 197 253 / 0.75) !important;
}

.tw-outline-blue-300\/80 {
  outline-color: rgb(147 197 253 / 0.8) !important;
}

.tw-outline-blue-300\/85 {
  outline-color: rgb(147 197 253 / 0.85) !important;
}

.tw-outline-blue-300\/90 {
  outline-color: rgb(147 197 253 / 0.9) !important;
}

.tw-outline-blue-300\/95 {
  outline-color: rgb(147 197 253 / 0.95) !important;
}

.tw-outline-blue-400 {
  outline-color: #60a5fa !important;
}

.tw-outline-blue-400\/0 {
  outline-color: rgb(96 165 250 / 0) !important;
}

.tw-outline-blue-400\/10 {
  outline-color: rgb(96 165 250 / 0.1) !important;
}

.tw-outline-blue-400\/100 {
  outline-color: rgb(96 165 250 / 1) !important;
}

.tw-outline-blue-400\/15 {
  outline-color: rgb(96 165 250 / 0.15) !important;
}

.tw-outline-blue-400\/20 {
  outline-color: rgb(96 165 250 / 0.2) !important;
}

.tw-outline-blue-400\/25 {
  outline-color: rgb(96 165 250 / 0.25) !important;
}

.tw-outline-blue-400\/30 {
  outline-color: rgb(96 165 250 / 0.3) !important;
}

.tw-outline-blue-400\/35 {
  outline-color: rgb(96 165 250 / 0.35) !important;
}

.tw-outline-blue-400\/40 {
  outline-color: rgb(96 165 250 / 0.4) !important;
}

.tw-outline-blue-400\/45 {
  outline-color: rgb(96 165 250 / 0.45) !important;
}

.tw-outline-blue-400\/5 {
  outline-color: rgb(96 165 250 / 0.05) !important;
}

.tw-outline-blue-400\/50 {
  outline-color: rgb(96 165 250 / 0.5) !important;
}

.tw-outline-blue-400\/55 {
  outline-color: rgb(96 165 250 / 0.55) !important;
}

.tw-outline-blue-400\/60 {
  outline-color: rgb(96 165 250 / 0.6) !important;
}

.tw-outline-blue-400\/65 {
  outline-color: rgb(96 165 250 / 0.65) !important;
}

.tw-outline-blue-400\/70 {
  outline-color: rgb(96 165 250 / 0.7) !important;
}

.tw-outline-blue-400\/75 {
  outline-color: rgb(96 165 250 / 0.75) !important;
}

.tw-outline-blue-400\/80 {
  outline-color: rgb(96 165 250 / 0.8) !important;
}

.tw-outline-blue-400\/85 {
  outline-color: rgb(96 165 250 / 0.85) !important;
}

.tw-outline-blue-400\/90 {
  outline-color: rgb(96 165 250 / 0.9) !important;
}

.tw-outline-blue-400\/95 {
  outline-color: rgb(96 165 250 / 0.95) !important;
}

.tw-outline-blue-50 {
  outline-color: #eff6ff !important;
}

.tw-outline-blue-50\/0 {
  outline-color: rgb(239 246 255 / 0) !important;
}

.tw-outline-blue-50\/10 {
  outline-color: rgb(239 246 255 / 0.1) !important;
}

.tw-outline-blue-50\/100 {
  outline-color: rgb(239 246 255 / 1) !important;
}

.tw-outline-blue-50\/15 {
  outline-color: rgb(239 246 255 / 0.15) !important;
}

.tw-outline-blue-50\/20 {
  outline-color: rgb(239 246 255 / 0.2) !important;
}

.tw-outline-blue-50\/25 {
  outline-color: rgb(239 246 255 / 0.25) !important;
}

.tw-outline-blue-50\/30 {
  outline-color: rgb(239 246 255 / 0.3) !important;
}

.tw-outline-blue-50\/35 {
  outline-color: rgb(239 246 255 / 0.35) !important;
}

.tw-outline-blue-50\/40 {
  outline-color: rgb(239 246 255 / 0.4) !important;
}

.tw-outline-blue-50\/45 {
  outline-color: rgb(239 246 255 / 0.45) !important;
}

.tw-outline-blue-50\/5 {
  outline-color: rgb(239 246 255 / 0.05) !important;
}

.tw-outline-blue-50\/50 {
  outline-color: rgb(239 246 255 / 0.5) !important;
}

.tw-outline-blue-50\/55 {
  outline-color: rgb(239 246 255 / 0.55) !important;
}

.tw-outline-blue-50\/60 {
  outline-color: rgb(239 246 255 / 0.6) !important;
}

.tw-outline-blue-50\/65 {
  outline-color: rgb(239 246 255 / 0.65) !important;
}

.tw-outline-blue-50\/70 {
  outline-color: rgb(239 246 255 / 0.7) !important;
}

.tw-outline-blue-50\/75 {
  outline-color: rgb(239 246 255 / 0.75) !important;
}

.tw-outline-blue-50\/80 {
  outline-color: rgb(239 246 255 / 0.8) !important;
}

.tw-outline-blue-50\/85 {
  outline-color: rgb(239 246 255 / 0.85) !important;
}

.tw-outline-blue-50\/90 {
  outline-color: rgb(239 246 255 / 0.9) !important;
}

.tw-outline-blue-50\/95 {
  outline-color: rgb(239 246 255 / 0.95) !important;
}

.tw-outline-blue-500 {
  outline-color: #3b82f6 !important;
}

.tw-outline-blue-500\/0 {
  outline-color: rgb(59 130 246 / 0) !important;
}

.tw-outline-blue-500\/10 {
  outline-color: rgb(59 130 246 / 0.1) !important;
}

.tw-outline-blue-500\/100 {
  outline-color: rgb(59 130 246 / 1) !important;
}

.tw-outline-blue-500\/15 {
  outline-color: rgb(59 130 246 / 0.15) !important;
}

.tw-outline-blue-500\/20 {
  outline-color: rgb(59 130 246 / 0.2) !important;
}

.tw-outline-blue-500\/25 {
  outline-color: rgb(59 130 246 / 0.25) !important;
}

.tw-outline-blue-500\/30 {
  outline-color: rgb(59 130 246 / 0.3) !important;
}

.tw-outline-blue-500\/35 {
  outline-color: rgb(59 130 246 / 0.35) !important;
}

.tw-outline-blue-500\/40 {
  outline-color: rgb(59 130 246 / 0.4) !important;
}

.tw-outline-blue-500\/45 {
  outline-color: rgb(59 130 246 / 0.45) !important;
}

.tw-outline-blue-500\/5 {
  outline-color: rgb(59 130 246 / 0.05) !important;
}

.tw-outline-blue-500\/50 {
  outline-color: rgb(59 130 246 / 0.5) !important;
}

.tw-outline-blue-500\/55 {
  outline-color: rgb(59 130 246 / 0.55) !important;
}

.tw-outline-blue-500\/60 {
  outline-color: rgb(59 130 246 / 0.6) !important;
}

.tw-outline-blue-500\/65 {
  outline-color: rgb(59 130 246 / 0.65) !important;
}

.tw-outline-blue-500\/70 {
  outline-color: rgb(59 130 246 / 0.7) !important;
}

.tw-outline-blue-500\/75 {
  outline-color: rgb(59 130 246 / 0.75) !important;
}

.tw-outline-blue-500\/80 {
  outline-color: rgb(59 130 246 / 0.8) !important;
}

.tw-outline-blue-500\/85 {
  outline-color: rgb(59 130 246 / 0.85) !important;
}

.tw-outline-blue-500\/90 {
  outline-color: rgb(59 130 246 / 0.9) !important;
}

.tw-outline-blue-500\/95 {
  outline-color: rgb(59 130 246 / 0.95) !important;
}

.tw-outline-blue-600 {
  outline-color: #2563eb !important;
}

.tw-outline-blue-600\/0 {
  outline-color: rgb(37 99 235 / 0) !important;
}

.tw-outline-blue-600\/10 {
  outline-color: rgb(37 99 235 / 0.1) !important;
}

.tw-outline-blue-600\/100 {
  outline-color: rgb(37 99 235 / 1) !important;
}

.tw-outline-blue-600\/15 {
  outline-color: rgb(37 99 235 / 0.15) !important;
}

.tw-outline-blue-600\/20 {
  outline-color: rgb(37 99 235 / 0.2) !important;
}

.tw-outline-blue-600\/25 {
  outline-color: rgb(37 99 235 / 0.25) !important;
}

.tw-outline-blue-600\/30 {
  outline-color: rgb(37 99 235 / 0.3) !important;
}

.tw-outline-blue-600\/35 {
  outline-color: rgb(37 99 235 / 0.35) !important;
}

.tw-outline-blue-600\/40 {
  outline-color: rgb(37 99 235 / 0.4) !important;
}

.tw-outline-blue-600\/45 {
  outline-color: rgb(37 99 235 / 0.45) !important;
}

.tw-outline-blue-600\/5 {
  outline-color: rgb(37 99 235 / 0.05) !important;
}

.tw-outline-blue-600\/50 {
  outline-color: rgb(37 99 235 / 0.5) !important;
}

.tw-outline-blue-600\/55 {
  outline-color: rgb(37 99 235 / 0.55) !important;
}

.tw-outline-blue-600\/60 {
  outline-color: rgb(37 99 235 / 0.6) !important;
}

.tw-outline-blue-600\/65 {
  outline-color: rgb(37 99 235 / 0.65) !important;
}

.tw-outline-blue-600\/70 {
  outline-color: rgb(37 99 235 / 0.7) !important;
}

.tw-outline-blue-600\/75 {
  outline-color: rgb(37 99 235 / 0.75) !important;
}

.tw-outline-blue-600\/80 {
  outline-color: rgb(37 99 235 / 0.8) !important;
}

.tw-outline-blue-600\/85 {
  outline-color: rgb(37 99 235 / 0.85) !important;
}

.tw-outline-blue-600\/90 {
  outline-color: rgb(37 99 235 / 0.9) !important;
}

.tw-outline-blue-600\/95 {
  outline-color: rgb(37 99 235 / 0.95) !important;
}

.tw-outline-blue-700 {
  outline-color: #1d4ed8 !important;
}

.tw-outline-blue-700\/0 {
  outline-color: rgb(29 78 216 / 0) !important;
}

.tw-outline-blue-700\/10 {
  outline-color: rgb(29 78 216 / 0.1) !important;
}

.tw-outline-blue-700\/100 {
  outline-color: rgb(29 78 216 / 1) !important;
}

.tw-outline-blue-700\/15 {
  outline-color: rgb(29 78 216 / 0.15) !important;
}

.tw-outline-blue-700\/20 {
  outline-color: rgb(29 78 216 / 0.2) !important;
}

.tw-outline-blue-700\/25 {
  outline-color: rgb(29 78 216 / 0.25) !important;
}

.tw-outline-blue-700\/30 {
  outline-color: rgb(29 78 216 / 0.3) !important;
}

.tw-outline-blue-700\/35 {
  outline-color: rgb(29 78 216 / 0.35) !important;
}

.tw-outline-blue-700\/40 {
  outline-color: rgb(29 78 216 / 0.4) !important;
}

.tw-outline-blue-700\/45 {
  outline-color: rgb(29 78 216 / 0.45) !important;
}

.tw-outline-blue-700\/5 {
  outline-color: rgb(29 78 216 / 0.05) !important;
}

.tw-outline-blue-700\/50 {
  outline-color: rgb(29 78 216 / 0.5) !important;
}

.tw-outline-blue-700\/55 {
  outline-color: rgb(29 78 216 / 0.55) !important;
}

.tw-outline-blue-700\/60 {
  outline-color: rgb(29 78 216 / 0.6) !important;
}

.tw-outline-blue-700\/65 {
  outline-color: rgb(29 78 216 / 0.65) !important;
}

.tw-outline-blue-700\/70 {
  outline-color: rgb(29 78 216 / 0.7) !important;
}

.tw-outline-blue-700\/75 {
  outline-color: rgb(29 78 216 / 0.75) !important;
}

.tw-outline-blue-700\/80 {
  outline-color: rgb(29 78 216 / 0.8) !important;
}

.tw-outline-blue-700\/85 {
  outline-color: rgb(29 78 216 / 0.85) !important;
}

.tw-outline-blue-700\/90 {
  outline-color: rgb(29 78 216 / 0.9) !important;
}

.tw-outline-blue-700\/95 {
  outline-color: rgb(29 78 216 / 0.95) !important;
}

.tw-outline-blue-800 {
  outline-color: #1e40af !important;
}

.tw-outline-blue-800\/0 {
  outline-color: rgb(30 64 175 / 0) !important;
}

.tw-outline-blue-800\/10 {
  outline-color: rgb(30 64 175 / 0.1) !important;
}

.tw-outline-blue-800\/100 {
  outline-color: rgb(30 64 175 / 1) !important;
}

.tw-outline-blue-800\/15 {
  outline-color: rgb(30 64 175 / 0.15) !important;
}

.tw-outline-blue-800\/20 {
  outline-color: rgb(30 64 175 / 0.2) !important;
}

.tw-outline-blue-800\/25 {
  outline-color: rgb(30 64 175 / 0.25) !important;
}

.tw-outline-blue-800\/30 {
  outline-color: rgb(30 64 175 / 0.3) !important;
}

.tw-outline-blue-800\/35 {
  outline-color: rgb(30 64 175 / 0.35) !important;
}

.tw-outline-blue-800\/40 {
  outline-color: rgb(30 64 175 / 0.4) !important;
}

.tw-outline-blue-800\/45 {
  outline-color: rgb(30 64 175 / 0.45) !important;
}

.tw-outline-blue-800\/5 {
  outline-color: rgb(30 64 175 / 0.05) !important;
}

.tw-outline-blue-800\/50 {
  outline-color: rgb(30 64 175 / 0.5) !important;
}

.tw-outline-blue-800\/55 {
  outline-color: rgb(30 64 175 / 0.55) !important;
}

.tw-outline-blue-800\/60 {
  outline-color: rgb(30 64 175 / 0.6) !important;
}

.tw-outline-blue-800\/65 {
  outline-color: rgb(30 64 175 / 0.65) !important;
}

.tw-outline-blue-800\/70 {
  outline-color: rgb(30 64 175 / 0.7) !important;
}

.tw-outline-blue-800\/75 {
  outline-color: rgb(30 64 175 / 0.75) !important;
}

.tw-outline-blue-800\/80 {
  outline-color: rgb(30 64 175 / 0.8) !important;
}

.tw-outline-blue-800\/85 {
  outline-color: rgb(30 64 175 / 0.85) !important;
}

.tw-outline-blue-800\/90 {
  outline-color: rgb(30 64 175 / 0.9) !important;
}

.tw-outline-blue-800\/95 {
  outline-color: rgb(30 64 175 / 0.95) !important;
}

.tw-outline-blue-900 {
  outline-color: #1e3a8a !important;
}

.tw-outline-blue-900\/0 {
  outline-color: rgb(30 58 138 / 0) !important;
}

.tw-outline-blue-900\/10 {
  outline-color: rgb(30 58 138 / 0.1) !important;
}

.tw-outline-blue-900\/100 {
  outline-color: rgb(30 58 138 / 1) !important;
}

.tw-outline-blue-900\/15 {
  outline-color: rgb(30 58 138 / 0.15) !important;
}

.tw-outline-blue-900\/20 {
  outline-color: rgb(30 58 138 / 0.2) !important;
}

.tw-outline-blue-900\/25 {
  outline-color: rgb(30 58 138 / 0.25) !important;
}

.tw-outline-blue-900\/30 {
  outline-color: rgb(30 58 138 / 0.3) !important;
}

.tw-outline-blue-900\/35 {
  outline-color: rgb(30 58 138 / 0.35) !important;
}

.tw-outline-blue-900\/40 {
  outline-color: rgb(30 58 138 / 0.4) !important;
}

.tw-outline-blue-900\/45 {
  outline-color: rgb(30 58 138 / 0.45) !important;
}

.tw-outline-blue-900\/5 {
  outline-color: rgb(30 58 138 / 0.05) !important;
}

.tw-outline-blue-900\/50 {
  outline-color: rgb(30 58 138 / 0.5) !important;
}

.tw-outline-blue-900\/55 {
  outline-color: rgb(30 58 138 / 0.55) !important;
}

.tw-outline-blue-900\/60 {
  outline-color: rgb(30 58 138 / 0.6) !important;
}

.tw-outline-blue-900\/65 {
  outline-color: rgb(30 58 138 / 0.65) !important;
}

.tw-outline-blue-900\/70 {
  outline-color: rgb(30 58 138 / 0.7) !important;
}

.tw-outline-blue-900\/75 {
  outline-color: rgb(30 58 138 / 0.75) !important;
}

.tw-outline-blue-900\/80 {
  outline-color: rgb(30 58 138 / 0.8) !important;
}

.tw-outline-blue-900\/85 {
  outline-color: rgb(30 58 138 / 0.85) !important;
}

.tw-outline-blue-900\/90 {
  outline-color: rgb(30 58 138 / 0.9) !important;
}

.tw-outline-blue-900\/95 {
  outline-color: rgb(30 58 138 / 0.95) !important;
}

.tw-outline-blue-950 {
  outline-color: #172554 !important;
}

.tw-outline-blue-950\/0 {
  outline-color: rgb(23 37 84 / 0) !important;
}

.tw-outline-blue-950\/10 {
  outline-color: rgb(23 37 84 / 0.1) !important;
}

.tw-outline-blue-950\/100 {
  outline-color: rgb(23 37 84 / 1) !important;
}

.tw-outline-blue-950\/15 {
  outline-color: rgb(23 37 84 / 0.15) !important;
}

.tw-outline-blue-950\/20 {
  outline-color: rgb(23 37 84 / 0.2) !important;
}

.tw-outline-blue-950\/25 {
  outline-color: rgb(23 37 84 / 0.25) !important;
}

.tw-outline-blue-950\/30 {
  outline-color: rgb(23 37 84 / 0.3) !important;
}

.tw-outline-blue-950\/35 {
  outline-color: rgb(23 37 84 / 0.35) !important;
}

.tw-outline-blue-950\/40 {
  outline-color: rgb(23 37 84 / 0.4) !important;
}

.tw-outline-blue-950\/45 {
  outline-color: rgb(23 37 84 / 0.45) !important;
}

.tw-outline-blue-950\/5 {
  outline-color: rgb(23 37 84 / 0.05) !important;
}

.tw-outline-blue-950\/50 {
  outline-color: rgb(23 37 84 / 0.5) !important;
}

.tw-outline-blue-950\/55 {
  outline-color: rgb(23 37 84 / 0.55) !important;
}

.tw-outline-blue-950\/60 {
  outline-color: rgb(23 37 84 / 0.6) !important;
}

.tw-outline-blue-950\/65 {
  outline-color: rgb(23 37 84 / 0.65) !important;
}

.tw-outline-blue-950\/70 {
  outline-color: rgb(23 37 84 / 0.7) !important;
}

.tw-outline-blue-950\/75 {
  outline-color: rgb(23 37 84 / 0.75) !important;
}

.tw-outline-blue-950\/80 {
  outline-color: rgb(23 37 84 / 0.8) !important;
}

.tw-outline-blue-950\/85 {
  outline-color: rgb(23 37 84 / 0.85) !important;
}

.tw-outline-blue-950\/90 {
  outline-color: rgb(23 37 84 / 0.9) !important;
}

.tw-outline-blue-950\/95 {
  outline-color: rgb(23 37 84 / 0.95) !important;
}

.tw-outline-current {
  outline-color: currentColor !important;
}

.tw-outline-cyan-100 {
  outline-color: #cffafe !important;
}

.tw-outline-cyan-100\/0 {
  outline-color: rgb(207 250 254 / 0) !important;
}

.tw-outline-cyan-100\/10 {
  outline-color: rgb(207 250 254 / 0.1) !important;
}

.tw-outline-cyan-100\/100 {
  outline-color: rgb(207 250 254 / 1) !important;
}

.tw-outline-cyan-100\/15 {
  outline-color: rgb(207 250 254 / 0.15) !important;
}

.tw-outline-cyan-100\/20 {
  outline-color: rgb(207 250 254 / 0.2) !important;
}

.tw-outline-cyan-100\/25 {
  outline-color: rgb(207 250 254 / 0.25) !important;
}

.tw-outline-cyan-100\/30 {
  outline-color: rgb(207 250 254 / 0.3) !important;
}

.tw-outline-cyan-100\/35 {
  outline-color: rgb(207 250 254 / 0.35) !important;
}

.tw-outline-cyan-100\/40 {
  outline-color: rgb(207 250 254 / 0.4) !important;
}

.tw-outline-cyan-100\/45 {
  outline-color: rgb(207 250 254 / 0.45) !important;
}

.tw-outline-cyan-100\/5 {
  outline-color: rgb(207 250 254 / 0.05) !important;
}

.tw-outline-cyan-100\/50 {
  outline-color: rgb(207 250 254 / 0.5) !important;
}

.tw-outline-cyan-100\/55 {
  outline-color: rgb(207 250 254 / 0.55) !important;
}

.tw-outline-cyan-100\/60 {
  outline-color: rgb(207 250 254 / 0.6) !important;
}

.tw-outline-cyan-100\/65 {
  outline-color: rgb(207 250 254 / 0.65) !important;
}

.tw-outline-cyan-100\/70 {
  outline-color: rgb(207 250 254 / 0.7) !important;
}

.tw-outline-cyan-100\/75 {
  outline-color: rgb(207 250 254 / 0.75) !important;
}

.tw-outline-cyan-100\/80 {
  outline-color: rgb(207 250 254 / 0.8) !important;
}

.tw-outline-cyan-100\/85 {
  outline-color: rgb(207 250 254 / 0.85) !important;
}

.tw-outline-cyan-100\/90 {
  outline-color: rgb(207 250 254 / 0.9) !important;
}

.tw-outline-cyan-100\/95 {
  outline-color: rgb(207 250 254 / 0.95) !important;
}

.tw-outline-cyan-200 {
  outline-color: #a5f3fc !important;
}

.tw-outline-cyan-200\/0 {
  outline-color: rgb(165 243 252 / 0) !important;
}

.tw-outline-cyan-200\/10 {
  outline-color: rgb(165 243 252 / 0.1) !important;
}

.tw-outline-cyan-200\/100 {
  outline-color: rgb(165 243 252 / 1) !important;
}

.tw-outline-cyan-200\/15 {
  outline-color: rgb(165 243 252 / 0.15) !important;
}

.tw-outline-cyan-200\/20 {
  outline-color: rgb(165 243 252 / 0.2) !important;
}

.tw-outline-cyan-200\/25 {
  outline-color: rgb(165 243 252 / 0.25) !important;
}

.tw-outline-cyan-200\/30 {
  outline-color: rgb(165 243 252 / 0.3) !important;
}

.tw-outline-cyan-200\/35 {
  outline-color: rgb(165 243 252 / 0.35) !important;
}

.tw-outline-cyan-200\/40 {
  outline-color: rgb(165 243 252 / 0.4) !important;
}

.tw-outline-cyan-200\/45 {
  outline-color: rgb(165 243 252 / 0.45) !important;
}

.tw-outline-cyan-200\/5 {
  outline-color: rgb(165 243 252 / 0.05) !important;
}

.tw-outline-cyan-200\/50 {
  outline-color: rgb(165 243 252 / 0.5) !important;
}

.tw-outline-cyan-200\/55 {
  outline-color: rgb(165 243 252 / 0.55) !important;
}

.tw-outline-cyan-200\/60 {
  outline-color: rgb(165 243 252 / 0.6) !important;
}

.tw-outline-cyan-200\/65 {
  outline-color: rgb(165 243 252 / 0.65) !important;
}

.tw-outline-cyan-200\/70 {
  outline-color: rgb(165 243 252 / 0.7) !important;
}

.tw-outline-cyan-200\/75 {
  outline-color: rgb(165 243 252 / 0.75) !important;
}

.tw-outline-cyan-200\/80 {
  outline-color: rgb(165 243 252 / 0.8) !important;
}

.tw-outline-cyan-200\/85 {
  outline-color: rgb(165 243 252 / 0.85) !important;
}

.tw-outline-cyan-200\/90 {
  outline-color: rgb(165 243 252 / 0.9) !important;
}

.tw-outline-cyan-200\/95 {
  outline-color: rgb(165 243 252 / 0.95) !important;
}

.tw-outline-cyan-300 {
  outline-color: #67e8f9 !important;
}

.tw-outline-cyan-300\/0 {
  outline-color: rgb(103 232 249 / 0) !important;
}

.tw-outline-cyan-300\/10 {
  outline-color: rgb(103 232 249 / 0.1) !important;
}

.tw-outline-cyan-300\/100 {
  outline-color: rgb(103 232 249 / 1) !important;
}

.tw-outline-cyan-300\/15 {
  outline-color: rgb(103 232 249 / 0.15) !important;
}

.tw-outline-cyan-300\/20 {
  outline-color: rgb(103 232 249 / 0.2) !important;
}

.tw-outline-cyan-300\/25 {
  outline-color: rgb(103 232 249 / 0.25) !important;
}

.tw-outline-cyan-300\/30 {
  outline-color: rgb(103 232 249 / 0.3) !important;
}

.tw-outline-cyan-300\/35 {
  outline-color: rgb(103 232 249 / 0.35) !important;
}

.tw-outline-cyan-300\/40 {
  outline-color: rgb(103 232 249 / 0.4) !important;
}

.tw-outline-cyan-300\/45 {
  outline-color: rgb(103 232 249 / 0.45) !important;
}

.tw-outline-cyan-300\/5 {
  outline-color: rgb(103 232 249 / 0.05) !important;
}

.tw-outline-cyan-300\/50 {
  outline-color: rgb(103 232 249 / 0.5) !important;
}

.tw-outline-cyan-300\/55 {
  outline-color: rgb(103 232 249 / 0.55) !important;
}

.tw-outline-cyan-300\/60 {
  outline-color: rgb(103 232 249 / 0.6) !important;
}

.tw-outline-cyan-300\/65 {
  outline-color: rgb(103 232 249 / 0.65) !important;
}

.tw-outline-cyan-300\/70 {
  outline-color: rgb(103 232 249 / 0.7) !important;
}

.tw-outline-cyan-300\/75 {
  outline-color: rgb(103 232 249 / 0.75) !important;
}

.tw-outline-cyan-300\/80 {
  outline-color: rgb(103 232 249 / 0.8) !important;
}

.tw-outline-cyan-300\/85 {
  outline-color: rgb(103 232 249 / 0.85) !important;
}

.tw-outline-cyan-300\/90 {
  outline-color: rgb(103 232 249 / 0.9) !important;
}

.tw-outline-cyan-300\/95 {
  outline-color: rgb(103 232 249 / 0.95) !important;
}

.tw-outline-cyan-400 {
  outline-color: #22d3ee !important;
}

.tw-outline-cyan-400\/0 {
  outline-color: rgb(34 211 238 / 0) !important;
}

.tw-outline-cyan-400\/10 {
  outline-color: rgb(34 211 238 / 0.1) !important;
}

.tw-outline-cyan-400\/100 {
  outline-color: rgb(34 211 238 / 1) !important;
}

.tw-outline-cyan-400\/15 {
  outline-color: rgb(34 211 238 / 0.15) !important;
}

.tw-outline-cyan-400\/20 {
  outline-color: rgb(34 211 238 / 0.2) !important;
}

.tw-outline-cyan-400\/25 {
  outline-color: rgb(34 211 238 / 0.25) !important;
}

.tw-outline-cyan-400\/30 {
  outline-color: rgb(34 211 238 / 0.3) !important;
}

.tw-outline-cyan-400\/35 {
  outline-color: rgb(34 211 238 / 0.35) !important;
}

.tw-outline-cyan-400\/40 {
  outline-color: rgb(34 211 238 / 0.4) !important;
}

.tw-outline-cyan-400\/45 {
  outline-color: rgb(34 211 238 / 0.45) !important;
}

.tw-outline-cyan-400\/5 {
  outline-color: rgb(34 211 238 / 0.05) !important;
}

.tw-outline-cyan-400\/50 {
  outline-color: rgb(34 211 238 / 0.5) !important;
}

.tw-outline-cyan-400\/55 {
  outline-color: rgb(34 211 238 / 0.55) !important;
}

.tw-outline-cyan-400\/60 {
  outline-color: rgb(34 211 238 / 0.6) !important;
}

.tw-outline-cyan-400\/65 {
  outline-color: rgb(34 211 238 / 0.65) !important;
}

.tw-outline-cyan-400\/70 {
  outline-color: rgb(34 211 238 / 0.7) !important;
}

.tw-outline-cyan-400\/75 {
  outline-color: rgb(34 211 238 / 0.75) !important;
}

.tw-outline-cyan-400\/80 {
  outline-color: rgb(34 211 238 / 0.8) !important;
}

.tw-outline-cyan-400\/85 {
  outline-color: rgb(34 211 238 / 0.85) !important;
}

.tw-outline-cyan-400\/90 {
  outline-color: rgb(34 211 238 / 0.9) !important;
}

.tw-outline-cyan-400\/95 {
  outline-color: rgb(34 211 238 / 0.95) !important;
}

.tw-outline-cyan-50 {
  outline-color: #ecfeff !important;
}

.tw-outline-cyan-50\/0 {
  outline-color: rgb(236 254 255 / 0) !important;
}

.tw-outline-cyan-50\/10 {
  outline-color: rgb(236 254 255 / 0.1) !important;
}

.tw-outline-cyan-50\/100 {
  outline-color: rgb(236 254 255 / 1) !important;
}

.tw-outline-cyan-50\/15 {
  outline-color: rgb(236 254 255 / 0.15) !important;
}

.tw-outline-cyan-50\/20 {
  outline-color: rgb(236 254 255 / 0.2) !important;
}

.tw-outline-cyan-50\/25 {
  outline-color: rgb(236 254 255 / 0.25) !important;
}

.tw-outline-cyan-50\/30 {
  outline-color: rgb(236 254 255 / 0.3) !important;
}

.tw-outline-cyan-50\/35 {
  outline-color: rgb(236 254 255 / 0.35) !important;
}

.tw-outline-cyan-50\/40 {
  outline-color: rgb(236 254 255 / 0.4) !important;
}

.tw-outline-cyan-50\/45 {
  outline-color: rgb(236 254 255 / 0.45) !important;
}

.tw-outline-cyan-50\/5 {
  outline-color: rgb(236 254 255 / 0.05) !important;
}

.tw-outline-cyan-50\/50 {
  outline-color: rgb(236 254 255 / 0.5) !important;
}

.tw-outline-cyan-50\/55 {
  outline-color: rgb(236 254 255 / 0.55) !important;
}

.tw-outline-cyan-50\/60 {
  outline-color: rgb(236 254 255 / 0.6) !important;
}

.tw-outline-cyan-50\/65 {
  outline-color: rgb(236 254 255 / 0.65) !important;
}

.tw-outline-cyan-50\/70 {
  outline-color: rgb(236 254 255 / 0.7) !important;
}

.tw-outline-cyan-50\/75 {
  outline-color: rgb(236 254 255 / 0.75) !important;
}

.tw-outline-cyan-50\/80 {
  outline-color: rgb(236 254 255 / 0.8) !important;
}

.tw-outline-cyan-50\/85 {
  outline-color: rgb(236 254 255 / 0.85) !important;
}

.tw-outline-cyan-50\/90 {
  outline-color: rgb(236 254 255 / 0.9) !important;
}

.tw-outline-cyan-50\/95 {
  outline-color: rgb(236 254 255 / 0.95) !important;
}

.tw-outline-cyan-500 {
  outline-color: #06b6d4 !important;
}

.tw-outline-cyan-500\/0 {
  outline-color: rgb(6 182 212 / 0) !important;
}

.tw-outline-cyan-500\/10 {
  outline-color: rgb(6 182 212 / 0.1) !important;
}

.tw-outline-cyan-500\/100 {
  outline-color: rgb(6 182 212 / 1) !important;
}

.tw-outline-cyan-500\/15 {
  outline-color: rgb(6 182 212 / 0.15) !important;
}

.tw-outline-cyan-500\/20 {
  outline-color: rgb(6 182 212 / 0.2) !important;
}

.tw-outline-cyan-500\/25 {
  outline-color: rgb(6 182 212 / 0.25) !important;
}

.tw-outline-cyan-500\/30 {
  outline-color: rgb(6 182 212 / 0.3) !important;
}

.tw-outline-cyan-500\/35 {
  outline-color: rgb(6 182 212 / 0.35) !important;
}

.tw-outline-cyan-500\/40 {
  outline-color: rgb(6 182 212 / 0.4) !important;
}

.tw-outline-cyan-500\/45 {
  outline-color: rgb(6 182 212 / 0.45) !important;
}

.tw-outline-cyan-500\/5 {
  outline-color: rgb(6 182 212 / 0.05) !important;
}

.tw-outline-cyan-500\/50 {
  outline-color: rgb(6 182 212 / 0.5) !important;
}

.tw-outline-cyan-500\/55 {
  outline-color: rgb(6 182 212 / 0.55) !important;
}

.tw-outline-cyan-500\/60 {
  outline-color: rgb(6 182 212 / 0.6) !important;
}

.tw-outline-cyan-500\/65 {
  outline-color: rgb(6 182 212 / 0.65) !important;
}

.tw-outline-cyan-500\/70 {
  outline-color: rgb(6 182 212 / 0.7) !important;
}

.tw-outline-cyan-500\/75 {
  outline-color: rgb(6 182 212 / 0.75) !important;
}

.tw-outline-cyan-500\/80 {
  outline-color: rgb(6 182 212 / 0.8) !important;
}

.tw-outline-cyan-500\/85 {
  outline-color: rgb(6 182 212 / 0.85) !important;
}

.tw-outline-cyan-500\/90 {
  outline-color: rgb(6 182 212 / 0.9) !important;
}

.tw-outline-cyan-500\/95 {
  outline-color: rgb(6 182 212 / 0.95) !important;
}

.tw-outline-cyan-600 {
  outline-color: #0891b2 !important;
}

.tw-outline-cyan-600\/0 {
  outline-color: rgb(8 145 178 / 0) !important;
}

.tw-outline-cyan-600\/10 {
  outline-color: rgb(8 145 178 / 0.1) !important;
}

.tw-outline-cyan-600\/100 {
  outline-color: rgb(8 145 178 / 1) !important;
}

.tw-outline-cyan-600\/15 {
  outline-color: rgb(8 145 178 / 0.15) !important;
}

.tw-outline-cyan-600\/20 {
  outline-color: rgb(8 145 178 / 0.2) !important;
}

.tw-outline-cyan-600\/25 {
  outline-color: rgb(8 145 178 / 0.25) !important;
}

.tw-outline-cyan-600\/30 {
  outline-color: rgb(8 145 178 / 0.3) !important;
}

.tw-outline-cyan-600\/35 {
  outline-color: rgb(8 145 178 / 0.35) !important;
}

.tw-outline-cyan-600\/40 {
  outline-color: rgb(8 145 178 / 0.4) !important;
}

.tw-outline-cyan-600\/45 {
  outline-color: rgb(8 145 178 / 0.45) !important;
}

.tw-outline-cyan-600\/5 {
  outline-color: rgb(8 145 178 / 0.05) !important;
}

.tw-outline-cyan-600\/50 {
  outline-color: rgb(8 145 178 / 0.5) !important;
}

.tw-outline-cyan-600\/55 {
  outline-color: rgb(8 145 178 / 0.55) !important;
}

.tw-outline-cyan-600\/60 {
  outline-color: rgb(8 145 178 / 0.6) !important;
}

.tw-outline-cyan-600\/65 {
  outline-color: rgb(8 145 178 / 0.65) !important;
}

.tw-outline-cyan-600\/70 {
  outline-color: rgb(8 145 178 / 0.7) !important;
}

.tw-outline-cyan-600\/75 {
  outline-color: rgb(8 145 178 / 0.75) !important;
}

.tw-outline-cyan-600\/80 {
  outline-color: rgb(8 145 178 / 0.8) !important;
}

.tw-outline-cyan-600\/85 {
  outline-color: rgb(8 145 178 / 0.85) !important;
}

.tw-outline-cyan-600\/90 {
  outline-color: rgb(8 145 178 / 0.9) !important;
}

.tw-outline-cyan-600\/95 {
  outline-color: rgb(8 145 178 / 0.95) !important;
}

.tw-outline-cyan-700 {
  outline-color: #0e7490 !important;
}

.tw-outline-cyan-700\/0 {
  outline-color: rgb(14 116 144 / 0) !important;
}

.tw-outline-cyan-700\/10 {
  outline-color: rgb(14 116 144 / 0.1) !important;
}

.tw-outline-cyan-700\/100 {
  outline-color: rgb(14 116 144 / 1) !important;
}

.tw-outline-cyan-700\/15 {
  outline-color: rgb(14 116 144 / 0.15) !important;
}

.tw-outline-cyan-700\/20 {
  outline-color: rgb(14 116 144 / 0.2) !important;
}

.tw-outline-cyan-700\/25 {
  outline-color: rgb(14 116 144 / 0.25) !important;
}

.tw-outline-cyan-700\/30 {
  outline-color: rgb(14 116 144 / 0.3) !important;
}

.tw-outline-cyan-700\/35 {
  outline-color: rgb(14 116 144 / 0.35) !important;
}

.tw-outline-cyan-700\/40 {
  outline-color: rgb(14 116 144 / 0.4) !important;
}

.tw-outline-cyan-700\/45 {
  outline-color: rgb(14 116 144 / 0.45) !important;
}

.tw-outline-cyan-700\/5 {
  outline-color: rgb(14 116 144 / 0.05) !important;
}

.tw-outline-cyan-700\/50 {
  outline-color: rgb(14 116 144 / 0.5) !important;
}

.tw-outline-cyan-700\/55 {
  outline-color: rgb(14 116 144 / 0.55) !important;
}

.tw-outline-cyan-700\/60 {
  outline-color: rgb(14 116 144 / 0.6) !important;
}

.tw-outline-cyan-700\/65 {
  outline-color: rgb(14 116 144 / 0.65) !important;
}

.tw-outline-cyan-700\/70 {
  outline-color: rgb(14 116 144 / 0.7) !important;
}

.tw-outline-cyan-700\/75 {
  outline-color: rgb(14 116 144 / 0.75) !important;
}

.tw-outline-cyan-700\/80 {
  outline-color: rgb(14 116 144 / 0.8) !important;
}

.tw-outline-cyan-700\/85 {
  outline-color: rgb(14 116 144 / 0.85) !important;
}

.tw-outline-cyan-700\/90 {
  outline-color: rgb(14 116 144 / 0.9) !important;
}

.tw-outline-cyan-700\/95 {
  outline-color: rgb(14 116 144 / 0.95) !important;
}

.tw-outline-cyan-800 {
  outline-color: #155e75 !important;
}

.tw-outline-cyan-800\/0 {
  outline-color: rgb(21 94 117 / 0) !important;
}

.tw-outline-cyan-800\/10 {
  outline-color: rgb(21 94 117 / 0.1) !important;
}

.tw-outline-cyan-800\/100 {
  outline-color: rgb(21 94 117 / 1) !important;
}

.tw-outline-cyan-800\/15 {
  outline-color: rgb(21 94 117 / 0.15) !important;
}

.tw-outline-cyan-800\/20 {
  outline-color: rgb(21 94 117 / 0.2) !important;
}

.tw-outline-cyan-800\/25 {
  outline-color: rgb(21 94 117 / 0.25) !important;
}

.tw-outline-cyan-800\/30 {
  outline-color: rgb(21 94 117 / 0.3) !important;
}

.tw-outline-cyan-800\/35 {
  outline-color: rgb(21 94 117 / 0.35) !important;
}

.tw-outline-cyan-800\/40 {
  outline-color: rgb(21 94 117 / 0.4) !important;
}

.tw-outline-cyan-800\/45 {
  outline-color: rgb(21 94 117 / 0.45) !important;
}

.tw-outline-cyan-800\/5 {
  outline-color: rgb(21 94 117 / 0.05) !important;
}

.tw-outline-cyan-800\/50 {
  outline-color: rgb(21 94 117 / 0.5) !important;
}

.tw-outline-cyan-800\/55 {
  outline-color: rgb(21 94 117 / 0.55) !important;
}

.tw-outline-cyan-800\/60 {
  outline-color: rgb(21 94 117 / 0.6) !important;
}

.tw-outline-cyan-800\/65 {
  outline-color: rgb(21 94 117 / 0.65) !important;
}

.tw-outline-cyan-800\/70 {
  outline-color: rgb(21 94 117 / 0.7) !important;
}

.tw-outline-cyan-800\/75 {
  outline-color: rgb(21 94 117 / 0.75) !important;
}

.tw-outline-cyan-800\/80 {
  outline-color: rgb(21 94 117 / 0.8) !important;
}

.tw-outline-cyan-800\/85 {
  outline-color: rgb(21 94 117 / 0.85) !important;
}

.tw-outline-cyan-800\/90 {
  outline-color: rgb(21 94 117 / 0.9) !important;
}

.tw-outline-cyan-800\/95 {
  outline-color: rgb(21 94 117 / 0.95) !important;
}

.tw-outline-cyan-900 {
  outline-color: #164e63 !important;
}

.tw-outline-cyan-900\/0 {
  outline-color: rgb(22 78 99 / 0) !important;
}

.tw-outline-cyan-900\/10 {
  outline-color: rgb(22 78 99 / 0.1) !important;
}

.tw-outline-cyan-900\/100 {
  outline-color: rgb(22 78 99 / 1) !important;
}

.tw-outline-cyan-900\/15 {
  outline-color: rgb(22 78 99 / 0.15) !important;
}

.tw-outline-cyan-900\/20 {
  outline-color: rgb(22 78 99 / 0.2) !important;
}

.tw-outline-cyan-900\/25 {
  outline-color: rgb(22 78 99 / 0.25) !important;
}

.tw-outline-cyan-900\/30 {
  outline-color: rgb(22 78 99 / 0.3) !important;
}

.tw-outline-cyan-900\/35 {
  outline-color: rgb(22 78 99 / 0.35) !important;
}

.tw-outline-cyan-900\/40 {
  outline-color: rgb(22 78 99 / 0.4) !important;
}

.tw-outline-cyan-900\/45 {
  outline-color: rgb(22 78 99 / 0.45) !important;
}

.tw-outline-cyan-900\/5 {
  outline-color: rgb(22 78 99 / 0.05) !important;
}

.tw-outline-cyan-900\/50 {
  outline-color: rgb(22 78 99 / 0.5) !important;
}

.tw-outline-cyan-900\/55 {
  outline-color: rgb(22 78 99 / 0.55) !important;
}

.tw-outline-cyan-900\/60 {
  outline-color: rgb(22 78 99 / 0.6) !important;
}

.tw-outline-cyan-900\/65 {
  outline-color: rgb(22 78 99 / 0.65) !important;
}

.tw-outline-cyan-900\/70 {
  outline-color: rgb(22 78 99 / 0.7) !important;
}

.tw-outline-cyan-900\/75 {
  outline-color: rgb(22 78 99 / 0.75) !important;
}

.tw-outline-cyan-900\/80 {
  outline-color: rgb(22 78 99 / 0.8) !important;
}

.tw-outline-cyan-900\/85 {
  outline-color: rgb(22 78 99 / 0.85) !important;
}

.tw-outline-cyan-900\/90 {
  outline-color: rgb(22 78 99 / 0.9) !important;
}

.tw-outline-cyan-900\/95 {
  outline-color: rgb(22 78 99 / 0.95) !important;
}

.tw-outline-cyan-950 {
  outline-color: #083344 !important;
}

.tw-outline-cyan-950\/0 {
  outline-color: rgb(8 51 68 / 0) !important;
}

.tw-outline-cyan-950\/10 {
  outline-color: rgb(8 51 68 / 0.1) !important;
}

.tw-outline-cyan-950\/100 {
  outline-color: rgb(8 51 68 / 1) !important;
}

.tw-outline-cyan-950\/15 {
  outline-color: rgb(8 51 68 / 0.15) !important;
}

.tw-outline-cyan-950\/20 {
  outline-color: rgb(8 51 68 / 0.2) !important;
}

.tw-outline-cyan-950\/25 {
  outline-color: rgb(8 51 68 / 0.25) !important;
}

.tw-outline-cyan-950\/30 {
  outline-color: rgb(8 51 68 / 0.3) !important;
}

.tw-outline-cyan-950\/35 {
  outline-color: rgb(8 51 68 / 0.35) !important;
}

.tw-outline-cyan-950\/40 {
  outline-color: rgb(8 51 68 / 0.4) !important;
}

.tw-outline-cyan-950\/45 {
  outline-color: rgb(8 51 68 / 0.45) !important;
}

.tw-outline-cyan-950\/5 {
  outline-color: rgb(8 51 68 / 0.05) !important;
}

.tw-outline-cyan-950\/50 {
  outline-color: rgb(8 51 68 / 0.5) !important;
}

.tw-outline-cyan-950\/55 {
  outline-color: rgb(8 51 68 / 0.55) !important;
}

.tw-outline-cyan-950\/60 {
  outline-color: rgb(8 51 68 / 0.6) !important;
}

.tw-outline-cyan-950\/65 {
  outline-color: rgb(8 51 68 / 0.65) !important;
}

.tw-outline-cyan-950\/70 {
  outline-color: rgb(8 51 68 / 0.7) !important;
}

.tw-outline-cyan-950\/75 {
  outline-color: rgb(8 51 68 / 0.75) !important;
}

.tw-outline-cyan-950\/80 {
  outline-color: rgb(8 51 68 / 0.8) !important;
}

.tw-outline-cyan-950\/85 {
  outline-color: rgb(8 51 68 / 0.85) !important;
}

.tw-outline-cyan-950\/90 {
  outline-color: rgb(8 51 68 / 0.9) !important;
}

.tw-outline-cyan-950\/95 {
  outline-color: rgb(8 51 68 / 0.95) !important;
}

.tw-outline-emerald-100 {
  outline-color: #d1fae5 !important;
}

.tw-outline-emerald-100\/0 {
  outline-color: rgb(209 250 229 / 0) !important;
}

.tw-outline-emerald-100\/10 {
  outline-color: rgb(209 250 229 / 0.1) !important;
}

.tw-outline-emerald-100\/100 {
  outline-color: rgb(209 250 229 / 1) !important;
}

.tw-outline-emerald-100\/15 {
  outline-color: rgb(209 250 229 / 0.15) !important;
}

.tw-outline-emerald-100\/20 {
  outline-color: rgb(209 250 229 / 0.2) !important;
}

.tw-outline-emerald-100\/25 {
  outline-color: rgb(209 250 229 / 0.25) !important;
}

.tw-outline-emerald-100\/30 {
  outline-color: rgb(209 250 229 / 0.3) !important;
}

.tw-outline-emerald-100\/35 {
  outline-color: rgb(209 250 229 / 0.35) !important;
}

.tw-outline-emerald-100\/40 {
  outline-color: rgb(209 250 229 / 0.4) !important;
}

.tw-outline-emerald-100\/45 {
  outline-color: rgb(209 250 229 / 0.45) !important;
}

.tw-outline-emerald-100\/5 {
  outline-color: rgb(209 250 229 / 0.05) !important;
}

.tw-outline-emerald-100\/50 {
  outline-color: rgb(209 250 229 / 0.5) !important;
}

.tw-outline-emerald-100\/55 {
  outline-color: rgb(209 250 229 / 0.55) !important;
}

.tw-outline-emerald-100\/60 {
  outline-color: rgb(209 250 229 / 0.6) !important;
}

.tw-outline-emerald-100\/65 {
  outline-color: rgb(209 250 229 / 0.65) !important;
}

.tw-outline-emerald-100\/70 {
  outline-color: rgb(209 250 229 / 0.7) !important;
}

.tw-outline-emerald-100\/75 {
  outline-color: rgb(209 250 229 / 0.75) !important;
}

.tw-outline-emerald-100\/80 {
  outline-color: rgb(209 250 229 / 0.8) !important;
}

.tw-outline-emerald-100\/85 {
  outline-color: rgb(209 250 229 / 0.85) !important;
}

.tw-outline-emerald-100\/90 {
  outline-color: rgb(209 250 229 / 0.9) !important;
}

.tw-outline-emerald-100\/95 {
  outline-color: rgb(209 250 229 / 0.95) !important;
}

.tw-outline-emerald-200 {
  outline-color: #a7f3d0 !important;
}

.tw-outline-emerald-200\/0 {
  outline-color: rgb(167 243 208 / 0) !important;
}

.tw-outline-emerald-200\/10 {
  outline-color: rgb(167 243 208 / 0.1) !important;
}

.tw-outline-emerald-200\/100 {
  outline-color: rgb(167 243 208 / 1) !important;
}

.tw-outline-emerald-200\/15 {
  outline-color: rgb(167 243 208 / 0.15) !important;
}

.tw-outline-emerald-200\/20 {
  outline-color: rgb(167 243 208 / 0.2) !important;
}

.tw-outline-emerald-200\/25 {
  outline-color: rgb(167 243 208 / 0.25) !important;
}

.tw-outline-emerald-200\/30 {
  outline-color: rgb(167 243 208 / 0.3) !important;
}

.tw-outline-emerald-200\/35 {
  outline-color: rgb(167 243 208 / 0.35) !important;
}

.tw-outline-emerald-200\/40 {
  outline-color: rgb(167 243 208 / 0.4) !important;
}

.tw-outline-emerald-200\/45 {
  outline-color: rgb(167 243 208 / 0.45) !important;
}

.tw-outline-emerald-200\/5 {
  outline-color: rgb(167 243 208 / 0.05) !important;
}

.tw-outline-emerald-200\/50 {
  outline-color: rgb(167 243 208 / 0.5) !important;
}

.tw-outline-emerald-200\/55 {
  outline-color: rgb(167 243 208 / 0.55) !important;
}

.tw-outline-emerald-200\/60 {
  outline-color: rgb(167 243 208 / 0.6) !important;
}

.tw-outline-emerald-200\/65 {
  outline-color: rgb(167 243 208 / 0.65) !important;
}

.tw-outline-emerald-200\/70 {
  outline-color: rgb(167 243 208 / 0.7) !important;
}

.tw-outline-emerald-200\/75 {
  outline-color: rgb(167 243 208 / 0.75) !important;
}

.tw-outline-emerald-200\/80 {
  outline-color: rgb(167 243 208 / 0.8) !important;
}

.tw-outline-emerald-200\/85 {
  outline-color: rgb(167 243 208 / 0.85) !important;
}

.tw-outline-emerald-200\/90 {
  outline-color: rgb(167 243 208 / 0.9) !important;
}

.tw-outline-emerald-200\/95 {
  outline-color: rgb(167 243 208 / 0.95) !important;
}

.tw-outline-emerald-300 {
  outline-color: #6ee7b7 !important;
}

.tw-outline-emerald-300\/0 {
  outline-color: rgb(110 231 183 / 0) !important;
}

.tw-outline-emerald-300\/10 {
  outline-color: rgb(110 231 183 / 0.1) !important;
}

.tw-outline-emerald-300\/100 {
  outline-color: rgb(110 231 183 / 1) !important;
}

.tw-outline-emerald-300\/15 {
  outline-color: rgb(110 231 183 / 0.15) !important;
}

.tw-outline-emerald-300\/20 {
  outline-color: rgb(110 231 183 / 0.2) !important;
}

.tw-outline-emerald-300\/25 {
  outline-color: rgb(110 231 183 / 0.25) !important;
}

.tw-outline-emerald-300\/30 {
  outline-color: rgb(110 231 183 / 0.3) !important;
}

.tw-outline-emerald-300\/35 {
  outline-color: rgb(110 231 183 / 0.35) !important;
}

.tw-outline-emerald-300\/40 {
  outline-color: rgb(110 231 183 / 0.4) !important;
}

.tw-outline-emerald-300\/45 {
  outline-color: rgb(110 231 183 / 0.45) !important;
}

.tw-outline-emerald-300\/5 {
  outline-color: rgb(110 231 183 / 0.05) !important;
}

.tw-outline-emerald-300\/50 {
  outline-color: rgb(110 231 183 / 0.5) !important;
}

.tw-outline-emerald-300\/55 {
  outline-color: rgb(110 231 183 / 0.55) !important;
}

.tw-outline-emerald-300\/60 {
  outline-color: rgb(110 231 183 / 0.6) !important;
}

.tw-outline-emerald-300\/65 {
  outline-color: rgb(110 231 183 / 0.65) !important;
}

.tw-outline-emerald-300\/70 {
  outline-color: rgb(110 231 183 / 0.7) !important;
}

.tw-outline-emerald-300\/75 {
  outline-color: rgb(110 231 183 / 0.75) !important;
}

.tw-outline-emerald-300\/80 {
  outline-color: rgb(110 231 183 / 0.8) !important;
}

.tw-outline-emerald-300\/85 {
  outline-color: rgb(110 231 183 / 0.85) !important;
}

.tw-outline-emerald-300\/90 {
  outline-color: rgb(110 231 183 / 0.9) !important;
}

.tw-outline-emerald-300\/95 {
  outline-color: rgb(110 231 183 / 0.95) !important;
}

.tw-outline-emerald-400 {
  outline-color: #34d399 !important;
}

.tw-outline-emerald-400\/0 {
  outline-color: rgb(52 211 153 / 0) !important;
}

.tw-outline-emerald-400\/10 {
  outline-color: rgb(52 211 153 / 0.1) !important;
}

.tw-outline-emerald-400\/100 {
  outline-color: rgb(52 211 153 / 1) !important;
}

.tw-outline-emerald-400\/15 {
  outline-color: rgb(52 211 153 / 0.15) !important;
}

.tw-outline-emerald-400\/20 {
  outline-color: rgb(52 211 153 / 0.2) !important;
}

.tw-outline-emerald-400\/25 {
  outline-color: rgb(52 211 153 / 0.25) !important;
}

.tw-outline-emerald-400\/30 {
  outline-color: rgb(52 211 153 / 0.3) !important;
}

.tw-outline-emerald-400\/35 {
  outline-color: rgb(52 211 153 / 0.35) !important;
}

.tw-outline-emerald-400\/40 {
  outline-color: rgb(52 211 153 / 0.4) !important;
}

.tw-outline-emerald-400\/45 {
  outline-color: rgb(52 211 153 / 0.45) !important;
}

.tw-outline-emerald-400\/5 {
  outline-color: rgb(52 211 153 / 0.05) !important;
}

.tw-outline-emerald-400\/50 {
  outline-color: rgb(52 211 153 / 0.5) !important;
}

.tw-outline-emerald-400\/55 {
  outline-color: rgb(52 211 153 / 0.55) !important;
}

.tw-outline-emerald-400\/60 {
  outline-color: rgb(52 211 153 / 0.6) !important;
}

.tw-outline-emerald-400\/65 {
  outline-color: rgb(52 211 153 / 0.65) !important;
}

.tw-outline-emerald-400\/70 {
  outline-color: rgb(52 211 153 / 0.7) !important;
}

.tw-outline-emerald-400\/75 {
  outline-color: rgb(52 211 153 / 0.75) !important;
}

.tw-outline-emerald-400\/80 {
  outline-color: rgb(52 211 153 / 0.8) !important;
}

.tw-outline-emerald-400\/85 {
  outline-color: rgb(52 211 153 / 0.85) !important;
}

.tw-outline-emerald-400\/90 {
  outline-color: rgb(52 211 153 / 0.9) !important;
}

.tw-outline-emerald-400\/95 {
  outline-color: rgb(52 211 153 / 0.95) !important;
}

.tw-outline-emerald-50 {
  outline-color: #ecfdf5 !important;
}

.tw-outline-emerald-50\/0 {
  outline-color: rgb(236 253 245 / 0) !important;
}

.tw-outline-emerald-50\/10 {
  outline-color: rgb(236 253 245 / 0.1) !important;
}

.tw-outline-emerald-50\/100 {
  outline-color: rgb(236 253 245 / 1) !important;
}

.tw-outline-emerald-50\/15 {
  outline-color: rgb(236 253 245 / 0.15) !important;
}

.tw-outline-emerald-50\/20 {
  outline-color: rgb(236 253 245 / 0.2) !important;
}

.tw-outline-emerald-50\/25 {
  outline-color: rgb(236 253 245 / 0.25) !important;
}

.tw-outline-emerald-50\/30 {
  outline-color: rgb(236 253 245 / 0.3) !important;
}

.tw-outline-emerald-50\/35 {
  outline-color: rgb(236 253 245 / 0.35) !important;
}

.tw-outline-emerald-50\/40 {
  outline-color: rgb(236 253 245 / 0.4) !important;
}

.tw-outline-emerald-50\/45 {
  outline-color: rgb(236 253 245 / 0.45) !important;
}

.tw-outline-emerald-50\/5 {
  outline-color: rgb(236 253 245 / 0.05) !important;
}

.tw-outline-emerald-50\/50 {
  outline-color: rgb(236 253 245 / 0.5) !important;
}

.tw-outline-emerald-50\/55 {
  outline-color: rgb(236 253 245 / 0.55) !important;
}

.tw-outline-emerald-50\/60 {
  outline-color: rgb(236 253 245 / 0.6) !important;
}

.tw-outline-emerald-50\/65 {
  outline-color: rgb(236 253 245 / 0.65) !important;
}

.tw-outline-emerald-50\/70 {
  outline-color: rgb(236 253 245 / 0.7) !important;
}

.tw-outline-emerald-50\/75 {
  outline-color: rgb(236 253 245 / 0.75) !important;
}

.tw-outline-emerald-50\/80 {
  outline-color: rgb(236 253 245 / 0.8) !important;
}

.tw-outline-emerald-50\/85 {
  outline-color: rgb(236 253 245 / 0.85) !important;
}

.tw-outline-emerald-50\/90 {
  outline-color: rgb(236 253 245 / 0.9) !important;
}

.tw-outline-emerald-50\/95 {
  outline-color: rgb(236 253 245 / 0.95) !important;
}

.tw-outline-emerald-500 {
  outline-color: #10b981 !important;
}

.tw-outline-emerald-500\/0 {
  outline-color: rgb(16 185 129 / 0) !important;
}

.tw-outline-emerald-500\/10 {
  outline-color: rgb(16 185 129 / 0.1) !important;
}

.tw-outline-emerald-500\/100 {
  outline-color: rgb(16 185 129 / 1) !important;
}

.tw-outline-emerald-500\/15 {
  outline-color: rgb(16 185 129 / 0.15) !important;
}

.tw-outline-emerald-500\/20 {
  outline-color: rgb(16 185 129 / 0.2) !important;
}

.tw-outline-emerald-500\/25 {
  outline-color: rgb(16 185 129 / 0.25) !important;
}

.tw-outline-emerald-500\/30 {
  outline-color: rgb(16 185 129 / 0.3) !important;
}

.tw-outline-emerald-500\/35 {
  outline-color: rgb(16 185 129 / 0.35) !important;
}

.tw-outline-emerald-500\/40 {
  outline-color: rgb(16 185 129 / 0.4) !important;
}

.tw-outline-emerald-500\/45 {
  outline-color: rgb(16 185 129 / 0.45) !important;
}

.tw-outline-emerald-500\/5 {
  outline-color: rgb(16 185 129 / 0.05) !important;
}

.tw-outline-emerald-500\/50 {
  outline-color: rgb(16 185 129 / 0.5) !important;
}

.tw-outline-emerald-500\/55 {
  outline-color: rgb(16 185 129 / 0.55) !important;
}

.tw-outline-emerald-500\/60 {
  outline-color: rgb(16 185 129 / 0.6) !important;
}

.tw-outline-emerald-500\/65 {
  outline-color: rgb(16 185 129 / 0.65) !important;
}

.tw-outline-emerald-500\/70 {
  outline-color: rgb(16 185 129 / 0.7) !important;
}

.tw-outline-emerald-500\/75 {
  outline-color: rgb(16 185 129 / 0.75) !important;
}

.tw-outline-emerald-500\/80 {
  outline-color: rgb(16 185 129 / 0.8) !important;
}

.tw-outline-emerald-500\/85 {
  outline-color: rgb(16 185 129 / 0.85) !important;
}

.tw-outline-emerald-500\/90 {
  outline-color: rgb(16 185 129 / 0.9) !important;
}

.tw-outline-emerald-500\/95 {
  outline-color: rgb(16 185 129 / 0.95) !important;
}

.tw-outline-emerald-600 {
  outline-color: #059669 !important;
}

.tw-outline-emerald-600\/0 {
  outline-color: rgb(5 150 105 / 0) !important;
}

.tw-outline-emerald-600\/10 {
  outline-color: rgb(5 150 105 / 0.1) !important;
}

.tw-outline-emerald-600\/100 {
  outline-color: rgb(5 150 105 / 1) !important;
}

.tw-outline-emerald-600\/15 {
  outline-color: rgb(5 150 105 / 0.15) !important;
}

.tw-outline-emerald-600\/20 {
  outline-color: rgb(5 150 105 / 0.2) !important;
}

.tw-outline-emerald-600\/25 {
  outline-color: rgb(5 150 105 / 0.25) !important;
}

.tw-outline-emerald-600\/30 {
  outline-color: rgb(5 150 105 / 0.3) !important;
}

.tw-outline-emerald-600\/35 {
  outline-color: rgb(5 150 105 / 0.35) !important;
}

.tw-outline-emerald-600\/40 {
  outline-color: rgb(5 150 105 / 0.4) !important;
}

.tw-outline-emerald-600\/45 {
  outline-color: rgb(5 150 105 / 0.45) !important;
}

.tw-outline-emerald-600\/5 {
  outline-color: rgb(5 150 105 / 0.05) !important;
}

.tw-outline-emerald-600\/50 {
  outline-color: rgb(5 150 105 / 0.5) !important;
}

.tw-outline-emerald-600\/55 {
  outline-color: rgb(5 150 105 / 0.55) !important;
}

.tw-outline-emerald-600\/60 {
  outline-color: rgb(5 150 105 / 0.6) !important;
}

.tw-outline-emerald-600\/65 {
  outline-color: rgb(5 150 105 / 0.65) !important;
}

.tw-outline-emerald-600\/70 {
  outline-color: rgb(5 150 105 / 0.7) !important;
}

.tw-outline-emerald-600\/75 {
  outline-color: rgb(5 150 105 / 0.75) !important;
}

.tw-outline-emerald-600\/80 {
  outline-color: rgb(5 150 105 / 0.8) !important;
}

.tw-outline-emerald-600\/85 {
  outline-color: rgb(5 150 105 / 0.85) !important;
}

.tw-outline-emerald-600\/90 {
  outline-color: rgb(5 150 105 / 0.9) !important;
}

.tw-outline-emerald-600\/95 {
  outline-color: rgb(5 150 105 / 0.95) !important;
}

.tw-outline-emerald-700 {
  outline-color: #047857 !important;
}

.tw-outline-emerald-700\/0 {
  outline-color: rgb(4 120 87 / 0) !important;
}

.tw-outline-emerald-700\/10 {
  outline-color: rgb(4 120 87 / 0.1) !important;
}

.tw-outline-emerald-700\/100 {
  outline-color: rgb(4 120 87 / 1) !important;
}

.tw-outline-emerald-700\/15 {
  outline-color: rgb(4 120 87 / 0.15) !important;
}

.tw-outline-emerald-700\/20 {
  outline-color: rgb(4 120 87 / 0.2) !important;
}

.tw-outline-emerald-700\/25 {
  outline-color: rgb(4 120 87 / 0.25) !important;
}

.tw-outline-emerald-700\/30 {
  outline-color: rgb(4 120 87 / 0.3) !important;
}

.tw-outline-emerald-700\/35 {
  outline-color: rgb(4 120 87 / 0.35) !important;
}

.tw-outline-emerald-700\/40 {
  outline-color: rgb(4 120 87 / 0.4) !important;
}

.tw-outline-emerald-700\/45 {
  outline-color: rgb(4 120 87 / 0.45) !important;
}

.tw-outline-emerald-700\/5 {
  outline-color: rgb(4 120 87 / 0.05) !important;
}

.tw-outline-emerald-700\/50 {
  outline-color: rgb(4 120 87 / 0.5) !important;
}

.tw-outline-emerald-700\/55 {
  outline-color: rgb(4 120 87 / 0.55) !important;
}

.tw-outline-emerald-700\/60 {
  outline-color: rgb(4 120 87 / 0.6) !important;
}

.tw-outline-emerald-700\/65 {
  outline-color: rgb(4 120 87 / 0.65) !important;
}

.tw-outline-emerald-700\/70 {
  outline-color: rgb(4 120 87 / 0.7) !important;
}

.tw-outline-emerald-700\/75 {
  outline-color: rgb(4 120 87 / 0.75) !important;
}

.tw-outline-emerald-700\/80 {
  outline-color: rgb(4 120 87 / 0.8) !important;
}

.tw-outline-emerald-700\/85 {
  outline-color: rgb(4 120 87 / 0.85) !important;
}

.tw-outline-emerald-700\/90 {
  outline-color: rgb(4 120 87 / 0.9) !important;
}

.tw-outline-emerald-700\/95 {
  outline-color: rgb(4 120 87 / 0.95) !important;
}

.tw-outline-emerald-800 {
  outline-color: #065f46 !important;
}

.tw-outline-emerald-800\/0 {
  outline-color: rgb(6 95 70 / 0) !important;
}

.tw-outline-emerald-800\/10 {
  outline-color: rgb(6 95 70 / 0.1) !important;
}

.tw-outline-emerald-800\/100 {
  outline-color: rgb(6 95 70 / 1) !important;
}

.tw-outline-emerald-800\/15 {
  outline-color: rgb(6 95 70 / 0.15) !important;
}

.tw-outline-emerald-800\/20 {
  outline-color: rgb(6 95 70 / 0.2) !important;
}

.tw-outline-emerald-800\/25 {
  outline-color: rgb(6 95 70 / 0.25) !important;
}

.tw-outline-emerald-800\/30 {
  outline-color: rgb(6 95 70 / 0.3) !important;
}

.tw-outline-emerald-800\/35 {
  outline-color: rgb(6 95 70 / 0.35) !important;
}

.tw-outline-emerald-800\/40 {
  outline-color: rgb(6 95 70 / 0.4) !important;
}

.tw-outline-emerald-800\/45 {
  outline-color: rgb(6 95 70 / 0.45) !important;
}

.tw-outline-emerald-800\/5 {
  outline-color: rgb(6 95 70 / 0.05) !important;
}

.tw-outline-emerald-800\/50 {
  outline-color: rgb(6 95 70 / 0.5) !important;
}

.tw-outline-emerald-800\/55 {
  outline-color: rgb(6 95 70 / 0.55) !important;
}

.tw-outline-emerald-800\/60 {
  outline-color: rgb(6 95 70 / 0.6) !important;
}

.tw-outline-emerald-800\/65 {
  outline-color: rgb(6 95 70 / 0.65) !important;
}

.tw-outline-emerald-800\/70 {
  outline-color: rgb(6 95 70 / 0.7) !important;
}

.tw-outline-emerald-800\/75 {
  outline-color: rgb(6 95 70 / 0.75) !important;
}

.tw-outline-emerald-800\/80 {
  outline-color: rgb(6 95 70 / 0.8) !important;
}

.tw-outline-emerald-800\/85 {
  outline-color: rgb(6 95 70 / 0.85) !important;
}

.tw-outline-emerald-800\/90 {
  outline-color: rgb(6 95 70 / 0.9) !important;
}

.tw-outline-emerald-800\/95 {
  outline-color: rgb(6 95 70 / 0.95) !important;
}

.tw-outline-emerald-900 {
  outline-color: #064e3b !important;
}

.tw-outline-emerald-900\/0 {
  outline-color: rgb(6 78 59 / 0) !important;
}

.tw-outline-emerald-900\/10 {
  outline-color: rgb(6 78 59 / 0.1) !important;
}

.tw-outline-emerald-900\/100 {
  outline-color: rgb(6 78 59 / 1) !important;
}

.tw-outline-emerald-900\/15 {
  outline-color: rgb(6 78 59 / 0.15) !important;
}

.tw-outline-emerald-900\/20 {
  outline-color: rgb(6 78 59 / 0.2) !important;
}

.tw-outline-emerald-900\/25 {
  outline-color: rgb(6 78 59 / 0.25) !important;
}

.tw-outline-emerald-900\/30 {
  outline-color: rgb(6 78 59 / 0.3) !important;
}

.tw-outline-emerald-900\/35 {
  outline-color: rgb(6 78 59 / 0.35) !important;
}

.tw-outline-emerald-900\/40 {
  outline-color: rgb(6 78 59 / 0.4) !important;
}

.tw-outline-emerald-900\/45 {
  outline-color: rgb(6 78 59 / 0.45) !important;
}

.tw-outline-emerald-900\/5 {
  outline-color: rgb(6 78 59 / 0.05) !important;
}

.tw-outline-emerald-900\/50 {
  outline-color: rgb(6 78 59 / 0.5) !important;
}

.tw-outline-emerald-900\/55 {
  outline-color: rgb(6 78 59 / 0.55) !important;
}

.tw-outline-emerald-900\/60 {
  outline-color: rgb(6 78 59 / 0.6) !important;
}

.tw-outline-emerald-900\/65 {
  outline-color: rgb(6 78 59 / 0.65) !important;
}

.tw-outline-emerald-900\/70 {
  outline-color: rgb(6 78 59 / 0.7) !important;
}

.tw-outline-emerald-900\/75 {
  outline-color: rgb(6 78 59 / 0.75) !important;
}

.tw-outline-emerald-900\/80 {
  outline-color: rgb(6 78 59 / 0.8) !important;
}

.tw-outline-emerald-900\/85 {
  outline-color: rgb(6 78 59 / 0.85) !important;
}

.tw-outline-emerald-900\/90 {
  outline-color: rgb(6 78 59 / 0.9) !important;
}

.tw-outline-emerald-900\/95 {
  outline-color: rgb(6 78 59 / 0.95) !important;
}

.tw-outline-emerald-950 {
  outline-color: #022c22 !important;
}

.tw-outline-emerald-950\/0 {
  outline-color: rgb(2 44 34 / 0) !important;
}

.tw-outline-emerald-950\/10 {
  outline-color: rgb(2 44 34 / 0.1) !important;
}

.tw-outline-emerald-950\/100 {
  outline-color: rgb(2 44 34 / 1) !important;
}

.tw-outline-emerald-950\/15 {
  outline-color: rgb(2 44 34 / 0.15) !important;
}

.tw-outline-emerald-950\/20 {
  outline-color: rgb(2 44 34 / 0.2) !important;
}

.tw-outline-emerald-950\/25 {
  outline-color: rgb(2 44 34 / 0.25) !important;
}

.tw-outline-emerald-950\/30 {
  outline-color: rgb(2 44 34 / 0.3) !important;
}

.tw-outline-emerald-950\/35 {
  outline-color: rgb(2 44 34 / 0.35) !important;
}

.tw-outline-emerald-950\/40 {
  outline-color: rgb(2 44 34 / 0.4) !important;
}

.tw-outline-emerald-950\/45 {
  outline-color: rgb(2 44 34 / 0.45) !important;
}

.tw-outline-emerald-950\/5 {
  outline-color: rgb(2 44 34 / 0.05) !important;
}

.tw-outline-emerald-950\/50 {
  outline-color: rgb(2 44 34 / 0.5) !important;
}

.tw-outline-emerald-950\/55 {
  outline-color: rgb(2 44 34 / 0.55) !important;
}

.tw-outline-emerald-950\/60 {
  outline-color: rgb(2 44 34 / 0.6) !important;
}

.tw-outline-emerald-950\/65 {
  outline-color: rgb(2 44 34 / 0.65) !important;
}

.tw-outline-emerald-950\/70 {
  outline-color: rgb(2 44 34 / 0.7) !important;
}

.tw-outline-emerald-950\/75 {
  outline-color: rgb(2 44 34 / 0.75) !important;
}

.tw-outline-emerald-950\/80 {
  outline-color: rgb(2 44 34 / 0.8) !important;
}

.tw-outline-emerald-950\/85 {
  outline-color: rgb(2 44 34 / 0.85) !important;
}

.tw-outline-emerald-950\/90 {
  outline-color: rgb(2 44 34 / 0.9) !important;
}

.tw-outline-emerald-950\/95 {
  outline-color: rgb(2 44 34 / 0.95) !important;
}

.tw-outline-fuchsia-100 {
  outline-color: #fae8ff !important;
}

.tw-outline-fuchsia-100\/0 {
  outline-color: rgb(250 232 255 / 0) !important;
}

.tw-outline-fuchsia-100\/10 {
  outline-color: rgb(250 232 255 / 0.1) !important;
}

.tw-outline-fuchsia-100\/100 {
  outline-color: rgb(250 232 255 / 1) !important;
}

.tw-outline-fuchsia-100\/15 {
  outline-color: rgb(250 232 255 / 0.15) !important;
}

.tw-outline-fuchsia-100\/20 {
  outline-color: rgb(250 232 255 / 0.2) !important;
}

.tw-outline-fuchsia-100\/25 {
  outline-color: rgb(250 232 255 / 0.25) !important;
}

.tw-outline-fuchsia-100\/30 {
  outline-color: rgb(250 232 255 / 0.3) !important;
}

.tw-outline-fuchsia-100\/35 {
  outline-color: rgb(250 232 255 / 0.35) !important;
}

.tw-outline-fuchsia-100\/40 {
  outline-color: rgb(250 232 255 / 0.4) !important;
}

.tw-outline-fuchsia-100\/45 {
  outline-color: rgb(250 232 255 / 0.45) !important;
}

.tw-outline-fuchsia-100\/5 {
  outline-color: rgb(250 232 255 / 0.05) !important;
}

.tw-outline-fuchsia-100\/50 {
  outline-color: rgb(250 232 255 / 0.5) !important;
}

.tw-outline-fuchsia-100\/55 {
  outline-color: rgb(250 232 255 / 0.55) !important;
}

.tw-outline-fuchsia-100\/60 {
  outline-color: rgb(250 232 255 / 0.6) !important;
}

.tw-outline-fuchsia-100\/65 {
  outline-color: rgb(250 232 255 / 0.65) !important;
}

.tw-outline-fuchsia-100\/70 {
  outline-color: rgb(250 232 255 / 0.7) !important;
}

.tw-outline-fuchsia-100\/75 {
  outline-color: rgb(250 232 255 / 0.75) !important;
}

.tw-outline-fuchsia-100\/80 {
  outline-color: rgb(250 232 255 / 0.8) !important;
}

.tw-outline-fuchsia-100\/85 {
  outline-color: rgb(250 232 255 / 0.85) !important;
}

.tw-outline-fuchsia-100\/90 {
  outline-color: rgb(250 232 255 / 0.9) !important;
}

.tw-outline-fuchsia-100\/95 {
  outline-color: rgb(250 232 255 / 0.95) !important;
}

.tw-outline-fuchsia-200 {
  outline-color: #f5d0fe !important;
}

.tw-outline-fuchsia-200\/0 {
  outline-color: rgb(245 208 254 / 0) !important;
}

.tw-outline-fuchsia-200\/10 {
  outline-color: rgb(245 208 254 / 0.1) !important;
}

.tw-outline-fuchsia-200\/100 {
  outline-color: rgb(245 208 254 / 1) !important;
}

.tw-outline-fuchsia-200\/15 {
  outline-color: rgb(245 208 254 / 0.15) !important;
}

.tw-outline-fuchsia-200\/20 {
  outline-color: rgb(245 208 254 / 0.2) !important;
}

.tw-outline-fuchsia-200\/25 {
  outline-color: rgb(245 208 254 / 0.25) !important;
}

.tw-outline-fuchsia-200\/30 {
  outline-color: rgb(245 208 254 / 0.3) !important;
}

.tw-outline-fuchsia-200\/35 {
  outline-color: rgb(245 208 254 / 0.35) !important;
}

.tw-outline-fuchsia-200\/40 {
  outline-color: rgb(245 208 254 / 0.4) !important;
}

.tw-outline-fuchsia-200\/45 {
  outline-color: rgb(245 208 254 / 0.45) !important;
}

.tw-outline-fuchsia-200\/5 {
  outline-color: rgb(245 208 254 / 0.05) !important;
}

.tw-outline-fuchsia-200\/50 {
  outline-color: rgb(245 208 254 / 0.5) !important;
}

.tw-outline-fuchsia-200\/55 {
  outline-color: rgb(245 208 254 / 0.55) !important;
}

.tw-outline-fuchsia-200\/60 {
  outline-color: rgb(245 208 254 / 0.6) !important;
}

.tw-outline-fuchsia-200\/65 {
  outline-color: rgb(245 208 254 / 0.65) !important;
}

.tw-outline-fuchsia-200\/70 {
  outline-color: rgb(245 208 254 / 0.7) !important;
}

.tw-outline-fuchsia-200\/75 {
  outline-color: rgb(245 208 254 / 0.75) !important;
}

.tw-outline-fuchsia-200\/80 {
  outline-color: rgb(245 208 254 / 0.8) !important;
}

.tw-outline-fuchsia-200\/85 {
  outline-color: rgb(245 208 254 / 0.85) !important;
}

.tw-outline-fuchsia-200\/90 {
  outline-color: rgb(245 208 254 / 0.9) !important;
}

.tw-outline-fuchsia-200\/95 {
  outline-color: rgb(245 208 254 / 0.95) !important;
}

.tw-outline-fuchsia-300 {
  outline-color: #f0abfc !important;
}

.tw-outline-fuchsia-300\/0 {
  outline-color: rgb(240 171 252 / 0) !important;
}

.tw-outline-fuchsia-300\/10 {
  outline-color: rgb(240 171 252 / 0.1) !important;
}

.tw-outline-fuchsia-300\/100 {
  outline-color: rgb(240 171 252 / 1) !important;
}

.tw-outline-fuchsia-300\/15 {
  outline-color: rgb(240 171 252 / 0.15) !important;
}

.tw-outline-fuchsia-300\/20 {
  outline-color: rgb(240 171 252 / 0.2) !important;
}

.tw-outline-fuchsia-300\/25 {
  outline-color: rgb(240 171 252 / 0.25) !important;
}

.tw-outline-fuchsia-300\/30 {
  outline-color: rgb(240 171 252 / 0.3) !important;
}

.tw-outline-fuchsia-300\/35 {
  outline-color: rgb(240 171 252 / 0.35) !important;
}

.tw-outline-fuchsia-300\/40 {
  outline-color: rgb(240 171 252 / 0.4) !important;
}

.tw-outline-fuchsia-300\/45 {
  outline-color: rgb(240 171 252 / 0.45) !important;
}

.tw-outline-fuchsia-300\/5 {
  outline-color: rgb(240 171 252 / 0.05) !important;
}

.tw-outline-fuchsia-300\/50 {
  outline-color: rgb(240 171 252 / 0.5) !important;
}

.tw-outline-fuchsia-300\/55 {
  outline-color: rgb(240 171 252 / 0.55) !important;
}

.tw-outline-fuchsia-300\/60 {
  outline-color: rgb(240 171 252 / 0.6) !important;
}

.tw-outline-fuchsia-300\/65 {
  outline-color: rgb(240 171 252 / 0.65) !important;
}

.tw-outline-fuchsia-300\/70 {
  outline-color: rgb(240 171 252 / 0.7) !important;
}

.tw-outline-fuchsia-300\/75 {
  outline-color: rgb(240 171 252 / 0.75) !important;
}

.tw-outline-fuchsia-300\/80 {
  outline-color: rgb(240 171 252 / 0.8) !important;
}

.tw-outline-fuchsia-300\/85 {
  outline-color: rgb(240 171 252 / 0.85) !important;
}

.tw-outline-fuchsia-300\/90 {
  outline-color: rgb(240 171 252 / 0.9) !important;
}

.tw-outline-fuchsia-300\/95 {
  outline-color: rgb(240 171 252 / 0.95) !important;
}

.tw-outline-fuchsia-400 {
  outline-color: #e879f9 !important;
}

.tw-outline-fuchsia-400\/0 {
  outline-color: rgb(232 121 249 / 0) !important;
}

.tw-outline-fuchsia-400\/10 {
  outline-color: rgb(232 121 249 / 0.1) !important;
}

.tw-outline-fuchsia-400\/100 {
  outline-color: rgb(232 121 249 / 1) !important;
}

.tw-outline-fuchsia-400\/15 {
  outline-color: rgb(232 121 249 / 0.15) !important;
}

.tw-outline-fuchsia-400\/20 {
  outline-color: rgb(232 121 249 / 0.2) !important;
}

.tw-outline-fuchsia-400\/25 {
  outline-color: rgb(232 121 249 / 0.25) !important;
}

.tw-outline-fuchsia-400\/30 {
  outline-color: rgb(232 121 249 / 0.3) !important;
}

.tw-outline-fuchsia-400\/35 {
  outline-color: rgb(232 121 249 / 0.35) !important;
}

.tw-outline-fuchsia-400\/40 {
  outline-color: rgb(232 121 249 / 0.4) !important;
}

.tw-outline-fuchsia-400\/45 {
  outline-color: rgb(232 121 249 / 0.45) !important;
}

.tw-outline-fuchsia-400\/5 {
  outline-color: rgb(232 121 249 / 0.05) !important;
}

.tw-outline-fuchsia-400\/50 {
  outline-color: rgb(232 121 249 / 0.5) !important;
}

.tw-outline-fuchsia-400\/55 {
  outline-color: rgb(232 121 249 / 0.55) !important;
}

.tw-outline-fuchsia-400\/60 {
  outline-color: rgb(232 121 249 / 0.6) !important;
}

.tw-outline-fuchsia-400\/65 {
  outline-color: rgb(232 121 249 / 0.65) !important;
}

.tw-outline-fuchsia-400\/70 {
  outline-color: rgb(232 121 249 / 0.7) !important;
}

.tw-outline-fuchsia-400\/75 {
  outline-color: rgb(232 121 249 / 0.75) !important;
}

.tw-outline-fuchsia-400\/80 {
  outline-color: rgb(232 121 249 / 0.8) !important;
}

.tw-outline-fuchsia-400\/85 {
  outline-color: rgb(232 121 249 / 0.85) !important;
}

.tw-outline-fuchsia-400\/90 {
  outline-color: rgb(232 121 249 / 0.9) !important;
}

.tw-outline-fuchsia-400\/95 {
  outline-color: rgb(232 121 249 / 0.95) !important;
}

.tw-outline-fuchsia-50 {
  outline-color: #fdf4ff !important;
}

.tw-outline-fuchsia-50\/0 {
  outline-color: rgb(253 244 255 / 0) !important;
}

.tw-outline-fuchsia-50\/10 {
  outline-color: rgb(253 244 255 / 0.1) !important;
}

.tw-outline-fuchsia-50\/100 {
  outline-color: rgb(253 244 255 / 1) !important;
}

.tw-outline-fuchsia-50\/15 {
  outline-color: rgb(253 244 255 / 0.15) !important;
}

.tw-outline-fuchsia-50\/20 {
  outline-color: rgb(253 244 255 / 0.2) !important;
}

.tw-outline-fuchsia-50\/25 {
  outline-color: rgb(253 244 255 / 0.25) !important;
}

.tw-outline-fuchsia-50\/30 {
  outline-color: rgb(253 244 255 / 0.3) !important;
}

.tw-outline-fuchsia-50\/35 {
  outline-color: rgb(253 244 255 / 0.35) !important;
}

.tw-outline-fuchsia-50\/40 {
  outline-color: rgb(253 244 255 / 0.4) !important;
}

.tw-outline-fuchsia-50\/45 {
  outline-color: rgb(253 244 255 / 0.45) !important;
}

.tw-outline-fuchsia-50\/5 {
  outline-color: rgb(253 244 255 / 0.05) !important;
}

.tw-outline-fuchsia-50\/50 {
  outline-color: rgb(253 244 255 / 0.5) !important;
}

.tw-outline-fuchsia-50\/55 {
  outline-color: rgb(253 244 255 / 0.55) !important;
}

.tw-outline-fuchsia-50\/60 {
  outline-color: rgb(253 244 255 / 0.6) !important;
}

.tw-outline-fuchsia-50\/65 {
  outline-color: rgb(253 244 255 / 0.65) !important;
}

.tw-outline-fuchsia-50\/70 {
  outline-color: rgb(253 244 255 / 0.7) !important;
}

.tw-outline-fuchsia-50\/75 {
  outline-color: rgb(253 244 255 / 0.75) !important;
}

.tw-outline-fuchsia-50\/80 {
  outline-color: rgb(253 244 255 / 0.8) !important;
}

.tw-outline-fuchsia-50\/85 {
  outline-color: rgb(253 244 255 / 0.85) !important;
}

.tw-outline-fuchsia-50\/90 {
  outline-color: rgb(253 244 255 / 0.9) !important;
}

.tw-outline-fuchsia-50\/95 {
  outline-color: rgb(253 244 255 / 0.95) !important;
}

.tw-outline-fuchsia-500 {
  outline-color: #d946ef !important;
}

.tw-outline-fuchsia-500\/0 {
  outline-color: rgb(217 70 239 / 0) !important;
}

.tw-outline-fuchsia-500\/10 {
  outline-color: rgb(217 70 239 / 0.1) !important;
}

.tw-outline-fuchsia-500\/100 {
  outline-color: rgb(217 70 239 / 1) !important;
}

.tw-outline-fuchsia-500\/15 {
  outline-color: rgb(217 70 239 / 0.15) !important;
}

.tw-outline-fuchsia-500\/20 {
  outline-color: rgb(217 70 239 / 0.2) !important;
}

.tw-outline-fuchsia-500\/25 {
  outline-color: rgb(217 70 239 / 0.25) !important;
}

.tw-outline-fuchsia-500\/30 {
  outline-color: rgb(217 70 239 / 0.3) !important;
}

.tw-outline-fuchsia-500\/35 {
  outline-color: rgb(217 70 239 / 0.35) !important;
}

.tw-outline-fuchsia-500\/40 {
  outline-color: rgb(217 70 239 / 0.4) !important;
}

.tw-outline-fuchsia-500\/45 {
  outline-color: rgb(217 70 239 / 0.45) !important;
}

.tw-outline-fuchsia-500\/5 {
  outline-color: rgb(217 70 239 / 0.05) !important;
}

.tw-outline-fuchsia-500\/50 {
  outline-color: rgb(217 70 239 / 0.5) !important;
}

.tw-outline-fuchsia-500\/55 {
  outline-color: rgb(217 70 239 / 0.55) !important;
}

.tw-outline-fuchsia-500\/60 {
  outline-color: rgb(217 70 239 / 0.6) !important;
}

.tw-outline-fuchsia-500\/65 {
  outline-color: rgb(217 70 239 / 0.65) !important;
}

.tw-outline-fuchsia-500\/70 {
  outline-color: rgb(217 70 239 / 0.7) !important;
}

.tw-outline-fuchsia-500\/75 {
  outline-color: rgb(217 70 239 / 0.75) !important;
}

.tw-outline-fuchsia-500\/80 {
  outline-color: rgb(217 70 239 / 0.8) !important;
}

.tw-outline-fuchsia-500\/85 {
  outline-color: rgb(217 70 239 / 0.85) !important;
}

.tw-outline-fuchsia-500\/90 {
  outline-color: rgb(217 70 239 / 0.9) !important;
}

.tw-outline-fuchsia-500\/95 {
  outline-color: rgb(217 70 239 / 0.95) !important;
}

.tw-outline-fuchsia-600 {
  outline-color: #c026d3 !important;
}

.tw-outline-fuchsia-600\/0 {
  outline-color: rgb(192 38 211 / 0) !important;
}

.tw-outline-fuchsia-600\/10 {
  outline-color: rgb(192 38 211 / 0.1) !important;
}

.tw-outline-fuchsia-600\/100 {
  outline-color: rgb(192 38 211 / 1) !important;
}

.tw-outline-fuchsia-600\/15 {
  outline-color: rgb(192 38 211 / 0.15) !important;
}

.tw-outline-fuchsia-600\/20 {
  outline-color: rgb(192 38 211 / 0.2) !important;
}

.tw-outline-fuchsia-600\/25 {
  outline-color: rgb(192 38 211 / 0.25) !important;
}

.tw-outline-fuchsia-600\/30 {
  outline-color: rgb(192 38 211 / 0.3) !important;
}

.tw-outline-fuchsia-600\/35 {
  outline-color: rgb(192 38 211 / 0.35) !important;
}

.tw-outline-fuchsia-600\/40 {
  outline-color: rgb(192 38 211 / 0.4) !important;
}

.tw-outline-fuchsia-600\/45 {
  outline-color: rgb(192 38 211 / 0.45) !important;
}

.tw-outline-fuchsia-600\/5 {
  outline-color: rgb(192 38 211 / 0.05) !important;
}

.tw-outline-fuchsia-600\/50 {
  outline-color: rgb(192 38 211 / 0.5) !important;
}

.tw-outline-fuchsia-600\/55 {
  outline-color: rgb(192 38 211 / 0.55) !important;
}

.tw-outline-fuchsia-600\/60 {
  outline-color: rgb(192 38 211 / 0.6) !important;
}

.tw-outline-fuchsia-600\/65 {
  outline-color: rgb(192 38 211 / 0.65) !important;
}

.tw-outline-fuchsia-600\/70 {
  outline-color: rgb(192 38 211 / 0.7) !important;
}

.tw-outline-fuchsia-600\/75 {
  outline-color: rgb(192 38 211 / 0.75) !important;
}

.tw-outline-fuchsia-600\/80 {
  outline-color: rgb(192 38 211 / 0.8) !important;
}

.tw-outline-fuchsia-600\/85 {
  outline-color: rgb(192 38 211 / 0.85) !important;
}

.tw-outline-fuchsia-600\/90 {
  outline-color: rgb(192 38 211 / 0.9) !important;
}

.tw-outline-fuchsia-600\/95 {
  outline-color: rgb(192 38 211 / 0.95) !important;
}

.tw-outline-fuchsia-700 {
  outline-color: #a21caf !important;
}

.tw-outline-fuchsia-700\/0 {
  outline-color: rgb(162 28 175 / 0) !important;
}

.tw-outline-fuchsia-700\/10 {
  outline-color: rgb(162 28 175 / 0.1) !important;
}

.tw-outline-fuchsia-700\/100 {
  outline-color: rgb(162 28 175 / 1) !important;
}

.tw-outline-fuchsia-700\/15 {
  outline-color: rgb(162 28 175 / 0.15) !important;
}

.tw-outline-fuchsia-700\/20 {
  outline-color: rgb(162 28 175 / 0.2) !important;
}

.tw-outline-fuchsia-700\/25 {
  outline-color: rgb(162 28 175 / 0.25) !important;
}

.tw-outline-fuchsia-700\/30 {
  outline-color: rgb(162 28 175 / 0.3) !important;
}

.tw-outline-fuchsia-700\/35 {
  outline-color: rgb(162 28 175 / 0.35) !important;
}

.tw-outline-fuchsia-700\/40 {
  outline-color: rgb(162 28 175 / 0.4) !important;
}

.tw-outline-fuchsia-700\/45 {
  outline-color: rgb(162 28 175 / 0.45) !important;
}

.tw-outline-fuchsia-700\/5 {
  outline-color: rgb(162 28 175 / 0.05) !important;
}

.tw-outline-fuchsia-700\/50 {
  outline-color: rgb(162 28 175 / 0.5) !important;
}

.tw-outline-fuchsia-700\/55 {
  outline-color: rgb(162 28 175 / 0.55) !important;
}

.tw-outline-fuchsia-700\/60 {
  outline-color: rgb(162 28 175 / 0.6) !important;
}

.tw-outline-fuchsia-700\/65 {
  outline-color: rgb(162 28 175 / 0.65) !important;
}

.tw-outline-fuchsia-700\/70 {
  outline-color: rgb(162 28 175 / 0.7) !important;
}

.tw-outline-fuchsia-700\/75 {
  outline-color: rgb(162 28 175 / 0.75) !important;
}

.tw-outline-fuchsia-700\/80 {
  outline-color: rgb(162 28 175 / 0.8) !important;
}

.tw-outline-fuchsia-700\/85 {
  outline-color: rgb(162 28 175 / 0.85) !important;
}

.tw-outline-fuchsia-700\/90 {
  outline-color: rgb(162 28 175 / 0.9) !important;
}

.tw-outline-fuchsia-700\/95 {
  outline-color: rgb(162 28 175 / 0.95) !important;
}

.tw-outline-fuchsia-800 {
  outline-color: #86198f !important;
}

.tw-outline-fuchsia-800\/0 {
  outline-color: rgb(134 25 143 / 0) !important;
}

.tw-outline-fuchsia-800\/10 {
  outline-color: rgb(134 25 143 / 0.1) !important;
}

.tw-outline-fuchsia-800\/100 {
  outline-color: rgb(134 25 143 / 1) !important;
}

.tw-outline-fuchsia-800\/15 {
  outline-color: rgb(134 25 143 / 0.15) !important;
}

.tw-outline-fuchsia-800\/20 {
  outline-color: rgb(134 25 143 / 0.2) !important;
}

.tw-outline-fuchsia-800\/25 {
  outline-color: rgb(134 25 143 / 0.25) !important;
}

.tw-outline-fuchsia-800\/30 {
  outline-color: rgb(134 25 143 / 0.3) !important;
}

.tw-outline-fuchsia-800\/35 {
  outline-color: rgb(134 25 143 / 0.35) !important;
}

.tw-outline-fuchsia-800\/40 {
  outline-color: rgb(134 25 143 / 0.4) !important;
}

.tw-outline-fuchsia-800\/45 {
  outline-color: rgb(134 25 143 / 0.45) !important;
}

.tw-outline-fuchsia-800\/5 {
  outline-color: rgb(134 25 143 / 0.05) !important;
}

.tw-outline-fuchsia-800\/50 {
  outline-color: rgb(134 25 143 / 0.5) !important;
}

.tw-outline-fuchsia-800\/55 {
  outline-color: rgb(134 25 143 / 0.55) !important;
}

.tw-outline-fuchsia-800\/60 {
  outline-color: rgb(134 25 143 / 0.6) !important;
}

.tw-outline-fuchsia-800\/65 {
  outline-color: rgb(134 25 143 / 0.65) !important;
}

.tw-outline-fuchsia-800\/70 {
  outline-color: rgb(134 25 143 / 0.7) !important;
}

.tw-outline-fuchsia-800\/75 {
  outline-color: rgb(134 25 143 / 0.75) !important;
}

.tw-outline-fuchsia-800\/80 {
  outline-color: rgb(134 25 143 / 0.8) !important;
}

.tw-outline-fuchsia-800\/85 {
  outline-color: rgb(134 25 143 / 0.85) !important;
}

.tw-outline-fuchsia-800\/90 {
  outline-color: rgb(134 25 143 / 0.9) !important;
}

.tw-outline-fuchsia-800\/95 {
  outline-color: rgb(134 25 143 / 0.95) !important;
}

.tw-outline-fuchsia-900 {
  outline-color: #701a75 !important;
}

.tw-outline-fuchsia-900\/0 {
  outline-color: rgb(112 26 117 / 0) !important;
}

.tw-outline-fuchsia-900\/10 {
  outline-color: rgb(112 26 117 / 0.1) !important;
}

.tw-outline-fuchsia-900\/100 {
  outline-color: rgb(112 26 117 / 1) !important;
}

.tw-outline-fuchsia-900\/15 {
  outline-color: rgb(112 26 117 / 0.15) !important;
}

.tw-outline-fuchsia-900\/20 {
  outline-color: rgb(112 26 117 / 0.2) !important;
}

.tw-outline-fuchsia-900\/25 {
  outline-color: rgb(112 26 117 / 0.25) !important;
}

.tw-outline-fuchsia-900\/30 {
  outline-color: rgb(112 26 117 / 0.3) !important;
}

.tw-outline-fuchsia-900\/35 {
  outline-color: rgb(112 26 117 / 0.35) !important;
}

.tw-outline-fuchsia-900\/40 {
  outline-color: rgb(112 26 117 / 0.4) !important;
}

.tw-outline-fuchsia-900\/45 {
  outline-color: rgb(112 26 117 / 0.45) !important;
}

.tw-outline-fuchsia-900\/5 {
  outline-color: rgb(112 26 117 / 0.05) !important;
}

.tw-outline-fuchsia-900\/50 {
  outline-color: rgb(112 26 117 / 0.5) !important;
}

.tw-outline-fuchsia-900\/55 {
  outline-color: rgb(112 26 117 / 0.55) !important;
}

.tw-outline-fuchsia-900\/60 {
  outline-color: rgb(112 26 117 / 0.6) !important;
}

.tw-outline-fuchsia-900\/65 {
  outline-color: rgb(112 26 117 / 0.65) !important;
}

.tw-outline-fuchsia-900\/70 {
  outline-color: rgb(112 26 117 / 0.7) !important;
}

.tw-outline-fuchsia-900\/75 {
  outline-color: rgb(112 26 117 / 0.75) !important;
}

.tw-outline-fuchsia-900\/80 {
  outline-color: rgb(112 26 117 / 0.8) !important;
}

.tw-outline-fuchsia-900\/85 {
  outline-color: rgb(112 26 117 / 0.85) !important;
}

.tw-outline-fuchsia-900\/90 {
  outline-color: rgb(112 26 117 / 0.9) !important;
}

.tw-outline-fuchsia-900\/95 {
  outline-color: rgb(112 26 117 / 0.95) !important;
}

.tw-outline-fuchsia-950 {
  outline-color: #4a044e !important;
}

.tw-outline-fuchsia-950\/0 {
  outline-color: rgb(74 4 78 / 0) !important;
}

.tw-outline-fuchsia-950\/10 {
  outline-color: rgb(74 4 78 / 0.1) !important;
}

.tw-outline-fuchsia-950\/100 {
  outline-color: rgb(74 4 78 / 1) !important;
}

.tw-outline-fuchsia-950\/15 {
  outline-color: rgb(74 4 78 / 0.15) !important;
}

.tw-outline-fuchsia-950\/20 {
  outline-color: rgb(74 4 78 / 0.2) !important;
}

.tw-outline-fuchsia-950\/25 {
  outline-color: rgb(74 4 78 / 0.25) !important;
}

.tw-outline-fuchsia-950\/30 {
  outline-color: rgb(74 4 78 / 0.3) !important;
}

.tw-outline-fuchsia-950\/35 {
  outline-color: rgb(74 4 78 / 0.35) !important;
}

.tw-outline-fuchsia-950\/40 {
  outline-color: rgb(74 4 78 / 0.4) !important;
}

.tw-outline-fuchsia-950\/45 {
  outline-color: rgb(74 4 78 / 0.45) !important;
}

.tw-outline-fuchsia-950\/5 {
  outline-color: rgb(74 4 78 / 0.05) !important;
}

.tw-outline-fuchsia-950\/50 {
  outline-color: rgb(74 4 78 / 0.5) !important;
}

.tw-outline-fuchsia-950\/55 {
  outline-color: rgb(74 4 78 / 0.55) !important;
}

.tw-outline-fuchsia-950\/60 {
  outline-color: rgb(74 4 78 / 0.6) !important;
}

.tw-outline-fuchsia-950\/65 {
  outline-color: rgb(74 4 78 / 0.65) !important;
}

.tw-outline-fuchsia-950\/70 {
  outline-color: rgb(74 4 78 / 0.7) !important;
}

.tw-outline-fuchsia-950\/75 {
  outline-color: rgb(74 4 78 / 0.75) !important;
}

.tw-outline-fuchsia-950\/80 {
  outline-color: rgb(74 4 78 / 0.8) !important;
}

.tw-outline-fuchsia-950\/85 {
  outline-color: rgb(74 4 78 / 0.85) !important;
}

.tw-outline-fuchsia-950\/90 {
  outline-color: rgb(74 4 78 / 0.9) !important;
}

.tw-outline-fuchsia-950\/95 {
  outline-color: rgb(74 4 78 / 0.95) !important;
}

.tw-outline-gray-100 {
  outline-color: #f3f4f6 !important;
}

.tw-outline-gray-100\/0 {
  outline-color: rgb(243 244 246 / 0) !important;
}

.tw-outline-gray-100\/10 {
  outline-color: rgb(243 244 246 / 0.1) !important;
}

.tw-outline-gray-100\/100 {
  outline-color: rgb(243 244 246 / 1) !important;
}

.tw-outline-gray-100\/15 {
  outline-color: rgb(243 244 246 / 0.15) !important;
}

.tw-outline-gray-100\/20 {
  outline-color: rgb(243 244 246 / 0.2) !important;
}

.tw-outline-gray-100\/25 {
  outline-color: rgb(243 244 246 / 0.25) !important;
}

.tw-outline-gray-100\/30 {
  outline-color: rgb(243 244 246 / 0.3) !important;
}

.tw-outline-gray-100\/35 {
  outline-color: rgb(243 244 246 / 0.35) !important;
}

.tw-outline-gray-100\/40 {
  outline-color: rgb(243 244 246 / 0.4) !important;
}

.tw-outline-gray-100\/45 {
  outline-color: rgb(243 244 246 / 0.45) !important;
}

.tw-outline-gray-100\/5 {
  outline-color: rgb(243 244 246 / 0.05) !important;
}

.tw-outline-gray-100\/50 {
  outline-color: rgb(243 244 246 / 0.5) !important;
}

.tw-outline-gray-100\/55 {
  outline-color: rgb(243 244 246 / 0.55) !important;
}

.tw-outline-gray-100\/60 {
  outline-color: rgb(243 244 246 / 0.6) !important;
}

.tw-outline-gray-100\/65 {
  outline-color: rgb(243 244 246 / 0.65) !important;
}

.tw-outline-gray-100\/70 {
  outline-color: rgb(243 244 246 / 0.7) !important;
}

.tw-outline-gray-100\/75 {
  outline-color: rgb(243 244 246 / 0.75) !important;
}

.tw-outline-gray-100\/80 {
  outline-color: rgb(243 244 246 / 0.8) !important;
}

.tw-outline-gray-100\/85 {
  outline-color: rgb(243 244 246 / 0.85) !important;
}

.tw-outline-gray-100\/90 {
  outline-color: rgb(243 244 246 / 0.9) !important;
}

.tw-outline-gray-100\/95 {
  outline-color: rgb(243 244 246 / 0.95) !important;
}

.tw-outline-gray-200 {
  outline-color: #e5e7eb !important;
}

.tw-outline-gray-200\/0 {
  outline-color: rgb(229 231 235 / 0) !important;
}

.tw-outline-gray-200\/10 {
  outline-color: rgb(229 231 235 / 0.1) !important;
}

.tw-outline-gray-200\/100 {
  outline-color: rgb(229 231 235 / 1) !important;
}

.tw-outline-gray-200\/15 {
  outline-color: rgb(229 231 235 / 0.15) !important;
}

.tw-outline-gray-200\/20 {
  outline-color: rgb(229 231 235 / 0.2) !important;
}

.tw-outline-gray-200\/25 {
  outline-color: rgb(229 231 235 / 0.25) !important;
}

.tw-outline-gray-200\/30 {
  outline-color: rgb(229 231 235 / 0.3) !important;
}

.tw-outline-gray-200\/35 {
  outline-color: rgb(229 231 235 / 0.35) !important;
}

.tw-outline-gray-200\/40 {
  outline-color: rgb(229 231 235 / 0.4) !important;
}

.tw-outline-gray-200\/45 {
  outline-color: rgb(229 231 235 / 0.45) !important;
}

.tw-outline-gray-200\/5 {
  outline-color: rgb(229 231 235 / 0.05) !important;
}

.tw-outline-gray-200\/50 {
  outline-color: rgb(229 231 235 / 0.5) !important;
}

.tw-outline-gray-200\/55 {
  outline-color: rgb(229 231 235 / 0.55) !important;
}

.tw-outline-gray-200\/60 {
  outline-color: rgb(229 231 235 / 0.6) !important;
}

.tw-outline-gray-200\/65 {
  outline-color: rgb(229 231 235 / 0.65) !important;
}

.tw-outline-gray-200\/70 {
  outline-color: rgb(229 231 235 / 0.7) !important;
}

.tw-outline-gray-200\/75 {
  outline-color: rgb(229 231 235 / 0.75) !important;
}

.tw-outline-gray-200\/80 {
  outline-color: rgb(229 231 235 / 0.8) !important;
}

.tw-outline-gray-200\/85 {
  outline-color: rgb(229 231 235 / 0.85) !important;
}

.tw-outline-gray-200\/90 {
  outline-color: rgb(229 231 235 / 0.9) !important;
}

.tw-outline-gray-200\/95 {
  outline-color: rgb(229 231 235 / 0.95) !important;
}

.tw-outline-gray-300 {
  outline-color: #d1d5db !important;
}

.tw-outline-gray-300\/0 {
  outline-color: rgb(209 213 219 / 0) !important;
}

.tw-outline-gray-300\/10 {
  outline-color: rgb(209 213 219 / 0.1) !important;
}

.tw-outline-gray-300\/100 {
  outline-color: rgb(209 213 219 / 1) !important;
}

.tw-outline-gray-300\/15 {
  outline-color: rgb(209 213 219 / 0.15) !important;
}

.tw-outline-gray-300\/20 {
  outline-color: rgb(209 213 219 / 0.2) !important;
}

.tw-outline-gray-300\/25 {
  outline-color: rgb(209 213 219 / 0.25) !important;
}

.tw-outline-gray-300\/30 {
  outline-color: rgb(209 213 219 / 0.3) !important;
}

.tw-outline-gray-300\/35 {
  outline-color: rgb(209 213 219 / 0.35) !important;
}

.tw-outline-gray-300\/40 {
  outline-color: rgb(209 213 219 / 0.4) !important;
}

.tw-outline-gray-300\/45 {
  outline-color: rgb(209 213 219 / 0.45) !important;
}

.tw-outline-gray-300\/5 {
  outline-color: rgb(209 213 219 / 0.05) !important;
}

.tw-outline-gray-300\/50 {
  outline-color: rgb(209 213 219 / 0.5) !important;
}

.tw-outline-gray-300\/55 {
  outline-color: rgb(209 213 219 / 0.55) !important;
}

.tw-outline-gray-300\/60 {
  outline-color: rgb(209 213 219 / 0.6) !important;
}

.tw-outline-gray-300\/65 {
  outline-color: rgb(209 213 219 / 0.65) !important;
}

.tw-outline-gray-300\/70 {
  outline-color: rgb(209 213 219 / 0.7) !important;
}

.tw-outline-gray-300\/75 {
  outline-color: rgb(209 213 219 / 0.75) !important;
}

.tw-outline-gray-300\/80 {
  outline-color: rgb(209 213 219 / 0.8) !important;
}

.tw-outline-gray-300\/85 {
  outline-color: rgb(209 213 219 / 0.85) !important;
}

.tw-outline-gray-300\/90 {
  outline-color: rgb(209 213 219 / 0.9) !important;
}

.tw-outline-gray-300\/95 {
  outline-color: rgb(209 213 219 / 0.95) !important;
}

.tw-outline-gray-400 {
  outline-color: #9ca3af !important;
}

.tw-outline-gray-400\/0 {
  outline-color: rgb(156 163 175 / 0) !important;
}

.tw-outline-gray-400\/10 {
  outline-color: rgb(156 163 175 / 0.1) !important;
}

.tw-outline-gray-400\/100 {
  outline-color: rgb(156 163 175 / 1) !important;
}

.tw-outline-gray-400\/15 {
  outline-color: rgb(156 163 175 / 0.15) !important;
}

.tw-outline-gray-400\/20 {
  outline-color: rgb(156 163 175 / 0.2) !important;
}

.tw-outline-gray-400\/25 {
  outline-color: rgb(156 163 175 / 0.25) !important;
}

.tw-outline-gray-400\/30 {
  outline-color: rgb(156 163 175 / 0.3) !important;
}

.tw-outline-gray-400\/35 {
  outline-color: rgb(156 163 175 / 0.35) !important;
}

.tw-outline-gray-400\/40 {
  outline-color: rgb(156 163 175 / 0.4) !important;
}

.tw-outline-gray-400\/45 {
  outline-color: rgb(156 163 175 / 0.45) !important;
}

.tw-outline-gray-400\/5 {
  outline-color: rgb(156 163 175 / 0.05) !important;
}

.tw-outline-gray-400\/50 {
  outline-color: rgb(156 163 175 / 0.5) !important;
}

.tw-outline-gray-400\/55 {
  outline-color: rgb(156 163 175 / 0.55) !important;
}

.tw-outline-gray-400\/60 {
  outline-color: rgb(156 163 175 / 0.6) !important;
}

.tw-outline-gray-400\/65 {
  outline-color: rgb(156 163 175 / 0.65) !important;
}

.tw-outline-gray-400\/70 {
  outline-color: rgb(156 163 175 / 0.7) !important;
}

.tw-outline-gray-400\/75 {
  outline-color: rgb(156 163 175 / 0.75) !important;
}

.tw-outline-gray-400\/80 {
  outline-color: rgb(156 163 175 / 0.8) !important;
}

.tw-outline-gray-400\/85 {
  outline-color: rgb(156 163 175 / 0.85) !important;
}

.tw-outline-gray-400\/90 {
  outline-color: rgb(156 163 175 / 0.9) !important;
}

.tw-outline-gray-400\/95 {
  outline-color: rgb(156 163 175 / 0.95) !important;
}

.tw-outline-gray-50 {
  outline-color: #f9fafb !important;
}

.tw-outline-gray-50\/0 {
  outline-color: rgb(249 250 251 / 0) !important;
}

.tw-outline-gray-50\/10 {
  outline-color: rgb(249 250 251 / 0.1) !important;
}

.tw-outline-gray-50\/100 {
  outline-color: rgb(249 250 251 / 1) !important;
}

.tw-outline-gray-50\/15 {
  outline-color: rgb(249 250 251 / 0.15) !important;
}

.tw-outline-gray-50\/20 {
  outline-color: rgb(249 250 251 / 0.2) !important;
}

.tw-outline-gray-50\/25 {
  outline-color: rgb(249 250 251 / 0.25) !important;
}

.tw-outline-gray-50\/30 {
  outline-color: rgb(249 250 251 / 0.3) !important;
}

.tw-outline-gray-50\/35 {
  outline-color: rgb(249 250 251 / 0.35) !important;
}

.tw-outline-gray-50\/40 {
  outline-color: rgb(249 250 251 / 0.4) !important;
}

.tw-outline-gray-50\/45 {
  outline-color: rgb(249 250 251 / 0.45) !important;
}

.tw-outline-gray-50\/5 {
  outline-color: rgb(249 250 251 / 0.05) !important;
}

.tw-outline-gray-50\/50 {
  outline-color: rgb(249 250 251 / 0.5) !important;
}

.tw-outline-gray-50\/55 {
  outline-color: rgb(249 250 251 / 0.55) !important;
}

.tw-outline-gray-50\/60 {
  outline-color: rgb(249 250 251 / 0.6) !important;
}

.tw-outline-gray-50\/65 {
  outline-color: rgb(249 250 251 / 0.65) !important;
}

.tw-outline-gray-50\/70 {
  outline-color: rgb(249 250 251 / 0.7) !important;
}

.tw-outline-gray-50\/75 {
  outline-color: rgb(249 250 251 / 0.75) !important;
}

.tw-outline-gray-50\/80 {
  outline-color: rgb(249 250 251 / 0.8) !important;
}

.tw-outline-gray-50\/85 {
  outline-color: rgb(249 250 251 / 0.85) !important;
}

.tw-outline-gray-50\/90 {
  outline-color: rgb(249 250 251 / 0.9) !important;
}

.tw-outline-gray-50\/95 {
  outline-color: rgb(249 250 251 / 0.95) !important;
}

.tw-outline-gray-500 {
  outline-color: #6b7280 !important;
}

.tw-outline-gray-500\/0 {
  outline-color: rgb(107 114 128 / 0) !important;
}

.tw-outline-gray-500\/10 {
  outline-color: rgb(107 114 128 / 0.1) !important;
}

.tw-outline-gray-500\/100 {
  outline-color: rgb(107 114 128 / 1) !important;
}

.tw-outline-gray-500\/15 {
  outline-color: rgb(107 114 128 / 0.15) !important;
}

.tw-outline-gray-500\/20 {
  outline-color: rgb(107 114 128 / 0.2) !important;
}

.tw-outline-gray-500\/25 {
  outline-color: rgb(107 114 128 / 0.25) !important;
}

.tw-outline-gray-500\/30 {
  outline-color: rgb(107 114 128 / 0.3) !important;
}

.tw-outline-gray-500\/35 {
  outline-color: rgb(107 114 128 / 0.35) !important;
}

.tw-outline-gray-500\/40 {
  outline-color: rgb(107 114 128 / 0.4) !important;
}

.tw-outline-gray-500\/45 {
  outline-color: rgb(107 114 128 / 0.45) !important;
}

.tw-outline-gray-500\/5 {
  outline-color: rgb(107 114 128 / 0.05) !important;
}

.tw-outline-gray-500\/50 {
  outline-color: rgb(107 114 128 / 0.5) !important;
}

.tw-outline-gray-500\/55 {
  outline-color: rgb(107 114 128 / 0.55) !important;
}

.tw-outline-gray-500\/60 {
  outline-color: rgb(107 114 128 / 0.6) !important;
}

.tw-outline-gray-500\/65 {
  outline-color: rgb(107 114 128 / 0.65) !important;
}

.tw-outline-gray-500\/70 {
  outline-color: rgb(107 114 128 / 0.7) !important;
}

.tw-outline-gray-500\/75 {
  outline-color: rgb(107 114 128 / 0.75) !important;
}

.tw-outline-gray-500\/80 {
  outline-color: rgb(107 114 128 / 0.8) !important;
}

.tw-outline-gray-500\/85 {
  outline-color: rgb(107 114 128 / 0.85) !important;
}

.tw-outline-gray-500\/90 {
  outline-color: rgb(107 114 128 / 0.9) !important;
}

.tw-outline-gray-500\/95 {
  outline-color: rgb(107 114 128 / 0.95) !important;
}

.tw-outline-gray-600 {
  outline-color: #4b5563 !important;
}

.tw-outline-gray-600\/0 {
  outline-color: rgb(75 85 99 / 0) !important;
}

.tw-outline-gray-600\/10 {
  outline-color: rgb(75 85 99 / 0.1) !important;
}

.tw-outline-gray-600\/100 {
  outline-color: rgb(75 85 99 / 1) !important;
}

.tw-outline-gray-600\/15 {
  outline-color: rgb(75 85 99 / 0.15) !important;
}

.tw-outline-gray-600\/20 {
  outline-color: rgb(75 85 99 / 0.2) !important;
}

.tw-outline-gray-600\/25 {
  outline-color: rgb(75 85 99 / 0.25) !important;
}

.tw-outline-gray-600\/30 {
  outline-color: rgb(75 85 99 / 0.3) !important;
}

.tw-outline-gray-600\/35 {
  outline-color: rgb(75 85 99 / 0.35) !important;
}

.tw-outline-gray-600\/40 {
  outline-color: rgb(75 85 99 / 0.4) !important;
}

.tw-outline-gray-600\/45 {
  outline-color: rgb(75 85 99 / 0.45) !important;
}

.tw-outline-gray-600\/5 {
  outline-color: rgb(75 85 99 / 0.05) !important;
}

.tw-outline-gray-600\/50 {
  outline-color: rgb(75 85 99 / 0.5) !important;
}

.tw-outline-gray-600\/55 {
  outline-color: rgb(75 85 99 / 0.55) !important;
}

.tw-outline-gray-600\/60 {
  outline-color: rgb(75 85 99 / 0.6) !important;
}

.tw-outline-gray-600\/65 {
  outline-color: rgb(75 85 99 / 0.65) !important;
}

.tw-outline-gray-600\/70 {
  outline-color: rgb(75 85 99 / 0.7) !important;
}

.tw-outline-gray-600\/75 {
  outline-color: rgb(75 85 99 / 0.75) !important;
}

.tw-outline-gray-600\/80 {
  outline-color: rgb(75 85 99 / 0.8) !important;
}

.tw-outline-gray-600\/85 {
  outline-color: rgb(75 85 99 / 0.85) !important;
}

.tw-outline-gray-600\/90 {
  outline-color: rgb(75 85 99 / 0.9) !important;
}

.tw-outline-gray-600\/95 {
  outline-color: rgb(75 85 99 / 0.95) !important;
}

.tw-outline-gray-700 {
  outline-color: #374151 !important;
}

.tw-outline-gray-700\/0 {
  outline-color: rgb(55 65 81 / 0) !important;
}

.tw-outline-gray-700\/10 {
  outline-color: rgb(55 65 81 / 0.1) !important;
}

.tw-outline-gray-700\/100 {
  outline-color: rgb(55 65 81 / 1) !important;
}

.tw-outline-gray-700\/15 {
  outline-color: rgb(55 65 81 / 0.15) !important;
}

.tw-outline-gray-700\/20 {
  outline-color: rgb(55 65 81 / 0.2) !important;
}

.tw-outline-gray-700\/25 {
  outline-color: rgb(55 65 81 / 0.25) !important;
}

.tw-outline-gray-700\/30 {
  outline-color: rgb(55 65 81 / 0.3) !important;
}

.tw-outline-gray-700\/35 {
  outline-color: rgb(55 65 81 / 0.35) !important;
}

.tw-outline-gray-700\/40 {
  outline-color: rgb(55 65 81 / 0.4) !important;
}

.tw-outline-gray-700\/45 {
  outline-color: rgb(55 65 81 / 0.45) !important;
}

.tw-outline-gray-700\/5 {
  outline-color: rgb(55 65 81 / 0.05) !important;
}

.tw-outline-gray-700\/50 {
  outline-color: rgb(55 65 81 / 0.5) !important;
}

.tw-outline-gray-700\/55 {
  outline-color: rgb(55 65 81 / 0.55) !important;
}

.tw-outline-gray-700\/60 {
  outline-color: rgb(55 65 81 / 0.6) !important;
}

.tw-outline-gray-700\/65 {
  outline-color: rgb(55 65 81 / 0.65) !important;
}

.tw-outline-gray-700\/70 {
  outline-color: rgb(55 65 81 / 0.7) !important;
}

.tw-outline-gray-700\/75 {
  outline-color: rgb(55 65 81 / 0.75) !important;
}

.tw-outline-gray-700\/80 {
  outline-color: rgb(55 65 81 / 0.8) !important;
}

.tw-outline-gray-700\/85 {
  outline-color: rgb(55 65 81 / 0.85) !important;
}

.tw-outline-gray-700\/90 {
  outline-color: rgb(55 65 81 / 0.9) !important;
}

.tw-outline-gray-700\/95 {
  outline-color: rgb(55 65 81 / 0.95) !important;
}

.tw-outline-gray-800 {
  outline-color: #1f2937 !important;
}

.tw-outline-gray-800\/0 {
  outline-color: rgb(31 41 55 / 0) !important;
}

.tw-outline-gray-800\/10 {
  outline-color: rgb(31 41 55 / 0.1) !important;
}

.tw-outline-gray-800\/100 {
  outline-color: rgb(31 41 55 / 1) !important;
}

.tw-outline-gray-800\/15 {
  outline-color: rgb(31 41 55 / 0.15) !important;
}

.tw-outline-gray-800\/20 {
  outline-color: rgb(31 41 55 / 0.2) !important;
}

.tw-outline-gray-800\/25 {
  outline-color: rgb(31 41 55 / 0.25) !important;
}

.tw-outline-gray-800\/30 {
  outline-color: rgb(31 41 55 / 0.3) !important;
}

.tw-outline-gray-800\/35 {
  outline-color: rgb(31 41 55 / 0.35) !important;
}

.tw-outline-gray-800\/40 {
  outline-color: rgb(31 41 55 / 0.4) !important;
}

.tw-outline-gray-800\/45 {
  outline-color: rgb(31 41 55 / 0.45) !important;
}

.tw-outline-gray-800\/5 {
  outline-color: rgb(31 41 55 / 0.05) !important;
}

.tw-outline-gray-800\/50 {
  outline-color: rgb(31 41 55 / 0.5) !important;
}

.tw-outline-gray-800\/55 {
  outline-color: rgb(31 41 55 / 0.55) !important;
}

.tw-outline-gray-800\/60 {
  outline-color: rgb(31 41 55 / 0.6) !important;
}

.tw-outline-gray-800\/65 {
  outline-color: rgb(31 41 55 / 0.65) !important;
}

.tw-outline-gray-800\/70 {
  outline-color: rgb(31 41 55 / 0.7) !important;
}

.tw-outline-gray-800\/75 {
  outline-color: rgb(31 41 55 / 0.75) !important;
}

.tw-outline-gray-800\/80 {
  outline-color: rgb(31 41 55 / 0.8) !important;
}

.tw-outline-gray-800\/85 {
  outline-color: rgb(31 41 55 / 0.85) !important;
}

.tw-outline-gray-800\/90 {
  outline-color: rgb(31 41 55 / 0.9) !important;
}

.tw-outline-gray-800\/95 {
  outline-color: rgb(31 41 55 / 0.95) !important;
}

.tw-outline-gray-900 {
  outline-color: #111827 !important;
}

.tw-outline-gray-900\/0 {
  outline-color: rgb(17 24 39 / 0) !important;
}

.tw-outline-gray-900\/10 {
  outline-color: rgb(17 24 39 / 0.1) !important;
}

.tw-outline-gray-900\/100 {
  outline-color: rgb(17 24 39 / 1) !important;
}

.tw-outline-gray-900\/15 {
  outline-color: rgb(17 24 39 / 0.15) !important;
}

.tw-outline-gray-900\/20 {
  outline-color: rgb(17 24 39 / 0.2) !important;
}

.tw-outline-gray-900\/25 {
  outline-color: rgb(17 24 39 / 0.25) !important;
}

.tw-outline-gray-900\/30 {
  outline-color: rgb(17 24 39 / 0.3) !important;
}

.tw-outline-gray-900\/35 {
  outline-color: rgb(17 24 39 / 0.35) !important;
}

.tw-outline-gray-900\/40 {
  outline-color: rgb(17 24 39 / 0.4) !important;
}

.tw-outline-gray-900\/45 {
  outline-color: rgb(17 24 39 / 0.45) !important;
}

.tw-outline-gray-900\/5 {
  outline-color: rgb(17 24 39 / 0.05) !important;
}

.tw-outline-gray-900\/50 {
  outline-color: rgb(17 24 39 / 0.5) !important;
}

.tw-outline-gray-900\/55 {
  outline-color: rgb(17 24 39 / 0.55) !important;
}

.tw-outline-gray-900\/60 {
  outline-color: rgb(17 24 39 / 0.6) !important;
}

.tw-outline-gray-900\/65 {
  outline-color: rgb(17 24 39 / 0.65) !important;
}

.tw-outline-gray-900\/70 {
  outline-color: rgb(17 24 39 / 0.7) !important;
}

.tw-outline-gray-900\/75 {
  outline-color: rgb(17 24 39 / 0.75) !important;
}

.tw-outline-gray-900\/80 {
  outline-color: rgb(17 24 39 / 0.8) !important;
}

.tw-outline-gray-900\/85 {
  outline-color: rgb(17 24 39 / 0.85) !important;
}

.tw-outline-gray-900\/90 {
  outline-color: rgb(17 24 39 / 0.9) !important;
}

.tw-outline-gray-900\/95 {
  outline-color: rgb(17 24 39 / 0.95) !important;
}

.tw-outline-gray-950 {
  outline-color: #030712 !important;
}

.tw-outline-gray-950\/0 {
  outline-color: rgb(3 7 18 / 0) !important;
}

.tw-outline-gray-950\/10 {
  outline-color: rgb(3 7 18 / 0.1) !important;
}

.tw-outline-gray-950\/100 {
  outline-color: rgb(3 7 18 / 1) !important;
}

.tw-outline-gray-950\/15 {
  outline-color: rgb(3 7 18 / 0.15) !important;
}

.tw-outline-gray-950\/20 {
  outline-color: rgb(3 7 18 / 0.2) !important;
}

.tw-outline-gray-950\/25 {
  outline-color: rgb(3 7 18 / 0.25) !important;
}

.tw-outline-gray-950\/30 {
  outline-color: rgb(3 7 18 / 0.3) !important;
}

.tw-outline-gray-950\/35 {
  outline-color: rgb(3 7 18 / 0.35) !important;
}

.tw-outline-gray-950\/40 {
  outline-color: rgb(3 7 18 / 0.4) !important;
}

.tw-outline-gray-950\/45 {
  outline-color: rgb(3 7 18 / 0.45) !important;
}

.tw-outline-gray-950\/5 {
  outline-color: rgb(3 7 18 / 0.05) !important;
}

.tw-outline-gray-950\/50 {
  outline-color: rgb(3 7 18 / 0.5) !important;
}

.tw-outline-gray-950\/55 {
  outline-color: rgb(3 7 18 / 0.55) !important;
}

.tw-outline-gray-950\/60 {
  outline-color: rgb(3 7 18 / 0.6) !important;
}

.tw-outline-gray-950\/65 {
  outline-color: rgb(3 7 18 / 0.65) !important;
}

.tw-outline-gray-950\/70 {
  outline-color: rgb(3 7 18 / 0.7) !important;
}

.tw-outline-gray-950\/75 {
  outline-color: rgb(3 7 18 / 0.75) !important;
}

.tw-outline-gray-950\/80 {
  outline-color: rgb(3 7 18 / 0.8) !important;
}

.tw-outline-gray-950\/85 {
  outline-color: rgb(3 7 18 / 0.85) !important;
}

.tw-outline-gray-950\/90 {
  outline-color: rgb(3 7 18 / 0.9) !important;
}

.tw-outline-gray-950\/95 {
  outline-color: rgb(3 7 18 / 0.95) !important;
}

.tw-outline-green-100 {
  outline-color: #dcfce7 !important;
}

.tw-outline-green-100\/0 {
  outline-color: rgb(220 252 231 / 0) !important;
}

.tw-outline-green-100\/10 {
  outline-color: rgb(220 252 231 / 0.1) !important;
}

.tw-outline-green-100\/100 {
  outline-color: rgb(220 252 231 / 1) !important;
}

.tw-outline-green-100\/15 {
  outline-color: rgb(220 252 231 / 0.15) !important;
}

.tw-outline-green-100\/20 {
  outline-color: rgb(220 252 231 / 0.2) !important;
}

.tw-outline-green-100\/25 {
  outline-color: rgb(220 252 231 / 0.25) !important;
}

.tw-outline-green-100\/30 {
  outline-color: rgb(220 252 231 / 0.3) !important;
}

.tw-outline-green-100\/35 {
  outline-color: rgb(220 252 231 / 0.35) !important;
}

.tw-outline-green-100\/40 {
  outline-color: rgb(220 252 231 / 0.4) !important;
}

.tw-outline-green-100\/45 {
  outline-color: rgb(220 252 231 / 0.45) !important;
}

.tw-outline-green-100\/5 {
  outline-color: rgb(220 252 231 / 0.05) !important;
}

.tw-outline-green-100\/50 {
  outline-color: rgb(220 252 231 / 0.5) !important;
}

.tw-outline-green-100\/55 {
  outline-color: rgb(220 252 231 / 0.55) !important;
}

.tw-outline-green-100\/60 {
  outline-color: rgb(220 252 231 / 0.6) !important;
}

.tw-outline-green-100\/65 {
  outline-color: rgb(220 252 231 / 0.65) !important;
}

.tw-outline-green-100\/70 {
  outline-color: rgb(220 252 231 / 0.7) !important;
}

.tw-outline-green-100\/75 {
  outline-color: rgb(220 252 231 / 0.75) !important;
}

.tw-outline-green-100\/80 {
  outline-color: rgb(220 252 231 / 0.8) !important;
}

.tw-outline-green-100\/85 {
  outline-color: rgb(220 252 231 / 0.85) !important;
}

.tw-outline-green-100\/90 {
  outline-color: rgb(220 252 231 / 0.9) !important;
}

.tw-outline-green-100\/95 {
  outline-color: rgb(220 252 231 / 0.95) !important;
}

.tw-outline-green-200 {
  outline-color: #bbf7d0 !important;
}

.tw-outline-green-200\/0 {
  outline-color: rgb(187 247 208 / 0) !important;
}

.tw-outline-green-200\/10 {
  outline-color: rgb(187 247 208 / 0.1) !important;
}

.tw-outline-green-200\/100 {
  outline-color: rgb(187 247 208 / 1) !important;
}

.tw-outline-green-200\/15 {
  outline-color: rgb(187 247 208 / 0.15) !important;
}

.tw-outline-green-200\/20 {
  outline-color: rgb(187 247 208 / 0.2) !important;
}

.tw-outline-green-200\/25 {
  outline-color: rgb(187 247 208 / 0.25) !important;
}

.tw-outline-green-200\/30 {
  outline-color: rgb(187 247 208 / 0.3) !important;
}

.tw-outline-green-200\/35 {
  outline-color: rgb(187 247 208 / 0.35) !important;
}

.tw-outline-green-200\/40 {
  outline-color: rgb(187 247 208 / 0.4) !important;
}

.tw-outline-green-200\/45 {
  outline-color: rgb(187 247 208 / 0.45) !important;
}

.tw-outline-green-200\/5 {
  outline-color: rgb(187 247 208 / 0.05) !important;
}

.tw-outline-green-200\/50 {
  outline-color: rgb(187 247 208 / 0.5) !important;
}

.tw-outline-green-200\/55 {
  outline-color: rgb(187 247 208 / 0.55) !important;
}

.tw-outline-green-200\/60 {
  outline-color: rgb(187 247 208 / 0.6) !important;
}

.tw-outline-green-200\/65 {
  outline-color: rgb(187 247 208 / 0.65) !important;
}

.tw-outline-green-200\/70 {
  outline-color: rgb(187 247 208 / 0.7) !important;
}

.tw-outline-green-200\/75 {
  outline-color: rgb(187 247 208 / 0.75) !important;
}

.tw-outline-green-200\/80 {
  outline-color: rgb(187 247 208 / 0.8) !important;
}

.tw-outline-green-200\/85 {
  outline-color: rgb(187 247 208 / 0.85) !important;
}

.tw-outline-green-200\/90 {
  outline-color: rgb(187 247 208 / 0.9) !important;
}

.tw-outline-green-200\/95 {
  outline-color: rgb(187 247 208 / 0.95) !important;
}

.tw-outline-green-300 {
  outline-color: #86efac !important;
}

.tw-outline-green-300\/0 {
  outline-color: rgb(134 239 172 / 0) !important;
}

.tw-outline-green-300\/10 {
  outline-color: rgb(134 239 172 / 0.1) !important;
}

.tw-outline-green-300\/100 {
  outline-color: rgb(134 239 172 / 1) !important;
}

.tw-outline-green-300\/15 {
  outline-color: rgb(134 239 172 / 0.15) !important;
}

.tw-outline-green-300\/20 {
  outline-color: rgb(134 239 172 / 0.2) !important;
}

.tw-outline-green-300\/25 {
  outline-color: rgb(134 239 172 / 0.25) !important;
}

.tw-outline-green-300\/30 {
  outline-color: rgb(134 239 172 / 0.3) !important;
}

.tw-outline-green-300\/35 {
  outline-color: rgb(134 239 172 / 0.35) !important;
}

.tw-outline-green-300\/40 {
  outline-color: rgb(134 239 172 / 0.4) !important;
}

.tw-outline-green-300\/45 {
  outline-color: rgb(134 239 172 / 0.45) !important;
}

.tw-outline-green-300\/5 {
  outline-color: rgb(134 239 172 / 0.05) !important;
}

.tw-outline-green-300\/50 {
  outline-color: rgb(134 239 172 / 0.5) !important;
}

.tw-outline-green-300\/55 {
  outline-color: rgb(134 239 172 / 0.55) !important;
}

.tw-outline-green-300\/60 {
  outline-color: rgb(134 239 172 / 0.6) !important;
}

.tw-outline-green-300\/65 {
  outline-color: rgb(134 239 172 / 0.65) !important;
}

.tw-outline-green-300\/70 {
  outline-color: rgb(134 239 172 / 0.7) !important;
}

.tw-outline-green-300\/75 {
  outline-color: rgb(134 239 172 / 0.75) !important;
}

.tw-outline-green-300\/80 {
  outline-color: rgb(134 239 172 / 0.8) !important;
}

.tw-outline-green-300\/85 {
  outline-color: rgb(134 239 172 / 0.85) !important;
}

.tw-outline-green-300\/90 {
  outline-color: rgb(134 239 172 / 0.9) !important;
}

.tw-outline-green-300\/95 {
  outline-color: rgb(134 239 172 / 0.95) !important;
}

.tw-outline-green-400 {
  outline-color: #4ade80 !important;
}

.tw-outline-green-400\/0 {
  outline-color: rgb(74 222 128 / 0) !important;
}

.tw-outline-green-400\/10 {
  outline-color: rgb(74 222 128 / 0.1) !important;
}

.tw-outline-green-400\/100 {
  outline-color: rgb(74 222 128 / 1) !important;
}

.tw-outline-green-400\/15 {
  outline-color: rgb(74 222 128 / 0.15) !important;
}

.tw-outline-green-400\/20 {
  outline-color: rgb(74 222 128 / 0.2) !important;
}

.tw-outline-green-400\/25 {
  outline-color: rgb(74 222 128 / 0.25) !important;
}

.tw-outline-green-400\/30 {
  outline-color: rgb(74 222 128 / 0.3) !important;
}

.tw-outline-green-400\/35 {
  outline-color: rgb(74 222 128 / 0.35) !important;
}

.tw-outline-green-400\/40 {
  outline-color: rgb(74 222 128 / 0.4) !important;
}

.tw-outline-green-400\/45 {
  outline-color: rgb(74 222 128 / 0.45) !important;
}

.tw-outline-green-400\/5 {
  outline-color: rgb(74 222 128 / 0.05) !important;
}

.tw-outline-green-400\/50 {
  outline-color: rgb(74 222 128 / 0.5) !important;
}

.tw-outline-green-400\/55 {
  outline-color: rgb(74 222 128 / 0.55) !important;
}

.tw-outline-green-400\/60 {
  outline-color: rgb(74 222 128 / 0.6) !important;
}

.tw-outline-green-400\/65 {
  outline-color: rgb(74 222 128 / 0.65) !important;
}

.tw-outline-green-400\/70 {
  outline-color: rgb(74 222 128 / 0.7) !important;
}

.tw-outline-green-400\/75 {
  outline-color: rgb(74 222 128 / 0.75) !important;
}

.tw-outline-green-400\/80 {
  outline-color: rgb(74 222 128 / 0.8) !important;
}

.tw-outline-green-400\/85 {
  outline-color: rgb(74 222 128 / 0.85) !important;
}

.tw-outline-green-400\/90 {
  outline-color: rgb(74 222 128 / 0.9) !important;
}

.tw-outline-green-400\/95 {
  outline-color: rgb(74 222 128 / 0.95) !important;
}

.tw-outline-green-50 {
  outline-color: #f0fdf4 !important;
}

.tw-outline-green-50\/0 {
  outline-color: rgb(240 253 244 / 0) !important;
}

.tw-outline-green-50\/10 {
  outline-color: rgb(240 253 244 / 0.1) !important;
}

.tw-outline-green-50\/100 {
  outline-color: rgb(240 253 244 / 1) !important;
}

.tw-outline-green-50\/15 {
  outline-color: rgb(240 253 244 / 0.15) !important;
}

.tw-outline-green-50\/20 {
  outline-color: rgb(240 253 244 / 0.2) !important;
}

.tw-outline-green-50\/25 {
  outline-color: rgb(240 253 244 / 0.25) !important;
}

.tw-outline-green-50\/30 {
  outline-color: rgb(240 253 244 / 0.3) !important;
}

.tw-outline-green-50\/35 {
  outline-color: rgb(240 253 244 / 0.35) !important;
}

.tw-outline-green-50\/40 {
  outline-color: rgb(240 253 244 / 0.4) !important;
}

.tw-outline-green-50\/45 {
  outline-color: rgb(240 253 244 / 0.45) !important;
}

.tw-outline-green-50\/5 {
  outline-color: rgb(240 253 244 / 0.05) !important;
}

.tw-outline-green-50\/50 {
  outline-color: rgb(240 253 244 / 0.5) !important;
}

.tw-outline-green-50\/55 {
  outline-color: rgb(240 253 244 / 0.55) !important;
}

.tw-outline-green-50\/60 {
  outline-color: rgb(240 253 244 / 0.6) !important;
}

.tw-outline-green-50\/65 {
  outline-color: rgb(240 253 244 / 0.65) !important;
}

.tw-outline-green-50\/70 {
  outline-color: rgb(240 253 244 / 0.7) !important;
}

.tw-outline-green-50\/75 {
  outline-color: rgb(240 253 244 / 0.75) !important;
}

.tw-outline-green-50\/80 {
  outline-color: rgb(240 253 244 / 0.8) !important;
}

.tw-outline-green-50\/85 {
  outline-color: rgb(240 253 244 / 0.85) !important;
}

.tw-outline-green-50\/90 {
  outline-color: rgb(240 253 244 / 0.9) !important;
}

.tw-outline-green-50\/95 {
  outline-color: rgb(240 253 244 / 0.95) !important;
}

.tw-outline-green-500 {
  outline-color: #22c55e !important;
}

.tw-outline-green-500\/0 {
  outline-color: rgb(34 197 94 / 0) !important;
}

.tw-outline-green-500\/10 {
  outline-color: rgb(34 197 94 / 0.1) !important;
}

.tw-outline-green-500\/100 {
  outline-color: rgb(34 197 94 / 1) !important;
}

.tw-outline-green-500\/15 {
  outline-color: rgb(34 197 94 / 0.15) !important;
}

.tw-outline-green-500\/20 {
  outline-color: rgb(34 197 94 / 0.2) !important;
}

.tw-outline-green-500\/25 {
  outline-color: rgb(34 197 94 / 0.25) !important;
}

.tw-outline-green-500\/30 {
  outline-color: rgb(34 197 94 / 0.3) !important;
}

.tw-outline-green-500\/35 {
  outline-color: rgb(34 197 94 / 0.35) !important;
}

.tw-outline-green-500\/40 {
  outline-color: rgb(34 197 94 / 0.4) !important;
}

.tw-outline-green-500\/45 {
  outline-color: rgb(34 197 94 / 0.45) !important;
}

.tw-outline-green-500\/5 {
  outline-color: rgb(34 197 94 / 0.05) !important;
}

.tw-outline-green-500\/50 {
  outline-color: rgb(34 197 94 / 0.5) !important;
}

.tw-outline-green-500\/55 {
  outline-color: rgb(34 197 94 / 0.55) !important;
}

.tw-outline-green-500\/60 {
  outline-color: rgb(34 197 94 / 0.6) !important;
}

.tw-outline-green-500\/65 {
  outline-color: rgb(34 197 94 / 0.65) !important;
}

.tw-outline-green-500\/70 {
  outline-color: rgb(34 197 94 / 0.7) !important;
}

.tw-outline-green-500\/75 {
  outline-color: rgb(34 197 94 / 0.75) !important;
}

.tw-outline-green-500\/80 {
  outline-color: rgb(34 197 94 / 0.8) !important;
}

.tw-outline-green-500\/85 {
  outline-color: rgb(34 197 94 / 0.85) !important;
}

.tw-outline-green-500\/90 {
  outline-color: rgb(34 197 94 / 0.9) !important;
}

.tw-outline-green-500\/95 {
  outline-color: rgb(34 197 94 / 0.95) !important;
}

.tw-outline-green-600 {
  outline-color: #16a34a !important;
}

.tw-outline-green-600\/0 {
  outline-color: rgb(22 163 74 / 0) !important;
}

.tw-outline-green-600\/10 {
  outline-color: rgb(22 163 74 / 0.1) !important;
}

.tw-outline-green-600\/100 {
  outline-color: rgb(22 163 74 / 1) !important;
}

.tw-outline-green-600\/15 {
  outline-color: rgb(22 163 74 / 0.15) !important;
}

.tw-outline-green-600\/20 {
  outline-color: rgb(22 163 74 / 0.2) !important;
}

.tw-outline-green-600\/25 {
  outline-color: rgb(22 163 74 / 0.25) !important;
}

.tw-outline-green-600\/30 {
  outline-color: rgb(22 163 74 / 0.3) !important;
}

.tw-outline-green-600\/35 {
  outline-color: rgb(22 163 74 / 0.35) !important;
}

.tw-outline-green-600\/40 {
  outline-color: rgb(22 163 74 / 0.4) !important;
}

.tw-outline-green-600\/45 {
  outline-color: rgb(22 163 74 / 0.45) !important;
}

.tw-outline-green-600\/5 {
  outline-color: rgb(22 163 74 / 0.05) !important;
}

.tw-outline-green-600\/50 {
  outline-color: rgb(22 163 74 / 0.5) !important;
}

.tw-outline-green-600\/55 {
  outline-color: rgb(22 163 74 / 0.55) !important;
}

.tw-outline-green-600\/60 {
  outline-color: rgb(22 163 74 / 0.6) !important;
}

.tw-outline-green-600\/65 {
  outline-color: rgb(22 163 74 / 0.65) !important;
}

.tw-outline-green-600\/70 {
  outline-color: rgb(22 163 74 / 0.7) !important;
}

.tw-outline-green-600\/75 {
  outline-color: rgb(22 163 74 / 0.75) !important;
}

.tw-outline-green-600\/80 {
  outline-color: rgb(22 163 74 / 0.8) !important;
}

.tw-outline-green-600\/85 {
  outline-color: rgb(22 163 74 / 0.85) !important;
}

.tw-outline-green-600\/90 {
  outline-color: rgb(22 163 74 / 0.9) !important;
}

.tw-outline-green-600\/95 {
  outline-color: rgb(22 163 74 / 0.95) !important;
}

.tw-outline-green-700 {
  outline-color: #15803d !important;
}

.tw-outline-green-700\/0 {
  outline-color: rgb(21 128 61 / 0) !important;
}

.tw-outline-green-700\/10 {
  outline-color: rgb(21 128 61 / 0.1) !important;
}

.tw-outline-green-700\/100 {
  outline-color: rgb(21 128 61 / 1) !important;
}

.tw-outline-green-700\/15 {
  outline-color: rgb(21 128 61 / 0.15) !important;
}

.tw-outline-green-700\/20 {
  outline-color: rgb(21 128 61 / 0.2) !important;
}

.tw-outline-green-700\/25 {
  outline-color: rgb(21 128 61 / 0.25) !important;
}

.tw-outline-green-700\/30 {
  outline-color: rgb(21 128 61 / 0.3) !important;
}

.tw-outline-green-700\/35 {
  outline-color: rgb(21 128 61 / 0.35) !important;
}

.tw-outline-green-700\/40 {
  outline-color: rgb(21 128 61 / 0.4) !important;
}

.tw-outline-green-700\/45 {
  outline-color: rgb(21 128 61 / 0.45) !important;
}

.tw-outline-green-700\/5 {
  outline-color: rgb(21 128 61 / 0.05) !important;
}

.tw-outline-green-700\/50 {
  outline-color: rgb(21 128 61 / 0.5) !important;
}

.tw-outline-green-700\/55 {
  outline-color: rgb(21 128 61 / 0.55) !important;
}

.tw-outline-green-700\/60 {
  outline-color: rgb(21 128 61 / 0.6) !important;
}

.tw-outline-green-700\/65 {
  outline-color: rgb(21 128 61 / 0.65) !important;
}

.tw-outline-green-700\/70 {
  outline-color: rgb(21 128 61 / 0.7) !important;
}

.tw-outline-green-700\/75 {
  outline-color: rgb(21 128 61 / 0.75) !important;
}

.tw-outline-green-700\/80 {
  outline-color: rgb(21 128 61 / 0.8) !important;
}

.tw-outline-green-700\/85 {
  outline-color: rgb(21 128 61 / 0.85) !important;
}

.tw-outline-green-700\/90 {
  outline-color: rgb(21 128 61 / 0.9) !important;
}

.tw-outline-green-700\/95 {
  outline-color: rgb(21 128 61 / 0.95) !important;
}

.tw-outline-green-800 {
  outline-color: #166534 !important;
}

.tw-outline-green-800\/0 {
  outline-color: rgb(22 101 52 / 0) !important;
}

.tw-outline-green-800\/10 {
  outline-color: rgb(22 101 52 / 0.1) !important;
}

.tw-outline-green-800\/100 {
  outline-color: rgb(22 101 52 / 1) !important;
}

.tw-outline-green-800\/15 {
  outline-color: rgb(22 101 52 / 0.15) !important;
}

.tw-outline-green-800\/20 {
  outline-color: rgb(22 101 52 / 0.2) !important;
}

.tw-outline-green-800\/25 {
  outline-color: rgb(22 101 52 / 0.25) !important;
}

.tw-outline-green-800\/30 {
  outline-color: rgb(22 101 52 / 0.3) !important;
}

.tw-outline-green-800\/35 {
  outline-color: rgb(22 101 52 / 0.35) !important;
}

.tw-outline-green-800\/40 {
  outline-color: rgb(22 101 52 / 0.4) !important;
}

.tw-outline-green-800\/45 {
  outline-color: rgb(22 101 52 / 0.45) !important;
}

.tw-outline-green-800\/5 {
  outline-color: rgb(22 101 52 / 0.05) !important;
}

.tw-outline-green-800\/50 {
  outline-color: rgb(22 101 52 / 0.5) !important;
}

.tw-outline-green-800\/55 {
  outline-color: rgb(22 101 52 / 0.55) !important;
}

.tw-outline-green-800\/60 {
  outline-color: rgb(22 101 52 / 0.6) !important;
}

.tw-outline-green-800\/65 {
  outline-color: rgb(22 101 52 / 0.65) !important;
}

.tw-outline-green-800\/70 {
  outline-color: rgb(22 101 52 / 0.7) !important;
}

.tw-outline-green-800\/75 {
  outline-color: rgb(22 101 52 / 0.75) !important;
}

.tw-outline-green-800\/80 {
  outline-color: rgb(22 101 52 / 0.8) !important;
}

.tw-outline-green-800\/85 {
  outline-color: rgb(22 101 52 / 0.85) !important;
}

.tw-outline-green-800\/90 {
  outline-color: rgb(22 101 52 / 0.9) !important;
}

.tw-outline-green-800\/95 {
  outline-color: rgb(22 101 52 / 0.95) !important;
}

.tw-outline-green-900 {
  outline-color: #14532d !important;
}

.tw-outline-green-900\/0 {
  outline-color: rgb(20 83 45 / 0) !important;
}

.tw-outline-green-900\/10 {
  outline-color: rgb(20 83 45 / 0.1) !important;
}

.tw-outline-green-900\/100 {
  outline-color: rgb(20 83 45 / 1) !important;
}

.tw-outline-green-900\/15 {
  outline-color: rgb(20 83 45 / 0.15) !important;
}

.tw-outline-green-900\/20 {
  outline-color: rgb(20 83 45 / 0.2) !important;
}

.tw-outline-green-900\/25 {
  outline-color: rgb(20 83 45 / 0.25) !important;
}

.tw-outline-green-900\/30 {
  outline-color: rgb(20 83 45 / 0.3) !important;
}

.tw-outline-green-900\/35 {
  outline-color: rgb(20 83 45 / 0.35) !important;
}

.tw-outline-green-900\/40 {
  outline-color: rgb(20 83 45 / 0.4) !important;
}

.tw-outline-green-900\/45 {
  outline-color: rgb(20 83 45 / 0.45) !important;
}

.tw-outline-green-900\/5 {
  outline-color: rgb(20 83 45 / 0.05) !important;
}

.tw-outline-green-900\/50 {
  outline-color: rgb(20 83 45 / 0.5) !important;
}

.tw-outline-green-900\/55 {
  outline-color: rgb(20 83 45 / 0.55) !important;
}

.tw-outline-green-900\/60 {
  outline-color: rgb(20 83 45 / 0.6) !important;
}

.tw-outline-green-900\/65 {
  outline-color: rgb(20 83 45 / 0.65) !important;
}

.tw-outline-green-900\/70 {
  outline-color: rgb(20 83 45 / 0.7) !important;
}

.tw-outline-green-900\/75 {
  outline-color: rgb(20 83 45 / 0.75) !important;
}

.tw-outline-green-900\/80 {
  outline-color: rgb(20 83 45 / 0.8) !important;
}

.tw-outline-green-900\/85 {
  outline-color: rgb(20 83 45 / 0.85) !important;
}

.tw-outline-green-900\/90 {
  outline-color: rgb(20 83 45 / 0.9) !important;
}

.tw-outline-green-900\/95 {
  outline-color: rgb(20 83 45 / 0.95) !important;
}

.tw-outline-green-950 {
  outline-color: #052e16 !important;
}

.tw-outline-green-950\/0 {
  outline-color: rgb(5 46 22 / 0) !important;
}

.tw-outline-green-950\/10 {
  outline-color: rgb(5 46 22 / 0.1) !important;
}

.tw-outline-green-950\/100 {
  outline-color: rgb(5 46 22 / 1) !important;
}

.tw-outline-green-950\/15 {
  outline-color: rgb(5 46 22 / 0.15) !important;
}

.tw-outline-green-950\/20 {
  outline-color: rgb(5 46 22 / 0.2) !important;
}

.tw-outline-green-950\/25 {
  outline-color: rgb(5 46 22 / 0.25) !important;
}

.tw-outline-green-950\/30 {
  outline-color: rgb(5 46 22 / 0.3) !important;
}

.tw-outline-green-950\/35 {
  outline-color: rgb(5 46 22 / 0.35) !important;
}

.tw-outline-green-950\/40 {
  outline-color: rgb(5 46 22 / 0.4) !important;
}

.tw-outline-green-950\/45 {
  outline-color: rgb(5 46 22 / 0.45) !important;
}

.tw-outline-green-950\/5 {
  outline-color: rgb(5 46 22 / 0.05) !important;
}

.tw-outline-green-950\/50 {
  outline-color: rgb(5 46 22 / 0.5) !important;
}

.tw-outline-green-950\/55 {
  outline-color: rgb(5 46 22 / 0.55) !important;
}

.tw-outline-green-950\/60 {
  outline-color: rgb(5 46 22 / 0.6) !important;
}

.tw-outline-green-950\/65 {
  outline-color: rgb(5 46 22 / 0.65) !important;
}

.tw-outline-green-950\/70 {
  outline-color: rgb(5 46 22 / 0.7) !important;
}

.tw-outline-green-950\/75 {
  outline-color: rgb(5 46 22 / 0.75) !important;
}

.tw-outline-green-950\/80 {
  outline-color: rgb(5 46 22 / 0.8) !important;
}

.tw-outline-green-950\/85 {
  outline-color: rgb(5 46 22 / 0.85) !important;
}

.tw-outline-green-950\/90 {
  outline-color: rgb(5 46 22 / 0.9) !important;
}

.tw-outline-green-950\/95 {
  outline-color: rgb(5 46 22 / 0.95) !important;
}

.tw-outline-indigo-100 {
  outline-color: #e0e7ff !important;
}

.tw-outline-indigo-100\/0 {
  outline-color: rgb(224 231 255 / 0) !important;
}

.tw-outline-indigo-100\/10 {
  outline-color: rgb(224 231 255 / 0.1) !important;
}

.tw-outline-indigo-100\/100 {
  outline-color: rgb(224 231 255 / 1) !important;
}

.tw-outline-indigo-100\/15 {
  outline-color: rgb(224 231 255 / 0.15) !important;
}

.tw-outline-indigo-100\/20 {
  outline-color: rgb(224 231 255 / 0.2) !important;
}

.tw-outline-indigo-100\/25 {
  outline-color: rgb(224 231 255 / 0.25) !important;
}

.tw-outline-indigo-100\/30 {
  outline-color: rgb(224 231 255 / 0.3) !important;
}

.tw-outline-indigo-100\/35 {
  outline-color: rgb(224 231 255 / 0.35) !important;
}

.tw-outline-indigo-100\/40 {
  outline-color: rgb(224 231 255 / 0.4) !important;
}

.tw-outline-indigo-100\/45 {
  outline-color: rgb(224 231 255 / 0.45) !important;
}

.tw-outline-indigo-100\/5 {
  outline-color: rgb(224 231 255 / 0.05) !important;
}

.tw-outline-indigo-100\/50 {
  outline-color: rgb(224 231 255 / 0.5) !important;
}

.tw-outline-indigo-100\/55 {
  outline-color: rgb(224 231 255 / 0.55) !important;
}

.tw-outline-indigo-100\/60 {
  outline-color: rgb(224 231 255 / 0.6) !important;
}

.tw-outline-indigo-100\/65 {
  outline-color: rgb(224 231 255 / 0.65) !important;
}

.tw-outline-indigo-100\/70 {
  outline-color: rgb(224 231 255 / 0.7) !important;
}

.tw-outline-indigo-100\/75 {
  outline-color: rgb(224 231 255 / 0.75) !important;
}

.tw-outline-indigo-100\/80 {
  outline-color: rgb(224 231 255 / 0.8) !important;
}

.tw-outline-indigo-100\/85 {
  outline-color: rgb(224 231 255 / 0.85) !important;
}

.tw-outline-indigo-100\/90 {
  outline-color: rgb(224 231 255 / 0.9) !important;
}

.tw-outline-indigo-100\/95 {
  outline-color: rgb(224 231 255 / 0.95) !important;
}

.tw-outline-indigo-200 {
  outline-color: #c7d2fe !important;
}

.tw-outline-indigo-200\/0 {
  outline-color: rgb(199 210 254 / 0) !important;
}

.tw-outline-indigo-200\/10 {
  outline-color: rgb(199 210 254 / 0.1) !important;
}

.tw-outline-indigo-200\/100 {
  outline-color: rgb(199 210 254 / 1) !important;
}

.tw-outline-indigo-200\/15 {
  outline-color: rgb(199 210 254 / 0.15) !important;
}

.tw-outline-indigo-200\/20 {
  outline-color: rgb(199 210 254 / 0.2) !important;
}

.tw-outline-indigo-200\/25 {
  outline-color: rgb(199 210 254 / 0.25) !important;
}

.tw-outline-indigo-200\/30 {
  outline-color: rgb(199 210 254 / 0.3) !important;
}

.tw-outline-indigo-200\/35 {
  outline-color: rgb(199 210 254 / 0.35) !important;
}

.tw-outline-indigo-200\/40 {
  outline-color: rgb(199 210 254 / 0.4) !important;
}

.tw-outline-indigo-200\/45 {
  outline-color: rgb(199 210 254 / 0.45) !important;
}

.tw-outline-indigo-200\/5 {
  outline-color: rgb(199 210 254 / 0.05) !important;
}

.tw-outline-indigo-200\/50 {
  outline-color: rgb(199 210 254 / 0.5) !important;
}

.tw-outline-indigo-200\/55 {
  outline-color: rgb(199 210 254 / 0.55) !important;
}

.tw-outline-indigo-200\/60 {
  outline-color: rgb(199 210 254 / 0.6) !important;
}

.tw-outline-indigo-200\/65 {
  outline-color: rgb(199 210 254 / 0.65) !important;
}

.tw-outline-indigo-200\/70 {
  outline-color: rgb(199 210 254 / 0.7) !important;
}

.tw-outline-indigo-200\/75 {
  outline-color: rgb(199 210 254 / 0.75) !important;
}

.tw-outline-indigo-200\/80 {
  outline-color: rgb(199 210 254 / 0.8) !important;
}

.tw-outline-indigo-200\/85 {
  outline-color: rgb(199 210 254 / 0.85) !important;
}

.tw-outline-indigo-200\/90 {
  outline-color: rgb(199 210 254 / 0.9) !important;
}

.tw-outline-indigo-200\/95 {
  outline-color: rgb(199 210 254 / 0.95) !important;
}

.tw-outline-indigo-300 {
  outline-color: #a5b4fc !important;
}

.tw-outline-indigo-300\/0 {
  outline-color: rgb(165 180 252 / 0) !important;
}

.tw-outline-indigo-300\/10 {
  outline-color: rgb(165 180 252 / 0.1) !important;
}

.tw-outline-indigo-300\/100 {
  outline-color: rgb(165 180 252 / 1) !important;
}

.tw-outline-indigo-300\/15 {
  outline-color: rgb(165 180 252 / 0.15) !important;
}

.tw-outline-indigo-300\/20 {
  outline-color: rgb(165 180 252 / 0.2) !important;
}

.tw-outline-indigo-300\/25 {
  outline-color: rgb(165 180 252 / 0.25) !important;
}

.tw-outline-indigo-300\/30 {
  outline-color: rgb(165 180 252 / 0.3) !important;
}

.tw-outline-indigo-300\/35 {
  outline-color: rgb(165 180 252 / 0.35) !important;
}

.tw-outline-indigo-300\/40 {
  outline-color: rgb(165 180 252 / 0.4) !important;
}

.tw-outline-indigo-300\/45 {
  outline-color: rgb(165 180 252 / 0.45) !important;
}

.tw-outline-indigo-300\/5 {
  outline-color: rgb(165 180 252 / 0.05) !important;
}

.tw-outline-indigo-300\/50 {
  outline-color: rgb(165 180 252 / 0.5) !important;
}

.tw-outline-indigo-300\/55 {
  outline-color: rgb(165 180 252 / 0.55) !important;
}

.tw-outline-indigo-300\/60 {
  outline-color: rgb(165 180 252 / 0.6) !important;
}

.tw-outline-indigo-300\/65 {
  outline-color: rgb(165 180 252 / 0.65) !important;
}

.tw-outline-indigo-300\/70 {
  outline-color: rgb(165 180 252 / 0.7) !important;
}

.tw-outline-indigo-300\/75 {
  outline-color: rgb(165 180 252 / 0.75) !important;
}

.tw-outline-indigo-300\/80 {
  outline-color: rgb(165 180 252 / 0.8) !important;
}

.tw-outline-indigo-300\/85 {
  outline-color: rgb(165 180 252 / 0.85) !important;
}

.tw-outline-indigo-300\/90 {
  outline-color: rgb(165 180 252 / 0.9) !important;
}

.tw-outline-indigo-300\/95 {
  outline-color: rgb(165 180 252 / 0.95) !important;
}

.tw-outline-indigo-400 {
  outline-color: #818cf8 !important;
}

.tw-outline-indigo-400\/0 {
  outline-color: rgb(129 140 248 / 0) !important;
}

.tw-outline-indigo-400\/10 {
  outline-color: rgb(129 140 248 / 0.1) !important;
}

.tw-outline-indigo-400\/100 {
  outline-color: rgb(129 140 248 / 1) !important;
}

.tw-outline-indigo-400\/15 {
  outline-color: rgb(129 140 248 / 0.15) !important;
}

.tw-outline-indigo-400\/20 {
  outline-color: rgb(129 140 248 / 0.2) !important;
}

.tw-outline-indigo-400\/25 {
  outline-color: rgb(129 140 248 / 0.25) !important;
}

.tw-outline-indigo-400\/30 {
  outline-color: rgb(129 140 248 / 0.3) !important;
}

.tw-outline-indigo-400\/35 {
  outline-color: rgb(129 140 248 / 0.35) !important;
}

.tw-outline-indigo-400\/40 {
  outline-color: rgb(129 140 248 / 0.4) !important;
}

.tw-outline-indigo-400\/45 {
  outline-color: rgb(129 140 248 / 0.45) !important;
}

.tw-outline-indigo-400\/5 {
  outline-color: rgb(129 140 248 / 0.05) !important;
}

.tw-outline-indigo-400\/50 {
  outline-color: rgb(129 140 248 / 0.5) !important;
}

.tw-outline-indigo-400\/55 {
  outline-color: rgb(129 140 248 / 0.55) !important;
}

.tw-outline-indigo-400\/60 {
  outline-color: rgb(129 140 248 / 0.6) !important;
}

.tw-outline-indigo-400\/65 {
  outline-color: rgb(129 140 248 / 0.65) !important;
}

.tw-outline-indigo-400\/70 {
  outline-color: rgb(129 140 248 / 0.7) !important;
}

.tw-outline-indigo-400\/75 {
  outline-color: rgb(129 140 248 / 0.75) !important;
}

.tw-outline-indigo-400\/80 {
  outline-color: rgb(129 140 248 / 0.8) !important;
}

.tw-outline-indigo-400\/85 {
  outline-color: rgb(129 140 248 / 0.85) !important;
}

.tw-outline-indigo-400\/90 {
  outline-color: rgb(129 140 248 / 0.9) !important;
}

.tw-outline-indigo-400\/95 {
  outline-color: rgb(129 140 248 / 0.95) !important;
}

.tw-outline-indigo-50 {
  outline-color: #eef2ff !important;
}

.tw-outline-indigo-50\/0 {
  outline-color: rgb(238 242 255 / 0) !important;
}

.tw-outline-indigo-50\/10 {
  outline-color: rgb(238 242 255 / 0.1) !important;
}

.tw-outline-indigo-50\/100 {
  outline-color: rgb(238 242 255 / 1) !important;
}

.tw-outline-indigo-50\/15 {
  outline-color: rgb(238 242 255 / 0.15) !important;
}

.tw-outline-indigo-50\/20 {
  outline-color: rgb(238 242 255 / 0.2) !important;
}

.tw-outline-indigo-50\/25 {
  outline-color: rgb(238 242 255 / 0.25) !important;
}

.tw-outline-indigo-50\/30 {
  outline-color: rgb(238 242 255 / 0.3) !important;
}

.tw-outline-indigo-50\/35 {
  outline-color: rgb(238 242 255 / 0.35) !important;
}

.tw-outline-indigo-50\/40 {
  outline-color: rgb(238 242 255 / 0.4) !important;
}

.tw-outline-indigo-50\/45 {
  outline-color: rgb(238 242 255 / 0.45) !important;
}

.tw-outline-indigo-50\/5 {
  outline-color: rgb(238 242 255 / 0.05) !important;
}

.tw-outline-indigo-50\/50 {
  outline-color: rgb(238 242 255 / 0.5) !important;
}

.tw-outline-indigo-50\/55 {
  outline-color: rgb(238 242 255 / 0.55) !important;
}

.tw-outline-indigo-50\/60 {
  outline-color: rgb(238 242 255 / 0.6) !important;
}

.tw-outline-indigo-50\/65 {
  outline-color: rgb(238 242 255 / 0.65) !important;
}

.tw-outline-indigo-50\/70 {
  outline-color: rgb(238 242 255 / 0.7) !important;
}

.tw-outline-indigo-50\/75 {
  outline-color: rgb(238 242 255 / 0.75) !important;
}

.tw-outline-indigo-50\/80 {
  outline-color: rgb(238 242 255 / 0.8) !important;
}

.tw-outline-indigo-50\/85 {
  outline-color: rgb(238 242 255 / 0.85) !important;
}

.tw-outline-indigo-50\/90 {
  outline-color: rgb(238 242 255 / 0.9) !important;
}

.tw-outline-indigo-50\/95 {
  outline-color: rgb(238 242 255 / 0.95) !important;
}

.tw-outline-indigo-500 {
  outline-color: #6366f1 !important;
}

.tw-outline-indigo-500\/0 {
  outline-color: rgb(99 102 241 / 0) !important;
}

.tw-outline-indigo-500\/10 {
  outline-color: rgb(99 102 241 / 0.1) !important;
}

.tw-outline-indigo-500\/100 {
  outline-color: rgb(99 102 241 / 1) !important;
}

.tw-outline-indigo-500\/15 {
  outline-color: rgb(99 102 241 / 0.15) !important;
}

.tw-outline-indigo-500\/20 {
  outline-color: rgb(99 102 241 / 0.2) !important;
}

.tw-outline-indigo-500\/25 {
  outline-color: rgb(99 102 241 / 0.25) !important;
}

.tw-outline-indigo-500\/30 {
  outline-color: rgb(99 102 241 / 0.3) !important;
}

.tw-outline-indigo-500\/35 {
  outline-color: rgb(99 102 241 / 0.35) !important;
}

.tw-outline-indigo-500\/40 {
  outline-color: rgb(99 102 241 / 0.4) !important;
}

.tw-outline-indigo-500\/45 {
  outline-color: rgb(99 102 241 / 0.45) !important;
}

.tw-outline-indigo-500\/5 {
  outline-color: rgb(99 102 241 / 0.05) !important;
}

.tw-outline-indigo-500\/50 {
  outline-color: rgb(99 102 241 / 0.5) !important;
}

.tw-outline-indigo-500\/55 {
  outline-color: rgb(99 102 241 / 0.55) !important;
}

.tw-outline-indigo-500\/60 {
  outline-color: rgb(99 102 241 / 0.6) !important;
}

.tw-outline-indigo-500\/65 {
  outline-color: rgb(99 102 241 / 0.65) !important;
}

.tw-outline-indigo-500\/70 {
  outline-color: rgb(99 102 241 / 0.7) !important;
}

.tw-outline-indigo-500\/75 {
  outline-color: rgb(99 102 241 / 0.75) !important;
}

.tw-outline-indigo-500\/80 {
  outline-color: rgb(99 102 241 / 0.8) !important;
}

.tw-outline-indigo-500\/85 {
  outline-color: rgb(99 102 241 / 0.85) !important;
}

.tw-outline-indigo-500\/90 {
  outline-color: rgb(99 102 241 / 0.9) !important;
}

.tw-outline-indigo-500\/95 {
  outline-color: rgb(99 102 241 / 0.95) !important;
}

.tw-outline-indigo-600 {
  outline-color: #4f46e5 !important;
}

.tw-outline-indigo-600\/0 {
  outline-color: rgb(79 70 229 / 0) !important;
}

.tw-outline-indigo-600\/10 {
  outline-color: rgb(79 70 229 / 0.1) !important;
}

.tw-outline-indigo-600\/100 {
  outline-color: rgb(79 70 229 / 1) !important;
}

.tw-outline-indigo-600\/15 {
  outline-color: rgb(79 70 229 / 0.15) !important;
}

.tw-outline-indigo-600\/20 {
  outline-color: rgb(79 70 229 / 0.2) !important;
}

.tw-outline-indigo-600\/25 {
  outline-color: rgb(79 70 229 / 0.25) !important;
}

.tw-outline-indigo-600\/30 {
  outline-color: rgb(79 70 229 / 0.3) !important;
}

.tw-outline-indigo-600\/35 {
  outline-color: rgb(79 70 229 / 0.35) !important;
}

.tw-outline-indigo-600\/40 {
  outline-color: rgb(79 70 229 / 0.4) !important;
}

.tw-outline-indigo-600\/45 {
  outline-color: rgb(79 70 229 / 0.45) !important;
}

.tw-outline-indigo-600\/5 {
  outline-color: rgb(79 70 229 / 0.05) !important;
}

.tw-outline-indigo-600\/50 {
  outline-color: rgb(79 70 229 / 0.5) !important;
}

.tw-outline-indigo-600\/55 {
  outline-color: rgb(79 70 229 / 0.55) !important;
}

.tw-outline-indigo-600\/60 {
  outline-color: rgb(79 70 229 / 0.6) !important;
}

.tw-outline-indigo-600\/65 {
  outline-color: rgb(79 70 229 / 0.65) !important;
}

.tw-outline-indigo-600\/70 {
  outline-color: rgb(79 70 229 / 0.7) !important;
}

.tw-outline-indigo-600\/75 {
  outline-color: rgb(79 70 229 / 0.75) !important;
}

.tw-outline-indigo-600\/80 {
  outline-color: rgb(79 70 229 / 0.8) !important;
}

.tw-outline-indigo-600\/85 {
  outline-color: rgb(79 70 229 / 0.85) !important;
}

.tw-outline-indigo-600\/90 {
  outline-color: rgb(79 70 229 / 0.9) !important;
}

.tw-outline-indigo-600\/95 {
  outline-color: rgb(79 70 229 / 0.95) !important;
}

.tw-outline-indigo-700 {
  outline-color: #4338ca !important;
}

.tw-outline-indigo-700\/0 {
  outline-color: rgb(67 56 202 / 0) !important;
}

.tw-outline-indigo-700\/10 {
  outline-color: rgb(67 56 202 / 0.1) !important;
}

.tw-outline-indigo-700\/100 {
  outline-color: rgb(67 56 202 / 1) !important;
}

.tw-outline-indigo-700\/15 {
  outline-color: rgb(67 56 202 / 0.15) !important;
}

.tw-outline-indigo-700\/20 {
  outline-color: rgb(67 56 202 / 0.2) !important;
}

.tw-outline-indigo-700\/25 {
  outline-color: rgb(67 56 202 / 0.25) !important;
}

.tw-outline-indigo-700\/30 {
  outline-color: rgb(67 56 202 / 0.3) !important;
}

.tw-outline-indigo-700\/35 {
  outline-color: rgb(67 56 202 / 0.35) !important;
}

.tw-outline-indigo-700\/40 {
  outline-color: rgb(67 56 202 / 0.4) !important;
}

.tw-outline-indigo-700\/45 {
  outline-color: rgb(67 56 202 / 0.45) !important;
}

.tw-outline-indigo-700\/5 {
  outline-color: rgb(67 56 202 / 0.05) !important;
}

.tw-outline-indigo-700\/50 {
  outline-color: rgb(67 56 202 / 0.5) !important;
}

.tw-outline-indigo-700\/55 {
  outline-color: rgb(67 56 202 / 0.55) !important;
}

.tw-outline-indigo-700\/60 {
  outline-color: rgb(67 56 202 / 0.6) !important;
}

.tw-outline-indigo-700\/65 {
  outline-color: rgb(67 56 202 / 0.65) !important;
}

.tw-outline-indigo-700\/70 {
  outline-color: rgb(67 56 202 / 0.7) !important;
}

.tw-outline-indigo-700\/75 {
  outline-color: rgb(67 56 202 / 0.75) !important;
}

.tw-outline-indigo-700\/80 {
  outline-color: rgb(67 56 202 / 0.8) !important;
}

.tw-outline-indigo-700\/85 {
  outline-color: rgb(67 56 202 / 0.85) !important;
}

.tw-outline-indigo-700\/90 {
  outline-color: rgb(67 56 202 / 0.9) !important;
}

.tw-outline-indigo-700\/95 {
  outline-color: rgb(67 56 202 / 0.95) !important;
}

.tw-outline-indigo-800 {
  outline-color: #3730a3 !important;
}

.tw-outline-indigo-800\/0 {
  outline-color: rgb(55 48 163 / 0) !important;
}

.tw-outline-indigo-800\/10 {
  outline-color: rgb(55 48 163 / 0.1) !important;
}

.tw-outline-indigo-800\/100 {
  outline-color: rgb(55 48 163 / 1) !important;
}

.tw-outline-indigo-800\/15 {
  outline-color: rgb(55 48 163 / 0.15) !important;
}

.tw-outline-indigo-800\/20 {
  outline-color: rgb(55 48 163 / 0.2) !important;
}

.tw-outline-indigo-800\/25 {
  outline-color: rgb(55 48 163 / 0.25) !important;
}

.tw-outline-indigo-800\/30 {
  outline-color: rgb(55 48 163 / 0.3) !important;
}

.tw-outline-indigo-800\/35 {
  outline-color: rgb(55 48 163 / 0.35) !important;
}

.tw-outline-indigo-800\/40 {
  outline-color: rgb(55 48 163 / 0.4) !important;
}

.tw-outline-indigo-800\/45 {
  outline-color: rgb(55 48 163 / 0.45) !important;
}

.tw-outline-indigo-800\/5 {
  outline-color: rgb(55 48 163 / 0.05) !important;
}

.tw-outline-indigo-800\/50 {
  outline-color: rgb(55 48 163 / 0.5) !important;
}

.tw-outline-indigo-800\/55 {
  outline-color: rgb(55 48 163 / 0.55) !important;
}

.tw-outline-indigo-800\/60 {
  outline-color: rgb(55 48 163 / 0.6) !important;
}

.tw-outline-indigo-800\/65 {
  outline-color: rgb(55 48 163 / 0.65) !important;
}

.tw-outline-indigo-800\/70 {
  outline-color: rgb(55 48 163 / 0.7) !important;
}

.tw-outline-indigo-800\/75 {
  outline-color: rgb(55 48 163 / 0.75) !important;
}

.tw-outline-indigo-800\/80 {
  outline-color: rgb(55 48 163 / 0.8) !important;
}

.tw-outline-indigo-800\/85 {
  outline-color: rgb(55 48 163 / 0.85) !important;
}

.tw-outline-indigo-800\/90 {
  outline-color: rgb(55 48 163 / 0.9) !important;
}

.tw-outline-indigo-800\/95 {
  outline-color: rgb(55 48 163 / 0.95) !important;
}

.tw-outline-indigo-900 {
  outline-color: #312e81 !important;
}

.tw-outline-indigo-900\/0 {
  outline-color: rgb(49 46 129 / 0) !important;
}

.tw-outline-indigo-900\/10 {
  outline-color: rgb(49 46 129 / 0.1) !important;
}

.tw-outline-indigo-900\/100 {
  outline-color: rgb(49 46 129 / 1) !important;
}

.tw-outline-indigo-900\/15 {
  outline-color: rgb(49 46 129 / 0.15) !important;
}

.tw-outline-indigo-900\/20 {
  outline-color: rgb(49 46 129 / 0.2) !important;
}

.tw-outline-indigo-900\/25 {
  outline-color: rgb(49 46 129 / 0.25) !important;
}

.tw-outline-indigo-900\/30 {
  outline-color: rgb(49 46 129 / 0.3) !important;
}

.tw-outline-indigo-900\/35 {
  outline-color: rgb(49 46 129 / 0.35) !important;
}

.tw-outline-indigo-900\/40 {
  outline-color: rgb(49 46 129 / 0.4) !important;
}

.tw-outline-indigo-900\/45 {
  outline-color: rgb(49 46 129 / 0.45) !important;
}

.tw-outline-indigo-900\/5 {
  outline-color: rgb(49 46 129 / 0.05) !important;
}

.tw-outline-indigo-900\/50 {
  outline-color: rgb(49 46 129 / 0.5) !important;
}

.tw-outline-indigo-900\/55 {
  outline-color: rgb(49 46 129 / 0.55) !important;
}

.tw-outline-indigo-900\/60 {
  outline-color: rgb(49 46 129 / 0.6) !important;
}

.tw-outline-indigo-900\/65 {
  outline-color: rgb(49 46 129 / 0.65) !important;
}

.tw-outline-indigo-900\/70 {
  outline-color: rgb(49 46 129 / 0.7) !important;
}

.tw-outline-indigo-900\/75 {
  outline-color: rgb(49 46 129 / 0.75) !important;
}

.tw-outline-indigo-900\/80 {
  outline-color: rgb(49 46 129 / 0.8) !important;
}

.tw-outline-indigo-900\/85 {
  outline-color: rgb(49 46 129 / 0.85) !important;
}

.tw-outline-indigo-900\/90 {
  outline-color: rgb(49 46 129 / 0.9) !important;
}

.tw-outline-indigo-900\/95 {
  outline-color: rgb(49 46 129 / 0.95) !important;
}

.tw-outline-indigo-950 {
  outline-color: #1e1b4b !important;
}

.tw-outline-indigo-950\/0 {
  outline-color: rgb(30 27 75 / 0) !important;
}

.tw-outline-indigo-950\/10 {
  outline-color: rgb(30 27 75 / 0.1) !important;
}

.tw-outline-indigo-950\/100 {
  outline-color: rgb(30 27 75 / 1) !important;
}

.tw-outline-indigo-950\/15 {
  outline-color: rgb(30 27 75 / 0.15) !important;
}

.tw-outline-indigo-950\/20 {
  outline-color: rgb(30 27 75 / 0.2) !important;
}

.tw-outline-indigo-950\/25 {
  outline-color: rgb(30 27 75 / 0.25) !important;
}

.tw-outline-indigo-950\/30 {
  outline-color: rgb(30 27 75 / 0.3) !important;
}

.tw-outline-indigo-950\/35 {
  outline-color: rgb(30 27 75 / 0.35) !important;
}

.tw-outline-indigo-950\/40 {
  outline-color: rgb(30 27 75 / 0.4) !important;
}

.tw-outline-indigo-950\/45 {
  outline-color: rgb(30 27 75 / 0.45) !important;
}

.tw-outline-indigo-950\/5 {
  outline-color: rgb(30 27 75 / 0.05) !important;
}

.tw-outline-indigo-950\/50 {
  outline-color: rgb(30 27 75 / 0.5) !important;
}

.tw-outline-indigo-950\/55 {
  outline-color: rgb(30 27 75 / 0.55) !important;
}

.tw-outline-indigo-950\/60 {
  outline-color: rgb(30 27 75 / 0.6) !important;
}

.tw-outline-indigo-950\/65 {
  outline-color: rgb(30 27 75 / 0.65) !important;
}

.tw-outline-indigo-950\/70 {
  outline-color: rgb(30 27 75 / 0.7) !important;
}

.tw-outline-indigo-950\/75 {
  outline-color: rgb(30 27 75 / 0.75) !important;
}

.tw-outline-indigo-950\/80 {
  outline-color: rgb(30 27 75 / 0.8) !important;
}

.tw-outline-indigo-950\/85 {
  outline-color: rgb(30 27 75 / 0.85) !important;
}

.tw-outline-indigo-950\/90 {
  outline-color: rgb(30 27 75 / 0.9) !important;
}

.tw-outline-indigo-950\/95 {
  outline-color: rgb(30 27 75 / 0.95) !important;
}

.tw-outline-inherit {
  outline-color: inherit !important;
}

.tw-outline-lime-100 {
  outline-color: #ecfccb !important;
}

.tw-outline-lime-100\/0 {
  outline-color: rgb(236 252 203 / 0) !important;
}

.tw-outline-lime-100\/10 {
  outline-color: rgb(236 252 203 / 0.1) !important;
}

.tw-outline-lime-100\/100 {
  outline-color: rgb(236 252 203 / 1) !important;
}

.tw-outline-lime-100\/15 {
  outline-color: rgb(236 252 203 / 0.15) !important;
}

.tw-outline-lime-100\/20 {
  outline-color: rgb(236 252 203 / 0.2) !important;
}

.tw-outline-lime-100\/25 {
  outline-color: rgb(236 252 203 / 0.25) !important;
}

.tw-outline-lime-100\/30 {
  outline-color: rgb(236 252 203 / 0.3) !important;
}

.tw-outline-lime-100\/35 {
  outline-color: rgb(236 252 203 / 0.35) !important;
}

.tw-outline-lime-100\/40 {
  outline-color: rgb(236 252 203 / 0.4) !important;
}

.tw-outline-lime-100\/45 {
  outline-color: rgb(236 252 203 / 0.45) !important;
}

.tw-outline-lime-100\/5 {
  outline-color: rgb(236 252 203 / 0.05) !important;
}

.tw-outline-lime-100\/50 {
  outline-color: rgb(236 252 203 / 0.5) !important;
}

.tw-outline-lime-100\/55 {
  outline-color: rgb(236 252 203 / 0.55) !important;
}

.tw-outline-lime-100\/60 {
  outline-color: rgb(236 252 203 / 0.6) !important;
}

.tw-outline-lime-100\/65 {
  outline-color: rgb(236 252 203 / 0.65) !important;
}

.tw-outline-lime-100\/70 {
  outline-color: rgb(236 252 203 / 0.7) !important;
}

.tw-outline-lime-100\/75 {
  outline-color: rgb(236 252 203 / 0.75) !important;
}

.tw-outline-lime-100\/80 {
  outline-color: rgb(236 252 203 / 0.8) !important;
}

.tw-outline-lime-100\/85 {
  outline-color: rgb(236 252 203 / 0.85) !important;
}

.tw-outline-lime-100\/90 {
  outline-color: rgb(236 252 203 / 0.9) !important;
}

.tw-outline-lime-100\/95 {
  outline-color: rgb(236 252 203 / 0.95) !important;
}

.tw-outline-lime-200 {
  outline-color: #d9f99d !important;
}

.tw-outline-lime-200\/0 {
  outline-color: rgb(217 249 157 / 0) !important;
}

.tw-outline-lime-200\/10 {
  outline-color: rgb(217 249 157 / 0.1) !important;
}

.tw-outline-lime-200\/100 {
  outline-color: rgb(217 249 157 / 1) !important;
}

.tw-outline-lime-200\/15 {
  outline-color: rgb(217 249 157 / 0.15) !important;
}

.tw-outline-lime-200\/20 {
  outline-color: rgb(217 249 157 / 0.2) !important;
}

.tw-outline-lime-200\/25 {
  outline-color: rgb(217 249 157 / 0.25) !important;
}

.tw-outline-lime-200\/30 {
  outline-color: rgb(217 249 157 / 0.3) !important;
}

.tw-outline-lime-200\/35 {
  outline-color: rgb(217 249 157 / 0.35) !important;
}

.tw-outline-lime-200\/40 {
  outline-color: rgb(217 249 157 / 0.4) !important;
}

.tw-outline-lime-200\/45 {
  outline-color: rgb(217 249 157 / 0.45) !important;
}

.tw-outline-lime-200\/5 {
  outline-color: rgb(217 249 157 / 0.05) !important;
}

.tw-outline-lime-200\/50 {
  outline-color: rgb(217 249 157 / 0.5) !important;
}

.tw-outline-lime-200\/55 {
  outline-color: rgb(217 249 157 / 0.55) !important;
}

.tw-outline-lime-200\/60 {
  outline-color: rgb(217 249 157 / 0.6) !important;
}

.tw-outline-lime-200\/65 {
  outline-color: rgb(217 249 157 / 0.65) !important;
}

.tw-outline-lime-200\/70 {
  outline-color: rgb(217 249 157 / 0.7) !important;
}

.tw-outline-lime-200\/75 {
  outline-color: rgb(217 249 157 / 0.75) !important;
}

.tw-outline-lime-200\/80 {
  outline-color: rgb(217 249 157 / 0.8) !important;
}

.tw-outline-lime-200\/85 {
  outline-color: rgb(217 249 157 / 0.85) !important;
}

.tw-outline-lime-200\/90 {
  outline-color: rgb(217 249 157 / 0.9) !important;
}

.tw-outline-lime-200\/95 {
  outline-color: rgb(217 249 157 / 0.95) !important;
}

.tw-outline-lime-300 {
  outline-color: #bef264 !important;
}

.tw-outline-lime-300\/0 {
  outline-color: rgb(190 242 100 / 0) !important;
}

.tw-outline-lime-300\/10 {
  outline-color: rgb(190 242 100 / 0.1) !important;
}

.tw-outline-lime-300\/100 {
  outline-color: rgb(190 242 100 / 1) !important;
}

.tw-outline-lime-300\/15 {
  outline-color: rgb(190 242 100 / 0.15) !important;
}

.tw-outline-lime-300\/20 {
  outline-color: rgb(190 242 100 / 0.2) !important;
}

.tw-outline-lime-300\/25 {
  outline-color: rgb(190 242 100 / 0.25) !important;
}

.tw-outline-lime-300\/30 {
  outline-color: rgb(190 242 100 / 0.3) !important;
}

.tw-outline-lime-300\/35 {
  outline-color: rgb(190 242 100 / 0.35) !important;
}

.tw-outline-lime-300\/40 {
  outline-color: rgb(190 242 100 / 0.4) !important;
}

.tw-outline-lime-300\/45 {
  outline-color: rgb(190 242 100 / 0.45) !important;
}

.tw-outline-lime-300\/5 {
  outline-color: rgb(190 242 100 / 0.05) !important;
}

.tw-outline-lime-300\/50 {
  outline-color: rgb(190 242 100 / 0.5) !important;
}

.tw-outline-lime-300\/55 {
  outline-color: rgb(190 242 100 / 0.55) !important;
}

.tw-outline-lime-300\/60 {
  outline-color: rgb(190 242 100 / 0.6) !important;
}

.tw-outline-lime-300\/65 {
  outline-color: rgb(190 242 100 / 0.65) !important;
}

.tw-outline-lime-300\/70 {
  outline-color: rgb(190 242 100 / 0.7) !important;
}

.tw-outline-lime-300\/75 {
  outline-color: rgb(190 242 100 / 0.75) !important;
}

.tw-outline-lime-300\/80 {
  outline-color: rgb(190 242 100 / 0.8) !important;
}

.tw-outline-lime-300\/85 {
  outline-color: rgb(190 242 100 / 0.85) !important;
}

.tw-outline-lime-300\/90 {
  outline-color: rgb(190 242 100 / 0.9) !important;
}

.tw-outline-lime-300\/95 {
  outline-color: rgb(190 242 100 / 0.95) !important;
}

.tw-outline-lime-400 {
  outline-color: #a3e635 !important;
}

.tw-outline-lime-400\/0 {
  outline-color: rgb(163 230 53 / 0) !important;
}

.tw-outline-lime-400\/10 {
  outline-color: rgb(163 230 53 / 0.1) !important;
}

.tw-outline-lime-400\/100 {
  outline-color: rgb(163 230 53 / 1) !important;
}

.tw-outline-lime-400\/15 {
  outline-color: rgb(163 230 53 / 0.15) !important;
}

.tw-outline-lime-400\/20 {
  outline-color: rgb(163 230 53 / 0.2) !important;
}

.tw-outline-lime-400\/25 {
  outline-color: rgb(163 230 53 / 0.25) !important;
}

.tw-outline-lime-400\/30 {
  outline-color: rgb(163 230 53 / 0.3) !important;
}

.tw-outline-lime-400\/35 {
  outline-color: rgb(163 230 53 / 0.35) !important;
}

.tw-outline-lime-400\/40 {
  outline-color: rgb(163 230 53 / 0.4) !important;
}

.tw-outline-lime-400\/45 {
  outline-color: rgb(163 230 53 / 0.45) !important;
}

.tw-outline-lime-400\/5 {
  outline-color: rgb(163 230 53 / 0.05) !important;
}

.tw-outline-lime-400\/50 {
  outline-color: rgb(163 230 53 / 0.5) !important;
}

.tw-outline-lime-400\/55 {
  outline-color: rgb(163 230 53 / 0.55) !important;
}

.tw-outline-lime-400\/60 {
  outline-color: rgb(163 230 53 / 0.6) !important;
}

.tw-outline-lime-400\/65 {
  outline-color: rgb(163 230 53 / 0.65) !important;
}

.tw-outline-lime-400\/70 {
  outline-color: rgb(163 230 53 / 0.7) !important;
}

.tw-outline-lime-400\/75 {
  outline-color: rgb(163 230 53 / 0.75) !important;
}

.tw-outline-lime-400\/80 {
  outline-color: rgb(163 230 53 / 0.8) !important;
}

.tw-outline-lime-400\/85 {
  outline-color: rgb(163 230 53 / 0.85) !important;
}

.tw-outline-lime-400\/90 {
  outline-color: rgb(163 230 53 / 0.9) !important;
}

.tw-outline-lime-400\/95 {
  outline-color: rgb(163 230 53 / 0.95) !important;
}

.tw-outline-lime-50 {
  outline-color: #f7fee7 !important;
}

.tw-outline-lime-50\/0 {
  outline-color: rgb(247 254 231 / 0) !important;
}

.tw-outline-lime-50\/10 {
  outline-color: rgb(247 254 231 / 0.1) !important;
}

.tw-outline-lime-50\/100 {
  outline-color: rgb(247 254 231 / 1) !important;
}

.tw-outline-lime-50\/15 {
  outline-color: rgb(247 254 231 / 0.15) !important;
}

.tw-outline-lime-50\/20 {
  outline-color: rgb(247 254 231 / 0.2) !important;
}

.tw-outline-lime-50\/25 {
  outline-color: rgb(247 254 231 / 0.25) !important;
}

.tw-outline-lime-50\/30 {
  outline-color: rgb(247 254 231 / 0.3) !important;
}

.tw-outline-lime-50\/35 {
  outline-color: rgb(247 254 231 / 0.35) !important;
}

.tw-outline-lime-50\/40 {
  outline-color: rgb(247 254 231 / 0.4) !important;
}

.tw-outline-lime-50\/45 {
  outline-color: rgb(247 254 231 / 0.45) !important;
}

.tw-outline-lime-50\/5 {
  outline-color: rgb(247 254 231 / 0.05) !important;
}

.tw-outline-lime-50\/50 {
  outline-color: rgb(247 254 231 / 0.5) !important;
}

.tw-outline-lime-50\/55 {
  outline-color: rgb(247 254 231 / 0.55) !important;
}

.tw-outline-lime-50\/60 {
  outline-color: rgb(247 254 231 / 0.6) !important;
}

.tw-outline-lime-50\/65 {
  outline-color: rgb(247 254 231 / 0.65) !important;
}

.tw-outline-lime-50\/70 {
  outline-color: rgb(247 254 231 / 0.7) !important;
}

.tw-outline-lime-50\/75 {
  outline-color: rgb(247 254 231 / 0.75) !important;
}

.tw-outline-lime-50\/80 {
  outline-color: rgb(247 254 231 / 0.8) !important;
}

.tw-outline-lime-50\/85 {
  outline-color: rgb(247 254 231 / 0.85) !important;
}

.tw-outline-lime-50\/90 {
  outline-color: rgb(247 254 231 / 0.9) !important;
}

.tw-outline-lime-50\/95 {
  outline-color: rgb(247 254 231 / 0.95) !important;
}

.tw-outline-lime-500 {
  outline-color: #84cc16 !important;
}

.tw-outline-lime-500\/0 {
  outline-color: rgb(132 204 22 / 0) !important;
}

.tw-outline-lime-500\/10 {
  outline-color: rgb(132 204 22 / 0.1) !important;
}

.tw-outline-lime-500\/100 {
  outline-color: rgb(132 204 22 / 1) !important;
}

.tw-outline-lime-500\/15 {
  outline-color: rgb(132 204 22 / 0.15) !important;
}

.tw-outline-lime-500\/20 {
  outline-color: rgb(132 204 22 / 0.2) !important;
}

.tw-outline-lime-500\/25 {
  outline-color: rgb(132 204 22 / 0.25) !important;
}

.tw-outline-lime-500\/30 {
  outline-color: rgb(132 204 22 / 0.3) !important;
}

.tw-outline-lime-500\/35 {
  outline-color: rgb(132 204 22 / 0.35) !important;
}

.tw-outline-lime-500\/40 {
  outline-color: rgb(132 204 22 / 0.4) !important;
}

.tw-outline-lime-500\/45 {
  outline-color: rgb(132 204 22 / 0.45) !important;
}

.tw-outline-lime-500\/5 {
  outline-color: rgb(132 204 22 / 0.05) !important;
}

.tw-outline-lime-500\/50 {
  outline-color: rgb(132 204 22 / 0.5) !important;
}

.tw-outline-lime-500\/55 {
  outline-color: rgb(132 204 22 / 0.55) !important;
}

.tw-outline-lime-500\/60 {
  outline-color: rgb(132 204 22 / 0.6) !important;
}

.tw-outline-lime-500\/65 {
  outline-color: rgb(132 204 22 / 0.65) !important;
}

.tw-outline-lime-500\/70 {
  outline-color: rgb(132 204 22 / 0.7) !important;
}

.tw-outline-lime-500\/75 {
  outline-color: rgb(132 204 22 / 0.75) !important;
}

.tw-outline-lime-500\/80 {
  outline-color: rgb(132 204 22 / 0.8) !important;
}

.tw-outline-lime-500\/85 {
  outline-color: rgb(132 204 22 / 0.85) !important;
}

.tw-outline-lime-500\/90 {
  outline-color: rgb(132 204 22 / 0.9) !important;
}

.tw-outline-lime-500\/95 {
  outline-color: rgb(132 204 22 / 0.95) !important;
}

.tw-outline-lime-600 {
  outline-color: #65a30d !important;
}

.tw-outline-lime-600\/0 {
  outline-color: rgb(101 163 13 / 0) !important;
}

.tw-outline-lime-600\/10 {
  outline-color: rgb(101 163 13 / 0.1) !important;
}

.tw-outline-lime-600\/100 {
  outline-color: rgb(101 163 13 / 1) !important;
}

.tw-outline-lime-600\/15 {
  outline-color: rgb(101 163 13 / 0.15) !important;
}

.tw-outline-lime-600\/20 {
  outline-color: rgb(101 163 13 / 0.2) !important;
}

.tw-outline-lime-600\/25 {
  outline-color: rgb(101 163 13 / 0.25) !important;
}

.tw-outline-lime-600\/30 {
  outline-color: rgb(101 163 13 / 0.3) !important;
}

.tw-outline-lime-600\/35 {
  outline-color: rgb(101 163 13 / 0.35) !important;
}

.tw-outline-lime-600\/40 {
  outline-color: rgb(101 163 13 / 0.4) !important;
}

.tw-outline-lime-600\/45 {
  outline-color: rgb(101 163 13 / 0.45) !important;
}

.tw-outline-lime-600\/5 {
  outline-color: rgb(101 163 13 / 0.05) !important;
}

.tw-outline-lime-600\/50 {
  outline-color: rgb(101 163 13 / 0.5) !important;
}

.tw-outline-lime-600\/55 {
  outline-color: rgb(101 163 13 / 0.55) !important;
}

.tw-outline-lime-600\/60 {
  outline-color: rgb(101 163 13 / 0.6) !important;
}

.tw-outline-lime-600\/65 {
  outline-color: rgb(101 163 13 / 0.65) !important;
}

.tw-outline-lime-600\/70 {
  outline-color: rgb(101 163 13 / 0.7) !important;
}

.tw-outline-lime-600\/75 {
  outline-color: rgb(101 163 13 / 0.75) !important;
}

.tw-outline-lime-600\/80 {
  outline-color: rgb(101 163 13 / 0.8) !important;
}

.tw-outline-lime-600\/85 {
  outline-color: rgb(101 163 13 / 0.85) !important;
}

.tw-outline-lime-600\/90 {
  outline-color: rgb(101 163 13 / 0.9) !important;
}

.tw-outline-lime-600\/95 {
  outline-color: rgb(101 163 13 / 0.95) !important;
}

.tw-outline-lime-700 {
  outline-color: #4d7c0f !important;
}

.tw-outline-lime-700\/0 {
  outline-color: rgb(77 124 15 / 0) !important;
}

.tw-outline-lime-700\/10 {
  outline-color: rgb(77 124 15 / 0.1) !important;
}

.tw-outline-lime-700\/100 {
  outline-color: rgb(77 124 15 / 1) !important;
}

.tw-outline-lime-700\/15 {
  outline-color: rgb(77 124 15 / 0.15) !important;
}

.tw-outline-lime-700\/20 {
  outline-color: rgb(77 124 15 / 0.2) !important;
}

.tw-outline-lime-700\/25 {
  outline-color: rgb(77 124 15 / 0.25) !important;
}

.tw-outline-lime-700\/30 {
  outline-color: rgb(77 124 15 / 0.3) !important;
}

.tw-outline-lime-700\/35 {
  outline-color: rgb(77 124 15 / 0.35) !important;
}

.tw-outline-lime-700\/40 {
  outline-color: rgb(77 124 15 / 0.4) !important;
}

.tw-outline-lime-700\/45 {
  outline-color: rgb(77 124 15 / 0.45) !important;
}

.tw-outline-lime-700\/5 {
  outline-color: rgb(77 124 15 / 0.05) !important;
}

.tw-outline-lime-700\/50 {
  outline-color: rgb(77 124 15 / 0.5) !important;
}

.tw-outline-lime-700\/55 {
  outline-color: rgb(77 124 15 / 0.55) !important;
}

.tw-outline-lime-700\/60 {
  outline-color: rgb(77 124 15 / 0.6) !important;
}

.tw-outline-lime-700\/65 {
  outline-color: rgb(77 124 15 / 0.65) !important;
}

.tw-outline-lime-700\/70 {
  outline-color: rgb(77 124 15 / 0.7) !important;
}

.tw-outline-lime-700\/75 {
  outline-color: rgb(77 124 15 / 0.75) !important;
}

.tw-outline-lime-700\/80 {
  outline-color: rgb(77 124 15 / 0.8) !important;
}

.tw-outline-lime-700\/85 {
  outline-color: rgb(77 124 15 / 0.85) !important;
}

.tw-outline-lime-700\/90 {
  outline-color: rgb(77 124 15 / 0.9) !important;
}

.tw-outline-lime-700\/95 {
  outline-color: rgb(77 124 15 / 0.95) !important;
}

.tw-outline-lime-800 {
  outline-color: #3f6212 !important;
}

.tw-outline-lime-800\/0 {
  outline-color: rgb(63 98 18 / 0) !important;
}

.tw-outline-lime-800\/10 {
  outline-color: rgb(63 98 18 / 0.1) !important;
}

.tw-outline-lime-800\/100 {
  outline-color: rgb(63 98 18 / 1) !important;
}

.tw-outline-lime-800\/15 {
  outline-color: rgb(63 98 18 / 0.15) !important;
}

.tw-outline-lime-800\/20 {
  outline-color: rgb(63 98 18 / 0.2) !important;
}

.tw-outline-lime-800\/25 {
  outline-color: rgb(63 98 18 / 0.25) !important;
}

.tw-outline-lime-800\/30 {
  outline-color: rgb(63 98 18 / 0.3) !important;
}

.tw-outline-lime-800\/35 {
  outline-color: rgb(63 98 18 / 0.35) !important;
}

.tw-outline-lime-800\/40 {
  outline-color: rgb(63 98 18 / 0.4) !important;
}

.tw-outline-lime-800\/45 {
  outline-color: rgb(63 98 18 / 0.45) !important;
}

.tw-outline-lime-800\/5 {
  outline-color: rgb(63 98 18 / 0.05) !important;
}

.tw-outline-lime-800\/50 {
  outline-color: rgb(63 98 18 / 0.5) !important;
}

.tw-outline-lime-800\/55 {
  outline-color: rgb(63 98 18 / 0.55) !important;
}

.tw-outline-lime-800\/60 {
  outline-color: rgb(63 98 18 / 0.6) !important;
}

.tw-outline-lime-800\/65 {
  outline-color: rgb(63 98 18 / 0.65) !important;
}

.tw-outline-lime-800\/70 {
  outline-color: rgb(63 98 18 / 0.7) !important;
}

.tw-outline-lime-800\/75 {
  outline-color: rgb(63 98 18 / 0.75) !important;
}

.tw-outline-lime-800\/80 {
  outline-color: rgb(63 98 18 / 0.8) !important;
}

.tw-outline-lime-800\/85 {
  outline-color: rgb(63 98 18 / 0.85) !important;
}

.tw-outline-lime-800\/90 {
  outline-color: rgb(63 98 18 / 0.9) !important;
}

.tw-outline-lime-800\/95 {
  outline-color: rgb(63 98 18 / 0.95) !important;
}

.tw-outline-lime-900 {
  outline-color: #365314 !important;
}

.tw-outline-lime-900\/0 {
  outline-color: rgb(54 83 20 / 0) !important;
}

.tw-outline-lime-900\/10 {
  outline-color: rgb(54 83 20 / 0.1) !important;
}

.tw-outline-lime-900\/100 {
  outline-color: rgb(54 83 20 / 1) !important;
}

.tw-outline-lime-900\/15 {
  outline-color: rgb(54 83 20 / 0.15) !important;
}

.tw-outline-lime-900\/20 {
  outline-color: rgb(54 83 20 / 0.2) !important;
}

.tw-outline-lime-900\/25 {
  outline-color: rgb(54 83 20 / 0.25) !important;
}

.tw-outline-lime-900\/30 {
  outline-color: rgb(54 83 20 / 0.3) !important;
}

.tw-outline-lime-900\/35 {
  outline-color: rgb(54 83 20 / 0.35) !important;
}

.tw-outline-lime-900\/40 {
  outline-color: rgb(54 83 20 / 0.4) !important;
}

.tw-outline-lime-900\/45 {
  outline-color: rgb(54 83 20 / 0.45) !important;
}

.tw-outline-lime-900\/5 {
  outline-color: rgb(54 83 20 / 0.05) !important;
}

.tw-outline-lime-900\/50 {
  outline-color: rgb(54 83 20 / 0.5) !important;
}

.tw-outline-lime-900\/55 {
  outline-color: rgb(54 83 20 / 0.55) !important;
}

.tw-outline-lime-900\/60 {
  outline-color: rgb(54 83 20 / 0.6) !important;
}

.tw-outline-lime-900\/65 {
  outline-color: rgb(54 83 20 / 0.65) !important;
}

.tw-outline-lime-900\/70 {
  outline-color: rgb(54 83 20 / 0.7) !important;
}

.tw-outline-lime-900\/75 {
  outline-color: rgb(54 83 20 / 0.75) !important;
}

.tw-outline-lime-900\/80 {
  outline-color: rgb(54 83 20 / 0.8) !important;
}

.tw-outline-lime-900\/85 {
  outline-color: rgb(54 83 20 / 0.85) !important;
}

.tw-outline-lime-900\/90 {
  outline-color: rgb(54 83 20 / 0.9) !important;
}

.tw-outline-lime-900\/95 {
  outline-color: rgb(54 83 20 / 0.95) !important;
}

.tw-outline-lime-950 {
  outline-color: #1a2e05 !important;
}

.tw-outline-lime-950\/0 {
  outline-color: rgb(26 46 5 / 0) !important;
}

.tw-outline-lime-950\/10 {
  outline-color: rgb(26 46 5 / 0.1) !important;
}

.tw-outline-lime-950\/100 {
  outline-color: rgb(26 46 5 / 1) !important;
}

.tw-outline-lime-950\/15 {
  outline-color: rgb(26 46 5 / 0.15) !important;
}

.tw-outline-lime-950\/20 {
  outline-color: rgb(26 46 5 / 0.2) !important;
}

.tw-outline-lime-950\/25 {
  outline-color: rgb(26 46 5 / 0.25) !important;
}

.tw-outline-lime-950\/30 {
  outline-color: rgb(26 46 5 / 0.3) !important;
}

.tw-outline-lime-950\/35 {
  outline-color: rgb(26 46 5 / 0.35) !important;
}

.tw-outline-lime-950\/40 {
  outline-color: rgb(26 46 5 / 0.4) !important;
}

.tw-outline-lime-950\/45 {
  outline-color: rgb(26 46 5 / 0.45) !important;
}

.tw-outline-lime-950\/5 {
  outline-color: rgb(26 46 5 / 0.05) !important;
}

.tw-outline-lime-950\/50 {
  outline-color: rgb(26 46 5 / 0.5) !important;
}

.tw-outline-lime-950\/55 {
  outline-color: rgb(26 46 5 / 0.55) !important;
}

.tw-outline-lime-950\/60 {
  outline-color: rgb(26 46 5 / 0.6) !important;
}

.tw-outline-lime-950\/65 {
  outline-color: rgb(26 46 5 / 0.65) !important;
}

.tw-outline-lime-950\/70 {
  outline-color: rgb(26 46 5 / 0.7) !important;
}

.tw-outline-lime-950\/75 {
  outline-color: rgb(26 46 5 / 0.75) !important;
}

.tw-outline-lime-950\/80 {
  outline-color: rgb(26 46 5 / 0.8) !important;
}

.tw-outline-lime-950\/85 {
  outline-color: rgb(26 46 5 / 0.85) !important;
}

.tw-outline-lime-950\/90 {
  outline-color: rgb(26 46 5 / 0.9) !important;
}

.tw-outline-lime-950\/95 {
  outline-color: rgb(26 46 5 / 0.95) !important;
}

.tw-outline-neutral-100 {
  outline-color: #f5f5f5 !important;
}

.tw-outline-neutral-100\/0 {
  outline-color: rgb(245 245 245 / 0) !important;
}

.tw-outline-neutral-100\/10 {
  outline-color: rgb(245 245 245 / 0.1) !important;
}

.tw-outline-neutral-100\/100 {
  outline-color: rgb(245 245 245 / 1) !important;
}

.tw-outline-neutral-100\/15 {
  outline-color: rgb(245 245 245 / 0.15) !important;
}

.tw-outline-neutral-100\/20 {
  outline-color: rgb(245 245 245 / 0.2) !important;
}

.tw-outline-neutral-100\/25 {
  outline-color: rgb(245 245 245 / 0.25) !important;
}

.tw-outline-neutral-100\/30 {
  outline-color: rgb(245 245 245 / 0.3) !important;
}

.tw-outline-neutral-100\/35 {
  outline-color: rgb(245 245 245 / 0.35) !important;
}

.tw-outline-neutral-100\/40 {
  outline-color: rgb(245 245 245 / 0.4) !important;
}

.tw-outline-neutral-100\/45 {
  outline-color: rgb(245 245 245 / 0.45) !important;
}

.tw-outline-neutral-100\/5 {
  outline-color: rgb(245 245 245 / 0.05) !important;
}

.tw-outline-neutral-100\/50 {
  outline-color: rgb(245 245 245 / 0.5) !important;
}

.tw-outline-neutral-100\/55 {
  outline-color: rgb(245 245 245 / 0.55) !important;
}

.tw-outline-neutral-100\/60 {
  outline-color: rgb(245 245 245 / 0.6) !important;
}

.tw-outline-neutral-100\/65 {
  outline-color: rgb(245 245 245 / 0.65) !important;
}

.tw-outline-neutral-100\/70 {
  outline-color: rgb(245 245 245 / 0.7) !important;
}

.tw-outline-neutral-100\/75 {
  outline-color: rgb(245 245 245 / 0.75) !important;
}

.tw-outline-neutral-100\/80 {
  outline-color: rgb(245 245 245 / 0.8) !important;
}

.tw-outline-neutral-100\/85 {
  outline-color: rgb(245 245 245 / 0.85) !important;
}

.tw-outline-neutral-100\/90 {
  outline-color: rgb(245 245 245 / 0.9) !important;
}

.tw-outline-neutral-100\/95 {
  outline-color: rgb(245 245 245 / 0.95) !important;
}

.tw-outline-neutral-200 {
  outline-color: #e5e5e5 !important;
}

.tw-outline-neutral-200\/0 {
  outline-color: rgb(229 229 229 / 0) !important;
}

.tw-outline-neutral-200\/10 {
  outline-color: rgb(229 229 229 / 0.1) !important;
}

.tw-outline-neutral-200\/100 {
  outline-color: rgb(229 229 229 / 1) !important;
}

.tw-outline-neutral-200\/15 {
  outline-color: rgb(229 229 229 / 0.15) !important;
}

.tw-outline-neutral-200\/20 {
  outline-color: rgb(229 229 229 / 0.2) !important;
}

.tw-outline-neutral-200\/25 {
  outline-color: rgb(229 229 229 / 0.25) !important;
}

.tw-outline-neutral-200\/30 {
  outline-color: rgb(229 229 229 / 0.3) !important;
}

.tw-outline-neutral-200\/35 {
  outline-color: rgb(229 229 229 / 0.35) !important;
}

.tw-outline-neutral-200\/40 {
  outline-color: rgb(229 229 229 / 0.4) !important;
}

.tw-outline-neutral-200\/45 {
  outline-color: rgb(229 229 229 / 0.45) !important;
}

.tw-outline-neutral-200\/5 {
  outline-color: rgb(229 229 229 / 0.05) !important;
}

.tw-outline-neutral-200\/50 {
  outline-color: rgb(229 229 229 / 0.5) !important;
}

.tw-outline-neutral-200\/55 {
  outline-color: rgb(229 229 229 / 0.55) !important;
}

.tw-outline-neutral-200\/60 {
  outline-color: rgb(229 229 229 / 0.6) !important;
}

.tw-outline-neutral-200\/65 {
  outline-color: rgb(229 229 229 / 0.65) !important;
}

.tw-outline-neutral-200\/70 {
  outline-color: rgb(229 229 229 / 0.7) !important;
}

.tw-outline-neutral-200\/75 {
  outline-color: rgb(229 229 229 / 0.75) !important;
}

.tw-outline-neutral-200\/80 {
  outline-color: rgb(229 229 229 / 0.8) !important;
}

.tw-outline-neutral-200\/85 {
  outline-color: rgb(229 229 229 / 0.85) !important;
}

.tw-outline-neutral-200\/90 {
  outline-color: rgb(229 229 229 / 0.9) !important;
}

.tw-outline-neutral-200\/95 {
  outline-color: rgb(229 229 229 / 0.95) !important;
}

.tw-outline-neutral-300 {
  outline-color: #d4d4d4 !important;
}

.tw-outline-neutral-300\/0 {
  outline-color: rgb(212 212 212 / 0) !important;
}

.tw-outline-neutral-300\/10 {
  outline-color: rgb(212 212 212 / 0.1) !important;
}

.tw-outline-neutral-300\/100 {
  outline-color: rgb(212 212 212 / 1) !important;
}

.tw-outline-neutral-300\/15 {
  outline-color: rgb(212 212 212 / 0.15) !important;
}

.tw-outline-neutral-300\/20 {
  outline-color: rgb(212 212 212 / 0.2) !important;
}

.tw-outline-neutral-300\/25 {
  outline-color: rgb(212 212 212 / 0.25) !important;
}

.tw-outline-neutral-300\/30 {
  outline-color: rgb(212 212 212 / 0.3) !important;
}

.tw-outline-neutral-300\/35 {
  outline-color: rgb(212 212 212 / 0.35) !important;
}

.tw-outline-neutral-300\/40 {
  outline-color: rgb(212 212 212 / 0.4) !important;
}

.tw-outline-neutral-300\/45 {
  outline-color: rgb(212 212 212 / 0.45) !important;
}

.tw-outline-neutral-300\/5 {
  outline-color: rgb(212 212 212 / 0.05) !important;
}

.tw-outline-neutral-300\/50 {
  outline-color: rgb(212 212 212 / 0.5) !important;
}

.tw-outline-neutral-300\/55 {
  outline-color: rgb(212 212 212 / 0.55) !important;
}

.tw-outline-neutral-300\/60 {
  outline-color: rgb(212 212 212 / 0.6) !important;
}

.tw-outline-neutral-300\/65 {
  outline-color: rgb(212 212 212 / 0.65) !important;
}

.tw-outline-neutral-300\/70 {
  outline-color: rgb(212 212 212 / 0.7) !important;
}

.tw-outline-neutral-300\/75 {
  outline-color: rgb(212 212 212 / 0.75) !important;
}

.tw-outline-neutral-300\/80 {
  outline-color: rgb(212 212 212 / 0.8) !important;
}

.tw-outline-neutral-300\/85 {
  outline-color: rgb(212 212 212 / 0.85) !important;
}

.tw-outline-neutral-300\/90 {
  outline-color: rgb(212 212 212 / 0.9) !important;
}

.tw-outline-neutral-300\/95 {
  outline-color: rgb(212 212 212 / 0.95) !important;
}

.tw-outline-neutral-400 {
  outline-color: #a3a3a3 !important;
}

.tw-outline-neutral-400\/0 {
  outline-color: rgb(163 163 163 / 0) !important;
}

.tw-outline-neutral-400\/10 {
  outline-color: rgb(163 163 163 / 0.1) !important;
}

.tw-outline-neutral-400\/100 {
  outline-color: rgb(163 163 163 / 1) !important;
}

.tw-outline-neutral-400\/15 {
  outline-color: rgb(163 163 163 / 0.15) !important;
}

.tw-outline-neutral-400\/20 {
  outline-color: rgb(163 163 163 / 0.2) !important;
}

.tw-outline-neutral-400\/25 {
  outline-color: rgb(163 163 163 / 0.25) !important;
}

.tw-outline-neutral-400\/30 {
  outline-color: rgb(163 163 163 / 0.3) !important;
}

.tw-outline-neutral-400\/35 {
  outline-color: rgb(163 163 163 / 0.35) !important;
}

.tw-outline-neutral-400\/40 {
  outline-color: rgb(163 163 163 / 0.4) !important;
}

.tw-outline-neutral-400\/45 {
  outline-color: rgb(163 163 163 / 0.45) !important;
}

.tw-outline-neutral-400\/5 {
  outline-color: rgb(163 163 163 / 0.05) !important;
}

.tw-outline-neutral-400\/50 {
  outline-color: rgb(163 163 163 / 0.5) !important;
}

.tw-outline-neutral-400\/55 {
  outline-color: rgb(163 163 163 / 0.55) !important;
}

.tw-outline-neutral-400\/60 {
  outline-color: rgb(163 163 163 / 0.6) !important;
}

.tw-outline-neutral-400\/65 {
  outline-color: rgb(163 163 163 / 0.65) !important;
}

.tw-outline-neutral-400\/70 {
  outline-color: rgb(163 163 163 / 0.7) !important;
}

.tw-outline-neutral-400\/75 {
  outline-color: rgb(163 163 163 / 0.75) !important;
}

.tw-outline-neutral-400\/80 {
  outline-color: rgb(163 163 163 / 0.8) !important;
}

.tw-outline-neutral-400\/85 {
  outline-color: rgb(163 163 163 / 0.85) !important;
}

.tw-outline-neutral-400\/90 {
  outline-color: rgb(163 163 163 / 0.9) !important;
}

.tw-outline-neutral-400\/95 {
  outline-color: rgb(163 163 163 / 0.95) !important;
}

.tw-outline-neutral-50 {
  outline-color: #fafafa !important;
}

.tw-outline-neutral-50\/0 {
  outline-color: rgb(250 250 250 / 0) !important;
}

.tw-outline-neutral-50\/10 {
  outline-color: rgb(250 250 250 / 0.1) !important;
}

.tw-outline-neutral-50\/100 {
  outline-color: rgb(250 250 250 / 1) !important;
}

.tw-outline-neutral-50\/15 {
  outline-color: rgb(250 250 250 / 0.15) !important;
}

.tw-outline-neutral-50\/20 {
  outline-color: rgb(250 250 250 / 0.2) !important;
}

.tw-outline-neutral-50\/25 {
  outline-color: rgb(250 250 250 / 0.25) !important;
}

.tw-outline-neutral-50\/30 {
  outline-color: rgb(250 250 250 / 0.3) !important;
}

.tw-outline-neutral-50\/35 {
  outline-color: rgb(250 250 250 / 0.35) !important;
}

.tw-outline-neutral-50\/40 {
  outline-color: rgb(250 250 250 / 0.4) !important;
}

.tw-outline-neutral-50\/45 {
  outline-color: rgb(250 250 250 / 0.45) !important;
}

.tw-outline-neutral-50\/5 {
  outline-color: rgb(250 250 250 / 0.05) !important;
}

.tw-outline-neutral-50\/50 {
  outline-color: rgb(250 250 250 / 0.5) !important;
}

.tw-outline-neutral-50\/55 {
  outline-color: rgb(250 250 250 / 0.55) !important;
}

.tw-outline-neutral-50\/60 {
  outline-color: rgb(250 250 250 / 0.6) !important;
}

.tw-outline-neutral-50\/65 {
  outline-color: rgb(250 250 250 / 0.65) !important;
}

.tw-outline-neutral-50\/70 {
  outline-color: rgb(250 250 250 / 0.7) !important;
}

.tw-outline-neutral-50\/75 {
  outline-color: rgb(250 250 250 / 0.75) !important;
}

.tw-outline-neutral-50\/80 {
  outline-color: rgb(250 250 250 / 0.8) !important;
}

.tw-outline-neutral-50\/85 {
  outline-color: rgb(250 250 250 / 0.85) !important;
}

.tw-outline-neutral-50\/90 {
  outline-color: rgb(250 250 250 / 0.9) !important;
}

.tw-outline-neutral-50\/95 {
  outline-color: rgb(250 250 250 / 0.95) !important;
}

.tw-outline-neutral-500 {
  outline-color: #737373 !important;
}

.tw-outline-neutral-500\/0 {
  outline-color: rgb(115 115 115 / 0) !important;
}

.tw-outline-neutral-500\/10 {
  outline-color: rgb(115 115 115 / 0.1) !important;
}

.tw-outline-neutral-500\/100 {
  outline-color: rgb(115 115 115 / 1) !important;
}

.tw-outline-neutral-500\/15 {
  outline-color: rgb(115 115 115 / 0.15) !important;
}

.tw-outline-neutral-500\/20 {
  outline-color: rgb(115 115 115 / 0.2) !important;
}

.tw-outline-neutral-500\/25 {
  outline-color: rgb(115 115 115 / 0.25) !important;
}

.tw-outline-neutral-500\/30 {
  outline-color: rgb(115 115 115 / 0.3) !important;
}

.tw-outline-neutral-500\/35 {
  outline-color: rgb(115 115 115 / 0.35) !important;
}

.tw-outline-neutral-500\/40 {
  outline-color: rgb(115 115 115 / 0.4) !important;
}

.tw-outline-neutral-500\/45 {
  outline-color: rgb(115 115 115 / 0.45) !important;
}

.tw-outline-neutral-500\/5 {
  outline-color: rgb(115 115 115 / 0.05) !important;
}

.tw-outline-neutral-500\/50 {
  outline-color: rgb(115 115 115 / 0.5) !important;
}

.tw-outline-neutral-500\/55 {
  outline-color: rgb(115 115 115 / 0.55) !important;
}

.tw-outline-neutral-500\/60 {
  outline-color: rgb(115 115 115 / 0.6) !important;
}

.tw-outline-neutral-500\/65 {
  outline-color: rgb(115 115 115 / 0.65) !important;
}

.tw-outline-neutral-500\/70 {
  outline-color: rgb(115 115 115 / 0.7) !important;
}

.tw-outline-neutral-500\/75 {
  outline-color: rgb(115 115 115 / 0.75) !important;
}

.tw-outline-neutral-500\/80 {
  outline-color: rgb(115 115 115 / 0.8) !important;
}

.tw-outline-neutral-500\/85 {
  outline-color: rgb(115 115 115 / 0.85) !important;
}

.tw-outline-neutral-500\/90 {
  outline-color: rgb(115 115 115 / 0.9) !important;
}

.tw-outline-neutral-500\/95 {
  outline-color: rgb(115 115 115 / 0.95) !important;
}

.tw-outline-neutral-600 {
  outline-color: #525252 !important;
}

.tw-outline-neutral-600\/0 {
  outline-color: rgb(82 82 82 / 0) !important;
}

.tw-outline-neutral-600\/10 {
  outline-color: rgb(82 82 82 / 0.1) !important;
}

.tw-outline-neutral-600\/100 {
  outline-color: rgb(82 82 82 / 1) !important;
}

.tw-outline-neutral-600\/15 {
  outline-color: rgb(82 82 82 / 0.15) !important;
}

.tw-outline-neutral-600\/20 {
  outline-color: rgb(82 82 82 / 0.2) !important;
}

.tw-outline-neutral-600\/25 {
  outline-color: rgb(82 82 82 / 0.25) !important;
}

.tw-outline-neutral-600\/30 {
  outline-color: rgb(82 82 82 / 0.3) !important;
}

.tw-outline-neutral-600\/35 {
  outline-color: rgb(82 82 82 / 0.35) !important;
}

.tw-outline-neutral-600\/40 {
  outline-color: rgb(82 82 82 / 0.4) !important;
}

.tw-outline-neutral-600\/45 {
  outline-color: rgb(82 82 82 / 0.45) !important;
}

.tw-outline-neutral-600\/5 {
  outline-color: rgb(82 82 82 / 0.05) !important;
}

.tw-outline-neutral-600\/50 {
  outline-color: rgb(82 82 82 / 0.5) !important;
}

.tw-outline-neutral-600\/55 {
  outline-color: rgb(82 82 82 / 0.55) !important;
}

.tw-outline-neutral-600\/60 {
  outline-color: rgb(82 82 82 / 0.6) !important;
}

.tw-outline-neutral-600\/65 {
  outline-color: rgb(82 82 82 / 0.65) !important;
}

.tw-outline-neutral-600\/70 {
  outline-color: rgb(82 82 82 / 0.7) !important;
}

.tw-outline-neutral-600\/75 {
  outline-color: rgb(82 82 82 / 0.75) !important;
}

.tw-outline-neutral-600\/80 {
  outline-color: rgb(82 82 82 / 0.8) !important;
}

.tw-outline-neutral-600\/85 {
  outline-color: rgb(82 82 82 / 0.85) !important;
}

.tw-outline-neutral-600\/90 {
  outline-color: rgb(82 82 82 / 0.9) !important;
}

.tw-outline-neutral-600\/95 {
  outline-color: rgb(82 82 82 / 0.95) !important;
}

.tw-outline-neutral-700 {
  outline-color: #404040 !important;
}

.tw-outline-neutral-700\/0 {
  outline-color: rgb(64 64 64 / 0) !important;
}

.tw-outline-neutral-700\/10 {
  outline-color: rgb(64 64 64 / 0.1) !important;
}

.tw-outline-neutral-700\/100 {
  outline-color: rgb(64 64 64 / 1) !important;
}

.tw-outline-neutral-700\/15 {
  outline-color: rgb(64 64 64 / 0.15) !important;
}

.tw-outline-neutral-700\/20 {
  outline-color: rgb(64 64 64 / 0.2) !important;
}

.tw-outline-neutral-700\/25 {
  outline-color: rgb(64 64 64 / 0.25) !important;
}

.tw-outline-neutral-700\/30 {
  outline-color: rgb(64 64 64 / 0.3) !important;
}

.tw-outline-neutral-700\/35 {
  outline-color: rgb(64 64 64 / 0.35) !important;
}

.tw-outline-neutral-700\/40 {
  outline-color: rgb(64 64 64 / 0.4) !important;
}

.tw-outline-neutral-700\/45 {
  outline-color: rgb(64 64 64 / 0.45) !important;
}

.tw-outline-neutral-700\/5 {
  outline-color: rgb(64 64 64 / 0.05) !important;
}

.tw-outline-neutral-700\/50 {
  outline-color: rgb(64 64 64 / 0.5) !important;
}

.tw-outline-neutral-700\/55 {
  outline-color: rgb(64 64 64 / 0.55) !important;
}

.tw-outline-neutral-700\/60 {
  outline-color: rgb(64 64 64 / 0.6) !important;
}

.tw-outline-neutral-700\/65 {
  outline-color: rgb(64 64 64 / 0.65) !important;
}

.tw-outline-neutral-700\/70 {
  outline-color: rgb(64 64 64 / 0.7) !important;
}

.tw-outline-neutral-700\/75 {
  outline-color: rgb(64 64 64 / 0.75) !important;
}

.tw-outline-neutral-700\/80 {
  outline-color: rgb(64 64 64 / 0.8) !important;
}

.tw-outline-neutral-700\/85 {
  outline-color: rgb(64 64 64 / 0.85) !important;
}

.tw-outline-neutral-700\/90 {
  outline-color: rgb(64 64 64 / 0.9) !important;
}

.tw-outline-neutral-700\/95 {
  outline-color: rgb(64 64 64 / 0.95) !important;
}

.tw-outline-neutral-800 {
  outline-color: #262626 !important;
}

.tw-outline-neutral-800\/0 {
  outline-color: rgb(38 38 38 / 0) !important;
}

.tw-outline-neutral-800\/10 {
  outline-color: rgb(38 38 38 / 0.1) !important;
}

.tw-outline-neutral-800\/100 {
  outline-color: rgb(38 38 38 / 1) !important;
}

.tw-outline-neutral-800\/15 {
  outline-color: rgb(38 38 38 / 0.15) !important;
}

.tw-outline-neutral-800\/20 {
  outline-color: rgb(38 38 38 / 0.2) !important;
}

.tw-outline-neutral-800\/25 {
  outline-color: rgb(38 38 38 / 0.25) !important;
}

.tw-outline-neutral-800\/30 {
  outline-color: rgb(38 38 38 / 0.3) !important;
}

.tw-outline-neutral-800\/35 {
  outline-color: rgb(38 38 38 / 0.35) !important;
}

.tw-outline-neutral-800\/40 {
  outline-color: rgb(38 38 38 / 0.4) !important;
}

.tw-outline-neutral-800\/45 {
  outline-color: rgb(38 38 38 / 0.45) !important;
}

.tw-outline-neutral-800\/5 {
  outline-color: rgb(38 38 38 / 0.05) !important;
}

.tw-outline-neutral-800\/50 {
  outline-color: rgb(38 38 38 / 0.5) !important;
}

.tw-outline-neutral-800\/55 {
  outline-color: rgb(38 38 38 / 0.55) !important;
}

.tw-outline-neutral-800\/60 {
  outline-color: rgb(38 38 38 / 0.6) !important;
}

.tw-outline-neutral-800\/65 {
  outline-color: rgb(38 38 38 / 0.65) !important;
}

.tw-outline-neutral-800\/70 {
  outline-color: rgb(38 38 38 / 0.7) !important;
}

.tw-outline-neutral-800\/75 {
  outline-color: rgb(38 38 38 / 0.75) !important;
}

.tw-outline-neutral-800\/80 {
  outline-color: rgb(38 38 38 / 0.8) !important;
}

.tw-outline-neutral-800\/85 {
  outline-color: rgb(38 38 38 / 0.85) !important;
}

.tw-outline-neutral-800\/90 {
  outline-color: rgb(38 38 38 / 0.9) !important;
}

.tw-outline-neutral-800\/95 {
  outline-color: rgb(38 38 38 / 0.95) !important;
}

.tw-outline-neutral-900 {
  outline-color: #171717 !important;
}

.tw-outline-neutral-900\/0 {
  outline-color: rgb(23 23 23 / 0) !important;
}

.tw-outline-neutral-900\/10 {
  outline-color: rgb(23 23 23 / 0.1) !important;
}

.tw-outline-neutral-900\/100 {
  outline-color: rgb(23 23 23 / 1) !important;
}

.tw-outline-neutral-900\/15 {
  outline-color: rgb(23 23 23 / 0.15) !important;
}

.tw-outline-neutral-900\/20 {
  outline-color: rgb(23 23 23 / 0.2) !important;
}

.tw-outline-neutral-900\/25 {
  outline-color: rgb(23 23 23 / 0.25) !important;
}

.tw-outline-neutral-900\/30 {
  outline-color: rgb(23 23 23 / 0.3) !important;
}

.tw-outline-neutral-900\/35 {
  outline-color: rgb(23 23 23 / 0.35) !important;
}

.tw-outline-neutral-900\/40 {
  outline-color: rgb(23 23 23 / 0.4) !important;
}

.tw-outline-neutral-900\/45 {
  outline-color: rgb(23 23 23 / 0.45) !important;
}

.tw-outline-neutral-900\/5 {
  outline-color: rgb(23 23 23 / 0.05) !important;
}

.tw-outline-neutral-900\/50 {
  outline-color: rgb(23 23 23 / 0.5) !important;
}

.tw-outline-neutral-900\/55 {
  outline-color: rgb(23 23 23 / 0.55) !important;
}

.tw-outline-neutral-900\/60 {
  outline-color: rgb(23 23 23 / 0.6) !important;
}

.tw-outline-neutral-900\/65 {
  outline-color: rgb(23 23 23 / 0.65) !important;
}

.tw-outline-neutral-900\/70 {
  outline-color: rgb(23 23 23 / 0.7) !important;
}

.tw-outline-neutral-900\/75 {
  outline-color: rgb(23 23 23 / 0.75) !important;
}

.tw-outline-neutral-900\/80 {
  outline-color: rgb(23 23 23 / 0.8) !important;
}

.tw-outline-neutral-900\/85 {
  outline-color: rgb(23 23 23 / 0.85) !important;
}

.tw-outline-neutral-900\/90 {
  outline-color: rgb(23 23 23 / 0.9) !important;
}

.tw-outline-neutral-900\/95 {
  outline-color: rgb(23 23 23 / 0.95) !important;
}

.tw-outline-neutral-950 {
  outline-color: #0a0a0a !important;
}

.tw-outline-neutral-950\/0 {
  outline-color: rgb(10 10 10 / 0) !important;
}

.tw-outline-neutral-950\/10 {
  outline-color: rgb(10 10 10 / 0.1) !important;
}

.tw-outline-neutral-950\/100 {
  outline-color: rgb(10 10 10 / 1) !important;
}

.tw-outline-neutral-950\/15 {
  outline-color: rgb(10 10 10 / 0.15) !important;
}

.tw-outline-neutral-950\/20 {
  outline-color: rgb(10 10 10 / 0.2) !important;
}

.tw-outline-neutral-950\/25 {
  outline-color: rgb(10 10 10 / 0.25) !important;
}

.tw-outline-neutral-950\/30 {
  outline-color: rgb(10 10 10 / 0.3) !important;
}

.tw-outline-neutral-950\/35 {
  outline-color: rgb(10 10 10 / 0.35) !important;
}

.tw-outline-neutral-950\/40 {
  outline-color: rgb(10 10 10 / 0.4) !important;
}

.tw-outline-neutral-950\/45 {
  outline-color: rgb(10 10 10 / 0.45) !important;
}

.tw-outline-neutral-950\/5 {
  outline-color: rgb(10 10 10 / 0.05) !important;
}

.tw-outline-neutral-950\/50 {
  outline-color: rgb(10 10 10 / 0.5) !important;
}

.tw-outline-neutral-950\/55 {
  outline-color: rgb(10 10 10 / 0.55) !important;
}

.tw-outline-neutral-950\/60 {
  outline-color: rgb(10 10 10 / 0.6) !important;
}

.tw-outline-neutral-950\/65 {
  outline-color: rgb(10 10 10 / 0.65) !important;
}

.tw-outline-neutral-950\/70 {
  outline-color: rgb(10 10 10 / 0.7) !important;
}

.tw-outline-neutral-950\/75 {
  outline-color: rgb(10 10 10 / 0.75) !important;
}

.tw-outline-neutral-950\/80 {
  outline-color: rgb(10 10 10 / 0.8) !important;
}

.tw-outline-neutral-950\/85 {
  outline-color: rgb(10 10 10 / 0.85) !important;
}

.tw-outline-neutral-950\/90 {
  outline-color: rgb(10 10 10 / 0.9) !important;
}

.tw-outline-neutral-950\/95 {
  outline-color: rgb(10 10 10 / 0.95) !important;
}

.tw-outline-orange-100 {
  outline-color: #ffedd5 !important;
}

.tw-outline-orange-100\/0 {
  outline-color: rgb(255 237 213 / 0) !important;
}

.tw-outline-orange-100\/10 {
  outline-color: rgb(255 237 213 / 0.1) !important;
}

.tw-outline-orange-100\/100 {
  outline-color: rgb(255 237 213 / 1) !important;
}

.tw-outline-orange-100\/15 {
  outline-color: rgb(255 237 213 / 0.15) !important;
}

.tw-outline-orange-100\/20 {
  outline-color: rgb(255 237 213 / 0.2) !important;
}

.tw-outline-orange-100\/25 {
  outline-color: rgb(255 237 213 / 0.25) !important;
}

.tw-outline-orange-100\/30 {
  outline-color: rgb(255 237 213 / 0.3) !important;
}

.tw-outline-orange-100\/35 {
  outline-color: rgb(255 237 213 / 0.35) !important;
}

.tw-outline-orange-100\/40 {
  outline-color: rgb(255 237 213 / 0.4) !important;
}

.tw-outline-orange-100\/45 {
  outline-color: rgb(255 237 213 / 0.45) !important;
}

.tw-outline-orange-100\/5 {
  outline-color: rgb(255 237 213 / 0.05) !important;
}

.tw-outline-orange-100\/50 {
  outline-color: rgb(255 237 213 / 0.5) !important;
}

.tw-outline-orange-100\/55 {
  outline-color: rgb(255 237 213 / 0.55) !important;
}

.tw-outline-orange-100\/60 {
  outline-color: rgb(255 237 213 / 0.6) !important;
}

.tw-outline-orange-100\/65 {
  outline-color: rgb(255 237 213 / 0.65) !important;
}

.tw-outline-orange-100\/70 {
  outline-color: rgb(255 237 213 / 0.7) !important;
}

.tw-outline-orange-100\/75 {
  outline-color: rgb(255 237 213 / 0.75) !important;
}

.tw-outline-orange-100\/80 {
  outline-color: rgb(255 237 213 / 0.8) !important;
}

.tw-outline-orange-100\/85 {
  outline-color: rgb(255 237 213 / 0.85) !important;
}

.tw-outline-orange-100\/90 {
  outline-color: rgb(255 237 213 / 0.9) !important;
}

.tw-outline-orange-100\/95 {
  outline-color: rgb(255 237 213 / 0.95) !important;
}

.tw-outline-orange-200 {
  outline-color: #fed7aa !important;
}

.tw-outline-orange-200\/0 {
  outline-color: rgb(254 215 170 / 0) !important;
}

.tw-outline-orange-200\/10 {
  outline-color: rgb(254 215 170 / 0.1) !important;
}

.tw-outline-orange-200\/100 {
  outline-color: rgb(254 215 170 / 1) !important;
}

.tw-outline-orange-200\/15 {
  outline-color: rgb(254 215 170 / 0.15) !important;
}

.tw-outline-orange-200\/20 {
  outline-color: rgb(254 215 170 / 0.2) !important;
}

.tw-outline-orange-200\/25 {
  outline-color: rgb(254 215 170 / 0.25) !important;
}

.tw-outline-orange-200\/30 {
  outline-color: rgb(254 215 170 / 0.3) !important;
}

.tw-outline-orange-200\/35 {
  outline-color: rgb(254 215 170 / 0.35) !important;
}

.tw-outline-orange-200\/40 {
  outline-color: rgb(254 215 170 / 0.4) !important;
}

.tw-outline-orange-200\/45 {
  outline-color: rgb(254 215 170 / 0.45) !important;
}

.tw-outline-orange-200\/5 {
  outline-color: rgb(254 215 170 / 0.05) !important;
}

.tw-outline-orange-200\/50 {
  outline-color: rgb(254 215 170 / 0.5) !important;
}

.tw-outline-orange-200\/55 {
  outline-color: rgb(254 215 170 / 0.55) !important;
}

.tw-outline-orange-200\/60 {
  outline-color: rgb(254 215 170 / 0.6) !important;
}

.tw-outline-orange-200\/65 {
  outline-color: rgb(254 215 170 / 0.65) !important;
}

.tw-outline-orange-200\/70 {
  outline-color: rgb(254 215 170 / 0.7) !important;
}

.tw-outline-orange-200\/75 {
  outline-color: rgb(254 215 170 / 0.75) !important;
}

.tw-outline-orange-200\/80 {
  outline-color: rgb(254 215 170 / 0.8) !important;
}

.tw-outline-orange-200\/85 {
  outline-color: rgb(254 215 170 / 0.85) !important;
}

.tw-outline-orange-200\/90 {
  outline-color: rgb(254 215 170 / 0.9) !important;
}

.tw-outline-orange-200\/95 {
  outline-color: rgb(254 215 170 / 0.95) !important;
}

.tw-outline-orange-300 {
  outline-color: #fdba74 !important;
}

.tw-outline-orange-300\/0 {
  outline-color: rgb(253 186 116 / 0) !important;
}

.tw-outline-orange-300\/10 {
  outline-color: rgb(253 186 116 / 0.1) !important;
}

.tw-outline-orange-300\/100 {
  outline-color: rgb(253 186 116 / 1) !important;
}

.tw-outline-orange-300\/15 {
  outline-color: rgb(253 186 116 / 0.15) !important;
}

.tw-outline-orange-300\/20 {
  outline-color: rgb(253 186 116 / 0.2) !important;
}

.tw-outline-orange-300\/25 {
  outline-color: rgb(253 186 116 / 0.25) !important;
}

.tw-outline-orange-300\/30 {
  outline-color: rgb(253 186 116 / 0.3) !important;
}

.tw-outline-orange-300\/35 {
  outline-color: rgb(253 186 116 / 0.35) !important;
}

.tw-outline-orange-300\/40 {
  outline-color: rgb(253 186 116 / 0.4) !important;
}

.tw-outline-orange-300\/45 {
  outline-color: rgb(253 186 116 / 0.45) !important;
}

.tw-outline-orange-300\/5 {
  outline-color: rgb(253 186 116 / 0.05) !important;
}

.tw-outline-orange-300\/50 {
  outline-color: rgb(253 186 116 / 0.5) !important;
}

.tw-outline-orange-300\/55 {
  outline-color: rgb(253 186 116 / 0.55) !important;
}

.tw-outline-orange-300\/60 {
  outline-color: rgb(253 186 116 / 0.6) !important;
}

.tw-outline-orange-300\/65 {
  outline-color: rgb(253 186 116 / 0.65) !important;
}

.tw-outline-orange-300\/70 {
  outline-color: rgb(253 186 116 / 0.7) !important;
}

.tw-outline-orange-300\/75 {
  outline-color: rgb(253 186 116 / 0.75) !important;
}

.tw-outline-orange-300\/80 {
  outline-color: rgb(253 186 116 / 0.8) !important;
}

.tw-outline-orange-300\/85 {
  outline-color: rgb(253 186 116 / 0.85) !important;
}

.tw-outline-orange-300\/90 {
  outline-color: rgb(253 186 116 / 0.9) !important;
}

.tw-outline-orange-300\/95 {
  outline-color: rgb(253 186 116 / 0.95) !important;
}

.tw-outline-orange-400 {
  outline-color: #fb923c !important;
}

.tw-outline-orange-400\/0 {
  outline-color: rgb(251 146 60 / 0) !important;
}

.tw-outline-orange-400\/10 {
  outline-color: rgb(251 146 60 / 0.1) !important;
}

.tw-outline-orange-400\/100 {
  outline-color: rgb(251 146 60 / 1) !important;
}

.tw-outline-orange-400\/15 {
  outline-color: rgb(251 146 60 / 0.15) !important;
}

.tw-outline-orange-400\/20 {
  outline-color: rgb(251 146 60 / 0.2) !important;
}

.tw-outline-orange-400\/25 {
  outline-color: rgb(251 146 60 / 0.25) !important;
}

.tw-outline-orange-400\/30 {
  outline-color: rgb(251 146 60 / 0.3) !important;
}

.tw-outline-orange-400\/35 {
  outline-color: rgb(251 146 60 / 0.35) !important;
}

.tw-outline-orange-400\/40 {
  outline-color: rgb(251 146 60 / 0.4) !important;
}

.tw-outline-orange-400\/45 {
  outline-color: rgb(251 146 60 / 0.45) !important;
}

.tw-outline-orange-400\/5 {
  outline-color: rgb(251 146 60 / 0.05) !important;
}

.tw-outline-orange-400\/50 {
  outline-color: rgb(251 146 60 / 0.5) !important;
}

.tw-outline-orange-400\/55 {
  outline-color: rgb(251 146 60 / 0.55) !important;
}

.tw-outline-orange-400\/60 {
  outline-color: rgb(251 146 60 / 0.6) !important;
}

.tw-outline-orange-400\/65 {
  outline-color: rgb(251 146 60 / 0.65) !important;
}

.tw-outline-orange-400\/70 {
  outline-color: rgb(251 146 60 / 0.7) !important;
}

.tw-outline-orange-400\/75 {
  outline-color: rgb(251 146 60 / 0.75) !important;
}

.tw-outline-orange-400\/80 {
  outline-color: rgb(251 146 60 / 0.8) !important;
}

.tw-outline-orange-400\/85 {
  outline-color: rgb(251 146 60 / 0.85) !important;
}

.tw-outline-orange-400\/90 {
  outline-color: rgb(251 146 60 / 0.9) !important;
}

.tw-outline-orange-400\/95 {
  outline-color: rgb(251 146 60 / 0.95) !important;
}

.tw-outline-orange-50 {
  outline-color: #fff7ed !important;
}

.tw-outline-orange-50\/0 {
  outline-color: rgb(255 247 237 / 0) !important;
}

.tw-outline-orange-50\/10 {
  outline-color: rgb(255 247 237 / 0.1) !important;
}

.tw-outline-orange-50\/100 {
  outline-color: rgb(255 247 237 / 1) !important;
}

.tw-outline-orange-50\/15 {
  outline-color: rgb(255 247 237 / 0.15) !important;
}

.tw-outline-orange-50\/20 {
  outline-color: rgb(255 247 237 / 0.2) !important;
}

.tw-outline-orange-50\/25 {
  outline-color: rgb(255 247 237 / 0.25) !important;
}

.tw-outline-orange-50\/30 {
  outline-color: rgb(255 247 237 / 0.3) !important;
}

.tw-outline-orange-50\/35 {
  outline-color: rgb(255 247 237 / 0.35) !important;
}

.tw-outline-orange-50\/40 {
  outline-color: rgb(255 247 237 / 0.4) !important;
}

.tw-outline-orange-50\/45 {
  outline-color: rgb(255 247 237 / 0.45) !important;
}

.tw-outline-orange-50\/5 {
  outline-color: rgb(255 247 237 / 0.05) !important;
}

.tw-outline-orange-50\/50 {
  outline-color: rgb(255 247 237 / 0.5) !important;
}

.tw-outline-orange-50\/55 {
  outline-color: rgb(255 247 237 / 0.55) !important;
}

.tw-outline-orange-50\/60 {
  outline-color: rgb(255 247 237 / 0.6) !important;
}

.tw-outline-orange-50\/65 {
  outline-color: rgb(255 247 237 / 0.65) !important;
}

.tw-outline-orange-50\/70 {
  outline-color: rgb(255 247 237 / 0.7) !important;
}

.tw-outline-orange-50\/75 {
  outline-color: rgb(255 247 237 / 0.75) !important;
}

.tw-outline-orange-50\/80 {
  outline-color: rgb(255 247 237 / 0.8) !important;
}

.tw-outline-orange-50\/85 {
  outline-color: rgb(255 247 237 / 0.85) !important;
}

.tw-outline-orange-50\/90 {
  outline-color: rgb(255 247 237 / 0.9) !important;
}

.tw-outline-orange-50\/95 {
  outline-color: rgb(255 247 237 / 0.95) !important;
}

.tw-outline-orange-500 {
  outline-color: #f97316 !important;
}

.tw-outline-orange-500\/0 {
  outline-color: rgb(249 115 22 / 0) !important;
}

.tw-outline-orange-500\/10 {
  outline-color: rgb(249 115 22 / 0.1) !important;
}

.tw-outline-orange-500\/100 {
  outline-color: rgb(249 115 22 / 1) !important;
}

.tw-outline-orange-500\/15 {
  outline-color: rgb(249 115 22 / 0.15) !important;
}

.tw-outline-orange-500\/20 {
  outline-color: rgb(249 115 22 / 0.2) !important;
}

.tw-outline-orange-500\/25 {
  outline-color: rgb(249 115 22 / 0.25) !important;
}

.tw-outline-orange-500\/30 {
  outline-color: rgb(249 115 22 / 0.3) !important;
}

.tw-outline-orange-500\/35 {
  outline-color: rgb(249 115 22 / 0.35) !important;
}

.tw-outline-orange-500\/40 {
  outline-color: rgb(249 115 22 / 0.4) !important;
}

.tw-outline-orange-500\/45 {
  outline-color: rgb(249 115 22 / 0.45) !important;
}

.tw-outline-orange-500\/5 {
  outline-color: rgb(249 115 22 / 0.05) !important;
}

.tw-outline-orange-500\/50 {
  outline-color: rgb(249 115 22 / 0.5) !important;
}

.tw-outline-orange-500\/55 {
  outline-color: rgb(249 115 22 / 0.55) !important;
}

.tw-outline-orange-500\/60 {
  outline-color: rgb(249 115 22 / 0.6) !important;
}

.tw-outline-orange-500\/65 {
  outline-color: rgb(249 115 22 / 0.65) !important;
}

.tw-outline-orange-500\/70 {
  outline-color: rgb(249 115 22 / 0.7) !important;
}

.tw-outline-orange-500\/75 {
  outline-color: rgb(249 115 22 / 0.75) !important;
}

.tw-outline-orange-500\/80 {
  outline-color: rgb(249 115 22 / 0.8) !important;
}

.tw-outline-orange-500\/85 {
  outline-color: rgb(249 115 22 / 0.85) !important;
}

.tw-outline-orange-500\/90 {
  outline-color: rgb(249 115 22 / 0.9) !important;
}

.tw-outline-orange-500\/95 {
  outline-color: rgb(249 115 22 / 0.95) !important;
}

.tw-outline-orange-600 {
  outline-color: #ea580c !important;
}

.tw-outline-orange-600\/0 {
  outline-color: rgb(234 88 12 / 0) !important;
}

.tw-outline-orange-600\/10 {
  outline-color: rgb(234 88 12 / 0.1) !important;
}

.tw-outline-orange-600\/100 {
  outline-color: rgb(234 88 12 / 1) !important;
}

.tw-outline-orange-600\/15 {
  outline-color: rgb(234 88 12 / 0.15) !important;
}

.tw-outline-orange-600\/20 {
  outline-color: rgb(234 88 12 / 0.2) !important;
}

.tw-outline-orange-600\/25 {
  outline-color: rgb(234 88 12 / 0.25) !important;
}

.tw-outline-orange-600\/30 {
  outline-color: rgb(234 88 12 / 0.3) !important;
}

.tw-outline-orange-600\/35 {
  outline-color: rgb(234 88 12 / 0.35) !important;
}

.tw-outline-orange-600\/40 {
  outline-color: rgb(234 88 12 / 0.4) !important;
}

.tw-outline-orange-600\/45 {
  outline-color: rgb(234 88 12 / 0.45) !important;
}

.tw-outline-orange-600\/5 {
  outline-color: rgb(234 88 12 / 0.05) !important;
}

.tw-outline-orange-600\/50 {
  outline-color: rgb(234 88 12 / 0.5) !important;
}

.tw-outline-orange-600\/55 {
  outline-color: rgb(234 88 12 / 0.55) !important;
}

.tw-outline-orange-600\/60 {
  outline-color: rgb(234 88 12 / 0.6) !important;
}

.tw-outline-orange-600\/65 {
  outline-color: rgb(234 88 12 / 0.65) !important;
}

.tw-outline-orange-600\/70 {
  outline-color: rgb(234 88 12 / 0.7) !important;
}

.tw-outline-orange-600\/75 {
  outline-color: rgb(234 88 12 / 0.75) !important;
}

.tw-outline-orange-600\/80 {
  outline-color: rgb(234 88 12 / 0.8) !important;
}

.tw-outline-orange-600\/85 {
  outline-color: rgb(234 88 12 / 0.85) !important;
}

.tw-outline-orange-600\/90 {
  outline-color: rgb(234 88 12 / 0.9) !important;
}

.tw-outline-orange-600\/95 {
  outline-color: rgb(234 88 12 / 0.95) !important;
}

.tw-outline-orange-700 {
  outline-color: #c2410c !important;
}

.tw-outline-orange-700\/0 {
  outline-color: rgb(194 65 12 / 0) !important;
}

.tw-outline-orange-700\/10 {
  outline-color: rgb(194 65 12 / 0.1) !important;
}

.tw-outline-orange-700\/100 {
  outline-color: rgb(194 65 12 / 1) !important;
}

.tw-outline-orange-700\/15 {
  outline-color: rgb(194 65 12 / 0.15) !important;
}

.tw-outline-orange-700\/20 {
  outline-color: rgb(194 65 12 / 0.2) !important;
}

.tw-outline-orange-700\/25 {
  outline-color: rgb(194 65 12 / 0.25) !important;
}

.tw-outline-orange-700\/30 {
  outline-color: rgb(194 65 12 / 0.3) !important;
}

.tw-outline-orange-700\/35 {
  outline-color: rgb(194 65 12 / 0.35) !important;
}

.tw-outline-orange-700\/40 {
  outline-color: rgb(194 65 12 / 0.4) !important;
}

.tw-outline-orange-700\/45 {
  outline-color: rgb(194 65 12 / 0.45) !important;
}

.tw-outline-orange-700\/5 {
  outline-color: rgb(194 65 12 / 0.05) !important;
}

.tw-outline-orange-700\/50 {
  outline-color: rgb(194 65 12 / 0.5) !important;
}

.tw-outline-orange-700\/55 {
  outline-color: rgb(194 65 12 / 0.55) !important;
}

.tw-outline-orange-700\/60 {
  outline-color: rgb(194 65 12 / 0.6) !important;
}

.tw-outline-orange-700\/65 {
  outline-color: rgb(194 65 12 / 0.65) !important;
}

.tw-outline-orange-700\/70 {
  outline-color: rgb(194 65 12 / 0.7) !important;
}

.tw-outline-orange-700\/75 {
  outline-color: rgb(194 65 12 / 0.75) !important;
}

.tw-outline-orange-700\/80 {
  outline-color: rgb(194 65 12 / 0.8) !important;
}

.tw-outline-orange-700\/85 {
  outline-color: rgb(194 65 12 / 0.85) !important;
}

.tw-outline-orange-700\/90 {
  outline-color: rgb(194 65 12 / 0.9) !important;
}

.tw-outline-orange-700\/95 {
  outline-color: rgb(194 65 12 / 0.95) !important;
}

.tw-outline-orange-800 {
  outline-color: #9a3412 !important;
}

.tw-outline-orange-800\/0 {
  outline-color: rgb(154 52 18 / 0) !important;
}

.tw-outline-orange-800\/10 {
  outline-color: rgb(154 52 18 / 0.1) !important;
}

.tw-outline-orange-800\/100 {
  outline-color: rgb(154 52 18 / 1) !important;
}

.tw-outline-orange-800\/15 {
  outline-color: rgb(154 52 18 / 0.15) !important;
}

.tw-outline-orange-800\/20 {
  outline-color: rgb(154 52 18 / 0.2) !important;
}

.tw-outline-orange-800\/25 {
  outline-color: rgb(154 52 18 / 0.25) !important;
}

.tw-outline-orange-800\/30 {
  outline-color: rgb(154 52 18 / 0.3) !important;
}

.tw-outline-orange-800\/35 {
  outline-color: rgb(154 52 18 / 0.35) !important;
}

.tw-outline-orange-800\/40 {
  outline-color: rgb(154 52 18 / 0.4) !important;
}

.tw-outline-orange-800\/45 {
  outline-color: rgb(154 52 18 / 0.45) !important;
}

.tw-outline-orange-800\/5 {
  outline-color: rgb(154 52 18 / 0.05) !important;
}

.tw-outline-orange-800\/50 {
  outline-color: rgb(154 52 18 / 0.5) !important;
}

.tw-outline-orange-800\/55 {
  outline-color: rgb(154 52 18 / 0.55) !important;
}

.tw-outline-orange-800\/60 {
  outline-color: rgb(154 52 18 / 0.6) !important;
}

.tw-outline-orange-800\/65 {
  outline-color: rgb(154 52 18 / 0.65) !important;
}

.tw-outline-orange-800\/70 {
  outline-color: rgb(154 52 18 / 0.7) !important;
}

.tw-outline-orange-800\/75 {
  outline-color: rgb(154 52 18 / 0.75) !important;
}

.tw-outline-orange-800\/80 {
  outline-color: rgb(154 52 18 / 0.8) !important;
}

.tw-outline-orange-800\/85 {
  outline-color: rgb(154 52 18 / 0.85) !important;
}

.tw-outline-orange-800\/90 {
  outline-color: rgb(154 52 18 / 0.9) !important;
}

.tw-outline-orange-800\/95 {
  outline-color: rgb(154 52 18 / 0.95) !important;
}

.tw-outline-orange-900 {
  outline-color: #7c2d12 !important;
}

.tw-outline-orange-900\/0 {
  outline-color: rgb(124 45 18 / 0) !important;
}

.tw-outline-orange-900\/10 {
  outline-color: rgb(124 45 18 / 0.1) !important;
}

.tw-outline-orange-900\/100 {
  outline-color: rgb(124 45 18 / 1) !important;
}

.tw-outline-orange-900\/15 {
  outline-color: rgb(124 45 18 / 0.15) !important;
}

.tw-outline-orange-900\/20 {
  outline-color: rgb(124 45 18 / 0.2) !important;
}

.tw-outline-orange-900\/25 {
  outline-color: rgb(124 45 18 / 0.25) !important;
}

.tw-outline-orange-900\/30 {
  outline-color: rgb(124 45 18 / 0.3) !important;
}

.tw-outline-orange-900\/35 {
  outline-color: rgb(124 45 18 / 0.35) !important;
}

.tw-outline-orange-900\/40 {
  outline-color: rgb(124 45 18 / 0.4) !important;
}

.tw-outline-orange-900\/45 {
  outline-color: rgb(124 45 18 / 0.45) !important;
}

.tw-outline-orange-900\/5 {
  outline-color: rgb(124 45 18 / 0.05) !important;
}

.tw-outline-orange-900\/50 {
  outline-color: rgb(124 45 18 / 0.5) !important;
}

.tw-outline-orange-900\/55 {
  outline-color: rgb(124 45 18 / 0.55) !important;
}

.tw-outline-orange-900\/60 {
  outline-color: rgb(124 45 18 / 0.6) !important;
}

.tw-outline-orange-900\/65 {
  outline-color: rgb(124 45 18 / 0.65) !important;
}

.tw-outline-orange-900\/70 {
  outline-color: rgb(124 45 18 / 0.7) !important;
}

.tw-outline-orange-900\/75 {
  outline-color: rgb(124 45 18 / 0.75) !important;
}

.tw-outline-orange-900\/80 {
  outline-color: rgb(124 45 18 / 0.8) !important;
}

.tw-outline-orange-900\/85 {
  outline-color: rgb(124 45 18 / 0.85) !important;
}

.tw-outline-orange-900\/90 {
  outline-color: rgb(124 45 18 / 0.9) !important;
}

.tw-outline-orange-900\/95 {
  outline-color: rgb(124 45 18 / 0.95) !important;
}

.tw-outline-orange-950 {
  outline-color: #431407 !important;
}

.tw-outline-orange-950\/0 {
  outline-color: rgb(67 20 7 / 0) !important;
}

.tw-outline-orange-950\/10 {
  outline-color: rgb(67 20 7 / 0.1) !important;
}

.tw-outline-orange-950\/100 {
  outline-color: rgb(67 20 7 / 1) !important;
}

.tw-outline-orange-950\/15 {
  outline-color: rgb(67 20 7 / 0.15) !important;
}

.tw-outline-orange-950\/20 {
  outline-color: rgb(67 20 7 / 0.2) !important;
}

.tw-outline-orange-950\/25 {
  outline-color: rgb(67 20 7 / 0.25) !important;
}

.tw-outline-orange-950\/30 {
  outline-color: rgb(67 20 7 / 0.3) !important;
}

.tw-outline-orange-950\/35 {
  outline-color: rgb(67 20 7 / 0.35) !important;
}

.tw-outline-orange-950\/40 {
  outline-color: rgb(67 20 7 / 0.4) !important;
}

.tw-outline-orange-950\/45 {
  outline-color: rgb(67 20 7 / 0.45) !important;
}

.tw-outline-orange-950\/5 {
  outline-color: rgb(67 20 7 / 0.05) !important;
}

.tw-outline-orange-950\/50 {
  outline-color: rgb(67 20 7 / 0.5) !important;
}

.tw-outline-orange-950\/55 {
  outline-color: rgb(67 20 7 / 0.55) !important;
}

.tw-outline-orange-950\/60 {
  outline-color: rgb(67 20 7 / 0.6) !important;
}

.tw-outline-orange-950\/65 {
  outline-color: rgb(67 20 7 / 0.65) !important;
}

.tw-outline-orange-950\/70 {
  outline-color: rgb(67 20 7 / 0.7) !important;
}

.tw-outline-orange-950\/75 {
  outline-color: rgb(67 20 7 / 0.75) !important;
}

.tw-outline-orange-950\/80 {
  outline-color: rgb(67 20 7 / 0.8) !important;
}

.tw-outline-orange-950\/85 {
  outline-color: rgb(67 20 7 / 0.85) !important;
}

.tw-outline-orange-950\/90 {
  outline-color: rgb(67 20 7 / 0.9) !important;
}

.tw-outline-orange-950\/95 {
  outline-color: rgb(67 20 7 / 0.95) !important;
}

.tw-outline-pink-100 {
  outline-color: #fce7f3 !important;
}

.tw-outline-pink-100\/0 {
  outline-color: rgb(252 231 243 / 0) !important;
}

.tw-outline-pink-100\/10 {
  outline-color: rgb(252 231 243 / 0.1) !important;
}

.tw-outline-pink-100\/100 {
  outline-color: rgb(252 231 243 / 1) !important;
}

.tw-outline-pink-100\/15 {
  outline-color: rgb(252 231 243 / 0.15) !important;
}

.tw-outline-pink-100\/20 {
  outline-color: rgb(252 231 243 / 0.2) !important;
}

.tw-outline-pink-100\/25 {
  outline-color: rgb(252 231 243 / 0.25) !important;
}

.tw-outline-pink-100\/30 {
  outline-color: rgb(252 231 243 / 0.3) !important;
}

.tw-outline-pink-100\/35 {
  outline-color: rgb(252 231 243 / 0.35) !important;
}

.tw-outline-pink-100\/40 {
  outline-color: rgb(252 231 243 / 0.4) !important;
}

.tw-outline-pink-100\/45 {
  outline-color: rgb(252 231 243 / 0.45) !important;
}

.tw-outline-pink-100\/5 {
  outline-color: rgb(252 231 243 / 0.05) !important;
}

.tw-outline-pink-100\/50 {
  outline-color: rgb(252 231 243 / 0.5) !important;
}

.tw-outline-pink-100\/55 {
  outline-color: rgb(252 231 243 / 0.55) !important;
}

.tw-outline-pink-100\/60 {
  outline-color: rgb(252 231 243 / 0.6) !important;
}

.tw-outline-pink-100\/65 {
  outline-color: rgb(252 231 243 / 0.65) !important;
}

.tw-outline-pink-100\/70 {
  outline-color: rgb(252 231 243 / 0.7) !important;
}

.tw-outline-pink-100\/75 {
  outline-color: rgb(252 231 243 / 0.75) !important;
}

.tw-outline-pink-100\/80 {
  outline-color: rgb(252 231 243 / 0.8) !important;
}

.tw-outline-pink-100\/85 {
  outline-color: rgb(252 231 243 / 0.85) !important;
}

.tw-outline-pink-100\/90 {
  outline-color: rgb(252 231 243 / 0.9) !important;
}

.tw-outline-pink-100\/95 {
  outline-color: rgb(252 231 243 / 0.95) !important;
}

.tw-outline-pink-200 {
  outline-color: #fbcfe8 !important;
}

.tw-outline-pink-200\/0 {
  outline-color: rgb(251 207 232 / 0) !important;
}

.tw-outline-pink-200\/10 {
  outline-color: rgb(251 207 232 / 0.1) !important;
}

.tw-outline-pink-200\/100 {
  outline-color: rgb(251 207 232 / 1) !important;
}

.tw-outline-pink-200\/15 {
  outline-color: rgb(251 207 232 / 0.15) !important;
}

.tw-outline-pink-200\/20 {
  outline-color: rgb(251 207 232 / 0.2) !important;
}

.tw-outline-pink-200\/25 {
  outline-color: rgb(251 207 232 / 0.25) !important;
}

.tw-outline-pink-200\/30 {
  outline-color: rgb(251 207 232 / 0.3) !important;
}

.tw-outline-pink-200\/35 {
  outline-color: rgb(251 207 232 / 0.35) !important;
}

.tw-outline-pink-200\/40 {
  outline-color: rgb(251 207 232 / 0.4) !important;
}

.tw-outline-pink-200\/45 {
  outline-color: rgb(251 207 232 / 0.45) !important;
}

.tw-outline-pink-200\/5 {
  outline-color: rgb(251 207 232 / 0.05) !important;
}

.tw-outline-pink-200\/50 {
  outline-color: rgb(251 207 232 / 0.5) !important;
}

.tw-outline-pink-200\/55 {
  outline-color: rgb(251 207 232 / 0.55) !important;
}

.tw-outline-pink-200\/60 {
  outline-color: rgb(251 207 232 / 0.6) !important;
}

.tw-outline-pink-200\/65 {
  outline-color: rgb(251 207 232 / 0.65) !important;
}

.tw-outline-pink-200\/70 {
  outline-color: rgb(251 207 232 / 0.7) !important;
}

.tw-outline-pink-200\/75 {
  outline-color: rgb(251 207 232 / 0.75) !important;
}

.tw-outline-pink-200\/80 {
  outline-color: rgb(251 207 232 / 0.8) !important;
}

.tw-outline-pink-200\/85 {
  outline-color: rgb(251 207 232 / 0.85) !important;
}

.tw-outline-pink-200\/90 {
  outline-color: rgb(251 207 232 / 0.9) !important;
}

.tw-outline-pink-200\/95 {
  outline-color: rgb(251 207 232 / 0.95) !important;
}

.tw-outline-pink-300 {
  outline-color: #f9a8d4 !important;
}

.tw-outline-pink-300\/0 {
  outline-color: rgb(249 168 212 / 0) !important;
}

.tw-outline-pink-300\/10 {
  outline-color: rgb(249 168 212 / 0.1) !important;
}

.tw-outline-pink-300\/100 {
  outline-color: rgb(249 168 212 / 1) !important;
}

.tw-outline-pink-300\/15 {
  outline-color: rgb(249 168 212 / 0.15) !important;
}

.tw-outline-pink-300\/20 {
  outline-color: rgb(249 168 212 / 0.2) !important;
}

.tw-outline-pink-300\/25 {
  outline-color: rgb(249 168 212 / 0.25) !important;
}

.tw-outline-pink-300\/30 {
  outline-color: rgb(249 168 212 / 0.3) !important;
}

.tw-outline-pink-300\/35 {
  outline-color: rgb(249 168 212 / 0.35) !important;
}

.tw-outline-pink-300\/40 {
  outline-color: rgb(249 168 212 / 0.4) !important;
}

.tw-outline-pink-300\/45 {
  outline-color: rgb(249 168 212 / 0.45) !important;
}

.tw-outline-pink-300\/5 {
  outline-color: rgb(249 168 212 / 0.05) !important;
}

.tw-outline-pink-300\/50 {
  outline-color: rgb(249 168 212 / 0.5) !important;
}

.tw-outline-pink-300\/55 {
  outline-color: rgb(249 168 212 / 0.55) !important;
}

.tw-outline-pink-300\/60 {
  outline-color: rgb(249 168 212 / 0.6) !important;
}

.tw-outline-pink-300\/65 {
  outline-color: rgb(249 168 212 / 0.65) !important;
}

.tw-outline-pink-300\/70 {
  outline-color: rgb(249 168 212 / 0.7) !important;
}

.tw-outline-pink-300\/75 {
  outline-color: rgb(249 168 212 / 0.75) !important;
}

.tw-outline-pink-300\/80 {
  outline-color: rgb(249 168 212 / 0.8) !important;
}

.tw-outline-pink-300\/85 {
  outline-color: rgb(249 168 212 / 0.85) !important;
}

.tw-outline-pink-300\/90 {
  outline-color: rgb(249 168 212 / 0.9) !important;
}

.tw-outline-pink-300\/95 {
  outline-color: rgb(249 168 212 / 0.95) !important;
}

.tw-outline-pink-400 {
  outline-color: #f472b6 !important;
}

.tw-outline-pink-400\/0 {
  outline-color: rgb(244 114 182 / 0) !important;
}

.tw-outline-pink-400\/10 {
  outline-color: rgb(244 114 182 / 0.1) !important;
}

.tw-outline-pink-400\/100 {
  outline-color: rgb(244 114 182 / 1) !important;
}

.tw-outline-pink-400\/15 {
  outline-color: rgb(244 114 182 / 0.15) !important;
}

.tw-outline-pink-400\/20 {
  outline-color: rgb(244 114 182 / 0.2) !important;
}

.tw-outline-pink-400\/25 {
  outline-color: rgb(244 114 182 / 0.25) !important;
}

.tw-outline-pink-400\/30 {
  outline-color: rgb(244 114 182 / 0.3) !important;
}

.tw-outline-pink-400\/35 {
  outline-color: rgb(244 114 182 / 0.35) !important;
}

.tw-outline-pink-400\/40 {
  outline-color: rgb(244 114 182 / 0.4) !important;
}

.tw-outline-pink-400\/45 {
  outline-color: rgb(244 114 182 / 0.45) !important;
}

.tw-outline-pink-400\/5 {
  outline-color: rgb(244 114 182 / 0.05) !important;
}

.tw-outline-pink-400\/50 {
  outline-color: rgb(244 114 182 / 0.5) !important;
}

.tw-outline-pink-400\/55 {
  outline-color: rgb(244 114 182 / 0.55) !important;
}

.tw-outline-pink-400\/60 {
  outline-color: rgb(244 114 182 / 0.6) !important;
}

.tw-outline-pink-400\/65 {
  outline-color: rgb(244 114 182 / 0.65) !important;
}

.tw-outline-pink-400\/70 {
  outline-color: rgb(244 114 182 / 0.7) !important;
}

.tw-outline-pink-400\/75 {
  outline-color: rgb(244 114 182 / 0.75) !important;
}

.tw-outline-pink-400\/80 {
  outline-color: rgb(244 114 182 / 0.8) !important;
}

.tw-outline-pink-400\/85 {
  outline-color: rgb(244 114 182 / 0.85) !important;
}

.tw-outline-pink-400\/90 {
  outline-color: rgb(244 114 182 / 0.9) !important;
}

.tw-outline-pink-400\/95 {
  outline-color: rgb(244 114 182 / 0.95) !important;
}

.tw-outline-pink-50 {
  outline-color: #fdf2f8 !important;
}

.tw-outline-pink-50\/0 {
  outline-color: rgb(253 242 248 / 0) !important;
}

.tw-outline-pink-50\/10 {
  outline-color: rgb(253 242 248 / 0.1) !important;
}

.tw-outline-pink-50\/100 {
  outline-color: rgb(253 242 248 / 1) !important;
}

.tw-outline-pink-50\/15 {
  outline-color: rgb(253 242 248 / 0.15) !important;
}

.tw-outline-pink-50\/20 {
  outline-color: rgb(253 242 248 / 0.2) !important;
}

.tw-outline-pink-50\/25 {
  outline-color: rgb(253 242 248 / 0.25) !important;
}

.tw-outline-pink-50\/30 {
  outline-color: rgb(253 242 248 / 0.3) !important;
}

.tw-outline-pink-50\/35 {
  outline-color: rgb(253 242 248 / 0.35) !important;
}

.tw-outline-pink-50\/40 {
  outline-color: rgb(253 242 248 / 0.4) !important;
}

.tw-outline-pink-50\/45 {
  outline-color: rgb(253 242 248 / 0.45) !important;
}

.tw-outline-pink-50\/5 {
  outline-color: rgb(253 242 248 / 0.05) !important;
}

.tw-outline-pink-50\/50 {
  outline-color: rgb(253 242 248 / 0.5) !important;
}

.tw-outline-pink-50\/55 {
  outline-color: rgb(253 242 248 / 0.55) !important;
}

.tw-outline-pink-50\/60 {
  outline-color: rgb(253 242 248 / 0.6) !important;
}

.tw-outline-pink-50\/65 {
  outline-color: rgb(253 242 248 / 0.65) !important;
}

.tw-outline-pink-50\/70 {
  outline-color: rgb(253 242 248 / 0.7) !important;
}

.tw-outline-pink-50\/75 {
  outline-color: rgb(253 242 248 / 0.75) !important;
}

.tw-outline-pink-50\/80 {
  outline-color: rgb(253 242 248 / 0.8) !important;
}

.tw-outline-pink-50\/85 {
  outline-color: rgb(253 242 248 / 0.85) !important;
}

.tw-outline-pink-50\/90 {
  outline-color: rgb(253 242 248 / 0.9) !important;
}

.tw-outline-pink-50\/95 {
  outline-color: rgb(253 242 248 / 0.95) !important;
}

.tw-outline-pink-500 {
  outline-color: #ec4899 !important;
}

.tw-outline-pink-500\/0 {
  outline-color: rgb(236 72 153 / 0) !important;
}

.tw-outline-pink-500\/10 {
  outline-color: rgb(236 72 153 / 0.1) !important;
}

.tw-outline-pink-500\/100 {
  outline-color: rgb(236 72 153 / 1) !important;
}

.tw-outline-pink-500\/15 {
  outline-color: rgb(236 72 153 / 0.15) !important;
}

.tw-outline-pink-500\/20 {
  outline-color: rgb(236 72 153 / 0.2) !important;
}

.tw-outline-pink-500\/25 {
  outline-color: rgb(236 72 153 / 0.25) !important;
}

.tw-outline-pink-500\/30 {
  outline-color: rgb(236 72 153 / 0.3) !important;
}

.tw-outline-pink-500\/35 {
  outline-color: rgb(236 72 153 / 0.35) !important;
}

.tw-outline-pink-500\/40 {
  outline-color: rgb(236 72 153 / 0.4) !important;
}

.tw-outline-pink-500\/45 {
  outline-color: rgb(236 72 153 / 0.45) !important;
}

.tw-outline-pink-500\/5 {
  outline-color: rgb(236 72 153 / 0.05) !important;
}

.tw-outline-pink-500\/50 {
  outline-color: rgb(236 72 153 / 0.5) !important;
}

.tw-outline-pink-500\/55 {
  outline-color: rgb(236 72 153 / 0.55) !important;
}

.tw-outline-pink-500\/60 {
  outline-color: rgb(236 72 153 / 0.6) !important;
}

.tw-outline-pink-500\/65 {
  outline-color: rgb(236 72 153 / 0.65) !important;
}

.tw-outline-pink-500\/70 {
  outline-color: rgb(236 72 153 / 0.7) !important;
}

.tw-outline-pink-500\/75 {
  outline-color: rgb(236 72 153 / 0.75) !important;
}

.tw-outline-pink-500\/80 {
  outline-color: rgb(236 72 153 / 0.8) !important;
}

.tw-outline-pink-500\/85 {
  outline-color: rgb(236 72 153 / 0.85) !important;
}

.tw-outline-pink-500\/90 {
  outline-color: rgb(236 72 153 / 0.9) !important;
}

.tw-outline-pink-500\/95 {
  outline-color: rgb(236 72 153 / 0.95) !important;
}

.tw-outline-pink-600 {
  outline-color: #db2777 !important;
}

.tw-outline-pink-600\/0 {
  outline-color: rgb(219 39 119 / 0) !important;
}

.tw-outline-pink-600\/10 {
  outline-color: rgb(219 39 119 / 0.1) !important;
}

.tw-outline-pink-600\/100 {
  outline-color: rgb(219 39 119 / 1) !important;
}

.tw-outline-pink-600\/15 {
  outline-color: rgb(219 39 119 / 0.15) !important;
}

.tw-outline-pink-600\/20 {
  outline-color: rgb(219 39 119 / 0.2) !important;
}

.tw-outline-pink-600\/25 {
  outline-color: rgb(219 39 119 / 0.25) !important;
}

.tw-outline-pink-600\/30 {
  outline-color: rgb(219 39 119 / 0.3) !important;
}

.tw-outline-pink-600\/35 {
  outline-color: rgb(219 39 119 / 0.35) !important;
}

.tw-outline-pink-600\/40 {
  outline-color: rgb(219 39 119 / 0.4) !important;
}

.tw-outline-pink-600\/45 {
  outline-color: rgb(219 39 119 / 0.45) !important;
}

.tw-outline-pink-600\/5 {
  outline-color: rgb(219 39 119 / 0.05) !important;
}

.tw-outline-pink-600\/50 {
  outline-color: rgb(219 39 119 / 0.5) !important;
}

.tw-outline-pink-600\/55 {
  outline-color: rgb(219 39 119 / 0.55) !important;
}

.tw-outline-pink-600\/60 {
  outline-color: rgb(219 39 119 / 0.6) !important;
}

.tw-outline-pink-600\/65 {
  outline-color: rgb(219 39 119 / 0.65) !important;
}

.tw-outline-pink-600\/70 {
  outline-color: rgb(219 39 119 / 0.7) !important;
}

.tw-outline-pink-600\/75 {
  outline-color: rgb(219 39 119 / 0.75) !important;
}

.tw-outline-pink-600\/80 {
  outline-color: rgb(219 39 119 / 0.8) !important;
}

.tw-outline-pink-600\/85 {
  outline-color: rgb(219 39 119 / 0.85) !important;
}

.tw-outline-pink-600\/90 {
  outline-color: rgb(219 39 119 / 0.9) !important;
}

.tw-outline-pink-600\/95 {
  outline-color: rgb(219 39 119 / 0.95) !important;
}

.tw-outline-pink-700 {
  outline-color: #be185d !important;
}

.tw-outline-pink-700\/0 {
  outline-color: rgb(190 24 93 / 0) !important;
}

.tw-outline-pink-700\/10 {
  outline-color: rgb(190 24 93 / 0.1) !important;
}

.tw-outline-pink-700\/100 {
  outline-color: rgb(190 24 93 / 1) !important;
}

.tw-outline-pink-700\/15 {
  outline-color: rgb(190 24 93 / 0.15) !important;
}

.tw-outline-pink-700\/20 {
  outline-color: rgb(190 24 93 / 0.2) !important;
}

.tw-outline-pink-700\/25 {
  outline-color: rgb(190 24 93 / 0.25) !important;
}

.tw-outline-pink-700\/30 {
  outline-color: rgb(190 24 93 / 0.3) !important;
}

.tw-outline-pink-700\/35 {
  outline-color: rgb(190 24 93 / 0.35) !important;
}

.tw-outline-pink-700\/40 {
  outline-color: rgb(190 24 93 / 0.4) !important;
}

.tw-outline-pink-700\/45 {
  outline-color: rgb(190 24 93 / 0.45) !important;
}

.tw-outline-pink-700\/5 {
  outline-color: rgb(190 24 93 / 0.05) !important;
}

.tw-outline-pink-700\/50 {
  outline-color: rgb(190 24 93 / 0.5) !important;
}

.tw-outline-pink-700\/55 {
  outline-color: rgb(190 24 93 / 0.55) !important;
}

.tw-outline-pink-700\/60 {
  outline-color: rgb(190 24 93 / 0.6) !important;
}

.tw-outline-pink-700\/65 {
  outline-color: rgb(190 24 93 / 0.65) !important;
}

.tw-outline-pink-700\/70 {
  outline-color: rgb(190 24 93 / 0.7) !important;
}

.tw-outline-pink-700\/75 {
  outline-color: rgb(190 24 93 / 0.75) !important;
}

.tw-outline-pink-700\/80 {
  outline-color: rgb(190 24 93 / 0.8) !important;
}

.tw-outline-pink-700\/85 {
  outline-color: rgb(190 24 93 / 0.85) !important;
}

.tw-outline-pink-700\/90 {
  outline-color: rgb(190 24 93 / 0.9) !important;
}

.tw-outline-pink-700\/95 {
  outline-color: rgb(190 24 93 / 0.95) !important;
}

.tw-outline-pink-800 {
  outline-color: #9d174d !important;
}

.tw-outline-pink-800\/0 {
  outline-color: rgb(157 23 77 / 0) !important;
}

.tw-outline-pink-800\/10 {
  outline-color: rgb(157 23 77 / 0.1) !important;
}

.tw-outline-pink-800\/100 {
  outline-color: rgb(157 23 77 / 1) !important;
}

.tw-outline-pink-800\/15 {
  outline-color: rgb(157 23 77 / 0.15) !important;
}

.tw-outline-pink-800\/20 {
  outline-color: rgb(157 23 77 / 0.2) !important;
}

.tw-outline-pink-800\/25 {
  outline-color: rgb(157 23 77 / 0.25) !important;
}

.tw-outline-pink-800\/30 {
  outline-color: rgb(157 23 77 / 0.3) !important;
}

.tw-outline-pink-800\/35 {
  outline-color: rgb(157 23 77 / 0.35) !important;
}

.tw-outline-pink-800\/40 {
  outline-color: rgb(157 23 77 / 0.4) !important;
}

.tw-outline-pink-800\/45 {
  outline-color: rgb(157 23 77 / 0.45) !important;
}

.tw-outline-pink-800\/5 {
  outline-color: rgb(157 23 77 / 0.05) !important;
}

.tw-outline-pink-800\/50 {
  outline-color: rgb(157 23 77 / 0.5) !important;
}

.tw-outline-pink-800\/55 {
  outline-color: rgb(157 23 77 / 0.55) !important;
}

.tw-outline-pink-800\/60 {
  outline-color: rgb(157 23 77 / 0.6) !important;
}

.tw-outline-pink-800\/65 {
  outline-color: rgb(157 23 77 / 0.65) !important;
}

.tw-outline-pink-800\/70 {
  outline-color: rgb(157 23 77 / 0.7) !important;
}

.tw-outline-pink-800\/75 {
  outline-color: rgb(157 23 77 / 0.75) !important;
}

.tw-outline-pink-800\/80 {
  outline-color: rgb(157 23 77 / 0.8) !important;
}

.tw-outline-pink-800\/85 {
  outline-color: rgb(157 23 77 / 0.85) !important;
}

.tw-outline-pink-800\/90 {
  outline-color: rgb(157 23 77 / 0.9) !important;
}

.tw-outline-pink-800\/95 {
  outline-color: rgb(157 23 77 / 0.95) !important;
}

.tw-outline-pink-900 {
  outline-color: #831843 !important;
}

.tw-outline-pink-900\/0 {
  outline-color: rgb(131 24 67 / 0) !important;
}

.tw-outline-pink-900\/10 {
  outline-color: rgb(131 24 67 / 0.1) !important;
}

.tw-outline-pink-900\/100 {
  outline-color: rgb(131 24 67 / 1) !important;
}

.tw-outline-pink-900\/15 {
  outline-color: rgb(131 24 67 / 0.15) !important;
}

.tw-outline-pink-900\/20 {
  outline-color: rgb(131 24 67 / 0.2) !important;
}

.tw-outline-pink-900\/25 {
  outline-color: rgb(131 24 67 / 0.25) !important;
}

.tw-outline-pink-900\/30 {
  outline-color: rgb(131 24 67 / 0.3) !important;
}

.tw-outline-pink-900\/35 {
  outline-color: rgb(131 24 67 / 0.35) !important;
}

.tw-outline-pink-900\/40 {
  outline-color: rgb(131 24 67 / 0.4) !important;
}

.tw-outline-pink-900\/45 {
  outline-color: rgb(131 24 67 / 0.45) !important;
}

.tw-outline-pink-900\/5 {
  outline-color: rgb(131 24 67 / 0.05) !important;
}

.tw-outline-pink-900\/50 {
  outline-color: rgb(131 24 67 / 0.5) !important;
}

.tw-outline-pink-900\/55 {
  outline-color: rgb(131 24 67 / 0.55) !important;
}

.tw-outline-pink-900\/60 {
  outline-color: rgb(131 24 67 / 0.6) !important;
}

.tw-outline-pink-900\/65 {
  outline-color: rgb(131 24 67 / 0.65) !important;
}

.tw-outline-pink-900\/70 {
  outline-color: rgb(131 24 67 / 0.7) !important;
}

.tw-outline-pink-900\/75 {
  outline-color: rgb(131 24 67 / 0.75) !important;
}

.tw-outline-pink-900\/80 {
  outline-color: rgb(131 24 67 / 0.8) !important;
}

.tw-outline-pink-900\/85 {
  outline-color: rgb(131 24 67 / 0.85) !important;
}

.tw-outline-pink-900\/90 {
  outline-color: rgb(131 24 67 / 0.9) !important;
}

.tw-outline-pink-900\/95 {
  outline-color: rgb(131 24 67 / 0.95) !important;
}

.tw-outline-pink-950 {
  outline-color: #500724 !important;
}

.tw-outline-pink-950\/0 {
  outline-color: rgb(80 7 36 / 0) !important;
}

.tw-outline-pink-950\/10 {
  outline-color: rgb(80 7 36 / 0.1) !important;
}

.tw-outline-pink-950\/100 {
  outline-color: rgb(80 7 36 / 1) !important;
}

.tw-outline-pink-950\/15 {
  outline-color: rgb(80 7 36 / 0.15) !important;
}

.tw-outline-pink-950\/20 {
  outline-color: rgb(80 7 36 / 0.2) !important;
}

.tw-outline-pink-950\/25 {
  outline-color: rgb(80 7 36 / 0.25) !important;
}

.tw-outline-pink-950\/30 {
  outline-color: rgb(80 7 36 / 0.3) !important;
}

.tw-outline-pink-950\/35 {
  outline-color: rgb(80 7 36 / 0.35) !important;
}

.tw-outline-pink-950\/40 {
  outline-color: rgb(80 7 36 / 0.4) !important;
}

.tw-outline-pink-950\/45 {
  outline-color: rgb(80 7 36 / 0.45) !important;
}

.tw-outline-pink-950\/5 {
  outline-color: rgb(80 7 36 / 0.05) !important;
}

.tw-outline-pink-950\/50 {
  outline-color: rgb(80 7 36 / 0.5) !important;
}

.tw-outline-pink-950\/55 {
  outline-color: rgb(80 7 36 / 0.55) !important;
}

.tw-outline-pink-950\/60 {
  outline-color: rgb(80 7 36 / 0.6) !important;
}

.tw-outline-pink-950\/65 {
  outline-color: rgb(80 7 36 / 0.65) !important;
}

.tw-outline-pink-950\/70 {
  outline-color: rgb(80 7 36 / 0.7) !important;
}

.tw-outline-pink-950\/75 {
  outline-color: rgb(80 7 36 / 0.75) !important;
}

.tw-outline-pink-950\/80 {
  outline-color: rgb(80 7 36 / 0.8) !important;
}

.tw-outline-pink-950\/85 {
  outline-color: rgb(80 7 36 / 0.85) !important;
}

.tw-outline-pink-950\/90 {
  outline-color: rgb(80 7 36 / 0.9) !important;
}

.tw-outline-pink-950\/95 {
  outline-color: rgb(80 7 36 / 0.95) !important;
}

.tw-outline-purple-100 {
  outline-color: #f3e8ff !important;
}

.tw-outline-purple-100\/0 {
  outline-color: rgb(243 232 255 / 0) !important;
}

.tw-outline-purple-100\/10 {
  outline-color: rgb(243 232 255 / 0.1) !important;
}

.tw-outline-purple-100\/100 {
  outline-color: rgb(243 232 255 / 1) !important;
}

.tw-outline-purple-100\/15 {
  outline-color: rgb(243 232 255 / 0.15) !important;
}

.tw-outline-purple-100\/20 {
  outline-color: rgb(243 232 255 / 0.2) !important;
}

.tw-outline-purple-100\/25 {
  outline-color: rgb(243 232 255 / 0.25) !important;
}

.tw-outline-purple-100\/30 {
  outline-color: rgb(243 232 255 / 0.3) !important;
}

.tw-outline-purple-100\/35 {
  outline-color: rgb(243 232 255 / 0.35) !important;
}

.tw-outline-purple-100\/40 {
  outline-color: rgb(243 232 255 / 0.4) !important;
}

.tw-outline-purple-100\/45 {
  outline-color: rgb(243 232 255 / 0.45) !important;
}

.tw-outline-purple-100\/5 {
  outline-color: rgb(243 232 255 / 0.05) !important;
}

.tw-outline-purple-100\/50 {
  outline-color: rgb(243 232 255 / 0.5) !important;
}

.tw-outline-purple-100\/55 {
  outline-color: rgb(243 232 255 / 0.55) !important;
}

.tw-outline-purple-100\/60 {
  outline-color: rgb(243 232 255 / 0.6) !important;
}

.tw-outline-purple-100\/65 {
  outline-color: rgb(243 232 255 / 0.65) !important;
}

.tw-outline-purple-100\/70 {
  outline-color: rgb(243 232 255 / 0.7) !important;
}

.tw-outline-purple-100\/75 {
  outline-color: rgb(243 232 255 / 0.75) !important;
}

.tw-outline-purple-100\/80 {
  outline-color: rgb(243 232 255 / 0.8) !important;
}

.tw-outline-purple-100\/85 {
  outline-color: rgb(243 232 255 / 0.85) !important;
}

.tw-outline-purple-100\/90 {
  outline-color: rgb(243 232 255 / 0.9) !important;
}

.tw-outline-purple-100\/95 {
  outline-color: rgb(243 232 255 / 0.95) !important;
}

.tw-outline-purple-200 {
  outline-color: #e9d5ff !important;
}

.tw-outline-purple-200\/0 {
  outline-color: rgb(233 213 255 / 0) !important;
}

.tw-outline-purple-200\/10 {
  outline-color: rgb(233 213 255 / 0.1) !important;
}

.tw-outline-purple-200\/100 {
  outline-color: rgb(233 213 255 / 1) !important;
}

.tw-outline-purple-200\/15 {
  outline-color: rgb(233 213 255 / 0.15) !important;
}

.tw-outline-purple-200\/20 {
  outline-color: rgb(233 213 255 / 0.2) !important;
}

.tw-outline-purple-200\/25 {
  outline-color: rgb(233 213 255 / 0.25) !important;
}

.tw-outline-purple-200\/30 {
  outline-color: rgb(233 213 255 / 0.3) !important;
}

.tw-outline-purple-200\/35 {
  outline-color: rgb(233 213 255 / 0.35) !important;
}

.tw-outline-purple-200\/40 {
  outline-color: rgb(233 213 255 / 0.4) !important;
}

.tw-outline-purple-200\/45 {
  outline-color: rgb(233 213 255 / 0.45) !important;
}

.tw-outline-purple-200\/5 {
  outline-color: rgb(233 213 255 / 0.05) !important;
}

.tw-outline-purple-200\/50 {
  outline-color: rgb(233 213 255 / 0.5) !important;
}

.tw-outline-purple-200\/55 {
  outline-color: rgb(233 213 255 / 0.55) !important;
}

.tw-outline-purple-200\/60 {
  outline-color: rgb(233 213 255 / 0.6) !important;
}

.tw-outline-purple-200\/65 {
  outline-color: rgb(233 213 255 / 0.65) !important;
}

.tw-outline-purple-200\/70 {
  outline-color: rgb(233 213 255 / 0.7) !important;
}

.tw-outline-purple-200\/75 {
  outline-color: rgb(233 213 255 / 0.75) !important;
}

.tw-outline-purple-200\/80 {
  outline-color: rgb(233 213 255 / 0.8) !important;
}

.tw-outline-purple-200\/85 {
  outline-color: rgb(233 213 255 / 0.85) !important;
}

.tw-outline-purple-200\/90 {
  outline-color: rgb(233 213 255 / 0.9) !important;
}

.tw-outline-purple-200\/95 {
  outline-color: rgb(233 213 255 / 0.95) !important;
}

.tw-outline-purple-300 {
  outline-color: #d8b4fe !important;
}

.tw-outline-purple-300\/0 {
  outline-color: rgb(216 180 254 / 0) !important;
}

.tw-outline-purple-300\/10 {
  outline-color: rgb(216 180 254 / 0.1) !important;
}

.tw-outline-purple-300\/100 {
  outline-color: rgb(216 180 254 / 1) !important;
}

.tw-outline-purple-300\/15 {
  outline-color: rgb(216 180 254 / 0.15) !important;
}

.tw-outline-purple-300\/20 {
  outline-color: rgb(216 180 254 / 0.2) !important;
}

.tw-outline-purple-300\/25 {
  outline-color: rgb(216 180 254 / 0.25) !important;
}

.tw-outline-purple-300\/30 {
  outline-color: rgb(216 180 254 / 0.3) !important;
}

.tw-outline-purple-300\/35 {
  outline-color: rgb(216 180 254 / 0.35) !important;
}

.tw-outline-purple-300\/40 {
  outline-color: rgb(216 180 254 / 0.4) !important;
}

.tw-outline-purple-300\/45 {
  outline-color: rgb(216 180 254 / 0.45) !important;
}

.tw-outline-purple-300\/5 {
  outline-color: rgb(216 180 254 / 0.05) !important;
}

.tw-outline-purple-300\/50 {
  outline-color: rgb(216 180 254 / 0.5) !important;
}

.tw-outline-purple-300\/55 {
  outline-color: rgb(216 180 254 / 0.55) !important;
}

.tw-outline-purple-300\/60 {
  outline-color: rgb(216 180 254 / 0.6) !important;
}

.tw-outline-purple-300\/65 {
  outline-color: rgb(216 180 254 / 0.65) !important;
}

.tw-outline-purple-300\/70 {
  outline-color: rgb(216 180 254 / 0.7) !important;
}

.tw-outline-purple-300\/75 {
  outline-color: rgb(216 180 254 / 0.75) !important;
}

.tw-outline-purple-300\/80 {
  outline-color: rgb(216 180 254 / 0.8) !important;
}

.tw-outline-purple-300\/85 {
  outline-color: rgb(216 180 254 / 0.85) !important;
}

.tw-outline-purple-300\/90 {
  outline-color: rgb(216 180 254 / 0.9) !important;
}

.tw-outline-purple-300\/95 {
  outline-color: rgb(216 180 254 / 0.95) !important;
}

.tw-outline-purple-400 {
  outline-color: #c084fc !important;
}

.tw-outline-purple-400\/0 {
  outline-color: rgb(192 132 252 / 0) !important;
}

.tw-outline-purple-400\/10 {
  outline-color: rgb(192 132 252 / 0.1) !important;
}

.tw-outline-purple-400\/100 {
  outline-color: rgb(192 132 252 / 1) !important;
}

.tw-outline-purple-400\/15 {
  outline-color: rgb(192 132 252 / 0.15) !important;
}

.tw-outline-purple-400\/20 {
  outline-color: rgb(192 132 252 / 0.2) !important;
}

.tw-outline-purple-400\/25 {
  outline-color: rgb(192 132 252 / 0.25) !important;
}

.tw-outline-purple-400\/30 {
  outline-color: rgb(192 132 252 / 0.3) !important;
}

.tw-outline-purple-400\/35 {
  outline-color: rgb(192 132 252 / 0.35) !important;
}

.tw-outline-purple-400\/40 {
  outline-color: rgb(192 132 252 / 0.4) !important;
}

.tw-outline-purple-400\/45 {
  outline-color: rgb(192 132 252 / 0.45) !important;
}

.tw-outline-purple-400\/5 {
  outline-color: rgb(192 132 252 / 0.05) !important;
}

.tw-outline-purple-400\/50 {
  outline-color: rgb(192 132 252 / 0.5) !important;
}

.tw-outline-purple-400\/55 {
  outline-color: rgb(192 132 252 / 0.55) !important;
}

.tw-outline-purple-400\/60 {
  outline-color: rgb(192 132 252 / 0.6) !important;
}

.tw-outline-purple-400\/65 {
  outline-color: rgb(192 132 252 / 0.65) !important;
}

.tw-outline-purple-400\/70 {
  outline-color: rgb(192 132 252 / 0.7) !important;
}

.tw-outline-purple-400\/75 {
  outline-color: rgb(192 132 252 / 0.75) !important;
}

.tw-outline-purple-400\/80 {
  outline-color: rgb(192 132 252 / 0.8) !important;
}

.tw-outline-purple-400\/85 {
  outline-color: rgb(192 132 252 / 0.85) !important;
}

.tw-outline-purple-400\/90 {
  outline-color: rgb(192 132 252 / 0.9) !important;
}

.tw-outline-purple-400\/95 {
  outline-color: rgb(192 132 252 / 0.95) !important;
}

.tw-outline-purple-50 {
  outline-color: #faf5ff !important;
}

.tw-outline-purple-50\/0 {
  outline-color: rgb(250 245 255 / 0) !important;
}

.tw-outline-purple-50\/10 {
  outline-color: rgb(250 245 255 / 0.1) !important;
}

.tw-outline-purple-50\/100 {
  outline-color: rgb(250 245 255 / 1) !important;
}

.tw-outline-purple-50\/15 {
  outline-color: rgb(250 245 255 / 0.15) !important;
}

.tw-outline-purple-50\/20 {
  outline-color: rgb(250 245 255 / 0.2) !important;
}

.tw-outline-purple-50\/25 {
  outline-color: rgb(250 245 255 / 0.25) !important;
}

.tw-outline-purple-50\/30 {
  outline-color: rgb(250 245 255 / 0.3) !important;
}

.tw-outline-purple-50\/35 {
  outline-color: rgb(250 245 255 / 0.35) !important;
}

.tw-outline-purple-50\/40 {
  outline-color: rgb(250 245 255 / 0.4) !important;
}

.tw-outline-purple-50\/45 {
  outline-color: rgb(250 245 255 / 0.45) !important;
}

.tw-outline-purple-50\/5 {
  outline-color: rgb(250 245 255 / 0.05) !important;
}

.tw-outline-purple-50\/50 {
  outline-color: rgb(250 245 255 / 0.5) !important;
}

.tw-outline-purple-50\/55 {
  outline-color: rgb(250 245 255 / 0.55) !important;
}

.tw-outline-purple-50\/60 {
  outline-color: rgb(250 245 255 / 0.6) !important;
}

.tw-outline-purple-50\/65 {
  outline-color: rgb(250 245 255 / 0.65) !important;
}

.tw-outline-purple-50\/70 {
  outline-color: rgb(250 245 255 / 0.7) !important;
}

.tw-outline-purple-50\/75 {
  outline-color: rgb(250 245 255 / 0.75) !important;
}

.tw-outline-purple-50\/80 {
  outline-color: rgb(250 245 255 / 0.8) !important;
}

.tw-outline-purple-50\/85 {
  outline-color: rgb(250 245 255 / 0.85) !important;
}

.tw-outline-purple-50\/90 {
  outline-color: rgb(250 245 255 / 0.9) !important;
}

.tw-outline-purple-50\/95 {
  outline-color: rgb(250 245 255 / 0.95) !important;
}

.tw-outline-purple-500 {
  outline-color: #a855f7 !important;
}

.tw-outline-purple-500\/0 {
  outline-color: rgb(168 85 247 / 0) !important;
}

.tw-outline-purple-500\/10 {
  outline-color: rgb(168 85 247 / 0.1) !important;
}

.tw-outline-purple-500\/100 {
  outline-color: rgb(168 85 247 / 1) !important;
}

.tw-outline-purple-500\/15 {
  outline-color: rgb(168 85 247 / 0.15) !important;
}

.tw-outline-purple-500\/20 {
  outline-color: rgb(168 85 247 / 0.2) !important;
}

.tw-outline-purple-500\/25 {
  outline-color: rgb(168 85 247 / 0.25) !important;
}

.tw-outline-purple-500\/30 {
  outline-color: rgb(168 85 247 / 0.3) !important;
}

.tw-outline-purple-500\/35 {
  outline-color: rgb(168 85 247 / 0.35) !important;
}

.tw-outline-purple-500\/40 {
  outline-color: rgb(168 85 247 / 0.4) !important;
}

.tw-outline-purple-500\/45 {
  outline-color: rgb(168 85 247 / 0.45) !important;
}

.tw-outline-purple-500\/5 {
  outline-color: rgb(168 85 247 / 0.05) !important;
}

.tw-outline-purple-500\/50 {
  outline-color: rgb(168 85 247 / 0.5) !important;
}

.tw-outline-purple-500\/55 {
  outline-color: rgb(168 85 247 / 0.55) !important;
}

.tw-outline-purple-500\/60 {
  outline-color: rgb(168 85 247 / 0.6) !important;
}

.tw-outline-purple-500\/65 {
  outline-color: rgb(168 85 247 / 0.65) !important;
}

.tw-outline-purple-500\/70 {
  outline-color: rgb(168 85 247 / 0.7) !important;
}

.tw-outline-purple-500\/75 {
  outline-color: rgb(168 85 247 / 0.75) !important;
}

.tw-outline-purple-500\/80 {
  outline-color: rgb(168 85 247 / 0.8) !important;
}

.tw-outline-purple-500\/85 {
  outline-color: rgb(168 85 247 / 0.85) !important;
}

.tw-outline-purple-500\/90 {
  outline-color: rgb(168 85 247 / 0.9) !important;
}

.tw-outline-purple-500\/95 {
  outline-color: rgb(168 85 247 / 0.95) !important;
}

.tw-outline-purple-600 {
  outline-color: #9333ea !important;
}

.tw-outline-purple-600\/0 {
  outline-color: rgb(147 51 234 / 0) !important;
}

.tw-outline-purple-600\/10 {
  outline-color: rgb(147 51 234 / 0.1) !important;
}

.tw-outline-purple-600\/100 {
  outline-color: rgb(147 51 234 / 1) !important;
}

.tw-outline-purple-600\/15 {
  outline-color: rgb(147 51 234 / 0.15) !important;
}

.tw-outline-purple-600\/20 {
  outline-color: rgb(147 51 234 / 0.2) !important;
}

.tw-outline-purple-600\/25 {
  outline-color: rgb(147 51 234 / 0.25) !important;
}

.tw-outline-purple-600\/30 {
  outline-color: rgb(147 51 234 / 0.3) !important;
}

.tw-outline-purple-600\/35 {
  outline-color: rgb(147 51 234 / 0.35) !important;
}

.tw-outline-purple-600\/40 {
  outline-color: rgb(147 51 234 / 0.4) !important;
}

.tw-outline-purple-600\/45 {
  outline-color: rgb(147 51 234 / 0.45) !important;
}

.tw-outline-purple-600\/5 {
  outline-color: rgb(147 51 234 / 0.05) !important;
}

.tw-outline-purple-600\/50 {
  outline-color: rgb(147 51 234 / 0.5) !important;
}

.tw-outline-purple-600\/55 {
  outline-color: rgb(147 51 234 / 0.55) !important;
}

.tw-outline-purple-600\/60 {
  outline-color: rgb(147 51 234 / 0.6) !important;
}

.tw-outline-purple-600\/65 {
  outline-color: rgb(147 51 234 / 0.65) !important;
}

.tw-outline-purple-600\/70 {
  outline-color: rgb(147 51 234 / 0.7) !important;
}

.tw-outline-purple-600\/75 {
  outline-color: rgb(147 51 234 / 0.75) !important;
}

.tw-outline-purple-600\/80 {
  outline-color: rgb(147 51 234 / 0.8) !important;
}

.tw-outline-purple-600\/85 {
  outline-color: rgb(147 51 234 / 0.85) !important;
}

.tw-outline-purple-600\/90 {
  outline-color: rgb(147 51 234 / 0.9) !important;
}

.tw-outline-purple-600\/95 {
  outline-color: rgb(147 51 234 / 0.95) !important;
}

.tw-outline-purple-700 {
  outline-color: #7e22ce !important;
}

.tw-outline-purple-700\/0 {
  outline-color: rgb(126 34 206 / 0) !important;
}

.tw-outline-purple-700\/10 {
  outline-color: rgb(126 34 206 / 0.1) !important;
}

.tw-outline-purple-700\/100 {
  outline-color: rgb(126 34 206 / 1) !important;
}

.tw-outline-purple-700\/15 {
  outline-color: rgb(126 34 206 / 0.15) !important;
}

.tw-outline-purple-700\/20 {
  outline-color: rgb(126 34 206 / 0.2) !important;
}

.tw-outline-purple-700\/25 {
  outline-color: rgb(126 34 206 / 0.25) !important;
}

.tw-outline-purple-700\/30 {
  outline-color: rgb(126 34 206 / 0.3) !important;
}

.tw-outline-purple-700\/35 {
  outline-color: rgb(126 34 206 / 0.35) !important;
}

.tw-outline-purple-700\/40 {
  outline-color: rgb(126 34 206 / 0.4) !important;
}

.tw-outline-purple-700\/45 {
  outline-color: rgb(126 34 206 / 0.45) !important;
}

.tw-outline-purple-700\/5 {
  outline-color: rgb(126 34 206 / 0.05) !important;
}

.tw-outline-purple-700\/50 {
  outline-color: rgb(126 34 206 / 0.5) !important;
}

.tw-outline-purple-700\/55 {
  outline-color: rgb(126 34 206 / 0.55) !important;
}

.tw-outline-purple-700\/60 {
  outline-color: rgb(126 34 206 / 0.6) !important;
}

.tw-outline-purple-700\/65 {
  outline-color: rgb(126 34 206 / 0.65) !important;
}

.tw-outline-purple-700\/70 {
  outline-color: rgb(126 34 206 / 0.7) !important;
}

.tw-outline-purple-700\/75 {
  outline-color: rgb(126 34 206 / 0.75) !important;
}

.tw-outline-purple-700\/80 {
  outline-color: rgb(126 34 206 / 0.8) !important;
}

.tw-outline-purple-700\/85 {
  outline-color: rgb(126 34 206 / 0.85) !important;
}

.tw-outline-purple-700\/90 {
  outline-color: rgb(126 34 206 / 0.9) !important;
}

.tw-outline-purple-700\/95 {
  outline-color: rgb(126 34 206 / 0.95) !important;
}

.tw-outline-purple-800 {
  outline-color: #6b21a8 !important;
}

.tw-outline-purple-800\/0 {
  outline-color: rgb(107 33 168 / 0) !important;
}

.tw-outline-purple-800\/10 {
  outline-color: rgb(107 33 168 / 0.1) !important;
}

.tw-outline-purple-800\/100 {
  outline-color: rgb(107 33 168 / 1) !important;
}

.tw-outline-purple-800\/15 {
  outline-color: rgb(107 33 168 / 0.15) !important;
}

.tw-outline-purple-800\/20 {
  outline-color: rgb(107 33 168 / 0.2) !important;
}

.tw-outline-purple-800\/25 {
  outline-color: rgb(107 33 168 / 0.25) !important;
}

.tw-outline-purple-800\/30 {
  outline-color: rgb(107 33 168 / 0.3) !important;
}

.tw-outline-purple-800\/35 {
  outline-color: rgb(107 33 168 / 0.35) !important;
}

.tw-outline-purple-800\/40 {
  outline-color: rgb(107 33 168 / 0.4) !important;
}

.tw-outline-purple-800\/45 {
  outline-color: rgb(107 33 168 / 0.45) !important;
}

.tw-outline-purple-800\/5 {
  outline-color: rgb(107 33 168 / 0.05) !important;
}

.tw-outline-purple-800\/50 {
  outline-color: rgb(107 33 168 / 0.5) !important;
}

.tw-outline-purple-800\/55 {
  outline-color: rgb(107 33 168 / 0.55) !important;
}

.tw-outline-purple-800\/60 {
  outline-color: rgb(107 33 168 / 0.6) !important;
}

.tw-outline-purple-800\/65 {
  outline-color: rgb(107 33 168 / 0.65) !important;
}

.tw-outline-purple-800\/70 {
  outline-color: rgb(107 33 168 / 0.7) !important;
}

.tw-outline-purple-800\/75 {
  outline-color: rgb(107 33 168 / 0.75) !important;
}

.tw-outline-purple-800\/80 {
  outline-color: rgb(107 33 168 / 0.8) !important;
}

.tw-outline-purple-800\/85 {
  outline-color: rgb(107 33 168 / 0.85) !important;
}

.tw-outline-purple-800\/90 {
  outline-color: rgb(107 33 168 / 0.9) !important;
}

.tw-outline-purple-800\/95 {
  outline-color: rgb(107 33 168 / 0.95) !important;
}

.tw-outline-purple-900 {
  outline-color: #581c87 !important;
}

.tw-outline-purple-900\/0 {
  outline-color: rgb(88 28 135 / 0) !important;
}

.tw-outline-purple-900\/10 {
  outline-color: rgb(88 28 135 / 0.1) !important;
}

.tw-outline-purple-900\/100 {
  outline-color: rgb(88 28 135 / 1) !important;
}

.tw-outline-purple-900\/15 {
  outline-color: rgb(88 28 135 / 0.15) !important;
}

.tw-outline-purple-900\/20 {
  outline-color: rgb(88 28 135 / 0.2) !important;
}

.tw-outline-purple-900\/25 {
  outline-color: rgb(88 28 135 / 0.25) !important;
}

.tw-outline-purple-900\/30 {
  outline-color: rgb(88 28 135 / 0.3) !important;
}

.tw-outline-purple-900\/35 {
  outline-color: rgb(88 28 135 / 0.35) !important;
}

.tw-outline-purple-900\/40 {
  outline-color: rgb(88 28 135 / 0.4) !important;
}

.tw-outline-purple-900\/45 {
  outline-color: rgb(88 28 135 / 0.45) !important;
}

.tw-outline-purple-900\/5 {
  outline-color: rgb(88 28 135 / 0.05) !important;
}

.tw-outline-purple-900\/50 {
  outline-color: rgb(88 28 135 / 0.5) !important;
}

.tw-outline-purple-900\/55 {
  outline-color: rgb(88 28 135 / 0.55) !important;
}

.tw-outline-purple-900\/60 {
  outline-color: rgb(88 28 135 / 0.6) !important;
}

.tw-outline-purple-900\/65 {
  outline-color: rgb(88 28 135 / 0.65) !important;
}

.tw-outline-purple-900\/70 {
  outline-color: rgb(88 28 135 / 0.7) !important;
}

.tw-outline-purple-900\/75 {
  outline-color: rgb(88 28 135 / 0.75) !important;
}

.tw-outline-purple-900\/80 {
  outline-color: rgb(88 28 135 / 0.8) !important;
}

.tw-outline-purple-900\/85 {
  outline-color: rgb(88 28 135 / 0.85) !important;
}

.tw-outline-purple-900\/90 {
  outline-color: rgb(88 28 135 / 0.9) !important;
}

.tw-outline-purple-900\/95 {
  outline-color: rgb(88 28 135 / 0.95) !important;
}

.tw-outline-purple-950 {
  outline-color: #3b0764 !important;
}

.tw-outline-purple-950\/0 {
  outline-color: rgb(59 7 100 / 0) !important;
}

.tw-outline-purple-950\/10 {
  outline-color: rgb(59 7 100 / 0.1) !important;
}

.tw-outline-purple-950\/100 {
  outline-color: rgb(59 7 100 / 1) !important;
}

.tw-outline-purple-950\/15 {
  outline-color: rgb(59 7 100 / 0.15) !important;
}

.tw-outline-purple-950\/20 {
  outline-color: rgb(59 7 100 / 0.2) !important;
}

.tw-outline-purple-950\/25 {
  outline-color: rgb(59 7 100 / 0.25) !important;
}

.tw-outline-purple-950\/30 {
  outline-color: rgb(59 7 100 / 0.3) !important;
}

.tw-outline-purple-950\/35 {
  outline-color: rgb(59 7 100 / 0.35) !important;
}

.tw-outline-purple-950\/40 {
  outline-color: rgb(59 7 100 / 0.4) !important;
}

.tw-outline-purple-950\/45 {
  outline-color: rgb(59 7 100 / 0.45) !important;
}

.tw-outline-purple-950\/5 {
  outline-color: rgb(59 7 100 / 0.05) !important;
}

.tw-outline-purple-950\/50 {
  outline-color: rgb(59 7 100 / 0.5) !important;
}

.tw-outline-purple-950\/55 {
  outline-color: rgb(59 7 100 / 0.55) !important;
}

.tw-outline-purple-950\/60 {
  outline-color: rgb(59 7 100 / 0.6) !important;
}

.tw-outline-purple-950\/65 {
  outline-color: rgb(59 7 100 / 0.65) !important;
}

.tw-outline-purple-950\/70 {
  outline-color: rgb(59 7 100 / 0.7) !important;
}

.tw-outline-purple-950\/75 {
  outline-color: rgb(59 7 100 / 0.75) !important;
}

.tw-outline-purple-950\/80 {
  outline-color: rgb(59 7 100 / 0.8) !important;
}

.tw-outline-purple-950\/85 {
  outline-color: rgb(59 7 100 / 0.85) !important;
}

.tw-outline-purple-950\/90 {
  outline-color: rgb(59 7 100 / 0.9) !important;
}

.tw-outline-purple-950\/95 {
  outline-color: rgb(59 7 100 / 0.95) !important;
}

.tw-outline-red-100 {
  outline-color: #fee2e2 !important;
}

.tw-outline-red-100\/0 {
  outline-color: rgb(254 226 226 / 0) !important;
}

.tw-outline-red-100\/10 {
  outline-color: rgb(254 226 226 / 0.1) !important;
}

.tw-outline-red-100\/100 {
  outline-color: rgb(254 226 226 / 1) !important;
}

.tw-outline-red-100\/15 {
  outline-color: rgb(254 226 226 / 0.15) !important;
}

.tw-outline-red-100\/20 {
  outline-color: rgb(254 226 226 / 0.2) !important;
}

.tw-outline-red-100\/25 {
  outline-color: rgb(254 226 226 / 0.25) !important;
}

.tw-outline-red-100\/30 {
  outline-color: rgb(254 226 226 / 0.3) !important;
}

.tw-outline-red-100\/35 {
  outline-color: rgb(254 226 226 / 0.35) !important;
}

.tw-outline-red-100\/40 {
  outline-color: rgb(254 226 226 / 0.4) !important;
}

.tw-outline-red-100\/45 {
  outline-color: rgb(254 226 226 / 0.45) !important;
}

.tw-outline-red-100\/5 {
  outline-color: rgb(254 226 226 / 0.05) !important;
}

.tw-outline-red-100\/50 {
  outline-color: rgb(254 226 226 / 0.5) !important;
}

.tw-outline-red-100\/55 {
  outline-color: rgb(254 226 226 / 0.55) !important;
}

.tw-outline-red-100\/60 {
  outline-color: rgb(254 226 226 / 0.6) !important;
}

.tw-outline-red-100\/65 {
  outline-color: rgb(254 226 226 / 0.65) !important;
}

.tw-outline-red-100\/70 {
  outline-color: rgb(254 226 226 / 0.7) !important;
}

.tw-outline-red-100\/75 {
  outline-color: rgb(254 226 226 / 0.75) !important;
}

.tw-outline-red-100\/80 {
  outline-color: rgb(254 226 226 / 0.8) !important;
}

.tw-outline-red-100\/85 {
  outline-color: rgb(254 226 226 / 0.85) !important;
}

.tw-outline-red-100\/90 {
  outline-color: rgb(254 226 226 / 0.9) !important;
}

.tw-outline-red-100\/95 {
  outline-color: rgb(254 226 226 / 0.95) !important;
}

.tw-outline-red-200 {
  outline-color: #fecaca !important;
}

.tw-outline-red-200\/0 {
  outline-color: rgb(254 202 202 / 0) !important;
}

.tw-outline-red-200\/10 {
  outline-color: rgb(254 202 202 / 0.1) !important;
}

.tw-outline-red-200\/100 {
  outline-color: rgb(254 202 202 / 1) !important;
}

.tw-outline-red-200\/15 {
  outline-color: rgb(254 202 202 / 0.15) !important;
}

.tw-outline-red-200\/20 {
  outline-color: rgb(254 202 202 / 0.2) !important;
}

.tw-outline-red-200\/25 {
  outline-color: rgb(254 202 202 / 0.25) !important;
}

.tw-outline-red-200\/30 {
  outline-color: rgb(254 202 202 / 0.3) !important;
}

.tw-outline-red-200\/35 {
  outline-color: rgb(254 202 202 / 0.35) !important;
}

.tw-outline-red-200\/40 {
  outline-color: rgb(254 202 202 / 0.4) !important;
}

.tw-outline-red-200\/45 {
  outline-color: rgb(254 202 202 / 0.45) !important;
}

.tw-outline-red-200\/5 {
  outline-color: rgb(254 202 202 / 0.05) !important;
}

.tw-outline-red-200\/50 {
  outline-color: rgb(254 202 202 / 0.5) !important;
}

.tw-outline-red-200\/55 {
  outline-color: rgb(254 202 202 / 0.55) !important;
}

.tw-outline-red-200\/60 {
  outline-color: rgb(254 202 202 / 0.6) !important;
}

.tw-outline-red-200\/65 {
  outline-color: rgb(254 202 202 / 0.65) !important;
}

.tw-outline-red-200\/70 {
  outline-color: rgb(254 202 202 / 0.7) !important;
}

.tw-outline-red-200\/75 {
  outline-color: rgb(254 202 202 / 0.75) !important;
}

.tw-outline-red-200\/80 {
  outline-color: rgb(254 202 202 / 0.8) !important;
}

.tw-outline-red-200\/85 {
  outline-color: rgb(254 202 202 / 0.85) !important;
}

.tw-outline-red-200\/90 {
  outline-color: rgb(254 202 202 / 0.9) !important;
}

.tw-outline-red-200\/95 {
  outline-color: rgb(254 202 202 / 0.95) !important;
}

.tw-outline-red-300 {
  outline-color: #fca5a5 !important;
}

.tw-outline-red-300\/0 {
  outline-color: rgb(252 165 165 / 0) !important;
}

.tw-outline-red-300\/10 {
  outline-color: rgb(252 165 165 / 0.1) !important;
}

.tw-outline-red-300\/100 {
  outline-color: rgb(252 165 165 / 1) !important;
}

.tw-outline-red-300\/15 {
  outline-color: rgb(252 165 165 / 0.15) !important;
}

.tw-outline-red-300\/20 {
  outline-color: rgb(252 165 165 / 0.2) !important;
}

.tw-outline-red-300\/25 {
  outline-color: rgb(252 165 165 / 0.25) !important;
}

.tw-outline-red-300\/30 {
  outline-color: rgb(252 165 165 / 0.3) !important;
}

.tw-outline-red-300\/35 {
  outline-color: rgb(252 165 165 / 0.35) !important;
}

.tw-outline-red-300\/40 {
  outline-color: rgb(252 165 165 / 0.4) !important;
}

.tw-outline-red-300\/45 {
  outline-color: rgb(252 165 165 / 0.45) !important;
}

.tw-outline-red-300\/5 {
  outline-color: rgb(252 165 165 / 0.05) !important;
}

.tw-outline-red-300\/50 {
  outline-color: rgb(252 165 165 / 0.5) !important;
}

.tw-outline-red-300\/55 {
  outline-color: rgb(252 165 165 / 0.55) !important;
}

.tw-outline-red-300\/60 {
  outline-color: rgb(252 165 165 / 0.6) !important;
}

.tw-outline-red-300\/65 {
  outline-color: rgb(252 165 165 / 0.65) !important;
}

.tw-outline-red-300\/70 {
  outline-color: rgb(252 165 165 / 0.7) !important;
}

.tw-outline-red-300\/75 {
  outline-color: rgb(252 165 165 / 0.75) !important;
}

.tw-outline-red-300\/80 {
  outline-color: rgb(252 165 165 / 0.8) !important;
}

.tw-outline-red-300\/85 {
  outline-color: rgb(252 165 165 / 0.85) !important;
}

.tw-outline-red-300\/90 {
  outline-color: rgb(252 165 165 / 0.9) !important;
}

.tw-outline-red-300\/95 {
  outline-color: rgb(252 165 165 / 0.95) !important;
}

.tw-outline-red-400 {
  outline-color: #f87171 !important;
}

.tw-outline-red-400\/0 {
  outline-color: rgb(248 113 113 / 0) !important;
}

.tw-outline-red-400\/10 {
  outline-color: rgb(248 113 113 / 0.1) !important;
}

.tw-outline-red-400\/100 {
  outline-color: rgb(248 113 113 / 1) !important;
}

.tw-outline-red-400\/15 {
  outline-color: rgb(248 113 113 / 0.15) !important;
}

.tw-outline-red-400\/20 {
  outline-color: rgb(248 113 113 / 0.2) !important;
}

.tw-outline-red-400\/25 {
  outline-color: rgb(248 113 113 / 0.25) !important;
}

.tw-outline-red-400\/30 {
  outline-color: rgb(248 113 113 / 0.3) !important;
}

.tw-outline-red-400\/35 {
  outline-color: rgb(248 113 113 / 0.35) !important;
}

.tw-outline-red-400\/40 {
  outline-color: rgb(248 113 113 / 0.4) !important;
}

.tw-outline-red-400\/45 {
  outline-color: rgb(248 113 113 / 0.45) !important;
}

.tw-outline-red-400\/5 {
  outline-color: rgb(248 113 113 / 0.05) !important;
}

.tw-outline-red-400\/50 {
  outline-color: rgb(248 113 113 / 0.5) !important;
}

.tw-outline-red-400\/55 {
  outline-color: rgb(248 113 113 / 0.55) !important;
}

.tw-outline-red-400\/60 {
  outline-color: rgb(248 113 113 / 0.6) !important;
}

.tw-outline-red-400\/65 {
  outline-color: rgb(248 113 113 / 0.65) !important;
}

.tw-outline-red-400\/70 {
  outline-color: rgb(248 113 113 / 0.7) !important;
}

.tw-outline-red-400\/75 {
  outline-color: rgb(248 113 113 / 0.75) !important;
}

.tw-outline-red-400\/80 {
  outline-color: rgb(248 113 113 / 0.8) !important;
}

.tw-outline-red-400\/85 {
  outline-color: rgb(248 113 113 / 0.85) !important;
}

.tw-outline-red-400\/90 {
  outline-color: rgb(248 113 113 / 0.9) !important;
}

.tw-outline-red-400\/95 {
  outline-color: rgb(248 113 113 / 0.95) !important;
}

.tw-outline-red-50 {
  outline-color: #fef2f2 !important;
}

.tw-outline-red-50\/0 {
  outline-color: rgb(254 242 242 / 0) !important;
}

.tw-outline-red-50\/10 {
  outline-color: rgb(254 242 242 / 0.1) !important;
}

.tw-outline-red-50\/100 {
  outline-color: rgb(254 242 242 / 1) !important;
}

.tw-outline-red-50\/15 {
  outline-color: rgb(254 242 242 / 0.15) !important;
}

.tw-outline-red-50\/20 {
  outline-color: rgb(254 242 242 / 0.2) !important;
}

.tw-outline-red-50\/25 {
  outline-color: rgb(254 242 242 / 0.25) !important;
}

.tw-outline-red-50\/30 {
  outline-color: rgb(254 242 242 / 0.3) !important;
}

.tw-outline-red-50\/35 {
  outline-color: rgb(254 242 242 / 0.35) !important;
}

.tw-outline-red-50\/40 {
  outline-color: rgb(254 242 242 / 0.4) !important;
}

.tw-outline-red-50\/45 {
  outline-color: rgb(254 242 242 / 0.45) !important;
}

.tw-outline-red-50\/5 {
  outline-color: rgb(254 242 242 / 0.05) !important;
}

.tw-outline-red-50\/50 {
  outline-color: rgb(254 242 242 / 0.5) !important;
}

.tw-outline-red-50\/55 {
  outline-color: rgb(254 242 242 / 0.55) !important;
}

.tw-outline-red-50\/60 {
  outline-color: rgb(254 242 242 / 0.6) !important;
}

.tw-outline-red-50\/65 {
  outline-color: rgb(254 242 242 / 0.65) !important;
}

.tw-outline-red-50\/70 {
  outline-color: rgb(254 242 242 / 0.7) !important;
}

.tw-outline-red-50\/75 {
  outline-color: rgb(254 242 242 / 0.75) !important;
}

.tw-outline-red-50\/80 {
  outline-color: rgb(254 242 242 / 0.8) !important;
}

.tw-outline-red-50\/85 {
  outline-color: rgb(254 242 242 / 0.85) !important;
}

.tw-outline-red-50\/90 {
  outline-color: rgb(254 242 242 / 0.9) !important;
}

.tw-outline-red-50\/95 {
  outline-color: rgb(254 242 242 / 0.95) !important;
}

.tw-outline-red-500 {
  outline-color: #ef4444 !important;
}

.tw-outline-red-500\/0 {
  outline-color: rgb(239 68 68 / 0) !important;
}

.tw-outline-red-500\/10 {
  outline-color: rgb(239 68 68 / 0.1) !important;
}

.tw-outline-red-500\/100 {
  outline-color: rgb(239 68 68 / 1) !important;
}

.tw-outline-red-500\/15 {
  outline-color: rgb(239 68 68 / 0.15) !important;
}

.tw-outline-red-500\/20 {
  outline-color: rgb(239 68 68 / 0.2) !important;
}

.tw-outline-red-500\/25 {
  outline-color: rgb(239 68 68 / 0.25) !important;
}

.tw-outline-red-500\/30 {
  outline-color: rgb(239 68 68 / 0.3) !important;
}

.tw-outline-red-500\/35 {
  outline-color: rgb(239 68 68 / 0.35) !important;
}

.tw-outline-red-500\/40 {
  outline-color: rgb(239 68 68 / 0.4) !important;
}

.tw-outline-red-500\/45 {
  outline-color: rgb(239 68 68 / 0.45) !important;
}

.tw-outline-red-500\/5 {
  outline-color: rgb(239 68 68 / 0.05) !important;
}

.tw-outline-red-500\/50 {
  outline-color: rgb(239 68 68 / 0.5) !important;
}

.tw-outline-red-500\/55 {
  outline-color: rgb(239 68 68 / 0.55) !important;
}

.tw-outline-red-500\/60 {
  outline-color: rgb(239 68 68 / 0.6) !important;
}

.tw-outline-red-500\/65 {
  outline-color: rgb(239 68 68 / 0.65) !important;
}

.tw-outline-red-500\/70 {
  outline-color: rgb(239 68 68 / 0.7) !important;
}

.tw-outline-red-500\/75 {
  outline-color: rgb(239 68 68 / 0.75) !important;
}

.tw-outline-red-500\/80 {
  outline-color: rgb(239 68 68 / 0.8) !important;
}

.tw-outline-red-500\/85 {
  outline-color: rgb(239 68 68 / 0.85) !important;
}

.tw-outline-red-500\/90 {
  outline-color: rgb(239 68 68 / 0.9) !important;
}

.tw-outline-red-500\/95 {
  outline-color: rgb(239 68 68 / 0.95) !important;
}

.tw-outline-red-600 {
  outline-color: #dc2626 !important;
}

.tw-outline-red-600\/0 {
  outline-color: rgb(220 38 38 / 0) !important;
}

.tw-outline-red-600\/10 {
  outline-color: rgb(220 38 38 / 0.1) !important;
}

.tw-outline-red-600\/100 {
  outline-color: rgb(220 38 38 / 1) !important;
}

.tw-outline-red-600\/15 {
  outline-color: rgb(220 38 38 / 0.15) !important;
}

.tw-outline-red-600\/20 {
  outline-color: rgb(220 38 38 / 0.2) !important;
}

.tw-outline-red-600\/25 {
  outline-color: rgb(220 38 38 / 0.25) !important;
}

.tw-outline-red-600\/30 {
  outline-color: rgb(220 38 38 / 0.3) !important;
}

.tw-outline-red-600\/35 {
  outline-color: rgb(220 38 38 / 0.35) !important;
}

.tw-outline-red-600\/40 {
  outline-color: rgb(220 38 38 / 0.4) !important;
}

.tw-outline-red-600\/45 {
  outline-color: rgb(220 38 38 / 0.45) !important;
}

.tw-outline-red-600\/5 {
  outline-color: rgb(220 38 38 / 0.05) !important;
}

.tw-outline-red-600\/50 {
  outline-color: rgb(220 38 38 / 0.5) !important;
}

.tw-outline-red-600\/55 {
  outline-color: rgb(220 38 38 / 0.55) !important;
}

.tw-outline-red-600\/60 {
  outline-color: rgb(220 38 38 / 0.6) !important;
}

.tw-outline-red-600\/65 {
  outline-color: rgb(220 38 38 / 0.65) !important;
}

.tw-outline-red-600\/70 {
  outline-color: rgb(220 38 38 / 0.7) !important;
}

.tw-outline-red-600\/75 {
  outline-color: rgb(220 38 38 / 0.75) !important;
}

.tw-outline-red-600\/80 {
  outline-color: rgb(220 38 38 / 0.8) !important;
}

.tw-outline-red-600\/85 {
  outline-color: rgb(220 38 38 / 0.85) !important;
}

.tw-outline-red-600\/90 {
  outline-color: rgb(220 38 38 / 0.9) !important;
}

.tw-outline-red-600\/95 {
  outline-color: rgb(220 38 38 / 0.95) !important;
}

.tw-outline-red-700 {
  outline-color: #b91c1c !important;
}

.tw-outline-red-700\/0 {
  outline-color: rgb(185 28 28 / 0) !important;
}

.tw-outline-red-700\/10 {
  outline-color: rgb(185 28 28 / 0.1) !important;
}

.tw-outline-red-700\/100 {
  outline-color: rgb(185 28 28 / 1) !important;
}

.tw-outline-red-700\/15 {
  outline-color: rgb(185 28 28 / 0.15) !important;
}

.tw-outline-red-700\/20 {
  outline-color: rgb(185 28 28 / 0.2) !important;
}

.tw-outline-red-700\/25 {
  outline-color: rgb(185 28 28 / 0.25) !important;
}

.tw-outline-red-700\/30 {
  outline-color: rgb(185 28 28 / 0.3) !important;
}

.tw-outline-red-700\/35 {
  outline-color: rgb(185 28 28 / 0.35) !important;
}

.tw-outline-red-700\/40 {
  outline-color: rgb(185 28 28 / 0.4) !important;
}

.tw-outline-red-700\/45 {
  outline-color: rgb(185 28 28 / 0.45) !important;
}

.tw-outline-red-700\/5 {
  outline-color: rgb(185 28 28 / 0.05) !important;
}

.tw-outline-red-700\/50 {
  outline-color: rgb(185 28 28 / 0.5) !important;
}

.tw-outline-red-700\/55 {
  outline-color: rgb(185 28 28 / 0.55) !important;
}

.tw-outline-red-700\/60 {
  outline-color: rgb(185 28 28 / 0.6) !important;
}

.tw-outline-red-700\/65 {
  outline-color: rgb(185 28 28 / 0.65) !important;
}

.tw-outline-red-700\/70 {
  outline-color: rgb(185 28 28 / 0.7) !important;
}

.tw-outline-red-700\/75 {
  outline-color: rgb(185 28 28 / 0.75) !important;
}

.tw-outline-red-700\/80 {
  outline-color: rgb(185 28 28 / 0.8) !important;
}

.tw-outline-red-700\/85 {
  outline-color: rgb(185 28 28 / 0.85) !important;
}

.tw-outline-red-700\/90 {
  outline-color: rgb(185 28 28 / 0.9) !important;
}

.tw-outline-red-700\/95 {
  outline-color: rgb(185 28 28 / 0.95) !important;
}

.tw-outline-red-800 {
  outline-color: #991b1b !important;
}

.tw-outline-red-800\/0 {
  outline-color: rgb(153 27 27 / 0) !important;
}

.tw-outline-red-800\/10 {
  outline-color: rgb(153 27 27 / 0.1) !important;
}

.tw-outline-red-800\/100 {
  outline-color: rgb(153 27 27 / 1) !important;
}

.tw-outline-red-800\/15 {
  outline-color: rgb(153 27 27 / 0.15) !important;
}

.tw-outline-red-800\/20 {
  outline-color: rgb(153 27 27 / 0.2) !important;
}

.tw-outline-red-800\/25 {
  outline-color: rgb(153 27 27 / 0.25) !important;
}

.tw-outline-red-800\/30 {
  outline-color: rgb(153 27 27 / 0.3) !important;
}

.tw-outline-red-800\/35 {
  outline-color: rgb(153 27 27 / 0.35) !important;
}

.tw-outline-red-800\/40 {
  outline-color: rgb(153 27 27 / 0.4) !important;
}

.tw-outline-red-800\/45 {
  outline-color: rgb(153 27 27 / 0.45) !important;
}

.tw-outline-red-800\/5 {
  outline-color: rgb(153 27 27 / 0.05) !important;
}

.tw-outline-red-800\/50 {
  outline-color: rgb(153 27 27 / 0.5) !important;
}

.tw-outline-red-800\/55 {
  outline-color: rgb(153 27 27 / 0.55) !important;
}

.tw-outline-red-800\/60 {
  outline-color: rgb(153 27 27 / 0.6) !important;
}

.tw-outline-red-800\/65 {
  outline-color: rgb(153 27 27 / 0.65) !important;
}

.tw-outline-red-800\/70 {
  outline-color: rgb(153 27 27 / 0.7) !important;
}

.tw-outline-red-800\/75 {
  outline-color: rgb(153 27 27 / 0.75) !important;
}

.tw-outline-red-800\/80 {
  outline-color: rgb(153 27 27 / 0.8) !important;
}

.tw-outline-red-800\/85 {
  outline-color: rgb(153 27 27 / 0.85) !important;
}

.tw-outline-red-800\/90 {
  outline-color: rgb(153 27 27 / 0.9) !important;
}

.tw-outline-red-800\/95 {
  outline-color: rgb(153 27 27 / 0.95) !important;
}

.tw-outline-red-900 {
  outline-color: #7f1d1d !important;
}

.tw-outline-red-900\/0 {
  outline-color: rgb(127 29 29 / 0) !important;
}

.tw-outline-red-900\/10 {
  outline-color: rgb(127 29 29 / 0.1) !important;
}

.tw-outline-red-900\/100 {
  outline-color: rgb(127 29 29 / 1) !important;
}

.tw-outline-red-900\/15 {
  outline-color: rgb(127 29 29 / 0.15) !important;
}

.tw-outline-red-900\/20 {
  outline-color: rgb(127 29 29 / 0.2) !important;
}

.tw-outline-red-900\/25 {
  outline-color: rgb(127 29 29 / 0.25) !important;
}

.tw-outline-red-900\/30 {
  outline-color: rgb(127 29 29 / 0.3) !important;
}

.tw-outline-red-900\/35 {
  outline-color: rgb(127 29 29 / 0.35) !important;
}

.tw-outline-red-900\/40 {
  outline-color: rgb(127 29 29 / 0.4) !important;
}

.tw-outline-red-900\/45 {
  outline-color: rgb(127 29 29 / 0.45) !important;
}

.tw-outline-red-900\/5 {
  outline-color: rgb(127 29 29 / 0.05) !important;
}

.tw-outline-red-900\/50 {
  outline-color: rgb(127 29 29 / 0.5) !important;
}

.tw-outline-red-900\/55 {
  outline-color: rgb(127 29 29 / 0.55) !important;
}

.tw-outline-red-900\/60 {
  outline-color: rgb(127 29 29 / 0.6) !important;
}

.tw-outline-red-900\/65 {
  outline-color: rgb(127 29 29 / 0.65) !important;
}

.tw-outline-red-900\/70 {
  outline-color: rgb(127 29 29 / 0.7) !important;
}

.tw-outline-red-900\/75 {
  outline-color: rgb(127 29 29 / 0.75) !important;
}

.tw-outline-red-900\/80 {
  outline-color: rgb(127 29 29 / 0.8) !important;
}

.tw-outline-red-900\/85 {
  outline-color: rgb(127 29 29 / 0.85) !important;
}

.tw-outline-red-900\/90 {
  outline-color: rgb(127 29 29 / 0.9) !important;
}

.tw-outline-red-900\/95 {
  outline-color: rgb(127 29 29 / 0.95) !important;
}

.tw-outline-red-950 {
  outline-color: #450a0a !important;
}

.tw-outline-red-950\/0 {
  outline-color: rgb(69 10 10 / 0) !important;
}

.tw-outline-red-950\/10 {
  outline-color: rgb(69 10 10 / 0.1) !important;
}

.tw-outline-red-950\/100 {
  outline-color: rgb(69 10 10 / 1) !important;
}

.tw-outline-red-950\/15 {
  outline-color: rgb(69 10 10 / 0.15) !important;
}

.tw-outline-red-950\/20 {
  outline-color: rgb(69 10 10 / 0.2) !important;
}

.tw-outline-red-950\/25 {
  outline-color: rgb(69 10 10 / 0.25) !important;
}

.tw-outline-red-950\/30 {
  outline-color: rgb(69 10 10 / 0.3) !important;
}

.tw-outline-red-950\/35 {
  outline-color: rgb(69 10 10 / 0.35) !important;
}

.tw-outline-red-950\/40 {
  outline-color: rgb(69 10 10 / 0.4) !important;
}

.tw-outline-red-950\/45 {
  outline-color: rgb(69 10 10 / 0.45) !important;
}

.tw-outline-red-950\/5 {
  outline-color: rgb(69 10 10 / 0.05) !important;
}

.tw-outline-red-950\/50 {
  outline-color: rgb(69 10 10 / 0.5) !important;
}

.tw-outline-red-950\/55 {
  outline-color: rgb(69 10 10 / 0.55) !important;
}

.tw-outline-red-950\/60 {
  outline-color: rgb(69 10 10 / 0.6) !important;
}

.tw-outline-red-950\/65 {
  outline-color: rgb(69 10 10 / 0.65) !important;
}

.tw-outline-red-950\/70 {
  outline-color: rgb(69 10 10 / 0.7) !important;
}

.tw-outline-red-950\/75 {
  outline-color: rgb(69 10 10 / 0.75) !important;
}

.tw-outline-red-950\/80 {
  outline-color: rgb(69 10 10 / 0.8) !important;
}

.tw-outline-red-950\/85 {
  outline-color: rgb(69 10 10 / 0.85) !important;
}

.tw-outline-red-950\/90 {
  outline-color: rgb(69 10 10 / 0.9) !important;
}

.tw-outline-red-950\/95 {
  outline-color: rgb(69 10 10 / 0.95) !important;
}

.tw-outline-rose-100 {
  outline-color: #ffe4e6 !important;
}

.tw-outline-rose-100\/0 {
  outline-color: rgb(255 228 230 / 0) !important;
}

.tw-outline-rose-100\/10 {
  outline-color: rgb(255 228 230 / 0.1) !important;
}

.tw-outline-rose-100\/100 {
  outline-color: rgb(255 228 230 / 1) !important;
}

.tw-outline-rose-100\/15 {
  outline-color: rgb(255 228 230 / 0.15) !important;
}

.tw-outline-rose-100\/20 {
  outline-color: rgb(255 228 230 / 0.2) !important;
}

.tw-outline-rose-100\/25 {
  outline-color: rgb(255 228 230 / 0.25) !important;
}

.tw-outline-rose-100\/30 {
  outline-color: rgb(255 228 230 / 0.3) !important;
}

.tw-outline-rose-100\/35 {
  outline-color: rgb(255 228 230 / 0.35) !important;
}

.tw-outline-rose-100\/40 {
  outline-color: rgb(255 228 230 / 0.4) !important;
}

.tw-outline-rose-100\/45 {
  outline-color: rgb(255 228 230 / 0.45) !important;
}

.tw-outline-rose-100\/5 {
  outline-color: rgb(255 228 230 / 0.05) !important;
}

.tw-outline-rose-100\/50 {
  outline-color: rgb(255 228 230 / 0.5) !important;
}

.tw-outline-rose-100\/55 {
  outline-color: rgb(255 228 230 / 0.55) !important;
}

.tw-outline-rose-100\/60 {
  outline-color: rgb(255 228 230 / 0.6) !important;
}

.tw-outline-rose-100\/65 {
  outline-color: rgb(255 228 230 / 0.65) !important;
}

.tw-outline-rose-100\/70 {
  outline-color: rgb(255 228 230 / 0.7) !important;
}

.tw-outline-rose-100\/75 {
  outline-color: rgb(255 228 230 / 0.75) !important;
}

.tw-outline-rose-100\/80 {
  outline-color: rgb(255 228 230 / 0.8) !important;
}

.tw-outline-rose-100\/85 {
  outline-color: rgb(255 228 230 / 0.85) !important;
}

.tw-outline-rose-100\/90 {
  outline-color: rgb(255 228 230 / 0.9) !important;
}

.tw-outline-rose-100\/95 {
  outline-color: rgb(255 228 230 / 0.95) !important;
}

.tw-outline-rose-200 {
  outline-color: #fecdd3 !important;
}

.tw-outline-rose-200\/0 {
  outline-color: rgb(254 205 211 / 0) !important;
}

.tw-outline-rose-200\/10 {
  outline-color: rgb(254 205 211 / 0.1) !important;
}

.tw-outline-rose-200\/100 {
  outline-color: rgb(254 205 211 / 1) !important;
}

.tw-outline-rose-200\/15 {
  outline-color: rgb(254 205 211 / 0.15) !important;
}

.tw-outline-rose-200\/20 {
  outline-color: rgb(254 205 211 / 0.2) !important;
}

.tw-outline-rose-200\/25 {
  outline-color: rgb(254 205 211 / 0.25) !important;
}

.tw-outline-rose-200\/30 {
  outline-color: rgb(254 205 211 / 0.3) !important;
}

.tw-outline-rose-200\/35 {
  outline-color: rgb(254 205 211 / 0.35) !important;
}

.tw-outline-rose-200\/40 {
  outline-color: rgb(254 205 211 / 0.4) !important;
}

.tw-outline-rose-200\/45 {
  outline-color: rgb(254 205 211 / 0.45) !important;
}

.tw-outline-rose-200\/5 {
  outline-color: rgb(254 205 211 / 0.05) !important;
}

.tw-outline-rose-200\/50 {
  outline-color: rgb(254 205 211 / 0.5) !important;
}

.tw-outline-rose-200\/55 {
  outline-color: rgb(254 205 211 / 0.55) !important;
}

.tw-outline-rose-200\/60 {
  outline-color: rgb(254 205 211 / 0.6) !important;
}

.tw-outline-rose-200\/65 {
  outline-color: rgb(254 205 211 / 0.65) !important;
}

.tw-outline-rose-200\/70 {
  outline-color: rgb(254 205 211 / 0.7) !important;
}

.tw-outline-rose-200\/75 {
  outline-color: rgb(254 205 211 / 0.75) !important;
}

.tw-outline-rose-200\/80 {
  outline-color: rgb(254 205 211 / 0.8) !important;
}

.tw-outline-rose-200\/85 {
  outline-color: rgb(254 205 211 / 0.85) !important;
}

.tw-outline-rose-200\/90 {
  outline-color: rgb(254 205 211 / 0.9) !important;
}

.tw-outline-rose-200\/95 {
  outline-color: rgb(254 205 211 / 0.95) !important;
}

.tw-outline-rose-300 {
  outline-color: #fda4af !important;
}

.tw-outline-rose-300\/0 {
  outline-color: rgb(253 164 175 / 0) !important;
}

.tw-outline-rose-300\/10 {
  outline-color: rgb(253 164 175 / 0.1) !important;
}

.tw-outline-rose-300\/100 {
  outline-color: rgb(253 164 175 / 1) !important;
}

.tw-outline-rose-300\/15 {
  outline-color: rgb(253 164 175 / 0.15) !important;
}

.tw-outline-rose-300\/20 {
  outline-color: rgb(253 164 175 / 0.2) !important;
}

.tw-outline-rose-300\/25 {
  outline-color: rgb(253 164 175 / 0.25) !important;
}

.tw-outline-rose-300\/30 {
  outline-color: rgb(253 164 175 / 0.3) !important;
}

.tw-outline-rose-300\/35 {
  outline-color: rgb(253 164 175 / 0.35) !important;
}

.tw-outline-rose-300\/40 {
  outline-color: rgb(253 164 175 / 0.4) !important;
}

.tw-outline-rose-300\/45 {
  outline-color: rgb(253 164 175 / 0.45) !important;
}

.tw-outline-rose-300\/5 {
  outline-color: rgb(253 164 175 / 0.05) !important;
}

.tw-outline-rose-300\/50 {
  outline-color: rgb(253 164 175 / 0.5) !important;
}

.tw-outline-rose-300\/55 {
  outline-color: rgb(253 164 175 / 0.55) !important;
}

.tw-outline-rose-300\/60 {
  outline-color: rgb(253 164 175 / 0.6) !important;
}

.tw-outline-rose-300\/65 {
  outline-color: rgb(253 164 175 / 0.65) !important;
}

.tw-outline-rose-300\/70 {
  outline-color: rgb(253 164 175 / 0.7) !important;
}

.tw-outline-rose-300\/75 {
  outline-color: rgb(253 164 175 / 0.75) !important;
}

.tw-outline-rose-300\/80 {
  outline-color: rgb(253 164 175 / 0.8) !important;
}

.tw-outline-rose-300\/85 {
  outline-color: rgb(253 164 175 / 0.85) !important;
}

.tw-outline-rose-300\/90 {
  outline-color: rgb(253 164 175 / 0.9) !important;
}

.tw-outline-rose-300\/95 {
  outline-color: rgb(253 164 175 / 0.95) !important;
}

.tw-outline-rose-400 {
  outline-color: #fb7185 !important;
}

.tw-outline-rose-400\/0 {
  outline-color: rgb(251 113 133 / 0) !important;
}

.tw-outline-rose-400\/10 {
  outline-color: rgb(251 113 133 / 0.1) !important;
}

.tw-outline-rose-400\/100 {
  outline-color: rgb(251 113 133 / 1) !important;
}

.tw-outline-rose-400\/15 {
  outline-color: rgb(251 113 133 / 0.15) !important;
}

.tw-outline-rose-400\/20 {
  outline-color: rgb(251 113 133 / 0.2) !important;
}

.tw-outline-rose-400\/25 {
  outline-color: rgb(251 113 133 / 0.25) !important;
}

.tw-outline-rose-400\/30 {
  outline-color: rgb(251 113 133 / 0.3) !important;
}

.tw-outline-rose-400\/35 {
  outline-color: rgb(251 113 133 / 0.35) !important;
}

.tw-outline-rose-400\/40 {
  outline-color: rgb(251 113 133 / 0.4) !important;
}

.tw-outline-rose-400\/45 {
  outline-color: rgb(251 113 133 / 0.45) !important;
}

.tw-outline-rose-400\/5 {
  outline-color: rgb(251 113 133 / 0.05) !important;
}

.tw-outline-rose-400\/50 {
  outline-color: rgb(251 113 133 / 0.5) !important;
}

.tw-outline-rose-400\/55 {
  outline-color: rgb(251 113 133 / 0.55) !important;
}

.tw-outline-rose-400\/60 {
  outline-color: rgb(251 113 133 / 0.6) !important;
}

.tw-outline-rose-400\/65 {
  outline-color: rgb(251 113 133 / 0.65) !important;
}

.tw-outline-rose-400\/70 {
  outline-color: rgb(251 113 133 / 0.7) !important;
}

.tw-outline-rose-400\/75 {
  outline-color: rgb(251 113 133 / 0.75) !important;
}

.tw-outline-rose-400\/80 {
  outline-color: rgb(251 113 133 / 0.8) !important;
}

.tw-outline-rose-400\/85 {
  outline-color: rgb(251 113 133 / 0.85) !important;
}

.tw-outline-rose-400\/90 {
  outline-color: rgb(251 113 133 / 0.9) !important;
}

.tw-outline-rose-400\/95 {
  outline-color: rgb(251 113 133 / 0.95) !important;
}

.tw-outline-rose-50 {
  outline-color: #fff1f2 !important;
}

.tw-outline-rose-50\/0 {
  outline-color: rgb(255 241 242 / 0) !important;
}

.tw-outline-rose-50\/10 {
  outline-color: rgb(255 241 242 / 0.1) !important;
}

.tw-outline-rose-50\/100 {
  outline-color: rgb(255 241 242 / 1) !important;
}

.tw-outline-rose-50\/15 {
  outline-color: rgb(255 241 242 / 0.15) !important;
}

.tw-outline-rose-50\/20 {
  outline-color: rgb(255 241 242 / 0.2) !important;
}

.tw-outline-rose-50\/25 {
  outline-color: rgb(255 241 242 / 0.25) !important;
}

.tw-outline-rose-50\/30 {
  outline-color: rgb(255 241 242 / 0.3) !important;
}

.tw-outline-rose-50\/35 {
  outline-color: rgb(255 241 242 / 0.35) !important;
}

.tw-outline-rose-50\/40 {
  outline-color: rgb(255 241 242 / 0.4) !important;
}

.tw-outline-rose-50\/45 {
  outline-color: rgb(255 241 242 / 0.45) !important;
}

.tw-outline-rose-50\/5 {
  outline-color: rgb(255 241 242 / 0.05) !important;
}

.tw-outline-rose-50\/50 {
  outline-color: rgb(255 241 242 / 0.5) !important;
}

.tw-outline-rose-50\/55 {
  outline-color: rgb(255 241 242 / 0.55) !important;
}

.tw-outline-rose-50\/60 {
  outline-color: rgb(255 241 242 / 0.6) !important;
}

.tw-outline-rose-50\/65 {
  outline-color: rgb(255 241 242 / 0.65) !important;
}

.tw-outline-rose-50\/70 {
  outline-color: rgb(255 241 242 / 0.7) !important;
}

.tw-outline-rose-50\/75 {
  outline-color: rgb(255 241 242 / 0.75) !important;
}

.tw-outline-rose-50\/80 {
  outline-color: rgb(255 241 242 / 0.8) !important;
}

.tw-outline-rose-50\/85 {
  outline-color: rgb(255 241 242 / 0.85) !important;
}

.tw-outline-rose-50\/90 {
  outline-color: rgb(255 241 242 / 0.9) !important;
}

.tw-outline-rose-50\/95 {
  outline-color: rgb(255 241 242 / 0.95) !important;
}

.tw-outline-rose-500 {
  outline-color: #f43f5e !important;
}

.tw-outline-rose-500\/0 {
  outline-color: rgb(244 63 94 / 0) !important;
}

.tw-outline-rose-500\/10 {
  outline-color: rgb(244 63 94 / 0.1) !important;
}

.tw-outline-rose-500\/100 {
  outline-color: rgb(244 63 94 / 1) !important;
}

.tw-outline-rose-500\/15 {
  outline-color: rgb(244 63 94 / 0.15) !important;
}

.tw-outline-rose-500\/20 {
  outline-color: rgb(244 63 94 / 0.2) !important;
}

.tw-outline-rose-500\/25 {
  outline-color: rgb(244 63 94 / 0.25) !important;
}

.tw-outline-rose-500\/30 {
  outline-color: rgb(244 63 94 / 0.3) !important;
}

.tw-outline-rose-500\/35 {
  outline-color: rgb(244 63 94 / 0.35) !important;
}

.tw-outline-rose-500\/40 {
  outline-color: rgb(244 63 94 / 0.4) !important;
}

.tw-outline-rose-500\/45 {
  outline-color: rgb(244 63 94 / 0.45) !important;
}

.tw-outline-rose-500\/5 {
  outline-color: rgb(244 63 94 / 0.05) !important;
}

.tw-outline-rose-500\/50 {
  outline-color: rgb(244 63 94 / 0.5) !important;
}

.tw-outline-rose-500\/55 {
  outline-color: rgb(244 63 94 / 0.55) !important;
}

.tw-outline-rose-500\/60 {
  outline-color: rgb(244 63 94 / 0.6) !important;
}

.tw-outline-rose-500\/65 {
  outline-color: rgb(244 63 94 / 0.65) !important;
}

.tw-outline-rose-500\/70 {
  outline-color: rgb(244 63 94 / 0.7) !important;
}

.tw-outline-rose-500\/75 {
  outline-color: rgb(244 63 94 / 0.75) !important;
}

.tw-outline-rose-500\/80 {
  outline-color: rgb(244 63 94 / 0.8) !important;
}

.tw-outline-rose-500\/85 {
  outline-color: rgb(244 63 94 / 0.85) !important;
}

.tw-outline-rose-500\/90 {
  outline-color: rgb(244 63 94 / 0.9) !important;
}

.tw-outline-rose-500\/95 {
  outline-color: rgb(244 63 94 / 0.95) !important;
}

.tw-outline-rose-600 {
  outline-color: #e11d48 !important;
}

.tw-outline-rose-600\/0 {
  outline-color: rgb(225 29 72 / 0) !important;
}

.tw-outline-rose-600\/10 {
  outline-color: rgb(225 29 72 / 0.1) !important;
}

.tw-outline-rose-600\/100 {
  outline-color: rgb(225 29 72 / 1) !important;
}

.tw-outline-rose-600\/15 {
  outline-color: rgb(225 29 72 / 0.15) !important;
}

.tw-outline-rose-600\/20 {
  outline-color: rgb(225 29 72 / 0.2) !important;
}

.tw-outline-rose-600\/25 {
  outline-color: rgb(225 29 72 / 0.25) !important;
}

.tw-outline-rose-600\/30 {
  outline-color: rgb(225 29 72 / 0.3) !important;
}

.tw-outline-rose-600\/35 {
  outline-color: rgb(225 29 72 / 0.35) !important;
}

.tw-outline-rose-600\/40 {
  outline-color: rgb(225 29 72 / 0.4) !important;
}

.tw-outline-rose-600\/45 {
  outline-color: rgb(225 29 72 / 0.45) !important;
}

.tw-outline-rose-600\/5 {
  outline-color: rgb(225 29 72 / 0.05) !important;
}

.tw-outline-rose-600\/50 {
  outline-color: rgb(225 29 72 / 0.5) !important;
}

.tw-outline-rose-600\/55 {
  outline-color: rgb(225 29 72 / 0.55) !important;
}

.tw-outline-rose-600\/60 {
  outline-color: rgb(225 29 72 / 0.6) !important;
}

.tw-outline-rose-600\/65 {
  outline-color: rgb(225 29 72 / 0.65) !important;
}

.tw-outline-rose-600\/70 {
  outline-color: rgb(225 29 72 / 0.7) !important;
}

.tw-outline-rose-600\/75 {
  outline-color: rgb(225 29 72 / 0.75) !important;
}

.tw-outline-rose-600\/80 {
  outline-color: rgb(225 29 72 / 0.8) !important;
}

.tw-outline-rose-600\/85 {
  outline-color: rgb(225 29 72 / 0.85) !important;
}

.tw-outline-rose-600\/90 {
  outline-color: rgb(225 29 72 / 0.9) !important;
}

.tw-outline-rose-600\/95 {
  outline-color: rgb(225 29 72 / 0.95) !important;
}

.tw-outline-rose-700 {
  outline-color: #be123c !important;
}

.tw-outline-rose-700\/0 {
  outline-color: rgb(190 18 60 / 0) !important;
}

.tw-outline-rose-700\/10 {
  outline-color: rgb(190 18 60 / 0.1) !important;
}

.tw-outline-rose-700\/100 {
  outline-color: rgb(190 18 60 / 1) !important;
}

.tw-outline-rose-700\/15 {
  outline-color: rgb(190 18 60 / 0.15) !important;
}

.tw-outline-rose-700\/20 {
  outline-color: rgb(190 18 60 / 0.2) !important;
}

.tw-outline-rose-700\/25 {
  outline-color: rgb(190 18 60 / 0.25) !important;
}

.tw-outline-rose-700\/30 {
  outline-color: rgb(190 18 60 / 0.3) !important;
}

.tw-outline-rose-700\/35 {
  outline-color: rgb(190 18 60 / 0.35) !important;
}

.tw-outline-rose-700\/40 {
  outline-color: rgb(190 18 60 / 0.4) !important;
}

.tw-outline-rose-700\/45 {
  outline-color: rgb(190 18 60 / 0.45) !important;
}

.tw-outline-rose-700\/5 {
  outline-color: rgb(190 18 60 / 0.05) !important;
}

.tw-outline-rose-700\/50 {
  outline-color: rgb(190 18 60 / 0.5) !important;
}

.tw-outline-rose-700\/55 {
  outline-color: rgb(190 18 60 / 0.55) !important;
}

.tw-outline-rose-700\/60 {
  outline-color: rgb(190 18 60 / 0.6) !important;
}

.tw-outline-rose-700\/65 {
  outline-color: rgb(190 18 60 / 0.65) !important;
}

.tw-outline-rose-700\/70 {
  outline-color: rgb(190 18 60 / 0.7) !important;
}

.tw-outline-rose-700\/75 {
  outline-color: rgb(190 18 60 / 0.75) !important;
}

.tw-outline-rose-700\/80 {
  outline-color: rgb(190 18 60 / 0.8) !important;
}

.tw-outline-rose-700\/85 {
  outline-color: rgb(190 18 60 / 0.85) !important;
}

.tw-outline-rose-700\/90 {
  outline-color: rgb(190 18 60 / 0.9) !important;
}

.tw-outline-rose-700\/95 {
  outline-color: rgb(190 18 60 / 0.95) !important;
}

.tw-outline-rose-800 {
  outline-color: #9f1239 !important;
}

.tw-outline-rose-800\/0 {
  outline-color: rgb(159 18 57 / 0) !important;
}

.tw-outline-rose-800\/10 {
  outline-color: rgb(159 18 57 / 0.1) !important;
}

.tw-outline-rose-800\/100 {
  outline-color: rgb(159 18 57 / 1) !important;
}

.tw-outline-rose-800\/15 {
  outline-color: rgb(159 18 57 / 0.15) !important;
}

.tw-outline-rose-800\/20 {
  outline-color: rgb(159 18 57 / 0.2) !important;
}

.tw-outline-rose-800\/25 {
  outline-color: rgb(159 18 57 / 0.25) !important;
}

.tw-outline-rose-800\/30 {
  outline-color: rgb(159 18 57 / 0.3) !important;
}

.tw-outline-rose-800\/35 {
  outline-color: rgb(159 18 57 / 0.35) !important;
}

.tw-outline-rose-800\/40 {
  outline-color: rgb(159 18 57 / 0.4) !important;
}

.tw-outline-rose-800\/45 {
  outline-color: rgb(159 18 57 / 0.45) !important;
}

.tw-outline-rose-800\/5 {
  outline-color: rgb(159 18 57 / 0.05) !important;
}

.tw-outline-rose-800\/50 {
  outline-color: rgb(159 18 57 / 0.5) !important;
}

.tw-outline-rose-800\/55 {
  outline-color: rgb(159 18 57 / 0.55) !important;
}

.tw-outline-rose-800\/60 {
  outline-color: rgb(159 18 57 / 0.6) !important;
}

.tw-outline-rose-800\/65 {
  outline-color: rgb(159 18 57 / 0.65) !important;
}

.tw-outline-rose-800\/70 {
  outline-color: rgb(159 18 57 / 0.7) !important;
}

.tw-outline-rose-800\/75 {
  outline-color: rgb(159 18 57 / 0.75) !important;
}

.tw-outline-rose-800\/80 {
  outline-color: rgb(159 18 57 / 0.8) !important;
}

.tw-outline-rose-800\/85 {
  outline-color: rgb(159 18 57 / 0.85) !important;
}

.tw-outline-rose-800\/90 {
  outline-color: rgb(159 18 57 / 0.9) !important;
}

.tw-outline-rose-800\/95 {
  outline-color: rgb(159 18 57 / 0.95) !important;
}

.tw-outline-rose-900 {
  outline-color: #881337 !important;
}

.tw-outline-rose-900\/0 {
  outline-color: rgb(136 19 55 / 0) !important;
}

.tw-outline-rose-900\/10 {
  outline-color: rgb(136 19 55 / 0.1) !important;
}

.tw-outline-rose-900\/100 {
  outline-color: rgb(136 19 55 / 1) !important;
}

.tw-outline-rose-900\/15 {
  outline-color: rgb(136 19 55 / 0.15) !important;
}

.tw-outline-rose-900\/20 {
  outline-color: rgb(136 19 55 / 0.2) !important;
}

.tw-outline-rose-900\/25 {
  outline-color: rgb(136 19 55 / 0.25) !important;
}

.tw-outline-rose-900\/30 {
  outline-color: rgb(136 19 55 / 0.3) !important;
}

.tw-outline-rose-900\/35 {
  outline-color: rgb(136 19 55 / 0.35) !important;
}

.tw-outline-rose-900\/40 {
  outline-color: rgb(136 19 55 / 0.4) !important;
}

.tw-outline-rose-900\/45 {
  outline-color: rgb(136 19 55 / 0.45) !important;
}

.tw-outline-rose-900\/5 {
  outline-color: rgb(136 19 55 / 0.05) !important;
}

.tw-outline-rose-900\/50 {
  outline-color: rgb(136 19 55 / 0.5) !important;
}

.tw-outline-rose-900\/55 {
  outline-color: rgb(136 19 55 / 0.55) !important;
}

.tw-outline-rose-900\/60 {
  outline-color: rgb(136 19 55 / 0.6) !important;
}

.tw-outline-rose-900\/65 {
  outline-color: rgb(136 19 55 / 0.65) !important;
}

.tw-outline-rose-900\/70 {
  outline-color: rgb(136 19 55 / 0.7) !important;
}

.tw-outline-rose-900\/75 {
  outline-color: rgb(136 19 55 / 0.75) !important;
}

.tw-outline-rose-900\/80 {
  outline-color: rgb(136 19 55 / 0.8) !important;
}

.tw-outline-rose-900\/85 {
  outline-color: rgb(136 19 55 / 0.85) !important;
}

.tw-outline-rose-900\/90 {
  outline-color: rgb(136 19 55 / 0.9) !important;
}

.tw-outline-rose-900\/95 {
  outline-color: rgb(136 19 55 / 0.95) !important;
}

.tw-outline-rose-950 {
  outline-color: #4c0519 !important;
}

.tw-outline-rose-950\/0 {
  outline-color: rgb(76 5 25 / 0) !important;
}

.tw-outline-rose-950\/10 {
  outline-color: rgb(76 5 25 / 0.1) !important;
}

.tw-outline-rose-950\/100 {
  outline-color: rgb(76 5 25 / 1) !important;
}

.tw-outline-rose-950\/15 {
  outline-color: rgb(76 5 25 / 0.15) !important;
}

.tw-outline-rose-950\/20 {
  outline-color: rgb(76 5 25 / 0.2) !important;
}

.tw-outline-rose-950\/25 {
  outline-color: rgb(76 5 25 / 0.25) !important;
}

.tw-outline-rose-950\/30 {
  outline-color: rgb(76 5 25 / 0.3) !important;
}

.tw-outline-rose-950\/35 {
  outline-color: rgb(76 5 25 / 0.35) !important;
}

.tw-outline-rose-950\/40 {
  outline-color: rgb(76 5 25 / 0.4) !important;
}

.tw-outline-rose-950\/45 {
  outline-color: rgb(76 5 25 / 0.45) !important;
}

.tw-outline-rose-950\/5 {
  outline-color: rgb(76 5 25 / 0.05) !important;
}

.tw-outline-rose-950\/50 {
  outline-color: rgb(76 5 25 / 0.5) !important;
}

.tw-outline-rose-950\/55 {
  outline-color: rgb(76 5 25 / 0.55) !important;
}

.tw-outline-rose-950\/60 {
  outline-color: rgb(76 5 25 / 0.6) !important;
}

.tw-outline-rose-950\/65 {
  outline-color: rgb(76 5 25 / 0.65) !important;
}

.tw-outline-rose-950\/70 {
  outline-color: rgb(76 5 25 / 0.7) !important;
}

.tw-outline-rose-950\/75 {
  outline-color: rgb(76 5 25 / 0.75) !important;
}

.tw-outline-rose-950\/80 {
  outline-color: rgb(76 5 25 / 0.8) !important;
}

.tw-outline-rose-950\/85 {
  outline-color: rgb(76 5 25 / 0.85) !important;
}

.tw-outline-rose-950\/90 {
  outline-color: rgb(76 5 25 / 0.9) !important;
}

.tw-outline-rose-950\/95 {
  outline-color: rgb(76 5 25 / 0.95) !important;
}

.tw-outline-sky-100 {
  outline-color: #e0f2fe !important;
}

.tw-outline-sky-100\/0 {
  outline-color: rgb(224 242 254 / 0) !important;
}

.tw-outline-sky-100\/10 {
  outline-color: rgb(224 242 254 / 0.1) !important;
}

.tw-outline-sky-100\/100 {
  outline-color: rgb(224 242 254 / 1) !important;
}

.tw-outline-sky-100\/15 {
  outline-color: rgb(224 242 254 / 0.15) !important;
}

.tw-outline-sky-100\/20 {
  outline-color: rgb(224 242 254 / 0.2) !important;
}

.tw-outline-sky-100\/25 {
  outline-color: rgb(224 242 254 / 0.25) !important;
}

.tw-outline-sky-100\/30 {
  outline-color: rgb(224 242 254 / 0.3) !important;
}

.tw-outline-sky-100\/35 {
  outline-color: rgb(224 242 254 / 0.35) !important;
}

.tw-outline-sky-100\/40 {
  outline-color: rgb(224 242 254 / 0.4) !important;
}

.tw-outline-sky-100\/45 {
  outline-color: rgb(224 242 254 / 0.45) !important;
}

.tw-outline-sky-100\/5 {
  outline-color: rgb(224 242 254 / 0.05) !important;
}

.tw-outline-sky-100\/50 {
  outline-color: rgb(224 242 254 / 0.5) !important;
}

.tw-outline-sky-100\/55 {
  outline-color: rgb(224 242 254 / 0.55) !important;
}

.tw-outline-sky-100\/60 {
  outline-color: rgb(224 242 254 / 0.6) !important;
}

.tw-outline-sky-100\/65 {
  outline-color: rgb(224 242 254 / 0.65) !important;
}

.tw-outline-sky-100\/70 {
  outline-color: rgb(224 242 254 / 0.7) !important;
}

.tw-outline-sky-100\/75 {
  outline-color: rgb(224 242 254 / 0.75) !important;
}

.tw-outline-sky-100\/80 {
  outline-color: rgb(224 242 254 / 0.8) !important;
}

.tw-outline-sky-100\/85 {
  outline-color: rgb(224 242 254 / 0.85) !important;
}

.tw-outline-sky-100\/90 {
  outline-color: rgb(224 242 254 / 0.9) !important;
}

.tw-outline-sky-100\/95 {
  outline-color: rgb(224 242 254 / 0.95) !important;
}

.tw-outline-sky-200 {
  outline-color: #bae6fd !important;
}

.tw-outline-sky-200\/0 {
  outline-color: rgb(186 230 253 / 0) !important;
}

.tw-outline-sky-200\/10 {
  outline-color: rgb(186 230 253 / 0.1) !important;
}

.tw-outline-sky-200\/100 {
  outline-color: rgb(186 230 253 / 1) !important;
}

.tw-outline-sky-200\/15 {
  outline-color: rgb(186 230 253 / 0.15) !important;
}

.tw-outline-sky-200\/20 {
  outline-color: rgb(186 230 253 / 0.2) !important;
}

.tw-outline-sky-200\/25 {
  outline-color: rgb(186 230 253 / 0.25) !important;
}

.tw-outline-sky-200\/30 {
  outline-color: rgb(186 230 253 / 0.3) !important;
}

.tw-outline-sky-200\/35 {
  outline-color: rgb(186 230 253 / 0.35) !important;
}

.tw-outline-sky-200\/40 {
  outline-color: rgb(186 230 253 / 0.4) !important;
}

.tw-outline-sky-200\/45 {
  outline-color: rgb(186 230 253 / 0.45) !important;
}

.tw-outline-sky-200\/5 {
  outline-color: rgb(186 230 253 / 0.05) !important;
}

.tw-outline-sky-200\/50 {
  outline-color: rgb(186 230 253 / 0.5) !important;
}

.tw-outline-sky-200\/55 {
  outline-color: rgb(186 230 253 / 0.55) !important;
}

.tw-outline-sky-200\/60 {
  outline-color: rgb(186 230 253 / 0.6) !important;
}

.tw-outline-sky-200\/65 {
  outline-color: rgb(186 230 253 / 0.65) !important;
}

.tw-outline-sky-200\/70 {
  outline-color: rgb(186 230 253 / 0.7) !important;
}

.tw-outline-sky-200\/75 {
  outline-color: rgb(186 230 253 / 0.75) !important;
}

.tw-outline-sky-200\/80 {
  outline-color: rgb(186 230 253 / 0.8) !important;
}

.tw-outline-sky-200\/85 {
  outline-color: rgb(186 230 253 / 0.85) !important;
}

.tw-outline-sky-200\/90 {
  outline-color: rgb(186 230 253 / 0.9) !important;
}

.tw-outline-sky-200\/95 {
  outline-color: rgb(186 230 253 / 0.95) !important;
}

.tw-outline-sky-300 {
  outline-color: #7dd3fc !important;
}

.tw-outline-sky-300\/0 {
  outline-color: rgb(125 211 252 / 0) !important;
}

.tw-outline-sky-300\/10 {
  outline-color: rgb(125 211 252 / 0.1) !important;
}

.tw-outline-sky-300\/100 {
  outline-color: rgb(125 211 252 / 1) !important;
}

.tw-outline-sky-300\/15 {
  outline-color: rgb(125 211 252 / 0.15) !important;
}

.tw-outline-sky-300\/20 {
  outline-color: rgb(125 211 252 / 0.2) !important;
}

.tw-outline-sky-300\/25 {
  outline-color: rgb(125 211 252 / 0.25) !important;
}

.tw-outline-sky-300\/30 {
  outline-color: rgb(125 211 252 / 0.3) !important;
}

.tw-outline-sky-300\/35 {
  outline-color: rgb(125 211 252 / 0.35) !important;
}

.tw-outline-sky-300\/40 {
  outline-color: rgb(125 211 252 / 0.4) !important;
}

.tw-outline-sky-300\/45 {
  outline-color: rgb(125 211 252 / 0.45) !important;
}

.tw-outline-sky-300\/5 {
  outline-color: rgb(125 211 252 / 0.05) !important;
}

.tw-outline-sky-300\/50 {
  outline-color: rgb(125 211 252 / 0.5) !important;
}

.tw-outline-sky-300\/55 {
  outline-color: rgb(125 211 252 / 0.55) !important;
}

.tw-outline-sky-300\/60 {
  outline-color: rgb(125 211 252 / 0.6) !important;
}

.tw-outline-sky-300\/65 {
  outline-color: rgb(125 211 252 / 0.65) !important;
}

.tw-outline-sky-300\/70 {
  outline-color: rgb(125 211 252 / 0.7) !important;
}

.tw-outline-sky-300\/75 {
  outline-color: rgb(125 211 252 / 0.75) !important;
}

.tw-outline-sky-300\/80 {
  outline-color: rgb(125 211 252 / 0.8) !important;
}

.tw-outline-sky-300\/85 {
  outline-color: rgb(125 211 252 / 0.85) !important;
}

.tw-outline-sky-300\/90 {
  outline-color: rgb(125 211 252 / 0.9) !important;
}

.tw-outline-sky-300\/95 {
  outline-color: rgb(125 211 252 / 0.95) !important;
}

.tw-outline-sky-400 {
  outline-color: #38bdf8 !important;
}

.tw-outline-sky-400\/0 {
  outline-color: rgb(56 189 248 / 0) !important;
}

.tw-outline-sky-400\/10 {
  outline-color: rgb(56 189 248 / 0.1) !important;
}

.tw-outline-sky-400\/100 {
  outline-color: rgb(56 189 248 / 1) !important;
}

.tw-outline-sky-400\/15 {
  outline-color: rgb(56 189 248 / 0.15) !important;
}

.tw-outline-sky-400\/20 {
  outline-color: rgb(56 189 248 / 0.2) !important;
}

.tw-outline-sky-400\/25 {
  outline-color: rgb(56 189 248 / 0.25) !important;
}

.tw-outline-sky-400\/30 {
  outline-color: rgb(56 189 248 / 0.3) !important;
}

.tw-outline-sky-400\/35 {
  outline-color: rgb(56 189 248 / 0.35) !important;
}

.tw-outline-sky-400\/40 {
  outline-color: rgb(56 189 248 / 0.4) !important;
}

.tw-outline-sky-400\/45 {
  outline-color: rgb(56 189 248 / 0.45) !important;
}

.tw-outline-sky-400\/5 {
  outline-color: rgb(56 189 248 / 0.05) !important;
}

.tw-outline-sky-400\/50 {
  outline-color: rgb(56 189 248 / 0.5) !important;
}

.tw-outline-sky-400\/55 {
  outline-color: rgb(56 189 248 / 0.55) !important;
}

.tw-outline-sky-400\/60 {
  outline-color: rgb(56 189 248 / 0.6) !important;
}

.tw-outline-sky-400\/65 {
  outline-color: rgb(56 189 248 / 0.65) !important;
}

.tw-outline-sky-400\/70 {
  outline-color: rgb(56 189 248 / 0.7) !important;
}

.tw-outline-sky-400\/75 {
  outline-color: rgb(56 189 248 / 0.75) !important;
}

.tw-outline-sky-400\/80 {
  outline-color: rgb(56 189 248 / 0.8) !important;
}

.tw-outline-sky-400\/85 {
  outline-color: rgb(56 189 248 / 0.85) !important;
}

.tw-outline-sky-400\/90 {
  outline-color: rgb(56 189 248 / 0.9) !important;
}

.tw-outline-sky-400\/95 {
  outline-color: rgb(56 189 248 / 0.95) !important;
}

.tw-outline-sky-50 {
  outline-color: #f0f9ff !important;
}

.tw-outline-sky-50\/0 {
  outline-color: rgb(240 249 255 / 0) !important;
}

.tw-outline-sky-50\/10 {
  outline-color: rgb(240 249 255 / 0.1) !important;
}

.tw-outline-sky-50\/100 {
  outline-color: rgb(240 249 255 / 1) !important;
}

.tw-outline-sky-50\/15 {
  outline-color: rgb(240 249 255 / 0.15) !important;
}

.tw-outline-sky-50\/20 {
  outline-color: rgb(240 249 255 / 0.2) !important;
}

.tw-outline-sky-50\/25 {
  outline-color: rgb(240 249 255 / 0.25) !important;
}

.tw-outline-sky-50\/30 {
  outline-color: rgb(240 249 255 / 0.3) !important;
}

.tw-outline-sky-50\/35 {
  outline-color: rgb(240 249 255 / 0.35) !important;
}

.tw-outline-sky-50\/40 {
  outline-color: rgb(240 249 255 / 0.4) !important;
}

.tw-outline-sky-50\/45 {
  outline-color: rgb(240 249 255 / 0.45) !important;
}

.tw-outline-sky-50\/5 {
  outline-color: rgb(240 249 255 / 0.05) !important;
}

.tw-outline-sky-50\/50 {
  outline-color: rgb(240 249 255 / 0.5) !important;
}

.tw-outline-sky-50\/55 {
  outline-color: rgb(240 249 255 / 0.55) !important;
}

.tw-outline-sky-50\/60 {
  outline-color: rgb(240 249 255 / 0.6) !important;
}

.tw-outline-sky-50\/65 {
  outline-color: rgb(240 249 255 / 0.65) !important;
}

.tw-outline-sky-50\/70 {
  outline-color: rgb(240 249 255 / 0.7) !important;
}

.tw-outline-sky-50\/75 {
  outline-color: rgb(240 249 255 / 0.75) !important;
}

.tw-outline-sky-50\/80 {
  outline-color: rgb(240 249 255 / 0.8) !important;
}

.tw-outline-sky-50\/85 {
  outline-color: rgb(240 249 255 / 0.85) !important;
}

.tw-outline-sky-50\/90 {
  outline-color: rgb(240 249 255 / 0.9) !important;
}

.tw-outline-sky-50\/95 {
  outline-color: rgb(240 249 255 / 0.95) !important;
}

.tw-outline-sky-500 {
  outline-color: #0ea5e9 !important;
}

.tw-outline-sky-500\/0 {
  outline-color: rgb(14 165 233 / 0) !important;
}

.tw-outline-sky-500\/10 {
  outline-color: rgb(14 165 233 / 0.1) !important;
}

.tw-outline-sky-500\/100 {
  outline-color: rgb(14 165 233 / 1) !important;
}

.tw-outline-sky-500\/15 {
  outline-color: rgb(14 165 233 / 0.15) !important;
}

.tw-outline-sky-500\/20 {
  outline-color: rgb(14 165 233 / 0.2) !important;
}

.tw-outline-sky-500\/25 {
  outline-color: rgb(14 165 233 / 0.25) !important;
}

.tw-outline-sky-500\/30 {
  outline-color: rgb(14 165 233 / 0.3) !important;
}

.tw-outline-sky-500\/35 {
  outline-color: rgb(14 165 233 / 0.35) !important;
}

.tw-outline-sky-500\/40 {
  outline-color: rgb(14 165 233 / 0.4) !important;
}

.tw-outline-sky-500\/45 {
  outline-color: rgb(14 165 233 / 0.45) !important;
}

.tw-outline-sky-500\/5 {
  outline-color: rgb(14 165 233 / 0.05) !important;
}

.tw-outline-sky-500\/50 {
  outline-color: rgb(14 165 233 / 0.5) !important;
}

.tw-outline-sky-500\/55 {
  outline-color: rgb(14 165 233 / 0.55) !important;
}

.tw-outline-sky-500\/60 {
  outline-color: rgb(14 165 233 / 0.6) !important;
}

.tw-outline-sky-500\/65 {
  outline-color: rgb(14 165 233 / 0.65) !important;
}

.tw-outline-sky-500\/70 {
  outline-color: rgb(14 165 233 / 0.7) !important;
}

.tw-outline-sky-500\/75 {
  outline-color: rgb(14 165 233 / 0.75) !important;
}

.tw-outline-sky-500\/80 {
  outline-color: rgb(14 165 233 / 0.8) !important;
}

.tw-outline-sky-500\/85 {
  outline-color: rgb(14 165 233 / 0.85) !important;
}

.tw-outline-sky-500\/90 {
  outline-color: rgb(14 165 233 / 0.9) !important;
}

.tw-outline-sky-500\/95 {
  outline-color: rgb(14 165 233 / 0.95) !important;
}

.tw-outline-sky-600 {
  outline-color: #0284c7 !important;
}

.tw-outline-sky-600\/0 {
  outline-color: rgb(2 132 199 / 0) !important;
}

.tw-outline-sky-600\/10 {
  outline-color: rgb(2 132 199 / 0.1) !important;
}

.tw-outline-sky-600\/100 {
  outline-color: rgb(2 132 199 / 1) !important;
}

.tw-outline-sky-600\/15 {
  outline-color: rgb(2 132 199 / 0.15) !important;
}

.tw-outline-sky-600\/20 {
  outline-color: rgb(2 132 199 / 0.2) !important;
}

.tw-outline-sky-600\/25 {
  outline-color: rgb(2 132 199 / 0.25) !important;
}

.tw-outline-sky-600\/30 {
  outline-color: rgb(2 132 199 / 0.3) !important;
}

.tw-outline-sky-600\/35 {
  outline-color: rgb(2 132 199 / 0.35) !important;
}

.tw-outline-sky-600\/40 {
  outline-color: rgb(2 132 199 / 0.4) !important;
}

.tw-outline-sky-600\/45 {
  outline-color: rgb(2 132 199 / 0.45) !important;
}

.tw-outline-sky-600\/5 {
  outline-color: rgb(2 132 199 / 0.05) !important;
}

.tw-outline-sky-600\/50 {
  outline-color: rgb(2 132 199 / 0.5) !important;
}

.tw-outline-sky-600\/55 {
  outline-color: rgb(2 132 199 / 0.55) !important;
}

.tw-outline-sky-600\/60 {
  outline-color: rgb(2 132 199 / 0.6) !important;
}

.tw-outline-sky-600\/65 {
  outline-color: rgb(2 132 199 / 0.65) !important;
}

.tw-outline-sky-600\/70 {
  outline-color: rgb(2 132 199 / 0.7) !important;
}

.tw-outline-sky-600\/75 {
  outline-color: rgb(2 132 199 / 0.75) !important;
}

.tw-outline-sky-600\/80 {
  outline-color: rgb(2 132 199 / 0.8) !important;
}

.tw-outline-sky-600\/85 {
  outline-color: rgb(2 132 199 / 0.85) !important;
}

.tw-outline-sky-600\/90 {
  outline-color: rgb(2 132 199 / 0.9) !important;
}

.tw-outline-sky-600\/95 {
  outline-color: rgb(2 132 199 / 0.95) !important;
}

.tw-outline-sky-700 {
  outline-color: #0369a1 !important;
}

.tw-outline-sky-700\/0 {
  outline-color: rgb(3 105 161 / 0) !important;
}

.tw-outline-sky-700\/10 {
  outline-color: rgb(3 105 161 / 0.1) !important;
}

.tw-outline-sky-700\/100 {
  outline-color: rgb(3 105 161 / 1) !important;
}

.tw-outline-sky-700\/15 {
  outline-color: rgb(3 105 161 / 0.15) !important;
}

.tw-outline-sky-700\/20 {
  outline-color: rgb(3 105 161 / 0.2) !important;
}

.tw-outline-sky-700\/25 {
  outline-color: rgb(3 105 161 / 0.25) !important;
}

.tw-outline-sky-700\/30 {
  outline-color: rgb(3 105 161 / 0.3) !important;
}

.tw-outline-sky-700\/35 {
  outline-color: rgb(3 105 161 / 0.35) !important;
}

.tw-outline-sky-700\/40 {
  outline-color: rgb(3 105 161 / 0.4) !important;
}

.tw-outline-sky-700\/45 {
  outline-color: rgb(3 105 161 / 0.45) !important;
}

.tw-outline-sky-700\/5 {
  outline-color: rgb(3 105 161 / 0.05) !important;
}

.tw-outline-sky-700\/50 {
  outline-color: rgb(3 105 161 / 0.5) !important;
}

.tw-outline-sky-700\/55 {
  outline-color: rgb(3 105 161 / 0.55) !important;
}

.tw-outline-sky-700\/60 {
  outline-color: rgb(3 105 161 / 0.6) !important;
}

.tw-outline-sky-700\/65 {
  outline-color: rgb(3 105 161 / 0.65) !important;
}

.tw-outline-sky-700\/70 {
  outline-color: rgb(3 105 161 / 0.7) !important;
}

.tw-outline-sky-700\/75 {
  outline-color: rgb(3 105 161 / 0.75) !important;
}

.tw-outline-sky-700\/80 {
  outline-color: rgb(3 105 161 / 0.8) !important;
}

.tw-outline-sky-700\/85 {
  outline-color: rgb(3 105 161 / 0.85) !important;
}

.tw-outline-sky-700\/90 {
  outline-color: rgb(3 105 161 / 0.9) !important;
}

.tw-outline-sky-700\/95 {
  outline-color: rgb(3 105 161 / 0.95) !important;
}

.tw-outline-sky-800 {
  outline-color: #075985 !important;
}

.tw-outline-sky-800\/0 {
  outline-color: rgb(7 89 133 / 0) !important;
}

.tw-outline-sky-800\/10 {
  outline-color: rgb(7 89 133 / 0.1) !important;
}

.tw-outline-sky-800\/100 {
  outline-color: rgb(7 89 133 / 1) !important;
}

.tw-outline-sky-800\/15 {
  outline-color: rgb(7 89 133 / 0.15) !important;
}

.tw-outline-sky-800\/20 {
  outline-color: rgb(7 89 133 / 0.2) !important;
}

.tw-outline-sky-800\/25 {
  outline-color: rgb(7 89 133 / 0.25) !important;
}

.tw-outline-sky-800\/30 {
  outline-color: rgb(7 89 133 / 0.3) !important;
}

.tw-outline-sky-800\/35 {
  outline-color: rgb(7 89 133 / 0.35) !important;
}

.tw-outline-sky-800\/40 {
  outline-color: rgb(7 89 133 / 0.4) !important;
}

.tw-outline-sky-800\/45 {
  outline-color: rgb(7 89 133 / 0.45) !important;
}

.tw-outline-sky-800\/5 {
  outline-color: rgb(7 89 133 / 0.05) !important;
}

.tw-outline-sky-800\/50 {
  outline-color: rgb(7 89 133 / 0.5) !important;
}

.tw-outline-sky-800\/55 {
  outline-color: rgb(7 89 133 / 0.55) !important;
}

.tw-outline-sky-800\/60 {
  outline-color: rgb(7 89 133 / 0.6) !important;
}

.tw-outline-sky-800\/65 {
  outline-color: rgb(7 89 133 / 0.65) !important;
}

.tw-outline-sky-800\/70 {
  outline-color: rgb(7 89 133 / 0.7) !important;
}

.tw-outline-sky-800\/75 {
  outline-color: rgb(7 89 133 / 0.75) !important;
}

.tw-outline-sky-800\/80 {
  outline-color: rgb(7 89 133 / 0.8) !important;
}

.tw-outline-sky-800\/85 {
  outline-color: rgb(7 89 133 / 0.85) !important;
}

.tw-outline-sky-800\/90 {
  outline-color: rgb(7 89 133 / 0.9) !important;
}

.tw-outline-sky-800\/95 {
  outline-color: rgb(7 89 133 / 0.95) !important;
}

.tw-outline-sky-900 {
  outline-color: #0c4a6e !important;
}

.tw-outline-sky-900\/0 {
  outline-color: rgb(12 74 110 / 0) !important;
}

.tw-outline-sky-900\/10 {
  outline-color: rgb(12 74 110 / 0.1) !important;
}

.tw-outline-sky-900\/100 {
  outline-color: rgb(12 74 110 / 1) !important;
}

.tw-outline-sky-900\/15 {
  outline-color: rgb(12 74 110 / 0.15) !important;
}

.tw-outline-sky-900\/20 {
  outline-color: rgb(12 74 110 / 0.2) !important;
}

.tw-outline-sky-900\/25 {
  outline-color: rgb(12 74 110 / 0.25) !important;
}

.tw-outline-sky-900\/30 {
  outline-color: rgb(12 74 110 / 0.3) !important;
}

.tw-outline-sky-900\/35 {
  outline-color: rgb(12 74 110 / 0.35) !important;
}

.tw-outline-sky-900\/40 {
  outline-color: rgb(12 74 110 / 0.4) !important;
}

.tw-outline-sky-900\/45 {
  outline-color: rgb(12 74 110 / 0.45) !important;
}

.tw-outline-sky-900\/5 {
  outline-color: rgb(12 74 110 / 0.05) !important;
}

.tw-outline-sky-900\/50 {
  outline-color: rgb(12 74 110 / 0.5) !important;
}

.tw-outline-sky-900\/55 {
  outline-color: rgb(12 74 110 / 0.55) !important;
}

.tw-outline-sky-900\/60 {
  outline-color: rgb(12 74 110 / 0.6) !important;
}

.tw-outline-sky-900\/65 {
  outline-color: rgb(12 74 110 / 0.65) !important;
}

.tw-outline-sky-900\/70 {
  outline-color: rgb(12 74 110 / 0.7) !important;
}

.tw-outline-sky-900\/75 {
  outline-color: rgb(12 74 110 / 0.75) !important;
}

.tw-outline-sky-900\/80 {
  outline-color: rgb(12 74 110 / 0.8) !important;
}

.tw-outline-sky-900\/85 {
  outline-color: rgb(12 74 110 / 0.85) !important;
}

.tw-outline-sky-900\/90 {
  outline-color: rgb(12 74 110 / 0.9) !important;
}

.tw-outline-sky-900\/95 {
  outline-color: rgb(12 74 110 / 0.95) !important;
}

.tw-outline-sky-950 {
  outline-color: #082f49 !important;
}

.tw-outline-sky-950\/0 {
  outline-color: rgb(8 47 73 / 0) !important;
}

.tw-outline-sky-950\/10 {
  outline-color: rgb(8 47 73 / 0.1) !important;
}

.tw-outline-sky-950\/100 {
  outline-color: rgb(8 47 73 / 1) !important;
}

.tw-outline-sky-950\/15 {
  outline-color: rgb(8 47 73 / 0.15) !important;
}

.tw-outline-sky-950\/20 {
  outline-color: rgb(8 47 73 / 0.2) !important;
}

.tw-outline-sky-950\/25 {
  outline-color: rgb(8 47 73 / 0.25) !important;
}

.tw-outline-sky-950\/30 {
  outline-color: rgb(8 47 73 / 0.3) !important;
}

.tw-outline-sky-950\/35 {
  outline-color: rgb(8 47 73 / 0.35) !important;
}

.tw-outline-sky-950\/40 {
  outline-color: rgb(8 47 73 / 0.4) !important;
}

.tw-outline-sky-950\/45 {
  outline-color: rgb(8 47 73 / 0.45) !important;
}

.tw-outline-sky-950\/5 {
  outline-color: rgb(8 47 73 / 0.05) !important;
}

.tw-outline-sky-950\/50 {
  outline-color: rgb(8 47 73 / 0.5) !important;
}

.tw-outline-sky-950\/55 {
  outline-color: rgb(8 47 73 / 0.55) !important;
}

.tw-outline-sky-950\/60 {
  outline-color: rgb(8 47 73 / 0.6) !important;
}

.tw-outline-sky-950\/65 {
  outline-color: rgb(8 47 73 / 0.65) !important;
}

.tw-outline-sky-950\/70 {
  outline-color: rgb(8 47 73 / 0.7) !important;
}

.tw-outline-sky-950\/75 {
  outline-color: rgb(8 47 73 / 0.75) !important;
}

.tw-outline-sky-950\/80 {
  outline-color: rgb(8 47 73 / 0.8) !important;
}

.tw-outline-sky-950\/85 {
  outline-color: rgb(8 47 73 / 0.85) !important;
}

.tw-outline-sky-950\/90 {
  outline-color: rgb(8 47 73 / 0.9) !important;
}

.tw-outline-sky-950\/95 {
  outline-color: rgb(8 47 73 / 0.95) !important;
}

.tw-outline-slate-100 {
  outline-color: #f1f5f9 !important;
}

.tw-outline-slate-100\/0 {
  outline-color: rgb(241 245 249 / 0) !important;
}

.tw-outline-slate-100\/10 {
  outline-color: rgb(241 245 249 / 0.1) !important;
}

.tw-outline-slate-100\/100 {
  outline-color: rgb(241 245 249 / 1) !important;
}

.tw-outline-slate-100\/15 {
  outline-color: rgb(241 245 249 / 0.15) !important;
}

.tw-outline-slate-100\/20 {
  outline-color: rgb(241 245 249 / 0.2) !important;
}

.tw-outline-slate-100\/25 {
  outline-color: rgb(241 245 249 / 0.25) !important;
}

.tw-outline-slate-100\/30 {
  outline-color: rgb(241 245 249 / 0.3) !important;
}

.tw-outline-slate-100\/35 {
  outline-color: rgb(241 245 249 / 0.35) !important;
}

.tw-outline-slate-100\/40 {
  outline-color: rgb(241 245 249 / 0.4) !important;
}

.tw-outline-slate-100\/45 {
  outline-color: rgb(241 245 249 / 0.45) !important;
}

.tw-outline-slate-100\/5 {
  outline-color: rgb(241 245 249 / 0.05) !important;
}

.tw-outline-slate-100\/50 {
  outline-color: rgb(241 245 249 / 0.5) !important;
}

.tw-outline-slate-100\/55 {
  outline-color: rgb(241 245 249 / 0.55) !important;
}

.tw-outline-slate-100\/60 {
  outline-color: rgb(241 245 249 / 0.6) !important;
}

.tw-outline-slate-100\/65 {
  outline-color: rgb(241 245 249 / 0.65) !important;
}

.tw-outline-slate-100\/70 {
  outline-color: rgb(241 245 249 / 0.7) !important;
}

.tw-outline-slate-100\/75 {
  outline-color: rgb(241 245 249 / 0.75) !important;
}

.tw-outline-slate-100\/80 {
  outline-color: rgb(241 245 249 / 0.8) !important;
}

.tw-outline-slate-100\/85 {
  outline-color: rgb(241 245 249 / 0.85) !important;
}

.tw-outline-slate-100\/90 {
  outline-color: rgb(241 245 249 / 0.9) !important;
}

.tw-outline-slate-100\/95 {
  outline-color: rgb(241 245 249 / 0.95) !important;
}

.tw-outline-slate-200 {
  outline-color: #e2e8f0 !important;
}

.tw-outline-slate-200\/0 {
  outline-color: rgb(226 232 240 / 0) !important;
}

.tw-outline-slate-200\/10 {
  outline-color: rgb(226 232 240 / 0.1) !important;
}

.tw-outline-slate-200\/100 {
  outline-color: rgb(226 232 240 / 1) !important;
}

.tw-outline-slate-200\/15 {
  outline-color: rgb(226 232 240 / 0.15) !important;
}

.tw-outline-slate-200\/20 {
  outline-color: rgb(226 232 240 / 0.2) !important;
}

.tw-outline-slate-200\/25 {
  outline-color: rgb(226 232 240 / 0.25) !important;
}

.tw-outline-slate-200\/30 {
  outline-color: rgb(226 232 240 / 0.3) !important;
}

.tw-outline-slate-200\/35 {
  outline-color: rgb(226 232 240 / 0.35) !important;
}

.tw-outline-slate-200\/40 {
  outline-color: rgb(226 232 240 / 0.4) !important;
}

.tw-outline-slate-200\/45 {
  outline-color: rgb(226 232 240 / 0.45) !important;
}

.tw-outline-slate-200\/5 {
  outline-color: rgb(226 232 240 / 0.05) !important;
}

.tw-outline-slate-200\/50 {
  outline-color: rgb(226 232 240 / 0.5) !important;
}

.tw-outline-slate-200\/55 {
  outline-color: rgb(226 232 240 / 0.55) !important;
}

.tw-outline-slate-200\/60 {
  outline-color: rgb(226 232 240 / 0.6) !important;
}

.tw-outline-slate-200\/65 {
  outline-color: rgb(226 232 240 / 0.65) !important;
}

.tw-outline-slate-200\/70 {
  outline-color: rgb(226 232 240 / 0.7) !important;
}

.tw-outline-slate-200\/75 {
  outline-color: rgb(226 232 240 / 0.75) !important;
}

.tw-outline-slate-200\/80 {
  outline-color: rgb(226 232 240 / 0.8) !important;
}

.tw-outline-slate-200\/85 {
  outline-color: rgb(226 232 240 / 0.85) !important;
}

.tw-outline-slate-200\/90 {
  outline-color: rgb(226 232 240 / 0.9) !important;
}

.tw-outline-slate-200\/95 {
  outline-color: rgb(226 232 240 / 0.95) !important;
}

.tw-outline-slate-300 {
  outline-color: #cbd5e1 !important;
}

.tw-outline-slate-300\/0 {
  outline-color: rgb(203 213 225 / 0) !important;
}

.tw-outline-slate-300\/10 {
  outline-color: rgb(203 213 225 / 0.1) !important;
}

.tw-outline-slate-300\/100 {
  outline-color: rgb(203 213 225 / 1) !important;
}

.tw-outline-slate-300\/15 {
  outline-color: rgb(203 213 225 / 0.15) !important;
}

.tw-outline-slate-300\/20 {
  outline-color: rgb(203 213 225 / 0.2) !important;
}

.tw-outline-slate-300\/25 {
  outline-color: rgb(203 213 225 / 0.25) !important;
}

.tw-outline-slate-300\/30 {
  outline-color: rgb(203 213 225 / 0.3) !important;
}

.tw-outline-slate-300\/35 {
  outline-color: rgb(203 213 225 / 0.35) !important;
}

.tw-outline-slate-300\/40 {
  outline-color: rgb(203 213 225 / 0.4) !important;
}

.tw-outline-slate-300\/45 {
  outline-color: rgb(203 213 225 / 0.45) !important;
}

.tw-outline-slate-300\/5 {
  outline-color: rgb(203 213 225 / 0.05) !important;
}

.tw-outline-slate-300\/50 {
  outline-color: rgb(203 213 225 / 0.5) !important;
}

.tw-outline-slate-300\/55 {
  outline-color: rgb(203 213 225 / 0.55) !important;
}

.tw-outline-slate-300\/60 {
  outline-color: rgb(203 213 225 / 0.6) !important;
}

.tw-outline-slate-300\/65 {
  outline-color: rgb(203 213 225 / 0.65) !important;
}

.tw-outline-slate-300\/70 {
  outline-color: rgb(203 213 225 / 0.7) !important;
}

.tw-outline-slate-300\/75 {
  outline-color: rgb(203 213 225 / 0.75) !important;
}

.tw-outline-slate-300\/80 {
  outline-color: rgb(203 213 225 / 0.8) !important;
}

.tw-outline-slate-300\/85 {
  outline-color: rgb(203 213 225 / 0.85) !important;
}

.tw-outline-slate-300\/90 {
  outline-color: rgb(203 213 225 / 0.9) !important;
}

.tw-outline-slate-300\/95 {
  outline-color: rgb(203 213 225 / 0.95) !important;
}

.tw-outline-slate-400 {
  outline-color: #94a3b8 !important;
}

.tw-outline-slate-400\/0 {
  outline-color: rgb(148 163 184 / 0) !important;
}

.tw-outline-slate-400\/10 {
  outline-color: rgb(148 163 184 / 0.1) !important;
}

.tw-outline-slate-400\/100 {
  outline-color: rgb(148 163 184 / 1) !important;
}

.tw-outline-slate-400\/15 {
  outline-color: rgb(148 163 184 / 0.15) !important;
}

.tw-outline-slate-400\/20 {
  outline-color: rgb(148 163 184 / 0.2) !important;
}

.tw-outline-slate-400\/25 {
  outline-color: rgb(148 163 184 / 0.25) !important;
}

.tw-outline-slate-400\/30 {
  outline-color: rgb(148 163 184 / 0.3) !important;
}

.tw-outline-slate-400\/35 {
  outline-color: rgb(148 163 184 / 0.35) !important;
}

.tw-outline-slate-400\/40 {
  outline-color: rgb(148 163 184 / 0.4) !important;
}

.tw-outline-slate-400\/45 {
  outline-color: rgb(148 163 184 / 0.45) !important;
}

.tw-outline-slate-400\/5 {
  outline-color: rgb(148 163 184 / 0.05) !important;
}

.tw-outline-slate-400\/50 {
  outline-color: rgb(148 163 184 / 0.5) !important;
}

.tw-outline-slate-400\/55 {
  outline-color: rgb(148 163 184 / 0.55) !important;
}

.tw-outline-slate-400\/60 {
  outline-color: rgb(148 163 184 / 0.6) !important;
}

.tw-outline-slate-400\/65 {
  outline-color: rgb(148 163 184 / 0.65) !important;
}

.tw-outline-slate-400\/70 {
  outline-color: rgb(148 163 184 / 0.7) !important;
}

.tw-outline-slate-400\/75 {
  outline-color: rgb(148 163 184 / 0.75) !important;
}

.tw-outline-slate-400\/80 {
  outline-color: rgb(148 163 184 / 0.8) !important;
}

.tw-outline-slate-400\/85 {
  outline-color: rgb(148 163 184 / 0.85) !important;
}

.tw-outline-slate-400\/90 {
  outline-color: rgb(148 163 184 / 0.9) !important;
}

.tw-outline-slate-400\/95 {
  outline-color: rgb(148 163 184 / 0.95) !important;
}

.tw-outline-slate-50 {
  outline-color: #f8fafc !important;
}

.tw-outline-slate-50\/0 {
  outline-color: rgb(248 250 252 / 0) !important;
}

.tw-outline-slate-50\/10 {
  outline-color: rgb(248 250 252 / 0.1) !important;
}

.tw-outline-slate-50\/100 {
  outline-color: rgb(248 250 252 / 1) !important;
}

.tw-outline-slate-50\/15 {
  outline-color: rgb(248 250 252 / 0.15) !important;
}

.tw-outline-slate-50\/20 {
  outline-color: rgb(248 250 252 / 0.2) !important;
}

.tw-outline-slate-50\/25 {
  outline-color: rgb(248 250 252 / 0.25) !important;
}

.tw-outline-slate-50\/30 {
  outline-color: rgb(248 250 252 / 0.3) !important;
}

.tw-outline-slate-50\/35 {
  outline-color: rgb(248 250 252 / 0.35) !important;
}

.tw-outline-slate-50\/40 {
  outline-color: rgb(248 250 252 / 0.4) !important;
}

.tw-outline-slate-50\/45 {
  outline-color: rgb(248 250 252 / 0.45) !important;
}

.tw-outline-slate-50\/5 {
  outline-color: rgb(248 250 252 / 0.05) !important;
}

.tw-outline-slate-50\/50 {
  outline-color: rgb(248 250 252 / 0.5) !important;
}

.tw-outline-slate-50\/55 {
  outline-color: rgb(248 250 252 / 0.55) !important;
}

.tw-outline-slate-50\/60 {
  outline-color: rgb(248 250 252 / 0.6) !important;
}

.tw-outline-slate-50\/65 {
  outline-color: rgb(248 250 252 / 0.65) !important;
}

.tw-outline-slate-50\/70 {
  outline-color: rgb(248 250 252 / 0.7) !important;
}

.tw-outline-slate-50\/75 {
  outline-color: rgb(248 250 252 / 0.75) !important;
}

.tw-outline-slate-50\/80 {
  outline-color: rgb(248 250 252 / 0.8) !important;
}

.tw-outline-slate-50\/85 {
  outline-color: rgb(248 250 252 / 0.85) !important;
}

.tw-outline-slate-50\/90 {
  outline-color: rgb(248 250 252 / 0.9) !important;
}

.tw-outline-slate-50\/95 {
  outline-color: rgb(248 250 252 / 0.95) !important;
}

.tw-outline-slate-500 {
  outline-color: #64748b !important;
}

.tw-outline-slate-500\/0 {
  outline-color: rgb(100 116 139 / 0) !important;
}

.tw-outline-slate-500\/10 {
  outline-color: rgb(100 116 139 / 0.1) !important;
}

.tw-outline-slate-500\/100 {
  outline-color: rgb(100 116 139 / 1) !important;
}

.tw-outline-slate-500\/15 {
  outline-color: rgb(100 116 139 / 0.15) !important;
}

.tw-outline-slate-500\/20 {
  outline-color: rgb(100 116 139 / 0.2) !important;
}

.tw-outline-slate-500\/25 {
  outline-color: rgb(100 116 139 / 0.25) !important;
}

.tw-outline-slate-500\/30 {
  outline-color: rgb(100 116 139 / 0.3) !important;
}

.tw-outline-slate-500\/35 {
  outline-color: rgb(100 116 139 / 0.35) !important;
}

.tw-outline-slate-500\/40 {
  outline-color: rgb(100 116 139 / 0.4) !important;
}

.tw-outline-slate-500\/45 {
  outline-color: rgb(100 116 139 / 0.45) !important;
}

.tw-outline-slate-500\/5 {
  outline-color: rgb(100 116 139 / 0.05) !important;
}

.tw-outline-slate-500\/50 {
  outline-color: rgb(100 116 139 / 0.5) !important;
}

.tw-outline-slate-500\/55 {
  outline-color: rgb(100 116 139 / 0.55) !important;
}

.tw-outline-slate-500\/60 {
  outline-color: rgb(100 116 139 / 0.6) !important;
}

.tw-outline-slate-500\/65 {
  outline-color: rgb(100 116 139 / 0.65) !important;
}

.tw-outline-slate-500\/70 {
  outline-color: rgb(100 116 139 / 0.7) !important;
}

.tw-outline-slate-500\/75 {
  outline-color: rgb(100 116 139 / 0.75) !important;
}

.tw-outline-slate-500\/80 {
  outline-color: rgb(100 116 139 / 0.8) !important;
}

.tw-outline-slate-500\/85 {
  outline-color: rgb(100 116 139 / 0.85) !important;
}

.tw-outline-slate-500\/90 {
  outline-color: rgb(100 116 139 / 0.9) !important;
}

.tw-outline-slate-500\/95 {
  outline-color: rgb(100 116 139 / 0.95) !important;
}

.tw-outline-slate-600 {
  outline-color: #475569 !important;
}

.tw-outline-slate-600\/0 {
  outline-color: rgb(71 85 105 / 0) !important;
}

.tw-outline-slate-600\/10 {
  outline-color: rgb(71 85 105 / 0.1) !important;
}

.tw-outline-slate-600\/100 {
  outline-color: rgb(71 85 105 / 1) !important;
}

.tw-outline-slate-600\/15 {
  outline-color: rgb(71 85 105 / 0.15) !important;
}

.tw-outline-slate-600\/20 {
  outline-color: rgb(71 85 105 / 0.2) !important;
}

.tw-outline-slate-600\/25 {
  outline-color: rgb(71 85 105 / 0.25) !important;
}

.tw-outline-slate-600\/30 {
  outline-color: rgb(71 85 105 / 0.3) !important;
}

.tw-outline-slate-600\/35 {
  outline-color: rgb(71 85 105 / 0.35) !important;
}

.tw-outline-slate-600\/40 {
  outline-color: rgb(71 85 105 / 0.4) !important;
}

.tw-outline-slate-600\/45 {
  outline-color: rgb(71 85 105 / 0.45) !important;
}

.tw-outline-slate-600\/5 {
  outline-color: rgb(71 85 105 / 0.05) !important;
}

.tw-outline-slate-600\/50 {
  outline-color: rgb(71 85 105 / 0.5) !important;
}

.tw-outline-slate-600\/55 {
  outline-color: rgb(71 85 105 / 0.55) !important;
}

.tw-outline-slate-600\/60 {
  outline-color: rgb(71 85 105 / 0.6) !important;
}

.tw-outline-slate-600\/65 {
  outline-color: rgb(71 85 105 / 0.65) !important;
}

.tw-outline-slate-600\/70 {
  outline-color: rgb(71 85 105 / 0.7) !important;
}

.tw-outline-slate-600\/75 {
  outline-color: rgb(71 85 105 / 0.75) !important;
}

.tw-outline-slate-600\/80 {
  outline-color: rgb(71 85 105 / 0.8) !important;
}

.tw-outline-slate-600\/85 {
  outline-color: rgb(71 85 105 / 0.85) !important;
}

.tw-outline-slate-600\/90 {
  outline-color: rgb(71 85 105 / 0.9) !important;
}

.tw-outline-slate-600\/95 {
  outline-color: rgb(71 85 105 / 0.95) !important;
}

.tw-outline-slate-700 {
  outline-color: #334155 !important;
}

.tw-outline-slate-700\/0 {
  outline-color: rgb(51 65 85 / 0) !important;
}

.tw-outline-slate-700\/10 {
  outline-color: rgb(51 65 85 / 0.1) !important;
}

.tw-outline-slate-700\/100 {
  outline-color: rgb(51 65 85 / 1) !important;
}

.tw-outline-slate-700\/15 {
  outline-color: rgb(51 65 85 / 0.15) !important;
}

.tw-outline-slate-700\/20 {
  outline-color: rgb(51 65 85 / 0.2) !important;
}

.tw-outline-slate-700\/25 {
  outline-color: rgb(51 65 85 / 0.25) !important;
}

.tw-outline-slate-700\/30 {
  outline-color: rgb(51 65 85 / 0.3) !important;
}

.tw-outline-slate-700\/35 {
  outline-color: rgb(51 65 85 / 0.35) !important;
}

.tw-outline-slate-700\/40 {
  outline-color: rgb(51 65 85 / 0.4) !important;
}

.tw-outline-slate-700\/45 {
  outline-color: rgb(51 65 85 / 0.45) !important;
}

.tw-outline-slate-700\/5 {
  outline-color: rgb(51 65 85 / 0.05) !important;
}

.tw-outline-slate-700\/50 {
  outline-color: rgb(51 65 85 / 0.5) !important;
}

.tw-outline-slate-700\/55 {
  outline-color: rgb(51 65 85 / 0.55) !important;
}

.tw-outline-slate-700\/60 {
  outline-color: rgb(51 65 85 / 0.6) !important;
}

.tw-outline-slate-700\/65 {
  outline-color: rgb(51 65 85 / 0.65) !important;
}

.tw-outline-slate-700\/70 {
  outline-color: rgb(51 65 85 / 0.7) !important;
}

.tw-outline-slate-700\/75 {
  outline-color: rgb(51 65 85 / 0.75) !important;
}

.tw-outline-slate-700\/80 {
  outline-color: rgb(51 65 85 / 0.8) !important;
}

.tw-outline-slate-700\/85 {
  outline-color: rgb(51 65 85 / 0.85) !important;
}

.tw-outline-slate-700\/90 {
  outline-color: rgb(51 65 85 / 0.9) !important;
}

.tw-outline-slate-700\/95 {
  outline-color: rgb(51 65 85 / 0.95) !important;
}

.tw-outline-slate-800 {
  outline-color: #1e293b !important;
}

.tw-outline-slate-800\/0 {
  outline-color: rgb(30 41 59 / 0) !important;
}

.tw-outline-slate-800\/10 {
  outline-color: rgb(30 41 59 / 0.1) !important;
}

.tw-outline-slate-800\/100 {
  outline-color: rgb(30 41 59 / 1) !important;
}

.tw-outline-slate-800\/15 {
  outline-color: rgb(30 41 59 / 0.15) !important;
}

.tw-outline-slate-800\/20 {
  outline-color: rgb(30 41 59 / 0.2) !important;
}

.tw-outline-slate-800\/25 {
  outline-color: rgb(30 41 59 / 0.25) !important;
}

.tw-outline-slate-800\/30 {
  outline-color: rgb(30 41 59 / 0.3) !important;
}

.tw-outline-slate-800\/35 {
  outline-color: rgb(30 41 59 / 0.35) !important;
}

.tw-outline-slate-800\/40 {
  outline-color: rgb(30 41 59 / 0.4) !important;
}

.tw-outline-slate-800\/45 {
  outline-color: rgb(30 41 59 / 0.45) !important;
}

.tw-outline-slate-800\/5 {
  outline-color: rgb(30 41 59 / 0.05) !important;
}

.tw-outline-slate-800\/50 {
  outline-color: rgb(30 41 59 / 0.5) !important;
}

.tw-outline-slate-800\/55 {
  outline-color: rgb(30 41 59 / 0.55) !important;
}

.tw-outline-slate-800\/60 {
  outline-color: rgb(30 41 59 / 0.6) !important;
}

.tw-outline-slate-800\/65 {
  outline-color: rgb(30 41 59 / 0.65) !important;
}

.tw-outline-slate-800\/70 {
  outline-color: rgb(30 41 59 / 0.7) !important;
}

.tw-outline-slate-800\/75 {
  outline-color: rgb(30 41 59 / 0.75) !important;
}

.tw-outline-slate-800\/80 {
  outline-color: rgb(30 41 59 / 0.8) !important;
}

.tw-outline-slate-800\/85 {
  outline-color: rgb(30 41 59 / 0.85) !important;
}

.tw-outline-slate-800\/90 {
  outline-color: rgb(30 41 59 / 0.9) !important;
}

.tw-outline-slate-800\/95 {
  outline-color: rgb(30 41 59 / 0.95) !important;
}

.tw-outline-slate-900 {
  outline-color: #0f172a !important;
}

.tw-outline-slate-900\/0 {
  outline-color: rgb(15 23 42 / 0) !important;
}

.tw-outline-slate-900\/10 {
  outline-color: rgb(15 23 42 / 0.1) !important;
}

.tw-outline-slate-900\/100 {
  outline-color: rgb(15 23 42 / 1) !important;
}

.tw-outline-slate-900\/15 {
  outline-color: rgb(15 23 42 / 0.15) !important;
}

.tw-outline-slate-900\/20 {
  outline-color: rgb(15 23 42 / 0.2) !important;
}

.tw-outline-slate-900\/25 {
  outline-color: rgb(15 23 42 / 0.25) !important;
}

.tw-outline-slate-900\/30 {
  outline-color: rgb(15 23 42 / 0.3) !important;
}

.tw-outline-slate-900\/35 {
  outline-color: rgb(15 23 42 / 0.35) !important;
}

.tw-outline-slate-900\/40 {
  outline-color: rgb(15 23 42 / 0.4) !important;
}

.tw-outline-slate-900\/45 {
  outline-color: rgb(15 23 42 / 0.45) !important;
}

.tw-outline-slate-900\/5 {
  outline-color: rgb(15 23 42 / 0.05) !important;
}

.tw-outline-slate-900\/50 {
  outline-color: rgb(15 23 42 / 0.5) !important;
}

.tw-outline-slate-900\/55 {
  outline-color: rgb(15 23 42 / 0.55) !important;
}

.tw-outline-slate-900\/60 {
  outline-color: rgb(15 23 42 / 0.6) !important;
}

.tw-outline-slate-900\/65 {
  outline-color: rgb(15 23 42 / 0.65) !important;
}

.tw-outline-slate-900\/70 {
  outline-color: rgb(15 23 42 / 0.7) !important;
}

.tw-outline-slate-900\/75 {
  outline-color: rgb(15 23 42 / 0.75) !important;
}

.tw-outline-slate-900\/80 {
  outline-color: rgb(15 23 42 / 0.8) !important;
}

.tw-outline-slate-900\/85 {
  outline-color: rgb(15 23 42 / 0.85) !important;
}

.tw-outline-slate-900\/90 {
  outline-color: rgb(15 23 42 / 0.9) !important;
}

.tw-outline-slate-900\/95 {
  outline-color: rgb(15 23 42 / 0.95) !important;
}

.tw-outline-slate-950 {
  outline-color: #020617 !important;
}

.tw-outline-slate-950\/0 {
  outline-color: rgb(2 6 23 / 0) !important;
}

.tw-outline-slate-950\/10 {
  outline-color: rgb(2 6 23 / 0.1) !important;
}

.tw-outline-slate-950\/100 {
  outline-color: rgb(2 6 23 / 1) !important;
}

.tw-outline-slate-950\/15 {
  outline-color: rgb(2 6 23 / 0.15) !important;
}

.tw-outline-slate-950\/20 {
  outline-color: rgb(2 6 23 / 0.2) !important;
}

.tw-outline-slate-950\/25 {
  outline-color: rgb(2 6 23 / 0.25) !important;
}

.tw-outline-slate-950\/30 {
  outline-color: rgb(2 6 23 / 0.3) !important;
}

.tw-outline-slate-950\/35 {
  outline-color: rgb(2 6 23 / 0.35) !important;
}

.tw-outline-slate-950\/40 {
  outline-color: rgb(2 6 23 / 0.4) !important;
}

.tw-outline-slate-950\/45 {
  outline-color: rgb(2 6 23 / 0.45) !important;
}

.tw-outline-slate-950\/5 {
  outline-color: rgb(2 6 23 / 0.05) !important;
}

.tw-outline-slate-950\/50 {
  outline-color: rgb(2 6 23 / 0.5) !important;
}

.tw-outline-slate-950\/55 {
  outline-color: rgb(2 6 23 / 0.55) !important;
}

.tw-outline-slate-950\/60 {
  outline-color: rgb(2 6 23 / 0.6) !important;
}

.tw-outline-slate-950\/65 {
  outline-color: rgb(2 6 23 / 0.65) !important;
}

.tw-outline-slate-950\/70 {
  outline-color: rgb(2 6 23 / 0.7) !important;
}

.tw-outline-slate-950\/75 {
  outline-color: rgb(2 6 23 / 0.75) !important;
}

.tw-outline-slate-950\/80 {
  outline-color: rgb(2 6 23 / 0.8) !important;
}

.tw-outline-slate-950\/85 {
  outline-color: rgb(2 6 23 / 0.85) !important;
}

.tw-outline-slate-950\/90 {
  outline-color: rgb(2 6 23 / 0.9) !important;
}

.tw-outline-slate-950\/95 {
  outline-color: rgb(2 6 23 / 0.95) !important;
}

.tw-outline-stone-100 {
  outline-color: #f5f5f4 !important;
}

.tw-outline-stone-100\/0 {
  outline-color: rgb(245 245 244 / 0) !important;
}

.tw-outline-stone-100\/10 {
  outline-color: rgb(245 245 244 / 0.1) !important;
}

.tw-outline-stone-100\/100 {
  outline-color: rgb(245 245 244 / 1) !important;
}

.tw-outline-stone-100\/15 {
  outline-color: rgb(245 245 244 / 0.15) !important;
}

.tw-outline-stone-100\/20 {
  outline-color: rgb(245 245 244 / 0.2) !important;
}

.tw-outline-stone-100\/25 {
  outline-color: rgb(245 245 244 / 0.25) !important;
}

.tw-outline-stone-100\/30 {
  outline-color: rgb(245 245 244 / 0.3) !important;
}

.tw-outline-stone-100\/35 {
  outline-color: rgb(245 245 244 / 0.35) !important;
}

.tw-outline-stone-100\/40 {
  outline-color: rgb(245 245 244 / 0.4) !important;
}

.tw-outline-stone-100\/45 {
  outline-color: rgb(245 245 244 / 0.45) !important;
}

.tw-outline-stone-100\/5 {
  outline-color: rgb(245 245 244 / 0.05) !important;
}

.tw-outline-stone-100\/50 {
  outline-color: rgb(245 245 244 / 0.5) !important;
}

.tw-outline-stone-100\/55 {
  outline-color: rgb(245 245 244 / 0.55) !important;
}

.tw-outline-stone-100\/60 {
  outline-color: rgb(245 245 244 / 0.6) !important;
}

.tw-outline-stone-100\/65 {
  outline-color: rgb(245 245 244 / 0.65) !important;
}

.tw-outline-stone-100\/70 {
  outline-color: rgb(245 245 244 / 0.7) !important;
}

.tw-outline-stone-100\/75 {
  outline-color: rgb(245 245 244 / 0.75) !important;
}

.tw-outline-stone-100\/80 {
  outline-color: rgb(245 245 244 / 0.8) !important;
}

.tw-outline-stone-100\/85 {
  outline-color: rgb(245 245 244 / 0.85) !important;
}

.tw-outline-stone-100\/90 {
  outline-color: rgb(245 245 244 / 0.9) !important;
}

.tw-outline-stone-100\/95 {
  outline-color: rgb(245 245 244 / 0.95) !important;
}

.tw-outline-stone-200 {
  outline-color: #e7e5e4 !important;
}

.tw-outline-stone-200\/0 {
  outline-color: rgb(231 229 228 / 0) !important;
}

.tw-outline-stone-200\/10 {
  outline-color: rgb(231 229 228 / 0.1) !important;
}

.tw-outline-stone-200\/100 {
  outline-color: rgb(231 229 228 / 1) !important;
}

.tw-outline-stone-200\/15 {
  outline-color: rgb(231 229 228 / 0.15) !important;
}

.tw-outline-stone-200\/20 {
  outline-color: rgb(231 229 228 / 0.2) !important;
}

.tw-outline-stone-200\/25 {
  outline-color: rgb(231 229 228 / 0.25) !important;
}

.tw-outline-stone-200\/30 {
  outline-color: rgb(231 229 228 / 0.3) !important;
}

.tw-outline-stone-200\/35 {
  outline-color: rgb(231 229 228 / 0.35) !important;
}

.tw-outline-stone-200\/40 {
  outline-color: rgb(231 229 228 / 0.4) !important;
}

.tw-outline-stone-200\/45 {
  outline-color: rgb(231 229 228 / 0.45) !important;
}

.tw-outline-stone-200\/5 {
  outline-color: rgb(231 229 228 / 0.05) !important;
}

.tw-outline-stone-200\/50 {
  outline-color: rgb(231 229 228 / 0.5) !important;
}

.tw-outline-stone-200\/55 {
  outline-color: rgb(231 229 228 / 0.55) !important;
}

.tw-outline-stone-200\/60 {
  outline-color: rgb(231 229 228 / 0.6) !important;
}

.tw-outline-stone-200\/65 {
  outline-color: rgb(231 229 228 / 0.65) !important;
}

.tw-outline-stone-200\/70 {
  outline-color: rgb(231 229 228 / 0.7) !important;
}

.tw-outline-stone-200\/75 {
  outline-color: rgb(231 229 228 / 0.75) !important;
}

.tw-outline-stone-200\/80 {
  outline-color: rgb(231 229 228 / 0.8) !important;
}

.tw-outline-stone-200\/85 {
  outline-color: rgb(231 229 228 / 0.85) !important;
}

.tw-outline-stone-200\/90 {
  outline-color: rgb(231 229 228 / 0.9) !important;
}

.tw-outline-stone-200\/95 {
  outline-color: rgb(231 229 228 / 0.95) !important;
}

.tw-outline-stone-300 {
  outline-color: #d6d3d1 !important;
}

.tw-outline-stone-300\/0 {
  outline-color: rgb(214 211 209 / 0) !important;
}

.tw-outline-stone-300\/10 {
  outline-color: rgb(214 211 209 / 0.1) !important;
}

.tw-outline-stone-300\/100 {
  outline-color: rgb(214 211 209 / 1) !important;
}

.tw-outline-stone-300\/15 {
  outline-color: rgb(214 211 209 / 0.15) !important;
}

.tw-outline-stone-300\/20 {
  outline-color: rgb(214 211 209 / 0.2) !important;
}

.tw-outline-stone-300\/25 {
  outline-color: rgb(214 211 209 / 0.25) !important;
}

.tw-outline-stone-300\/30 {
  outline-color: rgb(214 211 209 / 0.3) !important;
}

.tw-outline-stone-300\/35 {
  outline-color: rgb(214 211 209 / 0.35) !important;
}

.tw-outline-stone-300\/40 {
  outline-color: rgb(214 211 209 / 0.4) !important;
}

.tw-outline-stone-300\/45 {
  outline-color: rgb(214 211 209 / 0.45) !important;
}

.tw-outline-stone-300\/5 {
  outline-color: rgb(214 211 209 / 0.05) !important;
}

.tw-outline-stone-300\/50 {
  outline-color: rgb(214 211 209 / 0.5) !important;
}

.tw-outline-stone-300\/55 {
  outline-color: rgb(214 211 209 / 0.55) !important;
}

.tw-outline-stone-300\/60 {
  outline-color: rgb(214 211 209 / 0.6) !important;
}

.tw-outline-stone-300\/65 {
  outline-color: rgb(214 211 209 / 0.65) !important;
}

.tw-outline-stone-300\/70 {
  outline-color: rgb(214 211 209 / 0.7) !important;
}

.tw-outline-stone-300\/75 {
  outline-color: rgb(214 211 209 / 0.75) !important;
}

.tw-outline-stone-300\/80 {
  outline-color: rgb(214 211 209 / 0.8) !important;
}

.tw-outline-stone-300\/85 {
  outline-color: rgb(214 211 209 / 0.85) !important;
}

.tw-outline-stone-300\/90 {
  outline-color: rgb(214 211 209 / 0.9) !important;
}

.tw-outline-stone-300\/95 {
  outline-color: rgb(214 211 209 / 0.95) !important;
}

.tw-outline-stone-400 {
  outline-color: #a8a29e !important;
}

.tw-outline-stone-400\/0 {
  outline-color: rgb(168 162 158 / 0) !important;
}

.tw-outline-stone-400\/10 {
  outline-color: rgb(168 162 158 / 0.1) !important;
}

.tw-outline-stone-400\/100 {
  outline-color: rgb(168 162 158 / 1) !important;
}

.tw-outline-stone-400\/15 {
  outline-color: rgb(168 162 158 / 0.15) !important;
}

.tw-outline-stone-400\/20 {
  outline-color: rgb(168 162 158 / 0.2) !important;
}

.tw-outline-stone-400\/25 {
  outline-color: rgb(168 162 158 / 0.25) !important;
}

.tw-outline-stone-400\/30 {
  outline-color: rgb(168 162 158 / 0.3) !important;
}

.tw-outline-stone-400\/35 {
  outline-color: rgb(168 162 158 / 0.35) !important;
}

.tw-outline-stone-400\/40 {
  outline-color: rgb(168 162 158 / 0.4) !important;
}

.tw-outline-stone-400\/45 {
  outline-color: rgb(168 162 158 / 0.45) !important;
}

.tw-outline-stone-400\/5 {
  outline-color: rgb(168 162 158 / 0.05) !important;
}

.tw-outline-stone-400\/50 {
  outline-color: rgb(168 162 158 / 0.5) !important;
}

.tw-outline-stone-400\/55 {
  outline-color: rgb(168 162 158 / 0.55) !important;
}

.tw-outline-stone-400\/60 {
  outline-color: rgb(168 162 158 / 0.6) !important;
}

.tw-outline-stone-400\/65 {
  outline-color: rgb(168 162 158 / 0.65) !important;
}

.tw-outline-stone-400\/70 {
  outline-color: rgb(168 162 158 / 0.7) !important;
}

.tw-outline-stone-400\/75 {
  outline-color: rgb(168 162 158 / 0.75) !important;
}

.tw-outline-stone-400\/80 {
  outline-color: rgb(168 162 158 / 0.8) !important;
}

.tw-outline-stone-400\/85 {
  outline-color: rgb(168 162 158 / 0.85) !important;
}

.tw-outline-stone-400\/90 {
  outline-color: rgb(168 162 158 / 0.9) !important;
}

.tw-outline-stone-400\/95 {
  outline-color: rgb(168 162 158 / 0.95) !important;
}

.tw-outline-stone-50 {
  outline-color: #fafaf9 !important;
}

.tw-outline-stone-50\/0 {
  outline-color: rgb(250 250 249 / 0) !important;
}

.tw-outline-stone-50\/10 {
  outline-color: rgb(250 250 249 / 0.1) !important;
}

.tw-outline-stone-50\/100 {
  outline-color: rgb(250 250 249 / 1) !important;
}

.tw-outline-stone-50\/15 {
  outline-color: rgb(250 250 249 / 0.15) !important;
}

.tw-outline-stone-50\/20 {
  outline-color: rgb(250 250 249 / 0.2) !important;
}

.tw-outline-stone-50\/25 {
  outline-color: rgb(250 250 249 / 0.25) !important;
}

.tw-outline-stone-50\/30 {
  outline-color: rgb(250 250 249 / 0.3) !important;
}

.tw-outline-stone-50\/35 {
  outline-color: rgb(250 250 249 / 0.35) !important;
}

.tw-outline-stone-50\/40 {
  outline-color: rgb(250 250 249 / 0.4) !important;
}

.tw-outline-stone-50\/45 {
  outline-color: rgb(250 250 249 / 0.45) !important;
}

.tw-outline-stone-50\/5 {
  outline-color: rgb(250 250 249 / 0.05) !important;
}

.tw-outline-stone-50\/50 {
  outline-color: rgb(250 250 249 / 0.5) !important;
}

.tw-outline-stone-50\/55 {
  outline-color: rgb(250 250 249 / 0.55) !important;
}

.tw-outline-stone-50\/60 {
  outline-color: rgb(250 250 249 / 0.6) !important;
}

.tw-outline-stone-50\/65 {
  outline-color: rgb(250 250 249 / 0.65) !important;
}

.tw-outline-stone-50\/70 {
  outline-color: rgb(250 250 249 / 0.7) !important;
}

.tw-outline-stone-50\/75 {
  outline-color: rgb(250 250 249 / 0.75) !important;
}

.tw-outline-stone-50\/80 {
  outline-color: rgb(250 250 249 / 0.8) !important;
}

.tw-outline-stone-50\/85 {
  outline-color: rgb(250 250 249 / 0.85) !important;
}

.tw-outline-stone-50\/90 {
  outline-color: rgb(250 250 249 / 0.9) !important;
}

.tw-outline-stone-50\/95 {
  outline-color: rgb(250 250 249 / 0.95) !important;
}

.tw-outline-stone-500 {
  outline-color: #78716c !important;
}

.tw-outline-stone-500\/0 {
  outline-color: rgb(120 113 108 / 0) !important;
}

.tw-outline-stone-500\/10 {
  outline-color: rgb(120 113 108 / 0.1) !important;
}

.tw-outline-stone-500\/100 {
  outline-color: rgb(120 113 108 / 1) !important;
}

.tw-outline-stone-500\/15 {
  outline-color: rgb(120 113 108 / 0.15) !important;
}

.tw-outline-stone-500\/20 {
  outline-color: rgb(120 113 108 / 0.2) !important;
}

.tw-outline-stone-500\/25 {
  outline-color: rgb(120 113 108 / 0.25) !important;
}

.tw-outline-stone-500\/30 {
  outline-color: rgb(120 113 108 / 0.3) !important;
}

.tw-outline-stone-500\/35 {
  outline-color: rgb(120 113 108 / 0.35) !important;
}

.tw-outline-stone-500\/40 {
  outline-color: rgb(120 113 108 / 0.4) !important;
}

.tw-outline-stone-500\/45 {
  outline-color: rgb(120 113 108 / 0.45) !important;
}

.tw-outline-stone-500\/5 {
  outline-color: rgb(120 113 108 / 0.05) !important;
}

.tw-outline-stone-500\/50 {
  outline-color: rgb(120 113 108 / 0.5) !important;
}

.tw-outline-stone-500\/55 {
  outline-color: rgb(120 113 108 / 0.55) !important;
}

.tw-outline-stone-500\/60 {
  outline-color: rgb(120 113 108 / 0.6) !important;
}

.tw-outline-stone-500\/65 {
  outline-color: rgb(120 113 108 / 0.65) !important;
}

.tw-outline-stone-500\/70 {
  outline-color: rgb(120 113 108 / 0.7) !important;
}

.tw-outline-stone-500\/75 {
  outline-color: rgb(120 113 108 / 0.75) !important;
}

.tw-outline-stone-500\/80 {
  outline-color: rgb(120 113 108 / 0.8) !important;
}

.tw-outline-stone-500\/85 {
  outline-color: rgb(120 113 108 / 0.85) !important;
}

.tw-outline-stone-500\/90 {
  outline-color: rgb(120 113 108 / 0.9) !important;
}

.tw-outline-stone-500\/95 {
  outline-color: rgb(120 113 108 / 0.95) !important;
}

.tw-outline-stone-600 {
  outline-color: #57534e !important;
}

.tw-outline-stone-600\/0 {
  outline-color: rgb(87 83 78 / 0) !important;
}

.tw-outline-stone-600\/10 {
  outline-color: rgb(87 83 78 / 0.1) !important;
}

.tw-outline-stone-600\/100 {
  outline-color: rgb(87 83 78 / 1) !important;
}

.tw-outline-stone-600\/15 {
  outline-color: rgb(87 83 78 / 0.15) !important;
}

.tw-outline-stone-600\/20 {
  outline-color: rgb(87 83 78 / 0.2) !important;
}

.tw-outline-stone-600\/25 {
  outline-color: rgb(87 83 78 / 0.25) !important;
}

.tw-outline-stone-600\/30 {
  outline-color: rgb(87 83 78 / 0.3) !important;
}

.tw-outline-stone-600\/35 {
  outline-color: rgb(87 83 78 / 0.35) !important;
}

.tw-outline-stone-600\/40 {
  outline-color: rgb(87 83 78 / 0.4) !important;
}

.tw-outline-stone-600\/45 {
  outline-color: rgb(87 83 78 / 0.45) !important;
}

.tw-outline-stone-600\/5 {
  outline-color: rgb(87 83 78 / 0.05) !important;
}

.tw-outline-stone-600\/50 {
  outline-color: rgb(87 83 78 / 0.5) !important;
}

.tw-outline-stone-600\/55 {
  outline-color: rgb(87 83 78 / 0.55) !important;
}

.tw-outline-stone-600\/60 {
  outline-color: rgb(87 83 78 / 0.6) !important;
}

.tw-outline-stone-600\/65 {
  outline-color: rgb(87 83 78 / 0.65) !important;
}

.tw-outline-stone-600\/70 {
  outline-color: rgb(87 83 78 / 0.7) !important;
}

.tw-outline-stone-600\/75 {
  outline-color: rgb(87 83 78 / 0.75) !important;
}

.tw-outline-stone-600\/80 {
  outline-color: rgb(87 83 78 / 0.8) !important;
}

.tw-outline-stone-600\/85 {
  outline-color: rgb(87 83 78 / 0.85) !important;
}

.tw-outline-stone-600\/90 {
  outline-color: rgb(87 83 78 / 0.9) !important;
}

.tw-outline-stone-600\/95 {
  outline-color: rgb(87 83 78 / 0.95) !important;
}

.tw-outline-stone-700 {
  outline-color: #44403c !important;
}

.tw-outline-stone-700\/0 {
  outline-color: rgb(68 64 60 / 0) !important;
}

.tw-outline-stone-700\/10 {
  outline-color: rgb(68 64 60 / 0.1) !important;
}

.tw-outline-stone-700\/100 {
  outline-color: rgb(68 64 60 / 1) !important;
}

.tw-outline-stone-700\/15 {
  outline-color: rgb(68 64 60 / 0.15) !important;
}

.tw-outline-stone-700\/20 {
  outline-color: rgb(68 64 60 / 0.2) !important;
}

.tw-outline-stone-700\/25 {
  outline-color: rgb(68 64 60 / 0.25) !important;
}

.tw-outline-stone-700\/30 {
  outline-color: rgb(68 64 60 / 0.3) !important;
}

.tw-outline-stone-700\/35 {
  outline-color: rgb(68 64 60 / 0.35) !important;
}

.tw-outline-stone-700\/40 {
  outline-color: rgb(68 64 60 / 0.4) !important;
}

.tw-outline-stone-700\/45 {
  outline-color: rgb(68 64 60 / 0.45) !important;
}

.tw-outline-stone-700\/5 {
  outline-color: rgb(68 64 60 / 0.05) !important;
}

.tw-outline-stone-700\/50 {
  outline-color: rgb(68 64 60 / 0.5) !important;
}

.tw-outline-stone-700\/55 {
  outline-color: rgb(68 64 60 / 0.55) !important;
}

.tw-outline-stone-700\/60 {
  outline-color: rgb(68 64 60 / 0.6) !important;
}

.tw-outline-stone-700\/65 {
  outline-color: rgb(68 64 60 / 0.65) !important;
}

.tw-outline-stone-700\/70 {
  outline-color: rgb(68 64 60 / 0.7) !important;
}

.tw-outline-stone-700\/75 {
  outline-color: rgb(68 64 60 / 0.75) !important;
}

.tw-outline-stone-700\/80 {
  outline-color: rgb(68 64 60 / 0.8) !important;
}

.tw-outline-stone-700\/85 {
  outline-color: rgb(68 64 60 / 0.85) !important;
}

.tw-outline-stone-700\/90 {
  outline-color: rgb(68 64 60 / 0.9) !important;
}

.tw-outline-stone-700\/95 {
  outline-color: rgb(68 64 60 / 0.95) !important;
}

.tw-outline-stone-800 {
  outline-color: #292524 !important;
}

.tw-outline-stone-800\/0 {
  outline-color: rgb(41 37 36 / 0) !important;
}

.tw-outline-stone-800\/10 {
  outline-color: rgb(41 37 36 / 0.1) !important;
}

.tw-outline-stone-800\/100 {
  outline-color: rgb(41 37 36 / 1) !important;
}

.tw-outline-stone-800\/15 {
  outline-color: rgb(41 37 36 / 0.15) !important;
}

.tw-outline-stone-800\/20 {
  outline-color: rgb(41 37 36 / 0.2) !important;
}

.tw-outline-stone-800\/25 {
  outline-color: rgb(41 37 36 / 0.25) !important;
}

.tw-outline-stone-800\/30 {
  outline-color: rgb(41 37 36 / 0.3) !important;
}

.tw-outline-stone-800\/35 {
  outline-color: rgb(41 37 36 / 0.35) !important;
}

.tw-outline-stone-800\/40 {
  outline-color: rgb(41 37 36 / 0.4) !important;
}

.tw-outline-stone-800\/45 {
  outline-color: rgb(41 37 36 / 0.45) !important;
}

.tw-outline-stone-800\/5 {
  outline-color: rgb(41 37 36 / 0.05) !important;
}

.tw-outline-stone-800\/50 {
  outline-color: rgb(41 37 36 / 0.5) !important;
}

.tw-outline-stone-800\/55 {
  outline-color: rgb(41 37 36 / 0.55) !important;
}

.tw-outline-stone-800\/60 {
  outline-color: rgb(41 37 36 / 0.6) !important;
}

.tw-outline-stone-800\/65 {
  outline-color: rgb(41 37 36 / 0.65) !important;
}

.tw-outline-stone-800\/70 {
  outline-color: rgb(41 37 36 / 0.7) !important;
}

.tw-outline-stone-800\/75 {
  outline-color: rgb(41 37 36 / 0.75) !important;
}

.tw-outline-stone-800\/80 {
  outline-color: rgb(41 37 36 / 0.8) !important;
}

.tw-outline-stone-800\/85 {
  outline-color: rgb(41 37 36 / 0.85) !important;
}

.tw-outline-stone-800\/90 {
  outline-color: rgb(41 37 36 / 0.9) !important;
}

.tw-outline-stone-800\/95 {
  outline-color: rgb(41 37 36 / 0.95) !important;
}

.tw-outline-stone-900 {
  outline-color: #1c1917 !important;
}

.tw-outline-stone-900\/0 {
  outline-color: rgb(28 25 23 / 0) !important;
}

.tw-outline-stone-900\/10 {
  outline-color: rgb(28 25 23 / 0.1) !important;
}

.tw-outline-stone-900\/100 {
  outline-color: rgb(28 25 23 / 1) !important;
}

.tw-outline-stone-900\/15 {
  outline-color: rgb(28 25 23 / 0.15) !important;
}

.tw-outline-stone-900\/20 {
  outline-color: rgb(28 25 23 / 0.2) !important;
}

.tw-outline-stone-900\/25 {
  outline-color: rgb(28 25 23 / 0.25) !important;
}

.tw-outline-stone-900\/30 {
  outline-color: rgb(28 25 23 / 0.3) !important;
}

.tw-outline-stone-900\/35 {
  outline-color: rgb(28 25 23 / 0.35) !important;
}

.tw-outline-stone-900\/40 {
  outline-color: rgb(28 25 23 / 0.4) !important;
}

.tw-outline-stone-900\/45 {
  outline-color: rgb(28 25 23 / 0.45) !important;
}

.tw-outline-stone-900\/5 {
  outline-color: rgb(28 25 23 / 0.05) !important;
}

.tw-outline-stone-900\/50 {
  outline-color: rgb(28 25 23 / 0.5) !important;
}

.tw-outline-stone-900\/55 {
  outline-color: rgb(28 25 23 / 0.55) !important;
}

.tw-outline-stone-900\/60 {
  outline-color: rgb(28 25 23 / 0.6) !important;
}

.tw-outline-stone-900\/65 {
  outline-color: rgb(28 25 23 / 0.65) !important;
}

.tw-outline-stone-900\/70 {
  outline-color: rgb(28 25 23 / 0.7) !important;
}

.tw-outline-stone-900\/75 {
  outline-color: rgb(28 25 23 / 0.75) !important;
}

.tw-outline-stone-900\/80 {
  outline-color: rgb(28 25 23 / 0.8) !important;
}

.tw-outline-stone-900\/85 {
  outline-color: rgb(28 25 23 / 0.85) !important;
}

.tw-outline-stone-900\/90 {
  outline-color: rgb(28 25 23 / 0.9) !important;
}

.tw-outline-stone-900\/95 {
  outline-color: rgb(28 25 23 / 0.95) !important;
}

.tw-outline-stone-950 {
  outline-color: #0c0a09 !important;
}

.tw-outline-stone-950\/0 {
  outline-color: rgb(12 10 9 / 0) !important;
}

.tw-outline-stone-950\/10 {
  outline-color: rgb(12 10 9 / 0.1) !important;
}

.tw-outline-stone-950\/100 {
  outline-color: rgb(12 10 9 / 1) !important;
}

.tw-outline-stone-950\/15 {
  outline-color: rgb(12 10 9 / 0.15) !important;
}

.tw-outline-stone-950\/20 {
  outline-color: rgb(12 10 9 / 0.2) !important;
}

.tw-outline-stone-950\/25 {
  outline-color: rgb(12 10 9 / 0.25) !important;
}

.tw-outline-stone-950\/30 {
  outline-color: rgb(12 10 9 / 0.3) !important;
}

.tw-outline-stone-950\/35 {
  outline-color: rgb(12 10 9 / 0.35) !important;
}

.tw-outline-stone-950\/40 {
  outline-color: rgb(12 10 9 / 0.4) !important;
}

.tw-outline-stone-950\/45 {
  outline-color: rgb(12 10 9 / 0.45) !important;
}

.tw-outline-stone-950\/5 {
  outline-color: rgb(12 10 9 / 0.05) !important;
}

.tw-outline-stone-950\/50 {
  outline-color: rgb(12 10 9 / 0.5) !important;
}

.tw-outline-stone-950\/55 {
  outline-color: rgb(12 10 9 / 0.55) !important;
}

.tw-outline-stone-950\/60 {
  outline-color: rgb(12 10 9 / 0.6) !important;
}

.tw-outline-stone-950\/65 {
  outline-color: rgb(12 10 9 / 0.65) !important;
}

.tw-outline-stone-950\/70 {
  outline-color: rgb(12 10 9 / 0.7) !important;
}

.tw-outline-stone-950\/75 {
  outline-color: rgb(12 10 9 / 0.75) !important;
}

.tw-outline-stone-950\/80 {
  outline-color: rgb(12 10 9 / 0.8) !important;
}

.tw-outline-stone-950\/85 {
  outline-color: rgb(12 10 9 / 0.85) !important;
}

.tw-outline-stone-950\/90 {
  outline-color: rgb(12 10 9 / 0.9) !important;
}

.tw-outline-stone-950\/95 {
  outline-color: rgb(12 10 9 / 0.95) !important;
}

.tw-outline-teal-100 {
  outline-color: #ccfbf1 !important;
}

.tw-outline-teal-100\/0 {
  outline-color: rgb(204 251 241 / 0) !important;
}

.tw-outline-teal-100\/10 {
  outline-color: rgb(204 251 241 / 0.1) !important;
}

.tw-outline-teal-100\/100 {
  outline-color: rgb(204 251 241 / 1) !important;
}

.tw-outline-teal-100\/15 {
  outline-color: rgb(204 251 241 / 0.15) !important;
}

.tw-outline-teal-100\/20 {
  outline-color: rgb(204 251 241 / 0.2) !important;
}

.tw-outline-teal-100\/25 {
  outline-color: rgb(204 251 241 / 0.25) !important;
}

.tw-outline-teal-100\/30 {
  outline-color: rgb(204 251 241 / 0.3) !important;
}

.tw-outline-teal-100\/35 {
  outline-color: rgb(204 251 241 / 0.35) !important;
}

.tw-outline-teal-100\/40 {
  outline-color: rgb(204 251 241 / 0.4) !important;
}

.tw-outline-teal-100\/45 {
  outline-color: rgb(204 251 241 / 0.45) !important;
}

.tw-outline-teal-100\/5 {
  outline-color: rgb(204 251 241 / 0.05) !important;
}

.tw-outline-teal-100\/50 {
  outline-color: rgb(204 251 241 / 0.5) !important;
}

.tw-outline-teal-100\/55 {
  outline-color: rgb(204 251 241 / 0.55) !important;
}

.tw-outline-teal-100\/60 {
  outline-color: rgb(204 251 241 / 0.6) !important;
}

.tw-outline-teal-100\/65 {
  outline-color: rgb(204 251 241 / 0.65) !important;
}

.tw-outline-teal-100\/70 {
  outline-color: rgb(204 251 241 / 0.7) !important;
}

.tw-outline-teal-100\/75 {
  outline-color: rgb(204 251 241 / 0.75) !important;
}

.tw-outline-teal-100\/80 {
  outline-color: rgb(204 251 241 / 0.8) !important;
}

.tw-outline-teal-100\/85 {
  outline-color: rgb(204 251 241 / 0.85) !important;
}

.tw-outline-teal-100\/90 {
  outline-color: rgb(204 251 241 / 0.9) !important;
}

.tw-outline-teal-100\/95 {
  outline-color: rgb(204 251 241 / 0.95) !important;
}

.tw-outline-teal-200 {
  outline-color: #99f6e4 !important;
}

.tw-outline-teal-200\/0 {
  outline-color: rgb(153 246 228 / 0) !important;
}

.tw-outline-teal-200\/10 {
  outline-color: rgb(153 246 228 / 0.1) !important;
}

.tw-outline-teal-200\/100 {
  outline-color: rgb(153 246 228 / 1) !important;
}

.tw-outline-teal-200\/15 {
  outline-color: rgb(153 246 228 / 0.15) !important;
}

.tw-outline-teal-200\/20 {
  outline-color: rgb(153 246 228 / 0.2) !important;
}

.tw-outline-teal-200\/25 {
  outline-color: rgb(153 246 228 / 0.25) !important;
}

.tw-outline-teal-200\/30 {
  outline-color: rgb(153 246 228 / 0.3) !important;
}

.tw-outline-teal-200\/35 {
  outline-color: rgb(153 246 228 / 0.35) !important;
}

.tw-outline-teal-200\/40 {
  outline-color: rgb(153 246 228 / 0.4) !important;
}

.tw-outline-teal-200\/45 {
  outline-color: rgb(153 246 228 / 0.45) !important;
}

.tw-outline-teal-200\/5 {
  outline-color: rgb(153 246 228 / 0.05) !important;
}

.tw-outline-teal-200\/50 {
  outline-color: rgb(153 246 228 / 0.5) !important;
}

.tw-outline-teal-200\/55 {
  outline-color: rgb(153 246 228 / 0.55) !important;
}

.tw-outline-teal-200\/60 {
  outline-color: rgb(153 246 228 / 0.6) !important;
}

.tw-outline-teal-200\/65 {
  outline-color: rgb(153 246 228 / 0.65) !important;
}

.tw-outline-teal-200\/70 {
  outline-color: rgb(153 246 228 / 0.7) !important;
}

.tw-outline-teal-200\/75 {
  outline-color: rgb(153 246 228 / 0.75) !important;
}

.tw-outline-teal-200\/80 {
  outline-color: rgb(153 246 228 / 0.8) !important;
}

.tw-outline-teal-200\/85 {
  outline-color: rgb(153 246 228 / 0.85) !important;
}

.tw-outline-teal-200\/90 {
  outline-color: rgb(153 246 228 / 0.9) !important;
}

.tw-outline-teal-200\/95 {
  outline-color: rgb(153 246 228 / 0.95) !important;
}

.tw-outline-teal-300 {
  outline-color: #5eead4 !important;
}

.tw-outline-teal-300\/0 {
  outline-color: rgb(94 234 212 / 0) !important;
}

.tw-outline-teal-300\/10 {
  outline-color: rgb(94 234 212 / 0.1) !important;
}

.tw-outline-teal-300\/100 {
  outline-color: rgb(94 234 212 / 1) !important;
}

.tw-outline-teal-300\/15 {
  outline-color: rgb(94 234 212 / 0.15) !important;
}

.tw-outline-teal-300\/20 {
  outline-color: rgb(94 234 212 / 0.2) !important;
}

.tw-outline-teal-300\/25 {
  outline-color: rgb(94 234 212 / 0.25) !important;
}

.tw-outline-teal-300\/30 {
  outline-color: rgb(94 234 212 / 0.3) !important;
}

.tw-outline-teal-300\/35 {
  outline-color: rgb(94 234 212 / 0.35) !important;
}

.tw-outline-teal-300\/40 {
  outline-color: rgb(94 234 212 / 0.4) !important;
}

.tw-outline-teal-300\/45 {
  outline-color: rgb(94 234 212 / 0.45) !important;
}

.tw-outline-teal-300\/5 {
  outline-color: rgb(94 234 212 / 0.05) !important;
}

.tw-outline-teal-300\/50 {
  outline-color: rgb(94 234 212 / 0.5) !important;
}

.tw-outline-teal-300\/55 {
  outline-color: rgb(94 234 212 / 0.55) !important;
}

.tw-outline-teal-300\/60 {
  outline-color: rgb(94 234 212 / 0.6) !important;
}

.tw-outline-teal-300\/65 {
  outline-color: rgb(94 234 212 / 0.65) !important;
}

.tw-outline-teal-300\/70 {
  outline-color: rgb(94 234 212 / 0.7) !important;
}

.tw-outline-teal-300\/75 {
  outline-color: rgb(94 234 212 / 0.75) !important;
}

.tw-outline-teal-300\/80 {
  outline-color: rgb(94 234 212 / 0.8) !important;
}

.tw-outline-teal-300\/85 {
  outline-color: rgb(94 234 212 / 0.85) !important;
}

.tw-outline-teal-300\/90 {
  outline-color: rgb(94 234 212 / 0.9) !important;
}

.tw-outline-teal-300\/95 {
  outline-color: rgb(94 234 212 / 0.95) !important;
}

.tw-outline-teal-400 {
  outline-color: #2dd4bf !important;
}

.tw-outline-teal-400\/0 {
  outline-color: rgb(45 212 191 / 0) !important;
}

.tw-outline-teal-400\/10 {
  outline-color: rgb(45 212 191 / 0.1) !important;
}

.tw-outline-teal-400\/100 {
  outline-color: rgb(45 212 191 / 1) !important;
}

.tw-outline-teal-400\/15 {
  outline-color: rgb(45 212 191 / 0.15) !important;
}

.tw-outline-teal-400\/20 {
  outline-color: rgb(45 212 191 / 0.2) !important;
}

.tw-outline-teal-400\/25 {
  outline-color: rgb(45 212 191 / 0.25) !important;
}

.tw-outline-teal-400\/30 {
  outline-color: rgb(45 212 191 / 0.3) !important;
}

.tw-outline-teal-400\/35 {
  outline-color: rgb(45 212 191 / 0.35) !important;
}

.tw-outline-teal-400\/40 {
  outline-color: rgb(45 212 191 / 0.4) !important;
}

.tw-outline-teal-400\/45 {
  outline-color: rgb(45 212 191 / 0.45) !important;
}

.tw-outline-teal-400\/5 {
  outline-color: rgb(45 212 191 / 0.05) !important;
}

.tw-outline-teal-400\/50 {
  outline-color: rgb(45 212 191 / 0.5) !important;
}

.tw-outline-teal-400\/55 {
  outline-color: rgb(45 212 191 / 0.55) !important;
}

.tw-outline-teal-400\/60 {
  outline-color: rgb(45 212 191 / 0.6) !important;
}

.tw-outline-teal-400\/65 {
  outline-color: rgb(45 212 191 / 0.65) !important;
}

.tw-outline-teal-400\/70 {
  outline-color: rgb(45 212 191 / 0.7) !important;
}

.tw-outline-teal-400\/75 {
  outline-color: rgb(45 212 191 / 0.75) !important;
}

.tw-outline-teal-400\/80 {
  outline-color: rgb(45 212 191 / 0.8) !important;
}

.tw-outline-teal-400\/85 {
  outline-color: rgb(45 212 191 / 0.85) !important;
}

.tw-outline-teal-400\/90 {
  outline-color: rgb(45 212 191 / 0.9) !important;
}

.tw-outline-teal-400\/95 {
  outline-color: rgb(45 212 191 / 0.95) !important;
}

.tw-outline-teal-50 {
  outline-color: #f0fdfa !important;
}

.tw-outline-teal-50\/0 {
  outline-color: rgb(240 253 250 / 0) !important;
}

.tw-outline-teal-50\/10 {
  outline-color: rgb(240 253 250 / 0.1) !important;
}

.tw-outline-teal-50\/100 {
  outline-color: rgb(240 253 250 / 1) !important;
}

.tw-outline-teal-50\/15 {
  outline-color: rgb(240 253 250 / 0.15) !important;
}

.tw-outline-teal-50\/20 {
  outline-color: rgb(240 253 250 / 0.2) !important;
}

.tw-outline-teal-50\/25 {
  outline-color: rgb(240 253 250 / 0.25) !important;
}

.tw-outline-teal-50\/30 {
  outline-color: rgb(240 253 250 / 0.3) !important;
}

.tw-outline-teal-50\/35 {
  outline-color: rgb(240 253 250 / 0.35) !important;
}

.tw-outline-teal-50\/40 {
  outline-color: rgb(240 253 250 / 0.4) !important;
}

.tw-outline-teal-50\/45 {
  outline-color: rgb(240 253 250 / 0.45) !important;
}

.tw-outline-teal-50\/5 {
  outline-color: rgb(240 253 250 / 0.05) !important;
}

.tw-outline-teal-50\/50 {
  outline-color: rgb(240 253 250 / 0.5) !important;
}

.tw-outline-teal-50\/55 {
  outline-color: rgb(240 253 250 / 0.55) !important;
}

.tw-outline-teal-50\/60 {
  outline-color: rgb(240 253 250 / 0.6) !important;
}

.tw-outline-teal-50\/65 {
  outline-color: rgb(240 253 250 / 0.65) !important;
}

.tw-outline-teal-50\/70 {
  outline-color: rgb(240 253 250 / 0.7) !important;
}

.tw-outline-teal-50\/75 {
  outline-color: rgb(240 253 250 / 0.75) !important;
}

.tw-outline-teal-50\/80 {
  outline-color: rgb(240 253 250 / 0.8) !important;
}

.tw-outline-teal-50\/85 {
  outline-color: rgb(240 253 250 / 0.85) !important;
}

.tw-outline-teal-50\/90 {
  outline-color: rgb(240 253 250 / 0.9) !important;
}

.tw-outline-teal-50\/95 {
  outline-color: rgb(240 253 250 / 0.95) !important;
}

.tw-outline-teal-500 {
  outline-color: #14b8a6 !important;
}

.tw-outline-teal-500\/0 {
  outline-color: rgb(20 184 166 / 0) !important;
}

.tw-outline-teal-500\/10 {
  outline-color: rgb(20 184 166 / 0.1) !important;
}

.tw-outline-teal-500\/100 {
  outline-color: rgb(20 184 166 / 1) !important;
}

.tw-outline-teal-500\/15 {
  outline-color: rgb(20 184 166 / 0.15) !important;
}

.tw-outline-teal-500\/20 {
  outline-color: rgb(20 184 166 / 0.2) !important;
}

.tw-outline-teal-500\/25 {
  outline-color: rgb(20 184 166 / 0.25) !important;
}

.tw-outline-teal-500\/30 {
  outline-color: rgb(20 184 166 / 0.3) !important;
}

.tw-outline-teal-500\/35 {
  outline-color: rgb(20 184 166 / 0.35) !important;
}

.tw-outline-teal-500\/40 {
  outline-color: rgb(20 184 166 / 0.4) !important;
}

.tw-outline-teal-500\/45 {
  outline-color: rgb(20 184 166 / 0.45) !important;
}

.tw-outline-teal-500\/5 {
  outline-color: rgb(20 184 166 / 0.05) !important;
}

.tw-outline-teal-500\/50 {
  outline-color: rgb(20 184 166 / 0.5) !important;
}

.tw-outline-teal-500\/55 {
  outline-color: rgb(20 184 166 / 0.55) !important;
}

.tw-outline-teal-500\/60 {
  outline-color: rgb(20 184 166 / 0.6) !important;
}

.tw-outline-teal-500\/65 {
  outline-color: rgb(20 184 166 / 0.65) !important;
}

.tw-outline-teal-500\/70 {
  outline-color: rgb(20 184 166 / 0.7) !important;
}

.tw-outline-teal-500\/75 {
  outline-color: rgb(20 184 166 / 0.75) !important;
}

.tw-outline-teal-500\/80 {
  outline-color: rgb(20 184 166 / 0.8) !important;
}

.tw-outline-teal-500\/85 {
  outline-color: rgb(20 184 166 / 0.85) !important;
}

.tw-outline-teal-500\/90 {
  outline-color: rgb(20 184 166 / 0.9) !important;
}

.tw-outline-teal-500\/95 {
  outline-color: rgb(20 184 166 / 0.95) !important;
}

.tw-outline-teal-600 {
  outline-color: #0d9488 !important;
}

.tw-outline-teal-600\/0 {
  outline-color: rgb(13 148 136 / 0) !important;
}

.tw-outline-teal-600\/10 {
  outline-color: rgb(13 148 136 / 0.1) !important;
}

.tw-outline-teal-600\/100 {
  outline-color: rgb(13 148 136 / 1) !important;
}

.tw-outline-teal-600\/15 {
  outline-color: rgb(13 148 136 / 0.15) !important;
}

.tw-outline-teal-600\/20 {
  outline-color: rgb(13 148 136 / 0.2) !important;
}

.tw-outline-teal-600\/25 {
  outline-color: rgb(13 148 136 / 0.25) !important;
}

.tw-outline-teal-600\/30 {
  outline-color: rgb(13 148 136 / 0.3) !important;
}

.tw-outline-teal-600\/35 {
  outline-color: rgb(13 148 136 / 0.35) !important;
}

.tw-outline-teal-600\/40 {
  outline-color: rgb(13 148 136 / 0.4) !important;
}

.tw-outline-teal-600\/45 {
  outline-color: rgb(13 148 136 / 0.45) !important;
}

.tw-outline-teal-600\/5 {
  outline-color: rgb(13 148 136 / 0.05) !important;
}

.tw-outline-teal-600\/50 {
  outline-color: rgb(13 148 136 / 0.5) !important;
}

.tw-outline-teal-600\/55 {
  outline-color: rgb(13 148 136 / 0.55) !important;
}

.tw-outline-teal-600\/60 {
  outline-color: rgb(13 148 136 / 0.6) !important;
}

.tw-outline-teal-600\/65 {
  outline-color: rgb(13 148 136 / 0.65) !important;
}

.tw-outline-teal-600\/70 {
  outline-color: rgb(13 148 136 / 0.7) !important;
}

.tw-outline-teal-600\/75 {
  outline-color: rgb(13 148 136 / 0.75) !important;
}

.tw-outline-teal-600\/80 {
  outline-color: rgb(13 148 136 / 0.8) !important;
}

.tw-outline-teal-600\/85 {
  outline-color: rgb(13 148 136 / 0.85) !important;
}

.tw-outline-teal-600\/90 {
  outline-color: rgb(13 148 136 / 0.9) !important;
}

.tw-outline-teal-600\/95 {
  outline-color: rgb(13 148 136 / 0.95) !important;
}

.tw-outline-teal-700 {
  outline-color: #0f766e !important;
}

.tw-outline-teal-700\/0 {
  outline-color: rgb(15 118 110 / 0) !important;
}

.tw-outline-teal-700\/10 {
  outline-color: rgb(15 118 110 / 0.1) !important;
}

.tw-outline-teal-700\/100 {
  outline-color: rgb(15 118 110 / 1) !important;
}

.tw-outline-teal-700\/15 {
  outline-color: rgb(15 118 110 / 0.15) !important;
}

.tw-outline-teal-700\/20 {
  outline-color: rgb(15 118 110 / 0.2) !important;
}

.tw-outline-teal-700\/25 {
  outline-color: rgb(15 118 110 / 0.25) !important;
}

.tw-outline-teal-700\/30 {
  outline-color: rgb(15 118 110 / 0.3) !important;
}

.tw-outline-teal-700\/35 {
  outline-color: rgb(15 118 110 / 0.35) !important;
}

.tw-outline-teal-700\/40 {
  outline-color: rgb(15 118 110 / 0.4) !important;
}

.tw-outline-teal-700\/45 {
  outline-color: rgb(15 118 110 / 0.45) !important;
}

.tw-outline-teal-700\/5 {
  outline-color: rgb(15 118 110 / 0.05) !important;
}

.tw-outline-teal-700\/50 {
  outline-color: rgb(15 118 110 / 0.5) !important;
}

.tw-outline-teal-700\/55 {
  outline-color: rgb(15 118 110 / 0.55) !important;
}

.tw-outline-teal-700\/60 {
  outline-color: rgb(15 118 110 / 0.6) !important;
}

.tw-outline-teal-700\/65 {
  outline-color: rgb(15 118 110 / 0.65) !important;
}

.tw-outline-teal-700\/70 {
  outline-color: rgb(15 118 110 / 0.7) !important;
}

.tw-outline-teal-700\/75 {
  outline-color: rgb(15 118 110 / 0.75) !important;
}

.tw-outline-teal-700\/80 {
  outline-color: rgb(15 118 110 / 0.8) !important;
}

.tw-outline-teal-700\/85 {
  outline-color: rgb(15 118 110 / 0.85) !important;
}

.tw-outline-teal-700\/90 {
  outline-color: rgb(15 118 110 / 0.9) !important;
}

.tw-outline-teal-700\/95 {
  outline-color: rgb(15 118 110 / 0.95) !important;
}

.tw-outline-teal-800 {
  outline-color: #115e59 !important;
}

.tw-outline-teal-800\/0 {
  outline-color: rgb(17 94 89 / 0) !important;
}

.tw-outline-teal-800\/10 {
  outline-color: rgb(17 94 89 / 0.1) !important;
}

.tw-outline-teal-800\/100 {
  outline-color: rgb(17 94 89 / 1) !important;
}

.tw-outline-teal-800\/15 {
  outline-color: rgb(17 94 89 / 0.15) !important;
}

.tw-outline-teal-800\/20 {
  outline-color: rgb(17 94 89 / 0.2) !important;
}

.tw-outline-teal-800\/25 {
  outline-color: rgb(17 94 89 / 0.25) !important;
}

.tw-outline-teal-800\/30 {
  outline-color: rgb(17 94 89 / 0.3) !important;
}

.tw-outline-teal-800\/35 {
  outline-color: rgb(17 94 89 / 0.35) !important;
}

.tw-outline-teal-800\/40 {
  outline-color: rgb(17 94 89 / 0.4) !important;
}

.tw-outline-teal-800\/45 {
  outline-color: rgb(17 94 89 / 0.45) !important;
}

.tw-outline-teal-800\/5 {
  outline-color: rgb(17 94 89 / 0.05) !important;
}

.tw-outline-teal-800\/50 {
  outline-color: rgb(17 94 89 / 0.5) !important;
}

.tw-outline-teal-800\/55 {
  outline-color: rgb(17 94 89 / 0.55) !important;
}

.tw-outline-teal-800\/60 {
  outline-color: rgb(17 94 89 / 0.6) !important;
}

.tw-outline-teal-800\/65 {
  outline-color: rgb(17 94 89 / 0.65) !important;
}

.tw-outline-teal-800\/70 {
  outline-color: rgb(17 94 89 / 0.7) !important;
}

.tw-outline-teal-800\/75 {
  outline-color: rgb(17 94 89 / 0.75) !important;
}

.tw-outline-teal-800\/80 {
  outline-color: rgb(17 94 89 / 0.8) !important;
}

.tw-outline-teal-800\/85 {
  outline-color: rgb(17 94 89 / 0.85) !important;
}

.tw-outline-teal-800\/90 {
  outline-color: rgb(17 94 89 / 0.9) !important;
}

.tw-outline-teal-800\/95 {
  outline-color: rgb(17 94 89 / 0.95) !important;
}

.tw-outline-teal-900 {
  outline-color: #134e4a !important;
}

.tw-outline-teal-900\/0 {
  outline-color: rgb(19 78 74 / 0) !important;
}

.tw-outline-teal-900\/10 {
  outline-color: rgb(19 78 74 / 0.1) !important;
}

.tw-outline-teal-900\/100 {
  outline-color: rgb(19 78 74 / 1) !important;
}

.tw-outline-teal-900\/15 {
  outline-color: rgb(19 78 74 / 0.15) !important;
}

.tw-outline-teal-900\/20 {
  outline-color: rgb(19 78 74 / 0.2) !important;
}

.tw-outline-teal-900\/25 {
  outline-color: rgb(19 78 74 / 0.25) !important;
}

.tw-outline-teal-900\/30 {
  outline-color: rgb(19 78 74 / 0.3) !important;
}

.tw-outline-teal-900\/35 {
  outline-color: rgb(19 78 74 / 0.35) !important;
}

.tw-outline-teal-900\/40 {
  outline-color: rgb(19 78 74 / 0.4) !important;
}

.tw-outline-teal-900\/45 {
  outline-color: rgb(19 78 74 / 0.45) !important;
}

.tw-outline-teal-900\/5 {
  outline-color: rgb(19 78 74 / 0.05) !important;
}

.tw-outline-teal-900\/50 {
  outline-color: rgb(19 78 74 / 0.5) !important;
}

.tw-outline-teal-900\/55 {
  outline-color: rgb(19 78 74 / 0.55) !important;
}

.tw-outline-teal-900\/60 {
  outline-color: rgb(19 78 74 / 0.6) !important;
}

.tw-outline-teal-900\/65 {
  outline-color: rgb(19 78 74 / 0.65) !important;
}

.tw-outline-teal-900\/70 {
  outline-color: rgb(19 78 74 / 0.7) !important;
}

.tw-outline-teal-900\/75 {
  outline-color: rgb(19 78 74 / 0.75) !important;
}

.tw-outline-teal-900\/80 {
  outline-color: rgb(19 78 74 / 0.8) !important;
}

.tw-outline-teal-900\/85 {
  outline-color: rgb(19 78 74 / 0.85) !important;
}

.tw-outline-teal-900\/90 {
  outline-color: rgb(19 78 74 / 0.9) !important;
}

.tw-outline-teal-900\/95 {
  outline-color: rgb(19 78 74 / 0.95) !important;
}

.tw-outline-teal-950 {
  outline-color: #042f2e !important;
}

.tw-outline-teal-950\/0 {
  outline-color: rgb(4 47 46 / 0) !important;
}

.tw-outline-teal-950\/10 {
  outline-color: rgb(4 47 46 / 0.1) !important;
}

.tw-outline-teal-950\/100 {
  outline-color: rgb(4 47 46 / 1) !important;
}

.tw-outline-teal-950\/15 {
  outline-color: rgb(4 47 46 / 0.15) !important;
}

.tw-outline-teal-950\/20 {
  outline-color: rgb(4 47 46 / 0.2) !important;
}

.tw-outline-teal-950\/25 {
  outline-color: rgb(4 47 46 / 0.25) !important;
}

.tw-outline-teal-950\/30 {
  outline-color: rgb(4 47 46 / 0.3) !important;
}

.tw-outline-teal-950\/35 {
  outline-color: rgb(4 47 46 / 0.35) !important;
}

.tw-outline-teal-950\/40 {
  outline-color: rgb(4 47 46 / 0.4) !important;
}

.tw-outline-teal-950\/45 {
  outline-color: rgb(4 47 46 / 0.45) !important;
}

.tw-outline-teal-950\/5 {
  outline-color: rgb(4 47 46 / 0.05) !important;
}

.tw-outline-teal-950\/50 {
  outline-color: rgb(4 47 46 / 0.5) !important;
}

.tw-outline-teal-950\/55 {
  outline-color: rgb(4 47 46 / 0.55) !important;
}

.tw-outline-teal-950\/60 {
  outline-color: rgb(4 47 46 / 0.6) !important;
}

.tw-outline-teal-950\/65 {
  outline-color: rgb(4 47 46 / 0.65) !important;
}

.tw-outline-teal-950\/70 {
  outline-color: rgb(4 47 46 / 0.7) !important;
}

.tw-outline-teal-950\/75 {
  outline-color: rgb(4 47 46 / 0.75) !important;
}

.tw-outline-teal-950\/80 {
  outline-color: rgb(4 47 46 / 0.8) !important;
}

.tw-outline-teal-950\/85 {
  outline-color: rgb(4 47 46 / 0.85) !important;
}

.tw-outline-teal-950\/90 {
  outline-color: rgb(4 47 46 / 0.9) !important;
}

.tw-outline-teal-950\/95 {
  outline-color: rgb(4 47 46 / 0.95) !important;
}

.tw-outline-transparent {
  outline-color: transparent !important;
}

.tw-outline-transparent\/0 {
  outline-color: rgb(0 0 0 / 0) !important;
}

.tw-outline-transparent\/10 {
  outline-color: rgb(0 0 0 / 0.1) !important;
}

.tw-outline-transparent\/100 {
  outline-color: rgb(0 0 0 / 1) !important;
}

.tw-outline-transparent\/15 {
  outline-color: rgb(0 0 0 / 0.15) !important;
}

.tw-outline-transparent\/20 {
  outline-color: rgb(0 0 0 / 0.2) !important;
}

.tw-outline-transparent\/25 {
  outline-color: rgb(0 0 0 / 0.25) !important;
}

.tw-outline-transparent\/30 {
  outline-color: rgb(0 0 0 / 0.3) !important;
}

.tw-outline-transparent\/35 {
  outline-color: rgb(0 0 0 / 0.35) !important;
}

.tw-outline-transparent\/40 {
  outline-color: rgb(0 0 0 / 0.4) !important;
}

.tw-outline-transparent\/45 {
  outline-color: rgb(0 0 0 / 0.45) !important;
}

.tw-outline-transparent\/5 {
  outline-color: rgb(0 0 0 / 0.05) !important;
}

.tw-outline-transparent\/50 {
  outline-color: rgb(0 0 0 / 0.5) !important;
}

.tw-outline-transparent\/55 {
  outline-color: rgb(0 0 0 / 0.55) !important;
}

.tw-outline-transparent\/60 {
  outline-color: rgb(0 0 0 / 0.6) !important;
}

.tw-outline-transparent\/65 {
  outline-color: rgb(0 0 0 / 0.65) !important;
}

.tw-outline-transparent\/70 {
  outline-color: rgb(0 0 0 / 0.7) !important;
}

.tw-outline-transparent\/75 {
  outline-color: rgb(0 0 0 / 0.75) !important;
}

.tw-outline-transparent\/80 {
  outline-color: rgb(0 0 0 / 0.8) !important;
}

.tw-outline-transparent\/85 {
  outline-color: rgb(0 0 0 / 0.85) !important;
}

.tw-outline-transparent\/90 {
  outline-color: rgb(0 0 0 / 0.9) !important;
}

.tw-outline-transparent\/95 {
  outline-color: rgb(0 0 0 / 0.95) !important;
}

.tw-outline-violet-100 {
  outline-color: #ede9fe !important;
}

.tw-outline-violet-100\/0 {
  outline-color: rgb(237 233 254 / 0) !important;
}

.tw-outline-violet-100\/10 {
  outline-color: rgb(237 233 254 / 0.1) !important;
}

.tw-outline-violet-100\/100 {
  outline-color: rgb(237 233 254 / 1) !important;
}

.tw-outline-violet-100\/15 {
  outline-color: rgb(237 233 254 / 0.15) !important;
}

.tw-outline-violet-100\/20 {
  outline-color: rgb(237 233 254 / 0.2) !important;
}

.tw-outline-violet-100\/25 {
  outline-color: rgb(237 233 254 / 0.25) !important;
}

.tw-outline-violet-100\/30 {
  outline-color: rgb(237 233 254 / 0.3) !important;
}

.tw-outline-violet-100\/35 {
  outline-color: rgb(237 233 254 / 0.35) !important;
}

.tw-outline-violet-100\/40 {
  outline-color: rgb(237 233 254 / 0.4) !important;
}

.tw-outline-violet-100\/45 {
  outline-color: rgb(237 233 254 / 0.45) !important;
}

.tw-outline-violet-100\/5 {
  outline-color: rgb(237 233 254 / 0.05) !important;
}

.tw-outline-violet-100\/50 {
  outline-color: rgb(237 233 254 / 0.5) !important;
}

.tw-outline-violet-100\/55 {
  outline-color: rgb(237 233 254 / 0.55) !important;
}

.tw-outline-violet-100\/60 {
  outline-color: rgb(237 233 254 / 0.6) !important;
}

.tw-outline-violet-100\/65 {
  outline-color: rgb(237 233 254 / 0.65) !important;
}

.tw-outline-violet-100\/70 {
  outline-color: rgb(237 233 254 / 0.7) !important;
}

.tw-outline-violet-100\/75 {
  outline-color: rgb(237 233 254 / 0.75) !important;
}

.tw-outline-violet-100\/80 {
  outline-color: rgb(237 233 254 / 0.8) !important;
}

.tw-outline-violet-100\/85 {
  outline-color: rgb(237 233 254 / 0.85) !important;
}

.tw-outline-violet-100\/90 {
  outline-color: rgb(237 233 254 / 0.9) !important;
}

.tw-outline-violet-100\/95 {
  outline-color: rgb(237 233 254 / 0.95) !important;
}

.tw-outline-violet-200 {
  outline-color: #ddd6fe !important;
}

.tw-outline-violet-200\/0 {
  outline-color: rgb(221 214 254 / 0) !important;
}

.tw-outline-violet-200\/10 {
  outline-color: rgb(221 214 254 / 0.1) !important;
}

.tw-outline-violet-200\/100 {
  outline-color: rgb(221 214 254 / 1) !important;
}

.tw-outline-violet-200\/15 {
  outline-color: rgb(221 214 254 / 0.15) !important;
}

.tw-outline-violet-200\/20 {
  outline-color: rgb(221 214 254 / 0.2) !important;
}

.tw-outline-violet-200\/25 {
  outline-color: rgb(221 214 254 / 0.25) !important;
}

.tw-outline-violet-200\/30 {
  outline-color: rgb(221 214 254 / 0.3) !important;
}

.tw-outline-violet-200\/35 {
  outline-color: rgb(221 214 254 / 0.35) !important;
}

.tw-outline-violet-200\/40 {
  outline-color: rgb(221 214 254 / 0.4) !important;
}

.tw-outline-violet-200\/45 {
  outline-color: rgb(221 214 254 / 0.45) !important;
}

.tw-outline-violet-200\/5 {
  outline-color: rgb(221 214 254 / 0.05) !important;
}

.tw-outline-violet-200\/50 {
  outline-color: rgb(221 214 254 / 0.5) !important;
}

.tw-outline-violet-200\/55 {
  outline-color: rgb(221 214 254 / 0.55) !important;
}

.tw-outline-violet-200\/60 {
  outline-color: rgb(221 214 254 / 0.6) !important;
}

.tw-outline-violet-200\/65 {
  outline-color: rgb(221 214 254 / 0.65) !important;
}

.tw-outline-violet-200\/70 {
  outline-color: rgb(221 214 254 / 0.7) !important;
}

.tw-outline-violet-200\/75 {
  outline-color: rgb(221 214 254 / 0.75) !important;
}

.tw-outline-violet-200\/80 {
  outline-color: rgb(221 214 254 / 0.8) !important;
}

.tw-outline-violet-200\/85 {
  outline-color: rgb(221 214 254 / 0.85) !important;
}

.tw-outline-violet-200\/90 {
  outline-color: rgb(221 214 254 / 0.9) !important;
}

.tw-outline-violet-200\/95 {
  outline-color: rgb(221 214 254 / 0.95) !important;
}

.tw-outline-violet-300 {
  outline-color: #c4b5fd !important;
}

.tw-outline-violet-300\/0 {
  outline-color: rgb(196 181 253 / 0) !important;
}

.tw-outline-violet-300\/10 {
  outline-color: rgb(196 181 253 / 0.1) !important;
}

.tw-outline-violet-300\/100 {
  outline-color: rgb(196 181 253 / 1) !important;
}

.tw-outline-violet-300\/15 {
  outline-color: rgb(196 181 253 / 0.15) !important;
}

.tw-outline-violet-300\/20 {
  outline-color: rgb(196 181 253 / 0.2) !important;
}

.tw-outline-violet-300\/25 {
  outline-color: rgb(196 181 253 / 0.25) !important;
}

.tw-outline-violet-300\/30 {
  outline-color: rgb(196 181 253 / 0.3) !important;
}

.tw-outline-violet-300\/35 {
  outline-color: rgb(196 181 253 / 0.35) !important;
}

.tw-outline-violet-300\/40 {
  outline-color: rgb(196 181 253 / 0.4) !important;
}

.tw-outline-violet-300\/45 {
  outline-color: rgb(196 181 253 / 0.45) !important;
}

.tw-outline-violet-300\/5 {
  outline-color: rgb(196 181 253 / 0.05) !important;
}

.tw-outline-violet-300\/50 {
  outline-color: rgb(196 181 253 / 0.5) !important;
}

.tw-outline-violet-300\/55 {
  outline-color: rgb(196 181 253 / 0.55) !important;
}

.tw-outline-violet-300\/60 {
  outline-color: rgb(196 181 253 / 0.6) !important;
}

.tw-outline-violet-300\/65 {
  outline-color: rgb(196 181 253 / 0.65) !important;
}

.tw-outline-violet-300\/70 {
  outline-color: rgb(196 181 253 / 0.7) !important;
}

.tw-outline-violet-300\/75 {
  outline-color: rgb(196 181 253 / 0.75) !important;
}

.tw-outline-violet-300\/80 {
  outline-color: rgb(196 181 253 / 0.8) !important;
}

.tw-outline-violet-300\/85 {
  outline-color: rgb(196 181 253 / 0.85) !important;
}

.tw-outline-violet-300\/90 {
  outline-color: rgb(196 181 253 / 0.9) !important;
}

.tw-outline-violet-300\/95 {
  outline-color: rgb(196 181 253 / 0.95) !important;
}

.tw-outline-violet-400 {
  outline-color: #a78bfa !important;
}

.tw-outline-violet-400\/0 {
  outline-color: rgb(167 139 250 / 0) !important;
}

.tw-outline-violet-400\/10 {
  outline-color: rgb(167 139 250 / 0.1) !important;
}

.tw-outline-violet-400\/100 {
  outline-color: rgb(167 139 250 / 1) !important;
}

.tw-outline-violet-400\/15 {
  outline-color: rgb(167 139 250 / 0.15) !important;
}

.tw-outline-violet-400\/20 {
  outline-color: rgb(167 139 250 / 0.2) !important;
}

.tw-outline-violet-400\/25 {
  outline-color: rgb(167 139 250 / 0.25) !important;
}

.tw-outline-violet-400\/30 {
  outline-color: rgb(167 139 250 / 0.3) !important;
}

.tw-outline-violet-400\/35 {
  outline-color: rgb(167 139 250 / 0.35) !important;
}

.tw-outline-violet-400\/40 {
  outline-color: rgb(167 139 250 / 0.4) !important;
}

.tw-outline-violet-400\/45 {
  outline-color: rgb(167 139 250 / 0.45) !important;
}

.tw-outline-violet-400\/5 {
  outline-color: rgb(167 139 250 / 0.05) !important;
}

.tw-outline-violet-400\/50 {
  outline-color: rgb(167 139 250 / 0.5) !important;
}

.tw-outline-violet-400\/55 {
  outline-color: rgb(167 139 250 / 0.55) !important;
}

.tw-outline-violet-400\/60 {
  outline-color: rgb(167 139 250 / 0.6) !important;
}

.tw-outline-violet-400\/65 {
  outline-color: rgb(167 139 250 / 0.65) !important;
}

.tw-outline-violet-400\/70 {
  outline-color: rgb(167 139 250 / 0.7) !important;
}

.tw-outline-violet-400\/75 {
  outline-color: rgb(167 139 250 / 0.75) !important;
}

.tw-outline-violet-400\/80 {
  outline-color: rgb(167 139 250 / 0.8) !important;
}

.tw-outline-violet-400\/85 {
  outline-color: rgb(167 139 250 / 0.85) !important;
}

.tw-outline-violet-400\/90 {
  outline-color: rgb(167 139 250 / 0.9) !important;
}

.tw-outline-violet-400\/95 {
  outline-color: rgb(167 139 250 / 0.95) !important;
}

.tw-outline-violet-50 {
  outline-color: #f5f3ff !important;
}

.tw-outline-violet-50\/0 {
  outline-color: rgb(245 243 255 / 0) !important;
}

.tw-outline-violet-50\/10 {
  outline-color: rgb(245 243 255 / 0.1) !important;
}

.tw-outline-violet-50\/100 {
  outline-color: rgb(245 243 255 / 1) !important;
}

.tw-outline-violet-50\/15 {
  outline-color: rgb(245 243 255 / 0.15) !important;
}

.tw-outline-violet-50\/20 {
  outline-color: rgb(245 243 255 / 0.2) !important;
}

.tw-outline-violet-50\/25 {
  outline-color: rgb(245 243 255 / 0.25) !important;
}

.tw-outline-violet-50\/30 {
  outline-color: rgb(245 243 255 / 0.3) !important;
}

.tw-outline-violet-50\/35 {
  outline-color: rgb(245 243 255 / 0.35) !important;
}

.tw-outline-violet-50\/40 {
  outline-color: rgb(245 243 255 / 0.4) !important;
}

.tw-outline-violet-50\/45 {
  outline-color: rgb(245 243 255 / 0.45) !important;
}

.tw-outline-violet-50\/5 {
  outline-color: rgb(245 243 255 / 0.05) !important;
}

.tw-outline-violet-50\/50 {
  outline-color: rgb(245 243 255 / 0.5) !important;
}

.tw-outline-violet-50\/55 {
  outline-color: rgb(245 243 255 / 0.55) !important;
}

.tw-outline-violet-50\/60 {
  outline-color: rgb(245 243 255 / 0.6) !important;
}

.tw-outline-violet-50\/65 {
  outline-color: rgb(245 243 255 / 0.65) !important;
}

.tw-outline-violet-50\/70 {
  outline-color: rgb(245 243 255 / 0.7) !important;
}

.tw-outline-violet-50\/75 {
  outline-color: rgb(245 243 255 / 0.75) !important;
}

.tw-outline-violet-50\/80 {
  outline-color: rgb(245 243 255 / 0.8) !important;
}

.tw-outline-violet-50\/85 {
  outline-color: rgb(245 243 255 / 0.85) !important;
}

.tw-outline-violet-50\/90 {
  outline-color: rgb(245 243 255 / 0.9) !important;
}

.tw-outline-violet-50\/95 {
  outline-color: rgb(245 243 255 / 0.95) !important;
}

.tw-outline-violet-500 {
  outline-color: #8b5cf6 !important;
}

.tw-outline-violet-500\/0 {
  outline-color: rgb(139 92 246 / 0) !important;
}

.tw-outline-violet-500\/10 {
  outline-color: rgb(139 92 246 / 0.1) !important;
}

.tw-outline-violet-500\/100 {
  outline-color: rgb(139 92 246 / 1) !important;
}

.tw-outline-violet-500\/15 {
  outline-color: rgb(139 92 246 / 0.15) !important;
}

.tw-outline-violet-500\/20 {
  outline-color: rgb(139 92 246 / 0.2) !important;
}

.tw-outline-violet-500\/25 {
  outline-color: rgb(139 92 246 / 0.25) !important;
}

.tw-outline-violet-500\/30 {
  outline-color: rgb(139 92 246 / 0.3) !important;
}

.tw-outline-violet-500\/35 {
  outline-color: rgb(139 92 246 / 0.35) !important;
}

.tw-outline-violet-500\/40 {
  outline-color: rgb(139 92 246 / 0.4) !important;
}

.tw-outline-violet-500\/45 {
  outline-color: rgb(139 92 246 / 0.45) !important;
}

.tw-outline-violet-500\/5 {
  outline-color: rgb(139 92 246 / 0.05) !important;
}

.tw-outline-violet-500\/50 {
  outline-color: rgb(139 92 246 / 0.5) !important;
}

.tw-outline-violet-500\/55 {
  outline-color: rgb(139 92 246 / 0.55) !important;
}

.tw-outline-violet-500\/60 {
  outline-color: rgb(139 92 246 / 0.6) !important;
}

.tw-outline-violet-500\/65 {
  outline-color: rgb(139 92 246 / 0.65) !important;
}

.tw-outline-violet-500\/70 {
  outline-color: rgb(139 92 246 / 0.7) !important;
}

.tw-outline-violet-500\/75 {
  outline-color: rgb(139 92 246 / 0.75) !important;
}

.tw-outline-violet-500\/80 {
  outline-color: rgb(139 92 246 / 0.8) !important;
}

.tw-outline-violet-500\/85 {
  outline-color: rgb(139 92 246 / 0.85) !important;
}

.tw-outline-violet-500\/90 {
  outline-color: rgb(139 92 246 / 0.9) !important;
}

.tw-outline-violet-500\/95 {
  outline-color: rgb(139 92 246 / 0.95) !important;
}

.tw-outline-violet-600 {
  outline-color: #7c3aed !important;
}

.tw-outline-violet-600\/0 {
  outline-color: rgb(124 58 237 / 0) !important;
}

.tw-outline-violet-600\/10 {
  outline-color: rgb(124 58 237 / 0.1) !important;
}

.tw-outline-violet-600\/100 {
  outline-color: rgb(124 58 237 / 1) !important;
}

.tw-outline-violet-600\/15 {
  outline-color: rgb(124 58 237 / 0.15) !important;
}

.tw-outline-violet-600\/20 {
  outline-color: rgb(124 58 237 / 0.2) !important;
}

.tw-outline-violet-600\/25 {
  outline-color: rgb(124 58 237 / 0.25) !important;
}

.tw-outline-violet-600\/30 {
  outline-color: rgb(124 58 237 / 0.3) !important;
}

.tw-outline-violet-600\/35 {
  outline-color: rgb(124 58 237 / 0.35) !important;
}

.tw-outline-violet-600\/40 {
  outline-color: rgb(124 58 237 / 0.4) !important;
}

.tw-outline-violet-600\/45 {
  outline-color: rgb(124 58 237 / 0.45) !important;
}

.tw-outline-violet-600\/5 {
  outline-color: rgb(124 58 237 / 0.05) !important;
}

.tw-outline-violet-600\/50 {
  outline-color: rgb(124 58 237 / 0.5) !important;
}

.tw-outline-violet-600\/55 {
  outline-color: rgb(124 58 237 / 0.55) !important;
}

.tw-outline-violet-600\/60 {
  outline-color: rgb(124 58 237 / 0.6) !important;
}

.tw-outline-violet-600\/65 {
  outline-color: rgb(124 58 237 / 0.65) !important;
}

.tw-outline-violet-600\/70 {
  outline-color: rgb(124 58 237 / 0.7) !important;
}

.tw-outline-violet-600\/75 {
  outline-color: rgb(124 58 237 / 0.75) !important;
}

.tw-outline-violet-600\/80 {
  outline-color: rgb(124 58 237 / 0.8) !important;
}

.tw-outline-violet-600\/85 {
  outline-color: rgb(124 58 237 / 0.85) !important;
}

.tw-outline-violet-600\/90 {
  outline-color: rgb(124 58 237 / 0.9) !important;
}

.tw-outline-violet-600\/95 {
  outline-color: rgb(124 58 237 / 0.95) !important;
}

.tw-outline-violet-700 {
  outline-color: #6d28d9 !important;
}

.tw-outline-violet-700\/0 {
  outline-color: rgb(109 40 217 / 0) !important;
}

.tw-outline-violet-700\/10 {
  outline-color: rgb(109 40 217 / 0.1) !important;
}

.tw-outline-violet-700\/100 {
  outline-color: rgb(109 40 217 / 1) !important;
}

.tw-outline-violet-700\/15 {
  outline-color: rgb(109 40 217 / 0.15) !important;
}

.tw-outline-violet-700\/20 {
  outline-color: rgb(109 40 217 / 0.2) !important;
}

.tw-outline-violet-700\/25 {
  outline-color: rgb(109 40 217 / 0.25) !important;
}

.tw-outline-violet-700\/30 {
  outline-color: rgb(109 40 217 / 0.3) !important;
}

.tw-outline-violet-700\/35 {
  outline-color: rgb(109 40 217 / 0.35) !important;
}

.tw-outline-violet-700\/40 {
  outline-color: rgb(109 40 217 / 0.4) !important;
}

.tw-outline-violet-700\/45 {
  outline-color: rgb(109 40 217 / 0.45) !important;
}

.tw-outline-violet-700\/5 {
  outline-color: rgb(109 40 217 / 0.05) !important;
}

.tw-outline-violet-700\/50 {
  outline-color: rgb(109 40 217 / 0.5) !important;
}

.tw-outline-violet-700\/55 {
  outline-color: rgb(109 40 217 / 0.55) !important;
}

.tw-outline-violet-700\/60 {
  outline-color: rgb(109 40 217 / 0.6) !important;
}

.tw-outline-violet-700\/65 {
  outline-color: rgb(109 40 217 / 0.65) !important;
}

.tw-outline-violet-700\/70 {
  outline-color: rgb(109 40 217 / 0.7) !important;
}

.tw-outline-violet-700\/75 {
  outline-color: rgb(109 40 217 / 0.75) !important;
}

.tw-outline-violet-700\/80 {
  outline-color: rgb(109 40 217 / 0.8) !important;
}

.tw-outline-violet-700\/85 {
  outline-color: rgb(109 40 217 / 0.85) !important;
}

.tw-outline-violet-700\/90 {
  outline-color: rgb(109 40 217 / 0.9) !important;
}

.tw-outline-violet-700\/95 {
  outline-color: rgb(109 40 217 / 0.95) !important;
}

.tw-outline-violet-800 {
  outline-color: #5b21b6 !important;
}

.tw-outline-violet-800\/0 {
  outline-color: rgb(91 33 182 / 0) !important;
}

.tw-outline-violet-800\/10 {
  outline-color: rgb(91 33 182 / 0.1) !important;
}

.tw-outline-violet-800\/100 {
  outline-color: rgb(91 33 182 / 1) !important;
}

.tw-outline-violet-800\/15 {
  outline-color: rgb(91 33 182 / 0.15) !important;
}

.tw-outline-violet-800\/20 {
  outline-color: rgb(91 33 182 / 0.2) !important;
}

.tw-outline-violet-800\/25 {
  outline-color: rgb(91 33 182 / 0.25) !important;
}

.tw-outline-violet-800\/30 {
  outline-color: rgb(91 33 182 / 0.3) !important;
}

.tw-outline-violet-800\/35 {
  outline-color: rgb(91 33 182 / 0.35) !important;
}

.tw-outline-violet-800\/40 {
  outline-color: rgb(91 33 182 / 0.4) !important;
}

.tw-outline-violet-800\/45 {
  outline-color: rgb(91 33 182 / 0.45) !important;
}

.tw-outline-violet-800\/5 {
  outline-color: rgb(91 33 182 / 0.05) !important;
}

.tw-outline-violet-800\/50 {
  outline-color: rgb(91 33 182 / 0.5) !important;
}

.tw-outline-violet-800\/55 {
  outline-color: rgb(91 33 182 / 0.55) !important;
}

.tw-outline-violet-800\/60 {
  outline-color: rgb(91 33 182 / 0.6) !important;
}

.tw-outline-violet-800\/65 {
  outline-color: rgb(91 33 182 / 0.65) !important;
}

.tw-outline-violet-800\/70 {
  outline-color: rgb(91 33 182 / 0.7) !important;
}

.tw-outline-violet-800\/75 {
  outline-color: rgb(91 33 182 / 0.75) !important;
}

.tw-outline-violet-800\/80 {
  outline-color: rgb(91 33 182 / 0.8) !important;
}

.tw-outline-violet-800\/85 {
  outline-color: rgb(91 33 182 / 0.85) !important;
}

.tw-outline-violet-800\/90 {
  outline-color: rgb(91 33 182 / 0.9) !important;
}

.tw-outline-violet-800\/95 {
  outline-color: rgb(91 33 182 / 0.95) !important;
}

.tw-outline-violet-900 {
  outline-color: #4c1d95 !important;
}

.tw-outline-violet-900\/0 {
  outline-color: rgb(76 29 149 / 0) !important;
}

.tw-outline-violet-900\/10 {
  outline-color: rgb(76 29 149 / 0.1) !important;
}

.tw-outline-violet-900\/100 {
  outline-color: rgb(76 29 149 / 1) !important;
}

.tw-outline-violet-900\/15 {
  outline-color: rgb(76 29 149 / 0.15) !important;
}

.tw-outline-violet-900\/20 {
  outline-color: rgb(76 29 149 / 0.2) !important;
}

.tw-outline-violet-900\/25 {
  outline-color: rgb(76 29 149 / 0.25) !important;
}

.tw-outline-violet-900\/30 {
  outline-color: rgb(76 29 149 / 0.3) !important;
}

.tw-outline-violet-900\/35 {
  outline-color: rgb(76 29 149 / 0.35) !important;
}

.tw-outline-violet-900\/40 {
  outline-color: rgb(76 29 149 / 0.4) !important;
}

.tw-outline-violet-900\/45 {
  outline-color: rgb(76 29 149 / 0.45) !important;
}

.tw-outline-violet-900\/5 {
  outline-color: rgb(76 29 149 / 0.05) !important;
}

.tw-outline-violet-900\/50 {
  outline-color: rgb(76 29 149 / 0.5) !important;
}

.tw-outline-violet-900\/55 {
  outline-color: rgb(76 29 149 / 0.55) !important;
}

.tw-outline-violet-900\/60 {
  outline-color: rgb(76 29 149 / 0.6) !important;
}

.tw-outline-violet-900\/65 {
  outline-color: rgb(76 29 149 / 0.65) !important;
}

.tw-outline-violet-900\/70 {
  outline-color: rgb(76 29 149 / 0.7) !important;
}

.tw-outline-violet-900\/75 {
  outline-color: rgb(76 29 149 / 0.75) !important;
}

.tw-outline-violet-900\/80 {
  outline-color: rgb(76 29 149 / 0.8) !important;
}

.tw-outline-violet-900\/85 {
  outline-color: rgb(76 29 149 / 0.85) !important;
}

.tw-outline-violet-900\/90 {
  outline-color: rgb(76 29 149 / 0.9) !important;
}

.tw-outline-violet-900\/95 {
  outline-color: rgb(76 29 149 / 0.95) !important;
}

.tw-outline-violet-950 {
  outline-color: #2e1065 !important;
}

.tw-outline-violet-950\/0 {
  outline-color: rgb(46 16 101 / 0) !important;
}

.tw-outline-violet-950\/10 {
  outline-color: rgb(46 16 101 / 0.1) !important;
}

.tw-outline-violet-950\/100 {
  outline-color: rgb(46 16 101 / 1) !important;
}

.tw-outline-violet-950\/15 {
  outline-color: rgb(46 16 101 / 0.15) !important;
}

.tw-outline-violet-950\/20 {
  outline-color: rgb(46 16 101 / 0.2) !important;
}

.tw-outline-violet-950\/25 {
  outline-color: rgb(46 16 101 / 0.25) !important;
}

.tw-outline-violet-950\/30 {
  outline-color: rgb(46 16 101 / 0.3) !important;
}

.tw-outline-violet-950\/35 {
  outline-color: rgb(46 16 101 / 0.35) !important;
}

.tw-outline-violet-950\/40 {
  outline-color: rgb(46 16 101 / 0.4) !important;
}

.tw-outline-violet-950\/45 {
  outline-color: rgb(46 16 101 / 0.45) !important;
}

.tw-outline-violet-950\/5 {
  outline-color: rgb(46 16 101 / 0.05) !important;
}

.tw-outline-violet-950\/50 {
  outline-color: rgb(46 16 101 / 0.5) !important;
}

.tw-outline-violet-950\/55 {
  outline-color: rgb(46 16 101 / 0.55) !important;
}

.tw-outline-violet-950\/60 {
  outline-color: rgb(46 16 101 / 0.6) !important;
}

.tw-outline-violet-950\/65 {
  outline-color: rgb(46 16 101 / 0.65) !important;
}

.tw-outline-violet-950\/70 {
  outline-color: rgb(46 16 101 / 0.7) !important;
}

.tw-outline-violet-950\/75 {
  outline-color: rgb(46 16 101 / 0.75) !important;
}

.tw-outline-violet-950\/80 {
  outline-color: rgb(46 16 101 / 0.8) !important;
}

.tw-outline-violet-950\/85 {
  outline-color: rgb(46 16 101 / 0.85) !important;
}

.tw-outline-violet-950\/90 {
  outline-color: rgb(46 16 101 / 0.9) !important;
}

.tw-outline-violet-950\/95 {
  outline-color: rgb(46 16 101 / 0.95) !important;
}

.tw-outline-white {
  outline-color: #fff !important;
}

.tw-outline-white\/0 {
  outline-color: rgb(255 255 255 / 0) !important;
}

.tw-outline-white\/10 {
  outline-color: rgb(255 255 255 / 0.1) !important;
}

.tw-outline-white\/100 {
  outline-color: rgb(255 255 255 / 1) !important;
}

.tw-outline-white\/15 {
  outline-color: rgb(255 255 255 / 0.15) !important;
}

.tw-outline-white\/20 {
  outline-color: rgb(255 255 255 / 0.2) !important;
}

.tw-outline-white\/25 {
  outline-color: rgb(255 255 255 / 0.25) !important;
}

.tw-outline-white\/30 {
  outline-color: rgb(255 255 255 / 0.3) !important;
}

.tw-outline-white\/35 {
  outline-color: rgb(255 255 255 / 0.35) !important;
}

.tw-outline-white\/40 {
  outline-color: rgb(255 255 255 / 0.4) !important;
}

.tw-outline-white\/45 {
  outline-color: rgb(255 255 255 / 0.45) !important;
}

.tw-outline-white\/5 {
  outline-color: rgb(255 255 255 / 0.05) !important;
}

.tw-outline-white\/50 {
  outline-color: rgb(255 255 255 / 0.5) !important;
}

.tw-outline-white\/55 {
  outline-color: rgb(255 255 255 / 0.55) !important;
}

.tw-outline-white\/60 {
  outline-color: rgb(255 255 255 / 0.6) !important;
}

.tw-outline-white\/65 {
  outline-color: rgb(255 255 255 / 0.65) !important;
}

.tw-outline-white\/70 {
  outline-color: rgb(255 255 255 / 0.7) !important;
}

.tw-outline-white\/75 {
  outline-color: rgb(255 255 255 / 0.75) !important;
}

.tw-outline-white\/80 {
  outline-color: rgb(255 255 255 / 0.8) !important;
}

.tw-outline-white\/85 {
  outline-color: rgb(255 255 255 / 0.85) !important;
}

.tw-outline-white\/90 {
  outline-color: rgb(255 255 255 / 0.9) !important;
}

.tw-outline-white\/95 {
  outline-color: rgb(255 255 255 / 0.95) !important;
}

.tw-outline-yellow-100 {
  outline-color: #fef9c3 !important;
}

.tw-outline-yellow-100\/0 {
  outline-color: rgb(254 249 195 / 0) !important;
}

.tw-outline-yellow-100\/10 {
  outline-color: rgb(254 249 195 / 0.1) !important;
}

.tw-outline-yellow-100\/100 {
  outline-color: rgb(254 249 195 / 1) !important;
}

.tw-outline-yellow-100\/15 {
  outline-color: rgb(254 249 195 / 0.15) !important;
}

.tw-outline-yellow-100\/20 {
  outline-color: rgb(254 249 195 / 0.2) !important;
}

.tw-outline-yellow-100\/25 {
  outline-color: rgb(254 249 195 / 0.25) !important;
}

.tw-outline-yellow-100\/30 {
  outline-color: rgb(254 249 195 / 0.3) !important;
}

.tw-outline-yellow-100\/35 {
  outline-color: rgb(254 249 195 / 0.35) !important;
}

.tw-outline-yellow-100\/40 {
  outline-color: rgb(254 249 195 / 0.4) !important;
}

.tw-outline-yellow-100\/45 {
  outline-color: rgb(254 249 195 / 0.45) !important;
}

.tw-outline-yellow-100\/5 {
  outline-color: rgb(254 249 195 / 0.05) !important;
}

.tw-outline-yellow-100\/50 {
  outline-color: rgb(254 249 195 / 0.5) !important;
}

.tw-outline-yellow-100\/55 {
  outline-color: rgb(254 249 195 / 0.55) !important;
}

.tw-outline-yellow-100\/60 {
  outline-color: rgb(254 249 195 / 0.6) !important;
}

.tw-outline-yellow-100\/65 {
  outline-color: rgb(254 249 195 / 0.65) !important;
}

.tw-outline-yellow-100\/70 {
  outline-color: rgb(254 249 195 / 0.7) !important;
}

.tw-outline-yellow-100\/75 {
  outline-color: rgb(254 249 195 / 0.75) !important;
}

.tw-outline-yellow-100\/80 {
  outline-color: rgb(254 249 195 / 0.8) !important;
}

.tw-outline-yellow-100\/85 {
  outline-color: rgb(254 249 195 / 0.85) !important;
}

.tw-outline-yellow-100\/90 {
  outline-color: rgb(254 249 195 / 0.9) !important;
}

.tw-outline-yellow-100\/95 {
  outline-color: rgb(254 249 195 / 0.95) !important;
}

.tw-outline-yellow-200 {
  outline-color: #fef08a !important;
}

.tw-outline-yellow-200\/0 {
  outline-color: rgb(254 240 138 / 0) !important;
}

.tw-outline-yellow-200\/10 {
  outline-color: rgb(254 240 138 / 0.1) !important;
}

.tw-outline-yellow-200\/100 {
  outline-color: rgb(254 240 138 / 1) !important;
}

.tw-outline-yellow-200\/15 {
  outline-color: rgb(254 240 138 / 0.15) !important;
}

.tw-outline-yellow-200\/20 {
  outline-color: rgb(254 240 138 / 0.2) !important;
}

.tw-outline-yellow-200\/25 {
  outline-color: rgb(254 240 138 / 0.25) !important;
}

.tw-outline-yellow-200\/30 {
  outline-color: rgb(254 240 138 / 0.3) !important;
}

.tw-outline-yellow-200\/35 {
  outline-color: rgb(254 240 138 / 0.35) !important;
}

.tw-outline-yellow-200\/40 {
  outline-color: rgb(254 240 138 / 0.4) !important;
}

.tw-outline-yellow-200\/45 {
  outline-color: rgb(254 240 138 / 0.45) !important;
}

.tw-outline-yellow-200\/5 {
  outline-color: rgb(254 240 138 / 0.05) !important;
}

.tw-outline-yellow-200\/50 {
  outline-color: rgb(254 240 138 / 0.5) !important;
}

.tw-outline-yellow-200\/55 {
  outline-color: rgb(254 240 138 / 0.55) !important;
}

.tw-outline-yellow-200\/60 {
  outline-color: rgb(254 240 138 / 0.6) !important;
}

.tw-outline-yellow-200\/65 {
  outline-color: rgb(254 240 138 / 0.65) !important;
}

.tw-outline-yellow-200\/70 {
  outline-color: rgb(254 240 138 / 0.7) !important;
}

.tw-outline-yellow-200\/75 {
  outline-color: rgb(254 240 138 / 0.75) !important;
}

.tw-outline-yellow-200\/80 {
  outline-color: rgb(254 240 138 / 0.8) !important;
}

.tw-outline-yellow-200\/85 {
  outline-color: rgb(254 240 138 / 0.85) !important;
}

.tw-outline-yellow-200\/90 {
  outline-color: rgb(254 240 138 / 0.9) !important;
}

.tw-outline-yellow-200\/95 {
  outline-color: rgb(254 240 138 / 0.95) !important;
}

.tw-outline-yellow-300 {
  outline-color: #fde047 !important;
}

.tw-outline-yellow-300\/0 {
  outline-color: rgb(253 224 71 / 0) !important;
}

.tw-outline-yellow-300\/10 {
  outline-color: rgb(253 224 71 / 0.1) !important;
}

.tw-outline-yellow-300\/100 {
  outline-color: rgb(253 224 71 / 1) !important;
}

.tw-outline-yellow-300\/15 {
  outline-color: rgb(253 224 71 / 0.15) !important;
}

.tw-outline-yellow-300\/20 {
  outline-color: rgb(253 224 71 / 0.2) !important;
}

.tw-outline-yellow-300\/25 {
  outline-color: rgb(253 224 71 / 0.25) !important;
}

.tw-outline-yellow-300\/30 {
  outline-color: rgb(253 224 71 / 0.3) !important;
}

.tw-outline-yellow-300\/35 {
  outline-color: rgb(253 224 71 / 0.35) !important;
}

.tw-outline-yellow-300\/40 {
  outline-color: rgb(253 224 71 / 0.4) !important;
}

.tw-outline-yellow-300\/45 {
  outline-color: rgb(253 224 71 / 0.45) !important;
}

.tw-outline-yellow-300\/5 {
  outline-color: rgb(253 224 71 / 0.05) !important;
}

.tw-outline-yellow-300\/50 {
  outline-color: rgb(253 224 71 / 0.5) !important;
}

.tw-outline-yellow-300\/55 {
  outline-color: rgb(253 224 71 / 0.55) !important;
}

.tw-outline-yellow-300\/60 {
  outline-color: rgb(253 224 71 / 0.6) !important;
}

.tw-outline-yellow-300\/65 {
  outline-color: rgb(253 224 71 / 0.65) !important;
}

.tw-outline-yellow-300\/70 {
  outline-color: rgb(253 224 71 / 0.7) !important;
}

.tw-outline-yellow-300\/75 {
  outline-color: rgb(253 224 71 / 0.75) !important;
}

.tw-outline-yellow-300\/80 {
  outline-color: rgb(253 224 71 / 0.8) !important;
}

.tw-outline-yellow-300\/85 {
  outline-color: rgb(253 224 71 / 0.85) !important;
}

.tw-outline-yellow-300\/90 {
  outline-color: rgb(253 224 71 / 0.9) !important;
}

.tw-outline-yellow-300\/95 {
  outline-color: rgb(253 224 71 / 0.95) !important;
}

.tw-outline-yellow-400 {
  outline-color: #facc15 !important;
}

.tw-outline-yellow-400\/0 {
  outline-color: rgb(250 204 21 / 0) !important;
}

.tw-outline-yellow-400\/10 {
  outline-color: rgb(250 204 21 / 0.1) !important;
}

.tw-outline-yellow-400\/100 {
  outline-color: rgb(250 204 21 / 1) !important;
}

.tw-outline-yellow-400\/15 {
  outline-color: rgb(250 204 21 / 0.15) !important;
}

.tw-outline-yellow-400\/20 {
  outline-color: rgb(250 204 21 / 0.2) !important;
}

.tw-outline-yellow-400\/25 {
  outline-color: rgb(250 204 21 / 0.25) !important;
}

.tw-outline-yellow-400\/30 {
  outline-color: rgb(250 204 21 / 0.3) !important;
}

.tw-outline-yellow-400\/35 {
  outline-color: rgb(250 204 21 / 0.35) !important;
}

.tw-outline-yellow-400\/40 {
  outline-color: rgb(250 204 21 / 0.4) !important;
}

.tw-outline-yellow-400\/45 {
  outline-color: rgb(250 204 21 / 0.45) !important;
}

.tw-outline-yellow-400\/5 {
  outline-color: rgb(250 204 21 / 0.05) !important;
}

.tw-outline-yellow-400\/50 {
  outline-color: rgb(250 204 21 / 0.5) !important;
}

.tw-outline-yellow-400\/55 {
  outline-color: rgb(250 204 21 / 0.55) !important;
}

.tw-outline-yellow-400\/60 {
  outline-color: rgb(250 204 21 / 0.6) !important;
}

.tw-outline-yellow-400\/65 {
  outline-color: rgb(250 204 21 / 0.65) !important;
}

.tw-outline-yellow-400\/70 {
  outline-color: rgb(250 204 21 / 0.7) !important;
}

.tw-outline-yellow-400\/75 {
  outline-color: rgb(250 204 21 / 0.75) !important;
}

.tw-outline-yellow-400\/80 {
  outline-color: rgb(250 204 21 / 0.8) !important;
}

.tw-outline-yellow-400\/85 {
  outline-color: rgb(250 204 21 / 0.85) !important;
}

.tw-outline-yellow-400\/90 {
  outline-color: rgb(250 204 21 / 0.9) !important;
}

.tw-outline-yellow-400\/95 {
  outline-color: rgb(250 204 21 / 0.95) !important;
}

.tw-outline-yellow-50 {
  outline-color: #fefce8 !important;
}

.tw-outline-yellow-50\/0 {
  outline-color: rgb(254 252 232 / 0) !important;
}

.tw-outline-yellow-50\/10 {
  outline-color: rgb(254 252 232 / 0.1) !important;
}

.tw-outline-yellow-50\/100 {
  outline-color: rgb(254 252 232 / 1) !important;
}

.tw-outline-yellow-50\/15 {
  outline-color: rgb(254 252 232 / 0.15) !important;
}

.tw-outline-yellow-50\/20 {
  outline-color: rgb(254 252 232 / 0.2) !important;
}

.tw-outline-yellow-50\/25 {
  outline-color: rgb(254 252 232 / 0.25) !important;
}

.tw-outline-yellow-50\/30 {
  outline-color: rgb(254 252 232 / 0.3) !important;
}

.tw-outline-yellow-50\/35 {
  outline-color: rgb(254 252 232 / 0.35) !important;
}

.tw-outline-yellow-50\/40 {
  outline-color: rgb(254 252 232 / 0.4) !important;
}

.tw-outline-yellow-50\/45 {
  outline-color: rgb(254 252 232 / 0.45) !important;
}

.tw-outline-yellow-50\/5 {
  outline-color: rgb(254 252 232 / 0.05) !important;
}

.tw-outline-yellow-50\/50 {
  outline-color: rgb(254 252 232 / 0.5) !important;
}

.tw-outline-yellow-50\/55 {
  outline-color: rgb(254 252 232 / 0.55) !important;
}

.tw-outline-yellow-50\/60 {
  outline-color: rgb(254 252 232 / 0.6) !important;
}

.tw-outline-yellow-50\/65 {
  outline-color: rgb(254 252 232 / 0.65) !important;
}

.tw-outline-yellow-50\/70 {
  outline-color: rgb(254 252 232 / 0.7) !important;
}

.tw-outline-yellow-50\/75 {
  outline-color: rgb(254 252 232 / 0.75) !important;
}

.tw-outline-yellow-50\/80 {
  outline-color: rgb(254 252 232 / 0.8) !important;
}

.tw-outline-yellow-50\/85 {
  outline-color: rgb(254 252 232 / 0.85) !important;
}

.tw-outline-yellow-50\/90 {
  outline-color: rgb(254 252 232 / 0.9) !important;
}

.tw-outline-yellow-50\/95 {
  outline-color: rgb(254 252 232 / 0.95) !important;
}

.tw-outline-yellow-500 {
  outline-color: #eab308 !important;
}

.tw-outline-yellow-500\/0 {
  outline-color: rgb(234 179 8 / 0) !important;
}

.tw-outline-yellow-500\/10 {
  outline-color: rgb(234 179 8 / 0.1) !important;
}

.tw-outline-yellow-500\/100 {
  outline-color: rgb(234 179 8 / 1) !important;
}

.tw-outline-yellow-500\/15 {
  outline-color: rgb(234 179 8 / 0.15) !important;
}

.tw-outline-yellow-500\/20 {
  outline-color: rgb(234 179 8 / 0.2) !important;
}

.tw-outline-yellow-500\/25 {
  outline-color: rgb(234 179 8 / 0.25) !important;
}

.tw-outline-yellow-500\/30 {
  outline-color: rgb(234 179 8 / 0.3) !important;
}

.tw-outline-yellow-500\/35 {
  outline-color: rgb(234 179 8 / 0.35) !important;
}

.tw-outline-yellow-500\/40 {
  outline-color: rgb(234 179 8 / 0.4) !important;
}

.tw-outline-yellow-500\/45 {
  outline-color: rgb(234 179 8 / 0.45) !important;
}

.tw-outline-yellow-500\/5 {
  outline-color: rgb(234 179 8 / 0.05) !important;
}

.tw-outline-yellow-500\/50 {
  outline-color: rgb(234 179 8 / 0.5) !important;
}

.tw-outline-yellow-500\/55 {
  outline-color: rgb(234 179 8 / 0.55) !important;
}

.tw-outline-yellow-500\/60 {
  outline-color: rgb(234 179 8 / 0.6) !important;
}

.tw-outline-yellow-500\/65 {
  outline-color: rgb(234 179 8 / 0.65) !important;
}

.tw-outline-yellow-500\/70 {
  outline-color: rgb(234 179 8 / 0.7) !important;
}

.tw-outline-yellow-500\/75 {
  outline-color: rgb(234 179 8 / 0.75) !important;
}

.tw-outline-yellow-500\/80 {
  outline-color: rgb(234 179 8 / 0.8) !important;
}

.tw-outline-yellow-500\/85 {
  outline-color: rgb(234 179 8 / 0.85) !important;
}

.tw-outline-yellow-500\/90 {
  outline-color: rgb(234 179 8 / 0.9) !important;
}

.tw-outline-yellow-500\/95 {
  outline-color: rgb(234 179 8 / 0.95) !important;
}

.tw-outline-yellow-600 {
  outline-color: #ca8a04 !important;
}

.tw-outline-yellow-600\/0 {
  outline-color: rgb(202 138 4 / 0) !important;
}

.tw-outline-yellow-600\/10 {
  outline-color: rgb(202 138 4 / 0.1) !important;
}

.tw-outline-yellow-600\/100 {
  outline-color: rgb(202 138 4 / 1) !important;
}

.tw-outline-yellow-600\/15 {
  outline-color: rgb(202 138 4 / 0.15) !important;
}

.tw-outline-yellow-600\/20 {
  outline-color: rgb(202 138 4 / 0.2) !important;
}

.tw-outline-yellow-600\/25 {
  outline-color: rgb(202 138 4 / 0.25) !important;
}

.tw-outline-yellow-600\/30 {
  outline-color: rgb(202 138 4 / 0.3) !important;
}

.tw-outline-yellow-600\/35 {
  outline-color: rgb(202 138 4 / 0.35) !important;
}

.tw-outline-yellow-600\/40 {
  outline-color: rgb(202 138 4 / 0.4) !important;
}

.tw-outline-yellow-600\/45 {
  outline-color: rgb(202 138 4 / 0.45) !important;
}

.tw-outline-yellow-600\/5 {
  outline-color: rgb(202 138 4 / 0.05) !important;
}

.tw-outline-yellow-600\/50 {
  outline-color: rgb(202 138 4 / 0.5) !important;
}

.tw-outline-yellow-600\/55 {
  outline-color: rgb(202 138 4 / 0.55) !important;
}

.tw-outline-yellow-600\/60 {
  outline-color: rgb(202 138 4 / 0.6) !important;
}

.tw-outline-yellow-600\/65 {
  outline-color: rgb(202 138 4 / 0.65) !important;
}

.tw-outline-yellow-600\/70 {
  outline-color: rgb(202 138 4 / 0.7) !important;
}

.tw-outline-yellow-600\/75 {
  outline-color: rgb(202 138 4 / 0.75) !important;
}

.tw-outline-yellow-600\/80 {
  outline-color: rgb(202 138 4 / 0.8) !important;
}

.tw-outline-yellow-600\/85 {
  outline-color: rgb(202 138 4 / 0.85) !important;
}

.tw-outline-yellow-600\/90 {
  outline-color: rgb(202 138 4 / 0.9) !important;
}

.tw-outline-yellow-600\/95 {
  outline-color: rgb(202 138 4 / 0.95) !important;
}

.tw-outline-yellow-700 {
  outline-color: #a16207 !important;
}

.tw-outline-yellow-700\/0 {
  outline-color: rgb(161 98 7 / 0) !important;
}

.tw-outline-yellow-700\/10 {
  outline-color: rgb(161 98 7 / 0.1) !important;
}

.tw-outline-yellow-700\/100 {
  outline-color: rgb(161 98 7 / 1) !important;
}

.tw-outline-yellow-700\/15 {
  outline-color: rgb(161 98 7 / 0.15) !important;
}

.tw-outline-yellow-700\/20 {
  outline-color: rgb(161 98 7 / 0.2) !important;
}

.tw-outline-yellow-700\/25 {
  outline-color: rgb(161 98 7 / 0.25) !important;
}

.tw-outline-yellow-700\/30 {
  outline-color: rgb(161 98 7 / 0.3) !important;
}

.tw-outline-yellow-700\/35 {
  outline-color: rgb(161 98 7 / 0.35) !important;
}

.tw-outline-yellow-700\/40 {
  outline-color: rgb(161 98 7 / 0.4) !important;
}

.tw-outline-yellow-700\/45 {
  outline-color: rgb(161 98 7 / 0.45) !important;
}

.tw-outline-yellow-700\/5 {
  outline-color: rgb(161 98 7 / 0.05) !important;
}

.tw-outline-yellow-700\/50 {
  outline-color: rgb(161 98 7 / 0.5) !important;
}

.tw-outline-yellow-700\/55 {
  outline-color: rgb(161 98 7 / 0.55) !important;
}

.tw-outline-yellow-700\/60 {
  outline-color: rgb(161 98 7 / 0.6) !important;
}

.tw-outline-yellow-700\/65 {
  outline-color: rgb(161 98 7 / 0.65) !important;
}

.tw-outline-yellow-700\/70 {
  outline-color: rgb(161 98 7 / 0.7) !important;
}

.tw-outline-yellow-700\/75 {
  outline-color: rgb(161 98 7 / 0.75) !important;
}

.tw-outline-yellow-700\/80 {
  outline-color: rgb(161 98 7 / 0.8) !important;
}

.tw-outline-yellow-700\/85 {
  outline-color: rgb(161 98 7 / 0.85) !important;
}

.tw-outline-yellow-700\/90 {
  outline-color: rgb(161 98 7 / 0.9) !important;
}

.tw-outline-yellow-700\/95 {
  outline-color: rgb(161 98 7 / 0.95) !important;
}

.tw-outline-yellow-800 {
  outline-color: #854d0e !important;
}

.tw-outline-yellow-800\/0 {
  outline-color: rgb(133 77 14 / 0) !important;
}

.tw-outline-yellow-800\/10 {
  outline-color: rgb(133 77 14 / 0.1) !important;
}

.tw-outline-yellow-800\/100 {
  outline-color: rgb(133 77 14 / 1) !important;
}

.tw-outline-yellow-800\/15 {
  outline-color: rgb(133 77 14 / 0.15) !important;
}

.tw-outline-yellow-800\/20 {
  outline-color: rgb(133 77 14 / 0.2) !important;
}

.tw-outline-yellow-800\/25 {
  outline-color: rgb(133 77 14 / 0.25) !important;
}

.tw-outline-yellow-800\/30 {
  outline-color: rgb(133 77 14 / 0.3) !important;
}

.tw-outline-yellow-800\/35 {
  outline-color: rgb(133 77 14 / 0.35) !important;
}

.tw-outline-yellow-800\/40 {
  outline-color: rgb(133 77 14 / 0.4) !important;
}

.tw-outline-yellow-800\/45 {
  outline-color: rgb(133 77 14 / 0.45) !important;
}

.tw-outline-yellow-800\/5 {
  outline-color: rgb(133 77 14 / 0.05) !important;
}

.tw-outline-yellow-800\/50 {
  outline-color: rgb(133 77 14 / 0.5) !important;
}

.tw-outline-yellow-800\/55 {
  outline-color: rgb(133 77 14 / 0.55) !important;
}

.tw-outline-yellow-800\/60 {
  outline-color: rgb(133 77 14 / 0.6) !important;
}

.tw-outline-yellow-800\/65 {
  outline-color: rgb(133 77 14 / 0.65) !important;
}

.tw-outline-yellow-800\/70 {
  outline-color: rgb(133 77 14 / 0.7) !important;
}

.tw-outline-yellow-800\/75 {
  outline-color: rgb(133 77 14 / 0.75) !important;
}

.tw-outline-yellow-800\/80 {
  outline-color: rgb(133 77 14 / 0.8) !important;
}

.tw-outline-yellow-800\/85 {
  outline-color: rgb(133 77 14 / 0.85) !important;
}

.tw-outline-yellow-800\/90 {
  outline-color: rgb(133 77 14 / 0.9) !important;
}

.tw-outline-yellow-800\/95 {
  outline-color: rgb(133 77 14 / 0.95) !important;
}

.tw-outline-yellow-900 {
  outline-color: #713f12 !important;
}

.tw-outline-yellow-900\/0 {
  outline-color: rgb(113 63 18 / 0) !important;
}

.tw-outline-yellow-900\/10 {
  outline-color: rgb(113 63 18 / 0.1) !important;
}

.tw-outline-yellow-900\/100 {
  outline-color: rgb(113 63 18 / 1) !important;
}

.tw-outline-yellow-900\/15 {
  outline-color: rgb(113 63 18 / 0.15) !important;
}

.tw-outline-yellow-900\/20 {
  outline-color: rgb(113 63 18 / 0.2) !important;
}

.tw-outline-yellow-900\/25 {
  outline-color: rgb(113 63 18 / 0.25) !important;
}

.tw-outline-yellow-900\/30 {
  outline-color: rgb(113 63 18 / 0.3) !important;
}

.tw-outline-yellow-900\/35 {
  outline-color: rgb(113 63 18 / 0.35) !important;
}

.tw-outline-yellow-900\/40 {
  outline-color: rgb(113 63 18 / 0.4) !important;
}

.tw-outline-yellow-900\/45 {
  outline-color: rgb(113 63 18 / 0.45) !important;
}

.tw-outline-yellow-900\/5 {
  outline-color: rgb(113 63 18 / 0.05) !important;
}

.tw-outline-yellow-900\/50 {
  outline-color: rgb(113 63 18 / 0.5) !important;
}

.tw-outline-yellow-900\/55 {
  outline-color: rgb(113 63 18 / 0.55) !important;
}

.tw-outline-yellow-900\/60 {
  outline-color: rgb(113 63 18 / 0.6) !important;
}

.tw-outline-yellow-900\/65 {
  outline-color: rgb(113 63 18 / 0.65) !important;
}

.tw-outline-yellow-900\/70 {
  outline-color: rgb(113 63 18 / 0.7) !important;
}

.tw-outline-yellow-900\/75 {
  outline-color: rgb(113 63 18 / 0.75) !important;
}

.tw-outline-yellow-900\/80 {
  outline-color: rgb(113 63 18 / 0.8) !important;
}

.tw-outline-yellow-900\/85 {
  outline-color: rgb(113 63 18 / 0.85) !important;
}

.tw-outline-yellow-900\/90 {
  outline-color: rgb(113 63 18 / 0.9) !important;
}

.tw-outline-yellow-900\/95 {
  outline-color: rgb(113 63 18 / 0.95) !important;
}

.tw-outline-yellow-950 {
  outline-color: #422006 !important;
}

.tw-outline-yellow-950\/0 {
  outline-color: rgb(66 32 6 / 0) !important;
}

.tw-outline-yellow-950\/10 {
  outline-color: rgb(66 32 6 / 0.1) !important;
}

.tw-outline-yellow-950\/100 {
  outline-color: rgb(66 32 6 / 1) !important;
}

.tw-outline-yellow-950\/15 {
  outline-color: rgb(66 32 6 / 0.15) !important;
}

.tw-outline-yellow-950\/20 {
  outline-color: rgb(66 32 6 / 0.2) !important;
}

.tw-outline-yellow-950\/25 {
  outline-color: rgb(66 32 6 / 0.25) !important;
}

.tw-outline-yellow-950\/30 {
  outline-color: rgb(66 32 6 / 0.3) !important;
}

.tw-outline-yellow-950\/35 {
  outline-color: rgb(66 32 6 / 0.35) !important;
}

.tw-outline-yellow-950\/40 {
  outline-color: rgb(66 32 6 / 0.4) !important;
}

.tw-outline-yellow-950\/45 {
  outline-color: rgb(66 32 6 / 0.45) !important;
}

.tw-outline-yellow-950\/5 {
  outline-color: rgb(66 32 6 / 0.05) !important;
}

.tw-outline-yellow-950\/50 {
  outline-color: rgb(66 32 6 / 0.5) !important;
}

.tw-outline-yellow-950\/55 {
  outline-color: rgb(66 32 6 / 0.55) !important;
}

.tw-outline-yellow-950\/60 {
  outline-color: rgb(66 32 6 / 0.6) !important;
}

.tw-outline-yellow-950\/65 {
  outline-color: rgb(66 32 6 / 0.65) !important;
}

.tw-outline-yellow-950\/70 {
  outline-color: rgb(66 32 6 / 0.7) !important;
}

.tw-outline-yellow-950\/75 {
  outline-color: rgb(66 32 6 / 0.75) !important;
}

.tw-outline-yellow-950\/80 {
  outline-color: rgb(66 32 6 / 0.8) !important;
}

.tw-outline-yellow-950\/85 {
  outline-color: rgb(66 32 6 / 0.85) !important;
}

.tw-outline-yellow-950\/90 {
  outline-color: rgb(66 32 6 / 0.9) !important;
}

.tw-outline-yellow-950\/95 {
  outline-color: rgb(66 32 6 / 0.95) !important;
}

.tw-outline-zinc-100 {
  outline-color: #f4f4f5 !important;
}

.tw-outline-zinc-100\/0 {
  outline-color: rgb(244 244 245 / 0) !important;
}

.tw-outline-zinc-100\/10 {
  outline-color: rgb(244 244 245 / 0.1) !important;
}

.tw-outline-zinc-100\/100 {
  outline-color: rgb(244 244 245 / 1) !important;
}

.tw-outline-zinc-100\/15 {
  outline-color: rgb(244 244 245 / 0.15) !important;
}

.tw-outline-zinc-100\/20 {
  outline-color: rgb(244 244 245 / 0.2) !important;
}

.tw-outline-zinc-100\/25 {
  outline-color: rgb(244 244 245 / 0.25) !important;
}

.tw-outline-zinc-100\/30 {
  outline-color: rgb(244 244 245 / 0.3) !important;
}

.tw-outline-zinc-100\/35 {
  outline-color: rgb(244 244 245 / 0.35) !important;
}

.tw-outline-zinc-100\/40 {
  outline-color: rgb(244 244 245 / 0.4) !important;
}

.tw-outline-zinc-100\/45 {
  outline-color: rgb(244 244 245 / 0.45) !important;
}

.tw-outline-zinc-100\/5 {
  outline-color: rgb(244 244 245 / 0.05) !important;
}

.tw-outline-zinc-100\/50 {
  outline-color: rgb(244 244 245 / 0.5) !important;
}

.tw-outline-zinc-100\/55 {
  outline-color: rgb(244 244 245 / 0.55) !important;
}

.tw-outline-zinc-100\/60 {
  outline-color: rgb(244 244 245 / 0.6) !important;
}

.tw-outline-zinc-100\/65 {
  outline-color: rgb(244 244 245 / 0.65) !important;
}

.tw-outline-zinc-100\/70 {
  outline-color: rgb(244 244 245 / 0.7) !important;
}

.tw-outline-zinc-100\/75 {
  outline-color: rgb(244 244 245 / 0.75) !important;
}

.tw-outline-zinc-100\/80 {
  outline-color: rgb(244 244 245 / 0.8) !important;
}

.tw-outline-zinc-100\/85 {
  outline-color: rgb(244 244 245 / 0.85) !important;
}

.tw-outline-zinc-100\/90 {
  outline-color: rgb(244 244 245 / 0.9) !important;
}

.tw-outline-zinc-100\/95 {
  outline-color: rgb(244 244 245 / 0.95) !important;
}

.tw-outline-zinc-200 {
  outline-color: #e4e4e7 !important;
}

.tw-outline-zinc-200\/0 {
  outline-color: rgb(228 228 231 / 0) !important;
}

.tw-outline-zinc-200\/10 {
  outline-color: rgb(228 228 231 / 0.1) !important;
}

.tw-outline-zinc-200\/100 {
  outline-color: rgb(228 228 231 / 1) !important;
}

.tw-outline-zinc-200\/15 {
  outline-color: rgb(228 228 231 / 0.15) !important;
}

.tw-outline-zinc-200\/20 {
  outline-color: rgb(228 228 231 / 0.2) !important;
}

.tw-outline-zinc-200\/25 {
  outline-color: rgb(228 228 231 / 0.25) !important;
}

.tw-outline-zinc-200\/30 {
  outline-color: rgb(228 228 231 / 0.3) !important;
}

.tw-outline-zinc-200\/35 {
  outline-color: rgb(228 228 231 / 0.35) !important;
}

.tw-outline-zinc-200\/40 {
  outline-color: rgb(228 228 231 / 0.4) !important;
}

.tw-outline-zinc-200\/45 {
  outline-color: rgb(228 228 231 / 0.45) !important;
}

.tw-outline-zinc-200\/5 {
  outline-color: rgb(228 228 231 / 0.05) !important;
}

.tw-outline-zinc-200\/50 {
  outline-color: rgb(228 228 231 / 0.5) !important;
}

.tw-outline-zinc-200\/55 {
  outline-color: rgb(228 228 231 / 0.55) !important;
}

.tw-outline-zinc-200\/60 {
  outline-color: rgb(228 228 231 / 0.6) !important;
}

.tw-outline-zinc-200\/65 {
  outline-color: rgb(228 228 231 / 0.65) !important;
}

.tw-outline-zinc-200\/70 {
  outline-color: rgb(228 228 231 / 0.7) !important;
}

.tw-outline-zinc-200\/75 {
  outline-color: rgb(228 228 231 / 0.75) !important;
}

.tw-outline-zinc-200\/80 {
  outline-color: rgb(228 228 231 / 0.8) !important;
}

.tw-outline-zinc-200\/85 {
  outline-color: rgb(228 228 231 / 0.85) !important;
}

.tw-outline-zinc-200\/90 {
  outline-color: rgb(228 228 231 / 0.9) !important;
}

.tw-outline-zinc-200\/95 {
  outline-color: rgb(228 228 231 / 0.95) !important;
}

.tw-outline-zinc-300 {
  outline-color: #d4d4d8 !important;
}

.tw-outline-zinc-300\/0 {
  outline-color: rgb(212 212 216 / 0) !important;
}

.tw-outline-zinc-300\/10 {
  outline-color: rgb(212 212 216 / 0.1) !important;
}

.tw-outline-zinc-300\/100 {
  outline-color: rgb(212 212 216 / 1) !important;
}

.tw-outline-zinc-300\/15 {
  outline-color: rgb(212 212 216 / 0.15) !important;
}

.tw-outline-zinc-300\/20 {
  outline-color: rgb(212 212 216 / 0.2) !important;
}

.tw-outline-zinc-300\/25 {
  outline-color: rgb(212 212 216 / 0.25) !important;
}

.tw-outline-zinc-300\/30 {
  outline-color: rgb(212 212 216 / 0.3) !important;
}

.tw-outline-zinc-300\/35 {
  outline-color: rgb(212 212 216 / 0.35) !important;
}

.tw-outline-zinc-300\/40 {
  outline-color: rgb(212 212 216 / 0.4) !important;
}

.tw-outline-zinc-300\/45 {
  outline-color: rgb(212 212 216 / 0.45) !important;
}

.tw-outline-zinc-300\/5 {
  outline-color: rgb(212 212 216 / 0.05) !important;
}

.tw-outline-zinc-300\/50 {
  outline-color: rgb(212 212 216 / 0.5) !important;
}

.tw-outline-zinc-300\/55 {
  outline-color: rgb(212 212 216 / 0.55) !important;
}

.tw-outline-zinc-300\/60 {
  outline-color: rgb(212 212 216 / 0.6) !important;
}

.tw-outline-zinc-300\/65 {
  outline-color: rgb(212 212 216 / 0.65) !important;
}

.tw-outline-zinc-300\/70 {
  outline-color: rgb(212 212 216 / 0.7) !important;
}

.tw-outline-zinc-300\/75 {
  outline-color: rgb(212 212 216 / 0.75) !important;
}

.tw-outline-zinc-300\/80 {
  outline-color: rgb(212 212 216 / 0.8) !important;
}

.tw-outline-zinc-300\/85 {
  outline-color: rgb(212 212 216 / 0.85) !important;
}

.tw-outline-zinc-300\/90 {
  outline-color: rgb(212 212 216 / 0.9) !important;
}

.tw-outline-zinc-300\/95 {
  outline-color: rgb(212 212 216 / 0.95) !important;
}

.tw-outline-zinc-400 {
  outline-color: #a1a1aa !important;
}

.tw-outline-zinc-400\/0 {
  outline-color: rgb(161 161 170 / 0) !important;
}

.tw-outline-zinc-400\/10 {
  outline-color: rgb(161 161 170 / 0.1) !important;
}

.tw-outline-zinc-400\/100 {
  outline-color: rgb(161 161 170 / 1) !important;
}

.tw-outline-zinc-400\/15 {
  outline-color: rgb(161 161 170 / 0.15) !important;
}

.tw-outline-zinc-400\/20 {
  outline-color: rgb(161 161 170 / 0.2) !important;
}

.tw-outline-zinc-400\/25 {
  outline-color: rgb(161 161 170 / 0.25) !important;
}

.tw-outline-zinc-400\/30 {
  outline-color: rgb(161 161 170 / 0.3) !important;
}

.tw-outline-zinc-400\/35 {
  outline-color: rgb(161 161 170 / 0.35) !important;
}

.tw-outline-zinc-400\/40 {
  outline-color: rgb(161 161 170 / 0.4) !important;
}

.tw-outline-zinc-400\/45 {
  outline-color: rgb(161 161 170 / 0.45) !important;
}

.tw-outline-zinc-400\/5 {
  outline-color: rgb(161 161 170 / 0.05) !important;
}

.tw-outline-zinc-400\/50 {
  outline-color: rgb(161 161 170 / 0.5) !important;
}

.tw-outline-zinc-400\/55 {
  outline-color: rgb(161 161 170 / 0.55) !important;
}

.tw-outline-zinc-400\/60 {
  outline-color: rgb(161 161 170 / 0.6) !important;
}

.tw-outline-zinc-400\/65 {
  outline-color: rgb(161 161 170 / 0.65) !important;
}

.tw-outline-zinc-400\/70 {
  outline-color: rgb(161 161 170 / 0.7) !important;
}

.tw-outline-zinc-400\/75 {
  outline-color: rgb(161 161 170 / 0.75) !important;
}

.tw-outline-zinc-400\/80 {
  outline-color: rgb(161 161 170 / 0.8) !important;
}

.tw-outline-zinc-400\/85 {
  outline-color: rgb(161 161 170 / 0.85) !important;
}

.tw-outline-zinc-400\/90 {
  outline-color: rgb(161 161 170 / 0.9) !important;
}

.tw-outline-zinc-400\/95 {
  outline-color: rgb(161 161 170 / 0.95) !important;
}

.tw-outline-zinc-50 {
  outline-color: #fafafa !important;
}

.tw-outline-zinc-50\/0 {
  outline-color: rgb(250 250 250 / 0) !important;
}

.tw-outline-zinc-50\/10 {
  outline-color: rgb(250 250 250 / 0.1) !important;
}

.tw-outline-zinc-50\/100 {
  outline-color: rgb(250 250 250 / 1) !important;
}

.tw-outline-zinc-50\/15 {
  outline-color: rgb(250 250 250 / 0.15) !important;
}

.tw-outline-zinc-50\/20 {
  outline-color: rgb(250 250 250 / 0.2) !important;
}

.tw-outline-zinc-50\/25 {
  outline-color: rgb(250 250 250 / 0.25) !important;
}

.tw-outline-zinc-50\/30 {
  outline-color: rgb(250 250 250 / 0.3) !important;
}

.tw-outline-zinc-50\/35 {
  outline-color: rgb(250 250 250 / 0.35) !important;
}

.tw-outline-zinc-50\/40 {
  outline-color: rgb(250 250 250 / 0.4) !important;
}

.tw-outline-zinc-50\/45 {
  outline-color: rgb(250 250 250 / 0.45) !important;
}

.tw-outline-zinc-50\/5 {
  outline-color: rgb(250 250 250 / 0.05) !important;
}

.tw-outline-zinc-50\/50 {
  outline-color: rgb(250 250 250 / 0.5) !important;
}

.tw-outline-zinc-50\/55 {
  outline-color: rgb(250 250 250 / 0.55) !important;
}

.tw-outline-zinc-50\/60 {
  outline-color: rgb(250 250 250 / 0.6) !important;
}

.tw-outline-zinc-50\/65 {
  outline-color: rgb(250 250 250 / 0.65) !important;
}

.tw-outline-zinc-50\/70 {
  outline-color: rgb(250 250 250 / 0.7) !important;
}

.tw-outline-zinc-50\/75 {
  outline-color: rgb(250 250 250 / 0.75) !important;
}

.tw-outline-zinc-50\/80 {
  outline-color: rgb(250 250 250 / 0.8) !important;
}

.tw-outline-zinc-50\/85 {
  outline-color: rgb(250 250 250 / 0.85) !important;
}

.tw-outline-zinc-50\/90 {
  outline-color: rgb(250 250 250 / 0.9) !important;
}

.tw-outline-zinc-50\/95 {
  outline-color: rgb(250 250 250 / 0.95) !important;
}

.tw-outline-zinc-500 {
  outline-color: #71717a !important;
}

.tw-outline-zinc-500\/0 {
  outline-color: rgb(113 113 122 / 0) !important;
}

.tw-outline-zinc-500\/10 {
  outline-color: rgb(113 113 122 / 0.1) !important;
}

.tw-outline-zinc-500\/100 {
  outline-color: rgb(113 113 122 / 1) !important;
}

.tw-outline-zinc-500\/15 {
  outline-color: rgb(113 113 122 / 0.15) !important;
}

.tw-outline-zinc-500\/20 {
  outline-color: rgb(113 113 122 / 0.2) !important;
}

.tw-outline-zinc-500\/25 {
  outline-color: rgb(113 113 122 / 0.25) !important;
}

.tw-outline-zinc-500\/30 {
  outline-color: rgb(113 113 122 / 0.3) !important;
}

.tw-outline-zinc-500\/35 {
  outline-color: rgb(113 113 122 / 0.35) !important;
}

.tw-outline-zinc-500\/40 {
  outline-color: rgb(113 113 122 / 0.4) !important;
}

.tw-outline-zinc-500\/45 {
  outline-color: rgb(113 113 122 / 0.45) !important;
}

.tw-outline-zinc-500\/5 {
  outline-color: rgb(113 113 122 / 0.05) !important;
}

.tw-outline-zinc-500\/50 {
  outline-color: rgb(113 113 122 / 0.5) !important;
}

.tw-outline-zinc-500\/55 {
  outline-color: rgb(113 113 122 / 0.55) !important;
}

.tw-outline-zinc-500\/60 {
  outline-color: rgb(113 113 122 / 0.6) !important;
}

.tw-outline-zinc-500\/65 {
  outline-color: rgb(113 113 122 / 0.65) !important;
}

.tw-outline-zinc-500\/70 {
  outline-color: rgb(113 113 122 / 0.7) !important;
}

.tw-outline-zinc-500\/75 {
  outline-color: rgb(113 113 122 / 0.75) !important;
}

.tw-outline-zinc-500\/80 {
  outline-color: rgb(113 113 122 / 0.8) !important;
}

.tw-outline-zinc-500\/85 {
  outline-color: rgb(113 113 122 / 0.85) !important;
}

.tw-outline-zinc-500\/90 {
  outline-color: rgb(113 113 122 / 0.9) !important;
}

.tw-outline-zinc-500\/95 {
  outline-color: rgb(113 113 122 / 0.95) !important;
}

.tw-outline-zinc-600 {
  outline-color: #52525b !important;
}

.tw-outline-zinc-600\/0 {
  outline-color: rgb(82 82 91 / 0) !important;
}

.tw-outline-zinc-600\/10 {
  outline-color: rgb(82 82 91 / 0.1) !important;
}

.tw-outline-zinc-600\/100 {
  outline-color: rgb(82 82 91 / 1) !important;
}

.tw-outline-zinc-600\/15 {
  outline-color: rgb(82 82 91 / 0.15) !important;
}

.tw-outline-zinc-600\/20 {
  outline-color: rgb(82 82 91 / 0.2) !important;
}

.tw-outline-zinc-600\/25 {
  outline-color: rgb(82 82 91 / 0.25) !important;
}

.tw-outline-zinc-600\/30 {
  outline-color: rgb(82 82 91 / 0.3) !important;
}

.tw-outline-zinc-600\/35 {
  outline-color: rgb(82 82 91 / 0.35) !important;
}

.tw-outline-zinc-600\/40 {
  outline-color: rgb(82 82 91 / 0.4) !important;
}

.tw-outline-zinc-600\/45 {
  outline-color: rgb(82 82 91 / 0.45) !important;
}

.tw-outline-zinc-600\/5 {
  outline-color: rgb(82 82 91 / 0.05) !important;
}

.tw-outline-zinc-600\/50 {
  outline-color: rgb(82 82 91 / 0.5) !important;
}

.tw-outline-zinc-600\/55 {
  outline-color: rgb(82 82 91 / 0.55) !important;
}

.tw-outline-zinc-600\/60 {
  outline-color: rgb(82 82 91 / 0.6) !important;
}

.tw-outline-zinc-600\/65 {
  outline-color: rgb(82 82 91 / 0.65) !important;
}

.tw-outline-zinc-600\/70 {
  outline-color: rgb(82 82 91 / 0.7) !important;
}

.tw-outline-zinc-600\/75 {
  outline-color: rgb(82 82 91 / 0.75) !important;
}

.tw-outline-zinc-600\/80 {
  outline-color: rgb(82 82 91 / 0.8) !important;
}

.tw-outline-zinc-600\/85 {
  outline-color: rgb(82 82 91 / 0.85) !important;
}

.tw-outline-zinc-600\/90 {
  outline-color: rgb(82 82 91 / 0.9) !important;
}

.tw-outline-zinc-600\/95 {
  outline-color: rgb(82 82 91 / 0.95) !important;
}

.tw-outline-zinc-700 {
  outline-color: #3f3f46 !important;
}

.tw-outline-zinc-700\/0 {
  outline-color: rgb(63 63 70 / 0) !important;
}

.tw-outline-zinc-700\/10 {
  outline-color: rgb(63 63 70 / 0.1) !important;
}

.tw-outline-zinc-700\/100 {
  outline-color: rgb(63 63 70 / 1) !important;
}

.tw-outline-zinc-700\/15 {
  outline-color: rgb(63 63 70 / 0.15) !important;
}

.tw-outline-zinc-700\/20 {
  outline-color: rgb(63 63 70 / 0.2) !important;
}

.tw-outline-zinc-700\/25 {
  outline-color: rgb(63 63 70 / 0.25) !important;
}

.tw-outline-zinc-700\/30 {
  outline-color: rgb(63 63 70 / 0.3) !important;
}

.tw-outline-zinc-700\/35 {
  outline-color: rgb(63 63 70 / 0.35) !important;
}

.tw-outline-zinc-700\/40 {
  outline-color: rgb(63 63 70 / 0.4) !important;
}

.tw-outline-zinc-700\/45 {
  outline-color: rgb(63 63 70 / 0.45) !important;
}

.tw-outline-zinc-700\/5 {
  outline-color: rgb(63 63 70 / 0.05) !important;
}

.tw-outline-zinc-700\/50 {
  outline-color: rgb(63 63 70 / 0.5) !important;
}

.tw-outline-zinc-700\/55 {
  outline-color: rgb(63 63 70 / 0.55) !important;
}

.tw-outline-zinc-700\/60 {
  outline-color: rgb(63 63 70 / 0.6) !important;
}

.tw-outline-zinc-700\/65 {
  outline-color: rgb(63 63 70 / 0.65) !important;
}

.tw-outline-zinc-700\/70 {
  outline-color: rgb(63 63 70 / 0.7) !important;
}

.tw-outline-zinc-700\/75 {
  outline-color: rgb(63 63 70 / 0.75) !important;
}

.tw-outline-zinc-700\/80 {
  outline-color: rgb(63 63 70 / 0.8) !important;
}

.tw-outline-zinc-700\/85 {
  outline-color: rgb(63 63 70 / 0.85) !important;
}

.tw-outline-zinc-700\/90 {
  outline-color: rgb(63 63 70 / 0.9) !important;
}

.tw-outline-zinc-700\/95 {
  outline-color: rgb(63 63 70 / 0.95) !important;
}

.tw-outline-zinc-800 {
  outline-color: #27272a !important;
}

.tw-outline-zinc-800\/0 {
  outline-color: rgb(39 39 42 / 0) !important;
}

.tw-outline-zinc-800\/10 {
  outline-color: rgb(39 39 42 / 0.1) !important;
}

.tw-outline-zinc-800\/100 {
  outline-color: rgb(39 39 42 / 1) !important;
}

.tw-outline-zinc-800\/15 {
  outline-color: rgb(39 39 42 / 0.15) !important;
}

.tw-outline-zinc-800\/20 {
  outline-color: rgb(39 39 42 / 0.2) !important;
}

.tw-outline-zinc-800\/25 {
  outline-color: rgb(39 39 42 / 0.25) !important;
}

.tw-outline-zinc-800\/30 {
  outline-color: rgb(39 39 42 / 0.3) !important;
}

.tw-outline-zinc-800\/35 {
  outline-color: rgb(39 39 42 / 0.35) !important;
}

.tw-outline-zinc-800\/40 {
  outline-color: rgb(39 39 42 / 0.4) !important;
}

.tw-outline-zinc-800\/45 {
  outline-color: rgb(39 39 42 / 0.45) !important;
}

.tw-outline-zinc-800\/5 {
  outline-color: rgb(39 39 42 / 0.05) !important;
}

.tw-outline-zinc-800\/50 {
  outline-color: rgb(39 39 42 / 0.5) !important;
}

.tw-outline-zinc-800\/55 {
  outline-color: rgb(39 39 42 / 0.55) !important;
}

.tw-outline-zinc-800\/60 {
  outline-color: rgb(39 39 42 / 0.6) !important;
}

.tw-outline-zinc-800\/65 {
  outline-color: rgb(39 39 42 / 0.65) !important;
}

.tw-outline-zinc-800\/70 {
  outline-color: rgb(39 39 42 / 0.7) !important;
}

.tw-outline-zinc-800\/75 {
  outline-color: rgb(39 39 42 / 0.75) !important;
}

.tw-outline-zinc-800\/80 {
  outline-color: rgb(39 39 42 / 0.8) !important;
}

.tw-outline-zinc-800\/85 {
  outline-color: rgb(39 39 42 / 0.85) !important;
}

.tw-outline-zinc-800\/90 {
  outline-color: rgb(39 39 42 / 0.9) !important;
}

.tw-outline-zinc-800\/95 {
  outline-color: rgb(39 39 42 / 0.95) !important;
}

.tw-outline-zinc-900 {
  outline-color: #18181b !important;
}

.tw-outline-zinc-900\/0 {
  outline-color: rgb(24 24 27 / 0) !important;
}

.tw-outline-zinc-900\/10 {
  outline-color: rgb(24 24 27 / 0.1) !important;
}

.tw-outline-zinc-900\/100 {
  outline-color: rgb(24 24 27 / 1) !important;
}

.tw-outline-zinc-900\/15 {
  outline-color: rgb(24 24 27 / 0.15) !important;
}

.tw-outline-zinc-900\/20 {
  outline-color: rgb(24 24 27 / 0.2) !important;
}

.tw-outline-zinc-900\/25 {
  outline-color: rgb(24 24 27 / 0.25) !important;
}

.tw-outline-zinc-900\/30 {
  outline-color: rgb(24 24 27 / 0.3) !important;
}

.tw-outline-zinc-900\/35 {
  outline-color: rgb(24 24 27 / 0.35) !important;
}

.tw-outline-zinc-900\/40 {
  outline-color: rgb(24 24 27 / 0.4) !important;
}

.tw-outline-zinc-900\/45 {
  outline-color: rgb(24 24 27 / 0.45) !important;
}

.tw-outline-zinc-900\/5 {
  outline-color: rgb(24 24 27 / 0.05) !important;
}

.tw-outline-zinc-900\/50 {
  outline-color: rgb(24 24 27 / 0.5) !important;
}

.tw-outline-zinc-900\/55 {
  outline-color: rgb(24 24 27 / 0.55) !important;
}

.tw-outline-zinc-900\/60 {
  outline-color: rgb(24 24 27 / 0.6) !important;
}

.tw-outline-zinc-900\/65 {
  outline-color: rgb(24 24 27 / 0.65) !important;
}

.tw-outline-zinc-900\/70 {
  outline-color: rgb(24 24 27 / 0.7) !important;
}

.tw-outline-zinc-900\/75 {
  outline-color: rgb(24 24 27 / 0.75) !important;
}

.tw-outline-zinc-900\/80 {
  outline-color: rgb(24 24 27 / 0.8) !important;
}

.tw-outline-zinc-900\/85 {
  outline-color: rgb(24 24 27 / 0.85) !important;
}

.tw-outline-zinc-900\/90 {
  outline-color: rgb(24 24 27 / 0.9) !important;
}

.tw-outline-zinc-900\/95 {
  outline-color: rgb(24 24 27 / 0.95) !important;
}

.tw-outline-zinc-950 {
  outline-color: #09090b !important;
}

.tw-outline-zinc-950\/0 {
  outline-color: rgb(9 9 11 / 0) !important;
}

.tw-outline-zinc-950\/10 {
  outline-color: rgb(9 9 11 / 0.1) !important;
}

.tw-outline-zinc-950\/100 {
  outline-color: rgb(9 9 11 / 1) !important;
}

.tw-outline-zinc-950\/15 {
  outline-color: rgb(9 9 11 / 0.15) !important;
}

.tw-outline-zinc-950\/20 {
  outline-color: rgb(9 9 11 / 0.2) !important;
}

.tw-outline-zinc-950\/25 {
  outline-color: rgb(9 9 11 / 0.25) !important;
}

.tw-outline-zinc-950\/30 {
  outline-color: rgb(9 9 11 / 0.3) !important;
}

.tw-outline-zinc-950\/35 {
  outline-color: rgb(9 9 11 / 0.35) !important;
}

.tw-outline-zinc-950\/40 {
  outline-color: rgb(9 9 11 / 0.4) !important;
}

.tw-outline-zinc-950\/45 {
  outline-color: rgb(9 9 11 / 0.45) !important;
}

.tw-outline-zinc-950\/5 {
  outline-color: rgb(9 9 11 / 0.05) !important;
}

.tw-outline-zinc-950\/50 {
  outline-color: rgb(9 9 11 / 0.5) !important;
}

.tw-outline-zinc-950\/55 {
  outline-color: rgb(9 9 11 / 0.55) !important;
}

.tw-outline-zinc-950\/60 {
  outline-color: rgb(9 9 11 / 0.6) !important;
}

.tw-outline-zinc-950\/65 {
  outline-color: rgb(9 9 11 / 0.65) !important;
}

.tw-outline-zinc-950\/70 {
  outline-color: rgb(9 9 11 / 0.7) !important;
}

.tw-outline-zinc-950\/75 {
  outline-color: rgb(9 9 11 / 0.75) !important;
}

.tw-outline-zinc-950\/80 {
  outline-color: rgb(9 9 11 / 0.8) !important;
}

.tw-outline-zinc-950\/85 {
  outline-color: rgb(9 9 11 / 0.85) !important;
}

.tw-outline-zinc-950\/90 {
  outline-color: rgb(9 9 11 / 0.9) !important;
}

.tw-outline-zinc-950\/95 {
  outline-color: rgb(9 9 11 / 0.95) !important;
}

.tw-ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-0 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(0px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-4 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-8 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color) !important;
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(8px + var(--tw-ring-offset-width)) var(--tw-ring-color) !important;
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000) !important;
}

.tw-ring-inset {
  --tw-ring-inset: inset !important;
}

.tw-ring-amber-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 243 199 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-100\/0 {
  --tw-ring-color: rgb(254 243 199 / 0) !important;
}

.tw-ring-amber-100\/10 {
  --tw-ring-color: rgb(254 243 199 / 0.1) !important;
}

.tw-ring-amber-100\/100 {
  --tw-ring-color: rgb(254 243 199 / 1) !important;
}

.tw-ring-amber-100\/15 {
  --tw-ring-color: rgb(254 243 199 / 0.15) !important;
}

.tw-ring-amber-100\/20 {
  --tw-ring-color: rgb(254 243 199 / 0.2) !important;
}

.tw-ring-amber-100\/25 {
  --tw-ring-color: rgb(254 243 199 / 0.25) !important;
}

.tw-ring-amber-100\/30 {
  --tw-ring-color: rgb(254 243 199 / 0.3) !important;
}

.tw-ring-amber-100\/35 {
  --tw-ring-color: rgb(254 243 199 / 0.35) !important;
}

.tw-ring-amber-100\/40 {
  --tw-ring-color: rgb(254 243 199 / 0.4) !important;
}

.tw-ring-amber-100\/45 {
  --tw-ring-color: rgb(254 243 199 / 0.45) !important;
}

.tw-ring-amber-100\/5 {
  --tw-ring-color: rgb(254 243 199 / 0.05) !important;
}

.tw-ring-amber-100\/50 {
  --tw-ring-color: rgb(254 243 199 / 0.5) !important;
}

.tw-ring-amber-100\/55 {
  --tw-ring-color: rgb(254 243 199 / 0.55) !important;
}

.tw-ring-amber-100\/60 {
  --tw-ring-color: rgb(254 243 199 / 0.6) !important;
}

.tw-ring-amber-100\/65 {
  --tw-ring-color: rgb(254 243 199 / 0.65) !important;
}

.tw-ring-amber-100\/70 {
  --tw-ring-color: rgb(254 243 199 / 0.7) !important;
}

.tw-ring-amber-100\/75 {
  --tw-ring-color: rgb(254 243 199 / 0.75) !important;
}

.tw-ring-amber-100\/80 {
  --tw-ring-color: rgb(254 243 199 / 0.8) !important;
}

.tw-ring-amber-100\/85 {
  --tw-ring-color: rgb(254 243 199 / 0.85) !important;
}

.tw-ring-amber-100\/90 {
  --tw-ring-color: rgb(254 243 199 / 0.9) !important;
}

.tw-ring-amber-100\/95 {
  --tw-ring-color: rgb(254 243 199 / 0.95) !important;
}

.tw-ring-amber-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(253 230 138 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-200\/0 {
  --tw-ring-color: rgb(253 230 138 / 0) !important;
}

.tw-ring-amber-200\/10 {
  --tw-ring-color: rgb(253 230 138 / 0.1) !important;
}

.tw-ring-amber-200\/100 {
  --tw-ring-color: rgb(253 230 138 / 1) !important;
}

.tw-ring-amber-200\/15 {
  --tw-ring-color: rgb(253 230 138 / 0.15) !important;
}

.tw-ring-amber-200\/20 {
  --tw-ring-color: rgb(253 230 138 / 0.2) !important;
}

.tw-ring-amber-200\/25 {
  --tw-ring-color: rgb(253 230 138 / 0.25) !important;
}

.tw-ring-amber-200\/30 {
  --tw-ring-color: rgb(253 230 138 / 0.3) !important;
}

.tw-ring-amber-200\/35 {
  --tw-ring-color: rgb(253 230 138 / 0.35) !important;
}

.tw-ring-amber-200\/40 {
  --tw-ring-color: rgb(253 230 138 / 0.4) !important;
}

.tw-ring-amber-200\/45 {
  --tw-ring-color: rgb(253 230 138 / 0.45) !important;
}

.tw-ring-amber-200\/5 {
  --tw-ring-color: rgb(253 230 138 / 0.05) !important;
}

.tw-ring-amber-200\/50 {
  --tw-ring-color: rgb(253 230 138 / 0.5) !important;
}

.tw-ring-amber-200\/55 {
  --tw-ring-color: rgb(253 230 138 / 0.55) !important;
}

.tw-ring-amber-200\/60 {
  --tw-ring-color: rgb(253 230 138 / 0.6) !important;
}

.tw-ring-amber-200\/65 {
  --tw-ring-color: rgb(253 230 138 / 0.65) !important;
}

.tw-ring-amber-200\/70 {
  --tw-ring-color: rgb(253 230 138 / 0.7) !important;
}

.tw-ring-amber-200\/75 {
  --tw-ring-color: rgb(253 230 138 / 0.75) !important;
}

.tw-ring-amber-200\/80 {
  --tw-ring-color: rgb(253 230 138 / 0.8) !important;
}

.tw-ring-amber-200\/85 {
  --tw-ring-color: rgb(253 230 138 / 0.85) !important;
}

.tw-ring-amber-200\/90 {
  --tw-ring-color: rgb(253 230 138 / 0.9) !important;
}

.tw-ring-amber-200\/95 {
  --tw-ring-color: rgb(253 230 138 / 0.95) !important;
}

.tw-ring-amber-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(252 211 77 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-300\/0 {
  --tw-ring-color: rgb(252 211 77 / 0) !important;
}

.tw-ring-amber-300\/10 {
  --tw-ring-color: rgb(252 211 77 / 0.1) !important;
}

.tw-ring-amber-300\/100 {
  --tw-ring-color: rgb(252 211 77 / 1) !important;
}

.tw-ring-amber-300\/15 {
  --tw-ring-color: rgb(252 211 77 / 0.15) !important;
}

.tw-ring-amber-300\/20 {
  --tw-ring-color: rgb(252 211 77 / 0.2) !important;
}

.tw-ring-amber-300\/25 {
  --tw-ring-color: rgb(252 211 77 / 0.25) !important;
}

.tw-ring-amber-300\/30 {
  --tw-ring-color: rgb(252 211 77 / 0.3) !important;
}

.tw-ring-amber-300\/35 {
  --tw-ring-color: rgb(252 211 77 / 0.35) !important;
}

.tw-ring-amber-300\/40 {
  --tw-ring-color: rgb(252 211 77 / 0.4) !important;
}

.tw-ring-amber-300\/45 {
  --tw-ring-color: rgb(252 211 77 / 0.45) !important;
}

.tw-ring-amber-300\/5 {
  --tw-ring-color: rgb(252 211 77 / 0.05) !important;
}

.tw-ring-amber-300\/50 {
  --tw-ring-color: rgb(252 211 77 / 0.5) !important;
}

.tw-ring-amber-300\/55 {
  --tw-ring-color: rgb(252 211 77 / 0.55) !important;
}

.tw-ring-amber-300\/60 {
  --tw-ring-color: rgb(252 211 77 / 0.6) !important;
}

.tw-ring-amber-300\/65 {
  --tw-ring-color: rgb(252 211 77 / 0.65) !important;
}

.tw-ring-amber-300\/70 {
  --tw-ring-color: rgb(252 211 77 / 0.7) !important;
}

.tw-ring-amber-300\/75 {
  --tw-ring-color: rgb(252 211 77 / 0.75) !important;
}

.tw-ring-amber-300\/80 {
  --tw-ring-color: rgb(252 211 77 / 0.8) !important;
}

.tw-ring-amber-300\/85 {
  --tw-ring-color: rgb(252 211 77 / 0.85) !important;
}

.tw-ring-amber-300\/90 {
  --tw-ring-color: rgb(252 211 77 / 0.9) !important;
}

.tw-ring-amber-300\/95 {
  --tw-ring-color: rgb(252 211 77 / 0.95) !important;
}

.tw-ring-amber-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(251 191 36 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-400\/0 {
  --tw-ring-color: rgb(251 191 36 / 0) !important;
}

.tw-ring-amber-400\/10 {
  --tw-ring-color: rgb(251 191 36 / 0.1) !important;
}

.tw-ring-amber-400\/100 {
  --tw-ring-color: rgb(251 191 36 / 1) !important;
}

.tw-ring-amber-400\/15 {
  --tw-ring-color: rgb(251 191 36 / 0.15) !important;
}

.tw-ring-amber-400\/20 {
  --tw-ring-color: rgb(251 191 36 / 0.2) !important;
}

.tw-ring-amber-400\/25 {
  --tw-ring-color: rgb(251 191 36 / 0.25) !important;
}

.tw-ring-amber-400\/30 {
  --tw-ring-color: rgb(251 191 36 / 0.3) !important;
}

.tw-ring-amber-400\/35 {
  --tw-ring-color: rgb(251 191 36 / 0.35) !important;
}

.tw-ring-amber-400\/40 {
  --tw-ring-color: rgb(251 191 36 / 0.4) !important;
}

.tw-ring-amber-400\/45 {
  --tw-ring-color: rgb(251 191 36 / 0.45) !important;
}

.tw-ring-amber-400\/5 {
  --tw-ring-color: rgb(251 191 36 / 0.05) !important;
}

.tw-ring-amber-400\/50 {
  --tw-ring-color: rgb(251 191 36 / 0.5) !important;
}

.tw-ring-amber-400\/55 {
  --tw-ring-color: rgb(251 191 36 / 0.55) !important;
}

.tw-ring-amber-400\/60 {
  --tw-ring-color: rgb(251 191 36 / 0.6) !important;
}

.tw-ring-amber-400\/65 {
  --tw-ring-color: rgb(251 191 36 / 0.65) !important;
}

.tw-ring-amber-400\/70 {
  --tw-ring-color: rgb(251 191 36 / 0.7) !important;
}

.tw-ring-amber-400\/75 {
  --tw-ring-color: rgb(251 191 36 / 0.75) !important;
}

.tw-ring-amber-400\/80 {
  --tw-ring-color: rgb(251 191 36 / 0.8) !important;
}

.tw-ring-amber-400\/85 {
  --tw-ring-color: rgb(251 191 36 / 0.85) !important;
}

.tw-ring-amber-400\/90 {
  --tw-ring-color: rgb(251 191 36 / 0.9) !important;
}

.tw-ring-amber-400\/95 {
  --tw-ring-color: rgb(251 191 36 / 0.95) !important;
}

.tw-ring-amber-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(255 251 235 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-50\/0 {
  --tw-ring-color: rgb(255 251 235 / 0) !important;
}

.tw-ring-amber-50\/10 {
  --tw-ring-color: rgb(255 251 235 / 0.1) !important;
}

.tw-ring-amber-50\/100 {
  --tw-ring-color: rgb(255 251 235 / 1) !important;
}

.tw-ring-amber-50\/15 {
  --tw-ring-color: rgb(255 251 235 / 0.15) !important;
}

.tw-ring-amber-50\/20 {
  --tw-ring-color: rgb(255 251 235 / 0.2) !important;
}

.tw-ring-amber-50\/25 {
  --tw-ring-color: rgb(255 251 235 / 0.25) !important;
}

.tw-ring-amber-50\/30 {
  --tw-ring-color: rgb(255 251 235 / 0.3) !important;
}

.tw-ring-amber-50\/35 {
  --tw-ring-color: rgb(255 251 235 / 0.35) !important;
}

.tw-ring-amber-50\/40 {
  --tw-ring-color: rgb(255 251 235 / 0.4) !important;
}

.tw-ring-amber-50\/45 {
  --tw-ring-color: rgb(255 251 235 / 0.45) !important;
}

.tw-ring-amber-50\/5 {
  --tw-ring-color: rgb(255 251 235 / 0.05) !important;
}

.tw-ring-amber-50\/50 {
  --tw-ring-color: rgb(255 251 235 / 0.5) !important;
}

.tw-ring-amber-50\/55 {
  --tw-ring-color: rgb(255 251 235 / 0.55) !important;
}

.tw-ring-amber-50\/60 {
  --tw-ring-color: rgb(255 251 235 / 0.6) !important;
}

.tw-ring-amber-50\/65 {
  --tw-ring-color: rgb(255 251 235 / 0.65) !important;
}

.tw-ring-amber-50\/70 {
  --tw-ring-color: rgb(255 251 235 / 0.7) !important;
}

.tw-ring-amber-50\/75 {
  --tw-ring-color: rgb(255 251 235 / 0.75) !important;
}

.tw-ring-amber-50\/80 {
  --tw-ring-color: rgb(255 251 235 / 0.8) !important;
}

.tw-ring-amber-50\/85 {
  --tw-ring-color: rgb(255 251 235 / 0.85) !important;
}

.tw-ring-amber-50\/90 {
  --tw-ring-color: rgb(255 251 235 / 0.9) !important;
}

.tw-ring-amber-50\/95 {
  --tw-ring-color: rgb(255 251 235 / 0.95) !important;
}

.tw-ring-amber-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(245 158 11 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-500\/0 {
  --tw-ring-color: rgb(245 158 11 / 0) !important;
}

.tw-ring-amber-500\/10 {
  --tw-ring-color: rgb(245 158 11 / 0.1) !important;
}

.tw-ring-amber-500\/100 {
  --tw-ring-color: rgb(245 158 11 / 1) !important;
}

.tw-ring-amber-500\/15 {
  --tw-ring-color: rgb(245 158 11 / 0.15) !important;
}

.tw-ring-amber-500\/20 {
  --tw-ring-color: rgb(245 158 11 / 0.2) !important;
}

.tw-ring-amber-500\/25 {
  --tw-ring-color: rgb(245 158 11 / 0.25) !important;
}

.tw-ring-amber-500\/30 {
  --tw-ring-color: rgb(245 158 11 / 0.3) !important;
}

.tw-ring-amber-500\/35 {
  --tw-ring-color: rgb(245 158 11 / 0.35) !important;
}

.tw-ring-amber-500\/40 {
  --tw-ring-color: rgb(245 158 11 / 0.4) !important;
}

.tw-ring-amber-500\/45 {
  --tw-ring-color: rgb(245 158 11 / 0.45) !important;
}

.tw-ring-amber-500\/5 {
  --tw-ring-color: rgb(245 158 11 / 0.05) !important;
}

.tw-ring-amber-500\/50 {
  --tw-ring-color: rgb(245 158 11 / 0.5) !important;
}

.tw-ring-amber-500\/55 {
  --tw-ring-color: rgb(245 158 11 / 0.55) !important;
}

.tw-ring-amber-500\/60 {
  --tw-ring-color: rgb(245 158 11 / 0.6) !important;
}

.tw-ring-amber-500\/65 {
  --tw-ring-color: rgb(245 158 11 / 0.65) !important;
}

.tw-ring-amber-500\/70 {
  --tw-ring-color: rgb(245 158 11 / 0.7) !important;
}

.tw-ring-amber-500\/75 {
  --tw-ring-color: rgb(245 158 11 / 0.75) !important;
}

.tw-ring-amber-500\/80 {
  --tw-ring-color: rgb(245 158 11 / 0.8) !important;
}

.tw-ring-amber-500\/85 {
  --tw-ring-color: rgb(245 158 11 / 0.85) !important;
}

.tw-ring-amber-500\/90 {
  --tw-ring-color: rgb(245 158 11 / 0.9) !important;
}

.tw-ring-amber-500\/95 {
  --tw-ring-color: rgb(245 158 11 / 0.95) !important;
}

.tw-ring-amber-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(217 119 6 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-600\/0 {
  --tw-ring-color: rgb(217 119 6 / 0) !important;
}

.tw-ring-amber-600\/10 {
  --tw-ring-color: rgb(217 119 6 / 0.1) !important;
}

.tw-ring-amber-600\/100 {
  --tw-ring-color: rgb(217 119 6 / 1) !important;
}

.tw-ring-amber-600\/15 {
  --tw-ring-color: rgb(217 119 6 / 0.15) !important;
}

.tw-ring-amber-600\/20 {
  --tw-ring-color: rgb(217 119 6 / 0.2) !important;
}

.tw-ring-amber-600\/25 {
  --tw-ring-color: rgb(217 119 6 / 0.25) !important;
}

.tw-ring-amber-600\/30 {
  --tw-ring-color: rgb(217 119 6 / 0.3) !important;
}

.tw-ring-amber-600\/35 {
  --tw-ring-color: rgb(217 119 6 / 0.35) !important;
}

.tw-ring-amber-600\/40 {
  --tw-ring-color: rgb(217 119 6 / 0.4) !important;
}

.tw-ring-amber-600\/45 {
  --tw-ring-color: rgb(217 119 6 / 0.45) !important;
}

.tw-ring-amber-600\/5 {
  --tw-ring-color: rgb(217 119 6 / 0.05) !important;
}

.tw-ring-amber-600\/50 {
  --tw-ring-color: rgb(217 119 6 / 0.5) !important;
}

.tw-ring-amber-600\/55 {
  --tw-ring-color: rgb(217 119 6 / 0.55) !important;
}

.tw-ring-amber-600\/60 {
  --tw-ring-color: rgb(217 119 6 / 0.6) !important;
}

.tw-ring-amber-600\/65 {
  --tw-ring-color: rgb(217 119 6 / 0.65) !important;
}

.tw-ring-amber-600\/70 {
  --tw-ring-color: rgb(217 119 6 / 0.7) !important;
}

.tw-ring-amber-600\/75 {
  --tw-ring-color: rgb(217 119 6 / 0.75) !important;
}

.tw-ring-amber-600\/80 {
  --tw-ring-color: rgb(217 119 6 / 0.8) !important;
}

.tw-ring-amber-600\/85 {
  --tw-ring-color: rgb(217 119 6 / 0.85) !important;
}

.tw-ring-amber-600\/90 {
  --tw-ring-color: rgb(217 119 6 / 0.9) !important;
}

.tw-ring-amber-600\/95 {
  --tw-ring-color: rgb(217 119 6 / 0.95) !important;
}

.tw-ring-amber-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(180 83 9 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-700\/0 {
  --tw-ring-color: rgb(180 83 9 / 0) !important;
}

.tw-ring-amber-700\/10 {
  --tw-ring-color: rgb(180 83 9 / 0.1) !important;
}

.tw-ring-amber-700\/100 {
  --tw-ring-color: rgb(180 83 9 / 1) !important;
}

.tw-ring-amber-700\/15 {
  --tw-ring-color: rgb(180 83 9 / 0.15) !important;
}

.tw-ring-amber-700\/20 {
  --tw-ring-color: rgb(180 83 9 / 0.2) !important;
}

.tw-ring-amber-700\/25 {
  --tw-ring-color: rgb(180 83 9 / 0.25) !important;
}

.tw-ring-amber-700\/30 {
  --tw-ring-color: rgb(180 83 9 / 0.3) !important;
}

.tw-ring-amber-700\/35 {
  --tw-ring-color: rgb(180 83 9 / 0.35) !important;
}

.tw-ring-amber-700\/40 {
  --tw-ring-color: rgb(180 83 9 / 0.4) !important;
}

.tw-ring-amber-700\/45 {
  --tw-ring-color: rgb(180 83 9 / 0.45) !important;
}

.tw-ring-amber-700\/5 {
  --tw-ring-color: rgb(180 83 9 / 0.05) !important;
}

.tw-ring-amber-700\/50 {
  --tw-ring-color: rgb(180 83 9 / 0.5) !important;
}

.tw-ring-amber-700\/55 {
  --tw-ring-color: rgb(180 83 9 / 0.55) !important;
}

.tw-ring-amber-700\/60 {
  --tw-ring-color: rgb(180 83 9 / 0.6) !important;
}

.tw-ring-amber-700\/65 {
  --tw-ring-color: rgb(180 83 9 / 0.65) !important;
}

.tw-ring-amber-700\/70 {
  --tw-ring-color: rgb(180 83 9 / 0.7) !important;
}

.tw-ring-amber-700\/75 {
  --tw-ring-color: rgb(180 83 9 / 0.75) !important;
}

.tw-ring-amber-700\/80 {
  --tw-ring-color: rgb(180 83 9 / 0.8) !important;
}

.tw-ring-amber-700\/85 {
  --tw-ring-color: rgb(180 83 9 / 0.85) !important;
}

.tw-ring-amber-700\/90 {
  --tw-ring-color: rgb(180 83 9 / 0.9) !important;
}

.tw-ring-amber-700\/95 {
  --tw-ring-color: rgb(180 83 9 / 0.95) !important;
}

.tw-ring-amber-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(146 64 14 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-800\/0 {
  --tw-ring-color: rgb(146 64 14 / 0) !important;
}

.tw-ring-amber-800\/10 {
  --tw-ring-color: rgb(146 64 14 / 0.1) !important;
}

.tw-ring-amber-800\/100 {
  --tw-ring-color: rgb(146 64 14 / 1) !important;
}

.tw-ring-amber-800\/15 {
  --tw-ring-color: rgb(146 64 14 / 0.15) !important;
}

.tw-ring-amber-800\/20 {
  --tw-ring-color: rgb(146 64 14 / 0.2) !important;
}

.tw-ring-amber-800\/25 {
  --tw-ring-color: rgb(146 64 14 / 0.25) !important;
}

.tw-ring-amber-800\/30 {
  --tw-ring-color: rgb(146 64 14 / 0.3) !important;
}

.tw-ring-amber-800\/35 {
  --tw-ring-color: rgb(146 64 14 / 0.35) !important;
}

.tw-ring-amber-800\/40 {
  --tw-ring-color: rgb(146 64 14 / 0.4) !important;
}

.tw-ring-amber-800\/45 {
  --tw-ring-color: rgb(146 64 14 / 0.45) !important;
}

.tw-ring-amber-800\/5 {
  --tw-ring-color: rgb(146 64 14 / 0.05) !important;
}

.tw-ring-amber-800\/50 {
  --tw-ring-color: rgb(146 64 14 / 0.5) !important;
}

.tw-ring-amber-800\/55 {
  --tw-ring-color: rgb(146 64 14 / 0.55) !important;
}

.tw-ring-amber-800\/60 {
  --tw-ring-color: rgb(146 64 14 / 0.6) !important;
}

.tw-ring-amber-800\/65 {
  --tw-ring-color: rgb(146 64 14 / 0.65) !important;
}

.tw-ring-amber-800\/70 {
  --tw-ring-color: rgb(146 64 14 / 0.7) !important;
}

.tw-ring-amber-800\/75 {
  --tw-ring-color: rgb(146 64 14 / 0.75) !important;
}

.tw-ring-amber-800\/80 {
  --tw-ring-color: rgb(146 64 14 / 0.8) !important;
}

.tw-ring-amber-800\/85 {
  --tw-ring-color: rgb(146 64 14 / 0.85) !important;
}

.tw-ring-amber-800\/90 {
  --tw-ring-color: rgb(146 64 14 / 0.9) !important;
}

.tw-ring-amber-800\/95 {
  --tw-ring-color: rgb(146 64 14 / 0.95) !important;
}

.tw-ring-amber-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(120 53 15 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-900\/0 {
  --tw-ring-color: rgb(120 53 15 / 0) !important;
}

.tw-ring-amber-900\/10 {
  --tw-ring-color: rgb(120 53 15 / 0.1) !important;
}

.tw-ring-amber-900\/100 {
  --tw-ring-color: rgb(120 53 15 / 1) !important;
}

.tw-ring-amber-900\/15 {
  --tw-ring-color: rgb(120 53 15 / 0.15) !important;
}

.tw-ring-amber-900\/20 {
  --tw-ring-color: rgb(120 53 15 / 0.2) !important;
}

.tw-ring-amber-900\/25 {
  --tw-ring-color: rgb(120 53 15 / 0.25) !important;
}

.tw-ring-amber-900\/30 {
  --tw-ring-color: rgb(120 53 15 / 0.3) !important;
}

.tw-ring-amber-900\/35 {
  --tw-ring-color: rgb(120 53 15 / 0.35) !important;
}

.tw-ring-amber-900\/40 {
  --tw-ring-color: rgb(120 53 15 / 0.4) !important;
}

.tw-ring-amber-900\/45 {
  --tw-ring-color: rgb(120 53 15 / 0.45) !important;
}

.tw-ring-amber-900\/5 {
  --tw-ring-color: rgb(120 53 15 / 0.05) !important;
}

.tw-ring-amber-900\/50 {
  --tw-ring-color: rgb(120 53 15 / 0.5) !important;
}

.tw-ring-amber-900\/55 {
  --tw-ring-color: rgb(120 53 15 / 0.55) !important;
}

.tw-ring-amber-900\/60 {
  --tw-ring-color: rgb(120 53 15 / 0.6) !important;
}

.tw-ring-amber-900\/65 {
  --tw-ring-color: rgb(120 53 15 / 0.65) !important;
}

.tw-ring-amber-900\/70 {
  --tw-ring-color: rgb(120 53 15 / 0.7) !important;
}

.tw-ring-amber-900\/75 {
  --tw-ring-color: rgb(120 53 15 / 0.75) !important;
}

.tw-ring-amber-900\/80 {
  --tw-ring-color: rgb(120 53 15 / 0.8) !important;
}

.tw-ring-amber-900\/85 {
  --tw-ring-color: rgb(120 53 15 / 0.85) !important;
}

.tw-ring-amber-900\/90 {
  --tw-ring-color: rgb(120 53 15 / 0.9) !important;
}

.tw-ring-amber-900\/95 {
  --tw-ring-color: rgb(120 53 15 / 0.95) !important;
}

.tw-ring-amber-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(69 26 3 / var(--tw-ring-opacity)) !important;
}

.tw-ring-amber-950\/0 {
  --tw-ring-color: rgb(69 26 3 / 0) !important;
}

.tw-ring-amber-950\/10 {
  --tw-ring-color: rgb(69 26 3 / 0.1) !important;
}

.tw-ring-amber-950\/100 {
  --tw-ring-color: rgb(69 26 3 / 1) !important;
}

.tw-ring-amber-950\/15 {
  --tw-ring-color: rgb(69 26 3 / 0.15) !important;
}

.tw-ring-amber-950\/20 {
  --tw-ring-color: rgb(69 26 3 / 0.2) !important;
}

.tw-ring-amber-950\/25 {
  --tw-ring-color: rgb(69 26 3 / 0.25) !important;
}

.tw-ring-amber-950\/30 {
  --tw-ring-color: rgb(69 26 3 / 0.3) !important;
}

.tw-ring-amber-950\/35 {
  --tw-ring-color: rgb(69 26 3 / 0.35) !important;
}

.tw-ring-amber-950\/40 {
  --tw-ring-color: rgb(69 26 3 / 0.4) !important;
}

.tw-ring-amber-950\/45 {
  --tw-ring-color: rgb(69 26 3 / 0.45) !important;
}

.tw-ring-amber-950\/5 {
  --tw-ring-color: rgb(69 26 3 / 0.05) !important;
}

.tw-ring-amber-950\/50 {
  --tw-ring-color: rgb(69 26 3 / 0.5) !important;
}

.tw-ring-amber-950\/55 {
  --tw-ring-color: rgb(69 26 3 / 0.55) !important;
}

.tw-ring-amber-950\/60 {
  --tw-ring-color: rgb(69 26 3 / 0.6) !important;
}

.tw-ring-amber-950\/65 {
  --tw-ring-color: rgb(69 26 3 / 0.65) !important;
}

.tw-ring-amber-950\/70 {
  --tw-ring-color: rgb(69 26 3 / 0.7) !important;
}

.tw-ring-amber-950\/75 {
  --tw-ring-color: rgb(69 26 3 / 0.75) !important;
}

.tw-ring-amber-950\/80 {
  --tw-ring-color: rgb(69 26 3 / 0.8) !important;
}

.tw-ring-amber-950\/85 {
  --tw-ring-color: rgb(69 26 3 / 0.85) !important;
}

.tw-ring-amber-950\/90 {
  --tw-ring-color: rgb(69 26 3 / 0.9) !important;
}

.tw-ring-amber-950\/95 {
  --tw-ring-color: rgb(69 26 3 / 0.95) !important;
}

.tw-ring-black {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(0 0 0 / var(--tw-ring-opacity)) !important;
}

.tw-ring-black\/0 {
  --tw-ring-color: rgb(0 0 0 / 0) !important;
}

.tw-ring-black\/10 {
  --tw-ring-color: rgb(0 0 0 / 0.1) !important;
}

.tw-ring-black\/100 {
  --tw-ring-color: rgb(0 0 0 / 1) !important;
}

.tw-ring-black\/15 {
  --tw-ring-color: rgb(0 0 0 / 0.15) !important;
}

.tw-ring-black\/20 {
  --tw-ring-color: rgb(0 0 0 / 0.2) !important;
}

.tw-ring-black\/25 {
  --tw-ring-color: rgb(0 0 0 / 0.25) !important;
}

.tw-ring-black\/30 {
  --tw-ring-color: rgb(0 0 0 / 0.3) !important;
}

.tw-ring-black\/35 {
  --tw-ring-color: rgb(0 0 0 / 0.35) !important;
}

.tw-ring-black\/40 {
  --tw-ring-color: rgb(0 0 0 / 0.4) !important;
}

.tw-ring-black\/45 {
  --tw-ring-color: rgb(0 0 0 / 0.45) !important;
}

.tw-ring-black\/5 {
  --tw-ring-color: rgb(0 0 0 / 0.05) !important;
}

.tw-ring-black\/50 {
  --tw-ring-color: rgb(0 0 0 / 0.5) !important;
}

.tw-ring-black\/55 {
  --tw-ring-color: rgb(0 0 0 / 0.55) !important;
}

.tw-ring-black\/60 {
  --tw-ring-color: rgb(0 0 0 / 0.6) !important;
}

.tw-ring-black\/65 {
  --tw-ring-color: rgb(0 0 0 / 0.65) !important;
}

.tw-ring-black\/70 {
  --tw-ring-color: rgb(0 0 0 / 0.7) !important;
}

.tw-ring-black\/75 {
  --tw-ring-color: rgb(0 0 0 / 0.75) !important;
}

.tw-ring-black\/80 {
  --tw-ring-color: rgb(0 0 0 / 0.8) !important;
}

.tw-ring-black\/85 {
  --tw-ring-color: rgb(0 0 0 / 0.85) !important;
}

.tw-ring-black\/90 {
  --tw-ring-color: rgb(0 0 0 / 0.9) !important;
}

.tw-ring-black\/95 {
  --tw-ring-color: rgb(0 0 0 / 0.95) !important;
}

.tw-ring-blue-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(219 234 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-100\/0 {
  --tw-ring-color: rgb(219 234 254 / 0) !important;
}

.tw-ring-blue-100\/10 {
  --tw-ring-color: rgb(219 234 254 / 0.1) !important;
}

.tw-ring-blue-100\/100 {
  --tw-ring-color: rgb(219 234 254 / 1) !important;
}

.tw-ring-blue-100\/15 {
  --tw-ring-color: rgb(219 234 254 / 0.15) !important;
}

.tw-ring-blue-100\/20 {
  --tw-ring-color: rgb(219 234 254 / 0.2) !important;
}

.tw-ring-blue-100\/25 {
  --tw-ring-color: rgb(219 234 254 / 0.25) !important;
}

.tw-ring-blue-100\/30 {
  --tw-ring-color: rgb(219 234 254 / 0.3) !important;
}

.tw-ring-blue-100\/35 {
  --tw-ring-color: rgb(219 234 254 / 0.35) !important;
}

.tw-ring-blue-100\/40 {
  --tw-ring-color: rgb(219 234 254 / 0.4) !important;
}

.tw-ring-blue-100\/45 {
  --tw-ring-color: rgb(219 234 254 / 0.45) !important;
}

.tw-ring-blue-100\/5 {
  --tw-ring-color: rgb(219 234 254 / 0.05) !important;
}

.tw-ring-blue-100\/50 {
  --tw-ring-color: rgb(219 234 254 / 0.5) !important;
}

.tw-ring-blue-100\/55 {
  --tw-ring-color: rgb(219 234 254 / 0.55) !important;
}

.tw-ring-blue-100\/60 {
  --tw-ring-color: rgb(219 234 254 / 0.6) !important;
}

.tw-ring-blue-100\/65 {
  --tw-ring-color: rgb(219 234 254 / 0.65) !important;
}

.tw-ring-blue-100\/70 {
  --tw-ring-color: rgb(219 234 254 / 0.7) !important;
}

.tw-ring-blue-100\/75 {
  --tw-ring-color: rgb(219 234 254 / 0.75) !important;
}

.tw-ring-blue-100\/80 {
  --tw-ring-color: rgb(219 234 254 / 0.8) !important;
}

.tw-ring-blue-100\/85 {
  --tw-ring-color: rgb(219 234 254 / 0.85) !important;
}

.tw-ring-blue-100\/90 {
  --tw-ring-color: rgb(219 234 254 / 0.9) !important;
}

.tw-ring-blue-100\/95 {
  --tw-ring-color: rgb(219 234 254 / 0.95) !important;
}

.tw-ring-blue-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(191 219 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-200\/0 {
  --tw-ring-color: rgb(191 219 254 / 0) !important;
}

.tw-ring-blue-200\/10 {
  --tw-ring-color: rgb(191 219 254 / 0.1) !important;
}

.tw-ring-blue-200\/100 {
  --tw-ring-color: rgb(191 219 254 / 1) !important;
}

.tw-ring-blue-200\/15 {
  --tw-ring-color: rgb(191 219 254 / 0.15) !important;
}

.tw-ring-blue-200\/20 {
  --tw-ring-color: rgb(191 219 254 / 0.2) !important;
}

.tw-ring-blue-200\/25 {
  --tw-ring-color: rgb(191 219 254 / 0.25) !important;
}

.tw-ring-blue-200\/30 {
  --tw-ring-color: rgb(191 219 254 / 0.3) !important;
}

.tw-ring-blue-200\/35 {
  --tw-ring-color: rgb(191 219 254 / 0.35) !important;
}

.tw-ring-blue-200\/40 {
  --tw-ring-color: rgb(191 219 254 / 0.4) !important;
}

.tw-ring-blue-200\/45 {
  --tw-ring-color: rgb(191 219 254 / 0.45) !important;
}

.tw-ring-blue-200\/5 {
  --tw-ring-color: rgb(191 219 254 / 0.05) !important;
}

.tw-ring-blue-200\/50 {
  --tw-ring-color: rgb(191 219 254 / 0.5) !important;
}

.tw-ring-blue-200\/55 {
  --tw-ring-color: rgb(191 219 254 / 0.55) !important;
}

.tw-ring-blue-200\/60 {
  --tw-ring-color: rgb(191 219 254 / 0.6) !important;
}

.tw-ring-blue-200\/65 {
  --tw-ring-color: rgb(191 219 254 / 0.65) !important;
}

.tw-ring-blue-200\/70 {
  --tw-ring-color: rgb(191 219 254 / 0.7) !important;
}

.tw-ring-blue-200\/75 {
  --tw-ring-color: rgb(191 219 254 / 0.75) !important;
}

.tw-ring-blue-200\/80 {
  --tw-ring-color: rgb(191 219 254 / 0.8) !important;
}

.tw-ring-blue-200\/85 {
  --tw-ring-color: rgb(191 219 254 / 0.85) !important;
}

.tw-ring-blue-200\/90 {
  --tw-ring-color: rgb(191 219 254 / 0.9) !important;
}

.tw-ring-blue-200\/95 {
  --tw-ring-color: rgb(191 219 254 / 0.95) !important;
}

.tw-ring-blue-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(147 197 253 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-300\/0 {
  --tw-ring-color: rgb(147 197 253 / 0) !important;
}

.tw-ring-blue-300\/10 {
  --tw-ring-color: rgb(147 197 253 / 0.1) !important;
}

.tw-ring-blue-300\/100 {
  --tw-ring-color: rgb(147 197 253 / 1) !important;
}

.tw-ring-blue-300\/15 {
  --tw-ring-color: rgb(147 197 253 / 0.15) !important;
}

.tw-ring-blue-300\/20 {
  --tw-ring-color: rgb(147 197 253 / 0.2) !important;
}

.tw-ring-blue-300\/25 {
  --tw-ring-color: rgb(147 197 253 / 0.25) !important;
}

.tw-ring-blue-300\/30 {
  --tw-ring-color: rgb(147 197 253 / 0.3) !important;
}

.tw-ring-blue-300\/35 {
  --tw-ring-color: rgb(147 197 253 / 0.35) !important;
}

.tw-ring-blue-300\/40 {
  --tw-ring-color: rgb(147 197 253 / 0.4) !important;
}

.tw-ring-blue-300\/45 {
  --tw-ring-color: rgb(147 197 253 / 0.45) !important;
}

.tw-ring-blue-300\/5 {
  --tw-ring-color: rgb(147 197 253 / 0.05) !important;
}

.tw-ring-blue-300\/50 {
  --tw-ring-color: rgb(147 197 253 / 0.5) !important;
}

.tw-ring-blue-300\/55 {
  --tw-ring-color: rgb(147 197 253 / 0.55) !important;
}

.tw-ring-blue-300\/60 {
  --tw-ring-color: rgb(147 197 253 / 0.6) !important;
}

.tw-ring-blue-300\/65 {
  --tw-ring-color: rgb(147 197 253 / 0.65) !important;
}

.tw-ring-blue-300\/70 {
  --tw-ring-color: rgb(147 197 253 / 0.7) !important;
}

.tw-ring-blue-300\/75 {
  --tw-ring-color: rgb(147 197 253 / 0.75) !important;
}

.tw-ring-blue-300\/80 {
  --tw-ring-color: rgb(147 197 253 / 0.8) !important;
}

.tw-ring-blue-300\/85 {
  --tw-ring-color: rgb(147 197 253 / 0.85) !important;
}

.tw-ring-blue-300\/90 {
  --tw-ring-color: rgb(147 197 253 / 0.9) !important;
}

.tw-ring-blue-300\/95 {
  --tw-ring-color: rgb(147 197 253 / 0.95) !important;
}

.tw-ring-blue-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(96 165 250 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-400\/0 {
  --tw-ring-color: rgb(96 165 250 / 0) !important;
}

.tw-ring-blue-400\/10 {
  --tw-ring-color: rgb(96 165 250 / 0.1) !important;
}

.tw-ring-blue-400\/100 {
  --tw-ring-color: rgb(96 165 250 / 1) !important;
}

.tw-ring-blue-400\/15 {
  --tw-ring-color: rgb(96 165 250 / 0.15) !important;
}

.tw-ring-blue-400\/20 {
  --tw-ring-color: rgb(96 165 250 / 0.2) !important;
}

.tw-ring-blue-400\/25 {
  --tw-ring-color: rgb(96 165 250 / 0.25) !important;
}

.tw-ring-blue-400\/30 {
  --tw-ring-color: rgb(96 165 250 / 0.3) !important;
}

.tw-ring-blue-400\/35 {
  --tw-ring-color: rgb(96 165 250 / 0.35) !important;
}

.tw-ring-blue-400\/40 {
  --tw-ring-color: rgb(96 165 250 / 0.4) !important;
}

.tw-ring-blue-400\/45 {
  --tw-ring-color: rgb(96 165 250 / 0.45) !important;
}

.tw-ring-blue-400\/5 {
  --tw-ring-color: rgb(96 165 250 / 0.05) !important;
}

.tw-ring-blue-400\/50 {
  --tw-ring-color: rgb(96 165 250 / 0.5) !important;
}

.tw-ring-blue-400\/55 {
  --tw-ring-color: rgb(96 165 250 / 0.55) !important;
}

.tw-ring-blue-400\/60 {
  --tw-ring-color: rgb(96 165 250 / 0.6) !important;
}

.tw-ring-blue-400\/65 {
  --tw-ring-color: rgb(96 165 250 / 0.65) !important;
}

.tw-ring-blue-400\/70 {
  --tw-ring-color: rgb(96 165 250 / 0.7) !important;
}

.tw-ring-blue-400\/75 {
  --tw-ring-color: rgb(96 165 250 / 0.75) !important;
}

.tw-ring-blue-400\/80 {
  --tw-ring-color: rgb(96 165 250 / 0.8) !important;
}

.tw-ring-blue-400\/85 {
  --tw-ring-color: rgb(96 165 250 / 0.85) !important;
}

.tw-ring-blue-400\/90 {
  --tw-ring-color: rgb(96 165 250 / 0.9) !important;
}

.tw-ring-blue-400\/95 {
  --tw-ring-color: rgb(96 165 250 / 0.95) !important;
}

.tw-ring-blue-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(239 246 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-50\/0 {
  --tw-ring-color: rgb(239 246 255 / 0) !important;
}

.tw-ring-blue-50\/10 {
  --tw-ring-color: rgb(239 246 255 / 0.1) !important;
}

.tw-ring-blue-50\/100 {
  --tw-ring-color: rgb(239 246 255 / 1) !important;
}

.tw-ring-blue-50\/15 {
  --tw-ring-color: rgb(239 246 255 / 0.15) !important;
}

.tw-ring-blue-50\/20 {
  --tw-ring-color: rgb(239 246 255 / 0.2) !important;
}

.tw-ring-blue-50\/25 {
  --tw-ring-color: rgb(239 246 255 / 0.25) !important;
}

.tw-ring-blue-50\/30 {
  --tw-ring-color: rgb(239 246 255 / 0.3) !important;
}

.tw-ring-blue-50\/35 {
  --tw-ring-color: rgb(239 246 255 / 0.35) !important;
}

.tw-ring-blue-50\/40 {
  --tw-ring-color: rgb(239 246 255 / 0.4) !important;
}

.tw-ring-blue-50\/45 {
  --tw-ring-color: rgb(239 246 255 / 0.45) !important;
}

.tw-ring-blue-50\/5 {
  --tw-ring-color: rgb(239 246 255 / 0.05) !important;
}

.tw-ring-blue-50\/50 {
  --tw-ring-color: rgb(239 246 255 / 0.5) !important;
}

.tw-ring-blue-50\/55 {
  --tw-ring-color: rgb(239 246 255 / 0.55) !important;
}

.tw-ring-blue-50\/60 {
  --tw-ring-color: rgb(239 246 255 / 0.6) !important;
}

.tw-ring-blue-50\/65 {
  --tw-ring-color: rgb(239 246 255 / 0.65) !important;
}

.tw-ring-blue-50\/70 {
  --tw-ring-color: rgb(239 246 255 / 0.7) !important;
}

.tw-ring-blue-50\/75 {
  --tw-ring-color: rgb(239 246 255 / 0.75) !important;
}

.tw-ring-blue-50\/80 {
  --tw-ring-color: rgb(239 246 255 / 0.8) !important;
}

.tw-ring-blue-50\/85 {
  --tw-ring-color: rgb(239 246 255 / 0.85) !important;
}

.tw-ring-blue-50\/90 {
  --tw-ring-color: rgb(239 246 255 / 0.9) !important;
}

.tw-ring-blue-50\/95 {
  --tw-ring-color: rgb(239 246 255 / 0.95) !important;
}

.tw-ring-blue-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(59 130 246 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-500\/0 {
  --tw-ring-color: rgb(59 130 246 / 0) !important;
}

.tw-ring-blue-500\/10 {
  --tw-ring-color: rgb(59 130 246 / 0.1) !important;
}

.tw-ring-blue-500\/100 {
  --tw-ring-color: rgb(59 130 246 / 1) !important;
}

.tw-ring-blue-500\/15 {
  --tw-ring-color: rgb(59 130 246 / 0.15) !important;
}

.tw-ring-blue-500\/20 {
  --tw-ring-color: rgb(59 130 246 / 0.2) !important;
}

.tw-ring-blue-500\/25 {
  --tw-ring-color: rgb(59 130 246 / 0.25) !important;
}

.tw-ring-blue-500\/30 {
  --tw-ring-color: rgb(59 130 246 / 0.3) !important;
}

.tw-ring-blue-500\/35 {
  --tw-ring-color: rgb(59 130 246 / 0.35) !important;
}

.tw-ring-blue-500\/40 {
  --tw-ring-color: rgb(59 130 246 / 0.4) !important;
}

.tw-ring-blue-500\/45 {
  --tw-ring-color: rgb(59 130 246 / 0.45) !important;
}

.tw-ring-blue-500\/5 {
  --tw-ring-color: rgb(59 130 246 / 0.05) !important;
}

.tw-ring-blue-500\/50 {
  --tw-ring-color: rgb(59 130 246 / 0.5) !important;
}

.tw-ring-blue-500\/55 {
  --tw-ring-color: rgb(59 130 246 / 0.55) !important;
}

.tw-ring-blue-500\/60 {
  --tw-ring-color: rgb(59 130 246 / 0.6) !important;
}

.tw-ring-blue-500\/65 {
  --tw-ring-color: rgb(59 130 246 / 0.65) !important;
}

.tw-ring-blue-500\/70 {
  --tw-ring-color: rgb(59 130 246 / 0.7) !important;
}

.tw-ring-blue-500\/75 {
  --tw-ring-color: rgb(59 130 246 / 0.75) !important;
}

.tw-ring-blue-500\/80 {
  --tw-ring-color: rgb(59 130 246 / 0.8) !important;
}

.tw-ring-blue-500\/85 {
  --tw-ring-color: rgb(59 130 246 / 0.85) !important;
}

.tw-ring-blue-500\/90 {
  --tw-ring-color: rgb(59 130 246 / 0.9) !important;
}

.tw-ring-blue-500\/95 {
  --tw-ring-color: rgb(59 130 246 / 0.95) !important;
}

.tw-ring-blue-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(37 99 235 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-600\/0 {
  --tw-ring-color: rgb(37 99 235 / 0) !important;
}

.tw-ring-blue-600\/10 {
  --tw-ring-color: rgb(37 99 235 / 0.1) !important;
}

.tw-ring-blue-600\/100 {
  --tw-ring-color: rgb(37 99 235 / 1) !important;
}

.tw-ring-blue-600\/15 {
  --tw-ring-color: rgb(37 99 235 / 0.15) !important;
}

.tw-ring-blue-600\/20 {
  --tw-ring-color: rgb(37 99 235 / 0.2) !important;
}

.tw-ring-blue-600\/25 {
  --tw-ring-color: rgb(37 99 235 / 0.25) !important;
}

.tw-ring-blue-600\/30 {
  --tw-ring-color: rgb(37 99 235 / 0.3) !important;
}

.tw-ring-blue-600\/35 {
  --tw-ring-color: rgb(37 99 235 / 0.35) !important;
}

.tw-ring-blue-600\/40 {
  --tw-ring-color: rgb(37 99 235 / 0.4) !important;
}

.tw-ring-blue-600\/45 {
  --tw-ring-color: rgb(37 99 235 / 0.45) !important;
}

.tw-ring-blue-600\/5 {
  --tw-ring-color: rgb(37 99 235 / 0.05) !important;
}

.tw-ring-blue-600\/50 {
  --tw-ring-color: rgb(37 99 235 / 0.5) !important;
}

.tw-ring-blue-600\/55 {
  --tw-ring-color: rgb(37 99 235 / 0.55) !important;
}

.tw-ring-blue-600\/60 {
  --tw-ring-color: rgb(37 99 235 / 0.6) !important;
}

.tw-ring-blue-600\/65 {
  --tw-ring-color: rgb(37 99 235 / 0.65) !important;
}

.tw-ring-blue-600\/70 {
  --tw-ring-color: rgb(37 99 235 / 0.7) !important;
}

.tw-ring-blue-600\/75 {
  --tw-ring-color: rgb(37 99 235 / 0.75) !important;
}

.tw-ring-blue-600\/80 {
  --tw-ring-color: rgb(37 99 235 / 0.8) !important;
}

.tw-ring-blue-600\/85 {
  --tw-ring-color: rgb(37 99 235 / 0.85) !important;
}

.tw-ring-blue-600\/90 {
  --tw-ring-color: rgb(37 99 235 / 0.9) !important;
}

.tw-ring-blue-600\/95 {
  --tw-ring-color: rgb(37 99 235 / 0.95) !important;
}

.tw-ring-blue-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(29 78 216 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-700\/0 {
  --tw-ring-color: rgb(29 78 216 / 0) !important;
}

.tw-ring-blue-700\/10 {
  --tw-ring-color: rgb(29 78 216 / 0.1) !important;
}

.tw-ring-blue-700\/100 {
  --tw-ring-color: rgb(29 78 216 / 1) !important;
}

.tw-ring-blue-700\/15 {
  --tw-ring-color: rgb(29 78 216 / 0.15) !important;
}

.tw-ring-blue-700\/20 {
  --tw-ring-color: rgb(29 78 216 / 0.2) !important;
}

.tw-ring-blue-700\/25 {
  --tw-ring-color: rgb(29 78 216 / 0.25) !important;
}

.tw-ring-blue-700\/30 {
  --tw-ring-color: rgb(29 78 216 / 0.3) !important;
}

.tw-ring-blue-700\/35 {
  --tw-ring-color: rgb(29 78 216 / 0.35) !important;
}

.tw-ring-blue-700\/40 {
  --tw-ring-color: rgb(29 78 216 / 0.4) !important;
}

.tw-ring-blue-700\/45 {
  --tw-ring-color: rgb(29 78 216 / 0.45) !important;
}

.tw-ring-blue-700\/5 {
  --tw-ring-color: rgb(29 78 216 / 0.05) !important;
}

.tw-ring-blue-700\/50 {
  --tw-ring-color: rgb(29 78 216 / 0.5) !important;
}

.tw-ring-blue-700\/55 {
  --tw-ring-color: rgb(29 78 216 / 0.55) !important;
}

.tw-ring-blue-700\/60 {
  --tw-ring-color: rgb(29 78 216 / 0.6) !important;
}

.tw-ring-blue-700\/65 {
  --tw-ring-color: rgb(29 78 216 / 0.65) !important;
}

.tw-ring-blue-700\/70 {
  --tw-ring-color: rgb(29 78 216 / 0.7) !important;
}

.tw-ring-blue-700\/75 {
  --tw-ring-color: rgb(29 78 216 / 0.75) !important;
}

.tw-ring-blue-700\/80 {
  --tw-ring-color: rgb(29 78 216 / 0.8) !important;
}

.tw-ring-blue-700\/85 {
  --tw-ring-color: rgb(29 78 216 / 0.85) !important;
}

.tw-ring-blue-700\/90 {
  --tw-ring-color: rgb(29 78 216 / 0.9) !important;
}

.tw-ring-blue-700\/95 {
  --tw-ring-color: rgb(29 78 216 / 0.95) !important;
}

.tw-ring-blue-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(30 64 175 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-800\/0 {
  --tw-ring-color: rgb(30 64 175 / 0) !important;
}

.tw-ring-blue-800\/10 {
  --tw-ring-color: rgb(30 64 175 / 0.1) !important;
}

.tw-ring-blue-800\/100 {
  --tw-ring-color: rgb(30 64 175 / 1) !important;
}

.tw-ring-blue-800\/15 {
  --tw-ring-color: rgb(30 64 175 / 0.15) !important;
}

.tw-ring-blue-800\/20 {
  --tw-ring-color: rgb(30 64 175 / 0.2) !important;
}

.tw-ring-blue-800\/25 {
  --tw-ring-color: rgb(30 64 175 / 0.25) !important;
}

.tw-ring-blue-800\/30 {
  --tw-ring-color: rgb(30 64 175 / 0.3) !important;
}

.tw-ring-blue-800\/35 {
  --tw-ring-color: rgb(30 64 175 / 0.35) !important;
}

.tw-ring-blue-800\/40 {
  --tw-ring-color: rgb(30 64 175 / 0.4) !important;
}

.tw-ring-blue-800\/45 {
  --tw-ring-color: rgb(30 64 175 / 0.45) !important;
}

.tw-ring-blue-800\/5 {
  --tw-ring-color: rgb(30 64 175 / 0.05) !important;
}

.tw-ring-blue-800\/50 {
  --tw-ring-color: rgb(30 64 175 / 0.5) !important;
}

.tw-ring-blue-800\/55 {
  --tw-ring-color: rgb(30 64 175 / 0.55) !important;
}

.tw-ring-blue-800\/60 {
  --tw-ring-color: rgb(30 64 175 / 0.6) !important;
}

.tw-ring-blue-800\/65 {
  --tw-ring-color: rgb(30 64 175 / 0.65) !important;
}

.tw-ring-blue-800\/70 {
  --tw-ring-color: rgb(30 64 175 / 0.7) !important;
}

.tw-ring-blue-800\/75 {
  --tw-ring-color: rgb(30 64 175 / 0.75) !important;
}

.tw-ring-blue-800\/80 {
  --tw-ring-color: rgb(30 64 175 / 0.8) !important;
}

.tw-ring-blue-800\/85 {
  --tw-ring-color: rgb(30 64 175 / 0.85) !important;
}

.tw-ring-blue-800\/90 {
  --tw-ring-color: rgb(30 64 175 / 0.9) !important;
}

.tw-ring-blue-800\/95 {
  --tw-ring-color: rgb(30 64 175 / 0.95) !important;
}

.tw-ring-blue-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(30 58 138 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-900\/0 {
  --tw-ring-color: rgb(30 58 138 / 0) !important;
}

.tw-ring-blue-900\/10 {
  --tw-ring-color: rgb(30 58 138 / 0.1) !important;
}

.tw-ring-blue-900\/100 {
  --tw-ring-color: rgb(30 58 138 / 1) !important;
}

.tw-ring-blue-900\/15 {
  --tw-ring-color: rgb(30 58 138 / 0.15) !important;
}

.tw-ring-blue-900\/20 {
  --tw-ring-color: rgb(30 58 138 / 0.2) !important;
}

.tw-ring-blue-900\/25 {
  --tw-ring-color: rgb(30 58 138 / 0.25) !important;
}

.tw-ring-blue-900\/30 {
  --tw-ring-color: rgb(30 58 138 / 0.3) !important;
}

.tw-ring-blue-900\/35 {
  --tw-ring-color: rgb(30 58 138 / 0.35) !important;
}

.tw-ring-blue-900\/40 {
  --tw-ring-color: rgb(30 58 138 / 0.4) !important;
}

.tw-ring-blue-900\/45 {
  --tw-ring-color: rgb(30 58 138 / 0.45) !important;
}

.tw-ring-blue-900\/5 {
  --tw-ring-color: rgb(30 58 138 / 0.05) !important;
}

.tw-ring-blue-900\/50 {
  --tw-ring-color: rgb(30 58 138 / 0.5) !important;
}

.tw-ring-blue-900\/55 {
  --tw-ring-color: rgb(30 58 138 / 0.55) !important;
}

.tw-ring-blue-900\/60 {
  --tw-ring-color: rgb(30 58 138 / 0.6) !important;
}

.tw-ring-blue-900\/65 {
  --tw-ring-color: rgb(30 58 138 / 0.65) !important;
}

.tw-ring-blue-900\/70 {
  --tw-ring-color: rgb(30 58 138 / 0.7) !important;
}

.tw-ring-blue-900\/75 {
  --tw-ring-color: rgb(30 58 138 / 0.75) !important;
}

.tw-ring-blue-900\/80 {
  --tw-ring-color: rgb(30 58 138 / 0.8) !important;
}

.tw-ring-blue-900\/85 {
  --tw-ring-color: rgb(30 58 138 / 0.85) !important;
}

.tw-ring-blue-900\/90 {
  --tw-ring-color: rgb(30 58 138 / 0.9) !important;
}

.tw-ring-blue-900\/95 {
  --tw-ring-color: rgb(30 58 138 / 0.95) !important;
}

.tw-ring-blue-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(23 37 84 / var(--tw-ring-opacity)) !important;
}

.tw-ring-blue-950\/0 {
  --tw-ring-color: rgb(23 37 84 / 0) !important;
}

.tw-ring-blue-950\/10 {
  --tw-ring-color: rgb(23 37 84 / 0.1) !important;
}

.tw-ring-blue-950\/100 {
  --tw-ring-color: rgb(23 37 84 / 1) !important;
}

.tw-ring-blue-950\/15 {
  --tw-ring-color: rgb(23 37 84 / 0.15) !important;
}

.tw-ring-blue-950\/20 {
  --tw-ring-color: rgb(23 37 84 / 0.2) !important;
}

.tw-ring-blue-950\/25 {
  --tw-ring-color: rgb(23 37 84 / 0.25) !important;
}

.tw-ring-blue-950\/30 {
  --tw-ring-color: rgb(23 37 84 / 0.3) !important;
}

.tw-ring-blue-950\/35 {
  --tw-ring-color: rgb(23 37 84 / 0.35) !important;
}

.tw-ring-blue-950\/40 {
  --tw-ring-color: rgb(23 37 84 / 0.4) !important;
}

.tw-ring-blue-950\/45 {
  --tw-ring-color: rgb(23 37 84 / 0.45) !important;
}

.tw-ring-blue-950\/5 {
  --tw-ring-color: rgb(23 37 84 / 0.05) !important;
}

.tw-ring-blue-950\/50 {
  --tw-ring-color: rgb(23 37 84 / 0.5) !important;
}

.tw-ring-blue-950\/55 {
  --tw-ring-color: rgb(23 37 84 / 0.55) !important;
}

.tw-ring-blue-950\/60 {
  --tw-ring-color: rgb(23 37 84 / 0.6) !important;
}

.tw-ring-blue-950\/65 {
  --tw-ring-color: rgb(23 37 84 / 0.65) !important;
}

.tw-ring-blue-950\/70 {
  --tw-ring-color: rgb(23 37 84 / 0.7) !important;
}

.tw-ring-blue-950\/75 {
  --tw-ring-color: rgb(23 37 84 / 0.75) !important;
}

.tw-ring-blue-950\/80 {
  --tw-ring-color: rgb(23 37 84 / 0.8) !important;
}

.tw-ring-blue-950\/85 {
  --tw-ring-color: rgb(23 37 84 / 0.85) !important;
}

.tw-ring-blue-950\/90 {
  --tw-ring-color: rgb(23 37 84 / 0.9) !important;
}

.tw-ring-blue-950\/95 {
  --tw-ring-color: rgb(23 37 84 / 0.95) !important;
}

.tw-ring-current {
  --tw-ring-color: currentColor !important;
}

.tw-ring-cyan-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(207 250 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-100\/0 {
  --tw-ring-color: rgb(207 250 254 / 0) !important;
}

.tw-ring-cyan-100\/10 {
  --tw-ring-color: rgb(207 250 254 / 0.1) !important;
}

.tw-ring-cyan-100\/100 {
  --tw-ring-color: rgb(207 250 254 / 1) !important;
}

.tw-ring-cyan-100\/15 {
  --tw-ring-color: rgb(207 250 254 / 0.15) !important;
}

.tw-ring-cyan-100\/20 {
  --tw-ring-color: rgb(207 250 254 / 0.2) !important;
}

.tw-ring-cyan-100\/25 {
  --tw-ring-color: rgb(207 250 254 / 0.25) !important;
}

.tw-ring-cyan-100\/30 {
  --tw-ring-color: rgb(207 250 254 / 0.3) !important;
}

.tw-ring-cyan-100\/35 {
  --tw-ring-color: rgb(207 250 254 / 0.35) !important;
}

.tw-ring-cyan-100\/40 {
  --tw-ring-color: rgb(207 250 254 / 0.4) !important;
}

.tw-ring-cyan-100\/45 {
  --tw-ring-color: rgb(207 250 254 / 0.45) !important;
}

.tw-ring-cyan-100\/5 {
  --tw-ring-color: rgb(207 250 254 / 0.05) !important;
}

.tw-ring-cyan-100\/50 {
  --tw-ring-color: rgb(207 250 254 / 0.5) !important;
}

.tw-ring-cyan-100\/55 {
  --tw-ring-color: rgb(207 250 254 / 0.55) !important;
}

.tw-ring-cyan-100\/60 {
  --tw-ring-color: rgb(207 250 254 / 0.6) !important;
}

.tw-ring-cyan-100\/65 {
  --tw-ring-color: rgb(207 250 254 / 0.65) !important;
}

.tw-ring-cyan-100\/70 {
  --tw-ring-color: rgb(207 250 254 / 0.7) !important;
}

.tw-ring-cyan-100\/75 {
  --tw-ring-color: rgb(207 250 254 / 0.75) !important;
}

.tw-ring-cyan-100\/80 {
  --tw-ring-color: rgb(207 250 254 / 0.8) !important;
}

.tw-ring-cyan-100\/85 {
  --tw-ring-color: rgb(207 250 254 / 0.85) !important;
}

.tw-ring-cyan-100\/90 {
  --tw-ring-color: rgb(207 250 254 / 0.9) !important;
}

.tw-ring-cyan-100\/95 {
  --tw-ring-color: rgb(207 250 254 / 0.95) !important;
}

.tw-ring-cyan-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(165 243 252 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-200\/0 {
  --tw-ring-color: rgb(165 243 252 / 0) !important;
}

.tw-ring-cyan-200\/10 {
  --tw-ring-color: rgb(165 243 252 / 0.1) !important;
}

.tw-ring-cyan-200\/100 {
  --tw-ring-color: rgb(165 243 252 / 1) !important;
}

.tw-ring-cyan-200\/15 {
  --tw-ring-color: rgb(165 243 252 / 0.15) !important;
}

.tw-ring-cyan-200\/20 {
  --tw-ring-color: rgb(165 243 252 / 0.2) !important;
}

.tw-ring-cyan-200\/25 {
  --tw-ring-color: rgb(165 243 252 / 0.25) !important;
}

.tw-ring-cyan-200\/30 {
  --tw-ring-color: rgb(165 243 252 / 0.3) !important;
}

.tw-ring-cyan-200\/35 {
  --tw-ring-color: rgb(165 243 252 / 0.35) !important;
}

.tw-ring-cyan-200\/40 {
  --tw-ring-color: rgb(165 243 252 / 0.4) !important;
}

.tw-ring-cyan-200\/45 {
  --tw-ring-color: rgb(165 243 252 / 0.45) !important;
}

.tw-ring-cyan-200\/5 {
  --tw-ring-color: rgb(165 243 252 / 0.05) !important;
}

.tw-ring-cyan-200\/50 {
  --tw-ring-color: rgb(165 243 252 / 0.5) !important;
}

.tw-ring-cyan-200\/55 {
  --tw-ring-color: rgb(165 243 252 / 0.55) !important;
}

.tw-ring-cyan-200\/60 {
  --tw-ring-color: rgb(165 243 252 / 0.6) !important;
}

.tw-ring-cyan-200\/65 {
  --tw-ring-color: rgb(165 243 252 / 0.65) !important;
}

.tw-ring-cyan-200\/70 {
  --tw-ring-color: rgb(165 243 252 / 0.7) !important;
}

.tw-ring-cyan-200\/75 {
  --tw-ring-color: rgb(165 243 252 / 0.75) !important;
}

.tw-ring-cyan-200\/80 {
  --tw-ring-color: rgb(165 243 252 / 0.8) !important;
}

.tw-ring-cyan-200\/85 {
  --tw-ring-color: rgb(165 243 252 / 0.85) !important;
}

.tw-ring-cyan-200\/90 {
  --tw-ring-color: rgb(165 243 252 / 0.9) !important;
}

.tw-ring-cyan-200\/95 {
  --tw-ring-color: rgb(165 243 252 / 0.95) !important;
}

.tw-ring-cyan-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-300\/0 {
  --tw-ring-color: rgb(103 232 249 / 0) !important;
}

.tw-ring-cyan-300\/10 {
  --tw-ring-color: rgb(103 232 249 / 0.1) !important;
}

.tw-ring-cyan-300\/100 {
  --tw-ring-color: rgb(103 232 249 / 1) !important;
}

.tw-ring-cyan-300\/15 {
  --tw-ring-color: rgb(103 232 249 / 0.15) !important;
}

.tw-ring-cyan-300\/20 {
  --tw-ring-color: rgb(103 232 249 / 0.2) !important;
}

.tw-ring-cyan-300\/25 {
  --tw-ring-color: rgb(103 232 249 / 0.25) !important;
}

.tw-ring-cyan-300\/30 {
  --tw-ring-color: rgb(103 232 249 / 0.3) !important;
}

.tw-ring-cyan-300\/35 {
  --tw-ring-color: rgb(103 232 249 / 0.35) !important;
}

.tw-ring-cyan-300\/40 {
  --tw-ring-color: rgb(103 232 249 / 0.4) !important;
}

.tw-ring-cyan-300\/45 {
  --tw-ring-color: rgb(103 232 249 / 0.45) !important;
}

.tw-ring-cyan-300\/5 {
  --tw-ring-color: rgb(103 232 249 / 0.05) !important;
}

.tw-ring-cyan-300\/50 {
  --tw-ring-color: rgb(103 232 249 / 0.5) !important;
}

.tw-ring-cyan-300\/55 {
  --tw-ring-color: rgb(103 232 249 / 0.55) !important;
}

.tw-ring-cyan-300\/60 {
  --tw-ring-color: rgb(103 232 249 / 0.6) !important;
}

.tw-ring-cyan-300\/65 {
  --tw-ring-color: rgb(103 232 249 / 0.65) !important;
}

.tw-ring-cyan-300\/70 {
  --tw-ring-color: rgb(103 232 249 / 0.7) !important;
}

.tw-ring-cyan-300\/75 {
  --tw-ring-color: rgb(103 232 249 / 0.75) !important;
}

.tw-ring-cyan-300\/80 {
  --tw-ring-color: rgb(103 232 249 / 0.8) !important;
}

.tw-ring-cyan-300\/85 {
  --tw-ring-color: rgb(103 232 249 / 0.85) !important;
}

.tw-ring-cyan-300\/90 {
  --tw-ring-color: rgb(103 232 249 / 0.9) !important;
}

.tw-ring-cyan-300\/95 {
  --tw-ring-color: rgb(103 232 249 / 0.95) !important;
}

.tw-ring-cyan-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(34 211 238 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-400\/0 {
  --tw-ring-color: rgb(34 211 238 / 0) !important;
}

.tw-ring-cyan-400\/10 {
  --tw-ring-color: rgb(34 211 238 / 0.1) !important;
}

.tw-ring-cyan-400\/100 {
  --tw-ring-color: rgb(34 211 238 / 1) !important;
}

.tw-ring-cyan-400\/15 {
  --tw-ring-color: rgb(34 211 238 / 0.15) !important;
}

.tw-ring-cyan-400\/20 {
  --tw-ring-color: rgb(34 211 238 / 0.2) !important;
}

.tw-ring-cyan-400\/25 {
  --tw-ring-color: rgb(34 211 238 / 0.25) !important;
}

.tw-ring-cyan-400\/30 {
  --tw-ring-color: rgb(34 211 238 / 0.3) !important;
}

.tw-ring-cyan-400\/35 {
  --tw-ring-color: rgb(34 211 238 / 0.35) !important;
}

.tw-ring-cyan-400\/40 {
  --tw-ring-color: rgb(34 211 238 / 0.4) !important;
}

.tw-ring-cyan-400\/45 {
  --tw-ring-color: rgb(34 211 238 / 0.45) !important;
}

.tw-ring-cyan-400\/5 {
  --tw-ring-color: rgb(34 211 238 / 0.05) !important;
}

.tw-ring-cyan-400\/50 {
  --tw-ring-color: rgb(34 211 238 / 0.5) !important;
}

.tw-ring-cyan-400\/55 {
  --tw-ring-color: rgb(34 211 238 / 0.55) !important;
}

.tw-ring-cyan-400\/60 {
  --tw-ring-color: rgb(34 211 238 / 0.6) !important;
}

.tw-ring-cyan-400\/65 {
  --tw-ring-color: rgb(34 211 238 / 0.65) !important;
}

.tw-ring-cyan-400\/70 {
  --tw-ring-color: rgb(34 211 238 / 0.7) !important;
}

.tw-ring-cyan-400\/75 {
  --tw-ring-color: rgb(34 211 238 / 0.75) !important;
}

.tw-ring-cyan-400\/80 {
  --tw-ring-color: rgb(34 211 238 / 0.8) !important;
}

.tw-ring-cyan-400\/85 {
  --tw-ring-color: rgb(34 211 238 / 0.85) !important;
}

.tw-ring-cyan-400\/90 {
  --tw-ring-color: rgb(34 211 238 / 0.9) !important;
}

.tw-ring-cyan-400\/95 {
  --tw-ring-color: rgb(34 211 238 / 0.95) !important;
}

.tw-ring-cyan-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(236 254 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-50\/0 {
  --tw-ring-color: rgb(236 254 255 / 0) !important;
}

.tw-ring-cyan-50\/10 {
  --tw-ring-color: rgb(236 254 255 / 0.1) !important;
}

.tw-ring-cyan-50\/100 {
  --tw-ring-color: rgb(236 254 255 / 1) !important;
}

.tw-ring-cyan-50\/15 {
  --tw-ring-color: rgb(236 254 255 / 0.15) !important;
}

.tw-ring-cyan-50\/20 {
  --tw-ring-color: rgb(236 254 255 / 0.2) !important;
}

.tw-ring-cyan-50\/25 {
  --tw-ring-color: rgb(236 254 255 / 0.25) !important;
}

.tw-ring-cyan-50\/30 {
  --tw-ring-color: rgb(236 254 255 / 0.3) !important;
}

.tw-ring-cyan-50\/35 {
  --tw-ring-color: rgb(236 254 255 / 0.35) !important;
}

.tw-ring-cyan-50\/40 {
  --tw-ring-color: rgb(236 254 255 / 0.4) !important;
}

.tw-ring-cyan-50\/45 {
  --tw-ring-color: rgb(236 254 255 / 0.45) !important;
}

.tw-ring-cyan-50\/5 {
  --tw-ring-color: rgb(236 254 255 / 0.05) !important;
}

.tw-ring-cyan-50\/50 {
  --tw-ring-color: rgb(236 254 255 / 0.5) !important;
}

.tw-ring-cyan-50\/55 {
  --tw-ring-color: rgb(236 254 255 / 0.55) !important;
}

.tw-ring-cyan-50\/60 {
  --tw-ring-color: rgb(236 254 255 / 0.6) !important;
}

.tw-ring-cyan-50\/65 {
  --tw-ring-color: rgb(236 254 255 / 0.65) !important;
}

.tw-ring-cyan-50\/70 {
  --tw-ring-color: rgb(236 254 255 / 0.7) !important;
}

.tw-ring-cyan-50\/75 {
  --tw-ring-color: rgb(236 254 255 / 0.75) !important;
}

.tw-ring-cyan-50\/80 {
  --tw-ring-color: rgb(236 254 255 / 0.8) !important;
}

.tw-ring-cyan-50\/85 {
  --tw-ring-color: rgb(236 254 255 / 0.85) !important;
}

.tw-ring-cyan-50\/90 {
  --tw-ring-color: rgb(236 254 255 / 0.9) !important;
}

.tw-ring-cyan-50\/95 {
  --tw-ring-color: rgb(236 254 255 / 0.95) !important;
}

.tw-ring-cyan-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(6 182 212 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-500\/0 {
  --tw-ring-color: rgb(6 182 212 / 0) !important;
}

.tw-ring-cyan-500\/10 {
  --tw-ring-color: rgb(6 182 212 / 0.1) !important;
}

.tw-ring-cyan-500\/100 {
  --tw-ring-color: rgb(6 182 212 / 1) !important;
}

.tw-ring-cyan-500\/15 {
  --tw-ring-color: rgb(6 182 212 / 0.15) !important;
}

.tw-ring-cyan-500\/20 {
  --tw-ring-color: rgb(6 182 212 / 0.2) !important;
}

.tw-ring-cyan-500\/25 {
  --tw-ring-color: rgb(6 182 212 / 0.25) !important;
}

.tw-ring-cyan-500\/30 {
  --tw-ring-color: rgb(6 182 212 / 0.3) !important;
}

.tw-ring-cyan-500\/35 {
  --tw-ring-color: rgb(6 182 212 / 0.35) !important;
}

.tw-ring-cyan-500\/40 {
  --tw-ring-color: rgb(6 182 212 / 0.4) !important;
}

.tw-ring-cyan-500\/45 {
  --tw-ring-color: rgb(6 182 212 / 0.45) !important;
}

.tw-ring-cyan-500\/5 {
  --tw-ring-color: rgb(6 182 212 / 0.05) !important;
}

.tw-ring-cyan-500\/50 {
  --tw-ring-color: rgb(6 182 212 / 0.5) !important;
}

.tw-ring-cyan-500\/55 {
  --tw-ring-color: rgb(6 182 212 / 0.55) !important;
}

.tw-ring-cyan-500\/60 {
  --tw-ring-color: rgb(6 182 212 / 0.6) !important;
}

.tw-ring-cyan-500\/65 {
  --tw-ring-color: rgb(6 182 212 / 0.65) !important;
}

.tw-ring-cyan-500\/70 {
  --tw-ring-color: rgb(6 182 212 / 0.7) !important;
}

.tw-ring-cyan-500\/75 {
  --tw-ring-color: rgb(6 182 212 / 0.75) !important;
}

.tw-ring-cyan-500\/80 {
  --tw-ring-color: rgb(6 182 212 / 0.8) !important;
}

.tw-ring-cyan-500\/85 {
  --tw-ring-color: rgb(6 182 212 / 0.85) !important;
}

.tw-ring-cyan-500\/90 {
  --tw-ring-color: rgb(6 182 212 / 0.9) !important;
}

.tw-ring-cyan-500\/95 {
  --tw-ring-color: rgb(6 182 212 / 0.95) !important;
}

.tw-ring-cyan-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(8 145 178 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-600\/0 {
  --tw-ring-color: rgb(8 145 178 / 0) !important;
}

.tw-ring-cyan-600\/10 {
  --tw-ring-color: rgb(8 145 178 / 0.1) !important;
}

.tw-ring-cyan-600\/100 {
  --tw-ring-color: rgb(8 145 178 / 1) !important;
}

.tw-ring-cyan-600\/15 {
  --tw-ring-color: rgb(8 145 178 / 0.15) !important;
}

.tw-ring-cyan-600\/20 {
  --tw-ring-color: rgb(8 145 178 / 0.2) !important;
}

.tw-ring-cyan-600\/25 {
  --tw-ring-color: rgb(8 145 178 / 0.25) !important;
}

.tw-ring-cyan-600\/30 {
  --tw-ring-color: rgb(8 145 178 / 0.3) !important;
}

.tw-ring-cyan-600\/35 {
  --tw-ring-color: rgb(8 145 178 / 0.35) !important;
}

.tw-ring-cyan-600\/40 {
  --tw-ring-color: rgb(8 145 178 / 0.4) !important;
}

.tw-ring-cyan-600\/45 {
  --tw-ring-color: rgb(8 145 178 / 0.45) !important;
}

.tw-ring-cyan-600\/5 {
  --tw-ring-color: rgb(8 145 178 / 0.05) !important;
}

.tw-ring-cyan-600\/50 {
  --tw-ring-color: rgb(8 145 178 / 0.5) !important;
}

.tw-ring-cyan-600\/55 {
  --tw-ring-color: rgb(8 145 178 / 0.55) !important;
}

.tw-ring-cyan-600\/60 {
  --tw-ring-color: rgb(8 145 178 / 0.6) !important;
}

.tw-ring-cyan-600\/65 {
  --tw-ring-color: rgb(8 145 178 / 0.65) !important;
}

.tw-ring-cyan-600\/70 {
  --tw-ring-color: rgb(8 145 178 / 0.7) !important;
}

.tw-ring-cyan-600\/75 {
  --tw-ring-color: rgb(8 145 178 / 0.75) !important;
}

.tw-ring-cyan-600\/80 {
  --tw-ring-color: rgb(8 145 178 / 0.8) !important;
}

.tw-ring-cyan-600\/85 {
  --tw-ring-color: rgb(8 145 178 / 0.85) !important;
}

.tw-ring-cyan-600\/90 {
  --tw-ring-color: rgb(8 145 178 / 0.9) !important;
}

.tw-ring-cyan-600\/95 {
  --tw-ring-color: rgb(8 145 178 / 0.95) !important;
}

.tw-ring-cyan-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(14 116 144 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-700\/0 {
  --tw-ring-color: rgb(14 116 144 / 0) !important;
}

.tw-ring-cyan-700\/10 {
  --tw-ring-color: rgb(14 116 144 / 0.1) !important;
}

.tw-ring-cyan-700\/100 {
  --tw-ring-color: rgb(14 116 144 / 1) !important;
}

.tw-ring-cyan-700\/15 {
  --tw-ring-color: rgb(14 116 144 / 0.15) !important;
}

.tw-ring-cyan-700\/20 {
  --tw-ring-color: rgb(14 116 144 / 0.2) !important;
}

.tw-ring-cyan-700\/25 {
  --tw-ring-color: rgb(14 116 144 / 0.25) !important;
}

.tw-ring-cyan-700\/30 {
  --tw-ring-color: rgb(14 116 144 / 0.3) !important;
}

.tw-ring-cyan-700\/35 {
  --tw-ring-color: rgb(14 116 144 / 0.35) !important;
}

.tw-ring-cyan-700\/40 {
  --tw-ring-color: rgb(14 116 144 / 0.4) !important;
}

.tw-ring-cyan-700\/45 {
  --tw-ring-color: rgb(14 116 144 / 0.45) !important;
}

.tw-ring-cyan-700\/5 {
  --tw-ring-color: rgb(14 116 144 / 0.05) !important;
}

.tw-ring-cyan-700\/50 {
  --tw-ring-color: rgb(14 116 144 / 0.5) !important;
}

.tw-ring-cyan-700\/55 {
  --tw-ring-color: rgb(14 116 144 / 0.55) !important;
}

.tw-ring-cyan-700\/60 {
  --tw-ring-color: rgb(14 116 144 / 0.6) !important;
}

.tw-ring-cyan-700\/65 {
  --tw-ring-color: rgb(14 116 144 / 0.65) !important;
}

.tw-ring-cyan-700\/70 {
  --tw-ring-color: rgb(14 116 144 / 0.7) !important;
}

.tw-ring-cyan-700\/75 {
  --tw-ring-color: rgb(14 116 144 / 0.75) !important;
}

.tw-ring-cyan-700\/80 {
  --tw-ring-color: rgb(14 116 144 / 0.8) !important;
}

.tw-ring-cyan-700\/85 {
  --tw-ring-color: rgb(14 116 144 / 0.85) !important;
}

.tw-ring-cyan-700\/90 {
  --tw-ring-color: rgb(14 116 144 / 0.9) !important;
}

.tw-ring-cyan-700\/95 {
  --tw-ring-color: rgb(14 116 144 / 0.95) !important;
}

.tw-ring-cyan-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(21 94 117 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-800\/0 {
  --tw-ring-color: rgb(21 94 117 / 0) !important;
}

.tw-ring-cyan-800\/10 {
  --tw-ring-color: rgb(21 94 117 / 0.1) !important;
}

.tw-ring-cyan-800\/100 {
  --tw-ring-color: rgb(21 94 117 / 1) !important;
}

.tw-ring-cyan-800\/15 {
  --tw-ring-color: rgb(21 94 117 / 0.15) !important;
}

.tw-ring-cyan-800\/20 {
  --tw-ring-color: rgb(21 94 117 / 0.2) !important;
}

.tw-ring-cyan-800\/25 {
  --tw-ring-color: rgb(21 94 117 / 0.25) !important;
}

.tw-ring-cyan-800\/30 {
  --tw-ring-color: rgb(21 94 117 / 0.3) !important;
}

.tw-ring-cyan-800\/35 {
  --tw-ring-color: rgb(21 94 117 / 0.35) !important;
}

.tw-ring-cyan-800\/40 {
  --tw-ring-color: rgb(21 94 117 / 0.4) !important;
}

.tw-ring-cyan-800\/45 {
  --tw-ring-color: rgb(21 94 117 / 0.45) !important;
}

.tw-ring-cyan-800\/5 {
  --tw-ring-color: rgb(21 94 117 / 0.05) !important;
}

.tw-ring-cyan-800\/50 {
  --tw-ring-color: rgb(21 94 117 / 0.5) !important;
}

.tw-ring-cyan-800\/55 {
  --tw-ring-color: rgb(21 94 117 / 0.55) !important;
}

.tw-ring-cyan-800\/60 {
  --tw-ring-color: rgb(21 94 117 / 0.6) !important;
}

.tw-ring-cyan-800\/65 {
  --tw-ring-color: rgb(21 94 117 / 0.65) !important;
}

.tw-ring-cyan-800\/70 {
  --tw-ring-color: rgb(21 94 117 / 0.7) !important;
}

.tw-ring-cyan-800\/75 {
  --tw-ring-color: rgb(21 94 117 / 0.75) !important;
}

.tw-ring-cyan-800\/80 {
  --tw-ring-color: rgb(21 94 117 / 0.8) !important;
}

.tw-ring-cyan-800\/85 {
  --tw-ring-color: rgb(21 94 117 / 0.85) !important;
}

.tw-ring-cyan-800\/90 {
  --tw-ring-color: rgb(21 94 117 / 0.9) !important;
}

.tw-ring-cyan-800\/95 {
  --tw-ring-color: rgb(21 94 117 / 0.95) !important;
}

.tw-ring-cyan-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(22 78 99 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-900\/0 {
  --tw-ring-color: rgb(22 78 99 / 0) !important;
}

.tw-ring-cyan-900\/10 {
  --tw-ring-color: rgb(22 78 99 / 0.1) !important;
}

.tw-ring-cyan-900\/100 {
  --tw-ring-color: rgb(22 78 99 / 1) !important;
}

.tw-ring-cyan-900\/15 {
  --tw-ring-color: rgb(22 78 99 / 0.15) !important;
}

.tw-ring-cyan-900\/20 {
  --tw-ring-color: rgb(22 78 99 / 0.2) !important;
}

.tw-ring-cyan-900\/25 {
  --tw-ring-color: rgb(22 78 99 / 0.25) !important;
}

.tw-ring-cyan-900\/30 {
  --tw-ring-color: rgb(22 78 99 / 0.3) !important;
}

.tw-ring-cyan-900\/35 {
  --tw-ring-color: rgb(22 78 99 / 0.35) !important;
}

.tw-ring-cyan-900\/40 {
  --tw-ring-color: rgb(22 78 99 / 0.4) !important;
}

.tw-ring-cyan-900\/45 {
  --tw-ring-color: rgb(22 78 99 / 0.45) !important;
}

.tw-ring-cyan-900\/5 {
  --tw-ring-color: rgb(22 78 99 / 0.05) !important;
}

.tw-ring-cyan-900\/50 {
  --tw-ring-color: rgb(22 78 99 / 0.5) !important;
}

.tw-ring-cyan-900\/55 {
  --tw-ring-color: rgb(22 78 99 / 0.55) !important;
}

.tw-ring-cyan-900\/60 {
  --tw-ring-color: rgb(22 78 99 / 0.6) !important;
}

.tw-ring-cyan-900\/65 {
  --tw-ring-color: rgb(22 78 99 / 0.65) !important;
}

.tw-ring-cyan-900\/70 {
  --tw-ring-color: rgb(22 78 99 / 0.7) !important;
}

.tw-ring-cyan-900\/75 {
  --tw-ring-color: rgb(22 78 99 / 0.75) !important;
}

.tw-ring-cyan-900\/80 {
  --tw-ring-color: rgb(22 78 99 / 0.8) !important;
}

.tw-ring-cyan-900\/85 {
  --tw-ring-color: rgb(22 78 99 / 0.85) !important;
}

.tw-ring-cyan-900\/90 {
  --tw-ring-color: rgb(22 78 99 / 0.9) !important;
}

.tw-ring-cyan-900\/95 {
  --tw-ring-color: rgb(22 78 99 / 0.95) !important;
}

.tw-ring-cyan-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(8 51 68 / var(--tw-ring-opacity)) !important;
}

.tw-ring-cyan-950\/0 {
  --tw-ring-color: rgb(8 51 68 / 0) !important;
}

.tw-ring-cyan-950\/10 {
  --tw-ring-color: rgb(8 51 68 / 0.1) !important;
}

.tw-ring-cyan-950\/100 {
  --tw-ring-color: rgb(8 51 68 / 1) !important;
}

.tw-ring-cyan-950\/15 {
  --tw-ring-color: rgb(8 51 68 / 0.15) !important;
}

.tw-ring-cyan-950\/20 {
  --tw-ring-color: rgb(8 51 68 / 0.2) !important;
}

.tw-ring-cyan-950\/25 {
  --tw-ring-color: rgb(8 51 68 / 0.25) !important;
}

.tw-ring-cyan-950\/30 {
  --tw-ring-color: rgb(8 51 68 / 0.3) !important;
}

.tw-ring-cyan-950\/35 {
  --tw-ring-color: rgb(8 51 68 / 0.35) !important;
}

.tw-ring-cyan-950\/40 {
  --tw-ring-color: rgb(8 51 68 / 0.4) !important;
}

.tw-ring-cyan-950\/45 {
  --tw-ring-color: rgb(8 51 68 / 0.45) !important;
}

.tw-ring-cyan-950\/5 {
  --tw-ring-color: rgb(8 51 68 / 0.05) !important;
}

.tw-ring-cyan-950\/50 {
  --tw-ring-color: rgb(8 51 68 / 0.5) !important;
}

.tw-ring-cyan-950\/55 {
  --tw-ring-color: rgb(8 51 68 / 0.55) !important;
}

.tw-ring-cyan-950\/60 {
  --tw-ring-color: rgb(8 51 68 / 0.6) !important;
}

.tw-ring-cyan-950\/65 {
  --tw-ring-color: rgb(8 51 68 / 0.65) !important;
}

.tw-ring-cyan-950\/70 {
  --tw-ring-color: rgb(8 51 68 / 0.7) !important;
}

.tw-ring-cyan-950\/75 {
  --tw-ring-color: rgb(8 51 68 / 0.75) !important;
}

.tw-ring-cyan-950\/80 {
  --tw-ring-color: rgb(8 51 68 / 0.8) !important;
}

.tw-ring-cyan-950\/85 {
  --tw-ring-color: rgb(8 51 68 / 0.85) !important;
}

.tw-ring-cyan-950\/90 {
  --tw-ring-color: rgb(8 51 68 / 0.9) !important;
}

.tw-ring-cyan-950\/95 {
  --tw-ring-color: rgb(8 51 68 / 0.95) !important;
}

.tw-ring-emerald-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(209 250 229 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-100\/0 {
  --tw-ring-color: rgb(209 250 229 / 0) !important;
}

.tw-ring-emerald-100\/10 {
  --tw-ring-color: rgb(209 250 229 / 0.1) !important;
}

.tw-ring-emerald-100\/100 {
  --tw-ring-color: rgb(209 250 229 / 1) !important;
}

.tw-ring-emerald-100\/15 {
  --tw-ring-color: rgb(209 250 229 / 0.15) !important;
}

.tw-ring-emerald-100\/20 {
  --tw-ring-color: rgb(209 250 229 / 0.2) !important;
}

.tw-ring-emerald-100\/25 {
  --tw-ring-color: rgb(209 250 229 / 0.25) !important;
}

.tw-ring-emerald-100\/30 {
  --tw-ring-color: rgb(209 250 229 / 0.3) !important;
}

.tw-ring-emerald-100\/35 {
  --tw-ring-color: rgb(209 250 229 / 0.35) !important;
}

.tw-ring-emerald-100\/40 {
  --tw-ring-color: rgb(209 250 229 / 0.4) !important;
}

.tw-ring-emerald-100\/45 {
  --tw-ring-color: rgb(209 250 229 / 0.45) !important;
}

.tw-ring-emerald-100\/5 {
  --tw-ring-color: rgb(209 250 229 / 0.05) !important;
}

.tw-ring-emerald-100\/50 {
  --tw-ring-color: rgb(209 250 229 / 0.5) !important;
}

.tw-ring-emerald-100\/55 {
  --tw-ring-color: rgb(209 250 229 / 0.55) !important;
}

.tw-ring-emerald-100\/60 {
  --tw-ring-color: rgb(209 250 229 / 0.6) !important;
}

.tw-ring-emerald-100\/65 {
  --tw-ring-color: rgb(209 250 229 / 0.65) !important;
}

.tw-ring-emerald-100\/70 {
  --tw-ring-color: rgb(209 250 229 / 0.7) !important;
}

.tw-ring-emerald-100\/75 {
  --tw-ring-color: rgb(209 250 229 / 0.75) !important;
}

.tw-ring-emerald-100\/80 {
  --tw-ring-color: rgb(209 250 229 / 0.8) !important;
}

.tw-ring-emerald-100\/85 {
  --tw-ring-color: rgb(209 250 229 / 0.85) !important;
}

.tw-ring-emerald-100\/90 {
  --tw-ring-color: rgb(209 250 229 / 0.9) !important;
}

.tw-ring-emerald-100\/95 {
  --tw-ring-color: rgb(209 250 229 / 0.95) !important;
}

.tw-ring-emerald-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(167 243 208 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-200\/0 {
  --tw-ring-color: rgb(167 243 208 / 0) !important;
}

.tw-ring-emerald-200\/10 {
  --tw-ring-color: rgb(167 243 208 / 0.1) !important;
}

.tw-ring-emerald-200\/100 {
  --tw-ring-color: rgb(167 243 208 / 1) !important;
}

.tw-ring-emerald-200\/15 {
  --tw-ring-color: rgb(167 243 208 / 0.15) !important;
}

.tw-ring-emerald-200\/20 {
  --tw-ring-color: rgb(167 243 208 / 0.2) !important;
}

.tw-ring-emerald-200\/25 {
  --tw-ring-color: rgb(167 243 208 / 0.25) !important;
}

.tw-ring-emerald-200\/30 {
  --tw-ring-color: rgb(167 243 208 / 0.3) !important;
}

.tw-ring-emerald-200\/35 {
  --tw-ring-color: rgb(167 243 208 / 0.35) !important;
}

.tw-ring-emerald-200\/40 {
  --tw-ring-color: rgb(167 243 208 / 0.4) !important;
}

.tw-ring-emerald-200\/45 {
  --tw-ring-color: rgb(167 243 208 / 0.45) !important;
}

.tw-ring-emerald-200\/5 {
  --tw-ring-color: rgb(167 243 208 / 0.05) !important;
}

.tw-ring-emerald-200\/50 {
  --tw-ring-color: rgb(167 243 208 / 0.5) !important;
}

.tw-ring-emerald-200\/55 {
  --tw-ring-color: rgb(167 243 208 / 0.55) !important;
}

.tw-ring-emerald-200\/60 {
  --tw-ring-color: rgb(167 243 208 / 0.6) !important;
}

.tw-ring-emerald-200\/65 {
  --tw-ring-color: rgb(167 243 208 / 0.65) !important;
}

.tw-ring-emerald-200\/70 {
  --tw-ring-color: rgb(167 243 208 / 0.7) !important;
}

.tw-ring-emerald-200\/75 {
  --tw-ring-color: rgb(167 243 208 / 0.75) !important;
}

.tw-ring-emerald-200\/80 {
  --tw-ring-color: rgb(167 243 208 / 0.8) !important;
}

.tw-ring-emerald-200\/85 {
  --tw-ring-color: rgb(167 243 208 / 0.85) !important;
}

.tw-ring-emerald-200\/90 {
  --tw-ring-color: rgb(167 243 208 / 0.9) !important;
}

.tw-ring-emerald-200\/95 {
  --tw-ring-color: rgb(167 243 208 / 0.95) !important;
}

.tw-ring-emerald-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(110 231 183 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-300\/0 {
  --tw-ring-color: rgb(110 231 183 / 0) !important;
}

.tw-ring-emerald-300\/10 {
  --tw-ring-color: rgb(110 231 183 / 0.1) !important;
}

.tw-ring-emerald-300\/100 {
  --tw-ring-color: rgb(110 231 183 / 1) !important;
}

.tw-ring-emerald-300\/15 {
  --tw-ring-color: rgb(110 231 183 / 0.15) !important;
}

.tw-ring-emerald-300\/20 {
  --tw-ring-color: rgb(110 231 183 / 0.2) !important;
}

.tw-ring-emerald-300\/25 {
  --tw-ring-color: rgb(110 231 183 / 0.25) !important;
}

.tw-ring-emerald-300\/30 {
  --tw-ring-color: rgb(110 231 183 / 0.3) !important;
}

.tw-ring-emerald-300\/35 {
  --tw-ring-color: rgb(110 231 183 / 0.35) !important;
}

.tw-ring-emerald-300\/40 {
  --tw-ring-color: rgb(110 231 183 / 0.4) !important;
}

.tw-ring-emerald-300\/45 {
  --tw-ring-color: rgb(110 231 183 / 0.45) !important;
}

.tw-ring-emerald-300\/5 {
  --tw-ring-color: rgb(110 231 183 / 0.05) !important;
}

.tw-ring-emerald-300\/50 {
  --tw-ring-color: rgb(110 231 183 / 0.5) !important;
}

.tw-ring-emerald-300\/55 {
  --tw-ring-color: rgb(110 231 183 / 0.55) !important;
}

.tw-ring-emerald-300\/60 {
  --tw-ring-color: rgb(110 231 183 / 0.6) !important;
}

.tw-ring-emerald-300\/65 {
  --tw-ring-color: rgb(110 231 183 / 0.65) !important;
}

.tw-ring-emerald-300\/70 {
  --tw-ring-color: rgb(110 231 183 / 0.7) !important;
}

.tw-ring-emerald-300\/75 {
  --tw-ring-color: rgb(110 231 183 / 0.75) !important;
}

.tw-ring-emerald-300\/80 {
  --tw-ring-color: rgb(110 231 183 / 0.8) !important;
}

.tw-ring-emerald-300\/85 {
  --tw-ring-color: rgb(110 231 183 / 0.85) !important;
}

.tw-ring-emerald-300\/90 {
  --tw-ring-color: rgb(110 231 183 / 0.9) !important;
}

.tw-ring-emerald-300\/95 {
  --tw-ring-color: rgb(110 231 183 / 0.95) !important;
}

.tw-ring-emerald-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(52 211 153 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-400\/0 {
  --tw-ring-color: rgb(52 211 153 / 0) !important;
}

.tw-ring-emerald-400\/10 {
  --tw-ring-color: rgb(52 211 153 / 0.1) !important;
}

.tw-ring-emerald-400\/100 {
  --tw-ring-color: rgb(52 211 153 / 1) !important;
}

.tw-ring-emerald-400\/15 {
  --tw-ring-color: rgb(52 211 153 / 0.15) !important;
}

.tw-ring-emerald-400\/20 {
  --tw-ring-color: rgb(52 211 153 / 0.2) !important;
}

.tw-ring-emerald-400\/25 {
  --tw-ring-color: rgb(52 211 153 / 0.25) !important;
}

.tw-ring-emerald-400\/30 {
  --tw-ring-color: rgb(52 211 153 / 0.3) !important;
}

.tw-ring-emerald-400\/35 {
  --tw-ring-color: rgb(52 211 153 / 0.35) !important;
}

.tw-ring-emerald-400\/40 {
  --tw-ring-color: rgb(52 211 153 / 0.4) !important;
}

.tw-ring-emerald-400\/45 {
  --tw-ring-color: rgb(52 211 153 / 0.45) !important;
}

.tw-ring-emerald-400\/5 {
  --tw-ring-color: rgb(52 211 153 / 0.05) !important;
}

.tw-ring-emerald-400\/50 {
  --tw-ring-color: rgb(52 211 153 / 0.5) !important;
}

.tw-ring-emerald-400\/55 {
  --tw-ring-color: rgb(52 211 153 / 0.55) !important;
}

.tw-ring-emerald-400\/60 {
  --tw-ring-color: rgb(52 211 153 / 0.6) !important;
}

.tw-ring-emerald-400\/65 {
  --tw-ring-color: rgb(52 211 153 / 0.65) !important;
}

.tw-ring-emerald-400\/70 {
  --tw-ring-color: rgb(52 211 153 / 0.7) !important;
}

.tw-ring-emerald-400\/75 {
  --tw-ring-color: rgb(52 211 153 / 0.75) !important;
}

.tw-ring-emerald-400\/80 {
  --tw-ring-color: rgb(52 211 153 / 0.8) !important;
}

.tw-ring-emerald-400\/85 {
  --tw-ring-color: rgb(52 211 153 / 0.85) !important;
}

.tw-ring-emerald-400\/90 {
  --tw-ring-color: rgb(52 211 153 / 0.9) !important;
}

.tw-ring-emerald-400\/95 {
  --tw-ring-color: rgb(52 211 153 / 0.95) !important;
}

.tw-ring-emerald-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(236 253 245 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-50\/0 {
  --tw-ring-color: rgb(236 253 245 / 0) !important;
}

.tw-ring-emerald-50\/10 {
  --tw-ring-color: rgb(236 253 245 / 0.1) !important;
}

.tw-ring-emerald-50\/100 {
  --tw-ring-color: rgb(236 253 245 / 1) !important;
}

.tw-ring-emerald-50\/15 {
  --tw-ring-color: rgb(236 253 245 / 0.15) !important;
}

.tw-ring-emerald-50\/20 {
  --tw-ring-color: rgb(236 253 245 / 0.2) !important;
}

.tw-ring-emerald-50\/25 {
  --tw-ring-color: rgb(236 253 245 / 0.25) !important;
}

.tw-ring-emerald-50\/30 {
  --tw-ring-color: rgb(236 253 245 / 0.3) !important;
}

.tw-ring-emerald-50\/35 {
  --tw-ring-color: rgb(236 253 245 / 0.35) !important;
}

.tw-ring-emerald-50\/40 {
  --tw-ring-color: rgb(236 253 245 / 0.4) !important;
}

.tw-ring-emerald-50\/45 {
  --tw-ring-color: rgb(236 253 245 / 0.45) !important;
}

.tw-ring-emerald-50\/5 {
  --tw-ring-color: rgb(236 253 245 / 0.05) !important;
}

.tw-ring-emerald-50\/50 {
  --tw-ring-color: rgb(236 253 245 / 0.5) !important;
}

.tw-ring-emerald-50\/55 {
  --tw-ring-color: rgb(236 253 245 / 0.55) !important;
}

.tw-ring-emerald-50\/60 {
  --tw-ring-color: rgb(236 253 245 / 0.6) !important;
}

.tw-ring-emerald-50\/65 {
  --tw-ring-color: rgb(236 253 245 / 0.65) !important;
}

.tw-ring-emerald-50\/70 {
  --tw-ring-color: rgb(236 253 245 / 0.7) !important;
}

.tw-ring-emerald-50\/75 {
  --tw-ring-color: rgb(236 253 245 / 0.75) !important;
}

.tw-ring-emerald-50\/80 {
  --tw-ring-color: rgb(236 253 245 / 0.8) !important;
}

.tw-ring-emerald-50\/85 {
  --tw-ring-color: rgb(236 253 245 / 0.85) !important;
}

.tw-ring-emerald-50\/90 {
  --tw-ring-color: rgb(236 253 245 / 0.9) !important;
}

.tw-ring-emerald-50\/95 {
  --tw-ring-color: rgb(236 253 245 / 0.95) !important;
}

.tw-ring-emerald-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(16 185 129 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-500\/0 {
  --tw-ring-color: rgb(16 185 129 / 0) !important;
}

.tw-ring-emerald-500\/10 {
  --tw-ring-color: rgb(16 185 129 / 0.1) !important;
}

.tw-ring-emerald-500\/100 {
  --tw-ring-color: rgb(16 185 129 / 1) !important;
}

.tw-ring-emerald-500\/15 {
  --tw-ring-color: rgb(16 185 129 / 0.15) !important;
}

.tw-ring-emerald-500\/20 {
  --tw-ring-color: rgb(16 185 129 / 0.2) !important;
}

.tw-ring-emerald-500\/25 {
  --tw-ring-color: rgb(16 185 129 / 0.25) !important;
}

.tw-ring-emerald-500\/30 {
  --tw-ring-color: rgb(16 185 129 / 0.3) !important;
}

.tw-ring-emerald-500\/35 {
  --tw-ring-color: rgb(16 185 129 / 0.35) !important;
}

.tw-ring-emerald-500\/40 {
  --tw-ring-color: rgb(16 185 129 / 0.4) !important;
}

.tw-ring-emerald-500\/45 {
  --tw-ring-color: rgb(16 185 129 / 0.45) !important;
}

.tw-ring-emerald-500\/5 {
  --tw-ring-color: rgb(16 185 129 / 0.05) !important;
}

.tw-ring-emerald-500\/50 {
  --tw-ring-color: rgb(16 185 129 / 0.5) !important;
}

.tw-ring-emerald-500\/55 {
  --tw-ring-color: rgb(16 185 129 / 0.55) !important;
}

.tw-ring-emerald-500\/60 {
  --tw-ring-color: rgb(16 185 129 / 0.6) !important;
}

.tw-ring-emerald-500\/65 {
  --tw-ring-color: rgb(16 185 129 / 0.65) !important;
}

.tw-ring-emerald-500\/70 {
  --tw-ring-color: rgb(16 185 129 / 0.7) !important;
}

.tw-ring-emerald-500\/75 {
  --tw-ring-color: rgb(16 185 129 / 0.75) !important;
}

.tw-ring-emerald-500\/80 {
  --tw-ring-color: rgb(16 185 129 / 0.8) !important;
}

.tw-ring-emerald-500\/85 {
  --tw-ring-color: rgb(16 185 129 / 0.85) !important;
}

.tw-ring-emerald-500\/90 {
  --tw-ring-color: rgb(16 185 129 / 0.9) !important;
}

.tw-ring-emerald-500\/95 {
  --tw-ring-color: rgb(16 185 129 / 0.95) !important;
}

.tw-ring-emerald-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(5 150 105 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-600\/0 {
  --tw-ring-color: rgb(5 150 105 / 0) !important;
}

.tw-ring-emerald-600\/10 {
  --tw-ring-color: rgb(5 150 105 / 0.1) !important;
}

.tw-ring-emerald-600\/100 {
  --tw-ring-color: rgb(5 150 105 / 1) !important;
}

.tw-ring-emerald-600\/15 {
  --tw-ring-color: rgb(5 150 105 / 0.15) !important;
}

.tw-ring-emerald-600\/20 {
  --tw-ring-color: rgb(5 150 105 / 0.2) !important;
}

.tw-ring-emerald-600\/25 {
  --tw-ring-color: rgb(5 150 105 / 0.25) !important;
}

.tw-ring-emerald-600\/30 {
  --tw-ring-color: rgb(5 150 105 / 0.3) !important;
}

.tw-ring-emerald-600\/35 {
  --tw-ring-color: rgb(5 150 105 / 0.35) !important;
}

.tw-ring-emerald-600\/40 {
  --tw-ring-color: rgb(5 150 105 / 0.4) !important;
}

.tw-ring-emerald-600\/45 {
  --tw-ring-color: rgb(5 150 105 / 0.45) !important;
}

.tw-ring-emerald-600\/5 {
  --tw-ring-color: rgb(5 150 105 / 0.05) !important;
}

.tw-ring-emerald-600\/50 {
  --tw-ring-color: rgb(5 150 105 / 0.5) !important;
}

.tw-ring-emerald-600\/55 {
  --tw-ring-color: rgb(5 150 105 / 0.55) !important;
}

.tw-ring-emerald-600\/60 {
  --tw-ring-color: rgb(5 150 105 / 0.6) !important;
}

.tw-ring-emerald-600\/65 {
  --tw-ring-color: rgb(5 150 105 / 0.65) !important;
}

.tw-ring-emerald-600\/70 {
  --tw-ring-color: rgb(5 150 105 / 0.7) !important;
}

.tw-ring-emerald-600\/75 {
  --tw-ring-color: rgb(5 150 105 / 0.75) !important;
}

.tw-ring-emerald-600\/80 {
  --tw-ring-color: rgb(5 150 105 / 0.8) !important;
}

.tw-ring-emerald-600\/85 {
  --tw-ring-color: rgb(5 150 105 / 0.85) !important;
}

.tw-ring-emerald-600\/90 {
  --tw-ring-color: rgb(5 150 105 / 0.9) !important;
}

.tw-ring-emerald-600\/95 {
  --tw-ring-color: rgb(5 150 105 / 0.95) !important;
}

.tw-ring-emerald-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(4 120 87 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-700\/0 {
  --tw-ring-color: rgb(4 120 87 / 0) !important;
}

.tw-ring-emerald-700\/10 {
  --tw-ring-color: rgb(4 120 87 / 0.1) !important;
}

.tw-ring-emerald-700\/100 {
  --tw-ring-color: rgb(4 120 87 / 1) !important;
}

.tw-ring-emerald-700\/15 {
  --tw-ring-color: rgb(4 120 87 / 0.15) !important;
}

.tw-ring-emerald-700\/20 {
  --tw-ring-color: rgb(4 120 87 / 0.2) !important;
}

.tw-ring-emerald-700\/25 {
  --tw-ring-color: rgb(4 120 87 / 0.25) !important;
}

.tw-ring-emerald-700\/30 {
  --tw-ring-color: rgb(4 120 87 / 0.3) !important;
}

.tw-ring-emerald-700\/35 {
  --tw-ring-color: rgb(4 120 87 / 0.35) !important;
}

.tw-ring-emerald-700\/40 {
  --tw-ring-color: rgb(4 120 87 / 0.4) !important;
}

.tw-ring-emerald-700\/45 {
  --tw-ring-color: rgb(4 120 87 / 0.45) !important;
}

.tw-ring-emerald-700\/5 {
  --tw-ring-color: rgb(4 120 87 / 0.05) !important;
}

.tw-ring-emerald-700\/50 {
  --tw-ring-color: rgb(4 120 87 / 0.5) !important;
}

.tw-ring-emerald-700\/55 {
  --tw-ring-color: rgb(4 120 87 / 0.55) !important;
}

.tw-ring-emerald-700\/60 {
  --tw-ring-color: rgb(4 120 87 / 0.6) !important;
}

.tw-ring-emerald-700\/65 {
  --tw-ring-color: rgb(4 120 87 / 0.65) !important;
}

.tw-ring-emerald-700\/70 {
  --tw-ring-color: rgb(4 120 87 / 0.7) !important;
}

.tw-ring-emerald-700\/75 {
  --tw-ring-color: rgb(4 120 87 / 0.75) !important;
}

.tw-ring-emerald-700\/80 {
  --tw-ring-color: rgb(4 120 87 / 0.8) !important;
}

.tw-ring-emerald-700\/85 {
  --tw-ring-color: rgb(4 120 87 / 0.85) !important;
}

.tw-ring-emerald-700\/90 {
  --tw-ring-color: rgb(4 120 87 / 0.9) !important;
}

.tw-ring-emerald-700\/95 {
  --tw-ring-color: rgb(4 120 87 / 0.95) !important;
}

.tw-ring-emerald-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(6 95 70 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-800\/0 {
  --tw-ring-color: rgb(6 95 70 / 0) !important;
}

.tw-ring-emerald-800\/10 {
  --tw-ring-color: rgb(6 95 70 / 0.1) !important;
}

.tw-ring-emerald-800\/100 {
  --tw-ring-color: rgb(6 95 70 / 1) !important;
}

.tw-ring-emerald-800\/15 {
  --tw-ring-color: rgb(6 95 70 / 0.15) !important;
}

.tw-ring-emerald-800\/20 {
  --tw-ring-color: rgb(6 95 70 / 0.2) !important;
}

.tw-ring-emerald-800\/25 {
  --tw-ring-color: rgb(6 95 70 / 0.25) !important;
}

.tw-ring-emerald-800\/30 {
  --tw-ring-color: rgb(6 95 70 / 0.3) !important;
}

.tw-ring-emerald-800\/35 {
  --tw-ring-color: rgb(6 95 70 / 0.35) !important;
}

.tw-ring-emerald-800\/40 {
  --tw-ring-color: rgb(6 95 70 / 0.4) !important;
}

.tw-ring-emerald-800\/45 {
  --tw-ring-color: rgb(6 95 70 / 0.45) !important;
}

.tw-ring-emerald-800\/5 {
  --tw-ring-color: rgb(6 95 70 / 0.05) !important;
}

.tw-ring-emerald-800\/50 {
  --tw-ring-color: rgb(6 95 70 / 0.5) !important;
}

.tw-ring-emerald-800\/55 {
  --tw-ring-color: rgb(6 95 70 / 0.55) !important;
}

.tw-ring-emerald-800\/60 {
  --tw-ring-color: rgb(6 95 70 / 0.6) !important;
}

.tw-ring-emerald-800\/65 {
  --tw-ring-color: rgb(6 95 70 / 0.65) !important;
}

.tw-ring-emerald-800\/70 {
  --tw-ring-color: rgb(6 95 70 / 0.7) !important;
}

.tw-ring-emerald-800\/75 {
  --tw-ring-color: rgb(6 95 70 / 0.75) !important;
}

.tw-ring-emerald-800\/80 {
  --tw-ring-color: rgb(6 95 70 / 0.8) !important;
}

.tw-ring-emerald-800\/85 {
  --tw-ring-color: rgb(6 95 70 / 0.85) !important;
}

.tw-ring-emerald-800\/90 {
  --tw-ring-color: rgb(6 95 70 / 0.9) !important;
}

.tw-ring-emerald-800\/95 {
  --tw-ring-color: rgb(6 95 70 / 0.95) !important;
}

.tw-ring-emerald-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(6 78 59 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-900\/0 {
  --tw-ring-color: rgb(6 78 59 / 0) !important;
}

.tw-ring-emerald-900\/10 {
  --tw-ring-color: rgb(6 78 59 / 0.1) !important;
}

.tw-ring-emerald-900\/100 {
  --tw-ring-color: rgb(6 78 59 / 1) !important;
}

.tw-ring-emerald-900\/15 {
  --tw-ring-color: rgb(6 78 59 / 0.15) !important;
}

.tw-ring-emerald-900\/20 {
  --tw-ring-color: rgb(6 78 59 / 0.2) !important;
}

.tw-ring-emerald-900\/25 {
  --tw-ring-color: rgb(6 78 59 / 0.25) !important;
}

.tw-ring-emerald-900\/30 {
  --tw-ring-color: rgb(6 78 59 / 0.3) !important;
}

.tw-ring-emerald-900\/35 {
  --tw-ring-color: rgb(6 78 59 / 0.35) !important;
}

.tw-ring-emerald-900\/40 {
  --tw-ring-color: rgb(6 78 59 / 0.4) !important;
}

.tw-ring-emerald-900\/45 {
  --tw-ring-color: rgb(6 78 59 / 0.45) !important;
}

.tw-ring-emerald-900\/5 {
  --tw-ring-color: rgb(6 78 59 / 0.05) !important;
}

.tw-ring-emerald-900\/50 {
  --tw-ring-color: rgb(6 78 59 / 0.5) !important;
}

.tw-ring-emerald-900\/55 {
  --tw-ring-color: rgb(6 78 59 / 0.55) !important;
}

.tw-ring-emerald-900\/60 {
  --tw-ring-color: rgb(6 78 59 / 0.6) !important;
}

.tw-ring-emerald-900\/65 {
  --tw-ring-color: rgb(6 78 59 / 0.65) !important;
}

.tw-ring-emerald-900\/70 {
  --tw-ring-color: rgb(6 78 59 / 0.7) !important;
}

.tw-ring-emerald-900\/75 {
  --tw-ring-color: rgb(6 78 59 / 0.75) !important;
}

.tw-ring-emerald-900\/80 {
  --tw-ring-color: rgb(6 78 59 / 0.8) !important;
}

.tw-ring-emerald-900\/85 {
  --tw-ring-color: rgb(6 78 59 / 0.85) !important;
}

.tw-ring-emerald-900\/90 {
  --tw-ring-color: rgb(6 78 59 / 0.9) !important;
}

.tw-ring-emerald-900\/95 {
  --tw-ring-color: rgb(6 78 59 / 0.95) !important;
}

.tw-ring-emerald-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(2 44 34 / var(--tw-ring-opacity)) !important;
}

.tw-ring-emerald-950\/0 {
  --tw-ring-color: rgb(2 44 34 / 0) !important;
}

.tw-ring-emerald-950\/10 {
  --tw-ring-color: rgb(2 44 34 / 0.1) !important;
}

.tw-ring-emerald-950\/100 {
  --tw-ring-color: rgb(2 44 34 / 1) !important;
}

.tw-ring-emerald-950\/15 {
  --tw-ring-color: rgb(2 44 34 / 0.15) !important;
}

.tw-ring-emerald-950\/20 {
  --tw-ring-color: rgb(2 44 34 / 0.2) !important;
}

.tw-ring-emerald-950\/25 {
  --tw-ring-color: rgb(2 44 34 / 0.25) !important;
}

.tw-ring-emerald-950\/30 {
  --tw-ring-color: rgb(2 44 34 / 0.3) !important;
}

.tw-ring-emerald-950\/35 {
  --tw-ring-color: rgb(2 44 34 / 0.35) !important;
}

.tw-ring-emerald-950\/40 {
  --tw-ring-color: rgb(2 44 34 / 0.4) !important;
}

.tw-ring-emerald-950\/45 {
  --tw-ring-color: rgb(2 44 34 / 0.45) !important;
}

.tw-ring-emerald-950\/5 {
  --tw-ring-color: rgb(2 44 34 / 0.05) !important;
}

.tw-ring-emerald-950\/50 {
  --tw-ring-color: rgb(2 44 34 / 0.5) !important;
}

.tw-ring-emerald-950\/55 {
  --tw-ring-color: rgb(2 44 34 / 0.55) !important;
}

.tw-ring-emerald-950\/60 {
  --tw-ring-color: rgb(2 44 34 / 0.6) !important;
}

.tw-ring-emerald-950\/65 {
  --tw-ring-color: rgb(2 44 34 / 0.65) !important;
}

.tw-ring-emerald-950\/70 {
  --tw-ring-color: rgb(2 44 34 / 0.7) !important;
}

.tw-ring-emerald-950\/75 {
  --tw-ring-color: rgb(2 44 34 / 0.75) !important;
}

.tw-ring-emerald-950\/80 {
  --tw-ring-color: rgb(2 44 34 / 0.8) !important;
}

.tw-ring-emerald-950\/85 {
  --tw-ring-color: rgb(2 44 34 / 0.85) !important;
}

.tw-ring-emerald-950\/90 {
  --tw-ring-color: rgb(2 44 34 / 0.9) !important;
}

.tw-ring-emerald-950\/95 {
  --tw-ring-color: rgb(2 44 34 / 0.95) !important;
}

.tw-ring-fuchsia-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(250 232 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-100\/0 {
  --tw-ring-color: rgb(250 232 255 / 0) !important;
}

.tw-ring-fuchsia-100\/10 {
  --tw-ring-color: rgb(250 232 255 / 0.1) !important;
}

.tw-ring-fuchsia-100\/100 {
  --tw-ring-color: rgb(250 232 255 / 1) !important;
}

.tw-ring-fuchsia-100\/15 {
  --tw-ring-color: rgb(250 232 255 / 0.15) !important;
}

.tw-ring-fuchsia-100\/20 {
  --tw-ring-color: rgb(250 232 255 / 0.2) !important;
}

.tw-ring-fuchsia-100\/25 {
  --tw-ring-color: rgb(250 232 255 / 0.25) !important;
}

.tw-ring-fuchsia-100\/30 {
  --tw-ring-color: rgb(250 232 255 / 0.3) !important;
}

.tw-ring-fuchsia-100\/35 {
  --tw-ring-color: rgb(250 232 255 / 0.35) !important;
}

.tw-ring-fuchsia-100\/40 {
  --tw-ring-color: rgb(250 232 255 / 0.4) !important;
}

.tw-ring-fuchsia-100\/45 {
  --tw-ring-color: rgb(250 232 255 / 0.45) !important;
}

.tw-ring-fuchsia-100\/5 {
  --tw-ring-color: rgb(250 232 255 / 0.05) !important;
}

.tw-ring-fuchsia-100\/50 {
  --tw-ring-color: rgb(250 232 255 / 0.5) !important;
}

.tw-ring-fuchsia-100\/55 {
  --tw-ring-color: rgb(250 232 255 / 0.55) !important;
}

.tw-ring-fuchsia-100\/60 {
  --tw-ring-color: rgb(250 232 255 / 0.6) !important;
}

.tw-ring-fuchsia-100\/65 {
  --tw-ring-color: rgb(250 232 255 / 0.65) !important;
}

.tw-ring-fuchsia-100\/70 {
  --tw-ring-color: rgb(250 232 255 / 0.7) !important;
}

.tw-ring-fuchsia-100\/75 {
  --tw-ring-color: rgb(250 232 255 / 0.75) !important;
}

.tw-ring-fuchsia-100\/80 {
  --tw-ring-color: rgb(250 232 255 / 0.8) !important;
}

.tw-ring-fuchsia-100\/85 {
  --tw-ring-color: rgb(250 232 255 / 0.85) !important;
}

.tw-ring-fuchsia-100\/90 {
  --tw-ring-color: rgb(250 232 255 / 0.9) !important;
}

.tw-ring-fuchsia-100\/95 {
  --tw-ring-color: rgb(250 232 255 / 0.95) !important;
}

.tw-ring-fuchsia-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(245 208 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-200\/0 {
  --tw-ring-color: rgb(245 208 254 / 0) !important;
}

.tw-ring-fuchsia-200\/10 {
  --tw-ring-color: rgb(245 208 254 / 0.1) !important;
}

.tw-ring-fuchsia-200\/100 {
  --tw-ring-color: rgb(245 208 254 / 1) !important;
}

.tw-ring-fuchsia-200\/15 {
  --tw-ring-color: rgb(245 208 254 / 0.15) !important;
}

.tw-ring-fuchsia-200\/20 {
  --tw-ring-color: rgb(245 208 254 / 0.2) !important;
}

.tw-ring-fuchsia-200\/25 {
  --tw-ring-color: rgb(245 208 254 / 0.25) !important;
}

.tw-ring-fuchsia-200\/30 {
  --tw-ring-color: rgb(245 208 254 / 0.3) !important;
}

.tw-ring-fuchsia-200\/35 {
  --tw-ring-color: rgb(245 208 254 / 0.35) !important;
}

.tw-ring-fuchsia-200\/40 {
  --tw-ring-color: rgb(245 208 254 / 0.4) !important;
}

.tw-ring-fuchsia-200\/45 {
  --tw-ring-color: rgb(245 208 254 / 0.45) !important;
}

.tw-ring-fuchsia-200\/5 {
  --tw-ring-color: rgb(245 208 254 / 0.05) !important;
}

.tw-ring-fuchsia-200\/50 {
  --tw-ring-color: rgb(245 208 254 / 0.5) !important;
}

.tw-ring-fuchsia-200\/55 {
  --tw-ring-color: rgb(245 208 254 / 0.55) !important;
}

.tw-ring-fuchsia-200\/60 {
  --tw-ring-color: rgb(245 208 254 / 0.6) !important;
}

.tw-ring-fuchsia-200\/65 {
  --tw-ring-color: rgb(245 208 254 / 0.65) !important;
}

.tw-ring-fuchsia-200\/70 {
  --tw-ring-color: rgb(245 208 254 / 0.7) !important;
}

.tw-ring-fuchsia-200\/75 {
  --tw-ring-color: rgb(245 208 254 / 0.75) !important;
}

.tw-ring-fuchsia-200\/80 {
  --tw-ring-color: rgb(245 208 254 / 0.8) !important;
}

.tw-ring-fuchsia-200\/85 {
  --tw-ring-color: rgb(245 208 254 / 0.85) !important;
}

.tw-ring-fuchsia-200\/90 {
  --tw-ring-color: rgb(245 208 254 / 0.9) !important;
}

.tw-ring-fuchsia-200\/95 {
  --tw-ring-color: rgb(245 208 254 / 0.95) !important;
}

.tw-ring-fuchsia-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(240 171 252 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-300\/0 {
  --tw-ring-color: rgb(240 171 252 / 0) !important;
}

.tw-ring-fuchsia-300\/10 {
  --tw-ring-color: rgb(240 171 252 / 0.1) !important;
}

.tw-ring-fuchsia-300\/100 {
  --tw-ring-color: rgb(240 171 252 / 1) !important;
}

.tw-ring-fuchsia-300\/15 {
  --tw-ring-color: rgb(240 171 252 / 0.15) !important;
}

.tw-ring-fuchsia-300\/20 {
  --tw-ring-color: rgb(240 171 252 / 0.2) !important;
}

.tw-ring-fuchsia-300\/25 {
  --tw-ring-color: rgb(240 171 252 / 0.25) !important;
}

.tw-ring-fuchsia-300\/30 {
  --tw-ring-color: rgb(240 171 252 / 0.3) !important;
}

.tw-ring-fuchsia-300\/35 {
  --tw-ring-color: rgb(240 171 252 / 0.35) !important;
}

.tw-ring-fuchsia-300\/40 {
  --tw-ring-color: rgb(240 171 252 / 0.4) !important;
}

.tw-ring-fuchsia-300\/45 {
  --tw-ring-color: rgb(240 171 252 / 0.45) !important;
}

.tw-ring-fuchsia-300\/5 {
  --tw-ring-color: rgb(240 171 252 / 0.05) !important;
}

.tw-ring-fuchsia-300\/50 {
  --tw-ring-color: rgb(240 171 252 / 0.5) !important;
}

.tw-ring-fuchsia-300\/55 {
  --tw-ring-color: rgb(240 171 252 / 0.55) !important;
}

.tw-ring-fuchsia-300\/60 {
  --tw-ring-color: rgb(240 171 252 / 0.6) !important;
}

.tw-ring-fuchsia-300\/65 {
  --tw-ring-color: rgb(240 171 252 / 0.65) !important;
}

.tw-ring-fuchsia-300\/70 {
  --tw-ring-color: rgb(240 171 252 / 0.7) !important;
}

.tw-ring-fuchsia-300\/75 {
  --tw-ring-color: rgb(240 171 252 / 0.75) !important;
}

.tw-ring-fuchsia-300\/80 {
  --tw-ring-color: rgb(240 171 252 / 0.8) !important;
}

.tw-ring-fuchsia-300\/85 {
  --tw-ring-color: rgb(240 171 252 / 0.85) !important;
}

.tw-ring-fuchsia-300\/90 {
  --tw-ring-color: rgb(240 171 252 / 0.9) !important;
}

.tw-ring-fuchsia-300\/95 {
  --tw-ring-color: rgb(240 171 252 / 0.95) !important;
}

.tw-ring-fuchsia-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(232 121 249 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-400\/0 {
  --tw-ring-color: rgb(232 121 249 / 0) !important;
}

.tw-ring-fuchsia-400\/10 {
  --tw-ring-color: rgb(232 121 249 / 0.1) !important;
}

.tw-ring-fuchsia-400\/100 {
  --tw-ring-color: rgb(232 121 249 / 1) !important;
}

.tw-ring-fuchsia-400\/15 {
  --tw-ring-color: rgb(232 121 249 / 0.15) !important;
}

.tw-ring-fuchsia-400\/20 {
  --tw-ring-color: rgb(232 121 249 / 0.2) !important;
}

.tw-ring-fuchsia-400\/25 {
  --tw-ring-color: rgb(232 121 249 / 0.25) !important;
}

.tw-ring-fuchsia-400\/30 {
  --tw-ring-color: rgb(232 121 249 / 0.3) !important;
}

.tw-ring-fuchsia-400\/35 {
  --tw-ring-color: rgb(232 121 249 / 0.35) !important;
}

.tw-ring-fuchsia-400\/40 {
  --tw-ring-color: rgb(232 121 249 / 0.4) !important;
}

.tw-ring-fuchsia-400\/45 {
  --tw-ring-color: rgb(232 121 249 / 0.45) !important;
}

.tw-ring-fuchsia-400\/5 {
  --tw-ring-color: rgb(232 121 249 / 0.05) !important;
}

.tw-ring-fuchsia-400\/50 {
  --tw-ring-color: rgb(232 121 249 / 0.5) !important;
}

.tw-ring-fuchsia-400\/55 {
  --tw-ring-color: rgb(232 121 249 / 0.55) !important;
}

.tw-ring-fuchsia-400\/60 {
  --tw-ring-color: rgb(232 121 249 / 0.6) !important;
}

.tw-ring-fuchsia-400\/65 {
  --tw-ring-color: rgb(232 121 249 / 0.65) !important;
}

.tw-ring-fuchsia-400\/70 {
  --tw-ring-color: rgb(232 121 249 / 0.7) !important;
}

.tw-ring-fuchsia-400\/75 {
  --tw-ring-color: rgb(232 121 249 / 0.75) !important;
}

.tw-ring-fuchsia-400\/80 {
  --tw-ring-color: rgb(232 121 249 / 0.8) !important;
}

.tw-ring-fuchsia-400\/85 {
  --tw-ring-color: rgb(232 121 249 / 0.85) !important;
}

.tw-ring-fuchsia-400\/90 {
  --tw-ring-color: rgb(232 121 249 / 0.9) !important;
}

.tw-ring-fuchsia-400\/95 {
  --tw-ring-color: rgb(232 121 249 / 0.95) !important;
}

.tw-ring-fuchsia-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(253 244 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-50\/0 {
  --tw-ring-color: rgb(253 244 255 / 0) !important;
}

.tw-ring-fuchsia-50\/10 {
  --tw-ring-color: rgb(253 244 255 / 0.1) !important;
}

.tw-ring-fuchsia-50\/100 {
  --tw-ring-color: rgb(253 244 255 / 1) !important;
}

.tw-ring-fuchsia-50\/15 {
  --tw-ring-color: rgb(253 244 255 / 0.15) !important;
}

.tw-ring-fuchsia-50\/20 {
  --tw-ring-color: rgb(253 244 255 / 0.2) !important;
}

.tw-ring-fuchsia-50\/25 {
  --tw-ring-color: rgb(253 244 255 / 0.25) !important;
}

.tw-ring-fuchsia-50\/30 {
  --tw-ring-color: rgb(253 244 255 / 0.3) !important;
}

.tw-ring-fuchsia-50\/35 {
  --tw-ring-color: rgb(253 244 255 / 0.35) !important;
}

.tw-ring-fuchsia-50\/40 {
  --tw-ring-color: rgb(253 244 255 / 0.4) !important;
}

.tw-ring-fuchsia-50\/45 {
  --tw-ring-color: rgb(253 244 255 / 0.45) !important;
}

.tw-ring-fuchsia-50\/5 {
  --tw-ring-color: rgb(253 244 255 / 0.05) !important;
}

.tw-ring-fuchsia-50\/50 {
  --tw-ring-color: rgb(253 244 255 / 0.5) !important;
}

.tw-ring-fuchsia-50\/55 {
  --tw-ring-color: rgb(253 244 255 / 0.55) !important;
}

.tw-ring-fuchsia-50\/60 {
  --tw-ring-color: rgb(253 244 255 / 0.6) !important;
}

.tw-ring-fuchsia-50\/65 {
  --tw-ring-color: rgb(253 244 255 / 0.65) !important;
}

.tw-ring-fuchsia-50\/70 {
  --tw-ring-color: rgb(253 244 255 / 0.7) !important;
}

.tw-ring-fuchsia-50\/75 {
  --tw-ring-color: rgb(253 244 255 / 0.75) !important;
}

.tw-ring-fuchsia-50\/80 {
  --tw-ring-color: rgb(253 244 255 / 0.8) !important;
}

.tw-ring-fuchsia-50\/85 {
  --tw-ring-color: rgb(253 244 255 / 0.85) !important;
}

.tw-ring-fuchsia-50\/90 {
  --tw-ring-color: rgb(253 244 255 / 0.9) !important;
}

.tw-ring-fuchsia-50\/95 {
  --tw-ring-color: rgb(253 244 255 / 0.95) !important;
}

.tw-ring-fuchsia-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(217 70 239 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-500\/0 {
  --tw-ring-color: rgb(217 70 239 / 0) !important;
}

.tw-ring-fuchsia-500\/10 {
  --tw-ring-color: rgb(217 70 239 / 0.1) !important;
}

.tw-ring-fuchsia-500\/100 {
  --tw-ring-color: rgb(217 70 239 / 1) !important;
}

.tw-ring-fuchsia-500\/15 {
  --tw-ring-color: rgb(217 70 239 / 0.15) !important;
}

.tw-ring-fuchsia-500\/20 {
  --tw-ring-color: rgb(217 70 239 / 0.2) !important;
}

.tw-ring-fuchsia-500\/25 {
  --tw-ring-color: rgb(217 70 239 / 0.25) !important;
}

.tw-ring-fuchsia-500\/30 {
  --tw-ring-color: rgb(217 70 239 / 0.3) !important;
}

.tw-ring-fuchsia-500\/35 {
  --tw-ring-color: rgb(217 70 239 / 0.35) !important;
}

.tw-ring-fuchsia-500\/40 {
  --tw-ring-color: rgb(217 70 239 / 0.4) !important;
}

.tw-ring-fuchsia-500\/45 {
  --tw-ring-color: rgb(217 70 239 / 0.45) !important;
}

.tw-ring-fuchsia-500\/5 {
  --tw-ring-color: rgb(217 70 239 / 0.05) !important;
}

.tw-ring-fuchsia-500\/50 {
  --tw-ring-color: rgb(217 70 239 / 0.5) !important;
}

.tw-ring-fuchsia-500\/55 {
  --tw-ring-color: rgb(217 70 239 / 0.55) !important;
}

.tw-ring-fuchsia-500\/60 {
  --tw-ring-color: rgb(217 70 239 / 0.6) !important;
}

.tw-ring-fuchsia-500\/65 {
  --tw-ring-color: rgb(217 70 239 / 0.65) !important;
}

.tw-ring-fuchsia-500\/70 {
  --tw-ring-color: rgb(217 70 239 / 0.7) !important;
}

.tw-ring-fuchsia-500\/75 {
  --tw-ring-color: rgb(217 70 239 / 0.75) !important;
}

.tw-ring-fuchsia-500\/80 {
  --tw-ring-color: rgb(217 70 239 / 0.8) !important;
}

.tw-ring-fuchsia-500\/85 {
  --tw-ring-color: rgb(217 70 239 / 0.85) !important;
}

.tw-ring-fuchsia-500\/90 {
  --tw-ring-color: rgb(217 70 239 / 0.9) !important;
}

.tw-ring-fuchsia-500\/95 {
  --tw-ring-color: rgb(217 70 239 / 0.95) !important;
}

.tw-ring-fuchsia-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(192 38 211 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-600\/0 {
  --tw-ring-color: rgb(192 38 211 / 0) !important;
}

.tw-ring-fuchsia-600\/10 {
  --tw-ring-color: rgb(192 38 211 / 0.1) !important;
}

.tw-ring-fuchsia-600\/100 {
  --tw-ring-color: rgb(192 38 211 / 1) !important;
}

.tw-ring-fuchsia-600\/15 {
  --tw-ring-color: rgb(192 38 211 / 0.15) !important;
}

.tw-ring-fuchsia-600\/20 {
  --tw-ring-color: rgb(192 38 211 / 0.2) !important;
}

.tw-ring-fuchsia-600\/25 {
  --tw-ring-color: rgb(192 38 211 / 0.25) !important;
}

.tw-ring-fuchsia-600\/30 {
  --tw-ring-color: rgb(192 38 211 / 0.3) !important;
}

.tw-ring-fuchsia-600\/35 {
  --tw-ring-color: rgb(192 38 211 / 0.35) !important;
}

.tw-ring-fuchsia-600\/40 {
  --tw-ring-color: rgb(192 38 211 / 0.4) !important;
}

.tw-ring-fuchsia-600\/45 {
  --tw-ring-color: rgb(192 38 211 / 0.45) !important;
}

.tw-ring-fuchsia-600\/5 {
  --tw-ring-color: rgb(192 38 211 / 0.05) !important;
}

.tw-ring-fuchsia-600\/50 {
  --tw-ring-color: rgb(192 38 211 / 0.5) !important;
}

.tw-ring-fuchsia-600\/55 {
  --tw-ring-color: rgb(192 38 211 / 0.55) !important;
}

.tw-ring-fuchsia-600\/60 {
  --tw-ring-color: rgb(192 38 211 / 0.6) !important;
}

.tw-ring-fuchsia-600\/65 {
  --tw-ring-color: rgb(192 38 211 / 0.65) !important;
}

.tw-ring-fuchsia-600\/70 {
  --tw-ring-color: rgb(192 38 211 / 0.7) !important;
}

.tw-ring-fuchsia-600\/75 {
  --tw-ring-color: rgb(192 38 211 / 0.75) !important;
}

.tw-ring-fuchsia-600\/80 {
  --tw-ring-color: rgb(192 38 211 / 0.8) !important;
}

.tw-ring-fuchsia-600\/85 {
  --tw-ring-color: rgb(192 38 211 / 0.85) !important;
}

.tw-ring-fuchsia-600\/90 {
  --tw-ring-color: rgb(192 38 211 / 0.9) !important;
}

.tw-ring-fuchsia-600\/95 {
  --tw-ring-color: rgb(192 38 211 / 0.95) !important;
}

.tw-ring-fuchsia-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(162 28 175 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-700\/0 {
  --tw-ring-color: rgb(162 28 175 / 0) !important;
}

.tw-ring-fuchsia-700\/10 {
  --tw-ring-color: rgb(162 28 175 / 0.1) !important;
}

.tw-ring-fuchsia-700\/100 {
  --tw-ring-color: rgb(162 28 175 / 1) !important;
}

.tw-ring-fuchsia-700\/15 {
  --tw-ring-color: rgb(162 28 175 / 0.15) !important;
}

.tw-ring-fuchsia-700\/20 {
  --tw-ring-color: rgb(162 28 175 / 0.2) !important;
}

.tw-ring-fuchsia-700\/25 {
  --tw-ring-color: rgb(162 28 175 / 0.25) !important;
}

.tw-ring-fuchsia-700\/30 {
  --tw-ring-color: rgb(162 28 175 / 0.3) !important;
}

.tw-ring-fuchsia-700\/35 {
  --tw-ring-color: rgb(162 28 175 / 0.35) !important;
}

.tw-ring-fuchsia-700\/40 {
  --tw-ring-color: rgb(162 28 175 / 0.4) !important;
}

.tw-ring-fuchsia-700\/45 {
  --tw-ring-color: rgb(162 28 175 / 0.45) !important;
}

.tw-ring-fuchsia-700\/5 {
  --tw-ring-color: rgb(162 28 175 / 0.05) !important;
}

.tw-ring-fuchsia-700\/50 {
  --tw-ring-color: rgb(162 28 175 / 0.5) !important;
}

.tw-ring-fuchsia-700\/55 {
  --tw-ring-color: rgb(162 28 175 / 0.55) !important;
}

.tw-ring-fuchsia-700\/60 {
  --tw-ring-color: rgb(162 28 175 / 0.6) !important;
}

.tw-ring-fuchsia-700\/65 {
  --tw-ring-color: rgb(162 28 175 / 0.65) !important;
}

.tw-ring-fuchsia-700\/70 {
  --tw-ring-color: rgb(162 28 175 / 0.7) !important;
}

.tw-ring-fuchsia-700\/75 {
  --tw-ring-color: rgb(162 28 175 / 0.75) !important;
}

.tw-ring-fuchsia-700\/80 {
  --tw-ring-color: rgb(162 28 175 / 0.8) !important;
}

.tw-ring-fuchsia-700\/85 {
  --tw-ring-color: rgb(162 28 175 / 0.85) !important;
}

.tw-ring-fuchsia-700\/90 {
  --tw-ring-color: rgb(162 28 175 / 0.9) !important;
}

.tw-ring-fuchsia-700\/95 {
  --tw-ring-color: rgb(162 28 175 / 0.95) !important;
}

.tw-ring-fuchsia-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(134 25 143 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-800\/0 {
  --tw-ring-color: rgb(134 25 143 / 0) !important;
}

.tw-ring-fuchsia-800\/10 {
  --tw-ring-color: rgb(134 25 143 / 0.1) !important;
}

.tw-ring-fuchsia-800\/100 {
  --tw-ring-color: rgb(134 25 143 / 1) !important;
}

.tw-ring-fuchsia-800\/15 {
  --tw-ring-color: rgb(134 25 143 / 0.15) !important;
}

.tw-ring-fuchsia-800\/20 {
  --tw-ring-color: rgb(134 25 143 / 0.2) !important;
}

.tw-ring-fuchsia-800\/25 {
  --tw-ring-color: rgb(134 25 143 / 0.25) !important;
}

.tw-ring-fuchsia-800\/30 {
  --tw-ring-color: rgb(134 25 143 / 0.3) !important;
}

.tw-ring-fuchsia-800\/35 {
  --tw-ring-color: rgb(134 25 143 / 0.35) !important;
}

.tw-ring-fuchsia-800\/40 {
  --tw-ring-color: rgb(134 25 143 / 0.4) !important;
}

.tw-ring-fuchsia-800\/45 {
  --tw-ring-color: rgb(134 25 143 / 0.45) !important;
}

.tw-ring-fuchsia-800\/5 {
  --tw-ring-color: rgb(134 25 143 / 0.05) !important;
}

.tw-ring-fuchsia-800\/50 {
  --tw-ring-color: rgb(134 25 143 / 0.5) !important;
}

.tw-ring-fuchsia-800\/55 {
  --tw-ring-color: rgb(134 25 143 / 0.55) !important;
}

.tw-ring-fuchsia-800\/60 {
  --tw-ring-color: rgb(134 25 143 / 0.6) !important;
}

.tw-ring-fuchsia-800\/65 {
  --tw-ring-color: rgb(134 25 143 / 0.65) !important;
}

.tw-ring-fuchsia-800\/70 {
  --tw-ring-color: rgb(134 25 143 / 0.7) !important;
}

.tw-ring-fuchsia-800\/75 {
  --tw-ring-color: rgb(134 25 143 / 0.75) !important;
}

.tw-ring-fuchsia-800\/80 {
  --tw-ring-color: rgb(134 25 143 / 0.8) !important;
}

.tw-ring-fuchsia-800\/85 {
  --tw-ring-color: rgb(134 25 143 / 0.85) !important;
}

.tw-ring-fuchsia-800\/90 {
  --tw-ring-color: rgb(134 25 143 / 0.9) !important;
}

.tw-ring-fuchsia-800\/95 {
  --tw-ring-color: rgb(134 25 143 / 0.95) !important;
}

.tw-ring-fuchsia-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(112 26 117 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-900\/0 {
  --tw-ring-color: rgb(112 26 117 / 0) !important;
}

.tw-ring-fuchsia-900\/10 {
  --tw-ring-color: rgb(112 26 117 / 0.1) !important;
}

.tw-ring-fuchsia-900\/100 {
  --tw-ring-color: rgb(112 26 117 / 1) !important;
}

.tw-ring-fuchsia-900\/15 {
  --tw-ring-color: rgb(112 26 117 / 0.15) !important;
}

.tw-ring-fuchsia-900\/20 {
  --tw-ring-color: rgb(112 26 117 / 0.2) !important;
}

.tw-ring-fuchsia-900\/25 {
  --tw-ring-color: rgb(112 26 117 / 0.25) !important;
}

.tw-ring-fuchsia-900\/30 {
  --tw-ring-color: rgb(112 26 117 / 0.3) !important;
}

.tw-ring-fuchsia-900\/35 {
  --tw-ring-color: rgb(112 26 117 / 0.35) !important;
}

.tw-ring-fuchsia-900\/40 {
  --tw-ring-color: rgb(112 26 117 / 0.4) !important;
}

.tw-ring-fuchsia-900\/45 {
  --tw-ring-color: rgb(112 26 117 / 0.45) !important;
}

.tw-ring-fuchsia-900\/5 {
  --tw-ring-color: rgb(112 26 117 / 0.05) !important;
}

.tw-ring-fuchsia-900\/50 {
  --tw-ring-color: rgb(112 26 117 / 0.5) !important;
}

.tw-ring-fuchsia-900\/55 {
  --tw-ring-color: rgb(112 26 117 / 0.55) !important;
}

.tw-ring-fuchsia-900\/60 {
  --tw-ring-color: rgb(112 26 117 / 0.6) !important;
}

.tw-ring-fuchsia-900\/65 {
  --tw-ring-color: rgb(112 26 117 / 0.65) !important;
}

.tw-ring-fuchsia-900\/70 {
  --tw-ring-color: rgb(112 26 117 / 0.7) !important;
}

.tw-ring-fuchsia-900\/75 {
  --tw-ring-color: rgb(112 26 117 / 0.75) !important;
}

.tw-ring-fuchsia-900\/80 {
  --tw-ring-color: rgb(112 26 117 / 0.8) !important;
}

.tw-ring-fuchsia-900\/85 {
  --tw-ring-color: rgb(112 26 117 / 0.85) !important;
}

.tw-ring-fuchsia-900\/90 {
  --tw-ring-color: rgb(112 26 117 / 0.9) !important;
}

.tw-ring-fuchsia-900\/95 {
  --tw-ring-color: rgb(112 26 117 / 0.95) !important;
}

.tw-ring-fuchsia-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(74 4 78 / var(--tw-ring-opacity)) !important;
}

.tw-ring-fuchsia-950\/0 {
  --tw-ring-color: rgb(74 4 78 / 0) !important;
}

.tw-ring-fuchsia-950\/10 {
  --tw-ring-color: rgb(74 4 78 / 0.1) !important;
}

.tw-ring-fuchsia-950\/100 {
  --tw-ring-color: rgb(74 4 78 / 1) !important;
}

.tw-ring-fuchsia-950\/15 {
  --tw-ring-color: rgb(74 4 78 / 0.15) !important;
}

.tw-ring-fuchsia-950\/20 {
  --tw-ring-color: rgb(74 4 78 / 0.2) !important;
}

.tw-ring-fuchsia-950\/25 {
  --tw-ring-color: rgb(74 4 78 / 0.25) !important;
}

.tw-ring-fuchsia-950\/30 {
  --tw-ring-color: rgb(74 4 78 / 0.3) !important;
}

.tw-ring-fuchsia-950\/35 {
  --tw-ring-color: rgb(74 4 78 / 0.35) !important;
}

.tw-ring-fuchsia-950\/40 {
  --tw-ring-color: rgb(74 4 78 / 0.4) !important;
}

.tw-ring-fuchsia-950\/45 {
  --tw-ring-color: rgb(74 4 78 / 0.45) !important;
}

.tw-ring-fuchsia-950\/5 {
  --tw-ring-color: rgb(74 4 78 / 0.05) !important;
}

.tw-ring-fuchsia-950\/50 {
  --tw-ring-color: rgb(74 4 78 / 0.5) !important;
}

.tw-ring-fuchsia-950\/55 {
  --tw-ring-color: rgb(74 4 78 / 0.55) !important;
}

.tw-ring-fuchsia-950\/60 {
  --tw-ring-color: rgb(74 4 78 / 0.6) !important;
}

.tw-ring-fuchsia-950\/65 {
  --tw-ring-color: rgb(74 4 78 / 0.65) !important;
}

.tw-ring-fuchsia-950\/70 {
  --tw-ring-color: rgb(74 4 78 / 0.7) !important;
}

.tw-ring-fuchsia-950\/75 {
  --tw-ring-color: rgb(74 4 78 / 0.75) !important;
}

.tw-ring-fuchsia-950\/80 {
  --tw-ring-color: rgb(74 4 78 / 0.8) !important;
}

.tw-ring-fuchsia-950\/85 {
  --tw-ring-color: rgb(74 4 78 / 0.85) !important;
}

.tw-ring-fuchsia-950\/90 {
  --tw-ring-color: rgb(74 4 78 / 0.9) !important;
}

.tw-ring-fuchsia-950\/95 {
  --tw-ring-color: rgb(74 4 78 / 0.95) !important;
}

.tw-ring-gray-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-100\/0 {
  --tw-ring-color: rgb(243 244 246 / 0) !important;
}

.tw-ring-gray-100\/10 {
  --tw-ring-color: rgb(243 244 246 / 0.1) !important;
}

.tw-ring-gray-100\/100 {
  --tw-ring-color: rgb(243 244 246 / 1) !important;
}

.tw-ring-gray-100\/15 {
  --tw-ring-color: rgb(243 244 246 / 0.15) !important;
}

.tw-ring-gray-100\/20 {
  --tw-ring-color: rgb(243 244 246 / 0.2) !important;
}

.tw-ring-gray-100\/25 {
  --tw-ring-color: rgb(243 244 246 / 0.25) !important;
}

.tw-ring-gray-100\/30 {
  --tw-ring-color: rgb(243 244 246 / 0.3) !important;
}

.tw-ring-gray-100\/35 {
  --tw-ring-color: rgb(243 244 246 / 0.35) !important;
}

.tw-ring-gray-100\/40 {
  --tw-ring-color: rgb(243 244 246 / 0.4) !important;
}

.tw-ring-gray-100\/45 {
  --tw-ring-color: rgb(243 244 246 / 0.45) !important;
}

.tw-ring-gray-100\/5 {
  --tw-ring-color: rgb(243 244 246 / 0.05) !important;
}

.tw-ring-gray-100\/50 {
  --tw-ring-color: rgb(243 244 246 / 0.5) !important;
}

.tw-ring-gray-100\/55 {
  --tw-ring-color: rgb(243 244 246 / 0.55) !important;
}

.tw-ring-gray-100\/60 {
  --tw-ring-color: rgb(243 244 246 / 0.6) !important;
}

.tw-ring-gray-100\/65 {
  --tw-ring-color: rgb(243 244 246 / 0.65) !important;
}

.tw-ring-gray-100\/70 {
  --tw-ring-color: rgb(243 244 246 / 0.7) !important;
}

.tw-ring-gray-100\/75 {
  --tw-ring-color: rgb(243 244 246 / 0.75) !important;
}

.tw-ring-gray-100\/80 {
  --tw-ring-color: rgb(243 244 246 / 0.8) !important;
}

.tw-ring-gray-100\/85 {
  --tw-ring-color: rgb(243 244 246 / 0.85) !important;
}

.tw-ring-gray-100\/90 {
  --tw-ring-color: rgb(243 244 246 / 0.9) !important;
}

.tw-ring-gray-100\/95 {
  --tw-ring-color: rgb(243 244 246 / 0.95) !important;
}

.tw-ring-gray-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(229 231 235 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-200\/0 {
  --tw-ring-color: rgb(229 231 235 / 0) !important;
}

.tw-ring-gray-200\/10 {
  --tw-ring-color: rgb(229 231 235 / 0.1) !important;
}

.tw-ring-gray-200\/100 {
  --tw-ring-color: rgb(229 231 235 / 1) !important;
}

.tw-ring-gray-200\/15 {
  --tw-ring-color: rgb(229 231 235 / 0.15) !important;
}

.tw-ring-gray-200\/20 {
  --tw-ring-color: rgb(229 231 235 / 0.2) !important;
}

.tw-ring-gray-200\/25 {
  --tw-ring-color: rgb(229 231 235 / 0.25) !important;
}

.tw-ring-gray-200\/30 {
  --tw-ring-color: rgb(229 231 235 / 0.3) !important;
}

.tw-ring-gray-200\/35 {
  --tw-ring-color: rgb(229 231 235 / 0.35) !important;
}

.tw-ring-gray-200\/40 {
  --tw-ring-color: rgb(229 231 235 / 0.4) !important;
}

.tw-ring-gray-200\/45 {
  --tw-ring-color: rgb(229 231 235 / 0.45) !important;
}

.tw-ring-gray-200\/5 {
  --tw-ring-color: rgb(229 231 235 / 0.05) !important;
}

.tw-ring-gray-200\/50 {
  --tw-ring-color: rgb(229 231 235 / 0.5) !important;
}

.tw-ring-gray-200\/55 {
  --tw-ring-color: rgb(229 231 235 / 0.55) !important;
}

.tw-ring-gray-200\/60 {
  --tw-ring-color: rgb(229 231 235 / 0.6) !important;
}

.tw-ring-gray-200\/65 {
  --tw-ring-color: rgb(229 231 235 / 0.65) !important;
}

.tw-ring-gray-200\/70 {
  --tw-ring-color: rgb(229 231 235 / 0.7) !important;
}

.tw-ring-gray-200\/75 {
  --tw-ring-color: rgb(229 231 235 / 0.75) !important;
}

.tw-ring-gray-200\/80 {
  --tw-ring-color: rgb(229 231 235 / 0.8) !important;
}

.tw-ring-gray-200\/85 {
  --tw-ring-color: rgb(229 231 235 / 0.85) !important;
}

.tw-ring-gray-200\/90 {
  --tw-ring-color: rgb(229 231 235 / 0.9) !important;
}

.tw-ring-gray-200\/95 {
  --tw-ring-color: rgb(229 231 235 / 0.95) !important;
}

.tw-ring-gray-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-300\/0 {
  --tw-ring-color: rgb(209 213 219 / 0) !important;
}

.tw-ring-gray-300\/10 {
  --tw-ring-color: rgb(209 213 219 / 0.1) !important;
}

.tw-ring-gray-300\/100 {
  --tw-ring-color: rgb(209 213 219 / 1) !important;
}

.tw-ring-gray-300\/15 {
  --tw-ring-color: rgb(209 213 219 / 0.15) !important;
}

.tw-ring-gray-300\/20 {
  --tw-ring-color: rgb(209 213 219 / 0.2) !important;
}

.tw-ring-gray-300\/25 {
  --tw-ring-color: rgb(209 213 219 / 0.25) !important;
}

.tw-ring-gray-300\/30 {
  --tw-ring-color: rgb(209 213 219 / 0.3) !important;
}

.tw-ring-gray-300\/35 {
  --tw-ring-color: rgb(209 213 219 / 0.35) !important;
}

.tw-ring-gray-300\/40 {
  --tw-ring-color: rgb(209 213 219 / 0.4) !important;
}

.tw-ring-gray-300\/45 {
  --tw-ring-color: rgb(209 213 219 / 0.45) !important;
}

.tw-ring-gray-300\/5 {
  --tw-ring-color: rgb(209 213 219 / 0.05) !important;
}

.tw-ring-gray-300\/50 {
  --tw-ring-color: rgb(209 213 219 / 0.5) !important;
}

.tw-ring-gray-300\/55 {
  --tw-ring-color: rgb(209 213 219 / 0.55) !important;
}

.tw-ring-gray-300\/60 {
  --tw-ring-color: rgb(209 213 219 / 0.6) !important;
}

.tw-ring-gray-300\/65 {
  --tw-ring-color: rgb(209 213 219 / 0.65) !important;
}

.tw-ring-gray-300\/70 {
  --tw-ring-color: rgb(209 213 219 / 0.7) !important;
}

.tw-ring-gray-300\/75 {
  --tw-ring-color: rgb(209 213 219 / 0.75) !important;
}

.tw-ring-gray-300\/80 {
  --tw-ring-color: rgb(209 213 219 / 0.8) !important;
}

.tw-ring-gray-300\/85 {
  --tw-ring-color: rgb(209 213 219 / 0.85) !important;
}

.tw-ring-gray-300\/90 {
  --tw-ring-color: rgb(209 213 219 / 0.9) !important;
}

.tw-ring-gray-300\/95 {
  --tw-ring-color: rgb(209 213 219 / 0.95) !important;
}

.tw-ring-gray-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(156 163 175 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-400\/0 {
  --tw-ring-color: rgb(156 163 175 / 0) !important;
}

.tw-ring-gray-400\/10 {
  --tw-ring-color: rgb(156 163 175 / 0.1) !important;
}

.tw-ring-gray-400\/100 {
  --tw-ring-color: rgb(156 163 175 / 1) !important;
}

.tw-ring-gray-400\/15 {
  --tw-ring-color: rgb(156 163 175 / 0.15) !important;
}

.tw-ring-gray-400\/20 {
  --tw-ring-color: rgb(156 163 175 / 0.2) !important;
}

.tw-ring-gray-400\/25 {
  --tw-ring-color: rgb(156 163 175 / 0.25) !important;
}

.tw-ring-gray-400\/30 {
  --tw-ring-color: rgb(156 163 175 / 0.3) !important;
}

.tw-ring-gray-400\/35 {
  --tw-ring-color: rgb(156 163 175 / 0.35) !important;
}

.tw-ring-gray-400\/40 {
  --tw-ring-color: rgb(156 163 175 / 0.4) !important;
}

.tw-ring-gray-400\/45 {
  --tw-ring-color: rgb(156 163 175 / 0.45) !important;
}

.tw-ring-gray-400\/5 {
  --tw-ring-color: rgb(156 163 175 / 0.05) !important;
}

.tw-ring-gray-400\/50 {
  --tw-ring-color: rgb(156 163 175 / 0.5) !important;
}

.tw-ring-gray-400\/55 {
  --tw-ring-color: rgb(156 163 175 / 0.55) !important;
}

.tw-ring-gray-400\/60 {
  --tw-ring-color: rgb(156 163 175 / 0.6) !important;
}

.tw-ring-gray-400\/65 {
  --tw-ring-color: rgb(156 163 175 / 0.65) !important;
}

.tw-ring-gray-400\/70 {
  --tw-ring-color: rgb(156 163 175 / 0.7) !important;
}

.tw-ring-gray-400\/75 {
  --tw-ring-color: rgb(156 163 175 / 0.75) !important;
}

.tw-ring-gray-400\/80 {
  --tw-ring-color: rgb(156 163 175 / 0.8) !important;
}

.tw-ring-gray-400\/85 {
  --tw-ring-color: rgb(156 163 175 / 0.85) !important;
}

.tw-ring-gray-400\/90 {
  --tw-ring-color: rgb(156 163 175 / 0.9) !important;
}

.tw-ring-gray-400\/95 {
  --tw-ring-color: rgb(156 163 175 / 0.95) !important;
}

.tw-ring-gray-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(249 250 251 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-50\/0 {
  --tw-ring-color: rgb(249 250 251 / 0) !important;
}

.tw-ring-gray-50\/10 {
  --tw-ring-color: rgb(249 250 251 / 0.1) !important;
}

.tw-ring-gray-50\/100 {
  --tw-ring-color: rgb(249 250 251 / 1) !important;
}

.tw-ring-gray-50\/15 {
  --tw-ring-color: rgb(249 250 251 / 0.15) !important;
}

.tw-ring-gray-50\/20 {
  --tw-ring-color: rgb(249 250 251 / 0.2) !important;
}

.tw-ring-gray-50\/25 {
  --tw-ring-color: rgb(249 250 251 / 0.25) !important;
}

.tw-ring-gray-50\/30 {
  --tw-ring-color: rgb(249 250 251 / 0.3) !important;
}

.tw-ring-gray-50\/35 {
  --tw-ring-color: rgb(249 250 251 / 0.35) !important;
}

.tw-ring-gray-50\/40 {
  --tw-ring-color: rgb(249 250 251 / 0.4) !important;
}

.tw-ring-gray-50\/45 {
  --tw-ring-color: rgb(249 250 251 / 0.45) !important;
}

.tw-ring-gray-50\/5 {
  --tw-ring-color: rgb(249 250 251 / 0.05) !important;
}

.tw-ring-gray-50\/50 {
  --tw-ring-color: rgb(249 250 251 / 0.5) !important;
}

.tw-ring-gray-50\/55 {
  --tw-ring-color: rgb(249 250 251 / 0.55) !important;
}

.tw-ring-gray-50\/60 {
  --tw-ring-color: rgb(249 250 251 / 0.6) !important;
}

.tw-ring-gray-50\/65 {
  --tw-ring-color: rgb(249 250 251 / 0.65) !important;
}

.tw-ring-gray-50\/70 {
  --tw-ring-color: rgb(249 250 251 / 0.7) !important;
}

.tw-ring-gray-50\/75 {
  --tw-ring-color: rgb(249 250 251 / 0.75) !important;
}

.tw-ring-gray-50\/80 {
  --tw-ring-color: rgb(249 250 251 / 0.8) !important;
}

.tw-ring-gray-50\/85 {
  --tw-ring-color: rgb(249 250 251 / 0.85) !important;
}

.tw-ring-gray-50\/90 {
  --tw-ring-color: rgb(249 250 251 / 0.9) !important;
}

.tw-ring-gray-50\/95 {
  --tw-ring-color: rgb(249 250 251 / 0.95) !important;
}

.tw-ring-gray-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(107 114 128 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-500\/0 {
  --tw-ring-color: rgb(107 114 128 / 0) !important;
}

.tw-ring-gray-500\/10 {
  --tw-ring-color: rgb(107 114 128 / 0.1) !important;
}

.tw-ring-gray-500\/100 {
  --tw-ring-color: rgb(107 114 128 / 1) !important;
}

.tw-ring-gray-500\/15 {
  --tw-ring-color: rgb(107 114 128 / 0.15) !important;
}

.tw-ring-gray-500\/20 {
  --tw-ring-color: rgb(107 114 128 / 0.2) !important;
}

.tw-ring-gray-500\/25 {
  --tw-ring-color: rgb(107 114 128 / 0.25) !important;
}

.tw-ring-gray-500\/30 {
  --tw-ring-color: rgb(107 114 128 / 0.3) !important;
}

.tw-ring-gray-500\/35 {
  --tw-ring-color: rgb(107 114 128 / 0.35) !important;
}

.tw-ring-gray-500\/40 {
  --tw-ring-color: rgb(107 114 128 / 0.4) !important;
}

.tw-ring-gray-500\/45 {
  --tw-ring-color: rgb(107 114 128 / 0.45) !important;
}

.tw-ring-gray-500\/5 {
  --tw-ring-color: rgb(107 114 128 / 0.05) !important;
}

.tw-ring-gray-500\/50 {
  --tw-ring-color: rgb(107 114 128 / 0.5) !important;
}

.tw-ring-gray-500\/55 {
  --tw-ring-color: rgb(107 114 128 / 0.55) !important;
}

.tw-ring-gray-500\/60 {
  --tw-ring-color: rgb(107 114 128 / 0.6) !important;
}

.tw-ring-gray-500\/65 {
  --tw-ring-color: rgb(107 114 128 / 0.65) !important;
}

.tw-ring-gray-500\/70 {
  --tw-ring-color: rgb(107 114 128 / 0.7) !important;
}

.tw-ring-gray-500\/75 {
  --tw-ring-color: rgb(107 114 128 / 0.75) !important;
}

.tw-ring-gray-500\/80 {
  --tw-ring-color: rgb(107 114 128 / 0.8) !important;
}

.tw-ring-gray-500\/85 {
  --tw-ring-color: rgb(107 114 128 / 0.85) !important;
}

.tw-ring-gray-500\/90 {
  --tw-ring-color: rgb(107 114 128 / 0.9) !important;
}

.tw-ring-gray-500\/95 {
  --tw-ring-color: rgb(107 114 128 / 0.95) !important;
}

.tw-ring-gray-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(75 85 99 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-600\/0 {
  --tw-ring-color: rgb(75 85 99 / 0) !important;
}

.tw-ring-gray-600\/10 {
  --tw-ring-color: rgb(75 85 99 / 0.1) !important;
}

.tw-ring-gray-600\/100 {
  --tw-ring-color: rgb(75 85 99 / 1) !important;
}

.tw-ring-gray-600\/15 {
  --tw-ring-color: rgb(75 85 99 / 0.15) !important;
}

.tw-ring-gray-600\/20 {
  --tw-ring-color: rgb(75 85 99 / 0.2) !important;
}

.tw-ring-gray-600\/25 {
  --tw-ring-color: rgb(75 85 99 / 0.25) !important;
}

.tw-ring-gray-600\/30 {
  --tw-ring-color: rgb(75 85 99 / 0.3) !important;
}

.tw-ring-gray-600\/35 {
  --tw-ring-color: rgb(75 85 99 / 0.35) !important;
}

.tw-ring-gray-600\/40 {
  --tw-ring-color: rgb(75 85 99 / 0.4) !important;
}

.tw-ring-gray-600\/45 {
  --tw-ring-color: rgb(75 85 99 / 0.45) !important;
}

.tw-ring-gray-600\/5 {
  --tw-ring-color: rgb(75 85 99 / 0.05) !important;
}

.tw-ring-gray-600\/50 {
  --tw-ring-color: rgb(75 85 99 / 0.5) !important;
}

.tw-ring-gray-600\/55 {
  --tw-ring-color: rgb(75 85 99 / 0.55) !important;
}

.tw-ring-gray-600\/60 {
  --tw-ring-color: rgb(75 85 99 / 0.6) !important;
}

.tw-ring-gray-600\/65 {
  --tw-ring-color: rgb(75 85 99 / 0.65) !important;
}

.tw-ring-gray-600\/70 {
  --tw-ring-color: rgb(75 85 99 / 0.7) !important;
}

.tw-ring-gray-600\/75 {
  --tw-ring-color: rgb(75 85 99 / 0.75) !important;
}

.tw-ring-gray-600\/80 {
  --tw-ring-color: rgb(75 85 99 / 0.8) !important;
}

.tw-ring-gray-600\/85 {
  --tw-ring-color: rgb(75 85 99 / 0.85) !important;
}

.tw-ring-gray-600\/90 {
  --tw-ring-color: rgb(75 85 99 / 0.9) !important;
}

.tw-ring-gray-600\/95 {
  --tw-ring-color: rgb(75 85 99 / 0.95) !important;
}

.tw-ring-gray-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-700\/0 {
  --tw-ring-color: rgb(55 65 81 / 0) !important;
}

.tw-ring-gray-700\/10 {
  --tw-ring-color: rgb(55 65 81 / 0.1) !important;
}

.tw-ring-gray-700\/100 {
  --tw-ring-color: rgb(55 65 81 / 1) !important;
}

.tw-ring-gray-700\/15 {
  --tw-ring-color: rgb(55 65 81 / 0.15) !important;
}

.tw-ring-gray-700\/20 {
  --tw-ring-color: rgb(55 65 81 / 0.2) !important;
}

.tw-ring-gray-700\/25 {
  --tw-ring-color: rgb(55 65 81 / 0.25) !important;
}

.tw-ring-gray-700\/30 {
  --tw-ring-color: rgb(55 65 81 / 0.3) !important;
}

.tw-ring-gray-700\/35 {
  --tw-ring-color: rgb(55 65 81 / 0.35) !important;
}

.tw-ring-gray-700\/40 {
  --tw-ring-color: rgb(55 65 81 / 0.4) !important;
}

.tw-ring-gray-700\/45 {
  --tw-ring-color: rgb(55 65 81 / 0.45) !important;
}

.tw-ring-gray-700\/5 {
  --tw-ring-color: rgb(55 65 81 / 0.05) !important;
}

.tw-ring-gray-700\/50 {
  --tw-ring-color: rgb(55 65 81 / 0.5) !important;
}

.tw-ring-gray-700\/55 {
  --tw-ring-color: rgb(55 65 81 / 0.55) !important;
}

.tw-ring-gray-700\/60 {
  --tw-ring-color: rgb(55 65 81 / 0.6) !important;
}

.tw-ring-gray-700\/65 {
  --tw-ring-color: rgb(55 65 81 / 0.65) !important;
}

.tw-ring-gray-700\/70 {
  --tw-ring-color: rgb(55 65 81 / 0.7) !important;
}

.tw-ring-gray-700\/75 {
  --tw-ring-color: rgb(55 65 81 / 0.75) !important;
}

.tw-ring-gray-700\/80 {
  --tw-ring-color: rgb(55 65 81 / 0.8) !important;
}

.tw-ring-gray-700\/85 {
  --tw-ring-color: rgb(55 65 81 / 0.85) !important;
}

.tw-ring-gray-700\/90 {
  --tw-ring-color: rgb(55 65 81 / 0.9) !important;
}

.tw-ring-gray-700\/95 {
  --tw-ring-color: rgb(55 65 81 / 0.95) !important;
}

.tw-ring-gray-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(31 41 55 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-800\/0 {
  --tw-ring-color: rgb(31 41 55 / 0) !important;
}

.tw-ring-gray-800\/10 {
  --tw-ring-color: rgb(31 41 55 / 0.1) !important;
}

.tw-ring-gray-800\/100 {
  --tw-ring-color: rgb(31 41 55 / 1) !important;
}

.tw-ring-gray-800\/15 {
  --tw-ring-color: rgb(31 41 55 / 0.15) !important;
}

.tw-ring-gray-800\/20 {
  --tw-ring-color: rgb(31 41 55 / 0.2) !important;
}

.tw-ring-gray-800\/25 {
  --tw-ring-color: rgb(31 41 55 / 0.25) !important;
}

.tw-ring-gray-800\/30 {
  --tw-ring-color: rgb(31 41 55 / 0.3) !important;
}

.tw-ring-gray-800\/35 {
  --tw-ring-color: rgb(31 41 55 / 0.35) !important;
}

.tw-ring-gray-800\/40 {
  --tw-ring-color: rgb(31 41 55 / 0.4) !important;
}

.tw-ring-gray-800\/45 {
  --tw-ring-color: rgb(31 41 55 / 0.45) !important;
}

.tw-ring-gray-800\/5 {
  --tw-ring-color: rgb(31 41 55 / 0.05) !important;
}

.tw-ring-gray-800\/50 {
  --tw-ring-color: rgb(31 41 55 / 0.5) !important;
}

.tw-ring-gray-800\/55 {
  --tw-ring-color: rgb(31 41 55 / 0.55) !important;
}

.tw-ring-gray-800\/60 {
  --tw-ring-color: rgb(31 41 55 / 0.6) !important;
}

.tw-ring-gray-800\/65 {
  --tw-ring-color: rgb(31 41 55 / 0.65) !important;
}

.tw-ring-gray-800\/70 {
  --tw-ring-color: rgb(31 41 55 / 0.7) !important;
}

.tw-ring-gray-800\/75 {
  --tw-ring-color: rgb(31 41 55 / 0.75) !important;
}

.tw-ring-gray-800\/80 {
  --tw-ring-color: rgb(31 41 55 / 0.8) !important;
}

.tw-ring-gray-800\/85 {
  --tw-ring-color: rgb(31 41 55 / 0.85) !important;
}

.tw-ring-gray-800\/90 {
  --tw-ring-color: rgb(31 41 55 / 0.9) !important;
}

.tw-ring-gray-800\/95 {
  --tw-ring-color: rgb(31 41 55 / 0.95) !important;
}

.tw-ring-gray-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(17 24 39 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-900\/0 {
  --tw-ring-color: rgb(17 24 39 / 0) !important;
}

.tw-ring-gray-900\/10 {
  --tw-ring-color: rgb(17 24 39 / 0.1) !important;
}

.tw-ring-gray-900\/100 {
  --tw-ring-color: rgb(17 24 39 / 1) !important;
}

.tw-ring-gray-900\/15 {
  --tw-ring-color: rgb(17 24 39 / 0.15) !important;
}

.tw-ring-gray-900\/20 {
  --tw-ring-color: rgb(17 24 39 / 0.2) !important;
}

.tw-ring-gray-900\/25 {
  --tw-ring-color: rgb(17 24 39 / 0.25) !important;
}

.tw-ring-gray-900\/30 {
  --tw-ring-color: rgb(17 24 39 / 0.3) !important;
}

.tw-ring-gray-900\/35 {
  --tw-ring-color: rgb(17 24 39 / 0.35) !important;
}

.tw-ring-gray-900\/40 {
  --tw-ring-color: rgb(17 24 39 / 0.4) !important;
}

.tw-ring-gray-900\/45 {
  --tw-ring-color: rgb(17 24 39 / 0.45) !important;
}

.tw-ring-gray-900\/5 {
  --tw-ring-color: rgb(17 24 39 / 0.05) !important;
}

.tw-ring-gray-900\/50 {
  --tw-ring-color: rgb(17 24 39 / 0.5) !important;
}

.tw-ring-gray-900\/55 {
  --tw-ring-color: rgb(17 24 39 / 0.55) !important;
}

.tw-ring-gray-900\/60 {
  --tw-ring-color: rgb(17 24 39 / 0.6) !important;
}

.tw-ring-gray-900\/65 {
  --tw-ring-color: rgb(17 24 39 / 0.65) !important;
}

.tw-ring-gray-900\/70 {
  --tw-ring-color: rgb(17 24 39 / 0.7) !important;
}

.tw-ring-gray-900\/75 {
  --tw-ring-color: rgb(17 24 39 / 0.75) !important;
}

.tw-ring-gray-900\/80 {
  --tw-ring-color: rgb(17 24 39 / 0.8) !important;
}

.tw-ring-gray-900\/85 {
  --tw-ring-color: rgb(17 24 39 / 0.85) !important;
}

.tw-ring-gray-900\/90 {
  --tw-ring-color: rgb(17 24 39 / 0.9) !important;
}

.tw-ring-gray-900\/95 {
  --tw-ring-color: rgb(17 24 39 / 0.95) !important;
}

.tw-ring-gray-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(3 7 18 / var(--tw-ring-opacity)) !important;
}

.tw-ring-gray-950\/0 {
  --tw-ring-color: rgb(3 7 18 / 0) !important;
}

.tw-ring-gray-950\/10 {
  --tw-ring-color: rgb(3 7 18 / 0.1) !important;
}

.tw-ring-gray-950\/100 {
  --tw-ring-color: rgb(3 7 18 / 1) !important;
}

.tw-ring-gray-950\/15 {
  --tw-ring-color: rgb(3 7 18 / 0.15) !important;
}

.tw-ring-gray-950\/20 {
  --tw-ring-color: rgb(3 7 18 / 0.2) !important;
}

.tw-ring-gray-950\/25 {
  --tw-ring-color: rgb(3 7 18 / 0.25) !important;
}

.tw-ring-gray-950\/30 {
  --tw-ring-color: rgb(3 7 18 / 0.3) !important;
}

.tw-ring-gray-950\/35 {
  --tw-ring-color: rgb(3 7 18 / 0.35) !important;
}

.tw-ring-gray-950\/40 {
  --tw-ring-color: rgb(3 7 18 / 0.4) !important;
}

.tw-ring-gray-950\/45 {
  --tw-ring-color: rgb(3 7 18 / 0.45) !important;
}

.tw-ring-gray-950\/5 {
  --tw-ring-color: rgb(3 7 18 / 0.05) !important;
}

.tw-ring-gray-950\/50 {
  --tw-ring-color: rgb(3 7 18 / 0.5) !important;
}

.tw-ring-gray-950\/55 {
  --tw-ring-color: rgb(3 7 18 / 0.55) !important;
}

.tw-ring-gray-950\/60 {
  --tw-ring-color: rgb(3 7 18 / 0.6) !important;
}

.tw-ring-gray-950\/65 {
  --tw-ring-color: rgb(3 7 18 / 0.65) !important;
}

.tw-ring-gray-950\/70 {
  --tw-ring-color: rgb(3 7 18 / 0.7) !important;
}

.tw-ring-gray-950\/75 {
  --tw-ring-color: rgb(3 7 18 / 0.75) !important;
}

.tw-ring-gray-950\/80 {
  --tw-ring-color: rgb(3 7 18 / 0.8) !important;
}

.tw-ring-gray-950\/85 {
  --tw-ring-color: rgb(3 7 18 / 0.85) !important;
}

.tw-ring-gray-950\/90 {
  --tw-ring-color: rgb(3 7 18 / 0.9) !important;
}

.tw-ring-gray-950\/95 {
  --tw-ring-color: rgb(3 7 18 / 0.95) !important;
}

.tw-ring-green-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(220 252 231 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-100\/0 {
  --tw-ring-color: rgb(220 252 231 / 0) !important;
}

.tw-ring-green-100\/10 {
  --tw-ring-color: rgb(220 252 231 / 0.1) !important;
}

.tw-ring-green-100\/100 {
  --tw-ring-color: rgb(220 252 231 / 1) !important;
}

.tw-ring-green-100\/15 {
  --tw-ring-color: rgb(220 252 231 / 0.15) !important;
}

.tw-ring-green-100\/20 {
  --tw-ring-color: rgb(220 252 231 / 0.2) !important;
}

.tw-ring-green-100\/25 {
  --tw-ring-color: rgb(220 252 231 / 0.25) !important;
}

.tw-ring-green-100\/30 {
  --tw-ring-color: rgb(220 252 231 / 0.3) !important;
}

.tw-ring-green-100\/35 {
  --tw-ring-color: rgb(220 252 231 / 0.35) !important;
}

.tw-ring-green-100\/40 {
  --tw-ring-color: rgb(220 252 231 / 0.4) !important;
}

.tw-ring-green-100\/45 {
  --tw-ring-color: rgb(220 252 231 / 0.45) !important;
}

.tw-ring-green-100\/5 {
  --tw-ring-color: rgb(220 252 231 / 0.05) !important;
}

.tw-ring-green-100\/50 {
  --tw-ring-color: rgb(220 252 231 / 0.5) !important;
}

.tw-ring-green-100\/55 {
  --tw-ring-color: rgb(220 252 231 / 0.55) !important;
}

.tw-ring-green-100\/60 {
  --tw-ring-color: rgb(220 252 231 / 0.6) !important;
}

.tw-ring-green-100\/65 {
  --tw-ring-color: rgb(220 252 231 / 0.65) !important;
}

.tw-ring-green-100\/70 {
  --tw-ring-color: rgb(220 252 231 / 0.7) !important;
}

.tw-ring-green-100\/75 {
  --tw-ring-color: rgb(220 252 231 / 0.75) !important;
}

.tw-ring-green-100\/80 {
  --tw-ring-color: rgb(220 252 231 / 0.8) !important;
}

.tw-ring-green-100\/85 {
  --tw-ring-color: rgb(220 252 231 / 0.85) !important;
}

.tw-ring-green-100\/90 {
  --tw-ring-color: rgb(220 252 231 / 0.9) !important;
}

.tw-ring-green-100\/95 {
  --tw-ring-color: rgb(220 252 231 / 0.95) !important;
}

.tw-ring-green-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(187 247 208 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-200\/0 {
  --tw-ring-color: rgb(187 247 208 / 0) !important;
}

.tw-ring-green-200\/10 {
  --tw-ring-color: rgb(187 247 208 / 0.1) !important;
}

.tw-ring-green-200\/100 {
  --tw-ring-color: rgb(187 247 208 / 1) !important;
}

.tw-ring-green-200\/15 {
  --tw-ring-color: rgb(187 247 208 / 0.15) !important;
}

.tw-ring-green-200\/20 {
  --tw-ring-color: rgb(187 247 208 / 0.2) !important;
}

.tw-ring-green-200\/25 {
  --tw-ring-color: rgb(187 247 208 / 0.25) !important;
}

.tw-ring-green-200\/30 {
  --tw-ring-color: rgb(187 247 208 / 0.3) !important;
}

.tw-ring-green-200\/35 {
  --tw-ring-color: rgb(187 247 208 / 0.35) !important;
}

.tw-ring-green-200\/40 {
  --tw-ring-color: rgb(187 247 208 / 0.4) !important;
}

.tw-ring-green-200\/45 {
  --tw-ring-color: rgb(187 247 208 / 0.45) !important;
}

.tw-ring-green-200\/5 {
  --tw-ring-color: rgb(187 247 208 / 0.05) !important;
}

.tw-ring-green-200\/50 {
  --tw-ring-color: rgb(187 247 208 / 0.5) !important;
}

.tw-ring-green-200\/55 {
  --tw-ring-color: rgb(187 247 208 / 0.55) !important;
}

.tw-ring-green-200\/60 {
  --tw-ring-color: rgb(187 247 208 / 0.6) !important;
}

.tw-ring-green-200\/65 {
  --tw-ring-color: rgb(187 247 208 / 0.65) !important;
}

.tw-ring-green-200\/70 {
  --tw-ring-color: rgb(187 247 208 / 0.7) !important;
}

.tw-ring-green-200\/75 {
  --tw-ring-color: rgb(187 247 208 / 0.75) !important;
}

.tw-ring-green-200\/80 {
  --tw-ring-color: rgb(187 247 208 / 0.8) !important;
}

.tw-ring-green-200\/85 {
  --tw-ring-color: rgb(187 247 208 / 0.85) !important;
}

.tw-ring-green-200\/90 {
  --tw-ring-color: rgb(187 247 208 / 0.9) !important;
}

.tw-ring-green-200\/95 {
  --tw-ring-color: rgb(187 247 208 / 0.95) !important;
}

.tw-ring-green-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(134 239 172 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-300\/0 {
  --tw-ring-color: rgb(134 239 172 / 0) !important;
}

.tw-ring-green-300\/10 {
  --tw-ring-color: rgb(134 239 172 / 0.1) !important;
}

.tw-ring-green-300\/100 {
  --tw-ring-color: rgb(134 239 172 / 1) !important;
}

.tw-ring-green-300\/15 {
  --tw-ring-color: rgb(134 239 172 / 0.15) !important;
}

.tw-ring-green-300\/20 {
  --tw-ring-color: rgb(134 239 172 / 0.2) !important;
}

.tw-ring-green-300\/25 {
  --tw-ring-color: rgb(134 239 172 / 0.25) !important;
}

.tw-ring-green-300\/30 {
  --tw-ring-color: rgb(134 239 172 / 0.3) !important;
}

.tw-ring-green-300\/35 {
  --tw-ring-color: rgb(134 239 172 / 0.35) !important;
}

.tw-ring-green-300\/40 {
  --tw-ring-color: rgb(134 239 172 / 0.4) !important;
}

.tw-ring-green-300\/45 {
  --tw-ring-color: rgb(134 239 172 / 0.45) !important;
}

.tw-ring-green-300\/5 {
  --tw-ring-color: rgb(134 239 172 / 0.05) !important;
}

.tw-ring-green-300\/50 {
  --tw-ring-color: rgb(134 239 172 / 0.5) !important;
}

.tw-ring-green-300\/55 {
  --tw-ring-color: rgb(134 239 172 / 0.55) !important;
}

.tw-ring-green-300\/60 {
  --tw-ring-color: rgb(134 239 172 / 0.6) !important;
}

.tw-ring-green-300\/65 {
  --tw-ring-color: rgb(134 239 172 / 0.65) !important;
}

.tw-ring-green-300\/70 {
  --tw-ring-color: rgb(134 239 172 / 0.7) !important;
}

.tw-ring-green-300\/75 {
  --tw-ring-color: rgb(134 239 172 / 0.75) !important;
}

.tw-ring-green-300\/80 {
  --tw-ring-color: rgb(134 239 172 / 0.8) !important;
}

.tw-ring-green-300\/85 {
  --tw-ring-color: rgb(134 239 172 / 0.85) !important;
}

.tw-ring-green-300\/90 {
  --tw-ring-color: rgb(134 239 172 / 0.9) !important;
}

.tw-ring-green-300\/95 {
  --tw-ring-color: rgb(134 239 172 / 0.95) !important;
}

.tw-ring-green-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(74 222 128 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-400\/0 {
  --tw-ring-color: rgb(74 222 128 / 0) !important;
}

.tw-ring-green-400\/10 {
  --tw-ring-color: rgb(74 222 128 / 0.1) !important;
}

.tw-ring-green-400\/100 {
  --tw-ring-color: rgb(74 222 128 / 1) !important;
}

.tw-ring-green-400\/15 {
  --tw-ring-color: rgb(74 222 128 / 0.15) !important;
}

.tw-ring-green-400\/20 {
  --tw-ring-color: rgb(74 222 128 / 0.2) !important;
}

.tw-ring-green-400\/25 {
  --tw-ring-color: rgb(74 222 128 / 0.25) !important;
}

.tw-ring-green-400\/30 {
  --tw-ring-color: rgb(74 222 128 / 0.3) !important;
}

.tw-ring-green-400\/35 {
  --tw-ring-color: rgb(74 222 128 / 0.35) !important;
}

.tw-ring-green-400\/40 {
  --tw-ring-color: rgb(74 222 128 / 0.4) !important;
}

.tw-ring-green-400\/45 {
  --tw-ring-color: rgb(74 222 128 / 0.45) !important;
}

.tw-ring-green-400\/5 {
  --tw-ring-color: rgb(74 222 128 / 0.05) !important;
}

.tw-ring-green-400\/50 {
  --tw-ring-color: rgb(74 222 128 / 0.5) !important;
}

.tw-ring-green-400\/55 {
  --tw-ring-color: rgb(74 222 128 / 0.55) !important;
}

.tw-ring-green-400\/60 {
  --tw-ring-color: rgb(74 222 128 / 0.6) !important;
}

.tw-ring-green-400\/65 {
  --tw-ring-color: rgb(74 222 128 / 0.65) !important;
}

.tw-ring-green-400\/70 {
  --tw-ring-color: rgb(74 222 128 / 0.7) !important;
}

.tw-ring-green-400\/75 {
  --tw-ring-color: rgb(74 222 128 / 0.75) !important;
}

.tw-ring-green-400\/80 {
  --tw-ring-color: rgb(74 222 128 / 0.8) !important;
}

.tw-ring-green-400\/85 {
  --tw-ring-color: rgb(74 222 128 / 0.85) !important;
}

.tw-ring-green-400\/90 {
  --tw-ring-color: rgb(74 222 128 / 0.9) !important;
}

.tw-ring-green-400\/95 {
  --tw-ring-color: rgb(74 222 128 / 0.95) !important;
}

.tw-ring-green-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(240 253 244 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-50\/0 {
  --tw-ring-color: rgb(240 253 244 / 0) !important;
}

.tw-ring-green-50\/10 {
  --tw-ring-color: rgb(240 253 244 / 0.1) !important;
}

.tw-ring-green-50\/100 {
  --tw-ring-color: rgb(240 253 244 / 1) !important;
}

.tw-ring-green-50\/15 {
  --tw-ring-color: rgb(240 253 244 / 0.15) !important;
}

.tw-ring-green-50\/20 {
  --tw-ring-color: rgb(240 253 244 / 0.2) !important;
}

.tw-ring-green-50\/25 {
  --tw-ring-color: rgb(240 253 244 / 0.25) !important;
}

.tw-ring-green-50\/30 {
  --tw-ring-color: rgb(240 253 244 / 0.3) !important;
}

.tw-ring-green-50\/35 {
  --tw-ring-color: rgb(240 253 244 / 0.35) !important;
}

.tw-ring-green-50\/40 {
  --tw-ring-color: rgb(240 253 244 / 0.4) !important;
}

.tw-ring-green-50\/45 {
  --tw-ring-color: rgb(240 253 244 / 0.45) !important;
}

.tw-ring-green-50\/5 {
  --tw-ring-color: rgb(240 253 244 / 0.05) !important;
}

.tw-ring-green-50\/50 {
  --tw-ring-color: rgb(240 253 244 / 0.5) !important;
}

.tw-ring-green-50\/55 {
  --tw-ring-color: rgb(240 253 244 / 0.55) !important;
}

.tw-ring-green-50\/60 {
  --tw-ring-color: rgb(240 253 244 / 0.6) !important;
}

.tw-ring-green-50\/65 {
  --tw-ring-color: rgb(240 253 244 / 0.65) !important;
}

.tw-ring-green-50\/70 {
  --tw-ring-color: rgb(240 253 244 / 0.7) !important;
}

.tw-ring-green-50\/75 {
  --tw-ring-color: rgb(240 253 244 / 0.75) !important;
}

.tw-ring-green-50\/80 {
  --tw-ring-color: rgb(240 253 244 / 0.8) !important;
}

.tw-ring-green-50\/85 {
  --tw-ring-color: rgb(240 253 244 / 0.85) !important;
}

.tw-ring-green-50\/90 {
  --tw-ring-color: rgb(240 253 244 / 0.9) !important;
}

.tw-ring-green-50\/95 {
  --tw-ring-color: rgb(240 253 244 / 0.95) !important;
}

.tw-ring-green-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(34 197 94 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-500\/0 {
  --tw-ring-color: rgb(34 197 94 / 0) !important;
}

.tw-ring-green-500\/10 {
  --tw-ring-color: rgb(34 197 94 / 0.1) !important;
}

.tw-ring-green-500\/100 {
  --tw-ring-color: rgb(34 197 94 / 1) !important;
}

.tw-ring-green-500\/15 {
  --tw-ring-color: rgb(34 197 94 / 0.15) !important;
}

.tw-ring-green-500\/20 {
  --tw-ring-color: rgb(34 197 94 / 0.2) !important;
}

.tw-ring-green-500\/25 {
  --tw-ring-color: rgb(34 197 94 / 0.25) !important;
}

.tw-ring-green-500\/30 {
  --tw-ring-color: rgb(34 197 94 / 0.3) !important;
}

.tw-ring-green-500\/35 {
  --tw-ring-color: rgb(34 197 94 / 0.35) !important;
}

.tw-ring-green-500\/40 {
  --tw-ring-color: rgb(34 197 94 / 0.4) !important;
}

.tw-ring-green-500\/45 {
  --tw-ring-color: rgb(34 197 94 / 0.45) !important;
}

.tw-ring-green-500\/5 {
  --tw-ring-color: rgb(34 197 94 / 0.05) !important;
}

.tw-ring-green-500\/50 {
  --tw-ring-color: rgb(34 197 94 / 0.5) !important;
}

.tw-ring-green-500\/55 {
  --tw-ring-color: rgb(34 197 94 / 0.55) !important;
}

.tw-ring-green-500\/60 {
  --tw-ring-color: rgb(34 197 94 / 0.6) !important;
}

.tw-ring-green-500\/65 {
  --tw-ring-color: rgb(34 197 94 / 0.65) !important;
}

.tw-ring-green-500\/70 {
  --tw-ring-color: rgb(34 197 94 / 0.7) !important;
}

.tw-ring-green-500\/75 {
  --tw-ring-color: rgb(34 197 94 / 0.75) !important;
}

.tw-ring-green-500\/80 {
  --tw-ring-color: rgb(34 197 94 / 0.8) !important;
}

.tw-ring-green-500\/85 {
  --tw-ring-color: rgb(34 197 94 / 0.85) !important;
}

.tw-ring-green-500\/90 {
  --tw-ring-color: rgb(34 197 94 / 0.9) !important;
}

.tw-ring-green-500\/95 {
  --tw-ring-color: rgb(34 197 94 / 0.95) !important;
}

.tw-ring-green-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(22 163 74 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-600\/0 {
  --tw-ring-color: rgb(22 163 74 / 0) !important;
}

.tw-ring-green-600\/10 {
  --tw-ring-color: rgb(22 163 74 / 0.1) !important;
}

.tw-ring-green-600\/100 {
  --tw-ring-color: rgb(22 163 74 / 1) !important;
}

.tw-ring-green-600\/15 {
  --tw-ring-color: rgb(22 163 74 / 0.15) !important;
}

.tw-ring-green-600\/20 {
  --tw-ring-color: rgb(22 163 74 / 0.2) !important;
}

.tw-ring-green-600\/25 {
  --tw-ring-color: rgb(22 163 74 / 0.25) !important;
}

.tw-ring-green-600\/30 {
  --tw-ring-color: rgb(22 163 74 / 0.3) !important;
}

.tw-ring-green-600\/35 {
  --tw-ring-color: rgb(22 163 74 / 0.35) !important;
}

.tw-ring-green-600\/40 {
  --tw-ring-color: rgb(22 163 74 / 0.4) !important;
}

.tw-ring-green-600\/45 {
  --tw-ring-color: rgb(22 163 74 / 0.45) !important;
}

.tw-ring-green-600\/5 {
  --tw-ring-color: rgb(22 163 74 / 0.05) !important;
}

.tw-ring-green-600\/50 {
  --tw-ring-color: rgb(22 163 74 / 0.5) !important;
}

.tw-ring-green-600\/55 {
  --tw-ring-color: rgb(22 163 74 / 0.55) !important;
}

.tw-ring-green-600\/60 {
  --tw-ring-color: rgb(22 163 74 / 0.6) !important;
}

.tw-ring-green-600\/65 {
  --tw-ring-color: rgb(22 163 74 / 0.65) !important;
}

.tw-ring-green-600\/70 {
  --tw-ring-color: rgb(22 163 74 / 0.7) !important;
}

.tw-ring-green-600\/75 {
  --tw-ring-color: rgb(22 163 74 / 0.75) !important;
}

.tw-ring-green-600\/80 {
  --tw-ring-color: rgb(22 163 74 / 0.8) !important;
}

.tw-ring-green-600\/85 {
  --tw-ring-color: rgb(22 163 74 / 0.85) !important;
}

.tw-ring-green-600\/90 {
  --tw-ring-color: rgb(22 163 74 / 0.9) !important;
}

.tw-ring-green-600\/95 {
  --tw-ring-color: rgb(22 163 74 / 0.95) !important;
}

.tw-ring-green-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(21 128 61 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-700\/0 {
  --tw-ring-color: rgb(21 128 61 / 0) !important;
}

.tw-ring-green-700\/10 {
  --tw-ring-color: rgb(21 128 61 / 0.1) !important;
}

.tw-ring-green-700\/100 {
  --tw-ring-color: rgb(21 128 61 / 1) !important;
}

.tw-ring-green-700\/15 {
  --tw-ring-color: rgb(21 128 61 / 0.15) !important;
}

.tw-ring-green-700\/20 {
  --tw-ring-color: rgb(21 128 61 / 0.2) !important;
}

.tw-ring-green-700\/25 {
  --tw-ring-color: rgb(21 128 61 / 0.25) !important;
}

.tw-ring-green-700\/30 {
  --tw-ring-color: rgb(21 128 61 / 0.3) !important;
}

.tw-ring-green-700\/35 {
  --tw-ring-color: rgb(21 128 61 / 0.35) !important;
}

.tw-ring-green-700\/40 {
  --tw-ring-color: rgb(21 128 61 / 0.4) !important;
}

.tw-ring-green-700\/45 {
  --tw-ring-color: rgb(21 128 61 / 0.45) !important;
}

.tw-ring-green-700\/5 {
  --tw-ring-color: rgb(21 128 61 / 0.05) !important;
}

.tw-ring-green-700\/50 {
  --tw-ring-color: rgb(21 128 61 / 0.5) !important;
}

.tw-ring-green-700\/55 {
  --tw-ring-color: rgb(21 128 61 / 0.55) !important;
}

.tw-ring-green-700\/60 {
  --tw-ring-color: rgb(21 128 61 / 0.6) !important;
}

.tw-ring-green-700\/65 {
  --tw-ring-color: rgb(21 128 61 / 0.65) !important;
}

.tw-ring-green-700\/70 {
  --tw-ring-color: rgb(21 128 61 / 0.7) !important;
}

.tw-ring-green-700\/75 {
  --tw-ring-color: rgb(21 128 61 / 0.75) !important;
}

.tw-ring-green-700\/80 {
  --tw-ring-color: rgb(21 128 61 / 0.8) !important;
}

.tw-ring-green-700\/85 {
  --tw-ring-color: rgb(21 128 61 / 0.85) !important;
}

.tw-ring-green-700\/90 {
  --tw-ring-color: rgb(21 128 61 / 0.9) !important;
}

.tw-ring-green-700\/95 {
  --tw-ring-color: rgb(21 128 61 / 0.95) !important;
}

.tw-ring-green-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(22 101 52 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-800\/0 {
  --tw-ring-color: rgb(22 101 52 / 0) !important;
}

.tw-ring-green-800\/10 {
  --tw-ring-color: rgb(22 101 52 / 0.1) !important;
}

.tw-ring-green-800\/100 {
  --tw-ring-color: rgb(22 101 52 / 1) !important;
}

.tw-ring-green-800\/15 {
  --tw-ring-color: rgb(22 101 52 / 0.15) !important;
}

.tw-ring-green-800\/20 {
  --tw-ring-color: rgb(22 101 52 / 0.2) !important;
}

.tw-ring-green-800\/25 {
  --tw-ring-color: rgb(22 101 52 / 0.25) !important;
}

.tw-ring-green-800\/30 {
  --tw-ring-color: rgb(22 101 52 / 0.3) !important;
}

.tw-ring-green-800\/35 {
  --tw-ring-color: rgb(22 101 52 / 0.35) !important;
}

.tw-ring-green-800\/40 {
  --tw-ring-color: rgb(22 101 52 / 0.4) !important;
}

.tw-ring-green-800\/45 {
  --tw-ring-color: rgb(22 101 52 / 0.45) !important;
}

.tw-ring-green-800\/5 {
  --tw-ring-color: rgb(22 101 52 / 0.05) !important;
}

.tw-ring-green-800\/50 {
  --tw-ring-color: rgb(22 101 52 / 0.5) !important;
}

.tw-ring-green-800\/55 {
  --tw-ring-color: rgb(22 101 52 / 0.55) !important;
}

.tw-ring-green-800\/60 {
  --tw-ring-color: rgb(22 101 52 / 0.6) !important;
}

.tw-ring-green-800\/65 {
  --tw-ring-color: rgb(22 101 52 / 0.65) !important;
}

.tw-ring-green-800\/70 {
  --tw-ring-color: rgb(22 101 52 / 0.7) !important;
}

.tw-ring-green-800\/75 {
  --tw-ring-color: rgb(22 101 52 / 0.75) !important;
}

.tw-ring-green-800\/80 {
  --tw-ring-color: rgb(22 101 52 / 0.8) !important;
}

.tw-ring-green-800\/85 {
  --tw-ring-color: rgb(22 101 52 / 0.85) !important;
}

.tw-ring-green-800\/90 {
  --tw-ring-color: rgb(22 101 52 / 0.9) !important;
}

.tw-ring-green-800\/95 {
  --tw-ring-color: rgb(22 101 52 / 0.95) !important;
}

.tw-ring-green-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(20 83 45 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-900\/0 {
  --tw-ring-color: rgb(20 83 45 / 0) !important;
}

.tw-ring-green-900\/10 {
  --tw-ring-color: rgb(20 83 45 / 0.1) !important;
}

.tw-ring-green-900\/100 {
  --tw-ring-color: rgb(20 83 45 / 1) !important;
}

.tw-ring-green-900\/15 {
  --tw-ring-color: rgb(20 83 45 / 0.15) !important;
}

.tw-ring-green-900\/20 {
  --tw-ring-color: rgb(20 83 45 / 0.2) !important;
}

.tw-ring-green-900\/25 {
  --tw-ring-color: rgb(20 83 45 / 0.25) !important;
}

.tw-ring-green-900\/30 {
  --tw-ring-color: rgb(20 83 45 / 0.3) !important;
}

.tw-ring-green-900\/35 {
  --tw-ring-color: rgb(20 83 45 / 0.35) !important;
}

.tw-ring-green-900\/40 {
  --tw-ring-color: rgb(20 83 45 / 0.4) !important;
}

.tw-ring-green-900\/45 {
  --tw-ring-color: rgb(20 83 45 / 0.45) !important;
}

.tw-ring-green-900\/5 {
  --tw-ring-color: rgb(20 83 45 / 0.05) !important;
}

.tw-ring-green-900\/50 {
  --tw-ring-color: rgb(20 83 45 / 0.5) !important;
}

.tw-ring-green-900\/55 {
  --tw-ring-color: rgb(20 83 45 / 0.55) !important;
}

.tw-ring-green-900\/60 {
  --tw-ring-color: rgb(20 83 45 / 0.6) !important;
}

.tw-ring-green-900\/65 {
  --tw-ring-color: rgb(20 83 45 / 0.65) !important;
}

.tw-ring-green-900\/70 {
  --tw-ring-color: rgb(20 83 45 / 0.7) !important;
}

.tw-ring-green-900\/75 {
  --tw-ring-color: rgb(20 83 45 / 0.75) !important;
}

.tw-ring-green-900\/80 {
  --tw-ring-color: rgb(20 83 45 / 0.8) !important;
}

.tw-ring-green-900\/85 {
  --tw-ring-color: rgb(20 83 45 / 0.85) !important;
}

.tw-ring-green-900\/90 {
  --tw-ring-color: rgb(20 83 45 / 0.9) !important;
}

.tw-ring-green-900\/95 {
  --tw-ring-color: rgb(20 83 45 / 0.95) !important;
}

.tw-ring-green-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(5 46 22 / var(--tw-ring-opacity)) !important;
}

.tw-ring-green-950\/0 {
  --tw-ring-color: rgb(5 46 22 / 0) !important;
}

.tw-ring-green-950\/10 {
  --tw-ring-color: rgb(5 46 22 / 0.1) !important;
}

.tw-ring-green-950\/100 {
  --tw-ring-color: rgb(5 46 22 / 1) !important;
}

.tw-ring-green-950\/15 {
  --tw-ring-color: rgb(5 46 22 / 0.15) !important;
}

.tw-ring-green-950\/20 {
  --tw-ring-color: rgb(5 46 22 / 0.2) !important;
}

.tw-ring-green-950\/25 {
  --tw-ring-color: rgb(5 46 22 / 0.25) !important;
}

.tw-ring-green-950\/30 {
  --tw-ring-color: rgb(5 46 22 / 0.3) !important;
}

.tw-ring-green-950\/35 {
  --tw-ring-color: rgb(5 46 22 / 0.35) !important;
}

.tw-ring-green-950\/40 {
  --tw-ring-color: rgb(5 46 22 / 0.4) !important;
}

.tw-ring-green-950\/45 {
  --tw-ring-color: rgb(5 46 22 / 0.45) !important;
}

.tw-ring-green-950\/5 {
  --tw-ring-color: rgb(5 46 22 / 0.05) !important;
}

.tw-ring-green-950\/50 {
  --tw-ring-color: rgb(5 46 22 / 0.5) !important;
}

.tw-ring-green-950\/55 {
  --tw-ring-color: rgb(5 46 22 / 0.55) !important;
}

.tw-ring-green-950\/60 {
  --tw-ring-color: rgb(5 46 22 / 0.6) !important;
}

.tw-ring-green-950\/65 {
  --tw-ring-color: rgb(5 46 22 / 0.65) !important;
}

.tw-ring-green-950\/70 {
  --tw-ring-color: rgb(5 46 22 / 0.7) !important;
}

.tw-ring-green-950\/75 {
  --tw-ring-color: rgb(5 46 22 / 0.75) !important;
}

.tw-ring-green-950\/80 {
  --tw-ring-color: rgb(5 46 22 / 0.8) !important;
}

.tw-ring-green-950\/85 {
  --tw-ring-color: rgb(5 46 22 / 0.85) !important;
}

.tw-ring-green-950\/90 {
  --tw-ring-color: rgb(5 46 22 / 0.9) !important;
}

.tw-ring-green-950\/95 {
  --tw-ring-color: rgb(5 46 22 / 0.95) !important;
}

.tw-ring-indigo-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(224 231 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-100\/0 {
  --tw-ring-color: rgb(224 231 255 / 0) !important;
}

.tw-ring-indigo-100\/10 {
  --tw-ring-color: rgb(224 231 255 / 0.1) !important;
}

.tw-ring-indigo-100\/100 {
  --tw-ring-color: rgb(224 231 255 / 1) !important;
}

.tw-ring-indigo-100\/15 {
  --tw-ring-color: rgb(224 231 255 / 0.15) !important;
}

.tw-ring-indigo-100\/20 {
  --tw-ring-color: rgb(224 231 255 / 0.2) !important;
}

.tw-ring-indigo-100\/25 {
  --tw-ring-color: rgb(224 231 255 / 0.25) !important;
}

.tw-ring-indigo-100\/30 {
  --tw-ring-color: rgb(224 231 255 / 0.3) !important;
}

.tw-ring-indigo-100\/35 {
  --tw-ring-color: rgb(224 231 255 / 0.35) !important;
}

.tw-ring-indigo-100\/40 {
  --tw-ring-color: rgb(224 231 255 / 0.4) !important;
}

.tw-ring-indigo-100\/45 {
  --tw-ring-color: rgb(224 231 255 / 0.45) !important;
}

.tw-ring-indigo-100\/5 {
  --tw-ring-color: rgb(224 231 255 / 0.05) !important;
}

.tw-ring-indigo-100\/50 {
  --tw-ring-color: rgb(224 231 255 / 0.5) !important;
}

.tw-ring-indigo-100\/55 {
  --tw-ring-color: rgb(224 231 255 / 0.55) !important;
}

.tw-ring-indigo-100\/60 {
  --tw-ring-color: rgb(224 231 255 / 0.6) !important;
}

.tw-ring-indigo-100\/65 {
  --tw-ring-color: rgb(224 231 255 / 0.65) !important;
}

.tw-ring-indigo-100\/70 {
  --tw-ring-color: rgb(224 231 255 / 0.7) !important;
}

.tw-ring-indigo-100\/75 {
  --tw-ring-color: rgb(224 231 255 / 0.75) !important;
}

.tw-ring-indigo-100\/80 {
  --tw-ring-color: rgb(224 231 255 / 0.8) !important;
}

.tw-ring-indigo-100\/85 {
  --tw-ring-color: rgb(224 231 255 / 0.85) !important;
}

.tw-ring-indigo-100\/90 {
  --tw-ring-color: rgb(224 231 255 / 0.9) !important;
}

.tw-ring-indigo-100\/95 {
  --tw-ring-color: rgb(224 231 255 / 0.95) !important;
}

.tw-ring-indigo-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(199 210 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-200\/0 {
  --tw-ring-color: rgb(199 210 254 / 0) !important;
}

.tw-ring-indigo-200\/10 {
  --tw-ring-color: rgb(199 210 254 / 0.1) !important;
}

.tw-ring-indigo-200\/100 {
  --tw-ring-color: rgb(199 210 254 / 1) !important;
}

.tw-ring-indigo-200\/15 {
  --tw-ring-color: rgb(199 210 254 / 0.15) !important;
}

.tw-ring-indigo-200\/20 {
  --tw-ring-color: rgb(199 210 254 / 0.2) !important;
}

.tw-ring-indigo-200\/25 {
  --tw-ring-color: rgb(199 210 254 / 0.25) !important;
}

.tw-ring-indigo-200\/30 {
  --tw-ring-color: rgb(199 210 254 / 0.3) !important;
}

.tw-ring-indigo-200\/35 {
  --tw-ring-color: rgb(199 210 254 / 0.35) !important;
}

.tw-ring-indigo-200\/40 {
  --tw-ring-color: rgb(199 210 254 / 0.4) !important;
}

.tw-ring-indigo-200\/45 {
  --tw-ring-color: rgb(199 210 254 / 0.45) !important;
}

.tw-ring-indigo-200\/5 {
  --tw-ring-color: rgb(199 210 254 / 0.05) !important;
}

.tw-ring-indigo-200\/50 {
  --tw-ring-color: rgb(199 210 254 / 0.5) !important;
}

.tw-ring-indigo-200\/55 {
  --tw-ring-color: rgb(199 210 254 / 0.55) !important;
}

.tw-ring-indigo-200\/60 {
  --tw-ring-color: rgb(199 210 254 / 0.6) !important;
}

.tw-ring-indigo-200\/65 {
  --tw-ring-color: rgb(199 210 254 / 0.65) !important;
}

.tw-ring-indigo-200\/70 {
  --tw-ring-color: rgb(199 210 254 / 0.7) !important;
}

.tw-ring-indigo-200\/75 {
  --tw-ring-color: rgb(199 210 254 / 0.75) !important;
}

.tw-ring-indigo-200\/80 {
  --tw-ring-color: rgb(199 210 254 / 0.8) !important;
}

.tw-ring-indigo-200\/85 {
  --tw-ring-color: rgb(199 210 254 / 0.85) !important;
}

.tw-ring-indigo-200\/90 {
  --tw-ring-color: rgb(199 210 254 / 0.9) !important;
}

.tw-ring-indigo-200\/95 {
  --tw-ring-color: rgb(199 210 254 / 0.95) !important;
}

.tw-ring-indigo-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(165 180 252 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-300\/0 {
  --tw-ring-color: rgb(165 180 252 / 0) !important;
}

.tw-ring-indigo-300\/10 {
  --tw-ring-color: rgb(165 180 252 / 0.1) !important;
}

.tw-ring-indigo-300\/100 {
  --tw-ring-color: rgb(165 180 252 / 1) !important;
}

.tw-ring-indigo-300\/15 {
  --tw-ring-color: rgb(165 180 252 / 0.15) !important;
}

.tw-ring-indigo-300\/20 {
  --tw-ring-color: rgb(165 180 252 / 0.2) !important;
}

.tw-ring-indigo-300\/25 {
  --tw-ring-color: rgb(165 180 252 / 0.25) !important;
}

.tw-ring-indigo-300\/30 {
  --tw-ring-color: rgb(165 180 252 / 0.3) !important;
}

.tw-ring-indigo-300\/35 {
  --tw-ring-color: rgb(165 180 252 / 0.35) !important;
}

.tw-ring-indigo-300\/40 {
  --tw-ring-color: rgb(165 180 252 / 0.4) !important;
}

.tw-ring-indigo-300\/45 {
  --tw-ring-color: rgb(165 180 252 / 0.45) !important;
}

.tw-ring-indigo-300\/5 {
  --tw-ring-color: rgb(165 180 252 / 0.05) !important;
}

.tw-ring-indigo-300\/50 {
  --tw-ring-color: rgb(165 180 252 / 0.5) !important;
}

.tw-ring-indigo-300\/55 {
  --tw-ring-color: rgb(165 180 252 / 0.55) !important;
}

.tw-ring-indigo-300\/60 {
  --tw-ring-color: rgb(165 180 252 / 0.6) !important;
}

.tw-ring-indigo-300\/65 {
  --tw-ring-color: rgb(165 180 252 / 0.65) !important;
}

.tw-ring-indigo-300\/70 {
  --tw-ring-color: rgb(165 180 252 / 0.7) !important;
}

.tw-ring-indigo-300\/75 {
  --tw-ring-color: rgb(165 180 252 / 0.75) !important;
}

.tw-ring-indigo-300\/80 {
  --tw-ring-color: rgb(165 180 252 / 0.8) !important;
}

.tw-ring-indigo-300\/85 {
  --tw-ring-color: rgb(165 180 252 / 0.85) !important;
}

.tw-ring-indigo-300\/90 {
  --tw-ring-color: rgb(165 180 252 / 0.9) !important;
}

.tw-ring-indigo-300\/95 {
  --tw-ring-color: rgb(165 180 252 / 0.95) !important;
}

.tw-ring-indigo-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(129 140 248 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-400\/0 {
  --tw-ring-color: rgb(129 140 248 / 0) !important;
}

.tw-ring-indigo-400\/10 {
  --tw-ring-color: rgb(129 140 248 / 0.1) !important;
}

.tw-ring-indigo-400\/100 {
  --tw-ring-color: rgb(129 140 248 / 1) !important;
}

.tw-ring-indigo-400\/15 {
  --tw-ring-color: rgb(129 140 248 / 0.15) !important;
}

.tw-ring-indigo-400\/20 {
  --tw-ring-color: rgb(129 140 248 / 0.2) !important;
}

.tw-ring-indigo-400\/25 {
  --tw-ring-color: rgb(129 140 248 / 0.25) !important;
}

.tw-ring-indigo-400\/30 {
  --tw-ring-color: rgb(129 140 248 / 0.3) !important;
}

.tw-ring-indigo-400\/35 {
  --tw-ring-color: rgb(129 140 248 / 0.35) !important;
}

.tw-ring-indigo-400\/40 {
  --tw-ring-color: rgb(129 140 248 / 0.4) !important;
}

.tw-ring-indigo-400\/45 {
  --tw-ring-color: rgb(129 140 248 / 0.45) !important;
}

.tw-ring-indigo-400\/5 {
  --tw-ring-color: rgb(129 140 248 / 0.05) !important;
}

.tw-ring-indigo-400\/50 {
  --tw-ring-color: rgb(129 140 248 / 0.5) !important;
}

.tw-ring-indigo-400\/55 {
  --tw-ring-color: rgb(129 140 248 / 0.55) !important;
}

.tw-ring-indigo-400\/60 {
  --tw-ring-color: rgb(129 140 248 / 0.6) !important;
}

.tw-ring-indigo-400\/65 {
  --tw-ring-color: rgb(129 140 248 / 0.65) !important;
}

.tw-ring-indigo-400\/70 {
  --tw-ring-color: rgb(129 140 248 / 0.7) !important;
}

.tw-ring-indigo-400\/75 {
  --tw-ring-color: rgb(129 140 248 / 0.75) !important;
}

.tw-ring-indigo-400\/80 {
  --tw-ring-color: rgb(129 140 248 / 0.8) !important;
}

.tw-ring-indigo-400\/85 {
  --tw-ring-color: rgb(129 140 248 / 0.85) !important;
}

.tw-ring-indigo-400\/90 {
  --tw-ring-color: rgb(129 140 248 / 0.9) !important;
}

.tw-ring-indigo-400\/95 {
  --tw-ring-color: rgb(129 140 248 / 0.95) !important;
}

.tw-ring-indigo-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(238 242 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-50\/0 {
  --tw-ring-color: rgb(238 242 255 / 0) !important;
}

.tw-ring-indigo-50\/10 {
  --tw-ring-color: rgb(238 242 255 / 0.1) !important;
}

.tw-ring-indigo-50\/100 {
  --tw-ring-color: rgb(238 242 255 / 1) !important;
}

.tw-ring-indigo-50\/15 {
  --tw-ring-color: rgb(238 242 255 / 0.15) !important;
}

.tw-ring-indigo-50\/20 {
  --tw-ring-color: rgb(238 242 255 / 0.2) !important;
}

.tw-ring-indigo-50\/25 {
  --tw-ring-color: rgb(238 242 255 / 0.25) !important;
}

.tw-ring-indigo-50\/30 {
  --tw-ring-color: rgb(238 242 255 / 0.3) !important;
}

.tw-ring-indigo-50\/35 {
  --tw-ring-color: rgb(238 242 255 / 0.35) !important;
}

.tw-ring-indigo-50\/40 {
  --tw-ring-color: rgb(238 242 255 / 0.4) !important;
}

.tw-ring-indigo-50\/45 {
  --tw-ring-color: rgb(238 242 255 / 0.45) !important;
}

.tw-ring-indigo-50\/5 {
  --tw-ring-color: rgb(238 242 255 / 0.05) !important;
}

.tw-ring-indigo-50\/50 {
  --tw-ring-color: rgb(238 242 255 / 0.5) !important;
}

.tw-ring-indigo-50\/55 {
  --tw-ring-color: rgb(238 242 255 / 0.55) !important;
}

.tw-ring-indigo-50\/60 {
  --tw-ring-color: rgb(238 242 255 / 0.6) !important;
}

.tw-ring-indigo-50\/65 {
  --tw-ring-color: rgb(238 242 255 / 0.65) !important;
}

.tw-ring-indigo-50\/70 {
  --tw-ring-color: rgb(238 242 255 / 0.7) !important;
}

.tw-ring-indigo-50\/75 {
  --tw-ring-color: rgb(238 242 255 / 0.75) !important;
}

.tw-ring-indigo-50\/80 {
  --tw-ring-color: rgb(238 242 255 / 0.8) !important;
}

.tw-ring-indigo-50\/85 {
  --tw-ring-color: rgb(238 242 255 / 0.85) !important;
}

.tw-ring-indigo-50\/90 {
  --tw-ring-color: rgb(238 242 255 / 0.9) !important;
}

.tw-ring-indigo-50\/95 {
  --tw-ring-color: rgb(238 242 255 / 0.95) !important;
}

.tw-ring-indigo-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(99 102 241 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-500\/0 {
  --tw-ring-color: rgb(99 102 241 / 0) !important;
}

.tw-ring-indigo-500\/10 {
  --tw-ring-color: rgb(99 102 241 / 0.1) !important;
}

.tw-ring-indigo-500\/100 {
  --tw-ring-color: rgb(99 102 241 / 1) !important;
}

.tw-ring-indigo-500\/15 {
  --tw-ring-color: rgb(99 102 241 / 0.15) !important;
}

.tw-ring-indigo-500\/20 {
  --tw-ring-color: rgb(99 102 241 / 0.2) !important;
}

.tw-ring-indigo-500\/25 {
  --tw-ring-color: rgb(99 102 241 / 0.25) !important;
}

.tw-ring-indigo-500\/30 {
  --tw-ring-color: rgb(99 102 241 / 0.3) !important;
}

.tw-ring-indigo-500\/35 {
  --tw-ring-color: rgb(99 102 241 / 0.35) !important;
}

.tw-ring-indigo-500\/40 {
  --tw-ring-color: rgb(99 102 241 / 0.4) !important;
}

.tw-ring-indigo-500\/45 {
  --tw-ring-color: rgb(99 102 241 / 0.45) !important;
}

.tw-ring-indigo-500\/5 {
  --tw-ring-color: rgb(99 102 241 / 0.05) !important;
}

.tw-ring-indigo-500\/50 {
  --tw-ring-color: rgb(99 102 241 / 0.5) !important;
}

.tw-ring-indigo-500\/55 {
  --tw-ring-color: rgb(99 102 241 / 0.55) !important;
}

.tw-ring-indigo-500\/60 {
  --tw-ring-color: rgb(99 102 241 / 0.6) !important;
}

.tw-ring-indigo-500\/65 {
  --tw-ring-color: rgb(99 102 241 / 0.65) !important;
}

.tw-ring-indigo-500\/70 {
  --tw-ring-color: rgb(99 102 241 / 0.7) !important;
}

.tw-ring-indigo-500\/75 {
  --tw-ring-color: rgb(99 102 241 / 0.75) !important;
}

.tw-ring-indigo-500\/80 {
  --tw-ring-color: rgb(99 102 241 / 0.8) !important;
}

.tw-ring-indigo-500\/85 {
  --tw-ring-color: rgb(99 102 241 / 0.85) !important;
}

.tw-ring-indigo-500\/90 {
  --tw-ring-color: rgb(99 102 241 / 0.9) !important;
}

.tw-ring-indigo-500\/95 {
  --tw-ring-color: rgb(99 102 241 / 0.95) !important;
}

.tw-ring-indigo-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(79 70 229 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-600\/0 {
  --tw-ring-color: rgb(79 70 229 / 0) !important;
}

.tw-ring-indigo-600\/10 {
  --tw-ring-color: rgb(79 70 229 / 0.1) !important;
}

.tw-ring-indigo-600\/100 {
  --tw-ring-color: rgb(79 70 229 / 1) !important;
}

.tw-ring-indigo-600\/15 {
  --tw-ring-color: rgb(79 70 229 / 0.15) !important;
}

.tw-ring-indigo-600\/20 {
  --tw-ring-color: rgb(79 70 229 / 0.2) !important;
}

.tw-ring-indigo-600\/25 {
  --tw-ring-color: rgb(79 70 229 / 0.25) !important;
}

.tw-ring-indigo-600\/30 {
  --tw-ring-color: rgb(79 70 229 / 0.3) !important;
}

.tw-ring-indigo-600\/35 {
  --tw-ring-color: rgb(79 70 229 / 0.35) !important;
}

.tw-ring-indigo-600\/40 {
  --tw-ring-color: rgb(79 70 229 / 0.4) !important;
}

.tw-ring-indigo-600\/45 {
  --tw-ring-color: rgb(79 70 229 / 0.45) !important;
}

.tw-ring-indigo-600\/5 {
  --tw-ring-color: rgb(79 70 229 / 0.05) !important;
}

.tw-ring-indigo-600\/50 {
  --tw-ring-color: rgb(79 70 229 / 0.5) !important;
}

.tw-ring-indigo-600\/55 {
  --tw-ring-color: rgb(79 70 229 / 0.55) !important;
}

.tw-ring-indigo-600\/60 {
  --tw-ring-color: rgb(79 70 229 / 0.6) !important;
}

.tw-ring-indigo-600\/65 {
  --tw-ring-color: rgb(79 70 229 / 0.65) !important;
}

.tw-ring-indigo-600\/70 {
  --tw-ring-color: rgb(79 70 229 / 0.7) !important;
}

.tw-ring-indigo-600\/75 {
  --tw-ring-color: rgb(79 70 229 / 0.75) !important;
}

.tw-ring-indigo-600\/80 {
  --tw-ring-color: rgb(79 70 229 / 0.8) !important;
}

.tw-ring-indigo-600\/85 {
  --tw-ring-color: rgb(79 70 229 / 0.85) !important;
}

.tw-ring-indigo-600\/90 {
  --tw-ring-color: rgb(79 70 229 / 0.9) !important;
}

.tw-ring-indigo-600\/95 {
  --tw-ring-color: rgb(79 70 229 / 0.95) !important;
}

.tw-ring-indigo-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(67 56 202 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-700\/0 {
  --tw-ring-color: rgb(67 56 202 / 0) !important;
}

.tw-ring-indigo-700\/10 {
  --tw-ring-color: rgb(67 56 202 / 0.1) !important;
}

.tw-ring-indigo-700\/100 {
  --tw-ring-color: rgb(67 56 202 / 1) !important;
}

.tw-ring-indigo-700\/15 {
  --tw-ring-color: rgb(67 56 202 / 0.15) !important;
}

.tw-ring-indigo-700\/20 {
  --tw-ring-color: rgb(67 56 202 / 0.2) !important;
}

.tw-ring-indigo-700\/25 {
  --tw-ring-color: rgb(67 56 202 / 0.25) !important;
}

.tw-ring-indigo-700\/30 {
  --tw-ring-color: rgb(67 56 202 / 0.3) !important;
}

.tw-ring-indigo-700\/35 {
  --tw-ring-color: rgb(67 56 202 / 0.35) !important;
}

.tw-ring-indigo-700\/40 {
  --tw-ring-color: rgb(67 56 202 / 0.4) !important;
}

.tw-ring-indigo-700\/45 {
  --tw-ring-color: rgb(67 56 202 / 0.45) !important;
}

.tw-ring-indigo-700\/5 {
  --tw-ring-color: rgb(67 56 202 / 0.05) !important;
}

.tw-ring-indigo-700\/50 {
  --tw-ring-color: rgb(67 56 202 / 0.5) !important;
}

.tw-ring-indigo-700\/55 {
  --tw-ring-color: rgb(67 56 202 / 0.55) !important;
}

.tw-ring-indigo-700\/60 {
  --tw-ring-color: rgb(67 56 202 / 0.6) !important;
}

.tw-ring-indigo-700\/65 {
  --tw-ring-color: rgb(67 56 202 / 0.65) !important;
}

.tw-ring-indigo-700\/70 {
  --tw-ring-color: rgb(67 56 202 / 0.7) !important;
}

.tw-ring-indigo-700\/75 {
  --tw-ring-color: rgb(67 56 202 / 0.75) !important;
}

.tw-ring-indigo-700\/80 {
  --tw-ring-color: rgb(67 56 202 / 0.8) !important;
}

.tw-ring-indigo-700\/85 {
  --tw-ring-color: rgb(67 56 202 / 0.85) !important;
}

.tw-ring-indigo-700\/90 {
  --tw-ring-color: rgb(67 56 202 / 0.9) !important;
}

.tw-ring-indigo-700\/95 {
  --tw-ring-color: rgb(67 56 202 / 0.95) !important;
}

.tw-ring-indigo-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(55 48 163 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-800\/0 {
  --tw-ring-color: rgb(55 48 163 / 0) !important;
}

.tw-ring-indigo-800\/10 {
  --tw-ring-color: rgb(55 48 163 / 0.1) !important;
}

.tw-ring-indigo-800\/100 {
  --tw-ring-color: rgb(55 48 163 / 1) !important;
}

.tw-ring-indigo-800\/15 {
  --tw-ring-color: rgb(55 48 163 / 0.15) !important;
}

.tw-ring-indigo-800\/20 {
  --tw-ring-color: rgb(55 48 163 / 0.2) !important;
}

.tw-ring-indigo-800\/25 {
  --tw-ring-color: rgb(55 48 163 / 0.25) !important;
}

.tw-ring-indigo-800\/30 {
  --tw-ring-color: rgb(55 48 163 / 0.3) !important;
}

.tw-ring-indigo-800\/35 {
  --tw-ring-color: rgb(55 48 163 / 0.35) !important;
}

.tw-ring-indigo-800\/40 {
  --tw-ring-color: rgb(55 48 163 / 0.4) !important;
}

.tw-ring-indigo-800\/45 {
  --tw-ring-color: rgb(55 48 163 / 0.45) !important;
}

.tw-ring-indigo-800\/5 {
  --tw-ring-color: rgb(55 48 163 / 0.05) !important;
}

.tw-ring-indigo-800\/50 {
  --tw-ring-color: rgb(55 48 163 / 0.5) !important;
}

.tw-ring-indigo-800\/55 {
  --tw-ring-color: rgb(55 48 163 / 0.55) !important;
}

.tw-ring-indigo-800\/60 {
  --tw-ring-color: rgb(55 48 163 / 0.6) !important;
}

.tw-ring-indigo-800\/65 {
  --tw-ring-color: rgb(55 48 163 / 0.65) !important;
}

.tw-ring-indigo-800\/70 {
  --tw-ring-color: rgb(55 48 163 / 0.7) !important;
}

.tw-ring-indigo-800\/75 {
  --tw-ring-color: rgb(55 48 163 / 0.75) !important;
}

.tw-ring-indigo-800\/80 {
  --tw-ring-color: rgb(55 48 163 / 0.8) !important;
}

.tw-ring-indigo-800\/85 {
  --tw-ring-color: rgb(55 48 163 / 0.85) !important;
}

.tw-ring-indigo-800\/90 {
  --tw-ring-color: rgb(55 48 163 / 0.9) !important;
}

.tw-ring-indigo-800\/95 {
  --tw-ring-color: rgb(55 48 163 / 0.95) !important;
}

.tw-ring-indigo-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(49 46 129 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-900\/0 {
  --tw-ring-color: rgb(49 46 129 / 0) !important;
}

.tw-ring-indigo-900\/10 {
  --tw-ring-color: rgb(49 46 129 / 0.1) !important;
}

.tw-ring-indigo-900\/100 {
  --tw-ring-color: rgb(49 46 129 / 1) !important;
}

.tw-ring-indigo-900\/15 {
  --tw-ring-color: rgb(49 46 129 / 0.15) !important;
}

.tw-ring-indigo-900\/20 {
  --tw-ring-color: rgb(49 46 129 / 0.2) !important;
}

.tw-ring-indigo-900\/25 {
  --tw-ring-color: rgb(49 46 129 / 0.25) !important;
}

.tw-ring-indigo-900\/30 {
  --tw-ring-color: rgb(49 46 129 / 0.3) !important;
}

.tw-ring-indigo-900\/35 {
  --tw-ring-color: rgb(49 46 129 / 0.35) !important;
}

.tw-ring-indigo-900\/40 {
  --tw-ring-color: rgb(49 46 129 / 0.4) !important;
}

.tw-ring-indigo-900\/45 {
  --tw-ring-color: rgb(49 46 129 / 0.45) !important;
}

.tw-ring-indigo-900\/5 {
  --tw-ring-color: rgb(49 46 129 / 0.05) !important;
}

.tw-ring-indigo-900\/50 {
  --tw-ring-color: rgb(49 46 129 / 0.5) !important;
}

.tw-ring-indigo-900\/55 {
  --tw-ring-color: rgb(49 46 129 / 0.55) !important;
}

.tw-ring-indigo-900\/60 {
  --tw-ring-color: rgb(49 46 129 / 0.6) !important;
}

.tw-ring-indigo-900\/65 {
  --tw-ring-color: rgb(49 46 129 / 0.65) !important;
}

.tw-ring-indigo-900\/70 {
  --tw-ring-color: rgb(49 46 129 / 0.7) !important;
}

.tw-ring-indigo-900\/75 {
  --tw-ring-color: rgb(49 46 129 / 0.75) !important;
}

.tw-ring-indigo-900\/80 {
  --tw-ring-color: rgb(49 46 129 / 0.8) !important;
}

.tw-ring-indigo-900\/85 {
  --tw-ring-color: rgb(49 46 129 / 0.85) !important;
}

.tw-ring-indigo-900\/90 {
  --tw-ring-color: rgb(49 46 129 / 0.9) !important;
}

.tw-ring-indigo-900\/95 {
  --tw-ring-color: rgb(49 46 129 / 0.95) !important;
}

.tw-ring-indigo-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(30 27 75 / var(--tw-ring-opacity)) !important;
}

.tw-ring-indigo-950\/0 {
  --tw-ring-color: rgb(30 27 75 / 0) !important;
}

.tw-ring-indigo-950\/10 {
  --tw-ring-color: rgb(30 27 75 / 0.1) !important;
}

.tw-ring-indigo-950\/100 {
  --tw-ring-color: rgb(30 27 75 / 1) !important;
}

.tw-ring-indigo-950\/15 {
  --tw-ring-color: rgb(30 27 75 / 0.15) !important;
}

.tw-ring-indigo-950\/20 {
  --tw-ring-color: rgb(30 27 75 / 0.2) !important;
}

.tw-ring-indigo-950\/25 {
  --tw-ring-color: rgb(30 27 75 / 0.25) !important;
}

.tw-ring-indigo-950\/30 {
  --tw-ring-color: rgb(30 27 75 / 0.3) !important;
}

.tw-ring-indigo-950\/35 {
  --tw-ring-color: rgb(30 27 75 / 0.35) !important;
}

.tw-ring-indigo-950\/40 {
  --tw-ring-color: rgb(30 27 75 / 0.4) !important;
}

.tw-ring-indigo-950\/45 {
  --tw-ring-color: rgb(30 27 75 / 0.45) !important;
}

.tw-ring-indigo-950\/5 {
  --tw-ring-color: rgb(30 27 75 / 0.05) !important;
}

.tw-ring-indigo-950\/50 {
  --tw-ring-color: rgb(30 27 75 / 0.5) !important;
}

.tw-ring-indigo-950\/55 {
  --tw-ring-color: rgb(30 27 75 / 0.55) !important;
}

.tw-ring-indigo-950\/60 {
  --tw-ring-color: rgb(30 27 75 / 0.6) !important;
}

.tw-ring-indigo-950\/65 {
  --tw-ring-color: rgb(30 27 75 / 0.65) !important;
}

.tw-ring-indigo-950\/70 {
  --tw-ring-color: rgb(30 27 75 / 0.7) !important;
}

.tw-ring-indigo-950\/75 {
  --tw-ring-color: rgb(30 27 75 / 0.75) !important;
}

.tw-ring-indigo-950\/80 {
  --tw-ring-color: rgb(30 27 75 / 0.8) !important;
}

.tw-ring-indigo-950\/85 {
  --tw-ring-color: rgb(30 27 75 / 0.85) !important;
}

.tw-ring-indigo-950\/90 {
  --tw-ring-color: rgb(30 27 75 / 0.9) !important;
}

.tw-ring-indigo-950\/95 {
  --tw-ring-color: rgb(30 27 75 / 0.95) !important;
}

.tw-ring-inherit {
  --tw-ring-color: inherit !important;
}

.tw-ring-lime-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(236 252 203 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-100\/0 {
  --tw-ring-color: rgb(236 252 203 / 0) !important;
}

.tw-ring-lime-100\/10 {
  --tw-ring-color: rgb(236 252 203 / 0.1) !important;
}

.tw-ring-lime-100\/100 {
  --tw-ring-color: rgb(236 252 203 / 1) !important;
}

.tw-ring-lime-100\/15 {
  --tw-ring-color: rgb(236 252 203 / 0.15) !important;
}

.tw-ring-lime-100\/20 {
  --tw-ring-color: rgb(236 252 203 / 0.2) !important;
}

.tw-ring-lime-100\/25 {
  --tw-ring-color: rgb(236 252 203 / 0.25) !important;
}

.tw-ring-lime-100\/30 {
  --tw-ring-color: rgb(236 252 203 / 0.3) !important;
}

.tw-ring-lime-100\/35 {
  --tw-ring-color: rgb(236 252 203 / 0.35) !important;
}

.tw-ring-lime-100\/40 {
  --tw-ring-color: rgb(236 252 203 / 0.4) !important;
}

.tw-ring-lime-100\/45 {
  --tw-ring-color: rgb(236 252 203 / 0.45) !important;
}

.tw-ring-lime-100\/5 {
  --tw-ring-color: rgb(236 252 203 / 0.05) !important;
}

.tw-ring-lime-100\/50 {
  --tw-ring-color: rgb(236 252 203 / 0.5) !important;
}

.tw-ring-lime-100\/55 {
  --tw-ring-color: rgb(236 252 203 / 0.55) !important;
}

.tw-ring-lime-100\/60 {
  --tw-ring-color: rgb(236 252 203 / 0.6) !important;
}

.tw-ring-lime-100\/65 {
  --tw-ring-color: rgb(236 252 203 / 0.65) !important;
}

.tw-ring-lime-100\/70 {
  --tw-ring-color: rgb(236 252 203 / 0.7) !important;
}

.tw-ring-lime-100\/75 {
  --tw-ring-color: rgb(236 252 203 / 0.75) !important;
}

.tw-ring-lime-100\/80 {
  --tw-ring-color: rgb(236 252 203 / 0.8) !important;
}

.tw-ring-lime-100\/85 {
  --tw-ring-color: rgb(236 252 203 / 0.85) !important;
}

.tw-ring-lime-100\/90 {
  --tw-ring-color: rgb(236 252 203 / 0.9) !important;
}

.tw-ring-lime-100\/95 {
  --tw-ring-color: rgb(236 252 203 / 0.95) !important;
}

.tw-ring-lime-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(217 249 157 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-200\/0 {
  --tw-ring-color: rgb(217 249 157 / 0) !important;
}

.tw-ring-lime-200\/10 {
  --tw-ring-color: rgb(217 249 157 / 0.1) !important;
}

.tw-ring-lime-200\/100 {
  --tw-ring-color: rgb(217 249 157 / 1) !important;
}

.tw-ring-lime-200\/15 {
  --tw-ring-color: rgb(217 249 157 / 0.15) !important;
}

.tw-ring-lime-200\/20 {
  --tw-ring-color: rgb(217 249 157 / 0.2) !important;
}

.tw-ring-lime-200\/25 {
  --tw-ring-color: rgb(217 249 157 / 0.25) !important;
}

.tw-ring-lime-200\/30 {
  --tw-ring-color: rgb(217 249 157 / 0.3) !important;
}

.tw-ring-lime-200\/35 {
  --tw-ring-color: rgb(217 249 157 / 0.35) !important;
}

.tw-ring-lime-200\/40 {
  --tw-ring-color: rgb(217 249 157 / 0.4) !important;
}

.tw-ring-lime-200\/45 {
  --tw-ring-color: rgb(217 249 157 / 0.45) !important;
}

.tw-ring-lime-200\/5 {
  --tw-ring-color: rgb(217 249 157 / 0.05) !important;
}

.tw-ring-lime-200\/50 {
  --tw-ring-color: rgb(217 249 157 / 0.5) !important;
}

.tw-ring-lime-200\/55 {
  --tw-ring-color: rgb(217 249 157 / 0.55) !important;
}

.tw-ring-lime-200\/60 {
  --tw-ring-color: rgb(217 249 157 / 0.6) !important;
}

.tw-ring-lime-200\/65 {
  --tw-ring-color: rgb(217 249 157 / 0.65) !important;
}

.tw-ring-lime-200\/70 {
  --tw-ring-color: rgb(217 249 157 / 0.7) !important;
}

.tw-ring-lime-200\/75 {
  --tw-ring-color: rgb(217 249 157 / 0.75) !important;
}

.tw-ring-lime-200\/80 {
  --tw-ring-color: rgb(217 249 157 / 0.8) !important;
}

.tw-ring-lime-200\/85 {
  --tw-ring-color: rgb(217 249 157 / 0.85) !important;
}

.tw-ring-lime-200\/90 {
  --tw-ring-color: rgb(217 249 157 / 0.9) !important;
}

.tw-ring-lime-200\/95 {
  --tw-ring-color: rgb(217 249 157 / 0.95) !important;
}

.tw-ring-lime-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(190 242 100 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-300\/0 {
  --tw-ring-color: rgb(190 242 100 / 0) !important;
}

.tw-ring-lime-300\/10 {
  --tw-ring-color: rgb(190 242 100 / 0.1) !important;
}

.tw-ring-lime-300\/100 {
  --tw-ring-color: rgb(190 242 100 / 1) !important;
}

.tw-ring-lime-300\/15 {
  --tw-ring-color: rgb(190 242 100 / 0.15) !important;
}

.tw-ring-lime-300\/20 {
  --tw-ring-color: rgb(190 242 100 / 0.2) !important;
}

.tw-ring-lime-300\/25 {
  --tw-ring-color: rgb(190 242 100 / 0.25) !important;
}

.tw-ring-lime-300\/30 {
  --tw-ring-color: rgb(190 242 100 / 0.3) !important;
}

.tw-ring-lime-300\/35 {
  --tw-ring-color: rgb(190 242 100 / 0.35) !important;
}

.tw-ring-lime-300\/40 {
  --tw-ring-color: rgb(190 242 100 / 0.4) !important;
}

.tw-ring-lime-300\/45 {
  --tw-ring-color: rgb(190 242 100 / 0.45) !important;
}

.tw-ring-lime-300\/5 {
  --tw-ring-color: rgb(190 242 100 / 0.05) !important;
}

.tw-ring-lime-300\/50 {
  --tw-ring-color: rgb(190 242 100 / 0.5) !important;
}

.tw-ring-lime-300\/55 {
  --tw-ring-color: rgb(190 242 100 / 0.55) !important;
}

.tw-ring-lime-300\/60 {
  --tw-ring-color: rgb(190 242 100 / 0.6) !important;
}

.tw-ring-lime-300\/65 {
  --tw-ring-color: rgb(190 242 100 / 0.65) !important;
}

.tw-ring-lime-300\/70 {
  --tw-ring-color: rgb(190 242 100 / 0.7) !important;
}

.tw-ring-lime-300\/75 {
  --tw-ring-color: rgb(190 242 100 / 0.75) !important;
}

.tw-ring-lime-300\/80 {
  --tw-ring-color: rgb(190 242 100 / 0.8) !important;
}

.tw-ring-lime-300\/85 {
  --tw-ring-color: rgb(190 242 100 / 0.85) !important;
}

.tw-ring-lime-300\/90 {
  --tw-ring-color: rgb(190 242 100 / 0.9) !important;
}

.tw-ring-lime-300\/95 {
  --tw-ring-color: rgb(190 242 100 / 0.95) !important;
}

.tw-ring-lime-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(163 230 53 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-400\/0 {
  --tw-ring-color: rgb(163 230 53 / 0) !important;
}

.tw-ring-lime-400\/10 {
  --tw-ring-color: rgb(163 230 53 / 0.1) !important;
}

.tw-ring-lime-400\/100 {
  --tw-ring-color: rgb(163 230 53 / 1) !important;
}

.tw-ring-lime-400\/15 {
  --tw-ring-color: rgb(163 230 53 / 0.15) !important;
}

.tw-ring-lime-400\/20 {
  --tw-ring-color: rgb(163 230 53 / 0.2) !important;
}

.tw-ring-lime-400\/25 {
  --tw-ring-color: rgb(163 230 53 / 0.25) !important;
}

.tw-ring-lime-400\/30 {
  --tw-ring-color: rgb(163 230 53 / 0.3) !important;
}

.tw-ring-lime-400\/35 {
  --tw-ring-color: rgb(163 230 53 / 0.35) !important;
}

.tw-ring-lime-400\/40 {
  --tw-ring-color: rgb(163 230 53 / 0.4) !important;
}

.tw-ring-lime-400\/45 {
  --tw-ring-color: rgb(163 230 53 / 0.45) !important;
}

.tw-ring-lime-400\/5 {
  --tw-ring-color: rgb(163 230 53 / 0.05) !important;
}

.tw-ring-lime-400\/50 {
  --tw-ring-color: rgb(163 230 53 / 0.5) !important;
}

.tw-ring-lime-400\/55 {
  --tw-ring-color: rgb(163 230 53 / 0.55) !important;
}

.tw-ring-lime-400\/60 {
  --tw-ring-color: rgb(163 230 53 / 0.6) !important;
}

.tw-ring-lime-400\/65 {
  --tw-ring-color: rgb(163 230 53 / 0.65) !important;
}

.tw-ring-lime-400\/70 {
  --tw-ring-color: rgb(163 230 53 / 0.7) !important;
}

.tw-ring-lime-400\/75 {
  --tw-ring-color: rgb(163 230 53 / 0.75) !important;
}

.tw-ring-lime-400\/80 {
  --tw-ring-color: rgb(163 230 53 / 0.8) !important;
}

.tw-ring-lime-400\/85 {
  --tw-ring-color: rgb(163 230 53 / 0.85) !important;
}

.tw-ring-lime-400\/90 {
  --tw-ring-color: rgb(163 230 53 / 0.9) !important;
}

.tw-ring-lime-400\/95 {
  --tw-ring-color: rgb(163 230 53 / 0.95) !important;
}

.tw-ring-lime-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(247 254 231 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-50\/0 {
  --tw-ring-color: rgb(247 254 231 / 0) !important;
}

.tw-ring-lime-50\/10 {
  --tw-ring-color: rgb(247 254 231 / 0.1) !important;
}

.tw-ring-lime-50\/100 {
  --tw-ring-color: rgb(247 254 231 / 1) !important;
}

.tw-ring-lime-50\/15 {
  --tw-ring-color: rgb(247 254 231 / 0.15) !important;
}

.tw-ring-lime-50\/20 {
  --tw-ring-color: rgb(247 254 231 / 0.2) !important;
}

.tw-ring-lime-50\/25 {
  --tw-ring-color: rgb(247 254 231 / 0.25) !important;
}

.tw-ring-lime-50\/30 {
  --tw-ring-color: rgb(247 254 231 / 0.3) !important;
}

.tw-ring-lime-50\/35 {
  --tw-ring-color: rgb(247 254 231 / 0.35) !important;
}

.tw-ring-lime-50\/40 {
  --tw-ring-color: rgb(247 254 231 / 0.4) !important;
}

.tw-ring-lime-50\/45 {
  --tw-ring-color: rgb(247 254 231 / 0.45) !important;
}

.tw-ring-lime-50\/5 {
  --tw-ring-color: rgb(247 254 231 / 0.05) !important;
}

.tw-ring-lime-50\/50 {
  --tw-ring-color: rgb(247 254 231 / 0.5) !important;
}

.tw-ring-lime-50\/55 {
  --tw-ring-color: rgb(247 254 231 / 0.55) !important;
}

.tw-ring-lime-50\/60 {
  --tw-ring-color: rgb(247 254 231 / 0.6) !important;
}

.tw-ring-lime-50\/65 {
  --tw-ring-color: rgb(247 254 231 / 0.65) !important;
}

.tw-ring-lime-50\/70 {
  --tw-ring-color: rgb(247 254 231 / 0.7) !important;
}

.tw-ring-lime-50\/75 {
  --tw-ring-color: rgb(247 254 231 / 0.75) !important;
}

.tw-ring-lime-50\/80 {
  --tw-ring-color: rgb(247 254 231 / 0.8) !important;
}

.tw-ring-lime-50\/85 {
  --tw-ring-color: rgb(247 254 231 / 0.85) !important;
}

.tw-ring-lime-50\/90 {
  --tw-ring-color: rgb(247 254 231 / 0.9) !important;
}

.tw-ring-lime-50\/95 {
  --tw-ring-color: rgb(247 254 231 / 0.95) !important;
}

.tw-ring-lime-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(132 204 22 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-500\/0 {
  --tw-ring-color: rgb(132 204 22 / 0) !important;
}

.tw-ring-lime-500\/10 {
  --tw-ring-color: rgb(132 204 22 / 0.1) !important;
}

.tw-ring-lime-500\/100 {
  --tw-ring-color: rgb(132 204 22 / 1) !important;
}

.tw-ring-lime-500\/15 {
  --tw-ring-color: rgb(132 204 22 / 0.15) !important;
}

.tw-ring-lime-500\/20 {
  --tw-ring-color: rgb(132 204 22 / 0.2) !important;
}

.tw-ring-lime-500\/25 {
  --tw-ring-color: rgb(132 204 22 / 0.25) !important;
}

.tw-ring-lime-500\/30 {
  --tw-ring-color: rgb(132 204 22 / 0.3) !important;
}

.tw-ring-lime-500\/35 {
  --tw-ring-color: rgb(132 204 22 / 0.35) !important;
}

.tw-ring-lime-500\/40 {
  --tw-ring-color: rgb(132 204 22 / 0.4) !important;
}

.tw-ring-lime-500\/45 {
  --tw-ring-color: rgb(132 204 22 / 0.45) !important;
}

.tw-ring-lime-500\/5 {
  --tw-ring-color: rgb(132 204 22 / 0.05) !important;
}

.tw-ring-lime-500\/50 {
  --tw-ring-color: rgb(132 204 22 / 0.5) !important;
}

.tw-ring-lime-500\/55 {
  --tw-ring-color: rgb(132 204 22 / 0.55) !important;
}

.tw-ring-lime-500\/60 {
  --tw-ring-color: rgb(132 204 22 / 0.6) !important;
}

.tw-ring-lime-500\/65 {
  --tw-ring-color: rgb(132 204 22 / 0.65) !important;
}

.tw-ring-lime-500\/70 {
  --tw-ring-color: rgb(132 204 22 / 0.7) !important;
}

.tw-ring-lime-500\/75 {
  --tw-ring-color: rgb(132 204 22 / 0.75) !important;
}

.tw-ring-lime-500\/80 {
  --tw-ring-color: rgb(132 204 22 / 0.8) !important;
}

.tw-ring-lime-500\/85 {
  --tw-ring-color: rgb(132 204 22 / 0.85) !important;
}

.tw-ring-lime-500\/90 {
  --tw-ring-color: rgb(132 204 22 / 0.9) !important;
}

.tw-ring-lime-500\/95 {
  --tw-ring-color: rgb(132 204 22 / 0.95) !important;
}

.tw-ring-lime-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(101 163 13 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-600\/0 {
  --tw-ring-color: rgb(101 163 13 / 0) !important;
}

.tw-ring-lime-600\/10 {
  --tw-ring-color: rgb(101 163 13 / 0.1) !important;
}

.tw-ring-lime-600\/100 {
  --tw-ring-color: rgb(101 163 13 / 1) !important;
}

.tw-ring-lime-600\/15 {
  --tw-ring-color: rgb(101 163 13 / 0.15) !important;
}

.tw-ring-lime-600\/20 {
  --tw-ring-color: rgb(101 163 13 / 0.2) !important;
}

.tw-ring-lime-600\/25 {
  --tw-ring-color: rgb(101 163 13 / 0.25) !important;
}

.tw-ring-lime-600\/30 {
  --tw-ring-color: rgb(101 163 13 / 0.3) !important;
}

.tw-ring-lime-600\/35 {
  --tw-ring-color: rgb(101 163 13 / 0.35) !important;
}

.tw-ring-lime-600\/40 {
  --tw-ring-color: rgb(101 163 13 / 0.4) !important;
}

.tw-ring-lime-600\/45 {
  --tw-ring-color: rgb(101 163 13 / 0.45) !important;
}

.tw-ring-lime-600\/5 {
  --tw-ring-color: rgb(101 163 13 / 0.05) !important;
}

.tw-ring-lime-600\/50 {
  --tw-ring-color: rgb(101 163 13 / 0.5) !important;
}

.tw-ring-lime-600\/55 {
  --tw-ring-color: rgb(101 163 13 / 0.55) !important;
}

.tw-ring-lime-600\/60 {
  --tw-ring-color: rgb(101 163 13 / 0.6) !important;
}

.tw-ring-lime-600\/65 {
  --tw-ring-color: rgb(101 163 13 / 0.65) !important;
}

.tw-ring-lime-600\/70 {
  --tw-ring-color: rgb(101 163 13 / 0.7) !important;
}

.tw-ring-lime-600\/75 {
  --tw-ring-color: rgb(101 163 13 / 0.75) !important;
}

.tw-ring-lime-600\/80 {
  --tw-ring-color: rgb(101 163 13 / 0.8) !important;
}

.tw-ring-lime-600\/85 {
  --tw-ring-color: rgb(101 163 13 / 0.85) !important;
}

.tw-ring-lime-600\/90 {
  --tw-ring-color: rgb(101 163 13 / 0.9) !important;
}

.tw-ring-lime-600\/95 {
  --tw-ring-color: rgb(101 163 13 / 0.95) !important;
}

.tw-ring-lime-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(77 124 15 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-700\/0 {
  --tw-ring-color: rgb(77 124 15 / 0) !important;
}

.tw-ring-lime-700\/10 {
  --tw-ring-color: rgb(77 124 15 / 0.1) !important;
}

.tw-ring-lime-700\/100 {
  --tw-ring-color: rgb(77 124 15 / 1) !important;
}

.tw-ring-lime-700\/15 {
  --tw-ring-color: rgb(77 124 15 / 0.15) !important;
}

.tw-ring-lime-700\/20 {
  --tw-ring-color: rgb(77 124 15 / 0.2) !important;
}

.tw-ring-lime-700\/25 {
  --tw-ring-color: rgb(77 124 15 / 0.25) !important;
}

.tw-ring-lime-700\/30 {
  --tw-ring-color: rgb(77 124 15 / 0.3) !important;
}

.tw-ring-lime-700\/35 {
  --tw-ring-color: rgb(77 124 15 / 0.35) !important;
}

.tw-ring-lime-700\/40 {
  --tw-ring-color: rgb(77 124 15 / 0.4) !important;
}

.tw-ring-lime-700\/45 {
  --tw-ring-color: rgb(77 124 15 / 0.45) !important;
}

.tw-ring-lime-700\/5 {
  --tw-ring-color: rgb(77 124 15 / 0.05) !important;
}

.tw-ring-lime-700\/50 {
  --tw-ring-color: rgb(77 124 15 / 0.5) !important;
}

.tw-ring-lime-700\/55 {
  --tw-ring-color: rgb(77 124 15 / 0.55) !important;
}

.tw-ring-lime-700\/60 {
  --tw-ring-color: rgb(77 124 15 / 0.6) !important;
}

.tw-ring-lime-700\/65 {
  --tw-ring-color: rgb(77 124 15 / 0.65) !important;
}

.tw-ring-lime-700\/70 {
  --tw-ring-color: rgb(77 124 15 / 0.7) !important;
}

.tw-ring-lime-700\/75 {
  --tw-ring-color: rgb(77 124 15 / 0.75) !important;
}

.tw-ring-lime-700\/80 {
  --tw-ring-color: rgb(77 124 15 / 0.8) !important;
}

.tw-ring-lime-700\/85 {
  --tw-ring-color: rgb(77 124 15 / 0.85) !important;
}

.tw-ring-lime-700\/90 {
  --tw-ring-color: rgb(77 124 15 / 0.9) !important;
}

.tw-ring-lime-700\/95 {
  --tw-ring-color: rgb(77 124 15 / 0.95) !important;
}

.tw-ring-lime-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(63 98 18 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-800\/0 {
  --tw-ring-color: rgb(63 98 18 / 0) !important;
}

.tw-ring-lime-800\/10 {
  --tw-ring-color: rgb(63 98 18 / 0.1) !important;
}

.tw-ring-lime-800\/100 {
  --tw-ring-color: rgb(63 98 18 / 1) !important;
}

.tw-ring-lime-800\/15 {
  --tw-ring-color: rgb(63 98 18 / 0.15) !important;
}

.tw-ring-lime-800\/20 {
  --tw-ring-color: rgb(63 98 18 / 0.2) !important;
}

.tw-ring-lime-800\/25 {
  --tw-ring-color: rgb(63 98 18 / 0.25) !important;
}

.tw-ring-lime-800\/30 {
  --tw-ring-color: rgb(63 98 18 / 0.3) !important;
}

.tw-ring-lime-800\/35 {
  --tw-ring-color: rgb(63 98 18 / 0.35) !important;
}

.tw-ring-lime-800\/40 {
  --tw-ring-color: rgb(63 98 18 / 0.4) !important;
}

.tw-ring-lime-800\/45 {
  --tw-ring-color: rgb(63 98 18 / 0.45) !important;
}

.tw-ring-lime-800\/5 {
  --tw-ring-color: rgb(63 98 18 / 0.05) !important;
}

.tw-ring-lime-800\/50 {
  --tw-ring-color: rgb(63 98 18 / 0.5) !important;
}

.tw-ring-lime-800\/55 {
  --tw-ring-color: rgb(63 98 18 / 0.55) !important;
}

.tw-ring-lime-800\/60 {
  --tw-ring-color: rgb(63 98 18 / 0.6) !important;
}

.tw-ring-lime-800\/65 {
  --tw-ring-color: rgb(63 98 18 / 0.65) !important;
}

.tw-ring-lime-800\/70 {
  --tw-ring-color: rgb(63 98 18 / 0.7) !important;
}

.tw-ring-lime-800\/75 {
  --tw-ring-color: rgb(63 98 18 / 0.75) !important;
}

.tw-ring-lime-800\/80 {
  --tw-ring-color: rgb(63 98 18 / 0.8) !important;
}

.tw-ring-lime-800\/85 {
  --tw-ring-color: rgb(63 98 18 / 0.85) !important;
}

.tw-ring-lime-800\/90 {
  --tw-ring-color: rgb(63 98 18 / 0.9) !important;
}

.tw-ring-lime-800\/95 {
  --tw-ring-color: rgb(63 98 18 / 0.95) !important;
}

.tw-ring-lime-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(54 83 20 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-900\/0 {
  --tw-ring-color: rgb(54 83 20 / 0) !important;
}

.tw-ring-lime-900\/10 {
  --tw-ring-color: rgb(54 83 20 / 0.1) !important;
}

.tw-ring-lime-900\/100 {
  --tw-ring-color: rgb(54 83 20 / 1) !important;
}

.tw-ring-lime-900\/15 {
  --tw-ring-color: rgb(54 83 20 / 0.15) !important;
}

.tw-ring-lime-900\/20 {
  --tw-ring-color: rgb(54 83 20 / 0.2) !important;
}

.tw-ring-lime-900\/25 {
  --tw-ring-color: rgb(54 83 20 / 0.25) !important;
}

.tw-ring-lime-900\/30 {
  --tw-ring-color: rgb(54 83 20 / 0.3) !important;
}

.tw-ring-lime-900\/35 {
  --tw-ring-color: rgb(54 83 20 / 0.35) !important;
}

.tw-ring-lime-900\/40 {
  --tw-ring-color: rgb(54 83 20 / 0.4) !important;
}

.tw-ring-lime-900\/45 {
  --tw-ring-color: rgb(54 83 20 / 0.45) !important;
}

.tw-ring-lime-900\/5 {
  --tw-ring-color: rgb(54 83 20 / 0.05) !important;
}

.tw-ring-lime-900\/50 {
  --tw-ring-color: rgb(54 83 20 / 0.5) !important;
}

.tw-ring-lime-900\/55 {
  --tw-ring-color: rgb(54 83 20 / 0.55) !important;
}

.tw-ring-lime-900\/60 {
  --tw-ring-color: rgb(54 83 20 / 0.6) !important;
}

.tw-ring-lime-900\/65 {
  --tw-ring-color: rgb(54 83 20 / 0.65) !important;
}

.tw-ring-lime-900\/70 {
  --tw-ring-color: rgb(54 83 20 / 0.7) !important;
}

.tw-ring-lime-900\/75 {
  --tw-ring-color: rgb(54 83 20 / 0.75) !important;
}

.tw-ring-lime-900\/80 {
  --tw-ring-color: rgb(54 83 20 / 0.8) !important;
}

.tw-ring-lime-900\/85 {
  --tw-ring-color: rgb(54 83 20 / 0.85) !important;
}

.tw-ring-lime-900\/90 {
  --tw-ring-color: rgb(54 83 20 / 0.9) !important;
}

.tw-ring-lime-900\/95 {
  --tw-ring-color: rgb(54 83 20 / 0.95) !important;
}

.tw-ring-lime-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(26 46 5 / var(--tw-ring-opacity)) !important;
}

.tw-ring-lime-950\/0 {
  --tw-ring-color: rgb(26 46 5 / 0) !important;
}

.tw-ring-lime-950\/10 {
  --tw-ring-color: rgb(26 46 5 / 0.1) !important;
}

.tw-ring-lime-950\/100 {
  --tw-ring-color: rgb(26 46 5 / 1) !important;
}

.tw-ring-lime-950\/15 {
  --tw-ring-color: rgb(26 46 5 / 0.15) !important;
}

.tw-ring-lime-950\/20 {
  --tw-ring-color: rgb(26 46 5 / 0.2) !important;
}

.tw-ring-lime-950\/25 {
  --tw-ring-color: rgb(26 46 5 / 0.25) !important;
}

.tw-ring-lime-950\/30 {
  --tw-ring-color: rgb(26 46 5 / 0.3) !important;
}

.tw-ring-lime-950\/35 {
  --tw-ring-color: rgb(26 46 5 / 0.35) !important;
}

.tw-ring-lime-950\/40 {
  --tw-ring-color: rgb(26 46 5 / 0.4) !important;
}

.tw-ring-lime-950\/45 {
  --tw-ring-color: rgb(26 46 5 / 0.45) !important;
}

.tw-ring-lime-950\/5 {
  --tw-ring-color: rgb(26 46 5 / 0.05) !important;
}

.tw-ring-lime-950\/50 {
  --tw-ring-color: rgb(26 46 5 / 0.5) !important;
}

.tw-ring-lime-950\/55 {
  --tw-ring-color: rgb(26 46 5 / 0.55) !important;
}

.tw-ring-lime-950\/60 {
  --tw-ring-color: rgb(26 46 5 / 0.6) !important;
}

.tw-ring-lime-950\/65 {
  --tw-ring-color: rgb(26 46 5 / 0.65) !important;
}

.tw-ring-lime-950\/70 {
  --tw-ring-color: rgb(26 46 5 / 0.7) !important;
}

.tw-ring-lime-950\/75 {
  --tw-ring-color: rgb(26 46 5 / 0.75) !important;
}

.tw-ring-lime-950\/80 {
  --tw-ring-color: rgb(26 46 5 / 0.8) !important;
}

.tw-ring-lime-950\/85 {
  --tw-ring-color: rgb(26 46 5 / 0.85) !important;
}

.tw-ring-lime-950\/90 {
  --tw-ring-color: rgb(26 46 5 / 0.9) !important;
}

.tw-ring-lime-950\/95 {
  --tw-ring-color: rgb(26 46 5 / 0.95) !important;
}

.tw-ring-neutral-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(245 245 245 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-100\/0 {
  --tw-ring-color: rgb(245 245 245 / 0) !important;
}

.tw-ring-neutral-100\/10 {
  --tw-ring-color: rgb(245 245 245 / 0.1) !important;
}

.tw-ring-neutral-100\/100 {
  --tw-ring-color: rgb(245 245 245 / 1) !important;
}

.tw-ring-neutral-100\/15 {
  --tw-ring-color: rgb(245 245 245 / 0.15) !important;
}

.tw-ring-neutral-100\/20 {
  --tw-ring-color: rgb(245 245 245 / 0.2) !important;
}

.tw-ring-neutral-100\/25 {
  --tw-ring-color: rgb(245 245 245 / 0.25) !important;
}

.tw-ring-neutral-100\/30 {
  --tw-ring-color: rgb(245 245 245 / 0.3) !important;
}

.tw-ring-neutral-100\/35 {
  --tw-ring-color: rgb(245 245 245 / 0.35) !important;
}

.tw-ring-neutral-100\/40 {
  --tw-ring-color: rgb(245 245 245 / 0.4) !important;
}

.tw-ring-neutral-100\/45 {
  --tw-ring-color: rgb(245 245 245 / 0.45) !important;
}

.tw-ring-neutral-100\/5 {
  --tw-ring-color: rgb(245 245 245 / 0.05) !important;
}

.tw-ring-neutral-100\/50 {
  --tw-ring-color: rgb(245 245 245 / 0.5) !important;
}

.tw-ring-neutral-100\/55 {
  --tw-ring-color: rgb(245 245 245 / 0.55) !important;
}

.tw-ring-neutral-100\/60 {
  --tw-ring-color: rgb(245 245 245 / 0.6) !important;
}

.tw-ring-neutral-100\/65 {
  --tw-ring-color: rgb(245 245 245 / 0.65) !important;
}

.tw-ring-neutral-100\/70 {
  --tw-ring-color: rgb(245 245 245 / 0.7) !important;
}

.tw-ring-neutral-100\/75 {
  --tw-ring-color: rgb(245 245 245 / 0.75) !important;
}

.tw-ring-neutral-100\/80 {
  --tw-ring-color: rgb(245 245 245 / 0.8) !important;
}

.tw-ring-neutral-100\/85 {
  --tw-ring-color: rgb(245 245 245 / 0.85) !important;
}

.tw-ring-neutral-100\/90 {
  --tw-ring-color: rgb(245 245 245 / 0.9) !important;
}

.tw-ring-neutral-100\/95 {
  --tw-ring-color: rgb(245 245 245 / 0.95) !important;
}

.tw-ring-neutral-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(229 229 229 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-200\/0 {
  --tw-ring-color: rgb(229 229 229 / 0) !important;
}

.tw-ring-neutral-200\/10 {
  --tw-ring-color: rgb(229 229 229 / 0.1) !important;
}

.tw-ring-neutral-200\/100 {
  --tw-ring-color: rgb(229 229 229 / 1) !important;
}

.tw-ring-neutral-200\/15 {
  --tw-ring-color: rgb(229 229 229 / 0.15) !important;
}

.tw-ring-neutral-200\/20 {
  --tw-ring-color: rgb(229 229 229 / 0.2) !important;
}

.tw-ring-neutral-200\/25 {
  --tw-ring-color: rgb(229 229 229 / 0.25) !important;
}

.tw-ring-neutral-200\/30 {
  --tw-ring-color: rgb(229 229 229 / 0.3) !important;
}

.tw-ring-neutral-200\/35 {
  --tw-ring-color: rgb(229 229 229 / 0.35) !important;
}

.tw-ring-neutral-200\/40 {
  --tw-ring-color: rgb(229 229 229 / 0.4) !important;
}

.tw-ring-neutral-200\/45 {
  --tw-ring-color: rgb(229 229 229 / 0.45) !important;
}

.tw-ring-neutral-200\/5 {
  --tw-ring-color: rgb(229 229 229 / 0.05) !important;
}

.tw-ring-neutral-200\/50 {
  --tw-ring-color: rgb(229 229 229 / 0.5) !important;
}

.tw-ring-neutral-200\/55 {
  --tw-ring-color: rgb(229 229 229 / 0.55) !important;
}

.tw-ring-neutral-200\/60 {
  --tw-ring-color: rgb(229 229 229 / 0.6) !important;
}

.tw-ring-neutral-200\/65 {
  --tw-ring-color: rgb(229 229 229 / 0.65) !important;
}

.tw-ring-neutral-200\/70 {
  --tw-ring-color: rgb(229 229 229 / 0.7) !important;
}

.tw-ring-neutral-200\/75 {
  --tw-ring-color: rgb(229 229 229 / 0.75) !important;
}

.tw-ring-neutral-200\/80 {
  --tw-ring-color: rgb(229 229 229 / 0.8) !important;
}

.tw-ring-neutral-200\/85 {
  --tw-ring-color: rgb(229 229 229 / 0.85) !important;
}

.tw-ring-neutral-200\/90 {
  --tw-ring-color: rgb(229 229 229 / 0.9) !important;
}

.tw-ring-neutral-200\/95 {
  --tw-ring-color: rgb(229 229 229 / 0.95) !important;
}

.tw-ring-neutral-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(212 212 212 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-300\/0 {
  --tw-ring-color: rgb(212 212 212 / 0) !important;
}

.tw-ring-neutral-300\/10 {
  --tw-ring-color: rgb(212 212 212 / 0.1) !important;
}

.tw-ring-neutral-300\/100 {
  --tw-ring-color: rgb(212 212 212 / 1) !important;
}

.tw-ring-neutral-300\/15 {
  --tw-ring-color: rgb(212 212 212 / 0.15) !important;
}

.tw-ring-neutral-300\/20 {
  --tw-ring-color: rgb(212 212 212 / 0.2) !important;
}

.tw-ring-neutral-300\/25 {
  --tw-ring-color: rgb(212 212 212 / 0.25) !important;
}

.tw-ring-neutral-300\/30 {
  --tw-ring-color: rgb(212 212 212 / 0.3) !important;
}

.tw-ring-neutral-300\/35 {
  --tw-ring-color: rgb(212 212 212 / 0.35) !important;
}

.tw-ring-neutral-300\/40 {
  --tw-ring-color: rgb(212 212 212 / 0.4) !important;
}

.tw-ring-neutral-300\/45 {
  --tw-ring-color: rgb(212 212 212 / 0.45) !important;
}

.tw-ring-neutral-300\/5 {
  --tw-ring-color: rgb(212 212 212 / 0.05) !important;
}

.tw-ring-neutral-300\/50 {
  --tw-ring-color: rgb(212 212 212 / 0.5) !important;
}

.tw-ring-neutral-300\/55 {
  --tw-ring-color: rgb(212 212 212 / 0.55) !important;
}

.tw-ring-neutral-300\/60 {
  --tw-ring-color: rgb(212 212 212 / 0.6) !important;
}

.tw-ring-neutral-300\/65 {
  --tw-ring-color: rgb(212 212 212 / 0.65) !important;
}

.tw-ring-neutral-300\/70 {
  --tw-ring-color: rgb(212 212 212 / 0.7) !important;
}

.tw-ring-neutral-300\/75 {
  --tw-ring-color: rgb(212 212 212 / 0.75) !important;
}

.tw-ring-neutral-300\/80 {
  --tw-ring-color: rgb(212 212 212 / 0.8) !important;
}

.tw-ring-neutral-300\/85 {
  --tw-ring-color: rgb(212 212 212 / 0.85) !important;
}

.tw-ring-neutral-300\/90 {
  --tw-ring-color: rgb(212 212 212 / 0.9) !important;
}

.tw-ring-neutral-300\/95 {
  --tw-ring-color: rgb(212 212 212 / 0.95) !important;
}

.tw-ring-neutral-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(163 163 163 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-400\/0 {
  --tw-ring-color: rgb(163 163 163 / 0) !important;
}

.tw-ring-neutral-400\/10 {
  --tw-ring-color: rgb(163 163 163 / 0.1) !important;
}

.tw-ring-neutral-400\/100 {
  --tw-ring-color: rgb(163 163 163 / 1) !important;
}

.tw-ring-neutral-400\/15 {
  --tw-ring-color: rgb(163 163 163 / 0.15) !important;
}

.tw-ring-neutral-400\/20 {
  --tw-ring-color: rgb(163 163 163 / 0.2) !important;
}

.tw-ring-neutral-400\/25 {
  --tw-ring-color: rgb(163 163 163 / 0.25) !important;
}

.tw-ring-neutral-400\/30 {
  --tw-ring-color: rgb(163 163 163 / 0.3) !important;
}

.tw-ring-neutral-400\/35 {
  --tw-ring-color: rgb(163 163 163 / 0.35) !important;
}

.tw-ring-neutral-400\/40 {
  --tw-ring-color: rgb(163 163 163 / 0.4) !important;
}

.tw-ring-neutral-400\/45 {
  --tw-ring-color: rgb(163 163 163 / 0.45) !important;
}

.tw-ring-neutral-400\/5 {
  --tw-ring-color: rgb(163 163 163 / 0.05) !important;
}

.tw-ring-neutral-400\/50 {
  --tw-ring-color: rgb(163 163 163 / 0.5) !important;
}

.tw-ring-neutral-400\/55 {
  --tw-ring-color: rgb(163 163 163 / 0.55) !important;
}

.tw-ring-neutral-400\/60 {
  --tw-ring-color: rgb(163 163 163 / 0.6) !important;
}

.tw-ring-neutral-400\/65 {
  --tw-ring-color: rgb(163 163 163 / 0.65) !important;
}

.tw-ring-neutral-400\/70 {
  --tw-ring-color: rgb(163 163 163 / 0.7) !important;
}

.tw-ring-neutral-400\/75 {
  --tw-ring-color: rgb(163 163 163 / 0.75) !important;
}

.tw-ring-neutral-400\/80 {
  --tw-ring-color: rgb(163 163 163 / 0.8) !important;
}

.tw-ring-neutral-400\/85 {
  --tw-ring-color: rgb(163 163 163 / 0.85) !important;
}

.tw-ring-neutral-400\/90 {
  --tw-ring-color: rgb(163 163 163 / 0.9) !important;
}

.tw-ring-neutral-400\/95 {
  --tw-ring-color: rgb(163 163 163 / 0.95) !important;
}

.tw-ring-neutral-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-50\/0 {
  --tw-ring-color: rgb(250 250 250 / 0) !important;
}

.tw-ring-neutral-50\/10 {
  --tw-ring-color: rgb(250 250 250 / 0.1) !important;
}

.tw-ring-neutral-50\/100 {
  --tw-ring-color: rgb(250 250 250 / 1) !important;
}

.tw-ring-neutral-50\/15 {
  --tw-ring-color: rgb(250 250 250 / 0.15) !important;
}

.tw-ring-neutral-50\/20 {
  --tw-ring-color: rgb(250 250 250 / 0.2) !important;
}

.tw-ring-neutral-50\/25 {
  --tw-ring-color: rgb(250 250 250 / 0.25) !important;
}

.tw-ring-neutral-50\/30 {
  --tw-ring-color: rgb(250 250 250 / 0.3) !important;
}

.tw-ring-neutral-50\/35 {
  --tw-ring-color: rgb(250 250 250 / 0.35) !important;
}

.tw-ring-neutral-50\/40 {
  --tw-ring-color: rgb(250 250 250 / 0.4) !important;
}

.tw-ring-neutral-50\/45 {
  --tw-ring-color: rgb(250 250 250 / 0.45) !important;
}

.tw-ring-neutral-50\/5 {
  --tw-ring-color: rgb(250 250 250 / 0.05) !important;
}

.tw-ring-neutral-50\/50 {
  --tw-ring-color: rgb(250 250 250 / 0.5) !important;
}

.tw-ring-neutral-50\/55 {
  --tw-ring-color: rgb(250 250 250 / 0.55) !important;
}

.tw-ring-neutral-50\/60 {
  --tw-ring-color: rgb(250 250 250 / 0.6) !important;
}

.tw-ring-neutral-50\/65 {
  --tw-ring-color: rgb(250 250 250 / 0.65) !important;
}

.tw-ring-neutral-50\/70 {
  --tw-ring-color: rgb(250 250 250 / 0.7) !important;
}

.tw-ring-neutral-50\/75 {
  --tw-ring-color: rgb(250 250 250 / 0.75) !important;
}

.tw-ring-neutral-50\/80 {
  --tw-ring-color: rgb(250 250 250 / 0.8) !important;
}

.tw-ring-neutral-50\/85 {
  --tw-ring-color: rgb(250 250 250 / 0.85) !important;
}

.tw-ring-neutral-50\/90 {
  --tw-ring-color: rgb(250 250 250 / 0.9) !important;
}

.tw-ring-neutral-50\/95 {
  --tw-ring-color: rgb(250 250 250 / 0.95) !important;
}

.tw-ring-neutral-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(115 115 115 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-500\/0 {
  --tw-ring-color: rgb(115 115 115 / 0) !important;
}

.tw-ring-neutral-500\/10 {
  --tw-ring-color: rgb(115 115 115 / 0.1) !important;
}

.tw-ring-neutral-500\/100 {
  --tw-ring-color: rgb(115 115 115 / 1) !important;
}

.tw-ring-neutral-500\/15 {
  --tw-ring-color: rgb(115 115 115 / 0.15) !important;
}

.tw-ring-neutral-500\/20 {
  --tw-ring-color: rgb(115 115 115 / 0.2) !important;
}

.tw-ring-neutral-500\/25 {
  --tw-ring-color: rgb(115 115 115 / 0.25) !important;
}

.tw-ring-neutral-500\/30 {
  --tw-ring-color: rgb(115 115 115 / 0.3) !important;
}

.tw-ring-neutral-500\/35 {
  --tw-ring-color: rgb(115 115 115 / 0.35) !important;
}

.tw-ring-neutral-500\/40 {
  --tw-ring-color: rgb(115 115 115 / 0.4) !important;
}

.tw-ring-neutral-500\/45 {
  --tw-ring-color: rgb(115 115 115 / 0.45) !important;
}

.tw-ring-neutral-500\/5 {
  --tw-ring-color: rgb(115 115 115 / 0.05) !important;
}

.tw-ring-neutral-500\/50 {
  --tw-ring-color: rgb(115 115 115 / 0.5) !important;
}

.tw-ring-neutral-500\/55 {
  --tw-ring-color: rgb(115 115 115 / 0.55) !important;
}

.tw-ring-neutral-500\/60 {
  --tw-ring-color: rgb(115 115 115 / 0.6) !important;
}

.tw-ring-neutral-500\/65 {
  --tw-ring-color: rgb(115 115 115 / 0.65) !important;
}

.tw-ring-neutral-500\/70 {
  --tw-ring-color: rgb(115 115 115 / 0.7) !important;
}

.tw-ring-neutral-500\/75 {
  --tw-ring-color: rgb(115 115 115 / 0.75) !important;
}

.tw-ring-neutral-500\/80 {
  --tw-ring-color: rgb(115 115 115 / 0.8) !important;
}

.tw-ring-neutral-500\/85 {
  --tw-ring-color: rgb(115 115 115 / 0.85) !important;
}

.tw-ring-neutral-500\/90 {
  --tw-ring-color: rgb(115 115 115 / 0.9) !important;
}

.tw-ring-neutral-500\/95 {
  --tw-ring-color: rgb(115 115 115 / 0.95) !important;
}

.tw-ring-neutral-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(82 82 82 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-600\/0 {
  --tw-ring-color: rgb(82 82 82 / 0) !important;
}

.tw-ring-neutral-600\/10 {
  --tw-ring-color: rgb(82 82 82 / 0.1) !important;
}

.tw-ring-neutral-600\/100 {
  --tw-ring-color: rgb(82 82 82 / 1) !important;
}

.tw-ring-neutral-600\/15 {
  --tw-ring-color: rgb(82 82 82 / 0.15) !important;
}

.tw-ring-neutral-600\/20 {
  --tw-ring-color: rgb(82 82 82 / 0.2) !important;
}

.tw-ring-neutral-600\/25 {
  --tw-ring-color: rgb(82 82 82 / 0.25) !important;
}

.tw-ring-neutral-600\/30 {
  --tw-ring-color: rgb(82 82 82 / 0.3) !important;
}

.tw-ring-neutral-600\/35 {
  --tw-ring-color: rgb(82 82 82 / 0.35) !important;
}

.tw-ring-neutral-600\/40 {
  --tw-ring-color: rgb(82 82 82 / 0.4) !important;
}

.tw-ring-neutral-600\/45 {
  --tw-ring-color: rgb(82 82 82 / 0.45) !important;
}

.tw-ring-neutral-600\/5 {
  --tw-ring-color: rgb(82 82 82 / 0.05) !important;
}

.tw-ring-neutral-600\/50 {
  --tw-ring-color: rgb(82 82 82 / 0.5) !important;
}

.tw-ring-neutral-600\/55 {
  --tw-ring-color: rgb(82 82 82 / 0.55) !important;
}

.tw-ring-neutral-600\/60 {
  --tw-ring-color: rgb(82 82 82 / 0.6) !important;
}

.tw-ring-neutral-600\/65 {
  --tw-ring-color: rgb(82 82 82 / 0.65) !important;
}

.tw-ring-neutral-600\/70 {
  --tw-ring-color: rgb(82 82 82 / 0.7) !important;
}

.tw-ring-neutral-600\/75 {
  --tw-ring-color: rgb(82 82 82 / 0.75) !important;
}

.tw-ring-neutral-600\/80 {
  --tw-ring-color: rgb(82 82 82 / 0.8) !important;
}

.tw-ring-neutral-600\/85 {
  --tw-ring-color: rgb(82 82 82 / 0.85) !important;
}

.tw-ring-neutral-600\/90 {
  --tw-ring-color: rgb(82 82 82 / 0.9) !important;
}

.tw-ring-neutral-600\/95 {
  --tw-ring-color: rgb(82 82 82 / 0.95) !important;
}

.tw-ring-neutral-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(64 64 64 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-700\/0 {
  --tw-ring-color: rgb(64 64 64 / 0) !important;
}

.tw-ring-neutral-700\/10 {
  --tw-ring-color: rgb(64 64 64 / 0.1) !important;
}

.tw-ring-neutral-700\/100 {
  --tw-ring-color: rgb(64 64 64 / 1) !important;
}

.tw-ring-neutral-700\/15 {
  --tw-ring-color: rgb(64 64 64 / 0.15) !important;
}

.tw-ring-neutral-700\/20 {
  --tw-ring-color: rgb(64 64 64 / 0.2) !important;
}

.tw-ring-neutral-700\/25 {
  --tw-ring-color: rgb(64 64 64 / 0.25) !important;
}

.tw-ring-neutral-700\/30 {
  --tw-ring-color: rgb(64 64 64 / 0.3) !important;
}

.tw-ring-neutral-700\/35 {
  --tw-ring-color: rgb(64 64 64 / 0.35) !important;
}

.tw-ring-neutral-700\/40 {
  --tw-ring-color: rgb(64 64 64 / 0.4) !important;
}

.tw-ring-neutral-700\/45 {
  --tw-ring-color: rgb(64 64 64 / 0.45) !important;
}

.tw-ring-neutral-700\/5 {
  --tw-ring-color: rgb(64 64 64 / 0.05) !important;
}

.tw-ring-neutral-700\/50 {
  --tw-ring-color: rgb(64 64 64 / 0.5) !important;
}

.tw-ring-neutral-700\/55 {
  --tw-ring-color: rgb(64 64 64 / 0.55) !important;
}

.tw-ring-neutral-700\/60 {
  --tw-ring-color: rgb(64 64 64 / 0.6) !important;
}

.tw-ring-neutral-700\/65 {
  --tw-ring-color: rgb(64 64 64 / 0.65) !important;
}

.tw-ring-neutral-700\/70 {
  --tw-ring-color: rgb(64 64 64 / 0.7) !important;
}

.tw-ring-neutral-700\/75 {
  --tw-ring-color: rgb(64 64 64 / 0.75) !important;
}

.tw-ring-neutral-700\/80 {
  --tw-ring-color: rgb(64 64 64 / 0.8) !important;
}

.tw-ring-neutral-700\/85 {
  --tw-ring-color: rgb(64 64 64 / 0.85) !important;
}

.tw-ring-neutral-700\/90 {
  --tw-ring-color: rgb(64 64 64 / 0.9) !important;
}

.tw-ring-neutral-700\/95 {
  --tw-ring-color: rgb(64 64 64 / 0.95) !important;
}

.tw-ring-neutral-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(38 38 38 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-800\/0 {
  --tw-ring-color: rgb(38 38 38 / 0) !important;
}

.tw-ring-neutral-800\/10 {
  --tw-ring-color: rgb(38 38 38 / 0.1) !important;
}

.tw-ring-neutral-800\/100 {
  --tw-ring-color: rgb(38 38 38 / 1) !important;
}

.tw-ring-neutral-800\/15 {
  --tw-ring-color: rgb(38 38 38 / 0.15) !important;
}

.tw-ring-neutral-800\/20 {
  --tw-ring-color: rgb(38 38 38 / 0.2) !important;
}

.tw-ring-neutral-800\/25 {
  --tw-ring-color: rgb(38 38 38 / 0.25) !important;
}

.tw-ring-neutral-800\/30 {
  --tw-ring-color: rgb(38 38 38 / 0.3) !important;
}

.tw-ring-neutral-800\/35 {
  --tw-ring-color: rgb(38 38 38 / 0.35) !important;
}

.tw-ring-neutral-800\/40 {
  --tw-ring-color: rgb(38 38 38 / 0.4) !important;
}

.tw-ring-neutral-800\/45 {
  --tw-ring-color: rgb(38 38 38 / 0.45) !important;
}

.tw-ring-neutral-800\/5 {
  --tw-ring-color: rgb(38 38 38 / 0.05) !important;
}

.tw-ring-neutral-800\/50 {
  --tw-ring-color: rgb(38 38 38 / 0.5) !important;
}

.tw-ring-neutral-800\/55 {
  --tw-ring-color: rgb(38 38 38 / 0.55) !important;
}

.tw-ring-neutral-800\/60 {
  --tw-ring-color: rgb(38 38 38 / 0.6) !important;
}

.tw-ring-neutral-800\/65 {
  --tw-ring-color: rgb(38 38 38 / 0.65) !important;
}

.tw-ring-neutral-800\/70 {
  --tw-ring-color: rgb(38 38 38 / 0.7) !important;
}

.tw-ring-neutral-800\/75 {
  --tw-ring-color: rgb(38 38 38 / 0.75) !important;
}

.tw-ring-neutral-800\/80 {
  --tw-ring-color: rgb(38 38 38 / 0.8) !important;
}

.tw-ring-neutral-800\/85 {
  --tw-ring-color: rgb(38 38 38 / 0.85) !important;
}

.tw-ring-neutral-800\/90 {
  --tw-ring-color: rgb(38 38 38 / 0.9) !important;
}

.tw-ring-neutral-800\/95 {
  --tw-ring-color: rgb(38 38 38 / 0.95) !important;
}

.tw-ring-neutral-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(23 23 23 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-900\/0 {
  --tw-ring-color: rgb(23 23 23 / 0) !important;
}

.tw-ring-neutral-900\/10 {
  --tw-ring-color: rgb(23 23 23 / 0.1) !important;
}

.tw-ring-neutral-900\/100 {
  --tw-ring-color: rgb(23 23 23 / 1) !important;
}

.tw-ring-neutral-900\/15 {
  --tw-ring-color: rgb(23 23 23 / 0.15) !important;
}

.tw-ring-neutral-900\/20 {
  --tw-ring-color: rgb(23 23 23 / 0.2) !important;
}

.tw-ring-neutral-900\/25 {
  --tw-ring-color: rgb(23 23 23 / 0.25) !important;
}

.tw-ring-neutral-900\/30 {
  --tw-ring-color: rgb(23 23 23 / 0.3) !important;
}

.tw-ring-neutral-900\/35 {
  --tw-ring-color: rgb(23 23 23 / 0.35) !important;
}

.tw-ring-neutral-900\/40 {
  --tw-ring-color: rgb(23 23 23 / 0.4) !important;
}

.tw-ring-neutral-900\/45 {
  --tw-ring-color: rgb(23 23 23 / 0.45) !important;
}

.tw-ring-neutral-900\/5 {
  --tw-ring-color: rgb(23 23 23 / 0.05) !important;
}

.tw-ring-neutral-900\/50 {
  --tw-ring-color: rgb(23 23 23 / 0.5) !important;
}

.tw-ring-neutral-900\/55 {
  --tw-ring-color: rgb(23 23 23 / 0.55) !important;
}

.tw-ring-neutral-900\/60 {
  --tw-ring-color: rgb(23 23 23 / 0.6) !important;
}

.tw-ring-neutral-900\/65 {
  --tw-ring-color: rgb(23 23 23 / 0.65) !important;
}

.tw-ring-neutral-900\/70 {
  --tw-ring-color: rgb(23 23 23 / 0.7) !important;
}

.tw-ring-neutral-900\/75 {
  --tw-ring-color: rgb(23 23 23 / 0.75) !important;
}

.tw-ring-neutral-900\/80 {
  --tw-ring-color: rgb(23 23 23 / 0.8) !important;
}

.tw-ring-neutral-900\/85 {
  --tw-ring-color: rgb(23 23 23 / 0.85) !important;
}

.tw-ring-neutral-900\/90 {
  --tw-ring-color: rgb(23 23 23 / 0.9) !important;
}

.tw-ring-neutral-900\/95 {
  --tw-ring-color: rgb(23 23 23 / 0.95) !important;
}

.tw-ring-neutral-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(10 10 10 / var(--tw-ring-opacity)) !important;
}

.tw-ring-neutral-950\/0 {
  --tw-ring-color: rgb(10 10 10 / 0) !important;
}

.tw-ring-neutral-950\/10 {
  --tw-ring-color: rgb(10 10 10 / 0.1) !important;
}

.tw-ring-neutral-950\/100 {
  --tw-ring-color: rgb(10 10 10 / 1) !important;
}

.tw-ring-neutral-950\/15 {
  --tw-ring-color: rgb(10 10 10 / 0.15) !important;
}

.tw-ring-neutral-950\/20 {
  --tw-ring-color: rgb(10 10 10 / 0.2) !important;
}

.tw-ring-neutral-950\/25 {
  --tw-ring-color: rgb(10 10 10 / 0.25) !important;
}

.tw-ring-neutral-950\/30 {
  --tw-ring-color: rgb(10 10 10 / 0.3) !important;
}

.tw-ring-neutral-950\/35 {
  --tw-ring-color: rgb(10 10 10 / 0.35) !important;
}

.tw-ring-neutral-950\/40 {
  --tw-ring-color: rgb(10 10 10 / 0.4) !important;
}

.tw-ring-neutral-950\/45 {
  --tw-ring-color: rgb(10 10 10 / 0.45) !important;
}

.tw-ring-neutral-950\/5 {
  --tw-ring-color: rgb(10 10 10 / 0.05) !important;
}

.tw-ring-neutral-950\/50 {
  --tw-ring-color: rgb(10 10 10 / 0.5) !important;
}

.tw-ring-neutral-950\/55 {
  --tw-ring-color: rgb(10 10 10 / 0.55) !important;
}

.tw-ring-neutral-950\/60 {
  --tw-ring-color: rgb(10 10 10 / 0.6) !important;
}

.tw-ring-neutral-950\/65 {
  --tw-ring-color: rgb(10 10 10 / 0.65) !important;
}

.tw-ring-neutral-950\/70 {
  --tw-ring-color: rgb(10 10 10 / 0.7) !important;
}

.tw-ring-neutral-950\/75 {
  --tw-ring-color: rgb(10 10 10 / 0.75) !important;
}

.tw-ring-neutral-950\/80 {
  --tw-ring-color: rgb(10 10 10 / 0.8) !important;
}

.tw-ring-neutral-950\/85 {
  --tw-ring-color: rgb(10 10 10 / 0.85) !important;
}

.tw-ring-neutral-950\/90 {
  --tw-ring-color: rgb(10 10 10 / 0.9) !important;
}

.tw-ring-neutral-950\/95 {
  --tw-ring-color: rgb(10 10 10 / 0.95) !important;
}

.tw-ring-orange-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(255 237 213 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-100\/0 {
  --tw-ring-color: rgb(255 237 213 / 0) !important;
}

.tw-ring-orange-100\/10 {
  --tw-ring-color: rgb(255 237 213 / 0.1) !important;
}

.tw-ring-orange-100\/100 {
  --tw-ring-color: rgb(255 237 213 / 1) !important;
}

.tw-ring-orange-100\/15 {
  --tw-ring-color: rgb(255 237 213 / 0.15) !important;
}

.tw-ring-orange-100\/20 {
  --tw-ring-color: rgb(255 237 213 / 0.2) !important;
}

.tw-ring-orange-100\/25 {
  --tw-ring-color: rgb(255 237 213 / 0.25) !important;
}

.tw-ring-orange-100\/30 {
  --tw-ring-color: rgb(255 237 213 / 0.3) !important;
}

.tw-ring-orange-100\/35 {
  --tw-ring-color: rgb(255 237 213 / 0.35) !important;
}

.tw-ring-orange-100\/40 {
  --tw-ring-color: rgb(255 237 213 / 0.4) !important;
}

.tw-ring-orange-100\/45 {
  --tw-ring-color: rgb(255 237 213 / 0.45) !important;
}

.tw-ring-orange-100\/5 {
  --tw-ring-color: rgb(255 237 213 / 0.05) !important;
}

.tw-ring-orange-100\/50 {
  --tw-ring-color: rgb(255 237 213 / 0.5) !important;
}

.tw-ring-orange-100\/55 {
  --tw-ring-color: rgb(255 237 213 / 0.55) !important;
}

.tw-ring-orange-100\/60 {
  --tw-ring-color: rgb(255 237 213 / 0.6) !important;
}

.tw-ring-orange-100\/65 {
  --tw-ring-color: rgb(255 237 213 / 0.65) !important;
}

.tw-ring-orange-100\/70 {
  --tw-ring-color: rgb(255 237 213 / 0.7) !important;
}

.tw-ring-orange-100\/75 {
  --tw-ring-color: rgb(255 237 213 / 0.75) !important;
}

.tw-ring-orange-100\/80 {
  --tw-ring-color: rgb(255 237 213 / 0.8) !important;
}

.tw-ring-orange-100\/85 {
  --tw-ring-color: rgb(255 237 213 / 0.85) !important;
}

.tw-ring-orange-100\/90 {
  --tw-ring-color: rgb(255 237 213 / 0.9) !important;
}

.tw-ring-orange-100\/95 {
  --tw-ring-color: rgb(255 237 213 / 0.95) !important;
}

.tw-ring-orange-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 215 170 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-200\/0 {
  --tw-ring-color: rgb(254 215 170 / 0) !important;
}

.tw-ring-orange-200\/10 {
  --tw-ring-color: rgb(254 215 170 / 0.1) !important;
}

.tw-ring-orange-200\/100 {
  --tw-ring-color: rgb(254 215 170 / 1) !important;
}

.tw-ring-orange-200\/15 {
  --tw-ring-color: rgb(254 215 170 / 0.15) !important;
}

.tw-ring-orange-200\/20 {
  --tw-ring-color: rgb(254 215 170 / 0.2) !important;
}

.tw-ring-orange-200\/25 {
  --tw-ring-color: rgb(254 215 170 / 0.25) !important;
}

.tw-ring-orange-200\/30 {
  --tw-ring-color: rgb(254 215 170 / 0.3) !important;
}

.tw-ring-orange-200\/35 {
  --tw-ring-color: rgb(254 215 170 / 0.35) !important;
}

.tw-ring-orange-200\/40 {
  --tw-ring-color: rgb(254 215 170 / 0.4) !important;
}

.tw-ring-orange-200\/45 {
  --tw-ring-color: rgb(254 215 170 / 0.45) !important;
}

.tw-ring-orange-200\/5 {
  --tw-ring-color: rgb(254 215 170 / 0.05) !important;
}

.tw-ring-orange-200\/50 {
  --tw-ring-color: rgb(254 215 170 / 0.5) !important;
}

.tw-ring-orange-200\/55 {
  --tw-ring-color: rgb(254 215 170 / 0.55) !important;
}

.tw-ring-orange-200\/60 {
  --tw-ring-color: rgb(254 215 170 / 0.6) !important;
}

.tw-ring-orange-200\/65 {
  --tw-ring-color: rgb(254 215 170 / 0.65) !important;
}

.tw-ring-orange-200\/70 {
  --tw-ring-color: rgb(254 215 170 / 0.7) !important;
}

.tw-ring-orange-200\/75 {
  --tw-ring-color: rgb(254 215 170 / 0.75) !important;
}

.tw-ring-orange-200\/80 {
  --tw-ring-color: rgb(254 215 170 / 0.8) !important;
}

.tw-ring-orange-200\/85 {
  --tw-ring-color: rgb(254 215 170 / 0.85) !important;
}

.tw-ring-orange-200\/90 {
  --tw-ring-color: rgb(254 215 170 / 0.9) !important;
}

.tw-ring-orange-200\/95 {
  --tw-ring-color: rgb(254 215 170 / 0.95) !important;
}

.tw-ring-orange-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(253 186 116 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-300\/0 {
  --tw-ring-color: rgb(253 186 116 / 0) !important;
}

.tw-ring-orange-300\/10 {
  --tw-ring-color: rgb(253 186 116 / 0.1) !important;
}

.tw-ring-orange-300\/100 {
  --tw-ring-color: rgb(253 186 116 / 1) !important;
}

.tw-ring-orange-300\/15 {
  --tw-ring-color: rgb(253 186 116 / 0.15) !important;
}

.tw-ring-orange-300\/20 {
  --tw-ring-color: rgb(253 186 116 / 0.2) !important;
}

.tw-ring-orange-300\/25 {
  --tw-ring-color: rgb(253 186 116 / 0.25) !important;
}

.tw-ring-orange-300\/30 {
  --tw-ring-color: rgb(253 186 116 / 0.3) !important;
}

.tw-ring-orange-300\/35 {
  --tw-ring-color: rgb(253 186 116 / 0.35) !important;
}

.tw-ring-orange-300\/40 {
  --tw-ring-color: rgb(253 186 116 / 0.4) !important;
}

.tw-ring-orange-300\/45 {
  --tw-ring-color: rgb(253 186 116 / 0.45) !important;
}

.tw-ring-orange-300\/5 {
  --tw-ring-color: rgb(253 186 116 / 0.05) !important;
}

.tw-ring-orange-300\/50 {
  --tw-ring-color: rgb(253 186 116 / 0.5) !important;
}

.tw-ring-orange-300\/55 {
  --tw-ring-color: rgb(253 186 116 / 0.55) !important;
}

.tw-ring-orange-300\/60 {
  --tw-ring-color: rgb(253 186 116 / 0.6) !important;
}

.tw-ring-orange-300\/65 {
  --tw-ring-color: rgb(253 186 116 / 0.65) !important;
}

.tw-ring-orange-300\/70 {
  --tw-ring-color: rgb(253 186 116 / 0.7) !important;
}

.tw-ring-orange-300\/75 {
  --tw-ring-color: rgb(253 186 116 / 0.75) !important;
}

.tw-ring-orange-300\/80 {
  --tw-ring-color: rgb(253 186 116 / 0.8) !important;
}

.tw-ring-orange-300\/85 {
  --tw-ring-color: rgb(253 186 116 / 0.85) !important;
}

.tw-ring-orange-300\/90 {
  --tw-ring-color: rgb(253 186 116 / 0.9) !important;
}

.tw-ring-orange-300\/95 {
  --tw-ring-color: rgb(253 186 116 / 0.95) !important;
}

.tw-ring-orange-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(251 146 60 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-400\/0 {
  --tw-ring-color: rgb(251 146 60 / 0) !important;
}

.tw-ring-orange-400\/10 {
  --tw-ring-color: rgb(251 146 60 / 0.1) !important;
}

.tw-ring-orange-400\/100 {
  --tw-ring-color: rgb(251 146 60 / 1) !important;
}

.tw-ring-orange-400\/15 {
  --tw-ring-color: rgb(251 146 60 / 0.15) !important;
}

.tw-ring-orange-400\/20 {
  --tw-ring-color: rgb(251 146 60 / 0.2) !important;
}

.tw-ring-orange-400\/25 {
  --tw-ring-color: rgb(251 146 60 / 0.25) !important;
}

.tw-ring-orange-400\/30 {
  --tw-ring-color: rgb(251 146 60 / 0.3) !important;
}

.tw-ring-orange-400\/35 {
  --tw-ring-color: rgb(251 146 60 / 0.35) !important;
}

.tw-ring-orange-400\/40 {
  --tw-ring-color: rgb(251 146 60 / 0.4) !important;
}

.tw-ring-orange-400\/45 {
  --tw-ring-color: rgb(251 146 60 / 0.45) !important;
}

.tw-ring-orange-400\/5 {
  --tw-ring-color: rgb(251 146 60 / 0.05) !important;
}

.tw-ring-orange-400\/50 {
  --tw-ring-color: rgb(251 146 60 / 0.5) !important;
}

.tw-ring-orange-400\/55 {
  --tw-ring-color: rgb(251 146 60 / 0.55) !important;
}

.tw-ring-orange-400\/60 {
  --tw-ring-color: rgb(251 146 60 / 0.6) !important;
}

.tw-ring-orange-400\/65 {
  --tw-ring-color: rgb(251 146 60 / 0.65) !important;
}

.tw-ring-orange-400\/70 {
  --tw-ring-color: rgb(251 146 60 / 0.7) !important;
}

.tw-ring-orange-400\/75 {
  --tw-ring-color: rgb(251 146 60 / 0.75) !important;
}

.tw-ring-orange-400\/80 {
  --tw-ring-color: rgb(251 146 60 / 0.8) !important;
}

.tw-ring-orange-400\/85 {
  --tw-ring-color: rgb(251 146 60 / 0.85) !important;
}

.tw-ring-orange-400\/90 {
  --tw-ring-color: rgb(251 146 60 / 0.9) !important;
}

.tw-ring-orange-400\/95 {
  --tw-ring-color: rgb(251 146 60 / 0.95) !important;
}

.tw-ring-orange-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(255 247 237 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-50\/0 {
  --tw-ring-color: rgb(255 247 237 / 0) !important;
}

.tw-ring-orange-50\/10 {
  --tw-ring-color: rgb(255 247 237 / 0.1) !important;
}

.tw-ring-orange-50\/100 {
  --tw-ring-color: rgb(255 247 237 / 1) !important;
}

.tw-ring-orange-50\/15 {
  --tw-ring-color: rgb(255 247 237 / 0.15) !important;
}

.tw-ring-orange-50\/20 {
  --tw-ring-color: rgb(255 247 237 / 0.2) !important;
}

.tw-ring-orange-50\/25 {
  --tw-ring-color: rgb(255 247 237 / 0.25) !important;
}

.tw-ring-orange-50\/30 {
  --tw-ring-color: rgb(255 247 237 / 0.3) !important;
}

.tw-ring-orange-50\/35 {
  --tw-ring-color: rgb(255 247 237 / 0.35) !important;
}

.tw-ring-orange-50\/40 {
  --tw-ring-color: rgb(255 247 237 / 0.4) !important;
}

.tw-ring-orange-50\/45 {
  --tw-ring-color: rgb(255 247 237 / 0.45) !important;
}

.tw-ring-orange-50\/5 {
  --tw-ring-color: rgb(255 247 237 / 0.05) !important;
}

.tw-ring-orange-50\/50 {
  --tw-ring-color: rgb(255 247 237 / 0.5) !important;
}

.tw-ring-orange-50\/55 {
  --tw-ring-color: rgb(255 247 237 / 0.55) !important;
}

.tw-ring-orange-50\/60 {
  --tw-ring-color: rgb(255 247 237 / 0.6) !important;
}

.tw-ring-orange-50\/65 {
  --tw-ring-color: rgb(255 247 237 / 0.65) !important;
}

.tw-ring-orange-50\/70 {
  --tw-ring-color: rgb(255 247 237 / 0.7) !important;
}

.tw-ring-orange-50\/75 {
  --tw-ring-color: rgb(255 247 237 / 0.75) !important;
}

.tw-ring-orange-50\/80 {
  --tw-ring-color: rgb(255 247 237 / 0.8) !important;
}

.tw-ring-orange-50\/85 {
  --tw-ring-color: rgb(255 247 237 / 0.85) !important;
}

.tw-ring-orange-50\/90 {
  --tw-ring-color: rgb(255 247 237 / 0.9) !important;
}

.tw-ring-orange-50\/95 {
  --tw-ring-color: rgb(255 247 237 / 0.95) !important;
}

.tw-ring-orange-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(249 115 22 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-500\/0 {
  --tw-ring-color: rgb(249 115 22 / 0) !important;
}

.tw-ring-orange-500\/10 {
  --tw-ring-color: rgb(249 115 22 / 0.1) !important;
}

.tw-ring-orange-500\/100 {
  --tw-ring-color: rgb(249 115 22 / 1) !important;
}

.tw-ring-orange-500\/15 {
  --tw-ring-color: rgb(249 115 22 / 0.15) !important;
}

.tw-ring-orange-500\/20 {
  --tw-ring-color: rgb(249 115 22 / 0.2) !important;
}

.tw-ring-orange-500\/25 {
  --tw-ring-color: rgb(249 115 22 / 0.25) !important;
}

.tw-ring-orange-500\/30 {
  --tw-ring-color: rgb(249 115 22 / 0.3) !important;
}

.tw-ring-orange-500\/35 {
  --tw-ring-color: rgb(249 115 22 / 0.35) !important;
}

.tw-ring-orange-500\/40 {
  --tw-ring-color: rgb(249 115 22 / 0.4) !important;
}

.tw-ring-orange-500\/45 {
  --tw-ring-color: rgb(249 115 22 / 0.45) !important;
}

.tw-ring-orange-500\/5 {
  --tw-ring-color: rgb(249 115 22 / 0.05) !important;
}

.tw-ring-orange-500\/50 {
  --tw-ring-color: rgb(249 115 22 / 0.5) !important;
}

.tw-ring-orange-500\/55 {
  --tw-ring-color: rgb(249 115 22 / 0.55) !important;
}

.tw-ring-orange-500\/60 {
  --tw-ring-color: rgb(249 115 22 / 0.6) !important;
}

.tw-ring-orange-500\/65 {
  --tw-ring-color: rgb(249 115 22 / 0.65) !important;
}

.tw-ring-orange-500\/70 {
  --tw-ring-color: rgb(249 115 22 / 0.7) !important;
}

.tw-ring-orange-500\/75 {
  --tw-ring-color: rgb(249 115 22 / 0.75) !important;
}

.tw-ring-orange-500\/80 {
  --tw-ring-color: rgb(249 115 22 / 0.8) !important;
}

.tw-ring-orange-500\/85 {
  --tw-ring-color: rgb(249 115 22 / 0.85) !important;
}

.tw-ring-orange-500\/90 {
  --tw-ring-color: rgb(249 115 22 / 0.9) !important;
}

.tw-ring-orange-500\/95 {
  --tw-ring-color: rgb(249 115 22 / 0.95) !important;
}

.tw-ring-orange-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(234 88 12 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-600\/0 {
  --tw-ring-color: rgb(234 88 12 / 0) !important;
}

.tw-ring-orange-600\/10 {
  --tw-ring-color: rgb(234 88 12 / 0.1) !important;
}

.tw-ring-orange-600\/100 {
  --tw-ring-color: rgb(234 88 12 / 1) !important;
}

.tw-ring-orange-600\/15 {
  --tw-ring-color: rgb(234 88 12 / 0.15) !important;
}

.tw-ring-orange-600\/20 {
  --tw-ring-color: rgb(234 88 12 / 0.2) !important;
}

.tw-ring-orange-600\/25 {
  --tw-ring-color: rgb(234 88 12 / 0.25) !important;
}

.tw-ring-orange-600\/30 {
  --tw-ring-color: rgb(234 88 12 / 0.3) !important;
}

.tw-ring-orange-600\/35 {
  --tw-ring-color: rgb(234 88 12 / 0.35) !important;
}

.tw-ring-orange-600\/40 {
  --tw-ring-color: rgb(234 88 12 / 0.4) !important;
}

.tw-ring-orange-600\/45 {
  --tw-ring-color: rgb(234 88 12 / 0.45) !important;
}

.tw-ring-orange-600\/5 {
  --tw-ring-color: rgb(234 88 12 / 0.05) !important;
}

.tw-ring-orange-600\/50 {
  --tw-ring-color: rgb(234 88 12 / 0.5) !important;
}

.tw-ring-orange-600\/55 {
  --tw-ring-color: rgb(234 88 12 / 0.55) !important;
}

.tw-ring-orange-600\/60 {
  --tw-ring-color: rgb(234 88 12 / 0.6) !important;
}

.tw-ring-orange-600\/65 {
  --tw-ring-color: rgb(234 88 12 / 0.65) !important;
}

.tw-ring-orange-600\/70 {
  --tw-ring-color: rgb(234 88 12 / 0.7) !important;
}

.tw-ring-orange-600\/75 {
  --tw-ring-color: rgb(234 88 12 / 0.75) !important;
}

.tw-ring-orange-600\/80 {
  --tw-ring-color: rgb(234 88 12 / 0.8) !important;
}

.tw-ring-orange-600\/85 {
  --tw-ring-color: rgb(234 88 12 / 0.85) !important;
}

.tw-ring-orange-600\/90 {
  --tw-ring-color: rgb(234 88 12 / 0.9) !important;
}

.tw-ring-orange-600\/95 {
  --tw-ring-color: rgb(234 88 12 / 0.95) !important;
}

.tw-ring-orange-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(194 65 12 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-700\/0 {
  --tw-ring-color: rgb(194 65 12 / 0) !important;
}

.tw-ring-orange-700\/10 {
  --tw-ring-color: rgb(194 65 12 / 0.1) !important;
}

.tw-ring-orange-700\/100 {
  --tw-ring-color: rgb(194 65 12 / 1) !important;
}

.tw-ring-orange-700\/15 {
  --tw-ring-color: rgb(194 65 12 / 0.15) !important;
}

.tw-ring-orange-700\/20 {
  --tw-ring-color: rgb(194 65 12 / 0.2) !important;
}

.tw-ring-orange-700\/25 {
  --tw-ring-color: rgb(194 65 12 / 0.25) !important;
}

.tw-ring-orange-700\/30 {
  --tw-ring-color: rgb(194 65 12 / 0.3) !important;
}

.tw-ring-orange-700\/35 {
  --tw-ring-color: rgb(194 65 12 / 0.35) !important;
}

.tw-ring-orange-700\/40 {
  --tw-ring-color: rgb(194 65 12 / 0.4) !important;
}

.tw-ring-orange-700\/45 {
  --tw-ring-color: rgb(194 65 12 / 0.45) !important;
}

.tw-ring-orange-700\/5 {
  --tw-ring-color: rgb(194 65 12 / 0.05) !important;
}

.tw-ring-orange-700\/50 {
  --tw-ring-color: rgb(194 65 12 / 0.5) !important;
}

.tw-ring-orange-700\/55 {
  --tw-ring-color: rgb(194 65 12 / 0.55) !important;
}

.tw-ring-orange-700\/60 {
  --tw-ring-color: rgb(194 65 12 / 0.6) !important;
}

.tw-ring-orange-700\/65 {
  --tw-ring-color: rgb(194 65 12 / 0.65) !important;
}

.tw-ring-orange-700\/70 {
  --tw-ring-color: rgb(194 65 12 / 0.7) !important;
}

.tw-ring-orange-700\/75 {
  --tw-ring-color: rgb(194 65 12 / 0.75) !important;
}

.tw-ring-orange-700\/80 {
  --tw-ring-color: rgb(194 65 12 / 0.8) !important;
}

.tw-ring-orange-700\/85 {
  --tw-ring-color: rgb(194 65 12 / 0.85) !important;
}

.tw-ring-orange-700\/90 {
  --tw-ring-color: rgb(194 65 12 / 0.9) !important;
}

.tw-ring-orange-700\/95 {
  --tw-ring-color: rgb(194 65 12 / 0.95) !important;
}

.tw-ring-orange-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(154 52 18 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-800\/0 {
  --tw-ring-color: rgb(154 52 18 / 0) !important;
}

.tw-ring-orange-800\/10 {
  --tw-ring-color: rgb(154 52 18 / 0.1) !important;
}

.tw-ring-orange-800\/100 {
  --tw-ring-color: rgb(154 52 18 / 1) !important;
}

.tw-ring-orange-800\/15 {
  --tw-ring-color: rgb(154 52 18 / 0.15) !important;
}

.tw-ring-orange-800\/20 {
  --tw-ring-color: rgb(154 52 18 / 0.2) !important;
}

.tw-ring-orange-800\/25 {
  --tw-ring-color: rgb(154 52 18 / 0.25) !important;
}

.tw-ring-orange-800\/30 {
  --tw-ring-color: rgb(154 52 18 / 0.3) !important;
}

.tw-ring-orange-800\/35 {
  --tw-ring-color: rgb(154 52 18 / 0.35) !important;
}

.tw-ring-orange-800\/40 {
  --tw-ring-color: rgb(154 52 18 / 0.4) !important;
}

.tw-ring-orange-800\/45 {
  --tw-ring-color: rgb(154 52 18 / 0.45) !important;
}

.tw-ring-orange-800\/5 {
  --tw-ring-color: rgb(154 52 18 / 0.05) !important;
}

.tw-ring-orange-800\/50 {
  --tw-ring-color: rgb(154 52 18 / 0.5) !important;
}

.tw-ring-orange-800\/55 {
  --tw-ring-color: rgb(154 52 18 / 0.55) !important;
}

.tw-ring-orange-800\/60 {
  --tw-ring-color: rgb(154 52 18 / 0.6) !important;
}

.tw-ring-orange-800\/65 {
  --tw-ring-color: rgb(154 52 18 / 0.65) !important;
}

.tw-ring-orange-800\/70 {
  --tw-ring-color: rgb(154 52 18 / 0.7) !important;
}

.tw-ring-orange-800\/75 {
  --tw-ring-color: rgb(154 52 18 / 0.75) !important;
}

.tw-ring-orange-800\/80 {
  --tw-ring-color: rgb(154 52 18 / 0.8) !important;
}

.tw-ring-orange-800\/85 {
  --tw-ring-color: rgb(154 52 18 / 0.85) !important;
}

.tw-ring-orange-800\/90 {
  --tw-ring-color: rgb(154 52 18 / 0.9) !important;
}

.tw-ring-orange-800\/95 {
  --tw-ring-color: rgb(154 52 18 / 0.95) !important;
}

.tw-ring-orange-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(124 45 18 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-900\/0 {
  --tw-ring-color: rgb(124 45 18 / 0) !important;
}

.tw-ring-orange-900\/10 {
  --tw-ring-color: rgb(124 45 18 / 0.1) !important;
}

.tw-ring-orange-900\/100 {
  --tw-ring-color: rgb(124 45 18 / 1) !important;
}

.tw-ring-orange-900\/15 {
  --tw-ring-color: rgb(124 45 18 / 0.15) !important;
}

.tw-ring-orange-900\/20 {
  --tw-ring-color: rgb(124 45 18 / 0.2) !important;
}

.tw-ring-orange-900\/25 {
  --tw-ring-color: rgb(124 45 18 / 0.25) !important;
}

.tw-ring-orange-900\/30 {
  --tw-ring-color: rgb(124 45 18 / 0.3) !important;
}

.tw-ring-orange-900\/35 {
  --tw-ring-color: rgb(124 45 18 / 0.35) !important;
}

.tw-ring-orange-900\/40 {
  --tw-ring-color: rgb(124 45 18 / 0.4) !important;
}

.tw-ring-orange-900\/45 {
  --tw-ring-color: rgb(124 45 18 / 0.45) !important;
}

.tw-ring-orange-900\/5 {
  --tw-ring-color: rgb(124 45 18 / 0.05) !important;
}

.tw-ring-orange-900\/50 {
  --tw-ring-color: rgb(124 45 18 / 0.5) !important;
}

.tw-ring-orange-900\/55 {
  --tw-ring-color: rgb(124 45 18 / 0.55) !important;
}

.tw-ring-orange-900\/60 {
  --tw-ring-color: rgb(124 45 18 / 0.6) !important;
}

.tw-ring-orange-900\/65 {
  --tw-ring-color: rgb(124 45 18 / 0.65) !important;
}

.tw-ring-orange-900\/70 {
  --tw-ring-color: rgb(124 45 18 / 0.7) !important;
}

.tw-ring-orange-900\/75 {
  --tw-ring-color: rgb(124 45 18 / 0.75) !important;
}

.tw-ring-orange-900\/80 {
  --tw-ring-color: rgb(124 45 18 / 0.8) !important;
}

.tw-ring-orange-900\/85 {
  --tw-ring-color: rgb(124 45 18 / 0.85) !important;
}

.tw-ring-orange-900\/90 {
  --tw-ring-color: rgb(124 45 18 / 0.9) !important;
}

.tw-ring-orange-900\/95 {
  --tw-ring-color: rgb(124 45 18 / 0.95) !important;
}

.tw-ring-orange-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(67 20 7 / var(--tw-ring-opacity)) !important;
}

.tw-ring-orange-950\/0 {
  --tw-ring-color: rgb(67 20 7 / 0) !important;
}

.tw-ring-orange-950\/10 {
  --tw-ring-color: rgb(67 20 7 / 0.1) !important;
}

.tw-ring-orange-950\/100 {
  --tw-ring-color: rgb(67 20 7 / 1) !important;
}

.tw-ring-orange-950\/15 {
  --tw-ring-color: rgb(67 20 7 / 0.15) !important;
}

.tw-ring-orange-950\/20 {
  --tw-ring-color: rgb(67 20 7 / 0.2) !important;
}

.tw-ring-orange-950\/25 {
  --tw-ring-color: rgb(67 20 7 / 0.25) !important;
}

.tw-ring-orange-950\/30 {
  --tw-ring-color: rgb(67 20 7 / 0.3) !important;
}

.tw-ring-orange-950\/35 {
  --tw-ring-color: rgb(67 20 7 / 0.35) !important;
}

.tw-ring-orange-950\/40 {
  --tw-ring-color: rgb(67 20 7 / 0.4) !important;
}

.tw-ring-orange-950\/45 {
  --tw-ring-color: rgb(67 20 7 / 0.45) !important;
}

.tw-ring-orange-950\/5 {
  --tw-ring-color: rgb(67 20 7 / 0.05) !important;
}

.tw-ring-orange-950\/50 {
  --tw-ring-color: rgb(67 20 7 / 0.5) !important;
}

.tw-ring-orange-950\/55 {
  --tw-ring-color: rgb(67 20 7 / 0.55) !important;
}

.tw-ring-orange-950\/60 {
  --tw-ring-color: rgb(67 20 7 / 0.6) !important;
}

.tw-ring-orange-950\/65 {
  --tw-ring-color: rgb(67 20 7 / 0.65) !important;
}

.tw-ring-orange-950\/70 {
  --tw-ring-color: rgb(67 20 7 / 0.7) !important;
}

.tw-ring-orange-950\/75 {
  --tw-ring-color: rgb(67 20 7 / 0.75) !important;
}

.tw-ring-orange-950\/80 {
  --tw-ring-color: rgb(67 20 7 / 0.8) !important;
}

.tw-ring-orange-950\/85 {
  --tw-ring-color: rgb(67 20 7 / 0.85) !important;
}

.tw-ring-orange-950\/90 {
  --tw-ring-color: rgb(67 20 7 / 0.9) !important;
}

.tw-ring-orange-950\/95 {
  --tw-ring-color: rgb(67 20 7 / 0.95) !important;
}

.tw-ring-pink-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(252 231 243 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-100\/0 {
  --tw-ring-color: rgb(252 231 243 / 0) !important;
}

.tw-ring-pink-100\/10 {
  --tw-ring-color: rgb(252 231 243 / 0.1) !important;
}

.tw-ring-pink-100\/100 {
  --tw-ring-color: rgb(252 231 243 / 1) !important;
}

.tw-ring-pink-100\/15 {
  --tw-ring-color: rgb(252 231 243 / 0.15) !important;
}

.tw-ring-pink-100\/20 {
  --tw-ring-color: rgb(252 231 243 / 0.2) !important;
}

.tw-ring-pink-100\/25 {
  --tw-ring-color: rgb(252 231 243 / 0.25) !important;
}

.tw-ring-pink-100\/30 {
  --tw-ring-color: rgb(252 231 243 / 0.3) !important;
}

.tw-ring-pink-100\/35 {
  --tw-ring-color: rgb(252 231 243 / 0.35) !important;
}

.tw-ring-pink-100\/40 {
  --tw-ring-color: rgb(252 231 243 / 0.4) !important;
}

.tw-ring-pink-100\/45 {
  --tw-ring-color: rgb(252 231 243 / 0.45) !important;
}

.tw-ring-pink-100\/5 {
  --tw-ring-color: rgb(252 231 243 / 0.05) !important;
}

.tw-ring-pink-100\/50 {
  --tw-ring-color: rgb(252 231 243 / 0.5) !important;
}

.tw-ring-pink-100\/55 {
  --tw-ring-color: rgb(252 231 243 / 0.55) !important;
}

.tw-ring-pink-100\/60 {
  --tw-ring-color: rgb(252 231 243 / 0.6) !important;
}

.tw-ring-pink-100\/65 {
  --tw-ring-color: rgb(252 231 243 / 0.65) !important;
}

.tw-ring-pink-100\/70 {
  --tw-ring-color: rgb(252 231 243 / 0.7) !important;
}

.tw-ring-pink-100\/75 {
  --tw-ring-color: rgb(252 231 243 / 0.75) !important;
}

.tw-ring-pink-100\/80 {
  --tw-ring-color: rgb(252 231 243 / 0.8) !important;
}

.tw-ring-pink-100\/85 {
  --tw-ring-color: rgb(252 231 243 / 0.85) !important;
}

.tw-ring-pink-100\/90 {
  --tw-ring-color: rgb(252 231 243 / 0.9) !important;
}

.tw-ring-pink-100\/95 {
  --tw-ring-color: rgb(252 231 243 / 0.95) !important;
}

.tw-ring-pink-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(251 207 232 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-200\/0 {
  --tw-ring-color: rgb(251 207 232 / 0) !important;
}

.tw-ring-pink-200\/10 {
  --tw-ring-color: rgb(251 207 232 / 0.1) !important;
}

.tw-ring-pink-200\/100 {
  --tw-ring-color: rgb(251 207 232 / 1) !important;
}

.tw-ring-pink-200\/15 {
  --tw-ring-color: rgb(251 207 232 / 0.15) !important;
}

.tw-ring-pink-200\/20 {
  --tw-ring-color: rgb(251 207 232 / 0.2) !important;
}

.tw-ring-pink-200\/25 {
  --tw-ring-color: rgb(251 207 232 / 0.25) !important;
}

.tw-ring-pink-200\/30 {
  --tw-ring-color: rgb(251 207 232 / 0.3) !important;
}

.tw-ring-pink-200\/35 {
  --tw-ring-color: rgb(251 207 232 / 0.35) !important;
}

.tw-ring-pink-200\/40 {
  --tw-ring-color: rgb(251 207 232 / 0.4) !important;
}

.tw-ring-pink-200\/45 {
  --tw-ring-color: rgb(251 207 232 / 0.45) !important;
}

.tw-ring-pink-200\/5 {
  --tw-ring-color: rgb(251 207 232 / 0.05) !important;
}

.tw-ring-pink-200\/50 {
  --tw-ring-color: rgb(251 207 232 / 0.5) !important;
}

.tw-ring-pink-200\/55 {
  --tw-ring-color: rgb(251 207 232 / 0.55) !important;
}

.tw-ring-pink-200\/60 {
  --tw-ring-color: rgb(251 207 232 / 0.6) !important;
}

.tw-ring-pink-200\/65 {
  --tw-ring-color: rgb(251 207 232 / 0.65) !important;
}

.tw-ring-pink-200\/70 {
  --tw-ring-color: rgb(251 207 232 / 0.7) !important;
}

.tw-ring-pink-200\/75 {
  --tw-ring-color: rgb(251 207 232 / 0.75) !important;
}

.tw-ring-pink-200\/80 {
  --tw-ring-color: rgb(251 207 232 / 0.8) !important;
}

.tw-ring-pink-200\/85 {
  --tw-ring-color: rgb(251 207 232 / 0.85) !important;
}

.tw-ring-pink-200\/90 {
  --tw-ring-color: rgb(251 207 232 / 0.9) !important;
}

.tw-ring-pink-200\/95 {
  --tw-ring-color: rgb(251 207 232 / 0.95) !important;
}

.tw-ring-pink-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(249 168 212 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-300\/0 {
  --tw-ring-color: rgb(249 168 212 / 0) !important;
}

.tw-ring-pink-300\/10 {
  --tw-ring-color: rgb(249 168 212 / 0.1) !important;
}

.tw-ring-pink-300\/100 {
  --tw-ring-color: rgb(249 168 212 / 1) !important;
}

.tw-ring-pink-300\/15 {
  --tw-ring-color: rgb(249 168 212 / 0.15) !important;
}

.tw-ring-pink-300\/20 {
  --tw-ring-color: rgb(249 168 212 / 0.2) !important;
}

.tw-ring-pink-300\/25 {
  --tw-ring-color: rgb(249 168 212 / 0.25) !important;
}

.tw-ring-pink-300\/30 {
  --tw-ring-color: rgb(249 168 212 / 0.3) !important;
}

.tw-ring-pink-300\/35 {
  --tw-ring-color: rgb(249 168 212 / 0.35) !important;
}

.tw-ring-pink-300\/40 {
  --tw-ring-color: rgb(249 168 212 / 0.4) !important;
}

.tw-ring-pink-300\/45 {
  --tw-ring-color: rgb(249 168 212 / 0.45) !important;
}

.tw-ring-pink-300\/5 {
  --tw-ring-color: rgb(249 168 212 / 0.05) !important;
}

.tw-ring-pink-300\/50 {
  --tw-ring-color: rgb(249 168 212 / 0.5) !important;
}

.tw-ring-pink-300\/55 {
  --tw-ring-color: rgb(249 168 212 / 0.55) !important;
}

.tw-ring-pink-300\/60 {
  --tw-ring-color: rgb(249 168 212 / 0.6) !important;
}

.tw-ring-pink-300\/65 {
  --tw-ring-color: rgb(249 168 212 / 0.65) !important;
}

.tw-ring-pink-300\/70 {
  --tw-ring-color: rgb(249 168 212 / 0.7) !important;
}

.tw-ring-pink-300\/75 {
  --tw-ring-color: rgb(249 168 212 / 0.75) !important;
}

.tw-ring-pink-300\/80 {
  --tw-ring-color: rgb(249 168 212 / 0.8) !important;
}

.tw-ring-pink-300\/85 {
  --tw-ring-color: rgb(249 168 212 / 0.85) !important;
}

.tw-ring-pink-300\/90 {
  --tw-ring-color: rgb(249 168 212 / 0.9) !important;
}

.tw-ring-pink-300\/95 {
  --tw-ring-color: rgb(249 168 212 / 0.95) !important;
}

.tw-ring-pink-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(244 114 182 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-400\/0 {
  --tw-ring-color: rgb(244 114 182 / 0) !important;
}

.tw-ring-pink-400\/10 {
  --tw-ring-color: rgb(244 114 182 / 0.1) !important;
}

.tw-ring-pink-400\/100 {
  --tw-ring-color: rgb(244 114 182 / 1) !important;
}

.tw-ring-pink-400\/15 {
  --tw-ring-color: rgb(244 114 182 / 0.15) !important;
}

.tw-ring-pink-400\/20 {
  --tw-ring-color: rgb(244 114 182 / 0.2) !important;
}

.tw-ring-pink-400\/25 {
  --tw-ring-color: rgb(244 114 182 / 0.25) !important;
}

.tw-ring-pink-400\/30 {
  --tw-ring-color: rgb(244 114 182 / 0.3) !important;
}

.tw-ring-pink-400\/35 {
  --tw-ring-color: rgb(244 114 182 / 0.35) !important;
}

.tw-ring-pink-400\/40 {
  --tw-ring-color: rgb(244 114 182 / 0.4) !important;
}

.tw-ring-pink-400\/45 {
  --tw-ring-color: rgb(244 114 182 / 0.45) !important;
}

.tw-ring-pink-400\/5 {
  --tw-ring-color: rgb(244 114 182 / 0.05) !important;
}

.tw-ring-pink-400\/50 {
  --tw-ring-color: rgb(244 114 182 / 0.5) !important;
}

.tw-ring-pink-400\/55 {
  --tw-ring-color: rgb(244 114 182 / 0.55) !important;
}

.tw-ring-pink-400\/60 {
  --tw-ring-color: rgb(244 114 182 / 0.6) !important;
}

.tw-ring-pink-400\/65 {
  --tw-ring-color: rgb(244 114 182 / 0.65) !important;
}

.tw-ring-pink-400\/70 {
  --tw-ring-color: rgb(244 114 182 / 0.7) !important;
}

.tw-ring-pink-400\/75 {
  --tw-ring-color: rgb(244 114 182 / 0.75) !important;
}

.tw-ring-pink-400\/80 {
  --tw-ring-color: rgb(244 114 182 / 0.8) !important;
}

.tw-ring-pink-400\/85 {
  --tw-ring-color: rgb(244 114 182 / 0.85) !important;
}

.tw-ring-pink-400\/90 {
  --tw-ring-color: rgb(244 114 182 / 0.9) !important;
}

.tw-ring-pink-400\/95 {
  --tw-ring-color: rgb(244 114 182 / 0.95) !important;
}

.tw-ring-pink-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(253 242 248 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-50\/0 {
  --tw-ring-color: rgb(253 242 248 / 0) !important;
}

.tw-ring-pink-50\/10 {
  --tw-ring-color: rgb(253 242 248 / 0.1) !important;
}

.tw-ring-pink-50\/100 {
  --tw-ring-color: rgb(253 242 248 / 1) !important;
}

.tw-ring-pink-50\/15 {
  --tw-ring-color: rgb(253 242 248 / 0.15) !important;
}

.tw-ring-pink-50\/20 {
  --tw-ring-color: rgb(253 242 248 / 0.2) !important;
}

.tw-ring-pink-50\/25 {
  --tw-ring-color: rgb(253 242 248 / 0.25) !important;
}

.tw-ring-pink-50\/30 {
  --tw-ring-color: rgb(253 242 248 / 0.3) !important;
}

.tw-ring-pink-50\/35 {
  --tw-ring-color: rgb(253 242 248 / 0.35) !important;
}

.tw-ring-pink-50\/40 {
  --tw-ring-color: rgb(253 242 248 / 0.4) !important;
}

.tw-ring-pink-50\/45 {
  --tw-ring-color: rgb(253 242 248 / 0.45) !important;
}

.tw-ring-pink-50\/5 {
  --tw-ring-color: rgb(253 242 248 / 0.05) !important;
}

.tw-ring-pink-50\/50 {
  --tw-ring-color: rgb(253 242 248 / 0.5) !important;
}

.tw-ring-pink-50\/55 {
  --tw-ring-color: rgb(253 242 248 / 0.55) !important;
}

.tw-ring-pink-50\/60 {
  --tw-ring-color: rgb(253 242 248 / 0.6) !important;
}

.tw-ring-pink-50\/65 {
  --tw-ring-color: rgb(253 242 248 / 0.65) !important;
}

.tw-ring-pink-50\/70 {
  --tw-ring-color: rgb(253 242 248 / 0.7) !important;
}

.tw-ring-pink-50\/75 {
  --tw-ring-color: rgb(253 242 248 / 0.75) !important;
}

.tw-ring-pink-50\/80 {
  --tw-ring-color: rgb(253 242 248 / 0.8) !important;
}

.tw-ring-pink-50\/85 {
  --tw-ring-color: rgb(253 242 248 / 0.85) !important;
}

.tw-ring-pink-50\/90 {
  --tw-ring-color: rgb(253 242 248 / 0.9) !important;
}

.tw-ring-pink-50\/95 {
  --tw-ring-color: rgb(253 242 248 / 0.95) !important;
}

.tw-ring-pink-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(236 72 153 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-500\/0 {
  --tw-ring-color: rgb(236 72 153 / 0) !important;
}

.tw-ring-pink-500\/10 {
  --tw-ring-color: rgb(236 72 153 / 0.1) !important;
}

.tw-ring-pink-500\/100 {
  --tw-ring-color: rgb(236 72 153 / 1) !important;
}

.tw-ring-pink-500\/15 {
  --tw-ring-color: rgb(236 72 153 / 0.15) !important;
}

.tw-ring-pink-500\/20 {
  --tw-ring-color: rgb(236 72 153 / 0.2) !important;
}

.tw-ring-pink-500\/25 {
  --tw-ring-color: rgb(236 72 153 / 0.25) !important;
}

.tw-ring-pink-500\/30 {
  --tw-ring-color: rgb(236 72 153 / 0.3) !important;
}

.tw-ring-pink-500\/35 {
  --tw-ring-color: rgb(236 72 153 / 0.35) !important;
}

.tw-ring-pink-500\/40 {
  --tw-ring-color: rgb(236 72 153 / 0.4) !important;
}

.tw-ring-pink-500\/45 {
  --tw-ring-color: rgb(236 72 153 / 0.45) !important;
}

.tw-ring-pink-500\/5 {
  --tw-ring-color: rgb(236 72 153 / 0.05) !important;
}

.tw-ring-pink-500\/50 {
  --tw-ring-color: rgb(236 72 153 / 0.5) !important;
}

.tw-ring-pink-500\/55 {
  --tw-ring-color: rgb(236 72 153 / 0.55) !important;
}

.tw-ring-pink-500\/60 {
  --tw-ring-color: rgb(236 72 153 / 0.6) !important;
}

.tw-ring-pink-500\/65 {
  --tw-ring-color: rgb(236 72 153 / 0.65) !important;
}

.tw-ring-pink-500\/70 {
  --tw-ring-color: rgb(236 72 153 / 0.7) !important;
}

.tw-ring-pink-500\/75 {
  --tw-ring-color: rgb(236 72 153 / 0.75) !important;
}

.tw-ring-pink-500\/80 {
  --tw-ring-color: rgb(236 72 153 / 0.8) !important;
}

.tw-ring-pink-500\/85 {
  --tw-ring-color: rgb(236 72 153 / 0.85) !important;
}

.tw-ring-pink-500\/90 {
  --tw-ring-color: rgb(236 72 153 / 0.9) !important;
}

.tw-ring-pink-500\/95 {
  --tw-ring-color: rgb(236 72 153 / 0.95) !important;
}

.tw-ring-pink-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(219 39 119 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-600\/0 {
  --tw-ring-color: rgb(219 39 119 / 0) !important;
}

.tw-ring-pink-600\/10 {
  --tw-ring-color: rgb(219 39 119 / 0.1) !important;
}

.tw-ring-pink-600\/100 {
  --tw-ring-color: rgb(219 39 119 / 1) !important;
}

.tw-ring-pink-600\/15 {
  --tw-ring-color: rgb(219 39 119 / 0.15) !important;
}

.tw-ring-pink-600\/20 {
  --tw-ring-color: rgb(219 39 119 / 0.2) !important;
}

.tw-ring-pink-600\/25 {
  --tw-ring-color: rgb(219 39 119 / 0.25) !important;
}

.tw-ring-pink-600\/30 {
  --tw-ring-color: rgb(219 39 119 / 0.3) !important;
}

.tw-ring-pink-600\/35 {
  --tw-ring-color: rgb(219 39 119 / 0.35) !important;
}

.tw-ring-pink-600\/40 {
  --tw-ring-color: rgb(219 39 119 / 0.4) !important;
}

.tw-ring-pink-600\/45 {
  --tw-ring-color: rgb(219 39 119 / 0.45) !important;
}

.tw-ring-pink-600\/5 {
  --tw-ring-color: rgb(219 39 119 / 0.05) !important;
}

.tw-ring-pink-600\/50 {
  --tw-ring-color: rgb(219 39 119 / 0.5) !important;
}

.tw-ring-pink-600\/55 {
  --tw-ring-color: rgb(219 39 119 / 0.55) !important;
}

.tw-ring-pink-600\/60 {
  --tw-ring-color: rgb(219 39 119 / 0.6) !important;
}

.tw-ring-pink-600\/65 {
  --tw-ring-color: rgb(219 39 119 / 0.65) !important;
}

.tw-ring-pink-600\/70 {
  --tw-ring-color: rgb(219 39 119 / 0.7) !important;
}

.tw-ring-pink-600\/75 {
  --tw-ring-color: rgb(219 39 119 / 0.75) !important;
}

.tw-ring-pink-600\/80 {
  --tw-ring-color: rgb(219 39 119 / 0.8) !important;
}

.tw-ring-pink-600\/85 {
  --tw-ring-color: rgb(219 39 119 / 0.85) !important;
}

.tw-ring-pink-600\/90 {
  --tw-ring-color: rgb(219 39 119 / 0.9) !important;
}

.tw-ring-pink-600\/95 {
  --tw-ring-color: rgb(219 39 119 / 0.95) !important;
}

.tw-ring-pink-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(190 24 93 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-700\/0 {
  --tw-ring-color: rgb(190 24 93 / 0) !important;
}

.tw-ring-pink-700\/10 {
  --tw-ring-color: rgb(190 24 93 / 0.1) !important;
}

.tw-ring-pink-700\/100 {
  --tw-ring-color: rgb(190 24 93 / 1) !important;
}

.tw-ring-pink-700\/15 {
  --tw-ring-color: rgb(190 24 93 / 0.15) !important;
}

.tw-ring-pink-700\/20 {
  --tw-ring-color: rgb(190 24 93 / 0.2) !important;
}

.tw-ring-pink-700\/25 {
  --tw-ring-color: rgb(190 24 93 / 0.25) !important;
}

.tw-ring-pink-700\/30 {
  --tw-ring-color: rgb(190 24 93 / 0.3) !important;
}

.tw-ring-pink-700\/35 {
  --tw-ring-color: rgb(190 24 93 / 0.35) !important;
}

.tw-ring-pink-700\/40 {
  --tw-ring-color: rgb(190 24 93 / 0.4) !important;
}

.tw-ring-pink-700\/45 {
  --tw-ring-color: rgb(190 24 93 / 0.45) !important;
}

.tw-ring-pink-700\/5 {
  --tw-ring-color: rgb(190 24 93 / 0.05) !important;
}

.tw-ring-pink-700\/50 {
  --tw-ring-color: rgb(190 24 93 / 0.5) !important;
}

.tw-ring-pink-700\/55 {
  --tw-ring-color: rgb(190 24 93 / 0.55) !important;
}

.tw-ring-pink-700\/60 {
  --tw-ring-color: rgb(190 24 93 / 0.6) !important;
}

.tw-ring-pink-700\/65 {
  --tw-ring-color: rgb(190 24 93 / 0.65) !important;
}

.tw-ring-pink-700\/70 {
  --tw-ring-color: rgb(190 24 93 / 0.7) !important;
}

.tw-ring-pink-700\/75 {
  --tw-ring-color: rgb(190 24 93 / 0.75) !important;
}

.tw-ring-pink-700\/80 {
  --tw-ring-color: rgb(190 24 93 / 0.8) !important;
}

.tw-ring-pink-700\/85 {
  --tw-ring-color: rgb(190 24 93 / 0.85) !important;
}

.tw-ring-pink-700\/90 {
  --tw-ring-color: rgb(190 24 93 / 0.9) !important;
}

.tw-ring-pink-700\/95 {
  --tw-ring-color: rgb(190 24 93 / 0.95) !important;
}

.tw-ring-pink-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(157 23 77 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-800\/0 {
  --tw-ring-color: rgb(157 23 77 / 0) !important;
}

.tw-ring-pink-800\/10 {
  --tw-ring-color: rgb(157 23 77 / 0.1) !important;
}

.tw-ring-pink-800\/100 {
  --tw-ring-color: rgb(157 23 77 / 1) !important;
}

.tw-ring-pink-800\/15 {
  --tw-ring-color: rgb(157 23 77 / 0.15) !important;
}

.tw-ring-pink-800\/20 {
  --tw-ring-color: rgb(157 23 77 / 0.2) !important;
}

.tw-ring-pink-800\/25 {
  --tw-ring-color: rgb(157 23 77 / 0.25) !important;
}

.tw-ring-pink-800\/30 {
  --tw-ring-color: rgb(157 23 77 / 0.3) !important;
}

.tw-ring-pink-800\/35 {
  --tw-ring-color: rgb(157 23 77 / 0.35) !important;
}

.tw-ring-pink-800\/40 {
  --tw-ring-color: rgb(157 23 77 / 0.4) !important;
}

.tw-ring-pink-800\/45 {
  --tw-ring-color: rgb(157 23 77 / 0.45) !important;
}

.tw-ring-pink-800\/5 {
  --tw-ring-color: rgb(157 23 77 / 0.05) !important;
}

.tw-ring-pink-800\/50 {
  --tw-ring-color: rgb(157 23 77 / 0.5) !important;
}

.tw-ring-pink-800\/55 {
  --tw-ring-color: rgb(157 23 77 / 0.55) !important;
}

.tw-ring-pink-800\/60 {
  --tw-ring-color: rgb(157 23 77 / 0.6) !important;
}

.tw-ring-pink-800\/65 {
  --tw-ring-color: rgb(157 23 77 / 0.65) !important;
}

.tw-ring-pink-800\/70 {
  --tw-ring-color: rgb(157 23 77 / 0.7) !important;
}

.tw-ring-pink-800\/75 {
  --tw-ring-color: rgb(157 23 77 / 0.75) !important;
}

.tw-ring-pink-800\/80 {
  --tw-ring-color: rgb(157 23 77 / 0.8) !important;
}

.tw-ring-pink-800\/85 {
  --tw-ring-color: rgb(157 23 77 / 0.85) !important;
}

.tw-ring-pink-800\/90 {
  --tw-ring-color: rgb(157 23 77 / 0.9) !important;
}

.tw-ring-pink-800\/95 {
  --tw-ring-color: rgb(157 23 77 / 0.95) !important;
}

.tw-ring-pink-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(131 24 67 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-900\/0 {
  --tw-ring-color: rgb(131 24 67 / 0) !important;
}

.tw-ring-pink-900\/10 {
  --tw-ring-color: rgb(131 24 67 / 0.1) !important;
}

.tw-ring-pink-900\/100 {
  --tw-ring-color: rgb(131 24 67 / 1) !important;
}

.tw-ring-pink-900\/15 {
  --tw-ring-color: rgb(131 24 67 / 0.15) !important;
}

.tw-ring-pink-900\/20 {
  --tw-ring-color: rgb(131 24 67 / 0.2) !important;
}

.tw-ring-pink-900\/25 {
  --tw-ring-color: rgb(131 24 67 / 0.25) !important;
}

.tw-ring-pink-900\/30 {
  --tw-ring-color: rgb(131 24 67 / 0.3) !important;
}

.tw-ring-pink-900\/35 {
  --tw-ring-color: rgb(131 24 67 / 0.35) !important;
}

.tw-ring-pink-900\/40 {
  --tw-ring-color: rgb(131 24 67 / 0.4) !important;
}

.tw-ring-pink-900\/45 {
  --tw-ring-color: rgb(131 24 67 / 0.45) !important;
}

.tw-ring-pink-900\/5 {
  --tw-ring-color: rgb(131 24 67 / 0.05) !important;
}

.tw-ring-pink-900\/50 {
  --tw-ring-color: rgb(131 24 67 / 0.5) !important;
}

.tw-ring-pink-900\/55 {
  --tw-ring-color: rgb(131 24 67 / 0.55) !important;
}

.tw-ring-pink-900\/60 {
  --tw-ring-color: rgb(131 24 67 / 0.6) !important;
}

.tw-ring-pink-900\/65 {
  --tw-ring-color: rgb(131 24 67 / 0.65) !important;
}

.tw-ring-pink-900\/70 {
  --tw-ring-color: rgb(131 24 67 / 0.7) !important;
}

.tw-ring-pink-900\/75 {
  --tw-ring-color: rgb(131 24 67 / 0.75) !important;
}

.tw-ring-pink-900\/80 {
  --tw-ring-color: rgb(131 24 67 / 0.8) !important;
}

.tw-ring-pink-900\/85 {
  --tw-ring-color: rgb(131 24 67 / 0.85) !important;
}

.tw-ring-pink-900\/90 {
  --tw-ring-color: rgb(131 24 67 / 0.9) !important;
}

.tw-ring-pink-900\/95 {
  --tw-ring-color: rgb(131 24 67 / 0.95) !important;
}

.tw-ring-pink-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(80 7 36 / var(--tw-ring-opacity)) !important;
}

.tw-ring-pink-950\/0 {
  --tw-ring-color: rgb(80 7 36 / 0) !important;
}

.tw-ring-pink-950\/10 {
  --tw-ring-color: rgb(80 7 36 / 0.1) !important;
}

.tw-ring-pink-950\/100 {
  --tw-ring-color: rgb(80 7 36 / 1) !important;
}

.tw-ring-pink-950\/15 {
  --tw-ring-color: rgb(80 7 36 / 0.15) !important;
}

.tw-ring-pink-950\/20 {
  --tw-ring-color: rgb(80 7 36 / 0.2) !important;
}

.tw-ring-pink-950\/25 {
  --tw-ring-color: rgb(80 7 36 / 0.25) !important;
}

.tw-ring-pink-950\/30 {
  --tw-ring-color: rgb(80 7 36 / 0.3) !important;
}

.tw-ring-pink-950\/35 {
  --tw-ring-color: rgb(80 7 36 / 0.35) !important;
}

.tw-ring-pink-950\/40 {
  --tw-ring-color: rgb(80 7 36 / 0.4) !important;
}

.tw-ring-pink-950\/45 {
  --tw-ring-color: rgb(80 7 36 / 0.45) !important;
}

.tw-ring-pink-950\/5 {
  --tw-ring-color: rgb(80 7 36 / 0.05) !important;
}

.tw-ring-pink-950\/50 {
  --tw-ring-color: rgb(80 7 36 / 0.5) !important;
}

.tw-ring-pink-950\/55 {
  --tw-ring-color: rgb(80 7 36 / 0.55) !important;
}

.tw-ring-pink-950\/60 {
  --tw-ring-color: rgb(80 7 36 / 0.6) !important;
}

.tw-ring-pink-950\/65 {
  --tw-ring-color: rgb(80 7 36 / 0.65) !important;
}

.tw-ring-pink-950\/70 {
  --tw-ring-color: rgb(80 7 36 / 0.7) !important;
}

.tw-ring-pink-950\/75 {
  --tw-ring-color: rgb(80 7 36 / 0.75) !important;
}

.tw-ring-pink-950\/80 {
  --tw-ring-color: rgb(80 7 36 / 0.8) !important;
}

.tw-ring-pink-950\/85 {
  --tw-ring-color: rgb(80 7 36 / 0.85) !important;
}

.tw-ring-pink-950\/90 {
  --tw-ring-color: rgb(80 7 36 / 0.9) !important;
}

.tw-ring-pink-950\/95 {
  --tw-ring-color: rgb(80 7 36 / 0.95) !important;
}

.tw-ring-purple-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(243 232 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-100\/0 {
  --tw-ring-color: rgb(243 232 255 / 0) !important;
}

.tw-ring-purple-100\/10 {
  --tw-ring-color: rgb(243 232 255 / 0.1) !important;
}

.tw-ring-purple-100\/100 {
  --tw-ring-color: rgb(243 232 255 / 1) !important;
}

.tw-ring-purple-100\/15 {
  --tw-ring-color: rgb(243 232 255 / 0.15) !important;
}

.tw-ring-purple-100\/20 {
  --tw-ring-color: rgb(243 232 255 / 0.2) !important;
}

.tw-ring-purple-100\/25 {
  --tw-ring-color: rgb(243 232 255 / 0.25) !important;
}

.tw-ring-purple-100\/30 {
  --tw-ring-color: rgb(243 232 255 / 0.3) !important;
}

.tw-ring-purple-100\/35 {
  --tw-ring-color: rgb(243 232 255 / 0.35) !important;
}

.tw-ring-purple-100\/40 {
  --tw-ring-color: rgb(243 232 255 / 0.4) !important;
}

.tw-ring-purple-100\/45 {
  --tw-ring-color: rgb(243 232 255 / 0.45) !important;
}

.tw-ring-purple-100\/5 {
  --tw-ring-color: rgb(243 232 255 / 0.05) !important;
}

.tw-ring-purple-100\/50 {
  --tw-ring-color: rgb(243 232 255 / 0.5) !important;
}

.tw-ring-purple-100\/55 {
  --tw-ring-color: rgb(243 232 255 / 0.55) !important;
}

.tw-ring-purple-100\/60 {
  --tw-ring-color: rgb(243 232 255 / 0.6) !important;
}

.tw-ring-purple-100\/65 {
  --tw-ring-color: rgb(243 232 255 / 0.65) !important;
}

.tw-ring-purple-100\/70 {
  --tw-ring-color: rgb(243 232 255 / 0.7) !important;
}

.tw-ring-purple-100\/75 {
  --tw-ring-color: rgb(243 232 255 / 0.75) !important;
}

.tw-ring-purple-100\/80 {
  --tw-ring-color: rgb(243 232 255 / 0.8) !important;
}

.tw-ring-purple-100\/85 {
  --tw-ring-color: rgb(243 232 255 / 0.85) !important;
}

.tw-ring-purple-100\/90 {
  --tw-ring-color: rgb(243 232 255 / 0.9) !important;
}

.tw-ring-purple-100\/95 {
  --tw-ring-color: rgb(243 232 255 / 0.95) !important;
}

.tw-ring-purple-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(233 213 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-200\/0 {
  --tw-ring-color: rgb(233 213 255 / 0) !important;
}

.tw-ring-purple-200\/10 {
  --tw-ring-color: rgb(233 213 255 / 0.1) !important;
}

.tw-ring-purple-200\/100 {
  --tw-ring-color: rgb(233 213 255 / 1) !important;
}

.tw-ring-purple-200\/15 {
  --tw-ring-color: rgb(233 213 255 / 0.15) !important;
}

.tw-ring-purple-200\/20 {
  --tw-ring-color: rgb(233 213 255 / 0.2) !important;
}

.tw-ring-purple-200\/25 {
  --tw-ring-color: rgb(233 213 255 / 0.25) !important;
}

.tw-ring-purple-200\/30 {
  --tw-ring-color: rgb(233 213 255 / 0.3) !important;
}

.tw-ring-purple-200\/35 {
  --tw-ring-color: rgb(233 213 255 / 0.35) !important;
}

.tw-ring-purple-200\/40 {
  --tw-ring-color: rgb(233 213 255 / 0.4) !important;
}

.tw-ring-purple-200\/45 {
  --tw-ring-color: rgb(233 213 255 / 0.45) !important;
}

.tw-ring-purple-200\/5 {
  --tw-ring-color: rgb(233 213 255 / 0.05) !important;
}

.tw-ring-purple-200\/50 {
  --tw-ring-color: rgb(233 213 255 / 0.5) !important;
}

.tw-ring-purple-200\/55 {
  --tw-ring-color: rgb(233 213 255 / 0.55) !important;
}

.tw-ring-purple-200\/60 {
  --tw-ring-color: rgb(233 213 255 / 0.6) !important;
}

.tw-ring-purple-200\/65 {
  --tw-ring-color: rgb(233 213 255 / 0.65) !important;
}

.tw-ring-purple-200\/70 {
  --tw-ring-color: rgb(233 213 255 / 0.7) !important;
}

.tw-ring-purple-200\/75 {
  --tw-ring-color: rgb(233 213 255 / 0.75) !important;
}

.tw-ring-purple-200\/80 {
  --tw-ring-color: rgb(233 213 255 / 0.8) !important;
}

.tw-ring-purple-200\/85 {
  --tw-ring-color: rgb(233 213 255 / 0.85) !important;
}

.tw-ring-purple-200\/90 {
  --tw-ring-color: rgb(233 213 255 / 0.9) !important;
}

.tw-ring-purple-200\/95 {
  --tw-ring-color: rgb(233 213 255 / 0.95) !important;
}

.tw-ring-purple-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(216 180 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-300\/0 {
  --tw-ring-color: rgb(216 180 254 / 0) !important;
}

.tw-ring-purple-300\/10 {
  --tw-ring-color: rgb(216 180 254 / 0.1) !important;
}

.tw-ring-purple-300\/100 {
  --tw-ring-color: rgb(216 180 254 / 1) !important;
}

.tw-ring-purple-300\/15 {
  --tw-ring-color: rgb(216 180 254 / 0.15) !important;
}

.tw-ring-purple-300\/20 {
  --tw-ring-color: rgb(216 180 254 / 0.2) !important;
}

.tw-ring-purple-300\/25 {
  --tw-ring-color: rgb(216 180 254 / 0.25) !important;
}

.tw-ring-purple-300\/30 {
  --tw-ring-color: rgb(216 180 254 / 0.3) !important;
}

.tw-ring-purple-300\/35 {
  --tw-ring-color: rgb(216 180 254 / 0.35) !important;
}

.tw-ring-purple-300\/40 {
  --tw-ring-color: rgb(216 180 254 / 0.4) !important;
}

.tw-ring-purple-300\/45 {
  --tw-ring-color: rgb(216 180 254 / 0.45) !important;
}

.tw-ring-purple-300\/5 {
  --tw-ring-color: rgb(216 180 254 / 0.05) !important;
}

.tw-ring-purple-300\/50 {
  --tw-ring-color: rgb(216 180 254 / 0.5) !important;
}

.tw-ring-purple-300\/55 {
  --tw-ring-color: rgb(216 180 254 / 0.55) !important;
}

.tw-ring-purple-300\/60 {
  --tw-ring-color: rgb(216 180 254 / 0.6) !important;
}

.tw-ring-purple-300\/65 {
  --tw-ring-color: rgb(216 180 254 / 0.65) !important;
}

.tw-ring-purple-300\/70 {
  --tw-ring-color: rgb(216 180 254 / 0.7) !important;
}

.tw-ring-purple-300\/75 {
  --tw-ring-color: rgb(216 180 254 / 0.75) !important;
}

.tw-ring-purple-300\/80 {
  --tw-ring-color: rgb(216 180 254 / 0.8) !important;
}

.tw-ring-purple-300\/85 {
  --tw-ring-color: rgb(216 180 254 / 0.85) !important;
}

.tw-ring-purple-300\/90 {
  --tw-ring-color: rgb(216 180 254 / 0.9) !important;
}

.tw-ring-purple-300\/95 {
  --tw-ring-color: rgb(216 180 254 / 0.95) !important;
}

.tw-ring-purple-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(192 132 252 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-400\/0 {
  --tw-ring-color: rgb(192 132 252 / 0) !important;
}

.tw-ring-purple-400\/10 {
  --tw-ring-color: rgb(192 132 252 / 0.1) !important;
}

.tw-ring-purple-400\/100 {
  --tw-ring-color: rgb(192 132 252 / 1) !important;
}

.tw-ring-purple-400\/15 {
  --tw-ring-color: rgb(192 132 252 / 0.15) !important;
}

.tw-ring-purple-400\/20 {
  --tw-ring-color: rgb(192 132 252 / 0.2) !important;
}

.tw-ring-purple-400\/25 {
  --tw-ring-color: rgb(192 132 252 / 0.25) !important;
}

.tw-ring-purple-400\/30 {
  --tw-ring-color: rgb(192 132 252 / 0.3) !important;
}

.tw-ring-purple-400\/35 {
  --tw-ring-color: rgb(192 132 252 / 0.35) !important;
}

.tw-ring-purple-400\/40 {
  --tw-ring-color: rgb(192 132 252 / 0.4) !important;
}

.tw-ring-purple-400\/45 {
  --tw-ring-color: rgb(192 132 252 / 0.45) !important;
}

.tw-ring-purple-400\/5 {
  --tw-ring-color: rgb(192 132 252 / 0.05) !important;
}

.tw-ring-purple-400\/50 {
  --tw-ring-color: rgb(192 132 252 / 0.5) !important;
}

.tw-ring-purple-400\/55 {
  --tw-ring-color: rgb(192 132 252 / 0.55) !important;
}

.tw-ring-purple-400\/60 {
  --tw-ring-color: rgb(192 132 252 / 0.6) !important;
}

.tw-ring-purple-400\/65 {
  --tw-ring-color: rgb(192 132 252 / 0.65) !important;
}

.tw-ring-purple-400\/70 {
  --tw-ring-color: rgb(192 132 252 / 0.7) !important;
}

.tw-ring-purple-400\/75 {
  --tw-ring-color: rgb(192 132 252 / 0.75) !important;
}

.tw-ring-purple-400\/80 {
  --tw-ring-color: rgb(192 132 252 / 0.8) !important;
}

.tw-ring-purple-400\/85 {
  --tw-ring-color: rgb(192 132 252 / 0.85) !important;
}

.tw-ring-purple-400\/90 {
  --tw-ring-color: rgb(192 132 252 / 0.9) !important;
}

.tw-ring-purple-400\/95 {
  --tw-ring-color: rgb(192 132 252 / 0.95) !important;
}

.tw-ring-purple-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(250 245 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-50\/0 {
  --tw-ring-color: rgb(250 245 255 / 0) !important;
}

.tw-ring-purple-50\/10 {
  --tw-ring-color: rgb(250 245 255 / 0.1) !important;
}

.tw-ring-purple-50\/100 {
  --tw-ring-color: rgb(250 245 255 / 1) !important;
}

.tw-ring-purple-50\/15 {
  --tw-ring-color: rgb(250 245 255 / 0.15) !important;
}

.tw-ring-purple-50\/20 {
  --tw-ring-color: rgb(250 245 255 / 0.2) !important;
}

.tw-ring-purple-50\/25 {
  --tw-ring-color: rgb(250 245 255 / 0.25) !important;
}

.tw-ring-purple-50\/30 {
  --tw-ring-color: rgb(250 245 255 / 0.3) !important;
}

.tw-ring-purple-50\/35 {
  --tw-ring-color: rgb(250 245 255 / 0.35) !important;
}

.tw-ring-purple-50\/40 {
  --tw-ring-color: rgb(250 245 255 / 0.4) !important;
}

.tw-ring-purple-50\/45 {
  --tw-ring-color: rgb(250 245 255 / 0.45) !important;
}

.tw-ring-purple-50\/5 {
  --tw-ring-color: rgb(250 245 255 / 0.05) !important;
}

.tw-ring-purple-50\/50 {
  --tw-ring-color: rgb(250 245 255 / 0.5) !important;
}

.tw-ring-purple-50\/55 {
  --tw-ring-color: rgb(250 245 255 / 0.55) !important;
}

.tw-ring-purple-50\/60 {
  --tw-ring-color: rgb(250 245 255 / 0.6) !important;
}

.tw-ring-purple-50\/65 {
  --tw-ring-color: rgb(250 245 255 / 0.65) !important;
}

.tw-ring-purple-50\/70 {
  --tw-ring-color: rgb(250 245 255 / 0.7) !important;
}

.tw-ring-purple-50\/75 {
  --tw-ring-color: rgb(250 245 255 / 0.75) !important;
}

.tw-ring-purple-50\/80 {
  --tw-ring-color: rgb(250 245 255 / 0.8) !important;
}

.tw-ring-purple-50\/85 {
  --tw-ring-color: rgb(250 245 255 / 0.85) !important;
}

.tw-ring-purple-50\/90 {
  --tw-ring-color: rgb(250 245 255 / 0.9) !important;
}

.tw-ring-purple-50\/95 {
  --tw-ring-color: rgb(250 245 255 / 0.95) !important;
}

.tw-ring-purple-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(168 85 247 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-500\/0 {
  --tw-ring-color: rgb(168 85 247 / 0) !important;
}

.tw-ring-purple-500\/10 {
  --tw-ring-color: rgb(168 85 247 / 0.1) !important;
}

.tw-ring-purple-500\/100 {
  --tw-ring-color: rgb(168 85 247 / 1) !important;
}

.tw-ring-purple-500\/15 {
  --tw-ring-color: rgb(168 85 247 / 0.15) !important;
}

.tw-ring-purple-500\/20 {
  --tw-ring-color: rgb(168 85 247 / 0.2) !important;
}

.tw-ring-purple-500\/25 {
  --tw-ring-color: rgb(168 85 247 / 0.25) !important;
}

.tw-ring-purple-500\/30 {
  --tw-ring-color: rgb(168 85 247 / 0.3) !important;
}

.tw-ring-purple-500\/35 {
  --tw-ring-color: rgb(168 85 247 / 0.35) !important;
}

.tw-ring-purple-500\/40 {
  --tw-ring-color: rgb(168 85 247 / 0.4) !important;
}

.tw-ring-purple-500\/45 {
  --tw-ring-color: rgb(168 85 247 / 0.45) !important;
}

.tw-ring-purple-500\/5 {
  --tw-ring-color: rgb(168 85 247 / 0.05) !important;
}

.tw-ring-purple-500\/50 {
  --tw-ring-color: rgb(168 85 247 / 0.5) !important;
}

.tw-ring-purple-500\/55 {
  --tw-ring-color: rgb(168 85 247 / 0.55) !important;
}

.tw-ring-purple-500\/60 {
  --tw-ring-color: rgb(168 85 247 / 0.6) !important;
}

.tw-ring-purple-500\/65 {
  --tw-ring-color: rgb(168 85 247 / 0.65) !important;
}

.tw-ring-purple-500\/70 {
  --tw-ring-color: rgb(168 85 247 / 0.7) !important;
}

.tw-ring-purple-500\/75 {
  --tw-ring-color: rgb(168 85 247 / 0.75) !important;
}

.tw-ring-purple-500\/80 {
  --tw-ring-color: rgb(168 85 247 / 0.8) !important;
}

.tw-ring-purple-500\/85 {
  --tw-ring-color: rgb(168 85 247 / 0.85) !important;
}

.tw-ring-purple-500\/90 {
  --tw-ring-color: rgb(168 85 247 / 0.9) !important;
}

.tw-ring-purple-500\/95 {
  --tw-ring-color: rgb(168 85 247 / 0.95) !important;
}

.tw-ring-purple-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(147 51 234 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-600\/0 {
  --tw-ring-color: rgb(147 51 234 / 0) !important;
}

.tw-ring-purple-600\/10 {
  --tw-ring-color: rgb(147 51 234 / 0.1) !important;
}

.tw-ring-purple-600\/100 {
  --tw-ring-color: rgb(147 51 234 / 1) !important;
}

.tw-ring-purple-600\/15 {
  --tw-ring-color: rgb(147 51 234 / 0.15) !important;
}

.tw-ring-purple-600\/20 {
  --tw-ring-color: rgb(147 51 234 / 0.2) !important;
}

.tw-ring-purple-600\/25 {
  --tw-ring-color: rgb(147 51 234 / 0.25) !important;
}

.tw-ring-purple-600\/30 {
  --tw-ring-color: rgb(147 51 234 / 0.3) !important;
}

.tw-ring-purple-600\/35 {
  --tw-ring-color: rgb(147 51 234 / 0.35) !important;
}

.tw-ring-purple-600\/40 {
  --tw-ring-color: rgb(147 51 234 / 0.4) !important;
}

.tw-ring-purple-600\/45 {
  --tw-ring-color: rgb(147 51 234 / 0.45) !important;
}

.tw-ring-purple-600\/5 {
  --tw-ring-color: rgb(147 51 234 / 0.05) !important;
}

.tw-ring-purple-600\/50 {
  --tw-ring-color: rgb(147 51 234 / 0.5) !important;
}

.tw-ring-purple-600\/55 {
  --tw-ring-color: rgb(147 51 234 / 0.55) !important;
}

.tw-ring-purple-600\/60 {
  --tw-ring-color: rgb(147 51 234 / 0.6) !important;
}

.tw-ring-purple-600\/65 {
  --tw-ring-color: rgb(147 51 234 / 0.65) !important;
}

.tw-ring-purple-600\/70 {
  --tw-ring-color: rgb(147 51 234 / 0.7) !important;
}

.tw-ring-purple-600\/75 {
  --tw-ring-color: rgb(147 51 234 / 0.75) !important;
}

.tw-ring-purple-600\/80 {
  --tw-ring-color: rgb(147 51 234 / 0.8) !important;
}

.tw-ring-purple-600\/85 {
  --tw-ring-color: rgb(147 51 234 / 0.85) !important;
}

.tw-ring-purple-600\/90 {
  --tw-ring-color: rgb(147 51 234 / 0.9) !important;
}

.tw-ring-purple-600\/95 {
  --tw-ring-color: rgb(147 51 234 / 0.95) !important;
}

.tw-ring-purple-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(126 34 206 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-700\/0 {
  --tw-ring-color: rgb(126 34 206 / 0) !important;
}

.tw-ring-purple-700\/10 {
  --tw-ring-color: rgb(126 34 206 / 0.1) !important;
}

.tw-ring-purple-700\/100 {
  --tw-ring-color: rgb(126 34 206 / 1) !important;
}

.tw-ring-purple-700\/15 {
  --tw-ring-color: rgb(126 34 206 / 0.15) !important;
}

.tw-ring-purple-700\/20 {
  --tw-ring-color: rgb(126 34 206 / 0.2) !important;
}

.tw-ring-purple-700\/25 {
  --tw-ring-color: rgb(126 34 206 / 0.25) !important;
}

.tw-ring-purple-700\/30 {
  --tw-ring-color: rgb(126 34 206 / 0.3) !important;
}

.tw-ring-purple-700\/35 {
  --tw-ring-color: rgb(126 34 206 / 0.35) !important;
}

.tw-ring-purple-700\/40 {
  --tw-ring-color: rgb(126 34 206 / 0.4) !important;
}

.tw-ring-purple-700\/45 {
  --tw-ring-color: rgb(126 34 206 / 0.45) !important;
}

.tw-ring-purple-700\/5 {
  --tw-ring-color: rgb(126 34 206 / 0.05) !important;
}

.tw-ring-purple-700\/50 {
  --tw-ring-color: rgb(126 34 206 / 0.5) !important;
}

.tw-ring-purple-700\/55 {
  --tw-ring-color: rgb(126 34 206 / 0.55) !important;
}

.tw-ring-purple-700\/60 {
  --tw-ring-color: rgb(126 34 206 / 0.6) !important;
}

.tw-ring-purple-700\/65 {
  --tw-ring-color: rgb(126 34 206 / 0.65) !important;
}

.tw-ring-purple-700\/70 {
  --tw-ring-color: rgb(126 34 206 / 0.7) !important;
}

.tw-ring-purple-700\/75 {
  --tw-ring-color: rgb(126 34 206 / 0.75) !important;
}

.tw-ring-purple-700\/80 {
  --tw-ring-color: rgb(126 34 206 / 0.8) !important;
}

.tw-ring-purple-700\/85 {
  --tw-ring-color: rgb(126 34 206 / 0.85) !important;
}

.tw-ring-purple-700\/90 {
  --tw-ring-color: rgb(126 34 206 / 0.9) !important;
}

.tw-ring-purple-700\/95 {
  --tw-ring-color: rgb(126 34 206 / 0.95) !important;
}

.tw-ring-purple-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(107 33 168 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-800\/0 {
  --tw-ring-color: rgb(107 33 168 / 0) !important;
}

.tw-ring-purple-800\/10 {
  --tw-ring-color: rgb(107 33 168 / 0.1) !important;
}

.tw-ring-purple-800\/100 {
  --tw-ring-color: rgb(107 33 168 / 1) !important;
}

.tw-ring-purple-800\/15 {
  --tw-ring-color: rgb(107 33 168 / 0.15) !important;
}

.tw-ring-purple-800\/20 {
  --tw-ring-color: rgb(107 33 168 / 0.2) !important;
}

.tw-ring-purple-800\/25 {
  --tw-ring-color: rgb(107 33 168 / 0.25) !important;
}

.tw-ring-purple-800\/30 {
  --tw-ring-color: rgb(107 33 168 / 0.3) !important;
}

.tw-ring-purple-800\/35 {
  --tw-ring-color: rgb(107 33 168 / 0.35) !important;
}

.tw-ring-purple-800\/40 {
  --tw-ring-color: rgb(107 33 168 / 0.4) !important;
}

.tw-ring-purple-800\/45 {
  --tw-ring-color: rgb(107 33 168 / 0.45) !important;
}

.tw-ring-purple-800\/5 {
  --tw-ring-color: rgb(107 33 168 / 0.05) !important;
}

.tw-ring-purple-800\/50 {
  --tw-ring-color: rgb(107 33 168 / 0.5) !important;
}

.tw-ring-purple-800\/55 {
  --tw-ring-color: rgb(107 33 168 / 0.55) !important;
}

.tw-ring-purple-800\/60 {
  --tw-ring-color: rgb(107 33 168 / 0.6) !important;
}

.tw-ring-purple-800\/65 {
  --tw-ring-color: rgb(107 33 168 / 0.65) !important;
}

.tw-ring-purple-800\/70 {
  --tw-ring-color: rgb(107 33 168 / 0.7) !important;
}

.tw-ring-purple-800\/75 {
  --tw-ring-color: rgb(107 33 168 / 0.75) !important;
}

.tw-ring-purple-800\/80 {
  --tw-ring-color: rgb(107 33 168 / 0.8) !important;
}

.tw-ring-purple-800\/85 {
  --tw-ring-color: rgb(107 33 168 / 0.85) !important;
}

.tw-ring-purple-800\/90 {
  --tw-ring-color: rgb(107 33 168 / 0.9) !important;
}

.tw-ring-purple-800\/95 {
  --tw-ring-color: rgb(107 33 168 / 0.95) !important;
}

.tw-ring-purple-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(88 28 135 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-900\/0 {
  --tw-ring-color: rgb(88 28 135 / 0) !important;
}

.tw-ring-purple-900\/10 {
  --tw-ring-color: rgb(88 28 135 / 0.1) !important;
}

.tw-ring-purple-900\/100 {
  --tw-ring-color: rgb(88 28 135 / 1) !important;
}

.tw-ring-purple-900\/15 {
  --tw-ring-color: rgb(88 28 135 / 0.15) !important;
}

.tw-ring-purple-900\/20 {
  --tw-ring-color: rgb(88 28 135 / 0.2) !important;
}

.tw-ring-purple-900\/25 {
  --tw-ring-color: rgb(88 28 135 / 0.25) !important;
}

.tw-ring-purple-900\/30 {
  --tw-ring-color: rgb(88 28 135 / 0.3) !important;
}

.tw-ring-purple-900\/35 {
  --tw-ring-color: rgb(88 28 135 / 0.35) !important;
}

.tw-ring-purple-900\/40 {
  --tw-ring-color: rgb(88 28 135 / 0.4) !important;
}

.tw-ring-purple-900\/45 {
  --tw-ring-color: rgb(88 28 135 / 0.45) !important;
}

.tw-ring-purple-900\/5 {
  --tw-ring-color: rgb(88 28 135 / 0.05) !important;
}

.tw-ring-purple-900\/50 {
  --tw-ring-color: rgb(88 28 135 / 0.5) !important;
}

.tw-ring-purple-900\/55 {
  --tw-ring-color: rgb(88 28 135 / 0.55) !important;
}

.tw-ring-purple-900\/60 {
  --tw-ring-color: rgb(88 28 135 / 0.6) !important;
}

.tw-ring-purple-900\/65 {
  --tw-ring-color: rgb(88 28 135 / 0.65) !important;
}

.tw-ring-purple-900\/70 {
  --tw-ring-color: rgb(88 28 135 / 0.7) !important;
}

.tw-ring-purple-900\/75 {
  --tw-ring-color: rgb(88 28 135 / 0.75) !important;
}

.tw-ring-purple-900\/80 {
  --tw-ring-color: rgb(88 28 135 / 0.8) !important;
}

.tw-ring-purple-900\/85 {
  --tw-ring-color: rgb(88 28 135 / 0.85) !important;
}

.tw-ring-purple-900\/90 {
  --tw-ring-color: rgb(88 28 135 / 0.9) !important;
}

.tw-ring-purple-900\/95 {
  --tw-ring-color: rgb(88 28 135 / 0.95) !important;
}

.tw-ring-purple-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(59 7 100 / var(--tw-ring-opacity)) !important;
}

.tw-ring-purple-950\/0 {
  --tw-ring-color: rgb(59 7 100 / 0) !important;
}

.tw-ring-purple-950\/10 {
  --tw-ring-color: rgb(59 7 100 / 0.1) !important;
}

.tw-ring-purple-950\/100 {
  --tw-ring-color: rgb(59 7 100 / 1) !important;
}

.tw-ring-purple-950\/15 {
  --tw-ring-color: rgb(59 7 100 / 0.15) !important;
}

.tw-ring-purple-950\/20 {
  --tw-ring-color: rgb(59 7 100 / 0.2) !important;
}

.tw-ring-purple-950\/25 {
  --tw-ring-color: rgb(59 7 100 / 0.25) !important;
}

.tw-ring-purple-950\/30 {
  --tw-ring-color: rgb(59 7 100 / 0.3) !important;
}

.tw-ring-purple-950\/35 {
  --tw-ring-color: rgb(59 7 100 / 0.35) !important;
}

.tw-ring-purple-950\/40 {
  --tw-ring-color: rgb(59 7 100 / 0.4) !important;
}

.tw-ring-purple-950\/45 {
  --tw-ring-color: rgb(59 7 100 / 0.45) !important;
}

.tw-ring-purple-950\/5 {
  --tw-ring-color: rgb(59 7 100 / 0.05) !important;
}

.tw-ring-purple-950\/50 {
  --tw-ring-color: rgb(59 7 100 / 0.5) !important;
}

.tw-ring-purple-950\/55 {
  --tw-ring-color: rgb(59 7 100 / 0.55) !important;
}

.tw-ring-purple-950\/60 {
  --tw-ring-color: rgb(59 7 100 / 0.6) !important;
}

.tw-ring-purple-950\/65 {
  --tw-ring-color: rgb(59 7 100 / 0.65) !important;
}

.tw-ring-purple-950\/70 {
  --tw-ring-color: rgb(59 7 100 / 0.7) !important;
}

.tw-ring-purple-950\/75 {
  --tw-ring-color: rgb(59 7 100 / 0.75) !important;
}

.tw-ring-purple-950\/80 {
  --tw-ring-color: rgb(59 7 100 / 0.8) !important;
}

.tw-ring-purple-950\/85 {
  --tw-ring-color: rgb(59 7 100 / 0.85) !important;
}

.tw-ring-purple-950\/90 {
  --tw-ring-color: rgb(59 7 100 / 0.9) !important;
}

.tw-ring-purple-950\/95 {
  --tw-ring-color: rgb(59 7 100 / 0.95) !important;
}

.tw-ring-red-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 226 226 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-100\/0 {
  --tw-ring-color: rgb(254 226 226 / 0) !important;
}

.tw-ring-red-100\/10 {
  --tw-ring-color: rgb(254 226 226 / 0.1) !important;
}

.tw-ring-red-100\/100 {
  --tw-ring-color: rgb(254 226 226 / 1) !important;
}

.tw-ring-red-100\/15 {
  --tw-ring-color: rgb(254 226 226 / 0.15) !important;
}

.tw-ring-red-100\/20 {
  --tw-ring-color: rgb(254 226 226 / 0.2) !important;
}

.tw-ring-red-100\/25 {
  --tw-ring-color: rgb(254 226 226 / 0.25) !important;
}

.tw-ring-red-100\/30 {
  --tw-ring-color: rgb(254 226 226 / 0.3) !important;
}

.tw-ring-red-100\/35 {
  --tw-ring-color: rgb(254 226 226 / 0.35) !important;
}

.tw-ring-red-100\/40 {
  --tw-ring-color: rgb(254 226 226 / 0.4) !important;
}

.tw-ring-red-100\/45 {
  --tw-ring-color: rgb(254 226 226 / 0.45) !important;
}

.tw-ring-red-100\/5 {
  --tw-ring-color: rgb(254 226 226 / 0.05) !important;
}

.tw-ring-red-100\/50 {
  --tw-ring-color: rgb(254 226 226 / 0.5) !important;
}

.tw-ring-red-100\/55 {
  --tw-ring-color: rgb(254 226 226 / 0.55) !important;
}

.tw-ring-red-100\/60 {
  --tw-ring-color: rgb(254 226 226 / 0.6) !important;
}

.tw-ring-red-100\/65 {
  --tw-ring-color: rgb(254 226 226 / 0.65) !important;
}

.tw-ring-red-100\/70 {
  --tw-ring-color: rgb(254 226 226 / 0.7) !important;
}

.tw-ring-red-100\/75 {
  --tw-ring-color: rgb(254 226 226 / 0.75) !important;
}

.tw-ring-red-100\/80 {
  --tw-ring-color: rgb(254 226 226 / 0.8) !important;
}

.tw-ring-red-100\/85 {
  --tw-ring-color: rgb(254 226 226 / 0.85) !important;
}

.tw-ring-red-100\/90 {
  --tw-ring-color: rgb(254 226 226 / 0.9) !important;
}

.tw-ring-red-100\/95 {
  --tw-ring-color: rgb(254 226 226 / 0.95) !important;
}

.tw-ring-red-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 202 202 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-200\/0 {
  --tw-ring-color: rgb(254 202 202 / 0) !important;
}

.tw-ring-red-200\/10 {
  --tw-ring-color: rgb(254 202 202 / 0.1) !important;
}

.tw-ring-red-200\/100 {
  --tw-ring-color: rgb(254 202 202 / 1) !important;
}

.tw-ring-red-200\/15 {
  --tw-ring-color: rgb(254 202 202 / 0.15) !important;
}

.tw-ring-red-200\/20 {
  --tw-ring-color: rgb(254 202 202 / 0.2) !important;
}

.tw-ring-red-200\/25 {
  --tw-ring-color: rgb(254 202 202 / 0.25) !important;
}

.tw-ring-red-200\/30 {
  --tw-ring-color: rgb(254 202 202 / 0.3) !important;
}

.tw-ring-red-200\/35 {
  --tw-ring-color: rgb(254 202 202 / 0.35) !important;
}

.tw-ring-red-200\/40 {
  --tw-ring-color: rgb(254 202 202 / 0.4) !important;
}

.tw-ring-red-200\/45 {
  --tw-ring-color: rgb(254 202 202 / 0.45) !important;
}

.tw-ring-red-200\/5 {
  --tw-ring-color: rgb(254 202 202 / 0.05) !important;
}

.tw-ring-red-200\/50 {
  --tw-ring-color: rgb(254 202 202 / 0.5) !important;
}

.tw-ring-red-200\/55 {
  --tw-ring-color: rgb(254 202 202 / 0.55) !important;
}

.tw-ring-red-200\/60 {
  --tw-ring-color: rgb(254 202 202 / 0.6) !important;
}

.tw-ring-red-200\/65 {
  --tw-ring-color: rgb(254 202 202 / 0.65) !important;
}

.tw-ring-red-200\/70 {
  --tw-ring-color: rgb(254 202 202 / 0.7) !important;
}

.tw-ring-red-200\/75 {
  --tw-ring-color: rgb(254 202 202 / 0.75) !important;
}

.tw-ring-red-200\/80 {
  --tw-ring-color: rgb(254 202 202 / 0.8) !important;
}

.tw-ring-red-200\/85 {
  --tw-ring-color: rgb(254 202 202 / 0.85) !important;
}

.tw-ring-red-200\/90 {
  --tw-ring-color: rgb(254 202 202 / 0.9) !important;
}

.tw-ring-red-200\/95 {
  --tw-ring-color: rgb(254 202 202 / 0.95) !important;
}

.tw-ring-red-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(252 165 165 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-300\/0 {
  --tw-ring-color: rgb(252 165 165 / 0) !important;
}

.tw-ring-red-300\/10 {
  --tw-ring-color: rgb(252 165 165 / 0.1) !important;
}

.tw-ring-red-300\/100 {
  --tw-ring-color: rgb(252 165 165 / 1) !important;
}

.tw-ring-red-300\/15 {
  --tw-ring-color: rgb(252 165 165 / 0.15) !important;
}

.tw-ring-red-300\/20 {
  --tw-ring-color: rgb(252 165 165 / 0.2) !important;
}

.tw-ring-red-300\/25 {
  --tw-ring-color: rgb(252 165 165 / 0.25) !important;
}

.tw-ring-red-300\/30 {
  --tw-ring-color: rgb(252 165 165 / 0.3) !important;
}

.tw-ring-red-300\/35 {
  --tw-ring-color: rgb(252 165 165 / 0.35) !important;
}

.tw-ring-red-300\/40 {
  --tw-ring-color: rgb(252 165 165 / 0.4) !important;
}

.tw-ring-red-300\/45 {
  --tw-ring-color: rgb(252 165 165 / 0.45) !important;
}

.tw-ring-red-300\/5 {
  --tw-ring-color: rgb(252 165 165 / 0.05) !important;
}

.tw-ring-red-300\/50 {
  --tw-ring-color: rgb(252 165 165 / 0.5) !important;
}

.tw-ring-red-300\/55 {
  --tw-ring-color: rgb(252 165 165 / 0.55) !important;
}

.tw-ring-red-300\/60 {
  --tw-ring-color: rgb(252 165 165 / 0.6) !important;
}

.tw-ring-red-300\/65 {
  --tw-ring-color: rgb(252 165 165 / 0.65) !important;
}

.tw-ring-red-300\/70 {
  --tw-ring-color: rgb(252 165 165 / 0.7) !important;
}

.tw-ring-red-300\/75 {
  --tw-ring-color: rgb(252 165 165 / 0.75) !important;
}

.tw-ring-red-300\/80 {
  --tw-ring-color: rgb(252 165 165 / 0.8) !important;
}

.tw-ring-red-300\/85 {
  --tw-ring-color: rgb(252 165 165 / 0.85) !important;
}

.tw-ring-red-300\/90 {
  --tw-ring-color: rgb(252 165 165 / 0.9) !important;
}

.tw-ring-red-300\/95 {
  --tw-ring-color: rgb(252 165 165 / 0.95) !important;
}

.tw-ring-red-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(248 113 113 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-400\/0 {
  --tw-ring-color: rgb(248 113 113 / 0) !important;
}

.tw-ring-red-400\/10 {
  --tw-ring-color: rgb(248 113 113 / 0.1) !important;
}

.tw-ring-red-400\/100 {
  --tw-ring-color: rgb(248 113 113 / 1) !important;
}

.tw-ring-red-400\/15 {
  --tw-ring-color: rgb(248 113 113 / 0.15) !important;
}

.tw-ring-red-400\/20 {
  --tw-ring-color: rgb(248 113 113 / 0.2) !important;
}

.tw-ring-red-400\/25 {
  --tw-ring-color: rgb(248 113 113 / 0.25) !important;
}

.tw-ring-red-400\/30 {
  --tw-ring-color: rgb(248 113 113 / 0.3) !important;
}

.tw-ring-red-400\/35 {
  --tw-ring-color: rgb(248 113 113 / 0.35) !important;
}

.tw-ring-red-400\/40 {
  --tw-ring-color: rgb(248 113 113 / 0.4) !important;
}

.tw-ring-red-400\/45 {
  --tw-ring-color: rgb(248 113 113 / 0.45) !important;
}

.tw-ring-red-400\/5 {
  --tw-ring-color: rgb(248 113 113 / 0.05) !important;
}

.tw-ring-red-400\/50 {
  --tw-ring-color: rgb(248 113 113 / 0.5) !important;
}

.tw-ring-red-400\/55 {
  --tw-ring-color: rgb(248 113 113 / 0.55) !important;
}

.tw-ring-red-400\/60 {
  --tw-ring-color: rgb(248 113 113 / 0.6) !important;
}

.tw-ring-red-400\/65 {
  --tw-ring-color: rgb(248 113 113 / 0.65) !important;
}

.tw-ring-red-400\/70 {
  --tw-ring-color: rgb(248 113 113 / 0.7) !important;
}

.tw-ring-red-400\/75 {
  --tw-ring-color: rgb(248 113 113 / 0.75) !important;
}

.tw-ring-red-400\/80 {
  --tw-ring-color: rgb(248 113 113 / 0.8) !important;
}

.tw-ring-red-400\/85 {
  --tw-ring-color: rgb(248 113 113 / 0.85) !important;
}

.tw-ring-red-400\/90 {
  --tw-ring-color: rgb(248 113 113 / 0.9) !important;
}

.tw-ring-red-400\/95 {
  --tw-ring-color: rgb(248 113 113 / 0.95) !important;
}

.tw-ring-red-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 242 242 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-50\/0 {
  --tw-ring-color: rgb(254 242 242 / 0) !important;
}

.tw-ring-red-50\/10 {
  --tw-ring-color: rgb(254 242 242 / 0.1) !important;
}

.tw-ring-red-50\/100 {
  --tw-ring-color: rgb(254 242 242 / 1) !important;
}

.tw-ring-red-50\/15 {
  --tw-ring-color: rgb(254 242 242 / 0.15) !important;
}

.tw-ring-red-50\/20 {
  --tw-ring-color: rgb(254 242 242 / 0.2) !important;
}

.tw-ring-red-50\/25 {
  --tw-ring-color: rgb(254 242 242 / 0.25) !important;
}

.tw-ring-red-50\/30 {
  --tw-ring-color: rgb(254 242 242 / 0.3) !important;
}

.tw-ring-red-50\/35 {
  --tw-ring-color: rgb(254 242 242 / 0.35) !important;
}

.tw-ring-red-50\/40 {
  --tw-ring-color: rgb(254 242 242 / 0.4) !important;
}

.tw-ring-red-50\/45 {
  --tw-ring-color: rgb(254 242 242 / 0.45) !important;
}

.tw-ring-red-50\/5 {
  --tw-ring-color: rgb(254 242 242 / 0.05) !important;
}

.tw-ring-red-50\/50 {
  --tw-ring-color: rgb(254 242 242 / 0.5) !important;
}

.tw-ring-red-50\/55 {
  --tw-ring-color: rgb(254 242 242 / 0.55) !important;
}

.tw-ring-red-50\/60 {
  --tw-ring-color: rgb(254 242 242 / 0.6) !important;
}

.tw-ring-red-50\/65 {
  --tw-ring-color: rgb(254 242 242 / 0.65) !important;
}

.tw-ring-red-50\/70 {
  --tw-ring-color: rgb(254 242 242 / 0.7) !important;
}

.tw-ring-red-50\/75 {
  --tw-ring-color: rgb(254 242 242 / 0.75) !important;
}

.tw-ring-red-50\/80 {
  --tw-ring-color: rgb(254 242 242 / 0.8) !important;
}

.tw-ring-red-50\/85 {
  --tw-ring-color: rgb(254 242 242 / 0.85) !important;
}

.tw-ring-red-50\/90 {
  --tw-ring-color: rgb(254 242 242 / 0.9) !important;
}

.tw-ring-red-50\/95 {
  --tw-ring-color: rgb(254 242 242 / 0.95) !important;
}

.tw-ring-red-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(239 68 68 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-500\/0 {
  --tw-ring-color: rgb(239 68 68 / 0) !important;
}

.tw-ring-red-500\/10 {
  --tw-ring-color: rgb(239 68 68 / 0.1) !important;
}

.tw-ring-red-500\/100 {
  --tw-ring-color: rgb(239 68 68 / 1) !important;
}

.tw-ring-red-500\/15 {
  --tw-ring-color: rgb(239 68 68 / 0.15) !important;
}

.tw-ring-red-500\/20 {
  --tw-ring-color: rgb(239 68 68 / 0.2) !important;
}

.tw-ring-red-500\/25 {
  --tw-ring-color: rgb(239 68 68 / 0.25) !important;
}

.tw-ring-red-500\/30 {
  --tw-ring-color: rgb(239 68 68 / 0.3) !important;
}

.tw-ring-red-500\/35 {
  --tw-ring-color: rgb(239 68 68 / 0.35) !important;
}

.tw-ring-red-500\/40 {
  --tw-ring-color: rgb(239 68 68 / 0.4) !important;
}

.tw-ring-red-500\/45 {
  --tw-ring-color: rgb(239 68 68 / 0.45) !important;
}

.tw-ring-red-500\/5 {
  --tw-ring-color: rgb(239 68 68 / 0.05) !important;
}

.tw-ring-red-500\/50 {
  --tw-ring-color: rgb(239 68 68 / 0.5) !important;
}

.tw-ring-red-500\/55 {
  --tw-ring-color: rgb(239 68 68 / 0.55) !important;
}

.tw-ring-red-500\/60 {
  --tw-ring-color: rgb(239 68 68 / 0.6) !important;
}

.tw-ring-red-500\/65 {
  --tw-ring-color: rgb(239 68 68 / 0.65) !important;
}

.tw-ring-red-500\/70 {
  --tw-ring-color: rgb(239 68 68 / 0.7) !important;
}

.tw-ring-red-500\/75 {
  --tw-ring-color: rgb(239 68 68 / 0.75) !important;
}

.tw-ring-red-500\/80 {
  --tw-ring-color: rgb(239 68 68 / 0.8) !important;
}

.tw-ring-red-500\/85 {
  --tw-ring-color: rgb(239 68 68 / 0.85) !important;
}

.tw-ring-red-500\/90 {
  --tw-ring-color: rgb(239 68 68 / 0.9) !important;
}

.tw-ring-red-500\/95 {
  --tw-ring-color: rgb(239 68 68 / 0.95) !important;
}

.tw-ring-red-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(220 38 38 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-600\/0 {
  --tw-ring-color: rgb(220 38 38 / 0) !important;
}

.tw-ring-red-600\/10 {
  --tw-ring-color: rgb(220 38 38 / 0.1) !important;
}

.tw-ring-red-600\/100 {
  --tw-ring-color: rgb(220 38 38 / 1) !important;
}

.tw-ring-red-600\/15 {
  --tw-ring-color: rgb(220 38 38 / 0.15) !important;
}

.tw-ring-red-600\/20 {
  --tw-ring-color: rgb(220 38 38 / 0.2) !important;
}

.tw-ring-red-600\/25 {
  --tw-ring-color: rgb(220 38 38 / 0.25) !important;
}

.tw-ring-red-600\/30 {
  --tw-ring-color: rgb(220 38 38 / 0.3) !important;
}

.tw-ring-red-600\/35 {
  --tw-ring-color: rgb(220 38 38 / 0.35) !important;
}

.tw-ring-red-600\/40 {
  --tw-ring-color: rgb(220 38 38 / 0.4) !important;
}

.tw-ring-red-600\/45 {
  --tw-ring-color: rgb(220 38 38 / 0.45) !important;
}

.tw-ring-red-600\/5 {
  --tw-ring-color: rgb(220 38 38 / 0.05) !important;
}

.tw-ring-red-600\/50 {
  --tw-ring-color: rgb(220 38 38 / 0.5) !important;
}

.tw-ring-red-600\/55 {
  --tw-ring-color: rgb(220 38 38 / 0.55) !important;
}

.tw-ring-red-600\/60 {
  --tw-ring-color: rgb(220 38 38 / 0.6) !important;
}

.tw-ring-red-600\/65 {
  --tw-ring-color: rgb(220 38 38 / 0.65) !important;
}

.tw-ring-red-600\/70 {
  --tw-ring-color: rgb(220 38 38 / 0.7) !important;
}

.tw-ring-red-600\/75 {
  --tw-ring-color: rgb(220 38 38 / 0.75) !important;
}

.tw-ring-red-600\/80 {
  --tw-ring-color: rgb(220 38 38 / 0.8) !important;
}

.tw-ring-red-600\/85 {
  --tw-ring-color: rgb(220 38 38 / 0.85) !important;
}

.tw-ring-red-600\/90 {
  --tw-ring-color: rgb(220 38 38 / 0.9) !important;
}

.tw-ring-red-600\/95 {
  --tw-ring-color: rgb(220 38 38 / 0.95) !important;
}

.tw-ring-red-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(185 28 28 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-700\/0 {
  --tw-ring-color: rgb(185 28 28 / 0) !important;
}

.tw-ring-red-700\/10 {
  --tw-ring-color: rgb(185 28 28 / 0.1) !important;
}

.tw-ring-red-700\/100 {
  --tw-ring-color: rgb(185 28 28 / 1) !important;
}

.tw-ring-red-700\/15 {
  --tw-ring-color: rgb(185 28 28 / 0.15) !important;
}

.tw-ring-red-700\/20 {
  --tw-ring-color: rgb(185 28 28 / 0.2) !important;
}

.tw-ring-red-700\/25 {
  --tw-ring-color: rgb(185 28 28 / 0.25) !important;
}

.tw-ring-red-700\/30 {
  --tw-ring-color: rgb(185 28 28 / 0.3) !important;
}

.tw-ring-red-700\/35 {
  --tw-ring-color: rgb(185 28 28 / 0.35) !important;
}

.tw-ring-red-700\/40 {
  --tw-ring-color: rgb(185 28 28 / 0.4) !important;
}

.tw-ring-red-700\/45 {
  --tw-ring-color: rgb(185 28 28 / 0.45) !important;
}

.tw-ring-red-700\/5 {
  --tw-ring-color: rgb(185 28 28 / 0.05) !important;
}

.tw-ring-red-700\/50 {
  --tw-ring-color: rgb(185 28 28 / 0.5) !important;
}

.tw-ring-red-700\/55 {
  --tw-ring-color: rgb(185 28 28 / 0.55) !important;
}

.tw-ring-red-700\/60 {
  --tw-ring-color: rgb(185 28 28 / 0.6) !important;
}

.tw-ring-red-700\/65 {
  --tw-ring-color: rgb(185 28 28 / 0.65) !important;
}

.tw-ring-red-700\/70 {
  --tw-ring-color: rgb(185 28 28 / 0.7) !important;
}

.tw-ring-red-700\/75 {
  --tw-ring-color: rgb(185 28 28 / 0.75) !important;
}

.tw-ring-red-700\/80 {
  --tw-ring-color: rgb(185 28 28 / 0.8) !important;
}

.tw-ring-red-700\/85 {
  --tw-ring-color: rgb(185 28 28 / 0.85) !important;
}

.tw-ring-red-700\/90 {
  --tw-ring-color: rgb(185 28 28 / 0.9) !important;
}

.tw-ring-red-700\/95 {
  --tw-ring-color: rgb(185 28 28 / 0.95) !important;
}

.tw-ring-red-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(153 27 27 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-800\/0 {
  --tw-ring-color: rgb(153 27 27 / 0) !important;
}

.tw-ring-red-800\/10 {
  --tw-ring-color: rgb(153 27 27 / 0.1) !important;
}

.tw-ring-red-800\/100 {
  --tw-ring-color: rgb(153 27 27 / 1) !important;
}

.tw-ring-red-800\/15 {
  --tw-ring-color: rgb(153 27 27 / 0.15) !important;
}

.tw-ring-red-800\/20 {
  --tw-ring-color: rgb(153 27 27 / 0.2) !important;
}

.tw-ring-red-800\/25 {
  --tw-ring-color: rgb(153 27 27 / 0.25) !important;
}

.tw-ring-red-800\/30 {
  --tw-ring-color: rgb(153 27 27 / 0.3) !important;
}

.tw-ring-red-800\/35 {
  --tw-ring-color: rgb(153 27 27 / 0.35) !important;
}

.tw-ring-red-800\/40 {
  --tw-ring-color: rgb(153 27 27 / 0.4) !important;
}

.tw-ring-red-800\/45 {
  --tw-ring-color: rgb(153 27 27 / 0.45) !important;
}

.tw-ring-red-800\/5 {
  --tw-ring-color: rgb(153 27 27 / 0.05) !important;
}

.tw-ring-red-800\/50 {
  --tw-ring-color: rgb(153 27 27 / 0.5) !important;
}

.tw-ring-red-800\/55 {
  --tw-ring-color: rgb(153 27 27 / 0.55) !important;
}

.tw-ring-red-800\/60 {
  --tw-ring-color: rgb(153 27 27 / 0.6) !important;
}

.tw-ring-red-800\/65 {
  --tw-ring-color: rgb(153 27 27 / 0.65) !important;
}

.tw-ring-red-800\/70 {
  --tw-ring-color: rgb(153 27 27 / 0.7) !important;
}

.tw-ring-red-800\/75 {
  --tw-ring-color: rgb(153 27 27 / 0.75) !important;
}

.tw-ring-red-800\/80 {
  --tw-ring-color: rgb(153 27 27 / 0.8) !important;
}

.tw-ring-red-800\/85 {
  --tw-ring-color: rgb(153 27 27 / 0.85) !important;
}

.tw-ring-red-800\/90 {
  --tw-ring-color: rgb(153 27 27 / 0.9) !important;
}

.tw-ring-red-800\/95 {
  --tw-ring-color: rgb(153 27 27 / 0.95) !important;
}

.tw-ring-red-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(127 29 29 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-900\/0 {
  --tw-ring-color: rgb(127 29 29 / 0) !important;
}

.tw-ring-red-900\/10 {
  --tw-ring-color: rgb(127 29 29 / 0.1) !important;
}

.tw-ring-red-900\/100 {
  --tw-ring-color: rgb(127 29 29 / 1) !important;
}

.tw-ring-red-900\/15 {
  --tw-ring-color: rgb(127 29 29 / 0.15) !important;
}

.tw-ring-red-900\/20 {
  --tw-ring-color: rgb(127 29 29 / 0.2) !important;
}

.tw-ring-red-900\/25 {
  --tw-ring-color: rgb(127 29 29 / 0.25) !important;
}

.tw-ring-red-900\/30 {
  --tw-ring-color: rgb(127 29 29 / 0.3) !important;
}

.tw-ring-red-900\/35 {
  --tw-ring-color: rgb(127 29 29 / 0.35) !important;
}

.tw-ring-red-900\/40 {
  --tw-ring-color: rgb(127 29 29 / 0.4) !important;
}

.tw-ring-red-900\/45 {
  --tw-ring-color: rgb(127 29 29 / 0.45) !important;
}

.tw-ring-red-900\/5 {
  --tw-ring-color: rgb(127 29 29 / 0.05) !important;
}

.tw-ring-red-900\/50 {
  --tw-ring-color: rgb(127 29 29 / 0.5) !important;
}

.tw-ring-red-900\/55 {
  --tw-ring-color: rgb(127 29 29 / 0.55) !important;
}

.tw-ring-red-900\/60 {
  --tw-ring-color: rgb(127 29 29 / 0.6) !important;
}

.tw-ring-red-900\/65 {
  --tw-ring-color: rgb(127 29 29 / 0.65) !important;
}

.tw-ring-red-900\/70 {
  --tw-ring-color: rgb(127 29 29 / 0.7) !important;
}

.tw-ring-red-900\/75 {
  --tw-ring-color: rgb(127 29 29 / 0.75) !important;
}

.tw-ring-red-900\/80 {
  --tw-ring-color: rgb(127 29 29 / 0.8) !important;
}

.tw-ring-red-900\/85 {
  --tw-ring-color: rgb(127 29 29 / 0.85) !important;
}

.tw-ring-red-900\/90 {
  --tw-ring-color: rgb(127 29 29 / 0.9) !important;
}

.tw-ring-red-900\/95 {
  --tw-ring-color: rgb(127 29 29 / 0.95) !important;
}

.tw-ring-red-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(69 10 10 / var(--tw-ring-opacity)) !important;
}

.tw-ring-red-950\/0 {
  --tw-ring-color: rgb(69 10 10 / 0) !important;
}

.tw-ring-red-950\/10 {
  --tw-ring-color: rgb(69 10 10 / 0.1) !important;
}

.tw-ring-red-950\/100 {
  --tw-ring-color: rgb(69 10 10 / 1) !important;
}

.tw-ring-red-950\/15 {
  --tw-ring-color: rgb(69 10 10 / 0.15) !important;
}

.tw-ring-red-950\/20 {
  --tw-ring-color: rgb(69 10 10 / 0.2) !important;
}

.tw-ring-red-950\/25 {
  --tw-ring-color: rgb(69 10 10 / 0.25) !important;
}

.tw-ring-red-950\/30 {
  --tw-ring-color: rgb(69 10 10 / 0.3) !important;
}

.tw-ring-red-950\/35 {
  --tw-ring-color: rgb(69 10 10 / 0.35) !important;
}

.tw-ring-red-950\/40 {
  --tw-ring-color: rgb(69 10 10 / 0.4) !important;
}

.tw-ring-red-950\/45 {
  --tw-ring-color: rgb(69 10 10 / 0.45) !important;
}

.tw-ring-red-950\/5 {
  --tw-ring-color: rgb(69 10 10 / 0.05) !important;
}

.tw-ring-red-950\/50 {
  --tw-ring-color: rgb(69 10 10 / 0.5) !important;
}

.tw-ring-red-950\/55 {
  --tw-ring-color: rgb(69 10 10 / 0.55) !important;
}

.tw-ring-red-950\/60 {
  --tw-ring-color: rgb(69 10 10 / 0.6) !important;
}

.tw-ring-red-950\/65 {
  --tw-ring-color: rgb(69 10 10 / 0.65) !important;
}

.tw-ring-red-950\/70 {
  --tw-ring-color: rgb(69 10 10 / 0.7) !important;
}

.tw-ring-red-950\/75 {
  --tw-ring-color: rgb(69 10 10 / 0.75) !important;
}

.tw-ring-red-950\/80 {
  --tw-ring-color: rgb(69 10 10 / 0.8) !important;
}

.tw-ring-red-950\/85 {
  --tw-ring-color: rgb(69 10 10 / 0.85) !important;
}

.tw-ring-red-950\/90 {
  --tw-ring-color: rgb(69 10 10 / 0.9) !important;
}

.tw-ring-red-950\/95 {
  --tw-ring-color: rgb(69 10 10 / 0.95) !important;
}

.tw-ring-rose-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(255 228 230 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-100\/0 {
  --tw-ring-color: rgb(255 228 230 / 0) !important;
}

.tw-ring-rose-100\/10 {
  --tw-ring-color: rgb(255 228 230 / 0.1) !important;
}

.tw-ring-rose-100\/100 {
  --tw-ring-color: rgb(255 228 230 / 1) !important;
}

.tw-ring-rose-100\/15 {
  --tw-ring-color: rgb(255 228 230 / 0.15) !important;
}

.tw-ring-rose-100\/20 {
  --tw-ring-color: rgb(255 228 230 / 0.2) !important;
}

.tw-ring-rose-100\/25 {
  --tw-ring-color: rgb(255 228 230 / 0.25) !important;
}

.tw-ring-rose-100\/30 {
  --tw-ring-color: rgb(255 228 230 / 0.3) !important;
}

.tw-ring-rose-100\/35 {
  --tw-ring-color: rgb(255 228 230 / 0.35) !important;
}

.tw-ring-rose-100\/40 {
  --tw-ring-color: rgb(255 228 230 / 0.4) !important;
}

.tw-ring-rose-100\/45 {
  --tw-ring-color: rgb(255 228 230 / 0.45) !important;
}

.tw-ring-rose-100\/5 {
  --tw-ring-color: rgb(255 228 230 / 0.05) !important;
}

.tw-ring-rose-100\/50 {
  --tw-ring-color: rgb(255 228 230 / 0.5) !important;
}

.tw-ring-rose-100\/55 {
  --tw-ring-color: rgb(255 228 230 / 0.55) !important;
}

.tw-ring-rose-100\/60 {
  --tw-ring-color: rgb(255 228 230 / 0.6) !important;
}

.tw-ring-rose-100\/65 {
  --tw-ring-color: rgb(255 228 230 / 0.65) !important;
}

.tw-ring-rose-100\/70 {
  --tw-ring-color: rgb(255 228 230 / 0.7) !important;
}

.tw-ring-rose-100\/75 {
  --tw-ring-color: rgb(255 228 230 / 0.75) !important;
}

.tw-ring-rose-100\/80 {
  --tw-ring-color: rgb(255 228 230 / 0.8) !important;
}

.tw-ring-rose-100\/85 {
  --tw-ring-color: rgb(255 228 230 / 0.85) !important;
}

.tw-ring-rose-100\/90 {
  --tw-ring-color: rgb(255 228 230 / 0.9) !important;
}

.tw-ring-rose-100\/95 {
  --tw-ring-color: rgb(255 228 230 / 0.95) !important;
}

.tw-ring-rose-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 205 211 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-200\/0 {
  --tw-ring-color: rgb(254 205 211 / 0) !important;
}

.tw-ring-rose-200\/10 {
  --tw-ring-color: rgb(254 205 211 / 0.1) !important;
}

.tw-ring-rose-200\/100 {
  --tw-ring-color: rgb(254 205 211 / 1) !important;
}

.tw-ring-rose-200\/15 {
  --tw-ring-color: rgb(254 205 211 / 0.15) !important;
}

.tw-ring-rose-200\/20 {
  --tw-ring-color: rgb(254 205 211 / 0.2) !important;
}

.tw-ring-rose-200\/25 {
  --tw-ring-color: rgb(254 205 211 / 0.25) !important;
}

.tw-ring-rose-200\/30 {
  --tw-ring-color: rgb(254 205 211 / 0.3) !important;
}

.tw-ring-rose-200\/35 {
  --tw-ring-color: rgb(254 205 211 / 0.35) !important;
}

.tw-ring-rose-200\/40 {
  --tw-ring-color: rgb(254 205 211 / 0.4) !important;
}

.tw-ring-rose-200\/45 {
  --tw-ring-color: rgb(254 205 211 / 0.45) !important;
}

.tw-ring-rose-200\/5 {
  --tw-ring-color: rgb(254 205 211 / 0.05) !important;
}

.tw-ring-rose-200\/50 {
  --tw-ring-color: rgb(254 205 211 / 0.5) !important;
}

.tw-ring-rose-200\/55 {
  --tw-ring-color: rgb(254 205 211 / 0.55) !important;
}

.tw-ring-rose-200\/60 {
  --tw-ring-color: rgb(254 205 211 / 0.6) !important;
}

.tw-ring-rose-200\/65 {
  --tw-ring-color: rgb(254 205 211 / 0.65) !important;
}

.tw-ring-rose-200\/70 {
  --tw-ring-color: rgb(254 205 211 / 0.7) !important;
}

.tw-ring-rose-200\/75 {
  --tw-ring-color: rgb(254 205 211 / 0.75) !important;
}

.tw-ring-rose-200\/80 {
  --tw-ring-color: rgb(254 205 211 / 0.8) !important;
}

.tw-ring-rose-200\/85 {
  --tw-ring-color: rgb(254 205 211 / 0.85) !important;
}

.tw-ring-rose-200\/90 {
  --tw-ring-color: rgb(254 205 211 / 0.9) !important;
}

.tw-ring-rose-200\/95 {
  --tw-ring-color: rgb(254 205 211 / 0.95) !important;
}

.tw-ring-rose-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(253 164 175 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-300\/0 {
  --tw-ring-color: rgb(253 164 175 / 0) !important;
}

.tw-ring-rose-300\/10 {
  --tw-ring-color: rgb(253 164 175 / 0.1) !important;
}

.tw-ring-rose-300\/100 {
  --tw-ring-color: rgb(253 164 175 / 1) !important;
}

.tw-ring-rose-300\/15 {
  --tw-ring-color: rgb(253 164 175 / 0.15) !important;
}

.tw-ring-rose-300\/20 {
  --tw-ring-color: rgb(253 164 175 / 0.2) !important;
}

.tw-ring-rose-300\/25 {
  --tw-ring-color: rgb(253 164 175 / 0.25) !important;
}

.tw-ring-rose-300\/30 {
  --tw-ring-color: rgb(253 164 175 / 0.3) !important;
}

.tw-ring-rose-300\/35 {
  --tw-ring-color: rgb(253 164 175 / 0.35) !important;
}

.tw-ring-rose-300\/40 {
  --tw-ring-color: rgb(253 164 175 / 0.4) !important;
}

.tw-ring-rose-300\/45 {
  --tw-ring-color: rgb(253 164 175 / 0.45) !important;
}

.tw-ring-rose-300\/5 {
  --tw-ring-color: rgb(253 164 175 / 0.05) !important;
}

.tw-ring-rose-300\/50 {
  --tw-ring-color: rgb(253 164 175 / 0.5) !important;
}

.tw-ring-rose-300\/55 {
  --tw-ring-color: rgb(253 164 175 / 0.55) !important;
}

.tw-ring-rose-300\/60 {
  --tw-ring-color: rgb(253 164 175 / 0.6) !important;
}

.tw-ring-rose-300\/65 {
  --tw-ring-color: rgb(253 164 175 / 0.65) !important;
}

.tw-ring-rose-300\/70 {
  --tw-ring-color: rgb(253 164 175 / 0.7) !important;
}

.tw-ring-rose-300\/75 {
  --tw-ring-color: rgb(253 164 175 / 0.75) !important;
}

.tw-ring-rose-300\/80 {
  --tw-ring-color: rgb(253 164 175 / 0.8) !important;
}

.tw-ring-rose-300\/85 {
  --tw-ring-color: rgb(253 164 175 / 0.85) !important;
}

.tw-ring-rose-300\/90 {
  --tw-ring-color: rgb(253 164 175 / 0.9) !important;
}

.tw-ring-rose-300\/95 {
  --tw-ring-color: rgb(253 164 175 / 0.95) !important;
}

.tw-ring-rose-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(251 113 133 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-400\/0 {
  --tw-ring-color: rgb(251 113 133 / 0) !important;
}

.tw-ring-rose-400\/10 {
  --tw-ring-color: rgb(251 113 133 / 0.1) !important;
}

.tw-ring-rose-400\/100 {
  --tw-ring-color: rgb(251 113 133 / 1) !important;
}

.tw-ring-rose-400\/15 {
  --tw-ring-color: rgb(251 113 133 / 0.15) !important;
}

.tw-ring-rose-400\/20 {
  --tw-ring-color: rgb(251 113 133 / 0.2) !important;
}

.tw-ring-rose-400\/25 {
  --tw-ring-color: rgb(251 113 133 / 0.25) !important;
}

.tw-ring-rose-400\/30 {
  --tw-ring-color: rgb(251 113 133 / 0.3) !important;
}

.tw-ring-rose-400\/35 {
  --tw-ring-color: rgb(251 113 133 / 0.35) !important;
}

.tw-ring-rose-400\/40 {
  --tw-ring-color: rgb(251 113 133 / 0.4) !important;
}

.tw-ring-rose-400\/45 {
  --tw-ring-color: rgb(251 113 133 / 0.45) !important;
}

.tw-ring-rose-400\/5 {
  --tw-ring-color: rgb(251 113 133 / 0.05) !important;
}

.tw-ring-rose-400\/50 {
  --tw-ring-color: rgb(251 113 133 / 0.5) !important;
}

.tw-ring-rose-400\/55 {
  --tw-ring-color: rgb(251 113 133 / 0.55) !important;
}

.tw-ring-rose-400\/60 {
  --tw-ring-color: rgb(251 113 133 / 0.6) !important;
}

.tw-ring-rose-400\/65 {
  --tw-ring-color: rgb(251 113 133 / 0.65) !important;
}

.tw-ring-rose-400\/70 {
  --tw-ring-color: rgb(251 113 133 / 0.7) !important;
}

.tw-ring-rose-400\/75 {
  --tw-ring-color: rgb(251 113 133 / 0.75) !important;
}

.tw-ring-rose-400\/80 {
  --tw-ring-color: rgb(251 113 133 / 0.8) !important;
}

.tw-ring-rose-400\/85 {
  --tw-ring-color: rgb(251 113 133 / 0.85) !important;
}

.tw-ring-rose-400\/90 {
  --tw-ring-color: rgb(251 113 133 / 0.9) !important;
}

.tw-ring-rose-400\/95 {
  --tw-ring-color: rgb(251 113 133 / 0.95) !important;
}

.tw-ring-rose-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(255 241 242 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-50\/0 {
  --tw-ring-color: rgb(255 241 242 / 0) !important;
}

.tw-ring-rose-50\/10 {
  --tw-ring-color: rgb(255 241 242 / 0.1) !important;
}

.tw-ring-rose-50\/100 {
  --tw-ring-color: rgb(255 241 242 / 1) !important;
}

.tw-ring-rose-50\/15 {
  --tw-ring-color: rgb(255 241 242 / 0.15) !important;
}

.tw-ring-rose-50\/20 {
  --tw-ring-color: rgb(255 241 242 / 0.2) !important;
}

.tw-ring-rose-50\/25 {
  --tw-ring-color: rgb(255 241 242 / 0.25) !important;
}

.tw-ring-rose-50\/30 {
  --tw-ring-color: rgb(255 241 242 / 0.3) !important;
}

.tw-ring-rose-50\/35 {
  --tw-ring-color: rgb(255 241 242 / 0.35) !important;
}

.tw-ring-rose-50\/40 {
  --tw-ring-color: rgb(255 241 242 / 0.4) !important;
}

.tw-ring-rose-50\/45 {
  --tw-ring-color: rgb(255 241 242 / 0.45) !important;
}

.tw-ring-rose-50\/5 {
  --tw-ring-color: rgb(255 241 242 / 0.05) !important;
}

.tw-ring-rose-50\/50 {
  --tw-ring-color: rgb(255 241 242 / 0.5) !important;
}

.tw-ring-rose-50\/55 {
  --tw-ring-color: rgb(255 241 242 / 0.55) !important;
}

.tw-ring-rose-50\/60 {
  --tw-ring-color: rgb(255 241 242 / 0.6) !important;
}

.tw-ring-rose-50\/65 {
  --tw-ring-color: rgb(255 241 242 / 0.65) !important;
}

.tw-ring-rose-50\/70 {
  --tw-ring-color: rgb(255 241 242 / 0.7) !important;
}

.tw-ring-rose-50\/75 {
  --tw-ring-color: rgb(255 241 242 / 0.75) !important;
}

.tw-ring-rose-50\/80 {
  --tw-ring-color: rgb(255 241 242 / 0.8) !important;
}

.tw-ring-rose-50\/85 {
  --tw-ring-color: rgb(255 241 242 / 0.85) !important;
}

.tw-ring-rose-50\/90 {
  --tw-ring-color: rgb(255 241 242 / 0.9) !important;
}

.tw-ring-rose-50\/95 {
  --tw-ring-color: rgb(255 241 242 / 0.95) !important;
}

.tw-ring-rose-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(244 63 94 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-500\/0 {
  --tw-ring-color: rgb(244 63 94 / 0) !important;
}

.tw-ring-rose-500\/10 {
  --tw-ring-color: rgb(244 63 94 / 0.1) !important;
}

.tw-ring-rose-500\/100 {
  --tw-ring-color: rgb(244 63 94 / 1) !important;
}

.tw-ring-rose-500\/15 {
  --tw-ring-color: rgb(244 63 94 / 0.15) !important;
}

.tw-ring-rose-500\/20 {
  --tw-ring-color: rgb(244 63 94 / 0.2) !important;
}

.tw-ring-rose-500\/25 {
  --tw-ring-color: rgb(244 63 94 / 0.25) !important;
}

.tw-ring-rose-500\/30 {
  --tw-ring-color: rgb(244 63 94 / 0.3) !important;
}

.tw-ring-rose-500\/35 {
  --tw-ring-color: rgb(244 63 94 / 0.35) !important;
}

.tw-ring-rose-500\/40 {
  --tw-ring-color: rgb(244 63 94 / 0.4) !important;
}

.tw-ring-rose-500\/45 {
  --tw-ring-color: rgb(244 63 94 / 0.45) !important;
}

.tw-ring-rose-500\/5 {
  --tw-ring-color: rgb(244 63 94 / 0.05) !important;
}

.tw-ring-rose-500\/50 {
  --tw-ring-color: rgb(244 63 94 / 0.5) !important;
}

.tw-ring-rose-500\/55 {
  --tw-ring-color: rgb(244 63 94 / 0.55) !important;
}

.tw-ring-rose-500\/60 {
  --tw-ring-color: rgb(244 63 94 / 0.6) !important;
}

.tw-ring-rose-500\/65 {
  --tw-ring-color: rgb(244 63 94 / 0.65) !important;
}

.tw-ring-rose-500\/70 {
  --tw-ring-color: rgb(244 63 94 / 0.7) !important;
}

.tw-ring-rose-500\/75 {
  --tw-ring-color: rgb(244 63 94 / 0.75) !important;
}

.tw-ring-rose-500\/80 {
  --tw-ring-color: rgb(244 63 94 / 0.8) !important;
}

.tw-ring-rose-500\/85 {
  --tw-ring-color: rgb(244 63 94 / 0.85) !important;
}

.tw-ring-rose-500\/90 {
  --tw-ring-color: rgb(244 63 94 / 0.9) !important;
}

.tw-ring-rose-500\/95 {
  --tw-ring-color: rgb(244 63 94 / 0.95) !important;
}

.tw-ring-rose-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(225 29 72 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-600\/0 {
  --tw-ring-color: rgb(225 29 72 / 0) !important;
}

.tw-ring-rose-600\/10 {
  --tw-ring-color: rgb(225 29 72 / 0.1) !important;
}

.tw-ring-rose-600\/100 {
  --tw-ring-color: rgb(225 29 72 / 1) !important;
}

.tw-ring-rose-600\/15 {
  --tw-ring-color: rgb(225 29 72 / 0.15) !important;
}

.tw-ring-rose-600\/20 {
  --tw-ring-color: rgb(225 29 72 / 0.2) !important;
}

.tw-ring-rose-600\/25 {
  --tw-ring-color: rgb(225 29 72 / 0.25) !important;
}

.tw-ring-rose-600\/30 {
  --tw-ring-color: rgb(225 29 72 / 0.3) !important;
}

.tw-ring-rose-600\/35 {
  --tw-ring-color: rgb(225 29 72 / 0.35) !important;
}

.tw-ring-rose-600\/40 {
  --tw-ring-color: rgb(225 29 72 / 0.4) !important;
}

.tw-ring-rose-600\/45 {
  --tw-ring-color: rgb(225 29 72 / 0.45) !important;
}

.tw-ring-rose-600\/5 {
  --tw-ring-color: rgb(225 29 72 / 0.05) !important;
}

.tw-ring-rose-600\/50 {
  --tw-ring-color: rgb(225 29 72 / 0.5) !important;
}

.tw-ring-rose-600\/55 {
  --tw-ring-color: rgb(225 29 72 / 0.55) !important;
}

.tw-ring-rose-600\/60 {
  --tw-ring-color: rgb(225 29 72 / 0.6) !important;
}

.tw-ring-rose-600\/65 {
  --tw-ring-color: rgb(225 29 72 / 0.65) !important;
}

.tw-ring-rose-600\/70 {
  --tw-ring-color: rgb(225 29 72 / 0.7) !important;
}

.tw-ring-rose-600\/75 {
  --tw-ring-color: rgb(225 29 72 / 0.75) !important;
}

.tw-ring-rose-600\/80 {
  --tw-ring-color: rgb(225 29 72 / 0.8) !important;
}

.tw-ring-rose-600\/85 {
  --tw-ring-color: rgb(225 29 72 / 0.85) !important;
}

.tw-ring-rose-600\/90 {
  --tw-ring-color: rgb(225 29 72 / 0.9) !important;
}

.tw-ring-rose-600\/95 {
  --tw-ring-color: rgb(225 29 72 / 0.95) !important;
}

.tw-ring-rose-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(190 18 60 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-700\/0 {
  --tw-ring-color: rgb(190 18 60 / 0) !important;
}

.tw-ring-rose-700\/10 {
  --tw-ring-color: rgb(190 18 60 / 0.1) !important;
}

.tw-ring-rose-700\/100 {
  --tw-ring-color: rgb(190 18 60 / 1) !important;
}

.tw-ring-rose-700\/15 {
  --tw-ring-color: rgb(190 18 60 / 0.15) !important;
}

.tw-ring-rose-700\/20 {
  --tw-ring-color: rgb(190 18 60 / 0.2) !important;
}

.tw-ring-rose-700\/25 {
  --tw-ring-color: rgb(190 18 60 / 0.25) !important;
}

.tw-ring-rose-700\/30 {
  --tw-ring-color: rgb(190 18 60 / 0.3) !important;
}

.tw-ring-rose-700\/35 {
  --tw-ring-color: rgb(190 18 60 / 0.35) !important;
}

.tw-ring-rose-700\/40 {
  --tw-ring-color: rgb(190 18 60 / 0.4) !important;
}

.tw-ring-rose-700\/45 {
  --tw-ring-color: rgb(190 18 60 / 0.45) !important;
}

.tw-ring-rose-700\/5 {
  --tw-ring-color: rgb(190 18 60 / 0.05) !important;
}

.tw-ring-rose-700\/50 {
  --tw-ring-color: rgb(190 18 60 / 0.5) !important;
}

.tw-ring-rose-700\/55 {
  --tw-ring-color: rgb(190 18 60 / 0.55) !important;
}

.tw-ring-rose-700\/60 {
  --tw-ring-color: rgb(190 18 60 / 0.6) !important;
}

.tw-ring-rose-700\/65 {
  --tw-ring-color: rgb(190 18 60 / 0.65) !important;
}

.tw-ring-rose-700\/70 {
  --tw-ring-color: rgb(190 18 60 / 0.7) !important;
}

.tw-ring-rose-700\/75 {
  --tw-ring-color: rgb(190 18 60 / 0.75) !important;
}

.tw-ring-rose-700\/80 {
  --tw-ring-color: rgb(190 18 60 / 0.8) !important;
}

.tw-ring-rose-700\/85 {
  --tw-ring-color: rgb(190 18 60 / 0.85) !important;
}

.tw-ring-rose-700\/90 {
  --tw-ring-color: rgb(190 18 60 / 0.9) !important;
}

.tw-ring-rose-700\/95 {
  --tw-ring-color: rgb(190 18 60 / 0.95) !important;
}

.tw-ring-rose-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(159 18 57 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-800\/0 {
  --tw-ring-color: rgb(159 18 57 / 0) !important;
}

.tw-ring-rose-800\/10 {
  --tw-ring-color: rgb(159 18 57 / 0.1) !important;
}

.tw-ring-rose-800\/100 {
  --tw-ring-color: rgb(159 18 57 / 1) !important;
}

.tw-ring-rose-800\/15 {
  --tw-ring-color: rgb(159 18 57 / 0.15) !important;
}

.tw-ring-rose-800\/20 {
  --tw-ring-color: rgb(159 18 57 / 0.2) !important;
}

.tw-ring-rose-800\/25 {
  --tw-ring-color: rgb(159 18 57 / 0.25) !important;
}

.tw-ring-rose-800\/30 {
  --tw-ring-color: rgb(159 18 57 / 0.3) !important;
}

.tw-ring-rose-800\/35 {
  --tw-ring-color: rgb(159 18 57 / 0.35) !important;
}

.tw-ring-rose-800\/40 {
  --tw-ring-color: rgb(159 18 57 / 0.4) !important;
}

.tw-ring-rose-800\/45 {
  --tw-ring-color: rgb(159 18 57 / 0.45) !important;
}

.tw-ring-rose-800\/5 {
  --tw-ring-color: rgb(159 18 57 / 0.05) !important;
}

.tw-ring-rose-800\/50 {
  --tw-ring-color: rgb(159 18 57 / 0.5) !important;
}

.tw-ring-rose-800\/55 {
  --tw-ring-color: rgb(159 18 57 / 0.55) !important;
}

.tw-ring-rose-800\/60 {
  --tw-ring-color: rgb(159 18 57 / 0.6) !important;
}

.tw-ring-rose-800\/65 {
  --tw-ring-color: rgb(159 18 57 / 0.65) !important;
}

.tw-ring-rose-800\/70 {
  --tw-ring-color: rgb(159 18 57 / 0.7) !important;
}

.tw-ring-rose-800\/75 {
  --tw-ring-color: rgb(159 18 57 / 0.75) !important;
}

.tw-ring-rose-800\/80 {
  --tw-ring-color: rgb(159 18 57 / 0.8) !important;
}

.tw-ring-rose-800\/85 {
  --tw-ring-color: rgb(159 18 57 / 0.85) !important;
}

.tw-ring-rose-800\/90 {
  --tw-ring-color: rgb(159 18 57 / 0.9) !important;
}

.tw-ring-rose-800\/95 {
  --tw-ring-color: rgb(159 18 57 / 0.95) !important;
}

.tw-ring-rose-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(136 19 55 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-900\/0 {
  --tw-ring-color: rgb(136 19 55 / 0) !important;
}

.tw-ring-rose-900\/10 {
  --tw-ring-color: rgb(136 19 55 / 0.1) !important;
}

.tw-ring-rose-900\/100 {
  --tw-ring-color: rgb(136 19 55 / 1) !important;
}

.tw-ring-rose-900\/15 {
  --tw-ring-color: rgb(136 19 55 / 0.15) !important;
}

.tw-ring-rose-900\/20 {
  --tw-ring-color: rgb(136 19 55 / 0.2) !important;
}

.tw-ring-rose-900\/25 {
  --tw-ring-color: rgb(136 19 55 / 0.25) !important;
}

.tw-ring-rose-900\/30 {
  --tw-ring-color: rgb(136 19 55 / 0.3) !important;
}

.tw-ring-rose-900\/35 {
  --tw-ring-color: rgb(136 19 55 / 0.35) !important;
}

.tw-ring-rose-900\/40 {
  --tw-ring-color: rgb(136 19 55 / 0.4) !important;
}

.tw-ring-rose-900\/45 {
  --tw-ring-color: rgb(136 19 55 / 0.45) !important;
}

.tw-ring-rose-900\/5 {
  --tw-ring-color: rgb(136 19 55 / 0.05) !important;
}

.tw-ring-rose-900\/50 {
  --tw-ring-color: rgb(136 19 55 / 0.5) !important;
}

.tw-ring-rose-900\/55 {
  --tw-ring-color: rgb(136 19 55 / 0.55) !important;
}

.tw-ring-rose-900\/60 {
  --tw-ring-color: rgb(136 19 55 / 0.6) !important;
}

.tw-ring-rose-900\/65 {
  --tw-ring-color: rgb(136 19 55 / 0.65) !important;
}

.tw-ring-rose-900\/70 {
  --tw-ring-color: rgb(136 19 55 / 0.7) !important;
}

.tw-ring-rose-900\/75 {
  --tw-ring-color: rgb(136 19 55 / 0.75) !important;
}

.tw-ring-rose-900\/80 {
  --tw-ring-color: rgb(136 19 55 / 0.8) !important;
}

.tw-ring-rose-900\/85 {
  --tw-ring-color: rgb(136 19 55 / 0.85) !important;
}

.tw-ring-rose-900\/90 {
  --tw-ring-color: rgb(136 19 55 / 0.9) !important;
}

.tw-ring-rose-900\/95 {
  --tw-ring-color: rgb(136 19 55 / 0.95) !important;
}

.tw-ring-rose-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(76 5 25 / var(--tw-ring-opacity)) !important;
}

.tw-ring-rose-950\/0 {
  --tw-ring-color: rgb(76 5 25 / 0) !important;
}

.tw-ring-rose-950\/10 {
  --tw-ring-color: rgb(76 5 25 / 0.1) !important;
}

.tw-ring-rose-950\/100 {
  --tw-ring-color: rgb(76 5 25 / 1) !important;
}

.tw-ring-rose-950\/15 {
  --tw-ring-color: rgb(76 5 25 / 0.15) !important;
}

.tw-ring-rose-950\/20 {
  --tw-ring-color: rgb(76 5 25 / 0.2) !important;
}

.tw-ring-rose-950\/25 {
  --tw-ring-color: rgb(76 5 25 / 0.25) !important;
}

.tw-ring-rose-950\/30 {
  --tw-ring-color: rgb(76 5 25 / 0.3) !important;
}

.tw-ring-rose-950\/35 {
  --tw-ring-color: rgb(76 5 25 / 0.35) !important;
}

.tw-ring-rose-950\/40 {
  --tw-ring-color: rgb(76 5 25 / 0.4) !important;
}

.tw-ring-rose-950\/45 {
  --tw-ring-color: rgb(76 5 25 / 0.45) !important;
}

.tw-ring-rose-950\/5 {
  --tw-ring-color: rgb(76 5 25 / 0.05) !important;
}

.tw-ring-rose-950\/50 {
  --tw-ring-color: rgb(76 5 25 / 0.5) !important;
}

.tw-ring-rose-950\/55 {
  --tw-ring-color: rgb(76 5 25 / 0.55) !important;
}

.tw-ring-rose-950\/60 {
  --tw-ring-color: rgb(76 5 25 / 0.6) !important;
}

.tw-ring-rose-950\/65 {
  --tw-ring-color: rgb(76 5 25 / 0.65) !important;
}

.tw-ring-rose-950\/70 {
  --tw-ring-color: rgb(76 5 25 / 0.7) !important;
}

.tw-ring-rose-950\/75 {
  --tw-ring-color: rgb(76 5 25 / 0.75) !important;
}

.tw-ring-rose-950\/80 {
  --tw-ring-color: rgb(76 5 25 / 0.8) !important;
}

.tw-ring-rose-950\/85 {
  --tw-ring-color: rgb(76 5 25 / 0.85) !important;
}

.tw-ring-rose-950\/90 {
  --tw-ring-color: rgb(76 5 25 / 0.9) !important;
}

.tw-ring-rose-950\/95 {
  --tw-ring-color: rgb(76 5 25 / 0.95) !important;
}

.tw-ring-sky-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(224 242 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-100\/0 {
  --tw-ring-color: rgb(224 242 254 / 0) !important;
}

.tw-ring-sky-100\/10 {
  --tw-ring-color: rgb(224 242 254 / 0.1) !important;
}

.tw-ring-sky-100\/100 {
  --tw-ring-color: rgb(224 242 254 / 1) !important;
}

.tw-ring-sky-100\/15 {
  --tw-ring-color: rgb(224 242 254 / 0.15) !important;
}

.tw-ring-sky-100\/20 {
  --tw-ring-color: rgb(224 242 254 / 0.2) !important;
}

.tw-ring-sky-100\/25 {
  --tw-ring-color: rgb(224 242 254 / 0.25) !important;
}

.tw-ring-sky-100\/30 {
  --tw-ring-color: rgb(224 242 254 / 0.3) !important;
}

.tw-ring-sky-100\/35 {
  --tw-ring-color: rgb(224 242 254 / 0.35) !important;
}

.tw-ring-sky-100\/40 {
  --tw-ring-color: rgb(224 242 254 / 0.4) !important;
}

.tw-ring-sky-100\/45 {
  --tw-ring-color: rgb(224 242 254 / 0.45) !important;
}

.tw-ring-sky-100\/5 {
  --tw-ring-color: rgb(224 242 254 / 0.05) !important;
}

.tw-ring-sky-100\/50 {
  --tw-ring-color: rgb(224 242 254 / 0.5) !important;
}

.tw-ring-sky-100\/55 {
  --tw-ring-color: rgb(224 242 254 / 0.55) !important;
}

.tw-ring-sky-100\/60 {
  --tw-ring-color: rgb(224 242 254 / 0.6) !important;
}

.tw-ring-sky-100\/65 {
  --tw-ring-color: rgb(224 242 254 / 0.65) !important;
}

.tw-ring-sky-100\/70 {
  --tw-ring-color: rgb(224 242 254 / 0.7) !important;
}

.tw-ring-sky-100\/75 {
  --tw-ring-color: rgb(224 242 254 / 0.75) !important;
}

.tw-ring-sky-100\/80 {
  --tw-ring-color: rgb(224 242 254 / 0.8) !important;
}

.tw-ring-sky-100\/85 {
  --tw-ring-color: rgb(224 242 254 / 0.85) !important;
}

.tw-ring-sky-100\/90 {
  --tw-ring-color: rgb(224 242 254 / 0.9) !important;
}

.tw-ring-sky-100\/95 {
  --tw-ring-color: rgb(224 242 254 / 0.95) !important;
}

.tw-ring-sky-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(186 230 253 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-200\/0 {
  --tw-ring-color: rgb(186 230 253 / 0) !important;
}

.tw-ring-sky-200\/10 {
  --tw-ring-color: rgb(186 230 253 / 0.1) !important;
}

.tw-ring-sky-200\/100 {
  --tw-ring-color: rgb(186 230 253 / 1) !important;
}

.tw-ring-sky-200\/15 {
  --tw-ring-color: rgb(186 230 253 / 0.15) !important;
}

.tw-ring-sky-200\/20 {
  --tw-ring-color: rgb(186 230 253 / 0.2) !important;
}

.tw-ring-sky-200\/25 {
  --tw-ring-color: rgb(186 230 253 / 0.25) !important;
}

.tw-ring-sky-200\/30 {
  --tw-ring-color: rgb(186 230 253 / 0.3) !important;
}

.tw-ring-sky-200\/35 {
  --tw-ring-color: rgb(186 230 253 / 0.35) !important;
}

.tw-ring-sky-200\/40 {
  --tw-ring-color: rgb(186 230 253 / 0.4) !important;
}

.tw-ring-sky-200\/45 {
  --tw-ring-color: rgb(186 230 253 / 0.45) !important;
}

.tw-ring-sky-200\/5 {
  --tw-ring-color: rgb(186 230 253 / 0.05) !important;
}

.tw-ring-sky-200\/50 {
  --tw-ring-color: rgb(186 230 253 / 0.5) !important;
}

.tw-ring-sky-200\/55 {
  --tw-ring-color: rgb(186 230 253 / 0.55) !important;
}

.tw-ring-sky-200\/60 {
  --tw-ring-color: rgb(186 230 253 / 0.6) !important;
}

.tw-ring-sky-200\/65 {
  --tw-ring-color: rgb(186 230 253 / 0.65) !important;
}

.tw-ring-sky-200\/70 {
  --tw-ring-color: rgb(186 230 253 / 0.7) !important;
}

.tw-ring-sky-200\/75 {
  --tw-ring-color: rgb(186 230 253 / 0.75) !important;
}

.tw-ring-sky-200\/80 {
  --tw-ring-color: rgb(186 230 253 / 0.8) !important;
}

.tw-ring-sky-200\/85 {
  --tw-ring-color: rgb(186 230 253 / 0.85) !important;
}

.tw-ring-sky-200\/90 {
  --tw-ring-color: rgb(186 230 253 / 0.9) !important;
}

.tw-ring-sky-200\/95 {
  --tw-ring-color: rgb(186 230 253 / 0.95) !important;
}

.tw-ring-sky-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(125 211 252 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-300\/0 {
  --tw-ring-color: rgb(125 211 252 / 0) !important;
}

.tw-ring-sky-300\/10 {
  --tw-ring-color: rgb(125 211 252 / 0.1) !important;
}

.tw-ring-sky-300\/100 {
  --tw-ring-color: rgb(125 211 252 / 1) !important;
}

.tw-ring-sky-300\/15 {
  --tw-ring-color: rgb(125 211 252 / 0.15) !important;
}

.tw-ring-sky-300\/20 {
  --tw-ring-color: rgb(125 211 252 / 0.2) !important;
}

.tw-ring-sky-300\/25 {
  --tw-ring-color: rgb(125 211 252 / 0.25) !important;
}

.tw-ring-sky-300\/30 {
  --tw-ring-color: rgb(125 211 252 / 0.3) !important;
}

.tw-ring-sky-300\/35 {
  --tw-ring-color: rgb(125 211 252 / 0.35) !important;
}

.tw-ring-sky-300\/40 {
  --tw-ring-color: rgb(125 211 252 / 0.4) !important;
}

.tw-ring-sky-300\/45 {
  --tw-ring-color: rgb(125 211 252 / 0.45) !important;
}

.tw-ring-sky-300\/5 {
  --tw-ring-color: rgb(125 211 252 / 0.05) !important;
}

.tw-ring-sky-300\/50 {
  --tw-ring-color: rgb(125 211 252 / 0.5) !important;
}

.tw-ring-sky-300\/55 {
  --tw-ring-color: rgb(125 211 252 / 0.55) !important;
}

.tw-ring-sky-300\/60 {
  --tw-ring-color: rgb(125 211 252 / 0.6) !important;
}

.tw-ring-sky-300\/65 {
  --tw-ring-color: rgb(125 211 252 / 0.65) !important;
}

.tw-ring-sky-300\/70 {
  --tw-ring-color: rgb(125 211 252 / 0.7) !important;
}

.tw-ring-sky-300\/75 {
  --tw-ring-color: rgb(125 211 252 / 0.75) !important;
}

.tw-ring-sky-300\/80 {
  --tw-ring-color: rgb(125 211 252 / 0.8) !important;
}

.tw-ring-sky-300\/85 {
  --tw-ring-color: rgb(125 211 252 / 0.85) !important;
}

.tw-ring-sky-300\/90 {
  --tw-ring-color: rgb(125 211 252 / 0.9) !important;
}

.tw-ring-sky-300\/95 {
  --tw-ring-color: rgb(125 211 252 / 0.95) !important;
}

.tw-ring-sky-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(56 189 248 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-400\/0 {
  --tw-ring-color: rgb(56 189 248 / 0) !important;
}

.tw-ring-sky-400\/10 {
  --tw-ring-color: rgb(56 189 248 / 0.1) !important;
}

.tw-ring-sky-400\/100 {
  --tw-ring-color: rgb(56 189 248 / 1) !important;
}

.tw-ring-sky-400\/15 {
  --tw-ring-color: rgb(56 189 248 / 0.15) !important;
}

.tw-ring-sky-400\/20 {
  --tw-ring-color: rgb(56 189 248 / 0.2) !important;
}

.tw-ring-sky-400\/25 {
  --tw-ring-color: rgb(56 189 248 / 0.25) !important;
}

.tw-ring-sky-400\/30 {
  --tw-ring-color: rgb(56 189 248 / 0.3) !important;
}

.tw-ring-sky-400\/35 {
  --tw-ring-color: rgb(56 189 248 / 0.35) !important;
}

.tw-ring-sky-400\/40 {
  --tw-ring-color: rgb(56 189 248 / 0.4) !important;
}

.tw-ring-sky-400\/45 {
  --tw-ring-color: rgb(56 189 248 / 0.45) !important;
}

.tw-ring-sky-400\/5 {
  --tw-ring-color: rgb(56 189 248 / 0.05) !important;
}

.tw-ring-sky-400\/50 {
  --tw-ring-color: rgb(56 189 248 / 0.5) !important;
}

.tw-ring-sky-400\/55 {
  --tw-ring-color: rgb(56 189 248 / 0.55) !important;
}

.tw-ring-sky-400\/60 {
  --tw-ring-color: rgb(56 189 248 / 0.6) !important;
}

.tw-ring-sky-400\/65 {
  --tw-ring-color: rgb(56 189 248 / 0.65) !important;
}

.tw-ring-sky-400\/70 {
  --tw-ring-color: rgb(56 189 248 / 0.7) !important;
}

.tw-ring-sky-400\/75 {
  --tw-ring-color: rgb(56 189 248 / 0.75) !important;
}

.tw-ring-sky-400\/80 {
  --tw-ring-color: rgb(56 189 248 / 0.8) !important;
}

.tw-ring-sky-400\/85 {
  --tw-ring-color: rgb(56 189 248 / 0.85) !important;
}

.tw-ring-sky-400\/90 {
  --tw-ring-color: rgb(56 189 248 / 0.9) !important;
}

.tw-ring-sky-400\/95 {
  --tw-ring-color: rgb(56 189 248 / 0.95) !important;
}

.tw-ring-sky-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(240 249 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-50\/0 {
  --tw-ring-color: rgb(240 249 255 / 0) !important;
}

.tw-ring-sky-50\/10 {
  --tw-ring-color: rgb(240 249 255 / 0.1) !important;
}

.tw-ring-sky-50\/100 {
  --tw-ring-color: rgb(240 249 255 / 1) !important;
}

.tw-ring-sky-50\/15 {
  --tw-ring-color: rgb(240 249 255 / 0.15) !important;
}

.tw-ring-sky-50\/20 {
  --tw-ring-color: rgb(240 249 255 / 0.2) !important;
}

.tw-ring-sky-50\/25 {
  --tw-ring-color: rgb(240 249 255 / 0.25) !important;
}

.tw-ring-sky-50\/30 {
  --tw-ring-color: rgb(240 249 255 / 0.3) !important;
}

.tw-ring-sky-50\/35 {
  --tw-ring-color: rgb(240 249 255 / 0.35) !important;
}

.tw-ring-sky-50\/40 {
  --tw-ring-color: rgb(240 249 255 / 0.4) !important;
}

.tw-ring-sky-50\/45 {
  --tw-ring-color: rgb(240 249 255 / 0.45) !important;
}

.tw-ring-sky-50\/5 {
  --tw-ring-color: rgb(240 249 255 / 0.05) !important;
}

.tw-ring-sky-50\/50 {
  --tw-ring-color: rgb(240 249 255 / 0.5) !important;
}

.tw-ring-sky-50\/55 {
  --tw-ring-color: rgb(240 249 255 / 0.55) !important;
}

.tw-ring-sky-50\/60 {
  --tw-ring-color: rgb(240 249 255 / 0.6) !important;
}

.tw-ring-sky-50\/65 {
  --tw-ring-color: rgb(240 249 255 / 0.65) !important;
}

.tw-ring-sky-50\/70 {
  --tw-ring-color: rgb(240 249 255 / 0.7) !important;
}

.tw-ring-sky-50\/75 {
  --tw-ring-color: rgb(240 249 255 / 0.75) !important;
}

.tw-ring-sky-50\/80 {
  --tw-ring-color: rgb(240 249 255 / 0.8) !important;
}

.tw-ring-sky-50\/85 {
  --tw-ring-color: rgb(240 249 255 / 0.85) !important;
}

.tw-ring-sky-50\/90 {
  --tw-ring-color: rgb(240 249 255 / 0.9) !important;
}

.tw-ring-sky-50\/95 {
  --tw-ring-color: rgb(240 249 255 / 0.95) !important;
}

.tw-ring-sky-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(14 165 233 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-500\/0 {
  --tw-ring-color: rgb(14 165 233 / 0) !important;
}

.tw-ring-sky-500\/10 {
  --tw-ring-color: rgb(14 165 233 / 0.1) !important;
}

.tw-ring-sky-500\/100 {
  --tw-ring-color: rgb(14 165 233 / 1) !important;
}

.tw-ring-sky-500\/15 {
  --tw-ring-color: rgb(14 165 233 / 0.15) !important;
}

.tw-ring-sky-500\/20 {
  --tw-ring-color: rgb(14 165 233 / 0.2) !important;
}

.tw-ring-sky-500\/25 {
  --tw-ring-color: rgb(14 165 233 / 0.25) !important;
}

.tw-ring-sky-500\/30 {
  --tw-ring-color: rgb(14 165 233 / 0.3) !important;
}

.tw-ring-sky-500\/35 {
  --tw-ring-color: rgb(14 165 233 / 0.35) !important;
}

.tw-ring-sky-500\/40 {
  --tw-ring-color: rgb(14 165 233 / 0.4) !important;
}

.tw-ring-sky-500\/45 {
  --tw-ring-color: rgb(14 165 233 / 0.45) !important;
}

.tw-ring-sky-500\/5 {
  --tw-ring-color: rgb(14 165 233 / 0.05) !important;
}

.tw-ring-sky-500\/50 {
  --tw-ring-color: rgb(14 165 233 / 0.5) !important;
}

.tw-ring-sky-500\/55 {
  --tw-ring-color: rgb(14 165 233 / 0.55) !important;
}

.tw-ring-sky-500\/60 {
  --tw-ring-color: rgb(14 165 233 / 0.6) !important;
}

.tw-ring-sky-500\/65 {
  --tw-ring-color: rgb(14 165 233 / 0.65) !important;
}

.tw-ring-sky-500\/70 {
  --tw-ring-color: rgb(14 165 233 / 0.7) !important;
}

.tw-ring-sky-500\/75 {
  --tw-ring-color: rgb(14 165 233 / 0.75) !important;
}

.tw-ring-sky-500\/80 {
  --tw-ring-color: rgb(14 165 233 / 0.8) !important;
}

.tw-ring-sky-500\/85 {
  --tw-ring-color: rgb(14 165 233 / 0.85) !important;
}

.tw-ring-sky-500\/90 {
  --tw-ring-color: rgb(14 165 233 / 0.9) !important;
}

.tw-ring-sky-500\/95 {
  --tw-ring-color: rgb(14 165 233 / 0.95) !important;
}

.tw-ring-sky-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(2 132 199 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-600\/0 {
  --tw-ring-color: rgb(2 132 199 / 0) !important;
}

.tw-ring-sky-600\/10 {
  --tw-ring-color: rgb(2 132 199 / 0.1) !important;
}

.tw-ring-sky-600\/100 {
  --tw-ring-color: rgb(2 132 199 / 1) !important;
}

.tw-ring-sky-600\/15 {
  --tw-ring-color: rgb(2 132 199 / 0.15) !important;
}

.tw-ring-sky-600\/20 {
  --tw-ring-color: rgb(2 132 199 / 0.2) !important;
}

.tw-ring-sky-600\/25 {
  --tw-ring-color: rgb(2 132 199 / 0.25) !important;
}

.tw-ring-sky-600\/30 {
  --tw-ring-color: rgb(2 132 199 / 0.3) !important;
}

.tw-ring-sky-600\/35 {
  --tw-ring-color: rgb(2 132 199 / 0.35) !important;
}

.tw-ring-sky-600\/40 {
  --tw-ring-color: rgb(2 132 199 / 0.4) !important;
}

.tw-ring-sky-600\/45 {
  --tw-ring-color: rgb(2 132 199 / 0.45) !important;
}

.tw-ring-sky-600\/5 {
  --tw-ring-color: rgb(2 132 199 / 0.05) !important;
}

.tw-ring-sky-600\/50 {
  --tw-ring-color: rgb(2 132 199 / 0.5) !important;
}

.tw-ring-sky-600\/55 {
  --tw-ring-color: rgb(2 132 199 / 0.55) !important;
}

.tw-ring-sky-600\/60 {
  --tw-ring-color: rgb(2 132 199 / 0.6) !important;
}

.tw-ring-sky-600\/65 {
  --tw-ring-color: rgb(2 132 199 / 0.65) !important;
}

.tw-ring-sky-600\/70 {
  --tw-ring-color: rgb(2 132 199 / 0.7) !important;
}

.tw-ring-sky-600\/75 {
  --tw-ring-color: rgb(2 132 199 / 0.75) !important;
}

.tw-ring-sky-600\/80 {
  --tw-ring-color: rgb(2 132 199 / 0.8) !important;
}

.tw-ring-sky-600\/85 {
  --tw-ring-color: rgb(2 132 199 / 0.85) !important;
}

.tw-ring-sky-600\/90 {
  --tw-ring-color: rgb(2 132 199 / 0.9) !important;
}

.tw-ring-sky-600\/95 {
  --tw-ring-color: rgb(2 132 199 / 0.95) !important;
}

.tw-ring-sky-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(3 105 161 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-700\/0 {
  --tw-ring-color: rgb(3 105 161 / 0) !important;
}

.tw-ring-sky-700\/10 {
  --tw-ring-color: rgb(3 105 161 / 0.1) !important;
}

.tw-ring-sky-700\/100 {
  --tw-ring-color: rgb(3 105 161 / 1) !important;
}

.tw-ring-sky-700\/15 {
  --tw-ring-color: rgb(3 105 161 / 0.15) !important;
}

.tw-ring-sky-700\/20 {
  --tw-ring-color: rgb(3 105 161 / 0.2) !important;
}

.tw-ring-sky-700\/25 {
  --tw-ring-color: rgb(3 105 161 / 0.25) !important;
}

.tw-ring-sky-700\/30 {
  --tw-ring-color: rgb(3 105 161 / 0.3) !important;
}

.tw-ring-sky-700\/35 {
  --tw-ring-color: rgb(3 105 161 / 0.35) !important;
}

.tw-ring-sky-700\/40 {
  --tw-ring-color: rgb(3 105 161 / 0.4) !important;
}

.tw-ring-sky-700\/45 {
  --tw-ring-color: rgb(3 105 161 / 0.45) !important;
}

.tw-ring-sky-700\/5 {
  --tw-ring-color: rgb(3 105 161 / 0.05) !important;
}

.tw-ring-sky-700\/50 {
  --tw-ring-color: rgb(3 105 161 / 0.5) !important;
}

.tw-ring-sky-700\/55 {
  --tw-ring-color: rgb(3 105 161 / 0.55) !important;
}

.tw-ring-sky-700\/60 {
  --tw-ring-color: rgb(3 105 161 / 0.6) !important;
}

.tw-ring-sky-700\/65 {
  --tw-ring-color: rgb(3 105 161 / 0.65) !important;
}

.tw-ring-sky-700\/70 {
  --tw-ring-color: rgb(3 105 161 / 0.7) !important;
}

.tw-ring-sky-700\/75 {
  --tw-ring-color: rgb(3 105 161 / 0.75) !important;
}

.tw-ring-sky-700\/80 {
  --tw-ring-color: rgb(3 105 161 / 0.8) !important;
}

.tw-ring-sky-700\/85 {
  --tw-ring-color: rgb(3 105 161 / 0.85) !important;
}

.tw-ring-sky-700\/90 {
  --tw-ring-color: rgb(3 105 161 / 0.9) !important;
}

.tw-ring-sky-700\/95 {
  --tw-ring-color: rgb(3 105 161 / 0.95) !important;
}

.tw-ring-sky-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(7 89 133 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-800\/0 {
  --tw-ring-color: rgb(7 89 133 / 0) !important;
}

.tw-ring-sky-800\/10 {
  --tw-ring-color: rgb(7 89 133 / 0.1) !important;
}

.tw-ring-sky-800\/100 {
  --tw-ring-color: rgb(7 89 133 / 1) !important;
}

.tw-ring-sky-800\/15 {
  --tw-ring-color: rgb(7 89 133 / 0.15) !important;
}

.tw-ring-sky-800\/20 {
  --tw-ring-color: rgb(7 89 133 / 0.2) !important;
}

.tw-ring-sky-800\/25 {
  --tw-ring-color: rgb(7 89 133 / 0.25) !important;
}

.tw-ring-sky-800\/30 {
  --tw-ring-color: rgb(7 89 133 / 0.3) !important;
}

.tw-ring-sky-800\/35 {
  --tw-ring-color: rgb(7 89 133 / 0.35) !important;
}

.tw-ring-sky-800\/40 {
  --tw-ring-color: rgb(7 89 133 / 0.4) !important;
}

.tw-ring-sky-800\/45 {
  --tw-ring-color: rgb(7 89 133 / 0.45) !important;
}

.tw-ring-sky-800\/5 {
  --tw-ring-color: rgb(7 89 133 / 0.05) !important;
}

.tw-ring-sky-800\/50 {
  --tw-ring-color: rgb(7 89 133 / 0.5) !important;
}

.tw-ring-sky-800\/55 {
  --tw-ring-color: rgb(7 89 133 / 0.55) !important;
}

.tw-ring-sky-800\/60 {
  --tw-ring-color: rgb(7 89 133 / 0.6) !important;
}

.tw-ring-sky-800\/65 {
  --tw-ring-color: rgb(7 89 133 / 0.65) !important;
}

.tw-ring-sky-800\/70 {
  --tw-ring-color: rgb(7 89 133 / 0.7) !important;
}

.tw-ring-sky-800\/75 {
  --tw-ring-color: rgb(7 89 133 / 0.75) !important;
}

.tw-ring-sky-800\/80 {
  --tw-ring-color: rgb(7 89 133 / 0.8) !important;
}

.tw-ring-sky-800\/85 {
  --tw-ring-color: rgb(7 89 133 / 0.85) !important;
}

.tw-ring-sky-800\/90 {
  --tw-ring-color: rgb(7 89 133 / 0.9) !important;
}

.tw-ring-sky-800\/95 {
  --tw-ring-color: rgb(7 89 133 / 0.95) !important;
}

.tw-ring-sky-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(12 74 110 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-900\/0 {
  --tw-ring-color: rgb(12 74 110 / 0) !important;
}

.tw-ring-sky-900\/10 {
  --tw-ring-color: rgb(12 74 110 / 0.1) !important;
}

.tw-ring-sky-900\/100 {
  --tw-ring-color: rgb(12 74 110 / 1) !important;
}

.tw-ring-sky-900\/15 {
  --tw-ring-color: rgb(12 74 110 / 0.15) !important;
}

.tw-ring-sky-900\/20 {
  --tw-ring-color: rgb(12 74 110 / 0.2) !important;
}

.tw-ring-sky-900\/25 {
  --tw-ring-color: rgb(12 74 110 / 0.25) !important;
}

.tw-ring-sky-900\/30 {
  --tw-ring-color: rgb(12 74 110 / 0.3) !important;
}

.tw-ring-sky-900\/35 {
  --tw-ring-color: rgb(12 74 110 / 0.35) !important;
}

.tw-ring-sky-900\/40 {
  --tw-ring-color: rgb(12 74 110 / 0.4) !important;
}

.tw-ring-sky-900\/45 {
  --tw-ring-color: rgb(12 74 110 / 0.45) !important;
}

.tw-ring-sky-900\/5 {
  --tw-ring-color: rgb(12 74 110 / 0.05) !important;
}

.tw-ring-sky-900\/50 {
  --tw-ring-color: rgb(12 74 110 / 0.5) !important;
}

.tw-ring-sky-900\/55 {
  --tw-ring-color: rgb(12 74 110 / 0.55) !important;
}

.tw-ring-sky-900\/60 {
  --tw-ring-color: rgb(12 74 110 / 0.6) !important;
}

.tw-ring-sky-900\/65 {
  --tw-ring-color: rgb(12 74 110 / 0.65) !important;
}

.tw-ring-sky-900\/70 {
  --tw-ring-color: rgb(12 74 110 / 0.7) !important;
}

.tw-ring-sky-900\/75 {
  --tw-ring-color: rgb(12 74 110 / 0.75) !important;
}

.tw-ring-sky-900\/80 {
  --tw-ring-color: rgb(12 74 110 / 0.8) !important;
}

.tw-ring-sky-900\/85 {
  --tw-ring-color: rgb(12 74 110 / 0.85) !important;
}

.tw-ring-sky-900\/90 {
  --tw-ring-color: rgb(12 74 110 / 0.9) !important;
}

.tw-ring-sky-900\/95 {
  --tw-ring-color: rgb(12 74 110 / 0.95) !important;
}

.tw-ring-sky-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(8 47 73 / var(--tw-ring-opacity)) !important;
}

.tw-ring-sky-950\/0 {
  --tw-ring-color: rgb(8 47 73 / 0) !important;
}

.tw-ring-sky-950\/10 {
  --tw-ring-color: rgb(8 47 73 / 0.1) !important;
}

.tw-ring-sky-950\/100 {
  --tw-ring-color: rgb(8 47 73 / 1) !important;
}

.tw-ring-sky-950\/15 {
  --tw-ring-color: rgb(8 47 73 / 0.15) !important;
}

.tw-ring-sky-950\/20 {
  --tw-ring-color: rgb(8 47 73 / 0.2) !important;
}

.tw-ring-sky-950\/25 {
  --tw-ring-color: rgb(8 47 73 / 0.25) !important;
}

.tw-ring-sky-950\/30 {
  --tw-ring-color: rgb(8 47 73 / 0.3) !important;
}

.tw-ring-sky-950\/35 {
  --tw-ring-color: rgb(8 47 73 / 0.35) !important;
}

.tw-ring-sky-950\/40 {
  --tw-ring-color: rgb(8 47 73 / 0.4) !important;
}

.tw-ring-sky-950\/45 {
  --tw-ring-color: rgb(8 47 73 / 0.45) !important;
}

.tw-ring-sky-950\/5 {
  --tw-ring-color: rgb(8 47 73 / 0.05) !important;
}

.tw-ring-sky-950\/50 {
  --tw-ring-color: rgb(8 47 73 / 0.5) !important;
}

.tw-ring-sky-950\/55 {
  --tw-ring-color: rgb(8 47 73 / 0.55) !important;
}

.tw-ring-sky-950\/60 {
  --tw-ring-color: rgb(8 47 73 / 0.6) !important;
}

.tw-ring-sky-950\/65 {
  --tw-ring-color: rgb(8 47 73 / 0.65) !important;
}

.tw-ring-sky-950\/70 {
  --tw-ring-color: rgb(8 47 73 / 0.7) !important;
}

.tw-ring-sky-950\/75 {
  --tw-ring-color: rgb(8 47 73 / 0.75) !important;
}

.tw-ring-sky-950\/80 {
  --tw-ring-color: rgb(8 47 73 / 0.8) !important;
}

.tw-ring-sky-950\/85 {
  --tw-ring-color: rgb(8 47 73 / 0.85) !important;
}

.tw-ring-sky-950\/90 {
  --tw-ring-color: rgb(8 47 73 / 0.9) !important;
}

.tw-ring-sky-950\/95 {
  --tw-ring-color: rgb(8 47 73 / 0.95) !important;
}

.tw-ring-slate-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(241 245 249 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-100\/0 {
  --tw-ring-color: rgb(241 245 249 / 0) !important;
}

.tw-ring-slate-100\/10 {
  --tw-ring-color: rgb(241 245 249 / 0.1) !important;
}

.tw-ring-slate-100\/100 {
  --tw-ring-color: rgb(241 245 249 / 1) !important;
}

.tw-ring-slate-100\/15 {
  --tw-ring-color: rgb(241 245 249 / 0.15) !important;
}

.tw-ring-slate-100\/20 {
  --tw-ring-color: rgb(241 245 249 / 0.2) !important;
}

.tw-ring-slate-100\/25 {
  --tw-ring-color: rgb(241 245 249 / 0.25) !important;
}

.tw-ring-slate-100\/30 {
  --tw-ring-color: rgb(241 245 249 / 0.3) !important;
}

.tw-ring-slate-100\/35 {
  --tw-ring-color: rgb(241 245 249 / 0.35) !important;
}

.tw-ring-slate-100\/40 {
  --tw-ring-color: rgb(241 245 249 / 0.4) !important;
}

.tw-ring-slate-100\/45 {
  --tw-ring-color: rgb(241 245 249 / 0.45) !important;
}

.tw-ring-slate-100\/5 {
  --tw-ring-color: rgb(241 245 249 / 0.05) !important;
}

.tw-ring-slate-100\/50 {
  --tw-ring-color: rgb(241 245 249 / 0.5) !important;
}

.tw-ring-slate-100\/55 {
  --tw-ring-color: rgb(241 245 249 / 0.55) !important;
}

.tw-ring-slate-100\/60 {
  --tw-ring-color: rgb(241 245 249 / 0.6) !important;
}

.tw-ring-slate-100\/65 {
  --tw-ring-color: rgb(241 245 249 / 0.65) !important;
}

.tw-ring-slate-100\/70 {
  --tw-ring-color: rgb(241 245 249 / 0.7) !important;
}

.tw-ring-slate-100\/75 {
  --tw-ring-color: rgb(241 245 249 / 0.75) !important;
}

.tw-ring-slate-100\/80 {
  --tw-ring-color: rgb(241 245 249 / 0.8) !important;
}

.tw-ring-slate-100\/85 {
  --tw-ring-color: rgb(241 245 249 / 0.85) !important;
}

.tw-ring-slate-100\/90 {
  --tw-ring-color: rgb(241 245 249 / 0.9) !important;
}

.tw-ring-slate-100\/95 {
  --tw-ring-color: rgb(241 245 249 / 0.95) !important;
}

.tw-ring-slate-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(226 232 240 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-200\/0 {
  --tw-ring-color: rgb(226 232 240 / 0) !important;
}

.tw-ring-slate-200\/10 {
  --tw-ring-color: rgb(226 232 240 / 0.1) !important;
}

.tw-ring-slate-200\/100 {
  --tw-ring-color: rgb(226 232 240 / 1) !important;
}

.tw-ring-slate-200\/15 {
  --tw-ring-color: rgb(226 232 240 / 0.15) !important;
}

.tw-ring-slate-200\/20 {
  --tw-ring-color: rgb(226 232 240 / 0.2) !important;
}

.tw-ring-slate-200\/25 {
  --tw-ring-color: rgb(226 232 240 / 0.25) !important;
}

.tw-ring-slate-200\/30 {
  --tw-ring-color: rgb(226 232 240 / 0.3) !important;
}

.tw-ring-slate-200\/35 {
  --tw-ring-color: rgb(226 232 240 / 0.35) !important;
}

.tw-ring-slate-200\/40 {
  --tw-ring-color: rgb(226 232 240 / 0.4) !important;
}

.tw-ring-slate-200\/45 {
  --tw-ring-color: rgb(226 232 240 / 0.45) !important;
}

.tw-ring-slate-200\/5 {
  --tw-ring-color: rgb(226 232 240 / 0.05) !important;
}

.tw-ring-slate-200\/50 {
  --tw-ring-color: rgb(226 232 240 / 0.5) !important;
}

.tw-ring-slate-200\/55 {
  --tw-ring-color: rgb(226 232 240 / 0.55) !important;
}

.tw-ring-slate-200\/60 {
  --tw-ring-color: rgb(226 232 240 / 0.6) !important;
}

.tw-ring-slate-200\/65 {
  --tw-ring-color: rgb(226 232 240 / 0.65) !important;
}

.tw-ring-slate-200\/70 {
  --tw-ring-color: rgb(226 232 240 / 0.7) !important;
}

.tw-ring-slate-200\/75 {
  --tw-ring-color: rgb(226 232 240 / 0.75) !important;
}

.tw-ring-slate-200\/80 {
  --tw-ring-color: rgb(226 232 240 / 0.8) !important;
}

.tw-ring-slate-200\/85 {
  --tw-ring-color: rgb(226 232 240 / 0.85) !important;
}

.tw-ring-slate-200\/90 {
  --tw-ring-color: rgb(226 232 240 / 0.9) !important;
}

.tw-ring-slate-200\/95 {
  --tw-ring-color: rgb(226 232 240 / 0.95) !important;
}

.tw-ring-slate-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(203 213 225 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-300\/0 {
  --tw-ring-color: rgb(203 213 225 / 0) !important;
}

.tw-ring-slate-300\/10 {
  --tw-ring-color: rgb(203 213 225 / 0.1) !important;
}

.tw-ring-slate-300\/100 {
  --tw-ring-color: rgb(203 213 225 / 1) !important;
}

.tw-ring-slate-300\/15 {
  --tw-ring-color: rgb(203 213 225 / 0.15) !important;
}

.tw-ring-slate-300\/20 {
  --tw-ring-color: rgb(203 213 225 / 0.2) !important;
}

.tw-ring-slate-300\/25 {
  --tw-ring-color: rgb(203 213 225 / 0.25) !important;
}

.tw-ring-slate-300\/30 {
  --tw-ring-color: rgb(203 213 225 / 0.3) !important;
}

.tw-ring-slate-300\/35 {
  --tw-ring-color: rgb(203 213 225 / 0.35) !important;
}

.tw-ring-slate-300\/40 {
  --tw-ring-color: rgb(203 213 225 / 0.4) !important;
}

.tw-ring-slate-300\/45 {
  --tw-ring-color: rgb(203 213 225 / 0.45) !important;
}

.tw-ring-slate-300\/5 {
  --tw-ring-color: rgb(203 213 225 / 0.05) !important;
}

.tw-ring-slate-300\/50 {
  --tw-ring-color: rgb(203 213 225 / 0.5) !important;
}

.tw-ring-slate-300\/55 {
  --tw-ring-color: rgb(203 213 225 / 0.55) !important;
}

.tw-ring-slate-300\/60 {
  --tw-ring-color: rgb(203 213 225 / 0.6) !important;
}

.tw-ring-slate-300\/65 {
  --tw-ring-color: rgb(203 213 225 / 0.65) !important;
}

.tw-ring-slate-300\/70 {
  --tw-ring-color: rgb(203 213 225 / 0.7) !important;
}

.tw-ring-slate-300\/75 {
  --tw-ring-color: rgb(203 213 225 / 0.75) !important;
}

.tw-ring-slate-300\/80 {
  --tw-ring-color: rgb(203 213 225 / 0.8) !important;
}

.tw-ring-slate-300\/85 {
  --tw-ring-color: rgb(203 213 225 / 0.85) !important;
}

.tw-ring-slate-300\/90 {
  --tw-ring-color: rgb(203 213 225 / 0.9) !important;
}

.tw-ring-slate-300\/95 {
  --tw-ring-color: rgb(203 213 225 / 0.95) !important;
}

.tw-ring-slate-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(148 163 184 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-400\/0 {
  --tw-ring-color: rgb(148 163 184 / 0) !important;
}

.tw-ring-slate-400\/10 {
  --tw-ring-color: rgb(148 163 184 / 0.1) !important;
}

.tw-ring-slate-400\/100 {
  --tw-ring-color: rgb(148 163 184 / 1) !important;
}

.tw-ring-slate-400\/15 {
  --tw-ring-color: rgb(148 163 184 / 0.15) !important;
}

.tw-ring-slate-400\/20 {
  --tw-ring-color: rgb(148 163 184 / 0.2) !important;
}

.tw-ring-slate-400\/25 {
  --tw-ring-color: rgb(148 163 184 / 0.25) !important;
}

.tw-ring-slate-400\/30 {
  --tw-ring-color: rgb(148 163 184 / 0.3) !important;
}

.tw-ring-slate-400\/35 {
  --tw-ring-color: rgb(148 163 184 / 0.35) !important;
}

.tw-ring-slate-400\/40 {
  --tw-ring-color: rgb(148 163 184 / 0.4) !important;
}

.tw-ring-slate-400\/45 {
  --tw-ring-color: rgb(148 163 184 / 0.45) !important;
}

.tw-ring-slate-400\/5 {
  --tw-ring-color: rgb(148 163 184 / 0.05) !important;
}

.tw-ring-slate-400\/50 {
  --tw-ring-color: rgb(148 163 184 / 0.5) !important;
}

.tw-ring-slate-400\/55 {
  --tw-ring-color: rgb(148 163 184 / 0.55) !important;
}

.tw-ring-slate-400\/60 {
  --tw-ring-color: rgb(148 163 184 / 0.6) !important;
}

.tw-ring-slate-400\/65 {
  --tw-ring-color: rgb(148 163 184 / 0.65) !important;
}

.tw-ring-slate-400\/70 {
  --tw-ring-color: rgb(148 163 184 / 0.7) !important;
}

.tw-ring-slate-400\/75 {
  --tw-ring-color: rgb(148 163 184 / 0.75) !important;
}

.tw-ring-slate-400\/80 {
  --tw-ring-color: rgb(148 163 184 / 0.8) !important;
}

.tw-ring-slate-400\/85 {
  --tw-ring-color: rgb(148 163 184 / 0.85) !important;
}

.tw-ring-slate-400\/90 {
  --tw-ring-color: rgb(148 163 184 / 0.9) !important;
}

.tw-ring-slate-400\/95 {
  --tw-ring-color: rgb(148 163 184 / 0.95) !important;
}

.tw-ring-slate-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(248 250 252 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-50\/0 {
  --tw-ring-color: rgb(248 250 252 / 0) !important;
}

.tw-ring-slate-50\/10 {
  --tw-ring-color: rgb(248 250 252 / 0.1) !important;
}

.tw-ring-slate-50\/100 {
  --tw-ring-color: rgb(248 250 252 / 1) !important;
}

.tw-ring-slate-50\/15 {
  --tw-ring-color: rgb(248 250 252 / 0.15) !important;
}

.tw-ring-slate-50\/20 {
  --tw-ring-color: rgb(248 250 252 / 0.2) !important;
}

.tw-ring-slate-50\/25 {
  --tw-ring-color: rgb(248 250 252 / 0.25) !important;
}

.tw-ring-slate-50\/30 {
  --tw-ring-color: rgb(248 250 252 / 0.3) !important;
}

.tw-ring-slate-50\/35 {
  --tw-ring-color: rgb(248 250 252 / 0.35) !important;
}

.tw-ring-slate-50\/40 {
  --tw-ring-color: rgb(248 250 252 / 0.4) !important;
}

.tw-ring-slate-50\/45 {
  --tw-ring-color: rgb(248 250 252 / 0.45) !important;
}

.tw-ring-slate-50\/5 {
  --tw-ring-color: rgb(248 250 252 / 0.05) !important;
}

.tw-ring-slate-50\/50 {
  --tw-ring-color: rgb(248 250 252 / 0.5) !important;
}

.tw-ring-slate-50\/55 {
  --tw-ring-color: rgb(248 250 252 / 0.55) !important;
}

.tw-ring-slate-50\/60 {
  --tw-ring-color: rgb(248 250 252 / 0.6) !important;
}

.tw-ring-slate-50\/65 {
  --tw-ring-color: rgb(248 250 252 / 0.65) !important;
}

.tw-ring-slate-50\/70 {
  --tw-ring-color: rgb(248 250 252 / 0.7) !important;
}

.tw-ring-slate-50\/75 {
  --tw-ring-color: rgb(248 250 252 / 0.75) !important;
}

.tw-ring-slate-50\/80 {
  --tw-ring-color: rgb(248 250 252 / 0.8) !important;
}

.tw-ring-slate-50\/85 {
  --tw-ring-color: rgb(248 250 252 / 0.85) !important;
}

.tw-ring-slate-50\/90 {
  --tw-ring-color: rgb(248 250 252 / 0.9) !important;
}

.tw-ring-slate-50\/95 {
  --tw-ring-color: rgb(248 250 252 / 0.95) !important;
}

.tw-ring-slate-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(100 116 139 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-500\/0 {
  --tw-ring-color: rgb(100 116 139 / 0) !important;
}

.tw-ring-slate-500\/10 {
  --tw-ring-color: rgb(100 116 139 / 0.1) !important;
}

.tw-ring-slate-500\/100 {
  --tw-ring-color: rgb(100 116 139 / 1) !important;
}

.tw-ring-slate-500\/15 {
  --tw-ring-color: rgb(100 116 139 / 0.15) !important;
}

.tw-ring-slate-500\/20 {
  --tw-ring-color: rgb(100 116 139 / 0.2) !important;
}

.tw-ring-slate-500\/25 {
  --tw-ring-color: rgb(100 116 139 / 0.25) !important;
}

.tw-ring-slate-500\/30 {
  --tw-ring-color: rgb(100 116 139 / 0.3) !important;
}

.tw-ring-slate-500\/35 {
  --tw-ring-color: rgb(100 116 139 / 0.35) !important;
}

.tw-ring-slate-500\/40 {
  --tw-ring-color: rgb(100 116 139 / 0.4) !important;
}

.tw-ring-slate-500\/45 {
  --tw-ring-color: rgb(100 116 139 / 0.45) !important;
}

.tw-ring-slate-500\/5 {
  --tw-ring-color: rgb(100 116 139 / 0.05) !important;
}

.tw-ring-slate-500\/50 {
  --tw-ring-color: rgb(100 116 139 / 0.5) !important;
}

.tw-ring-slate-500\/55 {
  --tw-ring-color: rgb(100 116 139 / 0.55) !important;
}

.tw-ring-slate-500\/60 {
  --tw-ring-color: rgb(100 116 139 / 0.6) !important;
}

.tw-ring-slate-500\/65 {
  --tw-ring-color: rgb(100 116 139 / 0.65) !important;
}

.tw-ring-slate-500\/70 {
  --tw-ring-color: rgb(100 116 139 / 0.7) !important;
}

.tw-ring-slate-500\/75 {
  --tw-ring-color: rgb(100 116 139 / 0.75) !important;
}

.tw-ring-slate-500\/80 {
  --tw-ring-color: rgb(100 116 139 / 0.8) !important;
}

.tw-ring-slate-500\/85 {
  --tw-ring-color: rgb(100 116 139 / 0.85) !important;
}

.tw-ring-slate-500\/90 {
  --tw-ring-color: rgb(100 116 139 / 0.9) !important;
}

.tw-ring-slate-500\/95 {
  --tw-ring-color: rgb(100 116 139 / 0.95) !important;
}

.tw-ring-slate-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(71 85 105 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-600\/0 {
  --tw-ring-color: rgb(71 85 105 / 0) !important;
}

.tw-ring-slate-600\/10 {
  --tw-ring-color: rgb(71 85 105 / 0.1) !important;
}

.tw-ring-slate-600\/100 {
  --tw-ring-color: rgb(71 85 105 / 1) !important;
}

.tw-ring-slate-600\/15 {
  --tw-ring-color: rgb(71 85 105 / 0.15) !important;
}

.tw-ring-slate-600\/20 {
  --tw-ring-color: rgb(71 85 105 / 0.2) !important;
}

.tw-ring-slate-600\/25 {
  --tw-ring-color: rgb(71 85 105 / 0.25) !important;
}

.tw-ring-slate-600\/30 {
  --tw-ring-color: rgb(71 85 105 / 0.3) !important;
}

.tw-ring-slate-600\/35 {
  --tw-ring-color: rgb(71 85 105 / 0.35) !important;
}

.tw-ring-slate-600\/40 {
  --tw-ring-color: rgb(71 85 105 / 0.4) !important;
}

.tw-ring-slate-600\/45 {
  --tw-ring-color: rgb(71 85 105 / 0.45) !important;
}

.tw-ring-slate-600\/5 {
  --tw-ring-color: rgb(71 85 105 / 0.05) !important;
}

.tw-ring-slate-600\/50 {
  --tw-ring-color: rgb(71 85 105 / 0.5) !important;
}

.tw-ring-slate-600\/55 {
  --tw-ring-color: rgb(71 85 105 / 0.55) !important;
}

.tw-ring-slate-600\/60 {
  --tw-ring-color: rgb(71 85 105 / 0.6) !important;
}

.tw-ring-slate-600\/65 {
  --tw-ring-color: rgb(71 85 105 / 0.65) !important;
}

.tw-ring-slate-600\/70 {
  --tw-ring-color: rgb(71 85 105 / 0.7) !important;
}

.tw-ring-slate-600\/75 {
  --tw-ring-color: rgb(71 85 105 / 0.75) !important;
}

.tw-ring-slate-600\/80 {
  --tw-ring-color: rgb(71 85 105 / 0.8) !important;
}

.tw-ring-slate-600\/85 {
  --tw-ring-color: rgb(71 85 105 / 0.85) !important;
}

.tw-ring-slate-600\/90 {
  --tw-ring-color: rgb(71 85 105 / 0.9) !important;
}

.tw-ring-slate-600\/95 {
  --tw-ring-color: rgb(71 85 105 / 0.95) !important;
}

.tw-ring-slate-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(51 65 85 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-700\/0 {
  --tw-ring-color: rgb(51 65 85 / 0) !important;
}

.tw-ring-slate-700\/10 {
  --tw-ring-color: rgb(51 65 85 / 0.1) !important;
}

.tw-ring-slate-700\/100 {
  --tw-ring-color: rgb(51 65 85 / 1) !important;
}

.tw-ring-slate-700\/15 {
  --tw-ring-color: rgb(51 65 85 / 0.15) !important;
}

.tw-ring-slate-700\/20 {
  --tw-ring-color: rgb(51 65 85 / 0.2) !important;
}

.tw-ring-slate-700\/25 {
  --tw-ring-color: rgb(51 65 85 / 0.25) !important;
}

.tw-ring-slate-700\/30 {
  --tw-ring-color: rgb(51 65 85 / 0.3) !important;
}

.tw-ring-slate-700\/35 {
  --tw-ring-color: rgb(51 65 85 / 0.35) !important;
}

.tw-ring-slate-700\/40 {
  --tw-ring-color: rgb(51 65 85 / 0.4) !important;
}

.tw-ring-slate-700\/45 {
  --tw-ring-color: rgb(51 65 85 / 0.45) !important;
}

.tw-ring-slate-700\/5 {
  --tw-ring-color: rgb(51 65 85 / 0.05) !important;
}

.tw-ring-slate-700\/50 {
  --tw-ring-color: rgb(51 65 85 / 0.5) !important;
}

.tw-ring-slate-700\/55 {
  --tw-ring-color: rgb(51 65 85 / 0.55) !important;
}

.tw-ring-slate-700\/60 {
  --tw-ring-color: rgb(51 65 85 / 0.6) !important;
}

.tw-ring-slate-700\/65 {
  --tw-ring-color: rgb(51 65 85 / 0.65) !important;
}

.tw-ring-slate-700\/70 {
  --tw-ring-color: rgb(51 65 85 / 0.7) !important;
}

.tw-ring-slate-700\/75 {
  --tw-ring-color: rgb(51 65 85 / 0.75) !important;
}

.tw-ring-slate-700\/80 {
  --tw-ring-color: rgb(51 65 85 / 0.8) !important;
}

.tw-ring-slate-700\/85 {
  --tw-ring-color: rgb(51 65 85 / 0.85) !important;
}

.tw-ring-slate-700\/90 {
  --tw-ring-color: rgb(51 65 85 / 0.9) !important;
}

.tw-ring-slate-700\/95 {
  --tw-ring-color: rgb(51 65 85 / 0.95) !important;
}

.tw-ring-slate-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(30 41 59 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-800\/0 {
  --tw-ring-color: rgb(30 41 59 / 0) !important;
}

.tw-ring-slate-800\/10 {
  --tw-ring-color: rgb(30 41 59 / 0.1) !important;
}

.tw-ring-slate-800\/100 {
  --tw-ring-color: rgb(30 41 59 / 1) !important;
}

.tw-ring-slate-800\/15 {
  --tw-ring-color: rgb(30 41 59 / 0.15) !important;
}

.tw-ring-slate-800\/20 {
  --tw-ring-color: rgb(30 41 59 / 0.2) !important;
}

.tw-ring-slate-800\/25 {
  --tw-ring-color: rgb(30 41 59 / 0.25) !important;
}

.tw-ring-slate-800\/30 {
  --tw-ring-color: rgb(30 41 59 / 0.3) !important;
}

.tw-ring-slate-800\/35 {
  --tw-ring-color: rgb(30 41 59 / 0.35) !important;
}

.tw-ring-slate-800\/40 {
  --tw-ring-color: rgb(30 41 59 / 0.4) !important;
}

.tw-ring-slate-800\/45 {
  --tw-ring-color: rgb(30 41 59 / 0.45) !important;
}

.tw-ring-slate-800\/5 {
  --tw-ring-color: rgb(30 41 59 / 0.05) !important;
}

.tw-ring-slate-800\/50 {
  --tw-ring-color: rgb(30 41 59 / 0.5) !important;
}

.tw-ring-slate-800\/55 {
  --tw-ring-color: rgb(30 41 59 / 0.55) !important;
}

.tw-ring-slate-800\/60 {
  --tw-ring-color: rgb(30 41 59 / 0.6) !important;
}

.tw-ring-slate-800\/65 {
  --tw-ring-color: rgb(30 41 59 / 0.65) !important;
}

.tw-ring-slate-800\/70 {
  --tw-ring-color: rgb(30 41 59 / 0.7) !important;
}

.tw-ring-slate-800\/75 {
  --tw-ring-color: rgb(30 41 59 / 0.75) !important;
}

.tw-ring-slate-800\/80 {
  --tw-ring-color: rgb(30 41 59 / 0.8) !important;
}

.tw-ring-slate-800\/85 {
  --tw-ring-color: rgb(30 41 59 / 0.85) !important;
}

.tw-ring-slate-800\/90 {
  --tw-ring-color: rgb(30 41 59 / 0.9) !important;
}

.tw-ring-slate-800\/95 {
  --tw-ring-color: rgb(30 41 59 / 0.95) !important;
}

.tw-ring-slate-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(15 23 42 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-900\/0 {
  --tw-ring-color: rgb(15 23 42 / 0) !important;
}

.tw-ring-slate-900\/10 {
  --tw-ring-color: rgb(15 23 42 / 0.1) !important;
}

.tw-ring-slate-900\/100 {
  --tw-ring-color: rgb(15 23 42 / 1) !important;
}

.tw-ring-slate-900\/15 {
  --tw-ring-color: rgb(15 23 42 / 0.15) !important;
}

.tw-ring-slate-900\/20 {
  --tw-ring-color: rgb(15 23 42 / 0.2) !important;
}

.tw-ring-slate-900\/25 {
  --tw-ring-color: rgb(15 23 42 / 0.25) !important;
}

.tw-ring-slate-900\/30 {
  --tw-ring-color: rgb(15 23 42 / 0.3) !important;
}

.tw-ring-slate-900\/35 {
  --tw-ring-color: rgb(15 23 42 / 0.35) !important;
}

.tw-ring-slate-900\/40 {
  --tw-ring-color: rgb(15 23 42 / 0.4) !important;
}

.tw-ring-slate-900\/45 {
  --tw-ring-color: rgb(15 23 42 / 0.45) !important;
}

.tw-ring-slate-900\/5 {
  --tw-ring-color: rgb(15 23 42 / 0.05) !important;
}

.tw-ring-slate-900\/50 {
  --tw-ring-color: rgb(15 23 42 / 0.5) !important;
}

.tw-ring-slate-900\/55 {
  --tw-ring-color: rgb(15 23 42 / 0.55) !important;
}

.tw-ring-slate-900\/60 {
  --tw-ring-color: rgb(15 23 42 / 0.6) !important;
}

.tw-ring-slate-900\/65 {
  --tw-ring-color: rgb(15 23 42 / 0.65) !important;
}

.tw-ring-slate-900\/70 {
  --tw-ring-color: rgb(15 23 42 / 0.7) !important;
}

.tw-ring-slate-900\/75 {
  --tw-ring-color: rgb(15 23 42 / 0.75) !important;
}

.tw-ring-slate-900\/80 {
  --tw-ring-color: rgb(15 23 42 / 0.8) !important;
}

.tw-ring-slate-900\/85 {
  --tw-ring-color: rgb(15 23 42 / 0.85) !important;
}

.tw-ring-slate-900\/90 {
  --tw-ring-color: rgb(15 23 42 / 0.9) !important;
}

.tw-ring-slate-900\/95 {
  --tw-ring-color: rgb(15 23 42 / 0.95) !important;
}

.tw-ring-slate-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(2 6 23 / var(--tw-ring-opacity)) !important;
}

.tw-ring-slate-950\/0 {
  --tw-ring-color: rgb(2 6 23 / 0) !important;
}

.tw-ring-slate-950\/10 {
  --tw-ring-color: rgb(2 6 23 / 0.1) !important;
}

.tw-ring-slate-950\/100 {
  --tw-ring-color: rgb(2 6 23 / 1) !important;
}

.tw-ring-slate-950\/15 {
  --tw-ring-color: rgb(2 6 23 / 0.15) !important;
}

.tw-ring-slate-950\/20 {
  --tw-ring-color: rgb(2 6 23 / 0.2) !important;
}

.tw-ring-slate-950\/25 {
  --tw-ring-color: rgb(2 6 23 / 0.25) !important;
}

.tw-ring-slate-950\/30 {
  --tw-ring-color: rgb(2 6 23 / 0.3) !important;
}

.tw-ring-slate-950\/35 {
  --tw-ring-color: rgb(2 6 23 / 0.35) !important;
}

.tw-ring-slate-950\/40 {
  --tw-ring-color: rgb(2 6 23 / 0.4) !important;
}

.tw-ring-slate-950\/45 {
  --tw-ring-color: rgb(2 6 23 / 0.45) !important;
}

.tw-ring-slate-950\/5 {
  --tw-ring-color: rgb(2 6 23 / 0.05) !important;
}

.tw-ring-slate-950\/50 {
  --tw-ring-color: rgb(2 6 23 / 0.5) !important;
}

.tw-ring-slate-950\/55 {
  --tw-ring-color: rgb(2 6 23 / 0.55) !important;
}

.tw-ring-slate-950\/60 {
  --tw-ring-color: rgb(2 6 23 / 0.6) !important;
}

.tw-ring-slate-950\/65 {
  --tw-ring-color: rgb(2 6 23 / 0.65) !important;
}

.tw-ring-slate-950\/70 {
  --tw-ring-color: rgb(2 6 23 / 0.7) !important;
}

.tw-ring-slate-950\/75 {
  --tw-ring-color: rgb(2 6 23 / 0.75) !important;
}

.tw-ring-slate-950\/80 {
  --tw-ring-color: rgb(2 6 23 / 0.8) !important;
}

.tw-ring-slate-950\/85 {
  --tw-ring-color: rgb(2 6 23 / 0.85) !important;
}

.tw-ring-slate-950\/90 {
  --tw-ring-color: rgb(2 6 23 / 0.9) !important;
}

.tw-ring-slate-950\/95 {
  --tw-ring-color: rgb(2 6 23 / 0.95) !important;
}

.tw-ring-stone-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(245 245 244 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-100\/0 {
  --tw-ring-color: rgb(245 245 244 / 0) !important;
}

.tw-ring-stone-100\/10 {
  --tw-ring-color: rgb(245 245 244 / 0.1) !important;
}

.tw-ring-stone-100\/100 {
  --tw-ring-color: rgb(245 245 244 / 1) !important;
}

.tw-ring-stone-100\/15 {
  --tw-ring-color: rgb(245 245 244 / 0.15) !important;
}

.tw-ring-stone-100\/20 {
  --tw-ring-color: rgb(245 245 244 / 0.2) !important;
}

.tw-ring-stone-100\/25 {
  --tw-ring-color: rgb(245 245 244 / 0.25) !important;
}

.tw-ring-stone-100\/30 {
  --tw-ring-color: rgb(245 245 244 / 0.3) !important;
}

.tw-ring-stone-100\/35 {
  --tw-ring-color: rgb(245 245 244 / 0.35) !important;
}

.tw-ring-stone-100\/40 {
  --tw-ring-color: rgb(245 245 244 / 0.4) !important;
}

.tw-ring-stone-100\/45 {
  --tw-ring-color: rgb(245 245 244 / 0.45) !important;
}

.tw-ring-stone-100\/5 {
  --tw-ring-color: rgb(245 245 244 / 0.05) !important;
}

.tw-ring-stone-100\/50 {
  --tw-ring-color: rgb(245 245 244 / 0.5) !important;
}

.tw-ring-stone-100\/55 {
  --tw-ring-color: rgb(245 245 244 / 0.55) !important;
}

.tw-ring-stone-100\/60 {
  --tw-ring-color: rgb(245 245 244 / 0.6) !important;
}

.tw-ring-stone-100\/65 {
  --tw-ring-color: rgb(245 245 244 / 0.65) !important;
}

.tw-ring-stone-100\/70 {
  --tw-ring-color: rgb(245 245 244 / 0.7) !important;
}

.tw-ring-stone-100\/75 {
  --tw-ring-color: rgb(245 245 244 / 0.75) !important;
}

.tw-ring-stone-100\/80 {
  --tw-ring-color: rgb(245 245 244 / 0.8) !important;
}

.tw-ring-stone-100\/85 {
  --tw-ring-color: rgb(245 245 244 / 0.85) !important;
}

.tw-ring-stone-100\/90 {
  --tw-ring-color: rgb(245 245 244 / 0.9) !important;
}

.tw-ring-stone-100\/95 {
  --tw-ring-color: rgb(245 245 244 / 0.95) !important;
}

.tw-ring-stone-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(231 229 228 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-200\/0 {
  --tw-ring-color: rgb(231 229 228 / 0) !important;
}

.tw-ring-stone-200\/10 {
  --tw-ring-color: rgb(231 229 228 / 0.1) !important;
}

.tw-ring-stone-200\/100 {
  --tw-ring-color: rgb(231 229 228 / 1) !important;
}

.tw-ring-stone-200\/15 {
  --tw-ring-color: rgb(231 229 228 / 0.15) !important;
}

.tw-ring-stone-200\/20 {
  --tw-ring-color: rgb(231 229 228 / 0.2) !important;
}

.tw-ring-stone-200\/25 {
  --tw-ring-color: rgb(231 229 228 / 0.25) !important;
}

.tw-ring-stone-200\/30 {
  --tw-ring-color: rgb(231 229 228 / 0.3) !important;
}

.tw-ring-stone-200\/35 {
  --tw-ring-color: rgb(231 229 228 / 0.35) !important;
}

.tw-ring-stone-200\/40 {
  --tw-ring-color: rgb(231 229 228 / 0.4) !important;
}

.tw-ring-stone-200\/45 {
  --tw-ring-color: rgb(231 229 228 / 0.45) !important;
}

.tw-ring-stone-200\/5 {
  --tw-ring-color: rgb(231 229 228 / 0.05) !important;
}

.tw-ring-stone-200\/50 {
  --tw-ring-color: rgb(231 229 228 / 0.5) !important;
}

.tw-ring-stone-200\/55 {
  --tw-ring-color: rgb(231 229 228 / 0.55) !important;
}

.tw-ring-stone-200\/60 {
  --tw-ring-color: rgb(231 229 228 / 0.6) !important;
}

.tw-ring-stone-200\/65 {
  --tw-ring-color: rgb(231 229 228 / 0.65) !important;
}

.tw-ring-stone-200\/70 {
  --tw-ring-color: rgb(231 229 228 / 0.7) !important;
}

.tw-ring-stone-200\/75 {
  --tw-ring-color: rgb(231 229 228 / 0.75) !important;
}

.tw-ring-stone-200\/80 {
  --tw-ring-color: rgb(231 229 228 / 0.8) !important;
}

.tw-ring-stone-200\/85 {
  --tw-ring-color: rgb(231 229 228 / 0.85) !important;
}

.tw-ring-stone-200\/90 {
  --tw-ring-color: rgb(231 229 228 / 0.9) !important;
}

.tw-ring-stone-200\/95 {
  --tw-ring-color: rgb(231 229 228 / 0.95) !important;
}

.tw-ring-stone-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(214 211 209 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-300\/0 {
  --tw-ring-color: rgb(214 211 209 / 0) !important;
}

.tw-ring-stone-300\/10 {
  --tw-ring-color: rgb(214 211 209 / 0.1) !important;
}

.tw-ring-stone-300\/100 {
  --tw-ring-color: rgb(214 211 209 / 1) !important;
}

.tw-ring-stone-300\/15 {
  --tw-ring-color: rgb(214 211 209 / 0.15) !important;
}

.tw-ring-stone-300\/20 {
  --tw-ring-color: rgb(214 211 209 / 0.2) !important;
}

.tw-ring-stone-300\/25 {
  --tw-ring-color: rgb(214 211 209 / 0.25) !important;
}

.tw-ring-stone-300\/30 {
  --tw-ring-color: rgb(214 211 209 / 0.3) !important;
}

.tw-ring-stone-300\/35 {
  --tw-ring-color: rgb(214 211 209 / 0.35) !important;
}

.tw-ring-stone-300\/40 {
  --tw-ring-color: rgb(214 211 209 / 0.4) !important;
}

.tw-ring-stone-300\/45 {
  --tw-ring-color: rgb(214 211 209 / 0.45) !important;
}

.tw-ring-stone-300\/5 {
  --tw-ring-color: rgb(214 211 209 / 0.05) !important;
}

.tw-ring-stone-300\/50 {
  --tw-ring-color: rgb(214 211 209 / 0.5) !important;
}

.tw-ring-stone-300\/55 {
  --tw-ring-color: rgb(214 211 209 / 0.55) !important;
}

.tw-ring-stone-300\/60 {
  --tw-ring-color: rgb(214 211 209 / 0.6) !important;
}

.tw-ring-stone-300\/65 {
  --tw-ring-color: rgb(214 211 209 / 0.65) !important;
}

.tw-ring-stone-300\/70 {
  --tw-ring-color: rgb(214 211 209 / 0.7) !important;
}

.tw-ring-stone-300\/75 {
  --tw-ring-color: rgb(214 211 209 / 0.75) !important;
}

.tw-ring-stone-300\/80 {
  --tw-ring-color: rgb(214 211 209 / 0.8) !important;
}

.tw-ring-stone-300\/85 {
  --tw-ring-color: rgb(214 211 209 / 0.85) !important;
}

.tw-ring-stone-300\/90 {
  --tw-ring-color: rgb(214 211 209 / 0.9) !important;
}

.tw-ring-stone-300\/95 {
  --tw-ring-color: rgb(214 211 209 / 0.95) !important;
}

.tw-ring-stone-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(168 162 158 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-400\/0 {
  --tw-ring-color: rgb(168 162 158 / 0) !important;
}

.tw-ring-stone-400\/10 {
  --tw-ring-color: rgb(168 162 158 / 0.1) !important;
}

.tw-ring-stone-400\/100 {
  --tw-ring-color: rgb(168 162 158 / 1) !important;
}

.tw-ring-stone-400\/15 {
  --tw-ring-color: rgb(168 162 158 / 0.15) !important;
}

.tw-ring-stone-400\/20 {
  --tw-ring-color: rgb(168 162 158 / 0.2) !important;
}

.tw-ring-stone-400\/25 {
  --tw-ring-color: rgb(168 162 158 / 0.25) !important;
}

.tw-ring-stone-400\/30 {
  --tw-ring-color: rgb(168 162 158 / 0.3) !important;
}

.tw-ring-stone-400\/35 {
  --tw-ring-color: rgb(168 162 158 / 0.35) !important;
}

.tw-ring-stone-400\/40 {
  --tw-ring-color: rgb(168 162 158 / 0.4) !important;
}

.tw-ring-stone-400\/45 {
  --tw-ring-color: rgb(168 162 158 / 0.45) !important;
}

.tw-ring-stone-400\/5 {
  --tw-ring-color: rgb(168 162 158 / 0.05) !important;
}

.tw-ring-stone-400\/50 {
  --tw-ring-color: rgb(168 162 158 / 0.5) !important;
}

.tw-ring-stone-400\/55 {
  --tw-ring-color: rgb(168 162 158 / 0.55) !important;
}

.tw-ring-stone-400\/60 {
  --tw-ring-color: rgb(168 162 158 / 0.6) !important;
}

.tw-ring-stone-400\/65 {
  --tw-ring-color: rgb(168 162 158 / 0.65) !important;
}

.tw-ring-stone-400\/70 {
  --tw-ring-color: rgb(168 162 158 / 0.7) !important;
}

.tw-ring-stone-400\/75 {
  --tw-ring-color: rgb(168 162 158 / 0.75) !important;
}

.tw-ring-stone-400\/80 {
  --tw-ring-color: rgb(168 162 158 / 0.8) !important;
}

.tw-ring-stone-400\/85 {
  --tw-ring-color: rgb(168 162 158 / 0.85) !important;
}

.tw-ring-stone-400\/90 {
  --tw-ring-color: rgb(168 162 158 / 0.9) !important;
}

.tw-ring-stone-400\/95 {
  --tw-ring-color: rgb(168 162 158 / 0.95) !important;
}

.tw-ring-stone-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(250 250 249 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-50\/0 {
  --tw-ring-color: rgb(250 250 249 / 0) !important;
}

.tw-ring-stone-50\/10 {
  --tw-ring-color: rgb(250 250 249 / 0.1) !important;
}

.tw-ring-stone-50\/100 {
  --tw-ring-color: rgb(250 250 249 / 1) !important;
}

.tw-ring-stone-50\/15 {
  --tw-ring-color: rgb(250 250 249 / 0.15) !important;
}

.tw-ring-stone-50\/20 {
  --tw-ring-color: rgb(250 250 249 / 0.2) !important;
}

.tw-ring-stone-50\/25 {
  --tw-ring-color: rgb(250 250 249 / 0.25) !important;
}

.tw-ring-stone-50\/30 {
  --tw-ring-color: rgb(250 250 249 / 0.3) !important;
}

.tw-ring-stone-50\/35 {
  --tw-ring-color: rgb(250 250 249 / 0.35) !important;
}

.tw-ring-stone-50\/40 {
  --tw-ring-color: rgb(250 250 249 / 0.4) !important;
}

.tw-ring-stone-50\/45 {
  --tw-ring-color: rgb(250 250 249 / 0.45) !important;
}

.tw-ring-stone-50\/5 {
  --tw-ring-color: rgb(250 250 249 / 0.05) !important;
}

.tw-ring-stone-50\/50 {
  --tw-ring-color: rgb(250 250 249 / 0.5) !important;
}

.tw-ring-stone-50\/55 {
  --tw-ring-color: rgb(250 250 249 / 0.55) !important;
}

.tw-ring-stone-50\/60 {
  --tw-ring-color: rgb(250 250 249 / 0.6) !important;
}

.tw-ring-stone-50\/65 {
  --tw-ring-color: rgb(250 250 249 / 0.65) !important;
}

.tw-ring-stone-50\/70 {
  --tw-ring-color: rgb(250 250 249 / 0.7) !important;
}

.tw-ring-stone-50\/75 {
  --tw-ring-color: rgb(250 250 249 / 0.75) !important;
}

.tw-ring-stone-50\/80 {
  --tw-ring-color: rgb(250 250 249 / 0.8) !important;
}

.tw-ring-stone-50\/85 {
  --tw-ring-color: rgb(250 250 249 / 0.85) !important;
}

.tw-ring-stone-50\/90 {
  --tw-ring-color: rgb(250 250 249 / 0.9) !important;
}

.tw-ring-stone-50\/95 {
  --tw-ring-color: rgb(250 250 249 / 0.95) !important;
}

.tw-ring-stone-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(120 113 108 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-500\/0 {
  --tw-ring-color: rgb(120 113 108 / 0) !important;
}

.tw-ring-stone-500\/10 {
  --tw-ring-color: rgb(120 113 108 / 0.1) !important;
}

.tw-ring-stone-500\/100 {
  --tw-ring-color: rgb(120 113 108 / 1) !important;
}

.tw-ring-stone-500\/15 {
  --tw-ring-color: rgb(120 113 108 / 0.15) !important;
}

.tw-ring-stone-500\/20 {
  --tw-ring-color: rgb(120 113 108 / 0.2) !important;
}

.tw-ring-stone-500\/25 {
  --tw-ring-color: rgb(120 113 108 / 0.25) !important;
}

.tw-ring-stone-500\/30 {
  --tw-ring-color: rgb(120 113 108 / 0.3) !important;
}

.tw-ring-stone-500\/35 {
  --tw-ring-color: rgb(120 113 108 / 0.35) !important;
}

.tw-ring-stone-500\/40 {
  --tw-ring-color: rgb(120 113 108 / 0.4) !important;
}

.tw-ring-stone-500\/45 {
  --tw-ring-color: rgb(120 113 108 / 0.45) !important;
}

.tw-ring-stone-500\/5 {
  --tw-ring-color: rgb(120 113 108 / 0.05) !important;
}

.tw-ring-stone-500\/50 {
  --tw-ring-color: rgb(120 113 108 / 0.5) !important;
}

.tw-ring-stone-500\/55 {
  --tw-ring-color: rgb(120 113 108 / 0.55) !important;
}

.tw-ring-stone-500\/60 {
  --tw-ring-color: rgb(120 113 108 / 0.6) !important;
}

.tw-ring-stone-500\/65 {
  --tw-ring-color: rgb(120 113 108 / 0.65) !important;
}

.tw-ring-stone-500\/70 {
  --tw-ring-color: rgb(120 113 108 / 0.7) !important;
}

.tw-ring-stone-500\/75 {
  --tw-ring-color: rgb(120 113 108 / 0.75) !important;
}

.tw-ring-stone-500\/80 {
  --tw-ring-color: rgb(120 113 108 / 0.8) !important;
}

.tw-ring-stone-500\/85 {
  --tw-ring-color: rgb(120 113 108 / 0.85) !important;
}

.tw-ring-stone-500\/90 {
  --tw-ring-color: rgb(120 113 108 / 0.9) !important;
}

.tw-ring-stone-500\/95 {
  --tw-ring-color: rgb(120 113 108 / 0.95) !important;
}

.tw-ring-stone-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(87 83 78 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-600\/0 {
  --tw-ring-color: rgb(87 83 78 / 0) !important;
}

.tw-ring-stone-600\/10 {
  --tw-ring-color: rgb(87 83 78 / 0.1) !important;
}

.tw-ring-stone-600\/100 {
  --tw-ring-color: rgb(87 83 78 / 1) !important;
}

.tw-ring-stone-600\/15 {
  --tw-ring-color: rgb(87 83 78 / 0.15) !important;
}

.tw-ring-stone-600\/20 {
  --tw-ring-color: rgb(87 83 78 / 0.2) !important;
}

.tw-ring-stone-600\/25 {
  --tw-ring-color: rgb(87 83 78 / 0.25) !important;
}

.tw-ring-stone-600\/30 {
  --tw-ring-color: rgb(87 83 78 / 0.3) !important;
}

.tw-ring-stone-600\/35 {
  --tw-ring-color: rgb(87 83 78 / 0.35) !important;
}

.tw-ring-stone-600\/40 {
  --tw-ring-color: rgb(87 83 78 / 0.4) !important;
}

.tw-ring-stone-600\/45 {
  --tw-ring-color: rgb(87 83 78 / 0.45) !important;
}

.tw-ring-stone-600\/5 {
  --tw-ring-color: rgb(87 83 78 / 0.05) !important;
}

.tw-ring-stone-600\/50 {
  --tw-ring-color: rgb(87 83 78 / 0.5) !important;
}

.tw-ring-stone-600\/55 {
  --tw-ring-color: rgb(87 83 78 / 0.55) !important;
}

.tw-ring-stone-600\/60 {
  --tw-ring-color: rgb(87 83 78 / 0.6) !important;
}

.tw-ring-stone-600\/65 {
  --tw-ring-color: rgb(87 83 78 / 0.65) !important;
}

.tw-ring-stone-600\/70 {
  --tw-ring-color: rgb(87 83 78 / 0.7) !important;
}

.tw-ring-stone-600\/75 {
  --tw-ring-color: rgb(87 83 78 / 0.75) !important;
}

.tw-ring-stone-600\/80 {
  --tw-ring-color: rgb(87 83 78 / 0.8) !important;
}

.tw-ring-stone-600\/85 {
  --tw-ring-color: rgb(87 83 78 / 0.85) !important;
}

.tw-ring-stone-600\/90 {
  --tw-ring-color: rgb(87 83 78 / 0.9) !important;
}

.tw-ring-stone-600\/95 {
  --tw-ring-color: rgb(87 83 78 / 0.95) !important;
}

.tw-ring-stone-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(68 64 60 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-700\/0 {
  --tw-ring-color: rgb(68 64 60 / 0) !important;
}

.tw-ring-stone-700\/10 {
  --tw-ring-color: rgb(68 64 60 / 0.1) !important;
}

.tw-ring-stone-700\/100 {
  --tw-ring-color: rgb(68 64 60 / 1) !important;
}

.tw-ring-stone-700\/15 {
  --tw-ring-color: rgb(68 64 60 / 0.15) !important;
}

.tw-ring-stone-700\/20 {
  --tw-ring-color: rgb(68 64 60 / 0.2) !important;
}

.tw-ring-stone-700\/25 {
  --tw-ring-color: rgb(68 64 60 / 0.25) !important;
}

.tw-ring-stone-700\/30 {
  --tw-ring-color: rgb(68 64 60 / 0.3) !important;
}

.tw-ring-stone-700\/35 {
  --tw-ring-color: rgb(68 64 60 / 0.35) !important;
}

.tw-ring-stone-700\/40 {
  --tw-ring-color: rgb(68 64 60 / 0.4) !important;
}

.tw-ring-stone-700\/45 {
  --tw-ring-color: rgb(68 64 60 / 0.45) !important;
}

.tw-ring-stone-700\/5 {
  --tw-ring-color: rgb(68 64 60 / 0.05) !important;
}

.tw-ring-stone-700\/50 {
  --tw-ring-color: rgb(68 64 60 / 0.5) !important;
}

.tw-ring-stone-700\/55 {
  --tw-ring-color: rgb(68 64 60 / 0.55) !important;
}

.tw-ring-stone-700\/60 {
  --tw-ring-color: rgb(68 64 60 / 0.6) !important;
}

.tw-ring-stone-700\/65 {
  --tw-ring-color: rgb(68 64 60 / 0.65) !important;
}

.tw-ring-stone-700\/70 {
  --tw-ring-color: rgb(68 64 60 / 0.7) !important;
}

.tw-ring-stone-700\/75 {
  --tw-ring-color: rgb(68 64 60 / 0.75) !important;
}

.tw-ring-stone-700\/80 {
  --tw-ring-color: rgb(68 64 60 / 0.8) !important;
}

.tw-ring-stone-700\/85 {
  --tw-ring-color: rgb(68 64 60 / 0.85) !important;
}

.tw-ring-stone-700\/90 {
  --tw-ring-color: rgb(68 64 60 / 0.9) !important;
}

.tw-ring-stone-700\/95 {
  --tw-ring-color: rgb(68 64 60 / 0.95) !important;
}

.tw-ring-stone-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(41 37 36 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-800\/0 {
  --tw-ring-color: rgb(41 37 36 / 0) !important;
}

.tw-ring-stone-800\/10 {
  --tw-ring-color: rgb(41 37 36 / 0.1) !important;
}

.tw-ring-stone-800\/100 {
  --tw-ring-color: rgb(41 37 36 / 1) !important;
}

.tw-ring-stone-800\/15 {
  --tw-ring-color: rgb(41 37 36 / 0.15) !important;
}

.tw-ring-stone-800\/20 {
  --tw-ring-color: rgb(41 37 36 / 0.2) !important;
}

.tw-ring-stone-800\/25 {
  --tw-ring-color: rgb(41 37 36 / 0.25) !important;
}

.tw-ring-stone-800\/30 {
  --tw-ring-color: rgb(41 37 36 / 0.3) !important;
}

.tw-ring-stone-800\/35 {
  --tw-ring-color: rgb(41 37 36 / 0.35) !important;
}

.tw-ring-stone-800\/40 {
  --tw-ring-color: rgb(41 37 36 / 0.4) !important;
}

.tw-ring-stone-800\/45 {
  --tw-ring-color: rgb(41 37 36 / 0.45) !important;
}

.tw-ring-stone-800\/5 {
  --tw-ring-color: rgb(41 37 36 / 0.05) !important;
}

.tw-ring-stone-800\/50 {
  --tw-ring-color: rgb(41 37 36 / 0.5) !important;
}

.tw-ring-stone-800\/55 {
  --tw-ring-color: rgb(41 37 36 / 0.55) !important;
}

.tw-ring-stone-800\/60 {
  --tw-ring-color: rgb(41 37 36 / 0.6) !important;
}

.tw-ring-stone-800\/65 {
  --tw-ring-color: rgb(41 37 36 / 0.65) !important;
}

.tw-ring-stone-800\/70 {
  --tw-ring-color: rgb(41 37 36 / 0.7) !important;
}

.tw-ring-stone-800\/75 {
  --tw-ring-color: rgb(41 37 36 / 0.75) !important;
}

.tw-ring-stone-800\/80 {
  --tw-ring-color: rgb(41 37 36 / 0.8) !important;
}

.tw-ring-stone-800\/85 {
  --tw-ring-color: rgb(41 37 36 / 0.85) !important;
}

.tw-ring-stone-800\/90 {
  --tw-ring-color: rgb(41 37 36 / 0.9) !important;
}

.tw-ring-stone-800\/95 {
  --tw-ring-color: rgb(41 37 36 / 0.95) !important;
}

.tw-ring-stone-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(28 25 23 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-900\/0 {
  --tw-ring-color: rgb(28 25 23 / 0) !important;
}

.tw-ring-stone-900\/10 {
  --tw-ring-color: rgb(28 25 23 / 0.1) !important;
}

.tw-ring-stone-900\/100 {
  --tw-ring-color: rgb(28 25 23 / 1) !important;
}

.tw-ring-stone-900\/15 {
  --tw-ring-color: rgb(28 25 23 / 0.15) !important;
}

.tw-ring-stone-900\/20 {
  --tw-ring-color: rgb(28 25 23 / 0.2) !important;
}

.tw-ring-stone-900\/25 {
  --tw-ring-color: rgb(28 25 23 / 0.25) !important;
}

.tw-ring-stone-900\/30 {
  --tw-ring-color: rgb(28 25 23 / 0.3) !important;
}

.tw-ring-stone-900\/35 {
  --tw-ring-color: rgb(28 25 23 / 0.35) !important;
}

.tw-ring-stone-900\/40 {
  --tw-ring-color: rgb(28 25 23 / 0.4) !important;
}

.tw-ring-stone-900\/45 {
  --tw-ring-color: rgb(28 25 23 / 0.45) !important;
}

.tw-ring-stone-900\/5 {
  --tw-ring-color: rgb(28 25 23 / 0.05) !important;
}

.tw-ring-stone-900\/50 {
  --tw-ring-color: rgb(28 25 23 / 0.5) !important;
}

.tw-ring-stone-900\/55 {
  --tw-ring-color: rgb(28 25 23 / 0.55) !important;
}

.tw-ring-stone-900\/60 {
  --tw-ring-color: rgb(28 25 23 / 0.6) !important;
}

.tw-ring-stone-900\/65 {
  --tw-ring-color: rgb(28 25 23 / 0.65) !important;
}

.tw-ring-stone-900\/70 {
  --tw-ring-color: rgb(28 25 23 / 0.7) !important;
}

.tw-ring-stone-900\/75 {
  --tw-ring-color: rgb(28 25 23 / 0.75) !important;
}

.tw-ring-stone-900\/80 {
  --tw-ring-color: rgb(28 25 23 / 0.8) !important;
}

.tw-ring-stone-900\/85 {
  --tw-ring-color: rgb(28 25 23 / 0.85) !important;
}

.tw-ring-stone-900\/90 {
  --tw-ring-color: rgb(28 25 23 / 0.9) !important;
}

.tw-ring-stone-900\/95 {
  --tw-ring-color: rgb(28 25 23 / 0.95) !important;
}

.tw-ring-stone-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(12 10 9 / var(--tw-ring-opacity)) !important;
}

.tw-ring-stone-950\/0 {
  --tw-ring-color: rgb(12 10 9 / 0) !important;
}

.tw-ring-stone-950\/10 {
  --tw-ring-color: rgb(12 10 9 / 0.1) !important;
}

.tw-ring-stone-950\/100 {
  --tw-ring-color: rgb(12 10 9 / 1) !important;
}

.tw-ring-stone-950\/15 {
  --tw-ring-color: rgb(12 10 9 / 0.15) !important;
}

.tw-ring-stone-950\/20 {
  --tw-ring-color: rgb(12 10 9 / 0.2) !important;
}

.tw-ring-stone-950\/25 {
  --tw-ring-color: rgb(12 10 9 / 0.25) !important;
}

.tw-ring-stone-950\/30 {
  --tw-ring-color: rgb(12 10 9 / 0.3) !important;
}

.tw-ring-stone-950\/35 {
  --tw-ring-color: rgb(12 10 9 / 0.35) !important;
}

.tw-ring-stone-950\/40 {
  --tw-ring-color: rgb(12 10 9 / 0.4) !important;
}

.tw-ring-stone-950\/45 {
  --tw-ring-color: rgb(12 10 9 / 0.45) !important;
}

.tw-ring-stone-950\/5 {
  --tw-ring-color: rgb(12 10 9 / 0.05) !important;
}

.tw-ring-stone-950\/50 {
  --tw-ring-color: rgb(12 10 9 / 0.5) !important;
}

.tw-ring-stone-950\/55 {
  --tw-ring-color: rgb(12 10 9 / 0.55) !important;
}

.tw-ring-stone-950\/60 {
  --tw-ring-color: rgb(12 10 9 / 0.6) !important;
}

.tw-ring-stone-950\/65 {
  --tw-ring-color: rgb(12 10 9 / 0.65) !important;
}

.tw-ring-stone-950\/70 {
  --tw-ring-color: rgb(12 10 9 / 0.7) !important;
}

.tw-ring-stone-950\/75 {
  --tw-ring-color: rgb(12 10 9 / 0.75) !important;
}

.tw-ring-stone-950\/80 {
  --tw-ring-color: rgb(12 10 9 / 0.8) !important;
}

.tw-ring-stone-950\/85 {
  --tw-ring-color: rgb(12 10 9 / 0.85) !important;
}

.tw-ring-stone-950\/90 {
  --tw-ring-color: rgb(12 10 9 / 0.9) !important;
}

.tw-ring-stone-950\/95 {
  --tw-ring-color: rgb(12 10 9 / 0.95) !important;
}

.tw-ring-teal-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(204 251 241 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-100\/0 {
  --tw-ring-color: rgb(204 251 241 / 0) !important;
}

.tw-ring-teal-100\/10 {
  --tw-ring-color: rgb(204 251 241 / 0.1) !important;
}

.tw-ring-teal-100\/100 {
  --tw-ring-color: rgb(204 251 241 / 1) !important;
}

.tw-ring-teal-100\/15 {
  --tw-ring-color: rgb(204 251 241 / 0.15) !important;
}

.tw-ring-teal-100\/20 {
  --tw-ring-color: rgb(204 251 241 / 0.2) !important;
}

.tw-ring-teal-100\/25 {
  --tw-ring-color: rgb(204 251 241 / 0.25) !important;
}

.tw-ring-teal-100\/30 {
  --tw-ring-color: rgb(204 251 241 / 0.3) !important;
}

.tw-ring-teal-100\/35 {
  --tw-ring-color: rgb(204 251 241 / 0.35) !important;
}

.tw-ring-teal-100\/40 {
  --tw-ring-color: rgb(204 251 241 / 0.4) !important;
}

.tw-ring-teal-100\/45 {
  --tw-ring-color: rgb(204 251 241 / 0.45) !important;
}

.tw-ring-teal-100\/5 {
  --tw-ring-color: rgb(204 251 241 / 0.05) !important;
}

.tw-ring-teal-100\/50 {
  --tw-ring-color: rgb(204 251 241 / 0.5) !important;
}

.tw-ring-teal-100\/55 {
  --tw-ring-color: rgb(204 251 241 / 0.55) !important;
}

.tw-ring-teal-100\/60 {
  --tw-ring-color: rgb(204 251 241 / 0.6) !important;
}

.tw-ring-teal-100\/65 {
  --tw-ring-color: rgb(204 251 241 / 0.65) !important;
}

.tw-ring-teal-100\/70 {
  --tw-ring-color: rgb(204 251 241 / 0.7) !important;
}

.tw-ring-teal-100\/75 {
  --tw-ring-color: rgb(204 251 241 / 0.75) !important;
}

.tw-ring-teal-100\/80 {
  --tw-ring-color: rgb(204 251 241 / 0.8) !important;
}

.tw-ring-teal-100\/85 {
  --tw-ring-color: rgb(204 251 241 / 0.85) !important;
}

.tw-ring-teal-100\/90 {
  --tw-ring-color: rgb(204 251 241 / 0.9) !important;
}

.tw-ring-teal-100\/95 {
  --tw-ring-color: rgb(204 251 241 / 0.95) !important;
}

.tw-ring-teal-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(153 246 228 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-200\/0 {
  --tw-ring-color: rgb(153 246 228 / 0) !important;
}

.tw-ring-teal-200\/10 {
  --tw-ring-color: rgb(153 246 228 / 0.1) !important;
}

.tw-ring-teal-200\/100 {
  --tw-ring-color: rgb(153 246 228 / 1) !important;
}

.tw-ring-teal-200\/15 {
  --tw-ring-color: rgb(153 246 228 / 0.15) !important;
}

.tw-ring-teal-200\/20 {
  --tw-ring-color: rgb(153 246 228 / 0.2) !important;
}

.tw-ring-teal-200\/25 {
  --tw-ring-color: rgb(153 246 228 / 0.25) !important;
}

.tw-ring-teal-200\/30 {
  --tw-ring-color: rgb(153 246 228 / 0.3) !important;
}

.tw-ring-teal-200\/35 {
  --tw-ring-color: rgb(153 246 228 / 0.35) !important;
}

.tw-ring-teal-200\/40 {
  --tw-ring-color: rgb(153 246 228 / 0.4) !important;
}

.tw-ring-teal-200\/45 {
  --tw-ring-color: rgb(153 246 228 / 0.45) !important;
}

.tw-ring-teal-200\/5 {
  --tw-ring-color: rgb(153 246 228 / 0.05) !important;
}

.tw-ring-teal-200\/50 {
  --tw-ring-color: rgb(153 246 228 / 0.5) !important;
}

.tw-ring-teal-200\/55 {
  --tw-ring-color: rgb(153 246 228 / 0.55) !important;
}

.tw-ring-teal-200\/60 {
  --tw-ring-color: rgb(153 246 228 / 0.6) !important;
}

.tw-ring-teal-200\/65 {
  --tw-ring-color: rgb(153 246 228 / 0.65) !important;
}

.tw-ring-teal-200\/70 {
  --tw-ring-color: rgb(153 246 228 / 0.7) !important;
}

.tw-ring-teal-200\/75 {
  --tw-ring-color: rgb(153 246 228 / 0.75) !important;
}

.tw-ring-teal-200\/80 {
  --tw-ring-color: rgb(153 246 228 / 0.8) !important;
}

.tw-ring-teal-200\/85 {
  --tw-ring-color: rgb(153 246 228 / 0.85) !important;
}

.tw-ring-teal-200\/90 {
  --tw-ring-color: rgb(153 246 228 / 0.9) !important;
}

.tw-ring-teal-200\/95 {
  --tw-ring-color: rgb(153 246 228 / 0.95) !important;
}

.tw-ring-teal-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(94 234 212 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-300\/0 {
  --tw-ring-color: rgb(94 234 212 / 0) !important;
}

.tw-ring-teal-300\/10 {
  --tw-ring-color: rgb(94 234 212 / 0.1) !important;
}

.tw-ring-teal-300\/100 {
  --tw-ring-color: rgb(94 234 212 / 1) !important;
}

.tw-ring-teal-300\/15 {
  --tw-ring-color: rgb(94 234 212 / 0.15) !important;
}

.tw-ring-teal-300\/20 {
  --tw-ring-color: rgb(94 234 212 / 0.2) !important;
}

.tw-ring-teal-300\/25 {
  --tw-ring-color: rgb(94 234 212 / 0.25) !important;
}

.tw-ring-teal-300\/30 {
  --tw-ring-color: rgb(94 234 212 / 0.3) !important;
}

.tw-ring-teal-300\/35 {
  --tw-ring-color: rgb(94 234 212 / 0.35) !important;
}

.tw-ring-teal-300\/40 {
  --tw-ring-color: rgb(94 234 212 / 0.4) !important;
}

.tw-ring-teal-300\/45 {
  --tw-ring-color: rgb(94 234 212 / 0.45) !important;
}

.tw-ring-teal-300\/5 {
  --tw-ring-color: rgb(94 234 212 / 0.05) !important;
}

.tw-ring-teal-300\/50 {
  --tw-ring-color: rgb(94 234 212 / 0.5) !important;
}

.tw-ring-teal-300\/55 {
  --tw-ring-color: rgb(94 234 212 / 0.55) !important;
}

.tw-ring-teal-300\/60 {
  --tw-ring-color: rgb(94 234 212 / 0.6) !important;
}

.tw-ring-teal-300\/65 {
  --tw-ring-color: rgb(94 234 212 / 0.65) !important;
}

.tw-ring-teal-300\/70 {
  --tw-ring-color: rgb(94 234 212 / 0.7) !important;
}

.tw-ring-teal-300\/75 {
  --tw-ring-color: rgb(94 234 212 / 0.75) !important;
}

.tw-ring-teal-300\/80 {
  --tw-ring-color: rgb(94 234 212 / 0.8) !important;
}

.tw-ring-teal-300\/85 {
  --tw-ring-color: rgb(94 234 212 / 0.85) !important;
}

.tw-ring-teal-300\/90 {
  --tw-ring-color: rgb(94 234 212 / 0.9) !important;
}

.tw-ring-teal-300\/95 {
  --tw-ring-color: rgb(94 234 212 / 0.95) !important;
}

.tw-ring-teal-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(45 212 191 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-400\/0 {
  --tw-ring-color: rgb(45 212 191 / 0) !important;
}

.tw-ring-teal-400\/10 {
  --tw-ring-color: rgb(45 212 191 / 0.1) !important;
}

.tw-ring-teal-400\/100 {
  --tw-ring-color: rgb(45 212 191 / 1) !important;
}

.tw-ring-teal-400\/15 {
  --tw-ring-color: rgb(45 212 191 / 0.15) !important;
}

.tw-ring-teal-400\/20 {
  --tw-ring-color: rgb(45 212 191 / 0.2) !important;
}

.tw-ring-teal-400\/25 {
  --tw-ring-color: rgb(45 212 191 / 0.25) !important;
}

.tw-ring-teal-400\/30 {
  --tw-ring-color: rgb(45 212 191 / 0.3) !important;
}

.tw-ring-teal-400\/35 {
  --tw-ring-color: rgb(45 212 191 / 0.35) !important;
}

.tw-ring-teal-400\/40 {
  --tw-ring-color: rgb(45 212 191 / 0.4) !important;
}

.tw-ring-teal-400\/45 {
  --tw-ring-color: rgb(45 212 191 / 0.45) !important;
}

.tw-ring-teal-400\/5 {
  --tw-ring-color: rgb(45 212 191 / 0.05) !important;
}

.tw-ring-teal-400\/50 {
  --tw-ring-color: rgb(45 212 191 / 0.5) !important;
}

.tw-ring-teal-400\/55 {
  --tw-ring-color: rgb(45 212 191 / 0.55) !important;
}

.tw-ring-teal-400\/60 {
  --tw-ring-color: rgb(45 212 191 / 0.6) !important;
}

.tw-ring-teal-400\/65 {
  --tw-ring-color: rgb(45 212 191 / 0.65) !important;
}

.tw-ring-teal-400\/70 {
  --tw-ring-color: rgb(45 212 191 / 0.7) !important;
}

.tw-ring-teal-400\/75 {
  --tw-ring-color: rgb(45 212 191 / 0.75) !important;
}

.tw-ring-teal-400\/80 {
  --tw-ring-color: rgb(45 212 191 / 0.8) !important;
}

.tw-ring-teal-400\/85 {
  --tw-ring-color: rgb(45 212 191 / 0.85) !important;
}

.tw-ring-teal-400\/90 {
  --tw-ring-color: rgb(45 212 191 / 0.9) !important;
}

.tw-ring-teal-400\/95 {
  --tw-ring-color: rgb(45 212 191 / 0.95) !important;
}

.tw-ring-teal-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(240 253 250 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-50\/0 {
  --tw-ring-color: rgb(240 253 250 / 0) !important;
}

.tw-ring-teal-50\/10 {
  --tw-ring-color: rgb(240 253 250 / 0.1) !important;
}

.tw-ring-teal-50\/100 {
  --tw-ring-color: rgb(240 253 250 / 1) !important;
}

.tw-ring-teal-50\/15 {
  --tw-ring-color: rgb(240 253 250 / 0.15) !important;
}

.tw-ring-teal-50\/20 {
  --tw-ring-color: rgb(240 253 250 / 0.2) !important;
}

.tw-ring-teal-50\/25 {
  --tw-ring-color: rgb(240 253 250 / 0.25) !important;
}

.tw-ring-teal-50\/30 {
  --tw-ring-color: rgb(240 253 250 / 0.3) !important;
}

.tw-ring-teal-50\/35 {
  --tw-ring-color: rgb(240 253 250 / 0.35) !important;
}

.tw-ring-teal-50\/40 {
  --tw-ring-color: rgb(240 253 250 / 0.4) !important;
}

.tw-ring-teal-50\/45 {
  --tw-ring-color: rgb(240 253 250 / 0.45) !important;
}

.tw-ring-teal-50\/5 {
  --tw-ring-color: rgb(240 253 250 / 0.05) !important;
}

.tw-ring-teal-50\/50 {
  --tw-ring-color: rgb(240 253 250 / 0.5) !important;
}

.tw-ring-teal-50\/55 {
  --tw-ring-color: rgb(240 253 250 / 0.55) !important;
}

.tw-ring-teal-50\/60 {
  --tw-ring-color: rgb(240 253 250 / 0.6) !important;
}

.tw-ring-teal-50\/65 {
  --tw-ring-color: rgb(240 253 250 / 0.65) !important;
}

.tw-ring-teal-50\/70 {
  --tw-ring-color: rgb(240 253 250 / 0.7) !important;
}

.tw-ring-teal-50\/75 {
  --tw-ring-color: rgb(240 253 250 / 0.75) !important;
}

.tw-ring-teal-50\/80 {
  --tw-ring-color: rgb(240 253 250 / 0.8) !important;
}

.tw-ring-teal-50\/85 {
  --tw-ring-color: rgb(240 253 250 / 0.85) !important;
}

.tw-ring-teal-50\/90 {
  --tw-ring-color: rgb(240 253 250 / 0.9) !important;
}

.tw-ring-teal-50\/95 {
  --tw-ring-color: rgb(240 253 250 / 0.95) !important;
}

.tw-ring-teal-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(20 184 166 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-500\/0 {
  --tw-ring-color: rgb(20 184 166 / 0) !important;
}

.tw-ring-teal-500\/10 {
  --tw-ring-color: rgb(20 184 166 / 0.1) !important;
}

.tw-ring-teal-500\/100 {
  --tw-ring-color: rgb(20 184 166 / 1) !important;
}

.tw-ring-teal-500\/15 {
  --tw-ring-color: rgb(20 184 166 / 0.15) !important;
}

.tw-ring-teal-500\/20 {
  --tw-ring-color: rgb(20 184 166 / 0.2) !important;
}

.tw-ring-teal-500\/25 {
  --tw-ring-color: rgb(20 184 166 / 0.25) !important;
}

.tw-ring-teal-500\/30 {
  --tw-ring-color: rgb(20 184 166 / 0.3) !important;
}

.tw-ring-teal-500\/35 {
  --tw-ring-color: rgb(20 184 166 / 0.35) !important;
}

.tw-ring-teal-500\/40 {
  --tw-ring-color: rgb(20 184 166 / 0.4) !important;
}

.tw-ring-teal-500\/45 {
  --tw-ring-color: rgb(20 184 166 / 0.45) !important;
}

.tw-ring-teal-500\/5 {
  --tw-ring-color: rgb(20 184 166 / 0.05) !important;
}

.tw-ring-teal-500\/50 {
  --tw-ring-color: rgb(20 184 166 / 0.5) !important;
}

.tw-ring-teal-500\/55 {
  --tw-ring-color: rgb(20 184 166 / 0.55) !important;
}

.tw-ring-teal-500\/60 {
  --tw-ring-color: rgb(20 184 166 / 0.6) !important;
}

.tw-ring-teal-500\/65 {
  --tw-ring-color: rgb(20 184 166 / 0.65) !important;
}

.tw-ring-teal-500\/70 {
  --tw-ring-color: rgb(20 184 166 / 0.7) !important;
}

.tw-ring-teal-500\/75 {
  --tw-ring-color: rgb(20 184 166 / 0.75) !important;
}

.tw-ring-teal-500\/80 {
  --tw-ring-color: rgb(20 184 166 / 0.8) !important;
}

.tw-ring-teal-500\/85 {
  --tw-ring-color: rgb(20 184 166 / 0.85) !important;
}

.tw-ring-teal-500\/90 {
  --tw-ring-color: rgb(20 184 166 / 0.9) !important;
}

.tw-ring-teal-500\/95 {
  --tw-ring-color: rgb(20 184 166 / 0.95) !important;
}

.tw-ring-teal-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(13 148 136 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-600\/0 {
  --tw-ring-color: rgb(13 148 136 / 0) !important;
}

.tw-ring-teal-600\/10 {
  --tw-ring-color: rgb(13 148 136 / 0.1) !important;
}

.tw-ring-teal-600\/100 {
  --tw-ring-color: rgb(13 148 136 / 1) !important;
}

.tw-ring-teal-600\/15 {
  --tw-ring-color: rgb(13 148 136 / 0.15) !important;
}

.tw-ring-teal-600\/20 {
  --tw-ring-color: rgb(13 148 136 / 0.2) !important;
}

.tw-ring-teal-600\/25 {
  --tw-ring-color: rgb(13 148 136 / 0.25) !important;
}

.tw-ring-teal-600\/30 {
  --tw-ring-color: rgb(13 148 136 / 0.3) !important;
}

.tw-ring-teal-600\/35 {
  --tw-ring-color: rgb(13 148 136 / 0.35) !important;
}

.tw-ring-teal-600\/40 {
  --tw-ring-color: rgb(13 148 136 / 0.4) !important;
}

.tw-ring-teal-600\/45 {
  --tw-ring-color: rgb(13 148 136 / 0.45) !important;
}

.tw-ring-teal-600\/5 {
  --tw-ring-color: rgb(13 148 136 / 0.05) !important;
}

.tw-ring-teal-600\/50 {
  --tw-ring-color: rgb(13 148 136 / 0.5) !important;
}

.tw-ring-teal-600\/55 {
  --tw-ring-color: rgb(13 148 136 / 0.55) !important;
}

.tw-ring-teal-600\/60 {
  --tw-ring-color: rgb(13 148 136 / 0.6) !important;
}

.tw-ring-teal-600\/65 {
  --tw-ring-color: rgb(13 148 136 / 0.65) !important;
}

.tw-ring-teal-600\/70 {
  --tw-ring-color: rgb(13 148 136 / 0.7) !important;
}

.tw-ring-teal-600\/75 {
  --tw-ring-color: rgb(13 148 136 / 0.75) !important;
}

.tw-ring-teal-600\/80 {
  --tw-ring-color: rgb(13 148 136 / 0.8) !important;
}

.tw-ring-teal-600\/85 {
  --tw-ring-color: rgb(13 148 136 / 0.85) !important;
}

.tw-ring-teal-600\/90 {
  --tw-ring-color: rgb(13 148 136 / 0.9) !important;
}

.tw-ring-teal-600\/95 {
  --tw-ring-color: rgb(13 148 136 / 0.95) !important;
}

.tw-ring-teal-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(15 118 110 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-700\/0 {
  --tw-ring-color: rgb(15 118 110 / 0) !important;
}

.tw-ring-teal-700\/10 {
  --tw-ring-color: rgb(15 118 110 / 0.1) !important;
}

.tw-ring-teal-700\/100 {
  --tw-ring-color: rgb(15 118 110 / 1) !important;
}

.tw-ring-teal-700\/15 {
  --tw-ring-color: rgb(15 118 110 / 0.15) !important;
}

.tw-ring-teal-700\/20 {
  --tw-ring-color: rgb(15 118 110 / 0.2) !important;
}

.tw-ring-teal-700\/25 {
  --tw-ring-color: rgb(15 118 110 / 0.25) !important;
}

.tw-ring-teal-700\/30 {
  --tw-ring-color: rgb(15 118 110 / 0.3) !important;
}

.tw-ring-teal-700\/35 {
  --tw-ring-color: rgb(15 118 110 / 0.35) !important;
}

.tw-ring-teal-700\/40 {
  --tw-ring-color: rgb(15 118 110 / 0.4) !important;
}

.tw-ring-teal-700\/45 {
  --tw-ring-color: rgb(15 118 110 / 0.45) !important;
}

.tw-ring-teal-700\/5 {
  --tw-ring-color: rgb(15 118 110 / 0.05) !important;
}

.tw-ring-teal-700\/50 {
  --tw-ring-color: rgb(15 118 110 / 0.5) !important;
}

.tw-ring-teal-700\/55 {
  --tw-ring-color: rgb(15 118 110 / 0.55) !important;
}

.tw-ring-teal-700\/60 {
  --tw-ring-color: rgb(15 118 110 / 0.6) !important;
}

.tw-ring-teal-700\/65 {
  --tw-ring-color: rgb(15 118 110 / 0.65) !important;
}

.tw-ring-teal-700\/70 {
  --tw-ring-color: rgb(15 118 110 / 0.7) !important;
}

.tw-ring-teal-700\/75 {
  --tw-ring-color: rgb(15 118 110 / 0.75) !important;
}

.tw-ring-teal-700\/80 {
  --tw-ring-color: rgb(15 118 110 / 0.8) !important;
}

.tw-ring-teal-700\/85 {
  --tw-ring-color: rgb(15 118 110 / 0.85) !important;
}

.tw-ring-teal-700\/90 {
  --tw-ring-color: rgb(15 118 110 / 0.9) !important;
}

.tw-ring-teal-700\/95 {
  --tw-ring-color: rgb(15 118 110 / 0.95) !important;
}

.tw-ring-teal-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(17 94 89 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-800\/0 {
  --tw-ring-color: rgb(17 94 89 / 0) !important;
}

.tw-ring-teal-800\/10 {
  --tw-ring-color: rgb(17 94 89 / 0.1) !important;
}

.tw-ring-teal-800\/100 {
  --tw-ring-color: rgb(17 94 89 / 1) !important;
}

.tw-ring-teal-800\/15 {
  --tw-ring-color: rgb(17 94 89 / 0.15) !important;
}

.tw-ring-teal-800\/20 {
  --tw-ring-color: rgb(17 94 89 / 0.2) !important;
}

.tw-ring-teal-800\/25 {
  --tw-ring-color: rgb(17 94 89 / 0.25) !important;
}

.tw-ring-teal-800\/30 {
  --tw-ring-color: rgb(17 94 89 / 0.3) !important;
}

.tw-ring-teal-800\/35 {
  --tw-ring-color: rgb(17 94 89 / 0.35) !important;
}

.tw-ring-teal-800\/40 {
  --tw-ring-color: rgb(17 94 89 / 0.4) !important;
}

.tw-ring-teal-800\/45 {
  --tw-ring-color: rgb(17 94 89 / 0.45) !important;
}

.tw-ring-teal-800\/5 {
  --tw-ring-color: rgb(17 94 89 / 0.05) !important;
}

.tw-ring-teal-800\/50 {
  --tw-ring-color: rgb(17 94 89 / 0.5) !important;
}

.tw-ring-teal-800\/55 {
  --tw-ring-color: rgb(17 94 89 / 0.55) !important;
}

.tw-ring-teal-800\/60 {
  --tw-ring-color: rgb(17 94 89 / 0.6) !important;
}

.tw-ring-teal-800\/65 {
  --tw-ring-color: rgb(17 94 89 / 0.65) !important;
}

.tw-ring-teal-800\/70 {
  --tw-ring-color: rgb(17 94 89 / 0.7) !important;
}

.tw-ring-teal-800\/75 {
  --tw-ring-color: rgb(17 94 89 / 0.75) !important;
}

.tw-ring-teal-800\/80 {
  --tw-ring-color: rgb(17 94 89 / 0.8) !important;
}

.tw-ring-teal-800\/85 {
  --tw-ring-color: rgb(17 94 89 / 0.85) !important;
}

.tw-ring-teal-800\/90 {
  --tw-ring-color: rgb(17 94 89 / 0.9) !important;
}

.tw-ring-teal-800\/95 {
  --tw-ring-color: rgb(17 94 89 / 0.95) !important;
}

.tw-ring-teal-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(19 78 74 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-900\/0 {
  --tw-ring-color: rgb(19 78 74 / 0) !important;
}

.tw-ring-teal-900\/10 {
  --tw-ring-color: rgb(19 78 74 / 0.1) !important;
}

.tw-ring-teal-900\/100 {
  --tw-ring-color: rgb(19 78 74 / 1) !important;
}

.tw-ring-teal-900\/15 {
  --tw-ring-color: rgb(19 78 74 / 0.15) !important;
}

.tw-ring-teal-900\/20 {
  --tw-ring-color: rgb(19 78 74 / 0.2) !important;
}

.tw-ring-teal-900\/25 {
  --tw-ring-color: rgb(19 78 74 / 0.25) !important;
}

.tw-ring-teal-900\/30 {
  --tw-ring-color: rgb(19 78 74 / 0.3) !important;
}

.tw-ring-teal-900\/35 {
  --tw-ring-color: rgb(19 78 74 / 0.35) !important;
}

.tw-ring-teal-900\/40 {
  --tw-ring-color: rgb(19 78 74 / 0.4) !important;
}

.tw-ring-teal-900\/45 {
  --tw-ring-color: rgb(19 78 74 / 0.45) !important;
}

.tw-ring-teal-900\/5 {
  --tw-ring-color: rgb(19 78 74 / 0.05) !important;
}

.tw-ring-teal-900\/50 {
  --tw-ring-color: rgb(19 78 74 / 0.5) !important;
}

.tw-ring-teal-900\/55 {
  --tw-ring-color: rgb(19 78 74 / 0.55) !important;
}

.tw-ring-teal-900\/60 {
  --tw-ring-color: rgb(19 78 74 / 0.6) !important;
}

.tw-ring-teal-900\/65 {
  --tw-ring-color: rgb(19 78 74 / 0.65) !important;
}

.tw-ring-teal-900\/70 {
  --tw-ring-color: rgb(19 78 74 / 0.7) !important;
}

.tw-ring-teal-900\/75 {
  --tw-ring-color: rgb(19 78 74 / 0.75) !important;
}

.tw-ring-teal-900\/80 {
  --tw-ring-color: rgb(19 78 74 / 0.8) !important;
}

.tw-ring-teal-900\/85 {
  --tw-ring-color: rgb(19 78 74 / 0.85) !important;
}

.tw-ring-teal-900\/90 {
  --tw-ring-color: rgb(19 78 74 / 0.9) !important;
}

.tw-ring-teal-900\/95 {
  --tw-ring-color: rgb(19 78 74 / 0.95) !important;
}

.tw-ring-teal-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(4 47 46 / var(--tw-ring-opacity)) !important;
}

.tw-ring-teal-950\/0 {
  --tw-ring-color: rgb(4 47 46 / 0) !important;
}

.tw-ring-teal-950\/10 {
  --tw-ring-color: rgb(4 47 46 / 0.1) !important;
}

.tw-ring-teal-950\/100 {
  --tw-ring-color: rgb(4 47 46 / 1) !important;
}

.tw-ring-teal-950\/15 {
  --tw-ring-color: rgb(4 47 46 / 0.15) !important;
}

.tw-ring-teal-950\/20 {
  --tw-ring-color: rgb(4 47 46 / 0.2) !important;
}

.tw-ring-teal-950\/25 {
  --tw-ring-color: rgb(4 47 46 / 0.25) !important;
}

.tw-ring-teal-950\/30 {
  --tw-ring-color: rgb(4 47 46 / 0.3) !important;
}

.tw-ring-teal-950\/35 {
  --tw-ring-color: rgb(4 47 46 / 0.35) !important;
}

.tw-ring-teal-950\/40 {
  --tw-ring-color: rgb(4 47 46 / 0.4) !important;
}

.tw-ring-teal-950\/45 {
  --tw-ring-color: rgb(4 47 46 / 0.45) !important;
}

.tw-ring-teal-950\/5 {
  --tw-ring-color: rgb(4 47 46 / 0.05) !important;
}

.tw-ring-teal-950\/50 {
  --tw-ring-color: rgb(4 47 46 / 0.5) !important;
}

.tw-ring-teal-950\/55 {
  --tw-ring-color: rgb(4 47 46 / 0.55) !important;
}

.tw-ring-teal-950\/60 {
  --tw-ring-color: rgb(4 47 46 / 0.6) !important;
}

.tw-ring-teal-950\/65 {
  --tw-ring-color: rgb(4 47 46 / 0.65) !important;
}

.tw-ring-teal-950\/70 {
  --tw-ring-color: rgb(4 47 46 / 0.7) !important;
}

.tw-ring-teal-950\/75 {
  --tw-ring-color: rgb(4 47 46 / 0.75) !important;
}

.tw-ring-teal-950\/80 {
  --tw-ring-color: rgb(4 47 46 / 0.8) !important;
}

.tw-ring-teal-950\/85 {
  --tw-ring-color: rgb(4 47 46 / 0.85) !important;
}

.tw-ring-teal-950\/90 {
  --tw-ring-color: rgb(4 47 46 / 0.9) !important;
}

.tw-ring-teal-950\/95 {
  --tw-ring-color: rgb(4 47 46 / 0.95) !important;
}

.tw-ring-transparent {
  --tw-ring-color: transparent !important;
}

.tw-ring-transparent\/0 {
  --tw-ring-color: rgb(0 0 0 / 0) !important;
}

.tw-ring-transparent\/10 {
  --tw-ring-color: rgb(0 0 0 / 0.1) !important;
}

.tw-ring-transparent\/100 {
  --tw-ring-color: rgb(0 0 0 / 1) !important;
}

.tw-ring-transparent\/15 {
  --tw-ring-color: rgb(0 0 0 / 0.15) !important;
}

.tw-ring-transparent\/20 {
  --tw-ring-color: rgb(0 0 0 / 0.2) !important;
}

.tw-ring-transparent\/25 {
  --tw-ring-color: rgb(0 0 0 / 0.25) !important;
}

.tw-ring-transparent\/30 {
  --tw-ring-color: rgb(0 0 0 / 0.3) !important;
}

.tw-ring-transparent\/35 {
  --tw-ring-color: rgb(0 0 0 / 0.35) !important;
}

.tw-ring-transparent\/40 {
  --tw-ring-color: rgb(0 0 0 / 0.4) !important;
}

.tw-ring-transparent\/45 {
  --tw-ring-color: rgb(0 0 0 / 0.45) !important;
}

.tw-ring-transparent\/5 {
  --tw-ring-color: rgb(0 0 0 / 0.05) !important;
}

.tw-ring-transparent\/50 {
  --tw-ring-color: rgb(0 0 0 / 0.5) !important;
}

.tw-ring-transparent\/55 {
  --tw-ring-color: rgb(0 0 0 / 0.55) !important;
}

.tw-ring-transparent\/60 {
  --tw-ring-color: rgb(0 0 0 / 0.6) !important;
}

.tw-ring-transparent\/65 {
  --tw-ring-color: rgb(0 0 0 / 0.65) !important;
}

.tw-ring-transparent\/70 {
  --tw-ring-color: rgb(0 0 0 / 0.7) !important;
}

.tw-ring-transparent\/75 {
  --tw-ring-color: rgb(0 0 0 / 0.75) !important;
}

.tw-ring-transparent\/80 {
  --tw-ring-color: rgb(0 0 0 / 0.8) !important;
}

.tw-ring-transparent\/85 {
  --tw-ring-color: rgb(0 0 0 / 0.85) !important;
}

.tw-ring-transparent\/90 {
  --tw-ring-color: rgb(0 0 0 / 0.9) !important;
}

.tw-ring-transparent\/95 {
  --tw-ring-color: rgb(0 0 0 / 0.95) !important;
}

.tw-ring-violet-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(237 233 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-100\/0 {
  --tw-ring-color: rgb(237 233 254 / 0) !important;
}

.tw-ring-violet-100\/10 {
  --tw-ring-color: rgb(237 233 254 / 0.1) !important;
}

.tw-ring-violet-100\/100 {
  --tw-ring-color: rgb(237 233 254 / 1) !important;
}

.tw-ring-violet-100\/15 {
  --tw-ring-color: rgb(237 233 254 / 0.15) !important;
}

.tw-ring-violet-100\/20 {
  --tw-ring-color: rgb(237 233 254 / 0.2) !important;
}

.tw-ring-violet-100\/25 {
  --tw-ring-color: rgb(237 233 254 / 0.25) !important;
}

.tw-ring-violet-100\/30 {
  --tw-ring-color: rgb(237 233 254 / 0.3) !important;
}

.tw-ring-violet-100\/35 {
  --tw-ring-color: rgb(237 233 254 / 0.35) !important;
}

.tw-ring-violet-100\/40 {
  --tw-ring-color: rgb(237 233 254 / 0.4) !important;
}

.tw-ring-violet-100\/45 {
  --tw-ring-color: rgb(237 233 254 / 0.45) !important;
}

.tw-ring-violet-100\/5 {
  --tw-ring-color: rgb(237 233 254 / 0.05) !important;
}

.tw-ring-violet-100\/50 {
  --tw-ring-color: rgb(237 233 254 / 0.5) !important;
}

.tw-ring-violet-100\/55 {
  --tw-ring-color: rgb(237 233 254 / 0.55) !important;
}

.tw-ring-violet-100\/60 {
  --tw-ring-color: rgb(237 233 254 / 0.6) !important;
}

.tw-ring-violet-100\/65 {
  --tw-ring-color: rgb(237 233 254 / 0.65) !important;
}

.tw-ring-violet-100\/70 {
  --tw-ring-color: rgb(237 233 254 / 0.7) !important;
}

.tw-ring-violet-100\/75 {
  --tw-ring-color: rgb(237 233 254 / 0.75) !important;
}

.tw-ring-violet-100\/80 {
  --tw-ring-color: rgb(237 233 254 / 0.8) !important;
}

.tw-ring-violet-100\/85 {
  --tw-ring-color: rgb(237 233 254 / 0.85) !important;
}

.tw-ring-violet-100\/90 {
  --tw-ring-color: rgb(237 233 254 / 0.9) !important;
}

.tw-ring-violet-100\/95 {
  --tw-ring-color: rgb(237 233 254 / 0.95) !important;
}

.tw-ring-violet-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(221 214 254 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-200\/0 {
  --tw-ring-color: rgb(221 214 254 / 0) !important;
}

.tw-ring-violet-200\/10 {
  --tw-ring-color: rgb(221 214 254 / 0.1) !important;
}

.tw-ring-violet-200\/100 {
  --tw-ring-color: rgb(221 214 254 / 1) !important;
}

.tw-ring-violet-200\/15 {
  --tw-ring-color: rgb(221 214 254 / 0.15) !important;
}

.tw-ring-violet-200\/20 {
  --tw-ring-color: rgb(221 214 254 / 0.2) !important;
}

.tw-ring-violet-200\/25 {
  --tw-ring-color: rgb(221 214 254 / 0.25) !important;
}

.tw-ring-violet-200\/30 {
  --tw-ring-color: rgb(221 214 254 / 0.3) !important;
}

.tw-ring-violet-200\/35 {
  --tw-ring-color: rgb(221 214 254 / 0.35) !important;
}

.tw-ring-violet-200\/40 {
  --tw-ring-color: rgb(221 214 254 / 0.4) !important;
}

.tw-ring-violet-200\/45 {
  --tw-ring-color: rgb(221 214 254 / 0.45) !important;
}

.tw-ring-violet-200\/5 {
  --tw-ring-color: rgb(221 214 254 / 0.05) !important;
}

.tw-ring-violet-200\/50 {
  --tw-ring-color: rgb(221 214 254 / 0.5) !important;
}

.tw-ring-violet-200\/55 {
  --tw-ring-color: rgb(221 214 254 / 0.55) !important;
}

.tw-ring-violet-200\/60 {
  --tw-ring-color: rgb(221 214 254 / 0.6) !important;
}

.tw-ring-violet-200\/65 {
  --tw-ring-color: rgb(221 214 254 / 0.65) !important;
}

.tw-ring-violet-200\/70 {
  --tw-ring-color: rgb(221 214 254 / 0.7) !important;
}

.tw-ring-violet-200\/75 {
  --tw-ring-color: rgb(221 214 254 / 0.75) !important;
}

.tw-ring-violet-200\/80 {
  --tw-ring-color: rgb(221 214 254 / 0.8) !important;
}

.tw-ring-violet-200\/85 {
  --tw-ring-color: rgb(221 214 254 / 0.85) !important;
}

.tw-ring-violet-200\/90 {
  --tw-ring-color: rgb(221 214 254 / 0.9) !important;
}

.tw-ring-violet-200\/95 {
  --tw-ring-color: rgb(221 214 254 / 0.95) !important;
}

.tw-ring-violet-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(196 181 253 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-300\/0 {
  --tw-ring-color: rgb(196 181 253 / 0) !important;
}

.tw-ring-violet-300\/10 {
  --tw-ring-color: rgb(196 181 253 / 0.1) !important;
}

.tw-ring-violet-300\/100 {
  --tw-ring-color: rgb(196 181 253 / 1) !important;
}

.tw-ring-violet-300\/15 {
  --tw-ring-color: rgb(196 181 253 / 0.15) !important;
}

.tw-ring-violet-300\/20 {
  --tw-ring-color: rgb(196 181 253 / 0.2) !important;
}

.tw-ring-violet-300\/25 {
  --tw-ring-color: rgb(196 181 253 / 0.25) !important;
}

.tw-ring-violet-300\/30 {
  --tw-ring-color: rgb(196 181 253 / 0.3) !important;
}

.tw-ring-violet-300\/35 {
  --tw-ring-color: rgb(196 181 253 / 0.35) !important;
}

.tw-ring-violet-300\/40 {
  --tw-ring-color: rgb(196 181 253 / 0.4) !important;
}

.tw-ring-violet-300\/45 {
  --tw-ring-color: rgb(196 181 253 / 0.45) !important;
}

.tw-ring-violet-300\/5 {
  --tw-ring-color: rgb(196 181 253 / 0.05) !important;
}

.tw-ring-violet-300\/50 {
  --tw-ring-color: rgb(196 181 253 / 0.5) !important;
}

.tw-ring-violet-300\/55 {
  --tw-ring-color: rgb(196 181 253 / 0.55) !important;
}

.tw-ring-violet-300\/60 {
  --tw-ring-color: rgb(196 181 253 / 0.6) !important;
}

.tw-ring-violet-300\/65 {
  --tw-ring-color: rgb(196 181 253 / 0.65) !important;
}

.tw-ring-violet-300\/70 {
  --tw-ring-color: rgb(196 181 253 / 0.7) !important;
}

.tw-ring-violet-300\/75 {
  --tw-ring-color: rgb(196 181 253 / 0.75) !important;
}

.tw-ring-violet-300\/80 {
  --tw-ring-color: rgb(196 181 253 / 0.8) !important;
}

.tw-ring-violet-300\/85 {
  --tw-ring-color: rgb(196 181 253 / 0.85) !important;
}

.tw-ring-violet-300\/90 {
  --tw-ring-color: rgb(196 181 253 / 0.9) !important;
}

.tw-ring-violet-300\/95 {
  --tw-ring-color: rgb(196 181 253 / 0.95) !important;
}

.tw-ring-violet-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(167 139 250 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-400\/0 {
  --tw-ring-color: rgb(167 139 250 / 0) !important;
}

.tw-ring-violet-400\/10 {
  --tw-ring-color: rgb(167 139 250 / 0.1) !important;
}

.tw-ring-violet-400\/100 {
  --tw-ring-color: rgb(167 139 250 / 1) !important;
}

.tw-ring-violet-400\/15 {
  --tw-ring-color: rgb(167 139 250 / 0.15) !important;
}

.tw-ring-violet-400\/20 {
  --tw-ring-color: rgb(167 139 250 / 0.2) !important;
}

.tw-ring-violet-400\/25 {
  --tw-ring-color: rgb(167 139 250 / 0.25) !important;
}

.tw-ring-violet-400\/30 {
  --tw-ring-color: rgb(167 139 250 / 0.3) !important;
}

.tw-ring-violet-400\/35 {
  --tw-ring-color: rgb(167 139 250 / 0.35) !important;
}

.tw-ring-violet-400\/40 {
  --tw-ring-color: rgb(167 139 250 / 0.4) !important;
}

.tw-ring-violet-400\/45 {
  --tw-ring-color: rgb(167 139 250 / 0.45) !important;
}

.tw-ring-violet-400\/5 {
  --tw-ring-color: rgb(167 139 250 / 0.05) !important;
}

.tw-ring-violet-400\/50 {
  --tw-ring-color: rgb(167 139 250 / 0.5) !important;
}

.tw-ring-violet-400\/55 {
  --tw-ring-color: rgb(167 139 250 / 0.55) !important;
}

.tw-ring-violet-400\/60 {
  --tw-ring-color: rgb(167 139 250 / 0.6) !important;
}

.tw-ring-violet-400\/65 {
  --tw-ring-color: rgb(167 139 250 / 0.65) !important;
}

.tw-ring-violet-400\/70 {
  --tw-ring-color: rgb(167 139 250 / 0.7) !important;
}

.tw-ring-violet-400\/75 {
  --tw-ring-color: rgb(167 139 250 / 0.75) !important;
}

.tw-ring-violet-400\/80 {
  --tw-ring-color: rgb(167 139 250 / 0.8) !important;
}

.tw-ring-violet-400\/85 {
  --tw-ring-color: rgb(167 139 250 / 0.85) !important;
}

.tw-ring-violet-400\/90 {
  --tw-ring-color: rgb(167 139 250 / 0.9) !important;
}

.tw-ring-violet-400\/95 {
  --tw-ring-color: rgb(167 139 250 / 0.95) !important;
}

.tw-ring-violet-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(245 243 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-50\/0 {
  --tw-ring-color: rgb(245 243 255 / 0) !important;
}

.tw-ring-violet-50\/10 {
  --tw-ring-color: rgb(245 243 255 / 0.1) !important;
}

.tw-ring-violet-50\/100 {
  --tw-ring-color: rgb(245 243 255 / 1) !important;
}

.tw-ring-violet-50\/15 {
  --tw-ring-color: rgb(245 243 255 / 0.15) !important;
}

.tw-ring-violet-50\/20 {
  --tw-ring-color: rgb(245 243 255 / 0.2) !important;
}

.tw-ring-violet-50\/25 {
  --tw-ring-color: rgb(245 243 255 / 0.25) !important;
}

.tw-ring-violet-50\/30 {
  --tw-ring-color: rgb(245 243 255 / 0.3) !important;
}

.tw-ring-violet-50\/35 {
  --tw-ring-color: rgb(245 243 255 / 0.35) !important;
}

.tw-ring-violet-50\/40 {
  --tw-ring-color: rgb(245 243 255 / 0.4) !important;
}

.tw-ring-violet-50\/45 {
  --tw-ring-color: rgb(245 243 255 / 0.45) !important;
}

.tw-ring-violet-50\/5 {
  --tw-ring-color: rgb(245 243 255 / 0.05) !important;
}

.tw-ring-violet-50\/50 {
  --tw-ring-color: rgb(245 243 255 / 0.5) !important;
}

.tw-ring-violet-50\/55 {
  --tw-ring-color: rgb(245 243 255 / 0.55) !important;
}

.tw-ring-violet-50\/60 {
  --tw-ring-color: rgb(245 243 255 / 0.6) !important;
}

.tw-ring-violet-50\/65 {
  --tw-ring-color: rgb(245 243 255 / 0.65) !important;
}

.tw-ring-violet-50\/70 {
  --tw-ring-color: rgb(245 243 255 / 0.7) !important;
}

.tw-ring-violet-50\/75 {
  --tw-ring-color: rgb(245 243 255 / 0.75) !important;
}

.tw-ring-violet-50\/80 {
  --tw-ring-color: rgb(245 243 255 / 0.8) !important;
}

.tw-ring-violet-50\/85 {
  --tw-ring-color: rgb(245 243 255 / 0.85) !important;
}

.tw-ring-violet-50\/90 {
  --tw-ring-color: rgb(245 243 255 / 0.9) !important;
}

.tw-ring-violet-50\/95 {
  --tw-ring-color: rgb(245 243 255 / 0.95) !important;
}

.tw-ring-violet-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(139 92 246 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-500\/0 {
  --tw-ring-color: rgb(139 92 246 / 0) !important;
}

.tw-ring-violet-500\/10 {
  --tw-ring-color: rgb(139 92 246 / 0.1) !important;
}

.tw-ring-violet-500\/100 {
  --tw-ring-color: rgb(139 92 246 / 1) !important;
}

.tw-ring-violet-500\/15 {
  --tw-ring-color: rgb(139 92 246 / 0.15) !important;
}

.tw-ring-violet-500\/20 {
  --tw-ring-color: rgb(139 92 246 / 0.2) !important;
}

.tw-ring-violet-500\/25 {
  --tw-ring-color: rgb(139 92 246 / 0.25) !important;
}

.tw-ring-violet-500\/30 {
  --tw-ring-color: rgb(139 92 246 / 0.3) !important;
}

.tw-ring-violet-500\/35 {
  --tw-ring-color: rgb(139 92 246 / 0.35) !important;
}

.tw-ring-violet-500\/40 {
  --tw-ring-color: rgb(139 92 246 / 0.4) !important;
}

.tw-ring-violet-500\/45 {
  --tw-ring-color: rgb(139 92 246 / 0.45) !important;
}

.tw-ring-violet-500\/5 {
  --tw-ring-color: rgb(139 92 246 / 0.05) !important;
}

.tw-ring-violet-500\/50 {
  --tw-ring-color: rgb(139 92 246 / 0.5) !important;
}

.tw-ring-violet-500\/55 {
  --tw-ring-color: rgb(139 92 246 / 0.55) !important;
}

.tw-ring-violet-500\/60 {
  --tw-ring-color: rgb(139 92 246 / 0.6) !important;
}

.tw-ring-violet-500\/65 {
  --tw-ring-color: rgb(139 92 246 / 0.65) !important;
}

.tw-ring-violet-500\/70 {
  --tw-ring-color: rgb(139 92 246 / 0.7) !important;
}

.tw-ring-violet-500\/75 {
  --tw-ring-color: rgb(139 92 246 / 0.75) !important;
}

.tw-ring-violet-500\/80 {
  --tw-ring-color: rgb(139 92 246 / 0.8) !important;
}

.tw-ring-violet-500\/85 {
  --tw-ring-color: rgb(139 92 246 / 0.85) !important;
}

.tw-ring-violet-500\/90 {
  --tw-ring-color: rgb(139 92 246 / 0.9) !important;
}

.tw-ring-violet-500\/95 {
  --tw-ring-color: rgb(139 92 246 / 0.95) !important;
}

.tw-ring-violet-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(124 58 237 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-600\/0 {
  --tw-ring-color: rgb(124 58 237 / 0) !important;
}

.tw-ring-violet-600\/10 {
  --tw-ring-color: rgb(124 58 237 / 0.1) !important;
}

.tw-ring-violet-600\/100 {
  --tw-ring-color: rgb(124 58 237 / 1) !important;
}

.tw-ring-violet-600\/15 {
  --tw-ring-color: rgb(124 58 237 / 0.15) !important;
}

.tw-ring-violet-600\/20 {
  --tw-ring-color: rgb(124 58 237 / 0.2) !important;
}

.tw-ring-violet-600\/25 {
  --tw-ring-color: rgb(124 58 237 / 0.25) !important;
}

.tw-ring-violet-600\/30 {
  --tw-ring-color: rgb(124 58 237 / 0.3) !important;
}

.tw-ring-violet-600\/35 {
  --tw-ring-color: rgb(124 58 237 / 0.35) !important;
}

.tw-ring-violet-600\/40 {
  --tw-ring-color: rgb(124 58 237 / 0.4) !important;
}

.tw-ring-violet-600\/45 {
  --tw-ring-color: rgb(124 58 237 / 0.45) !important;
}

.tw-ring-violet-600\/5 {
  --tw-ring-color: rgb(124 58 237 / 0.05) !important;
}

.tw-ring-violet-600\/50 {
  --tw-ring-color: rgb(124 58 237 / 0.5) !important;
}

.tw-ring-violet-600\/55 {
  --tw-ring-color: rgb(124 58 237 / 0.55) !important;
}

.tw-ring-violet-600\/60 {
  --tw-ring-color: rgb(124 58 237 / 0.6) !important;
}

.tw-ring-violet-600\/65 {
  --tw-ring-color: rgb(124 58 237 / 0.65) !important;
}

.tw-ring-violet-600\/70 {
  --tw-ring-color: rgb(124 58 237 / 0.7) !important;
}

.tw-ring-violet-600\/75 {
  --tw-ring-color: rgb(124 58 237 / 0.75) !important;
}

.tw-ring-violet-600\/80 {
  --tw-ring-color: rgb(124 58 237 / 0.8) !important;
}

.tw-ring-violet-600\/85 {
  --tw-ring-color: rgb(124 58 237 / 0.85) !important;
}

.tw-ring-violet-600\/90 {
  --tw-ring-color: rgb(124 58 237 / 0.9) !important;
}

.tw-ring-violet-600\/95 {
  --tw-ring-color: rgb(124 58 237 / 0.95) !important;
}

.tw-ring-violet-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(109 40 217 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-700\/0 {
  --tw-ring-color: rgb(109 40 217 / 0) !important;
}

.tw-ring-violet-700\/10 {
  --tw-ring-color: rgb(109 40 217 / 0.1) !important;
}

.tw-ring-violet-700\/100 {
  --tw-ring-color: rgb(109 40 217 / 1) !important;
}

.tw-ring-violet-700\/15 {
  --tw-ring-color: rgb(109 40 217 / 0.15) !important;
}

.tw-ring-violet-700\/20 {
  --tw-ring-color: rgb(109 40 217 / 0.2) !important;
}

.tw-ring-violet-700\/25 {
  --tw-ring-color: rgb(109 40 217 / 0.25) !important;
}

.tw-ring-violet-700\/30 {
  --tw-ring-color: rgb(109 40 217 / 0.3) !important;
}

.tw-ring-violet-700\/35 {
  --tw-ring-color: rgb(109 40 217 / 0.35) !important;
}

.tw-ring-violet-700\/40 {
  --tw-ring-color: rgb(109 40 217 / 0.4) !important;
}

.tw-ring-violet-700\/45 {
  --tw-ring-color: rgb(109 40 217 / 0.45) !important;
}

.tw-ring-violet-700\/5 {
  --tw-ring-color: rgb(109 40 217 / 0.05) !important;
}

.tw-ring-violet-700\/50 {
  --tw-ring-color: rgb(109 40 217 / 0.5) !important;
}

.tw-ring-violet-700\/55 {
  --tw-ring-color: rgb(109 40 217 / 0.55) !important;
}

.tw-ring-violet-700\/60 {
  --tw-ring-color: rgb(109 40 217 / 0.6) !important;
}

.tw-ring-violet-700\/65 {
  --tw-ring-color: rgb(109 40 217 / 0.65) !important;
}

.tw-ring-violet-700\/70 {
  --tw-ring-color: rgb(109 40 217 / 0.7) !important;
}

.tw-ring-violet-700\/75 {
  --tw-ring-color: rgb(109 40 217 / 0.75) !important;
}

.tw-ring-violet-700\/80 {
  --tw-ring-color: rgb(109 40 217 / 0.8) !important;
}

.tw-ring-violet-700\/85 {
  --tw-ring-color: rgb(109 40 217 / 0.85) !important;
}

.tw-ring-violet-700\/90 {
  --tw-ring-color: rgb(109 40 217 / 0.9) !important;
}

.tw-ring-violet-700\/95 {
  --tw-ring-color: rgb(109 40 217 / 0.95) !important;
}

.tw-ring-violet-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(91 33 182 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-800\/0 {
  --tw-ring-color: rgb(91 33 182 / 0) !important;
}

.tw-ring-violet-800\/10 {
  --tw-ring-color: rgb(91 33 182 / 0.1) !important;
}

.tw-ring-violet-800\/100 {
  --tw-ring-color: rgb(91 33 182 / 1) !important;
}

.tw-ring-violet-800\/15 {
  --tw-ring-color: rgb(91 33 182 / 0.15) !important;
}

.tw-ring-violet-800\/20 {
  --tw-ring-color: rgb(91 33 182 / 0.2) !important;
}

.tw-ring-violet-800\/25 {
  --tw-ring-color: rgb(91 33 182 / 0.25) !important;
}

.tw-ring-violet-800\/30 {
  --tw-ring-color: rgb(91 33 182 / 0.3) !important;
}

.tw-ring-violet-800\/35 {
  --tw-ring-color: rgb(91 33 182 / 0.35) !important;
}

.tw-ring-violet-800\/40 {
  --tw-ring-color: rgb(91 33 182 / 0.4) !important;
}

.tw-ring-violet-800\/45 {
  --tw-ring-color: rgb(91 33 182 / 0.45) !important;
}

.tw-ring-violet-800\/5 {
  --tw-ring-color: rgb(91 33 182 / 0.05) !important;
}

.tw-ring-violet-800\/50 {
  --tw-ring-color: rgb(91 33 182 / 0.5) !important;
}

.tw-ring-violet-800\/55 {
  --tw-ring-color: rgb(91 33 182 / 0.55) !important;
}

.tw-ring-violet-800\/60 {
  --tw-ring-color: rgb(91 33 182 / 0.6) !important;
}

.tw-ring-violet-800\/65 {
  --tw-ring-color: rgb(91 33 182 / 0.65) !important;
}

.tw-ring-violet-800\/70 {
  --tw-ring-color: rgb(91 33 182 / 0.7) !important;
}

.tw-ring-violet-800\/75 {
  --tw-ring-color: rgb(91 33 182 / 0.75) !important;
}

.tw-ring-violet-800\/80 {
  --tw-ring-color: rgb(91 33 182 / 0.8) !important;
}

.tw-ring-violet-800\/85 {
  --tw-ring-color: rgb(91 33 182 / 0.85) !important;
}

.tw-ring-violet-800\/90 {
  --tw-ring-color: rgb(91 33 182 / 0.9) !important;
}

.tw-ring-violet-800\/95 {
  --tw-ring-color: rgb(91 33 182 / 0.95) !important;
}

.tw-ring-violet-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(76 29 149 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-900\/0 {
  --tw-ring-color: rgb(76 29 149 / 0) !important;
}

.tw-ring-violet-900\/10 {
  --tw-ring-color: rgb(76 29 149 / 0.1) !important;
}

.tw-ring-violet-900\/100 {
  --tw-ring-color: rgb(76 29 149 / 1) !important;
}

.tw-ring-violet-900\/15 {
  --tw-ring-color: rgb(76 29 149 / 0.15) !important;
}

.tw-ring-violet-900\/20 {
  --tw-ring-color: rgb(76 29 149 / 0.2) !important;
}

.tw-ring-violet-900\/25 {
  --tw-ring-color: rgb(76 29 149 / 0.25) !important;
}

.tw-ring-violet-900\/30 {
  --tw-ring-color: rgb(76 29 149 / 0.3) !important;
}

.tw-ring-violet-900\/35 {
  --tw-ring-color: rgb(76 29 149 / 0.35) !important;
}

.tw-ring-violet-900\/40 {
  --tw-ring-color: rgb(76 29 149 / 0.4) !important;
}

.tw-ring-violet-900\/45 {
  --tw-ring-color: rgb(76 29 149 / 0.45) !important;
}

.tw-ring-violet-900\/5 {
  --tw-ring-color: rgb(76 29 149 / 0.05) !important;
}

.tw-ring-violet-900\/50 {
  --tw-ring-color: rgb(76 29 149 / 0.5) !important;
}

.tw-ring-violet-900\/55 {
  --tw-ring-color: rgb(76 29 149 / 0.55) !important;
}

.tw-ring-violet-900\/60 {
  --tw-ring-color: rgb(76 29 149 / 0.6) !important;
}

.tw-ring-violet-900\/65 {
  --tw-ring-color: rgb(76 29 149 / 0.65) !important;
}

.tw-ring-violet-900\/70 {
  --tw-ring-color: rgb(76 29 149 / 0.7) !important;
}

.tw-ring-violet-900\/75 {
  --tw-ring-color: rgb(76 29 149 / 0.75) !important;
}

.tw-ring-violet-900\/80 {
  --tw-ring-color: rgb(76 29 149 / 0.8) !important;
}

.tw-ring-violet-900\/85 {
  --tw-ring-color: rgb(76 29 149 / 0.85) !important;
}

.tw-ring-violet-900\/90 {
  --tw-ring-color: rgb(76 29 149 / 0.9) !important;
}

.tw-ring-violet-900\/95 {
  --tw-ring-color: rgb(76 29 149 / 0.95) !important;
}

.tw-ring-violet-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(46 16 101 / var(--tw-ring-opacity)) !important;
}

.tw-ring-violet-950\/0 {
  --tw-ring-color: rgb(46 16 101 / 0) !important;
}

.tw-ring-violet-950\/10 {
  --tw-ring-color: rgb(46 16 101 / 0.1) !important;
}

.tw-ring-violet-950\/100 {
  --tw-ring-color: rgb(46 16 101 / 1) !important;
}

.tw-ring-violet-950\/15 {
  --tw-ring-color: rgb(46 16 101 / 0.15) !important;
}

.tw-ring-violet-950\/20 {
  --tw-ring-color: rgb(46 16 101 / 0.2) !important;
}

.tw-ring-violet-950\/25 {
  --tw-ring-color: rgb(46 16 101 / 0.25) !important;
}

.tw-ring-violet-950\/30 {
  --tw-ring-color: rgb(46 16 101 / 0.3) !important;
}

.tw-ring-violet-950\/35 {
  --tw-ring-color: rgb(46 16 101 / 0.35) !important;
}

.tw-ring-violet-950\/40 {
  --tw-ring-color: rgb(46 16 101 / 0.4) !important;
}

.tw-ring-violet-950\/45 {
  --tw-ring-color: rgb(46 16 101 / 0.45) !important;
}

.tw-ring-violet-950\/5 {
  --tw-ring-color: rgb(46 16 101 / 0.05) !important;
}

.tw-ring-violet-950\/50 {
  --tw-ring-color: rgb(46 16 101 / 0.5) !important;
}

.tw-ring-violet-950\/55 {
  --tw-ring-color: rgb(46 16 101 / 0.55) !important;
}

.tw-ring-violet-950\/60 {
  --tw-ring-color: rgb(46 16 101 / 0.6) !important;
}

.tw-ring-violet-950\/65 {
  --tw-ring-color: rgb(46 16 101 / 0.65) !important;
}

.tw-ring-violet-950\/70 {
  --tw-ring-color: rgb(46 16 101 / 0.7) !important;
}

.tw-ring-violet-950\/75 {
  --tw-ring-color: rgb(46 16 101 / 0.75) !important;
}

.tw-ring-violet-950\/80 {
  --tw-ring-color: rgb(46 16 101 / 0.8) !important;
}

.tw-ring-violet-950\/85 {
  --tw-ring-color: rgb(46 16 101 / 0.85) !important;
}

.tw-ring-violet-950\/90 {
  --tw-ring-color: rgb(46 16 101 / 0.9) !important;
}

.tw-ring-violet-950\/95 {
  --tw-ring-color: rgb(46 16 101 / 0.95) !important;
}

.tw-ring-white {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(255 255 255 / var(--tw-ring-opacity)) !important;
}

.tw-ring-white\/0 {
  --tw-ring-color: rgb(255 255 255 / 0) !important;
}

.tw-ring-white\/10 {
  --tw-ring-color: rgb(255 255 255 / 0.1) !important;
}

.tw-ring-white\/100 {
  --tw-ring-color: rgb(255 255 255 / 1) !important;
}

.tw-ring-white\/15 {
  --tw-ring-color: rgb(255 255 255 / 0.15) !important;
}

.tw-ring-white\/20 {
  --tw-ring-color: rgb(255 255 255 / 0.2) !important;
}

.tw-ring-white\/25 {
  --tw-ring-color: rgb(255 255 255 / 0.25) !important;
}

.tw-ring-white\/30 {
  --tw-ring-color: rgb(255 255 255 / 0.3) !important;
}

.tw-ring-white\/35 {
  --tw-ring-color: rgb(255 255 255 / 0.35) !important;
}

.tw-ring-white\/40 {
  --tw-ring-color: rgb(255 255 255 / 0.4) !important;
}

.tw-ring-white\/45 {
  --tw-ring-color: rgb(255 255 255 / 0.45) !important;
}

.tw-ring-white\/5 {
  --tw-ring-color: rgb(255 255 255 / 0.05) !important;
}

.tw-ring-white\/50 {
  --tw-ring-color: rgb(255 255 255 / 0.5) !important;
}

.tw-ring-white\/55 {
  --tw-ring-color: rgb(255 255 255 / 0.55) !important;
}

.tw-ring-white\/60 {
  --tw-ring-color: rgb(255 255 255 / 0.6) !important;
}

.tw-ring-white\/65 {
  --tw-ring-color: rgb(255 255 255 / 0.65) !important;
}

.tw-ring-white\/70 {
  --tw-ring-color: rgb(255 255 255 / 0.7) !important;
}

.tw-ring-white\/75 {
  --tw-ring-color: rgb(255 255 255 / 0.75) !important;
}

.tw-ring-white\/80 {
  --tw-ring-color: rgb(255 255 255 / 0.8) !important;
}

.tw-ring-white\/85 {
  --tw-ring-color: rgb(255 255 255 / 0.85) !important;
}

.tw-ring-white\/90 {
  --tw-ring-color: rgb(255 255 255 / 0.9) !important;
}

.tw-ring-white\/95 {
  --tw-ring-color: rgb(255 255 255 / 0.95) !important;
}

.tw-ring-yellow-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 249 195 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-100\/0 {
  --tw-ring-color: rgb(254 249 195 / 0) !important;
}

.tw-ring-yellow-100\/10 {
  --tw-ring-color: rgb(254 249 195 / 0.1) !important;
}

.tw-ring-yellow-100\/100 {
  --tw-ring-color: rgb(254 249 195 / 1) !important;
}

.tw-ring-yellow-100\/15 {
  --tw-ring-color: rgb(254 249 195 / 0.15) !important;
}

.tw-ring-yellow-100\/20 {
  --tw-ring-color: rgb(254 249 195 / 0.2) !important;
}

.tw-ring-yellow-100\/25 {
  --tw-ring-color: rgb(254 249 195 / 0.25) !important;
}

.tw-ring-yellow-100\/30 {
  --tw-ring-color: rgb(254 249 195 / 0.3) !important;
}

.tw-ring-yellow-100\/35 {
  --tw-ring-color: rgb(254 249 195 / 0.35) !important;
}

.tw-ring-yellow-100\/40 {
  --tw-ring-color: rgb(254 249 195 / 0.4) !important;
}

.tw-ring-yellow-100\/45 {
  --tw-ring-color: rgb(254 249 195 / 0.45) !important;
}

.tw-ring-yellow-100\/5 {
  --tw-ring-color: rgb(254 249 195 / 0.05) !important;
}

.tw-ring-yellow-100\/50 {
  --tw-ring-color: rgb(254 249 195 / 0.5) !important;
}

.tw-ring-yellow-100\/55 {
  --tw-ring-color: rgb(254 249 195 / 0.55) !important;
}

.tw-ring-yellow-100\/60 {
  --tw-ring-color: rgb(254 249 195 / 0.6) !important;
}

.tw-ring-yellow-100\/65 {
  --tw-ring-color: rgb(254 249 195 / 0.65) !important;
}

.tw-ring-yellow-100\/70 {
  --tw-ring-color: rgb(254 249 195 / 0.7) !important;
}

.tw-ring-yellow-100\/75 {
  --tw-ring-color: rgb(254 249 195 / 0.75) !important;
}

.tw-ring-yellow-100\/80 {
  --tw-ring-color: rgb(254 249 195 / 0.8) !important;
}

.tw-ring-yellow-100\/85 {
  --tw-ring-color: rgb(254 249 195 / 0.85) !important;
}

.tw-ring-yellow-100\/90 {
  --tw-ring-color: rgb(254 249 195 / 0.9) !important;
}

.tw-ring-yellow-100\/95 {
  --tw-ring-color: rgb(254 249 195 / 0.95) !important;
}

.tw-ring-yellow-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 240 138 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-200\/0 {
  --tw-ring-color: rgb(254 240 138 / 0) !important;
}

.tw-ring-yellow-200\/10 {
  --tw-ring-color: rgb(254 240 138 / 0.1) !important;
}

.tw-ring-yellow-200\/100 {
  --tw-ring-color: rgb(254 240 138 / 1) !important;
}

.tw-ring-yellow-200\/15 {
  --tw-ring-color: rgb(254 240 138 / 0.15) !important;
}

.tw-ring-yellow-200\/20 {
  --tw-ring-color: rgb(254 240 138 / 0.2) !important;
}

.tw-ring-yellow-200\/25 {
  --tw-ring-color: rgb(254 240 138 / 0.25) !important;
}

.tw-ring-yellow-200\/30 {
  --tw-ring-color: rgb(254 240 138 / 0.3) !important;
}

.tw-ring-yellow-200\/35 {
  --tw-ring-color: rgb(254 240 138 / 0.35) !important;
}

.tw-ring-yellow-200\/40 {
  --tw-ring-color: rgb(254 240 138 / 0.4) !important;
}

.tw-ring-yellow-200\/45 {
  --tw-ring-color: rgb(254 240 138 / 0.45) !important;
}

.tw-ring-yellow-200\/5 {
  --tw-ring-color: rgb(254 240 138 / 0.05) !important;
}

.tw-ring-yellow-200\/50 {
  --tw-ring-color: rgb(254 240 138 / 0.5) !important;
}

.tw-ring-yellow-200\/55 {
  --tw-ring-color: rgb(254 240 138 / 0.55) !important;
}

.tw-ring-yellow-200\/60 {
  --tw-ring-color: rgb(254 240 138 / 0.6) !important;
}

.tw-ring-yellow-200\/65 {
  --tw-ring-color: rgb(254 240 138 / 0.65) !important;
}

.tw-ring-yellow-200\/70 {
  --tw-ring-color: rgb(254 240 138 / 0.7) !important;
}

.tw-ring-yellow-200\/75 {
  --tw-ring-color: rgb(254 240 138 / 0.75) !important;
}

.tw-ring-yellow-200\/80 {
  --tw-ring-color: rgb(254 240 138 / 0.8) !important;
}

.tw-ring-yellow-200\/85 {
  --tw-ring-color: rgb(254 240 138 / 0.85) !important;
}

.tw-ring-yellow-200\/90 {
  --tw-ring-color: rgb(254 240 138 / 0.9) !important;
}

.tw-ring-yellow-200\/95 {
  --tw-ring-color: rgb(254 240 138 / 0.95) !important;
}

.tw-ring-yellow-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(253 224 71 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-300\/0 {
  --tw-ring-color: rgb(253 224 71 / 0) !important;
}

.tw-ring-yellow-300\/10 {
  --tw-ring-color: rgb(253 224 71 / 0.1) !important;
}

.tw-ring-yellow-300\/100 {
  --tw-ring-color: rgb(253 224 71 / 1) !important;
}

.tw-ring-yellow-300\/15 {
  --tw-ring-color: rgb(253 224 71 / 0.15) !important;
}

.tw-ring-yellow-300\/20 {
  --tw-ring-color: rgb(253 224 71 / 0.2) !important;
}

.tw-ring-yellow-300\/25 {
  --tw-ring-color: rgb(253 224 71 / 0.25) !important;
}

.tw-ring-yellow-300\/30 {
  --tw-ring-color: rgb(253 224 71 / 0.3) !important;
}

.tw-ring-yellow-300\/35 {
  --tw-ring-color: rgb(253 224 71 / 0.35) !important;
}

.tw-ring-yellow-300\/40 {
  --tw-ring-color: rgb(253 224 71 / 0.4) !important;
}

.tw-ring-yellow-300\/45 {
  --tw-ring-color: rgb(253 224 71 / 0.45) !important;
}

.tw-ring-yellow-300\/5 {
  --tw-ring-color: rgb(253 224 71 / 0.05) !important;
}

.tw-ring-yellow-300\/50 {
  --tw-ring-color: rgb(253 224 71 / 0.5) !important;
}

.tw-ring-yellow-300\/55 {
  --tw-ring-color: rgb(253 224 71 / 0.55) !important;
}

.tw-ring-yellow-300\/60 {
  --tw-ring-color: rgb(253 224 71 / 0.6) !important;
}

.tw-ring-yellow-300\/65 {
  --tw-ring-color: rgb(253 224 71 / 0.65) !important;
}

.tw-ring-yellow-300\/70 {
  --tw-ring-color: rgb(253 224 71 / 0.7) !important;
}

.tw-ring-yellow-300\/75 {
  --tw-ring-color: rgb(253 224 71 / 0.75) !important;
}

.tw-ring-yellow-300\/80 {
  --tw-ring-color: rgb(253 224 71 / 0.8) !important;
}

.tw-ring-yellow-300\/85 {
  --tw-ring-color: rgb(253 224 71 / 0.85) !important;
}

.tw-ring-yellow-300\/90 {
  --tw-ring-color: rgb(253 224 71 / 0.9) !important;
}

.tw-ring-yellow-300\/95 {
  --tw-ring-color: rgb(253 224 71 / 0.95) !important;
}

.tw-ring-yellow-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(250 204 21 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-400\/0 {
  --tw-ring-color: rgb(250 204 21 / 0) !important;
}

.tw-ring-yellow-400\/10 {
  --tw-ring-color: rgb(250 204 21 / 0.1) !important;
}

.tw-ring-yellow-400\/100 {
  --tw-ring-color: rgb(250 204 21 / 1) !important;
}

.tw-ring-yellow-400\/15 {
  --tw-ring-color: rgb(250 204 21 / 0.15) !important;
}

.tw-ring-yellow-400\/20 {
  --tw-ring-color: rgb(250 204 21 / 0.2) !important;
}

.tw-ring-yellow-400\/25 {
  --tw-ring-color: rgb(250 204 21 / 0.25) !important;
}

.tw-ring-yellow-400\/30 {
  --tw-ring-color: rgb(250 204 21 / 0.3) !important;
}

.tw-ring-yellow-400\/35 {
  --tw-ring-color: rgb(250 204 21 / 0.35) !important;
}

.tw-ring-yellow-400\/40 {
  --tw-ring-color: rgb(250 204 21 / 0.4) !important;
}

.tw-ring-yellow-400\/45 {
  --tw-ring-color: rgb(250 204 21 / 0.45) !important;
}

.tw-ring-yellow-400\/5 {
  --tw-ring-color: rgb(250 204 21 / 0.05) !important;
}

.tw-ring-yellow-400\/50 {
  --tw-ring-color: rgb(250 204 21 / 0.5) !important;
}

.tw-ring-yellow-400\/55 {
  --tw-ring-color: rgb(250 204 21 / 0.55) !important;
}

.tw-ring-yellow-400\/60 {
  --tw-ring-color: rgb(250 204 21 / 0.6) !important;
}

.tw-ring-yellow-400\/65 {
  --tw-ring-color: rgb(250 204 21 / 0.65) !important;
}

.tw-ring-yellow-400\/70 {
  --tw-ring-color: rgb(250 204 21 / 0.7) !important;
}

.tw-ring-yellow-400\/75 {
  --tw-ring-color: rgb(250 204 21 / 0.75) !important;
}

.tw-ring-yellow-400\/80 {
  --tw-ring-color: rgb(250 204 21 / 0.8) !important;
}

.tw-ring-yellow-400\/85 {
  --tw-ring-color: rgb(250 204 21 / 0.85) !important;
}

.tw-ring-yellow-400\/90 {
  --tw-ring-color: rgb(250 204 21 / 0.9) !important;
}

.tw-ring-yellow-400\/95 {
  --tw-ring-color: rgb(250 204 21 / 0.95) !important;
}

.tw-ring-yellow-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(254 252 232 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-50\/0 {
  --tw-ring-color: rgb(254 252 232 / 0) !important;
}

.tw-ring-yellow-50\/10 {
  --tw-ring-color: rgb(254 252 232 / 0.1) !important;
}

.tw-ring-yellow-50\/100 {
  --tw-ring-color: rgb(254 252 232 / 1) !important;
}

.tw-ring-yellow-50\/15 {
  --tw-ring-color: rgb(254 252 232 / 0.15) !important;
}

.tw-ring-yellow-50\/20 {
  --tw-ring-color: rgb(254 252 232 / 0.2) !important;
}

.tw-ring-yellow-50\/25 {
  --tw-ring-color: rgb(254 252 232 / 0.25) !important;
}

.tw-ring-yellow-50\/30 {
  --tw-ring-color: rgb(254 252 232 / 0.3) !important;
}

.tw-ring-yellow-50\/35 {
  --tw-ring-color: rgb(254 252 232 / 0.35) !important;
}

.tw-ring-yellow-50\/40 {
  --tw-ring-color: rgb(254 252 232 / 0.4) !important;
}

.tw-ring-yellow-50\/45 {
  --tw-ring-color: rgb(254 252 232 / 0.45) !important;
}

.tw-ring-yellow-50\/5 {
  --tw-ring-color: rgb(254 252 232 / 0.05) !important;
}

.tw-ring-yellow-50\/50 {
  --tw-ring-color: rgb(254 252 232 / 0.5) !important;
}

.tw-ring-yellow-50\/55 {
  --tw-ring-color: rgb(254 252 232 / 0.55) !important;
}

.tw-ring-yellow-50\/60 {
  --tw-ring-color: rgb(254 252 232 / 0.6) !important;
}

.tw-ring-yellow-50\/65 {
  --tw-ring-color: rgb(254 252 232 / 0.65) !important;
}

.tw-ring-yellow-50\/70 {
  --tw-ring-color: rgb(254 252 232 / 0.7) !important;
}

.tw-ring-yellow-50\/75 {
  --tw-ring-color: rgb(254 252 232 / 0.75) !important;
}

.tw-ring-yellow-50\/80 {
  --tw-ring-color: rgb(254 252 232 / 0.8) !important;
}

.tw-ring-yellow-50\/85 {
  --tw-ring-color: rgb(254 252 232 / 0.85) !important;
}

.tw-ring-yellow-50\/90 {
  --tw-ring-color: rgb(254 252 232 / 0.9) !important;
}

.tw-ring-yellow-50\/95 {
  --tw-ring-color: rgb(254 252 232 / 0.95) !important;
}

.tw-ring-yellow-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(234 179 8 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-500\/0 {
  --tw-ring-color: rgb(234 179 8 / 0) !important;
}

.tw-ring-yellow-500\/10 {
  --tw-ring-color: rgb(234 179 8 / 0.1) !important;
}

.tw-ring-yellow-500\/100 {
  --tw-ring-color: rgb(234 179 8 / 1) !important;
}

.tw-ring-yellow-500\/15 {
  --tw-ring-color: rgb(234 179 8 / 0.15) !important;
}

.tw-ring-yellow-500\/20 {
  --tw-ring-color: rgb(234 179 8 / 0.2) !important;
}

.tw-ring-yellow-500\/25 {
  --tw-ring-color: rgb(234 179 8 / 0.25) !important;
}

.tw-ring-yellow-500\/30 {
  --tw-ring-color: rgb(234 179 8 / 0.3) !important;
}

.tw-ring-yellow-500\/35 {
  --tw-ring-color: rgb(234 179 8 / 0.35) !important;
}

.tw-ring-yellow-500\/40 {
  --tw-ring-color: rgb(234 179 8 / 0.4) !important;
}

.tw-ring-yellow-500\/45 {
  --tw-ring-color: rgb(234 179 8 / 0.45) !important;
}

.tw-ring-yellow-500\/5 {
  --tw-ring-color: rgb(234 179 8 / 0.05) !important;
}

.tw-ring-yellow-500\/50 {
  --tw-ring-color: rgb(234 179 8 / 0.5) !important;
}

.tw-ring-yellow-500\/55 {
  --tw-ring-color: rgb(234 179 8 / 0.55) !important;
}

.tw-ring-yellow-500\/60 {
  --tw-ring-color: rgb(234 179 8 / 0.6) !important;
}

.tw-ring-yellow-500\/65 {
  --tw-ring-color: rgb(234 179 8 / 0.65) !important;
}

.tw-ring-yellow-500\/70 {
  --tw-ring-color: rgb(234 179 8 / 0.7) !important;
}

.tw-ring-yellow-500\/75 {
  --tw-ring-color: rgb(234 179 8 / 0.75) !important;
}

.tw-ring-yellow-500\/80 {
  --tw-ring-color: rgb(234 179 8 / 0.8) !important;
}

.tw-ring-yellow-500\/85 {
  --tw-ring-color: rgb(234 179 8 / 0.85) !important;
}

.tw-ring-yellow-500\/90 {
  --tw-ring-color: rgb(234 179 8 / 0.9) !important;
}

.tw-ring-yellow-500\/95 {
  --tw-ring-color: rgb(234 179 8 / 0.95) !important;
}

.tw-ring-yellow-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(202 138 4 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-600\/0 {
  --tw-ring-color: rgb(202 138 4 / 0) !important;
}

.tw-ring-yellow-600\/10 {
  --tw-ring-color: rgb(202 138 4 / 0.1) !important;
}

.tw-ring-yellow-600\/100 {
  --tw-ring-color: rgb(202 138 4 / 1) !important;
}

.tw-ring-yellow-600\/15 {
  --tw-ring-color: rgb(202 138 4 / 0.15) !important;
}

.tw-ring-yellow-600\/20 {
  --tw-ring-color: rgb(202 138 4 / 0.2) !important;
}

.tw-ring-yellow-600\/25 {
  --tw-ring-color: rgb(202 138 4 / 0.25) !important;
}

.tw-ring-yellow-600\/30 {
  --tw-ring-color: rgb(202 138 4 / 0.3) !important;
}

.tw-ring-yellow-600\/35 {
  --tw-ring-color: rgb(202 138 4 / 0.35) !important;
}

.tw-ring-yellow-600\/40 {
  --tw-ring-color: rgb(202 138 4 / 0.4) !important;
}

.tw-ring-yellow-600\/45 {
  --tw-ring-color: rgb(202 138 4 / 0.45) !important;
}

.tw-ring-yellow-600\/5 {
  --tw-ring-color: rgb(202 138 4 / 0.05) !important;
}

.tw-ring-yellow-600\/50 {
  --tw-ring-color: rgb(202 138 4 / 0.5) !important;
}

.tw-ring-yellow-600\/55 {
  --tw-ring-color: rgb(202 138 4 / 0.55) !important;
}

.tw-ring-yellow-600\/60 {
  --tw-ring-color: rgb(202 138 4 / 0.6) !important;
}

.tw-ring-yellow-600\/65 {
  --tw-ring-color: rgb(202 138 4 / 0.65) !important;
}

.tw-ring-yellow-600\/70 {
  --tw-ring-color: rgb(202 138 4 / 0.7) !important;
}

.tw-ring-yellow-600\/75 {
  --tw-ring-color: rgb(202 138 4 / 0.75) !important;
}

.tw-ring-yellow-600\/80 {
  --tw-ring-color: rgb(202 138 4 / 0.8) !important;
}

.tw-ring-yellow-600\/85 {
  --tw-ring-color: rgb(202 138 4 / 0.85) !important;
}

.tw-ring-yellow-600\/90 {
  --tw-ring-color: rgb(202 138 4 / 0.9) !important;
}

.tw-ring-yellow-600\/95 {
  --tw-ring-color: rgb(202 138 4 / 0.95) !important;
}

.tw-ring-yellow-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(161 98 7 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-700\/0 {
  --tw-ring-color: rgb(161 98 7 / 0) !important;
}

.tw-ring-yellow-700\/10 {
  --tw-ring-color: rgb(161 98 7 / 0.1) !important;
}

.tw-ring-yellow-700\/100 {
  --tw-ring-color: rgb(161 98 7 / 1) !important;
}

.tw-ring-yellow-700\/15 {
  --tw-ring-color: rgb(161 98 7 / 0.15) !important;
}

.tw-ring-yellow-700\/20 {
  --tw-ring-color: rgb(161 98 7 / 0.2) !important;
}

.tw-ring-yellow-700\/25 {
  --tw-ring-color: rgb(161 98 7 / 0.25) !important;
}

.tw-ring-yellow-700\/30 {
  --tw-ring-color: rgb(161 98 7 / 0.3) !important;
}

.tw-ring-yellow-700\/35 {
  --tw-ring-color: rgb(161 98 7 / 0.35) !important;
}

.tw-ring-yellow-700\/40 {
  --tw-ring-color: rgb(161 98 7 / 0.4) !important;
}

.tw-ring-yellow-700\/45 {
  --tw-ring-color: rgb(161 98 7 / 0.45) !important;
}

.tw-ring-yellow-700\/5 {
  --tw-ring-color: rgb(161 98 7 / 0.05) !important;
}

.tw-ring-yellow-700\/50 {
  --tw-ring-color: rgb(161 98 7 / 0.5) !important;
}

.tw-ring-yellow-700\/55 {
  --tw-ring-color: rgb(161 98 7 / 0.55) !important;
}

.tw-ring-yellow-700\/60 {
  --tw-ring-color: rgb(161 98 7 / 0.6) !important;
}

.tw-ring-yellow-700\/65 {
  --tw-ring-color: rgb(161 98 7 / 0.65) !important;
}

.tw-ring-yellow-700\/70 {
  --tw-ring-color: rgb(161 98 7 / 0.7) !important;
}

.tw-ring-yellow-700\/75 {
  --tw-ring-color: rgb(161 98 7 / 0.75) !important;
}

.tw-ring-yellow-700\/80 {
  --tw-ring-color: rgb(161 98 7 / 0.8) !important;
}

.tw-ring-yellow-700\/85 {
  --tw-ring-color: rgb(161 98 7 / 0.85) !important;
}

.tw-ring-yellow-700\/90 {
  --tw-ring-color: rgb(161 98 7 / 0.9) !important;
}

.tw-ring-yellow-700\/95 {
  --tw-ring-color: rgb(161 98 7 / 0.95) !important;
}

.tw-ring-yellow-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(133 77 14 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-800\/0 {
  --tw-ring-color: rgb(133 77 14 / 0) !important;
}

.tw-ring-yellow-800\/10 {
  --tw-ring-color: rgb(133 77 14 / 0.1) !important;
}

.tw-ring-yellow-800\/100 {
  --tw-ring-color: rgb(133 77 14 / 1) !important;
}

.tw-ring-yellow-800\/15 {
  --tw-ring-color: rgb(133 77 14 / 0.15) !important;
}

.tw-ring-yellow-800\/20 {
  --tw-ring-color: rgb(133 77 14 / 0.2) !important;
}

.tw-ring-yellow-800\/25 {
  --tw-ring-color: rgb(133 77 14 / 0.25) !important;
}

.tw-ring-yellow-800\/30 {
  --tw-ring-color: rgb(133 77 14 / 0.3) !important;
}

.tw-ring-yellow-800\/35 {
  --tw-ring-color: rgb(133 77 14 / 0.35) !important;
}

.tw-ring-yellow-800\/40 {
  --tw-ring-color: rgb(133 77 14 / 0.4) !important;
}

.tw-ring-yellow-800\/45 {
  --tw-ring-color: rgb(133 77 14 / 0.45) !important;
}

.tw-ring-yellow-800\/5 {
  --tw-ring-color: rgb(133 77 14 / 0.05) !important;
}

.tw-ring-yellow-800\/50 {
  --tw-ring-color: rgb(133 77 14 / 0.5) !important;
}

.tw-ring-yellow-800\/55 {
  --tw-ring-color: rgb(133 77 14 / 0.55) !important;
}

.tw-ring-yellow-800\/60 {
  --tw-ring-color: rgb(133 77 14 / 0.6) !important;
}

.tw-ring-yellow-800\/65 {
  --tw-ring-color: rgb(133 77 14 / 0.65) !important;
}

.tw-ring-yellow-800\/70 {
  --tw-ring-color: rgb(133 77 14 / 0.7) !important;
}

.tw-ring-yellow-800\/75 {
  --tw-ring-color: rgb(133 77 14 / 0.75) !important;
}

.tw-ring-yellow-800\/80 {
  --tw-ring-color: rgb(133 77 14 / 0.8) !important;
}

.tw-ring-yellow-800\/85 {
  --tw-ring-color: rgb(133 77 14 / 0.85) !important;
}

.tw-ring-yellow-800\/90 {
  --tw-ring-color: rgb(133 77 14 / 0.9) !important;
}

.tw-ring-yellow-800\/95 {
  --tw-ring-color: rgb(133 77 14 / 0.95) !important;
}

.tw-ring-yellow-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(113 63 18 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-900\/0 {
  --tw-ring-color: rgb(113 63 18 / 0) !important;
}

.tw-ring-yellow-900\/10 {
  --tw-ring-color: rgb(113 63 18 / 0.1) !important;
}

.tw-ring-yellow-900\/100 {
  --tw-ring-color: rgb(113 63 18 / 1) !important;
}

.tw-ring-yellow-900\/15 {
  --tw-ring-color: rgb(113 63 18 / 0.15) !important;
}

.tw-ring-yellow-900\/20 {
  --tw-ring-color: rgb(113 63 18 / 0.2) !important;
}

.tw-ring-yellow-900\/25 {
  --tw-ring-color: rgb(113 63 18 / 0.25) !important;
}

.tw-ring-yellow-900\/30 {
  --tw-ring-color: rgb(113 63 18 / 0.3) !important;
}

.tw-ring-yellow-900\/35 {
  --tw-ring-color: rgb(113 63 18 / 0.35) !important;
}

.tw-ring-yellow-900\/40 {
  --tw-ring-color: rgb(113 63 18 / 0.4) !important;
}

.tw-ring-yellow-900\/45 {
  --tw-ring-color: rgb(113 63 18 / 0.45) !important;
}

.tw-ring-yellow-900\/5 {
  --tw-ring-color: rgb(113 63 18 / 0.05) !important;
}

.tw-ring-yellow-900\/50 {
  --tw-ring-color: rgb(113 63 18 / 0.5) !important;
}

.tw-ring-yellow-900\/55 {
  --tw-ring-color: rgb(113 63 18 / 0.55) !important;
}

.tw-ring-yellow-900\/60 {
  --tw-ring-color: rgb(113 63 18 / 0.6) !important;
}

.tw-ring-yellow-900\/65 {
  --tw-ring-color: rgb(113 63 18 / 0.65) !important;
}

.tw-ring-yellow-900\/70 {
  --tw-ring-color: rgb(113 63 18 / 0.7) !important;
}

.tw-ring-yellow-900\/75 {
  --tw-ring-color: rgb(113 63 18 / 0.75) !important;
}

.tw-ring-yellow-900\/80 {
  --tw-ring-color: rgb(113 63 18 / 0.8) !important;
}

.tw-ring-yellow-900\/85 {
  --tw-ring-color: rgb(113 63 18 / 0.85) !important;
}

.tw-ring-yellow-900\/90 {
  --tw-ring-color: rgb(113 63 18 / 0.9) !important;
}

.tw-ring-yellow-900\/95 {
  --tw-ring-color: rgb(113 63 18 / 0.95) !important;
}

.tw-ring-yellow-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(66 32 6 / var(--tw-ring-opacity)) !important;
}

.tw-ring-yellow-950\/0 {
  --tw-ring-color: rgb(66 32 6 / 0) !important;
}

.tw-ring-yellow-950\/10 {
  --tw-ring-color: rgb(66 32 6 / 0.1) !important;
}

.tw-ring-yellow-950\/100 {
  --tw-ring-color: rgb(66 32 6 / 1) !important;
}

.tw-ring-yellow-950\/15 {
  --tw-ring-color: rgb(66 32 6 / 0.15) !important;
}

.tw-ring-yellow-950\/20 {
  --tw-ring-color: rgb(66 32 6 / 0.2) !important;
}

.tw-ring-yellow-950\/25 {
  --tw-ring-color: rgb(66 32 6 / 0.25) !important;
}

.tw-ring-yellow-950\/30 {
  --tw-ring-color: rgb(66 32 6 / 0.3) !important;
}

.tw-ring-yellow-950\/35 {
  --tw-ring-color: rgb(66 32 6 / 0.35) !important;
}

.tw-ring-yellow-950\/40 {
  --tw-ring-color: rgb(66 32 6 / 0.4) !important;
}

.tw-ring-yellow-950\/45 {
  --tw-ring-color: rgb(66 32 6 / 0.45) !important;
}

.tw-ring-yellow-950\/5 {
  --tw-ring-color: rgb(66 32 6 / 0.05) !important;
}

.tw-ring-yellow-950\/50 {
  --tw-ring-color: rgb(66 32 6 / 0.5) !important;
}

.tw-ring-yellow-950\/55 {
  --tw-ring-color: rgb(66 32 6 / 0.55) !important;
}

.tw-ring-yellow-950\/60 {
  --tw-ring-color: rgb(66 32 6 / 0.6) !important;
}

.tw-ring-yellow-950\/65 {
  --tw-ring-color: rgb(66 32 6 / 0.65) !important;
}

.tw-ring-yellow-950\/70 {
  --tw-ring-color: rgb(66 32 6 / 0.7) !important;
}

.tw-ring-yellow-950\/75 {
  --tw-ring-color: rgb(66 32 6 / 0.75) !important;
}

.tw-ring-yellow-950\/80 {
  --tw-ring-color: rgb(66 32 6 / 0.8) !important;
}

.tw-ring-yellow-950\/85 {
  --tw-ring-color: rgb(66 32 6 / 0.85) !important;
}

.tw-ring-yellow-950\/90 {
  --tw-ring-color: rgb(66 32 6 / 0.9) !important;
}

.tw-ring-yellow-950\/95 {
  --tw-ring-color: rgb(66 32 6 / 0.95) !important;
}

.tw-ring-zinc-100 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(244 244 245 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-100\/0 {
  --tw-ring-color: rgb(244 244 245 / 0) !important;
}

.tw-ring-zinc-100\/10 {
  --tw-ring-color: rgb(244 244 245 / 0.1) !important;
}

.tw-ring-zinc-100\/100 {
  --tw-ring-color: rgb(244 244 245 / 1) !important;
}

.tw-ring-zinc-100\/15 {
  --tw-ring-color: rgb(244 244 245 / 0.15) !important;
}

.tw-ring-zinc-100\/20 {
  --tw-ring-color: rgb(244 244 245 / 0.2) !important;
}

.tw-ring-zinc-100\/25 {
  --tw-ring-color: rgb(244 244 245 / 0.25) !important;
}

.tw-ring-zinc-100\/30 {
  --tw-ring-color: rgb(244 244 245 / 0.3) !important;
}

.tw-ring-zinc-100\/35 {
  --tw-ring-color: rgb(244 244 245 / 0.35) !important;
}

.tw-ring-zinc-100\/40 {
  --tw-ring-color: rgb(244 244 245 / 0.4) !important;
}

.tw-ring-zinc-100\/45 {
  --tw-ring-color: rgb(244 244 245 / 0.45) !important;
}

.tw-ring-zinc-100\/5 {
  --tw-ring-color: rgb(244 244 245 / 0.05) !important;
}

.tw-ring-zinc-100\/50 {
  --tw-ring-color: rgb(244 244 245 / 0.5) !important;
}

.tw-ring-zinc-100\/55 {
  --tw-ring-color: rgb(244 244 245 / 0.55) !important;
}

.tw-ring-zinc-100\/60 {
  --tw-ring-color: rgb(244 244 245 / 0.6) !important;
}

.tw-ring-zinc-100\/65 {
  --tw-ring-color: rgb(244 244 245 / 0.65) !important;
}

.tw-ring-zinc-100\/70 {
  --tw-ring-color: rgb(244 244 245 / 0.7) !important;
}

.tw-ring-zinc-100\/75 {
  --tw-ring-color: rgb(244 244 245 / 0.75) !important;
}

.tw-ring-zinc-100\/80 {
  --tw-ring-color: rgb(244 244 245 / 0.8) !important;
}

.tw-ring-zinc-100\/85 {
  --tw-ring-color: rgb(244 244 245 / 0.85) !important;
}

.tw-ring-zinc-100\/90 {
  --tw-ring-color: rgb(244 244 245 / 0.9) !important;
}

.tw-ring-zinc-100\/95 {
  --tw-ring-color: rgb(244 244 245 / 0.95) !important;
}

.tw-ring-zinc-200 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(228 228 231 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-200\/0 {
  --tw-ring-color: rgb(228 228 231 / 0) !important;
}

.tw-ring-zinc-200\/10 {
  --tw-ring-color: rgb(228 228 231 / 0.1) !important;
}

.tw-ring-zinc-200\/100 {
  --tw-ring-color: rgb(228 228 231 / 1) !important;
}

.tw-ring-zinc-200\/15 {
  --tw-ring-color: rgb(228 228 231 / 0.15) !important;
}

.tw-ring-zinc-200\/20 {
  --tw-ring-color: rgb(228 228 231 / 0.2) !important;
}

.tw-ring-zinc-200\/25 {
  --tw-ring-color: rgb(228 228 231 / 0.25) !important;
}

.tw-ring-zinc-200\/30 {
  --tw-ring-color: rgb(228 228 231 / 0.3) !important;
}

.tw-ring-zinc-200\/35 {
  --tw-ring-color: rgb(228 228 231 / 0.35) !important;
}

.tw-ring-zinc-200\/40 {
  --tw-ring-color: rgb(228 228 231 / 0.4) !important;
}

.tw-ring-zinc-200\/45 {
  --tw-ring-color: rgb(228 228 231 / 0.45) !important;
}

.tw-ring-zinc-200\/5 {
  --tw-ring-color: rgb(228 228 231 / 0.05) !important;
}

.tw-ring-zinc-200\/50 {
  --tw-ring-color: rgb(228 228 231 / 0.5) !important;
}

.tw-ring-zinc-200\/55 {
  --tw-ring-color: rgb(228 228 231 / 0.55) !important;
}

.tw-ring-zinc-200\/60 {
  --tw-ring-color: rgb(228 228 231 / 0.6) !important;
}

.tw-ring-zinc-200\/65 {
  --tw-ring-color: rgb(228 228 231 / 0.65) !important;
}

.tw-ring-zinc-200\/70 {
  --tw-ring-color: rgb(228 228 231 / 0.7) !important;
}

.tw-ring-zinc-200\/75 {
  --tw-ring-color: rgb(228 228 231 / 0.75) !important;
}

.tw-ring-zinc-200\/80 {
  --tw-ring-color: rgb(228 228 231 / 0.8) !important;
}

.tw-ring-zinc-200\/85 {
  --tw-ring-color: rgb(228 228 231 / 0.85) !important;
}

.tw-ring-zinc-200\/90 {
  --tw-ring-color: rgb(228 228 231 / 0.9) !important;
}

.tw-ring-zinc-200\/95 {
  --tw-ring-color: rgb(228 228 231 / 0.95) !important;
}

.tw-ring-zinc-300 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(212 212 216 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-300\/0 {
  --tw-ring-color: rgb(212 212 216 / 0) !important;
}

.tw-ring-zinc-300\/10 {
  --tw-ring-color: rgb(212 212 216 / 0.1) !important;
}

.tw-ring-zinc-300\/100 {
  --tw-ring-color: rgb(212 212 216 / 1) !important;
}

.tw-ring-zinc-300\/15 {
  --tw-ring-color: rgb(212 212 216 / 0.15) !important;
}

.tw-ring-zinc-300\/20 {
  --tw-ring-color: rgb(212 212 216 / 0.2) !important;
}

.tw-ring-zinc-300\/25 {
  --tw-ring-color: rgb(212 212 216 / 0.25) !important;
}

.tw-ring-zinc-300\/30 {
  --tw-ring-color: rgb(212 212 216 / 0.3) !important;
}

.tw-ring-zinc-300\/35 {
  --tw-ring-color: rgb(212 212 216 / 0.35) !important;
}

.tw-ring-zinc-300\/40 {
  --tw-ring-color: rgb(212 212 216 / 0.4) !important;
}

.tw-ring-zinc-300\/45 {
  --tw-ring-color: rgb(212 212 216 / 0.45) !important;
}

.tw-ring-zinc-300\/5 {
  --tw-ring-color: rgb(212 212 216 / 0.05) !important;
}

.tw-ring-zinc-300\/50 {
  --tw-ring-color: rgb(212 212 216 / 0.5) !important;
}

.tw-ring-zinc-300\/55 {
  --tw-ring-color: rgb(212 212 216 / 0.55) !important;
}

.tw-ring-zinc-300\/60 {
  --tw-ring-color: rgb(212 212 216 / 0.6) !important;
}

.tw-ring-zinc-300\/65 {
  --tw-ring-color: rgb(212 212 216 / 0.65) !important;
}

.tw-ring-zinc-300\/70 {
  --tw-ring-color: rgb(212 212 216 / 0.7) !important;
}

.tw-ring-zinc-300\/75 {
  --tw-ring-color: rgb(212 212 216 / 0.75) !important;
}

.tw-ring-zinc-300\/80 {
  --tw-ring-color: rgb(212 212 216 / 0.8) !important;
}

.tw-ring-zinc-300\/85 {
  --tw-ring-color: rgb(212 212 216 / 0.85) !important;
}

.tw-ring-zinc-300\/90 {
  --tw-ring-color: rgb(212 212 216 / 0.9) !important;
}

.tw-ring-zinc-300\/95 {
  --tw-ring-color: rgb(212 212 216 / 0.95) !important;
}

.tw-ring-zinc-400 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(161 161 170 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-400\/0 {
  --tw-ring-color: rgb(161 161 170 / 0) !important;
}

.tw-ring-zinc-400\/10 {
  --tw-ring-color: rgb(161 161 170 / 0.1) !important;
}

.tw-ring-zinc-400\/100 {
  --tw-ring-color: rgb(161 161 170 / 1) !important;
}

.tw-ring-zinc-400\/15 {
  --tw-ring-color: rgb(161 161 170 / 0.15) !important;
}

.tw-ring-zinc-400\/20 {
  --tw-ring-color: rgb(161 161 170 / 0.2) !important;
}

.tw-ring-zinc-400\/25 {
  --tw-ring-color: rgb(161 161 170 / 0.25) !important;
}

.tw-ring-zinc-400\/30 {
  --tw-ring-color: rgb(161 161 170 / 0.3) !important;
}

.tw-ring-zinc-400\/35 {
  --tw-ring-color: rgb(161 161 170 / 0.35) !important;
}

.tw-ring-zinc-400\/40 {
  --tw-ring-color: rgb(161 161 170 / 0.4) !important;
}

.tw-ring-zinc-400\/45 {
  --tw-ring-color: rgb(161 161 170 / 0.45) !important;
}

.tw-ring-zinc-400\/5 {
  --tw-ring-color: rgb(161 161 170 / 0.05) !important;
}

.tw-ring-zinc-400\/50 {
  --tw-ring-color: rgb(161 161 170 / 0.5) !important;
}

.tw-ring-zinc-400\/55 {
  --tw-ring-color: rgb(161 161 170 / 0.55) !important;
}

.tw-ring-zinc-400\/60 {
  --tw-ring-color: rgb(161 161 170 / 0.6) !important;
}

.tw-ring-zinc-400\/65 {
  --tw-ring-color: rgb(161 161 170 / 0.65) !important;
}

.tw-ring-zinc-400\/70 {
  --tw-ring-color: rgb(161 161 170 / 0.7) !important;
}

.tw-ring-zinc-400\/75 {
  --tw-ring-color: rgb(161 161 170 / 0.75) !important;
}

.tw-ring-zinc-400\/80 {
  --tw-ring-color: rgb(161 161 170 / 0.8) !important;
}

.tw-ring-zinc-400\/85 {
  --tw-ring-color: rgb(161 161 170 / 0.85) !important;
}

.tw-ring-zinc-400\/90 {
  --tw-ring-color: rgb(161 161 170 / 0.9) !important;
}

.tw-ring-zinc-400\/95 {
  --tw-ring-color: rgb(161 161 170 / 0.95) !important;
}

.tw-ring-zinc-50 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(250 250 250 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-50\/0 {
  --tw-ring-color: rgb(250 250 250 / 0) !important;
}

.tw-ring-zinc-50\/10 {
  --tw-ring-color: rgb(250 250 250 / 0.1) !important;
}

.tw-ring-zinc-50\/100 {
  --tw-ring-color: rgb(250 250 250 / 1) !important;
}

.tw-ring-zinc-50\/15 {
  --tw-ring-color: rgb(250 250 250 / 0.15) !important;
}

.tw-ring-zinc-50\/20 {
  --tw-ring-color: rgb(250 250 250 / 0.2) !important;
}

.tw-ring-zinc-50\/25 {
  --tw-ring-color: rgb(250 250 250 / 0.25) !important;
}

.tw-ring-zinc-50\/30 {
  --tw-ring-color: rgb(250 250 250 / 0.3) !important;
}

.tw-ring-zinc-50\/35 {
  --tw-ring-color: rgb(250 250 250 / 0.35) !important;
}

.tw-ring-zinc-50\/40 {
  --tw-ring-color: rgb(250 250 250 / 0.4) !important;
}

.tw-ring-zinc-50\/45 {
  --tw-ring-color: rgb(250 250 250 / 0.45) !important;
}

.tw-ring-zinc-50\/5 {
  --tw-ring-color: rgb(250 250 250 / 0.05) !important;
}

.tw-ring-zinc-50\/50 {
  --tw-ring-color: rgb(250 250 250 / 0.5) !important;
}

.tw-ring-zinc-50\/55 {
  --tw-ring-color: rgb(250 250 250 / 0.55) !important;
}

.tw-ring-zinc-50\/60 {
  --tw-ring-color: rgb(250 250 250 / 0.6) !important;
}

.tw-ring-zinc-50\/65 {
  --tw-ring-color: rgb(250 250 250 / 0.65) !important;
}

.tw-ring-zinc-50\/70 {
  --tw-ring-color: rgb(250 250 250 / 0.7) !important;
}

.tw-ring-zinc-50\/75 {
  --tw-ring-color: rgb(250 250 250 / 0.75) !important;
}

.tw-ring-zinc-50\/80 {
  --tw-ring-color: rgb(250 250 250 / 0.8) !important;
}

.tw-ring-zinc-50\/85 {
  --tw-ring-color: rgb(250 250 250 / 0.85) !important;
}

.tw-ring-zinc-50\/90 {
  --tw-ring-color: rgb(250 250 250 / 0.9) !important;
}

.tw-ring-zinc-50\/95 {
  --tw-ring-color: rgb(250 250 250 / 0.95) !important;
}

.tw-ring-zinc-500 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(113 113 122 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-500\/0 {
  --tw-ring-color: rgb(113 113 122 / 0) !important;
}

.tw-ring-zinc-500\/10 {
  --tw-ring-color: rgb(113 113 122 / 0.1) !important;
}

.tw-ring-zinc-500\/100 {
  --tw-ring-color: rgb(113 113 122 / 1) !important;
}

.tw-ring-zinc-500\/15 {
  --tw-ring-color: rgb(113 113 122 / 0.15) !important;
}

.tw-ring-zinc-500\/20 {
  --tw-ring-color: rgb(113 113 122 / 0.2) !important;
}

.tw-ring-zinc-500\/25 {
  --tw-ring-color: rgb(113 113 122 / 0.25) !important;
}

.tw-ring-zinc-500\/30 {
  --tw-ring-color: rgb(113 113 122 / 0.3) !important;
}

.tw-ring-zinc-500\/35 {
  --tw-ring-color: rgb(113 113 122 / 0.35) !important;
}

.tw-ring-zinc-500\/40 {
  --tw-ring-color: rgb(113 113 122 / 0.4) !important;
}

.tw-ring-zinc-500\/45 {
  --tw-ring-color: rgb(113 113 122 / 0.45) !important;
}

.tw-ring-zinc-500\/5 {
  --tw-ring-color: rgb(113 113 122 / 0.05) !important;
}

.tw-ring-zinc-500\/50 {
  --tw-ring-color: rgb(113 113 122 / 0.5) !important;
}

.tw-ring-zinc-500\/55 {
  --tw-ring-color: rgb(113 113 122 / 0.55) !important;
}

.tw-ring-zinc-500\/60 {
  --tw-ring-color: rgb(113 113 122 / 0.6) !important;
}

.tw-ring-zinc-500\/65 {
  --tw-ring-color: rgb(113 113 122 / 0.65) !important;
}

.tw-ring-zinc-500\/70 {
  --tw-ring-color: rgb(113 113 122 / 0.7) !important;
}

.tw-ring-zinc-500\/75 {
  --tw-ring-color: rgb(113 113 122 / 0.75) !important;
}

.tw-ring-zinc-500\/80 {
  --tw-ring-color: rgb(113 113 122 / 0.8) !important;
}

.tw-ring-zinc-500\/85 {
  --tw-ring-color: rgb(113 113 122 / 0.85) !important;
}

.tw-ring-zinc-500\/90 {
  --tw-ring-color: rgb(113 113 122 / 0.9) !important;
}

.tw-ring-zinc-500\/95 {
  --tw-ring-color: rgb(113 113 122 / 0.95) !important;
}

.tw-ring-zinc-600 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(82 82 91 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-600\/0 {
  --tw-ring-color: rgb(82 82 91 / 0) !important;
}

.tw-ring-zinc-600\/10 {
  --tw-ring-color: rgb(82 82 91 / 0.1) !important;
}

.tw-ring-zinc-600\/100 {
  --tw-ring-color: rgb(82 82 91 / 1) !important;
}

.tw-ring-zinc-600\/15 {
  --tw-ring-color: rgb(82 82 91 / 0.15) !important;
}

.tw-ring-zinc-600\/20 {
  --tw-ring-color: rgb(82 82 91 / 0.2) !important;
}

.tw-ring-zinc-600\/25 {
  --tw-ring-color: rgb(82 82 91 / 0.25) !important;
}

.tw-ring-zinc-600\/30 {
  --tw-ring-color: rgb(82 82 91 / 0.3) !important;
}

.tw-ring-zinc-600\/35 {
  --tw-ring-color: rgb(82 82 91 / 0.35) !important;
}

.tw-ring-zinc-600\/40 {
  --tw-ring-color: rgb(82 82 91 / 0.4) !important;
}

.tw-ring-zinc-600\/45 {
  --tw-ring-color: rgb(82 82 91 / 0.45) !important;
}

.tw-ring-zinc-600\/5 {
  --tw-ring-color: rgb(82 82 91 / 0.05) !important;
}

.tw-ring-zinc-600\/50 {
  --tw-ring-color: rgb(82 82 91 / 0.5) !important;
}

.tw-ring-zinc-600\/55 {
  --tw-ring-color: rgb(82 82 91 / 0.55) !important;
}

.tw-ring-zinc-600\/60 {
  --tw-ring-color: rgb(82 82 91 / 0.6) !important;
}

.tw-ring-zinc-600\/65 {
  --tw-ring-color: rgb(82 82 91 / 0.65) !important;
}

.tw-ring-zinc-600\/70 {
  --tw-ring-color: rgb(82 82 91 / 0.7) !important;
}

.tw-ring-zinc-600\/75 {
  --tw-ring-color: rgb(82 82 91 / 0.75) !important;
}

.tw-ring-zinc-600\/80 {
  --tw-ring-color: rgb(82 82 91 / 0.8) !important;
}

.tw-ring-zinc-600\/85 {
  --tw-ring-color: rgb(82 82 91 / 0.85) !important;
}

.tw-ring-zinc-600\/90 {
  --tw-ring-color: rgb(82 82 91 / 0.9) !important;
}

.tw-ring-zinc-600\/95 {
  --tw-ring-color: rgb(82 82 91 / 0.95) !important;
}

.tw-ring-zinc-700 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(63 63 70 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-700\/0 {
  --tw-ring-color: rgb(63 63 70 / 0) !important;
}

.tw-ring-zinc-700\/10 {
  --tw-ring-color: rgb(63 63 70 / 0.1) !important;
}

.tw-ring-zinc-700\/100 {
  --tw-ring-color: rgb(63 63 70 / 1) !important;
}

.tw-ring-zinc-700\/15 {
  --tw-ring-color: rgb(63 63 70 / 0.15) !important;
}

.tw-ring-zinc-700\/20 {
  --tw-ring-color: rgb(63 63 70 / 0.2) !important;
}

.tw-ring-zinc-700\/25 {
  --tw-ring-color: rgb(63 63 70 / 0.25) !important;
}

.tw-ring-zinc-700\/30 {
  --tw-ring-color: rgb(63 63 70 / 0.3) !important;
}

.tw-ring-zinc-700\/35 {
  --tw-ring-color: rgb(63 63 70 / 0.35) !important;
}

.tw-ring-zinc-700\/40 {
  --tw-ring-color: rgb(63 63 70 / 0.4) !important;
}

.tw-ring-zinc-700\/45 {
  --tw-ring-color: rgb(63 63 70 / 0.45) !important;
}

.tw-ring-zinc-700\/5 {
  --tw-ring-color: rgb(63 63 70 / 0.05) !important;
}

.tw-ring-zinc-700\/50 {
  --tw-ring-color: rgb(63 63 70 / 0.5) !important;
}

.tw-ring-zinc-700\/55 {
  --tw-ring-color: rgb(63 63 70 / 0.55) !important;
}

.tw-ring-zinc-700\/60 {
  --tw-ring-color: rgb(63 63 70 / 0.6) !important;
}

.tw-ring-zinc-700\/65 {
  --tw-ring-color: rgb(63 63 70 / 0.65) !important;
}

.tw-ring-zinc-700\/70 {
  --tw-ring-color: rgb(63 63 70 / 0.7) !important;
}

.tw-ring-zinc-700\/75 {
  --tw-ring-color: rgb(63 63 70 / 0.75) !important;
}

.tw-ring-zinc-700\/80 {
  --tw-ring-color: rgb(63 63 70 / 0.8) !important;
}

.tw-ring-zinc-700\/85 {
  --tw-ring-color: rgb(63 63 70 / 0.85) !important;
}

.tw-ring-zinc-700\/90 {
  --tw-ring-color: rgb(63 63 70 / 0.9) !important;
}

.tw-ring-zinc-700\/95 {
  --tw-ring-color: rgb(63 63 70 / 0.95) !important;
}

.tw-ring-zinc-800 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(39 39 42 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-800\/0 {
  --tw-ring-color: rgb(39 39 42 / 0) !important;
}

.tw-ring-zinc-800\/10 {
  --tw-ring-color: rgb(39 39 42 / 0.1) !important;
}

.tw-ring-zinc-800\/100 {
  --tw-ring-color: rgb(39 39 42 / 1) !important;
}

.tw-ring-zinc-800\/15 {
  --tw-ring-color: rgb(39 39 42 / 0.15) !important;
}

.tw-ring-zinc-800\/20 {
  --tw-ring-color: rgb(39 39 42 / 0.2) !important;
}

.tw-ring-zinc-800\/25 {
  --tw-ring-color: rgb(39 39 42 / 0.25) !important;
}

.tw-ring-zinc-800\/30 {
  --tw-ring-color: rgb(39 39 42 / 0.3) !important;
}

.tw-ring-zinc-800\/35 {
  --tw-ring-color: rgb(39 39 42 / 0.35) !important;
}

.tw-ring-zinc-800\/40 {
  --tw-ring-color: rgb(39 39 42 / 0.4) !important;
}

.tw-ring-zinc-800\/45 {
  --tw-ring-color: rgb(39 39 42 / 0.45) !important;
}

.tw-ring-zinc-800\/5 {
  --tw-ring-color: rgb(39 39 42 / 0.05) !important;
}

.tw-ring-zinc-800\/50 {
  --tw-ring-color: rgb(39 39 42 / 0.5) !important;
}

.tw-ring-zinc-800\/55 {
  --tw-ring-color: rgb(39 39 42 / 0.55) !important;
}

.tw-ring-zinc-800\/60 {
  --tw-ring-color: rgb(39 39 42 / 0.6) !important;
}

.tw-ring-zinc-800\/65 {
  --tw-ring-color: rgb(39 39 42 / 0.65) !important;
}

.tw-ring-zinc-800\/70 {
  --tw-ring-color: rgb(39 39 42 / 0.7) !important;
}

.tw-ring-zinc-800\/75 {
  --tw-ring-color: rgb(39 39 42 / 0.75) !important;
}

.tw-ring-zinc-800\/80 {
  --tw-ring-color: rgb(39 39 42 / 0.8) !important;
}

.tw-ring-zinc-800\/85 {
  --tw-ring-color: rgb(39 39 42 / 0.85) !important;
}

.tw-ring-zinc-800\/90 {
  --tw-ring-color: rgb(39 39 42 / 0.9) !important;
}

.tw-ring-zinc-800\/95 {
  --tw-ring-color: rgb(39 39 42 / 0.95) !important;
}

.tw-ring-zinc-900 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(24 24 27 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-900\/0 {
  --tw-ring-color: rgb(24 24 27 / 0) !important;
}

.tw-ring-zinc-900\/10 {
  --tw-ring-color: rgb(24 24 27 / 0.1) !important;
}

.tw-ring-zinc-900\/100 {
  --tw-ring-color: rgb(24 24 27 / 1) !important;
}

.tw-ring-zinc-900\/15 {
  --tw-ring-color: rgb(24 24 27 / 0.15) !important;
}

.tw-ring-zinc-900\/20 {
  --tw-ring-color: rgb(24 24 27 / 0.2) !important;
}

.tw-ring-zinc-900\/25 {
  --tw-ring-color: rgb(24 24 27 / 0.25) !important;
}

.tw-ring-zinc-900\/30 {
  --tw-ring-color: rgb(24 24 27 / 0.3) !important;
}

.tw-ring-zinc-900\/35 {
  --tw-ring-color: rgb(24 24 27 / 0.35) !important;
}

.tw-ring-zinc-900\/40 {
  --tw-ring-color: rgb(24 24 27 / 0.4) !important;
}

.tw-ring-zinc-900\/45 {
  --tw-ring-color: rgb(24 24 27 / 0.45) !important;
}

.tw-ring-zinc-900\/5 {
  --tw-ring-color: rgb(24 24 27 / 0.05) !important;
}

.tw-ring-zinc-900\/50 {
  --tw-ring-color: rgb(24 24 27 / 0.5) !important;
}

.tw-ring-zinc-900\/55 {
  --tw-ring-color: rgb(24 24 27 / 0.55) !important;
}

.tw-ring-zinc-900\/60 {
  --tw-ring-color: rgb(24 24 27 / 0.6) !important;
}

.tw-ring-zinc-900\/65 {
  --tw-ring-color: rgb(24 24 27 / 0.65) !important;
}

.tw-ring-zinc-900\/70 {
  --tw-ring-color: rgb(24 24 27 / 0.7) !important;
}

.tw-ring-zinc-900\/75 {
  --tw-ring-color: rgb(24 24 27 / 0.75) !important;
}

.tw-ring-zinc-900\/80 {
  --tw-ring-color: rgb(24 24 27 / 0.8) !important;
}

.tw-ring-zinc-900\/85 {
  --tw-ring-color: rgb(24 24 27 / 0.85) !important;
}

.tw-ring-zinc-900\/90 {
  --tw-ring-color: rgb(24 24 27 / 0.9) !important;
}

.tw-ring-zinc-900\/95 {
  --tw-ring-color: rgb(24 24 27 / 0.95) !important;
}

.tw-ring-zinc-950 {
  --tw-ring-opacity: 1 !important;
  --tw-ring-color: rgb(9 9 11 / var(--tw-ring-opacity)) !important;
}

.tw-ring-zinc-950\/0 {
  --tw-ring-color: rgb(9 9 11 / 0) !important;
}

.tw-ring-zinc-950\/10 {
  --tw-ring-color: rgb(9 9 11 / 0.1) !important;
}

.tw-ring-zinc-950\/100 {
  --tw-ring-color: rgb(9 9 11 / 1) !important;
}

.tw-ring-zinc-950\/15 {
  --tw-ring-color: rgb(9 9 11 / 0.15) !important;
}

.tw-ring-zinc-950\/20 {
  --tw-ring-color: rgb(9 9 11 / 0.2) !important;
}

.tw-ring-zinc-950\/25 {
  --tw-ring-color: rgb(9 9 11 / 0.25) !important;
}

.tw-ring-zinc-950\/30 {
  --tw-ring-color: rgb(9 9 11 / 0.3) !important;
}

.tw-ring-zinc-950\/35 {
  --tw-ring-color: rgb(9 9 11 / 0.35) !important;
}

.tw-ring-zinc-950\/40 {
  --tw-ring-color: rgb(9 9 11 / 0.4) !important;
}

.tw-ring-zinc-950\/45 {
  --tw-ring-color: rgb(9 9 11 / 0.45) !important;
}

.tw-ring-zinc-950\/5 {
  --tw-ring-color: rgb(9 9 11 / 0.05) !important;
}

.tw-ring-zinc-950\/50 {
  --tw-ring-color: rgb(9 9 11 / 0.5) !important;
}

.tw-ring-zinc-950\/55 {
  --tw-ring-color: rgb(9 9 11 / 0.55) !important;
}

.tw-ring-zinc-950\/60 {
  --tw-ring-color: rgb(9 9 11 / 0.6) !important;
}

.tw-ring-zinc-950\/65 {
  --tw-ring-color: rgb(9 9 11 / 0.65) !important;
}

.tw-ring-zinc-950\/70 {
  --tw-ring-color: rgb(9 9 11 / 0.7) !important;
}

.tw-ring-zinc-950\/75 {
  --tw-ring-color: rgb(9 9 11 / 0.75) !important;
}

.tw-ring-zinc-950\/80 {
  --tw-ring-color: rgb(9 9 11 / 0.8) !important;
}

.tw-ring-zinc-950\/85 {
  --tw-ring-color: rgb(9 9 11 / 0.85) !important;
}

.tw-ring-zinc-950\/90 {
  --tw-ring-color: rgb(9 9 11 / 0.9) !important;
}

.tw-ring-zinc-950\/95 {
  --tw-ring-color: rgb(9 9 11 / 0.95) !important;
}

.tw-ring-opacity-0 {
  --tw-ring-opacity: 0 !important;
}

.tw-ring-opacity-10 {
  --tw-ring-opacity: 0.1 !important;
}

.tw-ring-opacity-100 {
  --tw-ring-opacity: 1 !important;
}

.tw-ring-opacity-15 {
  --tw-ring-opacity: 0.15 !important;
}

.tw-ring-opacity-20 {
  --tw-ring-opacity: 0.2 !important;
}

.tw-ring-opacity-25 {
  --tw-ring-opacity: 0.25 !important;
}

.tw-ring-opacity-30 {
  --tw-ring-opacity: 0.3 !important;
}

.tw-ring-opacity-35 {
  --tw-ring-opacity: 0.35 !important;
}

.tw-ring-opacity-40 {
  --tw-ring-opacity: 0.4 !important;
}

.tw-ring-opacity-45 {
  --tw-ring-opacity: 0.45 !important;
}

.tw-ring-opacity-5 {
  --tw-ring-opacity: 0.05 !important;
}

.tw-ring-opacity-50 {
  --tw-ring-opacity: 0.5 !important;
}

.tw-ring-opacity-55 {
  --tw-ring-opacity: 0.55 !important;
}

.tw-ring-opacity-60 {
  --tw-ring-opacity: 0.6 !important;
}

.tw-ring-opacity-65 {
  --tw-ring-opacity: 0.65 !important;
}

.tw-ring-opacity-70 {
  --tw-ring-opacity: 0.7 !important;
}

.tw-ring-opacity-75 {
  --tw-ring-opacity: 0.75 !important;
}

.tw-ring-opacity-80 {
  --tw-ring-opacity: 0.8 !important;
}

.tw-ring-opacity-85 {
  --tw-ring-opacity: 0.85 !important;
}

.tw-ring-opacity-90 {
  --tw-ring-opacity: 0.9 !important;
}

.tw-ring-opacity-95 {
  --tw-ring-opacity: 0.95 !important;
}

.tw-ring-offset-0 {
  --tw-ring-offset-width: 0px !important;
}

.tw-ring-offset-1 {
  --tw-ring-offset-width: 1px !important;
}

.tw-ring-offset-2 {
  --tw-ring-offset-width: 2px !important;
}

.tw-ring-offset-4 {
  --tw-ring-offset-width: 4px !important;
}

.tw-ring-offset-8 {
  --tw-ring-offset-width: 8px !important;
}

.tw-ring-offset-amber-100 {
  --tw-ring-offset-color: #fef3c7 !important;
}

.tw-ring-offset-amber-100\/0 {
  --tw-ring-offset-color: rgb(254 243 199 / 0) !important;
}

.tw-ring-offset-amber-100\/10 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.1) !important;
}

.tw-ring-offset-amber-100\/100 {
  --tw-ring-offset-color: rgb(254 243 199 / 1) !important;
}

.tw-ring-offset-amber-100\/15 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.15) !important;
}

.tw-ring-offset-amber-100\/20 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.2) !important;
}

.tw-ring-offset-amber-100\/25 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.25) !important;
}

.tw-ring-offset-amber-100\/30 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.3) !important;
}

.tw-ring-offset-amber-100\/35 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.35) !important;
}

.tw-ring-offset-amber-100\/40 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.4) !important;
}

.tw-ring-offset-amber-100\/45 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.45) !important;
}

.tw-ring-offset-amber-100\/5 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.05) !important;
}

.tw-ring-offset-amber-100\/50 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.5) !important;
}

.tw-ring-offset-amber-100\/55 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.55) !important;
}

.tw-ring-offset-amber-100\/60 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.6) !important;
}

.tw-ring-offset-amber-100\/65 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.65) !important;
}

.tw-ring-offset-amber-100\/70 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.7) !important;
}

.tw-ring-offset-amber-100\/75 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.75) !important;
}

.tw-ring-offset-amber-100\/80 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.8) !important;
}

.tw-ring-offset-amber-100\/85 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.85) !important;
}

.tw-ring-offset-amber-100\/90 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.9) !important;
}

.tw-ring-offset-amber-100\/95 {
  --tw-ring-offset-color: rgb(254 243 199 / 0.95) !important;
}

.tw-ring-offset-amber-200 {
  --tw-ring-offset-color: #fde68a !important;
}

.tw-ring-offset-amber-200\/0 {
  --tw-ring-offset-color: rgb(253 230 138 / 0) !important;
}

.tw-ring-offset-amber-200\/10 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.1) !important;
}

.tw-ring-offset-amber-200\/100 {
  --tw-ring-offset-color: rgb(253 230 138 / 1) !important;
}

.tw-ring-offset-amber-200\/15 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.15) !important;
}

.tw-ring-offset-amber-200\/20 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.2) !important;
}

.tw-ring-offset-amber-200\/25 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.25) !important;
}

.tw-ring-offset-amber-200\/30 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.3) !important;
}

.tw-ring-offset-amber-200\/35 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.35) !important;
}

.tw-ring-offset-amber-200\/40 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.4) !important;
}

.tw-ring-offset-amber-200\/45 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.45) !important;
}

.tw-ring-offset-amber-200\/5 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.05) !important;
}

.tw-ring-offset-amber-200\/50 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.5) !important;
}

.tw-ring-offset-amber-200\/55 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.55) !important;
}

.tw-ring-offset-amber-200\/60 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.6) !important;
}

.tw-ring-offset-amber-200\/65 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.65) !important;
}

.tw-ring-offset-amber-200\/70 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.7) !important;
}

.tw-ring-offset-amber-200\/75 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.75) !important;
}

.tw-ring-offset-amber-200\/80 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.8) !important;
}

.tw-ring-offset-amber-200\/85 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.85) !important;
}

.tw-ring-offset-amber-200\/90 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.9) !important;
}

.tw-ring-offset-amber-200\/95 {
  --tw-ring-offset-color: rgb(253 230 138 / 0.95) !important;
}

.tw-ring-offset-amber-300 {
  --tw-ring-offset-color: #fcd34d !important;
}

.tw-ring-offset-amber-300\/0 {
  --tw-ring-offset-color: rgb(252 211 77 / 0) !important;
}

.tw-ring-offset-amber-300\/10 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.1) !important;
}

.tw-ring-offset-amber-300\/100 {
  --tw-ring-offset-color: rgb(252 211 77 / 1) !important;
}

.tw-ring-offset-amber-300\/15 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.15) !important;
}

.tw-ring-offset-amber-300\/20 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.2) !important;
}

.tw-ring-offset-amber-300\/25 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.25) !important;
}

.tw-ring-offset-amber-300\/30 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.3) !important;
}

.tw-ring-offset-amber-300\/35 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.35) !important;
}

.tw-ring-offset-amber-300\/40 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.4) !important;
}

.tw-ring-offset-amber-300\/45 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.45) !important;
}

.tw-ring-offset-amber-300\/5 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.05) !important;
}

.tw-ring-offset-amber-300\/50 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.5) !important;
}

.tw-ring-offset-amber-300\/55 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.55) !important;
}

.tw-ring-offset-amber-300\/60 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.6) !important;
}

.tw-ring-offset-amber-300\/65 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.65) !important;
}

.tw-ring-offset-amber-300\/70 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.7) !important;
}

.tw-ring-offset-amber-300\/75 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.75) !important;
}

.tw-ring-offset-amber-300\/80 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.8) !important;
}

.tw-ring-offset-amber-300\/85 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.85) !important;
}

.tw-ring-offset-amber-300\/90 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.9) !important;
}

.tw-ring-offset-amber-300\/95 {
  --tw-ring-offset-color: rgb(252 211 77 / 0.95) !important;
}

.tw-ring-offset-amber-400 {
  --tw-ring-offset-color: #fbbf24 !important;
}

.tw-ring-offset-amber-400\/0 {
  --tw-ring-offset-color: rgb(251 191 36 / 0) !important;
}

.tw-ring-offset-amber-400\/10 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.1) !important;
}

.tw-ring-offset-amber-400\/100 {
  --tw-ring-offset-color: rgb(251 191 36 / 1) !important;
}

.tw-ring-offset-amber-400\/15 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.15) !important;
}

.tw-ring-offset-amber-400\/20 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.2) !important;
}

.tw-ring-offset-amber-400\/25 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.25) !important;
}

.tw-ring-offset-amber-400\/30 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.3) !important;
}

.tw-ring-offset-amber-400\/35 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.35) !important;
}

.tw-ring-offset-amber-400\/40 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.4) !important;
}

.tw-ring-offset-amber-400\/45 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.45) !important;
}

.tw-ring-offset-amber-400\/5 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.05) !important;
}

.tw-ring-offset-amber-400\/50 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.5) !important;
}

.tw-ring-offset-amber-400\/55 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.55) !important;
}

.tw-ring-offset-amber-400\/60 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.6) !important;
}

.tw-ring-offset-amber-400\/65 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.65) !important;
}

.tw-ring-offset-amber-400\/70 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.7) !important;
}

.tw-ring-offset-amber-400\/75 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.75) !important;
}

.tw-ring-offset-amber-400\/80 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.8) !important;
}

.tw-ring-offset-amber-400\/85 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.85) !important;
}

.tw-ring-offset-amber-400\/90 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.9) !important;
}

.tw-ring-offset-amber-400\/95 {
  --tw-ring-offset-color: rgb(251 191 36 / 0.95) !important;
}

.tw-ring-offset-amber-50 {
  --tw-ring-offset-color: #fffbeb !important;
}

.tw-ring-offset-amber-50\/0 {
  --tw-ring-offset-color: rgb(255 251 235 / 0) !important;
}

.tw-ring-offset-amber-50\/10 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.1) !important;
}

.tw-ring-offset-amber-50\/100 {
  --tw-ring-offset-color: rgb(255 251 235 / 1) !important;
}

.tw-ring-offset-amber-50\/15 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.15) !important;
}

.tw-ring-offset-amber-50\/20 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.2) !important;
}

.tw-ring-offset-amber-50\/25 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.25) !important;
}

.tw-ring-offset-amber-50\/30 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.3) !important;
}

.tw-ring-offset-amber-50\/35 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.35) !important;
}

.tw-ring-offset-amber-50\/40 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.4) !important;
}

.tw-ring-offset-amber-50\/45 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.45) !important;
}

.tw-ring-offset-amber-50\/5 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.05) !important;
}

.tw-ring-offset-amber-50\/50 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.5) !important;
}

.tw-ring-offset-amber-50\/55 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.55) !important;
}

.tw-ring-offset-amber-50\/60 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.6) !important;
}

.tw-ring-offset-amber-50\/65 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.65) !important;
}

.tw-ring-offset-amber-50\/70 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.7) !important;
}

.tw-ring-offset-amber-50\/75 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.75) !important;
}

.tw-ring-offset-amber-50\/80 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.8) !important;
}

.tw-ring-offset-amber-50\/85 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.85) !important;
}

.tw-ring-offset-amber-50\/90 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.9) !important;
}

.tw-ring-offset-amber-50\/95 {
  --tw-ring-offset-color: rgb(255 251 235 / 0.95) !important;
}

.tw-ring-offset-amber-500 {
  --tw-ring-offset-color: #f59e0b !important;
}

.tw-ring-offset-amber-500\/0 {
  --tw-ring-offset-color: rgb(245 158 11 / 0) !important;
}

.tw-ring-offset-amber-500\/10 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.1) !important;
}

.tw-ring-offset-amber-500\/100 {
  --tw-ring-offset-color: rgb(245 158 11 / 1) !important;
}

.tw-ring-offset-amber-500\/15 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.15) !important;
}

.tw-ring-offset-amber-500\/20 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.2) !important;
}

.tw-ring-offset-amber-500\/25 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.25) !important;
}

.tw-ring-offset-amber-500\/30 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.3) !important;
}

.tw-ring-offset-amber-500\/35 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.35) !important;
}

.tw-ring-offset-amber-500\/40 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.4) !important;
}

.tw-ring-offset-amber-500\/45 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.45) !important;
}

.tw-ring-offset-amber-500\/5 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.05) !important;
}

.tw-ring-offset-amber-500\/50 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.5) !important;
}

.tw-ring-offset-amber-500\/55 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.55) !important;
}

.tw-ring-offset-amber-500\/60 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.6) !important;
}

.tw-ring-offset-amber-500\/65 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.65) !important;
}

.tw-ring-offset-amber-500\/70 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.7) !important;
}

.tw-ring-offset-amber-500\/75 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.75) !important;
}

.tw-ring-offset-amber-500\/80 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.8) !important;
}

.tw-ring-offset-amber-500\/85 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.85) !important;
}

.tw-ring-offset-amber-500\/90 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.9) !important;
}

.tw-ring-offset-amber-500\/95 {
  --tw-ring-offset-color: rgb(245 158 11 / 0.95) !important;
}

.tw-ring-offset-amber-600 {
  --tw-ring-offset-color: #d97706 !important;
}

.tw-ring-offset-amber-600\/0 {
  --tw-ring-offset-color: rgb(217 119 6 / 0) !important;
}

.tw-ring-offset-amber-600\/10 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.1) !important;
}

.tw-ring-offset-amber-600\/100 {
  --tw-ring-offset-color: rgb(217 119 6 / 1) !important;
}

.tw-ring-offset-amber-600\/15 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.15) !important;
}

.tw-ring-offset-amber-600\/20 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.2) !important;
}

.tw-ring-offset-amber-600\/25 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.25) !important;
}

.tw-ring-offset-amber-600\/30 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.3) !important;
}

.tw-ring-offset-amber-600\/35 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.35) !important;
}

.tw-ring-offset-amber-600\/40 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.4) !important;
}

.tw-ring-offset-amber-600\/45 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.45) !important;
}

.tw-ring-offset-amber-600\/5 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.05) !important;
}

.tw-ring-offset-amber-600\/50 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.5) !important;
}

.tw-ring-offset-amber-600\/55 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.55) !important;
}

.tw-ring-offset-amber-600\/60 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.6) !important;
}

.tw-ring-offset-amber-600\/65 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.65) !important;
}

.tw-ring-offset-amber-600\/70 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.7) !important;
}

.tw-ring-offset-amber-600\/75 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.75) !important;
}

.tw-ring-offset-amber-600\/80 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.8) !important;
}

.tw-ring-offset-amber-600\/85 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.85) !important;
}

.tw-ring-offset-amber-600\/90 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.9) !important;
}

.tw-ring-offset-amber-600\/95 {
  --tw-ring-offset-color: rgb(217 119 6 / 0.95) !important;
}

.tw-ring-offset-amber-700 {
  --tw-ring-offset-color: #b45309 !important;
}

.tw-ring-offset-amber-700\/0 {
  --tw-ring-offset-color: rgb(180 83 9 / 0) !important;
}

.tw-ring-offset-amber-700\/10 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.1) !important;
}

.tw-ring-offset-amber-700\/100 {
  --tw-ring-offset-color: rgb(180 83 9 / 1) !important;
}

.tw-ring-offset-amber-700\/15 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.15) !important;
}

.tw-ring-offset-amber-700\/20 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.2) !important;
}

.tw-ring-offset-amber-700\/25 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.25) !important;
}

.tw-ring-offset-amber-700\/30 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.3) !important;
}

.tw-ring-offset-amber-700\/35 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.35) !important;
}

.tw-ring-offset-amber-700\/40 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.4) !important;
}

.tw-ring-offset-amber-700\/45 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.45) !important;
}

.tw-ring-offset-amber-700\/5 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.05) !important;
}

.tw-ring-offset-amber-700\/50 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.5) !important;
}

.tw-ring-offset-amber-700\/55 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.55) !important;
}

.tw-ring-offset-amber-700\/60 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.6) !important;
}

.tw-ring-offset-amber-700\/65 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.65) !important;
}

.tw-ring-offset-amber-700\/70 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.7) !important;
}

.tw-ring-offset-amber-700\/75 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.75) !important;
}

.tw-ring-offset-amber-700\/80 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.8) !important;
}

.tw-ring-offset-amber-700\/85 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.85) !important;
}

.tw-ring-offset-amber-700\/90 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.9) !important;
}

.tw-ring-offset-amber-700\/95 {
  --tw-ring-offset-color: rgb(180 83 9 / 0.95) !important;
}

.tw-ring-offset-amber-800 {
  --tw-ring-offset-color: #92400e !important;
}

.tw-ring-offset-amber-800\/0 {
  --tw-ring-offset-color: rgb(146 64 14 / 0) !important;
}

.tw-ring-offset-amber-800\/10 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.1) !important;
}

.tw-ring-offset-amber-800\/100 {
  --tw-ring-offset-color: rgb(146 64 14 / 1) !important;
}

.tw-ring-offset-amber-800\/15 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.15) !important;
}

.tw-ring-offset-amber-800\/20 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.2) !important;
}

.tw-ring-offset-amber-800\/25 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.25) !important;
}

.tw-ring-offset-amber-800\/30 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.3) !important;
}

.tw-ring-offset-amber-800\/35 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.35) !important;
}

.tw-ring-offset-amber-800\/40 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.4) !important;
}

.tw-ring-offset-amber-800\/45 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.45) !important;
}

.tw-ring-offset-amber-800\/5 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.05) !important;
}

.tw-ring-offset-amber-800\/50 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.5) !important;
}

.tw-ring-offset-amber-800\/55 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.55) !important;
}

.tw-ring-offset-amber-800\/60 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.6) !important;
}

.tw-ring-offset-amber-800\/65 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.65) !important;
}

.tw-ring-offset-amber-800\/70 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.7) !important;
}

.tw-ring-offset-amber-800\/75 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.75) !important;
}

.tw-ring-offset-amber-800\/80 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.8) !important;
}

.tw-ring-offset-amber-800\/85 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.85) !important;
}

.tw-ring-offset-amber-800\/90 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.9) !important;
}

.tw-ring-offset-amber-800\/95 {
  --tw-ring-offset-color: rgb(146 64 14 / 0.95) !important;
}

.tw-ring-offset-amber-900 {
  --tw-ring-offset-color: #78350f !important;
}

.tw-ring-offset-amber-900\/0 {
  --tw-ring-offset-color: rgb(120 53 15 / 0) !important;
}

.tw-ring-offset-amber-900\/10 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.1) !important;
}

.tw-ring-offset-amber-900\/100 {
  --tw-ring-offset-color: rgb(120 53 15 / 1) !important;
}

.tw-ring-offset-amber-900\/15 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.15) !important;
}

.tw-ring-offset-amber-900\/20 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.2) !important;
}

.tw-ring-offset-amber-900\/25 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.25) !important;
}

.tw-ring-offset-amber-900\/30 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.3) !important;
}

.tw-ring-offset-amber-900\/35 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.35) !important;
}

.tw-ring-offset-amber-900\/40 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.4) !important;
}

.tw-ring-offset-amber-900\/45 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.45) !important;
}

.tw-ring-offset-amber-900\/5 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.05) !important;
}

.tw-ring-offset-amber-900\/50 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.5) !important;
}

.tw-ring-offset-amber-900\/55 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.55) !important;
}

.tw-ring-offset-amber-900\/60 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.6) !important;
}

.tw-ring-offset-amber-900\/65 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.65) !important;
}

.tw-ring-offset-amber-900\/70 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.7) !important;
}

.tw-ring-offset-amber-900\/75 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.75) !important;
}

.tw-ring-offset-amber-900\/80 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.8) !important;
}

.tw-ring-offset-amber-900\/85 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.85) !important;
}

.tw-ring-offset-amber-900\/90 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.9) !important;
}

.tw-ring-offset-amber-900\/95 {
  --tw-ring-offset-color: rgb(120 53 15 / 0.95) !important;
}

.tw-ring-offset-amber-950 {
  --tw-ring-offset-color: #451a03 !important;
}

.tw-ring-offset-amber-950\/0 {
  --tw-ring-offset-color: rgb(69 26 3 / 0) !important;
}

.tw-ring-offset-amber-950\/10 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.1) !important;
}

.tw-ring-offset-amber-950\/100 {
  --tw-ring-offset-color: rgb(69 26 3 / 1) !important;
}

.tw-ring-offset-amber-950\/15 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.15) !important;
}

.tw-ring-offset-amber-950\/20 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.2) !important;
}

.tw-ring-offset-amber-950\/25 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.25) !important;
}

.tw-ring-offset-amber-950\/30 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.3) !important;
}

.tw-ring-offset-amber-950\/35 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.35) !important;
}

.tw-ring-offset-amber-950\/40 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.4) !important;
}

.tw-ring-offset-amber-950\/45 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.45) !important;
}

.tw-ring-offset-amber-950\/5 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.05) !important;
}

.tw-ring-offset-amber-950\/50 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.5) !important;
}

.tw-ring-offset-amber-950\/55 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.55) !important;
}

.tw-ring-offset-amber-950\/60 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.6) !important;
}

.tw-ring-offset-amber-950\/65 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.65) !important;
}

.tw-ring-offset-amber-950\/70 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.7) !important;
}

.tw-ring-offset-amber-950\/75 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.75) !important;
}

.tw-ring-offset-amber-950\/80 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.8) !important;
}

.tw-ring-offset-amber-950\/85 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.85) !important;
}

.tw-ring-offset-amber-950\/90 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.9) !important;
}

.tw-ring-offset-amber-950\/95 {
  --tw-ring-offset-color: rgb(69 26 3 / 0.95) !important;
}

.tw-ring-offset-black {
  --tw-ring-offset-color: #000 !important;
}

.tw-ring-offset-black\/0 {
  --tw-ring-offset-color: rgb(0 0 0 / 0) !important;
}

.tw-ring-offset-black\/10 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.1) !important;
}

.tw-ring-offset-black\/100 {
  --tw-ring-offset-color: rgb(0 0 0 / 1) !important;
}

.tw-ring-offset-black\/15 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.15) !important;
}

.tw-ring-offset-black\/20 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.2) !important;
}

.tw-ring-offset-black\/25 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.25) !important;
}

.tw-ring-offset-black\/30 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.3) !important;
}

.tw-ring-offset-black\/35 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.35) !important;
}

.tw-ring-offset-black\/40 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.4) !important;
}

.tw-ring-offset-black\/45 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.45) !important;
}

.tw-ring-offset-black\/5 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.05) !important;
}

.tw-ring-offset-black\/50 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.5) !important;
}

.tw-ring-offset-black\/55 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.55) !important;
}

.tw-ring-offset-black\/60 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.6) !important;
}

.tw-ring-offset-black\/65 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.65) !important;
}

.tw-ring-offset-black\/70 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.7) !important;
}

.tw-ring-offset-black\/75 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.75) !important;
}

.tw-ring-offset-black\/80 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.8) !important;
}

.tw-ring-offset-black\/85 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.85) !important;
}

.tw-ring-offset-black\/90 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.9) !important;
}

.tw-ring-offset-black\/95 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.95) !important;
}

.tw-ring-offset-blue-100 {
  --tw-ring-offset-color: #dbeafe !important;
}

.tw-ring-offset-blue-100\/0 {
  --tw-ring-offset-color: rgb(219 234 254 / 0) !important;
}

.tw-ring-offset-blue-100\/10 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.1) !important;
}

.tw-ring-offset-blue-100\/100 {
  --tw-ring-offset-color: rgb(219 234 254 / 1) !important;
}

.tw-ring-offset-blue-100\/15 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.15) !important;
}

.tw-ring-offset-blue-100\/20 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.2) !important;
}

.tw-ring-offset-blue-100\/25 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.25) !important;
}

.tw-ring-offset-blue-100\/30 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.3) !important;
}

.tw-ring-offset-blue-100\/35 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.35) !important;
}

.tw-ring-offset-blue-100\/40 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.4) !important;
}

.tw-ring-offset-blue-100\/45 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.45) !important;
}

.tw-ring-offset-blue-100\/5 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.05) !important;
}

.tw-ring-offset-blue-100\/50 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.5) !important;
}

.tw-ring-offset-blue-100\/55 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.55) !important;
}

.tw-ring-offset-blue-100\/60 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.6) !important;
}

.tw-ring-offset-blue-100\/65 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.65) !important;
}

.tw-ring-offset-blue-100\/70 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.7) !important;
}

.tw-ring-offset-blue-100\/75 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.75) !important;
}

.tw-ring-offset-blue-100\/80 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.8) !important;
}

.tw-ring-offset-blue-100\/85 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.85) !important;
}

.tw-ring-offset-blue-100\/90 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.9) !important;
}

.tw-ring-offset-blue-100\/95 {
  --tw-ring-offset-color: rgb(219 234 254 / 0.95) !important;
}

.tw-ring-offset-blue-200 {
  --tw-ring-offset-color: #bfdbfe !important;
}

.tw-ring-offset-blue-200\/0 {
  --tw-ring-offset-color: rgb(191 219 254 / 0) !important;
}

.tw-ring-offset-blue-200\/10 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.1) !important;
}

.tw-ring-offset-blue-200\/100 {
  --tw-ring-offset-color: rgb(191 219 254 / 1) !important;
}

.tw-ring-offset-blue-200\/15 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.15) !important;
}

.tw-ring-offset-blue-200\/20 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.2) !important;
}

.tw-ring-offset-blue-200\/25 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.25) !important;
}

.tw-ring-offset-blue-200\/30 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.3) !important;
}

.tw-ring-offset-blue-200\/35 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.35) !important;
}

.tw-ring-offset-blue-200\/40 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.4) !important;
}

.tw-ring-offset-blue-200\/45 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.45) !important;
}

.tw-ring-offset-blue-200\/5 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.05) !important;
}

.tw-ring-offset-blue-200\/50 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.5) !important;
}

.tw-ring-offset-blue-200\/55 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.55) !important;
}

.tw-ring-offset-blue-200\/60 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.6) !important;
}

.tw-ring-offset-blue-200\/65 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.65) !important;
}

.tw-ring-offset-blue-200\/70 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.7) !important;
}

.tw-ring-offset-blue-200\/75 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.75) !important;
}

.tw-ring-offset-blue-200\/80 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.8) !important;
}

.tw-ring-offset-blue-200\/85 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.85) !important;
}

.tw-ring-offset-blue-200\/90 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.9) !important;
}

.tw-ring-offset-blue-200\/95 {
  --tw-ring-offset-color: rgb(191 219 254 / 0.95) !important;
}

.tw-ring-offset-blue-300 {
  --tw-ring-offset-color: #93c5fd !important;
}

.tw-ring-offset-blue-300\/0 {
  --tw-ring-offset-color: rgb(147 197 253 / 0) !important;
}

.tw-ring-offset-blue-300\/10 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.1) !important;
}

.tw-ring-offset-blue-300\/100 {
  --tw-ring-offset-color: rgb(147 197 253 / 1) !important;
}

.tw-ring-offset-blue-300\/15 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.15) !important;
}

.tw-ring-offset-blue-300\/20 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.2) !important;
}

.tw-ring-offset-blue-300\/25 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.25) !important;
}

.tw-ring-offset-blue-300\/30 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.3) !important;
}

.tw-ring-offset-blue-300\/35 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.35) !important;
}

.tw-ring-offset-blue-300\/40 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.4) !important;
}

.tw-ring-offset-blue-300\/45 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.45) !important;
}

.tw-ring-offset-blue-300\/5 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.05) !important;
}

.tw-ring-offset-blue-300\/50 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.5) !important;
}

.tw-ring-offset-blue-300\/55 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.55) !important;
}

.tw-ring-offset-blue-300\/60 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.6) !important;
}

.tw-ring-offset-blue-300\/65 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.65) !important;
}

.tw-ring-offset-blue-300\/70 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.7) !important;
}

.tw-ring-offset-blue-300\/75 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.75) !important;
}

.tw-ring-offset-blue-300\/80 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.8) !important;
}

.tw-ring-offset-blue-300\/85 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.85) !important;
}

.tw-ring-offset-blue-300\/90 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.9) !important;
}

.tw-ring-offset-blue-300\/95 {
  --tw-ring-offset-color: rgb(147 197 253 / 0.95) !important;
}

.tw-ring-offset-blue-400 {
  --tw-ring-offset-color: #60a5fa !important;
}

.tw-ring-offset-blue-400\/0 {
  --tw-ring-offset-color: rgb(96 165 250 / 0) !important;
}

.tw-ring-offset-blue-400\/10 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.1) !important;
}

.tw-ring-offset-blue-400\/100 {
  --tw-ring-offset-color: rgb(96 165 250 / 1) !important;
}

.tw-ring-offset-blue-400\/15 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.15) !important;
}

.tw-ring-offset-blue-400\/20 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.2) !important;
}

.tw-ring-offset-blue-400\/25 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.25) !important;
}

.tw-ring-offset-blue-400\/30 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.3) !important;
}

.tw-ring-offset-blue-400\/35 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.35) !important;
}

.tw-ring-offset-blue-400\/40 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.4) !important;
}

.tw-ring-offset-blue-400\/45 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.45) !important;
}

.tw-ring-offset-blue-400\/5 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.05) !important;
}

.tw-ring-offset-blue-400\/50 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.5) !important;
}

.tw-ring-offset-blue-400\/55 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.55) !important;
}

.tw-ring-offset-blue-400\/60 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.6) !important;
}

.tw-ring-offset-blue-400\/65 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.65) !important;
}

.tw-ring-offset-blue-400\/70 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.7) !important;
}

.tw-ring-offset-blue-400\/75 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.75) !important;
}

.tw-ring-offset-blue-400\/80 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.8) !important;
}

.tw-ring-offset-blue-400\/85 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.85) !important;
}

.tw-ring-offset-blue-400\/90 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.9) !important;
}

.tw-ring-offset-blue-400\/95 {
  --tw-ring-offset-color: rgb(96 165 250 / 0.95) !important;
}

.tw-ring-offset-blue-50 {
  --tw-ring-offset-color: #eff6ff !important;
}

.tw-ring-offset-blue-50\/0 {
  --tw-ring-offset-color: rgb(239 246 255 / 0) !important;
}

.tw-ring-offset-blue-50\/10 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.1) !important;
}

.tw-ring-offset-blue-50\/100 {
  --tw-ring-offset-color: rgb(239 246 255 / 1) !important;
}

.tw-ring-offset-blue-50\/15 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.15) !important;
}

.tw-ring-offset-blue-50\/20 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.2) !important;
}

.tw-ring-offset-blue-50\/25 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.25) !important;
}

.tw-ring-offset-blue-50\/30 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.3) !important;
}

.tw-ring-offset-blue-50\/35 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.35) !important;
}

.tw-ring-offset-blue-50\/40 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.4) !important;
}

.tw-ring-offset-blue-50\/45 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.45) !important;
}

.tw-ring-offset-blue-50\/5 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.05) !important;
}

.tw-ring-offset-blue-50\/50 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.5) !important;
}

.tw-ring-offset-blue-50\/55 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.55) !important;
}

.tw-ring-offset-blue-50\/60 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.6) !important;
}

.tw-ring-offset-blue-50\/65 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.65) !important;
}

.tw-ring-offset-blue-50\/70 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.7) !important;
}

.tw-ring-offset-blue-50\/75 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.75) !important;
}

.tw-ring-offset-blue-50\/80 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.8) !important;
}

.tw-ring-offset-blue-50\/85 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.85) !important;
}

.tw-ring-offset-blue-50\/90 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.9) !important;
}

.tw-ring-offset-blue-50\/95 {
  --tw-ring-offset-color: rgb(239 246 255 / 0.95) !important;
}

.tw-ring-offset-blue-500 {
  --tw-ring-offset-color: #3b82f6 !important;
}

.tw-ring-offset-blue-500\/0 {
  --tw-ring-offset-color: rgb(59 130 246 / 0) !important;
}

.tw-ring-offset-blue-500\/10 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.1) !important;
}

.tw-ring-offset-blue-500\/100 {
  --tw-ring-offset-color: rgb(59 130 246 / 1) !important;
}

.tw-ring-offset-blue-500\/15 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.15) !important;
}

.tw-ring-offset-blue-500\/20 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.2) !important;
}

.tw-ring-offset-blue-500\/25 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.25) !important;
}

.tw-ring-offset-blue-500\/30 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.3) !important;
}

.tw-ring-offset-blue-500\/35 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.35) !important;
}

.tw-ring-offset-blue-500\/40 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.4) !important;
}

.tw-ring-offset-blue-500\/45 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.45) !important;
}

.tw-ring-offset-blue-500\/5 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.05) !important;
}

.tw-ring-offset-blue-500\/50 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.5) !important;
}

.tw-ring-offset-blue-500\/55 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.55) !important;
}

.tw-ring-offset-blue-500\/60 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.6) !important;
}

.tw-ring-offset-blue-500\/65 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.65) !important;
}

.tw-ring-offset-blue-500\/70 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.7) !important;
}

.tw-ring-offset-blue-500\/75 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.75) !important;
}

.tw-ring-offset-blue-500\/80 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.8) !important;
}

.tw-ring-offset-blue-500\/85 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.85) !important;
}

.tw-ring-offset-blue-500\/90 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.9) !important;
}

.tw-ring-offset-blue-500\/95 {
  --tw-ring-offset-color: rgb(59 130 246 / 0.95) !important;
}

.tw-ring-offset-blue-600 {
  --tw-ring-offset-color: #2563eb !important;
}

.tw-ring-offset-blue-600\/0 {
  --tw-ring-offset-color: rgb(37 99 235 / 0) !important;
}

.tw-ring-offset-blue-600\/10 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.1) !important;
}

.tw-ring-offset-blue-600\/100 {
  --tw-ring-offset-color: rgb(37 99 235 / 1) !important;
}

.tw-ring-offset-blue-600\/15 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.15) !important;
}

.tw-ring-offset-blue-600\/20 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.2) !important;
}

.tw-ring-offset-blue-600\/25 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.25) !important;
}

.tw-ring-offset-blue-600\/30 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.3) !important;
}

.tw-ring-offset-blue-600\/35 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.35) !important;
}

.tw-ring-offset-blue-600\/40 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.4) !important;
}

.tw-ring-offset-blue-600\/45 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.45) !important;
}

.tw-ring-offset-blue-600\/5 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.05) !important;
}

.tw-ring-offset-blue-600\/50 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.5) !important;
}

.tw-ring-offset-blue-600\/55 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.55) !important;
}

.tw-ring-offset-blue-600\/60 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.6) !important;
}

.tw-ring-offset-blue-600\/65 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.65) !important;
}

.tw-ring-offset-blue-600\/70 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.7) !important;
}

.tw-ring-offset-blue-600\/75 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.75) !important;
}

.tw-ring-offset-blue-600\/80 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.8) !important;
}

.tw-ring-offset-blue-600\/85 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.85) !important;
}

.tw-ring-offset-blue-600\/90 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.9) !important;
}

.tw-ring-offset-blue-600\/95 {
  --tw-ring-offset-color: rgb(37 99 235 / 0.95) !important;
}

.tw-ring-offset-blue-700 {
  --tw-ring-offset-color: #1d4ed8 !important;
}

.tw-ring-offset-blue-700\/0 {
  --tw-ring-offset-color: rgb(29 78 216 / 0) !important;
}

.tw-ring-offset-blue-700\/10 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.1) !important;
}

.tw-ring-offset-blue-700\/100 {
  --tw-ring-offset-color: rgb(29 78 216 / 1) !important;
}

.tw-ring-offset-blue-700\/15 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.15) !important;
}

.tw-ring-offset-blue-700\/20 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.2) !important;
}

.tw-ring-offset-blue-700\/25 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.25) !important;
}

.tw-ring-offset-blue-700\/30 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.3) !important;
}

.tw-ring-offset-blue-700\/35 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.35) !important;
}

.tw-ring-offset-blue-700\/40 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.4) !important;
}

.tw-ring-offset-blue-700\/45 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.45) !important;
}

.tw-ring-offset-blue-700\/5 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.05) !important;
}

.tw-ring-offset-blue-700\/50 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.5) !important;
}

.tw-ring-offset-blue-700\/55 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.55) !important;
}

.tw-ring-offset-blue-700\/60 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.6) !important;
}

.tw-ring-offset-blue-700\/65 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.65) !important;
}

.tw-ring-offset-blue-700\/70 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.7) !important;
}

.tw-ring-offset-blue-700\/75 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.75) !important;
}

.tw-ring-offset-blue-700\/80 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.8) !important;
}

.tw-ring-offset-blue-700\/85 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.85) !important;
}

.tw-ring-offset-blue-700\/90 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.9) !important;
}

.tw-ring-offset-blue-700\/95 {
  --tw-ring-offset-color: rgb(29 78 216 / 0.95) !important;
}

.tw-ring-offset-blue-800 {
  --tw-ring-offset-color: #1e40af !important;
}

.tw-ring-offset-blue-800\/0 {
  --tw-ring-offset-color: rgb(30 64 175 / 0) !important;
}

.tw-ring-offset-blue-800\/10 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.1) !important;
}

.tw-ring-offset-blue-800\/100 {
  --tw-ring-offset-color: rgb(30 64 175 / 1) !important;
}

.tw-ring-offset-blue-800\/15 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.15) !important;
}

.tw-ring-offset-blue-800\/20 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.2) !important;
}

.tw-ring-offset-blue-800\/25 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.25) !important;
}

.tw-ring-offset-blue-800\/30 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.3) !important;
}

.tw-ring-offset-blue-800\/35 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.35) !important;
}

.tw-ring-offset-blue-800\/40 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.4) !important;
}

.tw-ring-offset-blue-800\/45 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.45) !important;
}

.tw-ring-offset-blue-800\/5 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.05) !important;
}

.tw-ring-offset-blue-800\/50 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.5) !important;
}

.tw-ring-offset-blue-800\/55 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.55) !important;
}

.tw-ring-offset-blue-800\/60 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.6) !important;
}

.tw-ring-offset-blue-800\/65 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.65) !important;
}

.tw-ring-offset-blue-800\/70 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.7) !important;
}

.tw-ring-offset-blue-800\/75 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.75) !important;
}

.tw-ring-offset-blue-800\/80 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.8) !important;
}

.tw-ring-offset-blue-800\/85 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.85) !important;
}

.tw-ring-offset-blue-800\/90 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.9) !important;
}

.tw-ring-offset-blue-800\/95 {
  --tw-ring-offset-color: rgb(30 64 175 / 0.95) !important;
}

.tw-ring-offset-blue-900 {
  --tw-ring-offset-color: #1e3a8a !important;
}

.tw-ring-offset-blue-900\/0 {
  --tw-ring-offset-color: rgb(30 58 138 / 0) !important;
}

.tw-ring-offset-blue-900\/10 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.1) !important;
}

.tw-ring-offset-blue-900\/100 {
  --tw-ring-offset-color: rgb(30 58 138 / 1) !important;
}

.tw-ring-offset-blue-900\/15 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.15) !important;
}

.tw-ring-offset-blue-900\/20 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.2) !important;
}

.tw-ring-offset-blue-900\/25 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.25) !important;
}

.tw-ring-offset-blue-900\/30 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.3) !important;
}

.tw-ring-offset-blue-900\/35 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.35) !important;
}

.tw-ring-offset-blue-900\/40 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.4) !important;
}

.tw-ring-offset-blue-900\/45 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.45) !important;
}

.tw-ring-offset-blue-900\/5 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.05) !important;
}

.tw-ring-offset-blue-900\/50 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.5) !important;
}

.tw-ring-offset-blue-900\/55 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.55) !important;
}

.tw-ring-offset-blue-900\/60 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.6) !important;
}

.tw-ring-offset-blue-900\/65 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.65) !important;
}

.tw-ring-offset-blue-900\/70 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.7) !important;
}

.tw-ring-offset-blue-900\/75 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.75) !important;
}

.tw-ring-offset-blue-900\/80 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.8) !important;
}

.tw-ring-offset-blue-900\/85 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.85) !important;
}

.tw-ring-offset-blue-900\/90 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.9) !important;
}

.tw-ring-offset-blue-900\/95 {
  --tw-ring-offset-color: rgb(30 58 138 / 0.95) !important;
}

.tw-ring-offset-blue-950 {
  --tw-ring-offset-color: #172554 !important;
}

.tw-ring-offset-blue-950\/0 {
  --tw-ring-offset-color: rgb(23 37 84 / 0) !important;
}

.tw-ring-offset-blue-950\/10 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.1) !important;
}

.tw-ring-offset-blue-950\/100 {
  --tw-ring-offset-color: rgb(23 37 84 / 1) !important;
}

.tw-ring-offset-blue-950\/15 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.15) !important;
}

.tw-ring-offset-blue-950\/20 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.2) !important;
}

.tw-ring-offset-blue-950\/25 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.25) !important;
}

.tw-ring-offset-blue-950\/30 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.3) !important;
}

.tw-ring-offset-blue-950\/35 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.35) !important;
}

.tw-ring-offset-blue-950\/40 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.4) !important;
}

.tw-ring-offset-blue-950\/45 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.45) !important;
}

.tw-ring-offset-blue-950\/5 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.05) !important;
}

.tw-ring-offset-blue-950\/50 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.5) !important;
}

.tw-ring-offset-blue-950\/55 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.55) !important;
}

.tw-ring-offset-blue-950\/60 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.6) !important;
}

.tw-ring-offset-blue-950\/65 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.65) !important;
}

.tw-ring-offset-blue-950\/70 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.7) !important;
}

.tw-ring-offset-blue-950\/75 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.75) !important;
}

.tw-ring-offset-blue-950\/80 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.8) !important;
}

.tw-ring-offset-blue-950\/85 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.85) !important;
}

.tw-ring-offset-blue-950\/90 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.9) !important;
}

.tw-ring-offset-blue-950\/95 {
  --tw-ring-offset-color: rgb(23 37 84 / 0.95) !important;
}

.tw-ring-offset-current {
  --tw-ring-offset-color: currentColor !important;
}

.tw-ring-offset-cyan-100 {
  --tw-ring-offset-color: #cffafe !important;
}

.tw-ring-offset-cyan-100\/0 {
  --tw-ring-offset-color: rgb(207 250 254 / 0) !important;
}

.tw-ring-offset-cyan-100\/10 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.1) !important;
}

.tw-ring-offset-cyan-100\/100 {
  --tw-ring-offset-color: rgb(207 250 254 / 1) !important;
}

.tw-ring-offset-cyan-100\/15 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.15) !important;
}

.tw-ring-offset-cyan-100\/20 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.2) !important;
}

.tw-ring-offset-cyan-100\/25 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.25) !important;
}

.tw-ring-offset-cyan-100\/30 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.3) !important;
}

.tw-ring-offset-cyan-100\/35 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.35) !important;
}

.tw-ring-offset-cyan-100\/40 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.4) !important;
}

.tw-ring-offset-cyan-100\/45 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.45) !important;
}

.tw-ring-offset-cyan-100\/5 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.05) !important;
}

.tw-ring-offset-cyan-100\/50 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.5) !important;
}

.tw-ring-offset-cyan-100\/55 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.55) !important;
}

.tw-ring-offset-cyan-100\/60 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.6) !important;
}

.tw-ring-offset-cyan-100\/65 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.65) !important;
}

.tw-ring-offset-cyan-100\/70 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.7) !important;
}

.tw-ring-offset-cyan-100\/75 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.75) !important;
}

.tw-ring-offset-cyan-100\/80 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.8) !important;
}

.tw-ring-offset-cyan-100\/85 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.85) !important;
}

.tw-ring-offset-cyan-100\/90 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.9) !important;
}

.tw-ring-offset-cyan-100\/95 {
  --tw-ring-offset-color: rgb(207 250 254 / 0.95) !important;
}

.tw-ring-offset-cyan-200 {
  --tw-ring-offset-color: #a5f3fc !important;
}

.tw-ring-offset-cyan-200\/0 {
  --tw-ring-offset-color: rgb(165 243 252 / 0) !important;
}

.tw-ring-offset-cyan-200\/10 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.1) !important;
}

.tw-ring-offset-cyan-200\/100 {
  --tw-ring-offset-color: rgb(165 243 252 / 1) !important;
}

.tw-ring-offset-cyan-200\/15 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.15) !important;
}

.tw-ring-offset-cyan-200\/20 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.2) !important;
}

.tw-ring-offset-cyan-200\/25 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.25) !important;
}

.tw-ring-offset-cyan-200\/30 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.3) !important;
}

.tw-ring-offset-cyan-200\/35 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.35) !important;
}

.tw-ring-offset-cyan-200\/40 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.4) !important;
}

.tw-ring-offset-cyan-200\/45 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.45) !important;
}

.tw-ring-offset-cyan-200\/5 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.05) !important;
}

.tw-ring-offset-cyan-200\/50 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.5) !important;
}

.tw-ring-offset-cyan-200\/55 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.55) !important;
}

.tw-ring-offset-cyan-200\/60 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.6) !important;
}

.tw-ring-offset-cyan-200\/65 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.65) !important;
}

.tw-ring-offset-cyan-200\/70 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.7) !important;
}

.tw-ring-offset-cyan-200\/75 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.75) !important;
}

.tw-ring-offset-cyan-200\/80 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.8) !important;
}

.tw-ring-offset-cyan-200\/85 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.85) !important;
}

.tw-ring-offset-cyan-200\/90 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.9) !important;
}

.tw-ring-offset-cyan-200\/95 {
  --tw-ring-offset-color: rgb(165 243 252 / 0.95) !important;
}

.tw-ring-offset-cyan-300 {
  --tw-ring-offset-color: #67e8f9 !important;
}

.tw-ring-offset-cyan-300\/0 {
  --tw-ring-offset-color: rgb(103 232 249 / 0) !important;
}

.tw-ring-offset-cyan-300\/10 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.1) !important;
}

.tw-ring-offset-cyan-300\/100 {
  --tw-ring-offset-color: rgb(103 232 249 / 1) !important;
}

.tw-ring-offset-cyan-300\/15 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.15) !important;
}

.tw-ring-offset-cyan-300\/20 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.2) !important;
}

.tw-ring-offset-cyan-300\/25 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.25) !important;
}

.tw-ring-offset-cyan-300\/30 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.3) !important;
}

.tw-ring-offset-cyan-300\/35 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.35) !important;
}

.tw-ring-offset-cyan-300\/40 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.4) !important;
}

.tw-ring-offset-cyan-300\/45 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.45) !important;
}

.tw-ring-offset-cyan-300\/5 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.05) !important;
}

.tw-ring-offset-cyan-300\/50 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.5) !important;
}

.tw-ring-offset-cyan-300\/55 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.55) !important;
}

.tw-ring-offset-cyan-300\/60 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.6) !important;
}

.tw-ring-offset-cyan-300\/65 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.65) !important;
}

.tw-ring-offset-cyan-300\/70 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.7) !important;
}

.tw-ring-offset-cyan-300\/75 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.75) !important;
}

.tw-ring-offset-cyan-300\/80 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.8) !important;
}

.tw-ring-offset-cyan-300\/85 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.85) !important;
}

.tw-ring-offset-cyan-300\/90 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.9) !important;
}

.tw-ring-offset-cyan-300\/95 {
  --tw-ring-offset-color: rgb(103 232 249 / 0.95) !important;
}

.tw-ring-offset-cyan-400 {
  --tw-ring-offset-color: #22d3ee !important;
}

.tw-ring-offset-cyan-400\/0 {
  --tw-ring-offset-color: rgb(34 211 238 / 0) !important;
}

.tw-ring-offset-cyan-400\/10 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.1) !important;
}

.tw-ring-offset-cyan-400\/100 {
  --tw-ring-offset-color: rgb(34 211 238 / 1) !important;
}

.tw-ring-offset-cyan-400\/15 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.15) !important;
}

.tw-ring-offset-cyan-400\/20 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.2) !important;
}

.tw-ring-offset-cyan-400\/25 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.25) !important;
}

.tw-ring-offset-cyan-400\/30 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.3) !important;
}

.tw-ring-offset-cyan-400\/35 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.35) !important;
}

.tw-ring-offset-cyan-400\/40 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.4) !important;
}

.tw-ring-offset-cyan-400\/45 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.45) !important;
}

.tw-ring-offset-cyan-400\/5 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.05) !important;
}

.tw-ring-offset-cyan-400\/50 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.5) !important;
}

.tw-ring-offset-cyan-400\/55 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.55) !important;
}

.tw-ring-offset-cyan-400\/60 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.6) !important;
}

.tw-ring-offset-cyan-400\/65 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.65) !important;
}

.tw-ring-offset-cyan-400\/70 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.7) !important;
}

.tw-ring-offset-cyan-400\/75 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.75) !important;
}

.tw-ring-offset-cyan-400\/80 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.8) !important;
}

.tw-ring-offset-cyan-400\/85 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.85) !important;
}

.tw-ring-offset-cyan-400\/90 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.9) !important;
}

.tw-ring-offset-cyan-400\/95 {
  --tw-ring-offset-color: rgb(34 211 238 / 0.95) !important;
}

.tw-ring-offset-cyan-50 {
  --tw-ring-offset-color: #ecfeff !important;
}

.tw-ring-offset-cyan-50\/0 {
  --tw-ring-offset-color: rgb(236 254 255 / 0) !important;
}

.tw-ring-offset-cyan-50\/10 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.1) !important;
}

.tw-ring-offset-cyan-50\/100 {
  --tw-ring-offset-color: rgb(236 254 255 / 1) !important;
}

.tw-ring-offset-cyan-50\/15 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.15) !important;
}

.tw-ring-offset-cyan-50\/20 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.2) !important;
}

.tw-ring-offset-cyan-50\/25 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.25) !important;
}

.tw-ring-offset-cyan-50\/30 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.3) !important;
}

.tw-ring-offset-cyan-50\/35 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.35) !important;
}

.tw-ring-offset-cyan-50\/40 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.4) !important;
}

.tw-ring-offset-cyan-50\/45 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.45) !important;
}

.tw-ring-offset-cyan-50\/5 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.05) !important;
}

.tw-ring-offset-cyan-50\/50 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.5) !important;
}

.tw-ring-offset-cyan-50\/55 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.55) !important;
}

.tw-ring-offset-cyan-50\/60 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.6) !important;
}

.tw-ring-offset-cyan-50\/65 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.65) !important;
}

.tw-ring-offset-cyan-50\/70 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.7) !important;
}

.tw-ring-offset-cyan-50\/75 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.75) !important;
}

.tw-ring-offset-cyan-50\/80 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.8) !important;
}

.tw-ring-offset-cyan-50\/85 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.85) !important;
}

.tw-ring-offset-cyan-50\/90 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.9) !important;
}

.tw-ring-offset-cyan-50\/95 {
  --tw-ring-offset-color: rgb(236 254 255 / 0.95) !important;
}

.tw-ring-offset-cyan-500 {
  --tw-ring-offset-color: #06b6d4 !important;
}

.tw-ring-offset-cyan-500\/0 {
  --tw-ring-offset-color: rgb(6 182 212 / 0) !important;
}

.tw-ring-offset-cyan-500\/10 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.1) !important;
}

.tw-ring-offset-cyan-500\/100 {
  --tw-ring-offset-color: rgb(6 182 212 / 1) !important;
}

.tw-ring-offset-cyan-500\/15 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.15) !important;
}

.tw-ring-offset-cyan-500\/20 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.2) !important;
}

.tw-ring-offset-cyan-500\/25 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.25) !important;
}

.tw-ring-offset-cyan-500\/30 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.3) !important;
}

.tw-ring-offset-cyan-500\/35 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.35) !important;
}

.tw-ring-offset-cyan-500\/40 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.4) !important;
}

.tw-ring-offset-cyan-500\/45 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.45) !important;
}

.tw-ring-offset-cyan-500\/5 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.05) !important;
}

.tw-ring-offset-cyan-500\/50 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.5) !important;
}

.tw-ring-offset-cyan-500\/55 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.55) !important;
}

.tw-ring-offset-cyan-500\/60 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.6) !important;
}

.tw-ring-offset-cyan-500\/65 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.65) !important;
}

.tw-ring-offset-cyan-500\/70 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.7) !important;
}

.tw-ring-offset-cyan-500\/75 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.75) !important;
}

.tw-ring-offset-cyan-500\/80 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.8) !important;
}

.tw-ring-offset-cyan-500\/85 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.85) !important;
}

.tw-ring-offset-cyan-500\/90 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.9) !important;
}

.tw-ring-offset-cyan-500\/95 {
  --tw-ring-offset-color: rgb(6 182 212 / 0.95) !important;
}

.tw-ring-offset-cyan-600 {
  --tw-ring-offset-color: #0891b2 !important;
}

.tw-ring-offset-cyan-600\/0 {
  --tw-ring-offset-color: rgb(8 145 178 / 0) !important;
}

.tw-ring-offset-cyan-600\/10 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.1) !important;
}

.tw-ring-offset-cyan-600\/100 {
  --tw-ring-offset-color: rgb(8 145 178 / 1) !important;
}

.tw-ring-offset-cyan-600\/15 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.15) !important;
}

.tw-ring-offset-cyan-600\/20 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.2) !important;
}

.tw-ring-offset-cyan-600\/25 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.25) !important;
}

.tw-ring-offset-cyan-600\/30 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.3) !important;
}

.tw-ring-offset-cyan-600\/35 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.35) !important;
}

.tw-ring-offset-cyan-600\/40 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.4) !important;
}

.tw-ring-offset-cyan-600\/45 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.45) !important;
}

.tw-ring-offset-cyan-600\/5 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.05) !important;
}

.tw-ring-offset-cyan-600\/50 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.5) !important;
}

.tw-ring-offset-cyan-600\/55 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.55) !important;
}

.tw-ring-offset-cyan-600\/60 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.6) !important;
}

.tw-ring-offset-cyan-600\/65 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.65) !important;
}

.tw-ring-offset-cyan-600\/70 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.7) !important;
}

.tw-ring-offset-cyan-600\/75 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.75) !important;
}

.tw-ring-offset-cyan-600\/80 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.8) !important;
}

.tw-ring-offset-cyan-600\/85 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.85) !important;
}

.tw-ring-offset-cyan-600\/90 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.9) !important;
}

.tw-ring-offset-cyan-600\/95 {
  --tw-ring-offset-color: rgb(8 145 178 / 0.95) !important;
}

.tw-ring-offset-cyan-700 {
  --tw-ring-offset-color: #0e7490 !important;
}

.tw-ring-offset-cyan-700\/0 {
  --tw-ring-offset-color: rgb(14 116 144 / 0) !important;
}

.tw-ring-offset-cyan-700\/10 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.1) !important;
}

.tw-ring-offset-cyan-700\/100 {
  --tw-ring-offset-color: rgb(14 116 144 / 1) !important;
}

.tw-ring-offset-cyan-700\/15 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.15) !important;
}

.tw-ring-offset-cyan-700\/20 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.2) !important;
}

.tw-ring-offset-cyan-700\/25 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.25) !important;
}

.tw-ring-offset-cyan-700\/30 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.3) !important;
}

.tw-ring-offset-cyan-700\/35 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.35) !important;
}

.tw-ring-offset-cyan-700\/40 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.4) !important;
}

.tw-ring-offset-cyan-700\/45 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.45) !important;
}

.tw-ring-offset-cyan-700\/5 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.05) !important;
}

.tw-ring-offset-cyan-700\/50 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.5) !important;
}

.tw-ring-offset-cyan-700\/55 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.55) !important;
}

.tw-ring-offset-cyan-700\/60 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.6) !important;
}

.tw-ring-offset-cyan-700\/65 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.65) !important;
}

.tw-ring-offset-cyan-700\/70 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.7) !important;
}

.tw-ring-offset-cyan-700\/75 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.75) !important;
}

.tw-ring-offset-cyan-700\/80 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.8) !important;
}

.tw-ring-offset-cyan-700\/85 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.85) !important;
}

.tw-ring-offset-cyan-700\/90 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.9) !important;
}

.tw-ring-offset-cyan-700\/95 {
  --tw-ring-offset-color: rgb(14 116 144 / 0.95) !important;
}

.tw-ring-offset-cyan-800 {
  --tw-ring-offset-color: #155e75 !important;
}

.tw-ring-offset-cyan-800\/0 {
  --tw-ring-offset-color: rgb(21 94 117 / 0) !important;
}

.tw-ring-offset-cyan-800\/10 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.1) !important;
}

.tw-ring-offset-cyan-800\/100 {
  --tw-ring-offset-color: rgb(21 94 117 / 1) !important;
}

.tw-ring-offset-cyan-800\/15 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.15) !important;
}

.tw-ring-offset-cyan-800\/20 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.2) !important;
}

.tw-ring-offset-cyan-800\/25 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.25) !important;
}

.tw-ring-offset-cyan-800\/30 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.3) !important;
}

.tw-ring-offset-cyan-800\/35 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.35) !important;
}

.tw-ring-offset-cyan-800\/40 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.4) !important;
}

.tw-ring-offset-cyan-800\/45 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.45) !important;
}

.tw-ring-offset-cyan-800\/5 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.05) !important;
}

.tw-ring-offset-cyan-800\/50 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.5) !important;
}

.tw-ring-offset-cyan-800\/55 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.55) !important;
}

.tw-ring-offset-cyan-800\/60 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.6) !important;
}

.tw-ring-offset-cyan-800\/65 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.65) !important;
}

.tw-ring-offset-cyan-800\/70 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.7) !important;
}

.tw-ring-offset-cyan-800\/75 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.75) !important;
}

.tw-ring-offset-cyan-800\/80 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.8) !important;
}

.tw-ring-offset-cyan-800\/85 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.85) !important;
}

.tw-ring-offset-cyan-800\/90 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.9) !important;
}

.tw-ring-offset-cyan-800\/95 {
  --tw-ring-offset-color: rgb(21 94 117 / 0.95) !important;
}

.tw-ring-offset-cyan-900 {
  --tw-ring-offset-color: #164e63 !important;
}

.tw-ring-offset-cyan-900\/0 {
  --tw-ring-offset-color: rgb(22 78 99 / 0) !important;
}

.tw-ring-offset-cyan-900\/10 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.1) !important;
}

.tw-ring-offset-cyan-900\/100 {
  --tw-ring-offset-color: rgb(22 78 99 / 1) !important;
}

.tw-ring-offset-cyan-900\/15 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.15) !important;
}

.tw-ring-offset-cyan-900\/20 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.2) !important;
}

.tw-ring-offset-cyan-900\/25 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.25) !important;
}

.tw-ring-offset-cyan-900\/30 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.3) !important;
}

.tw-ring-offset-cyan-900\/35 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.35) !important;
}

.tw-ring-offset-cyan-900\/40 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.4) !important;
}

.tw-ring-offset-cyan-900\/45 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.45) !important;
}

.tw-ring-offset-cyan-900\/5 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.05) !important;
}

.tw-ring-offset-cyan-900\/50 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.5) !important;
}

.tw-ring-offset-cyan-900\/55 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.55) !important;
}

.tw-ring-offset-cyan-900\/60 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.6) !important;
}

.tw-ring-offset-cyan-900\/65 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.65) !important;
}

.tw-ring-offset-cyan-900\/70 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.7) !important;
}

.tw-ring-offset-cyan-900\/75 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.75) !important;
}

.tw-ring-offset-cyan-900\/80 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.8) !important;
}

.tw-ring-offset-cyan-900\/85 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.85) !important;
}

.tw-ring-offset-cyan-900\/90 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.9) !important;
}

.tw-ring-offset-cyan-900\/95 {
  --tw-ring-offset-color: rgb(22 78 99 / 0.95) !important;
}

.tw-ring-offset-cyan-950 {
  --tw-ring-offset-color: #083344 !important;
}

.tw-ring-offset-cyan-950\/0 {
  --tw-ring-offset-color: rgb(8 51 68 / 0) !important;
}

.tw-ring-offset-cyan-950\/10 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.1) !important;
}

.tw-ring-offset-cyan-950\/100 {
  --tw-ring-offset-color: rgb(8 51 68 / 1) !important;
}

.tw-ring-offset-cyan-950\/15 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.15) !important;
}

.tw-ring-offset-cyan-950\/20 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.2) !important;
}

.tw-ring-offset-cyan-950\/25 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.25) !important;
}

.tw-ring-offset-cyan-950\/30 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.3) !important;
}

.tw-ring-offset-cyan-950\/35 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.35) !important;
}

.tw-ring-offset-cyan-950\/40 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.4) !important;
}

.tw-ring-offset-cyan-950\/45 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.45) !important;
}

.tw-ring-offset-cyan-950\/5 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.05) !important;
}

.tw-ring-offset-cyan-950\/50 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.5) !important;
}

.tw-ring-offset-cyan-950\/55 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.55) !important;
}

.tw-ring-offset-cyan-950\/60 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.6) !important;
}

.tw-ring-offset-cyan-950\/65 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.65) !important;
}

.tw-ring-offset-cyan-950\/70 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.7) !important;
}

.tw-ring-offset-cyan-950\/75 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.75) !important;
}

.tw-ring-offset-cyan-950\/80 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.8) !important;
}

.tw-ring-offset-cyan-950\/85 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.85) !important;
}

.tw-ring-offset-cyan-950\/90 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.9) !important;
}

.tw-ring-offset-cyan-950\/95 {
  --tw-ring-offset-color: rgb(8 51 68 / 0.95) !important;
}

.tw-ring-offset-emerald-100 {
  --tw-ring-offset-color: #d1fae5 !important;
}

.tw-ring-offset-emerald-100\/0 {
  --tw-ring-offset-color: rgb(209 250 229 / 0) !important;
}

.tw-ring-offset-emerald-100\/10 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.1) !important;
}

.tw-ring-offset-emerald-100\/100 {
  --tw-ring-offset-color: rgb(209 250 229 / 1) !important;
}

.tw-ring-offset-emerald-100\/15 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.15) !important;
}

.tw-ring-offset-emerald-100\/20 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.2) !important;
}

.tw-ring-offset-emerald-100\/25 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.25) !important;
}

.tw-ring-offset-emerald-100\/30 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.3) !important;
}

.tw-ring-offset-emerald-100\/35 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.35) !important;
}

.tw-ring-offset-emerald-100\/40 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.4) !important;
}

.tw-ring-offset-emerald-100\/45 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.45) !important;
}

.tw-ring-offset-emerald-100\/5 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.05) !important;
}

.tw-ring-offset-emerald-100\/50 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.5) !important;
}

.tw-ring-offset-emerald-100\/55 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.55) !important;
}

.tw-ring-offset-emerald-100\/60 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.6) !important;
}

.tw-ring-offset-emerald-100\/65 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.65) !important;
}

.tw-ring-offset-emerald-100\/70 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.7) !important;
}

.tw-ring-offset-emerald-100\/75 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.75) !important;
}

.tw-ring-offset-emerald-100\/80 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.8) !important;
}

.tw-ring-offset-emerald-100\/85 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.85) !important;
}

.tw-ring-offset-emerald-100\/90 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.9) !important;
}

.tw-ring-offset-emerald-100\/95 {
  --tw-ring-offset-color: rgb(209 250 229 / 0.95) !important;
}

.tw-ring-offset-emerald-200 {
  --tw-ring-offset-color: #a7f3d0 !important;
}

.tw-ring-offset-emerald-200\/0 {
  --tw-ring-offset-color: rgb(167 243 208 / 0) !important;
}

.tw-ring-offset-emerald-200\/10 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.1) !important;
}

.tw-ring-offset-emerald-200\/100 {
  --tw-ring-offset-color: rgb(167 243 208 / 1) !important;
}

.tw-ring-offset-emerald-200\/15 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.15) !important;
}

.tw-ring-offset-emerald-200\/20 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.2) !important;
}

.tw-ring-offset-emerald-200\/25 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.25) !important;
}

.tw-ring-offset-emerald-200\/30 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.3) !important;
}

.tw-ring-offset-emerald-200\/35 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.35) !important;
}

.tw-ring-offset-emerald-200\/40 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.4) !important;
}

.tw-ring-offset-emerald-200\/45 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.45) !important;
}

.tw-ring-offset-emerald-200\/5 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.05) !important;
}

.tw-ring-offset-emerald-200\/50 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.5) !important;
}

.tw-ring-offset-emerald-200\/55 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.55) !important;
}

.tw-ring-offset-emerald-200\/60 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.6) !important;
}

.tw-ring-offset-emerald-200\/65 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.65) !important;
}

.tw-ring-offset-emerald-200\/70 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.7) !important;
}

.tw-ring-offset-emerald-200\/75 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.75) !important;
}

.tw-ring-offset-emerald-200\/80 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.8) !important;
}

.tw-ring-offset-emerald-200\/85 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.85) !important;
}

.tw-ring-offset-emerald-200\/90 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.9) !important;
}

.tw-ring-offset-emerald-200\/95 {
  --tw-ring-offset-color: rgb(167 243 208 / 0.95) !important;
}

.tw-ring-offset-emerald-300 {
  --tw-ring-offset-color: #6ee7b7 !important;
}

.tw-ring-offset-emerald-300\/0 {
  --tw-ring-offset-color: rgb(110 231 183 / 0) !important;
}

.tw-ring-offset-emerald-300\/10 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.1) !important;
}

.tw-ring-offset-emerald-300\/100 {
  --tw-ring-offset-color: rgb(110 231 183 / 1) !important;
}

.tw-ring-offset-emerald-300\/15 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.15) !important;
}

.tw-ring-offset-emerald-300\/20 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.2) !important;
}

.tw-ring-offset-emerald-300\/25 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.25) !important;
}

.tw-ring-offset-emerald-300\/30 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.3) !important;
}

.tw-ring-offset-emerald-300\/35 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.35) !important;
}

.tw-ring-offset-emerald-300\/40 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.4) !important;
}

.tw-ring-offset-emerald-300\/45 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.45) !important;
}

.tw-ring-offset-emerald-300\/5 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.05) !important;
}

.tw-ring-offset-emerald-300\/50 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.5) !important;
}

.tw-ring-offset-emerald-300\/55 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.55) !important;
}

.tw-ring-offset-emerald-300\/60 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.6) !important;
}

.tw-ring-offset-emerald-300\/65 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.65) !important;
}

.tw-ring-offset-emerald-300\/70 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.7) !important;
}

.tw-ring-offset-emerald-300\/75 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.75) !important;
}

.tw-ring-offset-emerald-300\/80 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.8) !important;
}

.tw-ring-offset-emerald-300\/85 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.85) !important;
}

.tw-ring-offset-emerald-300\/90 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.9) !important;
}

.tw-ring-offset-emerald-300\/95 {
  --tw-ring-offset-color: rgb(110 231 183 / 0.95) !important;
}

.tw-ring-offset-emerald-400 {
  --tw-ring-offset-color: #34d399 !important;
}

.tw-ring-offset-emerald-400\/0 {
  --tw-ring-offset-color: rgb(52 211 153 / 0) !important;
}

.tw-ring-offset-emerald-400\/10 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.1) !important;
}

.tw-ring-offset-emerald-400\/100 {
  --tw-ring-offset-color: rgb(52 211 153 / 1) !important;
}

.tw-ring-offset-emerald-400\/15 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.15) !important;
}

.tw-ring-offset-emerald-400\/20 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.2) !important;
}

.tw-ring-offset-emerald-400\/25 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.25) !important;
}

.tw-ring-offset-emerald-400\/30 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.3) !important;
}

.tw-ring-offset-emerald-400\/35 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.35) !important;
}

.tw-ring-offset-emerald-400\/40 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.4) !important;
}

.tw-ring-offset-emerald-400\/45 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.45) !important;
}

.tw-ring-offset-emerald-400\/5 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.05) !important;
}

.tw-ring-offset-emerald-400\/50 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.5) !important;
}

.tw-ring-offset-emerald-400\/55 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.55) !important;
}

.tw-ring-offset-emerald-400\/60 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.6) !important;
}

.tw-ring-offset-emerald-400\/65 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.65) !important;
}

.tw-ring-offset-emerald-400\/70 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.7) !important;
}

.tw-ring-offset-emerald-400\/75 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.75) !important;
}

.tw-ring-offset-emerald-400\/80 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.8) !important;
}

.tw-ring-offset-emerald-400\/85 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.85) !important;
}

.tw-ring-offset-emerald-400\/90 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.9) !important;
}

.tw-ring-offset-emerald-400\/95 {
  --tw-ring-offset-color: rgb(52 211 153 / 0.95) !important;
}

.tw-ring-offset-emerald-50 {
  --tw-ring-offset-color: #ecfdf5 !important;
}

.tw-ring-offset-emerald-50\/0 {
  --tw-ring-offset-color: rgb(236 253 245 / 0) !important;
}

.tw-ring-offset-emerald-50\/10 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.1) !important;
}

.tw-ring-offset-emerald-50\/100 {
  --tw-ring-offset-color: rgb(236 253 245 / 1) !important;
}

.tw-ring-offset-emerald-50\/15 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.15) !important;
}

.tw-ring-offset-emerald-50\/20 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.2) !important;
}

.tw-ring-offset-emerald-50\/25 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.25) !important;
}

.tw-ring-offset-emerald-50\/30 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.3) !important;
}

.tw-ring-offset-emerald-50\/35 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.35) !important;
}

.tw-ring-offset-emerald-50\/40 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.4) !important;
}

.tw-ring-offset-emerald-50\/45 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.45) !important;
}

.tw-ring-offset-emerald-50\/5 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.05) !important;
}

.tw-ring-offset-emerald-50\/50 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.5) !important;
}

.tw-ring-offset-emerald-50\/55 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.55) !important;
}

.tw-ring-offset-emerald-50\/60 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.6) !important;
}

.tw-ring-offset-emerald-50\/65 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.65) !important;
}

.tw-ring-offset-emerald-50\/70 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.7) !important;
}

.tw-ring-offset-emerald-50\/75 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.75) !important;
}

.tw-ring-offset-emerald-50\/80 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.8) !important;
}

.tw-ring-offset-emerald-50\/85 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.85) !important;
}

.tw-ring-offset-emerald-50\/90 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.9) !important;
}

.tw-ring-offset-emerald-50\/95 {
  --tw-ring-offset-color: rgb(236 253 245 / 0.95) !important;
}

.tw-ring-offset-emerald-500 {
  --tw-ring-offset-color: #10b981 !important;
}

.tw-ring-offset-emerald-500\/0 {
  --tw-ring-offset-color: rgb(16 185 129 / 0) !important;
}

.tw-ring-offset-emerald-500\/10 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.1) !important;
}

.tw-ring-offset-emerald-500\/100 {
  --tw-ring-offset-color: rgb(16 185 129 / 1) !important;
}

.tw-ring-offset-emerald-500\/15 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.15) !important;
}

.tw-ring-offset-emerald-500\/20 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.2) !important;
}

.tw-ring-offset-emerald-500\/25 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.25) !important;
}

.tw-ring-offset-emerald-500\/30 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.3) !important;
}

.tw-ring-offset-emerald-500\/35 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.35) !important;
}

.tw-ring-offset-emerald-500\/40 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.4) !important;
}

.tw-ring-offset-emerald-500\/45 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.45) !important;
}

.tw-ring-offset-emerald-500\/5 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.05) !important;
}

.tw-ring-offset-emerald-500\/50 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.5) !important;
}

.tw-ring-offset-emerald-500\/55 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.55) !important;
}

.tw-ring-offset-emerald-500\/60 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.6) !important;
}

.tw-ring-offset-emerald-500\/65 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.65) !important;
}

.tw-ring-offset-emerald-500\/70 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.7) !important;
}

.tw-ring-offset-emerald-500\/75 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.75) !important;
}

.tw-ring-offset-emerald-500\/80 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.8) !important;
}

.tw-ring-offset-emerald-500\/85 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.85) !important;
}

.tw-ring-offset-emerald-500\/90 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.9) !important;
}

.tw-ring-offset-emerald-500\/95 {
  --tw-ring-offset-color: rgb(16 185 129 / 0.95) !important;
}

.tw-ring-offset-emerald-600 {
  --tw-ring-offset-color: #059669 !important;
}

.tw-ring-offset-emerald-600\/0 {
  --tw-ring-offset-color: rgb(5 150 105 / 0) !important;
}

.tw-ring-offset-emerald-600\/10 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.1) !important;
}

.tw-ring-offset-emerald-600\/100 {
  --tw-ring-offset-color: rgb(5 150 105 / 1) !important;
}

.tw-ring-offset-emerald-600\/15 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.15) !important;
}

.tw-ring-offset-emerald-600\/20 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.2) !important;
}

.tw-ring-offset-emerald-600\/25 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.25) !important;
}

.tw-ring-offset-emerald-600\/30 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.3) !important;
}

.tw-ring-offset-emerald-600\/35 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.35) !important;
}

.tw-ring-offset-emerald-600\/40 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.4) !important;
}

.tw-ring-offset-emerald-600\/45 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.45) !important;
}

.tw-ring-offset-emerald-600\/5 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.05) !important;
}

.tw-ring-offset-emerald-600\/50 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.5) !important;
}

.tw-ring-offset-emerald-600\/55 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.55) !important;
}

.tw-ring-offset-emerald-600\/60 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.6) !important;
}

.tw-ring-offset-emerald-600\/65 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.65) !important;
}

.tw-ring-offset-emerald-600\/70 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.7) !important;
}

.tw-ring-offset-emerald-600\/75 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.75) !important;
}

.tw-ring-offset-emerald-600\/80 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.8) !important;
}

.tw-ring-offset-emerald-600\/85 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.85) !important;
}

.tw-ring-offset-emerald-600\/90 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.9) !important;
}

.tw-ring-offset-emerald-600\/95 {
  --tw-ring-offset-color: rgb(5 150 105 / 0.95) !important;
}

.tw-ring-offset-emerald-700 {
  --tw-ring-offset-color: #047857 !important;
}

.tw-ring-offset-emerald-700\/0 {
  --tw-ring-offset-color: rgb(4 120 87 / 0) !important;
}

.tw-ring-offset-emerald-700\/10 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.1) !important;
}

.tw-ring-offset-emerald-700\/100 {
  --tw-ring-offset-color: rgb(4 120 87 / 1) !important;
}

.tw-ring-offset-emerald-700\/15 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.15) !important;
}

.tw-ring-offset-emerald-700\/20 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.2) !important;
}

.tw-ring-offset-emerald-700\/25 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.25) !important;
}

.tw-ring-offset-emerald-700\/30 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.3) !important;
}

.tw-ring-offset-emerald-700\/35 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.35) !important;
}

.tw-ring-offset-emerald-700\/40 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.4) !important;
}

.tw-ring-offset-emerald-700\/45 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.45) !important;
}

.tw-ring-offset-emerald-700\/5 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.05) !important;
}

.tw-ring-offset-emerald-700\/50 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.5) !important;
}

.tw-ring-offset-emerald-700\/55 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.55) !important;
}

.tw-ring-offset-emerald-700\/60 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.6) !important;
}

.tw-ring-offset-emerald-700\/65 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.65) !important;
}

.tw-ring-offset-emerald-700\/70 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.7) !important;
}

.tw-ring-offset-emerald-700\/75 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.75) !important;
}

.tw-ring-offset-emerald-700\/80 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.8) !important;
}

.tw-ring-offset-emerald-700\/85 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.85) !important;
}

.tw-ring-offset-emerald-700\/90 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.9) !important;
}

.tw-ring-offset-emerald-700\/95 {
  --tw-ring-offset-color: rgb(4 120 87 / 0.95) !important;
}

.tw-ring-offset-emerald-800 {
  --tw-ring-offset-color: #065f46 !important;
}

.tw-ring-offset-emerald-800\/0 {
  --tw-ring-offset-color: rgb(6 95 70 / 0) !important;
}

.tw-ring-offset-emerald-800\/10 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.1) !important;
}

.tw-ring-offset-emerald-800\/100 {
  --tw-ring-offset-color: rgb(6 95 70 / 1) !important;
}

.tw-ring-offset-emerald-800\/15 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.15) !important;
}

.tw-ring-offset-emerald-800\/20 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.2) !important;
}

.tw-ring-offset-emerald-800\/25 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.25) !important;
}

.tw-ring-offset-emerald-800\/30 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.3) !important;
}

.tw-ring-offset-emerald-800\/35 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.35) !important;
}

.tw-ring-offset-emerald-800\/40 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.4) !important;
}

.tw-ring-offset-emerald-800\/45 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.45) !important;
}

.tw-ring-offset-emerald-800\/5 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.05) !important;
}

.tw-ring-offset-emerald-800\/50 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.5) !important;
}

.tw-ring-offset-emerald-800\/55 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.55) !important;
}

.tw-ring-offset-emerald-800\/60 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.6) !important;
}

.tw-ring-offset-emerald-800\/65 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.65) !important;
}

.tw-ring-offset-emerald-800\/70 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.7) !important;
}

.tw-ring-offset-emerald-800\/75 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.75) !important;
}

.tw-ring-offset-emerald-800\/80 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.8) !important;
}

.tw-ring-offset-emerald-800\/85 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.85) !important;
}

.tw-ring-offset-emerald-800\/90 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.9) !important;
}

.tw-ring-offset-emerald-800\/95 {
  --tw-ring-offset-color: rgb(6 95 70 / 0.95) !important;
}

.tw-ring-offset-emerald-900 {
  --tw-ring-offset-color: #064e3b !important;
}

.tw-ring-offset-emerald-900\/0 {
  --tw-ring-offset-color: rgb(6 78 59 / 0) !important;
}

.tw-ring-offset-emerald-900\/10 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.1) !important;
}

.tw-ring-offset-emerald-900\/100 {
  --tw-ring-offset-color: rgb(6 78 59 / 1) !important;
}

.tw-ring-offset-emerald-900\/15 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.15) !important;
}

.tw-ring-offset-emerald-900\/20 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.2) !important;
}

.tw-ring-offset-emerald-900\/25 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.25) !important;
}

.tw-ring-offset-emerald-900\/30 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.3) !important;
}

.tw-ring-offset-emerald-900\/35 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.35) !important;
}

.tw-ring-offset-emerald-900\/40 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.4) !important;
}

.tw-ring-offset-emerald-900\/45 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.45) !important;
}

.tw-ring-offset-emerald-900\/5 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.05) !important;
}

.tw-ring-offset-emerald-900\/50 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.5) !important;
}

.tw-ring-offset-emerald-900\/55 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.55) !important;
}

.tw-ring-offset-emerald-900\/60 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.6) !important;
}

.tw-ring-offset-emerald-900\/65 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.65) !important;
}

.tw-ring-offset-emerald-900\/70 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.7) !important;
}

.tw-ring-offset-emerald-900\/75 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.75) !important;
}

.tw-ring-offset-emerald-900\/80 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.8) !important;
}

.tw-ring-offset-emerald-900\/85 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.85) !important;
}

.tw-ring-offset-emerald-900\/90 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.9) !important;
}

.tw-ring-offset-emerald-900\/95 {
  --tw-ring-offset-color: rgb(6 78 59 / 0.95) !important;
}

.tw-ring-offset-emerald-950 {
  --tw-ring-offset-color: #022c22 !important;
}

.tw-ring-offset-emerald-950\/0 {
  --tw-ring-offset-color: rgb(2 44 34 / 0) !important;
}

.tw-ring-offset-emerald-950\/10 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.1) !important;
}

.tw-ring-offset-emerald-950\/100 {
  --tw-ring-offset-color: rgb(2 44 34 / 1) !important;
}

.tw-ring-offset-emerald-950\/15 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.15) !important;
}

.tw-ring-offset-emerald-950\/20 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.2) !important;
}

.tw-ring-offset-emerald-950\/25 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.25) !important;
}

.tw-ring-offset-emerald-950\/30 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.3) !important;
}

.tw-ring-offset-emerald-950\/35 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.35) !important;
}

.tw-ring-offset-emerald-950\/40 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.4) !important;
}

.tw-ring-offset-emerald-950\/45 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.45) !important;
}

.tw-ring-offset-emerald-950\/5 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.05) !important;
}

.tw-ring-offset-emerald-950\/50 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.5) !important;
}

.tw-ring-offset-emerald-950\/55 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.55) !important;
}

.tw-ring-offset-emerald-950\/60 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.6) !important;
}

.tw-ring-offset-emerald-950\/65 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.65) !important;
}

.tw-ring-offset-emerald-950\/70 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.7) !important;
}

.tw-ring-offset-emerald-950\/75 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.75) !important;
}

.tw-ring-offset-emerald-950\/80 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.8) !important;
}

.tw-ring-offset-emerald-950\/85 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.85) !important;
}

.tw-ring-offset-emerald-950\/90 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.9) !important;
}

.tw-ring-offset-emerald-950\/95 {
  --tw-ring-offset-color: rgb(2 44 34 / 0.95) !important;
}

.tw-ring-offset-fuchsia-100 {
  --tw-ring-offset-color: #fae8ff !important;
}

.tw-ring-offset-fuchsia-100\/0 {
  --tw-ring-offset-color: rgb(250 232 255 / 0) !important;
}

.tw-ring-offset-fuchsia-100\/10 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.1) !important;
}

.tw-ring-offset-fuchsia-100\/100 {
  --tw-ring-offset-color: rgb(250 232 255 / 1) !important;
}

.tw-ring-offset-fuchsia-100\/15 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.15) !important;
}

.tw-ring-offset-fuchsia-100\/20 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.2) !important;
}

.tw-ring-offset-fuchsia-100\/25 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.25) !important;
}

.tw-ring-offset-fuchsia-100\/30 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.3) !important;
}

.tw-ring-offset-fuchsia-100\/35 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.35) !important;
}

.tw-ring-offset-fuchsia-100\/40 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.4) !important;
}

.tw-ring-offset-fuchsia-100\/45 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.45) !important;
}

.tw-ring-offset-fuchsia-100\/5 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.05) !important;
}

.tw-ring-offset-fuchsia-100\/50 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.5) !important;
}

.tw-ring-offset-fuchsia-100\/55 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.55) !important;
}

.tw-ring-offset-fuchsia-100\/60 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.6) !important;
}

.tw-ring-offset-fuchsia-100\/65 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.65) !important;
}

.tw-ring-offset-fuchsia-100\/70 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.7) !important;
}

.tw-ring-offset-fuchsia-100\/75 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.75) !important;
}

.tw-ring-offset-fuchsia-100\/80 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.8) !important;
}

.tw-ring-offset-fuchsia-100\/85 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.85) !important;
}

.tw-ring-offset-fuchsia-100\/90 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.9) !important;
}

.tw-ring-offset-fuchsia-100\/95 {
  --tw-ring-offset-color: rgb(250 232 255 / 0.95) !important;
}

.tw-ring-offset-fuchsia-200 {
  --tw-ring-offset-color: #f5d0fe !important;
}

.tw-ring-offset-fuchsia-200\/0 {
  --tw-ring-offset-color: rgb(245 208 254 / 0) !important;
}

.tw-ring-offset-fuchsia-200\/10 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.1) !important;
}

.tw-ring-offset-fuchsia-200\/100 {
  --tw-ring-offset-color: rgb(245 208 254 / 1) !important;
}

.tw-ring-offset-fuchsia-200\/15 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.15) !important;
}

.tw-ring-offset-fuchsia-200\/20 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.2) !important;
}

.tw-ring-offset-fuchsia-200\/25 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.25) !important;
}

.tw-ring-offset-fuchsia-200\/30 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.3) !important;
}

.tw-ring-offset-fuchsia-200\/35 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.35) !important;
}

.tw-ring-offset-fuchsia-200\/40 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.4) !important;
}

.tw-ring-offset-fuchsia-200\/45 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.45) !important;
}

.tw-ring-offset-fuchsia-200\/5 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.05) !important;
}

.tw-ring-offset-fuchsia-200\/50 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.5) !important;
}

.tw-ring-offset-fuchsia-200\/55 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.55) !important;
}

.tw-ring-offset-fuchsia-200\/60 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.6) !important;
}

.tw-ring-offset-fuchsia-200\/65 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.65) !important;
}

.tw-ring-offset-fuchsia-200\/70 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.7) !important;
}

.tw-ring-offset-fuchsia-200\/75 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.75) !important;
}

.tw-ring-offset-fuchsia-200\/80 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.8) !important;
}

.tw-ring-offset-fuchsia-200\/85 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.85) !important;
}

.tw-ring-offset-fuchsia-200\/90 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.9) !important;
}

.tw-ring-offset-fuchsia-200\/95 {
  --tw-ring-offset-color: rgb(245 208 254 / 0.95) !important;
}

.tw-ring-offset-fuchsia-300 {
  --tw-ring-offset-color: #f0abfc !important;
}

.tw-ring-offset-fuchsia-300\/0 {
  --tw-ring-offset-color: rgb(240 171 252 / 0) !important;
}

.tw-ring-offset-fuchsia-300\/10 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.1) !important;
}

.tw-ring-offset-fuchsia-300\/100 {
  --tw-ring-offset-color: rgb(240 171 252 / 1) !important;
}

.tw-ring-offset-fuchsia-300\/15 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.15) !important;
}

.tw-ring-offset-fuchsia-300\/20 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.2) !important;
}

.tw-ring-offset-fuchsia-300\/25 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.25) !important;
}

.tw-ring-offset-fuchsia-300\/30 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.3) !important;
}

.tw-ring-offset-fuchsia-300\/35 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.35) !important;
}

.tw-ring-offset-fuchsia-300\/40 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.4) !important;
}

.tw-ring-offset-fuchsia-300\/45 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.45) !important;
}

.tw-ring-offset-fuchsia-300\/5 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.05) !important;
}

.tw-ring-offset-fuchsia-300\/50 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.5) !important;
}

.tw-ring-offset-fuchsia-300\/55 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.55) !important;
}

.tw-ring-offset-fuchsia-300\/60 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.6) !important;
}

.tw-ring-offset-fuchsia-300\/65 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.65) !important;
}

.tw-ring-offset-fuchsia-300\/70 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.7) !important;
}

.tw-ring-offset-fuchsia-300\/75 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.75) !important;
}

.tw-ring-offset-fuchsia-300\/80 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.8) !important;
}

.tw-ring-offset-fuchsia-300\/85 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.85) !important;
}

.tw-ring-offset-fuchsia-300\/90 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.9) !important;
}

.tw-ring-offset-fuchsia-300\/95 {
  --tw-ring-offset-color: rgb(240 171 252 / 0.95) !important;
}

.tw-ring-offset-fuchsia-400 {
  --tw-ring-offset-color: #e879f9 !important;
}

.tw-ring-offset-fuchsia-400\/0 {
  --tw-ring-offset-color: rgb(232 121 249 / 0) !important;
}

.tw-ring-offset-fuchsia-400\/10 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.1) !important;
}

.tw-ring-offset-fuchsia-400\/100 {
  --tw-ring-offset-color: rgb(232 121 249 / 1) !important;
}

.tw-ring-offset-fuchsia-400\/15 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.15) !important;
}

.tw-ring-offset-fuchsia-400\/20 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.2) !important;
}

.tw-ring-offset-fuchsia-400\/25 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.25) !important;
}

.tw-ring-offset-fuchsia-400\/30 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.3) !important;
}

.tw-ring-offset-fuchsia-400\/35 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.35) !important;
}

.tw-ring-offset-fuchsia-400\/40 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.4) !important;
}

.tw-ring-offset-fuchsia-400\/45 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.45) !important;
}

.tw-ring-offset-fuchsia-400\/5 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.05) !important;
}

.tw-ring-offset-fuchsia-400\/50 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.5) !important;
}

.tw-ring-offset-fuchsia-400\/55 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.55) !important;
}

.tw-ring-offset-fuchsia-400\/60 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.6) !important;
}

.tw-ring-offset-fuchsia-400\/65 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.65) !important;
}

.tw-ring-offset-fuchsia-400\/70 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.7) !important;
}

.tw-ring-offset-fuchsia-400\/75 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.75) !important;
}

.tw-ring-offset-fuchsia-400\/80 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.8) !important;
}

.tw-ring-offset-fuchsia-400\/85 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.85) !important;
}

.tw-ring-offset-fuchsia-400\/90 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.9) !important;
}

.tw-ring-offset-fuchsia-400\/95 {
  --tw-ring-offset-color: rgb(232 121 249 / 0.95) !important;
}

.tw-ring-offset-fuchsia-50 {
  --tw-ring-offset-color: #fdf4ff !important;
}

.tw-ring-offset-fuchsia-50\/0 {
  --tw-ring-offset-color: rgb(253 244 255 / 0) !important;
}

.tw-ring-offset-fuchsia-50\/10 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.1) !important;
}

.tw-ring-offset-fuchsia-50\/100 {
  --tw-ring-offset-color: rgb(253 244 255 / 1) !important;
}

.tw-ring-offset-fuchsia-50\/15 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.15) !important;
}

.tw-ring-offset-fuchsia-50\/20 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.2) !important;
}

.tw-ring-offset-fuchsia-50\/25 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.25) !important;
}

.tw-ring-offset-fuchsia-50\/30 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.3) !important;
}

.tw-ring-offset-fuchsia-50\/35 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.35) !important;
}

.tw-ring-offset-fuchsia-50\/40 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.4) !important;
}

.tw-ring-offset-fuchsia-50\/45 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.45) !important;
}

.tw-ring-offset-fuchsia-50\/5 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.05) !important;
}

.tw-ring-offset-fuchsia-50\/50 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.5) !important;
}

.tw-ring-offset-fuchsia-50\/55 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.55) !important;
}

.tw-ring-offset-fuchsia-50\/60 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.6) !important;
}

.tw-ring-offset-fuchsia-50\/65 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.65) !important;
}

.tw-ring-offset-fuchsia-50\/70 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.7) !important;
}

.tw-ring-offset-fuchsia-50\/75 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.75) !important;
}

.tw-ring-offset-fuchsia-50\/80 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.8) !important;
}

.tw-ring-offset-fuchsia-50\/85 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.85) !important;
}

.tw-ring-offset-fuchsia-50\/90 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.9) !important;
}

.tw-ring-offset-fuchsia-50\/95 {
  --tw-ring-offset-color: rgb(253 244 255 / 0.95) !important;
}

.tw-ring-offset-fuchsia-500 {
  --tw-ring-offset-color: #d946ef !important;
}

.tw-ring-offset-fuchsia-500\/0 {
  --tw-ring-offset-color: rgb(217 70 239 / 0) !important;
}

.tw-ring-offset-fuchsia-500\/10 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.1) !important;
}

.tw-ring-offset-fuchsia-500\/100 {
  --tw-ring-offset-color: rgb(217 70 239 / 1) !important;
}

.tw-ring-offset-fuchsia-500\/15 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.15) !important;
}

.tw-ring-offset-fuchsia-500\/20 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.2) !important;
}

.tw-ring-offset-fuchsia-500\/25 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.25) !important;
}

.tw-ring-offset-fuchsia-500\/30 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.3) !important;
}

.tw-ring-offset-fuchsia-500\/35 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.35) !important;
}

.tw-ring-offset-fuchsia-500\/40 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.4) !important;
}

.tw-ring-offset-fuchsia-500\/45 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.45) !important;
}

.tw-ring-offset-fuchsia-500\/5 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.05) !important;
}

.tw-ring-offset-fuchsia-500\/50 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.5) !important;
}

.tw-ring-offset-fuchsia-500\/55 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.55) !important;
}

.tw-ring-offset-fuchsia-500\/60 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.6) !important;
}

.tw-ring-offset-fuchsia-500\/65 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.65) !important;
}

.tw-ring-offset-fuchsia-500\/70 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.7) !important;
}

.tw-ring-offset-fuchsia-500\/75 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.75) !important;
}

.tw-ring-offset-fuchsia-500\/80 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.8) !important;
}

.tw-ring-offset-fuchsia-500\/85 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.85) !important;
}

.tw-ring-offset-fuchsia-500\/90 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.9) !important;
}

.tw-ring-offset-fuchsia-500\/95 {
  --tw-ring-offset-color: rgb(217 70 239 / 0.95) !important;
}

.tw-ring-offset-fuchsia-600 {
  --tw-ring-offset-color: #c026d3 !important;
}

.tw-ring-offset-fuchsia-600\/0 {
  --tw-ring-offset-color: rgb(192 38 211 / 0) !important;
}

.tw-ring-offset-fuchsia-600\/10 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.1) !important;
}

.tw-ring-offset-fuchsia-600\/100 {
  --tw-ring-offset-color: rgb(192 38 211 / 1) !important;
}

.tw-ring-offset-fuchsia-600\/15 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.15) !important;
}

.tw-ring-offset-fuchsia-600\/20 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.2) !important;
}

.tw-ring-offset-fuchsia-600\/25 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.25) !important;
}

.tw-ring-offset-fuchsia-600\/30 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.3) !important;
}

.tw-ring-offset-fuchsia-600\/35 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.35) !important;
}

.tw-ring-offset-fuchsia-600\/40 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.4) !important;
}

.tw-ring-offset-fuchsia-600\/45 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.45) !important;
}

.tw-ring-offset-fuchsia-600\/5 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.05) !important;
}

.tw-ring-offset-fuchsia-600\/50 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.5) !important;
}

.tw-ring-offset-fuchsia-600\/55 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.55) !important;
}

.tw-ring-offset-fuchsia-600\/60 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.6) !important;
}

.tw-ring-offset-fuchsia-600\/65 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.65) !important;
}

.tw-ring-offset-fuchsia-600\/70 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.7) !important;
}

.tw-ring-offset-fuchsia-600\/75 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.75) !important;
}

.tw-ring-offset-fuchsia-600\/80 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.8) !important;
}

.tw-ring-offset-fuchsia-600\/85 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.85) !important;
}

.tw-ring-offset-fuchsia-600\/90 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.9) !important;
}

.tw-ring-offset-fuchsia-600\/95 {
  --tw-ring-offset-color: rgb(192 38 211 / 0.95) !important;
}

.tw-ring-offset-fuchsia-700 {
  --tw-ring-offset-color: #a21caf !important;
}

.tw-ring-offset-fuchsia-700\/0 {
  --tw-ring-offset-color: rgb(162 28 175 / 0) !important;
}

.tw-ring-offset-fuchsia-700\/10 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.1) !important;
}

.tw-ring-offset-fuchsia-700\/100 {
  --tw-ring-offset-color: rgb(162 28 175 / 1) !important;
}

.tw-ring-offset-fuchsia-700\/15 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.15) !important;
}

.tw-ring-offset-fuchsia-700\/20 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.2) !important;
}

.tw-ring-offset-fuchsia-700\/25 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.25) !important;
}

.tw-ring-offset-fuchsia-700\/30 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.3) !important;
}

.tw-ring-offset-fuchsia-700\/35 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.35) !important;
}

.tw-ring-offset-fuchsia-700\/40 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.4) !important;
}

.tw-ring-offset-fuchsia-700\/45 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.45) !important;
}

.tw-ring-offset-fuchsia-700\/5 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.05) !important;
}

.tw-ring-offset-fuchsia-700\/50 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.5) !important;
}

.tw-ring-offset-fuchsia-700\/55 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.55) !important;
}

.tw-ring-offset-fuchsia-700\/60 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.6) !important;
}

.tw-ring-offset-fuchsia-700\/65 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.65) !important;
}

.tw-ring-offset-fuchsia-700\/70 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.7) !important;
}

.tw-ring-offset-fuchsia-700\/75 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.75) !important;
}

.tw-ring-offset-fuchsia-700\/80 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.8) !important;
}

.tw-ring-offset-fuchsia-700\/85 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.85) !important;
}

.tw-ring-offset-fuchsia-700\/90 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.9) !important;
}

.tw-ring-offset-fuchsia-700\/95 {
  --tw-ring-offset-color: rgb(162 28 175 / 0.95) !important;
}

.tw-ring-offset-fuchsia-800 {
  --tw-ring-offset-color: #86198f !important;
}

.tw-ring-offset-fuchsia-800\/0 {
  --tw-ring-offset-color: rgb(134 25 143 / 0) !important;
}

.tw-ring-offset-fuchsia-800\/10 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.1) !important;
}

.tw-ring-offset-fuchsia-800\/100 {
  --tw-ring-offset-color: rgb(134 25 143 / 1) !important;
}

.tw-ring-offset-fuchsia-800\/15 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.15) !important;
}

.tw-ring-offset-fuchsia-800\/20 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.2) !important;
}

.tw-ring-offset-fuchsia-800\/25 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.25) !important;
}

.tw-ring-offset-fuchsia-800\/30 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.3) !important;
}

.tw-ring-offset-fuchsia-800\/35 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.35) !important;
}

.tw-ring-offset-fuchsia-800\/40 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.4) !important;
}

.tw-ring-offset-fuchsia-800\/45 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.45) !important;
}

.tw-ring-offset-fuchsia-800\/5 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.05) !important;
}

.tw-ring-offset-fuchsia-800\/50 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.5) !important;
}

.tw-ring-offset-fuchsia-800\/55 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.55) !important;
}

.tw-ring-offset-fuchsia-800\/60 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.6) !important;
}

.tw-ring-offset-fuchsia-800\/65 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.65) !important;
}

.tw-ring-offset-fuchsia-800\/70 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.7) !important;
}

.tw-ring-offset-fuchsia-800\/75 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.75) !important;
}

.tw-ring-offset-fuchsia-800\/80 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.8) !important;
}

.tw-ring-offset-fuchsia-800\/85 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.85) !important;
}

.tw-ring-offset-fuchsia-800\/90 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.9) !important;
}

.tw-ring-offset-fuchsia-800\/95 {
  --tw-ring-offset-color: rgb(134 25 143 / 0.95) !important;
}

.tw-ring-offset-fuchsia-900 {
  --tw-ring-offset-color: #701a75 !important;
}

.tw-ring-offset-fuchsia-900\/0 {
  --tw-ring-offset-color: rgb(112 26 117 / 0) !important;
}

.tw-ring-offset-fuchsia-900\/10 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.1) !important;
}

.tw-ring-offset-fuchsia-900\/100 {
  --tw-ring-offset-color: rgb(112 26 117 / 1) !important;
}

.tw-ring-offset-fuchsia-900\/15 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.15) !important;
}

.tw-ring-offset-fuchsia-900\/20 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.2) !important;
}

.tw-ring-offset-fuchsia-900\/25 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.25) !important;
}

.tw-ring-offset-fuchsia-900\/30 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.3) !important;
}

.tw-ring-offset-fuchsia-900\/35 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.35) !important;
}

.tw-ring-offset-fuchsia-900\/40 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.4) !important;
}

.tw-ring-offset-fuchsia-900\/45 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.45) !important;
}

.tw-ring-offset-fuchsia-900\/5 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.05) !important;
}

.tw-ring-offset-fuchsia-900\/50 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.5) !important;
}

.tw-ring-offset-fuchsia-900\/55 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.55) !important;
}

.tw-ring-offset-fuchsia-900\/60 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.6) !important;
}

.tw-ring-offset-fuchsia-900\/65 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.65) !important;
}

.tw-ring-offset-fuchsia-900\/70 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.7) !important;
}

.tw-ring-offset-fuchsia-900\/75 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.75) !important;
}

.tw-ring-offset-fuchsia-900\/80 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.8) !important;
}

.tw-ring-offset-fuchsia-900\/85 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.85) !important;
}

.tw-ring-offset-fuchsia-900\/90 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.9) !important;
}

.tw-ring-offset-fuchsia-900\/95 {
  --tw-ring-offset-color: rgb(112 26 117 / 0.95) !important;
}

.tw-ring-offset-fuchsia-950 {
  --tw-ring-offset-color: #4a044e !important;
}

.tw-ring-offset-fuchsia-950\/0 {
  --tw-ring-offset-color: rgb(74 4 78 / 0) !important;
}

.tw-ring-offset-fuchsia-950\/10 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.1) !important;
}

.tw-ring-offset-fuchsia-950\/100 {
  --tw-ring-offset-color: rgb(74 4 78 / 1) !important;
}

.tw-ring-offset-fuchsia-950\/15 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.15) !important;
}

.tw-ring-offset-fuchsia-950\/20 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.2) !important;
}

.tw-ring-offset-fuchsia-950\/25 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.25) !important;
}

.tw-ring-offset-fuchsia-950\/30 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.3) !important;
}

.tw-ring-offset-fuchsia-950\/35 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.35) !important;
}

.tw-ring-offset-fuchsia-950\/40 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.4) !important;
}

.tw-ring-offset-fuchsia-950\/45 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.45) !important;
}

.tw-ring-offset-fuchsia-950\/5 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.05) !important;
}

.tw-ring-offset-fuchsia-950\/50 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.5) !important;
}

.tw-ring-offset-fuchsia-950\/55 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.55) !important;
}

.tw-ring-offset-fuchsia-950\/60 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.6) !important;
}

.tw-ring-offset-fuchsia-950\/65 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.65) !important;
}

.tw-ring-offset-fuchsia-950\/70 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.7) !important;
}

.tw-ring-offset-fuchsia-950\/75 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.75) !important;
}

.tw-ring-offset-fuchsia-950\/80 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.8) !important;
}

.tw-ring-offset-fuchsia-950\/85 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.85) !important;
}

.tw-ring-offset-fuchsia-950\/90 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.9) !important;
}

.tw-ring-offset-fuchsia-950\/95 {
  --tw-ring-offset-color: rgb(74 4 78 / 0.95) !important;
}

.tw-ring-offset-gray-100 {
  --tw-ring-offset-color: #f3f4f6 !important;
}

.tw-ring-offset-gray-100\/0 {
  --tw-ring-offset-color: rgb(243 244 246 / 0) !important;
}

.tw-ring-offset-gray-100\/10 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.1) !important;
}

.tw-ring-offset-gray-100\/100 {
  --tw-ring-offset-color: rgb(243 244 246 / 1) !important;
}

.tw-ring-offset-gray-100\/15 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.15) !important;
}

.tw-ring-offset-gray-100\/20 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.2) !important;
}

.tw-ring-offset-gray-100\/25 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.25) !important;
}

.tw-ring-offset-gray-100\/30 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.3) !important;
}

.tw-ring-offset-gray-100\/35 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.35) !important;
}

.tw-ring-offset-gray-100\/40 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.4) !important;
}

.tw-ring-offset-gray-100\/45 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.45) !important;
}

.tw-ring-offset-gray-100\/5 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.05) !important;
}

.tw-ring-offset-gray-100\/50 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.5) !important;
}

.tw-ring-offset-gray-100\/55 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.55) !important;
}

.tw-ring-offset-gray-100\/60 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.6) !important;
}

.tw-ring-offset-gray-100\/65 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.65) !important;
}

.tw-ring-offset-gray-100\/70 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.7) !important;
}

.tw-ring-offset-gray-100\/75 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.75) !important;
}

.tw-ring-offset-gray-100\/80 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.8) !important;
}

.tw-ring-offset-gray-100\/85 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.85) !important;
}

.tw-ring-offset-gray-100\/90 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.9) !important;
}

.tw-ring-offset-gray-100\/95 {
  --tw-ring-offset-color: rgb(243 244 246 / 0.95) !important;
}

.tw-ring-offset-gray-200 {
  --tw-ring-offset-color: #e5e7eb !important;
}

.tw-ring-offset-gray-200\/0 {
  --tw-ring-offset-color: rgb(229 231 235 / 0) !important;
}

.tw-ring-offset-gray-200\/10 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.1) !important;
}

.tw-ring-offset-gray-200\/100 {
  --tw-ring-offset-color: rgb(229 231 235 / 1) !important;
}

.tw-ring-offset-gray-200\/15 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.15) !important;
}

.tw-ring-offset-gray-200\/20 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.2) !important;
}

.tw-ring-offset-gray-200\/25 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.25) !important;
}

.tw-ring-offset-gray-200\/30 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.3) !important;
}

.tw-ring-offset-gray-200\/35 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.35) !important;
}

.tw-ring-offset-gray-200\/40 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.4) !important;
}

.tw-ring-offset-gray-200\/45 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.45) !important;
}

.tw-ring-offset-gray-200\/5 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.05) !important;
}

.tw-ring-offset-gray-200\/50 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.5) !important;
}

.tw-ring-offset-gray-200\/55 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.55) !important;
}

.tw-ring-offset-gray-200\/60 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.6) !important;
}

.tw-ring-offset-gray-200\/65 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.65) !important;
}

.tw-ring-offset-gray-200\/70 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.7) !important;
}

.tw-ring-offset-gray-200\/75 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.75) !important;
}

.tw-ring-offset-gray-200\/80 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.8) !important;
}

.tw-ring-offset-gray-200\/85 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.85) !important;
}

.tw-ring-offset-gray-200\/90 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.9) !important;
}

.tw-ring-offset-gray-200\/95 {
  --tw-ring-offset-color: rgb(229 231 235 / 0.95) !important;
}

.tw-ring-offset-gray-300 {
  --tw-ring-offset-color: #d1d5db !important;
}

.tw-ring-offset-gray-300\/0 {
  --tw-ring-offset-color: rgb(209 213 219 / 0) !important;
}

.tw-ring-offset-gray-300\/10 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.1) !important;
}

.tw-ring-offset-gray-300\/100 {
  --tw-ring-offset-color: rgb(209 213 219 / 1) !important;
}

.tw-ring-offset-gray-300\/15 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.15) !important;
}

.tw-ring-offset-gray-300\/20 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.2) !important;
}

.tw-ring-offset-gray-300\/25 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.25) !important;
}

.tw-ring-offset-gray-300\/30 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.3) !important;
}

.tw-ring-offset-gray-300\/35 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.35) !important;
}

.tw-ring-offset-gray-300\/40 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.4) !important;
}

.tw-ring-offset-gray-300\/45 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.45) !important;
}

.tw-ring-offset-gray-300\/5 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.05) !important;
}

.tw-ring-offset-gray-300\/50 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.5) !important;
}

.tw-ring-offset-gray-300\/55 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.55) !important;
}

.tw-ring-offset-gray-300\/60 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.6) !important;
}

.tw-ring-offset-gray-300\/65 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.65) !important;
}

.tw-ring-offset-gray-300\/70 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.7) !important;
}

.tw-ring-offset-gray-300\/75 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.75) !important;
}

.tw-ring-offset-gray-300\/80 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.8) !important;
}

.tw-ring-offset-gray-300\/85 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.85) !important;
}

.tw-ring-offset-gray-300\/90 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.9) !important;
}

.tw-ring-offset-gray-300\/95 {
  --tw-ring-offset-color: rgb(209 213 219 / 0.95) !important;
}

.tw-ring-offset-gray-400 {
  --tw-ring-offset-color: #9ca3af !important;
}

.tw-ring-offset-gray-400\/0 {
  --tw-ring-offset-color: rgb(156 163 175 / 0) !important;
}

.tw-ring-offset-gray-400\/10 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.1) !important;
}

.tw-ring-offset-gray-400\/100 {
  --tw-ring-offset-color: rgb(156 163 175 / 1) !important;
}

.tw-ring-offset-gray-400\/15 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.15) !important;
}

.tw-ring-offset-gray-400\/20 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.2) !important;
}

.tw-ring-offset-gray-400\/25 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.25) !important;
}

.tw-ring-offset-gray-400\/30 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.3) !important;
}

.tw-ring-offset-gray-400\/35 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.35) !important;
}

.tw-ring-offset-gray-400\/40 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.4) !important;
}

.tw-ring-offset-gray-400\/45 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.45) !important;
}

.tw-ring-offset-gray-400\/5 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.05) !important;
}

.tw-ring-offset-gray-400\/50 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.5) !important;
}

.tw-ring-offset-gray-400\/55 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.55) !important;
}

.tw-ring-offset-gray-400\/60 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.6) !important;
}

.tw-ring-offset-gray-400\/65 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.65) !important;
}

.tw-ring-offset-gray-400\/70 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.7) !important;
}

.tw-ring-offset-gray-400\/75 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.75) !important;
}

.tw-ring-offset-gray-400\/80 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.8) !important;
}

.tw-ring-offset-gray-400\/85 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.85) !important;
}

.tw-ring-offset-gray-400\/90 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.9) !important;
}

.tw-ring-offset-gray-400\/95 {
  --tw-ring-offset-color: rgb(156 163 175 / 0.95) !important;
}

.tw-ring-offset-gray-50 {
  --tw-ring-offset-color: #f9fafb !important;
}

.tw-ring-offset-gray-50\/0 {
  --tw-ring-offset-color: rgb(249 250 251 / 0) !important;
}

.tw-ring-offset-gray-50\/10 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.1) !important;
}

.tw-ring-offset-gray-50\/100 {
  --tw-ring-offset-color: rgb(249 250 251 / 1) !important;
}

.tw-ring-offset-gray-50\/15 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.15) !important;
}

.tw-ring-offset-gray-50\/20 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.2) !important;
}

.tw-ring-offset-gray-50\/25 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.25) !important;
}

.tw-ring-offset-gray-50\/30 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.3) !important;
}

.tw-ring-offset-gray-50\/35 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.35) !important;
}

.tw-ring-offset-gray-50\/40 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.4) !important;
}

.tw-ring-offset-gray-50\/45 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.45) !important;
}

.tw-ring-offset-gray-50\/5 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.05) !important;
}

.tw-ring-offset-gray-50\/50 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.5) !important;
}

.tw-ring-offset-gray-50\/55 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.55) !important;
}

.tw-ring-offset-gray-50\/60 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.6) !important;
}

.tw-ring-offset-gray-50\/65 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.65) !important;
}

.tw-ring-offset-gray-50\/70 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.7) !important;
}

.tw-ring-offset-gray-50\/75 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.75) !important;
}

.tw-ring-offset-gray-50\/80 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.8) !important;
}

.tw-ring-offset-gray-50\/85 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.85) !important;
}

.tw-ring-offset-gray-50\/90 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.9) !important;
}

.tw-ring-offset-gray-50\/95 {
  --tw-ring-offset-color: rgb(249 250 251 / 0.95) !important;
}

.tw-ring-offset-gray-500 {
  --tw-ring-offset-color: #6b7280 !important;
}

.tw-ring-offset-gray-500\/0 {
  --tw-ring-offset-color: rgb(107 114 128 / 0) !important;
}

.tw-ring-offset-gray-500\/10 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.1) !important;
}

.tw-ring-offset-gray-500\/100 {
  --tw-ring-offset-color: rgb(107 114 128 / 1) !important;
}

.tw-ring-offset-gray-500\/15 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.15) !important;
}

.tw-ring-offset-gray-500\/20 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.2) !important;
}

.tw-ring-offset-gray-500\/25 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.25) !important;
}

.tw-ring-offset-gray-500\/30 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.3) !important;
}

.tw-ring-offset-gray-500\/35 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.35) !important;
}

.tw-ring-offset-gray-500\/40 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.4) !important;
}

.tw-ring-offset-gray-500\/45 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.45) !important;
}

.tw-ring-offset-gray-500\/5 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.05) !important;
}

.tw-ring-offset-gray-500\/50 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.5) !important;
}

.tw-ring-offset-gray-500\/55 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.55) !important;
}

.tw-ring-offset-gray-500\/60 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.6) !important;
}

.tw-ring-offset-gray-500\/65 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.65) !important;
}

.tw-ring-offset-gray-500\/70 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.7) !important;
}

.tw-ring-offset-gray-500\/75 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.75) !important;
}

.tw-ring-offset-gray-500\/80 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.8) !important;
}

.tw-ring-offset-gray-500\/85 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.85) !important;
}

.tw-ring-offset-gray-500\/90 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.9) !important;
}

.tw-ring-offset-gray-500\/95 {
  --tw-ring-offset-color: rgb(107 114 128 / 0.95) !important;
}

.tw-ring-offset-gray-600 {
  --tw-ring-offset-color: #4b5563 !important;
}

.tw-ring-offset-gray-600\/0 {
  --tw-ring-offset-color: rgb(75 85 99 / 0) !important;
}

.tw-ring-offset-gray-600\/10 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.1) !important;
}

.tw-ring-offset-gray-600\/100 {
  --tw-ring-offset-color: rgb(75 85 99 / 1) !important;
}

.tw-ring-offset-gray-600\/15 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.15) !important;
}

.tw-ring-offset-gray-600\/20 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.2) !important;
}

.tw-ring-offset-gray-600\/25 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.25) !important;
}

.tw-ring-offset-gray-600\/30 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.3) !important;
}

.tw-ring-offset-gray-600\/35 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.35) !important;
}

.tw-ring-offset-gray-600\/40 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.4) !important;
}

.tw-ring-offset-gray-600\/45 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.45) !important;
}

.tw-ring-offset-gray-600\/5 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.05) !important;
}

.tw-ring-offset-gray-600\/50 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.5) !important;
}

.tw-ring-offset-gray-600\/55 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.55) !important;
}

.tw-ring-offset-gray-600\/60 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.6) !important;
}

.tw-ring-offset-gray-600\/65 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.65) !important;
}

.tw-ring-offset-gray-600\/70 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.7) !important;
}

.tw-ring-offset-gray-600\/75 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.75) !important;
}

.tw-ring-offset-gray-600\/80 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.8) !important;
}

.tw-ring-offset-gray-600\/85 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.85) !important;
}

.tw-ring-offset-gray-600\/90 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.9) !important;
}

.tw-ring-offset-gray-600\/95 {
  --tw-ring-offset-color: rgb(75 85 99 / 0.95) !important;
}

.tw-ring-offset-gray-700 {
  --tw-ring-offset-color: #374151 !important;
}

.tw-ring-offset-gray-700\/0 {
  --tw-ring-offset-color: rgb(55 65 81 / 0) !important;
}

.tw-ring-offset-gray-700\/10 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.1) !important;
}

.tw-ring-offset-gray-700\/100 {
  --tw-ring-offset-color: rgb(55 65 81 / 1) !important;
}

.tw-ring-offset-gray-700\/15 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.15) !important;
}

.tw-ring-offset-gray-700\/20 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.2) !important;
}

.tw-ring-offset-gray-700\/25 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.25) !important;
}

.tw-ring-offset-gray-700\/30 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.3) !important;
}

.tw-ring-offset-gray-700\/35 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.35) !important;
}

.tw-ring-offset-gray-700\/40 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.4) !important;
}

.tw-ring-offset-gray-700\/45 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.45) !important;
}

.tw-ring-offset-gray-700\/5 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.05) !important;
}

.tw-ring-offset-gray-700\/50 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.5) !important;
}

.tw-ring-offset-gray-700\/55 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.55) !important;
}

.tw-ring-offset-gray-700\/60 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.6) !important;
}

.tw-ring-offset-gray-700\/65 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.65) !important;
}

.tw-ring-offset-gray-700\/70 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.7) !important;
}

.tw-ring-offset-gray-700\/75 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.75) !important;
}

.tw-ring-offset-gray-700\/80 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.8) !important;
}

.tw-ring-offset-gray-700\/85 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.85) !important;
}

.tw-ring-offset-gray-700\/90 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.9) !important;
}

.tw-ring-offset-gray-700\/95 {
  --tw-ring-offset-color: rgb(55 65 81 / 0.95) !important;
}

.tw-ring-offset-gray-800 {
  --tw-ring-offset-color: #1f2937 !important;
}

.tw-ring-offset-gray-800\/0 {
  --tw-ring-offset-color: rgb(31 41 55 / 0) !important;
}

.tw-ring-offset-gray-800\/10 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.1) !important;
}

.tw-ring-offset-gray-800\/100 {
  --tw-ring-offset-color: rgb(31 41 55 / 1) !important;
}

.tw-ring-offset-gray-800\/15 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.15) !important;
}

.tw-ring-offset-gray-800\/20 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.2) !important;
}

.tw-ring-offset-gray-800\/25 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.25) !important;
}

.tw-ring-offset-gray-800\/30 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.3) !important;
}

.tw-ring-offset-gray-800\/35 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.35) !important;
}

.tw-ring-offset-gray-800\/40 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.4) !important;
}

.tw-ring-offset-gray-800\/45 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.45) !important;
}

.tw-ring-offset-gray-800\/5 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.05) !important;
}

.tw-ring-offset-gray-800\/50 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.5) !important;
}

.tw-ring-offset-gray-800\/55 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.55) !important;
}

.tw-ring-offset-gray-800\/60 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.6) !important;
}

.tw-ring-offset-gray-800\/65 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.65) !important;
}

.tw-ring-offset-gray-800\/70 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.7) !important;
}

.tw-ring-offset-gray-800\/75 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.75) !important;
}

.tw-ring-offset-gray-800\/80 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.8) !important;
}

.tw-ring-offset-gray-800\/85 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.85) !important;
}

.tw-ring-offset-gray-800\/90 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.9) !important;
}

.tw-ring-offset-gray-800\/95 {
  --tw-ring-offset-color: rgb(31 41 55 / 0.95) !important;
}

.tw-ring-offset-gray-900 {
  --tw-ring-offset-color: #111827 !important;
}

.tw-ring-offset-gray-900\/0 {
  --tw-ring-offset-color: rgb(17 24 39 / 0) !important;
}

.tw-ring-offset-gray-900\/10 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.1) !important;
}

.tw-ring-offset-gray-900\/100 {
  --tw-ring-offset-color: rgb(17 24 39 / 1) !important;
}

.tw-ring-offset-gray-900\/15 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.15) !important;
}

.tw-ring-offset-gray-900\/20 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.2) !important;
}

.tw-ring-offset-gray-900\/25 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.25) !important;
}

.tw-ring-offset-gray-900\/30 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.3) !important;
}

.tw-ring-offset-gray-900\/35 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.35) !important;
}

.tw-ring-offset-gray-900\/40 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.4) !important;
}

.tw-ring-offset-gray-900\/45 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.45) !important;
}

.tw-ring-offset-gray-900\/5 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.05) !important;
}

.tw-ring-offset-gray-900\/50 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.5) !important;
}

.tw-ring-offset-gray-900\/55 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.55) !important;
}

.tw-ring-offset-gray-900\/60 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.6) !important;
}

.tw-ring-offset-gray-900\/65 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.65) !important;
}

.tw-ring-offset-gray-900\/70 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.7) !important;
}

.tw-ring-offset-gray-900\/75 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.75) !important;
}

.tw-ring-offset-gray-900\/80 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.8) !important;
}

.tw-ring-offset-gray-900\/85 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.85) !important;
}

.tw-ring-offset-gray-900\/90 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.9) !important;
}

.tw-ring-offset-gray-900\/95 {
  --tw-ring-offset-color: rgb(17 24 39 / 0.95) !important;
}

.tw-ring-offset-gray-950 {
  --tw-ring-offset-color: #030712 !important;
}

.tw-ring-offset-gray-950\/0 {
  --tw-ring-offset-color: rgb(3 7 18 / 0) !important;
}

.tw-ring-offset-gray-950\/10 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.1) !important;
}

.tw-ring-offset-gray-950\/100 {
  --tw-ring-offset-color: rgb(3 7 18 / 1) !important;
}

.tw-ring-offset-gray-950\/15 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.15) !important;
}

.tw-ring-offset-gray-950\/20 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.2) !important;
}

.tw-ring-offset-gray-950\/25 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.25) !important;
}

.tw-ring-offset-gray-950\/30 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.3) !important;
}

.tw-ring-offset-gray-950\/35 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.35) !important;
}

.tw-ring-offset-gray-950\/40 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.4) !important;
}

.tw-ring-offset-gray-950\/45 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.45) !important;
}

.tw-ring-offset-gray-950\/5 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.05) !important;
}

.tw-ring-offset-gray-950\/50 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.5) !important;
}

.tw-ring-offset-gray-950\/55 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.55) !important;
}

.tw-ring-offset-gray-950\/60 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.6) !important;
}

.tw-ring-offset-gray-950\/65 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.65) !important;
}

.tw-ring-offset-gray-950\/70 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.7) !important;
}

.tw-ring-offset-gray-950\/75 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.75) !important;
}

.tw-ring-offset-gray-950\/80 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.8) !important;
}

.tw-ring-offset-gray-950\/85 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.85) !important;
}

.tw-ring-offset-gray-950\/90 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.9) !important;
}

.tw-ring-offset-gray-950\/95 {
  --tw-ring-offset-color: rgb(3 7 18 / 0.95) !important;
}

.tw-ring-offset-green-100 {
  --tw-ring-offset-color: #dcfce7 !important;
}

.tw-ring-offset-green-100\/0 {
  --tw-ring-offset-color: rgb(220 252 231 / 0) !important;
}

.tw-ring-offset-green-100\/10 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.1) !important;
}

.tw-ring-offset-green-100\/100 {
  --tw-ring-offset-color: rgb(220 252 231 / 1) !important;
}

.tw-ring-offset-green-100\/15 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.15) !important;
}

.tw-ring-offset-green-100\/20 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.2) !important;
}

.tw-ring-offset-green-100\/25 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.25) !important;
}

.tw-ring-offset-green-100\/30 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.3) !important;
}

.tw-ring-offset-green-100\/35 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.35) !important;
}

.tw-ring-offset-green-100\/40 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.4) !important;
}

.tw-ring-offset-green-100\/45 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.45) !important;
}

.tw-ring-offset-green-100\/5 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.05) !important;
}

.tw-ring-offset-green-100\/50 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.5) !important;
}

.tw-ring-offset-green-100\/55 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.55) !important;
}

.tw-ring-offset-green-100\/60 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.6) !important;
}

.tw-ring-offset-green-100\/65 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.65) !important;
}

.tw-ring-offset-green-100\/70 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.7) !important;
}

.tw-ring-offset-green-100\/75 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.75) !important;
}

.tw-ring-offset-green-100\/80 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.8) !important;
}

.tw-ring-offset-green-100\/85 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.85) !important;
}

.tw-ring-offset-green-100\/90 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.9) !important;
}

.tw-ring-offset-green-100\/95 {
  --tw-ring-offset-color: rgb(220 252 231 / 0.95) !important;
}

.tw-ring-offset-green-200 {
  --tw-ring-offset-color: #bbf7d0 !important;
}

.tw-ring-offset-green-200\/0 {
  --tw-ring-offset-color: rgb(187 247 208 / 0) !important;
}

.tw-ring-offset-green-200\/10 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.1) !important;
}

.tw-ring-offset-green-200\/100 {
  --tw-ring-offset-color: rgb(187 247 208 / 1) !important;
}

.tw-ring-offset-green-200\/15 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.15) !important;
}

.tw-ring-offset-green-200\/20 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.2) !important;
}

.tw-ring-offset-green-200\/25 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.25) !important;
}

.tw-ring-offset-green-200\/30 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.3) !important;
}

.tw-ring-offset-green-200\/35 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.35) !important;
}

.tw-ring-offset-green-200\/40 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.4) !important;
}

.tw-ring-offset-green-200\/45 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.45) !important;
}

.tw-ring-offset-green-200\/5 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.05) !important;
}

.tw-ring-offset-green-200\/50 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.5) !important;
}

.tw-ring-offset-green-200\/55 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.55) !important;
}

.tw-ring-offset-green-200\/60 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.6) !important;
}

.tw-ring-offset-green-200\/65 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.65) !important;
}

.tw-ring-offset-green-200\/70 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.7) !important;
}

.tw-ring-offset-green-200\/75 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.75) !important;
}

.tw-ring-offset-green-200\/80 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.8) !important;
}

.tw-ring-offset-green-200\/85 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.85) !important;
}

.tw-ring-offset-green-200\/90 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.9) !important;
}

.tw-ring-offset-green-200\/95 {
  --tw-ring-offset-color: rgb(187 247 208 / 0.95) !important;
}

.tw-ring-offset-green-300 {
  --tw-ring-offset-color: #86efac !important;
}

.tw-ring-offset-green-300\/0 {
  --tw-ring-offset-color: rgb(134 239 172 / 0) !important;
}

.tw-ring-offset-green-300\/10 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.1) !important;
}

.tw-ring-offset-green-300\/100 {
  --tw-ring-offset-color: rgb(134 239 172 / 1) !important;
}

.tw-ring-offset-green-300\/15 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.15) !important;
}

.tw-ring-offset-green-300\/20 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.2) !important;
}

.tw-ring-offset-green-300\/25 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.25) !important;
}

.tw-ring-offset-green-300\/30 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.3) !important;
}

.tw-ring-offset-green-300\/35 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.35) !important;
}

.tw-ring-offset-green-300\/40 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.4) !important;
}

.tw-ring-offset-green-300\/45 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.45) !important;
}

.tw-ring-offset-green-300\/5 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.05) !important;
}

.tw-ring-offset-green-300\/50 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.5) !important;
}

.tw-ring-offset-green-300\/55 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.55) !important;
}

.tw-ring-offset-green-300\/60 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.6) !important;
}

.tw-ring-offset-green-300\/65 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.65) !important;
}

.tw-ring-offset-green-300\/70 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.7) !important;
}

.tw-ring-offset-green-300\/75 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.75) !important;
}

.tw-ring-offset-green-300\/80 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.8) !important;
}

.tw-ring-offset-green-300\/85 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.85) !important;
}

.tw-ring-offset-green-300\/90 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.9) !important;
}

.tw-ring-offset-green-300\/95 {
  --tw-ring-offset-color: rgb(134 239 172 / 0.95) !important;
}

.tw-ring-offset-green-400 {
  --tw-ring-offset-color: #4ade80 !important;
}

.tw-ring-offset-green-400\/0 {
  --tw-ring-offset-color: rgb(74 222 128 / 0) !important;
}

.tw-ring-offset-green-400\/10 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.1) !important;
}

.tw-ring-offset-green-400\/100 {
  --tw-ring-offset-color: rgb(74 222 128 / 1) !important;
}

.tw-ring-offset-green-400\/15 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.15) !important;
}

.tw-ring-offset-green-400\/20 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.2) !important;
}

.tw-ring-offset-green-400\/25 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.25) !important;
}

.tw-ring-offset-green-400\/30 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.3) !important;
}

.tw-ring-offset-green-400\/35 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.35) !important;
}

.tw-ring-offset-green-400\/40 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.4) !important;
}

.tw-ring-offset-green-400\/45 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.45) !important;
}

.tw-ring-offset-green-400\/5 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.05) !important;
}

.tw-ring-offset-green-400\/50 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.5) !important;
}

.tw-ring-offset-green-400\/55 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.55) !important;
}

.tw-ring-offset-green-400\/60 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.6) !important;
}

.tw-ring-offset-green-400\/65 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.65) !important;
}

.tw-ring-offset-green-400\/70 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.7) !important;
}

.tw-ring-offset-green-400\/75 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.75) !important;
}

.tw-ring-offset-green-400\/80 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.8) !important;
}

.tw-ring-offset-green-400\/85 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.85) !important;
}

.tw-ring-offset-green-400\/90 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.9) !important;
}

.tw-ring-offset-green-400\/95 {
  --tw-ring-offset-color: rgb(74 222 128 / 0.95) !important;
}

.tw-ring-offset-green-50 {
  --tw-ring-offset-color: #f0fdf4 !important;
}

.tw-ring-offset-green-50\/0 {
  --tw-ring-offset-color: rgb(240 253 244 / 0) !important;
}

.tw-ring-offset-green-50\/10 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.1) !important;
}

.tw-ring-offset-green-50\/100 {
  --tw-ring-offset-color: rgb(240 253 244 / 1) !important;
}

.tw-ring-offset-green-50\/15 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.15) !important;
}

.tw-ring-offset-green-50\/20 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.2) !important;
}

.tw-ring-offset-green-50\/25 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.25) !important;
}

.tw-ring-offset-green-50\/30 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.3) !important;
}

.tw-ring-offset-green-50\/35 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.35) !important;
}

.tw-ring-offset-green-50\/40 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.4) !important;
}

.tw-ring-offset-green-50\/45 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.45) !important;
}

.tw-ring-offset-green-50\/5 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.05) !important;
}

.tw-ring-offset-green-50\/50 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.5) !important;
}

.tw-ring-offset-green-50\/55 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.55) !important;
}

.tw-ring-offset-green-50\/60 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.6) !important;
}

.tw-ring-offset-green-50\/65 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.65) !important;
}

.tw-ring-offset-green-50\/70 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.7) !important;
}

.tw-ring-offset-green-50\/75 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.75) !important;
}

.tw-ring-offset-green-50\/80 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.8) !important;
}

.tw-ring-offset-green-50\/85 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.85) !important;
}

.tw-ring-offset-green-50\/90 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.9) !important;
}

.tw-ring-offset-green-50\/95 {
  --tw-ring-offset-color: rgb(240 253 244 / 0.95) !important;
}

.tw-ring-offset-green-500 {
  --tw-ring-offset-color: #22c55e !important;
}

.tw-ring-offset-green-500\/0 {
  --tw-ring-offset-color: rgb(34 197 94 / 0) !important;
}

.tw-ring-offset-green-500\/10 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.1) !important;
}

.tw-ring-offset-green-500\/100 {
  --tw-ring-offset-color: rgb(34 197 94 / 1) !important;
}

.tw-ring-offset-green-500\/15 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.15) !important;
}

.tw-ring-offset-green-500\/20 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.2) !important;
}

.tw-ring-offset-green-500\/25 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.25) !important;
}

.tw-ring-offset-green-500\/30 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.3) !important;
}

.tw-ring-offset-green-500\/35 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.35) !important;
}

.tw-ring-offset-green-500\/40 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.4) !important;
}

.tw-ring-offset-green-500\/45 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.45) !important;
}

.tw-ring-offset-green-500\/5 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.05) !important;
}

.tw-ring-offset-green-500\/50 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.5) !important;
}

.tw-ring-offset-green-500\/55 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.55) !important;
}

.tw-ring-offset-green-500\/60 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.6) !important;
}

.tw-ring-offset-green-500\/65 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.65) !important;
}

.tw-ring-offset-green-500\/70 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.7) !important;
}

.tw-ring-offset-green-500\/75 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.75) !important;
}

.tw-ring-offset-green-500\/80 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.8) !important;
}

.tw-ring-offset-green-500\/85 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.85) !important;
}

.tw-ring-offset-green-500\/90 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.9) !important;
}

.tw-ring-offset-green-500\/95 {
  --tw-ring-offset-color: rgb(34 197 94 / 0.95) !important;
}

.tw-ring-offset-green-600 {
  --tw-ring-offset-color: #16a34a !important;
}

.tw-ring-offset-green-600\/0 {
  --tw-ring-offset-color: rgb(22 163 74 / 0) !important;
}

.tw-ring-offset-green-600\/10 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.1) !important;
}

.tw-ring-offset-green-600\/100 {
  --tw-ring-offset-color: rgb(22 163 74 / 1) !important;
}

.tw-ring-offset-green-600\/15 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.15) !important;
}

.tw-ring-offset-green-600\/20 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.2) !important;
}

.tw-ring-offset-green-600\/25 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.25) !important;
}

.tw-ring-offset-green-600\/30 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.3) !important;
}

.tw-ring-offset-green-600\/35 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.35) !important;
}

.tw-ring-offset-green-600\/40 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.4) !important;
}

.tw-ring-offset-green-600\/45 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.45) !important;
}

.tw-ring-offset-green-600\/5 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.05) !important;
}

.tw-ring-offset-green-600\/50 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.5) !important;
}

.tw-ring-offset-green-600\/55 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.55) !important;
}

.tw-ring-offset-green-600\/60 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.6) !important;
}

.tw-ring-offset-green-600\/65 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.65) !important;
}

.tw-ring-offset-green-600\/70 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.7) !important;
}

.tw-ring-offset-green-600\/75 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.75) !important;
}

.tw-ring-offset-green-600\/80 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.8) !important;
}

.tw-ring-offset-green-600\/85 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.85) !important;
}

.tw-ring-offset-green-600\/90 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.9) !important;
}

.tw-ring-offset-green-600\/95 {
  --tw-ring-offset-color: rgb(22 163 74 / 0.95) !important;
}

.tw-ring-offset-green-700 {
  --tw-ring-offset-color: #15803d !important;
}

.tw-ring-offset-green-700\/0 {
  --tw-ring-offset-color: rgb(21 128 61 / 0) !important;
}

.tw-ring-offset-green-700\/10 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.1) !important;
}

.tw-ring-offset-green-700\/100 {
  --tw-ring-offset-color: rgb(21 128 61 / 1) !important;
}

.tw-ring-offset-green-700\/15 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.15) !important;
}

.tw-ring-offset-green-700\/20 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.2) !important;
}

.tw-ring-offset-green-700\/25 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.25) !important;
}

.tw-ring-offset-green-700\/30 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.3) !important;
}

.tw-ring-offset-green-700\/35 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.35) !important;
}

.tw-ring-offset-green-700\/40 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.4) !important;
}

.tw-ring-offset-green-700\/45 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.45) !important;
}

.tw-ring-offset-green-700\/5 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.05) !important;
}

.tw-ring-offset-green-700\/50 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.5) !important;
}

.tw-ring-offset-green-700\/55 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.55) !important;
}

.tw-ring-offset-green-700\/60 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.6) !important;
}

.tw-ring-offset-green-700\/65 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.65) !important;
}

.tw-ring-offset-green-700\/70 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.7) !important;
}

.tw-ring-offset-green-700\/75 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.75) !important;
}

.tw-ring-offset-green-700\/80 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.8) !important;
}

.tw-ring-offset-green-700\/85 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.85) !important;
}

.tw-ring-offset-green-700\/90 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.9) !important;
}

.tw-ring-offset-green-700\/95 {
  --tw-ring-offset-color: rgb(21 128 61 / 0.95) !important;
}

.tw-ring-offset-green-800 {
  --tw-ring-offset-color: #166534 !important;
}

.tw-ring-offset-green-800\/0 {
  --tw-ring-offset-color: rgb(22 101 52 / 0) !important;
}

.tw-ring-offset-green-800\/10 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.1) !important;
}

.tw-ring-offset-green-800\/100 {
  --tw-ring-offset-color: rgb(22 101 52 / 1) !important;
}

.tw-ring-offset-green-800\/15 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.15) !important;
}

.tw-ring-offset-green-800\/20 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.2) !important;
}

.tw-ring-offset-green-800\/25 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.25) !important;
}

.tw-ring-offset-green-800\/30 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.3) !important;
}

.tw-ring-offset-green-800\/35 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.35) !important;
}

.tw-ring-offset-green-800\/40 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.4) !important;
}

.tw-ring-offset-green-800\/45 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.45) !important;
}

.tw-ring-offset-green-800\/5 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.05) !important;
}

.tw-ring-offset-green-800\/50 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.5) !important;
}

.tw-ring-offset-green-800\/55 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.55) !important;
}

.tw-ring-offset-green-800\/60 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.6) !important;
}

.tw-ring-offset-green-800\/65 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.65) !important;
}

.tw-ring-offset-green-800\/70 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.7) !important;
}

.tw-ring-offset-green-800\/75 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.75) !important;
}

.tw-ring-offset-green-800\/80 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.8) !important;
}

.tw-ring-offset-green-800\/85 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.85) !important;
}

.tw-ring-offset-green-800\/90 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.9) !important;
}

.tw-ring-offset-green-800\/95 {
  --tw-ring-offset-color: rgb(22 101 52 / 0.95) !important;
}

.tw-ring-offset-green-900 {
  --tw-ring-offset-color: #14532d !important;
}

.tw-ring-offset-green-900\/0 {
  --tw-ring-offset-color: rgb(20 83 45 / 0) !important;
}

.tw-ring-offset-green-900\/10 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.1) !important;
}

.tw-ring-offset-green-900\/100 {
  --tw-ring-offset-color: rgb(20 83 45 / 1) !important;
}

.tw-ring-offset-green-900\/15 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.15) !important;
}

.tw-ring-offset-green-900\/20 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.2) !important;
}

.tw-ring-offset-green-900\/25 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.25) !important;
}

.tw-ring-offset-green-900\/30 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.3) !important;
}

.tw-ring-offset-green-900\/35 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.35) !important;
}

.tw-ring-offset-green-900\/40 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.4) !important;
}

.tw-ring-offset-green-900\/45 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.45) !important;
}

.tw-ring-offset-green-900\/5 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.05) !important;
}

.tw-ring-offset-green-900\/50 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.5) !important;
}

.tw-ring-offset-green-900\/55 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.55) !important;
}

.tw-ring-offset-green-900\/60 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.6) !important;
}

.tw-ring-offset-green-900\/65 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.65) !important;
}

.tw-ring-offset-green-900\/70 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.7) !important;
}

.tw-ring-offset-green-900\/75 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.75) !important;
}

.tw-ring-offset-green-900\/80 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.8) !important;
}

.tw-ring-offset-green-900\/85 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.85) !important;
}

.tw-ring-offset-green-900\/90 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.9) !important;
}

.tw-ring-offset-green-900\/95 {
  --tw-ring-offset-color: rgb(20 83 45 / 0.95) !important;
}

.tw-ring-offset-green-950 {
  --tw-ring-offset-color: #052e16 !important;
}

.tw-ring-offset-green-950\/0 {
  --tw-ring-offset-color: rgb(5 46 22 / 0) !important;
}

.tw-ring-offset-green-950\/10 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.1) !important;
}

.tw-ring-offset-green-950\/100 {
  --tw-ring-offset-color: rgb(5 46 22 / 1) !important;
}

.tw-ring-offset-green-950\/15 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.15) !important;
}

.tw-ring-offset-green-950\/20 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.2) !important;
}

.tw-ring-offset-green-950\/25 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.25) !important;
}

.tw-ring-offset-green-950\/30 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.3) !important;
}

.tw-ring-offset-green-950\/35 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.35) !important;
}

.tw-ring-offset-green-950\/40 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.4) !important;
}

.tw-ring-offset-green-950\/45 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.45) !important;
}

.tw-ring-offset-green-950\/5 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.05) !important;
}

.tw-ring-offset-green-950\/50 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.5) !important;
}

.tw-ring-offset-green-950\/55 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.55) !important;
}

.tw-ring-offset-green-950\/60 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.6) !important;
}

.tw-ring-offset-green-950\/65 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.65) !important;
}

.tw-ring-offset-green-950\/70 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.7) !important;
}

.tw-ring-offset-green-950\/75 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.75) !important;
}

.tw-ring-offset-green-950\/80 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.8) !important;
}

.tw-ring-offset-green-950\/85 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.85) !important;
}

.tw-ring-offset-green-950\/90 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.9) !important;
}

.tw-ring-offset-green-950\/95 {
  --tw-ring-offset-color: rgb(5 46 22 / 0.95) !important;
}

.tw-ring-offset-indigo-100 {
  --tw-ring-offset-color: #e0e7ff !important;
}

.tw-ring-offset-indigo-100\/0 {
  --tw-ring-offset-color: rgb(224 231 255 / 0) !important;
}

.tw-ring-offset-indigo-100\/10 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.1) !important;
}

.tw-ring-offset-indigo-100\/100 {
  --tw-ring-offset-color: rgb(224 231 255 / 1) !important;
}

.tw-ring-offset-indigo-100\/15 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.15) !important;
}

.tw-ring-offset-indigo-100\/20 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.2) !important;
}

.tw-ring-offset-indigo-100\/25 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.25) !important;
}

.tw-ring-offset-indigo-100\/30 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.3) !important;
}

.tw-ring-offset-indigo-100\/35 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.35) !important;
}

.tw-ring-offset-indigo-100\/40 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.4) !important;
}

.tw-ring-offset-indigo-100\/45 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.45) !important;
}

.tw-ring-offset-indigo-100\/5 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.05) !important;
}

.tw-ring-offset-indigo-100\/50 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.5) !important;
}

.tw-ring-offset-indigo-100\/55 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.55) !important;
}

.tw-ring-offset-indigo-100\/60 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.6) !important;
}

.tw-ring-offset-indigo-100\/65 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.65) !important;
}

.tw-ring-offset-indigo-100\/70 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.7) !important;
}

.tw-ring-offset-indigo-100\/75 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.75) !important;
}

.tw-ring-offset-indigo-100\/80 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.8) !important;
}

.tw-ring-offset-indigo-100\/85 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.85) !important;
}

.tw-ring-offset-indigo-100\/90 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.9) !important;
}

.tw-ring-offset-indigo-100\/95 {
  --tw-ring-offset-color: rgb(224 231 255 / 0.95) !important;
}

.tw-ring-offset-indigo-200 {
  --tw-ring-offset-color: #c7d2fe !important;
}

.tw-ring-offset-indigo-200\/0 {
  --tw-ring-offset-color: rgb(199 210 254 / 0) !important;
}

.tw-ring-offset-indigo-200\/10 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.1) !important;
}

.tw-ring-offset-indigo-200\/100 {
  --tw-ring-offset-color: rgb(199 210 254 / 1) !important;
}

.tw-ring-offset-indigo-200\/15 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.15) !important;
}

.tw-ring-offset-indigo-200\/20 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.2) !important;
}

.tw-ring-offset-indigo-200\/25 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.25) !important;
}

.tw-ring-offset-indigo-200\/30 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.3) !important;
}

.tw-ring-offset-indigo-200\/35 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.35) !important;
}

.tw-ring-offset-indigo-200\/40 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.4) !important;
}

.tw-ring-offset-indigo-200\/45 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.45) !important;
}

.tw-ring-offset-indigo-200\/5 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.05) !important;
}

.tw-ring-offset-indigo-200\/50 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.5) !important;
}

.tw-ring-offset-indigo-200\/55 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.55) !important;
}

.tw-ring-offset-indigo-200\/60 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.6) !important;
}

.tw-ring-offset-indigo-200\/65 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.65) !important;
}

.tw-ring-offset-indigo-200\/70 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.7) !important;
}

.tw-ring-offset-indigo-200\/75 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.75) !important;
}

.tw-ring-offset-indigo-200\/80 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.8) !important;
}

.tw-ring-offset-indigo-200\/85 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.85) !important;
}

.tw-ring-offset-indigo-200\/90 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.9) !important;
}

.tw-ring-offset-indigo-200\/95 {
  --tw-ring-offset-color: rgb(199 210 254 / 0.95) !important;
}

.tw-ring-offset-indigo-300 {
  --tw-ring-offset-color: #a5b4fc !important;
}

.tw-ring-offset-indigo-300\/0 {
  --tw-ring-offset-color: rgb(165 180 252 / 0) !important;
}

.tw-ring-offset-indigo-300\/10 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.1) !important;
}

.tw-ring-offset-indigo-300\/100 {
  --tw-ring-offset-color: rgb(165 180 252 / 1) !important;
}

.tw-ring-offset-indigo-300\/15 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.15) !important;
}

.tw-ring-offset-indigo-300\/20 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.2) !important;
}

.tw-ring-offset-indigo-300\/25 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.25) !important;
}

.tw-ring-offset-indigo-300\/30 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.3) !important;
}

.tw-ring-offset-indigo-300\/35 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.35) !important;
}

.tw-ring-offset-indigo-300\/40 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.4) !important;
}

.tw-ring-offset-indigo-300\/45 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.45) !important;
}

.tw-ring-offset-indigo-300\/5 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.05) !important;
}

.tw-ring-offset-indigo-300\/50 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.5) !important;
}

.tw-ring-offset-indigo-300\/55 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.55) !important;
}

.tw-ring-offset-indigo-300\/60 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.6) !important;
}

.tw-ring-offset-indigo-300\/65 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.65) !important;
}

.tw-ring-offset-indigo-300\/70 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.7) !important;
}

.tw-ring-offset-indigo-300\/75 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.75) !important;
}

.tw-ring-offset-indigo-300\/80 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.8) !important;
}

.tw-ring-offset-indigo-300\/85 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.85) !important;
}

.tw-ring-offset-indigo-300\/90 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.9) !important;
}

.tw-ring-offset-indigo-300\/95 {
  --tw-ring-offset-color: rgb(165 180 252 / 0.95) !important;
}

.tw-ring-offset-indigo-400 {
  --tw-ring-offset-color: #818cf8 !important;
}

.tw-ring-offset-indigo-400\/0 {
  --tw-ring-offset-color: rgb(129 140 248 / 0) !important;
}

.tw-ring-offset-indigo-400\/10 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.1) !important;
}

.tw-ring-offset-indigo-400\/100 {
  --tw-ring-offset-color: rgb(129 140 248 / 1) !important;
}

.tw-ring-offset-indigo-400\/15 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.15) !important;
}

.tw-ring-offset-indigo-400\/20 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.2) !important;
}

.tw-ring-offset-indigo-400\/25 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.25) !important;
}

.tw-ring-offset-indigo-400\/30 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.3) !important;
}

.tw-ring-offset-indigo-400\/35 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.35) !important;
}

.tw-ring-offset-indigo-400\/40 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.4) !important;
}

.tw-ring-offset-indigo-400\/45 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.45) !important;
}

.tw-ring-offset-indigo-400\/5 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.05) !important;
}

.tw-ring-offset-indigo-400\/50 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.5) !important;
}

.tw-ring-offset-indigo-400\/55 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.55) !important;
}

.tw-ring-offset-indigo-400\/60 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.6) !important;
}

.tw-ring-offset-indigo-400\/65 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.65) !important;
}

.tw-ring-offset-indigo-400\/70 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.7) !important;
}

.tw-ring-offset-indigo-400\/75 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.75) !important;
}

.tw-ring-offset-indigo-400\/80 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.8) !important;
}

.tw-ring-offset-indigo-400\/85 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.85) !important;
}

.tw-ring-offset-indigo-400\/90 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.9) !important;
}

.tw-ring-offset-indigo-400\/95 {
  --tw-ring-offset-color: rgb(129 140 248 / 0.95) !important;
}

.tw-ring-offset-indigo-50 {
  --tw-ring-offset-color: #eef2ff !important;
}

.tw-ring-offset-indigo-50\/0 {
  --tw-ring-offset-color: rgb(238 242 255 / 0) !important;
}

.tw-ring-offset-indigo-50\/10 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.1) !important;
}

.tw-ring-offset-indigo-50\/100 {
  --tw-ring-offset-color: rgb(238 242 255 / 1) !important;
}

.tw-ring-offset-indigo-50\/15 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.15) !important;
}

.tw-ring-offset-indigo-50\/20 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.2) !important;
}

.tw-ring-offset-indigo-50\/25 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.25) !important;
}

.tw-ring-offset-indigo-50\/30 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.3) !important;
}

.tw-ring-offset-indigo-50\/35 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.35) !important;
}

.tw-ring-offset-indigo-50\/40 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.4) !important;
}

.tw-ring-offset-indigo-50\/45 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.45) !important;
}

.tw-ring-offset-indigo-50\/5 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.05) !important;
}

.tw-ring-offset-indigo-50\/50 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.5) !important;
}

.tw-ring-offset-indigo-50\/55 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.55) !important;
}

.tw-ring-offset-indigo-50\/60 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.6) !important;
}

.tw-ring-offset-indigo-50\/65 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.65) !important;
}

.tw-ring-offset-indigo-50\/70 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.7) !important;
}

.tw-ring-offset-indigo-50\/75 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.75) !important;
}

.tw-ring-offset-indigo-50\/80 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.8) !important;
}

.tw-ring-offset-indigo-50\/85 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.85) !important;
}

.tw-ring-offset-indigo-50\/90 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.9) !important;
}

.tw-ring-offset-indigo-50\/95 {
  --tw-ring-offset-color: rgb(238 242 255 / 0.95) !important;
}

.tw-ring-offset-indigo-500 {
  --tw-ring-offset-color: #6366f1 !important;
}

.tw-ring-offset-indigo-500\/0 {
  --tw-ring-offset-color: rgb(99 102 241 / 0) !important;
}

.tw-ring-offset-indigo-500\/10 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.1) !important;
}

.tw-ring-offset-indigo-500\/100 {
  --tw-ring-offset-color: rgb(99 102 241 / 1) !important;
}

.tw-ring-offset-indigo-500\/15 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.15) !important;
}

.tw-ring-offset-indigo-500\/20 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.2) !important;
}

.tw-ring-offset-indigo-500\/25 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.25) !important;
}

.tw-ring-offset-indigo-500\/30 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.3) !important;
}

.tw-ring-offset-indigo-500\/35 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.35) !important;
}

.tw-ring-offset-indigo-500\/40 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.4) !important;
}

.tw-ring-offset-indigo-500\/45 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.45) !important;
}

.tw-ring-offset-indigo-500\/5 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.05) !important;
}

.tw-ring-offset-indigo-500\/50 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.5) !important;
}

.tw-ring-offset-indigo-500\/55 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.55) !important;
}

.tw-ring-offset-indigo-500\/60 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.6) !important;
}

.tw-ring-offset-indigo-500\/65 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.65) !important;
}

.tw-ring-offset-indigo-500\/70 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.7) !important;
}

.tw-ring-offset-indigo-500\/75 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.75) !important;
}

.tw-ring-offset-indigo-500\/80 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.8) !important;
}

.tw-ring-offset-indigo-500\/85 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.85) !important;
}

.tw-ring-offset-indigo-500\/90 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.9) !important;
}

.tw-ring-offset-indigo-500\/95 {
  --tw-ring-offset-color: rgb(99 102 241 / 0.95) !important;
}

.tw-ring-offset-indigo-600 {
  --tw-ring-offset-color: #4f46e5 !important;
}

.tw-ring-offset-indigo-600\/0 {
  --tw-ring-offset-color: rgb(79 70 229 / 0) !important;
}

.tw-ring-offset-indigo-600\/10 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.1) !important;
}

.tw-ring-offset-indigo-600\/100 {
  --tw-ring-offset-color: rgb(79 70 229 / 1) !important;
}

.tw-ring-offset-indigo-600\/15 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.15) !important;
}

.tw-ring-offset-indigo-600\/20 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.2) !important;
}

.tw-ring-offset-indigo-600\/25 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.25) !important;
}

.tw-ring-offset-indigo-600\/30 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.3) !important;
}

.tw-ring-offset-indigo-600\/35 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.35) !important;
}

.tw-ring-offset-indigo-600\/40 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.4) !important;
}

.tw-ring-offset-indigo-600\/45 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.45) !important;
}

.tw-ring-offset-indigo-600\/5 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.05) !important;
}

.tw-ring-offset-indigo-600\/50 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.5) !important;
}

.tw-ring-offset-indigo-600\/55 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.55) !important;
}

.tw-ring-offset-indigo-600\/60 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.6) !important;
}

.tw-ring-offset-indigo-600\/65 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.65) !important;
}

.tw-ring-offset-indigo-600\/70 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.7) !important;
}

.tw-ring-offset-indigo-600\/75 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.75) !important;
}

.tw-ring-offset-indigo-600\/80 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.8) !important;
}

.tw-ring-offset-indigo-600\/85 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.85) !important;
}

.tw-ring-offset-indigo-600\/90 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.9) !important;
}

.tw-ring-offset-indigo-600\/95 {
  --tw-ring-offset-color: rgb(79 70 229 / 0.95) !important;
}

.tw-ring-offset-indigo-700 {
  --tw-ring-offset-color: #4338ca !important;
}

.tw-ring-offset-indigo-700\/0 {
  --tw-ring-offset-color: rgb(67 56 202 / 0) !important;
}

.tw-ring-offset-indigo-700\/10 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.1) !important;
}

.tw-ring-offset-indigo-700\/100 {
  --tw-ring-offset-color: rgb(67 56 202 / 1) !important;
}

.tw-ring-offset-indigo-700\/15 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.15) !important;
}

.tw-ring-offset-indigo-700\/20 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.2) !important;
}

.tw-ring-offset-indigo-700\/25 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.25) !important;
}

.tw-ring-offset-indigo-700\/30 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.3) !important;
}

.tw-ring-offset-indigo-700\/35 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.35) !important;
}

.tw-ring-offset-indigo-700\/40 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.4) !important;
}

.tw-ring-offset-indigo-700\/45 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.45) !important;
}

.tw-ring-offset-indigo-700\/5 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.05) !important;
}

.tw-ring-offset-indigo-700\/50 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.5) !important;
}

.tw-ring-offset-indigo-700\/55 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.55) !important;
}

.tw-ring-offset-indigo-700\/60 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.6) !important;
}

.tw-ring-offset-indigo-700\/65 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.65) !important;
}

.tw-ring-offset-indigo-700\/70 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.7) !important;
}

.tw-ring-offset-indigo-700\/75 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.75) !important;
}

.tw-ring-offset-indigo-700\/80 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.8) !important;
}

.tw-ring-offset-indigo-700\/85 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.85) !important;
}

.tw-ring-offset-indigo-700\/90 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.9) !important;
}

.tw-ring-offset-indigo-700\/95 {
  --tw-ring-offset-color: rgb(67 56 202 / 0.95) !important;
}

.tw-ring-offset-indigo-800 {
  --tw-ring-offset-color: #3730a3 !important;
}

.tw-ring-offset-indigo-800\/0 {
  --tw-ring-offset-color: rgb(55 48 163 / 0) !important;
}

.tw-ring-offset-indigo-800\/10 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.1) !important;
}

.tw-ring-offset-indigo-800\/100 {
  --tw-ring-offset-color: rgb(55 48 163 / 1) !important;
}

.tw-ring-offset-indigo-800\/15 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.15) !important;
}

.tw-ring-offset-indigo-800\/20 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.2) !important;
}

.tw-ring-offset-indigo-800\/25 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.25) !important;
}

.tw-ring-offset-indigo-800\/30 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.3) !important;
}

.tw-ring-offset-indigo-800\/35 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.35) !important;
}

.tw-ring-offset-indigo-800\/40 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.4) !important;
}

.tw-ring-offset-indigo-800\/45 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.45) !important;
}

.tw-ring-offset-indigo-800\/5 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.05) !important;
}

.tw-ring-offset-indigo-800\/50 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.5) !important;
}

.tw-ring-offset-indigo-800\/55 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.55) !important;
}

.tw-ring-offset-indigo-800\/60 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.6) !important;
}

.tw-ring-offset-indigo-800\/65 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.65) !important;
}

.tw-ring-offset-indigo-800\/70 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.7) !important;
}

.tw-ring-offset-indigo-800\/75 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.75) !important;
}

.tw-ring-offset-indigo-800\/80 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.8) !important;
}

.tw-ring-offset-indigo-800\/85 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.85) !important;
}

.tw-ring-offset-indigo-800\/90 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.9) !important;
}

.tw-ring-offset-indigo-800\/95 {
  --tw-ring-offset-color: rgb(55 48 163 / 0.95) !important;
}

.tw-ring-offset-indigo-900 {
  --tw-ring-offset-color: #312e81 !important;
}

.tw-ring-offset-indigo-900\/0 {
  --tw-ring-offset-color: rgb(49 46 129 / 0) !important;
}

.tw-ring-offset-indigo-900\/10 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.1) !important;
}

.tw-ring-offset-indigo-900\/100 {
  --tw-ring-offset-color: rgb(49 46 129 / 1) !important;
}

.tw-ring-offset-indigo-900\/15 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.15) !important;
}

.tw-ring-offset-indigo-900\/20 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.2) !important;
}

.tw-ring-offset-indigo-900\/25 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.25) !important;
}

.tw-ring-offset-indigo-900\/30 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.3) !important;
}

.tw-ring-offset-indigo-900\/35 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.35) !important;
}

.tw-ring-offset-indigo-900\/40 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.4) !important;
}

.tw-ring-offset-indigo-900\/45 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.45) !important;
}

.tw-ring-offset-indigo-900\/5 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.05) !important;
}

.tw-ring-offset-indigo-900\/50 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.5) !important;
}

.tw-ring-offset-indigo-900\/55 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.55) !important;
}

.tw-ring-offset-indigo-900\/60 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.6) !important;
}

.tw-ring-offset-indigo-900\/65 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.65) !important;
}

.tw-ring-offset-indigo-900\/70 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.7) !important;
}

.tw-ring-offset-indigo-900\/75 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.75) !important;
}

.tw-ring-offset-indigo-900\/80 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.8) !important;
}

.tw-ring-offset-indigo-900\/85 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.85) !important;
}

.tw-ring-offset-indigo-900\/90 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.9) !important;
}

.tw-ring-offset-indigo-900\/95 {
  --tw-ring-offset-color: rgb(49 46 129 / 0.95) !important;
}

.tw-ring-offset-indigo-950 {
  --tw-ring-offset-color: #1e1b4b !important;
}

.tw-ring-offset-indigo-950\/0 {
  --tw-ring-offset-color: rgb(30 27 75 / 0) !important;
}

.tw-ring-offset-indigo-950\/10 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.1) !important;
}

.tw-ring-offset-indigo-950\/100 {
  --tw-ring-offset-color: rgb(30 27 75 / 1) !important;
}

.tw-ring-offset-indigo-950\/15 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.15) !important;
}

.tw-ring-offset-indigo-950\/20 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.2) !important;
}

.tw-ring-offset-indigo-950\/25 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.25) !important;
}

.tw-ring-offset-indigo-950\/30 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.3) !important;
}

.tw-ring-offset-indigo-950\/35 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.35) !important;
}

.tw-ring-offset-indigo-950\/40 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.4) !important;
}

.tw-ring-offset-indigo-950\/45 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.45) !important;
}

.tw-ring-offset-indigo-950\/5 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.05) !important;
}

.tw-ring-offset-indigo-950\/50 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.5) !important;
}

.tw-ring-offset-indigo-950\/55 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.55) !important;
}

.tw-ring-offset-indigo-950\/60 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.6) !important;
}

.tw-ring-offset-indigo-950\/65 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.65) !important;
}

.tw-ring-offset-indigo-950\/70 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.7) !important;
}

.tw-ring-offset-indigo-950\/75 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.75) !important;
}

.tw-ring-offset-indigo-950\/80 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.8) !important;
}

.tw-ring-offset-indigo-950\/85 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.85) !important;
}

.tw-ring-offset-indigo-950\/90 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.9) !important;
}

.tw-ring-offset-indigo-950\/95 {
  --tw-ring-offset-color: rgb(30 27 75 / 0.95) !important;
}

.tw-ring-offset-inherit {
  --tw-ring-offset-color: inherit !important;
}

.tw-ring-offset-lime-100 {
  --tw-ring-offset-color: #ecfccb !important;
}

.tw-ring-offset-lime-100\/0 {
  --tw-ring-offset-color: rgb(236 252 203 / 0) !important;
}

.tw-ring-offset-lime-100\/10 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.1) !important;
}

.tw-ring-offset-lime-100\/100 {
  --tw-ring-offset-color: rgb(236 252 203 / 1) !important;
}

.tw-ring-offset-lime-100\/15 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.15) !important;
}

.tw-ring-offset-lime-100\/20 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.2) !important;
}

.tw-ring-offset-lime-100\/25 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.25) !important;
}

.tw-ring-offset-lime-100\/30 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.3) !important;
}

.tw-ring-offset-lime-100\/35 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.35) !important;
}

.tw-ring-offset-lime-100\/40 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.4) !important;
}

.tw-ring-offset-lime-100\/45 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.45) !important;
}

.tw-ring-offset-lime-100\/5 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.05) !important;
}

.tw-ring-offset-lime-100\/50 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.5) !important;
}

.tw-ring-offset-lime-100\/55 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.55) !important;
}

.tw-ring-offset-lime-100\/60 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.6) !important;
}

.tw-ring-offset-lime-100\/65 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.65) !important;
}

.tw-ring-offset-lime-100\/70 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.7) !important;
}

.tw-ring-offset-lime-100\/75 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.75) !important;
}

.tw-ring-offset-lime-100\/80 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.8) !important;
}

.tw-ring-offset-lime-100\/85 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.85) !important;
}

.tw-ring-offset-lime-100\/90 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.9) !important;
}

.tw-ring-offset-lime-100\/95 {
  --tw-ring-offset-color: rgb(236 252 203 / 0.95) !important;
}

.tw-ring-offset-lime-200 {
  --tw-ring-offset-color: #d9f99d !important;
}

.tw-ring-offset-lime-200\/0 {
  --tw-ring-offset-color: rgb(217 249 157 / 0) !important;
}

.tw-ring-offset-lime-200\/10 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.1) !important;
}

.tw-ring-offset-lime-200\/100 {
  --tw-ring-offset-color: rgb(217 249 157 / 1) !important;
}

.tw-ring-offset-lime-200\/15 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.15) !important;
}

.tw-ring-offset-lime-200\/20 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.2) !important;
}

.tw-ring-offset-lime-200\/25 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.25) !important;
}

.tw-ring-offset-lime-200\/30 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.3) !important;
}

.tw-ring-offset-lime-200\/35 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.35) !important;
}

.tw-ring-offset-lime-200\/40 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.4) !important;
}

.tw-ring-offset-lime-200\/45 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.45) !important;
}

.tw-ring-offset-lime-200\/5 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.05) !important;
}

.tw-ring-offset-lime-200\/50 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.5) !important;
}

.tw-ring-offset-lime-200\/55 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.55) !important;
}

.tw-ring-offset-lime-200\/60 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.6) !important;
}

.tw-ring-offset-lime-200\/65 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.65) !important;
}

.tw-ring-offset-lime-200\/70 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.7) !important;
}

.tw-ring-offset-lime-200\/75 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.75) !important;
}

.tw-ring-offset-lime-200\/80 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.8) !important;
}

.tw-ring-offset-lime-200\/85 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.85) !important;
}

.tw-ring-offset-lime-200\/90 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.9) !important;
}

.tw-ring-offset-lime-200\/95 {
  --tw-ring-offset-color: rgb(217 249 157 / 0.95) !important;
}

.tw-ring-offset-lime-300 {
  --tw-ring-offset-color: #bef264 !important;
}

.tw-ring-offset-lime-300\/0 {
  --tw-ring-offset-color: rgb(190 242 100 / 0) !important;
}

.tw-ring-offset-lime-300\/10 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.1) !important;
}

.tw-ring-offset-lime-300\/100 {
  --tw-ring-offset-color: rgb(190 242 100 / 1) !important;
}

.tw-ring-offset-lime-300\/15 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.15) !important;
}

.tw-ring-offset-lime-300\/20 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.2) !important;
}

.tw-ring-offset-lime-300\/25 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.25) !important;
}

.tw-ring-offset-lime-300\/30 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.3) !important;
}

.tw-ring-offset-lime-300\/35 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.35) !important;
}

.tw-ring-offset-lime-300\/40 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.4) !important;
}

.tw-ring-offset-lime-300\/45 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.45) !important;
}

.tw-ring-offset-lime-300\/5 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.05) !important;
}

.tw-ring-offset-lime-300\/50 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.5) !important;
}

.tw-ring-offset-lime-300\/55 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.55) !important;
}

.tw-ring-offset-lime-300\/60 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.6) !important;
}

.tw-ring-offset-lime-300\/65 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.65) !important;
}

.tw-ring-offset-lime-300\/70 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.7) !important;
}

.tw-ring-offset-lime-300\/75 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.75) !important;
}

.tw-ring-offset-lime-300\/80 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.8) !important;
}

.tw-ring-offset-lime-300\/85 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.85) !important;
}

.tw-ring-offset-lime-300\/90 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.9) !important;
}

.tw-ring-offset-lime-300\/95 {
  --tw-ring-offset-color: rgb(190 242 100 / 0.95) !important;
}

.tw-ring-offset-lime-400 {
  --tw-ring-offset-color: #a3e635 !important;
}

.tw-ring-offset-lime-400\/0 {
  --tw-ring-offset-color: rgb(163 230 53 / 0) !important;
}

.tw-ring-offset-lime-400\/10 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.1) !important;
}

.tw-ring-offset-lime-400\/100 {
  --tw-ring-offset-color: rgb(163 230 53 / 1) !important;
}

.tw-ring-offset-lime-400\/15 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.15) !important;
}

.tw-ring-offset-lime-400\/20 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.2) !important;
}

.tw-ring-offset-lime-400\/25 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.25) !important;
}

.tw-ring-offset-lime-400\/30 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.3) !important;
}

.tw-ring-offset-lime-400\/35 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.35) !important;
}

.tw-ring-offset-lime-400\/40 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.4) !important;
}

.tw-ring-offset-lime-400\/45 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.45) !important;
}

.tw-ring-offset-lime-400\/5 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.05) !important;
}

.tw-ring-offset-lime-400\/50 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.5) !important;
}

.tw-ring-offset-lime-400\/55 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.55) !important;
}

.tw-ring-offset-lime-400\/60 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.6) !important;
}

.tw-ring-offset-lime-400\/65 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.65) !important;
}

.tw-ring-offset-lime-400\/70 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.7) !important;
}

.tw-ring-offset-lime-400\/75 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.75) !important;
}

.tw-ring-offset-lime-400\/80 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.8) !important;
}

.tw-ring-offset-lime-400\/85 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.85) !important;
}

.tw-ring-offset-lime-400\/90 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.9) !important;
}

.tw-ring-offset-lime-400\/95 {
  --tw-ring-offset-color: rgb(163 230 53 / 0.95) !important;
}

.tw-ring-offset-lime-50 {
  --tw-ring-offset-color: #f7fee7 !important;
}

.tw-ring-offset-lime-50\/0 {
  --tw-ring-offset-color: rgb(247 254 231 / 0) !important;
}

.tw-ring-offset-lime-50\/10 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.1) !important;
}

.tw-ring-offset-lime-50\/100 {
  --tw-ring-offset-color: rgb(247 254 231 / 1) !important;
}

.tw-ring-offset-lime-50\/15 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.15) !important;
}

.tw-ring-offset-lime-50\/20 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.2) !important;
}

.tw-ring-offset-lime-50\/25 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.25) !important;
}

.tw-ring-offset-lime-50\/30 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.3) !important;
}

.tw-ring-offset-lime-50\/35 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.35) !important;
}

.tw-ring-offset-lime-50\/40 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.4) !important;
}

.tw-ring-offset-lime-50\/45 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.45) !important;
}

.tw-ring-offset-lime-50\/5 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.05) !important;
}

.tw-ring-offset-lime-50\/50 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.5) !important;
}

.tw-ring-offset-lime-50\/55 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.55) !important;
}

.tw-ring-offset-lime-50\/60 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.6) !important;
}

.tw-ring-offset-lime-50\/65 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.65) !important;
}

.tw-ring-offset-lime-50\/70 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.7) !important;
}

.tw-ring-offset-lime-50\/75 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.75) !important;
}

.tw-ring-offset-lime-50\/80 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.8) !important;
}

.tw-ring-offset-lime-50\/85 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.85) !important;
}

.tw-ring-offset-lime-50\/90 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.9) !important;
}

.tw-ring-offset-lime-50\/95 {
  --tw-ring-offset-color: rgb(247 254 231 / 0.95) !important;
}

.tw-ring-offset-lime-500 {
  --tw-ring-offset-color: #84cc16 !important;
}

.tw-ring-offset-lime-500\/0 {
  --tw-ring-offset-color: rgb(132 204 22 / 0) !important;
}

.tw-ring-offset-lime-500\/10 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.1) !important;
}

.tw-ring-offset-lime-500\/100 {
  --tw-ring-offset-color: rgb(132 204 22 / 1) !important;
}

.tw-ring-offset-lime-500\/15 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.15) !important;
}

.tw-ring-offset-lime-500\/20 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.2) !important;
}

.tw-ring-offset-lime-500\/25 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.25) !important;
}

.tw-ring-offset-lime-500\/30 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.3) !important;
}

.tw-ring-offset-lime-500\/35 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.35) !important;
}

.tw-ring-offset-lime-500\/40 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.4) !important;
}

.tw-ring-offset-lime-500\/45 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.45) !important;
}

.tw-ring-offset-lime-500\/5 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.05) !important;
}

.tw-ring-offset-lime-500\/50 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.5) !important;
}

.tw-ring-offset-lime-500\/55 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.55) !important;
}

.tw-ring-offset-lime-500\/60 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.6) !important;
}

.tw-ring-offset-lime-500\/65 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.65) !important;
}

.tw-ring-offset-lime-500\/70 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.7) !important;
}

.tw-ring-offset-lime-500\/75 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.75) !important;
}

.tw-ring-offset-lime-500\/80 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.8) !important;
}

.tw-ring-offset-lime-500\/85 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.85) !important;
}

.tw-ring-offset-lime-500\/90 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.9) !important;
}

.tw-ring-offset-lime-500\/95 {
  --tw-ring-offset-color: rgb(132 204 22 / 0.95) !important;
}

.tw-ring-offset-lime-600 {
  --tw-ring-offset-color: #65a30d !important;
}

.tw-ring-offset-lime-600\/0 {
  --tw-ring-offset-color: rgb(101 163 13 / 0) !important;
}

.tw-ring-offset-lime-600\/10 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.1) !important;
}

.tw-ring-offset-lime-600\/100 {
  --tw-ring-offset-color: rgb(101 163 13 / 1) !important;
}

.tw-ring-offset-lime-600\/15 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.15) !important;
}

.tw-ring-offset-lime-600\/20 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.2) !important;
}

.tw-ring-offset-lime-600\/25 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.25) !important;
}

.tw-ring-offset-lime-600\/30 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.3) !important;
}

.tw-ring-offset-lime-600\/35 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.35) !important;
}

.tw-ring-offset-lime-600\/40 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.4) !important;
}

.tw-ring-offset-lime-600\/45 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.45) !important;
}

.tw-ring-offset-lime-600\/5 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.05) !important;
}

.tw-ring-offset-lime-600\/50 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.5) !important;
}

.tw-ring-offset-lime-600\/55 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.55) !important;
}

.tw-ring-offset-lime-600\/60 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.6) !important;
}

.tw-ring-offset-lime-600\/65 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.65) !important;
}

.tw-ring-offset-lime-600\/70 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.7) !important;
}

.tw-ring-offset-lime-600\/75 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.75) !important;
}

.tw-ring-offset-lime-600\/80 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.8) !important;
}

.tw-ring-offset-lime-600\/85 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.85) !important;
}

.tw-ring-offset-lime-600\/90 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.9) !important;
}

.tw-ring-offset-lime-600\/95 {
  --tw-ring-offset-color: rgb(101 163 13 / 0.95) !important;
}

.tw-ring-offset-lime-700 {
  --tw-ring-offset-color: #4d7c0f !important;
}

.tw-ring-offset-lime-700\/0 {
  --tw-ring-offset-color: rgb(77 124 15 / 0) !important;
}

.tw-ring-offset-lime-700\/10 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.1) !important;
}

.tw-ring-offset-lime-700\/100 {
  --tw-ring-offset-color: rgb(77 124 15 / 1) !important;
}

.tw-ring-offset-lime-700\/15 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.15) !important;
}

.tw-ring-offset-lime-700\/20 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.2) !important;
}

.tw-ring-offset-lime-700\/25 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.25) !important;
}

.tw-ring-offset-lime-700\/30 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.3) !important;
}

.tw-ring-offset-lime-700\/35 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.35) !important;
}

.tw-ring-offset-lime-700\/40 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.4) !important;
}

.tw-ring-offset-lime-700\/45 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.45) !important;
}

.tw-ring-offset-lime-700\/5 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.05) !important;
}

.tw-ring-offset-lime-700\/50 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.5) !important;
}

.tw-ring-offset-lime-700\/55 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.55) !important;
}

.tw-ring-offset-lime-700\/60 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.6) !important;
}

.tw-ring-offset-lime-700\/65 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.65) !important;
}

.tw-ring-offset-lime-700\/70 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.7) !important;
}

.tw-ring-offset-lime-700\/75 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.75) !important;
}

.tw-ring-offset-lime-700\/80 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.8) !important;
}

.tw-ring-offset-lime-700\/85 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.85) !important;
}

.tw-ring-offset-lime-700\/90 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.9) !important;
}

.tw-ring-offset-lime-700\/95 {
  --tw-ring-offset-color: rgb(77 124 15 / 0.95) !important;
}

.tw-ring-offset-lime-800 {
  --tw-ring-offset-color: #3f6212 !important;
}

.tw-ring-offset-lime-800\/0 {
  --tw-ring-offset-color: rgb(63 98 18 / 0) !important;
}

.tw-ring-offset-lime-800\/10 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.1) !important;
}

.tw-ring-offset-lime-800\/100 {
  --tw-ring-offset-color: rgb(63 98 18 / 1) !important;
}

.tw-ring-offset-lime-800\/15 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.15) !important;
}

.tw-ring-offset-lime-800\/20 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.2) !important;
}

.tw-ring-offset-lime-800\/25 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.25) !important;
}

.tw-ring-offset-lime-800\/30 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.3) !important;
}

.tw-ring-offset-lime-800\/35 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.35) !important;
}

.tw-ring-offset-lime-800\/40 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.4) !important;
}

.tw-ring-offset-lime-800\/45 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.45) !important;
}

.tw-ring-offset-lime-800\/5 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.05) !important;
}

.tw-ring-offset-lime-800\/50 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.5) !important;
}

.tw-ring-offset-lime-800\/55 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.55) !important;
}

.tw-ring-offset-lime-800\/60 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.6) !important;
}

.tw-ring-offset-lime-800\/65 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.65) !important;
}

.tw-ring-offset-lime-800\/70 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.7) !important;
}

.tw-ring-offset-lime-800\/75 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.75) !important;
}

.tw-ring-offset-lime-800\/80 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.8) !important;
}

.tw-ring-offset-lime-800\/85 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.85) !important;
}

.tw-ring-offset-lime-800\/90 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.9) !important;
}

.tw-ring-offset-lime-800\/95 {
  --tw-ring-offset-color: rgb(63 98 18 / 0.95) !important;
}

.tw-ring-offset-lime-900 {
  --tw-ring-offset-color: #365314 !important;
}

.tw-ring-offset-lime-900\/0 {
  --tw-ring-offset-color: rgb(54 83 20 / 0) !important;
}

.tw-ring-offset-lime-900\/10 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.1) !important;
}

.tw-ring-offset-lime-900\/100 {
  --tw-ring-offset-color: rgb(54 83 20 / 1) !important;
}

.tw-ring-offset-lime-900\/15 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.15) !important;
}

.tw-ring-offset-lime-900\/20 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.2) !important;
}

.tw-ring-offset-lime-900\/25 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.25) !important;
}

.tw-ring-offset-lime-900\/30 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.3) !important;
}

.tw-ring-offset-lime-900\/35 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.35) !important;
}

.tw-ring-offset-lime-900\/40 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.4) !important;
}

.tw-ring-offset-lime-900\/45 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.45) !important;
}

.tw-ring-offset-lime-900\/5 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.05) !important;
}

.tw-ring-offset-lime-900\/50 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.5) !important;
}

.tw-ring-offset-lime-900\/55 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.55) !important;
}

.tw-ring-offset-lime-900\/60 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.6) !important;
}

.tw-ring-offset-lime-900\/65 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.65) !important;
}

.tw-ring-offset-lime-900\/70 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.7) !important;
}

.tw-ring-offset-lime-900\/75 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.75) !important;
}

.tw-ring-offset-lime-900\/80 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.8) !important;
}

.tw-ring-offset-lime-900\/85 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.85) !important;
}

.tw-ring-offset-lime-900\/90 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.9) !important;
}

.tw-ring-offset-lime-900\/95 {
  --tw-ring-offset-color: rgb(54 83 20 / 0.95) !important;
}

.tw-ring-offset-lime-950 {
  --tw-ring-offset-color: #1a2e05 !important;
}

.tw-ring-offset-lime-950\/0 {
  --tw-ring-offset-color: rgb(26 46 5 / 0) !important;
}

.tw-ring-offset-lime-950\/10 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.1) !important;
}

.tw-ring-offset-lime-950\/100 {
  --tw-ring-offset-color: rgb(26 46 5 / 1) !important;
}

.tw-ring-offset-lime-950\/15 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.15) !important;
}

.tw-ring-offset-lime-950\/20 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.2) !important;
}

.tw-ring-offset-lime-950\/25 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.25) !important;
}

.tw-ring-offset-lime-950\/30 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.3) !important;
}

.tw-ring-offset-lime-950\/35 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.35) !important;
}

.tw-ring-offset-lime-950\/40 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.4) !important;
}

.tw-ring-offset-lime-950\/45 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.45) !important;
}

.tw-ring-offset-lime-950\/5 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.05) !important;
}

.tw-ring-offset-lime-950\/50 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.5) !important;
}

.tw-ring-offset-lime-950\/55 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.55) !important;
}

.tw-ring-offset-lime-950\/60 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.6) !important;
}

.tw-ring-offset-lime-950\/65 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.65) !important;
}

.tw-ring-offset-lime-950\/70 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.7) !important;
}

.tw-ring-offset-lime-950\/75 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.75) !important;
}

.tw-ring-offset-lime-950\/80 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.8) !important;
}

.tw-ring-offset-lime-950\/85 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.85) !important;
}

.tw-ring-offset-lime-950\/90 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.9) !important;
}

.tw-ring-offset-lime-950\/95 {
  --tw-ring-offset-color: rgb(26 46 5 / 0.95) !important;
}

.tw-ring-offset-neutral-100 {
  --tw-ring-offset-color: #f5f5f5 !important;
}

.tw-ring-offset-neutral-100\/0 {
  --tw-ring-offset-color: rgb(245 245 245 / 0) !important;
}

.tw-ring-offset-neutral-100\/10 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.1) !important;
}

.tw-ring-offset-neutral-100\/100 {
  --tw-ring-offset-color: rgb(245 245 245 / 1) !important;
}

.tw-ring-offset-neutral-100\/15 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.15) !important;
}

.tw-ring-offset-neutral-100\/20 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.2) !important;
}

.tw-ring-offset-neutral-100\/25 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.25) !important;
}

.tw-ring-offset-neutral-100\/30 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.3) !important;
}

.tw-ring-offset-neutral-100\/35 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.35) !important;
}

.tw-ring-offset-neutral-100\/40 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.4) !important;
}

.tw-ring-offset-neutral-100\/45 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.45) !important;
}

.tw-ring-offset-neutral-100\/5 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.05) !important;
}

.tw-ring-offset-neutral-100\/50 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.5) !important;
}

.tw-ring-offset-neutral-100\/55 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.55) !important;
}

.tw-ring-offset-neutral-100\/60 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.6) !important;
}

.tw-ring-offset-neutral-100\/65 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.65) !important;
}

.tw-ring-offset-neutral-100\/70 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.7) !important;
}

.tw-ring-offset-neutral-100\/75 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.75) !important;
}

.tw-ring-offset-neutral-100\/80 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.8) !important;
}

.tw-ring-offset-neutral-100\/85 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.85) !important;
}

.tw-ring-offset-neutral-100\/90 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.9) !important;
}

.tw-ring-offset-neutral-100\/95 {
  --tw-ring-offset-color: rgb(245 245 245 / 0.95) !important;
}

.tw-ring-offset-neutral-200 {
  --tw-ring-offset-color: #e5e5e5 !important;
}

.tw-ring-offset-neutral-200\/0 {
  --tw-ring-offset-color: rgb(229 229 229 / 0) !important;
}

.tw-ring-offset-neutral-200\/10 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.1) !important;
}

.tw-ring-offset-neutral-200\/100 {
  --tw-ring-offset-color: rgb(229 229 229 / 1) !important;
}

.tw-ring-offset-neutral-200\/15 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.15) !important;
}

.tw-ring-offset-neutral-200\/20 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.2) !important;
}

.tw-ring-offset-neutral-200\/25 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.25) !important;
}

.tw-ring-offset-neutral-200\/30 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.3) !important;
}

.tw-ring-offset-neutral-200\/35 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.35) !important;
}

.tw-ring-offset-neutral-200\/40 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.4) !important;
}

.tw-ring-offset-neutral-200\/45 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.45) !important;
}

.tw-ring-offset-neutral-200\/5 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.05) !important;
}

.tw-ring-offset-neutral-200\/50 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.5) !important;
}

.tw-ring-offset-neutral-200\/55 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.55) !important;
}

.tw-ring-offset-neutral-200\/60 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.6) !important;
}

.tw-ring-offset-neutral-200\/65 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.65) !important;
}

.tw-ring-offset-neutral-200\/70 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.7) !important;
}

.tw-ring-offset-neutral-200\/75 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.75) !important;
}

.tw-ring-offset-neutral-200\/80 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.8) !important;
}

.tw-ring-offset-neutral-200\/85 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.85) !important;
}

.tw-ring-offset-neutral-200\/90 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.9) !important;
}

.tw-ring-offset-neutral-200\/95 {
  --tw-ring-offset-color: rgb(229 229 229 / 0.95) !important;
}

.tw-ring-offset-neutral-300 {
  --tw-ring-offset-color: #d4d4d4 !important;
}

.tw-ring-offset-neutral-300\/0 {
  --tw-ring-offset-color: rgb(212 212 212 / 0) !important;
}

.tw-ring-offset-neutral-300\/10 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.1) !important;
}

.tw-ring-offset-neutral-300\/100 {
  --tw-ring-offset-color: rgb(212 212 212 / 1) !important;
}

.tw-ring-offset-neutral-300\/15 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.15) !important;
}

.tw-ring-offset-neutral-300\/20 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.2) !important;
}

.tw-ring-offset-neutral-300\/25 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.25) !important;
}

.tw-ring-offset-neutral-300\/30 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.3) !important;
}

.tw-ring-offset-neutral-300\/35 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.35) !important;
}

.tw-ring-offset-neutral-300\/40 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.4) !important;
}

.tw-ring-offset-neutral-300\/45 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.45) !important;
}

.tw-ring-offset-neutral-300\/5 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.05) !important;
}

.tw-ring-offset-neutral-300\/50 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.5) !important;
}

.tw-ring-offset-neutral-300\/55 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.55) !important;
}

.tw-ring-offset-neutral-300\/60 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.6) !important;
}

.tw-ring-offset-neutral-300\/65 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.65) !important;
}

.tw-ring-offset-neutral-300\/70 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.7) !important;
}

.tw-ring-offset-neutral-300\/75 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.75) !important;
}

.tw-ring-offset-neutral-300\/80 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.8) !important;
}

.tw-ring-offset-neutral-300\/85 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.85) !important;
}

.tw-ring-offset-neutral-300\/90 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.9) !important;
}

.tw-ring-offset-neutral-300\/95 {
  --tw-ring-offset-color: rgb(212 212 212 / 0.95) !important;
}

.tw-ring-offset-neutral-400 {
  --tw-ring-offset-color: #a3a3a3 !important;
}

.tw-ring-offset-neutral-400\/0 {
  --tw-ring-offset-color: rgb(163 163 163 / 0) !important;
}

.tw-ring-offset-neutral-400\/10 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.1) !important;
}

.tw-ring-offset-neutral-400\/100 {
  --tw-ring-offset-color: rgb(163 163 163 / 1) !important;
}

.tw-ring-offset-neutral-400\/15 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.15) !important;
}

.tw-ring-offset-neutral-400\/20 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.2) !important;
}

.tw-ring-offset-neutral-400\/25 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.25) !important;
}

.tw-ring-offset-neutral-400\/30 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.3) !important;
}

.tw-ring-offset-neutral-400\/35 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.35) !important;
}

.tw-ring-offset-neutral-400\/40 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.4) !important;
}

.tw-ring-offset-neutral-400\/45 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.45) !important;
}

.tw-ring-offset-neutral-400\/5 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.05) !important;
}

.tw-ring-offset-neutral-400\/50 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.5) !important;
}

.tw-ring-offset-neutral-400\/55 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.55) !important;
}

.tw-ring-offset-neutral-400\/60 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.6) !important;
}

.tw-ring-offset-neutral-400\/65 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.65) !important;
}

.tw-ring-offset-neutral-400\/70 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.7) !important;
}

.tw-ring-offset-neutral-400\/75 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.75) !important;
}

.tw-ring-offset-neutral-400\/80 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.8) !important;
}

.tw-ring-offset-neutral-400\/85 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.85) !important;
}

.tw-ring-offset-neutral-400\/90 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.9) !important;
}

.tw-ring-offset-neutral-400\/95 {
  --tw-ring-offset-color: rgb(163 163 163 / 0.95) !important;
}

.tw-ring-offset-neutral-50 {
  --tw-ring-offset-color: #fafafa !important;
}

.tw-ring-offset-neutral-50\/0 {
  --tw-ring-offset-color: rgb(250 250 250 / 0) !important;
}

.tw-ring-offset-neutral-50\/10 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.1) !important;
}

.tw-ring-offset-neutral-50\/100 {
  --tw-ring-offset-color: rgb(250 250 250 / 1) !important;
}

.tw-ring-offset-neutral-50\/15 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.15) !important;
}

.tw-ring-offset-neutral-50\/20 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.2) !important;
}

.tw-ring-offset-neutral-50\/25 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.25) !important;
}

.tw-ring-offset-neutral-50\/30 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.3) !important;
}

.tw-ring-offset-neutral-50\/35 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.35) !important;
}

.tw-ring-offset-neutral-50\/40 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.4) !important;
}

.tw-ring-offset-neutral-50\/45 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.45) !important;
}

.tw-ring-offset-neutral-50\/5 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.05) !important;
}

.tw-ring-offset-neutral-50\/50 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.5) !important;
}

.tw-ring-offset-neutral-50\/55 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.55) !important;
}

.tw-ring-offset-neutral-50\/60 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.6) !important;
}

.tw-ring-offset-neutral-50\/65 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.65) !important;
}

.tw-ring-offset-neutral-50\/70 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.7) !important;
}

.tw-ring-offset-neutral-50\/75 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.75) !important;
}

.tw-ring-offset-neutral-50\/80 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.8) !important;
}

.tw-ring-offset-neutral-50\/85 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.85) !important;
}

.tw-ring-offset-neutral-50\/90 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.9) !important;
}

.tw-ring-offset-neutral-50\/95 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.95) !important;
}

.tw-ring-offset-neutral-500 {
  --tw-ring-offset-color: #737373 !important;
}

.tw-ring-offset-neutral-500\/0 {
  --tw-ring-offset-color: rgb(115 115 115 / 0) !important;
}

.tw-ring-offset-neutral-500\/10 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.1) !important;
}

.tw-ring-offset-neutral-500\/100 {
  --tw-ring-offset-color: rgb(115 115 115 / 1) !important;
}

.tw-ring-offset-neutral-500\/15 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.15) !important;
}

.tw-ring-offset-neutral-500\/20 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.2) !important;
}

.tw-ring-offset-neutral-500\/25 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.25) !important;
}

.tw-ring-offset-neutral-500\/30 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.3) !important;
}

.tw-ring-offset-neutral-500\/35 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.35) !important;
}

.tw-ring-offset-neutral-500\/40 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.4) !important;
}

.tw-ring-offset-neutral-500\/45 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.45) !important;
}

.tw-ring-offset-neutral-500\/5 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.05) !important;
}

.tw-ring-offset-neutral-500\/50 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.5) !important;
}

.tw-ring-offset-neutral-500\/55 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.55) !important;
}

.tw-ring-offset-neutral-500\/60 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.6) !important;
}

.tw-ring-offset-neutral-500\/65 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.65) !important;
}

.tw-ring-offset-neutral-500\/70 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.7) !important;
}

.tw-ring-offset-neutral-500\/75 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.75) !important;
}

.tw-ring-offset-neutral-500\/80 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.8) !important;
}

.tw-ring-offset-neutral-500\/85 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.85) !important;
}

.tw-ring-offset-neutral-500\/90 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.9) !important;
}

.tw-ring-offset-neutral-500\/95 {
  --tw-ring-offset-color: rgb(115 115 115 / 0.95) !important;
}

.tw-ring-offset-neutral-600 {
  --tw-ring-offset-color: #525252 !important;
}

.tw-ring-offset-neutral-600\/0 {
  --tw-ring-offset-color: rgb(82 82 82 / 0) !important;
}

.tw-ring-offset-neutral-600\/10 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.1) !important;
}

.tw-ring-offset-neutral-600\/100 {
  --tw-ring-offset-color: rgb(82 82 82 / 1) !important;
}

.tw-ring-offset-neutral-600\/15 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.15) !important;
}

.tw-ring-offset-neutral-600\/20 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.2) !important;
}

.tw-ring-offset-neutral-600\/25 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.25) !important;
}

.tw-ring-offset-neutral-600\/30 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.3) !important;
}

.tw-ring-offset-neutral-600\/35 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.35) !important;
}

.tw-ring-offset-neutral-600\/40 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.4) !important;
}

.tw-ring-offset-neutral-600\/45 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.45) !important;
}

.tw-ring-offset-neutral-600\/5 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.05) !important;
}

.tw-ring-offset-neutral-600\/50 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.5) !important;
}

.tw-ring-offset-neutral-600\/55 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.55) !important;
}

.tw-ring-offset-neutral-600\/60 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.6) !important;
}

.tw-ring-offset-neutral-600\/65 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.65) !important;
}

.tw-ring-offset-neutral-600\/70 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.7) !important;
}

.tw-ring-offset-neutral-600\/75 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.75) !important;
}

.tw-ring-offset-neutral-600\/80 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.8) !important;
}

.tw-ring-offset-neutral-600\/85 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.85) !important;
}

.tw-ring-offset-neutral-600\/90 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.9) !important;
}

.tw-ring-offset-neutral-600\/95 {
  --tw-ring-offset-color: rgb(82 82 82 / 0.95) !important;
}

.tw-ring-offset-neutral-700 {
  --tw-ring-offset-color: #404040 !important;
}

.tw-ring-offset-neutral-700\/0 {
  --tw-ring-offset-color: rgb(64 64 64 / 0) !important;
}

.tw-ring-offset-neutral-700\/10 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.1) !important;
}

.tw-ring-offset-neutral-700\/100 {
  --tw-ring-offset-color: rgb(64 64 64 / 1) !important;
}

.tw-ring-offset-neutral-700\/15 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.15) !important;
}

.tw-ring-offset-neutral-700\/20 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.2) !important;
}

.tw-ring-offset-neutral-700\/25 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.25) !important;
}

.tw-ring-offset-neutral-700\/30 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.3) !important;
}

.tw-ring-offset-neutral-700\/35 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.35) !important;
}

.tw-ring-offset-neutral-700\/40 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.4) !important;
}

.tw-ring-offset-neutral-700\/45 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.45) !important;
}

.tw-ring-offset-neutral-700\/5 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.05) !important;
}

.tw-ring-offset-neutral-700\/50 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.5) !important;
}

.tw-ring-offset-neutral-700\/55 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.55) !important;
}

.tw-ring-offset-neutral-700\/60 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.6) !important;
}

.tw-ring-offset-neutral-700\/65 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.65) !important;
}

.tw-ring-offset-neutral-700\/70 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.7) !important;
}

.tw-ring-offset-neutral-700\/75 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.75) !important;
}

.tw-ring-offset-neutral-700\/80 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.8) !important;
}

.tw-ring-offset-neutral-700\/85 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.85) !important;
}

.tw-ring-offset-neutral-700\/90 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.9) !important;
}

.tw-ring-offset-neutral-700\/95 {
  --tw-ring-offset-color: rgb(64 64 64 / 0.95) !important;
}

.tw-ring-offset-neutral-800 {
  --tw-ring-offset-color: #262626 !important;
}

.tw-ring-offset-neutral-800\/0 {
  --tw-ring-offset-color: rgb(38 38 38 / 0) !important;
}

.tw-ring-offset-neutral-800\/10 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.1) !important;
}

.tw-ring-offset-neutral-800\/100 {
  --tw-ring-offset-color: rgb(38 38 38 / 1) !important;
}

.tw-ring-offset-neutral-800\/15 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.15) !important;
}

.tw-ring-offset-neutral-800\/20 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.2) !important;
}

.tw-ring-offset-neutral-800\/25 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.25) !important;
}

.tw-ring-offset-neutral-800\/30 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.3) !important;
}

.tw-ring-offset-neutral-800\/35 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.35) !important;
}

.tw-ring-offset-neutral-800\/40 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.4) !important;
}

.tw-ring-offset-neutral-800\/45 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.45) !important;
}

.tw-ring-offset-neutral-800\/5 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.05) !important;
}

.tw-ring-offset-neutral-800\/50 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.5) !important;
}

.tw-ring-offset-neutral-800\/55 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.55) !important;
}

.tw-ring-offset-neutral-800\/60 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.6) !important;
}

.tw-ring-offset-neutral-800\/65 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.65) !important;
}

.tw-ring-offset-neutral-800\/70 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.7) !important;
}

.tw-ring-offset-neutral-800\/75 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.75) !important;
}

.tw-ring-offset-neutral-800\/80 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.8) !important;
}

.tw-ring-offset-neutral-800\/85 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.85) !important;
}

.tw-ring-offset-neutral-800\/90 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.9) !important;
}

.tw-ring-offset-neutral-800\/95 {
  --tw-ring-offset-color: rgb(38 38 38 / 0.95) !important;
}

.tw-ring-offset-neutral-900 {
  --tw-ring-offset-color: #171717 !important;
}

.tw-ring-offset-neutral-900\/0 {
  --tw-ring-offset-color: rgb(23 23 23 / 0) !important;
}

.tw-ring-offset-neutral-900\/10 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.1) !important;
}

.tw-ring-offset-neutral-900\/100 {
  --tw-ring-offset-color: rgb(23 23 23 / 1) !important;
}

.tw-ring-offset-neutral-900\/15 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.15) !important;
}

.tw-ring-offset-neutral-900\/20 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.2) !important;
}

.tw-ring-offset-neutral-900\/25 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.25) !important;
}

.tw-ring-offset-neutral-900\/30 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.3) !important;
}

.tw-ring-offset-neutral-900\/35 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.35) !important;
}

.tw-ring-offset-neutral-900\/40 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.4) !important;
}

.tw-ring-offset-neutral-900\/45 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.45) !important;
}

.tw-ring-offset-neutral-900\/5 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.05) !important;
}

.tw-ring-offset-neutral-900\/50 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.5) !important;
}

.tw-ring-offset-neutral-900\/55 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.55) !important;
}

.tw-ring-offset-neutral-900\/60 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.6) !important;
}

.tw-ring-offset-neutral-900\/65 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.65) !important;
}

.tw-ring-offset-neutral-900\/70 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.7) !important;
}

.tw-ring-offset-neutral-900\/75 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.75) !important;
}

.tw-ring-offset-neutral-900\/80 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.8) !important;
}

.tw-ring-offset-neutral-900\/85 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.85) !important;
}

.tw-ring-offset-neutral-900\/90 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.9) !important;
}

.tw-ring-offset-neutral-900\/95 {
  --tw-ring-offset-color: rgb(23 23 23 / 0.95) !important;
}

.tw-ring-offset-neutral-950 {
  --tw-ring-offset-color: #0a0a0a !important;
}

.tw-ring-offset-neutral-950\/0 {
  --tw-ring-offset-color: rgb(10 10 10 / 0) !important;
}

.tw-ring-offset-neutral-950\/10 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.1) !important;
}

.tw-ring-offset-neutral-950\/100 {
  --tw-ring-offset-color: rgb(10 10 10 / 1) !important;
}

.tw-ring-offset-neutral-950\/15 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.15) !important;
}

.tw-ring-offset-neutral-950\/20 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.2) !important;
}

.tw-ring-offset-neutral-950\/25 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.25) !important;
}

.tw-ring-offset-neutral-950\/30 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.3) !important;
}

.tw-ring-offset-neutral-950\/35 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.35) !important;
}

.tw-ring-offset-neutral-950\/40 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.4) !important;
}

.tw-ring-offset-neutral-950\/45 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.45) !important;
}

.tw-ring-offset-neutral-950\/5 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.05) !important;
}

.tw-ring-offset-neutral-950\/50 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.5) !important;
}

.tw-ring-offset-neutral-950\/55 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.55) !important;
}

.tw-ring-offset-neutral-950\/60 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.6) !important;
}

.tw-ring-offset-neutral-950\/65 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.65) !important;
}

.tw-ring-offset-neutral-950\/70 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.7) !important;
}

.tw-ring-offset-neutral-950\/75 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.75) !important;
}

.tw-ring-offset-neutral-950\/80 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.8) !important;
}

.tw-ring-offset-neutral-950\/85 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.85) !important;
}

.tw-ring-offset-neutral-950\/90 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.9) !important;
}

.tw-ring-offset-neutral-950\/95 {
  --tw-ring-offset-color: rgb(10 10 10 / 0.95) !important;
}

.tw-ring-offset-orange-100 {
  --tw-ring-offset-color: #ffedd5 !important;
}

.tw-ring-offset-orange-100\/0 {
  --tw-ring-offset-color: rgb(255 237 213 / 0) !important;
}

.tw-ring-offset-orange-100\/10 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.1) !important;
}

.tw-ring-offset-orange-100\/100 {
  --tw-ring-offset-color: rgb(255 237 213 / 1) !important;
}

.tw-ring-offset-orange-100\/15 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.15) !important;
}

.tw-ring-offset-orange-100\/20 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.2) !important;
}

.tw-ring-offset-orange-100\/25 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.25) !important;
}

.tw-ring-offset-orange-100\/30 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.3) !important;
}

.tw-ring-offset-orange-100\/35 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.35) !important;
}

.tw-ring-offset-orange-100\/40 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.4) !important;
}

.tw-ring-offset-orange-100\/45 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.45) !important;
}

.tw-ring-offset-orange-100\/5 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.05) !important;
}

.tw-ring-offset-orange-100\/50 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.5) !important;
}

.tw-ring-offset-orange-100\/55 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.55) !important;
}

.tw-ring-offset-orange-100\/60 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.6) !important;
}

.tw-ring-offset-orange-100\/65 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.65) !important;
}

.tw-ring-offset-orange-100\/70 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.7) !important;
}

.tw-ring-offset-orange-100\/75 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.75) !important;
}

.tw-ring-offset-orange-100\/80 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.8) !important;
}

.tw-ring-offset-orange-100\/85 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.85) !important;
}

.tw-ring-offset-orange-100\/90 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.9) !important;
}

.tw-ring-offset-orange-100\/95 {
  --tw-ring-offset-color: rgb(255 237 213 / 0.95) !important;
}

.tw-ring-offset-orange-200 {
  --tw-ring-offset-color: #fed7aa !important;
}

.tw-ring-offset-orange-200\/0 {
  --tw-ring-offset-color: rgb(254 215 170 / 0) !important;
}

.tw-ring-offset-orange-200\/10 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.1) !important;
}

.tw-ring-offset-orange-200\/100 {
  --tw-ring-offset-color: rgb(254 215 170 / 1) !important;
}

.tw-ring-offset-orange-200\/15 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.15) !important;
}

.tw-ring-offset-orange-200\/20 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.2) !important;
}

.tw-ring-offset-orange-200\/25 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.25) !important;
}

.tw-ring-offset-orange-200\/30 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.3) !important;
}

.tw-ring-offset-orange-200\/35 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.35) !important;
}

.tw-ring-offset-orange-200\/40 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.4) !important;
}

.tw-ring-offset-orange-200\/45 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.45) !important;
}

.tw-ring-offset-orange-200\/5 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.05) !important;
}

.tw-ring-offset-orange-200\/50 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.5) !important;
}

.tw-ring-offset-orange-200\/55 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.55) !important;
}

.tw-ring-offset-orange-200\/60 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.6) !important;
}

.tw-ring-offset-orange-200\/65 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.65) !important;
}

.tw-ring-offset-orange-200\/70 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.7) !important;
}

.tw-ring-offset-orange-200\/75 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.75) !important;
}

.tw-ring-offset-orange-200\/80 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.8) !important;
}

.tw-ring-offset-orange-200\/85 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.85) !important;
}

.tw-ring-offset-orange-200\/90 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.9) !important;
}

.tw-ring-offset-orange-200\/95 {
  --tw-ring-offset-color: rgb(254 215 170 / 0.95) !important;
}

.tw-ring-offset-orange-300 {
  --tw-ring-offset-color: #fdba74 !important;
}

.tw-ring-offset-orange-300\/0 {
  --tw-ring-offset-color: rgb(253 186 116 / 0) !important;
}

.tw-ring-offset-orange-300\/10 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.1) !important;
}

.tw-ring-offset-orange-300\/100 {
  --tw-ring-offset-color: rgb(253 186 116 / 1) !important;
}

.tw-ring-offset-orange-300\/15 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.15) !important;
}

.tw-ring-offset-orange-300\/20 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.2) !important;
}

.tw-ring-offset-orange-300\/25 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.25) !important;
}

.tw-ring-offset-orange-300\/30 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.3) !important;
}

.tw-ring-offset-orange-300\/35 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.35) !important;
}

.tw-ring-offset-orange-300\/40 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.4) !important;
}

.tw-ring-offset-orange-300\/45 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.45) !important;
}

.tw-ring-offset-orange-300\/5 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.05) !important;
}

.tw-ring-offset-orange-300\/50 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.5) !important;
}

.tw-ring-offset-orange-300\/55 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.55) !important;
}

.tw-ring-offset-orange-300\/60 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.6) !important;
}

.tw-ring-offset-orange-300\/65 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.65) !important;
}

.tw-ring-offset-orange-300\/70 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.7) !important;
}

.tw-ring-offset-orange-300\/75 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.75) !important;
}

.tw-ring-offset-orange-300\/80 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.8) !important;
}

.tw-ring-offset-orange-300\/85 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.85) !important;
}

.tw-ring-offset-orange-300\/90 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.9) !important;
}

.tw-ring-offset-orange-300\/95 {
  --tw-ring-offset-color: rgb(253 186 116 / 0.95) !important;
}

.tw-ring-offset-orange-400 {
  --tw-ring-offset-color: #fb923c !important;
}

.tw-ring-offset-orange-400\/0 {
  --tw-ring-offset-color: rgb(251 146 60 / 0) !important;
}

.tw-ring-offset-orange-400\/10 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.1) !important;
}

.tw-ring-offset-orange-400\/100 {
  --tw-ring-offset-color: rgb(251 146 60 / 1) !important;
}

.tw-ring-offset-orange-400\/15 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.15) !important;
}

.tw-ring-offset-orange-400\/20 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.2) !important;
}

.tw-ring-offset-orange-400\/25 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.25) !important;
}

.tw-ring-offset-orange-400\/30 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.3) !important;
}

.tw-ring-offset-orange-400\/35 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.35) !important;
}

.tw-ring-offset-orange-400\/40 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.4) !important;
}

.tw-ring-offset-orange-400\/45 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.45) !important;
}

.tw-ring-offset-orange-400\/5 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.05) !important;
}

.tw-ring-offset-orange-400\/50 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.5) !important;
}

.tw-ring-offset-orange-400\/55 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.55) !important;
}

.tw-ring-offset-orange-400\/60 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.6) !important;
}

.tw-ring-offset-orange-400\/65 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.65) !important;
}

.tw-ring-offset-orange-400\/70 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.7) !important;
}

.tw-ring-offset-orange-400\/75 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.75) !important;
}

.tw-ring-offset-orange-400\/80 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.8) !important;
}

.tw-ring-offset-orange-400\/85 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.85) !important;
}

.tw-ring-offset-orange-400\/90 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.9) !important;
}

.tw-ring-offset-orange-400\/95 {
  --tw-ring-offset-color: rgb(251 146 60 / 0.95) !important;
}

.tw-ring-offset-orange-50 {
  --tw-ring-offset-color: #fff7ed !important;
}

.tw-ring-offset-orange-50\/0 {
  --tw-ring-offset-color: rgb(255 247 237 / 0) !important;
}

.tw-ring-offset-orange-50\/10 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.1) !important;
}

.tw-ring-offset-orange-50\/100 {
  --tw-ring-offset-color: rgb(255 247 237 / 1) !important;
}

.tw-ring-offset-orange-50\/15 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.15) !important;
}

.tw-ring-offset-orange-50\/20 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.2) !important;
}

.tw-ring-offset-orange-50\/25 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.25) !important;
}

.tw-ring-offset-orange-50\/30 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.3) !important;
}

.tw-ring-offset-orange-50\/35 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.35) !important;
}

.tw-ring-offset-orange-50\/40 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.4) !important;
}

.tw-ring-offset-orange-50\/45 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.45) !important;
}

.tw-ring-offset-orange-50\/5 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.05) !important;
}

.tw-ring-offset-orange-50\/50 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.5) !important;
}

.tw-ring-offset-orange-50\/55 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.55) !important;
}

.tw-ring-offset-orange-50\/60 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.6) !important;
}

.tw-ring-offset-orange-50\/65 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.65) !important;
}

.tw-ring-offset-orange-50\/70 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.7) !important;
}

.tw-ring-offset-orange-50\/75 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.75) !important;
}

.tw-ring-offset-orange-50\/80 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.8) !important;
}

.tw-ring-offset-orange-50\/85 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.85) !important;
}

.tw-ring-offset-orange-50\/90 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.9) !important;
}

.tw-ring-offset-orange-50\/95 {
  --tw-ring-offset-color: rgb(255 247 237 / 0.95) !important;
}

.tw-ring-offset-orange-500 {
  --tw-ring-offset-color: #f97316 !important;
}

.tw-ring-offset-orange-500\/0 {
  --tw-ring-offset-color: rgb(249 115 22 / 0) !important;
}

.tw-ring-offset-orange-500\/10 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.1) !important;
}

.tw-ring-offset-orange-500\/100 {
  --tw-ring-offset-color: rgb(249 115 22 / 1) !important;
}

.tw-ring-offset-orange-500\/15 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.15) !important;
}

.tw-ring-offset-orange-500\/20 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.2) !important;
}

.tw-ring-offset-orange-500\/25 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.25) !important;
}

.tw-ring-offset-orange-500\/30 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.3) !important;
}

.tw-ring-offset-orange-500\/35 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.35) !important;
}

.tw-ring-offset-orange-500\/40 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.4) !important;
}

.tw-ring-offset-orange-500\/45 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.45) !important;
}

.tw-ring-offset-orange-500\/5 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.05) !important;
}

.tw-ring-offset-orange-500\/50 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.5) !important;
}

.tw-ring-offset-orange-500\/55 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.55) !important;
}

.tw-ring-offset-orange-500\/60 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.6) !important;
}

.tw-ring-offset-orange-500\/65 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.65) !important;
}

.tw-ring-offset-orange-500\/70 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.7) !important;
}

.tw-ring-offset-orange-500\/75 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.75) !important;
}

.tw-ring-offset-orange-500\/80 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.8) !important;
}

.tw-ring-offset-orange-500\/85 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.85) !important;
}

.tw-ring-offset-orange-500\/90 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.9) !important;
}

.tw-ring-offset-orange-500\/95 {
  --tw-ring-offset-color: rgb(249 115 22 / 0.95) !important;
}

.tw-ring-offset-orange-600 {
  --tw-ring-offset-color: #ea580c !important;
}

.tw-ring-offset-orange-600\/0 {
  --tw-ring-offset-color: rgb(234 88 12 / 0) !important;
}

.tw-ring-offset-orange-600\/10 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.1) !important;
}

.tw-ring-offset-orange-600\/100 {
  --tw-ring-offset-color: rgb(234 88 12 / 1) !important;
}

.tw-ring-offset-orange-600\/15 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.15) !important;
}

.tw-ring-offset-orange-600\/20 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.2) !important;
}

.tw-ring-offset-orange-600\/25 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.25) !important;
}

.tw-ring-offset-orange-600\/30 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.3) !important;
}

.tw-ring-offset-orange-600\/35 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.35) !important;
}

.tw-ring-offset-orange-600\/40 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.4) !important;
}

.tw-ring-offset-orange-600\/45 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.45) !important;
}

.tw-ring-offset-orange-600\/5 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.05) !important;
}

.tw-ring-offset-orange-600\/50 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.5) !important;
}

.tw-ring-offset-orange-600\/55 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.55) !important;
}

.tw-ring-offset-orange-600\/60 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.6) !important;
}

.tw-ring-offset-orange-600\/65 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.65) !important;
}

.tw-ring-offset-orange-600\/70 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.7) !important;
}

.tw-ring-offset-orange-600\/75 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.75) !important;
}

.tw-ring-offset-orange-600\/80 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.8) !important;
}

.tw-ring-offset-orange-600\/85 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.85) !important;
}

.tw-ring-offset-orange-600\/90 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.9) !important;
}

.tw-ring-offset-orange-600\/95 {
  --tw-ring-offset-color: rgb(234 88 12 / 0.95) !important;
}

.tw-ring-offset-orange-700 {
  --tw-ring-offset-color: #c2410c !important;
}

.tw-ring-offset-orange-700\/0 {
  --tw-ring-offset-color: rgb(194 65 12 / 0) !important;
}

.tw-ring-offset-orange-700\/10 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.1) !important;
}

.tw-ring-offset-orange-700\/100 {
  --tw-ring-offset-color: rgb(194 65 12 / 1) !important;
}

.tw-ring-offset-orange-700\/15 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.15) !important;
}

.tw-ring-offset-orange-700\/20 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.2) !important;
}

.tw-ring-offset-orange-700\/25 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.25) !important;
}

.tw-ring-offset-orange-700\/30 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.3) !important;
}

.tw-ring-offset-orange-700\/35 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.35) !important;
}

.tw-ring-offset-orange-700\/40 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.4) !important;
}

.tw-ring-offset-orange-700\/45 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.45) !important;
}

.tw-ring-offset-orange-700\/5 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.05) !important;
}

.tw-ring-offset-orange-700\/50 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.5) !important;
}

.tw-ring-offset-orange-700\/55 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.55) !important;
}

.tw-ring-offset-orange-700\/60 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.6) !important;
}

.tw-ring-offset-orange-700\/65 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.65) !important;
}

.tw-ring-offset-orange-700\/70 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.7) !important;
}

.tw-ring-offset-orange-700\/75 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.75) !important;
}

.tw-ring-offset-orange-700\/80 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.8) !important;
}

.tw-ring-offset-orange-700\/85 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.85) !important;
}

.tw-ring-offset-orange-700\/90 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.9) !important;
}

.tw-ring-offset-orange-700\/95 {
  --tw-ring-offset-color: rgb(194 65 12 / 0.95) !important;
}

.tw-ring-offset-orange-800 {
  --tw-ring-offset-color: #9a3412 !important;
}

.tw-ring-offset-orange-800\/0 {
  --tw-ring-offset-color: rgb(154 52 18 / 0) !important;
}

.tw-ring-offset-orange-800\/10 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.1) !important;
}

.tw-ring-offset-orange-800\/100 {
  --tw-ring-offset-color: rgb(154 52 18 / 1) !important;
}

.tw-ring-offset-orange-800\/15 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.15) !important;
}

.tw-ring-offset-orange-800\/20 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.2) !important;
}

.tw-ring-offset-orange-800\/25 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.25) !important;
}

.tw-ring-offset-orange-800\/30 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.3) !important;
}

.tw-ring-offset-orange-800\/35 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.35) !important;
}

.tw-ring-offset-orange-800\/40 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.4) !important;
}

.tw-ring-offset-orange-800\/45 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.45) !important;
}

.tw-ring-offset-orange-800\/5 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.05) !important;
}

.tw-ring-offset-orange-800\/50 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.5) !important;
}

.tw-ring-offset-orange-800\/55 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.55) !important;
}

.tw-ring-offset-orange-800\/60 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.6) !important;
}

.tw-ring-offset-orange-800\/65 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.65) !important;
}

.tw-ring-offset-orange-800\/70 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.7) !important;
}

.tw-ring-offset-orange-800\/75 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.75) !important;
}

.tw-ring-offset-orange-800\/80 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.8) !important;
}

.tw-ring-offset-orange-800\/85 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.85) !important;
}

.tw-ring-offset-orange-800\/90 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.9) !important;
}

.tw-ring-offset-orange-800\/95 {
  --tw-ring-offset-color: rgb(154 52 18 / 0.95) !important;
}

.tw-ring-offset-orange-900 {
  --tw-ring-offset-color: #7c2d12 !important;
}

.tw-ring-offset-orange-900\/0 {
  --tw-ring-offset-color: rgb(124 45 18 / 0) !important;
}

.tw-ring-offset-orange-900\/10 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.1) !important;
}

.tw-ring-offset-orange-900\/100 {
  --tw-ring-offset-color: rgb(124 45 18 / 1) !important;
}

.tw-ring-offset-orange-900\/15 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.15) !important;
}

.tw-ring-offset-orange-900\/20 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.2) !important;
}

.tw-ring-offset-orange-900\/25 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.25) !important;
}

.tw-ring-offset-orange-900\/30 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.3) !important;
}

.tw-ring-offset-orange-900\/35 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.35) !important;
}

.tw-ring-offset-orange-900\/40 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.4) !important;
}

.tw-ring-offset-orange-900\/45 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.45) !important;
}

.tw-ring-offset-orange-900\/5 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.05) !important;
}

.tw-ring-offset-orange-900\/50 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.5) !important;
}

.tw-ring-offset-orange-900\/55 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.55) !important;
}

.tw-ring-offset-orange-900\/60 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.6) !important;
}

.tw-ring-offset-orange-900\/65 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.65) !important;
}

.tw-ring-offset-orange-900\/70 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.7) !important;
}

.tw-ring-offset-orange-900\/75 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.75) !important;
}

.tw-ring-offset-orange-900\/80 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.8) !important;
}

.tw-ring-offset-orange-900\/85 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.85) !important;
}

.tw-ring-offset-orange-900\/90 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.9) !important;
}

.tw-ring-offset-orange-900\/95 {
  --tw-ring-offset-color: rgb(124 45 18 / 0.95) !important;
}

.tw-ring-offset-orange-950 {
  --tw-ring-offset-color: #431407 !important;
}

.tw-ring-offset-orange-950\/0 {
  --tw-ring-offset-color: rgb(67 20 7 / 0) !important;
}

.tw-ring-offset-orange-950\/10 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.1) !important;
}

.tw-ring-offset-orange-950\/100 {
  --tw-ring-offset-color: rgb(67 20 7 / 1) !important;
}

.tw-ring-offset-orange-950\/15 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.15) !important;
}

.tw-ring-offset-orange-950\/20 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.2) !important;
}

.tw-ring-offset-orange-950\/25 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.25) !important;
}

.tw-ring-offset-orange-950\/30 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.3) !important;
}

.tw-ring-offset-orange-950\/35 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.35) !important;
}

.tw-ring-offset-orange-950\/40 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.4) !important;
}

.tw-ring-offset-orange-950\/45 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.45) !important;
}

.tw-ring-offset-orange-950\/5 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.05) !important;
}

.tw-ring-offset-orange-950\/50 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.5) !important;
}

.tw-ring-offset-orange-950\/55 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.55) !important;
}

.tw-ring-offset-orange-950\/60 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.6) !important;
}

.tw-ring-offset-orange-950\/65 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.65) !important;
}

.tw-ring-offset-orange-950\/70 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.7) !important;
}

.tw-ring-offset-orange-950\/75 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.75) !important;
}

.tw-ring-offset-orange-950\/80 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.8) !important;
}

.tw-ring-offset-orange-950\/85 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.85) !important;
}

.tw-ring-offset-orange-950\/90 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.9) !important;
}

.tw-ring-offset-orange-950\/95 {
  --tw-ring-offset-color: rgb(67 20 7 / 0.95) !important;
}

.tw-ring-offset-pink-100 {
  --tw-ring-offset-color: #fce7f3 !important;
}

.tw-ring-offset-pink-100\/0 {
  --tw-ring-offset-color: rgb(252 231 243 / 0) !important;
}

.tw-ring-offset-pink-100\/10 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.1) !important;
}

.tw-ring-offset-pink-100\/100 {
  --tw-ring-offset-color: rgb(252 231 243 / 1) !important;
}

.tw-ring-offset-pink-100\/15 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.15) !important;
}

.tw-ring-offset-pink-100\/20 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.2) !important;
}

.tw-ring-offset-pink-100\/25 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.25) !important;
}

.tw-ring-offset-pink-100\/30 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.3) !important;
}

.tw-ring-offset-pink-100\/35 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.35) !important;
}

.tw-ring-offset-pink-100\/40 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.4) !important;
}

.tw-ring-offset-pink-100\/45 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.45) !important;
}

.tw-ring-offset-pink-100\/5 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.05) !important;
}

.tw-ring-offset-pink-100\/50 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.5) !important;
}

.tw-ring-offset-pink-100\/55 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.55) !important;
}

.tw-ring-offset-pink-100\/60 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.6) !important;
}

.tw-ring-offset-pink-100\/65 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.65) !important;
}

.tw-ring-offset-pink-100\/70 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.7) !important;
}

.tw-ring-offset-pink-100\/75 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.75) !important;
}

.tw-ring-offset-pink-100\/80 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.8) !important;
}

.tw-ring-offset-pink-100\/85 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.85) !important;
}

.tw-ring-offset-pink-100\/90 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.9) !important;
}

.tw-ring-offset-pink-100\/95 {
  --tw-ring-offset-color: rgb(252 231 243 / 0.95) !important;
}

.tw-ring-offset-pink-200 {
  --tw-ring-offset-color: #fbcfe8 !important;
}

.tw-ring-offset-pink-200\/0 {
  --tw-ring-offset-color: rgb(251 207 232 / 0) !important;
}

.tw-ring-offset-pink-200\/10 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.1) !important;
}

.tw-ring-offset-pink-200\/100 {
  --tw-ring-offset-color: rgb(251 207 232 / 1) !important;
}

.tw-ring-offset-pink-200\/15 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.15) !important;
}

.tw-ring-offset-pink-200\/20 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.2) !important;
}

.tw-ring-offset-pink-200\/25 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.25) !important;
}

.tw-ring-offset-pink-200\/30 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.3) !important;
}

.tw-ring-offset-pink-200\/35 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.35) !important;
}

.tw-ring-offset-pink-200\/40 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.4) !important;
}

.tw-ring-offset-pink-200\/45 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.45) !important;
}

.tw-ring-offset-pink-200\/5 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.05) !important;
}

.tw-ring-offset-pink-200\/50 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.5) !important;
}

.tw-ring-offset-pink-200\/55 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.55) !important;
}

.tw-ring-offset-pink-200\/60 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.6) !important;
}

.tw-ring-offset-pink-200\/65 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.65) !important;
}

.tw-ring-offset-pink-200\/70 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.7) !important;
}

.tw-ring-offset-pink-200\/75 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.75) !important;
}

.tw-ring-offset-pink-200\/80 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.8) !important;
}

.tw-ring-offset-pink-200\/85 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.85) !important;
}

.tw-ring-offset-pink-200\/90 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.9) !important;
}

.tw-ring-offset-pink-200\/95 {
  --tw-ring-offset-color: rgb(251 207 232 / 0.95) !important;
}

.tw-ring-offset-pink-300 {
  --tw-ring-offset-color: #f9a8d4 !important;
}

.tw-ring-offset-pink-300\/0 {
  --tw-ring-offset-color: rgb(249 168 212 / 0) !important;
}

.tw-ring-offset-pink-300\/10 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.1) !important;
}

.tw-ring-offset-pink-300\/100 {
  --tw-ring-offset-color: rgb(249 168 212 / 1) !important;
}

.tw-ring-offset-pink-300\/15 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.15) !important;
}

.tw-ring-offset-pink-300\/20 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.2) !important;
}

.tw-ring-offset-pink-300\/25 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.25) !important;
}

.tw-ring-offset-pink-300\/30 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.3) !important;
}

.tw-ring-offset-pink-300\/35 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.35) !important;
}

.tw-ring-offset-pink-300\/40 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.4) !important;
}

.tw-ring-offset-pink-300\/45 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.45) !important;
}

.tw-ring-offset-pink-300\/5 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.05) !important;
}

.tw-ring-offset-pink-300\/50 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.5) !important;
}

.tw-ring-offset-pink-300\/55 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.55) !important;
}

.tw-ring-offset-pink-300\/60 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.6) !important;
}

.tw-ring-offset-pink-300\/65 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.65) !important;
}

.tw-ring-offset-pink-300\/70 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.7) !important;
}

.tw-ring-offset-pink-300\/75 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.75) !important;
}

.tw-ring-offset-pink-300\/80 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.8) !important;
}

.tw-ring-offset-pink-300\/85 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.85) !important;
}

.tw-ring-offset-pink-300\/90 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.9) !important;
}

.tw-ring-offset-pink-300\/95 {
  --tw-ring-offset-color: rgb(249 168 212 / 0.95) !important;
}

.tw-ring-offset-pink-400 {
  --tw-ring-offset-color: #f472b6 !important;
}

.tw-ring-offset-pink-400\/0 {
  --tw-ring-offset-color: rgb(244 114 182 / 0) !important;
}

.tw-ring-offset-pink-400\/10 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.1) !important;
}

.tw-ring-offset-pink-400\/100 {
  --tw-ring-offset-color: rgb(244 114 182 / 1) !important;
}

.tw-ring-offset-pink-400\/15 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.15) !important;
}

.tw-ring-offset-pink-400\/20 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.2) !important;
}

.tw-ring-offset-pink-400\/25 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.25) !important;
}

.tw-ring-offset-pink-400\/30 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.3) !important;
}

.tw-ring-offset-pink-400\/35 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.35) !important;
}

.tw-ring-offset-pink-400\/40 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.4) !important;
}

.tw-ring-offset-pink-400\/45 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.45) !important;
}

.tw-ring-offset-pink-400\/5 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.05) !important;
}

.tw-ring-offset-pink-400\/50 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.5) !important;
}

.tw-ring-offset-pink-400\/55 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.55) !important;
}

.tw-ring-offset-pink-400\/60 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.6) !important;
}

.tw-ring-offset-pink-400\/65 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.65) !important;
}

.tw-ring-offset-pink-400\/70 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.7) !important;
}

.tw-ring-offset-pink-400\/75 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.75) !important;
}

.tw-ring-offset-pink-400\/80 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.8) !important;
}

.tw-ring-offset-pink-400\/85 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.85) !important;
}

.tw-ring-offset-pink-400\/90 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.9) !important;
}

.tw-ring-offset-pink-400\/95 {
  --tw-ring-offset-color: rgb(244 114 182 / 0.95) !important;
}

.tw-ring-offset-pink-50 {
  --tw-ring-offset-color: #fdf2f8 !important;
}

.tw-ring-offset-pink-50\/0 {
  --tw-ring-offset-color: rgb(253 242 248 / 0) !important;
}

.tw-ring-offset-pink-50\/10 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.1) !important;
}

.tw-ring-offset-pink-50\/100 {
  --tw-ring-offset-color: rgb(253 242 248 / 1) !important;
}

.tw-ring-offset-pink-50\/15 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.15) !important;
}

.tw-ring-offset-pink-50\/20 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.2) !important;
}

.tw-ring-offset-pink-50\/25 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.25) !important;
}

.tw-ring-offset-pink-50\/30 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.3) !important;
}

.tw-ring-offset-pink-50\/35 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.35) !important;
}

.tw-ring-offset-pink-50\/40 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.4) !important;
}

.tw-ring-offset-pink-50\/45 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.45) !important;
}

.tw-ring-offset-pink-50\/5 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.05) !important;
}

.tw-ring-offset-pink-50\/50 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.5) !important;
}

.tw-ring-offset-pink-50\/55 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.55) !important;
}

.tw-ring-offset-pink-50\/60 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.6) !important;
}

.tw-ring-offset-pink-50\/65 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.65) !important;
}

.tw-ring-offset-pink-50\/70 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.7) !important;
}

.tw-ring-offset-pink-50\/75 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.75) !important;
}

.tw-ring-offset-pink-50\/80 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.8) !important;
}

.tw-ring-offset-pink-50\/85 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.85) !important;
}

.tw-ring-offset-pink-50\/90 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.9) !important;
}

.tw-ring-offset-pink-50\/95 {
  --tw-ring-offset-color: rgb(253 242 248 / 0.95) !important;
}

.tw-ring-offset-pink-500 {
  --tw-ring-offset-color: #ec4899 !important;
}

.tw-ring-offset-pink-500\/0 {
  --tw-ring-offset-color: rgb(236 72 153 / 0) !important;
}

.tw-ring-offset-pink-500\/10 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.1) !important;
}

.tw-ring-offset-pink-500\/100 {
  --tw-ring-offset-color: rgb(236 72 153 / 1) !important;
}

.tw-ring-offset-pink-500\/15 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.15) !important;
}

.tw-ring-offset-pink-500\/20 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.2) !important;
}

.tw-ring-offset-pink-500\/25 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.25) !important;
}

.tw-ring-offset-pink-500\/30 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.3) !important;
}

.tw-ring-offset-pink-500\/35 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.35) !important;
}

.tw-ring-offset-pink-500\/40 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.4) !important;
}

.tw-ring-offset-pink-500\/45 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.45) !important;
}

.tw-ring-offset-pink-500\/5 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.05) !important;
}

.tw-ring-offset-pink-500\/50 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.5) !important;
}

.tw-ring-offset-pink-500\/55 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.55) !important;
}

.tw-ring-offset-pink-500\/60 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.6) !important;
}

.tw-ring-offset-pink-500\/65 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.65) !important;
}

.tw-ring-offset-pink-500\/70 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.7) !important;
}

.tw-ring-offset-pink-500\/75 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.75) !important;
}

.tw-ring-offset-pink-500\/80 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.8) !important;
}

.tw-ring-offset-pink-500\/85 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.85) !important;
}

.tw-ring-offset-pink-500\/90 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.9) !important;
}

.tw-ring-offset-pink-500\/95 {
  --tw-ring-offset-color: rgb(236 72 153 / 0.95) !important;
}

.tw-ring-offset-pink-600 {
  --tw-ring-offset-color: #db2777 !important;
}

.tw-ring-offset-pink-600\/0 {
  --tw-ring-offset-color: rgb(219 39 119 / 0) !important;
}

.tw-ring-offset-pink-600\/10 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.1) !important;
}

.tw-ring-offset-pink-600\/100 {
  --tw-ring-offset-color: rgb(219 39 119 / 1) !important;
}

.tw-ring-offset-pink-600\/15 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.15) !important;
}

.tw-ring-offset-pink-600\/20 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.2) !important;
}

.tw-ring-offset-pink-600\/25 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.25) !important;
}

.tw-ring-offset-pink-600\/30 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.3) !important;
}

.tw-ring-offset-pink-600\/35 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.35) !important;
}

.tw-ring-offset-pink-600\/40 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.4) !important;
}

.tw-ring-offset-pink-600\/45 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.45) !important;
}

.tw-ring-offset-pink-600\/5 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.05) !important;
}

.tw-ring-offset-pink-600\/50 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.5) !important;
}

.tw-ring-offset-pink-600\/55 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.55) !important;
}

.tw-ring-offset-pink-600\/60 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.6) !important;
}

.tw-ring-offset-pink-600\/65 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.65) !important;
}

.tw-ring-offset-pink-600\/70 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.7) !important;
}

.tw-ring-offset-pink-600\/75 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.75) !important;
}

.tw-ring-offset-pink-600\/80 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.8) !important;
}

.tw-ring-offset-pink-600\/85 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.85) !important;
}

.tw-ring-offset-pink-600\/90 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.9) !important;
}

.tw-ring-offset-pink-600\/95 {
  --tw-ring-offset-color: rgb(219 39 119 / 0.95) !important;
}

.tw-ring-offset-pink-700 {
  --tw-ring-offset-color: #be185d !important;
}

.tw-ring-offset-pink-700\/0 {
  --tw-ring-offset-color: rgb(190 24 93 / 0) !important;
}

.tw-ring-offset-pink-700\/10 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.1) !important;
}

.tw-ring-offset-pink-700\/100 {
  --tw-ring-offset-color: rgb(190 24 93 / 1) !important;
}

.tw-ring-offset-pink-700\/15 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.15) !important;
}

.tw-ring-offset-pink-700\/20 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.2) !important;
}

.tw-ring-offset-pink-700\/25 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.25) !important;
}

.tw-ring-offset-pink-700\/30 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.3) !important;
}

.tw-ring-offset-pink-700\/35 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.35) !important;
}

.tw-ring-offset-pink-700\/40 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.4) !important;
}

.tw-ring-offset-pink-700\/45 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.45) !important;
}

.tw-ring-offset-pink-700\/5 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.05) !important;
}

.tw-ring-offset-pink-700\/50 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.5) !important;
}

.tw-ring-offset-pink-700\/55 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.55) !important;
}

.tw-ring-offset-pink-700\/60 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.6) !important;
}

.tw-ring-offset-pink-700\/65 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.65) !important;
}

.tw-ring-offset-pink-700\/70 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.7) !important;
}

.tw-ring-offset-pink-700\/75 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.75) !important;
}

.tw-ring-offset-pink-700\/80 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.8) !important;
}

.tw-ring-offset-pink-700\/85 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.85) !important;
}

.tw-ring-offset-pink-700\/90 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.9) !important;
}

.tw-ring-offset-pink-700\/95 {
  --tw-ring-offset-color: rgb(190 24 93 / 0.95) !important;
}

.tw-ring-offset-pink-800 {
  --tw-ring-offset-color: #9d174d !important;
}

.tw-ring-offset-pink-800\/0 {
  --tw-ring-offset-color: rgb(157 23 77 / 0) !important;
}

.tw-ring-offset-pink-800\/10 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.1) !important;
}

.tw-ring-offset-pink-800\/100 {
  --tw-ring-offset-color: rgb(157 23 77 / 1) !important;
}

.tw-ring-offset-pink-800\/15 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.15) !important;
}

.tw-ring-offset-pink-800\/20 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.2) !important;
}

.tw-ring-offset-pink-800\/25 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.25) !important;
}

.tw-ring-offset-pink-800\/30 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.3) !important;
}

.tw-ring-offset-pink-800\/35 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.35) !important;
}

.tw-ring-offset-pink-800\/40 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.4) !important;
}

.tw-ring-offset-pink-800\/45 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.45) !important;
}

.tw-ring-offset-pink-800\/5 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.05) !important;
}

.tw-ring-offset-pink-800\/50 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.5) !important;
}

.tw-ring-offset-pink-800\/55 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.55) !important;
}

.tw-ring-offset-pink-800\/60 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.6) !important;
}

.tw-ring-offset-pink-800\/65 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.65) !important;
}

.tw-ring-offset-pink-800\/70 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.7) !important;
}

.tw-ring-offset-pink-800\/75 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.75) !important;
}

.tw-ring-offset-pink-800\/80 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.8) !important;
}

.tw-ring-offset-pink-800\/85 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.85) !important;
}

.tw-ring-offset-pink-800\/90 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.9) !important;
}

.tw-ring-offset-pink-800\/95 {
  --tw-ring-offset-color: rgb(157 23 77 / 0.95) !important;
}

.tw-ring-offset-pink-900 {
  --tw-ring-offset-color: #831843 !important;
}

.tw-ring-offset-pink-900\/0 {
  --tw-ring-offset-color: rgb(131 24 67 / 0) !important;
}

.tw-ring-offset-pink-900\/10 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.1) !important;
}

.tw-ring-offset-pink-900\/100 {
  --tw-ring-offset-color: rgb(131 24 67 / 1) !important;
}

.tw-ring-offset-pink-900\/15 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.15) !important;
}

.tw-ring-offset-pink-900\/20 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.2) !important;
}

.tw-ring-offset-pink-900\/25 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.25) !important;
}

.tw-ring-offset-pink-900\/30 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.3) !important;
}

.tw-ring-offset-pink-900\/35 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.35) !important;
}

.tw-ring-offset-pink-900\/40 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.4) !important;
}

.tw-ring-offset-pink-900\/45 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.45) !important;
}

.tw-ring-offset-pink-900\/5 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.05) !important;
}

.tw-ring-offset-pink-900\/50 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.5) !important;
}

.tw-ring-offset-pink-900\/55 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.55) !important;
}

.tw-ring-offset-pink-900\/60 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.6) !important;
}

.tw-ring-offset-pink-900\/65 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.65) !important;
}

.tw-ring-offset-pink-900\/70 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.7) !important;
}

.tw-ring-offset-pink-900\/75 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.75) !important;
}

.tw-ring-offset-pink-900\/80 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.8) !important;
}

.tw-ring-offset-pink-900\/85 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.85) !important;
}

.tw-ring-offset-pink-900\/90 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.9) !important;
}

.tw-ring-offset-pink-900\/95 {
  --tw-ring-offset-color: rgb(131 24 67 / 0.95) !important;
}

.tw-ring-offset-pink-950 {
  --tw-ring-offset-color: #500724 !important;
}

.tw-ring-offset-pink-950\/0 {
  --tw-ring-offset-color: rgb(80 7 36 / 0) !important;
}

.tw-ring-offset-pink-950\/10 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.1) !important;
}

.tw-ring-offset-pink-950\/100 {
  --tw-ring-offset-color: rgb(80 7 36 / 1) !important;
}

.tw-ring-offset-pink-950\/15 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.15) !important;
}

.tw-ring-offset-pink-950\/20 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.2) !important;
}

.tw-ring-offset-pink-950\/25 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.25) !important;
}

.tw-ring-offset-pink-950\/30 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.3) !important;
}

.tw-ring-offset-pink-950\/35 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.35) !important;
}

.tw-ring-offset-pink-950\/40 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.4) !important;
}

.tw-ring-offset-pink-950\/45 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.45) !important;
}

.tw-ring-offset-pink-950\/5 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.05) !important;
}

.tw-ring-offset-pink-950\/50 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.5) !important;
}

.tw-ring-offset-pink-950\/55 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.55) !important;
}

.tw-ring-offset-pink-950\/60 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.6) !important;
}

.tw-ring-offset-pink-950\/65 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.65) !important;
}

.tw-ring-offset-pink-950\/70 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.7) !important;
}

.tw-ring-offset-pink-950\/75 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.75) !important;
}

.tw-ring-offset-pink-950\/80 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.8) !important;
}

.tw-ring-offset-pink-950\/85 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.85) !important;
}

.tw-ring-offset-pink-950\/90 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.9) !important;
}

.tw-ring-offset-pink-950\/95 {
  --tw-ring-offset-color: rgb(80 7 36 / 0.95) !important;
}

.tw-ring-offset-purple-100 {
  --tw-ring-offset-color: #f3e8ff !important;
}

.tw-ring-offset-purple-100\/0 {
  --tw-ring-offset-color: rgb(243 232 255 / 0) !important;
}

.tw-ring-offset-purple-100\/10 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.1) !important;
}

.tw-ring-offset-purple-100\/100 {
  --tw-ring-offset-color: rgb(243 232 255 / 1) !important;
}

.tw-ring-offset-purple-100\/15 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.15) !important;
}

.tw-ring-offset-purple-100\/20 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.2) !important;
}

.tw-ring-offset-purple-100\/25 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.25) !important;
}

.tw-ring-offset-purple-100\/30 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.3) !important;
}

.tw-ring-offset-purple-100\/35 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.35) !important;
}

.tw-ring-offset-purple-100\/40 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.4) !important;
}

.tw-ring-offset-purple-100\/45 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.45) !important;
}

.tw-ring-offset-purple-100\/5 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.05) !important;
}

.tw-ring-offset-purple-100\/50 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.5) !important;
}

.tw-ring-offset-purple-100\/55 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.55) !important;
}

.tw-ring-offset-purple-100\/60 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.6) !important;
}

.tw-ring-offset-purple-100\/65 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.65) !important;
}

.tw-ring-offset-purple-100\/70 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.7) !important;
}

.tw-ring-offset-purple-100\/75 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.75) !important;
}

.tw-ring-offset-purple-100\/80 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.8) !important;
}

.tw-ring-offset-purple-100\/85 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.85) !important;
}

.tw-ring-offset-purple-100\/90 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.9) !important;
}

.tw-ring-offset-purple-100\/95 {
  --tw-ring-offset-color: rgb(243 232 255 / 0.95) !important;
}

.tw-ring-offset-purple-200 {
  --tw-ring-offset-color: #e9d5ff !important;
}

.tw-ring-offset-purple-200\/0 {
  --tw-ring-offset-color: rgb(233 213 255 / 0) !important;
}

.tw-ring-offset-purple-200\/10 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.1) !important;
}

.tw-ring-offset-purple-200\/100 {
  --tw-ring-offset-color: rgb(233 213 255 / 1) !important;
}

.tw-ring-offset-purple-200\/15 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.15) !important;
}

.tw-ring-offset-purple-200\/20 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.2) !important;
}

.tw-ring-offset-purple-200\/25 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.25) !important;
}

.tw-ring-offset-purple-200\/30 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.3) !important;
}

.tw-ring-offset-purple-200\/35 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.35) !important;
}

.tw-ring-offset-purple-200\/40 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.4) !important;
}

.tw-ring-offset-purple-200\/45 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.45) !important;
}

.tw-ring-offset-purple-200\/5 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.05) !important;
}

.tw-ring-offset-purple-200\/50 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.5) !important;
}

.tw-ring-offset-purple-200\/55 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.55) !important;
}

.tw-ring-offset-purple-200\/60 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.6) !important;
}

.tw-ring-offset-purple-200\/65 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.65) !important;
}

.tw-ring-offset-purple-200\/70 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.7) !important;
}

.tw-ring-offset-purple-200\/75 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.75) !important;
}

.tw-ring-offset-purple-200\/80 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.8) !important;
}

.tw-ring-offset-purple-200\/85 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.85) !important;
}

.tw-ring-offset-purple-200\/90 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.9) !important;
}

.tw-ring-offset-purple-200\/95 {
  --tw-ring-offset-color: rgb(233 213 255 / 0.95) !important;
}

.tw-ring-offset-purple-300 {
  --tw-ring-offset-color: #d8b4fe !important;
}

.tw-ring-offset-purple-300\/0 {
  --tw-ring-offset-color: rgb(216 180 254 / 0) !important;
}

.tw-ring-offset-purple-300\/10 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.1) !important;
}

.tw-ring-offset-purple-300\/100 {
  --tw-ring-offset-color: rgb(216 180 254 / 1) !important;
}

.tw-ring-offset-purple-300\/15 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.15) !important;
}

.tw-ring-offset-purple-300\/20 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.2) !important;
}

.tw-ring-offset-purple-300\/25 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.25) !important;
}

.tw-ring-offset-purple-300\/30 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.3) !important;
}

.tw-ring-offset-purple-300\/35 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.35) !important;
}

.tw-ring-offset-purple-300\/40 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.4) !important;
}

.tw-ring-offset-purple-300\/45 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.45) !important;
}

.tw-ring-offset-purple-300\/5 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.05) !important;
}

.tw-ring-offset-purple-300\/50 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.5) !important;
}

.tw-ring-offset-purple-300\/55 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.55) !important;
}

.tw-ring-offset-purple-300\/60 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.6) !important;
}

.tw-ring-offset-purple-300\/65 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.65) !important;
}

.tw-ring-offset-purple-300\/70 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.7) !important;
}

.tw-ring-offset-purple-300\/75 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.75) !important;
}

.tw-ring-offset-purple-300\/80 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.8) !important;
}

.tw-ring-offset-purple-300\/85 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.85) !important;
}

.tw-ring-offset-purple-300\/90 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.9) !important;
}

.tw-ring-offset-purple-300\/95 {
  --tw-ring-offset-color: rgb(216 180 254 / 0.95) !important;
}

.tw-ring-offset-purple-400 {
  --tw-ring-offset-color: #c084fc !important;
}

.tw-ring-offset-purple-400\/0 {
  --tw-ring-offset-color: rgb(192 132 252 / 0) !important;
}

.tw-ring-offset-purple-400\/10 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.1) !important;
}

.tw-ring-offset-purple-400\/100 {
  --tw-ring-offset-color: rgb(192 132 252 / 1) !important;
}

.tw-ring-offset-purple-400\/15 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.15) !important;
}

.tw-ring-offset-purple-400\/20 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.2) !important;
}

.tw-ring-offset-purple-400\/25 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.25) !important;
}

.tw-ring-offset-purple-400\/30 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.3) !important;
}

.tw-ring-offset-purple-400\/35 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.35) !important;
}

.tw-ring-offset-purple-400\/40 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.4) !important;
}

.tw-ring-offset-purple-400\/45 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.45) !important;
}

.tw-ring-offset-purple-400\/5 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.05) !important;
}

.tw-ring-offset-purple-400\/50 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.5) !important;
}

.tw-ring-offset-purple-400\/55 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.55) !important;
}

.tw-ring-offset-purple-400\/60 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.6) !important;
}

.tw-ring-offset-purple-400\/65 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.65) !important;
}

.tw-ring-offset-purple-400\/70 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.7) !important;
}

.tw-ring-offset-purple-400\/75 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.75) !important;
}

.tw-ring-offset-purple-400\/80 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.8) !important;
}

.tw-ring-offset-purple-400\/85 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.85) !important;
}

.tw-ring-offset-purple-400\/90 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.9) !important;
}

.tw-ring-offset-purple-400\/95 {
  --tw-ring-offset-color: rgb(192 132 252 / 0.95) !important;
}

.tw-ring-offset-purple-50 {
  --tw-ring-offset-color: #faf5ff !important;
}

.tw-ring-offset-purple-50\/0 {
  --tw-ring-offset-color: rgb(250 245 255 / 0) !important;
}

.tw-ring-offset-purple-50\/10 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.1) !important;
}

.tw-ring-offset-purple-50\/100 {
  --tw-ring-offset-color: rgb(250 245 255 / 1) !important;
}

.tw-ring-offset-purple-50\/15 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.15) !important;
}

.tw-ring-offset-purple-50\/20 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.2) !important;
}

.tw-ring-offset-purple-50\/25 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.25) !important;
}

.tw-ring-offset-purple-50\/30 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.3) !important;
}

.tw-ring-offset-purple-50\/35 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.35) !important;
}

.tw-ring-offset-purple-50\/40 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.4) !important;
}

.tw-ring-offset-purple-50\/45 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.45) !important;
}

.tw-ring-offset-purple-50\/5 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.05) !important;
}

.tw-ring-offset-purple-50\/50 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.5) !important;
}

.tw-ring-offset-purple-50\/55 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.55) !important;
}

.tw-ring-offset-purple-50\/60 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.6) !important;
}

.tw-ring-offset-purple-50\/65 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.65) !important;
}

.tw-ring-offset-purple-50\/70 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.7) !important;
}

.tw-ring-offset-purple-50\/75 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.75) !important;
}

.tw-ring-offset-purple-50\/80 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.8) !important;
}

.tw-ring-offset-purple-50\/85 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.85) !important;
}

.tw-ring-offset-purple-50\/90 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.9) !important;
}

.tw-ring-offset-purple-50\/95 {
  --tw-ring-offset-color: rgb(250 245 255 / 0.95) !important;
}

.tw-ring-offset-purple-500 {
  --tw-ring-offset-color: #a855f7 !important;
}

.tw-ring-offset-purple-500\/0 {
  --tw-ring-offset-color: rgb(168 85 247 / 0) !important;
}

.tw-ring-offset-purple-500\/10 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.1) !important;
}

.tw-ring-offset-purple-500\/100 {
  --tw-ring-offset-color: rgb(168 85 247 / 1) !important;
}

.tw-ring-offset-purple-500\/15 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.15) !important;
}

.tw-ring-offset-purple-500\/20 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.2) !important;
}

.tw-ring-offset-purple-500\/25 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.25) !important;
}

.tw-ring-offset-purple-500\/30 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.3) !important;
}

.tw-ring-offset-purple-500\/35 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.35) !important;
}

.tw-ring-offset-purple-500\/40 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.4) !important;
}

.tw-ring-offset-purple-500\/45 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.45) !important;
}

.tw-ring-offset-purple-500\/5 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.05) !important;
}

.tw-ring-offset-purple-500\/50 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.5) !important;
}

.tw-ring-offset-purple-500\/55 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.55) !important;
}

.tw-ring-offset-purple-500\/60 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.6) !important;
}

.tw-ring-offset-purple-500\/65 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.65) !important;
}

.tw-ring-offset-purple-500\/70 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.7) !important;
}

.tw-ring-offset-purple-500\/75 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.75) !important;
}

.tw-ring-offset-purple-500\/80 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.8) !important;
}

.tw-ring-offset-purple-500\/85 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.85) !important;
}

.tw-ring-offset-purple-500\/90 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.9) !important;
}

.tw-ring-offset-purple-500\/95 {
  --tw-ring-offset-color: rgb(168 85 247 / 0.95) !important;
}

.tw-ring-offset-purple-600 {
  --tw-ring-offset-color: #9333ea !important;
}

.tw-ring-offset-purple-600\/0 {
  --tw-ring-offset-color: rgb(147 51 234 / 0) !important;
}

.tw-ring-offset-purple-600\/10 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.1) !important;
}

.tw-ring-offset-purple-600\/100 {
  --tw-ring-offset-color: rgb(147 51 234 / 1) !important;
}

.tw-ring-offset-purple-600\/15 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.15) !important;
}

.tw-ring-offset-purple-600\/20 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.2) !important;
}

.tw-ring-offset-purple-600\/25 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.25) !important;
}

.tw-ring-offset-purple-600\/30 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.3) !important;
}

.tw-ring-offset-purple-600\/35 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.35) !important;
}

.tw-ring-offset-purple-600\/40 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.4) !important;
}

.tw-ring-offset-purple-600\/45 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.45) !important;
}

.tw-ring-offset-purple-600\/5 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.05) !important;
}

.tw-ring-offset-purple-600\/50 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.5) !important;
}

.tw-ring-offset-purple-600\/55 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.55) !important;
}

.tw-ring-offset-purple-600\/60 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.6) !important;
}

.tw-ring-offset-purple-600\/65 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.65) !important;
}

.tw-ring-offset-purple-600\/70 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.7) !important;
}

.tw-ring-offset-purple-600\/75 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.75) !important;
}

.tw-ring-offset-purple-600\/80 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.8) !important;
}

.tw-ring-offset-purple-600\/85 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.85) !important;
}

.tw-ring-offset-purple-600\/90 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.9) !important;
}

.tw-ring-offset-purple-600\/95 {
  --tw-ring-offset-color: rgb(147 51 234 / 0.95) !important;
}

.tw-ring-offset-purple-700 {
  --tw-ring-offset-color: #7e22ce !important;
}

.tw-ring-offset-purple-700\/0 {
  --tw-ring-offset-color: rgb(126 34 206 / 0) !important;
}

.tw-ring-offset-purple-700\/10 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.1) !important;
}

.tw-ring-offset-purple-700\/100 {
  --tw-ring-offset-color: rgb(126 34 206 / 1) !important;
}

.tw-ring-offset-purple-700\/15 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.15) !important;
}

.tw-ring-offset-purple-700\/20 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.2) !important;
}

.tw-ring-offset-purple-700\/25 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.25) !important;
}

.tw-ring-offset-purple-700\/30 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.3) !important;
}

.tw-ring-offset-purple-700\/35 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.35) !important;
}

.tw-ring-offset-purple-700\/40 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.4) !important;
}

.tw-ring-offset-purple-700\/45 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.45) !important;
}

.tw-ring-offset-purple-700\/5 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.05) !important;
}

.tw-ring-offset-purple-700\/50 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.5) !important;
}

.tw-ring-offset-purple-700\/55 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.55) !important;
}

.tw-ring-offset-purple-700\/60 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.6) !important;
}

.tw-ring-offset-purple-700\/65 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.65) !important;
}

.tw-ring-offset-purple-700\/70 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.7) !important;
}

.tw-ring-offset-purple-700\/75 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.75) !important;
}

.tw-ring-offset-purple-700\/80 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.8) !important;
}

.tw-ring-offset-purple-700\/85 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.85) !important;
}

.tw-ring-offset-purple-700\/90 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.9) !important;
}

.tw-ring-offset-purple-700\/95 {
  --tw-ring-offset-color: rgb(126 34 206 / 0.95) !important;
}

.tw-ring-offset-purple-800 {
  --tw-ring-offset-color: #6b21a8 !important;
}

.tw-ring-offset-purple-800\/0 {
  --tw-ring-offset-color: rgb(107 33 168 / 0) !important;
}

.tw-ring-offset-purple-800\/10 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.1) !important;
}

.tw-ring-offset-purple-800\/100 {
  --tw-ring-offset-color: rgb(107 33 168 / 1) !important;
}

.tw-ring-offset-purple-800\/15 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.15) !important;
}

.tw-ring-offset-purple-800\/20 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.2) !important;
}

.tw-ring-offset-purple-800\/25 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.25) !important;
}

.tw-ring-offset-purple-800\/30 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.3) !important;
}

.tw-ring-offset-purple-800\/35 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.35) !important;
}

.tw-ring-offset-purple-800\/40 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.4) !important;
}

.tw-ring-offset-purple-800\/45 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.45) !important;
}

.tw-ring-offset-purple-800\/5 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.05) !important;
}

.tw-ring-offset-purple-800\/50 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.5) !important;
}

.tw-ring-offset-purple-800\/55 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.55) !important;
}

.tw-ring-offset-purple-800\/60 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.6) !important;
}

.tw-ring-offset-purple-800\/65 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.65) !important;
}

.tw-ring-offset-purple-800\/70 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.7) !important;
}

.tw-ring-offset-purple-800\/75 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.75) !important;
}

.tw-ring-offset-purple-800\/80 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.8) !important;
}

.tw-ring-offset-purple-800\/85 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.85) !important;
}

.tw-ring-offset-purple-800\/90 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.9) !important;
}

.tw-ring-offset-purple-800\/95 {
  --tw-ring-offset-color: rgb(107 33 168 / 0.95) !important;
}

.tw-ring-offset-purple-900 {
  --tw-ring-offset-color: #581c87 !important;
}

.tw-ring-offset-purple-900\/0 {
  --tw-ring-offset-color: rgb(88 28 135 / 0) !important;
}

.tw-ring-offset-purple-900\/10 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.1) !important;
}

.tw-ring-offset-purple-900\/100 {
  --tw-ring-offset-color: rgb(88 28 135 / 1) !important;
}

.tw-ring-offset-purple-900\/15 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.15) !important;
}

.tw-ring-offset-purple-900\/20 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.2) !important;
}

.tw-ring-offset-purple-900\/25 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.25) !important;
}

.tw-ring-offset-purple-900\/30 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.3) !important;
}

.tw-ring-offset-purple-900\/35 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.35) !important;
}

.tw-ring-offset-purple-900\/40 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.4) !important;
}

.tw-ring-offset-purple-900\/45 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.45) !important;
}

.tw-ring-offset-purple-900\/5 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.05) !important;
}

.tw-ring-offset-purple-900\/50 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.5) !important;
}

.tw-ring-offset-purple-900\/55 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.55) !important;
}

.tw-ring-offset-purple-900\/60 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.6) !important;
}

.tw-ring-offset-purple-900\/65 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.65) !important;
}

.tw-ring-offset-purple-900\/70 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.7) !important;
}

.tw-ring-offset-purple-900\/75 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.75) !important;
}

.tw-ring-offset-purple-900\/80 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.8) !important;
}

.tw-ring-offset-purple-900\/85 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.85) !important;
}

.tw-ring-offset-purple-900\/90 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.9) !important;
}

.tw-ring-offset-purple-900\/95 {
  --tw-ring-offset-color: rgb(88 28 135 / 0.95) !important;
}

.tw-ring-offset-purple-950 {
  --tw-ring-offset-color: #3b0764 !important;
}

.tw-ring-offset-purple-950\/0 {
  --tw-ring-offset-color: rgb(59 7 100 / 0) !important;
}

.tw-ring-offset-purple-950\/10 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.1) !important;
}

.tw-ring-offset-purple-950\/100 {
  --tw-ring-offset-color: rgb(59 7 100 / 1) !important;
}

.tw-ring-offset-purple-950\/15 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.15) !important;
}

.tw-ring-offset-purple-950\/20 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.2) !important;
}

.tw-ring-offset-purple-950\/25 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.25) !important;
}

.tw-ring-offset-purple-950\/30 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.3) !important;
}

.tw-ring-offset-purple-950\/35 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.35) !important;
}

.tw-ring-offset-purple-950\/40 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.4) !important;
}

.tw-ring-offset-purple-950\/45 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.45) !important;
}

.tw-ring-offset-purple-950\/5 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.05) !important;
}

.tw-ring-offset-purple-950\/50 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.5) !important;
}

.tw-ring-offset-purple-950\/55 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.55) !important;
}

.tw-ring-offset-purple-950\/60 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.6) !important;
}

.tw-ring-offset-purple-950\/65 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.65) !important;
}

.tw-ring-offset-purple-950\/70 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.7) !important;
}

.tw-ring-offset-purple-950\/75 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.75) !important;
}

.tw-ring-offset-purple-950\/80 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.8) !important;
}

.tw-ring-offset-purple-950\/85 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.85) !important;
}

.tw-ring-offset-purple-950\/90 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.9) !important;
}

.tw-ring-offset-purple-950\/95 {
  --tw-ring-offset-color: rgb(59 7 100 / 0.95) !important;
}

.tw-ring-offset-red-100 {
  --tw-ring-offset-color: #fee2e2 !important;
}

.tw-ring-offset-red-100\/0 {
  --tw-ring-offset-color: rgb(254 226 226 / 0) !important;
}

.tw-ring-offset-red-100\/10 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.1) !important;
}

.tw-ring-offset-red-100\/100 {
  --tw-ring-offset-color: rgb(254 226 226 / 1) !important;
}

.tw-ring-offset-red-100\/15 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.15) !important;
}

.tw-ring-offset-red-100\/20 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.2) !important;
}

.tw-ring-offset-red-100\/25 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.25) !important;
}

.tw-ring-offset-red-100\/30 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.3) !important;
}

.tw-ring-offset-red-100\/35 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.35) !important;
}

.tw-ring-offset-red-100\/40 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.4) !important;
}

.tw-ring-offset-red-100\/45 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.45) !important;
}

.tw-ring-offset-red-100\/5 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.05) !important;
}

.tw-ring-offset-red-100\/50 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.5) !important;
}

.tw-ring-offset-red-100\/55 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.55) !important;
}

.tw-ring-offset-red-100\/60 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.6) !important;
}

.tw-ring-offset-red-100\/65 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.65) !important;
}

.tw-ring-offset-red-100\/70 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.7) !important;
}

.tw-ring-offset-red-100\/75 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.75) !important;
}

.tw-ring-offset-red-100\/80 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.8) !important;
}

.tw-ring-offset-red-100\/85 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.85) !important;
}

.tw-ring-offset-red-100\/90 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.9) !important;
}

.tw-ring-offset-red-100\/95 {
  --tw-ring-offset-color: rgb(254 226 226 / 0.95) !important;
}

.tw-ring-offset-red-200 {
  --tw-ring-offset-color: #fecaca !important;
}

.tw-ring-offset-red-200\/0 {
  --tw-ring-offset-color: rgb(254 202 202 / 0) !important;
}

.tw-ring-offset-red-200\/10 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.1) !important;
}

.tw-ring-offset-red-200\/100 {
  --tw-ring-offset-color: rgb(254 202 202 / 1) !important;
}

.tw-ring-offset-red-200\/15 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.15) !important;
}

.tw-ring-offset-red-200\/20 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.2) !important;
}

.tw-ring-offset-red-200\/25 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.25) !important;
}

.tw-ring-offset-red-200\/30 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.3) !important;
}

.tw-ring-offset-red-200\/35 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.35) !important;
}

.tw-ring-offset-red-200\/40 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.4) !important;
}

.tw-ring-offset-red-200\/45 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.45) !important;
}

.tw-ring-offset-red-200\/5 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.05) !important;
}

.tw-ring-offset-red-200\/50 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.5) !important;
}

.tw-ring-offset-red-200\/55 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.55) !important;
}

.tw-ring-offset-red-200\/60 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.6) !important;
}

.tw-ring-offset-red-200\/65 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.65) !important;
}

.tw-ring-offset-red-200\/70 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.7) !important;
}

.tw-ring-offset-red-200\/75 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.75) !important;
}

.tw-ring-offset-red-200\/80 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.8) !important;
}

.tw-ring-offset-red-200\/85 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.85) !important;
}

.tw-ring-offset-red-200\/90 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.9) !important;
}

.tw-ring-offset-red-200\/95 {
  --tw-ring-offset-color: rgb(254 202 202 / 0.95) !important;
}

.tw-ring-offset-red-300 {
  --tw-ring-offset-color: #fca5a5 !important;
}

.tw-ring-offset-red-300\/0 {
  --tw-ring-offset-color: rgb(252 165 165 / 0) !important;
}

.tw-ring-offset-red-300\/10 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.1) !important;
}

.tw-ring-offset-red-300\/100 {
  --tw-ring-offset-color: rgb(252 165 165 / 1) !important;
}

.tw-ring-offset-red-300\/15 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.15) !important;
}

.tw-ring-offset-red-300\/20 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.2) !important;
}

.tw-ring-offset-red-300\/25 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.25) !important;
}

.tw-ring-offset-red-300\/30 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.3) !important;
}

.tw-ring-offset-red-300\/35 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.35) !important;
}

.tw-ring-offset-red-300\/40 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.4) !important;
}

.tw-ring-offset-red-300\/45 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.45) !important;
}

.tw-ring-offset-red-300\/5 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.05) !important;
}

.tw-ring-offset-red-300\/50 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.5) !important;
}

.tw-ring-offset-red-300\/55 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.55) !important;
}

.tw-ring-offset-red-300\/60 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.6) !important;
}

.tw-ring-offset-red-300\/65 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.65) !important;
}

.tw-ring-offset-red-300\/70 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.7) !important;
}

.tw-ring-offset-red-300\/75 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.75) !important;
}

.tw-ring-offset-red-300\/80 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.8) !important;
}

.tw-ring-offset-red-300\/85 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.85) !important;
}

.tw-ring-offset-red-300\/90 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.9) !important;
}

.tw-ring-offset-red-300\/95 {
  --tw-ring-offset-color: rgb(252 165 165 / 0.95) !important;
}

.tw-ring-offset-red-400 {
  --tw-ring-offset-color: #f87171 !important;
}

.tw-ring-offset-red-400\/0 {
  --tw-ring-offset-color: rgb(248 113 113 / 0) !important;
}

.tw-ring-offset-red-400\/10 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.1) !important;
}

.tw-ring-offset-red-400\/100 {
  --tw-ring-offset-color: rgb(248 113 113 / 1) !important;
}

.tw-ring-offset-red-400\/15 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.15) !important;
}

.tw-ring-offset-red-400\/20 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.2) !important;
}

.tw-ring-offset-red-400\/25 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.25) !important;
}

.tw-ring-offset-red-400\/30 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.3) !important;
}

.tw-ring-offset-red-400\/35 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.35) !important;
}

.tw-ring-offset-red-400\/40 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.4) !important;
}

.tw-ring-offset-red-400\/45 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.45) !important;
}

.tw-ring-offset-red-400\/5 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.05) !important;
}

.tw-ring-offset-red-400\/50 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.5) !important;
}

.tw-ring-offset-red-400\/55 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.55) !important;
}

.tw-ring-offset-red-400\/60 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.6) !important;
}

.tw-ring-offset-red-400\/65 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.65) !important;
}

.tw-ring-offset-red-400\/70 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.7) !important;
}

.tw-ring-offset-red-400\/75 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.75) !important;
}

.tw-ring-offset-red-400\/80 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.8) !important;
}

.tw-ring-offset-red-400\/85 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.85) !important;
}

.tw-ring-offset-red-400\/90 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.9) !important;
}

.tw-ring-offset-red-400\/95 {
  --tw-ring-offset-color: rgb(248 113 113 / 0.95) !important;
}

.tw-ring-offset-red-50 {
  --tw-ring-offset-color: #fef2f2 !important;
}

.tw-ring-offset-red-50\/0 {
  --tw-ring-offset-color: rgb(254 242 242 / 0) !important;
}

.tw-ring-offset-red-50\/10 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.1) !important;
}

.tw-ring-offset-red-50\/100 {
  --tw-ring-offset-color: rgb(254 242 242 / 1) !important;
}

.tw-ring-offset-red-50\/15 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.15) !important;
}

.tw-ring-offset-red-50\/20 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.2) !important;
}

.tw-ring-offset-red-50\/25 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.25) !important;
}

.tw-ring-offset-red-50\/30 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.3) !important;
}

.tw-ring-offset-red-50\/35 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.35) !important;
}

.tw-ring-offset-red-50\/40 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.4) !important;
}

.tw-ring-offset-red-50\/45 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.45) !important;
}

.tw-ring-offset-red-50\/5 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.05) !important;
}

.tw-ring-offset-red-50\/50 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.5) !important;
}

.tw-ring-offset-red-50\/55 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.55) !important;
}

.tw-ring-offset-red-50\/60 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.6) !important;
}

.tw-ring-offset-red-50\/65 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.65) !important;
}

.tw-ring-offset-red-50\/70 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.7) !important;
}

.tw-ring-offset-red-50\/75 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.75) !important;
}

.tw-ring-offset-red-50\/80 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.8) !important;
}

.tw-ring-offset-red-50\/85 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.85) !important;
}

.tw-ring-offset-red-50\/90 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.9) !important;
}

.tw-ring-offset-red-50\/95 {
  --tw-ring-offset-color: rgb(254 242 242 / 0.95) !important;
}

.tw-ring-offset-red-500 {
  --tw-ring-offset-color: #ef4444 !important;
}

.tw-ring-offset-red-500\/0 {
  --tw-ring-offset-color: rgb(239 68 68 / 0) !important;
}

.tw-ring-offset-red-500\/10 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.1) !important;
}

.tw-ring-offset-red-500\/100 {
  --tw-ring-offset-color: rgb(239 68 68 / 1) !important;
}

.tw-ring-offset-red-500\/15 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.15) !important;
}

.tw-ring-offset-red-500\/20 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.2) !important;
}

.tw-ring-offset-red-500\/25 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.25) !important;
}

.tw-ring-offset-red-500\/30 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.3) !important;
}

.tw-ring-offset-red-500\/35 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.35) !important;
}

.tw-ring-offset-red-500\/40 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.4) !important;
}

.tw-ring-offset-red-500\/45 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.45) !important;
}

.tw-ring-offset-red-500\/5 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.05) !important;
}

.tw-ring-offset-red-500\/50 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.5) !important;
}

.tw-ring-offset-red-500\/55 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.55) !important;
}

.tw-ring-offset-red-500\/60 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.6) !important;
}

.tw-ring-offset-red-500\/65 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.65) !important;
}

.tw-ring-offset-red-500\/70 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.7) !important;
}

.tw-ring-offset-red-500\/75 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.75) !important;
}

.tw-ring-offset-red-500\/80 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.8) !important;
}

.tw-ring-offset-red-500\/85 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.85) !important;
}

.tw-ring-offset-red-500\/90 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.9) !important;
}

.tw-ring-offset-red-500\/95 {
  --tw-ring-offset-color: rgb(239 68 68 / 0.95) !important;
}

.tw-ring-offset-red-600 {
  --tw-ring-offset-color: #dc2626 !important;
}

.tw-ring-offset-red-600\/0 {
  --tw-ring-offset-color: rgb(220 38 38 / 0) !important;
}

.tw-ring-offset-red-600\/10 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.1) !important;
}

.tw-ring-offset-red-600\/100 {
  --tw-ring-offset-color: rgb(220 38 38 / 1) !important;
}

.tw-ring-offset-red-600\/15 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.15) !important;
}

.tw-ring-offset-red-600\/20 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.2) !important;
}

.tw-ring-offset-red-600\/25 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.25) !important;
}

.tw-ring-offset-red-600\/30 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.3) !important;
}

.tw-ring-offset-red-600\/35 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.35) !important;
}

.tw-ring-offset-red-600\/40 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.4) !important;
}

.tw-ring-offset-red-600\/45 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.45) !important;
}

.tw-ring-offset-red-600\/5 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.05) !important;
}

.tw-ring-offset-red-600\/50 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.5) !important;
}

.tw-ring-offset-red-600\/55 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.55) !important;
}

.tw-ring-offset-red-600\/60 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.6) !important;
}

.tw-ring-offset-red-600\/65 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.65) !important;
}

.tw-ring-offset-red-600\/70 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.7) !important;
}

.tw-ring-offset-red-600\/75 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.75) !important;
}

.tw-ring-offset-red-600\/80 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.8) !important;
}

.tw-ring-offset-red-600\/85 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.85) !important;
}

.tw-ring-offset-red-600\/90 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.9) !important;
}

.tw-ring-offset-red-600\/95 {
  --tw-ring-offset-color: rgb(220 38 38 / 0.95) !important;
}

.tw-ring-offset-red-700 {
  --tw-ring-offset-color: #b91c1c !important;
}

.tw-ring-offset-red-700\/0 {
  --tw-ring-offset-color: rgb(185 28 28 / 0) !important;
}

.tw-ring-offset-red-700\/10 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.1) !important;
}

.tw-ring-offset-red-700\/100 {
  --tw-ring-offset-color: rgb(185 28 28 / 1) !important;
}

.tw-ring-offset-red-700\/15 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.15) !important;
}

.tw-ring-offset-red-700\/20 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.2) !important;
}

.tw-ring-offset-red-700\/25 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.25) !important;
}

.tw-ring-offset-red-700\/30 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.3) !important;
}

.tw-ring-offset-red-700\/35 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.35) !important;
}

.tw-ring-offset-red-700\/40 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.4) !important;
}

.tw-ring-offset-red-700\/45 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.45) !important;
}

.tw-ring-offset-red-700\/5 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.05) !important;
}

.tw-ring-offset-red-700\/50 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.5) !important;
}

.tw-ring-offset-red-700\/55 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.55) !important;
}

.tw-ring-offset-red-700\/60 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.6) !important;
}

.tw-ring-offset-red-700\/65 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.65) !important;
}

.tw-ring-offset-red-700\/70 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.7) !important;
}

.tw-ring-offset-red-700\/75 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.75) !important;
}

.tw-ring-offset-red-700\/80 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.8) !important;
}

.tw-ring-offset-red-700\/85 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.85) !important;
}

.tw-ring-offset-red-700\/90 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.9) !important;
}

.tw-ring-offset-red-700\/95 {
  --tw-ring-offset-color: rgb(185 28 28 / 0.95) !important;
}

.tw-ring-offset-red-800 {
  --tw-ring-offset-color: #991b1b !important;
}

.tw-ring-offset-red-800\/0 {
  --tw-ring-offset-color: rgb(153 27 27 / 0) !important;
}

.tw-ring-offset-red-800\/10 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.1) !important;
}

.tw-ring-offset-red-800\/100 {
  --tw-ring-offset-color: rgb(153 27 27 / 1) !important;
}

.tw-ring-offset-red-800\/15 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.15) !important;
}

.tw-ring-offset-red-800\/20 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.2) !important;
}

.tw-ring-offset-red-800\/25 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.25) !important;
}

.tw-ring-offset-red-800\/30 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.3) !important;
}

.tw-ring-offset-red-800\/35 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.35) !important;
}

.tw-ring-offset-red-800\/40 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.4) !important;
}

.tw-ring-offset-red-800\/45 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.45) !important;
}

.tw-ring-offset-red-800\/5 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.05) !important;
}

.tw-ring-offset-red-800\/50 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.5) !important;
}

.tw-ring-offset-red-800\/55 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.55) !important;
}

.tw-ring-offset-red-800\/60 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.6) !important;
}

.tw-ring-offset-red-800\/65 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.65) !important;
}

.tw-ring-offset-red-800\/70 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.7) !important;
}

.tw-ring-offset-red-800\/75 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.75) !important;
}

.tw-ring-offset-red-800\/80 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.8) !important;
}

.tw-ring-offset-red-800\/85 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.85) !important;
}

.tw-ring-offset-red-800\/90 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.9) !important;
}

.tw-ring-offset-red-800\/95 {
  --tw-ring-offset-color: rgb(153 27 27 / 0.95) !important;
}

.tw-ring-offset-red-900 {
  --tw-ring-offset-color: #7f1d1d !important;
}

.tw-ring-offset-red-900\/0 {
  --tw-ring-offset-color: rgb(127 29 29 / 0) !important;
}

.tw-ring-offset-red-900\/10 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.1) !important;
}

.tw-ring-offset-red-900\/100 {
  --tw-ring-offset-color: rgb(127 29 29 / 1) !important;
}

.tw-ring-offset-red-900\/15 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.15) !important;
}

.tw-ring-offset-red-900\/20 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.2) !important;
}

.tw-ring-offset-red-900\/25 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.25) !important;
}

.tw-ring-offset-red-900\/30 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.3) !important;
}

.tw-ring-offset-red-900\/35 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.35) !important;
}

.tw-ring-offset-red-900\/40 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.4) !important;
}

.tw-ring-offset-red-900\/45 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.45) !important;
}

.tw-ring-offset-red-900\/5 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.05) !important;
}

.tw-ring-offset-red-900\/50 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.5) !important;
}

.tw-ring-offset-red-900\/55 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.55) !important;
}

.tw-ring-offset-red-900\/60 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.6) !important;
}

.tw-ring-offset-red-900\/65 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.65) !important;
}

.tw-ring-offset-red-900\/70 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.7) !important;
}

.tw-ring-offset-red-900\/75 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.75) !important;
}

.tw-ring-offset-red-900\/80 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.8) !important;
}

.tw-ring-offset-red-900\/85 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.85) !important;
}

.tw-ring-offset-red-900\/90 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.9) !important;
}

.tw-ring-offset-red-900\/95 {
  --tw-ring-offset-color: rgb(127 29 29 / 0.95) !important;
}

.tw-ring-offset-red-950 {
  --tw-ring-offset-color: #450a0a !important;
}

.tw-ring-offset-red-950\/0 {
  --tw-ring-offset-color: rgb(69 10 10 / 0) !important;
}

.tw-ring-offset-red-950\/10 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.1) !important;
}

.tw-ring-offset-red-950\/100 {
  --tw-ring-offset-color: rgb(69 10 10 / 1) !important;
}

.tw-ring-offset-red-950\/15 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.15) !important;
}

.tw-ring-offset-red-950\/20 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.2) !important;
}

.tw-ring-offset-red-950\/25 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.25) !important;
}

.tw-ring-offset-red-950\/30 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.3) !important;
}

.tw-ring-offset-red-950\/35 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.35) !important;
}

.tw-ring-offset-red-950\/40 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.4) !important;
}

.tw-ring-offset-red-950\/45 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.45) !important;
}

.tw-ring-offset-red-950\/5 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.05) !important;
}

.tw-ring-offset-red-950\/50 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.5) !important;
}

.tw-ring-offset-red-950\/55 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.55) !important;
}

.tw-ring-offset-red-950\/60 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.6) !important;
}

.tw-ring-offset-red-950\/65 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.65) !important;
}

.tw-ring-offset-red-950\/70 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.7) !important;
}

.tw-ring-offset-red-950\/75 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.75) !important;
}

.tw-ring-offset-red-950\/80 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.8) !important;
}

.tw-ring-offset-red-950\/85 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.85) !important;
}

.tw-ring-offset-red-950\/90 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.9) !important;
}

.tw-ring-offset-red-950\/95 {
  --tw-ring-offset-color: rgb(69 10 10 / 0.95) !important;
}

.tw-ring-offset-rose-100 {
  --tw-ring-offset-color: #ffe4e6 !important;
}

.tw-ring-offset-rose-100\/0 {
  --tw-ring-offset-color: rgb(255 228 230 / 0) !important;
}

.tw-ring-offset-rose-100\/10 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.1) !important;
}

.tw-ring-offset-rose-100\/100 {
  --tw-ring-offset-color: rgb(255 228 230 / 1) !important;
}

.tw-ring-offset-rose-100\/15 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.15) !important;
}

.tw-ring-offset-rose-100\/20 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.2) !important;
}

.tw-ring-offset-rose-100\/25 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.25) !important;
}

.tw-ring-offset-rose-100\/30 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.3) !important;
}

.tw-ring-offset-rose-100\/35 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.35) !important;
}

.tw-ring-offset-rose-100\/40 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.4) !important;
}

.tw-ring-offset-rose-100\/45 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.45) !important;
}

.tw-ring-offset-rose-100\/5 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.05) !important;
}

.tw-ring-offset-rose-100\/50 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.5) !important;
}

.tw-ring-offset-rose-100\/55 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.55) !important;
}

.tw-ring-offset-rose-100\/60 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.6) !important;
}

.tw-ring-offset-rose-100\/65 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.65) !important;
}

.tw-ring-offset-rose-100\/70 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.7) !important;
}

.tw-ring-offset-rose-100\/75 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.75) !important;
}

.tw-ring-offset-rose-100\/80 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.8) !important;
}

.tw-ring-offset-rose-100\/85 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.85) !important;
}

.tw-ring-offset-rose-100\/90 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.9) !important;
}

.tw-ring-offset-rose-100\/95 {
  --tw-ring-offset-color: rgb(255 228 230 / 0.95) !important;
}

.tw-ring-offset-rose-200 {
  --tw-ring-offset-color: #fecdd3 !important;
}

.tw-ring-offset-rose-200\/0 {
  --tw-ring-offset-color: rgb(254 205 211 / 0) !important;
}

.tw-ring-offset-rose-200\/10 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.1) !important;
}

.tw-ring-offset-rose-200\/100 {
  --tw-ring-offset-color: rgb(254 205 211 / 1) !important;
}

.tw-ring-offset-rose-200\/15 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.15) !important;
}

.tw-ring-offset-rose-200\/20 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.2) !important;
}

.tw-ring-offset-rose-200\/25 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.25) !important;
}

.tw-ring-offset-rose-200\/30 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.3) !important;
}

.tw-ring-offset-rose-200\/35 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.35) !important;
}

.tw-ring-offset-rose-200\/40 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.4) !important;
}

.tw-ring-offset-rose-200\/45 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.45) !important;
}

.tw-ring-offset-rose-200\/5 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.05) !important;
}

.tw-ring-offset-rose-200\/50 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.5) !important;
}

.tw-ring-offset-rose-200\/55 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.55) !important;
}

.tw-ring-offset-rose-200\/60 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.6) !important;
}

.tw-ring-offset-rose-200\/65 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.65) !important;
}

.tw-ring-offset-rose-200\/70 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.7) !important;
}

.tw-ring-offset-rose-200\/75 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.75) !important;
}

.tw-ring-offset-rose-200\/80 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.8) !important;
}

.tw-ring-offset-rose-200\/85 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.85) !important;
}

.tw-ring-offset-rose-200\/90 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.9) !important;
}

.tw-ring-offset-rose-200\/95 {
  --tw-ring-offset-color: rgb(254 205 211 / 0.95) !important;
}

.tw-ring-offset-rose-300 {
  --tw-ring-offset-color: #fda4af !important;
}

.tw-ring-offset-rose-300\/0 {
  --tw-ring-offset-color: rgb(253 164 175 / 0) !important;
}

.tw-ring-offset-rose-300\/10 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.1) !important;
}

.tw-ring-offset-rose-300\/100 {
  --tw-ring-offset-color: rgb(253 164 175 / 1) !important;
}

.tw-ring-offset-rose-300\/15 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.15) !important;
}

.tw-ring-offset-rose-300\/20 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.2) !important;
}

.tw-ring-offset-rose-300\/25 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.25) !important;
}

.tw-ring-offset-rose-300\/30 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.3) !important;
}

.tw-ring-offset-rose-300\/35 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.35) !important;
}

.tw-ring-offset-rose-300\/40 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.4) !important;
}

.tw-ring-offset-rose-300\/45 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.45) !important;
}

.tw-ring-offset-rose-300\/5 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.05) !important;
}

.tw-ring-offset-rose-300\/50 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.5) !important;
}

.tw-ring-offset-rose-300\/55 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.55) !important;
}

.tw-ring-offset-rose-300\/60 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.6) !important;
}

.tw-ring-offset-rose-300\/65 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.65) !important;
}

.tw-ring-offset-rose-300\/70 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.7) !important;
}

.tw-ring-offset-rose-300\/75 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.75) !important;
}

.tw-ring-offset-rose-300\/80 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.8) !important;
}

.tw-ring-offset-rose-300\/85 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.85) !important;
}

.tw-ring-offset-rose-300\/90 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.9) !important;
}

.tw-ring-offset-rose-300\/95 {
  --tw-ring-offset-color: rgb(253 164 175 / 0.95) !important;
}

.tw-ring-offset-rose-400 {
  --tw-ring-offset-color: #fb7185 !important;
}

.tw-ring-offset-rose-400\/0 {
  --tw-ring-offset-color: rgb(251 113 133 / 0) !important;
}

.tw-ring-offset-rose-400\/10 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.1) !important;
}

.tw-ring-offset-rose-400\/100 {
  --tw-ring-offset-color: rgb(251 113 133 / 1) !important;
}

.tw-ring-offset-rose-400\/15 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.15) !important;
}

.tw-ring-offset-rose-400\/20 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.2) !important;
}

.tw-ring-offset-rose-400\/25 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.25) !important;
}

.tw-ring-offset-rose-400\/30 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.3) !important;
}

.tw-ring-offset-rose-400\/35 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.35) !important;
}

.tw-ring-offset-rose-400\/40 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.4) !important;
}

.tw-ring-offset-rose-400\/45 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.45) !important;
}

.tw-ring-offset-rose-400\/5 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.05) !important;
}

.tw-ring-offset-rose-400\/50 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.5) !important;
}

.tw-ring-offset-rose-400\/55 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.55) !important;
}

.tw-ring-offset-rose-400\/60 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.6) !important;
}

.tw-ring-offset-rose-400\/65 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.65) !important;
}

.tw-ring-offset-rose-400\/70 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.7) !important;
}

.tw-ring-offset-rose-400\/75 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.75) !important;
}

.tw-ring-offset-rose-400\/80 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.8) !important;
}

.tw-ring-offset-rose-400\/85 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.85) !important;
}

.tw-ring-offset-rose-400\/90 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.9) !important;
}

.tw-ring-offset-rose-400\/95 {
  --tw-ring-offset-color: rgb(251 113 133 / 0.95) !important;
}

.tw-ring-offset-rose-50 {
  --tw-ring-offset-color: #fff1f2 !important;
}

.tw-ring-offset-rose-50\/0 {
  --tw-ring-offset-color: rgb(255 241 242 / 0) !important;
}

.tw-ring-offset-rose-50\/10 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.1) !important;
}

.tw-ring-offset-rose-50\/100 {
  --tw-ring-offset-color: rgb(255 241 242 / 1) !important;
}

.tw-ring-offset-rose-50\/15 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.15) !important;
}

.tw-ring-offset-rose-50\/20 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.2) !important;
}

.tw-ring-offset-rose-50\/25 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.25) !important;
}

.tw-ring-offset-rose-50\/30 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.3) !important;
}

.tw-ring-offset-rose-50\/35 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.35) !important;
}

.tw-ring-offset-rose-50\/40 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.4) !important;
}

.tw-ring-offset-rose-50\/45 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.45) !important;
}

.tw-ring-offset-rose-50\/5 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.05) !important;
}

.tw-ring-offset-rose-50\/50 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.5) !important;
}

.tw-ring-offset-rose-50\/55 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.55) !important;
}

.tw-ring-offset-rose-50\/60 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.6) !important;
}

.tw-ring-offset-rose-50\/65 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.65) !important;
}

.tw-ring-offset-rose-50\/70 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.7) !important;
}

.tw-ring-offset-rose-50\/75 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.75) !important;
}

.tw-ring-offset-rose-50\/80 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.8) !important;
}

.tw-ring-offset-rose-50\/85 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.85) !important;
}

.tw-ring-offset-rose-50\/90 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.9) !important;
}

.tw-ring-offset-rose-50\/95 {
  --tw-ring-offset-color: rgb(255 241 242 / 0.95) !important;
}

.tw-ring-offset-rose-500 {
  --tw-ring-offset-color: #f43f5e !important;
}

.tw-ring-offset-rose-500\/0 {
  --tw-ring-offset-color: rgb(244 63 94 / 0) !important;
}

.tw-ring-offset-rose-500\/10 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.1) !important;
}

.tw-ring-offset-rose-500\/100 {
  --tw-ring-offset-color: rgb(244 63 94 / 1) !important;
}

.tw-ring-offset-rose-500\/15 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.15) !important;
}

.tw-ring-offset-rose-500\/20 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.2) !important;
}

.tw-ring-offset-rose-500\/25 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.25) !important;
}

.tw-ring-offset-rose-500\/30 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.3) !important;
}

.tw-ring-offset-rose-500\/35 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.35) !important;
}

.tw-ring-offset-rose-500\/40 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.4) !important;
}

.tw-ring-offset-rose-500\/45 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.45) !important;
}

.tw-ring-offset-rose-500\/5 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.05) !important;
}

.tw-ring-offset-rose-500\/50 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.5) !important;
}

.tw-ring-offset-rose-500\/55 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.55) !important;
}

.tw-ring-offset-rose-500\/60 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.6) !important;
}

.tw-ring-offset-rose-500\/65 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.65) !important;
}

.tw-ring-offset-rose-500\/70 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.7) !important;
}

.tw-ring-offset-rose-500\/75 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.75) !important;
}

.tw-ring-offset-rose-500\/80 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.8) !important;
}

.tw-ring-offset-rose-500\/85 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.85) !important;
}

.tw-ring-offset-rose-500\/90 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.9) !important;
}

.tw-ring-offset-rose-500\/95 {
  --tw-ring-offset-color: rgb(244 63 94 / 0.95) !important;
}

.tw-ring-offset-rose-600 {
  --tw-ring-offset-color: #e11d48 !important;
}

.tw-ring-offset-rose-600\/0 {
  --tw-ring-offset-color: rgb(225 29 72 / 0) !important;
}

.tw-ring-offset-rose-600\/10 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.1) !important;
}

.tw-ring-offset-rose-600\/100 {
  --tw-ring-offset-color: rgb(225 29 72 / 1) !important;
}

.tw-ring-offset-rose-600\/15 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.15) !important;
}

.tw-ring-offset-rose-600\/20 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.2) !important;
}

.tw-ring-offset-rose-600\/25 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.25) !important;
}

.tw-ring-offset-rose-600\/30 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.3) !important;
}

.tw-ring-offset-rose-600\/35 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.35) !important;
}

.tw-ring-offset-rose-600\/40 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.4) !important;
}

.tw-ring-offset-rose-600\/45 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.45) !important;
}

.tw-ring-offset-rose-600\/5 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.05) !important;
}

.tw-ring-offset-rose-600\/50 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.5) !important;
}

.tw-ring-offset-rose-600\/55 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.55) !important;
}

.tw-ring-offset-rose-600\/60 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.6) !important;
}

.tw-ring-offset-rose-600\/65 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.65) !important;
}

.tw-ring-offset-rose-600\/70 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.7) !important;
}

.tw-ring-offset-rose-600\/75 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.75) !important;
}

.tw-ring-offset-rose-600\/80 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.8) !important;
}

.tw-ring-offset-rose-600\/85 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.85) !important;
}

.tw-ring-offset-rose-600\/90 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.9) !important;
}

.tw-ring-offset-rose-600\/95 {
  --tw-ring-offset-color: rgb(225 29 72 / 0.95) !important;
}

.tw-ring-offset-rose-700 {
  --tw-ring-offset-color: #be123c !important;
}

.tw-ring-offset-rose-700\/0 {
  --tw-ring-offset-color: rgb(190 18 60 / 0) !important;
}

.tw-ring-offset-rose-700\/10 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.1) !important;
}

.tw-ring-offset-rose-700\/100 {
  --tw-ring-offset-color: rgb(190 18 60 / 1) !important;
}

.tw-ring-offset-rose-700\/15 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.15) !important;
}

.tw-ring-offset-rose-700\/20 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.2) !important;
}

.tw-ring-offset-rose-700\/25 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.25) !important;
}

.tw-ring-offset-rose-700\/30 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.3) !important;
}

.tw-ring-offset-rose-700\/35 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.35) !important;
}

.tw-ring-offset-rose-700\/40 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.4) !important;
}

.tw-ring-offset-rose-700\/45 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.45) !important;
}

.tw-ring-offset-rose-700\/5 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.05) !important;
}

.tw-ring-offset-rose-700\/50 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.5) !important;
}

.tw-ring-offset-rose-700\/55 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.55) !important;
}

.tw-ring-offset-rose-700\/60 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.6) !important;
}

.tw-ring-offset-rose-700\/65 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.65) !important;
}

.tw-ring-offset-rose-700\/70 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.7) !important;
}

.tw-ring-offset-rose-700\/75 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.75) !important;
}

.tw-ring-offset-rose-700\/80 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.8) !important;
}

.tw-ring-offset-rose-700\/85 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.85) !important;
}

.tw-ring-offset-rose-700\/90 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.9) !important;
}

.tw-ring-offset-rose-700\/95 {
  --tw-ring-offset-color: rgb(190 18 60 / 0.95) !important;
}

.tw-ring-offset-rose-800 {
  --tw-ring-offset-color: #9f1239 !important;
}

.tw-ring-offset-rose-800\/0 {
  --tw-ring-offset-color: rgb(159 18 57 / 0) !important;
}

.tw-ring-offset-rose-800\/10 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.1) !important;
}

.tw-ring-offset-rose-800\/100 {
  --tw-ring-offset-color: rgb(159 18 57 / 1) !important;
}

.tw-ring-offset-rose-800\/15 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.15) !important;
}

.tw-ring-offset-rose-800\/20 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.2) !important;
}

.tw-ring-offset-rose-800\/25 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.25) !important;
}

.tw-ring-offset-rose-800\/30 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.3) !important;
}

.tw-ring-offset-rose-800\/35 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.35) !important;
}

.tw-ring-offset-rose-800\/40 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.4) !important;
}

.tw-ring-offset-rose-800\/45 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.45) !important;
}

.tw-ring-offset-rose-800\/5 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.05) !important;
}

.tw-ring-offset-rose-800\/50 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.5) !important;
}

.tw-ring-offset-rose-800\/55 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.55) !important;
}

.tw-ring-offset-rose-800\/60 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.6) !important;
}

.tw-ring-offset-rose-800\/65 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.65) !important;
}

.tw-ring-offset-rose-800\/70 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.7) !important;
}

.tw-ring-offset-rose-800\/75 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.75) !important;
}

.tw-ring-offset-rose-800\/80 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.8) !important;
}

.tw-ring-offset-rose-800\/85 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.85) !important;
}

.tw-ring-offset-rose-800\/90 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.9) !important;
}

.tw-ring-offset-rose-800\/95 {
  --tw-ring-offset-color: rgb(159 18 57 / 0.95) !important;
}

.tw-ring-offset-rose-900 {
  --tw-ring-offset-color: #881337 !important;
}

.tw-ring-offset-rose-900\/0 {
  --tw-ring-offset-color: rgb(136 19 55 / 0) !important;
}

.tw-ring-offset-rose-900\/10 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.1) !important;
}

.tw-ring-offset-rose-900\/100 {
  --tw-ring-offset-color: rgb(136 19 55 / 1) !important;
}

.tw-ring-offset-rose-900\/15 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.15) !important;
}

.tw-ring-offset-rose-900\/20 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.2) !important;
}

.tw-ring-offset-rose-900\/25 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.25) !important;
}

.tw-ring-offset-rose-900\/30 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.3) !important;
}

.tw-ring-offset-rose-900\/35 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.35) !important;
}

.tw-ring-offset-rose-900\/40 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.4) !important;
}

.tw-ring-offset-rose-900\/45 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.45) !important;
}

.tw-ring-offset-rose-900\/5 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.05) !important;
}

.tw-ring-offset-rose-900\/50 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.5) !important;
}

.tw-ring-offset-rose-900\/55 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.55) !important;
}

.tw-ring-offset-rose-900\/60 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.6) !important;
}

.tw-ring-offset-rose-900\/65 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.65) !important;
}

.tw-ring-offset-rose-900\/70 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.7) !important;
}

.tw-ring-offset-rose-900\/75 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.75) !important;
}

.tw-ring-offset-rose-900\/80 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.8) !important;
}

.tw-ring-offset-rose-900\/85 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.85) !important;
}

.tw-ring-offset-rose-900\/90 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.9) !important;
}

.tw-ring-offset-rose-900\/95 {
  --tw-ring-offset-color: rgb(136 19 55 / 0.95) !important;
}

.tw-ring-offset-rose-950 {
  --tw-ring-offset-color: #4c0519 !important;
}

.tw-ring-offset-rose-950\/0 {
  --tw-ring-offset-color: rgb(76 5 25 / 0) !important;
}

.tw-ring-offset-rose-950\/10 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.1) !important;
}

.tw-ring-offset-rose-950\/100 {
  --tw-ring-offset-color: rgb(76 5 25 / 1) !important;
}

.tw-ring-offset-rose-950\/15 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.15) !important;
}

.tw-ring-offset-rose-950\/20 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.2) !important;
}

.tw-ring-offset-rose-950\/25 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.25) !important;
}

.tw-ring-offset-rose-950\/30 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.3) !important;
}

.tw-ring-offset-rose-950\/35 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.35) !important;
}

.tw-ring-offset-rose-950\/40 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.4) !important;
}

.tw-ring-offset-rose-950\/45 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.45) !important;
}

.tw-ring-offset-rose-950\/5 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.05) !important;
}

.tw-ring-offset-rose-950\/50 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.5) !important;
}

.tw-ring-offset-rose-950\/55 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.55) !important;
}

.tw-ring-offset-rose-950\/60 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.6) !important;
}

.tw-ring-offset-rose-950\/65 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.65) !important;
}

.tw-ring-offset-rose-950\/70 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.7) !important;
}

.tw-ring-offset-rose-950\/75 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.75) !important;
}

.tw-ring-offset-rose-950\/80 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.8) !important;
}

.tw-ring-offset-rose-950\/85 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.85) !important;
}

.tw-ring-offset-rose-950\/90 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.9) !important;
}

.tw-ring-offset-rose-950\/95 {
  --tw-ring-offset-color: rgb(76 5 25 / 0.95) !important;
}

.tw-ring-offset-sky-100 {
  --tw-ring-offset-color: #e0f2fe !important;
}

.tw-ring-offset-sky-100\/0 {
  --tw-ring-offset-color: rgb(224 242 254 / 0) !important;
}

.tw-ring-offset-sky-100\/10 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.1) !important;
}

.tw-ring-offset-sky-100\/100 {
  --tw-ring-offset-color: rgb(224 242 254 / 1) !important;
}

.tw-ring-offset-sky-100\/15 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.15) !important;
}

.tw-ring-offset-sky-100\/20 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.2) !important;
}

.tw-ring-offset-sky-100\/25 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.25) !important;
}

.tw-ring-offset-sky-100\/30 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.3) !important;
}

.tw-ring-offset-sky-100\/35 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.35) !important;
}

.tw-ring-offset-sky-100\/40 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.4) !important;
}

.tw-ring-offset-sky-100\/45 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.45) !important;
}

.tw-ring-offset-sky-100\/5 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.05) !important;
}

.tw-ring-offset-sky-100\/50 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.5) !important;
}

.tw-ring-offset-sky-100\/55 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.55) !important;
}

.tw-ring-offset-sky-100\/60 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.6) !important;
}

.tw-ring-offset-sky-100\/65 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.65) !important;
}

.tw-ring-offset-sky-100\/70 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.7) !important;
}

.tw-ring-offset-sky-100\/75 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.75) !important;
}

.tw-ring-offset-sky-100\/80 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.8) !important;
}

.tw-ring-offset-sky-100\/85 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.85) !important;
}

.tw-ring-offset-sky-100\/90 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.9) !important;
}

.tw-ring-offset-sky-100\/95 {
  --tw-ring-offset-color: rgb(224 242 254 / 0.95) !important;
}

.tw-ring-offset-sky-200 {
  --tw-ring-offset-color: #bae6fd !important;
}

.tw-ring-offset-sky-200\/0 {
  --tw-ring-offset-color: rgb(186 230 253 / 0) !important;
}

.tw-ring-offset-sky-200\/10 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.1) !important;
}

.tw-ring-offset-sky-200\/100 {
  --tw-ring-offset-color: rgb(186 230 253 / 1) !important;
}

.tw-ring-offset-sky-200\/15 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.15) !important;
}

.tw-ring-offset-sky-200\/20 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.2) !important;
}

.tw-ring-offset-sky-200\/25 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.25) !important;
}

.tw-ring-offset-sky-200\/30 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.3) !important;
}

.tw-ring-offset-sky-200\/35 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.35) !important;
}

.tw-ring-offset-sky-200\/40 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.4) !important;
}

.tw-ring-offset-sky-200\/45 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.45) !important;
}

.tw-ring-offset-sky-200\/5 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.05) !important;
}

.tw-ring-offset-sky-200\/50 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.5) !important;
}

.tw-ring-offset-sky-200\/55 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.55) !important;
}

.tw-ring-offset-sky-200\/60 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.6) !important;
}

.tw-ring-offset-sky-200\/65 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.65) !important;
}

.tw-ring-offset-sky-200\/70 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.7) !important;
}

.tw-ring-offset-sky-200\/75 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.75) !important;
}

.tw-ring-offset-sky-200\/80 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.8) !important;
}

.tw-ring-offset-sky-200\/85 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.85) !important;
}

.tw-ring-offset-sky-200\/90 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.9) !important;
}

.tw-ring-offset-sky-200\/95 {
  --tw-ring-offset-color: rgb(186 230 253 / 0.95) !important;
}

.tw-ring-offset-sky-300 {
  --tw-ring-offset-color: #7dd3fc !important;
}

.tw-ring-offset-sky-300\/0 {
  --tw-ring-offset-color: rgb(125 211 252 / 0) !important;
}

.tw-ring-offset-sky-300\/10 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.1) !important;
}

.tw-ring-offset-sky-300\/100 {
  --tw-ring-offset-color: rgb(125 211 252 / 1) !important;
}

.tw-ring-offset-sky-300\/15 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.15) !important;
}

.tw-ring-offset-sky-300\/20 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.2) !important;
}

.tw-ring-offset-sky-300\/25 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.25) !important;
}

.tw-ring-offset-sky-300\/30 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.3) !important;
}

.tw-ring-offset-sky-300\/35 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.35) !important;
}

.tw-ring-offset-sky-300\/40 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.4) !important;
}

.tw-ring-offset-sky-300\/45 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.45) !important;
}

.tw-ring-offset-sky-300\/5 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.05) !important;
}

.tw-ring-offset-sky-300\/50 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.5) !important;
}

.tw-ring-offset-sky-300\/55 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.55) !important;
}

.tw-ring-offset-sky-300\/60 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.6) !important;
}

.tw-ring-offset-sky-300\/65 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.65) !important;
}

.tw-ring-offset-sky-300\/70 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.7) !important;
}

.tw-ring-offset-sky-300\/75 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.75) !important;
}

.tw-ring-offset-sky-300\/80 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.8) !important;
}

.tw-ring-offset-sky-300\/85 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.85) !important;
}

.tw-ring-offset-sky-300\/90 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.9) !important;
}

.tw-ring-offset-sky-300\/95 {
  --tw-ring-offset-color: rgb(125 211 252 / 0.95) !important;
}

.tw-ring-offset-sky-400 {
  --tw-ring-offset-color: #38bdf8 !important;
}

.tw-ring-offset-sky-400\/0 {
  --tw-ring-offset-color: rgb(56 189 248 / 0) !important;
}

.tw-ring-offset-sky-400\/10 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.1) !important;
}

.tw-ring-offset-sky-400\/100 {
  --tw-ring-offset-color: rgb(56 189 248 / 1) !important;
}

.tw-ring-offset-sky-400\/15 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.15) !important;
}

.tw-ring-offset-sky-400\/20 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.2) !important;
}

.tw-ring-offset-sky-400\/25 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.25) !important;
}

.tw-ring-offset-sky-400\/30 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.3) !important;
}

.tw-ring-offset-sky-400\/35 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.35) !important;
}

.tw-ring-offset-sky-400\/40 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.4) !important;
}

.tw-ring-offset-sky-400\/45 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.45) !important;
}

.tw-ring-offset-sky-400\/5 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.05) !important;
}

.tw-ring-offset-sky-400\/50 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.5) !important;
}

.tw-ring-offset-sky-400\/55 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.55) !important;
}

.tw-ring-offset-sky-400\/60 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.6) !important;
}

.tw-ring-offset-sky-400\/65 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.65) !important;
}

.tw-ring-offset-sky-400\/70 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.7) !important;
}

.tw-ring-offset-sky-400\/75 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.75) !important;
}

.tw-ring-offset-sky-400\/80 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.8) !important;
}

.tw-ring-offset-sky-400\/85 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.85) !important;
}

.tw-ring-offset-sky-400\/90 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.9) !important;
}

.tw-ring-offset-sky-400\/95 {
  --tw-ring-offset-color: rgb(56 189 248 / 0.95) !important;
}

.tw-ring-offset-sky-50 {
  --tw-ring-offset-color: #f0f9ff !important;
}

.tw-ring-offset-sky-50\/0 {
  --tw-ring-offset-color: rgb(240 249 255 / 0) !important;
}

.tw-ring-offset-sky-50\/10 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.1) !important;
}

.tw-ring-offset-sky-50\/100 {
  --tw-ring-offset-color: rgb(240 249 255 / 1) !important;
}

.tw-ring-offset-sky-50\/15 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.15) !important;
}

.tw-ring-offset-sky-50\/20 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.2) !important;
}

.tw-ring-offset-sky-50\/25 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.25) !important;
}

.tw-ring-offset-sky-50\/30 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.3) !important;
}

.tw-ring-offset-sky-50\/35 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.35) !important;
}

.tw-ring-offset-sky-50\/40 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.4) !important;
}

.tw-ring-offset-sky-50\/45 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.45) !important;
}

.tw-ring-offset-sky-50\/5 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.05) !important;
}

.tw-ring-offset-sky-50\/50 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.5) !important;
}

.tw-ring-offset-sky-50\/55 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.55) !important;
}

.tw-ring-offset-sky-50\/60 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.6) !important;
}

.tw-ring-offset-sky-50\/65 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.65) !important;
}

.tw-ring-offset-sky-50\/70 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.7) !important;
}

.tw-ring-offset-sky-50\/75 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.75) !important;
}

.tw-ring-offset-sky-50\/80 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.8) !important;
}

.tw-ring-offset-sky-50\/85 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.85) !important;
}

.tw-ring-offset-sky-50\/90 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.9) !important;
}

.tw-ring-offset-sky-50\/95 {
  --tw-ring-offset-color: rgb(240 249 255 / 0.95) !important;
}

.tw-ring-offset-sky-500 {
  --tw-ring-offset-color: #0ea5e9 !important;
}

.tw-ring-offset-sky-500\/0 {
  --tw-ring-offset-color: rgb(14 165 233 / 0) !important;
}

.tw-ring-offset-sky-500\/10 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.1) !important;
}

.tw-ring-offset-sky-500\/100 {
  --tw-ring-offset-color: rgb(14 165 233 / 1) !important;
}

.tw-ring-offset-sky-500\/15 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.15) !important;
}

.tw-ring-offset-sky-500\/20 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.2) !important;
}

.tw-ring-offset-sky-500\/25 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.25) !important;
}

.tw-ring-offset-sky-500\/30 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.3) !important;
}

.tw-ring-offset-sky-500\/35 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.35) !important;
}

.tw-ring-offset-sky-500\/40 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.4) !important;
}

.tw-ring-offset-sky-500\/45 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.45) !important;
}

.tw-ring-offset-sky-500\/5 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.05) !important;
}

.tw-ring-offset-sky-500\/50 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.5) !important;
}

.tw-ring-offset-sky-500\/55 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.55) !important;
}

.tw-ring-offset-sky-500\/60 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.6) !important;
}

.tw-ring-offset-sky-500\/65 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.65) !important;
}

.tw-ring-offset-sky-500\/70 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.7) !important;
}

.tw-ring-offset-sky-500\/75 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.75) !important;
}

.tw-ring-offset-sky-500\/80 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.8) !important;
}

.tw-ring-offset-sky-500\/85 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.85) !important;
}

.tw-ring-offset-sky-500\/90 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.9) !important;
}

.tw-ring-offset-sky-500\/95 {
  --tw-ring-offset-color: rgb(14 165 233 / 0.95) !important;
}

.tw-ring-offset-sky-600 {
  --tw-ring-offset-color: #0284c7 !important;
}

.tw-ring-offset-sky-600\/0 {
  --tw-ring-offset-color: rgb(2 132 199 / 0) !important;
}

.tw-ring-offset-sky-600\/10 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.1) !important;
}

.tw-ring-offset-sky-600\/100 {
  --tw-ring-offset-color: rgb(2 132 199 / 1) !important;
}

.tw-ring-offset-sky-600\/15 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.15) !important;
}

.tw-ring-offset-sky-600\/20 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.2) !important;
}

.tw-ring-offset-sky-600\/25 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.25) !important;
}

.tw-ring-offset-sky-600\/30 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.3) !important;
}

.tw-ring-offset-sky-600\/35 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.35) !important;
}

.tw-ring-offset-sky-600\/40 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.4) !important;
}

.tw-ring-offset-sky-600\/45 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.45) !important;
}

.tw-ring-offset-sky-600\/5 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.05) !important;
}

.tw-ring-offset-sky-600\/50 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.5) !important;
}

.tw-ring-offset-sky-600\/55 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.55) !important;
}

.tw-ring-offset-sky-600\/60 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.6) !important;
}

.tw-ring-offset-sky-600\/65 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.65) !important;
}

.tw-ring-offset-sky-600\/70 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.7) !important;
}

.tw-ring-offset-sky-600\/75 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.75) !important;
}

.tw-ring-offset-sky-600\/80 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.8) !important;
}

.tw-ring-offset-sky-600\/85 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.85) !important;
}

.tw-ring-offset-sky-600\/90 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.9) !important;
}

.tw-ring-offset-sky-600\/95 {
  --tw-ring-offset-color: rgb(2 132 199 / 0.95) !important;
}

.tw-ring-offset-sky-700 {
  --tw-ring-offset-color: #0369a1 !important;
}

.tw-ring-offset-sky-700\/0 {
  --tw-ring-offset-color: rgb(3 105 161 / 0) !important;
}

.tw-ring-offset-sky-700\/10 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.1) !important;
}

.tw-ring-offset-sky-700\/100 {
  --tw-ring-offset-color: rgb(3 105 161 / 1) !important;
}

.tw-ring-offset-sky-700\/15 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.15) !important;
}

.tw-ring-offset-sky-700\/20 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.2) !important;
}

.tw-ring-offset-sky-700\/25 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.25) !important;
}

.tw-ring-offset-sky-700\/30 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.3) !important;
}

.tw-ring-offset-sky-700\/35 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.35) !important;
}

.tw-ring-offset-sky-700\/40 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.4) !important;
}

.tw-ring-offset-sky-700\/45 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.45) !important;
}

.tw-ring-offset-sky-700\/5 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.05) !important;
}

.tw-ring-offset-sky-700\/50 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.5) !important;
}

.tw-ring-offset-sky-700\/55 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.55) !important;
}

.tw-ring-offset-sky-700\/60 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.6) !important;
}

.tw-ring-offset-sky-700\/65 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.65) !important;
}

.tw-ring-offset-sky-700\/70 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.7) !important;
}

.tw-ring-offset-sky-700\/75 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.75) !important;
}

.tw-ring-offset-sky-700\/80 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.8) !important;
}

.tw-ring-offset-sky-700\/85 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.85) !important;
}

.tw-ring-offset-sky-700\/90 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.9) !important;
}

.tw-ring-offset-sky-700\/95 {
  --tw-ring-offset-color: rgb(3 105 161 / 0.95) !important;
}

.tw-ring-offset-sky-800 {
  --tw-ring-offset-color: #075985 !important;
}

.tw-ring-offset-sky-800\/0 {
  --tw-ring-offset-color: rgb(7 89 133 / 0) !important;
}

.tw-ring-offset-sky-800\/10 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.1) !important;
}

.tw-ring-offset-sky-800\/100 {
  --tw-ring-offset-color: rgb(7 89 133 / 1) !important;
}

.tw-ring-offset-sky-800\/15 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.15) !important;
}

.tw-ring-offset-sky-800\/20 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.2) !important;
}

.tw-ring-offset-sky-800\/25 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.25) !important;
}

.tw-ring-offset-sky-800\/30 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.3) !important;
}

.tw-ring-offset-sky-800\/35 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.35) !important;
}

.tw-ring-offset-sky-800\/40 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.4) !important;
}

.tw-ring-offset-sky-800\/45 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.45) !important;
}

.tw-ring-offset-sky-800\/5 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.05) !important;
}

.tw-ring-offset-sky-800\/50 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.5) !important;
}

.tw-ring-offset-sky-800\/55 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.55) !important;
}

.tw-ring-offset-sky-800\/60 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.6) !important;
}

.tw-ring-offset-sky-800\/65 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.65) !important;
}

.tw-ring-offset-sky-800\/70 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.7) !important;
}

.tw-ring-offset-sky-800\/75 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.75) !important;
}

.tw-ring-offset-sky-800\/80 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.8) !important;
}

.tw-ring-offset-sky-800\/85 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.85) !important;
}

.tw-ring-offset-sky-800\/90 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.9) !important;
}

.tw-ring-offset-sky-800\/95 {
  --tw-ring-offset-color: rgb(7 89 133 / 0.95) !important;
}

.tw-ring-offset-sky-900 {
  --tw-ring-offset-color: #0c4a6e !important;
}

.tw-ring-offset-sky-900\/0 {
  --tw-ring-offset-color: rgb(12 74 110 / 0) !important;
}

.tw-ring-offset-sky-900\/10 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.1) !important;
}

.tw-ring-offset-sky-900\/100 {
  --tw-ring-offset-color: rgb(12 74 110 / 1) !important;
}

.tw-ring-offset-sky-900\/15 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.15) !important;
}

.tw-ring-offset-sky-900\/20 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.2) !important;
}

.tw-ring-offset-sky-900\/25 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.25) !important;
}

.tw-ring-offset-sky-900\/30 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.3) !important;
}

.tw-ring-offset-sky-900\/35 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.35) !important;
}

.tw-ring-offset-sky-900\/40 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.4) !important;
}

.tw-ring-offset-sky-900\/45 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.45) !important;
}

.tw-ring-offset-sky-900\/5 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.05) !important;
}

.tw-ring-offset-sky-900\/50 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.5) !important;
}

.tw-ring-offset-sky-900\/55 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.55) !important;
}

.tw-ring-offset-sky-900\/60 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.6) !important;
}

.tw-ring-offset-sky-900\/65 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.65) !important;
}

.tw-ring-offset-sky-900\/70 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.7) !important;
}

.tw-ring-offset-sky-900\/75 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.75) !important;
}

.tw-ring-offset-sky-900\/80 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.8) !important;
}

.tw-ring-offset-sky-900\/85 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.85) !important;
}

.tw-ring-offset-sky-900\/90 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.9) !important;
}

.tw-ring-offset-sky-900\/95 {
  --tw-ring-offset-color: rgb(12 74 110 / 0.95) !important;
}

.tw-ring-offset-sky-950 {
  --tw-ring-offset-color: #082f49 !important;
}

.tw-ring-offset-sky-950\/0 {
  --tw-ring-offset-color: rgb(8 47 73 / 0) !important;
}

.tw-ring-offset-sky-950\/10 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.1) !important;
}

.tw-ring-offset-sky-950\/100 {
  --tw-ring-offset-color: rgb(8 47 73 / 1) !important;
}

.tw-ring-offset-sky-950\/15 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.15) !important;
}

.tw-ring-offset-sky-950\/20 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.2) !important;
}

.tw-ring-offset-sky-950\/25 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.25) !important;
}

.tw-ring-offset-sky-950\/30 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.3) !important;
}

.tw-ring-offset-sky-950\/35 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.35) !important;
}

.tw-ring-offset-sky-950\/40 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.4) !important;
}

.tw-ring-offset-sky-950\/45 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.45) !important;
}

.tw-ring-offset-sky-950\/5 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.05) !important;
}

.tw-ring-offset-sky-950\/50 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.5) !important;
}

.tw-ring-offset-sky-950\/55 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.55) !important;
}

.tw-ring-offset-sky-950\/60 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.6) !important;
}

.tw-ring-offset-sky-950\/65 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.65) !important;
}

.tw-ring-offset-sky-950\/70 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.7) !important;
}

.tw-ring-offset-sky-950\/75 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.75) !important;
}

.tw-ring-offset-sky-950\/80 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.8) !important;
}

.tw-ring-offset-sky-950\/85 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.85) !important;
}

.tw-ring-offset-sky-950\/90 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.9) !important;
}

.tw-ring-offset-sky-950\/95 {
  --tw-ring-offset-color: rgb(8 47 73 / 0.95) !important;
}

.tw-ring-offset-slate-100 {
  --tw-ring-offset-color: #f1f5f9 !important;
}

.tw-ring-offset-slate-100\/0 {
  --tw-ring-offset-color: rgb(241 245 249 / 0) !important;
}

.tw-ring-offset-slate-100\/10 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.1) !important;
}

.tw-ring-offset-slate-100\/100 {
  --tw-ring-offset-color: rgb(241 245 249 / 1) !important;
}

.tw-ring-offset-slate-100\/15 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.15) !important;
}

.tw-ring-offset-slate-100\/20 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.2) !important;
}

.tw-ring-offset-slate-100\/25 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.25) !important;
}

.tw-ring-offset-slate-100\/30 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.3) !important;
}

.tw-ring-offset-slate-100\/35 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.35) !important;
}

.tw-ring-offset-slate-100\/40 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.4) !important;
}

.tw-ring-offset-slate-100\/45 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.45) !important;
}

.tw-ring-offset-slate-100\/5 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.05) !important;
}

.tw-ring-offset-slate-100\/50 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.5) !important;
}

.tw-ring-offset-slate-100\/55 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.55) !important;
}

.tw-ring-offset-slate-100\/60 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.6) !important;
}

.tw-ring-offset-slate-100\/65 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.65) !important;
}

.tw-ring-offset-slate-100\/70 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.7) !important;
}

.tw-ring-offset-slate-100\/75 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.75) !important;
}

.tw-ring-offset-slate-100\/80 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.8) !important;
}

.tw-ring-offset-slate-100\/85 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.85) !important;
}

.tw-ring-offset-slate-100\/90 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.9) !important;
}

.tw-ring-offset-slate-100\/95 {
  --tw-ring-offset-color: rgb(241 245 249 / 0.95) !important;
}

.tw-ring-offset-slate-200 {
  --tw-ring-offset-color: #e2e8f0 !important;
}

.tw-ring-offset-slate-200\/0 {
  --tw-ring-offset-color: rgb(226 232 240 / 0) !important;
}

.tw-ring-offset-slate-200\/10 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.1) !important;
}

.tw-ring-offset-slate-200\/100 {
  --tw-ring-offset-color: rgb(226 232 240 / 1) !important;
}

.tw-ring-offset-slate-200\/15 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.15) !important;
}

.tw-ring-offset-slate-200\/20 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.2) !important;
}

.tw-ring-offset-slate-200\/25 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.25) !important;
}

.tw-ring-offset-slate-200\/30 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.3) !important;
}

.tw-ring-offset-slate-200\/35 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.35) !important;
}

.tw-ring-offset-slate-200\/40 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.4) !important;
}

.tw-ring-offset-slate-200\/45 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.45) !important;
}

.tw-ring-offset-slate-200\/5 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.05) !important;
}

.tw-ring-offset-slate-200\/50 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.5) !important;
}

.tw-ring-offset-slate-200\/55 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.55) !important;
}

.tw-ring-offset-slate-200\/60 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.6) !important;
}

.tw-ring-offset-slate-200\/65 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.65) !important;
}

.tw-ring-offset-slate-200\/70 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.7) !important;
}

.tw-ring-offset-slate-200\/75 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.75) !important;
}

.tw-ring-offset-slate-200\/80 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.8) !important;
}

.tw-ring-offset-slate-200\/85 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.85) !important;
}

.tw-ring-offset-slate-200\/90 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.9) !important;
}

.tw-ring-offset-slate-200\/95 {
  --tw-ring-offset-color: rgb(226 232 240 / 0.95) !important;
}

.tw-ring-offset-slate-300 {
  --tw-ring-offset-color: #cbd5e1 !important;
}

.tw-ring-offset-slate-300\/0 {
  --tw-ring-offset-color: rgb(203 213 225 / 0) !important;
}

.tw-ring-offset-slate-300\/10 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.1) !important;
}

.tw-ring-offset-slate-300\/100 {
  --tw-ring-offset-color: rgb(203 213 225 / 1) !important;
}

.tw-ring-offset-slate-300\/15 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.15) !important;
}

.tw-ring-offset-slate-300\/20 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.2) !important;
}

.tw-ring-offset-slate-300\/25 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.25) !important;
}

.tw-ring-offset-slate-300\/30 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.3) !important;
}

.tw-ring-offset-slate-300\/35 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.35) !important;
}

.tw-ring-offset-slate-300\/40 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.4) !important;
}

.tw-ring-offset-slate-300\/45 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.45) !important;
}

.tw-ring-offset-slate-300\/5 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.05) !important;
}

.tw-ring-offset-slate-300\/50 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.5) !important;
}

.tw-ring-offset-slate-300\/55 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.55) !important;
}

.tw-ring-offset-slate-300\/60 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.6) !important;
}

.tw-ring-offset-slate-300\/65 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.65) !important;
}

.tw-ring-offset-slate-300\/70 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.7) !important;
}

.tw-ring-offset-slate-300\/75 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.75) !important;
}

.tw-ring-offset-slate-300\/80 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.8) !important;
}

.tw-ring-offset-slate-300\/85 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.85) !important;
}

.tw-ring-offset-slate-300\/90 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.9) !important;
}

.tw-ring-offset-slate-300\/95 {
  --tw-ring-offset-color: rgb(203 213 225 / 0.95) !important;
}

.tw-ring-offset-slate-400 {
  --tw-ring-offset-color: #94a3b8 !important;
}

.tw-ring-offset-slate-400\/0 {
  --tw-ring-offset-color: rgb(148 163 184 / 0) !important;
}

.tw-ring-offset-slate-400\/10 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.1) !important;
}

.tw-ring-offset-slate-400\/100 {
  --tw-ring-offset-color: rgb(148 163 184 / 1) !important;
}

.tw-ring-offset-slate-400\/15 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.15) !important;
}

.tw-ring-offset-slate-400\/20 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.2) !important;
}

.tw-ring-offset-slate-400\/25 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.25) !important;
}

.tw-ring-offset-slate-400\/30 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.3) !important;
}

.tw-ring-offset-slate-400\/35 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.35) !important;
}

.tw-ring-offset-slate-400\/40 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.4) !important;
}

.tw-ring-offset-slate-400\/45 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.45) !important;
}

.tw-ring-offset-slate-400\/5 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.05) !important;
}

.tw-ring-offset-slate-400\/50 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.5) !important;
}

.tw-ring-offset-slate-400\/55 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.55) !important;
}

.tw-ring-offset-slate-400\/60 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.6) !important;
}

.tw-ring-offset-slate-400\/65 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.65) !important;
}

.tw-ring-offset-slate-400\/70 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.7) !important;
}

.tw-ring-offset-slate-400\/75 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.75) !important;
}

.tw-ring-offset-slate-400\/80 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.8) !important;
}

.tw-ring-offset-slate-400\/85 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.85) !important;
}

.tw-ring-offset-slate-400\/90 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.9) !important;
}

.tw-ring-offset-slate-400\/95 {
  --tw-ring-offset-color: rgb(148 163 184 / 0.95) !important;
}

.tw-ring-offset-slate-50 {
  --tw-ring-offset-color: #f8fafc !important;
}

.tw-ring-offset-slate-50\/0 {
  --tw-ring-offset-color: rgb(248 250 252 / 0) !important;
}

.tw-ring-offset-slate-50\/10 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.1) !important;
}

.tw-ring-offset-slate-50\/100 {
  --tw-ring-offset-color: rgb(248 250 252 / 1) !important;
}

.tw-ring-offset-slate-50\/15 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.15) !important;
}

.tw-ring-offset-slate-50\/20 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.2) !important;
}

.tw-ring-offset-slate-50\/25 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.25) !important;
}

.tw-ring-offset-slate-50\/30 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.3) !important;
}

.tw-ring-offset-slate-50\/35 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.35) !important;
}

.tw-ring-offset-slate-50\/40 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.4) !important;
}

.tw-ring-offset-slate-50\/45 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.45) !important;
}

.tw-ring-offset-slate-50\/5 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.05) !important;
}

.tw-ring-offset-slate-50\/50 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.5) !important;
}

.tw-ring-offset-slate-50\/55 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.55) !important;
}

.tw-ring-offset-slate-50\/60 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.6) !important;
}

.tw-ring-offset-slate-50\/65 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.65) !important;
}

.tw-ring-offset-slate-50\/70 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.7) !important;
}

.tw-ring-offset-slate-50\/75 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.75) !important;
}

.tw-ring-offset-slate-50\/80 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.8) !important;
}

.tw-ring-offset-slate-50\/85 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.85) !important;
}

.tw-ring-offset-slate-50\/90 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.9) !important;
}

.tw-ring-offset-slate-50\/95 {
  --tw-ring-offset-color: rgb(248 250 252 / 0.95) !important;
}

.tw-ring-offset-slate-500 {
  --tw-ring-offset-color: #64748b !important;
}

.tw-ring-offset-slate-500\/0 {
  --tw-ring-offset-color: rgb(100 116 139 / 0) !important;
}

.tw-ring-offset-slate-500\/10 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.1) !important;
}

.tw-ring-offset-slate-500\/100 {
  --tw-ring-offset-color: rgb(100 116 139 / 1) !important;
}

.tw-ring-offset-slate-500\/15 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.15) !important;
}

.tw-ring-offset-slate-500\/20 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.2) !important;
}

.tw-ring-offset-slate-500\/25 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.25) !important;
}

.tw-ring-offset-slate-500\/30 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.3) !important;
}

.tw-ring-offset-slate-500\/35 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.35) !important;
}

.tw-ring-offset-slate-500\/40 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.4) !important;
}

.tw-ring-offset-slate-500\/45 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.45) !important;
}

.tw-ring-offset-slate-500\/5 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.05) !important;
}

.tw-ring-offset-slate-500\/50 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.5) !important;
}

.tw-ring-offset-slate-500\/55 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.55) !important;
}

.tw-ring-offset-slate-500\/60 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.6) !important;
}

.tw-ring-offset-slate-500\/65 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.65) !important;
}

.tw-ring-offset-slate-500\/70 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.7) !important;
}

.tw-ring-offset-slate-500\/75 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.75) !important;
}

.tw-ring-offset-slate-500\/80 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.8) !important;
}

.tw-ring-offset-slate-500\/85 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.85) !important;
}

.tw-ring-offset-slate-500\/90 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.9) !important;
}

.tw-ring-offset-slate-500\/95 {
  --tw-ring-offset-color: rgb(100 116 139 / 0.95) !important;
}

.tw-ring-offset-slate-600 {
  --tw-ring-offset-color: #475569 !important;
}

.tw-ring-offset-slate-600\/0 {
  --tw-ring-offset-color: rgb(71 85 105 / 0) !important;
}

.tw-ring-offset-slate-600\/10 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.1) !important;
}

.tw-ring-offset-slate-600\/100 {
  --tw-ring-offset-color: rgb(71 85 105 / 1) !important;
}

.tw-ring-offset-slate-600\/15 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.15) !important;
}

.tw-ring-offset-slate-600\/20 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.2) !important;
}

.tw-ring-offset-slate-600\/25 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.25) !important;
}

.tw-ring-offset-slate-600\/30 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.3) !important;
}

.tw-ring-offset-slate-600\/35 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.35) !important;
}

.tw-ring-offset-slate-600\/40 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.4) !important;
}

.tw-ring-offset-slate-600\/45 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.45) !important;
}

.tw-ring-offset-slate-600\/5 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.05) !important;
}

.tw-ring-offset-slate-600\/50 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.5) !important;
}

.tw-ring-offset-slate-600\/55 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.55) !important;
}

.tw-ring-offset-slate-600\/60 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.6) !important;
}

.tw-ring-offset-slate-600\/65 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.65) !important;
}

.tw-ring-offset-slate-600\/70 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.7) !important;
}

.tw-ring-offset-slate-600\/75 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.75) !important;
}

.tw-ring-offset-slate-600\/80 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.8) !important;
}

.tw-ring-offset-slate-600\/85 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.85) !important;
}

.tw-ring-offset-slate-600\/90 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.9) !important;
}

.tw-ring-offset-slate-600\/95 {
  --tw-ring-offset-color: rgb(71 85 105 / 0.95) !important;
}

.tw-ring-offset-slate-700 {
  --tw-ring-offset-color: #334155 !important;
}

.tw-ring-offset-slate-700\/0 {
  --tw-ring-offset-color: rgb(51 65 85 / 0) !important;
}

.tw-ring-offset-slate-700\/10 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.1) !important;
}

.tw-ring-offset-slate-700\/100 {
  --tw-ring-offset-color: rgb(51 65 85 / 1) !important;
}

.tw-ring-offset-slate-700\/15 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.15) !important;
}

.tw-ring-offset-slate-700\/20 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.2) !important;
}

.tw-ring-offset-slate-700\/25 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.25) !important;
}

.tw-ring-offset-slate-700\/30 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.3) !important;
}

.tw-ring-offset-slate-700\/35 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.35) !important;
}

.tw-ring-offset-slate-700\/40 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.4) !important;
}

.tw-ring-offset-slate-700\/45 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.45) !important;
}

.tw-ring-offset-slate-700\/5 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.05) !important;
}

.tw-ring-offset-slate-700\/50 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.5) !important;
}

.tw-ring-offset-slate-700\/55 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.55) !important;
}

.tw-ring-offset-slate-700\/60 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.6) !important;
}

.tw-ring-offset-slate-700\/65 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.65) !important;
}

.tw-ring-offset-slate-700\/70 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.7) !important;
}

.tw-ring-offset-slate-700\/75 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.75) !important;
}

.tw-ring-offset-slate-700\/80 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.8) !important;
}

.tw-ring-offset-slate-700\/85 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.85) !important;
}

.tw-ring-offset-slate-700\/90 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.9) !important;
}

.tw-ring-offset-slate-700\/95 {
  --tw-ring-offset-color: rgb(51 65 85 / 0.95) !important;
}

.tw-ring-offset-slate-800 {
  --tw-ring-offset-color: #1e293b !important;
}

.tw-ring-offset-slate-800\/0 {
  --tw-ring-offset-color: rgb(30 41 59 / 0) !important;
}

.tw-ring-offset-slate-800\/10 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.1) !important;
}

.tw-ring-offset-slate-800\/100 {
  --tw-ring-offset-color: rgb(30 41 59 / 1) !important;
}

.tw-ring-offset-slate-800\/15 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.15) !important;
}

.tw-ring-offset-slate-800\/20 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.2) !important;
}

.tw-ring-offset-slate-800\/25 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.25) !important;
}

.tw-ring-offset-slate-800\/30 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.3) !important;
}

.tw-ring-offset-slate-800\/35 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.35) !important;
}

.tw-ring-offset-slate-800\/40 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.4) !important;
}

.tw-ring-offset-slate-800\/45 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.45) !important;
}

.tw-ring-offset-slate-800\/5 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.05) !important;
}

.tw-ring-offset-slate-800\/50 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.5) !important;
}

.tw-ring-offset-slate-800\/55 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.55) !important;
}

.tw-ring-offset-slate-800\/60 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.6) !important;
}

.tw-ring-offset-slate-800\/65 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.65) !important;
}

.tw-ring-offset-slate-800\/70 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.7) !important;
}

.tw-ring-offset-slate-800\/75 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.75) !important;
}

.tw-ring-offset-slate-800\/80 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.8) !important;
}

.tw-ring-offset-slate-800\/85 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.85) !important;
}

.tw-ring-offset-slate-800\/90 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.9) !important;
}

.tw-ring-offset-slate-800\/95 {
  --tw-ring-offset-color: rgb(30 41 59 / 0.95) !important;
}

.tw-ring-offset-slate-900 {
  --tw-ring-offset-color: #0f172a !important;
}

.tw-ring-offset-slate-900\/0 {
  --tw-ring-offset-color: rgb(15 23 42 / 0) !important;
}

.tw-ring-offset-slate-900\/10 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.1) !important;
}

.tw-ring-offset-slate-900\/100 {
  --tw-ring-offset-color: rgb(15 23 42 / 1) !important;
}

.tw-ring-offset-slate-900\/15 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.15) !important;
}

.tw-ring-offset-slate-900\/20 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.2) !important;
}

.tw-ring-offset-slate-900\/25 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.25) !important;
}

.tw-ring-offset-slate-900\/30 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.3) !important;
}

.tw-ring-offset-slate-900\/35 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.35) !important;
}

.tw-ring-offset-slate-900\/40 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.4) !important;
}

.tw-ring-offset-slate-900\/45 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.45) !important;
}

.tw-ring-offset-slate-900\/5 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.05) !important;
}

.tw-ring-offset-slate-900\/50 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.5) !important;
}

.tw-ring-offset-slate-900\/55 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.55) !important;
}

.tw-ring-offset-slate-900\/60 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.6) !important;
}

.tw-ring-offset-slate-900\/65 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.65) !important;
}

.tw-ring-offset-slate-900\/70 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.7) !important;
}

.tw-ring-offset-slate-900\/75 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.75) !important;
}

.tw-ring-offset-slate-900\/80 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.8) !important;
}

.tw-ring-offset-slate-900\/85 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.85) !important;
}

.tw-ring-offset-slate-900\/90 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.9) !important;
}

.tw-ring-offset-slate-900\/95 {
  --tw-ring-offset-color: rgb(15 23 42 / 0.95) !important;
}

.tw-ring-offset-slate-950 {
  --tw-ring-offset-color: #020617 !important;
}

.tw-ring-offset-slate-950\/0 {
  --tw-ring-offset-color: rgb(2 6 23 / 0) !important;
}

.tw-ring-offset-slate-950\/10 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.1) !important;
}

.tw-ring-offset-slate-950\/100 {
  --tw-ring-offset-color: rgb(2 6 23 / 1) !important;
}

.tw-ring-offset-slate-950\/15 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.15) !important;
}

.tw-ring-offset-slate-950\/20 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.2) !important;
}

.tw-ring-offset-slate-950\/25 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.25) !important;
}

.tw-ring-offset-slate-950\/30 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.3) !important;
}

.tw-ring-offset-slate-950\/35 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.35) !important;
}

.tw-ring-offset-slate-950\/40 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.4) !important;
}

.tw-ring-offset-slate-950\/45 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.45) !important;
}

.tw-ring-offset-slate-950\/5 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.05) !important;
}

.tw-ring-offset-slate-950\/50 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.5) !important;
}

.tw-ring-offset-slate-950\/55 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.55) !important;
}

.tw-ring-offset-slate-950\/60 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.6) !important;
}

.tw-ring-offset-slate-950\/65 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.65) !important;
}

.tw-ring-offset-slate-950\/70 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.7) !important;
}

.tw-ring-offset-slate-950\/75 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.75) !important;
}

.tw-ring-offset-slate-950\/80 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.8) !important;
}

.tw-ring-offset-slate-950\/85 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.85) !important;
}

.tw-ring-offset-slate-950\/90 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.9) !important;
}

.tw-ring-offset-slate-950\/95 {
  --tw-ring-offset-color: rgb(2 6 23 / 0.95) !important;
}

.tw-ring-offset-stone-100 {
  --tw-ring-offset-color: #f5f5f4 !important;
}

.tw-ring-offset-stone-100\/0 {
  --tw-ring-offset-color: rgb(245 245 244 / 0) !important;
}

.tw-ring-offset-stone-100\/10 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.1) !important;
}

.tw-ring-offset-stone-100\/100 {
  --tw-ring-offset-color: rgb(245 245 244 / 1) !important;
}

.tw-ring-offset-stone-100\/15 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.15) !important;
}

.tw-ring-offset-stone-100\/20 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.2) !important;
}

.tw-ring-offset-stone-100\/25 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.25) !important;
}

.tw-ring-offset-stone-100\/30 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.3) !important;
}

.tw-ring-offset-stone-100\/35 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.35) !important;
}

.tw-ring-offset-stone-100\/40 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.4) !important;
}

.tw-ring-offset-stone-100\/45 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.45) !important;
}

.tw-ring-offset-stone-100\/5 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.05) !important;
}

.tw-ring-offset-stone-100\/50 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.5) !important;
}

.tw-ring-offset-stone-100\/55 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.55) !important;
}

.tw-ring-offset-stone-100\/60 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.6) !important;
}

.tw-ring-offset-stone-100\/65 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.65) !important;
}

.tw-ring-offset-stone-100\/70 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.7) !important;
}

.tw-ring-offset-stone-100\/75 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.75) !important;
}

.tw-ring-offset-stone-100\/80 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.8) !important;
}

.tw-ring-offset-stone-100\/85 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.85) !important;
}

.tw-ring-offset-stone-100\/90 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.9) !important;
}

.tw-ring-offset-stone-100\/95 {
  --tw-ring-offset-color: rgb(245 245 244 / 0.95) !important;
}

.tw-ring-offset-stone-200 {
  --tw-ring-offset-color: #e7e5e4 !important;
}

.tw-ring-offset-stone-200\/0 {
  --tw-ring-offset-color: rgb(231 229 228 / 0) !important;
}

.tw-ring-offset-stone-200\/10 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.1) !important;
}

.tw-ring-offset-stone-200\/100 {
  --tw-ring-offset-color: rgb(231 229 228 / 1) !important;
}

.tw-ring-offset-stone-200\/15 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.15) !important;
}

.tw-ring-offset-stone-200\/20 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.2) !important;
}

.tw-ring-offset-stone-200\/25 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.25) !important;
}

.tw-ring-offset-stone-200\/30 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.3) !important;
}

.tw-ring-offset-stone-200\/35 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.35) !important;
}

.tw-ring-offset-stone-200\/40 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.4) !important;
}

.tw-ring-offset-stone-200\/45 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.45) !important;
}

.tw-ring-offset-stone-200\/5 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.05) !important;
}

.tw-ring-offset-stone-200\/50 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.5) !important;
}

.tw-ring-offset-stone-200\/55 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.55) !important;
}

.tw-ring-offset-stone-200\/60 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.6) !important;
}

.tw-ring-offset-stone-200\/65 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.65) !important;
}

.tw-ring-offset-stone-200\/70 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.7) !important;
}

.tw-ring-offset-stone-200\/75 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.75) !important;
}

.tw-ring-offset-stone-200\/80 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.8) !important;
}

.tw-ring-offset-stone-200\/85 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.85) !important;
}

.tw-ring-offset-stone-200\/90 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.9) !important;
}

.tw-ring-offset-stone-200\/95 {
  --tw-ring-offset-color: rgb(231 229 228 / 0.95) !important;
}

.tw-ring-offset-stone-300 {
  --tw-ring-offset-color: #d6d3d1 !important;
}

.tw-ring-offset-stone-300\/0 {
  --tw-ring-offset-color: rgb(214 211 209 / 0) !important;
}

.tw-ring-offset-stone-300\/10 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.1) !important;
}

.tw-ring-offset-stone-300\/100 {
  --tw-ring-offset-color: rgb(214 211 209 / 1) !important;
}

.tw-ring-offset-stone-300\/15 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.15) !important;
}

.tw-ring-offset-stone-300\/20 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.2) !important;
}

.tw-ring-offset-stone-300\/25 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.25) !important;
}

.tw-ring-offset-stone-300\/30 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.3) !important;
}

.tw-ring-offset-stone-300\/35 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.35) !important;
}

.tw-ring-offset-stone-300\/40 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.4) !important;
}

.tw-ring-offset-stone-300\/45 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.45) !important;
}

.tw-ring-offset-stone-300\/5 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.05) !important;
}

.tw-ring-offset-stone-300\/50 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.5) !important;
}

.tw-ring-offset-stone-300\/55 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.55) !important;
}

.tw-ring-offset-stone-300\/60 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.6) !important;
}

.tw-ring-offset-stone-300\/65 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.65) !important;
}

.tw-ring-offset-stone-300\/70 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.7) !important;
}

.tw-ring-offset-stone-300\/75 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.75) !important;
}

.tw-ring-offset-stone-300\/80 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.8) !important;
}

.tw-ring-offset-stone-300\/85 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.85) !important;
}

.tw-ring-offset-stone-300\/90 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.9) !important;
}

.tw-ring-offset-stone-300\/95 {
  --tw-ring-offset-color: rgb(214 211 209 / 0.95) !important;
}

.tw-ring-offset-stone-400 {
  --tw-ring-offset-color: #a8a29e !important;
}

.tw-ring-offset-stone-400\/0 {
  --tw-ring-offset-color: rgb(168 162 158 / 0) !important;
}

.tw-ring-offset-stone-400\/10 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.1) !important;
}

.tw-ring-offset-stone-400\/100 {
  --tw-ring-offset-color: rgb(168 162 158 / 1) !important;
}

.tw-ring-offset-stone-400\/15 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.15) !important;
}

.tw-ring-offset-stone-400\/20 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.2) !important;
}

.tw-ring-offset-stone-400\/25 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.25) !important;
}

.tw-ring-offset-stone-400\/30 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.3) !important;
}

.tw-ring-offset-stone-400\/35 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.35) !important;
}

.tw-ring-offset-stone-400\/40 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.4) !important;
}

.tw-ring-offset-stone-400\/45 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.45) !important;
}

.tw-ring-offset-stone-400\/5 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.05) !important;
}

.tw-ring-offset-stone-400\/50 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.5) !important;
}

.tw-ring-offset-stone-400\/55 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.55) !important;
}

.tw-ring-offset-stone-400\/60 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.6) !important;
}

.tw-ring-offset-stone-400\/65 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.65) !important;
}

.tw-ring-offset-stone-400\/70 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.7) !important;
}

.tw-ring-offset-stone-400\/75 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.75) !important;
}

.tw-ring-offset-stone-400\/80 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.8) !important;
}

.tw-ring-offset-stone-400\/85 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.85) !important;
}

.tw-ring-offset-stone-400\/90 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.9) !important;
}

.tw-ring-offset-stone-400\/95 {
  --tw-ring-offset-color: rgb(168 162 158 / 0.95) !important;
}

.tw-ring-offset-stone-50 {
  --tw-ring-offset-color: #fafaf9 !important;
}

.tw-ring-offset-stone-50\/0 {
  --tw-ring-offset-color: rgb(250 250 249 / 0) !important;
}

.tw-ring-offset-stone-50\/10 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.1) !important;
}

.tw-ring-offset-stone-50\/100 {
  --tw-ring-offset-color: rgb(250 250 249 / 1) !important;
}

.tw-ring-offset-stone-50\/15 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.15) !important;
}

.tw-ring-offset-stone-50\/20 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.2) !important;
}

.tw-ring-offset-stone-50\/25 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.25) !important;
}

.tw-ring-offset-stone-50\/30 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.3) !important;
}

.tw-ring-offset-stone-50\/35 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.35) !important;
}

.tw-ring-offset-stone-50\/40 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.4) !important;
}

.tw-ring-offset-stone-50\/45 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.45) !important;
}

.tw-ring-offset-stone-50\/5 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.05) !important;
}

.tw-ring-offset-stone-50\/50 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.5) !important;
}

.tw-ring-offset-stone-50\/55 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.55) !important;
}

.tw-ring-offset-stone-50\/60 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.6) !important;
}

.tw-ring-offset-stone-50\/65 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.65) !important;
}

.tw-ring-offset-stone-50\/70 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.7) !important;
}

.tw-ring-offset-stone-50\/75 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.75) !important;
}

.tw-ring-offset-stone-50\/80 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.8) !important;
}

.tw-ring-offset-stone-50\/85 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.85) !important;
}

.tw-ring-offset-stone-50\/90 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.9) !important;
}

.tw-ring-offset-stone-50\/95 {
  --tw-ring-offset-color: rgb(250 250 249 / 0.95) !important;
}

.tw-ring-offset-stone-500 {
  --tw-ring-offset-color: #78716c !important;
}

.tw-ring-offset-stone-500\/0 {
  --tw-ring-offset-color: rgb(120 113 108 / 0) !important;
}

.tw-ring-offset-stone-500\/10 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.1) !important;
}

.tw-ring-offset-stone-500\/100 {
  --tw-ring-offset-color: rgb(120 113 108 / 1) !important;
}

.tw-ring-offset-stone-500\/15 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.15) !important;
}

.tw-ring-offset-stone-500\/20 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.2) !important;
}

.tw-ring-offset-stone-500\/25 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.25) !important;
}

.tw-ring-offset-stone-500\/30 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.3) !important;
}

.tw-ring-offset-stone-500\/35 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.35) !important;
}

.tw-ring-offset-stone-500\/40 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.4) !important;
}

.tw-ring-offset-stone-500\/45 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.45) !important;
}

.tw-ring-offset-stone-500\/5 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.05) !important;
}

.tw-ring-offset-stone-500\/50 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.5) !important;
}

.tw-ring-offset-stone-500\/55 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.55) !important;
}

.tw-ring-offset-stone-500\/60 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.6) !important;
}

.tw-ring-offset-stone-500\/65 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.65) !important;
}

.tw-ring-offset-stone-500\/70 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.7) !important;
}

.tw-ring-offset-stone-500\/75 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.75) !important;
}

.tw-ring-offset-stone-500\/80 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.8) !important;
}

.tw-ring-offset-stone-500\/85 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.85) !important;
}

.tw-ring-offset-stone-500\/90 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.9) !important;
}

.tw-ring-offset-stone-500\/95 {
  --tw-ring-offset-color: rgb(120 113 108 / 0.95) !important;
}

.tw-ring-offset-stone-600 {
  --tw-ring-offset-color: #57534e !important;
}

.tw-ring-offset-stone-600\/0 {
  --tw-ring-offset-color: rgb(87 83 78 / 0) !important;
}

.tw-ring-offset-stone-600\/10 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.1) !important;
}

.tw-ring-offset-stone-600\/100 {
  --tw-ring-offset-color: rgb(87 83 78 / 1) !important;
}

.tw-ring-offset-stone-600\/15 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.15) !important;
}

.tw-ring-offset-stone-600\/20 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.2) !important;
}

.tw-ring-offset-stone-600\/25 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.25) !important;
}

.tw-ring-offset-stone-600\/30 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.3) !important;
}

.tw-ring-offset-stone-600\/35 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.35) !important;
}

.tw-ring-offset-stone-600\/40 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.4) !important;
}

.tw-ring-offset-stone-600\/45 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.45) !important;
}

.tw-ring-offset-stone-600\/5 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.05) !important;
}

.tw-ring-offset-stone-600\/50 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.5) !important;
}

.tw-ring-offset-stone-600\/55 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.55) !important;
}

.tw-ring-offset-stone-600\/60 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.6) !important;
}

.tw-ring-offset-stone-600\/65 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.65) !important;
}

.tw-ring-offset-stone-600\/70 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.7) !important;
}

.tw-ring-offset-stone-600\/75 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.75) !important;
}

.tw-ring-offset-stone-600\/80 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.8) !important;
}

.tw-ring-offset-stone-600\/85 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.85) !important;
}

.tw-ring-offset-stone-600\/90 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.9) !important;
}

.tw-ring-offset-stone-600\/95 {
  --tw-ring-offset-color: rgb(87 83 78 / 0.95) !important;
}

.tw-ring-offset-stone-700 {
  --tw-ring-offset-color: #44403c !important;
}

.tw-ring-offset-stone-700\/0 {
  --tw-ring-offset-color: rgb(68 64 60 / 0) !important;
}

.tw-ring-offset-stone-700\/10 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.1) !important;
}

.tw-ring-offset-stone-700\/100 {
  --tw-ring-offset-color: rgb(68 64 60 / 1) !important;
}

.tw-ring-offset-stone-700\/15 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.15) !important;
}

.tw-ring-offset-stone-700\/20 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.2) !important;
}

.tw-ring-offset-stone-700\/25 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.25) !important;
}

.tw-ring-offset-stone-700\/30 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.3) !important;
}

.tw-ring-offset-stone-700\/35 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.35) !important;
}

.tw-ring-offset-stone-700\/40 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.4) !important;
}

.tw-ring-offset-stone-700\/45 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.45) !important;
}

.tw-ring-offset-stone-700\/5 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.05) !important;
}

.tw-ring-offset-stone-700\/50 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.5) !important;
}

.tw-ring-offset-stone-700\/55 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.55) !important;
}

.tw-ring-offset-stone-700\/60 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.6) !important;
}

.tw-ring-offset-stone-700\/65 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.65) !important;
}

.tw-ring-offset-stone-700\/70 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.7) !important;
}

.tw-ring-offset-stone-700\/75 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.75) !important;
}

.tw-ring-offset-stone-700\/80 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.8) !important;
}

.tw-ring-offset-stone-700\/85 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.85) !important;
}

.tw-ring-offset-stone-700\/90 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.9) !important;
}

.tw-ring-offset-stone-700\/95 {
  --tw-ring-offset-color: rgb(68 64 60 / 0.95) !important;
}

.tw-ring-offset-stone-800 {
  --tw-ring-offset-color: #292524 !important;
}

.tw-ring-offset-stone-800\/0 {
  --tw-ring-offset-color: rgb(41 37 36 / 0) !important;
}

.tw-ring-offset-stone-800\/10 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.1) !important;
}

.tw-ring-offset-stone-800\/100 {
  --tw-ring-offset-color: rgb(41 37 36 / 1) !important;
}

.tw-ring-offset-stone-800\/15 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.15) !important;
}

.tw-ring-offset-stone-800\/20 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.2) !important;
}

.tw-ring-offset-stone-800\/25 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.25) !important;
}

.tw-ring-offset-stone-800\/30 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.3) !important;
}

.tw-ring-offset-stone-800\/35 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.35) !important;
}

.tw-ring-offset-stone-800\/40 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.4) !important;
}

.tw-ring-offset-stone-800\/45 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.45) !important;
}

.tw-ring-offset-stone-800\/5 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.05) !important;
}

.tw-ring-offset-stone-800\/50 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.5) !important;
}

.tw-ring-offset-stone-800\/55 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.55) !important;
}

.tw-ring-offset-stone-800\/60 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.6) !important;
}

.tw-ring-offset-stone-800\/65 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.65) !important;
}

.tw-ring-offset-stone-800\/70 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.7) !important;
}

.tw-ring-offset-stone-800\/75 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.75) !important;
}

.tw-ring-offset-stone-800\/80 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.8) !important;
}

.tw-ring-offset-stone-800\/85 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.85) !important;
}

.tw-ring-offset-stone-800\/90 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.9) !important;
}

.tw-ring-offset-stone-800\/95 {
  --tw-ring-offset-color: rgb(41 37 36 / 0.95) !important;
}

.tw-ring-offset-stone-900 {
  --tw-ring-offset-color: #1c1917 !important;
}

.tw-ring-offset-stone-900\/0 {
  --tw-ring-offset-color: rgb(28 25 23 / 0) !important;
}

.tw-ring-offset-stone-900\/10 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.1) !important;
}

.tw-ring-offset-stone-900\/100 {
  --tw-ring-offset-color: rgb(28 25 23 / 1) !important;
}

.tw-ring-offset-stone-900\/15 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.15) !important;
}

.tw-ring-offset-stone-900\/20 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.2) !important;
}

.tw-ring-offset-stone-900\/25 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.25) !important;
}

.tw-ring-offset-stone-900\/30 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.3) !important;
}

.tw-ring-offset-stone-900\/35 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.35) !important;
}

.tw-ring-offset-stone-900\/40 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.4) !important;
}

.tw-ring-offset-stone-900\/45 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.45) !important;
}

.tw-ring-offset-stone-900\/5 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.05) !important;
}

.tw-ring-offset-stone-900\/50 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.5) !important;
}

.tw-ring-offset-stone-900\/55 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.55) !important;
}

.tw-ring-offset-stone-900\/60 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.6) !important;
}

.tw-ring-offset-stone-900\/65 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.65) !important;
}

.tw-ring-offset-stone-900\/70 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.7) !important;
}

.tw-ring-offset-stone-900\/75 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.75) !important;
}

.tw-ring-offset-stone-900\/80 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.8) !important;
}

.tw-ring-offset-stone-900\/85 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.85) !important;
}

.tw-ring-offset-stone-900\/90 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.9) !important;
}

.tw-ring-offset-stone-900\/95 {
  --tw-ring-offset-color: rgb(28 25 23 / 0.95) !important;
}

.tw-ring-offset-stone-950 {
  --tw-ring-offset-color: #0c0a09 !important;
}

.tw-ring-offset-stone-950\/0 {
  --tw-ring-offset-color: rgb(12 10 9 / 0) !important;
}

.tw-ring-offset-stone-950\/10 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.1) !important;
}

.tw-ring-offset-stone-950\/100 {
  --tw-ring-offset-color: rgb(12 10 9 / 1) !important;
}

.tw-ring-offset-stone-950\/15 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.15) !important;
}

.tw-ring-offset-stone-950\/20 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.2) !important;
}

.tw-ring-offset-stone-950\/25 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.25) !important;
}

.tw-ring-offset-stone-950\/30 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.3) !important;
}

.tw-ring-offset-stone-950\/35 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.35) !important;
}

.tw-ring-offset-stone-950\/40 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.4) !important;
}

.tw-ring-offset-stone-950\/45 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.45) !important;
}

.tw-ring-offset-stone-950\/5 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.05) !important;
}

.tw-ring-offset-stone-950\/50 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.5) !important;
}

.tw-ring-offset-stone-950\/55 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.55) !important;
}

.tw-ring-offset-stone-950\/60 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.6) !important;
}

.tw-ring-offset-stone-950\/65 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.65) !important;
}

.tw-ring-offset-stone-950\/70 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.7) !important;
}

.tw-ring-offset-stone-950\/75 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.75) !important;
}

.tw-ring-offset-stone-950\/80 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.8) !important;
}

.tw-ring-offset-stone-950\/85 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.85) !important;
}

.tw-ring-offset-stone-950\/90 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.9) !important;
}

.tw-ring-offset-stone-950\/95 {
  --tw-ring-offset-color: rgb(12 10 9 / 0.95) !important;
}

.tw-ring-offset-teal-100 {
  --tw-ring-offset-color: #ccfbf1 !important;
}

.tw-ring-offset-teal-100\/0 {
  --tw-ring-offset-color: rgb(204 251 241 / 0) !important;
}

.tw-ring-offset-teal-100\/10 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.1) !important;
}

.tw-ring-offset-teal-100\/100 {
  --tw-ring-offset-color: rgb(204 251 241 / 1) !important;
}

.tw-ring-offset-teal-100\/15 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.15) !important;
}

.tw-ring-offset-teal-100\/20 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.2) !important;
}

.tw-ring-offset-teal-100\/25 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.25) !important;
}

.tw-ring-offset-teal-100\/30 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.3) !important;
}

.tw-ring-offset-teal-100\/35 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.35) !important;
}

.tw-ring-offset-teal-100\/40 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.4) !important;
}

.tw-ring-offset-teal-100\/45 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.45) !important;
}

.tw-ring-offset-teal-100\/5 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.05) !important;
}

.tw-ring-offset-teal-100\/50 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.5) !important;
}

.tw-ring-offset-teal-100\/55 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.55) !important;
}

.tw-ring-offset-teal-100\/60 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.6) !important;
}

.tw-ring-offset-teal-100\/65 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.65) !important;
}

.tw-ring-offset-teal-100\/70 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.7) !important;
}

.tw-ring-offset-teal-100\/75 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.75) !important;
}

.tw-ring-offset-teal-100\/80 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.8) !important;
}

.tw-ring-offset-teal-100\/85 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.85) !important;
}

.tw-ring-offset-teal-100\/90 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.9) !important;
}

.tw-ring-offset-teal-100\/95 {
  --tw-ring-offset-color: rgb(204 251 241 / 0.95) !important;
}

.tw-ring-offset-teal-200 {
  --tw-ring-offset-color: #99f6e4 !important;
}

.tw-ring-offset-teal-200\/0 {
  --tw-ring-offset-color: rgb(153 246 228 / 0) !important;
}

.tw-ring-offset-teal-200\/10 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.1) !important;
}

.tw-ring-offset-teal-200\/100 {
  --tw-ring-offset-color: rgb(153 246 228 / 1) !important;
}

.tw-ring-offset-teal-200\/15 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.15) !important;
}

.tw-ring-offset-teal-200\/20 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.2) !important;
}

.tw-ring-offset-teal-200\/25 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.25) !important;
}

.tw-ring-offset-teal-200\/30 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.3) !important;
}

.tw-ring-offset-teal-200\/35 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.35) !important;
}

.tw-ring-offset-teal-200\/40 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.4) !important;
}

.tw-ring-offset-teal-200\/45 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.45) !important;
}

.tw-ring-offset-teal-200\/5 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.05) !important;
}

.tw-ring-offset-teal-200\/50 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.5) !important;
}

.tw-ring-offset-teal-200\/55 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.55) !important;
}

.tw-ring-offset-teal-200\/60 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.6) !important;
}

.tw-ring-offset-teal-200\/65 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.65) !important;
}

.tw-ring-offset-teal-200\/70 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.7) !important;
}

.tw-ring-offset-teal-200\/75 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.75) !important;
}

.tw-ring-offset-teal-200\/80 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.8) !important;
}

.tw-ring-offset-teal-200\/85 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.85) !important;
}

.tw-ring-offset-teal-200\/90 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.9) !important;
}

.tw-ring-offset-teal-200\/95 {
  --tw-ring-offset-color: rgb(153 246 228 / 0.95) !important;
}

.tw-ring-offset-teal-300 {
  --tw-ring-offset-color: #5eead4 !important;
}

.tw-ring-offset-teal-300\/0 {
  --tw-ring-offset-color: rgb(94 234 212 / 0) !important;
}

.tw-ring-offset-teal-300\/10 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.1) !important;
}

.tw-ring-offset-teal-300\/100 {
  --tw-ring-offset-color: rgb(94 234 212 / 1) !important;
}

.tw-ring-offset-teal-300\/15 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.15) !important;
}

.tw-ring-offset-teal-300\/20 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.2) !important;
}

.tw-ring-offset-teal-300\/25 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.25) !important;
}

.tw-ring-offset-teal-300\/30 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.3) !important;
}

.tw-ring-offset-teal-300\/35 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.35) !important;
}

.tw-ring-offset-teal-300\/40 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.4) !important;
}

.tw-ring-offset-teal-300\/45 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.45) !important;
}

.tw-ring-offset-teal-300\/5 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.05) !important;
}

.tw-ring-offset-teal-300\/50 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.5) !important;
}

.tw-ring-offset-teal-300\/55 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.55) !important;
}

.tw-ring-offset-teal-300\/60 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.6) !important;
}

.tw-ring-offset-teal-300\/65 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.65) !important;
}

.tw-ring-offset-teal-300\/70 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.7) !important;
}

.tw-ring-offset-teal-300\/75 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.75) !important;
}

.tw-ring-offset-teal-300\/80 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.8) !important;
}

.tw-ring-offset-teal-300\/85 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.85) !important;
}

.tw-ring-offset-teal-300\/90 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.9) !important;
}

.tw-ring-offset-teal-300\/95 {
  --tw-ring-offset-color: rgb(94 234 212 / 0.95) !important;
}

.tw-ring-offset-teal-400 {
  --tw-ring-offset-color: #2dd4bf !important;
}

.tw-ring-offset-teal-400\/0 {
  --tw-ring-offset-color: rgb(45 212 191 / 0) !important;
}

.tw-ring-offset-teal-400\/10 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.1) !important;
}

.tw-ring-offset-teal-400\/100 {
  --tw-ring-offset-color: rgb(45 212 191 / 1) !important;
}

.tw-ring-offset-teal-400\/15 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.15) !important;
}

.tw-ring-offset-teal-400\/20 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.2) !important;
}

.tw-ring-offset-teal-400\/25 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.25) !important;
}

.tw-ring-offset-teal-400\/30 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.3) !important;
}

.tw-ring-offset-teal-400\/35 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.35) !important;
}

.tw-ring-offset-teal-400\/40 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.4) !important;
}

.tw-ring-offset-teal-400\/45 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.45) !important;
}

.tw-ring-offset-teal-400\/5 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.05) !important;
}

.tw-ring-offset-teal-400\/50 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.5) !important;
}

.tw-ring-offset-teal-400\/55 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.55) !important;
}

.tw-ring-offset-teal-400\/60 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.6) !important;
}

.tw-ring-offset-teal-400\/65 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.65) !important;
}

.tw-ring-offset-teal-400\/70 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.7) !important;
}

.tw-ring-offset-teal-400\/75 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.75) !important;
}

.tw-ring-offset-teal-400\/80 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.8) !important;
}

.tw-ring-offset-teal-400\/85 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.85) !important;
}

.tw-ring-offset-teal-400\/90 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.9) !important;
}

.tw-ring-offset-teal-400\/95 {
  --tw-ring-offset-color: rgb(45 212 191 / 0.95) !important;
}

.tw-ring-offset-teal-50 {
  --tw-ring-offset-color: #f0fdfa !important;
}

.tw-ring-offset-teal-50\/0 {
  --tw-ring-offset-color: rgb(240 253 250 / 0) !important;
}

.tw-ring-offset-teal-50\/10 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.1) !important;
}

.tw-ring-offset-teal-50\/100 {
  --tw-ring-offset-color: rgb(240 253 250 / 1) !important;
}

.tw-ring-offset-teal-50\/15 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.15) !important;
}

.tw-ring-offset-teal-50\/20 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.2) !important;
}

.tw-ring-offset-teal-50\/25 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.25) !important;
}

.tw-ring-offset-teal-50\/30 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.3) !important;
}

.tw-ring-offset-teal-50\/35 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.35) !important;
}

.tw-ring-offset-teal-50\/40 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.4) !important;
}

.tw-ring-offset-teal-50\/45 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.45) !important;
}

.tw-ring-offset-teal-50\/5 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.05) !important;
}

.tw-ring-offset-teal-50\/50 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.5) !important;
}

.tw-ring-offset-teal-50\/55 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.55) !important;
}

.tw-ring-offset-teal-50\/60 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.6) !important;
}

.tw-ring-offset-teal-50\/65 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.65) !important;
}

.tw-ring-offset-teal-50\/70 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.7) !important;
}

.tw-ring-offset-teal-50\/75 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.75) !important;
}

.tw-ring-offset-teal-50\/80 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.8) !important;
}

.tw-ring-offset-teal-50\/85 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.85) !important;
}

.tw-ring-offset-teal-50\/90 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.9) !important;
}

.tw-ring-offset-teal-50\/95 {
  --tw-ring-offset-color: rgb(240 253 250 / 0.95) !important;
}

.tw-ring-offset-teal-500 {
  --tw-ring-offset-color: #14b8a6 !important;
}

.tw-ring-offset-teal-500\/0 {
  --tw-ring-offset-color: rgb(20 184 166 / 0) !important;
}

.tw-ring-offset-teal-500\/10 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.1) !important;
}

.tw-ring-offset-teal-500\/100 {
  --tw-ring-offset-color: rgb(20 184 166 / 1) !important;
}

.tw-ring-offset-teal-500\/15 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.15) !important;
}

.tw-ring-offset-teal-500\/20 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.2) !important;
}

.tw-ring-offset-teal-500\/25 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.25) !important;
}

.tw-ring-offset-teal-500\/30 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.3) !important;
}

.tw-ring-offset-teal-500\/35 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.35) !important;
}

.tw-ring-offset-teal-500\/40 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.4) !important;
}

.tw-ring-offset-teal-500\/45 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.45) !important;
}

.tw-ring-offset-teal-500\/5 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.05) !important;
}

.tw-ring-offset-teal-500\/50 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.5) !important;
}

.tw-ring-offset-teal-500\/55 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.55) !important;
}

.tw-ring-offset-teal-500\/60 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.6) !important;
}

.tw-ring-offset-teal-500\/65 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.65) !important;
}

.tw-ring-offset-teal-500\/70 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.7) !important;
}

.tw-ring-offset-teal-500\/75 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.75) !important;
}

.tw-ring-offset-teal-500\/80 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.8) !important;
}

.tw-ring-offset-teal-500\/85 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.85) !important;
}

.tw-ring-offset-teal-500\/90 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.9) !important;
}

.tw-ring-offset-teal-500\/95 {
  --tw-ring-offset-color: rgb(20 184 166 / 0.95) !important;
}

.tw-ring-offset-teal-600 {
  --tw-ring-offset-color: #0d9488 !important;
}

.tw-ring-offset-teal-600\/0 {
  --tw-ring-offset-color: rgb(13 148 136 / 0) !important;
}

.tw-ring-offset-teal-600\/10 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.1) !important;
}

.tw-ring-offset-teal-600\/100 {
  --tw-ring-offset-color: rgb(13 148 136 / 1) !important;
}

.tw-ring-offset-teal-600\/15 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.15) !important;
}

.tw-ring-offset-teal-600\/20 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.2) !important;
}

.tw-ring-offset-teal-600\/25 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.25) !important;
}

.tw-ring-offset-teal-600\/30 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.3) !important;
}

.tw-ring-offset-teal-600\/35 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.35) !important;
}

.tw-ring-offset-teal-600\/40 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.4) !important;
}

.tw-ring-offset-teal-600\/45 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.45) !important;
}

.tw-ring-offset-teal-600\/5 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.05) !important;
}

.tw-ring-offset-teal-600\/50 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.5) !important;
}

.tw-ring-offset-teal-600\/55 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.55) !important;
}

.tw-ring-offset-teal-600\/60 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.6) !important;
}

.tw-ring-offset-teal-600\/65 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.65) !important;
}

.tw-ring-offset-teal-600\/70 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.7) !important;
}

.tw-ring-offset-teal-600\/75 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.75) !important;
}

.tw-ring-offset-teal-600\/80 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.8) !important;
}

.tw-ring-offset-teal-600\/85 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.85) !important;
}

.tw-ring-offset-teal-600\/90 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.9) !important;
}

.tw-ring-offset-teal-600\/95 {
  --tw-ring-offset-color: rgb(13 148 136 / 0.95) !important;
}

.tw-ring-offset-teal-700 {
  --tw-ring-offset-color: #0f766e !important;
}

.tw-ring-offset-teal-700\/0 {
  --tw-ring-offset-color: rgb(15 118 110 / 0) !important;
}

.tw-ring-offset-teal-700\/10 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.1) !important;
}

.tw-ring-offset-teal-700\/100 {
  --tw-ring-offset-color: rgb(15 118 110 / 1) !important;
}

.tw-ring-offset-teal-700\/15 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.15) !important;
}

.tw-ring-offset-teal-700\/20 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.2) !important;
}

.tw-ring-offset-teal-700\/25 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.25) !important;
}

.tw-ring-offset-teal-700\/30 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.3) !important;
}

.tw-ring-offset-teal-700\/35 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.35) !important;
}

.tw-ring-offset-teal-700\/40 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.4) !important;
}

.tw-ring-offset-teal-700\/45 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.45) !important;
}

.tw-ring-offset-teal-700\/5 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.05) !important;
}

.tw-ring-offset-teal-700\/50 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.5) !important;
}

.tw-ring-offset-teal-700\/55 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.55) !important;
}

.tw-ring-offset-teal-700\/60 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.6) !important;
}

.tw-ring-offset-teal-700\/65 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.65) !important;
}

.tw-ring-offset-teal-700\/70 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.7) !important;
}

.tw-ring-offset-teal-700\/75 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.75) !important;
}

.tw-ring-offset-teal-700\/80 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.8) !important;
}

.tw-ring-offset-teal-700\/85 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.85) !important;
}

.tw-ring-offset-teal-700\/90 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.9) !important;
}

.tw-ring-offset-teal-700\/95 {
  --tw-ring-offset-color: rgb(15 118 110 / 0.95) !important;
}

.tw-ring-offset-teal-800 {
  --tw-ring-offset-color: #115e59 !important;
}

.tw-ring-offset-teal-800\/0 {
  --tw-ring-offset-color: rgb(17 94 89 / 0) !important;
}

.tw-ring-offset-teal-800\/10 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.1) !important;
}

.tw-ring-offset-teal-800\/100 {
  --tw-ring-offset-color: rgb(17 94 89 / 1) !important;
}

.tw-ring-offset-teal-800\/15 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.15) !important;
}

.tw-ring-offset-teal-800\/20 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.2) !important;
}

.tw-ring-offset-teal-800\/25 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.25) !important;
}

.tw-ring-offset-teal-800\/30 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.3) !important;
}

.tw-ring-offset-teal-800\/35 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.35) !important;
}

.tw-ring-offset-teal-800\/40 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.4) !important;
}

.tw-ring-offset-teal-800\/45 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.45) !important;
}

.tw-ring-offset-teal-800\/5 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.05) !important;
}

.tw-ring-offset-teal-800\/50 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.5) !important;
}

.tw-ring-offset-teal-800\/55 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.55) !important;
}

.tw-ring-offset-teal-800\/60 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.6) !important;
}

.tw-ring-offset-teal-800\/65 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.65) !important;
}

.tw-ring-offset-teal-800\/70 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.7) !important;
}

.tw-ring-offset-teal-800\/75 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.75) !important;
}

.tw-ring-offset-teal-800\/80 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.8) !important;
}

.tw-ring-offset-teal-800\/85 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.85) !important;
}

.tw-ring-offset-teal-800\/90 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.9) !important;
}

.tw-ring-offset-teal-800\/95 {
  --tw-ring-offset-color: rgb(17 94 89 / 0.95) !important;
}

.tw-ring-offset-teal-900 {
  --tw-ring-offset-color: #134e4a !important;
}

.tw-ring-offset-teal-900\/0 {
  --tw-ring-offset-color: rgb(19 78 74 / 0) !important;
}

.tw-ring-offset-teal-900\/10 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.1) !important;
}

.tw-ring-offset-teal-900\/100 {
  --tw-ring-offset-color: rgb(19 78 74 / 1) !important;
}

.tw-ring-offset-teal-900\/15 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.15) !important;
}

.tw-ring-offset-teal-900\/20 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.2) !important;
}

.tw-ring-offset-teal-900\/25 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.25) !important;
}

.tw-ring-offset-teal-900\/30 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.3) !important;
}

.tw-ring-offset-teal-900\/35 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.35) !important;
}

.tw-ring-offset-teal-900\/40 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.4) !important;
}

.tw-ring-offset-teal-900\/45 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.45) !important;
}

.tw-ring-offset-teal-900\/5 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.05) !important;
}

.tw-ring-offset-teal-900\/50 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.5) !important;
}

.tw-ring-offset-teal-900\/55 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.55) !important;
}

.tw-ring-offset-teal-900\/60 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.6) !important;
}

.tw-ring-offset-teal-900\/65 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.65) !important;
}

.tw-ring-offset-teal-900\/70 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.7) !important;
}

.tw-ring-offset-teal-900\/75 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.75) !important;
}

.tw-ring-offset-teal-900\/80 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.8) !important;
}

.tw-ring-offset-teal-900\/85 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.85) !important;
}

.tw-ring-offset-teal-900\/90 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.9) !important;
}

.tw-ring-offset-teal-900\/95 {
  --tw-ring-offset-color: rgb(19 78 74 / 0.95) !important;
}

.tw-ring-offset-teal-950 {
  --tw-ring-offset-color: #042f2e !important;
}

.tw-ring-offset-teal-950\/0 {
  --tw-ring-offset-color: rgb(4 47 46 / 0) !important;
}

.tw-ring-offset-teal-950\/10 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.1) !important;
}

.tw-ring-offset-teal-950\/100 {
  --tw-ring-offset-color: rgb(4 47 46 / 1) !important;
}

.tw-ring-offset-teal-950\/15 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.15) !important;
}

.tw-ring-offset-teal-950\/20 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.2) !important;
}

.tw-ring-offset-teal-950\/25 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.25) !important;
}

.tw-ring-offset-teal-950\/30 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.3) !important;
}

.tw-ring-offset-teal-950\/35 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.35) !important;
}

.tw-ring-offset-teal-950\/40 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.4) !important;
}

.tw-ring-offset-teal-950\/45 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.45) !important;
}

.tw-ring-offset-teal-950\/5 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.05) !important;
}

.tw-ring-offset-teal-950\/50 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.5) !important;
}

.tw-ring-offset-teal-950\/55 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.55) !important;
}

.tw-ring-offset-teal-950\/60 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.6) !important;
}

.tw-ring-offset-teal-950\/65 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.65) !important;
}

.tw-ring-offset-teal-950\/70 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.7) !important;
}

.tw-ring-offset-teal-950\/75 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.75) !important;
}

.tw-ring-offset-teal-950\/80 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.8) !important;
}

.tw-ring-offset-teal-950\/85 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.85) !important;
}

.tw-ring-offset-teal-950\/90 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.9) !important;
}

.tw-ring-offset-teal-950\/95 {
  --tw-ring-offset-color: rgb(4 47 46 / 0.95) !important;
}

.tw-ring-offset-transparent {
  --tw-ring-offset-color: transparent !important;
}

.tw-ring-offset-transparent\/0 {
  --tw-ring-offset-color: rgb(0 0 0 / 0) !important;
}

.tw-ring-offset-transparent\/10 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.1) !important;
}

.tw-ring-offset-transparent\/100 {
  --tw-ring-offset-color: rgb(0 0 0 / 1) !important;
}

.tw-ring-offset-transparent\/15 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.15) !important;
}

.tw-ring-offset-transparent\/20 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.2) !important;
}

.tw-ring-offset-transparent\/25 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.25) !important;
}

.tw-ring-offset-transparent\/30 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.3) !important;
}

.tw-ring-offset-transparent\/35 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.35) !important;
}

.tw-ring-offset-transparent\/40 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.4) !important;
}

.tw-ring-offset-transparent\/45 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.45) !important;
}

.tw-ring-offset-transparent\/5 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.05) !important;
}

.tw-ring-offset-transparent\/50 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.5) !important;
}

.tw-ring-offset-transparent\/55 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.55) !important;
}

.tw-ring-offset-transparent\/60 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.6) !important;
}

.tw-ring-offset-transparent\/65 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.65) !important;
}

.tw-ring-offset-transparent\/70 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.7) !important;
}

.tw-ring-offset-transparent\/75 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.75) !important;
}

.tw-ring-offset-transparent\/80 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.8) !important;
}

.tw-ring-offset-transparent\/85 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.85) !important;
}

.tw-ring-offset-transparent\/90 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.9) !important;
}

.tw-ring-offset-transparent\/95 {
  --tw-ring-offset-color: rgb(0 0 0 / 0.95) !important;
}

.tw-ring-offset-violet-100 {
  --tw-ring-offset-color: #ede9fe !important;
}

.tw-ring-offset-violet-100\/0 {
  --tw-ring-offset-color: rgb(237 233 254 / 0) !important;
}

.tw-ring-offset-violet-100\/10 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.1) !important;
}

.tw-ring-offset-violet-100\/100 {
  --tw-ring-offset-color: rgb(237 233 254 / 1) !important;
}

.tw-ring-offset-violet-100\/15 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.15) !important;
}

.tw-ring-offset-violet-100\/20 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.2) !important;
}

.tw-ring-offset-violet-100\/25 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.25) !important;
}

.tw-ring-offset-violet-100\/30 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.3) !important;
}

.tw-ring-offset-violet-100\/35 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.35) !important;
}

.tw-ring-offset-violet-100\/40 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.4) !important;
}

.tw-ring-offset-violet-100\/45 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.45) !important;
}

.tw-ring-offset-violet-100\/5 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.05) !important;
}

.tw-ring-offset-violet-100\/50 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.5) !important;
}

.tw-ring-offset-violet-100\/55 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.55) !important;
}

.tw-ring-offset-violet-100\/60 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.6) !important;
}

.tw-ring-offset-violet-100\/65 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.65) !important;
}

.tw-ring-offset-violet-100\/70 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.7) !important;
}

.tw-ring-offset-violet-100\/75 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.75) !important;
}

.tw-ring-offset-violet-100\/80 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.8) !important;
}

.tw-ring-offset-violet-100\/85 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.85) !important;
}

.tw-ring-offset-violet-100\/90 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.9) !important;
}

.tw-ring-offset-violet-100\/95 {
  --tw-ring-offset-color: rgb(237 233 254 / 0.95) !important;
}

.tw-ring-offset-violet-200 {
  --tw-ring-offset-color: #ddd6fe !important;
}

.tw-ring-offset-violet-200\/0 {
  --tw-ring-offset-color: rgb(221 214 254 / 0) !important;
}

.tw-ring-offset-violet-200\/10 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.1) !important;
}

.tw-ring-offset-violet-200\/100 {
  --tw-ring-offset-color: rgb(221 214 254 / 1) !important;
}

.tw-ring-offset-violet-200\/15 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.15) !important;
}

.tw-ring-offset-violet-200\/20 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.2) !important;
}

.tw-ring-offset-violet-200\/25 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.25) !important;
}

.tw-ring-offset-violet-200\/30 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.3) !important;
}

.tw-ring-offset-violet-200\/35 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.35) !important;
}

.tw-ring-offset-violet-200\/40 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.4) !important;
}

.tw-ring-offset-violet-200\/45 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.45) !important;
}

.tw-ring-offset-violet-200\/5 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.05) !important;
}

.tw-ring-offset-violet-200\/50 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.5) !important;
}

.tw-ring-offset-violet-200\/55 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.55) !important;
}

.tw-ring-offset-violet-200\/60 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.6) !important;
}

.tw-ring-offset-violet-200\/65 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.65) !important;
}

.tw-ring-offset-violet-200\/70 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.7) !important;
}

.tw-ring-offset-violet-200\/75 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.75) !important;
}

.tw-ring-offset-violet-200\/80 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.8) !important;
}

.tw-ring-offset-violet-200\/85 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.85) !important;
}

.tw-ring-offset-violet-200\/90 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.9) !important;
}

.tw-ring-offset-violet-200\/95 {
  --tw-ring-offset-color: rgb(221 214 254 / 0.95) !important;
}

.tw-ring-offset-violet-300 {
  --tw-ring-offset-color: #c4b5fd !important;
}

.tw-ring-offset-violet-300\/0 {
  --tw-ring-offset-color: rgb(196 181 253 / 0) !important;
}

.tw-ring-offset-violet-300\/10 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.1) !important;
}

.tw-ring-offset-violet-300\/100 {
  --tw-ring-offset-color: rgb(196 181 253 / 1) !important;
}

.tw-ring-offset-violet-300\/15 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.15) !important;
}

.tw-ring-offset-violet-300\/20 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.2) !important;
}

.tw-ring-offset-violet-300\/25 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.25) !important;
}

.tw-ring-offset-violet-300\/30 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.3) !important;
}

.tw-ring-offset-violet-300\/35 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.35) !important;
}

.tw-ring-offset-violet-300\/40 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.4) !important;
}

.tw-ring-offset-violet-300\/45 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.45) !important;
}

.tw-ring-offset-violet-300\/5 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.05) !important;
}

.tw-ring-offset-violet-300\/50 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.5) !important;
}

.tw-ring-offset-violet-300\/55 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.55) !important;
}

.tw-ring-offset-violet-300\/60 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.6) !important;
}

.tw-ring-offset-violet-300\/65 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.65) !important;
}

.tw-ring-offset-violet-300\/70 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.7) !important;
}

.tw-ring-offset-violet-300\/75 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.75) !important;
}

.tw-ring-offset-violet-300\/80 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.8) !important;
}

.tw-ring-offset-violet-300\/85 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.85) !important;
}

.tw-ring-offset-violet-300\/90 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.9) !important;
}

.tw-ring-offset-violet-300\/95 {
  --tw-ring-offset-color: rgb(196 181 253 / 0.95) !important;
}

.tw-ring-offset-violet-400 {
  --tw-ring-offset-color: #a78bfa !important;
}

.tw-ring-offset-violet-400\/0 {
  --tw-ring-offset-color: rgb(167 139 250 / 0) !important;
}

.tw-ring-offset-violet-400\/10 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.1) !important;
}

.tw-ring-offset-violet-400\/100 {
  --tw-ring-offset-color: rgb(167 139 250 / 1) !important;
}

.tw-ring-offset-violet-400\/15 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.15) !important;
}

.tw-ring-offset-violet-400\/20 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.2) !important;
}

.tw-ring-offset-violet-400\/25 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.25) !important;
}

.tw-ring-offset-violet-400\/30 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.3) !important;
}

.tw-ring-offset-violet-400\/35 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.35) !important;
}

.tw-ring-offset-violet-400\/40 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.4) !important;
}

.tw-ring-offset-violet-400\/45 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.45) !important;
}

.tw-ring-offset-violet-400\/5 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.05) !important;
}

.tw-ring-offset-violet-400\/50 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.5) !important;
}

.tw-ring-offset-violet-400\/55 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.55) !important;
}

.tw-ring-offset-violet-400\/60 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.6) !important;
}

.tw-ring-offset-violet-400\/65 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.65) !important;
}

.tw-ring-offset-violet-400\/70 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.7) !important;
}

.tw-ring-offset-violet-400\/75 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.75) !important;
}

.tw-ring-offset-violet-400\/80 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.8) !important;
}

.tw-ring-offset-violet-400\/85 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.85) !important;
}

.tw-ring-offset-violet-400\/90 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.9) !important;
}

.tw-ring-offset-violet-400\/95 {
  --tw-ring-offset-color: rgb(167 139 250 / 0.95) !important;
}

.tw-ring-offset-violet-50 {
  --tw-ring-offset-color: #f5f3ff !important;
}

.tw-ring-offset-violet-50\/0 {
  --tw-ring-offset-color: rgb(245 243 255 / 0) !important;
}

.tw-ring-offset-violet-50\/10 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.1) !important;
}

.tw-ring-offset-violet-50\/100 {
  --tw-ring-offset-color: rgb(245 243 255 / 1) !important;
}

.tw-ring-offset-violet-50\/15 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.15) !important;
}

.tw-ring-offset-violet-50\/20 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.2) !important;
}

.tw-ring-offset-violet-50\/25 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.25) !important;
}

.tw-ring-offset-violet-50\/30 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.3) !important;
}

.tw-ring-offset-violet-50\/35 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.35) !important;
}

.tw-ring-offset-violet-50\/40 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.4) !important;
}

.tw-ring-offset-violet-50\/45 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.45) !important;
}

.tw-ring-offset-violet-50\/5 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.05) !important;
}

.tw-ring-offset-violet-50\/50 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.5) !important;
}

.tw-ring-offset-violet-50\/55 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.55) !important;
}

.tw-ring-offset-violet-50\/60 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.6) !important;
}

.tw-ring-offset-violet-50\/65 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.65) !important;
}

.tw-ring-offset-violet-50\/70 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.7) !important;
}

.tw-ring-offset-violet-50\/75 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.75) !important;
}

.tw-ring-offset-violet-50\/80 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.8) !important;
}

.tw-ring-offset-violet-50\/85 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.85) !important;
}

.tw-ring-offset-violet-50\/90 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.9) !important;
}

.tw-ring-offset-violet-50\/95 {
  --tw-ring-offset-color: rgb(245 243 255 / 0.95) !important;
}

.tw-ring-offset-violet-500 {
  --tw-ring-offset-color: #8b5cf6 !important;
}

.tw-ring-offset-violet-500\/0 {
  --tw-ring-offset-color: rgb(139 92 246 / 0) !important;
}

.tw-ring-offset-violet-500\/10 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.1) !important;
}

.tw-ring-offset-violet-500\/100 {
  --tw-ring-offset-color: rgb(139 92 246 / 1) !important;
}

.tw-ring-offset-violet-500\/15 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.15) !important;
}

.tw-ring-offset-violet-500\/20 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.2) !important;
}

.tw-ring-offset-violet-500\/25 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.25) !important;
}

.tw-ring-offset-violet-500\/30 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.3) !important;
}

.tw-ring-offset-violet-500\/35 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.35) !important;
}

.tw-ring-offset-violet-500\/40 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.4) !important;
}

.tw-ring-offset-violet-500\/45 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.45) !important;
}

.tw-ring-offset-violet-500\/5 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.05) !important;
}

.tw-ring-offset-violet-500\/50 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.5) !important;
}

.tw-ring-offset-violet-500\/55 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.55) !important;
}

.tw-ring-offset-violet-500\/60 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.6) !important;
}

.tw-ring-offset-violet-500\/65 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.65) !important;
}

.tw-ring-offset-violet-500\/70 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.7) !important;
}

.tw-ring-offset-violet-500\/75 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.75) !important;
}

.tw-ring-offset-violet-500\/80 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.8) !important;
}

.tw-ring-offset-violet-500\/85 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.85) !important;
}

.tw-ring-offset-violet-500\/90 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.9) !important;
}

.tw-ring-offset-violet-500\/95 {
  --tw-ring-offset-color: rgb(139 92 246 / 0.95) !important;
}

.tw-ring-offset-violet-600 {
  --tw-ring-offset-color: #7c3aed !important;
}

.tw-ring-offset-violet-600\/0 {
  --tw-ring-offset-color: rgb(124 58 237 / 0) !important;
}

.tw-ring-offset-violet-600\/10 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.1) !important;
}

.tw-ring-offset-violet-600\/100 {
  --tw-ring-offset-color: rgb(124 58 237 / 1) !important;
}

.tw-ring-offset-violet-600\/15 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.15) !important;
}

.tw-ring-offset-violet-600\/20 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.2) !important;
}

.tw-ring-offset-violet-600\/25 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.25) !important;
}

.tw-ring-offset-violet-600\/30 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.3) !important;
}

.tw-ring-offset-violet-600\/35 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.35) !important;
}

.tw-ring-offset-violet-600\/40 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.4) !important;
}

.tw-ring-offset-violet-600\/45 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.45) !important;
}

.tw-ring-offset-violet-600\/5 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.05) !important;
}

.tw-ring-offset-violet-600\/50 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.5) !important;
}

.tw-ring-offset-violet-600\/55 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.55) !important;
}

.tw-ring-offset-violet-600\/60 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.6) !important;
}

.tw-ring-offset-violet-600\/65 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.65) !important;
}

.tw-ring-offset-violet-600\/70 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.7) !important;
}

.tw-ring-offset-violet-600\/75 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.75) !important;
}

.tw-ring-offset-violet-600\/80 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.8) !important;
}

.tw-ring-offset-violet-600\/85 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.85) !important;
}

.tw-ring-offset-violet-600\/90 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.9) !important;
}

.tw-ring-offset-violet-600\/95 {
  --tw-ring-offset-color: rgb(124 58 237 / 0.95) !important;
}

.tw-ring-offset-violet-700 {
  --tw-ring-offset-color: #6d28d9 !important;
}

.tw-ring-offset-violet-700\/0 {
  --tw-ring-offset-color: rgb(109 40 217 / 0) !important;
}

.tw-ring-offset-violet-700\/10 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.1) !important;
}

.tw-ring-offset-violet-700\/100 {
  --tw-ring-offset-color: rgb(109 40 217 / 1) !important;
}

.tw-ring-offset-violet-700\/15 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.15) !important;
}

.tw-ring-offset-violet-700\/20 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.2) !important;
}

.tw-ring-offset-violet-700\/25 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.25) !important;
}

.tw-ring-offset-violet-700\/30 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.3) !important;
}

.tw-ring-offset-violet-700\/35 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.35) !important;
}

.tw-ring-offset-violet-700\/40 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.4) !important;
}

.tw-ring-offset-violet-700\/45 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.45) !important;
}

.tw-ring-offset-violet-700\/5 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.05) !important;
}

.tw-ring-offset-violet-700\/50 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.5) !important;
}

.tw-ring-offset-violet-700\/55 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.55) !important;
}

.tw-ring-offset-violet-700\/60 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.6) !important;
}

.tw-ring-offset-violet-700\/65 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.65) !important;
}

.tw-ring-offset-violet-700\/70 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.7) !important;
}

.tw-ring-offset-violet-700\/75 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.75) !important;
}

.tw-ring-offset-violet-700\/80 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.8) !important;
}

.tw-ring-offset-violet-700\/85 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.85) !important;
}

.tw-ring-offset-violet-700\/90 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.9) !important;
}

.tw-ring-offset-violet-700\/95 {
  --tw-ring-offset-color: rgb(109 40 217 / 0.95) !important;
}

.tw-ring-offset-violet-800 {
  --tw-ring-offset-color: #5b21b6 !important;
}

.tw-ring-offset-violet-800\/0 {
  --tw-ring-offset-color: rgb(91 33 182 / 0) !important;
}

.tw-ring-offset-violet-800\/10 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.1) !important;
}

.tw-ring-offset-violet-800\/100 {
  --tw-ring-offset-color: rgb(91 33 182 / 1) !important;
}

.tw-ring-offset-violet-800\/15 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.15) !important;
}

.tw-ring-offset-violet-800\/20 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.2) !important;
}

.tw-ring-offset-violet-800\/25 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.25) !important;
}

.tw-ring-offset-violet-800\/30 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.3) !important;
}

.tw-ring-offset-violet-800\/35 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.35) !important;
}

.tw-ring-offset-violet-800\/40 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.4) !important;
}

.tw-ring-offset-violet-800\/45 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.45) !important;
}

.tw-ring-offset-violet-800\/5 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.05) !important;
}

.tw-ring-offset-violet-800\/50 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.5) !important;
}

.tw-ring-offset-violet-800\/55 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.55) !important;
}

.tw-ring-offset-violet-800\/60 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.6) !important;
}

.tw-ring-offset-violet-800\/65 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.65) !important;
}

.tw-ring-offset-violet-800\/70 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.7) !important;
}

.tw-ring-offset-violet-800\/75 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.75) !important;
}

.tw-ring-offset-violet-800\/80 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.8) !important;
}

.tw-ring-offset-violet-800\/85 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.85) !important;
}

.tw-ring-offset-violet-800\/90 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.9) !important;
}

.tw-ring-offset-violet-800\/95 {
  --tw-ring-offset-color: rgb(91 33 182 / 0.95) !important;
}

.tw-ring-offset-violet-900 {
  --tw-ring-offset-color: #4c1d95 !important;
}

.tw-ring-offset-violet-900\/0 {
  --tw-ring-offset-color: rgb(76 29 149 / 0) !important;
}

.tw-ring-offset-violet-900\/10 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.1) !important;
}

.tw-ring-offset-violet-900\/100 {
  --tw-ring-offset-color: rgb(76 29 149 / 1) !important;
}

.tw-ring-offset-violet-900\/15 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.15) !important;
}

.tw-ring-offset-violet-900\/20 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.2) !important;
}

.tw-ring-offset-violet-900\/25 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.25) !important;
}

.tw-ring-offset-violet-900\/30 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.3) !important;
}

.tw-ring-offset-violet-900\/35 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.35) !important;
}

.tw-ring-offset-violet-900\/40 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.4) !important;
}

.tw-ring-offset-violet-900\/45 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.45) !important;
}

.tw-ring-offset-violet-900\/5 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.05) !important;
}

.tw-ring-offset-violet-900\/50 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.5) !important;
}

.tw-ring-offset-violet-900\/55 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.55) !important;
}

.tw-ring-offset-violet-900\/60 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.6) !important;
}

.tw-ring-offset-violet-900\/65 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.65) !important;
}

.tw-ring-offset-violet-900\/70 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.7) !important;
}

.tw-ring-offset-violet-900\/75 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.75) !important;
}

.tw-ring-offset-violet-900\/80 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.8) !important;
}

.tw-ring-offset-violet-900\/85 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.85) !important;
}

.tw-ring-offset-violet-900\/90 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.9) !important;
}

.tw-ring-offset-violet-900\/95 {
  --tw-ring-offset-color: rgb(76 29 149 / 0.95) !important;
}

.tw-ring-offset-violet-950 {
  --tw-ring-offset-color: #2e1065 !important;
}

.tw-ring-offset-violet-950\/0 {
  --tw-ring-offset-color: rgb(46 16 101 / 0) !important;
}

.tw-ring-offset-violet-950\/10 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.1) !important;
}

.tw-ring-offset-violet-950\/100 {
  --tw-ring-offset-color: rgb(46 16 101 / 1) !important;
}

.tw-ring-offset-violet-950\/15 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.15) !important;
}

.tw-ring-offset-violet-950\/20 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.2) !important;
}

.tw-ring-offset-violet-950\/25 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.25) !important;
}

.tw-ring-offset-violet-950\/30 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.3) !important;
}

.tw-ring-offset-violet-950\/35 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.35) !important;
}

.tw-ring-offset-violet-950\/40 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.4) !important;
}

.tw-ring-offset-violet-950\/45 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.45) !important;
}

.tw-ring-offset-violet-950\/5 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.05) !important;
}

.tw-ring-offset-violet-950\/50 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.5) !important;
}

.tw-ring-offset-violet-950\/55 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.55) !important;
}

.tw-ring-offset-violet-950\/60 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.6) !important;
}

.tw-ring-offset-violet-950\/65 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.65) !important;
}

.tw-ring-offset-violet-950\/70 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.7) !important;
}

.tw-ring-offset-violet-950\/75 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.75) !important;
}

.tw-ring-offset-violet-950\/80 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.8) !important;
}

.tw-ring-offset-violet-950\/85 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.85) !important;
}

.tw-ring-offset-violet-950\/90 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.9) !important;
}

.tw-ring-offset-violet-950\/95 {
  --tw-ring-offset-color: rgb(46 16 101 / 0.95) !important;
}

.tw-ring-offset-white {
  --tw-ring-offset-color: #fff !important;
}

.tw-ring-offset-white\/0 {
  --tw-ring-offset-color: rgb(255 255 255 / 0) !important;
}

.tw-ring-offset-white\/10 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.1) !important;
}

.tw-ring-offset-white\/100 {
  --tw-ring-offset-color: rgb(255 255 255 / 1) !important;
}

.tw-ring-offset-white\/15 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.15) !important;
}

.tw-ring-offset-white\/20 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.2) !important;
}

.tw-ring-offset-white\/25 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.25) !important;
}

.tw-ring-offset-white\/30 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.3) !important;
}

.tw-ring-offset-white\/35 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.35) !important;
}

.tw-ring-offset-white\/40 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.4) !important;
}

.tw-ring-offset-white\/45 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.45) !important;
}

.tw-ring-offset-white\/5 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.05) !important;
}

.tw-ring-offset-white\/50 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.5) !important;
}

.tw-ring-offset-white\/55 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.55) !important;
}

.tw-ring-offset-white\/60 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.6) !important;
}

.tw-ring-offset-white\/65 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.65) !important;
}

.tw-ring-offset-white\/70 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.7) !important;
}

.tw-ring-offset-white\/75 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.75) !important;
}

.tw-ring-offset-white\/80 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.8) !important;
}

.tw-ring-offset-white\/85 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.85) !important;
}

.tw-ring-offset-white\/90 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.9) !important;
}

.tw-ring-offset-white\/95 {
  --tw-ring-offset-color: rgb(255 255 255 / 0.95) !important;
}

.tw-ring-offset-yellow-100 {
  --tw-ring-offset-color: #fef9c3 !important;
}

.tw-ring-offset-yellow-100\/0 {
  --tw-ring-offset-color: rgb(254 249 195 / 0) !important;
}

.tw-ring-offset-yellow-100\/10 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.1) !important;
}

.tw-ring-offset-yellow-100\/100 {
  --tw-ring-offset-color: rgb(254 249 195 / 1) !important;
}

.tw-ring-offset-yellow-100\/15 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.15) !important;
}

.tw-ring-offset-yellow-100\/20 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.2) !important;
}

.tw-ring-offset-yellow-100\/25 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.25) !important;
}

.tw-ring-offset-yellow-100\/30 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.3) !important;
}

.tw-ring-offset-yellow-100\/35 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.35) !important;
}

.tw-ring-offset-yellow-100\/40 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.4) !important;
}

.tw-ring-offset-yellow-100\/45 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.45) !important;
}

.tw-ring-offset-yellow-100\/5 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.05) !important;
}

.tw-ring-offset-yellow-100\/50 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.5) !important;
}

.tw-ring-offset-yellow-100\/55 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.55) !important;
}

.tw-ring-offset-yellow-100\/60 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.6) !important;
}

.tw-ring-offset-yellow-100\/65 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.65) !important;
}

.tw-ring-offset-yellow-100\/70 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.7) !important;
}

.tw-ring-offset-yellow-100\/75 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.75) !important;
}

.tw-ring-offset-yellow-100\/80 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.8) !important;
}

.tw-ring-offset-yellow-100\/85 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.85) !important;
}

.tw-ring-offset-yellow-100\/90 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.9) !important;
}

.tw-ring-offset-yellow-100\/95 {
  --tw-ring-offset-color: rgb(254 249 195 / 0.95) !important;
}

.tw-ring-offset-yellow-200 {
  --tw-ring-offset-color: #fef08a !important;
}

.tw-ring-offset-yellow-200\/0 {
  --tw-ring-offset-color: rgb(254 240 138 / 0) !important;
}

.tw-ring-offset-yellow-200\/10 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.1) !important;
}

.tw-ring-offset-yellow-200\/100 {
  --tw-ring-offset-color: rgb(254 240 138 / 1) !important;
}

.tw-ring-offset-yellow-200\/15 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.15) !important;
}

.tw-ring-offset-yellow-200\/20 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.2) !important;
}

.tw-ring-offset-yellow-200\/25 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.25) !important;
}

.tw-ring-offset-yellow-200\/30 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.3) !important;
}

.tw-ring-offset-yellow-200\/35 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.35) !important;
}

.tw-ring-offset-yellow-200\/40 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.4) !important;
}

.tw-ring-offset-yellow-200\/45 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.45) !important;
}

.tw-ring-offset-yellow-200\/5 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.05) !important;
}

.tw-ring-offset-yellow-200\/50 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.5) !important;
}

.tw-ring-offset-yellow-200\/55 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.55) !important;
}

.tw-ring-offset-yellow-200\/60 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.6) !important;
}

.tw-ring-offset-yellow-200\/65 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.65) !important;
}

.tw-ring-offset-yellow-200\/70 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.7) !important;
}

.tw-ring-offset-yellow-200\/75 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.75) !important;
}

.tw-ring-offset-yellow-200\/80 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.8) !important;
}

.tw-ring-offset-yellow-200\/85 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.85) !important;
}

.tw-ring-offset-yellow-200\/90 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.9) !important;
}

.tw-ring-offset-yellow-200\/95 {
  --tw-ring-offset-color: rgb(254 240 138 / 0.95) !important;
}

.tw-ring-offset-yellow-300 {
  --tw-ring-offset-color: #fde047 !important;
}

.tw-ring-offset-yellow-300\/0 {
  --tw-ring-offset-color: rgb(253 224 71 / 0) !important;
}

.tw-ring-offset-yellow-300\/10 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.1) !important;
}

.tw-ring-offset-yellow-300\/100 {
  --tw-ring-offset-color: rgb(253 224 71 / 1) !important;
}

.tw-ring-offset-yellow-300\/15 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.15) !important;
}

.tw-ring-offset-yellow-300\/20 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.2) !important;
}

.tw-ring-offset-yellow-300\/25 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.25) !important;
}

.tw-ring-offset-yellow-300\/30 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.3) !important;
}

.tw-ring-offset-yellow-300\/35 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.35) !important;
}

.tw-ring-offset-yellow-300\/40 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.4) !important;
}

.tw-ring-offset-yellow-300\/45 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.45) !important;
}

.tw-ring-offset-yellow-300\/5 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.05) !important;
}

.tw-ring-offset-yellow-300\/50 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.5) !important;
}

.tw-ring-offset-yellow-300\/55 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.55) !important;
}

.tw-ring-offset-yellow-300\/60 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.6) !important;
}

.tw-ring-offset-yellow-300\/65 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.65) !important;
}

.tw-ring-offset-yellow-300\/70 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.7) !important;
}

.tw-ring-offset-yellow-300\/75 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.75) !important;
}

.tw-ring-offset-yellow-300\/80 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.8) !important;
}

.tw-ring-offset-yellow-300\/85 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.85) !important;
}

.tw-ring-offset-yellow-300\/90 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.9) !important;
}

.tw-ring-offset-yellow-300\/95 {
  --tw-ring-offset-color: rgb(253 224 71 / 0.95) !important;
}

.tw-ring-offset-yellow-400 {
  --tw-ring-offset-color: #facc15 !important;
}

.tw-ring-offset-yellow-400\/0 {
  --tw-ring-offset-color: rgb(250 204 21 / 0) !important;
}

.tw-ring-offset-yellow-400\/10 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.1) !important;
}

.tw-ring-offset-yellow-400\/100 {
  --tw-ring-offset-color: rgb(250 204 21 / 1) !important;
}

.tw-ring-offset-yellow-400\/15 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.15) !important;
}

.tw-ring-offset-yellow-400\/20 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.2) !important;
}

.tw-ring-offset-yellow-400\/25 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.25) !important;
}

.tw-ring-offset-yellow-400\/30 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.3) !important;
}

.tw-ring-offset-yellow-400\/35 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.35) !important;
}

.tw-ring-offset-yellow-400\/40 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.4) !important;
}

.tw-ring-offset-yellow-400\/45 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.45) !important;
}

.tw-ring-offset-yellow-400\/5 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.05) !important;
}

.tw-ring-offset-yellow-400\/50 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.5) !important;
}

.tw-ring-offset-yellow-400\/55 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.55) !important;
}

.tw-ring-offset-yellow-400\/60 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.6) !important;
}

.tw-ring-offset-yellow-400\/65 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.65) !important;
}

.tw-ring-offset-yellow-400\/70 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.7) !important;
}

.tw-ring-offset-yellow-400\/75 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.75) !important;
}

.tw-ring-offset-yellow-400\/80 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.8) !important;
}

.tw-ring-offset-yellow-400\/85 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.85) !important;
}

.tw-ring-offset-yellow-400\/90 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.9) !important;
}

.tw-ring-offset-yellow-400\/95 {
  --tw-ring-offset-color: rgb(250 204 21 / 0.95) !important;
}

.tw-ring-offset-yellow-50 {
  --tw-ring-offset-color: #fefce8 !important;
}

.tw-ring-offset-yellow-50\/0 {
  --tw-ring-offset-color: rgb(254 252 232 / 0) !important;
}

.tw-ring-offset-yellow-50\/10 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.1) !important;
}

.tw-ring-offset-yellow-50\/100 {
  --tw-ring-offset-color: rgb(254 252 232 / 1) !important;
}

.tw-ring-offset-yellow-50\/15 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.15) !important;
}

.tw-ring-offset-yellow-50\/20 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.2) !important;
}

.tw-ring-offset-yellow-50\/25 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.25) !important;
}

.tw-ring-offset-yellow-50\/30 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.3) !important;
}

.tw-ring-offset-yellow-50\/35 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.35) !important;
}

.tw-ring-offset-yellow-50\/40 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.4) !important;
}

.tw-ring-offset-yellow-50\/45 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.45) !important;
}

.tw-ring-offset-yellow-50\/5 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.05) !important;
}

.tw-ring-offset-yellow-50\/50 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.5) !important;
}

.tw-ring-offset-yellow-50\/55 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.55) !important;
}

.tw-ring-offset-yellow-50\/60 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.6) !important;
}

.tw-ring-offset-yellow-50\/65 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.65) !important;
}

.tw-ring-offset-yellow-50\/70 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.7) !important;
}

.tw-ring-offset-yellow-50\/75 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.75) !important;
}

.tw-ring-offset-yellow-50\/80 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.8) !important;
}

.tw-ring-offset-yellow-50\/85 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.85) !important;
}

.tw-ring-offset-yellow-50\/90 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.9) !important;
}

.tw-ring-offset-yellow-50\/95 {
  --tw-ring-offset-color: rgb(254 252 232 / 0.95) !important;
}

.tw-ring-offset-yellow-500 {
  --tw-ring-offset-color: #eab308 !important;
}

.tw-ring-offset-yellow-500\/0 {
  --tw-ring-offset-color: rgb(234 179 8 / 0) !important;
}

.tw-ring-offset-yellow-500\/10 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.1) !important;
}

.tw-ring-offset-yellow-500\/100 {
  --tw-ring-offset-color: rgb(234 179 8 / 1) !important;
}

.tw-ring-offset-yellow-500\/15 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.15) !important;
}

.tw-ring-offset-yellow-500\/20 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.2) !important;
}

.tw-ring-offset-yellow-500\/25 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.25) !important;
}

.tw-ring-offset-yellow-500\/30 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.3) !important;
}

.tw-ring-offset-yellow-500\/35 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.35) !important;
}

.tw-ring-offset-yellow-500\/40 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.4) !important;
}

.tw-ring-offset-yellow-500\/45 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.45) !important;
}

.tw-ring-offset-yellow-500\/5 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.05) !important;
}

.tw-ring-offset-yellow-500\/50 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.5) !important;
}

.tw-ring-offset-yellow-500\/55 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.55) !important;
}

.tw-ring-offset-yellow-500\/60 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.6) !important;
}

.tw-ring-offset-yellow-500\/65 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.65) !important;
}

.tw-ring-offset-yellow-500\/70 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.7) !important;
}

.tw-ring-offset-yellow-500\/75 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.75) !important;
}

.tw-ring-offset-yellow-500\/80 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.8) !important;
}

.tw-ring-offset-yellow-500\/85 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.85) !important;
}

.tw-ring-offset-yellow-500\/90 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.9) !important;
}

.tw-ring-offset-yellow-500\/95 {
  --tw-ring-offset-color: rgb(234 179 8 / 0.95) !important;
}

.tw-ring-offset-yellow-600 {
  --tw-ring-offset-color: #ca8a04 !important;
}

.tw-ring-offset-yellow-600\/0 {
  --tw-ring-offset-color: rgb(202 138 4 / 0) !important;
}

.tw-ring-offset-yellow-600\/10 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.1) !important;
}

.tw-ring-offset-yellow-600\/100 {
  --tw-ring-offset-color: rgb(202 138 4 / 1) !important;
}

.tw-ring-offset-yellow-600\/15 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.15) !important;
}

.tw-ring-offset-yellow-600\/20 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.2) !important;
}

.tw-ring-offset-yellow-600\/25 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.25) !important;
}

.tw-ring-offset-yellow-600\/30 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.3) !important;
}

.tw-ring-offset-yellow-600\/35 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.35) !important;
}

.tw-ring-offset-yellow-600\/40 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.4) !important;
}

.tw-ring-offset-yellow-600\/45 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.45) !important;
}

.tw-ring-offset-yellow-600\/5 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.05) !important;
}

.tw-ring-offset-yellow-600\/50 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.5) !important;
}

.tw-ring-offset-yellow-600\/55 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.55) !important;
}

.tw-ring-offset-yellow-600\/60 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.6) !important;
}

.tw-ring-offset-yellow-600\/65 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.65) !important;
}

.tw-ring-offset-yellow-600\/70 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.7) !important;
}

.tw-ring-offset-yellow-600\/75 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.75) !important;
}

.tw-ring-offset-yellow-600\/80 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.8) !important;
}

.tw-ring-offset-yellow-600\/85 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.85) !important;
}

.tw-ring-offset-yellow-600\/90 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.9) !important;
}

.tw-ring-offset-yellow-600\/95 {
  --tw-ring-offset-color: rgb(202 138 4 / 0.95) !important;
}

.tw-ring-offset-yellow-700 {
  --tw-ring-offset-color: #a16207 !important;
}

.tw-ring-offset-yellow-700\/0 {
  --tw-ring-offset-color: rgb(161 98 7 / 0) !important;
}

.tw-ring-offset-yellow-700\/10 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.1) !important;
}

.tw-ring-offset-yellow-700\/100 {
  --tw-ring-offset-color: rgb(161 98 7 / 1) !important;
}

.tw-ring-offset-yellow-700\/15 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.15) !important;
}

.tw-ring-offset-yellow-700\/20 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.2) !important;
}

.tw-ring-offset-yellow-700\/25 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.25) !important;
}

.tw-ring-offset-yellow-700\/30 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.3) !important;
}

.tw-ring-offset-yellow-700\/35 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.35) !important;
}

.tw-ring-offset-yellow-700\/40 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.4) !important;
}

.tw-ring-offset-yellow-700\/45 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.45) !important;
}

.tw-ring-offset-yellow-700\/5 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.05) !important;
}

.tw-ring-offset-yellow-700\/50 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.5) !important;
}

.tw-ring-offset-yellow-700\/55 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.55) !important;
}

.tw-ring-offset-yellow-700\/60 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.6) !important;
}

.tw-ring-offset-yellow-700\/65 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.65) !important;
}

.tw-ring-offset-yellow-700\/70 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.7) !important;
}

.tw-ring-offset-yellow-700\/75 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.75) !important;
}

.tw-ring-offset-yellow-700\/80 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.8) !important;
}

.tw-ring-offset-yellow-700\/85 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.85) !important;
}

.tw-ring-offset-yellow-700\/90 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.9) !important;
}

.tw-ring-offset-yellow-700\/95 {
  --tw-ring-offset-color: rgb(161 98 7 / 0.95) !important;
}

.tw-ring-offset-yellow-800 {
  --tw-ring-offset-color: #854d0e !important;
}

.tw-ring-offset-yellow-800\/0 {
  --tw-ring-offset-color: rgb(133 77 14 / 0) !important;
}

.tw-ring-offset-yellow-800\/10 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.1) !important;
}

.tw-ring-offset-yellow-800\/100 {
  --tw-ring-offset-color: rgb(133 77 14 / 1) !important;
}

.tw-ring-offset-yellow-800\/15 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.15) !important;
}

.tw-ring-offset-yellow-800\/20 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.2) !important;
}

.tw-ring-offset-yellow-800\/25 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.25) !important;
}

.tw-ring-offset-yellow-800\/30 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.3) !important;
}

.tw-ring-offset-yellow-800\/35 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.35) !important;
}

.tw-ring-offset-yellow-800\/40 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.4) !important;
}

.tw-ring-offset-yellow-800\/45 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.45) !important;
}

.tw-ring-offset-yellow-800\/5 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.05) !important;
}

.tw-ring-offset-yellow-800\/50 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.5) !important;
}

.tw-ring-offset-yellow-800\/55 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.55) !important;
}

.tw-ring-offset-yellow-800\/60 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.6) !important;
}

.tw-ring-offset-yellow-800\/65 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.65) !important;
}

.tw-ring-offset-yellow-800\/70 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.7) !important;
}

.tw-ring-offset-yellow-800\/75 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.75) !important;
}

.tw-ring-offset-yellow-800\/80 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.8) !important;
}

.tw-ring-offset-yellow-800\/85 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.85) !important;
}

.tw-ring-offset-yellow-800\/90 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.9) !important;
}

.tw-ring-offset-yellow-800\/95 {
  --tw-ring-offset-color: rgb(133 77 14 / 0.95) !important;
}

.tw-ring-offset-yellow-900 {
  --tw-ring-offset-color: #713f12 !important;
}

.tw-ring-offset-yellow-900\/0 {
  --tw-ring-offset-color: rgb(113 63 18 / 0) !important;
}

.tw-ring-offset-yellow-900\/10 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.1) !important;
}

.tw-ring-offset-yellow-900\/100 {
  --tw-ring-offset-color: rgb(113 63 18 / 1) !important;
}

.tw-ring-offset-yellow-900\/15 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.15) !important;
}

.tw-ring-offset-yellow-900\/20 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.2) !important;
}

.tw-ring-offset-yellow-900\/25 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.25) !important;
}

.tw-ring-offset-yellow-900\/30 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.3) !important;
}

.tw-ring-offset-yellow-900\/35 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.35) !important;
}

.tw-ring-offset-yellow-900\/40 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.4) !important;
}

.tw-ring-offset-yellow-900\/45 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.45) !important;
}

.tw-ring-offset-yellow-900\/5 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.05) !important;
}

.tw-ring-offset-yellow-900\/50 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.5) !important;
}

.tw-ring-offset-yellow-900\/55 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.55) !important;
}

.tw-ring-offset-yellow-900\/60 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.6) !important;
}

.tw-ring-offset-yellow-900\/65 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.65) !important;
}

.tw-ring-offset-yellow-900\/70 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.7) !important;
}

.tw-ring-offset-yellow-900\/75 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.75) !important;
}

.tw-ring-offset-yellow-900\/80 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.8) !important;
}

.tw-ring-offset-yellow-900\/85 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.85) !important;
}

.tw-ring-offset-yellow-900\/90 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.9) !important;
}

.tw-ring-offset-yellow-900\/95 {
  --tw-ring-offset-color: rgb(113 63 18 / 0.95) !important;
}

.tw-ring-offset-yellow-950 {
  --tw-ring-offset-color: #422006 !important;
}

.tw-ring-offset-yellow-950\/0 {
  --tw-ring-offset-color: rgb(66 32 6 / 0) !important;
}

.tw-ring-offset-yellow-950\/10 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.1) !important;
}

.tw-ring-offset-yellow-950\/100 {
  --tw-ring-offset-color: rgb(66 32 6 / 1) !important;
}

.tw-ring-offset-yellow-950\/15 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.15) !important;
}

.tw-ring-offset-yellow-950\/20 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.2) !important;
}

.tw-ring-offset-yellow-950\/25 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.25) !important;
}

.tw-ring-offset-yellow-950\/30 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.3) !important;
}

.tw-ring-offset-yellow-950\/35 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.35) !important;
}

.tw-ring-offset-yellow-950\/40 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.4) !important;
}

.tw-ring-offset-yellow-950\/45 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.45) !important;
}

.tw-ring-offset-yellow-950\/5 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.05) !important;
}

.tw-ring-offset-yellow-950\/50 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.5) !important;
}

.tw-ring-offset-yellow-950\/55 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.55) !important;
}

.tw-ring-offset-yellow-950\/60 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.6) !important;
}

.tw-ring-offset-yellow-950\/65 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.65) !important;
}

.tw-ring-offset-yellow-950\/70 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.7) !important;
}

.tw-ring-offset-yellow-950\/75 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.75) !important;
}

.tw-ring-offset-yellow-950\/80 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.8) !important;
}

.tw-ring-offset-yellow-950\/85 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.85) !important;
}

.tw-ring-offset-yellow-950\/90 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.9) !important;
}

.tw-ring-offset-yellow-950\/95 {
  --tw-ring-offset-color: rgb(66 32 6 / 0.95) !important;
}

.tw-ring-offset-zinc-100 {
  --tw-ring-offset-color: #f4f4f5 !important;
}

.tw-ring-offset-zinc-100\/0 {
  --tw-ring-offset-color: rgb(244 244 245 / 0) !important;
}

.tw-ring-offset-zinc-100\/10 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.1) !important;
}

.tw-ring-offset-zinc-100\/100 {
  --tw-ring-offset-color: rgb(244 244 245 / 1) !important;
}

.tw-ring-offset-zinc-100\/15 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.15) !important;
}

.tw-ring-offset-zinc-100\/20 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.2) !important;
}

.tw-ring-offset-zinc-100\/25 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.25) !important;
}

.tw-ring-offset-zinc-100\/30 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.3) !important;
}

.tw-ring-offset-zinc-100\/35 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.35) !important;
}

.tw-ring-offset-zinc-100\/40 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.4) !important;
}

.tw-ring-offset-zinc-100\/45 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.45) !important;
}

.tw-ring-offset-zinc-100\/5 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.05) !important;
}

.tw-ring-offset-zinc-100\/50 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.5) !important;
}

.tw-ring-offset-zinc-100\/55 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.55) !important;
}

.tw-ring-offset-zinc-100\/60 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.6) !important;
}

.tw-ring-offset-zinc-100\/65 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.65) !important;
}

.tw-ring-offset-zinc-100\/70 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.7) !important;
}

.tw-ring-offset-zinc-100\/75 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.75) !important;
}

.tw-ring-offset-zinc-100\/80 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.8) !important;
}

.tw-ring-offset-zinc-100\/85 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.85) !important;
}

.tw-ring-offset-zinc-100\/90 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.9) !important;
}

.tw-ring-offset-zinc-100\/95 {
  --tw-ring-offset-color: rgb(244 244 245 / 0.95) !important;
}

.tw-ring-offset-zinc-200 {
  --tw-ring-offset-color: #e4e4e7 !important;
}

.tw-ring-offset-zinc-200\/0 {
  --tw-ring-offset-color: rgb(228 228 231 / 0) !important;
}

.tw-ring-offset-zinc-200\/10 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.1) !important;
}

.tw-ring-offset-zinc-200\/100 {
  --tw-ring-offset-color: rgb(228 228 231 / 1) !important;
}

.tw-ring-offset-zinc-200\/15 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.15) !important;
}

.tw-ring-offset-zinc-200\/20 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.2) !important;
}

.tw-ring-offset-zinc-200\/25 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.25) !important;
}

.tw-ring-offset-zinc-200\/30 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.3) !important;
}

.tw-ring-offset-zinc-200\/35 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.35) !important;
}

.tw-ring-offset-zinc-200\/40 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.4) !important;
}

.tw-ring-offset-zinc-200\/45 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.45) !important;
}

.tw-ring-offset-zinc-200\/5 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.05) !important;
}

.tw-ring-offset-zinc-200\/50 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.5) !important;
}

.tw-ring-offset-zinc-200\/55 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.55) !important;
}

.tw-ring-offset-zinc-200\/60 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.6) !important;
}

.tw-ring-offset-zinc-200\/65 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.65) !important;
}

.tw-ring-offset-zinc-200\/70 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.7) !important;
}

.tw-ring-offset-zinc-200\/75 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.75) !important;
}

.tw-ring-offset-zinc-200\/80 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.8) !important;
}

.tw-ring-offset-zinc-200\/85 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.85) !important;
}

.tw-ring-offset-zinc-200\/90 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.9) !important;
}

.tw-ring-offset-zinc-200\/95 {
  --tw-ring-offset-color: rgb(228 228 231 / 0.95) !important;
}

.tw-ring-offset-zinc-300 {
  --tw-ring-offset-color: #d4d4d8 !important;
}

.tw-ring-offset-zinc-300\/0 {
  --tw-ring-offset-color: rgb(212 212 216 / 0) !important;
}

.tw-ring-offset-zinc-300\/10 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.1) !important;
}

.tw-ring-offset-zinc-300\/100 {
  --tw-ring-offset-color: rgb(212 212 216 / 1) !important;
}

.tw-ring-offset-zinc-300\/15 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.15) !important;
}

.tw-ring-offset-zinc-300\/20 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.2) !important;
}

.tw-ring-offset-zinc-300\/25 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.25) !important;
}

.tw-ring-offset-zinc-300\/30 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.3) !important;
}

.tw-ring-offset-zinc-300\/35 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.35) !important;
}

.tw-ring-offset-zinc-300\/40 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.4) !important;
}

.tw-ring-offset-zinc-300\/45 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.45) !important;
}

.tw-ring-offset-zinc-300\/5 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.05) !important;
}

.tw-ring-offset-zinc-300\/50 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.5) !important;
}

.tw-ring-offset-zinc-300\/55 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.55) !important;
}

.tw-ring-offset-zinc-300\/60 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.6) !important;
}

.tw-ring-offset-zinc-300\/65 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.65) !important;
}

.tw-ring-offset-zinc-300\/70 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.7) !important;
}

.tw-ring-offset-zinc-300\/75 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.75) !important;
}

.tw-ring-offset-zinc-300\/80 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.8) !important;
}

.tw-ring-offset-zinc-300\/85 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.85) !important;
}

.tw-ring-offset-zinc-300\/90 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.9) !important;
}

.tw-ring-offset-zinc-300\/95 {
  --tw-ring-offset-color: rgb(212 212 216 / 0.95) !important;
}

.tw-ring-offset-zinc-400 {
  --tw-ring-offset-color: #a1a1aa !important;
}

.tw-ring-offset-zinc-400\/0 {
  --tw-ring-offset-color: rgb(161 161 170 / 0) !important;
}

.tw-ring-offset-zinc-400\/10 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.1) !important;
}

.tw-ring-offset-zinc-400\/100 {
  --tw-ring-offset-color: rgb(161 161 170 / 1) !important;
}

.tw-ring-offset-zinc-400\/15 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.15) !important;
}

.tw-ring-offset-zinc-400\/20 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.2) !important;
}

.tw-ring-offset-zinc-400\/25 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.25) !important;
}

.tw-ring-offset-zinc-400\/30 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.3) !important;
}

.tw-ring-offset-zinc-400\/35 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.35) !important;
}

.tw-ring-offset-zinc-400\/40 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.4) !important;
}

.tw-ring-offset-zinc-400\/45 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.45) !important;
}

.tw-ring-offset-zinc-400\/5 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.05) !important;
}

.tw-ring-offset-zinc-400\/50 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.5) !important;
}

.tw-ring-offset-zinc-400\/55 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.55) !important;
}

.tw-ring-offset-zinc-400\/60 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.6) !important;
}

.tw-ring-offset-zinc-400\/65 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.65) !important;
}

.tw-ring-offset-zinc-400\/70 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.7) !important;
}

.tw-ring-offset-zinc-400\/75 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.75) !important;
}

.tw-ring-offset-zinc-400\/80 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.8) !important;
}

.tw-ring-offset-zinc-400\/85 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.85) !important;
}

.tw-ring-offset-zinc-400\/90 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.9) !important;
}

.tw-ring-offset-zinc-400\/95 {
  --tw-ring-offset-color: rgb(161 161 170 / 0.95) !important;
}

.tw-ring-offset-zinc-50 {
  --tw-ring-offset-color: #fafafa !important;
}

.tw-ring-offset-zinc-50\/0 {
  --tw-ring-offset-color: rgb(250 250 250 / 0) !important;
}

.tw-ring-offset-zinc-50\/10 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.1) !important;
}

.tw-ring-offset-zinc-50\/100 {
  --tw-ring-offset-color: rgb(250 250 250 / 1) !important;
}

.tw-ring-offset-zinc-50\/15 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.15) !important;
}

.tw-ring-offset-zinc-50\/20 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.2) !important;
}

.tw-ring-offset-zinc-50\/25 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.25) !important;
}

.tw-ring-offset-zinc-50\/30 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.3) !important;
}

.tw-ring-offset-zinc-50\/35 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.35) !important;
}

.tw-ring-offset-zinc-50\/40 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.4) !important;
}

.tw-ring-offset-zinc-50\/45 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.45) !important;
}

.tw-ring-offset-zinc-50\/5 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.05) !important;
}

.tw-ring-offset-zinc-50\/50 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.5) !important;
}

.tw-ring-offset-zinc-50\/55 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.55) !important;
}

.tw-ring-offset-zinc-50\/60 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.6) !important;
}

.tw-ring-offset-zinc-50\/65 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.65) !important;
}

.tw-ring-offset-zinc-50\/70 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.7) !important;
}

.tw-ring-offset-zinc-50\/75 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.75) !important;
}

.tw-ring-offset-zinc-50\/80 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.8) !important;
}

.tw-ring-offset-zinc-50\/85 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.85) !important;
}

.tw-ring-offset-zinc-50\/90 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.9) !important;
}

.tw-ring-offset-zinc-50\/95 {
  --tw-ring-offset-color: rgb(250 250 250 / 0.95) !important;
}

.tw-ring-offset-zinc-500 {
  --tw-ring-offset-color: #71717a !important;
}

.tw-ring-offset-zinc-500\/0 {
  --tw-ring-offset-color: rgb(113 113 122 / 0) !important;
}

.tw-ring-offset-zinc-500\/10 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.1) !important;
}

.tw-ring-offset-zinc-500\/100 {
  --tw-ring-offset-color: rgb(113 113 122 / 1) !important;
}

.tw-ring-offset-zinc-500\/15 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.15) !important;
}

.tw-ring-offset-zinc-500\/20 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.2) !important;
}

.tw-ring-offset-zinc-500\/25 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.25) !important;
}

.tw-ring-offset-zinc-500\/30 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.3) !important;
}

.tw-ring-offset-zinc-500\/35 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.35) !important;
}

.tw-ring-offset-zinc-500\/40 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.4) !important;
}

.tw-ring-offset-zinc-500\/45 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.45) !important;
}

.tw-ring-offset-zinc-500\/5 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.05) !important;
}

.tw-ring-offset-zinc-500\/50 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.5) !important;
}

.tw-ring-offset-zinc-500\/55 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.55) !important;
}

.tw-ring-offset-zinc-500\/60 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.6) !important;
}

.tw-ring-offset-zinc-500\/65 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.65) !important;
}

.tw-ring-offset-zinc-500\/70 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.7) !important;
}

.tw-ring-offset-zinc-500\/75 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.75) !important;
}

.tw-ring-offset-zinc-500\/80 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.8) !important;
}

.tw-ring-offset-zinc-500\/85 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.85) !important;
}

.tw-ring-offset-zinc-500\/90 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.9) !important;
}

.tw-ring-offset-zinc-500\/95 {
  --tw-ring-offset-color: rgb(113 113 122 / 0.95) !important;
}

.tw-ring-offset-zinc-600 {
  --tw-ring-offset-color: #52525b !important;
}

.tw-ring-offset-zinc-600\/0 {
  --tw-ring-offset-color: rgb(82 82 91 / 0) !important;
}

.tw-ring-offset-zinc-600\/10 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.1) !important;
}

.tw-ring-offset-zinc-600\/100 {
  --tw-ring-offset-color: rgb(82 82 91 / 1) !important;
}

.tw-ring-offset-zinc-600\/15 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.15) !important;
}

.tw-ring-offset-zinc-600\/20 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.2) !important;
}

.tw-ring-offset-zinc-600\/25 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.25) !important;
}

.tw-ring-offset-zinc-600\/30 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.3) !important;
}

.tw-ring-offset-zinc-600\/35 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.35) !important;
}

.tw-ring-offset-zinc-600\/40 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.4) !important;
}

.tw-ring-offset-zinc-600\/45 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.45) !important;
}

.tw-ring-offset-zinc-600\/5 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.05) !important;
}

.tw-ring-offset-zinc-600\/50 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.5) !important;
}

.tw-ring-offset-zinc-600\/55 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.55) !important;
}

.tw-ring-offset-zinc-600\/60 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.6) !important;
}

.tw-ring-offset-zinc-600\/65 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.65) !important;
}

.tw-ring-offset-zinc-600\/70 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.7) !important;
}

.tw-ring-offset-zinc-600\/75 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.75) !important;
}

.tw-ring-offset-zinc-600\/80 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.8) !important;
}

.tw-ring-offset-zinc-600\/85 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.85) !important;
}

.tw-ring-offset-zinc-600\/90 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.9) !important;
}

.tw-ring-offset-zinc-600\/95 {
  --tw-ring-offset-color: rgb(82 82 91 / 0.95) !important;
}

.tw-ring-offset-zinc-700 {
  --tw-ring-offset-color: #3f3f46 !important;
}

.tw-ring-offset-zinc-700\/0 {
  --tw-ring-offset-color: rgb(63 63 70 / 0) !important;
}

.tw-ring-offset-zinc-700\/10 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.1) !important;
}

.tw-ring-offset-zinc-700\/100 {
  --tw-ring-offset-color: rgb(63 63 70 / 1) !important;
}

.tw-ring-offset-zinc-700\/15 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.15) !important;
}

.tw-ring-offset-zinc-700\/20 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.2) !important;
}

.tw-ring-offset-zinc-700\/25 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.25) !important;
}

.tw-ring-offset-zinc-700\/30 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.3) !important;
}

.tw-ring-offset-zinc-700\/35 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.35) !important;
}

.tw-ring-offset-zinc-700\/40 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.4) !important;
}

.tw-ring-offset-zinc-700\/45 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.45) !important;
}

.tw-ring-offset-zinc-700\/5 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.05) !important;
}

.tw-ring-offset-zinc-700\/50 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.5) !important;
}

.tw-ring-offset-zinc-700\/55 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.55) !important;
}

.tw-ring-offset-zinc-700\/60 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.6) !important;
}

.tw-ring-offset-zinc-700\/65 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.65) !important;
}

.tw-ring-offset-zinc-700\/70 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.7) !important;
}

.tw-ring-offset-zinc-700\/75 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.75) !important;
}

.tw-ring-offset-zinc-700\/80 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.8) !important;
}

.tw-ring-offset-zinc-700\/85 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.85) !important;
}

.tw-ring-offset-zinc-700\/90 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.9) !important;
}

.tw-ring-offset-zinc-700\/95 {
  --tw-ring-offset-color: rgb(63 63 70 / 0.95) !important;
}

.tw-ring-offset-zinc-800 {
  --tw-ring-offset-color: #27272a !important;
}

.tw-ring-offset-zinc-800\/0 {
  --tw-ring-offset-color: rgb(39 39 42 / 0) !important;
}

.tw-ring-offset-zinc-800\/10 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.1) !important;
}

.tw-ring-offset-zinc-800\/100 {
  --tw-ring-offset-color: rgb(39 39 42 / 1) !important;
}

.tw-ring-offset-zinc-800\/15 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.15) !important;
}

.tw-ring-offset-zinc-800\/20 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.2) !important;
}

.tw-ring-offset-zinc-800\/25 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.25) !important;
}

.tw-ring-offset-zinc-800\/30 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.3) !important;
}

.tw-ring-offset-zinc-800\/35 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.35) !important;
}

.tw-ring-offset-zinc-800\/40 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.4) !important;
}

.tw-ring-offset-zinc-800\/45 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.45) !important;
}

.tw-ring-offset-zinc-800\/5 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.05) !important;
}

.tw-ring-offset-zinc-800\/50 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.5) !important;
}

.tw-ring-offset-zinc-800\/55 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.55) !important;
}

.tw-ring-offset-zinc-800\/60 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.6) !important;
}

.tw-ring-offset-zinc-800\/65 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.65) !important;
}

.tw-ring-offset-zinc-800\/70 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.7) !important;
}

.tw-ring-offset-zinc-800\/75 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.75) !important;
}

.tw-ring-offset-zinc-800\/80 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.8) !important;
}

.tw-ring-offset-zinc-800\/85 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.85) !important;
}

.tw-ring-offset-zinc-800\/90 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.9) !important;
}

.tw-ring-offset-zinc-800\/95 {
  --tw-ring-offset-color: rgb(39 39 42 / 0.95) !important;
}

.tw-ring-offset-zinc-900 {
  --tw-ring-offset-color: #18181b !important;
}

.tw-ring-offset-zinc-900\/0 {
  --tw-ring-offset-color: rgb(24 24 27 / 0) !important;
}

.tw-ring-offset-zinc-900\/10 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.1) !important;
}

.tw-ring-offset-zinc-900\/100 {
  --tw-ring-offset-color: rgb(24 24 27 / 1) !important;
}

.tw-ring-offset-zinc-900\/15 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.15) !important;
}

.tw-ring-offset-zinc-900\/20 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.2) !important;
}

.tw-ring-offset-zinc-900\/25 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.25) !important;
}

.tw-ring-offset-zinc-900\/30 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.3) !important;
}

.tw-ring-offset-zinc-900\/35 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.35) !important;
}

.tw-ring-offset-zinc-900\/40 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.4) !important;
}

.tw-ring-offset-zinc-900\/45 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.45) !important;
}

.tw-ring-offset-zinc-900\/5 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.05) !important;
}

.tw-ring-offset-zinc-900\/50 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.5) !important;
}

.tw-ring-offset-zinc-900\/55 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.55) !important;
}

.tw-ring-offset-zinc-900\/60 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.6) !important;
}

.tw-ring-offset-zinc-900\/65 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.65) !important;
}

.tw-ring-offset-zinc-900\/70 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.7) !important;
}

.tw-ring-offset-zinc-900\/75 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.75) !important;
}

.tw-ring-offset-zinc-900\/80 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.8) !important;
}

.tw-ring-offset-zinc-900\/85 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.85) !important;
}

.tw-ring-offset-zinc-900\/90 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.9) !important;
}

.tw-ring-offset-zinc-900\/95 {
  --tw-ring-offset-color: rgb(24 24 27 / 0.95) !important;
}

.tw-ring-offset-zinc-950 {
  --tw-ring-offset-color: #09090b !important;
}

.tw-ring-offset-zinc-950\/0 {
  --tw-ring-offset-color: rgb(9 9 11 / 0) !important;
}

.tw-ring-offset-zinc-950\/10 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.1) !important;
}

.tw-ring-offset-zinc-950\/100 {
  --tw-ring-offset-color: rgb(9 9 11 / 1) !important;
}

.tw-ring-offset-zinc-950\/15 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.15) !important;
}

.tw-ring-offset-zinc-950\/20 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.2) !important;
}

.tw-ring-offset-zinc-950\/25 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.25) !important;
}

.tw-ring-offset-zinc-950\/30 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.3) !important;
}

.tw-ring-offset-zinc-950\/35 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.35) !important;
}

.tw-ring-offset-zinc-950\/40 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.4) !important;
}

.tw-ring-offset-zinc-950\/45 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.45) !important;
}

.tw-ring-offset-zinc-950\/5 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.05) !important;
}

.tw-ring-offset-zinc-950\/50 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.5) !important;
}

.tw-ring-offset-zinc-950\/55 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.55) !important;
}

.tw-ring-offset-zinc-950\/60 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.6) !important;
}

.tw-ring-offset-zinc-950\/65 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.65) !important;
}

.tw-ring-offset-zinc-950\/70 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.7) !important;
}

.tw-ring-offset-zinc-950\/75 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.75) !important;
}

.tw-ring-offset-zinc-950\/80 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.8) !important;
}

.tw-ring-offset-zinc-950\/85 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.85) !important;
}

.tw-ring-offset-zinc-950\/90 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.9) !important;
}

.tw-ring-offset-zinc-950\/95 {
  --tw-ring-offset-color: rgb(9 9 11 / 0.95) !important;
}

.tw-blur {
  --tw-blur: blur(8px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-0 {
  --tw-blur: blur(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-2xl {
  --tw-blur: blur(40px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-3xl {
  --tw-blur: blur(64px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-lg {
  --tw-blur: blur(16px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-md {
  --tw-blur: blur(12px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-none {
  --tw-blur: blur(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-sm {
  --tw-blur: blur(4px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-blur-xl {
  --tw-blur: blur(24px) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-0 {
  --tw-brightness: brightness(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-100 {
  --tw-brightness: brightness(1) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-105 {
  --tw-brightness: brightness(1.05) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-110 {
  --tw-brightness: brightness(1.1) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-125 {
  --tw-brightness: brightness(1.25) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-150 {
  --tw-brightness: brightness(1.5) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-200 {
  --tw-brightness: brightness(2) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-50 {
  --tw-brightness: brightness(.5) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-75 {
  --tw-brightness: brightness(.75) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-90 {
  --tw-brightness: brightness(.9) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-brightness-95 {
  --tw-brightness: brightness(.95) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-0 {
  --tw-contrast: contrast(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-100 {
  --tw-contrast: contrast(1) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-125 {
  --tw-contrast: contrast(1.25) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-150 {
  --tw-contrast: contrast(1.5) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-200 {
  --tw-contrast: contrast(2) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-50 {
  --tw-contrast: contrast(.5) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-contrast-75 {
  --tw-contrast: contrast(.75) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow {
  --tw-drop-shadow: drop-shadow(0 1px 2px rgb(0 0 0 / 0.1)) drop-shadow(0 1px 1px rgb(0 0 0 / 0.06)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-2xl {
  --tw-drop-shadow: drop-shadow(0 25px 25px rgb(0 0 0 / 0.15)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-lg {
  --tw-drop-shadow: drop-shadow(0 10px 8px rgb(0 0 0 / 0.04)) drop-shadow(0 4px 3px rgb(0 0 0 / 0.1)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-md {
  --tw-drop-shadow: drop-shadow(0 4px 3px rgb(0 0 0 / 0.07)) drop-shadow(0 2px 2px rgb(0 0 0 / 0.06)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-none {
  --tw-drop-shadow: drop-shadow(0 0 #0000) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-sm {
  --tw-drop-shadow: drop-shadow(0 1px 1px rgb(0 0 0 / 0.05)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-drop-shadow-xl {
  --tw-drop-shadow: drop-shadow(0 20px 13px rgb(0 0 0 / 0.03)) drop-shadow(0 8px 5px rgb(0 0 0 / 0.08)) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-grayscale {
  --tw-grayscale: grayscale(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-grayscale-0 {
  --tw-grayscale: grayscale(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.-tw-hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(-0deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.-tw-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.-tw-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.-tw-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.-tw-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.-tw-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw--hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(-0deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw--hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(-15deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw--hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(-180deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw--hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(-30deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw--hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(-60deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw--hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(-90deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-hue-rotate-0 {
  --tw-hue-rotate: hue-rotate(0deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-hue-rotate-15 {
  --tw-hue-rotate: hue-rotate(15deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-hue-rotate-180 {
  --tw-hue-rotate: hue-rotate(180deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-hue-rotate-30 {
  --tw-hue-rotate: hue-rotate(30deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-hue-rotate-60 {
  --tw-hue-rotate: hue-rotate(60deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-hue-rotate-90 {
  --tw-hue-rotate: hue-rotate(90deg) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-invert {
  --tw-invert: invert(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-invert-0 {
  --tw-invert: invert(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-saturate-0 {
  --tw-saturate: saturate(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-saturate-100 {
  --tw-saturate: saturate(1) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-saturate-150 {
  --tw-saturate: saturate(1.5) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-saturate-200 {
  --tw-saturate: saturate(2) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-saturate-50 {
  --tw-saturate: saturate(.5) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-sepia {
  --tw-sepia: sepia(100%) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-sepia-0 {
  --tw-sepia: sepia(0) !important;
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important;
}

.tw-filter-none {
  filter: none !important;
}

.tw-backdrop-blur {
  --tw-backdrop-blur: blur(8px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-0 {
  --tw-backdrop-blur: blur(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-2xl {
  --tw-backdrop-blur: blur(40px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-3xl {
  --tw-backdrop-blur: blur(64px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-lg {
  --tw-backdrop-blur: blur(16px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-md {
  --tw-backdrop-blur: blur(12px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-none {
  --tw-backdrop-blur: blur(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-sm {
  --tw-backdrop-blur: blur(4px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-blur-xl {
  --tw-backdrop-blur: blur(24px) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-0 {
  --tw-backdrop-brightness: brightness(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-100 {
  --tw-backdrop-brightness: brightness(1) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-105 {
  --tw-backdrop-brightness: brightness(1.05) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-110 {
  --tw-backdrop-brightness: brightness(1.1) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-125 {
  --tw-backdrop-brightness: brightness(1.25) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-150 {
  --tw-backdrop-brightness: brightness(1.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-200 {
  --tw-backdrop-brightness: brightness(2) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-50 {
  --tw-backdrop-brightness: brightness(.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-75 {
  --tw-backdrop-brightness: brightness(.75) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-90 {
  --tw-backdrop-brightness: brightness(.9) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-brightness-95 {
  --tw-backdrop-brightness: brightness(.95) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-0 {
  --tw-backdrop-contrast: contrast(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-100 {
  --tw-backdrop-contrast: contrast(1) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-125 {
  --tw-backdrop-contrast: contrast(1.25) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-150 {
  --tw-backdrop-contrast: contrast(1.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-200 {
  --tw-backdrop-contrast: contrast(2) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-50 {
  --tw-backdrop-contrast: contrast(.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-contrast-75 {
  --tw-backdrop-contrast: contrast(.75) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-grayscale {
  --tw-backdrop-grayscale: grayscale(100%) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-grayscale-0 {
  --tw-backdrop-grayscale: grayscale(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.-tw-backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(-0deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.-tw-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.-tw-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.-tw-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.-tw-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.-tw-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw--backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(-0deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw--backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(-15deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw--backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(-180deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw--backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(-30deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw--backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(-60deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw--backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(-90deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-hue-rotate-0 {
  --tw-backdrop-hue-rotate: hue-rotate(0deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-hue-rotate-15 {
  --tw-backdrop-hue-rotate: hue-rotate(15deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-hue-rotate-180 {
  --tw-backdrop-hue-rotate: hue-rotate(180deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-hue-rotate-30 {
  --tw-backdrop-hue-rotate: hue-rotate(30deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-hue-rotate-60 {
  --tw-backdrop-hue-rotate: hue-rotate(60deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-hue-rotate-90 {
  --tw-backdrop-hue-rotate: hue-rotate(90deg) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-invert {
  --tw-backdrop-invert: invert(100%) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-invert-0 {
  --tw-backdrop-invert: invert(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-0 {
  --tw-backdrop-opacity: opacity(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-10 {
  --tw-backdrop-opacity: opacity(0.1) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-100 {
  --tw-backdrop-opacity: opacity(1) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-15 {
  --tw-backdrop-opacity: opacity(0.15) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-20 {
  --tw-backdrop-opacity: opacity(0.2) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-25 {
  --tw-backdrop-opacity: opacity(0.25) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-30 {
  --tw-backdrop-opacity: opacity(0.3) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-35 {
  --tw-backdrop-opacity: opacity(0.35) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-40 {
  --tw-backdrop-opacity: opacity(0.4) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-45 {
  --tw-backdrop-opacity: opacity(0.45) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-5 {
  --tw-backdrop-opacity: opacity(0.05) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-50 {
  --tw-backdrop-opacity: opacity(0.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-55 {
  --tw-backdrop-opacity: opacity(0.55) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-60 {
  --tw-backdrop-opacity: opacity(0.6) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-65 {
  --tw-backdrop-opacity: opacity(0.65) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-70 {
  --tw-backdrop-opacity: opacity(0.7) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-75 {
  --tw-backdrop-opacity: opacity(0.75) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-80 {
  --tw-backdrop-opacity: opacity(0.8) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-85 {
  --tw-backdrop-opacity: opacity(0.85) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-90 {
  --tw-backdrop-opacity: opacity(0.9) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-opacity-95 {
  --tw-backdrop-opacity: opacity(0.95) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-saturate-0 {
  --tw-backdrop-saturate: saturate(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-saturate-100 {
  --tw-backdrop-saturate: saturate(1) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-saturate-150 {
  --tw-backdrop-saturate: saturate(1.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-saturate-200 {
  --tw-backdrop-saturate: saturate(2) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-saturate-50 {
  --tw-backdrop-saturate: saturate(.5) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-sepia {
  --tw-backdrop-sepia: sepia(100%) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-sepia-0 {
  --tw-backdrop-sepia: sepia(0) !important;
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-filter {
  -webkit-backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
          backdrop-filter: var(--tw-backdrop-blur) var(--tw-backdrop-brightness) var(--tw-backdrop-contrast) var(--tw-backdrop-grayscale) var(--tw-backdrop-hue-rotate) var(--tw-backdrop-invert) var(--tw-backdrop-opacity) var(--tw-backdrop-saturate) var(--tw-backdrop-sepia) !important;
}

.tw-backdrop-filter-none {
  -webkit-backdrop-filter: none !important;
          backdrop-filter: none !important;
}

.tw-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter !important;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-all {
  transition-property: all !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-colors {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-none {
  transition-property: none !important;
}

.tw-transition-opacity {
  transition-property: opacity !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-shadow {
  transition-property: box-shadow !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-transition-transform {
  transition-property: transform !important;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
  transition-duration: 150ms !important;
}

.tw-delay-0 {
  transition-delay: 0s !important;
}

.tw-delay-100 {
  transition-delay: 100ms !important;
}

.tw-delay-1000 {
  transition-delay: 1000ms !important;
}

.tw-delay-150 {
  transition-delay: 150ms !important;
}

.tw-delay-200 {
  transition-delay: 200ms !important;
}

.tw-delay-300 {
  transition-delay: 300ms !important;
}

.tw-delay-500 {
  transition-delay: 500ms !important;
}

.tw-delay-700 {
  transition-delay: 700ms !important;
}

.tw-delay-75 {
  transition-delay: 75ms !important;
}

.tw-duration-0 {
  transition-duration: 0s !important;
}

.tw-duration-100 {
  transition-duration: 100ms !important;
}

.tw-duration-1000 {
  transition-duration: 1000ms !important;
}

.tw-duration-150 {
  transition-duration: 150ms !important;
}

.tw-duration-200 {
  transition-duration: 200ms !important;
}

.tw-duration-300 {
  transition-duration: 300ms !important;
}

.tw-duration-500 {
  transition-duration: 500ms !important;
}

.tw-duration-700 {
  transition-duration: 700ms !important;
}

.tw-duration-75 {
  transition-duration: 75ms !important;
}

.tw-ease-in {
  transition-timing-function: cubic-bezier(0.4, 0, 1, 1) !important;
}

.tw-ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1) !important;
}

.tw-ease-linear {
  transition-timing-function: linear !important;
}

.tw-ease-out {
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
}

.tw-will-change-auto {
  will-change: auto !important;
}

.tw-will-change-contents {
  will-change: contents !important;
}

.tw-will-change-scroll {
  will-change: scroll-position !important;
}

.tw-will-change-transform {
  will-change: transform !important;
}

.tw-content-none {
  --tw-content: none !important;
  content: var(--tw-content) !important;
}

.tw-forced-color-adjust-auto {
  forced-color-adjust: auto !important;
}

.tw-forced-color-adjust-none {
  forced-color-adjust: none !important;
}

